import React from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import getStyle from '../../Css/NeedUploadProofOverlayCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getFont } from '../../../../../PostSalesStyles';
import { UPLOAD_PROOF } from '../../../../FlightImageConstants';
import { isEmptyArray } from '../../../../../Common/commonUtil';
import {getGoBackAndUploadData} from "../../data/dataAdapter"
import { useTheme } from '../../../../../theme/theme.context';

const GoBackAndUpload = ({
  handleBottomOverlay,
  data,
  segments,
  reqSegments,
  goToUpload,
  docNotUploadedPageTwo,
  title,
}) => {
  const { fsStyle, ...fonts } = getFont(true);
  const {psTheme} = useTheme();
  const {color} = psTheme;
  const styles = getStyle(psTheme);
  const closeOverlay = () => {
    handleBottomOverlay('');
  };
  const {
    subtitle,
    requiredSegmentData
  } = getGoBackAndUploadData(docNotUploadedPageTwo, segments, reqSegments);

  const segmentIncludedSubtitle =
    !isEmptyArray(requiredSegmentData) &&
    !isEmptyArray(subtitle) &&
    subtitle.map((sub) =>
      sub.includes('$$%%') ? (
        <Text>
          <Text>{sub.split('$$%%')[0]}</Text>
          <Text key={sub} style={[fonts.boldFontFamily]}>
            {requiredSegmentData.toString()}
          </Text>
          <Text>{sub.split('$$%%')[1]}</Text>
        </Text>
      ) : (
        sub
      ),
    );
  return (
    <View style={styles.OverlayContentWrapper}>
      <View style={AtomicCss.marginBottom24}>
        <View style={styles.overlayTopline} />
      </View>
      <View style={styles.uploadProofWrapper}>
        <Image source={UPLOAD_PROOF} style={styles.uploadProofStyle} />
        {!!title && (
          <Text
            style={[styles.title, fonts.blackFontFamily, AtomicCss.marginBottom8, fsStyle.font22]}
          >
            {title}
          </Text>
        )}
        <View style={styles.strokeStyle} />
        {!isEmptyArray(segmentIncludedSubtitle)
          ? segmentIncludedSubtitle.map((sub) => (
              <Text
                key={sub}
                style={[
                  fonts.boldFontFamily,
                  fsStyle.font16,
                  {color : color.black},
                  styles.lineHeight22,
                  AtomicCss.marginBottom12,
                ]}
              >
                {sub}
              </Text>
            ))
          : !isEmptyArray(subtitle) &&
            subtitle.map((sub) => (
              <Text
                key={sub}
                style={[
                  fonts.boldFontFamily,
                  fsStyle.font16,
                  {color : color.black},
                  styles.lineHeight22,
                  AtomicCss.marginBottom12,
                ]}
              >
                {sub}
              </Text>
            ))}
        <TouchableOpacity style={[AtomicCss.flexRow]} onPress={closeOverlay}>
          <Text
            style={[
              fsStyle.font16,
              fonts.blackFontFamily,
              {color : color.azure},
              AtomicCss.alignRight,
              AtomicCss.lineHeight20,
              AtomicCss.flex1,
            ]}
          >
            {goToUpload}
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default GoBackAndUpload;
