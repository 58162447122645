import React from 'react';
import {View, Text, StyleSheet} from 'react-native';
import PropTypes from 'prop-types';
import AtomicCss from '../details/AtomicCss';
import {has} from 'lodash';
import AcmeBookingDetailsConstant from "../utils/AcmeBookingDetailsConstant";

class CancellationBreakupDetails extends React.Component {
  static navigationOptions = {
    header: null
  };

  render() {
    const customerCurrency = has(this.props.cancellationPreviewResponse, 'customerCurrency')
                                 ? this.props.cancellationPreviewResponse.customerCurrency
                                 : '';

    const priceText = AcmeBookingDetailsConstant.INDIAN_CURRENCIES.includes(customerCurrency)
        ? 'Price (Including GST)'
        : 'Price (Including Taxes)';

    return (
      <View style={[AtomicCss.whiteBg, AtomicCss.elevation1]}>
        <View style={[AtomicCss.flexRow, styles.CancPriceBreckup, styles.borderbtm]}>
          <View style={styles.CancLeftSection}>
            <Text
              style={[AtomicCss.blackText, AtomicCss.font14, AtomicCss.boldFont, AtomicCss.marginBottom8]}
            >
              Total Amount Paid
            </Text>
            <Text style={[AtomicCss.font12, AtomicCss.lightGrey, AtomicCss.marginBottom8]}>
              {priceText}
            </Text>
            {this.props.cancellationPreviewResponse.priceBreakup.convenienceFee > 0 &&
            <Text style={[AtomicCss.font12, AtomicCss.lightGrey, AtomicCss.marginBottom8]}>MMT
              Convenience fees
            </Text>}
            {this.props.cancellationPreviewResponse.priceBreakup.ecouponAmount > 0 ?
              <Text style={[AtomicCss.font12, AtomicCss.lightGrey]}>Instant E coupon</Text> : null
            }
          </View>
          <View>
            <Text
              style={[AtomicCss.blackText, AtomicCss.font14, AtomicCss.boldFont, AtomicCss.marginBottom8, AtomicCss.alignRight]}
            >
              {customerCurrency} {this.props.cancellationPreviewResponse.priceBreakup.totalAmountPaid}{' '}
            </Text>
            <Text
              style={[AtomicCss.font12, AtomicCss.lightGrey, AtomicCss.marginBottom8, AtomicCss.alignRight]}
            >
              {customerCurrency} {this.props.cancellationPreviewResponse.priceBreakup.sellingPrice}{' '}
            </Text>
            {this.props.cancellationPreviewResponse.priceBreakup.convenienceFee > 0 &&
            <Text
              style={[AtomicCss.font12, AtomicCss.lightGrey, AtomicCss.marginBottom8, AtomicCss.alignRight]}
            >
              {customerCurrency} {this.props.cancellationPreviewResponse.priceBreakup.convenienceFee}{' '}
            </Text>}
            {this.props.cancellationPreviewResponse.priceBreakup.ecouponAmount > 0 ?
              <Text style={[AtomicCss.font12, AtomicCss.lightGrey, AtomicCss.alignRight]}>
                - {customerCurrency} {this.props.cancellationPreviewResponse.priceBreakup.ecouponAmount}{' '}
              </Text> : null
            }
          </View>
        </View>
        <View style={[AtomicCss.flexRow, styles.CancPriceBreckup, styles.borderbtm]}>
          <View style={styles.CancLeftSection}>
            <Text style={[AtomicCss.blackText, AtomicCss.font14, AtomicCss.boldFont, AtomicCss.marginBottom8]}>
              Cancellation charges
            </Text>
            {this.props.cancellationPreviewResponse.acmeCancellationDetails.cancellationBreakup.penalty > 0 &&
            <Text style={[AtomicCss.lightGrey, AtomicCss.font12, AtomicCss.regularFont, AtomicCss.marginBottom8]}>
              Vendor Cancellation Fee
            </Text>
            }
            {this.props.cancellationPreviewResponse.acmeCancellationDetails.cancellationBreakup.serviceCharge > 0 &&
            <Text
              style={[AtomicCss.lightGrey, AtomicCss.font12, AtomicCss.regularFont, AtomicCss.marginBottom8]}
            >
              MMT Convenience fees
            </Text>
            }
            {this.props.cancellationPreviewResponse.acmeCancellationDetails.cancellationBreakup.cashbackAmount > 0 &&
            <Text style={[AtomicCss.lightGrey, AtomicCss.font12, AtomicCss.regularFont]}>
              CashBack Reversal
            </Text>
            }
          </View>
          <View>
            <Text style={[AtomicCss.blackText, AtomicCss.font14, AtomicCss.boldFont, AtomicCss.marginBottom8, AtomicCss.alignRight]}>
              - {customerCurrency}{this.props.cancellationPreviewResponse.acmeCancellationDetails.totalCancellationCharges}{' '}
            </Text>
            {this.props.cancellationPreviewResponse.acmeCancellationDetails.cancellationBreakup.penalty > 0 &&
            <Text
              style={[AtomicCss.lightGrey, AtomicCss.font12, AtomicCss.regularFont, AtomicCss.marginBottom8, AtomicCss.alignRight]}
            >
              - {customerCurrency} {this.props.cancellationPreviewResponse.acmeCancellationDetails.cancellationBreakup.penalty}{' '}
            </Text>
            }
            {this.props.cancellationPreviewResponse.acmeCancellationDetails.cancellationBreakup.serviceCharge > 0 &&
            <Text
              style={[AtomicCss.lightGrey, AtomicCss.font12, AtomicCss.regularFont, AtomicCss.alignRight, AtomicCss.marginBottom8]}
            >
              - {customerCurrency} {this.props.cancellationPreviewResponse.acmeCancellationDetails.cancellationBreakup.serviceCharge}{' '}
            </Text>
            }
            {this.props.cancellationPreviewResponse.acmeCancellationDetails.cancellationBreakup.cashbackAmount > 0 &&
            <Text
              style={[AtomicCss.lightGrey, AtomicCss.font12, AtomicCss.regularFont, AtomicCss.alignRight]}
            >
              - {customerCurrency} {this.props.cancellationPreviewResponse.acmeCancellationDetails.cancellationBreakup.cashbackAmount}{' '}
            </Text>
            }
          </View>
        </View>
        <View style={[AtomicCss.flexRow, styles.CancPriceBreckup]}>
          <View style={styles.CancLeftSection}>
            <Text style={[styles.impInfoGreen, AtomicCss.boldFont, AtomicCss.marginBottom8]}>
              Your Refund
            </Text>
          </View>
          <View>
            <Text style={[styles.impInfoGreen, AtomicCss.boldFont, AtomicCss.marginBottom8]}>
              {customerCurrency} {this.props.cancellationPreviewResponse.refundAmount}{' '}
            </Text>
          </View>
        </View>
      </View>

    );
  }
}

const styles = StyleSheet.create({
  CancPriceBreckup: {
    justifyContent: 'space-between',
    padding: 16
  },
  borderbtm: {
    borderBottomWidth: 1,
    borderBottomColor: '#ddd'
  },
  CancLeftSection: {
    flexDirection: 'column'
  },
  impInfoGreen: {
    color: '#00a19c',
    fontSize: 14,
    textAlign: 'right'
  },
  impInfoRed: {color: '#eb2026', fontSize: 14, textAlign: 'right'}
});

CancellationBreakupDetails.propTypes = {
  cancellationPreviewResponse: PropTypes.object.isRequired
};
export default CancellationBreakupDetails;
