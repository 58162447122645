// @flow
import React from 'react';
import isEmpty  from 'lodash/isEmpty';
import { getActivitySummaryData } from './activityCardAdapter';
import {
  ActionsContent,
  ActivityInfoText,
  DescriptionSection,
  MainContent,
  NeedHelpStrip,
  NewStatusText,
  RightArrow,
  StaticIcon,
  SummaryContainer,
  TitleText,
} from '../cardElements';

function ActivitySummaryCard({ response, msrTicket }) {
  const cardData = getActivitySummaryData(response, msrTicket);
  return (
    <SummaryContainer>
      <MainContent onPress={cardData.openDetails}>
        <StaticIcon icon={cardData.iconUrl} iconStyle={{ height: 25, width: 22 }} />
        <DescriptionSection>
          <TitleText text={cardData.productName} />
          {!cardData.failedBooking && !!cardData.cityName && (
            <ActivityInfoText text={cardData.cityName} />
          )}
          {!!cardData.dateTimeWithPaxInfo && (
            <NewStatusText status={cardData.status} text={cardData.dateTimeWithPaxInfo} />
          )}
        </DescriptionSection>
        <RightArrow />
      </MainContent>
      {!isEmpty(cardData.needHelpData) &&  cardData.needHelpData.map((needHelp)=><NeedHelpStrip {...needHelp}/>)}
      <ActionsContent actions={cardData.actions} />
    </SummaryContainer>
  );
}

export default ActivitySummaryCard;
