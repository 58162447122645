import React from 'react';
import {View, Text, TouchableOpacity, Image} from 'react-native';
import PropTypes from 'prop-types';
import {get, filter} from 'lodash';
import styles from './DynamicFormCss';
import InputFieldMaster from './InputFieldMaster';
import {isValidPattern, getHypotheticalParentNode, getIndex, getSingleMultiInputStyle} from './DynamicFormUtils';
import {CloseIcon} from '../../HolidayBookingConstants';


class FormMultiSelectInput extends React.Component {
  static navigationOptions = {
    header: null
  };

  constructor(props) {
    super(props);
    this.fetchNewDataIfNotAvailable();

    this.errorField = 'Cannot leave this blank';
    this.values = {};
    this.props.visaForm.fields[this.props.fieldName].values.forEach((element) => {
      if (element.parent === getHypotheticalParentNode(this.props.parent, this.props.visaForm.fields[this.props.fieldName])) {
        this.values[element.key] = false;
      }
    });
    this.fetchPrefilledValues();
    if (!this.alreadySetValues()) {
      if (this.props.visaForm.fields[this.props.fieldName].defaultValue !== '') {
        this.values[this.props.visaForm.fields[this.props.fieldName].defaultValue] = true;
      }
      this.updateValues();
    }
    this.state = {
      reRender: true,
      validate: this.props.validate
    };
  }

  alreadySetValues = () => {
    let retVal = false;
    Object.keys(this.values).forEach((key) => {
      retVal = retVal || this.values[key];
    });
    return retVal;
  }

  fetchPrefilledValues = () => {
    const vals = get(this.props, this.props.jsonKey, undefined);
    if (vals) {
      vals.forEach((elem) => {
        this.values[elem.value] = true;
      });
    }
  }

  fetchNewDataIfNotAvailable = () => {
    if (this.props.visaForm.fields[this.props.fieldName].validations[getHypotheticalParentNode(this.props.parent, this.props.visaForm.fields[this.props.fieldName])] === undefined &&
      this.props.visaForm.fields[this.props.fieldName].url !== '') {
      const params = {};
      params.jsonKey = this.props.jsonKey;
      params.fieldName = this.props.fieldName;
      params.parent = getHypotheticalParentNode(this.props.parent, this.props.visaForm.fields[this.props.fieldName]);
      this.props.setField(params);
    }
  }


  componentWillReceiveProps(newProps) {
    if (this.props.validate === !newProps.validate) {
      this.setState({
        validate: newProps.validate
      });
    }
  }

  updateValues = () => {
    this.props.updateChildValues(this.props.jsonKey, []);
    const values = filter(Object.keys(this.values), element => this.values[element] === true);
    values.forEach((element, index) => {
      this.props.updateChildValues(`${this.props.jsonKey}[${index}].value`, values[index]);
      this.props.updateChildValues(`${this.props.jsonKey}[${index}].parent`, getHypotheticalParentNode(this.props.parent, this.props.visaForm.fields[this.props.fieldName]));
      this.props.updateChildValues(`${this.props.jsonKey}[${index}].dependents`, {});
    });
  }

  selection = (element) => {
    if (!this.props.visaForm.fields[this.props.fieldName].disabled) {
      this.values[element] = !this.values[element];
      this.updateValues();
      this.setState({
        reRender: true
      });
    }
  }

  renderMultiValueInputView = () => {
    const list = [];
    this.props.visaForm.fields[this.props.fieldName].values.forEach((element) => {
      if (element.parent === getHypotheticalParentNode(this.props.parent, this.props.visaForm.fields[this.props.fieldName])) {
        list.push(this.view(element));
      }
    });
    return list;
  }

  view = (element) => {
    const isActive = this.values[element.key];
    return (
      <TouchableOpacity onPress={() => { this.selection(element.key); }} key={element.key}>
        <View style={[styles.ValueTab, isActive ? getSingleMultiInputStyle(this.props.visaForm.fields[this.props.fieldName].disabled) : '']}>
          <Text style={[styles.defaultGrey, styles.font12, styles.regularFont,
            isActive ? styles.tabActiveTxt : styles.defaultGrey]}
          >
            {element.value}
          </Text>
        </View>
      </TouchableOpacity>
    );
  }

  setErrorField = () => {
    if (this.props.visaForm.fields[this.props.fieldName].mandatory && filter(Object.keys(this.values), element => this.values[element] === true).length === 0) {
      this.errorField = 'Cannot leave this blank';
    } else {
      this.errorField = this.props.visaForm.fields[this.props.fieldName].error;
    }
    return true;
  }

  renderDependents = () => {
    const dependents = [];
    const values = filter(Object.keys(this.values), element => this.values[element] === true);
    values.forEach((element, index) => {
      if (this.props.visaForm.fields[this.props.fieldName].dependents &&
        this.props.visaForm.fields[this.props.fieldName].dependents[element]) {
        this.props.visaForm.fields[this.props.fieldName].dependents[element].forEach((elem) => {
          const jsonKey = `${this.props.jsonKey}[${index}].dependents`;
          dependents.push(<InputFieldMaster
            focusOnNextField={this.props.focusOnNextField}
            textInputRefs={this.props.textInputRefs}
            labelIndex={`${this.props.labelIndex}.${dependents.length + 1}`}
            key={`${elem}.${jsonKey}`}
            parent={element}
            jsonKey={jsonKey}
            fieldName={elem}
            visaForm={this.props.visaForm}
            updateChildValues={this.props.updateChildValues}
            validate={this.state.validate}
            setField={this.props.setField}
            showDropDown={this.props.showDropDown}
          />);
        });
      }
    });
    return dependents;
  }

  atleastOneSelected = () => {
    if (filter(Object.keys(this.values), element => this.values[element] === true).length) {
      return true;
    }
    return false;
  }

  isValid = (pattern) => {
    let valid = true;
    const keys = filter(Object.keys(this.values), element => this.values[element] === true);
    let validCount = 0;
    keys.forEach((element) => {
      valid = valid && isValidPattern(pattern, element);
      if (valid) validCount += 1;
    });
    if (this.props.visaForm.fields[this.props.fieldName].mandatory && !validCount) return false;
    return valid;
  }


  shouldShowErrorField = () => {
    if (this.props.validate) {
      this.setErrorField();
      if (this.props.visaForm.fields[this.props.fieldName].mandatory) {
        if (filter(Object.keys(this.values), element => this.values[element] === true).length === 0) {
          return true;
        }
        return !this.isValid(new RegExp(this.props.visaForm.fields[this.props.fieldName].validations[getHypotheticalParentNode(this.props.parent, this.props.visaForm.fields[this.props.fieldName])]));
      }
      if (filter(Object.keys(this.values), element => this.values[element] === true).length === 0) {
        return false;
      }
      return !this.isValid(new RegExp(this.props.visaForm.fields[this.props.fieldName].validations[getHypotheticalParentNode(this.props.parent, this.props.visaForm.fields[this.props.fieldName])]));
    }
    return false;
  }

  getDependents = () => {
    const dependents = this.renderDependents();
    if (dependents.length !== 0) {
      return dependents;
    }
  }

  shouldShowCancelIcon = () => {
    if (this.props.visaForm.fields[this.props.fieldName].mandatory) {
      return this.props.visaForm.fields[this.props.fieldName] &&
      this.props.visaForm.fields[this.props.fieldName].addMore &&
      getIndex(this.props.jsonKey) !== '0';
    }
    return this.props.visaForm.fields[this.props.fieldName] &&
      this.props.visaForm.fields[this.props.fieldName].addMore;
  }

  render() {
    return (
      <View>
        {this.shouldShowCancelIcon() &&
        <View style={[styles.flex1, styles.flexRow, styles.mar12Bt, styles.visaFormCloseIcon]}>
          <TouchableOpacity onPress={() => this.props.deleteWRTIndex(this.props.indexInParent)}>
            <Image source={CloseIcon} style={styles.visaFormCloseIconImage} />
          </TouchableOpacity>
        </View>}
        <View style={[styles.flexRow, {flexWrap: 'wrap'}]}>
          {this.renderMultiValueInputView()}
        </View>
        {this.state.validate &&
        (!this.atleastOneSelected() ||
        !this.isValid(new RegExp(this.props.visaForm.fields[this.props.fieldName].validations[getHypotheticalParentNode(this.props.parent, this.props.visaForm.fields[this.props.fieldName])]))) &&
        this.setErrorField() &&
        <Text style={[styles.ErrorText, styles.font14, styles.regularFont]}>{this.errorField}</Text>}
        {this.getDependents()}
      </View>
    );
  }
}

FormMultiSelectInput.propTypes = {
  labelIndex: PropTypes.string.isRequired,
  parent: PropTypes.string.isRequired,
  jsonKey: PropTypes.string.isRequired,
  visaForm: PropTypes.object.isRequired,
  fieldName: PropTypes.string.isRequired,
  validate: PropTypes.bool.isRequired,
  updateChildValues: PropTypes.func.isRequired,
  setField: PropTypes.func.isRequired,
  showDropDown: PropTypes.func.isRequired,
  deleteWRTIndex: PropTypes.func.isRequired,
  indexInParent: PropTypes.number.isRequired,
  textInputRefs: PropTypes.object.isRequired,
  focusOnNextField: PropTypes.func.isRequired
};

export default FormMultiSelectInput;
