import { colors, fonts } from '@mmt/legacy-commons/Styles/globalStyles';

const styles = {
  pageWrapper: {
    flex: 1,
  },
  pageScroll: {
    flex: 1,
    backgroundColor: '#f2f2f2',
  },
  pageScrollTopSpace: {
    height: 0,
  },
  whiteCard: {
    backgroundColor: '#ffffff',
    padding: 15,
    marginVertical: 8,
    elevation: 2,
    shadowColor: '#000000',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.3,
    shadowRadius: 1,
  },
  ReviewCard: {
    marginVertical: 5,
  },
  showView: {
    display: 'flex',
  },
  hideView: {
    display: 'none',
  },
  heading2Text: {
    fontSize: 14,
    color: colors.black,
    fontFamily: fonts.bold,
  },
  marBt5: {
    marginBottom: 5,
  },
  font12: {
    fontSize: 12,
  },
  infoIcon: {
    width: 16,
    height: 16,
    marginRight: 10,
  },
  info_redTxt: {
    color: '#e53442',
    fontSize: 12,
    lineHeight: 16,
    fontStyle: 'italic',
  },
  flexRow: {
    flexDirection: 'row',
  },
  marginBottom10: {
    marginBottom: 10,
  },
  padding10: {
    padding: 10,
  },
  refundInfoWrapper: {
    borderRadius: 4,
    padding: 10,
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 10,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: '#ffedd1',
  },
  refundInfoTxt: {
    fontFamily: fonts.regular,
    fontSize: 14,
    color: '#1a7971',
  },
  confirmOverlayIcon: {
    width: 50,
    height: 44,
    marginRight: 15,
  },
};

export default styles;
