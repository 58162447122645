import { StyleSheet } from 'react-native';
import { fonts } from '@mmt/legacy-commons/Styles/globalStyles';

const getStyles = (fonts) => {
  return StyleSheet.create({
      roomInfoSection: {
          padding: 16,
          backgroundColor: '#EEFDF6',
          marginTop: 2
        },
      roomAvailableTxt: {
      color: '#1A7971',
      fontFamily: fonts.bold,
      ...fonts.font14
      }
  });
}

export default getStyles;