import React from 'react';
import Actions from '../../../navigation/postSalesNavigation';
import LinearGradient from 'react-native-linear-gradient';
import getStyles from '../../details/components/HotelMamiCss';
import {Image, Text, TouchableOpacity, View, BackHandler} from 'react-native';
import {
  fillCheckInCheckOutData,
  isIntlHotelBooking,
  resetToHotelDetail
} from '../../details/utils/HotelBookingDetailUtil';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import BasePage from '../../../Common/PostSalesBasePage';
import PropTypes from 'prop-types';
import {
  ADD_GUEST_SUCCESS, ADD_GUESTS_SEE_UPDATED_BOOKING, CHANGE_DATE_SEE_UPDATED_BOOKING,
  DATE_CHANGE_SUCCESS, NAME_CHANGE_SUCCESS, NAME_CHANGE_SEE_UPDATED_BOOKING
} from '../HotelModificationConstants';
import HotelBookingTrackingHelper from '../../HotelBookingTrackingHelper';
import { SUCCESSFUL_ICON } from '../../HotelImageConstants';
import * as NpsModule from '@mmt/legacy-commons/Native/NpsModule';
import { getRatingData } from '../../../Common/commonUtil';
import { MODIFICATION_TYPE } from '../../details/HotelBookingDetailsConstant';
import { getStaticData } from '../../../staticData/staticData';
import { LANG_AWARE } from '../../HotelConstants';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';

export const HOTEL_THANKYOU_PAGE = 'hotelModificationThankYouPage';
class HotelModificationThankYouPage extends BasePage {
  constructor(props) {
    super(props, HOTEL_THANKYOU_PAGE);
    this.fonts = getFont(true);
    this.styles = getStyles(this.fonts);
    this.checkindate = this.props.newCheckInDate;
    this.checkoutdate = this.props.newCheckOutDate;
    this.bookingid = this.props.newBookingID;
    this.emailid = this.props.emailID;
    this.ratingTrackingPageName = 'HotelModificationThankYou';
    this.showRatingPrompt = false;
    this.state = {
      modificationResponse: props.modificationResponse
    };
    if(props.action && props.action.length && (props.modificationResponse != null)){
        //need dev for thank you page AFTER payment
        this.ratingTrackingPageName += "_"+props.action;
        this.showRatingPrompt = (props.action === 'DATECHANGE') || (props.action === 'ADDGUEST');
    }
    this.getRequestHeaderData(this.props.newBookingID);
  }

  async getRequestHeaderData(bookingId) {
    const {mmtAuth, loggingTrackingInfo} = await HotelBookingModule.getRequestHeader(bookingId, 'Hotel_Modification_ViewRoom');
    this.mmtAuth = mmtAuth;
    this.loggingTrackingInfo = loggingTrackingInfo;
  }

    static navigationOptions = {
      header: null
    }
    onHardBackPress = () => {
      this.resettoDetail(this.bookingid, this.mmtAuth, this.loggingTrackingInfo);
      return true;
    };

    componentDidMount() {
      BackHandler.addEventListener('hardwareBackPress', this.onHardBackPress);
      let hotelDetailResponse = this.props.hotelDetailResponse ;
      super.componentDidMount();
      if (hotelDetailResponse == null) {
        this.getHotelBookingDetailResponse();
      } else {
        this.trackLoadEvent(hotelDetailResponse);
      }
    }

    componentWillUnmount() {
      BackHandler.removeEventListener('hardwareBackPress', this.onHardBackPress);
    }

    async getHotelBookingDetailResponse() {
      try {
        const hotelBookingDetailResponse = await HotelBookingModule.getHotelBookingDetailsResponse();
        this.trackLoadEvent(JSON.parse(hotelBookingDetailResponse.HOTEL_BOOKING_DETAILS));
      } catch (error) {

      }
    }

    resettoDetail(bookingId, mmtAuth, loggingTrackingInfo) {
      resetToHotelDetail(bookingId)
      const action = this.props.action;
      if (action === 'DATECHANGE') {
        HotelBookingTrackingHelper.trackClickEvent(DATE_CHANGE_SUCCESS, CHANGE_DATE_SEE_UPDATED_BOOKING);
      } else if (action === 'ADDGUEST') {
        HotelBookingTrackingHelper.trackClickEvent(ADD_GUEST_SUCCESS, ADD_GUESTS_SEE_UPDATED_BOOKING);
      } else if (action && action.toUpperCase() === MODIFICATION_TYPE.NAME_CHANGE.toUpperCase()) {
        HotelBookingTrackingHelper.trackClickEvent(NAME_CHANGE_SUCCESS, NAME_CHANGE_SEE_UPDATED_BOOKING);
      }
    }

    trackLoadEvent(hotelBookingDetailResponse) {
      const { action = '' } = this.props;
      if (action === 'DATECHANGE') {
        this.trackingPageName = isIntlHotelBooking(hotelBookingDetailResponse?.lobCode)
          ? 'Mob:customer support:Hotel International:DateChange:ThankYou_Success'
          : 'Mob:customer support:Hotel Domestic:DateChange:ThankYou_Success';
      } else if (action === 'ADDGUEST') {
        this.trackingPageName = isIntlHotelBooking(hotelBookingDetailResponse?.lobCode)
          ? 'Mob:customer support:Hotel International:AddGuest:ThankYou_Success'
          : 'Mob:customer support:Hotel Domestic:AddGuest:ThankYou_Success';
      } else if (action && action.toUpperCase() == MODIFICATION_TYPE.NAME_CHANGE.toUpperCase()) {
        this.trackingPageName = isIntlHotelBooking(hotelBookingDetailResponse?.lobCode)
          ? 'Mob:customer support:Hotel International:NameChange:ThankYou_Success'
          : 'Mob:customer support:Hotel Domestic:NameChange:ThankYou_Success';
      }
      HotelBookingTrackingHelper.trackLoadEvent(this.trackingPageName, hotelBookingDetailResponse);
    }


    render() {
      const successIcon = SUCCESSFUL_ICON;
      const { action = '', thankYouText, heading } = this.props;
      const { seeUpdatedBookingText, addGuestSuccessfulText, nameChangeSuccessfulText, dcSuccessfulText } = getStaticData(LANG_AWARE.MODIFICATION);

      const { ratingData, npsData } = getRatingData(this.trackingPageName, this.state.modificationResponse);
      if (ratingData && this.showRatingPrompt) {
          NpsModule.showRatingPrompt(ratingData, npsData);
          this.showRatingPrompt = false;
      }
      
      return (
        <View style={this.styles.ErrorStateWrapper}>
          <Image
            style={[this.styles.dateChangeIcon, this.styles.mar50Bt, this.styles.CenterText]}
            source={successIcon}
          />
          <View style={[action === 'DATECHANGE' ? this.styles.showView : this.styles.hideView]}>
            <Text style={[this.styles.ErrorPageText, this.styles.boldFont]}>{dcSuccessfulText}</Text>
            <Text style={[this.styles.AlignCenter, this.styles.lineHt22, this.styles.mar50Bt]}>Your new dates are from&nbsp;
              {fillCheckInCheckOutData(this.checkindate, 'DD MMM')} - {fillCheckInCheckOutData(this.checkoutdate, 'DD MMM')}.
            </Text>
          </View>
          <View style={[action === 'ADDGUEST' ? this.styles.showView : this.styles.hideView]}>
            <Text style={[this.styles.ErrorPageText, this.styles.boldFont]}>{addGuestSuccessfulText}</Text>
          </View>
          { (action && action.toUpperCase() === MODIFICATION_TYPE.NAME_CHANGE.toUpperCase()) &&
            <View style={AtomicCss.marginBottom30}>
              {!!heading && <Text style={[this.styles.ErrorPageText, this.styles.boldFont]}>{heading}</Text>}
              <Text style={[this.styles.thankYouText]}>{ thankYouText || nameChangeSuccessfulText}</Text>
            </View>
          }
          <TouchableOpacity onPress={() => {
              this.resettoDetail(this.bookingid, this.mmtAuth, this.loggingTrackingInfo);
                }}
          >
            <LinearGradient
              start={{x: 1.0, y: 0.0}}
              end={{x: 0.0, y: 1.0}}
              colors={this.props.buttonGradientColor}
              style={[this.styles.CommonFillBtn]}
            >
              <Text style={[this.styles.WhiteText, this.styles.CenterText, this.styles.blackFont, this.styles.transperantBgrnd]}>{seeUpdatedBookingText}</Text>
            </LinearGradient>
          </TouchableOpacity>
        </View>
      );
    }
}

HotelModificationThankYouPage.propTypes =
    {
      newCheckinDate: PropTypes.string.isRequired,
      newCheckoutDate: PropTypes.string.isRequired,
      newBookingID: PropTypes.string.isRequired,
      emailID: PropTypes.string.isRequired,
      action: PropTypes.string.isRequired,
      buttonGradientColor: PropTypes.array
    };

HotelModificationThankYouPage.defaultProps = {
  buttonGradientColor: ['#53B2FE', '#065AF3']
};

export default HotelModificationThankYouPage;

