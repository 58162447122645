import React from 'react';
import { View, Text, Image, StyleSheet } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
import { isNonEmpty } from '@mmt/legacy-commons/Common/utils/StringUtils';
import { INFO_ICON } from '../../../../../FlightImageConstants';
import { getFont } from '../../../../../../PostSalesStyles';

const WhyFareLockFailedCard = ({ title, description }) => {
  const { fsStyle, ...fonts } = getFont(true);
  return (
    <View style={[AtomicCss.marginBottom10, styles.cardBorder]}>
      <View style={[styles.infoCardWrapper]}>
        <View style={styles.headingIconWrapper}>
          <Image style={styles.headingIconStyle} source={INFO_ICON} />
        </View>
        <View style={styles.infoCardContent}>
          <Text
            style={[
              AtomicCss.defaultText,
              fonts.blackFontFamily,
              fsStyle.font14,
              AtomicCss.marginBottom15,
            ]}
          >
            {title}
          </Text>
          {isNonEmpty(description) && (
            <View style={[AtomicCss.flexRow, AtomicCss.marginBottom10]}>
              <Text
                style={[
                  AtomicCss.defaultText,
                  fsStyle.font14,
                  AtomicCss.flex1,
                  fonts.regularFontFamily,
                ]}
              >
                {description}
              </Text>
            </View>
          )}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  cardBorder: {
    borderTopColor: '#e4e4e4',
    borderBottomColor: '#e4e4e4',
    borderTopWidth: 1,
    borderBottomWidth: 1,
  },
  infoCardWrapper: {
    backgroundColor: '#fff',
    justifyContent: 'space-between',
    flexDirection: 'row',
    ...getPlatformElevation(2),
    marginBottom: 1,
  },
  infoCardContent: {
    paddingHorizontal: 8,
    paddingVertical: 15,
    flex: 1,
  },
  headingIconWrapper: {
    width: 46,
    alignItems: 'flex-start',
    top: 14,
    left: 16,
  },
  headingIconStyle: {
    width: 21,
    height: 20,
  },
});
export default WhyFareLockFailedCard;
