import React from 'react';
import { View, Text ,Image} from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import createStyles from './changeTravelDateCss';
import { getActionButton, isNotNullAndEmpty } from '../../utils/BusBookingDetailUtil';
import { getButtonStyle } from '../../../Common/commonUtil';
import HTMLViewWrapper from '../../../Common/HTMLViewWrapper';
import { getFont } from '../../../PostSalesStyles';
import { useTheme } from '../../../theme/theme.context';
import { CHANGE_TRAVEL_DATE_ICON } from '../../BusImageConstants';

function ChangeTravelDate({ response, card, handleClick }) {

  const { heading, subheading: subHeading, actionList } = card || {};
  const { fsStyle, ...fonts } = getFont();
  const {psTheme : theme} = useTheme();
  const styles = createStyles(theme, fonts);

  return (
    <View style={styles.cardBorder}>
      <View style={[styles.infoCardWrapper]}>
        <View style={styles.headingIconWrapper}>
          <Image style={styles.headingIconStyle} source={CHANGE_TRAVEL_DATE_ICON} />
        </View>
        <View style={styles.infoCardContent}>
          {!!heading && (
            <Text
              style={[
                AtomicCss.defaultText,
                fonts.blackFontFamily,
                AtomicCss.font14,
                AtomicCss.marginBottom15,
              ]}
            >
              {heading}
            </Text>
          )}
          {!!subHeading && <HTMLViewWrapper htmlContent={subHeading} />}
        </View>
      </View>
      <View style={isNotNullAndEmpty(actionList) ? getButtonStyle(actionList) : styles.hideView}>
        {getActionButton(card, response, handleClick)}
      </View>
    </View>
  );
}

export default ChangeTravelDate;
