import React from 'react';
import { View, Text, Image, Platform, TouchableOpacity } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import isEmpty from 'lodash/isEmpty';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { AndroidBackArrow, IOSBackArrow, PAYMENT_STATUS } from '../../../../../../HolidayBookingConstants';
import BaseButton from '../../../../../../../Common/BaseButton';
import { BUTTON_VARIANT } from '../../../../../../../Common/BaseButton';
import createStyles from './style';
import { getFont } from '../../../../../../../PostSalesStyles';
import { useTheme } from '../../../../../../../theme/theme.context';

const TopStatusCard = ({
  imageUrl,
  heading,
  description,
  descriptionKey,
  bookingId,
  bookingStateValue,
  buttons,
  note,
  goBack,
  handleButtonClick,
}) => {

  const {fsStyle,...fonts} = getFont();
  const { psTheme: theme } = useTheme();
  const styles = createStyles(fonts, theme);

  const getTopCardColor = (state) => {
    if (state === PAYMENT_STATUS.SUCCESS) {
      return [theme.color.lightGreen16, theme.color.lightGreen15];
    } else {
      return [theme.color.palePink, theme.color.palePink];
    }
  };

  return (
    <>
      <LinearGradient
        start={{
          x: 1.0,
          y: 0.0,
        }}
        end={{
          x: 0.0,
          y: 1.0,
        }}
        colors={getTopCardColor(bookingStateValue)}
        style={[styles.topStatusCard]}
      >
        <View>
          <TouchableOpacity style={[styles.backArrowWrapper, styles.topStatusBar]} onPress={goBack}>
            <Image
              style={Platform.OS === 'ios' ? [styles.IOSbackarrow] : [styles.backarrow]}
              source={Platform.OS === 'ios' ? IOSBackArrow : AndroidBackArrow}
            />
          </TouchableOpacity>

          <View>
            <Image
              style={[AtomicCss.marginTop30, AtomicCss.marginBottom10, styles.topIconStyle]}
              source={{ uri: imageUrl }}
            />
          </View>
        </View>
        <View style={styles.statusCardWrapper}>
          {!isEmpty(heading) && <Text style={[styles.statusHeadingTxt]}>{heading}</Text>}
          {!isEmpty(description) && <Text style={[styles.statusSubHeadTxt]}>{description}</Text>}
          {!isEmpty(descriptionKey) && !isEmpty(bookingId) && (
            <View style={[styles.bookingWrapper, AtomicCss.marginBottom16, AtomicCss.marginTop12]}>
              <Text style={styles.booingDtlsTxt}>{descriptionKey}</Text>
              <Text style={styles.booingDtlsbold}>{bookingId}</Text>
            </View>
          )}
        </View>
        {!isEmpty(buttons) && (
          <View style={styles.buttonWrapper}>
            {buttons.map((item) => (
              <BaseButton
                variant={BUTTON_VARIANT.CAPSULE}
                text={item.text}
                clickHandler={handleButtonClick}
                disabled={false}
                textStyle={[fsStyle.font16, AtomicCss.paddingHz24]}
              />
            ))}
          </View>
        )}
      </LinearGradient>
      {!isEmpty(note) && (
        <View style={styles.noteTextWrapper}>
          <Text style={styles.noteText}>{note}</Text>
        </View>
      )}
    </>
  );
};

export default TopStatusCard;
