import get from 'lodash/get';
import { findDaysBetweenDates } from '@mmt/legacy-commons/Common/utils/DateUtils';
import { trackOmniture } from '@mmt/legacy-commons/Common/utils/OmnitureTrackerUtils';
import { logMessage } from '@mmt/legacy-commons/Helpers/actionsLogger';
import { FLIGHT_CANCELLATION_TYPE } from '../../../PostSalesConstant';
import FlightEventsLogger from '../../modules/details/helper/FlightEventsLogger';
import {
  _trackDepartureDate,
  _getJourneyType,
  _trackFltBookingDate,
} from '../../FlightOmnitureTrackerUtils';

import { isApiSuccess } from '../CancellationValidationUtil';

class CancellationTrackingHelper {
  trackLoadEvent = (pageName, trackingBundle, shouldCheckValidation) => {
    try {
      FlightEventsLogger.loadEvent(pageName, trackingBundle);
      const cancelValidationErrorPageName = this.getValidationErrorPageName(
        pageName,
        trackingBundle.response,
      );
      if (cancelValidationErrorPageName && shouldCheckValidation) {
        pageName = ` ${cancelValidationErrorPageName}`;
      }
      this.trackLoadEventWithFullPartialFlag(pageName, trackingBundle, true, shouldCheckValidation);
    } catch (e) {
      logMessage(`${pageName}:exception while tracking loadEvent:${e}`);
    }
  };

  trackLoadEventWithFullPartialFlag = (
    pageName,
    trackingBundle,
    addFullPartial,
    shouldCheckValidation,
  ) => {
    try {
      FlightEventsLogger.loadEvent(pageName, trackingBundle);
      const params = this.getTrackingParams(
        pageName,
        trackingBundle,
        addFullPartial,
        shouldCheckValidation,
      );
      const trackingPageName = this.getTrackingPageName(
        pageName,
        trackingBundle,
        addFullPartial,
        shouldCheckValidation,
      );
      trackOmniture(trackingPageName, params, true);
    } catch (e) {
      logMessage(`${pageName}:exception while tracking loadEvent:${e}`);
    }
  };

  trackErrorEvent = (pageName, trackingBundle, supplierName) => {
    try {
      FlightEventsLogger.errorEvent(pageName, trackingBundle);
      const params = this.getTrackingParams(pageName, trackingBundle, true);
      const trackingPageName = `${this.getTrackingPageName(pageName, trackingBundle, true)}:error`;
      params.m_c22 = trackingBundle.error;
      if (supplierName) {
        params.m_c40 = supplierName;
      }
      params.m_v15 += ':error';
      params.pageName += ':error';
      trackOmniture(trackingPageName, params);
    } catch (e) {
      logMessage(`${pageName}:exception while tracking error:${e}`);
    }
  };

  trackClickEvent = (pageName, clickEvent, trackingBundle) => {
    FlightEventsLogger.clickEvent(pageName, clickEvent, trackingBundle);
    this.trackClickEventWithFullPartialFlag(pageName, clickEvent, trackingBundle, true);
  };

  trackClickEventWithFullPartialFlag = (pageName, clickEvent, trackingBundle, addFullPartial) => {
    try {
      FlightEventsLogger.clickEvent(pageName, clickEvent, trackingBundle);
      const params = this.getTrackingParams(pageName, trackingBundle, addFullPartial);
      const trackingPageName = this.getTrackingPageName(pageName, trackingBundle, addFullPartial);
      const clickEventName = `MI_${pageName}_${clickEvent}`;
      params.m_c54 = clickEventName;
      trackOmniture(trackingPageName, params);
    } catch (e) {
      logMessage(`${pageName}:exception while tracking clickEvent:${e}`);
    }
  };

  getTrackingParams(pageName, trackingBundle, addFullPartial, shouldCheckValidation) {
    const params = {};
    try {
      const trackingPageName = this.getTrackingPageName(
        pageName,
        trackingBundle,
        addFullPartial,
        shouldCheckValidation,
      );
      params.pageName = trackingPageName;
      params.m_v15 = trackingPageName;
      params.m_v39 = 'Y';

      params.m_v13 = this.getTypeOfCancellation(trackingBundle);

      if (trackingBundle.response) {
        params.m_v16 = trackingBundle.response.bookingID;
        params.m_c24 = trackingBundle.response.lobName;
        params.m_22 = trackingBundle.rfndReason;
        params.m_v5 = trackingBundle.response.bookingDateTime
          ? _trackFltBookingDate(trackingBundle.response.bookingDateTime.split('T')[0])
          : '';
        params.m_v7 = trackingBundle.response.bookingDateTime
          ? findDaysBetweenDates(trackingBundle.response.bookingDateTime, new Date()) + ` Days`
          : '';

        if (trackingBundle.response.trackingdata) {
          this.addParam(params, 'm_v6', trackingBundle.response.trackingdata.timetotravel);
          this.addParam(params, 'm_v40', trackingBundle.response.trackingdata.airlinecodes);
          this.addParam(params, 'm_v11', trackingBundle.response.trackingdata.cancellationpenalty);
          this.addParam(params, 'm_v46', trackingBundle.response.trackingdata.refundamount);
          this.addParam(params, 'm_v3', trackingBundle.response.trackingdata.origindestination);
        }
        if (trackingBundle.response.flightDetails) {
          params.m_v4 = _trackDepartureDate(
            trackingBundle.response.flightDetails.segmentGroupDetailList,
          );
          params.m_v9 = _getJourneyType(trackingBundle.response.flightDetails.journeyType);
        }
      }

      if (trackingBundle && trackingBundle.claimReason) {
        params.m_v13 = trackingBundle.claimReason;
      }
      params.m_c22 = trackingBundle && trackingBundle.ruleTrace ? trackingBundle.ruleTrace : '';
    } catch (e) {
      logMessage(`${pageName}:exception while tracking:${e}`);
    }
    return params;
  }

  getTypeOfCancellation(trackingBundle) {
    let typeOfCancellation =
      trackingBundle.flightCancellationType == FLIGHT_CANCELLATION_TYPE.SPECIAL_CLAIM
        ? 'SpecialClaim_'
        : 'Cancellation_';
    typeOfCancellation += trackingBundle.isFullSelection ? 'full' : 'partial';
    return typeOfCancellation;
  }

  getTrackingPageName(pageName, trackingBundle, shouldAddFullPartial, shouldCheckValidation) {
    if (trackingBundle.flightCancellationType === FLIGHT_CANCELLATION_TYPE.SPECIAL_CLAIM) {
      pageName = `SpecialClaim${pageName}_Auto`;
    } else {
      pageName = `Cancellation${pageName}`;
    }
    if (shouldAddFullPartial && !shouldCheckValidation) {
      pageName += trackingBundle.isFullSelection ? ':full' : ':partial';
    }
    return trackingBundle.isInternational
      ? `Mob:Customer support:Intl flight :${pageName}`
      : `Mob:customer support:Dom flight :${pageName}`;
  }

  addParam(paramMap, paramKey, paramValue) {
    if (paramValue) {
      paramMap[paramKey] = paramValue;
    }
    return paramMap;
  }

  getValidationErrorPageName(pageName, response) {
    try {
      const cancellationValidation = get(response, 'cancellationValidation', null);
      if (isApiSuccess(cancellationValidation)) {
        const segments = get(cancellationValidation, 'segments', []);
        if (segments && segments.every((seg) => seg.actionList && seg.actionList.length > 0))
          return 'Validation Error:Full';
        else if (segments && segments.some((seg) => seg.actionList && seg.actionList.length > 0))
          return 'Validation PartSeg Error';
        else if (
          segments &&
          segments.some(
            (seg) =>
              !seg.actionList &&
              seg.paxList &&
              seg.paxList.some((pax) => pax.actionList && pax.actionList.length > 0),
          )
        )
          return 'Validation PartPax Error';
      }
      return '';
    } catch (e) {
      logMessage(`${pageName}:exception while tracking:${e}`);
    }
  }
}

export default new CancellationTrackingHelper();
