/* eslint-disable indent,max-len */
import React from 'react';
import PropTypes from 'prop-types';
import {View, Text, Image, TouchableOpacity, StyleSheet} from 'react-native';
import Actions from '../../../navigation/postSalesNavigation';
import AtomicCss from '../../commonStyles/AtomicCss';
import {getSubTitleForCancellationHeader} from '../../utils/BusBookingDetailUtil';
import {headerIcon} from '../../BusImageConstants';
import { label } from '@mmt/post-sales/src/staticData/staticData';
import { getFont } from '../../../PostSalesStyles';
// eslint-disable-next-line import/no-unresolved

class StickyHeader extends React.Component {
    static navigationOptions = {
        header: null
    };

    GoBackHandler = () => {
        Actions.pop();
    }

    render() {
        const fonts = getFont(true);

        return (
            <View style={styles.WhiteStickyHeader}>
                <View style={styles.topStatusBar}>
                    <TouchableOpacity onPress={this.GoBackHandler}>
                        <Image style={styles.backarrow} source={headerIcon} />
                    </TouchableOpacity>
                    <View style={AtomicCss.marginLeft12}>
                        <Text style={[AtomicCss.blackText,
                            fonts.blackFontFamily, AtomicCss.marginBottom5, fonts.font14]}
                        >
                            {label('bus.cancellation.header')}
                        </Text>
                        <Text style={[AtomicCss.defaultTextColor, fonts.font12, fonts.regularFontFamily]}>
                            {getSubTitleForCancellationHeader(this.props.busDetails)}
                        </Text>
                    </View>
                </View>
            </View>
        );
        console.log('out of stickyheader');
    }
}

StickyHeader.propTypes = {
    busDetails: PropTypes.object.isRequired
};

const styles = StyleSheet.create({
    WhiteStickyHeader: {
        backgroundColor: '#fff',
        paddingTop: 10,
        paddingBottom: 10,
        elevation: 3,
        shadowColor: '#000',
        shadowOffset: {width: 0, height: 4},
        shadowOpacity: 0.3,
        shadowRadius: 2
    },
    topStatusBar: {
        flexDirection: 'row',
        paddingLeft: 10,
        paddingRight: 20,
        alignItems: 'center'
    },
    backarrow: {
        width: 16,
        height: 16,
        marginHorizontal: 10,
        marginVertical: 10
    }
});

export default StickyHeader;
