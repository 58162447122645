import React from 'react';
import { View, Text, Image } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import styles from './TravellerDtlsCss';
import {GUEST_ICON} from '../../../SelfDriveImageConstant';

class TravellerDtlsSection extends React.Component {

  render() {
    const { name, emailId, contactNo } = this.props.response.paxInfo;
    return (
      <View style={[AtomicCss.whiteBg, AtomicCss.marginBottom10, styles.elevation1]}>
        <View style={[styles.CardContentFull, AtomicCss.marginBottom20]}>
          <View style={[styles.CardContent]}>
            <Image style={styles.CardIcon} source={GUEST_ICON} />
            <Text style={[AtomicCss.blackFont, AtomicCss.blackText, AtomicCss.font14]}>
              {this.props.card.header}
            </Text>
          </View>
          <View style={[styles.marL10per, AtomicCss.paddingRight16]}>
            <Text style={[AtomicCss.font14, AtomicCss.boldFont, AtomicCss.blackText, AtomicCss.marginBottom8]}>{name}</Text>
            <View style={[AtomicCss.flexRow, AtomicCss.marginBottom5, AtomicCss.alignCenter]}>
              <Text style={[AtomicCss.flex1, AtomicCss.font14, AtomicCss.defaultText, AtomicCss.regularFont]}>{emailId}</Text>
            </View>
            <View style={[AtomicCss.flexRow, AtomicCss.alignCenter,AtomicCss.marginBottom10]}>
              <Text style={[AtomicCss.flex1, AtomicCss.font14, AtomicCss.defaultText, AtomicCss.regularFont]}>{contactNo}</Text>
            </View>
            {this.props.response.tripInfo.userDLText && <Text style={[AtomicCss.defaultText,AtomicCss.font14,AtomicCss.regularFont]}><Text style={AtomicCss.boldFont}>Driving License: </Text> {this.props.response.tripInfo.userDLText}</Text>}
          </View>
        </View>
      </View>
    );
  }
}

export default TravellerDtlsSection;
