import { StyleSheet } from 'react-native';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';

export const getStyle = (theme, fonts) => {
  const { color } = theme;
  return StyleSheet.create({
    textDiv: {
      width: 150,
    },
    mmtSelectWrapper: {
      paddingHorizontal: 16,
      marginBottom:5
    },
    cardHeadingWrapper: {
      marginBottom: 12,
      zIndex:2
    },
    cardTitleStyle: {
      resizeMode: 'contain',
      top: 2,
    },
    cardDetailsWrapper: {
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
      borderTopRightRadius: 8,
      overflow: 'hidden',
      ...getPlatformElevation(1),
      top: -12,
      paddingBottom: 12,
    },
    bgEffectsStyle: {
      height: 125,
      borderRadius: 10,
      resizeMode: 'cover',
      paddingVertical: 12,
    },
    cardTopSection: {
      paddingHorizontal: 12,
      marginTop: 12,
      marginBottom: 12,
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    detailHeading: {
      ...fonts.blackFontFamily,
      color: color.defaultTextColor,
      fontSize: fonts.fontSize.font16,
    },
    detailInfo: {
      ...fonts.regularFontFamily,
      color: color.defaultTextColor,
      fontSize: fonts.fontSize.font12,
      marginBottom: 15,
    },
    lineGradientStyle: {
      height: 5,
      width: '64%',
      zIndex: -10,
      position: 'absolute',
      bottom: -3,
      left: 125,
    },
    benefitsCards: {
      backgroundColor: color.white,
      paddingHorizontal: 6,
      paddingVertical: 10,
      flexDirection: 'row',
      ...getPlatformElevation(1),
      width: 203,
      borderRadius: 8,
      marginRight: 10,
      alignItems: 'center',
    },
    lastCard: {
      backgroundColor: color.aliceBlue,
      width: 92,
      paddingLeft: 15,
    },
    clickableText: {
      fontSize: fonts.fontSize.font12,
      ...fonts.boldFontFamily,
      color: color.azure,
    },
    iconWrapper: {
      width: 32,
      height: 32,
      marginRight: 8,
    },
    benefitsIcon: {
      width: 32,
      height: 32,
    },
    benefitsText: {
      fontSize: fonts.fontSize.font14,
      ...fonts.regularFontFamily,
      color: color.defaultTextColor,
    },
    firstCard: {
      marginLeft: 12,
    },
    cardTopDetails: {
      flex: 1,
    }
  });
};
