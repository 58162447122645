import React from 'react';
import {View, Text, TouchableWithoutFeedback} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import PropTypes from 'prop-types';
import {appendRupeeSymbol} from '../../hotel/details/utils/HotelBookingDetailUtil';
import getStyles from '../styles/BusMamiCss';


class BusPenaltyDetails extends React.Component {
    static navigationOptions = {
      header: null
    };

    constructor(props) {
      super(props);
      this.busBookingInfo = this.props.response.busBookingInfo;
      this.bookingPaymentDetail = this.props.response.bookingPaymentDetail;
      this.penalty = this.props.penalty;
    }

    onConfirmClickHandler = (action) => {
      if (typeof this.props.onConfirmClick === 'function') {
        this.props.onConfirmClick(action.target.value);
      }
    };

    render() {
      const styles = getStyles();
      return (
        <View style={[styles.MamiCard, styles.mar10Bt]}>
          <View style={styles.CardContentFull}>
            <View style={[styles.row, styles.paddingBottom8, styles.pad10Top]}>
              <Text style={[styles.HotelLabel, styles.boldFont, styles.font14, styles.BlackText]}>
               Total Amount Paid
              </Text>
              <View style={styles.marginLeftAuto}>
                <Text style={[styles.font14, styles.boldFont, styles.BlackText]}>{appendRupeeSymbol(Math.round(this.penalty.sellingPrice + this.penalty.insuranceAmount))}</Text>
              </View>
            </View>
            <View style={[styles.row, styles.paddingBottom8]}>
              <Text style={[styles.HotelLabel, styles.boldFont, styles.font14, styles.BlackText]}>
               Cancellation Charges
              </Text>
              <View style={styles.marginLeftAuto}>
                <Text style={[styles.font14, styles.boldFont, styles.BlackText]}>- {appendRupeeSymbol(Math.round(this.penalty.penaltyAmount + this.penalty.ecouponAmount + this.penalty.markupAmount))}</Text>
              </View>
            </View>
            {this.renderMMTFees()}
            {this.renderPenaltyAmount()}
            {this.renderCouponAmount()}
          </View>
          <View style={{padding: 15}}>
            <View style={[styles.row, styles.paddingBottom8]}>
              <Text style={[styles.HotelLabel, styles.boldFont, styles.font14, styles.lightGreenTxt]}>
               REFUND AMOUNT
              </Text>
              <View style={styles.marginLeftAuto}>
                <Text style={[styles.font14, styles.boldFont, styles.lightGreenTxt]}> {appendRupeeSymbol(Math.round(this.penalty.refundAmount))}</Text>
              </View>
            </View>
            <Text style={[styles.font13, styles.regularFont]}>This includes the amount that will be sent to your bank and/or wallet.</Text>
          </View>
          <TouchableWithoutFeedback onPress={this.onConfirmClickHandler}>
            <LinearGradient
              start={{x: 1.0, y: 0.0}}
              end={{x: 0.0, y: 1.0}}
              colors={['#065af3', '#53b2fe']}
              style={[styles.CapsuleBtnFill, styles.mar150Bt]}
            >
              <Text style={[styles.WhiteText, styles.regularFont, styles.CenterText, styles.transperantBgrnd]}>CONTINUE</Text>
            </LinearGradient>
          </TouchableWithoutFeedback>
        </View>
      );
    }


    renderMMTFees() {
      if (Math.round(this.penalty.markupAmount) > 0) {
        return (
          <View style={[styles.row, styles.paddingBottom8]}>
            <Text style={[styles.HotelLabel, styles.boldFont, styles.font12, styles.lightGrey]}>
            MMT Service Charges
            </Text>
            <View style={styles.marginLeftAuto}>
              <Text
                style={[styles.font12, styles.regularFont, styles.lightGrey]}
              > {appendRupeeSymbol(Math.round(this.penalty.markupAmount))}
              </Text>
            </View>
          </View>
        );
      }
      return null;
    }

    renderPenaltyAmount() {
      if (Math.round(this.penalty.penaltyAmount) > 0) {
        return (
          <View style={[styles.row, styles.paddingBottom8]}>
            <Text style={[styles.HotelLabel, styles.boldFont, styles.font12, styles.lightGrey]}>
            Bus Operator Penalty
            </Text>
            <View style={styles.marginLeftAuto}>
              <Text style={[styles.font12, styles.regularFont, styles.lightGrey]}> {appendRupeeSymbol(Math.round(this.penalty.penaltyAmount))}</Text>
            </View>
          </View>
        );
      }
      return null;
    }


    renderCouponAmount() {
      if (Math.round(this.penalty.ecouponAmount) > 0) {
        return (
          <View style={[styles.row, styles.paddingBottom8]}>
            <Text style={[styles.HotelLabel, styles.boldFont, styles.font12, styles.lightGrey]}>
            MMT Discount
            </Text>
            <View style={styles.marginLeftAuto}>
              <Text style={[styles.font12, styles.regularFont, styles.lightGrey]}> {appendRupeeSymbol(Math.round(this.penalty.ecouponAmount))}</Text>
            </View>
          </View>
        );
      }
      return null;
    }
}

BusPenaltyDetails.propTypes =
  {
    response: PropTypes.object.isRequired,
    penalty: PropTypes.object.isRequired,
    onConfirmClick: PropTypes.func.isRequired
  };


export default BusPenaltyDetails;
