import { StyleSheet } from "react-native";
import { DEFAULT_THEME } from "apps/post-sales/src/theme/default.theme";

export default <T extends typeof DEFAULT_THEME>(theme:T, fonts: any) => StyleSheet.create({
    myRequestCard: {
        borderRadius: 16,
        backgroundColor: theme.color.white,
        paddingVertical: 14,
        paddingHorizontal: 16,
        marginHorizontal: 10,
        flexDirection: 'row',
        alignItems: 'center',
        zIndex: 1,
    },
    rightArrowStyle: {
        width: 8,
        height: 12,
    },
    requestIconStyle: {
        width: 22,
        height: 22,
        resizeMode: 'contain',
    },
    dot: {
        marginHorizontal: 8,
        width: 4,
        height: 4,
        backgroundColor: theme.color.black,
        borderRadius: 4,
    },
    openStates: {
        color: theme.color.red29,
       ...fonts.boldFontfamily,
        fontSize: fonts.fontSize.font16,

    },
    myRequestTextStyle: {
        fontSize: fonts.fontSize.font16, 
        ...fonts.boldFontFamily, 
        color: theme.color.black
    }
});
