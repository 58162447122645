import React, { useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import { RAIL_TG_PREMIUM_RETURN_URL} from '../../utils/NetworkUtils';
import SimpleHeader from '@mmt/legacy-commons/Common/Components/Header/SimpleHeader.android';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import {
    DEAFULTREFUNDMODE
} from '../../PostSalesConstant';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import ProgressView from '../../Common/ProgressView/ProgressView';
import { getStaticData } from '../../staticData/staticData';
import Actions from '../../navigation/postSalesNavigation';
import StickyHeaderBack from '../components/StickyHeader/StickyHeaderBack';
import AtomicCss from '../commonStyles/AtomicCss';

import { getFont } from '../../PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import {createStyles} from './previewTGChargesCss'
import { ScrollView } from 'react-native';
import TGRefundInfoCard from './TGRefundInfoCard'
import RecBlueBtn from '../components/buttons/RecBlueBtn';
import ReviewStepHeader from '../components/ReviewStepHeader/ReviewStepHeader';
import CancellationBreakupDtls from '../components/RefundBreakUp/CancellationBreakupDtls';
import { RailBookingDetails, TGCancelResponse } from '../types';
import OverlayAlert from '../components/OverlayAlert/OverlayAlert';
import { checkViewState, fareBreakUpObject, getDeductions, getPaidComponent, getPreviewRequest, isRefundZero } from './adapter/data'
import useApi from '../../Common/useApi';
import NoInternetView from '@mmt/legacy-commons/Common/Components/Error/NoInternetView';
import { UseApiResponse } from '../../types';
import { RAIL_TG_CANCEL_API } from '../details/constants';
interface Props {
    bookingDetails: RailBookingDetails
    isFullCancellation: boolean;
    refundOption: string;
    actionKey: string;
    reason: string;
}
  

const PreviewTGCharges : React.FC<Props> = ({ bookingDetails, isFullCancellation, actionKey, reason}) => {

    const [viewState, setViewState] = useState<string>(ViewState.LOADING);
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [errorText, setErrorText] = useState<string>("");

    const [previewResponseInProgress, previewResponse, previewResponseAPI] : UseApiResponse<TGCancelResponse> = useApi(RAIL_TG_PREMIUM_RETURN_URL) as UseApiResponse<TGCancelResponse>;
    const fonts = getFont();
    const { psTheme: theme } = useTheme();
    const styles =  createStyles(theme,fonts);

    useEffect( ()=>{
        previewResponseAPI.httpPost({
            body: getPreviewRequest(bookingDetails, actionKey, isFullCancellation)
        })
    },[])


    useEffect( () => {
        if(previewResponse){
            checkViewState(previewResponse, RAIL_TG_CANCEL_API.PREVIEW, setViewState, setErrorText);
        }
    },[previewResponse])


    async function retry() {
        setViewState(ViewState.LOADING);
        if(previewResponseAPI){
            return previewResponseAPI.httpPost({body : getPreviewRequest(bookingDetails, actionKey, isFullCancellation) });
        }
    }    
    
    const goBackToDetails = () => {
        Actions.popTo("railBookingDetail");
    }

    const renderProgressView = () => {
        const staticData = getStaticData();
        const { loadingText } = staticData;
        return (<ProgressView message={loadingText ? loadingText : ""} />);
    }
   

    const onClickHandler = () => {
        if ( previewResponse && !isRefundZero(previewResponse)) {
            pushRefundModeView();
        } else {
            setShowAlert(true);
        }
    }
    const pushRefundModeView = () => {
        Actions.reviewTGRefundMode({
            bookingDetails: bookingDetails,
            isFullCancellation: isFullCancellation,
            previewObj: previewResponse,
            actionKey: actionKey,
            bookingID: bookingDetails.bookingID,
            reason: reason,
            isTGPremiumRefundCase: false
        });
    }

    const renderMainView = () => {
        const staticData = getStaticData();
        const {initiateRefundText, travelWithRAC, yesCancelText, noGoBackText, youSureText, youWishToCancelText, trainCancellationText } = staticData;
        const cancelTrainTicketsText = trainCancellationText?.additionalText?.cancelTrainTicketsText
        const {railTgCardData: cardData} = previewResponse || {};
        return (
            previewResponse ? 
            <View style={styles.CancellationWrapper}>
                <StickyHeaderBack
                    trainDetails={bookingDetails ? bookingDetails.train : null}
                    heading = {cancelTrainTicketsText}
                />
                 <ScrollView style={AtomicCss.greyBg}>
                    <ReviewStepHeader currentIndex={1} />
                    {cardData && <TGRefundInfoCard
                        cardData={cardData}
                        isPreview={true}
                    />}
                    <CancellationBreakupDtls
                        fareBreakUpObject={fareBreakUpObject(previewResponse)}
                        isFullCancellation={true}
                        paidComponent={getPaidComponent(previewResponse)}
                        deductionComponent={getDeductions(previewResponse)}
                        isRefundZero={isRefundZero(previewResponse)}
                        penaltyResponse={previewResponse}
                    />
                   
                </ScrollView>
                <RecBlueBtn
                    btntext={initiateRefundText}
                    onPressHandler={ onClickHandler }
                />
                {showAlert && isRefundZero(previewResponse) &&
                <OverlayAlert
                    positiveBtnText={yesCancelText}
                    negativeBtnText={noGoBackText}
                    positiveBtnOnTap={cancelBookingBtnTapped}
                    negativeBtnOnTap={backButtonTapped}
                    titleText={youSureText}
                    heading2Text={youWishToCancelText}
                    subTitleText={previewResponse?.ticketCancellationPreview?.refundTitleMessage}
                    bgTapAction={backButtonTapped}
                />}
            </View> : null
        );
    }
    const cancelBookingBtnTapped = () => {
        setShowAlert(false);
        Actions.commitTGCancel({
            bookingDetails: bookingDetails,
            refundOption: DEAFULTREFUNDMODE,
        });
    }
    const backButtonTapped = () => {
        setShowAlert(false);
    }
    const renderErrorView = () => {
        const staticData = getStaticData();
        const { goBackToBookingText, backText } = staticData;
        return (
            <View style={ AtomicCss.flex1 }>
            <SimpleHeader
                title={backText}
                iconPress={goBackToDetails}
            />
            <ErrorView
                showRetry
                buttonLabel={goBackToBookingText}
                message={errorText}
                onRetry={goBackToDetails}
            />
            </View>
        );
    }
    const renderNoNetworkView = () => {
        const staticData = getStaticData();
        const { backText, tryAgainText} = staticData ;
         return <View style={ AtomicCss.flex1}>
          <SimpleHeader title={backText} iconPress={goBackToDetails} />
          <NoInternetView
            buttonText={tryAgainText}
            onRetry={() => {
              setViewState( ViewState.LOADING );
              retry();
            }}
          />
        </View>
    };

    return (
        <View style={ AtomicCss.flex1 }>
          {viewState === ViewState.NO_INTERNET && renderNoNetworkView()}
          {viewState === ViewState.LOADING && renderProgressView()}
          {viewState === ViewState.SHOW_DETAIL && renderMainView()}
          {viewState === ViewState.ERROR && renderErrorView()}
        </View>
      );
}

export default PreviewTGCharges;