import React from 'react';
import PropTypes from 'prop-types';
import Actions from '../../../navigation/postSalesNavigation';
import {isIntlHotelBooking} from '../utils/HotelBookingDetailUtil';
import HotelBookingTrackingHelper from '../../HotelBookingTrackingHelper';
import ProgressView from '../../../Common/ProgressView/ProgressView';
import NoInternetView from '@mmt/legacy-commons/Common/Components/Error/NoInternetView';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import ViewStates from '../utils/ViewStates';
import BasePage from '../../../Common/PostSalesBasePage';
import logAction from '@mmt/legacy-commons/Helpers/actionsLogger';
import { getStaticData } from "../../../staticData/staticData"
import { themeColors } from '../../../PostSalesStyles';


/**
 * This class provides some common methods like:
 * renderProgressView : Display progress view along with customize message
 *
 * renderNoNetworkView :Display no internet available view along with retry btn
 *                      to invoke the api call again
 *
 * renderErrorView: Shows error screen ,allowing user to invoke api call
 *
 * onBackPress : Backs user to previous screen ,override if you want
 *               to change its behaviour
 *
 *  Pass pageName in constructor which is as same as needed for
 *              omniture logging and routerKeyName which is same as
 *              key defined in AppRouter.js
 *
 *  Call super of componentDidMount if overriding
 * fetchData : method to be implemented by child classes in order to invoke their API
 *
 */
class PostSalesHotelsBaseClass extends BasePage {
  static navigationOptions = {
    header: null

  };

  constructor(props,routerKeyName, pageName) {
    super(props,routerKeyName);
    this.pageName = pageName;
    this.state = {
      viewState:''
    };
    this.trackingPageName='';
  }

  componentDidMount() {
    super.componentDidMount();
    this.trackPageLoad(this.pageName);
  }

  renderProgressView = (message) => (<ProgressView message={message}/>);
  renderNoNetworkView = () => (
    <NoInternetView
      onRetry={() => {
        this.setState({viewState: ViewStates.LOADING});
        this.fetchData();
      }}
    />);


  fetchData() {
    // to be implemented by child classes
  }

  renderErrorView = () => {
    const {
      errorText:{
        somethingWentWrongTryAgainError
      },
      tryAgainText
    } = getStaticData();
    return <ErrorView
      showRetry
      onRetry={() => {
        this.setState({viewState: ViewStates.LOADING});
        this.fetchData();
        this.trackError();
      }}
      message={somethingWentWrongTryAgainError}
      buttonLabel={tryAgainText}
      buttonGradient={themeColors.gradientBtnColor}
    />;
  }

  trackPageLoad() {
    this.trackingPageName = isIntlHotelBooking(this.props.response?.lobCode)
      ? `Mob:customer support:Hotel International:${this.pageName}`
      : `Mob:customer support:Hotel Domestic:${this.pageName}`;
    HotelBookingTrackingHelper
      .trackLoadEvent(this.trackingPageName, this.props.response);
  }

  trackError() {
    this.trackingPageName = isIntlHotelBooking(this.props.response?.lobCode)
      ? `Mob:customer support:Hotel International:${this.pageName}:Error`
      : `Mob:customer support:Hotel Domestic:${this.pageName}:Error`;
    HotelBookingTrackingHelper.trackErrorEvent(this.trackingPageName, this.props.response);
  }


  onBackPress = () => {
    Actions.pop();
    logAction(`${this.pageName}onBackPress`);
  };

}

PostSalesHotelsBaseClass.propTypes = {
  response: PropTypes.object.isRequired,
  message: PropTypes.string
};
PostSalesHotelsBaseClass.defaultProps = {
  message: 'Loading ...'
};

export default PostSalesHotelsBaseClass;
