import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isNull } from 'lodash';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import styles from './CancellationCardCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { imageTransformY } from '../../../../../utils/PostSaleUtil';

import {
  CANCELLED_FLIGHT_ICON,
  DB_ICON,
  ZC_ICON,
  RIGHT_ARROW,
  GREY_RIGHT_ARROW,
  SPECIAL_CLAIM_ICON,
  CFAR_ICON,
} from '../../../../FlightImageConstants';
import { getFont } from '../../../../../PostSalesStyles';
import { RefNameConst } from '../../FlightBookingDetailsConstant';
import Button from '../../../../../Common/Button';

const CancellationCard = ({
  cancelCardDetailInfo,
  cancellationButtonClick,
  dbMessage,
  zcGlobalMsg,
  normalMsgObj,
  imagePath,
  pageName,
  isCfarBooking,
  refElement,
  gccBooking
}) => {
  const { fsStyle, ...fonts } = getFont(true);
  const getSpecialClaimInfo = (cancellationAvailable) => {
    if (
      cancelCardDetailInfo &&
      cancelCardDetailInfo.specialClaimData &&
      !isEmpty(cancelCardDetailInfo.specialClaimData.message)
    ) {
      const { message, clickableText, heading, action } = cancelCardDetailInfo.specialClaimData;
      const splitMsg1 = message.split('<');
      const splitMsg2 = message.split('>');
      const result = [];
      result.push(splitMsg1[0]);
      result.push(clickableText);
      if (splitMsg2.length > 1) {
        result.push(splitMsg2[1]);
      }
      const displayMsg = result.join(' ');
      return (
        <View style={styles.cancSpecialSection}>
          {cancellationAvailable && (
            <View style={styles.dividerSection}>
              <View style={[styles.semiCircleStyle, styles.leftSemiCircle]} />
              <View style={[styles.semiCircleStyle, styles.rightSemiCircle]} />
            </View>
          )}
          <View style={AtomicCss.flexRow}>
            <View style={styles.headingIconWrapper}>
              <Image
                style={[styles.headingfltIconStyle, imageTransformY]}
                source={SPECIAL_CLAIM_ICON}
              />
            </View>
            <View style={styles.infoCardContent}>
              <Text
                style={[
                  AtomicCss.defaultText,
                  fonts.blackFontFamily,
                  fsStyle.font14,
                  AtomicCss.marginBottom15,
                  AtomicCss.alignLeft,
                ]}
              >
                {heading}{' '}
              </Text>
              <Text
                style={[
                  fonts.regularFontFamily,
                  fsStyle.font14,
                  AtomicCss.defaultText,
                  AtomicCss.lineHeight20,
                  styles.canInfoTxt,
                  AtomicCss.alignLeft,
                ]}
              >
                {displayMsg}
              </Text>
            </View>
          </View>
          {!isEmpty(action) && (
            <TouchableOpacity
              style={[styles.redirectBtnWrapper, styles.raiseRqstBtn]}
              activeOpacity={0.8}
              onPress={() => {
                if (action.type === 'REFUND_REQUEST') {
                  cancellationButtonClick(
                    { actionId: 'SPECIAL_CLAIM_TEXT' },
                    pageName,
                    false,
                    false,
                  );
                }
              }}
            >
              <Text style={[styles.linkBtnTxt, fonts.boldFontFamily, fsStyle.font14]}>
                {action.title}
              </Text>
              <Image style={[styles.rightArrowIcon, imageTransformY]} source={RIGHT_ARROW} />
            </TouchableOpacity>
          )}
        </View>
      );
    }
    return null;
  };

  const actionItemView = () => {
    const views = [];
    if (cancelCardDetailInfo.actionItemInfoList) {
      cancelCardDetailInfo.actionItemInfoList.forEach((item) => {
        const disableLinkItemTxt = item.disabled ? [styles.disableLinkItemTxt] : '';
        const isLinkDisabled = item.disabled;
        if (item.actionText) {
          views.push(
            <TouchableOpacity
              style={styles.redirectBtnSection}
              disabled={isLinkDisabled}
              activeOpacity={0.8}
              onPress={() => cancellationButtonClick(item, pageName, false, true)}
            >
              <View style={styles.redirectBtnWrapper}>
                <View style={{ flex: 9.5 }}>
                  <Text
                    style={[
                      styles.linkBtnTxt,
                      disableLinkItemTxt,
                      AtomicCss.alignLeft,
                      fonts.boldFontFamily,
                      fsStyle.font14,
                    ]}
                  >
                    {item.actionText}
                  </Text>
                  {!isEmpty(item.infoMessage) && (
                    <Text
                      style={[
                        styles.linkinfoTxt,
                        AtomicCss.alignLeft,
                        fonts.regularFontFamily,
                        fsStyle.font12,
                      ]}
                    >
                      {item.infoMessage}
                    </Text>
                  )}
                  {!isEmpty(item.actionSubText) && (
                    <Text
                      style={[
                        styles.actionBtnSubText,
                        AtomicCss.alignLeft,
                        fonts.regularFontFamily,
                        fsStyle.font12,
                      ]}
                    >
                      {item.actionSubText}
                    </Text>
                  )}
                </View>
                <View style={{ flex: 0.5 }}>
                  {!isLinkDisabled ? (
                    <Image style={[styles.rightArrowIcon, imageTransformY]} source={RIGHT_ARROW} />
                  ) : (
                    <Image
                      style={[styles.rightArrowIcon, imageTransformY]}
                      source={GREY_RIGHT_ARROW}
                    />
                  )}
                </View>
              </View>

              {!isEmpty(item.tag) && (
                <View style={styles.linkTagWrapper}>
                  <Text style={[styles.linkTagtxt, fonts.regularFontFamily, fsStyle.font12]}>
                    {item.tag}
                  </Text>
                </View>
              )}
            </TouchableOpacity>,
          );
        }
        if (item.buttonText){
          views.push(
            <View style={[styles.redirectBtnSection, AtomicCss.paddingAll12]}>
              <View style={AtomicCss.alignSelfCenter}>
                <Button
                  clickHandler={() => cancellationButtonClick(item)}
                  btnTxt={item.buttonText}
                  btnBg="whiteWithBorder"
                  btnWidth={230}
                  buttonTextCss={fsStyle.font14}
                />
              </View>
            </View>
          );
        }
      });
    }
    return views.length ? views : null;
  };

  const onlySpecialClaimAction = () => {
    if (
      cancelCardDetailInfo &&
      cancelCardDetailInfo.actionItemInfoList &&
      cancelCardDetailInfo.actionItemInfoList.length === 1 &&
      cancelCardDetailInfo.actionItemInfoList[0].actionId === 'SPECIAL_CLAIM'
    ) {
      return true;
    }
    return false;
  };

  const getCancellationIcon = () => {
    const imageIconStyle = {};

    if (dbMessage) {
      imageIconStyle.style = styles.DBIconStyle;
      imageIconStyle.icon = DB_ICON;
    } else if (zcGlobalMsg) {
      imageIconStyle.style = styles.ZCIconStyle;
      imageIconStyle.icon = ZC_ICON;
    } else if (isCfarBooking) {
      imageIconStyle.style = styles.cfarInfoIcon;
      imageIconStyle.icon = CFAR_ICON;
    } else {
      imageIconStyle.style = styles.headingIconStyle;
      imageIconStyle.icon = CANCELLED_FLIGHT_ICON;
    }
    return imageIconStyle;
  };

  const imageStyle = getCancellationIcon();
  const normalMsgColor = !isEmpty(normalMsgObj?.colorClass)
    ? AtomicCss[`${normalMsgObj.colorClass}`]
    : {};
  const cancelCardActions = actionItemView();
  const cancellationAvailable = cancelCardActions && !onlySpecialClaimAction() ? true : false;
  return (
    <View
      style={[AtomicCss.marginBottom10]}
      ref={(e) => refElement(e, RefNameConst.FLIGHT_CANCELLATION_REF)}
    >
      {cancellationAvailable && (
        <View style={[styles.infoCardWrapper]}>
          <View style={styles.headingIconWrapper}>
          {!isCfarBooking && <Image style={imageStyle.style} source={imageStyle.icon} />}
          </View>
          <View style={styles.infoCardContent}>
          {(isCfarBooking && !!imagePath) ?
            <Image style={gccBooking ? styles.cfarImageGCC : styles.cfarImage} source={{uri:imagePath}} /> :
            <Text
              style={[
                AtomicCss.defaultText,
                fonts.blackFontFamily,
                fsStyle.font14,
                AtomicCss.alignLeft,
              ]}
            >
              {cancelCardDetailInfo.cardHeaderText}
            </Text>
          }
            {dbMessage && (
              <Text
                style={[
                  styles.greenTxt,
                  fsStyle.font14,
                  fonts.regularFontFamily,
                  AtomicCss.marginBottom10,
                  AtomicCss.alignLeft,
                ]}
              >
                {dbMessage.globalMessage}
              </Text>
            )}
            {dbMessage &&
              dbMessage.localMessage &&
              dbMessage.localMessage.map((message) => (
                <Text
                  style={[
                    styles.greenTxt,
                    fsStyle.font14,
                    fonts.regularFontFamily,
                    AtomicCss.marginBottom10,
                    AtomicCss.alignLeft,
                  ]}
                >
                  {message}
                </Text>
              ))}

            {!isEmpty(zcGlobalMsg) && (
              <Text
                style={[
                  styles.greenTxt,
                  fsStyle.font14,
                  fonts.regularFontFamily,
                  AtomicCss.marginBottom10,
                  AtomicCss.alignLeft,
                ]}
              >
                {zcGlobalMsg}
              </Text>
            )}
            {normalMsgObj && (
              <Text
                style={[
                  fonts.regularFontFamily,
                  fsStyle.font14,
                  AtomicCss.defaultText,
                  AtomicCss.lineHeight20,
                  normalMsgColor,
                  AtomicCss.alignLeft,
                ]}
              >
                {normalMsgObj.descObjText}
              </Text>
            )}
          </View>
        </View>
      )}
      {cancellationAvailable ? cancelCardActions : null}
      {getSpecialClaimInfo(cancellationAvailable)}
    </View>
  );
};

CancellationCard.propTypes = {
  cancelCardDetailInfo: PropTypes.object.isRequired,
  cancellationButtonClick: PropTypes.func.isRequired,
  dbMessage: PropTypes.object,
  zcGlobalMsg: PropTypes.string,
  normalMsgObj: PropTypes.object,
  pageName: PropTypes.string.isRequired,
};

CancellationCard.defaultProps = {
  dbMessage: undefined,
  zcGlobalMsg: undefined,
  normalMsgObj: undefined,
};

export default CancellationCard;
