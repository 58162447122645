import React from 'react';
import { View, Image, Text, TouchableOpacity} from 'react-native';
import createStyle from './CrossLobBenefitCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { useTheme } from '../../../../theme/theme.context';
import HTMLView from 'react-native-htmlview';
import isEmpty from 'lodash/isEmpty';
import { getActionButton, handleClickEvent, isNotNullAndEmpty } from '../../utils/HotelBookingDetailUtil';
import { getFont, ps_colors } from '../../../../PostSalesStyles';
import HotelBookingTrackingHelper from '../../../HotelBookingTrackingHelper';
import Actions from '../../../../navigation/postSalesNavigation';
import { WHITE_LEFT_ARROW_ICON } from '../../../HotelImageConstants';
import HotelBookingDetailsConstant from '../../HotelBookingDetailsConstant';

interface CrossLobBenefitCardProps {
  response:{
    crossLobForexBuy: ICrossLobForexBuy
  }
  card: ICard
  pageName: boolean
  onViewCrossLobBenefitTermsClick: (terms: ITerms) => void
}

interface ITerms {
  title: string
  list: string[]
  link: string
}

interface ICard {
  actionList: Array<IActionList>
  bookingType: string
  cardId: string
  cardRank: number
  pageNumber: number
}

interface IActionList {
  actionFamily: string
  actionId: string
  actionLabeltext: string
  actionRank: string
  actionType: string
  bookingType: string
}

interface ICrossLobForexBuy{
  title: string,
  titleColor: string,
  tagname: string
  description: string
  flyerIconUrl: string
  offerIconUrl: string
  offerLink: string
  forexBenefits: {
    title: string
    list: string[]
  },
  terms:ITerms
}


const getHtmlStyles = (fonts, theme) => {
  return {
      span: {
          color: theme.color.defaultTextColor,
          fontSize: fonts.fontSize.font12,
          marginVerticle:5,
      }
  }
}

const CrossLobBenefitCard = ({ response, card, pageName}: CrossLobBenefitCardProps) => {
  const { fsStyle, ...fonts } = getFont();
  const { psTheme: theme } = useTheme();
  const styles = createStyle(theme);
  const htmlStyle = getHtmlStyles(fonts, theme);

  const { title, titleColor, tagname, description, flyerIconUrl, offerLink, offerIconUrl, forexBenefits, terms } = response.crossLobForexBuy || {} 

  const showTermsAndCondition = (terms: ITerms) => {
    HotelBookingTrackingHelper.trackClickEvent(pageName, HotelBookingDetailsConstant.CROSSLOB_VIEW_TERMS_CLICKED, response)
    Actions.openPsWebView({
      url: terms.link,
      headerIcon: WHITE_LEFT_ARROW_ICON,
      headerText: ''
    });
  }

  const handleClick = (action: IActionList) => {
    handleClickEvent(card, action, response, pageName);
  };

  return (
    <View style={[styles.cardWrapper]}>
      {!isEmpty(tagname) &&
        <View style={AtomicCss.flexRow}>
          <Image source={{uri: flyerIconUrl}} style={styles.flyrDealOfferIcon} />
          <View style={[styles.flyerStyleWrapper]}>
            <Text style={[fsStyle.font12, fonts.boldFontFamily, AtomicCss.paddingHz5, styles.flyerTagName]}>{tagname}</Text>
          </View>
        </View>
      }
      {!isEmpty(title) &&
        <View style={[AtomicCss.marginTop10, AtomicCss.flexRow]}>
          {!isEmpty(offerIconUrl) && <Image source={{uri: offerIconUrl}} style={[styles.cashbackOfferIcon, AtomicCss.marginRight5]} />}
          <Text style={[ !isEmpty(titleColor) ? {color: titleColor } : {color: theme.color.defaultTextColor}, fonts.blackFont, fonts.blackFontFamily, fsStyle.font14, AtomicCss.alignLeft]}>
            {title}
          </Text>
        </View>
      }
      {!isEmpty(description) && (
        <View style={[AtomicCss.displayFlex, AtomicCss.flexWrap]}>
          <HTMLView stylesheet={htmlStyle} value={description} />
          {!isEmpty(terms?.title) && !isEmpty(terms?.link) &&
            <Text>
                <TouchableOpacity onPress={() => showTermsAndCondition(terms)}>
                  <Text style={[AtomicCss.azure, fonts.boldFontFamily, fsStyle.font12, AtomicCss.lineHeight12, AtomicCss.marginTop5]}>{terms.title}</Text>
                </TouchableOpacity>
            </Text>
          }
        </View>
      )}
      {forexBenefits && !isEmpty(forexBenefits.title) && !isEmpty(forexBenefits.list) &&
        <Text style={[styles.flyrDealInfoHead, fonts.boldFontFamily, fsStyle.font12]}>{forexBenefits.title}
          <Text style={[styles.flyerDealInfoDescription, fonts.regularFontFamily, fsStyle.font12]}> {forexBenefits.list.join(', ')}</Text>
        </Text>
      }
      {card && !isEmpty(offerLink) && isNotNullAndEmpty(card.actionList) &&
        getActionButton(card, response, handleClick)}
    </View>
  )
}
export default CrossLobBenefitCard