import {
  TravelerInfoCard,
  PaymentCard,
  ImportantInfoCard,
  TrackRefundCard,
  MSRCard,
  PageHeader
} from '@core_app_common/Cards';
import {
  ManageBookingCard,
  ItineraryCard,
  BookingStatusCard,
  TravelInsuranceCard,
  AncillaryAddOnCard,
  MmtConnectFlight,
  TermsAndConditionCard,
  PriceLockBookingCard,
  RecommnedationCard,
  AddonsCard,
  PartPaymentCard,
} from '@core_app_flight/DetailPageCards';
import RefundTrackerPage from '@core_app_common/RefundTracker';
import LoyalityCard from '../Components/LoyalityCard';
import AdTechCard from '../Components/AdTech';


export const getCardListMap = () => {
  const cardListMap = {
    ItineraryCard: ItineraryCard,
    ManageYourBookingCard: ManageBookingCard,
    MSRCard: MSRCard,
    AddOnsBuyCard: AncillaryAddOnCard,
    PrimaryContactCard: TravelerInfoCard,
    PaymentDetailCard: PaymentCard,
    PartPaymentCard: PartPaymentCard,
    TopStatusCard: BookingStatusCard,
    TravelInsurancePopup: TravelInsuranceCard,
    ImportantInfoCard: ImportantInfoCard,
    RefundStatusCard: TrackRefundCard,
    RefundTrackerCard: RefundTrackerPage,
    HeaderCard: PageHeader,
    MMTConnectFlightCard: MmtConnectFlight,
    ItineraryDetailedCard: ItineraryCard,
    FarelockTermAndConditionCard: TermsAndConditionCard,
    HoldTermsAndConditionCard: TermsAndConditionCard,
    LoyaltyCard: LoyalityCard,
    CompleteYourBookingCard: PriceLockBookingCard,
    OneNudgeCard: RecommnedationCard,
    AddOnsPurchasedCard: AddonsCard,
    AdTechCard: AdTechCard,
  };
  return cardListMap;
};
