import React, { useCallback } from 'react';
import { View, Text, TouchableOpacity, Image, Platform } from 'react-native';
import getStyles from './HeaderCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {addActionManually, handleClickEvent} from '../../utils/HotelBookingDetailUtil';
import HotelBookingDetailsConstant from '../../HotelBookingDetailsConstant';
import {getImageTransformStyling, getRTLDirectionCss} from '../../../../utils/PostSaleUtil';
import { CROSS_ICON1, DOWNLOAD_ICON, CALL_US_ICON, GREEN_SHARE_ICON, GREY_IOS_BACK_ICON, GREEN_BACK_ARROW_ICON } from '../../../HotelImageConstants';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import HeaderWrapper from 'apps/post-sales/src/Common/HeaderWrapper';

const Header = ({ title, subTitle, headerRtSection, headerShadow, download, callUs, share, onBackPress, response, isHeaderBg, isCrossIcon, bgColor, pageName, titleStyle, subTitleStyle }) => {
  const { ...fonts } = getFont(true);
  const styles = getStyles(fonts);
  const headerStyle = [styles.headerWrapper];
  if (headerShadow) {
    headerStyle.push(styles.headerShadow);
  }
  let headerBg = '';
  let headerBgText;
  let crossIconColor;

  switch(isHeaderBg) {
    case 'lightGreen':
      headerBg = styles.lightGreen;
      break;
    case 'darkGrey':
      headerBg = styles.darkGrey;
      headerBgText = styles.whiteText;
      crossIconColor = styles.whiteCrossIcon;
      break;
    default:
      headerBg = styles.white
  }

  const onClick = useCallback((action) => {
    const card = addActionManually(action);
    handleClickEvent(card, card.actionList[0], response, pageName);
  }, []);

  return (
    <HeaderWrapper
      titleText={title} 
      descText= {subTitle}
      closeIcon=
        {isCrossIcon === true ?
          <Image style={[styles.crossIconStyle, crossIconColor]} source={CROSS_ICON1}/>
          :
          <Image style={Platform.OS === 'ios' ? [styles.backArrowIos, getImageTransformStyling()] : [styles.backArrow, getImageTransformStyling()]} source={Platform.OS === 'ios' ? GREY_IOS_BACK_ICON : GREEN_BACK_ARROW_ICON} />
        }
        clickHandler={()=>onBackPress()}
        // iconStyle={imageTransformY}
        wrapperStyle= {[headerStyle,headerBg, {backgroundColor: bgColor}, getRTLDirectionCss()]}
        titleStyle={[styles.headingTxt, AtomicCss.alignLeft, headerBgText, titleStyle]}
        descriptionStyle={[styles.subTxt, AtomicCss.alignLeft, headerBgText, subTitleStyle]}
        rightComponent={
          headerRtSection === true && (
           <View style={AtomicCss.flexRow}>
              {download &&
                <TouchableOpacity onPress={() => onClick(HotelBookingDetailsConstant.ACTION_DOWNLOAD_VOUCHER)}>
                  <Image style={styles.downloadIcon} source={DOWNLOAD_ICON} />
                </TouchableOpacity>
              }
              {callUs &&
                <TouchableOpacity onPress={() => onClick(HotelBookingDetailsConstant.ACTION_CALL_HOTEL)}>
                  <Image style={styles.downloadIcon} source={CALL_US_ICON} />
                </TouchableOpacity>
              }
              {share &&
                <TouchableOpacity onPress={() => onClick(HotelBookingDetailsConstant.ACTION_RESEND_CONFIRMATION)}>
                  <Image style={styles.shareIcon} source={GREEN_SHARE_ICON} />
                </TouchableOpacity>
              }
            </View>
          )
        }
    />


  );
}

export default Header;