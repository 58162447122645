import React from 'react';
import FetchFlightData from '../../../v2/utils/fetchFlightData.ts';
import { fligtNewTrackingOmniture } from '../../../v2/utils/trackingUtil';
import usePageRefresh from '../../../../Common/Hooks/usePageRefresh';
import RenderSellingPage from './Components/RenderSellingPage';

interface SellingPageProps {
  BOOKING_ID: string;
  bookingId: string;
  refresh: {
    isRefresh: boolean;
  };
}

const SellingPage: React.FC<SellingPageProps> = (props) => {
  const { refresh } = props;
  const bookingId = props.BOOKING_ID || props.bookingId;
  const { onLoad, onClick, onError } = fligtNewTrackingOmniture();
  const uniqueKey = usePageRefresh({
    refresh,
    refreshDependency: [bookingId],
    onRefresh: () => {
      FetchFlightData.deleteData(bookingId);
    },
  });

  const renderSellingPageProps = {
    bookingId,
    onLoad,
    onClick,
    onError,
    extraPropsData: {
      bookingId,
    },
    refreshPage: refresh?.isRefresh,
  };
  return (
    <RenderSellingPage {...renderSellingPageProps} key={uniqueKey}/>
  );
};

export default SellingPage;
