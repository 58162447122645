import { Navigation } from './types';
import { logRouter } from './logRouter';
import { logRNPageLoad } from "@mmt/legacy-commons/Native/GenericModule";
export class NavigationWrapper implements Navigation {
  private navigationRef: any | null = null;
  private navState: any | null = null;
  private navigationRefList: any[] = [];

  setNavigationRef = (value: any | null, routerId: string | null) => {
    this.navigationRefList.push({ value, routerId });
    if (this.navigationRef !== null) {
      this.navState = null;
      this.navigationRef?.removeListener('state', this.onStateChangeListener);
    }
    this.navigationRef = value;
    this.navState = this.navigationRef?.dangerouslyGetState() || null;
    this.navigationRef?.addListener('state', this.onStateChangeListener);
  };

  popNavigationRef = (routerId: string) => {
    this.navigationRefList = this.navigationRefList.filter(
      (navigationRef) => navigationRef.routerId !== routerId,
    );
    if (this.navigationRefList?.length) {
      this.reinitializeRef(this.navigationRefList[this.navigationRefList.length - 1]);
    }
  };

  reinitializeRef = (navigationRef: any) => {
    this.navigationRef?.removeListener('state', this.onStateChangeListener);
    this.navigationRef = navigationRef.value;
    this.navState = this.navigationRef?.dangerouslyGetState() || null;
    this.navigationRef?.addListener('state', this.onStateChangeListener);
  };

  onStateChangeListener = (newState: any) => {
    this.navState = newState;
  };

  canGoBack = (): boolean => {
    return this.navigationRef?.canGoBack() || false;
  };

  getCurrentRoute = (): string[] | null => {
    if (
      this.navigationRef == null ||
      this.navState == null ||
      !this.navState.data ||
      !this.navState.data.state
    ) {
      return null;
    }
    return this.getTopRoute(this.navState.data.state);
  };

  getCurrentRouteInfo = (): string[] | null => {
    if (this.navState) {
      const { data, routes } = this.navState;
      if (data && data.state) {
        return this.getTopRoute(data.state);
      } else if (routes) {
        return this.getTopRoute(this.navState);
      } else {
        return null;
      }
    }
    return null;
  };

  private getTopRoute = (state: any, root?: string): string[] => {
    const { routes } = state;
    const topRoute = routes[routes.length - 1];
    // topRoute.state
    let childState: string[] = [];
    if (topRoute.state) {
      childState = this.getTopRoute(topRoute.state, root);
    }
    return [topRoute.name].concat(childState);
  };

  getNavigationObj = () => this.navigationRef;

  goBack = (): void => {
    logRouter('rnav goBack'); // log statements will help in pinpoint the crash in crashlytics
    this.navigationRef?.goBack();
  };

  push = (key: string, params?: any): void => {
    logRouter(`rnav push key: ${key}`);
    logRNPageLoad(key)
    this.navigationRef?.push(key, params);
  };

  pop = (): void => {
    logRouter('rnav pop');
    this.navigationRef?.pop();
  };

  navigate = (key: string, params?: any): void => {
    logRouter(`rnav navigate key: ${key}`);
    this.navigationRef?.navigate(key, params);
  };

  replace = (key: string, params?: any): void => {
    logRouter(`rnav replace key: ${key}`);
    this.navigationRef?.replace(key, params);
  };

  reset = (key: string, params?: any, navStateExtra?: any): void => {
    logRouter(`rnav replace key: ${key}`);
    this.navigationRef?.reset({
      index: 0,
      routes: [{ name: key, params: params }],
      ...navStateExtra,
    });
  };
  getNavigationRef = () => {
    return this.navigationRef;
  };

  resetRoute = (routes: { name: string; params?: any }[], index: number) => {
    this.navigationRef?.reset({
      index,
      routes,
    });
  };
}

export default new NavigationWrapper();
