import React from 'react';
import { View, Image, Text, TouchableOpacity } from 'react-native';
import styles from './LocationCardCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {copyAddress, getHotelAddressString, handleClickEvent, isNotNullAndEmpty, getActionButton} from '../../utils/HotelBookingDetailUtil';
import { GREY_MAP_ICON, RED_MAP_ICON, NEW_COPY_ICON} from '../../../HotelImageConstants';
import { getStaticData } from '../../../../staticData/staticData';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const LocationCard = ({ response, card, pageName }) => {
  const { fsStyle, ...fonts } = getFont(true);
  const staticData = getStaticData();
  const hotelDetailList = response.hotelBookingInfo && response.hotelBookingInfo.hotelDetailsList && response.hotelBookingInfo.hotelDetailsList.length > 0 &&  response.hotelBookingInfo.hotelDetailsList[0];

  const onCopyAddress = () => {
    copyAddress(response.hotelBookingInfo);
  }

  const handleClick = (action) => {
    handleClickEvent(card, action, response, pageName);
  };
	
  return (
    <View style={[AtomicCss.whiteCardNew, AtomicCss.marginHz12, AtomicCss.borderRadius16]}>
      <React.Fragment>
      <View
        style={[
          styles.headerSec,
          AtomicCss.flexRow,
          AtomicCss.alignCenter,
          AtomicCss.marginBottom15,
        ]}
      >
        <Text
          style={[fonts.blackFontFamily, fsStyle.font16, AtomicCss.blackText, AtomicCss.lineHeight20, AtomicCss.flexWrap]}
        >
          {staticData.hotelLocationCardText?.heading}
        </Text>
      </View>
      <View
        style={[
          styles.addressWrapper,
          AtomicCss.flexRow,
          AtomicCss.spaceBetween,
        ]}
      >
        <View style={[AtomicCss.flex1, AtomicCss.alignLeft]}>
          <Text
            style={[
              fonts.boldFontFamily,
              fsStyle.font12,
              AtomicCss.blackText,
              AtomicCss.marginBottom2,
              AtomicCss.lineHeight20
            ]}
          >
            {staticData.addressText}
          </Text>
          <Text
            style={[
              fonts.regularFontFamily,
              fsStyle.font14,
              AtomicCss.blackText,
              AtomicCss.flex1,
              AtomicCss.lineHeight20
            ]}
          >
            {getHotelAddressString(hotelDetailList)}
          </Text>
            <TouchableOpacity style={[styles.copyWrapper, AtomicCss.flexRow, AtomicCss.alignCenter]} onPress={onCopyAddress}>
              <Image style={styles.copyIcon} source={NEW_COPY_ICON} />
              <Text style={[AtomicCss.boldFont, AtomicCss.font14, AtomicCss.azure]}>
                {staticData.copyAddressText}
              </Text>
            </TouchableOpacity>
        </View>
        <Image style={styles.mapStyle} source={RED_MAP_ICON} />
      </View>
      </React.Fragment>
      <View>
        {card && isNotNullAndEmpty(card.actionList) && 
          getActionButton(card, response, handleClick)}
      </View>
    </View>
  );
};

export default LocationCard;
