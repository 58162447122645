import {colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles';

const styles = {
    steps:{
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: colors.white,
        paddingVertical: 9,
        paddingHorizontal: 15,
        elevation: 2,
        shadowColor: colors.black,
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.2,
        shadowRadius: 2,
        position: 'relative',
        zIndex: 2,
        marginTop: -2
    },
    stepItem:{
        flexDirection: 'row',
        marginRight: 10,
        alignItems: 'center',
        flex:1,
    },
    stepNumberWrapper:{
        backgroundColor: colors.disabledBtnBg,
        width: 13,
        height: 13,
        borderRadius: 13,
        marginRight: 3
    },
    stepNumberWrapperActive:{
        backgroundColor: colors.black
    },
    stepNumberWrapperDone:{
      backgroundColor: colors.green2,
    },
    stepNumber:{
        fontSize: 10,
        color: colors.white,
        lineHeight: 13,
        textAlign: 'center'
    },
    stepName:{
        color: colors.disabledBtnBg,
        fontSize: 10,
    },
    stepNameActive:{
        color: colors.black,
    },
    greenTickIcon:{
      width:7,
      height:5,
      position:'relative',
      top:4,
      left:3,
      tintColor:colors.white
    },
};

export default styles;
