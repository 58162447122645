import React from 'react';
import {View} from 'react-native';
import PropTypes from 'prop-types';
import SimpleHeader from '@mmt/legacy-commons/Common/Components/Header/SimpleHeader.ios';
import PostSalesBasePage from '../PostSalesBasePage';
import { getStaticData } from '../../staticData/staticData';
import NoInternetView from '@mmt/legacy-commons/Common/Components/Error/NoInternetView';

const staticData = getStaticData();

export default class NoNetworkView extends PostSalesBasePage {
  render() {
    const {
      heading,
      subHeading,
      buttonText,
      title,
      retryFunction
    } = this.props
    return (
      <View style={{flex: 1}}>
        <SimpleHeader
          title={title}
          iconPress={super.onBackIconPress}
        />
        <NoInternetView
          onRetry={retryFunction}
          heading={heading}
          subHeading={subHeading}
          buttonText={buttonText}
        />
      </View>
    );
  }
}

NoNetworkView.defaultProps = {
  title: staticData.backText,
  heading: staticData.errorText.notConnectedToTheInternetError,
  subHeading: staticData.errorText.checkYourSettingsAndTryAgainError,
  buttonText: staticData.tryAgainText
};

NoNetworkView.propTypes = {
  retryFunction: PropTypes.func.isRequired,
  title: PropTypes.string,
  subHeading: PropTypes.string,
  heading: PropTypes.string,
  buttonText: PropTypes.string
};
