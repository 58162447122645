import { StyleSheet } from 'react-native';

export default (theme) =>StyleSheet.create({
  superPackageImgContainer: {
    width: 90,
    height: 21
  },
  superPackageImg: {
    height: '100%',
    width: '100%',
    resizeMode: 'contain'
  },
  BookingWhiteCard: {
    borderRadius: 16,
    backgroundColor: theme.color.white,
    marginBottom: 16,
  },
  borderBottom: {
    borderBottomWidth: 1,
    borderColor: theme.color.lightGrey,
    paddingBottom: 12,
  },
  borderTop: {
    borderTopWidth: 1,
    borderColor: theme.color.lightGrey
  },
  rightArrow: {
    width: 24,
    height: 24,
  },
  bottomWrapper: {
    padding: 16,
    backgroundColor: theme.color.white,
    borderRadius: 16,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  seperator: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: -24,
  },
  semiCircle: {
    height: 16,
    width: 16,
    borderRadius: 8,
  },
  leftSemiCircle: {
    marginLeft: -35,
  },
  rightSemiCircle: {
    marginRight: -35,
  },
  dashedLineWrapper: {
    height: 1,
    flex: 1,
    overflow: 'hidden',
  },
  dashedLine: {
    flex: 1,
    borderStyle: 'dashed',
    borderWidth: 1,
    borderColor: theme.color.faintgrey,
    height: 3,
  },
  topWrapper: {
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
  dividor: {
    borderBottomWidth: 1,
    borderColor: theme.color.grey35,
  },
  bottomInfo: {
    backgroundColor: theme.color.faintgrey,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    padding: 5,
    marginTop: -20,
    width: '90%',
  },
  rowSection: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderBottomColor: theme.color.lightGrey,
    paddingVertical: 12,
    paddingHorizontal: 12,
  },
  headingText: {
    marginBottom: 12,
    marginTop: 12,
    paddingHorizontal: 12
  },
  paddingLR12:{
    paddingHorizontal: 12
  },
  btnSection: {
    padding: 12,
  },
  width50: {
    width: '50%',
  },
  bullet: {
    width: 4,
    height: 4,
    marginRight: 10,
    marginTop: 6,
    backgroundColor: theme.color.defaultTextColor,
    borderRadius: 10,
  },
  installTagWrapper: {
    marginLeft: -16,
    marginBottom: 8,
  },
  installTag: {
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    position: 'absolute',
  },
  tagBehind: {
    backgroundColor: theme.color.red19,
    width: 4,
    height: 25,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 4,
  },
  hotelInfoCard: {
    borderRadius: 16,
    backgroundColor: theme.color.white,
    // ...getPlatformElevation(2),
    padding: 12,
    marginBottom: 16,
  },
  editWrapper: {
    flexDirection: 'row',
    paddingHorizontal: 10.5,
    paddingVertical: 8,
    backgroundColor: theme.color.grayBg,
    borderRadius: 4,
  },
  editIcon: {
    width: 17,
    height: 17,
    marginRight: 6.5,
  },
  editText: {
    color: theme.color.green,
  },
  benefitClaimedLabel: {
    paddingHorizontal: 8,
    paddingVertical:2,
    borderWidth: 1,
    borderColor: theme.color.lightGreen18,
    marginLeft: 5,
    borderRadius:10,
    color: theme.color.lightGreen18
  },
  benefitClaimWrapper: {
    backgroundColor: theme.color.green11,
    padding: 12,
    borderRadius: 8,
    marginTop: 10
  },
  benefitClaimIconStyle: {
    width: 17,
    height: 20,
    marginRight: 10,
    marginTop: 3
  },
  maxWidth70: {
    maxWidth: '70%'
  }
})