const METRO_BOOKING_ROUTE_KEYS_ARRAY = [
    {
        key: 'metroBookingDetail',
        component: () => require('./MetroBookingDetail').default
    },
    {
        key: 'metroCancelSelectTicket',
        component: () => require('./cancel/SelectTicket/MetroSelectTicket').default
    },
    {
        key: 'metroCancelReviewRefundCharge',
        component: () => require('./cancel/RefundCharge/MetroReviewRefundCharge').default
    },
    {
        key: 'metroCancelReviewRefundMode',
        component: () => require('./cancel/RefundMode/MetroReviewRefundMode').default
    },
{
        key: 'metroCancelThankYou',
        component: () => require('./cancel/ThankYou/MetroThankYou').default
    }
]

export default METRO_BOOKING_ROUTE_KEYS_ARRAY;