import NetworkModule from '@mmt/legacy-commons/Native/NetworkModule';

import { clearCookieByName, clearCookies } from '@mmt/legacy-commons/Common/utils/AppUtils';
import { LOBNAMES, PWA_COOKIE_NAME } from '../PostSalesConstant';
import { Platform } from 'react-native';
import { getUserProfileType, setUserCountryCode } from 'packages/legacy-commons/AppState/AppUserConfig';

export const SUPPORTZ_COOKIE_DOMAIN = '.supportz.makemytrip.com';
export const MIMA_BASE_URL = 'https://supportz.makemytrip.com/api/';
export const MIMA_BASE_URL_GLOBAL = 'https://supportz.makemytrip.global/api/';
export const HUBBLE_BASE_URL = 'https://hubble.makemytrip.com';
// export const MIMA_BASE_URL = 'http://10.66.39.209:9006/myaccount/';
export const DOWNLOAD_AWS_DOCUMENT = `${MIMA_BASE_URL}common/getAWSObject`;
export const USER_INFO_URL = `${MIMA_BASE_URL}corp/userInfo/`;
export const PAYNOW_SUBMIT_URL = `${MIMA_BASE_URL}payNow`;
export const BUS_BOOKING_DETAILS_URL = `${MIMA_BASE_URL}busbookingdetail/`;
export const BUS_CANCELLATION_URL = `${MIMA_BASE_URL}cancelBusReservation`;
export const BUS_CANCEL_REBOOK = `${MIMA_BASE_URL}bus`;
// search url as well cancelRebookReview
// /bus/{bookingId}/cancelRebookSearch/{doj}
// /bus/{bookingId}/cancelRebookReview
export const BUS_SUPPORT_URL = `${MIMA_BASE_URL}busBookingSupport`;
export const USER_CONFIRMATION_URL = `${MIMA_BASE_URL}userconfirmation`;
export const CAB_BOOKING_DETAILS_URL = `${MIMA_BASE_URL}carbookingdetail/`;
export const CAB_GOOGLE_DIREACTION_URL = `${MIMA_BASE_URL}fetchcablocation`;
export const CAB_RESCHEDULE_URL = `${MIMA_BASE_URL}car/userConsentReschedule`;
export const CAB_PAY_ONLINE_URL = `${MIMA_BASE_URL}carPayIntent`;
export const RAIL_BOOKING_DETAILS_URL = `${MIMA_BASE_URL}railbookingdetail/`;
export const TGS_BOOKING_DETAILS_URL = `${MIMA_BASE_URL}tgbookingdetail/`;
export const RAIL_CANCEL_PREVIEW_URL = `${MIMA_BASE_URL}railTicketCancellationPreview/`;
export const RAIL_BOOKING_CANCEL_URL = `${MIMA_BASE_URL}v2/railcancellation`;
export const RAIL_BOOKING_CANCEL_URL_V2 = `${MIMA_BASE_URL}v2/railcancellation`;
export const RAIL_TG_PREMIUM_RETURN_URL = `${MIMA_BASE_URL}cancelAndTgRefund`;
export const RAIL_DOWNLOAD_TICKET = `${MIMA_BASE_URL}/generateRailTicketPdf`;
export const RAIL_TDR_URL = `${MIMA_BASE_URL}railtdr`;
export const RAIL_PAYMENT = `${MIMA_BASE_URL}v2/railtgs`;
export const ACME_BOOKING_DETAILS_URL = `${MIMA_BASE_URL}acmebookingdetail/`;
export const ACME_BOOKING_DETAILS_HOLIDAY_URL = `${MIMA_BASE_URL}acmeholidaydetails/`;
export const VISA_BOOKING_DETAIL_URL = `${MIMA_BASE_URL}visabookingdetail/`;
export const VISA_BOOKING_DETAILS_HOLIDAY_URL = `${MIMA_BASE_URL}holidayVisaBookingDetails`;
export const HOLIDAYS_GET_VISA_PROFILE_QUESTIONS_URL = `${MIMA_BASE_URL}/visa/getProfileQuestions`;
export const HOLIDAYS_SAVE_VISA_PROFILE_QUESTIONS_URL = `${MIMA_BASE_URL}/visa/saveProfileQuestionResponses`;
export const ACME_BOOKING_CANCELLATION_URL = `${MIMA_BASE_URL}acmeCancellation`;
export const FILE_UPLOAD_API = `${MIMA_BASE_URL}UploadAttachment/`;
export const CAB_CANCELLATION_URL = `${MIMA_BASE_URL}cancelCarBooking`;
export const CAB_SPECIAL_CLAIM_URL = `${MIMA_BASE_URL}v1/car/support`;
export const CHILD_BOOKING_INFO_URL = `${MIMA_BASE_URL}v1/crossplatform/childbookinginfo`;
export const METRO_BOOKING_DETAIL_URL = `${MIMA_BASE_URL}metrobookingdetail/`;
export const HOLIDAY_BOOKING_DETAIL_URL = `${MIMA_BASE_URL}holidayMyTripsBookingDetails/`;
export const HOLIDAY_DOCUMENTS_UPLOAD_URL = `${MIMA_BASE_URL}holidays/uploaddocument`;
export const REFUND_TRACKER_API = `${MIMA_BASE_URL}refundcommunicationDetails/`;
export const DO_WEBCHECKIN_URL = `${MIMA_BASE_URL}ancillaries/v2/webcheckin/`;
export const SEND_OTP_URL = `${MIMA_BASE_URL}sendotp`;
export const VALIDATE_OTP_URL = `${MIMA_BASE_URL}validateotp`;
export const METRO_CANCEL_URL = `${MIMA_BASE_URL}metrobookingcancel/`;
export const CAB_AMENDMENT_POLICIES_URL = `${MIMA_BASE_URL}carAmendmentPolicies/`;
export const CAB_VALIDATE_AMENDMENT_URL = `${MIMA_BASE_URL}carValidateAmendment`;
export const CAB_COMPLETE_AMENDMENT_URL = `${MIMA_BASE_URL}carCompleteAmendment`;
export const CAR_REFRESH_MAP = `${MIMA_BASE_URL}carrefreshmap/`;
export const RAIL_PNR_STATUS = `${MIMA_BASE_URL}currentpnrstatus`;
export const IOS_AAROGYA_SETU_LINK = 'https://apps.apple.com/in/app/aarogyasetu/id1505825357';
export const INITALIZE_IVR_PRIVILIGE_SESSION = `${MIMA_BASE_URL}initalizeIvrPriviligeSession`;
export const UPLOAD_ATTACHMENT_URL = `${MIMA_BASE_URL}uploadattachment`;
export const SELF_DRIVE_CANCELLATION_URL = `${MIMA_BASE_URL}cancelBooking`;
export const SELF_DRIVE_BOOKING_DETAILS_URL = `${MIMA_BASE_URL}selfdrive/bookingdetail/`;
export const REFUND_CALCULATION_URL = `${MIMA_BASE_URL}refundDetails/`;
export const REFUND_CALCULATION_URL_V2 = `${MIMA_BASE_URL}refundDetails/v2/`;
export const IMMEDIATE_CALLBACK_URL = `${MIMA_BASE_URL}immediateCallBack/`;
export const CALLBACK_SLOTS_URL = `${MIMA_BASE_URL}callBackSlots`;
export const SCHEDULE_CALLBACK_URL = `${MIMA_BASE_URL}scheduleCallBack`;
export const CHECK_CALLBACK_URL = `${MIMA_BASE_URL}callBackAvailabilty`;
export const CALLBACK_DETAILS_URL = `${MIMA_BASE_URL}callBackDetails`;
export const GIFTCARD_BOOKING_DETAILS_URL = `${MIMA_BASE_URL}giftCardDetails/`;
export const CAB_GET_CLAIM_REASONS_URL = `${MIMA_BASE_URL}car/claim/reasons/`;
export const CAB_SUBMIT_CLAIM_URL = `${MIMA_BASE_URL}car/claim/submit`;
export const HELPING_HAND_URL = `${MIMA_BASE_URL}helpingHandDetails`;
export const CANCEL_AND_REBOOK_PREVIEW_URL = `${MIMA_BASE_URL}car/cancelRebookSearch/v2`;
export const CANCEL_AND_REBOOK_COMMIT_URL = `${MIMA_BASE_URL}car/cancelRebookCommit/v2`;
export const HOLIDAY_BOOKING_CANCELLATION_URL = `${MIMA_BASE_URL}holidayCancellation`;
export const CALL_NOW_URL = `${MIMA_BASE_URL}callNow`;
export const RESEND_VOUCHER = `${MIMA_BASE_URL}resendGiftVoucherToCustomer`;
export const CLAIM_ANCILLARY = `${MIMA_BASE_URL}claimancillary/details/`;
export const CLAIM_ANCILLARY_REVIEW = `${MIMA_BASE_URL}claimancillary/review`;
export const CLAIM_ANCILLARY_DONE = `${MIMA_BASE_URL}claimancillary/done`;
export const DIGIT_INSURANCE_LISTING_URL = `${MIMA_BASE_URL}insurance/v4/listing`;
export const DIGIT_INSURANCE_HOLD_API = `${MIMA_BASE_URL}insurance/v4/confirm`;
export const INITIATE_DRIVER_CHAT = `${MIMA_BASE_URL}car/driverChat`;
export const CAB_FETCH_INVOCE_PDF = `${MIMA_BASE_URL}/generatecabTicketPdf`;
export const BUS_FETCH_INVOCE_PDF = `${MIMA_BASE_URL}/generateBusVoucherPdf`;
export const CUSTOMER_FORM_GET = `${MIMA_BASE_URL}customerform`;
export const RAIL_FETCH_INVOCE_PDF = `${MIMA_BASE_URL}/generateRailTicketPdf`;
export const CUSTOMER_FORM_POST = `${MIMA_BASE_URL}customerformsubmit`;
export const UPDATE_REFUND_STATUS = `/updateRefundReceivedStatus`;
export const MSR_UPDATE_SEARCH_API = `${MIMA_BASE_URL}v2/msr/search`;
export const LOST_BOOKING_SUMMARY_URL = `${MIMA_BASE_URL}lostbookingsummary`;
export const LOST_BOOKING_DETAIL_URL = `${MIMA_BASE_URL}lostbookingdetail`;
export const BOOKING_SUMMARY_URL = `${MIMA_BASE_URL}v2/bookingsummary`;
export const BOOKING_SUMMARY_URL_V4 = `${MIMA_BASE_URL}v4/bookingsummary`;
export const PURCHASED_ANCILLARIES_URL = `${MIMA_BASE_URL}purchasedAncillaries/`;
export const PURCHASED_TRAVELINS_URL = `${MIMA_BASE_URL}insuranceStatus/`;
export const VERIFY_BOOKING_LANG = `${MIMA_BASE_URL}bookingMetaInfo/`;
export const CUSTOMER_SUPPORT_LIST_API = `${MIMA_BASE_URL}v1/customerservice/bookingsummary`;
export const CUSTOMER_SUPPORT_LIST_API_V2 = `${MIMA_BASE_URL}v1/customerservice/v2/bookingsummary`;
export const CUSTOMER_SUPPORT_SEARCH_API = `${MIMA_BASE_URL}v1/customerservice/bookingdetailbyuuid`;
export const TRIP_IDEAS_SUMMARY_URL = `${HUBBLE_BASE_URL}/hubble/myTripsCard`;
export const HOLIDAY_PLANNING_DETAILS_URL = `${MIMA_BASE_URL}holidayPlanningDetails`;
export const HOLIDAY_PLANNING_VIEW_LISTING_URL = 'https://holidayz.makemytrip.com/holidays/psm/quotes/listing'
export const REVIEW_CORRECTION_URL = `${MIMA_BASE_URL}namecorrection/reviewNameCorrection`;
export const GET_ERROR_DATA = `${MIMA_BASE_URL}errors/getErrorData`;
export const HOLIDAY_VOUCHER_PDF_URL = `${MIMA_BASE_URL}holiday/getHolidayVouchers`;
export const CSAT_FEEDBACK_GET_DETAILS = `${MIMA_BASE_URL}csatfeedback/getDetails`;
export const GET_CSAT_CONTROLLER_DATA = `${MIMA_BASE_URL}surveytaken/details`;
export const GET_HOLIDAY_CAR_PICKUP_DETAILS = `${MIMA_BASE_URL}getholidaycarpickupdetails`
export const SUBMIT_HOLIDAY_CAR_PICKUP_DETAILS = `${MIMA_BASE_URL}holidaycarpickupDetailV2`
export const ADD_BOOKING_SEND_OTP = `${MIMA_BASE_URL}/v1/common/sendotp`;
export const ADD_BOOKING_VALIDATE_OTP = `${MIMA_BASE_URL}/v1/common/validateotp/addbooking`;
export const HOLIDAY_ADD_VISA_URL = `${MIMA_BASE_URL}holidayVisaSearchDetails`;
export const HOLIDAY_THANK_YOU_VISA_URL = `${MIMA_BASE_URL}holidayVisaThankyouPage`;
export const HOLIDAYS_HOLD_VISA_URL = `${MIMA_BASE_URL}holidayApplyVisa`;
export const TRAIN_DELAY_INFO = `${MIMA_BASE_URL}traindelayinfo`;
export const TALK_TO_US_CALL_NOW_API = `${MIMA_BASE_URL}callNow`;
export const BOOKING_SUMMARY_V6_URL = `/v6/bookingsummary`;
export const SUMMARY_DOWNLOAD_VIEW_API = `${MIMA_BASE_URL}content`;
export const BOOKING_SUMMARY_FILTER_DATA = `${MIMA_BASE_URL}v6/bookingsummary/filterBookings`;
export const WHERE_2_GO_URL = 'https://platform-query-hub.makemytrip.com/graphql';
export const DYNAMIC_FILTERS_URL = `${MIMA_BASE_URL}v6/bookingsummary/getDynamicFilterValues`;
export const HOLIDAY_PLANNING_URL = `/holidayPlanningDetails`;
export const CAB_MODIFICATION_V2_URL = `${MIMA_BASE_URL}car/modificationpreview`;
export const MODIFICATION_COMMIT_V2_URL = `${MIMA_BASE_URL}car/modificationhold`;
export const CAB_CONFIRM_LANDING_URL = `${MIMA_BASE_URL}caruserConfirmLanding`;
export const HOTEL_EXPEDIA_CHAT_FIREBASE_SET_USER_DATA = `${MIMA_BASE_URL}hotel/userActivityForVendorChat`;
export const MYPARTNER_ADDITIONAL_INFO_URL = `${MIMA_BASE_URL}getAdditionalInfo/`;

// Flight APIs
export const FLIGHT_BOOKING_DETAILS_URL = `${MIMA_BASE_URL}flightBookingDetails/v4/`;
export const FLIGHT_BOOKING_UNIVERSAL_SEARCH_DETAILS_URL = `${MIMA_BASE_URL}universalsearch/`;
export const FLIGHT_ANCILLARY_DETAILS_URL = `${MIMA_BASE_URL}ancillaryDetails/v3/`;
export const FLIGHT_ANCILLARY_HOLD_URL = `${MIMA_BASE_URL}holdAncillaries/`;
export const FLIGHT_BOOKING_DETAILS_HOLIDAY_URL = `${MIMA_BASE_URL}holidayFlightBookingDetails/`;
export const FLIGHT_GET_ADDRESS_BY_PINCODE = `${MIMA_BASE_URL}getAddressByPinCode/`;
export const FLIGHT_CYT_CROSSELL_URL = `${MIMA_BASE_URL}cross-sell`;
export const FLIGHT_CANCELLATION_PREVIEW_URL = `${MIMA_BASE_URL}flightcancellation/view`;
export const FLIGHT_CANCELLATION_DONE_URL = `${MIMA_BASE_URL}flightcancellation/done`;
export const FLIGHT_CANCELLATION_DONE_V2_URL = `${MIMA_BASE_URL}v2/flightcancellation/done`;
export const FLIGHT_RELEASE_HOLD_URL =  `${MIMA_BASE_URL}v2/flightcancellation/releaseHold`;
export const FLIGHT_CORP_DATE_CHANGE_REVIEW = `${MIMA_BASE_URL}v2/corp/review`;
export const FLIGHT_CORP_DATE_CHANGE_POLICY = `${MIMA_BASE_URL}v2/corp/getpolicy`;
export const FLIGHT_CORP_DATE_CHANGE_HOLD = `${MIMA_BASE_URL}v2/corp/hold`;
export const FLIGHT_CORP_DATE_CHANGE_RAISE_REQUEST = `${MIMA_BASE_URL}v2/corp/raiserequest`;
export const FLIGHT_CORP_DATE_CHANGE_WORKFLOW_DETAILS = `${MIMA_BASE_URL}corp/workflow/`;
export const FLIGHT_CORP_DATE_CHANGE_APPROVER_DETAILS = `${MIMA_BASE_URL}corp/approve`;
export const FLIGHT_PAX_NAME_CORRECTION = `${MIMA_BASE_URL}namecorrection/submit`;
export const FLIGHT_DATE_CHANGE_REQUEST = `${MIMA_BASE_URL}flightsodc/preview`;
export const FLIGHT_DATE_CHANGE_REQUESTV2 = `${MIMA_BASE_URL}v2/flightsodc/preview`;
export const FLIGHT_DATE_CHANGE_REVIEW = `${MIMA_BASE_URL}flightsodc/review`;
export const FLIGHT_DATE_CHANGE_REVIEWV2 = `${MIMA_BASE_URL}v2/flightsodc/review`;
export const FLIGHT_DATE_CHANGE_HOLD = `${MIMA_BASE_URL}flightsodc/hold`;
export const FLIGHT_DATE_CHANGE_HOLDV2 = `${MIMA_BASE_URL}v2/flightsodc/hold`;
export const FLIGHT_NO_TRUE_DATE_CHANGE_HOLD = `${MIMA_BASE_URL}flightsodc/submitNoTrueDateChange`;
export const FLIGHT_ODC_CANCELLATION_PREVIEW_URL = `${MIMA_BASE_URL}flightsodc/cancellationpreview`;
export const FLIGHT_ODC_CANCELLATION_DONE_URL = `${MIMA_BASE_URL}flightsodc/cancel`;
export const FLIGHTS_PARTIAL_DATE_CHANGE_REQUEST = `${MIMA_BASE_URL}flightsodc/partialdatechange`;
export const FLIGHTS_LISTING_DATE_CHANGE_REQUEST = `${MIMA_BASE_URL}flightsodc/listing`;
export const FLIGHTS_LISTING_DATE_CHANGE_REQUESTV2 = `${MIMA_BASE_URL}v2/flightsodc/listing`;
export const DOWNLOAD_INVOICE_API = `${MIMA_BASE_URL}flight/getAllPdfFlightInvoices`;
export const DOWNLOAD_VENDOR_INVOICE_API = `${MIMA_BASE_URL}flight/getAllVendorInvoices`;
export const FLIGHTS_DATE_CHANGE_LISTING_URL = `${MIMA_BASE_URL}flights/search/listing?requestId=`;
export const SUBMIT_REQUEST_URL = `${MIMA_BASE_URL}flightsodc/submitrequest`;
export const CORP_FLIGHTS_DATE_CHANGE_LISTING_URL = 'https://supportz.makemytrip.com/api/corp/flights/search/listing?requestId=';
export const MY_PARTNER_FLIGHTS_DATE_CHANGE_LISTING_URL = `${MIMA_BASE_URL}mypartner/flights/search/listing?requestId=`;
export const EXCHANGE_FLIGHT_URL = 'https://supportz.makemytrip.com/Mima/exchangeFlightPickDate';
export const FLIGHT_DOWNLOAD_TICKET = `${MIMA_BASE_URL}flight/getPdfFlightVoucher`;
export const FLIGHT_DOWNLOAD_CUSTOMER_CONFIRMATION = `${MIMA_BASE_URL}flight/myPartnerGetPdfFlightVoucher`;
export const FLIGHT_UNDO_WEBCHECKIN_URL = `${MIMA_BASE_URL}v2/flightsodc/raiseUndoWebCheckin`;
export const GET_ERROR_DATA_V2 = `${MIMA_BASE_URL}errors/getErrorDataV2`;
export const HOTEL_CROSS_SELL_URL = `${MIMA_BASE_URL}hotels/cross-sell`;
export const COMMON_HOTEL_CROSS_SELL_URL = `${MIMA_BASE_URL}hotels/cross-sell-generic`;
export const NAME_CORRECTION_SUBMIT = `${MIMA_BASE_URL}namecorrection/submit`;
export const REVIEW_NAME_CORRECTION = `${MIMA_BASE_URL}namecorrection/reviewNameCorrection`;
export const TGS_DOWNLOAD_INVOICE = `${MIMA_BASE_URL}tg/previewVoucherPdf`;
export const RENEW_FIREBASE_AUTH_URL = `${MIMA_BASE_URL}renewFireBaseAuth/`;
export const PART_PAYMENT_STATUS = `${MIMA_BASE_URL}flightPartPayment/initiatePayment`;
export const PART_PAYMENT_DONE_STATUS = `${MIMA_BASE_URL}flightPartPayment/status/`;
// Hotel APIs
export const HOTEL_BOOKING_DETAILS_URL = `${MIMA_BASE_URL}hotelDetails/`;
export const HOTEL_GET_INVOICE_URL = `${MIMA_BASE_URL}hotel/sendMail`;
export const HOTEL_AUTO_DEBIT_MANDATE_URL = `${MIMA_BASE_URL}hotelupimandate/`;
export const HOTEL_BOOKING_DETAILS_HOLIDAY_URL = `${MIMA_BASE_URL}holidaymytripshoteldetails`;
export const HOTEL_MODIFY_NAME_CHANGE_URL = `${MIMA_BASE_URL}checkNameChangeApplicable/`;
export const HOTEL_CUSTOMER_NAME_CHANGE_MODIFICATION_URL = `${MIMA_BASE_URL}chkcustomernamechangeallowed/`;
export const HOTEL_CUSTOMER_SUBMIT_NAME_CHANGE_API = `${MIMA_BASE_URL}executeCustomerNameChange/`;
export const HOTEL_MODIFY_NAME_CHANGE_SUBMIT_URL = `${MIMA_BASE_URL}submitAutoNameChange`;
export const HOTEL_BOOKING_CANCELLATION_URL = `${MIMA_BASE_URL}hotelcancellation`;
export const HOTEL_CHANGE_DATE_URL = `${MIMA_BASE_URL}hotelmodificationchangedate`;
export const HOTEL_CHANGE_DATE_AVAIL_URL = `${MIMA_BASE_URL}hotelmodificationreview`;
export const HOTEL_ADD_GUEST_URL = `${MIMA_BASE_URL}hoteladdguest`;
export const HOTEL_ADD_GUEST_AVAIL_URL = `${MIMA_BASE_URL}hoteladdguestreview`;
export const HOTEL_ADD_GUEST_NAMES_URL = `${MIMA_BASE_URL}hotel/addGuestNames`;
export const HOTEL_RESEND_VOUCHER_URL = `${MIMA_BASE_URL}sendHotelVoucher`;
export const HOTEL_PAYMENT_INFO = `${MIMA_BASE_URL}paymentinfo/`;
export const SPECIAL_REQUEST_WRITE_TO_HOTEL_URL = `${MIMA_BASE_URL}writeToHotel`;
export const HOTEL_VALIDATE_PAN_URL = `${MIMA_BASE_URL}validatePANCardNo`;
export const HOTEL_SUBMIT_PAN_URL = `${MIMA_BASE_URL}savePANCardDetail`;
export const HOTEL_DOWNLOAD_VOUCHER = `${MIMA_BASE_URL}hotel/generatePdf`;
export const HOTEL_MYPARTNER_VOUCHER = `${MIMA_BASE_URL}hotel/mypartnerGeneratePdf`;
export const HOTEL_DOWNLOAD_CUSTOMER_VOUCHER = `${MIMA_BASE_URL}hotel/mypartnerGeneratePdf`;
export const HOTEL_DOWNLOAD_BILL_MEMO = `${MIMA_BASE_URL}invoice/generatePdf`;
export const HOTEL_DOWNLOAD_GST_INVOICE = `${MIMA_BASE_URL}invoice/generatePdf`;
export const INSURANCE_BOOKING_DETAIL_URL=`${MIMA_BASE_URL}insuranceDetails/`;
export const INSURANCE_UPDATE_DETAIL_URL=`${MIMA_BASE_URL}insurance/edit-traveller-details`;
export const SOCIAL_MEDIA_URL = `${MIMA_BASE_URL}v1/mytrip/social-entry`;

export const TRAVEL_CARD_URL='/travelCardDetails';

export async function getCommonHeaders(auth, loggingInfo, { psLob, langAware = true } = {}) {
  if (Platform.OS === 'android' || Platform.OS === 'web') {
    await clearCookieByName(PWA_COOKIE_NAME, SUPPORTZ_COOKIE_DOMAIN);
  } else {
    await clearCookies();
  }
  const headers = await NetworkModule.getHeaders();
  setUserCountryCode(headers.region);
  headers['mmt-auth'] = auth;
  headers['Content-Type'] = 'application/json';
  headers['logging-trackinfo'] = loggingInfo;
  headers.profileType = getUserProfileType();
  //device-id was required at MIMA for Pokus driven experiment
  if (Platform.OS !== 'web') {
    headers.deviceIdentifier = (Platform.OS === 'android') ? (headers.deviceid) : (headers.deviceId);
  }
  headers.deviceid = Platform.OS.toUpperCase();
  headers.psLob = psLob;
  headers.langAware = langAware;
  return headers;
}

export async function getOdcApprovalCommonHeaders(userInfo, authCode) {
  const headers = await getCommonHeaders(
    userInfo.mmtAuth,
    userInfo.loggingTrackingInfo,
    { psLob: LOBNAMES.FLIGHT }
  );
  if (authCode) {
    headers.authCode = authCode;
  }
  return headers;
}

export async function getHotelCanDoneResponse(
  auth,
  loggingTrackInfo,
  cancellationRequest
) {
  const response = await fetch(HOTEL_BOOKING_CANCELLATION_URL, {
    method: 'POST',
    headers: await getCommonHeaders(auth, loggingTrackInfo),
    body: JSON.stringify(cancellationRequest)
  });
  return response;
}

export async function getAcmeCanDoneResponse(
  url,
  auth,
  loggingTrackInfo,
  cancellationRequest
) {
  const response = await fetch(url, {
    method: 'POST',
    headers: await getCommonHeaders(auth, loggingTrackInfo),
    body: JSON.stringify(cancellationRequest)
  });
  if (response.ok) {
    const body = await response.json();
    return body;
  }
  return null;
}

export async function getHolidayCanDoneResponse(
  auth,
  loggingTrackInfo,
  cancellationRequest
) {
  const response = await fetch(HOLIDAY_BOOKING_CANCELLATION_URL, {
    method: 'POST',
    headers: await getCommonHeaders(auth, loggingTrackInfo),
    body: JSON.stringify(cancellationRequest)
  });
  if (response.ok) {
    const body = await response.json();
    return body;
  }
  return null;
}

export function getFormatedImageUrl(url) {
  if (url && url.trim().startsWith('https:')) {
    return url;
  } else if (url && url.trim().startsWith('http:')) {
    return url.replace('http:', 'https:');
  }
  return `https:${url}`;
}

export async function fetchApiResponse(data, additionalHeaderObj = {}) {
  const startTime = (new Date()).getTime();
  // If internet is not available, return directly with error object
  const hasNetwork = await NetworkModule.isNetworkAvailable();
  if (!hasNetwork) {
    return {
      error: 'Not connected to internet',
      error_code: 'NO_INTERNET_ERROR'
    };
  }

  const commonHeaders = await getCommonHeaders(additionalHeaderObj.Authorization);
  const initObj = {
    method: data.method,
    headers: {
      ...commonHeaders,
      ...additionalHeaderObj
    }
  };
  if (data.method === 'POST' || data.method === 'PUT') {
    initObj.body = JSON.stringify(data.request);
  }
  try {
    const response = await fetch(data.apiUrl, initObj);
    if (!response.ok) {
      return null;
    }
    const responseBody = await response.json();
    console.log('API::', data.apiUrl, '::Took::', (((new Date()).getTime() - startTime) / 1000));
    return responseBody;
  } catch (e) {
    return null;
  }
}

export const loadCollectionsForDynamicForm = async (url) => {
  const reqDetails = {
    method: 'GET',
    apiUrl: `${MIMA_BASE_URL}${url}`
  };
  const responseBody = await fetchApiResponse(reqDetails);
  return responseBody;
}
export function getURLParams(paramName, url) {
  const results = new RegExp(`[\?&]${paramName}=([^&#]*)`).exec(url);
  if (results == null) {
    return null;
  }
  return decodeURI(results[1]) || 0;
}

export const getCookie = (name) => {
  const { UserSessionModule} = NativeModules;

  var decodedCookie = UserSessionModule.gdprGetCookie(name,{isPiiData:true}, UserSessionModule.cookieType && UserSessionModule.cookieType.ES)

  return decodedCookie || "";
};

export const isAffiliateUser = () => {
  return NetworkModule.isAffiliateUser();
}

export const getAffiliateName = () => {
  return NetworkModule.getAffiliateName();
}

export const getHomeLink = () => {
  if (isAffiliateUser()) {
    return `https://${getAffiliateName()}.makemytrip.com`;
  }
  return `https://www.makemytrip.com`;
}

export const PLATFORM_IOS = "ios";
export const PLATFORM_ANDROID = "android";
export const PLATFORM_WEB = "web";

export const getUserPlatform = () => {
  switch (Platform.OS) {
    case PLATFORM_IOS:
      return PLATFORM_IOS;
    case PLATFORM_ANDROID:
      return PLATFORM_ANDROID;
    case PLATFORM_WEB:
      return PLATFORM_WEB;
    default:
      return PLATFORM_ANDROID;
  }
};
