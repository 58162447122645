import { ActionConst } from 'react-native-router-flux';
import GenericModule from '../Native/GenericModule';
import { JS_VERSION } from '../Common/utils/OmnitureTrackerUtils';

export const shouldLogAction = (action) => action.type.indexOf('REACT_NATIVE_ROUTER_FLUX') === -1;

export const actionsLoggerMiddleware = () => (next) => (action) => {
  if (shouldLogAction(action)) {
    const { type, ...rest } = action;
    let data;
    try {
      data = JSON.stringify(rest);
    } catch (e) {
      data = rest;
    }
    logAction(type, data);
  }
  return next(action);
};

const actionMap = {
  [ActionConst?.FOCUS]: (action) => {
    logAction(action.routeName || action.scene.sceneKey);
    onRouteChanged();
  },
  [ActionConst?.BACK_ACTION]: () => {
    logAction('back');
  },
};
export const onRouteUpdated = (state, action) => {
  const actionName = actionMap[action.type];
  if (!actionName) {
    return;
  }
  actionName(action);
};

const logAction = (action, data) => {
  console.log('___LOG____', action);
  if (GenericModule && GenericModule.logBreadCrumb) {
    let crumb;
    if (data !== null && data !== undefined) {
      crumb = `RN ${action}`;
    } else {
      crumb = `RN ${action}`;
    }
    GenericModule.logBreadCrumb(crumb, JS_VERSION);
  }
};

export const logException = (errorMsg) => {
  if (!errorMsg) {
    return;
  }
  if (GenericModule.logException) {
    GenericModule.logException(errorMsg);
  }
};

export const logMessage = (message) => {
  console.log(`___LOG____: ${message}`);
};

const routeChangeListeners = new Set();

export const registerRouteChangeListener = (listener) => {
  if (listener && typeof listener === 'function') {
    routeChangeListeners.add(listener);
  } else {
    throw new Error('Invalid argument');
  }
};

export const onRouteChanged = (sceneKey) => {
  routeChangeListeners.forEach((value) => value(sceneKey));
};

export const clearListeners = () => {
  routeChangeListeners.clear();
};

export default logAction;
