import React from 'react';
import { StyleSheet, Text, View, Image } from 'react-native';
import isEmpty from 'lodash/isEmpty'
import { colors, fonts } from '../../../Styles/globalStyles';
import CtaButton from '../Buttons/CtaButton';
import PropTypes from 'prop-types';
import noError from '@mmt/legacy-assets/src/no_cabs.webp';

export default class ErrorView extends React.Component {
  render() {
    return (
      <View style={[styles.container, this.props.style]}>
        {this.props.showImage && (
          <Image
            style={this.props.errorIconStyle ? this.props.errorIconStyle : styles.noErrorImage}
            source={this.props.errImg || noError}
            resizeMode="contain"
          />
        )}
        <Text
          style={[styles.textDark, { marginTop: 8,marginBottom: 5 }, { paddingRight: 30 }, { paddingLeft: 30 }]}
        >
          {this.props.message}
        </Text>
        {!isEmpty(this.props.errorDesc) && (
          <Text
            style={[styles.descText, { marginTop: 8 }, { paddingRight: 30 }, { paddingLeft: 30 }]}
          >
            {this.props.errorDesc}
          </Text>
        )}
        {this.props.showRetry && (
          <View style={styles.retryView}>
            <CtaButton
              label={this.props.buttonLabel}
              onPress={this.props.onRetry}
              buttonGradient={this.props.buttonGradient}
            />
          </View>
        )}
      </View>
    );
  }
}

ErrorView.propTypes = {
  style: PropTypes.object,
  showRetry: PropTypes.bool,
  onRetry: PropTypes.func,
  message: PropTypes.string,
  buttonLabel: PropTypes.string,
  showImage: PropTypes.bool,
  errImg: PropTypes.object,
  buttonGradient: PropTypes.array
};

ErrorView.defaultProps = {
  style: {},
  showRetry: false,
  onRetry: PropTypes.func,
  message: 'Something went wrong, Please try again!',
  buttonLabel: 'Try again',
  showImage: false,
  errImg: null,
  buttonGradient: ['#53B2FE', '#065AF3']
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    height: '100%',
    backgroundColor: '#ffffffd9',
    alignItems: 'center',
    justifyContent: 'center'
  },

  noErrorImage: {
    height: 180,
    marginVertical: 16
  },
  textDark: {
    color: colors.black,
    fontSize: 16,
    fontFamily: fonts.bold,
    textAlign: 'center'
  },
  descText: {
    color: colors.grey1,
    fontSize: 16,
    textAlign: 'center'
  },
  retryView: {
    marginTop: 16
  }
});
