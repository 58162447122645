
const ViewState = {
  LOADING: 'loading',
  SHOW_DETAIL: 'detail',
  NO_INTERNET: 'no_internet',
  DEFAULT: 'default',
  ERROR: 'error',
  LISTING_API_ERROR: 'listing_api_error',
  RENDER_LIST: 'render_list'
};

export default ViewState;
