import { StyleSheet } from 'react-native';

export default (theme, font) =>
  StyleSheet.create({
    benefitCardGradientStyle: {
      marginTop: -20,
      borderBottomLeftRadius: 16,
      borderBottomRightRadius: 16,
      zIndex: -1,
    },
    benefitCardGradientLoyalityStyle: {
      borderRadius: 16,
    },
    benefitCardContainerStyle: {
      backgroundColor: 'transparent',
      paddingTop: 24,
    },
    rowStyle: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    headingStyle: {
      fontSize: 16,
      fontWeight: 'bold',
      marginBottom: 8,
    },
    descStyle: {
      ...font.font12,
      color: theme.color.textGrey,
    },
    icStyle: {
      width: 24,
      height: 24,
      resizeMode: 'contain',
      marginRight: 8
    },
  });
