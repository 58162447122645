import React from 'react';
import { Image, ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native';

import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getFont } from '../../../../PostSalesStyles';
import { useTheme } from '../../../../theme/theme.context';
import { carPickupDetailsText, transferMode } from '../../constants';

const SelectTransportMode = ({ onSelect }) => {
  const { psTheme: theme } = useTheme();
  const fonts = getFont(true);

  const styles = createStyle(theme, fonts);
  return (
    <View style={styles.OverlayContentWrapper}>
      <View style={styles.headingWrapper}>
        <Text style={[styles.headingTxt]}>{carPickupDetailsText.selectTransportMode}</Text>
      </View>
      <ScrollView>
        <View style={[AtomicCss.flexRow, AtomicCss.justifyCenter]}>
          {Object.keys(transferMode).map((item, index) => {
            const lastItem = index === transferMode.length - 1 ? styles['lastItemStyle'] : '';
            return (
              <TouchableOpacity
                style={[styles.tabWrapper, lastItem]}
                onPress={() => onSelect(item)}
              >
                <Image source={transferMode[item].icon} style={styles.iconStyle} />
                <Text
                  style={[
                    AtomicCss.font11,
                    AtomicCss.boldFont,
                    AtomicCss.defaultText,
                    styles.uppertxt,
                  ]}
                >
                  {transferMode[item].label}
                </Text>
              </TouchableOpacity>
            );
          })}
        </View>
      </ScrollView>
    </View>
  );
};

const createStyle = (theme, fonts) =>
  StyleSheet.create({
    OverlayContentWrapper: {
      zIndex: 999,
      paddingBottom: 30,
    },

    headingTxt: {
      fontSize: 24,
      color: theme.color.black22,
      lineHeight: 32,
      ...fonts.blackFontFamily,
    },
    headingWrapper: {
      paddingHorizontal: 20,
      marginBottom: 16,
    },
    tabWrapper: {
      borderRadius: 4,
      borderColor: theme.color.gray27,
      borderWidth: 1,
      width: 75,
      height: 70,
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: 8,
    },
    lastItemStyle: {
      marginRight: 0,
    },
    iconStyle: {
      width: 32,
      height: 32,
      marginBottom: 3,
    },
    uppertxt: {
      textTransform: 'uppercase',
    },
  });
export default SelectTransportMode;
