import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { isEmpty } from 'lodash';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import Button from '../../../Common/Button';

const CallAirlineCard = ({ heading, description, contactList, onContactBtnClick }) => (
  <View style={styles.cardSection}>
    <Text
      style={[AtomicCss.font16, AtomicCss.blackText, AtomicCss.blackFont, AtomicCss.marginBottom4]}
    >
      {heading}
    </Text>
    <Text
      style={[
        AtomicCss.font14,
        AtomicCss.defaultText,
        AtomicCss.regularFont,
        AtomicCss.marginBottom24,
      ]}
    >
      {description}
    </Text>
    {!isEmpty(contactList) &&
      contactList.map((item, index) => {
        if (item.actionId === 'AIRLINE') {
          return (
            <View style={[styles.marginHorizontal10, index === 0 ? AtomicCss.marginBottom20 : '']}>
              <Button
                btnBg="gradientBtn"
                btnType="flat"
                btnTxt={item.actionText}
                btnClickAction={item.actionId}
                clickHandler={() => onContactBtnClick(item.actionId, item.contactNoList)}
              />
            </View>
          );
        } else if (item.actionId === 'MAKEMYTRIP') {
          return (
            <TouchableOpacity
              activeOpacity={0.8}
              style={[
                AtomicCss.alignCenter,
                AtomicCss.justifyCenter,
                index === 0 ? AtomicCss.marginBottom20 : '',
              ]}
              onPress={() => {
                onContactBtnClick(item.actionId, item.contactNoList);
              }}
            >
              <Text style={[AtomicCss.azure, AtomicCss.font14, AtomicCss.boldFont]}>
                {item.actionText}
              </Text>
            </TouchableOpacity>
          );
        }
        return null;
      })}
  </View>
);

const styles = StyleSheet.create({
  cardSection: {
    marginBottom: 8,
    backgroundColor: '#fff',
    paddingHorizontal: 20,
    paddingVertical: 20,
    shadowColor: '#330000',
    shadowOpacity: 0.3,
    shadowRadius: 2,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    elevation: 3,
    zIndex: 1,
  },
  marginHorizontal10: { marginHorizontal: 10 },
});
export default CallAirlineCard;
