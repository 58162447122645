import React, { useEffect } from 'react';
import { StyleSheet, View, Text, Image, TouchableOpacity } from 'react-native';
import { DriverProfileCardProps, Fonts, Theme } from '../../../../types';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import ButtonWpr from '../../../../components/ButtonWpr';

import CabBookingDetailsConstant from '@mmt/post-sales/src/cab/CabBookingDetailsConstant';
import { getRatingColors } from 'apps/post-sales/src/cab/utils/CabBookingDetailUtil';
import CabBookingTrackingHelper from '@mmt/post-sales/src/cab/CabBookingTrackingHelper';

const DriverProfile: React.FC<DriverProfileCardProps> = ({
  handleBottomSheetOverlay,
  driverInfo,
  pageName,
}) => {
  const {
    driverName,
    carNo,
    driverPhotoUrl,
    driverRating,
    carModel,
    ratingIconURL,
    viewProfileText,
  } = driverInfo || {};

  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);

  useEffect(() => {
    !!viewProfileText &&
      CabBookingTrackingHelper.trackClickEvent(pageName, 'Mob_Cabs_MIMA_ViewProfileCTA_Shown');
  }, []);

  return (
    <>
      <View style={styles.carnoAndModel}>
        {!!carNo && <Text style={styles.cabNumber}>{carNo}</Text>}
        {!!carModel && <Text style={styles.carModel}>{carModel}</Text>}
      </View>
      <View style={styles.rightContentWrapper}>
        <View style={styles.imageAndRating}>
          {!!driverName && (
            <Text
              style={[styles.driverText, !viewProfileText && styles.marginTop8]}
              numberOfLines={1}
            >
              {driverName}
            </Text>
          )}
          <View style={styles.viewProfileBtnWrapper}>
            <ButtonWpr
              buttonType="text"
              buttonWidth="dynamic"
              buttonText={viewProfileText}
              btnTxtStyle={styles.viewProfileBtnStyle}
              onButtonPress={() => {
                CabBookingTrackingHelper.trackClickEvent(
                  pageName,
                  'Mob_Cabs_MIMA_ViewProfileCTA_Clicked',
                );
                handleBottomSheetOverlay(CabBookingDetailsConstant.DriverProfileOverlay);
              }}
            />
          </View>
        </View>
        <View>
          <View style={styles.driverImageWrapper}>
            <Image
              style={styles.driverImageStyle}
              source={{
                uri: driverPhotoUrl,
              }}
            />
          </View>
          {!!driverRating && (
            <View
              style={[
                styles.starRatingWrapper,
                { backgroundColor: getRatingColors(driverRating, theme.color) },
              ]}
            >
              <Image
                style={styles.starRatingStyle}
                source={{
                  uri: ratingIconURL,
                }}
              />
              <Text style={styles.ratingTextStyle}>{driverRating}</Text>
            </View>
          )}
        </View>
      </View>
    </>
  );
};

const createStyles = (theme: Theme, fonts: Fonts) => {
  const { color } = theme;
  return StyleSheet.create({
    cabDetailsWrapper: {
      paddingVertical: 16,
      paddingHorizontal: 14,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    starRatingStyle: {
      width: 10,
      height: 10,
      marginRight: 4,
    },
    ratingWrapper: {
      backgroundColor: '#1a7971',
      borderRadius: 2,
      paddingHorizontal: 4,
      paddingVertical: 2,
      marginLeft: 5,
      flexDirection: 'row',
      alignItems: 'center',
      alignSelf: 'flex-start',
    },
    cabNumber: {
      color: color.black,
      ...fonts.boldFontFamily,
      fontSize: 18,
      marginBottom: 6,
    },
    carModel: {
      fontSize: fonts.fontSize.font14,
      lineHeight: 16,
      ...fonts.regularFontFamily,
      color: color.black74,
      marginTop: 5,
    },
    driverImageWrapper: {
      width: 40,
      height: 40,
    },
    driverImageStyle: {
      width: 40,
      height: 40,
      borderRadius: 25,
    },
    btnWrapper: {
      flexDirection: 'row',
      paddingHorizontal: 10,
      justifyContent: 'space-between',
    },
    connectInfo: {
      backgroundColor: '#f2f2f2',
      paddingHorizontal: 15,
      paddingVertical: 10,
      marginHorizontal: 10,
      borderRadius: 8,
    },
    starRatingWrapper: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      paddingVertical: 2,
      paddingHorizontal: 2,
      borderRadius: 4,
      marginTop: -6,
    },
    btnTxtStyle: {
      fontSize: 10,
      fontWeight: '700',
      textAlign: 'right',
    },
    rightContentWrapper: {
      flexDirection: 'row',
      alignItems: 'center',
      flex: 1,
    },
    imageAndRating: {
      marginRight: 10,
      flex: 1,
    },
    driverText: {
      fontSize: fonts.fontSize.font14,
      lineHeight: 16,
      color: color.black,
      marginBottom: 5,
      ...fonts.boldFontFamily,
      textAlign: 'right',
    },
    marginTop8: {
      marginTop: 8,
    },
    viewProfileBtnWrapper: {
      alignSelf: 'flex-end',
      marginTop: 3,
    },
    viewProfileBtnStyle: {
      fontSize: fonts.fontSize.font14,
      ...fonts.regularFontFamily,
    },
    ratingTextStyle: {
      color: color.white,
      ...fonts.blackFontFamily,
      fontSize: fonts.fontSize.font12,
      lineHeight: 16,
    },
    carnoAndModel: {
      flex: 1,
      justifyContent: 'center',
    },
  });
};

export default DriverProfile;
