import { View, StyleSheet, Dimensions } from 'react-native';

export default (theme, fonts) => {
  const { color } = theme;
  return StyleSheet.create({
    bottomWrapper: {
      paddingHorizontal: 16,
      textAlign: 'center',
      zIndex: 10,
    },
  });
};
