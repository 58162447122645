import cloneDeep from 'lodash/cloneDeep';
import { downloadVisaImage } from "./VisaUtil";

export const STATE_QUEUED = 'queued';
export const STATE_LOADING = 'loading';
export const STATE_LOADED = 'loaded';
export const STATE_FAILED = 'failed';

const IMAGE_ENTRY = {
    url: undefined,
    state: undefined,
    base64data: undefined,
    callback: undefined
};

let imagesMap = new Map();
/**
 * Returns downloaded image base64data from cache otherwise undefined
 * @param {*} url Image url to get from cache
 */
export const getDownloadedImage = url => {
    const imageEntry = imagesMap.get(url);
    return imageEntry ? imageEntry.base64data : undefined;
};
/**
 * Download and cache the requested image from given web url
 * @param {*} url Url of the image
 * @param {*} callback Callback of download events
 */
export const downloadImage = async (url, callback) => {
    let imageEntry = imagesMap.get(url);
    if (imageEntry) {
        imageEntry.callback = callback;
        switch (imageEntry.state) {
            case STATE_LOADED:
                notifyImageLoaded(imageEntry);
                return;
            case STATE_LOADING:
                notifyImageLoading(imageEntry);
                return;
            case STATE_QUEUED:
                notifyImageQueued(imageEntry);
                return;
            default:
                queueImage(url, callback);
        }
    } else {
        queueImage(url, callback);
    }
};
const queueImage = (url, callback) => {
    const imageEntry = cloneDeep(IMAGE_ENTRY);
    imageEntry.url = url;
    imageEntry.callback = callback;
    imagesMap.set(url, imageEntry);
    notifyImageQueued(imageEntry);
    processImageEntries();
}
const processImageEntries = () => {
    imagesMap.forEach(entry => {
        if (entry.state === STATE_QUEUED || entry.state === STATE_FAILED) {
            download(entry);
        }
    });
}
const download = async (imageEntry) => {
    try {
        notifyImageLoading(imageEntry);
        const blobData = await downloadVisaImage(imageEntry.url);
        if (blobData) {
            let reader = new FileReader();
            reader.readAsDataURL(blobData);
            reader.onloadend = () => {
                imageEntry.base64data = reader.result;
                if (imageEntry.base64data) {
                    notifyImageLoaded(imageEntry);
                } else {
                    notifyImageLoadingFailed(imageEntry);
                }
            };
            reader.onerror = () => {
                notifyImageLoadingFailed(imageEntry);
            };
        }
    } catch (error) {
        notifyImageLoadingFailed(imageEntry);
    }
};

const notifyImageLoaded = imageEntry => {
    imageEntry.state = STATE_LOADED;
    if (imageEntry.callback) {
        imageEntry.callback.onImageLoaded(imageEntry.base64data);
    }
};
const notifyImageLoading = imageEntry => {
    imageEntry.state = STATE_LOADING;
    if (imageEntry.callback) {
        imageEntry.callback.onImageLoading();
    }
};

const notifyImageLoadingFailed = imageEntry => {
    imageEntry.state = STATE_FAILED;
    if (imageEntry.callback) {
        imageEntry.callback.onImageLoadingFailed();
    }
};
const notifyImageQueued = imageEntry => {
    imageEntry.state = STATE_QUEUED;
    if (imageEntry.callback) {
        imageEntry.callback.onImageQueued();
    }
};
