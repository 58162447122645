import {colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles';

const styles = {
  pageWrapper: {
    marginBottom: 10,
    backgroundColor: colors.white
  },
  pageContent: {
    paddingTop: 17,
    paddingHorizontal: 15
  },
  header: {
    paddingHorizontal: 12,
    paddingTop: 15,
    backgroundColor: colors.white
    // borderBottomColor: colors.rippleColor,
    // borderBottomWidth: 1
  },
  headerTitle: {
    fontSize: 14,
    fontFamily: fonts.black,
    color: colors.black
  },
  headerDesc: {
    fontSize: 12,
    fontFamily: fonts.regular,
    color: colors.defaultTextColor,
    marginTop: 2,
    marginBottom: 5
  },
  mapContainer: {
    backgroundColor: colors.red3,
    height: 154,
    alignItems: 'center',
    justifyContent: 'center'
  },
  errorIcon: {
    height: 26,
    width: 28,
    marginBottom: 6
  },
  errorText: {
    fontSize: 14,
    fontFamily: fonts.regular,
    color: colors.red,
    paddingHorizontal: '25%',
    textAlign: 'center'
  },
  addressDetailsWrapper: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 86
  },
  addressDetails: {
    flex: 1
  },
  locationList: {
    marginTop: 15
  },
  locationItem: {
    paddingLeft: 16,
    position: 'relative'
  },
  locationTrackBorder: {
    position: 'absolute',
    zIndex: 1,
    left: 78,
    top: 2,
    height: '100%',
    width: 1,
    backgroundColor: colors.gray3
  },

  timeTrackBorder: {
    position: 'absolute',
    left: 0,
    top: -3,
    right: 8,
    color: colors.black,
    fontFamily: fonts.black
  },
  dayTrackBorder: {
    position: 'absolute',
    left: 1,
    top: 14,
    color: colors.black
  },
  locatioListStyleType: {
    height: 9,
    width: 9,
    borderRadius: 5,
    backgroundColor: colors.white,
    borderColor: colors.lightSeaGreenTextColor,
    borderWidth: 1,
    position: 'absolute',
    zIndex: 2,
    top: 2,
    left: 74
  },
  locatioListStyleTypeActive: {
    backgroundColor: colors.lightSeaGreenTextColor,
    borderColor: 'transparent',
    borderWidth: 0
  },
  locationItemTitle: {
    fontSize: 11,
    fontFamily: fonts.regular,
    color: colors.black,
    marginBottom: 4
  },
  vehicleInfo: {
    backgroundColor: colors.yellow3,
    height: 40,
    width: '90%',
    fontSize: 12,
    marginTop: 5,
    marginBottom: 15,
    paddingVertical: 10,
    paddingHorizontal: 10,
    color: colors.lightYello
  },
  vehicleNumber: {
    fontFamily: fonts.bold,
    fontSize: 12,
    color: colors.black,
    alignItems: 'center'
  },
  locationItemDesc: {
    fontSize: 14,
    fontFamily: fonts.regular,
    color: colors.black,
    marginBottom: 15,
    width: '85%'
  },
  locationIcon: {
    height: 32,
    width: 32,
    marginBottom: 35,
    marginTop: -15
  },
  extraDetailsBadge: {
    backgroundColor: colors.goldenYellow3,
    padding: 9,
    borderRadius: 2,
    marginBottom: 15
  },
  extraDetailsDesc: {
    fontSize: 11,
    fontFamily: fonts.regular,
    color: colors.lightYello
  },
  extraDetailsValue: {
    fontSize: 12,
    fontFamily: fonts.bold,
    color: colors.black
  },
  footer: {
    backgroundColor: colors.lightBlue5,
    paddingHorizontal: 12,
    paddingVertical: 5,
    borderTopColor: colors.skyBlue,
    borderTopWidth: 1
  },
  footerBtn: {
    fontSize: 14,
    fontFamily: fonts.bold,
    color: colors.azure,
    paddingVertical: 10
  },
  footerBtnLast: {
    borderBottomColor: colors.skyBlue,
    borderBottomWidth: 1,
    paddingHorizontal: 12,
    paddingVertical: 5
  },
  notification: {
    paddingHorizontal: 9,
    backgroundColor: colors.red3,
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 2
  },
  notificationYellow: {
    backgroundColor: colors.goldenYellow3
  },
  notificationYellowText: {
    color: colors.lightYello
  },
  notificationText: {
    fontSize: 12,
    fontFamily: fonts.regular,
    color: colors.red,
    flex: 1
  },
  notifictionIcon: {
    height: 14,
    width: 30,
    marginRight: 7
  },
  notificationClose: {
    width: 40,
    height: 35,
    alignItems: 'flex-end',
    justifyContent: 'center'
  },
  closeIcon: {
    height: 10,
    width: 10,
    marginLeft: 15
  }
};

export default styles;
