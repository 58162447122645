import React from 'react';
import LinearGradient from "react-native-linear-gradient";
import {Image, Platform, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import AtomicCss from "../../commonStyles/AtomicCss";
import PropTypes from "prop-types";
import {fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import {ANDROID_BACK_ARROW, IOS_BACK_ARROW,CLOCK_ICON} from '../../Utils/RailImageConstant';
import { getStaticData } from '../../../staticData/staticData';
import { getFont } from '../../../PostSalesStyles';

class RailUnConfirmedTopStatusCard extends React.Component {
    static navigationOptions = {
        header: null
    };
    constructor(props){
        super(props)
        this.colors = [this.props.card.colorBegin, this.props.card.colorEnd]
    }
    render() {
        const staticData =  getStaticData();
        const { fsStyle, ...fonts } = getFont(true);
        return (
            <LinearGradient start={{x: 1.0, y: 0.0}} end={{x: 0.0, y: 1.0}} colors={this.colors} style={[styles.topStatusCard]} >
                <TouchableOpacity style={styles.backIconPress} onPress={this.props.onBackPress}>
                    <Image style={Platform.OS === 'ios' ? [AtomicCss.marginBottom10, styles.IOSbackarrow] : [AtomicCss.marginBottom10, styles.backarrow]} source={Platform.OS === 'ios' ? IOS_BACK_ARROW:ANDROID_BACK_ARROW} />
                </TouchableOpacity>
                <View>
                    <Image style={[AtomicCss.marginBottom10, styles.holdIcon]} source={CLOCK_ICON} />
                </View>
                <View style={styles.StatusCardWrapper}>
                    <Text style={[styles.StatusHeadingTxt, fonts.blackFontFamily, fsStyle.font16]}>
                        {this.props.response.statusTag}
                    </Text>
                    <View style={[styles.BookingWrapper]}>
                        <Text style={[styles.booingDtlsTxt,  fonts.regularFontFamily, fsStyle.font12]}>{staticData.bookingIdCapsText}</Text>
                    <Text style={[styles.booingDtlsbold, fonts.boldFontFamily, fsStyle.font12]}>{this.props.response.bookingID}</Text>
                    </View>
                </View>
            </LinearGradient>
        );
    }
}
const styles = StyleSheet.create({
    topStatusCard:{
        paddingTop:15,
        paddingBottom:20,
        zIndex: 10,
        paddingHorizontal:20,
        position: 'relative'
    },
    backarrow:{
        width:16,
        height:16,
    },
    IOSbackarrow:{
        width:10,
        height:16,
    },
    StatusCardWrapper:{
        paddingLeft:50,
        paddingRight:50
    },
    backIconPress: {
        position: 'absolute',
        top: 15,
        left: 0,
        padding: 15
    },
    StatusHeadingTxt:{
        color:'#070404',
        textAlign:'center',
        lineHeight:24,
        marginBottom:10,
        backgroundColor:'transparent',
    },
    holdIcon:{
        width:57,
        height:49,
        alignSelf:'center'
    },
    BookingWrapper:{
        flexDirection:'row',
        alignSelf:'center',
    },
    booingDtlsTxt:{
        color:'#000',
        lineHeight:14,
        marginRight:10,
    },
    booingDtlsbold:{
        color:'#000',
        lineHeight:14,
    },
})

RailUnConfirmedTopStatusCard.propTypes = {
    onBackPress: PropTypes.func.isRequired,
    response:PropTypes.object.isRequired
};

export default RailUnConfirmedTopStatusCard;
