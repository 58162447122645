import React from 'react';
import {Text, View, Image} from 'react-native';
import _ from 'lodash';
import PropTypes from 'prop-types';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import styles from './InformationCardCss';

const InformationCard = ({
  title, description, clickableText, clickEvent, clickEventActionType, iconSource, cardBackgroundColor, textColor
}) => (
  <View style={[styles.dCTopInfoWrapper, {backgroundColor: cardBackgroundColor}]}>
    {iconSource && <Image style={styles.calIconStyle} source={iconSource} />}
    <View style={AtomicCss.flex1}>
      {
            !_.isEmpty(title) &&
            <Text
              style={[AtomicCss.boldFont, AtomicCss.font12, styles.postponeTxt, AtomicCss.marginBottom3, {color: textColor}]}
            >
                {title}
            </Text>
        }
      {!_.isEmpty(description) && <Text style={[AtomicCss.regularFont, AtomicCss.font12, styles.postponeTxt, {color: textColor}]}>
        {description}
        {!_.isEmpty(clickableText) && <Text
          onPress={() => clickEvent(clickEventActionType)}
          style={[AtomicCss.azure, AtomicCss.boldFont, AtomicCss.font12, AtomicCss.marginLeft3]}
        >
          {clickableText}
        </Text>
            }
                      </Text>
        }
    </View>
  </View>);

InformationCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string.isRequired,
  clickableText: PropTypes.string,
  clickEvent: PropTypes.func,
  iconSource: PropTypes.oneOfType([
    PropTypes.shape({
      uri: PropTypes.string,
      headers: PropTypes.objectOf(PropTypes.string)
    }),
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.shape({
      uri: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
      headers: PropTypes.objectOf(PropTypes.string)
    }))
  ]),
  cardBackgroundColor: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  clickEventActionType: PropTypes.any
};

InformationCard.defaultProps = {
  title: '',
  clickableText: undefined,
  clickEvent: undefined,
  iconSource: undefined,
  clickEventActionType: undefined
};

export default InformationCard;
