import { StyleSheet } from "react-native";

export default (theme, fonts) =>StyleSheet.create({
btn:{
    backgroundColor: theme.color.white,
    paddingBottom: 10,
    paddingTop: 15,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 24,
    paddingRight: 35, 
    marginTop : 10
    
},
btnText:{
    paddingLeft:10,
    color : theme.color.primary
},
RecFullBtnArrow:{
width: 9,
height: 12,
tintColor: theme.color.primary
},
borderBottomStyle:{
    borderBottomWidth: 1,
    borderBottomColor: theme.color.lightGrey3
},
borderTopStyle:{
    borderTopWidth: 1,
    borderTopColor: theme.color.lightGrey3
}
})