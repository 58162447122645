import isEmpty from 'lodash/isEmpty';
import React, { useEffect } from 'react';
import { View, Text, DeviceEventEmitter, ScrollView } from 'react-native';
import Actions from '../../../../../navigation/postSalesNavigation';
import { getFont } from '../../../../../PostSalesStyles';
import BusCancelRebookConstant from '../../../CancelRebookConstants';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import NewBookingCard from './NewBusCard';

const BusList = ({ response, bookingId, navigation }) => {
  const { buses: busList } = response || {};
  const { getNavigationRef, setNavigationRef } = navigation;
  const navRef = getNavigationRef();
  const { fsStyle, ...fonts } = getFont();
  useEffect(() => {
    DeviceEventEmitter.addListener(BusCancelRebookConstant.OPEN_REVIEW_PAGE_EVENT, openReviewPage);
    return () => {
      DeviceEventEmitter.removeAllListeners(
        BusCancelRebookConstant.OPEN_REVIEW_PAGE_EVENT,
        openReviewPage,
      );
    };
  }, []);
  function openReviewPage(reviewPageData) {
    setNavigationRef(navRef);
    Actions.reviewDateChangePage({ reviewPageData, bookingID: bookingId });
  }
  return (
    <View style={[AtomicCss.flex1]}>
      <View style={[AtomicCss.paddingHz16, AtomicCss.paddingBottom12]}>
        <Text style={[fsStyle.font20, AtomicCss.blackText, fonts.blackFontFamily]}>
          {BusCancelRebookConstant.SELECT_NEW_BOOKING}
        </Text>
      </View>
      <ScrollView>
        {!isEmpty(busList) &&
          busList.map((item, index) => {
            return <NewBookingCard item={item} index={index} />;
          })}
      </ScrollView>
    </View>
  );
};
export default BusList;
