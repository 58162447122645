import React from 'react';
import { View, ScrollView } from 'react-native';
import Actions from '../../../../../navigation/postSalesNavigation';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import AirlineContactCard from './AirlineContactCard';
import BasePage from '../../../../../Common/PostSalesBasePage';
import Header from '../../../../../Common/HeaderCross';
import { getStaticData } from '../../../../../staticData/staticData';

class AirlineContactModal extends BasePage {
  static navigationOptions = {
    header: null,
  };

  render() {
    const { airlineContactText } = getStaticData();
    return (
      <View style={{ flex: 1 }}>
        <Header
          title={airlineContactText}
          headerShadow
          headingTxt="font18"
          handleClose={this.onBackPress}
        />
        <ScrollView>
          <View style={[AtomicCss.flex1, { paddingTop: 10 }]}>
            {this.props.airlineNameContacts.map((item, index) => (
              <AirlineContactCard item={item} index={index} />
            ))}
          </View>
        </ScrollView>
      </View>
    );
  }

  onBackPress = () => {
    Actions.pop();
  };
}

export default AirlineContactModal;
