import React from 'react';
import {View, Text, Image} from 'react-native';
import isEmpty from 'lodash/isEmpty';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from './getPlatformElevation';
import {getPaymentIcon} from '../HolidayBookingDetailUtils';

function getIconStyleName(getPayType) {
  switch (getPayType) {
    case 'Card':
      return [styles.creditIconStyle];
    case 'Wallet':
      return [styles.walletIconStyle];
    case 'GiftCard':
      return [styles.giftIconStyle];
    default:
      return [styles.creditIconStyle];
  }
}

class PastPaymentCard extends React.Component {

  render() {
    const {paymentDetails} = this.props;
    return (
      <View style={[AtomicCss.whiteBg]}>
        <View style={styles.fareBreakupInnerSection}>
          {!isEmpty(paymentDetails.pastPayments) &&
          paymentDetails.pastPayments.map((item, index) => {
            const iconStyleName = getIconStyleName(item.modeOfPayment);
            const paidByLabel = `Paid By ${item.modeOfPayment}`;
            let paidByBankLabel = '';
            if (item.bankName) {
              paidByBankLabel = item.bankName;
            }
            return (
              <View style={[styles.rowSection]}>
                <View style={[AtomicCss.flexRow, styles.PastPaidLftDtls]}>
                  <View style={styles.iconWrapper}>
                    <Image style={iconStyleName} source={getPaymentIcon(item.modeOfPayment)}/>
                  </View>
                  <View style={AtomicCss.flex1}>
                    <Text
                      style={[styles.fareBkHeadingTxt, AtomicCss.marginBottom3]}
                    >{paidByLabel}
                    </Text>
                    {!isEmpty(paidByBankLabel) &&
                    <Text style={styles.lftTxt}>{paidByBankLabel}</Text>
                    }
                  </View>
                </View>
                <View>
                  <Text
                    style={[styles.successTxt, AtomicCss.marginBottom3]}
                  >{item.amountPaid}{' '}
                  </Text>
                  <Text style={[styles.lftTxt]}>{item.paymentReceivedDate}</Text>
                </View>
              </View>
            );
          })
          }
        </View>
      </View>
    );
  }
}

const styles = {
  fareBreakupInnerSection: {
    ...getPlatformElevation(2),
    marginBottom: 1,
    backgroundColor: '#fff',
    marginTop: 1

  },
  rowSection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 15,
    paddingHorizontal: 16
  },
  fareBkHeadingTxt: {
    color: '#000',
    fontFamily: fonts.bold,
    fontSize: 14
  },
  successTxt: {
    color: '#1a7971',
    fontFamily: fonts.black,
    fontSize: 14,
    textAlign: 'right'
  },
  lftTxt: {
    fontSize: 12,
    color: '#747474',
    fontFamily: fonts.regular,
    lineHeight: 14
  },
  payTxt: {
    fontSize: 12,
    color: '#cf8100',
    fontFamily: fonts.black,
    lineHeight: 14
  },
  creditIconStyle: {
    width: 20,
    height: 14
  },
  walletIconStyle: {
    width: 19,
    height: 16
  },
  giftIconStyle: {
    width: 20,
    height: 21
  },
  pahInfoWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderRadius: 4,
    backgroundColor: '#ffedd1',
    paddingVertical: 5,
    paddingHorizontal: 8,
    marginHorizontal: 15,
    marginBottom: 10,
    alignItems: 'center'
  },
  clockIconStyle: {
    width: 20,
    height: 20,
    marginRight: 8
  },
  pahInfoTxt: {
    fontFamily: fonts.black,
    color: '#cf8100',
    fontSize: 12
  },
  PastDatesTxtStyle: {
    color: '#747474',
    fontSize: 12,
    fontFamily: fonts.regular,
    marginHorizontal: 15,
    marginTop: 15,
    marginBottom: 10
  },
  PastPaidLftDtls: {
    width: '60%',
    flexWrap: 'wrap'
  },
  iconWrapper: {
    width: 25,
    height: 25,
    marginRight: 10
  }
};


export default PastPaymentCard;
