import React from 'react';
import LinearGradient from 'react-native-linear-gradient';
import {
  BackHandler,
  Image,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import PropTypes from 'prop-types';
import Actions from '../../navigation/postSalesNavigation';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import ProgressView from '@mmt/legacy-commons/Common/Components/Progress/ProgressView';
import SimpleHeader from '@mmt/legacy-commons/Common/Components/Header/SimpleHeader';
import NoInternetView from '@mmt/legacy-commons/Common/Components/Error/NoInternetView';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import { getCommonHeaders, CAB_CANCELLATION_URL } from '../../utils/NetworkUtils';
import { isNetworkAvailable } from '@mmt/legacy-commons/Common/utils/AppUtils';
import { colors, fonts } from '@mmt/legacy-commons/Styles/globalStyles';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import { invalidateTrips } from '@mmt/legacy-commons/Helpers/genericHelper';
import * as NpsModule from '@mmt/legacy-commons/Native/NpsModule';
import { getRatingData } from '../../Common/commonUtil';
import { cancelIcon } from '../CabImageConstants';
import BaseButton, { BUTTON_VARIANT } from '../../Common/BaseButton';
import { POST_SALES_CAB_CANCELLATION_SUCCESS_PAGE } from '@mmt/legacy-commons/adsConfig/adsConfigConstants';
import { getAdTechCard } from '../../Common/commonUtil';

export const CAB_CANCEL_BOOKING = 'Cancellation: Cancel booking';

export default class CabCancelRequest extends React.Component {
  static navigationOptions = {
    header: null,
  };

  constructor(props) {
    super(props, CAB_CANCEL_BOOKING);
    this.trackingPageName = CAB_CANCEL_BOOKING;
    this.bookingId = this.props.bookingId;
    this.mmtAuth = null;
    this.loggingTrackingInfo = null;
    this.penaltyResponse = null;
    this.state = {
      viewState: ViewState.LOADING,
      responseJson: null,
    };
    this.showRatingPrompt = true;
  }

  componentWillMount() {
    BackHandler.addEventListener('hardwareBackPress', this.goToBookingDetails);
  }

  componentWillUnmount() {
    BackHandler.removeEventListener('hardwareBackPress', this.goToBookingDetails);
  }

  render() {
    return (
      <View style={{ flex: 1 }}>
        {this.state.viewState === ViewState.LOADING && this.renderProgressView()}
        {this.state.viewState === ViewState.NO_INTERNET && this.renderNoNetworkView()}
        {this.state.viewState === ViewState.ERROR && this.renderErrorView()}
        {this.state.viewState === ViewState.SHOW_DETAIL && this.renderPage()}
      </View>
    );
  }

  renderProgressView = () => <ProgressView message="Loading Details..." />;

  renderNoNetworkView = () => (
    <View style={{ flex: 1 }}>
      <SimpleHeader title="Back" iconPress={this.goBack} />
      <NoInternetView
        onRetry={() => {
          this.setState({ viewState: ViewState.LOADING });
          this.fetchJSONAsync();
        }}
      />
    </View>
  );

  renderErrorView = () => {
    const {errorPageData} = this.state.responseJson || {};
    const {heading,description} = errorPageData || {};
    const {ctaList} = errorPageData || {}
    const {actionText} = ctaList && ctaList.length ? ctaList[0] : {};
   return( <View style={{ flex: 1 }}>
      <SimpleHeader iconPress={this.goBack} />
      <ErrorView
        showRetry
        message={heading}
        errorDesc={description}
        buttonLabel = {actionText}
        onRetry={() => {
         this.goToBookingDetails()
        }}
      />
    </View>)
  }

    renderAdTechCard = () => {
      return getAdTechCard(POST_SALES_CAB_CANCELLATION_SUCCESS_PAGE);
    };

  renderPage = () => {
    const { ratingData, npsData } = getRatingData(this.trackingPageName, this.state.responseJson);
    if (ratingData && this.showRatingPrompt) {
      NpsModule.showRatingPrompt(ratingData, npsData);
      this.showRatingPrompt = false;
    }

    return (
      <View style={styles.thankYouWrapper}>
        <Image style={styles.successIcon} source={cancelIcon} />
        <Text style={styles.messageTitle}>Booking Successfully cancelled</Text>
        <View style={[styles.CommonFillBtn, styles.transparantBgrnd]}>
          <BaseButton
            variant={BUTTON_VARIANT.CAPSULE}
            buttonStyle={styles.CommonFillBtnGradient}
            text="See Updated Booking"
            clickHandler={() => this.goToBookingDetails()}
          />
        </View>
        <View style = {styles.adTechCardView}>
          {this.renderAdTechCard()}
        </View>
      </View>
    );
  };

  goToBookingDetails = () => {
    if (this.bookingId) {
      const params = {};
      const refresh = {};
      params.BOOKING_ID = this.bookingId;
      params.MMT_AUTH = this.mmtAuth;
      params.LOGGING_TRACKINFO = this.loggingTrackingInfo;
      refresh.isRefresh = true;
      params.refresh = refresh;
      if (this.props.cabCrossSellBooking) {
        params.CROSS_SELL_BOOKING = this.props.cabCrossSellBooking;
        params.PARENT_BOOKING_ID = this.props.parentBookingId;
      }
      params.isMyGate = this.props.isMyGate;
      Actions.cabBookingDetail({ data: params });
    } else {
      Actions.popTo('cabBookingDetail');
    }
    return true;
  };
  componentDidMount() {
    this.fetchJSONAsync();
  }

  async fetchJSONAsync() {
    try {
      const hasNetwork = await isNetworkAvailable();
      if (!hasNetwork) {
        this.setState({ viewState: ViewState.NO_INTERNET });
        return;
      }
      const request = {
        bookingId: this.bookingId,
        action: 'Commit',
        fullCancellation: {
          refundAdjustment: this.props.selectedRefundMode,
        },
        reason: this.props.reason,
        instantRefundData: this.props.instantData,
        authorizationToken: this.props.authorizationToken,
      };
      const { mmtAuth, loggingTrackingInfo } = await HotelBookingModule.getRequestHeader(
        this.props.bookingId,
        'Cab_Cancellation_Details',
      );
      this.mmtAuth = mmtAuth;
      this.loggingTrackingInfo = loggingTrackingInfo;
      const response = await fetch(CAB_CANCELLATION_URL, {
        method: 'POST',
        body: JSON.stringify(request),
        headers: await getCommonHeaders(mmtAuth, loggingTrackingInfo),
      });

      if (response && response.ok) {
        const body = await response.json();
        this.penaltyResponse = body;
        if (this.penaltyResponse && this.penaltyResponse.cancellationDone) {
          this.setState({
            viewState: ViewState.SHOW_DETAIL,
            responseJson: this.penaltyResponse,
          });
          invalidateTrips();
        } else {
          this.setState({ viewState: ViewState.ERROR,responseJson: this.penaltyResponse });
        }
      } else {
        this.setState({ viewState: ViewState.ERROR});
      }
    } catch (error) {
      this.setState({ viewState: ViewState.ERROR});
    }
  }

  goBack = () => {
    Actions.pop();
  };
}

const styles = StyleSheet.create({
  parentContainer: {
    flex: 1,
    backgroundColor: '#ffffff',
  },
  thankYouWrapper: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    paddingHorizontal: '10%',
  },
  transparantBgrnd: {
    ...Platform.select({
      ios: {
        backgroundColor: 'transparent',
      },
    }),
  },
  thankYouHeader: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 47,
  },
  headerIcon: {
    width: 24,
    height: 32,
    marginRight: 7,
  },
  headerTitle: {
    fontSize: 14,
    color: '#053cb2',
    fontFamily: fonts.black,
  },
  headerDesc: {
    fontSize: 12,
    color: '#053cb2',
  },
  successIcon: {
    width: 40,
    height: 40,
    marginRight: 15,
    marginBottom: 10,
    alignSelf: 'center',
  },
  messageTitle: {
    fontSize: 20,
    textAlign: 'center',
    lineHeight: 25,
    color: '#000000',
    fontFamily: fonts.bold,
    marginBottom: 27,
  },
  messageText: {
    textAlign: 'center',
    fontSize: 12,
    color: '#000000',
  },
  CommonFillBtn: {
    width: '100%',
  },
  CommonFillBtnGradient: {
    borderRadius: 25,
    paddingVertical: 11,
    paddingHorizontal: 25,
    marginTop: 24,
  },
  CommonFillBtnText: {
    color: colors.white,
    alignSelf: 'center',
    fontFamily: fonts.black,
  },
  hideView: {
    display: 'none'
  },
  adTechCardView: {
    marginTop: 10
  }
});

CabCancelRequest.defaultProps = {
  instantData: null,
};

CabCancelRequest.propTypes = {
  bookingId: PropTypes.string.isRequired,
  selectedRefundMode: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  cabCrossSellBooking: PropTypes.bool.isRequired,
  parentBookingId: PropTypes.string.isRequired,
  instantData: PropTypes.object,
  reason: PropTypes.string,
};
