import React, { FC } from 'react';
import { Image, Text, View } from 'react-native';
import { isEmpty } from 'lodash';
import BaseButton, { BUTTON_VARIANT } from '../../../../Common/BaseButton';
import { getFont } from '../../../../PostSalesStyles';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import styles from '../../../summaryCss';
import { NO_TRIPS_ICON, NO_TRIPS_ICON_LARGE } from 'apps/post-sales/src/flights/FlightImageConstants';

interface EmptyDataScreenProps {
  loadData?: () => void; 
  mainText: string | null;
  subText?: string | null;
  buttonText?: string;
  showLargeIcon?: boolean;
}

const EmptyDataScreen: FC<EmptyDataScreenProps> = ({
  loadData,
  mainText,
  subText,
  buttonText,
  showLargeIcon = false,
}) => {
  const { fsStyle, ...fonts } = getFont(true);
  return (
    <View style={[styles.errorStateWraper, AtomicCss.flex1]}>
      <Image style={showLargeIcon ? styles.errorImgLrgStyle : styles.errorImgStyle} source={showLargeIcon ? NO_TRIPS_ICON_LARGE : NO_TRIPS_ICON} />
      {!isEmpty(mainText) && <Text style={[styles.errorStateTxt, fonts.blackFontFamily, fsStyle.font16]}>{mainText}</Text>}
      {!isEmpty(subText) && <Text style={[styles.errorStateSubTxt, fonts.regularFontFamily, fsStyle.font12]}>{subText}</Text>}
      {loadData && !!buttonText && (
        <View style={styles.buttonWrapper}>
          <BaseButton variant={BUTTON_VARIANT.CAPSULE} text={buttonText} clickHandler={loadData} shadow />
        </View>
      )}
    </View>
  );
}

export default EmptyDataScreen;