import {NativeModules} from 'react-native';
import { pokusConfig } from '../components/usePokus'
import get from "lodash/get";
import { getDataFromStorageSync } from '../localstorage';

export const getAbConfig = async () => {
  let abConfigStr = getDataFromStorageSync('brc_ab');
  try {
    return JSON.parse(abConfigStr);
  } catch (e) {
    return null;
  }
};

export const getPokusConfig = (lob, experimentKey, defaultValue) => {
  if (!pokusConfig) {
    return defaultValue;
  }
  const configPath = ["perLobMap", lob, "metadataValues", experimentKey];
  return get(pokusConfig, configPath, defaultValue);
};

NativeModules.AbConfigModule = {
  getAbConfig,
  getPokusConfig,
};
