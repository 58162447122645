import React from 'react';
import {View, Text, ScrollView, StyleSheet, TouchableOpacity, Image, Alert, BackHandler} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import {colors} from '@mmt/legacy-commons/Styles/globalStyles';
import {submittedIcon} from '../../../CabImageConstants';
import AtomicCss from '../../../../bus/commonStyles/AtomicCss';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import { getCommonHeaders, CAB_SUBMIT_CLAIM_URL } from '../../../../utils/NetworkUtils';
import { isNetworkAvailable } from '@mmt/legacy-commons/Common/utils/AppUtils';
import NoNetworkView from '../../../../Common/Cancellation/NoNetworkView';
import PageLoadingError from '../../../../Common/Cancellation/PageLoadingError';
import CabBookingTrackingHelper from '../../../CabBookingTrackingHelper';
import BasePage from '../../../../Common/PostSalesBasePage';
import ProgressView from '@mmt/legacy-commons/Common/Components/Progress/ProgressView';
import {openCabBookingDetail} from '../../../utils/CabBookingDetailUtil';

class CarIssueSubmit extends BasePage  {

  static navigationOptions = {
    header: null
  };

  constructor(props) {
    super(props, 'carSupportIssueSubmit');

    this.mmtAuth= "";
    this.state = {
      viewState: ViewState.LOADING,
      submitResponse: null
    }
  }

  componentDidMount() {
    super.componentDidMount();
    this.submitIssue();
  }

  componentWillMount() {
    BackHandler.addEventListener('hardwareBackPress', this.clickHandler);
  }

  componentWillUnmount() {
    BackHandler.removeEventListener('hardwareBackPress', this.clickHandler);
  }

  async submitIssue() {
    try {
      const hasNetwork = await isNetworkAvailable();
      if (!hasNetwork) {
        Alert.alert('Please check your internet connection');
        return;
      }

      const { mmtAuth, loggingTrackingInfo } = await HotelBookingModule.getRequestHeader(this.props.bookingid, 'CAR_SUPPORT_SUBMIT');
      this.mmtAuth = mmtAuth;
      const commonHeaders = await getCommonHeaders(mmtAuth, loggingTrackingInfo);

      const request = this.props.submitRequest;

      const response = await fetch(CAB_SUBMIT_CLAIM_URL, {
        method: 'POST',
        body: JSON.stringify(request),
        headers: commonHeaders
      });

      if (response.ok) {
        const body = await
          response.json();

        CabBookingTrackingHelper.trackLoadEvent('mob: cabs: mima support issue submit page', this.props.response)
        this.setState({ viewState: ViewState.SHOW_DETAIL, submitResponse: body });
      } else {
        CabBookingTrackingHelper.trackErrorEvent('Car_Support_Issue_Submit');
        this.setState({ viewState: ViewState.ERROR });
      }
    } catch (error) {
      console.log(error);
      CabBookingTrackingHelper.trackErrorEvent('Car_Support_Issue_Submit');
      this.setState({ viewState: ViewState.ERROR });
    }
  }

  render() {
    return (
      <View style={AtomicCss.flex1}>
        {this.state.viewState === ViewState.LOADING && this.renderProgressView()}
        {this.state.viewState === ViewState.NO_INTERNET && <NoNetworkView retryFunction={this.refreshDetail} />}
        {this.state.viewState === ViewState.ERROR && <PageLoadingError retryFunction={this.refreshDetail} />}
        {this.state.viewState === ViewState.SHOW_DETAIL && this.renderPage()}
      </View>
    );
  }

  refreshDetail = () => {
    this.submitIssue();
  }

  renderProgressView = () => (<ProgressView message="Submitting the issue..." />);

  getTicketList() {
    if (this.state.submitResponse && this.state.submitResponse.myDeskTicketList) {
      return this.state.submitResponse.myDeskTicketList.join(", ");
    }
  }

  getSubText() {
    let subText = "";
    const carSupportData = this.props.response.carSupportData;
    const tickets = this.state.submitResponse.myDeskTicketList ? this.state.submitResponse.myDeskTicketList.length : 0;

    if (tickets == 1) {
      subText = carSupportData.requestSubmitSubtext + this.state.submitResponse.myDeskTicketList[0]
    } else {
      subText = carSupportData.multipleRequestSubmitSubtext;
    }

    return subText;
  }

  renderPage() {
    const carSupportData = this.props.response.carSupportData;
    return (
      <View style={[AtomicCss.flex1, AtomicCss.whiteBg]}>
        <ScrollView>
          <View style={[AtomicCss.alignCenter, AtomicCss.paddingHz, AtomicCss.CenterText]}>
            <Image source={submittedIcon} style={styles.IconSection} />
            <Text style={[AtomicCss.textCenter, AtomicCss.font26, AtomicCss.boldFont, AtomicCss.blackText, AtomicCss.marginBottom10, AtomicCss.marginTop10]}>
              {carSupportData.requestSubmitHeader}
            </Text>
            <Text style={[AtomicCss.textCenter, AtomicCss.font14, AtomicCss.boldFont, AtomicCss.defaultText, AtomicCss.marginBottom24]}>
              {this.getSubText()}
            </Text>
            <Text style={[AtomicCss.textCenter, AtomicCss.font12, AtomicCss.regularFont, AtomicCss.defaultText, AtomicCss.marginBottom15]}>
              {carSupportData.requestSubmitDescription}
            </Text>
          </View>
        </ScrollView>
        <TouchableOpacity style={AtomicCss.btn} activeOpacity={0.7} onPress={this.clickHandler}>
          <LinearGradient
            colors={[colors.lightBlue, colors.darkBlue]}
            start={{ x: 0, y: 0 }}
            end={{ x: 1.0, y: 0 }}
            style={AtomicCss.btnGradient}
          >
            <Text style={[AtomicCss.font12, AtomicCss.whiteText, AtomicCss.boldFont]}>{carSupportData.updatedBookingButton}</Text>
          </LinearGradient>
        </TouchableOpacity>
      </View>
    );
  }

  onHardBackPress = () => {
    this.clickHandler();
    return true;
  };

  clickHandler = () => {
    CabBookingTrackingHelper.trackClickEvent('mob: cabs: mima support issue submit page', 'open cab booking detail')
    openCabBookingDetail(this.props.response.bookingId);
  }
}

const styles = StyleSheet.create({
  IconSection: {
    height: 53,
    width: 52,
    marginTop: 40
  }
});

export default CarIssueSubmit;
