import { StyleSheet } from 'react-native';

export default (theme, fonts) =>
  StyleSheet.create({
    contentTitle: {
      fontSize: fonts.fontSize.font26,
      color: theme.color.black,
      marginBottom: 24,
    },
    contentFooter: {
      flexDirection: 'row',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
    },
    actionBtn: {
      paddingVertical: 10,
    },

    fltStatusCard: {
      borderWidth: 1,
      borderColor: theme.color.lightGrey,
      borderRadius: 4,
      marginBottom: 20,
    },
    cityInfoWrapper: {
      backgroundColor: theme.color.grey7,
      padding: 12,
    },
    flightDtlsRow: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },

    titleTxt: {
      fontSize: fonts.fontSize.font11,
      color: theme.color.greyLight,
      marginBottom: 5,
    },
    destName: {
      fontSize: fonts.fontSize.font14,
      color: '#000',
      marginBottom: 4,
    },

    fltDisc: {
      fontSize: fonts.fontSize.font12,
      color: theme.color.defaultTextColor,
    },

    arrowRight: {
      width: 10,
      height: 6,
      marginTop: 5,
      marginHorizontal: 5,
    },
    infoTxt: {
      color: theme.color.defaultTextColor,
    },
    imgWrapper: {
      width: 32,
      height: 32,
      marginRight: 10,
    },
    submitBtn: {
      alignItems: 'center',
      justifyContent: 'center',
      paddingHorizontal: 36,
      paddingVertical: 14,
      borderRadius: 40,
      overflow: 'hidden',
    },
    actionBtnText: {
      fontSize: fonts.fontSize.font14,
      color: theme.color.primary,
    },
  });
