import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { getFont } from '../../../../../PostSalesStyles';
import { useTheme } from '../../../../../theme/theme.context';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
import CheckBox from '../../../../../Common/Checkbox';
import SPECIAL_CLAIM_ACITON_TYPES from '../../data/specialClaimActionTypes';

const ConfirmCard = ({ text, isConfirmed, dispatchCancellationState, subText }) => {
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = getStyle(theme);
  const onCheckboxChange = () => {
    dispatchCancellationState({ type: SPECIAL_CLAIM_ACITON_TYPES.TOGGLE_CONFIRM });
  };
  return (
    <View style={styles.refundDetails}>
      <Text style={[styles.headTxt, fonts.blackFontFamily, fsStyle.font16]}>{text}</Text>
      <CheckBox
        checked={isConfirmed}
        onChange={onCheckboxChange}
        label={subText}
        tintColor={theme.color.primary}
      />
    </View>
  );
};
const getStyle = (theme) => {
  return StyleSheet.create({
   refundDetails: {
     backgroundColor: theme.color.grey22,
     borderRadius: 8,
     overflow: 'hidden',
     marginBottom: 12,
     padding: 20,
     marginHorizontal: 12,
     ...getPlatformElevation(2),
   },
   headTxt: {
     lineHeight: 20,
     color: theme.color.darkGrey4,
     marginBottom: 16,
   },
 });
}
export default ConfirmCard;
