import { MIMA_BASE_URL, MIMA_BASE_URL_GLOBAL } from './NetworkUtils';
import { updateRegionQueryParams } from './PostSaleUtil';
import { fetchStaticData, getPSLobLanguage } from '../staticData/staticData';
import { LANGUAGE_MAP } from '@mmt/legacy-commons/Common/constants/AppConstants';

 let prevent_double_init = false;

export function fetchInterceptor() {
  if(!prevent_double_init){
  const fetchAPI = fetch;
  fetch = function () {
    try {
      let url = arguments[0];
      const config = arguments[1] || {};
      if (config?.headers?.['entity-name']) {
        if (url.indexOf(MIMA_BASE_URL) === 0 && config?.headers?.['entity-name'] === 'global') {
          url = url.replace('.com', '.global');
        } else if (url.indexOf(MIMA_BASE_URL_GLOBAL) === 0) {
          url = url.replace('.global', '.com');
        }
      }
      arguments[0] = appendQueryString(url, config);
    } catch (error) {
      //  Ignore exception
    }
    return fetchAPI.apply(this, arguments);
  };
  prevent_double_init = true;
  }
}

const appendQueryString = (url, config) => {
  const { headers } = config;
  // Add region, currency and language in query params for postsales APIs
  if (url && (url.indexOf(MIMA_BASE_URL) === 0 || url.indexOf(MIMA_BASE_URL_GLOBAL) === 0) && headers && headers.region) {
    const { psLob, langAware = true } = headers || {};
    if (langAware === false) {
      headers.language = LANGUAGE_MAP.eng;
    } else {
      headers.language = getPSLobLanguage(psLob);
    }
    delete headers.psLob;
    delete headers.langAware;
    // Fetch static data to support multi-language.
    fetchStaticData();
    url = updateRegionQueryParams(url, headers);
  }
  return url;
};
