/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import LinearGradient from 'react-native-linear-gradient';
import Actions from '../../navigation/postSalesNavigation';
import {
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  Image,
  DeviceEventEmitter,
  Alert,
  Keyboard,
} from 'react-native';
import styles from './CabMamiCss';
import { getCabSubTitle, isNotNullAndEmpty } from '../utils/CabBookingDetailUtil';
import CabRefundOverlay from './CabRefundOverlay';
import StickyHeader from '../../hotel/details/components/StickyHeader';
import BasePage from '../../Common/PostSalesBasePage';
import { appendRupeeSymbol } from '../../hotel/details/utils/HotelBookingDetailUtil';
import * as PSC from '../../PostSalesConstant';
import irstyles from '../../Common/InstantRefund/Styles/RefundPaymentModeCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import OverlayMessage from '../../Common/InstantRefund/Overlays/OverlayMessage';
import {
  getCancelRequestKeyName,
  getInstantRefundGradient,
  registerForInstantRefund,
  retrieveObjFromAsyncStorage,
  sendOtp,
  verifyOtp,
} from '../../Common/commonUtil';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import PageLoader from '../../Common/Cancellation/PageLoader';
import NoNetworkView from '../../Common/Cancellation/NoNetworkView';
import PageLoadingError from '../../Common/Cancellation/PageLoadingError';
import { isNetworkAvailable } from '@mmt/legacy-commons/Common/utils/AppUtils';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import { CAB_CANCELLATION_URL, getCommonHeaders } from '../../utils/NetworkUtils';
import OTPBottomOverlay from '../../Common/InstantRefund/Overlays/OTPBottomOverlay';
import OTPLoader from '../../Common/InstantRefund/Overlays/OTPLoader';
import BottomOverlayDtls from '../../Common/InstantRefund/Overlays/BottomOverlayDtls';
import { isNonEmpty } from '@mmt/legacy-commons/Common/utils/StringUtils';
import PostSalesBookingTrackingHelper from '../../PostSalesBookingTrackingHelper';
import InstantRefundBanner from '../../Common/InstantRefund/Overlays/InstantRefundBanner';
import { refundIcon, headerIcon2 } from '../CabImageConstants';
import BaseButton, { BUTTON_VARIANT } from '../../Common/BaseButton';
import CommonOverlayMessage from '../../Common/CommonOverlayMessage';

export const pageName = 'Mob:Customer Support:Cab:TripDetails:CancelBooking:Confirm';
export const pageN = 'CancellationRefundMode';

class CancellationRefundMode extends BasePage {
  constructor(props) {
    super(props, pageN);
    this.state = {
      isOverlayVisible: false,
      bottomOverlay: PSC.EMPTY_STRING,
      radiobtn: 'Refund_To_Customer',
      OTPValue: PSC.EMPTY_STRING,
      errorState: false,
      viewState: ViewState.SHOW_DETAIL,
      keyboard: false,
    };
    this.radio = this.radio.bind();
    this.penalty = this.props.penaltyResponse;
    this.response = this.props.response;
    this.payment = this.props.response.bookingPaymentDetail.paymentDetails;
    this.carDetailInfo = this.response.carBookingInfo.carDetail;
    this.cancelRequestKey = getCancelRequestKeyName(
      PSC.CancellationPreviewRequest.CAR,
      this.props.response.bookingId,
    );
    this.instantData = null;
    this.bookingId = this.props.response.bookingId;
    this.refundReloadEventListener = null;
  }

  componentWillMount() {
    this.refundReloadEventListener = DeviceEventEmitter.addListener(PSC.InstantRefundReloadEvent.CAR, this.refreshCar);
    this.keyboardDidShowListener = Keyboard.addListener(
      PSC.KEYBOARD_SHOW_EVENT,
      this._keyboardDidShow,
    );
    this.keyboardDidHideListener = Keyboard.addListener(
      PSC.KEYBOARD_HIDE_EVENT,
      this._keyboardDidHide,
    );
  }

  componentWillUnmount() {
    if (this.refundReloadEventListener) {
      this.refundReloadEventListener.remove();
    }
    this.keyboardDidShowListener && this.keyboardDidShowListener.remove();
    this.keyboardDidHideListener && this.keyboardDidHideListener.remove();
  }

  _keyboardDidShow = () => {
    this.setState({ keyboard: true });
  };

  _keyboardDidHide = () => {
    this.setState({ keyboard: false });
  };

  static navigationOptions = {
    header: null,
  };

  toggleOverlay = () => {
    this.setState({
      isOverlayVisible: !this.state.isOverlayVisible,
    });
  };

  radio = (active) => {
    this.setState({
      radiobtn: active,
    });
  };

  renderPage() {
    return (
      <View style={irstyles.pageWrapper}>
        <StickyHeader
          iconSrc={headerIcon2}
          title="Cab Cancellation"
          headerColor={['#ffffff', '#ffffff']}
          titleStyle={[styles.DefaultText, styles.blackFont]}
          subTitle={getCabSubTitle(this.response)}
          subTitleStyle={[styles.DefaultText, styles.regularFont, styles.font12]}
          onBackPress={this.goBack}
        />
        <ScrollView>
          <View style={[styles.MamiCard, styles.mar10Bt]}>
            {/* Breadcrumb */}
            <View style={[styles.flexRow, styles.cancelBreadcrumb]}>
              <View style={styles.flexRow}>
                <Text style={[styles.mar5Rt, styles.darkGrey]}>1</Text>
                <Text style={styles.darkGrey}>View Refund Amount</Text>
              </View>
              <View style={styles.flexRow}>
                <Text style={[styles.mar5Rt, styles.activeBreadCrumb]}>2</Text>
                <Text style={styles.activeBreadCrumb}>Confirm Cancellation</Text>
              </View>
            </View>

            <View style={[styles.padding16, styles.borderbtm]}>
              <Text style={styles.bookedCabType}>
                {this.carDetailInfo.fromCity} - {this.carDetailInfo.toCity}
              </Text>
            </View>

            <View>
              <View
                style={
                  isNotNullAndEmpty(this.payment) && this.penalty.refundAmount > 0
                    ? styles.showView
                    : styles.hideView
                }
              >
                <View style={[styles.padding16, styles.borderbtmLight, styles.flexRow]}>
                  <Image source={refundIcon} style={[styles.refundIcon, styles.mar10Rt]} />
                  <Text style={[styles.blackFont, styles.BlackText, styles.font14]}>
                    SELECT REFUND MODE
                  </Text>
                </View>
                {!isEmpty(this.penalty.cancellationRefundMode.message) && (
                  <View style={styles.refundModeMessageCard}>
                    <Text style={[styles.BlackText]}>
                      {this.penalty.cancellationRefundMode.message}
                    </Text>
                  </View>
                )}
                {!isEmpty(this.penalty.emiCancellationMessage) && (
                  <View style={styles.refundModeMessageCard}>
                    <Text style={[styles.BlackText]}>{this.penalty.emiCancellationMessage}</Text>
                  </View>
                )}
                <View style={[styles.ActionBtn, styles.mar15Top]}>
                  {this.getRefundModeList(this.penalty.cancellationRefundMode.refundModeList)}
                </View>
                {this.penalty.instantRefundData &&
                  this.penalty.instantRefundData.showInstantRefundBanner && (
                    <InstantRefundBanner handleOverlay={this.handleBottomOverlay} />
                  )}
              </View>
            </View>
          </View>
        </ScrollView>
        <View style={irstyles.footerBtnWrapper}>
          <BaseButton
            variant={BUTTON_VARIANT.CAPSULE}
            text="CONFIRM CANCELLATION"
            buttonStyle={[styles.CapsuleBtnFill, styles.marginTop10]}
            clickHandler={() => this.handleConfirmClickBtn()}
            textStyle={styles.regularFont}
          />
        </View>

        {this.state.isOverlayVisible && (
          <CommonOverlayMessage
          handleBottomOverlay={this.onOverlayBackPress}
          isBorderRadius={true}
            content = {<CabRefundOverlay
              backPress={this.onOverlayBackPress}
              selectedRefundMode={this.state.radiobtn}
              bookingId={this.props.response.bookingId}
              parentBookingId={this.props.response.parentBookingId}
              pageName={pageName}
              cabCrossSellBooking={this.isCabCrossSellBooking()}
              email={this.props.response.primaryCustomerDetails.emailId}
              emiBooking={this.penalty.emiBooking}
              cancellationReason={this.props.cancellationReason}
              isRefundPage={true}
              toggleOverlay={this.toggleOverlay}
              isMyGate={this.props.isMyGate}
            />}
          />
        )}
        {this.state.bottomOverlay === PSC.OVERLAY && (
          <OverlayMessage
            handleBottomOverlay={this.handleBottomOverlay}
            content={
              <BottomOverlayDtls
                handleBottomOverlay={this.handleBottomOverlay}
                handleProceedButton={this.handleProceedButtonFn}
              />
            }
          />
        )}
        {this.state.bottomOverlay === PSC.OTP_OVERLAY && (
          <OverlayMessage
            handleBottomOverlay={this.handleBottomOverlay}
            keyboard={this.state.keyboard}
            content={
              <OTPBottomOverlay
                bookingId={this.bookingId}
                OTPValue={this.state.OTPValue}
                handleBottomOverlay={this.handleBottomOverlay}
                handleOTPValue={this.handleOTPValue}
                handleOTPOverlay={this.handleOTPOverlay}
                errorState={this.state.errorState}
                resendOtp={this.resendOtp}
              />
            }
          />
        )}
        {this.state.bottomOverlay === PSC.OTP_LOADER && (
          <OverlayMessage handleBottomOverlay={this.handleBottomOverlay} content={<OTPLoader />} />
        )}
      </View>
    );
  }

  handleBottomOverlay = (showOverlay) => {
    this.setState({ bottomOverlay: showOverlay, OTPValue: PSC.EMPTY_STRING });
  };

  handleProceedButtonFn = () => {
    PostSalesBookingTrackingHelper.trackClickEvent(
      PSC.REFUNDMODE_PAGE_NAMES.CAR,
      PSC.REGISTER_INSTANT_REFUND_CLICKED,
      this.props.response.bookingId,
    );
    this.handleBottomOverlay(PSC.EMPTY_STRING);
    registerForInstantRefund(PSC.LOBNAMES.CAR);
  };

  handleConfirmClickBtn = () => {
    if (this.instantData != null) {
      this.sendOtp();
    } else {
      PostSalesBookingTrackingHelper.trackClickEvent(
        PSC.REFUNDMODE_PAGE_NAMES.CAR,
        PSC.NO_INSTANT_REFUND_REQUESTED,
        this.props.response.bookingId,
      );
      this.toggleOverlay();
    }
  };

  resendOtp = () => {
    PostSalesBookingTrackingHelper.trackClickEvent(
      PSC.REFUNDMODE_PAGE_NAMES.CAR,
      PSC.INSTANT_REFUND_OTP_REQUESTED_AGAIN,
      this.props.response.bookingId,
    );
    this.sendOtp();
  };

  async sendOtp() {
    this.clientTxnId = await sendOtp(this.props.response.bookingId);
    if (isNonEmpty(this.clientTxnId)) {
      PostSalesBookingTrackingHelper.trackClickEvent(
        PSC.REFUNDMODE_PAGE_NAMES.CAR,
        PSC.INSTANT_REFUND_OTP_SUCCESS,
        this.props.response.bookingId,
      );
      this.setState({ bottomOverlay: PSC.OTP_OVERLAY });
    } else {
      /* no screen for otp sending failure right now. Alert temp */
      PostSalesBookingTrackingHelper.trackClickEvent(
        PSC.REFUNDMODE_PAGE_NAMES.CAR,
        PSC.INSTANT_REFUND_OTP_FAILED,
        this.props.response.bookingId,
      );
      Alert.alert(PSC.OTP_FAILED_ALERT);
    }
  }

  handleOTPValue = (EnterText) => {
    this.setState({
      OTPValue: EnterText,
      errorState: false,
    });
  };

  handleOTPOverlay = () => {
    if (this.state.OTPValue === PSC.EMPTY_STRING) {
      this.setState({
        errorState: true,
      });
    } else {
      this.setState({ bottomOverlay: PSC.OTP_LOADER });
      this.verifyOtp();
    }
  };

  async verifyOtp() {
    const authorizationToken = await verifyOtp(
      this.bookingId,
      this.state.OTPValue,
      this.clientTxnId,
    );
    if (!authorizationToken) {
      PostSalesBookingTrackingHelper.trackLoadEvent(
        PSC.REFUNDMODE_PAGE_NAMES.CAR,
        PSC.WRONG_OTP_ENTERED,
        this.props.response.bookingId,
      );
      this.setState({ bottomOverlay: PSC.OTP_OVERLAY, errorState: true });
    } else {
      PostSalesBookingTrackingHelper.trackClickEvent(
        PSC.REFUNDMODE_PAGE_NAMES.CAR,
        PSC.INSTANT_REFUND_REQUESTED,
        this.props.response.bookingId,
      );
      this.setState({ bottomOverlay: PSC.EMPTY_STRING });
      Actions.CabCancelRequest({
        bookingId: this.bookingId,
        selectedRefundMode: this.state.radiobtn,
        cabCrossSellBooking: this.isCabCrossSellBooking(),
        email: this.props.response.primaryCustomerDetails.emailId,
        parentBookingId: this.props.response.parentBookingId,
        instantData: this.instantData,
        reason: this.props.cancellationReason,
        authorizationToken: authorizationToken,
      });
    }
  }

  refreshCar = () => {
    this.setState({ viewState: ViewState.LOADING });
  };

  retry = () => {
    this.updateAPIData();
  };

  updateRefundMode = (updatedMode) => {
    if (updatedMode.accountNo && updatedMode.accountIdentifier) {
      PostSalesBookingTrackingHelper.trackLoadEvent(
        PSC.REFUNDMODE_PAGE_NAMES.CAR,
        PSC.INSTANT_REFUND_AVAILABLE,
        this.props.response.bookingId,
      );
      this.instantData = {
        requestInstantRefund: true,
        accountNo: updatedMode.accountNo,
        accountIdentifier: updatedMode.accountIdentifier,
      };
    } else {
      this.instantData = null;
    }
  };

  async updateAPIData() {
    try {
      const hasNetwork = await isNetworkAvailable();
      if (!hasNetwork) {
        this.setState({ viewState: ViewState.NO_INTERNET });
        return;
      }
      const request = await retrieveObjFromAsyncStorage(this.cancelRequestKey);
      const { mmtAuth, loggingTrackingInfo } = await HotelBookingModule.getRequestHeader(
        this.bookingId,
        PSC.CAB_CANCEL_DETAILS,
      );
      const response = await fetch(CAB_CANCELLATION_URL, {
        method: PSC.POST,
        body: JSON.stringify(request),
        headers: await getCommonHeaders(mmtAuth, loggingTrackingInfo),
      });

      if (response.ok) {
        const body = await response.json();
        this.penaltyResponse = body;
        this.setState({ viewState: ViewState.SHOW_DETAIL });
      } else {
        this.setState({ viewState: ViewState.ERROR });
      }
    } catch (error) {
      this.setState({ viewState: ViewState.ERROR });
    }
  }

  render() {
    return (
      <View style={AtomicCss.flex1}>
        {this.state.viewState === ViewState.LOADING && <PageLoader updateAPIData={this.retry} />}
        {this.state.viewState === ViewState.NO_INTERNET && (
          <NoNetworkView retryFunction={this.refreshCar} />
        )}
        {this.state.viewState === ViewState.ERROR && (
          <PageLoadingError retryFunction={this.refreshCar} />
        )}
        {this.state.viewState === ViewState.SHOW_DETAIL && this.renderPage()}
      </View>
    );
  }

  getRefundModeList(refundModeList) {
    const refundModes = [];

    Object.keys(refundModeList).forEach((mode) => {
      const modeName =
        refundModeList[mode].refundModeReason === PSC.DEAFULTREFUNDMODE
          ? 'Refund_To_Customer'
          : 'Refund_To_Wallet';
      refundModes.push(
        <TouchableOpacity
          style={[styles.showView]}
          onPress={() => {
            this.radio(modeName);
            this.updateRefundMode(refundModeList[mode]);
          }}
        >
          <View style={styles.refundCardOuter}>
            <View style={[styles.refundCardWrap]}>
              <View style={[styles.flexRow, AtomicCss.flex1]}>
                <View style={styles.MimaRadio}>
                  {!isNonEmpty(refundModeList[mode].accountNo) && (
                    <View
                      style={[
                        this.state.radiobtn === modeName && !this.instantData
                          ? styles.MimaRadioInside
                          : '',
                      ]}
                    />
                  )}
                  {isNonEmpty(refundModeList[mode].accountNo) && (
                    <View
                      style={[
                        this.state.radiobtn === modeName &&
                        this.instantData &&
                        this.instantData.accountNo === refundModeList[mode].accountNo
                          ? styles.MimaRadioInside
                          : '',
                      ]}
                    />
                  )}
                </View>
                <View style={styles.refundCardLeft}>
                  <Text style={[styles.BlackText, styles.font14, styles.mediumFont]}>
                    {refundModeList[mode].title}
                  </Text>
                  <Text style={[styles.font12, styles.DefaultText, styles.mar3Bt]}>
                    {/* {this.response.primaryCustomerDetails.emailId} */}
                  </Text>
                  {refundModeList[mode].subText.map((subText, index) => (
                    <Text
                      style={[
                        styles.font12,
                        index % 2 == 1 ? styles.lightGrey : styles.BlackText,
                        styles.lineHeight18,
                        AtomicCss.flexWrap
                      ]}
                    >
                      {subText}
                    </Text>
                  ))}
                </View>
              </View>
              <Text style={[styles.BlackText, styles.boldFont, styles.font14]}>
                {appendRupeeSymbol(Math.round(this.penalty.refundAmount))}
              </Text>
            </View>
            {isNonEmpty(refundModeList[mode].accountNo) && (
              <View style={{ paddingLeft: 32, paddingTop: 10 }}>
                <LinearGradient
                  start={{ x: 1.0, y: 0.0 }}
                  end={{ x: 0.0, y: 1.0 }}
                  colors={getInstantRefundGradient()}
                  style={styles.instantRefundTag}
                >
                  <Text style={styles.instantRefundTagTxxt}>{PSC.INSTANT_REFUND}</Text>
                </LinearGradient>
              </View>
            )}
          </View>
        </TouchableOpacity>,
      );
    });
    return refundModes;
  }

  componentDidMount() {
    if (
      this.penalty &&
      this.penalty.instantRefundData &&
      this.penalty.instantRefundData.showInstantRefundBanner
    ) {
      PostSalesBookingTrackingHelper.trackLoadEvent(
        PSC.REFUNDMODE_PAGE_NAMES.CAR,
        PSC.REGISTER_INSTANT_REFUND_SHOWN,
        this.props.response.bookingId,
      );
    } else {
      PostSalesBookingTrackingHelper.trackLoadEvent(
        PSC.REFUNDMODE_PAGE_NAMES.CAR,
        PSC.INSTANT_REFUND_NOT_ELIGBLE,
        this.props.response.bookingId,
      );
    }
    super.componentDidMount();
  }

  onHardBackPress = () => {
    if (this.state.isOverlayVisible) {
      this.toggleOverlay();
      return true;
    }
    return false;
  };
  goBack = () => {
    Actions.pop();
  };
  onOverlayBackPress = () => {
    this.toggleOverlay();
  };

  isCabCrossSellBooking() {
    return this.props.response.parentPage === 'FLIGHT_DETAILS_PAGE';
  }
}
CancellationRefundMode.propTypes = {
  response: PropTypes.object.isRequired,
  penaltyResponse: PropTypes.object.isRequired,
};
export default CancellationRefundMode;
