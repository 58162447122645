import React from 'react';
import {View} from 'react-native';
import PropTypes from 'prop-types';
import styles from '../../CSS/VisaMamiCss';
import VisaFAQCollapsed from './VisaFAQCollapsed';

export default class VisaFAQListing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeCard: null,
      collapseActiveCard: true
    };
  }

  tabbingFn =(index, nextState) =>{
    this.setState({
      activeCard: index,
      collapseActiveCard: nextState
    });
  }

    static navigationOptions = {
      header: null
    };


    render() {
      return (
        <View style={styles.elevation1}>
          <View style={{}}>
            {
                        this.renderFaqs()
                    }
          </View>
        </View>
      );
    }

    renderFaqs = () => {
      const faqs = [];
      if (this.props.faqs) {
        this.props.faqs.forEach((element, index) => {
          const isActive = index === this.state.activeCard
            ? this.state.collapseActiveCard
            : false;
          faqs.push(this.faqsCollapsedView(element, index, isActive));
        });
      }
      return faqs;
    }


    faqsCollapsedView = (item, index, isActive) => (
      <VisaFAQCollapsed
        key={index}
        index={index}
        faq={item}
        isActive={isActive}
        tabbingFn={this.tabbingFn}
      />
    );
}

VisaFAQListing.propTypes = {
  faqs: PropTypes.array.isRequired,
  pageName: PropTypes.string.isRequired
};
