// Copied from https://stackoverflow.com/a/46181/1547530
export const isValidName = (name) => {
  const nameRegex = /^[a-zA-Z ]+$/;
  return name && name.length >= 1 && nameRegex.test(name);
};
export const isValidEmail = (email) => {
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(email.toLowerCase());
};

export const isValidGstNumber = (number) => {
  const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
  return gstRegex.test(number);
};

export const isValidMobile = (mobile) => {
  const mobileRegex = /^\d{10}$/;
  return mobile.match(mobileRegex);
};

export const isValidIntlMobile = (mobile) => {
  const mobileRegex = /^\d{4,12}$/;
  return mobile.match(mobileRegex);
};

export const isValidMobileCode = (mobileCode) => {
  const mobileRegex = /^\d{2,4}$/;
  return mobileCode.match(mobileRegex);
};

export const isValidURL = (url) => {
  const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(url);
};

export const isValidGstNum = (gstNum) => {
  const gstRegex = /^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5})([0-9]{4})([a-zA-Z]{1}[1-9a-zA-Z]{1})([zZ]{1})([0-9a-zA-Z]{1})+$/;
  return gstNum.match(gstRegex);
};

export const isOnlyNumber = (value) => {
  return /^[0-9]*$/.test(value);
}