import get from 'lodash/get';
import { getFont } from '../../../../PostSalesStyles';
import FlightConst from '../FlightBookingDetailsConstant';
import {
  SM_BELT_ICON,
  YELLOW_BELT_ICON,
  GATE_ICON_BLUE,
  CIRCLED_SUCCESS_ICON,
  RED_CROSS_ICON,
  ALARM_ICON,
  DEALYED_ICON,
  DEFAULT_ICON,
  CANCELLED_FLT_ICON,
  GATE_ICON_YELLOW,
} from '../../../FlightImageConstants';

export const getSegmentStatusForCard = (segmentDetail, styles) => {
  const fonts = getFont(true);
  const bookingSegmentStatus = get(segmentDetail, 'bookingSegmentStatus', '');
  const segmentStatusDesc = get(segmentDetail, 'segmentStatusDesc', '');
  const airlineLink = get(segmentDetail, 'bookingSegmentAction.url', '');
  const title = get(segmentDetail, 'bookingSegmentAction.title', '');
  const bookingSegmentAction = get(segmentDetail, 'bookingSegmentAction', '');
  const result = {};
  if (bookingSegmentStatus === 'BLANK') {
    result.textStyle = [styles.confirmUseCaseTxt, fonts.boldFontFamily];
    result.imageSource = CIRCLED_SUCCESS_ICON;
    result.backGround = styles.confirmSection;
    result.iconStyle = styles.defaultIcon;
  } else if (bookingSegmentStatus === 'FAILED') {
    result.textStyle = [styles.errorUseCaseTxt, fonts.boldFontFamily];
    result.imageSource = RED_CROSS_ICON;
    result.backGround = styles.errorSection;
    result.iconStyle = styles.defaultIcon;
  } else if (bookingSegmentStatus === 'AWAITED') {
    result.textStyle = [styles.delayUseCaseTxt, fonts.boldFontFamily];
    result.imageSource = ALARM_ICON;
    result.backGround = styles.ticketSection;
    result.iconStyle = styles.delayIcon;
  } else if (bookingSegmentStatus === 'SCHEDULE_CHANGE') {
    result.textStyle = [styles.delayUseCaseTxt, fonts.boldFontFamily];
    result.imageSource = DEALYED_ICON;
    result.backGround = styles.ticketSection;
    result.iconStyle = styles.delayIcon;
  } else if (bookingSegmentStatus === 'DEFAULT_CHANGE') {
    result.textStyle = [styles.defaultUseCaseTxt, fonts.boldFontFamily];
    result.imageSource = DEFAULT_ICON;
    result.backGround = styles.ticketSection;
    result.iconStyle = styles.defaultChangeIcon;
  } else if (bookingSegmentStatus === 'CANCELLED' || bookingSegmentStatus === 'SEAT_NOT_ATTACHED') {
    result.textStyle = [styles.delayUseCaseTxt, fonts.boldFontFamily];
    result.imageSource = CANCELLED_FLT_ICON;
    result.backGround = styles.ticketSection;
    result.iconStyle = styles.delayIcon;
  }
  return {
    segmentStatusDesc,
    bookingSegmentStatus,
    cardTheme: result,
    airlineLink,
    title,
    bookingSegmentAction,
  };
};

export const getFISIcon = (type, state) => {
  if (state === FlightConst.INITIAL_SCHEDULE_CHANGE_STATE) {
    switch (type) {
      case FlightConst.SCHEDULE_CHANGE_GATE_CHECKIN:
        return GATE_ICON_BLUE;
      case FlightConst.SCHEDULE_CHANGE_BELT:
        return SM_BELT_ICON;
      default:
        return GATE_ICON_BLUE;
    }
  } else {
    switch (type) {
      case FlightConst.SCHEDULE_CHANGE_GATE_CHECKIN:
        return GATE_ICON_YELLOW;
      case FlightConst.SCHEDULE_CHANGE_BELT:
        return YELLOW_BELT_ICON;
      default:
        return GATE_ICON_YELLOW;
    }
  }
};
