import React, { useCallback, useMemo } from 'react';
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  DeviceEventEmitter,
  Platform,
  NativeModules,
  StyleSheet,
} from 'react-native';
import HTMLView from 'react-native-htmlview';
import noop from 'lodash/noop';
import styles from './bottomOverlayDtlsCss';
import { DOWNLOAD_IMG } from '../../../FlightImageConstants';
import SpecialClaimAddAttachmentModule from '@mmt/legacy-commons/Native/SpecialClaimAddAttachmentModule';
import SpecialClaimTrackingHelper from '../../Helpers/SpecialClaimTrackingHelper';
import { getFont } from '../../../../PostSalesStyles';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';

const UploadProofBottomOverlay = React.memo(
  ({
    onFileChanged,
    setAttachmentList,
    attachmentLst,
    submitProofInfo,
    showAgreeOverlay,
    trackingPageName,
    isFullBlocked,
    closePopupHandler,
  }) => {
    const { fsStyle, ...fonts } = getFont(true);
    const htmlTitleStyle = StyleSheet.create({
      q: {
        fontFamily: fonts.regular,
        lineHeight: 20,
      },
      p: {
        color: colors.black,
        fontSize: fonts.fontSize.font14,
        lineHeight: 20,
      },
      b: {
        fontFamily: fonts.bold,
        color: colors.black,
        fontSize: fonts.fontSize.font18,
        lineHeight: 20,
      },
    });
    const onContinue = useCallback(() => {
      SpecialClaimTrackingHelper.trackClickEvent(
        trackingPageName,
        `MI_FLT_SClaim_${isFullBlocked ? 'full' : 'part'}block_Continue`,
      );
      showAgreeOverlay();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isWebPlatform = Platform.OS === 'web';
    const FileUploaderComp = useMemo(() => {
      if (isWebPlatform) {
        const {
          FileUploaderModule: { FileUploader },
        } = NativeModules;
        return FileUploader;
      }
      return null;
    }, [isWebPlatform]);

    const onAddAttachment = useCallback(() => {
      SpecialClaimTrackingHelper.trackClickEvent(
        trackingPageName,
        `MI_FLT_SClaim_${isFullBlocked ? 'full' : 'part'}block_Upload`,
      );
      const eventKey = 'special_claim_review_page';
      DeviceEventEmitter.addListener(eventKey, (data) => {
        DeviceEventEmitter.removeAllListeners(eventKey);
        const map = JSON.parse(JSON.stringify(attachmentLst));
        map.push(data);
        onFileChanged(map);
        setAttachmentList(map);
      });
      SpecialClaimAddAttachmentModule.onButtonClicked();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const getCta = useCallback(
      (ctaId) => {
        const actionId =
          submitProofInfo &&
          submitProofInfo.ctaList &&
          submitProofInfo.ctaList.length > 0 &&
          submitProofInfo.ctaList.filter((cta) => cta.actionId === ctaId);
        if (actionId && actionId.length > 0) {
          return actionId[0].text;
        }
        return '';
      },
      [submitProofInfo],
    );
    return (
      <View style={[styles.overlayContent, styles.submittedWrapper]}>
        <View style={styles.uploadIconWrapper}>
          <Image style={styles.uploadIconstyle} source={DOWNLOAD_IMG} />
        </View>
        {!!submitProofInfo.heading && (
          <Text style={[styles.contentTitle, fonts.blackFontFamily, fsStyle.font28]}>
            {submitProofInfo.heading}
          </Text>
        )}
        {!!submitProofInfo.desc && (
          <HTMLView
            style={[styles.desc, fonts.regularFontFamily, fsStyle.font16]}
            stylesheet={htmlTitleStyle}
            value={submitProofInfo.desc}
          />
        )}

        <View style={[styles.contentFooter]} onPress={closePopupHandler}>
          {!isWebPlatform && (
            <TouchableOpacity onPress={isWebPlatform ? noop() : onAddAttachment}>
              {!!getCta('UPLOAD_PROOF') && (
                <Text style={[styles.actionBtnText, fonts.blackFontFamily, fsStyle.font16]}>
                  {getCta('UPLOAD_PROOF')}
                </Text>
              )}
            </TouchableOpacity>
          )}
          {isWebPlatform && !!FileUploaderComp && (
            <FileUploaderComp
              onFileChanged={onFileChanged}
              setAttachmentList={setAttachmentList}
              attachmentLst={attachmentLst}
              text={getCta('UPLOAD_PROOF')}
            />
          )}
          <TouchableOpacity onPress={onContinue}>
            {!!getCta('PROOF_CONTINUE') && (
              <Text style={[styles.actionBtnText, fonts.blackFontFamily, fsStyle.font16]}>
                {getCta('PROOF_CONTINUE')}
              </Text>
            )}
          </TouchableOpacity>
        </View>
      </View>
    );
  },
);

export default UploadProofBottomOverlay;
