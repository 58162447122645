import React from 'react';

import BenefitsClaimedCard from '../../../../../../Common/BenefitsClaimedCard';
import { Props } from './types';

const LoyalityCard = (props: Props) => {
  const data = props.data || {};

  if (!data?.heading) {
    return null;
  }

  return <BenefitsClaimedCard {...data} isDetailV2={true} />;
};

export default LoyalityCard;
