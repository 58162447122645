import React, { useCallback, useEffect } from 'react';
import { View, Platform, DeviceEventEmitter } from 'react-native';
import InvoiceList from './InvoiceList';
import useApi from '../../../../../Common/useApi';
import { DOWNLOAD_INVOICE_API } from '../../../../../utils/NetworkUtils';

import useHardwareBackpress from '../../../../../Common/useHardwareBackpress';
import ShimmerCard from "../../../../../Common/ShimmerCard"
import { LOBNAMES } from '../../../../../PostSalesConstant';
import CabBookingDetailsConstant from 'apps/post-sales/src/cab/CabBookingDetailsConstant';
import DownloadInvoiceError from './DownloadInvoiceError';
import { DownloadInvoiceOverlayProps } from 'apps/post-sales/src/cab/types';
import getStyle from './styles';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';

const DownloadInvoiceOverlay: React.FC<DownloadInvoiceOverlayProps> = ({ bookingId, handleBottomOverlay, isCompletedBooking }) => {
  const [invoiceInProgress, invoiceResponse, invoiceApi] = useApi(DOWNLOAD_INVOICE_API);
  useHardwareBackpress().addListener(handleBottomOverlay, true);
  const apiCall = useCallback(() => {
    invoiceApi.httpPost({
      body: {
        bookingid: bookingId,
        userId: Platform.OS === 'web' ? 'PWA' : Platform.OS.toUpperCase(),
        lob: 'Car',
      },
      bookingId: bookingId,
      uniqueId: 'DOWNLOAD_INVOICE',
      psLob: LOBNAMES.CAR,
    });
  }, [invoiceApi, bookingId]);

  useEffect(() => {
    apiCall();
  }, [apiCall]);

  const {fsStyle, ...fonts} = getFont();
  const {psTheme} = useTheme();
  const styles = getStyle(fonts, psTheme);
  return (
    <View style={styles.OverlayContentWrapper}>
      <View style={styles.overlayTopline} />
      {invoiceInProgress && <ShimmerCard rows={2} />}
      {!invoiceInProgress && !invoiceResponse?.errorPageData && (
        <InvoiceList
          data={invoiceResponse}
          handleBottomOverlay={handleBottomOverlay}
          heading={CabBookingDetailsConstant.downloadInvoiceText}
          isCompletedBooking={isCompletedBooking}
        />
      )}
      {!invoiceInProgress &&
        (invoiceResponse?.error || invoiceResponse?.errorPageData) && (
          <DownloadInvoiceError onRetry={apiCall} />
        )}
    </View>
  );
};

export default DownloadInvoiceOverlay;
