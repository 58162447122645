/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import Actions from '../../../navigation/postSalesNavigation';
import { View, ScrollView, StyleSheet, DeviceEventEmitter, Alert, Keyboard } from 'react-native';
import StickyHeaderBack from '../../components/StickyHeader/StickyHeaderBack';
import ReviewStepHeader2 from '../../components/ReviewStepHeader/ReviewStepHeader2';
import ReviewStepHeader from '../../components/ReviewStepHeader/ReviewStepHeader';
import RecBlueBtn from '../../components/buttons/RecBlueBtn';
import AtomicCss from '../../commonStyles/AtomicCss';
import RefundModeList from './RefundModeList';
import RailBookingTrackingHelper from '../../details/RailBookingTrackEventHelper';
import OverlayAlert from '../../components/OverlayAlert/OverlayAlert';
import {
  getCancelRequestKeyName,
  registerForInstantRefund,
  retrieveObjFromAsyncStorage,
  sendOtp,
  verifyOtp,
} from '../../../Common/commonUtil';
import * as PSC from '../../../PostSalesConstant';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import PageLoader from '../../../Common/Cancellation/PageLoader';
import NoNetworkView from '../../../Common/Cancellation/NoNetworkView';
import PageLoadingError from '../../../Common/Cancellation/PageLoadingError';
import OverlayMessage from '../../../Common/InstantRefund/Overlays/OverlayMessage';
import BottomOverlayDtls from '../../../Common/InstantRefund/Overlays/BottomOverlayDtls';
import OTPBottomOverlay from '../../../Common/InstantRefund/Overlays/OTPBottomOverlay';
import OTPLoader from '../../../Common/InstantRefund/Overlays/OTPLoader';
import { isNetworkAvailable } from '@mmt/legacy-commons/Common/utils/AppUtils';
import { showShortToast } from '@mmt/legacy-commons/Common/Components/Toast';
import { getCommonHeaders, RAIL_BOOKING_CANCEL_URL_V2 } from '../../../utils/NetworkUtils';
import { isNonEmpty } from '@mmt/legacy-commons/Common/utils/StringUtils';
import PostSalesBookingTrackingHelper from '../../../PostSalesBookingTrackingHelper';
import InstantRefundBanner from '../../../Common/InstantRefund/Overlays/InstantRefundBanner';
import { getStaticData } from '../../../staticData/staticData';

class RailRefundMode extends React.Component {
  static navigationOptions = {
    header: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      showAlert: false,
      bottomOverlay: PSC.EMPTY_STRING,
      OTPValue: PSC.EMPTY_STRING,
      errorState: false,
      keyboard: false,
      viewState: ViewState.SHOW_DETAIL,
    };
    this.bookingId = this.props.bookingDetails.bookingID;
    this.previewObject = this.props.previewObject;
    this.refundModeList = this.props.previewObject.cancellationRefundMode.refundModeList;
    this.selectedRefundMode = PSC.DEAFULTREFUNDMODE;
    this.cancelRequestKey = getCancelRequestKeyName(
      PSC.CancellationPreviewRequest.RAIL,
      this.bookingId,
    );
    this.instantData = null;
    this.refundReloadEventListener = null;
  }

  componentWillMount() {
    this.refundReloadEventListener = DeviceEventEmitter.addListener(PSC.InstantRefundReloadEvent.RAIL, this.refreshRail);
    this.keyboardDidShowListener = Keyboard.addListener(
      PSC.KEYBOARD_SHOW_EVENT,
      this._keyboardDidShow,
    );
    this.keyboardDidHideListener = Keyboard.addListener(
      PSC.KEYBOARD_HIDE_EVENT,
      this._keyboardDidHide,
    );
  }

  componentWillUnmount() {
    if (this.refundReloadEventListener) {
      this.refundReloadEventListener.remove();
    }
    this.keyboardDidShowListener && this.keyboardDidShowListener.remove();
    this.keyboardDidHideListener && this.keyboardDidHideListener.remove();
  }

  _keyboardDidShow = () => {
    this.setState({ keyboard: true });
  };

  _keyboardDidHide = () => {
    this.setState({ keyboard: false });
  };

  render() {
    return (
      <View style={AtomicCss.flex1}>
        {this.state.viewState === ViewState.LOADING && <PageLoader updateAPIData={this.retry} />}
        {this.state.viewState === ViewState.NO_INTERNET && (
          <NoNetworkView retryFunction={this.refreshRail} />
        )}
        {this.state.viewState === ViewState.ERROR && (
          <PageLoadingError retryFunction={this.refreshRail} />
        )}
        {this.state.viewState === ViewState.SHOW_DETAIL && this.renderPage()}
      </View>
    );
  }

  renderPage() {
    const staticData = getStaticData();
    const {
      trainCancellationText: {
        additionalText: { finalStepCancellationessage },
      },
      confirmAndInitiateRfndText,
      yesCancelText,
      noGoBackText,
      youSureText,
      youWishToCancelText,
    } = staticData;
    RailBookingTrackingHelper.trackLoadEvent('chooseRefundMode', this.props.bookingDetails);
    const instantRefundDisplay =
      this.previewObject.instantRefundData &&
      this.previewObject.instantRefundData.showInstantRefundBanner;
    if (instantRefundDisplay) {
      RailBookingTrackingHelper.trackLoadEvent('instantRefundBanner', this.props.bookingDetails);
      PostSalesBookingTrackingHelper.trackLoadEvent(
        PSC.REFUNDMODE_PAGE_NAMES.RAIL,
        PSC.REGISTER_INSTANT_REFUND_SHOWN,
        this.props.bookingDetails.bookingID,
      );
    } else {
      PostSalesBookingTrackingHelper.trackLoadEvent(
        PSC.REFUNDMODE_PAGE_NAMES.RAIL,
        PSC.INSTANT_REFUND_NOT_ELIGBLE,
        this.props.bookingDetails.bookingID,
      );
    }

    return (
      <View style={styles.CancellationWrapper}>
        <StickyHeaderBack
          trainDetails={this.props.bookingDetails ? this.props.bookingDetails.train : null}
        />
        <ScrollView style={AtomicCss.greyBg}>
          {this.props.isFullCancellation === true && <ReviewStepHeader currentIndex={2} />}
          {this.props.isFullCancellation === false && <ReviewStepHeader2 currentIndex={3} />}
          <RefundModeList
            refundModeList={this.refundModeList}
            previewObj={this.previewObject}
            changeSelectedRefundMode={this.changeSelectedRefundMode}
            instantRefund={this.instantData}
          />
          {instantRefundDisplay && <InstantRefundBanner handleOverlay={this.handleBottomOverlay} />}
        </ScrollView>
        <RecBlueBtn
          btntext={confirmAndInitiateRfndText}
          onPressHandler={() => this.handleConfirmClickBtn()}
        />
        {this.state.showAlert && (
          <OverlayAlert
            positiveBtnText={yesCancelText}
            negativeBtnText={noGoBackText}
            positiveBtnOnTap={this.cancelBookingBtnTapped}
            negativeBtnOnTap={this.backButtonTapped}
            titleText={youSureText}
            heading2Text={youWishToCancelText}
            subTitleText={finalStepCancellationessage}
            bgTapAction={this.backButtonTapped}
          />
        )}
        {this.state.bottomOverlay === PSC.OVERLAY && (
          <OverlayMessage
            handleBottomOverlay={this.handleBottomOverlay}
            content={
              <BottomOverlayDtls
                handleBottomOverlay={this.handleBottomOverlay}
                handleProceedButton={this.handleProceedButtonFn}
              />
            }
          />
        )}
        {this.state.bottomOverlay === PSC.OTP_OVERLAY && (
          <OverlayMessage
            handleBottomOverlay={this.handleBottomOverlay}
            keyboard={this.state.keyboard}
            content={
              <OTPBottomOverlay
                bookingId={this.bookingId}
                OTPValue={this.state.OTPValue}
                handleBottomOverlay={this.handleBottomOverlay}
                handleOTPValue={this.handleOTPValue}
                handleOTPOverlay={this.handleOTPOverlay}
                errorState={this.state.errorState}
                resendOtp={this.resendOtp}
              />
            }
          />
        )}
        {this.state.bottomOverlay === PSC.OTP_LOADER && (
          <OverlayMessage handleBottomOverlay={this.handleBottomOverlay} content={<OTPLoader />} />
        )}
      </View>
    );
  }

  handleConfirmClickBtn = () => {
    RailBookingTrackingHelper.trackClickEvents('Confirm cancel button', 'Cancellation_Confirmed');
    if (this.instantData != null) {
      this.sendOtp();
    } else {
      PostSalesBookingTrackingHelper.trackClickEvent(
        PSC.REFUNDMODE_PAGE_NAMES.RAIL,
        PSC.NO_INSTANT_REFUND_REQUESTED,
        this.props.bookingDetails.bookingID,
      );
      this.onClickHandler();
    }
  };

  handleBottomOverlay = (showOverlay) => {
    this.setState({ bottomOverlay: showOverlay, OTPValue: PSC.EMPTY_STRING });
  };

  handleProceedButtonFn = () => {
    PostSalesBookingTrackingHelper.trackClickEvent(
      PSC.REFUNDMODE_PAGE_NAMES.RAIL,
      PSC.REGISTER_INSTANT_REFUND_CLICKED,
      this.props.bookingDetails.bookingID,
    );
    this.handleBottomOverlay(PSC.EMPTY_STRING);
    registerForInstantRefund(PSC.LOBNAMES.RAIL);
  };

  handleOTPValue = (EnterText) => {
    this.setState({
      OTPValue: EnterText,
      errorState: false,
    });
  };

  handleOTPOverlay = () => {
    if (this.state.OTPValue === PSC.EMPTY_STRING) {
      this.setState({
        errorState: true,
      });
    } else {
      this.setState({ bottomOverlay: PSC.OTP_LOADER });
      this.verifyOtp();
    }
  };

  resendOtp = () => {
    PostSalesBookingTrackingHelper.trackClickEvent(
      PSC.REFUNDMODE_PAGE_NAMES.RAIL,
      PSC.INSTANT_REFUND_OTP_REQUESTED_AGAIN,
      this.props.bookingDetails.bookingID,
    );
    this.sendOtp();
  };

  async sendOtp() {
    const staticData = getStaticData();
    RailBookingTrackingHelper.trackClickEvents('OTP request sent', 'Cancellation_Confirmed');
    this.clientTxnId = await sendOtp(this.bookingId);
    if (isNonEmpty(this.clientTxnId)) {
      PostSalesBookingTrackingHelper.trackClickEvent(
        PSC.REFUNDMODE_PAGE_NAMES.RAIL,
        PSC.INSTANT_REFUND_OTP_SUCCESS,
        this.props.bookingDetails.bookingID,
      );
      this.setState({ bottomOverlay: PSC.OTP_OVERLAY });
    } else {
      /* no screen for otp sending failure right now. Alert temp */
      RailBookingTrackingHelper.trackErrorEvent('OTP sending failed');
      PostSalesBookingTrackingHelper.trackClickEvent(
        PSC.REFUNDMODE_PAGE_NAMES.RAIL,
        PSC.INSTANT_REFUND_OTP_FAILED,
        this.props.bookingDetails.bookingID,
      );
      Alert.alert(staticData.errorText.sendingOtpFailedError);
    }
  }

  async verifyOtp() {
    const authorizationToken = await verifyOtp(
      this.bookingId,
      this.state.OTPValue,
      this.clientTxnId,
    );
    if (!authorizationToken) {
      PostSalesBookingTrackingHelper.trackLoadEvent(
        PSC.REFUNDMODE_PAGE_NAMES.RAIL,
        PSC.WRONG_OTP_ENTERED,
        this.props.bookingDetails.bookingID,
      );
      this.setState({ bottomOverlay: PSC.OTP_OVERLAY, errorState: true });
    } else {
      PostSalesBookingTrackingHelper.trackClickEvent(
        PSC.REFUNDMODE_PAGE_NAMES.RAIL,
        PSC.INSTANT_REFUND_REQUESTED,
        this.props.bookingDetails.bookingID,
      );
      this.setState({ bottomOverlay: PSC.EMPTY_STRING });
      this.pushBookingCancelView(authorizationToken);
    }
  }

  refreshRail = () => {
    this.setState({ viewState: ViewState.LOADING });
  };

  retry = () => {
    this.updateAPIData();
  };

  async updateAPIData() {
    const staticData = getStaticData();
    try {
      const hasNetwork = await isNetworkAvailable();
      if (!hasNetwork) {
        this.setState({ viewState: ViewState.NO_INTERNET });
        return;
      }
      const url = RAIL_BOOKING_CANCEL_URL_V2;
      const commonHeaders = await getCommonHeaders(this.props.mmtAuth, this.props.loggingTrackInfo);
      const request = await retrieveObjFromAsyncStorage(this.cancelRequestKey);

      const response = await fetch(url, {
        method: PSC.POST,
        body: JSON.stringify(request),
        headers: commonHeaders,
      });

      if (response.ok) {
        const responseBody = await response.json();
        this.previewObject = responseBody;
        this.refundModeList = this.props.previewObject.cancellationRefundMode.refundModeList;
        if (
          (responseBody.response && responseBody.response.errorText.length > 0) ||
          !responseBody.ticketCancellationPreview ||
          Object.keys(responseBody.ticketCancellationPreview).length === 0
        ) {
          this.setState({ viewState: ViewState.ERROR });
        } else {
          RailBookingTrackingHelper.trackLoadEvent(
            'Fetch Cancellation Penalty',
            this.props.bookingDetails,
          );
          this.setState({ viewState: ViewState.SHOW_DETAIL });
        }
      } else {
        RailBookingTrackingHelper.trackErrorEvent('Fetch Cancellation Penalty Error');
        showShortToast(staticData.errorText.somethingWentWrongTryAgainError);
      }
    } catch (error) {
      showShortToast(staticData.errorText.somethingWentWrongTryAgainError);
    }
  }

  onClickHandler = () => {
    RailBookingTrackingHelper.trackClickEvent(
      'chooseRefundMode',
      this.props.bookingDetails,
      'confirm_refund_initiate_clicked',
    );
    this.setState({ showAlert: true });
  };

  cancelBookingBtnTapped = () => {
    this.setState({ showAlert: false });
    this.pushBookingCancelView();
    RailBookingTrackingHelper.trackClickEvents(
      'Cancellation Screen Shown',
      'Cancellation_Confirmed',
    );
  };

  backButtonTapped = () => {
    this.setState({ showAlert: false });
    RailBookingTrackingHelper.trackClickEvents(
      'Cancellation Screen Shown',
      'Cancellation_NotConfirmed',
    );
  };

  pushBookingCancelView = (authorizationToken) => {
    Actions.railBookingCancelled({
      isFullCancellation: this.props.isFullCancellation,
      selectedRefundMode: this.selectedRefundMode,
      totalPaxCount: this.props.totalPaxCount,
      cancelledPaxLineId: this.props.cancelledPaxLineId,
      mmtAuth: this.props.mmtAuth,
      loggingTrackInfo: this.props.loggingTrackInfo,
      bookingID: this.props.bookingDetails.bookingID,
      bookingDetails: this.props.bookingDetails,
      refundAmount: this.props.refundAmount,
      instantData: this.instantData,
      reason: this.props.reason,
      activePaxCount: this.props.activePaxCount,
      authorizationToken: authorizationToken,
    });
  };

  changeSelectedRefundMode = (updatedMode) => {
    if (updatedMode.accountNo && updatedMode.accountIdentifier) {
      PostSalesBookingTrackingHelper.trackLoadEvent(
        PSC.REFUNDMODE_PAGE_NAMES.RAIL,
        PSC.INSTANT_REFUND_AVAILABLE,
        this.props.bookingDetails.bookingID,
      );
      this.instantData = {
        requestInstantRefund: true,
        accountNo: updatedMode.accountNo,
        accountIdentifier: updatedMode.accountIdentifier,
      };
    } else {
      this.instantData = null;
    }
    this.selectedRefundMode = updatedMode.refundModeReason;
  };
}

RailRefundMode.propTypes = {
  bookingDetails: PropTypes.object.isRequired,
  mmtAuth: PropTypes.string.isRequired,
  loggingTrackInfo: PropTypes.string.isRequired,
  previewObject: PropTypes.object.isRequired,
  isFullCancellation: PropTypes.bool.isRequired,
  selectedPax: PropTypes.array.isRequired,
  refundModeList: PropTypes.array.isRequired,
  refundAmount: PropTypes.number.isRequired,
};

RailRefundMode.defaultProps = {
  selectedPax: null,
};

const styles = StyleSheet.create({
  CancellationWrapper: {
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: '#f2f2f2',
  },
  PartCancellationHead: {
    backgroundColor: '#ffffff',
    paddingVertical: 18,
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
  },
});

export default RailRefundMode;
