import { StyleSheet } from 'react-native';

export default (theme) => {
  return StyleSheet.create({
    rowSection: {
      backgroundColor: theme.color.white,
      borderRadius: 16,
      padding: 16,
      flexDirection: 'row',
      alignItems: 'center',
    },
    iconStyle: {
      width: 20,
      height: 20,
      marginRight: 15,
    },
    rightArrowStyle: {
      width: 10,
      height: 15,
    },
  });
};
