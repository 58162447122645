import { useState } from 'react';
import { Alert } from 'react-native';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import Actions from '../../../navigation/postSalesNavigation';
import { openErrorPage } from '../../../utils/PostSaleUtil';
import { invalidateTrips } from '@mmt/legacy-commons/Helpers/genericHelper';
import { FLIGHT_CANCELLATION_DONE_URL, getCommonHeaders } from '../../../utils/NetworkUtils';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import { isNetworkAvailable } from '@mmt/legacy-commons/Common/utils/AppUtils';
import SpecialClaimTrackingHelper from '../Helpers/SpecialClaimTrackingHelper';
import { LOBNAMES } from '../../../PostSalesConstant';

const useSubmitSpecialClaim = (responseJson, bookingState, specialClaimData) => {
  const [status, setStatus] = useState(ViewState.SHOW_DETAIL);

  const trackPageLoad = (pageName, otherData) => {
    const intlLob = ['LOB02520', 'LOB02720', 'LOB01520'];
    const isIntlFlightBooking = (lobCode) => intlLob.indexOf(lobCode) !== -1;
    const trackingPageName = isIntlFlightBooking(responseJson.lobCode)
      ? `Mob:Customer support:Intl Flight:${pageName}`
      : `Mob:customer support:Dom Flight:${pageName}`;
    SpecialClaimTrackingHelper.trackLoadEvent(
      trackingPageName,
      responseJson,
      Object.assign(specialClaimData, otherData),
      bookingState,
    );
  };

  const submitRequest = async (payload, submitClickHandler) => {
    try {
      setStatus(ViewState.LOADING);

      const hasNetwork = await isNetworkAvailable();
      if (!hasNetwork) {
        Alert.alert('Please check your internet connection');
        return;
      }

      const userInfo = await HotelBookingModule.getRequestHeader(
        payload.bookingId,
        'SPECIAL_CLAIM',
      );
      const response = await fetch(FLIGHT_CANCELLATION_DONE_URL, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo, {
          psLob: LOBNAMES.FLIGHT,
        }),
      });

      if (response.ok) {
        const body = await response.json();
        if (body.errorPageData) {
          openErrorPage(
            body.errorPageData,
            undefined,
            submitClickHandler,
            payload.bookingId,
            'specialclaim',
            Actions,
            { psLob: LOBNAMES.FLIGHT },
          );
        } else if (body.raiseSpecialClaimData && body.raiseSpecialClaimData.success) {
          invalidateTrips();
          Actions.specialClaimRequestThankYou({
            bookingId: body.bookingId,
            ...body.raiseSpecialClaimData.successData,
          });
          trackPageLoad('SpecialclaimThankyou', {
            uploadFailed: payload.fileUrls && payload.fileUrls.length === 0,
          });
        } else {
          setStatus(ViewState.ERROR);
        }
      } else {
        setStatus(ViewState.ERROR);
      }
    } catch (error) {
      setStatus(ViewState.ERROR);
    }
  };

  return [status, submitRequest];
};
export default useSubmitSpecialClaim;
