import React from 'react';
import { View, Text } from 'react-native';
import { getFont } from '../../../../../PostSalesStyles';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import RefundVoucherCard from "../RefundVoucherCard";

const TripGuaranteeInfo = ({ tripGuaranteeInfo }) => {
    const { heading } = tripGuaranteeInfo || {};
    const fonts = getFont();
    return (
        <View style={[AtomicCss.paddingHz10, AtomicCss.marginBottom10]}>
            {!!heading &&
                <Text style={[AtomicCss.font12, AtomicCss.defaultText, fonts.regularFontFamily, AtomicCss.marginBottom15]} >{heading}</Text>
                }
            <View style={AtomicCss.flexRow}>
                <RefundVoucherCard tripGuaranteeInfo={tripGuaranteeInfo} />
            </View>
        </View>
    )
}


export default TripGuaranteeInfo