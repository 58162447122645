import React from 'react';
import {Image, Text, View} from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import styles from './TrackerCss';
import BasePage from '../../../../../Common/PostSalesBasePage';
import {isEmpty} from 'lodash';
import { getImagePath } from '../../../../../utils/PostSaleUtil';

class Tracker extends BasePage {

    constructor(props) {
        super(props, 'Trip End Tracker');
    }

    render() {
        const stepOneComplete = this.props.statusStep === 'stepOneComplete';
        const stepTwoComplete = this.props.statusStep === 'stepTwoComplete';
        const stepThreeComplete = this.props.statusStep === 'stepThreeComplete';
        const borderTopStyle = this.props.borderTopActive ? [styles.borderTopActive] : ''

        return (
            <View style={borderTopStyle}>
                {this.props.trackerRefundStatus === 'active' &&
                <View style={styles.trackerContainer}>
                    <View style={styles.progressLine}>
                        <View style={this.fetchActiveStyle(this.props.trackerList)}/>
                    </View>
                    <View style={styles.trackerDtls}>
                        {this.fetchTrackerHtml(this.props.trackerList)}
                    </View>
                </View>
                }
            </View>
        )
    }

    fetchActiveStyle(trackerList) {
        let defaultActiveStyle = [styles.defaultActiveStyle];
        Object.keys(trackerList).map((tracker, key) => {
            let {header, desc, subheader, endTime} = trackerList[key];
            if (key === 0 && this.enableTick(endTime)) {
                defaultActiveStyle.push(styles.stepOneLineActive)
            }
            if (key === 1 && this.enableTick(endTime)) {
                defaultActiveStyle.push(styles.stepTwoLineActive)
            }
            if (key === 2 && this.enableTick(endTime)) {
                defaultActiveStyle.push(styles.stepThreeLineActive)
            }
        })
        return defaultActiveStyle;
    }

    fetchTrackerHtml(trackerList) {
        let views = [];
        Object.keys(trackerList).map((tracker, key) => {
            let {header, desc, subheader, endTime} = trackerList[key];
            views.push(<View style={styles.trackerInnerDtls}>
                    <View style={styles.countWrapper}>
                        {this.enableTick(endTime) &&
                        <Image style={styles.tickIcon}
                               source={getImagePath('flight_top_card_greenTick.webp')}/>}
                        <Text style={styles.countTxt}>{key + 1}</Text>
                    </View>
                    <View>
                        <Text style={styles.trackerStatus}>{header}</Text>
                        <Text style={styles.trackerStatusTime}>{subheader}</Text>
                        {!isEmpty(desc) &&
                        <View style={{paddingRight: 50}}>
                            <Text style={[AtomicCss.marginTop5, styles.trackerStatusTime]}>{desc}</Text>
                        </View>
                        }
                    </View>
                </View>
            )
        })
        return views;
    }

    enableTick(endTime) {
        return endTime < new Date().getTime();
    }
}

export default Tracker
