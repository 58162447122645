import getPlatformElevation from '@mmt/legacy-commons/Common/Components/Card/getPlatformElevation';


const styles = {
  backgroundColorContainer: {
    backgroundColor: '#f2f2f2'
  },
  inclusionsWrapper: {
    backgroundColor: '#fff',
    ...getPlatformElevation(3),
    marginTop: 10,
    zIndex: 18
  },

  arrow: {
    width: 16,
    height: 10,
    transform: [{rotate: '180deg'}]
  },
  arrowActive: {
    width: 18,
    height: 12
  },
  head: {
    paddingVertical: 14
  },
  wrapper: {
    paddingVertical: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#e4e4e4',
    paddingHorizontal: 16
  },
  exclusion: {
    width: 10,
    height: 10,
    marginRight: 5
  },
  extraCharge: {
    width: 13,
    height: 14,
    marginRight: 10
  }

};

export default styles;
