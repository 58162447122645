import React, { useEffect } from 'react';
import { Text, View, Image, TouchableOpacity } from 'react-native';
import { CabUpsellCardProps } from 'apps/post-sales/src/cab/types';
import CardWrapper from '../CardWrapper';
import LinearGradient from 'react-native-linear-gradient';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getStyles from './style';
import { blueArrowRight, doneIcon } from 'apps/post-sales/src/cab/CabImageConstants';
import isEmpty from 'lodash/isEmpty';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import { getStaticData } from 'apps/post-sales/src/staticData/staticData';
import { copyToClipboard } from 'apps/post-sales/src/Common/commonUtil';
import { showLongToast } from 'packages/legacy-commons/Common/Components/Toast';
import CabBookingTrackingHelper from '../../../../CabBookingTrackingHelper';
import CabBookingDetailsConstant, { CLICK_EVENT, PAGE_NAME } from 'apps/post-sales/src/cab/CabBookingDetailsConstant';

const CabUpsellCard = ({ upSellCardData }: CabUpsellCardProps) => {
  const {
    heading,
    subHeading,
    startingPriceInfo,
    exclusiveOfferText,
    discountValue,
    benifitList,
    voucherCode,
    copyAndBookNowText,
    deeplink,
    clickProps
  } = upSellCardData || {};

  const { fsStyle, ...fonts } = getFont();
  const { psTheme } = useTheme();
  const styles = getStyles(fonts, psTheme);
  const { flatText, offText } = getStaticData();
  const clickEvent = clickProps || CLICK_EVENT.AT_RETURN_UPSELL_CLICK;

  useEffect(()=>{
    let eventName = CLICK_EVENT.OS_RETURN_UPSELL_CARD_SHOWN;
    if(clickEvent === CLICK_EVENT.AT_RETURN_UPSELL_CLICK){
      eventName = CLICK_EVENT.AT_RETURN_UPSELL_CARD_SHOWN;
    }
    CabBookingTrackingHelper.trackClickEvent(PAGE_NAME.TRIP_DETAILS, eventName);
  }, []);

  const copyAndOpenDeeplink = async () => {
    try {
      CabBookingTrackingHelper.trackClickEvent(PAGE_NAME.TRIP_DETAILS, clickEvent);
      const { copiedToClipboardText } = getStaticData(false);
      const copyStatus = await copyToClipboard(voucherCode);
      if (copyStatus) {
        showLongToast(copiedToClipboardText);
      }
      if (!!deeplink) {
        GenericModule.openDeepLink(deeplink);
      }
    } catch (error) {
      console.log('Error Occured: ', error);
    }
  };

  return (
		<LinearGradient
			start={{ x: 1, y: 0 }}
			end={{ x: 0.7, y: 1 }}
			colors={psTheme.color.lightBlueGradient}
			style={styles.AirportUpselCards}
		>
			<View style={[AtomicCss.flexRow, AtomicCss.marginBottom10]}>
				<View style={styles.flatOfferSection}>
					<View style={styles.flatTag}>
						<Text style={styles.flatText}>{flatText.toUpperCase()}</Text>
					</View>
					{!!discountValue && <Text style={styles.offerText1}>{discountValue}</Text>}
					<Text style={styles.offerText2}>{offText.toUpperCase()}</Text>
				</View>
				<View style={[AtomicCss.marginTop5]}>
					{!!heading && <Text style={[styles.heading, AtomicCss.marginBottom3]}>{heading}</Text>}
					{!!subHeading && (
						<Text style={[styles.subHeading, AtomicCss.marginBottom3]}>{subHeading}</Text>
					)}
					{!!startingPriceInfo && (
						<Text style={styles.startingPriceInfo}>{startingPriceInfo}</Text>
					)}
				</View>
			</View>

			{!isEmpty(benifitList) && (
				<View style={styles.offerListSection}>
					{benifitList.map((item, index) => {
						const { text } = item;
						return (
							<View
								key={index}
								style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginRight15]}
							>
								<Image style={styles.doneIconStyle} source={doneIcon} />
								<Text style={styles.listText}>{text}</Text>
							</View>
						);
					})}
				</View>
			)}
			<View style={styles.bookNowSection}>
				<View style={AtomicCss.alignItemsStart}>
				{!!voucherCode && (
					<View style={styles.copyCode}>
						<Text style={styles.voucherCode}>{voucherCode}</Text>
					</View>
				)}
				</View>
				{!!deeplink && (
					<TouchableOpacity
						style={[AtomicCss.flexRow, AtomicCss.alignItemEnd]}
						onPress={copyAndOpenDeeplink}
					>
						{!!copyAndBookNowText && (
							<Text style={styles.copyAndBookNowText}>{copyAndBookNowText}</Text>
						)}
						<Image style={styles.rightArrowStyle} source={blueArrowRight} />
					</TouchableOpacity>
				)}
			</View>
		</LinearGradient>
	);
};

export default CardWrapper(CabUpsellCard);
