import { StyleSheet } from 'react-native';
export default (theme) =>StyleSheet.create({
    travellerIcon: {
      height: 20,
      width: 23,
    },
    travellerIconWrapper: {
      width: 30,
      height: 30,
      borderRadius: 50,
      backgroundColor: theme.color.white,
      justifyContent: 'center',
      marginRight: 8,
    },
  });
