import React, {Component} from 'react';
import { View, Text, ToastAndroid, TouchableOpacity, Image, StyleSheet, Alert,
  DeviceEventEmitter,Platform, NativeModules} from 'react-native';
import AtomicCss from '../../../commonStyles/AtomicCss';
import SpecialClaimAddAttachmentModule from '@mmt/legacy-commons/Native/SpecialClaimAddAttachmentModule';
import {colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';
import {tick3, deleteIcon} from '../../../BusImageConstants';
import isEmpty from 'lodash/isEmpty';

class UploadCard extends Component {

  constructor(props) {
    super(props);
    // this.state = {
    //   attachmentLst: new Map()
    // };
    this.data = new FormData();
  }

  componentWillMount() {
    DeviceEventEmitter.addListener('special_claim_review_page', this.fetchAttachment.bind(this));
  }

  fetchAttachment(data) {
    const map = this.props.attachmentList;
    map.set(data.filePath, data);
    this.props.updateAttachmentList(map);
    // this.setState({
    //   attachmentLst: map
    // });
  }

  onAddAttachment = () => {
    if (this.props.attachmentList.size >= this.props.maxAttachment) {
      ToastAndroid.show(`Max ${this.props.maxAttachment} attachment allowed!`, ToastAndroid.LONG);
    } else {
      SpecialClaimAddAttachmentModule.onButtonClicked();
    }
  };

  onDelAttachment = (key) => {
    const attachmentMap = this.props.attachmentList;
    !isEmpty(attachmentMap) && attachmentMap.delete(key);
    this.props.updateAttachmentList(attachmentMap);
  };

  getAttachmentHtmlView() {
    const attachmentHtmlView = [];

    this.props.attachmentList.forEach(function (obj) {
      attachmentHtmlView.push(<View style={styles.attachedItem}>
        <View style={[styles.flexRow, styles.alignCenter]}>
          <View style={styles.attachedImgWrapper}>
            <Image
              style={styles.attachedImg}
              source={{uri: `file://${obj.filePath}`}}
            />
          </View>
          <Text style={styles.attachedImgName}>{obj.fileName}</Text>
          <Image style={styles.greenTick} source={tick3} />
        </View>
        <TouchableOpacity
          onPress={() => this.onDelAttachment(obj.filePath)}
          style={styles.imgDeleteBtn}
        >
          <Image style={styles.deleteIcon} source={deleteIcon} />
        </TouchableOpacity>
      </View>);
    }, this);
    return attachmentHtmlView;
  }

  renderFileUploader = () => {
    const { FileUploaderModule: { FileUploader } } = NativeModules;
    return (
      <TouchableOpacity>
        <FileUploader
            onFileChanged={this.props.updateAttachmentList}   
            attachmentLst={this.props.attachmentList}
            text={this.props.uploadDescription}
            />
      </TouchableOpacity>
    )
  }

  render () {
    return (
            <View style={[AtomicCss.whiteCardSection, styles.sectionCard, AtomicCss.marginBottom10]}>
            <Text style={[AtomicCss.defaultText, AtomicCss.font16, AtomicCss.boldFont, AtomicCss.marginBottom15]}>
              {this.props.uploadTitle}
            </Text>
              <View>
                {this.getAttachmentHtmlView()}
              </View>
              {
                (Platform.OS === 'web') ?
                this.renderFileUploader() :
                ( <TouchableOpacity onPress={this.onAddAttachment}>
                  <View style={styles.uploadSection}>
                    <Text style={[AtomicCss.font26, AtomicCss.lightText]}>+</Text>
                    <Text style={[AtomicCss.font12, AtomicCss.lightText, AtomicCss.boldFont, AtomicCss.marginBottom15]}>
                      {this.props.uploadDescription}
                    </Text>
                  </View>
                </TouchableOpacity>)
              }
            </View>
          );
  };
}

const styles = StyleSheet.create({
  sectionCard:{padding: 16},
  uploadSection: {borderWidth: 1, borderColor: '#e7e7e7', borderRadius: 4, alignItems: 'center', justifyContent: 'center', height: 66},
  attachedItem: {
    ...getPlatformElevation(2),
    backgroundColor: colors.white,
    padding: 4,
    borderRadius: 4,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 5
  },
  attachedImgWrapper: {
    overflow: 'hidden',
    borderRadius: 2,
    marginRight: 8
  },
  attachedImg: {
    height: 16,
    width: 16
  },
  attachedImgName: {
    fontSize: 12,
    fontFamily: fonts.regular,
    color: colors.grey,
    marginRight: 4
  },
  greenTick: {
    width: 12,
    height: 9,
    marginTop: 3
  },
  deleteIcon: {
    height: 24,
    width: 24
  },
  paraText: {
    fontSize: 14,
    color: colors.black28,
    fontFamily: fonts.regular,
    marginBottom: 5
  },
  flexRow: {
    flexDirection: 'row'
  },
  spaceBetween: {
    justifyContent: 'space-between'
  }
});

export default UploadCard;