import { PAGE_BOOKINGS_STATUS } from '../summaryConstants';
import { initialStateObjectType, payLoadType } from '../types';

interface Action {
  type: string;
  payload: payLoadType;
}

const planningStateObject = {
  bookings: [],
};

const initialStateObject: initialStateObjectType = {
  bookings: [],
  hasNextPage: false,
  currentPage: 0,
  currentPageResponse: null,
  statusWiseMessage: null,
};


export const summaryReducer = (state: typeof INITIAL_STATE = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case PAGE_BOOKINGS_STATUS.PLANNING:
      return {
        ...state,
        [PAGE_BOOKINGS_STATUS.PLANNING]: action.payload[PAGE_BOOKINGS_STATUS.PLANNING],
      };
      case PAGE_BOOKINGS_STATUS.ONTRIP:
        return {
          ...state,
          [PAGE_BOOKINGS_STATUS.ONTRIP]: action.payload[PAGE_BOOKINGS_STATUS.ONTRIP],
        };
    case PAGE_BOOKINGS_STATUS.UPCOMING:
      return {
        ...state,
        [PAGE_BOOKINGS_STATUS.UPCOMING]: action.payload[PAGE_BOOKINGS_STATUS.UPCOMING],
      };
    case PAGE_BOOKINGS_STATUS.CANCELLED:
      return {
        ...state,
        [PAGE_BOOKINGS_STATUS.CANCELLED]: action.payload[PAGE_BOOKINGS_STATUS.CANCELLED],
      };
    case PAGE_BOOKINGS_STATUS.FAILED:
    case PAGE_BOOKINGS_STATUS.UNSUCCESSFUL:
      return {
        ...state,
        [PAGE_BOOKINGS_STATUS.FAILED]: action.payload[PAGE_BOOKINGS_STATUS.FAILED],
      };
    case PAGE_BOOKINGS_STATUS.COMPLETED:
      return {
        ...state,
        [PAGE_BOOKINGS_STATUS.COMPLETED]: action.payload[PAGE_BOOKINGS_STATUS.COMPLETED],
      };
    case PAGE_BOOKINGS_STATUS.PENDING:
      return {
        ...state,
        [PAGE_BOOKINGS_STATUS.PENDING]: action.payload,
      };
    case PAGE_BOOKINGS_STATUS.ALL:
      return {
        ...state,
        [PAGE_BOOKINGS_STATUS.ONTRIP]: action.payload[PAGE_BOOKINGS_STATUS.ONTRIP] ,
        [PAGE_BOOKINGS_STATUS.UPCOMING]: action.payload [PAGE_BOOKINGS_STATUS.UPCOMING],
        [PAGE_BOOKINGS_STATUS.CANCELLED]: action.payload[PAGE_BOOKINGS_STATUS.CANCELLED],
        [PAGE_BOOKINGS_STATUS.COMPLETED]: action.payload[PAGE_BOOKINGS_STATUS.COMPLETED],
        [PAGE_BOOKINGS_STATUS.FAILED]: action.payload[PAGE_BOOKINGS_STATUS.FAILED],
        [PAGE_BOOKINGS_STATUS.AWAITING]: action.payload[PAGE_BOOKINGS_STATUS.AWAITING],
        [PAGE_BOOKINGS_STATUS.PLANNING]: action.payload[PAGE_BOOKINGS_STATUS.PLANNING],
      }
  }
};

export const INITIAL_STATE = {
  [PAGE_BOOKINGS_STATUS.ONTRIP]: initialStateObject,
  [PAGE_BOOKINGS_STATUS.UPCOMING]: initialStateObject,
  [PAGE_BOOKINGS_STATUS.COMPLETED]: initialStateObject,
  [PAGE_BOOKINGS_STATUS.CANCELLED]: initialStateObject,
  [PAGE_BOOKINGS_STATUS.FAILED]: initialStateObject,
  [PAGE_BOOKINGS_STATUS.AWAITING]: planningStateObject,
  [PAGE_BOOKINGS_STATUS.PLANNING]: planningStateObject,
};
