import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { Dimensions } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { isEmptyArray } from '../../../../../Common/commonUtil';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
import SectorSelection from './components/SectorSelection';
import { getFont } from '../../../../../PostSalesStyles';
import { useTheme } from '../../../../../theme/theme.context';
const windW = Dimensions.get('window').width;

const PassengerSelection = ({
  title,
  subtitle,
  flightPaxDetails,
  handleBottomOverlay,
  knowMoreText,
  dispatchCancellationState,
}) => {
  const { fsStyle, ...fonts } = getFont(true);
  const {psTheme} = useTheme();
  const styles = getStyle(psTheme);
  const {color} = psTheme;
  return (
    <View style={styles.cardWrapper}>
      <View style={styles.headingText}>
        <Text
          style={[
            fsStyle.font20,
            fonts.blackFontFamily,
            { color : color.black},
            AtomicCss.marginBottom8,
            AtomicCss.lineHeight28,
            AtomicCss.flex1,
          ]}
        >
          {title}
        </Text>
        {!isEmptyArray(subtitle) &&
          subtitle.map((sub) => (
            <Text
              key={sub}
              style={[
                fsStyle.font14,
                fonts.regularFontFamily,
                {color : color.defaultTextColor},
                styles.lineHeight22,
                AtomicCss.flex1,
              ]}
            >
              {sub}
            </Text>
          ))}
      </View>
      {!isEmptyArray(flightPaxDetails) &&
        flightPaxDetails.map((item, index) => {
          const lastItem = index === flightPaxDetails.length - 1 ? 'lastItemStyle' : '';
          return (
            <SectorSelection
              key={item.id}
              isLastItem={lastItem}
              handleBottomOverlay={handleBottomOverlay}
              paxData={item}
              knowMoreText={knowMoreText}
              dispatchCancellationState={dispatchCancellationState}
            />
          );
        })}
    </View>
  );
};
const getStyle = (theme) => {
  const {color} = theme;
  return StyleSheet.create({
    cardWrapper: {
      width: windW - 55,
      paddingHorizontal: 5,
    },
    headingText: {
      marginBottom: 24,
      flex: 1,
    },
    paddingAll20: {
      padding: 20,
    },
    lineHeight22: {
      lineHeight: 22,
    },
    cancelledFltCard: {
      ...getPlatformElevation(2),
      backgroundColor: color.grey22,
      padding: 20,
      borderRadius: 8,
      overflow: 'hidden',
      marginBottom: 12,
    },
  });
}
export default PassengerSelection;
