
const ViewState = {
  LOADING: 'loading',
  LOADING_PAY:'loading_pay',
  SUCCESS: 'success',
  LOADING_TGS: 'loading_tgs',
  SHOW_DETAIL: 'detail',
  REFRESH: 'refresh',
  NO_INTERNET: 'no_internet',
  DEFAULT: 'default',
  ERROR: 'error',
  AUTH_ERROR: 'auth_failed',
  NO_RESULT: 'no_result',
  HOLD_SUCCESS: 'HOLD_SUCCESS',
  HOLD_FAILURE: 'HOLD_FAILURE',
  NOT_LOGGED_IN: 'not_logged_in',
  HOLD_LOADING: 'HOLD_LOADING',
  HOLD_POPUP: 'HOLD_POPUP',
  COMMIT :'commit',
  COMMIT_SUCCESS : 'commit_success',
  COMMIT_FAIL : 'commit_fail',
  NO_CABS : 'no_cabs',
  RESEND_VOUCHER: 'resend_voucher',
  FORCE_CLOSE: 'force_close',
  DOWNLOAD_ETICKET_IOS: 'DOWNLOAD_ETICKET_IOS'
};

export default ViewState;
