/* eslint-disable max-len */
import React from 'react';
import {View, Text, StyleSheet} from 'react-native';
import PropTypes from 'prop-types';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';
import ButtonWithIcon from './ButtonWithIcon';
import MetroConstant from '../../MetroConstant';


class Support extends React.Component {
    static navigationOptions = {
      header: null
    };

    render() {
      return (
        <View style={[styles.elevation1, AtomicCss.marginBottom10, AtomicCss.marginTop5]}>
          <View style={styles.CardContent}>
            <View style={[AtomicCss.flex1]}>
              <Text style={[AtomicCss.blackFont, AtomicCss.blackText, AtomicCss.paddingTop3]}>
              STILL CAN’T FIND WHAT YOU NEED?
              </Text>
            </View>
          </View>
          <View style={[styles.ActionBtnHalf, styles.supportWrap]}>
            {this.props.showCallMetro && <ButtonWithIcon
              action={this.getActionForBtn(MetroConstant.CALL_METRO_SUPPORT, MetroConstant.CALL_METRO_SUPPORT_FAMILY)}
              clickHandler={this.props.supportCardClickHandler}
            />}
            {this.props.showMyraBtn && <ButtonWithIcon
              action={this.getActionForBtn(MetroConstant.CHAT_WITH_US_ACTION_BTN_TEXT, MetroConstant.CHAT_WITH_US_ACTION_BTN_FAMILY)}
              clickHandler={this.props.supportCardClickHandler}
            />}
            {
                this.props.showCallus && <ButtonWithIcon
                  action={this.getActionForBtn(MetroConstant.CALL_MMT_SUPPORT, MetroConstant.CALL_MMT_SUPPORT_FAMILY)}
                  clickHandler={this.props.supportCardClickHandler}
                />
              }
          </View>
        </View>
      );
    }

    getActionForBtn = (text, actionFamily) => {
      const action = {};
      action.actionLabeltext = text;
      action.actionFamily = actionFamily;
      return action;
    }
}

const styles = StyleSheet.create({
  CardContent: {
    backgroundColor: '#fff',
    borderBottomWidth: 1,
    borderBottomColor: '#e4e4e4',
    flexDirection: 'row',
    padding: 15
  },
  ActionBtnHalf: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100 %',
    backgroundColor: '#fff',
    paddingBottom: 10,
    marginTop: 15
  },
  elevation1: {
    backgroundColor: '#ffffff',
    ...getPlatformElevation(1)
  },
  supportWrap: {paddingTop: 16, paddingBottom: 24}
});

export default Support;

Support.propTypes = {
  showCallMetro: PropTypes.bool.isRequired,
  supportCardClickHandler: PropTypes.func.isRequired,
  showMyraBtn: PropTypes.bool.isRequired,
  showCallus: PropTypes.bool.isRequired
};
