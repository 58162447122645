import {colors, fonts} from '../../../Styles/globalStyles';
import getPlatformElevation from '../Card/getPlatformElevation';

const styles = {
  headerWrapper: {
    padding: 15,
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
    zIndex: 1,
    elevation: 1,
    backgroundColor: '#fff',
    marginBottom: 1,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.3,
    shadowRadius: 2
  },
  headerTypeDetails: {
    paddingVertical: 10
  },
  headerTypeTab: {
    paddingBottom: 35
  },
  headerTypeElevated: {
    ...getPlatformElevation(3)
  },
  headerTitle: {
    fontSize: 16,
    color: colors.black,
    fontFamily: fonts.bold
  },
  headerDescText: {
    fontSize: 12,
    fontFamily: fonts.regular,
    color: colors.disabledBtnBg,
    marginTop: 3
  },
  stickyBarWrapper: {
    paddingHorizontal: 15,
    paddingVertical: 5
  },
  backArrow: {
    width: 16,
    height: 16,
    marginRight: 18
  },
  CloseIcon: {
    width: 14,
    height: 14,
    marginRight: 18
  }
};

export default styles;
