import React from 'react';
import { View } from 'react-native';
import { RNCardContainer, RNLabel, RNView, RNRowContainer, RNInfoChip, RNBorder, RNDivider } from 'core-rn-ui-factory';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { useTheme } from '../../../../theme/theme.context';
import createStyles from './dateChangeStyle';
import isEmpty from 'lodash/isEmpty';

interface PriceBreakupCardProps {
  priceBreakupCard: {
    notificationStrip: {
      theme: string;
      iconUrl: string;
      message: string;
    };
    heading: string;
    priceBreakupDetails: {
      infoStrip: {
        theme: string;
        message: string;
      };
      originalDates: {
        heading: string;
        subHeading: string;
        price: string;
      };
      newDates: {
        heading: string;
        subHeading: string;
        price: string;
      };
      totalAmount: {
        title: string;
        theme?: string;
        price: string;
      };
    };
  };
}

const PriceBreakupCard: React.FC<PriceBreakupCardProps> = ({ priceBreakupCard }) => {
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme);
  return (
    <RNCardContainer style={[styles.cardContainer, AtomicCss.whiteBg, AtomicCss.marginBottom40]}>
      {!isEmpty(priceBreakupCard.notificationStrip) &&
          <RNInfoChip
            style={styles.infoChip}
            type={priceBreakupCard.notificationStrip.theme}
            icon={priceBreakupCard.notificationStrip.iconUrl}
            text={priceBreakupCard.notificationStrip.message}
          />
      }

      <RNView style={{flex:1}}>
        {!!priceBreakupCard.heading && <RNLabel size="mediumW2" shade="highEmphasis">{priceBreakupCard.heading}</RNLabel>}
        {!isEmpty(priceBreakupCard.priceBreakupDetails?.infoStrip) &&
          <View style={styles.infoStripContainer}>
            <View style={styles.headingContainer}>
              {!!priceBreakupCard.priceBreakupDetails.infoStrip.theme 
                && !!priceBreakupCard.priceBreakupDetails.infoStrip.message
                && <RNBorder cardHighlighterType={priceBreakupCard.priceBreakupDetails.infoStrip.theme} />}
              {!!priceBreakupCard.priceBreakupDetails.infoStrip.message && <RNLabel shade={priceBreakupCard.priceBreakupDetails.infoStrip.theme}>{priceBreakupCard.priceBreakupDetails.infoStrip.message}</RNLabel>}
            </View>
          </View>
        }

        <RNView style={styles.priceDateContainer}>
          {!isEmpty(priceBreakupCard.priceBreakupDetails?.originalDates) &&
            <RNRowContainer style={[AtomicCss.spaceBetween, AtomicCss.alignItemsStart]}>
              <RNView style={styles.w70}>
                {!!priceBreakupCard.priceBreakupDetails.originalDates.heading && <RNLabel size="mediumW2" shade="high">{priceBreakupCard.priceBreakupDetails.originalDates.heading}</RNLabel>}
                {!!priceBreakupCard.priceBreakupDetails.originalDates.subHeading && <RNLabel style={AtomicCss.marginTop4} size="baseW1" shade="medium">{priceBreakupCard.priceBreakupDetails.originalDates.subHeading}</RNLabel>}
              </RNView>

              {!!priceBreakupCard.priceBreakupDetails.originalDates.price && <RNLabel size="mediumW2" shade="high" style={[styles.w30, AtomicCss.alignRight]}>{priceBreakupCard.priceBreakupDetails.originalDates.price}</RNLabel>}
            </RNRowContainer>
          }

          {!isEmpty(priceBreakupCard.priceBreakupDetails?.newDates) &&
            <RNRowContainer style={[AtomicCss.spaceBetween, AtomicCss.marginTop10, AtomicCss.alignItemsStart]}>
              <RNView style={styles.w70}>
                {!!priceBreakupCard.priceBreakupDetails.newDates.heading && <RNLabel size="mediumW2" shade="high">{priceBreakupCard.priceBreakupDetails.newDates.heading}</RNLabel>}
                {!!priceBreakupCard.priceBreakupDetails.newDates.subHeading && <RNLabel style={AtomicCss.marginTop4} size="baseW1" shade="medium">{priceBreakupCard.priceBreakupDetails.newDates.subHeading}</RNLabel>}
              </RNView>

              {!!priceBreakupCard.priceBreakupDetails.newDates.price && <RNLabel size="mediumW2" shade="high" style={[styles.w30, AtomicCss.alignRight]}>{priceBreakupCard.priceBreakupDetails.newDates.price}</RNLabel>}
            </RNRowContainer>
          }

          <RNDivider
            marginTop={16}
            marginBottom={16}
            height={1}
          />
          {!isEmpty(priceBreakupCard.priceBreakupDetails?.totalAmount) &&
            <RNRowContainer style={AtomicCss.spaceBetween}>
              {!!priceBreakupCard.priceBreakupDetails.totalAmount.title && <RNLabel size="mediumW3" shade="high" style={styles.w70}>{priceBreakupCard.priceBreakupDetails.totalAmount.title}</RNLabel>}
              {!!priceBreakupCard.priceBreakupDetails.totalAmount.price && <RNLabel size="mediumW2" shade={priceBreakupCard.priceBreakupDetails.totalAmount.theme ? priceBreakupCard.priceBreakupDetails.totalAmount.theme : "high"} style={[styles.w30, AtomicCss.alignRight]}>{priceBreakupCard.priceBreakupDetails.totalAmount.price}</RNLabel>}
            </RNRowContainer>
          }
        </RNView>
      </RNView>
    </RNCardContainer>
  );
};

export default PriceBreakupCard;