import React from 'react';
import { Text, View, ScrollView, StyleSheet } from 'react-native';

import HeaderCross from '../../../Common/HeaderCross';
import ContactUsRow from './ContactUsRow';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import * as constants from "../../HolidayBookingConstants";

const ContactUsPage = ({
    response,
    onBackPopUp
}) => {
    return (
        <View style={[styles.modalWrapper, AtomicCss.flex1, AtomicCss.whiteBg]}>
            <View style={styles.WebCheckin}>
                <HeaderCross
                    title={constants.CONTACT_US}
                    headerShadow
                    headingTxt="font18"
                    handleClose={onBackPopUp}
                />
                <ScrollView>
                    <View style={[AtomicCss.flex1, AtomicCss.marginTop15]}>
                        {
                            response.holidayHelpCard &&
                            response.holidayHelpCard.agentDetailsList &&
                            response.holidayHelpCard.agentDetailsList.map((itemData) => {
                                return (
                                    <ContactUsRow data={itemData} />
                                )
                            })
                        }
                    </View>
                </ScrollView>
            </View>
        </View>
    )
}

ContactUsPage.navigationOptions = () => {
    return {
        header: null
    }
}

const styles = StyleSheet.create({
    modalWrapper: {
        flex: 1,
        justifyContent: 'space-between',
        backgroundColor: colors.grayBg,
        position: 'absolute',
        elevation: 3,
        zIndex: 13,
        width: '100%',
        height: '100%'
    },
});


export default ContactUsPage