import React from 'react';
import { View, Text, Image } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import CustomerDetails from './CustomerDetails';
import isEmpty from 'lodash/isEmpty';
import createStyles from './contactDetailsCss';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { CONTACT_ICON } from '../../Utils/RailImageConstant';
import { ContactCard } from '../../types';
import WhiteCardWrapper from '../../../Common/WhiteCardWrapper';

const ContactDetailsCard = ({ card, response }: ContactCard) => {
  const { heading } = card || {};
  const { contactDetailInformation } = response || {};
  const { contactDetails } = contactDetailInformation || {};
  const fonts = getFont();
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);
  return (
    <View style={styles.container}>
      <View style={[AtomicCss.flexRow, AtomicCss.marginBottom10, AtomicCss.alignCenter]}>
        <View style={[styles.iconWrapper, AtomicCss.justifyCenter]}>
          <Image style={styles.contactIconStyle} source={CONTACT_ICON} />
        </View>
        {!!heading && <Text style={[styles.heading, AtomicCss.flex1]}>{heading}</Text>}
      </View>
      {!isEmpty(contactDetails) ? (
        <View>
          <View
            style={[
              AtomicCss.flexRow,
              AtomicCss.flexWrap,
              AtomicCss.margin5,
              AtomicCss.paddingLeft30,
            ]}
          >
            {contactDetails.map((detail, index) => (
              <CustomerDetails
                key={`ContactDetail${index}`}
                detail={detail}
                isLast={index === contactDetails.length - 1
              } />
            ))}
          </View>
        </View>
      ) : null}
    </View>
  );
};

export default WhiteCardWrapper(ContactDetailsCard);
