import React from 'react';
import { View, Text, Image } from 'react-native';
import { getFont } from '../../../../../../PostSalesStyles';
import { INFO_RED_ICON } from '../../../../../FlightImageConstants';
import createStyles from './styles';
import { useTheme } from '../../../../../../theme/theme.context';

const ChangeDateInfo = ({ info }) => {
  const fonts = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);
  return (
    <View style={styles.cdiWrapper}>
      <Image source={INFO_RED_ICON} style={styles.infoIconStyle} />
      <Text style={[styles.infoText, fonts.regularFontFamily]}>{info}</Text>
    </View>
  );
};


export default ChangeDateInfo;
