import React from 'react';
import {View, Text, Image, TouchableOpacity} from 'react-native';
import styles from './inclusionExclusionCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {GREEN_TICK} from '../../../SelfDriveImageConstant';

export default class InclusionTab extends React.Component {
  constructor() {
    super();
    this.state = {
      active: true
    };
  }

    handleClick = () => {
      this.setState({active: !this.state.active});
    }

    render() {
      return (
        <View style={styles.wrapper}>
          <TouchableOpacity onPress={this.handleClick} style={[AtomicCss.flexRow, AtomicCss.spaceBetween, styles.head]}>
            <Text style={[AtomicCss.blackText, AtomicCss.font14, AtomicCss.blackFont]}>{this.props.response.inclusionHeader}</Text>
          </TouchableOpacity>
          {this.state.active &&
            <View>
              {this.fetchInclusions(this.props.response.inclusions)}
            </View>
                }
        </View>
      );
    }

    fetchInclusions = function (inclusions) {
      const views = [];
      Object.keys(inclusions).map((key) => {
        views.push(<View style={[AtomicCss.marginBottom10, AtomicCss.flexRow, AtomicCss.alignCenter]}><Image source={GREEN_TICK} style={styles.exclusion} /><Text style={[AtomicCss.greyText, AtomicCss.font14, AtomicCss.paddingLeft5]}>{inclusions[key]}</Text></View>);
      });
      return views;
    }
}

