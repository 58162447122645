import { PROFILE_TYPE } from '@mmt/legacy-commons/Common/constants/AppConstants';
import DefaultTheme from "../DefaultTheme";
import { ps_colors } from "../PostSalesStyles";

const MY_BIZ_COLOR_THEME = {
  ...ps_colors,
  ...DefaultTheme,
  primary: '#FF664B',
  primaryGradient:['#f95776', '#f0772c'],
  filterTextColor:"#4a4a4a",
  filterActiveBorderColor:"#f85a6e",
  lightPrimaryColor:"#FDF0EC",
  linkTextColor:"#FF664B",
  linkButtonTextColor:"#008CFF",
  primaryBorderLight:"#FF664B",
// Cabs gradients for different booking status
  cabsUnconfirmedGradient:["#FF664B", "#FF664B"],
  cabsConfirmedGradient:["#f95776", '#f0772c'],
  cabsCompletedGradient:['#f95776', '#f0772c'],
  cabsAfterRefundDone:['#f95776', '#f0772c'],
  cabsCancelledGradient:["#f95776", '#f0772c'],
  submitDateChangeReqBtnText: '#f0772c',
  linkTextDateChange: '#f0772c'
};

export const MY_BIZ_THEME = {
  id: PROFILE_TYPE.BUSINESS,
  color: MY_BIZ_COLOR_THEME,
  component: {
    button: {
      capsule: {
        bgColor: ['#f95776', '#f0772c'],
        borderRadius: 25,
        textColor: '#FFFFFF',
      },
      primary: {
        bgColor: ['#f95776', '#f0772c'],
        borderRadius: 5,
        textColor: '#FFFFFF',
      },
    },
    tab: {
      active: {
        bgColor: '#F17531',
        color: '#FFFFFF',
        subTextColor: '#FFFFFF',
      },
      inActive: {
        bgColor: '#FFFFFF',
        color: '#000000',
        subTextColor: '#777777',
      },
    },
  },
};
