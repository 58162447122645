import React from 'react';
import PropTypes from 'prop-types';
import HTMLView from 'react-native-htmlview';
import { Image, Text, View, Linking, ScrollView, BackHandler } from 'react-native';
import { openLink } from '../../../../../Common/commonUtil';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Actions from '../../../../../navigation/postSalesNavigation';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getStyle from './AncillarySimpleThankyouCss';
import {
  resetToFlightBookingDetail,
  resetToHolidayBookingDetail,
} from '../../../../FlightBookingUtil';
import BasePage from '../../../../../Common/PostSalesBasePage';
import Buttons from '../../../components/Button';
import ProgressView from '../../../../../Common/ProgressView/ProgressView';
import {ancillaryStates, thankyouPageStatusType, TY_WEBCHCEKIN_STATES } from '../../constants';
import AncillaryTrackingUtil from '../../utils/AncillaryTrackingUtil';
import { getAirlineImageUrl } from '../../../../../flights-funnel/Listing/utils/FlightListingUtil';
import {
  BACK_ARROW_WHITE_ICON,
  SUCCESS_TICK_ICON,
  ALERT_RED_TRIANGLE_ICON,
  SUCCESS_ICON,
  FAILED_ICON, ADD_ON_SUCCESS_ICON, ADD_ON_FAIL_ICON,
} from '../../../../FlightImageConstants';
import { _getFltTrackData } from '../../../../FlightOmnitureTrackerUtils';
import { isEmpty, isNil } from 'lodash';
import { webCheckinApi } from '../../data/api';
import { trackLoadCYTOmniture } from '../../utils/helper';
import { getEventCytPageName, getStatusIcon } from '../../utils/WebCheckinUtils';
import { getStaticData } from '../../../../../staticData/staticData';
import {getNewThankyouAttributes} from '../../utils/AncillaryUtils';
import { getFont } from '../../../../../PostSalesStyles';
import { ThemeContext } from '../../../../../theme/theme.context';
import { getDataFromStorage } from '../../../../../utils/localstorage';


class AncillarySimpleThankyou extends BasePage {
  static contextType = ThemeContext;
  constructor(props) {
    super(props, 'ancillarySimpleThankyou');
    this.webCheckinObj = {};
    this.thankyouObj = this.props.thankyouObj || {};
    this.screenSplitFactor = this.thankyouObj.isWebCheckin ? 2 : 1;
    this.FltTrackData = _getFltTrackData();
    this.state = {
      ancillaryState: this.props.ancillaryState,
      webcheckinResponse: null,
    };
  }

  async fetchWebcheckinApi() {
    this.setState({
      ancillaryState: ancillaryStates.WEBCHECKIN_LOADER,
    });
    const response = await webCheckinApi(this.props.ancillaryResponse, this.props.selectedAncillaries);
    if (response.error) {
      this.setState({
        ancillaryState: thankyouPageStatusType.WEBCHECKIN_FAILED,
        webcheckinResponse: response.response,
      });
    }
    if (response.success) {
      this.thankyouObj = response;
      this.thankyouObj.addOnSuccess = true;
      this.setState({
        ancillaryState: thankyouPageStatusType.WEBCHECKIN_SUCCESS,
        webcheckinResponse: response,
      });
    }
  }

  componentDidMount() {
    super.componentDidMount();
    this.trackCYTOmniture();
    const pageName = getEventCytPageName(this.thankyouObj);
    AncillaryTrackingUtil._trackLoadEventCyt(
      pageName,
      this.props.ancillaryResponse,
      !isEmpty(this.FltTrackData) && !isNil(this.FltTrackData.ominatureValue)
        ? this.FltTrackData.ominatureValue
        : '',
    );
    if (this.thankyouObj.isWebCheckin) {
      this.fetchWebcheckinApi();
    }
    BackHandler.addEventListener('hardwareBackPress', this.onHardBackPress);
    this.props.newNavigation?.addListener('beforeRemove', this.preventSwipeBack);
  }
  componentWillUnmount(){
    BackHandler.removeEventListener('hardwareBackPress', this.onHardBackPress);
    this.props.newNavigation?.removeListener('beforeRemove');
  }
  trackCYTOmniture = async () => {
    try {
      const value = JSON.parse(await getDataFromStorage('addon'));
      if (!isNil(value)) {
        trackLoadCYTOmniture(
          this.props.thankyouObj,
          this.thankyouObj,
          value,
          this.props.ancillaryResponse,
          this.FltTrackData,
        );
      }
    } catch (error) {
      AncillaryTrackingUtil._trackErrorEvent('CYT', error);
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (
      (nextState.ancillaryState === thankyouPageStatusType.WEBCHECKIN_SUCCESS ||
      nextState.ancillaryState === thankyouPageStatusType.WEBCHECKIN_FAILED) &&
      nextState.webcheckinResponse
    ) {
      const { ancillaryDetailTexts } = getStaticData();
      this.webCheckinObj = getNewThankyouAttributes(nextState.webcheckinResponse, this.props.selectedAncillaries, ancillaryDetailTexts, ADD_ON_SUCCESS_ICON,
        ADD_ON_FAIL_ICON);
      let pageName = 'Thankyou Seat Webcheckin ';
      pageName += this.thankyouObj.addOnSuccess
        ? 'Success'
        : this.thankyouObj.addOnPartial
        ? 'Part'
        : 'Fail';
      AncillaryTrackingUtil._trackLoadEventCyt(pageName, this.props.ancillaryResponse);
    }
    return true;
  }

  render() {
    const {psTheme} = this.context;
    const {fsStyle, ...fonts} = getFont(true);
    const styles = getStyle(fonts, psTheme);
    return <View style={styles.pageWrapper}>{this._getThankyouViews()}</View>;
  }

  _getThankyouViews = () => {
    const { webCheckingText } = getStaticData();
    const { completingWebCheckinText } = webCheckingText;
    const views = [];
    if (
      this.thankyouObj &&
      this.thankyouObj.status &&
      TY_WEBCHCEKIN_STATES.includes(this.thankyouObj.status)
    ) {
      views.push(this._getWebCheckinTyView(this.thankyouObj, false));
    } else {
      if (this.screenSplitFactor === 2) {
        if (this.state.ancillaryState === ancillaryStates.WEBCHECKIN_LOADER) {
          views.push(<ProgressView message={completingWebCheckinText} />);
        } else if (
          this.state.ancillaryState === thankyouPageStatusType.WEBCHECKIN_SUCCESS ||
          this.state.ancillaryState === thankyouPageStatusType.WEBCHECKIN_FAILED
        ) {
          views.push(this._getWebCheckinTyView(this.webCheckinObj, true));
        }
      } else {
        views.push(this._getAddOnTyView());
      }
    }
    return views;
  };

  _getAddOnTyView = () => {
    const { goBackToBookingText } = getStaticData();
    const {psTheme} = this.context;
    const {fsStyle, ...fonts} = getFont(true);
    const styles = getStyle(fonts, psTheme);
    const {color} = psTheme;
    return (
      <View style={styles.addOnContent}>
        {this.screenSplitFactor === 1 && (
          <View style={[AtomicCss.alignCenter]}>
            <Image style={styles.messageIcon} source={this.thankyouObj.imageIcon} />
          </View>
        )}

        <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
          {this.thankyouObj.addOnSuccess && (
            <Image style={{ width: 24, height: 24 }} source={SUCCESS_TICK_ICON} />
          )}
          {!this.thankyouObj.addOnSuccess && (
            <Image style={{ width: 32, height: 32 }} source={ALERT_RED_TRIANGLE_ICON} />
          )}
          <Text
            style={[
              fsStyle.font16,
              fonts.boldFontFamily,
              {color:color.defaultTextColor},
              AtomicCss.marginLeft15,
              AtomicCss.marginRight15,
            ]}
          >
            {this.thankyouObj.addOnTitle}
          </Text>
        </View>
        {!isEmpty(this.thankyouObj?.desc) && 
          <HTMLView
            style={styles.messageDesc}
            value={this.thankyouObj.desc}
            onLinkPress={(url) => this.handleLinkPress(url, this.thankyouObj.openInWebView)}
          />
        }
        {this.screenSplitFactor === 1 && (
          <View style={styles.footerBtnWrapper}>
            <Buttons
              text={goBackToBookingText}
              type="Rounded"
              color="blue"
              onPressHandler={this._openUpdatedBooking}
            />
          </View>
        )}
      </View>
    );
  };

  handleLinkPress = (url, openWebView) => {
    const {flightWebCheckInText} = getStaticData();
    if (openWebView) {
      Actions.openPsWebView({
        headerText: flightWebCheckInText,
        headerIcon: BACK_ARROW_WHITE_ICON,
        url,
      });
    } else {
      openLink(url);
    }
  };

  _getWebCheckinTyView = (thankyouData, displayAddonTy) => {
    if (!thankyouData) {
      return null;
    }
    const { goBackToBookingText, webCheckinStatusText } = getStaticData();
    const {psTheme} = this.context;
    const {fsStyle, ...fonts} = getFont(true);
    const styles = getStyle(fonts, psTheme);
    return (
      <View style={styles.addOnContent}>
        <ScrollView>
          <View style={styles.pageSection}>
            {thankyouData.addOnSuccess && (
              <Image style={styles.mainconStyle} source={SUCCESS_ICON} />
            )}
            {!thankyouData.addOnSuccess && (
              <Image style={styles.mainconStyle} source={FAILED_ICON} />
            )}
            <Text style={styles.titleHeading}>{thankyouData.header || webCheckinStatusText}</Text>
            {thankyouData.segments &&
              thankyouData.segments.map((seg) => (
                <View style={styles.segmentCard}>
                  <View style={styles.segmentHeadingWrapper}>
                    <Image
                      style={styles.logoStyle}
                      source={{ uri: getAirlineImageUrl(seg.airlineCode) }}
                    />
                    <View>
                      <Text
                        style={[
                          AtomicCss.defaultText,
                          AtomicCss.boldFont,
                          AtomicCss.font14,
                          AtomicCss.marginBottom5,
                        ]}
                      >
                        {seg.from} - {seg.to}
                      </Text>
                      <Text style={[AtomicCss.greyText, AtomicCss.regularFont, AtomicCss.font12]}>
                        {seg.airlineName} • {seg.travelDate}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.innerDtls}>
                    {seg && seg.statusList &&
                      seg.statusList.map((status) => (
                        <View style={[styles.dtlsRow, AtomicCss.marginBottom10]}>
                          <Image
                            style={[styles.greenIconStyle]}
                            source={getStatusIcon(status.status)}
                          />
                          <View>
                            <Text
                              style={[AtomicCss.boldFont, AtomicCss.font14, AtomicCss.defaultText]}
                            >
                              {status.title}
                            </Text>
                            {status.desc && (
                              <HTMLView
                                style={styles.messageDesc}
                                value={status.desc}
                                onLinkPress={(url) =>
                                  this.handleLinkPress(url, this.thankyouObj.openInWebView)
                                }
                              />
                            )}
                          </View>
                        </View>
                      ))}
                  </View>
                  {seg.footer && (
                    <View style={styles.moreInfoWrapper}>
                      <Text style={[AtomicCss.greyText, AtomicCss.boldFont, AtomicCss.font12]}>
                        {seg.footer}
                      </Text>
                    </View>
                  )}
                </View>
              ))}
          </View>
          {displayAddonTy && this._getAddOnTyView()}
        </ScrollView>
        <View style={styles.footerBtnWrapper}>
          <Buttons
            text={goBackToBookingText}
            type="Rounded"
            color="blue"
            onPressHandler={this._openUpdatedBooking}
          />
        </View>
      </View>
    );
  };

  _openUpdatedBooking = () => {
    if (this.props.destroySession) {
      this.props.destroySession();
    }

    if (this.props.holidayBooking) {
      resetToHolidayBookingDetail(this.props.holidayData, true);
      return;
    }
    resetToFlightBookingDetail(this.props.ancillaryResponse.bookingID);
  };

  preventSwipeBack = (e) => {
    e.preventDefault();
    this.props.newNavigation?.removeListener('beforeRemove');
    return this.onHardBackPress()
  }

  onHardBackPress = () => {
    this._openUpdatedBooking();
    return true;
  };
}

AncillarySimpleThankyou.propTypes = {
  thankyouObj: PropTypes.object.isRequired,
  ancillaryResponse: PropTypes.object.isRequired,
  selectedAncillaries: PropTypes.object.isRequired,
};

export default AncillarySimpleThankyou;
