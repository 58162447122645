import React from 'react';
import { Platform, Linking, DeviceEventEmitter } from 'react-native';
import Actions from '../navigation/postSalesNavigation';
import isEmpty from 'lodash/isEmpty';
import {isNullOrEmpty} from '../Common/commonUtil';
import {
  COMMA,
  colors,
  succussIcon,
  onTripIcon,
  partialIcon,
  cancelledIcon,
  completedIcon,
  bookingState,
  SupportIcon,
  BusIcon,
  CarIcon,
  CruiseIcon,
  Hotel,
  MaleIcon,
  FemaleIcon,
  GiftIcon,
  CardIcon,
  WalletIcon,
  itineraryType,
  tabsType,
  holidayDownloadAction,
  bookingSubState,
  BackWhiteIcon,
  HOLIDAY_MINIMAL_BOOKING_TYPE,
  mealInclIcon,
  transferInclIcon,
  hotelInclIcon,
  activityInclIcon,
  PACKAGE_INCLUSIONS,
  CrossIcon,
  HolidayBookingDetailsConstant,
  HOLIDAY_DOWNLOAD_TRIGGERS,
  HOLIDAY_VOUCHER_TYPE,
  DOWNLOAD_VOUCHER_STATES,
  PAX_LABELS,
} from './HolidayBookingConstants';
import {
  removeHTMLTags,
  unescapeHTML,
  downloadRequest,
  promiseAllSettled,
} from '../utils/PostSaleUtil';
import {PokusLobs} from '@mmt/legacy-commons/Native/AbConfig/AbConfigKeyMappings';
import {AbConfigKeyMappings, getPokusConfig} from '@mmt/legacy-commons/Native/AbConfig/AbConfigModule';
import { getFormattedDate } from '@mmt/legacy-commons/Helpers/dateHelpers';
import { HOLIDAY_BOOKING_CANCELLATION_URL, getCommonHeaders } from '../utils/NetworkUtils';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import { themeColors } from '../PostSalesStyles';
import HolidayBookingModule from '@mmt/legacy-commons/Native/HolidayBookingModule';
import { downloadFile } from '@mmt/legacy-commons/Common/utils/fileUtils';
import { getStaticData } from '../staticData/staticData';
import HolidaysSharedModuleHolder from '@mmt/holidays-shared/src';
export const resetToHolidayBookingDetail = (bookingId, isRefresh = false) => {
  if (Platform.OS === 'web') {
    HolidayBookingModule.resetToHolidayBookingDetail(bookingId);
  }
  else {
    if (isRefresh === true) {
      Actions.holidayBookingDetail({
        data: {
          BOOKING_ID: bookingId,
          isRefresh,
        },
      });
    } else {
      Actions.holidayBookingDetail({
        data: {
          BOOKING_ID: bookingId,
        },
      });
    }
  }
}

export function getPackageDestNightList(citiesIncluded) {
  if (isNullOrEmpty(citiesIncluded)) {
    return [];
  }
  const list = [];
  const cityList = citiesIncluded.split(';');
  for (let i = 0; i < cityList.length; i += 1) {
    const cityDuration = cityList[i].split(':');
    if (cityDuration.length === 2) {
      list.push({
        cityName: cityDuration[0],
        numOfnight: cityDuration[1]
      });
    }
  }
  return list;
}

export const getPaxConfigLabel = (adults, children, infants) => {
  const {
      ADULT,
      ADULTS,
      CHILD,
      CHILDREN,
      INFANT,
      INFANTS,
      SPACE,
    } = PAX_LABELS;
  let label = '';
  if (adults === 1) {
    label += adults + SPACE + ADULT;
  } else if (adults > 1) {
    label += adults + SPACE + ADULTS;
  }

  if (children === 1) {
    label += COMMA + SPACE + children + SPACE + CHILD;
  } else if (children > 1) {
    label += COMMA + SPACE + children + SPACE + CHILDREN;
  }

  if (infants === 1) {
    label += COMMA + SPACE + infants + SPACE + INFANT;
  } else if (infants > 1) {
    label += COMMA + SPACE + infants + SPACE + INFANTS;
  }
  return label;
};

export const getTopCardColor = (bookingStatus) => {
  if (bookingStatus.state === bookingState.UPCOMING && bookingStatus.subState === bookingSubState.UPCOMINGPAYMENTINCOMPLETE) {
    return [themeColors.confirmedWithPartialPaymentStartColor, themeColors.confirmedWithPartialPaymentEndColor];
  } else if (bookingStatus.state === bookingState.UPCOMING && bookingStatus.subState === bookingSubState.UPCOMINGPAYMENTCOMPLETE) {
    return [themeColors.confirmedWithCompletePaymentStartColor, themeColors.confirmedWithCompletePaymentEndColor];
  } else if (bookingStatus.state === bookingState.ONTRIP) {
    return [themeColors.onTripStart, themeColors.onTripEnd];
  } else if (bookingStatus.state === bookingState.CANCELLED || bookingStatus.state === bookingState.REFUNDED) {
    return [themeColors.cancelledStartColor, themeColors.cancelledStartColor];
  } else if (bookingStatus.state === bookingState.COMPLETED) {
    return [themeColors.completeStartColor, themeColors.completeEndColor];
  } else {
    return [themeColors.confirmedWithCompletePaymentStartColor, themeColors.confirmedWithCompletePaymentEndColor];
  }
};


export const getTopCardIcon = (bookingStatus) => {
  if (bookingStatus.state === bookingState.UPCOMING && bookingStatus.subState === bookingSubState.UPCOMINGPAYMENTINCOMPLETE) {
    return partialIcon;
  } else if (bookingStatus.state === bookingState.UPCOMING && bookingStatus.subState === bookingSubState.UPCOMINGPAYMENTCOMPLETE) {
    return succussIcon;
  } else if (bookingStatus.state === bookingState.ONTRIP) {
    return onTripIcon;
  } else if (bookingStatus.state === bookingState.CANCELLED || bookingStatus.state === bookingState.REFUNDED) {
    return cancelledIcon;
  } else if (bookingStatus.state === bookingState.COMPLETED) {
    return completedIcon;
  } else {
    return succussIcon;
  }
};

export const getFontColor = (bookingStatus) => {
  if ((bookingStatus.state === bookingState.UPCOMING && bookingStatus.subState === bookingSubState.UPCOMINGPAYMENTINCOMPLETE)
    || bookingStatus.state === bookingState.CANCELLED
    || bookingStatus.state === bookingState.REFUNDED
    || bookingStatus.state === bookingState.COMPLETED) {
    return themeColors.topCardFontColor1;
  }
  return themeColors.topCardFontColor2;
};

export const showCtaTabView = (packageInclusions) => {
  let count = 0;
  if (packageInclusions.flights) {
    count = count + 1;
  }
  if (packageInclusions.hotels) {
    count = count + 1;
  }
  if (packageInclusions.transfers) {
    count = count + 1;
  }
  if (packageInclusions.activities) {
    count = count + 1;
  }
  if (count === 0) {
    return false;
  }
  return true;
};

export const showPackageItinerary = (packageInclusions) => {
  let count = 0;
  if (packageInclusions.flights) {
    count = count + 1;
  }
  if (packageInclusions.hotels) {
    count = count + 1;
  }
  if (packageInclusions.transfers) {
    count = count + 1;
  }
  if (packageInclusions.activities) {
    count = count + 1;
  }
  if (count > 0 && count < 4) {
    return true;
  }
  return false;
};

export const getIconsClassName = (getLob) => {
  switch (getLob) {
    case 'support':
      return 'supportIcon';

    case 'bus':
      return 'busIcon';

    case 'car':
      return 'carIcon';

    case 'hotel':
      return 'hotelIcon';

    case 'cruise':
      return 'cruiseIcon';

    default:
      return 'supportIcon';
  }
};

export const getContactIconForType = (getLob) => {
  switch (getLob) {
    case 'agent':
    case 'support':
      return SupportIcon;
    case 'bus':
      return BusIcon;
    case 'transfer':
    case 'car':
      return CarIcon;
    case 'hotel':
      return Hotel;
    case 'cruise':
      return CruiseIcon;
    default:
      return SupportIcon;
  }
};

export const getTravellerIcon = (gender) => {
  switch (gender) {
    case 'Male':
      return MaleIcon;
    case 'Female':
      return FemaleIcon;
    default:
      return MaleIcon;
  }
};

export const getPaymentIcon = (modeOfPayment) => {
  switch (modeOfPayment) {
    case 'Card':
      return CardIcon;
    case 'Wallet':
      return WalletIcon;
    case 'GiftCard':
      return GiftIcon;
    default:
      return CardIcon;
  }
};

export const getButtonsForType = (buttons, type, included) => {
  if (isEmpty(buttons)) {
    return [];
  }
  return included ? buttons.filter(button => button.displayType === type) :
    buttons.filter(button => button.displayType !== type);
};

export const getItinerarySubHeading = (dayWiseDataItem) => {
  if (dayWiseDataItem.itinerary === itineraryType.Flight) {
    let subHeadingPrefix = dayWiseDataItem.itineraryData.carrier;
    let subHeadingSuffix = dayWiseDataItem.itineraryData.departureTime;
  
    if (dayWiseDataItem.dummyFlight) {
      subHeadingPrefix = dayWiseDataItem.dummyFlightInfo?.dummyFlightName;
      subHeadingSuffix = null;
    }
    return {
      subHeadingPrefix,
      subHeadingSuffix
    };
  } else if (dayWiseDataItem.itinerary === itineraryType.Hotel) {
    const nights = dayWiseDataItem.itineraryData.noOfDaysOrNights;
    const nightsSuffix = (nights > 1) ? ' Nights' : ' Night';
    return {
      subHeadingPrefix: dayWiseDataItem.itineraryData.cityCountryName,
      subHeadingSuffix: nights + nightsSuffix
    };
  } else if (dayWiseDataItem.itinerary === itineraryType.Car) {
    return {
      subHeadingPrefix: dayWiseDataItem.itineraryData.transportationBrand
    };
  } else if (dayWiseDataItem.itinerary === itineraryType.Activity) {
    return {
      subHeadingPrefix: dayWiseDataItem.itineraryData.cityCountryName
    };
  } else if (dayWiseDataItem.itinerary === itineraryType.Cruise) {
    const nights = dayWiseDataItem.itineraryData.noOfDaysOrNights;
    const nightsSuffix = (nights > 1) ? ' Nights' : ' Night';
    const cabins = dayWiseDataItem.itineraryData.noOfCabins;
    const cabinSuffix = (cabins > 1) ? ' Cabins' : ' Cabin';
    return {
      subHeadingPrefix: cabins + cabinSuffix,
      subHeadingSuffix: nights + nightsSuffix
    };
  }
  return {};
};

export const getDefaultTab = (itinerary) => {
  if (itinerary === itineraryType.Flight) {
    return tabsType.FLIGHTS;
  } else if (itinerary === itineraryType.Hotel || itinerary === itineraryType.Free) {
    return tabsType.HOTELS;
  } else if (itinerary === itineraryType.Activity || itinerary === itineraryType.Cruise) {
    return tabsType.ACTIVITIES;
  } else if (itinerary === itineraryType.Car || itinerary === itineraryType.Bus
    || itinerary === itineraryType.Rail || itinerary === itineraryType.Transfers) {
    return tabsType.TRANSFERS;
  } else {
    return tabsType.HOTELS;
  }
};

export const removeTags = label => removeHTMLTags(unescapeHTML(label));

export const getDownloadActionForType = (type) => {
  switch (type) {
    case 'flight':
      return holidayDownloadAction.FLIGHT;
    case 'hotel':
      return holidayDownloadAction.HOTEL;
    case 'holiday':
      return holidayDownloadAction.HOLIDAY;
    case 'car':
      return holidayDownloadAction.CAR;
    case 'addon':
      return holidayDownloadAction.ACTIVITY;
    case 'baggageTag':
      return holidayDownloadAction.BAGGAGE_TAG;
    default:
      return holidayDownloadAction.HOLIDAY;
  }
};

export const initCap = (str) => {
  return str.toLowerCase()
    .replace(/(?:^|\s)[a-z]/g, function (m) {
      return m.toUpperCase();
    });
};

export const getInitialVoucherState = (voucherList) => {
  return (
    !isEmpty(voucherList) &&
    voucherList.map(() => {
      return {
        state: DOWNLOAD_VOUCHER_STATES.INITIAL,
        message: '',
      };
    })
  );
};

export const getVouchersList = (voucherPage, type = '', voucherId = '', voucherUrl = '') => {
  let vouchers = [];
  const typeVouchers = [];
  const idVouchers = [];
  const UrlVouchers = [];

  if (voucherPage && !isEmpty(voucherPage.downloadVoucherSegmentList)) {
    vouchers = voucherPage.downloadVoucherSegmentList;
    vouchers.forEach((file) => {
      if (!isEmpty(type) && file.type === type) {
        typeVouchers.push(file);
      }
      if (!isEmpty(voucherId) && file.voucherId === voucherId) {
        idVouchers.push(file);
      }
      if (!isEmpty(voucherUrl) && file.voucherUrl === voucherUrl) {
        UrlVouchers.push(file);
      }
    });

    if (!isEmpty(voucherUrl)) {
      return UrlVouchers;
    } else if (!isEmpty(voucherId)) {
      return idVouchers;
    } else if (!isEmpty(type)) {
      return typeVouchers;
    } else {
      return vouchers;
    }
  }
  return vouchers;
};

export const getVoucherIndex = (voucherPage, type = '', voucherId = '', voucherUrl) => {
  let voucherIndex;
  if (!isEmpty(voucherId)) {
    voucherIndex = voucherPage.downloadVoucherSegmentList.findIndex(
      (file) => file.voucherId === voucherId,
    );
  } else if (!isEmpty(voucherUrl)) {
    voucherIndex = voucherPage.downloadVoucherSegmentList.findIndex(
      (file) => file.voucherUrl === voucherUrl,
    );
  } else if (!isEmpty(type)) {
    voucherIndex = voucherPage.downloadVoucherSegmentList.findIndex((file) => file.type === type);
  }
  return voucherIndex;
};

export const getItineraryIndex = (type, index, dataIndex, daywiseItineraryDetails) => {
  let itineraryIndex = -1;
  if (!isEmpty(daywiseItineraryDetails)) {
    for (let i = 0; i < daywiseItineraryDetails.length; i += 1) {
      for (let j = 0; j < daywiseItineraryDetails[i].daywiseData.length; j += 1) {
        const itinerary = daywiseItineraryDetails[i].daywiseData[j].itinerary;
        if (itinerary === type) {
          itineraryIndex++;
          if (i === index && j === dataIndex) {
            return itineraryIndex;
          }
        }
      }
    }
  }

  return 0;
};

export function isDocumentUploadEnabled() {
  return getPokusConfig(PokusLobs.POST_SALES, AbConfigKeyMappings.holDocumentUpload, false);
}

export const getActivityTimeInfo = (activity) => {

  /*
  ------------------------------------------------------
  | case | isfreehold | isslotTimeAvailable | multiDay |
  ------------------------------------------------------
  | 0    | true       | true                | true     |
  | 1    | true       | true                | false    |
  | 2    | true       | false               | true     |
  | 3    | true       | false               | false    |
  | 4    | false      | true                | true     |
  | 5    | false      | true                | false    |
  | 6    | false      | false               | true     |
  | 7    | false      | false               | false    |
  ------------------------------------------------------

  -------------------------------------------------------
  | case | timeHeaderFormat                             |
  -------------------------------------------------------
  | 0    | startDate, startTime -> endDate, endTime     |
  | 1    | endDate, startTime - endTime                 |
  | 2    | startDate -> endDate                         |
  | 3    | endDate, endTime                             |
  | 4    | startDate, startTime -> endDate, endTime     |
  | 5    | startDate, startTime - endTime               |
  | 6    | startDate -> endDate                         |
  | 7    | endDate                                      |
  -------------------------------------------------------
  */

  const startDate = getFormattedDate(activity.startDate, "YYYY-MM-DD", "ddd, DD MMM'YY");
  const endDate = getFormattedDate(activity.endDate, "YYYY-MM-DD", "ddd, DD MMM'YY");
  const startTime = getFormattedDate(activity.startTime, "HH:mm:ss", "hh:mm A");
  const endTime = getFormattedDate(activity.endTime, "HH:mm:ss", "hh:mm A");
  const slotstarttime = getFormattedDate(activity.slotstarttime, "HH:mm", "hh:mm A");
  const slotendtime = getFormattedDate(activity.slotendtime, "HH:mm", "hh:mm A");

  let activityInfo = {
    startTime: null,
    endTime: null,
    startDate: null,
    endDate: null,
  }

  if (activity.isfreehold) {
    if (activity.isslotTimeAvailable) {
      if (activity.startDate !== activity.endDate) {
        // case 0
        activityInfo = {
          ...activityInfo,
          startDate: startDate,
          startTime: slotstarttime,
          endDate: endDate,
          endTime: slotendtime,
        }
      } else {
        // case 1
        activityInfo = {
          ...activityInfo,
          startDate: endDate,
          startTime: slotstarttime,
          endTime: slotendtime,
        }
      }
    } else {
      if (activity.startDate !== activity.endDate) {
        // case 2
        activityInfo = {
          ...activityInfo,
          startDate: startDate,
          endDate: endDate,
        }
      } else {
        // case 3
        activityInfo = {
          ...activityInfo,
          startDate: endDate,
          startTime: activity.slotendtime.length > 0 ?
            slotendtime :
            endTime
        }
      }
    }

  } else {
    if (activity.isslotTimeAvailable) {
      if (activity.startDate !== activity.endDate) {
        // case 4
        activityInfo = {
          ...activityInfo,
          startDate: startDate,
          startTime: slotstarttime,
          endDate: endDate,
          endTime: slotendtime,
        }
      } else {
        // case 5
        activityInfo = {
          ...activityInfo,
          startDate: startDate,
          startTime: slotstarttime,
          endTime: slotendtime,
        }
      }
    } else {
      if (activity.startDate !== activity.endDate) {
        //case 6
        activityInfo = {
          ...activityInfo,
          startDate: startDate,
          endDate: endDate,
        }
      } else {
        // case 7
        activityInfo = {
          ...activityInfo,
          startDate: endDate,
        }
      }
    }
  }

  return activityInfo;
}

export const isMinimalBooking = (bookingType) => {
  return bookingType &&
    [
      HOLIDAY_MINIMAL_BOOKING_TYPE.FD,
      HOLIDAY_MINIMAL_BOOKING_TYPE.OFFLINE
    ].includes(bookingType.toUpperCase())
    ? true : false
}

export const externalLinkHandler = (action, title, iosURL) => {
  let externalUrl = action.url;
  if (Platform.OS === 'ios' && iosURL) {
    externalUrl = iosURL;
  }
  if (externalUrl) {
    if (action.openInWebView) {
      Actions.openPsWebView({
        headerText: action.webViewTitle || title,
        headerIcon: CrossIcon,
        url: externalUrl,
        whiteHeader: true
      });
    } else {
      Linking.openURL(externalUrl);
    }
  }
};

export async function openHolidayCancellationThankYou(response, refundMode) {
  const userInfo = await HotelBookingModule.getRequestHeader(response.bookingId, 'HOLIDAY_CANCELLATION_DONE');
  const cancellationRequest = getCancellationRequest(response.bookingId, 'false', refundMode);
  Actions.holidayCancellationThankyou({
    url: HOLIDAY_BOOKING_CANCELLATION_URL,
    mmtAuth: userInfo.mmtAuth,
    bookingId: response.bookingId,
    loggingTrackInfo: userInfo.loggingTrackingInfo,
    cancellationDoneRequest: cancellationRequest,
    holidayBookingResponse: response,
  });
}

export function getCancellationRequest(bookingId, isCancellationPreview, refundOption) {
  const holidayCancellationRequest = {};
  holidayCancellationRequest.bookingId = bookingId;
  holidayCancellationRequest.preview = isCancellationPreview;
  holidayCancellationRequest.refundAdjustment = refundOption;
  holidayCancellationRequest.userId = 'WEBLIVE';
  return holidayCancellationRequest;
}

export const getActivityListIconData = (itineraryData) => {
  const getIconClass = (type) => {
    switch (type) {
      case PACKAGE_INCLUSIONS.BREAKFAST:
      case PACKAGE_INCLUSIONS.LUNCH:
      case PACKAGE_INCLUSIONS.DINNER:
      case PACKAGE_INCLUSIONS.MEALS:
        return mealInclIcon
      case PACKAGE_INCLUSIONS.AIRPORT_CAB:
      case PACKAGE_INCLUSIONS.TRANSFERS:
        return transferInclIcon
      case PACKAGE_INCLUSIONS.HOTEL:
        return hotelInclIcon
      case PACKAGE_INCLUSIONS.ACTIVITY:
        return activityInclIcon
      default:
        return activityInclIcon
    }
  }

  return itineraryData.inclusion ? itineraryData.inclusion.map(
    (incl, id) => ({
      text: incl.text,
      iconSrc: getIconClass(incl.type),
      type: incl.type,
    })
  ) : []
}

export const downloadTicket = (item, bookingId) => {
  const vouchers = [{
    triggerName: item,
  }];
  if (Platform.OS === 'web'){
    HolidayBookingModule.downloadTickets(JSON.stringify(vouchers), bookingId);
  }
  else {
    HolidayBookingModule.downloadTickets(JSON.stringify(vouchers));
  }
};

const downloadHolidayBookingCard = async (
  bookingId,
  index,
  voucherStatus,
  setVoucherStatus,
  defaultErrorMessage,
) => {
  voucherStatus[index] = { state: DOWNLOAD_VOUCHER_STATES.PROGRESS, message: '' };
  setVoucherStatus(voucherStatus);
  const requestBody = {
    triggerName: HOLIDAY_DOWNLOAD_TRIGGERS.HOLIDAY_BOOKING_CARD,
    pdf: true,
    bookingId: bookingId,
  };
  const userInfo = await HotelBookingModule.getRequestHeader(bookingId, 'HOLIDAY_BOOKINGCARD');
  const headers = await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo);
  const result = await downloadRequest(
    requestBody,
    headers,
    { triggerName: HOLIDAY_DOWNLOAD_TRIGGERS.HOLIDAY_BOOKING_CARD, bookingId },
    HolidayBookingDetailsConstant.DOWNLOAD_FAILED,
  );
  if (result) {
    const { downloadSuccessText } = getStaticData();
    voucherStatus[index] = {
      state: DOWNLOAD_VOUCHER_STATES.SUCCESS,
      message: downloadSuccessText,
    };
  } else {
    voucherStatus[index] = {
      state: DOWNLOAD_VOUCHER_STATES.RETRY,
      message: defaultErrorMessage,
    };
  }
  setVoucherStatus(voucherStatus);
};

const downloadViatorVoucher = (
  voucherItem,
  index,
  voucherStatus,
  setVoucherStatus,
  defaultErrorMessage,
) => {
  const { downloadSuccessText } = getStaticData();
  voucherStatus[index] = { state: DOWNLOAD_VOUCHER_STATES.PROGRESS, message: '' };
  setVoucherStatus(voucherStatus);
  if (voucherItem.voucherUrl) {
    downloadFile(voucherItem.voucherUrl, `Activity_voucher_${voucherItem.text}`, 'pdf');
    voucherStatus[index] = { state: DOWNLOAD_VOUCHER_STATES.SUCCESS, message: downloadSuccessText };
    setVoucherStatus(voucherStatus);
  } else {
    voucherStatus[index] = { state: DOWNLOAD_VOUCHER_STATES.RETRY, message: defaultErrorMessage };
    setVoucherStatus(voucherStatus);
  }
};

const downloadAddonVouchers = async (
  item,
  bookingId,
  index,
  voucherStatus,
  setVoucherStatus,
  defaultErrorMessage,
) => {
  voucherStatus[index] = { state: DOWNLOAD_VOUCHER_STATES.PROGRESS, message: '' };
  setVoucherStatus(voucherStatus);
  const requestBody = {
    triggerName: HOLIDAY_DOWNLOAD_TRIGGERS.HOLIDAY_ADD_ON_VOUCHER,
    pdf: true,
    bookingId: bookingId,
    voucherId: item.voucherId,
  };
  const userInfo = await HotelBookingModule.getRequestHeader(bookingId, 'HOLIDAY_ADD_ON_VOUCHER');
  const headers = await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo);
  const result = await downloadRequest(
    requestBody,
    headers,
    { triggerName: HOLIDAY_DOWNLOAD_TRIGGERS.HOLIDAY_ADD_ON_VOUCHER, bookingId },
    HolidayBookingDetailsConstant.DOWNLOAD_FAILED,
  );

  if (result) {
    const { downloadSuccessText } = getStaticData();
    voucherStatus[index] = {
      state: DOWNLOAD_VOUCHER_STATES.SUCCESS,
      message: downloadSuccessText,
    };
  } else {
    voucherStatus[index] = {
      state: DOWNLOAD_VOUCHER_STATES.RETRY,
      message: defaultErrorMessage,
    };
  }
  setVoucherStatus(voucherStatus);
};

export const downloadHolidayVoucher = (
  voucherDetails,
  bookingId,
  voucherStatus,
  setVoucherStatus,
  defaultErrorMessage,
  voucherIndex,
) => {
  handleEventListner(
    voucherDetails,
    bookingId,
    voucherStatus,
    setVoucherStatus,
    defaultErrorMessage,
    voucherIndex,
  );
  HolidayBookingModule.checkStoragePermission();
};

function handleEventListner(
  voucherDetails,
  bookingId,
  voucherStatus,
  setVoucherStatus,
  defaultErrorMessage,
  voucherIndex,
) {
  const listener = DeviceEventEmitter.addListener(
    HolidayBookingDetailsConstant.HOLIDAY_STORAGE_PERMISSION,
    (status) => {
      checkPermission(
        status,
        voucherDetails,
        bookingId,
        voucherStatus,
        setVoucherStatus,
        defaultErrorMessage,
        voucherIndex,
      );
      listener.remove();
    }
  );
}

const checkPermission = async (
  status,
  voucherDetails,
  bookingId,
  voucherStatus,
  setVoucherStatus,
  defaultErrorMessage,
  voucherIndex,
) => {
  if (status.success) {
    await promiseAllSettled(
      voucherDetails.map((voucherDetail, index) => {
        if (voucherDetails.length === 1) {
          index = voucherIndex;
        }
        if (voucherDetail.type === HOLIDAY_VOUCHER_TYPE.ADDON) {
          downloadAddonVouchers(
            voucherDetail,
            bookingId,
            index,
            voucherStatus,
            setVoucherStatus,
            defaultErrorMessage,
          );
        } else if (voucherDetail.type === HOLIDAY_VOUCHER_TYPE.HOLIDAY) {
          downloadHolidayBookingCard(
            bookingId,
            index,
            voucherStatus,
            setVoucherStatus,
            defaultErrorMessage,
          );
        } else if (voucherDetail.type === HOLIDAY_VOUCHER_TYPE.ADDON_VIATOR) {
          downloadViatorVoucher(
            voucherDetail,
            index,
            voucherStatus,
            setVoucherStatus,
            defaultErrorMessage,
          );
        }
      })
    );
  }
};
