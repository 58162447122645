import React, { useState, useCallback } from "react";
import { SelectedPaxMap, PassangerSegmentAndPaxLineNo } from "./wheelChairTypes";


export const usePaxSelection = () => {


    const [selectedPaxMap, setSelectedPaxMap] = useState<SelectedPaxMap[]>([]);

    const isPaxSelected = useCallback(({ paxLineNo, segmentLineNo }: PassangerSegmentAndPaxLineNo) => {
        const selectedPax = selectedPaxMap.filter((pax) => pax.paxLineNo === paxLineNo && pax.segmentLineNo === segmentLineNo)
        return selectedPax.length > 0;
    }, [selectedPaxMap])

    const onCheckBoxClicked = useCallback(({ paxLineNo, segmentLineNo, firstName, lastName }: SelectedPaxMap) => {

        if (isPaxSelected({paxLineNo, segmentLineNo})) {
            const removedPaxMap = selectedPaxMap.filter((pax) => !(pax.paxLineNo === paxLineNo && pax.segmentLineNo === segmentLineNo))
            setSelectedPaxMap(removedPaxMap)
        } else {
            setSelectedPaxMap((prev) => [...prev, { paxLineNo, segmentLineNo, firstName, lastName }])
        }

    }, [selectedPaxMap])

    return [selectedPaxMap, onCheckBoxClicked, isPaxSelected] as const;

}

export default usePaxSelection;