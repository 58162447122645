import {StyleSheet} from "react-native"
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation'

export default (theme, fonts) =>StyleSheet.create({
    headerWrapper:{
        justifyContent:'space-between',
        padding:16,
        flexDirection:'row',
        alignItems:'center',
        zIndex:10    
    },
    headerShadow:{
        ...getPlatformElevation(2),
    },
    lightGreen:{
        backgroundColor:theme.color.limeGreen
    },
    lightYellow:{
        backgroundColor:theme.color.creamWhite
    },
    lightPink :{
        backgroundColor: theme.color.lightPink 
    },
    whiteText:{
        color:theme.color.grey22
    },
    crossIconStyle:{
        width:14,
        height:14,
        marginLeft:10,
        marginRight:20,
    },
    icon:{
        width:20,
        height:20,
        // marginRight:28,
    },
    backArrowWrapper:{
     paddingRight:5,   
    }
   
})
