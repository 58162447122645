import React from "react";
import { View } from "react-native";
import  Actions  from '../../navigation/postSalesNavigation';
import BasePage from '../../Common/PostSalesBasePage';
import styles from '../styles/CabMamiCss';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import ProgressView from '@mmt/legacy-commons/Common/Components/Progress/ProgressView';
import SimpleHeader from '@mmt/legacy-commons/Common/Components/Header/SimpleHeader';
import NoInternetView from '@mmt/legacy-commons/Common/Components/Error/NoInternetView';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import {
  getCommonHeaders,
  CAB_CANCELLATION_URL
} from "../../utils/NetworkUtils";
import { isNetworkAvailable } from '@mmt/legacy-commons/Common/utils/AppUtils';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import StickyHeader from "../../hotel/details/components/StickyHeader";
import CancellationInfoCard from "./CancellationInfoCard";
import { getCabSubTitle } from "../utils/CabBookingDetailUtil";
import CabBookingTrackingHelper from "../CabBookingTrackingHelper";
import {
  getCancelRequestKeyName,
  removeKeyFromAsyncStorage,
  storeObjInAsyncStorage
} from '../../Common/commonUtil';
import {CancellationPreviewRequest as CancelPreviewRequest} from '../../PostSalesConstant';
import {headerIcon2} from '../CabImageConstants';

export const CAB_CANCELLATION_DETAILS_PAGE =
  "Mob:Customer Support:Cab:TripDetails:CancelBooking:RefundAmt";

export default class CabCancellationDetails extends BasePage {
  static navigationOptions = {
    header: null
  };

  constructor(props) {
    super(props, CAB_CANCELLATION_DETAILS_PAGE);
    this.trackingPageName = CAB_CANCELLATION_DETAILS_PAGE;
    this.bookingId = this.props.response.bookingId;
    this.mmtAuth = null;
    this.loggingTrackingInfo = null;
    this.penaltyResponse = null;
    this.state = {
      responseJson: null,
      viewState: ViewState.LOADING
    };
    this.cabBookingInfo = this.props.response.cabBookingInfo;
    this.cancelRequestKey = getCancelRequestKeyName(CancelPreviewRequest.CAR, this.props.response.bookingId);
  }

  render() {
    return (
      <View style={[styles.flex1, styles.whitebg]}>
        {this.state.viewState === ViewState.LOADING &&
          this.renderProgressView()}
        {this.state.viewState === ViewState.NO_INTERNET &&
          this.renderNoNetworkView()}
        {this.state.viewState === ViewState.ERROR && this.renderErrorView()}
        {this.state.viewState === ViewState.SHOW_DETAIL && this.renderPage()}
      </View>
    );
  }

  renderProgressView = () => <ProgressView message="Loading Details..." />;

  renderNoNetworkView = () => (
    <View style={{ flex: 1 }}>
      <SimpleHeader title="Back" iconPress={this.goBack} />
      <NoInternetView
        onRetry={() => {
          this.setState({ viewState: ViewState.LOADING });
          this.fetchJSONAsync();
        }}
      />
    </View>
  );

  renderErrorView = () => (
    <View style={{ flex: 1 }}>
      <SimpleHeader title="Back" iconPress={this.goBack} />
      <ErrorView
        showRetry
        onRetry={() => {
          this.setState({ viewState: ViewState.LOADING });
          this.fetchJSONAsync();
        }}
      />
    </View>
  );

  renderPage = () => {
    const {clmCashbackDeductionBanner : mmtBlackCancellationText} = this.cancelPreviewObj || {};
    return (
    <View style={styles.whitebg}>
      <StickyHeader
        iconSrc={headerIcon2}
        title="Cab Cancellation"
        headerColor={["#ffffff", "#ffffff"]}
        titleStyle={[styles.DefaultText, styles.blackFont]}
        subTitle={getCabSubTitle(this.props.response)}
        subTitleStyle={[styles.DefaultText, styles.regularFont, styles.font12]}
        onBackPress={this.goBack}
      />
      <CancellationInfoCard
          response={this.props.response}
          penalty={this.penaltyResponse}
          fareBreakUpObject={this.fareBreakUpObject}
          deductionComponent={this.getDeductions}
          paidComponent={this.getPaidBreakup}
          hideBreakup={true}
          mmtBlackCancellationText={mmtBlackCancellationText}
          isMyGate={this.props.isMyGate}
        />
    </View>
    )
  };

  componentDidMount() {
    removeKeyFromAsyncStorage(this.cancelRequestKey);
    this.fetchJSONAsync();
  }

  fareBreakUpObject = () => {
    let totalRefundAmount = 0;
    let totalCancelationCharges = 0;
    let totalPaidAmount = 0;
    let refundText = "No Refund";

    if (this.cancelPreviewObj && this.cancelPreviewObj.status) {
      totalRefundAmount = parseInt(
        Math.round(this.cancelPreviewObj.refundAmount)
      );
      totalCancelationCharges = parseInt(
        Math.round(this.cancelPreviewObj.penaltyAmount)
      );
      totalPaidAmount = Math.round(
        this.cancelPreviewObj.responsePriceBreakup.totalpaid.amount
      );

      refundText = this.cancelPreviewObj.refundBreakUp.total_refund.label
    }

    return { totalRefundAmount, totalCancelationCharges, totalPaidAmount, refundText };
  };

  getDeductions = () => {
    const components = [];

    if (
      this.cancelPreviewObj &&
      this.cancelPreviewObj.cancellationAmountDetails &&
      this.cancelPreviewObj.status
    ) {
      const cancellationamountdetails = this.cancelPreviewObj.cancellationAmountDetails;

        Object.keys(cancellationamountdetails).forEach(function (key) {
          let segment = {};
          let value = cancellationamountdetails[key];
          let code = key.split(" ")[0];

          if ((value > 0.0) || key.includes("Cab Cancellation Charges")) {
            if (!(code.toLowerCase() === 'e-coupon')) {
              segment.label = key;
              segment.value = (value > 0.0) ? '- ₹ ' + value+ ' ' : ' ₹ ' + value+ ' ' ;
            }

            components.push(segment);
          }
      });
    }

    return components;
  };

  getPaidBreakup = () => {
    const components = [];
    let value;

    if (
      this.cancelPreviewObj &&
      this.cancelPreviewObj.responsePriceBreakup &&
      this.cancelPreviewObj.status
    ) {
      const paidBreakup = this.cancelPreviewObj.responsePriceBreakup;
      Object.keys(paidBreakup).forEach(key => {
        value = paidBreakup[key];
        if (
          key.toLowerCase() !== "totalpaid" &&
          Math.round(value.amount) !== 0
        ) {
          const segment = {};
          segment.label =
            key.toLowerCase() === "ecoupon"
              ? `${value.label} (${value.code})`
              : value.label;
          segment.value = `${value.discount ? "-" : ""} ₹ ${Math.round(
            value.amount
          )} `;
          segment.key = key;
          segment.isHtmlText = value.isHtmlText;
          segment.htmlTextColor = value.htmlTextColor;
          components.push(segment);
        }
      });
    }

    return components;
  };

  async fetchJSONAsync() {
    try {
      const hasNetwork = await isNetworkAvailable();
      if (!hasNetwork) {
        this.setState({ viewState: ViewState.NO_INTERNET });
        return;
      }
      const request = {
        bookingId: this.bookingId,
        action: "Preview",
        fullCancellation: {
          refundAdjustment: "Refund_To_Customer"
        }
      };
      await storeObjInAsyncStorage(this.cancelRequestKey, request);
      const {
        mmtAuth,
        loggingTrackingInfo
      } = await HotelBookingModule.getRequestHeader(
        this.props.bookingid,
        "Cab_Cancellation_Details"
      );
      this.mmtAuth = mmtAuth;
      this.loggingTrackingInfo = loggingTrackingInfo;
      const response = await fetch(CAB_CANCELLATION_URL, {
        method: "POST",
        body: JSON.stringify(request),
        headers: await getCommonHeaders(mmtAuth, loggingTrackingInfo)
      });

      if (response && response.ok) {
        const body = await response.json();
        this.penaltyResponse = body;
        this.cancelPreviewObj = body;

        if (this.penaltyResponse && this.penaltyResponse.status) {
          this.errorText = this.cancelPreviewObj.errorText;
          this.fareBreakUpObject = this.fareBreakUpObject();
          this.getDeductions = this.getDeductions();
          this.getPaidBreakup = this.getPaidBreakup();
          this.cancelButtonText =
              this.cancelPreviewObj && this.cancelPreviewObj.refundAmount === 0
                  ? "INITIATE CANCELLATION"
                  : "SELECT REFUND MODE";

          if (
              this.penaltyResponse !== null &&
              this.penaltyResponse !== undefined
          ) {
            this.setState({
              viewState: ViewState.SHOW_DETAIL
            });
          }
          CabBookingTrackingHelper.trackLoadEvent(
              this.trackingPageName,
              this.state.penaltyResponse
          );
        } else {
          this.setState({ viewState: ViewState.ERROR });
        }
      }else {
        this.setState({ viewState: ViewState.ERROR });
      }
    } catch (error) {
      this.setState({ viewState: ViewState.ERROR });
    }
  }

  goBack = () => {
    Actions.pop();
  };
}
