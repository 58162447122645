import React from 'react';
import {View, Text, Image, TouchableOpacity, TouchableHighlight} from 'react-native';
import {styles} from './CancelAndRebookStyles'
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {crossIcon} from '../../CabImageConstants';
import {CancelAndRebookConstants} from './CancelAndRebookConstants';
import RecBlueBtn from '@mmt/legacy-commons/Common/Components/Buttons/RecBlueBtn';

const gradients = ["#53B2FE", "#065AF3"];

const BottomOverlay = (props) => {
    return (
        <View style={styles.overlayContainer}>
            <TouchableHighlight style={styles.overlayBg}>
                <Text>.</Text>
            </TouchableHighlight>
            <View style={styles.overlayContent}>
                <View style={[AtomicCss.flexRow, AtomicCss.marginBottom20]}>
                    <View style={AtomicCss.flex1}>
                        <Text style={[AtomicCss.blackText, AtomicCss.font20, AtomicCss.blackFont, AtomicCss.marginBottom8]}>{CancelAndRebookConstants.CONFIRMATION}</Text>
                    </View>
                    <TouchableOpacity onPress={()=> {props.closeOverlay()}}>
                        <Image style={styles.crossIcon} source={crossIcon}/>
                    </TouchableOpacity>
                </View>
                <View style={AtomicCss.marginBottom35}>
                   <Text>{props.text} </Text>
                </View>
                <RecBlueBtn label={CancelAndRebookConstants.OK} onPress={() => {props.onClickOfOk()}} />
            </View>
        </View>
    );

}

export default BottomOverlay;
