import { colors, fonts } from '@mmt/legacy-commons/Styles/globalStyles';
import { Dimensions, I18nManager } from 'react-native';

const windowHeight = Dimensions.get('screen').height;
const windowWidth = Dimensions.get('screen').width;

const styles = {
    overlayWrapper: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: windowHeight,
        width: windowWidth,
        elevation: 10,
        // inreasing zIndex so that it come on top of other overlays
        zIndex: 110,
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        flexDirection: 'row',
        paddingBottom: 70,
    },
    overlayContent: {
        backgroundColor: colors.white,
        paddingHorizontal: 20,
        position: 'relative',
        zIndex: 1,
        width: '100%',
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        paddingTop: 20,
        paddingBottom: 40,
        top: 20
    },
    overlayTopline: {
        width: 40,
        height: 4,
        borderRadius: 4,
        backgroundColor: colors.disabledButton,
        alignSelf: 'center',
    },
    arrowStyle:{
        width: 8,
        height: 13,
        marginLeft: 10,
    },
    callImgStyle:{
        width: 32,
        height: 32,
        marginRight: 12,
    },
    linkTxt:{
        fontFamily: fonts.bold,
        fontSize: 16,
        color: colors.azure,
        marginBottom: 4
    },
    desc:{
        fontFamily: fonts.regular,
        fontSize: 12,
        color: colors.greyLight
    },
    scheduleLinkBtn:{
        borderTopColor: colors.grayBg,
        paddingTop: 20,
        borderTopWidth: 1,
    },
    title:{
        fontFamily:fonts.black,
        fontSize:22,
        color:colors.black
    },
    myraIconStyle:{
        width:64,
        height:64,
        marginLeft:20,
    },
    blueLineStyle:{
        width:24,
        height:4,
        backgroundColor:'#008cff'
    },
    connectHlpCard:{
        borderWidth:1,
        borderColor:'#e3f1ff',
        backgroundColor:'#f7fbff',
        paddingVertical:15,
        paddingHorizontal:10,
        borderRadius:6,
        flexDirection:'row',
    },
    helpCardTitle:{
        fontFamily:fonts.bold,
        color:'#008cff',
        fontSize:16,
        marginBottom:4
    },
    rtArrow:{
        width:9,
        height:13,
        marginLeft:10,
        top:3,
        transform: [{scaleX: I18nManager.isRTL ? -1 : 1}]
    },
    iconStyle:{
        width:24,
        height:24,
        marginRight:10,
    }
};

export default styles;