import { BREAKUP_KEYS } from "core-ui-lib/flight/common/Cancellation/constant";

export {
    BREAKUP_KEYS
}

export const CANCELLATION_BREAKUP_PAGE_VIEW = {
    LOADING: 'LOADING',
    ERROR: 'ERROR',
    OPEN_ERROR_PAGE: 'OPEN_ERROR_PAGE',
    OPEN_CANCELLATION_ERROR_PAGE: 'OPEN_CANCELLATION_ERROR_PAGE',
    LOADED: 'LOADED'
  }

  export const CANCELLATION_REFUNDMODE_PAGE_VIEW = {
    NO_INTERNET: 'NO_INTERNET',
    LOADING: 'LOADING',
    LOADED: 'LOADED',
    ERROR: 'ERROR',
}

export const CANCELLATION_THANKYOU_PAGE_VIEW = {
    NO_INTERNET: 'NO_INTERNET',
    LOADING: 'LOADING',
    LOADED: 'LOADED',
    ERROR: 'ERROR',
}

export const REFUNDMODE_ERROR = {
    OPEN_ERROR_PAGE: 'OPEN_ERROR_PAGE',
    API_ERROR: 'API_ERROR'
}

export const THANKYOU_PAGE_ERROR = {
    OPEN_ERROR_PAGE: 'OPEN_ERROR_PAGE',
    BUSSINESS_ERROR: 'BUSSINESS_ERROR',
    API_ERROR: 'API_ERROR'
}


export const ATTRIBUTE_KEYS = {
    ADD_ATTRIBUTES: 'addAttributes',
    REFUND_ADD_ATTRIBUTES: 'refundAddAttributes',
}

export const BREAD_CRUMB_TEXT = {
    SELECT: 'SELECT',
    REVIEW: 'REVIEW',
    CONFIRM: 'CONFIRM',
    REFUND_MODE: 'REFUND MODE' 
}