import { colors } from '@mmt/legacy-commons/Styles/globalStyles';

const styles = {
  pageWrapper: {
    flex: 2,
    backgroundColor: colors.white,
    justifyContent: 'space-between',
    paddingTop: 88,
    paddingBottom: 50,
  },
  footerBtnWrapper: {
    paddingLeft: 50,
    paddingRight: 50,
    paddingTop: 10,
    top: 40,
  },
  messageContent: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: 50,
  },
  messageIcon: {
    width: 50,
    height: 44.3,
    marginBottom: 40,
  },
  messageTitle: {
    color: colors.green,
    marginBottom: 10,
    textAlign: 'center',
  },
  messageSubTitle: {
    color: colors.black,
    marginBottom: 10,
    textAlign: 'center',
  },
  errMessageTitle: {
    color: colors.red,
    marginBottom: 10,
    textAlign: 'center',
  },
  messageDesc: {
    color: colors.gray,
    textAlign: 'center',
  },
  ErrorPageWrapper: {
    backgroundColor: '#ffffff',
    paddingVertical: 20,
    paddingHorizontal: 40,
  },
  ErrorImage: {
    width: 60,
    height: 60,
    marginBottom: 80,
  },
  mar20Bt: {
    marginBottom: 20,
  },
  CenterText: {
    alignSelf: 'center',
  },
  ErrorPageText: {
    fontSize: 20,
    textAlign: 'center',
    lineHeight: 25,
    color: '#3c3c3c',
  },
  AlignCenter: {
    textAlign: 'center',
  },
  lineHt22: {
    lineHeight: 22,
  },
  mar15Bt: {
    marginBottom: 15,
  },
  goBackBtn: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 8,
    marginHorizontal: 16,
    marginBottom: 30,
    borderRadius: 2,
  },
};

export default styles;
