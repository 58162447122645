import React from 'react';
import {View, Text, StyleSheet} from 'react-native';
import Call from './Call';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getPlatformElevation from '@mmt/legacy-commons/Common/Components/Card/getPlatformElevation';
import BasePage from '../../../../Common/PostSalesBasePage';


class Support extends BasePage {
    constructor(props) {
        super(props, 'MMTHelpLineSupportPage');
    }

    render() {
        return (
            <View style={[styles.whiteWrapper, AtomicCss.marginBottom10]}>
                <View style={styles.CardContent}>
                    <View style={[AtomicCss.flex1]}>
                        <Text
                            style={[AtomicCss.blackFont, AtomicCss.blackText, AtomicCss.paddingTop3, AtomicCss.textCenter]}>
                            {this.props.card.header}
                        </Text>
                    </View>
                </View>
                <View style={[styles.ActionBtnHalf, styles.supportWrap]}>
                    {this.fetchListOfActions(this.props.card.actionList)}
                </View>
            </View>
        );
    }

    fetchListOfActions = (actions) => {
        let views = [];
        Object.keys(actions).map(key => {
            views.push(
                <Call key={key} action={actions[key]}
                response={this.props.response}/>
            )
        })
        return views;
    }
}

const styles = StyleSheet.create({
    whiteWrapper: {
        backgroundColor: '#ffffff',
        marginBottom: 10,
        ...getPlatformElevation(2),
        marginTop: -2,
    },
    CardContent: {
        backgroundColor: '#fff',
        borderBottomWidth: 1,
        borderBottomColor: '#e4e4e4',
        flexDirection: 'row',
        padding: 15,
        elevation: 1
    },
    ActionBtnHalf: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        width: '100 %',
        backgroundColor: '#fff',
        paddingBottom: 10,
        marginTop: 15
    },
    supportWrap: {paddingTop: 16, paddingBottom: 24}
});

export default Support;
