import ViewState from 'packages/legacy-commons/Common/constants/ViewState';
import {PAGE_NAME} from '../../../CabBookingDetailsConstant'

export const getCabReschedulePageName = (viewState) => {
    let pageName = '';
    switch (viewState) {
      case ViewState.SHOW_DETAIL:
        pageName = PAGE_NAME.CAB_RESCHEDULE_PAGE;
        break;

      case ViewState.ERROR:
        pageName = PAGE_NAME.CAB_RESCHEDULE_ERROR_PAGE;
        break;

      case ViewState.SUCCESS:
        pageName = PAGE_NAME.CAB_RESCHEDULE_REQUESTED_PAGE;
        break;
    }
    return pageName;
  }