import React from 'react';
import {
    ActionsContent,
    DescriptionSection,
    MainContent,
    StatusInfoSection,
    SummaryContainer,
    VisaCardHeader,
    VisaCardSection,
    WhereToGoTopImage
} from '../cardElements';
import getVisaSummaryData from './visaCardAdapter';
import { MsrTicket, VisaSummaryDataProps } from '../cardInterfaces';
import MSRRefundInfoCard from '../MSRRefundInfoCard';

interface VisaSummaryCardProps {
    response: VisaSummaryDataProps;
    msrTicket: MsrTicket;
    isUpcomingOrOnTripFirstBooking: boolean,
    topImage: string
}
function VisaSummaryCard({ response, msrTicket, isUpcomingOrOnTripFirstBooking, topImage }: VisaSummaryCardProps) {
    const cardData = getVisaSummaryData(response, msrTicket);
    const { iconUrl,
        countryName,
        description,
        flagUrl,
        visaStatus,
        validityText,
        validity,
        myRequestsCard,
        startsIn,
        paxInfo,
        actions,
        infoCardStatusText,
        infoCardStatusCTA,
        lobName,
        bookingId,
        status,
        openDetails,
        handleInfoPress } = cardData || {};

    return (
        <SummaryContainer bookingId={response.bookingID}>
            {topImage && isUpcomingOrOnTripFirstBooking &&
                <WhereToGoTopImage topImageText={startsIn} topImage={topImage} />
            }
            <MainContent onPress={openDetails}>
                <DescriptionSection>
                    <VisaCardHeader iconUrl={iconUrl} status={visaStatus} paxInfo={paxInfo} />
                    <VisaCardSection iconUrl={flagUrl} name={countryName} visaType={description} validityText={validityText} validity={validity} />
                    {infoCardStatusText && <StatusInfoSection statusText={infoCardStatusText} statusCta={infoCardStatusCTA} handleInfoPress={handleInfoPress} />}
                </DescriptionSection>
            </MainContent>
            <ActionsContent actions={actions} isInfoCardShown={!!infoCardStatusText}/>
            {myRequestsCard && <MSRRefundInfoCard data={myRequestsCard} lobName={lobName} status={status} bookingId={bookingId} />}
        </SummaryContainer>
    );
}

export default VisaSummaryCard;