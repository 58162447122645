import {HOTEL_DETAIL_ACTIONS} from './actions';

const initialState = {
  resetBookingId: '',
  pageNameForHelpingHand: '',
  hotelDetailsResponseEng: null,
  isHotelBookingDetailsFetching: false,
  isHotelBookingAPIErrorEng: false,
  hotelDetailsErrorData: null,
};

const hotelDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case HOTEL_DETAIL_ACTIONS.RESET_HOTEL_BOOKING_DETAIL:
      const {resetBookingId} = action;
      return {
        ...state,
        // resetBookingId is the bookingId for which the trip detail page has to be refreshed.
        resetBookingId,
        hotelDetailsResponseEng: null,
        isHotelBookingDetailsFetching: false,
        hotelDetailsErrorData: null,
        isHotelBookingAPIErrorEng: false,
      };
    case HOTEL_DETAIL_ACTIONS.SHOW_HELPING_HAND:
      const {pageNameForHelpingHand} = action;
      return {
        ...state,
        // pageNameForHelpingHand is the page for which helping hand has to be shown.
        pageNameForHelpingHand
      };
    case HOTEL_DETAIL_ACTIONS.FETCH_HOTEL_BOOKING_DETAILS_ENG:
      return {
        ...state,
        resetBookingId: '',
        isHotelBookingDetailsFetching: true,
        isHotelBookingAPIErrorEng: false,
        hotelDetailsResponseEng: null,
        hotelDetailsErrorData: null,
      }
    case HOTEL_DETAIL_ACTIONS.HOTEL_DETAILS_API_SUCCESS_ENG:
      return {
        ...state,
        resetBookingId: '',
        hotelDetailsResponseEng: action.payload,
        isHotelBookingDetailsFetching: false,
        isHotelBookingAPIErrorEng: false,
        hotelDetailsErrorData: null,
      }
    case HOTEL_DETAIL_ACTIONS.HOTEL_BOOKING_DETAILS_API_ERROR_ENG:
      return {
        ...state,
        resetBookingId: '',
        isHotelBookingDetailsFetching: false,
        isHotelBookingAPIErrorEng: true,
        hotelDetailsErrorData: action.payload,
      }
    default:
      return {
        ...state
      };
  }
};


export default hotelDetailReducer;
