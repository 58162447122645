import React, {useMemo} from 'react';
import {View, Text, Image, I18nManager,StyleSheet} from 'react-native';
import PropTypes from 'prop-types';
import styles from './RefundCardCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {fonts,colors} from '@mmt/legacy-commons/Styles/globalStyles';
import Tracker from '../../../flights/modules/details/components/Tracker';
import {isNonEmpty} from '@mmt/legacy-commons/Common/utils/StringUtils';
import { getImagePath } from '../../../utils/PostSaleUtil';
import HTMLView from 'react-native-htmlview';
import {htmlStyle, onRefundCardHTMLLinkPress} from '../../../utils/RefundCardUtils';
import CfarBenefitStrip from '../../CfarBenefitStrip'
const back_white_icon = require('@mmt/legacy-assets/src/back-white.webp');
const userIcon = require('@mmt/legacy-assets/src/userIcon.webp');
const rupeeIcon = I18nManager.isRTL ? require('@mmt/legacy-assets/src/ic_refundAraCurrency.webp') : require('@mmt/legacy-assets/src/refundIcon.webp');
const doneIcon = getImagePath('double-tick.webp');
const priceIcon = require('@mmt/legacy-assets/src/Images/priceIcon.webp');
const ksaCurrencyIcon = require('@mmt/legacy-assets/src/postSales/ic_ksa_currency.webp');

const RefundCard =    ({
  progressList,
  messageStripObj,
  myPromiseList,
  refundModesMessageList,
  refundAmount, heading, marginZero,
  refundStatusText,
  refundModeBreakupList,
  refundText,
  noRefundText,
  onRefundModeWebViewBackPress,
  onRefundModeSubMessageLinkPress,
  bookingId,
  isRoundedCornerAndPadding,
  trackRefundEvent,
  currency,
  navigation,
  canReqVsPenaltyMap,
  requestNo,
  isCfarSuccess,
  customStyle,
  showIconImg = true
}) => {
  const getTitle = () => {
    const refundAmountValue = parseInt(refundAmount.split(' ')[1], 0);
    if (refundAmountValue <= 0) {
      return 'NO REFUND ON CANCELLATION';
    }
    if (isNonEmpty(refundStatusText)) {
      return refundStatusText;
    }
    return `REFUND OF ${refundAmount} - PROCESSED`;
  };

  const text = refundText || noRefundText;
  const currencyImageObject = useMemo(() => {
    let currencyImageObj = {};
    currencyImageObj.src = rupeeIcon;
    currencyImageObj.style = styles.headingIconStyle;
    if (!currency || currency.toLowerCase() === 'inr' || currency === '₹') {
      return currencyImageObj;
    }
    currencyImageObj.src = ksaCurrencyIcon;
    currencyImageObj.style = styles.ksaCurrencyImageStyle;
    return currencyImageObj;
  }, [currency]);

  const cfarBenefitItem = !!canReqVsPenaltyMap ? canReqVsPenaltyMap[requestNo] : null;

  return (
    <View style={[marginZero ? [AtomicCss.marginBottom0] : [AtomicCss.marginBottom10], customStyle?customStyle:{}, isRoundedCornerAndPadding ? [AtomicCss.whiteCardNew, AtomicCss.marginHz12, AtomicCss.overflow] : null]}>
      <View style={[styles.infoCardWrapper]}>
        {showIconImg &&
          <View style={styles.headingIconWrapper}>
            <Image style={currencyImageObject.style} source={currencyImageObject.src} />
          </View>
        }
        <View style={styles.infoCardContent}>
          {isNonEmpty(text) &&
            <HTMLView
              style={[AtomicCss.defaultText, AtomicCss.blackFont, AtomicCss.font14, AtomicCss.marginBottom20, AtomicCss.alignLeft]}
              value={text}
              stylesheet={htmlStyleText}
            />
          }
          {!isNonEmpty(text) &&
            <Text style={[AtomicCss.defaultText, AtomicCss.blackFont, AtomicCss.font14, AtomicCss.marginBottom20, AtomicCss.alignLeft]}>
              {getTitle()}
            </Text>
          }

          {
              heading &&
              <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, styles.userCancelledWrapper]}>
                <Image style={styles.userIconStyle} source={userIcon} />
                <Text style={[AtomicCss.defaultText, AtomicCss.font14, AtomicCss.boldFont, AtomicCss.paddingRight20]}>
                  {heading}
                </Text>
              </View>
            }

          {myPromiseList && myPromiseList.length > 0 && myPromiseList.map(item => (<React.Fragment>
            <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom15]}>
              <Image style={styles.doneIconStyle} source={doneIcon} />
              <HTMLView
                style={styles.useCaseTxt}
                value={item.promiseCommitmentText}
              />
            </View>
            <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom10]}>
              <Image style={styles.priceIconStyle} source={priceIcon} />
              <HTMLView
                style={[AtomicCss.defaultText, AtomicCss.boldFont,styles.useCaseTxt]}
                value={item.promisePenaltyText}
              />
            </View>
          </React.Fragment>))
            }
          <Tracker
            borderTopActive={myPromiseList && myPromiseList.length > 0}
            progressList={progressList}
          />
          {!!cfarBenefitItem &&
            <CfarBenefitStrip cfarBenefitItem={cfarBenefitItem} isCfarSuccess={isCfarSuccess}/>
          }
          {refundModesMessageList && refundModesMessageList.length > 0 &&
            <View style={{
borderTopWidth: 1, borderTopColor: '#e7e7e7', paddingTop: 15, marginTop: 15
}}
            >
                {refundModesMessageList.map(item =>
                    (<View style={[AtomicCss.marginBottom10]}>
                      {isNonEmpty(item.message) && <Text
                        style={[AtomicCss.defaultText, AtomicCss.boldFont, AtomicCss.font14, AtomicCss.lineHeight18, AtomicCss.marginBottom3]}
                      >
                        {item.message}
                      </Text>}
                      {isNonEmpty(item.subMessage) && <HTMLView
                      value={item.subMessage}
                      onLinkPress={onRefundCardHTMLLinkPress.bind(this,onRefundModeSubMessageLinkPress, onRefundModeWebViewBackPress, bookingId, trackRefundEvent, navigation)}
                      stylesheet={htmlStyle}
                    />}
                    </View>))

                }
            </View>
            }

            {refundModeBreakupList && refundModeBreakupList.length > 0 &&
            <View style={{
              borderTopWidth: 1, borderTopColor: '#e7e7e7', paddingTop: 15, marginTop: 15
              }}
            >
              {refundModeBreakupList.map(item =>
                  (<View style={[AtomicCss.marginBottom10, AtomicCss.flexRow]}>
                    <View style={[AtomicCss.flex1]}>
                      {isNonEmpty(item.header) && <Text
                        style={[AtomicCss.defaultText, AtomicCss.boldFont, AtomicCss.font14, AtomicCss.lineHeight18, AtomicCss.marginBottom3]}
                      >
                        {item.header}
                      </Text>}
                      {isNonEmpty(item.subHeader) && <Text
                        style={[AtomicCss.greyText, AtomicCss.regularFont, AtomicCss.font14, AtomicCss.lineHeight18, AtomicCss.alignLeft]}
                      >
                        {item.subHeader}
                      </Text>}
                    </View>
                    <Text style={[AtomicCss.defaultText, AtomicCss.boldFont]}>{item.value}</Text>
                  </View>))
              }
            </View>
            }
        </View>
      </View>
    </View>
  );
};


RefundCard.propTypes = {
  progressList: PropTypes.array.isRequired,
  // messageStripObj: PropTypes.string.isRequired,
  myPromiseList: PropTypes.array.isRequired,
  refundModesMessageList: PropTypes.array.isRequired,
  refundAmount: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  refundStatusText: PropTypes.string.isRequired
};

const htmlStyleText = StyleSheet.create({
  span:{
    color:colors.defaultTextColor,
    fontFamily:fonts.black,
  },
  div:{
    color:colors.defaultTextColor,
    fontFamily:fonts.black,
  },
  p:{
    color:colors.defaultTextColor,
    fontFamily:fonts.black,
  },
  b:{
    color:colors.defaultTextColor,
    fontFamily:fonts.black,
  }
});

export default RefundCard;
