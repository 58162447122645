import React from 'react';
import {View, Text, Image} from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import styles from './CarDeliveryDtlsCardCss'
import  {DRIVER_ICON} from '../../../SelfDriveImageConstant';

const CarDeliveryDtlsCard = ({card}) => {
    return (
        <View style={[AtomicCss.marginBottom10, styles.cardBorder]}>
            <View style={[styles.infoCardWrapper]}>
                <View style={styles.headingIconWrapper}>
                    <Image style={styles.headingIconStyle} source={DRIVER_ICON}/>
                </View>
                <View style={styles.infoCardContent}>
                    <Text
                        style={[AtomicCss.defaultText, AtomicCss.blackFont, AtomicCss.font14, AtomicCss.marginBottom15, AtomicCss.textUppercase]}>{card.header} </Text>
                    {fetchSubHeader(card.subHeaders)}

                </View>
            </View>
        </View>
    )

    function fetchSubHeader(subHeader) {
        let views = [];
        Object.keys(subHeader).map(key => {
            views.push(
                <Text
                    style={[AtomicCss.regularFont, AtomicCss.font14, AtomicCss.defaultText, AtomicCss.lineHeight20]}>
                    {subHeader[key]}
                </Text>
            )
        })
        return views;
    }
}


export default CarDeliveryDtlsCard
