import { BookingStates, Lobs } from '../../../../summaryConstants';
import myTripsNavigation from '../../../../navigation/navigation';
import { handleCTAClick } from '../../../utils';
import { LostIDSummaryData, LostIDSummaryDataProps } from '../cardInterfaces';
import { busImage } from 'apps/post-sales/src/bus/BusImageConstants';
import { trainIcon } from 'apps/post-sales/src/Rails/Utils/RailImageConstant';
import { cabIcon } from 'apps/post-sales/src/cab/CabImageConstants';
import { holidaysIcon } from 'apps/post-sales/src/Holiday/HolidayBookingConstants';
import { giftCardIcon } from 'apps/post-sales/src/giftcard/GiftCardImageConstants';
import { hotelIcon } from 'apps/post-sales/src/hotel/HotelImageConstants';
import { flightIcon } from 'apps/post-sales/src/flights/FlightImageConstants';

export function getLostIDSummaryData(data: LostIDSummaryData) : LostIDSummaryDataProps {

  let bookingIntent, bookingStatus, lobName, status, paymentAttempts, isPushedAsLostId, isBookingConfirmed;
  const { ctaInfoList: ctaDetails, infoCard } = data && data.bookingStateLayout || {};
  const { text, ctaInfo } = infoCard || {};
  const infoCardStatusText = text;
  const infoCardStatusCTA = ctaInfo && ctaInfo.text;
  if (data.lostBookingDetails) {
    bookingIntent = data.lostBookingDetails.bookingIntent;
    bookingStatus = data.lostBookingDetails.bookingStatus;
    lobName = data.lostBookingDetails.lobname;
    isBookingConfirmed = data.lostBookingDetails.isBookingConfirmed;
    paymentAttempts = data?.lostBookingDetails?.paymentAttempts || [];
  } else {
    bookingIntent = data.bookingIntent; 
    bookingStatus = data.bookingStatus;
    lobName = data.lobname;
    isBookingConfirmed = data.isBookingConfirmed;
    paymentAttempts = data?.paymentAttempts || [];
  }
  status = data.status;
  const isPending = status === BookingStates.PENDING;
  isPushedAsLostId = data.isPushedAsLostId;
  const paymentInfo = paymentAttempts && paymentAttempts[0] ? paymentAttempts[0] : {};
  const requestNo = paymentInfo.payId || '';
  const primaryLob = lobName || [];
  let iconUrl = '';
  let icon = '';
  let iconStyle = { height: 24, width: 24 };
  let openDetails = null;
  const getPageData = (raiseReqInfo = {}) => ({
    lostBooking: true,
    isPushedAsLostId,
    requestNo,
    primaryLob,
    fromMyTrips: true,
    refundCommStatus: data.refundCommStatus,
    lostRaiseReqInfo: raiseReqInfo,
    isBookingConfirmed: isBookingConfirmed,
  });
  switch (primaryLob) {
    case Lobs.Flight:
      icon = flightIcon;
      iconStyle = { height: 18, width: 30 };
      openDetails = (raiseReqInfo, trackClickEvent = true) => {
        myTripsNavigation.openFlightBookingDetail(data, trackClickEvent, getPageData(raiseReqInfo));
      };
      break;
    case Lobs.Hotel:
      icon = hotelIcon;
      iconStyle = isPending ? { height: 29, width: 27 } : { height: 20, width: 20 };
      openDetails = (raiseReqInfo, trackClickEvent = true) => {
        myTripsNavigation.openHotelBookingDetail(data, getPageData(raiseReqInfo), trackClickEvent);
      };
      break;
    case Lobs.Bus:
      iconUrl = busImage;
      iconStyle = { height: 20, width: 19 };
      openDetails = (raiseReqInfo, trackClickEvent = true) => {
        myTripsNavigation.openBusBookingDetail(data, getPageData(raiseReqInfo), trackClickEvent);
      };
      break;
    case Lobs.Rail:
      iconUrl = trainIcon;
      iconStyle = { width: 20, height: 26 };
      openDetails = (raiseReqInfo, trackClickEvent = true) => {
        myTripsNavigation.openRailBookingDetail(data, getPageData(raiseReqInfo), trackClickEvent);
      };
      break;
    case Lobs.Cabs:
      iconUrl = cabIcon;
      iconStyle = { width: 23, height: 20 };
      openDetails = (raiseReqInfo, trackClickEvent = true) => {
        myTripsNavigation.openCabBookingDetail(data, getPageData(raiseReqInfo), trackClickEvent);
      };
      break;
    case Lobs.Acme:
      iconUrl = holidaysIcon;
      iconStyle = { height: 25, width: 22 };
      openDetails = (raiseReqInfo, trackClickEvent = true) => {
        myTripsNavigation.openActivityBookingDetails(
          data,
          getPageData(raiseReqInfo),
          trackClickEvent,
        );
      };
      break;
    case Lobs.GiftCard:
      iconUrl = giftCardIcon;
      iconStyle = { width: 32, height: 32 };
      openDetails = (raiseReqInfo, trackClickEvent = true) => {
        myTripsNavigation.openGiftCardBookingDetail(
          data,
          getPageData(raiseReqInfo),
          trackClickEvent,
        );
      };
      break;
    case Lobs.HOLIDAYS:
      iconUrl = holidaysIcon;
      iconStyle = { height: 25, width: 17 };
      openDetails = (raiseReqInfo, trackClickEvent = true) => {
        myTripsNavigation.openHolidayBookingDetail(
          data,
          getPageData(raiseReqInfo),
          trackClickEvent,
        );
      };
      break;
      case Lobs.TGS:
        iconUrl = trainIcon;
        iconStyle = { width: 32, height: 32 };
        openDetails = (raiseReqInfo, trackClickEvent = true) => {
          myTripsNavigation.openTripGuaranteeBookingDetail(
            data,
            getPageData(raiseReqInfo),
            trackClickEvent,
          );
        };
        break;
  }

  const actions: Array<{ label: string; onPress: () => void }> = [];

  if (ctaDetails && ctaDetails[0]) {
    actions.push({ label: ctaDetails[0]?.text, onPress: () => openDetails() })
  };
  if (ctaDetails && ctaDetails[1]) {
    actions.push({ label: ctaDetails[1]?.text, onPress: () => openDetails() })
  };

  const handleInfoClick = () => {
    if (infoCard) {
      handleCTAClick(infoCard.ctaInfo, data);
    }
  };

  return {
    title: bookingIntent,
    subTitle: bookingStatus,
    openDetails,
    iconUrl,
    icon,
    status,
    lobName,
    actions,
    payStatusText: paymentInfo.payStatusText || null,
    paymentTextLine1: paymentInfo.paymentTextLine1 || null,
    paymentTextLine2: paymentInfo.paymentTextLine2 || null,
    infoCardStatusText,
    infoCardStatusCTA,
    handleInfoPress: handleInfoClick,
  };
}
