import { colors } from '@mmt/legacy-commons/Styles/globalStyles';

const styles = {
  raiseClaimTextContainer: {
    flexDirection: 'row',
    paddingBottom: 16,
    paddingTop: 4,
    marginRight: 16,
  },
  raiseClaimIcon: {
    width: 22,
    height: 19,
    top: 2,
  },
  raiseClaimText: {
    color: colors.defaultTextColor,
    lineHeight: 17,
    flex: 1,
    marginLeft: 10,
  },
  raiseClaimClickableText: {
    color: colors.azure,
    lineHeight: 17,
  },
  raiseClaimPartial: {
    lineHeight: 17,
    marginLeft: '13%',
    color: colors.azure,
    paddingTop: 10,
  },
  actionBtnSubText: {
    color: colors.azure,
    fontSize: 12,
    lineHeight: 14,
    marginTop: 4,
  },
};

export default styles;
