const TGS_ROUTE_CONFIG = [
    {
      key: 'tripGuaranteeBookingDetail',
      component: () => require('./pages/details/index').default
    },
    {
      key: 'tgsPriceBreakUp',
      component: () => require('./pages/priceBreakup/index').default
    },
]

export default TGS_ROUTE_CONFIG