import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import styles from './TripProtectCardCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getStaticData } from '../../../../../staticData/staticData';

import { TRIP_PROTECTIPN_ICON, RIGHT_ARROW, FLT_POLICY_ICON } from '../../../../FlightImageConstants';
import { getFont } from '../../../../../PostSalesStyles';

const TripProtectCard = ({
  insuranceDetails,
  insuranceBtnClick,
  policyBtnClick,
  cardType,
  refElement,
}) => {
  const { fsStyle, ...fonts } = getFont(true);
  const {
    tripProtectCardText: {
      additionalText: { policyNumbersText },
    },
    viewProtectionDetailsText,
  } = getStaticData();

  const actionItemView = () => {
    const views = [];
    if (
      insuranceDetails.provider === 'DIGIT' &&
      insuranceDetails.journeys &&
      insuranceDetails.journeys.length > 0
    ) {
      views.push(
        <TouchableOpacity
          style={styles.redirectBtnWrapper}
          activeOpacity={0.8}
          onPress={() => insuranceBtnClick(insuranceDetails, cardType)}
        >
          <Text style={[styles.linkBtnTxt, fonts.boldFontFamily, fsStyle.font14]}>
            {viewProtectionDetailsText}
          </Text>
          <Image style={styles.rightArrowIcon} source={RIGHT_ARROW} />
        </TouchableOpacity>,
      );
    }
    if (!isEmpty(insuranceDetails.tripProtectCtaList)) {
      insuranceDetails.tripProtectCtaList.forEach((cta) => {
        views.push(
          <TouchableOpacity
            style={styles.redirectBtnWrapper}
            activeOpacity={0.8}
            onPress={() => policyBtnClick(cta, cardType)}
          >
            <Text style={[styles.linkBtnTxt, fonts.boldFontFamily, fsStyle.font14]}>
              {cta.text}
            </Text>
            <Image style={styles.rightArrowIcon} source={RIGHT_ARROW} />
          </TouchableOpacity>,
        );
      });
    }
    return views;
  };

  const getHeaderIcon = () => {
    const imageIconStyle = {};
    imageIconStyle.style = styles.headingIconStyle;
    imageIconStyle.icon = TRIP_PROTECTIPN_ICON;
    return imageIconStyle;
  };
  const getPolicyText = () => {
    if (isEmpty(insuranceDetails.policyNos)) {
      return null;
    }
    const policySize = insuranceDetails.policyNos.length;
    const policySet = [...new Set(insuranceDetails.policyNos)];
    if (policySize <= 3) {
      const policyStr = policySet.join(', ');
      if (isEmpty(policyStr)) {
        return null;
      }
      return policyStr;
    }
    let policyStrForShowLess = '';
    let separator = '';
    for (let i = 0; i < 3; i += 1) {
      policyStrForShowLess += separator + insuranceDetails.policyNos[i];
      separator = ', ';
    }
    return policyStrForShowLess;
  };

  const imageStyle = getHeaderIcon();

  return (
    <View ref={refElement} style={[AtomicCss.marginBottom10]}>
      <View style={[styles.infoCardWrapper]}>
        <View style={styles.headingIconWrapper}>
          <Image style={imageStyle.style} source={imageStyle.icon} />
        </View>
        <View style={styles.infoCardContent}>
          <Text
            style={[
              AtomicCss.defaultText,
              fonts.blackFontFamily,
              fsStyle.font14,
              AtomicCss.marginBottom15,
            ]}
          >
            {insuranceDetails.heading}
          </Text>
          <Text
            style={[
              fonts.regularFontFamily,
              fsStyle.font14,
              AtomicCss.defaultText,
              AtomicCss.lineHeight20,
            ]}
          >
            {insuranceDetails.description}
          </Text>
          {!isEmpty(getPolicyText()) && (
            <View style={styles.policySection}>
              <Image style={imageStyle.style} source={FLT_POLICY_ICON} />
              <Text style={[styles.policyText, fonts.regularFontFamily, fsStyle.font12]}>
                <Text>{policyNumbersText}</Text>
                <Text> : </Text>
                <Text>{getPolicyText()}</Text>
                {insuranceDetails.policyNos.length > 3 && (
                  <Text style={[styles.morePolicyText, fonts.boldFontFamily, fsStyle.font12]}>
                    +{insuranceDetails.policyNos.length - 3}
                  </Text>
                )}
              </Text>
            </View>
          )}
        </View>
      </View>
      {actionItemView()}
    </View>
  );
};

TripProtectCard.propTypes = {
  insuranceDetails: PropTypes.object.isRequired,
  insuranceBtnClick: PropTypes.func.isRequired,
  policyBtnClick: PropTypes.func.isRequired,
};

export default TripProtectCard;
