import {statusBarHeightForIphone} from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from '@mmt/legacy-commons/Common/Components/Card/getPlatformElevation';
import { StyleSheet, Platform } from 'react-native';

const getStyle = (color) => {
  return StyleSheet.create({
    OverlayContentWrapper: {
      backgroundColor: color.white,
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
    },
    pedZero: {
      paddingHorizontal: 0,
      paddingVertical: 0,
    },
    mainView: {
      flex: 1,
      marginTop: -statusBarHeightForIphone,
      marginBottom: 0,
    },
    overlayContainer: {
      justifyContent: 'flex-end',
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      zIndex: 1000,
      elevation: 3,
    },
    lostBookingContainer: {
      flex: 1,
      borderTopWidth: Platform.OS === 'ios' ? 0 : 0,
    },
    tgsOverlayAnimation:{
      backgroundColor: color.white,
      borderTopLeftRadius: 16,
      borderTopRightRadius: 16,
    },
    overlayBg: {
      backgroundColor: color.lightBlack3,
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      zIndex: 1,
    },
    refundTrackerCardStyle: {
      marginHorizontal: 0,
      paddingHorizontal: 5,
      marginTop: 10
    },
    footer: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      height: 50,
      padding: 10,
      backgroundColor: color.lightBlue20,
      alignItems: 'center',
      justifyContent: 'center',
    },
    whiteCard: {
      marginBottom: 10,
      marginHorizontal: 12,
      backgroundColor: color.white,
      ...getPlatformElevation(1),
      borderRadius: 8,
    }
  });
};

export default getStyle;
