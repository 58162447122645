import { StyleSheet } from 'react-native';

export default (theme, fonts) =>
  StyleSheet.create({
    overlayContentWrapper: {
      paddingHorizontal: 30,
      paddingTop: 15,
      position: 'relative',
      zIndex: 999,
      borderRadius: 4,
      backgroundColor: theme.color.white,
      width: '90%',
    },
    contentWrapper: {
      width: '100%',
    },
    iconStyle: {
      width: 60,
      height: 60,
      marginBottom: 10,
    },
    headingTxt: {
      ...fonts.blackFontFamily,
      color: theme.color.black,
      fontSize: 18,
      lineHeight: 25,
      marginBottom: 10,
    },
    subHeadingTxt: {
      fontSize: 12,
      ...fonts.blackFontFamily,
      color: theme.color.greyLight,
      textTransform: 'uppercase',
      marginBottom: 10,
      zIndex: -2,
    },
    btnWrapper: {
      marginTop: 30,
      width: '100%',
      marginBottom: 20,
      zIndex: -1,
    },
    dropDownWrapper: {
      flexDirection: 'row',
      padding: 10,
      backgroundColor: theme.color.grayBg,
      zIndex: -4,
    },
    dropDownSelct: {
      borderWidth: 1,
      borderColor: theme.color.gray28,
      backgroundColor: theme.color.white,
      height: 36,
      paddingHorizontal: 10,
      zIndex: -4,
    },
    arrowStyle: { width: 13, height: 9, marginLeft: 10, marginRight: 3 },
    dropDownSelctOption: {
      backgroundColor: theme.color.white,
      position: 'absolute',
      width: '100%',
      borderWidth: 1,
      borderColor: theme.color.greyBookedSeat,
      maxHeight: 150,
      top:-46,
    },
    optionItem: {
      paddingVertical: 7,
      paddingHorizontal: 8,
    },
    optionText: {
      color: theme.color.black,
      fontSize: 14,
      ...fonts.blackFontFamily,
      lineHeight: 22,
    },
    dropDownSelctTxt:{
      ...fonts.blackFontFamily,
    },
    errorText:{
      color: theme.color.red,
    },
    errorContainer:{
      padding:5,
      marginBottom:5,
      alignItems: 'center',
      minHeight:30
    },
    errorActive:{
      backgroundColor: theme.color.lightGray,
    }
  });
