import {colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles.js';

const styles = {
  btn: {
    borderColor: colors.azure,
    borderWidth: 1,
    borderRadius: 20,
    height: 38,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row'
  },
  btnText: {
    fontSize: 16,
    color: colors.azure,
    fontFamily: fonts.bold
  },
  btnIcon: {
    height: 19,
    width: 19,
    marginRight: 7
  }
};

export default styles;
