
import React from 'react';
import { View, Image, Text } from 'react-native';
import { getFont } from '../../../../PostSalesStyles';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import createStyles from './tripGuaranteeCard'
import { isEmpty } from 'lodash';

const InfoCardTGS = ({ iconUrl, desc, heading, imageList=[] }) => {
    const fonts = getFont();
    const { psTheme: theme } = useTheme();
    const styles =  createStyles(theme,fonts);
  
    return (
      <View style={[styles.infoCards, styles.infoCardsWidth]}>
        <Image style={[styles.iconStyles, AtomicCss.marginBottom8]} source={{uri:iconUrl}} />
        {!isEmpty(heading) && <Text style={[AtomicCss.blackText, fonts.boldFontFamily, fonts.fsStyle.font16, AtomicCss.marginBottom8, AtomicCss.fontWeightBold]}>{heading}</Text>}
        {!isEmpty(desc) && <Text style={[AtomicCss.blackText, AtomicCss.regularFont, fonts.fsStyle.font14, AtomicCss.lineHeight18]}>{desc}</Text> }
        {!isEmpty(imageList) &&
          <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween, AtomicCss.marginTop10, AtomicCss.alignCenter]}>
            {imageList.map((imageUrl)=>{
              return <View key={imageUrl}>
                <Image style={styles.lobIcon} source={{uri:imageUrl}} resizeMode={'contain'} />
              </View>
            })}
          </View>
        }
      </View>
    )
  }


export default InfoCardTGS;