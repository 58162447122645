import {StyleSheet} from 'react-native'
import { colors, fonts } from '@mmt/legacy-commons/Styles/globalStyles';

const styles = StyleSheet.create({
    whiteCard:{padding:0, marginVertical:0},
    pageWrapper:{
        flex:1,
    },
    topHeading:{
        paddingHorizontal:16,
        paddingVertical:12,
        borderBottomWidth: 1,
        borderBottomColor: colors.lightGray
    },
    queryResWrapper:{
        padding:16,
    },
    queryBotWrapper:{
        backgroundColor: colors.black,
    },
    queryBotResWrapper:{
        paddingHorizontal:24,
        paddingVertical:24,
        backgroundColor: colors.white,
        borderTopLeftRadius:55
    },
    bottomStickyWrapper:{
        position:'relative'
    },
    topHeadWithCrossIcon:{
        flexDirection: 'row',
        width:'90%',
        backgroundColor: colors.black,
        paddingHorizontal:8,
        paddingVertical:5,
        borderTopRightRadius:25,
        position:'absolute',
        top:-28,
        minHeight:50,
    },
    closeIcon:{
        width:14,
        height:14,
        marginRight: 36,
        marginTop:5,
        marginLeft:5,
        tintColor: colors.white
    },
    queryBtn:{
        paddingHorizontal:16,
        paddingVertical:8,
        backgroundColor: colors.gray5,
        borderRadius: 4,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width:'48%'
    },
    queryBtnActive:{
        backgroundColor: colors.purpleyGrey2,
    },
    midSection:{
        width:16
    },
    iconStyle:{
        width: 28,
        height: 28,
        marginRight:14
    },
    resolveTxt:{
        // fontFamily: fonts.black,
        fontSize: 14,
        color: colors.black
    },
    resolveTxtActive:{
        color: colors.white
    },
    statusTxt:{
        // fontFamily: fonts.regular,
        fontSize: 12,
        color: colors.black
    },
    statusTxtActive:{
        color: colors.white
    }
});
export default styles;
