import React, { useState } from 'react';
import { View, Image, Text, TouchableOpacity, Platform, Dimensions } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { crossIcon } from 'apps/post-sales/src/cab/CabImageConstants';
import CabBookingDetailsConstant, { CLICK_EVENT, PAGE_NAME } from 'apps/post-sales/src/cab/CabBookingDetailsConstant';
import isEmpty from 'lodash/isEmpty';
import { useTheme } from '../../../../../theme/theme.context';
import { getFont } from '../../../../../PostSalesStyles';
import getStyle from './styles';
import { InvoiceListProps, InvoiceListRowProps } from 'apps/post-sales/src/cab/types';
import CabBookingTrackingHelper from 'apps/post-sales/src/cab/CabBookingTrackingHelper';
import InvoiceListRow from "./invoiceListRow"

const InvoiceList: React.FC<InvoiceListProps> = ({ data, handleBottomOverlay, heading, isCompletedBooking }) => {
  const [itemToShow, setItemToShow] = useState<number>(1);
  const invoiceDetailList =  data?.invoiceDetails?.filter((item) => (item.isVisible));

  const handleShowMore = () => {
    CabBookingTrackingHelper.trackClickEvent(PAGE_NAME.TRIP_DETAILS, CLICK_EVENT.DOCUMENT_VIEW_MORE_CLICK);
    setItemToShow(invoiceDetailList?.length);
  };

  const { fsStyle, ...fonts } = getFont(false);
  const { psTheme } = useTheme();
  const styles = getStyle(fonts, psTheme);
  return (
    <React.Fragment>
      <View style={[styles.headingSection]}>
        <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.spaceBetween]}>
          <Text style={[styles.headingText]}>{heading}</Text>
          <TouchableOpacity style={AtomicCss.marginRight5} onPress={handleBottomOverlay}>
            <Image style={styles.crossIconStyle} source={crossIcon} />
          </TouchableOpacity>
        </View>
       {!isCompletedBooking && <Text
          style={[
            styles.subHeaderText,
            AtomicCss.marginBottom10,
          ]}
        >
          {CabBookingDetailsConstant.invoiceAvailableText}
        </Text>}
      </View>
      <View style={styles.moreInfo}>
        {invoiceDetailList?.map((item: any, index: number) => {
            if (index < itemToShow) {
              return (
                <InvoiceListRow
                  key={index}
                  item={item}
                  index={index}
                  downloadingInvoice={data.downloadingText}
                  downloadFailedText={data.downloadFailed}
                />
              );
            }
          })}

        {itemToShow === 1 && invoiceDetailList?.length > 1 && (
          <TouchableOpacity style={[AtomicCss.marginTop20]} onPress={handleShowMore}>
            <Text style={[AtomicCss.textCenter, styles.viewMoreText]}>
              {CabBookingDetailsConstant.viewMoreText}
            </Text>
          </TouchableOpacity>
        )}
      </View>
    </React.Fragment>
  );
};

export default InvoiceList;
