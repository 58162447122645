import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {View, Text, Image, TouchableOpacity} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import getStyles from '../styles/BusMamiCss';
import {getActionButton, handleBusClickEvent, isNotNullAndEmpty} from '../utils/BusBookingDetailUtil';
import _ from 'lodash';
import {whiteBackIcon} from '../BusImageConstants';
import { label } from '@mmt/post-sales/src/staticData/staticData';
import { getFont } from '../../PostSalesStyles';
import { getUserSelectedLanguage } from '@mmt/legacy-commons/AppState/AppUserConfig';

class TopStatusCard extends React.Component {
    static navigationOptions = {
      header: null
    };

    render() {

      const fonts = getFont(true);
      const styles = getStyles();
      const lang = getUserSelectedLanguage();

      return (
        <LinearGradient start={{x: 1.0, y: 0.0}} end={{x: 0.0, y: 1.0}} colors={this.props.color} style={[styles.topStatusCard]} >
          <View style={[styles.topStatusBar, styles.statusCardOuter]}>
            <TouchableOpacity onPress={this.props.onBackPress}>
              <Image style={[styles.mar10Bt, styles.backarrow]} source={whiteBackIcon} />
            </TouchableOpacity>
            <Image style={[styles.mar3Bt, styles.topStatusConfirmIcon]} source={this.props.iconPath} />
            <TouchableOpacity >
              <Image style={[styles.mar10Bt, styles.shareicon]} />
            </TouchableOpacity>
          </View>
          <View style={styles.statusCardOuter}>
            <Text style={[styles.WhiteText, styles.AlignCenter, styles.font18, styles.blackFont, styles.mar20Bt, styles.bgTransparent]}>{this.props.headText}</Text>
            {
              isNotNullAndEmpty(this.props.subText) &&
              <Text style={[styles.WhiteText, styles.AlignCenter, styles.blackFont, styles.mar20Bt, styles.bgTransparent]}>{this.props.subText}</Text>
            }
            <Text style={[styles.WhiteText, styles.AlignCenter, fonts.lightFontFamily, styles.mar5Bt, styles.bgTransparent]}>{label('bus.statusCard.bookingId')} <Text style={styles.boldFont}>{this.props.response.bookingId}</Text></Text>
            <Text style={[styles.WhiteText, styles.AlignCenter, fonts.lightFontFamily, this.checkServiceNo() ? styles.mar5Bt : styles.mar25Bt, styles.bgTransparent]}>{lang === 'eng' ? label('bus.statusCard.referencePnr') : 'PNR : '} <Text style={styles.boldFont}>{this.props.response.busBookingInfo.busDetail.operatorPnr}</Text></Text>
            {this.checkServiceNo() && <Text style={[styles.WhiteText, styles.AlignCenter, fonts.lightFontFamily, styles.mar25Bt, styles.bgTransparent]}>{label('bus.statusCard.serviceNo')} <Text style={styles.boldFont}>{this.props.response.busBookingInfo.coachadditionaldetails[0].serviceno}</Text></Text>}

            {!_.isEmpty(this.props.card.actionList) &&
            <View style={this.props.card.actionList.length === 1 ? styles.whiteBtnSingle : styles.whiteBtnHalfOuter}>
              { getActionButton(this.props.card, this.props.response, this.handleClick)}
            </View>}

          </View>
        </LinearGradient>
      );
    }

    checkServiceNo = () => (isNotNullAndEmpty(this.props.response.busBookingInfo.coachadditionaldetails) && isNotNullAndEmpty(this.props.response.busBookingInfo.coachadditionaldetails[0].serviceno));

    getPNRStyles = () => {
      const styles = getStyles();
      if (isNotNullAndEmpty(this.props.response.busBookingInfo.busDetail.operatorPnr)) {
        return [0];
      }
      return styles.hideView;
    };

    handleClick = (action) => {
      const { overlayCallback, card, response, pageName } = this.props;
      handleBusClickEvent(card, action, response, pageName, overlayCallback);
    };
}
TopStatusCard.propTypes = {
  response: PropTypes.object.isRequired,
  iconPath: PropTypes.number.isRequired,
  headText: PropTypes.string.isRequired,
  color: PropTypes.array.isRequired,
  subText: PropTypes.string.isRequired,
  card: PropTypes.object.isRequired,
  pageName: PropTypes.string.isRequired,
  mmtAuth: PropTypes.string.isRequired,
  loggerTracker: PropTypes.string.isRequired
};
export default TopStatusCard;
