import React, { useEffect } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
} from 'react-native';
import CardWrapper from '../CardWrapper';
import isEmpty from 'lodash/isEmpty';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import createStyles from './styles';
import CabBookingTrackingHelper from 'apps/post-sales/src/cab/CabBookingTrackingHelper';
import CabBookingDetailsConstant from 'apps/post-sales/src/cab/CabBookingDetailsConstant';

interface KioskDirectionsCardProps {
  response: any;
  handleBottomSheetOverlay: (overlayType: string) => void;
	pageName: string;
}

const KioskDirectionsCard: React.FC<KioskDirectionsCardProps> = ({ response, handleBottomSheetOverlay, pageName }) => {
  if (isEmpty(response?.cabDirectionsData?.cardData)) {
    return null;
  }

  const { desc, directionsLinkText, mainText } = response?.cabDirectionsData?.cardData || {};

  const fonts = getFont();
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);

	useEffect(() => {
    CabBookingTrackingHelper.trackClickEvent(pageName, 'Mob_Cabs_MIMA_PickupDirectionsCard_Shown');
  }, []);

	const handleViewDirections = () => {
		CabBookingTrackingHelper.trackClickEvent(pageName, 'Mob_Cabs_MIMA_OpenInMaps_Click');
		handleBottomSheetOverlay(CabBookingDetailsConstant.KioskDirectionsOverlay);
	};

	return (
		<>
			<View style={styles.container}>
				<View style={styles.topRow}>
					{!!mainText && <Text style={styles.title}>{mainText}</Text>}
					<TouchableOpacity onPress={handleViewDirections}>
						{!!directionsLinkText && <Text style={styles.link}>{directionsLinkText}</Text>}
					</TouchableOpacity>
				</View>
				{!!desc && <Text style={styles.location}>{desc}</Text>}
			</View>
		</>
	);
};

export default CardWrapper(KioskDirectionsCard);
