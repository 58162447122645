import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import {getCommonHeaders, MIMA_BASE_URL} from "../utils/NetworkUtils";

export async function fetchWrapper(url, options, { uniqueId, uniqueIdType, psLob }) {
  let _url = url;
  if (_url.startsWith('/')) {
    _url = `${MIMA_BASE_URL}${_url.substr(1)}`;
  }
  let _options = options;
  const userInfo = await HotelBookingModule.getRequestHeader('', uniqueIdType);
  const headers = await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo, { psLob })
  _options = {
    ..._options,
    headers: {
      ...headers,
      ...((_options && _options.headers) || {})
    }
  };
  return fetch(_url, _options);
}

export const httpGet = (url, options, { uniqueId = null, uniqueIdType = null, psLob } = {}) => fetchWrapper(url, options, {
  uniqueId,
  uniqueIdType,
  psLob
})
  .then(response => response.json());

export const httpPost = (url, body, { uniqueId = null, uniqueIdType = null, psLob } = {}) => {
  let _body = body;

  if (typeof _body !== 'string') {
    _body = JSON.stringify(_body);
  }
  return fetchWrapper(url, { method: 'POST', body: _body }, {
    uniqueId,
    uniqueIdType,
    psLob
  }).then((response) => {
    if (!response.ok) {

    }
    return response.json();
  });
};
