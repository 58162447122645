import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
import  {statusBarHeightForIphone} from '@mmt/legacy-commons/Styles/globalStyles';
import {Platform, StyleSheet} from 'react-native';


const getStyles = (color,fonts) => {
  return  StyleSheet.create({
    headerImage: {
      height: 25,
      width: 25,
    },

    TopStatusCardDetls: {
      padding: 15
    },

    transperantBgrnd: {
      ...Platform.select({
        ios: {
          backgroundColor: 'transparent'
        }
      })
    },

    topStatusCard: {
    paddingBottom: 20,
      zIndex: 10,
      width: '100%',
      borderBottomLeftRadius: 16,
      borderBottomRightRadius: 16,
      position: 'relative',
      ...Platform.select({
        ios: {
          paddingTop: statusBarHeightForIphone - 8,
          marginTop: -(67 + statusBarHeightForIphone)
        },
        android: {
          paddingTop: 10,
          marginTop: -67
        }
      }),
      marginBottom: 10
    },
    copyIcon: {
      width: 9,
      height: 12,
      marginLeft: 6
    },
    copyIconColorGreen: {
      tintColor: color.green
    },
    copyIconColor: {
      tintColor: color.textGrey
    },
    topStatusBar: {
      flexDirection: 'row',
      paddingLeft: 20,
      paddingRight: 20,
      alignItems: 'center',
      justifyContent: 'center'
    },
    statusCardOuter: {
      paddingLeft: 16,
      paddingRight: 16
    },
    backIconPress: {
      position: 'absolute',
      top: 15,
      left: 5,
      padding: 15
    },
    backarrow: {
      width: 16,
      height: 16
    },
    IOSbackarrow: {
      width: 10,
      height: 16
    },
    shareicon: {
      width: 22,
      height: 17
    },
    topStatusIcon: {
      width: 76,
      height: 72,
      marginBottom: 10
    },
    topStatusIconOnBoard: {
      width: 50,
      height: 50,
      marginBottom: 10
    },
    topStatusConfirmIcon: {
      width: 100,
      height: 60
    },
    RecFullBtnArrow: {
      width: 9,
      height: 12
    },
    StatusCardWrapper: {
      paddingLeft: 15,
      paddingRight: 15,
      marginTop: 60,
    },
    StatusHeadingTxt: {
      textAlign: 'center',
      lineHeight: 34,
      marginBottom: 10,
      backgroundColor: 'transparent'
    },
    StatusSubHeadingTxt: {
      color: color.green,
      textAlign: 'center',
      marginBottom: 8,
      top: -6
    },
    SuccessIcon: {
      width: 64,
      height: 38
    },
    baggageIcon: {
      width: 38,
      height: 45
    },
    StatusIcon: {
      width: 70,
      height: 70
    },
    StatusIcon2: {
      width: 42,
      height: 46
    },
    StatusIcon3: {
      width: 61,
      height: 35
    },
    StatusIcon4: {
      width: 62,
      height: 36
    },
    BookingWrapper: {
      flexDirection: 'row',
      alignSelf: 'center',
      marginBottom: 5
    },
    booingDtlsTxt: {
      color: color.green,
      marginRight: 10
    },

    stickyBarWrapper: {
      flexDirection: 'row',
      width: '85%',
      alignItems: 'center'
    },
    stickyTextWrapper: {
      paddingHorizontal: 10,
      paddingVertical: 15
    },
    stickyTxt: {
      color: color.white,
      ...fonts.blackFontFamily,
      backgroundColor: 'transparent',
      marginBottom: 5
    },
    stickyTxt2: {
      color: color.white,
      ...fonts.regularFontFamily,
      backgroundColor: 'transparent'
    },
    StatusBtnWrpper: {
      paddingHorizontal: 30
    },
    MultiBtn: {
      justifyContent: 'space-around',
      flexDirection: 'row'

    },
    whiteBtnSingle: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignSelf: 'center',
      width: 170
    },
    btnWrapper: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 12,
    },
    singleBtnWrapper: {
      alignItems: 'center',
      marginTop: 12
    },
    buttonTxtStyle: {
      fontSize: fonts.fontSize.font12,
      ...fonts.boldFontFamily,
      textAlign: 'center',
      color: color.azure,
    },
    buttonStyle: {
      borderRadius: 10,
      paddingHorizontal: 20,
      paddingVertical: 10,
      backgroundColor: color.white,
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'center',
      ...getPlatformElevation(2),
    },
    icon: {
      width: 16,
      height: 16,
    },
    refundTrackerCardStyle: {
      marginHorizontal: 0,
      marginTop: 10
    },
    buttonView: {
      flex: 1,
      margin: 5
    }
  });
}
export default getStyles;
