import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { View, Text, TouchableOpacity, TextInput } from 'react-native';
import createStyles from './OverlayCss';
import Buttons from '../../../../../components/Button';
import RadioList from '../RadioList';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { CONTINUE, DateChangeConstants } from '../../../../constants';

const RescheduleOverlay = (props) => {
  const {psTheme: theme} = useTheme();
  const fonts = getFont(true);
  const styles = createStyles(theme,fonts);
  const [activeState, setActiveState] = useState(0);
  const [activeStateText, setActiveStateText] = useState(props.RadioItem[0]);
  const [comment, setComment] = useState('');

  const handleRadio = useCallback(
    (index) => {
      setActiveState(index);
      setActiveStateText(props.RadioItem[index]);
    },
    [props.RadioItem],
  );

  const handleComment = useCallback((text) => {
    setComment(text);
  }, []);
  return (
    <View style={styles.paddingBottom25}>
      <Text style={styles.contentTitle}>{DateChangeConstants.HEADING_REASON}</Text>
      {props.RadioItem.map((item, index) => (
        <RadioList item={item} index={index} handleRadio={handleRadio} activeState={activeState} />
      ))}
      <View style={styles.textAreaContainer}>
        <TextInput
          style={styles.textArea}
          underlineColorAndroid={theme.color.transparent}
          placeholder={DateChangeConstants.DESC_REASON}
          placeholderTextColor={theme.color.grey}
          numberOfLines={10}
          multiline
          onChangeText={(text) => handleComment(text)}
        />
      </View>
      <View style={styles.contentFooter}>
        {props.showSkipApproval && (
          <TouchableOpacity style={styles.actionBtn} onPress={props.onSkipClicked}>
            <Text style={styles.actionBtnText}>{DateChangeConstants.SKIP_APPROVAL}</Text>
          </TouchableOpacity>
        )}
        {!props.showSkipApproval && <TouchableOpacity />}
        <TouchableOpacity style={styles.actionBtn}>
          <Buttons
            text={CONTINUE}
            type="Rounded"
            isCorporateBooking
            onPressHandler={() => {
              props.onContinueClicked(comment, activeStateText);
            }}
          />
        </TouchableOpacity>
      </View>
    </View>
  );
};

RescheduleOverlay.propTypes = {
  RadioItem: PropTypes.array.isRequired,
  showSkipApproval: PropTypes.bool.isRequired,
  onSkipClicked: PropTypes.func.isRequired,
  onContinueClicked: PropTypes.func.isRequired,
};
export default RescheduleOverlay;
