import React, { useEffect } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import HTMLView from 'react-native-htmlview';
import styles from './addSeatMealStripCardCss';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import FlightBookingTrackingHelper from '../../helper/FlightBookingTrackingHelper';
import { getFont } from '../../../../../PostSalesStyles';

const AddSeatMealStripCard = ({ odcAncillaryPopUpMessage, response }) => {
  const { fsStyle, ...fonts } = getFont(true);
  const htmlStyle = StyleSheet.create({
    strong: {
      color: colors.defaultTextColor,
      fontFamily: fonts.bold,
    },
    b: {
      color: colors.black,
      fontFamily: fonts.bold,
    },
    div: {
      color: colors.defaultTextColor,
    },
  });
  useEffect(() => {
    FlightBookingTrackingHelper.trackClickEvent('Details', 'odc_seatmeal_strip_show', response);
  }, [response]);
  return (
    <View>
      {/* <View style={styles.triangle} /> */}
      <View style={styles.selectMealInfo}>
        <Text style={[styles.infoTitle, fonts.blackFontFamily, fsStyle.font14]}>
          {odcAncillaryPopUpMessage.heading}
        </Text>
        <HTMLView
          style={[styles.infoDesc, fonts.regularFontFamily, fsStyle.font12]}
          value={odcAncillaryPopUpMessage.text}
          stylesheet={htmlStyle}
        />
      </View>
    </View>
  );
};

export default AddSeatMealStripCard;
