import { Platform, StyleSheet, View } from 'react-native';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Modal from '../Modal';
import { format } from 'fecha';
import Card from '@mmt/ui/components/card';
import { showShortToast } from '@mmt/legacy-commons/Common/Components/Toast';
import CabCard from '../../molecules/CabCard';
import LocationInputCard from '../../molecules/LocationInputCard';
import PrimaryButton from '@mmt/ui/components/button/PrimaryButton';
import CityPicker from '../CityPicker';
import { CabCardProps } from '../../../types/globalTypes';
import useCrossSellStore from '../../../hooks/useCrossSellStore';
import { getFareDetails } from '../../../apis';
import { ACTION_UPDATE_FARE_DATA } from '../../../reducers/crossSellReducer';
// @ts-ignore
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import trackEvent from 'apps/crosslob/cabs/omniture/crossSellOmniture';
import { EVENTS } from 'apps/crosslob/cabs/omniture/constants';
import { DEEPLINK_REDIRECTION, ERROR_CODES, EXPERIMENT_KEYS, USER_FACING_STRING } from '../../../constants';
import {
  getPokusConfig,
  getPokusConfigFetchIfNeeded,
} from '@mmt/legacy-commons/Native/AbConfig/AbConfigModule';
import { PokusLobs } from '@mmt/legacy-commons/Native/AbConfig/AbConfigKeyMappings';
import { openListingUrl, PlatformConfig } from 'apps/crosslob/cabs/utils';

interface Props {
  cabData: CabCardProps | null;
  checkinDate: Date;
  checkoutDate: Date;
  bookingDate: Date;
}

const EditBookingDetails = ({ cabData, checkinDate, checkoutDate, bookingDate }: Props) => {
  const [showCityPicker, setShowCityPicker] = useState<boolean>(false);
  const [crossSellState, crossSellDispatch] = useCrossSellStore();
  const [showButtonLoader, setShowButtonLoader] = useState(false);
  const isSourceInputClicked = useRef(false);

  const { sourceLocation, destinationLocation, fareData } = crossSellState;



  const toggleCityPicker = useCallback(
    (isSourceClicked = false) => {
      isSourceInputClicked.current = isSourceClicked;
      trackEvent(
        isSourceClicked
          ? EVENTS.CROSS_SELL.PICKUP_ADDRESS_CLICK
          : EVENTS.CROSS_SELL.DROP_ADDRESS_CLICK,
      );
      setShowCityPicker(!showCityPicker);
    },
    [showCityPicker],
  );

  const closeCityPicker = () => {
    setShowCityPicker(false);
  };

  const validateInput = () => {
    if (!sourceLocation) {
      trackEvent(EVENTS.CROSS_SELL.PICKUP_ADDRESS_EMPTY_ERROR);
      showShortToast('Please enter Pickup Address');
      return false;
    }
    if (!destinationLocation) {
      if (fareData?.totalFareLabel) {
        trackEvent(EVENTS.CROSS_SELL.DESTINATION_ADDRESS_EMPTY_ERROR + '_fare_showing');
      } else {
        trackEvent(EVENTS.CROSS_SELL.DESTINATION_ADDRESS_EMPTY_ERROR);
      }
      showShortToast('Please enter Drop Address');
      return false;
    }
    if (sourceLocation?.place_id === destinationLocation?.place_id) {
      showShortToast('Pickup and Drop locations should not be the same');
      return false;
    }
    if (
      (sourceLocation?.address?.toLowerCase()?.indexOf('airport') > -1 ||
        sourceLocation?.mainText?.toLowerCase()?.indexOf('airport') > -1) &&
      destinationLocation?.is_city
    ) {
      showShortToast('Drop address should be of a building, street or locality');
      return false;
    }
    return true;
  };

  const onProceedClicked = async () => {
    if (!validateInput()) {
      return false;
    }
    const isSearchExperiment = getPokusConfig(PokusLobs.CAB, EXPERIMENT_KEYS.HOTEL_MY_TRIPS, false);
    if (isSearchExperiment) {
      openListingUrl(sourceLocation, destinationLocation, 'Hotel_My_Trips_Search');
      return;
    }
    if (fareData) {
      trackEvent(EVENTS.CROSS_SELL.REDIRECTION_URL_CLICKED);
      GenericModule.openDeepLink(fareData.deeplink);
    } else {
      trackEvent(EVENTS.CROSS_SELL.CHECK_FARE_CLICK);
      setShowButtonLoader(true);
      const reqBody = {
        type: 'hotelRequest',
        source_location: sourceLocation,
        destination_location: destinationLocation,
        checkout_date: format(checkoutDate, 'DD-MM-YYYY HH:mm'), //"DD-MM-YYYY HH:mm",
        checkin_date: format(checkinDate, 'DD-MM-YYYY HH:mm'),
        booking_date: format(bookingDate, 'DD-MM-YYYY HH:mm'),
        book_mode: PlatformConfig.bookMode,
      };
      const fareDetails = await getFareDetails(reqBody);
      const errObj = fareDetails?.errors?.error_list?.[0];
      const successResponse = fareDetails?.response;
      if (fareDetails.status === 'SUCCESS' && fareDetails?.response) {
        if (successResponse?.deeplink?.indexOf('tripType=OW') > -1) {
          trackEvent(EVENTS.CROSS_SELL.TRIP_TYPE_OW_SEARCHED);
        } else if (successResponse?.deeplink?.indexOf('tripType=AT') > -1) {
          trackEvent(EVENTS.CROSS_SELL.TRIP_TYPE_AT_SEARCHED);
        }
        /* This condition is to check if search is for intracity */
        if (errObj?.code === ERROR_CODES.INTRACITY_UNAVAILABILITY_ERROR_CODE) {
          trackEvent(EVENTS.CROSS_SELL.TRIP_TYPE_INTRACITY_SEARCHED);
          const isHRActive = await getPokusConfigFetchIfNeeded(
            PokusLobs.CAB,
            EXPERIMENT_KEYS.HOURLY_RENTAL_VISIBLE,
            false,
          );
          if (successResponse?.hrAvailable && isHRActive) {
            if (successResponse?.deeplink) {
              GenericModule.openDeepLink(successResponse.deeplink);
            } else {
              GenericModule.openDeepLink(DEEPLINK_REDIRECTION.HOURLY_RENTAL_LINK);
            }
          } else {
            showShortToast(
              errObj?.message || USER_FACING_STRING.CROSS_SELL.INVALID_SEARCH_DEFAULT_MSG,
            );
          }
          setShowButtonLoader(false);
          return;
        }
        /* Ends Here */
        crossSellDispatch({
          type: ACTION_UPDATE_FARE_DATA,
          payload: fareDetails.response,
        });
      } else {
        trackEvent(EVENTS.CROSS_SELL.NO_CABS_FOUND_ERROR);
        const errorMessage = errObj?.message || 'Something went wrong!';
        crossSellDispatch({
          type: ACTION_UPDATE_FARE_DATA,
          payload: null,
        });
        showShortToast(errorMessage);
      }
      setShowButtonLoader(false);
    }
  };

  if (!cabData) {
    return null;
  }

  const shouldRenderModal = Platform.OS === 'web' ? showCityPicker : true;

  const getButtonLabel = () => {
    const isSearchExperiment = getPokusConfig(PokusLobs.CAB, EXPERIMENT_KEYS.HOTEL_MY_TRIPS, false);
    if (isSearchExperiment) {
      return 'SEARCH';
    }
    return fareData?.totalFareLabel || 'CHECK FARE';
  };

  return (
    <Card style={styles.container}>
      <CabCard data={cabData} />
      <LocationInputCard onClick={toggleCityPicker} />
      <PrimaryButton
        text={getButtonLabel()}
        onPress={onProceedClicked}
        // eslint-disable-next-line react-native/no-inline-styles
        containerStyle={{ marginVertical: 0, marginHorizontal: 0, borderRadius:4}}
        showLoader={showButtonLoader}
      />
      {shouldRenderModal && (
        <Modal
          animationType="slide"
          transparent={true}
          visible={showCityPicker}
          onRequestClose={closeCityPicker}
        >
          <View style={{ flex: 1 }}>
            <CityPicker
              toggleCityPicker={closeCityPicker}
              initialCityPickerData={{
                sourceLocation,
                destinationLocation,
                isSourceClicked: isSourceInputClicked.current,
              }}
            />
          </View>
        </Modal>
      )}
    </Card>
  );
};

export default EditBookingDetails;

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    elevation: 4,
    zIndex: 4,
    paddingVertical: 15,
    paddingHorizontal: 10,
    borderRadius: 16,
  },
});
