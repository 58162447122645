import React from 'react';
import {View, Text, TouchableOpacity} from 'react-native';
import PropTypes from 'prop-types';
import styles from './ErrorOverlayCss';


export default class ErrorOverlay extends React.Component {
  render() {
    return (
      <View>
        <Text style={styles.contentTitle}>Online date change not supported</Text>
        <Text style={styles.contentDesc}>{this.props.listingResponse.responseText}</Text>
        <View style={styles.contentFooter}>
          <TouchableOpacity style={styles.actionBtn} onPress={() => this.props.CloseOverlay()}>
            <Text style={styles.actionBtnText}>GO BACK</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }
}

ErrorOverlay.propTypes = {
  CloseOverlay: PropTypes.func.isRequired,
  listingResponse: PropTypes.object.isRequired
};

