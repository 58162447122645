import React from 'react';
import { View, Text, StyleSheet, Image } from 'react-native';
import { isNullOrEmpty } from  '../../../../../Common/commonUtil'
import { useTheme } from '../../../../../theme/theme.context';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getFont } from '../../../../../PostSalesStyles';
import {getStyle} from './VirtualInterliningCss'

const VirtualInterlining = (props) => {
  const { layOverDetailsList } = props.data || {};
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme } = useTheme();
  const { color } = psTheme;
  const styles = getStyle(fonts, color);
  return (
    <View style={styles.viWrapper}>
      {layOverDetailsList &&
        layOverDetailsList.map((item) => {
          return (
            <View style={AtomicCss.flexRow}>
            {!isNullOrEmpty(item.url) && <Image style={styles.imageWrap} source={{uri : item.url}}></Image>}
            <View style={styles.detailWrapper}>
              <Text style={[styles.title, AtomicCss.lineHeight18]}>{item.title}</Text>
              <View style={styles.subtitle}>
                {!isNullOrEmpty(item.subtitle) &&
                  item.subtitle.map((sub) => (
                    <Text style={[
                        fsStyle.font12, 
                        fonts.regularFontFamily, 
                        AtomicCss.marginRight5,
                        AtomicCss.lineHeight18,
                    ]}>
                    {sub}
                    </Text>
                  ))}
              </View>
            </View>
            </View>
          );
        })}
    </View>
  );
};

export default VirtualInterlining;
