// @flow
import React from 'react';
import isEmpty  from 'lodash/isEmpty';
import { getBusSummaryData } from './busCardAdapter';
import {
  ActionsContent,
  BusCoachInfoText,
  DescriptionSection,
  JourneyText,
  MainContent,
  NewStatusText,
  RightArrow,
  StaticIcon,
  SummaryContainer,
  NeedHelpStrip
} from '../cardElements';

function BusSummaryCard({ response, msrTicket }) {
  const cardData = getBusSummaryData(response, msrTicket);
  return (
    <SummaryContainer bookingId={response.bookingID}>
      <MainContent onPress={cardData.openDetails}>
        <StaticIcon icon={cardData.iconUrl} iconStyle={{ height: 20, width: 19 }} />
        <DescriptionSection>
          <JourneyText from={cardData.from} to={cardData.to} />
          {!cardData.failedBooking && <BusCoachInfoText text={cardData.busNameAndType} />}
          <NewStatusText status={cardData.status} text={cardData.dateTime} />
        </DescriptionSection>
        <RightArrow />
      </MainContent>
      {!isEmpty(cardData.needHelpData) &&  cardData.needHelpData.map((needHelp)=><NeedHelpStrip {...needHelp}/>)}
      <ActionsContent actions={cardData.actions} />
    </SummaryContainer>
  );
}

export default BusSummaryCard;
