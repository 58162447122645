import React from 'react';
import { Image, Text, View } from 'react-native';
import { getImageIcon } from "../../../../helper";
import { getFont } from '../../../../../../../PostSalesStyles';
import { useTheme } from '../../../../../../../theme/theme.context';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import createStyles from "./styles";

export const GetRefundMessage = ({ attributeList = [], crossSellBookingMessage = '', cancellationReviewPageData}) => {
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);
  const views = [];

  attributeList && attributeList.forEach(({ bgColor, img, subtitle, subtitleColor }) => {
    const backGroundColor = {
      backgroundColor: bgColor || '',
    };
    const imageIcon = getImageIcon(img);
    (subtitle || imageIcon) && views.push(
      <View
        key={subtitle}
        style={[styles.claimStatusInfoWrapper, backGroundColor]}
      >
        {imageIcon && (
          <Image
            style={
              img === 'DOUBLE_SEAT' ? styles.doubleSeatIcon : styles.priceInfoIcon
            }
            source={imageIcon}
          />
        )}
        <Text
          style={[
            styles.attributeText,
            fonts.boldFontFamily,
            { color: subtitleColor },
            fsStyle.font14,
          ]}
        >
          {subtitle}
        </Text>
      </View>,
    );
  });

  crossSellBookingMessage && views.push(
    <View>
      <Text
        style={[
          styles.priceInfoTxt,
          fonts.boldFontFamily,
          styles.claimStatusInfoWrapper,
          { color: colors.red },
          fsStyle.font14,
        ]}
      >
        {cancellationReviewPageData && cancellationReviewPageData.crossSellBookingMsg}
      </Text>
    </View>,
  )

  return views;

}

export default GetRefundMessage;