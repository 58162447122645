import React from 'react';
import PropTypes from 'prop-types';
import { ScrollView, View, StyleSheet, Platform } from 'react-native';
import Actions from '../../../../../navigation/postSalesNavigation';
import BasePage from '../../../../../Common/PostSalesBasePage';
import Header from '../../../../../Common/HeaderCross';
import DateChangeCard from '../DateChangeCard';
import OverlayMessage from '../../../../../Common/OverlayMessage';
import FlightBookingTrackingHelper from '../../helper/FlightBookingTrackingHelper';

class ModificationOptionStandalone extends BasePage {
  static navigationOptions = {
    header: null,
  };

  constructor(props) {
    super(props, 'flightModificationOption');
    this.state = {
      showDateChangeOverlay: false,
    };
  }

  render() {
    return (
      <View style={[styles.container, styles.transparentBgrnd]}>
        <Header title="Modify" handleClose={this.onBackPress} headerShadow headingTxt="font18" />
        <ScrollView>
          <View style={{ paddingBottom: 70, marginTop: 10 }}>
            <DateChangeCard
              pageName="Details_ChangePlan_Modification"
              dateChangeCacheDetail={this.props.response.datechangeCacheDetail}
              dateChangeButtonClick={this.onDateChangeItemClick}
              differentialPenaltyList={this.props.response.differentialPenaltyNodes}
            />
          </View>
        </ScrollView>
        {this.state.showDateChangeOverlay && (
          <OverlayMessage
            popUpOutsideClick={this.dismissDateChangePopup}
            leftBtnClick={this.dismissDateChangePopup}
            rightBtnClick={this.dismissDateChangePopup}
            title="Date Change Not Supported"
            leftBtnTxt="Go Back"
            rightBtnTxt="OKAY"
            content={this.props.response.newDateChangeEnabledMessage}
          />
        )}
      </View>
    );
  }

  onDateChangeItemClick = (action, pageName) => {
    if (action === 'ACT_RES_FLIGHT' && !this.props.response.dateChangeSupported) {
      this.setState({
        showDateChangeOverlay: true,
      });
    } else {
      this.props.dateChangeCardActionClick(action, pageName);
    }
  };

  dismissDateChangePopup = () => {
    this.setState({
      showDateChangeOverlay: false,
    });
  };

  onBackPress = () => {
    Actions.pop();
  };

  componentDidMount() {
    FlightBookingTrackingHelper.trackLoadEvent(
      'Details_ChangePlan_Modification',
      this.props.response,
    );
  }
}

ModificationOptionStandalone.propTypes = {
  response: PropTypes.object.isRequired,
  dateChangeCardActionClick: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#f2f2f2',
    flex: 1,
  },
  transparentBgrnd: {
    ...Platform.select({
      ios: {
        backgroundColor: 'transparent',
      },
    }),
  },
});
export default ModificationOptionStandalone;
