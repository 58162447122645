import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { View, ScrollView, Animated, Easing } from 'react-native';
import { CANCELLATION_REASONS, SPECIAL_CLAIM_STATES } from './constant';
import PassengerSelection from './pages/PassengerSelection';
import getStyle from './SpecialClaimNewCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import Header from '@mmt/legacy-commons/Common/Components/Header/SimpleHeader';
import RefundDetailCard from './pages/PassengerSelection/components/RefundDetailCard';
import BaseButton, { BUTTON_VARIANT } from '../../../Common/BaseButton';
import StepBar from '../../../Common/StepBar';
import {
  getDataFromResponse,
  getDataFromMap,
  getProofUploadedSegmentsCount,
  canellationDoneApi,
} from './data/dataAdapter';
import { Provider } from './SpecialClaimNewContext';
import UploadDocuments from './pages/UploadDocuments';
import SelectRefundPaymentMode from './pages/SelectRefundPaymentMode';
import ConfirmCard from './components/ConfirmCard';
import RefundRequestSubmitted from './components/RefundRequestSubmitted';
import { BOTTOM_OVERLAY_STATES, SPECIAL_CLAIM_ACITONS } from './constant';
import CommonOverlayMessage from '../../../Common/CommonOverlayMessage';
import commonOverlayMessageStyles from '../../../Common/CommonOverlayMessage/OverlayMessageCss';
import RefundDetailsOverlay from './components/RefundDetailsOverlay';
import NeedUploadProofOverlay from './components/NeedUploadProofOverlay';
import GoBackAndUpload from './components/GoBackAndUpload';
import UploadProofToSupport from './components/UploadProofToSupport';
import specialClaimReducer from './data/specialClaimReducer';
import initialState from './data/initialState';
import specialClaimActionTypes from './data/specialClaimActionTypes';
import {
  FLIGHT_CANCELLATION_DONE_URL,
  CLAIM_ANCILLARY_DONE,
} from '../../../utils/NetworkUtils';
import useApi from '../../../Common/useApi';
import { resetToFlightBookingDetail } from '../../FlightBookingUtil';
import FlightBookingTrackingHelper from '../../modules/details/helper/FlightBookingTrackingHelper';
import useHardwareBackpress from '../../../Common/useHardwareBackpress';
import LoadingCard from '../../../Common/LoadingCard';
import { getFont } from '../../../PostSalesStyles';
import AncillarySelection from './pages/AncillarySelection';
import { isEmptyArray, isNullOrEmptyObject } from '../../../Common/commonUtil';
import { getStaticData } from '../../../staticData/staticData';
import { isProofOfAllSegmentsNotUploaded } from './specialClaimUtils';
import { useTheme } from '../../../theme/theme.context';
import { FLIGHT_THROTTLE_OBJECT } from '../details/FlightBookingDetailsConstant';
import InformationCard from 'apps/post-sales/src/Common/InformationCard';

const SpecialClaim = ({ detailsResponse, scCode }) => {
  const { refundRequestText, uploadProofForClaimText } = getStaticData();
  const { fsStyle, ...fonts } = getFont(true);
  const {psTheme} = useTheme();
  const styles = getStyle(psTheme, fonts);
  //set states
  const [screen, setScreen] = useState(SPECIAL_CLAIM_STATES.PASSENGER_SELECTION);
  const [bottomoverlay, setBottomOverlay] = useState();
  const [cancellationProgress, cancellationResponse, cancellationApi] = useApi(
    FLIGHT_CANCELLATION_DONE_URL,
  );
  const [ancClaimProgress, ancClaimResponse, ancClaimApi] = useApi(CLAIM_ANCILLARY_DONE);
  const [cancellationState, dispatchCancellationState] = useReducer(
    specialClaimReducer,
    initialState,
  );

  //set flow state
  useEffect(() => {
    if (passengerSegments && !isEmptyArray(passengerSegments.groupEntries)) {
      dispatchCancellationState({
        type: specialClaimActionTypes.LOAD_PAX,
        data: passengerSegments.groupEntries,
      });
    }
  }, [passengerSegments]);

  //set state for ancillary
  useEffect(() => {
    if (!isEmptyArray(cards)) {
      dispatchCancellationState({
        type: specialClaimActionTypes.LOAD_ANCILLARY,
        data: cards[0],
      });
    }
  }, [cards]);

  //fetch data from api response
  const {
    cards,
    ancClaimSubtitle,
    ancClaimHeader,
    ancillaryDisableUploadProof,
    newSpecialClaimDataCard,
  } = getDataFromResponse(detailsResponse);

  const {
    cardsList = null,
    actionLists = null,
    title = null,
    header = null,
    imgMap = null,
  } = newSpecialClaimDataCard;

  //set frequently used variables
  const isUploadProofRequired =
    (scCode === CANCELLATION_REASONS.ANCILLARY_REFUND && !ancillaryDisableUploadProof) ||
    (cancellationState.isAnySelected && cancellationState.proofSegments.length > 0);

  //set refs
  const overlayPosition = useRef(new Animated.Value(-600)).current;
  const widthAnimation = useRef(
    Array.from({ length: isUploadProofRequired ? 3 : 2 }, (_) => new Animated.Value(36)),
  ).current;
  const fadeAnimation = useRef(Array.from({ length: 3 }, (_) => new Animated.Value(0))).current;
  const leftAnimation = useRef(Array.from({ length: 3 }, (_) => new Animated.Value(40))).current;

  //get data from map
  const {
    passengerSegments,
    viewMoreText,
    okayGotItText,
    knowMoreText,
    uploadText,
    addMoreText,
    retryText,
    refundDetails,
    uploadProofPageData,
    knowMoreUploadOverlayData,
    selectRefundModeText,
    proceedToUploadProofText,
    refundPageData,
    confirmRequest,
    dontHaveComm,
    goToUpload,
    partialRequest,
    docNotUploadedPageOne,
    docNotUploadedPageTwo,
  } = getDataFromMap(cardsList, actionLists);

  //bottomoverlay handeler
  const handleBottomOverlay = (action, data) => {
    switch (action) {
      case SPECIAL_CLAIM_ACITONS.view_more:
        setBottomOverlay({ screen: BOTTOM_OVERLAY_STATES.REFUND_DETAILS });
        FlightBookingTrackingHelper.trackClickEvent(
          FlightBookingTrackingHelper.getPageName(
            'Specialclaim_refund_details_new|personal',
            detailsResponse,
          ),
          'MI_FLT_Sclaim_New_Refund_Details_Ok',
        );
        break;
      case SPECIAL_CLAIM_ACITONS.know_more:
        setBottomOverlay({ screen: BOTTOM_OVERLAY_STATES.KNOW_MORE, data: data });
        FlightBookingTrackingHelper.trackClickEvent(
          FlightBookingTrackingHelper.getPageName(
            'Specialclaim_fullblock_details_new|personal',
            detailsResponse,
          ),
          'MI_FLT_Proof_Details_New_Ok',
        );
        break;
      case SPECIAL_CLAIM_ACITONS.know_more_upload:
        setBottomOverlay({ screen: BOTTOM_OVERLAY_STATES.KNOW_MORE_UPLOAD });
        break;
      case SPECIAL_CLAIM_ACITONS.partial_request:
        setBottomOverlay({ screen: BOTTOM_OVERLAY_STATES.SELECT_REFUND_MODE_UPLOAD_PROOF });
        FlightBookingTrackingHelper.trackClickEvent(
          FlightBookingTrackingHelper.getPageName(
            'Specialclaim_partblock_proof_popup|personal',
            detailsResponse,
          ),
          'MI_FLT_Sclaim_PartBlock_NoProof_Proceed',
        );
        break;
      case SPECIAL_CLAIM_ACITONS.go_back_and_upload:
        setBottomOverlay({ screen: BOTTOM_OVERLAY_STATES.GO_BACK_AND_UPLOAD });
        break;
      default:
        Animated.timing(overlayPosition, {
          toValue: -600,
          easing: Easing.fadeInOut,
          duration: 400,
          delay: 200,
          useNativeDriver: false,
        }).start();
        setTimeout(() => {
          setBottomOverlay(null);
        }, 400);
    }
  };

  //click handlers
  const hitCanellationDone = () => {
    canellationDoneApi(
      true,
      scCode,
      cancellationState,
      detailsResponse,
      ancClaimApi,
      cancellationApi,
    );
    setScreen(SPECIAL_CLAIM_STATES.THANK_YOU);
    FlightBookingTrackingHelper.trackClickEvent(
      FlightBookingTrackingHelper.getPageName(
        'Specialclaim_fullblock_refundmode_new|personal',
        detailsResponse,
      ),
      'MI_FLT_Sclaim_fullblock_New_Submit',
    );
  };

  const onPassengerSelection = () => {
    if (
      (scCode !== CANCELLATION_REASONS.ANCILLARY_REFUND &&
        cancellationState.proofSegments.length == 0) ||
      (scCode === CANCELLATION_REASONS.ANCILLARY_REFUND && ancillaryDisableUploadProof)
    ) {
      setScreen(SPECIAL_CLAIM_STATES.REFUND_MODE);
      Animated.timing(leftAnimation[2], {
        toValue: 0,
        duration: 500,
        delay: 100,
      }).start();
      Animated.timing(fadeAnimation[2], {
        toValue: 1,
        duration: 700,
      }).start();
      FlightBookingTrackingHelper.trackClickEvent(
        FlightBookingTrackingHelper.getPageName('Specialclaimselect_new|personal', detailsResponse),
        'MI_FLT_Sclaim_Select_New_Refund_Mode',
      );
    } else {
      setScreen(SPECIAL_CLAIM_STATES.UPLOAD_DOC);
      Animated.timing(leftAnimation[1], {
        toValue: 0,
        duration: 500,
        delay: 100,
      }).start();
      Animated.timing(fadeAnimation[1], {
        toValue: 1,
        duration: 700,
      }).start();
    }
    const isProofMandatory = isUploadProofRequired ? 'Sclaim_New_Upload_proof_Mandatory' : 'Sclaim_New_Upload_proof_Optional'
    FlightBookingTrackingHelper.trackClickEvent(
      FlightBookingTrackingHelper.getPageName('Specialclaimselect_new|personal', detailsResponse),
      'MI_FLT_Sclaim_New_FullBlock_Proceed_to_Upload_proof',
      undefined, undefined, undefined,
      isProofMandatory
    );
  };

  const onBackPress = useCallback(() => {
    if (bottomoverlay) {
      handleBottomOverlay('');
      return;
    }
    if (
      screen === SPECIAL_CLAIM_STATES.THANK_YOU ||
      screen === SPECIAL_CLAIM_STATES.PASSENGER_SELECTION
    ) {
      resetToFlightBookingDetail(detailsResponse.bookingId);
    }
    if (
      screen === SPECIAL_CLAIM_STATES.UPLOAD_DOC ||
      (screen === SPECIAL_CLAIM_STATES.REFUND_MODE && !isUploadProofRequired)
    ) {
      setScreen(SPECIAL_CLAIM_STATES.PASSENGER_SELECTION);
      leftAnimation[1].setValue(40);
      fadeAnimation[1].setValue(0);
      leftAnimation[2].setValue(40);
      fadeAnimation[2].setValue(0);
    }
    if (screen === SPECIAL_CLAIM_STATES.REFUND_MODE && isUploadProofRequired) {
      setScreen(SPECIAL_CLAIM_STATES.UPLOAD_DOC);
      leftAnimation[2].setValue(40);
      fadeAnimation[2].setValue(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screen, leftAnimation, fadeAnimation, setScreen, bottomoverlay]);

  useHardwareBackpress().addListener(onBackPress, true);

  const onUploadProof = async (submitPartial = false) => {
    if (
      !cancellationState.isAnyNonProofSegment &&
      cancellationState.proofSegments.length > 0 &&
      isProofOfAllSegmentsNotUploaded(cancellationState)
    ) {
      handleBottomOverlay(SPECIAL_CLAIM_ACITONS.go_back_and_upload);
      return;
    } else if (
      !submitPartial &&
      cancellationState.isAnyNonProofSegment &&
      cancellationState.proofSegments.length > 0 &&
      isProofOfAllSegmentsNotUploaded(cancellationState)
    ) {
      handleBottomOverlay(SPECIAL_CLAIM_ACITONS.partial_request);
      return;
    }
    handleBottomOverlay('');
    setScreen(SPECIAL_CLAIM_STATES.REFUND_MODE);
    Animated.timing(leftAnimation[2], {
      toValue: 0,
      duration: 500,
      delay: 100,
    }).start();
    Animated.timing(fadeAnimation[2], {
      toValue: 1,
      duration: 700,
    }).start();

    FlightBookingTrackingHelper.trackClickEvent(
      FlightBookingTrackingHelper.getPageName(
        'Specialclaim_fullblock_upload_new|personal',
        detailsResponse,
      ),
      'MI_FLT_Sclaim_FullBlock_Select_New_Refund_Mode',
      undefined, undefined, undefined,
      'Sclaim_new_Proof_upload_success'
    );
  };

  //animation handler
  useEffect(() => {
    if (bottomoverlay) {
      Animated.timing(overlayPosition, {
        toValue: 0,
        easing: Easing.easeInOut,
        duration: 400,
        delay: 200,
        useNativeDriver: false,
      }).start();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bottomoverlay]);

  //screen loader
  const loadScreen = () => {
    switch (screen) {
      case SPECIAL_CLAIM_STATES.LOADING:
        return (
          <View style={[AtomicCss.whiteCard, AtomicCss.flex1, styles.pageWrapper]}>
            <LoadingCard rows={4} />
          </View>
        );
      case SPECIAL_CLAIM_STATES.PASSENGER_SELECTION:
        if (!passengerSegments && !cards) {
          return null;
        }
        return (
          <React.Fragment>
            <View style={[AtomicCss.whiteCard, AtomicCss.flex1, styles.pageWrapper]}>
              <Animated.View style={[styles.selectModeWrapper]}>
                <View style={styles.stepBarWrapper}>
                  <StepBar
                    isActive={0}
                    count={isUploadProofRequired ? 3 : 2}
                    widthAnimation={widthAnimation}
                  />
                </View>
                {scCode === CANCELLATION_REASONS.ANCILLARY_REFUND ? (
                  <AncillarySelection
                    data={cards}
                    header={ancClaimHeader}
                    subtitle={ancClaimSubtitle}
                    selectedAnc={cancellationState.ancillaryCancel}
                    dispatchCancellationState={dispatchCancellationState}
                  />
                ) : (
                  <PassengerSelection
                    title={passengerSegments.title}
                    subtitle={passengerSegments.subtitle}
                    flightPaxDetails={cancellationState.paxDetails}
                    knowMoreText={knowMoreText}
                    handleBottomOverlay={handleBottomOverlay}
                    dispatchCancellationState={dispatchCancellationState}
                  />
                )}
              </Animated.View>
            </View>
            <Animated.View style={[styles.selectModeWrapper]}>
              {passengerSegments.footerText && (
                <RefundDetailCard
                  text={passengerSegments.footerText}
                  subtext={passengerSegments.footerSubText}
                  viewMoreText={viewMoreText}
                  handleBottomOverlay={handleBottomOverlay}
                />
              )}
            </Animated.View>
          </React.Fragment>
        );
      case SPECIAL_CLAIM_STATES.UPLOAD_DOC:
        return (
          <View
            style={[
              AtomicCss.whiteCard,
              AtomicCss.marginHorizontal12,
              AtomicCss.flex1,
              styles.pageWrapper,
            ]}
          >
            <Animated.View
              style={[styles.uploadDocWrapper, { left: leftAnimation[1] }]}
              opacity={fadeAnimation[1]}
              key="upload_doc"
            >
              <View style={styles.stepBarWrapper}>
                <StepBar
                  isActive={1}
                  count={isUploadProofRequired ? 3 : 2}
                  widthAnimation={widthAnimation}
                />
              </View>
              <UploadDocuments
                handleBottomOverlay={handleBottomOverlay}
                uploadProofPageData={uploadProofPageData}
                knowMoreText={knowMoreText}
                uploadText={uploadText}
                paxDetails={cancellationState.paxDetails}
                documentList={cancellationState.documentList}
                dispatchCancellationState={dispatchCancellationState}
                addMoreText={addMoreText}
                retryText={retryText}
                bookingId={detailsResponse.bookingId}
                ancUploadData={cancellationState.ancillaryCancel}
              />
            </Animated.View>
          </View>
        );
      case SPECIAL_CLAIM_STATES.REFUND_MODE:
        return (
          <React.Fragment>
            <View
              style={[
                AtomicCss.whiteCard,
                AtomicCss.marginHorizontal12,
                AtomicCss.flex1,
                styles.pageWrapper,
              ]}
            >
              <Animated.View
                style={[styles.uploadDocWrapper, { left: leftAnimation[2] }]}
                opacity={fadeAnimation[2]}
                key="refund_mode"
              >
                <View style={styles.stepBarWrapper}>
                  <StepBar
                    isActive={isUploadProofRequired ? 2 : 1}
                    count={isUploadProofRequired ? 3 : 2}
                    widthAnimation={widthAnimation}
                  />
                </View>
                <SelectRefundPaymentMode
                  data={refundPageData}
                  refundMode={cancellationState.refundMode}
                  dispatchCancellationState={dispatchCancellationState}
                />
              </Animated.View>
            </View>
            <Animated.View style={[styles.uploadDocWrapper, {}]}>
              <ConfirmCard
                text={refundPageData.footerText}
                subText={refundPageData.footerSubText}
                isConfirmed={cancellationState.isConfirmed}
                dispatchCancellationState={dispatchCancellationState}
              />
            </Animated.View>
          </React.Fragment>
        );
      case SPECIAL_CLAIM_STATES.THANK_YOU:
        return (
          <RefundRequestSubmitted
            cancellationProgress={cancellationProgress || ancClaimProgress}
            data={cancellationResponse || ancClaimResponse}
            onBackPress={onBackPress}
            handleBottomOverlay={handleBottomOverlay}
          />
        );
    }
  };

  //render function
  return (
    <View style={[styles.contentWrapper, AtomicCss.flex1]}>
      <Header
        title={title ? title : refundRequestText}
        subtitle={{ text: header ? header : '' }}
        textFont={fonts.bold}
        elevation={2}
        textSize={18}
        iconPress={onBackPress}
      />
      {cancellationResponse && cancellationResponse.cgNudgeText && (
        <InformationCard
          description={cancellationResponse.cgNudgeText}
          cardBackgroundColor={psTheme.color.creamWhite}
          textColor={psTheme.color.lightYello}
        />
      )}
      <ScrollView>
        <View style={[AtomicCss.makeRelative, styles.cardWrapper]}>
          <Provider value={{ airlineUrlMap: imgMap }}>{loadScreen()}</Provider>
        </View>
      </ScrollView>
      <View style={[styles.buttonBottomWrapper]}>
        {screen === SPECIAL_CLAIM_STATES.PASSENGER_SELECTION && (
          <BaseButton
            {...FLIGHT_THROTTLE_OBJECT}
            clickHandler={onPassengerSelection}
            variant={BUTTON_VARIANT.PRIMARY}
            disabled={
              (!cancellationState.isAnySelected || cancellationState.areOnlyChildPaxSelected) &&
              isNullOrEmptyObject(cancellationState.ancillaryCancel)
            }
            text={
              (scCode === CANCELLATION_REASONS.ANCILLARY_REFUND && ancillaryDisableUploadProof) ||
              (scCode !== CANCELLATION_REASONS.ANCILLARY_REFUND &&
                cancellationState.proofSegments.length == 0)
                ? selectRefundModeText
                : proceedToUploadProofText
            }
            textStyle={fsStyle.font16}
            shadow
          />
        )}
        {screen === SPECIAL_CLAIM_STATES.UPLOAD_DOC && (
          <BaseButton
            {...FLIGHT_THROTTLE_OBJECT}
            clickHandler={onUploadProof}
            variant={BUTTON_VARIANT.PRIMARY}
            disabled={
              !cancellationState.isAnyNonProofSegment &&
              getProofUploadedSegmentsCount(cancellationState, true) == 0
            }
            text={selectRefundModeText}
            textStyle={fsStyle.font16}
          />
        )}
        {screen === SPECIAL_CLAIM_STATES.REFUND_MODE && (
          <BaseButton
            {...FLIGHT_THROTTLE_OBJECT}
            clickHandler={hitCanellationDone}
            disabled={!cancellationState.isConfirmed || !cancellationState.refundMode}
            variant={BUTTON_VARIANT.PRIMARY}
            textStyle={fsStyle.font16}
            text={confirmRequest}
            shadow
          />
        )}
      </View>
      {!!bottomoverlay && bottomoverlay.screen === BOTTOM_OVERLAY_STATES.REFUND_DETAILS && (
        <CommonOverlayMessage
          handleBottomOverlay={handleBottomOverlay}
          isBorderRadius={true}
          overlayContentStyle={[commonOverlayMessageStyles.overlayContent, { paddingTop: 10 }]}
          content={
            <Animated.View style={{ bottom: overlayPosition }}>
              <RefundDetailsOverlay
                refundDetails={refundDetails}
                okayGotItText={okayGotItText}
                handleBottomOverlay={handleBottomOverlay}
              />
            </Animated.View>
          }
        />
      )}
      {!!bottomoverlay && bottomoverlay.screen === BOTTOM_OVERLAY_STATES.KNOW_MORE && (
        <CommonOverlayMessage
          handleBottomOverlay={handleBottomOverlay}
          isBorderRadius={true}
          overlayContentStyle={[commonOverlayMessageStyles.overlayContent, { paddingTop: 10 }]}
          content={
            <Animated.View style={{ bottom: overlayPosition }}>
              <NeedUploadProofOverlay
                handleBottomOverlay={handleBottomOverlay}
                data={bottomoverlay.data}
                okayGotItText={okayGotItText}
              />
            </Animated.View>
          }
        />
      )}
      {!!bottomoverlay && bottomoverlay.screen === BOTTOM_OVERLAY_STATES.KNOW_MORE_UPLOAD && (
        <CommonOverlayMessage
          handleBottomOverlay={handleBottomOverlay}
          isBorderRadius={true}
          overlayContentStyle={[commonOverlayMessageStyles.overlayContent, { paddingTop: 10 }]}
          content={
            <Animated.View style={{ bottom: overlayPosition }}>
              <NeedUploadProofOverlay
                handleBottomOverlay={handleBottomOverlay}
                data={knowMoreUploadOverlayData}
                segments={uploadProofPageData.groupEntries}
                reqSegments={cancellationState.proofSegments}
                okayGotItText={okayGotItText}
              />
            </Animated.View>
          }
        />
      )}
      {!!bottomoverlay &&
        bottomoverlay.screen === BOTTOM_OVERLAY_STATES.SELECT_REFUND_MODE_UPLOAD_PROOF && (
          <CommonOverlayMessage
            handleBottomOverlay={handleBottomOverlay}
            isBorderRadius={true}
            overlayContentStyle={[commonOverlayMessageStyles.overlayContent, { paddingTop: 10 }]}
            content={
              <Animated.View style={{ bottom: overlayPosition }}>
                <UploadProofToSupport
                  handleBottomOverlay={handleBottomOverlay}
                  docNotUploadedPageOne={docNotUploadedPageOne}
                  reqSegments={cancellationState.proofSegments}
                  segments={uploadProofPageData.groupEntries}
                  dontHaveComm={dontHaveComm}
                  goToUpload={goToUpload}
                  docNotUploadedPageTwo={docNotUploadedPageTwo}
                  partialRequest={partialRequest}
                  onUploadProof={onUploadProof}
                />
              </Animated.View>
            }
          />
        )}
      {!!bottomoverlay && bottomoverlay.screen === BOTTOM_OVERLAY_STATES.GO_BACK_AND_UPLOAD && (
        <CommonOverlayMessage
          handleBottomOverlay={handleBottomOverlay}
          isBorderRadius={true}
          overlayContentStyle={[commonOverlayMessageStyles.overlayContent, { paddingTop: 10 }]}
          content={
            <Animated.View style={{ bottom: overlayPosition }}>
              <GoBackAndUpload
                handleBottomOverlay={handleBottomOverlay}
                data={knowMoreUploadOverlayData}
                segments={uploadProofPageData.groupEntries}
                reqSegments={cancellationState.proofSegments}
                goToUpload={goToUpload}
                docNotUploadedPageTwo={docNotUploadedPageTwo}
                title={docNotUploadedPageOne.title || uploadProofForClaimText}
              />
            </Animated.View>
          }
        />
      )}
    </View>
  );
};

export default SpecialClaim;
