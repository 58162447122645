import { useEffect, useState } from 'react';
import { getCardDetails } from '../apis';
import { CardDetailSuccessResponse } from '../types/APITypes';

export function useGetCardDetails(hotel_details) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [cardData, setCardData] = useState<CardDetailSuccessResponse | null>(null);
  useEffect(() => {
    const getData = async () => {
      try{
        const data = await getCardDetails(hotel_details);
        setIsLoading(false);
        if (data?.response) {
          setCardData(data.response);
        }
      } catch(e){
        setIsLoading(false);
      }
    };
    getData();
  }, []);
  return {
    isLoading,
    cardData,
  };
}
