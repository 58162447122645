import React from 'react';
import getGiftCardSummaryData from './giftcardCardAdapter';
import {
    ActionsContent,
    DescriptionSection,
    MainContent,
    SummaryContainer,
    CardInfoSection,
    HeaderSection,
    JourneyStartsInfoText,
    WhereToGoTopImage,
    StatusInfoSection,
} from '../cardElements';
import MSRRefundInfoCard from '../MSRRefundInfoCard';
import { GiftCardData, MsrTicket } from '../cardInterfaces';

interface GiftSummaryCardProps {
    response: GiftCardData;
    msrTicket: MsrTicket;
    isUpcomingOrOnTripFirstBooking: boolean,
    topImage: string
}

function GiftCardSummaryCard({ response, msrTicket, isUpcomingOrOnTripFirstBooking, topImage }: GiftSummaryCardProps) {
    const cardData = getGiftCardSummaryData(response, msrTicket);
    const {
        iconUrl,
        giftCardName,
        startDateText,
        expiryDateText,
        purchasedOnText,
        validTillText,
        tripType,
        myRequestsCard,
        actions,
        infoCardStatusText,
        startsIn,
        infoCardStatusCTA,
        lobName,
        bookingId,
        status,
        openDetails,
        handleInfoPress } = cardData || {};

    return (
        <SummaryContainer bookingId={response.bookingID}>
            {topImage && isUpcomingOrOnTripFirstBooking &&
                <WhereToGoTopImage topImageText={startsIn} topImage={topImage} />
            }
            <MainContent onPress={openDetails}>
                <DescriptionSection>
                    <CardInfoSection
                        name={tripType}
                        iconUrl={iconUrl}
                    />
                    <HeaderSection name={giftCardName} />
                    <JourneyStartsInfoText startsOnText={purchasedOnText} startDate={startDateText} endsText={validTillText} endDate={expiryDateText} />
                    {infoCardStatusText && <StatusInfoSection statusText={infoCardStatusText} statusCta={infoCardStatusCTA} handleInfoPress={handleInfoPress} />}
                </DescriptionSection>
            </MainContent>
            <ActionsContent actions={actions} isInfoCardShown={!!infoCardStatusText}/>
            {myRequestsCard && <MSRRefundInfoCard data={myRequestsCard} lobName={lobName} status={status} bookingId={bookingId} />}
        </SummaryContainer>
    );
}

export default GiftCardSummaryCard;