import {fonts} from '../../../Styles/globalStyles';

const styles = {
  btnTypeRound: {
    borderRadius: 4,
    overflow: 'hidden'
  },
  btnTypeRounded: {
    borderRadius: 40,
    overflow: 'hidden'
  },
  blueBtnText: {
    paddingVertical: 16,
    fontSize: 12,
    fontFamily: fonts.bold,
    color: '#ffffff',
    textAlign: 'center'
  }
};

export default styles;

