import React from 'react';
import PropTypes from 'prop-types';
import LinearGradient from 'react-native-linear-gradient';
import { Image, Text, TouchableOpacity, View } from 'react-native';
import getStyles from './HotelMamiCss';
import { imageTransformY } from '../../../utils/PostSaleUtil';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss'
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import HeaderWrapper from 'apps/post-sales/src/Common/HeaderWrapper';
class StickyHeader extends React.Component {
  constructor(props) {
    super(props);
    this.fonts = getFont(true);
    this.styles = getStyles(this.fonts);
  }
  render() {
    return (
      <LinearGradient
        start={{
          x: 1.0,
          y: 0.0,
        }}
        end={{
          x: 0.0,
          y: 1.0,
        }}
        colors={this.props.headerColor}
        style={this.styles.stickyHeader}
      >
            <HeaderWrapper
                titleText={this.props.title} 
                descText= {!!this.props.subTitle && (this.props.subTitle)}
                closeIcon={this.props.iconSrc}
                clickHandler={this.props.onBackPress}
                iconStyle={imageTransformY}
                // wrapperStyle= {}
                titleStyle={[this.props.titleStyle, AtomicCss.alignLeft]}
                descriptionStyle={[this.props.subTitleStyle, AtomicCss.alignLeft]}
                titleNoOfLines={1}
            />

       </LinearGradient> 
    );
  }
}

StickyHeader.propTypes = {
  iconSrc: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  headerColor: PropTypes.array,
  subTitle: PropTypes.string,
  titleStyle: PropTypes.array.isRequired,
  subTitleStyle: PropTypes.array,
  onBackPress: PropTypes.func.isRequired,
  arrowStyle: PropTypes.object
};

StickyHeader.defaultProps = {
  headerColor: [],
  subTitle: undefined,
  subTitleStyle: [],
};

export default StickyHeader;