import useCrossSellStore from '../../../hooks/useCrossSellStore';
import { ACTION_UPDATE_LOCATION } from '../../../reducers/crossSellReducer';
import { LocationProps } from '../../../types/globalTypes';
import { fetchAutoCompleteResults, getPlaceDetails } from '../../../utils';
import React, { useEffect, useReducer } from 'react';
// @ts-ignore
import cityPickerReducer, {
  ACTION_INIT_CITY_PICKER,
  ACTION_UPDATE_AUTOCOMPLETE_RESULTS,
  ACTION_UPDATE_SOURCE_FOCUSED,
  ACTION_UPDATE_SOURCE_TEXT,
  ACTION_UPDATE_DESTINATION_TEXT,
  initialState,
} from '../../../reducers/cityPickerReducer';
import CityPicker from './CityPicker';

export const Store = React.createContext(initialState);
Store.displayName = 'Store';

interface Props {
  toggleCityPicker: () => void;
  initialCityPickerData: {
    sourceLocation: LocationProps;
    destinationLocation: LocationProps;
    isSourceClicked: boolean;
  };
}

const CityPickerContainer = (props: Props) => {
  const { toggleCityPicker } = props;
  const [cityPickerState, cityPickerDispatch] = useReducer(cityPickerReducer, initialState);
  const [crossSellState, crossSellDispatch] = useCrossSellStore();
  const { isSourceFocused, autocompleteResults, sourceText, destinationText } = cityPickerState;

  useEffect(() => {
    if (isSourceFocused) {
      onSourceClick();
    } else {
      onDestinationClick();
    }
  }, [isSourceFocused]);

  useEffect(() => {
    const { initialCityPickerData } = props;
    const { sourceLocation, destinationLocation, isSourceClicked } = initialCityPickerData;
    cityPickerDispatch({
      type: ACTION_INIT_CITY_PICKER,
      payload: {
        sourceText: sourceLocation?.mainText || '',
        destinationText: destinationLocation?.mainText || '',
        isSourceFocused: isSourceClicked,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { sourceLocation, destinationLocation } = crossSellState;

  const onSourceClick = () => {
    cityPickerDispatch({
      type: ACTION_UPDATE_SOURCE_FOCUSED,
      payload: true,
    });
  };

  const onDestinationClick = () => {
    cityPickerDispatch({
      type: ACTION_UPDATE_SOURCE_FOCUSED,
      payload: false,
    });
  };

  const triggerUpdateLocation = (src, dest) => {
    crossSellDispatch({
      type: ACTION_UPDATE_LOCATION,
      payload: {
        sourceLocation: src,
        destinationLocation: dest,
      },
    });
  };

  const onSourceClearClicked = () => {
    changeSourceText('');
    clearAutocompleteResults();
    triggerUpdateLocation(null, destinationLocation);
  };

  const onDestinationClearClicked = () => {
    changeDestinationText('');
    clearAutocompleteResults();
    triggerUpdateLocation(sourceLocation, null);
  };

  const checkAndCloseCityPicker = (
    sourceLocation: LocationProps | null,
    destinationLocation: LocationProps | null,
  ) => {
    if (sourceLocation && destinationLocation) {
      toggleCityPicker();
    }
  };

  const clearAutocompleteResults = () => {
    cityPickerDispatch({
      type: ACTION_UPDATE_AUTOCOMPLETE_RESULTS,
      payload: [],
    });
  };

  const changeSourceText = (val: string) => {
    cityPickerDispatch({
      type: ACTION_UPDATE_SOURCE_TEXT,
      payload: val,
    });
  };

  const changeDestinationText = (val: string) => {
    cityPickerDispatch({
      type: ACTION_UPDATE_DESTINATION_TEXT,
      payload: val,
    });
  };

  const onInputChange = async (val: string) => {
    if (isSourceFocused) {
      changeSourceText(val);
    } else {
      changeDestinationText(val);
    }
    const matchingResults = await fetchAutoCompleteResults(val);
    cityPickerDispatch({
      type: ACTION_UPDATE_AUTOCOMPLETE_RESULTS,
      payload: matchingResults,
    });
  };

  const onSourceResultSelect = async (item) => {
    let displayText = item.mainText;
    if (displayText.length > 27) {
      displayText = displayText.slice(0, 27) + '...';
    }
    let placeDetail = await getPlaceDetails(item.placeId);
    placeDetail = {
      ...placeDetail,
      mainText: displayText,
    };
    changeSourceText(displayText);
    clearAutocompleteResults();
    crossSellDispatch({
      type: ACTION_UPDATE_LOCATION,
      payload: {
        sourceLocation: placeDetail,
        destinationLocation,
      },
    });
    onDestinationClick();
    checkAndCloseCityPicker(placeDetail, destinationLocation);
  };

  const onDestinationResultSelect = async (item) => {
    let displayText = item.mainText;
    if (displayText.length > 27) {
      displayText = displayText.slice(0, 27) + '...';
    }
    let placeDetail = await getPlaceDetails(item.placeId);
    placeDetail = {
      ...placeDetail,
      mainText: displayText,
    };
    changeDestinationText(displayText);
    crossSellDispatch({
      type: ACTION_UPDATE_LOCATION,
      payload: {
        sourceLocation,
        destinationLocation: placeDetail,
      },
    });
    checkAndCloseCityPicker(sourceLocation, placeDetail);
  };

  return (
    <CityPicker
      isSourceFocused={isSourceFocused}
      autocompleteResults={autocompleteResults}
      onSourceClick={onSourceClick}
      onDestinationClick={onDestinationClick}
      onInputChange={onInputChange}
      sourceText={sourceText}
      destinationText={destinationText}
      onSourceResultSelect={onSourceResultSelect}
      onDestinationResultSelect={onDestinationResultSelect}
      onCloseCityPicker={toggleCityPicker}
      onSourceClearClicked={onSourceClearClicked}
      onDestinationClearClicked={onDestinationClearClicked}
      sourceData={props.initialCityPickerData.sourceLocation}
      destData={props.initialCityPickerData.destinationLocation}
    />
  );
};

export default CityPickerContainer;
