import { colors, fonts } from '@mmt/legacy-commons/Styles/globalStyles.js';

const styles = {
  btn: {
    borderColor: colors.azure,
    borderWidth: 1,
    borderRadius: 20,
    height: 38,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row'
  },
  btnText: {
    fontSize: 12,
    color: colors.azure,
    fontFamily: fonts.bold
  },
  btnIcon: {
    height: 19,
    width: 19,
    marginRight: 7
  },
  busStop: {
    position: 'relative',
    paddingLeft: 32,
    minHeight: 90,
    marginHorizontal: 15
  },
  busStopTitle: {
    fontSize: 10,
    color: colors.defaultTextColor,
    fontFamily: fonts.regular
  },
  busStopName: {
    fontSize: 14,
    color: colors.black,
    fontFamily: fonts.bold
  },
  busStopEstimateTime: {
    fontSize: 10,
    color: colors.red,
    fontFamily: fonts.regular,
    marginTop: 3,
    paddingBottom: 15
  },
  busStopEstimateTimeCrossed: {
    color: colors.lightSeaGreenTextColor
  },
  busStopScheduleTime: {
    fontSize: 10,
    color: colors.greyLight,
    fontFamily: fonts.regular
  },
  trackingPointer: {
    height: 18,
    width: 18,
    marginTop: -9,
    position: 'absolute',
    zIndex: 4,
    left: 0,
  },
  trackingPointerIcon: {
    height: 18,
    width: 18
  },
  locationTrackBorder: {
    position: 'absolute',
    zIndex: 1,
    left: 8,
    top: 5,
    height: '100%',
    width: 1,
    backgroundColor: colors.gray3
  },
  greenTrackingLine: {
    position: 'absolute',
    zIndex: 2,
    left: 8,
    top: 5,
    height: 0,
    width: 1,
    backgroundColor: colors.lightSeaGreenTextColor
  },
  locatioListStyleType: {
    height: 9,
    width: 9,
    borderRadius: 5,
    backgroundColor: colors.white,
    borderColor: colors.lightSeaGreenTextColor,
    borderWidth: 1,
    position: 'absolute',
    zIndex: 3,
    top: 2,
    left: 4
  },
  locatioListStyleTypeActive: {
    backgroundColor: colors.lightSeaGreenTextColor,
    borderColor: 'transparent',
    borderWidth: 0,
  },
  moreLink: {
    fontSize: 8,
    color: colors.azure,
    fontFamily: fonts.bold
  }
};

export default styles;
