import { Platform } from 'react-native';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';

let _getPlatformElevation;
if (Platform.OS === 'android') {
  _getPlatformElevation = (elevation) => ({ elevation });
} else {
  _getPlatformElevation = (elevation) => {
    if (elevation === 0) {
      return {
        shadowColor: colors.transparent,
        zIndex: 0,
      };
    }

    return {
      shadowColor: colors.black,
      shadowOpacity: 0.2,
      shadowRadius: elevation / 2,
      shadowOffset: {
        height: elevation,
        width: 0,
      },
      // we need to have zIndex on iOS, otherwise the shadow is under components that
      // are rendered later
    };
  };
}

// eslint-disable-next-line
const getPlatformElevation = _getPlatformElevation;
export default getPlatformElevation;
