import { themeColors } from '../../../PostSalesStyles';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
import { fonts,colors } from '@mmt/legacy-commons/Styles/globalStyles';


const styles = {
    overlayContent:{

    },
    overlayContentheading:{
        fontSize:20,
        color:colors.black,
        fontFamily:fonts.black
    },

    btnWrapper:{
        marginTop:40,
    },
    btnLinkWrapper:{
        paddingVertical:10,
    },
    btnLinkTxt:{
        color: themeColors.linkTextColor,
        fontSize:14,
        fontFamily:fonts.black
    },
    singleLink:{
        alignSelf:'flex-end'
    },
    lastRow:{
        marginBottom:0,
        borderBottomWidth:0,
        borderBottomColor:'transparent'
    },
    submittedWrapper:{
        paddingTop:50,
    },
    pendingWrapper:{
        paddingTop:30,
    },
    successfullyTxtHeading:{
        color: colors.green,
        fontFamily:fonts.black,
        fontSize:26,
        marginBottom:16,
    },
    rowSection:{
        borderLeftWidth:2,
        borderLeftColor: colors.grey8,
        paddingLeft:30,
        paddingBottom:15,
        flexDirection:'row'
    },
    countWrapper:{
        ...getPlatformElevation(2),
        width:32,
        height:32,
        justifyContent:'center',
        alignItems:'center',
        borderWidth:1,
        borderColor: colors.grey6,
        backgroundColor: colors.white,
        borderRadius:6,
        position:'absolute',
        left:-15,
    },
    lastItem:{
        borderLeftWidth:0,
        borderLeftColor:'transparent', 
        paddingBottom:0,
    },
    countTxt:{
        color:colors.greyLight,
        fontFamily:fonts.regular,
        fontSize:14,
    },
}
export default styles;