import React, { useEffect, useState, useRef } from 'react';
import { BackHandler, DeviceEventEmitter, Platform, ScrollView, View } from 'react-native';
import isEmpty from 'lodash/isEmpty';
import useApi from '../../../Common/useApi';
import HelpCard from "./components/HelpCard";
import Header from "./components/StickyHeader";
import WhiteCard from '../../../Common/WhiteCard';
import PaymentCard from './components/PaymentCard';
import { AUTH_FAILED_MESSAGE, LOBNAMES } from '../../../PostSalesConstant';
import TopStatusCard from './components/TopStatusCard';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import RefundTracker from '../../../Common/RefundTracker';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import { getStaticData } from '../../../staticData/staticData';
import TripVoucherUseCard from './components/TripVoucherUseCard';
import RailInfoServices from './components/RailInfoServicesCard';
import ContactDetailsCard from './components/ContactDetailsCard';
import { statusBarHeightForIphone } from '@mmt/legacy-commons/Styles/globalStyles';
import ViewControllerModule from '@mmt/legacy-commons/Native/ViewControllerModule';
import TravellerDetailsCard from './components/TravellerDetailsCard';
import SimpleHeader from '@mmt/legacy-commons/Common/Components/Header/SimpleHeader';
import { TGS_BOOKING_DETAILS_URL } from '../../../utils/NetworkUtils';
import ProgressView from '@mmt/legacy-commons/Common/Components/Progress/ProgressView';
import TripGuaranteeFeatures from './components/TripGuaranteeFeatures';
import ImprotantInstructions from './components/ImportantInstructions';
import LostIDBookingDetailPage from '../../../Common/LostIdDetailPage';
import NoInternetView from '@mmt/legacy-commons/Common/Components/Error/NoInternetView';
import { REFUND_OOT_TRACKING } from '../../../Common/RefundOutOfTATForm/constants';
import TGSBookingConstants, { PAGE_NAME } from "../../Utils/TGSBookingConstants";
import { backPressHandler, isLostBookingData } from '../../../utils/PostSaleUtil';
import { getFont } from '../../../PostSalesStyles';
import { useTheme } from '../../../theme/theme.context';
import createStyles from "./indexCss";
import TGSBookingTrackEventHelper from '../../Utils/TGSBookingTrackEventHelper';
import AuthErrorView from 'apps/post-sales/src/Common/AuthError/AuthErrorView';
import ViewState from 'packages/legacy-commons/Common/constants/ViewState';

const TripGuaranteeBookingDetail = (props) => {
  const previousProps = useRef(props);
  const pageData = props.data && props.data.pageData ? JSON.parse(props.data.pageData) : undefined;
  const isLostBooking = isLostBookingData(pageData);
  const [viewState, setViewState] = useState(isLostBooking ? TGSBookingConstants.SHOW_DETAIL : TGSBookingConstants.LOADING,);
  const [errorMessage, setErrorMessage] = useState('');
  const [refreshLostDetail, setRefreshLostDetail] = useState(null);
  const fonts = getFont();
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);
  let bookingId = null;
  if (props.data != null && props.data.BOOKING_ID != null) {
    bookingId = props.data.BOOKING_ID;
  } else {
    bookingId = props.BOOKING_ID || props.bookingId;
  }
  const [tgsReqInProgress, tgsData, tgsApi] = useApi(TGS_BOOKING_DETAILS_URL + bookingId);

  useEffect(() => {
    return () => {
      DeviceEventEmitter.removeAllListeners(TGSBookingConstants.TGS_STORAGE_PERMISSION);
    }
  })

  useEffect(() => {
    if (!isLostBooking) {
      fetchJSONAsync();
    }
    BackHandler.addEventListener('hardwareBackPress', goBack);
    return () => {
      BackHandler.removeEventListener('hardwareBackPress', goBack);
    }
  }, [])

  useEffect(() => {
    if (previousProps.current.data && previousProps.current.data.refresh && previousProps.current.data.refresh.isRefresh) {
      setViewState(TGSBookingConstants.LOADING);
      fetchJSONAsync();
    }
    else if (props.data && props.data.refresh) {
      if (isLostBooking) {
        setRefreshLostDetail({});
        return;
      }
    }
    previousProps.current = props;
  }, [props.data])

  useEffect(() => {
    if (tgsData) {
      if (tgsData.error) {
        TGSBookingTrackEventHelper.trackErrorEvent(PAGE_NAME.TRIP_DETAILS);
        if (tgsData.error === TGSBookingConstants.NO_INTERNET) {
          setViewState(TGSBookingConstants.NO_INTERNET)
        } else {
          const errorBody = tgsData.error?.json();
          if (errorBody && errorBody?.message && errorBody.message.toUpperCase() === AUTH_FAILED_MESSAGE) {
            setViewState(ViewState.AUTH_ERROR)
          } else {
            setErrorMessage(tgsData.error)
            setViewState(TGSBookingConstants.ERROR)
          }

        }
      } else {
        TGSBookingTrackEventHelper.trackLoadEvent(PAGE_NAME.TRIP_DETAILS, tgsData);
        setViewState(TGSBookingConstants.SHOW_DETAIL);
      }
    }
  }, [tgsData])

  const fetchJSONAsync = () => {
    tgsApi.httpGet({
      bookingId,
      uniqueId: 'TGS_BOOKING_DETAIL',
    });
  }
  const goBack = () => {
    if (Platform.OS === 'ios') {
      ViewControllerModule.popViewController(1);
    } else {
      backPressHandler()
    }
  };

  const getMainPageViews = () => {
    const views = [];
    if (!isEmpty(tgsData.layoutDetail.cardList)) {
      const cards = tgsData.layoutDetail.cardList;
      cards.forEach((card) => {
        addCard(card, views);
      });
    }
    else {
      setViewState(TGSBookingConstants.ERROR)
    }
    return views;
  }
  const getCancellationRequestNo = (cancellationDetailList) => {
    const cancellationRequestNoList = [];
    if (!isEmpty(cancellationDetailList)) {
      cancellationDetailList.forEach((cancellationDetail) => {
        cancellationRequestNoList.push(cancellationDetail.requestNo);
      });
    }
    return cancellationRequestNoList;
  }

  const addCard = (card, views) => {
    switch (card.cardId) {
       case TGSBookingConstants.TOP_STATUS_CARD:
        views.push(<TopStatusCard
          key="TopStatusCard"
          response={tgsData}
          pageName="TripDetails"
          card={card}
        />);
        break;
      case TGSBookingConstants.TRIP_VOUCHER_USAGE_CARD:
        views.push(
       <WhiteCard
          styles={styles}><TripVoucherUseCard card={card} response={tgsData} />
        </WhiteCard>);
        break;
      case TGSBookingConstants.IMP_INSTRUCTIONS_CARD:
        views.push(
          <ImprotantInstructions card={card} response={tgsData} />)
        break;
      case TGSBookingConstants.RAIL_SERVICES_CARD:
        views.push(<WhiteCard
          styles={styles}><RailInfoServices card={card} response={tgsData} /></WhiteCard>);
        break;
      case TGSBookingConstants.TGS_FEATURES_CARD:
        views.push(<WhiteCard
          styles={styles}><TripGuaranteeFeatures card={card} response={tgsData} /></WhiteCard>);
        break;
      case TGSBookingConstants.PAX_DETAILS_CARD:
        views.push(<WhiteCard
          styles={styles}><TravellerDetailsCard card={card} response={tgsData} /></WhiteCard>);
        break;
      case TGSBookingConstants.CONTACT_DETAILS_CARD:
        views.push(<WhiteCard
          styles={styles}><ContactDetailsCard card={card} response={tgsData} /></WhiteCard>);
        break;
      case TGSBookingConstants.PAYMENT_INFO:
        views.push(<PaymentCard card={card} response={tgsData} />);
        break;
      case TGSBookingConstants.CONTACT_US:
        views.push(<HelpCard card={card} response={tgsData} />);
        break;
      default:
        break;
    }
  }


  const renderProgressView = () => {
    const staticData = getStaticData();
    const { loadingYourTripText } = staticData;
    return (<ProgressView message={loadingYourTripText ? loadingYourTripText : ""} />)
  }
  const renderNoNetworkView = () => (
    <NoInternetView
      onRetry={() => {
        setViewState(TGSBookingConstants.LOADING);
        fetchJSONAsync(TGS_BOOKING_DETAILS_URL + bookingId);
      }}
    />);
  const renderRefundCard = (response, lostBooking = false) => {
    const { cancellationDetailList } = response || {};
    const cancellationRequestNoList = getCancellationRequestNo(cancellationDetailList);
    if (!isEmpty(cancellationRequestNoList)) {
      return (<RefundTracker
        key="RefundTracker"
        cancelRequests={cancellationRequestNoList}
        bookingId={bookingId}
        bookingResponse={response}
        lobName={LOBNAMES.TGS}
        isLostBookingId={lostBooking}
        pageName={REFUND_OOT_TRACKING.PAGES.TGS}
      />);
    }
    return null;
  }

  const renderErrorView = () => {
    const staticData = getStaticData();
    const { backText, errorText, tryAgainText } = staticData;
    return (
      <View style={{ flex: 1, marginTop: statusBarHeightForIphone }}>
        <SimpleHeader
          title={backText}
          iconPress={goBack}
        />
        <ErrorView
          showRetry
          buttonLabel={tryAgainText}
          onRetry={() => {
            setViewState(TGSBookingConstants.LOADING);
            fetchJSONAsync(TGS_BOOKING_DETAILS_URL + bookingId);
          }}
          message={!isEmpty(errorMessage) && typeof errorMessage === 'string' ? errorMessage : errorText.somethingWentWrongTryAgainError}
        />
      </View>
    );
  }

  const renderAuthErrorView = () => <AuthErrorView bookingId={bookingId} lobName={LOBNAMES.TGS} onGoBackPress={goBack} />
  
  const renderTripGuaranteeDetail = () => {
    if (isLostBooking) {
      return (
        <LostIDBookingDetailPage
          bookingId={bookingId}
          pageData={pageData}
          lobName={LOBNAMES.TGS}
          refreshLostDetail={refreshLostDetail}
          getRefundCard={({ response }) => renderRefundCard(response, true)}
        />
      );
    }
    let views = getMainPageViews();
    return (
      <>
        {!isEmpty(views) ?
          <ScrollView style={[AtomicCss.greyBg, AtomicCss.marginBottom10]} stickyHeaderIndices={[0]} >
            <Header response={tgsData} goBack={goBack} isCrossIcon={false} />
            {views}
          </ScrollView> : null}
      </>
    )
  }
  return (
    <View style={AtomicCss.flex1}>
      {viewState === TGSBookingConstants.NO_INTERNET && renderNoNetworkView()}
      {(tgsReqInProgress || viewState === TGSBookingConstants.LOADING) && renderProgressView()}
      {viewState === TGSBookingConstants.ERROR && renderErrorView()}
      {viewState === ViewState.AUTH_ERROR && renderAuthErrorView()}
      {viewState === TGSBookingConstants.SHOW_DETAIL && renderTripGuaranteeDetail()}
    </View>
  );
}
export default TripGuaranteeBookingDetail;
