import React from 'react';
import { View, Text } from 'react-native';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import styles from './PayModeBreakupCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { addSpaceWithAmount, appendCurrency } from '../../../../../../utils/PostSaleUtil';
import { getFont } from '../../../../../../PostSalesStyles';

class PayModeBreakup extends React.Component {
  static navigationOptions = {
    header: null,
  };

  render() {
    const { fsStyle, ...fonts } = getFont(true);
    return (
      <View style={[AtomicCss.whiteBg, AtomicCss.marginBottom10]}>
        <View style={[styles.CardContentFull]}>
          <View style={styles.cardHeadingWrapper}>
            <Text style={[styles.cardHeading, fonts.blackFontFamily, fsStyle.font20]}>
              {this.props.heading || 'Payment Breakup'}
            </Text>
          </View>
          <View style={styles.fareBreakupInnerSection}>
            {this.getPayModeViews()}
            {this.props.bnplMsg && (
              <View style={[styles.pahInfoWrapper, { backgroundColor: '#ffedd1' }]}>
                <Text style={[fonts.blackFontFamily, { color: '#cf8100' }, fsStyle.font12]}>
                  {this.props.bnplMsg}
                </Text>
              </View>
            )}
          </View>

          <View style={[styles.totalPrice]}>
            <Text
              style={[styles.totalPricetxt, fsStyle.font16, fonts.blackFontFamily, fsStyle.font16]}
            >
              {this.props.amountPaidText || 'Amount Paid'}
            </Text>
            <Text
              style={[styles.totalPricetxt, fsStyle.font18, fonts.blackFontFamily, fsStyle.font16]}
            >
              {addSpaceWithAmount(
                appendCurrency(this.props.paymentDetails.paymentAmount, this.props.currency),
              )}
            </Text>
          </View>
        </View>
      </View>
    );
  }

  getPayModeViews = () => {
    const { fsStyle } = getFont(true);
    return !isEmpty(this.props.paymentBreakupList) && this.props.paymentBreakupList.map((item) => (
      <View style={[styles.rowSection]}>
        <View style={AtomicCss.flexRow}>
          <View>
            <Text style={[styles.fareBkHeadingTxt, AtomicCss.marginBottom3, fsStyle.font14]}>
              {item.displayText}
            </Text>
          </View>
        </View>
        <View>
          <Text style={[styles.successTxt, AtomicCss.marginBottom3, fsStyle.font14]}>
            {item.priceWithCurrency}
          </Text>
        </View>
      </View>
    ));
  };
}

export default PayModeBreakup;

PayModeBreakup.propTypes = {
  paymentDetails: PropTypes.object.isRequired,
  paymentBreakupList: PropTypes.array.isRequired,
  bnplMsg: PropTypes.string.isRequired,
};
