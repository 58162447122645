import { CorpReviewErrors, DateChangeConstants } from "../../../constants";
import isEmpty from 'lodash/isEmpty'
export const getCorpReviewData = (response) => {
    const obj =  {
        fareBreakupData: response.fareBreakup,
        ...response
    };
    return obj;
};
export const getFareBreakupData = (response) => {
    const { fareDetail, refundMsg } = response;
    const { totalFare, fareBreakupItems, header } = fareDetail || {};
    return {
      fareDetail,
      header,
      refundMsg,
      // totalFareHeader:
      //   totalFare && totalFare.amount < 0
      //     ? DateChangeConstants.YOUR_REFUND
      //     : DateChangeConstants.DATE_CHANGE_CHARGES,
      ...getTotalFare(response),
      fareBreakupItems,
    };
  };
export const getTotalFare = (response) => {
  const { fareDetail } = response;
  const { totalFare } = fareDetail || {};
  return {
    totalFare,
  };
};

export const getBaggageDataInfo = (leg, baggageData) => {
    const baggageInfoLeg = baggageData && baggageData[leg.legId];
    let bagInfo = '';
    const baggageInfos = [];
    baggageInfoLeg &&
      baggageInfoLeg.paxWiseInfo &&
      baggageInfoLeg.paxWiseInfo.forEach((baggageInfo) => {
        bagInfo = `${baggageInfo.paxType}`;
        if (baggageInfo.checkin) {
          bagInfo = `${baggageInfo.checkin} ${DateChangeConstants.CHECK_IN_BAG}`;
        }
        if (bagInfo !== '' && baggageInfo.cabin) {
          bagInfo += ' | ';
        }
        if (baggageInfo.cabin) {
          bagInfo += `${baggageInfo.cabin} ${DateChangeConstants.CABIN_BAG}`;
        }
        baggageInfos.push(bagInfo)
      });
  
    return baggageInfos;
  };

  export const getCorpReviewError = (values) => {
    let error = '';
    if(!isEmpty(values) && values[0] === 'ERROR'){
      error = values[0];
    }
    else if(!isEmpty(values) && !values[0]){
      error = CorpReviewErrors.DATA_NOT_AVAIL;
    }
    else if(!isNil(values[0]) &&
    !isNil(values[0].corpPolicyInfo) &&
    !values[0].corpPolicyInfo.success){
      error = CorpReviewErrors.POLICY_API_FAIL;
    }
    return error;
  }