import React from 'react';
import { ActivityIndicator, Image, Text, View, NativeModules, Platform } from 'react-native';
import Card from '@mmt/legacy-commons/Common/Components/Card';
import { colors, fonts } from '@mmt/legacy-commons/Styles/globalStyles';
import { numAppendedWithRuppeeSymbol } from '@mmt/legacy-commons/Common/utils/NumberUtils';
import TouchableRipple from '@mmt/legacy-commons/Common/Components/TouchableRipple';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import FlightBookingTrackingHelper from '../../helper/FlightBookingTrackingHelper';
import { getStaticData } from '../../../../../staticData/staticData';
import { getFont } from '../../../../../PostSalesStyles';
import { getGdprData, shouldShowGdprBlockerScreen } from '../../../../../utils/PostSaleUtil';
import FlightBookingModule from '@mmt/legacy-commons/Native/FlightBookingModule';
import { getCommonHeaders } from '../../../../../utils/NetworkUtils';
import { LOBNAMES } from '../../../../../PostSalesConstant';

const fetchCrossSellData = async (bookingId) => {
  const requestUrl = 'https://cabs.makemytrip.com/myTrips/flight';
  const userInfo = await FlightBookingModule.getRequestHeader(
    bookingId ? bookingId.flightBookingId : '',
    'CABS_CROSS_SELL',
  );
  const headers = await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo, {
    psLob: LOBNAMES.FLIGHT,
  })
  
  const app_version = Platform.OS !== 'web' ? await GenericModule.getAppVersion() :  null;
  const request = {
    type: 'myTripsRequest',
    flight_booking_id: bookingId ? bookingId.flightBookingId : '',
    app_version,
    book_mode: 'M',
  };
  if (Platform.OS !== 'web') {
    request.book_mode = Platform.OS === 'android' ? 'A' : 'I';
  }
  try {
    let resp = await fetch(
      requestUrl,
      {
        method: 'POST',
        body: JSON.stringify(request),
        headers: headers,
      },
      3000,
    );
    resp = await resp.json();
    if (resp && resp.status === 'SUCCESS') {
      try {
        if (resp.cab_list.length) {
          if (resp.is_international) {
            FlightBookingTrackingHelper.trackCabShownCard(
              'Mob:customer support:Intl Flight : Details_Personal',
            );
          } else {
            FlightBookingTrackingHelper.trackCabShownCard(
              'Mob:customer support:Dom Flight : Details_Personal',
            );
          }
        }
      } catch (error) {
        //  Ignore exception
      }
      return resp;
    }
    return null;
  } catch (e) {
    return null;
  }
};

function CabToAirport({ data, isInternational, isCorporateBooking }) {
  const { fsStyle, ...fonts } = getFont(true);
  const {
    cabsCrossSellFlightsCardText: {
      additionalText: { pickupFromText, startsAtText, dropToText },
    },
    bookNowText,
    gdprText,
  } = getStaticData();
  const type = data.airport_pickup_type === 'FROM' ? pickupFromText : dropToText;
  return (
    <TouchableRipple
      onPress={ async () => {
        const { GenericModule, UserSessionModule} = NativeModules;
        const showGdprBlockerScreen = await shouldShowGdprBlockerScreen();
        try {
          if (showGdprBlockerScreen) {
            const { lobs: { cabs = 'Cabs'} = {} } = gdprText || {} 
            UserSessionModule.openGDPRBlockerScreen(cabs, null, getGdprData(gdprText));
          }
          else if (isInternational) {
            FlightBookingTrackingHelper.trackCabClickedCard(
              'Mob:customer support:Intl Flight : Details_Personal',
            );
          } else {
            FlightBookingTrackingHelper.trackCabClickedCard(
              'Mob:customer support:Dom Flight : Details_Personal',
            );
          }
        } catch (e) {
          //  Ignore exception
        }
        !showGdprBlockerScreen && GenericModule.openDeepLink(data.deeplink);
      }}
    >
      <View
        style={[
          styles.cabContainer,
          isCorporateBooking ? styles.corporateBackground : styles.personalBackground,
        ]}
      >
        <View style={styles.cabRightSection}>
          <Text style={[styles.cabJourneyType, fsStyle.font14]}>{type}</Text>
          <Text style={[styles.cabDestination, fonts.blackFontFamily, fsStyle.font18]}>
            {data.city_name}
          </Text>
          <Text style={[styles.cabIncluded, fsStyle.font14]}>{data.included_kms} kms included</Text>
        </View>
        <View style={styles.cabLeftSection}>
          <View
            style={{
              flex: 1,
              alignItems: 'flex-end',
            }}
          >
            <Text style={[styles.priceStart, fsStyle.font12]}>{startsAtText}</Text>
            <Text style={[styles.price, fonts.blackFontFamily, fsStyle.font22]}>
              {numAppendedWithRuppeeSymbol(data.price)}
            </Text>
          </View>
          <Text style={[styles.cabsCta, fsStyle.font16]}>{bookNowText}</Text>
        </View>
      </View>
    </TouchableRipple>
  );
}

function CabsCrossSellFlights(props) {
  const { fsStyle, ...fonts } = getFont(true);
  const [crossSellData, setCrossSellData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  React.useEffect(() => {
    fetchCrossSellData(props)
      .then((data) => {
        setCrossSellData(data);
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (isLoading) {
    return (
      <View style={{ padding: 16 }}>
        <ActivityIndicator size={24} color="#008b8b" />
      </View>
    );
  }
  if (crossSellData == null) {
    return null;
  }
  return (
    <View
      style={{
        padding: 8,
        backgroundColor: colors.grayBg,
      }}
    >
      <Card style={styles.card} elevation={8} showBorder>
        <View style={{ flexDirection: 'column' }}>
          <View style={styles.mainContainer}>
            <Image
              style={styles.cabsImage}
              source={{
                uri: crossSellData.image,
              }}
            />
            <Text style={[styles.title, fonts.blackFontFamily, fsStyle.font16]}>
              {crossSellData.title}
            </Text>
          </View>
          <View style={styles.smFeatureWrapper}>
            {crossSellData.persuasion_message.map((msg) => (
              <Text style={[styles.smFeatureText, fsStyle.font12]}>
                <Text style={{ marginRight: 5 }}>{'\u25CF'}</Text> {msg}{' '}
              </Text>
            ))}
          </View>
          {crossSellData.cab_list.map((cabData) => (
            <CabToAirport
              data={cabData}
              isCorporateBooking={props.isCorporateBooking}
              isInternational={crossSellData.is_international || false}
            />
          ))}
        </View>
      </Card>
    </View>
  );
}

const styles = {
  card: {
    paddingVertical: 8,
    paddingHorizontal: 16,
    margin: 8,
    borderRadius: 8,
  },
  mainContainer: {
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
  },
  cabsImage: {
    resizeMode: 'contain',
    width: 72,
    height: 48,
    marginRight: 24,
    margin: 8,
  },
  title: {
    marginLeft: 8,
    color: colors.black,
  },
  uspContainer: {
    flexDirection: 'row',
  },
  uspText: {
    paddingRight: 8,
    fontFamily: fonts.regular,
    color: colors.black,
    fontSize: 14,
  },
  couponContainer: {
    flexDirection: 'row',
    marginTop: 8,
    marginBottom: 8,
    alignItems: 'center',
    justifyContent: 'center',
  },
  couponText: {
    backgroundColor: '#A2F8E5',
    borderWidth: 2,
    color: colors.black,
    fontFamily: fonts.bold,
    lineHeight: 32,
    paddingHorizontal: 18,
    borderColor: colors.lightGreen,
    borderRadius: 1,
    borderStyle: 'dotted',
  },
  couponCopyText: {
    marginLeft: 12,
    color: colors.black,
    fontFamily: fonts.light,
  },
  ctaContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'flex-end',
  },

  ctaText: {
    color: colors.azure,
    fontSize: 16,
    fontFamily: fonts.bold,
    marginRight: 16,
  },
  ctaArrow: {
    resizeMode: 'contain',
    width: 12,
    height: 12,
  },
  cabContainer: {
    flexDirection: 'row',
    padding: 12,
    marginVertical: 8,
    borderRadius: 8,
  },
  personalBackground: {
    backgroundColor: colors.lightBlue15,
  },
  corporateBackground: {
    backgroundColor: colors.brightOrange2,
  },
  cabRightSection: {
    flex: 1,
    justifyContent: 'space-around',
  },
  cabLeftSection: {
    alignItems: 'flex-end',
  },
  cabJourneyType: {
    color: colors.black,
    fontFamily: fonts.medium,
  },
  cabDestination: {
    paddingVertical: 6,
    color: colors.black,
  },
  cabIncluded: {
    color: colors.black,
    fontFamily: fonts.regular,
  },
  priceStart: {
    color: colors.black,
    fontFamily: fonts.medium,
  },
  price: {
    color: colors.black,
  },
  cabsCta: {
    color: colors.azure,
    fontFamily: fonts.bold,
  },
  smFeatureWrapper: {
    flexDirection: 'row',
  },
  smFeatureText: {
    fontFamily: fonts.regular,
    color: colors.black,
    lineHeight: 18,
    marginLeft: 8,
  },
};

export default CabsCrossSellFlights;
