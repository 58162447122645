import React from 'react';
import {Text, View, Dimensions, ScrollView, StyleSheet, Animated, Easing, TouchableOpacity} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import {colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import {connect} from 'react-redux';
import {isEmpty} from 'lodash';

const windowHeight = Dimensions.get('screen').height;
const windowWidth = Dimensions.get('screen').width;

class Snackbar extends React.Component {
  constructor(props) {
    super(props);
    this.initialBottom = -windowHeight;
    this.state = {
      snackbarPosition: new Animated.Value(this.initialBottom),
      isVisible: true
    };
    this.delay = props.delay || 200;
    this.duration = props.duration || 400;
  }

  componentDidMount() {
    this.showSnackBar(this.bottom, this.duration, this.delay);
  }

  showSnackBar(bottom, duration, delay) {
    this.setState({isVisible: true});
    Animated.timing(this.state.snackbarPosition, {
      toValue: 0,
      easing: Easing.easeInOut,
      duration,
      delay,
      useNativeDriver: false
    }).start();
  }

  hideSnackBar = () => {
    if (this.props.hasAnimateOnClose) {
      this.setState({isVisible: false});
      Animated.timing(this.state.snackbarPosition, {
        toValue: this.initialBottom,
        easing: Easing.easeInOut,
        duration: this.duration,
        delay: this.delay,
        useNativeDriver: false
      }).start();
    } else if (this.props.onRequestClose) {
      this.props.onRequestClose();
    }
  }

  toggleSnackBar = () => {
    if (this.state.isVisible) {
      this.hideSnackBar();
    } else {
      this.showSnackBar();
    }
  }

  scrollViewLayout = (e) => {
    if (e) {
      this.initialBottom = (this.props.minBottom || 0) - e.nativeEvent.layout.height;
    }
  }
  _getDelayInTime = (busLiveData) => {
    const positions = busLiveData.positions;
    for (const pos of positions) {
      if (pos.type === 'PERSONALISED_BP') {
        const estimatedTime = pos.estimatedTime;
        const scheduledTime = pos.scheduleTime;
        const timeStart = new Date(`01/01/2007 ${estimatedTime}`);
        const timeEnd = new Date(`01/01/2007 ${scheduledTime}`);
        const diff = (timeEnd - timeStart) / 60000;
        const minutes = diff % 60;
        const hours = (diff - minutes) / 60;
        let delay = '';
        if (hours > 0 && minutes > 0) {
          delay = `${hours} hours and ${minutes} mins`;
        } else if (minutes > 0) {
          delay = `${minutes}mins`;
        }
        return delay;
      }
    }
    return null;
  }

  isEarly = (timeStatus) => {
    if (timeStatus && timeStatus.toLowerCase().includes('early')) {
      return true;
    }
    return false;
  }

  render() {
    const activeBtnOpacity = 0.7;
    const delayInTime = this._getDelayInTime(this.props.busLiveData);
    const isEarly = this.isEarly(this.props.busLiveData.timeStatus);
    return (
      <View style={styles.overlayContainer}>
        {!this.props.isTransparent ? <TouchableOpacity
          activeBtnOpacity={activeBtnOpacity}
          onPress={this.hideSnackBar}
          style={styles.pageBg}
        /> : null}
        <Animated.View style={[styles.snackbar, {bottom: this.state.snackbarPosition}]}>
          {/*to be done in version 2 when time is in EPOCH format*/}

          {/*{!isEmpty(delayInTime) && <View style={isEarly ? styles.toastGreen : styles.toastRed}>*/}
          {/*  <View style={styles.toastLeftContent}>*/}
          {/*    <LinearGradient*/}
          {/*      colors={['#3b41ff', '#8734f3']}*/}
          {/*      start={{x: 0.0, y: 0.0}}*/}
          {/*      end={{x: 1.0, y: 0.0}}*/}
          {/*      style={styles.remainingTimeGradient}*/}
          {/*    >*/}
          {/*      <Text style={styles.remainingTimeText}>{delayInTime}</Text>*/}
          {/*    </LinearGradient>*/}
          {/*    <Text style={styles.toastLeftContentDesc}>away from boarding point</Text>*/}
          {/*  </View>*/}
          {/*  <Text style={styles.toastStatusText}>{this.props.busLiveData.timeStatus}</Text>*/}
          {/*</View>}*/}
          <View style={styles.scrollView} onLayout={this.scrollViewLayout}>
            <TouchableOpacity style={styles.hideBtn} onPress={this.toggleSnackBar}>
              <View style={styles.hideBtnBar} />
            </TouchableOpacity>
            <ScrollView>
              <View style={{position: 'relative', paddingTop: 2}}>
                <View style={{position: 'relative'}}>
                  {this.props.children}
                </View>
              </View>
            </ScrollView>
          </View>
        </Animated.View>
      </View >
    );
  }
}

const styles = StyleSheet.create({
  overlayContainer: {
    justifyContent: 'flex-end',
    flex: 1,
    position: 'absolute',
    bottom: 0,
    left: 0,
    height: windowHeight,
    width: windowWidth
  },
  pageBg: {
    backgroundColor: 'rgba(0,0,0,.5)',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    zIndex: 3
  },
  snackbar: {
    zIndex: 4,
    position: 'absolute',
    width: '100%',
    maxHeight: windowHeight - 200,
    justifyContent: 'flex-end'
  },
  scrollView: {
    backgroundColor: colors.white,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    overflow: 'hidden'
  },
  hideBtn: {
    alignItems: 'center',
    justifyContent: 'center'
  },
  hideBtnBar: {
    backgroundColor: colors.grey8,
    height: 4,
    width: 70,
    borderRadius: 2,
    overflow: 'hidden',
    alignSelf: 'center',
    marginTop: 15,
    marginBottom: 20
  },
  overlayIcon: {
    height: 16,
    width: 16
  },
  toastRed: {
    backgroundColor: colors.red,
    flexDirection: 'row',
    height: 26,
    alignItems: 'center',
    borderRadius: 15,
    marginHorizontal: 5,
    marginBottom: 10,
    alignSelf: 'center'
  },
  toastGreen: {
    backgroundColor: '#16aea9',
    flexDirection: 'row',
    height: 26,
    alignItems: 'center',
    borderRadius: 15,
    marginHorizontal: 5,
    marginBottom: 10,
    alignSelf: 'center'
  },
  toastLeftContent: {
    backgroundColor: colors.black,
    flexDirection: 'row',
    height: 26,
    borderRadius: 15,
    alignItems: 'center',
    padding: 3,
    paddingRight: 10
  },
  toastRightContent: {
    backgroundColor: '#16aea9',
    flexDirection: 'row',
    height: 26,
    borderRadius: 15,
    alignItems: 'center',
    padding: 3,
    paddingRight: 10
  },
  toastLeftContentDesc: {
    fontSize: 11,
    color: colors.white,
    fontFamily: fonts.regular,
    paddingLeft: 6
  },
  toastStatusText: {
    fontSize: 10,
    color: colors.white,
    fontFamily: fonts.bold,
    paddingHorizontal: 10
  },
  remainingTimeText: {
    fontSize: 12,
    color: colors.white,
    fontFamily: fonts.bold,
    paddingHorizontal: 10
  },
  remainingTimeGradient: {
    height: '100%',
    borderRadius: 15,
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

const mapStateToProps = (state) => {
  const {
    busLiveData,
    busDetailResponse
  } = state.busBookingDetailReducer;
  return {
    busLiveData,
    busDetailResponse
  };
};

export default connect(mapStateToProps, null)(Snackbar);
