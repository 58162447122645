import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import HTMLView from 'react-native-htmlview';
import { isEmpty } from 'lodash';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';
import Actions from '../../../../../navigation/postSalesNavigation';
import { BACK_ARROW_WHITE_ICON } from '../../../../FlightImageConstants';
import { getFont } from '../../../../../PostSalesStyles';

const { fsStyle, ...fonts } = getFont(true);

const htmlStyle = {
  q: {
    /* fix fo Html Fonts Not working without adding blank tag */
  },
  p: {
    color: colors.defaultTextColor,
    fontSize: fonts.fontSize.font14,
    fontFamily: fonts.regular,
  },
  b: {
    color: colors.white,
    fontSize: fonts.fontSize.font14,
    fontFamily: fonts.black,
  },
};

const RefundExpectedCard = ({ header, ruleList, webViewHeaderText }) => (
  <View style={[styles.whiteCard, styles.innerWrapper]}>
    {!isEmpty(header) && <Text style={[styles.headingTxt, fsStyle.font16]}>{header}</Text>}
    {!isEmpty(ruleList) &&
      ruleList.map((rule) => (
        <View style={[AtomicCss.flexRow, AtomicCss.marginBottom10]}>
          <View style={styles.bullet} />
          <HTMLView
            value={rule}
            stylesheet={htmlStyle}
            style={AtomicCss.flex1}
            onLinkPress={(url) =>
              Actions.openPsWebView({
                headerText: webViewHeaderText,
                headerIcon: BACK_ARROW_WHITE_ICON,
                url,
              })
            }
          />
        </View>
      ))}
  </View>
);
const styles = StyleSheet.create({
  whiteCard: {
    ...getPlatformElevation(2),
    backgroundColor: '#fff',
    marginBottom: 2,
  },
  innerWrapper: {
    padding: 16,
  },
  headingTxt: {
    color: colors.black,
    fontFamily: fonts.black,
    marginBottom: 25,
  },
  bullet: {
    width: 8,
    height: 8,
    backgroundColor: '#4a4a4a',
    borderRadius: 10,
    marginRight: 10,
    top: 6,
  },
});

export default RefundExpectedCard;
