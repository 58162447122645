/* eslint-disable object-curly-spacing */
import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, StyleSheet } from 'react-native';
import RadioButton from './RadioButton';
import TouchableRipple from '../TouchableRipple';
import { colors, fonts } from '../../../Styles/globalStyles';
import Label from '../Label/label';

import { getKey, getDisplayText, isSelected, _onPress } from './radio.utils';

export default function RadioGroup(props) {
  const {
    label,
    options = [],
    keyResolver,
    displayTextResolver,
    selected,
    selectedValueResolver,
    onSelect,
    valueResolver,
    individualStyles,
    radioInputCommonStyle = {},
    containerStyle = {},
    disabled,
    optionStyle = {}
  } = props;

  const pointerEvents = disabled ? 'none' : 'auto';
  const radioContainerStyle = disabled ? { opacity: 0.5 } : {};

  return (
    <View>
      {label && <Label label={label} />}
      <View style={[styles.container, radioContainerStyle, containerStyle]} pointerEvents={pointerEvents} testID={props?.id}>
        {options.map((option, index) => {
          const checked = isSelected(option, selected, selectedValueResolver);
          const individualStyle = individualStyles[index] || {};
          const radioInputStyle = [styles.radioInput, radioInputCommonStyle, individualStyle];
          return (
            <View style={radioInputStyle} key={getKey(option, keyResolver)}>
              <TouchableRipple feedbackColor={colors.transparent} onPress={_onPress(option, valueResolver, onSelect)}>
                <View style={{ flexDirection: 'row', alignItems: 'center' }} testID={`${props?.id}_${index+1}`}>
                  <RadioButton checked={checked} />
                  <Text style={[styles.optionText, optionStyle]}>
                    {getDisplayText(option, displayTextResolver)}
                  </Text>
                </View>
              </TouchableRipple>
            </View>
          );
        })}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 20
  },
  radioInput: {
    width: '50%',
    marginBottom: 26
  },
  optionText: {
    fontSize: 14,
    fontFamily: fonts.regular,
    letterSpacing: 0.01,
    color: colors.black,
    marginLeft: 11,
  }
});

RadioGroup.propTypes = {
  label: PropTypes.string, // eslint-disable-line react/require-default-props
  options: PropTypes.array.isRequired,
  individualStyles: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
  keyResolver: PropTypes.func, // eslint-disable-line react/require-default-props
  valueResolver: PropTypes.func, // eslint-disable-line react/require-default-props
  displayTextResolver: PropTypes.func, // eslint-disable-line react/require-default-props
  selected: PropTypes.any, // eslint-disable-line react/require-default-props
  selectedValueResolver: PropTypes.func, // eslint-disable-line react/require-default-props
  containerStyle: PropTypes.object,
  radioInputCommonStyle: PropTypes.object,
  disabled: PropTypes.bool
};

RadioGroup.defaultProps = {
  individualStyles: [],
  containerStyle: {},
  radioInputCommonStyle: {},
  disabled: false
};
