import React from 'react';
import {View, Text, Image, AppState, Platform} from 'react-native';
import styles from './trackBusCss';
import Header from '../Components/header/busTrackingHeader';
import Snackbar from '../Components/snackbar/SnackBar';
import BusTracker from '../Components/busTracker/BusTracker';
import Notification from '../boardingDropInfo/notification';
import {locationAccuracy, busIcon} from "../../BusImageConstants";
import {connect} from 'react-redux';
import {fetchLiveTrackingData} from '../../busBookingDetailAction';
import {isEmpty} from 'lodash';
import ViewControllerModule from '@mmt/legacy-commons/Native/ViewControllerModule';
import {
  getCurrentLocation,
  getDestLatLon, getInitialRegion,
  getOriginLatLon, getPollFreqInMin, getPollFreqInSec, getPolyLineData, getRotationAngle
} from '../../utils/BusBookingDetailUtil';
import MapView, {Marker, Polyline, AnimatedRegion} from 'react-native-maps';
import Actions from '../../../navigation/postSalesNavigation';
import {colors} from '@mmt/legacy-commons/Styles/globalStyles';
import { label } from '@mmt/post-sales/src/staticData/staticData';

class TrackBus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumberList: [],
      polyLineData: [],
      originLatLong: {},
      destLatLong: {},
      lastUpdated: '',
      previousLocation: {},
      currentLocation: {},
      focusArea: {},
      rotationAngle: 0,
      appState: 'active',
      pollingFrequencyInSeconds: 30,
      pollingFrequencyInMinutes: 10,
      isLoading: false,
      gpsQuality: 0
    };
    this.animateMarker = this.animateMarker.bind(this);
    this.appStateListener = null;
  }
  componentDidMount() {
    this.appStateListener = AppState.addEventListener('change', this._handleAppStateChange);
    if (this.state.appState === 'active') {
      // eslint-disable-next-line react/prop-types
      this._trackBusLocations();
    }
    if (!isEmpty(this.props.busLiveData)) {
      const originLatLong = getOriginLatLon(this.props.busLiveData);
      const destLatLong = getDestLatLon(this.props.busLiveData);
      const polyLineData = getPolyLineData(this.props.busLiveData);
      const lastUpdated = this.props.busLiveData.updatedOn;
      const currentLocation = getCurrentLocation(this.props.busLiveData);
      const rotationAngle = getRotationAngle(this.props.busLiveData);
      const focusArea = getInitialRegion(this.props.busLiveData);
      const pollingFrequencyInSeconds = getPollFreqInSec(this.props.busDetailResponse, this.state.pollingFrequencyInSeconds);
      const pollingFrequencyInMinutes = getPollFreqInMin(this.props.busDetailResponse, this.state.pollingFrequencyInMinutes);
      const previousLocation = currentLocation;
      const gpsQuality = this.props.busLiveData.gpsQuality;
      this.setState({
        originLatLong,
        destLatLong,
        polyLineData,
        lastUpdated,
        currentLocation,
        rotationAngle,
        focusArea,
        gpsQuality,
        previousLocation,
        pollingFrequencyInSeconds,
        pollingFrequencyInMinutes
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!isEmpty(nextProps.busLiveData) && this.props.busLiveData !== nextProps.busLiveData) {
      const {busLiveData} = nextProps;
      const originLatLong = getOriginLatLon(busLiveData);
      const destLatLong = getDestLatLon(busLiveData);
      const polyLineData = getPolyLineData(busLiveData);
      const lastUpdated = busLiveData.updatedOn;
      const currentLocation = getCurrentLocation(busLiveData);
      const focusArea = getInitialRegion(busLiveData);
      const rotationAngle = getRotationAngle(busLiveData);
      const pollingFrequencyInSeconds = nextProps.busDetailResponse.busLiveTrackingInfo.pollingFreqInSeconds;
      const pollingFrequencyInMinutes = nextProps.busDetailResponse.busLiveTrackingInfo.pollingDurationInMinutes;
      const gpsQuality = this.props.busLiveData.gpsQuality;
      const previousLocation = this.state.currentLocation;
      this.animateMarker(previousLocation, currentLocation);
      this.setState({
        originLatLong,
        destLatLong,
        polyLineData,
        lastUpdated,
        currentLocation,
        rotationAngle,
        focusArea,
        pollingFrequencyInMinutes,
        pollingFrequencyInSeconds,
        gpsQuality,
        previousLocation
      });
    }
  }

  animateMarker = (previousLocation, currentLocation) => {
    if (isEmpty(previousLocation) || isEmpty(currentLocation)) return;
    const newCoordinate = {latitude: currentLocation.latitude, longitude: currentLocation.longitude};
    const timingConfig = {
      latitude: currentLocation.latitude,
      longitude: currentLocation.longitude,
      duration: 3500
    };
    const coordinateIos = new AnimatedRegion({
      latitude: previousLocation.latitude,
      longitude: previousLocation.longitude,
      latitudeDelta: 0,
      longitudeDelta: 0
    });
    if (Platform.OS === 'android') {
      if (!isEmpty(coordinateIos)) {
        coordinateIos.timing(timingConfig).start();
      }
    } else {
      if (!isEmpty(coordinateIos)) {
        coordinateIos.timing(timingConfig).start();
      }
    }
  }


  _trackBusLocations = () => {
    const countEnd = ((this.state.pollingFrequencyInMinutes * 60) / this.state.pollingFrequencyInSeconds);
    let count = 0;
    const interval = this.state.pollingFrequencyInSeconds * 1000;
    this.timer = setInterval(() => {
      if (count < countEnd) {
        count++;
        this.props.fetchLiveTrackingData(this.props.busDetailResponse, this.props.mmtAuth, this.props.loggingTrackInfo);
      } else {
        clearInterval(this.timer);
      }
    }, interval);
  };

  bearingBetweenLocations = () => {
    if (isEmpty(this.state.previousLocation) && isEmpty(this.state.currentLocation)) { return 0; }
    const latLng1 = this.state.previousLocation;
    const latLng2 = this.state.currentLocation;
    const PI = 3.14159;
    const lat1 = latLng1.latitude * PI / 180;
    const long1 = latLng1.longitude * PI / 180;
    const lat2 = latLng2.latitude * PI / 180;
    const long2 = latLng2.longitude * PI / 180;

    const dLon = (long2 - long1);
    const y = Math.sin(dLon) * Math.cos(lat2);
    const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1)
      * Math.cos(lat2) * Math.cos(dLon);
    let brng = Math.atan2(y, x);
    brng = brng * 180 / PI;
    brng = (brng + 360) % 360;
    return brng;
  }

  componentWillUnmount() {
    this.timer = null;
    if (this.appStateListener) {
      this.appStateListener.remove();
    }
  }

  _handleAppStateChange = (nextAppState) => {
    if (this.state.appState.match(/inactive|background/) && nextAppState === 'active') {
      this.setState({appState: 'active'});
    }
    this.setState({appState: nextAppState});
  };

  _goBack = () => {
    if (Platform.OS === 'ios') {
      ViewControllerModule.popViewController(1);
    } else {
      Actions.pop();
    }
  };
  _onRefresh = () => {
    clearInterval(this.timer);
    this._trackBusLocations();
    let count = 0;
    this.showLoader = setInterval(() => {
      if (count < 3) {
        count++;
        this.setState({
          isLoading: true
        });
      } else {
        this.setState({
          isLoading: false
        });
        clearInterval(this.showLoader);
      }
    }, 100);
  };
  render() {
    return (
      <View style={{flex: 1}}>
        <Header
          navigation={this._goBack}
          title={label('bus.trackDetail.trackBus')}
          subTitle={this.state.lastUpdated}
          onRefresh={this._onRefresh}
          isRefreshVisible={!this.state.isLoading}
          isLoading={this.state.isLoading}
        />
        <Notification theme="yellow" />
        <MapView
          ref={ref => (this.mapView = ref)}
          initialRegion={this.state.focusArea}
          region={this.state.focusArea}
          style={styles.bgImage}
          onMapReady={() => {
            this.mapView.fitToSuppliedMarkers(['MK1', 'MK2'], {
            edgePadding: {
                top: 50,
                right: 50,
                bottom: 50,
                left: 50
            }
          });}}>
          <Marker
            identifier="MK1"
            coordinate={this.state.originLatLong}
            title={label('bus.trackDetail.pickUpPoint')}
            description={label('bus.trackDetail.pickUp')}
          />
          <Marker
            identifier="MK2"
            coordinate={this.state.destLatLong}
            title={label('bus.trackDetail.dropPoint')}
          />
          <Marker.Animated
            image={busIcon}
            anchor={{x: 0.5, y: 0.5}}
            rotation={this.state.rotationAngle}
            coordinate={this.state.currentLocation}
            ref={(marker) => { this.marker = marker; }}
            title={label('bus.trackDetail.currentLocation')}
          />
          <Polyline
            coordinates={this.state.polyLineData}
            strokeColor={colors.black}
            strokeWidth={3}
          />
        </MapView>
        <Snackbar
          isVisible
          isHideBtnVisible
          hideBtnStyle={styles.snackbarTopBorder}
          hasAnimateOnClose
          isTransparent
          minBottom={40}
        >
          <View style={styles.busTrackerWrapper}>
            <BusTracker />
          </View>
          {this.state.gpsQuality < 3 && <View style={styles.locationAccuracyWrapper}>
            <View style={{flex: 1}}>
              <Text style={styles.locationAccuracyTitle}>{label('bus.trackDetail.locationAccLow')}</Text>
              <Text style={styles.locationAccuracyDesc}>{label('bus.trackDetail.slowGps')}</Text>
            </View>
            <Image source={locationAccuracy} style={styles.locationAccuracyIcon} />
          </View>}
        </Snackbar>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    busLiveData,
    busDetailResponse,
    mmtAuth,
    loggingTrackInfo
  } = state.busBookingDetailReducer;
  return {
    busLiveData,
    busDetailResponse,
    mmtAuth,
    loggingTrackInfo
  };
};

const mapDispatchToProps = dispatch => ({
  fetchLiveTrackingData: (busDetailResponse, mmtAuth, loggingTrackInfo) => dispatch(fetchLiveTrackingData(busDetailResponse, mmtAuth, loggingTrackInfo))
});

export default connect(mapStateToProps, mapDispatchToProps)(TrackBus);
