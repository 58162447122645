import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { isEmpty } from 'lodash';
import DocumentDetailCard from './DocumentDetailCard';

const UploadDocuments = ({
  data,
  setAttachmentSectionData,
  bookingId,
  pageName = '',
  formType,
}) => {
  return (
    <View style={styles.cardWrapper}>
      <View style={styles.headingText}>
        <Text
          style={[
            AtomicCss.font20,
            AtomicCss.blackFont,
            AtomicCss.blackText,
            AtomicCss.lineHeight20,
            AtomicCss.flex1,
          ]}
        >
          {data.heading}
        </Text>
      </View>
      {!isEmpty(data.attachmentSectionList) &&
        data.attachmentSectionList.map((attachmentSection, index) => (
          <DocumentDetailCard
            key={index}
            attachmentSectionData={attachmentSection}
            bookingId={bookingId}
            pageName={pageName}
            setAttachmentSectionData={(data) => setAttachmentSectionData(data, index)}
            formType={formType}
          />
        ))}
    </View>
  );
};
const styles = StyleSheet.create({
  cardWrapper: {
    paddingHorizontal: 20,
  },
  headingText: {
    marginBottom: 24,
    flex: 1,
  },

  lineHeight22: {
    lineHeight: 22,
  },
});
export default UploadDocuments;
