import { Platform } from 'react-native';
import { isIPhoneX } from '@mmt/legacy-commons/Styles/globalStyles';

const styles = {
  bottomOverlayWrapper: {
    height: 'auto',
    paddingRight: 16,
    ...Platform.select({
      ios: {
        paddingBottom: isIPhoneX ? 40 : 26,
      },
    }),
  },
  headingText: {
    color: '#000',
    lineHeight: 28,
  },
  imgPlaceholder: {
    width: 24,
    height: 24,
    marginRight: 5,
  },
  payIconStyle: {
    width: 17,
    height: 21,
  },
  phoneIconStyle: {
    width: 24,
    height: 24,
  },
  rcWrapper: {
    borderWidth: 1,
    borderRadius: 4,
    borderColor: '#e7e7e7',
  },
  rcWrapperRow: {
    borderBottomWidth: 1,
    borderBottomColor: '#e7e7e7',
    paddingVertical: 16,
    flexDirection: 'row',
  },
  fltIconStyle: {
    width: 20,
    height: 20,
    marginRight: 8,
  },
  calIconStyle: {
    width: 13,
    height: 14,
  },
  userIconStyle: {
    width: 16,
    height: 12,
  },
  bullet: {
    width: 4,
    height: 4,
    borderRadius: 10,
    marginRight: 10,
    backgroundColor: 'black',
  },
  infoIconStyle: {
    width: 16,
    height: 16,
    marginRight: 10,
    top: 3,
  },
  infoTxt: {
    lineHeight: 22,
    color: '#cf8100',
    flex: 1,
  },
  linkBtn: {
    alignSelf: 'flex-end',
  },
};

export default styles;
