import React from 'react';
import { Image, Text, TouchableOpacity, View } from 'react-native';
import createStyles from './style';
import { getFont } from '../../../../../../../PostSalesStyles';
import { useTheme } from '../../../../../../../theme/theme.context';
import { closeIcon } from '../../../../../../../bus/BusImageConstants';
import isEmpty from 'lodash/isEmpty';

const FareBreakupCard = ({ onDismiss, heading, totalPaidData, fareBreakup, custCurrency }) => {
  const fonts = getFont();
  const { psTheme: theme } = useTheme();
  const styles = createStyles(fonts, theme);
  const { heading: fareBreakupHeading, items = [] } = fareBreakup || {};
  const fareBreakupItems = Object.entries(items).map(([key, value]) => ({
    title: key,
    value: value,
  }));
  return (
    <View style={[styles.cardWrapper]}>
      <View style={styles.header}>
        <TouchableOpacity onPress={onDismiss}>
          <Image source={closeIcon} />
        </TouchableOpacity>
        {!isEmpty(heading) && <Text style={styles.headingText}>{heading}</Text>}
      </View>

      {!isEmpty(totalPaidData) && (
        <View style={styles.subHeader}>
          {!isEmpty(totalPaidData.key) && (
            <Text style={styles.subHeaderKey}>{totalPaidData.key}</Text>
          )}
            <Text style={styles.subHeaderValue}>{custCurrency} {totalPaidData.value}</Text>
        </View>
      )}
      <View style={styles.fareBreakupSection}>
        {!isEmpty(fareBreakupHeading) && (
          <Text style={styles.fareBreakupHeading}>{fareBreakupHeading}</Text>
        )}
        {!isEmpty(fareBreakupItems) &&
          fareBreakupItems.map((item) => (
            <View style={styles.fareBreakupItems}>
              <Text style={styles.fareBreakupKey}>{item.title}</Text>
              <Text style={styles.fareBreakupValue}>{custCurrency} {item.value}</Text>
            </View>
          ))}
      </View>
    </View>
  );
};

export default FareBreakupCard;
