import React from 'react';
import PropTypes from 'prop-types';
import { Text, View } from 'react-native';
import _ from 'lodash';
import createStyles from './TravellerDtlsCss';
import { getFont } from '../../../../../../../PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import { DateChangeConstants } from '../../../../constants';

const TravellerDtls = (props) => {
  const fonts = getFont(true);
  const {psTheme: theme} = useTheme();
  const styles = createStyles(theme,fonts);
  const travellers = _.flatten(Object.values(props.travellers));
  return (
    <View>
      <Text style={[styles.cardHead, fonts.lightFontFamily]}>{DateChangeConstants.TRAVELLER_DETAILS}</Text>
      {travellers.map((item) => (
        <View style={styles.paxDtlsRow}>
          <Text style={[styles.paxName, fonts.boldFontFamily]}>{item.fullName}</Text>
          <Text style={[styles.paxDtls, fonts.regularFontFamily]}>{item.gender}</Text>
        </View>
      ))}
    </View>
  );
};

TravellerDtls.propTypes = {
  travellers: PropTypes.object.isRequired,
};

export default TravellerDtls;
