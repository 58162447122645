import React, { memo } from 'react';
import { Text, View, TouchableOpacity } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { themeColors } from '../../PostSalesStyles';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { useTheme } from '../../theme/theme.context';
import RadioButton from '../RadioButton';
import createStyle from './OptionsListCss';

const OptionListItem = ({ title, subText, value, label, isActive, onChangeHandler, index, isCorporateBooking }) => {
  const{psTheme:theme} = useTheme();
  const styles = createStyle(theme)
  const onPressHandler = () => {
    onChangeHandler(value)
  }
  const schemeBasedColorTag = theme.color.primaryGradient;
  return (
    <TouchableOpacity onPress={onPressHandler} activeOpacity={0.8} key={index}>
      <View style={[styles.itemContainer, [isActive ? styles.selectedOption : '']]}>
        <View style={AtomicCss.flexRow}>
          <RadioButton selected={isActive} onChange={onPressHandler}
          radioOuterStyle={styles.radioButtonContainer} />
          <View style={styles.contentContaienr}>
            <View style={styles.titleContainer}>
              <Text style={styles.titleContent}>{title}</Text>
              {label && <LinearGradient
                start={{ x: 0.0, y: 1.0 }}
                end={{ x: 1.0, y: 0.0 }}
                colors={schemeBasedColorTag}
                style={styles.tag}
              >
                <Text style={styles.tagText}>{label}</Text>
              </LinearGradient>}
            </View>
            <View>
              <Text style={[styles.subText, AtomicCss.font12]}>
                {subText}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </TouchableOpacity>)
}

export default memo(OptionListItem);