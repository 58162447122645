import React, { useState } from 'react';
import { View, Text, Image } from 'react-native';
import styles from '../indexCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { isNullOrEmpty } from '../../commonUtil';
import constants from './constants';
import { addSpaceWithAmount } from '../../../utils/PostSaleUtil';

const RefundBreakup = ({ data, imgType, fonts }) => {
  return (
    <View>
      <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween, AtomicCss.marginTop10]}>
        {!isNullOrEmpty(data.img) && <Image source={{uri: data.img}} style={imgType === constants.imgType.DB ? styles.imgIcon : styles.zeroIconSm} />}
        <Text style={[AtomicCss.font14, fonts.regularFontFamily, AtomicCss.defaultText, AtomicCss.flex1, AtomicCss.paddingRight16]}>{data.desc} {data.extraDesc}</Text>
        <Text style={[AtomicCss.font14, fonts.regularFontFamily, AtomicCss.blackText]}>{data.showSlasher && <Text><Text style={AtomicCss.lineThrought}>{data.slashedValue}</Text><Text>{"  "}</Text></Text>}{addSpaceWithAmount(data.actualValue)}</Text>
      </View>
    </View>
    
  );
};

export default RefundBreakup;