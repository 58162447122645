
const BusCancelRebookConstant = {
  CANCEL_REBOOK_SEARCH :  "cancelRebookSearch",
  PROCEED_CANCELLATION : "1",
  CHANGE_TRAVEL_DATE : "2",
  CANCEL_REBOOK_REVIEW : "cancelRebookReview",
  CANCEL_REBOOK_COMMIT : "cancelRebookCommit",
  EXTRA_PAYABLE : "Extra Payable / Adult",
  SEATS_LEFT : "Seats Left",
  NEW_DATES : "New Dates",
  SELECT_NEW_BOOKING :"Select your New Booking",
  FAQ_HEADING : 'Frequently asked',
  //
 BUS_SEARCH_STATE : 'BUS_SEARCH_STATE',
 BUS_REVIEW_STATE : 'BUS_REVIEW_STATE',

 //
 TOTAL_PAYABLE : 'Total Payable',
 FREE_DATE_CHANGE_TEXT : 'Change Date for FREE',
 PAID_DATE_CHANGE_TEXT : 'Extra charge for the seat',
 CHANGE_DATE_CHARGE_TEXT : 'Change Date Charge',


 //
  CALENDAR_EVENT_RECEIVED :'calEventReceived',
 OPEN_REVIEW_PAGE_EVENT : 'bus_rebook_open_review_page',

 //
 TRAVELLER_TEXT : "Traveller(s)",
 YEAR : 'Yr',
 SEAT_NO : 'Seat No.',
 RATINGS_TEXT :"ratings",

 HOLD_REQ_TYPE:"ReviewRequest",
 FOR_TEXT : 'for',
 TO_TEXT : ' to ',
 DEFAULT_ERROR_MSG : 'SOMETHING WENT WRONG',
 SEARCHING_BUSES :'SEARCHING BUSES...',
 LOADING:'LOADING...',
 DAY_YEAR :'DD MMM',
 DAY_DATE_MONTH :'ddd, DD MMM',
 HOUR_MINUTES:'HH:mm',
 MODIFIED_SUCCESS : 'BOOKING_SUCCESS',
 HOLD_SUCCESS : 'HOLD_SUCCESS',
 HOLDING_YOUR_SEAT :'HOLDING YOUR SEAT...'
}

export default BusCancelRebookConstant;