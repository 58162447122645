import { StyleSheet } from 'react-native';

export default (theme, fonts) => StyleSheet.create({
  buttonStyle: { paddingHorizontal: 30, paddingVertical: 16 },
  DarkFooter: {
    backgroundColor: theme.color.black28,
    paddingVertical: 8,
    paddingHorizontal: 10,
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
  },
  FooterTotalPrice: {
    fontSize: fonts.fontSize.font18,
    ...fonts.boldFontFamily,
    color: theme.color.white,
    marginRight: 10,
  },
  FooterpriceDtls: {
    fontSize: fonts.fontSize.font10,
    color: theme.color.lightYellow2,
    marginTop: 3,
  },
  flexRow: {
    flexDirection: 'row',
  },
  infoIcon: {
    width: 18,
    height: 19,
  },
  verticalSeparator: {
    height: 2,
    width: 25,
    backgroundColor: theme.color.white,
    transform: [{ rotate: '90deg' }],
  },
  wtRightArrow: { width: 16, height: 12, marginLeft: 10 },
})