import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import { isNetworkAvailable } from '@mmt/legacy-commons/Common/utils/AppUtils';
import {
  CAB_BOOKING_DETAILS_URL,
  CAB_PAY_ONLINE_URL,
  USER_CONFIRMATION_URL,
  getCommonHeaders,
} from '../../../../utils/NetworkUtils';
import CabBookingTrackingHelper from '../../../CabBookingTrackingHelper';
/* we can move to postsales level will be taken care later */
export const getHeaders = async (bookingId, uniqueId, psLob) => {
  let headers = {};
  const userInfo = await HotelBookingModule.getRequestHeader(
    bookingId ? bookingId : '',
    uniqueId
  );
  headers = await getCommonHeaders(
    userInfo.mmtAuth,
    userInfo.loggingTrackingInfo,
    { psLob }
  );
  return headers;
};

export const fetchCabBookingDetails = async (bookingId) => {
  try {
    const hasNetwork = await isNetworkAvailable();
    if (!hasNetwork) {
      return { isNetworkNotAvailable: true };
    }
    const headers = await getHeaders(bookingId, 'CAB_BOOKING_DETAIL', 'CAB');
    const url = CAB_BOOKING_DETAILS_URL + bookingId;
    let response = await fetch(url, {
      headers,
    });
    if (response && response.ok) {
      response = await response.json();
      return response;
    } else {
      CabBookingTrackingHelper.trackErrorEvent(
        'mob: cabs: mima main page:Error'
      );
      response = await response?.json();
      return { error: response };
    }
  } catch (error) {
    CabBookingTrackingHelper.trackErrorEvent('mob: cabs: mima main page:Error');
    return { error: error };
  }
};

export const fetchOnlinePaymentDetail = async (bookingId, amount) => {
  try {
    const hasNetwork = await isNetworkAvailable();
    if (!hasNetwork) {
      return { isNetworkNotAvailable: true };
    }
    const headers = await getHeaders(bookingId, 'CAB_ONLINE_PAYMENT', 'CAB');
    const request = {
      bookingId: bookingId,
      amount: amount,
    };
    let response = await fetch(CAB_PAY_ONLINE_URL, {
      method: 'POST',
      body: JSON.stringify(request),
      headers,
    });
    if (response && response.ok) {
      response = await response.json();
      return response;
    } else {
      CabBookingTrackingHelper.trackErrorEvent(
        'mob: cabs: mima main page:Error'
      );
      return { error: response };
    }
  } catch (error) {
    CabBookingTrackingHelper.trackErrorEvent('mob: cabs: mima main page:Error');
    return { error: error };
  }
};

export const userConfirmApi = async (userId, userConfirm, bookingId) => {
  try {
    const request = {
      bookingId: bookingId,
      userId: userId,
      userConfirm: userConfirm,
    };
    const headers = await getHeaders(bookingId, 'CAB_USER_CONFIRMATION', 'CAB');
    const response = await fetch(USER_CONFIRMATION_URL, {
      method: 'POST',
      body: JSON.stringify(request),
      headers
    });
    if (response && response.ok) {
      const body = await response.json();
      return body;
    } else {
      return { error: response };
    }
  } catch (error) {
    return { error: error };
  }
};