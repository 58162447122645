import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';

export const getInitialCountriesData = (response) => {
  const { cards } = response;
  const { countriesCard } = cards || {};
  const { countries } = countriesCard || {};
  if (isEmpty(countries)) {
    return [];
  }
  return countries.map((item) => ({
    countryCode: item.countryCode,
    visaTypeId:
      !isEmpty(item.visaType) &&
      !isEmpty(item.visaType.type) &&
      item.visaType.type[0].id,
    countryBreakup: {},
    countryPrice: 0,
    passengers: [],
  }));
};

export const getTotalPrice = (countriesData) => {
  let newPrice = 0;
  countriesData.map((item) => {
    newPrice += item.countryPrice;
  });
  return getRounded2Digits(newPrice);
};

export const getTotalCountriesBreakup = (countriesData) => {
  const totalCountriesBreakup = {};
  countriesData.forEach((item) => {
    const countryBreakup = item.countryBreakup;
    for (const key in countryBreakup) {
      totalCountriesBreakup[key] =
        ((isUndefined(totalCountriesBreakup[key])
          ? 0
          : totalCountriesBreakup[key]) + countryBreakup[key]);
      totalCountriesBreakup[key] = getRounded2Digits(totalCountriesBreakup[key]);
    }
  });
  return totalCountriesBreakup;
};

export const onSelectPassengers = (payload, countryData) => {
  const { selected, paxId, breakup, name, dateOfBirth } = payload;
  const { passengers, countryBreakup, countryPrice } = countryData;
  const priceObject = {};
  let newPassengers = [...passengers];
  let newVisaStatePrice = countryPrice;
  const newPriceBreakup = { ...countryBreakup };

  const breakupToBeUpdated = breakup;
  breakupToBeUpdated.map((item) => {
    priceObject[item.key] = item.value;
  });

  if (selected) {
    for (const item in priceObject) {
      newPriceBreakup[item] = isUndefined(newPriceBreakup[item])
        ? 0 + priceObject[item]
        : newPriceBreakup[item] + priceObject[item];
      newPriceBreakup[item] = getRounded2Digits(newPriceBreakup[item]);
    }
    const visaPriceToBeUpdated = breakupToBeUpdated.reduce(
      (acc, item) => acc + item.value,
      0
    );
    newVisaStatePrice = newVisaStatePrice + visaPriceToBeUpdated;
    newVisaStatePrice = getRounded2Digits(newVisaStatePrice);
    newPassengers.push({ name: name, paxId: paxId, dateOfBirth: dateOfBirth });
  } else {
    for (const item in priceObject) {
      newPriceBreakup[item] = newPriceBreakup[item] - priceObject[item];
      newPriceBreakup[item] = getRounded2Digits(newPriceBreakup[item]);
    }
    const visaPriceToBeUpdated = breakupToBeUpdated.reduce(
      (acc, item) => acc + item.value,
      0
    );
    newVisaStatePrice = newVisaStatePrice - visaPriceToBeUpdated;
    newVisaStatePrice = getRounded2Digits(newVisaStatePrice);

    newPassengers = newPassengers.filter((item) => item.paxId !== paxId);
  }

  return {
    priceBreakup: newPriceBreakup,
    totalPrice: newVisaStatePrice,
    newPassengers: newPassengers,
  };
};

export const getSelectedPassengersName = (countryData) => {
  let passengersName = '';
  const { passengers } = countryData || {};
  if (passengers.length > 2) {
    passengers.slice(0, 2).forEach((item, index) => {
      passengersName += item.name + (index !== passengers.length - 1 ? ',' : '');
    });
    passengersName = `${passengersName}...+ ${passengers.length - 2}`;
    return passengersName;
  } else {
    !isEmpty(passengers) && passengers.forEach((item, index) => {
      passengersName += item.name + (index !== passengers.length - 1 ? ',' : '');
    });
    return passengersName;
  }
};


export const getCountriesDataForVisaType = (state, payload) => {
  const { index, visaTypeId } = payload;
  const newCountriesData = [...state.countriesData];
  let selectedCountry = newCountriesData[index];
  selectedCountry.visaTypeId = visaTypeId;
  selectedCountry.passengers = [];
  selectedCountry.countryBreakup = {};
  selectedCountry.countryPrice = 0;
  return newCountriesData;
}
export const getCountriesDataForPassengers = (state, payload) => {
  const { countryIndex } = payload;
  const newCountriesData = [...state.countriesData];
  let selectedCountry = newCountriesData[countryIndex];
  const { priceBreakup, totalPrice, newPassengers } = onSelectPassengers(payload, selectedCountry);
  selectedCountry.countryBreakup = priceBreakup;
  selectedCountry.countryPrice = totalPrice;
  selectedCountry.passengers = newPassengers;
  return newCountriesData;
}

export const getCustCurrency = (response) => {
  return response?.header?.custCurrency;
}


export const getRounded2Digits = (num) => Math.round((num + Number.EPSILON) * 100) / 100;
