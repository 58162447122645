import React from 'react';
import {View, TouchableOpacity} from 'react-native';
import AgreeOverlay from '../../../components/AgreeOverlay';
import { getFont } from '../../../../../../PostSalesStyles';
import { useTheme } from '../../../../../../theme/theme.context';
import createStyles from "./OverlayMessageCss";

const OverlayMessage = ({closePopupHandler, proceedClickHandler, specialClaimData}) => {
  const fonts = getFont()
  const {psTheme : theme} = useTheme();
  const styles = createStyles(theme,fonts);

return (
  <View style={styles.overlayWrapper}>
    <TouchableOpacity activeOpacity={1} style={styles.overlay} onPress={() => closePopupHandler()}/>
    <View style={styles.overlayContent}>
      <AgreeOverlay specialClaimData={specialClaimData}
                    proceedClickHandler={proceedClickHandler}/>
    </View>
  </View>
);

}

export default OverlayMessage;
