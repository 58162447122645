import React, { ReactNode } from 'react';
import { View, StyleSheet, ViewStyle } from 'react-native';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import Divider from '../../Atoms/Divider';
/**
 * Props for the RowCardContainer component.
 * @interface CardHeaderProps
 * @property {ReactNode} children - The child components to be rendered inside the HeaderCardContainer.
 * @property {ViewStyle} [containerStyle] - Additional styles for the RowCardContainer.
 */
interface CardHeaderProps {
  children: ReactNode;
  containerStyle?: ViewStyle;
}

/**
 * RowCardContainer component renders a contain in flex row with custom styles.
 * @param {CardHeaderProps} props - The component props.
 * @returns {JSX.Element} - The rendered JSX element.
 */
const CardHeader: React.FC<CardHeaderProps> = ({
  containerStyle = {},
  children
  
}) => (
  <View style={[styles.headingWrapper, containerStyle]}>
    {children}
  </View>
);

const styles = StyleSheet.create({
  headingWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

export default CardHeader;
