import React from 'react';
import { Text, View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { useTheme } from '../../../../../theme/theme.context';
import { getFont } from '../../../../../PostSalesStyles';
import getStyle, {htmlstyles} from './UserConfirmationCardStyle';
import isEmpty from 'lodash/isEmpty';
import BaseButton from 'apps/post-sales/src/Common/BaseButton';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { UserConfirmationCardProps, ActionList } from '../../../../types';
import HTMLView from 'react-native-htmlview';
import CabBookingTrackingHelper from 'apps/post-sales/src/cab/CabBookingTrackingHelper';
import CabBookingDetailsConstant from 'apps/post-sales/src/cab/CabBookingDetailsConstant';


const UserConfirmationCard = ({
  card,
  onConfirmBooking,
  pageName
}: UserConfirmationCardProps) => {
  const { psTheme } = useTheme();
  const { color } = psTheme;
  const { fsStyle, ...fonts } = getFont(true);
  const styles = getStyle(color, fonts);

  const { heading, actionList } = card || {};
  const htmlstyle = htmlstyles(color, fonts);

  const getButtonStyles = (actionId: string) => {
    switch (actionId) {
      case 'Cancel_Request_Btn':
        return [styles.cancelButtonStyle, styles.cancelTextStyle];
      case 'Confirm_Ride_Btn':
        return [styles.confirmButtonStyle, styles.confirmTextStyle];
    }
    return [];
  };

  const onHandleClick = (action: ActionList) => {
    const { actionId = '' } = action || {};
    if (actionId == 'Cancel_Request_Btn') {
      CabBookingTrackingHelper.trackClickEvent(pageName,CabBookingDetailsConstant.CAB_CANCEL_REQUEST);
    } else if (actionId == 'Confirm_Ride_Btn') {
      CabBookingTrackingHelper.trackClickEvent(pageName,CabBookingDetailsConstant.CAB_CONFIRM_REQUEST);
    }
    onConfirmBooking(action);
  };

  return (
    <View
      style={styles.container}
    >
      <React.Fragment>
        {!isEmpty(heading) && (
          <View>
            <HTMLView value={heading} stylesheet={htmlstyle} />
          </View>
        )}
        {!isEmpty(actionList) && (
          <View style={styles.btnWrapper}>
            {actionList.map((action) => {
              const {
                actionType = '',
                actionLabeltext = '',
                actionId = '',
                actionFamily = '',
              } = action || {};
              const [buttonStyle, textStyle] = getButtonStyles(actionId);
              return (
                <View
                key={actionId}
                style={styles.buttonView}
                >
                  <BaseButton
                    variant={actionType}
                    buttonStyle={buttonStyle}
                    textStyle={textStyle}
                    clickHandler={() => {
                      onHandleClick(action);
                    }}
                    text={actionLabeltext.toUpperCase()}
                  />
                </View>
              );
            })}
          </View>
        )}
      </React.Fragment>
    </View>
  );
};

export default UserConfirmationCard;
