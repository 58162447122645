import React from 'react';
import {View, Text, Image, TouchableOpacity} from 'react-native';
import styles from './inclusionExclusionCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {exclusionIcon2, dropDownArrow} from "../../CabImageConstants";
import CabBookingTrackingHelper from '../../CabBookingTrackingHelper';
import { CLICK_EVENT, PAGE_NAME } from '../../CabBookingDetailsConstant';

export default class ExclusionTab extends React.Component {
  constructor() {
    super();
    this.state = {
      active: true
    };
  }

    handleClick = () => {
      this.state.active ?
        CabBookingTrackingHelper.trackClickEvent(PAGE_NAME.MODIFY_BOOKING, CLICK_EVENT.COLLAPSE_NEW_EXCLUSION_CLICK):
        CabBookingTrackingHelper.trackClickEvent(PAGE_NAME.MODIFY_BOOKING, CLICK_EVENT.EXPAND_NEW_EXCLUSION_CLICK);
      this.setState({active: !this.state.active});
    }

    render() {
      const dropDownArrowStyle = (this.state.active) ? [styles.arrowActiveStyle, styles.blueArrowStyle] : [styles.blueArrowStyle];
      return (
        <View style={(this.props.isLast) ? (styles.wrapperForLastLine) : (styles.wrapper)}>
          <TouchableOpacity
            onPress={this.handleClick}
            style={[AtomicCss.flexRow, AtomicCss.spaceBetween, styles.head]}
          >
            <Text
              style={[AtomicCss.blackText, AtomicCss.font14, AtomicCss.blackFont]}
            >{this.props.heading}
            </Text>
            <Image style={[dropDownArrowStyle]} source={dropDownArrow} />
          </TouchableOpacity>
          {this.state.active &&
            <View>
              {this.fetchExclusions(this.props.exclusionData)}
            </View>
                }
        </View>
      );
    }

    fetchExclusions = function (exclusions) {
      const views = Object.keys(exclusions).map((key) => {
        return(<View key={key} style={[AtomicCss.marginBottom10, AtomicCss.flexRow, AtomicCss.alignCenter]}><Image source={exclusionIcon2} style={styles.exclusion} /><Text style={[AtomicCss.greyText, AtomicCss.font14, AtomicCss.paddingLeft5]}>{exclusions[key]}</Text></View>);
      });
      return views;
    }
}
