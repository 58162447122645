import Provider from './components/Provider';
import connectAdvanced from './components/connectAdvanced';
import { ReactReduxContext } from './components/Context';
import connect from './connect/connect';

import { createDispatchHook, useDispatch } from './hooks/useDispatch';
import { createSelectorHook, useSelector } from './hooks/useSelector';
import { createStoreHook, useStore } from './hooks/useStore';

import { setBatch } from './utils/batch';
import { unstable_batchedUpdates as batch } from './utils/reactBatchedUpdates';
import shallowEqual from './utils/shallowEqual';

setBatch(batch);

export {
  Provider,
  connectAdvanced,
  ReactReduxContext,
  connect,
  batch,
  useDispatch,
  createDispatchHook,
  useSelector,
  createSelectorHook,
  useStore,
  createStoreHook,
  shallowEqual,
};
