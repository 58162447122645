import React from 'react';
import { StyleSheet, View, Text, ActivityIndicator } from 'react-native';
import isEmpty from 'lodash/isEmpty';

const ProgressView = (props) => {

    const styles = createStyles();
    return (
        <View style={[styles.progressContainer]}>
            <ActivityIndicator
                styleAttr="Inverse"
                style={styles.indicator}
                color="008b8b"
                size="large"
            />
            {!isEmpty(props.message) && (
                <Text
                    style={styles.textDark}
                >
                    {props.message}
                </Text>
            )}
        </View>
    );
};

const createStyles = () => {
    return StyleSheet.create({
        progressContainer: {
            flex: 1,
            width: '100%',
            height: '100%',
            backgroundColor: '#ffffff',
            alignItems: 'center',
            justifyContent: 'center',
        },
        textDark: {
            color: '#000000',
            fontSize: 16,
            fontFamily: 'Roboto-Bold',
            textAlign: 'center',
            marginTop: 8
        },
    });
};


export default ProgressView;
