import { StyleSheet, Text, View, Image } from 'react-native';
import React from 'react';
// @ts-ignore
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { PersuasionProps } from '../../../types/globalTypes';
import { colors, fonts } from 'packages/legacy-commons/Styles/globalStyles';

interface Props {
  data: PersuasionProps | null;
}

const PersuasionCard = ({ data }: Props) => {
  if (!data) {
    return null;
  }
  const { title, persuasions, coupon_details } = data;
  return (
    <View>
      <View style={styles.detailsMoreInfoCard}>
        <Text
          style={[
            AtomicCss.blackFont,
            AtomicCss.blackText,
            AtomicCss.font15,
            AtomicCss.marginBottom6,
            AtomicCss.lineHeight15,
            {fontSize:14, fontWeight:'700'}
          ]}
        >
          {title}
        </Text>
        {/* @ts-ignore */}
        <View style={[AtomicCss.flexRow, AtomicCss.flexWrap]}>
          {persuasions?.map((persuasion) => (
            // @ts-ignore
            <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginRight10]}>
              <View style={styles.dots} />
              <Text style={[AtomicCss.regularFont, AtomicCss.blackText,AtomicCss.lineHeight12,AtomicCss.font11,{fontSize:12,fontWeight:'400'}]}>
                {persuasion}
              </Text>
            </View>
          ))}
        </View>
      </View>
      {coupon_details?.text &&
        <View style={styles.cabVoucher}>
          {Boolean(coupon_details?.image) && (
            <View >
              <Image style={styles.voucherIconStyle} source={{ uri: coupon_details?.image }} />
            </View>
          )}
          <View style={styles.persuasionText}>
            <Text style={[AtomicCss.regularFont, AtomicCss.font12, AtomicCss.blackText]}>
              <Text style={[AtomicCss.boldFont,{color:colors.black, fontWeight:'700'}]}>{coupon_details?.text}</Text>
            </Text>
          </View>
        </View>
      }
    </View>
  );
};

export default PersuasionCard;

const styles = StyleSheet.create({
  detailsMoreInfoCard: {
    marginTop: 12,
    borderWidth: 1,
    borderColor: '#7fc5ff',
    paddingHorizontal: 16,
    paddingVertical: 12,
    borderRadius: 4,
  },
  voucherIconStyle: {
    height: 22,
    width: 22,
    marginRight: 4,
  },
  dots: {
    width: 5,
    height: 5,
    borderRadius: 10,
    backgroundColor: '#000000',
    marginRight: 5,
  },
  cabVoucher: {
    paddingHorizontal: 10,
    paddingVertical: 8,
    backgroundColor:'rgba(193, 241, 221, 0.3522)',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    marginHorizontal: 10,
    flex: 1,
    justifyContent: 'space-between'
  },
  persuasionText: {
    flex: 1,
  },
  persuasionText: {
    flex: 1,
  }
});
