import React from 'react';
import { View, Text } from 'react-native';
import { getFont } from '../../../PostSalesStyles';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { addSpaceWithAmount } from '../../../utils/PostSaleUtil';

const PaymentCard = ({data}) => {
  const fonts = getFont(true);
  return (
    <React.Fragment>
      <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween, AtomicCss.marginTop10]}>
        <Text style={[AtomicCss.font14, fonts.regularFontFamily, AtomicCss.defaultText, AtomicCss.flex1, AtomicCss.paddingRight16]}>{data.key}</Text>
        <Text style={[AtomicCss.font14, fonts.regularFontFamily, AtomicCss.blackText]}>{addSpaceWithAmount(data.value)}</Text>
      </View>
    </React.Fragment>
  );
};

export default PaymentCard;