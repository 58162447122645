import React from 'react';
import {View, Text, Image} from 'react-native';
import PropTypes from 'prop-types';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import * as PSC from '../../../PostSalesConstant';
import {styles} from './CabAmendmentStyles';
import {stepHeaderTick} from '../../CabImageConstants';

class ReviewStepHeader extends React.Component {
    static navigationOptions = {
      header: null
    };

    render() {
      return (
        <View style={styles.InfoStrip}>
          { this.getReviewHeader() }
        </View>
      );
    }

    getReviewHeader() {
      const views = [];
      for (let i = 0, keys = Object.keys(this.props.headersMap), ii = keys.length; i < ii; i++) {
        views.push(<View style={[AtomicCss.flexRow, i === 0 ? styles.mar24Rt : null]}>
          {i < this.props.currentStep &&
          <View style={[styles.RowCount, styles.RowCountActive, AtomicCss.boldFont]}>
            <Image style={{width: 9, height: 6}} source={stepHeaderTick} />
          </View> }
          { !(i < this.props.currentStep) &&
            <View style={[styles.RowCount, i > this.props.currentStep ? styles.RowCountInActive : styles.RowCountActive]}><Text style={[styles.RowCountText, AtomicCss.boldFont]}>{keys[i]}</Text></View> }
          <Text style={[AtomicCss.boldFont, i > this.props.currentStep ? AtomicCss.lightGreyText : AtomicCss.blackText, AtomicCss.font11]}>{this.props.headersMap[keys[i]]}</Text>
        </View>);
      }
      return views;
    }
}

ReviewStepHeader.defaultProps = {
  headersMap: {1: PSC.PICK_DATE, 2: PSC.VIEW_AVAILABLITY}
};

ReviewStepHeader.propTypes = {
  currentStep: PropTypes.number.isRequired,
  headersMap: PropTypes.object
};

export default ReviewStepHeader;
