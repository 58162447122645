import { FLIGHT_CANCELLATION_TYPE } from '../../../../../PostSalesConstant';
import CancellationTrackingHelper from 'apps/post-sales/src/flights/cancellation/Helpers/CancellationTrackingHelper';

export const setTrackingPageName = (cancellationRequest, allPaxSelected) => {
  try {
    if (
      cancellationRequest.flightCancellationType ===
      FLIGHT_CANCELLATION_TYPE.FULL_CANCELLATION
    ) {
      return 'Review:full';
    } else if (
      cancellationRequest.flightCancellationType ===
      FLIGHT_CANCELLATION_TYPE.PARTIAL_CANCELLATION
    ) {
      return 'Review:partial';
    } else if (allPaxSelected) {
      return 'Review:full';
    } else {
      return 'Review:partial';
    }
  } catch (e) {
    console.log("Error in Refund Page setTrackingPageName ", e);
    return '';
  }
}

export const getTrackingBundle = (cancellationPreviewResponseJson, allPaxSelected, cancellationRequest, error = false) => {
  try {
    const trackingBundle = {};
    trackingBundle.response = cancellationPreviewResponseJson;
    trackingBundle.isInternational = cancellationPreviewResponseJson && cancellationPreviewResponseJson.international;
    trackingBundle.claimReason = ''
    trackingBundle.flightCancellationType = cancellationRequest.flightCancellationType;
    trackingBundle.isFullSelection = allPaxSelected || cancellationRequest.flightCancellationType === FLIGHT_CANCELLATION_TYPE.FULL_CANCELLATION;
    if (error) {
      trackingBundle.error = error;
    }
    return trackingBundle;
  } catch (e) {
    console.log("Error in Refund Page getTrackingBundle ", e);
    return {};
  }
}

export const trackHelpingHandEvents = (type, page, click, data, cancellationPreviewResponseJson = {}, allPaxSelected = false, cancellationRequest = {}) => {
  try {
    const pageName = `${setTrackingPageName(cancellationRequest, allPaxSelected)}_${page}`;
    const trackData = Object.assign({}, getTrackingBundle(cancellationPreviewResponseJson, allPaxSelected, cancellationRequest), {
      ruleTrace: data ? data.ruleTrace : '',
    });
    switch (type) {
      case 'load':
        CancellationTrackingHelper.trackLoadEvent(pageName, trackData);
        break;
      case 'click':
        CancellationTrackingHelper.trackClickEvent(pageName, `Flight_${click}`, trackData);
        break;
      default:
        break;
    }
  } catch (e) {
    console.log("Error in Refund Page trackHelpingHandEvents ", e);
    return '';
  }
};

export const trackViewHideBreakUpClick = (clickEvent, cancellationPreviewResponseJson = {}, allPaxSelected = false, cancellationRequest = {}) => {
  try {
    CancellationTrackingHelper.trackClickEvent(
      setTrackingPageName(cancellationRequest, allPaxSelected),
      clickEvent,
      getTrackingBundle(cancellationPreviewResponseJson, allPaxSelected, cancellationRequest)
    );
  } catch (e) {
    console.log("Error in Refund Page trackViewHideBreakUpClick ", e);
  }
};


