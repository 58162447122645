import React, { useState } from 'react';
import { StyleSheet, View, TouchableOpacity, Image, Text } from 'react-native';

import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import iconParachute from '@mmt/legacy-assets/src/postSales/summary/upcomingHolidaysIocn.webp';
import iconThreeDots from '@mmt/legacy-assets/src/postSales/summary/ic_threeDots.webp';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
import getHolidayPlanningCardSummaryData from './holidayPlanningCardAdapter';
import { cancelHolidayPlanningQuery } from '../../../data/api';
import TripSummaryOmnitureTracker from '../../../tracking/TripSummaryOmnitureTracker';
import { Popup } from '../cardElements';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import feedbackIcon from '@mmt/legacy-assets/src/Feedback/confirmation-image.webp';
import { getStaticData } from '../../../../staticData/staticData';
import { buttonDisplayTypes } from '../../../../Holiday/HolidayBookingConstants';
import { isEmpty } from 'lodash';
import { getFont } from '../../../../PostSalesStyles';

const HolidayPlanningSummaryCard = ({ response, onRefreshHolidayPlannings }) => {
  const staticData = getStaticData();
  const cardData = getHolidayPlanningCardSummaryData(response);
  const [showCancelQuery, setShowCancelQuery] = useState(false);
  const [showCancelShimmer, setShowCancelShimmer] = useState(false);
  const [thankYouData, setThankYouData] = useState({});
  const { fsStyle, ...fonts }  = getFont(true);
  const handleConfirm = () => {
    if (!thankYouData.success) {
      setShowCancelShimmer(true);
      handleTryAgain();
    } else {
      handleThankyouPopup();
      onRefreshHolidayPlannings();
    }
  };
  const handleCancelQuery = () => {
    setShowCancelQuery(!showCancelQuery);
  };

  const [showThankyouPopup, setShowThankyouPopup] = useState(false);
  const handleThankyouPopup = () => {
    setShowThankyouPopup(!showThankyouPopup);
  };
  const handleTryAgain = () => {
    cancelHolidayPlanningQuery({
      ticketId: response.ticketId,
      tagDestination: response.tagDestination,
    })
      .then((data) => {
        setShowCancelShimmer(false);
        setThankYouData(data);
      })
      .catch(() => {
        setShowCancelShimmer(false);
        TripSummaryOmnitureTracker.trackErrorEvent(
          'mob:error:customer support:APIResponse',
          'CANCEL_HOLIDAY_PLANNING_API_ERROR',
        );
        setThankYouData({
          success: false,
          message: staticData.errorText.soomethingWentWrongOnlyError,
          button: {
            text: staticData.tryAgainText,
            displayType: buttonDisplayTypes.CAPSULE_GRADIENT,
          },
        });
      });
  };

  const handleCancel = (button) => {
    if (button.type === staticData.confirmText) {
      setShowCancelShimmer(true);
      cancelHolidayPlanningQuery({
        ticketId: response.ticketId,
        tagDestination: response.tagDestination,
      })
        .then((data) => {
          setShowCancelShimmer(false);
          handleCancelQuery();
          setThankYouData(data);
          handleThankyouPopup();
        })
        .catch(() => {
          setShowCancelShimmer(false);
          handleCancelQuery();
          TripSummaryOmnitureTracker.trackErrorEvent(
            'mob:error:customer support:APIResponse',
            'CANCEL_HOLIDAY_PLANNING_API_ERROR',
          );
          setThankYouData({
            success: false,
            message: staticData.errorText.soomethingWentWrongOnlyError,
            button: {
              text: staticData.tryAgainText,
              displayType: buttonDisplayTypes.CAPSULE_GRADIENT,
            },
          });
          handleThankyouPopup();
        });
    } else {
      setShowCancelShimmer(false);
      handleCancelQuery();
    }
  };

  const [showMenu, setShowMenu] = useState(false);
  const [showTransBg, setShowTransBg] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
    setShowTransBg(!showTransBg);
  };

  const handleAction = (text) => {
    if (text === staticData.editQueryText) {
      toggleMenu();
      cardData.editQuery();
    }
    if (text === staticData.closeQueryText) {
      toggleMenu();
      handleCancelQuery();
    }
    if (text == staticData.viewDetailsCapsText) {
      cardData.openDetails();
    }
  };

  const getCancelPopupData = () => {
    if (cardData.closeQueryCTAs) {
      return {
        heading: cardData.closeQueryCTAs.heading,
        subHeading: cardData.closeQueryCTAs.subHeading,
        buttons: cardData.closeQueryCTAs.closeQueryButtonCTAs,
      };
    }
  };

  const getThankYouPopupData = () => {
    if (thankYouData) {
      return {
        heading: thankYouData.message,
        buttons: [thankYouData.button],
      };
    }
  };
  return (
    <>
      {showCancelQuery && (
        <Popup
          modalVisible={showCancelQuery}
          handleModal={handleCancelQuery}
          handleClick={handleCancel}
          popUpData={getCancelPopupData()}
          showCancelShimmer={showCancelShimmer}
          centerImage={feedbackIcon}
        />
      )}
      {showThankyouPopup && (
        <Popup
          popUpData={getThankYouPopupData()}
          modalVisible={showThankyouPopup}
          handleClick={handleConfirm}
          showCancelShimmer={showCancelShimmer}
          handleModal={handleThankyouPopup}
          centerImage={feedbackIcon}
        />
      )}
      <View style={styles.queryCard}>
        <View style={styles.cardContainer}>
          <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween]}>
            <View style={[AtomicCss.flexRow]}>
              <Image source={iconParachute} style={styles.iconParachute} />
              <View style={AtomicCss.paddingLeft10}>
                <Text style={[fsStyle.font16, AtomicCss.boldFont, AtomicCss.blackText]}>
                  {cardData.title}
                </Text>
                <Text
                  style={[
                    fsStyle.font14,
                    AtomicCss.regularFont,
                    AtomicCss.defaultText,
                    AtomicCss.paddingTop5,
                  ]}
                >
                  {cardData.travelText}
                </Text>
                {!isEmpty(cardData.status) && (
                  <View style={AtomicCss.paddingTop10}>
                    <Text style={[fsStyle.font14, AtomicCss.boldFont, AtomicCss.azure]}>
                      {cardData.status}
                    </Text>
                  </View>
                )}
              </View>
            </View>
            {cardData.optionCTAs && cardData.optionCTAs.length > 0 && (
              <TouchableOpacity onPress={toggleMenu} style={styles.toggleMenuDots}>
                <Image source={iconThreeDots} style={styles.iconThreeDots} />
              </TouchableOpacity>
            )}
            {showTransBg && (
              <TouchableOpacity
                style={styles.transparentBg}
                onPress={toggleMenu}
              ></TouchableOpacity>
            )}
            {showMenu && cardData.optionCTAs && cardData.optionCTAs.length > 0 && (
              <View style={styles.menuQueryOptions}>
                {cardData.optionCTAs.map((optionCTA) => (
                  <TouchableOpacity onPress={() => handleAction(optionCTA.text)}>
                    <View>
                      <Text
                        style={[
                          fsStyle.font12,
                          AtomicCss.blackText,
                          AtomicCss.regularFont,
                          AtomicCss.marginBottom2,
                        ]}
                      >
                        {optionCTA.text}
                      </Text>
                    </View>
                  </TouchableOpacity>
                ))}
              </View>
            )}
          </View>
        </View>
        {cardData.buttonCTAs && cardData.buttonCTAs.length > 0 && (
          <View style={styles.cardFooter}>
            {cardData.buttonCTAs.map((buttonCTA) => (
              <TouchableOpacity onPress={() => handleAction(buttonCTA.text)}>
                <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
                  <Text style={[fsStyle.font12, fonts.blackFontFamily, AtomicCss.azure]}>
                    {buttonCTA.text}
                  </Text>
                  <View style={[styles.rightArrow, AtomicCss.marginLeft5]}></View>
                </View>
              </TouchableOpacity>
            ))}
          </View>
        )}
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  queryCard: {
    backgroundColor: colors.white,
    width: '100%',
    marginBottom: 10,
  },
  transparentBg: {
    backgroundColor: 'transparent',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    position: 'absolute',
    zIndex: 1,
  },
  cardContainer: {
    padding: 15,
  },
  cardFooter: {
    backgroundColor: colors.black2,
    paddingHorizontal: 15,
    paddingVertical: 10,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },
  iconParachute: {
    width: 20,
    height: 28,
    resizeMode: 'cover',
  },
  iconThreeDots: {
    width: 3,
    height: 13,
    resizeMode: 'cover',
  },
  menuQueryOptions: {
    backgroundColor: colors.white,
    position: 'absolute',
    right: 5,
    top: 15,
    zIndex: 2,
    ...getPlatformElevation(3),
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderRadius: 2,
  },
  toggleMenuDots: {
    paddingLeft: 15,
    paddingRight: 5,
    paddingVertical: 5,
  },
  rightArrow: {
    width: 8,
    height: 8,
    borderTopWidth: 2,
    borderRightWidth: 2,
    borderColor: colors.azure,
    transform: [{ rotate: '45deg' }],
  },
});

export default HolidayPlanningSummaryCard;
