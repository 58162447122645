import React from 'react';

import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import NoInternetView from '@mmt/legacy-commons/Common/Components/Error/NoInternetView';

import Actions from '../../../../../navigation/postSalesNavigation';
import useCancellationDone from '../../pages/Thankyou/hooks/useCancellationDone';
import { getStaticData } from '../../../../../staticData/staticData';
import { LOBNAMES } from '../../../../../PostSalesConstant';

import ProgressView from '../../../../../Common/ProgressView/ProgressView';

import { openErrorPage } from '../../../../../utils/PostSaleUtil';
import { CANCELLATION_THANKYOU_PAGE_VIEW, THANKYOU_PAGE_ERROR } from '../../constant';
import { goToBookingDetails } from '../../helper';
import { FlightBookingCancellationProps, StaticDataResult } from './types';
import useHardwareBackpress from '../../../../../Common/useHardwareBackpress';

const FlightBookingCancellation = ({
  cancellationRequest,
  onSuccess = () => {},
  onError = () => {},
}: FlightBookingCancellationProps) => {
  const { bookingId } = cancellationRequest || {};
  const {
    initializingCancellationText,
    errorText: {
      somethingWentWrongTryAgainError,
      notConnectedToTheInternetError,
      checkYourSettingsAndTryAgainError,
    },
    tryAgainText,
    backText,
  }: StaticDataResult = getStaticData();
  const [
  viewState,
  cancellationDonePageData,
  errorType,
  onRetry,
] = useCancellationDone(cancellationRequest, { bookingId });

  useHardwareBackpress().addListener(() => goToBookingDetails(bookingId), true);

  const onBackPressFromError = () => {
    onError();
  };

  if (viewState === CANCELLATION_THANKYOU_PAGE_VIEW.LOADING) {
    return <ProgressView message={initializingCancellationText} />;
  } else if (
    viewState === CANCELLATION_THANKYOU_PAGE_VIEW.ERROR ||
    errorType === THANKYOU_PAGE_ERROR.OPEN_ERROR_PAGE
  ) {
    if (
      errorType === THANKYOU_PAGE_ERROR.OPEN_ERROR_PAGE &&
      cancellationDonePageData?.errorPageData
    ) {
      openErrorPage(
        cancellationDonePageData.errorPageData,
        onBackPressFromError,
        onRetry,
        bookingId,
        'cancellation',
        Actions,
        { psLob: LOBNAMES.FLIGHT }
      );
      return null;
    } else {
      return (
        <ErrorView
          message={somethingWentWrongTryAgainError}
          buttonLabel={tryAgainText}
          showRetry
          onRetry={() => {
            onRetry();
          }}
        />
      );
    }
  } else if (viewState === CANCELLATION_THANKYOU_PAGE_VIEW.NO_INTERNET) {
    return (
      <NoInternetView
        title={backText}
        heading={notConnectedToTheInternetError}
        subHeading={checkYourSettingsAndTryAgainError}
        buttonText={tryAgainText}
        onRetry={() => {
          onRetry();
        }}
      />
    );
  } else if (onSuccess) {
    onSuccess();
  }

  return null;
};

export default FlightBookingCancellation;
