//utility class to convert given list to a map based on a key. This is to support O(1) fetch operation based on the key.
//preferably use this with useMemo to avoid running multiple times on rerender.

export default class ListToMapUtil {
    constructor(dataList, key){
        this._map={};
        if(typeof dataList === 'object' && Array.isArray(dataList)){
            dataList.forEach((value) => {
                this._map[value[key]] = value;
            });
        }
    }
    getObject = (idList) => {
        if(typeof idList === 'string') return this._map[idList] || {};
        if(idList.length ===1 ) return this._map[idList[0]] || {};
        let res = [];
        idList.forEach((value) => {
            if(this._map.hasOwnProperty(value))
                res.push(this._map[value]);
        })
        return res;
      }

     getObjectExcluding = (idList) => {
         let res = [];
         if(typeof idList === 'string') {
             for(let key in this._map){
                 if(this._map.hasOwnProperty(key)){
                     if(key == idList) continue;
                    res.push(this._map[key]);
                 }
             }
         }
         else{ //expensive operation. Should avoid using.
            for(let key in this._map){
                if(this._map.hasOwnProperty(key)){
                    if(idList.includes(key)) continue;
                   res.push(this._map[key]);
                }
            }
         }

         return res
            
     } 
    
      getValue = (idList, key)=>{
        if(typeof idList === 'string') return this._map[idList] && this._map[idList][key];
        if(idList.length ===1 ) return this._map[idList[0]] && this._map[idList[0]][key];
        let res = [];
        idList.forEach((value) => {
            if(this._map.hasOwnProperty(value))
                res.push(this._map[value][key]);
        })
        return res;
        }
        
    getMap = () => {
        return this._map;
    }
}