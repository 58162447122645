import React from 'react';
import { View } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getStyles from './styles';
import { getFont } from '../../../../../PostSalesStyles';
import { useTheme } from '../../../../../theme/theme.context';
import HTMLViewWrapper from '../../../../../Common/HTMLViewWrapper';
import isEmpty from 'lodash/isEmpty';

interface RefundViaVoucherProps {
    heading: string | null | undefined;
    subheading: string[] | null | undefined;
}

const CreditShellMessageBox: React.FC<RefundViaVoucherProps> = ({heading,subheading}) => {
    if (isEmpty(subheading)) {return null;}
    const { fsStyle, ...fonts } = getFont();
    const { psTheme: theme } = useTheme();
    const styles = getStyles(theme, fonts);
    return (
        <View style={styles.refundWrapper}>
            {!!heading &&
                <HTMLViewWrapper htmlContent={heading} style={[]}/>
            }
            {subheading?.map((text, index) => (
                <View style={index !== subheading.length - 1 && [AtomicCss.marginBottom8]}>
                    <HTMLViewWrapper
                        key={index}
                        htmlContent={text}
                        style={[styles.textStyle]}
                    />
                </View>
            ))}
        </View>
    );
};



export default CreditShellMessageBox;