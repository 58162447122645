import React from 'react';
import {View, Text, Image, ScrollView, TouchableOpacity} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import {fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import HeaderCross from '../../../Common/HeaderCross/index';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {logoMYSafety} from '../../HolidayBookingConstants';
import { themeColors } from '../../../PostSalesStyles';

const CheckListOverlay = props => (
  <React.Fragment>

    <View style={[styles.WebCheckinOverlayWrapper, AtomicCss.flex1]}>
      <HeaderCross
        title="Check List"
        headerShadow
        headingTxt="font18"
        handleClose={props.onBackPopUp}
      />
      <ScrollView>
        <LinearGradient
          start={{
            x: 1.0,
            y: 0.0
          }}
          end={{
            x: 0.0,
            y: 1.0
          }}
          colors={['#e8fde9', '#ffffff']}
          style={[styles.headingDtls]}
        >
          <Image style={styles.logoMYSafety} source={logoMYSafety}/>
          <Text
            style={[AtomicCss.font16, AtomicCss.blackText, AtomicCss.blackFont, AtomicCss.marginBottom5]}
          >{props.checkList.header}
          </Text>

        </LinearGradient>
        <View style={styles.checkListMain}>
          {props.checkList.itemlist && props.checkList.itemlist.map((item, index) =>
            (<View
                style={[styles.sectionRow, index === props.checkList.itemlist.length - 1 ? styles.lastEleBorO : {}]}>
                <View style={styles.countWrapper}>
                  <Text style={styles.countingTxt}>{index + 1}</Text>
                </View>
                <View
                  style={[styles.dtlsSection, index === props.checkList.itemlist.length - 1 ? {borderBottomWidth: 0} : {}]}>
                  <View style={AtomicCss.flex1}>
                    <View style={styles.textDetails}>
                      <Text
                        style={[AtomicCss.boldFont, AtomicCss.blackText, AtomicCss.font12, AtomicCss.marginBottom5]}
                      >{item.title}
                      </Text>
                      {item.action.type==="WEB_CHECKIN" && 
                              <View>
                                <Text style={styles.mandatoryTag}>Mandatory</Text>
                              </View>
                            }
                    </View>
                    {item.desc && item.desc.map((des) =>
                      (<Text
                          style={[AtomicCss.regularFont, AtomicCss.defaultText, AtomicCss.font12]}>
                          {des}
                        </Text>
                      ))}
                  </View>
                  <TouchableOpacity onPress={() => props.checklistActionHandler(item.action)}>
                    <Text
                      style={[{color: themeColors.primary}, AtomicCss.font14, AtomicCss.boldFont]}
                    >{item.action.title}
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            ))
          }
        </View>

      </ScrollView>
    </View>

  </React.Fragment>
);

const styles = {

  WebCheckinOverlayWrapper: {
    backgroundColor: '#fff',
    justifyContent: 'space-between',
    position: 'absolute',
    elevation: 10,
    zIndex: 10,
    width: '100%',
    height: '100%'
  },
  logoMYSafety: {
    width: 20,
    height: 20,
    marginRight: 12
  },
  checkListMain: {
    padding: 16,
    paddingRight: 0,
    paddingLeft: 30
  },
  sectionRow: {
    borderLeftWidth: 1,
    borderLeftColor: '#e7e7e7',
    paddingLeft: 25

  },
  lastEleBorO: {
    borderLeftWidth: 0,
    borderLeftColor: 'transparent'
  },
  dtlsSection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderBottomColor: '#e7e7e7',
    paddingBottom: 15,
    paddingRight: 15,
    marginBottom: 15
  },
  lastItem: {
    paddingBottom: 0,
    marginBottom: 0,
    borderBottomWidth: 0,
    borderBottomColor: 'transparent'
  },
  countWrapper: {
    width: 20,
    height: 20,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: '#e7e7e7',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
    position: 'absolute',
    left: -10
  },
  countingTxt: {
    fontSize: 12,
    fontFamily: fonts.bold,
    color: '#4a4a4a'
  },
  btnWrapper: {
    padding: 15,
    borderTopWidth: 1,
    borderTopColor: '#e3e3e3',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row'
  },
  linkText: {
    color: '#008cff',
    fontSize: 12,
    fontFamily: fonts.bold
  },
  arrowIconStyle: {
    width: 16,
    height: 16,
    marginLeft: 5
  },
  activeArrowIconStyle: {
    width: 16,
    height: 16,
    transform: [
      {rotate: '180deg'}
    ],
    marginLeft: 5
  },
  headingDtls: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 16
  },
  ViewAllTxt: {
    marginLeft: 30
  },
  crossIconActionWrapper: {
    position: 'absolute',
    right: 20,
    top: -30
  },
  crossIconStyle: {
    width: 14,
    height: 14,
    tintColor: '#fff'
  },
  textDetails:{
    flexDirection:'row',
  },
  mandatoryTag: {
    fontSize: 12,
    fontWeight: '700',
    color: '#cf8100',
    textAlign: 'center',
    paddingTop: 3,
    paddingLeft: 9,
    paddingRight: 9,
    paddingBottom: 3,
    borderRadius: 100,
    backgroundColor: '#ffedd1',
    marginLeft: 8,
    marginTop:-5
  }
};

export default CheckListOverlay;
