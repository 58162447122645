import React from 'react';
import {Image, Platform, StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { fonts } from '@mmt/legacy-commons/Styles/globalStyles';
import {getImageIconForZCTopStatusCard} from '../../../utils/SelfDriveBookingDetailUtil';
import ZoomCarConstants from '../../../utils/ZoomCarConstants';
import {isEmpty} from 'lodash';
import {CANCELLED_IMAGE,FAILED_IMAGE, JOURNEY_OVER_IMAGE, CONFIRMED_IMAGE, ALARM_IMAGE} from '../../../SelfDriveImageConstant';
import {ANDROID_BACK_ARROW,IOS_BACK_ARROW } from '../../../SelfDriveImageConstant';

class TopStatusCard extends React.Component {
  render() {
    this.colors = [this.props.card.colorBegin, this.props.card.colorEnd];
    this.iconImage = CANCELLED_IMAGE;
    let customStyle = [AtomicCss.marginBottom10, styles.mmtPromiseIcon];
    const status = getImageIconForZCTopStatusCard(this.props.card);
    if (status === ZoomCarConstants.CANCELED_IMAGE) {
      this.iconImage = CANCELLED_IMAGE;
    } else if (status === ZoomCarConstants.FAILED_IMAGE) {
      this.iconImage = FAILED_IMAGE;
    } else if (status === ZoomCarConstants.JOURNEY_OVER_IMAGE) {
      this.iconImage = JOURNEY_OVER_IMAGE;
      customStyle = [AtomicCss.marginBottom10, styles.mmtJourneyOverPromiseIcon];
    } else if (status === ZoomCarConstants.CONFIRMED_IMAGE) {
      this.iconImage = CONFIRMED_IMAGE;
    } else if (status === ZoomCarConstants.ALARM_IMAGE) {
      this.iconImage = ALARM_IMAGE;
      customStyle = [AtomicCss.marginBottom10, styles.mmtAlarmPromiseIcon];
    }
    return (
      <LinearGradient start={{x: 1.0, y: 0.0}} end={{x: 0.0, y: 1.0}} colors={this.colors} style={[styles.topStatusCard]} >
        <TouchableOpacity style={{padding: 14}} onPress={this.props.onBackPress}>
          <Image style={Platform.OS === 'ios' ? [AtomicCss.marginBottom10, styles.IOSbackarrow] : [AtomicCss.marginBottom10, styles.backarrow]} source={Platform.OS === 'ios' ? IOS_BACK_ARROW : ANDROID_BACK_ARROW} />
        </TouchableOpacity>
        <View>
          <Image style={customStyle} source={this.iconImage} />
        </View>
        <View style={styles.StatusCardWrapper}>
          <Text style={(status === ZoomCarConstants.CONFIRMED_IMAGE) ? ([styles.confirmedSubText]) : ([styles.StatusHeadingTxt])}>
            {this.props.card.header}
          </Text>
          {!isEmpty(this.props.card.descriptions[0]) && <Text style={[AtomicCss.font14, AtomicCss.defaultText, AtomicCss.marginBottom3, AtomicCss.textCenter]}>{this.props.card.descriptions[0]}</Text>}
          <View style={[styles.BookingWrapper, AtomicCss.marginBottom15]}>
            <Text style={styles.booingDtlsTxt}>BOOKING ID</Text>
            <Text style={styles.booingDtlsbold}>{this.props.responseJson.bookingId}</Text>
          </View>
        </View>

      </LinearGradient>
    );
  }
}
const styles = StyleSheet.create({
  topStatusCard: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingHorizontal: 20,
    position: 'relative',
    marginTop: -65
  },
  backarrow: {
    width: 16,
    height: 16,
    position: 'absolute'
  },
  IOSbackarrow: {
    width: 10,
    height: 16,
    position: 'absolute'

  },
  StatusCardWrapper: {
    paddingLeft: 50,
    paddingRight: 50
  },
  StatusHeadingTxt: {
    color: '#4a4a4a',
    textAlign: 'center',
    fontSize: 16,
    fontFamily: fonts.black,
    lineHeight: 24,
    marginBottom: 6,
    backgroundColor: 'transparent',
  },
  confirmedSubText : {
    textAlign: 'center',
    fontSize: 16,
    fontFamily: fonts.black,
    lineHeight: 24,
    marginBottom: 6,
    backgroundColor: 'transparent',
    color : '#1a7971'
  },
  mmtPromiseIcon: {
    width: 80,
    height: 56,
    alignSelf: 'center'
  },
  mmtAlarmPromiseIcon: {
    width: 40,
    height: 40,
    alignSelf: 'center'
  },
  mmtJourneyOverPromiseIcon: {
    width: 42,
    height: 46,
    alignSelf: 'center'
  },
  BookingWrapper: {
    flexDirection: 'row',
    alignSelf: 'center'
  },
  booingDtlsTxt: {
    color: '#000',
    fontSize: 12,
    fontFamily: fonts.regular,
    lineHeight: 14,
    marginRight: 10
  },
  booingDtlsbold: {
    color: '#000',
    fontSize: 12,
    fontFamily: fonts.bold,
    lineHeight: 14
  },
  buttonWrapper: {
    alignItems: 'center',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'center'
  }
});

export default TopStatusCard;
