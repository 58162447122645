import React from 'react';
import { View } from 'react-native';
import { Shimmer, PlaceholderContainerWrapper } from '../../../../../Common/ShimmerV2';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';

const ShimmerCard = ({ rows }) => {
  const getAnimRows = () => {
    let animRows = [];
    for (let iter = 0; iter < rows; iter++) {
      animRows.push(
        <View style={{ marginBottom: 12, backgroundColor: '#fff', padding: 16 }}>
          <View style={AtomicCss.flexRow} marginRight={12}>
            <Shimmer height={24} width={24} marginRight={12} style={{ borderRadius: 4 }} />
            <View>
              <Shimmer height={12} width={119} marginBottom={14} />
              <Shimmer height={12} width={227} marginBottom={11} />
            </View>
          </View>
        </View>,
      );
    }
    return animRows;
  };

  return (
    <PlaceholderContainerWrapper>
      <View>
        <Shimmer height={20} width={230} marginTop={8} marginBottom={12} />
        {getAnimRows()}
        <Shimmer height={44} width={'100%'} marginTop={8} marginBottom={12} />
      </View>
    </PlaceholderContainerWrapper>
  );
};

export default ShimmerCard;
