import React from "react";
import { ActionsContent, CardInfoSection, HeaderSection, MainContent, SummaryContainer, LostBookingPaymentStatus, DescriptionSection, StatusInfoSection } from "../cardElements";
import { getLostIDSummaryData } from "./lostIdCardAdapter";
import { LostIDSummaryData } from "../cardInterfaces";
import  isEmpty  from "lodash/isEmpty";

interface LostIDSummaryCardProps {
    response: LostIDSummaryData;
}

const LostIDSummaryCard = ({ response }: LostIDSummaryCardProps) => {
    const cardData = getLostIDSummaryData(response);
    const { iconUrl, icon, lobName, paymentTextLine1, paymentTextLine2, subTitle, openDetails, actions, infoCardStatusText, infoCardStatusCTA, handleInfoPress } = cardData || {};

    return (
        <SummaryContainer bookingId={response.bookingID}>
            <MainContent onPress={()=>openDetails()}>
                <DescriptionSection>
                    <CardInfoSection
                        name={lobName}
                        iconUrl={iconUrl}
                        icon={icon}
                    />
                    <HeaderSection name={subTitle} />
                    {!isEmpty(paymentTextLine1) && <LostBookingPaymentStatus paymentTextLine1={paymentTextLine1} paymentTextLine2={paymentTextLine2} />}
                    {!isEmpty(infoCardStatusText) && <StatusInfoSection statusText={infoCardStatusText} statusCta={infoCardStatusCTA} handleInfoPress={handleInfoPress} />}
                </DescriptionSection>
            </MainContent>
            <ActionsContent actions={actions} isInfoCardShown={!!infoCardStatusText || !!paymentTextLine1}/>
        </SummaryContainer>
    )
}

export default LostIDSummaryCard;
