import React, { useState, useCallback } from 'react';
import {
  View,
  Image,
  Text,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
  Platform,
} from 'react-native';
import { isEmpty } from 'lodash';
import Actions from '../../../../navigation/postSalesNavigation';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import NeedMoreHelpOverlay from '../../../../Common/NeedMoreHelpOverlay';
import { NEED_HELP_PAGE, NEED_MORE_HELP } from '../../../../PostSalesConstant';
import SpecialClaimTrackingHelper from '../../Helpers/SpecialClaimTrackingHelper';
import { resetToFlightBookingDetail } from '../../../FlightBookingUtil';

import {
  SM_BLUE_RT_ARROW,
  AIRLINE_PHONE_ICON,
  FALSE_CLAIM_ICON,
  QUESTION_MARK_ICON,
} from '../../../FlightImageConstants';
import { openDialer } from '../../../../Common/commonUtil';
import { getFont } from '../../../../PostSalesStyles';
import useHardwareBackpress from '../../../../Common/useHardwareBackpress';
import BaseButton, { BUTTON_VARIANT } from '../../../../Common/BaseButton';

const { fsStyle, ...fonts } = getFont(true);

const FalseClaim = ({
  heading,
  description,
  whatNextCard,
  actionCTA,
  descList,
  bookingId,
  submitproofCTA,
  extraProps,
}) => {
  const [helpingHandOverlay, setHelpingHandOverlay] = useState(true);
  const onBackPressHandler = () => {
    if (Platform.OS === 'web') {
      resetToFlightBookingDetail(bookingId);
    } else {
      let somethingPopped = false;
      try {
        somethingPopped = Actions.popTo('flightBookingDetail');
      } catch (e) {
        console.log('Error is ', e);
      }
      if (!somethingPopped) {
        resetToFlightBookingDetail(bookingId);
      }
    }
  };
  useHardwareBackpress().addListener(onBackPressHandler);

  const openClaimReview = useCallback(() => {
    SpecialClaimTrackingHelper.trackClickEvent(
      extraProps.trackingPageName,
      'MI_FLT_SClaim_PopUp_Proof_Review',
    );
    Actions.specialClaimReviewDetails({
      ...extraProps,
      responseJson: {
        ...extraProps.responseJson,
        specialClaimValidationData: {
          ...extraProps.responseJson.specialClaimValidationData,
          approvedStatusInfo:
            extraProps.responseJson.specialClaimValidationData.rejectedProofStatusInfo,
        },
      },
    });
  }, [extraProps]);

  return (
    <View style={styles.pageWrapper}>
      <ScrollView>
        <View style={styles.whiteCard}>
          <View style={styles.messageContent}>
            <Image style={styles.messageIcon} source={FALSE_CLAIM_ICON} />
            {!isEmpty(heading) && (
              <Text style={[styles.messageTitle, fsStyle.font20]}>{heading}</Text>
            )}
            {!isEmpty(descList) &&
              descList.map((text, index) => (
                <Text key={index} style={[styles.messageDesc, fsStyle.font14]}>
                  {text}
                </Text>
              ))}
            {isEmpty(descList) && !isEmpty(description) && (
              <Text style={[styles.messageDesc, fsStyle.font14]}>{description}</Text>
            )}
          </View>
        </View>
        {whatNextCard && (
          <View style={[styles.whatNextCard, styles.innerSection]}>
            <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom12]}>
              <Image style={styles.questionIcon} source={QUESTION_MARK_ICON} />
              {!isEmpty(whatNextCard.heading) && (
                <Text style={[styles.headingTxt, fsStyle.font18]}>{whatNextCard.heading}</Text>
              )}
            </View>
            <View style={styles.moreInfoWrapper}>
              {!isEmpty(whatNextCard.description) &&
                whatNextCard.description.map((desc) => (
                  <Text style={[styles.desc, fsStyle.font15]}>{desc}</Text>
                ))}
              {!isEmpty(whatNextCard.airlineContacts) &&
                whatNextCard.airlineContacts.map((airline, index) => (
                  <View
                    style={[
                      styles.contactRow,
                      index === Object.keys(whatNextCard.airlineContacts).length - 1
                        ? styles.borderBottom0
                        : '',
                    ]}
                  >
                    <Text
                      style={[
                        styles.defaultTxt,
                        fonts.blackFontFamily,
                        fsStyle.font14,
                        AtomicCss.marginBottom10,
                      ]}
                    >
                      {airline.airlineName}
                    </Text>
                    {!isEmpty(airline.contactNo) &&
                      airline.contactNo.map((contactNo) => (
                        <TouchableOpacity
                          onPress={() => openDialer(contactNo)}
                          style={[
                            AtomicCss.flexRow,
                            AtomicCss.spaceBetween,
                            AtomicCss.marginBottom14,
                          ]}
                        >
                          <Text style={[styles.defaultTxt, fonts.boldFontFamily, fsStyle.font14]}>
                            {contactNo}
                          </Text>
                          <Image style={styles.callIconStyle} source={AIRLINE_PHONE_ICON} />
                        </TouchableOpacity>
                      ))}
                  </View>
                ))}
              {!isEmpty(submitproofCTA) && (
                <TouchableOpacity
                  onPress={openClaimReview}
                  style={[styles.redirectLinkWrapper]}
                  activeOpacity={0.6}
                >
                  <View style={[AtomicCss.flex1]}>
                    {!!submitproofCTA.text && (
                      <Text style={[styles.titleStyle, fsStyle.font17]}>{submitproofCTA.text}</Text>
                    )}
                    {!!submitproofCTA.desc && (
                      <Text
                        style={[AtomicCss.darkGreyText, fonts.regularFontFamily, fsStyle.font12]}
                      >
                        {submitproofCTA.desc}
                      </Text>
                    )}
                  </View>
                  <Image style={styles.rtBlueArrowStyle} source={SM_BLUE_RT_ARROW} />
                </TouchableOpacity>
              )}
            </View>
          </View>
        )}
      </ScrollView>
      {actionCTA && actionCTA.actionId === 'BOOKING_DETAILS' && (
        <View style={styles.margin8}>
          <BaseButton
            text={actionCTA.text}
            variant={BUTTON_VARIANT.PRIMARY}
            clickHandler={onBackPressHandler}
          />
        </View>
      )}
      {helpingHandOverlay === true && (
        <NeedMoreHelpOverlay
          bookingId={bookingId || ''}
          handleBottomOverlay={() => setHelpingHandOverlay(!helpingHandOverlay)}
          handleReload={() => setHelpingHandOverlay(!helpingHandOverlay)}
          component={NEED_MORE_HELP.HELPING_HAND}
          pageName={NEED_HELP_PAGE.FLIGHT_FALSE_CLAIM}
          hideLoader={true}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  whiteCard: {
    backgroundColor: '#ffffff',
    elevation: 1,
    shadowColor: '#000000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 2,
    marginBottom: 10,
    padding: 16,
    paddingTop: 83,
    paddingBottom: 40,
  },
  whatNextCard: {
    backgroundColor: '#ffffff',
    elevation: 1,
    shadowColor: '#000000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 2,
    marginBottom: 10,
    padding: 16,
  },
  pageWrapper: {
    flex: 1,
    backgroundColor: '#f2f2f2',
  },
  margin8: { margin: 8 },
  paddingBottom40: { paddingBottom: 40 },
  messageContent: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 50,
  },
  messageIcon: {
    width: 52,
    height: 47,
    marginBottom: 40,
  },
  messageTitle: {
    fontFamily: fonts.black,
    color: colors.black28,
    marginBottom: 13,
    textAlign: 'center',
  },
  messageDesc: {
    fontFamily: fonts.regular,
    color: colors.defaultTextColor,
    textAlign: 'center',
    lineHeight: 20,
  },
  innerSection: {
    padding: 16,
    paddingBottom: 4,
  },
  questionIcon: {
    width: 20,
    height: 20,
    marginRight: 10,
  },
  headingTxt: {
    color: '#3c3c3c',
    fontFamily: fonts.bold,
  },
  moreInfoWrapper: {
    paddingLeft: 30,
  },
  desc: {
    color: colors.defaultTextColor,
    fontFamily: fonts.regular,
    lineHeight: 19,
  },
  defaultTxt: {
    color: colors.defaultTextColor,
  },

  callIconStyle: {
    width: 18,
    height: 18,
  },
  contactRow: {
    paddingVertical: 16,
    borderBottomWidth: 1,
    borderBottomColor: '#e7e7e7',
  },
  borderBottom0: {
    borderBottomWidth: 0,
    borderBottomColor: 'transparent',
  },
  redirectLinkWrapper: {
    borderTopColor: '#e1e7ee',
    borderTopWidth: 1,
    paddingVertical: 13,
    paddingHorizontal: 12,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titleStyle: {
    color: colors.azureBlue,
    fontFamily: fonts.bold,
    marginBottom: 5,
  },
  rtBlueArrowStyle: {
    width: 10,
    height: 15,
    position: 'absolute',
    right: 3,
    top: 18,
  },
});

export default FalseClaim;
