import Actions from '../navigation/postSalesNavigation';
import _ from 'lodash';
import { Platform, BackHandler, Linking } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Fecha from 'fecha';
import FlightConst, {
  DEEP_LINK_EVENT_NAME,
  FLIGHT_BOOKING_ID_INITIALS,
  FLIGHT_DOWNLOAD_FILES_THROTTLE_INTERVAL,
  FLIGHT_THROTTLE_OBJECT,
  RefNameConst,
  TopCardType,
} from './modules/details/FlightBookingDetailsConstant';
import { getPokusConfig } from '@mmt/legacy-commons/Native/AbConfig/AbConfigModule';
import { isNonEmpty } from '@mmt/legacy-commons/Common/utils/StringUtils';
import { isNotNullAndEmpty } from '../hotel/details/utils/HotelBookingDetailUtil';
import ViewControllerModule from '@mmt/legacy-commons/Native/ViewControllerModule';
import { getStaticData,ENGLISH } from '../staticData/staticData';
import CancellationTrackingHelper from './cancellation/Helpers/CancellationTrackingHelper';
import FlightBookingTrackingHelper from './modules/details/helper/FlightBookingTrackingHelper';
import { FLIGHT_CANCELLATION_TYPE, LOBNAMES } from '../PostSalesConstant';
import { BACK_ARROW_WHITE_ICON } from './FlightImageConstants';
import { CANCELLATION_REQUEST_TYPE, CLAIM_REQUEST_TYPE } from '../PostSalesConstant';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import {
  getCommonHeaders,
  FLIGHT_DOWNLOAD_TICKET,
  DOWNLOAD_AWS_DOCUMENT,
  FLIGHT_DOWNLOAD_CUSTOMER_CONFIRMATION,
} from '../utils/NetworkUtils';
import FlightBookingModule from '@mmt/legacy-commons/Native/FlightBookingModule';
import { isEnglishLangSelected } from '@mmt/legacy-commons/AppState/AppUserConfig';
import { showShortToast } from '@mmt/legacy-commons/Common/Components/Toast';
import { getTranslatedTextFor } from '../utils/PostSaleUtil';
import { PokusLobs } from '@mmt/legacy-commons/Native/AbConfig/AbConfigKeyMappings';
import { DEEP_LINK_PAGE } from '../PostSalesConstant';
import { getRootTag } from '@mmt/legacy-commons/AppState/RootTagHolder';
import GenericModule from 'packages/core/native/GenericModule';
import FlightBookingDetailsConstant from './modules/details/FlightBookingDetailsConstant';
import { setDataInStorage } from "../utils/localstorage";

export function resetToFlightBookingDetail(
  bookingId,
  refreshPage = true,
  refElment = '',
  scrollToSection = '',
) {
  if (Platform.OS === 'web') {
    FlightBookingModule.resetToFlightBookingDetail(bookingId);
  } else {
    Actions.flightBookingDetail({
      BOOKING_ID: bookingId,
      refresh: { isRefresh: refreshPage },
      refElm: refElment,
      scrollToSection,
    });
  }
}

const ancillaryApplicableMap = {
  [FlightConst.MEAL_TYPE]: 'mealApplicable',
  [FlightConst.SEAT_TYPE]: 'seatSelectionApplicable',
};

export const FLIGHT_DOWNLOAD_TRIGGERS = {
  E_TICKET: 'Flight_CustomerETicket',
  INVOICE: 'Flight_Customer_Invoice',
  BAGGAGE: 'Flight_BaggageTag',
  INSURANCE: 'InsuranceVoucher',
  BOARDING_PASS: 'BOARDING_PASS',
  FLIGHT_ETICKET_MYPARTNER: 'Flight_Eticket_MyPartner',
  DOWNLOAD_FAILED: 'DOWNLOAD_FAILED',
};

export function resetToHolidayBookingDetail(holidayData, isSeatPage) {
  if (isSeatPage) {
    Actions.holidayBookingDetail({ data: holidayData });
    return;
  }
  const somethingPoped = Actions.pop();
  if (somethingPoped != null && !somethingPoped) {
    if (Platform.OS === 'ios') {
      ViewControllerModule.popViewController(getRootTag());
    } else {
      BackHandler.exitApp();
    }
  }
}
export function getButtonText(bookingstate, ancillary, flightBookingResponse) {
  const {
    topCardText: {
      additionalText: {
        addToPassbookText,
        downloadETicketText,
        downloadInvoiceText,
        addSeatText,
        addMealText,
        addBaggageText,
        bookingConfirmationText,
      },
    },
  } = getStaticData();
  const button = {};
  switch (bookingstate.toUpperCase()) {
    case FlightConst.UPCOMING:
    case FlightConst.ONTRIP:
      button.text1 = Platform.OS === 'ios' ? addToPassbookText : downloadETicketText;
      button.action1 = Platform.OS === 'ios' ? FlightBookingDetailsConstant.ACTION_ADD_TO_PASSBOOK : FlightBookingDetailsConstant.ACTION_DOWNLOAD_ETICKET;
      if(flightBookingResponse.showDownloadETicket) {
        button.text1 = downloadETicketText;
        button.action1 = FlightBookingDetailsConstant.ACTION_DOWNLOAD_ETICKET;
        button.throttleInterval1 = FLIGHT_DOWNLOAD_FILES_THROTTLE_INTERVAL;
      }
      if (ancillary.seatApplicable) {
        button.text2 = addSeatText;
        button.action2 = addSeatText;
        button.throttleInterval2 = FLIGHT_THROTTLE_OBJECT.throttleInterval;
      } else if (ancillary.mealApplicale) {
        button.text2 = addMealText;
        button.action2 = addMealText;
        button.throttleInterval2 = FLIGHT_THROTTLE_OBJECT.throttleInterval;
      } else if (ancillary.baggageApplicable) {
        button.text2 = addBaggageText;
        button.action2 = addBaggageText;
        button.throttleInterval2 = FLIGHT_THROTTLE_OBJECT.throttleInterval;
      }
      if (flightBookingResponse && flightBookingResponse.myPartnerBooking) {
        button.text3 = bookingConfirmationText;
        button.action3 = bookingConfirmationText;
        button.throttleInterval3 = FLIGHT_THROTTLE_OBJECT.throttleInterval;
      }
      if (Platform.OS === 'ios' && !button.text2 && !button.text3 && flightBookingResponse.showDownloadETicket){
        button.text4 = downloadETicketText;
        button.action4 = FlightBookingDetailsConstant.ACTION_DOWNLOAD_ETICKET;
        button.throttleInterval4 = FLIGHT_DOWNLOAD_FILES_THROTTLE_INTERVAL;
      }
      // commenting previous code, logic for this also looks to be wrong, implementing new behaviour above
      // if(ancillary.seatApplicable || ancillary.mealApplicale || ancillary.baggageApplicable) {
      //   button.text2 = 'ADD ';
      //   button.action2 = FlightConst.ADD_ANCILLARY;
      //   if(ancillary.seatApplicable || ancillary.mealApplicale || ancillary.baggageApplicable){
      //     button.text2 += FlightConst.ANCILLARY;
      //   }
      //   else{
      //         if (ancillary.seatApplicable) {
      //           button.text2 += FlightConst.SEAT;
      //         }
      //         if (ancillary.mealApplicale) {
      //           if (!ancillary.seatApplicable) {
      //             button.text2 += FlightConst.MEAL;
      //           } else button.text2 += '/'+FlightConst.MEAL;
      //         }
      //         if (ancillary.baggageApplicable) {
      //           if (!ancillary.seatApplicable && !ancillary.mealApplicale)
      //             button.text2 += FlightConst.BAGGAGE;
      //           else button.text2 += '/'+ FlightConst.BAGGAGE;
      //         }
      //       }
      // }
      break;
    case FlightConst.COMPLETED:
      if (flightBookingResponse && flightBookingResponse.showDownloadInvoice) {
        button.text1 = downloadInvoiceText;
        button.action1 = downloadInvoiceText;
        button.throttleInterval1 = FLIGHT_DOWNLOAD_FILES_THROTTLE_INTERVAL;
      }
      if (flightBookingResponse && flightBookingResponse.myPartnerBooking) {
        button.text2 = downloadETicketText;
        button.action2 = downloadETicketText;
        button.throttleInterval2 = FLIGHT_DOWNLOAD_FILES_THROTTLE_INTERVAL;

        button.text3 = bookingConfirmationText;
        button.action3 = bookingConfirmationText;
        button.throttleInterval3 = FLIGHT_THROTTLE_OBJECT.throttleInterval;
      }
      break;
  }
  return button;
}

export function shouldShowChangeInPlanCard(flightBookingResponse) {
  return (
    getPokusConfig('OTHERS', 'showChangeInPlanCard', false) &&
    ((flightBookingResponse.cancelCardDetailInfo &&
      flightBookingResponse.cancelCardDetailInfo.actionItemInfoList &&
      flightBookingResponse.cancelCardDetailInfo.actionItemInfoList.lenght > 0) ||
      (flightBookingResponse.specialClaimDataList &&
        flightBookingResponse.specialClaimDataList.length > 0) ||
      flightBookingResponse.datechangeCacheDetail)
  );
}

export function getTravelDate(trvlDateTime) {
  try {
    const travelDate = trvlDateTime.split('T')[0];
    const mIndex = parseInt(travelDate.split('-')[1], 10);
    const month = getMonthNames()[mIndex];
    const day = parseInt(travelDate.split('-')[2]);
    const year = parseInt(travelDate.split('-')[0]) % 100;
    return `${day} ${month.label}` + `'${year}`;
  } catch (e) {
    return Fecha.format(new Date(trvlDateTime), "ddd DD MMM'YY");
  }
}
export function getTravelDateTime(trvlTime) {
  try {
    const travelDate = trvlTime.split('T')[0];
    const mIndex = parseInt(travelDate.split('-')[1], 10);
    const month = getMonthNames()[mIndex];
    const day = parseInt(travelDate.split('-')[2]);
    return `${getTravelTime(trvlTime)}, ` + `${day} ${month.label}`;
  } catch (e) {
    return Fecha.format(new Date(trvlTime), 'HH:mm, DD MMM');
  }
}

export function getEmailId(contactDetails) {
  for (let i = 0; i < contactDetails.length; i++) {
    if (contactDetails[i].contactType === 0) {
      return contactDetails[i].contactValue;
    }
  }
}


export const getMonthNames = () => [
  { value: '0', label: 'Jan' },
  { value: '1', label: 'Jan' },
  { value: '2', label: 'Feb' },
  { value: '3', label: 'Mar' },
  { value: '4', label: 'Apr' },
  { value: '5', label: 'May' },
  { value: '6', label: 'Jun' },
  { value: '7', label: 'Jul' },
  { value: '8', label: 'Aug' },
  { value: '9', label: 'Sep' },
  { value: '10', label: 'Oct' },
  { value: '11', label: 'Nov' },
  { value: '12', label: 'Dec' },
];

export function getDurationinHourMinutes(duration) {
  const hours = Math.floor(duration / 60);
  const minutes = duration - hours * 60;
  if (minutes > 0) {
    if (hours > 0) {
      return `${hours}h ${minutes}m`;
    }
    return `${minutes}m`;
  }
  return `${hours}h `;
}
export function toCamelCase(str) {
  return str
    .toLowerCase()
    .replace(/(?:(^.))/g, (match) => match.charAt(match.length - 1).toUpperCase());
}

export function isSeatApplicable(segmentAncillaryDetails, segmentLineNo) {
  const SeatApplicableAncillary =
    segmentAncillaryDetails &&
    segmentAncillaryDetails.filter(
      (segment) =>
        segment.segmentLineNo === segmentLineNo &&
        segment.seatSelectionApplicable &&
        segment.webCheckInDescription !== 'ALL_CHECKED_IN',
    );
  if (SeatApplicableAncillary && SeatApplicableAncillary.length > 0) {
    return true;
  }
  return false;
}
export function isMealApplicable(segmentAncillaryDetails, segmentLineNo) {
  const MealApplicableAncillary =
    segmentAncillaryDetails &&
    segmentAncillaryDetails.filter(
      (segment) =>
        segment.segmentLineNo === segmentLineNo &&
        segment.mealApplicable &&
        checkForThisJourney(segment) &&
        segment.webCheckInDescription !== 'ALL_CHECKED_IN',
    );
  return !!(MealApplicableAncillary && MealApplicableAncillary.length > 0);
}
export function isBaggageApplicable(segmentAncillaryDetails, segmentLineNo) {
  const BaggageApplicableAncillary =
    segmentAncillaryDetails &&
    segmentAncillaryDetails.filter(
      (segment) =>
        segment.segmentLineNo === segmentLineNo &&
        segment.baggageApplicable &&
        segment.webCheckInDescription !== 'ALL_CHECKED_IN',
    );
  if (BaggageApplicableAncillary && BaggageApplicableAncillary.length > 0) {
    return true;
  }
  return false;
}
export function checkForThisJourney(segment) {
  let bFlag = false;
  const { paxAncillaryDetails } = segment || {};
  !_.isEmpty(paxAncillaryDetails) &&
    paxAncillaryDetails.map((paxAncillaryDetail) => {
      bFlag = !_.isEmpty(paxAncillaryDetail.ancillaryDetails)
        ? paxAncillaryDetail.ancillaryDetails.filter(
            (ancillaryDetails) => ancillaryDetails.ancillaryType === FlightConst.MEAL_TYPE,
          ).length > 0
        : bFlag;
    });
  return bFlag;
}
export function showAirlineContact(response) {
  if (response.airLineNameContacts && response.airLineNameContacts.length > 0) {
    const showAirline = response.airLineNameContacts.find((airlineData) =>
      isNonEmpty(airlineData.airLineContactNo),
    );
    return !!showAirline;
  }
  return false;
}
export function getTravelClass(travelClass) {
  switch (travelClass) {
    case 1:
      return FlightConst.ECONOMY;
    case 2:
      return FlightConst.BUSINESS;
    case 3:
      return FlightConst.FIRST_CLASS;
    case 4:
      return FlightConst.PREMIUM_ECONOMY;
    default:
      return '';
  }
}
export function getPrimaryContactNo(contactDetails) {
  if (isNotNullAndEmpty(contactDetails)) {
    for (let i = 0; i < contactDetails.length; i += 1) {
      if (contactDetails[i].contactType === 2) {
        return contactDetails[i].contactValue;
      }
    }
  }
  return undefined;
}
export function getScCardHeader(messageAttributes) {
  const { claimARefundText } = getStaticData();
  let header = claimARefundText;
  if (_.isEmpty(messageAttributes) || _.isEmpty(messageAttributes.SC_CARD_HEADER)) {
    return header;
  }
  return messageAttributes.SC_CARD_HEADER;
}
//This method is used in case API gives error and we need to show something specific to Gcc booking
export const isGccFlightBooking = (bookingId) => {
  return !!(
    bookingId &&
    (bookingId.indexOf(FLIGHT_BOOKING_ID_INITIALS.UAE) === 0 ||
      bookingId.indexOf(FLIGHT_BOOKING_ID_INITIALS.US) === 0 ||
      bookingId.indexOf(FLIGHT_BOOKING_ID_INITIALS.KN) === 0 ||
      bookingId.indexOf(FLIGHT_BOOKING_ID_INITIALS.ON) === 0)
  );
};

//This method is used in case API gives error and we need to show something specific to KSA booking
export const isKsaFlightBooking = (bookingId) => {
  return !!(bookingId && bookingId.indexOf(FLIGHT_BOOKING_ID_INITIALS.KSA) === 0);
};

export const externalLinkHandler = (action, title, iosURL) => {
  let externalUrl = action.url;
  if (Platform.OS === 'ios' && iosURL) {
    externalUrl = iosURL;
  }
  if (externalUrl) {
    if (action.openInWebView) {
      Actions.openPsWebView({
        headerText: action.webViewTitle || title,
        headerIcon: BACK_ARROW_WHITE_ICON,
        url: externalUrl,
      });
    } else {
      Linking.openURL(externalUrl);
    }
  }
};

export const isFareLockBooking = (topCardType) => {
  switch (topCardType) {
    case TopCardType.FARE_LOCK_HOLD:
    case TopCardType.FARE_LOCK_EXPIRED:
    case TopCardType.FARE_LOCK_FAILED:
    case TopCardType.FARE_LOCK_CANCELLED_BY_AIRLINE:
    case TopCardType.SEAT_NOT_AVAILABLE:
      return true;
    default:
      return false;
  }
};

export const isHoldBooking = (topCardType) => {
  switch (topCardType) {
    case TopCardType.TEMPORARY_BOOKING:
    case TopCardType.TEMPORARY_BOOKING_FAILED:
    case TopCardType.TEMPORARY_BOOKING_EXPIRED:
    case TopCardType.TEMPORARY_BOOKING_CANCELLED:
      return true;
    default:
      return false;
  }
};

export const isFareLockExpired = (topCardType) =>{
  return topCardType === TopCardType.FARE_LOCK_EXPIRED;
};

export const openFlightCancellationThankYouPage = (
  cancellationRequest,
  trackingPageName,
  refundModeReason,
  instantData,
  trackingBundleData,
  segmentWiseInfo,
  isCorporateBooking,
) => {
  CancellationTrackingHelper.trackClickEvent(
    trackingPageName,
    'confirmpopup_yes',
    trackingBundleData,
  );
  const cancellationRequestData = cancellationRequest;
  if (refundModeReason) {
    cancellationRequestData.refundOption = refundModeReason;
  }
  if (instantData) {
    cancellationRequestData.instantData = instantData;
  }
  Actions.flightCancellationThankyou({
    cancellationRequest: cancellationRequestData,
    trackingBundle: trackingBundleData,
    segmentWiseInfo,
    isCorporateBooking: isCorporateBooking,
  });
};

export const addNewLineInMiddle = (string) => {
  let splitArr = string.split(' ');
  const middleOfString = parseInt(splitArr.length / 2);
  if (middleOfString) {
    splitArr.splice(middleOfString, 0, '\n');
  }
  return splitArr.join(' ');
};

export const getPaxFareSegmentList = (responseJson, specialClaimData) => {
  const paxFareSegmentList = [];
  responseJson.flightDetails.segmentGroupDetailList.forEach((segmentGroupDetail) => {
    segmentGroupDetail.segmentDetails.forEach((segmentDetail) => {
      segmentDetail.segmentPassengerDetail.forEach((segmentPassengerDetail) => {
        if (!segmentPassengerDetail.isPaxCancelled) {
          const paxFareSegment = {};
          paxFareSegment.reason = specialClaimData.scCode;
          paxFareSegment.paxFareSegmentId = segmentPassengerDetail.paxFareSegmentId;
          paxFareSegmentList.push(paxFareSegment);
        }
      });
    });
  });
  return paxFareSegmentList;
};
export const cancellationPolicyBtnClick = (action, responseJson) => {
  switch (action) {
    case 'CANCEL_FLT_FULL':
      const data = {};
      data.bookingId = responseJson.bookingID;
      data.flightCancellationType = FLIGHT_CANCELLATION_TYPE.FULL_CANCELLATION;
      Actions.flightCancellationPage({
        data: JSON.stringify(data),
        bookingId: responseJson.bookingID,
        isCorporateBooking: responseJson.isCorporateBooking,
      });
      FlightBookingTrackingHelper.trackClickEvent(
        'Details_CancelPolicy',
        'Calculate_Refund',
        responseJson,
      );
      break;
    case 'ACT_RES_FLIGHT':
      Actions.flightRescheduleFlightsPage({
        flightDateChangeData: JSON.stringify(responseJson),
      });
      FlightBookingTrackingHelper.trackClickEvent(
        'Details_DateChangePolicy',
        'Continue',
        responseJson,
      );
      break;
    default:
      break;
  }
};

export const setFlightAddonItem = async (val) => {
  try {
    await setDataInStorage('addon', JSON.stringify(val));
  } catch (error) {
    console.error('error in async storage');
  }
};

export const getFlightCancellationRequestNo = (cancellationDetailList) => {
  const cancellationRequestNoList = [];
  if (isNotNullAndEmpty(cancellationDetailList)) {
    cancellationDetailList.forEach((cancellationDetail) => {
      if (
        (cancellationDetail.requestType === CANCELLATION_REQUEST_TYPE ||
          cancellationDetail.requestType === CLAIM_REQUEST_TYPE) &&
        !cancellationDetail.blockCallRefundApi
      ) {
        cancellationRequestNoList.push(cancellationDetail.requestNo);
      }
    });
  }
  return cancellationRequestNoList;
};

const getDownloadURL = (triggerName) => {
  switch (triggerName) {
    case FLIGHT_DOWNLOAD_TRIGGERS.E_TICKET:
    case FLIGHT_DOWNLOAD_TRIGGERS.INVOICE:
    case FLIGHT_DOWNLOAD_TRIGGERS.FLIGHT_ETICKET_MYPARTNER:
      return FLIGHT_DOWNLOAD_TICKET;
    // case FLIGHT_DOWNLOAD_TRIGGERS.BAGGAGE:
    //     return FLIGHT_DOWNLOAD_BAGGAGE_TAG;
    case FLIGHT_DOWNLOAD_TRIGGERS.BOARDING_PASS:
    case FLIGHT_DOWNLOAD_TRIGGERS.INSURANCE:
      return DOWNLOAD_AWS_DOCUMENT;
    default:
      return '';
  }
};

const _getDownloadFileName = (val, bookingId) => {
  let documentType = '';
  switch (val) {
    case FLIGHT_DOWNLOAD_TRIGGERS.E_TICKET:
      documentType = 'ETicket';
      break;
    case FLIGHT_DOWNLOAD_TRIGGERS.INVOICE:
      documentType = 'Invoice';
      break;
    case FLIGHT_DOWNLOAD_TRIGGERS.INSURANCE:
      documentType = 'Insurance';
      break;
    case FLIGHT_DOWNLOAD_TRIGGERS.BOARDING_PASS:
      documentType = 'Boarding_Pass';
      break;
    default:
      break;
  }
  return bookingId + '_' + documentType + '.pdf';
};

const getDownloadText = (val) => {
  const {
    downloadETicketText,
    downloadInvoiceText,
    downloadFailedText,
    downloadFileText,
    downloadCustomerBookingInvoice
  } = getStaticData();
  switch (val) {
    case FLIGHT_DOWNLOAD_TRIGGERS.E_TICKET:
      return downloadETicketText;
    case FLIGHT_DOWNLOAD_TRIGGERS.INVOICE:
      return downloadInvoiceText;
    case FLIGHT_DOWNLOAD_TRIGGERS.BOARDING_PASS:
    case FLIGHT_DOWNLOAD_TRIGGERS.INSURANCE:
      return downloadFileText;
    case FLIGHT_DOWNLOAD_TRIGGERS.DOWNLOAD_FAILED:
      return downloadFailedText;
    case FLIGHT_DOWNLOAD_TRIGGERS.FLIGHT_ETICKET_MYPARTNER:
      return downloadCustomerBookingInvoice;
    default:
      return '';
  }
};

const downloadRquest = async (requestBody, headers, { triggerName, bookingId } = {}) => {
  try {
    const fileName = _getDownloadFileName(triggerName, bookingId);
    showShortToast(getDownloadText(triggerName));
    const response = await fetch(getDownloadURL(triggerName), {
      method: 'POST',
      headers,
      body: JSON.stringify(requestBody),
    });

    if (response.ok) {
      const responseBody = await response.json();
      if (
        responseBody &&
        (responseBody.success || responseBody.status) &&
        (responseBody.pdfBytes || responseBody.fileContent)
      ) {
        const fileData = responseBody.pdfBytes || responseBody.fileContent;
        if (Platform.OS === 'ios')
          await GenericModule.openPDFViewer(fileName,fileData);
        else {
          await FlightBookingModule.saveTicket(fileData, fileName);
          const { openFileText } = getStaticData();
          FlightBookingModule.showTicketDownloadedNotification({
            title: fileName,
            description: openFileText,
            fileName,
          });
        }
      } else {
        showShortToast(getDownloadText(FLIGHT_DOWNLOAD_TRIGGERS.DOWNLOAD_FAILED));
      }
    } else {
      showShortToast(getDownloadText(FLIGHT_DOWNLOAD_TRIGGERS.DOWNLOAD_FAILED));
    }
  } catch (error) {
    showShortToast(getDownloadText(FLIGHT_DOWNLOAD_TRIGGERS.DOWNLOAD_FAILED));
  }
};

export const flightDownloadTicket = async (triggerName, bookingId) => {
  if (triggerName && bookingId) {
    const requestBody = {
      sendEticket:
        triggerName === FLIGHT_DOWNLOAD_TRIGGERS.E_TICKET ||
        FLIGHT_DOWNLOAD_TRIGGERS.FLIGHT_ETICKET_MYPARTNER
          ? true
          : false,
      sendInvoice: triggerName === FLIGHT_DOWNLOAD_TRIGGERS.INVOICE,
      userId: Platform.OS.toUpperCase(),
      triggerName,
      bookingId,
    };
    const userInfo = await HotelBookingModule.getRequestHeader(bookingId, 'FLIGHT_DOWNLOAD_PDF');
    const headers = await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo);
    downloadRquest(requestBody, headers, { triggerName, bookingId });
    !isEnglishLangSelected() &&
      downloadRquest(
        requestBody,
        { ...headers, psLob: LOBNAMES.POST_SALES, langAware: false },
        { triggerName, bookingId },
      );
  }
};

export const flightDownloadCustomerTicket = async (triggerName, bookingId, markup, isCancellationPolicySelected, markupDetails, markupselected) => {
  if (triggerName && bookingId) {
    const requestBody = {
      sendEticket: triggerName === FLIGHT_DOWNLOAD_TRIGGERS.E_TICKET || triggerName === FLIGHT_DOWNLOAD_TRIGGERS.FLIGHT_ETICKET_MYPARTNER ? true : false,
      sendInvoice: triggerName === FLIGHT_DOWNLOAD_TRIGGERS.INVOICE,
      userId: 'PWA',
      triggerName: triggerName,
      bookingId: bookingId,
      markupAmount: markup ? markup : 0,
      isCancellationPolicyAdded: isCancellationPolicySelected,
      markupInfo: markupDetails ? markupDetails : {},
      isServiceFeesSelected: markupselected 
    };

    try {
      const fileName = _getDownloadFileName(triggerName, bookingId);
      showShortToast(getDownloadText(triggerName));

      const userInfo = await FlightBookingModule.getRequestHeader(bookingId, FlightBookingDetailsConstant?.FLIGHT_DOWNLOAD_PDF);
      const { mmtAuth, loggingTrackingInfo } = userInfo || {};
      const headers = await getCommonHeaders(mmtAuth, loggingTrackingInfo, { langAware: isEnglishLangSelected() });
      const response = await fetch(FLIGHT_DOWNLOAD_CUSTOMER_CONFIRMATION, {
        method: 'POST',
        headers,
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        const responseBody = await response.json();
        if (
          responseBody &&
          (responseBody.success || responseBody.status) &&
          (responseBody.pdfBytes || responseBody.fileContent)
        ) {
          if(triggerName == FLIGHT_DOWNLOAD_TRIGGERS.FLIGHT_ETICKET_MYPARTNER) {            
            showShortToast(getStaticData().downloadCustomerBookingInvoiceSuccess);
          }
          const fileData = responseBody.pdfBytes || responseBody.fileContent;
          await FlightBookingModule.saveTicket(fileData, fileName);
        } else {
          showShortToast(getDownloadText(FLIGHT_DOWNLOAD_TRIGGERS.DOWNLOAD_FAILED));
        }
      } else {
        showShortToast(getDownloadText(FLIGHT_DOWNLOAD_TRIGGERS.DOWNLOAD_FAILED));
      }
    } catch (error) {
      showShortToast(getDownloadText(FLIGHT_DOWNLOAD_TRIGGERS.DOWNLOAD_FAILED));
    }
  }
};

export const downloadAwsDocument = async (awskey, bookingId, triggerName) => {
  const requestBody = {
    awskey,
    bookingId,
    sendEticket: false,
    sendInvoice: false,
  };
  const userInfo = await HotelBookingModule.getRequestHeader(bookingId, 'DOWNLOAD_AWS_DOCUMENT');
  const headers = await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo);

  downloadRquest(requestBody, headers, { triggerName, bookingId });
};

export function getPerNightText() {
  const configValue = getPokusConfig(PokusLobs.HOTEL, 'htl_pricing_experiment_name', '');
  const { perNightText, totalPriceText } = getStaticData(true);

  switch (configValue) {
    case 'PN':
    case 'PNT':
    case 'PRN':
    case 'PRNT':
      return perNightText;
    case 'TP':
    case 'TPT':
      return totalPriceText;
    default:
      return null;
  }
}

export const getRefNamefromDeepLink = (deepLinkId) => {
  const refName = {
    [DEEP_LINK_PAGE.FLIGHT_DATE_CHANGE]: RefNameConst.FLIGHT_DATE_CHANGE_REF,
    [DEEP_LINK_PAGE.FLIGHT_CANCELLATION]: RefNameConst.FLIGHT_CANCELLATION_REF,
    [DEEP_LINK_PAGE.FLIGHT_WEB_CHECK_IN]: RefNameConst.FLIGHT_WEB_CHECKIN_REF,
  };
  return refName[deepLinkId];
};

export const getEventNameFromDeepLink = (deepLinkId) => {
  const eventName = {
    [DEEP_LINK_PAGE.FLIGHT_DATE_CHANGE]: DEEP_LINK_EVENT_NAME.FLIGHT_DATE_CHANE_CTA,
    [DEEP_LINK_PAGE.FLIGHT_CANCELLATION]: DEEP_LINK_EVENT_NAME.FLIGHT_CANCELLATION_CTA,
    [DEEP_LINK_PAGE.FLIGHT_WEB_CHECK_IN]: DEEP_LINK_EVENT_NAME.FLIGHT_WEB_CHECKIN_CTA,
  };
  return eventName[deepLinkId];
};

export const getSegmentAncillaryMessageObj = (segmentAncillaryDetail) => {
  return (
    Array.isArray(segmentAncillaryDetail) &&
    segmentAncillaryDetail.reduce((prev, curr) => {
      const {
        segmentAncillaryGenericMessage: mealMessage = '',
        seatSegmentErrorMessage: seatMessage = '',
      } = curr || {};
      return {
        ...prev,
        [curr.segmentLineNo]: [mealMessage, seatMessage],
      };
    }, {})
  );
};
