import React, {useState, useEffect} from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import styles from './ReqstScheduleBottomInfoCardCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { CALLBACK_DETAILS_URL } from '../../../utils/NetworkUtils';
import { NEED_MORE_HELP, ViewState, CALLBACK } from '../../../PostSalesConstant';
import Timer from '@mmt/legacy-commons/Common/Components/Timer';
import NeedMoreHelpOverlay from '../index';
import { usePostRequestApi } from '../../PostRequestApi';
import closeIcon from '@mmt/legacy-assets/src/close_grey.webp';

const ReqstScheduleBottomInfoCard = (props) => {
    const trackPage = "helpinghand_scheduledcallback";
    const [retry, setRetry] = useState(0);

    const request = {
      bookingId: props.bookingId,
      pageName: props.pageName || ''
    };

    const [viewState, response] = usePostRequestApi(CALLBACK_DETAILS_URL, request, props.bookingId, 'CHECK_CALLBACK', retry);

    useEffect(() => {
      if(response && response.success) {
        props.trackEvent && props.trackEvent('load', trackPage);
      }
    }, [response]);

    if (viewState === ViewState.ERROR) {
      props.handleCallbackBottomSheet();
      return null;
    }

    if (viewState === ViewState.LOADING) {
      return null;
    }

    if (viewState === ViewState.LOADED && response && response.layoutData) {
        const timerEnd = props.handleTime;
        return (
            <View style={styles.OverlayScheduleWrapper}>
              <TouchableOpacity style={styles.close} onPress={props.handleCallbackBottomSheet}>
                <Image source={closeIcon} />
              </TouchableOpacity>
              <Text style={[styles.headingTxt,AtomicCss.marginBottom10]}>{response.layoutData.headerText}</Text>
              {response.layoutData.subHeaderText && <Text style={[AtomicCss.defaultText, AtomicCss.font14, AtomicCss.regularFont, AtomicCss.marginBottom10]}>{response.layoutData.subHeaderText}</Text>}
              {response.layoutData.timestamp && ((response.layoutData.timestamp - new Date().getTime()) > 0) && <Timer
                  flipToHHMMSS
                  timeInMillis={response.layoutData.timestamp - new Date().getTime()}
                  timerTextStyle={[AtomicCss.defaultText, AtomicCss.font14, AtomicCss.regularFont, AtomicCss.marginBottom10]}
                  onEnd={timerEnd}
              />
              }
            </View>
        );
    }

    if (viewState === ViewState.LOADED && response && response.layoutData && response.currentStatus === 2 && response.isScheduledPastTime) {
        return (
          <NeedMoreHelpOverlay
            handleBottomOverlay={props.handleCallbackBottomSheet}
            component={NEED_MORE_HELP.REQUEST_ERROR}
            layoutData={response.layoutData}
            bookingId={props.bookingId}
            pageName={props.pageName}
            callDrivers={props.callDrivers}
          />
        );
    }

    return null;
}

export default ReqstScheduleBottomInfoCard;
