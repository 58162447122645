import React from 'react';
import { View, Text, Image, Platform, Linking } from 'react-native';
import PropTypes from 'prop-types';
import Actions from '../../../../../navigation/postSalesNavigation';
import HTMLView from 'react-native-htmlview';
import styles from './importantInfoCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import FlightBookingModule from '@mmt/legacy-commons/Native/FlightBookingModule';
import { YELLOW_INFO_ICON, BACK_WHITE_ICON } from '../../../../FlightImageConstants';
import { isPhoneNumUri } from '../../../../../utils/PostSaleUtil';

import { getStaticData } from '../../../../../staticData/staticData';
import { getFont } from '../../../../../PostSalesStyles';

class ImportantInformation extends React.Component {
  appendPlatformInfo = (url, userInfo) => {
    const { loggingTrackingInfo } = userInfo || {};
    const { appVersion } = loggingTrackingInfo ? JSON.parse(loggingTrackingInfo) : {};
    const platformParam = `trackPlatform=${Platform.OS}app`;
    const appVersionParam = `appVersion=${appVersion}`;
    url += `${url.indexOf('?') === -1 ? '?' : '&'}${platformParam}&${appVersionParam}`;
    return url;
  };

  updateURL = (url, userInfo) => {
    url = this.appendPlatformInfo(url, userInfo);
    return url;
  };

  linkPressHandler = async (url) => {
    if (isPhoneNumUri(url)) {
      Linking.openURL(url);
      return;
    }
    const { informationText } = getStaticData();
    const { bookingId } = this.props;
    const userInfo = await FlightBookingModule.getRequestHeader(
      bookingId,
      'IMPORTANT_INFO_LINK_CLICK',
    );
    Actions.openPsWebView({
      headerText: informationText,
      headerIcon: BACK_WHITE_ICON,
      url: this.updateURL(url, userInfo),
    });
  };

  render() {
    const { fsStyle, ...fonts } = getFont(true);
    return (
      <View style={[styles.infoCardWrapper]}>
        <View style={styles.infoCardHading}>
          <Image style={styles.infoIcon} source={YELLOW_INFO_ICON} />
          <Text
            style={[styles.headingTxt, AtomicCss.alignLeft, fonts.boldFontFamily, fsStyle.font16]}
          >
            {this.props.title}
          </Text>
        </View>
        {this.props.infoList.map((item) => (
          <View style={[AtomicCss.marginBottom10]}>
            <View style={AtomicCss.flexRow}>
              <View style={styles.bulletCircle} />
              <Text style={[styles.infoCardDisc, fonts.boldFontFamily, fsStyle.font14]}>
                {item.header}
              </Text>
            </View>
            <View style={[{ marginLeft: 25 }, AtomicCss.alignLeft]}>
              <HTMLView
                value={item.htmlSubText}
                onLinkPress={(url) => this.linkPressHandler(url)}
              />
            </View>
          </View>
        ))}
      </View>
    );
  }
}
export default ImportantInformation;

ImportantInformation.propTypes = {
  infoList: PropTypes.array.isRequired,
};
