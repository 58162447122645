import React from 'react';
import { View, Image, Text, TouchableOpacity } from 'react-native';
import { getFont } from '../../../../PostSalesStyles';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { TG_ICON } from '../../../Utils/RailImageConstant';
import Actions from '../../../../navigation/postSalesNavigation';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import { getImagePath } from 'apps/post-sales/src/utils/PostSaleUtil';
import WhiteCardWrapper from 'apps/post-sales/src/Common/WhiteCardWrapper';
import { REFUND_OOT_TRACKING } from 'apps/post-sales/src/Common/RefundOutOfTATForm/constants';
import RefundTracker from 'apps/post-sales/src/Common/RefundTracker';
import { LOBNAMES } from 'apps/post-sales/src/PostSalesConstant';
import { createStyles } from './railTGSCardCss';
import { CancellationDetail, CancellationReason, CardList, RefundTrackerCardProps, Train } from '../../../types';
import { TGS_ACTIONS } from '../../constants';
import isEmpty from 'lodash/isEmpty';
import { getCancellationDetailInfo, setWishingToTravelWithRAC } from '../../RailBookingDetailsUtil';
import { RAIL_TRACKING_CONSTANT } from '../../RailBookingDetailConstant';
import { removeDataFromStorage } from '../../../../utils/localstorage';

const rightArrow = getImagePath('rightArrow.webp');


const RefundTrackerCard: React.FC<RefundTrackerCardProps> = ({ response, card, bookingId }) => {
  const {cancelDetailNoList} : { cancelDetailNoList: string[]} = getCancellationDetailInfo(response);
  return (
    <RefundTracker
      key="RefundTrackerTGS"
      cancelRequests={cancelDetailNoList}
      bookingId={bookingId}
      card={card}
      bookingResponse={response}
      lobName={LOBNAMES.RAIL}
      isLostBookingId={false}
      pageName={REFUND_OOT_TRACKING.PAGES.RAIL}
      isRoundedCornerAndPadding={false}
      skipCardMargin={true}
      cardStyle={{
        marginTop: -20
      }}
    />
  );
};



interface RailTGSCardProps {
  cardData: {
    title: string;
    desc: string;
    subdesc: string;
    cardInfoText: string;
    showRefundCard: boolean;
    altervativeDesc: string;
    altervativeSubDesc: string
  };
  response: {
    cancellationDetailList: CancellationDetail[];
    train: Train[];
    tgsCancellationReasonList: CancellationReason[];
    bookingID: string;
  };
  card: CardList;
  bookingId: string;
  travellingWithRAC: boolean;
  travelWithRACClickHandler: () => void
  omnitureTrackHandler : (event: string) => void
}

const RailTGSCard: React.FC<RailTGSCardProps> = ({ cardData, response, card, bookingId, travellingWithRAC, travelWithRACClickHandler, omnitureTrackHandler }) => {
  const { title, desc, subdesc, showRefundCard, cardInfoText } = cardData || {};
  const { actionList } = card || {};
  const fonts = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);

  const getAppropriateHandler = (type: string) => {
    switch (type) {
      case TGS_ACTIONS.TRAVEL_WITH_RAC:
        if(!isEmpty(response) && !isEmpty(response.tgsCancellationReasonList)){
          const cancellationReason = response.tgsCancellationReasonList[0];
          const {reason} = cancellationReason || {};
          omnitureTrackHandler(RAIL_TRACKING_CONSTANT.RAC_TRAVEL);
          Actions.reviewTGRefundMode({
            bookingDetails: response,
            isFullCancellation: true,
            actionKey: type,
            bookingID: response.bookingID,
            reason: reason,
            isTGPremiumRefundCase: true
          });
        }
        break;
      case TGS_ACTIONS.TRAVEL_WITH_RAC_NOREFUND:
        omnitureTrackHandler(RAIL_TRACKING_CONSTANT.RAC_TRAVEL);
        setWishingToTravelWithRAC(bookingId, travelWithRACClickHandler);
        break;
      case TGS_ACTIONS.CANCEL_BOOKING_TGREFUND:
      case TGS_ACTIONS.CANCEL_BOOKING_3X:
        omnitureTrackHandler(RAIL_TRACKING_CONSTANT.RAC_CANCEL);
        if(!isEmpty(response) && !isEmpty(response.tgsCancellationReasonList)){
          const cancellationReason = response.tgsCancellationReasonList[0];
          const {reason} = cancellationReason || {};
          Actions.reviewTGCharges({
            bookingDetails: response,
            actionKey: type,
            reason: reason,
            isFullCancellation: true
          });
        }
        break;
      default:
        break;
    }
  };

  return (
    <View>
      <View style={[styles.topSection, AtomicCss.marginBottom6]}>
        <View style={styles.iconWrapper}>
          <Image style={styles.tripGuaranty} source={TG_ICON} />
        </View>
        <View style={[AtomicCss.flex1]}>
          { !isEmpty(title) && <Text
            style={[
              fonts.blackFontFamily,
              AtomicCss.blackText,
              fonts.font16,
              AtomicCss.marginBottom10,
            ]}>
            {title}
          </Text>}
          { !isEmpty(desc) && <Text
            style={[
              AtomicCss.defaultText,
              fonts.boldFontFamily,
              fonts.font14,
            ]}>
            {desc}
          </Text>}
          {!isEmpty(cardInfoText) &&
          <Text
            style={[
              fonts.semiBoldFontFamily,
              fonts.font14,
              AtomicCss.paddingVertical3
            ]}>
            {cardInfoText}
          </Text>
        }
        </View>
      </View>
      {showRefundCard && <View style={[AtomicCss.marginTop20]}>
        <RefundTrackerCard
          response={response}
          card={card}
          bookingId={bookingId}
        />
      </View>}
      <View>
        {!isEmpty(subdesc) && <View style={ styles.section }>
          <Text
            style={[
              fonts.regularFontFamily,
              fonts.font14,
            ]}>
            {subdesc}
          </Text>
        </View>}
        {!travellingWithRAC && <View>
          {!isEmpty(actionList) && actionList.map(action => {
            return (<TouchableOpacity style={[styles.redirectLink]} onPress={() => {
              getAppropriateHandler(action.actionId);
            }}>
              <View style={[AtomicCss.flex1]}>
                {!isEmpty(action.actionLabeltext) && <Text
                  style={[AtomicCss.azure, fonts.font14, fonts.boldFontFamily]}>
                  {action.actionLabeltext}
                </Text>
                }
                {!isEmpty(action.actionLabelSubText) && <Text
                  style={[AtomicCss.midGreyText, fonts.font12, fonts.regularFontFamily, AtomicCss.marginTop5, AtomicCss.marginRight10]}>
                  {action.actionLabelSubText}
                </Text>
                }
                {!isEmpty(action.actionLabelDesc) && <Text style={[fonts.font12, fonts.regularFontFamily, AtomicCss.marginTop5, styles.limitText, AtomicCss.alignSelfStart]}>
                  {action.actionLabelDesc}
                </Text>}
              </View>
              <Image style={styles.rightArrowStyle} source={rightArrow} />
            </TouchableOpacity>)
          })}
        </View>}
      </View>
    </View>
  );
};

export default WhiteCardWrapper(RailTGSCard);