import { Platform } from 'react-native';

import { fileUploadAPI, getImageByteCharacters } from '../../../../utils/PostSaleUtil';

export const uploadFile = async (doc, bookingId) => {
  try {
    let imageStr;
    if (Platform.OS === 'ios') {
      imageStr = doc.data;
    } else if (Platform.OS === 'web') {
      imageStr = doc.base64String;
    } else {
      imageStr = await getImageByteCharacters(doc.filePath);
    }
    if (imageStr) {
      const res = await fileUploadAPI(imageStr, doc, bookingId);
      const json = await res.json();
      if (res.ok && res.status === 200 && json.statusCode === 200 && json.documentID) {
        // API Done state
        return json;
      } else {
        return false;
      }
    }
  } catch (err) {
    return false;
  }
};
