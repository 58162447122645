import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  OverlayContentWrapper: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  headerWrapper: {
    marginBottom: 15,
    marginLeft: -20,
    marginRight: -20,
    paddingHorizontal: 20,
  },
  title: {
    color: '#EB2026',
  },
  addSeatMealWrapper: {
    paddingHorizontal: 15,
    paddingVertical: 20,
    borderRadius: 4,
    backgroundColor: '#f2f2f2',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 50,
  },
  ancillaryIcon: {
    width: 32,
    height: 32,
  },
  btnWrapper: {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
  myBizLink: {
    color: '#F17531',
    alignSelf: 'flex-start',
  },
});

export default styles;
