import {colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles';

const styles = {
    parentContainer: {
        flex: 1,
        backgroundColor: colors.white
    },
    pageWrapper:{
        flex: 1,
        backgroundColor: colors.white,
        justifyContent: 'center',
        alignItems: 'center',
    },
    footerBtnWrapper:{
        paddingHorizontal: 50,
        paddingTop: 15,
        width: '100%'
    },
    messageContent:{
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 50,
        marginBottom:60,
    },
    messageIcon:{
        width: 65,
        height: 40,
        marginBottom: 60
    },
    messageTitle:{
        fontSize: 20,
        fontFamily: fonts.bold,
        color: colors.black28,
        marginBottom: 20,
        textAlign: 'center'
    },
    messageDesc: {
        fontSize: 14,
        fontFamily: fonts.regular,
        color: colors.gray,
        textAlign: 'center',
        lineHeight: 20
    },
    lastDisc: {
        marginBottom: 80
    }
};

export default styles;

