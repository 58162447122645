import React from 'react';
import {LIST_IMG} from '../../../SelfDriveImageConstant';
import {View, Text, Image} from 'react-native';
import styles from './moreLinksCardCss';
import {isEmpty} from 'lodash';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {getButtonStyle, handleZcClickEvent} from "../../../utils/SelfDriveBookingDetailUtil";
import {getActionButton} from "../../../../Common/commonUtil";


const MoreLinksCard = ({card, response}) => {

 return(
    <View style={[AtomicCss.marginBottom10,styles.cardBorder]}>
        <View style={[styles.infoCardWrapper]}>
        <View style={styles.headingIconWrapper}>
           <Image style={styles.headingIconStyle} source={LIST_IMG}/>
        </View>
            <View style={styles.infoCardContent}>
                <Text style={[AtomicCss.defaultText, AtomicCss.blackFont, AtomicCss.font14]}>MORE ABOUT YOUR BOOKING </Text>
            </View>
        </View>

        {!isEmpty(card.actionList) &&
        <View
            style={!isEmpty(card) ? !isEmpty(card.actionList) ? getButtonStyle(card.actionList) : styles.hideView : styles.hideView}>
            {!isEmpty(card) ? getActionButton(card, response, handleClick) : null}
        </View>
        }

   </View>
    )

    function handleClick (action) {
        handleZcClickEvent(card, action, response, "moreAboutLinks").then(r => console.log("links are clicked"));
    }

}
export default MoreLinksCard
