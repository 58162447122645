import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  myBizStayConnectedCard: {
    backgroundColor: '#fff',
    borderRadius: 10,
    marginHorizontal: 12,
    marginBottom: 12,
    ...getPlatformElevation(2),
  },
  stayConnectedInnerCard: {
    padding: 12,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  stayConnectedContent: {
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  contactListWrap: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 12,
    paddingVertical:10,
    marginLeft: -12,
    marginRight:-14
  },
  contactImgWrap: {
    width: 35,
    paddingTop: 4,
  },
  contactListContent: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '79%',
  },
  contactListDivider: {
    borderTopWidth: 1,
    borderColor: '#e7e7e7',
  },
  imgStayConnected: {
    width: 24,
    height: 24,
  },
  imgMyTripIcon: {
    width: 24,
    height: 24,
  },
  imgContactIcon: {
    width: 40,
    height: 40,
  },
  imgHotelIcon: {
    width: 20,
    height: 23,
  },
});

export default styles;
