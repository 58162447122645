import React, { useEffect, useState } from 'react';
import { View, Text, Linking, BackHandler } from 'react-native';
import { isEmpty } from 'lodash';
import HTMLView from 'react-native-htmlview';
import createStyle, { createHtmlStyle } from './styles';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import CancellationThankYouPage from '../../components/Success';
import Actions from '../../../../../navigation/postSalesNavigation';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import FIREBASE_CONFIG from '../../../../../firebase/const';
import { resetToFlightBookingDetail } from '../../../../FlightBookingUtil';
import { openDialer } from '../../../../../Common/commonUtil';
import CircularCountdown from '../../../../../Common/CircularCountdown';
import { getFont } from '../../../../../PostSalesStyles';
import { ViewState } from 'core-ui-lib/flight/common/Cancellation/constant.js';
import useFirebase from './hooks/useFirebase'
import { useTheme } from '../../../../../theme/theme.context';
import useHardwareBackpress from '../../../../../Common/useHardwareBackpress';

const CancellationProgress = ({
  isCorporateBooking,
  updateStatusInterval,
  bookingId,
  segmentWiseInfo,
  cancellationDoneResponseJson,
  awaitingStatusInterval,
}) => {

  const [updateTextStatus, setUpdatedTextStatus] = useState(false);
  const { psTheme: theme } = useTheme();
  const styles = createStyle(theme);
  // eslint-disable-next-line no-unused-vars
  const [viewState,lastMinuteErrorPageData,reloadFirebase] = useFirebase(FIREBASE_CONFIG.LAST_MINUTE_CANCELLATION, {bookingId, segmentWiseInfo, isCorporateBooking, cancellationDoneResponseJson});

  const onBackPressHandler = () => {
    resetToFlightBookingDetail(bookingId);
    return true;
  };

  useHardwareBackpress().addListener(onBackPressHandler, true);

  useEffect(() => {
    if (!isEmpty(lastMinuteErrorPageData)) {
        Actions.errorInLastMinuteCancellation({
          isCorporateBooking: isCorporateBooking,
          bookingId: bookingId,
          errorPageData: lastMinuteErrorPageData,
          segmentWiseInfo: segmentWiseInfo,
        });
    }
  }, [lastMinuteErrorPageData]);

  const _openUpdatedBooking = () => {
    resetToFlightBookingDetail(bookingId, true);
  };

  const renderActionFromLink = (url) => {
    const [condition, value] = url.replaceAll('/', '').split(':');
    switch (condition) {
      case 'openBookingDetails':
        return _openUpdatedBooking();
      case 'tel':
        return openDialer(value);
      default:
        return Linking.openURL(url);
    }
  };

  const completedCoundownHandler = () => {
    if (updateTextStatus) {
      _openUpdatedBooking();
    } else {
      setUpdatedTextStatus(true);
    }
  };

  const renderView = () => {
    const { lastMinuteCancellationInProgressText } = cancellationDoneResponseJson || {};
    const { fsStyle, ...fonts } = getFont(true);
    const htmlStyles = createHtmlStyle(theme);
    return (
      <View style={styles.fullPage}>
        {updateTextStatus && !isEmpty(lastMinuteCancellationInProgressText.secondDescription) && (
          <View style={AtomicCss.marginBottom20}>
            <HTMLView
              value={`<div>${lastMinuteCancellationInProgressText.footerText}</div>`}
              stylesheet={htmlStyles}
            />
          </View>
        )}
        <View style={AtomicCss.marginBottom12}>
          <CircularCountdown
            initialSeconds={updateTextStatus ? awaitingStatusInterval : updateStatusInterval}
            onComplete={completedCoundownHandler}
            radius={50}
            borderWidth={12}
            color={isCorporateBooking ? colors.red10 : colors.azure}
            shadowColor={colors.white}
            bgColor={colors.white}
            key={`${updateTextStatus}`}
          />
        </View>
        {!isEmpty(lastMinuteCancellationInProgressText) &&
          (!updateTextStatus ? (
            <View style={styles.msgSection}>
              {!isEmpty(lastMinuteCancellationInProgressText.heading) && (
                <Text style={[styles.titleHeading, fonts.blackFontFamily, fsStyle.font20]}>
                  {lastMinuteCancellationInProgressText.heading}
                </Text>
              )}
              {!isEmpty(lastMinuteCancellationInProgressText.description) && (
                <HTMLView
                  value={`<div>${lastMinuteCancellationInProgressText.description}</div>`}
                  stylesheet={{
                    ...htmlStyles,
                    div: { ...htmlStyles.div, ...fsStyle.font16 },
                    b: { ...htmlStyles.b, ...fonts.boldFontFamily },
                    span: { ...htmlStyles.span, ...fsStyle.font16 },
                  }}
                />
              )}
            </View>
          ) : (
            <View style={styles.msgSection}>
              {!isEmpty(lastMinuteCancellationInProgressText.secondHeading) && (
                <Text style={[styles.titleHeading, fonts.blackFontFamily, fsStyle.font20]}>
                  {lastMinuteCancellationInProgressText.secondHeading}
                </Text>
              )}
              {!isEmpty(lastMinuteCancellationInProgressText.secondDescription) && (
                <View>
                  <HTMLView
                    value={`<div>${lastMinuteCancellationInProgressText.secondDescription}</div>`}
                    onLinkPress={renderActionFromLink}
                    stylesheet={{
                      ...htmlStyles,
                      div: { ...htmlStyles.div, ...fsStyle.font16 },
                      span: { ...htmlStyles.span, ...fsStyle.font16 },
                    }}
                  />
                </View>
              )}
              {!isEmpty(lastMinuteCancellationInProgressText.contactUsText) && (
                <View style={styles.contactUsWrapper}>
                  <HTMLView
                    value={`<span>${lastMinuteCancellationInProgressText.contactUsText}</span>`}
                    onLinkPress={renderActionFromLink}
                    stylesheet={{
                      ...htmlStyles,
                      div: { ...htmlStyles.div, ...fsStyle.font16 },
                      b: { ...htmlStyles.b, ...fonts.boldFontFamily },
                      span: { ...htmlStyles.span, ...fsStyle.font16 },
                    }}
                  />
                </View>
              )}
            </View>
          ))}
      </View>
    );
  };

  const renderErrorView = () => (
    <View style={AtomicCss.flex1}>
      <ErrorView showRetry onRetry={reloadFirebase} />
    </View>
  );

  const renderSuccessView = () => (
    <CancellationThankYouPage responseJson={cancellationDoneResponseJson} bookingId={bookingId} />
  );

  return (
    <>
      {(viewState === ViewState.ERROR || viewState === ViewState.VIEW_DETAIL) && renderView()}
      {viewState === ViewState.VIEW_SUCCESS && renderSuccessView()}
      {viewState === ViewState.API_ERROR && renderErrorView()}
    </>
  );
};

export default CancellationProgress;
