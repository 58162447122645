import {NativeModules} from 'react-native';
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { noop } from 'lodash';

const FileUploader = React.memo(
  ({ onFileChanged, setAttachmentList = noop, attachmentLst, text, showFileSelection = true }) => {
    const onDrop = useCallback(
      async (acceptedFiles) => {
        const File = acceptedFiles[0];
        if(!["image/png","image/jpeg"].includes(acceptedFiles[0].type)){
          return
        }
        const reader = new FileReader();
        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          const binaryStr = reader.result;
          const data = {
            ...acceptedFiles[0],
            fileName: acceptedFiles[0].name,
            filePath: binaryStr,
            mimeType: acceptedFiles[0].type,
            base64String: binaryStr.replace("data:", "").replace(/^.+,/, ""),
          };
          const map = JSON.parse(JSON.stringify(attachmentLst));
          map.push(data);
          onFileChanged(map);
          setAttachmentList(map);
        };
        reader.readAsDataURL(File);
      },
      [onFileChanged, setAttachmentList, attachmentLst]
    );
    const { getRootProps, getInputProps } = useDropzone({ onDrop });
    return (
      <div {...getRootProps()}>
        {showFileSelection && <input {...getInputProps()} accept=".png,.jpeg" />}
        <p
          style={{
            color: "#008cff",
            fontFamily: "Lato-Bold",
            fontSize: "14px",
          }}
        >
          {text}
        </p>
      </div>
    );
  }
);

NativeModules.FileUploaderModule = {
  FileUploader: FileUploader
};