export const PROGRESS_TRACKER_MAP = {
  INITIATED: [
    {
      barLabel: 'Request',
      isComplete: true,
      completedBarColor: '#26B5A9',
      icon: require('@mmt/legacy-assets/src/green_tick.webp'),
      identifier: 1
    },
    {
      barLabel: 'Approval',
      isComplete: false,
      completedBarColor: '#EB2226',
      icon: require('@mmt/legacy-assets/src/green_tick.webp'),
      identifier: 2
    },
    {
      barLabel: 'Payment',
      isComplete: false,
      completedBarColor: '#26B5A9',
      icon: require('@mmt/legacy-assets/src/green_tick.webp'),
      identifier: 3
    }
  ],
  APPROVED: [
    {
      barLabel: 'Request',
      isComplete: true,
      completedBarColor: '#26B5A9',
      icon: require('@mmt/legacy-assets/src/green_tick.webp'),
      identifier: 1
    },
    {
      barLabel: 'Approval',
      isComplete: true,
      completedBarColor: '#26B5A9',
      icon: require('@mmt/legacy-assets/src/green_tick.webp'),
      identifier: 2
    },
    {
      barLabel: 'Payment',
      isComplete: false,
      completedBarColor: '#26B5A9',
      icon: require('@mmt/legacy-assets/src/green_tick.webp'),
      identifier: 3
    }
  ],
  REJECTED: [
    {
      barLabel: 'Request',
      isComplete: true,
      completedBarColor: '#26B5A9',
      icon: require('@mmt/legacy-assets/src/green_tick.webp'),
      identifier: 1
    },
    {
      barLabel: 'Rejection',
      isComplete: true,
      completedBarColor: '#EB2226',
      icon: require('@mmt/legacy-assets/src/cross_red.webp'),
      identifier: 2
    },
    {
      barLabel: 'Payment',
      isComplete: false,
      completedBarColor: '#26B5A9',
      icon: require('@mmt/legacy-assets/src/green_tick.webp'),
      identifier: 3
    }
  ],
}

export const PAGE_STATES = {
  LOADING: 'LOADING',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS'
};

export const MODAL_VISIBILITY_TYPES = {
  APPROVE: 'APPROVE',
  REJECT: 'REJECT',
  INFO: 'INFO',
  SKIP: 'SKIP',
  HIDDEN: 'HIDDEN'
}

export const ACTION_TYPES = {
  APPROVE: 'APPROVE',
  REJECT: 'REJECT',
  SKIP: 'SKIP',
  PAY: 'PAY'
}

export const BUTTON_TYPES = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY'
}

export const ROLE_TYPES = {
  APPROVER: 'APPROVER',
  REQUESTER: 'REQUESTER'
}
