import React from 'react';
import { View, Text, Image, Platform, TouchableOpacity } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import createStyles from './ReturnTripOfferCardCss';
import { getFont } from '../../../../../PostSalesStyles';
import { useTheme } from '../../../../../theme/theme.context';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import BusBookingTrackingHelper from 'apps/post-sales/src/bus/BusBookingTrackingHelper';
import { BusTrackingConstant } from 'apps/post-sales/src/bus/BusBookingDetailsConstant';
import { getImagePath } from '../../../../../utils/PostSaleUtil';

const offerIcon = getImagePath('offerIcon.png');
const rightArrow = getImagePath('rightArrow.png');
interface Props {
  card: {
    isVisible: boolean;
  };
  response: {
    specialOfferCardInfo: {
      operartorName: string;
      deepLinkInfo: {
        url: string;
        text: string;
      };
      expiryText: string;
      unlockInfoMsg: string;
      offerMsg: string;
      journeyDetail: {
        segmentDetail: [
          {
            src: string;
            dest: string;
          },
        ];
      };
    };
  };
}

const ReturnTripOfferCard = ({ card, response } : Props) => {
  const { fsStyle, ...fonts } = getFont();
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);
  const gradientsColor = [theme.color.grey22, theme.color.lightRed5];
  const unlockGradientsColor = [theme.color.lightRed6, theme.color.lightRed7];

  const specialOfferCardInfo = get(response, 'specialOfferCardInfo', {});
  if (isEmpty(specialOfferCardInfo) || !card.isVisible) {
    return null;
  }
  const {
    operartorName,
    deepLinkInfo,
    expiryText,
    unlockInfoMsg,
    offerMsg,
    journeyDetail,
  } = specialOfferCardInfo;
  const showDeeplinkUrl = !isEmpty(deepLinkInfo) && deepLinkInfo.url && deepLinkInfo.text;
  const { segmentDetail } = journeyDetail || {};

  const handleDeepLink = () => {
    BusBookingTrackingHelper.trackClickEvent(
      BusTrackingConstant.BUS_BOOKING_DETAILS_PAGE,
      response,
      BusTrackingConstant.RETURN_TRIP_EVENT,
    );
    const { url } = deepLinkInfo || {};
    if( !isEmpty(url) ){
      if (Platform.OS === 'web') {
        window.open(url, '_blank').focus();
      } else {
        GenericModule.openDeepLink(url);
      }
    }
  };
  return (
    <LinearGradient
      colors={gradientsColor}
      start={{ x: 0.8, y: 2.0 }}
      end={{ x: 1.2, y: 0.5 }}
      style={styles.specialOfferCard}
    >
      <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween, AtomicCss.alignCenter]}>
        {!isEmpty(unlockInfoMsg) && (
          <LinearGradient
            colors={unlockGradientsColor}
            start={{ x: 0.1, y: 0.0 }}
            end={{ x: 1, y: 0.0 }}
            style={styles.unlockInfoSection}
          >
            <Text style={[styles.offerText, fonts.boldFontFamily]}>{unlockInfoMsg}</Text>
          </LinearGradient>
        )}
        {!isEmpty(expiryText) && (
          <Text style={[AtomicCss.defaultText, fsStyle.font12, fonts.regularFontFamily]}>
            {expiryText}
          </Text>
        )}
      </View>
      <View style={styles.specialOfferDtls}>
        <View style={styles.imageWrapper}>
          <Image style={styles.offerIcon} source={offerIcon} />
        </View>
        <View style={[AtomicCss.flex1]}>
          {!isEmpty(offerMsg) && (
            <Text
              style={[
                fsStyle.font16,
                fonts.blackFontFamily,
                AtomicCss.marginBottom3,
              ]}
            >
              {offerMsg}
            </Text>
          )}
          {!isEmpty(segmentDetail) &&
            segmentDetail.map((segment: any, index: number) => {
              const { src, dest } = segment || {};
              return (
                <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]} key={index}>
                  {!isEmpty(src) && (
                    <Text style={[fsStyle.font12, fonts.regularFontFamily]}>
                      {src}
                    </Text>
                  )}
                  {!isEmpty(rightArrow) && (
                    <Image style={styles.arrowStyle} source={rightArrow} />
                  )}
                  {!isEmpty(dest) && (
                    <Text style={[fsStyle.font12, fonts.regularFontFamily]}>
                      {dest}
                    </Text>
                  )}
                </View>
              );
            })}
        </View>
      </View>
      <View
        style={[
          AtomicCss.flexRow,
          AtomicCss.alignCenter,
          AtomicCss.spaceBetween,
          styles.moreInfoWrapper,
        ]}
      >
        {!isEmpty(operartorName) && (
          <Text style={[ fsStyle.font14, fonts.regularFontFamily]}>
            {operartorName}
          </Text>
        )}
        {showDeeplinkUrl && (
          <TouchableOpacity onPress={handleDeepLink}>
            <Text style={[AtomicCss.azure, fonts.boldFontFamily, fsStyle.font14]}>
              {deepLinkInfo.text}
            </Text>
          </TouchableOpacity>
        )}
      </View>
    </LinearGradient>
  );
};

export default ReturnTripOfferCard;
