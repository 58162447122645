class RailsEnvVariables {
  public PWA_ENDPOINTS = [
    { label: 'PROD', value: 'https://rails.makemytrip.com' },
    { label: 'QA', value: 'http://10.212.93.167' },
    { label: 'STG', value: 'http://172.16.217.97' },
  ];

  public NODE_ENDPOINTS = [
    { label: 'PROD', value: 'https://railways.makemytrip.com' },
    { label: 'QA', value: 'http://10.212.93.167' },
    { label: 'STG', value: 'http://172.16.44.92' },
  ];

  //TODO : Custom endpoints

  private pwaBaseUrl: string = 'https://rails.makemytrip.com';
  private nodeBaseUrl: string = 'https://railways.makemytrip.com';
  private canChangeBaseUrl: boolean = __DEV__;

  public getPWABaseUrl() {
    return this.pwaBaseUrl;
  }

  public setPWABaseUrl(pwaBaseUrl: string) {
    this.pwaBaseUrl = pwaBaseUrl;
  }

  public getNodeBaseUrl() {
    return this.nodeBaseUrl;
  }

  public setNodeBaseUrl(nodeBaseUrl: string) {
    this.nodeBaseUrl = nodeBaseUrl;
  }

  public getCanChangeBaseUrl() {
    return this.canChangeBaseUrl;
  }

  public setCanChangeBaseUrl(canChangeBaseUrl: boolean) {
    this.canChangeBaseUrl = canChangeBaseUrl;
  }
}

export default new RailsEnvVariables();
