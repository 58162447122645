import { connect } from 'react-redux';
import React from 'react';
import {
  dateChangePreviewRequest,
  dateChangeListingRequest,
  listingErrorOpen,
  listingErrorLoader,
  enableProceedBtn,
  dateChangeLoader,
  rtDtErrPopup,
} from './DateChangeActions';
import PickDates from '../../pages/PickDates/index';
import { ThemeContext } from '../../../../../theme/theme.context';

const dateChangeMapStateToProps = (state) => state.dateChangeReducer;

const dateChangeDispatchToProps = (dispatch) => ({
  dateChangeListingRequest: (bookingID, segmentGroup, url) =>
    dispatch(dateChangeListingRequest(bookingID, segmentGroup, url)),
  dateChangePreviewRequest: (bookingID, segmentGroup, url, isCfarBooking) =>
    dispatch(dateChangePreviewRequest(bookingID, segmentGroup, url, isCfarBooking)),
  dateChangeListingError: (overlayShow) => dispatch(listingErrorOpen(overlayShow)),
  dateChangeListingLoader: () => dispatch(listingErrorLoader()),
  enableProceedBtn: () => dispatch(enableProceedBtn()),
  dateChangeRequestLoader: () => dispatch(dateChangeLoader()),
  rtDateErrorPopup: (response) => dispatch(rtDtErrPopup(response)),
});

const ConnectedPickDates=(props)=>{
  return <PickDates {...props} />
}
export default connect(dateChangeMapStateToProps, dateChangeDispatchToProps,null , {context:ThemeContext})(ConnectedPickDates);
