import React, { useState, useEffect, useRef } from 'react';
import { getDomainHeaders, getRequestHeader } from '../WebModules/NetworkModule';
import { getPokusBody, GET_UUID, POKUS_DETAILS } from '../const';

export let pokusConfig = null;

const usePokus = () => {
  const [isPokusLoading, setPokusLoading] = useState(true);
  let timerId = null;
  useEffect(() => {
    fetchPokusData()
  }, [])
  const clearSessionTimeout = () => {
    clearTimeout(timerId)
    setPokusLoading(false)
  }
  const fetchPokusData = async () => {
    try {
      const controller = new AbortController()
      timerId = setTimeout(() => {
        controller.abort();
        clearSessionTimeout()
      }, 4000)
      const headers = await getRequestHeader();
      const uuidResponse = await fetch(GET_UUID, {
        method: 'GET',
        signal: controller.signal,
        headers: {
          'mmt-auth': headers.mmtAuth,
          ...getDomainHeaders()
        }
      });
      if (uuidResponse.ok) {
        const uuidData = await uuidResponse.json();
        const pokusBody = JSON.stringify(getPokusBody(uuidData));
        const pokusResponse = await fetch(POKUS_DETAILS, {
          method: 'POST',
          signal: controller.signal,
          body: pokusBody
        });
        if (pokusResponse.ok) {
          const pokusData = await pokusResponse.json()
          pokusConfig = pokusData;
          clearSessionTimeout()
        }
      }
    } catch (error) {
      console.error('Failed to load pokus api', error);
      clearSessionTimeout()
    }
  }
  return [isPokusLoading]
}

export default usePokus;