import React from 'react';
import { View, Text, Image, StyleSheet, Platform } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import Actions from '../../../../../navigation/postSalesNavigation';
import { AROGYA_SETU, BACK_WHITE_ICON } from '../../../../FlightImageConstants';
import { getFont } from '../../../../../PostSalesStyles';

const AarogyaSetuCard = ({ aarogyaSetuBannerData }) => {
  const { fsStyle, ...fonts } = getFont(true);
  const openUrl = () => {
    const { heading, androidAppURL, iosappURL } = aarogyaSetuBannerData;
    let appUrl = '';
    if (Platform.OS === 'android') {
      appUrl = androidAppURL;
    }
    if (Platform.OS === 'ios') {
      appUrl = iosappURL;
    }
    Actions.openPsWebView({
      headerText: heading || '',
      headerIcon: BACK_WHITE_ICON,
      url: appUrl,
    });
  };
  return (
    <View style={styles.bannerCard}>
      <Image style={styles.arogyaLogoIcon} source={AROGYA_SETU} />
      <View style={AtomicCss.flex1}>
        <Text style={[fsStyle.font12, AtomicCss.blackText, fonts.regularFontFamily]}>
          {aarogyaSetuBannerData.description}
          <Text
            onPress={() => openUrl()}
            style={[fsStyle.font12, fonts.boldFontFamily, AtomicCss.azure]}
          >
            {aarogyaSetuBannerData.installerDesc}
          </Text>
          !
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  bannerCard: {
    backgroundColor: '#fff',
    paddingHorizontal: 16,
    paddingVertical: 13,
    marginBottom: 12,
    flexDirection: 'row',
    alignItems: 'center',
  },
  arogyaLogoIcon: { width: 34, height: 31, marginRight: 7 },
});

export default AarogyaSetuCard;
