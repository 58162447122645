import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';
import FlightBookingDetailsConstant from '../../FlightBookingDetailsConstant';
import ButtonWithIcon from './ButtonWithIcon';
import { getStaticData } from '../../../../../staticData/staticData';
import { getFont } from '../../../../../PostSalesStyles';
class Support extends React.Component {
  static navigationOptions = {
    header: null,
  };

  render() {
    const fonts = getFont(true);
    const {
      supportCardText: {
        heading,
        additionalText: { chatWithMyraText },
      },
      callAirlineText,
      contactUsText,
      callUsText,
    } = getStaticData();
    return (
      <View style={[styles.elevation1, AtomicCss.marginBottom10]}>
        <View style={styles.CardContent}>
          <View style={[AtomicCss.flex1]}>
            <Text
              style={[
                fonts.blackFontFamily,
                AtomicCss.blackText,
                AtomicCss.paddingTop3,
                AtomicCss.textCenter,
              ]}
            >
              {heading}
            </Text>
          </View>
        </View>
        <View style={[styles.ActionBtnHalf, styles.supportWrap]}>
          {this.props.showAirlineContactBtn && (
            <ButtonWithIcon
              action={this.getActionForBtn(
                callAirlineText,
                FlightBookingDetailsConstant.CALL_AIRLINE_ACTION_BTN_FAMILY,
              )}
              clickHandler={this.props.supportCardClickHandler}
            />
          )}
          {this.props.showMyraBtn && (
            <ButtonWithIcon
              action={this.getActionForBtn(
                chatWithMyraText,
                FlightBookingDetailsConstant.CHAT_WITH_US_ACTION_BTN_FAMILY,
              )}
              clickHandler={this.props.supportCardClickHandler}
            />
          )}
          {this.props.isCorpBooking && (
            <ButtonWithIcon
              action={this.getActionForBtn(
                contactUsText,
                FlightBookingDetailsConstant.ACTION_CORPORATE_CALL,
              )}
              clickHandler={this.props.supportCardClickHandler}
            />
          )}
          {this.props.isGccBooking && (
            <ButtonWithIcon
              action={this.getActionForBtn(
                callUsText,
                FlightBookingDetailsConstant.ACTION_CALL_MMT_SUPPORT_GCC,
              )}
              clickHandler={this.props.supportCardClickHandler}
            />
          )}
        </View>
      </View>
    );
  }

  getActionForBtn = (text, actionFamily) => {
    const action = {};
    action.actionLabeltext = text;
    action.actionFamily = actionFamily;
    return action;
  };
}

const styles = StyleSheet.create({
  CardContent: {
    backgroundColor: '#fff',
    borderBottomWidth: 1,
    borderBottomColor: '#e4e4e4',
    flexDirection: 'row',
    padding: 15,
  },
  ActionBtnHalf: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100 %',
    backgroundColor: '#fff',
    paddingBottom: 10,
    marginTop: 15,
  },
  elevation1: {
    backgroundColor: '#ffffff',
    ...getPlatformElevation(1),
  },
  supportWrap: { paddingTop: 16, paddingBottom: 24 },
});

export default Support;

Support.propTypes = {
  showAirlineContactBtn: PropTypes.bool.isRequired,
  supportCardClickHandler: PropTypes.func.isRequired,
  showMyraBtn: PropTypes.bool.isRequired,
  isCorpBooking: PropTypes.bool.isRequired,
};
