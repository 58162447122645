import React from 'react';
import { View, Platform } from 'react-native';
import { POST_SALES_BOOKING_SUMMARY_PAGE, POST_SALES_WEB_BOOKING_SUMMARY_PAGE } from "packages/legacy-commons/adsConfig/adsConfigConstants";
import { getAdTechCard } from "../../Common/commonUtil";
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import myTripsNavigation from '../navigation/navigation';
import { whiteBackIcon } from '../../bus/BusImageConstants';
import { openGoogleMap, openDialer, openLink } from '../../Common/commonUtil';
import { openDetailsPageByLob } from '../utils';
import { getStaticData } from '../../staticData/staticData';
import GenericModule from 'packages/core/native/GenericModule';
import isEmpty from 'lodash/isEmpty';
import AddBookingCard from './components/cards/AddBooking';
import createStyles from './summaryCss';
import { isAddBookingFlowEnabled, openWebView, setWebViewAuth } from '../../utils/PostSaleUtil';
import { FILTERS, FILTERS_TYPE, Lobs, MyTripsCards, PROPERTIES_ID } from './summaryConstants';
import { downloadFile, loadWhereToGoImageData } from './data/api';
import { AllFiltersDataType, summaryStateObjectType, CTAInfoType, CardDetails, FilterSectionType, MsrTicketInterface, SelectedFilter, BookingData } from "./types";
import Actions from '../../navigation/postSalesNavigation';
import PostSalesModule from '@mmt/legacy-commons/Native/PostSalesModule';
import FlightBookingModule from '@mmt/legacy-commons/Native/FlightBookingModule';
import { PROPERTIES_PAGE_TYPE, PROPERTIES_ACTION, PAGE_BOOKINGS_STATUS } from "./summaryConstants";
import { LobCardData } from './components/cards/cardInterfaces';
import TripSummaryOmnitureTracker from './TripSummaryOmnitureTracker';
import { showLongToast } from 'packages/legacy-commons/Common/Components/Toast';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';

export const renderAdTechCard = (cardStyle = []) => {
  return (
    <View style={[...cardStyle, AtomicCss.borderRadius4]}>
      {getAdTechCard(
        Platform.OS !== 'web' ? POST_SALES_BOOKING_SUMMARY_PAGE : POST_SALES_WEB_BOOKING_SUMMARY_PAGE,
      )}
    </View>
  )
}

const getWhere2GoLocusId = (lobType: string, data: BookingData) => {
  switch (lobType) {
    case Lobs.Flight:
      const flightLocusCode = data && data.flightSegment && data.flightSegment[1] && data.flightSegment[1].locusIdDetails && data.flightSegment[1].locusIdDetails[0] && data.flightSegment[1].locusIdDetails[0].locusCode;
      return flightLocusCode;
    case Lobs.Hotel:
      const hotelLocusCode = data && data.hotelDetails && data.hotelDetails[0] && data.hotelDetails[0].locusIdDetails && data.hotelDetails[0].locusIdDetails[0] && data.hotelDetails[0].locusIdDetails[0].locusCode;
      return hotelLocusCode;
    case Lobs.Bus:
      const busLocusCode = data && data.coachDetails && data.coachDetails[0] && data.coachDetails[0].locusIdDetails && data.coachDetails[0].locusIdDetails[0] && data.coachDetails[0].locusIdDetails[0].locusCode;
      return busLocusCode;
    case Lobs.Trains:
      const railLocusCode = data && data.railDetails && data.railDetails[0] && data.railDetails[0].locusIdDetails && data.railDetails[0].locusIdDetails[0] && data.railDetails[0].locusIdDetails[0].locusCode;
      return railLocusCode;
    case Lobs.Cars:
      const carLocusCode = data && data.carDetails && data.carDetails[0] && data.carDetails[0].locusIdDetails && data.carDetails[0].locusIdDetails[0] && data.carDetails[0].locusIdDetails[0].locusCode;
      return carLocusCode;
    case Lobs.Acme:
      const acmeLocusCode = data && data.acmeDetails && data.acmeDetails[0] && data.acmeDetails[0].locusIdDetails && data.acmeDetails[0].locusIdDetails[0] && data.acmeDetails[0].locusIdDetails[0].locusCode;
      return acmeLocusCode;
    case Lobs.Visa:
      const visaLocusCode = data && data.visaDetails && data.visaDetails[0] && data.visaDetails[0].locusIdDetails && data.visaDetails[0].locusIdDetails[0] && data.visaDetails[0].locusIdDetails[0].locusCode;
      return visaLocusCode;
    case Lobs.GiftCard:
      const giftCardLocusCode = data && data.giftCardDetails && data.giftCardDetails[0] && data.giftCardDetails[0].locusIdDetails && data.giftCardDetails[0].locusIdDetails[0] && data.giftCardDetails[0].locusIdDetails[0].locusCode;
      return giftCardLocusCode;
    case Lobs.TGS:
      const tgsLocusCode = data && data.tgsDetails && data.tgsDetails[0] && data.tgsDetails[0].locusIdDetails && data.tgsDetails[0].locusIdDetails[0] && data.tgsDetails[0].locusIdDetails[0].locusCode;
      return tgsLocusCode;
    default:
      return null;
  }
}

export const getWhere2GoImageData = async (data: BookingData) => {
  const lobType = data?.lobType && data.lobType[0];
  const locusCode = getWhere2GoLocusId(lobType, data);
  const whereToGoImage = await loadWhereToGoImageData(locusCode);
  return whereToGoImage;
}

export const renderCardList = (
  cardData: CardDetails[],
  addBookingCTA: { text: string, addBooking: string },
  handleAddBookingOverlay: () => void,
  theme
) => {
  const styles = createStyles(theme);
  const result = !isEmpty(cardData) ?
    cardData.map((data: { cardId: string, isSticky: boolean }) => {
      const { cardId, isSticky } = data;
      const cardStyle = [AtomicCss.marginTop10];
      let card = null;
      if (cardId === MyTripsCards.AD_TECH_CARD) {
        card = renderAdTechCard();
      } else if (
        cardId === MyTripsCards.ADD_BOOKING &&
        addBookingCTA &&
        isAddBookingFlowEnabled()
      ) {
        card = (
          <AddBookingCard
            response={addBookingCTA}
            handleAddBookingOverlay={handleAddBookingOverlay}
          />
        );
      }
      if (cardId === MyTripsCards.AD_TECH_CARD) {
        return (
          <View
            style={[
              AtomicCss.paddingAll10,
              AtomicCss.alignCenter,
              isSticky ? AtomicCss.whiteBg : '',
              styles.shadowWrapper,
              ...cardStyle,
            ]}
          >
            {card}
          </View>
        );
      }
      return <View style={[...cardStyle, isSticky ? AtomicCss.whiteBg : '']}>{card}</View>;
    }) : null;
  return result;
}

export const downloadFileHelper = async (ctaInfo: CTAInfoType) => {
  const { openFileText, downloadFileText } = getStaticData();
  showLongToast(downloadFileText);
  return downloadFile(ctaInfo?.properties)
    .then((pdfFile) => {
      const fileName = `${ctaInfo?.properties?.lob}-${ctaInfo?.properties?.bookingId}.pdf`;
      if (pdfFile) {
        if (Platform.OS === 'ios') {
          GenericModule.openPDFViewer(fileName, pdfFile);

        } else {
          FlightBookingModule.saveTicket(pdfFile, fileName);
          FlightBookingModule.showTicketDownloadedNotification({
            title: fileName,
            description: openFileText,
            fileName,
          });
        }
      }
      return true;
    });

};

const viewFileHelper = async (ctaInfo: CTAInfoType) => {
  const { downloadFileText } = getStaticData();
  showLongToast(downloadFileText);
  return downloadFile(ctaInfo?.properties)
    .then((pdf) => {
      const fileViewName = `${ctaInfo?.properties?.lob}-${ctaInfo?.properties?.bookingId}.pdf`;
      if (Platform.OS === 'android') {
        PostSalesModule.openPdf({
          fileName: fileViewName,
          base64String: pdf,
        });
      } else if (Platform.OS === 'ios') {
        GenericModule.openPDFViewer(`${fileViewName}`, pdf);
      } else {
        FlightBookingModule.saveTicket(pdf, fileViewName);
      }
      return true;
    });
}

const getContactPropertyValue = (ctaInfo: CTAInfoType) => {
   return ctaInfo?.properties?.contactNumbers;
}

export const handleCTAClick = async (ctaInfo: CTAInfoType, data: LobCardData) => {
  const staticData = getStaticData(true);
  const lobType = data.lobType[0];
  const { bookingID: bookingId, bookingType, status } = data;
  const lobName = `${bookingType} ${lobType}`;
  TripSummaryOmnitureTracker.trackBookingClickEvent(lobName, status, bookingId, ctaInfo?.id);
  switch (ctaInfo.action) {
    case PROPERTIES_ACTION.ROUTE:
      if (ctaInfo?.properties?.page === PROPERTIES_PAGE_TYPE.DETAILS || ctaInfo?.properties?.page === PROPERTIES_PAGE_TYPE.ITINERARY) {
        const pageData = {
          fromMyTrips: true,
        };
        openDetailsPageByLob(ctaInfo?.properties?.lob, data, pageData);
      } else if (ctaInfo?.properties?.page === PROPERTIES_PAGE_TYPE.REVIEWUS) {
        if (ctaInfo?.properties?.lob === Lobs.Hotel) {
          myTripsNavigation.openHotelBookingDetail(data, {
            fromMyTrips: true,
            showHotelReview: true,
          });
        }
      } else if (ctaInfo?.properties?.page === PROPERTIES_PAGE_TYPE.ADD_REQUEST) {
        if (ctaInfo?.properties?.lob === Lobs.Hotel) {
          myTripsNavigation.openHotelBookingDetail(data, {
            fromMyTrips: true,
            routeToAddRequest: true,
          });
        }
      }
      break;
    case PROPERTIES_ACTION.DEEP_LINK:
      GenericModule.openDeepLink(ctaInfo?.properties?.url);
      break;
    case PROPERTIES_ACTION.OPEN_DIALER:
      const contactPropertyValue = getContactPropertyValue(ctaInfo);
      if(!isEmpty(contactPropertyValue)) {
        openDialer(Array.isArray(contactPropertyValue) ? contactPropertyValue[0]: contactPropertyValue);
      }
      break;
    case PROPERTIES_ACTION.CONTACT_PROPERTY:
      const contactPropertyCtaValue = getContactPropertyValue(ctaInfo);
      const contactPropertyCtaInfo = contactPropertyCtaValue && contactPropertyCtaValue.length > 1 ? contactPropertyCtaValue.join(', ') : contactPropertyCtaValue?.[0];
      contactPropertyCtaValue && contactPropertyCtaValue.length > 1 ? Actions.openCallHotel({
        headerText: staticData?.callHotelCardText?.additionalText?.callSupportText,
        phoneNumbers: contactPropertyCtaInfo,
      }) : openDialer(contactPropertyValue);
      break;
    case PROPERTIES_ACTION.VIEW_DOC:
      return viewFileHelper(ctaInfo);
    case PROPERTIES_ACTION.LINK:
      const url = ctaInfo?.properties?.url;
      if (ctaInfo?.properties?.isWebView) {
        if(ctaInfo.id === PROPERTIES_ID.NEED_HELP){
          const userInfo = await HotelBookingModule.getRequestHeader('', 'NEED_HELP');
          Actions.needHelpWebView({
            url: url,
            injectedJavaScript: setWebViewAuth(userInfo.mmtAuth),
            mmtAuth: userInfo.mmtAuth,
          });
        } else {
          openWebView('', whiteBackIcon, url);
        }
      } else {
        openLink(url);
      }
      break;
    case PROPERTIES_ACTION.DOWNLOAD:
      return downloadFileHelper(ctaInfo);
    case PROPERTIES_ACTION.DERIVE_LINK:
      const lat = ctaInfo?.properties?.lat;
      const long = ctaInfo?.properties?.long;
      openGoogleMap(lat, long);
      break;
  }
}

export const getBookingCount = (
  bookingData: summaryStateObjectType,
  statuses = ['UPCOMING', 'CANCELLED', 'COMPLETED', 'ONTRIP', 'FAILED', 'PLANNING', 'AWAITING'],
) => {
  let bookingCount = 0;
  statuses.forEach((item) => {
    const bookings = bookingData?.[item]?.bookings ?? [];
    bookingCount += bookings.length;
  });
  return bookingCount;
}

export const getDynamicFilterIds = (filtersData: FilterSectionType[]) => {
  const dynamicSectionsIds = filtersData.filter((item) =>
    item.type === FILTERS_TYPE.DYNAMIC).map((item) => item.id);
  return dynamicSectionsIds;
}

export const isValuesNotPresentForAnyFilter = (filtersData: FilterSectionType[]) => {
  const emptyFiltersData = filtersData.filter((item) => isEmpty(item.values));
  return emptyFiltersData.length > 0;
}

export const setDynamicFilterValues = (filtersData: FilterSectionType[], dynamicFiltersData: AllFiltersDataType) => {
  dynamicFiltersData && dynamicFiltersData.sections && dynamicFiltersData.sections.forEach((section: FilterSectionType) => {
    const filteredSection = filtersData.find((filter) => filter.id === section.id);
    if (filteredSection) {
      filteredSection.values = section.values;
    }

  });
  return filtersData;
}

export const getMyRequestCardData = (msrTicket: MsrTicketInterface) => {
  const myRequestsCard = msrTicket ? {
    icon: msrTicket?.myRequestIcon,
    cta: { text: msrTicket?.myRequestCTAText, url: msrTicket?.msrUrl },
    heading: msrTicket?.secondaryType,
    subHeading: msrTicket?.statusText,
  } : null;

  return myRequestsCard;
}


export const onlyPlanningandLostIdFilterSelected = (filters: SelectedFilter[]): boolean => {
  const filtersBookingStatusValues = filters.find((filter) => filter.key === FILTERS.BOOKING_STATUS)?.values.map((value) => value.id && value.id.toUpperCase());
  const filtersCategoryValues = filters.find((filter) => filter.key === FILTERS.CATEGORY)?.values.map((value) => value.id && value.id.toUpperCase());
  const filtersDateValues = filters.find((filter) => filter.key === FILTERS.DATE)?.values.map((value) => value.id && value.id.toUpperCase());
  const filtersLocationValues = filters.find((filter) => filter.key === FILTERS.LOCATION)?.values.map((value) => value.id && value.id.toUpperCase());
  if (isEmpty(filtersBookingStatusValues)) {
    return false;
  }
  const containsOtherBookingStatus = filtersBookingStatusValues && filtersBookingStatusValues.some((e) => e !== PAGE_BOOKINGS_STATUS.AWAITING && e !== PAGE_BOOKINGS_STATUS.PLANNING);
  if (isEmpty(filtersCategoryValues) && isEmpty(filtersDateValues) && isEmpty(filtersLocationValues)) {
    return !containsOtherBookingStatus;
  } else {
    if (!isEmpty(filtersDateValues)) {
      return false;
    }
    if (!isEmpty(filtersLocationValues)) {
      return false;
    } if (!isEmpty(filtersCategoryValues)) {
      return !containsOtherBookingStatus;
    }
  }
  return !containsOtherBookingStatus;
}
