import React, { useEffect } from 'react';
import { Text, TouchableOpacity, View, StyleSheet, Image } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import { CROSS_ICON } from 'apps/post-sales/src/cab/CabImageConstants';
import isEmpty from 'lodash/isEmpty';
import ButtonWpr from '../../../../components/ButtonWpr';
import { CAB_CONFIRM_LANDING_URL } from 'apps/post-sales/src/utils/NetworkUtils';
import useApi from 'apps/post-sales/src/Common/useApi';
import getStyles from './styles';
import { LOBNAMES } from 'apps/post-sales/src/PostSalesConstant';
import CabBookingTrackingHelper from 'apps/post-sales/src/cab/CabBookingTrackingHelper';
import CabBookingDetailsConstant, { CLICK_EVENT } from 'apps/post-sales/src/cab/CabBookingDetailsConstant';
import { showShortToast } from 'packages/legacy-commons/Common/Components/Toast';
interface ConfirmLandingOverlayProps {
  handleBottomOverlay: (value: string) => void;
  pageName: string;
  cardData: {
    bookingId: string;
    confirmYourLandingData: {
      heading: string;
      subHeading: string;
      desc: string;
      imageUrl: string;
      actionList: {
        actionLabelText: string;
        actionType: string;
      }[];
    };
  };
  fetchDetailInProgress: boolean;
  reloadPage: () => void;
}

const ConfirmLandingOverlay = ({
  handleBottomOverlay,
  pageName,
  cardData,
  fetchDetailInProgress,
  reloadPage,
}: ConfirmLandingOverlayProps) => {
  const { fsStyle, ...fonts } = getFont();
  const { psTheme: theme } = useTheme();
  const styles = getStyles(fonts, theme);
  const [inProgress, response, api] = useApi(CAB_CONFIRM_LANDING_URL);

  useEffect(() => {
    if (!isEmpty(response)) {
      if (response.confirmLanding) {
        typeof reloadPage === 'function' && reloadPage();
      } else if(response.errorMsg){
        showShortToast(response.errorMsg);
      }else {
        showShortToast(CabBookingDetailsConstant.DEFAULT_ERROR_MESSAGE);
      }
    }
  }, [response]);

  useEffect(() => {
    CabBookingTrackingHelper.trackClickEvent(
      pageName,
      CLICK_EVENT.CONFIRM_YOUR_LANDING_OVERLAY_SHOWN,
    );
  }, []);

  const { confirmYourLandingData, bookingId } = cardData || {};
  if(isEmpty(confirmYourLandingData)) return null;
  const { heading, subHeading, desc, imageUrl, actionList } = confirmYourLandingData || {};

  const handleConfirmClick = () => {
    api.httpPost({
      body: {
        bookingId: bookingId,
      },
      bookingId: bookingId,
      uniqueId: 'LANDING_CONFIRM_CLICK',
      psLob: LOBNAMES.CAR,
    });
  };

  return (
    <View style={styles.card}>
      <View style={styles.headingSection}>
        {!!heading && (
          <View style={AtomicCss.flex1}>
            <Text style={styles.headingText}>{heading}</Text>
          </View>
        )}
        <TouchableOpacity onPress={() => handleBottomOverlay('')}>
          <Image style={styles.crossIconStyle} source={CROSS_ICON} />
        </TouchableOpacity>
      </View>
      {!!subHeading && <Text style={styles.subHeading}>{subHeading}</Text>}
      <View
        style={[
          AtomicCss.flexRow,
          AtomicCss.spaceBetween,
          AtomicCss.alignCenter,
          AtomicCss.marginBottom48,
        ]}
      >
        {!!desc && <Text style={[styles.description, AtomicCss.flex1]}>{desc}</Text>}
        {!!imageUrl && <Image style={[styles.landingImg]} source={{ uri: imageUrl }} />}
      </View>
      {!isEmpty(actionList) &&
        actionList.map((action) => {
          const { actionLabelText, actionType } = action || {};
          return (
            <ButtonWpr
              buttonText={actionLabelText}
              onButtonPress={handleConfirmClick}
              showBtnLoader={inProgress || !!fetchDetailInProgress}
              buttonType={actionType}
              loaderSize={22}
            />
          );
        })}
    </View>
  );
};

export default ConfirmLandingOverlay;
