import {Platform} from 'react-native';
import {
  colors,
  fonts,
  statusBarHeightForIphone,
  statusBarBootomHeightForIphone,
} from './globalStyles';
import getPlatformElevation from '../Common/Components/Card/getPlatformElevation';

const styles = {
  displayFlex: { display: 'flex' },
  flexRow: { flexDirection: 'row' },
  flexColumn: { flexDirection: 'column' },
  flex1: { flex: 1 },
  flex0: { flex: 0 },
  flex2: { flex: 2 },
  textUppercase: { textTransform: 'uppercase' },
  flexWrap: { flexWrap: 'wrap' },
  flexGrow1: { flexGrow: 1 },
  elevation1: { elevation: 1 },
  elevation2: { elevation: 2 },
  elevation3: { elevation: 3 },
  makeRelative: { position: 'relative' },
  alignCenter: { alignItems: 'center' },
  alignStart: { alignItems: 'flex-start' },
  alignItemsStart: { alignItems: 'flex-start' },
  alignItemEnd: {alignItems: 'flex-end'},
  textCenter: { textAlign: 'center' },
  textCenterVertical: { textAlignVertical: 'center' },
  alignLeft: { textAlign: 'left' },
  alignRight: { textAlign: 'right' },
  alignSelfCenter: { alignSelf: 'center' },
  alignSelfStart: { alignSelf: 'flex-start' },
  alignSelfFlexEnd: { alignSelf: 'flex-end' },
  lineThrought: { textDecorationLine: 'line-through' },
  underLine: { textDecorationLine: 'underline' },
  spaceBetween: { justifyContent: 'space-between' },
  spaceEvenly: { justifyContent: 'space-evenly' },
  spaceAround: { justifyContent: 'space-around' },
  justifyStart: { justifyContent: 'flex-start' },
  justifyEnd: { justifyContent: 'flex-end' },
  justifyCenter: { justifyContent: 'center' },
  whiteBg: { backgroundColor: colors.white },
  greyBg: { backgroundColor: '#f2f2f2' },
  lightBlueBg: { backgroundColor: colors.lighterBlue },
  pushLeft: { marginRight: 'auto' },
  pushRight: { marginLeft: 'auto' },
  lineHeight18: { lineHeight: 18 },
  lineHeight12: { lineHeight: 12 },
  lineHeight14: { lineHeight: 14 },
  lineHeight15: { lineHeight: 15 },
  lineHeight16: { lineHeight: 16 },
  lineHeight20: { lineHeight: 20 },
  lineHeight21: { lineHeight: 21 },
  lineHeight22: { lineHeight: 22 },
  lineHeight24: { lineHeight: 24 },
  lineHeight28: { lineHeight: 28 },
  lineHeight34: { lineHeight: 34 },
  lineHeight38: { lineHeight: 38 },
  margin5: { margin: 5 },
  margin4: { margin: 4 },
  margin10: { margin: 10 },
  marginTop10: { marginTop: 10 },
  marginTop12: { marginTop: 12 },
  marginTop2: { marginTop: 2 },
  marginTop3: { marginTop: 3 },
  marginTop4: { marginTop: 4 },
  marginTop5: { marginTop: 5 },
  marginTop8: { marginTop: 8 },
  marginTop15: { marginTop: 15 },
  marginTop16: { marginTop: 16 },
  marginTop28: { marginTop: 28 },
  marginTop30: { marginTop: 30 },
  marginTop40: { marginTop: 40 },
  marginTop20: { marginTop: 20 },
  marginTop25: { marginTop: 25 },
  marginTop100: { marginTop: 100 },
  marginTop0: { marginTop: 0 },
  marginBottom0: { marginBottom: 0 },
  marginBottom2: { marginBottom: 2 },
  marginBottom3: { marginBottom: 3 },
  marginBottom4: { marginBottom: 4 },
  marginBottom5: { marginBottom: 5 },
  marginBottom6: { marginBottom: 6 },
  marginBottom8: { marginBottom: 8 },
  marginBottom10: { marginBottom: 10 },
  marginBottom12: { marginBottom: 12 },
  marginBottom13: { marginBottom: 13 },
  marginBottom14: { marginBottom: 14 },
  marginBottom15: { marginBottom: 15 },
  marginBottom16: { marginBottom: 16 },
  marginBottom18: { marginBottom: 18 },
  marginBottom20: { marginBottom: 20 },
  marginBottom24: { marginBottom: 24 },
  marginBottom25: { marginBottom: 25 },
  marginBottom26: { marginBottom: 26 },
  marginBottom48: { marginBottom: 48 },
  marginLeft12: { marginLeft: 12 },
  marginLeft14: { marginLeft: 14 },
  marginLeft16: { marginLeft: 16 },
  marginBottom30: { marginBottom: 30 },
  marginBottom40: { marginBottom: 40 },
  marginBottom45: { marginBottom: 45 },
  marginBottom60: { marginBottom: 60 },
  marginVertical20: { marginVertical: 20 },
  marginVertical10: { marginVertical: 10 },
  marginVertical5: { marginVertical: 5 },
  marginVertical8: { marginVertical: 8 },
  marginBottom130: { marginBottom: 130 },
  marginLeft0: { marginLeft: 0 },
  marginLeft3: { marginLeft: 3 },
  marginLeft4: { marginLeft: 4 },
  marginLeft5: { marginLeft: 5 },
  marginLeft8: { marginLeft: 8 },
  marginLeft10: { marginLeft: 10 },
  marginLeft15: { marginLeft: 15 },
  marginLeft20: { marginLeft: 20 },
  marginLeft30: { marginLeft: 30 },
  marginRight2: { marginRight: 2 },
  marginRight4: { marginRight: 4 },
  marginRight5: { marginRight: 5 },
  marginRight6: { marginRight: 6 },
  marginRight8: { marginRight: 8 },
  marginRight10: { marginRight: 10 },
  marginRight12: { marginRight: 12 },
  marginRight30: { marginRight: 30 },
  marginRight15: { marginRight: 15 },
  marginRight16: { marginRight: 16 },
  marginRight18: { marginRight: 18 },
  marginRight20: { marginRight: 20 },
  marginRight96: { marginRight: 96 },
  marginHz8: { marginHorizontal: 8 },
  marginHz12: { marginHorizontal: 12 },
  marginHz16: { marginHorizontal: 16 },
  marginHz20: { marginHorizontal: 20 },
  paddingTop3: { paddingTop: 3 },
  paddingTop5: { paddingTop: 5 },
  paddingTop7: { paddingTop: 7 },
  paddingTop10: { paddingTop: 10 },
  paddingTop12: { paddingTop: 12 },
  paddingTop16: { paddingTop: 16 },
  paddingTop25: { paddingTop: 25},
  marginRight35: { marginRight: 35 },
  paddingTop35: { paddingTop: 35 },
  paddingTop36: { paddingTop: 36 },
  paddingTop40: { paddingTop: 40 },
  paddingTop22: { paddingTop: 22 },
  paddingAll8: { padding: 8 },
  paddingAll16: { padding: 16 },
  paddingAll18: { padding: 18 },
  paddingAll20: { padding: 20 },
  paddingAll12: { padding: 12 },
  paddingAll10: { padding: 10 },
  paddingAll5: { padding: 5 },
  paddingAll6: { padding: 6 },
  paddingLeft0: { paddingLeft: 0 },
  paddingLeft5: { paddingLeft: 5 },
  paddingLeft10: { paddingLeft: 10 },
  paddingLeft12: { paddingLeft: 12 },
  paddingLeft15: { paddingLeft: 15 },
  paddingLeft16: { paddingLeft: 16 },
  paddingLeft18: { paddingLeft: 18 },
  paddingLeft20: { paddingLeft: 20 },
  paddingLeft25: { paddingLeft: 25 },
  paddingLeft24: { paddingLeft: 24 },
  paddingLeft30: { paddingLeft: 30 },
  paddingRight5: { paddingRight: 5 },
  paddingRight8: { paddingRight: 8 },
  paddingRight10: { paddingRight: 10 },
  paddingRight12: { paddingRight: 12 },
  paddingRight15: { paddingRight: 15 },
  paddingRight16: { paddingRight: 16 },
  paddingRight20: { paddingRight: 20 },
  paddingRight25: { paddingRight: 25 },
  paddingRight35: { paddingRight: 35 },
  paddingBottom5: { paddingBottom: 5 },
  paddingBottom1: { paddingBottom: 1 },
  paddingBottom8: { paddingBottom: 8 },
  paddingBottom0: { paddingBottom: 0 },
  paddingBottom10: { paddingBottom: 10 },
  paddingBottom12: { paddingBottom: 12 },
  paddingBottom14: { paddingBottom: 14 },
  paddingBottom15: { paddingBottom: 15 },
  paddingBottom20: { paddingBottom: 20 },
  paddingBottom24: { paddingBottom: 24 },
  paddingBottom30: { paddingBottom: 30 },
  paddingBottom70: { paddingBottom: 70 },
  paddingBottom100: { paddingBottom: 100 },
  paddingHz3: { paddingHorizontal: 3 },
  paddingHz5: { paddingHorizontal: 5 },
  paddingHz24: { paddingHorizontal: 24 },
  paddingHz10: { paddingHorizontal: 10 },
  paddingHz12: { paddingHorizontal: 12 },
  paddingHz15: { paddingHorizontal: 15 },
  paddingHz20: { paddingHorizontal: 20 },
  paddingVertical10: { paddingVertical: 10 },
  paddingHz16: { paddingHorizontal: 16 },
  borderWidth0: { borderWidth: 0 },
  borderRightWidth1: { borderRightWidth: 1 },
  paddingHz8: { paddingHorizontal: 8 },
  paddingVertical3: {paddingVertical: 3},
  paddingVertical5: { paddingVertical: 5 },
  paddingVertical8: { paddingVertical: 8 },
  paddingVertical12: { paddingVertical: 12 },
  paddingVertical14: { paddingVertical: 14 },
  paddingVertical16: { paddingVertical: 16 },
  tapableArea: {
    left: 10,
    top: 10,
    right: 10,
    bottom: 10,
  },
  whiteCard: {
    backgroundColor: '#ffffff',
    padding: 15,
    marginVertical: 8,
    ...getPlatformElevation(2),
    borderBottomWidth: 1,
    borderBottomColor: colors.lightGrey,
  },
  whiteCardNew: {
    backgroundColor: '#ffffff',
    marginBottom: 12,
    borderRadius: 10,
    ...getPlatformElevation(2),
  },
  borderRadius10: {
    borderRadius: 10,
  },
  borderRadius8: {
    borderRadius: 8,
  },
  borderRadius0: {
    borderRadius: 0,
  },
  borderTopRadius8: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  borderTopRadius16: {
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
  borderBottomRadius8: {
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  borderRadius20: {
    borderRadius: 20,
  },
  borderRadius15: {
    borderRadius: 15,
  },
  borderRadius12: {
    borderRadius: 12,
  },
  borderRadius16: {
    borderRadius: 16,
  },
  borderRadius4: {
    borderRadius: 4,
  },
  borderRadius30: {
    borderRadius: 30,
  },
  borderColorRed: {
    borderColor: colors.red,
  },
  borderColorGrey: {
    borderColor: colors.greyBookedSeat,
  },
  whiteCardInstantRefund: {
    backgroundColor: '#ffffff',
    padding: 15,
    marginVertical: 8,
    ...getPlatformElevation(2),
    borderBottomColor: colors.lightGrey,
  },
  whiteCardHeading: {
    ...getPlatformElevation(2),
    marginBottom: 5,
    backgroundColor: '#ffffff',
    padding: 15,
  },
  whiteCardHeadingTxt: {
    fontFamily: fonts.black,
    color: '#4a4a4a',
    fontSize: 14,
  },
  transperantBgrnd: {
    ...Platform.select({
      ios: {
        backgroundColor: 'transparent',
      },
    }),
  },
  boldFont: {fontFamily: fonts.bold},
  blackFont: {fontFamily: fonts.black},
  regularFont: {fontFamily: fonts.regular},
  lightFont: {fontFamily: fonts.light},
  mediumFont: {fontFamily: fonts.medium},
  italicFont: {fontFamily: fonts.italic},
  font8: {fontSize: 8},
  font9: {fontSize: 9},
  font10: {fontSize: 10},
  font11: {fontSize: 11},
  font12: {fontSize: 12},
  font13: {fontSize: 13},
  font14: {fontSize: 14},
  font15: {fontSize: 15},
  font16: {fontSize: 16},
  font17: {fontSize: 17},
  font18: {fontSize: 18},
  font19: {fontSize: 19},
  font20: {fontSize: 20},
  font22: {fontSize: 22},
  font24: {fontSize: 24},
  font26: {fontSize: 26},
  font30: {fontSize: 30},
  fontWeightBold: { fontWeight: 'bold' },
  fontStyleItalic: { fontStyle: 'italic' },
  blackText: { color: colors.black },
  whiteText: { color: colors.white },
  lightTextColor: { color: colors.lightTextColor },
  greenText: { color: '#1a7971' },
  lightSeaGreenTextColor: { color: '#249995' },
  yellowText: { color: '#cf8100' },
  blueText: { color: '#008cff' },
  selago: { color: '#E7F2FC' },
  royalBlue: { color: '#3380E5' },
  lightBlueText: { color: '#7fb4fe' },
  bgTransparent: { backgroundColor: colors.transparent },
  TextTransparent: { color: colors.transparent },
  lightGreyText: { color: colors.lightGrey },
  greyText: { color: colors.grey },
  orangeText: { color: colors.orange },
  defaultText: { color: colors.defaultTextColor },
  darkGreyText: { color: colors.darkGrey },
  lightText: { color: colors.lightTextColor },
  redText: { color: colors.red },
  errorRedText: { color: colors.errorRed },
  linkText: { color: colors.linkBlue },
  azure: { color: colors.azure },
  midGreyText: { color: '#747474' },
  primaryText: { color: colors.primary },
  lightGreen16Text: { color: colors.lightGreen16 },
  safeAreaTopMarginMinus: { marginTop: -statusBarHeightForIphone },
  safeAreaTopMarginPlus: { marginTop: statusBarHeightForIphone },
  safeAreaBottomMarginMinus: { marginBottom: -statusBarBootomHeightForIphone },
  overflow: { overflow: 'hidden' },
  height4: { height: 4 },
  height8: { height: 8 },
  height12: { height: 12 },
  opacity60: { opacity: 0.6 },
  headingTxt: {
    fontSize: 14,
    color: '#4a4a4a',
    fontFamily: fonts.bold,
    paddingBottom: 2,
  },
  headingSubTxt: {
    fontSize: 11,
    color: '#9b9b9b',
    fontFamily: fonts.regular,
  },
  whiteSection: {
    shadowColor: '#330000',
    shadowOpacity: 0.3,
    shadowRadius: 2,
    shadowOffset: {width: 0, height: 2},
    elevation: 1,
    backgroundColor: '#fff',
    marginBottom: 10,
  },
  regularTextFont: {
    fontFamily: 'Lato-Regular',
  },
  blackTextFont: {
    fontFamily: 'Lato-Black',
  },
  safeBlueColor: {
    color: '#008cff',
  },
  cardTitle: {
    color: colors.gray,
    fontSize: 21,
    fontFamily: fonts.light,
    marginBottom: 24,
  },
  whiteSectionCard: {
    backgroundColor: '#ffffff',
    padding: 20,
    ...getPlatformElevation(2),
    borderBottomWidth: 1,
    borderBottomColor: colors.lightGrey,
  },
  adTechCard: {
    margin : 10,
    alignSelf: 'flex-end',
  },
  blueIconBg: {
    tintColor: 'white',
  },

  lightBlueIconBg: {
    tintColor: '#008cff',
  },

  blueMyBizIconBg: {
    tintColor: '#fff',
  },

  lightBlueMyBizIconBg: {
    tintColor: '#f17531',
  },

  blueMyBizBg: {
    backgroundColor: '#f17531',
  },

  lightBlueMyBizBg: {
    backgroundColor: '#fdf0ec',
  },

  grayBg: {
    backgroundColor: colors.grayBg,
  },

  blueMyBizText: {
    color: colors.white,
  },

  lightBlueMyBizText: {
    color: '#f17531',
  },
  width100: {
    width: '100%',
  },
  width90: {
    width: '90%',
  },
  width90: {
    width: '90%',
  },
  width80: {
    width: '80%',
  },
  width50: {
    width: '50%',
  },
  width73: {
    width: '73%',
  },
  purpleText: {
    color: colors.purple2,
  },
  imgStyle16: {
    width: 16,
    height: 16,
  },
  seperator: {
    height: 1,
    backgroundColor: colors.lightGrey,
  },
};

export default styles;
