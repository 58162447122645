import React from 'react';
import { Text, View, Image, ScrollView, StyleSheet, Platform } from 'react-native';
import _ from 'lodash';
import Actions from '../../../../../navigation/postSalesNavigation';
import HTMLView from 'react-native-htmlview';
import FlightBookingModule from '@mmt/legacy-commons/Native/FlightBookingModule';
import createStyles, {createHtmlStyle} from './styles';
import PaxDtls from './PaxDtls';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import SimpleHeader from '@mmt/legacy-commons/Common/Components/Header/SimpleHeader';
import {DcViewState} from '../../constants';
import DateChangeBaseClass from '../DateChangeBaseClass';
import Button from '../../../../../Common/Button';
import { CALENDAR_GREEN_ICON, CALENDAR_GREEN_TICK_ICON } from '../../../../FlightImageConstants';
import { openDialer } from '../../../../../Common/commonUtil';
import {
  makeSubmitRequestAPI,
} from '../../data/api';
import { getErrorPageDataFromAPIResponse,
  getErrorPageDataFromReviewAPI} from "../../utils";
import { ThemeContext } from '../../../../../theme/theme.context';
import { getStaticData } from '../../../../../staticData/staticData';



class DateChangeRequest extends DateChangeBaseClass {
  static navigationOptions = {
    header: null,
  };
  static contextType = ThemeContext;

  constructor(props) {
    super(props, 'dateChangeSubmitRequest');
    this.state = {
      viewState: props.viewState,
    };
  }

  componentDidMount() {
    super.componentDidMount();
    if (this.props.pageType === 'PAYMENT_SUBMIT_REQUEST') {
      this.makeSubmitRequestCall();
    }
  }

  render() {
    const { fsStyle, ...fonts } = getFont(true);
    const styles = createStyles(this.context.psTheme, fonts);
    const {loadingText} = getStaticData();
    return (
      <View style={{ flex: 1 }}>
        {this.state.viewState === DcViewState.DEFAULT && this.renderDefaultView(styles)}
        {this.state.viewState === DcViewState.LOADING && this.renderProgressView(loadingText)}
        {this.state.viewState === DcViewState.HOLD_SUCCESS && this.openNextPage()}
        {this.state.viewState === DcViewState.HOLD_API_FAILURE_RESPONSE &&
          this.openErrorPage(
            getErrorPageDataFromAPIResponse(
              this.state.holdResponse,
              'Mob:customer support:Dom flight:datechange_requesterror',
              this.btnClick,
              this.onBackPressFromError,
              this.state.errorReason,
            ),
          )}
        {this.state.viewState === DcViewState.ERROR &&
          this.openErrorPage(
            getErrorPageDataFromReviewAPI(
              this.props.contactNoList,
              this.props.mobileHeaderData,
              this.onBackPressFromError,
              this.btnClick,
              this.state.errorReason,
            ),
          )}
        {this.state.viewState === DcViewState.NO_INTERNET && this.renderNoNetworkView()}
        {this.state.viewState === DcViewState.AFTER_PAYMENT &&
          this.openModificationThankYouPage(this.props.paymentResponse)}
      </View>
    );
  }

  renderDefaultView = (styles) => {
    const {
      submitDateChangeDetailList: { title, policyList },
      passengersRequestData,
      mobileHeaderData: { header },
      actionBtn,
      pageType,
    } = this.props;
    let requestList;
    if (passengersRequestData) {
      requestList = passengersRequestData.requestList;
    }
    const { fsStyle, ...fonts } = getFont(true);
    const htmlStyle = createHtmlStyle(this.context.psTheme,fonts);
    return (
      <View style={styles.pageWrapper}>
        <SimpleHeader title={header} iconPress={() => Actions.pop()} />
        <ScrollView showsVerticalScrollIndicator={false} style={styles.pageScroll}>
          <View style={[styles.whiteCard]}>
            <View style={[AtomicCss.flexRow, AtomicCss.alignSelfStart]}>
              <Image style={styles.headingInfoIcon} source={CALENDAR_GREEN_ICON} />
              <View style={[styles.infoWrapper, AtomicCss.marginBottom10]}>
                {!_.isEmpty(title) && <Text style={styles.cancelTopHeading}>{title}</Text>}
                {!_.isEmpty(policyList) &&
                  policyList.map((text, index) => (
                    <HTMLView
                      style={[
                        styles.descriptionInfo,
                        index !== policyList.length - 1 ? AtomicCss.marginBottom10 : '',
                      ]}
                      value={text}
                      stylesheet={htmlStyle}
                    />
                  ))}
              </View>
            </View>
            {!_.isEmpty(requestList) && (
              <View style={styles.paxDtlsWrapper}>
                {requestList.map((request) => (
                  <PaxDtls {...request} />
                ))}
              </View>
            )}
            {pageType === 'DATE_CHANGE_WARNING' && this.getBtn(actionBtn, styles)}
          </View>
        </ScrollView>
        {pageType === 'DATE_CHANGE_REQUEST' && this.getBtn(actionBtn, styles)}
      </View>
    );
  };

  getBtn = (actionBtn, styles) => {
    const btnList = actionBtn.filter((btnData) => btnData.actionType !== 'REVIEW_PAGE');
    if (!_.isEmpty(btnList)) {
      return btnList.map((btnData) => (
        <View style={styles.btnWrapper}>
          <Button
            btnTxt={btnData.title}
            btnBg={btnData.buttonType.includes('WHITE') ? 'white' : 'gradientBtn'}
            clickHandler={(actionType) => this.btnClick(actionType, false)}
            btnClickAction={btnData.actionType}
          />
        </View>
      ));
    }
    return null;
  };

  btnClick = (actionType, popBtnClickedPage) => {
    if (popBtnClickedPage) {
      Actions.pop();
    }
    switch (actionType) {
      case 'CALL_TO_AIRLINE':
        openDialer(this.props.contactNoList.CALL_TO_AIRLINE.contactNumberList[0]);
        break;
      case 'CALL_TO_US':
        openDialer(this.props.contactNoList.CALL_TO_US.contactNumberList[0]);
        break;
      case 'SUBMIT_REQUEST':
      case 'TRY_AGAIN':
        this.makeSubmitRequestCall();
        break;
      default:
        break;
    }
  };

  openNextPage = () => {
    const { nextStep } = this.state.holdResponse;
    if (nextStep === 'PAYMENT') {
      this.state.holdResponse.dateChangeType = 'NOT_TRUE_DATE_CHANGE';
      if (Platform.OS === 'web') {
        if (this.state.holdResponse.paymentUrl) {
          // eslint-disable-next-line no-undef
          location.href = this.state.holdResponse.paymentUrl;
        }
      } else {
        FlightBookingModule.openFlightDateChangePaymentPage(
          JSON.stringify(this.state.holdResponse),
        );
      }
      Actions.pop();
    } else {
      this.openThankYouPage(this.state.holdResponse, 'datechangethankyousuccess_request');
    }
  };

  openThankYouPage = (holdResponse, omniturePageName) => {
    const {
      heading,
      tagLine,
      descriptionList,
      actionBtn: { title },
    } = holdResponse.thankyouPageData;
    const { bookingId } = holdResponse;
    Actions.flightCommonThankYou({
      icon: CALENDAR_GREEN_TICK_ICON,
      iconStyle: {
        width: 80,
        height: 80,
        marginBottom: 8,
      },
      title: heading,
      descriptionBoldText: tagLine,
      descriptionList,
      btnTitle: title,
      bookingId,
      omniturePageName,
    });
    if (
      this.props.pageType !== 'PAYMENT_SUBMIT_REQUEST' &&
      this.props.pageType !== 'AFTER_PAYMENT'
    ) {
      this.setState({
        viewState: DcViewState.DEFAULT,
      });
    }
  };

  openErrorPage = (errorPageData) => {
    Actions.flightCommonError(errorPageData);
    if (
      this.props.pageType !== 'PAYMENT_SUBMIT_REQUEST' &&
      this.props.pageType !== 'AFTER_PAYMENT'
    ) {
      this.setState({
        viewState: DcViewState.DEFAULT,
      });
    }
  };

  onBackPressFromError = () => {
    if (
      this.props.pageType === 'PAYMENT_SUBMIT_REQUEST' ||
      this.props.pageType !== 'AFTER_PAYMENT'
    ) {
      Actions.popTo('flightRescheduleReviewPage');
    } else {
      Actions.pop();
    }
    return true;
  };

  makeSubmitRequestCall = async () => {
    this.setState({
      viewState: DcViewState.LOADING,
    });
    const result = await makeSubmitRequestAPI(this.props);
    this.setState({ ...result });
  };

  openModificationThankYouPage = (data) => {
    const paymentResponse = JSON.parse(data.PAYMENT_RESPONSE_VO);
    const respObj = JSON.parse(paymentResponse.response);
    if (respObj.status) {
      this.openThankYouPage(respObj, 'datechangethankyousuccess_Payment');
    } else {
      this.openErrorPage(
        getErrorPageDataFromAPIResponse(
          respObj,
          'Mob:customer support:Dom flight:datechange_paymenterror',
          this.btnClick,
          this.onBackPressFromError,
          this.state.errorReason,
        ),
      );
    }
  };
}

export default DateChangeRequest;
