import React from 'react';
import { Text, View, Image } from 'react-native';
import createStyles from './FlightInfoDtlsCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import FltAmentiesDtls from './FltAmentiesDtls';
import { BLACK_RIGHT_ARROW } from '../../../../../../../../FlightImageConstants';
import { getBaggageDataInfo } from '../../../../data/dataAdapter';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import { DateChangeConstants } from 'apps/post-sales/src/flights/modules/DateChange/constants';

const getTerminalData = (tNo) => {
  if (tNo) {
    return ', Terminal '.concat(tNo);
  }
  return '';
};
const getLegViewCard = (legs, baggageData,styles,fonts, fsStyle) => {
  const legViewLst = [];

  legs.forEach((leg) => {
    legViewLst.push(
      <View style={[AtomicCss.whiteSectionCard, AtomicCss.marginBottom10]}>
        <View style={AtomicCss.flexRow}>
          <View style={[AtomicCss.spaceBetween]}>
            <View>
              <Text style={styles.FltTime}>{leg.departInfo.time}</Text>
              <Text style={styles.FltDateInfo}>
                {leg.departInfo.date.split(',')[0].toUpperCase().slice(0, -3)}
              </Text>
            </View>
            <View>
              <Text style={styles.FltTime}>{leg.arrivalInfo.time}</Text>
              <Text style={styles.FltDateInfo}>
                {leg.arrivalInfo.date.split(',')[0].toUpperCase().slice(0, -3)}
              </Text>
            </View>
          </View>
          <View style={AtomicCss.flex1}>
            <View style={styles.fltInfoCircleTop} />
            <View style={styles.fltInfoInnerDtls}>
              <View style={AtomicCss.marginBottom20}>
                <Text style={[fsStyle.font12, styles.boldText]}>
                  {leg.departInfo.terminalName}
                  <Text style={styles.LightText}>{getTerminalData(leg.departInfo.terminalNo)}</Text>
                </Text>
                <Text style={[fsStyle.font12, styles.boldText]}>
                  {leg.departInfo.cityCode},
                  <Text style={styles.LightText}>{leg.departInfo.cityName}</Text>
                </Text>
              </View>
              <View style={styles.fltAmentiesWrapper}>
                <FltAmentiesDtls legDetails={leg} />
              </View>
              <View style={[styles.marginBottom20]}>
                <Text
                  style={[
                    fsStyle.font11,
                    styles.boldText,
                    getBaggageInfo(leg, baggageData,styles,fonts).length == 0 ? styles.hidden : null,
                  ]}
                >
                  {DateChangeConstants.BAG_ALLOWANCE}
                </Text>
                <Text style={[fsStyle.font11]}>{getBaggageInfo(leg, baggageData,styles,fonts)}</Text>
              </View>
              <View>
                <Text style={[fsStyle.font12, styles.boldText]}>
                  {leg.arrivalInfo.terminalName}
                  <Text style={styles.LightText}>
                    {getTerminalData(leg.arrivalInfo.terminalNo)}
                  </Text>
                </Text>
                <Text style={[fsStyle.font12, styles.boldText]}>
                  {leg.arrivalInfo.cityCode},
                  <Text style={styles.LightText}>{leg.arrivalInfo.cityName}</Text>
                </Text>
              </View>
            </View>
            <View style={styles.fltInfoCircleBottom} />
          </View>
        </View>
      </View>,
    );
  });
  return legViewLst;
};

const getBaggageInfo = (leg, baggageData,styles,fonts) => {
    const bagInfo = getBaggageDataInfo(leg,baggageData);
    const baggageViews = []
    if (bagInfo)
      {bagInfo.forEach((bag) => baggageViews.push(
        <View>
          <Text style={[styles.font11]} />
          {bag}
        </View>
      )
    )}
  return baggageViews;
};

const FlightInfoDtls = (props) => {
  const { fsStyle, ...fonts } = getFont(true);
  const {psTheme: theme} = useTheme()
  const styles = createStyles(theme,fonts);
  const outOfPolicy = !props.reviewDetail?.corpPolicyInfo?.withinPolicy;
  const jrnyVwLst = [];
  props.reviewDetail.modifiedJourney.forEach((journey) => {
    const titleArray = journey.title.split('-');
    const subTitleArray = journey.subTitle.split('|');
    jrnyVwLst.push(
      <View>
        <View
          style={[
            AtomicCss.flexRow,
            AtomicCss.spaceBetween,
            AtomicCss.whiteBg,
            styles.FlightInfoTopSection,
          ]}
        >
          <View style={AtomicCss.flex1}>
            <View style={AtomicCss.flexRow}>
              <Text style={styles.FltDestTxt}>{titleArray[0]}</Text>
              <Image style={styles.BlackArrow} source={BLACK_RIGHT_ARROW} />
              <Text style={styles.FltDestTxt}>{titleArray[1]}</Text>
            </View>
            <Text style={styles.FltDate}>{subTitleArray[0]}</Text>
          </View>
          <View>
            <Text style={styles.FltTime}>{subTitleArray[1]}</Text>
            <Text style={styles.FltStop}>{subTitleArray[2]}</Text>
          </View>
        </View>
        {getLegViewCard(journey.leg, props.reviewDetail.baggageData,styles,fonts, fsStyle)}
      </View>,
    );
  });
  return (
    <View>
      {outOfPolicy && (
        <View style={styles.flightPolicy}>
          <Text
            style={[
              fsStyle.font12,
              theme.color.red,
              fonts.boldFontFamily,
              AtomicCss.marginBottom3,
            ]}
          >
            {DateChangeConstants.OutOfPolicyHeading}
          </Text>
          <Text style={[fsStyle.font12, AtomicCss.darkTxt, fonts.regularFontFamily]}>
            {DateChangeConstants.OutOfPolicySubHeading}
          </Text>
        </View>
      )}

      <View style={[AtomicCss.flexRow, styles.FlightInfoTopSection]}>
        <Text style={styles.info_greenTxt}>{DateChangeConstants.OutOfPolicySubHeading2}</Text>
      </View>
      {jrnyVwLst}
    </View>
  );
};

export default FlightInfoDtls;
