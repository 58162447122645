import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';
import {fonts} from '@mmt/legacy-commons/Styles/globalStyles';

const styles = {
  infoCardWrapper: {
    ...getPlatformElevation(2),
    paddingVertical: 20,
    paddingHorizontal: 16,
    justifyContent: 'space-between',
    marginBottom: 10
  },
  infoCardHading: {
    flexDirection: 'row'
  },
  headingTxt: {
    flex: 1,
    fontSize: 16,
    fontFamily: fonts.bold,
    marginBottom: 15
  },
  infoCardDisc: {
    fontSize: 14,
    color: '#4a4a4a',
    lineHeight: 18,
    paddingLeft: 20,
    fontFamily: fonts.regular,
    flex: 1
  },
  bulletCircle: {
    width: 6,
    height: 6,
    backgroundColor: '#5a5a5a',
    borderRadius: 20,
    top: 5,
    left: 5
  },
  infoIcon: {
    width: 20,
    height: 20,
    marginRight: 10
  },
  redirectBtnWrapper: {
    paddingHorizontal: 15,
    paddingVertical: 15,
    justifyContent: 'space-between',
    flexDirection: 'row',
    backgroundColor: '#fff',
    ...getPlatformElevation(2),
    alignItems: 'center',
    marginTop: 2,
    marginBottom: 1
  },
  rightArrowIcon: {
    width: 9,
    height: 13
  },
  linkBtnTxt: {
    fontSize: 14,
    fontFamily: fonts.bold,
    color: '#008cff'
  }
};
export default styles;
