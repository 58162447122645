import React from 'react';
import { RNCardContainer, RNLabel, RNView, RNRowContainer, RNTouchableOpacity, RNIcon } from 'core-rn-ui-factory';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { useTheme } from '../../../../theme/theme.context';
import createStyles from './dateChangeStyle';
import isEmpty from 'lodash/isEmpty';

interface DateModificationCardProps {
  dateModificationCard: {
    heading: string;
    selectedDates: {
      iconUrl: string;
      description: string;
    };
    editIconUrl: string;
  };
  onDateChange: () => void;
}

const DateModificationCard: React.FC<DateModificationCardProps> = ({ dateModificationCard, onDateChange }) => {
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme);
  return (
    <RNCardContainer style={[styles.cardContainer, AtomicCss.whiteBg, AtomicCss.marginTop12]}>
      <RNView>
        {!!dateModificationCard.heading && <RNLabel>{dateModificationCard.heading}</RNLabel>}
        <RNRowContainer style={styles.selectedDateContainer}>
          {!isEmpty(dateModificationCard.selectedDates) &&
            <RNRowContainer>
              {!!dateModificationCard.selectedDates.iconUrl &&
                <RNIcon
                  source={{ uri: dateModificationCard.selectedDates.iconUrl }}
                  size="sm"
                  testID={'bottomsheet_cross_icon'}
                />
              }
              {!!dateModificationCard.selectedDates.description &&
                <RNLabel style={{ marginLeft: 8 }} size="smallW2" shade="highEmphasis">
                  {dateModificationCard.selectedDates.description}
                </RNLabel>
              }
            </RNRowContainer>
          }
          {!!dateModificationCard.editIconUrl &&
            <RNTouchableOpacity onPress={onDateChange}>
              <RNIcon
                source={{ uri: dateModificationCard.editIconUrl }}
                size="sm"
                testID={'bottomsheet_cross_icon'}
              />
            </RNTouchableOpacity>
          }
        </RNRowContainer>
      </RNView>
    </RNCardContainer>
  );
};

export default DateModificationCard;