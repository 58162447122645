import { isCorporateBookingId } from '../../../../../../utils/PostSaleUtil';
import { getUserPlatform } from '../../../../../../utils/NetworkUtils';

export const getReviewAPIRequest = (bookingId, crKey, rKey, recomId, sessionId) => {
    let request;
    if (isCorporateBookingId(bookingId)) {
      request = {
        commonInfo: crKey,
        recommendation: rKey,
      };
    } else {
      request = {
        commonInfo: crKey,
        recomId: recomId,
        bookingId: bookingId,
        sessionId: sessionId,
      };
    }
    return request;
  };
  
  export const getHoldAPIRequest = (userInfo, bookingId, crKey, rKey, recomId, sessionId) => {
    let request;
    if (isCorporateBookingId(bookingId)) {
      request = {
        commonInfo: crKey,
        recommendation: rKey,
        appVersion: '1.0',
      };
    } else {
      request = {
        authId: userInfo.mmtAuth,
        platformSource: getUserPlatform().toUpperCase(),
        commonInfo: crKey,
        recomId: recomId,
        bookingId: bookingId,
        sessionId: sessionId,
      };
    }
    return request;
  };
  