
import React, { useEffect, useRef } from 'react';
import { StyleSheet, Text, View, Image, TouchableOpacity } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import CommonBottomOverlayMessage from '../../../Common/CommonOverlayMessage/CommonBottomOverlayMessage';
import { resetToHotelDetail } from '../../details/utils/HotelBookingDetailUtil';
import Actions from '../../../navigation/postSalesNavigation';
import { getStaticData } from '../../../staticData/staticData';
import { EDIT_BLUE_ICON } from '../../HotelImageConstants';
import { isEmpty } from 'lodash';
import HotelBookingTrackingHelper from '../../HotelBookingTrackingHelper';
import { BACK_TO_BOOKING_CLICK, EDIT_CLICK, MODIFICATION_ACTION } from '../HotelModificationConstants';
import { LANG_AWARE } from '../../HotelConstants';
import { useTheme } from '../../../theme/theme.context';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import useHardwareBackpress from 'apps/post-sales/src/Common/useHardwareBackpress';
import ButtonWrapper from 'apps/post-sales/src/Common/ButtonWrapper';

const ModificationFailureOverlay = (props) => {
	const {modificationFlowV2, title, actionList, handleTryAgain, header, overlayType, overlayTitle, overlayPrimaryBtnProps, overlayEditProps, response, pageName, hotelDetailsResponse, dismiss } = props;
	const {psTheme: theme}=useTheme();
	const { fsStyle, ...fonts } = getFont(true);
	const styles  = createStyle(theme, fonts);
	const { bookingID } = response;
	const { backToBooking, edit } = getStaticData(LANG_AWARE.MODIFICATION);
	const trackingPageName = useRef(pageName);
	const goToBookingDetailsPage = () => {
		resetToHotelDetail(bookingID);
	}
	useHardwareBackpress().addListener(goToBookingDetailsPage);

	useEffect(() => {
		const eventValue = modificationFlowV2 ? `${modificationFlowV2}_shown` : `${overlayType}Shown`;
		HotelBookingTrackingHelper.trackShowEvent(trackingPageName.current, eventValue, hotelDetailsResponse);
	}, []);

	const navigateTobooking = () => {
		dismiss()
		HotelBookingTrackingHelper.trackClickEvent(trackingPageName.current, `${overlayType}_${BACK_TO_BOOKING_CLICK}`, hotelDetailsResponse);
		resetToHotelDetail(bookingID)
	}

	const onPressPrimaryBtn = () => {
		const { clickEvent = '' } = overlayPrimaryBtnProps || {};
		HotelBookingTrackingHelper.trackClickEvent(trackingPageName.current, `${overlayType}_${clickEvent}`, hotelDetailsResponse);
		overlayPrimaryBtnProps.onTap();
	}

  const editBtnHandler = () => {
		HotelBookingTrackingHelper.trackClickEvent(trackingPageName.current, `${overlayType}_${EDIT_CLICK}`, hotelDetailsResponse);
    Actions.pop();
  }

  const handleModificationClick = (action = {}) => {
	const { TRY_AGAIN, BACK_TO_BOOKING } = MODIFICATION_ACTION;
	const { ctaActionFamily } = action || {};
	HotelBookingTrackingHelper.trackClickEvent(trackingPageName.current, `${modificationFlowV2}_${ctaActionFamily}_click`, hotelDetailsResponse);
	switch (ctaActionFamily) {
		case TRY_AGAIN:
			handleTryAgain();
			break;
		case BACK_TO_BOOKING:
			dismiss();
			Actions.popTo('hotelBookingDetail');
			break;
		default:
			break;
	}
 }

  if(modificationFlowV2){
	return (
		<>
			<View style={AtomicCss.marginBottom5}>
				<View style={styles.overlayTopline}></View>
			</View>
			{!!title && <Text style={styles.title}>{title}</Text>}
			{!!header && <Text style={[fonts.regularFontFamily, fsStyle.font16, AtomicCss.blackText, AtomicCss.marginBottom12]}>{header}</Text>}
			{!isEmpty(actionList) && 
				actionList.map((callToAction, index) => {
				const { ctaText, ctaActionFamily } = callToAction || {}
				return !!ctaText && (
					<ButtonWrapper
						key={`${ctaActionFamily}_${index}`}
						clickHandler={() => handleModificationClick(callToAction)}
						btnText={ctaText}
						buttonType={index ? 'outline' : 'fill'}
						isThrottleRequired={true}
						throttleInterval={1000}
						btnWrpStyle={[AtomicCss.paddingHz12, AtomicCss.marginBottom12, AtomicCss.marginTop4]}
					/>
				)
				})
			}
		</>
	)
  }

	return (
			<>
				<View style={AtomicCss.marginBottom5}>
					<View style={styles.overlayTopline}></View>
				</View>
				{overlayEditProps && !isEmpty(overlayEditProps.title) && <View style={[AtomicCss.flexRow]}>
					<Text>{overlayEditProps.title}</Text>
					<TouchableOpacity style={[AtomicCss.flexRow, AtomicCss.alignCenter]} onPress={editBtnHandler}>
						<Image style={styles.editImgStyle} source={EDIT_BLUE_ICON} />
						<Text style={styles.editText}>{edit}</Text>
					</TouchableOpacity>
				</View>}
				{!isEmpty(overlayTitle) && <Text style={styles.title}>{overlayTitle}</Text>}
				<Text style={[fonts.regularFontFamily, fsStyle.font16, AtomicCss.blackText, AtomicCss.marginBottom12]}>
					{header}
				</Text>

				<View style={[AtomicCss.marginTop40, AtomicCss.marginBottom20]}>
					{overlayPrimaryBtnProps && !isEmpty(overlayPrimaryBtnProps.btnTxt) && 
						<ButtonWrapper
							clickHandler={onPressPrimaryBtn}
							buttonTextStyle={fsStyle.font16}
							btnText={overlayPrimaryBtnProps.btnTxt.toUpperCase()}
					  	/>
					}
					<TouchableOpacity onPress={navigateTobooking}>
						<Text style={[styles.backToBooking]}>{backToBooking}</Text>
					</TouchableOpacity>
				</View>
			</>
	)
}

const createStyle = (theme, fonts)=> StyleSheet.create({
	title: {
		color: colors.black,
		...fonts.font22,
		fontFamily: fonts.black,
		marginVertical: 10,
		lineHeight: 32
	},
	backToBooking: {
		color: theme.color.primary,
		...fonts.font16,
		fontFamily: fonts.black,
		lineHeight: 20,
		textAlign: 'center',
		marginTop: 20
	},
	overlayTopline: {
		width: 40,
		height: 4,
		borderRadius: 4,
		backgroundColor: '#c2c2c2',
		alignSelf: 'center',
	},
	editImgStyle: {
		width: 12,
		height: 12,
		marginStart: 10
	},
	editText: {
		color: colors.primary,
		...fonts.font12,
		fontFamily: fonts.black,
		lineHeight: 12,
		marginLeft:5
	}

})

export default React.memo(ModificationFailureOverlay);
