import React from "react";
import {appendUrlPrefix} from "../../webUtils";
import loadable from "@loadable/component";
import ProgressView from "../../components/ProgressView";

const TRIP_GUARANTEE_PATHS = {
    TRIP_GUARANTEE_BOOKING_DETAIL: {
        pathName: appendUrlPrefix('/tripGuarantee'),
        pageKey: "tripGuaranteeBookingDetail",
        Component: loadable(() => import("@rn/apps/post-sales/src/TripGuarantee/pages/details"), { fallback: <ProgressView /> })
    },
    TRIP_GUARANTEE_PRICE_BREAKUP : {
        pathName: appendUrlPrefix('/tgsPriceBreakUp'),
        pageKey: "tgsPriceBreakUp",
        Component: loadable(() => import("@rn/apps/post-sales/src/TripGuarantee/pages/priceBreakup"), { fallback: <ProgressView /> })
    },
   
}

export default TRIP_GUARANTEE_PATHS;


