import Actions from '../../../navigation/postSalesNavigation';
import React from 'react';
import {ScrollView, View} from 'react-native';
import BasePage from '../../../Common/PostSalesBasePage';
import styles from '../../CSS/VisaMamiCss';
import StickyHeader from '../../../hotel/details/components/StickyHeader';
import VisaFAQListing from './VisaFAQListing';
import PropTypes from 'prop-types';
import {TripHeaderIcon} from '../../Constant/VisaImageConstant';


export default class VisaFAQ extends BasePage {
  constructor(props) {
    super(props, 'ALLFAQS');
  }
    static navigationOptions = {
      header: null
    };

    onBackPressed = () => {
      Actions.pop();
    };

    render() {
      const faqs = this.props.response.faqList;
      return (
        <View style={styles.MimaMainWrapper}>
          <StickyHeader
            headerColor={['#ffffff', '#ffffff']}
            iconSrc={TripHeaderIcon}
            title="Frequently Asked Questions"
            subTitle=""
            titleStyle={[styles.BlackText, styles.boldFont, styles.bgTransparent, styles.font16]}
            subTitleStyle={[styles.BlackText, styles.boldFont, styles.bgTransparent, styles.font16]}
            onBackPress={this.onBackPressed}
          />
          <ScrollView>
            <View style={{}}>
              <View style={[styles.MakeRelative, styles.mar20Bt]} >
                <VisaFAQListing
                  faqs={faqs}
                  pageName={"ALL FAQS"}
                />
              </View>
            </View>
          </ScrollView>
        </View>);
    }
}

VisaFAQ.propTypes = {
  response: PropTypes.object.isRequired
};

