const CsatFeedbackConst = {
    YES: 'Yes',
    NO: 'No',
    NUDGE_CARD: 'Nudge',
    INLINE_CARD: 'Inlinecard',
    INLINE: 'Inline',
    DISMISSED: 'Dismissed',
}

export default CsatFeedbackConst;
