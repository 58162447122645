const RailBookingDetailConstant = {
    ACTION_DOWNLOAD_TICKET: 'DownloadTicket',
    ACTION_DOWNLOAD_INVOICE: 'DownloadInvoice',
    ACTION_CALL_MAKEMYTRIP: 'CallMakeMyTrip',
    RAIL_TGS_THANKYOU_EVENT: "rail_booking_details_amendment_thankyou_page",
    ACTION_TICKET_TRIGGER: 'Rail_Eticket',
    ACTION_INVOICE_TRIGGER: 'Rail_CustomerInvoice',
    TOP_STATUS_CARD_CAN_INIT_TO_REFUND_DONE: 'TopStatusCard_BetweenCanInitToRefundDone',
    TOP_STATUS_CARD_AFTER_REFUND_DONE: 'TopStatusCard_AfterRefundDone',
    TOP_STATUS_CARD_BOOKING_TO_BOARDING_TIME_BOOKING: 'TopStatusCard_BookingToBoardingTime',
    TOP_STATUS_CARD_ON_TRIP: 'TopStatusCard_OnTrip',
    TOP_STATUS_CARD_ON_TRIP_PART_CANCEL: 'TopStatusCard_PartiallyCancelledAtLeast1Ontrip',
    TOP_STATUS_CARD_COMPLETTION_OF_TRIP_TO_FOREVER: 'TopStatusCard_CompletionOfTripToForever',
    TOP_STATUS_CARD_LOST_FAILED_BOOKING: 'TopStatusCard_LostFailedBooking',
    TOP_STATUS_CARD_LOST_FAILED_BOOKING_CANCELLED: 'TopStatusCard_LostFailedBookingCancelled',
    TOP_STATUS_CARD_PARTIALY_CAN_AT_LEAST_1_UPCOMING: 'TopStatusCard_PartiallyCancelledAtLeast1Upcoming',
    TOP_STATUS_CARD_PARTIALY_CAN_AT_REST_ALL_COMPLETED: 'TopStatusCard_PartiallyCancelledRestAllCompleted',
    TOP_STATUS_CARD_12_HRS_AFTER_BOOKING: 'TopStatusCard_BookingTo12HrsAfterBooking',
    TOP_STATUS_CARD_48_HRS_BEFORE_TRAVEL: 'TopStatusCard_AfterBookingTo48HrsBeforeTravel',
    TOP_STATUS_CARD_48_TO_24_HRS_BEFORE_TRAVEL: 'TopStatusCard_48HrsBeforeTravelTo24HrsBeforeTravel',
    TOP_STATUS_CARD_24_HRS_TO_ON_TRIP: 'TopStatusCard_24HrsBeforeTravelToOnTrip',
    TOP_STATUS_CARD_UNCONFIRMED: 'TopStatusCard_Unconfirmed',
    TOP_STATUS_CARD_DEFAULT_COMPLETED: 'TopStatusCard_DEFAULT_COMPLETED',
    TOP_STATUS_CARD_DEFAULT_CONFIRMED: 'TopStatusCard_DEFAULT_CONFIRMED',
    TOP_STATUS_CARD_DEFAULT_CANCELLED: 'TopStatusCard_DEFAULT_CANCELLED',
    TOP_STATUS_CARD_JUST_DEFAULT_MODIFIED: 'TopStatusCard_DEFAULT_MODIFIED',
    TOP_STATUS_CARD_JUST_AFTER_MODIFICATION: 'TopStatusCard_JustAfterModification',
    TOP_STATUS_CARD_MODIFIED_BOOKING_ON_TRIP: 'TopStatusCard_ModifiedBookingOnTrip',
    TOP_STATUS_CARD_MODIFIED_BOOKING_TRIP_COMPLETED: 'ModifiedBookingTripCompleted',
    RAIL_ITINERARY_CARD: 'RailItineraryCard',
    RAIL_UNCONFIRMED_INFORMATION_CARD: "RailUnconfirmedInfoCard",
    RAIL_TRAVELLER_CARD: 'RailTravellerCard',
    PAYMENT_PENDING_CARD: 'PaymentPendingCard',
    RAIL_PRICE_CARD: 'RailPriceCard',
    RAIL_REFUND_PROGRESS_CARD: 'RefundProgressCard',
    RAIL_REFUND_TRACKER_CARD: 'RefundTrackerCard',
    CONTACT_DETAILS_CARD: 'ContactDetails',
    RAIL_HOTEL_CROSS_SELL: 'RailHotelCrossSellCard',
    RAIL_CHANGE_IN_PLANS_CARD: 'ChangeInPlansCard',
    RAIL_TGS_Card: 'RailTgStatusCard',
    RAIL_NEED_HELP_CARD: 'NeedHelpCard',
    RAIL_NEED_HELP_CARD_MMT: 'needHelpMakemytrip',
    RAIL_RED_BANNER_CARD: 'RailRedBannerCard',
    RAIL_RED_TRAVEL_BANNER_CARD: 'RailRedTravelBannerCard',
    RAIL_GREEN_BANNER_CARD: 'RailGreenBannerCard',
    RAIL_DETAIL_GREEN_BANNER_CARD: 'RailDetailGreenBannerCard',
    RAIL_DOWNLOAD_VOUCHER: 'RAIL_DOWNLOAD_VOUCHER',
    RECTANGLE_FULL_BUTTON: 'RecFullBtn',
    RECTANGLE_PRIMARY_FULL_WIDTH: 'CapsuleFullBtnFill',
    RECTANGLE_PRIMARY_HALF_WIDTH: 'CapsuleHalfBtnFill',
    RECTANGLE_SECONDARY_FULL_WIDTH: 'CapsuleFullBtn',
    RECTANGLE_SECONDARY_HALF_WIDTH: 'CapsuleHalfBtn',
    WHITE_BUTTON_FULL: 'CapsuleWhiteBtn',
    WHITE_BUTTON_HALF: 'CapsuleWhiteBtnHalf',
    RAIL_CANCELLATION_POLICY: 'RailCancellationPolicy',
    ACTION_CANCEL_FULL_BOOKING: 'CancelFullBooking',
    ACTION_CANCEL_PARTIAL_BOOKING: 'CancelPartBooking',
    ACTION_TDR_FILE: 'FileTdrBooking',
    ACTION_TRAIN_CANCELLED: 'CancelTrainBooking',
    ACTION_CALL_HELPLINE: 'CallHelpline',
    ACTION_PAYMENT_DETAILS_PAGE: 'SeePriceBreakup',
    ACTION_RAIL_MAKE_REQUEST: 'RailMakeRequest',
    ACTION_RAIL_GOTO_HELP_SECTION: 'GoToRailHelpSection',
    ACTION_RAIL_CALL_US: 'RailCallUs',
    ACTION_PNR_STATUS: 'PnrStatus',
    HELPLINE_NUMBER: '0124 4628747',
    ALERT_INFO_CARD: 'alertinfocard',
    E_COUPON_TYPE_DISCOUNT: 'e_coupon',
    CASHBACK_TYPE: 'cashback',
    RAIL_TDR_PROCEDURE: 'FileTdrProcedure',
    RAIL_BOTTOM_OVERLAY: 'BottomOverlay',
    RAIL_HELP_SECTION_CARD: 'RailHelpSection',
    LIVE_TRAIN_STATION: 'LiveTrainStatus',
    RAIL_LIVE_PNR_CHECK: 'TrainPnrStatus',
    TRAIN_COACH_POSITION: 'TrainCoachPosition',
    RAIL_TRIP_ASSURANCE_CARD: 'RailTripAssuranceCard',
    RAIL_TGS_UPGRADE_CARD: 'RailTGSUpgradeCard',
    RAIL_TRIP_ASSURANCE_LABEL: 'TRIP ASSURANCE',
    RAILOFY_IMPORTANT_INFORMATION: 'ZcplusImportantInformation',
    RAIL_CONTACT_MAKEMYTRIP: 'contactMakemyTrip',
    RAIL_ADD_TECH_CARD: 'RailAddTechCard',
    ADVISORY_NOTICE: 'AdvisoryNoticeCard',
    DOWNLOAD_FAILED: 'DOWNLOAD_FAILED',
    RAIL_STORAGE_PERMISSION: 'rail_booking_details_storage_permission',
    UNIQUE_ID_TYPE: 'RAIL_INVOICE',
    BUS_CROSS_SELL : 'BusCrossSellCard',
    PAGE_NAME : 'bookingDetail',
    NEED_MORE_HELP_CARD: 'NeedMoreHelpCard',
    RAIL_REFUND_VOUCHER_CARD: 'RefundVoucherCard',
    MSR_MAIN_CARD: 'MsrMainCard',
    FLAT_WHITE_BUTTON: 'FlatWhiteBtn',
    RAIL_SCHEDULE_CARD:'RailSchedule'
};

export const RAIL_OVERLAY = {
    TRIP_GUARANTEE : 'tripGuarantee',
    FREE_CANCELLATION : 'FreeCancellation',
    CANCELLATION_POLICY : "CancellationPolicy",
    TGS: 'TGS',
    PENDING_PAYMENT : 'PendingPayment'
}

export const LOADING = {
    TGS_HOLD : 'tgs_hold'
}

export const RAIL_TGS = {
    HOLD: 'hold'
}

export const RAIL_TRACKING_CONSTANT =  {
    RAIL_BOOKING_DETAILS_PAGE: 'TripDetails',
    RAC_TRAVEL: 'RAC_Travel',
    RAC_CANCEL: 'RAC_Cancel',
    RAC_CONFIRM_CANCEL: 'RAC_ConfirmCancel',
    RAC_TG_REFUND: 'RAC_TGRefund',
    RAC_TG_2X_CONFIRM_CANCEL: 'RAC_TG_2x_ConfirmCancel'

}
export default RailBookingDetailConstant;
