import React from 'react';
import PropTypes from 'prop-types';
import { Button, Text, View, Image, Platform } from 'react-native'
import QRCode from 'react-qr-code';
import styles from './MainCardCss';
import AtomicCss from '../AtomicCss';
import {fillDateAndTime} from '@mmt/legacy-commons/Common/utils/DateUtils';
import {isNotNullAndEmpty} from '../../../hotel/details/utils/HotelBookingDetailUtil';
import {colors} from '@mmt/legacy-commons/Styles/globalStyles';
import {isNil} from 'lodash';
import {has} from 'lodash'
import AcmeBookingDetailsConstant from '../../utils/AcmeBookingDetailsConstant'
import { maybePluralize } from '../../utils/AcmeBookingDetailUtil'
import { isNullOrEmpty } from '../../../Common/commonUtil';
import { downloadFile } from '@mmt/legacy-commons/Common/utils/fileUtils';
import VisaBookingModule from '@mmt/legacy-commons/Native/VisaBookingModule';
import CovidTag from './CovidTag';

class AcmeBookingDetailCard extends React.Component {
  static navigationOptions = {
    header: null
  };
  componentDidMount(){
    if(Platform.OS !== 'web'){
      import ('@mmt/legacy-commons/Common/utils/fileUtils').then(
        downloadFile=>{
          this.downloadFile = downloadFile;
        });
    }
  }
  render() {
    const acmeBookingInfo = this.props.response.acmeBookingInfo.acmeBookingDetails[0];
    const { coverImageUrl } = this.props.response.acmeBookingInfo.acmeBookingDetails[0].acmeAdditionalInfo;
    return (
      <View style={[AtomicCss.whiteBg, AtomicCss.marginBottom10]}>
        <View style={[styles.CardContentFull]}>
          <View style={AtomicCss.flexRow}>
            <View style={AtomicCss.flex1}>
              <Text
                style={[AtomicCss.font18, AtomicCss.boldFont, AtomicCss.blackText, AtomicCss.marginBottom5, AtomicCss.lineHeight24]}
              >
                {acmeBookingInfo.productname}
              </Text>
              <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
                <Text style={[AtomicCss.font12, AtomicCss.regularFont, AtomicCss.midGreyText]}>
                  {acmeBookingInfo.addressDetails.cityName}
                </Text>
              </View>
              {this.props.response.isgosafe &&
                <View style={{marginTop: 20}}>
                  <CovidTag/>
                </View>
              }
            </View>
            <View style={[AtomicCss.overflow]}>
              <View style={[AtomicCss.overflow]}>
                {
                  this.props.holidayBookingApi ?
                    isNotNullAndEmpty(coverImageUrl) && <Image
                      style={styles.coverImg}
                      source={{
                        uri: this.props.response.acmeBookingInfo.acmeBookingDetails[0].acmeAdditionalInfo.coverImageUrl
                      }}
                    /> :
                  isNotNullAndEmpty(this.props.response.acmeBookingInfo) && this.props.response.acmeBookingInfo.showQrCode && <QRCode
                    value={this.props.response.scanCode}
                    size={86}
                    fgColor={colors.black}
                    bgColor={colors.white}
                  />
                }
              </View>
            </View>
          </View>
          {this.getViews()}
        </View>
        {!this.props.holidayBookingApi && <DownloadButton response={this.props.response} />}
      </View>
    );
  }

  getViews() {
    const views = [];
    const {acmeBookingInfo} = this.props.response;
    const acmeDetails = acmeBookingInfo.acmeBookingDetails[0];
    if (isNotNullAndEmpty(acmeDetails.packagename)) {
      this.addView(views, [styles.CardInfoRow], 'PACKAGE NAME', acmeDetails.packagename);
    }
    if (acmeDetails.freeHoldFlag) {
      const expiryDateTime = `Upto ${fillDateAndTime(acmeDetails.endDate, 'DD MMM YY')} , ${this.formatAMPM(acmeDetails.endTime)}`;
      if (acmeDetails.slotAvailableFlag) {
        this.addView(views, [styles.CardInfoRow], 'SLOT', `${this.formatAMPM(acmeDetails.slotStartTime)} - ${this.formatAMPM(acmeDetails.slotEndTime)}`);
      }
      this.addView(views, [styles.CardInfoRow], 'VALID TILL', expiryDateTime);
    } else if (acmeDetails.startDate === acmeDetails.endDate) {
      this.addView(views, [styles.CardInfoRow], 'DATE', this.getSingleDateData(acmeDetails.endDate, acmeDetails.slotAvailableFlag, acmeDetails.slotStartTime, acmeDetails.slotEndTime));
    } else {
      this.addMultiplineView(views, 'DATE', acmeDetails);
    }
    if (isNotNullAndEmpty(acmeDetails.validityText)) {
      this.addView(views, [styles.CardInfoRow], 'SPECIFIC VALIDITY', acmeDetails.validityText);
    }
    if (acmeBookingInfo.unitType === 1) {
      this.addView(views, [styles.CardInfoRow], 'QUANTITY', acmeBookingInfo.totalQuantity);
    } else {
      this.addView(views, [styles.CardInfoRow], 'GUESTS', this.getGuestInfo(acmeBookingInfo));
    }
    if (acmeBookingInfo.acmeBookingDetails[0].addressDetails.locality) {
      this.addView(views, [styles.CardInfoRow, styles.borderBottom0], 'PLACE', acmeBookingInfo.acmeBookingDetails[0].addressDetails.locality);
    }
    return views;
  }

  getSingleDateData(date, showTime, startTime, endTime) {
    let result = fillDateAndTime(date, 'DD MMM YY');
    if (showTime) {
      result += `( ${this.formatAMPM(startTime)} - ${this.formatAMPM(endTime, 'hh:mm A')})`;
    }
    return result;
  }

  addView(views, styleArray, key, value) {
    views.push(<View key={key} style={styleArray}>
      <Text style={[styles.rowLeft, AtomicCss.regularFont]}>{key}</Text>
      <Text style={[styles.rowRight, AtomicCss.blackFont, AtomicCss.flex1]}>{value}</Text>
    </View>);
  }

  addMultiplineView(views, key, acmeDetails) {
    views.push(<View key={key} style={[styles.CardInfoRow]}>
      <Text style={[styles.rowLeft, AtomicCss.regularFont]}>{key}</Text>
      <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
        <View style={styles.width110}>
          <Text
            style={[AtomicCss.font12, AtomicCss.blackFont, AtomicCss.blackText, AtomicCss.marginBottom5]}
          >{fillDateAndTime(acmeDetails.startDate, 'ddd, DD MMM YY')}
          </Text>
          {acmeDetails.slotAvailableFlag
          && <Text style={[AtomicCss.font12, AtomicCss.regularFont, AtomicCss.midGreyText]}>Starts
            at {this.formatAMPM(acmeDetails.startTime)}
          </Text>
          }
        </View>
        <View style={styles.width25}><Text>-</Text></View>
        <View style={styles.width110}>
          <Text
            style={[AtomicCss.font12, AtomicCss.blackFont, AtomicCss.blackText, AtomicCss.marginBottom5]}
          >{fillDateAndTime(acmeDetails.endDate, 'ddd, DD MMM YY')}
          </Text>
          {acmeDetails.slotAvailableFlag &&
          <Text style={[AtomicCss.font12, AtomicCss.regularFont, AtomicCss.midGreyText]}>Ends
            at {this.formatAMPM(acmeDetails.endTime)}
          </Text>
          }
        </View>
      </View>
    </View>);
  }

  getGuestInfo(acmeBookingInfo) {
    if (!has(acmeBookingInfo, 'unitTypeDetails')){
      return '';
    }

    const {unitTypeDetails} = acmeBookingInfo
    let result = '';
    let separator = '  '
    let suffix = 'S';
    for (var KEY in unitTypeDetails) {
      const tempObj = unitTypeDetails[KEY]
      const { unittype, unitCount, displayname } = tempObj

      let unitType = unittype;

      if (isNotNullAndEmpty(unitType)){
      suffix = (KEY === AcmeBookingDetailsConstant.UNIT_TYPE.CHILD)
        ? 'REN'
        : 'S'
      } else {
        switch (KEY) {
          case AcmeBookingDetailsConstant.UNIT_TYPE.CHILD:
            unitType = 'CHILD'
            suffix = (KEY === AcmeBookingDetailsConstant.UNIT_TYPE.CHILD)
              ? 'REN'
              : 'S'
            break
          case AcmeBookingDetailsConstant.UNIT_TYPE.ADULT:
            unitType = 'ADULT'
            break
          case AcmeBookingDetailsConstant.UNIT_TYPE.INFANT:
            unitType = 'INFANT'
            break
          case AcmeBookingDetailsConstant.UNIT_TYPE.SENIOR_CITIZEN:
            unitType = 'SENIOR CITIZEN'
            break
          case AcmeBookingDetailsConstant.UNIT_TYPE.VOUCHER:
            unitType = 'VOUCHER'
            break
        }
      }

      if (isNullOrEmpty(displayname) && isNotNullAndEmpty(unitType)) {
        console.log(AcmeBookingDetailsConstant.UNIT_TYPE.ADULT )
        result = `${result + maybePluralize(unitCount, unitType, suffix).toUpperCase() + separator}`
      } else {
        result = `${result + separator+ unitCount + separator + displayname + separator}`
      }
    }
    return result;
  }

  formatAMPM(date) {
    const dateArr = date.split(':');
    let hours = dateArr[0];
    let minutes = dateArr[1] === '00' ? '0' : dateArr[1];
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours %= 12;
    hours = hours || 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    const strTime = `${hours}:${minutes} ${ampm}`;
    return strTime;
  }
}

AcmeBookingDetailCard.propTypes = {
  response: PropTypes.object.isRequired
};

const DownloadButton = (res) => {
  // Validate response.
  if (!has(res, 'response.acmeBookingInfo.viatorUrl')){
    return null;
  }

  const { viatorUrl } = res.response.acmeBookingInfo;

  if (isNotNullAndEmpty(viatorUrl)) {
    const ext = viatorUrl.toLowerCase().includes('.pdf') ? 'pdf' :'html';
    return (
      <Button title={'Download Voucher'} 
        onPress={ Platform.OS === 'web' ? 
        () => VisaBookingModule.downloadSingleVisa(viatorUrl, 'Voucher', 'no-cors'): 
        () => downloadFile(viatorUrl, 'Voucher', ext)
        }/>)
  } else {
    return null
  }
}
export default AcmeBookingDetailCard;