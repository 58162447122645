import { StyleSheet } from 'react-native';

export default (theme, fonts) =>
StyleSheet.create({
  steps: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: theme.color.white,
    paddingVertical: 9,
    paddingHorizontal: 15,
    elevation: 2,
    shadowColor: theme.color.black,
    shadowOffset: {width: 0, height: 2},
    shadowOpacity: 0.2,
    shadowRadius: 2,
    position: 'relative',
    zIndex: 2
  },
  stepItem: {
    flexDirection: 'row',
    marginRight: 10,
    justifyContent: 'center',
    alignItems: 'center'
  },
  stepNumberWrapper: {
    backgroundColor: theme.color.disabledBtnBg,
    width: 13,
    height: 13,
    borderRadius: 13,
    marginRight: 3
  },
  stepNumberWrapperActive: {
    backgroundColor: theme.color.black
  },
  stepNumber: {
    fontSize: 10,
    color: theme.color.white,
    lineHeight: 13,
    textAlign: 'center'
  },
  stepName: {
    color: theme.color.disabledBtnBg,
    fontSize: 10
  },
  stepNameActive: {
    color: theme.color.black
  }
});
