import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {View, Text, Image} from 'react-native';
import styles from './CabMamiCss';

import {getActionButton, handleClickEvent, isNotNullAndEmpty} from '../utils/CabBookingDetailUtil';
import {getButtonStyle} from '../../hotel/details/utils/HotelBookingDetailUtil';
import {chatImage} from "../CabImageConstants";
import CabBookingTrackingHelper from "../CabBookingTrackingHelper";
import CardWrapper from '../modules/bookingDetails/components/CardWrapper';

const CabChatWithDriverCard = ({card,response,pageName}) => {

    const handleClick = (action) => {
        handleClickEvent(card, action, response, pageName);
    };

    const header = (card && card.heading) ? card.heading : 'CHAT WITH DRIVER';
    const subText = (card && card.subheading) ? card.subheading: '';
     
    useEffect(()=>{
    CabBookingTrackingHelper.trackLoadEvent('mob: cabs: chat with driver Option shown', response);
    },[])
    
    return(
        <View style={[styles.MamiCard, styles.mar10Bt]}>
            <View style={styles.CardContent}>
                <View style={styles.IconPortion}>
                    <Image style={styles.CardIcon} source={chatImage} />
                </View>
                <View style={styles.ContentPortion}>
                    <Text style={[styles.blackFont, styles.BlackText, styles.pad5Top]}>{header}</Text>
                </View>
            </View>
            <View style={isNotNullAndEmpty(card.actionList) ? [getButtonStyle(card.actionList), styles.transperantBg] : styles.hideView}>
                {getActionButton(card, response, handleClick)}
            </View>
        </View>
    )
}

CabChatWithDriverCard.propTypes = {
    pageName: PropTypes.string.isRequired,
    response: PropTypes.object.isRequired,
    card: PropTypes.object.isRequired
};
export default CardWrapper(CabChatWithDriverCard);
