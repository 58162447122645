import React from 'react';
import { Image, ScrollView, Text, View } from 'react-native';
import StickyHeader from '../../../hotel/details/components/StickyHeader';
import Actions from '../../../navigation/postSalesNavigation';
import styles from './CancelAnywayCss';
import CommonStyle from '@mmt/legacy-commons/Styles/AtomicCss';
import BlueRectangleButton from '../../../Common/BlueRectangleButton';
import BasePage from '../../../Common/PostSalesBasePage';
import PropTypes from 'prop-types';
import ConfirmationOverlay from '../../../Common/ConfirmationOverlay';
import { segmentPaxInfo } from '../Helpers/FlightCancellationUtility';
import CancellationTrackingHelper from '../Helpers/CancellationTrackingHelper';

import { getStaticData } from '../../../staticData/staticData';
import { HEADER_ICON, ERROR_INFO_ICON, FAILED_INFO_ICON } from '../../FlightImageConstants';
import { getFont } from '../../../PostSalesStyles';

const {
  cancelAnyWayRequestText: {
    heading,
    subHeading,
    additionalText: { confirmCancellationRequestText, agentWillContactYouShortlyText },
  },
  submitARequestText,
  submitRequestText,
  areYouSureText,
  backCapsText,
  confirmText,
} = getStaticData();
export default class CancelAnyWayRequest extends BasePage {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 1,
      overlay: false,
    };
    this.trackingPageName = 'CancelAnyway';
    CancellationTrackingHelper.trackLoadEvent(this.trackingPageName, this.props.trackingBundle);
  }

  render() {
    const { fsStyle, ...fonts } = getFont(true);
    return (
      <View style={styles.pageWrapper}>
        <StickyHeader
          iconSrc={HEADER_ICON}
          title={this.props.headerTitle}
          headerColor={['#ffffff', '#ffffff']}
          titleStyle={[styles.headerTitle, fonts.boldFontFamily, fsStyle.font16]}
          onBackPress={Actions.pop}
        />
        <ScrollView ref="pageScroll" showsVerticalScrollIndicator={false} style={styles.pageScroll}>
          <View style={[styles.whiteCard, { marginTop: -2 }]}>
            <View style={CommonStyle.flexRow}>
              <Image style={styles.errorInfoIcon} source={ERROR_INFO_ICON} />
              <View style={styles.cancelInfoWrapper}>
                <Text style={[styles.cancelTopHeading, fonts.blackFontFamily, fsStyle.font16]}>
                  {heading}
                </Text>
                <Text style={[styles.cancelDiscription, fonts.regularFontFamily, fsStyle.font14]}>
                  {subHeading}
                </Text>
              </View>
            </View>
          </View>
          <View style={[styles.whiteCard]}>
            <View style={CommonStyle.flexRow}>
              <Image style={styles.errorInfoIcon} source={FAILED_INFO_ICON} />
              <View style={styles.cancelInfoWrapper}>
                <Text style={[styles.cancelTopHeading, fonts.blackFontFamily, fsStyle.font16]}>
                  {submitARequestText}
                </Text>
                <Text style={[styles.cancelDiscription, fonts.regularFontFamily, fsStyle.font14]}>
                  {agentWillContactYouShortlyText}
                </Text>
              </View>
            </View>
            <View style={styles.cancelPaxDtlsWrapper}>
              {segmentPaxInfo(this.props.cancellationPreviewResponseJson)}
            </View>
            <View style={styles.footerBtnWrapper}>
              <BlueRectangleButton
                buttonText={submitRequestText}
                clickHandler={this.onContinueClicked}
              />
            </View>
          </View>
          {/*//TODO: Myra has to be opened when integrated.*/}
          {/* <View style={styles.dividerWrapper}>
                        <View style={styles.dividerLine}></View>
                        <View style={styles.orDivider}>
                            <Text style={styles.orDividerText}>OR</Text>
                        </View>
                    </View>
                    <TouchableOpacity style={[styles.whiteCard, CommonStyle.flexRow, CommonStyle.spaceBetween]} activeOpacity={.8}>
                        <View style={CommonStyle.flex1}>
                            <Text style={styles.callUsTxt}>Call Us for Cancellation</Text>
                            <Text>Talk to our agent now to cancel your ticket</Text>
                        </View>
                        <Image style={[styles.rightArrow, styles.rtArrowPosition]} source={require('src/Assets/right_arrow_blue.webp')} />
                    </TouchableOpacity> */}
        </ScrollView>
        {this.state.overlay ? this.renderConfirmationPopup() : null}
      </View>
    );
  }

  renderConfirmationPopup() {
    return (
      <ConfirmationOverlay
        content={confirmCancellationRequestText}
        leftBtnClick={this.confirmationPopup}
        rightBtnClick={this.overlayConfirmCancelClick}
        outsideClick={this.confirmationPopup}
        title={areYouSureText}
        rightButtonText={confirmText}
        leftButtonText={backCapsText}
      />
    );
  }

  onContinueClicked = () => {
    this.confirmationPopup();
  };

  confirmationPopup = () => {
    if (this.state.overlay) {
      CancellationTrackingHelper.trackClickEvent(
        this.trackingPageName,
        'confirmpopup_no',
        this.props.trackingBundle,
      );
    }
    this.setState({ overlay: !this.state.overlay });
  };

  overlayConfirmCancelClick = () => {
    CancellationTrackingHelper.trackClickEvent(
      this.trackingPageName,
      'confirmpopup_yes',
      this.props.trackingBundle,
    );
    Actions.cancelAnywaySuccess({
      cancellationRequest: this.props.cancellationRequest,
      trackingBundle: this.props.trackingBundle,
    });
  };
}

CancelAnyWayRequest.propTypes = {
  headerTitle: PropTypes.string.isRequired,
  cancellationPreviewResponseJson: PropTypes.object.isRequired,
  cancellationRequest: PropTypes.object.isRequired,
  trackingBundle: PropTypes.object.isRequired,
};
