import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, TouchableOpacity } from 'react-native';
import styles from '../css/AgreeOverlayCss';
import BasePage from '../../../Common/PostSalesBasePage';
import { getFont } from '../../../PostSalesStyles';
import Checkbox from '../../../Common/Checkbox';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getStaticData } from "../../../staticData/staticData";

let agreemntArr = [];
let agreementSelectLst = [];

export default class AgreeOverlay extends BasePage {
  constructor(props) {
    super(props);
    agreemntArr = [];
    agreementSelectLst = [];
    if (this.props.specialClaimData.checkCond1) {
      agreemntArr.push(this.props.specialClaimData.checkCond1);
      agreementSelectLst.push(false);
    }
    if (this.props.specialClaimData.checkCond2) {
      agreemntArr.push(this.props.specialClaimData.checkCond2);
      agreementSelectLst.push(false);
    }
    if (this.props.specialClaimData.checkCond3) {
      agreemntArr.push(this.props.specialClaimData.checkCond3);
      agreementSelectLst.push(false);
    }
    this.state = {
      agreementSelectList: agreementSelectLst,
      proceedEnabled: false,
    };
  }

  render() {
    const { fsStyle, ...fonts } = getFont(true);
    const staticData = getStaticData();
    const condLstView = [];
    agreemntArr.forEach(function (obj, index) {
      const cndSlctd = this.state.agreementSelectList[index];

      condLstView.push(
        <Checkbox
          checked={cndSlctd}
          onChange={() => this.checkboxHandler(index)}
          label={obj}
          labelStyle={[styles.contentDesc, fonts.regularFontFamily, fsStyle.font14]}
          containerStyle={[styles.checkBtn, AtomicCss.paddingLeft0]}
        />,
      );
    }, this);

    return (
      <View>
        <Text style={[styles.contentTitle, fonts.lightFontFamily, fsStyle.font21]}>
          {staticData.agreeText || 'Please agree to the following before you proceed.'}
        </Text>
        <View style={styles.agreementList}>
          <Text style={[styles.contentDesc, fonts.regularFontFamily, fsStyle.font14]}>
            {staticData.haveUnderstoodText || 'I have understood: '}
          </Text>
          {condLstView}
        </View>
        <View style={styles.contentFooter}>
          <TouchableOpacity
            disabled={!this.state.proceedEnabled}
            style={styles.actionBtn}
            onPress={() => this.props.proceedClickedHandler()}
          >
            <Text
              style={
                this.state.proceedEnabled
                  ? [styles.actionBtnEnabledText, fonts.mediumFontFamily, fsStyle.font16]
                  : [styles.actionBtnDisabledText, fonts.mediumFontFamily, fsStyle.font16]
              }
            >
              YES! PROCEED
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }

  checkboxHandler(index) {
    const agrmntSlctLst = this.state.agreementSelectList;
    agrmntSlctLst[index] = !agrmntSlctLst[index];
    let prcdEnbld = true;
    let tmp = false;
    for (let i = 0; i < agrmntSlctLst.length; i++) {
      if (agrmntSlctLst[i] === false) {
        tmp = true;
        break;
      }
    }
    if (tmp) {
      prcdEnbld = false;
    }
    this.setState({
      agreementSelectList: agrmntSlctLst,
      proceedEnabled: prcdEnbld,
    });
  }
}

AgreeOverlay.propTypes = {
  proceedClickedHandler: PropTypes.func.isRequired,
  specialClaimData: PropTypes.object.isRequired,
};
