import React from 'react';
import { Dimensions, Text, View, Image } from 'react-native';
import { useTheme } from '../../../../../theme/theme.context';
import { getFont } from '../../../../../PostSalesStyles';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import HTMLView from 'react-native-htmlview';
import ButtonWrapper from 'apps/post-sales/src/Common/ButtonWrapper';
import { openCabBookingDetail } from 'apps/post-sales/src/cab/utils/CabBookingDetailUtil';
import getStyle from './style';
import { CabRescheduleThankyouPageProps } from '../../../../types';
import CabBookingTrackingHelper from 'apps/post-sales/src/cab/CabBookingTrackingHelper';
import { CLICK_EVENT, PAGE_NAME } from 'apps/post-sales/src/cab/CabBookingDetailsConstant';

const CabRescheduleThankyouPage: React.FC<CabRescheduleThankyouPageProps> = ({ response, bookingId }) => {
  const height = Dimensions.get('screen').height;
  const { imageUrl, heading, subHeding, checkStatusText, viewBookingText } = response || {};
  const { psTheme } = useTheme();
  const { fsStyle, ...fonts } = getFont();
  const styles = getStyle(fonts, psTheme);

  const handleClick = () => {
    CabBookingTrackingHelper.trackClickEvent(
      PAGE_NAME.CAB_RESCHEDULE_PAGE,
      CLICK_EVENT.CAB_RESCHEDULE_VIEW_BOOKING_CLICK,
    );
    openCabBookingDetail(bookingId);
  };

  return (
    <View style={[AtomicCss.alignCenter, AtomicCss.justifyCenter, styles.paddingAll30, { height }]}>
      <Image source={{ uri: imageUrl }} style={styles.calendarIconStyle} />
      {!!heading && (
        <Text
          style={[
            styles.headingText,
            AtomicCss.textCenter,
            AtomicCss.marginBottom12,
            AtomicCss.lineHeight20,
          ]}
        >
          {heading}
        </Text>
      )}
      {!!subHeding && <HTMLView value={`${subHeding}`} stylesheet={styles} />}
      {!!checkStatusText && <HTMLView value={`${checkStatusText}`} stylesheet={styles} />}
      {!!viewBookingText && (
        <ButtonWrapper
          clickHandler={handleClick}
          btnText={viewBookingText}
          btnWrpStyle={styles.buttonWrapStyle}
          btnTxtStyle={styles.btnTextStyle}
        />
      )}
    </View>
  );
};

export default CabRescheduleThankyouPage;
