import { TRACKING_CONST } from 'react-native-core-ui-module';
import AncillaryTrackingUtil from './../../Ancillary/utils/AncillaryTrackingUtil';
import { getSelectedAncillariesTextForTracking } from './../../Ancillary/utils/AncillaryUtils';
import {
  checkSeatSelectionTypes,
  getEventCytPageName,
} from '../../Ancillary/utils/WebCheckinUtils';
import isEmpty from 'lodash/isEmpty';

const { PAGE_NAME, EVENT_NAME } = TRACKING_CONST;

const TrackingUtil = {
  _trackLoadEvent: (pageName, response) => {
    try {
      const { ancillaryResponse, selectedAncillaryDetail, uniquePassengersLength } = response;
      switch (pageName) {
        case PAGE_NAME.WEBCHECKIN:
          AncillaryTrackingUtil._trackLoadEvent('Webcheckin_sector_selection', ancillaryResponse);
          return;
        case PAGE_NAME.TRAVELLER_DETAIL:
          const pageNameStr = `Traveller Details${uniquePassengersLength === 1 ? '_onepax' : ''}`;
          AncillaryTrackingUtil._trackLoadEvent(
            pageNameStr,
            Object.assign(ancillaryResponse, {
              seatSelectionType: checkSeatSelectionTypes(selectedAncillaryDetail),
            }),
          );
          return;
        case PAGE_NAME.TRAVELLER_DETAIL_INFO:
          AncillaryTrackingUtil._trackLoadEvent('Traveller Details_info', ancillaryResponse);
          return;
        default:
          AncillaryTrackingUtil._trackLoadEvent(pageName, response);
      }
    } catch (e) {}
  },
  _trackLoadEventCyt: (pageName, response, cytExperimentValue = null) => {
    try {
      const { thankyouObj, ancillaryResponse, FltTrackData } = response;
      switch (pageName) {
        case PAGE_NAME.THANK_YOU:
          const pageNameStr = getEventCytPageName(thankyouObj);
          AncillaryTrackingUtil._trackLoadEventCyt(
            pageNameStr,
            ancillaryResponse,
            !isEmpty(FltTrackData) && !isNil(FltTrackData?.ominatureValue)
              ? FltTrackData?.ominatureValue
              : '',
          );
          return;
        default:
          AncillaryTrackingUtil._trackLoadEventCyt(pageName, response, cytExperimentValue);
      }
    } catch (e) {}
  },
  _trackErrorEvent: (pageName, error) => {
    try {
      switch (pageName) {
        case PAGE_NAME.THANK_YOU:
          AncillaryTrackingUtil._trackErrorEvent('CYT', error);
          return;
        default:
          AncillaryTrackingUtil._trackErrorEvent(pageName, error);
      }
    } catch (e) {}
  },
  _trackClickEvent: (pageName, clickEvent, response) => {
    try {
      const {
        addonType,
        selectedAncillaryDetail,
        ancillaryResponse,
        type,
        fromWebCheckin,
        paxLineNo,
        flightDetailResponse,
        uniquePassengersLength,
      } = response;
      switch (pageName) {
        case PAGE_NAME.ADDONS_PAGE:
          switch (clickEvent) {
            case EVENT_NAME[PAGE_NAME.ADDONS_PAGE].CONTINUE_TO_REVIEW:
              const loadedAncText = getSelectedAncillariesTextForTracking(selectedAncillaryDetail);
              AncillaryTrackingUtil._trackClickEvent(
                AncillaryTrackingUtil._getAddOnPageName(addonType),
                `Flight_${loadedAncText}_Attached_and_Continue`,
                ancillaryResponse,
              );
              return;
            case EVENT_NAME[PAGE_NAME.ADDONS_PAGE].BACK:
              AncillaryTrackingUtil._trackClickEvent(
                'MEALS_POPUP',
                'Popup_closed_clicked',
                ancillaryResponse,
              );
              return;
            case EVENT_NAME[PAGE_NAME.ADDONS_PAGE].CONTINUE:
              AncillaryTrackingUtil._trackClickEvent(
                'MEALS_POPUP',
                'CONTINUE_WITHOUT_clicked',
                ancillaryResponse,
              );
              return;
            case EVENT_NAME[PAGE_NAME.ADDONS_PAGE].VIEW_MORE:
              AncillaryTrackingUtil._trackClickEvent(
                AncillaryTrackingUtil._getAddOnPageName(type),
                `FLIGHT_${type}_MORE`,
                ancillaryResponse,
              );
              return;
            case EVENT_NAME[PAGE_NAME.ADDONS_PAGE].VIEW_LESS:
              AncillaryTrackingUtil._trackClickEvent(
                AncillaryTrackingUtil._getAddOnPageName(type),
                `FLIGHT_${type}_LESS`,
                ancillaryResponse,
              );
              return;
          }
          return;
        case PAGE_NAME.SELECT_SEAT:
          switch (clickEvent) {
            case EVENT_NAME[PAGE_NAME.SELECT_SEAT].CONTINUE_TO_REVIEW:
              AncillaryTrackingUtil._trackClickEvent(
                AncillaryTrackingUtil._getSeatPageName(fromWebCheckin),
                'Flight_Seat_Continue_Seats_Attached',
                ancillaryResponse,
              );
              return;
            case EVENT_NAME[PAGE_NAME.SELECT_SEAT].AUTO:
              AncillaryTrackingUtil._trackClickEvent(
                'SEATS_POPUP',
                'AUTO_ASSIGN_clicked',
                ancillaryResponse,
              );
              return;
            case EVENT_NAME[PAGE_NAME.SELECT_SEAT].CONTINUE:
              AncillaryTrackingUtil._trackClickEvent(
                'SEATS_POPUP',
                'CONTINUE_WITHOUT_SEATS_clicked',
                ancillaryResponse,
              );
              return;
          }
          return;
        case PAGE_NAME.WEBCHECKIN:
          const webchekinPage = 'Webcheckin_sector_selection';
          switch (clickEvent) {
            case EVENT_NAME[PAGE_NAME.WEBCHECKIN].PROCEED_SEAT_MANUAL:
              AncillaryTrackingUtil._trackClickEvent(
                webchekinPage,
                'Proceed_webcheckin_autoSeat_manual',
                ancillaryResponse,
              );
              return;
            case EVENT_NAME[PAGE_NAME.WEBCHECKIN].PROCEED_SEAT_AUTO:
              AncillaryTrackingUtil._trackClickEvent(
                webchekinPage,
                `Proceed_webcheckin_seat_auto`,
                ancillaryResponse,
              );
              return;
            case EVENT_NAME[PAGE_NAME.WEBCHECKIN].PROCEED_WEBCHECKIN:
              AncillaryTrackingUtil._trackClickEvent(
                webchekinPage,
                `Proceed_webcheckin`,
                ancillaryResponse,
              );
              return;
            case EVENT_NAME[PAGE_NAME.WEBCHECKIN].PROCEED_WEBCHECKIN:
              AncillaryTrackingUtil._trackClickEvent(
                'Webcheckin',
                'Webcheckin_Outside',
                flightDetailResponse,
              );
              return;
          }
          return;

        case PAGE_NAME.TRAVELLER_DETAIL:
          const travellerPage = `Traveller Details${uniquePassengersLength === 1 ? '_onepax' : ''}`;
          switch (clickEvent) {
            case EVENT_NAME[PAGE_NAME.TRAVELLER_DETAIL].ADD_PAX:
              AncillaryTrackingUtil._trackClickEvent(
                travellerPage,
                `Flight_Traveller_Details_Add_Pax_${paxLineNo}`,
                ancillaryResponse,
              );
              return;
            case EVENT_NAME[PAGE_NAME.TRAVELLER_DETAIL].EDIT_PAX:
              AncillaryTrackingUtil._trackClickEvent(
                travellerPage,
                `Flight_Traveller_Details_Edit_Pax_${paxLineNo}`,
                ancillaryResponse,
              );
              return;
            case EVENT_NAME[PAGE_NAME.TRAVELLER_DETAIL].SUBMIT:
              AncillaryTrackingUtil._trackClickEvent(
                travellerPage,
                'Flight_Traveller_Details_Submit',
                ancillaryResponse,
              );
              return;
            case EVENT_NAME[PAGE_NAME.TRAVELLER_DETAIL].WC_SEAT_MANUAL:
              AncillaryTrackingUtil._trackClickEvent(
                'Traveller_Details',
                'Flight_WC_Traveller_Seat_Manual',
                ancillaryResponse,
              );
              return;
            default:
              AncillaryTrackingUtil._trackClickEvent(pageName, clickEvent, response);
          }
          return;
        case PAGE_NAME.TRAVELLER_DETAIL_INFO:
          const travellerInfoPage = 'Traveller Details_info';
          switch (clickEvent) {
            case EVENT_NAME[PAGE_NAME.TRAVELLER_DETAIL].DETAIL_INFO_NEXT_PAX:
              AncillaryTrackingUtil._trackClickEvent(
                travellerInfoPage,
                `Flight_Traveller_Details_info_Next_Pax_${paxLineNo}`,
                ancillaryResponse,
              );
              return;
            case EVENT_NAME[PAGE_NAME.TRAVELLER_DETAIL].SUBMIT_PAX:
              AncillaryTrackingUtil._trackClickEvent(
                travellerInfoPage,
                `Flight_Traveller_Details_info_Submit_Pax_${paxLineNo}`,
                ancillaryResponse,
              );
              return;
            default:
              AncillaryTrackingUtil._trackClickEvent(pageName, clickEvent, response);
          }
      }
    } catch (e) {}
  },
};

export default TrackingUtil;
