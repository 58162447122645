import React, { useEffect, useRef } from 'react';
import { Animated, Easing, StyleSheet, View } from 'react-native';
import LottieView from 'lottie-react-native';

// Screenshot: https://gommt-my.sharepoint.com/:i:/g/personal/mmt8626_go-mmt_com/ESkdQ5dT45tHsMoTPIUNgAcBgzMJeeXPXCENqI8rQE1-5A?e=3lIdw6
const SuccessLoaderAnim = () => {
  
  const progress = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.timing(progress, {
      toValue: 1,
      duration: 1500,
      easing: Easing.linear,
    }).start();
  }, [progress])

  return (
    <View style={styles.animationContainer}>
      <LottieView
        progress={progress}
        style={styles.lottieViewStyle}
        source={require('./SuccessTick.json')}
      />
    </View> 
  )
}

export default SuccessLoaderAnim;

const styles = StyleSheet.create({
  animationContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    marginBottom: 16
  },
  lottieViewStyle: {
    width: 64,
    height: 64,
  }
});
