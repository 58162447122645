import React, { useState } from 'react';
import { View, Text, TouchableOpacity, Image, ScrollView } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import TabList from '../../../../../../../Common/TabList';
import { rightArrowBlueDropdown } from '../../../../../../HolidayBookingConstants';
import isEmpty from 'lodash/isEmpty';
import { useTheme } from '../../../../../../../theme/theme.context';
import createStyles, { createHtmlAnswerStyles , createHtmlQuestionStyles} from './style';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { getStaticData } from '../../../../../../../staticData/staticData';
import HTMLView from 'react-native-htmlview';


const FAQList = ({ item, activeState, handleFAQ, index, isLastElement, itemToShow }) => {
    const fonts = getFont();
    const {psTheme: theme} = useTheme();
    const styles = createStyles(fonts, theme);
    const activeCard = index === activeState;
    const lastElementStyle = isLastElement ? 'lastElement' : '';
    const thirdRowStyle = index === itemToShow - 1 ? 'thirdRowStyles' : '';
    const htmlQuestionStyles = createHtmlQuestionStyles(fonts, theme);
    const htmlAnswerStyles = createHtmlAnswerStyles(fonts, theme);
    return (
        <View style={[styles.faqRow, styles[lastElementStyle], styles[thirdRowStyle]]}>
            <TouchableOpacity style={[AtomicCss.flexRow, AtomicCss.spaceBetween, AtomicCss.alignCenter]} onPress={() => handleFAQ(index)} activeOpacity={0.9}>
                {!isEmpty(item.question) && <HTMLView stylesheet={htmlQuestionStyles} value= {item.question.trim('\n')} />}
                <Image style={!activeCard ? [styles.arrowDownStyle] : [styles.arrowDownStyle, styles.activeArrow]} source={rightArrowBlueDropdown} />
            </TouchableOpacity>
            {activeCard && !isEmpty(item.answer) &&
                <HTMLView stylesheet={htmlAnswerStyles} value = {item.answer.trim('\n')} />
            }
        </View>
    );
};


const FAQCard = ({title, list, categoriesList, maxItem}) => {
    const fonts = getFont();
    const {psTheme: theme} = useTheme();
    const styles = createStyles(fonts,theme);
    const {viewMoreText} = getStaticData();
    const [activeState, setActiveState] = useState(null);
    const [itemToShow, setItemToShow] = useState(maxItem);
    const [activeCounterTab, setActiveCounterTab] = useState(0);
    const [FaqList, setFaqList] = useState(list.filter(item => item.type === categoriesList[activeCounterTab]));
    const handleFAQ = (active) => {
        setActiveState(activeState === active ? null : active);
    };

    const faqListLen = FaqList.length;
    const handleViewMore = () => {
        setItemToShow(faqListLen);
    };

    const handleCountryTabFilter = (activeTab, type) => {
        const filteredFAQ = list.filter(item => item.type === type);
        setItemToShow(maxItem);
        setActiveCounterTab(activeTab);
        setFaqList(filteredFAQ);
    };

    return (
        <View style={styles.innerCardWrapper}>
            {!isEmpty(title) && <Text style={[styles.titleText, AtomicCss.marginBottom15]}>{title}</Text>}
            <ScrollView contentContainerStyle={styles.countryFilterWrapper} horizontal={true}>
                {!isEmpty(categoriesList) && categoriesList.map((item, index) => {
                    return (
                        <TabList
                            item={item}
                            index={index}
                            handleTab={handleCountryTabFilter}
                            activeCounterTab={activeCounterTab}
                        />
                    );
                })}

            </ScrollView>
            {!isEmpty(FaqList) && FaqList.map((item, index) => {
                while (index < itemToShow) {
                    return (
                        <FAQList
                            item={item}
                            activeState={activeState}
                            handleFAQ={handleFAQ}
                            index={index}
                            itemToShow={itemToShow}
                            isLastElement={index === FaqList.length - 1}
                        />
                    );
                }
            })}
            {FaqList.length > maxItem && itemToShow === maxItem &&
                <TouchableOpacity onPress={handleViewMore}>
                    <Text style={styles.viewMoreText}>{viewMoreText}</Text>
                </TouchableOpacity>
            }
        </View>

    );

};


export default FAQCard;
