import { Platform, StyleSheet } from 'react-native';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';

const styles = StyleSheet.create({
  pageWrapper: {
    backgroundColor: '#f2f2f2',
    flex: 1,
    borderTopColor: Platform.OS === 'ios' ? '#dddddd' : 'transparent',
    borderTopWidth: Platform.OS === 'ios' ? 0 : 0,
  },
  OverlayContent: {
    paddingTop: Platform.OS === 'web' ? 0 : 12,
    position: 'relative',
    width: '100%',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    bottom: 0,
    overflow: 'hidden',
    flex: 1,
    backgroundColor: colors.white,
    ...Platform.select({
      web: {
        overflowY: 'auto'
      }
    }),
  },
  tnCListWrapper: {
    backgroundColor: '#ffffff',
    position: 'absolute',
    bottom: 0,
    width: '100%',
    zIndex: 11,
    elevation: 11,
  },
  overlayContentStyle: {
    backgroundColor: colors.white,
    padding: 20,
    paddingTop: 15,
    position: 'relative',
    zIndex: 1,
    width: '100%',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },
  overlayWrapperStyle: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    elevation: 10,
    zIndex: 100,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    flexDirection: 'row',
  },
});
export default styles;
