import { StyleSheet } from "react-native";
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
export default (theme, fonts) =>StyleSheet.create({
    payableCard:{
        paddingHorizontal:20,
        marginHorizontal:12,
        backgroundColor:theme.color.white,
        borderRadius:8,
        ...getPlatformElevation(2),
        marginBottom:24,
        paddingTop:32,
        paddingBottom:24, 
    },
    lineHeight24:{
        lineHeight:24
    },
    paymentWrapper:{
        borderColor:theme.color.lightGrey,
        borderWidth:1,
        borderRadius:8,
        paddingHorizontal:16,
        paddingTop:22,
        paddingBottom:20,
    },
    seprator:{
        borderBottomColor:theme.color.lightGrey,
        borderBottomWidth:1,
        paddingBottom:20,
        marginBottom:20,
    }
})