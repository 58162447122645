import React from 'react';
import AdtechWrapper from '../../../../../../Common/AdTechWrapper';


interface AdTechCardProps {
  data?: {
    cid: string;
  };
}

const AdTechCard: React.FC<AdTechCardProps> = ({ data }) => {
  const { cid } = data || {};
  if (!cid) {return null;}
  return <AdtechWrapper cid={cid} />;
};

export default AdTechCard;
