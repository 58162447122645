import { getStaticData } from '../../staticData/staticData';
const { viewBreakupText, hideBreakupTest } = getStaticData();

export const REFUND_BREAKUP = {
  SHOW: viewBreakupText || 'View Breakup',
  HIDE: hideBreakupTest || 'Hide Breakup',
};

export const CANCELLATION_VALIDATION = {
  SUCESS: 'success',
};

export const SPECIAL_CLAIM_BOTTOM_SHEET_STATES = {
  REASON_SCREEN: 0,
  SUBMIT_REFUND_SCREEN: 1,
};

export const CANCELLATION_REASONS = {
  PLANS_CHANGED: 'CITP',
  CANCELLED_WITH_AIRLINES: 'TDCWA',
  MISSED_FLIGHT: 'NOSHOW',
  FLIGHT_CANCELLED: 'FNO',
  FLIGHT_RESCHEDULED: 'FDOP',
  SPECIAL_CLAIM: 'SRR',
  ANCILLARY_REFUND: 'ancclaim',
};

export const SPECIAL_CLAIM_STATES = {
  PASSENGER_SELECTION: 0,
  UPLOAD_DOC: 1,
  REFUND_MODE: 2,
  THANK_YOU: 3,
  LOADING: 4,
};

export const BOTTOM_OVERLAY_STATES = {
  REFUND_DETAILS: 1,
  KNOW_MORE: 2,
  KNOW_MORE_UPLOAD: 3,
  SELECT_REFUND_MODE_UPLOAD_PROOF: 4,
  GO_BACK_AND_UPLOAD: 5,
};

export const SPECIAL_CLAIM_ACITONS = {
  view_more: 'view_more',
  okay_got_it: 'okay_got_it',
  know_more: 'know_more',
  know_more_upload: 'know_more_upload',
  upload: 'upload',
  addMore: 'add_more',
  retry: 'retry',
  select_refund_mode: 'select_refund_mode',
  process_to_upload_proof: 'process_to_upload_proof',
  go_back_to_booking: 'go_to_booking',
  confirm_request: 'confirm_request',
  dont_have_comm: 'dont_have_comm',
  go_back_to_upload: 'go_back_to_upload',
  partial_request: 'partial_request',
  go_back_and_upload: 'go_back_and_upload',
};

export const UPLOAD_STATES = {
  INPROGRESS: 1,
  ERROR: 2,
  SUCCESS: 3,
};

export const MAX_UPLOAD_LIMIT = 5;
