import React from 'react';
import PropTypes from 'prop-types';
import { Image, Text, View } from 'react-native';
import createStyles from '../../styles';
import FlightInfoCard from '../FlightInfoCard';
import InfoListCard from '../InfoListCard';
import { INFO_RED_ICON } from '../../../../../../FlightImageConstants';
import { getStaticData } from '../../../../../../../staticData/staticData';
import isEmpty from 'lodash/isEmpty';
import { getFont } from '../../../../../../../PostSalesStyles';
import { useTheme } from '../../../../../../../theme/theme.context';

const SpecificFlightContent = (props) => {
  const {
    cancellationChargesText,
    reschedulingChargesText,
    makemyTripServiceFeesIncluded,
  } = getStaticData();
  const {psTheme: theme} = useTheme();
  const fonts = getFont(true);
  const styles = createStyles(theme, fonts);
  return (
    <View style={styles.flightContent}>
      <View style={styles.whiteCard}>
        <Text style={styles.cardTitle}>
          {props.isCancelChargesPage ? cancellationChargesText : reschedulingChargesText}
        </Text>

        <FlightInfoCard response={props.response} isCancelChargesPage={props.isCancelChargesPage} />
        <Text style={styles.italicText}>{makemyTripServiceFeesIncluded}</Text>
        {!props.isCancelChargesPage && !isEmpty(props.response.datechangeCacheDetail.rcAlertText) && (
          <View style={styles.infoNote}>
            <Image style={styles.infoNoteIcon} source={INFO_RED_ICON} />
            <Text style={styles.infoNoteText}>
              {props.response.datechangeCacheDetail.rcAlertText}
            </Text>
          </View>
        )}
      </View>

      {!props.isCancelChargesPage && props.response.datechangeCacheDetail.rcImpInfo && (
        <InfoListCard
          header={props.response.datechangeCacheDetail.rcImpInfoHeader}
          data={props.response.datechangeCacheDetail.rcImpInfo}
        />
      )}
    </View>
  );
};

SpecificFlightContent.propTypes = {
  response: PropTypes.object.isRequired,
  isCancelChargesPage: PropTypes.bool.isRequired,
};

export default SpecificFlightContent;
