const styles = {
  container: {
    backgroundColor: '#e7e7e7',
    flex: 1,
  },
  detailsWrapper: {
    paddingTop: 12,
    paddingBottom: 24,
  },
  topWrapper: {
    backgroundColor: '#fff',
    padding: 16,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    marginBottom: 20,
  },
};
export default styles;
