// @flow
import React from 'react';
import { getTrainSummaryData } from './trainCardAdapter';
import {
  ActionsContent,
  DescriptionSection,
  JourneyTextRail,
  MainContent,
  NeedHelpStrip,
  NewStatusText,
  RightArrow,
  StaticIcon,
  SummaryContainer,
  TrainCoachInfoText,
} from '../cardElements';
import isEmpty from 'lodash/isEmpty';

function TrainSummaryCard({ response, msrTicket }) {
  const cardData = getTrainSummaryData(response, msrTicket);
  const { needHelpData } = cardData;
  return (
    <SummaryContainer bookingId={response.bookingID}>
      <MainContent onPress={cardData.openDetails}>
        <StaticIcon icon={cardData.iconUrl} iconStyle={{ width: 20, height: 26 }} />
        <DescriptionSection>
          <JourneyTextRail from={cardData.from} to={cardData.to} />
          {!cardData.failedBooking && <TrainCoachInfoText text={cardData.bookingInfo} />}
          <NewStatusText status={cardData.status} text={cardData.dateTime} />
          {!!cardData.dateTimeWithPaxInfo && (
            <NewStatusText status={cardData.status} text={cardData.dateTimeWithPaxInfo} />
          )}
        </DescriptionSection>
        <RightArrow />
      </MainContent>
      {!isEmpty(needHelpData) &&  needHelpData.map((needHelp)=><NeedHelpStrip {...needHelp}/>)}
      <ActionsContent actions={cardData.actions} />
    </SummaryContainer>
  );
}

export default TrainSummaryCard;
