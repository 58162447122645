import React, { useEffect, useState } from 'react';
import { View, Text, TouchableOpacity, Image } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import VisaTypeCard from '../VisaTypeCard';
import SelectTravellerCard from '../SelectTravellerCard';
import { rightArrowBlueDropdown, greenClockIcon } from '../../../../../../HolidayBookingConstants';
import { getFont } from '../../../../../../../PostSalesStyles';
import { useTheme } from '../../../../../../../theme/theme.context';
import createStyles from './style';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { getSelectedPassengersName } from '../../utils';
import ACTION_TYPES from '../../reducer/actions';
import { getStaticData } from 'apps/post-sales/src/staticData/staticData';

const CountryVisaCard = ({
  country,
  countryIndex,
  dispatch,
  selectedCountryData,
  countryPrice,
}) => {
  const fonts = getFont();
  const { psTheme: theme } = useTheme();
  const styles = createStyles(fonts, theme);
  const [isVisaDetailsVisible, setVisaDetailsVisible] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isDetailsSelected, setDetailsSelected] = useState(false);
  const {
    title,
    visaKey,
    travellersKey,
    description,
    visaType: visaTypeData,
    disable,
    disableText,
  } = country || {};
  const { type, travellersSelectionText } = visaTypeData || {};
  const selectedVisaType = get(type, `${selectedIndex}`, {});
  const {processingTimeText} = getStaticData();
  const handleCountryVisa = () => {
    if (disable) {
      return;
    }
    setVisaDetailsVisible(!isVisaDetailsVisible);
  };
  useEffect(() => {
    if (countryPrice > 0) {
      setDetailsSelected(true);
    }
  }, [countryPrice]);
  const onSelectPassenger = (selected, index) => {
    const traveller =
      selectedVisaType && selectedVisaType.travellersList && selectedVisaType.travellersList[index];
    dispatch({
      type: ACTION_TYPES.SELECT_PASSENGER,
      payload: {
        countryIndex: countryIndex,
        selected: selected,
        paxId: traveller.paxId,
        breakup: traveller.visaPriceBreakupList,
        name: traveller.name,
        dateOfBirth: traveller.dateOfBirth,
      },
    });
  };

  const onSelectIndex = (index) => {
    dispatch({
      type: ACTION_TYPES.SELECT_VISA_TYPE,
      payload: {
        index: countryIndex,
        visaTypeId: get(type, `${index}`, 'id', null),
      },
    });
    setSelectedIndex(index);
  };
  const cardStyle =
    isVisaDetailsVisible === false && isDetailsSelected !== true && !disable
      ? 'visaCardWrapper'
      : 'activeVisaCardWrapper';

  return (
    <View style={styles[cardStyle]}>
      <TouchableOpacity
        style={styles.collapseHeading}
        onPress={handleCountryVisa}
        activeOpacity={0.8}
      >
        <View
          style={[
            AtomicCss.flexRow,
            AtomicCss.spaceBetween,
            AtomicCss.alignCenter,
            AtomicCss.marginBottom6,
          ]}
        >
          <View style={[AtomicCss.flex1, AtomicCss.flexRow]}>
            <Text style={styles.countryNameText}>{title}</Text>
          </View>
          {!disable && (
            <Image
              style={
                !isVisaDetailsVisible
                  ? [styles.arrowDownStyle]
                  : [styles.arrowDownStyle, styles.activeArrow]
              }
              source={rightArrowBlueDropdown}
            />
          )}
        </View>
        {disable && <Text style={[fonts.regularFontFamily, styles.textClass]}>{disableText}</Text>}
        {!isVisaDetailsVisible && isDetailsSelected ? (
          <View>
            <View style={[AtomicCss.flexRow, AtomicCss.marginBottom3]}>
              <Text style={styles.visaKeyText}>{visaKey}{' '}</Text>
              <Text style={[fonts.regularFontFamily, styles.textClass]}>
                {get(selectedVisaType, 'name', '')}
              </Text>
            </View>
            <View style={AtomicCss.flexRow}>
              <Text style={[fonts.boldFontFamily, styles.textClass]}>{travellersKey}{' '}</Text>
              <Text style={[fonts.regularFontFamily, styles.passengerText]}>
                {getSelectedPassengersName(selectedCountryData)}
              </Text>
            </View>
          </View>
        ) : (
          <>
            {!isVisaDetailsVisible && (
              <Text style={styles.selectTravelerText}>{travellersSelectionText}</Text>
            )}
          </>
        )}
      </TouchableOpacity>
      {isVisaDetailsVisible && !disable && (
        <View style={styles.innerMainDetails}>
          <View style={[styles.processingTimeWrapper, AtomicCss.marginBottom10]}>
            <Image style={styles.clockGreenStyle} source={greenClockIcon} />
              <Text
                style={[
                  { color: theme.color.defaultTextColor, fontSize: fonts.fontSize.font12 },
                  fonts.regularFontFamily,
                ]}
              ><Text>{processingTimeText}{' '}</Text>
                {get(selectedVisaType, 'processingTime', '')}
              </Text>
          </View>
          {!isEmpty(description) && (<Text
            style={[
              { color: theme.color.black, fontSize: fonts.fontSize.font12 },
              fonts.regularFontFamily,
              AtomicCss.marginBottom15,
            ]}
          >
            {description}
          </Text>)}
          <View style={AtomicCss.marginBottom15}>
            <VisaTypeCard visaType={visaTypeData} onSelectIndex={onSelectIndex} />
          </View>
          <View style={AtomicCss.marginBottom15}>
            <SelectTravellerCard
              label={travellersSelectionText}
              passengers={get(selectedVisaType, 'travellersList', [])}
              selectedPassengers={selectedCountryData.passengers}
              onSelectPassenger={onSelectPassenger}
              key={selectedIndex}
            />
          </View>
        </View>
      )}
    </View>
  );
};

export default CountryVisaCard;
