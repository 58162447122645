import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {Image, Text, View} from 'react-native';
import styles from '../../CSS/VisaMamiCss';
import {getActionButton, getButtonStyle} from '../../../hotel/details/utils/HotelBookingDetailUtil';
import {handleClickEvent} from '../../Util/VisaUtil';
import {faqIcon} from '../../Constant/VisaImageConstant';


class VisaRejectionReasons extends React.Component {
  render() {
    return (
      <View style={[styles.mar10Bt]}>
        <View style={[styles.MimaWhiteCard]} >
          <View style={[styles.flexRow, styles.mar10Bt]}>
            <Image style={[styles.FaqIcon, styles.mar10Rt]} source={faqIcon} />
            <Text style={[styles.BlackText, styles.boldFont]}>WHY ARE {this.props.visaCountry.toUpperCase()} VISAs REJECTED ?</Text>
          </View>
          <Text style={[styles.VisaRjectedText, styles.mar10Bt]}>
                Generally, {this.props.visaCountry.toLowerCase()} visa applications are delayed or rejected due to one of the following reasons:
          </Text>
          {this.getVisaRejectionViews(this.props.response.visaRejectionReason)}

        </View>
        <View
          style={this.props.card != null ? !_.isEmpty(this.props.card.actionList) ? getButtonStyle(this.props.card.actionList) : styles.hideView : styles.hideView}
        >
          {this.props.card != null ? getActionButton(this.props.card, this.props.response, this.handleClick) : null}
        </View>
      </View>);
  }


  getVisaRejectionViews(visaRejectionReason) {
    const views = [];
    visaRejectionReason.forEach((rejectionReason, index) => {
      views.push(<View key={index} style={[styles.flexRow, styles.mar10Bt]}>
        <View style={styles.discStyle} />
        <Text style={[styles.DefaultText,
            styles.font14,
            styles.lineHeight20, styles.whyRejectedTxt]}
        >
          {visaRejectionReason[index]}
        </Text>
      </View>);
    });

    return views;
  }

    handleClick = (action) => {
      handleClickEvent(this.props.card, action, this.props.response, this.props.pageName);
    };
}

VisaRejectionReasons.propTypes = {
  response: PropTypes.object.isRequired,
  visaCountry: PropTypes.string.isRequired,
  card: PropTypes.object.isRequired,
  pageName: PropTypes.string.isRequired
};

export default VisaRejectionReasons;
