import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  Image,
  TouchableOpacity,
} from "react-native";
import Checkbox from 'apps/post-sales/src/Common/Checkbox';
import { CROSS_ICON1 } from '../../hotel/HotelImageConstants';
import { getFont } from '../../PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import { MYPARTNER_ADDITIONAL_INFO_URL } from "../../utils/NetworkUtils";
import { FLOAT_REGEX, PERCENTAGE_ACTIVE_STATE, MARKUP_SELECTED, MARKUP_DESELECTED, MARKUP_PERCENTAGE, MARKUP_ABSOLUTE, CUSTOMERVOUCHER_MARKUP, CUSTOMERVOUCHER_WITHOUTMARKUP, ABSOLUTE_ACTIVE_STATE } from '../../PostSalesConstant';
import createStyles from './styles';
import PaymentDetailsSection from './PaymentDetailsSection/PaymentDetailsSection'
import CancellationPolicy from './CancellationPolicy/CancellationPolicy'
import useApi from "../useApi";
interface AdditionalText {
  addMarkupHeading: string;
  addCancellationPolicyText: string;
  addMarkupSubHeading: string;
  markupInputInfo: string;
  downloadWithMarkupText: string;
  downloadWithoutMarkupText: string;
}
interface CustomerBookingConfirmProps {
  handleClick: () => void;
  currency: string;
  baseCharges: number;
  discountAmount: number;
  feeSurcharges: number;
  totalAmt: number;
  downloadEticket: (amount: number, isCancellationPolicySelected: boolean) => void;
  heading: string;
  showEnterPercentage: string;
  showEnterAbsolute: string;
  additionalText: AdditionalText;
  notCancellationpolicy: boolean;
  trackHelper: (markupselected: string) => void;
  bookingid: string;
}

const CustomerBookingConfirm: React.FC<CustomerBookingConfirmProps> = ({
  handleClick,
  currency,
  baseCharges,
  discountAmount,
  totalAmt,
  downloadEticket,
  heading,
  additionalText,
  feeSurcharges,
  notCancellationpolicy,
  trackHelper,
  bookingid
}) => {
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme);
  const [isCancellationPolicySelected, setIsCancellationPolicySelected] = useState(false);
  const [amountType, setAmountType] = useState(currency);
  const [amount, setAmount] = useState("");
  const [addedAmount, setAddedAmount] = useState(0);
  const [isMarkupEnabled, setIsMarkupEnabled] = useState(false);
  const [activeState, setActiveState] = useState(0);
  const [customerVoucherMarkupInfo, setCustomerVoucherMarkupInfo] = useState({});
  const [fetchInProgress, additionalInfoData, additionalInfoApi] = useApi(MYPARTNER_ADDITIONAL_INFO_URL + bookingid);
  const [markupDetails, setmarkupDetails] = useState({});
  const ABSOLUTE = "Absolute", PERCENTAGE = "Percentage";

  useEffect(() => {
    additionalInfoApi.httpGet();
  }, []);

  useEffect(() => {
    if(additionalInfoData?.myPartnerMarkupInfo?.markupValue && additionalInfoData?.myPartnerMarkupInfo?.markupType) {
      setCustomerVoucherMarkupInfo(additionalInfoData.myPartnerMarkupInfo)
      additionalInfoData.myPartnerMarkupInfo.markupType === ABSOLUTE ? setActiveState(ABSOLUTE_ACTIVE_STATE) : setActiveState(PERCENTAGE_ACTIVE_STATE);
      setIsMarkupEnabled(true);
    }
  }, [additionalInfoData])

  useEffect(() => {

    if(activeState === ABSOLUTE_ACTIVE_STATE) {
      if(customerVoucherMarkupInfo?.markupType === ABSOLUTE && customerVoucherMarkupInfo?.markupValue){ 
        handleAmountInput(customerVoucherMarkupInfo.markupValue);
      } else {
        setmarkupDetails({});
      }
    }

    else {
      // PERCENTAGE_ACTIVE_STATE
      if(customerVoucherMarkupInfo?.markupType === PERCENTAGE && customerVoucherMarkupInfo?.markupValue){
        handleAmountInput(customerVoucherMarkupInfo.markupValue);
      } else {
        setmarkupDetails({});
      }
    }
    
  }, [activeState, customerVoucherMarkupInfo])

  const handleMarkup = () => {
    const markupselected = isMarkupEnabled ? MARKUP_SELECTED : MARKUP_DESELECTED;
    trackHelper(markupselected);
    setIsMarkupEnabled(!isMarkupEnabled);
  };

  const handleAmount = (active: number, type: string) => {
    const markupselected = active ? MARKUP_PERCENTAGE : MARKUP_ABSOLUTE;
    trackHelper(markupselected)
    setActiveState(active);
    setAmountType(type);
    setAmount("");
    setAddedAmount(0);
  };

  const round = (val: number) => {
    return Math.round(val * 100) / 100;
  };

  const handleAmountInput = (e: string) => {
    let addedVal = e;
    let val = e;
    const floatregex = FLOAT_REGEX;
    if (val.match(floatregex)) {
      let parsedVal = parseFloat(val);
      if (parsedVal) {
        if (activeState === PERCENTAGE_ACTIVE_STATE) {
          if (parsedVal <= 100) {
            let amountadded = (totalAmt * parsedVal) / 100;
            setAmount(addedVal);
            setAddedAmount(amountadded);
            setmarkupDetails({markupType: PERCENTAGE, markupValue: val});
          } else {
            return;
          }
        } else {
          setAmount(addedVal);
          setAddedAmount(parsedVal);
          setmarkupDetails({markupType: ABSOLUTE, markupValue: val});
        }
      } else {
        setAmount("");
        setAddedAmount(0);
        setmarkupDetails({});
      }
    }
  };

  const handleCancellationPolicyChange = () => {
    setIsCancellationPolicySelected(!isCancellationPolicySelected);
  };

  const handleDownload = () => {
    let markupselected = isMarkupEnabled ? CUSTOMERVOUCHER_MARKUP : CUSTOMERVOUCHER_WITHOUTMARKUP;
    if (isMarkupEnabled) {
      markupselected += `_${amount}${activeState ? "%" : ""}`;
    }  
    trackHelper(markupselected);
    downloadEticket(!isMarkupEnabled ? 0 : addedAmount, isCancellationPolicySelected, isMarkupEnabled ? markupDetails : {}, isMarkupEnabled);
  };
  const totalAmountCalculated = round(totalAmt + addedAmount);
  const baseChargeCalculated = round(baseCharges - discountAmount);
  const feesSurcharges = round(feeSurcharges + addedAmount);

  return (
    <View>
      <View style={styles.headerWrapper}>
        <Text style={[styles.textBlack, fsStyle.font22, fonts.blackFontFamily]}>
        {heading || ''}
        </Text>
        <TouchableOpacity style={styles.crossWrapper} onPress={handleClick}>
          <Image style={styles.closeIcon} source={CROSS_ICON1} />
        </TouchableOpacity>
      </View>
      <View style={styles.addMarkupWrapper}>
        <View style={styles.addMarkupContent}>
          <View style={styles.checkBoxAddMarkup}>
            <Checkbox
              label={additionalText?.addMarkupHeading}
              checked={isMarkupEnabled}
              onChange={handleMarkup}
            />
          </View>
          <View style={styles.markupHeading}>
            <Text style={[styles.textBlack, fsStyle.font12, fonts.regularFontFamily]} >
              {additionalText?.addMarkupSubHeading}
            </Text>
          </View>
        </View>
        <PaymentDetailsSection
          activeState={activeState}
          handleAmount={handleAmount}
          amountType={amountType}
          handleAmountInput={handleAmountInput}
          amount={amount}
          isMarkupEnabled={isMarkupEnabled}
          totalAmountCalculated={totalAmountCalculated}
          baseChargeCalculated={baseChargeCalculated}
          feesSurcharges={feesSurcharges}
          currency={currency}
        />
      </View>
      <CancellationPolicy
        notCancellationpolicy={notCancellationpolicy}
        isCancellationPolicySelected={isCancellationPolicySelected}
        handleCancellationPolicyChange={handleCancellationPolicyChange}
        isMarkupEnabled={isMarkupEnabled}
        handleDownload={handleDownload}
      />
    </View>
  );
};

export default CustomerBookingConfirm;