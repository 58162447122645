import { View, StyleSheet, Dimensions } from 'react-native';

export default (theme, fonts) => {
  const { color } = theme;
  return StyleSheet.create({
    cabBookedInfoSection: {
      margin: 2,
      padding: 10,
      backgroundColor: color.gray2,
      borderBottomLeftRadius: 14,
      borderBottomRightRadius: 14,
    },
    textRight: {
      textAlign: 'right',
    },
    bookingStatusTrackerInfo: {
      flexDirection: 'row',
    },
    iconWrapper: {
      width: 24,
      height: 24,
    },
    iconStyle: {
      width: 24,
      height: 24,
    },
    progessStatusWrapper: {
      height: 6,
      borderRadius: 8,
      backgroundColor: color.lightGrey16,
      marginTop: 10,
      marginHorizontal: 5,
    },
    progressStatus: {
      backgroundColor: color.lightGreen18,
      height: 6,
      borderRadius: 8,
    },
    TootlTipInnerInfo: {
      backgroundColor: color.black,
      paddingHorizontal: 16,
      paddingVertical: 10,
      borderRadius: 8,
      alignSelf: 'flex-start',
      width: '100%',
      top: -10
    },
    triangle: {
      width: 20,
      height: 20,
      transform: [{ rotate: '180deg' }],
      tintColor: color.black
    },
  });
};
