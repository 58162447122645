import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Image, Text, TouchableOpacity, View } from 'react-native';
import styles from './CancellationRefundDtlsCss';
import CommonStyle from '@mmt/legacy-commons/Styles/AtomicCss';
import { getImageIcon } from '../Helpers/FlightCancellationUtility';
import { GROPU_3_ICON } from '../../FlightImageConstants';
import { getStaticData } from '../../../staticData/staticData';
import { getFont } from '../../../PostSalesStyles';

class RefundPaxCancelledDtls extends Component {
  constructor(props) {
    super(props);
    this.state = {
      moreTraveller: false,
    };
  }

  handleMoreTravler = () => {
    this.setState({
      moreTraveller: !this.state.moreTraveller,
    });
  };

  render() {
    const { fsStyle, ...fonts } = getFont(true);
    return (
      <View
        style={[styles.paxCancelledinnderDtls, this.props.isBorderTop ? styles.topGreyBorder : '']}
      >
        <Text
          style={[
            styles.fltDtlsTxt,
            fonts.blackFontFamily,
            CommonStyle.marginBottom10,
            CommonStyle.alignLeft,
            fsStyle.font14,
          ]}
        >
          {this.props.segmentWithDate}
        </Text>
        <View style={[CommonStyle.flexRow]}>
          <Image style={styles.UserIcon} source={GROPU_3_ICON} />
          <Text style={[styles.refundtopDiscPax, fonts.regularFontFamily, fsStyle.font12]}>
            {this.props.paxNames[0]}
          </Text>
          {this.props.paxNames.length > 1 && (
            <TouchableOpacity activeOpacity={0.9} onPress={() => this.handleMoreTravler()}>
              <Text style={[styles.moreTraveller, fonts.boldFontFamily, fsStyle.font12]}>
                {this.getMorePaxText()}
              </Text>
            </TouchableOpacity>
          )}
        </View>
        {this.state.moreTraveller && this.showAllPax()}
        {this.getSegmentWiseRefundMessage()}
      </View>
    );
  }

  showAllPax() {
    const { fsStyle, ...fonts } = getFont(true);
    const views = [];
    for (let i = 1; i < this.props.paxNames.length; i += 1) {
      views.push(
        <View style={[CommonStyle.flexRow]}>
          <Image style={styles.UserIcon} source={GROPU_3_ICON} />
          <Text style={[styles.refundtopDisc, fonts.regularFontFamily, fsStyle.font12]}>
            {this.props.paxNames[i]}
          </Text>
        </View>,
      );
    }
    return views;
  }

  getMorePaxText = () => {
    const { showLessText, travellerText } = getStaticData();
    if (this.state.moreTraveller) {
      return `- ${showLessText}`;
    }
    const remainingNoOfPax = this.props.paxNames.length - 1;
    return ` +${remainingNoOfPax} ${travellerText}`;
  };

  getSegmentWiseRefundMessage = () => {
    const { fsStyle, ...fonts } = getFont(true);
    const views = [];
    this.props.messages.forEach((attribute) => {
      if (attribute) {
        const attributeObj = attribute;
        const imageIcon = getImageIcon(attributeObj.attributImg);
        views.push(
          <View key={attributeObj.attributSubTitle} style={CommonStyle.flexRow}>
            {imageIcon && <Image style={styles.zcIconSm} source={imageIcon} />}
            <Text
              style={[
                styles.refundtopDisc,
                fonts.regularFontFamily,
                { color: attributeObj.attributSubTitleColor },
                fsStyle.font12,
              ]}
            >
              {attributeObj.attributSubTitle}
            </Text>
          </View>,
        );
      }
    });
    return views;
  };
}

RefundPaxCancelledDtls.propTypes = {
  segmentWithDate: PropTypes.string.isRequired,
  paxNames: PropTypes.array.isRequired,
  isBorderTop: PropTypes.bool.isRequired,
};

export default RefundPaxCancelledDtls;
