import { FlatList, Text, View, Image, TouchableOpacity, Platform } from 'react-native';
import React, { useEffect, memo, useMemo } from 'react';
import LinearGradient from 'react-native-linear-gradient';

import HotelCard from './HotelCard';
import styles from './hotelCrossSellCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { RIGHT_ARROW_BLUE_ICON } from '../../../../FlightImageConstants';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import { getFont } from '../../../../../PostSalesStyles';
import useApi from '../../../../../Common/useApi';
import get from 'lodash/get';
import { isNullOrEmptyObject, isEmptyArray } from '../../../../../Common/commonUtil';
import FlightHotelCrossSellTrackingHelper from './tracking';
import { HOTEL_CROSS_SELL_URL } from '../../../../../utils/NetworkUtils';
import HTMLView from 'react-native-htmlview';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';

const HotelCrossSell = ({
  bookingID: bookingId,
  bookingDateTime,
  isInternational,
  newFlightDetails,
  passengerList,
}) => {
  if(Platform.OS === 'web'){
    return null;
  }
  const [inProgress, response, api] = useApi(HOTEL_CROSS_SELL_URL);

  const fetchCrossData = async () => {
    let payload;
    if(Platform.OS!=='web'){
      payload = await GenericModule.getSkyWalkerRequestBody();
    }
    if (Platform.OS === 'android') {
      payload = JSON.parse(payload.skywalkerRequest);
    }
    const body = {
      context: payload.context,
      user: payload.user,
      filters: {
        bookingId,
      },
      bookingDateTime,
      isInternational,
      newFlightDetails,
      passengerList,
    };
    api.httpPost({ bookingId, uniqueId: 'FLIGHT_HOTEL_CROSS_SELL', body });
  };

  useEffect(() => {
    if (response) {
      FlightHotelCrossSellTrackingHelper.trackLoadEvent('details', 'persuasion');
      if (crossSellData && !isEmptyArray(crossSellData.hotelList)) {
        FlightHotelCrossSellTrackingHelper.trackLoadEvent('details', 'reco');
      }
    }
  }, [response, crossSellData]);

  useEffect(() => {
    fetchCrossData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const crossSellData = useMemo(() => {
    if (response && response.data) {
      const { data, headerData, viewAllCard } = get(
        response,
        'data.BASE_SHEET.apiData.HOTEL_CROSS_SELL',
        {},
      );
      if (!isNullOrEmptyObject(data)) {
        const { tabDetails, tabsData } = data || {};
        const hotelTabMeta =
          tabDetails && tabDetails.find((tab) => tab.isHotel || tab.id === 'HOTELS');
        const hotelsId = hotelTabMeta.id;
        const hotelsData = tabsData[hotelsId];
        const { hotelList, deeplinkUrl } = hotelsData;
        return { hotelList, headerData, viewAllCard, deeplinkUrl };
      } else if (!isNullOrEmptyObject(headerData) && !isNullOrEmptyObject(viewAllCard))
        return { headerData, viewAllCard };
    } else return {};
  }, [response]);

  if (inProgress || (response && response.error) || isNullOrEmptyObject(crossSellData)) return null;

  const { hotelList, headerData, viewAllCard, deeplinkUrl } = crossSellData;

  const { header, icon, iconText, subheader } = headerData || {};

  const { fsStyle, ...fonts } = getFont(true);

  const handleViewAllDeepLink = () => {
    FlightHotelCrossSellTrackingHelper.trackClickEvent('details', 'View_All');

    if (deeplinkUrl) {
      GenericModule.openDeepLink(deeplinkUrl);
    } else if (!isNullOrEmptyObject(viewAllCard) && viewAllCard.deeplink) {
      GenericModule.openDeepLink(viewAllCard.deeplink);
    }
  };

  const htmlStylePersuasions = {
    div: {
      fontSize: fonts.fontSize.font14,
      color: colors.defaultTextColor,
    },
  };

  return (
    <View style={[AtomicCss.marginBottom10]}>
      {!!iconText && (
        <LinearGradient
          start={{ x: 0.0, y: 1.0 }}
          end={{ x: 0.0, y: 0.1 }}
          colors={['#FF7F3F', '#FF3E5E']}
          style={[[styles.tagInfo, AtomicCss.flexRow]]}
        >
          <Text style={[styles.tagStyle, fonts.boldFontFamily, fsStyle.font10]}>{iconText}</Text>
        </LinearGradient>
      )}

      <View style={styles.cardWrapper}>
        <View style={styles.topSectionInfo}>
          {!!icon && <Image style={styles.hotelIconStyle} source={{ uri: icon }} />}
          <View style={AtomicCss.flex1}>
            {!!header && (
              <Text
                style={[
                  AtomicCss.blackText,
                  fonts.boldFontFamily,
                  fsStyle.font18,
                  AtomicCss.marginBottom10,
                ]}
              >
                {header}
              </Text>
            )}
            {!!subheader && (
              <HTMLView
                stylesheet={{ ...htmlStylePersuasions, ...fonts.regularFontFamily }}
                value={`<div>${subheader}</div>`}
              />
            )}
          </View>
        </View>
        {!isEmptyArray(hotelList) && (
          <View style={styles.recommondedHotelWrapper}>
            <FlatList
              data={hotelList}
              horizontal={true}
              showsHorizontalScrollIndicator={false}
              renderItem={({ item, index }) => <HotelCard data={item} index={index} />}
              keyExtractor={(item, index) => `${item.id}${index}`}
              initialNumToRender={4}
              maxToRenderPerBatch={2}
              windowSize={2}
            />
          </View>
        )}
        {!isNullOrEmptyObject(viewAllCard) && (
          <View
            style={[
              styles.actionWrapper,
              hotelList && !isEmptyArray(hotelList)
                ? AtomicCss.alignCenter
                : { alignItems: 'flex-start' },
            ]}
          >
            {!!viewAllCard.title && (
              <TouchableOpacity style={[styles.actionLink]} onPress={handleViewAllDeepLink}>
                <Text style={[AtomicCss.azure, fsStyle.font14, fonts.boldFontFamily]}>
                  {viewAllCard.title}
                </Text>
                <Image style={styles.blueArrowStyle} source={RIGHT_ARROW_BLUE_ICON} />
              </TouchableOpacity>
            )}
          </View>
        )}
      </View>
    </View>
  );
};

export default memo(HotelCrossSell);
