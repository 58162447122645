import React from "react";
import { appendUrlPrefix } from "../../webUtils";
import loadable from "@loadable/component";
import ProgressView from "../../components/ProgressView";

export const HOLIDAY_ROUTE_URLS = {
    DETAIL_PAGE: appendUrlPrefix('/holiday')
}

const HOLIDAYS_PATHS = {
    HOLIDAYS_BOOKING_DETAIL: {
        pathName: appendUrlPrefix('/holiday'),
        pageKey: "holidayBookingDetail",
        Component: loadable(() => import("@rn/apps/post-sales/src/Holiday/HolidayBookingDetail"), { fallback: <ProgressView /> }),
        customFunction: (redirectUrl, pathName, params) => {
            if (params.data) {
                params.bookingId = params.data.BOOKING_ID;
            }
            redirectUrl(pathName, params);
        }
    },
    HOLIDAYS_TAB: {
        pathName: appendUrlPrefix('/holiday/tab'),
        pageKey: "holidayMimaTabDetail",
        Component: loadable(() => import("@rn/apps/post-sales/src/Holiday/HolidayBookingTab"), { fallback: <ProgressView /> }),
    },
    HOLIDAYS_UPLOAD_DOCUMENTS: {
        pathName: appendUrlPrefix('/holiday/uploadDocuments'),
        pageKey: "holidayUploadDocuments",
        Component: loadable(() => import("@rn/apps/post-sales/src/Holiday/DocumentsPage/DocumentsPage"), { fallback: <ProgressView /> }),
    },
    HOLIDAYS_BOOKING_CANCELLATION_REVIEW: {
        pathName: appendUrlPrefix('/holiday/cancellation/review'),
        pageKey: "holidayCancellationReview",
        Component: loadable(() => import("@rn/apps/post-sales/src/Holiday/CancellationPage/CancellationReview/index"), { fallback: <ProgressView /> }),
    },
    HOLIDAYS_BOOKING_CANCELLATION_REFUND_MODE: {
        pathName: appendUrlPrefix('/holiday/cancellation/refundMode'),
        pageKey: "holidayCancellationRefundMode",
        Component: loadable(() => import("@rn/apps/post-sales/src/Holiday/CancellationPage/CancellationRefundMode/index"), { fallback: <ProgressView /> }),
    },
    HOLIDAYS_BOOKING_CANCELLATION_THANK_YOU:{
        pathName: appendUrlPrefix('/holiday/cancellation/thankYou'),
        pageKey: "holidayCancellationThankyou",
        Component: loadable(() => import("@rn/apps/post-sales/src/Holiday/CancellationPage/CancellationSuccess/index"), { fallback: <ProgressView /> }),
        backRoute: HOLIDAY_ROUTE_URLS.DETAIL_PAGE,
    },
    HOLIDAYS_CAR_PICKUP_DETAILS:{
        pathName: appendUrlPrefix('/holiday/CarPickupDetails'),
        pageKey: "holidayAddCarPickUpDetails",
        Component: loadable(() => import("@rn/apps/post-sales/src/Holiday/CarPickupDetails"), { fallback: <ProgressView /> }),
    }
}

export default HOLIDAYS_PATHS;