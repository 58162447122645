import {colors} from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from '@mmt/legacy-commons/Common/Components/Card/getPlatformElevation';
import {Dimensions, Platform} from 'react-native';

const windowHeight = Dimensions.get('screen').height;
const windowWidth = Dimensions.get('screen').width;

const getStyles = (fonts) => {
  return {
    overlayWrapper:{
      position: 'fixed',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      elevation: 10,
      zIndex: 100,
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      flexDirection: 'row',
  
    },
    overlay:{
      position: 'absolute',
      top: 0,
      left: 0,
      backgroundColor: 'rgba(0,0,0,0.7)',
      height: '100%',
      width: '100%',
      zIndex: 1
    },
    overlayContent:{
      backgroundColor: colors.white,
      position: 'relative',
      zIndex: 11,
      width:'100%',
      ...Platform.select({
        web: {
          maxHeight: '75%',
          overflowY: 'auto',
          overflowX: 'hidden',
          paddingTop: 0,
          paddingBottom: 0
        }
      })
    },
    OverlayTitle:{
      ...fonts.font24,
      fontFamily:fonts.light,
      color:'#000',
    },
    bankCardWrapper:{
      ...getPlatformElevation(2),
      borderRadius:4,
      paddingHorizontal:16,
      paddingTop:16,
      paddingBottom:40,
      backgroundColor:'#fff',
      marginTop:3,
      borderTopWidth:1,
      borderTopColor:'#f2f2f2'
  
    },
    cardIconStyle:{
      width:20,
      height:22,
    },
    bulletsPoints:{
      color:'#747474',
      fontSize:6,
      marginRight:3,
    },
    btnWrapper:{
      flexDirection:'row',
      alignItems:'center',
      justifyContent:'center',
      top:-22
    },
  }
}


export default getStyles;
