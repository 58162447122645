import { trackOmniture, EVAR_44, PROP_1, PROP_50, VAR_BOOKING_ID } from '@mmt/legacy-commons/Common/utils/OmnitureTrackerUtils';

class CustomerSupportCardTracker {
    constructor(pageName, cardType, bookingId) {
        this.pageName = pageName;
        this.cardType = cardType;
        this.bookingId = bookingId;
    }
    trackLoadEvent() {
        const params = {
            pageName: this.pageName,
            [EVAR_44]: `stillneedhelp_${this.cardType}_loaded`,
            [VAR_BOOKING_ID]: this.bookingId,
        }
        trackOmniture(this.pageName, params);
    }
    trackClickEvent() {
        const params = {
            pageName: this.pageName,
            [PROP_1]: `stillneedhelp_${this.cardType}_clicked`,
            [VAR_BOOKING_ID]: this.bookingId,
        }
        trackOmniture(this.pageName, params);
    }
    trackSeenEvent() {
        const params = {
            pageName: this.pageName,
            [PROP_50]: `stillneedhelp_${this.cardType}_seen`,
            [VAR_BOOKING_ID]: this.bookingId,
        }
        trackOmniture(this.pageName, params);
    }
    trackCTAClickEvent(clickEvent){
        const params = {
            pageName: this.pageName,
            [PROP_1]: clickEvent,
            [VAR_BOOKING_ID]: this.bookingId,
        };
        trackOmniture(this.pageName, params);
    }
}

export default CustomerSupportCardTracker;