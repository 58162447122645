import React from 'react';
import {View, Text,TouchableOpacity,Image, NativeModules} from 'react-native';
import styles from './RailUnConfirmedCss';
import PropTypes from "prop-types";
import RectFullButton from "../../../hotel/details/ActionButton/RectFullButton";
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import RailBookingTrackingHelper from "../../details/RailBookingTrackEventHelper";
import {isNotNullAndEmpty} from "../../../bus/utils/BusBookingDetailUtil";
import {INFO_IMAGE} from '../../Utils/RailImageConstant';
import { getStaticData } from '../../../staticData/staticData';
import { getFont } from '../../../PostSalesStyles';
import { getGdprData, shouldShowGdprBlockerScreen } from '../../../utils/PostSaleUtil';
import HTMLView from 'react-native-htmlview';

class RailUnConfirmedInformationCard extends React.Component{
    constructor(props){
        super(props);
    }
    static navigationOptions = {
        header: null
    };

    render(){
        const staticData = getStaticData();
        const { fsStyle, ...fonts } = getFont(true);
        const { trainConfirmationAwaitedText, trainContinueBookingText } = staticData;
        const deeplink = (this.props.response &&
            this.props.response.railAdditionalInfo &&
            this.props.response.railAdditionalInfo.pendingBookingInfo &&
            isNotNullAndEmpty(this.props.response.railAdditionalInfo.pendingBookingInfo.deepLink));
        return(

            <View style={[styles.infoCardWrapper,styles.marginBottom12]}>
              <View style={styles.infoCardHading}>
                <Image style={styles.infoIcon} source={INFO_IMAGE}/>
                <Text style={[styles.defaultTxt, fonts.boldFontFamily, fsStyle.font16, styles.marginBottom15, styles.flex1]}>{trainConfirmationAwaitedText}</Text>
              </View>
              <HTMLView value={this.props.response.unconfirmedMessage} />

             {deeplink && <RectFullButton
                 action={{actionLabeltext: trainContinueBookingText}}
                 clickHandler={this.confirmRailBooking}
                 style = {styles.RecFullBtn}
             />}
            </View>


    );
    }

    confirmRailBooking = async () => {
        const { UserSessionModule } = NativeModules;
        const showGdprBlockerScreen = await shouldShowGdprBlockerScreen();
        if (showGdprBlockerScreen) {
            const { gdprText } = getStaticData();
            const { lobs: { trains = 'Trains'} = {} } = gdprText || {} 
            UserSessionModule.openGDPRBlockerScreen(trains, null, getGdprData(gdprText));
        }else{
            RailBookingTrackingHelper.trackClickEvent('TripDetails', this.props.response, 'UnconfirmedBookingContinueClicked');
            GenericModule.openDeepLink(this.props.response.railAdditionalInfo.pendingBookingInfo.deepLink);
        }
    }
}

RailUnConfirmedInformationCard.propTypes = {
    response:PropTypes.object.isRequired
};

export default RailUnConfirmedInformationCard
