import { StyleSheet } from "react-native";
export default (theme, fonts) =>StyleSheet.create({
    voucherCodeWrapper: {
        paddingHorizontal: 8,
        paddingVertical: 10,
        borderWidth: 1,
        borderColor: theme.color.goldenYellow,
        borderRadius: 4,
        justifyContent: 'space-between',
        width : '95%',
        alignSelf : 'center',
        overflow : 'scroll'
    },
    offerIconStyle: {
        width: 19,
        height:20,
        marginRight: 10,
    },
    offerCodeText: {
        color: theme.color.yello,
        fontSize: 13,
         ...(fonts.boldFontFamily),
         marginLeft:5,
         marginRight:5
    },
    voucherCodeInfo: {
        paddingHorizontal: 10,
        paddingVertical: 4,
        borderRadius: 4,
        width : '95%',
        alignSelf : 'center'
    },
    copyIconStyle: {
        width: 11,
        height: 13,
        marginLeft: 6,
    },

})

