import React, { useEffect } from 'react';
import { ScrollView, Text, View } from 'react-native';
import PropTypes from 'prop-types';
import  Actions  from '../../../navigation/postSalesNavigation';
import getStyles from './HotelMamiCss';
import CommonHeader from '../components/CommonHeader';
import { isIntlHotelBooking } from '../utils/HotelBookingDetailUtil';
import HotelBookingTrackingHelper from '../../HotelBookingTrackingHelper';
import { getStaticData } from '../../../staticData/staticData';
import { WHITE_CROSS_ICON } from '../../HotelImageConstants';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const getRefundBreakupView = (refundGroup, styles) => {
  const views = [];
  if (refundGroup.total) {
    views.push(
      <View style={[styles.Paymentrow, styles.pad15Top, styles.paddingBottom8]}>
        <View style={styles.PaymentLeft}>
          <Text style={[styles.BlackText, styles.blackFont, styles.font16]}>
            {refundGroup.total.text}
          </Text>
        </View>
        <Text style={[styles.BlackText, styles.blackFont, styles.font16]}>
          {refundGroup.total.value}
        </Text>
      </View>,
    );
  }
  if (refundGroup.refundDetailList && refundGroup.refundDetailList.length > 0) {
    refundGroup.refundDetailList.map((refundDetail) => {
      views.push(
        <View
          style={[styles.Paymentrow, styles.mar10Bt]}
          key={refundDetail.desc}
        >
          <Text style={[styles.PaymentLeft]}>{refundDetail.desc}</Text>
          <Text style={styles.BlackText}>{refundDetail.value}</Text>
        </View>,
      );
    });
  }
  return views;
};

const RefundBreakUp = ({ refundBreakup, styles }) => {
  return (
    <View style={[styles.MamiCard, styles.mar10Bt]}>
      <View style={styles.CardContent}>
        <View style={styles.ContentPortion}>
          {refundBreakup &&
            refundBreakup.map((refundGroup, index) => (
              <View
                style={
                  index < refundBreakup.length - 1 ? [styles.borderbtm] : ''
                }
              >
                {getRefundBreakupView(refundGroup, styles)}
              </View>
            ))}
        </View>
      </View>
    </View>
  );
};

const onBackPress = () => {
  Actions.pop();
};

const RefundBreakUpCard = ({ response }) => {
  const { ...fonts } = getFont(true);
  const styles = getStyles(fonts);
  const staticData = getStaticData();
  useEffect(() => {
    const trackPageName = isIntlHotelBooking(response?.lobCode)
      ? 'mob:customer support:Hotel International:RefundCalculation'
      : 'mob:customer support:Hotel Domestic:RefundCalculation';
    HotelBookingTrackingHelper.trackLoadEvent(trackPageName, response);
  }, []);

  return (
    <View style={[styles.GreyBg, { flex: 1 }]}>
      <CommonHeader
        imgSrc={WHITE_CROSS_ICON}
        headerText={staticData.refundCalculationText}
        backPressHandler={onBackPress}
      />
      <ScrollView>
        <RefundBreakUp refundBreakup={response.refundBreakup} styles={styles} />
      </ScrollView>
    </View>
  );
};

RefundBreakUpCard.propTypes = {
  response: PropTypes.object.isRequired,
};

export default RefundBreakUpCard;
