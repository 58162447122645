import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import LinearGradient from 'react-native-linear-gradient';
import { Text, Image, TouchableOpacity } from 'react-native';
import createStyles from './ButtonsCss';
import { RIGHT_ARROW_WHITE_ICON } from '../../../FlightImageConstants';
import { useTheme } from '../../../../theme/theme.context';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';



const Buttons = ({ text, type, arrow, disabled, onPressHandler, isCorporateBooking, bgColor }) => {
  const { psTheme: theme } = useTheme();
  const fonts = getFont(true);
  const styles = createStyles(theme,fonts)
  const btnColor = theme.color.primaryGradient;
  return (
    <TouchableOpacity
      style={[styles.btn, type ? styles[`btnType${type}`] : '']}
      activeOpacity={0.85}
      disabled={disabled}
      onPress={() => onPressHandler()}
    >
      <LinearGradient
        start={{ x: 1.0, y: 0.0 }}
        end={{ x: 0.0, y: 1.0 }}
        colors={
          disabled
            ? [colors.disabledButton, colors.disabledButton]
            : bgColor == 'white'
            ? [theme.color.white, theme.color.white]
            : btnColor
        }
        style={[styles.blueBtnGradient, styles.BtnCenter]}
      >
        <Text
          style={[
            styles.blueBtnText,
            styles.transperantBgrnd,
            bgColor === 'white' ? styles.corpTxtColor : [],
          ]}
        >
          {text}
        </Text>
        {arrow === 'RightArrow' && (
          <Image style={styles.WtRightArrow} source={RIGHT_ARROW_WHITE_ICON} />
        )}
      </LinearGradient>
    </TouchableOpacity>
  );
};

Buttons.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  arrow: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  isCorporateBooking: PropTypes.bool.isRequired,
  onPressHandler: PropTypes.func.isRequired,
  bgColor: PropTypes.array,
};

export default Buttons;
