import React from 'react';
import PropTypes from 'prop-types';
import {View, ScrollView, StyleSheet, TouchableOpacity, Image, Text} from 'react-native';
import ContactListItem from './ContactListItem';
import {fonts, colors} from '@mmt/legacy-commons/Styles/globalStyles';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getPlatformElevation from './getPlatformElevation';
import {CrossIcon} from '../HolidayBookingConstants';

class ContactInfo extends React.Component {
  constructor(props) {
    super(props);
    this.tabbingFn = this.tabbingFn.bind(this);
    this.state = {
      activeCard: 0,
      collapseActiveCard: true
    };
  }

  tabbingFn(index, nextState) {
    this.setState({
      activeCard: index,
      collapseActiveCard: nextState
    });
  }

  static navigationOptions = {
    header: null
  };

  render() {
    const {holidayContactList} = this.props;
    return (
      <View style={styles.modalWrapper}>
        <View style={AtomicCss.marginBottom10}>
          <View style={styles.headerWrapper}>
            <TouchableOpacity onPress={() => this.props.onBackPopUp(this.props.pageName)}>
              <Image
                style={styles.backArrow}
                source={CrossIcon}
              />
            </TouchableOpacity>
            <View>
              <Text style={styles.headerTitle}>Contacts</Text>
            </View>
          </View>
        </View>
        <ScrollView>
          {
            holidayContactList.map((item, index) => {
              const isActive =
                index === this.state.activeCard ? this.state.collapseActiveCard : false;
              return (
                <ContactListItem
                  item={item}
                  key={index}
                  index={index}
                  isActive={isActive}
                  tabbingFn={this.tabbingFn}
                  callMmt={this.props.callMmt}
                  sendMail={this.props.sendMail}
                  showLocation={this.props.showLocation}
                />
              );
            })
          }
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  modalWrapper: {
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: '#f2f2f2',
    position: 'absolute',
    elevation: 3,
    zIndex: 13,
    width: '100%',
    height: '100%'
  },
  headerWrapper: {
    padding: 15,
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
    zIndex: 1,
    elevation: 1,
    backgroundColor: '#fff',
    marginBottom: 1,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.3,
    shadowRadius: 2
  },
  headerTypeDetails: {
    paddingVertical: 10
  },
  headerTypeTab: {
    paddingBottom: 35
  },
  headerTypeElevated: {
    ...getPlatformElevation(3)
  },
  headerTitle: {
    fontSize: 16,
    color: colors.black,
    fontFamily: fonts.bold
  },
  headerDescText: {
    fontSize: 12,
    fontFamily: fonts.regular,
    color: colors.disabledBtnBg,
    marginTop: 3
  },
  stickyBarWrapper: {
    paddingHorizontal: 15,
    paddingVertical: 5
  },
  backArrow: {
    width: 16,
    height: 16,
    marginRight: 18
  },
  CloseIcon: {
    width: 14,
    height: 14,
    marginRight: 18
  }
});

ContactInfo.propTypes = {
  holidayContactList: PropTypes.array.isRequired,
  callMmt: PropTypes.func.isRequired,
  sendMail: PropTypes.func.isRequired,
  showLocation: PropTypes.func.isRequired,
  onBackPopUp: PropTypes.func.isRequired,
  pageName: PropTypes.string.isRequired
};

export default ContactInfo;
