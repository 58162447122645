import { NativeModules } from 'react-native';

const JS_VERSION = '24.0';

export const logRouter = (action: string) => {
  if (typeof NativeModules?.GenericModule?.logBreadCrumb === 'function') {
    const crumb = `RN ${action}`;
    console.log(action);
    NativeModules?.GenericModule?.logBreadCrumb(crumb, JS_VERSION);
  }
};
