import React from 'react';
import { View, Text } from 'react-native';
import Actions from '../../../../../navigation/postSalesNavigation';
import { isEmpty } from 'lodash';
import HTMLView from 'react-native-htmlview';
import styles from './raiseRequestProcessV2Css';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import { BACK_ARROW_WHITE_ICON } from '../../../../FlightImageConstants';
import { getFont } from '../../../../../PostSalesStyles';

const RaiseRequestProcessCardV2 = ({ header, steps, extraInformation, webViewHeaderText }) => {
  const { fsStyle, ...fonts } = getFont(true);

  const htmlStyle = {
    q: {
      /* fix fo Html Fonts Not working without adding blank tag */
    },
    p: {
      color: colors.defaultTextColor,
      fontSize: fonts.fontSize.font14,
      fontFamily: fonts.regular,
    },
    b: {
      color: colors.white,
      fontSize: fonts.fontSize.font14,
      fontFamily: fonts.black,
    },
  };

  const htmlStyleForExtraInformation = {
    q: {
      /* fix fo Html Fonts Not working without adding blank tag */
    },
    p: {
      color: '#cf8100',
      fontSize: 14,
      fontFamily: fonts.regular,
    },
    b: {
      fontSize: 14,
      fontFamily: fonts.bold,
    },
  };

  return (
    <View style={[styles.whiteCard, styles.innerWrapper]}>
      {!isEmpty(header) && (
        <Text style={[styles.headingTxt, fonts.blackFontFamily, fsStyle.font16]}>{header}</Text>
      )}

      {!isEmpty(steps) &&
        steps.map((step, index) => (
          <View
            style={[
              styles.refundProcessRow,
              AtomicCss.flexRow,
              index === steps.length - 1 ? styles.lastItemStyle : '',
            ]}
          >
            <View style={styles.imgWrapper}>
              <View style={styles.circleWrapper}>
                <Text style={[styles.circleCountTxt, fonts.blackFontFamily, fsStyle.font14]}>
                  {index + 1}
                </Text>
              </View>
            </View>
            <View style={AtomicCss.flex1}>
              <Text style={[AtomicCss.defaultText, fonts.boldFontFamily, fsStyle.font14]}>
                {step.header}
              </Text>
              {isEmpty(step.subTextHtml) && !isEmpty(step.subText) && (
                <Text
                  style={[
                    AtomicCss.defaultText,
                    fonts.regularFontFamily,
                    fsStyle.font14,
                    styles.width90,
                  ]}
                >
                  {step.subText}
                </Text>
              )}
              {!isEmpty(step.subTextHtml) && (
                <HTMLView
                  value={step.subTextHtml}
                  stylesheet={htmlStyle}
                  style={styles.width90}
                  onLinkPress={(url) =>
                    Actions.openPsWebView({
                      headerText: webViewHeaderText,
                      headerIcon: BACK_ARROW_WHITE_ICON,
                      url,
                    })
                  }
                />
              )}
            </View>
          </View>
        ))}

      {!isEmpty(extraInformation) && (
        <View style={[styles.moreInfoWrapper, isEmpty(steps) ? '' : AtomicCss.marginTop20]}>
          <HTMLView
            value={extraInformation}
            stylesheet={htmlStyleForExtraInformation}
            onLinkPress={(url) =>
              Actions.openPsWebView({
                headerText: 'Information',
                headerIcon: BACK_ARROW_WHITE_ICON,
                url,
              })
            }
          />
        </View>
      )}
    </View>
  );
};

export default RaiseRequestProcessCardV2;
