import React from 'react';
import PropTypes from 'prop-types';
import {Image, Text, View} from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getStyles from '../../styles/HotelChatWithHostCss';
import {getActionButton, getHouseRules, handleClickEvent} from '../utils/HotelBookingDetailUtil';
import HotelBookingTrackingHelper from '../../HotelBookingTrackingHelper';
import { SERVICE_DESK_ICON } from '../../HotelImageConstants';
import { getStaticData } from '../../../staticData/staticData';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const talkHostCardIcon = SERVICE_DESK_ICON;

class SpecialRequestsCard extends React.Component {

  constructor(props) {
    super(props);
    this.fonts = getFont(true);
    this.styles = getStyles(this.fonts);
    this.fsStyle = this.fonts.fsStyle;
    this.hotelDetailResponse = this.props.response;
    this.houseRules = getHouseRules(this.hotelDetailResponse);
  }

  componentDidMount() {
    HotelBookingTrackingHelper.trackClickEvent(this.props.pageName, this.props.card.cardId + '_SHOWN', this.props.response);
  }

  render() {
    const staticData = getStaticData();
    return (
      <View style={[this.styles.CardContentOuter]}>
        <View style={[AtomicCss.whiteBg, AtomicCss.marginBottom0]}>
          <View style={[this.styles.CardContentFull]}>
            <View style={[this.styles.CardContent]}>
              <Image style={this.styles.CardIcon} source={talkHostCardIcon}/>
              <Text style={[this.fonts.blackFontFamily, AtomicCss.blackText, this.fsStyle.font14]}>
                {staticData.specialRequestCardsText.additionalText.makeASpecialRequestText}
              </Text>
            </View>
            <View style={[AtomicCss.paddingRight16, {marginLeft: 35}]}>
              <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween, AtomicCss.paddingBottom8]}>
                <View style={[this.styles.hostReplyInfo]}>
                  <Text
                    style={[this.styles.blackFont, this.styles.BlackText, this.styles.specialRequestText]}
                  >{staticData.specialRequestCardsText.additionalText.youCanReachOutToTheHotelText}
                    {this.houseRules && '\n\n'+staticData.specialRequestCardsText.additionalText.doReadTheHouseRulesProvidedText}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View style={this.styles.redirectBtnWrapper}>
            {getActionButton(this.props.card, this.props.response, this.handleClick)}
          </View>
        </View>
      </View>
    );
  }

  handleClick = (action) => {
    handleClickEvent(this.props.card, action, this.props.response, this.props.pageName);
  };
}

SpecialRequestsCard.propTypes = {
  response: PropTypes.object.isRequired,
  card: PropTypes.object.isRequired,
  pageName: PropTypes.string.isRequired
};

export default SpecialRequestsCard;