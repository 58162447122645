import React from 'react';
import { View, Text, TouchableHighlight, Animated, Easing, TouchableOpacity } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { themeColors } from '../../../../../PostSalesStyles';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import SpellingCorrectionSection from '../SpellingCorrectionSection';
import styles from './nameChangeRequestCss';
import FlightBookingTrackingHelper from '../../helper/FlightBookingTrackingHelper';
import { getStaticData } from '../../../../../staticData/staticData';
import { getFont } from '../../../../../PostSalesStyles';

class Overlay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      overlayPosition: new Animated.Value(0),
    };
    FlightBookingTrackingHelper.trackLoadEvent(
      'Special request: Name Change Pop up',
      this.props.response,
    );
  }

  static navigationOptions = { header: null };
  componentDidMount() {
    const bottom = 400;
    const delay = 200;
    const duration = 400;
    this.startAnimate(bottom, duration, delay);
  }

  startAnimate(bottom, duration, delay) {
    Animated.timing(this.state.overlayPosition, {
      toValue: bottom,
      easing: Easing.easeInOut,
      duration,
      delay,
    }).start();
  }
  render() {
    const { fsStyle, ...fonts } = getFont(true);
    const {
      nameChangeCloseToDepartureText: {
        items: { spellingCorrectionSectionText },
      },
      nameChangeOverlayText: {
        heading,
        additionalText: { incorrectNameSpellingCorrectionText, changingFullNameIsNotPermitted },
      },
      submitARequestText,
      backText,
    } = getStaticData();
    const colorbuttonScheme = this.props.isCorporateBooking
      ? themeColors.myBizGradientColor
      : themeColors.rectFullBlueButtonGradientColor;
    return (
      <View style={styles.overlayContainer}>
        <TouchableHighlight style={styles.overlayBg}>
          <Text>.</Text>
        </TouchableHighlight>
        <Animated.View style={[styles.overlayContent, { bottom: this.state.overlayPosition }]}>
          <Text style={[styles.heading, fonts.regularFontFamily, fsStyle.font36]}>{heading}</Text>
          <Text style={[styles.subHeading, fonts.regularFontFamily, fsStyle.font16]}>
            <Text style={fonts.boldFontFamily}>{incorrectNameSpellingCorrectionText}</Text>
            {changingFullNameIsNotPermitted}
          </Text>
          <SpellingCorrectionSection content={spellingCorrectionSectionText} />
          <View
            style={[
              AtomicCss.flexRow,
              AtomicCss.spaceBetween,
              AtomicCss.marginBottom10,
              AtomicCss.alignCenter,
            ]}
          >
            <TouchableOpacity
              onPress={() => {
                this.props.onGoBackPress();
              }}
            >
              <Text style={[styles.btn, fonts.boldFontFamily, fsStyle.font16]}>{backText}</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                this.props.onSubmitClick();
              }}
            >
              <LinearGradient
                style={styles.submitBtn}
                start={{ x: 1.0, y: 0.0 }}
                end={{ x: 0.0, y: 1.0 }}
                colors={colorbuttonScheme}
              >
                <Text style={[AtomicCss.whiteText, fsStyle.font14, fonts.blackFontFamily]}>
                  {submitARequestText}
                </Text>
              </LinearGradient>
            </TouchableOpacity>
          </View>
        </Animated.View>
      </View>
    );
  }
}

export default Overlay;
