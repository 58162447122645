import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import isEmpty from 'lodash/isEmpty'
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { useTheme } from '../../../theme/theme.context';

const InsuranceBenefitsOverlay = ({ response }) => {
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  const { benefits, insuranceHeader } = response;
  const styles = getStyles(fonts, theme);
  
  return (
    <View style={AtomicCss.marginBottom10}>
      <View style={[AtomicCss.marginBottom24, AtomicCss.marginTop5]}>
          <View style={styles.overlayTopline}></View>
      </View>
      <View style={[AtomicCss.paddingHz10]}>
          {!isEmpty(insuranceHeader) && <Text style={[styles.title, AtomicCss.marginBottom24]}>{insuranceHeader}</Text>}
          {!isEmpty(benefits) && benefits.map((item, index) => {
            if(!isEmpty(item.title)){
              return (
                <View style={[AtomicCss.flexRow, AtomicCss.marginBottom10]} key={index}>
                  <View style={styles.bulletCircle}></View>
                  <Text style={[styles.infoCardDisc, AtomicCss.alignLeft]}>{item.title}</Text>
                </View>
              )
            }
          })}
      </View>
    </View>
  )
};

const getStyles = (fonts, theme) => {
  return StyleSheet.create({
    overlayTopline: {
      width: 40,
      height: 4,
      borderRadius: 4,
      backgroundColor: theme.color.disabledButton,
      alignSelf: 'center',
    },
    title: {
      color: theme.color.black,
      ...fonts.font24,
      fontFamily: fonts.black,
      marginBottom: 30,
      lineHeight: 32
    },
    bulletCircle: {
      width: 6,
      height: 6,
      backgroundColor: theme.color.darkGrey5,
      borderRadius: 20,
      top: 5,
      left: 5,
    },
    infoCardDisc: {
      ...fonts.font14,
      color: theme.color.defaultTextColor,
      paddingLeft: 20,
      fontFamily: fonts.regular,
      flex: 1,
    }
  });
}

export default InsuranceBenefitsOverlay;
