import React, { useCallback, useState, useEffect } from 'react';
import { Text, View, TouchableOpacity } from 'react-native';
import getStyles from './SelectRefundModeCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import LinearGradient from 'react-native-linear-gradient';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import InstantRefundBanner from '../../../../Common/InstantRefund/Overlays/InstantRefundBanner';
import { getStaticData } from '../../../../staticData/staticData';
import { isNotNullAndEmpty } from '../../../details/utils/HotelBookingDetailUtil';
import { REFUND_MODE_LIST_NOT_AVAILABLE, INSTANT_REFUND_AVAILABLE, LOBNAMES, REFUNDMODE_PAGE_NAMES, THEME } from '../../../../PostSalesConstant';
import PostSalesBookingTrackingHelper from '../../../../PostSalesBookingTrackingHelper';
import { isNonEmpty } from '@mmt/legacy-commons/Common/utils/StringUtils';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
import HTMLView from 'react-native-htmlview';
import { getFont} from 'apps/post-sales/src/PostSalesStyles';
import RadioWpr from 'apps/post-sales/src/Common/RadioButton/RadioWpr';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import isEmpty from 'lodash/isEmpty';
const getHtmlStyles = (fonts) => {
    return {
        p: {
            color: colors.green,
            ...fonts.font13,
            fontFamily: fonts.black,
            lineHeight: 15.6,
        }
    }
}

const SelectRefundPaymentMode = ({refundAmountText, cancellationRefundMode, setRefundMode, setInstantData, handleBottomOverlay, bookingID, instantRefundData, theme = ''}) => {
    const { fsStyle, ...fonts } = getFont(true);
    const htmlStyle = getHtmlStyles(fonts);
    const styles = getStyles(fonts);
    const {cancellationText: {additionalText}} = getStaticData();
    const { refundModeList } = cancellationRefundMode;
    const [selectedRefundMode, setSelectedRefundMode] = useState(0);
    const {psTheme: styleTheme} = useTheme();

    useEffect(() => {
        const refundModeObj = refundModeList[selectedRefundMode];
        if(!isEmpty(refundModeObj)){
            setRefundMode(refundModeObj.refundModeReason);
            updateRefundMode(refundModeObj);
        } else {
            PostSalesBookingTrackingHelper.trackErrorWithData(REFUNDMODE_PAGE_NAMES.HOTEL, bookingID, REFUND_MODE_LIST_NOT_AVAILABLE, LOBNAMES.HOTEL);
        }
    },[selectedRefundMode])

    const updateRefundMode = (updatedMode) => {
        let instantData = null;
        if (updatedMode.accountNo && updatedMode.accountIdentifier) {
            PostSalesBookingTrackingHelper.trackLoadEvent(REFUNDMODE_PAGE_NAMES.HOTEL, INSTANT_REFUND_AVAILABLE, bookingID);
            instantData = {
                requestInstantRefund: true,
                accountNo: updatedMode.accountNo,
                accountIdentifier: updatedMode.accountIdentifier
            };
        }
        setInstantData(instantData);
    }

    const renderInstantTag = () => {
        return (
            <LinearGradient
                start={{ x:0.0, y: 1.0 }}
                end={{ x: 1.0, y: 0.0 }}
                colors={[colors.skyBlue2, colors.skyBlue1]}
                style={styles.refundTag}
            >
                <Text style={styles.refundTagText}>{additionalText.instantText}</Text>
            </LinearGradient>
        )
    }    

    const getRefundMode = (index, item) => {
        const isActive = selectedRefundMode === index;
        const activeBorderStyle = isActive ? theme === THEME.MYBIZ ? styleTheme.orange3 :  styleTheme.azure : '';
        return (
            <RadioWpr
                isSelected={isActive}
                radioSize={18}
                activeColorStyle={activeBorderStyle}
                customStyle={styles.radioWprStyle}
                onPress={() => {
                    setSelectedRefundMode(index);
                  }}
                activeOpacity={0.8}
                children={
                    <View style={AtomicCss.flex1}>
                        <View style={styles.refundTopDtls}>
                        <Text style={styles.refundLeftDtls}>{item.title}</Text>
                        {item.showInstantTag && renderInstantTag()}
                        </View>
                        <View>
                            {item.subText && item.subText.map((text, index) => {
                            return (
                            <Text style={[styles.refundText, fsStyle.font13, (item.subText.length !== 1 && (index + 1)%2 === 1) ? AtomicCss.blackText : null]}>{text}</Text>
                            )
                            })}
                        </View>
                    </View>
                }
            />

        )
    }

    const renderInstantRefund = () => {
        if (instantRefundData && instantRefundData.showInstantRefundBanner) {
            return (
                <InstantRefundBanner 
                    handleOverlay={handleBottomOverlay}
                    wrapperStyle={{...getPlatformElevation(0)}}
                />
            )
        } else {
            return null;
        }
    };

    return (
        <View style={styles.cardWrapper}>
            <View style={styles.headingText}>
                <Text style={[fsStyle.font20, fonts.blackFontFamily, AtomicCss.blackText, AtomicCss.marginBottom16, AtomicCss.lineHeight24, AtomicCss.flex1]}>{additionalText.selectRefundOptionText}</Text>
                <View style={[AtomicCss.flex1, AtomicCss.flexRow]}>
                    {isNonEmpty(refundAmountText) && <View style={styles.infoWrapper}>
                        <HTMLView stylesheet={htmlStyle} value={refundAmountText} />
                    </View>}
                </View>
            </View>
            <View>
                {isNotNullAndEmpty(refundModeList) && refundModeList.map((item, index) => {
                    return (
                        getRefundMode(index, item)
                    )
                })}
            </View>
            {renderInstantRefund()}
        </View>
    );
}

export default React.memo(SelectRefundPaymentMode);
