import { colors, fontStyle } from '@mmt/legacy-commons/Styles/globalStyles';
  const travelInsuranceColors = {
    highEmphasis: colors.black,
    white: colors.white,
    lightGrey: colors.lightGrey,
    link: colors.blue,
    success: colors.lightSeaGreenTextColor,
    infoCardColor: colors.yellow3,
    disabled: colors.grey,
    lowEmphasis: colors.grey14,
    mediumEmphasis: colors.textGrey,
  };
  export const travelInsuranceAppData = {
    ...fontStyle,
    color: travelInsuranceColors,
    gradientColor: {
      blueGradientColor: [colors.white, '#e0f3ff'],
    },
  };