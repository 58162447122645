import React from 'react';
import { Text, View } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import BaseButton from '../../../../../Common/BaseButton';
import { getFont } from '../../../../../PostSalesStyles';
import { useTheme } from '../../../../../theme/theme.context';
import createStyles from './footerCSS';
const BlackFooter = ({ reviewData, handleClick }) => {
  const { footerContent } = reviewData || {};
  const { heading, priceText, subHeading, cta } = footerContent || {};
  const { title, buttonType } = cta || {};
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);
  return (
    <View style={styles.blackFooterWrapper}>
      <View>
        {heading && <Text style={[styles.btmTextInfo, AtomicCss.marginBottom2]}>{heading}</Text>}
        {!!priceText && (
          <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom2]}>
            <Text style={[fonts.blackFontFamily, fsStyle.font18, AtomicCss.whiteText]}>
              {priceText}
            </Text>
          </View>
        )}
        {!!subHeading && <Text style={styles.btmTextInfo}>{subHeading}</Text>}
      </View>
      <BaseButton
        variant={buttonType}
        text={title}
        clickHandler={handleClick}
        textStyle={{ ...fonts.boldFontFamily, ...fsStyle.font14 }}
      />
    </View>
  );
};

export default BlackFooter;
