import React from 'react';
import { View, Text, ActivityIndicator } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import styles from './BottomOverlay/ReqstWaitingOverlay/ReqstWaitingOverlayCss';
import Button from '../Button';
import { getStaticData } from '../../staticData/staticData';
import BaseButton, { BUTTON_VARIANT } from '../BaseButton';

export const renderProgressView = (langAware = false) => {
  const { loadingText } = getStaticData(langAware);
  return (
    <View style={{ alignItems: 'center' }}>
      <ActivityIndicator styleAttr="Inverse" size="large" color="#008b8b" />
      <Text style={[{ marginTop: 8 }]}>{loadingText}</Text>
    </View>
  );
};

export const renderErrorView = (clickHandler = () => {}, langAware = false, desc) => {
  const {
    errorText: {
      somethingWentWrongError,
      unableToProcessRequestTechIssueText
    },
    tryAgainText
  } = getStaticData(langAware);
  const description = !!desc? desc : unableToProcessRequestTechIssueText
  return (
    <View style={styles.OverlayContentWrapper}>
      <View style={AtomicCss.marginBottom16}>
        <View style={styles.overlayTopline}></View>
      </View>
      <View style={styles.titleWrapper}>
        <Text style={styles.title}>{somethingWentWrongError}</Text>
        <Text style={AtomicCss.font16}>{description}</Text>
      </View>
      <View style={[AtomicCss.marginBottom20, AtomicCss.marginTop10]}>
        <BaseButton 
        variant={BUTTON_VARIANT.PRIMARY}
        text={tryAgainText}
        clickHandler={clickHandler}
        />
      </View>
    </View>
  );
};
