import { NativeModules } from "react-native";
import { COUNTRY_CODE, COUNTRY_INFO_MAPPING, AFFILIATES } from "../const";

import {
  _getLangCookie,
  _getCCDECookie,
  _getAgentIdCookie,
  _isAffiliateUser,
  _getAffiliateName,
  _getExternalPlatformCookie,
  _gdprGetCookie,
  isSupportzGlobalDomain,
  isSupportzIndiaDomain,
} from "../webUtils";
import { _getMmtAuth } from "./UserSessionModules";

const LOGGING_TRACKING_INFO = "loggingTrackingInfo";
const MMT_AUTH = "mmtAuth";
const EMAIL_ID = "emailId";
const UX_AFFILIATE_NAME = "ux-affiliate-name";

export const getDomainHeaders = () => {
  if (isSupportzGlobalDomain()) {
    return {
      'entity-name': 'global',
      'user-currency': _gdprGetCookie('userCurrency') || 'AED',
      'user-country': _gdprGetCookie('cc') || 'ae',
    }
  } else if (isSupportzIndiaDomain()) {
    if(_getCCDECookie() === 'IN') {
      return {
        'entity-name': 'india',
        'user-currency': _gdprGetCookie('userCurrency') || 'inr',
        'user-country': _gdprGetCookie('cc') || 'in',
      }
    } else {
      return {
        'user-currency': _gdprGetCookie('userCurrency') || 'inr',
        'user-country': _gdprGetCookie('cc') || 'in',
      }
    }
   
  }
  return {};
}
const getHeaders = () => {
  let countryCode = _getCCDECookie() || COUNTRY_CODE.IN;
  const langCookie = _getLangCookie();
  countryCode = countryCode.toUpperCase();
  const lang = langCookie ? langCookie.toLowerCase() : null;
  const countryInfoMap = COUNTRY_INFO_MAPPING[countryCode] || null;
  const uxAffiliateName = getUxAffiliateCookie() || "";
  const domainHeaders = getDomainHeaders();
  return {
    "Content-Type": "application/json",
    Accept: "application/json",
    region: countryInfoMap["region"],
    language: lang || countryInfoMap["language"],
    currency: countryInfoMap["currency"],
    [UX_AFFILIATE_NAME]: uxAffiliateName,
    ...domainHeaders,
  };
};

export const getRequestHeader = async (bookingId, uniqueIDType, act) => {
  const clientIp =
    document.getElementById("ip") && document.getElementById("ip").innerText;
  const agentId = _getAgentIdCookie() || "";
  const uxAffiliateName = getUxAffiliateCookie() || "";
  const domainHeaders = getDomainHeaders();
  try {
    return {
      [MMT_AUTH]: await _getMmtAuth(act),
      [EMAIL_ID]: "",
      [UX_AFFILIATE_NAME]: uxAffiliateName,
      [LOGGING_TRACKING_INFO]: JSON.stringify({
        userId: "",
        uniqueId: bookingId,
        uniqueIdType: uniqueIDType,
        source: "PWA",
        loginSource: "PWA",
        agentId,
        clientIp,
        affiliate_id: _isAffiliateUser()
          ? AFFILIATES[_getAffiliateName()] &&
            AFFILIATES[_getAffiliateName()].toUpperCase()
          : "",
        appVersion: "PWA"
      }),
      ...domainHeaders,
    };
  } catch (e) {
    return null;
  }
};

const isNetworkAvailable = () => {
  return true;
};

const isAffiliateUser = () => {
  return _isAffiliateUser();
};

const getAffiliateName = () => {
  return _getAffiliateName();
};

const getUxAffiliateCookie = () => {
  return _getExternalPlatformCookie();
}

NativeModules.NetworkModule = {
  getHeaders,
  isNetworkAvailable,
  isAffiliateUser,
  getAffiliateName,
  getUxAffiliateCookie,
};
