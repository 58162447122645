import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import {View, Text, StyleSheet, Image, TouchableOpacity} from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {mapIcon, emailIcon, phoneIcon} from '../HolidayBookingConstants';


class ContactListItemDetails extends React.Component {

  static navigationOptions = {
    header: null
  };

  render() {
    const {item} = this.props;
    return (
      <View style={[styles.CollapsedTapDtlsContainer1]}>

        {!isEmpty(item.address) &&
        <View
          style={[AtomicCss.flexDirection, AtomicCss.flexRow, AtomicCss.spaceBetween,
            styles.ContactRow]}>
          <View style={AtomicCss.flex1}>
            <Text
              style={[AtomicCss.lineHeight18, AtomicCss.font14, AtomicCss.boldFont,
                AtomicCss.defaultTextColor]}>
              {item.address}
            </Text>
          </View>
          {!isEmpty(item.addressUrl) ? (
            <TouchableOpacity onPress={() => this.props.showLocation(item.locationUrl)}>
              <View style={styles.ImageWrapper}>
                <Image style={styles.mapIcon} source={mapIcon}/>
              </View>
            </TouchableOpacity>
          ): (
            <View style={styles.ImageWrapper}>
              <Image style={styles.mapIcon} source={mapIcon}/>
            </View>
          )}
        </View>
        }

        {!isEmpty(item.email) &&
        <View
          style={[AtomicCss.flexDirection, AtomicCss.flexRow, AtomicCss.spaceBetween,
            styles.ContactRow]}
        >
          <View style={AtomicCss.flex1}>
            <Text
              style={[AtomicCss.lineHeight18, AtomicCss.font14, AtomicCss.boldFont,
                AtomicCss.defaultTextColor]}
            >
              {item.email}
            </Text>
          </View>
          <TouchableOpacity onPress={() => this.props.sendMail(item.email)}>
            <View style={styles.ImageWrapper}>
              <Image
                style={styles.emailIcon}
                source={emailIcon}/>
            </View>
          </TouchableOpacity>
        </View>
        }

        {!isEmpty(item.phone) &&
        <TouchableOpacity
          activeOpacity={0.7}
          style={[AtomicCss.flexDirection, AtomicCss.flexRow, AtomicCss.spaceBetween, styles.ContactRow]}>
          <View style={AtomicCss.flex1}>
            <Text
              style={[AtomicCss.lineHeight18, AtomicCss.font14, AtomicCss.boldFont, AtomicCss.defaultTextColor]}>
              {item.phone}
            </Text>
          </View>
          <TouchableOpacity onPress={() => this.props.callMmt(item.phone)}>
            <View style={styles.ImageWrapper}>
              <Image
                style={styles.phoneIcon}
                source={phoneIcon}/>
            </View>
          </TouchableOpacity>
        </TouchableOpacity>
        }
      </View>
    );
  }
}

const styles = StyleSheet.create({
  CollapsedTapDtlsContainer1: {
    backgroundColor: 'transparent',
    borderTopWidth: 1,
    borderColor: '#e4e4e4'
  },

  ContactRow: {
    paddingLeft: 56,
    borderBottomWidth: 1,
    borderColor: '#e4e4e4',
    backgroundColor: 'transparent',
    paddingVertical: 12,
    paddingRight: 10
  },

  ImageWrapper: {
    width: 22,
    height: 22,
    marginLeft: 10
  },

  mapIcon: {
    width: 14,
    height: 17
  },

  emailIcon: {
    width: 16,
    height: 11
  },

  phoneIcon: {
    width: 18,
    height: 18
  }
});

ContactListItemDetails.propTypes = {
  item: PropTypes.object.isRequired,
  callMmt: PropTypes.func.isRequired,
  sendMail: PropTypes.func.isRequired,
  showLocation: PropTypes.func.isRequired
};

export default ContactListItemDetails;
