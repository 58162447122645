import {StyleSheet } from "react-native";
export default (theme, fonts) =>
  StyleSheet.create({
    OverlayContentWrapper: {
      backgroundColor: theme.color.white,
      paddingTop: 12,
      // position: 'relative',
      zIndex: 100,
      width: '100%',
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      // paddingBottom: 50,
      overflow: 'scroll',
    },
    innerWrapper: {
      marginBottom: 10,
    },
    overlayTopline: {
      width: 40,
      height: 4,
      borderRadius: 4,
      backgroundColor: theme.color.disabledButton,
      alignSelf: 'center',
      borderRadius: 4,
      zIndex: 11,
      top: 5,
      opacity: 0.8,
    },
    title: {
      color: theme.color.black,
      fontSize: 22,
      ...fonts.blackFontFamily,
      marginBottom: 30,
      lineHeight: 32,
    },

    headingTxt: {
      fontSize: 24,
      color: theme.color.black22,
      marginTop: 15,
      ...fonts.blackFontFamily,
    },
    statusTracker: {
      borderRadius: 4,
      padding: 20,
      paddingLeft: 22,
      paddingRight: 10,
      backgroundColor: theme.color.paleBlue,
      borderWidth: 1,
      borderColor: theme.color.faintBlue,
      marginBottom: 20,
    },
    statusTrackerRow: {
      // flexDirection: 'row',
      borderLeftColor: theme.color.cyanBlue1,
      borderLeftWidth: 2,
      paddingLeft: 23,
      paddingBottom: 30,
    },
    lastEleStyle: {
      borderLeftColor: theme.color.cyanBlue1,
      borderLeftWidth: 0,
      paddingBottom: 10,
    },
    countWrapper: {
      borderRadius: 4,
      backgroundColor: theme.color.cyanBlue1,
      width: 25,
      height: 25,
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      left: -13,
    },
    countText: {
      color: theme.color.white,
      fontSize: 14,
      ...fonts.blackFontFamily,
    },
    btnWrapper: {
      paddingHorizontal: 20,
    },
    faqListWrapper: {
      borderWidth: 1,
      borderColor: theme.color.lightGrey,
      padding: 16,
      borderRadius: 8,
      marginBottom: 10,
    },
    faqListRow: {
      marginBottom: 0,
    },
    faqListRowHeading: {
      paddingVertical: 5,
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    rightArrow: {
      width: 14,
      height: 10,
      marginLeft: 5,
      marginTop: 5,
    },
    activeArrow: {
      transform: [
        {
          rotate: '-180deg',
        },
      ],
    },
    lastElemetStyle: {
      marginBottom: 0,
    },
    extracharge: {
      backgroundColor: theme.color.creamWhite,
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      paddingHorizontal: 7,
      paddingVertical: 6,
      flexDirection: 'row',
      flex: 1,
      justifyContent: 'space-evenly',
      marginTop: 6,
      alignItems: 'center',
    },
    locationIcon: {
      marginTop: 20,
      height: 65,
      width: 120,
      alignSelf: 'center',
    },
  });