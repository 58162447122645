import { StyleSheet } from 'react-native';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
import { colors, fonts } from '@mmt/legacy-commons/Styles/globalStyles';

const styles = StyleSheet.create({
  contentWrapper: {
    backgroundColor: colors.lightGrey,
  },
  whiteColor: {
    backgroundColor: colors.white,
  },
  topStickySection: {
    ...getPlatformElevation(2),
    backgroundColor: colors.white,
    zIndex: 10,
  },
  pageWrapper: {
    marginTop: 16,
    marginBottom: 78,
  },
  buttonBottomWrapper: {
    position: 'absolute',
    bottom: 16,
    paddingHorizontal: 12,
    width: '100%',
    flex: 1,
  },

  flatBtnWrapper: {
    borderRadius: 4,
  },
  flatBtn: {
    borderRadius: 8,
    paddingHorizontal: 20,
    paddingVertical: 14,
  },
  btnText: {
    fontSize: 16,
    fontFamily: fonts.black,
    lineHeight: 20,
    color: colors.white,
  },
  selectModeWrapper: {
    position: 'relative',
    zIndex: 2,
  },
  uploadDocWrapper: {
    position: 'relative',
    zIndex: 4,
  },
  UploadDocumentsOldWrapper: {
    position: 'relative',
    zIndex: 6,
  },
  stepBarWrapper: {
    paddingHorizontal: 20,
    marginTop: 16,
    marginBottom: 24,
  },
  disabledLine: {
    backgroundColor: colors.gray3,
    width: 36,
    height: 6,
    borderRadius: 2,
    marginRight: 4,
  },
  activeLine: {
    backgroundColor: colors.azure,
  },
  activeDot: {
    backgroundColor: colors.azure,
  },
  loaddingWrapper: {
    width: 44,
    height: 44,
    borderRadius: 50,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  activityIndicator: {
    transform: [{ scale: 1.2 }],
  },
});

export default styles;
