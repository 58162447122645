import { StyleSheet } from 'react-native';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';

const styles = StyleSheet.create({
  headerSec: {
    paddingHorizontal: 12,
    paddingTop: 16,
  },
  headingIcon: {
    width: 24,
    height: 24,
    marginRight: 10,
    backgroundColor: '#f1f1f1',
  },
  imageGallery: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingLeft: 35,
    backgroundColor: '#fff',
    paddingBottom: 20,
    ...getPlatformElevation(0.5),
  },
  galleryImg: {
    width: '45%',
    height: 142,
    margin: 5,
    borderRadius: 10,
  },
});
export default styles;
