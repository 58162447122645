import { StyleSheet, Platform } from 'react-native';
import { DEFAULT_THEME } from 'apps/post-sales/src/theme/default.theme';

export default <T extends typeof DEFAULT_THEME>(theme: T) => StyleSheet.create({
    benefitWrapper:{
        backgroundColor: theme.color.transparent,
        marginHorizontal: Platform.OS === 'web' ? 0 : 12,
        marginBottom:12,
        marginTop:25,
        paddingVertical: 0
    },
    benefitHead:{
        paddingTop:10,
        paddingBottom:12,
        marginTop:-30,
        left: 15,
        position:'absolute',
        zIndex: 1
    },
    cardDetailsWrapper:{
        borderRadius:17,
        padding:1,
    },
    benefitWhiteBg:{
        backgroundColor: theme.color.white,
        borderRadius:16,
        padding:16
    },
    membershipIcon: {
        width: 200,
        height: 35,
    },
    listIconStyle:{
        width:24,
        height:24,
        resizeMode:'contain',
        marginRight: 8
    },
    flashWrapper:{
        padding:8,
        borderRadius:8
    },
    flashIconStyle:{
        width:16,
        height:16,
        marginRight: 4,
        marginTop: 3
    },
});
