import { isEmpty } from 'lodash';
import React, { useCallback} from 'react';
import { View } from "react-native";
import Actions from '../../../../navigation/postSalesNavigation';
import { getStaticData } from '../../../../staticData/staticData';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { handleClickEvent, openHostChatWeb } from '../../utils/HotelBookingDetailUtil';
import Assistance from './Assistance';
import createStyle from "./AssistanceCss";
import { useTheme } from '../../../../theme/theme.context';


const AssistanceCard = ({ response, card, pageName, resetUnreadCount }) => {
  const {psTheme: theme}=useTheme();
	const styles = createStyle(theme);
  const { hostDetails, hotelBookingInfo, hostCaretakerInfo } = !isEmpty(response) ? response : {};
  const { staffDetails } = !isEmpty(hotelBookingInfo) ? response.hotelBookingInfo : {}
  const { hosts, caretakers, sections } = !isEmpty(staffDetails) ? staffDetails : {}
  const hostDetailsObj = !isEmpty(hostDetails) ? hostDetails : {}
  const hostCaretakerInfoObj = !isEmpty(hostCaretakerInfo) ? hostCaretakerInfo : {}
  const finalHostDetail = !isEmpty(hosts) ? { ...hosts[0], ...hostDetailsObj } : null
  const finalCareTakerDetails = !isEmpty(caretakers) ? { ...caretakers[0], ...hostDetailsObj } : null

  if (finalHostDetail == null) {
    return null;
  }

  const { host, others } = hostDetailsObj.contact || {};
  const { chatURL } = hosts[0];

  const { callHostText, callCareTakerText } = getStaticData();
  const { actionList = [] } = !isEmpty(card) ? card : {};
  const actionList1 = [
    {
      "actionRank": 1,
      "actionId": "SeeStaffDetails_VerList",
      "actionType": "RectFullBtn",
      "actionLabeltext": "See Details",
      "actionFamily": "StaffDetails",
      "actionAlertHeaderText": null,
      "actionAlertMainText": null,
      "subCardId": null,
      "subActionListMainText": null,
      "subActionList": null,
      "bookingType": "DEFAULT",
      "modalWindowData": null,
      "label": null
  }
  ]

  const handleChatWithHost = useCallback(() => {
    resetUnreadCount();
    openHostChatWeb(chatURL);
  }, [chatURL, resetUnreadCount]);

  const handleCallToHost = useCallback(() => {
    Actions.openCallHotel({ response, headerText: callHostText, phoneNumbers: host });
  }, [response, callHostText, host]);

  const handleCallToOthers = useCallback(() => {
    Actions.openCallHotel({ response, headerText: callCareTakerText, phoneNumbers: others });
  }, [response, others, callCareTakerText]);

  const handleCTAAction = useCallback((action) => {
    handleClickEvent(card, action, response, pageName);
  }, [card, response, pageName]);

  return (
    <View
      style={[
        AtomicCss.whiteBg,
        AtomicCss.marginHz12,
        AtomicCss.marginBottom12,
        AtomicCss.borderRadius16,
      ]}
    >
        <View style={styles.cancelDetails}>
          <Assistance
            sections={sections}
            hostDetails={finalHostDetail}
            hostCareTakerInfo={hostCaretakerInfoObj}
            caretakersDetails={finalCareTakerDetails}
            handleChatWithHost={handleChatWithHost}
            handleCallToHost={handleCallToHost}
            handleCallToOthers={handleCallToOthers} 
            actionList={actionList1}
            handleCTAAction={handleCTAAction} 
          />
        </View>
    </View>
  );
};

export default React.memo(AssistanceCard);

