import { Dimensions, Platform, StyleSheet } from 'react-native';
import { Fonts, Theme } from '../../types';

const DEVICE_WINDOW = Platform.OS === 'ios' ? Dimensions.get('screen') : Dimensions.get('window');

const getMapStyle = (fonts: Fonts, theme: Theme) => {
  const { color } = theme;
  return StyleSheet.create({
    bookingInfoDetails: {
      paddingVertical: 13,
      paddingHorizontal: 14,
      paddingBottom: 16,
    },
    mapContainer: {
      backgroundColor: color.greyBrdr2,
      borderRadius: 16,
      overflow: 'hidden',
      paddingHorizontal: 2,
    },
    mapContainerExpanded: {
      backgroundColor: color.greyBrdr2,
      borderRadius: 0,
      overflow: 'hidden',
      paddingHorizontal: 0,
    },
    mapNotLoadedContainer: {
      backgroundColor: color.red3,
      height: 100,
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: -30,
    },
    mapLoading: {
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: -30,
    },
    errorIcon: {
      height: 26,
      width: 28,
      marginBottom: 6,
    },
    errorText: {
      fontSize: fonts.fontSize.font14,
      ...fonts.regularFontFamily,
      color: color.red,
      paddingHorizontal: '25%',
      textAlign: 'center',
    },
    warningText: {
      fontSize: fonts.fontSize.font14,
      ...fonts.regularFontFamily,
      color: color.yellow,
      paddingHorizontal: '25%',
      textAlign: 'center',
    },
    icon: {
      width: 40,
      height: 40,
    },
    carIcon: {
      width: 35,
      height: 35,
      transform: [{ rotate: '-20deg' }],
    },
    iconWrapper: {
      alignContent: 'center',
      zIndex: 2
    },
    refresh: {
      height: 40,
      width: 40,
      position: 'absolute',
      left: 10,
      top: 10,
    },
    recenter: {
      height: 40,
      width: 40,
      position: 'absolute',
    },
    zoomOut: {
      height: 40,
      width: 40,
      position: 'absolute',
    },
    infoMessage: {
      paddingHorizontal: 6,
      marginVertical: 10,
      flexDirection: 'row',
    },

    lastUpdateMsg: {
      paddingHorizontal: 6,
      paddingVertical: 5,
      marginLeft: 5,
      marginRight: 5,
      alighItems: 'center',
      justifyContent: 'center',
    },

		lastUpdateMsgExpanded: {
      paddingHorizontal: 0,
      marginVertical: 0,
      marginLeft: 0,
      marginRight: 0,
      alighItems: 'center',
      justifyContent: 'center',
    },

    MamiCard: {
      borderRadius: 16,
      backgroundColor: color.smokeWhite,
    },
    mar10Bt: {
      marginBottom: 10,
    },
    mar30Tp: {
      // marginTop:30
    },
    mar0Tp: {
      marginTop: 0,
      marginBottom: 0,
    },
    minuteInfo: {
      backgroundColor: color.azure,
      paddingVertical: 5,
      paddingHorizontal: 10,
      borderRadius: 50,
      //width:60,
      marginRight: 10,
    },
    whiteText: {
      color: color.white,
    },
    font12: {
      fontSize: fonts.fontSize.font12,
    },
    font14: {
      fontSize: fonts.fontSize.font14,
    },
    flexOne: {
      flex: 1,
    },
    textCenter: {
      textAlign: 'center',
    },
    mapImage: {
      height: 250,
    },
    blackText: { color: '#000' },
    fontRegular: { ...fonts.regularFontFamily },
    fontBlack: { ...fonts.blackFontFamily },
    rowCenter: { flexDirection: 'row', alignItems: 'center' },
    whiteBtnHalfOuter: {
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
    showView: {
      display: 'flex',
    },
    hideView: {
      display: 'none',
    },
    green: {
      backgroundColor: color.lightCyan,
    },
    greenText: {
      color: color.green,
    },
    red: {
      backgroundColor: color.lightRed,
    },
    redText: {
      color: color.red2,
    },
    yellow: {
      backgroundColor: color.lightYellow1,
    },
    yellowText: {
      color: color.yellow,
    },
    trackerStatus: {
      borderRadius: 4,
      padding: 8,
      margin: 8,
      marginTop: 0,
    },
    trackerStatusTxt: {
      fontSize: fonts.fontSize.font12,
    },
    circle: {
      width: 30,
      height: 30,
      borderRadius: 30 / 2,
      backgroundColor: color.red,
    },
    pinText: {
      color: color.white,
      ...fonts.boldFontFamily,
      textAlign: 'center',
      fontSize: fonts.fontSize.font20,
      marginBottom: 10,
    },
    markerImage: {
      width: 20,
      height: 20,
    },
    markerText: {
      color: color.white,
      ...fonts.boldFontFamily,
    },
    tooltipStatusWrapper: {
      marginBottom: 1,
    },
    TootlTipInnerInfo: {
      backgroundColor: color.black,
      padding: 8,
      borderRadius: 8,
      alignSelf: 'flex-start',
    },
    markerContainer: {
      alignItems: 'center',
      bottom: -21,
    },
    customMarker: {
      width: 100,
      height: 100,
      justifyContent: 'center',
      alignItems: 'center',
    },
    overlayStyles: {
      marginTop: -30,
    },

    mapDirection: {
      height: 24,
      width: 24,
      marginRight: 3,
    },
    pickupIcon: {
      height: 62,
      width: 32,
    },

    dropIcon: {
      height: 48,
      width: 24,
    },
    toolTipIconWrapAndroid: {
      top: 56,
      left: 44,
    },
    toolTipIconWrapIos: {
      top: 106,
      left: 40,
    },

    toolTipIcon: {
      height: 20,
      width: 20,
    },

    toolTipText: {
      color: color.white,
      ...fonts.boldFontFamily,
      fontSize: fonts.fontSize.font14,
      textAlign: 'center',
    },

    headerWrap: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      zIndex: 10,
    }
  });
};

export default getMapStyle;

export const htmlstyles = (theme: Theme, fonts: Fonts) => {
  return StyleSheet.create({
    p: {
      fontSize: 16,
      color: theme.color.defaultTextColor,
    },
    b: {
      fontSize: fonts.fontSize.font16,
      ...fonts.boldFontFamily,
      color: theme.color.black,
    },
  });
};

export const lastUpdateMsgStyle = (theme: Theme, fonts: Fonts) => {
  return StyleSheet.create({
    p: {
      fontSize: fonts.fontSize.font12,
      color: theme.color.greyText1,
      textAlign: 'center',
    },
    b: {
      fontSize: fonts.fontSize.font12,
      ...fonts.boldFontFamily,
    },
  });
};
