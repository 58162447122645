/* eslint-disable prettier/prettier */
import { StyleSheet } from 'react-native';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';

const styles = StyleSheet.create({
  progressWrapper: {
    position: 'relative',
    width: '100%',
    backgroundColor: colors.lightGray,
    height: 4,
    borderRadius: 4,
  },
  progress: {
    height: '100%',
    position: 'absolute',
    zIndex: 1,
    borderRadius: 4,
  },
  progressinProgress: {
    backgroundColor: colors.cngColor2,
  },
  progressinPending: {
    backgroundColor: colors.strongOrange,
  },
  progressfailed: {
    backgroundColor: colors.red6,
  },
  progressText: {
    fontSize: 11,
    color: colors.defaultTextColor,
    fontWeight: '600',
    paddingHorizontal: 10,
    backgroundColor: 'transparent',
    height: 18,
    textAlign: 'center',
  },
  textWrapper: {
    flexDirection: 'row',
    zIndex: 2,
    position: 'relative',
  },
  transparentFiller: {
    maxWidth: '90%',
    alignItems: 'flex-end',
  },
});

export default styles;
