import { Dimensions } from 'react-native';
import { colors, fonts } from '@mmt/legacy-commons/Styles/globalStyles';

const windowHeight = Dimensions.get('screen').height;
const windowWidth = Dimensions.get('screen').width;

const styles = {
  pageWrapper: {
    flex: 1,
  },
  pageScroll: {
    flex: 1,
  },

  footerBtnWrapper: {
    padding: 12,
    position: 'absolute',
    zIndex: 2,
    bottom: 0,
    left: 0,
    width: '100%',
  },
  outPolicy: {
    backgroundColor: '#ec2126',
    borderRadius: 9,
    paddingHorizontal: 5,
    paddingVertical: 2,
    alignSelf: 'flex-start',
    position: 'absolute',
    bottom: 50,
    zIndex: 9,
    left: 12,
  },
  policyText: { color: '#fff', fontSize: 11, fontFamily: fonts.bold },
  whiteCard: {
    backgroundColor: '#ffffff',
    padding: 15,
    marginVertical: 8,
    elevation: 2,
    shadowColor: '#000000',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.3,
    shadowRadius: 1,
  },

  ReviewCard: {
    marginVertical: 5,
  },
  FareBkCard: {
    paddingVertical: 15,
    paddingHorizontal: 0,
    marginVertical: 5,
  },
  cardTitle: {
    color: colors.gray,
    fontSize: 16,
    fontFamily: fonts.light,
    marginBottom: 10,
  },
  overlayWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: windowHeight,
    width: windowWidth,
    elevation: 10,
    zIndex: 100,
    justifyContent: 'flex-end',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
    height: '100%',
    width: '100%',
    zIndex: 1,
  },
  overlayContent: {
    backgroundColor: colors.white,
    padding: 20,
    position: 'absolute',
    zIndex: 1,
    width: '100%',
    bottom: 0,
  },
};

export default styles;
