import React, { useEffect } from 'react';
import { View, Text, Image, StyleSheet } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { handleClickEvent } from '../utils/CabBookingDetailUtil';
import CardWrapper from '../modules/bookingDetails/components/CardWrapper';
import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';
import { getFont } from '../../PostSalesStyles';
import { useTheme } from '../../theme/theme.context';
import HeadingComponent from '../modules/bookingDetails/components/HeadingComponent';
import BaseButton, { BUTTON_VARIANT } from '../../Common/BaseButton';
import isEmpty from 'lodash/isEmpty';
import CabBookingTrackingHelper from '../CabBookingTrackingHelper';
import { CLICK_EVENT, PAGE_NAME } from '../CabBookingDetailsConstant';

const NeedHelpCard = ({ card, response, pageName }) => {
  const { heading, subheading, desc, actionList } = card || {};

  const handleClick = (action) => {
    handleClickEvent(card, action, response, pageName);
  };

  const { fsStyle, ...fonts } = getFont();
  const { psTheme } = useTheme();
  const styles = getStyle(fonts, psTheme);

  useEffect(()=>{
    CabBookingTrackingHelper.trackClickEvent(PAGE_NAME.TRIP_DETAILS, CLICK_EVENT.NEED_HELP_CARD_SHOWN);
  }, []);

  return (
    <View style={styles.cardHeading}>
      <HeadingComponent heading={heading} />
      <View style={[AtomicCss.marginVertical10]}>
        {!!subheading && <Text style={styles.subHeading}>{subheading}</Text>}
        {!!desc && <Text style={styles.description}>{desc}</Text>}
      </View>
      {!isEmpty(actionList) &&
        actionList.map((action) => {
          const { actionLabeltext, actionType } = action;
          if(actionType === BUTTON_VARIANT.OUTLINED_CAPSULE){
            return (
              <BaseButton
                variant={actionType}
                buttonStyle={styles.buttonStyle}
                textStyle={styles.textStyle}
                text={actionLabeltext}
                clickHandler={() => handleClick(action)}
                shadow={false}
              />
            );
          }
        })}
    </View>
  );
};

const getStyle = (fonts, theme) => {
  const { color } = theme;
  return StyleSheet.create({
    headingText: {
      color: color.black,
      ...fonts.boldFontFamily,
      fontSize: fonts.fontSize.font16,
    },
    cardHeading: {
      paddingVertical: 12,
      paddingHorizontal: 14,
    },
    subHeading: {
      color: color.black,
      ...fonts.boldFontFamily,
      fontSize: fonts.fontSize.font14,
    },
    description: {
      color: color.black,
      ...fonts.regularFontFamily,
      fontSize: fonts.fontSize.font14,
    },
    textStyle: {
      fontSize: fonts.fontSize.font14,
    },
    buttonStyle: {
      borderRadius: 10,
      paddingVertical: 10,
      ...getPlatformElevation(0),
    },
  });
};

export default CardWrapper(NeedHelpCard);
