import React from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import createStyle from './IssueSelectionCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {ISSUE_AT_HOTEL_OVERLAY, ISSUE_AT_HOTEL_L1} from '../../../PostSalesConstant';
import { CHECKIN_DENIED_ICON, SERVICE_DESK_LIGHT_BLUE_BG_ICON, CHEVRON_RIGHT_BLUE_ICON, SERVICE_DESK_LIGHT_MYBIZ, CHECKIN_DENIED_ICON_MYBIZ } from '../../HotelImageConstants';
import { useTheme } from '../../../theme/theme.context';
import { PROFILE_TYPE } from '@mmt/legacy-commons/Common/constants/AppConstants';
import { getUserProfileType } from '@mmt/legacy-commons/AppState/AppUserConfig';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const rtArrow = CHEVRON_RIGHT_BLUE_ICON;

const IssueSelection = (props) => {
    const { ...fonts } = getFont(true);
    const {psTheme: theme}=useTheme();
    const styles = createStyle(theme, fonts);
    const userProfile = getUserProfileType();
    return (
        <>
            <View style={AtomicCss.marginBottom16}>
                <View style={styles.overlayTopline}></View>
            </View>
            <Text style={styles.title}>What issue you are facing?</Text>
            <TouchableOpacity style={[AtomicCss.flexRow, AtomicCss.spaceBetween, AtomicCss.alignCenter, AtomicCss.marginBottom20]}
            onPress={()=> props.handleIssueAtHotelCard('IssueAtHotelOptions_BHF',props.pageName,true)}>
                <View style={[AtomicCss.flex1, AtomicCss.flexRow]}>
                    <Image style={styles.callImgStyle} source={userProfile === PROFILE_TYPE.BUSINESS ? SERVICE_DESK_LIGHT_MYBIZ:SERVICE_DESK_LIGHT_BLUE_BG_ICON} />
                    <View>
                        <Text style={styles.linkTxt}>{ISSUE_AT_HOTEL_L1.BAD_SERVICE_TITLE}</Text>
                        <Text style={styles.desc}>{ISSUE_AT_HOTEL_L1.BAD_SERVICE_SUBTEXT}</Text>
                    </View>
                </View>
                <Image style={styles.arrowStyle} source={rtArrow} />
            </TouchableOpacity>
            <TouchableOpacity style={[AtomicCss.flexRow, AtomicCss.spaceBetween, AtomicCss.alignCenter,styles.scheduleLinkBtn]}
            onPress={()=> props.updateHotelOverlayType(ISSUE_AT_HOTEL_OVERLAY.ISSUE_L2_SELECTION)}>
                <View style={[AtomicCss.flex1, AtomicCss.flexRow]}>
                    <Image style={styles.callImgStyle} source={userProfile === PROFILE_TYPE.BUSINESS ? CHECKIN_DENIED_ICON_MYBIZ:CHECKIN_DENIED_ICON} />
                    <View>
                        <Text style={styles.linkTxt}>{ISSUE_AT_HOTEL_L1.CHECKIN_DENIED_TITLE}</Text>
                        <Text style={styles.desc}>{ISSUE_AT_HOTEL_L1.CHECKIN_DENIED_SUBTEXT}</Text>
                    </View>
                </View>
                <Image style={styles.arrowStyle} source={rtArrow} />
            </TouchableOpacity>
        </>

    );
}


export default IssueSelection;
