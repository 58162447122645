import React from 'react';
import PropTypes from 'prop-types';
import { Image, Text, TouchableOpacity, View } from 'react-native';
import createStyle from './ConfirmOverlayCss';
import BaseButton, { BUTTON_VARIANT } from './BaseButton';
import { ThemeContext } from '../theme/theme.context';
const cancelIcon = require('@mmt/legacy-assets/src/cancelling-icon.webp');
class ConfirmationOverlay extends React.Component {
  static contextType = ThemeContext;
  render() {
    const {
      title,
      content,
      leftButtonText,
      rightButtonText,
      leftBtnClick,
      rightBtnClick,
      outsideClick,
      additionalText,
    } = this.props;
    const styles = createStyle(this.context.psTheme);
    return (
      <View style={styles.overlayContainer}>
        <TouchableOpacity style={styles.overlayBg} onPress={() => outsideClick()} />
        <View style={styles.overlayContent}>
          <View style={styles.flexRow}>
            <Image source={this.props.imgIcon} style={this.props.imgIconStyle} />
            <View style={styles.mar15Bt}>
              <Text style={styles.overlayHead}>{title}</Text>
            </View>
          </View>
          {!!additionalText &&
            <View style={[styles.mar15Bt]}>
              <Text style={styles.cancelTxt}>{additionalText}</Text>
            </View>
          }
          <View style={[styles.mar25Bt]}>
            <Text style={styles.cancelTxt}>{content}</Text>
          </View>
          <View style={[styles.flexRow, styles.alignCenter, styles.spaceBetween]}>
            <TouchableOpacity onPress={() => leftBtnClick()}>
              <Text style={styles.actionBlueText}>{leftButtonText}</Text>
            </TouchableOpacity>
            <BaseButton
              variant={BUTTON_VARIANT.CAPSULE}
              text={rightButtonText}
              clickHandler={rightBtnClick}
              minWidth={100}
              shadow
            />
          </View>
        </View>
      </View>
    );
  }
}

export default ConfirmationOverlay;

ConfirmationOverlay.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string.isRequired,
  leftButtonText: PropTypes.string,
  rightButtonText: PropTypes.string,
  leftBtnClick: PropTypes.func.isRequired,
  rightBtnClick: PropTypes.func.isRequired,
  outsideClick: PropTypes.func.isRequired,
  imgIcon: PropTypes.object,
  imgIconStyle: PropTypes.object,
};

ConfirmationOverlay.defaultProps = {
  title: 'Are you sure?',
  leftButtonText: 'BACK',
  rightButtonText: 'CONFIRM',
  imgIcon: cancelIcon,
  imgIconStyle: { width: 65, height: 40, marginRight: 15 },
};
