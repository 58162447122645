import React from 'react';
import { Text, TouchableOpacity, View, Image } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import styles from './checkBoxCss';
import {tick2} from '../../BusImageConstants';

export default class CheckBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: this.props.listObj.some(x => x.id === this.props.currentObj.id) ,
      issueList: null
    };
  }

  onPressHandler = () => {
    let newList = this.props.listObj;
    let currObj = this.props.currentObj;

    let exists = newList.some(item => item.id === currObj.id);

    if (exists) {
      newList = newList.filter((item) => item.id !== currObj.id);
      // console.log('Removed Item --- ', currObj)
    } else {
      newList.push(currObj);
      // console.log('Added Item --- ', currObj)
    }
    this.props.updateList(newList);

    this.setState({
      isActive: !this.state.isActive
    });
  }

  renderCheckbox = () => {
    const { isActive } = this.state;
    const selectedStyle = isActive ? 'Selected' : '';
    return (
      <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween, AtomicCss.alignCenter]}>
        <Text style={[AtomicCss.font14, AtomicCss.blackText, AtomicCss.regularFont]}>{this.props.title}</Text>
        <View style={[styles.checkbox, styles['checkbox' + selectedStyle]]}>
          {isActive ? <Image style={styles.checkedIcon} source={tick2} /> : null}
        </View>
      </View>
    );
  }

  render() {
    return (
      <TouchableOpacity style={styles.checkBoxWrapper} onPress={this.onPressHandler}>
        {this.renderCheckbox()}
      </TouchableOpacity>
    );
  }
}
