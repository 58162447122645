import {colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles';

const styles = {
  contentTitle: {
    fontSize: 28,
    color: colors.black,
    fontFamily: fonts.light,
    marginBottom: 18
  },
  contentDesc: {
    fontSize: 14,
    color: colors.defaultTextColor,
    fontFamily: fonts.bold,
    marginBottom: 20
  },
  contentFooter: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  actionBtn: {
    paddingVertical: 10
  },
  actionBtnText: {
    fontSize: 16,
    color: colors.azure,
    fontFamily: fonts.medium
  },
  ImagePlaceholder: {
    width: 30,
    marginRight: 12,
    alignItems: 'center'
  },
  chatIconStyle: {
    width: 19,
    height: 24
  },
  successIconStyle: {
    width: 28,
    height: 24

  },
  ruppeeIconStyle: {
    width: 27,
    height: 25

  }
};

export default styles;

