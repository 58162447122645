import React from 'react';
import { View, ScrollView, StyleSheet, Platform, Animated, BackHandler } from 'react-native';
import PropTypes from 'prop-types';
import Actions from '../../../../../navigation/postSalesNavigation';
import FareBreakUp from './FareBreakUp/FareBreakUp';
import BasePage from '../../../../../Common/PostSalesBasePage';
import PayModeBreakup from './PayModeBreakup/PayModeBreakup';
import FlightBookingDetailsConstant from '../../FlightBookingDetailsConstant';
import FlightBookingTrackingHelper from '../../helper/FlightBookingTrackingHelper';
import { isNullOrEmpty } from '../../../../../Common/commonUtil';
import { getStaticData } from '../../../../../staticData/staticData';
import FlightBookingModule from '@mmt/legacy-commons/Native/FlightBookingModule';
import { getRootTag } from '@mmt/legacy-commons/AppState/RootTagHolder';
import { getPriceBreakCardDetails } from '../../utils/priceBreakupUtil';
import CommonOverlayMessage from 'apps/post-sales/src/Common/CommonOverlayMessage';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import TCSBottomOverlay from '../../../../../Common/TCSBottomOverlay/TCSBottomOverlay';
import isEmpty from 'lodash/isEmpty';
import HeaderWrapper from 'apps/post-sales/src/Common/HeaderWrapper';

class PaymentPriceBreakup extends BasePage {
  static navigationOptions = {
    header: null,
  };
  constructor(props) {
    super(props);
    this.state = {
      showTcsInfoOverlay: { value: false },
    };
  }

  actionHandler = (action) => {
    switch (action) {
      case FlightBookingDetailsConstant.ACTION_DOWNLOAD_INVOICE:
        if (Platform.OS === 'ios') {
          this.props.bookingDetailsResponse.reactTag = getRootTag();
          FlightBookingModule.downloadTicket(
            JSON.stringify(this.props.bookingDetailsResponse),
            'Flight_Customer_Invoice',
          );
        } else if (Platform.OS === 'web') {
          FlightBookingModule.downloadTicket(
            'Flight_Customer_Invoice',
            this.props.bookingDetailsResponse.bookingID,
          );
        } else {
          FlightBookingModule.downloadTicket('Flight_Customer_Invoice');
        }
        FlightBookingTrackingHelper.trackClickEvent(
          'Details_paymentdetail',
          'Flight_invoice',
          this.props.bookingDetailsResponse,
        );
        break;
      default:
        break;
    }
  };

  openTcsInfoOverlay = () => {
    this.setState(
      {
        showTcsInfoOverlay: { value: true },
      },
      () => {
        Animated.timing(new Animated.Value(-500), {
          toValue: 0,
          duration: 200,
        }).start();
      },
    );
  };

  closeTcsInfoOverlay = () => {
    Animated.timing(new Animated.Value(-500), {
      toValue: 0,
      duration: 200,
    }).start();
    setTimeout(() => {
      this.setState({
        showTcsInfoOverlay: { value: false },
      });
    }, 0);
  };

  render() {
    const staticData = getStaticData();
    const {
      flightPaymentPriceBreakUpText: {
        heading,
        items: {
          fareBreakUpText: { priceBreakupText, totalAmountText },
          paymentBreakupText: { payModeBreakupText, amountPaidText },
        },
      },
    } = staticData;
    const {
      paymentModeBreakUpList,
      priceBreakUpList,
      loyalityDiscountDetail,
      totalAmount,
      discountDetails,
    } = getPriceBreakCardDetails(this.props.bookingDetailsResponse, staticData, false, false);
    return (
      <View style={styles.PriceBreakupWrapper}>
        <HeaderWrapper
          titleText={heading}
          clickHandler={this.onBackPress}
      />
        <ScrollView style={{ marginTop: 10 }}>
          {!isNullOrEmpty(this.props.bookingDetailsResponse.fareBreakupNodeList) && (
            <FareBreakUp
              pricebreakuplist={priceBreakUpList}
              discountDetails={discountDetails}
              loyalityDiscountDetail={loyalityDiscountDetail}
              heading={priceBreakupText}
              totalAmount={totalAmount}
              totalAmountText={totalAmountText}
              cashbackCouponText={this.props.bookingDetailsResponse.cashbackCouponText}
              failedAncillaryRefundText={
                this.props.bookingDetailsResponse.failedAncillaryRefundText
              }
              tcsInfoText={this.props.bookingDetailsResponse?.tcsCollectionInfo?.tcsInfoText}
              tcsInfoOverlay={this.openTcsInfoOverlay}
              currency={this.props.bookingDetailsResponse.currency}
              loyaltyDetails={this.props.bookingDetailsResponse.loyaltyDetails}
            />
          )}
          {this.props.bookingDetailsResponse.paymentBreakupList && (
            <PayModeBreakup
              paymentBreakupList={paymentModeBreakUpList}
              heading={payModeBreakupText}
              amountPaidText={amountPaidText}
              paymentDetails={this.props.bookingDetailsResponse.paymentDetails}
              bnplMsg={this.props.bookingDetailsResponse.bnplPaidText}
              btnClickListener={this.props.btnClickListener}
              currency={this.props.bookingDetailsResponse.currency}
            />
          )}
        </ScrollView>
        {this.state.showTcsInfoOverlay.value &&
          !isEmpty(this.props.bookingDetailsResponse?.tcsCollectionInfo?.tcsPopupInfo) && (
            <CommonOverlayMessage
              handleBottomOverlay={this.closeTcsInfoOverlay}
              isBorderRadius={true}
              overlayContentStyle={styles.overlayContentStyle}
              overlayWrapperStyle={styles.overlayWrapperStyle}
              content={
                <TCSBottomOverlay
                  tcsPopupInfo={this.props.bookingDetailsResponse?.tcsCollectionInfo?.tcsPopupInfo}
                  onClose={this.closeTcsInfoOverlay}
                />
              }
            />
          )}
      </View>
    );
  }

  onBackPress = () => {
    Actions.pop();
  };
  onBackPressHandler = () => {
    if (this.state.showTcsInfoOverlay.value) {
      this.closeTcsInfoOverlay()
      return true;
    }
  };

  componentDidMount() {
    BackHandler.addEventListener('hardwareBackPress', this.onBackPressHandler);
    FlightBookingTrackingHelper.trackLoadEvent(
      'Details_paymentdetail',
      this.props.bookingDetailsResponse,
    );
  }

  componentWillUnmount() {
    BackHandler.removeEventListener('hardwareBackPress', this.onBackPressHandler);
    this.props.newNavigation?.removeListener('beforeRemove');
  }
}

const styles = StyleSheet.create({
  PriceBreakupWrapper: {
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: '#f2f2f2',
  },
  btnWrapper: {
    paddingLeft: 15,
    paddingRight: 15,
    marginBottom: 15,
    alignItems: 'center',
  },
  overlayContentStyle: {
    backgroundColor: colors.white,
    padding: 20,
    paddingTop: 15,
    position: 'relative',
    zIndex: 1,
    width: '100%',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },
  overlayWrapperStyle: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    elevation: 10,
    zIndex: 100,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    flexDirection: 'row',
  },
});

export default PaymentPriceBreakup;

PaymentPriceBreakup.propTypes = {
  bookingDetailsResponse: PropTypes.object.isRequired,
  btnClickListener: PropTypes.func.isRequired,
};
