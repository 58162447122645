import React from 'react';
import {View, StyleSheet, ActivityIndicator} from 'react-native';

export default class OTPLoader extends React.Component {
  render() {
    return (
      <View style={styles.LoaderWrapper}>
        <ActivityIndicator size="large" color="#008cff" />
      </View>
    );
  }
}
const styles = StyleSheet.create({
  LoaderWrapper: {
    height: 250,
    alignItems: 'center',
    justifyContent: 'center'
  }
});
