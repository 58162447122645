import React, { useEffect } from 'react';
import { View, Image, Text, TouchableOpacity } from 'react-native';
import { isEmpty } from 'lodash';
import getStyles, {getHtmlStyles} from './InstallmentsCardCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getActionButton, handleClickEvent, getThrottleParams} from '../../utils/HotelBookingDetailUtil';
import HTMLView from 'react-native-htmlview';
import HotelBookingTrackingHelper from '../../../HotelBookingTrackingHelper';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const InstallmentsCard = ({ hotelDetailsResponse, card, showOverlay, pageName }) => {
  const { fsStyle, ...fonts } = getFont(true);
  const styles = getStyles(fonts);
  const htmlStyle = getHtmlStyles(fonts);
  const { installmentDetails } = hotelDetailsResponse || {};
  const { mainCard } = installmentDetails || {};
  const { sectionList, ctaList, title, subTitle, bottomText } = mainCard || {};

  useEffect(() => {
		HotelBookingTrackingHelper.trackShowEvent(pageName, 'gb_payment_pending_card_shown', hotelDetailsResponse);
	}, [pageName, hotelDetailsResponse]);

  const handleViewDetailsClick = () => {
    showOverlay && showOverlay();
    HotelBookingTrackingHelper.trackClickEvent(pageName, 'gb_payment_pending_view_details_click', hotelDetailsResponse);
  };

  const handleCTAAction = (action) => {
    HotelBookingTrackingHelper.trackClickEvent(pageName, 'gb_payment_pending_pay_now_click', hotelDetailsResponse);
    handleClickEvent(card, action, hotelDetailsResponse, pageName);
  };

  return (
    <View style={styles.myBizPaymentPendingCard}>
      <View style={styles.cardHeader}>
        {!isEmpty(title) && (
          <Text
            style={[
              fsStyle.font18,
              fonts.blackFontFamily,
              AtomicCss.blackText,
              AtomicCss.paddingBottom8,
              AtomicCss.lineHeight18
            ]}
          >
            {title}
          </Text>
        )}
        {!isEmpty(subTitle) && (
          <Text style={[AtomicCss.font14, AtomicCss.defaultText, AtomicCss.regularFont, AtomicCss.lineHeight15]}>
            {subTitle}
          </Text>
        )}
      </View>
      <View style={styles.cardContent}>
        <View style={[styles.innerContentWrap]}>
          <View style={styles.innerContent}>
            {!isEmpty(sectionList) &&
              sectionList.map(({ icon, heading, extraTag, subHeading }, index) => (
                <View style={AtomicCss.flexRow}>
                  {index != sectionList.length - 1 && <View style={styles.dashedLines} />}
                  <Image source={{ uri: icon }} style={[styles.iconDueCalendar, AtomicCss.marginRight10]} />
                  <View style={styles.paymentStepContent}>
                    <View
                      style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom3]}
                    >
                      <Text style={[fsStyle.font14, fonts.blackFontFamily, AtomicCss.blackText]}>
                        {heading}
                      </Text>
                      <Text style={[fsStyle.font13, fonts.regularFontFamily, AtomicCss.greyText, AtomicCss.marginLeft5]}>
                        {extraTag}
                      </Text>
                    </View>
                    <Text style={[fsStyle.font13, AtomicCss.defaultText, fonts.regularFontFamily]}>
                      {subHeading}
                    </Text>
                  </View>
                </View>
              ))}
          </View>
        </View>
        {card && !isEmpty(card.actionList) &&
              getActionButton(card, hotelDetailsResponse, handleCTAAction, getThrottleParams)}
        <View style={AtomicCss.paddingTop10}>
            <HTMLView
            value={bottomText}
            stylesheet={htmlStyle}
            onLinkPress={handleViewDetailsClick}
            />

        </View>
      </View>
    </View>
  );
};

export default InstallmentsCard;
