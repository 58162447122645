//postsales
import {getImagePath} from '../utils/PostSaleUtil';

export const topStatusConfirmedIcon = getImagePath('cab_confirmation.webp');
export const topStatusCancelledIcon = getImagePath('topstatusIcon8.webp');
export const topStatusFailedIcon = getImagePath('topstatusIcon7.webp');
export const topStatusOnTripIcon = getImagePath('cab_thumbsup.webp');
export const refundProcessed = getImagePath('refund_processed.webp');
export const cancellationIcon = getImagePath('cancellation.webp');
export const changeplanicon = getImagePath('changeplans.webp');
export const cancelIcon2 = getImagePath('ic_cross.webp');
export const summaryIcon = getImagePath('summaryIcon.webp');
export const currentLocation = getImagePath('curLocation.webp');
export const currentLocationImage = getImagePath('curLocation.webp');
export const zoomOut = getImagePath('zoomOut.webp');
export const zoomIn = getImagePath('zoomIn.webp');
export const mainCardIcon = getImagePath('ic_tick_stroke.webp');
export const oval = getImagePath('cab_oval.webp');
export const lockImg = getImagePath('lock.webp');
export const offerIcon = getImagePath('offer_green_small.webp');
export const mapImg2 = getImagePath('rectangle.webp');
export const guestIcon = getImagePath('guest_icon.webp');
export const locationGhostIcon = getImagePath('locationGhostIcon.webp');
export const tick = getImagePath('ic_fp_tick_white_copy_2.webp');
export const deleteIcon = getImagePath('ic_delete.webp');
export const submittedIcon = getImagePath('submittedIcon.webp');
export const checkBoxIcon = getImagePath('checkbox_tick.webp');
export const crossIcon = getImagePath('crossIcon1.webp');
export const CROSS_ICON = getImagePath('crossIcon3.png');
export const dropDownArrow = getImagePath('blueArrow.webp');
export const exclusionIcon2 = getImagePath('circular_red.webp');
export const dateChangeIcon = getImagePath('DateChangeIcon.webp');
export const driverIcon = getImagePath('driver.webp');
export const seatIcon = getImagePath('seats.webp');
export const aCIcon = getImagePath('ac.webp');
export const bagIcon = getImagePath('bags.webp');
export const notAvailable = getImagePath('NotAvailable.webp');
export const successIcon = getImagePath('amendmentSuccess.webp');
export const rightArrow = getImagePath('rightIcon.webp');
export const rightArrowGrey = getImagePath('rightArrowGrey.webp');
export const stepHeaderTick = getImagePath('stepHeaderTick.webp');
export const InfoBlueIcon =  getImagePath('infoBlueIcon.webp');
export const DOWN_ARROW_LG = getImagePath('arrowDownImgLg.png');
export const DOWN_ARROW_SM = getImagePath('arrowDownArrowSm.png');
export const LOCATION = getImagePath('location.png');
export const LOC = getImagePath('locIcon.png');
export const doubleTick = getImagePath('doubleTick.png');
export const TopIllustration = getImagePath('TopIllustration.png');
export const COUPONS_ICON = getImagePath('coupons.png');
export const ArrowDown = getImagePath('blueDropIcondown.png');
export const greenTick2 = getImagePath('greenTick2.png');
export const userIcon = getImagePath('userIcon.png');
export const acIcon = getImagePath('acIcon.png');
export const luggageIcon = getImagePath('luggageIcon.png');
export const seatIcon1 = getImagePath('seatIcon.png');
export const rideImage = getImagePath('rideLogo.png');
export const whiteStar = getImagePath('whiteStar.png');
export const backArrowIOS = getImagePath('backIosGrey.png');
export const downloadIcon = getImagePath('downloadIcon.png');
export const callUsIcon = getImagePath('callUsIcon.png');
export const shareIcon = getImagePath('shareIcon2.png');
export const falseClaimIcon = getImagePath('false_special_claim.webp');
export const calendarIconError = getImagePath('calendarIconError.webp');
export const cabVoucherImage = getImagePath('cabVoucher.webp');
export const copyCodeIcon = getImagePath('copyCodeIcon.webp')

export const mapImage = getImagePath('map.png');
export const bluedot = getImagePath('bluedot.png');
export const callIcon = getImagePath('callIcon.png');
export const mailIcon = getImagePath('mailIconsmall.png');
export const carImage = getImagePath('carIcon.png');
export const downloadGrey = getImagePath('download.png');
export const doneIcon = getImagePath('done.webp');
export const confirmedTag = getImagePath('confirmedTag.webp');
export const infoIcon = getImagePath('info-icon1.webp');
export const roundTrip = getImagePath('roundTrip.webp');
export const fullScreen = getImagePath('fullScreen.webp');
export const triangle = getImagePath('triangle.webp');
export const cabAssured = getImagePath('assured.webp');
export const openUrl = getImagePath('link.webp');
export const currentLocationIcon = getImagePath('currentLocation.webp');
export const zoomInIcon = getImagePath('zoomIn2.webp');
export const zoomOutIcon = getImagePath('zoomOut2.webp');
export const mapDirection = getImagePath('mapDirection.webp');
export const dropIcon = getImagePath('dropIcon.webp');
export const pickupIcon = getImagePath('pickupIcon.webp');
export const toolTip = getImagePath('toolTip.webp');
//common
export const blueArrowRight = require('@mmt/legacy-assets/src/blueArrowRight.webp');
export const mapHeaderIcon = require('@mmt/legacy-assets/src/ic_back_arrow.webp');
export const headerIcon = require('@mmt/legacy-assets/src/white_backarrow.webp');
export const topStatusAfterTripIcon = require('@mmt/legacy-assets/src/topstatusIcon9.webp');
export const headerIcon2 = require('@mmt/legacy-assets/src/trip_header_back_icon.webp');
export const commonheaderIcon = require('@mmt/legacy-assets/src/whiteCross.webp');
export const cancelIcon = require('@mmt/legacy-assets/src/ic_cancelled.webp');
export const mapImg = require('@mmt/legacy-assets/src/map.webp');
export const refundIcon = require('@mmt/legacy-assets/src/refundIcon.webp');
export const refreshIcon = require('@mmt/legacy-assets/src/img/refresh.webp');
export const errorIcon = require('@mmt/legacy-assets/src/error_red.webp');
export const priceBreakUpImage = require('@mmt/legacy-assets/src/payment_icon.webp');
export const inclusionIcon = require('@mmt/legacy-assets/src/green.webp');
export const exclusionIcon = require('@mmt/legacy-assets/src/ic_cross_gray.webp');
export const dotHollowGray = require('@mmt/legacy-assets/src/dot_hollow_gray.webp');
export const infoRed = require('@mmt/legacy-assets/src/info_red.webp');
export const helpImage = require('@mmt/legacy-assets/src/cantFind.webp');
export const paymentIcon = require('@mmt/legacy-assets/src/payment_icon.webp');
export const extraInfo = require('@mmt/legacy-assets/src/iconInfoWhite.webp');
export const calenderIcon = require('@mmt/legacy-assets/src/Images/calendar.webp');
export const addressBoxIcon = require('@mmt/legacy-assets/src/ic_from.webp');
export const swapIcon = require('@mmt/legacy-assets/src/swap_gray.webp');
export const greenTick = getImagePath('circular_green.webp');
export const arrowRight = require('@mmt/legacy-assets/src/arrow_cta.webp');
export const backArrow = require('@mmt/legacy-assets/src/backArrowAndroid.webp');
export const chatImage = require('@mmt/legacy-assets/src/chatIcon-whiteBg.webp');
export const COPY_ICON = require('@mmt/legacy-assets/src/copyIcon.webp');
export const forwardIcon = getImagePath('Arrow.webp');
export const myCashIcon = getImagePath('MyCashIcon.webp');
export const cashRefundIcon = getImagePath('NewRefundIcon.webp');
export const cabIcon = getImagePath('v5cabIcon3x.webp');
export const likeThumbIcon = require('@mmt/legacy-assets/src/likeThumb.png');
export const dislikeThumbIcon = require('@mmt/legacy-assets/src/dislikeThumb.png');
export const closeIcon = require('@mmt/legacy-assets/src/close.png');
