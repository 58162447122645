import React from 'react';
import PropTypes from 'prop-types';
import {Image, Text, View} from 'react-native';
import styles from '../styles/MetroMamiCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {getActionButton, isNotNullAndEmpty, getButtonStyle, onPartialCancel, onFullCancel} from '../MetroBookingDetailUtil';
import MetroConstant from '../MetroConstant';
import MetroBookingTrackingHelper from '../MetroBookingTrackingHelper';

class ChangeInPlan extends React.Component {
  render() {
    return (
      <View style={[styles.MamiCard, styles.mar10Bt,styles.Card,AtomicCss.marginTop10]}>
        <View style={styles.CardContent}>
          <View style={styles.ContentPortion}>
            <Text style={[styles.blackFont, styles.BlackText]}>CHANGE IN PLANS?</Text>
          </View>
        </View>
        <View
          style={isNotNullAndEmpty(this.props.card.actionList) ? [getButtonStyle(this.props.card.actionList), styles.transperantBg] : styles.hideView}
        >
          {getActionButton(this.props.card, this.props.response, this.handleClick)}
        </View>
      </View>
    );
  }

  handleClick = (action) => {
    switch (action.actionFamily) {
      case MetroConstant.FULL_CANCEL:
        MetroBookingTrackingHelper.trackClickEvent("Mob:Customer Support:Metro:TripDetails", "FullCancel");
        onFullCancel(this.props.response);
        break;
      case MetroConstant.PARTIAL_CANCEL:
        onPartialCancel(this.props.response);
        break;
      default:
        break;
    }
  };
}

ChangeInPlan.propTypes = {
  response: PropTypes.object.isRequired,
  card: PropTypes.object.isRequired,
  pageName: PropTypes.string.isRequired
};

export default ChangeInPlan;
