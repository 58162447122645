import React from 'react';
import { View, Text } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import createStyles from './PickAndDropCSS';
import { getFont } from '../../../../../PostSalesStyles';
import { useTheme } from '../../../../../theme/theme.context';
import {
  dateMonth,
  getFormattedDateFromMillis,
  twentyFourHourTimeFormat,
} from '@mmt/legacy-commons/Helpers/dateHelpers';
const PickAndDropDetails = ({ reviewData }) => {
  const { selectedBus, boardingPoint, droppingPoint } = reviewData || {};
  const { from: boardingCity, to: dropCity, departureTime, arrivalTime, durationText } =
    selectedBus || {};
  const departure = getFormattedDateFromMillis(departureTime, twentyFourHourTimeFormat);
  const arrival = getFormattedDateFromMillis(arrivalTime, twentyFourHourTimeFormat);
  const departureDate = getFormattedDateFromMillis(departureTime, dateMonth);
  const arrivalDate = getFormattedDateFromMillis(arrivalTime, dateMonth);
  const { name: boardAddress } = boardingPoint || {};
  const { name: dropAddress } = droppingPoint || {};
  const { fsStyle, ...fonts } = getFont();
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);

  return (
    <View style={[styles.pickAndDropDetails]}>
      <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween, AtomicCss.alignCenter]}>
        <View style={[AtomicCss.flex2]}>
          <Text
            style={[
              fsStyle.font12,
              AtomicCss.defaultText,
              fonts.boldFontFamily,
              styles.lineHeight24,
            ]}
          >
            {departureDate}
          </Text>
          {!!departure && (
            <Text
              style={[
                fsStyle.font22,
                AtomicCss.blackText,
                fonts.blackFontFamily,
                AtomicCss.marginBottom12,
              ]}
            >
              {departure}
            </Text>
          )}
          <View>
            {!!boardAddress && (
              <Text
                style={[
                  fsStyle.font12,
                  AtomicCss.defaultText,
                  fonts.boldFontFamily,
                  AtomicCss.lineHeight20,
                ]}
              >
                {boardAddress},
              </Text>
            )}
            {!!boardingCity && (
              <Text
                style={[
                  fsStyle.font12,
                  AtomicCss.defaultText,
                  fonts.boldFontFamily,
                  AtomicCss.lineHeight20,
                  AtomicCss.alignLeft,
                ]}
              >
                {boardingCity}
              </Text>
            )}
          </View>
        </View>
        <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginHz8]}>
          <View style={styles.borderLine}></View>
          {!!durationText && (
            <Text
              style={[fsStyle.font12, AtomicCss.lightText, fonts.regularFontFamily, styles.marHz4]}
            >
              {durationText}
            </Text>
          )}
          <View style={styles.borderLine}></View>
        </View>
        <View style={[styles.selfStart, AtomicCss.alignRight, AtomicCss.flex2]}>
          <Text
            style={[
              fsStyle.font12,
              AtomicCss.defaultText,
              fonts.boldFontFamily,
              styles.lineHeight24,
              AtomicCss.alignRight,
            ]}
          >
            {arrivalDate}
          </Text>
          {!!arrival && (
            <Text
              style={[
                fsStyle.font22,
                AtomicCss.blackText,
                fonts.blackFontFamily,
                AtomicCss.alignRight,
                AtomicCss.marginBottom12,
              ]}
            >
              {arrival}
            </Text>
          )}
          <View>
            {!!dropAddress && (
              <Text
                style={[
                  fsStyle.font12,
                  AtomicCss.defaultText,
                  fonts.boldFontFamily,
                  AtomicCss.lineHeight20,
                  AtomicCss.alignRight,
                ]}
              >
                {dropAddress}
              </Text>
            )}
            {!!dropCity && (
              <Text
                style={[
                  fsStyle.font12,
                  AtomicCss.defaultText,
                  fonts.boldFontFamily,
                  AtomicCss.lineHeight20,
                  AtomicCss.alignRight,
                ]}
              >
                {dropCity}
              </Text>
            )}
          </View>
        </View>
      </View>
    </View>
  );
};

export default PickAndDropDetails;
