import React, { useCallback, useEffect, useMemo } from "react";
import { Text, View, Image, ScrollView, TouchableOpacity, StyleSheet, Platform } from "react-native";
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import CommonBottomOverlayMessage from '../../../Common/CommonOverlayMessage/CommonBottomOverlayMessage';
import Button from "../../../Common/Button";
import { isEmpty } from "lodash";
import { getStaticData } from '../../../staticData/staticData';
import { handleClickEvent } from "../../details/utils/HotelBookingDetailUtil";
import HotelBookingDetailsConstant from "../../details/HotelBookingDetailsConstant";
import HTMLView from 'react-native-htmlview';
import HotelBookingTrackingHelper from "../../HotelBookingTrackingHelper";
import { getFont } from "apps/post-sales/src/PostSalesStyles";

const InstallmentDetails = React.memo(({response, title, subTitle, ctaList, sectionList, closeModal, pageName }) => {
  const { fsStyle, ...fonts } = getFont(true);
  const { lblOkayGotIt } = getStaticData(true);
  const payNowCTA = useMemo(() => !isEmpty(ctaList) && ctaList.find(({ actionFamily }) => actionFamily == 'INSTALLMENT_PAY_NOW'));

  useEffect(()=>{
    HotelBookingTrackingHelper.trackShowEvent(pageName, "gb_installment_details_card_shown", response);
  }, [pageName, response]);
  const handlePayNow = useCallback(() => {
        HotelBookingTrackingHelper.trackClickEvent("Hotel|Groupbooking", "gb_installment_details_pay_now_click", response);
        handleClickEvent({}, {actionFamily: HotelBookingDetailsConstant.ACTION_GROUP_BOOKNG_PAY_NOW}, response, "")
        closeModal && closeModal();
  },[closeModal, response]);

  return (
    <View style={AtomicCss.paddingBottom20}>
      <TouchableOpacity onPress={closeModal} >
        <View style={styles.overlayTopline}></View>
      </TouchableOpacity>
      <ScrollView>
        <View style={styles.cardContent}>
          <View style={styles.cardHeader}>
            <Text style={[fsStyle.font18, fonts.blackFontFamily, AtomicCss.blackText, AtomicCss.paddingBottom8]}>{title}</Text>
            <Text style={[fsStyle.font14, AtomicCss.defaultText, fonts.regularFontFamily, AtomicCss.lineHeight20]}>{subTitle}</Text>
          </View>

          <View style={[styles.innerContentWrap]}>

            <View style={styles.innerContent}>
              {
                !isEmpty(sectionList) && sectionList.map((item, index) => (
                  <View style={[AtomicCss.flexRow]}>
                    {sectionList.length != index + 1 && <View style={styles.dashedLines}></View>}
                    <Image source={{ uri: item.icon }} style={[styles.iconDueCalendar, AtomicCss.marginRight10]} />
                    <View style={styles.paymentStepContent}>
                      <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom3]}>
                        <Text style={[fsStyle.font14, fonts.blackFontFamily, AtomicCss.blackText]}>{item.heading}</Text>
                      </View>
                      <Text style={[fsStyle.font13, AtomicCss.greyText, fonts.boldFontFamily]}>{item.subHeading}</Text>
                      {
                        !isEmpty(item.pointerList) && <View style={AtomicCss.paddingTop10}>
                          {
                            item.pointerList.map((pointers, index1) => (
                              <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom5]}>
                                <View style={AtomicCss.marginRight10}><Image source={{ uri: pointers.icon }} style={styles.rollingIcon} /></View>
                                <Text style={[fsStyle.font12, AtomicCss.greyText, fonts.regularFontFamily]}>{pointers.text}</Text>
                              </View>
                            ))
                          }
                        </View>
                      }
                      {
                        !isEmpty(item.banner) &&
                        <View style={[styles.duePaymentInfo, { backgroundColor: item.banner.bgColor }]}>
                          <HTMLView 
                            style={[fsStyle.font13, AtomicCss.defaultText, fonts.regularFontFamily, AtomicCss.lineHeight20]} 
                            stylesheet={styles} 
                            value={item.banner.desc} />
                        </View>
                      }
                    </View>
                  </View>
                ))
              }
              <View style={ (Platform.OS == 'web' && AtomicCss.width60) || Platform.OS == 'android' && AtomicCss.marginBottom30}>
              {payNowCTA ? <Button clickHandler={handlePayNow} btnBg="gradientBtn" btnType="flat" btnTxt={payNowCTA.actionLabelText} />
                : <Text style={{ alignSelf: 'flex-end', fontWeight: '800', color: '#008CFF' }} onPress={closeModal}>{lblOkayGotIt}</Text>}
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
    </View>
  )
});

const InstallmentDetailsBottomSheet = ({ response, cardData, dismiss, pageName }) => {
  return (
    <CommonBottomOverlayMessage customStyle={AtomicCss.borderTopRadius16} dismiss={dismiss} content={
      <InstallmentDetails {...cardData} pageName={pageName}  response={response} closeModal={dismiss} />
    } />
  )
}


export default React.memo(InstallmentDetailsBottomSheet);


const styles = StyleSheet.create({
  cardContent: {
    padding: 20,
    paddingTop: 15,
  },
  overlayTopline: {
    width: 40,
    height: 4,
    borderRadius: 4,
    backgroundColor: '#c2c2c2',
    alignSelf: 'center',
    margin: 15,
  },
  scrollViewContent: {
    borderWidth: 1,
  },
  iconDueCalendar: {
    width: 32,
    height: 40,
    resizeMode: 'cover'
  },
  innerContentWrap: {
    flexDirection: 'row',
    paddingLeft: 15,
    marginTop: 40
  },
  innerContent: {
    marginLeft: -17,
  },
  dashedLines: {
    borderColor: '#f0d3a5',
    borderWidth:1,
    borderStyle: 'dashed',
    position: 'relative',
    left: 16,
    borderRadius: Platform.OS == 'android' ? 1 : 0,
  },
  duePaymentInfo: {
    padding: 10,
    backgroundColor: '#f8f8f8',
    flexWrap: 'wrap',
    flexDirection: 'row',
    marginTop: 10
  },
  paymentStepContent: {
    width: '87%',
    paddingBottom: 40
  },
  rollingIcon: {
    width: 13,
    height: 13
  },
  p: {
    color:'red'
  }
})