import React from 'react';
import { View } from 'react-native';
import get from 'lodash/get';
import ReasonRefundRqstCard from '../../../../components/ReasonRefundRqstCard';
import {
  getAllPossibleScActionLists
} from '../../../../helper';
import { getHeading } from './getHeading';
import { getDescription } from './getDescription';

export const SingleSegmentSinglePaxCom = React.memo(({ cancelRresponse, onItemClick }) => {
    const heading = get(cancelRresponse, 'cancellationValidation.segments[0]', '');
    const description = get(cancelRresponse, 'cancellationValidation.segments[0]', '');
  
    return (
      <View>
        {getHeading(heading, true)}
        {getDescription(description)}
        <ReasonRefundRqstCard
          claimReasonHeader=""
          componnetType="CTIP"
          specialClaimDataList={getAllPossibleScActionLists(
            cancelRresponse.cancellationValidation,
            'segment',
            cancelRresponse.flightDetails.segmentGroupDetailList[0],
          )}
          onItemClick={onItemClick}
        />
      </View>
    );
  });
  