import React, { useEffect, useState } from 'react';
import { isEmptyArray } from '../../../../../Common/commonUtil';
import useApi from '../../../../../Common/useApi';
import { FLIGHT_UNDO_WEBCHECKIN_URL } from '../../../../../utils/NetworkUtils';
import CommonOverlayMessage from '../../../../../Common/CommonOverlayMessage';
import ChangeTravelDate from './ChangeTravelDate';
import UndoWebCheckInOverlay from './UndoWebCheckInOverlay';
import ShimmerCard from './ShimmerCard';
import DateChangeTrackingHelper from '../../analytics/DateChangeTrackingHelper';
import { getUndoWebCheckInRequest } from './data/dataAdapter';

const UndoWebCheckInComp = ({
  undoWebcheckinInfo,
  handleBottomOverlay,
  reviewInfo,
  makePayment,
  odcPreviewResponse,
  odcSegmentGroup,
  pageName = 'datechangereviewUndoWCOverlay',
  isCorp = false,
}) => {
  const states = {
    UNDO_WEBCHECKIN: 'UNDO_WEBCHECKIN',
    UNDO_WC_IN_PROGRESS: 'UNDO_WC_IN_PROGRESS',
  };
  const [state, setState] = useState(states.UNDO_WEBCHECKIN);
  const [undoneInProgress, undoWCResponse, api] = useApi(FLIGHT_UNDO_WEBCHECKIN_URL);

  useEffect(() => {
    if (undoWCResponse && !isEmptyArray(undoWCResponse.flightsRaiseUndoWebCheckinResponseList)) {
      setState(states.UNDO_WC_IN_PROGRESS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [undoWCResponse]);

  const onUndoWebCheckin = (segs) => {
    DateChangeTrackingHelper.trackUndoWCClickEvent(pageName, 'undoWebcheckinCtaClk');
    const { bookingId } = reviewInfo || {};
    api.httpPost({
      bookingId,
      uniqueId: 'FLIGHT_UNDO_WEBCHECKIN',
      body: getUndoWebCheckInRequest(segs, reviewInfo, odcPreviewResponse),
    });
  };

  const onContinue = () => {
    DateChangeTrackingHelper.trackUndoWCClickEvent(pageName, 'continueDateChangeCtaClk');
    handleBottomOverlay && handleBottomOverlay();
    makePayment && makePayment();
  };

  const renderUI = () => {
    if (undoneInProgress) {
      return <ShimmerCard />;
    }
    switch (state) {
      case states.UNDO_WEBCHECKIN:
        return (
          <ChangeTravelDate
            undoWebcheckinInfo={undoWebcheckinInfo}
            onUndoWebCheckin={onUndoWebCheckin}
            odcPreviewResponse={odcPreviewResponse}
          />
        );
      case states.UNDO_WC_IN_PROGRESS:
        return (
          <UndoWebCheckInOverlay
            undoWebcheckinInfo={undoWebcheckinInfo}
            reviewInfo={reviewInfo}
            onContinue={onContinue}
            data={undoWCResponse}
            odcPreviewResponse={odcPreviewResponse}
            odcSegmentGroup={odcSegmentGroup}
            handleBottomOverlay={handleBottomOverlay}
            pageName={pageName}
            isCorp={isCorp}
          />
        );
      default:
        return null;
    }
  };

  return (
    <CommonOverlayMessage
      topLine
      isBorderRadius
      handleBottomOverlay={state === states.UNDO_WC_IN_PROGRESS ? null : handleBottomOverlay}
      content={renderUI()}
    />
  );
};

export default UndoWebCheckInComp;
