import React, { useCallback, useEffect } from 'react';
import { View, Platform, DeviceEventEmitter } from 'react-native';
import styles from './downloadInvoiceBottomSheetCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import ShimmerCard from './ShimmerCard';
import InvoiceList from './InvoiceList';
import useApi from '../../../../../Common/useApi';
import { DOWNLOAD_INVOICE_API } from '../../../../../utils/NetworkUtils';
import FlightBookingModule from '@mmt/legacy-commons/Native/FlightBookingModule';
import DownloadInvoiceError from './DownloadInvoiceError';
import useHardwareBackpress from '../../../../../Common/useHardwareBackpress';
import { LOBNAMES } from '../../../../../PostSalesConstant';

const DownloadInvoiceBottomSheet = ({ bookingId, closeOverlay }) => {
  const [invoiceInProgress, invoiceResponse, invoiceApi] = useApi(DOWNLOAD_INVOICE_API);

  useHardwareBackpress().addListener(closeOverlay, true);

  const apiCall = useCallback(() => {
    invoiceApi.httpPost({
      body: {
        bookingid: bookingId,
        userId: Platform.OS === 'web' ? 'PWA' : Platform.OS.toUpperCase(),
        lob: 'Air',
      },
      bookingId: bookingId,
      uniqueId: 'DOWNLOAD_INVOICE',
      psLob: LOBNAMES.FLIGHT,
    });
  }, [invoiceApi, bookingId]);

  useEffect(() => {
    apiCall();
  }, [apiCall]);
  return (
    <View style={styles.OverlayContentWrapper}>
      <View style={AtomicCss.marginBottom20}>
        <View style={styles.overlayTopline} />
      </View>
      {invoiceInProgress && <ShimmerCard rows={2} />}
      {!invoiceInProgress && invoiceResponse && !invoiceResponse.errorPageData && (
        <InvoiceList data={invoiceResponse} />
      )}
      {!invoiceInProgress && invoiceResponse && (invoiceResponse.error || invoiceResponse.errorPageData) && (
        <DownloadInvoiceError onRetry={apiCall} />
      )}
    </View>
  );
};

export default DownloadInvoiceBottomSheet;
