import { StyleSheet } from 'react-native';

export default (theme) =>StyleSheet.create({
  starStyle: {
    width: 11,
    height: 11,
    marginRight: 5,
  },
  activeStyle: {
    tintColor: theme.color.black,
  },
  disabledStyle: {
    tintColor: theme.color.gray7,
  },
  hotelImg: {
    width: 96,
    height: 96,
    borderRadius: 10,
  },
});
