import React, {useState, useEffect, useRef} from 'react';
import {StyleSheet, View, Animated, Dimensions} from 'react-native';

export default function ProgressBar(props) {
  // eslint-disable-next-line arrow-parens
  const {current: animation} = useRef(new Animated.Value(0));

  useEffect(() => {
    Animated.timing(animation, {
      toValue: props.toValue * ((Dimensions.get('screen').width - 100) / 100),
      duration: props.duration
    }).start();
  }, []);

  return (
    <View style={styles.container}>
      <Animated.View style={[styles.inner, {width: animation}]} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: 85,
    height: 4,
    justifyContent: 'center',
    backgroundColor: '#e7e7e7'
  },
  inner: {
    width: '100%',
    height: 4,
    backgroundColor: '#2276e3'
  }
});
