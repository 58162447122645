const CustomerSupportCardConst = {
    INLINE_CARD_HEADER: 'Customer Support',
    INLINE_CARD_DESC: 'Need help for your ongoing trip',
    CARD_CTA_TEXT: 'NEED HELP?',
    CHAT_WITH_US: 'CHAT_WITH_US',
    CALL_US_NOW: 'CALL_US_NOW',
    WIFI_CALL: 'WIFI_CALL',
    VOIP: 'VOIP'
};

export const RuleTraceConst = {
    INLINE: 'INLINE',
    FLOATING: 'FLOATING',
    BLOCK: 'BLOCK',
};

export const POPUP_TYPE = {
    POPUP_CTA_TYPE_DISCONNECT: 'DISCONNECT',
    POPUP_CTA_TYPE_CONTINUE: 'CONTINUE',
}
export const FLIGHT_BOOKING_DETAILS_AUDIO_PERMISSION = 'flight_booking_details_record_audio_and_modify_settings_permission';
export const HOTEL_BOOKING_DETAILS_AUDIO_PERMISSION = 'hotel_booking_details_record_audio_and_modify_settings_permission';

export default CustomerSupportCardConst;
