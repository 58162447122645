import React, { useEffect } from 'react';
import { LOBNAMES } from 'apps/post-sales/src/PostSalesConstant';
import { MIMA_BASE_URL, getCommonHeaders } from 'apps/post-sales/src/utils/NetworkUtils';
import FlightBookingModule from '@mmt/legacy-commons/Native/FlightBookingModule';
import { Platform, DeviceEventEmitter } from 'react-native';
import Actions from 'apps/post-sales/src/navigation/postSalesNavigation';
import { resetToFlightBookingDetail } from 'apps/post-sales/src/flights/FlightBookingUtil';
import { isEmpty } from 'lodash';
import { getStaticData } from 'apps/post-sales/src/staticData/staticData';
import FlightBookingTrackingHelper from '../../../helper/FlightBookingTrackingHelper';
import { PAYMENT_EVENT_LISTNER } from '../../../../AncillaryModule/constants';
import PartPayDetails from './PartPayDetails';
import { PartPaymentData } from './PartPayDetailsType';

interface PartPaymentCardProp {
  bookingId: string;
  response: PartPaymentData;
}

const PartPaymentCard: React.FC<PartPaymentCardProp> = ({ bookingId, response }) => {
  const onClickTracking = (eventName: string) => {
    FlightBookingTrackingHelper.trackingNewClickEvent('Details', `PartPayment_${eventName}`, {});
  };
  const onLoadTracking = () => {
    FlightBookingTrackingHelper.trackLoadEvent('Details', {}, 'PartPayment_card_shown');
  };

  const onErrorTracking = (error: any) => {
    FlightBookingTrackingHelper.trackErrorWithData('Details', bookingId, error, 'Payments');
  };

  useEffect(() => {
    return () => {
      if (Platform.OS !== 'web') {
        const count = DeviceEventEmitter.listenerCount(PAYMENT_EVENT_LISTNER);
        if (count > 0) {
          DeviceEventEmitter.removeAllListeners(PAYMENT_EVENT_LISTNER);
        }
      }
    };
  }, []);

  const { partPaymentText = {}, backToBookingText } = getStaticData();
  const { errorHeading, errorDesc } = partPaymentText;
  
  const renderPaymentFailed = () => {
    Actions.partPaymentThankYou({
      description: errorDesc,
      message: errorHeading,
      iconUrl: 'https://imgak.mmtcdn.com/mima/images/mobile/AncillaryBookingFailed_Icon.webp',
      bookingId: bookingId,
      buttonText: backToBookingText,
    });
  };

  const tracking = {
    onClickTracking,
    onLoadTracking,
  };
  const actionList = (actionId: string) => {
    if (actionId === 'backToBooking') {
      resetToFlightBookingDetail(bookingId);
    }
  };
  const paymentHandler = (holdResponse) => {
    try {
      const { paymentUrl } = holdResponse || {};
      if (Platform.OS === 'web') {
        window.location.href = paymentUrl;
      } else {
        DeviceEventEmitter.removeAllListeners(PAYMENT_EVENT_LISTNER);
        DeviceEventEmitter.addListener(PAYMENT_EVENT_LISTNER, (paymentRes) => {
          DeviceEventEmitter.removeAllListeners(PAYMENT_EVENT_LISTNER);
          if (!isEmpty(paymentRes?.PAYMENT_RESPONSE_VO)) {
            const paymentJson = JSON.parse(paymentRes.PAYMENT_RESPONSE_VO);
            if (!isEmpty(paymentJson.response)) {
              const { description, title, buttonText, isSuccess } =
                JSON.parse(paymentJson.response) || {};
              const imageUrl = isSuccess
                ? 'https://imgak.mmtcdn.com/mima/images/mobile/AncillaryAddOnsSuccessfull_Icon.webp'
                : 'https://imgak.mmtcdn.com/mima/images/mobile/AncillaryBookingFailed_Icon.webp';
              Actions.partPaymentThankYou({
                description: description,
                message: title,
                success: imageUrl,
                iconUrl: imageUrl,
                bookingId: bookingId,
                buttonText: buttonText,
              });
            } else {
              renderPaymentFailed();
            }
          } else {
            renderPaymentFailed();
          }
        });
        FlightBookingModule.openAncillaryPaymentPage(JSON.stringify(holdResponse));
      }
    } catch (error) {
      console.error('Error in paymentHandler:', error);
      onErrorTracking(error);
      actionList('backToBooking');
    }
  };
  
  const nativeInfo = {
    backendBaseUrl: MIMA_BASE_URL,
    getPartPaymentHeaders: async () => {
      const userInfo = await FlightBookingModule.getRequestHeader(bookingId, 'PartPayment');
      const headers = await getCommonHeaders(
        userInfo.authToken,
        userInfo?.loggingTrackingInfo || '',
        {
          psLob: LOBNAMES.FLIGHT,
        },
      );
      return headers;
    },
    staticText: {
      errorHeading,
      errorDesc,
    },
  };
  return (
    <PartPayDetails
      bookingId={bookingId}
      response={response}
      lobName={LOBNAMES.FLIGHT}
      tracking={tracking}
      nativeInfo={nativeInfo}
      paymentHandler={paymentHandler}
    />
  );
};
export default PartPaymentCard;