import React from 'react';
import { View, Text, TextInput } from 'react-native';
import styles from './AddCommentCardCss';
import { getStaticData } from '../../../staticData/staticData';
import InputField from '@Frontend_Ui_Lib_App/InputField';

const AddCommentCard = ({ setTextareaVal }) => {
  const { additionalCommentsText, commentsPlaceholderText } = getStaticData(false);

  return (
    <View style={styles.cardWrapper}>
      <Text style={styles.statusHeadText}>{additionalCommentsText}</Text>
      <InputField
          customStyle={{
            inputFieldStyle: [styles.textarea]
            }}
            placeholder={commentsPlaceholderText}
            onChangeText={setTextareaVal}
            inputProps={{
            multiline: true,
          }}
        />
    </View>
  );
};
export default AddCommentCard;
