import React from 'react';
import {View, Image, StyleSheet, Text, Dimensions} from 'react-native';
import {fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import BasePage from '../../Common/PostSalesBasePage';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {MailIcon} from '../HolidayBookingConstants';

const windW = Dimensions.get('screen').width;

class TopInfo extends BasePage {

  render() {
    return (
      <View style={{width: windW}}>
        <View style={[styles.infoWrapper, {backgroundColor: '#cf8100'}]}>
          <View style={[AtomicCss.flex1, AtomicCss.marginRight18]}>
            <Text style={styles.infoTxtStyle}>{this.props.info}</Text>
          </View>
          <View style={styles.iconWrapper}>
            <Image style={styles.mailIconStyle} source={MailIcon}/>
          </View>
        </View>
      </View>
    );
  }
}


const styles = StyleSheet.create({

  infoWrapper: {
    marginHorizontal: 10,
    marginTop: 5,
    marginBottom: 15,
    padding: 16,
    borderRadius: 4,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  iconWrapper: {
    width: 40,
    height: 40,
    backgroundColor: '#fff',
    borderRadius: 50,
    alignItems: 'center',
    justifyContent: 'center'

  },
  voucherIconStyle: {
    width: 24,
    height: 14
  },
  infoTxtStyle: {
    color: '#fff',
    fontSize: 14,
    lineHeight: 24,
    fontFamily: fonts.bold
  },
  mailIconStyle: {
    width: 22,
    height: 15
  },
  lockIconStyle: {
    width: 17,
    height: 21
  }
});

export default TopInfo;
