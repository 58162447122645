import React, { useEffect } from 'react';
import { View, Text, Image } from 'react-native';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import isEmpty from 'lodash/isEmpty';
import CardWrapper from '../CardWrapper';
import ButtonWpr from '../../../../components/ButtonWpr';
import useApi from 'apps/post-sales/src/Common/useApi';
import { CAB_CONFIRM_LANDING_URL } from 'apps/post-sales/src/utils/NetworkUtils';
import { LOBNAMES } from 'apps/post-sales/src/PostSalesConstant';
import getStyles from './styles';
import CabBookingTrackingHelper from 'apps/post-sales/src/cab/CabBookingTrackingHelper';
import CabBookingDetailsConstant, {
  CLICK_EVENT,
} from 'apps/post-sales/src/cab/CabBookingDetailsConstant';
import { showShortToast } from 'packages/legacy-commons/Common/Components/Toast';
interface ConfirmYourLandingProps {
  pageName: string;
  cardData: {
    bookingId: string;
    confirmYourLandingData: {
      heading: string;
      subHeading: string;
      desc: string;
      imageUrl: string;
      actionList: {
        actionLabelText: string;
        actionType: string;
      }[];
    };
  };
  fetchDetailInProgress: boolean;
  reloadPage: () => void;
}

const ConfirmYourLanding = ({
  cardData,
  pageName,
  reloadPage,
  fetchDetailInProgress,
}: ConfirmYourLandingProps) => {
  const { fsStyle, ...fonts } = getFont();
  const { psTheme: theme } = useTheme();
  const styles = getStyles(fonts, theme);
  const [inProgress, response, api] = useApi(CAB_CONFIRM_LANDING_URL);

  useEffect(() => {
    if (!isEmpty(response)) {
      if (response.confirmLanding) {
        typeof reloadPage === 'function' && reloadPage();
      } else if (response.errorMsg) {
        showShortToast(response.errorMsg);
      } else {
        showShortToast(CabBookingDetailsConstant.DEFAULT_ERROR_MESSAGE);
      }
    }
  }, [response]);

  useEffect(() => {
    CabBookingTrackingHelper.trackClickEvent(pageName, CLICK_EVENT.CONFIRM_YOUR_LANDING_CARD_SHOWN);
  }, []);

  const { confirmYourLandingData, bookingId } = cardData || {};
  if(isEmpty(confirmYourLandingData)) return null;
  const { desc, imageUrl, actionList } = confirmYourLandingData || {};

  const handleConfirmClick = () => {
    api.httpPost({
      body: {
        bookingId: bookingId,
      },
      bookingId: bookingId,
      uniqueId: 'LANDING_CONFIRM_CLICK',
      psLob: LOBNAMES.CAR,
    });
  };

  return (
    <View style={styles.card}>
      <View style={[AtomicCss.flexRow, AtomicCss.marginBottom20]}>
        {!!imageUrl && <Image source={{ uri: imageUrl }} style={styles.landingIcon} />}
        {!!desc && <Text style={[styles.description, AtomicCss.flex1]}>{desc}</Text>}
      </View>
      {!isEmpty(actionList) &&
        actionList.map((action) => {
          const { actionLabelText, actionType } = action || {};
          return (
            <ButtonWpr
              buttonText={actionLabelText}
              onButtonPress={handleConfirmClick}
              showBtnLoader={inProgress || fetchDetailInProgress}
              buttonType={actionType}
              btnStyle={styles.btnStyle}
              btnTxtStyle={styles.btnTxtStyle}
              loaderSize={16}
            />
          );
        })}
    </View>
  );
};

export default CardWrapper(ConfirmYourLanding);
