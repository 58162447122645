import React from 'react';
import {View, Text, Image, TouchableOpacity, StyleSheet, FlatList} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import PropTypes from 'prop-types';

import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getPlatformElevation from '@mmt/legacy-commons/Common/Components/Card/getPlatformElevation';
import {formatDate, openWebView, trackrRedirectToMsr} from './Utils';
import { themeColors } from '../../PostSalesStyles';
import { EscalateMessage, FeedbackCard } from './MSRComponents';

function MultipleRequestTicketCard(props) {
  const {ticket, index, pageName} = props;
  if (!ticket) {
    return null;
  }
  if (!Object.keys(ticket).length) {
    return null;
  }

  let {ticketTagClr, actionBtnText} = ticket;
  if (!ticketTagClr) {
    ticketTagClr = 'grey';
  }
  if (!actionBtnText) {
    actionBtnText = 'View Details';
  }

  return (
    <View
      style={props.index === 0 ? [styles.msrEntryCard, styles.marginLeft16] : styles.msrEntryCard}
    >
      <View style={[styles.ticketTag]} />
      <View style={AtomicCss.flexRow}>
        <View style={[AtomicCss.flex1]}>
          <View style={styles.msrEntryCardTopSec}>
            <View style={AtomicCss.marginBottom5}>
              <Text style={[AtomicCss.blackText, AtomicCss.blackFont, AtomicCss.font16]}>
                {ticket.secondaryType}
              </Text>
            </View>
            <View style={AtomicCss.marginBottom8}>
              <Text style={[AtomicCss.defaultText, AtomicCss.font12]}>
                Created {formatDate(ticket.createdAt)}
              </Text>
            </View>
          </View>
          <View style={[AtomicCss.marginBottom12, AtomicCss.paddingRight25]}>
            <Text style={[AtomicCss.font11, AtomicCss.midGreyText, AtomicCss.lineHeight18]}>
              <Text
                style={[
                  ticketTagColourStatusTextColourMap[ticketTagClr],
                  AtomicCss.font12,
                  AtomicCss.boldFont
                ]}
              >
                {ticket.statusText}
              </Text>{' '}
              (Updated {formatDate(ticket.updatedAt)})
            </Text>
            {ticket.expectedClosureDate && (
              <Text
                style={[
                  AtomicCss.marginTop5,
                  AtomicCss.font12,
                  AtomicCss.midGreyText,
                  AtomicCss.lineHeight18
                ]}
              >
                Expected resolution by
                <Text style={[styles.expectedClosureDate, AtomicCss.font12, AtomicCss.boldFont]}>
                  {` `}
                  {formatDate(ticket.expectedClosureDate)}
                </Text>
              </Text>
            )}
          </View>
          <TouchableOpacity
            style={[AtomicCss.flexRow, AtomicCss.alignCenter, {marginBottom: 4}]}
            onPress={() => {
              trackrRedirectToMsr(ticket, index, pageName);
              openWebView(ticket.msrUrl);
            }}
          >
            <Text
              style={[
                {color: themeColors.linkTextColor},
                AtomicCss.boldFont,
                AtomicCss.font12,
                AtomicCss.marginRight5,
                AtomicCss.lineHeight12
              ]}
            >
              {actionBtnText}
            </Text>
            <Image
              style={styles.msrEntryCardArrow}
              source={require('@mmt/legacy-assets/src/blueRightArrow.webp')}
            />
          </TouchableOpacity>
          {ticket.escalationDetails && ticket.escalationDetails.showEscalate &&
            <EscalateMessage 
              escalationDetails={ticket.escalationDetails}
              pageName={pageName}
              ticket={ticket}
            />
          }
          {ticket.feedbackDetails && ticket.feedbackDetails.showFeedbackOption &&
            <FeedbackCard 
              feedbackDetails={ticket.feedbackDetails}
              pageName={pageName}
              ticket={ticket}
            />
          }
        </View>
      </View>
    </View>
  );
}

MultipleRequestTicketCard.defaultProps = {
  ticket: null,
  index: -1
};

MultipleRequestTicketCard.propTypes = {
  ticket: PropTypes.shape({
    iconUrl: PropTypes.string.isRequired,
    secondaryType: PropTypes.string.isRequired,
    createdAt: PropTypes.number.isRequired,
    updatedAt: PropTypes.number.isRequired,
    expectedClosureDate: PropTypes.number,
    assignee: PropTypes.string,
    msrUrl: PropTypes.string.isRequired,
    actionBtnText: PropTypes.string.isRequired
  }),
  index: PropTypes.number
};

export default function MultipleRequestsTicketCards(props) {
  const {pageName,customStyle} = props;
  const keyExtractor = (ticket) => ticket.id;
  const renderItem = ({item: ticket, index}) => (
    <MultipleRequestTicketCard ticket={ticket} index={index} pageName={pageName}/>
  );

  return (
    <LinearGradient
      start={{x: 1.0, y: 0.0}}
      end={{x: 0.0, y: 1.0}}
      colors={['#e7f0ff', '#ffffff']}
      style={[styles.msrEntryCardOuter, AtomicCss.marginBottom10, customStyle ? customStyle:{}]}
    >
      <View style={[AtomicCss.marginBottom20, styles.marginLeft16]}>
        <Text style={[AtomicCss.defaultText, AtomicCss.blackFont, AtomicCss.font12]}>
          YOU CONTACTED US FOR
        </Text>
      </View>
      <FlatList
        data={props.ticketsList}
        horizontal
        keyExtractor={keyExtractor}
        renderItem={renderItem}
        showsHorizontalScrollIndicator={false}
      />
    </LinearGradient>
  );
}

MultipleRequestsTicketCards.defaultProps = {
  ticketsList: []
};

MultipleRequestsTicketCards.propTypes = {
  ticketsList: PropTypes.arrayOf(
    PropTypes.shape({
      iconUrl: PropTypes.string.isRequired,
      secondaryType: PropTypes.string.isRequired,
      createdAt: PropTypes.number.isRequired,
      updatedAt: PropTypes.number.isRequired,
      expectedClosureDate: PropTypes.number,
      assignee: PropTypes.string,
      msrUrl: PropTypes.string,
      actionBtnText: PropTypes.string.isRequired
    })
  )
};

const styles = StyleSheet.create({
  msrEntryCardOuter: {
    paddingVertical: 16
  },

  msrEntryCard: {
    backgroundColor: '#fff',
    paddingVertical: 12,
    paddingHorizontal: 12,
    ...getPlatformElevation(3),
    borderRadius: 4,
    width: 260,
    marginRight: 10,
    marginBottom: 5,
    marginTop: 2,
    marginLeft: 5
  },

  msrEntryCardTopSec: {
    borderBottomWidth: 1,
    borderBottomColor: '#e7e7e7',
    marginBottom: 8
  },
  msrEntryCardArrow: {
    width: 6,
    height: 10,
    tintColor: themeColors.rightArrowTintColor,
  },
  msrEntryCardIcon: {
    width: 20,
    height: 20
  },
  greenTxt: {color: '#1a7971'},
  yellowText: {color: '#cc7f01'},

  ticketTag: {
    position: 'absolute',
    width: 1,
    height: 18,
    borderColor: '#747474',
    borderWidth: 1.5,
    backgroundColor: '#747474',
    left: -0.5,
    top: 12
  },

  marginLeft16: {
    marginLeft: 16
  },

  expectedClosureDate: {
    color: '#000'
  }
});

const ticketTagColourStatusTextColourMap = {
  grey: {color: '#747474'},
  yellow: styles.yellowText,
  red: AtomicCss.errorRedText,
  green: styles.greenTxt
};
