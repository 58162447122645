import React from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import styles from './TNCCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { TNC_ICON, RIGHT_ARROW } from '../../../../../FlightImageConstants';
import { getFont } from '../../../../../../PostSalesStyles';

const TermAndConditionCard = ({
  handleBottomSheet,
  title,
  termsAndConditionsData,
  viewMoreText,
  viewMoreLink,
}) => {
  const maxPoints = 2;
  const { fsStyle, ...fonts } = getFont(true);
  return (
    <View style={[AtomicCss.marginBottom10, styles.cardBorder]}>
      <View style={[styles.infoCardWrapper]}>
        <View style={styles.headingIconWrapper}>
          <Image style={styles.headingIconStyle} source={TNC_ICON} />
        </View>
        <View style={[styles.infoCardContent, AtomicCss.marginRight10]}>
          <Text
            style={[
              AtomicCss.defaultText,
              fonts.blackFontFamily,
              fsStyle.font14,
              AtomicCss.marginBottom15,
            ]}
          >
            {title}
          </Text>
          {termsAndConditionsData &&
            termsAndConditionsData.length &&
            termsAndConditionsData.map((item, index) => {
              if (index >= maxPoints) {
                return null;
              }
              return (
                <View style={[AtomicCss.flexRow, AtomicCss.marginBottom10]} key={index}>
                  <View style={styles.bullet} />
                  <Text
                    style={[
                      AtomicCss.defaultText,
                      fsStyle.font14,
                      AtomicCss.flex1,
                      fonts.regularFontFamily,
                    ]}
                  >
                    {item}
                  </Text>
                </View>
              );
            })}
        </View>
      </View>
      {(termsAndConditionsData.length > maxPoints || !!viewMoreLink) && (
        <TouchableOpacity
          style={styles.redirectBtnWrapper}
          activeOpacity={0.8}
          onPress={() => handleBottomSheet('TNCFullPage', viewMoreLink, title)}
        >
          <Text style={[styles.linkBtnTxt, fonts.boldFontFamily, fsStyle.font14]}>
            {viewMoreText}
          </Text>
          <Image style={styles.rightArrowIcon} source={RIGHT_ARROW} />
        </TouchableOpacity>
      )}
    </View>
  );
};
export default TermAndConditionCard;
