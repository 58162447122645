import React from "react";
import {appendUrlPrefix} from "../../webUtils";
import loadable from "@loadable/component";
import ProgressView from "../../components/ProgressView";

const RAIL_PATHS = {
    RAIL_BOOKING_DETAIL: {
        pathName: appendUrlPrefix('/rail'),
        pageKey: "railBookingDetail",
        Component: loadable(() => import("@rn/apps/post-sales/src/Rails/details/RailBookingDetails"), { fallback: <ProgressView /> })
    },
    RAIL_PRICE_BREAKUP : {
        pathName: appendUrlPrefix('/railPriceBreakUp'),
        pageKey: "railPriceBreakUp",
        Component: loadable(() => import("@rn/apps/post-sales/src/Rails/RailPriceBreakUp/PaymentPriceBreakup"), { fallback: <ProgressView /> })
    },
    RAIL_TDR_SELECT_TRAVELLER : {
        pathName: appendUrlPrefix('/tdrSelectPassengers'),
        pageKey: "tdrSelectPassengers",
        Component: loadable(() => import("@rn/apps/post-sales/src/Rails/Tdr/TdrSelectTraveller/TdrSelectTraveller"), { fallback: <ProgressView /> })
    },
    RAIL_TDR_FORM : {
        pathName: appendUrlPrefix('/tdrForm'),
        pageKey: "tdrForm",
        Component: loadable(() => import("@rn/apps/post-sales/src/Rails/Tdr/TdrForm/TdrForm"), { fallback: <ProgressView /> })
    },
    RAIL_TDR_FILE : {
        pathName: appendUrlPrefix('/railFileTdr'),
        pageKey: "railFileTdr",
        Component: loadable(() => import("@rn/apps/post-sales/src/Rails/Tdr/FileTdrOption/FileTdrOption"), { fallback: <ProgressView /> })
    },
    RAIL_TDR_THANK_YOU : {
        pathName: appendUrlPrefix('/tdrThankYou'),
        pageKey: "tdrThankYou",
        Component: loadable(() => import("@rn/apps/post-sales/src/Rails/Tdr/TdrFiledDetails/TdrFiledThanks"), { fallback: <ProgressView /> })
    },
    RAIL_SELECT_TRAVELLER : {
        pathName: appendUrlPrefix('/railCancellationSelectPax'),
        pageKey: "railCancellationSelectPax",
        Component: loadable(() => import("@rn/apps/post-sales/src/Rails/cancellation/SelectTraveller"), { fallback: <ProgressView /> })
    },
    RAIL_REVIEW_REFUND : {
        pathName: appendUrlPrefix('/reviewRefundCharges'),
        pageKey: "reviewRefundCharges",
        Component: loadable(() => import("@rn/apps/post-sales/src/Rails/cancellation/ReviewRefundCharges"), { fallback: <ProgressView /> })
    },
    RAIL_REFUND_MODE : {
        pathName: appendUrlPrefix('/reviewRailRefundMode'),
        pageKey: "reviewRailRefundMode",
        Component: loadable(() => import("@rn/apps/post-sales/src/Rails/cancellation/RefundMode/RailRefundMode"), { fallback: <ProgressView /> })
    },
    RAIL_CANCEL_CONFIRM : {
        pathName: appendUrlPrefix('/railBookingCancelled'),
        pageKey: "railBookingCancelled",
        Component: loadable(() => import("@rn/apps/post-sales/src/Rails/ErrorState/BookingCancel"), { fallback: <ProgressView /> })
    },
    RAIL_TDR_PROCEDURE : {
        pathName: appendUrlPrefix('/railTdrProcedure'),
        pageKey: "railTdrProcedure",
        Component: loadable(() => import("@rn/apps/post-sales/src/Rails/components/AccordianTDR/TDRProcedure"), { fallback: <ProgressView /> })
    },
    RAIL_REFUND_BREAKUP_PAGE : {
        pathName: appendUrlPrefix('/railRefundBreakupPage'),
        pageKey: "railRefundBreakupPage",
        Component: loadable(() => import("@rn/apps/post-sales/src/Common/RefundBreakupPage"), { fallback: <ProgressView /> })
    },
    RAIL_REFUND_BREAKUP_PAGE_V2 : {
        pathName: appendUrlPrefix('/railRefundBreakupV2'),
        pageKey: "railRefundBreakupV2",
        Component: loadable(() => import("@rn/apps/post-sales/src/Common/RefundBreakUpPageV2"), { fallback: <ProgressView /> })
    }
    
}

export default RAIL_PATHS;


