import React from 'react';
import {View, Text, TouchableOpacity, Image} from 'react-native';
import styles from './RailQrCodeOverlayStyle';
import {isEmpty} from 'lodash';
import { getStaticData } from '../../../staticData/staticData';
import { getFont } from '../../../PostSalesStyles';

export default class RailQrCodeOverlay extends React.Component {
  render() {
    const { fsStyle, ...fonts } = getFont(true);
    const staticData = getStaticData();
    const { bookingIdCapsText } = staticData;
    const jurneyInfo = this.props.response.train[0].pnrDetails[0].segmentInfo[0].boardingFromToMsg;
    const qrcode = this.props.response.qrCodeByte64Format;
    return (
      <View style={styles.pageWrapper}>
        <TouchableOpacity onPress={() => this.props.closePopup()}>
          <View style={[styles.topHeading, styles.IOSSpacing]}>
            <Text style={[styles.overlayTopHeadding, fonts.boldFontFamily, fsStyle.font20]}>
                        {jurneyInfo}
            </Text>
          </View>
          <View>
            <View style={[styles.centerContainer, styles.appendBottom4]}>
              <View style={styles.overlayCouponCode}><Text style={[styles.overlayCouponText, fonts.blackFontFamily, fsStyle.font32]}>{this.props.response.train[0].pnrDetails[0].pnrNo}</Text></View>
            </View>
            {
                (!isEmpty(this.props.response.qrCodeByte64Format)) &&
                <View style={[styles.centerContainer, styles.appendBottom24]}>
                  <Image style={styles.mapIcon} source={{uri: this.props.response.qrCodeByte64Format}} />
                </View>
            }
            <View style={[styles.centerContainer, styles.appendBottom44, {flexDirection: 'column'}]}>
              <Text style={[styles.bookingId, fonts.regularFontFamily, fsStyle.font14]}>{ bookingIdCapsText }</Text>
              <Text style={[styles.bookingIdNo, fonts.blackFontFamily, fsStyle.font14]}>{this.props.response.bookingID}</Text>
            </View>
          </View>
        </TouchableOpacity>
      </View>
    );
  }
}
