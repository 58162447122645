import { StyleSheet } from "react-native";
import { DEFAULT_THEME } from "apps/post-sales/src/theme/default.theme";


export default <T extends typeof DEFAULT_THEME>(theme: T) => StyleSheet.create({
    scrollWrapper: {
        padding: 16,
    },
    topSliderCard: {
        backgroundColor: theme.color.grey22,
        padding: 16,
        borderBottomStartRadius: 16,
        borderBottomEndRadius: 16,
        margin: -16
    },
});
