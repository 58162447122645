import React from 'react';
import {View, Text, Image, TouchableOpacity} from 'react-native';
import PropTypes from 'prop-types';
import styles from './BottomOverlayDtlsCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getStaticData } from '../../../staticData/staticData';

const chatIcon = require('@mmt/legacy-assets/src/InstantRefund/chatIcon.webp');
const successIcon = require('@mmt/legacy-assets/src/InstantRefund/successIcon.webp');
const rupeeIcon = require('@mmt/legacy-assets/src/InstantRefund/ruppeIcon.webp');

const staticData = getStaticData();

export default class BottomOverlayDtls extends React.Component {
  render() {
    const {
      heading,
      step1,
      step2,
      step3,
      leftButtonText,
      rightButtonText
    } = this.props
    return (
      <View>
        <Text style={styles.contentTitle}>{heading}</Text>
        <View style={AtomicCss.flexRow}>
          <View style={styles.ImagePlaceholder}>
            <Image style={[styles.chatIconStyle]} source={chatIcon} />
          </View>
          <Text style={styles.contentDesc}>{step1}</Text>
        </View>
        <View style={AtomicCss.flexRow}>
          <View style={styles.ImagePlaceholder}>
            <Image style={[styles.successIconStyle]} source={successIcon} />
          </View>
          <Text style={styles.contentDesc}>{step2}</Text>
        </View>
        <View style={AtomicCss.flexRow}>
          <View style={styles.ImagePlaceholder}>
            <Image style={[styles.ruppeeIconStyle]} source={rupeeIcon} />
          </View>
          <Text style={styles.contentDesc}>{step3}</Text>
        </View>
        <View style={styles.contentFooter}>
          <TouchableOpacity style={styles.actionBtn} onPress={() => this.props.handleBottomOverlay(' ')}>
            <Text style={styles.actionBtnText}>{leftButtonText}</Text>
          </TouchableOpacity>
          <TouchableOpacity style={styles.actionBtn} onPress={() => this.props.handleProceedButton()}>
            <Text style={[styles.actionBtnText, AtomicCss.boldFont]}>{rightButtonText}</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }
}

BottomOverlayDtls.propTypes = {
  handleBottomOverlay: PropTypes.func.isRequired,
  handleProceedButton: PropTypes.func.isRequired,
  heading:PropTypes.string,
  step1:PropTypes.string,
  step2:PropTypes.string,
  step3:PropTypes.string,
  leftButtonText:PropTypes.string,
  rightButtonText:PropTypes.string
};

BottomOverlayDtls.defaultProps = {
  heading: staticData.linkAccountText.headingText,
  step1: staticData.linkAccountText.additionalText.step1Text,
  step2: staticData.linkAccountText.additionalText.step2Text,
  step3: staticData.linkAccountText.additionalText.step3Text,
  leftButtonText: staticData.notNowText,
  rightButtonText: staticData.proceedText
};