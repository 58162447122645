import React from 'react';
import {View, Text, TouchableOpacity, Image} from 'react-native';
import PropTypes from 'prop-types';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {styles} from './CabAmendmentStyles';
import {backArrow, rightArrow} from '../../CabImageConstants';

const Header = (props) => {
  const {
    title, handleBack, fromCity, toCity
  } = props;
  return (
    <View style={styles.header}>
      <View style={[AtomicCss.flex1, AtomicCss.flexRow, AtomicCss.alignCenter]}>
        <TouchableOpacity onPress={handleBack} style={styles.backWrapper} >
          <Image style={styles.iconBack} source={backArrow} />
        </TouchableOpacity>
        <View style={[AtomicCss.flex1]}>
          <Text style={[styles.title, styles.marginBottom3]}>{title}</Text>
          <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
            <Text style={[AtomicCss.font12, AtomicCss.defaultText, AtomicCss.regularFont, AtomicCss.paddingTop3]}>{fromCity}</Text>
            <Image style={styles.rightIcon} source={rightArrow} />
            <Text style={[AtomicCss.font12, AtomicCss.defaultText, AtomicCss.regularFont, AtomicCss.paddingTop3]}>{toCity}</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  handleBack: PropTypes.func.isRequired,
  fromCity: PropTypes.string.isRequired,
  toCity: PropTypes.string.isRequired
};

export default Header;
