import {colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles';

const styles = {
    contentWrpper:{
        paddingVertical:20,
    },
    failedImgStyle:{
        width:60,
        height:35,
        marginRight:10,
    },
    title:{
        fontFamily:fonts.regular,
        color:colors.black,
        fontSize:20,
    },
};
export default styles;