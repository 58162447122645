import React, {useCallback} from 'react';
import {View, Text, TouchableOpacity} from 'react-native';
import RadioButton from '../RadioButton';
import styles from './RadioSectionCss';

const RadioSection = React.memo(({
  data, activeId, setActiveId, identifier = 'id'
}) => {
  const onRadioSelection = useCallback(
    (id) => {
      setActiveId(id);
    },
    [setActiveId]
  );

  return (
    <View>
      {data.length > 0 &&
          data.map((item, index) => {
            const isSelected = activeId
              ? activeId === item[identifier]
              : index === 0;
            const selectedStyle = isSelected ? 'Selected' : '';
            return (
              <TouchableOpacity
                style={[styles.radioItem]}
                onPress={() => onRadioSelection(item.scCode)}
                activeOpacity={0.8}
                key={item.scCode}
              >
                <RadioButton
                  selected={isSelected}
                  onChange={() => onRadioSelection(item.scCode)}
                  radioOuterStyle={styles.radio}
                  radioInnerStyle={styles.radioSelectedCircle}
                />
                <View style={[styles.infoDtls]}>
                  <Text style={[styles.infoDtlsTxt]}>{item.scName}</Text>
                </View>
              </TouchableOpacity>
            );
          })}
    </View>
  );
});

export default RadioSection;
