// @flow
import React from 'react';
import { getFlightsSummaryData } from './flightsCardAdapter';
import {
  ActionsContent,
  FlightInfoText,
  DescriptionSection,
  JourneyText,
  MainContent,
  NewStatusText,
  RightArrow,
  SummaryContainer,
  StaticIcon,
  TitleText,
  FareLockIcon,
  NeedHelpStrip,
  PaymentInfoCard,
} from '../cardElements';
import MessageStrip from '../../messageStrip';
import { isNullOrEmpty } from '../../../../Common/commonUtil';
import { Platform, Image } from 'react-native';
import FlightBookingTrackingHelper from '../../../../flights/modules/details/helper/FlightBookingTrackingHelper';
import PendingPaymentTag from 'apps/post-sales/src/Common/PendingPaymentTag/PendingPaymentTag';
import PendingPaymentMessage from 'apps/post-sales/src/Common/PendingPaymentMessage/PendingPaymentMessage';

function FlightSummaryCard({ response, msrTicket }) {
  const cardData = getFlightsSummaryData(response, msrTicket);
  const { bkgSchdMsg, bkgSchdMsgColor, failedBooking, needHelpData, paymentInfo, holdBookingState, isHoldBooking  } = cardData;
  if (!isNullOrEmpty(holdBookingState)) {
    const trackingKey = {
      pending: 'hold_myt_payment_pending_card',
      expire: 'hold_myt_booking_expired_card',
      cancelled: 'hold_myt_booking_cancelled_card',
    };
    FlightBookingTrackingHelper.trackLoadEvent('Details', {}, trackingKey[holdBookingState]);
  }
  return (
    <SummaryContainer bookingId={cardData.bookingID}>
      <MainContent
        onPress={() => {
          cardData.openDetails();
        }}
        lob="Flights"
      >
        {cardData.fareLockBooking ? (
          <FareLockIcon bookingStatus={cardData.status} />
        ) : (
          <StaticIcon icon={cardData.iconUrl} iconStyle={{ height: 18, width: 30 }} />
        )}
        <DescriptionSection>
          {response && response.inCfarWindow && <Image style={{width:121,height:12,marginBottom:2}} source={{uri:response.cfarImage}}/>}
          {!cardData.journeyTypeText && <TitleText text={cardData.bookingID} />}
          {cardData.journeyType === 'M' &&
            cardData.intentDetails &&
            cardData.intentDetails.length > 0 &&
            cardData.intentDetails.map((intent) => (
              <JourneyText numberOfLines={1} from={intent.originCity} to={intent.destinationCity} />
            ))}
          {(cardData.journeyType === 'R' || cardData.journeyType === 'O') && (
            <JourneyText
              numberOfLines={1}
              from={cardData.from}
              to={cardData.to}
              showRoundTripArrowIcon={cardData.journeyType === 'R'}
            />
          )}
          {!!isHoldBooking && !isNullOrEmpty(paymentInfo) && !isNullOrEmpty(holdBookingState) && (
            <PaymentInfoCard status={holdBookingState} {...paymentInfo} />
          )}
          {!failedBooking && cardData.journeyTypeText && (
            <FlightInfoText text={cardData.journeyTypeText} />
          )}
          { Platform.OS === "web" && cardData.status === "PENDING"  &&
            <>
            <PendingPaymentTag tagTextStart={cardData.tagStartText} tagTextEnd={cardData.tagEndText} paymentChargeDate={cardData.pendingPaymentChargeDate} />
            <PendingPaymentMessage subTitle={cardData.paymentDueText} />
          </>
          }
          <NewStatusText status={cardData.status} text={cardData.dateTimeWithPaxInfo} />
          {/*<PaxInfo text={cardData.paxInfo} />*/}
          {!failedBooking && !isNullOrEmpty(bkgSchdMsg) && (
            <MessageStrip text={bkgSchdMsg} theme={bkgSchdMsgColor} />
          )}
        </DescriptionSection>
        <RightArrow />
      </MainContent>
      {!isNullOrEmpty(needHelpData) &&
        needHelpData.map((needHelp) => <NeedHelpStrip {...needHelp} />)}
      <ActionsContent actions={cardData.actions} />
    </SummaryContainer>
  );
}

export default FlightSummaryCard;
