import React from 'react';
import {
  View,
  Text,
  Image,
  ScrollView,
  StyleSheet
} from 'react-native';
import Actions from '../../navigation/postSalesNavigation';
import RedirectBtn from './RedirectBtn';
import TopMessage from './TopMessage';

import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { fonts, colors } from '@mmt/legacy-commons/Styles/globalStyles';
import genericHotelDefaultImage from '@mmt/legacy-assets/src/no_dest_default.webp';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import {MONTHS} from '../HolidayBookingConstants';
import { initCap, removeTags } from '../HolidayBookingDetailUtils';
import {
  screenWidth,
  HotelIcon,
  StarFilled,
  StarEmpty,
  VIEW_HOTEL_DETAILS_TEXT,
  SIMILAR_HOTELS_LIKE_TEXT,
  SIMILAR_TEXT
} from '../HolidayBookingConstants';
import { isNotNullAndEmpty } from '../../hotel/details/utils/HotelBookingDetailUtil';
import HolidayBookingTrackingHelper from '../HolidayBookingTrackingHelper';

const HotelDetailTab = (props) => {
  const { hotelData, topMessage, bookingId, pageName, isBundlePackage } = props;

  const getDate = (date) => {
    const date1  = new Date(date);
    return `${date1.getDate()} ${MONTHS[date1.getMonth()]}, `
  }

  const viewDetail = async ({ vendorNo, serviceVoucherNo, index }) => {
    const userInfo = await HotelBookingModule.getRequestHeader(
      bookingId,
      'HOTEL_CHILD_BOOKING_INFO',
    );
    HolidayBookingTrackingHelper.trackClickEvent(pageName, 'View_Hotel_Details', 'componentList_Hotels');

    HolidayBookingTrackingHelper.trackViewEvent(
      pageName + '_Hotels',
      'Hotel Booking Details',
    );
    const prePageName = HolidayBookingTrackingHelper.getPrePageName();
    Actions.hotelBookingDetail({
      BOOKING_ID: bookingId,
      holidayPageName: prePageName + pageName + '_Hotels',
      holidayBooking: true,
      vendorNo,
      serviceVoucherNo,
      stickyHeader: false,
      MMT_AUTH: userInfo.mmtAuth,
      type: 'push',
      holidayIndex: index,
    });
  }

  if (!hotelData || hotelData.length === 0) {
    return (
      <View style={{
        width: screenWidth,
        alignItems: 'center'
      }}
      >
        <Text
          style={[
            AtomicCss.blackFont,
            AtomicCss.font16,
            AtomicCss.blackText
          ]}
        >
          Sorry! No Hotel Found
        </Text>
      </View>
    );
  }

  const getSimilarHotelText = (similarHotels) => {
    if (isNotNullAndEmpty(similarHotels)) {
      let similarHotelsText = similarHotels[0].name;
      for (let i = 1; i < similarHotels.length; i++) {
        similarHotelsText += `/ ${similarHotels[i].name}`;
      }
      return <Text style={AtomicCss.font12}>{SIMILAR_HOTELS_LIKE_TEXT} {similarHotelsText}</Text>
    } else {
      return <Text style={AtomicCss.font12}>({SIMILAR_TEXT})</Text>
    }
  }

  return (
    <ScrollView style={{width: screenWidth}}>
      {topMessage && (
        <TopMessage img="voucherIcon" info={topMessage} bgcolor="#cf8100"/>
      )}
      <React.Fragment>
        {hotelData.map((item, index) => {
          const stars = [];
          for (let i = 0; i < 5; i++) {
            if (i < item.starRating) {
              stars.push(<Image style={styles.starIcon} source={StarFilled}/>);
            } else {
              stars.push(<Image style={styles.starIcon} source={StarEmpty}/>);
            }
          }

          const checkInDateTime = getDate(item.checkInDateTime);
          const checkOutDateTime = getDate(item.checkOutDateTime);
          const isUnconfirmedFDPackageHotel = (isBundlePackage && item.bookingStatus.toLowerCase() === "confirmed") ? true : false;

          return (
            <View
              style={[styles.cardWrapper, AtomicCss.marginBottom10]}
              key={`${item.hotelId}${item.checkInDateTime}`}
            >
              <View style={[styles.hotelList, AtomicCss.flexRow]}>
                <View>
                  <Image style={styles.hotelIcon} source={HotelIcon}/>
                </View>
                <View style={styles.hotelListRight}>
                  <View style={styles.hotelTopInfo}>
                    <View style={[AtomicCss.flex1, AtomicCss.marginRight10]}>
                      <Text
                        style={[
                          AtomicCss.blackFont,
                          AtomicCss.defaultTextColor,
                          AtomicCss.font14,
                          AtomicCss.marginBottom8
                        ]}
                      >
                        {initCap(item.cityName)} ({item.durationOfStay}N)
                      </Text>
                      <Text
                        style={[
                          AtomicCss.blackText,
                          AtomicCss.blackFont,
                          AtomicCss.font16,
                          AtomicCss.marginBottom10
                        ]}
                      >
                        {removeTags(item.hotelName)}
                      </Text>
                      <View style={AtomicCss.flexRow}>{stars}</View>
                      {
                        isUnconfirmedFDPackageHotel ?
                          <View style={AtomicCss.marginTop10}>
                            {getSimilarHotelText(item.similarHotels)}
                          </View> : null
                      }
                    </View>
                    <View>
                      <Image style={styles.hotelImage} source={{uri: item.hotelImage}}/>
                      <Image style={styles.hotelImageDefualt} source={genericHotelDefaultImage}/>
                    </View>
                  </View>
                  <View style={styles.flightsTimeInfo}>
                    <Text
                      style={[
                        AtomicCss.defaultText,
                        AtomicCss.font14,
                        AtomicCss.boldFont,
                      ]}
                    >
                      {checkInDateTime}
                    </Text>
                    <Text
                      style={[
                        AtomicCss.defaultTextColor,
                        AtomicCss.font14,
                        AtomicCss.regularFont,
                      ]}
                    >
                      {item.checkInTimeFormatted}
                    </Text>
                    <View style={styles.triangle} />
                    <Text style={[AtomicCss.defaultText, AtomicCss.font14, AtomicCss.boldFont]}>
                      {checkOutDateTime}
                    </Text>
                    <Text
                      style={[
                        AtomicCss.defaultTextColor,
                        AtomicCss.font14,
                        AtomicCss.regularFont,
                      ]}
                    >
                      {item.checkOutTimeFormatted}
                    </Text>
                  </View>
                  {!isUnconfirmedFDPackageHotel ?
                    <View>
                        <View style={[AtomicCss.flexRow, AtomicCss.marginBottom10]}>
                          <Text>
                            <Text
                              style={[
                                AtomicCss.boldFont,
                                AtomicCss.font14,
                                AtomicCss.defaultText
                              ]}
                              >
                              {item.roomCatagory}
                            </Text>{' '}
                            ({item.mealPlan})
                          </Text>
                        </View>
                      {item.bookingStatusMessage && 
                        <Text style={styles.infoTxt}>
                        {item.bookingStatusMessage}
                        </Text>
                      }
                    </View> : null
                  }
                </View>
              </View>
             { item.showDetailsPage ?
                !isUnconfirmedFDPackageHotel  ?
                  <RedirectBtn btnName={VIEW_HOTEL_DETAILS_TEXT} onPress={() => viewDetail({ vendorNo: item.vendorNo, serviceVoucherNo: item.serviceVoucherNo, index })} /> :
                  <View style={styles.moreInfoWrapper}>
                    <Text style={styles.moreInfoTxt}>
                      {item.disableInfoText}
                    </Text>
                  </View> :
                  null
              } 
            </View>
          );
        })}
        </React.Fragment>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  cardWrapper: {
    width: screenWidth,
    backgroundColor: '#fff',
    ...getPlatformElevation(2),
    paddingLeft: 16,
    paddingTop: 16,
    marginBottom: 3
  },
  hotelList: {
    marginBottom: 10
  },
  hotelImage: {
    width: 60,
    height: 60,
    zIndex: 2
  },
  hotelImageDefualt:{
    width: 60,
    height: 60,
    position: 'absolute',
    zIndex: 1,
  },
  hotelListRight: {
    flex: 1,
    marginRight: 16
  },
  hotelTopInfo: {
    justifyContent: 'space-between',
    borderBottomColor: '#e7e7e7',
    borderBottomWidth: 1,
    paddingBottom: 15,
    flexDirection: 'row'
  },
  flightsTimeInfo: {
    flexDirection: 'row',
    paddingVertical: 10,
    alignItems: 'center'
  },
  triangle: {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderLeftWidth: 4,
    borderRightWidth: 4,
    borderBottomWidth: 7,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: '#4a4a4a',
    transform: [{rotate: '90deg'}],
    marginTop: 2,
    marginLeft: 5,
    marginRight: 5,
    borderWidth: 0,
    borderColor: '#fff'
  },
  hotelIcon: {
    width: 22,
    height: 22,
    marginRight: 15
  },
  starIcon: {
    width: 8,
    height: 8,
    marginRight: 1
  },
  infoTxt: {
    color: '#cf8100',
    fontFamily: fonts.regular,
    fontSize: 14,
    lineHeight: 20,
    fontStyle: 'italic'
  },
  moreInfoWrapper: {
    backgroundColor: colors.white,
    paddingLeft: 50,
    paddingRight: 30,
    paddingVertical: 20,
    borderTopWidth: 1,
    borderTopColor: '#d5d5d5',
    marginLeft: -16,
  },
  moreInfoTxt: {
    color: colors.lightYello,
    fontFamily: fonts.regular,
    fontSize: 12,
  }
});

export default HotelDetailTab;
