import React from 'react';
import { View, Text } from 'react-native';
import createStyles from './styles';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getFont } from '../../../../../../PostSalesStyles';
import { isEmptyArray, isNullOrEmptyObject } from '../../../../../../Common/commonUtil';
import { useTheme } from '../../../../../../theme/theme.context';
import { getJourneyInfo } from '../../../utils';
import BaseButton, { BUTTON_VARIANT } from '../../../../../../Common/BaseButton';
import { FLIGHT_THROTTLE_OBJECT } from '../../../../details/FlightBookingDetailsConstant';

const ChangeTravelDate = ({ undoWebcheckinInfo, onUndoWebCheckin, odcPreviewResponse }) => {
  const { heading, description, steps, actionBtn, undoWebCheckinApplicableSegs } =
    undoWebcheckinInfo || {};
  const { flightDetails } = odcPreviewResponse || {};
  const { segmentGroupDetailList } = flightDetails || {};
  const fonts = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);

  const renderJourney = (data) => {
    const journeyInfo = getJourneyInfo(data, undoWebCheckinApplicableSegs);
    const cards = [];
    journeyInfo && journeyInfo.map((item) => {
      cards.push(
        <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom8]}>
          <View style={styles.bullet}></View>
          <Text style={[AtomicCss.regularFont, AtomicCss.font14, AtomicCss.defaultText]}>
            <Text style={[AtomicCss.boldFont, AtomicCss.blackText]}>
              {item.text1}
            </Text>
            {item.text2}
          </Text>
        </View>,
      )
    });
    return cards;
  };

  return (
    <View>
      <View>
        {!!heading && <Text style={[styles.title, AtomicCss.marginBottom20, fonts.blackFontFamily]}>
          {heading}
        </Text>}
        {!!description && (
          <Text
            style={[
              fonts.regularFontFamily,
              fonts.fontSize.font14,
              AtomicCss.defaultText,
              styles.lineHeight21,
              AtomicCss.marginBottom12,
            ]}
          >
            {description}
          </Text>
        )}
        {!isEmptyArray(segmentGroupDetailList) &&
          segmentGroupDetailList.map((item) => renderJourney(item))}
      </View>
      <View style={styles.bottomWrapper}>
        {!isNullOrEmptyObject(steps) && (
          <View style={styles.noteWrapper}>
            <Text
              style={[
                fonts.blackFontFamily,
                fonts.fontSize.font13,
                AtomicCss.yellowText,
                AtomicCss.lineHeight18,
                AtomicCss.marginBottom5,
              ]}
            >
              {steps.heading}
            </Text>
            {!isEmptyArray(steps.description) &&
              steps.description.map((text, index) => (
                <View style={AtomicCss.flexRow}>
                  <Text
                    style={[
                      fonts.boldFontFamily,
                      fonts.fontSize.font13,
                      AtomicCss.yellowText,
                      AtomicCss.lineHeight20,
                    ]}
                  >
                    {`${index + 1}. `}
                  </Text>
                  <Text
                    style={[
                      fonts.regularFontFamily,
                      fonts.fontSize.font13,
                      AtomicCss.yellowText,
                      AtomicCss.lineHeight20,
                    ]}
                  >
                    {text}
                  </Text>
                </View>
              ))}
          </View>
        )}
        {!isNullOrEmptyObject(actionBtn) && (
          <BaseButton
            text={actionBtn.title}
            clickHandler={() => onUndoWebCheckin(undoWebCheckinApplicableSegs)}
            btnBg="gradientBtn"
            btnType="flat"
            variant={BUTTON_VARIANT.PRIMARY}
            textStyle={{...fonts.fsStyle.font16}}
            {...FLIGHT_THROTTLE_OBJECT}
          />
        )}
      </View>
    </View>
  );
};

export default ChangeTravelDate;
