/* eslint-disable max-len */
import React from 'react';
import Actions from '../../navigation/postSalesNavigation';
import PropTypes from 'prop-types';
import CustomSafeAreaView from '@mmt/legacy-commons/Common/Components/CustomSafeAreaView';
import {View, ScrollView, Text, StyleSheet, Platform} from 'react-native';
import StickyHeaderBack from '../components/StickyHeader/StickyHeaderBack';
import ReviewStepHeader2 from '../components/ReviewStepHeader/ReviewStepHeader2';
import ReviewStepHeader from '../components/ReviewStepHeader/ReviewStepHeader';
import RecBlueBtn from '../components/buttons/RecBlueBtn';
import CancellationBreakup from '../components/RefundBreakUp/CancellationBreakup';
import CancellationBreakupDtls from '../components/RefundBreakUp/CancellationBreakupDtls';
import AtomicCss from '../commonStyles/AtomicCss';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import ProgressView from '../../Common/ProgressView/ProgressView';
import {allPaxList, getTheSelectedData, activePaxCount} from '../details/RailBookingDetailsUtil';
import RailBookingTrackingHelper from '../details/RailBookingTrackEventHelper';
import OverlayAlert from '../components/OverlayAlert/OverlayAlert';
import {showShortToast} from '@mmt/legacy-commons/Common/Components/Toast';
import {isNetworkAvailable} from '@mmt/legacy-commons/Common/utils/AppUtils';
import {getCommonHeaders, RAIL_BOOKING_CANCEL_URL_V2} from '../../utils/NetworkUtils';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import SimpleHeader from '@mmt/legacy-commons/Common/Components/Header/SimpleHeader';
import _ from 'lodash';
import {
  addRupeeSign,
  getCancelRequestKeyName, getParamsForBookingDetail, removeKeyFromAsyncStorage,
  storeObjInAsyncStorage
} from '../../Common/commonUtil';
import {
  CancellationPreviewRequest as CancelPreviewRequest,
  DEAFULTREFUNDMODE, POST,
  PREVIEW
} from '../../PostSalesConstant';
import ReasonForCancellation from "./ReasonForCancellation";
import { getStaticData } from '../../staticData/staticData';
import { getFont } from '../../PostSalesStyles';

class ReviewRefundCharges extends React.Component {
  static navigationOptions = {
    header: null
  };
  constructor(props) {
    super(props, 'ReviewRefundCharges');
    this.state = {
      viewState: ViewState.LOADING,
      showAlert: false,
      cancellationReasonList : this.props.bookingDetails && this.props.bookingDetails.cancellationReasonList,
      cancellationReason : this.props.bookingDetails && this.props.bookingDetails.cancellationReasonList && (_.isEmpty(this.props.bookingDetails.cancellationReasonList) ? ('') : (this.props.bookingDetails.cancellationReasonList[0].reason)),
      enableInitiateCancellationCta : (!(this.props.bookingDetails && this.props.bookingDetails.cancellationReasonList))
    };
    this.cancelPreviewObj = null;
    this.cancelRequestKey = getCancelRequestKeyName(CancelPreviewRequest.RAIL, this.props.bookingDetails.bookingID);
  }
  componentDidMount() {
    removeKeyFromAsyncStorage(this.cancelRequestKey);
    this.fetchCancelPreviewJSON();
  }
  render() {
    if (this.state.viewState === ViewState.SHOW_DETAIL) {
      RailBookingTrackingHelper.trackLoadEvent(this.getPageName(), this.bookingDetails);
    }
    return (
      <View style={{flex: 1}}>
        {Platform.OS === 'ios' && <CustomSafeAreaView />}
        {this.state.viewState === ViewState.LOADING && this.renderProgressView()}
        {this.state.viewState === ViewState.SHOW_DETAIL && this.renderMainView()}
        {this.state.viewState === ViewState.ERROR && this.renderErrorView()}
        {Platform.OS === 'ios' && <CustomSafeAreaView positionTop={false} />}
      </View>
    );
  }

  getTheSelectedData = (selectedReason,index,otherInfo) => {
    let detail = getTheSelectedData(selectedReason,index,otherInfo, this.state.cancellationReasonList, this.state.cancellationReason);
    this.setState({cancellationReasonList : detail.newCancellationRules, cancellationReason : detail.cancellationReason, enableInitiateCancellationCta : true})
  }

  getPageName = () => {
    let pageName = '';
    if (this.props.isFullCancellation) {
      pageName = 'FullCancellation';
    } else {
      pageName = 'PartialCancellation';
    }
    return pageName;
  }

  renderMainView = () => {
    const staticData = getStaticData();
    const { initiateCacellationText, yesCancelText, noGoBackText, youSureText, youWishToCancelText } = staticData;
    return (
      <View style={styles.cancellationWrapper}>
        <StickyHeaderBack
          trainDetails={this.props.bookingDetails ? this.props.bookingDetails.train : null}
        />
        <ScrollView style={AtomicCss.greyBg}>
          { this.props.isFullCancellation === true && <ReviewStepHeader currentIndex={1} />}
          { this.props.isFullCancellation === false && <ReviewStepHeader2 currentIndex={2} />}
          {this.props.isFullCancellation === false && this.props.selectedPax &&
          this.props.selectedPax.length > 0 &&
          <CancellationBreakup passengers={this.props.selectedPax} />}
          {this.props.isFullCancellation === true && this.renderFullCancelHeader()}
          {this.props.bookingDetails.train[0].cancellationAssured
            && this.renderZCMessage(this.props.bookingDetails.train[0].zeroCancellationMessage)}
          {
            this.props.bookingDetails.train[0].zcplus
            && this.renderZCMessage(this.props.bookingDetails.train[0].zcplusMessage)
          }
          <CancellationBreakupDtls
            fareBreakUpObject={this.fareBreakUpObject()}
            selectedPax={this.props.selectedPax}
            isFullCancellation={this.props.isFullCancellation}
            paidComponent={this.getPaidComponent()}
            deductionComponent={this.getDeductions()}
            isRefundZero={this.isRefundZero()}
            penaltyResponse={this.cancelPreviewObj}
            zcplusSubtext={this.cancelPreviewObj.zcplusSubText}
          />
          {
            this.props.bookingDetails && this.props.bookingDetails.cancellationReasonList && !_.isEmpty(this.props.bookingDetails.cancellationReasonList) &&
            <ReasonForCancellation reasons={this.state.cancellationReasonList} getTheSelectedData={this.getTheSelectedData}/>
          }
          {
            this.state.enableInitiateCancellationCta && <RecBlueBtn btntext={initiateCacellationText} onPressHandler={this.onClickHandler} />
          }

        </ScrollView>

        {this.state.showAlert && this.isRefundZero() &&
        <OverlayAlert
          positiveBtnText={yesCancelText}
          negativeBtnText={noGoBackText}
          positiveBtnOnTap={this.cancelBookingBtnTapped}
          negativeBtnOnTap={this.backButtonTapped}
          titleText={youSureText}
          heading2Text={youWishToCancelText}
          subTitleText={this.cancelPreviewObj.ticketCancellationPreview.refundTitleMessage}
          bgTapAction={this.backButtonTapped}
        />
        }

      </View>

    );
  };

  renderErrorView = () => {
    const staticData = getStaticData();
    const { goBackToBookingText, backText } = staticData;
    return (
      <View style={{flex: 1}}>
        <SimpleHeader
          title={backText}
          iconPress={this.goBackToDetails}
        />
        <ErrorView
          showRetry
          buttonLabel={goBackToBookingText}
          message={this.getErrorMessage()}
          onRetry={this.goBackToDetails}
        />
      </View>
    );
  }

  getErrorMessage = () => {
    const staticData = getStaticData();
    const { errorText } = staticData;
    if (this.cancelPreviewObj.response && this.cancelPreviewObj.response.errorText) {
      return this.cancelPreviewObj.response.errorText;
    }
    return errorText.somethingWentWrongTryAgainError;
  }


  goBackToDetails = () => {
    if(Platform.OS === 'web'){
      Actions.railBookingDetail({data: getParamsForBookingDetail(this.props.bookingDetails.bookingID,this.props.mmtAuth,this.props.loggingTrackInfo), BOOKING_ID: this.props.bookingDetails.bookingID});
    }else{
      Actions.popTo('railBookingDetail');
    }
    RailBookingTrackingHelper.trackClickEvents('Cancellation Screen Shown', 'Cancellation_NotConfirmed');
  }


  isRefundZero = () => this.cancelPreviewObj.ticketCancellationPreview.refundBreakup.totalRefund.amount <= 0

  renderFullCancelHeader = () => {
    const { fsStyle, ...fonts } = getFont(true);
    const staticData = getStaticData();
    const {trainCancellationText} = staticData;
    return (
      <View style={[styles.partCancellationHead, AtomicCss.elevation1]}>
        <Text style={[styles.wrapHeading, fonts.blackFontFamily, AtomicCss.blackText, fsStyle.font16]}>
          {trainCancellationText.headingText}
        </Text>
      </View>
    )
  };

  renderZCMessage = (zcMessage) => {
    const { fsStyle } = getFont(true);
    return (
      <View style={[styles.zCMessage, AtomicCss.elevation1]}>
        <Text style={[styles.impInfoRed, fsStyle.font14]}>
        {zcMessage}
        </Text>
      </View>
    )
  }

  renderProgressView = () => {
    const staticData = getStaticData();
    const { loadingText } = staticData;
    return (<ProgressView message={loadingText ? loadingText : ""} style={styles.progressContainer} />);
  }

  fareBreakUpObject = () => {
    let totalRefundAmount = 0.0;
    let totalCancelationCharges = 0.0;
    let totalPaidAmount = 0.0;
    let remainingAmountText = '';

    totalRefundAmount = addRupeeSign(this.cancelPreviewObj.ticketCancellationPreview.refundBreakup.totalRefund.amount);
    remainingAmountText = this.cancelPreviewObj?.remainingAmountText;
    totalCancelationCharges = `-${addRupeeSign(this.cancelPreviewObj.ticketCancellationPreview.cancellationFare.totalPenalty.amount)}`;
    totalPaidAmount = addRupeeSign(this.getAmountPaid());

    return {totalRefundAmount, totalCancelationCharges, totalPaidAmount, remainingAmountText};
  }


  penaltyNodeForPax = (paxDetails) => {
    let penaltyNodeForPax = null;
    if (this.cancelPreviewObj && this.cancelPreviewObj.passengerCancellationPenaltyList) {
      let index = 0;
      while (index < this.cancelPreviewObj.passengerCancellationPenaltyList.length) {
        const penalyNode = this.cancelPreviewObj.passengerCancellationPenaltyList[index];
        if (paxDetails.ticketLineNo && penalyNode.ticketLineNo &&
          paxDetails.ticketLineNo === penalyNode.ticketLineNo) {
          penaltyNodeForPax = penalyNode;
          break;
        }
        index += 1;
      }
    }
    return penaltyNodeForPax;
  }

  getCancelledPaxLineIds = () => {
    const paxLineId = [];
    if (this.props.selectedPax && !this.props.isFullCancellation) {
      this.props.selectedPax.forEach((paxDetails) => {
        if (paxDetails.ticketLineNo) {
          paxLineId.push(paxDetails.ticketLineNo.toString());
        }
      });
    } else if (this.props.isFullCancellation) {
      const paxList = allPaxList(this.bookingDetails);
      if (paxList) {
        paxList.forEach((paxDetails) => {
          if (paxDetails.ticketLineNo) {
            paxLineId.push(paxDetails.ticketLineNo);
          }
        });
      }
    }
    return paxLineId;
  }

  totalPaxCount = () => {
    let totalPaxCount = 0;
    const paxList = allPaxList(this.props.bookingDetails);
    if (paxList) {
      totalPaxCount = paxList.length;
    }
    return totalPaxCount;
  }
  cancelBookingBtnTapped = () => {
    this.backButtonTapped();
    this.pushBookingCancelView();
    RailBookingTrackingHelper.trackClickEvents('Cancellation Screen Shown', 'Cancellation_Confirmed');
  }

  backButtonTapped = () => {
    this.setState({showAlert: false});
    RailBookingTrackingHelper.trackClickEvents('Cancellation Screen Shown', 'Cancellation_NotConfirmed');
  }

  onClickHandler = () => {
    RailBookingTrackingHelper.trackClickEvent(this.getPageName(), this.props.bookingDetails, 'Chooserefundmode');
    if (!this.isRefundZero()) {
      this.pushRefundModeView();
    } else {
      this.setState({showAlert: true});
    }
  }

  pushBookingCancelView = () => {
    Actions.railBookingCancelled({
      isFullCancellation: this.props.isFullCancellation,
      cancelledPaxLineId: this.getCancelledPaxLineIds(),
      totalPaxCount: this.totalPaxCount(),
      bookingID: this.props.bookingDetails.bookingID,
      mmtAuth: this.props.mmtAuth,
      loggingTrackInfo: this.props.loggingTrackInfo,
      bookingDetails: this.props.bookingDetails,
      refundAmount: this.cancelPreviewObj.ticketCancellationPreview.refundBreakup.totalRefund.amount,
      reason: this.state.cancellationReason,
      activePaxCount: activePaxCount(this.props.bookingDetails),
    });
  }

  pushRefundModeView = () => {
    Actions.reviewRailRefundMode({
      bookingDetails: this.props.bookingDetails,
      isFullCancellation: this.props.isFullCancellation,
      previewObject: this.cancelPreviewObj,
      mmtAuth: this.props.mmtAuth,
      loggingTrackInfo: this.props.loggingTrackInfo,
      selectedPax: this.props.selectedPax,
      refundModeList: this.cancelPreviewObj.cancellationRefundMode.refundModeList,
      cancelledPaxLineId: this.getCancelledPaxLineIds(),
      totalPaxCount: this.totalPaxCount(),
      bookingID: this.props.bookingDetails.bookingID,
      refundAmount: this.cancelPreviewObj.ticketCancellationPreview.refundBreakup.totalRefund.amount,
      reason: this.state.cancellationReason,
      activePaxCount: activePaxCount(this.props.bookingDetails),
    });
  }


  async fetchCancelPreviewJSON() {
    const staticData = getStaticData();
    const { errorText } = staticData;
    try {
      const hasNetwork = await isNetworkAvailable();
      if (!hasNetwork) {
        showShortToast(errorText.checkYourInternetConnectionError);
        return;
      }
      const url = RAIL_BOOKING_CANCEL_URL_V2;
      const commonHeaders = await getCommonHeaders(this.props.mmtAuth, this.props.loggingTrackInfo);
      const request = {
        bookingID: this.props.bookingDetails.bookingID,
        action: PREVIEW,
        refundOption: DEAFULTREFUNDMODE,
        functionType: 'CancellationRequest',
        isFullCancellation: this.props.isFullCancellation,
        ticketList: this.getCancelledPaxLineIds()
      };
      await storeObjInAsyncStorage(this.cancelRequestKey, request);
      const response = await fetch(url, {
        method: POST,
        body: JSON.stringify(request),
        headers: commonHeaders
      });
      if (response.ok) {
        const responseBody = await response.json();
        this.cancelPreviewObj = responseBody;
        if ((responseBody.response && responseBody.response.errorText.length > 0) || (!responseBody.ticketCancellationPreview || Object.keys(responseBody.ticketCancellationPreview).length === 0)) {
          this.setState({viewState: ViewState.ERROR});
        } else {
          this.setState({viewState: ViewState.SHOW_DETAIL});
        }
      } else {
        showShortToast(errorText.somethingWentWrongTryAgainError);
        Actions.pop();
      }
    } catch (error) {
      showShortToast(errorText.somethingWentWrongTryAgainError);
      Actions.pop();
    }
  }

  getPaidComponent = () => {
    const components = [];

    if (this.cancelPreviewObj && this.cancelPreviewObj.ticketCancellationPreview) {
      const fareBreakupList = this.cancelPreviewObj.selectedPaxAmountPaid;
      let i = 0;
      for (i = 0; i < fareBreakupList.length; i++) {
        const segment = {};
        segment.label = fareBreakupList[i].text;
        segment.value = `₹ ${fareBreakupList[i].amountInDouble}`;
        segment.order = i + 1;
        components.push(segment);
      }
    }

    return components;
  }

  getAmountPaid = () => {
    let amountPaid = 0;
    if (this.cancelPreviewObj && this.cancelPreviewObj.ticketCancellationPreview) {
      const fareBreakupList = this.cancelPreviewObj.selectedPaxAmountPaid;
      let i = 0;
      for (i = 0; i < fareBreakupList.length; i++) {
        amountPaid += fareBreakupList[i].amountInDouble;
      }
    }

    return amountPaid;
  }

  getDeductions = () => {
    const components = [];

    if (this.cancelPreviewObj && this.cancelPreviewObj.ticketCancellationPreview) {
      const fareBreakupList = this.cancelPreviewObj.deductionsBreakup;
      let i = 0;
      for (i = 0; i < fareBreakupList.length; i++) {
        const segment = {};
        segment.label = fareBreakupList[i].text;
        segment.labelId = fareBreakupList[i].labelId;
        segment.value = `-₹ ${fareBreakupList[i].amountInDouble}`;
        segment.order = i + 1;
        if (this.cancelPreviewObj.cancellationAssured && fareBreakupList[i].labelId === "ticket_cancellation_charges") {
          segment.amountSaved = `₹ ${fareBreakupList[i].amountInDouble}`;
        }
        components.push(segment);
      }
    }

    return components;
  }
}

ReviewRefundCharges.propTypes = {
  bookingDetails: PropTypes.object.isRequired,
  selectedPax: PropTypes.array,
  mmtAuth: PropTypes.string.isRequired,
  loggingTrackInfo: PropTypes.string.isRequired,
  isFullCancellation: PropTypes.bool.isRequired
};

ReviewRefundCharges.defaultProps = {
  selectedPax: null
};

const styles = StyleSheet.create({
  cancellationWrapper: {
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: '#f2f2f2'
  },
  partCancellationHead: {
    backgroundColor: '#ffffff',
    paddingVertical: 18,
    paddingLeft: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#ddd'
  },
  zCMessage: {
    backgroundColor: '#ffffff',
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#ddd'
  },
  impInfoRed:{
    color: '#eb2026',
  },
  wrapHeading: {
    maxWidth: 370
  },
});

export default ReviewRefundCharges;
