// @flow
import React from 'react';
import getCabsSummaryData from './cabsCardAdapter';
import {
  ActionsContent,
  CabUnconfirmedText,
  CoachInfoText,
  DescriptionSection,
  JourneyText,
  MainContent,
  NeedHelpStrip,
  NewStatusText,
  RightArrow,
  StaticIcon,
  SummaryContainer,
} from '../cardElements';
import MulticityInfoText from "./MultiCityInfoText"
import isEmpty from 'lodash/isEmpty';
function CabsSummaryCard({ response, msrTicket }) {
  const cardData = getCabsSummaryData(response, msrTicket);
  const [carDetails] = response.carDetails;
  const multiCityInfo = response.multicitytransport;
  const multiCityInfoFlag = !isEmpty(multiCityInfo);
  return (
    <SummaryContainer bookingId={response.bookingID}>
      <MainContent onPress={cardData.openDetails}>
        <StaticIcon icon={cardData.iconUrl} iconStyle={{ width: 23, height: 20 }} />
        <DescriptionSection>
          <JourneyText
            from={cardData.from}
            to={cardData.to}
            isHourlyRental={cardData.isHourlyRental}
            showRoundTripArrowIcon={cardData.showRoundTripArrowIcon}
          />
          {multiCityInfoFlag && <MulticityInfoText multicityText={carDetails.tripType} vehicleType={carDetails.vehicleType} />}
          {!multiCityInfoFlag && !cardData.failedBooking && <CoachInfoText text={cardData.cabType} />}
          <NewStatusText status={cardData.status} text={cardData.dateTime} />
          {cardData.showUnconfirmedText && <CabUnconfirmedText />}
        </DescriptionSection>
        <RightArrow />
      </MainContent>
      {!isEmpty(cardData.needHelpData) &&  cardData.needHelpData.map((needHelp)=><NeedHelpStrip {...needHelp}/>)}
      <ActionsContent actions={cardData.actions} />
    </SummaryContainer>
  );
}

export default CabsSummaryCard;
