const VISA_BOOKING_ROUTE_KEYS_ARRAY = [
    {
        key: 'visaDocumentViewer',
        component: () => require('./Details/Component/VisaDocumentViewer').default,
    },
    {
        key: 'visaDocument',
        component: () => require('./Details/Component/VisaDocument').default,
    },
    {
        key: 'visapaymentBreakup',
        component: () => require('./Details/Component/VisaPaymentBreakUp').default,
    },
    {
        key: 'visaFAQ',
        component: () => require('./Details/Component/VisaFAQ').default,
    },
    {
        key: 'visaBookingDetail',
        component: () => require('./Details/VisaBookingDetail').default
    }
]

export default VISA_BOOKING_ROUTE_KEYS_ARRAY;
