import React from "react";
import { View, Text, Linking }  from "react-native";
import { TitleText } from "../../summary/components/cards/cardElements";
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import PendingPaymentTag from "../PendingPaymentTag/PendingPaymentTag"
import PendingPaymentMessage from "../PendingPaymentMessage/PendingPaymentMessage"
import LinearGradient from "react-native-linear-gradient";
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';

const PendingPaymentCard = ({
  title,
  subTitle,
  tagTextStart,
  tagTextEnd,
  ctaUrl,
  paymentChargeDate,
  ctaText
}) => {

  return (
    <View style={[AtomicCss.paddingAll20, AtomicCss.whiteBg, AtomicCss.borderRadius10, AtomicCss.marginBottom10]}>
       <TitleText style={[AtomicCss.marginBottom8]} text={title} />
       <PendingPaymentTag tagTextStart={tagTextStart} tagTextEnd={tagTextEnd}  paymentChargeDate={paymentChargeDate} />
       <PendingPaymentMessage subTitle={subTitle} />
        <LinearGradient
            start={{ x: 1.0, y: 0.0 }}
            end={{ x: 0.0, y: 1.0 }}
            colors={[colors.darkBlue, colors.lightBlue]}
            style={[AtomicCss.borderRadius20, AtomicCss.paddingAll10]}
        >
            <Text 
                style={[AtomicCss.textUppercase, AtomicCss.textCenter, AtomicCss.font14, AtomicCss.whiteText, AtomicCss.boldFont]}
                onPress={() => {
                  Linking.canOpenURL(ctaUrl).then(supported => {
                    if (supported) {
                        Linking.openURL(ctaUrl);
                    }
                });
            }}>
            {ctaText}
          </Text>
        </LinearGradient>
    </View>
  );
};

export default PendingPaymentCard;