import { StyleSheet } from 'react-native';

const createStyles = (theme, fonts) => StyleSheet.create({

  headingText: {
    color: theme.color.black22,
    ...fonts.blackFontFamily,
    fontSize: fonts.fontSize.font24,
  },
  detailsSection: {
    paddingVertical : 20,
  },
  iconWrapper: {
    width: 32,
    height: 32,
    alignItems:'center',
    justifyContent:'center',
    marginRight: 5,
  },
  iconStyles: {
    width: 18,
    height: 24,
  },
  rowSection: {
    flexDirection: 'row',
  },
  btnWrapper: {
    marginTop: 45,
  },
  crossIconStyle: {
    width: 24,
    height: 24,
  },
});

export default createStyles;