import React from 'react';
import { View, Image, Text, TouchableOpacity, ImageSourcePropType } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getImageTransformStyling } from '../../utils/PostSaleUtil';
import { themeColors } from '../../PostSalesStyles';
import { getRedirectLinkImage } from '../../hotel/details/utils/HotelBookingDetailUtil';
import rightBlueArrow from '@mmt/legacy-assets/src/blueArrowRight.webp';
import { getFont } from '../../PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import createStyles from './styles';

interface SubAction {
  actionRank: number;
  actionId: string;
  actionType: string;
  actionLabeltext: string;
  actionFamily: string;
}

interface Action {
  actionRank: number;
  actionId: string;
  actionType: string;
  actionLabeltext: string;
  actionFamily: string;
  actionAlertHeaderText: string | null;
  actionAlertMainText: string | null;
  subCardId: string | null;
  subActionListMainText: string | null;
  subActionList: SubAction[] | null;
  bookingType: string;
}

interface RedirectLinkImageProps {
  action: Action;
  clickHandler: (action: Action) => void;
  content?: string;
}

const RedirectLinkImage: React.FC<RedirectLinkImageProps> = ({ action, clickHandler, content }) => {
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme);
  return (
    <View style={styles.bottomLink}>
      <TouchableOpacity
        style={[AtomicCss.flexRow, AtomicCss.spaceBetween, AtomicCss.alignCenter, AtomicCss.flex1]}
        onPress={() => clickHandler(action)}
      >
        <View style={styles.imageContainer}>
          <Image
            style={styles.imageIcon}
            source={getRedirectLinkImage(action.actionFamily)}
          />
        </View>
        <Text style={[fonts.boldFontFamily, fsStyle.font14, styles.textStyle]}>
          {content ? content : action.actionLabeltext}
        </Text>
        <Image style={[styles.rightBlueArrow, getImageTransformStyling()]} source={rightBlueArrow} />
      </TouchableOpacity>
    </View>
  );
};

export default RedirectLinkImage;