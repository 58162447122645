import React, { useState } from 'react';
import { Text, View, TouchableOpacity } from 'react-native';
import createStyles from '../styles';
import FlightAirlines from './FlightAirlines';
import { getStaticData } from '../../../../../../../../../staticData/staticData';
import { isAlFltrOn } from '../../../../../../utils';
import { getFont } from '../../../../../../../../../PostSalesStyles';
import { useTheme } from '../../../../../../../../../theme/theme.context';

const FiltersAirlines = (props) => {
  const fonts = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);
  const alFltrOn = isAlFltrOn(props.currOpAlFltrLst);
  const [hideFlts, setHideFlts] = useState(alFltrOn);
  const currOpAlFltrLst = props.currOpAlFltrLst;

  const { alFltrInfo } = props;
  const { operatingAirlinesText, showAirlinesText, hideAirlinesText } = getStaticData();
  const _renderOAView = () => {
    const oaVwLst = [];
    for (let i = 0; i < alFltrInfo.length; i++) {
      if (hideFlts && i === 3) {
        break;
      }
      oaVwLst.push(
        <FlightAirlines
          data={alFltrInfo[i]}
          selected={currOpAlFltrLst[i].selected}
          index={i}
          toggleOpAlFltr={_setOpAlFltr}
          currency={props.currency}
        />,
      );
    }
    return oaVwLst;
  };

  const _setOpAlFltr = (active, index) => {
    currOpAlFltrLst[index].selected = active;
    props.toggleOpAlFilter(currOpAlFltrLst);
  };
  return (
    <View style={styles.filterItemRow}>
      <Text style={[styles.FiltersHeading, styles.headingBottomBorder]}>
        {operatingAirlinesText}
      </Text>
      <View>
        {_renderOAView(alFltrInfo, hideFlts)}
        {alFltrInfo.length > 3 && (
          <TouchableOpacity style={styles.ShowAllAirlines} onPress={() => setHideFlts(!hideFlts)}>
            <Text style={styles.ShowAllAirlinesTxt}>
              {hideFlts ? showAirlinesText : hideAirlinesText}
            </Text>
          </TouchableOpacity>
        )}
      </View>
    </View>
  );
};

export default FiltersAirlines;
