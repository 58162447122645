import myTripsNavigation from '../../../../navigation/navigation';
import { titleCase } from '@mmt/legacy-commons/Helpers/stringHelpers';
import { getMyRequestCardData, handleCTAClick } from '../../../utils';
import { BusData, BusDetailsProps, BusSummaryDataProps, MsrTicket } from '../cardInterfaces';
import { busImage } from 'apps/post-sales/src/bus/BusImageConstants';

function getTravelerSummary(busDetails: BusDetailsProps) {
  const { passengers } = busDetails;
  const count = passengers.length;
  const firstPaxName = titleCase(passengers[0].firstName);
  if (count === 1) {
    return firstPaxName;
  }
  return `${firstPaxName} + ${count - 1}`;
}

export function getBusSummaryData(data: BusData, msrTicket: MsrTicket): BusSummaryDataProps {
  const busDetails = data.coachDetails && data.coachDetails[0] || {};
  const destinationCoachDetailsIndex = data.coachDetails && data.coachDetails.length - 1;
  const { ctaInfoList: ctaDetails, infoCard } = data && data.bookingStateLayout || {};
  let coachName = busDetails.coachName;
  const pageData = {
    fromMyTrips: true,
  };
  const openDetails = () => {
    myTripsNavigation.openBusBookingDetail(data, pageData);
  };

  const actions: Array<{ label: string; onPress: () => void }> = [];
  const travelerSummary = getTravelerSummary(busDetails);
  let passengerDetails = travelerSummary;
  const startsIn = data.startsIn;
  const lobName = data.lobType[0];
  const bookingId = data.bookingID;
  const { startDateText, startTimeText, pickupLocation } = busDetails || {};
  const { endDateText, endTimeText } = data.coachDetails && data.coachDetails[destinationCoachDetailsIndex] || {};
  const { text, ctaInfo } = infoCard || {};
  const infoCardStatusText = text;
  const infoCardStatusCTA = ctaInfo && ctaInfo.text;

  if (ctaDetails && ctaDetails[0]) {
    actions.push({ label: ctaDetails[0]?.text, onPress: () => handleCTAClick(ctaDetails[0], data) });
  };
  if (ctaDetails && ctaDetails[1]) {
    actions.push({ label: ctaDetails[1]?.text, onPress: () => handleCTAClick(ctaDetails[1], data) });
  };

  const handleInfoClick = () => {
    if (infoCard) {
      handleCTAClick(infoCard.ctaInfo, data);
    }
  };

  return {
    status: data.status,
    iconUrl: busImage,
    from: busDetails.originCityName,
    to: busDetails.destinationCityName,
    actions,
    myRequestsCard: getMyRequestCardData(msrTicket),
    busName: coachName,
    passengerDetails,
    busStartingPoint: pickupLocation,
    startsIn,
    startDateText,
    endDateText,
    startTimeText,
    endTimeText,
    lobName,
    bookingId,
    infoCardStatusText,
    infoCardStatusCTA,
    openDetails,
    handleInfoPress: handleInfoClick
  };
}

export default {};