import React from 'react';
import PropTypes from 'prop-types';
import {View, Text, Image, TouchableOpacity, StyleSheet} from 'react-native';
import Actions from '../../../navigation/postSalesNavigation';
import AtomicCss from '../../commonStyles/AtomicCss';
import {trainFromToSubTitleWithDate} from '../../details/RailBookingDetailsUtil';
import { HEADER_BACK_ICON} from '../../Utils/RailImageConstant';
import { getStaticData } from '../../../staticData/staticData';
import { getFont } from '../../../PostSalesStyles';
import isEmpty from 'lodash/isEmpty';

class StickyHeader extends React.Component {
    static navigationOptions = {
      header: null
    };

    GoBackHandler = () => {
      Actions.pop();
    }

    // trainFromToSubTitleWithDate to be updated when node from api available.
    render() {
      const { fsStyle, ...fonts } = getFont(true);
      const staticData = getStaticData();
      const { trainCancellationText: {additionalText : {cancelTrainTicketsText}} } = staticData;
      const heading = this.props.heading || cancelTrainTicketsText; 
      return (
        <View style={styles.WhiteStickyHeader}>
          <View style={styles.topStatusBar}>
            <TouchableOpacity onPress={this.GoBackHandler}>
              <Image style={styles.backarrow} source={HEADER_BACK_ICON} />
            </TouchableOpacity>
            <View style={AtomicCss.marginLeft12}>
              { !isEmpty(heading) && <Text style={[AtomicCss.blackText,
                fonts.blackFontFamily, AtomicCss.marginBottom5, fsStyle.font14]}
              >
              {heading}
              </Text>}
              <Text style={[AtomicCss.defaultTextColor, fsStyle.font12, fonts.regularFontFamily]}>
                {trainFromToSubTitleWithDate(this.props.trainDetails)}
              </Text>
            </View>
          </View>
        </View>
      );
    }
}

StickyHeader.propTypes = {
  trainDetails: PropTypes.array.isRequired
};

const styles = StyleSheet.create({
  WhiteStickyHeader: {
    backgroundColor: '#fff',
    paddingTop: 10,
    paddingBottom: 10,
    elevation: 3,
    shadowColor: '#000',
    shadowOffset: {width: 0, height: 4},
    shadowOpacity: 0.3,
    shadowRadius: 2
  },
  topStatusBar: {
    flexDirection: 'row',
    paddingLeft: 10,
    paddingRight: 20,
    alignItems: 'center'
  },
  backarrow: {
    width: 16,
    height: 16,
    marginHorizontal: 10,
    marginVertical: 10
  }
});

export default StickyHeader;
