import React from 'react';
import { Text, View } from 'react-native';
import { getStaticData } from '../../../../../../../../../staticData/staticData';
import { getFont } from '../../../../../../../../../PostSalesStyles';
import { useTheme } from '../../../../../../../../../theme/theme.context';
import createStyles from '../styles';
import FlightStop from './FlightStop';

const FiltersStop = (props) => {
  const currStopFltr = props.currStopFltr;
  const { data } = props;
  const { itinFltrInfo } = props;
  const { stopsFromText } = getStaticData();
  const _stopsFromText = stopsFromText.replace('${fcn}', itinFltrInfo.fcn);
  const fonts = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);
  const _renderStopView = (data, itinFltrInfo) => {
    const stpVwLst = [];
    data.list.forEach((item, index) => {
      stpVwLst.push(
        <FlightStop
          data={item}
          currency={props.currency}
          fltrMtDta={itinFltrInfo.itinFltr.stpsLst[index]}
          selected={currStopFltr[index]}
          index={index}
          toggleStopFilter={_setStopFilter}
        />,
      );
    });
    return stpVwLst;
  };

  const _setStopFilter = (active, index) => {
    currStopFltr[index] = active;
    props.toggleStopFilter(currStopFltr, props.index);
  };
  return (
    <View style={styles.filterItemRow}>
      <Text style={styles.FiltersHeading}>
        {_stopsFromText} 
      </Text>
      <View style={styles.filterStopMain}>{_renderStopView(data, itinFltrInfo)}</View>
    </View>
  );
};
export default FiltersStop;
