import { StyleSheet } from 'react-native';

export const createStyle = (fonts: any, color: any) => {
    return StyleSheet.create({
          container: {
            flexDirection: 'row',
            alignItems: 'center',
            marginHorizontal: 16,
            padding: 12,
            borderRadius: 8,
            backgroundColor: color.creamWhite9,
          },
          text: {
            paddingHorizontal: 8,
            color: color.lightYello,
            ...fonts.boldFontFamily,
            ...fonts.font12,
          },
          linkText: {
            color: 'blue',
            ...fonts.font12,
            ...fonts.boldFontFamily,
            marginLeft: 4,
          },
          icon: {
            width: 16,
            height: 16,
          },
    });
};
