import React from 'react';
import { View } from 'react-native';
import SimilarPropertiesListView from '../../../hotels-funnel/SimilarProperties/SimilarPropertiesListView';
import { getSimilarHotelsProps } from '../utils/HotelBookingDetailUtil';
import getStyles from './HotelMamiCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const SimilarPropertiesCard = ({ hotelDetailsResponse }) => {
  const { ...fonts } = getFont(true);
  const styles = getStyles(fonts);
  return (
    <View style={[styles.MamiCard, AtomicCss.marginBottom12]}>
      <SimilarPropertiesListView
        {...getSimilarHotelsProps(hotelDetailsResponse)}
      />
    </View>
  );
}

export default SimilarPropertiesCard;
