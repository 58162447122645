import React, {useEffect} from 'react';
import {View, Text, Image, TouchableOpacity, StyleSheet} from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { colors} from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
import HotelBookingTrackingHelper from '../../HotelBookingTrackingHelper';
import { HELP_ICON, CHEVRON_RIGHT_BLUE_ICON } from '../../HotelImageConstants';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const helpIcon = HELP_ICON;
const rightArrow = CHEVRON_RIGHT_BLUE_ICON;

const BHFCard = ({response, pageName, card, onPress}) => {
  const { fsStyle, ...fonts } = getFont(true);
  const styles = getStyles(fonts);
  const {bhfForm} = response || {};
  const {
    cardText, formUrl, cardBoldText, cardLinkText, webViewText
  } = bhfForm || {};
  useEffect(() => {
    HotelBookingTrackingHelper.trackClickEvent(pageName, 'MI_HTL_BHF_card_shown', response);
  }, [pageName, response, bhfForm]);
  return (
    <View style={[{backgroundColor: '#fff'}, AtomicCss.marginBottom10]}>
      <View style={[styles.CardContentFull, AtomicCss.paddingBottom24]}>

        <View style={[styles.CardContent]}>
          <Image style={styles.CardIcon} source={helpIcon} />
          <Text style={[fonts.blackFontFamily, AtomicCss.blackText, fsStyle.font14]}>
            {cardBoldText}
          </Text>
        </View>

        <View style={[AtomicCss.paddingRight16, styles.marginLeft35]}>
          <Text style={[fsStyle.font14, AtomicCss.defaultText, fonts.regularFontFamily, AtomicCss.lineHeight20]}>
            {cardText}
          </Text>
        </View>

      </View>


      <View style={styles.redirectBtnWrapper}>
        <TouchableOpacity
          style={styles.redirectBtn}
          activeOpacity={0.8}
          onPress={() => onPress(formUrl, 'BHF_card', pageName, response, webViewText)}
        >
          <Text style={styles.linkBtnTxt}>{cardLinkText}</Text>
          <Image style={styles.rightArrowIcon} source={rightArrow} />
        </TouchableOpacity>
      </View>
    </View>
  );
};

const getStyles = (fonts) => {
  return StyleSheet.create({
    CardContentFull: {
      backgroundColor: colors.white,
      padding: 15,
      paddingBottom: 0
    },
    CardContent: {
      backgroundColor: colors.white,
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 16
    },
    marginLeft35: {
      marginLeft: 35
    },
    CardIcon: {
      width: 24,
      height: 24,
      marginRight: 10
    },
    redirectBtnWrapper:
    {
      ...getPlatformElevation(2),
      marginBottom: 2
    },
    redirectBtn: {
      paddingTop: 15,
      paddingBottom: 15,
      paddingLeft: 50,
      paddingRight: 35,
      justifyContent: 'space-between',
      flexDirection: 'row',
      backgroundColor: '#fff',
      alignItems: 'center',
      borderTopWidth: 1,
      borderTopColor: '#ddd'
    },
    rightArrowIcon: {
      width: 9,
      height: 13
    },
    linkBtnTxt: {
      ...fonts.font14,
      fontFamily: fonts.bold,
      color: '#008cff'
    }
  });
}

export default BHFCard;
