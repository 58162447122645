import RailsEnvVariables from './Utils/RailsEnvVariables';
import { Platform } from 'react-native';
// PWA
const pwaServerUrl = RailsEnvVariables.getPWABaseUrl();
const NODE_CB_URL = RailsEnvVariables.getNodeBaseUrl();

let cancellationServerURL;
let hotelCrossSellUrl;
const MAPI_URL = 'https://mapi.makemytrip.com/';
if (Platform.OS === 'web') {
  cancellationServerURL = '//promos.makemytrip.com/';
  hotelCrossSellUrl = MAPI_URL + 'cosmos_personalization/v2/railslisting';
} else {
  cancellationServerURL = 'https://promos.makemytrip.com/';
  hotelCrossSellUrl = 'https://cp3o.makemytrip.com/cosmos_personalization/v2/railslisting';
}
// Rail services
const AUTOCOMPETE_SERVER_URL = 'https://voyagerx.goibibo.com/';
const PDT_URL = 'https://pdt.makemytrip.com/dts/s/da/n';
const SERVER_URL_USER_SERVICE = 'https://userservice.makemytrip.com/';
export const AUTHORIZATION_KEY = 'h4nhc9jcgpAGIjp';
export const HOTELS_LANDING_DEEPLINK = 'https://www.makemytrip.com/hotels?cmp=Rail_Listing_Banner';
export const PRIVACY_POLICY_URL = 'https://www.makemytrip.com/legal/privacy_policy.html';
export const USER_AGREEMENT_URL = 'https://www.makemytrip.com/legal/user_agreement.html';
export const AUTOSUGGEST_URL = "https://ground-auto-suggest.makemytrip.com/rails/autosuggest/stations?search_query=__query__&limit=__limit__&version=v1&input_language=__language__&rails-lang-code=__langcode__";
export const HYDRA_SEGMENT_URL = "https://rails.makemytrip.com/pwa/mobile/getHydraFromPwa";
export const SEARCH_CONTEXT = 'search_context';
export const IRCTC_CREATE_ACCOUNT_URL = 'https://www.irctc.co.in/nget/profile/user-registration';
export const GMAP = 'https://www.google.com/maps/dir/?api=1';
export const GO_IMAGE_CDN_URL = 'https://go-assets.ibcdn.com/u/MMT/images/';
export const RAILS_CDN_IMAGE_PATH = 'https://imgak.mmtcdn.com/rails_cdn_rnw/static/images/';
export const IRCTC_FORM_URL = 'https://www.ws.irctc.co.in/eticketing/wsapplogin';

const SERVICES_END_POINT_URL = {
  vernacularConfig: 'pwa/mobile/vernacular/configV2',
  validateURL: 'pwa/mobile/users/validate?',
  forgotDetails: 'pwa/mobile/users/forgotDetails?',
  autoCompleteURL: 'api/v1/autosuggest/find_train_nodes_by_name/?',
  translateAutoCompleteURL: 'pwa/getStations',
  getOtpURL: 'pwa/mobile/users/getOtp/',
  verifyOTPURLGi: 'pwa/mobile/users/giVerifyOtp/',
  verifyOTPURL: 'pwa/mobile/users/verifyOtp/',
  getExclusiveSectionData: 'pwa/mobile/landing/mobileExclusiveData/',
  verifyBooking: 'pwa/mobile/booking/confirm/',
  getBookingId: 'pwa/mobile/thankyou/getBookingId/',
  getBookingIdV2: 'pwa/mobile/booking/getBookingId/v2',
  refundRules: 'pwa/mobile/refundRules/',
  refundRulesHin: 'pwa/mobile/refundRulesHin',
  refundRulesHin: 'pwa/mobile/refundRulesTam',
  insuranceTnC: 'pwa/mobile/trainsInsuranceTnC/',
  cancellationPolicyURLS: 'irctc-cancellation-policy',
  recommendCouponURL: 'pwa/mobile/coupon/recommend',
  getEncodedCommIdURL: 'pwa/mobile/getEncodedCommId',
  needHelpOptions: 'pwa/mobile/gfthelp/needhelp',
  changeUserName: 'pwa/mobile/gfthelp/changeUsername',
  whatIsCaptcha: 'pwa/mobile/gfthelp/captcha',
  feedbackFetch: 'pwa/mobile/gfthelp/feedback/fetch',
  whyCancelFetch: 'pwa/mobile/gfthelp/cancelBooking/fetch',
  feedbackPost: 'pwa/mobile/gfthelp/feedback/post',
  whyCancelPost: 'pwa/mobile/gfthelp/cancelBooking/postFeedback',
  invalidCaptcha: 'pwa/mobile/gfthelp/invalid/captcha',
  wrongCredentials: 'pwa/mobile/gfthelp/wrong/credential',
  freeCancellation: 'pwa/mobile/enquiry/cancellationPreferences',
  assuredConfirmation: 'pwa/mobile/enquiry/cnfmGuaranteePreferences',
  configStore: 'get/configKey',
  productList: 'pwa/mobile/enquiry/getProductList',
};
const PARTIAL_URL = {
  validateURL: 'pwa/partial/listing/validate?',
  forgotDetails: 'pwa/partial/listing/forgotDetails?',
  autoCompleteURL: 'api/v2/trains_search/find_node_by_name/?',
  getOtpURL: 'pwa/users/getOtp/',
  verifyOTPURL: 'pwa/users/gi_verifyOtp/',
  userDetailsURL: 'ext/web/pwa/user/details',
  userUpdateURL: 'ext/web/pwa/user/update',
  createUserURL: 'ext/web/pwa/user/create/false',
  searchTrainUrl: 'pwa/mobile/search',
  searchUrlNewListing: 'pwa/mobile/searchWithAvailAndRecommendation',
  trainAvailabilityUrl: 'pwa/mobile/enquiry/availability',
  nearbyDatesConfirmProbablity: 'pwa/mobile/enquiry/confirmationProbability/nearbyDate',
  railsLandingCardOrders: 'pwa/mobile/enquiry/section/order',
  railsLandingReturnTicket : 'pwa/mobile/postBooking/returnTicketPersuasion',
  railsLandingRecentBooking : 'pwa/mobile/postBooking/recentBookings',
  boardingStationUrl: 'pwa/mobile/enquiry/boardingStations',
  trainReviewUrl: 'pwa/mobile/booking/review',
  refundOptionUrl: 'pwa/mobile/refundOption/',
  alternateAvailabilityUrl: 'pwa/mobile/search/alternateAvailability',
  failureReasonUrl: 'pwa/mobile/failureReasons/',
  ppfReview: 'pwa/mobile/booking/details/',
  trainBookingStatus: 'pwa/mobile/booking/details/fetch',
  generateVoucher: 'pwa/mobile/booking/hotel/generateVoucher',
  getActiveVoucherIfEligible: 'pwa/mobile/booking/hotel/getVoucher',
  securityOccupationList: 'pwa/mobile/users/getUserRegistrationCodeValueList',
  createAccount: 'pwa/mobile/users/register',
  userNameAvailability: 'pwa/mobile/users/checkUserAvailability',
  getAddressFromPinCode: 'pwa/mobile/users/getAddressByPin',
  ugcGetFeedbackOptions: 'pwa/mobile/postbooking/journeyfeedback/getOptions',
  ugcSubmitFeedback: 'pwa/mobile/postbooking/journeyfeedback/submit',
  confirmationPrediction: 'pwa/mobile/enquiry/confirmationChance',
  confirmationProbablity: 'pwa/mobile/enquiry/confirmationProbability',
  failedBookingDetails: 'pwa/mobile/failedBooking/getDetails',
  retryBookingUrl: 'pwa/mobile/failedBooking/retry',
  getBookingRetryDetailsUrl: 'pwa/mobile/failedBooking/getBookingRetryDetails?bookingId=',
  fullUserDetails: 'pwa/mobile/api/fullUserDetails',
  validateCoupon: 'pwa/mobile/coupon/apply',
  confirmedOptions: 'pwa/mobile/search/confirmedOptions',
  detailFromLocus: 'pwa/mobile/search/getLobId',
  locusFromStation: 'pwa/mobile/search/getLocusId',
  getUserSession:'pwa/mobile/users/getCaptcha',
  webPageGrafanaMetric:'pwa/mobile/search/webPageGrafanaMetric',
  logFrontEndMetric: 'pwa/mobile/search/logFrontEndMetric',
  connectedTraveller: '/api/connectedTravel/suggestOptions',
  routeAvailability: 'v1/calendar/route_availability',
  getIrctcUserName: '/api/user/getIrctcUserName',
  updateIrctcUserName: '/api/user/updateIrctcUserName',
  inventoryAvailabilities: '/api/inventoryAvailabilities',
};

const NODE_ENDPOINT = {
  tbsWithAvailabilityAndRecommendation: '/api/tbsWithAvailabilityAndRecommendation',
};

const SERVER_URL = `${pwaServerUrl}/`;
const CANCELLATION_SERVER_URL = cancellationServerURL;
const ENDPOINT_URL = SERVICES_END_POINT_URL;
const CONFIG_STORE_URL = MAPI_URL + ENDPOINT_URL.configStore;
const ALTERNATE_AVAILABILITY_URL = SERVER_URL + PARTIAL_URL.alternateAvailabilityUrl;
const RETRY_BOOKING_URL = SERVER_URL + PARTIAL_URL.retryBookingUrl;
const FAILED_BOOKING_DETAILS = SERVER_URL + PARTIAL_URL.failedBookingDetails;
const VERNACULAR_CONFIG = SERVER_URL + ENDPOINT_URL.vernacularConfig;
const VALIDATE_USER = SERVER_URL + ENDPOINT_URL.validateURL;
const RETRIEVE_USER_ID = SERVER_URL + ENDPOINT_URL.forgotDetails;
const RETRIEVE_PASSWORD = SERVER_URL + ENDPOINT_URL.forgotDetails;
const AUTO_COMPLETE = AUTOCOMPETE_SERVER_URL + ENDPOINT_URL.autoCompleteURL;
const TRANSLATE_AUTO_COMPLETE = SERVER_URL + ENDPOINT_URL.translateAutoCompleteURL;
const GET_OTP = SERVER_URL + ENDPOINT_URL.getOtpURL;
const VERIFY_OTP = SERVER_URL + ENDPOINT_URL.verifyOTPURL;
const GET_EXCLUSIVE_SECTION_DATA = SERVER_URL + ENDPOINT_URL.getExclusiveSectionData;
const VERIFY_BOOKING = SERVER_URL + ENDPOINT_URL.verifyBooking;
const GET_BOOKING_ID = SERVER_URL + ENDPOINT_URL.getBookingId;
const GET_BOOKING_ID_V2 = SERVER_URL + ENDPOINT_URL.getBookingIdV2;
const REFUND_RULES = SERVER_URL + ENDPOINT_URL.refundRules;
const REFUND_RULES_HIN = SERVER_URL + ENDPOINT_URL.refundRulesHin;
const CANCELLATION_POLICY_URL = CANCELLATION_SERVER_URL + ENDPOINT_URL.cancellationPolicyURLS;
const INSURANCE_TERMS_AND_CONDITIONS = SERVER_URL + ENDPOINT_URL.insuranceTnC;
const RECOMMEND_COUPON_URL = SERVER_URL + ENDPOINT_URL.recommendCouponURL;
const PRODUCT_MARKETTING_URL = SERVER_URL + ENDPOINT_URL.productList;
const GET_FULL_USER_DETAILS_URL = SERVER_URL + PARTIAL_URL.fullUserDetails;
const GET_USER_DETAILS_URL = SERVER_URL_USER_SERVICE + PARTIAL_URL.userDetailsURL;
const UPDATE_USER_DETAILS_URL = SERVER_URL_USER_SERVICE + PARTIAL_URL.userUpdateURL;
const CREATE_USER_URL = SERVER_URL_USER_SERVICE + PARTIAL_URL.createUserURL;
const TRAIN_SEARCH_URL = SERVER_URL + PARTIAL_URL.searchTrainUrl;
const TRAIN_SEARCH_URL_NEWLISTING = SERVER_URL + PARTIAL_URL.searchUrlNewListing;
const TRAIN_AVAILABILITY_URL = SERVER_URL + PARTIAL_URL.trainAvailabilityUrl;
const TRAIN_NEARBY_PROBABLITY_URL = SERVER_URL + PARTIAL_URL.nearbyDatesConfirmProbablity;
const TRAIN_LANDING_CARDS_ORDER_URL = SERVER_URL + PARTIAL_URL.railsLandingCardOrders;
const TRAIN_LANDING_RETURN_TICKET_URL = SERVER_URL + PARTIAL_URL.railsLandingReturnTicket;
const TRAIN_LANDING_RECENT_BOOKING_URL = SERVER_URL + PARTIAL_URL.railsLandingRecentBooking;
const BOARDING_STATIONS_URL = SERVER_URL + PARTIAL_URL.boardingStationUrl;
const BOOKING_REVIEW_URL = SERVER_URL + PARTIAL_URL.trainReviewUrl;
const REFUND_OPTION_URL = SERVER_URL + PARTIAL_URL.refundOptionUrl;
const FAILURE_REASON_URL = SERVER_URL + PARTIAL_URL.failureReasonUrl;
const PPF_REVIEW = SERVER_URL + PARTIAL_URL.ppfReview;
const TRAINS_BOOKING_STATUS = SERVER_URL + PARTIAL_URL.trainBookingStatus;
const GET_ACTIVE_VOUCHER_IF_ELIGIBLE = SERVER_URL + PARTIAL_URL.getActiveVoucherIfEligible;
const GENERATE_VOUCHER = SERVER_URL + PARTIAL_URL.generateVoucher;
const CONNECTED_TRAVELLER = NODE_CB_URL + PARTIAL_URL.connectedTraveller;
const VALIDATE_COUPON_URL = SERVER_URL + PARTIAL_URL.validateCoupon;
const SECURITY_OCCUPATION_LIST = SERVER_URL + PARTIAL_URL.securityOccupationList;
const CREATE_NEW_ACCOUNT = SERVER_URL + PARTIAL_URL.createAccount;
const USER_NAME_AVAILABILITY = SERVER_URL + PARTIAL_URL.userNameAvailability;
const GET_ADDRESS_FROM_PINCODE = SERVER_URL + PARTIAL_URL.getAddressFromPinCode;
const UGC_GET_FEEDBACK_OPTIONS = SERVER_URL + PARTIAL_URL.ugcGetFeedbackOptions;
const UGC_SUBMIT_FEEDBACK = SERVER_URL + PARTIAL_URL.ugcSubmitFeedback;
const CONFIRMATIOON_PREDICTION = `${RailsEnvVariables.getPWABaseUrl()}/${
  PARTIAL_URL.confirmationPrediction
}`;
const CONFIRMATIOON_PROBABLITY = SERVER_URL + PARTIAL_URL.confirmationProbablity;
const CONFIRMED_OPTIONS = SERVER_URL + PARTIAL_URL.confirmedOptions;
const GET_ENCODED_COMMID = SERVER_URL + SERVICES_END_POINT_URL.getEncodedCommIdURL;
const NEED_HELP_OPTIONS = SERVER_URL + SERVICES_END_POINT_URL.needHelpOptions;
const CHANGE_USER_NAME = SERVER_URL + SERVICES_END_POINT_URL.changeUserName;
const WHAT_IS_CAPTCHA = SERVER_URL + SERVICES_END_POINT_URL.whatIsCaptcha;
const FEEDBACK_FETCH = SERVER_URL + SERVICES_END_POINT_URL.feedbackFetch;
const WHY_CANCEL_FETCH = SERVER_URL + SERVICES_END_POINT_URL.whyCancelFetch;
const FEEDBACK_POST = SERVER_URL + SERVICES_END_POINT_URL.feedbackPost;
const WHY_CANCEL_POST = SERVER_URL + SERVICES_END_POINT_URL.whyCancelPost;
const INVALID_CAPTCHA = SERVER_URL + SERVICES_END_POINT_URL.invalidCaptcha;
const WRONG_CREDENTIALS = SERVER_URL + SERVICES_END_POINT_URL.wrongCredentials;
const FREE_CANCELLATION_URL = SERVER_URL + SERVICES_END_POINT_URL.freeCancellation;
const ASSURED_CONFIRMATION_URL = SERVER_URL + SERVICES_END_POINT_URL.assuredConfirmation;
const GET_STATION_DETAILS_FROM_LOCUS = SERVER_URL + PARTIAL_URL.detailFromLocus;
const GET_LOCUS_FROM_STATION_DETAILS = SERVER_URL + PARTIAL_URL.locusFromStation;
const GET_BOOKING_RETRY_DETAILS = SERVER_URL + PARTIAL_URL.getBookingRetryDetailsUrl;
const GET_USER_SESSION =SERVER_URL + PARTIAL_URL.getUserSession;
const GET_RAILS_LISTING_TBS = NODE_CB_URL + NODE_ENDPOINT.tbsWithAvailabilityAndRecommendation;
const GRAFANA_METRIC_URL = SERVER_URL + PARTIAL_URL.webPageGrafanaMetric;
const FE_METRIC_URL = SERVER_URL + PARTIAL_URL.logFrontEndMetric;
const ROUTE_AVAILABILITY = SERVER_URL + PARTIAL_URL.routeAvailability;
const UPDATE_IRCTC_USER_ID = NODE_CB_URL + PARTIAL_URL.updateIrctcUserName;
const GET_IRCTC_USER_ID = NODE_CB_URL + PARTIAL_URL.getIrctcUserName;
const INVENTORY_AVAILABILITIES = NODE_CB_URL + PARTIAL_URL.inventoryAvailabilities;

export const railsConfig = {
  DEBUG_DANGEROUSLY: false,
  vernacularConfig: VERNACULAR_CONFIG,
  validateUserId: VALIDATE_USER,
  retrieveUserId: RETRIEVE_USER_ID,
  retrievePassword: RETRIEVE_PASSWORD,
  autoCompleteURL: AUTO_COMPLETE,
  translateAutoCompleteURL: TRANSLATE_AUTO_COMPLETE,
  getOtp: GET_OTP,
  verifyOtp: VERIFY_OTP,
  fetchFullUserDetailsURL: GET_FULL_USER_DETAILS_URL,
  fetchUserDetailsURL: GET_USER_DETAILS_URL,
  updateUserDetailsURL: UPDATE_USER_DETAILS_URL,
  createUserURL: CREATE_USER_URL,
  trainSearchUrl: TRAIN_SEARCH_URL,
  confirmedOptions: CONFIRMED_OPTIONS,
  searchUrlNewListing: TRAIN_SEARCH_URL_NEWLISTING,
  trainAvailabilityUrl: TRAIN_AVAILABILITY_URL,
  neabyDatesProbablityUrl: TRAIN_NEARBY_PROBABLITY_URL,
  railsLandingCardOrderUrl: TRAIN_LANDING_CARDS_ORDER_URL,
  railsLandingReturnTicketUrl : TRAIN_LANDING_RETURN_TICKET_URL,
  railsLandingRecentBookingUrl : TRAIN_LANDING_RECENT_BOOKING_URL,
  boardingStationUrl: BOARDING_STATIONS_URL,
  bookingReviewUrl: BOOKING_REVIEW_URL,
  getExclusiveSectionData: GET_EXCLUSIVE_SECTION_DATA,
  verifyBooking: VERIFY_BOOKING,
  getBookingId: GET_BOOKING_ID,
  getBookingIdV2: GET_BOOKING_ID_V2,
  refundRules: REFUND_RULES,
  refundRulesHin: REFUND_RULES_HIN,
  cancellationURLS: CANCELLATION_POLICY_URL,
  insuranceTnC: INSURANCE_TERMS_AND_CONDITIONS,
  refundOptionUrl: REFUND_OPTION_URL,
  failureReasonUrl: FAILURE_REASON_URL,
  ppfReview: PPF_REVIEW,
  trainBookingStatus: TRAINS_BOOKING_STATUS,
  getActiveVoucherIfEligible: GET_ACTIVE_VOUCHER_IF_ELIGIBLE,
  generateVoucher: GENERATE_VOUCHER,
  connectedTraveller:CONNECTED_TRAVELLER,
  hotelsCrossSellUrl: hotelCrossSellUrl,
  securityOccupationList: SECURITY_OCCUPATION_LIST,
  createAccount: CREATE_NEW_ACCOUNT,
  userNameAvailability: USER_NAME_AVAILABILITY,
  getAddressFromPinCode: GET_ADDRESS_FROM_PINCODE,
  ugcGetFeedbackOptions: UGC_GET_FEEDBACK_OPTIONS,
  ugcSubmitFeedback: UGC_SUBMIT_FEEDBACK,
  confirmationPrediction: CONFIRMATIOON_PREDICTION,
  confirmationProbablity: CONFIRMATIOON_PROBABLITY,
  failedBookingDetails: FAILED_BOOKING_DETAILS,
  retryUrl: RETRY_BOOKING_URL,
  alternateAvailabilityUrl: ALTERNATE_AVAILABILITY_URL,
  validateCoupon: VALIDATE_COUPON_URL,
  pdtUrl: PDT_URL,
  recommendCouponURL: RECOMMEND_COUPON_URL,
  getEncodedCommIdURL: GET_ENCODED_COMMID,
  needHelpOptionsURL: NEED_HELP_OPTIONS,
  changeUserNameURL: CHANGE_USER_NAME,
  whatIsCaptchaURL: WHAT_IS_CAPTCHA,
  feedbackFetchURL: FEEDBACK_FETCH,
  whyCancelFetchURL: WHY_CANCEL_FETCH,
  feedbackPostURL: FEEDBACK_POST,
  whyCancelPostURL: WHY_CANCEL_POST,
  invalidCaptcha: INVALID_CAPTCHA,
  wrongCredentials: WRONG_CREDENTIALS,
  freeCancellationUrl: FREE_CANCELLATION_URL,
  assuredConfirmationUrl: ASSURED_CONFIRMATION_URL,
  detailFromLocusUrl: GET_STATION_DETAILS_FROM_LOCUS,
  locusFromStation: GET_LOCUS_FROM_STATION_DETAILS,
  configStoreUrl: CONFIG_STORE_URL,
  productListURL: PRODUCT_MARKETTING_URL,
  getBookingRetryDetailsURL: GET_BOOKING_RETRY_DETAILS,
  getUserSession: GET_USER_SESSION,
  getTBSRailsListing:GET_RAILS_LISTING_TBS,
  grafanaMetricUrl:GRAFANA_METRIC_URL,
  feMetricUrl: FE_METRIC_URL,
  routeAvailability: ROUTE_AVAILABILITY,
  updateIrctcUserID: UPDATE_IRCTC_USER_ID,
  getIrctcUserName: GET_IRCTC_USER_ID,
  inventoryAvailabilities: INVENTORY_AVAILABILITIES,
};
export default railsConfig;
