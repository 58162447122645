import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
const styles = {
  customerStoryWrapper: {
    marginBottom: 12,
    backgroundColor: '#fff',
    paddingTop: 20,
    paddingBottom: 15,
    ...getPlatformElevation(1),
  },
  darkGreen: {
    color: '#1a7971',
  },
  paddingHz20: {
    paddingHorizontal: 20,
  },
  storySlideWrapper: {
    flexDirection: 'row',
    paddingRight: 20,
  },
  storyWrapper: {
    borderRadius: 8,
    marginBottom: 12,
    backgroundColor: '#fff',
    marginLeft: 20,
    padding: 20,
    borderWidth: 1,
    borderColor: '#fafafa',
    ...getPlatformElevation(3),
    width: 220,
  },
  quoteIconStyle: {
    width: 19,
    height: 16,
    marginLeft: 20,
  },
};

export default styles;
