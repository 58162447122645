import { isCorporateUser } from '../helpers/userSession';

export const LOB_NAME = 'domestic cabs';

export const PAGE_NAME_PREFIX = {
  LANDING: 'mob:landing:domestic cabs',
  DEFAULT: 'mob:funnel:domestic cabs',
};

export const PAGE_NAMES = {
  CROSS_SELL_CABS: 'cross_sell_cabs',
};

export const CHANNEL_NAME = {
  LANDING: 'landing',
  DEFAULT: 'mob domestic cabs funnel',
};

export let VARIABLE_FACTORS = {
  CORPORATE_SUFFIX: '',
};

export const initOmniture = async () => {
  const isCorporateProfile = await isCorporateUser();
  VARIABLE_FACTORS.CORPORATE_SUFFIX = isCorporateProfile ? '|corporate' : '';
};

export const EVENTS = {
  CROSS_SELL: {
    PICKUP_ADDRESS_CLICK: 'Mob_Cabs_MyTrips_Pickup_Address_Clicked',
    DROP_ADDRESS_CLICK: 'Mob_Cabs_MyTrips_Drop_Address_Clicked',
    PICKUP_ADDRESS_SELECTED: 'Mob_Cabs_MyTrips_Pickup_Address_Selected',
    DROP_ADDRESS_SELECTED: 'Mob_Cabs_MyTrips_Drop_Address_Selected',
    CHECK_FARE_CLICK: 'Mob_Cabs_MyTrips_Check_Fare_Click',
    REDIRECTION_URL_CLICKED: 'Mob_Cabs_MyTrips_Deeplink_Clicked',
    PICKUP_ADDRESS_EMPTY_ERROR: 'Mob_Cabs_MyTrips_Empty_Src_Error',
    DESTINATION_ADDRESS_EMPTY_ERROR: 'Mob_Cabs_MyTrips_Empty_Dest_Error',
    NO_CABS_FOUND_ERROR: 'Mob_Cabs_MyTrips_No_Cabs_Found_Error',
    TRIP_TYPE_OW_SEARCHED: 'Mob_Cabs_MyTrips_OW_Search',
    TRIP_TYPE_AT_SEARCHED: 'Mob_Cabs_MyTrips_AT_Search',
    TRIP_TYPE_INTRACITY_SEARCHED: 'Mob_Cabs_MyTrips_Intracity_Search',
  },
};
