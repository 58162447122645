import isEmpty from 'lodash/isEmpty';
import { useTheme } from '../../../../../theme/theme.context';
import React from 'react';
import { View, Text } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import createStyles from "./PaxStatusCardCss";
import StatusList from "./StatusListCard"
import { getFont } from '../../../../../PostSalesStyles';
import { getCurrentPassengerStatus } from '../../../../Utils/TgsBookingDetailUtil';
const PaxStatusCard = ({ passengerDetailsInfo,fetchedPassengerDetails,showPnrStatusLodar,travellerCard }) => {
    const { passengerHeading, statusHeading, passengerList } = passengerDetailsInfo || {};
    const fonts = getFont();
    const {psTheme : theme} = useTheme();
    const styles = createStyles(theme,fonts);
    return (
        <View style={[AtomicCss.paddingHz10,AtomicCss.marginBottom10,AtomicCss.marginLeft10]}>
            <View style={[AtomicCss.flexRow,AtomicCss.alignCenter, AtomicCss.marginBottom16]}>
                {!!passengerHeading && <Text style={styles.headingStatusTxt}>{passengerHeading}</Text>}
                {!!statusHeading && <Text style={[styles.headingStatusTxt, AtomicCss.textCenter]}>{statusHeading}</Text>}
            </View>
            {!isEmpty(passengerList) && passengerList.map((passenger, index) => {
                return (
                    <StatusList
                        passenger={passenger}
                        index={index}
                        isLastElement={index === passengerList.length - 1}
                        currentStatus={getCurrentPassengerStatus(fetchedPassengerDetails,passenger)}
                        showPnrStatusLodar={showPnrStatusLodar}
                        travellerCard={travellerCard}
                    />
                )
            })}

        </View>
    )
}
export default PaxStatusCard;