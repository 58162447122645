import React, {useEffect, useState, useRef } from 'react';
import {View, Text, ScrollView} from 'react-native';

import styles, { SLIDE_HEIGHT } from './wheelPickerCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';

const WheelPickerRow = (props) => {
  const [activeSlide, setActiveSlide] = useState(props.activeSlide || 0);
  const wheel = useRef();
  const { list, callback, type, value, onChange } = props;

  useEffect(() => {
    setTimeout(() => {
      focusActiveSlide(props.activeSlide || 0);
    }, 1)
  }, [1]);


  const renderCols = (list = []) => (
    list.map((item, index) => {
      const isActive = index === activeSlide;
      const activeSuffix = isActive ? 'Active' : '';
      return (
        <View key={index} style={[styles.pickerCol, styles[`pickerCol${activeSuffix}`]]}>
          <Text style={[AtomicCss.lightText, AtomicCss.textCenter, AtomicCss.boldFont, AtomicCss.font16, styles[`pickerColText${activeSuffix}`]]}>
            {item}
          </Text>
        </View>
      )
    })
  )

  const focusActiveSlide = (index) => {
    wheel.current.scrollTo({ x: 0, y: index * SLIDE_HEIGHT, animated: true });
      onChange(list[activeSlide]);
    callback && callback(type, list[activeSlide]);
  }

  const onScroll = (event) => {
      setActiveSlide(Math.round(event.nativeEvent.contentOffset.y / SLIDE_HEIGHT));
      onChange(list[Math.round(event.nativeEvent.contentOffset.y / SLIDE_HEIGHT)]);
  }

  return (
    <View style={{ flex: 1, ...props.style }}>
      <View style={styles.activeBlock} />
      <ScrollView
        scrollEventThrottle={400}
        onScrollEndDrag={() => focusActiveSlide(activeSlide)}
        onScroll={onScroll}
        ref={wheel}
        showsVerticalScrollIndicator={false}
        decelerationRate="fast"
        bounces
      >
        <View style={styles.pickerCol} />
        {renderCols(list)}
        <View style={styles.pickerCol} />
      </ScrollView>
    </View>
  )
}

export default WheelPickerRow;
