import React, { useCallback, useEffect, useRef } from 'react';
import { Animated, Platform, KeyboardAvoidingView, View, Easing, StyleSheet, TouchableWithoutFeedback, BackHandler } from 'react-native';
import {colors} from '@mmt/legacy-commons/Styles/globalStyles';

/* 
    Design URL: https://www.figma.com/proto/ER9EZ9T0FovoVLCXTysX7o/2B.-Hotels---Mobile?node-id=8363%3A4112&scaling=min-zoom&page-id=4595%3A1305
*/
const CommonBottomOverlayMessage = React.forwardRef(({ isBorderRadius, content, isForceClose, isCancellable = true, overlayContentStyle, dismiss, customStyle, contentMaxHeight, overlayWrapperStyle={}, keyboardAvoidBehavior=null, initialBottomPosition=-1000 ,extraOverlayContentStyle={}}, ref) => {
    const isRadius = isBorderRadius === true ? 'isBorderRadius' : '';
    const bottomAnim = useRef(new Animated.Value(initialBottomPosition)).current;
    const fadeAnim = useRef(new Animated.Value(0)).current;
    
    const closeAnimation = () => {
        Animated.parallel([
            Animated.timing(bottomAnim, {
                toValue: -1000,
                duration: 700,
                useNativeDriver: false,
            }),
            Animated.timing(fadeAnim, {
                toValue: 0,
                duration: 400,
                easing: Easing.linear,
                useNativeDriver: false,
            })
        ]).start();
    }

    useEffect(() => {
        const onBackPress = () => {
            onDismiss && onDismiss();
            return true;
        }
        BackHandler.addEventListener('hardwareBackPress', onBackPress);
        return () => {BackHandler.removeEventListener('hardwareBackPress', onBackPress)}
    }, [])

    useEffect(() => {
        Animated.parallel([
            Animated.timing(bottomAnim, {
                toValue: 0,
                duration: 700,
                easing: Easing.linear,
                useNativeDriver: false
            }),
            Animated.timing(fadeAnim, {
                toValue: 0.7,
                duration: 400,
                easing: Easing.linear,
                useNativeDriver: false
            })
        ]).start();

    }, [bottomAnim]);

    useEffect(() => {
        isForceClose && closeAnimation();
    }, [isForceClose]);

    const onDismiss = useCallback(() => {
        if(!isCancellable) {
            return;
        }
        closeAnimation();
        setTimeout(()=> { dismiss && dismiss(); }, 100);
    }, [dismiss]);

    useEffect(() => {
        if(ref) {
            ref.current = {
                closeOverlay: onDismiss
            };
        }
    }, []);

    return (
        <KeyboardAvoidingView style={[styles.overlayWrapper, overlayWrapperStyle]} behavior={Platform.OS === "ios" ? "padding" : keyboardAvoidBehavior}>
            <TouchableWithoutFeedback onPress={onDismiss}>
                <Animated.View style={[styles.overlay, { opacity: fadeAnim }]} />
            </TouchableWithoutFeedback>
            <Animated.View style={[styles.animatedWrapper, { bottom: bottomAnim }, contentMaxHeight ? { maxHeight: contentMaxHeight } : {}, customStyle?customStyle:{}]} >
                <View style={overlayContentStyle || [styles.overlayContent, styles[isRadius],extraOverlayContentStyle, customStyle?customStyle:{}]}>
                    {content}
                </View>
            </Animated.View>
        </KeyboardAvoidingView>
    );
});

const styles =StyleSheet.create({
  overlayWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    elevation: 10,
    zIndex: 100,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    flexDirection: 'row',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
    height: '100%',
    width: '100%',
    zIndex: 1
  },
  overlayContent: {
    backgroundColor: colors.white,
    padding: 20,
    paddingBottom: Platform.OS === 'ios' ? 0 : 20,
    position: 'relative',
    zIndex: 200,
    width: '100%'
  },
  isBorderRadius:{
    borderTopLeftRadius:8,
    borderTopRightRadius:8,
  },
  animatedWrapper: {
    flex:1,
    zIndex: 2,
    maxHeight:'80%'
  }
});

export default CommonBottomOverlayMessage;
