import { StyleSheet} from 'react-native';

const styles = StyleSheet.create({
  addBookingWrapper: {
    flexDirection: 'row',
  },
  addBookingTopWrapper: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  plusIcon: {
    width: 20,
    height: 20,
  },
});

export default styles;

