import React, { useRef, useState } from "react";
import { View, Text, DeviceEventEmitter } from 'react-native';
import createStyles from './TravellerProofCss';
import Seperator from '../../../../../../../Common/CardLayoutSeperator';
import SpecialClaimAddAttachmentModule from '@mmt/legacy-commons/Native/SpecialClaimAddAttachmentModule';
import { getStaticData } from '../../../../../../../staticData/staticData';
import { getFont } from '../../../../../../../PostSalesStyles';
import { useTheme } from '../../../../../../../theme/theme.context';
import { UPLOAD_STATES } from '../../../../constant';
import { uploadFile } from '../../../../data/api';
import UploadProofCard from "apps/post-sales/src/flights/modules/SpecialClaimNew/pages/UploadDocuments/components/UploadProofCard";

const eventKey = 'special_claim_review_page';

const TravellerProofCard = ({
  info,
  count,
  id,
  updateAttachments,
  onDeleteAttachment: onDelete,
  mandatoryText,
  bookingId,
}) => {
  const staticData = getStaticData();
  const imageDataListener = useRef(null);
  const { nameCorrectionTexts } = staticData || {};
  const { fullNameOld, gender } = info;

  const fonts = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);

  const [documents, setDocuments] = useState([]);

  const doFileUpload = async (data) => {
    data.status = UPLOAD_STATES.INPROGRESS;
    setDocuments([{ ...data, status: UPLOAD_STATES.INPROGRESS }]);
    const result = await uploadFile(data, bookingId);
    if (result) {
      const { fileName, documentID, url } = result;
      const fileUrls = {
        fileName,
        documentId: documentID,
        url,
      };
      updateAttachments(id, { fileUrls });
      setDocuments([{ ...data, status: UPLOAD_STATES.SUCCESS }]);
    } else {
      setDocuments([{ ...data, status: UPLOAD_STATES.ERROR }]);
    }
  }
  const onFileChanged = async (uploadFileList) => {
    await doFileUpload(uploadFileList[0]);
  };

  const onImageDataListener = async (data) => {
    if (imageDataListener.current) {
      imageDataListener.current.remove();
    }
    await doFileUpload(data);
  };

  const onAddAttachment = async () => {
    try {
      imageDataListener.current = DeviceEventEmitter.addListener(eventKey, onImageDataListener);
      SpecialClaimAddAttachmentModule.onButtonClicked();
    } catch (error) {
      setDocuments([{ status: UPLOAD_STATES.ERROR }]);
    }
  };

  const onDeleteAttachment = async (document) => {
    try {
      const data = Array.isArray(document) ? document[0] : document;
      if (data.status == UPLOAD_STATES.ERROR) {
        setDocuments([{ ...data, status: UPLOAD_STATES.INPROGRESS }]);
        const result = await uploadFile(data);
        if (result) {
          const { fileName, documentID, url } = result;
          const fileUrls = {
            fileName,
            documentId: documentID,
            url,
          };
          updateAttachments(id, { fileUrls });
          setDocuments([{ ...data, status: UPLOAD_STATES.SUCCESS }]);
        } else {
          setDocuments([{ ...data, status: UPLOAD_STATES.ERROR }]);
        }
      } else {
        setDocuments([]);
        onDelete(id);
      }
    } catch (error) {}
  };

  return (
    <View style={[styles.cardWrapper]} key={id}>
      <View style={styles.checkAllWrapper}>
        <View style={[styles.checkAllWrapperInner]}>
          <View style={styles.travellerProofHeader}>
            <View>
              <View style={styles.destRow}>
                {!!fullNameOld && <Text style={styles.paxText}>{fullNameOld}</Text>}
              </View>
              <Text style={styles.smallText}>
                {nameCorrectionTexts.travellerText} {count} , {gender}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.infoTag}>
          <View style={styles.infoWrapper}>
            {!!mandatoryText && <Text style={styles.infoText}>{mandatoryText}</Text>}
          </View>
        </View>
      </View>
      <View style={styles.seperatorWrapper}>
        <Seperator />
      </View>
      <View style={styles.uploadWrapper}>
        <View style={styles.uploadWrapper}>
            <UploadProofCard
              key={id}
              uploadProofData={[
                {
                  title: nameCorrectionTexts.attachProof,
                  subtitle: [nameCorrectionTexts.supportedFilesText],
                },
              ]}
              uploadText={nameCorrectionTexts.upload}
              documents={documents}
              onAddAttachment={onAddAttachment}
              onDeleteAttachment={onDeleteAttachment}
              onAddAttachmentWeb={onFileChanged}
              retryText={nameCorrectionTexts.retry}
              errorText={nameCorrectionTexts.uploadFailed}
            />
        </View>
      </View>
    </View>
  );
};
export default TravellerProofCard;
