import {
  actionTypes,
  listingActionTypes,
  responseState,
  rtActionTypes,
  steps,
} from '../../constants';

const initialState = {
  dateChangeResponse: {},
  listingResponse: {},
  dateChangeState: responseState.RESPONSE_IS_LOADING,
  activeStep: steps.SELECT_DATES,
};

const dateChangeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RESPONSE_LOADING: {
      return {
        dateChangeState: responseState.RESPONSE_IS_LOADING,
      };
    }
    case actionTypes.RESPONSE_FETCHED: {
      return {
        dateChangeState: responseState.RESPONSE_IS_FETCHED,
        dateChangeResponse: action.dateChangeResponse,
      };
    }
    case actionTypes.RESPONSE_FAILED: {
      return {
        dateChangeState: responseState.RESPONSE_IS_FAILED,
      };
    }
    case actionTypes.PREVIEW_RESPONSE_FAILED: {
      return {
        dateChangeState: responseState.PREVIEW_RESPONSE_FAILED,
        errorPageData: action.errorPageData,
      };
    }
    case actionTypes.LISTING_RESPONSE_FAILED_NEW: {
      return {
        dateChangeState: responseState.LISTING_RESPONSE_FAILED_NEW,
        errorPageData: action.errorPageData,
      };
    }
    case actionTypes.PROCEED_ENABLED: {
      return {
        dateChangeState: responseState.PROCEED_ENABLED,
      };
    }
    case listingActionTypes.LISTING_RESPONSE_FETCHED: {
      return {
        dateChangeState: responseState.LISTING_RESPONSE_FETCHED,
        listingResponse: action.listingResponse,
      };
    }
    case listingActionTypes.LISTING_RESPONSE_FAILED: {
      return {
        dateChangeState: responseState.LISTING_RESPONSE_FAILED,
      };
    }
    case listingActionTypes.LISTING_RESPONSE_ERROR_CLOSE: {
      return {
        dateChangeState: responseState.LISTING_RESPONSE_ERROR_CLOSE,
      };
    }
    case listingActionTypes.LISTING_RESPONSE_ERROR_OPEN: {
      return {
        dateChangeState: responseState.LISTING_RESPONSE_ERROR_OPEN,
      };
    }
    case listingActionTypes.LISTING_RESPONSE_ERROR_LOADER: {
      return {
        dateChangeState: responseState.LISTING_RESPONSE_ERROR_LOADER,
      };
    }
    case rtActionTypes.RT_DATE_ERROR: {
      return {
        dateChangeState: responseState.RT_DATE_ERROR,
      };
    }
    default:
      return state;
  }
};

export default dateChangeReducer;
