import React, { useState } from 'react';
import { View, Text, TouchableOpacity, Platform } from 'react-native';
import getStyles from './choosePaymentModeCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import Button from '../../../../Common/Button';
import Actions from '../../../../navigation/postSalesNavigation';
import HotelBookingTrackingHelper from '../../../HotelBookingTrackingHelper';
import { getStaticData } from '../../../../staticData/staticData';
import { setWebViewAuth } from '../../../../utils/PostSaleUtil';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import { WHITE_LEFT_ARROW_ICON } from '../../../HotelImageConstants';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import { isEasyPayNativeFlowEnabled } from '../../../../Common/commonUtil';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
const PAYMENT_MODE = {
    CREDIT_CARD: 0,
    OTHER: 1,
}

const ChoosePaymentMode = ({ response, pageName }) => {
    const { fsStyle, ...fonts } = getFont(true);
    const styles = getStyles(fonts);
    const makePaymentModeList = () => {
        const paymentModeList = [];
        paymentModeList.push({
            title: isUPIBooking ? staticData.upiText : staticData.creditCardText,
            subTitle: isUPIBooking ? null : bnplCardChargeInfo.bankName,
            extraInfo: bnplCardChargeInfo.maskedCardNo,
            otherInfo: bnplCardChargeInfo.cvvMessage,
            type: PAYMENT_MODE.CREDIT_CARD,
        });
        paymentModeList.push({
            title: staticData.otherPaymentOptionsText,
            subTitle: bnplCardChargeInfo.otherPaymodesText,
            type: PAYMENT_MODE.OTHER,
        })
        return paymentModeList;
    }

    const [activePaymentMode, setActivePaymentMode] = useState(PAYMENT_MODE.CREDIT_CARD);
    const staticData = getStaticData();
    const { paymentBreakup: { bnplCardChargeInfo }, isUPIBooking } = response;
    const paymentModeList = makePaymentModeList();

    const handleClick = async () => {
        switch (activePaymentMode) {
            case PAYMENT_MODE.CREDIT_CARD:
                HotelBookingTrackingHelper.trackClickEvent(pageName, 'Pay_Same_Mode_Clicked', response);
                Actions.pahxChargeCard({
                    response,
                    pageName
                });
                break;
            case PAYMENT_MODE.OTHER:
                HotelBookingTrackingHelper.trackClickEvent(pageName, 'Pay_Different_Mode_Clicked', response);
                const userInfo = await HotelBookingModule.getRequestHeader(response.bookingID, `BNPL_PAYMENT_${staticData.payNowText}`);
                if(Platform.OS === 'web'){
                    location.href = bnplCardChargeInfo.otherPaymodeLink;
                }
                else {
                    isEasyPayNativeFlowEnabled()
                      ? GenericModule.openDeepLink(bnplCardChargeInfo.otherPaymodeLink)
                      : Actions.openPsWebView({
                          url: bnplCardChargeInfo.otherPaymodeLink,
                          headerText: staticData.payNowText,
                          headerIcon: WHITE_LEFT_ARROW_ICON,
                          injectedJavaScript: setWebViewAuth(userInfo.mmtAuth),
                        });
                }
                break;
        }
    }

    const choosePaymentCardList = (item) => {
        const activeState = item.type === activePaymentMode;
        const activeSuffix = activeState ? 'Active' : 'Disabled';

        return (
            <View style={[styles.paymentCard, styles[(`paymentCard${activeSuffix}`, AtomicCss.flex1)]]}>
                <TouchableOpacity style={[styles.radioSection, styles[`radioSection${activeSuffix}`]]} onPress={() => setActivePaymentMode(item.type)} activeOpacity={.7}>
                    <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween]}>
                        <View style={AtomicCss.flex1}>
                            <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom8]}>
                                <View style={[styles.radio, styles[`radio${activeSuffix}`]]}>
                                    {activeState && <View style={styles.radioInside}></View>}
                                </View>
                                <Text style={[AtomicCss.blackText, fsStyle.font16, fonts.blackFontFamily, AtomicCss.flex1]}>
                                    {item.title}
                                </Text>
                            </View>
                            <View style={styles.additionalSection}>
                                <View style={[AtomicCss.flex1]}>
                                    <Text style={[fsStyle.font13, AtomicCss.blackText, fonts.regularFontFamily, AtomicCss.lineHeight16, AtomicCss.marginBottom5]}>{item.subTitle}</Text>
                                    {!!item.extraInfo && <Text style={[fsStyle.font13, AtomicCss.blackText, fonts.regularFontFamily, AtomicCss.lineHeight16]}>{item.extraInfo}</Text>}
                                </View>
                            </View>
                        </View>
                    </View>
                </TouchableOpacity>
                {!!item.otherInfo &&
                    <Text style={[fsStyle.font12, AtomicCss.midGreyText, fonts.regularFontFamily, AtomicCss.lineHeight18, styles.extraText]}>
                        {item.otherInfo}
                    </Text>
                }
            </View>
        );
    }

    return (
        <View style={AtomicCss.paddingHz24}>
            <View style={AtomicCss.marginBottom24}>
                <View style={styles.overlayTopline}></View>
            </View>
            <Text style={styles.title}>{bnplCardChargeInfo.newCardHeading}</Text>
            {paymentModeList.map((item) => (
                choosePaymentCardList(item)
            ))}
            <View style={styles.buttonBottomWrapper}>
                <Button
                    clickHandler={handleClick}
                    btnWidth={'110%'}
                    btnBg="gradientBtn"
                    btnType="flat"
                    fontSize="font16"
                    btnTxt={staticData.proceedText}
                />
            </View>
        </View>
    )
}

export default ChoosePaymentMode;
