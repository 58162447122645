import React from 'react';
import {View, Text, StyleSheet } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {colors} from '@mmt/legacy-commons/Styles/globalStyles';
import { isEmpty } from 'lodash';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const { fsStyle, ...fonts } = getFont(true);

const AmountPendingTimeline = ({pricingBreakup}) =>{
    const styles = getStyles(fonts);
    const { mainSection={}, bottomSection={}} = pricingBreakup || {};
    return(
        <View style={styles.amountTimeWrapper}>
            {!isEmpty(mainSection) && <TimeLineCard cardData={mainSection} />}
            {!isEmpty(mainSection) && <View style={styles.dividor}></View>}
            <View style={styles.recivedWrapper}>
                <Text style={[styles.font13, fonts.boldFontFamily, AtomicCss.defaultText, AtomicCss.lineHeight20, AtomicCss.marginBottom8]}>{bottomSection.title}</Text>
                {!isEmpty(bottomSection.sectionList) &&  bottomSection.sectionList.map((item)=>
                    <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.spaceBetween, AtomicCss.marginBottom5]}>
                        <Text style={[styles.font14, fonts.regularFontFamily, AtomicCss.defaultText]}>-{item.heading}</Text>
                        <Text style={[styles.font14, fonts.regularFontFamily, AtomicCss.defaultText]}>{item.subHeading}</Text>
                    </View>)}
            </View>

        </View>
            
                
        
    )
}
const TimeLineCard = ({cardData})=>{
    const styles = getStyles(fonts);
    const {sectionList={}} = cardData || {};
        return(
            <>
            <View>
                <Text style={[styles.font13, fonts.boldFontFamily, AtomicCss.defaultText, AtomicCss.lineHeight20]}>{cardData.title}</Text>
                <View  style={styles.leftBorderActive}></View>
            </View>
            {!isEmpty(sectionList) && sectionList.map((item, i) => 
                <View style={sectionList.length-1 === i ? styles.pendingList : [styles.pendingList, styles.trackerLine]} key={i}>
                    <View style={styles.imgTrackerWrap}>
                        <View style={ item.active ? styles.circleActive: styles.circle}>
                            <Text style={[fsStyle.font12, fonts.boldFontFamily, AtomicCss.whiteText]}>{i+1}</Text>
                        </View>
                    </View>
                    <View style={[styles.pendingListCont]}>
                        <View>
                            <Text style={[fsStyle.font14, AtomicCss.defaultText, fonts.regularFontFamily]}>{item.heading}</Text>
                        </View>
                        <View style={styles.pendingRightCont}>
                            <Text style={[fsStyle.font14, AtomicCss.defaultText, fonts.boldFontFamily]}>{item.subHeading}</Text>
                        </View>
                    </View>
                </View>
            )}
            </>
        )
}
const getStyles = (fonts) => {
    return StyleSheet.create ({
        amountTimeWrapper:{
            backgroundColor: colors.grayBg,
            padding:20,
            borderRadius:4
        },
        pendingList: {
            flexDirection: 'row',
            paddingBottom: 16
        },
        pendingTimeline: {
            paddingHorizontal: 25
        },
        underline: {
            textDecorationLine: 'underline'
        },
        pendingListCont: {
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop:2
        },
        pendingRightCont: {
            alignItems: 'flex-end'
        },
        
        imgTrackerWrap: {
            width: 20,
            height: 20,
            alignItems: 'center',
            position: 'relative',
            left: -10,
        },
        circleActive:{
            width: 20,
            height: 20,
            backgroundColor: colors.lightYello,
            borderRadius:50,
            alignItems: 'center',
            justifyContent: 'center',
        },
        circle: {
            width: 20,
            height: 20,
            backgroundColor: colors.grey25,
            borderRadius:50,
            alignItems: 'center',
            justifyContent: 'center',
        },
        txtRight: {
            textAlign: 'right'
        },
        trackerLine:{
            borderLeftWidth: 1,
            borderColor: colors.grey23
        },
        pendingTimeLineTrue: {
            ...fonts.font14,
            fontFamily: fonts.bold,
            color: colors.defaultTextColor
        },
        pendingTimeLineFalse: {
            ...fonts.font14,
            fontFamily: fonts.regular,
            color: colors.defaultTextColor,
            textDecorationLine: 'line-through'
        },
        leftBorderActive:{
            paddingBottom:16, 
            borderLeftWidth:1, 
            borderColor: colors.lightYello
        },
        dividor:{
            borderTopWidth:1,
            borderColor: colors.grey24,
            marginHorizontal:-20,
            marginBottom:20
        }
    })
}
export default AmountPendingTimeline;