import getPlatformElevation from '@mmt/legacy-commons/Common/Components/Card/getPlatformElevation';
import { StyleSheet } from 'react-native';

const getStyles = (fonts, color) => {
    return StyleSheet.create({
      disabledCard: {opacity: 0.4},
      headingStyle: {
        fontSize: fonts.fontSize.font16,
        ...fonts.blackFontFamily,
        color: color.black,
      },
      subHeadingStyle: {
        color: color.lightTextColor,
        fontSize: fonts.fontSize.font14,
        ...fonts.regularFontFamily
      },
      topSection: {
        ...getPlatformElevation(2),
        borderTopRightRadius: 8,
        borderTopLeftRadius: 8,
        backgroundColor: color.white,
        paddingHorizontal: 12,
        paddingTop: 16,
        paddingBottom: 12,
      },
      whiteCard: {
        marginBottom: 10,
        marginHorizontal: 12,
        backgroundColor: color.white,
        ...getPlatformElevation(1),
        borderRadius: 8,
      },
      pedZero: {
        paddingHorizontal: 0,
        paddingVertical: 0,
      },
      headingWrapper: {
        flexDirection: 'row',
      },
      iconWrapper: {
        marginRight: 8,
        alignItems: 'center',
        justifyContent: 'flex-start',
      },
      cancellationIconStyle: {
        width: 24,
        height: 24,
      },
      detailsSection: {
        paddingLeft: 32,
        paddingRight: 0,
      },
      rightArrowStyle: {
        transform: [
          {
            rotate: '-90deg',
          },
        ],
        width: 15,
        height: 10,
      },
      redirectLink: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingVertical: 20,
        alignItems: 'center',
        paddingLeft: 44,
        paddingRight: 20,
        borderBottomWidth: 1,
        borderBottomColor: color.lightGrey3,
      },
      buttonRadiusStyle: {
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
      },
    });
  };

  export default getStyles;