import {
  DATE_MONTH,
  POSTSALES_DATE_TIME,
  reformatDateString,
} from '@mmt/legacy-commons/Helpers/dateTimehelpers';
import myTripsNavigation from '../../../navigation/navigation';
import { titleCase } from '@mmt/legacy-commons/Helpers/stringHelpers';
import upcomingBookingIcon from '@mmt/legacy-assets/src/postSales/summary/upcomingTrainIocn.webp';
import completedBookingIcon from '@mmt/legacy-assets/src/postSales/summary/completedTrainIcon.webp';
import cancelledBookingIcon from '@mmt/legacy-assets/src/postSales/summary/cancelledTrainIcon.webp';
import failedBookingIcon from '@mmt/legacy-assets/src/postSales/summary/failedTrainIcon.webp';
import { getStaticData } from '../../../../staticData/staticData';
import {
  getDateTimeWithPaxInfo,
  unsuccessfulBookingTextHandler,
} from '../../../data/tripListManager';
import _isEmpty from 'lodash/isEmpty';
import { msrUpdateAction  } from '../../../../utils/MsrUtils';
import { BookingStates } from '../../../summaryConstants';
import { getNeedHelpData, getTalkToUsDetails } from '../../../utils';

function getTravelerSummary(busDetails, data) {
  const { passengers } = busDetails || {};
  if (_isEmpty(passengers)) {
    const { leadPaxFirstName, leadPaxLastName } = data;
    return `${leadPaxFirstName} ${leadPaxLastName || ''}`;
  }
  const count = passengers.length;
  const firstPaxName = titleCase(passengers[0].firstName);
  if (count === 1) {
    return firstPaxName;
  }
  return `${firstPaxName} + ${count - 1}`;
}

export function getTrainSummaryData(data, msrTicket) {
  const staticData = getStaticData();
  const {trainBookingText, cancelledText, failedText, bookingSummaryText: { additionalText: {trainBookingFailedText}}} = staticData;
  const departDate = data.minDeptDateMobile || reformatDateString(data.minDeptDate, POSTSALES_DATE_TIME, DATE_MONTH);
  const [trainDetails] = data.railDetails;
  const pageData = {
    fromMyTrips: true,
  };

  const openDetails = () => {
    myTripsNavigation.openRailBookingDetail(data, pageData);
  };

  const actions = [];
  const failedBooking = data.status === 'FAILED';
  const msrAction = msrUpdateAction(msrTicket);
  if (msrAction) {
    actions.push(msrAction);
  }
  
  const talkToUsDetails = getTalkToUsDetails(
    data.railDetails?.[0]?.productCode,
    data.uuid,
    false,
    true
  );
  const needHelpData = getNeedHelpData(msrTicket, data.bookingID, data.needHelpData, data.showQuickResolve, talkToUsDetails);

  let bookingInfo = trainBookingText;
  const travelerSummary = getTravelerSummary(trainDetails, data);
  let dateTime = `${departDate} • ${travelerSummary}`;
  let iconUrl = '';
  switch (data.status) {
    case BookingStates.UPCOMING:
      iconUrl = upcomingBookingIcon;
      break;
    case BookingStates.COMPLETED:
      iconUrl = completedBookingIcon;
      break;
    case BookingStates.CANCELLED:
      iconUrl = cancelledBookingIcon;
      dateTime = getDateTimeWithPaxInfo( data, cancelledText , dateTime , travelerSummary);
      break;
    case BookingStates.FAILED:
      iconUrl = failedBookingIcon;
      dateTime = getDateTimeWithPaxInfo( data, failedText , dateTime , travelerSummary);
      bookingInfo = trainBookingFailedText;
      break;
    default:
      iconUrl = upcomingBookingIcon;
      break;
  }
  return {
    status: data.status,
    iconUrl: iconUrl,
    dateTime: dateTime,
    bookingInfo,
    from: `${trainDetails.originCityName} (${trainDetails.origin})`,
    to: `${trainDetails.destinationCityName} (${trainDetails.destination})`,
    persuasions: [],
    actions,
    openDetails,
    failedBooking,
    msrTicket,
    needHelpData
  };
}

export default {};
