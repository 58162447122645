import React from 'react';
import {View, Text, TouchableOpacity,Image} from 'react-native';
import styles from './CabOnSpotInfoOverlayStyle';

export default class CabOnSpotInfoOverlay extends React.Component {

  render() {
    return (
        <View>
            <TouchableOpacity onPress={()=>this.props.closePopup()}>
          <View style={[styles.topHeading, styles.IOSSpacing]}>
            <Text style={styles.overlayTopHeadding}>
                Airport Cab QR and OTP
            </Text>
          </View>
          <View style={styles.containerOverlay}>
            <Text style={styles.QRImpText}>{this.props.carOnSpotBoardingInfo.message}</Text>
            <View style={[styles.centerContainer, styles.appendBottom4]}>
              <View style={styles.overlayCouponCode}><Text style={styles.overlayCouponText}>{this.props.carOnSpotBoardingInfo.qrCode}</Text></View>
            </View>
            <View style={[styles.centerContainer, styles.appendBottom24]}>
              <Image style={styles.mapIcon} source={{uri: this.props.carOnSpotBoardingInfo.qrCodeLink}} />
            </View>
            <View style={[styles.centerContainer, styles.appendBottom44, {flexDirection: 'column'}]}>
              <Text style={styles.bookingId}>BOOKING ID</Text>
              <Text style={styles.bookingIdNo}>{this.props.bookingId}</Text>
            </View>
          </View>
        </TouchableOpacity>
        </View>
    );
  }
}
