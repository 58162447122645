import { Dimensions, Platform } from 'react-native';
import { AffiliateThemes } from './PostSalesConstant';
import { getAffiliateName } from './utils/NetworkUtils';
import DefaultTheme from './DefaultTheme';
import { getUserSelectedLanguage } from '@mmt/legacy-commons/AppState/AppUserConfig';
import PostSalesVernac from './PostSalesVernac';
import { LANGUAGE_MAP } from '@mmt/legacy-commons/Common/constants/AppConstants';
import NetworkModule from '@mmt/legacy-commons/Native/NetworkModule';

const LatoFont = {
  black: 'Lato-Black',
  bold: 'Lato-Bold',
  italic: 'Lato-Italic',
  regular: 'Lato-Regular',
  medium: 'Lato-Medium',
  light: 'Lato-Light',
  semiBold: 'Lato-Semibold',
  boldItalic: 'Lato-BoldItalic',
  heavy: 'Lato-Heavy',
};

const TamilLatoFont = {
  black: 'Lato-Bold',
  bold: 'Lato-Bold',
  italic: 'Lato-Italic',
  regular: 'Lato-Regular',
  medium: 'Lato-Medium',
  light: 'Lato-Light',
  semiBold: 'Lato-Semibold',
  boldItalic: 'Lato-BoldItalic',
  heavy: 'Lato-Heavy',
};

const MuktaFont = {
  black: 'Mukta-Black',
  bold: 'Mukta-Bold',
  italic: 'Mukta-Italic',
  regular: 'Mukta-Regular',
  medium: 'Mukta-Medium',
  light: 'Mukta-Light',
  semiBold: 'Mukta-Semibold',
  boldItalic: 'Mukta-BoldItalic',
  heavy: 'Mukta-Heavy',
};

const AlmaraiFont = {
  black: 'Almarai-Black',
  bold: 'Almarai-Bold',
  italic: 'Almarai-Italic',
  regular: 'Almarai-Regular',
  medium: 'Almarai-Medium',
  light: 'Almarai-Light',
  semiBold: 'Almarai-SemiBold',
  boldItalic: 'Almarai-BoldItalic',
  heavy: 'Almarai-Heavy',
};

export const appFonts = {
  [LANGUAGE_MAP.eng]: LatoFont,
  [LANGUAGE_MAP.hin]: MuktaFont,
  [LANGUAGE_MAP.ara]: Platform.OS === 'web' ? AlmaraiFont : LatoFont,
  [LANGUAGE_MAP.tam]: TamilLatoFont,
};

const fontSize = (() => {
  const fs = {};
  for (let i = 8; i <= 40; i++) {
    fs[`font${i}`] = i;
  }
  return fs;
})();

const getTamilFontSize = () =>
  Object.keys(fontSize).reduce((obj, key) => {
    obj[key] = fontSize[key] >= 12 ? Math.floor(fontSize[key] * 0.9) : fontSize[key];
    return obj;
  }, {});

export const appFontSize = {
  [LANGUAGE_MAP.eng]: fontSize,
  [LANGUAGE_MAP.hin]: fontSize,
  [LANGUAGE_MAP.ara]: fontSize,
  [LANGUAGE_MAP.tam]: getTamilFontSize(),
};

export const getLang = (userSelectedLanguage = false) => {
  const currntLang = getUserSelectedLanguage();
  const lang =
    userSelectedLanguage && (Platform.OS !== 'ios' || currntLang === LANGUAGE_MAP.tam)
      ? PostSalesVernac.applicableLang(currntLang)
      : LANGUAGE_MAP.eng;
  return lang;
};

export const getFont = (userSelectedLanguage = false) => {
  const lang = getLang(userSelectedLanguage);
  const fonts = appFonts[lang] || appFonts.eng;
 const fontSize = appFontSize[lang] || appFontSize.eng;
  const fsStyle = appFontSizeStyle[lang] || appFontSizeStyle.eng;
  const fontStyle = appFontsFamily[lang] || appFontsFamily.eng;
  return { ...fonts, ...fontStyle, fontSize, fsStyle, ...fsStyle };
};

function getFontFamily(fonts) {
  const fontStyle = {};
  for (let key in fonts) {
    fontStyle[`${key}FontFamily`] = { fontFamily: fonts[key] };
  }
  return fontStyle;
}

const { appFontsFamily, appFontSizeStyle } = [
  LANGUAGE_MAP.eng,
  LANGUAGE_MAP.hin,
  LANGUAGE_MAP.ara,
  LANGUAGE_MAP.tam,
].reduce(
  (obj, lang) => {
    obj.appFontsFamily[lang] = getFontFamily(appFonts[lang]);
    obj.appFontSizeStyle[lang] = getFontSizeStyle(appFontSize[lang], lang);
    return obj;
  },
  { appFontsFamily: {}, appFontSizeStyle: {} },
);

function getFontSizeStyle(fontSize, lang) {
  const fontStyle = {};
  for (let key in fontSize) {
    fontStyle[key] = { fontSize: fontSize[key], ...getLineHeightStyle(fontSize[key], lang) };
  }
  return fontStyle;
}

function getLineHeightStyle(fontSize, lang) {
  if (lang === LANGUAGE_MAP.tam && Platform.OS !== 'ios') {
    return { lineHeight: Math.floor(fontSize * 1.25) };
  }
  return {};
}
 const isAffiliateUser = () => {
  return NetworkModule.isAffiliateUser();
};

export const themeColors =
  Platform.OS === 'web' && isAffiliateUser() ? AffiliateThemes[getAffiliateName()] : DefaultTheme;

export const ps_colors = {
  primary: '#008CFF',
  black: '#000000',
  blackWithOpacity: '#00000014',
  black3: '#242424',
  black1: '#212121',
  white: '#ffffff',
  whiteSmoke: '#FFEEEE',
  textGrey: '#4A4A4A',
  smokeWhite: '#f6f6f6',
  grayBg: '#f2f2f2',
  greyBookedSeat: '#e7e7e7',
  transparent: 'transparent',
  azure: '#008cff',
  azureBlue: '#008bff',
  azureBlue1: '#038cff',
  azureBlue2: '#001F33',
  azureBlue3: '#032134',
  azureBlue4: '#022135',
  azureLight: '#0084ff',
  azureLight2: '#f2a330',
  azureSemi: '#2399ff',
  azureSemiTrans: 'rgba(0,140,255, 0.3)',
  aliceBlue: '#EBF5FF',
  lightGrey: '#e7e7e7',
  lightGrey1: '#505050',
  lightGrey6: '#f8f8f8',
  lightGrey8: '#e2e2e2',
  rippleColor: '#d7d7d7',
  grey: '#7a7a7a',
  grey1: '#7D7D7D',
  grey2: '#f1f1f1',
  grey3: '#f3f3f3',
  grey4: '#d3d3d3',
  grey5: '#f5f5f5',
  grey6: '#d9d9d9',
  grey7: '#f9f9f9',
  grey8: '#ddd',
  grey9: '#d4d4d4',
  grey10: '#afafaf',
  grey11: '#f4f4f4',
  grey12: '#e9e6e6',
  grey13: '#fafafa',
  grey14: '#f7f7f7',
  grey15: '#bfb7b7b7',
  grey16: '#f4ebe5',
  grey17: '#2d2d2d',
  grey18: '#e6e6e6',
  grey19: '#e1e1e1',
  grey21: '#e8e8e8',
  grey23: '#b5b5b5',
  grey24: '#ccc',
  grey25: '#BDBDBD',
  grey26: '#dfdfdf',
  gray27: '#D0D0D0',
  gray28: '#D9D5D5',
  gray29: '#46484d',
  gray30: '#BEBEBE',
  grey33: '#777777',

  pink1: '#9F0469',
  grayDark: '#363636',
  easternBlue: '#26b5a9',
  cruise: '#c1f1dd',
  defaultTextColor: '#4a4a4a',
  darkGrey: '#292828',
  cyanBlue: '#2F80ED',
  cyanBlue1: '#62ACF6',
  cyanBlue2: '#3CA1FD',
  pinkRed: '#FFEAEB',
  darkGrey1: '#1f1f1f',
  cyanColor: '#d2f7f5',
  lightTextColor: '#9b9b9b',
  greyText1: '#757575',
  lighterTextColor: '#a5a5a5',
  lightestTextColor: '#c9c9c9',
  lightSeaGreenTextColor: '#249995',
  lightGreen: '#31a498',
  lightGreen2: '#fbfffe',
  lightGreen3: '#bfe5d9',
  lightGreen4: '#33d18f',
  lightGreen5: '#228b22',
  lightGreen6: '#d4ebea',
  lightGreen7: '#d7f6e9',
  lightGreen8: '#f0fdfc',
  lightGreen9: '#43e1a8',
  lightGreen10: '#219393',
  lightGreen11: '#239f99',
  lightGreen12: '#DFE9E9',
  lightGreen13: '#2D9994',
  lightGreen14: "#B8F5E0",
  lightGreen15: "#e8fde9",
  lightGreen16: '#C2F4E9',
  lightGreen17: "#E9F6EA",
  lightGreen18: '#007E7D',
  lightGreen19: '#DCFFF0',
  lightGreen20: '#dff9f4',
  lightGreen21: '#d3f2ed',
  hummingBird: '#cdf6e8',
  redDiscount: '#d3000d',
  red: '#eb2026',
  red2: '#ec1c24',
  red3: '#ffe8e9',
  red4: '#d0021b',
  red5: '#fff6f6',
  red6: '#e53442',
  red7: '#ff6f00',
  red8: '#ff684a',
  red9: '#ff4959',
  red10: '#ff664b',
  red11: '#ff7527',
  red12: '#ffcab5',
  red13: '#FFCCC7',
  red14: '#EB2226',
  red15: '#F36F40',
  red16: '#F85E66',
  red17: '#ec2127',
  red18: '#DA4E52',
  red19: '#FF5D50',
  red20: '#FF3434',
  red21: '#AE3333',
  red22: '#FF7F3F',
  red23: '#FF3E5E',
  red24: '#330000',
  red25: '#e02020',
  red26: '#e05a65',
  red27: '#eee',
  red29: '#960D11',
  red30: '#E4747A',
  red31: '#EC2127',
  lightRed: '#ffe3d5',
  lightRed1: '#fbe6e9',
  lightRed2: '#f06d6d',
  black2f: '#2f2f2f',
  errorRed: '#d0011b',
  errorRed2: '#e62120',
  disabledButton: '#c2c2c2',
  brightGreen: '#ace143',
  brown: '#371F31',
  veryLightGrey: '#eaeaea',
  darkGrey2: '#979797',
  linkBlue: '#004cde',
  linkBlue2: '#00244d',
  disabledBtnBg: '#9b9b9b',
  purpleyGrey: '#9b9b9b',
  purpleyGrey2: "#6877B5",
  lightPurple: '#053cb2',
  black04: '#040404',
  darkBlue: '#065af3',
  darkBlue1: '#1f3f66',
  darkBlue2: '#1c5185',
  darkBlue3: '#5715f9',
  darkBlue4: '#4a90e2',
  darkBlue5: '#003399',
  darkBlue6: '#1c73f6',
  darkBlue7: '#7FB4FE',
  darkBlue8: '#2F4F6E',
  darkBlue9: '#547898',
  darkBlue10: '#1b269f',
  darkBlue11: '#12355B',
  darkBlue12: '#647A97',
  darkBlue13: '#0051b7',
  lightBlue: '#53b2fe',
  lightBlue2: '#e6f4ff',
  lightBlue3: '#c3e4ff',
  lightBlue4: '#b5dcff',
  lightBlue5: '#f9fcff',
  lightBlue6: '#edf7ff',
  lightBlue7: '#edf7ff',
  faintBlue: '#E3F1FF',
  hawkesBlue: '#e1f1fd',
  lightBlue8: '#d8e8f6',
  lightBlue9: '#d4f3fd',
  lightBlue10: '#f4faff',
  lightBlue11: '#7fc5ff',
  lightBlue12: '#e1e7ee',
  lightBlue13: '#325E93',
  lightBlue14: '#B9D5EC',
  lightBlue15: '#E1F2FF',
  lightBlue16: '#667eea',
  lightBlue17: '#008CFF',
  lightBlue18: '#202e4a',
  lightBlue19: '#a9d4f6',
  lightBlue21: '#f3faff',
  lightBlue20: '#E4F1FF',
  lightBlue22: '#e8f0fb',
  lightBlue23: '#F2F5FF',
	lightBlue24: '#e5f3ff',
  governorBay: '#3023ae',
  seance: '#9416aa',
  lavender: '#c86dd7',
  cabsGreyBg: '#E5E5E5',
  darkGray: '#3c3c3c',
  gray1: '#ededed',
  gray2: '#f0f0f0',
  gray3: '#cccccc',
  gray4: '#c7c7c7',
  gray5: "#EDEDF2",
  gray6: '#EFF3F8',
  gray7: '#d1d1d1',
  gray8: '#ECECEC',
  gray9: '#00000080',
  yellow: '#f2b343',
  yellow1: '#f5a623',
  yellow2: '#f0772c',
  yellow3: '#fef8e5',
  yellow4: '#f95776',
  yellow5: '#ffcf03',
  yellow6: '#FEFCF0',
  yellow7: '#fef6eb',
  yellow8: '#e1be5b',
  yellow9: '#ddb01f',
  yellow10: '#f5ecae',
  yellow11: '#bc9637',
  yellow12: '#F5E5CC',
  yellow13: '#C06D0C',
  yellow14: '#F2C21A',
  goldenYellow: '#f3d452',
  lightYellow: '#f2b635',
  lightYellow1: '#FFF0E5',
  goldenYellow2: '#f9d71a',
  goldenYellow3: '#fff3d1',
  goldenYellow5: '#f27a20',
  goldenYellow6: '#f2771a',
  goldenYellow7: '#f17530',
  goldenYellow8: '#ff6544',
  goldenYellow9: '#fe664b',
  goldenYellow10: '#f27337',
  goldenYellow11: '#f85a6e',
  goldenYellow12: '#ffcc00',
  lightGoldenYellow1: '#fdf1e8',
  lightGoldenYellow2: '#fad5c0',
  lightGoldenYellow: '#fce4d1',
  lightGoldenYellow3: '#fff9f7',
  persianGreen: '#009997',
  deepBlue: '#3b385e',
  lighterBlue: '#eaf5ff',
  lightestBlue: '#e7f3fe',
  skyBlue: '#def0ff',
  skyBlue1: '#3a7bd5',
  skyBlue2: '#00d2ff',
  skyBlue3: 'rgba(180,220,250,0.5)',
  skyBlue4: '#DDE8FF',
  skyBlue5: '#CCE8FF',
  skyBlue6: '#0091ff',
  skyBlue7: '#46bcec',
  skyBlue8: '#BDDBFC',
  skyBlue9: 'FAFCFF',
  vividBlue: '#0080FF',
  lightWhite: 'rgba(255,255,255,0.7)',
  lightWhite2: 'rgba(255,255,255,0.8)',
  lightBlack: 'rgba(0,0,0,0.7)',
  lightBlack3: 'rgba(0,0,0,.5)',
  lightGrey2: 'rgba(151,151,151,0.16)',
  black28: '#282828',
  black54: '#545454',
  black74: '#747474',
  black22: '#222222',
  darkPurple: '#01145e',
  lightBlack1: '#AF0067',
  lightBlack2: '#3A3838',
  pink: '#ff40bf',
  pink2: '#ffe0db',
  pink3: '#FFDED9',
  pink4: '#fcdadb',
  pink5: "#FFEAEA",
  pink6: '#ffe1e2',
  pink7: '#FCDADB',
  darkPink: '#fc4192',
  greyLight: '#747474',
  modalgrey: 'rgba(0,0,0,0.7)',
  dividerGrey: '#ededed',
  radicalRed: '#ff3e5e',
  brightOrange: '#f2c544',
  brightOrange2: '#FDF0EC',
  orange: '#ff7f3f',
  orange2: '#ef9749',
  orange3: '#F17531',
  orange4: '#ff674b',
  yellowOrange: '#FFE2B5',
  yellowOrange2: '#FCEDD4',
  greyOrange: '#ECDBC1',
  padua: '#EBD1BF',
  strongOrange: '#cc7f01',
  yello: '#f09819',
  cyan: '#00a19c',
  softCyan: '#B6ECE2',
  lightCyan: '#c8f8f6',
  darkCyan: '#1F4941',
  lightPink: '#ffd3d4',
  lightYello: '#cf8100',
  creamWhite: '#ffedd1',
  creamWhite2: '#fffaf2',
  creamWhite3: '#ffecd1',
  creamWhite4: '#fff5ea',
  creamWhite5: '#ffe4c7',
  creamWhite6: '#ffecef',
  creamWhite7: '#fffbf9',
  lightGray: '#d8d8d8',
  green: '#1a7971',
  green1: '#5e9a1a',
  green2: '#3a7771',
  cngColor1: '#43e1a8',
  cngColor2: '#28beb2',
  lightGrey3: '#e4e4e4',
  darkGrey3: '#6f6f6f',
  lightGrey4: '#666666',
  cardBorderColor: 'rgba(0,0,0,0.1)',
  lightGrey5: '#e7f1fd',
  lightGrey7: '#C4C4C4',
  successGreen: '#26b5a9',
  referralGold: '#ff9f00',
  activeBg: '#f1f8fe',
  activeBorder: '#d1e2f2',
  darkGreyish: '#484848',
  greyish: '#6d7278',
  faintgrey: '#dddddd',
  buttonBlue: '#008CBA',
  palePink: '#fedcde',
  purple: '#b979c6',
  purple2: '#613393',
  purple3: '#9152c8',
  purple4: '#663EBD',
  green2: '#93d51f',
  green3: '#209593',
  green4: '#cedcc5',
  green8: '#0b4f57',
  green9: '#18A160',
  green10: '#02c674',
  green11: '#E6FFF9',
  green12: '#1A7971',
  amour: '#FFEBEE',
  lightGrey9: '#F4F9FF',
  lightGrey10: '#E9F3FF',
  lightGrey11: '#AAAAAA',
  lightGrey12: '#BCBCBC',
  lightGrey13: '#FBFBFB',
  lightGrey14: '#bbbbbb',
  lightGrey15: '#c8c8c8',
  lightGrey16: '#cbcbcb',
  lightPurple1: '#F4F2FF',
  darkCharcoal: '#333',
  greyBrdr1: '#c4c4c4',
  greyBrdr2: '#EFEFEF',
  royalBlue: '#3380E5',
  selago: '#E7F2FC',
  limeGreen: '#d1f8e6',
  deepRed: '#A9112F',
  darkGrey4: '#222',
  darkGrey5: '#5a5a5a',
  darkBrown: '#955000',
  cyan2: '#b6ebe2',
  cyan3: '#2276E3',
  darkGreen: '#1F4941',
  paleBlue: '#F7FBFF',
  gainsBoro: '#dcdcdc',
  lightViolet: '#994BFF',
  grey22: '#fff',
  grey20: 'rgba(0,0,0,0.5)',
  grey34: 'rgba(0,0,0,0.8)',
  grey35: '#d4daea',
  grey23: '#DBDBDB',
  black2: '#f2f9ff',
  blackRussian: '#141823',
  lightYellow2: '#fff7cb',
  lightYellow3: '#FFFF07',
  darkYellow: '#c58510',
  cabsUnconfirmedGradient: ["#053cb2", "#0c6fd9"],
  cabsConfirmedGradient: ["#5f88ff", '#a768ff'],
  cabsCompletedGradient: ['#1f1c2c', '#928dab'],
  cabsAfterRefundDone: ['#912086', '#3023ae'],
  cabsCancelledGradient: ['#912086', '#3023ae'],
  blueGradient: ['#f3d452', '#f09819'],
  lightBlueGradient: ['#d3e7ff', '#ffffff'],
  greyGradient: ['#9b9b9b', '#9b9b9b'],
  green2Gradient:['#cef1eb', '#ffffff'],
  yellowGradient: ['#9b9b9b', '#9b9b9b'],
  seagull: '#7DBFCC',
  turquoise: '#4AFFCD',
  sweetCorn: '#FFE380',
  lightCoral: '#FF8080',
  green5: '#7ed321',
  green6: '#007e71',
  green7: '#e8fde9',
  grey31: '#929292',
  creamWhite8: "#F0E7E7",
  lightYellow3: '#e58f13',
  grey32: '#FAFAFA',
  pink4: '#c96dd8',
  darkBlue11: '#3023ae',
  creamWhite9: "#ffedd1",
  gray35: '#9a9a9a',
  gray36: '#bebebe',
  blue10: '#0052b6',
  blue11: '#89889e',
  blue12: '#d6e5f1',
  blue13: '#b6daf7',
  blue14: '#16477f',
  blue15: '#0c58b4',
  blue16: '#788498',
  submitDateChangeReqBtnText: '#008cff',
  linkTextDateChange: '#0052b6',
  red24: '#ff000d',
  lightRed3: '#f6e7e7',
  lightRed4: '#eeeeee',
  lightBlue17: '#0055b7',
  lightGrey17: '#d6d6d6',
  lightGrey18: '#d5d5d5',
  lightYellow2: 'rgba(255,255,255,.7)',
  grey28: '#3c3c3c',
  grey29: '#878787',
  grey30: '#b7b7b766',
  grey31: '#929292',
  lightYellow3: '#e58f13',
  grey32: '#FAFAFA',
  pink4: '#c96dd8',
  lightRed: '#ff6666',
  visaGradientColor: ['#FFF5DD', '#FFFFFF'],
  nero: '#1E1E1E',
  transparentRed: '#F6DBDC',
  lightBlue23: '#F2F8FE',
  lightRed5: '#ffe1ce',
  lightRed6: '#fff3f0',
  lightRed7: '#ffe7e1',
  lightRed8: '#fbfafa',
  lightRed9: '#ffefee',
  grey35: '#E7E5E5',
  grey36: "#e6e6e6",
  grey37: "#d8d8d8",
  grey38: "#cacaca",
  grey39: "#e5e5e5",
  grey40: "#808080",
  grey41: "#494949",
  platinumGradientColor: ['#C4C4C4', '#949393'],
  goldGradientColor: ['#F5ECAE', '#BC9637'],
  longStayBenefitGradientColor : ['#3023AE', '#C86DD7'],
  purpleLight: '#AA69EB',
  purpleDark: '#3428A6',
  lightWhiteBlue: "#E9F7FF",
  ivory: '#FFF8EE',
  lightyellow4: "ffffffd9",
  cyangreen: "#337777",
  violet: "#e9d4ff",
};

export const screenDimension = {
  windowHeight:
    Platform.OS === 'ios' ? Dimensions.get('screen').height : Dimensions.get('window').height,
  windowWidth:
    Platform.OS === 'ios' ? Dimensions.get('screen').width : Dimensions.get('window').width,
};
