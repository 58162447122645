import React from 'react';
import {View, Text, StyleSheet, TextInput} from 'react-native';
import { getFont } from '../../../PostSalesStyles';
class TdrFormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: ''
    };
  }

      search=(textEntered) => {
        this.props.updateFunction(textEntered);
        this.setState({
          inputValue: textEntered
        });
      };

    static navigationOptions = {
      header: null
    };

    render() {
      const { fsStyle, ...fonts } = getFont(true);
      return (
        <View style={styles.inputWrapper}>
          <Text style={[styles.fieldTxt, fsStyle.font12, fonts.boldFontFamily]}>{this.props.label}</Text>
          <TextInput
            style={styles.searchInput}
            onChangeText={this.search}
            value={this.props.inputValue || this.state.inputValue}
            placeholder={this.props.placeholderTxt}
            placeholderTextColor="#9b9b9b"
          />
        </View>

      );
    }
}
const styles = StyleSheet.create({
  inputWrapper: {
    marginBottom: 15
  },
  fieldTxt: {
    marginBottom: 10,
    color: '#000'
  },
  searchInput: {
    borderColor: '#ddd',
    borderWidth: 1,
    backgroundColor: '#fff',
    padding: 10,
    borderRadius: 4
  }
});
export default TdrFormComponent;
