import React from 'react';
import {BackHandler, Platform, ScrollView, View} from 'react-native';
import _ from 'lodash';
import isEmpty from 'lodash/isEmpty';
import Actions from '../../navigation/postSalesNavigation';
import BasePage from '../../Common/PostSalesBasePage';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import ProgressView from '../../Common/ProgressView/ProgressView';
import {getCommonHeaders, VISA_BOOKING_DETAIL_URL, VISA_BOOKING_DETAILS_HOLIDAY_URL} from '../../utils/NetworkUtils';
import {isNetworkAvailable} from '@mmt/legacy-commons/Common/utils/AppUtils';
import logAction from '@mmt/legacy-commons/Helpers/actionsLogger';
import SimpleHeader from '@mmt/legacy-commons/Common/Components/Header/SimpleHeader.android';
import NoInternetView from '@mmt/legacy-commons/Common/Components/Error/NoInternetView';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import ViewControllerModule from '@mmt/legacy-commons/Native/ViewControllerModule';
import StickyHeader from '../../hotel/details/components/StickyHeader';
import VisaDetailConstant from '../Constant/VisaDetailConstant';
import TopStatusCard from './Component/TopStatusCard';
import {colors} from './Component/TopStatusCardColor';
import styles from '../CSS/VisaMamiCss';
import VisaMainCard from './Component/VisaMainCard';
import VisaApplicantCard from './Component/VisaApplicantCard';
import VisaCancellationPolicyCard from './Component/VisaCancellationPolicyCard';
import VisaOtbPolicyCard from './Component/VisaOtbPolicyCard';
import VisaPaymentCard from './Component/VisaPaymentCard';
import NeedHelpCard from '../../Common/NeedHelpCard';
import VisaRejectionMessage from './Component/VisaRejectionMessage';
import VisaRejectionReasons from './Component/VisaRejectionReasons';

import VisaTrackerCard from './Component/VisaTrackerCard';
import VisaAdditionalDocumentCard from './Component/VisaAdditionalDocumentCard';
import VisaBookingTrackingHelper from '../VisaBookingTrackingHelper';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import {AUTH_FAILED_MESSAGE, CHAT_WITH_MYRA_CARD, COMMON_CARD, LOBNAMES} from '../../PostSalesConstant';
import ChatWithMyraCard from '../../Common/ChatWithMyraCard';
import {isLostBookingData, isMyraEnabled} from '../../utils/PostSaleUtil';
import AlertInfo from '../../Common/CommonImpInfo';
import {headerIcon, applicationAppliedIcon, inprocessIcon, applicationrejectedIcon, additionaldocumentIcon, visaapprovedIcn} from '../Constant/VisaImageConstant';
import LostIDBookingDetailPage from '../../Common/LostIdDetailPage';
import { themeColors } from '../../PostSalesStyles';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import addBookingTrackingHelper, { ADD_BOOKING_TRACKING_EVENTS } from '../../summary/components/AddBooking/tracking';
import AuthErrorView from '../../Common/AuthError/AuthErrorView';


let VisaTrackerheight = 0;

export const VISA_BOOKING_DETAILS = 'VisaBookingDetails';

export default class VisaBookingDetail extends BasePage {

    static navigationOptions = {
      header: null
    };
    constructor(props) {
      super(props, VISA_BOOKING_DETAILS);
      this.topStatusCardColor = [];
      this.trackPageName = props.holidayPageName || 'VisaBookingDetail';
      if (props.holidayBookingApi &&  props.BOOKING_ID) {
        this.bookingId = props.BOOKING_ID;
      }
      else if(props.data) {
          this.bookingId = props.data;
      }
      else if(props.bookingId) {
        this.bookingId = props.bookingId;
      }
      this.pageData = props.pageData ? JSON.parse(props.pageData) : undefined;
      this.isLostBooking = isLostBookingData(this.pageData);
      this.state = {
        responseJson: null,
        viewState: this.isLostBooking ? ViewState.SHOW_DETAIL : ViewState.LOADING,
      };
    }


    componentDidMount() {
      if (!this.isLostBooking) {
        this.getData();
      }
      if (this.pageData?.isAddBookingFlow) {
        addBookingTrackingHelper.trackLoadEvent(
          ADD_BOOKING_TRACKING_EVENTS.Addbooking_detailspage_landed,
          this.pageData?.uuid,
          this.bookingId,
          "VisaBookingDetail"
        );
      }
    }

    componentDidUpdate(prevProps) {
      if (this.props.data !== prevProps.data && this.props.data.refresh === true) {
        if (this.isLostBooking) {
          this.setState({
            refreshLostDetail: {},
          });
          return;
        }
      }
    }

    getData() {
      const {holidayBookingApi} = this.props;
      if (holidayBookingApi) {
        this.fetchJSONAsync(VISA_BOOKING_DETAILS_HOLIDAY_URL, holidayBookingApi);
      } else {
        this.fetchJSONAsync(VISA_BOOKING_DETAIL_URL + this.bookingId);
      }
    }

    updateVisaTrackerCoordinates(visaTrackerCoordinates) {
      VisaTrackerheight = visaTrackerCoordinates;
    }

    scrolltoVisaTracker =() => {
      this.state._scrollViewRef.scrollTo({y: VisaTrackerheight}, true);
    }

    async fetchJSONAsync(url, holidayBookingApi) {
      try {
        const hasNetwork = await isNetworkAvailable();
        const {mmtAuth, loggingTrackingInfo} = await HotelBookingModule.getRequestHeader(this.props.bookingid, 'Visa_Booking_Details');
        if (!hasNetwork) {
          this.setState({viewState: ViewState.NO_INTERNET});
          return;
        }
        let response;
        if(holidayBookingApi){
            response = await fetch(url,{
              headers: await getCommonHeaders(mmtAuth, loggingTrackingInfo),
              method: 'POST',
              body:JSON.stringify({
                bookingId: this.bookingId,
                refBookingId: this.props.refBookingId
              })
            });
        }
        else {
         response = await
          fetch(url, {headers: await getCommonHeaders(mmtAuth, loggingTrackingInfo)});
        }
        if (response.ok) {
          const body = await response.json();
          this.setState({
            responseJson: body,
            viewState: ViewState.SHOW_DETAIL
          });
          VisaBookingTrackingHelper.trackLoadEvent('VisaBookingDetail', this.state.responseJson);
        } else {
          const errorBody = await response?.json();
          if (errorBody && errorBody?.message && errorBody.message.toUpperCase() === AUTH_FAILED_MESSAGE) {
            this.setState({ viewState: ViewState.AUTH_ERROR });
          } else {
            this.setState({viewState: ViewState.ERROR});
          }
        }
      } catch (error) {
        this.setState({viewState: ViewState.ERROR});
        logAction('Error while fetching data from visa detail API', error);
      }
    }

  render() {
    return (
      <View style={AtomicCss.flex1}>
        {this.state.viewState === ViewState.LOADING && this.renderProgressView()}
        {this.state.viewState === ViewState.NO_INTERNET && this.renderNoNetworkView()}
        {this.state.viewState === ViewState.ERROR && this.renderErrorView()}
        {this.state.viewState === ViewState.AUTH_ERROR && this.renderAuthErrorView()}
        {this.state.viewState === ViewState.SHOW_DETAIL && this.renderPage()}
      </View>
    );
  }

    saveReferenceToScrollView(ref) {
      if (!this.state._scrollViewRef) {
        this.setState({
          _scrollViewRef: ref
        });
      }
    }

    renderPage() {
      if (this.isLostBooking) {
        return (
          <LostIDBookingDetailPage
            bookingId={this.bookingId}
            pageData={this.pageData}
            lobName={LOBNAMES.VISA}
            refreshLostDetail={this.state.refreshLostDetail}
          />
        );
      }
      let views = [];
      views = this.getMainPageViews();
      return (
        <ScrollView style={styles.lightGreyBg} stickyHeaderIndices={[0]} ref={ref => this.saveReferenceToScrollView(ref)}>
          <StickyHeader
            iconSrc={headerIcon}
            headerColor={this.topStatusCardColor}
            title={this.state.responseJson.visaBookingInfo.visaDetailList[0].countryName}
            titleStyle={[styles.WhiteText, styles.blackFont]}
            subTitle={this.state.responseJson.visaBookingInfo.visaDetailList[0].visaDescription}
            subTitleStyle={[styles.WhiteText, styles.font12]}
            onBackPress={this.goBack}
          />
          {views}
        </ScrollView>
      );
    }

    getMainPageViews() {
      const views = [];
      const cards = this.state.responseJson.layoutDetail.cardList;
      cards.forEach((card) => {
        this.addCard(card, views);
      });
      return views;
    }

    addCard(card, views) {
      switch (card.cardId) {
        case VisaDetailConstant.TOP_STATUS_CARD_BOOKING_INIT_BEFORE_APPLICATION_FILED:
          this.topStatusCardColor = [colors.Visa_blueStartColor, colors.Visa_blueEndColor];
          views.push(<TopStatusCard
            key="TopStatusCard"
            response={this.state.responseJson}
            iconPath={applicationAppliedIcon}
            headText={this.state.responseJson.visaTopHeaderMsg}
            color={[colors.Visa_blueStartColor, colors.Visa_blueEndColor]}
            card={card}
            onBackPress={this.goBack}
            pageName={this.trackPageName}
            headtextStyle={[styles.topStatusHeader]}
            subTextStyle={[styles.topStatusHeaderSubText]}
            bookingId={this.bookingId}
            scrollToVisaTracker={this.scrolltoVisaTracker}

          />);
          break;
        case VisaDetailConstant.TOPS_STATUS_CARD_APPLCATION_FILED_TODAY_STATE:
          this.topStatusCardColor = [colors.Visa_blueStartColor, colors.Visa_blueEndColor];
          views.push(<TopStatusCard
            key="TopStatusCard"
            response={this.state.responseJson}
            iconPath={applicationAppliedIcon}
            headText={this.state.responseJson.visaTopHeaderMsg}
            color={[colors.Visa_blueStartColor, colors.Visa_blueEndColor]}
            card={card}
            onBackPress={this.goBack}
            pageName={this.trackPageName}
            headtextStyle={[styles.topStatusHeader]}
            subTextStyle={[styles.topStatusHeaderSubText]}
            bookingId={this.bookingId}
            scrollToVisaTracker={this.scrolltoVisaTracker}
          />);
          break;
        case VisaDetailConstant.TOP_STATUS_CARD_UNDERPROCESS_STATE:
          this.topStatusCardColor = [colors.Visa_lightblueStartColor, colors.Visa_lightblueendColor];
          views.push(<TopStatusCard
            key="TopStatusCard"
            response={this.state.responseJson}
            iconPath={inprocessIcon}
            headText={this.state.responseJson.visaTopHeaderMsg}
            color={[colors.Visa_lightblueStartColor, colors.Visa_lightblueendColor]}
            card={card}
            onBackPress={this.goBack}
            pageName={this.trackPageName}
            headtextStyle={[styles.topStatusHeader]}
            subTextStyle={[styles.topStatusHeaderSubText]}
            bookingId={this.bookingId}
            scrollToVisaTracker={this.scrolltoVisaTracker}
          />);
          break;
        case VisaDetailConstant.TOP_STATUS_CARD_OTB_REQUIRED_STATE:
          this.topStatusCardColor = [colors.Visa_GreenStartColor, colors.Visa_GreenEndColor];
          views.push(<TopStatusCard
            key="TopStatusCard"
            response={this.state.responseJson}
            iconPath={applicationAppliedIcon}
            headText={this.state.responseJson.visaTopHeaderMsg}
            color={[colors.Visa_GreenStartColor, colors.Visa_GreenEndColor]}
            card={card}
            onBackPress={this.goBack}
            pageName={this.trackPageName}
            headtextStyle={[styles.topStatusHeader]}
            subTextStyle={[styles.topStatusHeaderSubText]}
            bookingId={this.bookingId}
            scrollToVisaTracker={this.scrolltoVisaTracker}
          />);
          break;
        case VisaDetailConstant.TOP_STATUS_CARD_BOOKING_CANCELLED:
          this.topStatusCardColor = [colors.Visa_purpleStartColor, colors.Visa_purpleEndColor];
          views.push(<TopStatusCard
            key="TopStatusCard"
            response={this.state.responseJson}
            iconPath={applicationAppliedIcon}
            headText={this.state.responseJson.visaTopHeaderMsg}
            color={[colors.Visa_purpleStartColor, colors.Visa_purpleEndColor]}
            card={card}
            onBackPress={this.goBack}
            pageName={this.trackPageName}
            headtextStyle={[styles.topStatusHeader]}
            subTextStyle={[styles.topStatusHeaderSubText]}
            bookingId={this.bookingId}
            scrollToVisaTracker={this.scrolltoVisaTracker}
          />);
          break;
        case VisaDetailConstant.TOP_STATUS_CARD_TRAVEL_COMPLETED_STATE:
          this.topStatusCardColor = [colors.Visa_blackStartColor, colors.Visa_blackEndColor];
          views.push(<TopStatusCard
            key="TopStatusCard"
            response={this.state.responseJson}
            iconPath={applicationAppliedIcon}
            headText={this.state.responseJson.visaTopHeaderMsg}
            color={[colors.Visa_blackStartColor, colors.Visa_blackEndColor]}
            card={card}
            onBackPress={this.goBack}
            pageName={this.trackPageName}
            headtextStyle={[styles.topStatusHeader]}
            subTextStyle={[styles.topStatusHeaderSubText]}
            bookingId={this.bookingId}
            scrollToVisaTracker={this.scrolltoVisaTracker}
          />);
          break;
        case VisaDetailConstant.TOP_STATUS_CARD_ALL_VISA_REJECTED_STATE:
          this.topStatusCardColor = [colors.Visa_OrangeStartColor, colors.Visa_OrangeEndColor];
          views.push(<TopStatusCard
            key="TopStatusCard"
            response={this.state.responseJson}
            iconPath={applicationrejectedIcon}
            headText={this.state.responseJson.visaTopHeaderMsg}
            color={[colors.Visa_OrangeStartColor, colors.Visa_OrangeEndColor]}
            card={card}
            onBackPress={this.goBack}
            pageName={this.trackPageName}
            headtextStyle={[styles.topStatusHeader]}
            subTextStyle={[styles.topStatusHeaderSubText]}
            bookingId={this.bookingId}
            scrollToVisaTracker={this.scrolltoVisaTracker}
          />);
          break;
        case VisaDetailConstant.TOP_STATUS_CARD_ADDITIONAL_DOCUMENT_STATE:
          this.topStatusCardColor = [colors.Visa_lightblueStartColor, colors.Visa_lightblueendColor];
          views.push(<TopStatusCard
            key="TopStatusCard"
            response={this.state.responseJson}
            iconPath={additionaldocumentIcon}
            headText={this.state.responseJson.visaTopHeaderMsg}
            color={[colors.Visa_lightblueStartColor, colors.Visa_lightblueendColor]}
            card={card}
            onBackPress={this.goBack}
            pageName={this.trackPageName}
            headtextStyle={[styles.topStatusHeader]}
            subTextStyle={[styles.topStatusHeaderSubText]}
            bookingId={this.bookingId}
            scrollToVisaTracker={this.scrolltoVisaTracker}
          />);
          break;
        case VisaDetailConstant.TOP_STATUS_CARD_APPROVED_STATE:
          this.topStatusCardColor = [colors.Visa_GreenStartColor, colors.Visa_GreenEndColor];
          views.push(<TopStatusCard
            key="TopStatusCard"
            response={this.state.responseJson}
            iconPath={visaapprovedIcn}
            headText={this.state.responseJson.visaTopHeaderMsg}
            color={[colors.Visa_GreenStartColor, colors.Visa_GreenEndColor]}
            card={card}
            onBackPress={this.goBack}
            pageName={this.trackPageName}
            headtextStyle={[styles.topStatusHeader]}
            subTextStyle={[styles.topStatusHeaderSubText]}
            bookingId={this.bookingId}
            scrollToVisaTracker={this.scrolltoVisaTracker}
          />);
          break;
        case VisaDetailConstant.TOP_STATUS_CARD_OTB_PENDING_STATE:
          this.topStatusCardColor = [colors.Visa_GreenStartColor, colors.Visa_GreenEndColor];
          views.push(<TopStatusCard
            key="TopStatusCard"
            response={this.state.responseJson}
            iconPath={visaapprovedIcn}
            headText={this.state.responseJson.visaTopHeaderMsg}
            color={[colors.Visa_GreenStartColor, colors.Visa_GreenEndColor]}
            card={card}
            onBackPress={this.goBack}
            pageName={this.trackPageName}
            headtextStyle={[styles.topStatusHeader]}
            subTextStyle={[styles.topStatusHeaderSubText]}
            bookingId={this.bookingId}
            scrollToVisaTracker={this.scrolltoVisaTracker}
          />);
          break;
        case VisaDetailConstant.TOP_STATUS_CARD_PARTIAL_VISA_REJECTED_STATE:
          this.topStatusCardColor = [colors.Visa_OrangeStartColor, colors.Visa_OrangeEndColor];
          views.push(<TopStatusCard
            key="TopStatusCard"
            response={this.state.responseJson}
            iconPath={visaapprovedIcn}
            headText={this.state.responseJson.visaTopHeaderMsg}
            color={[colors.Visa_OrangeStartColor, colors.Visa_OrangeEndColor]}
            card={card}
            onBackPress={this.goBack}
            pageName={this.trackPageName}
            headtextStyle={[styles.topStatusHeader]}
            subTextStyle={[styles.topStatusHeaderSubText]}
            bookingId={this.bookingId}
            scrollToVisaTracker={this.scrolltoVisaTracker}
          />);
          break;
        case VisaDetailConstant.VISA_DETAIL_CARD:
          views.push(<VisaMainCard
            key={VisaDetailConstant.VISA_DETAIL_CARD}
            visaDescription={this.state.responseJson.visaBookingInfo.visaDetailList[0].visaDescription}
            validity={this.state.responseJson.visaBookingInfo.visaDetailList[0].validity}
            applicants={this.state.responseJson.visaBookingInfo.visaDetailList.length}
            dateofEntry={this.state.responseJson.visaBookingInfo.visaDetailList[0].dateOfEntry}
            dateofExit={this.state.responseJson.visaBookingInfo.visaDetailList[0].dateOfExit}
            visaCountry={this.state.responseJson.visaBookingInfo.visaDetailList[0].countryName}
            countryURL={this.state.responseJson.visaBookingInfo.visaDetailList[0].countryurl.toLowerCase()}
          />);
          break;
        case VisaDetailConstant.VISA_APPLICANT_CARD:
          views.push(<VisaApplicantCard
            key={VisaDetailConstant.VISA_APPLICANT_CARD}
            response={this.state.responseJson}
            card={card}
            pageName={this.trackPageName}
            payRefId={this.props.refBookingId}
          />);
          break;
        case VisaDetailConstant.VISA_CANCELLATIONPOLICY_CARD:
          views.push(<VisaCancellationPolicyCard
            key={VisaDetailConstant.VISA_CANCELLATIONPOLICY_CARD}
            response={this.state.responseJson}
            card={card}
            pageName={this.trackPageName}
          />);
          break;
        case VisaDetailConstant.VISA_OTBPOLICY_CARD:
          const card2 = this.state.responseJson.visaBookingInfo.visaDetailList[0];
          const {otbHeading, otbDescription} = card2;
          if (_.isEmpty(otbHeading) || _.isEmpty(otbDescription)) {
            break;
          } else {
            views.push(<VisaOtbPolicyCard
              key={VisaDetailConstant.VISA_OTBPOLICYPOLICY_CARD}
              response={this.state.responseJson}
              card={card}
              pageName={this.trackPageName}
            />);
            break;
          }
        case VisaDetailConstant.VISA_PAYMENT_CARD:
          views.push(<VisaPaymentCard
            key={VisaDetailConstant.VISA_PAYMENT_CARD}
            paymentresponse={this.state.responseJson.pricingSummary.pricingSummaryDetail}
            response={this.state.responseJson}
            card={card}
            pageName={this.trackPageName}
          />);
          break;
        case COMMON_CARD.TRAVEL_CARD:
          if(!isEmpty(this.state.responseJson.travelCardResponse)){
            views.push(
              <TravelCashCard
                key="TravelCashCard"
                travelCardResponse={this.state.responseJson.travelCardResponse}
                uuid={this.state.responseJson.uuid}
                bookingID={this.bookingId}
              />,
              );
            }
            break;
        case CHAT_WITH_MYRA_CARD:
          if (isMyraEnabled(this.state.responseJson.lobCode)) {
            views.push(<ChatWithMyraCard
              key={card.cardId}
              response={this.state.responseJson}
              card={card}
              pageName={this.trackPageName}
            />);
          }
          break;
        case VisaDetailConstant.VISA_NEED_HELPUS_CARD:
          if (!isMyraEnabled(this.state.responseJson.lobCode)) {
            views.push(<NeedHelpCard
              key={VisaDetailConstant.VISA_PAYMENT_CARD}
              response={this.state.responseJson}
              card={card}
              pageName={this.trackPageName}
              phoneNumber={this.state.responseJson.customerCareNumber}
            />);
          }
          break;
        case VisaDetailConstant.VISA_REJECTION_Message:
          views.push(<VisaRejectionMessage
            visaCountry={this.state.responseJson.visaBookingInfo.visaDetailList[0].countryName}
            visaRejectionString={this.state.responseJson.visaRejectionReasonString}
            card={card}
            pageName={this.trackPageName}
            response={this.state.responseJson}
          />);
          break;
        case VisaDetailConstant.VISA_REJECTION_REASON:
          views.push(<VisaRejectionReasons
            visaCountry={this.state.responseJson.visaBookingInfo.visaDetailList[0].countryName}
            card={card}
            pageName={this.trackPageName}
            response={this.state.responseJson}
          />);
          break;
        case VisaDetailConstant.VISA_TRACKER_CARD:
          views.push(<VisaTrackerCard
            key={VisaDetailConstant.VISA_TRACKER_CARD}
            visaExpectedDate={this.state.responseJson.receivingDate}
            visaSteps={this.state.responseJson.visaStepList}
            card={card}
            refBookingId={this.props.refBookingId}
            pageName={this.trackPageName}
            response={this.state.responseJson}
            updateVisaTracker={this.updateVisaTrackerCoordinates}
            onCardLayout={({nativeEvent}) => {
                const {
                    x, y
                } = nativeEvent.layout;
                this.setState({
                    visaTrackerLayout: {
                        x, y
                    }
                });
}}
          />);
          break;
        case VisaDetailConstant.VISA_ADDITIONAL_DOCUMENT:
          views.push(<VisaAdditionalDocumentCard card={card} response={this.state.responseJson} pageName={this.trackPageName} message={this.state.responseJson.additionalDocRequired} />);
          break;
        case VisaDetailConstant.ALERT_INFO_CARD:
          views.push(<AlertInfo
            key="AlertInfo"
            infoList={this.state.responseJson.importantInformationList}
          />);
          break;
        default:
          break;
      }
    }

  goBack = () => {
    VisaBookingTrackingHelper.trackClickEvent(
      'VisaBookingDetail',
      this.state.responseJson,
      'back-clicked',
    );
    if (this.props.holidayBookingApi) {
      Actions.pop();
      return;
    }
    const somethingPoped = Actions.pop();
    if (somethingPoped != null && !somethingPoped) {
        if (Platform.OS === 'ios') {
          ViewControllerModule.popViewController(1);
        } else {
          BackHandler.exitApp();
        }
      }
    };

    renderNoNetworkView = () => (
      <View style={{flex: 1}}>
        <SimpleHeader
          title="Back"
          iconPress={this.goBack}
        />
        <NoInternetView
          onRetry={() => {
                    this.setState({viewState: ViewState.LOADING});
                    this.fetchJSONAsync(VISA_BOOKING_DETAIL_URL + this.bookingId);
                }}
        />
      </View>
    );

    renderErrorView = () => (
      <View style={{flex: 1}}>
        <SimpleHeader
          title="Back"
          iconPress={this.goBack}
        />
        <ErrorView
          showRetry
          onRetry={() => {
                    this.setState({viewState: ViewState.LOADING});
                    this.fetchJSONAsync(VISA_BOOKING_DETAIL_URL + this.bookingId);
                }}
          buttonGradient={themeColors.gradientBtnColor}
        />
      </View>);

    renderAuthErrorView = () => <AuthErrorView bookingId={this.bookingId} lobName={LOBNAMES.VISA} onGoBackPress={this.goBack} />

    renderProgressView = () => (<ProgressView message="Loading Your Visa Details..." />);
}
