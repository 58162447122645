import React from 'react';
import { View } from 'react-native';
import PrimaryUserDetailsCard from '../PrimaryUserDetailsCard';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { useTheme } from '../../../../../../../theme/theme.context';
import createStyles from './style';
import isEmpty from 'lodash/isEmpty';

const VisaRequestDetailsCard = ({ primaryDetails }) => {
  const fonts = getFont();
  const { psTheme: theme } = useTheme();
  const styles = createStyles(fonts, theme);
  return (
    <View style={styles.visaDetailsCard}>
      {!isEmpty(primaryDetails) && (
        <PrimaryUserDetailsCard
          heading={primaryDetails.heading}
          description={primaryDetails.description}
          email={primaryDetails.email}
          phoneNo={primaryDetails.phoneNo}
        />
      )}
    </View>
  );
};

export default VisaRequestDetailsCard;
