import { StyleSheet } from 'react-native';

export default (theme, fonts) => 
StyleSheet.create({
    thankYouWrapper: {
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        backgroundColor: theme.color.white,
        paddingHorizontal: '10%',
    },
    successIcon: {
        width: 40,
        height: 40,
        marginRight: 15,
        marginBottom: 10,
        alignSelf: 'center',
    },
    messageTitle: {
        textAlign: 'center',
        color: theme.color.black,
        fontFamily: fonts.bold,
        marginBottom: 27,
    },
    messageText: {
        textAlign: 'center',
        color: theme.color.black,
    },
    CommonFillBtnGradient: {
        borderRadius: 25,
        paddingVertical: 11,
        paddingHorizontal: 25,
        marginTop: 24,
    },
});