import React from 'react';
import PropTypes from 'prop-types';
import {View, Text, Image, StyleSheet} from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {getContactIconForType, getIconsClassName} from '../HolidayBookingDetailUtils';
import {blueDropIcondown, blueDropIconUp} from '../HolidayBookingConstants';

class ContactListItemHeader extends React.Component {
  static navigationOptions = {
    header: null
  };

  render() {
    const iconClassName = getIconsClassName(this.props.item.contactType);

    return (
      <View style={[styles.CollapsedTapContainer1, AtomicCss.alignCenter]}>
        <View style={[styles.CollapsedTapLeftSection, AtomicCss.flexDirection, AtomicCss.flexRow]}>
          <View style={styles.ImageWrapper}>
            <Image
              source={getContactIconForType(this.props.item.contactType)}
              style={styles[iconClassName]}/>
          </View>
          <View style={AtomicCss.flex1}>
            <Text
              numberOfLines={1}
              ellipsizeMode="tail"
              style={[AtomicCss.font16, AtomicCss.blackText, AtomicCss.blackFont,
                AtomicCss.marginBottom5]}
            >
              {this.props.item.contactHeading}
            </Text>
            <Text
              style={[AtomicCss.font14, AtomicCss.defaultTextColor, AtomicCss.regularFont]}
            >
              {this.props.item.contactSubHeading}
            </Text>
          </View>
        </View>
        <View style={[styles.CollapsedTapRightSection, AtomicCss.marginTop10]}>
          <Image
            style={[styles.AlignSelfRight, styles.blueArrow, AtomicCss.marginBottom10]}
            source={this.props.tabbing ? blueDropIconUp : blueDropIcondown}/>
        </View>
      </View>

    );
  }
}

const styles = StyleSheet.create({
  CollapsedTapContainer1: {
    backgroundColor: 'transparent',
    paddingHorizontal: 20,
    paddingVertical: 15,
    flexDirection: 'row'
  },
  CollapsedTapLeftSection: {
    width: '90%'
  },
  CollapsedTapRightSection: {
    width: '10%'
  },

  blueArrow: {
    width: 13,
    height: 7
  },
  AlignSelfRight: {
    alignSelf: 'flex-end'
  },

  ImageWrapper: {
    width: 22,
    height: 22,
    marginTop: 2,
    marginRight: 12
  },

  supportIcon: {
    width: 21,
    height: 21
  },

  busIcon: {
    width: 19,
    height: 21
  },

  carIcon: {
    width: 20,
    height: 19
  },

  hotelIcon: {
    width: 21,
    height: 19
  },

  cruiseIcon: {
    width: 17,
    height: 22
  }

});

ContactListItemHeader.propTypes = {
  item: PropTypes.object.isRequired
};

export default ContactListItemHeader;
