import React from 'react';
import RenderFlightDetailPage from '../details/Components/RenderFlightDetailPage';
import { fligtNewTrackingOmniture } from 'apps/post-sales/src/flights/v2/utils/trackingUtil';
interface FlightDetailedInfoProps {
  BOOKING_ID?: string;
  bookingId?: string;
}
const FlightDetailedInfo: React.FC<FlightDetailedInfoProps> = (props) => {
  const bookingId = props.BOOKING_ID || props.bookingId || '';
  const { onLoad, onClick, onError } = fligtNewTrackingOmniture();
  const renderFlightPageProps = {
    bookingId,
    onLoad,
    onClick,
    onError,
    pageRequested: 'FLIGHT_TRIP_DETAILS',
    isDetailedPage: true,
    extraPropsData: {
      bookingId,
    },
  };
  return (
    <RenderFlightDetailPage {...renderFlightPageProps} />
  );
};

export default FlightDetailedInfo;
