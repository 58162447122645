import React, { useCallback, useRef } from 'react';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import Button, { BUTTON_VARIANT } from '../../../../Common/BaseButton';
import { CROSS_ICON } from '../../../../flights/FlightImageConstants';
import Actions from '../../../../navigation/postSalesNavigation';
import { getStaticData } from '../../../../staticData/staticData';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import CommonBottomOverlayMessage from '../../../../Common/CommonOverlayMessage/CommonBottomOverlayMessage';
import { LANG_AWARE } from '../../../HotelConstants';
import { useTheme } from '../../../../theme/theme.context';
import { PanDetailOverlayContentProps, PanDetailOverlayProps } from './types';
import HTMLView from 'react-native-htmlview';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const createHtmlStyle = (theme: any, fonts: any) => StyleSheet.create({
  span:{
    color: theme.color.textGrey,
    fontFamily: fonts.regular,
  },
  u: {
    textDecorationLine: 'underline',
  }
});

const { fsStyle, ...fonts } = getFont(true);

const PanDetailOverlayContent = (props: PanDetailOverlayContentProps) => {
  const { styles, bookingResponse } = props;
  const { panCardDetails } = bookingResponse || {};
  const { knowMoreText, knowMoreUrl } = panCardDetails || {};
  const staticData = getStaticData(LANG_AWARE.MODIFICATION);
  const { psTheme: theme } = useTheme();
  const htmlStyleText = createHtmlStyle(theme, fonts);
  const clickHandler = useCallback(() => {
    Actions.openPsWebView({
      headerText: staticData?.panCardHeadingText,
      url: knowMoreUrl,
      showHeader: true,
    });
    props.handleCloseIcon();
  }, []);
  return (
    <View style={AtomicCss.marginBottom20}>
      <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween, styles.overlayHeader]}>
        <Text style={[fsStyle.font20, fonts.boldFontFamily, AtomicCss.blackText]}>
          {staticData?.panCardHeadingText}
        </Text>
        <TouchableOpacity
          activeBtnOpacity={0.7}
          style={styles.cancelWrapper}
          onPress={props.handleCloseIcon}
        >
          <Image source={CROSS_ICON} style={styles.overlayIcon} resizeMode={'contain'} />
        </TouchableOpacity>
      </View>
      <View style={styles.overlayBody}>
        <View style={AtomicCss.marginBottom20}>
          <HTMLView stylesheet={htmlStyleText} value={knowMoreText.replace('T&C', '')} />
        </View>
        <View>
          <Button  variant={BUTTON_VARIANT.PRIMARY} clickHandler={clickHandler} text={staticData?.exploreText} />
        </View>
      </View>
    </View>
  );
};

const PanDetailOverlay = (props: PanDetailOverlayProps) => {
  const { panCardDetails } = props.bookingResponse || {};
  const { knowMoreText } = panCardDetails || {};
  const overlayRef = useRef();
  const { psTheme: theme } = useTheme();
  const styles = createStyle(theme, fonts);
  const handleCloseIcon = useCallback(() => {
    overlayRef.current && overlayRef.current.closeOverlay();
  }, [props.dismiss]);

  if (!!knowMoreText) {
    return (
      <CommonBottomOverlayMessage
        ref={overlayRef}
        dismiss={props.dismiss}
        overlayContentStyle={styles.overlayContent}
        content={
          <PanDetailOverlayContent {...props} handleCloseIcon={handleCloseIcon} styles={styles} />
        }
      />
    );
  }
};

export default PanDetailOverlay;

const createStyle = (theme: any, fonts: any) =>
  StyleSheet.create({
    overlayContent: {
      backgroundColor: theme.color.white,
      shadowColor: '#330000',
      shadowOpacity: 0.3,
      shadowRadius: 2,
      elevation: 4,
      zIndex: 4,
      borderTopLeftRadius: 24,
      borderTopRightRadius: 24,
      shadowOffset: {
        width: 1,
        height: 0,
      },
      paddingHorizontal: 24,
    },
    overlayIcon: {
      height: 10,
      width: 10,
      tintColor: theme.color.white,
    },
    cancelWrapper: {
      padding: 5,
      backgroundColor: theme.color.disabledButton,
      borderRadius: 10,
    },
    overlayHeader: {
      paddingVertical: 24,
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    overlayBody: {
      backgroundColor: theme.color.white,
      paddingBottom: 15,
    },
    desc: {
      color: theme.color.gray29,
      ...fonts.font14,
      fontFamily: fonts.regular,
      lineHeight: 20,
    },
  });
