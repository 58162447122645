import React, { useEffect } from 'react';
import { View, Text, StyleSheet, Image } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import { Fonts, Theme } from 'apps/post-sales/src/cab/types';
import CabBookingTrackingHelper from 'apps/post-sales/src/cab/CabBookingTrackingHelper';
import { CLICK_EVENT } from 'apps/post-sales/src/cab/CabBookingDetailsConstant';
import Loader from 'apps/post-sales/src/Common/SpinnerLoader/Loader';

interface FetchingDriverDetailsAnimationProps {
  cardData: {
    bookingId: string;
    confirmYourLandingData: {
      loaderData: {
        text: string;
        gifUrl: string;
      };
    };
  };
  pageName: string;
}

const FetchingDriverDetailsAnimation = ({
  cardData,
  pageName,
}: FetchingDriverDetailsAnimationProps) => {
  const { confirmYourLandingData } = cardData || {};
  const { loaderData } = confirmYourLandingData || {};
  const { text, gifUrl } = loaderData || {};
  const { fsStyle, ...fonts } = getFont();
  const { psTheme: theme } = useTheme();
  const styles = getStyles(fonts, theme);

  useEffect(() => {
    CabBookingTrackingHelper.trackClickEvent(pageName, CLICK_EVENT.ASSIGNING_DRIVER_CARD_SHOWN);
  }, []);

  return (
    <View style={[AtomicCss.alignCenter, AtomicCss.marginVertical20]}>
      {!!gifUrl &&
        <View style={styles.imageContainer}>
          <Image
            style={styles.iconStyle}
            source={{ uri: gifUrl }}
          />
        </View>
      }
      {!!text && (
        <Text style={[styles.loadingText, AtomicCss.textCenter, AtomicCss.lineHeight16]}>
          {text}
        </Text>
      )}
    </View>
  );
};

const getStyles = (fonts: Fonts, theme: Theme) =>
  StyleSheet.create({
    imageContainer: {
      width: 159,
      height: 100, // hiding lower part of the gif
      overflow: 'hidden',
    },
    iconStyle: {
      width: 159,
      height: 133,
    },
    loadingText: {
      fontSize: fonts.fontSize.font14,
      color: theme.color.textGrey,
      ...fonts.regularFontFamily,
    },
  });
export default FetchingDriverDetailsAnimation;
