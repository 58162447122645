import React, {Component} from 'react';
import {View, Text, Image} from 'react-native';
import styles from './TotalRefundCardCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {isEmpty} from 'lodash';
import ZoomCarConstant from "../../../utils/ZoomCarConstants";
import {getButtonStyle, handleActionClicks, handleZcClickEvent} from "../../../utils/SelfDriveBookingDetailUtil";
import {getActionButton} from "../../../../Rails/details/RailBookingDetailsUtil";
import ZoomCarConstants from "../../../utils/ZoomCarConstants";
import Button from "../../../../Common/Button";
import {REFUNDICON_GROUP_115,SWIPE_CARD,DISOUNT_ICON} from '../../../SelfDriveImageConstant';

class TotalRefundCard extends Component {

    render() {

        const {paymentBreakups} = this.props.response.paymentInfo;
        const {actionList} = this.props.card
        let payNowInfo = actionList.filter(a=>a.actionType === ZoomCarConstants.ACTION_PAY_NOW_CTA)[0];
        let paidAmountDetail = paymentBreakups.filter(k => k.key === ZoomCarConstant.TOTAL_PAID_KEY)[0];
        let discountDetail = !isEmpty(this.props.response.pricingInfo.pricingbreakups) ? this.props.response.pricingInfo.pricingbreakups.filter(k => k.key === ZoomCarConstant.E_COUPON_KEY)[0] : '';
        let outstandingAmount = this.props.response.additionalInfo.outstandingAmount;
        const outStandingAmountApplicable = this.props.response.additionalInfo.outstanding ?this.props.response.additionalInfo.outstanding : false;
        let securityDepositAmount = '';
        if (!isEmpty(this.props.response.securityDepositDetails) && !isEmpty(this.props.response.securityDepositDetails.securityPriceBreakup)) {
            securityDepositAmount = this.props.response.securityDepositDetails.securityPriceBreakup.filter(k => k.key === ZoomCarConstant.SECURITY_DEPOSIT_KEY)[0];
        }

        return (
            <View style={[AtomicCss.marginBottom10, styles.cardBorder]}>
                <View style={[styles.infoCardWrapper]}>

                    <View style={AtomicCss.flexRow}>
                        <View style={styles.headingIconWrapper}>
                            <Image style={styles.headingIconStyle} source={SWIPE_CARD}/>
                        </View>
                        <View style={[styles.infoCardContent]}>
                            <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween, AtomicCss.marginBottom10]}>
                                <View>
                                    <Text
                                        style={[AtomicCss.defaultText, AtomicCss.blackFont, AtomicCss.font14, AtomicCss.marginBottom3]}>TOTAL
                                        PAID </Text>
                                    {!isEmpty(discountDetail) && <View style={AtomicCss.flexRow}>
                                        <Image style={styles.disIcon} source={DISOUNT_ICON}/>
                                        <Text style={[AtomicCss.regularFont, styles.greenTxt]}>You saved
                                            {`₹ ${discountDetail.amount} `}</Text>
                                    </View>}
                                </View>
                                {!isEmpty(paidAmountDetail) && <View style={styles.totalRefundprice}>
                                    <Text
                                        style={[AtomicCss.defaultText, AtomicCss.blackFont, AtomicCss.font14]}>{`₹ ${paidAmountDetail.amount} `}</Text>
                                </View>}
                            </View>
                            {
                                paymentBreakups && paymentBreakups.map((payment, key) => {
                                    if (payment.key === ZoomCarConstant.TOTAL_PAID_KEY) {
                                        return;
                                    }
                                    return (
                                        <View key={key}
                                              style={[AtomicCss.flexRow, AtomicCss.spaceBetween, styles.totalRefundprice, AtomicCss.marginBottom5]}>
                                            <Text
                                                style={[AtomicCss.regularFont, AtomicCss.defaultText, AtomicCss.font14]}>{payment.modeLabel}</Text>
                                            <Text
                                                style={[AtomicCss.regularFont, AtomicCss.defaultText, AtomicCss.font14]}>{`₹ ${payment.amount} `}</Text>
                                        </View>
                                    )
                                })
                            }
                            {outStandingAmountApplicable && <View key={outstandingAmount}
                                  style={[AtomicCss.flexRow, AtomicCss.spaceBetween, styles.totalRefundprice, AtomicCss.marginBottom5, AtomicCss.marginTop15 ]}>
                                <Text
                                    style={[AtomicCss.blackFont, AtomicCss.defaultText, AtomicCss.font14, AtomicCss.textUppercase, AtomicCss.boldFont]}>Outstanding balance</Text>
                                <Text
                                    style={[AtomicCss.blackFont, AtomicCss.defaultText, AtomicCss.font14, AtomicCss.boldFont]}>{`₹ ${outstandingAmount} `}</Text>
                            </View>}

                            {!outStandingAmountApplicable && !isEmpty(securityDepositAmount) && <View style={styles.depositeCard}>
                                <View style={styles.imgPlaceHolder}>
                                    <Image style={styles.priceImgStyle} source={REFUNDICON_GROUP_115}/>
                                </View>
                                <Text
                                    style={[AtomicCss.font12, AtomicCss.defaultText, AtomicCss.regularFont, AtomicCss.flex1]}>
                                    {`Security deposit of ₹ ${securityDepositAmount.amount} will be refunded after validation, if there is no car damage.`}
                                </Text>
                            </View>}

                        </View>
                    </View>
                    {outStandingAmountApplicable && !isEmpty(payNowInfo) && <View style={styles.btnWrapper}>
                        <Button
                            btnTxt={payNowInfo.actionLabeltext}
                            btnBg="gradientBtn"
                            btnType="Capsule"
                            btnWidth={300}
                            clickHandler={this.handleClickPayNow}
                            btnClickAction={ZoomCarConstants.OUTSTANDING_AMOUNT}/>
                    </View>}
                </View>
                {!isEmpty(actionList) &&
                <View
                    style={!isEmpty(this.props.card) ? !isEmpty(actionList) ? getButtonStyle(actionList) : styles.hideView : styles.hideView}>
                    {!isEmpty(this.props.card) ? getActionButton(this.props.card, this.props.response, this.handleClick) : null}
                </View>
                }
            </View>
        )

    }
    handleClickPayNow = () => {
        handleActionClicks(this.props.card, ZoomCarConstants.ACTION_PAY_NOW_CTA, this.props.response, this.props.pageName).then(r => console.log('clicked event'));
    }
    handleClick = (action) => {
        handleZcClickEvent(this.props.card, action, this.props.response, this.props.pageName).then(r => console.log('clicked event'));
    };
}

export default TotalRefundCard
