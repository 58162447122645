import { openDialer } from '../Common/commonUtil';
import isEmpty  from 'lodash/isEmpty';
import PostSalesBookingTrackingHelper from '../PostSalesBookingTrackingHelper';
import { MSR_TYPE, Lobs, TalkToUsConstants } from './summaryConstants';
import {isEscalateEnabled, isTalkToUsEnabled} from '../utils/PostSaleUtil';
import { openWebView } from '../Common/MSR/Utils';
import myTripsNavigation from './navigation/navigation';
import { isEnglishLangSelected } from '@mmt/legacy-commons/AppState/AppUserConfig';
import NetworkModule from '@mmt/legacy-commons/Native/NetworkModule';
import { isPreSalesEnabled } from '../utils/PostSaleUtil';
import { callSupportAPI } from './data/api';
import { BOOKING_SUMMARY_PAGE_NAME } from './components/AddBooking/tracking';

const getActionHandler = (action, type, bookingID, ticketId, productCode, isInternational, uuid, shouldConnectToAgent) => {
    switch(type){
        case MSR_TYPE.TALK_TO_US:
        return () => {
          if (action && action.contactNo) {
            PostSalesBookingTrackingHelper.trackClickEvent(BOOKING_SUMMARY_PAGE_NAME, "mytrips_talktous_clicked", bookingID, ticketId);
            openDialer(action.contactNo);
            if (shouldConnectToAgent) {
              callSupportAPI(bookingID, productCode, isInternational, uuid, BOOKING_SUMMARY_PAGE_NAME)
            }
          }
        }
        case MSR_TYPE.ESCALATE:
        return ()=>{
            if(action && action.msrUrl){
            PostSalesBookingTrackingHelper.trackClickEvent("mob:customer support:all:mytrips", "mytrips_escalate_clicked", bookingID, ticketId)
            openWebView(action.msrUrl);
            }
        }
        default:()=>{}
    }
}
export const getTalkToUsDetails = (productCode,uuid,isInternational=false,shouldConnectToAgent=false) => {
  return {
    productCode,
    uuid,
    isInternational,
    shouldConnectToAgent
  }
}
export function getNeedHelpData(msrTicket, bookingID, talktoUsData, showQuickResolve, talkToUsDetails) {
  const {needHelpData} = msrTicket || {};
  const needHelpActionData = [];
  const { productCode, isInternational, uuid, shouldConnectToAgent = false } = talkToUsDetails || {};
  if(!isEmpty(needHelpData) ){
      const {type, title, action} = needHelpData;
      if(type === MSR_TYPE.ESCALATE && isEscalateEnabled() && showQuickResolve ){
        PostSalesBookingTrackingHelper.trackMsrCardShownEvent("mob:customer support:all:mytrips", "mytrips_escalate_loaded", bookingID, msrTicket.ticketId);
        needHelpActionData.push({
          title: title,
          action: {
            label: action && action.label,
            iconUrl: action && action.iconUrl,
            onPress: getActionHandler(action, type, bookingID, msrTicket.ticketId)
          }
        })
      }
  }
  if(!isEmpty(talktoUsData) ){
    const {type, title, action} = talktoUsData;
    if(type === MSR_TYPE.TALK_TO_US && isTalkToUsEnabled() ){
      PostSalesBookingTrackingHelper.trackMsrCardShownEvent("mob:customer support:all:mytrips", "mytrips_talktous_loaded", bookingID);
      needHelpActionData.push({
          title: title,
          action: {
            label: action && action.label,
            iconUrl: action && action.iconUrl,
            onPress: getActionHandler(action, type, bookingID, '', productCode, isInternational, uuid, shouldConnectToAgent),
        }
      });
    }
  }
  return needHelpActionData;
}

export const getProductType = (productCode, isInternational) => {
  switch (productCode) {
    case Lobs.Flight:
      return isInternational ? TalkToUsConstants.INTERNATIONAL_FLIGHT : TalkToUsConstants.DOMESTIC_FLIGHT;
    case Lobs.Hotel:
      return isInternational ? TalkToUsConstants.INTERNATIONAL_HOTEL : TalkToUsConstants.DOMESTIC_HOTEL;
    case Lobs.Cabs:
    case Lobs.Cars:
    case Lobs.Bus:
    case Lobs.Rail:
    case Lobs.Trains:
      return isInternational ? TalkToUsConstants.INTERNATIONAL_GROUND : TalkToUsConstants.DOMESTIC_GROUND;
    default:
      return null;
  }
};

export const openDetailsPageByLob = (lobType, response, pageData) => {
  switch (lobType) {
    case Lobs.Flight:
      myTripsNavigation.openFlightBookingDetail(response, false, pageData);
      break;
    case Lobs.Hotel:
      myTripsNavigation.openHotelBookingDetail(response, pageData, false);
      break;
    case Lobs.Bus:
      myTripsNavigation.openBusBookingDetail(response, pageData, false);
      break;
    case Lobs.Trains:
      myTripsNavigation.openRailBookingDetail(response, pageData, false);
      break;
    case Lobs.Cars:
      myTripsNavigation.openCabBookingDetail(response, pageData, false);
      break;
    case Lobs.Metro:
      myTripsNavigation.openMetroBookingDetail(response, pageData, false);
      break;
    case Lobs.Acme:
      myTripsNavigation.openActivityBookingDetails(response, pageData, false);
      break;
    case Lobs.SelfDrive:
      myTripsNavigation.openSelfDriveBookingDetail(response, pageData, false);
      break;
    case Lobs.Visa:
      myTripsNavigation.openVisaBookingDetail(response, pageData, false);
      break;
    case Lobs.GiftCard:
      myTripsNavigation.openGiftCardBookingDetail(response, pageData, false);
      break;
    case Lobs.HOLIDAYS:
      myTripsNavigation.openHolidayBookingDetail(response, pageData, false);
      break;
    case Lobs.TGS:
      myTripsNavigation.openTripGuaranteeBookingDetail(response, pageData, false);
      break;
    case Lobs.INSURANCE:
      myTripsNavigation.openInsuranceBookingDetail(response,pageData,false);
      break;
    default:
      return null;
  }
};

export const shouldLoadPlanningData = async () => {
  const prev = isEnglishLangSelected() && await isPreSalesEnabled();
  const header = await NetworkModule.getHeaders();
  return header.hasOwnProperty('region') && header.region != 'ae' && prev;
};
