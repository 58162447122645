import React from 'react';
import { View, Text, Image } from 'react-native';
import PropTypes from 'prop-types';
import calendarTick from '@mmt/legacy-assets/src/calendarTick.webp';
import createStyles from './styles';
import { getStaticData } from '../../../../../staticData/staticData';
import { getFont } from '../../../../../PostSalesStyles';
import { useTheme } from '../../../../../theme/theme.context';
import BaseButton, { BUTTON_VARIANT } from '../../../../../Common/BaseButton';
import useHardwareBackpress from 'apps/post-sales/src/Common/useHardwareBackpress';
import { FLIGHT_THROTTLE_OBJECT } from '../../../details/FlightBookingDetailsConstant';

const ClaimSubmit = (props) => {
  const { backToDetailsText, requestSubmittedText } = getStaticData();
  const { psTheme: theme } = useTheme();
  const fonts = getFont(true);
  const styles = createStyles(theme, fonts);
  useHardwareBackpress().addListener(props.onPressHandler, true)
  return (
    <View style={styles.pageWrapper}>
      <View style={[styles.messageContent, styles.marginBottom80]}>
        <Image source={calendarTick} style={styles.calendarTickIcon} />
        <Text style={styles.messageTitle}>{requestSubmittedText}</Text>
        <Text style={styles.messageDesc}>{props.message}</Text>
      </View>
      <View style={styles.footerBtnWrapper}>
      <BaseButton
        text={backToDetailsText}
        clickHandler={props.onPressHandler}
        variant={BUTTON_VARIANT.CAPSULE}
        {...FLIGHT_THROTTLE_OBJECT}
      />
      </View>
    </View>
  );
};

ClaimSubmit.propTypes = {
  message: PropTypes.string.isRequired,
  onPressHandler: PropTypes.func.isRequired,
};

export default ClaimSubmit;
