import React from 'react';
import {StyleSheet, View, PixelRatio, Platform} from 'react-native';
import PropTypes from 'prop-types';
import {colors} from '../../../Styles/globalStyles';
import getPlatformElevation from './getPlatformElevation';


class Card extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      style: this.getStyles(props.style)
    };
  }

  getStyles = (customStyle, elevation = this.props.elevation) => {
    const {showBorder} = this.props;
    const borderProps = showBorder ? {
      borderWidth: 1 / PixelRatio.getPixelSizeForLayoutSize(1),
      borderColor: '#e7e7e7'
    } : {};
    return StyleSheet.flatten([{
      backgroundColor: colors.white,
      borderRadius: 2,
      marginVertical: 4,
      marginHorizontal: 8,
      ...borderProps,
      ...getPlatformElevation(elevation),
      ...customStyle
    }]);
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      style: this.getStyles(nextProps.style, nextProps.elevation)
    });
  }

  render() {
    let cardExtraProps = {};
    if (Platform.OS === 'web') {
      cardExtraProps = {
        ...cardExtraProps,
        // className: "card-view"
      }
    }
    return (<View {...cardExtraProps} style={this.state.style} pointerEvents="auto">
      {this.props.children}
    </View>);
  }
}

Card.propTypes = {
  style: PropTypes.object,
  children: PropTypes.node,
  elevation: PropTypes.number,
  showBorder: PropTypes.bool
};


Card.defaultProps = {
  style: {},
  children: [],
  elevation: 2,
  showBorder: false
};

export default Card;
