import React from 'react';
import PropTypes from 'prop-types';
import LinearGradient from 'react-native-linear-gradient';
import {Image, Text, TouchableOpacity, View} from 'react-native';
import getStyles from '../../styles/BusMamiCss';

class StickyHeader extends React.Component {
  render() {
    const styles = getStyles();
    return (
      <LinearGradient
        start={{
          x: 1.0,
          y: 0.0
        }}
        end={{
          x: 0.0,
          y: 1.0
        }}
        colors={this.props.headerColor}
        style={styles.stickyHeader}
      >
        <View style={styles.topStatusBar}>
          <View style={styles.stickyBarWrapper}>
            <TouchableOpacity style={{padding: 14}} onPress={this.props.onBackPress}>
              <Image
                style={styles.backarrow}
                source={this.props.iconSrc}
              />
            </TouchableOpacity>
            <View style={[styles.stickyTextWrapper, styles.transperantBgrnd]}>
              <Text
                numberOfLines={1}
                ellipsizeMode="tail"
                style={this.props.titleStyle}
              >{this.props.title}
              </Text>
              {this.props.subTitle()}
            </View>
          </View>
          <TouchableOpacity style={{padding: 14}} />
        </View>
      </LinearGradient>
    );
  }
}

StickyHeader.propTypes = {
  iconSrc: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  headerColor: PropTypes.array,
  subTitle: PropTypes.string,
  titleStyle: PropTypes.array.isRequired,
  subTitleStyle: PropTypes.array,
  onBackPress: PropTypes.func.isRequired
};

StickyHeader.defaultProps = {
  headerColor: [],
  subTitle: '',
  subTitleStyle: []
};

export default StickyHeader;
