import Fecha from 'fecha';
import isEmpty from 'lodash/isEmpty';
import { convertToISTDate } from '../../Helpers/dateHelpers';

const DAY_LIST = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
const SHORT_NAME_LIST = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];
const DIFFTIME = 1000 * 3600 * 24;
const DAYS = 365.25;

export function fillDateAndTime(date, outputDateFormat) {
  let resultDate = '';
  const newdate = new Date(new Date(date).valueOf());
  const formattedDate = Fecha.parse(`${newdate.getFullYear()}-${newdate.getMonth() + 1}-${newdate.getDate()}`, 'YYYY-MM-DD');
  resultDate = `${Fecha.format(getNewDate(formattedDate), outputDateFormat)}`;
  return resultDate;
}
export const nthDate = function(date) {
  if (date > 3 && date < 21) { return 'th'; }
  switch (date % 10) {
    case 1:  return 'st';
    case 2:  return 'nd';
    case 3:  return 'rd';
    default: return 'th';
  }
};

export function getDayName(date, shortName = false) {
  try {
    if (shortName) {
      return SHORT_NAME_LIST[getNewDate(date).getDay()];
    }
    return DAY_LIST[getNewDate(date).getDay()];
  } catch (e) {
    return '';
  }
}

export function getNewDate(date){
  if (date) {
    return convertToISTDate(new Date(date));
  }
  return convertToISTDate(new Date());
}

export function findDaysBetweenDates(firstDate, secondDate) {
  const oneDay = 24 * 60 * 60 * 1000;
  const FirstDateObj = new Date(fillDateAndTime(firstDate, 'YYYY-MM-DD'));
  const secondDateObj = new Date(fillDateAndTime(secondDate, 'YYYY-MM-DD'));
  return Math.abs((secondDateObj.getTime() - FirstDateObj.getTime()) / oneDay);
}
export function findNumberOfDaysBetweenDates(firstDate, secondDate) {
  const oneDay = 24 * 60 * 60 * 1000;
  let  FirstDateObj;
  let  secondDateObj;
  FirstDateObj = new Date(fillDateAndTime(firstDate, 'YYYY-MM-DD'));
  if (!isEmpty(secondDate.toString())){
    secondDateObj = new Date(fillDateAndTime(secondDate, 'YYYY-MM-DD'));
  } else {
    return 0;
  }
  return Math.abs((secondDateObj.getTime() - FirstDateObj.getTime()) / oneDay);
}
/**
 * @param timeRemainingMillis
 * @returns {string} of form hh:mm:ss if corresponding values are > 0
 */
export function toHHMMSS(timeRemainingMillis) {
  let hours = Math.floor(timeRemainingMillis / 3600);
  let minutes = Math.floor((timeRemainingMillis - (hours * 3600)) / 60);
  let seconds = timeRemainingMillis - (hours * 3600) - (minutes * 60);
  let result = '';
  if (hours !== 0) {
    if (hours < 10) { hours = `0${hours}`; }
    result += `${hours}:`;
  }
  if (minutes < 10) { minutes = `0${minutes}`; }
  result += `${minutes}:`;
  if (seconds < 10) { seconds = `0${seconds}`; }
  result += `${seconds}`;
  return result;
}

export function convertUTCDateToLocalDate(date) {
  const newDate = new Date(date.getTime() + (date.getTimezoneOffset() * 60 * 1000));

  const offset = date.getTimezoneOffset() / 60;
  const hours = date.getHours();

  newDate.setHours(hours - offset);

  return newDate;
}
export function daysLapsedSinceLastTime(days, lastTime) {
  let timeDiff = new Date().getTime() - lastTime;
  timeDiff = Math.round(timeDiff /= 1000); // into seconds
  const daysLapsed = Math.floor(timeDiff / (3600 * 24));
  return daysLapsed >= days;
}

export function getDurationInDaysHoursMinutesSeconds(date) {
  const days = Math.floor(date / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (date % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((date % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((date % (1000 * 60)) / 1000);
  return [days, hours, minutes, seconds];
}

export const timeDiff = (endTime, startTime) => {
  if (endTime < startTime) {
    return '';
  }
  let diffInMilliSeconds = (endTime - startTime) / 1000;
  // calculate days
  const days = Math.floor(diffInMilliSeconds / 86400);
  diffInMilliSeconds -= days * 86400;

  // calculate hours
  const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
  diffInMilliSeconds -= hours * 3600;

  // calculate minutes
  const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
  diffInMilliSeconds -= minutes * 60;

  // calculate seconds
  const seconds = Math.floor(diffInMilliSeconds);

  if (days > 0) {
    return `${toPadStart(days)}d : ${toPadStart(hours)}h : ${toPadStart(minutes)}m`;
  }
  return `${toPadStart(hours)}h : ${toPadStart(minutes)}m : ${toPadStart(seconds)}s`;
};
export const toPadStart = number => number.toString().padStart(2, '0');

export const getTimeDiff = (date1, date2) => {
    const diff = date2.getTime() - date1.getTime();
    const diffVal = diff / DIFFTIME;
    const diffInYears = Math.floor(diffVal / DAYS);
    const diffInDays = Math.floor(diffVal % DAYS);
    return [diffInYears, diffInDays];
};

export const formatAMPM = (date) => {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
};

export const getFormattedDate = (timeStamp) => {
    if (timeStamp) {
      const oneDay = 24 * 60 * 60 * 1000;
      const currentTime = new Date();
      var time = new Date(timeStamp);
      var timeInAMPM = formatAMPM(time);
      let month_names_short = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      let month_names_long = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      let day_names_long = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      let day_names_short = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];
      let formatDate = {
        month : month_names_short[time .getMonth()],
        month_long : month_names_long[time .getMonth()],
        monthVal : time .getMonth() + 1,
        day : time .getDate(),
        dayName : day_names_long[time .getDay()],
        dayShortName: day_names_short[time.getDay()],
        year : time .getFullYear(),
        dayDiff: Math.round(Math.abs((currentTime - time) / oneDay)),
        timeInAMPM: timeInAMPM,
      };
      return formatDate;
    } else {
      return null;
    }
  };

  export const epocTimeToFormattedTime = (date, outputDateFormat) => {
    try{
    const result = Fecha.format(Number(date), outputDateFormat);
    return result;
    }catch (err){
      console.log("Error: ", err);
      return "";
    }
  }