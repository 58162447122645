import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import styles from './SpecialClaimCardCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import BasePage from '../../../Common/PostSalesBasePage';
import { refundIcon, rightArrow } from '../../BusImageConstants';
import { getFont } from '../../../PostSalesStyles';

class SpecialClaimCard extends BasePage {
  constructor(props) {
    super(props);
    this.fonts = getFont(true);
  }

  render() {
    let specialClaimData = this.props.specialClaimDataList[0];
    const { fonts } = this;
    return (
      <View>
        <View style={[AtomicCss.marginBottom10]}>
          <View style={[styles.infoCardWrapper]}>
            <View style={styles.headingIconWrapper}>
              <Image style={styles.headingIconStyle} source={refundIcon} />
            </View>
            <View style={styles.infoCardContent}>
              <Text
                style={[
                  AtomicCss.defaultText,
                  fonts.blackFontFamily,
                  AtomicCss.marginBottom15,
                  fonts.font14,
                ]}
              >
                {specialClaimData.claimHeader}
              </Text>
              <Text
                style={[
                  AtomicCss.defaultText,
                  AtomicCss.lineHeight20,
                  fonts.regularFontFamily,
                  fonts.font14,
                ]}
              >
                {specialClaimData.claimMessage}
              </Text>
            </View>
          </View>
          {this.specialClaimReasonView(this.props.specialClaimDataList, this.onItemClick)}
        </View>
      </View>
    );
  }

  specialClaimReasonView = (specialClaimDataList, onItemClick) => {
    const { fonts } = this;
    return specialClaimDataList.map((specialClaimItem) => (
      <TouchableOpacity
        key={specialClaimItem.scName}
        style={styles.redirectBtnWrapper}
        activeOpacity={0.8}
        onPress={() => {
          onItemClick(specialClaimItem);
        }}
      >
        <Text style={[styles.linkBtnTxt, fonts.boldFontFamily, fonts.font14]}>{specialClaimItem.scName}</Text>
        <Image style={styles.rightArrowIcon} source={rightArrow} />
      </TouchableOpacity>
    ));
  };

  onItemClick = (specialClaimItem) => {
    this.props.specialClaimActionClick(specialClaimItem);
  };
}

SpecialClaimCard.propTypes = {
  specialClaimDataList: PropTypes.array.isRequired,
  specialClaimActionClick: PropTypes.func.isRequired,
};

export default SpecialClaimCard;
