import AtomicCss from '../commonStyles/AtomicCss';
import {StyleSheet, Text, TextInput, TouchableOpacity, View} from "react-native";
import styles from '../Tdr/TdrSelectReason/TdrSelectReasonCss';
import React from "react";
import { OTHERS } from "../../PostSalesConstant";
import { getStaticData } from "../../staticData/staticData";
import { getFont } from "../../PostSalesStyles";


const { fsStyle, ...fonts } = getFont(true);
const ReasonForCancellation = ({reasons, getTheSelectedData}) => {
    const staticData = getStaticData();
    const { reasonForCancellationText } = staticData;
return(
<View style={[AtomicCss.whiteBg, AtomicCss.elevation1, AtomicCss.marginTop10, AtomicCss.paddingBottom8]}>
    <View style={[reasonForCancellationStyles.PartCancellationHead, AtomicCss.elevation1]}>
        <Text style={[fonts.blackFontFamily, AtomicCss.blackText, fsStyle.font16]}>
            {reasonForCancellationText}
        </Text>
    </View>
    {getReasonList(reasons, getTheSelectedData)}
</View>
)
}

const getReasonList = (reasons, getTheSelectedData) => reasons && reasons.map((reason, index) => {
    const staticData = getStaticData();
    const { enterYourReasonText } = staticData;
    return (
        <React.Fragment>
        <TouchableOpacity style={[AtomicCss.marginBottom18,AtomicCss.paddingLeft20]} onPress={() => getTheSelectedData(reason,index)}>
            <View style={[AtomicCss.flexRowWithTopMargin20]}>
                <View style={styles.MimaRadio}>
                    <View style={[reason.selected ? styles.MimaRadioInside : '']}></View>
                </View>
                <Text style={[AtomicCss.blackText, fsStyle.font14, fonts.regularFontFamily, AtomicCss.flex1, AtomicCss.tdrText]}>
                    {reason.translatedReason}
                </Text>
            </View>
        </TouchableOpacity>
    {
        (reason.reason === OTHERS && reason.selected === true) && (
                <TextInput
                    multiline
                    placeholder= {enterYourReasonText}
                    style={[reasonForCancellationStyles.textarea, fonts.regularFontFamily, fsStyle.font14]}
                    onChangeText={text => getTheSelectedData(reason, index,text)}
                />
            )
    }
        </React.Fragment>
    );
});

const reasonForCancellationStyles = StyleSheet.create({
    textarea: {
        borderColor: '#e1e7ee',
        borderWidth: 1,
        borderRadius: 6,
        marginTop: 16,
        minHeight: 112,
        padding: 12,
        lineHeight: 17,
        color: '#647a97',
        textAlignVertical: 'top',
        marginLeft: 16,
        marginRight: 16,
        marginBottom: 16
    },
    CancellationWrapper: {
        flex: 1,
        justifyContent: 'space-between',
        backgroundColor: '#f2f2f2'
    },
    PartCancellationHead: {
        backgroundColor: '#ffffff',
        paddingVertical: 18,
        paddingLeft: 10,
        borderBottomWidth: 1,
        borderBottomColor: '#ddd'
    },
})
export  default ReasonForCancellation;
