import React, { useRef, useState } from 'react';
import { View, Text, Image, TouchableOpacity, Animated } from 'react-native';
import getStyle from '../../Css/UploadProofToSupportCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import SubmitPartialRequest from '../SubmitPartialRequest';
import { BLUE_LIST_ICON_ERROR, SM_BLUE_RT_ARROW } from '../../../../FlightImageConstants';
import { isEmptyArray } from '../../../../../Common/commonUtil';
import { getFont } from '../../../../../PostSalesStyles';
import {screens, getUploadProofToSupportData} from "../../data/dataAdapter"
import { useTheme } from '../../../../../theme/theme.context';

const UploadProofToSupport = ({
  handleBottomOverlay,
  docNotUploadedPageOne,
  docNotUploadedPageTwo,
  reqSegments,
  segments,
  dontHaveComm,
  goToUpload,
  partialRequest,
  onUploadProof,
}) => {
  const { fsStyle, ...fonts } = getFont(true);
  const {psTheme} = useTheme();
  const styles = getStyle(psTheme);
  const {color} = psTheme;
  const left = useRef(new Animated.Value(1100)).current;
  const right = useRef(new Animated.Value(0)).current;
  const [screen, setScreen] = useState(screens.DOC_NOT_UPLOADED_PAGE_ONE);

  const handleTab = () => {
    setScreen(screens.DOC_NOT_UPLOADED_PAGE_TWO);
    Animated.timing(left, {
      toValue: 0,
      timing: 1500,
    }).start();
  };
  const handleBack = () => {
    setScreen(screens.DOC_NOT_UPLOADED_PAGE_ONE);
    Animated.timing(right, {
      toValue: 0,
      timing: 1500,
    }).start();
  };

  const {
    title,
    subtitle,
    requiredSegmentData,
    notRequiredSegmentData
  } = getUploadProofToSupportData(true, docNotUploadedPageOne, segments, reqSegments);

  const SegmentIncludedSubtitle =
    !isEmptyArray(requiredSegmentData) &&
    !isEmptyArray(subtitle) &&
    subtitle.map((sub) =>
      sub.includes('$$%%') ? (
        <Text>
          <Text>{sub.split('$$%%')[0]}</Text>
          <Text key={sub} style={[fonts.boldFontFamily]}>
            {requiredSegmentData.toString()}
          </Text>
          <Text>{sub.split('$$%%')[1]}</Text>
        </Text>
      ) : (
        sub
      ),
    );
  return (
    <React.Fragment>
      {screen === screens.DOC_NOT_UPLOADED_PAGE_ONE && (
        <Animated.View style={{ right: right }}>
          <View style={styles.OverlayContentWrapper}>
            <View style={AtomicCss.marginBottom24}>
              <View style={styles.overlayTopline}></View>
            </View>
            <React.Fragment>
              <Image source={BLUE_LIST_ICON_ERROR} style={styles.uploadProofStyle} />
              <Text
                style={[
                  styles.title,
                  fonts.blackFontFamily,
                  AtomicCss.marginBottom12,
                  fsStyle.font22,
                ]}
              >
                {title}
              </Text>
              <View style={styles.strokeStyle}></View>
              {!isEmptyArray(SegmentIncludedSubtitle) &&
                SegmentIncludedSubtitle.map((sub) => (
                  <Text
                    key={sub}
                    style={[
                      fonts.regularFontFamily,
                      fsStyle.font14,
                      {color : color.black},
                      styles.lineHeight22,
                      AtomicCss.marginBottom26,
                    ]}
                  >
                    {sub}
                  </Text>
                ))}
              <TouchableOpacity style={styles.linkWrapper} onPress={handleTab}>
                <Text
                  style={[
                    fonts.blackFontFamily,
                    fsStyle.font14,
                    {color : color.azure},
                    styles.lineHeight24,
                  ]}
                >
                  {dontHaveComm}
                </Text>
                <Image source={SM_BLUE_RT_ARROW} style={styles.rtArrowStyle} />
              </TouchableOpacity>
              <TouchableOpacity
                style={[AtomicCss.flexRow]}
                onPress={handleBottomOverlay.bind(null, '')}
              >
                <Text
                  style={[
                    fsStyle.font16,
                    fonts.blackFontFamily,
                    {color : color.azure},
                    AtomicCss.lineHeight20,
                    AtomicCss.flex1,
                  ]}
                >
                  {goToUpload}
                </Text>
              </TouchableOpacity>
            </React.Fragment>
          </View>
        </Animated.View>
      )}
      {screen === screens.DOC_NOT_UPLOADED_PAGE_TWO && (
        <Animated.View style={{ left: left }}>
          <View style={styles.OverlayContentWrapper}>
            <View style={AtomicCss.marginBottom24}>
              <View style={styles.overlayTopline}></View>
            </View>
            <SubmitPartialRequest
              handleBack={handleBack}
              docNotUploadedPageTwo={docNotUploadedPageTwo}
              requiredSegmentData={requiredSegmentData}
              notRequiredSegmentData={notRequiredSegmentData}
              dontHaveComm={dontHaveComm}
              partialRequest={partialRequest}
              onUploadProof={onUploadProof}
            />
          </View>
        </Animated.View>
      )}
    </React.Fragment>
  );
};

export default UploadProofToSupport;
