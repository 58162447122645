import { StyleSheet } from 'react-native';

const getStyle = (theme, fonts) =>
  StyleSheet.create({
    needhelpCard: {
      flexDirection: 'row',
      padding: 8,
      elevation: 1,
    },
    ContentPortion: {
      flex: 1,
    },
    header: {
      fontFamily: fonts.black,
      color: theme.color.black,
      padding: 10,
    },
    subHeading: {
      lineHeight: 20,
      marginBottom: 16,
    },
    container: {
      borderTopWidth: 1,
      borderTopColor: theme.color.grey8,
      padding: 20,
      paddingTop: 10,
    },
    topContainer: {
      borderRadius: 10,
      marginHorizontal: 12,
      marginBottom: 10,
      backgroundColor: theme.color.white,
    },
  });

export default getStyle;
