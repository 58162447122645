import React from 'react';
import {View, Text, StyleSheet, TouchableWithoutFeedback, Image, TouchableOpacity, Platform} from 'react-native';
import {fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import {mapImg} from '../CabImageConstants';
import {openGoogleMap} from "../../Common/commonUtil";
import CabBookingTrackingHelper from '../CabBookingTrackingHelper';

class CabOnSpotInfoCard extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <View style={[styles.flex1, styles.bgWhite]}>
          <View>
          <View >
            <View style={[styles.QRCodeCard, styles.IOSSpacing]}>
              <View style={styles.width70}>
                <Text style={styles.QRInfo}>{this.props.carOnSpotBoardingInfo.message}</Text>
                <Text style={styles.QRLocation}>{this.props.carOnSpotBoardingInfo.locationMessage} </Text>
                {/*<Text style={styles.QRLocationText}>{this.props.carOnSpotBoardingInfo.kioskName}</Text>*/}
                <Text style={styles.QRLocationText}>{this.props.carOnSpotBoardingInfo.landmark}</Text>
                <View style={styles.rowCenter}>
                  <Image style={styles.mapIcon} source={mapImg} />
                  <TouchableOpacity onPress={() => this.openGoogleMap(this.props.carOnSpotBoardingInfo.latitude,this.props.carOnSpotBoardingInfo.longitude)} >
                    <Text style={styles.mapWay}>{this.props.carOnSpotBoardingInfo.mapWay}</Text>
                  </TouchableOpacity>
                </View>
              </View>
              <View style={styles.alignCenter}>
                <View style={styles.couponCodeWrapper}>
                  <Text style={styles.couponCode}>{this.props.carOnSpotBoardingInfo.qrCode}</Text>
                </View>
                <TouchableWithoutFeedback onPress={this.props.closePopup}>
                  <Image style={styles.QRCodeIcon} source={{uri: this.props.carOnSpotBoardingInfo.qrCodeLink}} />
                </TouchableWithoutFeedback>
              </View>
            </View>
          </View>
        </View>
        </View>
    );
  }

    openGoogleMap(lat, lng) {
        CabBookingTrackingHelper.trackClickEvent("TripDetails",this.props.response,"LocationMap");
        return openGoogleMap(lat,lng);
    }
}

const styles = StyleSheet.create({
  IOSSpacing: {
    ...Platform.select({
      ios: {
        marginTop: 16
      }
    }),
  },
  flex1: {flex: 1},
  QRWrapper:{marginHorizontal: 16,marginVertical: 16},
  QRCodeCard:{backgroundColor: '#e5f2fd', borderRadius: 4, padding: 16, flexDirection: 'row', justifyContent: 'space-between', marginBottom: 4},
  QRInfo: {color: '#000', fontSize: 14, lineHeight: 18, fontFamily: fonts.bold, marginBottom: 8},
  QRLocation:{color: '#9b9b9b',fontSize: 12, fontFamily: fonts.bold, marginBottom: 3},
  QRLocationText: {color: '#000', fontSize: 12, fontFamily: fonts.regular, marginBottom: 20},
  mapWay:{fontSize: 14, color: '#0082ee', fontFamily: fonts.bold},
  couponCode:{fontSize: 16, color: '#000', fontFamily: fonts.black},
  width70: {width: '70%'},
  rowCenter: {flexDirection: 'row', alignItems: 'center'},
  mapIcon: {width: 40, height: 40, marginLeft: -8},
  couponCodeWrapper: {backgroundColor: '#fff', borderWidth: 1,paddingVertical: 3, paddingHorizontal: 7, borderRadius: 3, marginBottom: 10},
  QRCodeIcon: {width: 76, height: 76},
  alignCenter: {alignItems: 'center'},
  driverDetails: {backgroundColor: '#f2f2f2', flexDirection: 'row', alignItems: 'center', padding: 16, borderRadius: 4},
  detailsInfo: {color: '#000', fontSize: 14, fontFamily: fonts.bold, marginBottom: 6},
  lockIcon: {width: 40, height: 40},
  contactDetails: {fontSize: 12, color: '#000', fontFamily: fonts.regular, marginBottom: 4},
  departTime: {fontSize: 12, color: '#000', fontFamily: fonts.bold},
  bgWhite: {backgroundColor: '#fff'}
});

export default CabOnSpotInfoCard;
