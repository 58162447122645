import React, {PureComponent} from 'react';
import {View, Text, Image, StyleSheet} from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { BLUE_DROP_ICON_DOWN, BLUE_DROP_ICON_UP } from '../../../SelfDriveImageConstant';

class FaqsText extends PureComponent {

    render() {
        return (
            <View style={[styles.CollapsedTapContainer1, AtomicCss.alignCenter]}>
                <View style={styles.CollapsedTapLeftSection}>
                    <Text style={[AtomicCss.font14, AtomicCss.blackText, AtomicCss.blackFont]}>
                        {this.props.item}
                    </Text>
                </View>
                <View style={[styles.CollapsedTapRightSection, AtomicCss.marginTop10]}>
                    <Image style={[styles.AlignSelfRight, styles.blueArrow, AtomicCss.marginBottom10]}
                           source={this.props.tabbing ? BLUE_DROP_ICON_UP : BLUE_DROP_ICON_DOWN}/>
                </View>
            </View>

        );
    }
}

const styles = StyleSheet.create({
    CollapsedTapContainer1: {
        backgroundColor: 'transparent',
        paddingHorizontal: 20,
        paddingVertical: 10,
        flexDirection: 'row'
    },
    CollapsedTapLeftSection: {
        width: '70%'
    },
    CollapsedTapRightSection: {
        width: '30%'
    },
    blueArrow: {
        width: 13,
        height: 7
    },
    AlignSelfRight: {
        alignSelf: 'flex-end'
    }
});

export default FaqsText;
