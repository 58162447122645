/* eslint-disable max-len */
import React from 'react';
import Actions from '../../navigation/postSalesNavigation';
import Fecha from 'fecha';
import { Platform, Alert, Linking, NativeModules } from 'react-native';
import _, { get, isEmpty } from 'lodash';
import CabBookingDetailsConstant, { CLICK_EVENT, SOURCE, PAGE_NAME } from '../CabBookingDetailsConstant';
import styles from '../styles/CabMamiCss';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import BusBookingModule from '@mmt/legacy-commons/Native/BusBookingModule';
import CabBookingModule from '@mmt/legacy-commons/Native/CabBookingModule';
import CabBookingTrackingHelper from '../CabBookingTrackingHelper';
import {
  E_COUPON,
  E_COUPON_TYPE,
  ENTITY_TYPES,
  MYRA_INTENTS,
  MYTA_CHAT_OWNER,
  PAGE_IDENTIFIER,
  DONWLOAD_FILE_EXT
} from '../../PostSalesConstant';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import BusBookingDetailsConstant from '../../bus/BusBookingDetailsConstant';
import { copyToClipboard, isNullOrEmpty, openDialer } from '../../Common/commonUtil';
import { getQueryParamsFromUrl } from '@mmt/legacy-commons/Helpers/misc';
import { isNetworkAvailable } from '@mmt/legacy-commons/Common/utils/AppUtils';
import { CAB_FETCH_INVOCE_PDF, INITIATE_DRIVER_CHAT, getCommonHeaders } from "../../utils/NetworkUtils";
import { downloadEticketVoucher, getGdprData, shouldShowGdprBlockerScreen} from '../../utils/PostSaleUtil';
import { getStaticData } from '../../staticData/staticData';
import WhiteFullCapsuleButton from '../../Common/ActionButton/WhiteFullCapsuleButton';
import CapsuleBtnFill from '../../Common/ActionButton/CapsuleBtnFill';
import CapsuleBtn from '../../Common/ActionButton/CapsuleBtn';
import RectFullButton from '../../Common/ActionButton/RectFullButton';
import RoundButtonWithIcon from '../../Common/ActionButton/RoundButtonWithIcon';
import { epocTimeToFormattedTime } from 'packages/legacy-commons/Common/utils/DateUtils';
import { PokusLobs } from 'packages/legacy-commons/Native/AbConfig/AbConfigKeyMappings';
import { AbConfigKeyMappings, getPokusConfig, getPokusConfigFetchIfNeeded } from 'packages/legacy-commons/Native/AbConfig/AbConfigModule';
import { showLongToast } from 'packages/core/helpers/toast';

export function getCabTitle(cabdetailInfo) {
  return `${cabdetailInfo.carBookingInfo.carDetail.carBrand}`;
}

export function getCabSubTitle(cabdetailInfo) {
  return `${fillDepartureArrivalDate(cabdetailInfo.carAdditionalInfo.departureDetail.departureDate, 'DD MMM')}${getArivalDate(cabdetailInfo.carAdditionalInfo.arrivalDetail.arrivalDate)} | ${getTripType(cabdetailInfo.carBookingInfo.carDetail.tripType)}`;
}
export function getArivalDate(date) {
  if (fillDepartureArrivalDate(date, 'YY') === '53') { return ''; }
  return ` - ${fillDepartureArrivalDate(date, 'DD MMM')}`;
}
export function getUpperCase(str) {
  return str.toUpperCase();
}
export function getCancelledSubTitle(response) {
  let textTitle = '';
  if (response.refundDetail) {
    const amt = response.refundDetail.amount;
    textTitle = `₹ ${amt} will be refunded to your original paymode within 7 working days`;
  }
  return textTitle;
}
export function getRefundSubTitle(response) {
  let textTitle = '';
  if (response.refundDetail) {
    const amt = response.refundDetail.amount;
    textTitle = `₹ ${amt} has been refunded to your original paymode`;
  }
  return textTitle;
}
export function getSubtextDetailsConfirmed(response) {
  const phNum = getTenDigitMobileNumber(response.primaryCustomerDetails.phoneNumber);
  return `SMS will be sent to ${phNum} \n${getDriverExpectationHours(response)} before departure`;
}
export function fillDepartureArrivalDate(date, outputDateFormat) {
  return Fecha.format(new Date(date), outputDateFormat);
}
export function isNotNullAndEmpty(object) {
  return (object != null && object.length > 0);
}
export const getThrottleParams = (action) => {
  switch (action.actionFamily) {
    case CabBookingDetailsConstant.ACTION_DOWNLOAD_INVOICE:
      return {
        isThrottleRequired: true,
        throttleInterval: 2000,
      };
    default:
      return {
        isThrottleRequired: false,
        throttleInterval: 0,
      };
  }
};

export function getActionButton(card, response, clickHandler) {
  const actionButtons = [];
  for (const action of card.actionList) {
    const {isThrottleRequired , throttleInterval} = getThrottleParams(action);
    addActionButton(card, action, actionButtons, response, clickHandler, isThrottleRequired , throttleInterval);
  }
  return actionButtons;
}
export function getPageName(subState) {
  switch (subState) {
    case CabBookingDetailsConstant.DEFAULT_CANCELLED:
    case CabBookingDetailsConstant.CAN_INIT_TO_REFUND_DONE:
    case CabBookingDetailsConstant.AFTER_REFUND_DONE:
    case CabBookingDetailsConstant.LOST_FAILED:
    case CabBookingDetailsConstant.LOST_FAILED_BOOKING_CANCELLED:
    default:
      return 'Mob:Customer Support:Cab:TripDetails';
  }
  return '';
}

export function getButtonStyle(actionList) {
  const { actionType } = actionList[0];
  if (actionType === CabBookingDetailsConstant.RECTANGLE_PRIMARY_HALF_WIDTH
    || actionType === CabBookingDetailsConstant.RECTANGLE_SECONDARY_HALF_WIDTH
    || actionType === CabBookingDetailsConstant.ROUND_BUTTON) {
    return [styles.ActionBtnHalf, styles.transperantBgrnd];
  } else if (actionType === CabBookingDetailsConstant.RECTANGLE_FULL_BUTTON) {
    return [styles.ActionBtnRec, styles.transperantBgrnd];
  }
  return [styles.ActionBtn, styles.transperantBgrnd];
}

export function showPriceBreakupCard(response, card) {
  if (response && (!_.isEmpty(response.clientDetails.priceList))) {
    Actions.commonPaymentPriceBreakUp({ bookingDetailsResponse: response });
  } else {
    Actions.cabPaymentBreakup({
      response,
      card
    });
  }
}

export async function handleClickEvent(card, action, response, pageName) {
  switch (action.actionFamily) {
    case CabBookingDetailsConstant.ACTION_CAB_CALL_US:
    case CabBookingDetailsConstant.ACTION_CALL_HELPLINE:
      openDialer(!!response.callUsContactNo ? response.callUsContactNo : CabBookingDetailsConstant.HELPLINE_NUMBER);
      let clickEvent = CabBookingDetailsConstant.CLICK_CALL;
      if(action.actionFamily === CabBookingDetailsConstant.ACTION_CALL_HELPLINE){
        clickEvent = CabBookingDetailsConstant.CLICK_CALL_HELPLINE;
      }
      CabBookingTrackingHelper.trackClickEvent(pageName, clickEvent);
      break;
    case CabBookingDetailsConstant.ACTION_CALL_DRIVER:
      callCabdriver(response);
      CabBookingTrackingHelper.trackClickEvent(pageName, CabBookingDetailsConstant.CLICK_CALL_DRIVE);
      break;
    case CabBookingDetailsConstant.ACTION_CAB_WTU:
      BusBookingModule.startWriteToUsActivity();
      CabBookingTrackingHelper.trackClickEvent(pageName, CabBookingDetailsConstant.CLICK_WTU);
      break;
    case CabBookingDetailsConstant.ACTION_BOOK_AGAIN:
      Actions.cabs({ initial: false });
      CabBookingTrackingHelper.trackClickEvent(pageName, CabBookingDetailsConstant.CLICK_BOOKAGAIN);
      break;
    case CabBookingDetailsConstant.ACTION_VIEW_PRICE_BREAKUP:
      showPriceBreakupCard(response, card);
      CabBookingTrackingHelper.trackClickEvent(pageName, CabBookingDetailsConstant.CLICK_FARE_BREAKUP);
      break;
    case CabBookingDetailsConstant.ACTION_CANCEL_FULL_BOOKING:
      Actions.cabCancellationDetails({
        response,
        card,
        isMyGate : myGateView()
      });
      CabBookingTrackingHelper.trackClickEvent(pageName, CabBookingDetailsConstant.CLICK_CANCEL_BOOKING);
      break;
    case CabBookingDetailsConstant.ACTION_MODIFY_BOOKING:
      Actions.cabModifyBooking({ response });
      CabBookingTrackingHelper.trackClickEvent(pageName, CLICK_EVENT.MODIFY_BOOKING_CLICK);
      break;
    case CabBookingDetailsConstant.ACTION_VIEW_CANCELLATION_POLICY:
      Actions.carCancellationPoilcyRule({
        response,
        card
      });
      CabBookingTrackingHelper.trackClickEvent(pageName, CabBookingDetailsConstant.CLICK_CANCELLATION_POLICY);
      break;
    case CabBookingDetailsConstant.ACTION_DOWNLOAD_VOUCHER:
      downloadVoucher(CabBookingDetailsConstant.ACTION_TRIGGER_VOUCHER, response);
      CabBookingTrackingHelper.trackClickEvent(pageName, CabBookingDetailsConstant.CLICK_DOWNLOAD_VOUCHER);
      break;
    case CabBookingDetailsConstant.ACTION_DOWNLOAD_INVOICE:
      const invoiceAlert = action.actionAlertText || '';
      downloadInvoice(CabBookingDetailsConstant.ACTION_TRIGGER_INVOICE, response, invoiceAlert);
      CabBookingTrackingHelper.trackClickEvent((pageName, CabBookingDetailsConstant.CLICK_DOWNLOAD_INVOICE));
      break;
    case CabBookingDetailsConstant.ACTION_DRIVER_DETAILS:
      showDriverDetails(response, card);
      CabBookingTrackingHelper.trackClickEvent(pageName, CabBookingDetailsConstant.CLICK_DRIVER_DETAILS);
      break;
    case CabBookingDetailsConstant.ACTION_TERMS_AND_CONDITIONS:
      Actions.cabTermsAndConditions({
        response,
        card
      });
      CabBookingTrackingHelper.trackClickEvent(pageName, CabBookingDetailsConstant.CLICK_TERMS_AND_CONDITIONS);
      break;
    case CabBookingDetailsConstant.ACTION_INVOICE_DETAILS:
      Actions.cabInvoiceDetails({
        response,
        card
      });
      CabBookingTrackingHelper.trackClickEvent(pageName, CabBookingDetailsConstant.CLICK_INVOICE_DETAILS);
      break;
    case CabBookingDetailsConstant.ACTION_DATECHANGE_DETAILS:
      Actions.cabChangePickup({
        response
      });
      CabBookingTrackingHelper.trackClickEvent(pageName, CabBookingDetailsConstant.CLICK_CABDATECHANGE_DETAILS);
      break;
    case CabBookingDetailsConstant.ACTION_CANCELBOOKING_DETAILS:
      Actions.cabCancellationDetailsStandAlone({
        response,
        card
      });
      CabBookingTrackingHelper.trackClickEvent(pageName, CabBookingDetailsConstant.CLICK_CANCELBOOKING_DETAILS);
      break;
    case CabBookingDetailsConstant.ACTION_CHANGEPICKUP_DETAILS:
      Actions.cabChangePickup({
        response
      });
      CabBookingTrackingHelper.trackClickEvent(pageName, CabBookingDetailsConstant.CLICK_CHANGEPICKUP_DETAILS);
      break;
    case CabBookingDetailsConstant.ACTION_CAB_REVIEW:
      openCabReviewPage(response, pageName);
      break;
    case CabBookingDetailsConstant.ACTION_TRIP_MODIFICATION:
      openCabBookingDetail(response.clientDetails.cancelRebookBookingId)
      break;
    case CabBookingDetailsConstant.ACTION_DRIVER_CHAT:
      chatCabdriver(response);
      CabBookingTrackingHelper.trackClickEvent(pageName, CabBookingDetailsConstant.CLICK_CHAT_WITH_DRIVER);
      break;
    case CabBookingDetailsConstant.ACTION_RAISE_SPECIAL_CLAIM:
      Actions.carSupportIssueSelect({
        response,
        card
      });
      break;
    case CabBookingDetailsConstant.ACTION_GO_TO_BOOKING_DETAILS:
      CabBookingTrackingHelper.trackClickEvent(PAGE_NAME.CAB_RESCHEDULE_PAGE, CLICK_EVENT.CAB_RESCHEDULE_GO_TO_MYTRIPS_CLICK);
      openCabBookingDetail(response.bookingId);
      break;
    default:
      break;
  }
}
export function getDriverPhoneNumber(response) {
  const phoneNumber = response.carAdditionalInfo.driverDetail.contactNumber;
  return phoneNumber;
}

export function callCabdriver(response) {
  const driverNumber = getDriverPhoneNumber(response);
  if (driverNumber) {
    openDialer(driverNumber);
  } else {
    Alert.alert('', `The driver details will be sent to your mobile number, ${getDriverExpectationHours(response)} prior to your pickup time.`);
  }
}

export function downloadVoucher(triggerName, response) {
  if (Platform.OS === 'ios') {
    downloadInvoiceVoucher(response, false);
  } else {
    CabBookingModule.downloadTicket(triggerName, response.bookingId);
  }
}

const downloadInvoiceVoucher = async (response, isInvoice) => {
  const extension = isInvoice ? DONWLOAD_FILE_EXT.INVOICE : DONWLOAD_FILE_EXT.ETICKET;
  downloadEticketVoucher(
    response.bookingId,
    isInvoice
      ? CabBookingDetailsConstant.ACTION_TRIGGER_INVOICE
      : CabBookingDetailsConstant.ACTION_TRIGGER_VOUCHER,
      CAB_FETCH_INVOCE_PDF,
      CabBookingDetailsConstant.CAB_DOWNLOAD_VOUCHER,
    response.bookingId + extension,
  );
}

export function downloadInvoice(triggerName, response, invoiceAlert) {
  if (Platform.OS === 'ios') {
    downloadInvoiceVoucher(response, true);
  }
  else if (Platform.OS === 'web') {
    CabBookingModule.downloadTicket(triggerName, response.bookingId);
  } else {
    if (response.bookingState.state.toUpperCase() === 'COMPLETED' || response.bookingState.state.toUpperCase() === 'CANCELLED') {
      CabBookingModule.downloadTicket(triggerName, response.bookingId);
    } else {
      Alert.alert(invoiceAlert);
    }
  }
}

export function showDriverDetails(response, card) {
  if (isNotNullAndEmpty(response.carAdditionalInfo.driverDetail.name)) {
    Actions.cabDriverDetails({
      response,
      card
    });
  } else {
    Alert.alert('', `The driver details will be sent to your mobile number, ${getDriverExpectationHours(response)} prior to your pickup time.`);
  }
}

function addActionButton(card, action, actionButtons, response, clickHandler,isThrottleRequired= false, throttleInterval = 0)  {
  switch (action.actionType) {
    case CabBookingDetailsConstant.WHITE_BUTTON_FULL:
      actionButtons.push(<WhiteFullCapsuleButton
        key={`WhiteFullCapsuleButton${action.actionLabeltext}`}
        card={card}
        action={action}
        response={response}
        clickHandler={clickHandler}
        isThrottleRequired={isThrottleRequired}
        throttleInterval={throttleInterval}
      />);
      break;
    case CabBookingDetailsConstant.RECTANGLE_PRIMARY_FULL_WIDTH:
    case CabBookingDetailsConstant.RECTANGLE_PRIMARY_HALF_WIDTH:
      actionButtons.push(<CapsuleBtnFill
        key={`CapsuleBtnFill${action.actionLabeltext}`}
        card={card}
        action={action}
        response={response}
        clickHandler={clickHandler}
        isThrottleRequired={isThrottleRequired}
        throttleInterval={throttleInterval}
      />);
      break;
    case CabBookingDetailsConstant.RECTANGLE_SECONDARY_FULL_WIDTH:
    case CabBookingDetailsConstant.RECTANGLE_SECONDARY_HALF_WIDTH:
      actionButtons.push(<CapsuleBtn
        key={`CapsuleBtn${action.actionLabeltext}`}
        card={card}
        action={action}
        response={response}
        clickHandler={clickHandler}
        isThrottleRequired={isThrottleRequired}
        throttleInterval={throttleInterval}
      />);
      break;
    case CabBookingDetailsConstant.RECTANGLE_FULL_BUTTON:
      actionButtons.push(<RectFullButton
        key={`RectFullButton${action.actionLabeltext}`}
        card={card}
        action={action}
        response={response}
        clickHandler={clickHandler}
        isThrottleRequired={isThrottleRequired}
        throttleInterval={throttleInterval}
      />);
      break;
    case CabBookingDetailsConstant.ROUND_BUTTON:
      actionButtons.push(<RoundButtonWithIcon
        key={`RoundButtonWithIcon${action.actionLabeltext}`}
        card={card}
        action={action}
        response={response}
        clickHandler={clickHandler}
        isThrottleRequired={isThrottleRequired}
        throttleInterval={throttleInterval}
      />);
      break;
    default:
      break;
  }
}

export function getECouponAmt(paymentDetail) {
  let amt = 0;
  if (paymentDetail.paymentDetails) {
    paymentDetail.paymentDetails.forEach((paymentDetails) => {
      if (paymentDetails.paymentModeName === E_COUPON && paymentDetails.ecouponNo !== E_COUPON_TYPE) {
        amt = paymentDetails.amount;
      }
    });
  }
  return amt;
}

/**
 * this function will give us a card for cancellation for internal use on another subcard
 * this data is unavailable at backend or otherwise has to be added somewhere except the Layout node.
 * @returns {{cardId: string, actionList: *[]}}
 */
export function getCardObject() {
  const cancelCardLayout =
  {
    cardId: 'CancelBooking',
    actionList: [
      {
        actionRank: 2,
        actionId: 'CancelPolicy_RecPriFull',
        actionType: 'RecFullBtn',
        actionLabeltext: 'View Cancellation Policy',
        actionFamily: 'CancellationPolicy'
      },
      {
        actionRank: 1,
        actionId: 'Cancel_RecPriFull',
        actionType: 'RecFullBtn',
        actionLabeltext: 'Cancel Entire Booking',
        actionFamily: 'CancelFullBooking'
      }
    ]
  };

  return cancelCardLayout;
}

export async function openCabBookingDetail(bookingId) {
  const { mmtAuth, loggingTrackingInfo } = await HotelBookingModule.getRequestHeader(bookingId, 'CAB_BOOKING_DETAIL');
  const params = {};
  const refresh = {};
  params.BOOKING_ID = bookingId;
  params.MMT_AUTH = mmtAuth;
  params.LOGGING_TRACKINFO = loggingTrackingInfo;
  refresh.isRefresh = true;
  params.refresh = refresh;
  Actions.cabBookingDetail({ data: params, type : '' });
}

export function getTripType(str) {
  let tripType = str;
  if (tripType.includes('_')) { tripType = tripType.split('_').join(' '); }
  return tripType.toUpperCase();
}

export function reverseString(str) {
  return str.split('').reverse().join('');
}

export function getTenDigitMobileNumber(mobileno) {
  let mobilenoStr = reverseString(mobileno.toString());
  if (mobilenoStr.length > 10) {
    mobilenoStr = mobilenoStr.substr(0, 10);
  }
  return reverseString(mobilenoStr);
}

export function getDriverExpectationHours(response) {
  if (!isNullOrEmpty(response) && !isNullOrEmpty(response.carAdditionalInfo)) {
    return response.carAdditionalInfo.driverExpectationTime;
  }
  return '2 hours';
}

export const isMyWallet = (bookingDetails) => {
  let mywallet = false;
  let bankPayment = false;
  if (bookingDetails) {
    const paymentdetails = bookingDetails.bookingPaymentDetail.paymentDetails;
    paymentdetails.forEach((paymentDetail) => {
      if (paymentDetail.paymentGatewayCode && paymentDetail.paymentGatewayCode === BusBookingDetailsConstant.BUS_MYWALLET) {
        mywallet = true;
      } else if (!(paymentDetail.paymentModeName && paymentDetail.paymentModeName === BusBookingDetailsConstant.BUS_ECOUPON)) {
        bankPayment = true;
      }
    });
  }

  if (mywallet && !bankPayment) {
    return true;
  }

  return false;
};

export function getCabFeatures(response) {
  const cabFeatures = [];
  let luggageText = null;

  const seatText = `${response.carAdditionalInfo.carCapacity} ${CabBookingDetailsConstant.SEATS}`;
  const acText = (response.carAdditionalInfo.airCondition === CabBookingDetailsConstant.AC_AVAILABLE) ? CabBookingDetailsConstant.AC : undefined;

  if (response.carAdditionalInfo && response.carAdditionalInfo.additionalLOBInfo && response.carAdditionalInfo.additionalLOBInfo.tripInfo && !_.isEmpty(response.carAdditionalInfo.additionalLOBInfo.tripInfo.bagsCount)) {
    luggageText = `${response.carAdditionalInfo.additionalLOBInfo.tripInfo.bagsCount} ${CabBookingDetailsConstant.BAGS}`;
  }

  cabFeatures.push(seatText);
  if (acText) {
    cabFeatures.push(acText);
  }
  if (luggageText != null) {
    cabFeatures.push(luggageText);
  }

  return cabFeatures;
}

export function checkOnSpotDataAvailable(response) {
  return !_.isEmpty(response) && !_.isEmpty(response.carAdditionalInfo) &&
    !_.isEmpty(response.carAdditionalInfo.additionalLOBInfo) &&
    !_.isEmpty(response.carAdditionalInfo.additionalLOBInfo.driverCommunicationType) &&
    CabBookingDetailsConstant.ONSPOT_BOOKING_TYPE === response.carAdditionalInfo.additionalLOBInfo.driverCommunicationType;
}

export async function openCabReviewPage(response, pageName) {
  CabBookingTrackingHelper.trackClickEvent(pageName, CabBookingDetailsConstant.CLICK_CAB_REVIEW);
  if (checkReviewLinkAvailable(response)) {
    const { UserSessionModule } = NativeModules;
    const showGdprBlockerScreen =  await shouldShowGdprBlockerScreen();
    if (showGdprBlockerScreen) {
      const { gdprText } = getStaticData(false);
      const { lobs: { cabs = 'Cabs'} = {} } = gdprText || {}
      UserSessionModule.openGDPRBlockerScreen(cabs, null, getGdprData(gdprText));
    }else{
      GenericModule.openDeepLink(response.carAdditionalInfo.additionalLOBInfo.tripReview.deeplink);
    }
  } else {
    CabBookingTrackingHelper.trackClickEvent(pageName, CabBookingDetailsConstant.CAB_REVIEW_UNAVAILABLE);
    Alert.alert(CabBookingDetailsConstant.REVIEW_LINK_UNAVAILABLE);
  }
}

export function checkReviewLinkAvailable(response) {
  return !_.isEmpty(response) && !_.isEmpty(response.carAdditionalInfo) &&
    !_.isEmpty(response.carAdditionalInfo.additionalLOBInfo) &&
    !_.isEmpty(response.carAdditionalInfo.additionalLOBInfo.tripReview) &&
    !_.isEmpty(response.carAdditionalInfo.additionalLOBInfo.tripReview.deeplink);
}

export function chatCabdriver(response) {

  initiateChatWithDriver(response);
  const bookingId = response.bookingId;
  const isSessionPresent = response.clientDetails && response.clientDetails.isChatSessionPresent;
  const chatUrl = response.clientDetails.driverChatUrl;
  const chatParams = getQueryParamsFromUrl(chatUrl);
  const {
    driverId
  } = chatParams;
  const paramMap = {
    entityType: ENTITY_TYPES.POST_SALES_BOOKING_DRIVERCHAT,
    entityKey: bookingId,
    pageIdentifier: PAGE_IDENTIFIER.CAB_DETAILS_PAGE,
    driverId: driverId,
    openChat: true,
    toUser: driverId,
    chatOwner: MYTA_CHAT_OWNER.DRIVER
  };
  if (isSessionPresent) {
    paramMap.chatOwner = MYTA_CHAT_OWNER.DRIVER;
  } else {
    paramMap.intent = MYRA_INTENTS.CHAT_WITH_DRIVER_INTENT;
  }
  GenericModule.openMyraFromParams(paramMap);

}

export async function initiateChatWithDriver(response) {
  try {
    const hasNetwork = await isNetworkAvailable();
    if (!hasNetwork) {
      CabBookingTrackingHelper.trackErrorEvent('mob: cabs: initiate chat with driver Failed:No Internet');
      return;
    }
    const userInfo = await HotelBookingModule.getRequestHeader(response.bookingId, 'INITIATE_DRIVER_CHAT_SERVICE');
    const commonHeaders = await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo);
    const request = {
      bookingId: response.bookingId
    };
    const apiResponse = await fetch(INITIATE_DRIVER_CHAT, {
      method: 'POST',
      body: JSON.stringify(request),
      headers: commonHeaders,
    });
    if (apiResponse.ok) {
      const body = await apiResponse.json();
      if (body.success) {
        CabBookingTrackingHelper.trackLoadEvent('mob: cabs: chat with driver initiated', response);
      }
    }
  } catch (error) {
    CabBookingTrackingHelper.trackErrorEvent('mob: cabs: initiate chat with driver Failed:server error');
  }
}

export function getAmenities(amenities) {
  let list = [];
  for (let amenity in amenities) {
    const listObj = amenities[amenity];
    if (listObj && listObj.length > 0) {
      list = list.concat(listObj);
    }
  }
  return list;
}
export function checkIfExist(object, string) {
  return _.get(object, string);
}
export const vendorTnCHandler = (data, pageName) => {
  const { url, openInWebView, webViewTitle } = data || {};
  CabBookingTrackingHelper.trackClickEvent(pageName,CabBookingDetailsConstant.SPECIAL_PROGRAM_TNC_CLICK);
  if (url && openInWebView && Platform.OS !== 'web') {
    Actions.openPsWebView({
      url,
      headerText: webViewTitle,
    });
  } else if (url) {
    Linking.openURL(url);
  }
};

export const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';

export const trackError = () => {
  CabBookingTrackingHelper.trackErrorEvent('mob: cabs: mima main page:Error');
};


export const getTopStatusPopUPCard = (response)=>{
  const cardList = get(response,'layoutDetail.cardList',[]);
  const connectToFirebase = get(response,'carBookingInfo.carDetail.connectToFirebase',false);
  if (!isEmpty(cardList)) {
    const cardId = cardList[0].cardId;
    const subCardList = cardList[0]?.subCardList || [];
    const subCardId = !isEmpty(subCardList) && subCardList[0]?.cardId;
    if (
      subCardId === CabBookingDetailsConstant.SUBCARD_DRIVER_ASSIGNED_CARD
      ||
      (
        (cardId === CabBookingDetailsConstant.TOP_STAUTUS_LIVETRACKING_ENABLED_POST_PICKUP ||
          cardId === CabBookingDetailsConstant.TOP_STATUS_LIVE_TRACKING_AFTER_PRE_JOURNEY)
        &&
        (subCardId === CabBookingDetailsConstant.SUBACRD_CAR_TRACKER_PRE_PICKUP ||
          subCardId === CabBookingDetailsConstant.SUBACRD_CAR_TRACKER_POST_PICKUP)
        && connectToFirebase
      )
      ){
           return {
             ...subCardList[0],
             heading:cardList[0].heading,
            };
      }
      else if (cardId === CabBookingDetailsConstant.TOP_STATUS_ADDRESS_PENDING && !isEmpty(cardList[0].actionList)){
        return cardList[0];
     }
     return false;
  }
  return false;
};

export const getTopStatusCardId = (response) => {
  const cardList = get(response,'layoutDetail.cardList',[]);
  if (!isEmpty(cardList)) {
    return cardList[0]?.cardId;
  }
  return '';
}

export const isDownloadIconVisible = (response)=>{
  const downloadActionList = response?.clientDetails?.downloadActionList?.actionlist;
  return !isEmpty(downloadActionList);
};

export const getRydeBannerData = (responseJson)=>{
  const isRydeBooking = responseJson.rydeBooking;
  const rydeBannerIfo =
    responseJson && responseJson.rydeBannerInfo;
  if (!isRydeBooking && responseJson.upgradeRyde) {
    return (
      rydeBannerIfo &&
      rydeBannerIfo.UpgradeRyde &&
      rydeBannerIfo.UpgradeRyde.bannerInfo
    );
  }
  if (isRydeBooking) {
    return (
      rydeBannerIfo &&
      rydeBannerIfo.NormalRyde &&
      rydeBannerIfo.NormalRyde.bannerInfo
    );
  }
  return {};
};

export const getPaxName = (firstname, lastname) =>
  `${!isEmpty(firstname) ? firstname : ''}${
    !isEmpty(lastname) ? lastname : ''
  }`;

export const truncateString = (str, maxLength) => {
 const truncatedVal = str && str.length > maxLength ? `${str.substring(0, maxLength - 3)}...` : str;
 return truncatedVal;
};

export const getCarTrackerOverlayCards = (response)=>{
  let overlayCards = {};
  const cardList = response?.layoutDetail?.cardList;
  if (!isEmpty(cardList)){
    const subCardList = cardList[0]?.subCardList;
    if (!isEmpty(subCardList) &&
      (subCardList[0].cardId === CabBookingDetailsConstant.SUBACRD_CAR_TRACKER_POST_PICKUP ||
        subCardList[0].cardId === CabBookingDetailsConstant.SUBACRD_CAR_TRACKER_PRE_PICKUP)
      ){
      const driverAssignedCards = cardList.find(item => item.cardId === CabBookingDetailsConstant.SUBCARD_DRIVER_ASSIGNED_CARD);
      overlayCards = {
        heading: cardList[0].heading,
        carTrackerData: subCardList[0],
        driverAssignedCard: !isEmpty(driverAssignedCards) ? driverAssignedCards : {},
      };
    }
  }
  return overlayCards;
};


export const getPolicyData = (response) => {
  const policyList = response?.cancellationPolicyDetailList;
  const dataList = new Array(policyList.size);
  if (!isEmpty(policyList)) {
    policyList.forEach((policy, index) => {
      const { penalty_percent, refund_sub_title, refund_title } = policy;
      const fromDate = epocTimeToFormattedTime(policy.from_ts, 'ddd, DD MMM, hh:mm A');
      const toDate = epocTimeToFormattedTime(policy.to_ts, 'ddd, DD MMM, hh:mm A');
      dataList[index] = {
        timeData: {
          heading:
            fromDate === toDate ? 'After ' + `<b>${fromDate}</b>` : 'Until ' + `<b>${toDate}</b>`,
          subHeading: refund_title,
        },
        chargesData: {
          penaltyText:
            penalty_percent === 0
              ? '<q>FREE</q>'
              : penalty_percent === 100
              ? 'No Refund'
              : `<b>${refund_sub_title}</b>`
        },
      };
    });
    return dataList;
  }
  return null;
};


export const isSelfServerEnabled = () => {
  return getPokusConfig(PokusLobs.POST_SALES, AbConfigKeyMappings.cab_needHelp, false);
};

export const onCopyText = async (text) => {
  const { copiedToClipboardText } = getStaticData(false);
  const copyStatus = await copyToClipboard(text);
  if (copyStatus) {
    showLongToast(copiedToClipboardText);
  }
};

export const getBasePath = (bookingId, basePath) => {
	const basePathFrom = `${basePath}${bookingId}/FROM`;
	const basePathTo = `${basePath}${bookingId}/TO`;
	const basePathStatic = `${basePath}${bookingId}/LOCATION`;
	const basePathEvents = `${basePath}${bookingId}/EVENT`;
	return { basePathFrom, basePathTo, basePathStatic, basePathEvents };
}


export const getInitFlowType = (props) => {
  if (Platform.OS === 'android') {
    const { data } = props;
    const { pageData } = data || {};
    if (typeof pageData === 'string') {
      try {
        const val = JSON.parse(pageData)
        return val?.type;
      } catch {
        return '';
      }
    }
  } else if (Platform.OS === 'ios') {
    const { type } = props;
    return type;
  }
  return '';
}

export const getRatingColors = (rating, color) => {
  if (rating > 0 && rating < 3) {
      return color.yello;
    } else if (rating >= 3 && rating <= 3.5) {
      return color.lightGreen4;
    } else if (rating > 3.5 && rating <= 4.0) {
      return color.green5;
    } else if (rating > 4.0 && rating <= 4.5) {
      return color.green6;
    } else {
      return color.green;
    }
  };
export const myGateView = () => {
  const params = new URLSearchParams(location.search);
  const source = params?.get("cmp");
  return source === SOURCE.MYGATE;
}
