import { View, StyleSheet, Dimensions } from 'react-native';

export default (theme, fonts) => {
  const { color } = theme;
  return StyleSheet.create({
    container: {
      backgroundColor: color.greyBookedSeat,
      flex: 1,
    },
    topWrapper: {
      backgroundColor: color.creamWhite,
      padding: 16,
      paddingBottom: 0,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderBottomLeftRadius: 16,
      borderBottomRightRadius: 16,
      marginBottom: 10,
      marginHorizontal: -16,
    },
    scrollSection: {
      paddingHorizontal: 16,
      paddingBottom: 16,
    },
  });
};
