import React from "react";
import PropTypes from 'prop-types';
import { TouchableOpacity, Image, Text } from 'react-native';

const RectRoundIconBtn = ({ handleClick, btnText, downloadIcon, btnTextStyle, btnStyle, iconStyle }) => (
  <TouchableOpacity style={btnStyle} onPress={handleClick}>
    {!!downloadIcon && <Image style={iconStyle} source={downloadIcon} />}
    <Text style={btnTextStyle}>{btnText}</Text>
  </TouchableOpacity>
)

RectRoundIconBtn.propTypes = {
  handleClick: PropTypes.func.isRequired,
  btnText: PropTypes.string.isRequired,
  btnStyle: PropTypes.object.isRequired
};

export default RectRoundIconBtn;

