import React from 'react';
import Actions from '../../../navigation/postSalesNavigation';
import PropTypes from 'prop-types';
import Header from '@mmt/legacy-commons/Common/Components/Header/Header';
import {Alert, Image, Platform, ScrollView, Text, View} from 'react-native';
import getStyles from './HotelMamiCss';
import HotelBookingTrackingHelper from '../../HotelBookingTrackingHelper';
import {isIntlHotelBooking} from '../utils/HotelBookingDetailUtil';
import {
  getActionButton,
  getButtonStyle, handleClickEvent, isNotNullAndEmpty, getThrottleParams
} from '../utils/HotelBookingDetailUtil';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

export const GET_DIRECTIONS_PAGE = 'ViewGetDirections';


class ViewGetDirections extends React.Component {
    static navigationOptions = {
      header: null
    }

    constructor(props) {
      super(props, GET_DIRECTIONS_PAGE);
      this.fonts = getFont(true);
      this.styles = getStyles(this.fonts);
    }

    render() {
      return (
        <View style={[this.styles.whiteContainer, this.styles.transperantBgrnd]}>
          <Header title={this.props.headerText} onBackPress={this.onBackPress} type='Elevated' />
          <View style={isNotNullAndEmpty(this.props.actionCard.actionList) ? getButtonStyle(this.props.actionCard.actionList) : this.styles.hideView}>
             {getActionButton(this.props.actionCard, this.props.response, this.handleClick, getThrottleParams)}
          </View>
        </View>
      );
    }

    componentDidMount() {
      const trackPageName = isIntlHotelBooking(this.props.response?.lobCode)
        ? 'Mob:customer support:Hotel International:GetDirectionsMessage'
        : 'Mob:customer support:Hotel Domestic:GetDirectionsMessage';
      HotelBookingTrackingHelper.trackLoadEvent(trackPageName, this.props.response);
    }

    onBackPress = () => {
      Actions.pop();
    }

    handleClick = (action) => {
        handleClickEvent(this.props.actionCard, action, this.props.response,this.props.pageName);
    };


}

ViewGetDirections.propTypes = {
  response: PropTypes.object.isRequired
};

export default ViewGetDirections;
