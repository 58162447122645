import React, { useEffect, useState } from 'react';
import { View, ScrollView } from 'react-native';
import Actions from '../../../navigation/postSalesNavigation';
import { isEmpty } from 'lodash';
import { useTheme } from '../../../theme/theme.context';
import { getFont } from '../../../PostSalesStyles';
import CabBookingDetailsConstant, {
  CabRescheduleErrorData,
  CLICK_EVENT,
  PAGE_NAME,
} from '../../CabBookingDetailsConstant';
import RescheduleAwaitingCard from './components/RescheduleAwaitingCard';
import RescheduleInfoCard from './components/RescheduleInfoCard';
import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';
// import DatePickerCard from './components/DatePickerCard';
import JourneyDetailCard from './components/JourneyDetailCard';
import BookingDetailNavigation from './components/BookingDetailNavigation';
import HeaderWrapper from 'apps/post-sales/src/Common/HeaderWrapper';
import ViewState from 'packages/legacy-commons/Common/constants/ViewState';
import CabRescheduleThankyouPage from './components/CabRescheduleThankyouPage';
import ErrorPage from './components/ErrorPage';
import { calendarIconError } from '../../CabImageConstants';
import { CabRescheduleProps, CabRescheduleResponse, RescheduleCard } from '../../types';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import ProgressView from 'apps/post-sales/src/Common/ProgressView/ProgressView';
import CabBookingTrackingHelper from '../../CabBookingTrackingHelper';
import { getCabReschedulePageName } from './data/helper';
import useApi from 'apps/post-sales/src/Common/useApi';
import { CAB_RESCHEDULE_URL } from 'apps/post-sales/src/utils/NetworkUtils';
import { LOBNAMES } from 'apps/post-sales/src/PostSalesConstant';

const cabReschedule: React.FC<CabRescheduleProps> = ({
  bookingId,
  cabRescheduleData,
  cardData,
  response,
}) => {
  const { carBookingInfoReschedule, dateTime, cabDetailInfo } = cardData || {};
  const { pageHeader, cardsData } = cabRescheduleData || {};
  const [viewState, setViewState] = useState<string>(ViewState.SHOW_DETAIL);
  const [rescheduleResponse, setRescheduleResponse] = useState<CabRescheduleResponse>({});
  const [pickupDate, setPickupDate] = useState<Date>(new Date());
  const [rescheduleDateSubmitProgress, rescheduleDateSubmitData, rescheduleDateSubmitApi] =
    useApi(CAB_RESCHEDULE_URL);

  const {
    rescheduleAwaitingCard,
    rescheduleInfoCard,
    datePickerCard,
    journeyDetailCard,
    goToMyTripsCard,
  } = cardsData || {};
  const { psTheme } = useTheme();
  const { fsStyle, ...fonts } = getFont();

  useEffect(() => {
    if(viewState !== ViewState.LOADING){
      const pageName = getCabReschedulePageName(viewState);
      CabBookingTrackingHelper.trackLoadEvent(pageName, response);
    }
  }, [viewState]);

  useEffect(() => {
    if (!isEmpty(rescheduleDateSubmitData)) {
      if (rescheduleDateSubmitData.error || rescheduleDateSubmitData.errorData) {
        setViewState(ViewState.ERROR);
        !isEmpty(rescheduleDateSubmitData.errorData)
          ? setRescheduleResponse(rescheduleDateSubmitData.errorData)
          : setRescheduleResponse(CabRescheduleErrorData);
      } else {
        setViewState(ViewState.SUCCESS);
        setRescheduleResponse(rescheduleDateSubmitData);
      }
    }
  }, [rescheduleDateSubmitData]);

  useEffect(() => {
    if(rescheduleDateSubmitProgress){
      setViewState(ViewState.LOADING);
    }
  }, [rescheduleDateSubmitProgress]);

  const handlePickupDatePickerChange = (date: Date) => {
    CabBookingTrackingHelper.trackClickEvent(
      PAGE_NAME.CAB_RESCHEDULE_PAGE,
      CLICK_EVENT.RESCHEDULE_DATE_SELECTED,
    );
    !!date && setPickupDate(date);
  };

  const onBackPress = () => {
    Actions.pop();
  };

  const handleSubmitClick = async () => {
    if (pickupDate) {
      CabBookingTrackingHelper.trackClickEvent(
        PAGE_NAME.CAB_RESCHEDULE_PAGE,
        CLICK_EVENT.CONFIRM_RESCHEDULE_CLICK,
      );
      rescheduleDateSubmitApi.httpPost({
        body: {
          bookingId: bookingId,
          rescheduleDateTime: pickupDate.getTime(),
          currDateTime: Date.now()
        },
        bookingId: bookingId,
        uniqueId: 'CAB_RESCHEDULE',
        psLob: LOBNAMES.CAR,
      });
    }
  };

  const addCard = (card: RescheduleCard, views: JSX.Element[]) => {
    switch (card.cardId) {
      case CabBookingDetailsConstant.RESCHEDULE_AWAITING_CARD:
        views.push(
          <RescheduleAwaitingCard cardData={rescheduleAwaitingCard} />,
        );
        break;

      case CabBookingDetailsConstant.RESCHEDULE_INFO_CARD:
        views.push(<RescheduleInfoCard cardData={rescheduleInfoCard} />);
        break;

      // case CabBookingDetailsConstant.RESCHEDULE_DATE_PICKER_CARD:
      //   views.push(
      //     <DatePickerCard
      //       cardData={datePickerCard}
      //       handleSubmitClick={handleSubmitClick}
      //       pickupDate={pickupDate}
      //       handlePickupDatePickerChange={handlePickupDatePickerChange}
      //     />,
      //   );
      //   break;

      case CabBookingDetailsConstant.RESCHEDULE_JOURNEY_DETAILS_CARD:
        views.push(
          <JourneyDetailCard
            cardData={journeyDetailCard}
            carBookingInfo={carBookingInfoReschedule}
            dateTime={dateTime}
            cabDetailInfo={cabDetailInfo}
            bookingId={bookingId}
          />,
        );
        break;

      case CabBookingDetailsConstant.RESCHEDULE_GO_TO_MYTRIPS_CARD:
        views.push(<BookingDetailNavigation cardData={goToMyTripsCard} response={response} />);
        break;

      default:
        break;
    }
    return views;
  };

  const getMainView = () => {
    const { cardList } = cabRescheduleData || {};
    const views: JSX.Element[] = [];
    !isEmpty(cardList) &&
      cardList.forEach((card) => {
        if (card.visible) addCard(card, views);
      });
    return views;
  };

  const renderPage = () => {
    return (
      <ScrollView style={{ backgroundColor: psTheme.color.greyBookedSeat }}>
        <HeaderWrapper titleText={pageHeader} clickHandler={onBackPress} />
        {getMainView()}
      </ScrollView>
    );
  };

  const renderLoader = () => <ProgressView />;

  const retryHandler = () => {
    CabBookingTrackingHelper.trackClickEvent(
      PAGE_NAME.CAB_RESCHEDULE_PAGE,
      CLICK_EVENT.CAB_RESCHEDULE_TRY_AGAIN_CLICK,
    );
    setViewState(ViewState.SHOW_DETAIL);
  };

  return (
    <View style={AtomicCss.flex1}>
      {viewState === ViewState.SHOW_DETAIL && renderPage()}
      {viewState === ViewState.LOADING && renderLoader()}
      {viewState === ViewState.ERROR && (
        <ErrorPage
          heading={rescheduleResponse?.heading}
          subHeading={rescheduleResponse?.subHeading}
          imageUrl={calendarIconError}
          retryHandler={retryHandler}
        />
      )}
      {viewState === ViewState.SUCCESS && (
        <CabRescheduleThankyouPage response={rescheduleResponse} bookingId={bookingId} />
      )}
    </View>
  );
};

export default cabReschedule;
