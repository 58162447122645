// PostSales icons
import {getImagePath} from '../../utils/PostSaleUtil';

export const cancelIcon = getImagePath('ic_partial_cancellation.webp');
export const completedStepIcon = getImagePath('ic_fp_tick_white_copy_2.webp');
export const topStatusIconCancelled = getImagePath('topstatusIcon8.webp');
export const topStatusIconFailed = getImagePath('topstatusIcon7.webp');
export const policyImg = getImagePath('ic_cancellation_policy.webp');
export const crossIcon = getImagePath('ic_cross.webp');
export const voucherIcon = getImagePath('ic-voucher.webp');
export const mainCardIcon = getImagePath('ic_tick_stroke.webp');
export const mapImage = getImagePath('group_42.webp');
export const pickupCardIcon = getImagePath('group_53.webp');
export const primaryCardIcon = getImagePath('guest_icon.webp');
export const emailIcon = getImagePath('group_24.webp');

// Common Icons
export const refundIcon = getImagePath('refundIcon.webp'); // used in other lobs
export const cancellingIcon = getImagePath('cancelling-icon.webp'); // used in other lobs
export const topStatusIconUpcoming = getImagePath('topstatusIcon1.webp'); // used in other lobs
export const topStatusIconCompleted = getImagePath('topstatusIcon9.webp'); // used in other lobs
export const paymentIcon = getImagePath('payment_icon.webp'); // used in other lobs
export const headerIcon = require('@mmt/legacy-assets/src/trip_header_back_icon.webp');
export const closeIcon = require('@mmt/legacy-assets/src/ic_close_black.webp');
export const headerWhiteIcon = require('@mmt/legacy-assets/src/whiteCross.webp');
export const covidCard = require('@mmt/legacy-assets/src/Acme/mySafetyTop.webp');
export const inclusionIcon = require('@mmt/legacy-assets/src/green.webp');
export const exclusionIcon = require('@mmt/legacy-assets/src/ic_cross_gray.webp');
export const locationCardIcon = require('@mmt/legacy-assets/src/location_icon.webp');
export const contactIcon = require('@mmt/legacy-assets/src/ic_contact_stroke.webp');
export const backIcon = require('@mmt/legacy-assets/src/white_backarrow.webp');
