import React from "react";
import {View, Text, TouchableOpacity, Image, Platform} from "react-native";
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';
import {colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import {backArrow, backArrowIOS, searchIcon, refreshIcon} from '../HolidayBookingConstants';

const Header = props => {
  const headerStyle = [styles.headerWrapper];
  if (props.headerShadow) {
    headerStyle.push(styles.headerShadow);
  }

  return (
    <View style={headerStyle}>
      <TouchableOpacity
        style={styles.backArrowWrapper}
        onPress={() => props.goBack(props.pageName)}
      >
        <Image
          style={
            Platform.OS === "ios" ? [styles.backArrowIos] : [styles.backArrow]
          }
          source={Platform.OS === "ios" ? backArrowIOS : backArrow}
        />
      </TouchableOpacity>
      <View style={AtomicCss.flex1}>
        <Text
          style={
            Platform.OS === "ios"
              ? [styles.headingTxt, AtomicCss.textCenter]
              : [styles.headingTxt]
          }
        >
          {props.title}
        </Text>
        {!!props.subTitle && (
          <Text
            style={
              Platform.OS === "ios"
                ? [styles.subTxt, AtomicCss.textCenter]
                : [styles.subTxt]
            }
          >
            {props.subTitle}
          </Text>
        )}
      </View>
      {props.headerRtSection === true && (
        <View style={AtomicCss.flexRow}>
          <TouchableOpacity>
            <Image style={styles.searchIcon} source={searchIcon} />
          </TouchableOpacity>
          <TouchableOpacity>
            <Image style={styles.refreshIcon} source={refreshIcon} />
          </TouchableOpacity>
        </View>
      )}
    </View>
  );
};

const styles = {
  headerWrapper: {
    backgroundColor: "#fff",
    justifyContent: "space-between",
    padding: 16,
    flexDirection: "row",
    alignItems: "center",
    zIndex: 10,
  },
  headerShadow: {
    ...getPlatformElevation(2)
  },
  headingTxt: {
    fontSize: 18,
    color: "#4a4a4a",
    fontFamily: fonts.bold
  },
  subTxt: {
    fontFamily: fonts.regular,
    fontSize: 11,
    color: colors.defaultTextColor,
    marginTop: 3
  },
  searchIcon: {
    width: 16,
    height: 16,
    marginRight: 10
  },
  backArrowWrapper: {
    paddingRight: 5
  },
  backArrow: {
    width: 24,
    height: 24,
    marginRight: 5
  },
  refreshIcon: {
    width: 16,
    height: 16,
    marginRight: 10
  },
  backArrowIos: {
    width: 11,
    height: 18,
    marginRight: 5
  }
};

export default Header;
