import React from 'react';
import { View, Text } from 'react-native';
import { getFont } from '../../../PostSalesStyles';
import styles from './BreadcrumbCss';

const Breadcrumb = (props) => {
  const fonts = getFont(true);
  return (
    <View style={styles.steps}>
      {props.data.map((step, index) => {
        const activeStyle = index <= props.activeStep ? 'Active' : '';
        return (
          <View key={index} style={styles.stepItem}>
            <View style={[styles.stepNumberWrapper, styles[`stepNumberWrapper${activeStyle}`]]}>
              <Text style={styles.stepNumber}>
                {index + 1}
              </Text>
            </View>
            <Text style={[styles.stepName, styles[`stepName${activeStyle}`], fonts.regularFontFamily, fonts.font10]}>{step}</Text>
          </View>
        );
      })}
    </View>
  );
};

export default Breadcrumb;
