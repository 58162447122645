import React, {useMemo} from 'react';
import {View, Text, Image, ScrollView} from 'react-native';
import  isEmpty  from 'lodash/isEmpty';
import Actions from '../../navigation/postSalesNavigation';
import RedirectBtn from './RedirectBtn';
import TopMessage from './TopMessage';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';
import {fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import LableRow from './LabelRow';
import {buttonTypes} from '../HolidayBookingConstants';
import {
  removeTags,
  getActivityTimeInfo,
} from '../HolidayBookingDetailUtils';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import genericActivityDefaultImage from '@mmt/legacy-assets/src/no_dest_default.webp';

import {
  screenWidth,
  CruiseIcon,
  ActivityIcon,
  holidayType,
  StarFilled,
  StarEmpty,
} from '../HolidayBookingConstants';
import HolidayBookingTrackingHelper from '../HolidayBookingTrackingHelper';

const ICON_CONFIG = {
  [holidayType.ACTIVITY]: {
    image: ActivityIcon,
    iconStyle: 'activityIcon'
  },
  [holidayType.CRUISE]: {
    image: CruiseIcon,
    iconStyle: 'cruiseIcon'
  }
};

const ActivityList = (props) => {

  const {
    activityDetail,
    contactList,
    setContactInfo,
    holidayResponse,
    pageName,
    bookingId,
  } = props;

  const {holidayContactList} = contactList;
  const {activityBookingMessage, holidayActivityList} = activityDetail;
  if (!holidayActivityList || holidayActivityList.length === 0) {
    return (
      <View style={{
        width: screenWidth,
        alignItems: 'center'
      }}
      >
        <Text
          style={[
            AtomicCss.blackFont,
            AtomicCss.font16,
            AtomicCss.blackText
          ]}
        >
          No Activity Found
        </Text>
      </View>
    );
  }
  const contactMapping = useMemo(() => {
    const contactMap = [];
    for (let i = 0; i < holidayActivityList.length; i += 1) {
      const {lineno, type} = holidayActivityList[i];
      const list = [];
      for (let j = 0; j < holidayContactList.length; j += 1) {
        const contact = holidayContactList[j];
        if (contact.contactType === type && contact.lineNo === lineno) {
          list.push(holidayContactList[j]);
        }
      }
      contactMap[i] = list;
    }
    return contactMap;
  }, [holidayContactList, activityDetail]);

  const viewDetail = async (refId) => {
    HolidayBookingTrackingHelper.trackClickEvent(pageName, 'View_Activity_Detail', 'componentList_Activities');
    const userInfo = await HotelBookingModule.getRequestHeader(bookingId, 'ACME_HOLIDAY_DETAIL');
    HolidayBookingTrackingHelper.trackViewEvent(
      pageName + 'Activities',
      'Activity Booking Details',
    );
    Actions.acmeBookingDetail({
      data: {
        BOOKING_ID: bookingId,
        MMT_AUTH: userInfo.mmtAuth,
        referenceId: refId,
      },
      stickyHeader: false,
      type: 'push',
      holidayBookingApi: true,
    })
  }

  const activityAction = (contactIndex, button) => {
    HolidayBookingTrackingHelper.trackClickEvent(pageName, `Activity_Contact_Vendor_${contactIndex}`);
    if (button.type === buttonTypes.CONTACTS) {
      setContactInfo(contactMapping[contactIndex]);
    }
  };

  const getFormattedActivityDateTime = ({
    startDate,
    endDate,
    startTime,
    endTime,
  }) => {

    // To handle date display format

    if (!endDate) {
      return <View style={styles.acmeTimeInfo}>
        <Text
          style={[
            AtomicCss.defaultText,
            AtomicCss.font14,
            AtomicCss.boldFont,
          ]}
        >
          {`${startDate}${startTime ? ', ' : ' '}`}
        </Text>
        <Text
          style={[
            AtomicCss.defaultTextColor,
            AtomicCss.font14,
            AtomicCss.regularFont,
          ]}
        >
          {!endTime ?
            startTime :
            `${startTime} - ${endTime}`
          }
        </Text>
      </View>
    }
    else {
      return <View style={styles.acmeTimeInfo}>
        <Text
          style={[
            AtomicCss.defaultText,
            AtomicCss.font14,
            AtomicCss.boldFont,
          ]}
        >
          {`${startDate}${startTime ? ', ' : ' '}`}
        </Text>
        <Text
          style={[
            AtomicCss.defaultTextColor,
            AtomicCss.font14,
            AtomicCss.regularFont,
          ]}
        >
          {startTime}
        </Text>
        <View style={styles.triangle}></View>
        <Text
          style={[
            AtomicCss.defaultText,
            AtomicCss.font14,
            AtomicCss.boldFont,
          ]}
        >
          {`${endDate}${endTime ? ', ' : ' '}`}
        </Text>
        <Text
          style={[
            AtomicCss.defaultTextColor,
            AtomicCss.font14,
            AtomicCss.regularFont,
          ]}
        >
          {endTime}
        </Text>
      </View>
    }
  }

  const getActivityInformation = (item) => {

    // Return different activity information if
    // activity type is ACME

    if (item.acmeActivity) {
      return <View>
        <View style={styles.flightsTimeInfo}>
          {getFormattedActivityDateTime(getActivityTimeInfo(item))}
        </View>
        <View style={[AtomicCss.flexRow, AtomicCss.marginBottom10]}>
          <Text style={[
            AtomicCss.regularFont,
            AtomicCss.font14,
          ]}
          >
            <Text
              style={[
                AtomicCss.boldFont,
                AtomicCss.font14,
                AtomicCss.defaultText
              ]}
            >
              {item.packagename}
            </Text>
          </Text>
        </View>
      </View>
    } else {
      return <View>
        <LableRow
          label={'Guest(s):'}
          value={item.noOfPassengers}
        />
        <LableRow label="Night(s):" value={item.noOfNights} />
        <LableRow label="Cabin(s):" value={item.noOfCabins} />
        {item.inclusions ? (
          <Text style={[
            AtomicCss.regularFont,
            AtomicCss.font14,
            AtomicCss.defaultText,
            AtomicCss.marginBottom10
          ]}
          >
            <Text style={[AtomicCss.boldFont]}>Inclusions:</Text>{' '}
            {removeTags(item.inclusions)}
          </Text>
        ) : null}
        {item.exclusions ? (
          <Text style={[
            AtomicCss.regularFont,
            AtomicCss.font14,
            AtomicCss.defaultText,
            AtomicCss.marginBottom10
          ]}
          >
            <Text style={[AtomicCss.boldFont]}>Exclusions:</Text>{' '}
            {removeTags(item.exclusions)}
          </Text>
        ) : null}
      </View>
    }
  }

  return (
    <ScrollView style={{ width: screenWidth }}>
      <React.Fragment>
        {activityBookingMessage && (
          <TopMessage
            img="voucherIcon"
            info={activityBookingMessage}
            bgcolor="#cf8100"
          />
        )}
        <View>
          {holidayActivityList.map((item, index) => {
            const stars = [];

            for (let i = 0; i < 5; i++) {
              if (i < item.starrating) {
                stars.push(<Image style={styles.starIcon} source={StarFilled} />);
              } else {
                stars.push(<Image style={styles.starIcon} source={StarEmpty} />);
              }
            }
            return (
              <View
                style={[styles.cardWrapper, AtomicCss.marginBottom10]}
                key={index}
              >
                <View style={[styles.activityList, AtomicCss.flexRow]}>
                  <View>
                    <Image
                      style={styles[ICON_CONFIG[item.type].iconStyle]}
                      source={ICON_CONFIG[item.type].image}
                    />
                  </View>
                  <View style={styles.activityListRight}>
                    <View style={item.acmeActivity && styles.activityTopInfo}>
                      {/* styles.activityTopInfo should only be used when activity type is ACME. */}
                      <View style={[AtomicCss.flex1, AtomicCss.marginRight10]}>
                        <Text style={[
                          AtomicCss.blackFont,
                          AtomicCss.defaultTextColor,
                          AtomicCss.font14,
                          AtomicCss.marginBottom8
                        ]}>
                          {item.cityCountryName} ({item.date})
                        </Text>
                        {item.subHeaderText ? (
                          <Text
                            style={[
                              AtomicCss.blackFont,
                              AtomicCss.font16,
                              AtomicCss.blackText,
                              AtomicCss.marginBottom10
                            ]}
                          >{item.subHeaderText}
                          </Text>
                        ) : null}
                        {
                          item.acmeActivity &&
                          item.starrating &&
                          <View style={[AtomicCss.flexRow, AtomicCss.marginBottom15]}>{stars}</View>
                        }
                      </View>
                      {item.acmeActivity && < View style={AtomicCss.marginBottom10} >
                        <Image style={styles.activityImage} source={{ uri: item.activityImage }} />
                        <Image style={styles.activityImageDefault} source={genericActivityDefaultImage} />
                      </View>}
                    </View>
                    {getActivityInformation(item)}
                    {item.bookingMessage && (
                      <Text style={styles.infoTxt}>{item.bookingMessage}</Text>
                    )}
                  </View>
                </View>
                {
                  item.acmeActivity && <RedirectBtn
                    btnName={"View Activity Details"}
                    onPress={() => {
                      viewDetail(item.addonId)
                    }}
                  />
                }
                {!isEmpty(item.buttons) && item.buttons.map((button) => {
                  return (
                    <RedirectBtn
                      btnName={button.text}
                      key={button.type}
                      onPress={() => {
                        activityAction(index, button);
                      }}
                    />
                  )
                })}
              </View>
            )
          })}
        </View>
      </React.Fragment>
    </ScrollView>
  );
};

const styles = {
  cardWrapper: {
    width: screenWidth,
    backgroundColor: '#fff',
    ...getPlatformElevation(2),
    paddingLeft: 16,
    paddingTop: 16,
    marginBottom: 3
  },
  activityIcon: {
    width: 15,
    height: 23,
    marginRight: 15,
  },
  busIcon: {
    width: 19,
    height: 21
  },
  experienceIcon: {
    width: 24,
    height: 22
  },
  cruiseIcon: {
    width: 17,
    height: 22
  },
  marginBottom4: {
    marginBottom: 4
  },
  bulletText: {
    fontSize: 6,
    paddingHorizontal: 5,
    paddingTop: 3
  },
  pendingInfo: {
    color: '#cf8100',
    fontStyle: 'italic'
  },
  infoTxt: {
    color: '#cf8100',
    fontFamily: fonts.regular,
    fontSize: 14,
    lineHeight: 20,
    fontStyle: 'italic'
  },
  starIcon: {
    width: 8,
    height: 8,
    marginRight: 1
  },
  flightsTimeInfo: {
    flexDirection: 'row',
    paddingVertical: 10,
    alignItems: 'center'
  },
  acmeTimeInfo: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  triangle: {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderLeftWidth: 4,
    borderRightWidth: 4,
    borderBottomWidth: 7,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: '#4a4a4a',
    transform: [{ rotate: '90deg' }],
    marginTop: 2,
    marginLeft: 5,
    marginRight: 5,
    borderWidth: 0,
    borderColor: '#fff'
  },
  activityImageDefault: {
    width: 60,
    height: 60,
    position: 'absolute',
    zIndex: 1,
  },
  activityImage: {
    width: 60,
    height: 60,
    zIndex: 2
  },
  activityList: {
    marginBottom: 10
  },
  activityListRight: {
    flex: 1,
    marginRight: 16
  },
  activityTopInfo: {
    justifyContent: 'space-between',
    borderBottomColor: '#e7e7e7',
    borderBottomWidth: 1,
    flexDirection: 'row'
  },
};

export default ActivityList;
