import { isEmpty } from "lodash";
import React, { useCallback, useMemo } from "react";
import { Image, ScrollView, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import HTMLView from "react-native-htmlview";;
import Actions from '../../../../navigation/postSalesNavigation';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { openHostChatWeb } from "../../utils/HotelBookingDetailUtil";
import createStyle from "./StaffDetailsCSS";
import LinearGradient from 'react-native-linear-gradient';
import { getStaticData } from '../../../../staticData/staticData';
import { COTTAGE_ICON, LANG_ICON, CALL_HOST_ICON, CHAT_HOST_ICON } from '../../../HotelImageConstants';
import HotelBookingDetailsConstant from "../../HotelBookingDetailsConstant";
import { useTheme } from '../../../../theme/theme.context'
import { getFont } from '../../../../PostSalesStyles';
import { HOSTS_ID } from "apps/post-sales/src/PostSalesConstant";

const getDescriptionStyles = (fonts) => {
  return StyleSheet.create({
    span: {
      ...fonts.regularFontFamily,
      ...fonts.font14,
      ...AtomicCss.blackText
    },
    b: {
      ...fonts.blackFontFamily
    }
  });
}

const StaffDetailCard = ({ data, cardId }) => {
  const { fsStyle, ...fonts } = getFont();
  const { psTheme: theme } = useTheme();
  const styles = createStyle(theme, fonts);
  const descriptionStyle = getDescriptionStyles(fonts);
  const { title, subTitle, imageURI, description, chatURL, contact, ctaList, responseRate='', location='', language='', hostingSince=''} = data;
  const staticData = getStaticData();
  const ctaData = useMemo(() => {
    return !isEmpty(ctaList) ? ctaList.map(cta => {
      return {
        type: cta,
        icon: cta == HotelBookingDetailsConstant.CALL_STAFF ? CALL_HOST_ICON : CHAT_HOST_ICON,
      }
    }) : [];
  }, [ctaList]);

  const handleCTAClick = useCallback((cta) => {
    if (cta.type == HotelBookingDetailsConstant.CALL_STAFF) {
      Actions.openCallHotel({ response: {}, headerText: `${staticData.callTxt} ${subTitle}`, phoneNumbers: contact });
    } else if (cta.type == HotelBookingDetailsConstant.CHAT_STAFF) {
      openHostChatWeb(chatURL)
    }
  }, [chatURL, title, contact]);

  const gradientColors = [theme.color.lightGray, theme.color.white]
  const titleNSubtitle = `${title} ${cardId && cardId===HOSTS_ID ? subTitle : ""}`
  return (
    <View style={styles.cardWrapper}>
      <View
        style={[
          AtomicCss.whiteCard,
          AtomicCss.marginRight20,
          AtomicCss.borderRadius16
        ]}
      >
        <View>
          <View style={[AtomicCss.flexRow, AtomicCss.marginBottom16]}>
            <View style={[AtomicCss.flex1]}>
              {!isEmpty(title) && <Text style={[fonts.blackFontFamily, AtomicCss.blackText, fsStyle.font18, AtomicCss.marginBottom10]}>{titleNSubtitle}</Text>}
              {!isEmpty(subTitle) && cardId !== HOSTS_ID && <Text style={[fonts.regularFontFamily, AtomicCss.defaultText, fsStyle.font14, AtomicCss.marginBottom10]}>{subTitle}</Text>}
              {!isEmpty(hostingSince) && <Text style={[fonts.regularFontFamily,AtomicCss.defaultText,fsStyle.font14, AtomicCss.lineHeight15, AtomicCss.marginBottom10]}>{hostingSince}</Text>}
              <LinearGradient
                start={{ x: 0.0, y: 1.0 }}
                end={{ x: 1.0, y: 0.0 }}
                colors={gradientColors}
                style={styles.gradientBorder}
              >
              </LinearGradient>  
              {(!isEmpty(location) || !isEmpty(language)) && 
              <View style={styles.infoSection}>
                {!isEmpty(location) && 
                  <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom3]}>
                    <Image style={styles.cottageIcon} source={COTTAGE_ICON} />
                    <Text style={[AtomicCss.blackText, fonts.regularFontFamily, fsStyle.font14, AtomicCss.lineHeight15]}>{location}</Text>
                  </View>
                } 
                {!isEmpty(language) &&
                <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
                  <Image style={styles.cottageIcon} source={LANG_ICON} />
                  <Text style={[AtomicCss.blackText, fonts.regularFontFamily, fsStyle.font14, AtomicCss.lineHeight15]}>{language}</Text>
                </View>
                } 
              </View>
             }
            </View>
              {!isEmpty(imageURI) &&
                <View style={styles.hostImgWrapper}>
                  <Image
                    style={styles.hostImg}
                    source={{ uri: imageURI }}
                  />
                </View>
              }
          </View>

          {(!isEmpty(description) || !isEmpty(responseRate)) && 
          <View style={styles.moreDesc}>
            {!isEmpty(description) && description.map((desc, index) => {
                return (
                  !isEmpty(desc) && (<View key={index} style={AtomicCss.marginBottom6}>
                    <HTMLView
                      value={desc}
                      stylesheet={descriptionStyle} />
                  </View>)
                );
              })}   
            {!isEmpty(responseRate) && <Text style={[fonts.regularFontFamily, fsStyle.font14]}>{staticData.responseRate}: {responseRate}</Text> }
          </View>
          }
        </View>

        {
          !isEmpty(ctaData) && (
            <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween, styles.actionWrapper]}>
             { ctaData.map((cta, index) =>(  
              <TouchableOpacity key={index} style={[AtomicCss.flexRow, AtomicCss.alignCenter]} onPress={() => handleCTAClick(cta)}>
                <Image style={styles.iconStyle} source={cta.icon} />
                <Text style={[AtomicCss.azure, fsStyle.font12, fonts.blackFontFamily]}>{staticData[cta.type]}</Text>
              </TouchableOpacity>
              ))}
            </View>
          )
        }
      </View>
    </View>
  );
};
export default React.memo(StaffDetailCard);
