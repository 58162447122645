import React from 'react';
import { isEmpty } from 'lodash';
import { Text, View, TouchableOpacity, Image } from 'react-native';
import PropTypes from 'prop-types';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getNoOfStopsText, getAirlineImage } from '../utils/FlightListingUtil';
import { getDurationText } from '../../../data/dataAdapter';
import createStyles from '../css/FlightListItemCss';
import { OUT_OF_POLICY } from '../../../constants';
import { getFont } from '../../../../../../PostSalesStyles';
import { useTheme } from '../../../../../../theme/theme.context';
import { getTotalPriceText } from '../../../utils';
const infoRed = require('@mmt/legacy-assets/src/info_red.webp');

const FlightListItem = (props) => {
  const fonts = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);
  const getTotalPrice=(listItem, currency) => {
    const flightItemText = getTotalPriceText(listItem, currency);
  return (<Text style={styles.price}>
    {flightItemText}
    </Text>);
  }
  const renderListItemLeg = (legs) => {
    const legView = [];
    legs.forEach((leg) => {
      legView.push(
        <View style={styles.listItemWrapper}>
          <View style={styles.listItem}>
            <View style={styles.timeDestWrapperIF}>
              <Text style={styles.timeDestIFTxtTop}>{leg.depTime}</Text>
              <Text style={styles.timeDestIFTxtBottom}>{leg.from}</Text>
            </View>
            <View style={styles.stopsWrapper}>
              <View style={styles.stopView}>
                <View style={[styles.greenDisc]} />
                <View style={[styles.greenDisc, styles.left95Per]} />
                <View style={styles.stopSeperator} />
              </View>
              <Text style={styles.stopsText}>{getNoOfStopsText(leg.noOfStop)}</Text>
            </View>
            <View style={[styles.timeDestWrapperIF]}>
              <Text style={styles.timeDestIFTxtTop}>{leg.arrTime}</Text>
              <Text style={styles.timeDestIFTxtBottom}>{leg.to}</Text>
            </View>
            <View style={styles.totalDurationWrapper}>
              <Text style={styles.totalDuration}>{getDurationText(leg.dur)}</Text>
            </View>
          </View>
          {!isEmpty(leg.transitVisaMsg) &&
            <View style={styles.transitVisaWrapper}>
              <Text style={styles.transitVisaBullet}>•</Text>
              <Text style={styles.transitVisaText}>
                {leg.transitVisaMsg}
              </Text>
            </View>}
        </View>
        ,
      );
    });
    return legView;
  };
  const listItem = props.listItemResponse;
  const currency = props.currency || '₹';
  return (
    <TouchableOpacity
      style={styles.listItemFlight}
      onPress={() => {
        props.onItemSelect(listItem);
      }}
    >
      <View style={{ flex: 1 }}>
        <View style={styles.listingFltPriceDtls}>
          <View style={styles.leftSide}>
            <View style={styles.airlineIconWrapper}>
              <Image
                style={styles.airlineIcon}
                source={{ uri: getAirlineImage(listItem.legs[0].airline) }}
              />
              <View>
                <Text style={styles.airlineName}>{listItem.legs[0].airlineName}</Text>
                {!isEmpty(listItem.legs[0].opAirline) && (
                  <Text style={styles.operatedByAirlineName}>{listItem.legs[0].opAirline}</Text>
                )}
              </View>
            </View>
          </View>
          <View style={styles.priceWrapper}>
            {props.isCorporateBooking && (
              <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
                {listItem.outOfPolicy && (
                  <React.Fragment>
                    <Text style={styles.outOfPolicyTagTxt}>{OUT_OF_POLICY}</Text>
                    <Image source={infoRed} style={styles.infoRedIcon} />
                  </React.Fragment>
                )}
              </View>
            )}
            {getTotalPrice(listItem, currency)}
          </View>
        </View>

        {renderListItemLeg(listItem.legs)}
      </View>
    </TouchableOpacity>
  );
};

FlightListItem.propTypes = {
  listItemResponse: PropTypes.object.isRequired,
  onItemSelect: PropTypes.func.isRequired,
  isCorporateBooking: PropTypes.bool.isRequired,
  bookingType: PropTypes.string.isRequired,
};

export default FlightListItem;
