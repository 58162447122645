import React from 'react';
import {View, Text, TouchableOpacity, Image} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import styles from './RefundModeCss';
import AtomicCss from '../../commonStyles/AtomicCss';
import {isNonEmpty} from '@mmt/legacy-commons/Common/utils/StringUtils';
import {getInstantRefundGradient} from '../../../Common/commonUtil';
import {REFUND_ICON} from '../../Utils/RailImageConstant';
import {isEmpty} from 'lodash';
import { getStaticData } from '../../../staticData/staticData';
import { getFont } from '../../../PostSalesStyles';

class RefundModeList extends React.Component {
  static navigationOptions = {
    header: null
  }

  constructor(props) {
    super(props);
    this.state = {
      radiobtn: {refundModeReason: 'RefundToCustomer'}
    };
    this.radio = this.radio.bind(this);
    this.refundModeList = this.props.previewObj.cancellationRefundMode.refundModeList;
    this.instantRefund = false;
    this.message = this.props.previewObj.cancellationRefundMode.message;
  }

  radio(active) {
    this.props.changeSelectedRefundMode(active);
    this.setState({
      radiobtn: active
    });
  }

  render() {
    const { fsStyle, ...fonts } = getFont(true);
    const staticData = getStaticData();
    const { trainCancellationText, selectRefundModeText, instantRefundText } = staticData;
    return (
      <View style={[AtomicCss.greyBg, AtomicCss.flex1]}>
        <View style={[AtomicCss.whiteBg, AtomicCss.marginBottom10, AtomicCss.elevation1]}>
          <View
              style={[
                styles.padding16,
                styles.borderbtmLight,
                styles.flexRow
              ]}
          >
            <Image
                source={REFUND_ICON}
                style={[styles.refundIcon, styles.mar10Rt]}
            />
            <Text
                style={[fonts.blackFontFamily, AtomicCss.blackText, fsStyle.font16]}
            >
              { selectRefundModeText }
            </Text>
          </View>

          {!isEmpty(this.message) && <View style={[styles.refundModeMessageCard]}>
            <Text style={[styles.BlackText, fonts.blackFontFamily]}>{this.message}
            </Text>
          </View>}
          <View style={[styles.ActionBtn, AtomicCss.marginTop15]}>
            {this.populateRefundModes(this.refundModeList,  instantRefundText)}
          </View>
        </View>
      </View>
    );
  }

  addRefundModedata = data => data.map((refundModeText, index) => (
    <View>
      <Text style={this.getTextStyle(index)}>{refundModeText}</Text>
    </View>
  ));

  getTextStyle = (index) => {
    const { fsStyle, ...fonts } = getFont(true);
    const cssStyles = [fsStyle.font12, styles.refundText, fonts.regularFontFamily];
    if (index % 2 === 0) cssStyles.push(AtomicCss.blackText);

    return cssStyles;
  }

  getStyle = (refundModeData, i) => {
    const cssStyles = [];
    if (i + 1 !== this.refundModeList.length) {
      cssStyles.push(styles.borderbtm);
      cssStyles.push(AtomicCss.marginBottom10);
    }

    if (this.isMyWalletRefundMode(refundModeData.title)) {
      cssStyles.push(styles.hideView);
    } else {
      cssStyles.push(styles.showView);
    }

    return cssStyles;
  }

  isMyWalletRefundMode = refundModeTitle => refundModeTitle.includes('Wallet') || refundModeTitle.includes('wallet')

  populateRefundModes = (data, instantRefundText) => data.map((refundModeData, i) => {
    const { fsStyle, ...fonts } = getFont(true);
    const totalRefundAmount = this.props.previewObj?.ticketCancellationPreview?.refundBreakup?.totalRefund?.amount;
    let refundAmount  = "";
    if(totalRefundAmount){
      refundAmount = Math.round(totalRefundAmount*100)/100;
    }else if(this.props.previewObj?.refundAmount){
      refundAmount = Math.round(this.props.previewObj.refundAmount * 100)/100;
    }

    return (
      <TouchableOpacity
        style={this.getStyle(refundModeData, i)}
        onPress={() => {
          this.instantRefund = isNonEmpty(refundModeData.accountNo);
          this.radio(refundModeData);
        }}
      >
        <View style={styles.refundCardOuter}>
          <View style={[styles.refundCardWrap, AtomicCss.marginBottom10]}>
            <View style={[AtomicCss.flexRow, AtomicCss.flex1]}>
              <View style={styles.MimaRadio}>
                {!isNonEmpty(refundModeData.accountNo) &&
                  <View style={[this.state.radiobtn.refundModeReason === refundModeData.refundModeReason
                  && !this.instantRefund ? styles.MimaRadioInside : '']}
                  /> }
                { isNonEmpty(refundModeData.accountNo) &&
                <View style={[this.state.radiobtn.accountNo === refundModeData.accountNo
                && this.instantRefund ? styles.MimaRadioInside : '']}
                />}
              </View>
              <View style={styles.refundCardLeft}>
                <Text style={[AtomicCss.defaultTextColor, fsStyle.font14, fonts.boldFontFamily]}>
                  {refundModeData.title}
                </Text>
  
              </View>
            </View>
            <Text
              style={[AtomicCss.defaultTextColor, fonts.boldFontFamily, fsStyle.font16]}
            >&#8377; {refundAmount}{' '}
            </Text>
          </View>
          {refundModeData.subText.length > 0 && this.addRefundModedata(refundModeData.subText)}
          {isNonEmpty(refundModeData.accountNo) && <View style={{paddingLeft: 32, paddingTop: 10}}>
            <LinearGradient
              start={{x: 1.0, y: 0.0}}
              end={{x: 0.0, y: 1.0}}
              colors={getInstantRefundGradient()}
              style={styles.instantRefundTag}
            >
              <Text style={[styles.instantRefundTagTxxt, fonts.boldFontFamily, fsStyle.font10]}>{instantRefundText}</Text>
            </LinearGradient>
          </View> }
        </View>
      </TouchableOpacity>
    )
  });
}

RefundModeList.defaultProps = {
  instantRefund: null
};

export default RefundModeList;
