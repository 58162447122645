import initialState from './initialState';
import specialClaimActionTypes from './specialClaimActionTypes';
import { handleLoadPaxState, handleTogglePax, handleToggleSegment} from "./dataAdapter"


const specialClaimReducer = (state = initialState, action) => {
  switch (action.type) {
    case specialClaimActionTypes.LOAD_PAX: {
      const { paxDetails, isAnySelected, isAnyNonProofSegment } =
        handleLoadPaxState(action);
      return { ...state, paxDetails, isAnySelected, isAnyNonProofSegment };
    }

    case specialClaimActionTypes.TOGGLE_PAX: {
      const {
        paxDetails,
        isAnySelected,
        isAnyNonProofSegment,
        proofSegments,
        areOnlyChildPaxSelected,
      } = handleTogglePax(state, action);

      return {
        ...state,
        paxDetails,
        isAnySelected,
        isAnyNonProofSegment,
        proofSegments,
        areOnlyChildPaxSelected,
      };
    }

    case specialClaimActionTypes.TOGGLE_SEGMENT: {
      const {
        paxDetails,
        isAnySelected,
        isAnyNonProofSegment,
        proofSegments,
        areOnlyChildPaxSelected,
      } = handleToggleSegment(state, action);

      return {
        ...state,
        paxDetails,
        isAnySelected,
        isAnyNonProofSegment,
        proofSegments,
        areOnlyChildPaxSelected,
      };
    }
    case specialClaimActionTypes.UPLOAD_DOC: {
      let newArr = [];
      const arr = state.documentList[action.segmentId];
      if (arr) {
        newArr = [...arr, action.data];
      } else {
        newArr = [action.data];
      }
      return { ...state, documentList: { ...state.documentList, [action.segmentId]: newArr } };
    }
    case specialClaimActionTypes.UPLOAD_DOC_STATUS: {
      const newArr = action.segmentId && state.documentList && state.documentList[action.segmentId] && 
        Array.isArray(state.documentList[action.segmentId]) && state.documentList[action.segmentId].map((doc) =>
        doc.filePath === action.filePath
          ? { ...doc, status: action.status }
          : doc
      );
      return {
        ...state,
        documentList: { ...state.documentList, [action.segmentId]: newArr || [] },
      };
    }
    case specialClaimActionTypes.REMOVE_DOC: {
      let newState = { ...state };
      if (state.documentList[action.segmentId].length == 1) {
        delete newState.documentList[action.segmentId];
      } else {
        newState.documentList[action.segmentId] = state.documentList[
          action.segmentId
        ].filter((file) => file.filePath != action.filePath);
      }
      return newState;
    }
    case specialClaimActionTypes.SET_REFUND_MODE: {
      return { ...state, refundMode: action.refundMode };
    }
    case specialClaimActionTypes.TOGGLE_CONFIRM: {
      return { ...state, isConfirmed: !state.isConfirmed };
    }
    case specialClaimActionTypes.UPLOAD_DOC_CLOUD:
      return { ...state, fileUrls: [...state.fileUrls, action.data]  };
      case specialClaimActionTypes.LOAD_ANCILLARY:
        return { ...state, ancillaryCancel: action.data, isAnySelected:true }
    default:
      return { ...state };
  }
};

export default specialClaimReducer;
