import myTripsNavigation from '../../../../navigation/navigation';
import { titleCase } from '@mmt/legacy-commons/Helpers/stringHelpers';
import { getMyRequestCardData, handleCTAClick } from '../../../utils';
import { MsrTicket, VisaData, VisaSummaryDataProps } from '../cardInterfaces';
import { timerIcon } from 'apps/post-sales/src/Visa/Constant/VisaImageConstant';

const getVisaSummaryData = (data: VisaData, msrTicket: MsrTicket): VisaSummaryDataProps => {
  const visaDetails = data && data.visaDetails && data.visaDetails[0];
  const visaCommonFieldStatus = data && data.visaCommonFields && data.visaCommonFields.status;
  const pageData = {
    fromMyTrips: true,
  };
  const openDetails = () => {
    myTripsNavigation.openVisaBookingDetail(data, pageData);
  };
  const actions: Array<{ label: string; onPress: () => void }> = [];
  const startsIn = data.startsIn;
  const lobName = data.lobType[0];
  const bookingId = data.bookingID;

  let paxInfo =
    visaDetails.totalPax > 1
      ? `${data.leadPaxFirstName} + ${visaDetails.totalPax - 1} `
      : `${titleCase(data.leadPaxFirstName)}`;
  const { ctaInfoList: ctaDetails, infoCard } = data && data.bookingStateLayout || {};
  const { headerText, visadescription, flagUrl, status, validityRange, validityText } = visaDetails || {};
  const { text, ctaInfo } = infoCard || {};
  const infoCardStatusText = text;
  const infoCardStatusCTA = ctaInfo && ctaInfo.text;

  if (ctaDetails && ctaDetails[0]) {
    actions.push({ label: ctaDetails[0]?.text,  onPress: () => handleCTAClick(ctaDetails[0], data) })
  };
  if (ctaDetails && ctaDetails[1]) {
    actions.push({ label: ctaDetails[1]?.text, onPress: () => handleCTAClick(ctaDetails[1], data) })
  };

  const handleInfoClick = () => {
    if (infoCard) {
      handleCTAClick(infoCard.ctaInfo, data);
    }
  };

  return {
    iconUrl: timerIcon,
    countryName: headerText,
    description: visadescription,
    flagUrl: flagUrl,
    visaStatus: visaCommonFieldStatus,
    validityText,
    validity: validityRange,
    myRequestsCard: getMyRequestCardData(msrTicket),
    startsIn,
    lobName,
    bookingId,
    paxInfo,
    actions,
    infoCardStatusText,
    infoCardStatusCTA,
    openDetails,
    handleInfoPress: handleInfoClick
  };
}

export default getVisaSummaryData;