import { getLobName, openDateChange, openPolicyPage } from '../../../details/utils/cardUtil';
import { getStaticData } from 'apps/post-sales/src/staticData/staticData';
import { ViewState } from 'apps/post-sales/src/PostSalesConstant';
import FlightBookingModule from '@mmt/legacy-commons/Native/FlightBookingModule';
import Actions from 'apps/post-sales/src/navigation/postSalesNavigation';
import { Platform, Linking } from 'react-native';
import isEmpty from 'lodash/isEmpty';
import {
  getPrimaryContactNo,
} from 'apps/post-sales/src/flights/FlightBookingUtil';
import { showShortToast } from '@mmt/legacy-commons/Common/Components/Toast';
import {
  CANCELLATION_REASONSV2,
} from '../constants';
import {FLIGHT_ACTION_IDS} from 'apps/post-sales/src/flights/v2/commonConstants';
import { openDialer, openLink } from 'apps/post-sales/src/Common/commonUtil';
import { fetchWalletDetail } from '../../../details/data/api';
import { openWebView } from '../../../details/components/FlightMSRCard/Utils';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import { downloadETickets, downloadBoardingPass } from '../helper/downloadTicketsAndPass';
import FlightBookingDetailsConstant from 'apps/post-sales/src/flights/modules/details/FlightBookingDetailsConstant';
import { EVENT_NAME_ACTION_IDS_MAPPING } from 'apps/post-sales/src/flights/v2/utils/trackingUtil';
import { isPhoneNumUri } from 'apps/post-sales/src/utils/PostSaleUtil';
import { BACK_WHITE_ICON } from 'apps/post-sales/src/flights/FlightImageConstants';
import { UPDATE_REFUND_STATUS } from 'apps/post-sales/src/utils/NetworkUtils';
import myTripsNavigation from 'apps/post-sales/src/summary/navigation/navigation';
import { getRootTag } from '@mmt/legacy-commons/AppState/RootTagHolder';
import { FLIGHT_BOOKING_ROUTE_KEYS } from 'apps/post-sales/src/flights/flightBookingRouteKeys';
import { addonsHandler, resetToFlightBookingDetail, fetchFlightAncillary, commonPaymentHandler } from '@mmt/post-sales/src/flights/v2/utils/commonUtils';

const cancellationPolicyHandler = (responseData) => {
  const staticData = getStaticData();
  const btn = {};
  btn.text = staticData.calculateRefundText;
  btn.btnAction = 'CANCEL_FLT_FULL';
  openPolicyPage(
    staticData.cancellationPolicyText,
    responseData?.farePenaltyList?.Cancellation,
    true,
    btn,
    responseData,
  );
};

const resChargesHandler = (responseData) => {
  const { continueText, dateChangePolicyText } = getStaticData();
  const btn = {};
  btn.text = continueText;
  btn.btnAction = 'ACT_RES_FLIGHT';
  openPolicyPage(
    dateChangePolicyText,
    responseData?.farePenaltyList?.DateChange,
    responseData?.isFreeDateChangeAssured ? true : false,
    btn,
    responseData,
  );
};

const chatBotActionHandler = (lobData, flightResponse) => {
  const {nav} = lobData || {};
  const {pageId, autoOpenOverlay, refresh, props} = nav && nav.length > 0 && nav[0] || {};
  if (pageId == FLIGHT_BOOKING_ROUTE_KEYS.FLIGHT_RESCHEDULE_FLIGHTS_PAGE) {
    openDateChange(
      undefined,
      flightResponse,
      getLobName(),
      nav,
      autoOpenOverlay
    );
  }
}

const googleWalletHandler = async (fetchFlightBookingDetails, bookingId) => {
  if (Platform.OS === 'android') {
    const responseJson = await fetchFlightBookingDetails();
    const { newFlightDetails, accountDetail } = responseJson;
    const { segmentGroupDetailList } = newFlightDetails;
    const { segmentDetails } = !isEmpty(segmentGroupDetailList) ? segmentGroupDetailList[0] : {};
    const { airLinePNRNo, airLineCode, flightDesignator } = segmentDetails[0];
    const { flightNumber } = flightDesignator || {};
    const { contactDetails } = accountDetail || {};
    const contactNumber = getPrimaryContactNo(contactDetails);
    const params = {
      bookingId: bookingId,
      contactNumber,
      pnr: airLinePNRNo,
      flightId: `${airLineCode}${flightNumber}`,
    };
    try {
      const data = await fetchWalletDetail(params);
      const { googlePassUrl } = data || {};
      if (googlePassUrl) {
        openLink(googlePassUrl);
        return false;
      } else {
        return true;
      }
    } catch (e) {
      console.log('error', e);
      return true;
    }
  }
};

const appendPlatformInfo = (url, userInfo) => {
  const { loggingTrackingInfo } = userInfo || {};
  const { appVersion } = loggingTrackingInfo ? JSON.parse(loggingTrackingInfo) : {};
  const platformParam = `trackPlatform=${Platform.OS}app`;
  const appVersionParam = `appVersion=${appVersion}`;
  url += `${url.indexOf('?') === -1 ? '?' : '&'}${platformParam}&${appVersionParam}`;
  return url;
};

const updateURL = (url, userInfo) => {
  url = appendPlatformInfo(url, userInfo);
  return url;
};

const linkPressHandler = async (url, bookingId, isExternalUrl = false) => {
  if (isPhoneNumUri(url) || isExternalUrl) {
    Linking.openURL(url);
    return;
  }
  const { informationText } = getStaticData();
  const userInfo = await FlightBookingModule.getRequestHeader(
    bookingId,
    'IMPORTANT_INFO_LINK_CLICK',
  );
  Actions.openPsWebView({
    headerText: informationText,
    headerIcon: BACK_WHITE_ICON,
    url: updateURL(url, userInfo),
  });
};

const {
  FULL_CANCELLATION,
  CANCEL_HOLD_BOOKING,
  PARTIAL_CANCELLATION,
  CANCELATION_POLICY,
  CHANGE_DATE,
  ACT_RES_FLIGHT,
  ACT_RES_CHARGES,
  NAME_CHANGE,
  FLT_CANCELLED_WITH_AIRLINE,
  FLT_CANCELLED,
  MEDICAL_EMERGENCY,
  FLT_RESCHEDULED,
  ADD_MEAL,
  ADD_SEAT,
  ADD_BAGGAGE,
  IMPT_INFO_LINK,
  TRACK_ISSUE,
  DOWNLOAD_INVOICE,
  BOOKING_INVOICE,
  BACK,
  OPEN_DETAILED_PAGE,
  ADD_TO_GOOGLE_WALLET,
  ADD_TO_PASSBOOK,
  OPEN_VISA_TNC_PAGE,
  OPEN_VISA_CLAIM_REFUND,
  TRACK_REFUND,
  WHEEL_CHAIR,
  WEB_CHECKIN,
  LOAD_OLD_FLOW,
  REFRESH_PAGE,
  LOAD_TRACKING,
  CLICK_TRACKING,
  ERROR_TRACKING,
  TRAVEL_INSURANCE_BUY,
  PART_PAYMENT_COMPLETE,
  ERROR,
  HOLD_BOOKING_TNC,
  DOWNLOAD_ETICKET,
  BOARDING_PASS,
  SHOW_SHORT_TOAST,
  VIEW_VISA_DETAILS,
  UPDATE_CAB_DETAILS,
  BOOK_ANOTHER_FLIGHT,
  VIEW_CAB_DETAILS,
  SEARCH_MORE_FLIGHT,
  PRICE_LOCK_DEEPLINK,
  LOUNGE_ACCESS_QR,
  FOOD_BEVERAGE_QR,
  TRANSFER_REFUND,
  VIEW_REFUND_BREAKUP,
  VIEW_REFUND_DELAYED,
  REFUND_OUT_OF_TAT_FORM,
  VPG_CLAIM_REFUND,
  VPG_RESUBMIT_CLAIM,
  KNOW_MORE_ABOUT_POLICY,
  FILE_INSURANCE_CLAIM,
  READ_POLICY_TNC,
  VIEW_PROTECTION_DETAILS,
  TM_INSURANCE_ROUTE,
  CROSS_SELL,
  CHAT_BOT_EVENT,
  CALL_NOW_CLICK,
  COD_TNC,
  INSURANCE_PRODUCT_VIEW_TNC,
  FAST_FORWARD_BUY,
  AIRPORT_SERVICE_BUY,
  PROVIDE_BANK_DETAILS,
} = FLIGHT_ACTION_IDS;

const {errorText = {}} = getStaticData();

export const actionHandler = async ({
  actionId,
  actionData,
  onLoad,
  onClick,
  onError,
  bookingId,
  setWheelChairFlow,
  setWheelChairData,
  checkPermissionRef,
  setBookingInvoice,
  setWalletOverlay,
  setErrorData,
  loadOldFlow,
  setViewState,
  cancelCardActionItemClick,
  cancelHoldBooking,
  dateChangeCardActionClick,
  specialRequestCardBtnClick,
  openSpecialClaimPopUp,
  setResponseJson,
  fetchFlightBookingDetails,
  setApiLoading,
  responseJson,
  setAncillaryReponse,
  handleClick,
}) => {
  if(!!actionData?.responseData){
    if(Object.keys(actionData?.responseData).length === 0) {
      showShortToast(errorText?.somethingWentWrongTryAgainError);
    } else {
      setResponseJson(actionData.responseData);
    }
  }
  switch (actionId) {
    case FULL_CANCELLATION:
    case PARTIAL_CANCELLATION:
      cancelCardActionItemClick({ actionId }, 'Details', false, true, actionData?.responseData);
      break;
    case CANCEL_HOLD_BOOKING: {
      cancelHoldBooking();
      break;
    }
    case CANCELATION_POLICY:
      cancellationPolicyHandler(actionData?.responseData);
      break;
    case CHANGE_DATE:
    case ACT_RES_FLIGHT:
      dateChangeCardActionClick(ACT_RES_FLIGHT, 'Details', false, actionData?.responseData);
      break;
    case ACT_RES_CHARGES:
      resChargesHandler(actionData?.responseData);
      break;
    case NAME_CHANGE:
      specialRequestCardBtnClick(actionId, actionData);
      break;
    case FLT_CANCELLED_WITH_AIRLINE:
    case FLT_CANCELLED:
    case MEDICAL_EMERGENCY:
    case FLT_RESCHEDULED: {
      if (!responseJson || Object.keys(responseJson).length === 0) {
        setApiLoading(true);
        const flightResponse = await fetchFlightBookingDetails();
        setApiLoading(false);
        setResponseJson(flightResponse);
      }
      openSpecialClaimPopUp({
        actionId: CANCELLATION_REASONSV2[actionId],
        backPress: actionData?.dispatchPopup,
      });
      break;
    }
    case IMPT_INFO_LINK:
      linkPressHandler(actionData?.url, bookingId, actionData?.isExternalUrl);
      break;
    case CROSS_SELL: {
        Actions.flightSellingPage({
          bookingId: bookingId,
        });
        break;
      }
    case TRACK_REFUND:
      Actions.refundTrackerFlights({
        bookingId: bookingId,
        updateRefundReceivedStatusUrl: UPDATE_REFUND_STATUS,
      });
      break;
    case VIEW_REFUND_BREAKUP: {
      Actions.refundBreakupV2(actionData);
      break;
    }
    case VIEW_REFUND_DELAYED: {
      Actions.refundDelayInfo({...actionData, pageName: 'RefundOOT:DelayInfo'});
      break;
    }
    case REFUND_OUT_OF_TAT_FORM: {
      // This case is only for lostBookingId
      Actions.refundOutOfTATForm(actionData);
      break;
    }
    case PROVIDE_BANK_DETAILS: {
      Actions.openPsWebView({
        headerText: actionData?.title,
        headerIcon: '',
        url: actionData?.actionUrl || actionData?.deepLink,
      });
      break;
    }
    case TRANSFER_REFUND:
    case VPG_RESUBMIT_CLAIM:
    case VPG_CLAIM_REFUND:
    case OPEN_VISA_CLAIM_REFUND:
    case OPEN_VISA_TNC_PAGE:
    case SEARCH_MORE_FLIGHT:
    case VIEW_VISA_DETAILS:
    case VIEW_CAB_DETAILS:
    case UPDATE_CAB_DETAILS:
    case PRICE_LOCK_DEEPLINK:
    case LOUNGE_ACCESS_QR:
    case FOOD_BEVERAGE_QR:
    case BOOK_ANOTHER_FLIGHT: {
      if (actionData?.isExternalUrl) {
        openExternalUrl(actionData.actionUrl || actionData.deepLink);
      } else {
        GenericModule.openDeepLink(actionData?.actionUrl || actionData?.deepLink);
      }
      break;
    }
    case BACK: {
      Actions.pop();
      break;
    }
    case OPEN_DETAILED_PAGE: {
      Actions.flightBookingDetailedInfo({
        bookingId,
      });
      break;
    }
    case WEB_CHECKIN: {
      setApiLoading(true);
      const flightResponse = await fetchFlightBookingDetails();
      setApiLoading(false);
      if (flightResponse && !flightResponse.error) {
        Actions.openAncillary({
          pageKey: 'webcheckin',
          bookingId,
          flightDetailResponse: flightResponse,
        });
      }
      break;
    }
    case ADD_MEAL:
    case ADD_BAGGAGE:
    case ADD_SEAT: {
      addonsHandler({
        bookingId,
        actionId,
        flightDetailResponse: actionData?.responseData,
        segmentGroupId: actionData?.segmentId,
      });
      break;
    }
    case WHEEL_CHAIR: {
      setApiLoading(true);
      const ancillaryReponse = await fetchFlightAncillary(bookingId);
      setApiLoading(false);
      if (ancillaryReponse && !ancillaryReponse.error) {
        setAncillaryReponse(ancillaryReponse)
        setWheelChairData({
          segmentId: actionData?.segmentId,
        });
        setWheelChairFlow(true);
      }
      break;
    }
    case TM_INSURANCE_ROUTE:
      const tmOrderId = actionData?.tmOrderId;
      const normalizeBookingId = tmOrderId ? bookingId + `?tmBookingId=${tmOrderId}` : tmOrderId;
      myTripsNavigation.openInsuranceBookingDetail({ bookingID:normalizeBookingId }, {}, false);
      break;
    case VIEW_PROTECTION_DETAILS:
      Actions.tripProtectionPolicy({
        insuranceDetails: actionData?.insuranceDetails,
        title: actionData?.heading,
        bookingId,
      });
      break;
    case READ_POLICY_TNC:
    case FILE_INSURANCE_CLAIM:
    case KNOW_MORE_ABOUT_POLICY:
    case TRACK_ISSUE:
      openWebView(actionData?.actionUrl, actionData?.heading);
      break;
    case DOWNLOAD_INVOICE: {
      if (Platform.OS !== 'android') {
        setBookingInvoice(true);
      } else {
        checkPermissionRef.current = FLIGHT_ACTION_IDS.DOWNLOAD_INVOICE_POPUP;
        FlightBookingModule.checkStoragePermission();
      }
      break;
    }
    case LOAD_OLD_FLOW: {
      loadOldFlow();
      break;
    }
    case REFRESH_PAGE: {
      resetToFlightBookingDetail(bookingId);
      break;
    }
    case LOAD_TRACKING: {
      onLoad(actionData);
      break;
    }
    case CLICK_TRACKING: {
      onClick(actionData?.trackingEventName, actionData?.trackingExtraInfo);
      break;
    }
    case ERROR_TRACKING: {
      onError(actionData?.trackingError);
      break;
    }
    case ADD_TO_GOOGLE_WALLET: {
      setWalletOverlay(true);
      googleWalletHandler(fetchFlightBookingDetails, bookingId).then((curretState) =>
        setWalletOverlay(curretState),
      );
      break;
    }
    case ADD_TO_PASSBOOK: {
      setApiLoading(true);
      fetchFlightBookingDetails()
        .then((res) => {
          setApiLoading(false);
          res.reactTag = getRootTag();
          FlightBookingModule.downloadTicket(JSON.stringify(res), 'Flight_CustomerETicket');
        })
        .catch((err) => {
          setApiLoading(false);
          console.log(err);
        });
      break;
    }
    case ERROR: {
      setViewState(ViewState.ERROR);
      setErrorData(actionData);
      break;
    }
    case DOWNLOAD_ETICKET: {
      downloadETickets(bookingId);
      checkPermissionRef.current = FlightBookingDetailsConstant.FLIGHT_ETICKET;
      break;
    }
    case BOARDING_PASS:
      downloadBoardingPass(actionData, bookingId);
      break;
    case TRAVEL_INSURANCE_BUY:
    case PART_PAYMENT_COMPLETE:
    case FAST_FORWARD_BUY: 
    case AIRPORT_SERVICE_BUY: {
      commonPaymentHandler(actionData);
      break;
    }
    case HOLD_BOOKING_TNC:
      Actions.openPsWebView({
        headerText: actionData?.tncTitle,
        headerIcon: '',
        url: actionData?.links?.webLink,
      });
      break;
    case SHOW_SHORT_TOAST:
      showShortToast(actionData?.message || errorText?.somethingWentWrongTryAgainError);
      break;
    case CHAT_BOT_EVENT:
      let flightResponse = responseJson || {};
      if (!responseJson || Object.keys(responseJson).length === 0) {
        flightResponse = await fetchFlightBookingDetails();
        setResponseJson(flightResponse);
      }
      chatBotActionHandler(actionData, flightResponse);
      break;
    
    case CALL_NOW_CLICK: {
      const { contactNumber } = actionData || {};
      !!contactNumber && openDialer(contactNumber);
      break;
    }
    case COD_TNC:
    case INSURANCE_PRODUCT_VIEW_TNC: {
      Actions.openPsWebView({
        headerText: '',
        headerIcon: '',
        url: actionData?.url,
      });
      break;
    }
    default:
      break;
  }
  const clickEventName = EVENT_NAME_ACTION_IDS_MAPPING[actionId];
  if (clickEventName) {
    onClick(clickEventName, actionData?.trackingExtraInfo);
  }
};
