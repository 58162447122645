import React, { useState } from 'react';
import { View, Text, TouchableOpacity, Image } from 'react-native';
import BookingInfoDetails from '../../../bookingDetails/components/BookingInfoDetails';
import CabBookingDetailsConstant, { CLICK_EVENT, PAGE_NAME } from '../../../../CabBookingDetailsConstant';
import { useTheme } from '../../../../../theme/theme.context';
import { getFont } from '../../../../../PostSalesStyles';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import isEmpty from 'lodash/isEmpty';
import CabDetails from '../../../bookingDetails/components/CabDetails';
import getStyle from './styles';
import { JourneyDetailCardProps } from 'apps/post-sales/src/cab/types';
import CabBookingTrackingHelper from 'apps/post-sales/src/cab/CabBookingTrackingHelper';

const JourneyDetailCard: React.FC<JourneyDetailCardProps> = ({
  carBookingInfo,
  cardData,
  dateTime,
  cabDetailInfo,
  bookingId,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [viewDetailPress, setViewDetailPress] = useState(false);
  const { pickupTimeText, rescheduleRequestedData } = cardData || {};
  const { psTheme } = useTheme();
  const { fsStyle, ...fonts } = getFont();
  const styles = getStyle(fonts, psTheme);
  const { viewDetailText, bookingIdText } = CabBookingDetailsConstant;

  const toggleJourneySection = () => {
    setIsExpanded(!isExpanded);
  };

  const clickViewDetails = () => {
    CabBookingTrackingHelper.trackClickEvent(PAGE_NAME.CAB_RESCHEDULE_PAGE, CLICK_EVENT.CAB_RESCHEDULE_VIEW_DETAILS_CLICK);
    setViewDetailPress(true);
  };

  const getPickUpData = () => (
    <View style={[styles.pickupTimeWrap, { marginTop: isExpanded ? 0 : -10 }]}>
      <View style={AtomicCss.marginRight5}>
        {!!pickupTimeText && <Text style={styles.pickupTimeText}>{pickupTimeText}</Text>}
        {!!dateTime && <Text style={styles.dateTime}>{dateTime}</Text>}
      </View>
      {!isEmpty(rescheduleRequestedData) && !!rescheduleRequestedData.text && (
        <View style={AtomicCss.alignSelfFlexEnd}>
          <View
            style={[
              styles.tagWrapper,
              { borderColor: rescheduleRequestedData.color || psTheme.color.purple3 },
            ]}
          >
            {!!rescheduleRequestedData.imageUrl && (
              <Image
                style={styles.assuranceImg}
                source={{ uri: rescheduleRequestedData.imageUrl }}
              />
            )}
            {!!rescheduleRequestedData.text && (
              <Text
                style={[
                  styles.rescheduleText,
                  { color: rescheduleRequestedData.color || psTheme.color.purple3 },
                ]}
              >
                {rescheduleRequestedData.text}
              </Text>
            )}
          </View>
        </View>
      )}
    </View>
  );

  return (
    <View style={styles.container}>
      <BookingInfoDetails
        pageName={PAGE_NAME.CAB_RESCHEDULE_PAGE}
        carBookingInfo={carBookingInfo}
        isExpanded={isExpanded}
        expandCarDetail={toggleJourneySection}
      />
      {getPickUpData()}
      <View style={styles.middleSection}>
        {!!bookingId && (
          <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
            <Text style={styles.bookingIdText}>{bookingIdText} : </Text>
            <Text style={styles.bookingId}>{bookingId}</Text>
          </View>
        )}

        {!viewDetailPress && (
          <TouchableOpacity style={AtomicCss.alignItemEnd} onPress={clickViewDetails}>
            <Text style={styles.viewDetailsText}>{viewDetailText}</Text>
          </TouchableOpacity>
        )}
      </View>
      {!!viewDetailPress && <CabDetails cabDetailInfo={cabDetailInfo} />}
    </View>
  );
};

export default JourneyDetailCard;
