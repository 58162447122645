import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import {ScrollView, View, Animated, Easing, Keyboard, DeviceEventEmitter, Alert, Platform, Text} from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import Header from '../details/cards/Header';
import createStyles from './CancellationCss';
import HotelDetailCard from './components/HotelDetailCard';
import { getCancellationSteps, getHotelDetailCardData , getHotelModalCardData, getTCSPriceBreakup} from './CancellationUtil';
import PolicyDetailCard from './components/PolicyDetailCard';
import StepBar from '../../Common/StepBar';
import RefundApplicableCard from './components/RefundBreakupCard';
import SelectRefundPaymentMode from './components/SelectRefundMode';
import SelectReasonCard from './components/SelectReasonCard';
import CommonOverlayMessage from '../../Common/CommonOverlayMessage';
import CommonBottomOverlayMessage from '../../Common/CommonOverlayMessage/CommonBottomOverlayMessage';
import CancelledSuccess from './components/CancelledSuccess';
import CancelLoader from '../../Common/LoaderOverlay';
import ConfirmToCancel from './components/ConfirmToCancel';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import SimpleHeader from '@mmt/legacy-commons/Common/Components/Header/SimpleHeader';
import useApi from '../../Common/useApi';
import { HOTEL_BOOKING_CANCELLATION_URL } from '../../utils/NetworkUtils';
import ErrorPage from '../details/pages/ErrorPage';
import NoInternetView from '@mmt/legacy-commons/Common/Components/Error/NoInternetView';
import ProgressView from '@mmt/legacy-commons/Common/Components/Progress/ProgressView';
import { getCardAndActionFromAllCards, getHotelCancellationRequest, getHotelName, handleClickEvent, isBNPL, isIntlHotelBooking, isNonRefundable, isNotNullAndEmpty, isPAH, resetToHotelDetail, shouldBlockOnlineModification } from '../details/utils/HotelBookingDetailUtil';
import HotelBookingTrackingHelper from '../HotelBookingTrackingHelper';
import Actions from '../../navigation/postSalesNavigation';
import { BOTTOM_OVERLAY_STATES, CANCELLATION_ACTION, CANCELLATION_REASON, CANCELLATION_STEPS, MAX_STEPS_IN_CANCELLATION, REFUND_TO_CUSTOMER } from './HotelCancellationConstants';
import { getHelpingHandData, renderNeedHelpOverlay, trackHelpingHandEvent } from '../details/utils/HelpingHandHelper';
import { CancellationPreviewRequest, EMPTY_STRING, InstantRefundReloadEvent, INSTANT_REFUND_OTP_FAILED, INSTANT_REFUND_OTP_REQUESTED_AGAIN, INSTANT_REFUND_OTP_SUCCESS, INSTANT_REFUND_REQUESTED, KEYBOARD_HIDE_EVENT, KEYBOARD_SHOW_EVENT, LOBNAMES, NEED_HELP_PAGE, NO_INTERNET, OTP_FAILED_ALERT, OTP_LOADER, OTP_OVERLAY, OVERLAY, REFUNDMODE_PAGE_NAMES, REGISTER_INSTANT_REFUND_CLICKED, THEME, WRONG_OTP_ENTERED } from '../../PostSalesConstant';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import { getCancelRequestKeyName, getMmtSelectData, getRatingData, isEasyPayNativeFlowEnabled, isNullOrEmpty, registerForInstantRefund, removeKeyFromAsyncStorage, sendOtp, storeObjInAsyncStorage, verifyOtp } from '../../Common/commonUtil';
import useHardwareBackpress from '../../Common/useHardwareBackpress';
import CancellationPolicyOverlay from '../details/components/cancellationPolicy/CancellationPolicyOverlay';
import * as NpsModule from '@mmt/legacy-commons/Native/NpsModule';
import { getStaticData } from '../../staticData/staticData';
import EmiDetailCard from './components/EmiDetailCard';
import CancellationError from './components/CancellationError';
import OverlayMessage from '../../Common/InstantRefund/Overlays/OverlayMessage';
import BottomOverlayDtls from '../../Common/InstantRefund/Overlays/BottomOverlayDtls';
import PostSalesBookingTrackingHelper from '../../PostSalesBookingTrackingHelper';
import OTPBottomOverlay from '../../Common/InstantRefund/Overlays/OTPBottomOverlay';
import { isNonEmpty } from '@mmt/legacy-commons/Common/utils/StringUtils';
import OTPLoader from '../../Common/InstantRefund/Overlays/OTPLoader';
import { isEmpty } from 'lodash';
import { CANCELLATION_REASON_TYPES, ERROR_CTAS_ACTION_ID } from '../HotelConstants';
import { showErrorPageCTA, getErrorPageHeading, isModificationEligible, getErrorPageDesc, isMweb } from '../hotelUtils';
import NewCommonOverlay from '../../Common/NewCommonOverlay';
import CancelDateChangeReason from './components/BottomSheet'
import HotelBookingDetailsConstant from '../details/HotelBookingDetailsConstant';
import MMTSelectCard from '../../Common/MMTSelectCard';
import TCSBottomOverlay from '../../Common/TCSBottomOverlay/TCSBottomOverlay';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { setWebViewAuth } from '../../utils/PostSaleUtil';
import { WHITE_LEFT_ARROW_ICON } from '../HotelImageConstants';
import BankOfferPopup from './components/BottomSheet/BankOfferPopup';
import ButtonWrapper from '../../Common/ButtonWrapper';
import BottomSheetWpr from '../../Common/BottomSheetWpr';
import { useTheme } from '../../theme/theme.context';

const CancellationReview = (props) => {
   const {cancelaltionPreviewRequest, hotelBookingResponse, userInfoResponse, actionHandler } = props;
   const { ...fonts } = getFont(true);
   const { psTheme } = useTheme();
   const styles = createStyles(psTheme, fonts);
   const {cancellationText: {additionalText}, errorText: { somethingWentWrongTryAgainError }, goBackToBookingHotelText, cancellationDetailsCouldNotBeFetchedText, cancelAnywayText, weAreUnableToFetchTheCancellationChargesText, backText, skipText} = getStaticData();
   const [cancellationProgress, cancellationResponse, cancellationApi] = useApi(HOTEL_BOOKING_CANCELLATION_URL);
   const [cancellationCommitProgress, cancellationCommitResponse, cancellationCommitApi] = useApi(HOTEL_BOOKING_CANCELLATION_URL);
   const { persuationText, confirmationText } = cancellationResponse || {};
   const [helpingHandOverlay, setHelpingHandOverlay] = useState(false);
   const [cancellationReason, setCancellationReason] = useState(null);
   const [trackerSlide, setTrackerSlide] = useState(CANCELLATION_STEPS.CANCELLATION_REASON);
   const [bottomOverlay, setBottomOverlay] = useState('');
   const [activeState, setActiveState] = useState(0);
   const [tcsChecked, setTCSChecked] = useState(false);

   //instant refund
   const [OTPValue, setOTPValue] = useState(EMPTY_STRING);
   const [keyboard, setKeyboard] = useState(false);
   const [instantRefundError, setInstantRefundError] = useState(false);

   const cancellationStepsList = useRef(null);
   const refundModeRef = useRef(REFUND_TO_CUSTOMER);
   const scrollViewRef = useRef();
   const showRatingPrompt = useRef(true);
   const instantDataRef = useRef(null);
   const bottomOverlayRef = useRef(null);

   //animation
   const overlayPosition = useRef(new Animated.Value(-1000)).current;
   const fadeIn = useRef(new Animated.Value(0)).current;
   const overlaySuccess = useRef(new Animated.Value(-400)).current;
   const fadeInSuccess = useRef(new Animated.Value(0.7)).current;

   const widthAnimation = useRef(Array.from({length: MAX_STEPS_IN_CANCELLATION} , _ => new Animated.Value(36))).current;
   const fadeAnimation = useRef(Array.from({length: MAX_STEPS_IN_CANCELLATION} , _ => new Animated.Value(0))).current;
   const leftAnimation = useRef(Array.from({length: MAX_STEPS_IN_CANCELLATION} , _ => new Animated.Value(40))).current;

   const showHelpingHand = hotelBookingResponse && hotelBookingResponse.isHelpingHandEnabled;
   const helpingHandData = getHelpingHandData(hotelBookingResponse, NEED_HELP_PAGE.HOTEL_CANCELLATION, trackHelpingHandEvents);
   const blockModification = shouldBlockOnlineModification(hotelBookingResponse,userInfoResponse);
   const cancelRequestKey = getCancelRequestKeyName(CancellationPreviewRequest.HOTEL, hotelBookingResponse.bookingID);
   const ratingTrackingPageName = 'HotelCancellationThankYou';
   const theme = hotelBookingResponse && hotelBookingResponse.isCorporateBooking ? THEME.MYBIZ : '';
   const trackingPageName = useRef(EMPTY_STRING);
   const clientTxnId = useRef(EMPTY_STRING);
   const isCorporateTheme = hotelBookingResponse && hotelBookingResponse.isCorporateBooking;
   const handleTCSChecked = (value)=> {
      if (value){
         HotelBookingTrackingHelper.trackClickEvent(trackingPageName.current, 'cancellation_tcs_agreement_checked', hotelBookingResponse);
      }
      setTCSChecked(value);
   }
   const goBack = useCallback(() => {
      const currScreenIdx = !isEmpty(cancellationStepsList.current) ? cancellationStepsList.current.findIndex(item => item === trackerSlide) : 1;
      if (bottomOverlay !== '') {
         if (bottomOverlay === BOTTOM_OVERLAY_STATES.CANCELLED_SUCCESS) {
            goToBookingDetails(false, true);
         } else {
            handleCloseOverlay();
         }
      } if(bottomOverlay === BOTTOM_OVERLAY_STATES.CANCELLATION_ERROR && showErrorPageCTA(ERROR_CTAS_ACTION_ID.MYTRIP, cancellationCommitResponse)) {
         Actions.exit();
      } else {
         if (trackerSlide === CANCELLATION_STEPS.REFUND_MODE || trackerSlide === CANCELLATION_STEPS.VIEW_REFUND) {
            widthAnimation[currScreenIdx - 1].setValue(36);
            fadeAnimation[currScreenIdx].setValue(0);
            leftAnimation[currScreenIdx].setValue(40);
            setTrackerSlide(cancellationStepsList.current[currScreenIdx - 1]);
            setActiveState(currScreenIdx - 1);
            setCancellationReason(null);
            setInstantData(null);
            if (trackerSlide === CANCELLATION_STEPS.REFUND_MODE && showHelpingHand) {
               setHelpingHandOverlay(true);
            }
         } else {
            Actions.pop();
         }
      }
   },[trackerSlide, bottomOverlay, showHelpingHand]);

   useHardwareBackpress().addListener(goBack);

   const goForward = (shouldProceed=false ) => {
      handleCloseOverlay(shouldProceed);
      closeBottomOverlay();
      if(shouldProceed)
         onHandleTabChange(CANCELLATION_STEPS.VIEW_REFUND, trackerSlide);
   }

   useEffect(() => {
      removeKeyFromAsyncStorage(cancelRequestKey);
      apiCall();
   }, []);

   useEffect(() => {
      if (cancellationCommitProgress) {
         setBottomOverlay(BOTTOM_OVERLAY_STATES.CANCEL_LOADER);
         Animated.timing(fadeIn, {
            toValue: 1,
            easing: Easing.easeInOut,
            duration: 300,
            delay: 100,
         }).start();
      } else if (cancellationCommitResponse && cancellationCommitResponse.response && cancellationCommitResponse.response.status) {
         setBottomOverlay(BOTTOM_OVERLAY_STATES.CANCELLED_SUCCESS);
         Animated.timing(fadeInSuccess, {
            toValue: 1,
            easing: Easing.easeInOut,
            duration: 300,
            delay: 100,
         }).start();
         Animated.timing(overlaySuccess, {
            toValue: 0,
            easing: Easing.easeInOut,
            duration: 300,
           delay: 100,
         }).start();
         const { ratingData, npsData } = getRatingData(ratingTrackingPageName, cancellationCommitResponse);
         if (ratingData && showRatingPrompt.current) {
            setTimeout(() => {
               NpsModule.showRatingPrompt(ratingData, npsData);
               showRatingPrompt.current = false;
            }, 1500);
         }
      } else if(cancellationCommitResponse) {
         setBottomOverlay(BOTTOM_OVERLAY_STATES.CANCELLATION_ERROR);
         handleOpenOverlay((BOTTOM_OVERLAY_STATES.CANCELLATION_ERROR));
         Animated.timing(fadeIn, {
            toValue: 1,
            easing: Easing.easeInOut,
            duration: 300,
            delay: 100,
         }).start();
      }
   }, [cancellationCommitProgress, cancellationCommitResponse])

   useEffect(() => {
      const refundLoadListener = DeviceEventEmitter.addListener(InstantRefundReloadEvent.HOTEL, refreshCancellation);
      const keyboardDidShowListener = Keyboard.addListener(KEYBOARD_SHOW_EVENT, _keyboardDidShow);
      const keyboardDidHideListener = Keyboard.addListener(KEYBOARD_HIDE_EVENT, _keyboardDidHide);
      return () => {
         if (refundLoadListener) {
            refundLoadListener.remove();
         }
         keyboardDidShowListener.remove();
         keyboardDidHideListener.remove();
      }
   }, []);

   const setDefaultRefundMode = useCallback(() => {
      if (cancellationResponse && cancellationResponse.cancellationRefundMode && isNotNullAndEmpty(cancellationResponse.cancellationRefundMode.refundModeList) && Math.round(cancellationResponse.totalRefundAmount, 0) > 0) {
         refundModeRef.current = cancellationResponse.cancellationRefundMode.refundModeList[0].refundModeReason;
      }
   },[cancellationResponse])

   useEffect(() => {
      setDefaultRefundMode();
   }, [setDefaultRefundMode])

   useEffect(() => {
      trackingPageName.current = isIntlHotelBooking(hotelBookingResponse?.lobCode)
         ? 'Mob:customer support:Hotel International:FullCancel:'
         : 'Mob:customer support:Hotel Domestic:FullCancel:';
      let extraInfo = null;
      switch (trackerSlide) {
         case CANCELLATION_STEPS.VIEW_REFUND:
            trackingPageName.current += 'ReviewCancellation';
            break;
         case CANCELLATION_STEPS.REFUND_MODE:
            trackingPageName.current += 'ChooseRefundMode';
            break;
         case CANCELLATION_STEPS.CANCELLATION_REASON:
            trackingPageName.current += 'PickCancellationReason';
            if(hotelBookingResponse?.showBankOfferCard){
               extraInfo = { bankOfferInfo: HotelBookingDetailsConstant.BANK_OFFER_DISPLAYED }
            }
            break;
      }
      HotelBookingTrackingHelper.trackLoadEvent(
         trackingPageName.current,
         hotelBookingResponse,
         extraInfo
      );
   }, [trackerSlide])

   const _keyboardDidShow = () => {
      setKeyboard(true);
   }

   const _keyboardDidHide = () => {
      setKeyboard(false);
   }

   const refreshCancellation = () => {
      apiCall();
      setTrackerSlide(CANCELLATION_STEPS.REFUND_MODE);
   }

   const closeBottomOverlay = () => {
      bottomOverlayRef.current && bottomOverlayRef.current.closeOverlay && bottomOverlayRef.current.closeOverlay();
   }

   const apiCall = async () => {
      await storeObjInAsyncStorage(cancelRequestKey, cancelaltionPreviewRequest);
      cancellationApi.httpPost({body: cancelaltionPreviewRequest, bookingId: hotelBookingResponse.bookingID, uniqueId: 'HOTEL_CANCELLATION_PREVIEW'});
   }

   const renderStateWise = () => {
      if (cancellationProgress) {
        return renderProgressView();
      } else if (cancellationResponse && cancellationResponse.error && cancellationResponse.error === NO_INTERNET) {
        return renderNoNetworkView();
      } else if (cancellationResponse && cancellationResponse.error) {
        return renderErrorView();
      } else if (cancellationResponse) {
         setCancellationStepsList();
         return renderPage();
      }
   }

   const renderProgressView = () => {
      const { loadingText } = getStaticData(true, true);
      return (
         <ProgressView message={loadingText} />
      );
   };

   const renderErrorView = () => (
      <View style={AtomicCss.flex1}>
         <SimpleHeader title={backText} iconPress={goBack} />
         <ErrorPage
            showRetry={showErrorPageCTA(ERROR_CTAS_ACTION_ID.RETRY, cancellationResponse)}
            onRetry={() => {
               apiCall();
               trackRetryClickEvent('retry_clicked');
            }}
            message={getErrorPageHeading(somethingWentWrongTryAgainError, cancellationResponse)}
            showHelpingHand={showHelpingHand && showErrorPageCTA(ERROR_CTAS_ACTION_ID.HELPING_HAND, cancellationResponse)}
            helpingHandLabel={helpingHandData && helpingHandData.connectWithUsText}
            helpingHandClickHandler={() => setHelpingHandOverlay(true)}
         />
         {showHelpingHand && helpingHandOverlay === true &&
            renderNeedHelpOverlay(helpingHandData, setHelpingHandOverlay)
         }
      </View>
   );

   const renderNoNetworkView = () => (
      <View style={AtomicCss.flex1}>
        <SimpleHeader title={backText} iconPress={goBack} />
        <NoInternetView
            onRetry={() => {
               apiCall();
               trackRetryClickEvent('no_internet_retry_clicked');
            }}
        />
      </View>
   );

   const renderPage = () => {
      return (
         <View style={AtomicCss.flex1}>
            <View style={styles.CancellationWrapper}>
               <Header
                  title={additionalText.cancelBookingText}
                  headerShadow={true}
                  bgColor={colors.white}
                  onBackPress={goBack}
               />
               {showMainContent()}
            </View>
            {showHelpingHand && helpingHandOverlay === true &&
               renderNeedHelpOverlay(helpingHandData, setHelpingHandOverlay, true, true)
            }
            {renderBottomOverlay()}
         </View>
      );
   }

   const renderBottomOverlay = () => {
      switch(bottomOverlay) {
         case BOTTOM_OVERLAY_STATES.CONFIRM_TO_CANCEL:
            return (
               <BottomSheetWpr
                  visible={BOTTOM_OVERLAY_STATES.CONFIRM_TO_CANCEL}
                  containerStyles={styles.bottomSheetWprStyle}
                  setVisible={handleCloseOverlay}
                  children={
                   <ConfirmToCancel
                     handleTab={confirmCancellationClickHandler}
                     isCorporateTheme={isCorporateTheme}
                     confirmationDesc={confirmationText}
                   />
                   }
               />
            )
         case BOTTOM_OVERLAY_STATES.CANCELLATION_REASON_CONFIRM_OVERLAY:
            const { shouldEnableReasonLedFlow } = cancellationResponse || {};
            if(cancellationReason.modalData != null) {
               const hotelDetailCardData = getHotelModalCardData(hotelBookingResponse, cancellationReason);
               switch(cancellationReason.id) {
                  case CANCELLATION_REASON_TYPES.DATE_CHANGE:
                  case CANCELLATION_REASON_TYPES.ADD_GUEST:
                  case CANCELLATION_REASON_TYPES.NAME_CHANGE:
                     if(!shouldEnableReasonLedFlow){
                        return;
                     }
                  case CANCELLATION_REASON_TYPES.OTHER:
                     return (
                        <BottomSheetWpr
                           visible={BOTTOM_OVERLAY_STATES.CANCELLATION_REASON_CONFIRM_OVERLAY}
                           containerStyles={{padding:16}}
                           setVisible={handleCloseOverlay}
                           children={
                                 <CancelDateChangeReason
                                    handleAlternateFlowClick={handleAlternateFlowClick}
                                    handleClick={goForward}
                                    cancellationReason={cancellationReason}
                                    hotelDetailData= {hotelDetailCardData}
                                    theme={theme}
                                    setReason={setCancellationReason}
                                    dismiss={handleCloseOverlay}
                                    isCorporateTheme={isCorporateTheme}
                                 />
                           }
                        />
                     )
                  default:
                     return null;

               }
            } else {
               return null;
            }
         case BOTTOM_OVERLAY_STATES.BANK_OFFER_OVERLAY:
         case BOTTOM_OVERLAY_STATES.FREE_CANCELLATION_OVERLAY:
            const { cancellationPopupDetails, offerDetails } = cancellationReason || {};
            const { FREE_CANCELLATION_POPUP_DISPLAYED, BANK_OFFER_POPUP_DISPLAYED } = HotelBookingDetailsConstant;
            const popupDetails = offerDetails || cancellationPopupDetails;
            if(!isEmpty(popupDetails)) {
               const clickEvent = offerDetails ? BANK_OFFER_POPUP_DISPLAYED : (cancellationPopupDetails?.omnitureValue || FREE_CANCELLATION_POPUP_DISPLAYED);
               HotelBookingTrackingHelper.trackClickEvent(trackingPageName.current, clickEvent, hotelBookingResponse);
                     return (
                        <BottomSheetWpr
                           visible={BOTTOM_OVERLAY_STATES.BANK_OFFER_OVERLAY}
                           containerStyles={styles.bottomSheetWprStyle}
                           setVisible={handlePopupClosedOverlay}
                           children={
                              <BankOfferPopup 
                              handlePaymentOfferClick={handlePaymentOfferClick}
                              handleCancelClick={handleCancelClick}
                              dismiss={handlePopupClosedOverlay}
                              offerDetails={popupDetails}
                              showCrossIcon={bottomOverlay !== BOTTOM_OVERLAY_STATES.FREE_CANCELLATION_OVERLAY}
                           />
                           }
                       />
                )
            }
         case BOTTOM_OVERLAY_STATES.CANCEL_LOADER:
            return(
               <CommonOverlayMessage
                  handleBottomOverlay={handleCloseOverlay}
                  animatedOverlay={true}
                  isTopRequired={true}
                  customStyle={AtomicCss.borderTopRadius16}
                  content={[
                     <Animated.View style={[styles.OverlayContent, { opacity: fadeIn }]}>
                        <CancelLoader
                           loaderText={additionalText.cancellingYourBookingText}
                        />
                     </Animated.View>
                  ]}
               />
            )
         case BOTTOM_OVERLAY_STATES.CANCELLED_SUCCESS:
            return(
               <CommonOverlayMessage
                  handleBottomOverlay={handleCloseOverlay}
                  animatedOverlay={true}
                  isTopRequired={true}
                  customStyle={AtomicCss.borderTopRadius16}
                  content={[
                     <Animated.View style={[styles.OverlayContent, { opacity: fadeInSuccess, bottom: overlaySuccess }]}>
                        <CancelledSuccess
                           hotelName={getHotelName(hotelBookingResponse)}
                           handleClick={() => backToBookingHandler(true)}
                           theme={theme}
                           hotelBookingResponse={hotelBookingResponse}
                           successResponse = {cancellationCommitResponse?.response || {}}
                        />
                     </Animated.View>
                  ]}
               />
            )
         case BOTTOM_OVERLAY_STATES.CANCELLATION_POLICY:
            return(
               <BottomSheetWpr
                  visible={BOTTOM_OVERLAY_STATES.CANCELLATION_POLICY}
                  setVisible={handleCloseOverlay}
                  children={
                     <CancellationPolicyOverlay
                        showDismiss={true}
                        dismissClickHandler={handleCloseOverlay}
                        cancellationPolicyCardInfo={hotelBookingResponse.cancellationPolicyCardInfo}
                        isRefund={!isNonRefundable(hotelBookingResponse)}
                        hotelDetailsResponse={hotelBookingResponse}
                        canResponse={cancellationResponse}
                  />
                }
            />
            )
         case BOTTOM_OVERLAY_STATES.CANCELLATION_ERROR:
            return(
               <CommonOverlayMessage
                  handleBottomOverlay={handleCloseOverlay}
                  animatedOverlay={true}
                  isTopRequired={true}
                  customStyle={AtomicCss.borderTopRadius16}
                  content={[
                     <Animated.View style={[styles.OverlayContent, { bottom: overlayPosition }]}>
                        <CancellationError
                           hotelBookingResponse={hotelBookingResponse}
                           showRetry={showErrorPageCTA(ERROR_CTAS_ACTION_ID.RETRY, cancellationCommitResponse)}
                           showMytrip={showErrorPageCTA(ERROR_CTAS_ACTION_ID.MYTRIP, cancellationCommitResponse)}
                           showBackToBookingCTA={showErrorPageCTA(ERROR_CTAS_ACTION_ID.BACK_TO_BOOKING, cancellationCommitResponse)}
                           heading={getErrorPageHeading(additionalText.bookingCancellationFailedText, cancellationCommitResponse)}
                           errorDesc={getErrorPageDesc('', cancellationCommitResponse)}
                           hotelName={getHotelName(hotelBookingResponse)}
                           showHelpingHand={showHelpingHand && showErrorPageCTA(ERROR_CTAS_ACTION_ID.HELPING_HAND, cancellationCommitResponse)}
                           helpingHandClickHandler={setHelpingHandOverlay}
                           backToBookingHandler={backToBookingHandler}
                           onRetry={onRetry}
                           theme={theme}
                           cancellationCommitResponse={cancellationCommitResponse}
                        />
                     </Animated.View>
                  ]}
               />
            )
         case OVERLAY:
            return(
               <OverlayMessage
                  handleBottomOverlay={handleBottomOverlay}
                  content={
                     <BottomOverlayDtls
                        handleBottomOverlay={handleBottomOverlay}
                        handleProceedButton={handleProceedButtonFn}
                     />
                  }
               />
            )
         case OTP_OVERLAY:
            return(
               <OverlayMessage
                  handleBottomOverlay={handleBottomOverlay}
                  keyboard={keyboard}
                  content={
                     <OTPBottomOverlay
                        bookingId={hotelBookingResponse.bookingID}
                        OTPValue={OTPValue}
                        handleBottomOverlay={handleBottomOverlay}
                        handleOTPValue={handleOTPValue}
                        handleOTPOverlay={handleOTPOverlay}
                        errorState={instantRefundError}
                        resendOtp={resendOtp}
                     />
                  }
               />
            )
         case OTP_LOADER:
            return(
               <OverlayMessage
                  handleBottomOverlay={handleBottomOverlay}
                  content={
                     <OTPLoader />
                  }
               />
            )
         case BOTTOM_OVERLAY_STATES.TCS_OVERLAY:
            const priceBreakup = getTCSPriceBreakup(cancellationResponse);
            if (priceBreakup){
               return (
                  <CommonBottomOverlayMessage
                     isBorderRadius
                     dismiss={handleBottomOverlay}
                     content={<TCSBottomOverlay tcsPopupInfo={priceBreakup.popup} onClose={()=>handleBottomOverlay('')} />}
                     customStyle={AtomicCss.borderTopRadius16}
                     isCancellable={false} />
                  );
            } else {
               return null;
            }
         default:
            return null;
      }
   }

   const handlePopupClosedOverlay = () => {
      const { BANK_OFFER_POPUP_CLOSED, FREE_CANCELLATION_POPUP_CLOSED } = HotelBookingDetailsConstant;
      const clickEvent = bottomOverlay === BOTTOM_OVERLAY_STATES.BANK_OFFER_OVERLAY ? BANK_OFFER_POPUP_CLOSED : FREE_CANCELLATION_POPUP_CLOSED;
      HotelBookingTrackingHelper.trackClickEvent(trackingPageName.current, clickEvent, hotelBookingResponse);
      handleCloseOverlay()
   }
   const handleBottomOverlay = (showOverlay) => {
      setBottomOverlay(showOverlay);
      setOTPValue(EMPTY_STRING);
   }

   const handleProceedButtonFn = () => {
      PostSalesBookingTrackingHelper.trackClickEvent(REFUNDMODE_PAGE_NAMES.HOTEL, REGISTER_INSTANT_REFUND_CLICKED, hotelBookingResponse.bookingID);
      handleBottomOverlay(EMPTY_STRING);
      registerForInstantRefund(LOBNAMES.HOTEL);
   }

   const handleOTPValue = (enterText) => {
      setOTPValue(enterText);
      setInstantRefundError(false);
   }

   const handleOTPOverlay = () => {
      if (OTPValue === EMPTY_STRING) {
        setInstantRefundError(true);
      } else {
         setBottomOverlay(OTP_LOADER)
         verifyOTP();
      }
   }

   const verifyOTP = async () => {
      const authorizationToken = await verifyOtp(
        hotelBookingResponse.bookingID,
        OTPValue,
        clientTxnId.current,
      );
      if (!authorizationToken) {
        PostSalesBookingTrackingHelper.trackLoadEvent(
          REFUNDMODE_PAGE_NAMES.HOTEL,
          WRONG_OTP_ENTERED,
          hotelBookingResponse.bookingID,
        );
        setBottomOverlay(OTP_OVERLAY);
        setInstantRefundError(true);
      } else {
        PostSalesBookingTrackingHelper.trackClickEvent(
          REFUNDMODE_PAGE_NAMES.HOTEL,
          INSTANT_REFUND_REQUESTED,
          hotelBookingResponse.bookingID,
        );
        setBottomOverlay(EMPTY_STRING);
        callConfirmCancellation(authorizationToken);
      }
    };

   const resendOtp = () => {
      PostSalesBookingTrackingHelper.trackClickEvent(REFUNDMODE_PAGE_NAMES.HOTEL, INSTANT_REFUND_OTP_REQUESTED_AGAIN, hotelBookingResponse.bookingID);
      sendOTP();
   }

   const sendOTP = async () => {
      clientTxnId.current = await sendOtp(hotelBookingResponse.bookingID);
      if (isNonEmpty(clientTxnId.current)) {
        PostSalesBookingTrackingHelper.trackClickEvent(REFUNDMODE_PAGE_NAMES.HOTEL, INSTANT_REFUND_OTP_SUCCESS, hotelBookingResponse.bookingID);
        setBottomOverlay(OTP_OVERLAY);
      } else {
        /* no screen for otp sending failure right now. Alert temp */
        PostSalesBookingTrackingHelper.trackClickEvent(REFUNDMODE_PAGE_NAMES.HOTEL, INSTANT_REFUND_OTP_FAILED, hotelBookingResponse.bookingID);
        Alert.alert(OTP_FAILED_ALERT);
      }
    }

   const showMainContent = () => {
      if (shouldShowScreen()) {
        return showCancellationDetails();
      }
      trackError(cancellationResponse.response.errorText);
      return renderAPIErrorScreen();
   }

   const shouldShowScreen = () => {
      return cancellationResponse && cancellationResponse.response && cancellationResponse.response.status;
   }

   const onRetry = useCallback(() => {
      callConfirmCancellation();
      trackRetryClickEvent('retry_clicked');
   },[callConfirmCancellation, trackRetryClickEvent])

   const renderMainContent = () => {
      return (
        <View style={AtomicCss.flex1}>
          <ScrollView ref={scrollViewRef}>
            <View style={[AtomicCss.makeRelative, styles.cardWrapper]}>
              {loadScreen()}
            </View>
          </ScrollView>
          <View style={[styles.buttonBottomWrapper]}>
            {!!persuationText && trackerSlide === CANCELLATION_STEPS.VIEW_REFUND && <Text style={styles.persuationText}>{persuationText}</Text>}
            {renderFooterButton()}
          </View>
        </View>
      )
   }

   const showCancellationDetails = () => {
      if (isNotNullAndEmpty(cancellationResponse.response.errorText)) {
        trackCancelAnywayLoad();
        return renderIsCancelAnyway();
      }
      return renderMainContent();
   }

   const renderAPIErrorScreen = () => (
      <ErrorView
         showRetry={showErrorPageCTA(ERROR_CTAS_ACTION_ID.BACK_TO_BOOKING, cancellationResponse)}
         buttonLabel={goBackToBookingHotelText}
         message={getErrorPageHeading(cancellationDetailsCouldNotBeFetchedText, cancellationResponse)}
         errorDesc={getErrorPageDesc('', cancellationResponse)}
         onRetry={() => {
            backToBookingHandler(false);
         }}
      />
   );

   const renderIsCancelAnyway = () => (
      <ErrorView
         showRetry
         buttonLabel={cancelAnywayText}
         message={weAreUnableToFetchTheCancellationChargesText}
         onRetry={() => {
            callConfirmCancellation();
            HotelBookingTrackingHelper.trackClickEvent(trackingPageName.current, 'FullCancel_ConfirmCancellation', hotelBookingResponse);
         }}
      />
   );

   const trackCancelAnywayLoad = () => {
      trackingPageName.current = isIntlHotelBooking(hotelBookingResponse?.lobCode)
        ? 'Mob:customer support:Hotel International:FullCancel:CancelAnyway'
        : 'Mob:customer support:Hotel Domestic:FullCancel:CancelAnyway';
      HotelBookingTrackingHelper.trackLoadEvent(
         trackingPageName.current,
         hotelBookingResponse
      );
    }

   const trackError = (error) => {
      trackingPageName.current = isIntlHotelBooking(hotelBookingResponse?.lobCode)
        ? 'Mob:customer support:Hotel International:FullCancel:ReviewCancellation:Error'
        : 'Mob:customer support:Hotel Domestic:FullCancel:ReviewCancellation:Error';
      HotelBookingTrackingHelper.trackErrorWithData(trackingPageName.current, hotelBookingResponse.bookingID, error, hotelBookingResponse);
    }

   const trackRetryClickEvent = (clickEvent) => {
      trackingPageName.current = isIntlHotelBooking(hotelBookingResponse?.lobCode)
        ? 'Mob:customer support:Hotel International:FullCancel:ReviewCancellation:Error'
        : 'Mob:customer support:Hotel Domestic:FullCancel:ReviewCancellation:Error';
      HotelBookingTrackingHelper.trackClickEvent(trackingPageName.current, clickEvent, hotelBookingResponse);
   }

   const backToBookingHandler = useCallback((refresh) => {
      goToBookingDetails(true, refresh);
   },[goToBookingDetails])

   const goToBookingDetails = useCallback((doTrack, refresh = false) => {
      const bookingId = hotelBookingResponse && hotelBookingResponse.bookingID;
      if (bookingId && (refresh || Platform.OS === 'web')) {
         resetToHotelDetail(bookingId);
      } else {
         Actions.popTo('hotelBookingDetail');
      }
      if (doTrack) {
         if (refresh) {
            HotelBookingTrackingHelper.trackClickEvent(trackingPageName.current, 'FullCancel_SeeUpdatedBooking', hotelBookingResponse);
         } else {
            HotelBookingTrackingHelper.trackClickEvent(trackingPageName.current, 'FullCancel_BackToBooking', hotelBookingResponse);
         }
      }
   },[hotelBookingResponse]);

   const trackHelpingHandEvents = (type, page, click, data) => {
      trackHelpingHandEvent(type, page, click, data, trackingPageName.current, hotelBookingResponse);
   }

   const handleOpenOverlay = (overlay) => {
      setBottomOverlay(overlay);
      Animated.timing(overlayPosition, {
        toValue: 0,
        easing: Easing.easeInOut,
        duration: 300,
        delay: 100,
      }).start();
   }

   const handleCloseOverlay = () => {
      Animated.timing(overlayPosition, {
        toValue: -1000,
        easing: Easing.easeInOut,
        duration: 300,
        delay: 100,
      }).start();
      setBottomOverlay('');
   }

   const handleAlternateFlowClick = (type) => {
      switch(type){
         case CANCELLATION_REASON.CHANGE_DATE:
            setBottomOverlay(''); 
            const cardAndActionDate = getCardAndActionFromAllCards(hotelBookingResponse, HotelBookingDetailsConstant.ACTION_CHANGE_DATES);
            if (cardAndActionDate.card && cardAndActionDate.action){
               isMweb ? Actions.hotelBookingDetail({ bookingId: hotelBookingResponse.bookingID, isODC: true}) :
               setTimeout(() => handleClickEvent(cardAndActionDate.card, {...cardAndActionDate.action, actionHandler}, hotelBookingResponse, trackingPageName.current), 10);
            }
            break;
         case CANCELLATION_REASON.ADD_GUEST:
            const cardAndActionAddGuest = getCardAndActionFromAllCards(hotelBookingResponse, HotelBookingDetailsConstant.ACTION_ADD_GUEST);
            if (cardAndActionAddGuest.card && cardAndActionAddGuest.action && isNullOrEmpty(cardAndActionAddGuest.actionAlertHeaderText) && isNullOrEmpty(cardAndActionAddGuest.actionAlertMainText) )
               handleClickEvent(cardAndActionAddGuest.card, {...cardAndActionAddGuest.action, actionHandler}, hotelBookingResponse, trackingPageName.current);
            break;
         case CANCELLATION_REASON.CHANGE_NAME:
            const cardAndActionName = getCardAndActionFromAllCards(hotelBookingResponse, HotelBookingDetailsConstant.ACTION_EDIT_PRIMARY_GUEST_NAMES);
            if (cardAndActionName.card && cardAndActionName.action) {
               handleClickEvent(cardAndActionName.card, {...cardAndActionName.action, actionHandler}, hotelBookingResponse, trackingPageName.current);
            }
            break;
         default:
      }
      closeBottomOverlay();
      handleCloseOverlay();
   }

   const handleCancelClick = (action = {}) => {
      const { KEEP_BOOKING, CANCEL_BOOKING } = CANCELLATION_ACTION;
      const { FREE_CANCELLATION_CANCEL_BOOKING_CLICKED } = HotelBookingDetailsConstant;
      const { actionId } = action || {};
      if(actionId === KEEP_BOOKING){
         handleCloseOverlay();
         goToBookingDetails(true);
      } else if(actionId === CANCEL_BOOKING && cancellationReason.id === CANCELLATION_REASON_TYPES.OTHER){
         HotelBookingTrackingHelper.trackClickEvent(trackingPageName.current, FREE_CANCELLATION_CANCEL_BOOKING_CLICKED, hotelBookingResponse);
         handleOpenOverlay(BOTTOM_OVERLAY_STATES.CANCELLATION_REASON_CONFIRM_OVERLAY);
      } else {
         actionId === CANCEL_BOOKING && HotelBookingTrackingHelper.trackClickEvent(trackingPageName.current, FREE_CANCELLATION_CANCEL_BOOKING_CLICKED, hotelBookingResponse);
         goForward(true);
      }
   }

   const handlePaymentOfferClick = async () => {
      const staticData = getStaticData()
      const { paymentBreakup, bookingID } = hotelBookingResponse || {};
      const { bnplCardChargeInfo } = paymentBreakup || {}
      const { PAY_DIFFERENT_MODE_WITH_BANK_OFFER_POPUP_CLICKED } = HotelBookingDetailsConstant
      HotelBookingTrackingHelper.trackClickEvent(trackingPageName.current, PAY_DIFFERENT_MODE_WITH_BANK_OFFER_POPUP_CLICKED, hotelBookingResponse);
      const userInfo = await HotelBookingModule.getRequestHeader(bookingID, `BNPL_PAYMENT_${staticData.payNowText}`);
      if(isMweb()) {
        window.location.href = bnplCardChargeInfo?.otherPaymodeLink;
      } else {
       isEasyPayNativeFlowEnabled()
         ? GenericModule.openDeepLink(bnplCardChargeInfo?.otherPaymodeLink)
         : Actions.openPsWebView({
             url: bnplCardChargeInfo?.otherPaymodeLink,
             headerText: staticData.payNowText,
             headerIcon: WHITE_LEFT_ARROW_ICON,
             injectedJavaScript: setWebViewAuth(userInfo.mmtAuth),
           });
      }
      handleCloseOverlay();
   }

   const onHandleTabChange = (show, index, autoOpenOverlay = false) => {
      scrollViewRef.current.scrollTo({ x: 0, y: 0 });
      const currScreenIdx = cancellationStepsList.current.findIndex(item => item === trackerSlide);
      if (trackerSlide === CANCELLATION_STEPS.VIEW_REFUND) {
         Animated.timing(widthAnimation[currScreenIdx], {
            toValue: 12,
            duration: 700,
            delay: 100
         }).start();
         Animated.timing(fadeAnimation[currScreenIdx + 1], {
            toValue: 1,
            duration: 700
         }).start();
         Animated.timing(leftAnimation[[currScreenIdx + 1]], {
            toValue: 0,
            duration: 700,
            delay: 100
         }).start();
      }

      if (trackerSlide === CANCELLATION_STEPS.REFUND_MODE) {
         Animated.timing(widthAnimation[currScreenIdx], {
            toValue: 12,
            duration: 500,
            delay: 100
         }).start();
         Animated.timing(fadeAnimation[currScreenIdx + 1], {
            toValue: 1,
            duration: 500
         }).start();
         Animated.timing(leftAnimation[currScreenIdx + 1], {
            toValue: 0,
            duration: 500,
            delay: 100
         }).start();
      }

      if (trackerSlide === CANCELLATION_STEPS.CANCELLATION_REASON) {
         Animated.timing(widthAnimation[currScreenIdx], {
            toValue: 12,
            duration: 500,
            delay: 100
         }).start();
         Animated.timing(fadeAnimation[currScreenIdx + 1], {
            toValue: 1,
            duration: 500
         }).start();
         Animated.timing(leftAnimation[currScreenIdx + 1], {
            toValue: 0,
            duration: 500,
            delay: 100
         }).start();
      }

      setTrackerSlide(show);
      setActiveState(index => index +1);
   }

   const handleReasonSelection = (cancellationReason) => {
      setCancellationReason(cancellationReason);
      if (cancellationReason?.showBankOffer) {
         handleOpenOverlay(BOTTOM_OVERLAY_STATES.BANK_OFFER_OVERLAY)
      } else if(!isEmpty(cancellationReason?.cancellationPopupDetails)) {
         handleOpenOverlay(BOTTOM_OVERLAY_STATES.FREE_CANCELLATION_OVERLAY);
      } else if(cancellationReason.id == CANCELLATION_REASON_TYPES.OTHER || isModificationEligible(hotelBookingResponse, cancellationReason, blockModification)){
         handleOpenOverlay(BOTTOM_OVERLAY_STATES.CANCELLATION_REASON_CONFIRM_OVERLAY)
      }
   }
   const loadScreen = () => {
      const { cancellationPolicyCardInfo, loyaltyDetails, flexiCancellationData } = hotelBookingResponse || {};
      const {cancellationPopupDetails, backGroundGradient} = loyaltyDetails || {};
      const {tierName} = getMmtSelectData(loyaltyDetails)
      return (
         <React.Fragment>
            <View style={styles.topCardStyle}>
               <HotelDetailCard {...getHotelDetailCardData(hotelBookingResponse)} />
               {trackerSlide === CANCELLATION_STEPS.VIEW_REFUND && cancellationPolicyCardInfo &&
                  <PolicyDetailCard
                     isRefundable={!isNonRefundable(hotelBookingResponse)}
                     handleClick={openCancellationPolicy}
                     title={cancellationPolicyCardInfo.title}
                     desc={cancellationPolicyCardInfo.description}
                     flexiCancellationData={flexiCancellationData}
                  />
               }
               {<MMTSelectCard
                        cancellationPopupDetails={cancellationPopupDetails}
                        backgroundGradient={backGroundGradient}
                        pageName = {trackingPageName.current}
                        tierName = {tierName}
                  />
               }
            </View>
            {loadSlide()}
         </React.Fragment>
      )
   }

   const loadSlide = () => {
      const currScreenIdx = cancellationStepsList.current && cancellationStepsList.current.findIndex(item => item === trackerSlide);
      switch(trackerSlide){
         case CANCELLATION_STEPS.VIEW_REFUND:
            return (
               <React.Fragment>
                  <View style={[AtomicCss.whiteCardNew, AtomicCss.flex1, styles.pageWrapper]}>
                     <Animated.View style={[styles.uploadDocWrapper, {left: leftAnimation[currScreenIdx]}]} opacity={fadeAnimation[currScreenIdx]} key='VIEW_REFUND' >
                        <View style={styles.stepBarWrapper}>
                           <StepBar
                              isActive={activeState}
                              count={cancellationStepsList.current.length}
                              widthAnimation={widthAnimation}
                              theme={theme}
                              langAware={true}
                           />
                        </View>
                        <RefundApplicableCard
                           isRefundable={!isNonRefundable(hotelBookingResponse)}
                           isPAH={isPAH(hotelBookingResponse)}
                           isBNPL={isBNPL(hotelBookingResponse)}
                           breakups={cancellationResponse.breakups}
                           cancellationResponse={cancellationResponse}
                           handleBottomOverlay={handleBottomOverlay}
                           tcsChecked={tcsChecked}
                           setTCSChecked={handleTCSChecked}
                        />
                     </Animated.View>
                  </View>
                  {isNonEmpty(cancellationResponse.emiCancellationMessage) && <Animated.View style={[styles.selectModeWrapper]}>
                     <EmiDetailCard
                        emiMessage={cancellationResponse.emiCancellationMessage}
                     />
                  </Animated.View>}
               </React.Fragment>
            )
         case CANCELLATION_STEPS.REFUND_MODE:
            return (
               <View style={[AtomicCss.whiteCardNew, AtomicCss.flex1, styles.pageWrapper]}>
                  <Animated.View style={[styles.uploadDocWrapper, {left: leftAnimation[currScreenIdx]}]} opacity={fadeAnimation[currScreenIdx]} key='REFUND_MODE' >
                     <View style={styles.stepBarWrapper}>
                        <StepBar
                           isActive={activeState}
                           count={cancellationStepsList.current.length}
                           widthAnimation={widthAnimation}
                           theme={theme}
                           langAware={true}
                        />
                     </View>
                     {
                        !isEmpty(cancellationResponse.cancellationRefundMode) && 
                           <View style={styles.paymentModeWrapper}>
                              <SelectRefundPaymentMode
                                 refundAmountText={cancellationResponse.refundAmountText}
                                 cancellationRefundMode={cancellationResponse.cancellationRefundMode}
                                 setRefundMode={setRefundMode}
                                 setInstantData={setInstantData}
                                 theme={theme}
                                 handleBottomOverlay={handleBottomOverlay}
                                 bookingID={hotelBookingResponse.bookingID}
                                 instantRefundData={cancellationResponse.instantRefundData}
                              />
                           </View>
                     }
                  </Animated.View>
               </View>
            )
         case CANCELLATION_STEPS.CANCELLATION_REASON:
            return (
               <View style={[AtomicCss.whiteCardNew, AtomicCss.flex1, styles.pageWrapper]}>
                  <Animated.View style={[styles.uploadDocWrapper]} key='CANCELLATION_REASON'>
                     <View style={styles.stepBarWrapper}>
                        <StepBar
                           isActive={activeState}
                           skipHandler={confirmCancellationHandler}
                           count={cancellationStepsList.current.length}
                           widthAnimation={widthAnimation}
                           theme={theme}
                           langAware={true}
                        />
                     </View>
                     <View style={styles.paymentModeWrapper}>
                        <SelectReasonCard
                           cancellationReasonData={cancellationResponse.cancellationReasonData}
                           setReason={handleReasonSelection}
                           theme={theme}
                        />
                     </View>
                  </Animated.View>
               </View>
            )
         default:
            return null;
      }
   }

   const renderFooterButton = () => {
      let clickHandler, btnTxt;
      let toDisable = false;
      const currentScreen = cancellationStepsList.current.findIndex(item => item === trackerSlide);
      const nextSceen = cancellationStepsList.current[trackerSlide + 1];
      if (!nextSceen) {
        clickHandler = confirmCancellationHandler;
        btnTxt = additionalText.proceedToCancelText;
      } else if (nextSceen === CANCELLATION_STEPS.REFUND_MODE) {
        clickHandler = refundModeClickHandler;
        btnTxt = additionalText.pickRefundModeText;
      } else if (nextSceen === CANCELLATION_STEPS.VIEW_REFUND) {
        clickHandler = ViewRefundClickHandler;
        btnTxt = additionalText.proceedToCancelText;
      }
      if (trackerSlide === CANCELLATION_STEPS.CANCELLATION_REASON) {
         toDisable = cancellationReason === null || cancellationReason.id === CANCELLATION_REASON_TYPES.OTHER;
      } else if (trackerSlide === CANCELLATION_STEPS.VIEW_REFUND){
         const priceBreakup = getTCSPriceBreakup(cancellationResponse);
         if (priceBreakup){
            toDisable = !tcsChecked;
         }
      } else if(trackerSlide === CANCELLATION_STEPS.REFUND_MODE){
         toDisable = !isNonEmpty(cancellationResponse?.cancellationRefundMode?.refundModeList);
      }
      return (
            <ButtonWrapper
               clickHandler={clickHandler}
               disabled={toDisable}
               isThrottleRequired={true}
               throttleInterval={500}
               btnText={btnTxt}
               buttonWidth='98%'
               bgColors = {isCorporateTheme ? "myBiz" : null}
            />
      )
   }

   const refundModeClickHandler = () => {
      HotelBookingTrackingHelper.trackClickEvent(trackingPageName.current, 'FullCancel_ContinueToRefundMode', hotelBookingResponse);
      onHandleTabChange(CANCELLATION_STEPS.REFUND_MODE, CANCELLATION_STEPS.CANCELLATION_REASON);
   }

   const ViewRefundClickHandler = () => {
      HotelBookingTrackingHelper.trackClickEvent(trackingPageName.current, 'FullCancel_ContinueToViewRefund', hotelBookingResponse);

      if(isModificationEligible(hotelBookingResponse, cancellationReason, blockModification)) {
         handleOpenOverlay(BOTTOM_OVERLAY_STATES.CANCELLATION_REASON_CONFIRM_OVERLAY);
      } else {
         onHandleTabChange(CANCELLATION_STEPS.VIEW_REFUND, trackerSlide);
      }
   }

   const confirmCancellationHandler = () => {
      let clickEvent = 'FullCancel_ConfirmCancellationWallet';
      if (refundModeRef.current === REFUND_TO_CUSTOMER) {
         clickEvent = 'FullCancel_ConfirmCancellationOriginal';
      }
      HotelBookingTrackingHelper.trackClickEvent(trackingPageName.current, clickEvent, hotelBookingResponse);
      if (instantDataRef.current != null) {
         sendOTP();
      } else {
         handleOpenOverlay(BOTTOM_OVERLAY_STATES.CONFIRM_TO_CANCEL);
      }
   }

   const openCancellationPolicy = () => {
      handleOpenOverlay(BOTTOM_OVERLAY_STATES.CANCELLATION_POLICY);
   }

   const confirmCancellationClickHandler = () => {
      HotelBookingTrackingHelper.trackClickEvent(trackingPageName.current, 'FullCancel_ConfirmCancellation', hotelBookingResponse);
      callConfirmCancellation();
   }

   const getCancellationCommitRequest = () => {
      const cancellationReasonData = cancellationReason ? {reason: cancellationReason.reasonText} : null;
      const cancellationRequest = getHotelCancellationRequest(hotelBookingResponse, 'false', refundModeRef.current, instantDataRef.current, cancellationReasonData);
      return cancellationRequest;
   }

   const callConfirmCancellation = useCallback(
      (authorizationToken) => {
        const request = getCancellationCommitRequest();
        cancellationCommitApi.httpPost({
          body: { ...request, authorizationToken },
          bookingId: hotelBookingResponse.bookingID,
          uniqueId: 'HOTEL_CANCELLATION_DONE',
        });
      },[getCancellationCommitRequest, cancellationCommitApi, hotelBookingResponse, cancellationReason]);


   const setRefundMode = useCallback((refundMode) => {
      refundModeRef.current = refundMode;
   },[refundModeRef]);

   const setInstantData = useCallback((instantData) => {
      instantDataRef.current = instantData;
   },[]);

   const setCancellationStepsList = () => {
      cancellationStepsList.current = getCancellationSteps(hotelBookingResponse, cancellationResponse);
   }

   return (
      <View style={AtomicCss.flex1}>
         {renderStateWise()}
      </View>
   )
}

export default CancellationReview;
