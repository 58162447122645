import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
import { StyleSheet, Dimensions } from 'react-native';

const windowWidth = Dimensions.get('window').width;

const getStyle = (theme, fonts) => {
  const {color} = theme;
  return StyleSheet.create({
   cardWrapper: {
     backgroundColor: color.white,
     elevation: 3,
     ...getPlatformElevation(2),
     marginBottom: 16,
     borderRadius: 8,
     borderWidth: 1,
     borderColor: color.greyBookedSeat,
   },
   lastItemStyle: {
     marginBottom: 5,
   },
   checkAllWrapper: {
     borderRadius: 8,
   },
   checkBtn: {
     flexDirection: 'row',
     paddingVertical: 10,
     paddingHorizontal: 15,
   },
   checkAllBtn: {
     alignItems: 'flex-start',
   },
   checkbox: {
     height: 18,
     width: 18,
     backgroundColor: color.white,
     borderColor: color.lightTextColor,
     borderWidth: 1.5,
     borderRadius: 3,
     overflow: 'hidden',
     position: 'relative',
     marginRight: 12,
   },
   checkboxSelected: {
     borderColor: color.transparent,
     borderWidth: 0,
   },
   checkboxAll: {
     marginTop: 6,
   },
   checkedIcon: {
     width: 19,
     height: 19,
     position: 'absolute',
     left: 0,
     top: -2,
   },
   destRow: {
     flexDirection: 'row',
     alignItems: 'center',
     flexWrap: 'wrap',
     width: windowWidth - 170,
   },
   cityText: {
     ...fonts.boldFontFamily,
     color: color.black28,
     lineHeight: 24,
   },
   arrowIcon: {
     width: 10,
     height: 7,
     marginHorizontal: 5,
   },
   smallText: {
     ...fonts.regularFontFamily,
     color: color.defaultTextColor,
     marginTop: 5,
     marginBottom: 0,
     lineHeight: 16,
   },
   passengerName: {
     ...fonts.boldFontFamily,
     color: color.black,
   },
   passengersList: {
     paddingHorizontal: 8,
   },
   redInfoIcon: {
     width: 16,
     height: 16,
     marginRight: 10,
   },
   redItalicText: {
     ...fonts.regularFontFamily,
     color: color.red,
     fontSize: fonts.fontSize.font12,
     fontStyle: 'italic',
   },
   redInfoWrappe: {
     padding: 16,
     paddingTop: 6,
     flexDirection: 'row',
   },
   checkAllWrapperInner: {
     flexDirection: 'row',
     justifyContent: 'space-between',
   },
   selectNameRow: {
     flexDirection: 'column',
     justifyContent: 'space-between',
     flex: 1,
   },
   paxListWrapper: { 
    backgroundColor: color.lightGrey6, 
    borderRadius: 4, 
    marginBottom: 8 
  },
   sectorPaxList: {
     paddingVertical: 19,
     paddingHorizontal: 35,
   },
   isMoreInfoWrapper: {
     borderBottomColor: color.transparent,
     borderBottomWidth: 0,
   },
   passengerRfundTxt: {
     ...fonts.regularFontFamily,
     fontSize: fonts.fontSize.font12,
     color: color.greyLight,
     marginTop: 5,
   },
   paxslctionHeader: {
     flex: 1,
     justifyContent: 'space-between',
   },
   sectorHeader: {
     flexDirection: 'row',
     flex: 1,
     justifyContent: 'space-between',
   },
   airlinesIconWrapper: {
     width: 33,
     height: 33,
     alignSelf: 'center',
   },
   airlineIcon: {
     width: 32,
     height: 32,
   },
   paxDisabled: {
     backgroundColor: color.grey7,
     opacity: 0.7,
   },
   margin_bottom10: {
     marginBottom: 10,
   },
   passengerCancelled: {
     ...fonts.boldFontFamily,
     fontSize: fonts.fontSize.font14,
     color: color.black,
     textDecorationLine: 'line-through',
   },
   boxDisabled: {
     backgroundColor: color.grey7,
     opacity: 0.7,
   },
   cancelledTxt: {
     ...fonts.regularFontFamily,
     color: color.red,
     fontSize: fonts.fontSize.font12,
   },
   disableChkBox: {
     backgroundColor: color.greyBookedSeat,
     borderColor: color.gray3,
   },
   disableTxt: {
     ...fonts.regularFontFamily,
     color: color.greyLight,
     fontSize: fonts.fontSize.font14,
   },
   errorInfoTxt: {
     fontSize: fonts.fontSize.font14,
     color: color.red6,
   },
   infoTxtColor: {
     ...fonts.regularFontFamily,
     color: color.lightYello,
     fontSize: fonts.fontSize.font12,
   },
   moreDtlsSection: {
     padding: 10,
     flexDirection: 'row',
     flex: 1,
     backgroundColor: color.creamWhite,
     borderRadius: 4,
     marginTop: 4,
     marginBottom: 8,
   },
   textBlue: {
     color: color.azure,
   },
   txtInfo: {
     ...fonts.regularFontFamily,
     color: color.defaultTextColor,
     fontSize: fonts.fontSize.font12,
   },
   moreInfoWrapper: {
     backgroundColor: color.grayBg,
     borderRadius: 4,
     padding: 16,
     margin: 10,
   },
   infoIcon: {
     width: 20,
     height: 20,
     marginRight: 8,
     tintColor: color.lightYello,
   },
   seperatorWrapper: {
     paddingHorizontal: 20,
     overflow: 'hidden',
   },
 });
} 

export const htmlStyle = (theme, fonts) => {
  const {color} = theme;
  return {
    p: {
      ...fonts.boldFontFamily,
      color: color.lightYello,
      marginBottom: 5,
      lineHeight: 18,
      flex: 1,
    },
  }
};

export default getStyle;
