import { StyleSheet } from 'react-native';

export default (theme, fonts) => {
  const { color } = theme;
  return StyleSheet.create({
    cabDetailsWrapper: {
      paddingVertical: 12,
      paddingHorizontal: 14,
      flexDirection: 'row',
      borderTopWidth: 1,
      borderTopColor: color.lightGray,
    },
    carTypeWrapper: {
      width: 90,
      borderRadius: 8,
      backgroundColor: color.lightBlue22,
      justifyContent: 'center',
      // alignItems:'center'
    },
    carImageStyle: {
      width: 80,
      height: 51,
      alignSelf: 'center',
    },
    rideImageStyle: {
      width: 32,
      height: 20,
      alignSelf: 'center',
      position: 'absolute',
      top: -8,
      zIndex: 11,
    },
    carType: {
      backgroundColor: color.persianGreen,
      paddingHorizontal: 4,
      paddingVertical: 2,
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
    },
    carInnerImageWrapper: {
      width: 12,
      height: 12,
      marginRight: 4,
    },
    starRatingStyle: {
      width: 6,
      height: 6,
      marginLeft: 3,
    },
    ratingWrapper: {
      backgroundColor: color.green,
      borderRadius: 2,
      paddingHorizontal: 4,
      paddingVertical: 2,
      marginLeft: 5,
      flexDirection: 'row',
      alignItems: 'center',
    },
    iconStyle: {
      width: 12,
      height: 12,
    },
  });
};
