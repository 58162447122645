import { Dimensions, Platform } from 'react-native';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';

const windowHeight = Dimensions.get('screen').height;
const windowWidth = Dimensions.get('screen').width;

const styles = {
  overlayWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: windowHeight,
    width: windowWidth,
    elevation: 10,
    zIndex: 100,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    flexDirection: 'row',
    paddingBottom: Platform.OS === 'web' ? 0 : '8%',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
    height: '100%',
    width: '100%',
    zIndex: 1,
  },
  overlayContent: {
    backgroundColor: colors.white,
    padding: 20,
    position: 'relative',
    zIndex: 1,
    width: '100%',
  },
};

export default styles;
