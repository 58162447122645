import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';

const styles = {
  MamiCardGrey: {
    backgroundColor: '#ddd',
    paddingLeft: 10,
    paddingRight: 10,
  },
  CardContentFull: {
    backgroundColor: '#ffffff',
    padding: 15,
    paddingBottom: 0,
  },
  CardContent: {
    backgroundColor: '#ffffff',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 20,
  },
  CardIcon: {
    width: 24,
    height: 24,
    marginRight: 10,
  },
  marL10per: { paddingLeft: '10%' },
  messageIcon: {
    width: 16,
    height: 16,
    marginRight: 8,
  },
  callIcon: { width: 16, height: 16, marginRight: 8 },

  elevation1: {
    backgroundColor: '#ffffff',
    ...getPlatformElevation(1),
  },
};

export default styles;
