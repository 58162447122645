import React from 'react';
import {View, Text, Image, TouchableOpacity} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import PropTypes from 'prop-types';
import getStyles from '../../styles/ImportantInformationCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import HotelBookingDetailsConstant from '../HotelBookingDetailsConstant';
import {handleClickEvent, isIntlHotelBooking, isNotNullAndEmpty, getActionButton} from '../utils/HotelBookingDetailUtil';
import HotelBookingTrackingHelper from '../../HotelBookingTrackingHelper';
import { BLACK_INFO_ICON, WHITE_BG_INFO_ICON } from '../../HotelImageConstants';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
class ImportantInfoCard extends React.Component {

    constructor(props) {
        super(props);
        this.fonts = getFont(true);
        this.styles = getStyles(this.fonts);
    }

    componentDidMount() {
        const trackPageName = isIntlHotelBooking(this.props.response?.lobCode)
        ? 'Mob:customer support:Hotel International:ImportantInformation'
        : 'Mob:customer support:Hotel Domestic:ImportantInformation';
        HotelBookingTrackingHelper.trackLoadEvent(trackPageName, this.props.response);
    }

    handleClick = (action) => {
        handleClickEvent(this.props.card, action, this.props.response,this.props.pageName);
    };

    handleClickIcon = (heading) => {
        this.handleClick({actionFamily: HotelBookingDetailsConstant.ACTION_SEE_PAYMENT_BREAK_UP_EXTRA_CHARGES, actionLabeltext: heading});
    }

    render() {
        const response = this.props.response;
        const importantInformation = response.hotelBookingImportantInfo;

        if(importantInformation && isNotNullAndEmpty(importantInformation.importantInfoList)){
            return (
                <View style={[AtomicCss.whiteCardNew, AtomicCss.marginHz12, AtomicCss.borderRadius16]}>
                    <LinearGradient
                        start={{x: 1.0, y: 0.0}}
                        end={{x: 0.0, y: 1.0}}
                        colors={['#f09819', '#f3d452']}
                        style={this.styles.tipLineGradient}
                    >
                    </LinearGradient>
                    <View style={this.styles.infoCardWrapper}>
                        <View style={this.styles.infoCardHading}>
                                {<Text style={[this.styles.headingTxt]}>{importantInformation.header}</Text>}
                        </View>
                        <View>
                            {importantInformation.importantInfoList && importantInformation.importantInfoList.length > 0 &&
                                importantInformation.importantInfoList.map(info => (
                                    <View style={[AtomicCss.flexRow, AtomicCss.marginBottom10]}>
                                        <View style={this.styles.bulletCircle}></View>
                                        <Text style={[this.styles.infoCardDisc, AtomicCss.alignLeft]}>{info}</Text>
                                    </View>
                                ))
                            }
                            {isNotNullAndEmpty(importantInformation.extraChargesText) && <View style={this.styles.chargesInfoWrapper}>
                                <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween]}>
                                    <Text style={[this.styles.extraChargeTxt]}>{importantInformation.extraChargesText}</Text>
                                    <TouchableOpacity style={AtomicCss.justifyCenter} onPress={() => this.handleClickIcon(importantInformation.extraChargesText)}>
                                        <Image style={this.styles.infoIconStyle} source={BLACK_INFO_ICON}/>
                                    </TouchableOpacity>
                                </View>
                            </View>}
                        </View>
                    </View>
                    {this.props.card && isNotNullAndEmpty(this.props.card.actionList) &&
                        getActionButton(this.props.card, this.props.response, this.handleClick)}
                </View>
            );
        }
        return null;
    }
}

ImportantInfoCard.propTypes = {
    response: PropTypes.object.isRequired,
    card: PropTypes.object.isRequired,
    pageName: PropTypes.string.isRequired
};

export default ImportantInfoCard;
