import React from 'react';
import { View, Text } from 'react-native';
import { getStaticData } from '../staticData/staticData';
import useCountDown from './Hooks/useCountdown';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';

const DaysTimeCountDown = ({initialDate}) => {

    const { daysText } = getStaticData();
    const [days, hours, minutes, seconds] = useCountDown(initialDate);

    const getFormattedValue = (value) => {
        return value >= 10 ? value : `0${value}`;
    };

    if (days + hours + minutes + seconds <= 0) {
        return <Text>00h : 00m : 00s</Text>;
    }

    return (
        <View>
            {days >= 1 ? (
               <Text style={[AtomicCss.flexRow]}>{getFormattedValue(days)} {" "}{daysText}{" "}</Text> 
            ) : (
                <Text>
                    {hours > 0 && (
                        <Text>
                            {getFormattedValue(hours)}h {" "}:{" "}
                        </Text>
                    )}
                    {getFormattedValue(minutes)}m {" "}: {getFormattedValue(seconds)}s
                </Text>
            )}
        </View>
    );
};

export default DaysTimeCountDown;