import React from 'react';
import { View, Text, Image } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import createStyles from "./impInstructionsCss";
import LinearGradient from 'react-native-linear-gradient';
import TermsAndConditionCard from '../TermsAndConditionsCard';
import isEmpty from 'lodash/isEmpty';
import { INFO_ICON } from '../../../../Utils/tripGuaranteeImageConstants';
import { getActionButton, getButtonStyle, voucherTnCHandler } from '../../../../Utils/TgsBookingDetailUtil';
import { getFont } from '../../../../../PostSalesStyles';
import { useTheme } from '../../../../../theme/theme.context';
import TGSBookingTrackEventHelper from '../../../../Utils/TGSBookingTrackEventHelper';
import { CLICK_TYPE, PAGE_NAME } from '../../../../Utils/TGSBookingConstants';

const ImprotantInstructions = ({ card, response }) => {
    const { heading, subHeading, actionList } = card || {};
    const { extraPolicies } = response || {}

    const handleClick = () => {
        const {viewTermsAndConditions} = extraPolicies || {};
        TGSBookingTrackEventHelper.trackClickEvents(PAGE_NAME.TRIP_DETAILS, CLICK_TYPE.TERMS_AND_CONDITION_CLICK)
        if(!isEmpty(viewTermsAndConditions))
        voucherTnCHandler(viewTermsAndConditions);
    }
    const fonts = getFont();
    const { psTheme: theme } = useTheme();
    const styles = createStyles(theme, fonts);
    const btnFont = fonts.boldFontFamily;
    return (
        <View style={styles.topSection}>
            <LinearGradient
                colors={[theme.color.goldenYellow, theme.color.yellow]}
                start={{ x: 0, y: 0 }}
                end={{ x: 1.0, y: 0 }}
                style={styles.gradientStyle}
            >
            </LinearGradient>
                <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
                    <View style={[styles.iconWrapper, AtomicCss.paddingLeft10]}>
                        <Image style={styles.cardIconStyle} source={INFO_ICON} />
                    </View>
                    {!!heading && <Text style={[AtomicCss.font16, fonts.blackFontFamily, AtomicCss.blackText]}>{heading}</Text>}
                </View>
            {!isEmpty(extraPolicies) && <TermsAndConditionCard extraPolicies={extraPolicies} useToggle={false} />}
            {!!subHeading && <Text style={[AtomicCss.font10, AtomicCss.paddingHz10, AtomicCss.greyText]}>{subHeading}</Text>}
            {!isEmpty(actionList) && <View style={!isEmpty(actionList) && [getButtonStyle(actionList), AtomicCss.transperantBgrnd]}>
                {getActionButton(card, response, handleClick, btnFont)}
            </View>
            }
        </View>

    )
}

export default ImprotantInstructions