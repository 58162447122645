import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';

const styles = {
  whiteCard: {
    backgroundColor: '#ffffff',
    ...getPlatformElevation(2),
    marginBottom: 3,
    padding: 16,
  },
  tag: {
    backgroundColor: '#ffedd1',
    borderRadius: 2,
    paddingVertical: 3,
    paddingHorizontal: 6,
    alignSelf: 'flex-start',
    marginBottom: 16,
  },
  tagTxt: {
    color: '#cf8100',
  },
  cardTitle: {
    color: colors.black,
    marginBottom: 10,
  },
  cardDesc: {
    color: colors.black,
    marginBottom: 10,
  },
  uploadCard: {
    backgroundColor: '#ffffff',
    ...getPlatformElevation(2),
    marginBottom: 3,
    padding: 12,
    borderRadius: 4,
  },
  uploadCardHeading: {
    color: colors.black,
    marginBottom: 4,
  },
  uploadCardInfo: {
    color: '#747474',
  },
  lftDtls: {
    flex: 1,
  },
  linkWrpper: {
    top: 10,
  },
  linkTxt: {
    color: colors.azure,
  },
  uploadIcon: {
    width: 24,
    height: 24,
    marginRight: 12,
  },
  boldFont: {},
  blackFont: {},
  blackTxt: {
    color: colors.black,
  },
  defaultTxt: {
    color: colors.defaultTextColor,
  },
  progressBarWrppaer: {
    marginTop: 10,
  },
  closeIcon: {
    width: 14,
    height: 14,
    marginLeft: 10,
  },
  progressInfoTxt: {
    color: '#747474',
    fontSize: 12,
  },
  failedTxt: {
    color: '#e53442',
  },
  deleteIconStyle: {
    width: 16,
    height: 18,
  },
  successIconStyle: {
    width: 14,
    height: 14,
    marginLeft: 5,
  },
  attachedProofWrapper: {
    padding: 0,
  },
  uploadproffImg: {
    width: '100%',
    height: 120,
  },
  attachedProof: {
    padding: 16,
  },
};

export default styles;
