import React from 'react';
import LinearGradient from 'react-native-linear-gradient';
import {BackHandler, Text, TouchableOpacity, View} from 'react-native';
import logAction from '@mmt/legacy-commons/Helpers/actionsLogger';
import Actions from '../../../../../navigation/postSalesNavigation';
import { getFont } from '../../../../../PostSalesStyles';
import { useTheme } from '../../../../../theme/theme.context';
import createStyles from "./thankyoucss";


function ThankYou ({bookingId, response}) {

  const fonts = getFont()
  const {psTheme : theme} = useTheme();
  const styles = createStyles(theme,fonts);

  const specialClaimData = response.specialClaimDataList[0];

  const goToBookingDetails = () => {
    const params = {};
    const refresh = {};
    params.BOOKING_ID = bookingId;
    refresh.isRefresh = true;
    params.refresh = refresh;
    Actions.cabBookingDetail({data: params});
  };
  
  const onHardBackPress = () => {
    goToBookingDetails();
    logAction('HardwareBackPressed');
    return true;
  };

  React.useEffect(() => {
    BackHandler.addEventListener('hardwareBackPress', onHardBackPress);

    return () => {
      BackHandler.removeEventListener('hardwareBackPress', onHardBackPress);
    }
  }, []);
  

  return (
    <View style={styles.ErrorStateWrapper}>
      <View>
        <Text style={[styles.ErrorPageText, styles.boldFont]}>{specialClaimData.claimSubmitHeader}</Text>
        <Text style={[styles.AlignCenter, styles.lineHt22, styles.mar20Bt]}>
          {specialClaimData.claimSubmitMessage}
        </Text>
      </View>
      <TouchableOpacity onPress={goToBookingDetails}>
        <LinearGradient
          start={{
          x: 1.0,
          y: 0.0
        }}
          end={{
          x: 0.0,
          y: 1.0
        }}
          colors={['#065af3', '#53b2fe']}
          style={[styles.CommonFillBtn]}
        >
          <Text
            style={[styles.WhiteText, styles.CenterText, styles.blackFont, styles.transperantBgrnd]}
          >{specialClaimData.updatedBooking}
          </Text>
        </LinearGradient>
      </TouchableOpacity>
    </View>
  );
}
export default ThankYou;

