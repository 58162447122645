import React from 'react';
import PropTypes from 'prop-types';
import {Image, Text, View, I18nManager} from 'react-native';
import {Actions} from 'react-native-router-flux';
import Card from '../Card';
import {colors, fonts} from '../../../Styles/globalStyles';
import TouchableRipple from '../TouchableRipple';

// FIXME @rajeshbatth web impl

// eslint-disable-next-line import/no-unresolved
const backIcon = require('@mmt/legacy-assets/src/ic_back_arrow.webp');

const SimpleHeader = ({
  icon, title, iconPress, elevation, textFont, textColor, textSize, rightIcon, rightIconPress, subtitle
}) => {
  const subtitleProp = {...defaultSubtitle, ...subtitle};
  return (
    <Card style={{marginHorizontal: 0, marginVertical: 0}} elevation={elevation} noBorder>
      <View style={{
        flexDirection: 'row',
        alignItems: 'center',
        height: 56
      }}
      >
        <TouchableRipple onPress={iconPress}>
          <View style={{padding: 16}}>
            <Image source={icon} style={[{ transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }] }, {width: 16, height: 16, resizeMode: 'center'}]} />
          </View>
        </TouchableRipple>
        <View style={{
          flex: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginRight: rightIcon ? 0 : 48
        }}
        >
          <Text style={{
            textAlign: 'center', fontSize: textSize, fontFamily: textFont, color: textColor
          }}
          >
            {title}
          </Text>
          {subtitleProp.text != null &&
            <Text style={{
              textAlign: 'center', fontSize: subtitleProp.size, fontFamily: subtitleProp.font, color: subtitleProp.color
            }}
            >
              {subtitleProp.text}
            </Text>
          }
        </View>
        {
          rightIcon &&
          <TouchableRipple onPress={rightIconPress}>
            <View style={{
              width: 48, height: 44, flex: 0, justifyContent: 'center', alignItems: 'center'
            }}
            >
              <Image style={{width: 24, height: 24}} source={rightIcon} />
            </View>
          </TouchableRipple>
        }
      </View>
    </Card>);
};

SimpleHeader.propTypes = {
  icon: Image.propTypes.source,
  rightIcon: Image.propTypes.source,
  elevation: PropTypes.number,
  title: PropTypes.string.isRequired,
  iconPress: PropTypes.func,
  rightIconPress: PropTypes.func,
  textFont: PropTypes.string,
  textColor: PropTypes.string,
  textSize: PropTypes.number,
  subtitle: PropTypes.object
};

SimpleHeader.defaultProps = {
  icon: backIcon,
  rightIcon: null,
  elevation: 4,
  iconPress: () => Actions.pop(),
  rightIconPress: () => {},
  textFont: fonts.medium,
  textColor: colors.defaultTextColor,
  textSize: 18,
  subtitle: defaultSubtitle
};

const defaultSubtitle = {
  text: null,
  font: fonts.regular,
  color: colors.lightTextColor,
  size: 12
};

export default SimpleHeader;
