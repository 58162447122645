import React from 'react';
import {View, Text, Image} from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import styles from '../GiftCardDetailsCss';
import ShareLinkCard from './ShareLinkCard';
import YellowGiftCard from './YellowGiftCard';
import TrackingDetailsCard from './TrackingDetailsCard';
import {isNotNullAndEmpty, validateTrackingDetails} from '../GiftCardBookingDetailUtil';
import GiftCardBookingDetailsConstant from '../GiftCardBookingDetailsConstant';

const GiftCardMainCard = ({ response, pageName , handleClick}) => {

	const {giftCardDetails} = response.giftCardBookingInfo;
	const getTitle = (name, value, quantity) => {
		if(quantity) {
			return name + GiftCardBookingDetailsConstant.WORTH + value + GiftCardBookingDetailsConstant.CROSS + quantity;
		} else {
			return name + GiftCardBookingDetailsConstant.WORTH + value;
		}
	}

	const renderDeliveryModeCard = (giftCardBookingInfo, giftCardDetails) => {
		const { trackingDetails } = giftCardBookingInfo;

		if (giftCardBookingInfo && giftCardBookingInfo.cardInfo && giftCardDetails && giftCardDetails.deliveryMode && [GiftCardBookingDetailsConstant.DELIVERY_MODES.EMAIL,GiftCardBookingDetailsConstant.DELIVERY_MODES.WHATSAPP].includes(giftCardDetails.deliveryMode)) {
			return (<YellowGiftCard
				GiftCardSuccessfully={true}
				giftCardBookingInfo={giftCardBookingInfo}
				pageName={pageName}
				handleClick={handleClick}
			/>);
		} else if (giftCardDetails && giftCardDetails.deliveryMode && giftCardDetails.deliveryMode === GiftCardBookingDetailsConstant.DELIVERY_MODES.COURRIER) {
			return (
				<>
					{ validateTrackingDetails(trackingDetails) ? <TrackingDetailsCard trackingDetails={trackingDetails} />
						: <YellowGiftCard
							GiftCardShipped={true}
							giftCardBookingInfo={giftCardBookingInfo}
							pageName={pageName}
						/>}
				</>
			);
		}
	}

	if (!response.giftCardBookingInfo || !giftCardDetails) {
		return null;
	}

	return (
		<View style={[styles.MamiCard, styles.mar10Bt]}>
			<View style={[AtomicCss.whiteCardSection, AtomicCss.paddingAll16]}>
				<View style={[AtomicCss.flexRow, AtomicCss.marginBottom5]}>
					<View style={[AtomicCss.flex1]}>
						<Text style={[AtomicCss.font16, AtomicCss.blackFont, AtomicCss.blackText, AtomicCss.marginBottom5]}>
							{getTitle(giftCardDetails.name, giftCardDetails.value, giftCardDetails.quantity)}
						</Text>
						<Text style={[styles.gcSummarySuccessText, AtomicCss.font14, AtomicCss.boldFont, AtomicCss.marginBottom16]}>
							{giftCardDetails.issuedText}
						</Text>
					</View>
					{isNotNullAndEmpty(giftCardDetails.imgUrl) && <Image style={styles.imageStyle} source={{ uri: giftCardDetails.imgUrl }} />}
				</View>
				{renderDeliveryModeCard(response.giftCardBookingInfo, giftCardDetails)}
			</View>
		</View>
	)
}

export default GiftCardMainCard;