import {
  DATE_MONTH,
  POSTSALES_DATE_TIME,
  reformatDateString,
} from '@mmt/legacy-commons/Helpers/dateTimehelpers';
import myTripsNavigation from '../../../navigation/navigation';
import { titleCase } from '@mmt/legacy-commons/Helpers/stringHelpers';
import upcomingBookingIcon from '@mmt/legacy-assets/src/postSales/summary/upcomingCabIocn.webp';
import completedBookingIcon from '@mmt/legacy-assets/src/postSales/summary/completedCabIcon.webp';
import cancelledBookingIcon from '@mmt/legacy-assets/src/postSales/summary/cancelledCabIcon.webp';
import failedBookingIcon from '@mmt/legacy-assets/src/postSales/summary/failedCabIcon.webp';
import { getStaticData } from '../../../../staticData/staticData';
import { getDateTimeWithPaxInfo, unsuccessfulBookingTextHandler } from "../../../data/tripListManager";
import { msrUpdateAction  } from '../../../../utils/MsrUtils';
import { getNeedHelpData, getTalkToUsDetails } from '../../../utils';
import isEmpty from 'lodash/isEmpty';
function getCabsSummaryData(data, msrTicket) {
  const staticData = getStaticData();
  const departDate = reformatDateString(data.minDeptDate, POSTSALES_DATE_TIME, DATE_MONTH);
  const [carDetails] = data.carDetails;
  const multiCityInfo = data.multicitytransport;
  const multiCityInfoFlag = !isEmpty(multiCityInfo);
  let cabType = `${carDetails.tripType} - ${carDetails.vehicleType}`;
  const pageData = {
    fromMyTrips: true,
  };

  const openDetails = () => {
    myTripsNavigation.openCabBookingDetail(data, pageData);
  };
  const actions = [];
  const failedBooking = data.status === 'FAILED';
  const msrAction = msrUpdateAction(msrTicket)
  if (msrAction) {
    actions.push(msrAction)
  }
  const showUnconfirmedText = data.status === 'UPCOMING' && data.bookingSubStatus === '2';
  const summary = `${carDetails.carCapacity} ${staticData.seatsText}`;
  let dateTime = `${departDate} • ${summary}`;
  const paxInfo = titleCase(`${data.leadPaxFirstName} ${data.leadPaxLastName}`);
  let iconUrl = '';

  switch (data.status) {
    case 'UPCOMING':
      iconUrl = upcomingBookingIcon;
      break;
    case 'COMPLETED':
      iconUrl = completedBookingIcon;
      break;
    case 'CANCELLED':
      iconUrl = cancelledBookingIcon;
      dateTime = getDateTimeWithPaxInfo(data, staticData.cancelledText, dateTime, summary);
      break;
    case 'FAILED':
      iconUrl = failedBookingIcon;
      dateTime = getDateTimeWithPaxInfo(data) || unsuccessfulBookingTextHandler(dateTime);
      cabType = staticData.bookingSummaryText.additionalText.cabBookingFailedText;
      break;
    default:
      iconUrl = upcomingBookingIcon;
      break;
  }

  const isHourlyRental = carDetails.tripType && (carDetails.tripType.toUpperCase() === 'HOURLY RENTALS');
  
  const talkToUsDetails = getTalkToUsDetails(
    data.carDetails?.[0]?.productCode,
    data.uuid,
    false,
    true
  );
  const needHelpData = getNeedHelpData(msrTicket, data.bookingID, data.needHelpData, data.showQuickResolve, talkToUsDetails);

  const getMultiCityFrom = () => {
    return carDetails.originCityName + `...\+` + `${multiCityInfo.length}`;
  }
  return {
    dateTime,
    iconUrl: iconUrl,
    from: isHourlyRental ? carDetails.originCityName + ' City' : multiCityInfoFlag ? getMultiCityFrom() : carDetails.originCityName,
    to: carDetails.destinationCityName,
    paxInfo,
    isHourlyRental,
    cabType: titleCase(cabType),
    persuasions: [],
    actions,
    status: data.status,
    showUnconfirmedText,
    showRoundTripArrowIcon: carDetails.tripType === 'Outstation Return',
    openDetails,
    failedBooking,
    needHelpData,
  };
}

export default getCabsSummaryData;
