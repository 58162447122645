import colors from './colors';

export type FontSizeType = 'xs' | 'sm' | 'md' | 'reg' | 'lg' | 'xl' | 'xxl';

export const fontSizes: Record<FontSizeType, number> = {
  xs: 8,
  sm: 10,
  md: 12,
  reg: 14,
  lg: 16,
  xl: 20,
  xxl: 24,
};

export type FontFamily =
  | 'black'
  | 'bold'
  | 'semiBold'
  | 'regular'
  | 'medium'
  | 'light'
  | 'italic'
  | 'italicBold';

export const fontFamilies: Record<FontFamily, string> = {
  black: 'Lato-Black',
  bold: 'Lato-Bold',
  italic: 'Lato-Italic',
  regular: 'Lato-Regular',
  medium: 'Lato-Medium',
  light: 'Lato-Light',
  semiBold: 'Lato-Semibold',
  italicBold: 'Lato-BoldItalic',
};

export const buttonStyle = {
  color: colors.white,
  fontSize: 16,
  fontFamily: fontFamilies.regular,
};
