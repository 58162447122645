import React, { Fragment } from 'react';
import { View, Text, Image, StyleSheet } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {
  NAME_CHANGE_ARROW,
  GREEN_TICK_ICON2,
  CROSS_RED_CIRCLE_ICON,
} from '../../../../FlightImageConstants';
import { getFont } from '../../../../../PostSalesStyles';

const SpellingCorrectionSection = ({
  content: {
    heading,
    additionalText: { consideredByAirlineText, nameChangeText, notPermittedText },
  },
}) => {
  const { fsStyle, ...fonts } = getFont(true);
  return (
    <Fragment>
      <Text
        style={[
          fonts.blackFontFamily,
          fsStyle.font12,
          AtomicCss.defaultText,
          AtomicCss.marginBottom4,
        ]}
      >
        {heading || 'SPELLING CORRECTION'}
      </Text>
      <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom10]}>
        <Image style={styles.overlayIcon} source={GREEN_TICK_ICON2} />
        <Text style={[AtomicCss.flex1, fsStyle.font12, AtomicCss.greenText, fonts.boldFontFamily]}>
          {consideredByAirlineText || 'Considered by Airline'}
        </Text>
      </View>
      <View style={[styles.nameCorrectionRow, AtomicCss.marginBottom4]}>
        <Text
          style={[AtomicCss.defaultText, fsStyle.font14, fonts.regularFontFamily, styles.width100]}
        >
          P<Text style={[AtomicCss.blackText, fonts.boldFontFamily, styles.underline]}>ar</Text>teek
          Mittal
        </Text>
        <Image style={[styles.arrowIcon]} source={NAME_CHANGE_ARROW} />
        <Text style={[AtomicCss.defaultText, fsStyle.font14, fonts.regularFontFamily]}>
          P<Text style={[AtomicCss.greenText, fonts.boldFontFamily, styles.underline]}>ra</Text>teek
          Mittal
        </Text>
      </View>
      <View style={[styles.nameCorrectionRow, AtomicCss.marginBottom20]}>
        <Text
          style={[AtomicCss.defaultText, fsStyle.font14, fonts.regularFontFamily, styles.width100]}
        >
          A<Text style={[AtomicCss.blackText, fonts.boldFontFamily, styles.underline]}>ak</Text>ash
          Garg
        </Text>
        <Image style={[styles.arrowIcon]} source={NAME_CHANGE_ARROW} />
        <Text style={[AtomicCss.defaultText, fsStyle.font14, fonts.regularFontFamily]}>
          A<Text style={[AtomicCss.greenText, fonts.boldFontFamily, styles.underline]}>ka</Text>sh
          Garg
        </Text>
      </View>
      <Text
        style={[
          fonts.blackFontFamily,
          fsStyle.font12,
          AtomicCss.defaultText,
          AtomicCss.marginBottom4,
        ]}
      >
        {nameChangeText || 'NAME CHANGE'}
      </Text>
      <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom10]}>
        <Image style={styles.overlayIcon} source={CROSS_RED_CIRCLE_ICON} />
        <Text style={[AtomicCss.flex1, fsStyle.font12, AtomicCss.redText, fonts.boldFontFamily]}>
          {notPermittedText || 'Not permitted'}
        </Text>
      </View>
      <View style={[styles.nameCorrectionRow, AtomicCss.marginBottom30]}>
        <Text
          style={[AtomicCss.defaultText, fsStyle.font14, fonts.regularFontFamily, styles.width100]}
        >
          <Text style={[AtomicCss.blackText, fonts.boldFontFamily, styles.underline]}>
            Sanjana{' '}
          </Text>
          Mittal
        </Text>
        <Image style={[styles.arrowIcon]} source={NAME_CHANGE_ARROW} />
        <Text style={[AtomicCss.defaultText, fsStyle.font14, fonts.regularFontFamily]}>
          <Text style={[AtomicCss.redText, fonts.boldFontFamily, styles.underline]}>Prateek </Text>
          Mittal
        </Text>
      </View>
    </Fragment>
  );
};

const styles = StyleSheet.create({
  overlayIcon: {
    height: 16,
    width: 16,
    marginRight: 4,
  },
  arrowIcon: {
    width: 10,
    height: 8,
    marginRight: 12,
  },
  nameCorrectionRow: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#f2f2f2',
    paddingHorizontal: 20,
    paddingVertical: 12,
    borderRadius: 4,
  },
  underline: {
    textDecorationLine: 'underline',
  },
  width100: {
    width: 100,
  },
});
export default SpellingCorrectionSection;
