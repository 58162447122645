import React from 'react';
import {Text, TouchableOpacity} from 'react-native';
import getStyles from '../../details/components/HotelMamiCss';
import LinearGradient from 'react-native-linear-gradient';
import PropTypes from 'prop-types';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import ButtonWrapper from 'apps/post-sales/src/Common/ButtonWrapper';


class CheckAvlBtn extends React.Component {
    static navigationOptions = {
      header: null
    };
    constructor(props) {
      super(props);
      this.fonts = getFont(true);
      this.styles = getStyles(this.fonts);
    }
    render() {
      return (
        <ButtonWrapper
            clickHandler={() => this.handleClick()}
            btnText={this.props.buttonText}
        />
        
      );
    }

    handleClick() {
      this.props.onButtonClick(this.props);
    }
}

CheckAvlBtn.propTypes =
    {
      onButtonClick: PropTypes.func.isRequired,
      buttonStyle: PropTypes.array.isRequired,
      buttonText: PropTypes.string.isRequired
    };

export default CheckAvlBtn;
