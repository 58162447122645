import React from 'react';
import { View, Text, TouchableOpacity, Image } from 'react-native';
import styles from './boardingDropInfoCss';
import {closeIcon, notificationIcon} from '../../BusImageConstants';
import { label } from '@mmt/post-sales/src/staticData/staticData';

class Notification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: true
    };
  }

  hideNotification = () => {
    this.setState({
      isVisible: false
    });
  }

  renderYellowNotification = () => {
    return (
      <View style={[styles.notification, styles.notificationYellow]}>
        <Text style={[styles.notificationText, styles.notificationYellowText]}>{label('bus.trackDetail.tentativeMap')}</Text>
        <TouchableOpacity onPress={this.hideNotification} style={styles.notificationClose}>
          <Image source={closeIcon} style={styles.closeIcon} />
        </TouchableOpacity>
      </View>
    );
  }

  renderRedNotification = () => {
    return (
      <View style={styles.notification}>
        <Image source={notificationIcon} style={styles.notifictionIcon} />
        <Text style={styles.notificationText}>{label('bus.trackDetail.unableToFetch')}</Text>
        <TouchableOpacity onPress={this.hideNotification} style={styles.notificationClose}>
          <Image source={closeIcon} style={styles.closeIcon} />
        </TouchableOpacity>
      </View>
    );
  }

  render() {
    if (this.state.isVisible) {
      return this.props.theme === 'yellow' ? this.renderYellowNotification() : this.renderRedNotification()
    }
    return null;
  }
}

export default Notification;
