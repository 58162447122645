import { StyleSheet } from "react-native";

export default (theme, fonts) => StyleSheet.create({
  filterItem: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingLeft: 15,
    paddingRight: 23,
    borderTopWidth: 1,
    borderTopColor: theme.color.lightGrey,
  },
  SortIconWrapper: {
    width: 50,
  },
  filterIcon: {
    height: 25,
    width: 25,
  },
  PricefilterIcon: {
    height: 17,
    width: 11,
    marginLeft: 7,
  },
  departurefilterIcon: {
    height: 15,
    width: 24,
  },
  arrivalfilterIcon: {
    height: 19,
    width: 23,
  },
  DurationfilterIcon: {
    height: 21,
    width: 18,
  },
  leftView: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 0.6,
    paddingVertical: 15,
  },
  rightView: {
    flex: 0.4,
  },
  actionRow: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 20,
    paddingRight: 50,
    position: 'relative',
  },
  tickIcon: {
    marginLeft: 42,
    height: 16,
    width: 15,
    position: 'absolute',
    right: 0,
  },
  labelLarge: {
    fontSize: fonts.fontSize.font14,
    ...fonts.regularFontFamily,
    color: theme.color.lightTextColor,
  },
  labelSmall: {
    fontSize: fonts.fontSize.font12,
    ...fonts.regularFontFamily,
    color: theme.color.lightTextColor,
  },
  labelSmallActive: {
    color: theme.color.azure,
  },
});
