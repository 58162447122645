const priceImg = require('@mmt/legacy-assets/src/price.webp');
const depImg = require('@mmt/legacy-assets/src/departure.webp');
const arrImg = require('@mmt/legacy-assets/src/arrival.webp');
const durImg = require('@mmt/legacy-assets/src/duration.webp');

const mImg = require('@mmt/legacy-assets/src/ic_morning_blue.webp');
const mAImg = require('@mmt/legacy-assets/src/ic_morning_blueActive.webp');
const nImg = require('@mmt/legacy-assets/src/ic_noon_grey.webp');
const nAImg = require('@mmt/legacy-assets/src/ic_noon_greyActive.webp');
const eImg = require('@mmt/legacy-assets/src/ic_evening_grey.webp');
const eAImg = require('@mmt/legacy-assets/src/ic_evening_greyActive.webp');
const niImg = require('@mmt/legacy-assets/src/ic_night_grey.webp');
const niAImg = require('@mmt/legacy-assets/src/ic_night_greyActive.webp');
export const OUT_OF_POLICY = 'OUT OF POLICY';

export const SORTERS = [
  {
    sortIcon: null,
    label: 'Price',
    type: 'price',
    filterTypes: [
      'Lowest first'
    ]
  },
  {
    sortIcon: depImg,
    label: 'Departure Time',
    type: 'departure',
    filterTypes: [
      'Earliest first',
      'Latest first'
    ]
  },
  {
    sortIcon: durImg,
    label: 'Duration',
    type: 'duration',
    filterTypes: [
      'Shortest first'
    ]
  }
];

export const SORT_STYLE_MAP = {
  price: 'PricefilterIcon',
  departure: 'departurefilterIcon',
  arrival: 'arrivalfilterIcon',
  duration: 'DurationfilterIcon'
};

export const FILTERS = {
  stops: {
    name: 'Number of stops from Bangkok',
    list: [
      {
        stopCount: '0',
        stopTxt: 'Non-Stop'
      },
      {
        stopCount: '1',
        stopTxt: 'Stop'
      },
      {
        stopCount: '2+',
        stopTxt: 'Stops'
      }
    ]
  },
  departure: {
    name: 'Departure time from New Delhi',
    list: [
      {
        DepImg: mImg,
        DepTime: 'Before 6 AM',
        iconStyle: 'DepmorningIcon',
        DepImgActive: mAImg
      },
      {
        DepImg: nImg,
        DepTime: '6 AM - 12 Noon',
        iconStyle: '',
        DepImgActive: nAImg
      },
      {
        DepImg: eImg,
        DepTime: '12 Noon - 6 PM',
        iconStyle: '',
        DepImgActive: eAImg
      },
      {
        DepImg: niImg,
        DepTime: 'After 6 PM',
        iconStyle: '',
        DepImgActive: niAImg
      }
    ]
  }
};
