// import "./polyfills";
import "./WebModules";
import React, {useEffect} from "react";
import { AppRegistry, View } from "react-native";
import Raven from "raven-js";
import WebRouter from "./router/WebRouter";
import { initAbConfig } from "@mmt/legacy-commons/Native/AbConfig/AbConfigModule";
import adsTechConfig from "./adsTechConfig";
import { setConfig } from "ad-react-wrapper";
import { fetchInterceptor } from "@rn/apps/post-sales/src/utils/fetchInterceptor";
import Toast from 'react-native-fast-toast';
import {ToastRef} from './components/ToastWeb.js';
import usePokus from './components/usePokus';
import RNToast from '@mmt/legacy-commons/Common/Components/Toast2';
import ProgressView from "./components/ProgressView";
import { _getLangCookie, handleDomainRedirection } from "./webUtils";
import { setUserSelectedLanguage } from '@mmt/legacy-commons/AppState/AppUserConfig';
import EnvVariable from 'core-ui-lib/envVariable';
import { handleLocalStorageInGdpr } from 'MMT-UI/storages/client/localStorage';
import { initializeLocaleSetting } from 'MMT-UI/helpers/localeHelper/LocaleSetting';

setConfig(adsTechConfig);
fetchInterceptor();

const App = () => {
    useEffect(()=> {
      if (window) {
        initializeLocaleSetting().then(res => {
          window.localeSettings = res || {};
        });
      }
     
      handleDomainRedirection();
      handleLocalStorageInGdpr();
      EnvVariable.setEnvVariable({brand: 'MMT',platform:'MWEB'});
  },[])
  const [isPokusLoading] = usePokus();
  if (isPokusLoading) {
    return <ProgressView/>
  }
 
  setUserSelectedLanguage(_getLangCookie());
  return (
    <View style={{ flex: 1 }}>
      <WebRouter />
      <Toast ref={ToastRef} />
      <RNToast />
    </View>
  )
};

initAbConfig().finally(() => {
  const rootElement = window.document.getElementById("react-root");
  rootElement.innerHTML = "";
  AppRegistry.registerComponent("MmtReactNative", () => App);
  AppRegistry.runApplication("MmtReactNative", {
    rootTag: rootElement,
  });
});

if (window) {
  if ("serviceWorker" in window.navigator) {
    window.addEventListener("load", () => {
      window.navigator.serviceWorker
        .register("/rn/service-worker.js")
        .then((registration) => {
          console.log("SW registered: ", registration);
        })
        .catch((registrationError) => {
          console.log("SW registration failed: ", registrationError);
        });
    });
  }

}

// if (!__DEV__) {
//   try {
//     Raven.config(
//       "https://3fde8daf8d044272abb0a4c79733e951@bugs.goibibo.com/143"
//     ).install();
//   } catch (e) {
//     console.error("error while loading raven", e);
//   }
// }

function landscapeLock() {
  const winW = window.innerWidth;
  const landscapeOnlyEl = window.document.getElementById("landscape_screen");
  const rootElement = window.document.getElementById("react-root");
  if (winW > 480) {
    rootElement.style.display = "none";
    landscapeOnlyEl.style.display = "flex";
  } else {
    rootElement.style.display = "flex";
    landscapeOnlyEl.style.display = "none";
  }
}
window.onresize = landscapeLock;
landscapeLock();

try {
  navigator.getInstalledRelatedApps().then((relatedApps) => {
    relatedApps.forEach((app) => {
      console.log("getInstalledRelatedApps", app.id, app.platform, app.url);
    });
  });
} catch (e) {
  console.log("error in getInstalledRelatedApps", e);
}
