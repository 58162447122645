import React, { useEffect, useRef, useCallback } from 'react';
import { StyleSheet, Text, TouchableOpacity, View, Animated } from 'react-native';
import { colors, fonts } from '@mmt/legacy-commons/Styles/globalStyles';

// Please refer design link: https://app.zeplin.io/project/5cda55abbe9abf68393f4ea7/screen/5cda5612ab2e233467f9c3e9

const SnackBarCard = ({actionHandler, actionLabel, message, closeErrorCard}) => {
    const animatedBottom = useRef(new Animated.Value(-400)).current;
    const animateIn = useCallback(() => {
        Animated.timing(animatedBottom, {
            toValue: 0,
            duration: 1000,
            useNativeDriver: false
        }).start()
    });
    const animateOut = useCallback(() => {
        Animated.timing(animatedBottom, {
            toValue: -400,
            duration: 1000,
            useNativeDriver: false
        }).start()
    });
    useEffect(() => {
        animateIn();
        setTimeout(closeErrorCard, 9000);
        setTimeout(animateOut, 8000);
    }, [animateIn, animateOut, closeErrorCard]);
    return (
        <Animated.View style={[styles.container, {left: animatedBottom}]}>
            <View style={styles.content}>
                <Text style={[styles.messageStyle]}>{message}</Text>
                <TouchableOpacity onPress={actionHandler}>
                    <Text style={[styles.actionBtn]}>{actionLabel}</Text>
                </TouchableOpacity>
            </View>
        </Animated.View>
    )
}

const styles = StyleSheet.create({
    container: {
        width: '90%',
        height: 68,
        paddingHorizontal: 20,
        paddingVertical: 10,
        backgroundColor: colors.defaultTextColor,
        position: 'absolute',
        marginHorizontal: '5%',
        bottom: 10,
        zIndex: 100,
        borderRadius: 6,
        justifyContent: 'center'
    },
    content: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    messageStyle: {
        color: colors.white,
        flex: 0.9
    },
    actionBtn: {
        alignSelf: 'center',
        padding: 10,
        color: colors.lightGreen4,
    }
})

export default SnackBarCard;
