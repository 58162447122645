import {isUserLoggedIn, getMmtAuth} from "../Native/UserSession/UserSessionModule";
import  {setConfig as setConfigWeb} from 'ad-react-wrapper';

const configWeb = {
  userCallbackForView(id, adResponse) {
    console.log('view callback');
  },

  userClickHandlerForClick(id, adResponse) {
    console.log('click callback');
  },

  onError(error, id) {
    console.log('error callback');
  },

  ORG: 'MMT',
  AUTH_COOKIE_KEY: 'mmt-auth',
  mode: 'PROD',
  API_CALL: 'SINGLE'
};

const getWebConfigModule = async () => {
  try {
    const userLoggedIn = await isUserLoggedIn();
    if (userLoggedIn) {
      const mmtAuth = await getMmtAuth();
      configWeb.AUTH_COOKIE_KEY = mmtAuth;
    }
    return configWeb;
  } catch (e) {
    console.log(e);
    return configWeb;
  }
};
export default async () => {
  try {
    const config = await getWebConfigModule();
    setConfigWeb(config);
  } catch (error) {
    console.log(error);
  }
};
