import { StyleSheet } from "react-native";
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
export default (theme, fonts) =>StyleSheet.create({
    CardContentFull: {
        backgroundColor: theme.color.white,
        paddingRight: 0,
        paddingBottom: 0,
        borderBottomWidth: 1,
        borderBottomColor: theme.color.grey24,
      },
      cardHeadingWrapper: {
        marginBottom: 2,
        paddingVertical: 10,
        borderBottomWidth: 1,
        borderBottomColor: theme.color.lightGrey3,
        backgroundColor: theme.color.grey22,
      },
      cardHeading: {
        color: theme.color.black,
        textAlign: 'center',
        fontSize: 20,
      },
      fareBreakupInnerSection: {
        ...getPlatformElevation(1),
        marginBottom: 1,
        marginTop: -1,
        backgroundColor: theme.color.grey22,
      },
    fareBreakupInnerRowSection: {
        borderBottomWidth: 1,
        borderBottomColor: theme.color.grayBg,
        paddingVertical: 15,
        paddingHorizontal: 16,
      },
      totalPrice: {
        padding: 16,
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 2,
      },
      totalPricetxt: {
        fontSize: 16,
        color: theme.color.defaultTextColor,
      },
      fareBkHeadingTxt: {
        color: theme.color.black,
        fontSize: 14,
      },
});