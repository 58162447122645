import React from 'react';
import { View } from 'react-native';
import { getStaticData } from '../../../../../../staticData/staticData';
import styles from '../FlightDtlsCss';
import LinkBtn from '../../../../../../Common/LinkBtn';
import { addNewLineInMiddle } from '../../../../../FlightBookingUtil';
import FlightConst from '../../../FlightBookingDetailsConstant';
import {
  WEB_CHECKIN_ICON,
  BAG_ICON,
  SEAT_ICON_SM,
  BG_MEAL_ICON,
  myBiz_ADD_MEAL,
  myBiz_ADD_SEAT,
  myBiz_ADD_BAG,
  myBiz_WEB_CHECKIN_ICON,
  WHEELCHAIR_ICON
} from '../../../../../FlightImageConstants';

const AddonCard = ({
  itinaryDetails,
  segmentDetails,
  ancillaryResponseReceived,
  isCorporateBooking,
  onBtnClick,
}) => {
  const {
    flightBaggageDetailsCardText: {
      additionalText: { webCheckinText, addSeatText, addMealText, addBaggageText },
    },
    flightMoreBookingActionsText: {
      additionalText: { wheelChairText = 'Add Wheelchair' },
    }
  } = getStaticData();
  const {
    seatApplicableAncillary,
    mealApplicableAncillary,
    baggageApplicableAncillary,
    wheelChairApplicableAncillary
  } = itinaryDetails;
  const isWebcheckinSupported = segmentDetails.find(
    (item) =>
      item.webCheckInDescription === 'OPEN' ||
      item.webCheckInDescription === 'SCHEDULED_OPEN' ||
      item.webCheckInDescription === 'OPEN_MMT_SUPPORTED',
  );

  const getWebCheckInbtnObj = (segmentDetail) => {
    const webCheckInActionObj = {};
    webCheckInActionObj.actionFamily = FlightConst.ACTION_WEBCHECKIN;
    const desc = segmentDetail.webCheckInDescription;
    if (
      desc !== 'OPEN_MMT_SUPPORTED' &&
      desc !== 'SCHEDULED_OPEN' &&
      !(seatApplicableAncillary || mealApplicableAncillary || baggageApplicableAncillary)
    ) {
      webCheckInActionObj.url = segmentDetail.webCheckInUrl;
      webCheckInActionObj.openInWebView = segmentDetail.openInWebView;
    }
    return webCheckInActionObj;
  };

  const getAncillaryBtnObj = (actionFamily) => {
    const actionObj = {};
    actionObj.actionFamily = actionFamily;
    return actionObj;
  };
  if (!((isWebcheckinSupported && ancillaryResponseReceived) || seatApplicableAncillary || mealApplicableAncillary || baggageApplicableAncillary)) {
    return null;
  }
  return (
    <View style={styles.btnLinkWrapper}>
      {isWebcheckinSupported && ancillaryResponseReceived && (
        <LinkBtn
          icon={isCorporateBooking ? myBiz_WEB_CHECKIN_ICON : WEB_CHECKIN_ICON}
          linkTxt={addNewLineInMiddle(webCheckinText)}
          onBtnClick={onBtnClick}
          isCorporateBooking={isCorporateBooking}
          useUserSelectedLangFont={true}
          action={getWebCheckInbtnObj(segmentDetails[0])}
        />
      )}
      {seatApplicableAncillary && (
        <LinkBtn
          icon={isCorporateBooking ? myBiz_ADD_SEAT : SEAT_ICON_SM}
          isCorporateBooking={isCorporateBooking}
          linkTxt={addNewLineInMiddle(addSeatText)}
          onBtnClick={onBtnClick}
          action={getAncillaryBtnObj(FlightConst.ACTION_SEAT)}
          useUserSelectedLangFont={true}
        />
      )}
      {mealApplicableAncillary && (
        <LinkBtn
          icon={isCorporateBooking ? myBiz_ADD_MEAL : BG_MEAL_ICON}
          isCorporateBooking={isCorporateBooking}
          linkTxt={addNewLineInMiddle(addMealText)}
          onBtnClick={onBtnClick}
          action={getAncillaryBtnObj(FlightConst.ACTION_MEAL)}
          useUserSelectedLangFont={true}
        />
      )}
      {baggageApplicableAncillary && (
        <LinkBtn
          icon={isCorporateBooking ? myBiz_ADD_BAG : BAG_ICON}
          isCorporateBooking={isCorporateBooking}
          linkTxt={addNewLineInMiddle(addBaggageText)}
          onBtnClick={onBtnClick}
          action={getAncillaryBtnObj(FlightConst.ACTION_BAGGAGE)}
          useUserSelectedLangFont={true}
        />
      )}
      {wheelChairApplicableAncillary && !isCorporateBooking && (
        <LinkBtn
          icon={WHEELCHAIR_ICON}
          isCorporateBooking={isCorporateBooking}
          linkTxt={wheelChairText}
          onBtnClick={onBtnClick}
          action={getAncillaryBtnObj(FlightConst.ACTION_WHEELCHAIR)}
          useUserSelectedLangFont={true}
        />
      )}
    </View>
  );
};

export default AddonCard;
