import { StyleSheet } from 'react-native';
import { colors, fonts } from '@mmt/legacy-commons/Styles/globalStyles';
const styles = StyleSheet.create({
  OverlayContentWrapper: {
    backgroundColor: colors.white,
    position: 'relative',
    zIndex: 1,
    width: '100%',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    paddingBottom: 10,
    maxHeight: 550,
  },
  submitReqWrapper: {
    minHeight: 490,
  },
  overlayTopline: {
    width: 40,
    height: 4,
    borderRadius: 4,
    backgroundColor: '#dddddd',
    alignSelf: 'center',
  },
  title: {
    color: colors.black,
    marginBottom: 30,
    lineHeight: 32,
  },
  arrowStyle: {
    width: 8,
    height: 13,
    marginLeft: 10,
    marginTop: 5,
  },
  iconStyle: {
    width: 24,
    height: 24,
    marginRight: 12,
    marginTop: 3,
  },
  listWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 12,
    backgroundColor: '#F7FBFF',
    borderColor: '#E3F1FF',
    borderWidth: 1,
    borderRadius: 4,
    padding: 16,
  },
  lastItemStyle: {
    marginBottom: 0,
  },
  titleTxt: {
    color: colors.black,
    marginBottom: 4,
    lineHeight: 24,
  },
  desc: {
    fontFamily: fonts.regular,
    color: colors.defaultTextColor,
    lineHeight: 20,
  },
  scheduleLinkBtn: {
    borderTopColor: colors.grayBg,
    paddingTop: 20,
    borderTopWidth: 1,
  },
  backArrowWrapper: {
    width: 24,
    height: 24,
    marginLeft: -4,
    marginRight: 4,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  backArrowStyle: {
    width: 20,
    height: 20,
  },
});

export default styles;
