import { Fonts, Theme } from 'apps/post-sales/src/cab/types';
import { StyleSheet } from 'react-native';

const getStyles = (fonts: Fonts, theme: Theme) => {
  const color = theme.color;
  return StyleSheet.create({
    card: {
      paddingHorizontal: 20,
      paddingTop: 16,
      paddingBottom: 32,
      backgroundColor: color.white,
    },
    headingSection: {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 24,
    },
    headingText: {
      fontSize: fonts.fontSize.font24,
      ...fonts.blackFontFamily,
      color: color.black,
    },
    crossIconStyle: {
      width: 24,
      height: 24,
    },
    subHeading: {
      fontSize: fonts.fontSize.font16,
      ...fonts.regularFontFamily,
      color: color.greyText1,
      marginBottom: 12,
    },
    description: {
      fontSize: fonts.fontSize.font18,
      color: color.defaultTextColor,
      ...fonts.regularFontFamily,
    },
    landingImg: {
      width: 48,
      height: 47,
    },
  });
};

export default getStyles;
