const SELF_DRIVE_ROUTE_CONFIG = [
  {
    key: 'selfDriveBookingDetail',
    component: () => require('./selfDriveBookingDetail').default
  },
  {
    key: 'selfDrivePriceBreakUp',
    component: () => require('./components/DetailPage/SelfDrivePriceBreakUp/PaymentPriceBreakup').default
  },
  {
    key: 'moreInfoWindow',
    component: () => require('./components/DetailPage/inclusionExclusionPage/index').default
  },
  // {
  //   key: 'downloadApp',
  //   component: () => require('src/Routes/postsales/selfDrive/components/DetailPage/downloadApp/index').default
  // },
  {
    key: 'selfDriveCancellationDetails',
    component: () => require('./components/Cancellation/SelfDriveCancellationDetails').default
  },
  {
    key: 'selfDriveCancellationRefundMode',
    component: () => require('./components/Cancellation/SelfDriveCancellationRefundMode').default
  },
  {
    key: 'SelfDriveCancelRequest',
    component: () => require('./components/Cancellation/SelfDriveCancelRequest').default
  },
];

export default SELF_DRIVE_ROUTE_CONFIG;