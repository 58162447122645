import React, { useCallback, useState, useMemo } from 'react';
import { View, ScrollView } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { isEmptyArray, isNullOrEmptyObject } from '../../../../../Common/commonUtil';
import ReviewButton from '../../../SelectPassengers/ReviewButton';
import styles from '../ClaimAncillaryRefundCss';
import CardComponent from './CardComponent';

const SelectRefundAncillary = ({
  headerComp,
  selectedData,
  onContinue,
  cards,
  imgMap,
  multiCardSelection,
  actionLists,
}) => {
  const [selectedCard, setSelectedCard] = useState(selectedData);

  const action = useMemo(() => {
    if (!isEmptyArray(actionLists)) {
      const data = actionLists.find((card) => card.actionId === 'claim_proceed');
      return data;
    }
    return null;
  }, [actionLists]);

  const onCardSelection = useCallback(
    (card) => {
      if (multiCardSelection && selectedCard[card.id]) {
        delete selectedCard[card.id];
        setSelectedCard({ ...selectedCard });
      } else {
        setSelectedCard((prev) => {
          const data = multiCardSelection
            ? { ...prev, [card.id]: JSON.parse(JSON.stringify(card)) }
            : { [card.id]: JSON.parse(JSON.stringify(card)) };
          return data;
        });
      }
    },
    [selectedCard, multiCardSelection],
  );

  const onGroupItemSelection = useCallback(
    (card, group, item) => {
      if (selectedCard[card.id]) {
        const { groupEntries } = selectedCard[card.id];
        let removeGroupIndex = -1;
        for (let k = 0; k < groupEntries.length; k++) {
          if (groupEntries[k].id === group.id) {
            const index = groupEntries[k].entries.findIndex((a) => a.id === item.id);
            if (index === -1) {
              groupEntries[k].entries.push(item);
              break;
            } else {
              groupEntries[k].entries.splice(index, 1);
              removeGroupIndex = groupEntries[k].entries.length === 0 ? k : removeGroupIndex;
              break;
            }
          } else if (k === groupEntries.length - 1) {
            groupEntries.push({
              ...group,
              entries: [item],
            });
            break;
          }
        }
        if (removeGroupIndex > -1) {
          groupEntries.splice(removeGroupIndex, 1);
        }
        if (groupEntries.length === 0) {
          delete selectedCard[card.id];
        }
        setSelectedCard({ ...selectedCard });
      } else {
        setSelectedCard((prev) => {
          const data = multiCardSelection
            ? {
                ...prev,
                [card.id]: {
                  ...card,
                  groupEntries: [
                    {
                      ...group,
                      entries: [item],
                    },
                  ],
                },
              }
            : {
                [card.id]: {
                  ...card,
                  groupEntries: [
                    {
                      ...group,
                      entries: [item],
                    },
                  ],
                },
              };
          return data;
        });
      }
    },
    [selectedCard, multiCardSelection],
  );

  return (
    <React.Fragment>
      {headerComp}
      <ScrollView contentContainerStyle={AtomicCss.paddingBottom70}>
        {!isEmptyArray(cards) &&
          cards.map((card, index) => (
            <React.Fragment key={card.id}>
              <CardComponent
                {...{
                  cardIndex: index,
                  card,
                  imgMap,
                  selectedCard,
                  onCardSelection,
                  multiCardSelection,
                  onGroupItemSelection,
                }}
              />
            </React.Fragment>
          ))}
      </ScrollView>
      {!isNullOrEmptyObject(action) && (
        <View style={styles.footerBtnWrapper}>
          <ReviewButton
            buttonText={action.actionText}
            clickHandler={() => onContinue(selectedCard)}
            disabled={isNullOrEmptyObject(selectedCard)}
          />
        </View>
      )}
    </React.Fragment>
  );
};

export default SelectRefundAncillary;
