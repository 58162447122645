import {StyleSheet} from 'react-native'
export default (theme,fonts) => 
  StyleSheet.create({
    contentTitle: {
      fontSize: fonts.fontSize.font34,
      color: theme.color.black,
      ...fonts.lightFontFamily,
      marginBottom: 24,
    },
    contentDesc: {
      fontSize: fonts.fontSize.font16,
      color: theme.color.defaultTextColor,
      ...fonts.boldFontFamily,
      marginBottom: 30,
      lineHeight: 22,
    },
    contentFooter: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 30,
    },
    actionBtn: {
      paddingVertical: 10,
      alignItems: 'center',
      justifyContent: 'center',
    },
    actionBtnText: {
      fontSize: fonts.fontSize.font16,
      color: theme.color.orange,
      ...fonts.mediumFontFamily,
    },
    RadioWrapper: {
      flexDirection: 'row',
      marginBottom: 10,
    },
    Radio: {
      width: 18,
      height: 18,
      backgroundColor: theme.color.white,
      borderRadius: 20,
      borderWidth: 1,
      borderColor: theme.color.yellow2,
      marginRight: 10,
      marginTop: 5,
    },
    RadioInside: {
      width: 12,
      height: 12,
      backgroundColor: theme.color.yellow2,
      borderRadius: 20,
      marginLeft: 2,
      marginTop: 2,
    },
    RadioTxt: {
      fontSize: fonts.fontSize.font14,
      color: theme.color.black,
      ...fonts.boldFontFamily,
      lineHeight: 16,
      paddingTop: 5,
    },
    textAreaContainer: {
      borderColor: theme.color.darkGrey2,
      borderWidth: 1,
      padding: 5,
      borderRadius: 4,
      justifyContent: 'flex-start',
      marginBottom: 10,
    },
    textArea: {
      textAlignVertical: 'top',
      height: 80,
      padding: 0,
      color: theme.color.disabledBtnBg,
      ...fonts.regularFontFamily,
    },
    paddingBottom25: { paddingBottom: 25 },
  }
);
