import React from 'react';
import { View } from 'react-native';
import HTMLView from 'react-native-htmlview';
import {createStyles, createHtmlStyle} from './TGSOverlayCss';
import { getFont } from '../../../../PostSalesStyles';
import { useTheme } from '../../../../theme/theme.context';
import isEmpty  from 'lodash/isEmpty';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';

const RefundInfoSection = ({tgsUpgradeCardData={}}) => {
    const fonts = getFont();
    const { psTheme: theme } = useTheme();
    const styles =  createStyles(theme,fonts);
    const htmlStyle = createHtmlStyle(theme, fonts);
    const {refundForBooking} = tgsUpgradeCardData || {};

    return (
      !isEmpty(refundForBooking) ? <View style={[styles.refundInfo]}>
            {refundForBooking.map(( item, index) => {
              const validate = !isEmpty(item.key) && !isEmpty(item.value);
              return validate && (
                <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween, AtomicCss.marginVertical5]}>
                    <HTMLView
                      value={`<q>${item.key}</q>`}
                      stylesheet={htmlStyle}
                    />
                    <View style={[ AtomicCss.marginTop4, styles.refundValue ]}>
                      <HTMLView
                        value={`<q>${item.value}</q>`}
                        stylesheet={htmlStyle}
                      />
                    </View>
                </View>
              )
            })}
        </View>:null
    )
}


export default RefundInfoSection;