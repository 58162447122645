import { useCallback, useEffect, useMemo, useState } from 'react';
import { getCommonHeaders, MIMA_BASE_URL } from '../utils/NetworkUtils';
import { isNetworkAvailable } from '@mmt/legacy-commons/Common/utils/AppUtils';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';

const useApi = (reqUrl) => {
  const [inProgress, setInProgress] = useState(false);
  const [response, setResponse] = useState(null);
  const [reset, setReset] = useState(false);

  useEffect(() => {
    if (!inProgress && reset) {
      setResponse(null);
      setReset(false);
    }
  }, [inProgress, reset]);

  const getHeaders = async (bookingId, uniqueId, psLob) => {
    let headers = {};
    const userInfo = await HotelBookingModule.getRequestHeader(
      bookingId ? bookingId : '',
      uniqueId,
    );
    headers = await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo, { psLob });
    return headers;
  };

  const fetchWrapper = useCallback(
    async (data, options) => {
      try {
        setInProgress(true);
        const hasNetwork = await isNetworkAvailable();
        if (!hasNetwork) {
          setResponse({ error: 'NO_INTERNET' });
          setInProgress(false);
          return;
        }
        let _url = reqUrl;
        if (_url.startsWith('/')) {
          _url = `${MIMA_BASE_URL}${_url.substr(1)}`;
        }
        const { bookingId, uniqueId, psLob } = data;
        const commonHeaders = await getHeaders(bookingId, uniqueId, psLob);

        let _options = options;
        _options = {
          ..._options,
          headers: {
            ...commonHeaders,
            ...((_options && _options.headers) || {}),
          },
        };
        let _res = await fetch(_url, _options);
        _res = _res && _res.ok ? await _res.json() : { error: _res };
        setInProgress(false);
        setResponse(_res);
      } catch (err) {
        setInProgress(false);
        setResponse({ error: err });
      }
    },
    [reqUrl],
  );

  const httpGet = useCallback(
    (data = {}, options = {}) => {
      fetchWrapper(data, { method: 'GET', ...options });
    },
    [fetchWrapper],
  );

  const httpPost = useCallback(
    (data = {}, options = {}) => {
      let _body = data.body;
      if (typeof _body !== 'string') {
        _body = JSON.stringify(_body);
      }
      fetchWrapper(data, { method: 'POST', body: _body, ...options });
    },
    [fetchWrapper],
  );

  const abortApiCall = useCallback(() => {
    setReset(true);
  }, [setReset]);

  const clearResponse = useCallback(() => {
    setResponse(null);
  }, [setResponse]);

  const api = useMemo(() => ({ httpPost, httpGet, clearResponse, abortApiCall }), [
    httpPost,
    httpGet,
    clearResponse,
    abortApiCall,
  ]);

  return [inProgress, response, api];
};

export default useApi;
