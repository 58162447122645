import React from 'react';
import { View, Text, ScrollView, StyleSheet } from 'react-native';
import Header from '../../../../../../Common/HeaderCross';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getFont } from '../../../../../../PostSalesStyles';

const TNCFullPageOverlay = ({ tncData, handleBottomSheet }) => {
  const {title = '', termsAndConditionsData = []} = tncData || {};
  const { fsStyle, ...fonts } = getFont(true);
  return (
    <React.Fragment>
      <Header
        title={title}
        headerShadow
        headingTxt="font18"
        handleClose={() => handleBottomSheet('')}
      />
      <ScrollView showsVerticalScrollIndicator={true}>
        <View style={styles.innerWrapper}>
          { termsAndConditionsData?.map((item, index) => {
            return (
              <View style={[AtomicCss.flexRow, styles.listWrapper]} key={index}>
                <View style={styles.bullet}></View>
                <Text
                  style={[
                    AtomicCss.defaultText,
                    fsStyle.font14,
                    AtomicCss.flex1,
                    fonts.regularFontFamily,
                  ]}
                >
                  {item}
                </Text>
              </View>
            );
          })}
        </View>
      </ScrollView>
    </React.Fragment>
  );
};
const styles = StyleSheet.create({
  innerWrapper: {
    padding: 16,
  },
  listWrapper: {
    marginBottom: 10,
  },
  bullet: {
    width: 4,
    height: 4,
    borderRadius: 4,
    backgroundColor: '#4a4a4a',
    top: 5,
    marginRight: 10,
  },
});
export default TNCFullPageOverlay;
