import React from 'react';
import { Text, View } from 'react-native';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styles from './styles';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';

const PaxDtls = ({ journeyIntent, services }) => (
  <View style={[styles.rowSection]}>
    {!_.isEmpty(journeyIntent) && <Text style={styles.DtlsHeading}>{journeyIntent}</Text>}
    {!_.isEmpty(services) && services.map((data, index) => (
        <View
          style={[
            AtomicCss.flexRow,
            AtomicCss.alignCenter,
            index !== services.length - 1 ? AtomicCss.marginBottom5 : '',
          ]}
        >
          <View style={styles.bullet} />
          <Text style={[styles.paxName, AtomicCss.marginRight5]}>{data.text}</Text>
        </View>
      ))}
  </View>
);

export default PaxDtls;

PaxDtls.propTypes = {
  journeyIntent: PropTypes.string,
  services: PropTypes.array,
};

PaxDtls.defaultProps = {
  journeyIntent: undefined,
  services: [],
};
