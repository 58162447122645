// PostSales icons
import {getImagePath} from '../../utils/PostSaleUtil';

export const applicationAppliedIcon = getImagePath('applicationapplied.webp');
export const inprocessIcon = getImagePath('ic_top_2.webp');
export const applicationrejectedIcon = getImagePath('ic_top_3.webp');
export const additionaldocumentIcon = getImagePath('group_7.webp');
export const visaapprovedIcn = getImagePath('ic_visaApproved.webp');
export const faqIcon = getImagePath('ic_faqs.webp');
export const arrowLightCreamIcon = getImagePath('arrow_light_cream.webp');
export const arrowCreamIcon = getImagePath('arrow_cream.webp');
export const invalidIcon = getImagePath('invalid_icon.webp');
export const scissorIcon = getImagePath('ic_scissor_grey.webp');
export const offerGreenSmallIcon = getImagePath('ic_offer_red_large.webp');
export const tickIcon = getImagePath('ic_tick_stroke.webp');
export const timerIcon = getImagePath('v5timerIcon.webp');

// Common Icons
export const paymentIcon = getImagePath('payment_icon.webp'); // used in other lobs
export const refundIcon = getImagePath('refundIcon.webp');  // used in other lobs
export const headerIcon = require('@mmt/legacy-assets/src/white_backarrow.webp');
export const ArrowIcon = require('@mmt/legacy-assets/src/white_backarrow.webp');
export const guestIcon = require('@mmt/legacy-assets/src/travellers_icon.webp');
export const infoIcon = require('@mmt/legacy-assets/src/infoIcon3.webp');
export const formIcon = require('@mmt/legacy-assets/src/ic_form.webp');
export const PassportIcon = require('@mmt/legacy-assets/src/ic_passport.webp');
export const TicketIcon = require('@mmt/legacy-assets/src/ic_ticket.webp');
export const RightArrowBlue = require('@mmt/legacy-assets/src/ic_arrow_blue.webp');
export const TripHeaderIcon = require('@mmt/legacy-assets/src/trip_header_back_icon.webp');
export const trackerCheckIcon = require('@mmt/legacy-assets/src/tracker_check.webp');
export const visaRejectionCardIcon = require('@mmt/legacy-assets/src/exclamation.webp');
export const blueDropIcondown = require('@mmt/legacy-assets/src/blueDropIcondown.webp');
export const blueDropIconUp = require('@mmt/legacy-assets/src/blueDropIconUp.webp');
