import React from 'react';
import { Text, TouchableOpacity } from 'react-native';
import styles from './ReqstCallScheduleCss';

const ScheduleList = ({ item, handleTimeList, index, timeActive }) => {

    const isActiveSuffix = item.timestamp === timeActive;
    const activeTab = isActiveSuffix ? 'activeTab' : '';
    const activeTabTxt = isActiveSuffix ? 'activeTabTxt' : '';
    const slot = item.timestamp;
    return (
        <TouchableOpacity style={[styles.scheduleListTimeItem, styles[activeTab]]} onPress={() => handleTimeList(index,slot)}>
            <Text style={[styles.scheduleTxt, styles[activeTabTxt]]}>{item.slotText}</Text>
        </TouchableOpacity>
    );
}

export default ScheduleList;