import { StyleSheet } from "react-native"
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';

export default (theme, fonts) => {
    const styles = StyleSheet.create({
        heading:{
            fontSize: fonts.fontSize.font24,
            color: theme.color.black,
            lineHeight: 32,
            ...fonts.blackFontFamily
        },
        subHeading:{
            fontSize: fonts.fontSize.font16,
            lineHeight:22,
            ...fonts.regularFontFamily
        },
        overlayContent: {
            backgroundColor: theme.color.white,
            paddingVertical: 20,
            paddingHorizontal: 20,  
            shadowColor: theme.color.red24,
            shadowOpacity: 0.3,
            shadowRadius: 2,  
            elevation: 4,
            zIndex: 4,
            position: "absolute",
            bottom: 400,
            marginBottom: -400,
            width: "100%",
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            shadowOffset: {
                width: 1,
                height: 0
            },
        },
        closeIconWrapper:{
            width: 28,
            height: 28,
        },
        closeIconBg:{
            width: 21,
            height: 21,
            backgroundColor: theme.color.lightTextColor,
            borderRadius: 50
        },
        closeIcon:{
            width:21,
            height:21,
        },
        infoIcon:{
            width: 16,
            height: 16,
            tintColor: theme.color.lightYello,
        },
        bookingInput:{
            height: 40,
            width: '100%',
            borderWidth: 1,
            borderColor: theme.color.lightTextColor,
            color: theme.color.defaultTextColor,
            ...fonts.regularFontFamily,
            fontSize:fonts.fontSize.font14
        },
        activeStyle:{
            borderColor: theme.color.azure,
        },
        errorStyle:{
            borderColor: theme.color.errorRed,
        },
        errorTextStyle:{
            color:theme.color.errorRed,
            paddingTop:5
        },
        tooltipWrapper:{
            position: 'absolute',
            backgroundColor: theme.color.green,
            width:'100%',
            left: 0,
            top: 25,
            ...getPlatformElevation(2),
            zIndex: 111,
        },
        notificationInfoTxt:{
            color: theme.color.white,
            lineHeight: 20,
            marginRight: 40,
            ...fonts.regularFontFamily,
            fontSize:fonts.fontSize.font14
        },
        crossIconWrapper:{
            right: 20,
            width: 20,
            height: 20,
        },
        crossIconWhite:{
            tintColor: theme.color.white,
            width: 14,
            height: 14,
        },
        arrowWrapper:{
            position: 'absolute',
            top: -6,
            left: 78,
        },
        arrowStyle:{
            width: 19,
            height: 8,
        },
        bookingIdField:{
            ...fonts.boldFontFamily,
            fontSize:fonts.fontSize.font14
        },
        otpHeading:{
            fontSize: 24,
            color: theme.color.black,
            ...fonts.boldFontFamily,
            marginBottom: 18
        },
        otpSubHeading:{
            fontSize: 16,
            color: theme.color.defaultTextColor,
            ...fonts.regularFontFamily,
            marginBottom: 20,
            lineHeight:22
        }
    });
    return styles;
}