const styles = {
  dCTopInfoWrapper: {
    paddingHorizontal: 10,
    paddingVertical: 8,
    backgroundColor: '#ffedd1',
    borderRadius: 4,
    flexDirection: 'row',
    margin: 8
  },
  calIconStyle: {
    width: 16,
    height: 16,
    marginRight: 10
  },
  bgOrange: {
    backgroundColor: '#ffedd1'
  },
  postponeTxt: {
    color: '#cf8100'
  }

};
export default styles;
