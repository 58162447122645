import {Alert, Platform, Linking} from 'react-native';
import Actions from '../../navigation/postSalesNavigation';
import VisaDetailConstant from '../Constant/VisaDetailConstant';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import VisaBookingTrackingHelper from '../VisaBookingTrackingHelper';
import Fecha from 'fecha';
import VisaBookingModule from '@mmt/legacy-commons/Native/VisaBookingModule';
import { openDialer } from '../../Common/commonUtil';
import NetworkModule from '@mmt/legacy-commons/Native/NetworkModule';
import isEmpty from 'lodash/isEmpty';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getDataFromStorage, setDataInStorage, removeDataFromStorage } from '../../utils/localstorage';

export async function handleEvent(action, phoneNumber, response, pageName) {
  switch (action.actionFamily) {
    case VisaDetailConstant.VISA_CALL_CUSTOMERCARE:
      openDialer(phoneNumber);
      break;
    default:
      break;
  }
}

export function findDaysBetweenDates(firstDate, secondDate) {
  const oneDay = 24 * 60 * 60 * 1000;
  const secondDateObj = Fecha.parse(secondDate.split('T')[0], 'YYYY-MM-DD');
  return Math.round(Math.abs((secondDateObj.getTime() - firstDate) / oneDay));
}

export function externalLinkHandler (url, title, iosURL){
  let externalUrl = url;
  if (Platform.OS === 'ios' && iosURL) {
    externalUrl = iosURL;
  }
  Linking.openURL(externalUrl);
};
export async function handleClickEvent(card, action, response, pageName, payRefId) {
  switch (action.actionFamily) {
    case VisaDetailConstant.TRACK_VISA_STATUS:
      VisaBookingTrackingHelper.trackClickEvent(pageName, response, 'track_status');
      break;
    case VisaDetailConstant.VIEW_DOCUMENTS:
    case VisaDetailConstant.VIEW_VISA_DOCUMENT:
      if(Platform.OS === 'web'){
        externalLinkHandler(response.visastatusCTAs && response.visastatusCTAs[0].buttons && response.visastatusCTAs[0].buttons.length > 0 && response.visastatusCTAs[0].buttons[0].link);
        return;
      }
      Actions.visaDocument({ response });
      VisaBookingTrackingHelper.trackClickEvent(pageName, response, 'view_documents');
      break;
    case VisaDetailConstant.VIEW_PAYMENT_BREAKUP:
      Actions.visapaymentBreakup({ response });
      VisaBookingTrackingHelper.trackClickEvent(pageName, response, 'price_breakup');
      break;
    case VisaDetailConstant.VISA_FAQ:
      Actions.visaFAQ({ response });
      VisaBookingTrackingHelper.trackClickEvent(pageName, response, 'faq_clicked');
      break;
    case VisaDetailConstant.VISA_DOWNLOAD_VISA:
      VisaBookingModule.downloadVisa(JSON.stringify(response.visaURlList));
      VisaBookingTrackingHelper.trackClickEvent(pageName, response, 'download_visa');
      break;
    case VisaDetailConstant.VIEW_DOCUMENT_CHECKLIST:
      if(Platform.OS === 'web'){
         VisaBookingModule.openLink(response.applicantCTAs && response.applicantCTAs[0] && response.applicantCTAs[0].buttons[0] && response.applicantCTAs[0].buttons[0].link);
         return;
      }
      externalLinkHandler(
        response.applicantCTAs &&
          response.applicantCTAs[0] &&
          response.applicantCTAs[0].buttons[0] &&
          response.applicantCTAs[0].buttons[0].link,
        'Document CheckList',
      );
      VisaBookingTrackingHelper.trackClickEvent(pageName, response, 'view_document_checklist');
      break;
    case VisaDetailConstant.FILL_FORM:
    case VisaDetailConstant.UPLOAD_DOCUMENTS:
      if(Platform.OS === 'android') {
        if(!payRefId) {
          const link = `https://visa.makemytrip.com?fromMIMA=${true}&fromPage=${pageName}&toPage=addVisaDocuments&payRefId=${response.bookingId}`;
          GenericModule.openDeepLink(link);
        }
        else {
          const link = `https://visa.makemytrip.com?fromMIMA=${true}&fromPage=${pageName}&toPage=addVisaDocuments&payRefId=${payRefId}&externalRefId=${response.bookingId}`;
          GenericModule.openDeepLink(link);
        }
      } else if(Platform.OS === 'web') {
        externalLinkHandler(response.visastatusCTAs && response.visastatusCTAs[0].buttons && response.visastatusCTAs[0].buttons.length > 0 && response.visastatusCTAs[0].buttons[0].link);
        return;
      } else {
        if(response.visaUploadDeepLinkUrl) {
          GenericModule.openDeepLink(response.visaUploadDeepLinkUrl);
        }
      }
      VisaBookingTrackingHelper.trackClickEvent(pageName, response, 'upload_documents');
      break;
    case VisaDetailConstant.VISA_VIEW_CANCELLATION_CHARGES:
      Alert.alert('This functionality is Coming Soon');
      VisaBookingTrackingHelper.trackClickEvent(pageName, response, 'view_cancel_amount');
      break;
    case VisaDetailConstant.VISA_CANCEL_ALL_APPLICATIONS:
      Alert.alert('This functionality is Coming Soon');
      VisaBookingTrackingHelper.trackClickEvent(pageName, response, 'cancel_all');
      break;
    case VisaDetailConstant.VISA_CANCEL_SOME_APPLICATIONS:
      Alert.alert('This functionality is Coming Soon');
      VisaBookingTrackingHelper.trackClickEvent(pageName, response, 'cancel_partial');
      break;
    case VisaDetailConstant.VISA_DOWNLOAD_INVOICE:
      Alert.alert('This functionality is Coming Soon');
      VisaBookingTrackingHelper.trackClickEvent(pageName, response, 'invoice_download');
      break;
    default:
      Alert.alert('This functionality is Coming Soon');
  }
}

export const getDocExtension = (name) => {
  const splitArr = name.split('.');
  return (splitArr.length > 1 ? splitArr[splitArr.length - 1] : VisaDetailConstant.DEFAULT_DOC_EXTENSION);
};

export const getAuthValue = () => {
  const PlatformAuthCode = {
    IOS: 'Basic bWFrZW15dHJpcElvczpWMXNhRnVubjNs',
    ANDROID: 'Basic bWFrZW15dHJpcEFuZHJvaWQ6VjFzYUZ1bm4zbA==',
    MWEB: 'Basic bWFrZW15dHJpcE13ZWI6VjFzYUZ1bm4zbA=='
  }
  if (Platform.OS === 'ios') {
    return PlatformAuthCode.IOS;
  }
  else if (Platform.OS === 'android') {
    return PlatformAuthCode.ANDROID;
  }
  else if (Platform.OS === 'web') {
    return PlatformAuthCode.MWEB;
  }
  return null;
}

export const getSessionID = async (key, value, timeout) => {
  const getValue = await getDataFromStorage(key);
  if (getValue == null) {
    setDataInStorage(key, value);
    setTimeout(() => {
      removeDataFromStorage(key);
    }, timeout);
  }
  const currentValue = await getDataFromStorage(key);
  return currentValue;
};

export const generateGUID = () => {
  const s4 = () =>
    Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  const guid = `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4() + s4() + s4()}`;
  return guid;
};

export const getHeaders = async () => {
  const headers = await NetworkModule.getHeaders();
  isLoggedIn = !isEmpty(headers['mmt-auth']);
  const getUUID = generateGUID();
  const sessionId = await getSessionID('visa_session_id', getUUID, sessionExpiryTime);
  headers['sessionId'] = sessionId;
  headers['Authorization'] = getAuthValue();
  return headers;
};

export const timeout = (ms, promise) => new Promise((resolve, reject) => {
  setTimeout(() => {
    reject(new Error('api timed out'));
  }, ms);
  promise.then(resolve, reject);
});

export const downloadVisaImage = async (url) => {
  try {
    const headers = await getHeaders();
    const response = await timeout(apiTimeouts.landing.PERSUASION, fetch(url, {
      method: 'GET',
      headers
    }));
    if (response) {
      const responseBody1 = await response.blob();
      return responseBody1
    }
  } catch (e) {
    return null;
  }
  return null;
}