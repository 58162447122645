import React from 'react';
import { View, Text, Image } from 'react-native';
import TripAssuredTag from '../TripAssuredTag';
import { getHtmlStyle } from './styleCss';
import isEmpty from 'lodash/isEmpty';
import HTMLView from 'react-native-htmlview';
import { getFont } from '../../../PostSalesStyles';
import { useTheme } from '../../../theme/theme.context';
import createStyle from './styleCss';

interface TripAssuredClaimApprovedProps {
        tripAssuredApprovedData: {
            tripAssuredRefundData: {
                pointName: string;
                iconImg: string;
            }[];
            tripAssuredTagData?: {
                text: string;
                iconUrl: string;
            };
            heading: string;
            subHeading?: string;
    };
}

const TripAssuredClaimApproved: React.FC<TripAssuredClaimApprovedProps> = ({ tripAssuredApprovedData }) => {
    if (isEmpty(tripAssuredApprovedData)) {
        return null;
    }
    const { tripAssuredRefundData, tripAssuredTagData, heading, subHeading } = tripAssuredApprovedData || {};
    const { fsStyle, ...fonts } = getFont(true);
    const { psTheme: theme } = useTheme();
    const styles = createStyle(fonts, theme);
    return (
        <View style={styles.tripAssuredWrapper}>
            <View style={styles.tripTagContainer}>
                {!isEmpty(tripAssuredTagData) && <TripAssuredTag tripAssuredTagData={tripAssuredTagData} />}
            </View>
            {!!heading && <Text style={styles.headingStyle}>{heading}</Text>}
            {!!subHeading && <HTMLView
                style={styles.subHeadingStyle}
                value={subHeading}
                stylesheet={getHtmlStyle(fonts, theme)}
            />}
            {!isEmpty(tripAssuredRefundData) && tripAssuredRefundData.map((point, index) => {
                const { pointName, iconImg } = point || {};
                const lastIndex = tripAssuredRefundData.length - 1;
                return (
                    <View style={[styles.pointWrapper,
                    lastIndex === index ? styles.lastIndexStyle : null
                    ]} key={index}>
                        {!!iconImg && <Image source={{ uri: iconImg }} style={styles.circleTickStyle} />}
                        {!!pointName && <Text style={styles.pointNameStyle}>{pointName}</Text>}
                    </View>
                )
            })}
        </View>
    )
};

export default TripAssuredClaimApproved;