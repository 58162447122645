import React from 'react';
import {View,ScrollView,Text,Image,TouchableOpacity} from 'react-native';
import getStyles from './HotelMamiCss';
import {getPaymentDueText} from '../utils/HotelBookingDetailUtil';
import PAHXSubmissionSuccessful from './HotelSuccessfulSubmissionPage';
import PropTypes from 'prop-types';
import { getStaticData } from '../../../staticData/staticData';
import { REFUND_RUPEE_ICON } from '../../HotelImageConstants';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';


class ChoosePAHX extends React.Component {
  static navigationOptions = {
    header:null
  }
  constructor(props) {
    super(props);
    this.fonts = getFont(true);
    this.styles = getStyles(this.fonts);
      this.state = {
          radiobtn: 'Yes',

      };
  }


  radio = (active) => {
    this.setState({
      radiobtn: active
    }, () => {
      this.props.radioSelector(this.state.radiobtn);
    });
  };

  render() {
    const staticData = getStaticData();
    const {paymentSummary} = this.props.response;
    const refundIcon = REFUND_RUPEE_ICON;
    return (
      <View>
        <View style={this.styles.PAHXInfoHeading}>
          <View style={this.styles.IconPortion}>
            <Image style={this.styles.CardIcon} source={refundIcon} />
          </View>
          <View style={this.styles.ContentPortion}>
             <Text style={[this.styles.blackFont,this.styles.BlackText]}>
               {staticData.choosePAHXText.additionalText.didNotShowUpAtTheHotelText}
             </Text>
          </View>
        </View>
        <View style={this.styles.PAHXInfo}>
          <TouchableOpacity onPress={ () => {this.radio('Yes')}}>
              <View style={[this.styles.flexRow, this.styles.mar15Bt]}>
                <View style={[this.styles.MimaRadio, this.styles.ChooseOneRadio]}>
                  <View style={[this.state.radiobtn === 'Yes' ? this.styles.MimaRadioInside : '']}></View>
                </View>
                <View style={this.styles.flex1}>
                  <Text style={[this.styles.BlackText, this.styles.boldFont]}>
                    {staticData.choosePAHXText.additionalText.yesIDidNotShowUpText}
                  </Text>
                <Text style={this.styles.font12}>{paymentSummary.bookingBeCancelledAndPanalityText || `(your booking will be cancelled and a penalty of ${getPaymentDueText(paymentSummary, this.props.response.paymentDetails)} will be charged on your card)`}</Text>
                </View>
              </View>
          </TouchableOpacity>
            <TouchableOpacity onPress={ () => {this.radio('No')}}>
              <View style={[this.styles.flexRow, this.styles.mar15Bt]}>
                <View style={[this.styles.MimaRadio , this.styles.ChooseOneRadio]}>
                  <View style={[this.state.radiobtn === 'No' ? this.styles.MimaRadioInside : '']}></View>
                </View>
                <View>
                    <Text style={[this.styles.BlackText, this.styles.boldFont]}>{staticData.choosePAHXText.additionalText.noIHadCheckedInText}</Text>
                    <Text style={this.styles.font12}>({staticData.additionalText.iHadSettledTheFullBillAtTheHotelText})</Text>
                </View>
              </View>
            </TouchableOpacity>
           </View>

        </View>
    );
  }

}
PAHXSubmissionSuccessful.propTypes = {
  response: PropTypes.object.isRequired,
  radioSelector:PropTypes.object.isRequired
};
export default ChoosePAHX;

