import { StyleSheet } from 'react-native';
import { colors, fonts } from '@mmt/legacy-commons/Styles/globalStyles';

const styles = StyleSheet.create({
  trackerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.white,
  },
  trackerDtls: {
    position: 'relative',
    marginLeft: 10,
    borderLeftWidth: 4,
    borderLeftColor: colors.lightGrey,
    paddingBottom: 24,
  },
  trackerDtlsComplete: {
    borderLeftColor: colors.brightOrange,
  },
  lastItemStyle: {
    borderLeftWidth: 4,
    borderLeftColor: 'transparent',
    paddingBottom: 0,
  },
  trackerInnerDtls: {
    position: 'relative',
    flexDirection: 'row',
    paddingRight: 30,
  },
  progressLine: {
    width: 4,
    backgroundColor: colors.lightGrey,
    position: 'absolute',
    height: '80%',
    left: 10,
    top: 0,
  },
  countWrapper: {
    width: 24,
    height: 24,
    backgroundColor: colors.lightGrey,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 50,
    left: -14,
  },
  iconWrapper: {
    width: 24,
    height: 24,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 50,
    left: -14,
  },
  countTxt: {
    color: colors.greyLight,
  },
  trackerStatus: {
    color: colors.defaultTextColor,
    fontSize: 14,
    marginBottom: 5,
  },
  trackerStatusTime: {
    color: colors.greyLight,
    fontSize: 13,
  },
  trackerPriceTxt: {
    color: colors.lightYello,
    fontSize: 13,
    fontFamily: fonts.regular,
  },
  showIcon: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 11,
    width: 24,
    height: 24,
  },
  defaultActiveStyle: {
    backgroundColor: colors.brightOrange,
    width: 4,
    position: 'absolute',
    top: 0,
  },
  stepOneLineActive: {
    height: '35%',
  },
  stepTwoLineActive: {
    height: '78%',
  },
  stepThreeLineActive: {
    height: '100%',
  },
  lastItem: {
    marginBottom: 0,
  },
  borderTopActive: {
    borderTopColor: '#e5e5e5',
    paddingTop: 15,
    borderTopWidth: 1,
  },
  specialContainer: {
    backgroundColor: colors.grayBg,
    padding: 8,
    paddingRight: 20,
    top: -8,
    borderRadius: 4,
  },
  flexShrink: {
    flexShrink: 1,
  },
  leftArrow: {
    width: 0,
    height: 0,
    position: 'absolute',
    top: 13,
    left: -20,
    borderLeftWidth: 10,
    borderLeftColor: 'transparent',
    borderRightWidth: 10,
    borderRightColor: colors.grayBg,
    borderTopWidth: 6,
    borderTopColor: 'transparent',
    borderBottomWidth: 6,
    borderBottomColor: 'transparent',
  },
});
export default styles;
