import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';

const styles = {
  whiteCard: {
    ...getPlatformElevation(2),
    backgroundColor: '#fff',
    marginBottom: 10,
  },
  innerWrapper: {
    padding: 16,
  },
  headingTxt: {
    color: colors.black,
    marginBottom: 25,
  },
  refundProcessRow: {
    borderLeftWidth: 2,
    borderLeftColor: '#cf8100',
    paddingLeft: 26,
    marginLeft: 20,
    marginBottom: 12,
    paddingBottom: 20,
  },
  lastItemStyle: {
    marginBottom: 0,
    paddingBottom: 0,
    borderLeftWidth: 0,
    borderLeftColor: 'transparent',
  },
  imgWrapper: {
    width: 32,
    position: 'absolute',
    backgroundColor: '#fff',
    left: -16,
    marginRight: 12,
    paddingBottom: 10,
  },
  circleWrapper: {
    width: 32,
    height: 32,
    borderColor: '#cf8100',
    borderWidth: 1,
    borderRadius: 40,
    backgroundColor: '#ffedd1',
    alignItems: 'center',
    justifyContent: 'center',
  },
  circleCountTxt: {
    color: '#4a4a4a',
  },
  width90: {
    width: '90%',
    marginTop: 4,
  },
  iconStyles: {
    width: 32,
    height: 32,
  },
  moreInfoWrapper: {
    borderRadius: 4,
    backgroundColor: '#ffedd1',
    padding: 10,
  },
  moreInfoTxt: {
    color: '#cf8100',
    fontSize: 14,
  },
};
export default styles;
