import { StyleSheet } from 'react-native';
import { colors, fonts } from '@mmt/legacy-commons/Styles/globalStyles';

const styles = StyleSheet.create({
  cardWrapper: {
    margin: 20,
  },
  statusHeadText: {
    fontSize: 16,
    color: colors.black,
    fontFamily: fonts.black,
    lineHeight: 20,
    marginBottom: 16,
  },
  textarea: {
    borderColor: colors.lightGrey,
    borderWidth: 1,
    borderRadius: 4,
    height: 120,
    textAlignVertical: 'top',
    paddingHorizontal: 10,
  },
});

export default styles;
