import React, { useEffect, useCallback } from 'react';
import CustomViewPager from '../../../Common/CustomViewPager/CustomViewPager';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { FlatList, Text, TouchableHighlight, View, Platform, StyleSheet } from 'react-native';
import { colors, fontSizes } from '@mmt/legacy-commons/Styles/globalStyles';
import { BookingStates, PAGE_BOOKINGS_STATUS } from '../../summaryConstants';
import { getRecentBkgInfo } from '../cards/lostID/lostIdCardAdapter';
import TripSummaryOmnitureTracker from '../../tracking/TripSummaryOmnitureTracker';
import { getFont, themeColors } from '../../../PostSalesStyles';
import { getTranslatedTextFor } from '../../../utils/PostSaleUtil';
import PulseIconAnim from '../../../Common/PulseIconAnimation';
import { useTheme } from '../../../theme/theme.context';
import { setDataInStorage } from "../../../utils/localstorage";

function Tabs({ titles, onTabClicked, activeIndex = 0, showPulseIcon }) {
  const {psTheme: theme}=useTheme();
  const { fsStyle, ...fonts}  = getFont(true);
  const styles = createStyle(theme)
  const flatListRef = React.useRef(false);
  useEffect(() => {
    if (activeIndex < 1 || activeIndex === titles.length) {
      return;
    }
    flatListRef.current.scrollToIndex({
      animated: true,
      index: activeIndex - 1,
    });
  }, [titles, activeIndex]);

  return (
    <FlatList
      ref={flatListRef}
      horizontal
      style={styles.tabContainer}
      data={titles}
      extraData={activeIndex}
      keyExtractor={({ item }) => item}
      renderItem={({ item: title, index }) => (
        <TouchableHighlight
          underlayColor={theme.color.primary}
          onPress={() => onTabClicked(index, title)}
        >
          <View>
            {showPulseIcon(title) && (
              <View style={styles.pulse}>
                <PulseIconAnim />
              </View>
            )}
            <Text
              style={[
                styles.tab,
                fonts.regularFontFamily,
                ...(activeIndex === index ? [styles.tabActive, fonts.boldFontFamily] : []),
                fsStyle.font14,
              ]}
            >
              {title && title.includes(BookingStates.UNSUCCESSFUL)
                ? title.replace(
                    BookingStates.UNSUCCESSFUL,
                    getTranslatedTextFor(BookingStates.UNSUCCESSFUL),
                  )
                : getTranslatedTextFor(title)}
            </Text>
          </View>
        </TouchableHighlight>
      )}
    />
  );
}

let pulsatingTracked = false;
function ViewPagerWithTabs({ tabs, children, updateCurrentTab, displayTabs, topRecentBkg }) {
  const recentCount = topRecentBkg && topRecentBkg.split('__')[1] || 0;
  const viewPagerRef = React.useRef(null);
  const [currentViewPagerPage, setCurrentViewPagerPage] = React.useState(0);

  const showPulseIcon = (title) => {
    const val = getRecentBkgInfo();
    if (
      title &&
      title.includes(BookingStates.UNSUCCESSFUL) &&
      title !== BookingStates.UNSUCCESSFUL
    ) {
      if (val !== topRecentBkg) {
        !pulsatingTracked &&
          TripSummaryOmnitureTracker.trackNormalClickEvent(
            'mob:customer support:all:mytrips',
            `pulsating_shown_${recentCount}`,
          );
        pulsatingTracked = true;
        return true;
      }
      return false;
    }
    return undefined;
  };
  const trackTabClick = async (title) => {
    const pageName = await TripSummaryOmnitureTracker.getPageName();
    if(showPulseIcon(title)!== undefined){
      TripSummaryOmnitureTracker.trackNormalClickEvent(
        pageName,
        `unsuccesssful_tabclick_${recentCount}`,
        { recentCount },
      );
    }
  }
  

  const onTabItemClicked = async (tabIndex, title) => {
    if (viewPagerRef.current) {
        updateCurrentTab(displayTabs[tabIndex]);
      // This check is added to avoid crash in ios while switching tabs from react-native-pager-view library
      if (Platform.OS === 'ios') {
        viewPagerRef.current.setPage && viewPagerRef.current.setPageWithoutAnimation(tabIndex);
      } else {
        viewPagerRef.current.setPage && viewPagerRef.current.setPage(tabIndex);
      }
    }
    if (!isNaN(tabIndex) && Platform.OS === 'web') {
      setCurrentViewPagerPage(tabIndex);
    }
    trackTabClick(title);
    if (showPulseIcon(title) !== undefined) {
      await setDataInStorage('RECENT_UNSUCCESSFUL_BKG', topRecentBkg);
    }
  };
  const setInitialPage = (index) => {
    setCurrentViewPagerPage(index);
    if (Platform.OS === 'ios') {
      viewPagerRef.current.setPage && viewPagerRef.current.setPageWithoutAnimation(index);
    } else {
      viewPagerRef.current.setPage && viewPagerRef.current.setPage(index);
    }
  };
  React.useEffect(() => {
    if (tabs && tabs.includes(PAGE_BOOKINGS_STATUS.PLANNING)) {
      setInitialPage(1);
    } else {
      setInitialPage(0);
    }
  }, []);

  const shouldLoadComponent = (index) => index === currentViewPagerPage;
  
  const onHorizantalTabChanged = useCallback((position) => {
    updateCurrentTab(displayTabs[position]);
    setCurrentViewPagerPage(position);
  }, [tabs, updateCurrentTab]);

  return (
    <View style={{ flex: 1 }}>
      <Tabs
        onTabClicked={(tabIndex, title) => onTabItemClicked(tabIndex, title)}
        titles={tabs}
        activeIndex={currentViewPagerPage}
        showPulseIcon={showPulseIcon}
      />
      <CustomViewPager
        ref={viewPagerRef}
        style={{ flex: 1 }}
        initialPage={0}
        selectedIndex={currentViewPagerPage}
        onSelectedIndexChange={onTabItemClicked}
        currentViewPagerPage={currentViewPagerPage}
        shouldLoadComponent={shouldLoadComponent}
        onPageSelected={(e) => {
          onHorizantalTabChanged(e.nativeEvent.position);
        }}
      >
        {children}
      </CustomViewPager>
    </View>
  );
}

const createStyle = (theme) =>
  StyleSheet.create({
    tabContainer: {
      flexGrow: 0,
      backgroundColor: colors.white,
      marginVertical: 1,
    },
    tab: {
      textAlign: 'center',
      color: '#4a4a4a',
      borderBottomWidth: 2,
      lineHeight: 24,
      paddingVertical: 12,
      paddingHorizontal: 12,
      borderBottomColor: 'transparent',
    },
    tabActive: {
      color: '#000000',
      lineHeight: 24,
      borderBottomWidth: 4,
      borderBottomColor: theme.color.primary,
      borderStyle: 'solid',
    },
    pulse: {
      position: 'absolute',
      top: 6,
      left: -2,
    },
  });
export default ViewPagerWithTabs;
