import React from 'react';
import { Keyboard, ScrollView, Text, TouchableOpacity, View, Platform } from 'react-native';
import _ from 'lodash';
import FareChangeOverlay from '@mmt/legacy-commons/Common/Components/MIMABottomOverlay/FareChangeOverlay';
import OverlayMessage from '@mmt/legacy-commons/Common/Components/MIMABottomOverlay/OverlayMessage';
import CommonStyle from '@mmt/legacy-commons/Styles/AtomicCss';
import Header from '../../../../components/Header';
import styles from './managerViewCss';
import FareBreakUp from '../../../../components/FareBreakUp'
import ReasonBooking from '../ReasonBooking';
import TravellerDtls from '../TravellerDtls';
import InfoListCard from '../InfoListCard';
import GSTDetailCard from '../GSTDetailCard';
import TabsHeader from '../TabsHeader';
import FlightDtlsInfo from './components/FlightInfo';
import Buttons from '../../../../../components/Button';
import ManagerApprovalCard from '../ManagerApprovalCard';
import styles1 from '../RejectionOverlay/RejectionOverlayCss';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import {
  FLIGHT_CORP_DATE_CHANGE_APPROVER_DETAILS,
  FLIGHT_CORP_DATE_CHANGE_WORKFLOW_DETAILS,
  getOdcApprovalCommonHeaders,
  getURLParams,
} from '../../../../../../../utils/NetworkUtils';
import { isNetworkAvailable } from '@mmt/legacy-commons/Common/utils/AppUtils';
import FlightBookingModule from '@mmt/legacy-commons/Native/FlightBookingModule';
import { DcViewState } from '../../../../constants';
import DateChangeBaseClass from '../../../../components/DateChangeBaseClass';
import DateChangeTrackingHelper from '../../../../analytics/DateChangeTrackingHelper';
import ApprovedOverlayDtls from '../ApprovedOverlay/';
import { openErrorPage } from '../../../../../../../utils/PostSaleUtil';
import RejectionOverlayDtls from '../RejectionOverlay';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import Actions from '../../../../../../../navigation/postSalesNavigation';
import { LOBNAMES } from '../../../../../../../PostSalesConstant';
import { getApproverDetailRequest, getUserInfo } from '../../data/requestAdapter';
import { fetchReviewResponse, getWorkFlowDetails } from '../../data/api';
import UndoWebCheckInComp from '../../../../components/UndoWebCheckIn';
import { ACTION_BUTTONS } from './constants';

const TAB_LINKS = [
  {
    subTitle: 'REQUEST',
    title: 'Modified',
  },
  {
    subTitle: 'REQUEST',
    title: 'Original',
  },
];

export default class ManagerView extends DateChangeBaseClass {
  static navigationOptions = {
    header: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      showRejectionOverlay: false,
      showApprovedOverlay: false,
      activeTab: 0,
      bottom: 0,
      errorMsg: null,
      fareChangeOverlay: false,
      holdResponse: null,
      skipValue: null,
      undoWebCheckInOverlay:false,
      action:''
    };

    if (props.renderPwaApprovalPage) {
      const currentLocation = window && window.location && window.location.href;
      this.bookingId = getURLParams('bookingId', currentLocation);
      this.workflowId = getURLParams('workflowId', currentLocation);
      this.authCode = getURLParams('authCode', currentLocation);
      this.action = '301';
    }
    this.handleTab = this.handleTab.bind(this);
    this.closeRejectionOverlay = this.closeRejectionOverlay.bind(this);
    this.closeApprovedOverlay = this.closeApprovedOverlay.bind(this);
    this.closeFareChangeOverlay = this.closeFareChangeOverlay.bind(this);
    this.holdAPIErrorNextStep = undefined;
  }
  componentDidMount() {
    this.keyboardDidShowListener = Keyboard.addListener(
      'keyboardDidShow',
      this._keyboardDidShow.bind(this),
    );
    this.keyboardDidHideListener = Keyboard.addListener(
      'keyboardDidHide',
      this._keyboardDidHide.bind(this),
    );
    this.fetchData();
  }

  componentWillUnmount() {
    this.keyboardDidShowListener.remove();
    this.keyboardDidHideListener.remove();
  }

  _keyboardDidShow(e) {
    this.setState({ bottom: e.endCoordinates.height });
  }

  closeRejectionOverlay() {
    this.setState({
      showRejectionOverlay: !this.state.showRejectionOverlay,
    });
  }
  closeApprovedOverlay() {
    this.setState({
      viewState: DcViewState.SHOW_DETAIL,
    });
  }
  closeFareChangeOverlay() {
    this.setState({
      fareChangeOverlay: !this.state.fareChangeOverlay,
    });
    DateChangeTrackingHelper.trackClickableEvent(`${this.props.pageName}fareupdate`, 'close', this.props.odcPreviewResponse, this.props.isCorporateBooking);
  }

  _keyboardDidHide(e) {
    this.setState({ bottom: 0 });
  }
  handleTab(index) {
    this.setState({
      activeTab: index,
    });
    this.refs.tabContent.scrollTo({ x: 0, y: 0, animated: true });
  }

  fetchData = () => {
    this.setState({ viewState: DcViewState.LOADING });
    this.fetchJSONAsync(FLIGHT_CORP_DATE_CHANGE_WORKFLOW_DETAILS);
  };

  getContentTitle = (header, description) => {
    const content = {};
    content.title = header;
    content.desc = description;
    return content;
  };

  async fetchJSONAsync(url) {
    try {
      const hasNetwork = await isNetworkAvailable();
      if (!hasNetwork) {
        this.setState({ viewState: DcViewState.NO_INTERNET });
        return;
      }
      let userInfo = await getUserInfo(this.props.reviewResponse.bookingId,this.action,'FLIGHT_DATE_CHANGE_REVIEW');

      const workFlowDetails = await this.fetchWorkFlowDetails(
        url + this.props.workflowId,
        userInfo,
        this.props.pageName,
      );
      if (workFlowDetails && workFlowDetails !== 'ERROR') {
        this.setState({
          workFlowDetails,
          viewState: DcViewState.SHOW_DETAIL,
        });
      } else {
        this.setState({
          viewState: DcViewState.ERROR,
        });
      }
    } catch (error) {
      DateChangeTrackingHelper.trackErrorEvent(
        this.props.pageName,
        'ODCStatus_workFlowDetails_Failure',
        '',
        true,
      );
      this.setState({ viewState: DcViewState.ERROR });
    }
  }

  async fetchWorkFlowDetails(url, userInfo, pageName) {
  try {
    const body = await getWorkFlowDetails(url,userInfo,this.authCode)
    if (body.errorPageData) {
      openErrorPage(
        body.errorPageData,
        this.props.onBackPressFromErrorPage,
        this.fetchData,
        body.bookingId,
        'datechange',
        Actions,
        { psLob: LOBNAMES.FLIGHT },
      );
      DateChangeTrackingHelper.trackErrorEvent(
        `${pageName}workFlowDetails_Call`,
        'ODCStatus_workFlowDetails_Call_Failure',
        body.errorPageData.meta.supplier,
        true,
      );
    } else {
      DateChangeTrackingHelper.trackLoadEvent(`${pageName}workFlowDetails_Call`, body, true);
      return body;
    }
  } catch (e) {
    DateChangeTrackingHelper.trackErrorEvent(
      pageName,
      'ODCStatus_workFlowDetails_Call_Failure',
      '',
      true,
    );
    return 'ERROR';
  }
    return null;
  }
  async getApproverActionData(bookingId, workflowId, comment, reason, action) {
    this.setState({
      viewState: DcViewState.APPROVE_REQUEST_LOADING,
    });
    const hasNetwork = await isNetworkAvailable();
    if (!hasNetwork) {
      this.setState({ viewState: DcViewState.NO_INTERNET });
      return;
    }
    let userInfo = getUserInfo(bookingId,this.action,'FLIGHT_DATE_CHANGE_REVIEW');
    const requestBody = getApproverDetailRequest(bookingId,workflowId,action,reason,comment);
    await fetchReviewResponse(userInfo,this.authCode,FLIGHT_CORP_DATE_CHANGE_APPROVER_DETAILS,requestBody).then((body) => {
      if (body.errorPageData) {
        openErrorPage(
          body.errorPageData,
          this.props.onBackPressFromErrorPage,
          this.fetchData,
          body.bookingId,
          'datechange',
          Actions,
          { psLob: LOBNAMES.FLIGHT },
        );
        DateChangeTrackingHelper.trackErrorEvent(
          this.props.pageName,
          'ODCStatus_ApproverCall_Failed',
          body.errorPageData.meta.supplier,
          true,
        );
      } else {
        if (Platform.OS === 'ios' || Platform.OS === 'android') {
          GenericModule.fetchPersonalizedData({ event: 'corporateRequestUpdated' });
        }
        DateChangeTrackingHelper.trackLoadEvent(`${this.props.pageName}ApproverCall`, body, true);
        this.setState({
          approverDetails: body,
          viewState: DcViewState.APPROVE_REQUEST_SUCCESS,
        });
      }
    }, (_err) => {
      DateChangeTrackingHelper.trackErrorEvent(
        this.props.pageName,
        'ODCStatus_ApproverCall_Failed',
        '',
        true,
      );
      this.setState({
        viewState: DcViewState.APPROVE_REQUEST_ERROR,
        approverAction: action,
      });
    });
  }

  openUndoWebcheckinOverlay = (action)=> {
    this.setState({ undoWebCheckInOverlay: true, action });
  }

  closeUndoWebcheckinOverlay = () => {
    this.setState({ undoWebCheckInOverlay: false });
  }

  makePayment = (skipClicked, isUndoWebCheckInDone = false) => {
    const { reviewDetail } = this.props.reviewResponse;

    const { undoWebCheckinInfo } = reviewDetail;
    const { bannerInfo } = undoWebCheckinInfo || {};
    if (
      !isUndoWebCheckInDone && bannerInfo &&
      (skipClicked === ACTION_BUTTONS.PENDING_SKIPPED || skipClicked === ACTION_BUTTONS.PROCEED_TO_BOOK)
      ) {
      this.openUndoWebcheckinOverlay(skipClicked);
      return;
    }

    let skipClickedPay = skipClicked;
    if (!skipClickedPay) {
      skipClickedPay = ACTION_BUTTONS.SKIP;
    }
    if (skipClicked === ACTION_BUTTONS.PENDING_SKIPPED) {
      DateChangeTrackingHelper.trackClickableEvent(
        this.props.pageName,
        'ODCStatus_PendingSkipAppproval_Clicked',
        this.props.odcPreviewResponse,
        this.props.isCorporateBooking
      );
    } else if (skipClicked === ACTION_BUTTONS.PROCEED_TO_BOOK) {
      DateChangeTrackingHelper.trackClickableEvent(
        this.props.pageName,
        'ODCStatus_ProceedtoBook_Clicked',
        this.props.odcPreviewResponse,
        this.props.isCorporateBooking
      );
    }
    this.setState({ skipValue: skipClickedPay });
    this.props.makeHoldCall(
      reviewDetail.bookingId,
      this.props.workflowId,
      reviewDetail.crKey,
      reviewDetail.rKey,
      reviewDetail.corpPolicyInfo.pKey,
      skipClickedPay,
      this.setViewState,
      this.setHoldResponse,
    );
  };

  onRejectSubmitClicked = (comment, reason) => {
    DateChangeTrackingHelper.trackClickableEvent(
      this.props.pageName,
      'ODCStatusReject_Reason_Selected',
      this.props.odcPreviewResponse,
      this.props.isCorporateBooking
    );
    DateChangeTrackingHelper.trackClickableEvent(
      this.props.pageName,
      'ODCStatus_RejectSubmit_Clicked',
      this.props.odcPreviewResponse,
      this.props.isCorporateBooking
    );
    this.getApproverActionData(
      this.props.reviewResponse.reviewDetail.bookingId,
      this.props.workflowId,
      comment,
      reason,
      'rejected',
    );
  };

  setFareChangeOverlay = () => {
    this.setState({
      fareChangeOverlay: !this.state.fareChangeOverlay,
    });
  };

  setViewState = (viewState) => {
    this.setState({
      viewState,
    });
  };

  setHoldResponse = (holdResponse) => {
    this.setState({ holdResponse });
  };

  renderPage() {
    const { workFlowDetails } = this.state;
    const { reviewResponse } = this.props;
    const { reviewDetail } = reviewResponse || {};
    const { undoWebCheckinInfo } = reviewDetail || {};

    return (
      <View style={styles.pageWrapper}>
        <Header
          type="Tab"
          title="Reschedule Request"
          onBackPress={this.props.onBackPressFromErrorPage}
        />
        <View style={styles.tabWrapper}>
          <View style={styles.tabsHeader}>
            <TabsHeader
              data={TAB_LINKS}
              handleTab={this.handleTab}
              activeTab={this.state.activeTab}
            />
          </View>
          <ScrollView
            ref="tabContent"
            showsVerticalScrollIndicator={false}
            style={[styles.tabContent, CommonStyle.paddingTop35]}
          >
            {!reviewResponse.reviewDetail.corpPolicyInfo.withinPolicy && (
              <View style={styles.infoIconWrapper}>
                <Text style={[styles.info_redTxt]}>OUT OF POLICY BOOKING</Text>
              </View>
            )}
            <FlightDtlsInfo
              flightDetails={
                this.state.activeTab === 1
                  ? reviewResponse.reviewDetail.originalJourney
                  : reviewResponse.reviewDetail.modifiedJourney
              }
            />

            <View style={[styles.whiteCard, styles.ReviewCard]}>
              <TravellerDtls travellers={reviewResponse.reviewDetail.paxWiseTraveller} />
            </View>
            <View style={[styles.whiteCard, styles.ReviewCard]}>
              <ReasonBooking rescheduleReason={workFlowDetails.bookingDetails} />
            </View>
            <View style={[styles.whiteCard, styles.FareBkCard]}>
              {!_.isEmpty(reviewResponse.reviewDetail.fareBreakup) && (
                <FareBreakUp fareBreakupData={reviewResponse.reviewDetail.fareBreakupData} />
              )}
            </View>

            <View>
              <View style={[styles.whiteCard, styles.ReviewCard]}>
                <InfoListCard importantInfos={reviewResponse.reviewDetail.impInfo} />
              </View>
            </View>
            <View style={[styles.whiteCard, styles.ReviewCard, CommonStyle.marginBottom20]}>
              <GSTDetailCard gst={reviewResponse.reviewDetail.gstDetails} />
            </View>

            <ManagerApprovalCard requesterDetails={workFlowDetails.requesterDetails} />
            <View style={styles.tabContentBtmSpace} />
          </ScrollView>

          {workFlowDetails.ctaList && (
            <View style={styles.DarkFooter}>
              {workFlowDetails.ctaList
                .filter((cta) => cta.text === ACTION_BUTTONS.REJECT)
                .map((cta) => (
                  <Buttons
                    navigation={this.props.navigation}
                    text={cta.text}
                    type="Rounded"
                    width={165}
                    bgColor="white"
                    link="dsffsf"
                    onPressHandler={() => {
                      this.handleApproverAction(cta);
                    }}
                  />
                ))}
              {workFlowDetails.ctaList
                .filter((cta) => cta.text === 'APPROVE')
                .map((cta) => (
                  <Buttons
                    isCorporateBooking
                    navigation={this.props.navigation}
                    text={cta.text}
                    type="Rounded"
                    width={165}
                    link="dsffsf"
                    onPressHandler={() => {
                      this.handleApproverAction(cta);
                    }}
                  />
                ))}
              {workFlowDetails.ctaList
                .filter((cta) => cta.action === ACTION_BUTTONS.CTA_SKIP)
                .map((cta) => (
                  <Buttons
                    isCorporateBooking
                    navigation={this.props.navigation}
                    text={cta.text}
                    type="Rounded"
                    width={165}
                    link="dsffsf"
                    onPressHandler={() => {
                      this.makePayment(ACTION_BUTTONS.PENDING_SKIPPED);
                    }}
                  />
                ))}
              {workFlowDetails.ctaList
                .filter((cta) => cta.action === ACTION_BUTTONS.CTA_BOOK)
                .map((cta) => (
                  <Buttons
                    isCorporateBooking
                    navigation={this.props.navigation}
                    text={cta.text}
                    type="Rounded"
                    arrow="RightArrow"
                    width={165}
                    link="dsffsf"
                    onPressHandler={() => {
                      this.makePayment(ACTION_BUTTONS.PROCEED_TO_BOOK);
                    }}
                  />
                ))}
              {workFlowDetails.ctaList
                .filter((cta) => cta.text === ACTION_BUTTONS.SELECT_NEW)
                .map((cta) => (
                  <Buttons
                    isCorporateBooking
                    navigation={this.props.navigation}
                    text={cta.text}
                    type="Rounded"
                    width={165}
                    link="dsffsf"
                    onPressHandler={this.props.onBackPressFromErrorPage}
                  />
                ))}
            </View>
          )}
        </View>
        {this.state.showRejectionOverlay && (
          <View style={styles1.overlayWrapper}>
            <TouchableOpacity activeOpacity={1} style={styles1.overlay} />
            <View style={[styles1.overlayContent, { bottom: this.state.bottom }]}>
              <ScrollView>
                <RejectionOverlayDtls
                  reasons={workFlowDetails.rejectionReasons}
                  closeOverlay={this.closeRejectionOverlay}
                  onRejectSubmitClicked={this.onRejectSubmitClicked}
                />
              </ScrollView>
            </View>
          </View>
        )}
        {this.state.fareChangeOverlay && (
          <OverlayMessage
            CloseOverlay={this.closeFareChangeOverlay}
            content={
              <FareChangeOverlay
                CloseOverlay={this.closeFareChangeOverlay}
                ContinuePress={(holdResponse, reviewResponse) =>
                  this.props.openPaymentPage(holdResponse, this.state.skipValue)
                }
                content={this.getContentTitle(
                  this.state.holdResponse.fareChangeHeader,
                  this.state.holdResponse.fareChangeText,
                )}
                holdResponse={this.state.holdResponse}
                reviewResponse={this.props.reviewResponse.reviewDetail}
                isCorporateBooking
              />
            }
          />
        )}
        {this.state.undoWebCheckInOverlay && (
          <UndoWebCheckInComp
            undoWebcheckinInfo={undoWebCheckinInfo}
            handleBottomOverlay={this.closeUndoWebcheckinOverlay}
            reviewInfo={reviewDetail}
            makePayment={() => this.makePayment(this.state.action, true)}
            odcPreviewResponse={reviewDetail}
            odcSegmentGroup={reviewDetail.segmentGroupPaxDcRequested}
            isCorp
          />
        )}
      </View>
    );
  }

  renderApprovedView = () => (
    <OverlayMessage
      CloseOverlay={this.closeApprovedOverlay}
      content={
        <ApprovedOverlayDtls
          CloseOverlay={this.closeApprovedOverlay}
          approvalText={this.state.approverDetails.header}
          approvalSubText={this.state.approverDetails.subText}
          onOkPress={this.props.onBackPressFromErrorPage}
        />
      }
    />
  );

  renderApproveErrorView = () => (
    <ErrorView
      showRetry
      onRetry={() => {
        this.onApproveRetryClicked();
      }}
    />
  );

  onApproveRetryClicked = () => {
    this.setState({
      viewState: DcViewState.APPROVE_REQUEST_LOADING,
    });
    this.getApproverActionData(
      this.props.reviewDetail.bookingId,
      this.props.workflowId,
      '',
      '',
      'approved',
    );
  };

  renderErrorPage = () => {
    return (
      <ErrorView
        showRetry
        onRetry={() => {
          this.props.fetchData();
        }}
      />
    );
  };

  render() {
    return (
      <View style={{ flex: 1 }}>
        {this.state.viewState === DcViewState.LOADING &&
          this.renderProgressView('Loading Review...')}
        {this.state.viewState === DcViewState.NO_INTERNET && this.renderNoNetworkView()}
        {this.state.viewState === DcViewState.ERROR && this.renderErrorPage()}
        {this.state.viewState === DcViewState.SHOW_DETAIL && this.renderPage()}
        {this.state.viewState === DcViewState.HOLD_LOADING && this.renderProgressView('Loading...')}
        {this.state.viewState === DcViewState.HOLD_SUCCESS &&
          this.props.renderPaymentPage(
            this.state.holdResponse,
            this.setFareChangeOverlay,
            this.setViewState,
            this.state.skipValue,
          )}
        {this.state.viewState === DcViewState.HOLD_API_FAILURE_RESPONSE &&
          this.props.renderHoldAPIFailureView()}
        {this.state.viewState === DcViewState.APPROVE_REQUEST_LOADING &&
          this.renderProgressView('Loading...')}
        {this.state.viewState === DcViewState.APPROVE_REQUEST_ERROR &&
          this.renderApproveErrorView()}
        {this.state.viewState === DcViewState.APPROVE_REQUEST_SUCCESS && this.renderApprovedView()}
      </View>
    );
  }

  handleApproverAction(cta) {
    const { reviewDetail } = this.props.reviewResponse;
    if (cta.action === ACTION_BUTTONS.CTA_APPROVE) {
      DateChangeTrackingHelper.trackClickableEvent(
        this.props.pageName,
        'ODCStatus_approve_clicked',
        this.props.odcPreviewResponse,
        this.props.isCorporateBooking
      );
      // call the api to approve
      this.getApproverActionData(reviewDetail.bookingId, this.props.workflowId, '', '', 'approved');
    }

    if (cta.action === ACTION_BUTTONS.CTA_REJECT) {
      DateChangeTrackingHelper.trackClickableEvent(
        this.props.pageName,
        'ODCStatus_reject_clicked',
        this.props.odcPreviewResponse,
        this.props.isCorporateBooking
      );
      // open reason for rejection overlay
      this.setState({ showRejectionOverlay: true });
    }
  }
}
