import { StyleSheet } from 'react-native';
import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';

export default (theme, fonts) =>
StyleSheet.create({
  pageWrapper: {
    flex: 1,
    backgroundColor: '#f2f2f2'
  },
  pageScroll: {
    flex: 1
  },
  pageScrollBtmSpace: {
    height: 58
  },
  pageScrollTopSpace: {
    height: 0,
    marginTop: -5
  },
  footerBtnWrapper: {
    padding: 12,
    position: 'absolute',
    zIndex: 2,
    bottom: 0,
    left: 0,
    width: '100%'
  },
  reqMessageWrapper: {
    padding: 15,
    paddingTop: 8
  },
  reqMessage: {
    fontSize: 14,
    color: theme.color.defaultTextColor,
    fontFamily: fonts.regular,
    marginBottom: 6
  },
  submitReqBtnText: {
    fontSize: 12,
    color: theme.color.azure,
    fontFamily: fonts.black,
    paddingVertical: 6
  },
  headerTitle: {
    fontSize: 16,
    color: theme.color.black,
    fontFamily: fonts.bold
  },
  headerSubTitle: {
    fontSize: 12,
    fontFamily: fonts.regular,
    color: theme.color.disabledBtnBg,
    marginTop: 3
  },
  whiteCard: {
    backgroundColor: '#ffffff',
    padding: 15,
    marginVertical: 8,
    marginBottom: 8,
    elevation: 2,
    shadowColor: '#000000',
    shadowOffset: {width: 0, height: 0},
    shadowOpacity: 0.3,
    shadowRadius: 1
  },
  cardTitle: {
    color: theme.color.gray,
    fontSize: 21,
    fontFamily: fonts.light,
    marginBottom: 10
  },
  cardSubTitle: {
    fontSize: 12,
    color: theme.color.gray,
    fontFamily: fonts.regular,
    marginBottom: 20
  },
  anchorMD: {
    color: theme.color.azure,
    fontSize: 14,
    letterSpacing: 0.4,
    fontFamily: fonts.bold
  },
  marginTop20: {
    marginTop: 20
  },
  marginBottom20: {
    marginBottom: 20
  },
  segmentCard: {
    backgroundColor: '#ffffff',
    padding: 15,
    marginVertical: 0,
    marginBottom: 1,
    elevation: 2,
    shadowColor: '#000000',
    shadowOffset: {width: 0, height: 0},
    shadowOpacity: 0.3,
    shadowRadius: 1
  },
  listWrapper: {
    borderRadius: 4,
    overflow: 'hidden',
    borderBottomColor: 'transparent',
    paddingTop: 10,
    paddingBottom: 10,
    ...getPlatformElevation(2)
  },
  destRow: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  cityText: {
    marginTop: 5,
    fontSize: 12,
    color: theme.color.black28,
    fontFamily: fonts.bold
  },
  arrowIcon: {
    marginTop: 5,
    width: 10,
    height: 7,
    marginHorizontal: 5
  },
  smallText: {
    fontSize: 10,
    color: theme.color.defaultTextColor,
    fontFamily: fonts.semibold,
    marginLeft: 5,
    marginTop: 7,
    marginBottom: 0,
    lineHeight: 10
  },
  attachedItem: {
    ...getPlatformElevation(2),
    backgroundColor: theme.color.white,
    padding: 4,
    borderRadius: 4,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 5
  },
  attachedImgWrapper: {
    overflow: 'hidden',
    borderRadius: 2,
    marginRight: 8
  },
  attachedImg: {
    height: 16,
    width: 16
  },
  attachedImgName: {
    fontSize: 12,
    fontFamily: fonts.regular,
    color: theme.color.grey,
    marginRight: 4
  },
  greenTick: {
    width: 12,
    height: 9,
    marginTop: 3
  },
  deleteIcon: {
    height: 24,
    width: 24
  },
  paraText: {
    fontSize: 14,
    color: theme.color.black28,
    fontFamily: fonts.regular,
    marginBottom: 5
  },
  flexRow: {
    flexDirection: 'row'
  },
  spaceBetween: {
    justifyContent: 'space-between'
  }
});


