import React from 'react';
import { View, Image, Text, TouchableOpacity } from 'react-native';
import { getFont } from '../../../../PostSalesStyles';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {TG_ICON} from '../../../Utils/RailImageConstant';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import createStyles, {createHtmlStyle} from './tripGuaranteeCard'
import BaseButton, { BUTTON_VARIANT } from 'apps/post-sales/src/Common/BaseButton';
import HTMLView from 'react-native-htmlview';
import { isEmpty } from 'lodash';
import { getStaticData } from 'apps/post-sales/src/staticData/staticData';
import InfoCardTGS from './InfoCardTGS'

const TripGuaranteeCard = ({knowMoreClickHandler, proceedClickHandler, tgsUpgradeCardData, data}) => {
  const fonts = getFont();
  const { psTheme: theme } = useTheme();
  const styles =  createStyles(theme,fonts);
  const htmlStyle = createHtmlStyle(theme, fonts);
  const { knowMoreText } = getStaticData();
  const {benefitList,perTravellerCostText,refundForBooking={}, tgsMotivationalText, topHeaderText, btnText} = tgsUpgradeCardData || {};

  const continueClickHandler = () => {
      proceedClickHandler();
  }
  return (
    <View style={styles.tripGuaranteeCard}>
      <View style={styles.tripWhiteCard}>
        <View style={[styles.headingSection]}>
          <View style={[AtomicCss.flexRow, AtomicCss.flex1, AtomicCss.alignCenter]}>
            <Image style={styles.tgIconStyle} source={(TG_ICON)} />
            {!isEmpty(topHeaderText) && <Text style={styles.headingText}>{topHeaderText}</Text>}
          </View>
          {!isEmpty(knowMoreText) && <TouchableOpacity>
             <Text style={[AtomicCss.azure, fonts.fsStyle.font12, fonts.boldFontFamily]} onPress={knowMoreClickHandler}>{knowMoreText.toUpperCase()}</Text>
          </TouchableOpacity>}
        </View>
        <View style={styles.detailsSection}>
          <View style={styles.highlighterBar}></View>
          <HTMLView
            value={`<q>${tgsMotivationalText}</q>`}
            stylesheet={htmlStyle}
          />

          <View style={styles.infoCardSection}>
            {!isEmpty(benefitList) && benefitList.map((benefitData)=>{
              const {benefitImageUrl, benefitText, benefitHeader, bottomImageList} = benefitData;
              return (
                <InfoCardTGS
                  key={benefitImageUrl}
                  isCardWidth
                  iconUrl={benefitImageUrl}
                  heading={benefitHeader}
                  desc={benefitText}
                  imageList={bottomImageList}
                />
              )
            })}
            <View style={[styles.infoCards, AtomicCss.flex1]}>
            {!isEmpty(refundForBooking) &&
            refundForBooking.map(( item, index) => {
              const validate = !isEmpty(item.key) && !isEmpty(item.value);
              return validate&& (
                <View style={[AtomicCss.flex1,AtomicCss.flexRow,AtomicCss.spaceBetween]} key={index}>
                  <HTMLView
                      value={`<q>${item.key}</q>`}
                      stylesheet={htmlStyle}
                  />
                  <View style={[ AtomicCss.marginTop4 ]}>
                    <HTMLView
                      value={`<q>${item.value}</q>`}
                      stylesheet={htmlStyle}
                    />
                  </View>
                </View>
              )
            })}
              </View>
              
          </View>
        </View>
        <View style={styles.btnSection}>
          {!isEmpty(perTravellerCostText) && <Text style={[ AtomicCss.blackText,styles.priceContainerStyle]}>
            <HTMLView
                value={`<q>${perTravellerCostText}</q>`}
                stylesheet={htmlStyle}
            />
          </Text>}
          
          <BaseButton
            buttonStyle={{}}
            textStyle={{}}
            isThrottleRequired={true}
            throttleInterval={1000}
            text={!isEmpty(btnText) && btnText.toUpperCase()}
            variant={BUTTON_VARIANT.CAPSULE}
            clickHandler={continueClickHandler}
            shadow
          />
        </View>
       
      </View>

    </View>
  )
}

export default TripGuaranteeCard;