import {combineReducers} from 'redux';
import {configureStore, registerReducerTransformer} from '@mmt/legacy-commons/AppState/asyncStorev2';
import {createLogger} from "redux-logger";
import {shouldLogAction} from "@mmt/legacy-commons/Helpers/actionsLogger"; // defaults to localStorage for web and AsyncStorage for react-native

const loggerMiddleware = createLogger({
  predicate: (getState, action) => __DEV__ && shouldLogAction(action)
});

const persistConfig = {
  key: 'root',
}


registerReducerTransformer(reducers => reducers);

const reducer = combineReducers({__DUMMY__: _dummyReducer});

const _dummyReducer = (state = {}) => state;

const store = configureStore(
  reducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);
export default store;
