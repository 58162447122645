import React from 'react';
import PropTypes from 'prop-types';
import {View, Text, Image, ScrollView} from 'react-native';
import isEmpty from 'lodash/isEmpty';
import Button from './Button';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getPlatformElevation from './getPlatformElevation';
import BasePage from '../../Common/PostSalesBasePage';
import ItineraryCardItem from './ItineraryCardItem';
import {changeCalIcon} from '../HolidayBookingConstants';

class ItineraryCard extends BasePage {
  static navigationOptions = {
    header: null
  };

  itinerarySlider(item, index, firstItem) {
    const list = [];
    list.push(
      <ItineraryCardItem
        item={item}
        index={index}
        firstItem={firstItem}
        {...this.props}/>
    );
    return list;
  }

  render() {
    const {itineraryCard, buttons} = this.props;
    return (
      <View style={[AtomicCss.marginBottom10, styles.cardBorder]}>
        <View style={[styles.infoCardWrapper]}>
          <View style={styles.headingWrapper}>
            <View style={styles.headingIconWrapper}>
              <Image style={styles.headingIconStyle} source={changeCalIcon}/>
            </View>
            <Text
              style={[AtomicCss.defaultText, AtomicCss.blackFont, AtomicCss.font14]}>
              ITINERARY</Text>
          </View>
          <View>
            <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
              <View style={[styles.sliderWrapper, AtomicCss.marginBottom15]}>
                {itineraryCard && !isEmpty(itineraryCard.itineraryCardSegments)
                && itineraryCard.itineraryCardSegments.map((item, index) => {
                  const firstItem = index === 0 ? [styles.fisrtItemStyle] : '';
                  return (
                    <React.Fragment>
                      {this.itinerarySlider(item, index, firstItem)}
                    </React.Fragment>
                  );
                })}
              </View>
            </ScrollView>
            {!isEmpty(buttons) &&
            <View style={styles.bntWrapper}>
              {buttons.map((item) => {
                return (
                  <Button
                    button={item}
                    handleClick={this.props.handleButtonClick}
                    btnWidth={220}
                  />
                );
              })
              }
            </View>
            }
          </View>
        </View>
      </View>
    );
  }
}

const styles = {
  cardBorder: {
    borderTopColor: '#e4e4e4',
    borderBottomColor: '#e4e4e4',
    borderTopWidth: 1,
    borderBottomWidth: 1
  },
  infoCardWrapper: {
    backgroundColor: '#fff',
    ...getPlatformElevation(2),
    marginBottom: 1,
    paddingVertical: 16,
    flex: 1
  },
  headingWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 15
  },
  headingIconWrapper: {
    width: 46,
    alignItems: 'center',
    alignSelf: 'flex-start'
  },
  headingIconStyle: {
    width: 16,
    height: 20
  },
  sliderWrapper: {
    flexDirection: 'row'

  },
  slidercard: {
    width: 200,
    height: 205,
    ...getPlatformElevation(2),
    marginBottom: 2,
    marginRight: 10,
    backgroundColor: '#fff',
    borderRadius: 4,
    borderWidth: 1,
    borderColor: '#f2f2f2'
  },
  imgWrapper: {
    width: 200,
    height: 150
  },
  imgStyle: {
    width: '100%',
    height: '100%'
  },
  descWrapper: {
    padding: 10
  },
  fisrtItemStyle: {
    marginLeft: 16
  },
  bntWrapper: {
    alignItems: 'center',
    justifyContent: 'center'
  }
};

ItineraryCard.propTypes = {
  itineraryCard: PropTypes.object.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
  buttons: PropTypes.array.isRequired
};

export default ItineraryCard;
