import React from 'react';
import { View, Text, Image } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import Button from '../../../Common/Button/index';
import styles from './rydeBottomOverlayMessageCss';
import { TopIllustration } from '../../CabImageConstants';

const RydeBottomOverlayMessageComponent = ({handleBottomOverlay, overlayContent}) => {
    const { featureList, heading, subHeading, subHeading2, iconUrl, text } = overlayContent || {};
    return (
        <View style={styles.OverlayContentWrapper}>
           <View style={styles.overlayTopline}></View>
                <View style={styles.topillustration}>
                    <View style={styles.topCurve}>
                       
                    </View>
                    <View style={styles.giftIconillustrationWrapper}>
                        <Image style={styles.giftIconillustration} source={TopIllustration}/>
                    </View>
                </View>
                <View style={styles.innerInfoSection}>
                    {!!heading && <Text style={[styles.title, AtomicCss.textCenter]}>{heading}</Text>}
                    {!!subHeading && <Text style={[AtomicCss.boldFont, AtomicCss.defaultText, AtomicCss.font18, AtomicCss.textCenter]}>{subHeading}</Text>}
                    <View style={[AtomicCss.flexRow, AtomicCss.alignSelfCenter, AtomicCss.marginBottom24]}>
                        {!!iconUrl &&  <Image style={styles.rideImgStyle} source={{uri: iconUrl}} />}
                        {!!subHeading2 && <Text style={[AtomicCss.boldFont, AtomicCss.defaultText, AtomicCss.font18,AtomicCss.purpleText]}>{subHeading2}</Text>}
                    </View>
                    {!!text && <Text style={[AtomicCss.boldFont, AtomicCss.defaultText, AtomicCss.font15, AtomicCss.textCenter, AtomicCss.marginBottom16]}>{text}</Text>}
                    <View>
                    <View style={styles.benefitList}>
                    {!!featureList && featureList.length > 0 && featureList.map((item,index)=>{
                            return(
                                <InfoItem
                                    item={item}
                                    index={index}
                                    key={index}
                                />
                            )
                        })}
                    </View>
                    <Button
                        clickHandler={handleBottomOverlay}
                        // btnWidth={'100%'}
                        btnBg="gradientBtn"
                        btnType="flat"
                        fontSize="font16"
                        btnTxt="Ok, got it!" />
                    </View>
                </View>
          

        </View>
    )
}

const InfoItem = ({item, index}) => {
    return (<View style={styles.listWrapper}>
                    <View style={styles.imgWrapper}>
                        {!!item.iconUrl && <Image style={styles.iconStyle} source={{uri: item.iconUrl}} />}
                    </View>
                    {!!item.info && <Text style={[AtomicCss.font14, AtomicCss.blackText, AtomicCss.boldFont]}>{item.info}</Text>}
                </View>
            )
}

export default RydeBottomOverlayMessageComponent