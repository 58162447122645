import { CAB_GOOGLE_DIREACTION_URL } from '../../../../utils/NetworkUtils';
import { getHeaders } from '../../bookingDetails/data/api';
import { getGoogleApiRequest } from './requestAdapter';

export const fetchGoogleApiDirection = async (fromLocation, toLocation, bookingId) => {
  try {
    const headers = await getHeaders(bookingId, 'CAB_DIRECTIONS', 'CAB');
    const request = getGoogleApiRequest(fromLocation, toLocation, bookingId);
    const response = await fetch(CAB_GOOGLE_DIREACTION_URL, {
      method: 'POST',
      body: JSON.stringify(request),
      headers,
    });
    if (response && response.ok) {
      const body = await response.json();
      return { response: body };
    } else {
      const body = await response?.json();
      return { error: body };
    }
  } catch (error) {
    console.log('error while fetching google api direction', error);
    return { error: error };
  }
};