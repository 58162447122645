import React from 'react';
import {View, Text, Image, StyleSheet} from 'react-native';
import PropTypes from 'prop-types';
import AtomicCss from '../../commonStyles/AtomicCss';
import {passengerImage} from '../../details/RailBookingDetailsUtil';
import { getStaticData } from '../../../staticData/staticData';
import { getFont } from '../../../PostSalesStyles';

class CancellationBreakup extends React.Component {
    static navigationOptions = {
      header: null
    };

    render() {
      return (
        <View style={[styles.borderbtm, styles.bggreyLight, styles.CancelPerson,
          AtomicCss.elevation1]}
        >
          {this.renderHeaderText()}
          {this.renderPaxView()}
        </View>
      );
    }

    renderHeaderText = () => {
      let paxCount = 0;
      if (this.props.passengers) {
        paxCount = this.props.passengers.length;
      }
      const staticData = getStaticData();
      const { fsStyle, ...fonts } = getFont(true);
      const { trainCancellationText } = staticData;

      const headingTextWithVar = paxCount > 1 ? trainCancellationText.additionalText.cancellingPassengersCountText : trainCancellationText.additionalText.cancellingPassengerCountText;
      const headingText = headingTextWithVar ? headingTextWithVar.replace('{pax_count}', paxCount) : "";
      return (
        <Text style={[AtomicCss.blackText, AtomicCss.marginBottom10,
          fsStyle.font14, fonts.blackFontFamily]}
        >{headingText}
        </Text>
      );
    }
    renderPaxView = () => {
      const views = [];
      if (this.props.passengers) {
        this.props.passengers.forEach((passenger) => {
          views.push(this.passengerView(passenger));
        });
      }
      return views;
    }

    passengerView = passenger => {
      const { fsStyle } = getFont(true);
      return (
      <View
        style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom10]}
        key={`${passenger.paxId}`}
      >
        <Image
          style={[styles.femaleIcon, styles.mar8LR]}
          source={passengerImage(passenger)}
        />
        <Text style={[fsStyle.font12, AtomicCss.blackText]}>
          {`${passenger.fullName} `}
        </Text>
        <Text style={[fsStyle.font12, AtomicCss.lightGrey]}>
          {passenger.paxInfo}
        </Text>
      </View>
    )
  }
}

CancellationBreakup.propTypes = {
  passengers: PropTypes.array.isRequired
};
const styles = StyleSheet.create({
  borderbtm: {
    borderBottomWidth: 1,
    borderBottomColor: '#ddd'
  },
  bggreyLight: {
    backgroundColor: '#f9f9f9'
  },
  CancelPerson: {paddingVertical: 12, paddingHorizontal: 16},
  femaleIcon: {
    width: 16,
    height: 16
  },
  mar8LR: {marginHorizontal: 8}
});

export default CancellationBreakup;
