import React from 'react';
import PropTypes from 'prop-types';
import {View, Text, Image, TouchableHighlight, TouchableOpacity, StyleSheet} from 'react-native';
import {fonts, statusBarHeightForIphone} from '@mmt/legacy-commons/Styles/globalStyles';
import CapsuleBtnFill from '../../../hotel/details/ActionButton/CapsuleBtnFill';
import {TOP_STATUS_ICON} from '../../Utils/RailImageConstant';
import { getFont } from '../../../PostSalesStyles';

class OverlayAlert extends React.Component {
  static navigationOptions = {
    header: null
  }

  render() {
    const { fsStyle } = getFont(true);
    return (
      <View style={styles.overlayContainer}>
        <TouchableHighlight style={styles.overlayBg} onPress={this.props.bgTapAction}>
          <Text>.</Text>
        </TouchableHighlight>
        <View style={styles.overlayContent}>
          <View style={styles.flexRow}>
            <Image source={TOP_STATUS_ICON} style={styles.CancelledIcon} />
            <View style={[styles.mar15Bt, styles.headingTextContainer]}>
              { !!this.props.titleText && <Text style={[styles.overlayHead, fsStyle.font23]}>{this.props.titleText}
              </Text>}
              { !!this.props.heading2Text && <Text style={[styles.overlayHead, fsStyle.font23]}>{this.props.heading2Text}
              </Text>}
            </View>
          </View>
          <View style={[styles.mar25Bt]}>
            <Text style={[styles.cancelTxt, fsStyle.font16]}>
              {this.props.subTitleText}
            </Text>
          </View>
          <View style={[styles.flexRow, styles.spaceBetween]}>
            <TouchableOpacity onPress={this.props.negativeBtnOnTap} style={styles.alignCenter}>
              <Text style={[styles.actionBlueText, fsStyle.font16]}>{this.props.negativeBtnText}</Text>
            </TouchableOpacity>
            <View style={[styles.alignCenter, styles.flexRow, styles.positiveButton]}>
              <CapsuleBtnFill
                action={{actionLabeltext: this.props.positiveBtnText}}
                clickHandler={this.props.positiveBtnOnTap}
                style={styles.alignCenter}
              />
            </View>
          </View>
        </View>
      </View>

    );
  }
}

OverlayAlert.propTypes = {
  positiveBtnText: PropTypes.string.isRequired,
  negativeBtnText: PropTypes.string.isRequired,
  positiveBtnOnTap: PropTypes.func.isRequired,
  negativeBtnOnTap: PropTypes.func.isRequired,
  bgTapAction: PropTypes.func.isRequired,
  titleText: PropTypes.string,
  heading2Text: PropTypes.string,
  subTitleText: PropTypes.string.isRequired
};


const styles = StyleSheet.create({
  overlayContainer:
  {
    justifyContent: 'flex-end',
    position: 'absolute',
    marginTop: -statusBarHeightForIphone,
    height: '100%',
    width: '100%',
    zIndex: 1000,
    elevation: 3
  },
  overlayBg: {
    backgroundColor: 'rgba(0,0,0,.5)',
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 1
  },
  overlayContent: {
    backgroundColor: '#fff',
    paddingVertical: 20,
    paddingHorizontal: 20,
    position: 'relative',
    zIndex: 100,
    marginBottom: -statusBarHeightForIphone,
    justifyContent: 'flex-end'
  },
  actionBlueText: {
    color: '#008cff',
    fontFamily: fonts.bold
  },
  cancelTxt: {
    color: '#4a4a4a',
    fontFamily: fonts.regular,
    lineHeight: 22
  },
  overlayHead: {
    color: '#000'
  },
  CancelledIcon: {
    width: 85,
    height: 80,
    marginRight: 10,
    marginTop: -16
  },
  headingTextContainer: {
    width: '75%'
  },
  positiveButton: {
    width: 150,
    paddingTop: 10,
    marginRight: -10
  },
  flexRow: {flexDirection: 'row'},
  mar15Bt: {marginBottom: 15, marginRight: 15},
  alignCenter: {alignSelf: 'center'},
  spaceBetween: {justifyContent: 'space-between'},
  mar25Bt: {marginBottom: 25},
  flex1: {flex: 1},
  font12: {fontSize: 12}

});
export default OverlayAlert;
