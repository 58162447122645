import {fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';

const styles = {

  WebCheckinOverlayWrapper: {
    flex: 1,
    backgroundColor: '#fff',
    justifyContent: 'space-between',
    position: 'absolute',
    elevation: 10,
    zIndex: 10,
    width: '100%',
    height: '100%'
  },
  sectorWrapper: {
    ...getPlatformElevation(2),
    backgroundColor: '#fff',
    borderRadius: 4,
    marginBottom: 12,
    minHeight: 70,
    borderColor: '#ddd',
    borderWidth: 1
  },
  sectorinnerDtls: {
    padding: 15
  },
  Radio: {
    width: 18,
    height: 18,
    backgroundColor: '#fff',
    borderRadius: 20,
    borderWidth: 1,
    borderColor: '#008cff',
    marginRight: 20,
    marginTop: 5
  },
  RadioInside: {
    width: 12,
    height: 12,
    backgroundColor: '#008cff',
    borderRadius: 20,
    marginLeft: 2,
    marginTop: 2
  },
  wrapperSector: {
    padding: 12
  },
  arrowStyle: {
    width: 10,
    height: 9,
    marginHorizontal: 5
  },
  cityTxt: {
    fontSize: 14,
    fontFamily: fonts.bold,
    color: '#505050'
  },
  midTxtStyle: {
    color: '#808080',
    fontSize: 14,
    fontFamily: fonts.regular,
    borderRadius: 2
  },
  copyCodeWrapper: {
    borderWidth: 1,
    borderColor: '#008cff',
    flexDirection: 'row',
    padding: 5,
    paddingHorizontal: 10,
    alignSelf: 'flex-start',
    marginTop: 10
  },
  sectorHeading: {
    borderBottomWidth: 1,
    borderBottomColor: '#e7e7e7',
    padding: 12
  },
  planeImgStyle: {
    width: 24,
    height: 24,
    marginRight: 10
  },
  btnWrapper: {
    paddingHorizontal: 16,
    paddingVertical: 16,
    position: 'absolute',
    width: '100%',
    bottom: 0
  },
  scrollViewStyle: {
    paddingBottom: 100

  },
  oneSectorTwoPnrStyle: {
    marginTop: -15
  },
  errorState: {
    borderRadius: 4,
    padding: 8,
    marginTop: 10
  },
  errorStateprogress: {
    backgroundColor: '#ffedd1'
  },
  errorStatefailed: {
    backgroundColor: '#ffd3d4'
  },
  errorStateTxt: {
    fontFamily: fonts.regular,
    fontSize: 12
  },
  errorStateTxtprogress: {
    color: '#cf8100'
  },
  errorStateTxtfailed: {
    color: '#eb2026'
  }
};

export default styles;

