import React, { useEffect } from 'react';
import { View, Image, Text, TouchableOpacity, Platform, StyleSheet } from 'react-native'; // Import the necessary components
import { TripGuaranteeCardProps } from './type';
import HTMLView from 'react-native-htmlview';
import LinearGradient from 'react-native-linear-gradient';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import isEmpty from 'lodash/isEmpty';
import CabBookingTrackingHelper from 'apps/post-sales/src/cab/CabBookingTrackingHelper';
import { createStyles } from './styles';

export default function TripGuaranteeCard({ data, pageName }: TripGuaranteeCardProps) {
  const { header, content, actionTitle, url } = data || {};
  const { icon, title, subTitle } = header || {};
  const { list, title: contentTitle } = content || {};
  const fonts = getFont();
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);
  const getHtmlStyle = () =>
    StyleSheet.create({
      p: {
        fontSize: fonts.fontSize.font14,
        ...fonts.regularFontFamily,
        color: theme.color.black,
      },
      b: {
        color: theme.color.black,
        ...fonts.boldFontFamily,
      },
    });
  const htmlStyle = getHtmlStyle();
  const handleDeepLink = () => {
    CabBookingTrackingHelper.trackClickEvent(pageName, 'Mob_Cabs_MIMA_RideGuarantee_Clicked');
    if (!isEmpty(url)) {
      if (Platform.OS === 'web') {
        //@ts-ignore
        window.open(url, '_blank').focus();
      } else {
        GenericModule.openDeepLink(url);
      }
    }
  };

  useEffect(() => {
    // Tracking code
    CabBookingTrackingHelper.trackClickEvent(pageName, 'Mob_Cabs_MIMA_RideGuarantee_Shown');
  }, []);

  return (
    <View style={[AtomicCss.paddingHz16, AtomicCss.marginBottom10]}>
      <LinearGradient
        start={{ x: 1, y: 0 }}
        end={{ x: 0.7, y: 1 }}
        colors={[theme.color.violet, theme.color.white]}
        style={[AtomicCss.borderRadius16]}
      >
        <View style={styles.contentStyle}>
          <View style={styles.topSection}>
            {!isEmpty(icon) && <Image source={{ uri: icon }} style={styles.rideIconStyle} />}
            <View style={AtomicCss.flex1}>
              {!isEmpty(title) && <Text style={styles.titleText}>{title}</Text>}
              {!isEmpty(subTitle) && <Text style={styles.purpleColorText}>{subTitle}</Text>}
            </View>
          </View>
          {!isEmpty(contentTitle) && (
            <View style={AtomicCss.paddingTop16}>
              <Text style={styles.bodyTitleStyle}>{contentTitle} </Text>
            </View>
          )}
          {!isEmpty(list) &&
            list.map((item, index) => {
              const { icon, htmlText } = item || {};
              return (
                <View key={index} style={styles.listStyle}>
                  {!isEmpty(icon) && <Image style={styles.tickStyle} source={{ uri: icon }} />}
                  {!isEmpty(htmlText) && <HTMLView value={htmlText} stylesheet={htmlStyle} />}
                </View>
              );
            })}
          {!isEmpty(actionTitle) && (
            <TouchableOpacity onPress={handleDeepLink}>
              <Text style={styles.actionTitleStyle}>{actionTitle}</Text>
            </TouchableOpacity>
          )}
        </View>
      </LinearGradient>
    </View>
  );
}
