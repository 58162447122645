import { LOBNAMES } from '../../../PostSalesConstant';
import { httpGet, httpPost } from '../../../data/api';
import { getTicketData, makePageData, preparePlanningData } from './dataAdapter';
import { ApiRequestTypes, ViewState } from '../summaryConstants';
import {
  BOOKING_SUMMARY_V6_URL,
  BOOKING_SUMMARY_FILTER_DATA,
  MSR_UPDATE_SEARCH_API,
  LOST_BOOKING_SUMMARY_URL,
  WHERE_2_GO_URL,
  SUMMARY_DOWNLOAD_VIEW_API,
  DYNAMIC_FILTERS_URL,
  getCommonHeaders,
  HOLIDAY_PLANNING_URL,
  SOCIAL_MEDIA_URL
} from 'apps/post-sales/src/utils/NetworkUtils';
import { getShowMoreFilterRequestData, getTicketRequestData } from "./requestAdapter";
import { showLongToast } from 'packages/legacy-commons/Common/Components/Toast';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import { getStaticData } from 'apps/post-sales/src/staticData/staticData';
import { getWhere2GoImage } from './dataAdapter';
import { SelectedFilter, summaryStateObjectType, AllFiltersDataType, BookingData, initialStateObjectType, WhereToGOImage, PageDataType, MyRequestsCardDataInterface, BookingIdToTicketDetailMapType, SummaryStateArrayType, SocialMediaResponse, } from '../types';
import { PAGE_BOOKINGS_STATUS, PROMISE_STATUS, INITIAL_PAGE_NUMBER } from '../summaryConstants';
import { promiseAllSettled } from 'apps/post-sales/src/utils/PostSaleUtil';
import { shouldLoadPlanningData } from '../../utils';

export async function fetchBookings(status: string, pageNumber: number, searchValue: string): Promise<PageDataType> {
  const data = await httpGet(
    `${BOOKING_SUMMARY_V6_URL}?status=${status}&pageNo=${pageNumber - 1}&searchValue=${searchValue}`,
    null,
    { uniqueIdType: ApiRequestTypes.SUMMARY, psLob: LOBNAMES.POST_SALES },
  );
  return makePageData(data);
}

export async function fetchTickets(): Promise<{myRequestsCardData: MyRequestsCardDataInterface, bookingIdtoTicketDetailmap: BookingIdToTicketDetailMapType}| null> {
  const searchTicketRequest = getTicketRequestData();
  const data = await httpPost(MSR_UPDATE_SEARCH_API, searchTicketRequest, {
    uniqueIdType: ApiRequestTypes.SUMMARY,
    psLob: LOBNAMES.POST_SALES,
  });

  if (data) {
    return getTicketData(data);
  } else {
    return null;
  }
}



export async function fetchLostIDBooking() {
  const data = await httpGet(LOST_BOOKING_SUMMARY_URL, null, {
    uniqueIdType: ApiRequestTypes.SUMMARY,
    psLob: LOBNAMES.POST_SALES,
  });
  return data;
}

export async function fetchHolidayPlannings() {
  const loadPlanningData = await shouldLoadPlanningData();
  if(loadPlanningData) {
    const data = await httpGet(HOLIDAY_PLANNING_URL, {
      uniqueIdType: ApiRequestTypes.PLANNING_DETAILS,
    });
    if (data && data.success) {
      return preparePlanningData(data);
    } else {
      return null;
    }
  } else {
    return null;
  }
}



export const fetchDataWithFilters = async (pageNo: number, request: SelectedFilter[]): Promise<PageDataType> => {
  const data = await httpPost(BOOKING_SUMMARY_FILTER_DATA, {
    page: pageNo,
    filters: request,
  });
  return makePageData(data);
};

export const fetchMoreBookings = async (status: string, pageNumber: number, searchValue: string): Promise<PageDataType> => {
  const data = await httpGet(
    `${BOOKING_SUMMARY_V6_URL}?status=${status}&pageNo=${pageNumber - 1}&searchValue=${searchValue}`,
    null,
    { uniqueIdType: ApiRequestTypes.SUMMARY, psLob: LOBNAMES.POST_SALES },
  );
  return makePageData(data);
}

export const loadWhereToGoImageData = async (locusId = 'CTBOM'): Promise<string | null> => {
  try {
    const url = WHERE_2_GO_URL;
    const request = {
      query:
       `{\n  location(\n    me: {lobCode: W2G, platform: mobile}\n    locationFetchInput: {queryType: BY_POI, poiIds: ["${locusId}"]}\n    paginationInput: {first: 1}\n  ) {\n    ... on LocConnection {\n      edges {\n        node {\n          assets(mediaFetchInput: {queryType: BY_ORDER, sourceLob:W2G },paginationInput: {first: 1}){\n            edges{\n              node{\n                storageMeta{\n                  cdnPath\n                  cdnRoot\n                }\n              }\n            }\n          \n          }\n        }\n      }\n    }\n    ...on LocationNotFound {\n      message\n    }\n  }\n}`,
      variables: {},
    };
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(request),
    };
    const response = await fetch(url, requestOptions);
    const result = await response.json();
    const whereToGoImage = getWhere2GoImage(result);
    return whereToGoImage;
  } catch (error) {
    console.error('API error:', error);
    return null;
  }
};

export const loadWhereToGoNoTripsData = async (): Promise<WhereToGOImage[] | null> => {
  try {
    const url = WHERE_2_GO_URL;
    const request = {
      query:
        '{\n  collection(\n    me: {lobCode: W2G, platform: mobile, userId:"Test"}\n    collectionFetchInput: {collectionType: BY_AUTHOR}\n  ) {\n    ... on CollectionResponse {\n      list {\n        title\n        thumbnail\n        deeplink\n      }\n    }\n  }\n}',
      variables: {},
    };
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(request),
    };
    const response = await fetch(url, requestOptions);
    const result = await response.json();
    const whereToGoList = result?.data?.collection?.list;
    return whereToGoList;
  } catch (error) {
    console.error('API error:', error);
    return null;
  }
};

export const loadWhereToGoData = async (): Promise<WhereToGOImage[] | null> => {
  try {
    const url = WHERE_2_GO_URL;
    const request = {
      query:
        '{\n  collection(\n    me: {lobCode: W2G, platform: mobile, userId:"Test"}\n    collectionFetchInput: {collectionType: BY_INTEREST}\n  ) {\n    ... on CollectionResponse {\n      list {\n        title\n        thumbnail\n        deeplink\n      }\n    }\n  }\n}',
      variables: {},
    };
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(request),
    };
    const response = await fetch(url, requestOptions);
    const result = await response.json();
    const whereToGoList = result?.data?.collection?.list;
    return whereToGoList;
  } catch (error) {
    console.error('API error:', error);
    return null;
  }
};

export const loadSocialMediaData = async (): Promise<SocialMediaResponse | null> => {
  try {
    const url = SOCIAL_MEDIA_URL;
    return fetch(url).then(res => {
      return res.json();
    }).catch(err => {
      console.error('API error:', err);
      return null;
    });
  } catch (error) {
    console.error('API error:', error);
    return null;
  }
}

export const loadData = async (pageStatus: string, page: number, loadMore: boolean = false, summaryState: summaryStateObjectType): Promise<{ _bookings: initialStateObjectType, bookingStatus: string, pageNumber: number, loadMore: boolean }| null> => {
  const prevState = {
    ...summaryState,
  };
  const bookingStatus = pageStatus;

  let pageNumber: number;
  if (page) {
    pageNumber = page;
  } else {
    if (prevState[bookingStatus]) {
      pageNumber = prevState[bookingStatus]?.currentPage + 1;
    } else {
      pageNumber = 1;
    }
  }
  return fetchBookings(bookingStatus, pageNumber, '')
    .then((_bookings) => {
      return ({ _bookings, bookingStatus, pageNumber, loadMore });
    })
    .catch((err) => {
      console.log(err);
      if (!loadMore) { return {error: true}; }
      return null;
    });
};

export const loadShowMoreFilteredData = async (bookingStatus: string, pageNo: number, summaryState: summaryStateObjectType, selectedFilters: {current: SelectedFilter[]}): Promise<{ _bookings: initialStateObjectType, bookingStatus: string, filters: selectedFilters, pageNumber: number, loadMore: boolean }| null>  => {
  const {errorFilteredText} = getStaticData(true);
  const { filteredPageNumber, updatedSelectedFilters } = getShowMoreFilterRequestData(summaryState, selectedFilters, pageNo, bookingStatus);
  return fetchDataWithFilters(filteredPageNumber, updatedSelectedFilters)
    .then(
      (bookingData) => {
        if (bookingData.errorCode) {
          showLongToast(errorFilteredText);
          return {viewState: ViewState.SHOW_MORE_LOADED, error: true };
        }
        return { _bookings: bookingData, bookingStatus, filters: selectedFilters.current, loadMore: true, filteredPageNumber };
      }).catch((error)=>{
        showLongToast(errorFilteredText);
          return {viewState: ViewState.SHOW_MORE_LOADED, error: true };
      });
};

export const downloadFile = async (properties): Promise<string | null> => {
  const {bookingId} = properties || {};
  const { downloadFailedText } = getStaticData();
  try {
    const userInfo = await HotelBookingModule.getRequestHeader(
      bookingId,
      'BOOKING_SUMMARY_NEW',
    );
    const { mmtAuth, loggingTrackingInfo } = userInfo || {};
    const response = await fetch(SUMMARY_DOWNLOAD_VIEW_API, {
      method: 'post',
      body: JSON.stringify(properties),
      headers: await getCommonHeaders(mmtAuth, loggingTrackingInfo),
    });
    const body = await response.json();
    if(!body.success){
      showLongToast(downloadFailedText);
      return null;
    }
    return body.pdf;
  } catch (error) {
    showLongToast(downloadFailedText);
    return null;
  }
};


export const loadDynamicFiltersData = async (dynamicFilterIds: string[]): Promise<AllFiltersDataType| null>  => {
  try {
    const dynamicFiltersData = await httpPost(DYNAMIC_FILTERS_URL, {
      dynamicFilterFields: dynamicFilterIds,
    });
    return dynamicFiltersData;
  } catch (error) {
    console.log("error", error);
    return null;
  }
};


export const fetchPageData = async (summaryState: summaryStateObjectType)=> {
  const data = await promiseAllSettled([loadData(PAGE_BOOKINGS_STATUS.ALL, INITIAL_PAGE_NUMBER, false, summaryState), fetchLostIDBooking(), fetchHolidayPlannings()])();
  const bookingsData = data[0] && data[0].status === PROMISE_STATUS.FULFILLED && data[0].value;
  const lostIdBookingData = data[1] && data[1].status === PROMISE_STATUS.FULFILLED && data[1].value;
  const planningsData = data[2] && data[2].status === PROMISE_STATUS.FULFILLED && data[2].value;
  return {bookingsData, lostIdBookingData, planningsData};
}