import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  cardBorder: {
    borderTopColor: '#e4e4e4',
    borderBottomColor: '#e4e4e4',
    borderTopWidth: 1,
    borderBottomWidth: 1,
  },
  infoCardWrapper: {
    backgroundColor: '#fff',
    justifyContent: 'space-between',
    ...getPlatformElevation(1.2),
    marginBottom: 1,
  },
  infoCardContent: {
    paddingHorizontal: 1,
    paddingTop: 16,
    flex: 1,
  },
  headingIconWrapper: {
    width: 46,
    alignItems: 'flex-start',
    top: 14,
    left: 16,
  },
  headingIconStyle: {
    width: 17,
    height: 21,
  },
  bottomRowSection: {
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 16,
    paddingRight: 16,
    justifyContent: 'space-between',
    backgroundColor: '#fff',
  },
  linkBtnTxt: {
    fontSize: 14,
    color: '#008cff',
  },

  totalRefundprice: {
    paddingRight: 10,
  },
  priceInfoWrapper: {
    borderRadius: 4,
    backgroundColor: '#ffedd1',
    padding: 12,
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 16,
  },
  priceInfoTxt: {
    color: '#cf8100',
  },
  btnWrapper: {
    marginBottom: 16,
    alignItems: 'center',
  },
});
export default styles;