import {colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import {Platform, Dimensions} from 'react-native';
import _getPlatformElevation from '@mmt/legacy-commons/Common/Components/Card/getPlatformElevation';
import webStyles from './AcmeCommonWebCss';

/**
 * DO NOT DELETE BELOW 5 CONSTANTS FROM THIS FILE. THESE HAVE BEEN ADDED TO FIX THE CIRCULAR DEPENDENCY ISSUE WITH CONSTANT.JS FILE
 */

export const PLATFORM_IOS = 'ios';
export const PLATFORM_ANDROID = 'android';
export const PLATFORM_WEB = 'web';

export const USER_PLATFORM = {
  IOS: Platform.OS === PLATFORM_IOS,
  ANDROID: Platform.OS === PLATFORM_ANDROID,
  WEB: Platform.OS === PLATFORM_WEB
};

export const getPlatformElevation = (elevation) => {
  if (!USER_PLATFORM.WEB) {
    return _getPlatformElevation(elevation);
  }

  // In case of web with non-zero elevation, return boxShadow. Otherwise blank object.
  if (elevation !== 0) {
    return {boxShadow: '0px 2px 10px 0px rgba(0,0,0,0.2)'};
  }
  return {};
};

export const DEVICE_WINDOW = Platform.OS === 'ios' ? Dimensions.get('screen') : Dimensions.get('window');

export const fontSizes = {
  xxs: 8,
  xs: 10,
  xs2: 11,
  sm: 12,
  reg: 14,
  md: 16,
  lg: 18,
  lg2: 20,
  lg3: 22
};

export const zIndexes = {
  z0: 0,
  z1: 1,
  z2: 2,
  z3: 3,
  z4: 4,
  z5: 5
};

export const gradient = {
  white: ['#ffffff', '#ffffff'],
  green: ['#219393', '#26bd99'],
  blue: ['#3023ae', '#c86dd7'],
  blue2: ['#00d2ff', '#3a7bd5'],
  blue3: ['#763ece', '#411da0'],
  blue4: ['#3f4447', '#7fb7e2'],
  blue5: ['#3023ae', '#912086'],
  lightBlue: ['#53b2fe', '#065af3'],
  lightBlue2: ['#065af3', '#53b2fe'],
  ratingPoor: ['#ff6d3f', '#ff3e5e'],
  ratingAverage: ['#f3cd52', '#f09819'],
  ratingGood: ['#219393', '#26bd99'],
  purple: ['#a768ff', '#5f88ff'],
  orange: ['#ff7f3f', '#ff3e5e'],
  yellow: ['#f3d452', '#f09819'],
  red: ['#ff7f3f', '#ff3e5e'],
  grey: ['#989898', '#989898'],
  pink: ['#faaca8', '#ddd6f3'],
  lightPink: ['#ffffff', '#ffeeed'],
  maroon: ['#f5515f80', '#9f046980'],
  grey2: [colors.grey2, colors.grey3, colors.grey4, colors.grey5]
};

const commonStyles = {
  pageWrapper: {
    paddingTop: 0
  },
  flexRow: {
    flexDirection: 'row'
  },
  flex1: {
    flex: 1
  },
  makeRelative: {
    position: 'relative'
  },
  alignSelfEnd: {
    alignSelf: 'flex-end'
  },
  alignCenter: {
    alignItems: 'center'
  },
  basicTextStyle: {
    backgroundColor: 'transparent'
  },
  whiteBox: {
    ...getPlatformElevation(Platform.select({ios: 3, android: 2})),
    backgroundColor: colors.white,
    borderRadius: 2
  },
  loaderWrapper: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    position: 'absolute',
    height: DEVICE_WINDOW.height,
    width: DEVICE_WINDOW.width,
    top: 0,
    left: 0,
    ...getPlatformElevation(Platform.select({ios: 4, android: 5}))
  },
  section: {
    padding: 15,
    borderBottomWidth: 1,
    borderBottomColor: colors.gray1
  },
  heading1: {
    fontSize: fontSizes.reg,
    fontFamily: fonts.black,
    color: colors.black,
    marginBottom: 10,
    lineHeight: 20
  },
  heading2: {
    fontSize: fontSizes.lg,
    fontFamily: fonts.black,
    color: colors.black,
    marginBottom: 10,
    lineHeight: 26
  },
  heading3: {
    fontSize: fontSizes.lg,
    fontFamily: fonts.bold,
    color: colors.black,
    marginVertical: 12,
    lineHeight: 22
  },
  heading4: {
    fontSize: fontSizes.md,
    fontFamily: fonts.regular,
    color: colors.black,
    marginBottom: 10
  },
  heading5: {
    fontSize: fontSizes.md,
    fontFamily: fonts.bold,
    color: colors.black,
    marginBottom: 7
  },
  topRadius4: {
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    overflow: 'hidden'
  },
  paraText: {
    fontSize: fontSizes.reg,
    fontFamily: fonts.regular,
    color: colors.defaultTextColor,
    paddingBottom: 14,
    lineHeight: 22
  },
  boldText: {
    fontFamily: fonts.bold
  },
  anchorMd: {
    color: colors.azure,
    fontFamily: fonts.bold,
    fontSize: fontSizes.sm
  },
  marginBtm10: {
    marginBottom: 10
  },
  brdBtmNone: {
    borderBottomWidth: 0,
    borderBottomColor: 'transparent'
  },
  filterRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 47,
    backgroundColor: colors.white,
    borderBottomColor: colors.greyBookedSeat,
    borderBottomWidth: 1
  },
  inText: {
    flex: 0.04,
    color: colors.black,
    fontSize: fontSizes.md,
    fontFamily: fonts.regular,
    textAlign: 'center'
  },
  dropDownWrapper: {
    flex: 0.48
  },
  selectDatesOverlayContainer: {
    padding: 0
  },
  selectDatesOverlayBody: {
    paddingTop: 0
  },
  progressViewWrapper: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  querySupportWrapper: {
    paddingBottom: 84
  },
  textRight: {
    textAlign: 'right'
  },
  contentEndCard: {
    alignItems: 'center',
    marginTop: 40,
    marginBottom: 20
  },
  contentEndCardTitle: {
    fontSize: fontSizes.sm,
    fontFamily: fonts.regular,
    textAlign: 'center',
    color: colors.defaultTextColor
  },
  contentEndCardDesc: {
    fontSize: fontSizes.sm,
    fontFamily: fonts.regular,
    textAlign: 'center',
    color: colors.defaultTextColor
  },
  contentEndCardImage: {
    width: 111,
    height: 77,
    marginVertical: 20
  },
  webViewModalStyle: {
    position: 'relative',
    top: null,
    left: null,
    height: null
  }
};

let styles = commonStyles;

if (Platform.OS === 'web') {
  styles = {...commonStyles, ...webStyles};
}

export default styles;
