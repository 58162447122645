import React from 'react';
import LinearGradient from 'react-native-linear-gradient';
import {Image, StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import {fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import PropTypes from 'prop-types';
import cabStyles from './CabMamiCss';
import {summaryIcon} from '../CabImageConstants';

const CabSummaryCard = ({response, updateCarDetails}) => (
  <View style={[styles.cabWhiteCard,styles.marginBtm10]}>

    <View style={[styles.borderLight,cabStyles.paddingBottom18]}>
      <View style={[cabStyles.row, cabStyles.mar15Bt]}>
        <Text
          style={[cabStyles.width100, cabStyles.font12, cabStyles.lightGrey, cabStyles.regularFont]}
        >
          TRIP DETAILS
        </Text>
        <Text style={[cabStyles.font14, cabStyles.BlackText, cabStyles.blackFont]}>
          Airport Cab
        </Text>
      </View>
      <View
        style={[cabStyles.row, cabStyles.mar15Bt]}
      >
        <Text
          style={[cabStyles.width100, cabStyles.font12, cabStyles.lightGrey, cabStyles.regularFont]}
        >FROM CITY
        </Text>
        <Text
          style={[cabStyles.blackFont, cabStyles.BlackText]}
        >{response.carBookingInfo.carDetail.fromCity}
        </Text>
      </View>
      <View style={cabStyles.row}>
        <Text
          style={[cabStyles.width100, cabStyles.font12, cabStyles.lightGrey, cabStyles.regularFont]}
        >
        TO CITY
        </Text>
        <Text style={[cabStyles.font12]}>
          <Text
            style={[cabStyles.font14,cabStyles.blackFont, cabStyles.BlackText]}
          >{response.carBookingInfo.carDetail.toCity}
          </Text>
        </Text>
      </View>
    </View>
    <View style={styles.midSectionDtls}>
      <Image style={[styles.summaryIcon,cabStyles.mar15Top]} source={summaryIcon} />
      <Text style={[styles.topDtlsTxt, styles.blackTxt, styles.marginBtm5]}>Enter details
        for your airport cab
      </Text>
      <Text style={[styles.pickUpTxt]}>Please enter the address &amp; time for your cab.
      </Text>
    </View>
    <TouchableOpacity onPress={updateCarDetails}>
      <LinearGradient
        start={{
          x: 1.0,
          y: 0.0
        }}
        end={{
          x: 0.0,
          y: 1.0
        }}
        colors={['#065af3', '#53b2fe']}
        style={[styles.CapsuleBtnFill, styles.mar10Bt]}
      >
        <Text style={[styles.btnTxt]}>ENTER DETAILS</Text>
      </LinearGradient>
    </TouchableOpacity>
  </View>
);
const styles = StyleSheet.create({
  cabWhiteCard: {
    backgroundColor: '#fff',
    elevation: 2,
    shadowOffset: {
      width: 5,
      height: 1
    },
    shadowColor: '#ddd',
    shadowOpacity: 1.0,
    paddingVertical: 16,
    paddingHorizontal: 16

  },
  topDtls: {
    flexDirection: 'row',
    borderBottomColor: '#ddd',
    borderBottomWidth: 1,
    paddingBottom: 15,
    marginBottom: 30,
    alignItems: 'center'
  },
  topDtlsTxt: {
    fontSize: 16,
    fontFamily: fonts.bold
  },
  regularFont: {
    fontFamily: fonts.regular
  },
  boldFont: {
    fontFamily: fonts.bold
  },
  blackTxt: {
    color: '#000'

  },
  greyTxt: {
    color: '#747474'
  },
  oneWayArrow: {
    width: 9,
    height: 7,
    marginHorizontal: 5
  },
  midSectionDtls: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  summaryIcon: {
    width: 64,
    height: 64,
    marginBottom: 30
  },
  font12: {
    fontSize: 12
  },
  marginBtm5: {
    marginBottom: 5
  },
  marginBtm10: {
    marginBottom: 10
  },
  CapsuleBtnFill: {
    padding: 13,
    borderRadius: 35,
    textAlign: 'center',
    alignItems: 'center'
  },
  btnTxt: {
    color: '#fff',
    fontSize: 12,
    fontFamily: fonts.black
  },
  pickUpTxt: {
    fontSize: 12,
    color: '#000',
    fontFamily: fonts.regular,
    marginBottom: 30
  },
  borderLight: {
    borderBottomWidth: 1,
    borderBottomColor: "#e4e4e4",
  }
});
CabSummaryCard.propTypes = {
  updateCarDetails: PropTypes.func.isRequired,
  response: PropTypes.object.isRequired
};

export default CabSummaryCard;
