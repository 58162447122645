import React from "react";
import {appendUrlPrefix} from "../../webUtils";
import loadable from "@loadable/component";
import ProgressView from "../../components/ProgressView";

const ESCALATE_PATH = {
    ESCALATE_PAGE: {
        pathName: appendUrlPrefix('/Escalate'),
        pageKey: "Escalate",
        Component: loadable(() => import("../../Modules/Escalate/index"), { fallback: <ProgressView /> })
    },
    ESCALATE_MY_TRIPS: {
        pathName: appendUrlPrefix('/EscalateMyTrips'),
        pageKey: "EscalateMyTrips",
        Component: loadable(() => import("../../Modules/Escalate/index"), { fallback: <ProgressView /> })
    },
    ESCALATE_TO_TEAM_PAGE : {
        pathName: appendUrlPrefix('/EscalationToTeam'),
        pageKey: "Escalate",
        Component: loadable(() => import("../../Modules/Escalate/index"), { fallback: <ProgressView /> })
    }
}

export default ESCALATE_PATH;


