import React, { useEffect, useState } from 'react';
import { NativeModules, Platform, TouchableOpacity } from 'react-native';
import useApi from '../../../../Common/useApi';
import { BOOKING_SUMMARY_URL_V4 } from '../../../../utils/NetworkUtils';
import { backIcon } from '@mmt/legacy-commons/Helpers/displayHelper';
import { getFont } from '../../../../PostSalesStyles';
import SimpleHeader from '@mmt/legacy-commons/Common/Components/Header/SimpleHeader';
import { getCurrentLangStaticData, getStaticData } from 'apps/post-sales/src/staticData/staticData';
import { getUserProfileType } from 'packages/legacy-commons/AppState/AppUserConfig';
import { isAddBookingFlowEnabled } from 'apps/post-sales/src/utils/PostSaleUtil';
import { Image } from 'react-native';
import styles from './style';
import MybizToolTip from '../../components/MyBizSwitcher/MybizToolTip';
import MybizSwitcher from '../../components/MyBizSwitcher';
import myTripsNavigation from '../../../navigation/navigation';
import { isEmpty } from 'lodash';
import { Text } from 'react-native';
import { View } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { PROFILE_TYPE } from 'packages/legacy-commons/Common/constants/AppConstants';
import TripSummaryOmnitureTracker from '../../TripSummaryOmnitureTracker';
import { MAX_SWITCHER_BOOKING_COUNT_TO_SHOW } from '../../summaryConstants';

const MyTripsHeader = ({ hideBack, headerData, addBookingCTA }) => {
  const { uuid = null, handleAddBookingOverlay, plusIcon, showBookingOverlay } = headerData || {};
  const [bookingCount, setBookingCount] = useState(0);
  const [inProgress, response, api] = useApi(`${BOOKING_SUMMARY_URL_V4}?status=UPCOMING&pageNo=0`);
  const fonts = getFont(true);
  const Header = SimpleHeader;
  const _backIcon = Platform.OS === 'ios' ? (hideBack ? null : backIcon) : backIcon;
  const staticData = getCurrentLangStaticData();
  const userProfileType = getUserProfileType();

  const fetchMyBizBookings = async () => {
    if (userProfileType !== PROFILE_TYPE.PERSONAL) return;
    const { UserSessionModule } = NativeModules;
    const b2bAuth = await UserSessionModule.getAuthForProfile(PROFILE_TYPE.CORPORATE);
    if (b2bAuth) {
      api.httpGet({ uniqueId: 'MY_BIZ_SWITCHER' }, { headers: { 'mmt-auth': b2bAuth } });
    }
  };

  useEffect(() => {
    if (Platform.OS !== 'web') {
      fetchMyBizBookings();
    }
  }, []);

  useEffect(() => {
    if (response && !isEmpty(response.newBookingDetails) && !isEmpty(response.newBookingDetails[0].bookingDetails)) {
      setBookingCount(response.newBookingDetails[0].bookingDetails.length);
    }
  }, [response]);


  const bookingCountText = bookingCount > 10 ? `${MAX_SWITCHER_BOOKING_COUNT_TO_SHOW}+` : bookingCount;
  const { myBizNotificationText, myBizBookingNotificationText } = getStaticData();

  const tootlTipText =
    bookingCount > 10
      ? myBizNotificationText.replace('MAX_BOOKING_COUNT_TO_SHOW', MAX_SWITCHER_BOOKING_COUNT_TO_SHOW)
      : myBizBookingNotificationText.replace('BOOKING_COUNT', bookingCount);

  const addBookingOnPress = () => {
    TripSummaryOmnitureTracker.trackNormalClickEvent('add booking', 'add_bookings_button');
    handleAddBookingOverlay(true, "top")
  }

  const renderChildLeftToIcon = () => {
    return (
      !isEmpty(addBookingCTA) && <View style={[AtomicCss.marginRight10, styles.addBookingWrapper]}>
        <TouchableOpacity style={styles.addBookingTopWrapper} onPress={addBookingOnPress}>
          <Image source={plusIcon} style={[styles.plusIcon]} />
          <Text style={[AtomicCss.font14, AtomicCss.boldFont, AtomicCss.blackText]}>
            {addBookingCTA.addBooking}</Text>
        </TouchableOpacity>
      </View>)
  };
  const showAddBookingIcon = isAddBookingFlowEnabled() && uuid && plusIcon;

  return (
    <>
      <Header
        icon={_backIcon}
        title={staticData.myTripsText}
        textFont={fonts.bold}
        iconPress={() => {
          myTripsNavigation.exitApp();
        }}
        rightChild={bookingCount > 0 ? <MybizSwitcher bookingCountText={bookingCountText} /> : null}
        childLeftToRightIcon={showAddBookingIcon && renderChildLeftToIcon()}
      />
      {!showBookingOverlay && bookingCount > 0 && <MybizToolTip tootlTipText={tootlTipText} />}
    </>
  );
}

export default MyTripsHeader;
