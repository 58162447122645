import React, { useEffect } from 'react';
import { View, Text, Image, TouchableOpacity, Platform, StyleSheet } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { RIGHT_ARROW } from '../../../../FlightImageConstants';
import { getFont } from '../../../../../PostSalesStyles';
import GenericModule from 'packages/core/native/GenericModule';
import isEmpty from 'lodash/isEmpty';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import createStyles from './AddonListCss';
import HTMLView from 'react-native-htmlview';
import FlightBookingTrackingHelper from '../../helper/FlightBookingTrackingHelper';

interface AddonListProps {
  trackClickEvent?: (actionId?: string) => void;
  openVpgInfoOverlay?: () => void;
  deepLinkForApp?: string;
  deepLinkForWeb?: string;
  headerMessage?: string;
  subHeaderMessage?: string;
  status?: string;
  statusColor?: string;
  actionId?: string;
  iconForApp?: string;
  popupText?: string;
  loadTrackEvent?: string;
  flightDetailResponse?: object;
}

const AddonList: React.FC<AddonListProps> = (props) => {
  const {
    trackClickEvent,
    deepLinkForApp,
    deepLinkForWeb,
    headerMessage,
    subHeaderMessage,
    status,
    statusColor,
    actionId,
    iconForApp,
    openVpgInfoOverlay,
    popupText,
    loadTrackEvent,
    flightDetailResponse,
  } = props;
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme);
  const statusTxtColor = statusColor && { color: statusColor };
  const disableClick = Platform.OS === 'web' ? isEmpty(deepLinkForWeb) : isEmpty(deepLinkForApp);

  useEffect(()=>{
     if (loadTrackEvent && flightDetailResponse){
       FlightBookingTrackingHelper.trackAddOnShownCard('Details', loadTrackEvent, flightDetailResponse);
     }
  }, []);

  const openAddOnDeepLink = () => {
    if (Platform.OS === 'web' && !disableClick) {
      GenericModule.openDeepLink(deepLinkForWeb);
    } else if (Platform.OS !== 'web' && !disableClick) {
      GenericModule.openDeepLink(deepLinkForApp);
    }
    trackClickEvent && trackClickEvent(actionId);
  };

  const descStyle = StyleSheet.create({
    p: {
      color: theme.color.grey1,
      textAlign: 'left',
      ...fsStyle.font14,
      ...fonts.regularFontFamily,
      ...AtomicCss.lineHeight24
    }
  });

  return (
    <TouchableOpacity
      style={styles.listRow}
      activeOpacity={0.7}
      disabled={disableClick}
      onPress={openAddOnDeepLink}
    >
      <View style={styles.listDtlsSection}>
        <View style={styles.iconPlaceHolder}>
          {!isEmpty(iconForApp) && <Image style={styles.iconStyle} source={{ uri: iconForApp }} />}
        </View>
        <View style={AtomicCss.width90}>
          {!isEmpty(headerMessage) && (
            <Text
              style={[
                fsStyle.font16,
                fonts.boldFontFamily,
                styles.headerMessage,
                AtomicCss.marginBottom5,
              ]}
            >
              {headerMessage}
            </Text>
          )}
          {!isEmpty(subHeaderMessage) && (
              <HTMLView
                value={`<p>${subHeaderMessage}</p>`}
                stylesheet={descStyle}
              />
          )}
          {!isEmpty(popupText) && openVpgInfoOverlay && (
            <TouchableOpacity onPress={openVpgInfoOverlay}>
              <Text 
                style={[
                  fsStyle.font14, 
                  fonts.regularFontFamily, 
                  AtomicCss.lineHeight24, 
                  styles.popupOpenText
                ]}
              >
                {popupText}
              </Text>
            </TouchableOpacity>
          )}
          {!isEmpty(status) && (
            <Text
              style={[
                fsStyle.font14,
                fonts.regularFontFamily,
                AtomicCss.lineHeight24,
                statusTxtColor,
              ]}
            >
              {status}
            </Text>
          )}
        </View>
      </View>
      {!disableClick && <Image style={styles.rgtArrowStyle} source={RIGHT_ARROW} />}
    </TouchableOpacity>
  );
};

export default AddonList;
