import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getFont } from '../../../../PostSalesStyles';
import { useTheme } from '../../../../theme/theme.context';
import { getStaticData } from 'apps/post-sales/src/staticData/staticData';

const ImportantInfoCard = ({ handleBottomOverlay, data }) => {
  const { heading, subHeading } = data;

  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);
  const staticData = getStaticData();
  return (
    <View style={[AtomicCss.flex1, AtomicCss.grayBg]}>
      <View style={styles.impoInfoWrapper}>
        {!!heading && (
          <Text style={[styles.impoInfoHeadText, fsStyle.font14, fonts.boldFontFamily]}>
            {heading}
          </Text>
        )}

        <View style={[AtomicCss.alignCenter, AtomicCss.flexRow]}>
          {!!subHeading && (
            <Text
              style={[
                AtomicCss.flex1,
                fonts.regularFontFamily,
                AtomicCss.defaultText,
                fsStyle.font12,
                AtomicCss.marginRight18,
              ]}
            >
              {subHeading}
            </Text>
          )}
          <TouchableOpacity style={AtomicCss.marginLeft12} onPress={handleBottomOverlay}>
            <Text style={[fsStyle.font12, fonts.boldFontFamily, styles.viewMore]}>{staticData.viewAllText}</Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};
const createStyles = (theme) => StyleSheet.create({
  impoInfoWrapper: {
    backgroundColor: theme.color.creamWhite,
    paddingHorizontal: 10,
    paddingVertical: 12,
    borderRadius: 4,
    margin: 12,
    marginBottom:0
  },
  impoInfoHeadText: {
    color: theme.color.lightYello,
    textTransform: 'uppercase',
    marginBottom: 6,
  },
  viewMore: {
    color: theme.color.azure,
  },
});
export default ImportantInfoCard;
