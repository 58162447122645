import React from 'react';
import { View, Text } from 'react-native';
import { getFont } from '../../../../../PostSalesStyles';
import { useTheme } from '../../../../../theme/theme.context';
import createStyles from './PaymentinformationCSS';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import isEmpty from 'lodash/isEmpty';
const PayableCard = ({ reviewData }) => {
  const { selectedBus, paymentCard } = reviewData || {};
  const { chargeInfo, mainInfo } = paymentCard || {};
  const { heading: totalPayable, subHeading: totalPaySubHeading, value: totalPay } = mainInfo || {};
  const { busFare: price } = selectedBus || {};
  const { fsStyle, ...fonts } = getFont();
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);
  return (
    <View style={styles.payableCard}>
      <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween, AtomicCss.alignCenter]}>
        {!!totalPayable && (
          <Text
            style={[
              fsStyle.font20,
              fonts.blackFontFamily,
              AtomicCss.blackText,
              styles.lineHeight24,
            ]}
          >
            {totalPayable}
          </Text>
        )}
        {!!totalPay && (
          <Text
            style={[
              fsStyle.font20,
              fonts.blackFontFamily,
              AtomicCss.blackText,
              styles.lineHeight24,
            ]}
          >
            {totalPay}
          </Text>
        )}
      </View>
      {!!totalPaySubHeading && (
        <Text
          style={[
            fsStyle.font14,
            fonts.regularFontFamily,
            AtomicCss.defaultText,
            AtomicCss.lineHeight20,
            AtomicCss.marginBottom24,
          ]}
        >
          {totalPaySubHeading}
        </Text>
      )}
      <View style={styles.paymentWrapper}>
        {!isEmpty(chargeInfo) &&
          chargeInfo.map((charge, index) => {
            const { heading, subHeading, value } = charge || {};
            return (
              <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween, styles.seprator]}>
                <View style={[AtomicCss.flex1, AtomicCss.marginRight30]}>
                  {!!heading && (
                    <Text
                      style={[
                        fsStyle.font14,
                        fonts.boldFontFamily,
                        AtomicCss.blackText,
                        AtomicCss.marginBottom6,
                      ]}
                    >
                      {heading}
                    </Text>
                  )}
                  {!!subHeading && (
                    <Text
                      style={[
                        fsStyle.font14,
                        fonts.regularFontFamily,
                        AtomicCss.midGreyText,
                        AtomicCss.lineHeight20,
                      ]}
                    >
                      {subHeading}
                    </Text>
                  )}
                </View>
                {!!value && (
                  <Text
                    style={[
                      fsStyle.font14,
                      fonts.boldFontFamily,
                      AtomicCss.blackText,
                      styles.lineHeight24,
                    ]}
                  >
                    {value}
                  </Text>
                )}
              </View>
            );
          })}
        <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween, AtomicCss.alignCenter]}>
          {!!totalPayable && (
            <Text
              style={[
                fsStyle.font16,
                fonts.blackFontFamily,
                AtomicCss.blackText,
                AtomicCss.lineHeight20,
              ]}
            >
              {totalPayable}
            </Text>
          )}
          {!!price && (
            <Text
              style={[
                fsStyle.font16,
                fonts.blackFontFamily,
                AtomicCss.blackText,
                AtomicCss.lineHeight20,
              ]}
            >
              {totalPay}
            </Text>
          )}
        </View>
      </View>
    </View>
  );
};

export default PayableCard;
