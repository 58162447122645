import { StyleSheet} from 'react-native';
import { DEFAULT_THEME } from '../../theme/default.theme';

export default <T extends typeof DEFAULT_THEME>(theme: T) => StyleSheet.create({
  mainWrapper: {
    backgroundColor: theme.color.grayBg,
    flex: 1,
  },
  summaryContainer: {
    backgroundColor: theme.color.lightBlue12,
    flex: 1,
  },
  sectionHeader: {
    paddingHorizontal: 12,
    paddingTop: 8,
    paddingBottom: 3,
    color: theme.color.defaultTextColor,
    backgroundColor: theme.color.grayBg,
    textAlign: 'left',
  },
  whiteCard: {
    flex: 1,
    marginHorizontal: 54,
    alignItems: 'center',
    justifyContent: 'center',
  },
  ImgStyle: {
    width: 120,
    height: 120,
  },
  errorStateWraper: {
    alignItems: 'center',
    padding: 20,
    justifyContent: 'center',
    width: '100%',
  },
  errorStateTxt: {
    textAlign: 'center',
    color: theme.color.defaultTextColor,
    marginBottom: 4,
    marginTop: 10,
  },
  errorStateSubTxt: {
    textAlign: 'center',
    color: theme.color.defaultTextColor,
  },
  buttonWrapper: {
    padding: 15,
    width: 220,
    alignSelf: 'center',
  },
  errorImgWrapper: {
    width: 120,
    height: 120,
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 20,
  },
  errorImgStyle: {
    width: 54,
    height: 54,
  },
  errorImgLrgStyle: {
    width: 82,
    height: 90,
  },
  emptyWrapper: {
    backgroundColor: theme.color.grayBg,
    flex: 1,
  },
  activeStyle: {
    transform: [
      {
        rotate: '-90deg',
      },
    ],
  },
  shadowWrapper: {
    shadowColor: theme.color.black,
    shadowRadius: 2,
    shadowOpacity: 0.2,
  },
  headingSection: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  }
});

