import React, { useState } from 'react';
import { View, Text, TouchableOpacity, Image } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import createStyles from "./termsAndConditionsCss";
import isEmpty from 'lodash/isEmpty';
import { DOWN_ARROW} from '../../../../Utils/tripGuaranteeImageConstants';
import HTMLViewWrapper from '../../../../../Common/HTMLViewWrapper';
import { getFont } from '../../../../../PostSalesStyles';
import { useTheme } from '../../../../../theme/theme.context';

const TermsAndConditionCard = ({ extraPolicies, useToggle }) => {
    const { heading, policies, additionalInfo } = extraPolicies || {}
    const [toggle, setToggle] = useState(true);
    const handleToggle = () => {
        setToggle(!toggle);
    }
    const fonts = getFont();
    const {psTheme : theme} = useTheme();
    const styles = createStyles(theme,fonts);
    const getPolicy = (policy) => {
        const { icon, message } = policy || {};
        const { url: iconUrl } = icon || {};
        return (<View style={[AtomicCss.flexRow, AtomicCss.alignCenter,AtomicCss.marginBottom10]}>
            {!isEmpty(iconUrl) && <Image style={styles.voucherIconStyle} source={{uri:iconUrl}} /> }
            {!!message &&
                <HTMLViewWrapper
                    style={[ AtomicCss.defaultText, AtomicCss.font11]} htmlContent={message} contentWidth={'95%'} />}
        </View>)
    }
    const getAdditionalInfoText = (info) => {
        const { header, description } = info || {};
        return <View style={[AtomicCss.flexRow,AtomicCss.marginRight10,AtomicCss.spaceBetween,AtomicCss.marginBottom10]}>
            {!!header && <HTMLViewWrapper style={[AtomicCss.font11,fonts.bondFontFamily]} htmlContent={header + " : "} /> }
            {!!description &&
                <Text
                    style={[styles.description,AtomicCss.defaultText, AtomicCss.font11, fonts.regularFontFamily]}>
                   {description}</Text> }
        </View>
    }
    const getAdditionalInfo = (additionalInfo) => {
        return <View style={styles.moreDetls}>
            {additionalInfo.map((info, index) => getAdditionalInfoText(info))}
        </View>
    }
    const getPolicies = () => (
        <View style={[!useToggle ? [AtomicCss.paddingLeft16,AtomicCss.marginLeft5] :styles.tncList]}>
            {!isEmpty(policies) && policies.map((policy, index) => getPolicy(policy))}
            {!isEmpty(additionalInfo) && getAdditionalInfo(additionalInfo)}
        </View>
    )
    return (
        <View style={styles.tncWrapper}>
            {useToggle ?
                <>
                    {(!isEmpty(policies) || !isEmpty(additionalInfo)) && <TouchableOpacity style={[AtomicCss.flexRow, AtomicCss.alignCenter]} onPress={handleToggle}>
                        {!!heading && <Text style={[AtomicCss.azure, AtomicCss.font13]}>{heading}</Text>}
                        <Image style={toggle ? [styles.activeArrow,styles.arrowStyle] :styles.arrowStyle} source={DOWN_ARROW} />
                    </TouchableOpacity>}
                    {toggle && getPolicies()}
                </> : getPolicies()}
        </View>
    )
}


export default TermsAndConditionCard;