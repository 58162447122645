import React from 'react';
import Actions from '../../../navigation/postSalesNavigation';
import PropTypes from 'prop-types';
import {DeviceEventEmitter, ScrollView, View} from 'react-native';
import getStyles from './HotelMamiCss';
import HotelCancellationPolicyCard from './HotelCancellationPolicyCard';
import {getCardObject, isIntlHotelBooking} from '../utils/HotelBookingDetailUtil';
import HotelBookingDetailsConstant from '../HotelBookingDetailsConstant';
import CommonHeader from '../../details/components/CommonHeader';
import HotelBookingTrackingHelper from '../../HotelBookingTrackingHelper';
import { getStaticData } from '../../../staticData/staticData';
import { WHITE_LEFT_ARROW_ICON } from '../../HotelImageConstants';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

class HotelCancellationStandalone extends React.Component {
  static navigationOptions = {
    header: null
  }

  constructor(props) {
    super(props);
    this.fonts = getFont(true);
    this.styles = getStyles(this.fonts);
    this.trackPageName = isIntlHotelBooking(props.response?.lobCode)
      ? 'mob:customer support:Hotel International:CancellationStandalone'
      : 'mob:customer support:Hotel Domestic:CancellationStandalone';
  }
  componentWillMount() {
    DeviceEventEmitter.addListener('hotel_details_response_from_mypromise_service', this.refreshHotelDetails.bind(this));
  }
  render() {
    const staticData = getStaticData();
    return (
      <View style={[this.styles.whiteContainer, this.styles.transperantBgrnd]}>
        <CommonHeader
          headerText={staticData.hotelCancelText}
          imgSrc={WHITE_LEFT_ARROW_ICON}
          backPressHandler={this.onBackPress}
        />
        <ScrollView>
          <View style={this.styles.headerPaddingbtm}>
            <HotelCancellationPolicyCard
              pageName={this.trackPageName}
              response={this.props.response}
              card={getCardObject(this.props.response, HotelBookingDetailsConstant.HOTEL_CANCELLATION_POLICY_CARD)}
              cancellationState={this.getCancellationState}
            />
          </View>
        </ScrollView>
      </View>
    );
  }

  componentDidMount() {
    HotelBookingTrackingHelper.trackLoadEvent(this.trackPageName, this.props.response);
  }

  onBackPress = () => {
    Actions.pop();
  }

  refreshHotelDetails(e) {
    this.onBackPress();
  }
}

HotelCancellationStandalone.propTypes = {
  response: PropTypes.object.isRequired
};

export default HotelCancellationStandalone;
