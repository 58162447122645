import { StyleSheet } from 'react-native';

export default (theme, fonts) => {
  return StyleSheet.create({
    infoSection: {
      flex: 1,
      marginLeft: 20,
    },
    htlImgSection: {
      height: 70,
      borderRadius: 4,
    },
    htlImageStyle: {
      width: '100%',
      height: '100%',
      resizeMode: 'cover',
      borderRadius: 4,
    },
    gradientBG: {
      width: '100%',
      position: 'absolute',
      bottom: 0,
      borderRadius: 8,
      minHeight: 70,
      justifyContent: 'flex-end',
      paddingHorizontal: 5,
      paddingBottom: 4,
    },
    slashPrice: {
      textDecorationLine: 'line-through',
      color: theme.color.lightGrey16,
      ...fonts.font10,
    },
  });
};
