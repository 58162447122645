import React, { useCallback, useEffect } from 'react';
import { View, Text, StyleSheet, Image, TouchableOpacity } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { connect } from 'react-redux';
import {
  FETCH_ACTIONS_RAILS_PNR_STATUS,
  fetchPnrStatusInfo,
} from '../../RailAction/RailInfoActionService';
import { REFRESH_ICON_BLUE } from '../../Utils/RailImageConstant';
import PnrPaxStatusCard from '../PnrPaxStatusCard';
import { getStatusData } from '../../details/data/dataAdapter';
import { useTheme } from '../../../theme/theme.context';
import { getStaticData } from 'apps/post-sales/src/staticData/staticData';
import { PNRstatusCardProps } from '../../types';
import isEmpty from 'lodash/isEmpty';
import { getPnrDetails, getSegmentInfo } from '../../details/data/helper';
import getStyles from './TravellerCss';
import { getFont } from '../../../PostSalesStyles';
import WhiteCardWrapper from '../../../Common/WhiteCardWrapper';
import { BOOKING_TYPE } from '../../details/constants';
import throttle from 'lodash/throttle';

const PNRstatusCard = ({
  response,
  fetchPnrStatusInfo,
  mmtAuth,
  loggingTrackingInfo,
  lastUpdateTime,
  lastbookingId,
  clearStore,
  onClickEventTracker,
}: PNRstatusCardProps) => {
  const { lastUpdatedPnrStatus, bookingID, bookingState } = response;
  const { pnrNo, travelClass } = getPnrDetails(response);
  const { noOfAdult, noOfChild, passenger } = getSegmentInfo(response);
  const statusData = getStatusData(passenger, response);
  const staticData = getStaticData();
  const { state } = bookingState || {};
  const handleRefresh = async () => {
    if (pnrNo && bookingID) {
      onClickEventTracker('Refresh_PNR');
      fetchPnrStatusInfo(pnrNo, bookingID, mmtAuth, loggingTrackingInfo);
    }
  };
  const throttleOnPressFunc = useCallback(throttle( handleRefresh, 4000), []);

  const updatedPnrMsg =
    !!lastbookingId && bookingID === lastbookingId ? lastUpdateTime : lastUpdatedPnrStatus;
  const { psTheme } = useTheme();
  const { fsStyle, ...fonts } = getFont(true);
  const { color } = psTheme;
  const styles = getStyles(psTheme, fonts);
  let { pnrStatusText, adultCountText, childCountText } = staticData;
  pnrStatusText = pnrStatusText.replace('{pnrNo}', pnrNo);
  adultCountText = adultCountText.replace('{noOfAdult}', noOfAdult);
  childCountText = childCountText.replace('{noOfChild}', noOfChild);

  useEffect(() => {
    return () => {
      clearStore();
    };
  }, []);

  return (
    <View>
      <View style={[styles.headingWrapper]}>
        <View style={AtomicCss.flex1}>
          {!isEmpty(pnrStatusText) && (
            <Text style={[styles.header, AtomicCss.marginBottom8]}>{pnrStatusText}</Text>
          )}
          <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, {flexWrap: 'wrap'}]}>
            {!isEmpty(travelClass) && <Text style={styles.subHeader}>{travelClass}</Text>}
            {!isEmpty(adultCountText) && noOfAdult > 0 && (
              <>
                <Text style={[styles.bullet]}> </Text>
                <Text style={styles.subHeader}>{adultCountText}</Text>
              </>
            )}
            {!isEmpty(childCountText) &&  noOfChild > 0 && (
              <>
                <Text style={[styles.bullet]}> </Text>
                <Text style={styles.subHeader}>{childCountText}</Text>
              </>
            )}
          </View>
        </View>
        <View style={{ width: '40%', alignItems: 'flex-end' }}>
          {(state === BOOKING_TYPE.UPCOMING || state === BOOKING_TYPE.ONTRIP) && (
            <>
              <TouchableOpacity
                activeOpacity={0.6}
                style={[AtomicCss.alignSelfFlexEnd]}
                onPress={throttleOnPressFunc}
              >
                <Image style={styles.refreshIconStyle} source={REFRESH_ICON_BLUE} />
              </TouchableOpacity>
              <Text style={styles.updatedMsgStyles}>{updatedPnrMsg}</Text>
            </>
          )}
        </View>
      </View>
      <View style={styles.detailsSection}>
        <PnrPaxStatusCard data={statusData} />
      </View>
    </View>
  );
};

const mapStatesToProps = (state, ownProps) => {
  const {
    passengerDetails,
    showPnrStatusLodar,
    lastUpdateTime,
    allowRefresh,
    lastbookingId,
  } = state.trainReducer;
  return {
    ...ownProps,
    passengerDetails,
    showPnrStatusLodar,
    lastUpdateTime,
    allowRefresh,
    lastbookingId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchPnrStatusInfo: (pnr: string, bookingId: string, mmtAuth: string, loggingTrackInfo: string) =>
    dispatch(fetchPnrStatusInfo(pnr, bookingId, mmtAuth, loggingTrackInfo)),

  clearStore: () => {
    dispatch({
      type: FETCH_ACTIONS_RAILS_PNR_STATUS,
      data: {},
    });
  },
});

export default connect(mapStatesToProps, mapDispatchToProps)(WhiteCardWrapper(PNRstatusCard));
