import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import BasePage from '../../../../../Common/PostSalesBasePage';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';
import { getFont } from '../../../../../PostSalesStyles';

class CancelPolicyList extends BasePage {
  render() {
    return (
      <View style={[styles.whiteWrapper]}>
        {this.getCancellationPolicyInfo(this.props.cancellationPolicyInfo)}
      </View>
    );
  }

  getCancellationPolicyInfo = (cancellationPolicyInfoList) => {
    const { fsStyle, ...fonts } = getFont(true);
    return cancellationPolicyInfoList.map((info) => (
      <View style={[AtomicCss.flexRow, AtomicCss.marginBottom10]}>
        <Text style={AtomicCss.marginRight10}>{'\u2022'}</Text>
        <Text
          style={[
            AtomicCss.flex1,
            AtomicCss.defaultText,
            fsStyle.font14,
            fonts.regularFontFamily,
            AtomicCss.lineHeight20,
          ]}
        >
          {info}
        </Text>
      </View>
    ));
  };
}

const styles = StyleSheet.create({
  whiteWrapper: {
    padding: 16,
    backgroundColor: '#ffffff',
    marginBottom: 10,
    ...getPlatformElevation(2),
    marginTop: -2,
  },
});
export default CancelPolicyList;
