import React, {Component} from 'react';
import LinearGradient from 'react-native-linear-gradient';
import PropTypes from 'prop-types';
import {Image, Platform, StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import {colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from '@mmt/legacy-commons/Common/Components/Card/getPlatformElevation';

class DocumentsPageHeader extends Component {
  render() {
    const {headerText, imgSrc, backPressHandler, whiteHeader} = this.props;
    return (
      <LinearGradient
        start={{
          x: 1.0,
          y: 0.0
        }}
        end={{
          x: 0.0,
          y: 1.0
        }}
        colors={whiteHeader ? ['#fff', '#fff'] : ['#5f88ff', '#a768ff']}
        style={[styles.topHeader, whiteHeader ? [{borderColor: '#e8e8e8', ...getPlatformElevation(this.props.elevation)}] : {}]}
      >
        <View style={styles.stickyBarWrapper}>
          <TouchableOpacity style={{padding: 14}} onPress={backPressHandler}>
            <Image style={styles.backarrow} source={imgSrc}/>
          </TouchableOpacity>
          <View>
            <Text
              numberOfLines={1}
              ellipsizeMode="tail"
              style={[styles.title, whiteHeader ? {color: colors.defaultTextColor} : {}]}
            >{headerText}
            </Text>
          </View>
        </View>
      </LinearGradient>
    );
  }
}

const styles = StyleSheet.create({
  title: {
    color: colors.white,
    fontFamily: fonts.bold,
    marginBottom: 3,
    fontSize: 18,
    paddingLeft: 14,
    ...Platform.select({
      ios: {
        backgroundColor: 'transparent'
      }
    })
  },
  backarrow: {
    width: 16,
    height: 16
  },
  topHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 56
  },
  stickyBarWrapper: {
    flexDirection: 'row',
    width: '75%',
    alignItems: 'center'
  }
});

DocumentsPageHeader.propTypes = {
  headerText: PropTypes.string.isRequired,
  imgSrc: PropTypes.number.isRequired,
  backPressHandler: PropTypes.func.isRequired,
  whiteHeader: PropTypes.bool,
  elevation: PropTypes.number
};
DocumentsPageHeader.defaultProps = {
  whiteHeader: false,
  elevation: 1
};
export default DocumentsPageHeader;
