import React, { useState, useEffect } from 'react';
import { View, StyleSheet, TouchableOpacity, Text, Image } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import useApi from '../useApi';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
import { UPDATE_REFUND_STATUS } from '../../utils/NetworkUtils';
import Actions from '../../navigation/postSalesNavigation';
import { showShortToast } from '@mmt/legacy-commons/Common/Components/Toast';
import { REFUND_STATUS } from '../../PostSalesConstant';
import Loader from '../SpinnerLoader/Loader';
import { getStaticData } from '../../staticData/staticData';
import { REFUND_OOT_TRACKING } from '../RefundOutOfTATForm/constants';
import PostSalesBookingTrackingHelper from '../../PostSalesBookingTrackingHelper';

const RefundFeedback = ({
  headerText,
  answerYes,
  answerNo,
  requestNo,
  refundNotReceivedDetails,
  bookingId,
  lobName,
  isLostBookingId,
  pageName = REFUND_OOT_TRACKING.PAGES.DEFAULT,
  fonts = {},
}) => {
  const [updateInProgress, response, updateApi] = useApi(UPDATE_REFUND_STATUS);
  const [isReceived, setReceived] = useState(undefined);
  const [refundStatus, setRefundStatus] = useState(undefined);
  const [disableAction, setDisableAction] = useState(false);
  const {
    errorText: { failureMsg },
    raiseRefundTicketText,
  } = getStaticData();


  useEffect(() => {
    PostSalesBookingTrackingHelper.trackClickEvent(pageName, 'lostPaymentFeedbackShown', bookingId);
    PostSalesBookingTrackingHelper.trackShownEvent(pageName, REFUND_OOT_TRACKING.SHOW.RECEIVED_REFUND, bookingId);
  }, [pageName, bookingId]);

  useEffect(() => {
    if (!response) {
      return;
    }
    if (response.success) {
      setRefundStatus((refundStatus) => {
        if (refundStatus == REFUND_STATUS.NOT_RECEIVED) {
          Actions.refundDelayInfo({
            data: refundNotReceivedDetails,
            bookingId,
            requestNo,
            lobName,
            pageName,
          });
          setReceived(false);
        } else {
          setReceived(true);
        }
        return refundStatus;
      });
    } else {
      showShortToast(failureMsg);
    }
  }, [refundNotReceivedDetails, response]);

  const handleRefundReceived = (refundStatus) => {
    if (disableAction || updateInProgress) {
      return;
    }
    const clickEvent = isLostBookingId
      ? refundStatus === REFUND_STATUS.RECEIVED
        ? 'amountDeducted'
        : 'amountNotDeducted'
      : refundStatus === REFUND_STATUS.RECEIVED
      ? REFUND_OOT_TRACKING.CLICK.REFUND_RECEIVED_FEEDBACK_YES
      : REFUND_OOT_TRACKING.CLICK.REFUND_RECEIVED_FEEDBACK_NO;
    PostSalesBookingTrackingHelper.trackClickEvent(pageName, clickEvent, bookingId);
    setRefundStatus(refundStatus);
    if (isLostBookingId) {
      if (refundStatus === REFUND_STATUS.RECEIVED) {
        Actions.refundOutOfTATForm({
          bookingId,
          requestNo,
          headerTitle: raiseRefundTicketText,
          lobName,
          formType: 'RTD',
          isLostBookingId,
        });
      } else {
        setDisableAction(true);
      }
    } else {
      updateApi.httpPost({
        bookingId,
        uniqueId: 'UPDATE_REFUND_STATUS',
        body: {
          cancellationRequestNo: requestNo,
          bookingId: bookingId,
          refundReceivedStatus: refundStatus,
        },
        psLob: lobName,
      });
    }
  };

  const getIconStyle = () => {
    return [styles.thumsIconStyle, isLostBookingId ? { transform: [{ scaleY: -1 }] } : null];
  };

  const disableBtn = updateInProgress || disableAction ? 'disableStyle' : '';

  if (isReceived == false) {
    return null;
  }

  return (
    <View
      style={[
        styles.refundReceivedCard,
        isLostBookingId ? { padding: 20, borderTopColor: '#fff' } : null,
      ]}
    >
      {isReceived == undefined && (
        <View>
          <Text
            style={[
              AtomicCss.font16,
              AtomicCss.blackText,
              fonts.boldFontFamily,
              AtomicCss.marginBottom16,
            ]}
          >
            {headerText}
          </Text>
          <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween]}>
            <TouchableOpacity
              style={[styles.btnWrapper]}
              activeOpacity={0.7}
              onPress={() => {
                handleRefundReceived(REFUND_STATUS.RECEIVED);
              }}
            >
              <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, styles[disableBtn]]}>
                <Image style={getIconStyle()} source={{ uri: answerYes.iconUrl }} />
                <Text style={[AtomicCss.font14, AtomicCss.blackText, AtomicCss.boldFont]}>
                  {answerYes.ctaText}
                </Text>
              </View>
              {updateInProgress && refundStatus == REFUND_STATUS.RECEIVED && (
                <View style={styles.activityIndicator}>
                  <Loader />
                </View>
              )}
            </TouchableOpacity>
            <TouchableOpacity
              style={[styles.btnWrapper]}
              onPress={() => {
                handleRefundReceived(REFUND_STATUS.NOT_RECEIVED);
              }}
            >
              <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, styles[disableBtn]]}>
                <Image style={getIconStyle()} source={{ uri: answerNo.iconUrl }} />
                <Text style={[AtomicCss.font14, AtomicCss.blackText, fonts.boldFontFamily]}>
                  {answerNo.ctaText}
                </Text>
              </View>
              {updateInProgress && refundStatus == REFUND_STATUS.NOT_RECEIVED && (
                <View style={styles.activityIndicator}>
                  <Loader />
                </View>
              )}
            </TouchableOpacity>
          </View>
        </View>
      )}
      {isReceived == true && (
        <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, styles.successbgWrapper]}>
          <Image style={getIconStyle()} source={{ uri: answerYes.iconUrl }} />
          <Text
            style={[
              AtomicCss.font14,
              fonts.regularFontFamily,
              AtomicCss.blackText,
              AtomicCss.lineHeight20,
              AtomicCss.flex1,
            ]}
          >
            {answerYes.text}
          </Text>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  refundReceivedCard: {
    backgroundColor: '#fff',
    marginBottom: 12,
    paddingTop: 10,
    marginTop: 10,
    borderTopColor: '#e7e7e7',
    borderTopWidth: 1,
  },
  thumsIconStyle: {
    width: 22,
    height: 22,
    marginRight: 12,
  },
  btnWrapper: {
    ...getPlatformElevation(2),
    backgroundColor: '#fff',
    borderRadius: 4,
    borderWidth: 1,
    borderColor: '#f1f1f1',
    padding: 10,
    flexDirection: 'row',
    alignItems: 'center',
    width: '47%',
    justifyContent: 'center',
  },
  successbgWrapper: {
    backgroundColor: '#E8FBF2',
    borderRadius: 4,
    paddingVertical: 4,
    paddingLeft: 14,
    paddingRight: 8,
  },
  activityIndicator: {
    transform: [{ scale: 1.2 }],
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    bottom: -10,
  },

  disableStyle: {
    opacity: 0.4,
  },
});

export default RefundFeedback;
