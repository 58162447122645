export const ViewState = {
  LOADING: 'loading',
  LOADED: 'loaded',
  EMPTY: 'empty',
  ERROR: 'error',
};

export const contactUsApi = {
  BookingList: 'BookingLists',
  searchByUUID: 'searchByUUID',
};
export const PAGE_NAME = {
  CONTACT_US: 'cs:personal:Contact Us',
  STILL_NEED_HELP: 'STILL_NEED_HELP',
  BOOKING_SEARCH_OVERLAY: 'BOOKING_SEARCH_OVERLAY',
  CONTACT_US_API_SUCCESS: 'CONTACT_US_API_SUCCESS',
  CONTACT_US_API_ERROR: 'CONTACT_US_API_ERROR',
};

export const EVENT = {
  OPEN_CONTACT_US: 'OPEN_CONTACT_US',
  OPEN_STILL_NEED_HELP: 'OPEN_STILL_NEED_HELP',
  OPEN_BOOKING_SEARCH_OVERLAY: 'OPEN_BOOKING_SEARCH_OVERLAY',
  MY_TRIP: 'MY_TRIP_CLICK',
  OLDER_BOOKINg: 'OLDER_BOOKINg_CLK',
  SEARCH_BOOKING_CLK: 'SEARCH_BOOKING_CLK',
  CONTACT_US_MY_TRIP: 'mytrips_',
  CONTACT_US_QUICK_LINKS: 'quicklinks_',
  CONTACT_US_STILL_NEED_HELP: 'stillneedhelp',
  ON_TRIP_TALK_TO_US: 'ontrip_talktous_clicked',
  MSR_OPEN_ALL: 'msr_open_all_clicked',
  MSR_ON_TRIP: 'msr_ontrip_clicked',
  LOYAL_TALK_TO_US: 'loyal_talktous_clicked',
};

export const cardIds = {
  MY_TRIP: 'my_trips',
  BOOKING_LIST: 'booking_details',
  QUICK_LINK: 'quick_link',
  NEED_HELP: 'need_help',
  HELP_BOOKING_LIST: 'help_booking_list',
  HELP_FOOTER: 'help_footer',
  CANCELATION_CARD: 'cancellation_card',
  REFUND_CARD: 'modification_card',
  MODIFICATION_CARD: 'track_refund_card',
  STILL_NEED_HELP_CARD: 'needHelp',
};
export const deepLinkUrl = {
  BOOKING_SUMMARY: 'mmyt://mytrips',
  LOB_DETAIL: 'mmyt://tripDetails/',
  MWEB_BASE_URL: 'https://supportz.makemytrip.com/mweb/',
  MWEB_SUMMARY_URL: 'https://supportz.makemytrip.com/mweb/bookingSummary',
};

export const CUSTOMER_SUPPORT = 'CUSTOMER_SUPPORT';

export const ACTIVE = 'ACTIVE';

export const BULLET_POINT_CONTENT = '\u2B24';

export default {};
