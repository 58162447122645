import React from 'react';
import {View, Text, StyleSheet} from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';

class AccordianDetails extends React.Component {

    render() {
        return (
            <View style={[styles.CollapsedTapDtlsContainer1]}>
                <View style={[styles.answerRow, AtomicCss.marginBottom15]}>
                    <Text style={styles.bulletsPoints}>.</Text>
                    <View style={AtomicCss.flex1}>
                        {this.fetchFaqDescriptionInfo(this.props.response.generalFAQList)}
                    </View>
                </View>
            </View>
        );
    }

    fetchFaqDescriptionInfo = (response) => {
        let views = [];
        Object.keys(response).map(key => {
            const {answers} = response[key]
            views.push(
                <Text
                    style={[AtomicCss.lineHeight20, AtomicCss.font14, AtomicCss.boldFont, AtomicCss.defaultTextColor]}>
                    {answers}
                </Text>
            );
        })
        return views;
    }
}

const styles = StyleSheet.create({
    CollapsedTapDtlsContainer1: {
        backgroundColor: 'transparent',
        paddingHorizontal: 20,
        borderTopWidth: 1,
        borderColor: '#e4e4e4',
        paddingVertical: 10
    },

    answerRow: {
        paddingBottom: 12,
        flexDirection: 'row',
        marginLeft: 10
    },
    bulletsPoints: {
        width: 8,
        height: 8,
        borderRadius: 50,
        backgroundColor: '#5a5a5a',
        marginRight: 15,
        alignItems: 'center',
        marginTop: 8
    }
});

export default AccordianDetails;
