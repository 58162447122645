import React, { useContext } from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import HTMLView from 'react-native-htmlview';
import getStyle, { htmlStyle } from './SelectPassengersCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getFont } from '../../../../../../../PostSalesStyles';
import CheckboxRow from '../CheckboxRow';
import { YELLOW_BG_INFO_ICON, ONEWAY_ARROW_ICON } from '../../../../../../FlightImageConstants';
import SpecialClaimNewContext from '../../../../SpecialClaimNewContext';
import CardLayoutSeperator from '../../../../../../../Common/CardLayoutSeperator';
import { SPECIAL_CLAIM_ACITONS } from '../../../../constant';
import specialClaimActionTypes from '../../../../data/specialClaimActionTypes';
import { isEmptyArray } from '../../../../../../../Common/commonUtil';
import { externalLinkPressHandler } from '../../../../../../../utils/PostSaleUtil';
import CheckBox from '../../../../../../../Common/Checkbox';
import { getPassengerSelectionData } from '../../../../data/dataAdapter';
import { useTheme } from '../../../../../../../theme/theme.context';
import isEmpty from 'lodash/isEmpty';

const SelectPassengers = ({
  isLastItem,
  handleBottomOverlay,
  paxData,
  knowMoreText,
  dispatchCancellationState,
}) => {
  const context = useContext(SpecialClaimNewContext);
  const { fsStyle, ...fonts } = getFont(true);
  const {psTheme} = useTheme();
  const styles = getStyle(psTheme, fonts);
  const {
    title,
    subtitle,
    img,
    footerText,
    entries,
    selected,
    id,
    fromCity,
    toCity,
    paxList,
    knowMoreOverlayData
  } = getPassengerSelectionData(paxData);
  const { titleList, subtitleList } = paxData;
  const imgUri = context.airlineUrlMap[img] && context.airlineUrlMap[img].mobile;

  const openKnowMoreOverlay = () => {
    handleBottomOverlay(SPECIAL_CLAIM_ACITONS.know_more, knowMoreOverlayData);
  };

  const onSectorSelection = () => {
    dispatchCancellationState({ type: specialClaimActionTypes.TOGGLE_SEGMENT, segmentId: id });
  };

  const onPaxSelection = (paxId) => {
    dispatchCancellationState({ type: specialClaimActionTypes.TOGGLE_PAX, segmentId: id, paxId });
  };

  const makeTitleOfSpecialClaim = (title) => {
    const citiesToShow = title.split(" - ");
    let headingView = [];
    for(let i=0; i< citiesToShow.length; i++){
      headingView.push(<Text key={`heading-${i}`} style={[styles.cityText, fsStyle.font18, AtomicCss.lineHeight28]}>{citiesToShow[i]}</Text>);
      if(i !== citiesToShow.length -1){
        headingView.push(<Image key={`arrow-${i}`} style={styles.arrowIcon} source={ONEWAY_ARROW_ICON} />)
      }
    }
    return headingView;
  }

  return (
    <View style={[styles.cardWrapper, styles[isLastItem]]}>
      <View style={styles.checkAllWrapper}>
        <View style={[styles.checkAllWrapperInner]}>
          <TouchableOpacity
            style={[styles.checkBtn, styles.checkAllBtn, styles.paxslctionHeader]}
            activeOpacity={0.9}
            onPress={onSectorSelection}
          >
            <View style={styles.sectorHeader}>
              <View style={{ flexDirection: 'row' }}>
                <CheckBox
                  checked={selected}
                  containerStyle={AtomicCss.marginTop3}
                  size={19}
                  onChange={onSectorSelection}
                />
                <View style={{flexDirection: 'column'}}>
                  {!isEmpty(titleList) ? titleList.map((title,index) => (
                    <>
                      <View style={styles.destRow}>
                        {makeTitleOfSpecialClaim(title)}
                      </View>
                      {!isEmpty(subtitleList) && !isEmpty(subtitleList[index]) && <Text style={[styles.smallText, fsStyle.font13, AtomicCss.marginBottom5]}>{subtitleList[index]}</Text>}
                    </>
                  ))
                  :
                  <>
                   <View style={styles.destRow}>
                    {!isEmpty(title) ? makeTitleOfSpecialClaim(title) :
                      <>
                        {!!fromCity && (
                          <Text style={[styles.cityText, fsStyle.font18, AtomicCss.lineHeight28]}>{fromCity}</Text>
                        )}
                        <Image style={styles.arrowIcon} source={ONEWAY_ARROW_ICON} />
                        {!!toCity && <Text style={[styles.cityText, fsStyle.font18, AtomicCss.lineHeight28]}>{toCity}</Text>}
                      </>}
                  </View>
                  {!!subtitle && <Text style={[styles.smallText, fsStyle.font13]}>{subtitle}</Text>}
                  </>
                  }
                </View>
              </View>
              <View style={styles.airlinesIconWrapper}>
                {!!imgUri && <Image style={styles.airlineIcon} source={{ uri: imgUri }} />}
              </View>
            </View>
          </TouchableOpacity>
        </View>
      </View>
      <View style={styles.seperatorWrapper}>
        <CardLayoutSeperator marginVertical={3} />
      </View>
      <View style={styles.passengersList}>
        <React.Fragment>
          {!isEmptyArray(paxList) &&
            paxList.map((item, index) => {
              return (
                <View style={styles.paxListWrapper} key={item.id}>
                  <CheckboxRow
                    key={index}
                    index={index}
                    data={item}
                    onPressHandler={onPaxSelection.bind(null, item.id)}
                  />
                </View>
              );
            })}
          {!!footerText && (
            <View style={styles.moreDtlsSection}>
              <Image source={YELLOW_BG_INFO_ICON} style={styles.infoIcon} />
              <View style={[AtomicCss.flex1, { paddingRight: 2 }]}>
                <HTMLView
                  stylesheet={{ ...htmlStyle(psTheme, fonts), p: { ...htmlStyle(psTheme, fonts).p, ...fsStyle.font12 } }}
                  value={'<p>' + footerText + '</p>'}
                  onLinkPress={externalLinkPressHandler}
                />
                {!!knowMoreText && (
                  <Text
                    style={[fonts.boldFontFamily, fsStyle.font12, styles.textBlue]}
                    onPress={openKnowMoreOverlay}
                  >
                    {' '}
                    {knowMoreText}
                  </Text>
                )}
              </View>
            </View>
          )}
        </React.Fragment>
      </View>
    </View>
  );
};

export default SelectPassengers;
