import React from 'react';
import { Image, Text, View } from 'react-native';
import PropTypes from 'prop-types';
import Actions from '../../../navigation/postSalesNavigation';
import styles from './CancelAnywaySuccessCss';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import NoInternetView from '@mmt/legacy-commons/Common/Components/Error/NoInternetView';
import ProgressView from '../../../Common/ProgressView/ProgressView';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import { isNetworkAvailable } from '@mmt/legacy-commons/Common/utils/AppUtils';
import BasePage from '../../../Common/PostSalesBasePage';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import logAction from '@mmt/legacy-commons/Helpers/actionsLogger';
import {
  isNotNullAndEmpty,
  isNullOrEmpty,
} from '../../../hotel/details/utils/HotelBookingDetailUtil';
import { goToBookingDetails } from '../Helpers/FlightCancellationUtility';
import { FLIGHT_CANCELLATION_DONE_URL, getCommonHeaders } from '../../../utils/NetworkUtils';
import BlueRectangleButton from '../../../Common/BlueRectangleButton';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import CancellationTrackingHelper from '../Helpers/CancellationTrackingHelper';
import { openErrorPage } from '../../../utils/PostSaleUtil';
import * as NpsModule from '@mmt/legacy-commons/Native/NpsModule';
import { getRatingData } from '../../../Common/commonUtil';
import { getStaticData } from '../../../staticData/staticData';
import { ERROR_REQUEST_ICON } from '../../FlightImageConstants';
import { LOBNAMES } from '../../../PostSalesConstant';
import { getFont } from '../../../PostSalesStyles';

export default class CancelAnywaySuccess extends BasePage {
  constructor(props) {
    super(props, 'cancelAnywaySuccess');
    this.state = {
      viewState: ViewState.LOADING,
      responseJson: null,
    };
    this.trackingPageName = 'CancelAnywaySuccess';
    this.showRatingPrompt = true;
  }

  componentDidMount() {
    super.componentDidMount();
    this.fetchJSONAsync();
  }

  render() {
    return <View style={styles.parentContainer}>{this.renderStateWise()}</View>;
  }

  onHardBackPress = () => {
    goToBookingDetails(this.props.cancellationRequest.bookingId);
    logAction('HardwareBackPressed');
    return true;
  };

  async fetchJSONAsync() {
    try {
      const hasNetwork = await isNetworkAvailable();
      if (!hasNetwork) {
        this.setState({ viewState: ViewState.NO_INTERNET });
        CancellationTrackingHelper.trackErrorEvent(
          this.trackingPageName,
          this.getErrorTrackingBundle('no_internet'),
          undefined,
        );
        return;
      }
      const userInfo = await HotelBookingModule.getRequestHeader(
        this.props.cancellationRequest.bookingId,
        'FLIGHT_CANCELLATION_DONE',
      );
      const response = await fetch(FLIGHT_CANCELLATION_DONE_URL, {
        method: 'POST',
        headers: await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo, {
          psLob: LOBNAMES.FLIGHT,
        }),
        body: JSON.stringify(this.props.cancellationRequest),
      });
      if (response && response.ok) {
        const body = await response.json();
        if (body.errorPageData) {
          openErrorPage(
            body.errorPageData,
            this.onBackPressFromError,
            this.setStateLoading,
            this.props.cancellationRequest.bookingId,
            'cancellation',
            Actions,
            { psLob: LOBNAMES.FLIGHT, langAware: true },
          );
          CancellationTrackingHelper.trackErrorEvent(
            this.trackingPageName,
            this.getErrorTrackingBundle(body.errorPageData.errorCode),
            body.errorPageData.meta ? body.errorPageData.meta.supplier : undefined,
          );
        } else if (isNullOrEmpty(body.businessErrorDescription) && body.isCancelAnyway) {
          this.setState({
            responseJson: body,
            viewState: ViewState.SHOW_DETAIL,
          });
          CancellationTrackingHelper.trackLoadEvent(
            this.trackingPageName,
            this.props.trackingBundle,
          );
        } else {
          this.setState({ viewState: ViewState.ERROR });
          const errorName = isNotNullAndEmpty(body.businessErrorDescription)
            ? body.businessErrorDescription
            : 'cancellation_status_0';
          CancellationTrackingHelper.trackErrorEvent(
            this.trackingPageName,
            this.getErrorTrackingBundle(errorName),
            undefined,
          );
        }
      } else {
        this.setState({ viewState: ViewState.ERROR });
        CancellationTrackingHelper.trackErrorEvent(
          this.trackingPageName,
          this.getErrorTrackingBundle('response_error'),
          undefined,
        );
      }
    } catch (error) {
      this.setState({ viewState: ViewState.ERROR });
      CancellationTrackingHelper.trackErrorEvent(
        this.trackingPageName,
        this.getErrorTrackingBundle(error.message),
        undefined,
      );
    }
  }

  onBackPressFromError = () => {
    setTimeout(() => goToBookingDetails(this.props.cancellationRequest.bookingId));
  };

  renderStateWise() {
    if (this.state.viewState === ViewState.LOADING) {
      return this.renderProgressView();
    } else if (this.state.viewState === ViewState.NO_INTERNET) {
      return this.renderNoNetworkView();
    } else if (this.state.viewState === ViewState.ERROR) {
      return this.renderErrorView();
    }
    return this.renderPage();
  }

  renderNoNetworkView = () => {
    const {
      errorText: { notConnectedToTheInternetError, checkYourSettingsAndTryAgainError },
      tryAgainText,
    } = getStaticData();

    return (
      <NoInternetView
        heading={notConnectedToTheInternetError}
        subHeading={checkYourSettingsAndTryAgainError}
        buttonText={tryAgainText}
        onRetry={() => {
          this.setStateLoading();
        }}
      />
    );
  };

  renderProgressView = () => {
    const { initializingRequestText } = getStaticData();
    return <ProgressView message={initializingRequestText} />;
  };

  renderErrorView = () => {
    const {
      errorText: { somethingWentWrongTryAgainError },
      tryAgainText,
    } = getStaticData();
    return (
      <ErrorView
        showRetry
        buttonLabel={tryAgainText}
        message={somethingWentWrongTryAgainError}
        onRetry={() => {
          this.setStateLoading();
        }}
      />
    );
  };

  setStateLoading = () => {
    this.setState({ viewState: ViewState.LOADING });
    this.fetchJSONAsync();
  };

  renderPage = () => {
    const { ratingData, npsData } = getRatingData(this.trackingPageName, this.state.responseJson);
    if (ratingData && this.showRatingPrompt) {
      NpsModule.showRatingPrompt(ratingData, npsData);
      this.showRatingPrompt = false;
    }
    const {
      goBackToBookingText,
      cancelAnywaySuccessText: {
        heading,
        item: { description },
      },
    } = getStaticData();
    const { fsStyle, ...fonts } = getFont(true);

    return (
      <View style={styles.pageWrapper}>
        <View style={styles.messageContent}>
          <Image style={styles.messageIcon} source={ERROR_REQUEST_ICON} />
          <Text style={[styles.messageTitle, fonts.boldFontFamily, fsStyle.font20]}>{heading}</Text>
          <Text style={[styles.messageDesc, fonts.regularFontFamily, fsStyle.font14]}>
            {description.heading}
          </Text>
          <Text
            style={[
              styles.messageDesc,
              AtomicCss.marginBottom20,
              fonts.regularFontFamily,
              fsStyle.font14,
            ]}
          >
            {description.subHeading}
          </Text>
        </View>
        <View style={styles.footerBtnWrapper}>
          <BlueRectangleButton
            buttonText={goBackToBookingText}
            clickHandler={() => goToBookingDetails(this.props.cancellationRequest.bookingId)}
          />
        </View>
      </View>
    );
  };
}

CancelAnywaySuccess.propTypes = {
  cancellationRequest: PropTypes.object.isRequired,
  trackingBundle: PropTypes.object.isRequired,
};
