import React from "react";
import {appendUrlPrefix} from "../../webUtils";
import loadable from "@loadable/component";
import ProgressView from "../../components/ProgressView";

const BUS_PATHS = {
    BUS_PAYMENT_BREAKUP: {
        pathName: appendUrlPrefix('/busPaymentBreakup'),
        pageKey: "busPaymentBreakup",
        Component: loadable(() => import("@rn/apps/post-sales/src/bus/components/BusPaymentDetails"), { fallback: <ProgressView /> })
    },
    BUS_CANCELLATION_DETAILS: {
        pathName: appendUrlPrefix('/busCancellationDetails'),
        pageKey: "busCancellationDetails",
        Component: loadable(() => import("@rn/apps/post-sales/src/bus/components/BusCancellationDetails"), { fallback: <ProgressView /> })
    },
    CHOOSE_BUS_REFUND: {
        pathName: appendUrlPrefix('/chooseBusRefund'),
        pageKey: "chooseBusRefund",
        Component: loadable(() => import("@rn/apps/post-sales/src/bus/components/ChooseBusRefund"), { fallback: <ProgressView /> })
    },
    BUS_BOOKING_DETAIL: {
        pathName: appendUrlPrefix('/busBookingDetail'),
        pageKey: "busBookingDetail",
        Component: loadable(() => import("@rn/apps/post-sales/src/bus/BusBookingDetail"), { fallback: <ProgressView /> })
    },
    TRACK_BUS: {
        pathName: appendUrlPrefix('/trackBus'),
        pageKey: "trackBus",
        Component: loadable(() => import("@rn/apps/post-sales/src/bus/liveTracking/trackBus/TrackBus"), { fallback: <ProgressView /> })
    },
    BUS_CANCELLATION_SELECTED_PAX: {
        pathName: appendUrlPrefix('/busCancellationSelectPax'),
        pageKey: "busCancellationSelectPax",
        Component: loadable(() => import("@rn/apps/post-sales/src/bus/cancellation/SelectTravellers"), { fallback: <ProgressView /> })
    },
    BUS_REVIEW_REFUND_CARGES: {
        pathName: appendUrlPrefix('/busreviewRefundCharges'),
        pageKey: "busreviewRefundCharges",
        Component: loadable(() => import("@rn/apps/post-sales/src/bus/cancellation/ReviewRefundCharges"), { fallback: <ProgressView /> })
    },
    REVIEW_BUS_REFUND_MODE: {
        pathName: appendUrlPrefix('/reviewBusRefundMode'),
        pageKey: "reviewBusRefundMode",
        Component: loadable(() => import("@rn/apps/post-sales/src/bus/cancellation/RefundMode/BusRefundMode"), { fallback: <ProgressView /> })
    },
    BUS_BOOKING_CANCELLED: {
        pathName: appendUrlPrefix('/busBookingCancelled'),
        pageKey: "busBookingCancelled",
        Component: loadable(() => import("@rn/apps/post-sales/src/bus/ErrorState/BusBookingCancel"), { fallback: <ProgressView /> })
    },
    BUS_CANCELLED_BY_OPERATOR: {
        pathName: appendUrlPrefix('/busCancelledByOperator'),
        pageKey: "busCancelledByOperator",
        Component: loadable(() => import("@rn/apps/post-sales/src/bus/components/SpecialClaim/BusCancelledByOperator"), { fallback: <ProgressView /> })
    },
    BUS_CANCELLED_BY_OPERATOR_REVIEW: {
        pathName: appendUrlPrefix('/busCancelledByOperatorReview'),
        pageKey: "busCancelledByOperatorReview",
        Component: loadable(() => import("@rn/apps/post-sales/src/bus/components/SpecialClaim/BusCancelledByOperatorReview"), { fallback: <ProgressView /> })
    },
    BUS_CANCELLED_BY_OPERATOR_THANK_YOU: {
        pathName: appendUrlPrefix('/busCancelledByOperatorThankYou'),
        pageKey: "busCancelledByOperatorThankYou",
        Component: loadable(() => import("@rn/apps/post-sales/src/bus/components/SpecialClaim/BusCancelledByOperatorThankYou"), { fallback: <ProgressView /> })
    },
    BUS_SUPPORT_ISSUE_SELECTED: {
        pathName: appendUrlPrefix('/busSupportIssueSelect'),
        pageKey: "busSupportIssueSelect",
        Component: loadable(() => import("@rn/apps/post-sales/src/bus/components/Support/IssueSelection"), { fallback: <ProgressView /> })
    },
    AUTO_REFUND_BUS_ROUTER: {
        pathName: appendUrlPrefix('/autoRefundBusRouter'),
        pageKey: "autoRefundBusRouter",
        Component: loadable(() => import("@rn/apps/post-sales/src/bus/components/SpecialClaim/CheckingBusOverlay"), { fallback: <ProgressView /> })
    },
    BUS_SUPPORTED_ISSUE_DETAIL: {
        pathName: appendUrlPrefix('/busSupportIssueDetail'),
        pageKey: "busSupportIssueDetail",
        Component: loadable(() => import("@rn/apps/post-sales/src/bus/components/Support/IssueDetail"), { fallback: <ProgressView /> })
    },
    BUS_SUPPORTED_ISSUE_SUBMIT: {
        pathName: appendUrlPrefix('/busSupportIssueSubmit'),
        pageKey: "busSupportIssueSubmit",
        Component: loadable(() => import("@rn/apps/post-sales/src/bus/components/Support/IssueSubmit"), { fallback: <ProgressView /> })
    },
    BUS_WRITE_REVIEW_WRAPPER: {
        pathName: appendUrlPrefix('/busWriteReviewWrapper'),
        pageKey: "busWriteReviewWrapper",
        Component: loadable(() => import("@rn/apps/post-sales/src/bus/components/BusWriteReviewWrapper"), { fallback: <ProgressView /> })
    },
    PRIMO_VIEW_MORE: {
        pathName: appendUrlPrefix('/primoViewMore'),
        pageKey: "primoViewMore",
        Component: loadable(() => import("@rn/apps/post-sales/src/bus/components/PrimoViewMore"), { fallback: <ProgressView /> })
    },
    BUS_REFUND_BREAKUP_PAGE : {
        pathName: appendUrlPrefix('/busRefundBreakup'),
        pageKey: "busRefundBreakup",
        Component: loadable(() => import("@rn/apps/post-sales/src/Common/RefundBreakupPage"), { fallback: <ProgressView /> })
    },
}

export default BUS_PATHS;