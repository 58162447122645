import React, { useRef, useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Polyline, Tooltip, Popup } from 'react-leaflet';
import L from 'leaflet';
import { StyleSheet } from 'react-native';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import HTMLView from 'react-native-htmlview';
import 'leaflet/dist/leaflet.css';
import carImage from '../../images/carIcon.webp';
import dropIcon from '../../images/Drop_icon_2x.webp';
import pickupIcon from '../../images/Pickup_icon_2x.webp';
import isEmpty from 'lodash/isEmpty';

const MapViewWrapper = ({
  handleRegionChange,
  initialRegion,
  pickupLocation,
  destinationLocation,
  wayPointsList,
  strokeWidth,
  strokeColor,
  rotationAngle,
  currentCarLocation,
  toolTipText,
}) => {
  const { psTheme: theme } = useTheme();
  const { fsStyle, ...fonts } = getFont(true);

  const pickupIconMarker = new L.Icon({ iconUrl: pickupIcon, iconSize: [20, 40] });
  const dropIconMarker = new L.Icon({ iconUrl: dropIcon, iconSize: [20, 40] });

  // Car icon with DivIcon for dynamic rotation
  const carIconMarker = new L.DivIcon({
    html: `<div class="car-icon" style="transform: rotate(${rotationAngle}deg); width: 30px; height: 30px; background-image: url(${carImage}); background-size: contain;"></div>`,
    iconSize: [30, 30],
    className: 'custom-car-marker',
  });

  useEffect(() => {
    // Update rotation dynamically when rotationAngle changes
    const carIconElement = document.querySelector('.car-icon');
    if (carIconElement) {
      carIconElement.style.transform = `rotate(${rotationAngle}deg)`;
    }
  }, [rotationAngle]);

  return (
    <MapContainer
      center={[initialRegion.latitude, initialRegion.longitude]}
      zoom={15}
      style={{ height: '100%', width: '100%', zIndex: 0 }}
      onMoveEnd={handleRegionChange}
      attributionControl={false}
    >
      <TileLayer style={{ zIndex: 0 }} url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

      {/* Pickup Location Marker */}
      <Marker
        position={[pickupLocation.latitude, pickupLocation.longitude]}
        icon={pickupIconMarker}
      />

      {/* Destination Location Marker */}
      <Marker
        position={[destinationLocation.latitude, destinationLocation.longitude]}
        icon={dropIconMarker}
      />

      {/* Car Marker with rotation */}
      {!isEmpty(wayPointsList) && (
        <Marker
          key={`${wayPointsList[0].latitude}-${wayPointsList[0].longitude}`}
          position={[wayPointsList[0].latitude, wayPointsList[0].longitude]}
          icon={carIconMarker}
        >
          <Popup closeButton={false}>
            <HTMLView value={toolTipText} stylesheet={toolTipTextStyle(theme, fonts)} />
          </Popup>
        </Marker>
      )}

      <Polyline
        positions={wayPointsList.map((point) => [point.latitude, point.longitude])}
        color={strokeColor}
        weight={strokeWidth}
      />
    </MapContainer>
  );
};

export const toolTipTextStyle = (theme, fonts) => {
  return StyleSheet.create({
    p: {
      color: theme.color.black,
      fontSize: fonts.fontSize.font14,
      textAlign: 'center',
      ...fonts.regularFontFamily,
      margin: -8
    },
    b: {
      ...fonts.boldFontFamily,
    },
  });
};

export default MapViewWrapper;
