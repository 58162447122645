import React from 'react';
import { getBusSummaryData } from './busCardAdapter';
import {
    ActionsContent,
    DescriptionSection,
    MainContent,
    SummaryContainer,
    CardInfoSection,
    JourneyInfoText,
    StatusInfoSection,
    WhereToGoTopImage,
} from '../cardElements';
import MSRRefundInfoCard from '../MSRRefundInfoCard';
import { BusSummaryDataProps, MsrTicket } from '../cardInterfaces';
interface BusSummaryCardProps {
    response: BusSummaryDataProps,
    msrTicket: MsrTicket,
    isUpcomingOrOnTripFirstBooking: boolean,
    topImage: string
}

function BusSummaryCard({ response, msrTicket, isUpcomingOrOnTripFirstBooking, topImage }: BusSummaryCardProps) {
    const cardData = getBusSummaryData(response, msrTicket);
    const { iconUrl,
        from,
        to,
        actions,
        myRequestsCard,
        busName,
        passengerDetails,
        busStartingPoint,
        startsIn,
        startDateText,
        endDateText,
        startTimeText,
        endTimeText,
        lobName,
        bookingId,
        status,
        infoCardStatusText,
        infoCardStatusCTA,
        openDetails,
        handleInfoPress } = cardData || {};

    return (
        <SummaryContainer bookingId={response.bookingID} >
            {topImage && isUpcomingOrOnTripFirstBooking &&
                <WhereToGoTopImage topImageText={startsIn} topImage={topImage} />
            }
            <MainContent onPress={openDetails}>
                <DescriptionSection>
                    <CardInfoSection
                        iconUrl={iconUrl}
                        name={busName}
                        startingPoint={busStartingPoint}
                        passengerDetails={passengerDetails}
                    />
                    <JourneyInfoText
                        from={from}
                        startDateTime={startTimeText}
                        startDateText={startDateText}
                        to={to}
                        endDateTime={endTimeText}
                        endDateText={endDateText}
                    />
                    {infoCardStatusText && <StatusInfoSection statusText={infoCardStatusText} statusCta={infoCardStatusCTA} handleInfoPress={handleInfoPress} />}
                </DescriptionSection>
            </MainContent>
            <ActionsContent actions={actions} isInfoCardShown={!!infoCardStatusText}/>
            {myRequestsCard && <MSRRefundInfoCard data={myRequestsCard} lobName={lobName} status={status} bookingId={bookingId} />}
        </SummaryContainer>
    );
}

export default BusSummaryCard;