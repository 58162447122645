import React from 'react';
import LinearGradient from 'react-native-linear-gradient';
import getStyles from '../../styles/BusMamiCss';
import {BackHandler, Text, TouchableOpacity, View, StyleSheet} from 'react-native';
import logAction from '@mmt/legacy-commons/Helpers/actionsLogger';
import SpecialClaimBaseClass from './SpecialClaimBaseClass';
import Actions from '../../../navigation/postSalesNavigation';
import PropTypes from 'prop-types';
import BusCancelledByOperatorReview from './BusCancelledByOperatorReview';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

export default class BusCancelledByOperatorThankYou extends SpecialClaimBaseClass {
  constructor(props) {
    super(props, 'busCancelledByOperatorThankYou', 'SpecialclaimThankyou');
    this.response = this.props.response;
  }

  render() {
    let specialClaimData = this.props.response.specialClaimDataList[0];
    const fonts = getFont(true);
    const styles = getStyles();
    return (
      <View style={styles.ErrorStateWrapper}>
        <View>
          <Text style={[thankyouStyles.textContainer, fonts.boldFontFamily, fonts.font20]}>{specialClaimData.claimSubmitHeader}</Text>
          <Text style={[styles.AlignCenter, styles.lineHt22, styles.mar20Bt]}>
            {specialClaimData.claimSubmitMessage}
          </Text>
        </View>
        <TouchableOpacity onPress={this.goBack}>
          <LinearGradient
            start={{
            x: 1.0,
            y: 0.0
          }}
            end={{
            x: 0.0,
            y: 1.0
          }}
            colors={['#065af3', '#53b2fe']}
            style={[styles.CommonFillBtn]}
          >
            <Text
              style={[styles.WhiteText, styles.CenterText, fonts.blackFontFamily, styles.transperantBgrnd]}
            >{specialClaimData.updatedBooking}
            </Text>
          </LinearGradient>
        </TouchableOpacity>
      </View>
    );
  }

  goBack = () => {
    Actions.busBookingDetail({ data: { BOOKING_ID: this.props.bookingId, refresh: true }});
  };

  onHardBackPress = () => {
    this.goBack();
    logAction('HardwareBackPressed');
    return true;
  };

  componentDidMount() {
    super.componentDidMount();
    this.trackPageLoad(this.response, this.props.specialClaim, this.props.bookingState);
    BackHandler.addEventListener('hardwareBackPress', this.onHardBackPress);
  }
  
  componentWillUnmount() {
    BackHandler.removeEventListener('hardwareBackPress', this.onHardBackPress);
  }
}

const thankyouStyles = StyleSheet.create({
  textContainer: {
    textAlign: 'center',
    color: '#3c3c3c',
  },
});

BusCancelledByOperatorReview.propTypes = {
  specialClaimData: PropTypes.string.isRequired,
  bookingId: PropTypes.string.isRequired,
  lobCode: PropTypes.string.isRequired,
  selectedPassenger: PropTypes.object.isRequired
};


