import { colors } from '@mmt/legacy-commons/Styles/globalStyles';

const styles = {
  dottedList: {
    elevation: 2,
    shadowColor: '#000000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 2,
    borderRadius: 4,
    padding: 18,
    paddingBottom: 8,
    marginTop: 2,
    backgroundColor: '#ffeae1',
  },
  dottedListItem: {
    position: 'relative',
    paddingLeft: 13,
  },
  dottedListDot: {
    height: 4,
    width: 4,
    backgroundColor: '#000',
    borderRadius: 5,
    position: 'absolute',
    top: 6,
    left: 0,
  },
  dottedListText: {
    color: colors.black,
    marginBottom: 10,
  },
};

export default styles;
