import { Dimensions, StyleSheet } from "react-native";
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';

export default (theme, fonts) =>StyleSheet.create({
    iconWrapper: {
        width: 26,
        height: 26,
        marginRight: 10,
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    offerIconStyle: {
        width: 24,
        height: 24,
        alignSelf: 'center'
    },
    detailsSection: {
        paddingLeft: 38,
        paddingRight: 10,
        marginRight: -25,
    },
    listCard: {
        borderWidth: 1,
        width: Dimensions.get('window').width / 1.8,
        borderRadius: 4,
        marginRight: 15,
    },
    listInnerDtls: {
        paddingLeft:5,
        paddingRight:5,
        paddingVertical: 15,
        flexDirection: 'row',
        flex: 1,
    },
    content:{
        position:'absolute',
        left: 30,
        top : 13,
        width : '85%'
    },
    iconStyle: {
        width: 40,
        height: 40,
        marginRight: 10,
        left :-23
    },
    title: {
        color: theme.color.azureBlue2,
        fontSize: 12,
        ...(fonts.boldFontFamily),
    },
    desc: {
        color: theme.color.azureBlue2,
        fontSize: 10,
        ...(fonts.regularFontFamily),
        marginTop: 3,
    },
    heigLighter: {
        width: 30,
        height: '100%',
    },
    rightArrowStyle: {
        width: 9,
        height: 10,
        top: 35,
        right: 5,
        marginLeft:5
    },
    nextOffer :{
        backgroundColor: theme.color.bubbles,
        paddingHorizontal: 10,
        paddingVertical: 4,
        borderRadius: 4,
        marginLeft : 34
    },
    nextOfferText :{
        color : theme.color.darkBlue11,
        width:'90%',
        alignSelf :'center'
    }
})