import { StyleSheet } from 'react-native';

export default (color) => {
  return StyleSheet.create({
    cabContainer: {
      backgroundColor: color.greyBookedSeat,
      flex: 1,
    },

    subCardPadding: {
      paddingLeft: 0,

      paddingRight: 0,
    },
    scrollSection: {
      paddingBottom: 16,
    },
		scrollSectionWithOverlay: {
      paddingBottom: 0,
    },
    OverlayContentWrapper: {
      backgroundColor: color.white,
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      paddingVertical: 20,
      paddingHorizontal: 5
    },
    customStyles:{
       borderRadius: 10,
       marginHorizontal:12,
       marginTop:10,
    },
  });
};
