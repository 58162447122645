import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import CabBookingDetailsConstant from '../../../CabBookingDetailsConstant';
import { getRydeBannerData } from '../../../utils/CabBookingDetailUtil';

const getLongTailActionList = (cardList) => {
  const longTailCard =
    !isEmpty(cardList) &&
    cardList.find(
      (item) =>
        item.cardId ===
          CabBookingDetailsConstant.TOP_STATUS_LONG_TAIL_VENDOR_PENDING ||
        item.cardId ===
          CabBookingDetailsConstant.TOP_STATUS_LONG_TAI_VENDOR_ACCEPTED,
    );
  const longTailCancelActionList =
    !isEmpty(longTailCard) && longTailCard.actionList;
  return longTailCancelActionList;
};

const shouldShowTrackerCard = (cardList) => {
  const showTrackerCard =
    !isEmpty(cardList) &&
    cardList.find(
      (item) =>
        (item.cardId ===
          CabBookingDetailsConstant.TOP_STAUTUS_LIVETRACKING_ENABLED_POST_PICKUP ||
          item.cardId ===
            CabBookingDetailsConstant.TOP_STATUS_LIVE_TRACKING_AFTER_PRE_JOURNEY) &&
        item.isVisible,
    );
  return showTrackerCard;
};

export const makeData = (response) => {
  const {
    bookingId,
    carAdditionalInfo,
    clientDetails,
    currency,
    ExtraChargesData: extraChargesData,
    bookingPaymentDetail,
    paymentBreakups,
    isInternationalBooking,
    flightDetails,
    internationalCabData,
    addonServicesData,
    cabRescheduleData,
    refundIssuedCardData,
    confirmYourLandingData,
		cabDirectionsData
  } = response;
  const isAcAvailable =
    carAdditionalInfo.airCondition === CabBookingDetailsConstant.AC_AVAILABLE;
  const seatInfo = carAdditionalInfo.carCapacity;
  let luggageText = '';
  if (
    response.carAdditionalInfo &&
    response.carAdditionalInfo.additionalLOBInfo &&
    response.carAdditionalInfo.additionalLOBInfo.tripInfo &&
    !isEmpty(response.carAdditionalInfo.additionalLOBInfo.tripInfo.bagsCount)
  ) {
    luggageText = `${response.carAdditionalInfo.additionalLOBInfo.tripInfo.bagsCount} ${CabBookingDetailsConstant.BAGS}`;
  }
  const carBookingInfo = {
    fromCity: get(response, 'carBookingInfo.carDetail.fromCity', ''),
    toCity: get(response, 'carBookingInfo.carDetail.toCity', ''),
    fromCityDesc: get(
      response,
      'carAdditionalInfo.departureDetail.address',
      ''
    ),
    dateTime: get(response, 'clientDetails.pickuptime', ''),
    internationalPickupTimeText: get(response, 'internationalCabData.pickupTimeText', ''),
    toCityDesc: get(response, 'carAdditionalInfo.arrivalDetail.address', ''),
    toCity: get(response, 'carBookingInfo.carDetail.toCity', ''),
    tripTypeText: get(response, 'carBookingInfo.carDetail.tripTypeText', ''),
    isRoundTrip: get(response, 'clientDetails.isRoundTrip', ''),
    returnDateTime: get(response, 'clientDetails.destinationtime', ''),
    newFromCity: get(response, 'carBookingInfo.carDetail.newFromCity', ''),
    newToCity: get(response, 'carBookingInfo.carDetail.newToCity', ''),
    hourlyRentalHours: get(response, 'carBookingInfo.carDetail.hourlyRentalHours', ''),
    hourlyRentalDistance: get(response, 'carBookingInfo.carDetail.hourlyRentalDistance', ''),
    isTimeUpdated: get(response, 'flightDetails.isTimeUpdated', ''),
    timeUpdatedText: get(response, 'flightDetails.timeUpdatedText', ''),
    cabReassignedText: get(response, 'flightDetails.cabReassignedText', ''),
    rescheduleRequestedData: get(response, 'flightDetails.rescheduleRequestedData', ''),
  };
  const dateTime = get(response, 'clientDetails.pickuptime', '');
  const carBookingInfoReschedule = {
    fromCity: get(response, 'carBookingInfo.carDetail.fromCity', ''),
    toCity: get(response, 'carBookingInfo.carDetail.toCity', ''),
    fromCityDesc: get(
      response,
      'carAdditionalInfo.departureDetail.address',
      ''
    ),
    toCityDesc: get(response, 'carAdditionalInfo.arrivalDetail.address', ''),
    toCity: get(response, 'carBookingInfo.carDetail.toCity', ''),
    tripTypeText: get(response, 'carBookingInfo.carDetail.tripTypeText', ''),
    newFromCity: get(response, 'carBookingInfo.carDetail.newFromCity', ''),
    newToCity: get(response, 'carBookingInfo.carDetail.newToCity', ''),
  };
  const cabDetailInfo = {
    carBrand: get(response, 'carBookingInfo.carDetail.carBrand', ''),
    vendorRating: get(response, 'carBookingInfo.carDetail.vendorRating', ''),
    carType: get(response, 'carBookingInfo.carDetail.carType', ''),
    cabUrl: get(response, 'carBookingInfo.carDetail.cabUrl', ''),
    seatText: seatInfo ? `${seatInfo} ${CabBookingDetailsConstant.SEATS}` : '',
    acText: isAcAvailable ? CabBookingDetailsConstant.AC : '',
    luggageText,
    fuelType: get(carAdditionalInfo, 'additionalLOBInfo.tripInfo.fuelType', ''),
    rydeBannerData: getRydeBannerData(response),
  };
  const driverDetailsInfo = {
    showStartOtp : !!get(carAdditionalInfo,'additionalLOBInfo.driverDetailsInfo.otp','',),
    startOtpText:
      get(clientDetails, 'startOtpText', '') ||
      `Start trip OTP  <b>${get(
        carAdditionalInfo,
        'additionalLOBInfo.driverDetailsInfo.otp',
        '',
      )}</b>`,
    endOtpText:
      get(clientDetails, 'endOtpText', ''),
    driverInfo: get(carAdditionalInfo, 'additionalLOBInfo.driverDetailsInfo', {}),
  };
  const { driverETAData } = clientDetails || {};
  const paymentInfo = {
    paymentToDriverText: get(clientDetails, 'paymentToDriverText', ''),
    isAllSettled: get(clientDetails, 'extraChargesData.isAllSettled', false),
    allSettledText: get(clientDetails, 'extraChargesData.allSettledText', ''),
    extraKmsChargeInfo: get(
      clientDetails,
      'extraChargesData.extraKmsChargeInfo',
      ''
    ),
    extrakms: get(clientDetails, 'extraChargesData.extrakms', ''),
    amountDue: get(bookingPaymentDetail, 'amountDue', ''),
    amountPaid: get(bookingPaymentDetail, 'paymentAmount', ''),
    currency,
    totalPaid:
      !isEmpty(paymentBreakups) &&
      paymentBreakups.find((item) => item.key === 'TOTALPAID'),
    paymentBreakups:
      !isEmpty(paymentBreakups) &&
      paymentBreakups.filter((item) => item.key !== 'TOTALPAID'),
  };
  const kioskInfo = {
    boardingSteps: get(clientDetails, 'kioskBoardingInfo.boardingSteps', []),
    heading: get(carAdditionalInfo, 'kioskBoardingInfo.heading', '') || 'How to Board the Cab?',
    kioskImage: get(
      carAdditionalInfo,
      'additionalLOBInfo.onSpotBoardingInfo.kioskImage',
      ''
    ),
    kioskPathImage: get(
      carAdditionalInfo,
      'additionalLOBInfo.onSpotBoardingInfo.kioskPathImage',
      ''
    ),
    QRCodeIcon: get(
      carAdditionalInfo,
      'additionalLOBInfo.onSpotBoardingInfo.qrCodeLink',
      ''
    ),
    qrCodeMessage: get(clientDetails, 'kioskBoardingInfo.qrCodeMsg', ''),
    latitude: get(carAdditionalInfo,'additionalLOBInfo.onSpotBoardingInfo.latitude',''),
    longitude:  get(carAdditionalInfo,'additionalLOBInfo.onSpotBoardingInfo.longitude',''),
  };
  const startOtpText = get(cabDirectionsData, 'startOtpText');
	const cabDirectionsInfo = {
		otpText: startOtpText ? `Start trip OTP <b>${startOtpText}</b>` : undefined,
	};
  const oldBookingText = get(clientDetails, 'OldBookingText', '');
  const cardList = response?.layoutDetail?.cardList;
  const unConfirmedCard =  !isEmpty(cardList) && cardList.find(item=> item.cardId === CabBookingDetailsConstant.TOP_STATUS_ADDRESS_PENDING);
  const addressPendingActionList = !isEmpty(unConfirmedCard) && unConfirmedCard.actionList;
  const longTailCancelActionList = getLongTailActionList(cardList);
  const updateTripDetailAction = !isEmpty(addressPendingActionList) && addressPendingActionList.find(item=> item.actionId === 'UpdateTripDetail');
  const closeAddresAction = !isEmpty(addressPendingActionList) && addressPendingActionList.find(item=> item.actionId === 'PopUp');
  const downloadAction = get(clientDetails,'downloadActionList.actionlist',[]);
  const showTrackerCard = shouldShowTrackerCard(cardList);
  const liveTrackingCardData = clientDetails?.liveTrackingCardData;
  const heading =  cardList?.[0]?.heading || '';
  return {
    oldBookingText,
    carBookingInfo,
    bookingId,
    cabDetailInfo,
    driverETAData,
    driverDetailsInfo,
    paymentInfo,
    kioskInfo,
    addressPendingActionList:{
      updateTripDetailAction,
      closeAddresAction,
    },
    longTailCancelActionList,
    downloadAction,
    showTrackerCard,
    isInternationalBooking,
    flightDetails,
    internationalCabData,
    addonServicesData,
    liveTrackingCardData,
    heading,
    cabRescheduleData : cabRescheduleData?.cardsData,
    carBookingInfoReschedule,
    dateTime,
    refundIssuedCardData,
    confirmYourLandingData,
		cabDirectionsInfo
  };
};
