import React from 'react';
import { View } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { Shimmer, PlaceholderContainerWrapper } from '../../../../../Common/ShimmerV2';
import { useTheme } from '../../../../../theme/theme.context';

const ShimmerCard = () => {
  const { psTheme: theme } = useTheme();
  return (
  <PlaceholderContainerWrapper>
    <View>
      <Shimmer height={20} width={300} marginTop={8} marginBottom={12} />
      <Shimmer height={20} width={100} marginBottom={24} />
      <View
        style={{
          marginBottom: 60,
          backgroundColor: theme.color.grey22,
          borderColor: theme.color.grayBg,
          borderWidth: 1,
          borderRadius: 4,
          padding: 16,
        }}
      >
        <View style={AtomicCss.flexRow} marginRight={12}>
          <Shimmer height={24} width={24} marginRight={12} style={{ borderRadius: 4 }} />
          <View>
            <Shimmer height={12} width={119} marginBottom={14} />
            <Shimmer height={12} width={227} marginBottom={11} />
          </View>
        </View>
      </View>
      <View style={{ marginHorizontal: -10 }}>
        <Shimmer height={44} width={'100%'} marginRight={12} style={{ borderRadius: 4 }} />
      </View>
    </View>
  </PlaceholderContainerWrapper>
);};

export default ShimmerCard;
