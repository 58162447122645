import { StyleSheet } from 'react-native';

export default (theme:any, fonts:any) => StyleSheet.create({
    refundWrapper: {
        backgroundColor: theme.color.grayBg,
        margin: 14,
        padding: 12,
        borderRadius: 4,
        marginBottom: 14,
    },
    textStyle: {
        color: theme.color.black,
        fontSize: 12,
    },
});
