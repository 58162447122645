import { trackOmniture } from '@mmt/legacy-commons/Common/utils/OmnitureTrackerUtils';
import isEmpty from 'lodash/isEmpty';

export const EVENT_NAME_ACTION_IDS_MAPPING: Record<string, string> = {
  CANCEL_FLT_FULL: 'Cancellation_Full_Click',
  CANCEL_FLT_PARTIAL: 'Cancellation_Partial_Click',
  CANCEL_CHRGS: 'Cancellation_Policy_Click',
  CHANGE_DATE: 'Date_Change_Journey_Click',
  ACT_RES_FLIGHT: 'Date_Change_Journey_Click',
  ACT_RES_CHARGES: 'Date_Change_Policy_Click',
  NAME_CHANGE: 'Special_Claim_Name_Change_Click',
  FLT_CANCELLED_WITH_AIRLINE: 'Special_Claim_TDCWA_Click',
  FLT_CANCELLED: 'Special_Claim_FNO_Click',
  MEDICAL_EMERGENCY: 'Special_Claim_MEDICAL_WAIVER_DEATH_WAIVER_Click',
  FLT_RESCHEDULED: 'Special_Claim_FDOP_Click',
  ADD_MEAL: 'Cross_Sell_Meal_Click',
  ADD_SEAT: 'Cross_Sell_Seat_Click',
  ADD_BAGGAGE: 'Cross_Sell_Baggage_Click',
  TRACK_ISSUE: 'Track_Issues_click',
  TRACK_ISSUE_REFUND: 'Track_refund_click',
  PARTIAL_CANCELLATION_SEGGRP: 'PARTIAL_CANCELLATION_SEGGRP',
  DOWNLOAD_INVOICE: 'Download_CTA_Click',
  BOOKING_INVOICE: 'Download_invoice_click',
  SPECIAL_CLAIM: 'Special_claim_Click',
  SPECIAL_CLAIM_TEXT: 'SPECIAL_CLAIM_TEXT',
  EXPECTING_REFUND: 'EXPECTING_REFUND',
  BACK: 'BACK',
  OPEN_VISA_TNC_PAGE: 'OPEN_VISA_TNC_PAGE_click',
  OPEN_VISA_CLAIM_REFUND: 'OPEN_VISA_CLAIM_REFUND',
  OPEN_REFUND_TRACKER: 'OPEN_REFUND_TRACKER',
  SEARCH_MORE_FLIGHT: 'Price_lock_click',
  CLOSE_BOTTOMSHEET: 'CLOSE_BOTTOMSHEET',
  TRAVEL_INSURANCE_BUY: 'TRAVEL_INSURANCE_BUY',
  TRACK_REFUND: 'TRACK_REFUND',
  WEB_CHECKIN: 'One_Nudge_Click',
  TRANSFER_REFUND: 'transfer_refund_click',
  VIEW_REFUND_DELAYED: 'how_to_check_your_refund_click',
  VIEW_REFUND_BREAKUP: 'view_refund_breakup_click',
  VPG_RESUBMIT_CLAIM: 'VPG_RESUBMIT_CLAIM_click',
  VPG_CLAIM_REFUND: 'VPG_CLAIM_REFUND__click',
  VISA_PROTECTION_KNOWLEDGE_POPUP: 'VISA_PROTECTION_KNOWLEDGE_POPUP__click',
  VIEW_CAB_DETAILS: 'VIEW_CAB_DETAILS_click',
  UPDATE_CAB_DETAILS: 'UPDATE_CAB_DETAILS_click',
  VIEW_VISA_DETAILS: 'VIEW_VISA_DETAILS_click',
};

export interface TrackingOmnitureLoadData {
  pageName: string;
  entityId: string;
  entityType: string;
  tripType: string;
  itinerary: string;
  departureInfo: string;
  uuid: string;
}
type TrackingOmnitureData = Record<string, any>;
interface TrackingOmniture {
  onLoad: (data: TrackingOmnitureLoadData) => void;
  onClick: (clickEventIgnore: string, extraInfoIgnore?: string) => void;
  onError: (errorDescIgnore: string) => void;
}


export const fligtNewTrackingOmniture = (): TrackingOmniture => {
  let dataSet: TrackingOmnitureData = {
    pageName: 'mob:customer support:all',
    m_v15: 'mob:customer support:all',
  };

  const onLoad = (data: TrackingOmnitureLoadData) => {
    try {
      const {entityId, entityType, tripType, itinerary, departureInfo, uuid, pageName } = data;
      const params: TrackingOmnitureData = {};
      params.pageName = isEmpty(pageName) ? 'mob:customer support:all' : pageName;
      params.m_v15 = isEmpty(pageName) ? 'mob:customer support:all' : pageName;
      if (!isEmpty(entityId)) {
        params.m_v16 = entityId;
      }
      if (!isEmpty(entityType)) {
        params.m_v60 = entityType;
      }
      if (!isEmpty(tripType)) {
        params.m_v9 = tripType;
      }
      if (!isEmpty(itinerary)) {
        params.m_v3 = itinerary;
      }
      if (!isEmpty(departureInfo)) {
        params.m_v7 = departureInfo;
      }
      if (!isEmpty(uuid)) {
        params.m_v54 = uuid;
      }
      dataSet = { ...dataSet, ...params};
      trackOmniture(dataSet?.pageName, dataSet);
    } catch (err) {
      console.log('Error: ', err);
    }
  };

  const onClick = (eventName: string, extraInfo?: string) => {
    try {
      const clickEventDataSet = {...dataSet};
      clickEventDataSet.m_c54 = eventName;
      if (!isEmpty(extraInfo)) {
        clickEventDataSet.m_v13 = extraInfo;
      }
      trackOmniture(clickEventDataSet?.pageName, clickEventDataSet);
    } catch (err) {
      console.log('Error: ', err);
    }
  };

  const onError = (error: string) => {
    try {
      const errorEventDataSet = {...dataSet};
      if (!isEmpty(error)) {
        errorEventDataSet.m_c22 = error;
      }
      trackOmniture(errorEventDataSet?.pageName, errorEventDataSet);
    } catch (err) {
      console.log('Error: ', err);
    }
  };

  return {
    onLoad,
    onClick,
    onError,
  };
};
