import {colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from '@mmt/legacy-commons/Common/Components/Card/getPlatformElevation';


const styles = {
  pageWrapper: {
    flex: 1
  },
  whiteBg: {
    backgroundColor: colors.white
  },
  footerBtnWrapper: {
    paddingTop: 5,
    paddingBottom: 12,
    paddingHorizontal: 12,
    position: 'absolute',
    bottom: 0,
    width: '100%'

  },
  pageScroll: {
    flex: 1
  },
  refundCardWrap: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  refundCardDetails: {
    flex: 1,
    flexWrap: 'wrap'
  },
  RefundTopDtls: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
    marginBottom: 5
  },
  Radio: {
    width: 18,
    height: 18,
    backgroundColor: '#fff',
    borderRadius: 20,
    borderWidth: 1,
    borderColor: '#008cff',
    marginRight: 20,
    marginTop: 5
  },
  RadioInside: {
    width: 12,
    height: 12,
    backgroundColor: '#008cff',
    borderRadius: 20,
    marginLeft: 2,
    marginTop: 2
  },
  refundText: {
    color: '#747474',
    fontFamily: fonts.regular,
    flex: 1,
    lineHeight: 16
  },
  RefundLeftDtls: {
    color: '#4a4a4a',
    fontFamily: fonts.bold,
    fontSize: 14,
    width: '70%'
  },
  RefundPriceTxt: {
    fontFamily: fonts.bold,
    color: '#4a4a4a',
    fontSize: 15

  },
  headingWrapper: {
    // ...getPlatformElevation(2),
    backgroundColor: '#fff',
    paddingHorizontal: 16,
    paddingTop: 5,
    paddingBottom: 15,
    marginHorizontal: -16,
    alignItems: 'center',
    marginBottom: 20
  },
  cardTitle1: {
    color: colors.black,
    fontSize: 16,
    fontFamily: fonts.bold

  },
  dividerWrapper: {
    alignItem: 'center',
    justifyContent: 'center',
    height: 50,
    position: 'relative',
    marginHorizontal: 16


  },
  dividerLine: {
    width: '100%',
    height: 1,
    backgroundColor: '#ccc',
    position: 'absolute'
  },
  orDivider: {
    width: 32,
    height: 32,
    borderRadius: 50,
    borderColor: '#ccc',
    borderWidth: 1,
    backgroundColor: '#f2f2f2',
    justifyContent: 'center',
    alignSelf: 'center'

  },
  orDividerText: {
    fontSize: 12,
    fontFamily: fonts.regular,
    color: colors.defaultTextColor,
    alignSelf: 'center',
    textAlign: 'left'
  },
  rightArrow: {
    width: 14,
    height: 18
  },
  instantRefundWRapper: {
    // borderTopWidth: 1,
    // borderTopColor: '#ddd',
    paddingHorizontal: 16,
    paddingTop: 10,
    paddingBottom: 100  //this is exclusively done to adjust the scroll with the fixed footer cancel button
  },
  callUsTxt: {
    fontFamily: fonts.bold,
    color: '#000',
    fontSize: 16,
    marginBottom: 5
  },
  textInfoDtls: {
    fontSize: 14,
    fontFamily: fonts.regular,
    color: '#000'
  },
  rtArrowPosition: {
    top: 12
  },
  // footerBtnWrapper: {
  //   paddingBottom: 30,
  //   paddingHorizontal: 16
  // },
  BtnStyle: {
    borderRadius: 4,
    padding: 15,
    ...getPlatformElevation(2)

  },
  buttonTxtStyle: {
    fontSize: 14,
    fontFamily: fonts.black,
    textAlign: 'center',
    color: '#fff'
  },
  instantRefundTag: {
    height: 18,
    borderRadius: 8,
    width: 115,
    alignItems: 'left',
    justifyContent: 'left'
  },
  instantRefundTagTxxt: {
    fontFamily: fonts.bold,
    fontSize: 10,
    color: '#fff',
    alignSelf: 'center'
  },
  rupeeIconMdStyle: {
    width: 27,
    height: 25,
    marginRight: 15,
    marginTop: 3
  }
};

export default styles;
