import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import { BAGGAGE_PROTECTION, RIGHT_ARROW } from '../../../../FlightImageConstants';
import styles from './BaggageProtectCardCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';

import { getStaticData } from '../../../../../staticData/staticData';
import { getFont } from '../../../../../PostSalesStyles';

const BaggageProtectCard = ({ baggaeProtectionDetails, baggageDetailsBtnClick }) => {
  const { fsStyle, ...fonts } = getFont(true);
  const { viewProtectionDetailsText } = getStaticData();

  const actionItemView = () => {
    const views = [];
    views.push(
      <TouchableOpacity
        style={styles.redirectBtnWrapper}
        activeOpacity={0.8}
        onPress={() => baggageDetailsBtnClick(baggaeProtectionDetails)}
      >
        <Text style={[styles.linkBtnTxt, fonts.boldFontFamily, fsStyle.font14]}>
          {viewProtectionDetailsText}
        </Text>
        <Image style={styles.rightArrowIcon} source={RIGHT_ARROW} />
      </TouchableOpacity>,
    );
    return views;
  };

  const getHeaderIcon = () => {
    const imageIconStyle = {};
    imageIconStyle.style = styles.headingIconStyle;
    imageIconStyle.icon = BAGGAGE_PROTECTION;
    return imageIconStyle;
  };

  const imageStyle = getHeaderIcon();

  return (
    <View style={[AtomicCss.marginBottom10]}>
      <View style={[styles.infoCardWrapper]}>
        <View style={styles.headingIconWrapper}>
          <Image style={imageStyle.style} source={imageStyle.icon} />
        </View>
        <View style={styles.infoCardContent}>
          <Text
            style={[
              AtomicCss.defaultText,
              fonts.blackFontFamily,
              fsStyle.font14,
              AtomicCss.marginBottom15,
            ]}
          >
            {baggaeProtectionDetails.heading}
          </Text>
          <Text
            style={[
              fonts.regularFontFamily,
              fsStyle.font14,
              AtomicCss.defaultText,
              AtomicCss.lineHeight20,
            ]}
          >
            {baggaeProtectionDetails.description}
          </Text>
        </View>
      </View>
      {actionItemView()}
    </View>
  );
};

BaggageProtectCard.propTypes = {
  baggaeProtectionDetails: PropTypes.object.isRequired,
  baggageDetailsBtnClick: PropTypes.func.isRequired,
};

export default BaggageProtectCard;
