export const ERROR_CODES = {
  INTRACITY_UNAVAILABILITY_ERROR_CODE: 'INTRACITY_UNAVAILABILITY_ERROR_CODE',
};

export const USER_FACING_STRING = {
  CROSS_SELL: {
    HOURLY_RENTALS_REDIRECTION: 'We don’t serve within the city, redirecting to hourly rentals',
    INVALID_SEARCH_DEFAULT_MSG:
      'We currently serve only for intercity routes & airport transfers. Please try a valid search',
  },
};

export const EXPERIMENT_KEYS = {
  HOURLY_RENTAL_VISIBLE: 'HR_Landing',
  HOTEL_MY_TRIPS: 'Hotel_Mytrips_AB',
};

export const DEEPLINK_REDIRECTION = {
  HOURLY_RENTAL_LINK: 'mmyt://react/?page=cabs&deeplink=true&tripType=HR&source=Hotel_My_Trips_Check_Fare'
}