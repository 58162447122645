import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import head from 'lodash/head';
import get from 'lodash/get';
import createStyle from './ReasonRefundDtlsCss';
import CommonStyle from '@mmt/legacy-commons/Styles/AtomicCss';
import RadioSection from '../../../../../Common/RadioSection';
import { getAddSegmentInfo } from '../../pages/SelectPassengers/components/SegmentDetailsView';
import { getFont } from '../../../../../PostSalesStyles';
import BaseButton, { BUTTON_VARIANT } from '../../../../../Common/BaseButton';
import { useTheme } from '../../../../../theme/theme.context';
import { FLIGHT_THROTTLE_OBJECT } from '../../../details/FlightBookingDetailsConstant';

const ReasonRefundDtls = React.memo(
  ({
    activeSectorForRefund,
    onProceed,
    specialClaimDataList,
    closeOverlay,
    cancellationValidation,
    heading,
    subHeading,
    rightButtonText,
    leftButtonText,
  }) => {
    const [activeScCode, setActiveScCode] = useState('');
    useEffect(() => {
      if (specialClaimDataList && specialClaimDataList.length > 0) {
        const defaultScCode = head(specialClaimDataList);
        setActiveScCode(defaultScCode.scCode);
      }
    }, [specialClaimDataList]);
    const { fsStyle, ...fonts } = getFont(true);
    const { psTheme: theme } = useTheme();
    const styles = createStyle(theme);
    const specialClaimHeader = get(cancellationValidation, 'specialClaimHeader', heading);
    const specialClaimMesssage = get(cancellationValidation, 'specialClaimMesssage', subHeading);
    return (
      <View style={[styles.contentTitle, fonts.blackFontFamily, fsStyle.font26]}>
        <Text style={[styles.contentTitle, fonts.blackFontFamily, fsStyle.font26]}>
          {specialClaimHeader}
        </Text>
        <View style={styles.fltStatusCard}>
          <View style={[styles.cityInfoWrapper]}>
            <View style={styles.flightLftDtls}>
              <Text style={[styles.titleTxt, fonts.boldFontFamily, fsStyle.font11]}>
                {specialClaimMesssage}
              </Text>
              {getAddSegmentInfo(activeSectorForRefund)}
            </View>
          </View>
        </View>
        <View style={[CommonStyle.marginBottom20, CommonStyle.paddingLeft5]}>
          <RadioSection
            data={specialClaimDataList}
            setActiveId={setActiveScCode}
            activeId={activeScCode}
            identifier="scCode"
          />
        </View>
        <View
          style={[
            CommonStyle.flexRow,
            CommonStyle.spaceBetween,
            CommonStyle.marginBottom10,
            CommonStyle.alignCenter,
            CommonStyle.paddingLeft5,
          ]}
        >
          <TouchableOpacity
            onPress={() => {
              closeOverlay();
            }}
          >
            <Text style={[styles.actionBtnText, fonts.mediumFontFamily, fsStyle.font14]}>
              {leftButtonText}
            </Text>
          </TouchableOpacity>

          <BaseButton
            variant={BUTTON_VARIANT.CAPSULE}
            text={rightButtonText}
            clickHandler={() => {
              onProceed(activeScCode);
            }}
            buttonStyle={styles.submitBtn}
            textStyle={[CommonStyle.whiteText, CommonStyle.font17, fonts.blackFontFamily]}
            {...FLIGHT_THROTTLE_OBJECT}
          />
        </View>
      </View>
    );
  },
);

export default ReasonRefundDtls;
