import { colors, fonts } from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';

const styles = {
    overlayTopline: {
        width: 40,
        height: 4,
        borderRadius: 4,
        backgroundColor: '#c2c2c2',
        alignSelf: 'center',
    },
    title: {
        color: colors.black,
        fontSize: 20,
        fontFamily: fonts.black,
        marginBottom: 10,
    },
    scheduleListItem: {
        borderRadius: 8,
        borderTopLeftRadius: 4,
        paddingHorizontal: 12,
        paddingVertical: 14,
        borderWidth: 1,
        borderColor: '#ddd',
        ...getPlatformElevation(1),
        backgroundColor: '#fff',
        marginRight: 12,
    },
    activeTab: {
        borderColor: '#008cff',
        backgroundColor: '#f8fcff',
    },
    activeTabTxt: {
        color: '#008cff',
        fontFamily: fonts.bold
    },

    scheduleTxt: {
        color: '#000',
        fontFamily: fonts.regular,
        fontSize: 14,
    },
    listWrapper: {
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    scheduleListTimeWrapper: {
        paddingVertical: 15,
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    scheduleListTimeSection: {
        paddingVertical: 15,
    },
    scheduleListTimeItem: {
        borderRadius: 8,
        borderTopLeftRadius: 4,
        paddingHorizontal: 12,
        paddingVertical: 14,
        borderWidth: 1,
        borderColor: '#ddd',
        ...getPlatformElevation(1),
        backgroundColor: '#fff',
        marginRight: 12,
        marginBottom: 12,
    },
    disabledStyle: {
        opacity: .4
    },
    rowWrapper: {
        borderBottomWidth: 1,
        borderBottomColor: '#ddd',
        marginBottom: 15,
    },
    lastItemStyle: {
        marginBottom: 0,
        borderBottomColor: 'transparent',
        borderBottomWidth: 1,
        paddingBottom: 0,
    },
};

export default styles;