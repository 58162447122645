import React from 'react';
import createStyle from './travelPassCardCss';
import { View, Text, Image } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import { isEmpty } from 'lodash';

interface TravelPassCardProp {
  travelData: {
    heading: string;
    description: string;
    iconUrl: string;
  };
}

const TravelPassCard: React.FC<TravelPassCardProp> = ({ travelData }) => {

  const { heading = '', description = '', iconUrl = '' } = travelData || {};

  if (isEmpty(travelData) || (!heading && !description)) {
    return null;
  }

  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  const { color } = theme;
  const styles = createStyle(fonts, theme);

  return (
    <View style={styles.card}>
      <LinearGradient
        colors={[color.white, color.skyBlue5]}
        start={{ x: 0, y: 1 }}
        end={{ x: 1, y: 0 }}
        style={styles.linearGradient}
      >
        <View style={styles.contentContainer}>
          <View style={styles.textContainer}>
            {!!heading && <Text style={[styles.title, fonts.blackFontFamily]}>{heading}</Text>}
            {!!description && (
              <Text style={[styles.description, fonts.regularFontFamily]}>{description}</Text>
            )}
          </View>
          {!!iconUrl && <Image source={{ uri: iconUrl }} style={styles.icon} />}
        </View>
      </LinearGradient>
    </View>
  );
};

export default TravelPassCard;
