import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity
} from 'react-native';
import React from 'react';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import LinearGradient from 'react-native-linear-gradient';
import HTMLView from 'react-native-htmlview';
import isEmpty from 'lodash/isEmpty';
import { getFont } from '../../../../PostSalesStyles';
import { useTheme } from '../../../../theme/theme.context';
import Button from '../../../../Common/Button';
import {
  HOTEL_DETAILS_BOTTOM_OVERLAY,
} from '../../HotelBookingDetailsConstant';

interface IInsuranceOfferData {
  handleOpenBottomOverlay: (overlayName: string) => void;
  handleClick: (deeplinks: string) => void;
  insuranceOfferCard: IInsuranceOfferCardData;
}
interface IInsuranceOfferCardData {
  header: IHeaderData;
  vendor: IVendorData;
  benefits: Array<IBenefits>;
  benefitsCount?: number;
  moreBenefits: IMoreBefefits;
  insuranceHeader?: string;
  footerText?: string;
  actionList: Array<IActionList>;
}

interface IHeaderData {
  tag?: string;
  title: string;
  subTitle?: string;
  icon?: string;
}

interface IVendorData {
  title: string;
  icon?: string;
}

interface IBenefits {
  title: string;
  icon: string;
}

interface IMoreBefefits {
  text: string;
  isClickable: boolean;
}

interface IActionList {
  actionRank: number;
  actionId: string;
  actionType: string;
  actionLabeltext: string;
  actionFamily: string;
  bookingType: string;
  deeplinkUrl?: string;
}

const getHtmlStyles = (fonts) => {
  return {
    div: {
      ...fonts.font14,
    },
  };
};

const InsuranceOfferCard = (props: IInsuranceOfferData) => {
  const { insuranceOfferCard, handleOpenBottomOverlay, handleClick } = props;
  const { fsStyle, ...fonts } = getFont();
  const htmlStyles = getHtmlStyles(fonts);
  const { psTheme: theme } = useTheme();
  const styles = getStyles(theme);
  const {
    header,
    vendor,
    benefits,
    moreBenefits,
    footerText,
    actionList,
    benefitsCount,
  }: IInsuranceOfferCardData = insuranceOfferCard || {};
  const { tag, title, subTitle, icon } = header || {};
  const { title: vendorTitle, icon: vendorIcon } = vendor || {};
  const { text: moreBenefitsText, isClickable } = moreBenefits || {};
  const visibleBenefits = benefitsCount ? benefits.slice(0, benefitsCount) : benefits;

  const onViewMoreClick = () => {
    handleOpenBottomOverlay(HOTEL_DETAILS_BOTTOM_OVERLAY.INSURANCE_BENEFITS);
  };

  return (
    <View style={[AtomicCss.whiteCardNew, AtomicCss.marginHz12, AtomicCss.borderRadius16]}>
      <View style={styles.insTopWrapper}>
        <View style={AtomicCss.flex1}>
          <View style={[AtomicCss.flexRow, AtomicCss.marginBottom3]}>
            <LinearGradient
              colors={theme.color.insuranceTagGradientColor}
              start={{ x: 0.0, y: 0.0 }}
              end={{ x: 0.0, y: 1.0 }}
              style={[styles.insReqWrapper]}
            >
              {!isEmpty(tag) && <Text style={[fsStyle.font12, fonts.blackFontFamily, AtomicCss.whiteText]}>{tag}</Text>}
            </LinearGradient>
          </View>
          <Text style={[fsStyle.font18, AtomicCss.defaultText, fonts.blackFontFamily]}>
            {title}
          </Text>
          {!isEmpty(subTitle) && <Text style={[fsStyle.font12, AtomicCss.defaultText, fonts.regularFontFamily, AtomicCss.lineHeight18]}>{subTitle}</Text>}
        </View>
        {!isEmpty(icon) && <Image source={{ uri: icon }} style={styles.insTravel} />}
      </View>
      <View style={styles.insOfferSection}>
        {!isEmpty(vendorTitle) && (
          <View
            style={[
              AtomicCss.flexRow,
              AtomicCss.alignCenter,
              AtomicCss.spaceBetween,
              styles.offerTextWrapper,
            ]}
          >
            <Text style={[fsStyle.font12, AtomicCss.defaultText, fonts.boldFontFamily, AtomicCss.flex1]} >
              {vendorTitle}
            </Text>
            {!isEmpty(vendorIcon) && <Image source={{ uri: vendorIcon }} style={styles.tataAigStyle} />}
          </View>
        )}
        {!isEmpty(visibleBenefits) && visibleBenefits.length > 0
          ? visibleBenefits.map((list, index) => {
              const isLastItem = index === visibleBenefits.length - 1;
              return (
                <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom16]} key={index}>
                  <View style={[AtomicCss.flexRow, AtomicCss.flex1]}>
                    <Image source={{ uri: list.icon }} style={styles.offerIcon} />
                    <Text style={[fsStyle.font14, AtomicCss.blackText, fonts.boldFontFamily]}>
                      {list.title}
                    </Text>
                  </View>
                  {isLastItem && moreBenefitsText ? (
                    isClickable ? (
                      <TouchableOpacity onPress={onViewMoreClick}>
                        <Text
                          style={[
                            fsStyle.font12,
                            AtomicCss.blackText,
                            fonts.regularFontFamily,
                            AtomicCss.alignRight,
                          ]}
                        >
                          {moreBenefitsText}
                        </Text>
                      </TouchableOpacity>
                    ) : (
                      <Text
                        style={[
                          fsStyle.font12,
                          AtomicCss.blackText,
                          fonts.regularFontFamily,
                          AtomicCss.alignRight,
                        ]}
                      >
                        {moreBenefitsText}
                      </Text>
                    )
                  ) : null}
                </View>
              );
            })
          : null}
        {!isEmpty(actionList)
          ? actionList.map((item, index) => {
              const { deeplinkUrl = "" } = item;
              return (
                <Button
                  key={item.actionId}
                  btnBg="gradientBtn"
                  btnType="flat"
                  fontSize={fsStyle.font16}
                  btnTxt={item.actionLabeltext}
                  clickHandler={() => handleClick(deeplinkUrl)}
                />
              );
            })
          : null}
        {!isEmpty(footerText) && (
          <View style={[AtomicCss.paddingTop16]}>
            <HTMLView value={`<div>${footerText}</div>`} stylesheet={htmlStyles} />
          </View>
        )}
      </View>
    </View>
  );
};

export default InsuranceOfferCard;

const getStyles = (theme) => {
  return StyleSheet.create({
    insReqWrapper: {
      paddingHorizontal: 8,
      paddingVertical: 2,
      borderRadius: 16,
    },
    overlayTopline: {
      width: 40,
      height: 4,
      borderRadius: 4,
      backgroundColor: theme.color.disabledButton,
      alignSelf: 'center',
    },
    insTopWrapper: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: theme.color.lighterBlue,
      borderTopLeftRadius: 16,
      borderTopRightRadius: 16,
      margin: 1,
      paddingHorizontal: 16,
      paddingTop: 16,
      paddingBottom: 12,
    },
    insTravel: {
      width: 44,
      height: 44,
      marginLeft: 12,
    },
    insOfferSection: {
      paddingHorizontal: 12,
      paddingTop: 12,
      paddingBottom: 16,
    },
    offerTextWrapper: {
      paddingVertical: 8,
      paddingHorizontal: 12,
      backgroundColor: theme.color.smokeWhite,
      borderRadius: 8,
      marginBottom: 12,
    },
    tataAigStyle: {
      width: 22,
      height: 22,
      marginLeft: 12,
    },
    offerIcon: {
      width: 16,
      height: 16,
      marginRight: 8,
    },
  });
}
