import myTripsNavigation from '../../../navigation/navigation';

function getTripIdeasCardData(response) {
    const { deeplink, title, text, titleColors, images } = response;
    const openDetails = () => {
        myTripsNavigation.openTripIdeasDetail(deeplink);
    };
    return {
        title,
        titleColors,
        text,
        openDetails, 
        images
    };
}

export default getTripIdeasCardData;
