import React from 'react';
import PropTypes from 'prop-types';
import {isEmpty} from 'lodash';
import {Image, ScrollView, Text, TouchableWithoutFeedback, View} from 'react-native';
import styles from '../../styles/MetroMamiCss';
import StickyHeader from '../../../hotel/details/components/StickyHeader';
import FullWidthButton from '../../../hotel/details/ActionButton/FullWidthButton';
import BasePage from '../../../Common/PostSalesBasePage';
import ConfirmationOverlay from '../../../Common/ConfirmationOverlay';
import Breadcrumb from '../../components/Breadcrumb';
import BlueRectangleButton from '../../../Common/BlueRectangleButton';
import {addRupeeSign} from '../../../Common/commonUtil';
import {REFUND_ICON, HEADER_ICON} from '../../MetroImageConstant';

const REFUND_MODE_STEPS = [
  'SELECT',
  'REVIEW',
  'REFUND MODE',
  'CONFIRM'
];

class MetroReviewRefundMode extends BasePage {

  constructor(props) {
    super(props, "metroCancelReviewRefundMode");
    this.radio = this.radio.bind(this);
    this.refundModes = props.cancelRefundMode.refundModeList;
    this.message = props.cancelRefundMode.message;
    this.state = {
      radiobtn: this.refundModes[0].refundModeReason,
      activeStep: 2,
      overlay: false
    };
    this.trackingPageName = 'RefundMode';
  }

  componentDidMount() {
    // this.trackLoadEvent();
  }

  onHardBackPress = () => {
    if (this.state.overlay) {
      this.confirmationPopup();
      return true;
    }
    return false;
  };

  radio(active) {
    this.setState({
      radiobtn: active
    });
  }

  render() {
    return (

      <View style={[styles.whiteContainer, styles.flex1, styles.pageWrapper]}>
        {this.renderHeader()}
        {this.renderContent()}
        {this.renderFooter()}
        {this.state.overlay ? this.renderConfirmationPopup() : null}
      </View>

    );
  }

  renderHeader = () => (
    <StickyHeader
      iconSrc={HEADER_ICON}
      title={this.props.headerTitle}
      headerColor={['#ffffff', '#ffffff']}
      titleStyle={[styles.headerTitle]}
      subTitle={this.props.headerSubTitle}
      subTitleStyle={[styles.DefaultText, styles.font12]}
      onBackPress={super.onBackIconPress}
    />
  );

  renderContent = () => (
    <ScrollView>
      <Breadcrumb activeStep={this.state.activeStep} data={REFUND_MODE_STEPS}/>
      <View style={[styles.MamiCard]}>
        <View style={styles.CardContent}>
          <View>
            <Image style={styles.CardIcon} source={REFUND_ICON}/>
          </View>
          <View style={styles.ContentPortion}>
            <Text style={[styles.blackFont, styles.BlackText]}>SELECT A MODE TO RECEIVE
              REFUND
            </Text>
          </View>
        </View>
        {!isEmpty(this.message) &&
        <View style={styles.refundModeMessageCard}>
          <Text style={[styles.BlackText]}>{this.props.cancellationRefundMode.message}
          </Text>
        </View>}
        <View style={[styles.ActionBtn, styles.mar15Top]}>
          {this.addRefundModeOptions()}
        </View>

      </View>
    </ScrollView>
  );

  renderFooter = () => (
    <View>
    <View style={styles.footerBtnWrapper} />
    <View style={styles.buttonStyle}>
    <BlueRectangleButton
      buttonText="CONFIRM CANCELLATION"
      clickHandler={this.confirmationPopup}
    />
    </View>
    </View>
  );

  renderConfirmationPopup() {
    return (
      <ConfirmationOverlay
        content={this.props.getCancellationConfirmationText()}
        leftBtnClick={this.confirmationPopup}
        rightBtnClick={() => this.props.overlayConfirmCancelClick(this.state.radiobtn, this.props.isFullCancel, this.props.ticketCount, this.props.refundAmount)}
        outsideClick={this.confirmationPopup}
      />
    );
  }

  confirmationPopup = () => {
    this.setState({overlay: !this.state.overlay});
  };

  addRefundModeOptions() {
    const views = [];
    this.refundModes.forEach((refundModeObj) => {
      views.push(
        <View key={refundModeObj.refundModeReason}>
        <TouchableWithoutFeedback onPress={() => {
        this.radio(refundModeObj.refundModeReason);
      }}
      >
        <View style={styles.refundCardOuter}>
          <View style={[styles.flexRow]}>
            <View style={styles.MimaRadio}>
              <View
                style={[this.state.radiobtn === refundModeObj.refundModeReason ? styles.MetroRadioInside : '']}
              />
            </View>
            <View style={{width: '90%'}}>
              <View style={styles.refundCardWrap}>
                <View style={styles.refundCardLeft}>
                  <Text
                    style={[styles.font14, styles.boldFont, styles.BlackText]}
                  >{refundModeObj.title}
                  </Text>
                  {this.addSubText(refundModeObj.subText)}
                </View>
                <Text
                  style={[styles.BlackText,
                    styles.boldFont, styles.font16, styles.AlignRight]}
                > {addRupeeSign(this.props.refundAmount)}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </TouchableWithoutFeedback>
        </View>
      );
    });
    return views;
  }

  addSubText(subTitleList) {
    const views = [];
    subTitleList.forEach((subtitle) => {
      views.push(<View key={subtitle}><Text style={[styles.font13]}>{subtitle}</Text></View>);
    });
    return views;
  }
}

MetroReviewRefundMode.propTypes = {
  refundAmount: PropTypes.number.isRequired,
  headerTitle: PropTypes.string.isRequired,
  cancelRefundMode: PropTypes.object.isRequired,
  overlayConfirmCancelClick: PropTypes.func.isRequired,
  getCancellationConfirmationText: PropTypes.func.isRequired
};

export default MetroReviewRefundMode;

