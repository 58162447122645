// @flow
import React from 'react';
import { getHotelSummaryData } from './hotelCardAdapter';
import { Platform } from 'react-native';

import {
  ActionsContent,
  DescriptionSection,
  HotelCityInfoText,
  MainContent,
  NeedHelpStrip,
  NewStatusText,
  RightArrow,
  StaticIcon,
  SummaryContainer,
  TitleText,
} from '../cardElements';
import isEmpty from 'lodash/isEmpty';
import PendingPaymentTag from '../../../../Common/PendingPaymentTag/PendingPaymentTag';
import PendingPaymentMessage from '../../../../Common/PendingPaymentMessage/PendingPaymentMessage';
import { isNullOrEmpty } from '../../../../Common/commonUtil';

function HotelSummaryCard({ response, msrTicket }) {
  const cardData = getHotelSummaryData(response, msrTicket);
  const { needHelpData } = cardData;
  return (
    <SummaryContainer bookingId={cardData.bookingID}>
      <MainContent onPress={cardData.openDetails}>
        <StaticIcon icon={cardData.iconUrl} iconStyle={{ height: 24, width: 24 }} />
        <DescriptionSection>
          <TitleText text={cardData.hotel} />
          {!cardData.failedBooking && cardData.hotelCityInfo && (
            <HotelCityInfoText text={cardData.hotelCityInfo} />
          )}
          { Platform.OS === "web" && cardData.status === "PENDING"  && !isNullOrEmpty(cardData.pendingPaymentChargeDate) &&
            <>
            <PendingPaymentTag tagTextStart={cardData.tagStartText} tagTextEnd={cardData.tagEndText} paymentChargeDate={cardData.pendingPaymentChargeDate} />
            <PendingPaymentMessage subTitle={cardData.paymentDueText} />
          </>
          }
          <NewStatusText status={cardData.status} text={cardData.dateTime} style={cardData.style} />
        </DescriptionSection>
        <RightArrow />
      </MainContent>
      {!isEmpty(needHelpData) &&  needHelpData.map((needHelp)=><NeedHelpStrip {...needHelp}/>)}
      <ActionsContent actions={cardData.actions} />
    </SummaryContainer>
  );
}

export default HotelSummaryCard;
