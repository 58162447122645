import { StyleSheet } from "react-native"

export const createStyles = (theme,fonts) =>{
    return StyleSheet.create({
        headingSection: {
            paddingHorizontal: 24,
            paddingVertical: 20,
            borderBottomWidth: 1,
            borderBottomColor: theme.color.grayBg
        },
        headingText: {
            color: theme.color.black22,
            fontFamily: fonts.black,
            ...fonts.fsStyle.font24,
        },
        tgIconStyle: {
            width: 46,
            height: 30,
            marginRight: 10,
        },
        detailsSection: {
            paddingHorizontal: 24,
            paddingVertical: 20,
        },
        tandCText:{
          color: theme.color.azure,
          marginTop: 10
        },
        iconWrapper: {
            width: 24,
            height: 24,
            marginRight: 10,
        },
        iconStyles: {
            width: 24,
            height: 24,
        },
        rowSection: {
            marginBottom: 35,
            flexDirection: 'row'
        },
        refundValue:{
          top: -2
        },
        refundInfo:{
            borderWidth:1,
            borderColor: theme.color.lightGray,
            paddingHorizontal:10,
            paddingVertical:4,
            borderRadius:8,
            marginTop:10,
        },
        refundPriceText: {
            fontFamily: fonts.black,
            color: theme.color.lightGreen4,
            ...fonts.fsStyle.font14,
        },
        crossIconStyle:{
            width:24,
            height:24,
        },
    })
}


export const createHtmlStyle = ( theme, fonts) => {
    return StyleSheet.create({
      q: {
        /* fix fo Html Fonts Not working without adding blank tag */
      },
      span:{
        ...fonts.boldFontFamily,
        color: theme.color.lightGreen4,
      },
      p: {
        color: theme.color.black,
        ...fonts.fsStyle.font14,
      },
      b:{
        color: theme.color.black,
        ...fonts.boldFontFamily,
        fontWeight:'bold'
      },
      small:{
        ...fonts.fsStyle.font12
      },
      large:{
        ...fonts.fsStyle.font20
      },
    })
  }