import React, { useCallback, useMemo, useRef, useEffect, useReducer, useState } from 'react';
import { Image, Platform, SectionList, Text, View, TouchableOpacity, NativeModules, ScrollView } from 'react-native';
import { isEmpty } from 'lodash';
import { backIcon } from '@mmt/legacy-commons/Helpers/displayHelper';
import { fetchBookings, fetchLostIDBooking, fetchTickets, fetchHolidayPlannings } from './data/api';
import {
  filterData,
  prepareBookingsData,
  SortTypes,
  modifiedBookingListResponse,
  showEmptyScreenActiveTabV4,
  getStatusWiseMessage,
  swapBookingStateNames,
  prepareTripIdeasDataV4,
  preparePlanningData,
  getMyTripsCardsInfo
} from './data/tripListManager';
import ViewPagerWithTabs from './components/viewPagerWithTabs';
import SimpleHeader from '@mmt/legacy-commons/Common/Components/Header/SimpleHeader';
import { resetSummaryData } from '../data/cache';
import myTripsNavigation from './navigation/navigation';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles.js';
import ProgressView from '../Common/ProgressView/ProgressView';
import refreshIcon from '@mmt/legacy-assets/src/postSales/summary/reloadTrips.webp';
import arrowIcon from '@mmt/legacy-assets/src/redirect_blue.webp';
import Button from '../Common/Button';
import TripSummaryOmnitureTracker from './tracking/TripSummaryOmnitureTracker';
import { getStaticData, getCurrentLangStaticData } from '../staticData/staticData';
import {
  BookingState,
  Lobs,
  BookingStates,
  ApiRequestTypes,
  BookingStatusCode,
  PAGE_BOOKINGS_STATUS,
  MyTripsCards,
  MyTripsCardsVisibility,
  MAX_SWITCHER_BOOKING_COUNT_TO_SHOW,
  LOAD_MORE_THRESHOLD
} from './summaryConstants';
import styles from './summaryCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {
  POST_SALES_BOOKING_SUMMARY_PAGE,
  POST_SALES_WEB_BOOKING_SUMMARY_PAGE,
} from '@mmt/legacy-commons/adsConfig/adsConfigConstants';
import { getAdTechCard, isGCCBooking } from '../Common/commonUtil';
import LinearGradient from 'react-native-linear-gradient';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import {
  NO_TRIPS_ICON,
  NO_TRIPS_ICON_LARGE,
} from '../flights/FlightImageConstants';
import SkeltonLoaderComponent from './components/cards/skeltonLoaderComponent';
import SnackBarCard from '../Common/snackBarCard';
import { summaryReducer, INITIAL_STATE } from './bookingSummaryReducer';
import { setRecentBkgInfo } from './components/cards/lostID/lostIdCardAdapter';
import { getFont, ps_colors } from '../PostSalesStyles';
import { MyTripsLangSwitchCard, renderEmptyRecentFailedView, RenderLobCard } from './';
import { getTranslatedTextFor, isAddBookingFlowEnabled, isScrollViewCloseToBottom, isTripIdeaCardEnabled } from '../utils/PostSaleUtil';
import { TRIP_IDEAS_SUMMARY_URL } from '../utils/NetworkUtils';
import useApi from '../Common/useApi';
import TripIdeasCard from './components/cards/TripIdeas';
import { isEnglishLangSelected } from '@mmt/legacy-commons/AppState/AppUserConfig';
import AddBookingCard from './components/cards/AddBooking';
import BaseButton, { BUTTON_VARIANT } from '../Common/BaseButton';
import MybizSwitcher from './components/MyBizSwitcher';
import { getUserProfileType } from '@mmt/legacy-commons/AppState/AppUserConfig';
import { PROFILE_TYPE } from '@mmt/legacy-commons/Common/constants/AppConstants';
import { BOOKING_SUMMARY_URL_V4 } from '../utils/NetworkUtils';
import MybizToolTip from './components/MyBizSwitcher/MybizToolTip';
import AddBookingBottomOverlay from './components/AddBooking/AddBookingBottomOverlay';
import addBookingTrackingHelper, { ADD_BOOKING_TRACKING_EVENTS } from './components/AddBooking/tracking'
import {shouldLoadPlanningData} from './utils'
import { LOBNAMES } from '../PostSalesConstant';
import GlobalMessageBox from './BookingSummaryV5/components/cards/GlobalMessageBox';

const ViewState = {
  LOADING: 'loading',
  LOADED: 'loaded',
  EMPTY: 'empty',
  ERROR: 'error',
};

function MyTripsHeader({ onRefresh, hideBack, headerData }) {
  const { uuid = null, handleAddBookingOverlay, plusIcon, showBookingOverlay } = headerData || {};
  const [bookingCount, setBookingCount] = useState(0);
  const [inProgress, response, api] = useApi(`${BOOKING_SUMMARY_URL_V4}?status=UPCOMING&pageNo=0`);
  const fonts = getFont(true);
  const Header = SimpleHeader ;
  const _backIcon = Platform.OS === 'ios' ? (hideBack ? null : backIcon) : backIcon;
  const staticData = getCurrentLangStaticData();
  const userProfileType = getUserProfileType();

  const fetchMyBizBookings = async () => {
    if (userProfileType !== PROFILE_TYPE.PERSONAL) return;
    const { UserSessionModule } = NativeModules;
    const b2bAuth = await UserSessionModule.getAuthForProfile(PROFILE_TYPE.CORPORATE);
    if (b2bAuth) {
      api.httpGet({ uniqueId: 'MY_BIZ_SWITCHER' }, { headers: { 'mmt-auth': b2bAuth } });
    }
  };

  useEffect(() => {
    if (Platform.OS !== 'web') {
      fetchMyBizBookings();
    }
  }, []);

  useEffect(() => {
    if (response && !isEmpty(response.newBookingDetails) && !isEmpty(response.newBookingDetails[0].bookingDetails)) {
      setBookingCount(response.newBookingDetails[0].bookingDetails.length);
    }
  }, [response]);


  const bookingCountText = bookingCount > 10 ? `${MAX_SWITCHER_BOOKING_COUNT_TO_SHOW}+` : bookingCount;
  const { myBizNotificationText, myBizBookingNotificationText } = getStaticData();

  const tootlTipText =
    bookingCount > 10
      ? myBizNotificationText.replace('MAX_BOOKING_COUNT_TO_SHOW', MAX_SWITCHER_BOOKING_COUNT_TO_SHOW)
      : myBizBookingNotificationText.replace('BOOKING_COUNT', bookingCount);

  const addBookingOnPress = () => {
    handleAddBookingOverlay(true, "top")
  }

  const renderChildLeftToIcon = () => {
    return (
      <TouchableOpacity onPress={addBookingOnPress}>
        <Image source={{ uri: plusIcon }} style={styles.plusIcon} />
      </TouchableOpacity>
    )
  };

  const showAddBookingIcon = isAddBookingFlowEnabled() && uuid && plusIcon;
  return (
    <>
      <Header
        icon={_backIcon}
        title={staticData.myTripsText}
        rightIcon={refreshIcon}
        textFont={fonts.bold}
        rightIconPress={() => {
          onRefresh();
          TripSummaryOmnitureTracker.trackNormalClickEvent(
            'mob:customer support:all:mytrips',
            'MyTrips_RefreshTrips',
          );
        }}
        iconPress={() => {
          myTripsNavigation.exitApp();
        }}
        rightChild={bookingCount > 0 ? <MybizSwitcher bookingCountText={bookingCountText} /> : null}
        childLeftToRightIcon={showAddBookingIcon && renderChildLeftToIcon()}
      />
      {!showBookingOverlay && bookingCount > 0 && <MybizToolTip tootlTipText={tootlTipText} />}
    </>
  );
}

const ALL_BOOKINGS = 'All Bookings';
const SORT_OPTIONS = Object.values(SortTypes);

function BookingList({
  bookings,
  loadMore,
  showSortOptions = false,
  showLoadMoreLoader = false,
  showError = false,
  summaryResponse,
  statusWiseMessage,
  recentLostIdDurationInDays,
  recentBookingDisplayLimit = 2,
  pageName,
  footerSection,
  bookingIdtoTicketDetailmap,
  bottomCardSection,
  tripIdeasData,
  hasNextPage,
  refreshPlanningData,
  hasNonStickyBottomCards = false,
  headerSection,
  stickyTopCard
}) {
  const { fsStyle, ...fonts } = getFont(true);
  const selectedLob = ALL_BOOKINGS;
  const selectedSort = SORT_OPTIONS[0];
  const [modifiedBookings, setModifiedBookings] = useState([]);
  const calledBookingIDs = [];
  const prevSelectedSortVal = React.useRef(selectedSort);
  const [filteredBookings, setFilteredBookings] = React.useState([]);
  const [displayOlderBookings, setDisplayOlderBookings] = useState(false);
  const { showFailedBkgText, hideAllText, noRecentFailedBkgText } = getCurrentLangStaticData();
  const [showRecentBookingCount, setShowRecentBookingCount] = useState(recentBookingDisplayLimit);
  const [visibleItemMap, setVisibleItemMap] = useState({});
  const [tripIdeasSeen, setTripIdeasSeen] = useState(false);

  const errorMessage = summaryResponse ? summaryResponse.errorMessage : null;

  const staticData = getCurrentLangStaticData();
  const { bookingSummaryText, refreshText, moreUnsuccessfulText } = staticData;

  useEffect(() => {
    setModifiedBookings([...bookings]);
  }, [bookings]);

  React.useEffect(() => {
    const selectedLobId = Lobs[selectedLob];
    const sortChanged = prevSelectedSortVal.current !== selectedSort;
    if (!selectedLobId && !sortChanged) {
      setFilteredBookings(modifiedBookings);
      return;
    }
    prevSelectedSortVal.current = selectedSort;
    setFilteredBookings(filterData(modifiedBookings, selectedLobId, selectedSort));
  }, [modifiedBookings, selectedLob, selectedSort]);

  const onCheckViewableItems = ({ viewableItems, changed }) => {
    viewableItems &&
      viewableItems.forEach(({ key, section }) => {
        if (
          section &&
          section.pageTitle &&
          section.pageTitle.includes(BookingStates.UNSUCCESSFUL)
        ) {
          setVisibleItemMap((preMap) => ({
            ...preMap,
            [key]: true,
          }));
        }
      });
    const activeBookingCandidates = [
      BookingState.UPCOMING.toUpperCase(),
      BookingState.ONTRIP.toUpperCase(),
    ];
    const bIDs = changed.map((item) => {
      if (!item) return;
      const { hotelDetails, status } = item.item || {};
      if (!hotelDetails || !hotelDetails[0] || !status) return;
      return (
        status &&
        activeBookingCandidates.includes(status.toUpperCase()) &&
        hotelDetails[0].contactlessCheckin &&
        item.key
      );
    });
    const newBIDs = bIDs.filter(
      (bID) =>
        // return only those bookingID which are not present in calledBIDs
        bID && !calledBookingIDs.includes(bID),
    );
    if (newBIDs.length > 0) {
      // setToCallBookingIDs(newBIDs);
    }
  };

  const checkTripIdeasSeen = (pageName) => {
    if (
      tripIdeasData &&
      tripIdeasData.success &&
      pageName === BookingStates.UPCOMING &&
      !tripIdeasSeen &&
      !hasNextPage
    ) {
      TripSummaryOmnitureTracker.trackTripIdeasSeenEvent(tripIdeasData);
      setTripIdeasSeen(true);
    }
  };

  const renderListFooter = () => {
    if (showLoadMoreLoader) {
      return (
        <SkeltonLoaderComponent
          cardCount={3}
          gradientColor={[colors.skeltonGradientStartColor, colors.skeltonGradientEndColor]}
        />
      );
    }
    return <View style={{ height: showSortOptions ? 72 : 0 }} />;
  };

  if (showError) {
    return (
      <View style={[styles.errorScreenContainer]}>
        <EmptyDataScreen
          loadData={() => loadMore(false)}
          mainText={
            !isEmpty(errorMessage)
              ? errorMessage.heading
              : bookingSummaryText.additionalText.noBookingErrorText
          }
          buttonText={!isEmpty(errorMessage) ? errorMessage.ctaText : refreshText}
        />
      </View>
    );
  }

  const renderOlderBookingCount = ({ title }) => {
    if (title && title.includes('OLDER')) {
      return (
        <View style={styles.olderWrapper}>
          <Text
            style={[
              fsStyle.font13,
              fonts.boldFontFamily,
              AtomicCss.blackText,
              AtomicCss.marginBottom8,
            ]}
          >
            {title.replace('OLDER', getTranslatedTextFor('OLDER'))}
          </Text>
          <TouchableOpacity
            activeOpacity={0.8}
            style={[AtomicCss.flexRow, AtomicCss.alignCenter]}
            onPress={() => {
              !displayOlderBookings &&
                TripSummaryOmnitureTracker.trackNormalClickEvent(
                  'mob:customer support:all:mytrips',
                  'older_unsuccessful_showall_clicked',
                );
              setDisplayOlderBookings(!displayOlderBookings);
            }}
          >
            <Text style={[fsStyle.font14, fonts.boldFontFamily, AtomicCss.azure]}>
              {!displayOlderBookings ? showFailedBkgText : hideAllText}
            </Text>
            <Image
              source={arrowIcon}
              style={[styles.downArrow, displayOlderBookings ? styles.activeStyle : null]}
            />
          </TouchableOpacity>
        </View>
      );
    }
    return null;
  };

  const hideTitleWhen = ({ pageTitle, title, data }) => {
    const lob_name = data?.[0]?.lobName;
    if (pageTitle === BookingStates.UNSUCCESSFUL) {
      if (title.includes('OLDER') || title.includes('RECENT')) {
        return true;
      } else if (!displayOlderBookings) {
        return true;
      }
    } else if (lob_name?.includes("Self Drive") || lob_name?.includes("Metro")) {
      return true;
    }
    return false;
  };

  const renderSectionListItem = ({ item, index, section }) => {
    if (section.pageTitle === BookingStates.UNSUCCESSFUL && section.title.includes('RECENT')) {
      return (
        <>
          {index < showRecentBookingCount ? (
            <RenderLobCard
              item={item}
              displayOlderBookings={displayOlderBookings}
              section={section}
              bookingIdtoTicketDetailmap={bookingIdtoTicketDetailmap}
              visibleItemMap={visibleItemMap}
              onRefreshPlannings={refreshPlanningData}
            />
          ) : null}
          {section.data.length > showRecentBookingCount &&
            index === showRecentBookingCount &&
            moreUnsuccessfulText && (
              <TouchableOpacity
                style={styles.recentMore}
                onPress={() => {
                  TripSummaryOmnitureTracker.trackNormalClickEvent(
                    'mob:customer support:all:mytrips',
                    'recent_unsuccessful_viewmore_clicked',
                  );
                  setShowRecentBookingCount(section.data.length);
                }}
              >
                <Text style={[fsStyle.font14, fonts.boldFontFamily, AtomicCss.azure]}>
                  {moreUnsuccessfulText
                    .replace('{remainingCount}', section.data.length - showRecentBookingCount)
                    .replace('{noOfDays}', recentLostIdDurationInDays)}
                </Text>
              </TouchableOpacity>
            )}
        </>
      );
    }
    return (
      <RenderLobCard
        item={item}
        displayOlderBookings={displayOlderBookings}
        section={section}
        bookingIdtoTicketDetailmap={bookingIdtoTicketDetailmap}
        visibleItemMap={visibleItemMap}
        onRefreshPlannings={refreshPlanningData}
      />
    );
  };

  const handleScroll = (event) => {
    if (isScrollViewCloseToBottom(event.nativeEvent, LOAD_MORE_THRESHOLD)) {
      checkTripIdeasSeen(pageName);
      loadMore(false);
    }
  };

  return (
    <ScrollView scrollEnabled={Platform.OS !== 'web'} style={AtomicCss.flex1} onScroll={handleScroll}>
      {stickyTopCard}
      {pageName === BookingStates.FAILED && filteredBookings.length === 0 ? (
        <>
          {showLoadMoreLoader ? (
            renderListFooter()
          ) : (
            <View style={AtomicCss.flex1}>{footerSection}</View>
          )}
          {bottomCardSection}
        </>
      ) : <SectionList
        scrollEnabled={Platform.OS === 'web'}
        sections={filteredBookings}
        keyExtractor={(item) => item.bookingID || item.bookingId}
        renderItem={({ item, index, section }) => renderSectionListItem({ item, index, section })}
        onEndReachedThreshold={0.9}
        onViewableItemsChanged={onCheckViewableItems}
        viewabilityConfig={{
          itemVisiblePercentThreshold: 50, // if 50% of the item is visible, execute onViweableItemsChanged prop
        }}
        ListHeaderComponent={headerSection}
        contentContainerStyle={
          !hasNonStickyBottomCards && !bookings.length > 0
            ? [AtomicCss.justifyCenter, AtomicCss.flexGrow1]
            : ''
        }
        ListFooterComponent={() => (
          <View style={AtomicCss.flex1}>
            {!showLoadMoreLoader && !showError && filteredBookings.length === 0 ? <View style={AtomicCss.flex1}>{footerSection}</View> : <></>}
            {showLoadMoreLoader ? renderListFooter() : <></>}
            {bottomCardSection}
          </View>
        )}
        stickySectionHeadersEnabled={pageName === BookingStates.FAILED}
        renderSectionHeader={({ section }) => {
          if (section.title === 'AdTechSection') {
            if (!isEmpty(section.data) && !isEmpty(section.data[0].sectionTitle)) {
              return (
                <Text style={[styles.vaccineSectionHeader, fonts.regularFontFamily, fsStyle.font12]}>
                  {section.data[0].sectionTitle}
                </Text>
              );
            }
            return null;
          } else {
            return (
              <>
                {(section.title !== 'Others (0)' || section.title !== 'Upcoming (0)') &&
                  !hideTitleWhen(section) && (
                    <Text style={[styles.sectionHeader, fonts.semiBoldFontFamily, fsStyle.font16]}>
                      {section.title}
                    </Text>
                  )}
                {renderEmptyRecentFailedView(section)}
                {renderOlderBookingCount(section)}
              </>
            );
          }
        }}
      />}
    </ScrollView>
  );
}

function renderAdTechCard(cardStyle = []) {
  return (
    <View style={[...cardStyle, AtomicCss.borderRadius4]}>
      {getAdTechCard(
        Platform.OS !== 'web' ? POST_SALES_BOOKING_SUMMARY_PAGE : POST_SALES_WEB_BOOKING_SUMMARY_PAGE,
      )}
    </View>
  )
}
function renderTripIdeasCard(tripIdeasData, cardStyle = []) {
  if (Platform.OS !== 'web' && isEnglishLangSelected()) {
    if (!tripIdeasData.success) {
      TripSummaryOmnitureTracker.trackTripIdeasErrorEvent(tripIdeasData);
    } else {
      return <TripIdeasCard response={tripIdeasData} cardStyle={cardStyle} />
    }
  }
}

function EmptyDataScreen({ loadData, mainText, subText, buttonText, showLargeIcon = false }) {
  const { fsStyle, ...fonts } = getFont(true);
  return (
    <View style={[styles.errorStateWraper, AtomicCss.flex1]}>
      <Image style={showLargeIcon ? styles.errorImgLrgStyle : styles.errorImgStyle} source={showLargeIcon ? NO_TRIPS_ICON_LARGE : NO_TRIPS_ICON} />
      {!isEmpty(mainText) && <Text style={[styles.errorStateTxt, fonts.blackFontFamily, fsStyle.font16]}>{mainText}</Text>}
      {!isEmpty(subText) && <Text style={[styles.errorStateSubTxt, fonts.regularFontFamily, fsStyle.font12]}>{subText}</Text>}
      {loadData && !!buttonText && (
        <View style={styles.buttonWrapper}>
          <BaseButton variant={BUTTON_VARIANT.CAPSULE} text={buttonText} clickHandler={loadData} shadow />
        </View>
      )}
    </View>
  );
}

const renderCardList = (cardData, currentPage, summaryState, tripIdeasData, addBookingCTA, handleAddBookingOverlay) => {
  const swapNames = swapBookingStateNames(currentPage);
  const bookings = modifiedBookingListResponse(summaryState[swapNames].bookings, currentPage);
  currentPage = currentPage === BookingStates.FAILED ? BookingStates.UNSUCCESSFUL : currentPage;
  let visibleCardCount = 0;
  const result =
    !!cardData &&
    cardData.map((data, idx) => {
      const { cardId, isSticky, visibleTabs = { [currentPage]: -1 } } = data;

      // apply marginTop10 when there are other cards on top
      const applyMarginTop10 = idx !== 0 && visibleCardCount > 0;
      // apply marginTop3 when there are bookings and is the first card
      const applyMarginTop3 = visibleCardCount === 0 && bookings.length > 0 && !isSticky;

      const cardStyle = [
        applyMarginTop10 ? AtomicCss.marginTop10 : null,
        applyMarginTop3 ? AtomicCss.marginTop3 : null
      ];

      let card = null;
      /* if for current Tab we receive 2, than the card should always appear
        if for current Tab we receive 1, then we shall show the card only if there are bookings
        if for current Tab we receive 0, then we shall show the card only if there are no bookings */
      const showCard =
        visibleTabs[currentPage] === MyTripsCardsVisibility.ALWAYS ||
        (visibleTabs[currentPage] === MyTripsCardsVisibility.WITHOUT_BOOKING &&
          bookings.length < 1) ||
        (visibleTabs[currentPage] === MyTripsCardsVisibility.WITH_BOOKING && bookings.length > 0);
      if (showCard) {
        visibleCardCount += 1;
        if (cardId === MyTripsCards.TRIP_IDEAS_CARD && tripIdeasData) {
          card = renderTripIdeasCard(tripIdeasData);
        }
        else if (cardId === MyTripsCards.AD_TECH_CARD) {
          card = renderAdTechCard();
        }
        else if(cardId === MyTripsCards.ADD_BOOKING && addBookingCTA && isAddBookingFlowEnabled() ){
          card = (
            <AddBookingCard
              response={addBookingCTA}
              handleAddBookingOverlay={handleAddBookingOverlay}
            />
          );
        }
      }
      if (cardId === MyTripsCards.AD_TECH_CARD) {
        return (
          <View
            style={[
              AtomicCss.paddingAll10,
              AtomicCss.alignCenter,
              isSticky ? AtomicCss.whiteBg : '',
              styles.shadowWrapper,
              ...cardStyle
            ]}
          >
            {card}
          </View>
        )
      }
      return <View style={[...cardStyle, isSticky ? AtomicCss.whiteBg : '']}>{card}</View>
    });
  return result;
};

export const renderView = ({
  nonStickyBottomCard,
  stickyBottomCard,
  currentStatus,
  summaryState,
  tripIdeasData,
  addBookingCTA,
  handleAddBookingOverlay
}) => {
  return (
    <View style={[AtomicCss.flex1, AtomicCss.spaceBetween, AtomicCss.flexColumn]}>
      <View style={[AtomicCss.flexColumn, AtomicCss.flex1]}>
        {!!nonStickyBottomCard &&
          renderCardList(
            nonStickyBottomCard,
            currentStatus,
            summaryState,
            tripIdeasData,
            addBookingCTA,
            handleAddBookingOverlay
          )}
      </View>
      <View>
        {!!stickyBottomCard &&
          renderCardList(
            stickyBottomCard,
            currentStatus,
            summaryState,
            tripIdeasData,
          )}
      </View>
    </View>
  );
};

function BookingSummary(props) {
  const [viewState, setViewState] = useState(ViewState.LOADING);
  const [failedBookingProgress, updatefailedBookingProgress] = useState(false);
  const [planningsProgress, setPlanningsProgress] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [tripIdeasProgress, tripIdeasData, tripIdeasApi] = useApi(TRIP_IDEAS_SUMMARY_URL);
  const [tripIdeasDataLoaded, setTripIdeasDataLoaded] = useState(false);
  const [plannings, setPlannings] = useState({});
  const [summaryState, dispatch] = useReducer(summaryReducer, INITIAL_STATE);
  const [summaryResponse, setSummaryResponse] = useState({});
  const [currentStatus, updateCurrentStatus] = useState(PAGE_BOOKINGS_STATUS.UPCOMING);
  const [searchQuery, updateSearchQuery] = useState('');
  const [loadMoreLoaderOnPage, setloadMoreLoaderOnPage] = useState('');
  const [showLoadMoreError, setShowLoadMoreError] = useState(false);
  const [showErrorScreenOnPage, setShowErrorScreenOnPage] = useState('');
  const [bookingIdtoTicketDetailmap, setTicketsMap] = React.useState([]);
  const [showBookingOverlay, setShowBookingOverlay] = useState(false);
  let tabsArray = useRef([]);
  const hideBack = props?.hideBack === 'true';
  const staticData = getCurrentLangStaticData();
  const { bookingSummaryText, refreshText } = staticData;
  const { statusWiseMessage, errorMessage, emptyBookingSummaryMessage, otherLanguageBookingInfo, bookingsInOtherLanguage, cardDetail = {}, addBookingCTA } = bookings || {};

  const [stickyBottomCard, nonStickyBottomCard, nonStickyBottomCardCount] = useMemo(() => getMyTripsCardsInfo(
    cardDetail.bottomCardList || []
  ), [cardDetail.bottomCardList]);

  const [nonStickyTopCard, stickyTopCard] = useMemo(() => {
    // getMyTripsCardsInfo returns sticky and nonSticky cards among other infos. Extracing them for rendering
    const cards = cardDetail?.topCardList && getMyTripsCardsInfo(cardDetail.topCardList || []);
    const nonStickyCard = cards?.length > 1 && renderCardList(cards[1], currentStatus, summaryState, tripIdeasData, addBookingCTA, handleAddBookingOverlay);
    const stickyCard = cards?.length > 0 && renderCardList(cards[0], currentStatus, summaryState, tripIdeasData, addBookingCTA, handleAddBookingOverlay);
    return [nonStickyCard, stickyCard];
  }, [cardDetail.topCardList, tripIdeasData, summaryState, currentStatus]);

  let pages = [];
  let pageNames = [];
  let displayTabs = [];
  const globalPersuasion = useRef(null);

  const openHomePageSection = () => {
    if (Platform.OS === 'android') {
      myTripsNavigation.exitApp();
    } else {
      GenericModule.openDeepLink(Platform.OS === 'web' ? 'https://www.makemytrip.com/' : 'mmyt://app/home/');
    }
  };

  const handleAddBookingOverlay = (val, source) => {
    if (val && source === 'top') {
      addBookingTrackingHelper.trackClickEvent(ADD_BOOKING_TRACKING_EVENTS.Mytrips_topbar_addbooking_clicked, bookings.uuid);
    } else if (val && source === 'bottom') {
      addBookingTrackingHelper.trackClickEvent(`Mytrips_${currentStatus}_bottom_addbooking_clicked`, bookings.uuid);
    }
    setShowBookingOverlay(val);
  }

  useEffect(() => {
    setMsrTickets();
  }, []);

  const setMsrTickets = () => {
    fetchTickets().then((data) => {
      if (data) {
        setTicketsMap(data);
      }
    });
  };

  const getDisplayTabs = () => {
    // When Bookings and Planning Data not available it will return the cards from the backend
    // else will return the array by adding planning tab in the front
    if (!isEmpty(bookings) && !isEmpty(plannings)) {
      const { planningCapsText } = staticData;
      if (plannings && !!plannings.success && !plannings.noTicketPresent) {
        const planningTab = {
          id: PAGE_BOOKINGS_STATUS.PLANNING,
          title: planningCapsText,
        };
        tabsArray.current = [planningTab, ...bookings.bookingStateCards];
        return tabsArray.current;
      }
    }
    return bookings.bookingStateCards || [];
  }

  const getPageNames = (tabsIdArray) => Object.keys(summaryState)
    .map((item) => (item === BookingStates.FAILED ? BookingStates.UNSUCCESSFUL : item))
    .filter((item) => {
      if (item === BookingStates.PENDING) {
        return tabsIdArray.includes(item);
      } else {
        return true;
      }
    })
    .sort((a, b) =>
      tabsIdArray.indexOf(a) - tabsIdArray.indexOf(b)
    )
    .map((s) => {
      const tempName = swapBookingStateNames(s);
      return summaryState[tempName] && summaryState[tempName].recentCount
        ? `(${summaryState[tempName].recentCount}) ${s}`
        : s;
    })
    .filter((item) => item !== BookingStates.LOSTID_SUMMARY)
    .filter(
      (item) =>
        item !== PAGE_BOOKINGS_STATUS.PLANNING ||
        (item === PAGE_BOOKINGS_STATUS.PLANNING && !isEmpty(summaryState[item].bookings)),
    );

  const loadData = (pageStatus, page, pageRefresed = false) => {
    const prevState = {
      ...summaryState,
    };
    const bookingStatus = pageStatus ? pageStatus : currentStatus;
    const pageNumber = page
      ? page
      : prevState[bookingStatus]
        ? prevState[bookingStatus].currentPage + 1
        : 0;
    setloadMoreLoaderOnPage(
      bookingStatus === BookingStates.FAILED ? BookingStates.UNSUCCESSFUL : bookingStatus,
    );
    setShowLoadMoreError(false);
    setShowErrorScreenOnPage('');
    resetSummaryData();
    fetchBookings(bookingStatus, pageNumber, searchQuery)
      .then((_bookings) => {
        setSummaryResponse(_bookings);
        setBookings(_bookings);
        let currentStatusOldBookings = prevState[bookingStatus].bookings;
        if (pageRefresed) {
          currentStatusOldBookings = [];
        }
        resetSummaryData();
        setloadMoreLoaderOnPage('');
        if (_bookings.responseCode === 'FAILED') {
          handleError(bookingStatus);
        } else {
          const { updatedBookings, hasNextPage, updatedStatusWiseMessage, persuasion } = prepareBookingsData(
            _bookings,
            currentStatusOldBookings,
            bookingStatus,
          );
          const newState = {
            bookings: updatedBookings,
            hasNextPage: hasNextPage,
            currentPage: pageNumber,
            currentPageResponse: _bookings,
            statusWiseMessage: updatedStatusWiseMessage,
          };
          if (!!persuasion && !globalPersuasion.current) {
            globalPersuasion.current = persuasion;
          }
          dispatch({
            type: bookingStatus,
            payload: newState,
          });
          setViewState(ViewState.LOADED);
          TripSummaryOmnitureTracker.trackLoadEvent(_bookings, _bookings.bookingStateCards[0].id);
        }
      })
      .catch((err) => {
        console.log(err);
        setViewState(ViewState.ERROR);
        handleError(bookingStatus);
      });
  };

  const handleError = (bookingStatus) => {
    setloadMoreLoaderOnPage('');
    if (summaryState[bookingStatus] && summaryState[bookingStatus].currentPage === 0) {
      setShowErrorScreenOnPage(bookingStatus);
    } else {
      setShowLoadMoreError(true);
    }
    TripSummaryOmnitureTracker.trackErrorEvent(
      'mob:error:customer support:APIResponse',
      'MY_ININERARY_API_ERROR',
    );
  };

  const loadUnsuccessfulBookingData = useCallback(() => {
    updatefailedBookingProgress(true);
    setloadMoreLoaderOnPage(BookingStates.UNSUCCESSFUL);
    Promise.all([fetchLostIDBooking(), fetchBookings(BookingStates.FAILED, 1, '')])
      .then((values) => {
        if (!isEmpty(values[1])) {
          const {
            updatedBookings,
            hasNextPage,
            updatedStatusWiseMessage,
            recentCount,
          } = prepareBookingsData(values[1], [], BookingStates.FAILED, values[0]);
          const newState = {
            bookings: updatedBookings,
            hasNextPage: false,
            currentPage: 1,
            currentPageResponse: values[1],
            statusWiseMessage: updatedStatusWiseMessage,
            recentCount,
          };
          dispatch({
            type: PAGE_BOOKINGS_STATUS.FAILED,
            payload: newState,
          });
          setloadMoreLoaderOnPage('');
          updatefailedBookingProgress(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setViewState(ViewState.ERROR);
        setloadMoreLoaderOnPage('');
        updatefailedBookingProgress(false);
      });
  }, []);

  const loadTripIdeasData = useCallback(
    (_bookings) => {
      if (Object.keys(_bookings).length > 0 && !tripIdeasData && !tripIdeasProgress) {
        tripIdeasApi.httpPost({
          body: {
            bookings: prepareTripIdeasDataV4(_bookings),
          },
        });
      }
    },
    [tripIdeasApi, tripIdeasData, tripIdeasProgress],
  );

  const loadV4BookingData = useCallback(() => {
    loadData();
    loadUnsuccessfulBookingData();
    setRecentBkgInfo();
  }, [loadData, loadUnsuccessfulBookingData]);

  const loadPlanningData = useCallback(() => {
    setPlanningsProgress(true);
    fetchHolidayPlannings()
      .then((planningsData) => {
        setPlanningsProgress(false);
        if (planningsData && planningsData.success) {
          setPlannings(planningsData);
          dispatch({
            type: 'PLANNING',
            payload: preparePlanningData(planningsData),
          });
        } else {
          setPlannings({});
        }
      })
      .catch(() => {
        setPlanningsProgress(false);
        TripSummaryOmnitureTracker.trackErrorEvent(
          'mob:error:customer support:APIResponse',
          'HOLIDAY_PLANNING_API_ERROR',
        );
        setPlannings({});
      });
  });

  useEffect(() => {
    loadV4BookingData();
    const loadPlanningDataFunc = async () => {
      if (await shouldLoadPlanningData()) {
        loadPlanningData();
      }
    }
    loadPlanningDataFunc().catch((e)=>{
      console.log(e);
    });
  }, []);

  useEffect(() => {
    const fetchTripIdeasData = async () => {
      const isGcc = await isGCCBooking();
      if (isTripIdeaCardEnabled() && !isGcc){
        loadTripIdeasData(bookings);
      }
    };
    fetchTripIdeasData();
  }, [bookings]);

  const refreshBookingsPage = async () => {
    if (currentStatus !== PAGE_BOOKINGS_STATUS.PLANNING) {
      dispatch({
        type: currentStatus,
        payload: INITIAL_STATE[currentStatus],
      });
      resetSummaryData();
      if (currentStatus !== BookingStates.FAILED) loadData(currentStatus, 1, true);
      else {
        loadUnsuccessfulBookingData();
      }
    } else {
      if (await shouldLoadPlanningData()) {
        loadPlanningData();
      }
    }
  };

  const loadMoreBookings = (tryAgainClicked = false) => {
    if (
      (!showLoadMoreError &&
        loadMoreLoaderOnPage !== currentStatus &&
        summaryState[currentStatus] &&
        summaryState[currentStatus].hasNextPage) ||
      tryAgainClicked
    ) {
      setShowLoadMoreError(false);
      const pageName = PAGE_BOOKINGS_STATUS[currentStatus];
      setloadMoreLoaderOnPage(pageName);
      if (currentStatus !== BookingStates.FAILED) loadData();
    }
  };

  const trackTabUpdate = async (modifiedTabId) => {
    const pageName = await TripSummaryOmnitureTracker.getPageName();
    TripSummaryOmnitureTracker.trackNormalClickEvent(
      pageName,
      PAGE_BOOKINGS_STATUS[modifiedTabId],
      {},
    );
  }
  const updateCurrentTab = (tab) => {
    const modifiedTabId = swapBookingStateNames(tab.id);
    trackTabUpdate(modifiedTabId);
    if (currentStatus !== PAGE_BOOKINGS_STATUS[modifiedTabId]) {
      updateCurrentStatus(PAGE_BOOKINGS_STATUS[modifiedTabId]);
      closeLoadMoreError();
      if (
        summaryState[modifiedTabId] &&
        summaryState[modifiedTabId].currentPage === 0 &&
        modifiedTabId !== BookingStates.FAILED
      ) {
        loadData(PAGE_BOOKINGS_STATUS[modifiedTabId]);
      }
    }
  };

  const closeLoadMoreError = () => {
    setShowLoadMoreError(false);
  };

  React.useEffect(() => {
    if (tripIdeasData && tripIdeasData.success && !tripIdeasProgress && !tripIdeasDataLoaded) {
      TripSummaryOmnitureTracker.trackTripIdeasLoadEvent(tripIdeasData);
      setTripIdeasDataLoaded(true);
    }
  }, [tripIdeasData, tripIdeasProgress, tripIdeasDataLoaded]);


  displayTabs = getDisplayTabs();
  const tabsIdArray = displayTabs.map((tab) => tab.id);
  pageNames = getPageNames(tabsIdArray);

  const topRecentBkgV4 = useMemo(() => {
    const recentData = summaryState[BookingStates.FAILED].bookings
      ? summaryState[BookingStates.FAILED].bookings.find((item) => item.title.includes('RECENT'))
      : null;
    const bookingDate =
      recentData && recentData.data && recentData.data[0]
        ? recentData.data[0].bookingDateTime || recentData.data[0].maxArrDate
        : null;
    return bookingDate ? `${bookingDate}__${summaryState[BookingStates.FAILED].recentCount}` : null;
  }, [summaryState[BookingStates.FAILED]]);

  if (viewState === ViewState.LOADING || planningsProgress) {
    const { loadingYourTripText } = getStaticData(true, true);
    return (
      <View style={AtomicCss.flex1}>
        <ProgressView message={loadingYourTripText} />
      </View>
    );
  }

  if (viewState === ViewState.ERROR && (!pages || pages.length === 0)) {
    return (
      <View style={AtomicCss.flex1}>
        <MyTripsHeader onRefresh={loadV4BookingData} hideBack={hideBack} />
        <EmptyDataScreen
          loadData={loadData}
          mainText={
            !isEmpty(errorMessage)
              ? errorMessage.heading
              : bookingSummaryText.additionalText.noBookingErrorText
          }
          buttonText={!isEmpty(errorMessage) ? errorMessage.ctaText : refreshText}
        />
        {renderView({
          nonStickyBottomCard,
          stickyBottomCard,
          currentStatus,
          summaryState,
          tripIdeasData,
        })}
      </View>
    );
  }

  if (viewState === ViewState.EMPTY) {
    TripSummaryOmnitureTracker.trackErrorEvent(
      'mob:error:customer support:APIResponse',
      'MY_ININERARY_NO_BOOKINGS_ERROR',
    );
    return (
      <View style={styles.emptyWrapper}>
        <MyTripsHeader onRefresh={loadV4BookingData} hideBack={hideBack} />
        {Platform.OS !== 'web' && (
          <MyTripsLangSwitchCard
            bookingsInOtherLanguage={bookingsInOtherLanguage}
            otherLanguageBookingInfo={otherLanguageBookingInfo}
          />
        )}
        {!_.isEmpty(emptyBookingSummaryMessage) && (
          <EmptyDataScreen
            loadData={openHomePageSection}
            mainText={emptyBookingSummaryMessage.heading}
            subText={emptyBookingSummaryMessage.text}
          />
        )}
        {renderView({
          nonStickyBottomCard,
          stickyBottomCard,
          currentStatus,
          summaryState,
          tripIdeasData,
          addBookingCTA
        })}
      </View>
    );
  }

  const renderBookingList = () => {
    const persuasionObj = globalPersuasion.current || {};

    return (
      <View style={(!pages || pages.length === 0) ? [AtomicCss.greyBg, AtomicCss.flex1] : styles.mainWrapper}>
        <MyTripsHeader onRefresh={refreshBookingsPage} hideBack={hideBack} headerData={{ uuid: bookings.uuid, plusIcon: addBookingCTA?.plusImageURL, handleAddBookingOverlay, showBookingOverlay }} />
        {Platform.OS !== 'web' && (
          <MyTripsLangSwitchCard
            bookingsInOtherLanguage={bookingsInOtherLanguage}
            otherLanguageBookingInfo={otherLanguageBookingInfo}
          />
        )}
        <ViewPagerWithTabs
          tabs={pageNames}
          topRecentBkg={topRecentBkgV4}
          updateCurrentTab={updateCurrentTab}
          displayTabs={displayTabs}
        >
          {displayTabs.map((tab, index) => {
            const swapNames = swapBookingStateNames(tab.id);
            const bookings = modifiedBookingListResponse(summaryState[swapNames].bookings, tab.id);
            const getStatusMessage = getStatusWiseMessage(statusWiseMessage, tab.id)
            const hasNonStickyBottomCards = !!(bookings.length > 0
              ? nonStickyBottomCardCount[`NON_STICKYCARD_${tab.id}`].nonEmptyListingCount
              : nonStickyBottomCardCount[`NON_STICKYCARD_${tab.id}`].emptyListingCount);
            return (
              <View style={AtomicCss.flex1} key={index}>
                <BookingList
                  key={index}
                  bookings={bookings}
                  loadMore={loadMoreBookings}
                  pageName={swapNames}
                  recentLostIdDurationInDays={summaryState[swapNames].recentCount}
                  showSortOptions={false}
                  showError={showErrorScreenOnPage === currentStatus}
                  showLoadMoreLoader={tab.id === loadMoreLoaderOnPage}
                  summaryResponse={summaryState[swapNames].currentPageResponse}
                  openHomePageSection={openHomePageSection}
                  statusWiseMessage={summaryState[swapNames].statusWiseMessage}
                  bookingIdtoTicketDetailmap={bookingIdtoTicketDetailmap}
                  refreshPlanningData={loadPlanningData}
                  hasNonStickyBottomCards={hasNonStickyBottomCards}
                  stickyTopCard={stickyTopCard}
                  headerSection={
                    <>
                      {nonStickyTopCard}
                    </>
                  }
                  footerSection={
                    showEmptyScreenActiveTabV4(summaryState[swapNames].bookings) &&
                    !isEmpty(getStatusMessage) && (
                      <EmptyDataScreen
                        loadData={openHomePageSection}
                        showLargeIcon={!hasNonStickyBottomCards}
                        mainText={getStatusMessage.heading}
                        subText={getStatusMessage.text}
                      />
                    )}
                  bottomCardSection={
                    !!nonStickyBottomCard &&
                    renderCardList(
                      nonStickyBottomCard,
                      currentStatus,
                      summaryState,
                      tripIdeasData,
                      addBookingCTA,
                      handleAddBookingOverlay
                    )
                  }
                  tripIdeasData={tripIdeasData}
                  hasNextPage={summaryState[swapNames].hasNextPage}
                />
              </View>
            )
          })}
        </ViewPagerWithTabs>
        {showLoadMoreError && <SnackBarCard
          message={staticData.errorText.notAbleToLoadMoreBookingsText}
          actionLabel={staticData.tryAgainText}
          actionHandler={() => loadMoreBookings(true)}
          closeErrorCard={closeLoadMoreError}
        />}
        {!!persuasionObj && <GlobalMessageBox {...persuasionObj}></GlobalMessageBox>}
        {!!stickyBottomCard &&
          renderCardList(
            stickyBottomCard,
            currentStatus,
            summaryState,
            tripIdeasData,
            addBookingCTA,
            handleAddBookingOverlay
          )
        }
        {showBookingOverlay && <AddBookingBottomOverlay handleOverlay={handleAddBookingOverlay} uuid={bookings.uuid} response={addBookingCTA} />}
      </View>
    );
  };

  return renderBookingList();
}
export default BookingSummary;
