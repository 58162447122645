import React from 'react';
import PropTypes from 'prop-types';
import Actions from '../../../../../navigation/postSalesNavigation';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import {
  DeviceEventEmitter,
  ScrollView,
  Text,
  View,
  Image,
  Platform,
  TouchableOpacity,
} from 'react-native';
import convertMapToList from 'core-ui-lib/helper/Object/convertMapToList';
import DateChangeBaseClass from '../../components/DateChangeBaseClass.js';
import {
  getHeaderText,
  areAllSegmentsDisabled,
  getPaxFareSegmentList,
  getDateRangeInputCalendar,
  selectFlights,
  isTripNotAllowed,
  getSteps,
  checkSegmentDetailsListLength,
} from '../../utils';
import {
  getPaxList,
  checkRtDiffPnr,
  getAllPossibleScActionLists,
  getSegValidationData,
  getMathingScCodes
} from '../../helper';
import Header from '../../components/Header';
import Breadcrumb from '../../../components/Breadcrumb';
import PickDatesCard from './components/PickDatesCard';
import { responseState } from '../../constants';
import {
  FLIGHTS_LISTING_DATE_CHANGE_REQUESTV2,
  FLIGHT_DATE_CHANGE_REQUESTV2,
} from '../../../../../utils/NetworkUtils';
import OverlayMessage from '@mmt/legacy-commons/Common/Components/MIMABottomOverlay/OverlayMessage';
import ErrorOverlay from '@mmt/legacy-commons/Common/Components/MIMABottomOverlay/ErrorOverlay';
import DateChangeTrackingHelper from '../../analytics/DateChangeTrackingHelper';
import RtDtErrOverlay from '@mmt/legacy-commons/Common/Components/MIMABottomOverlay/RtDtErrOverlay';

import InformationCard from '../../../../../Common/InformationCard';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { openErrorPage } from '../../../../../utils/PostSaleUtil';
import getPickDatesCss from './styles';
import RequestValidationOverlay from './components/RequestValidationOverlay';
import ReasonRefundDtls from './components/ReasonRefundDtls';
import { getPrimaryContactNo } from '../../../../FlightBookingUtil';

import NeedMoreHelpOverlay from '../../../../../Common/NeedMoreHelpOverlay';
import { NEED_MORE_HELP, NEED_HELP_PAGE, LOBNAMES } from '../../../../../PostSalesConstant';
import { CALENDAR_GREEN_TICK_ICON, INFO_RED_ICON } from '../../../../FlightImageConstants';
import Calender from '../../../../../Common/OverlayCalendar';
import SimpleHeader from '@mmt/legacy-commons/Common/Components/Header/SimpleHeader';
import DateChangeOverlay from '../../../../../hotel/modification/datechange/DateChangeOverlay';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import BaseButton, { BUTTON_VARIANT } from '../../../../../Common/BaseButton';
import { getStaticData } from '../../../../../staticData/staticData';
import { getFont } from '../../../../../PostSalesStyles.js';
import { ThemeContext } from '../../../../../theme/theme.context.js';
import { getPaxFareSegmentList as getSpecialClaimPaxFareSegmentList, getRequestBodyTwo } from '../../../SpecialClaimNew/data/dataAdapter';
import { fetchSpecialClaimPreview } from '../../../Cancellation/data/api';
import { FLIGHT_THROTTLE_OBJECT } from '../../../details/FlightBookingDetailsConstant';

export default class PickDates extends DateChangeBaseClass {
  static contextType = ThemeContext;
  constructor(props) {
    super(props, 'flightRescheduleFlightsPage', '');
    this.response = {};
    this.listingResponse = {};
    this.segmentGroup = [];
    this.state = {
      activeStep: 0,
      segmentGroup: [],
      isPopUpOpen: false,
      activeSectorForRefund: {},
      refundLevel: 'segment',
      callbackOverlay: true,
      calenderOverlayData: {
        checkinDate: new Date(),
        checkoutDate: new Date(),
      },
      showPickFlightsCta: false,
      activeIndex: 0,
      rescheduleData: {},
      computedRescheduleDate: {},
      showCalendarModal: false,
      specialClaimLoader: false,
    };

    this.segmentMap = {};
    this.flightDateChangeData = JSON.parse(this.props.flightDateChangeData);
    this.segmentPassengerMap = {};
    this.openModificationThankYouPage = this.openModificationThankYouPage.bind(this);
    this.rtDiffPnr = checkSegmentDetailsListLength(this.flightDateChangeData) > 1 && checkRtDiffPnr(this.flightDateChangeData);
    this.flowName = 'datechange';
    this.dateChangeThankyouListener = null;
  }



  componentWillMount() {
    this.dateChangeThankyouListener = DeviceEventEmitter.addListener(
      'flight_datechange_thankyou_page',
      this.openModificationThankYouPage.bind(this),
    );
  }

  componentWillUnmount() {
    if (this.dateChangeThankyouListener) {
      this.dateChangeThankyouListener.remove();
    }
  }

  componentDidMount() {
    this.props.dateChangePreviewRequest(
      this.flightDateChangeData.bookingID,
      this.segmentGroup,
      FLIGHT_DATE_CHANGE_REQUESTV2,
      this.flightDateChangeData.cfarApplicable,
    );
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.dateChangeResponse) {
      this.response = nextProps.dateChangeResponse;
    } else if (nextProps.listingResponse) {
      this.listingResponse = nextProps.listingResponse;
    }
    return true;
  }

  updateOverlayDate = (from, to) => {
    const calenderOverlayData = { ...this.state.calenderOverlayData };
    calenderOverlayData.checkinDate = from;
    calenderOverlayData.checkoutDate = to;
    this.setState({
      calenderOverlayData,
    });
  };

  updateDateRangeInput(sDate, eDate) {
    const { startDate, endDate } = getDateRangeInputCalendar(sDate, eDate);
    this.setState({
      computedRescheduleDate: {
        ...this.state.computedRescheduleDate,
        [this.state.activeIndex]: {
          checkInDate: startDate,
          checkOutDate: eDate ? endDate : null,
          departureDate: `${startDate.year}-${startDate.month}-${startDate.date}T00:00:00`,
        },
      },
    });
  }

  onDayPress = () => {
    const { checkinDate, checkoutDate } = this.state.calenderOverlayData;
    const rescheduleData = {
      ...this.state.rescheduleDate,
      [this.state.activeIndex]: {
        checkinDate: checkinDate,
        checkoutDate: checkoutDate,
      },
    };
    this.setState(
      {
        showCalendarModal: false,
        rescheduleData,
      },
      () => {
        this.updateDateRangeInput(checkinDate, checkoutDate);
      },
    );
  };

  _showCalendarModel = (index) => {
    this.setState({
      showCalendarModal: true,
      activeIndex: index,
    });
  };
  _hideCalendarModel = () => {
    this.setState({
      showCalendarModal: false,
    });
  };
  render() {
    const fonts = getFont(true);
    const styles = getPickDatesCss(this.context.psTheme, fonts);
    const {selectTravelDatesText, doneText} = getStaticData();
    const calenderOverlayText = {
      heading: selectTravelDatesText,
      additionalText: {
        buttonText: doneText,
      },
    };
    const { loadingDateChangeText, loadingFlightsText } = getStaticData();
    return (
      <View style={styles.pageWrapper}>
        {(this.props.dateChangeState === responseState.RESPONSE_IS_FETCHED ||
          this.props.dateChangeState === responseState.LISTING_RESPONSE_ERROR_OPEN ||
          this.props.dateChangeState === responseState.LISTING_RESPONSE_ERROR_CLOSE ||
          this.props.dateChangeState === responseState.PROCEED_ENABLED ||
          this.props.dateChangeState === responseState.RT_DATE_ERROR) &&
          this._renderView(styles)}
        {(this.props.dateChangeState === responseState.RESPONSE_IS_FAILED ||
          this.props.dateChangeState === responseState.LISTING_RESPONSE_FAILED) &&
          this.renderErrorView()}
        {this.props.dateChangeState === responseState.RESPONSE_IS_LOADING &&
          this.renderProgressView(loadingDateChangeText)}
        {this.props.dateChangeState === responseState.LISTING_RESPONSE_FETCHED &&
          this._renderListingView()}
        {this.props.dateChangeState === responseState.LISTING_RESPONSE_ERROR_LOADER &&
          this.renderProgressView(loadingFlightsText)}
        {this.props.dateChangeState === responseState.PREVIEW_RESPONSE_FAILED &&
          this.showNewErrorPageFromResponse(this.onBackPressFromPreviewAPIError)}
        {this.props.dateChangeState === responseState.LISTING_RESPONSE_FAILED_NEW &&
          this.showNewErrorPageFromResponse(this.onBackPressFromPreviewAPIError)}
        {this.state.showCalendarModal && Platform.OS === 'web' && (
          <DateChangeOverlay>
            {this.state.calenderOverlayData.checkinDate && (
              <TouchableOpacity style={styles.doneBtn} onPress={this.onDayPress}>
                <Text style={{ color: '#fff' }}>
                  {calenderOverlayText.additionalText.buttonText}
                </Text>
              </TouchableOpacity>
            )}
            <SimpleHeader title={calenderOverlayText.heading} iconPress={this._hideCalendarModel} />
            <Calender
              singleDate
              {...calenderOverlayText.additionalText}
              from={
                this.state.rescheduleData && this.state.rescheduleData[this.state.activeIndex]
                  ? this.state.rescheduleData[this.state.activeIndex].checkinDate
                  : this.state.calenderOverlayData.checkinDate
              }
              updateDate={this.updateOverlayDate}
            />
          </DateChangeOverlay>
        )}
      </View>
    );
  }

  fetchData = () => {
    this.props.dateChangeRequestLoader();
    this.props.dateChangePreviewRequest(
      this.flightDateChangeData.bookingID,
      this.segmentGroup,
      FLIGHT_DATE_CHANGE_REQUESTV2,
      this.flightDateChangeData.isCfarBooking,
    );
  };

  toggleCallBackOverlay = (value) => {
    this.setState({
      callbackOverlay: value,
    });
  };

  getSpecialClaimDataList = () => {
    const segValidationData = getSegValidationData(
      this.response?.odcValidation,
      this.state.activeSectorForRefund,
    )
    const allActionLists = [];
    !isEmpty(segValidationData) && segValidationData.cards &&
      segValidationData.cards.map((card) => {
        if (card?.actionList && card?.cardName === 'SpecialClaim') {
          allActionLists.push(...card.actionList);
        }
      });
    const specialClaimDataList = get(
      this.response?.odcValidation,
      'specialClaimDataList',
      null
    );
    if(isEmpty(allActionLists) || isEmpty(specialClaimDataList)){
      return [];
    }
    const matchingScCodes = getMathingScCodes(allActionLists, specialClaimDataList);
    return matchingScCodes;
  }

  trackHelpingHandEvents = (type, page, click, data) => {
    const pageName = `datechangepickdate_${page}`;
    const trackData = Object.assign({}, this.flightDateChangeData, {
      ruleTrace: data ? data.ruleTrace : '',
    });
    switch (type) {
      case 'load':
        this.flightDateChangeData && DateChangeTrackingHelper.trackLoadEvent(pageName, trackData);
        break;
      case 'click':
        DateChangeTrackingHelper.trackClickableEvent(pageName, `Flight_${click}`, this.flightDateChangeData, this.flightDateChangeData?.isCorporateBooking);
        break;
      default:
        break;
    }
  };

  _renderView = (styles) => {
    const {
      rescheduleYourFlightText,
      dateChangeSupportText,
      selectRefundReasonHeaderText,
      selectRefundReasonSubHeaderText,
      proceedFlightsText,
      proceedText,
      backCapsText,
    } = getStaticData();

    if(this.state.specialClaimLoader){
      return this.renderProgressView();
    }

    return (
      <View style={styles.pageWrapper}>
        <Header
          type="Details"
          title={rescheduleYourFlightText}
          onBackPress={this.goBack}
          headerData={getHeaderText(this.response)}
        />
        <Breadcrumb
          activeStep={this.state.activeStep}
          isCorporateBooking={this.flightDateChangeData.isCorporateBooking}
          data={getSteps()}
        />
        <ScrollView
          ref="pageScroll"
          showsVerticalScrollIndicator={false}
          style={styles.pageScroll}
        >
          {!isEmpty(this.response.message) && (
            <InformationCard
              description={this.response.message}
              cardBackgroundColor={colors.creamWhite}
              textColor={colors.lightYello}
            />
          )}
          {!isEmpty(get(this.response, 'cfarInfo.title', '')) && (
            <InformationCard
              description={get(this.response, 'cfarInfo.title', '')}
              cardBackgroundColor={colors.creamWhite}
              textColor={colors.lightYello}
            />
          )}
          {this.rtDiffPnr && !areAllSegmentsDisabled(this.response.odcValidation) && (
            <View style={styles.rtAlertTxt}>
              <Image style={styles.infoIcon} source={INFO_RED_ICON} />
              <Text style={[styles.info_redTxt]}>{dateChangeSupportText}</Text>
            </View>
          )}

          <View
            style={[
              styles.whiteCard,
              isEmpty(this.response.message) ? AtomicCss.marginTop15 : '',
            ]}
          >
            {this._addDatePickerView(this.response, styles)}
          </View>
          <View style={styles.pageScrollBtmSpace} />
        </ScrollView>

        {this.props.dateChangeState === responseState.LISTING_RESPONSE_ERROR_OPEN && (
          <OverlayMessage
            CloseOverlay={this.closeErrorOverlay}
            content={
              <ErrorOverlay
                listingResponse={this.listingResponse}
                CloseOverlay={this.closeErrorOverlay}
              />
            }
          />
        )}

        {this.props.dateChangeState === responseState.RT_DATE_ERROR && (
          <OverlayMessage
            CloseOverlay={this.closeErrorOverlay}
            content={
              <RtDtErrOverlay
                CloseOverlay={this.closeErrorOverlay}
                continueOverlay={this.callListing}
              />
            }
          />
        )}

        {this.state.isPopUpOpen && (
          <RequestValidationOverlay
            closeOverlay={this.setPopUpOpen}
            content={
              <ReasonRefundDtls
                heading={selectRefundReasonHeaderText}
                subHeading={selectRefundReasonSubHeaderText}
                rightButtonText={proceedText}
                leftButtonText={backCapsText}
                closeOverlay={this.setPopUpOpen}
                onProceed={this.onProceed}
                activeSectorForRefund={this.state.activeSectorForRefund}
                specialClaimDataList={this.getSpecialClaimDataList()}
                refundLevel={this.state.refundLevel}
              />
            }
          />
        )}
        <View style={styles.footerBtnWrapper}>
          <BaseButton
            text={proceedFlightsText}
            clickHandler={() => this._selectFlights()}
            variant={BUTTON_VARIANT.PRIMARY}
            {...FLIGHT_THROTTLE_OBJECT}
            textStyle={AtomicCss.font16}
            disabled={!this.state.showPickFlightsCta}
          />
        </View>
        {this.state.callbackOverlay === true && !isEmpty(this.response) && (
          <NeedMoreHelpOverlay
            bookingId={this.flightDateChangeData ? this.flightDateChangeData.bookingID : ''}
            handleBottomOverlay={this.toggleCallBackOverlay}
            handleReload={this.toggleCallBackOverlay}
            component={NEED_MORE_HELP.HELPING_HAND}
            pageName={NEED_HELP_PAGE.FLIGHT_DATE_CHANGE}
            hideLoader={true}
            trackEvent={this.trackHelpingHandEvents}
          />
        )}
        {this.state.callbackOverlay === true && !isEmpty(this.response) && (
          <NeedMoreHelpOverlay
            bookingId={this.flightDateChangeData ? this.flightDateChangeData.bookingID : ''}
            handleBottomOverlay={this.toggleCallBackOverlay}
            handleReload={this.toggleCallBackOverlay}
            component={NEED_MORE_HELP.HELPING_HAND}
            pageName={NEED_HELP_PAGE.FLIGHT_DATE_CHANGE}
            hideLoader={true}
            trackEvent={this.trackHelpingHandEvents}
          />
        )}
      </View>
    );
  };


  _selectFlights = async () => {
    selectFlights(
      getStaticData(),
      this.segmentGroup,
      this.response,
      this.props.rtDateErrorPopup,
      this.rtDiffPnr,
      this.callListing,
    );
    DateChangeTrackingHelper.trackClickableEvent('datechangepickdate', 'proceedToPickFlights', this.flightDateChangeData, this.flightDateChangeData?.isCorporateBooking);
  };

  callListing = () => {
    this.props.dateChangeListingLoader();
    this.props.dateChangeListingRequest(
      this.flightDateChangeData.bookingID,
      this.segmentGroup,
      FLIGHTS_LISTING_DATE_CHANGE_REQUESTV2,
      this.flightDateChangeData.cfarApplicable,
    );
  };

  _addDatePickerView = (response, styles) => {
    const { flightDetails, odcValidation } = response;
    const views = [];
    if (flightDetails) {
      if (!isEmpty(flightDetails.segmentGroupDetailList)) {
        flightDetails.segmentGroupDetailList.forEach((segmentGroupObj, index) => {
          this._addViewSegmentWise(
            views,
            segmentGroupObj.segmentDetails[0],
            index,
            segmentGroupObj.dateChangeSegmentGroup,
            segmentGroupObj.intentRefID,
            segmentGroupObj.cancellation,
            segmentGroupObj,
            odcValidation,
            styles
          );
        });
      }
    }
    return views;
  };

  _addViewSegmentWise = (
    views,
    segment,
    index,
    dateChangeSegmentGroup,
    intentRefId,
    cancellation,
    segmentGroupObj,
    odcValidation,
    styles
  ) => {
    const tripNotAllowed = isTripNotAllowed(cancellation, this.response)
    views.push(
      <View key={segment.segmentLineNo} style={styles.pickDateWrapper}>
        <PickDatesCard
          response={segment}
          odcPrevResponse={this.response}
          segmentMap={this.segmentMap}
          selectedDates={this._selectedDates}
          dateChangeSegmentGroup={dateChangeSegmentGroup}
          intentRefId={intentRefId}
          webSelectedDate={
            this.state.computedRescheduleDate ? this.state.computedRescheduleDate[index] : {}
          }
          clearDate={this._clearSelectedDate}
          showCalendarModel={() => this._showCalendarModel(index)}
          rtDiffPnr={this.rtDiffPnr}
          isCorporateBooking={this.flightDateChangeData.isCorporateBooking}
          tripNotAllowed={tripNotAllowed}
          tripNotAllowedMsg={
            tripNotAllowed && cancellation.infoMessage
          }
          bookingDateTime={this.response.bookingDateTime}
          segmentDetails={segmentGroupObj.segmentDetails}
          odcValidation={odcValidation}
          specialClaimData={
            this.response.odcValidation ? this.response.odcValidation.specialClaimData : {}
          }
          setPopUpOpen={(type = 'segment') => this.setPopUpOpen(type, segmentGroupObj)}
          onSubmit={this.onSubmit}
          trackValidationError={DateChangeTrackingHelper.trackValidationError}
          trackValidationClickEvent={DateChangeTrackingHelper.trackValidationClickEvent}
          noChangeObj={segmentGroupObj?.cancellation}
        />
      </View>,
    );
  };

  _clearSelectedDate = (segmentLineNo) => {
    delete this.segmentPassengerMap[segmentLineNo];
    const result = convertMapToList(this.segmentPassengerMap);
    this.segmentGroup = result;
    this.setState({
      showPickFlightsCta: false
    })
  };

  _selectedDates = (selectedDatesMap, segmentLineNo, intentRefId) => {
    const mapper = {};
    if (selectedDatesMap && selectedDatesMap[segmentLineNo]) {
      mapper.newDate = selectedDatesMap[segmentLineNo].departureDate.split('T')[0];
    }
    if (segmentLineNo) {
      this.segmentPassengerMap[segmentLineNo] = mapper;
    }
    mapper.paxFareSegmentId = getPaxList(this.response, intentRefId);
    mapper.intentRefID = intentRefId;
    const result = convertMapToList(this.segmentPassengerMap);
    this.segmentGroup = result;
    this.setState({
      showPickFlightsCta: true
    })
  };

  _renderListingView = () => {
    if (this.props.listingResponse.status) {
      this.props.enableProceedBtn();
      const searchKey = this.props.listingResponse.encryptedContent;
      Actions.flightListing({
        searchKey,
        bookingId: this.flightDateChangeData.bookingID,
        currency: this.props.listingResponse.custCurrency,
        isCorporateBooking: this.flightDateChangeData.isCorporateBooking,
        segmentGroup: this.segmentGroup,
        odcPreviewResponse: this.response,
        isCfarBooking: this.flightDateChangeData.cfarApplicable,
      });
    } else {
      this.props.dateChangeListingError(true);
      DateChangeTrackingHelper.trackErrorEvent(
        'FLIGHT_LISTING_ERROR_POPUP_',
        this.props.listingResponse.errorCode,
      );
    }
  };

  closeErrorOverlay = () => {
    DateChangeTrackingHelper.trackClickableEvent('datechangepickdate', 'listingErrorClose', this.flightDateChangeData, this.flightDateChangeData?.isCorporateBooking);
    this.props.dateChangeListingError(false);
  };

  openModificationThankYouPage(data) {
    if (data) {
      const paymentResponse = JSON.parse(data.PAYMENT_RESPONSE_VO);
      const emailId = data.emailID;
      const bookingId = data.BOOKING_ID;
      if (paymentResponse) {
        const respObj = JSON.parse(paymentResponse.response);
        Actions.dateChangeSucess({
          paymentSuccess: respObj.success,
          bookingId,
          emailId,
        });
      } else {
        Actions.dateChangeSucess({
          paymentSuccess: false,
          bookingId,
        });
      }
    } else {
      Actions.dateChangeSucess({
        paymentSuccess: false,
        bookingId: this.flightDateChangeData && this.flightDateChangeData.bookingID,
      });
    }
  }

  showNewErrorPageFromResponse = (backPressHandler) => {
    openErrorPage(
      this.props.errorPageData,
      backPressHandler,
      this.fetchData,
      this.flightDateChangeData.bookingID,
      'datechange',
      Actions,
      { psLob: LOBNAMES.FLIGHT,
        trackClickableEvent: this.trackClickableEvent
      }
    );
  };

  trackClickableEvent = (ctaName, type) => {
    DateChangeTrackingHelper.trackClickableEvent('datechangepickdate', ctaName, this.flightDateChangeData, this.flightDateChangeData?.isCorporateBooking, type);
  }

  onBackPressFromPreviewAPIError = () => {
    setTimeout(() => Actions.pop());
  };

  setPopUpOpen = (type, selectedSectorForRefund) => {
    this.setState({
      activeSectorForRefund: selectedSectorForRefund,
      isPopUpOpen: !this.state.isPopUpOpen,
      refundLevel: type,
    });
  };

  onProceed = (activeScCode) => {
    if(this.flightDateChangeData && this.flightDateChangeData.specialClaimVariant === 2){
      const { flightDetails, bookingID } = this.flightDateChangeData;
      const paxFareSegmentIdList = getSpecialClaimPaxFareSegmentList(flightDetails, activeScCode);
      const body = getRequestBodyTwo(bookingID, activeScCode, 'RefundToCustomer', paxFareSegmentIdList);
      try{
        fetchSpecialClaimPreview(bookingID,body).then((resposneObj) => {
          const { error, response } =  resposneObj;
          if(!error){
            Actions.specialClaimNew({ detailsResponse: response, scCode: activeScCode })
          }
        })
        .catch((e) => { console.log("error in new special claim api: ", e); })
        .finally(() => {
          this.setState({specialClaimLoader: false})
        })
      }catch(e){
        console.log("error in new special claim api: ", e);
        this.setState({ specialClaimLoader: false })
      }
      this.setState({ specialClaimLoader: true})
    }else{
    const specialClaimData = this.response.odcValidation.specialClaimDataList.find(
      (data) => data && data.scCode === activeScCode,
    );
    Actions.specialClaimSelectPassenger({
      specialClaimData: JSON.stringify(specialClaimData),
      partialPax: JSON.stringify(getPaxFareSegmentList(this.response, specialClaimData)),
      bookingID: this.response.bookingID,
      bookingState: '',
      lobCode: this.response.lobCode,
      validationError: true,
      mobileNumber: getPrimaryContactNo(
        this.response.accountDetail ? this.response.accountDetail.contactDetails : '',
      ),
      isCorporateBooking: this.response.isCorporateBooking,
    });
    }
    this.setState({
      isPopUpOpen: false,
    });
  };

  onSubmit = (uniquePageId, errorCode) => {
    const bookingId = this.response.bookingID;
    Actions.commonSubmitRequest({
      bookingId,
      uniquePageId,
      errorCode,
      openThankYouPage: this.openThankYouPage,
      openFailurePage: this.openFailurePage,
      openFailurePageWithDefaultData: this.openFailurePageWithDefaultData,
      flowName: this.flowName,
      psLob: LOBNAMES.FLIGHT,
    });
  };

  openThankYouPage = (response) => {
    const { heading, description, ctaList, bookingId } = response;
    Actions.commonSubmitRequestThankYou({
      icon: CALENDAR_GREEN_TICK_ICON,
      iconStyle: {
        width: 80,
        height: 80,
        marginBottom: 8,
      },
      title: heading,
      descriptionList: [description],
      btnTitle: ctaList[0].actionText,
      bookingId,
      lob : LOBNAMES.FLIGHT
    });
  };

  openFailurePage = (response) => {
    const errorData = { ...response };
    errorData.mobileHeaderData = {};
    errorData.mobileHeaderData.title = response.mobileHeaderData.header;
    errorData.mobileHeaderData.description = response.mobileHeaderData.subText;
    openErrorPage(
      errorData,
      undefined,
      this.onSubmit,
      this.response.bookingId,
      this.flowName,
      Actions,
      { psLob: LOBNAMES.FLIGHT },
    );
  };

  openFailurePageWithDefaultData = () => {
    const { errorText: errorTextObj, tryAgainText } = getStaticData();
    const {
      errorText,
      soomethingWentWrongOnlyError,
      unableToProcessRequestTechIssueText,
    } = errorTextObj || {};
    const errorData = {};
    errorData.heading = soomethingWentWrongOnlyError;
    errorData.description = unableToProcessRequestTechIssueText;
    errorData.mobileHeaderData = {};
    errorData.mobileHeaderData.title = errorText;
    const ctaList =
      ' [{\n' +
      '            "actionId": "TRY_AGAIN",\n' +
      `            "actionText": "${tryAgainText}",\n` +
      '            "actionUrl": null,\n' +
      '            "type": 1\n' +
      '        }]';
    errorData.ctaList = JSON.parse(ctaList);
    openErrorPage(
      errorData,
      undefined,
      this.onSubmit,
      this.response.bookingId,
      this.flowName,
      Actions,
      { psLob: LOBNAMES.FLIGHT },
    );
  };
}

PickDates.propTypes = {
  flightDateChangeData: PropTypes.string.isRequired,
};
