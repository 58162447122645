import { Text, View, TouchableOpacity, ScrollView, Image } from 'react-native';
import React from 'react';
import createStyles from './InclusionCss';

import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { additionalInclusionIcon } from '../../HolidayBookingConstants';
import { BULLET_POINT_CONTENT } from '../../../../../holidays/src/HolidayConstants';
import { isEmpty } from 'lodash';
import { getFont } from '../../../PostSalesStyles';
import { useTheme } from '../../../theme/theme.context';

export const InclusionsOverlay = ({ handleClose, inclusions , inclusionsHeading  }) => {
  const {fsStyle,...fonts} = getFont();
  const {psTheme : theme} = useTheme();
  const styles = createStyles(fonts, theme);
  return (
    <View style={styles.modalWrapper}>
      <View style={styles.headerWrapper}>
        <Text style={[styles.InclusionHead, AtomicCss.blackFont, AtomicCss.flex2]}>
          {inclusionsHeading?.inclusionHeader}
        </Text>
        <TouchableOpacity onPress={() => handleClose('')}>
          <Image style={styles.closeIconStyle} source={additionalInclusionIcon} />
        </TouchableOpacity>
      </View>
      <ScrollView showsVerticalScrollIndicator={false}>
        <View style={styles.collapedContainer}>
          { !isEmpty(inclusions) && Object.entries(inclusions).map((item, index) => {
            const [key, value] = item;
            if (key && value) {
              return (
                <View style={[styles.answerRow, AtomicCss.marginBottom10]}>
                  <Text style={styles.bulletsPoints}>{BULLET_POINT_CONTENT}</Text>
                  <View style={AtomicCss.flex1}>
                    <Text
                      style={[
                        AtomicCss.lineHeight20,
                        fsStyle.font14,
                        fonts.regularFontFamily,
                        {color: theme.color.defaultTextColor},
                      ]}
                    >
                      <Text style={fonts.boldFontFamily}>{key}</Text> : {value}
                    </Text>
                  </View>
                </View>
              );
            }
          })}
        </View>
      </ScrollView>
    </View>
  );
};

export default InclusionsOverlay;
