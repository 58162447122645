import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {View, Image, Text, StyleSheet} from 'react-native';
import errorImage from '@mmt/legacy-assets/src/no_cabs.webp';
import {colors, fonts} from '../../Styles/globalStyles';

class ImageErrorView extends Component {
  render() {
    return (
      <View style={styles.noDestinationContainer}>
        <Image style={styles.noDestinationImage} source={errorImage} resizeMode="contain" />
        <Text style={styles.noDestinationTitle}>{this.props.title}</Text>
        <Text
          style={styles.noDestinationSubtitle}
          onPress={() => this.props.retry(this.props.retryData)}
        >{this.props.subTitle}
        </Text>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  noDestinationContainer: {
    flex: 1,
    backgroundColor: colors.white,
    paddingHorizontal: 36,
    marginTop: 15,
    alignItems: 'center'
  },
  noDestinationImage: {
    height: 180,
    marginVertical: 16
  },
  noDestinationTitle: {
    textAlign: 'center',
    marginTop: 16,
    fontSize: 20,
    color: colors.black,
    lineHeight: 32,
    fontFamily: fonts.bold
  },
  noDestinationSubtitle: {
    textAlign: 'center',
    paddingVertical: 16,
    paddingHorizontal: 16,
    fontSize: 14,
    color: colors.azure,
    fontFamily: fonts.regular
  }
});

ImageErrorView.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  retry: PropTypes.func.isRequired,
  retryData: PropTypes.string.isRequired
};

ImageErrorView.defaultProps = {
  subTitle: ''
};

export default ImageErrorView;
