import React from 'react';
import { View, Text, Image, StyleSheet } from 'react-native';
import isEmpty from 'lodash/isEmpty';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';
import Button from '../../../../../Common/Button';
import { FLT_BOOKING_ICON } from '../../../../FlightImageConstants';
import { getFont } from '../../../../../PostSalesStyles';

class ExchangePNRCard extends React.Component {
  render() {
    const { fsStyle, ...fonts } = getFont(true);
    const { title, description, exchangedPnrList } = this.props.exchangeBookingCardInfo;
    return (
      <View style={[styles.CardWrapper, AtomicCss.marginBottom10]}>
        <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
          <Image
            style={[styles.bookingFltIconStyle, styles.headerIconStyle]}
            source={FLT_BOOKING_ICON}
          />
          {title && (
            <Text
              style={[
                fonts.blackFontFamily,
                AtomicCss.defaultText,
                fsStyle.font14,
                AtomicCss.marginBottom10,
              ]}
            >
              {title}
            </Text>
          )}
        </View>
        {description && (
          <Text
            style={[
              fonts.regularFontFamily,
              AtomicCss.defaultText,
              styles.headingTitleDesc,
              fsStyle.font14,
            ]}
          >
            {description}
          </Text>
        )}
        {!isEmpty(exchangedPnrList) &&
          exchangedPnrList.map((exchangePnrItem) => {
            const { newBookingId, ctaList } = exchangePnrItem;
            return (
              <View style={[AtomicCss.marginTop15, AtomicCss.marginBottom10, styles.InnerWrapper]}>
                <View>
                  <View style={[AtomicCss.flexRow, AtomicCss.marginBottom16]}>
                    <View style={styles.imgPlaceHodler}>
                      <Image style={styles.bookingFltIconStyle} source={FLT_BOOKING_ICON} />
                    </View>
                    <View style={AtomicCss.flex1}>
                      {exchangePnrItem.description && (
                        <Text
                          style={[
                            fsStyle.font14,
                            AtomicCss.lineHeight20,
                            fonts.boldFontFamily,
                            AtomicCss.blackText,
                          ]}
                        >
                          {exchangePnrItem.description}
                        </Text>
                      )}
                    </View>
                  </View>
                  {!isEmpty(ctaList) &&
                    !isEmpty(newBookingId) &&
                    ctaList[0].actionType === 'NEW_FLIGHT_DETAILS' && (
                      <View style={styles.btnWrapper}>
                        <Button
                          btnTxt={ctaList[0].actionText}
                          btnWidth={200}
                          btnBg="gradientBtn"
                          clickHandler={() => this.props.btnClickListener(newBookingId)}
                          buttonTextCss={fsStyle.font14}
                        />
                      </View>
                    )}
                </View>
              </View>
            );
          })}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  CardWrapper: {
    padding: 16,
    ...getPlatformElevation(2),
    backgroundColor: '#fff',
  },
  imgPlaceHodler: {
    width: 24,
    height: 24,
    marginRight: 10,
    marginTop: 2,
  },
  bookingFltIconStyle: {
    width: 24,
    height: 24,
  },
  rtArrorStyle: {
    width: 9,
    height: 11,
    marginHorizontal: 6,
  },

  InnerWrapper: {
    padding: 16,
    ...getPlatformElevation(2),
    borderRadius: 4,
    backgroundColor: '#fff',
  },
  btnWrapper: {
    paddingLeft: 30,
  },
  headerIconStyle: {
    top: -4,
    marginRight: 10,
  },
  headingTitleDesc: {
    paddingLeft: 35,
    lineHeight: 20,
  },
});

export default ExchangePNRCard;
