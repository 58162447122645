import React, { useContext, useState } from 'react';
import { Image, Platform, Text, TextInput, TouchableOpacity, View } from 'react-native';
import { getFont, ps_colors } from '../../../PostSalesStyles';
import { ThemeContext } from '../../../theme/theme.context';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import createStyle from './styles';
import AddBookingTrackingHelper, { ADD_BOOKING_TRACKING_EVENTS } from './tracking';

const BookingIdCard = ({ bookingValue, handleBookingValue, errorText, uuid, textData }) => {
  const [activeInput, setActiveInput] = useState(false);
  const [tooltip, setTooltip] = useState(false);

  const { psTheme: theme } = useContext(ThemeContext);
  const fonts = getFont(true);
  const styles = createStyle(theme, fonts);

  const {
    bookingIDField,
    bookingIDFieldInfo,
    bookingIDFieldInfoCloseImageURL,
    bookingIDFieldInfoPointerImageURL,
    bookingIDPlaceholder,
    bookingIDFieldInfoImageURL,
  } = textData || {};

  const onFocusSearch = () => {
    AddBookingTrackingHelper.trackClickEvent(
      ADD_BOOKING_TRACKING_EVENTS.Addbooking_sheet_searchbox_clicked,
      uuid,
    );
    setActiveInput(true);
  };
  const onBlurSearch = () => {
    setActiveInput(false);
  };
  const handleTooltip = () => {
    if (!tooltip) {
      AddBookingTrackingHelper.trackClickEvent(
        ADD_BOOKING_TRACKING_EVENTS.Addbooking_sheet_info_clicked,
        uuid,
      );
    }
    setTooltip(!tooltip);
  };

  let bookingInputStyle = [
    styles.bookingInput,
    AtomicCss.borderRadius4,
    AtomicCss.paddingHz15,
  ];

  if (activeInput) {
    bookingInputStyle = [...bookingInputStyle, styles.activeStyle];
  }
  if (errorText) {
    bookingInputStyle = [...bookingInputStyle, styles.errorStyle];
  }
  return (
    <View style={AtomicCss.makeRelative}>
      <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom10]}>
        {!!bookingIDField && (
          <Text style={[styles.bookingIdField, AtomicCss.defaultText, AtomicCss.marginRight10]}>
            {bookingIDField}
          </Text>
        )}
        <TouchableOpacity onPress={handleTooltip}>
          {!!bookingIDFieldInfoImageURL && (
            <Image source={{ uri: bookingIDFieldInfoImageURL }} style={styles.infoIcon} />
          )}
        </TouchableOpacity>
      </View>
      {tooltip && (
        <View
          style={[
            styles.tooltipWrapper,
            AtomicCss.flexRow,
            AtomicCss.spaceBetween,
            AtomicCss.alignCenter,
            AtomicCss.borderRadius4,
            AtomicCss.paddingAll8,
          ]}
        >
          <View style={styles.arrowWrapper}>
            {!!bookingIDFieldInfoPointerImageURL && (
              <Image
                style={styles.arrowStyle}
                source={{ uri: bookingIDFieldInfoPointerImageURL }}
              />
            )}
          </View>
          {!!bookingIDFieldInfo && (
            <Text style={styles.notificationInfoTxt}>{bookingIDFieldInfo}</Text>
          )}
          <TouchableOpacity
            style={[styles.crossIconWrapper, AtomicCss.alignCenter]}
            onPress={handleTooltip}
          >
            {!!bookingIDFieldInfoCloseImageURL && (
              <Image
                style={styles.crossIconWhite}
                source={{ uri: bookingIDFieldInfoCloseImageURL }}
              />
            )}
          </TouchableOpacity>
        </View>
      )}
      <TextInput
        underlineColorAndroid="transparent"
        style={bookingInputStyle}
        onChangeText={handleBookingValue}
        onFocus={onFocusSearch}
        onBlur={onBlurSearch}
        value={bookingValue}
        placeholder={bookingIDPlaceholder || ''}
        placeholderTextColor={ps_colors.lightTextColor}
        keyboardType={Platform.OS === 'ios' ? null : 'visible-password'}
      />
      <Text style={styles.errorTextStyle}>{errorText || ' '}</Text>
    </View>
  );
};

export default BookingIdCard;
