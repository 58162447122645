import { NativeModules } from 'react-native';
import { getRequestHeader } from './NetworkModule';
import { appendURLParams, appendUrlPrefix } from '../webUtils';
import customRouter from '../router/RouterCustomStack';
import { defaultURLParams } from '../router/routerFluxMock';
let hotelBookingDetail;

const getThankYouURL = (bookingId) => {
    let origin = window.location.origin;
    if (origin.indexOf("supportz.mmt.com") != -1) {
        return "http://supportz.mmt.com/Mima/PaymentInfo?channel=pwa_mima&bookingId=" + bookingId;
    }
    else {
        return "https://supportz.makemytrip.com/mweb/hotelPaymentInfo?channel=pwa_mima&bookingId=" + bookingId;
    }
}

const getHotelDateChangeStaticData = (bookingId) => {
    const hotelStaticData = {};
    hotelStaticData.appVersion = '5.6';
    hotelStaticData.exprimentData = "{\"PAH5\":\"t\",\"PAHX\":\"t\",\"HDVM\":\"f\",\"BNPL\":\"t\",\"LVD\":\"\",\"PAHP\":\"f\",\"PAH\":\"5\",\"ADDV\":\"f\",\"PAV\":\"3\",\"NPDTL\":\"t\",\"HP\":\"t\",\"SPTD\":\"t\",\"PAV_I\":\"3\",\"REV\":\"4\",\"ADDON\":\"t\",\"PDO\":\"PN\",\"APE\":\"14\",\"HBH\":\"f\",\"MRS\":\"t\",\"LVI\":\"\",\"EXL\":\"\"}";
    hotelStaticData.deviceId = 'bfd623b3076e0843';
    hotelStaticData.visitorId = '2DC3E6A1853152F0-4000012B6000B6CE';
    hotelStaticData.platformSource = 'msite';
    hotelStaticData.bookingDevice = 'msite';
    hotelStaticData.thankYouURL = getThankYouURL(bookingId);

    return hotelStaticData;
}

const setHotelBookingResponse = (stringfiedJson) =>{
    hotelBookingDetail = stringfiedJson;
}

const getHotelBookingDetailsResponse = () => {
    return hotelBookingDetail;
}

const getHotelFeedbackDate = () => {
    return new Promise((resolve) =>  resolve(null));
}

const invalidateTripDetails = () => {

}

const resetToHotelBookingDetail = (bookingId) => {
    const url = appendURLParams(appendUrlPrefix('/hotel'), { bookingId }, defaultURLParams);
    customRouter.popToRoute(url);
}

NativeModules.HotelBookingModule = {
    getRequestHeader,
    getHotelDateChangeStaticData,
    setHotelBookingResponse,
    getHotelBookingDetailsResponse,
    getHotelFeedbackDate,
    invalidateTripDetails,
    resetToHotelBookingDetail
}
