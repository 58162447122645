import React from 'react';
import {View, Text, TouchableOpacity} from 'react-native';
import PropTypes from 'prop-types';
import styles from './RtDtErrOverlayCss';
import { getStaticData } from 'apps/post-sales/src/staticData/staticData';



export default class RtDtErrOverlay extends React.Component {
  
  render() {
    const staticData = getStaticData()
    const {datesNotCompatible,departureShouldBeAfter,closeBtnText} = staticData;
    return (
      <View>
        <Text style={styles.contentTitle}>{datesNotCompatible}</Text>
        <Text style={styles.contentDesc}>{departureShouldBeAfter}</Text>
        <View style={styles.contentFooter}>
          <TouchableOpacity style={styles.actionBtn} onPress={() => this.props.CloseOverlay()}>
            <Text style={styles.actionBtnText}>{closeBtnText}</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }
}

RtDtErrOverlay.propTypes = {
  CloseOverlay: PropTypes.func.isRequired,
  continueOverlay: PropTypes.func.isRequired
};

