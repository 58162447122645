import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { StyleSheet } from 'react-native';

export const getStyle = (fonts, psTheme) => {
  const { color } = psTheme;
  return StyleSheet.create({
    container: {
      padding: 20,
    },
    title: {
      fontSize: fonts.fontSize.font24,
      color: color.black,
      ...fonts.boldFontFamily,
      ...AtomicCss.marginBottom16,
    },
    barStyles: {
      height: 4,
      width: 30,
      borderWidth: 2,
      borderColor: color.azure,
      ...fonts.boldFontFamily,
      ...AtomicCss.marginBottom16,
    },
    ctaTextWrap: {
      ...AtomicCss.alignCenter,
    },
    ctaText: {
      fontSize: fonts.fontSize.font16,
      color: color.azure,
      ...fonts.boldFontFamily,
    },
  });
};

export const getHtmlStyles = (fonts, psTheme) => {
    const {color} = psTheme;
    return StyleSheet.create({
        div : {
            fontSize: fonts.fontSize.font16,
            color: color.black,
            ...fonts.regularFontFamily,
            ...AtomicCss.marginBottom60,
        }
    })
}