import React from 'react';
import {View, Text, Image, StyleSheet} from 'react-native';
import AtomicCss from './commonStyles/AtomicCss';
import {BLUE_DROP_ICON_DOWN, BLUE_DROP_ICON_UP} from '../../Utils/RailImageConstant';
import { getFont } from '../../../PostSalesStyles';

class FaqsText extends React.Component {
    static navigationOptions = {
      header: null
    };

    render() {
      const { fsStyle, ...fonts } = getFont(true);
      return (
        <View style={[styles.CollapsedTapContainer1, AtomicCss.alignCenter]}>
          <View style={styles.CollapsedTapLeftSection}>
            <Text style={[fsStyle.font14, AtomicCss.azure, fonts.boldFontFamily]}>
            {this.props.item.accordianHeading}
            </Text>
          </View>
          <View style={[styles.CollapsedTapRightSection, AtomicCss.marginTop10]}>
            <Image style={[styles.AlignSelfRight, styles.blueArrow, AtomicCss.marginBottom10]} source={this.props.tabbing? BLUE_DROP_ICON_UP:BLUE_DROP_ICON_DOWN } />
          </View>
        </View>

      );
    }
}

const styles = StyleSheet.create({
  CollapsedTapContainer1: {
    backgroundColor: 'transparent',
    paddingHorizontal: 20,
    paddingVertical: 18,
    flexDirection: 'row'
  },
  CollapsedTapLeftSection: {
    width: '70%'
  },
  CollapsedTapRightSection: {
    width: '30%'
  },
  blueArrow: {
    width: 13,
    height: 7
  },
  AlignSelfRight: {
    alignSelf: 'flex-end'
  }
});

export default FaqsText;
