import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { View, StyleSheet, TouchableWithoutFeedback, Platform } from 'react-native';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';

const ModalWeb = ({ onRequestClose, children, visible }) => {
  const backButtonPrevented = useRef(false);
  const onTouchOutside = (event = null) => {
    if (Platform.OS === 'web' && event) {
      event.preventDefault();
    }
    window.history.back();
    onRequestClose();
  };
  useEffect(() => {
    window.history.pushState({}, null, window.location.href);
    window.onpopstate = () => {
      onRequestClose();
      return;
    };
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    return () => {
      backButtonPrevented.current = true;
      window.onpopstate = () => {
        return;
      };
      document.getElementsByTagName('body')[0].style.overflow = 'auto';
    };
  }, []);
  const el = document.getElementsByTagName('body')[0];

  const renderModalContent = () => (
    <View style={styles.container}>
      <TouchableWithoutFeedback style={{ flex: 1 }} onPress={onTouchOutside}>
        <View style={{ flex: 1 }}>
          <TouchableWithoutFeedback onPress={() => {}}>
            <View style={[styles.contentContainer, { height: '100%' }]}>{children}</View>
          </TouchableWithoutFeedback>
        </View>
      </TouchableWithoutFeedback>
    </View>
  );

  if (!visible) {
    return null;
  }

  return ReactDOM.createPortal(renderModalContent(), el);
};

const styles = StyleSheet.create({
  container: {
    position: 'fixed',
    top: 1,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: 'rgba(0,0,0,0.7)',
    justifyContent: 'flex-end',
  },
  backgroundWrapper: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  contentContainer: {
    backgroundColor: colors.white,
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
});

export default ModalWeb;
