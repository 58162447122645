// @flow
import FlightBookingModule from '@mmt/legacy-commons/Native/FlightBookingModule';
import TgsBookingModule from '@mmt/legacy-commons/Native/TgsBookingModule';
import TMInsuranceModule from '@mmt/legacy-commons/Native/TMInsuranceModule';
import Actions from '../../navigation/postSalesNavigation';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import { BackHandler, Platform } from 'react-native';
import ViewControllerModule from '@mmt/legacy-commons/Native/ViewControllerModule';
import TripSummaryOmnitureTracker from '../tracking/TripSummaryOmnitureTracker';
import { HOLIDAY_PLANNING_VIEW_LISTING_URL } from '../../utils/NetworkUtils';
import PostSalesVernac from '../../PostSalesVernac';
import { getRootTag } from '@mmt/legacy-commons/AppState/RootTagHolder';

const myTripsNavigation = {
  exitApp() {

    // simulate hardware back press
    const somethingPoped = Actions.pop();
    if (somethingPoped != null && !somethingPoped) {
      if (Platform.OS === 'ios') {
        ViewControllerModule.popViewController(getRootTag());
      } else {
        BackHandler.exitApp();
      }
    }
  },
  createBookingDetailsMap(pageName, bookingId, pageData = {}) {
    PostSalesVernac.init();
    const map = {};
    map.BOOKING_ID = bookingId;
    map.pageData = JSON.stringify(pageData);
    if (pageName === 'visaBookingDetail') {
      map.data = bookingId;
    } else {
      map.data = {};
      //This is done as some of lobs get data like props.data.keyName
      map.data.BOOKING_ID = bookingId;
      map.data.pageData = JSON.stringify(pageData);
    }
    map.page = pageName;
    return map;
  },

  openBusBookingDetail(response, pageData = {}, trackClickEvent = true) {
    PostSalesVernac.init();
    const { bookingID: bookingId, uuid } = response;
    const pageInfo = { ...pageData, uuid };
    if (Platform.OS === 'android' || Platform.OS === 'web') {
      FlightBookingModule.openBusBookingDetail(bookingId, pageInfo);
    } else {
      FlightBookingModule.openBookingDetails(
        this.createBookingDetailsMap('busBookingDetail', bookingId, pageInfo),
      );
    }
    trackClickEvent &&
      TripSummaryOmnitureTracker.trackBookingClickEvent(
        'bus',
        response.status,
        bookingId,
        getClickEvent(pageData),
      );
  },
  openTripGuaranteeBookingDetail(response, pageData = {}, trackClickEvent = true) {
    PostSalesVernac.init();
    const { bookingID: bookingId } = response;
    if (Platform.OS === 'android' || Platform.OS === 'web') {
      TgsBookingModule.openTripGuaranteeBookingDetail(bookingId, pageData);
    } else {
      FlightBookingModule.openBookingDetails(
        this.createBookingDetailsMap('tripGuaranteeBookingDetail', bookingId, pageData),
      );
    }
    trackClickEvent &&
      TripSummaryOmnitureTracker.trackBookingClickEvent(
        'tgs',
        response.status,
        bookingId,
        getClickEvent(pageData),
      );
  },
  openInsuranceBookingDetail(response,pageData = {}, trackClickEvent = true ) {
    PostSalesVernac.init();
    const { bookingID: bookingId } = response;
    console.log('inside');
    if (Platform.OS === 'android' || Platform.OS === 'web') {
      console.log(TMInsuranceModule,'===tminsurance');
      TMInsuranceModule.openTMInsuranceBookingDetail(bookingId, pageData);
    } else {
      FlightBookingModule.openBookingDetails(
        this.createBookingDetailsMap('tmInsuranceBookingDetail', bookingId, pageData),
      );
    }
    trackClickEvent &&
      TripSummaryOmnitureTracker.trackBookingClickEvent(
        'insurance',
        response.status,
        bookingId,
        getClickEvent(pageData),
      );
  },
  openCabBookingDetail(response, pageData = {}, trackClickEvent = true) {
    PostSalesVernac.init();
    const { bookingID: bookingId, uuid } = response;
    const pageInfo = { ...pageData, uuid };
    if (Platform.OS === 'android' || Platform.OS === 'web') {
      FlightBookingModule.openCabBookingDetail(bookingId, pageInfo);
    } else {
      FlightBookingModule.openBookingDetails(
        this.createBookingDetailsMap('cabBookingDetail', bookingId, pageInfo),
      );
    }
    trackClickEvent &&
      TripSummaryOmnitureTracker.trackBookingClickEvent(
        'cab',
        response.status,
        bookingId,
        getClickEvent(pageData),
      );
  },
  openRailBookingDetail(response, pageData = {}, trackClickEvent = true) {
    PostSalesVernac.init();
    const { bookingID: bookingId, uuid } = response;
    const pageInfo = { ...pageData, uuid }
    if (Platform.OS === 'android' || Platform.OS === 'web') {
      FlightBookingModule.openRailBookingDetail(bookingId, pageInfo);
    } else {
      FlightBookingModule.openBookingDetails(
        this.createBookingDetailsMap('railBookingDetail', bookingId, pageInfo),
      );
    }
    trackClickEvent &&
      TripSummaryOmnitureTracker.trackBookingClickEvent(
        'rail',
        response.status,
        bookingId,
        getClickEvent(pageInfo),
      );
  },
  openVisaBookingDetail(response, pageData = {}, trackClickEvent = true) {
    PostSalesVernac.init();
    const { bookingID: bookingId } = response;
    if (Platform.OS === 'android' || Platform.OS === 'web') {
      FlightBookingModule.openVisaBookingDetail(bookingId, pageData);
    } else {
      FlightBookingModule.openBookingDetails(
        this.createBookingDetailsMap('visaBookingDetail', bookingId, pageData),
      );
    }
    trackClickEvent &&
      TripSummaryOmnitureTracker.trackBookingClickEvent(
        'visa',
        response.status,
        bookingId,
        getClickEvent(pageData),
      );
  },
  openHotelBookingDetail(response, pageData = {}, trackClickEvent = true) {
    PostSalesVernac.init();
    const { bookingID: bookingId, uuid } = response;
    const pageInfo = { ...pageData, uuid }
    if (Platform.OS === 'android' || Platform.OS === 'web') {
      FlightBookingModule.openHotelBookingDetail(bookingId, JSON.stringify(response), pageInfo);
    } else {
      FlightBookingModule.openBookingDetails(
        this.createBookingDetailsMap('hotelBookingDetail', bookingId, pageInfo),
      );
    }
    const bookingState = `${response.status}${response.isBNPLBooking? '_BNPL':''}`
    trackClickEvent &&
      TripSummaryOmnitureTracker.trackBookingClickEvent(
        'hotel',
        response.status,
        bookingId,
        getClickEvent(pageInfo),
        {m_v14: bookingState}
      );
  },
  openHolidayBookingDetail(response, pageData = {}, trackClickEvent = true) {
    PostSalesVernac.init();
    const { bookingID: bookingId } = response;
    if (Platform.OS === 'android' || Platform.OS === 'web') {
      FlightBookingModule.openHolidayBookingDetail(bookingId, response.phoneNumber, pageData);
    } else {
      FlightBookingModule.openBookingDetails(
        this.createBookingDetailsMap('holidayBookingDetail', bookingId, pageData),
      );
    }
    trackClickEvent &&
      TripSummaryOmnitureTracker.trackBookingClickEvent(
        'holiday',
        response.status,
        bookingId,
        'View_Booking',
      );
  },
  openFlightBookingDetail(response, trackClickEvent = true, pageData = {}) {
    PostSalesVernac.init();
    const { bookingID: bookingId, uuid } = response;
    const pageInfo = { ...pageData, uuid }
    if (Platform.OS === 'android' || Platform.OS === 'web') {
      FlightBookingModule.openFlightBookingDetail(bookingId, pageInfo);
    } else {
      FlightBookingModule.openBookingDetails(
        this.createBookingDetailsMap('flightBookingDetail', bookingId, pageInfo),
      );
    }
    if (trackClickEvent) {
      TripSummaryOmnitureTracker.trackBookingClickEvent(
        'flight',
        response.status,
        bookingId,
        getClickEvent(pageInfo),
      );
    }
  },
  openMetroBookingDetail(response, pageData = {}, trackClickEvent = true) {
    PostSalesVernac.init();
    const { bookingID: bookingId } = response;
    if (Platform.OS === 'android' || Platform.OS === 'web') {
      FlightBookingModule.openMetroBookingDetail(bookingId, pageData);
    } else {
      FlightBookingModule.openBookingDetails(
        this.createBookingDetailsMap('metroBookingDetail', bookingId, pageData),
      );
    }
    trackClickEvent &&
      TripSummaryOmnitureTracker.trackBookingClickEvent(
        'metro',
        response.status,
        bookingId,
        getClickEvent(pageData),
      );
  },
  openActivityBookingDetails(response, pageData = {}, trackClickEvent = true) {
    PostSalesVernac.init();
    const { bookingID: bookingId } = response;
    if (Platform.OS === 'android' || Platform.OS === 'web') {
      FlightBookingModule.openAcmeBookingDetail(bookingId, pageData);
    } else {
      FlightBookingModule.openBookingDetails(
        this.createBookingDetailsMap('acmeBookingDetail', bookingId, pageData),
      );
    }
    trackClickEvent &&
      TripSummaryOmnitureTracker.trackBookingClickEvent(
        'activity',
        response.status,
        bookingId,
        getClickEvent(pageData),
      );
  },
  openSelfDriveBookingDetail(response, pageData = {}, trackClickEvent = true) {
    PostSalesVernac.init();
    const { bookingID: bookingId } = response;
    if (Platform.OS === 'android') {
      FlightBookingModule.openSelfDriveBookingDetail(bookingId, pageData);
    } else if (Platform.OS === 'web') {
      FlightBookingModule.openSelfDriveBookingDetail(bookingId, response, pageData);
    } else {
      FlightBookingModule.openBookingDetails(
        this.createBookingDetailsMap('selfDriveBookingDetail', bookingId, pageData),
      );
    }
    trackClickEvent &&
      TripSummaryOmnitureTracker.trackBookingClickEvent(
        'selfdrive',
        response.status,
        bookingId,
        getClickEvent(pageData),
      );
  },
  openGiftCardBookingDetail(response, pageData = {}, trackClickEvent = true) {
    PostSalesVernac.init();
    const { bookingID: bookingId } = response;
    if (Platform.OS === 'android') {
      FlightBookingModule.openGiftCardBookingDetail(bookingId, pageData);
    } else {
      FlightBookingModule.openBookingDetails(
        this.createBookingDetailsMap('giftCardBookingDetail', bookingId, pageData),
      );
    }
    trackClickEvent &&
      TripSummaryOmnitureTracker.trackBookingClickEvent(
        'giftcard',
        response.status,
        bookingId,
        getClickEvent(pageData),
      );
  },
  openTripIdeasDetail(url) {
    GenericModule.openDeepLink(url);
  },
  openHolidayPlanningCardBookingDetail(ticketId, tagDestination, trackClickEvent = true) {
    PostSalesVernac.init();
    GenericModule.openDeepLink(
      `${HOLIDAY_PLANNING_VIEW_LISTING_URL}?ticketId=${ticketId}&tagDestination=${
        tagDestination && tagDestination.replace(' ', '')
      }`,
    );
    trackClickEvent &&
      TripSummaryOmnitureTracker.trackBookingClickEvent(
        'holidayPlanning',
        '',
        ticketId,
        'pre_sales_view_detail',
      );
  },
  openHolidayEditPlannningDetail() {
    /*TODO : ADD EDIT PLANNING DETAILS OPTION */
    console.log('Open Edit Planning Booking Details');
  },
};

function getClickEvent(data) {
  const { lostBooking, isPushedAsLostId } = data || {};
  return isPushedAsLostId
    ? 'tripdetail_lost_pushed'
    : lostBooking
    ? 'tripdetail_lost'
    : 'tripdetail_unsuccessful';
}

export default myTripsNavigation;
