import React from 'react';
import {Image, StyleSheet, Text, TextInput, TouchableOpacity, View} from 'react-native';
import backIcon from '@mmt/legacy-assets/src/trip_header_back_icon.webp';
import {fonts, colors} from '../../Styles/globalStyles.js';
import CommonStyles from '../../Styles/AtomicCss';
import PropTypes from 'prop-types';
import getPlatformElevation from './Card/getPlatformElevation';
import {labelsName} from '../../testingConfig/constant';

const CommonAutoCompleteHeader = ({
  onBackClick, onClear, fetchList, query, hint, hideClear, hideTextInput
}) => (
  <View style={[styles.searchContainer, hideTextInput ?'': CommonStyles.justifyCenter]}>
    <TouchableOpacity
      style={styles.backBtn}
      onPress={onBackClick}
      hitSlop={{
          top: 8, left: 8, right: 8, bottom: 8
      }}
    >
      <View>
        <Image style={styles.icon} source={backIcon} />
      </View>
    </TouchableOpacity>
    {hideTextInput? <Text style={styles.hintText}>{hint}</Text> : (<TextInput
      accessibilityLabel={labelsName.tTextBoxToSearch}
      testId={labelsName.tTextBoxToSearch}
      underlineColorAndroid="transparent"
      style={styles.textinput}
      value={query}
      autoFocus
      placeholder={hint}
      placeholderTextColor={colors.disabledButton}
      onChangeText={text => fetchList(text)}
    />)}
    {hideClear ? null : (<TouchableOpacity style={styles.backBtn} onPress={onClear}>
      <View>
        <Text style={styles.clearText}>CLEAR</Text>
      </View>
    </TouchableOpacity>)}
  </View>
);

CommonAutoCompleteHeader.propTypes = {
  query: PropTypes.string.isRequired,
  onBackClick: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  fetchList: PropTypes.func.isRequired,
  hint: PropTypes.string,
  hideTextInput: PropTypes.bool,
  hideClear: PropTypes.bool
};

CommonAutoCompleteHeader.defaultProps = {
  hint: '',
  hideTextInput:false,
  hideClear:false
};

const styles = StyleSheet.create({
  textinput: {
    flex: 1,
    padding: 0,
    color: colors.black,
    fontSize: 18,
    alignSelf: 'center',
    fontWeight: 'bold',
    paddingLeft: 0
  },
  searchContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    paddingVertical: 15,
    alignItems: 'center',
    backgroundColor: colors.white,
    ...getPlatformElevation(2)
  },
  clearText: {
    color: colors.black,
    fontSize: 10,
    fontFamily: fonts.bold,
    paddingRight: 15
  },
  icon: {
    width: 15,
    height: 15,
    marginHorizontal: 15
  },
  backBtn: {
    justifyContent: 'center',
    height: 32
  },
  hintText: {
    color: colors.black,
    fontSize: 20,
    paddingRight: 15
  },
});

export default CommonAutoCompleteHeader;

