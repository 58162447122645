import { StyleSheet } from "react-native";
import { DEFAULT_THEME } from "apps/post-sales/src/theme/default.theme";


export default <T extends typeof DEFAULT_THEME>(theme: T, fonts: any) => StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    noFilteredBookingsText: {
        fontSize: fonts.fontSize.font18,
        ...fonts.blackFontFamily,
        color: theme.color.black,
        textAlign: 'center',
    },
    headerText: {
        fontSize: fonts.fontSize.font18,
        ...fonts.blackFontFamily,
        color: theme.color.black
    },
    subHeadingText: {
        fontSize: fonts.fontSize.font14,
        ...fonts.regularFontFamily,
        color: theme.color.black
    },
});