const { StyleSheet } = require('react-native');

const getStyles = (color, fonts) => {
  return StyleSheet.create({
    container: {
      paddingHorizontal: 20,
      paddingTop: 20,
      paddingBottom: 40,
      borderRadius:20,
      marginBottom: -30,
      backgroundColor: color.black
    },
    heading: {
      fontSize: fonts.fontSize.font16,
      color: color.white,
      ...fonts.regularFontFamily,
    },
    btnWrapper: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 12,
    },
    buttonView: {
      flex: 1,
      margin: 5,
    },
    cancelButtonStyle: {
      borderRadius: 4,
      paddingHorizontal: 20,
      paddingVertical: 10,
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'center',
      borderWidth: 2,
      borderColor: color.azure,
    },
    cancelTextStyle: {
      fontSize: fonts.fontSize.font12,
      ...fonts.boldFontFamily,
      textAlign: 'center',
      color: color.azure,
    },
    confirmButtonStyle: {
      borderRadius: 4,
      paddingHorizontal: 20,
      paddingVertical: 10,
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    confirmTextStyle: {
      fontSize: fonts.fontSize.font12,
      ...fonts.boldFontFamily,
      textAlign: 'center',
      color: color.white,
    },
  });
};

export const htmlstyles = (color, fonts) =>  StyleSheet.create({
  p : {
    fontSize: fonts.fontSize.font16,
    color: color.white,
    ...fonts.regularFontFamily,
    textAlign: 'center'
  }
})
export default getStyles;
