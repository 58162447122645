import { StyleSheet } from "react-native";

export default (theme, fonts) =>StyleSheet.create({
    title :{
     ...(fonts.regularFontFamily),
     fontSize : 14, 
    },
    iconWrapper: {
        width: 26,
        height: 26,
        marginRight: 10,
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    contactIconStyle: {
        width: 24,
        height: 24,
    },
    detailsSection: {
        paddingLeft: 18,
        paddingRight: 0,
    },
    cusDetailsWrapper: {
        paddingHorizontal: 10,
        paddingVertical: 6,
        borderRadius: 4,
        borderWidth: 1,
        borderColor: theme.color.lightGray,
        minWidth: '40%',
    },
    heading:{
        ...(fonts.blackFontFamily)
    }
})




