import { holidaysIcon } from 'apps/post-sales/src/Holiday/HolidayBookingConstants';
import myTripsNavigation from '../../../../navigation/navigation';
import { getMyRequestCardData, handleCTAClick } from '../../../utils';
import { HolidaysPlanningData, HolidaysPlanningDataProps, MsrTicket } from '../cardInterfaces';

export function getHolidayPlanningSummaryData(data: HolidaysPlanningData, msrTicket: MsrTicket): HolidaysPlanningDataProps {

  const { ctaInfoList: ctaDetails } = data && data.bookingStateLayout || {};
  const { startsOnText, startDate, status, noOfTravellers, title, ticketId, tagDestination, startingFrom } = data || {};
  const openDetails = () => {
    myTripsNavigation.openHolidayPlanningCardBookingDetail(ticketId, tagDestination);
  };
  const actions: Array<{ label: string; onPress: () => void }> = [];

  if (ctaDetails && ctaDetails[0]) {
    actions.push({ label: ctaDetails[0]?.text,  onPress: () => handleCTAClick(ctaDetails[0], data) })
  };
  if (ctaDetails && ctaDetails[1]) {
    actions.push({ label: ctaDetails[1]?.text,  onPress: () => handleCTAClick(ctaDetails[1], data) })
  };

  return {
    status,
    iconUrl: holidaysIcon,
    packageName: title,
    actions,
    passengerDetails: noOfTravellers,
    myRequestsCard : getMyRequestCardData(msrTicket),
    startingFrom,
    startsOnText,
    startDate,
    openDetails,
  };
}

export default {};