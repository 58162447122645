import React from 'react';
import { ScrollView, View } from 'react-native';
import Header from '../../../Common/HeaderCross';
import createStyles from "./priceBreakupCss";
import { getFont } from '../../../PostSalesStyles';
import { getStaticData } from '../../../staticData/staticData';
import { useTheme } from '../../../theme/theme.context';
import FareBreakup from './components/FareBreakup';
import { backPressHandler } from '../../../utils/PostSaleUtil';

const PaymentPriceBreakup = ({bookingDetailsResponse}) => {
    const staticData = getStaticData();
    const { paymentDetailsText } = staticData;
    const fonts = getFont();
    const {psTheme : theme} = useTheme();
    const styles = createStyles(theme,fonts);

    const goBackToDetails = () => {
        backPressHandler();
    }
    return (
        <View style={styles.PriceBreakupWrapper}>
            <Header
                title={paymentDetailsText}
                headerShadow
                headingTxt="font18"
                handleClose={goBackToDetails}
            />
            <ScrollView style={{ marginTop: 10 }}>
                <FareBreakup bookingDetailsResponse={bookingDetailsResponse}/>
            </ScrollView>
        </View>
    )
}

export default PaymentPriceBreakup;