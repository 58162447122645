import React from 'react';
import PropTypes from 'prop-types';
import {Image, Text, TouchableOpacity, View} from 'react-native';
import { imageTransformY } from '../../utils/PostSaleUtil';
import { getFont } from '../../PostSalesStyles';
import createStyles from "./indexCss";
import { useTheme } from '../../theme/theme.context';


const RectFullButton = ({btnText, clickHandler,fontFamily,showBottomBorder,showTopBorder,icon,btnStyle={}}) => {
    const fonts = getFont()
    const {psTheme : theme} = useTheme()
    const styles = createStyles(theme,fonts);
    const btnStyles = [styles.btn,btnStyle]
  return(<TouchableOpacity onPress={clickHandler}>
    <View style={[btnStyles,showBottomBorder ? styles.borderBottomStyle : null,showTopBorder ? styles.borderTopStyle : null]}>
      <Text style={[styles.btnText,fontFamily]}>{btnText}</Text>
      {!!icon &&<Image style={[styles.RecFullBtnArrow, imageTransformY]} source={icon}/>}
    </View>
  </TouchableOpacity>)
}


RectFullButton.propTypes = {
  btnText: PropTypes.string.isRequired,
  clickHandler: PropTypes.func.isRequired
};

export default RectFullButton;
