import { StyleSheet, Platform } from 'react-native';

const styles = StyleSheet.create({
  overlayWrapper: {
    position:'absolute',
    top:0,
    left:0,
    height: '100%',
    width: '100%',
    elevation: 10,
    zIndex: 100,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    flexDirection: 'row',
  },
  overlay: {
    position:'absolute',
    top:0,
    left:0,
    backgroundColor: 'rgba(0,0,0,0.5)',
    height: '100%',
    width: '100%',
    zIndex: 1,
  },
  overlayContent: {
    position: 'relative',
    zIndex: 1,
    width: '100%',
    maxHeight: '85%',
    padding: 0,
    backgroundColor: 'white',
    ...Platform.select({
      web: {
        overflowY: 'visible',
      },
    }),
  },
  roundBorder:{
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
  overflowVisible: {
    ...Platform.select({
      web: {
        overflowX: "visible",
      },
    }),
  },
  overflowScroll: {
    ...Platform.select({
      web: {
        overflowX: "scroll",
      },
    }),
  },
});

export default styles;
