import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';

const styles = {
  whiteCard: {
    backgroundColor: '#ffffff',
    ...getPlatformElevation(2),
    marginBottom: 3,
    padding: 12,
  },
  pageWrapper: {
    flex: 1,
    backgroundColor: '#f2f2f2',
  },
  laggageIconStyle: {
    width: 24,
    height: 24,
    marginRight: 15,
  },
  logoIconStyle: {
    width: 55,
    height: 24,
  },
  cardList: {
    backgroundColor: '#ffffff',
    ...getPlatformElevation(1),
    padding: 12,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: '#f2f2f2',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  RedirectImgStyle: {
    width: 9,
    height: 13,
  },
  bullet: {
    width: 4,
    height: 4,
    backgroundColor: '#9b9b9b',
    borderRadius: 4,
    marginRight: 5,
    top: 5,
  },
  dot: {
    marginHorizontal: 5,
    lineHeight: 10,
  },
  cardListdisabled: {
    backgroundColor: '#f2f2f2',
    opacity: 0.4,
  },
  linkWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: 12,
  },
};

export default styles;
