import React from 'react';
import { View, Text, Image } from 'react-native';
import { getFont } from '../../../PostSalesStyles';
import { useTheme } from '../../../theme/theme.context';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import createStyles from './PayableTagCss';
import { INFO_ICON } from '../../BusImageConstants';
const arrow = ['', '', '', '', '', '', '', ''];
function PayableTag({ tag, borderBottomColor = '#FFEDD1' }) {
  const { subHeading, heading } = tag || {};
  const { fsStyle, ...fonts } = getFont();
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);
  return (
    <View style={[styles.payableWrapper]}>
      <View style={styles.topWrapper}>
        {!!heading && (
          <Text
            style={[fsStyle.font8, fonts.boldFontFamily, AtomicCss.whiteText, AtomicCss.textCenter]}
          >
            {heading}
          </Text>
        )}
      </View>
      <View style={styles.bottomWrapper}>
        <Text
          style={[
            fsStyle.font15,
            AtomicCss.blackText,
            fonts.blackFontFamily,
            AtomicCss.lineHeight18,
            AtomicCss.marginRight10,
          ]}
        >
          {subHeading}
        </Text>
        <Image source={INFO_ICON} style={styles.infoIcon} />
      </View>
      <View style={styles.arrowWrapper}>
        {arrow.map(() => {
          return (
            <View style={[styles.borderArrow, { borderBottomColor: borderBottomColor }]}></View>
          );
        })}
      </View>
    </View>
  );
}

export default PayableTag;
