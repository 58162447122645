/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import Actions from '../../navigation/postSalesNavigation';
import CustomSafeAreaView from '@mmt/legacy-commons/Common/Components/CustomSafeAreaView';
import { Platform, ScrollView, StyleSheet, Text, View } from 'react-native';
import StickyHeaderBack from '../components/StickyHeader/StickyHeaderBack';
import ReviewStepHeader2 from '../components/ReviewStepHeader/ReviewStepHeader2';
import AtomicCss from '../commonStyles/AtomicCss';
import RecBlueBtn from '../components/buttons/RecBlueBtn';
import CheckboxRow from '../components/CheckBoxRow/CheckBoxRow';
import OverlayAlert from '../components/OverlayAlert/OverlayAlert';
import { cancellablePaxList } from '../utils/BusBookingDetailUtil';
import BasePage from '../../Common/PostSalesBasePage';
import BusBookingTrackingHelper from '../BusBookingTrackingHelper';
import Toast from '@mmt/legacy-commons/Common/Components/Toast2';
import { label } from '@mmt/post-sales/src/staticData/staticData';
import { get } from 'lodash';
import { getFont } from '../../PostSalesStyles';

class SelectTraveller extends BasePage {
  static navigationOptions = {
    header: null,
  };

  constructor(props) {
    super(props, 'busCancellationSelectPax');
    this.bookingDetails = this.props.bookingDetails;
    this.passengers = this.props.passengers;
    this.selectedPax = [];
    this.state = {
      showPopup: false,
      errorText: '',
    };
  }

  onHardBackPress() {
    Actions.popTo('busBookingDetail');
    return true;
  }

  render() {
    const ispartcancellable = get(
      this,
      'bookingDetails.busBookingInfo.coachadditionaldetails[0].ispartcancellable',
      false,
    );
    const alertTitle = ispartcancellable
      ? label('bus.oopsSomethingWentWrong')
      : label('bus.cancellation.partCancelNotAllowed');
    const fonts = getFont(true);

    BusBookingTrackingHelper.trackLoadEvent('PaxSelection', this.bookingDetails);
    return (
      <View style={styles.CancellationWrapper}>
        {Platform.OS === 'ios' && <CustomSafeAreaView />}
        <StickyHeaderBack busDetails={this.bookingDetails ? this.bookingDetails : null} />
        <ScrollView style={AtomicCss.greyBg}>
          <ReviewStepHeader2 currentIndex={1} />
          <View style={[styles.PartCancellationHead, AtomicCss.elevation1]}>
            <Text style={[fonts.blackFontFamily, AtomicCss.blackText, fonts.font16]}>
              {label('bus.cancellation.selectTravellers')}
            </Text>
          </View>
          {this.paxViews()}
        </ScrollView>
        <RecBlueBtn
          btntext={label('bus.cancellation.reviewRefundCancelBtn')}
          onPressHandler={this.onPressHandler}
        />
        {Platform.OS === 'ios' && <CustomSafeAreaView positionTop={false} />}
        {this.state.showPopup && (
          <OverlayAlert
            positiveBtnText={label('bus.cancellation.yesCancel')}
            negativeBtnText={label('bus.cancellation.noGoBack')}
            positiveBtnOnTap={this.cancelBookingBtnTapped}
            negativeBtnOnTap={this.backButtonTapped}
            titleText={alertTitle}
            subTitleText={this.state.errorText}
            bgTapAction={this.hideErrorPopup}
          />
        )}
      </View>
    );
  }

  hideErrorPopup = () => {
    this.setState({
      showPopup: false,
    });
  };

  onError = (data) => {
    this.setState({
      showPopup: true,
      errorText: data.errortexts,
    });
  };

  backButtonTapped = () => {
    Actions.popTo('busBookingDetail');
    BusBookingTrackingHelper.trackClickEvents(
      'Cancellation Screen Shown',
      'Cancellation_NotConfirmed',
    );
  };

  cancelBookingBtnTapped = () => {
    Actions.busreviewRefundCharges({
      bookingDetails: this.props.bookingDetails,
      selectedPax: cancellablePaxList(this.props.bookingDetails),
      mmtAuth: this.props.mmtAuth,
      loggingTrackInfo: this.props.loggingTrackInfo,
      isFullCancellation: true,
      onError: (data) => {
        this.onError(data);
      },
    });
    BusBookingTrackingHelper.trackClickEvents(
      'Cancellation Screen Shown',
      'Cancellation_Confirmed',
    );
  };

  onPressHandler = () => {
    if (this.selectedPax && this.selectedPax.length > 0) {
      BusBookingTrackingHelper.trackClickEvent(
        'PaxSelection',
        this.bookingDetails,
        'ReviewRefundCalculationClicked',
      );

      Actions.busreviewRefundCharges({
        bookingDetails: this.props.bookingDetails,
        selectedPax: this.selectedPax,
        mmtAuth: this.props.mmtAuth,
        loggingTrackInfo: this.props.loggingTrackInfo,
        isFullCancellation: false,
        onError: (data) => {
          this.onError(data);
        },
      });
    } else {
      Toast.show(label('bus.cancellation.atleastOnePassengerSelect'));
    }
  };

  onPaxSelection = (passenger, isSelected) => {
    const index = this.selectedPax.findIndex((x) => x.lineno == passenger.lineno);
    if (isSelected) {
      if (index === -1) {
        this.selectedPax.push(passenger);
      }
    } else {
      this.selectedPax.splice(index, 1);
    }
  };

  paxViews = () => {
    const views = [];
    this.passengers.forEach((passenger) => {
      views.push(
        <CheckboxRow
          passenger={passenger}
          key={`${passenger.lineno}`}
          onPaxSelection={this.onPaxSelection}
        />,
      );
    });
    return views;
  };
}

SelectTraveller.propTypes = {
  bookingDetails: PropTypes.object.isRequired,
  passengers: PropTypes.array.isRequired,
  mmtAuth: PropTypes.string.isRequired,
  loggingTrackInfo: PropTypes.string.isRequired,
  showNoCancel: PropTypes.bool,
  errorText: PropTypes.string,
};

SelectTraveller.defaultProps = {
  showNoCancel: false,
  errorText: '',
};

const styles = StyleSheet.create({
  CancellationWrapper: {
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: '#f2f2f2',
  },
  PartCancellationHead: {
    backgroundColor: '#ffffff',
    paddingVertical: 18,
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
  },
  progressContainer: {
    flex: 1,
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default SelectTraveller;
