import React from 'react';
import { View, Text } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import createStyles from './ConfirmCancelOverlayCSS';
import isEmpty from 'lodash/isEmpty';
import { getFont } from '../../../PostSalesStyles';
import { useTheme } from '../../../theme/theme.context';
import BaseButton from '../../../Common/BaseButton';
function ConfirmCancelOverlay({ response, handleOverlay }) {
  const { cancellationCheckData } = response || {};
  const { heading1, heading2, subHeading, cta } = cancellationCheckData || {};
  const { fsStyle, ...fonts } = getFont();
  const {psTheme : theme} = useTheme();
  const styles = createStyles(theme, fonts);
  return (
    <View style={styles.OverlayContentWrapper}>
      <View style={AtomicCss.marginBottom24}>
        <View style={styles.overlayTopline}></View>
        {/* <Image src="" /> */}
        <View style={styles.headingWrapper}>
          {/* <Text>aasas</Text> */}
          {!!heading1 && (
            <Text style={[styles.headingTxt, AtomicCss.marginTop16]}>{heading1}</Text>
          )}
          {!!heading2 && (
            <Text style={[styles.headingTxt, AtomicCss.marginBottom18]}>{heading2}</Text>
          )}
          {!!subHeading && <Text>{subHeading}</Text>}
        </View>
      </View>
      {!isEmpty(cta) && (
        <View style={AtomicCss.flexColumn}>
          {cta.map((button, index) => {
            return (
              <View style={AtomicCss.marginBottom10 }>
              <BaseButton
                text={button.title}
                variant={button.buttonType}
                clickHandler={() => handleOverlay(button)}
              />
              </View>
            );
          })}
        </View>
      )}
    </View>
  );
}
export default ConfirmCancelOverlay;
