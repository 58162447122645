import React from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import isEmpty from 'lodash/isEmpty';
import HTMLView from 'react-native-htmlview';
import PropTypes from 'prop-types';
import Actions from '../../../../../../navigation/postSalesNavigation';
import styles from './DateChangeBottomOverlayCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {
  PAY_BY_CARD_ICON,
  AIRLINE_PHONE_BLUE_ICON,
  YELLOW_BG_INFO_ICON,
  BACK_WHITE_ICON,
} from '../../../../../FlightImageConstants';
import { getFont } from '../../../../../../PostSalesStyles';

export default class DateChangeBottomOverlay extends React.Component {
  render() {
    const { fsStyle, ...fonts } = getFont(true);
    const {
      title,
      description,
      paymentDescription,
      message,
      passengersRequestData: { heading, requestList },
    } = this.props.data;
    return (
      <View style={styles.bottomOverlayWrapper}>
        {!isEmpty(title) && (
          <Text
            style={[
              styles.headingText,
              AtomicCss.marginBottom15,
              fonts.boldFontFamily,
              fsStyle.font22,
            ]}
          >
            {title}
          </Text>
        )}
        {!isEmpty(paymentDescription) && (
          <View style={[AtomicCss.flexRow, AtomicCss.marginBottom10]}>
            <View style={styles.imgPlaceholder}>
              <Image style={styles.payIconStyle} source={PAY_BY_CARD_ICON} />
            </View>
            <Text style={[AtomicCss.defaultText, fonts.boldFontFamily, fsStyle.font14]}>
              {paymentDescription}
            </Text>
          </View>
        )}
        {!isEmpty(description) && (
          <View style={[AtomicCss.flexRow, AtomicCss.marginBottom20]}>
            <View style={styles.imgPlaceholder}>
              <Image style={styles.phoneIconStyle} source={AIRLINE_PHONE_BLUE_ICON} />
            </View>
            <Text style={[AtomicCss.defaultText, fonts.regularFontFamily, fsStyle.font14]}>
              {description}
            </Text>
          </View>
        )}
        {!isEmpty(heading) && (
          <Text
            style={[
              AtomicCss.greyText,
              fsStyle.font12,
              fonts.regularFontFamily,
              AtomicCss.marginBottom10,
            ]}
          >
            {heading}
          </Text>
        )}
        <View style={[styles.rcWrapper, AtomicCss.marginBottom15]}>
          {!isEmpty(requestList) &&
            requestList.map((item) => (
              <View style={styles.rcWrapperRow}>
                {!isEmpty(item.airlineImageURLMobile) && (
                  <Image style={styles.fltIconStyle} source={item.airlineImageURLMobile} />
                )}
                <View>
                  {!isEmpty(item.journeyIntent) && (
                    <Text
                      style={[
                        AtomicCss.blackText,
                        fonts.boldFontFamily,
                        fsStyle.font14,
                        AtomicCss.marginBottom10,
                        AtomicCss.marginRight16,
                      ]}
                    >
                      {item.journeyIntent}
                    </Text>
                  )}
                  {!isEmpty(item.services) &&
                    item.services.map((serviceItem) => (
                      <View
                        style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom8]}
                      >
                        <View style={styles.bullet} />
                        {!isEmpty(serviceItem.htmlText) && (
                          <HTMLView
                            style={AtomicCss.marginRight16}
                            value={serviceItem.htmlText}
                            onLinkPress={(url) =>
                              Actions.openPsWebView({
                                headerText: 'Information',
                                headerIcon: BACK_WHITE_ICON,
                                url,
                              })
                            }
                          />
                        )}
                        {!isEmpty(serviceItem.text) && isEmpty(serviceItem.htmlText) && (
                          <Text
                            style={[AtomicCss.blackText, fonts.regularFontFamily, fsStyle.font12]}
                          >
                            {serviceItem.text}
                          </Text>
                        )}
                      </View>
                    ))}
                </View>
              </View>
            ))}
        </View>
        {!isEmpty(message) && (
          <View style={[AtomicCss.flexRow, AtomicCss.marginBottom20]}>
            <Image style={styles.infoIconStyle} source={YELLOW_BG_INFO_ICON} />
            <Text style={[styles.infoTxt, fonts.regularFontFamily, fsStyle.font14]}>{message}</Text>
          </View>
        )}
        <TouchableOpacity
          style={styles.linkBtn}
          activeOpacity={0.4}
          onPress={() => this.props.handleBottomOverlay(' ')}
        >
          <Text style={[fonts.boldFontFamily, fsStyle.font16, AtomicCss.azure]}>
            {this.props.rightBtnTxt}
          </Text>
        </TouchableOpacity>
      </View>
    );
  }
}

DateChangeBottomOverlay.propTypes = {
  data: PropTypes.shape({
    tittle: PropTypes.string,
    description: PropTypes.string,
    paymentDescription: PropTypes.string,
    message: PropTypes.string,
    passengersRequestData: PropTypes.shape({
      heading: PropTypes.string,
      requestList: PropTypes.arrayOf(
        PropTypes.shape({
          airlineImageURLMobile: PropTypes.string,
          journeyIntent: PropTypes.string,
          services: PropTypes.arrayOf(
            PropTypes.shape({
              text: PropTypes.string,
              htmlText: PropTypes.string,
            }),
          ),
        }),
      ),
    }),
  }).isRequired,
};
