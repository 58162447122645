import React from 'react';
import { connect } from 'react-redux';
import { Text, View, TouchableOpacity, Image } from 'react-native';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import createStyles from '../css/RescheduleFareConfirmationOverlayCss';
import { REFUND_CALENDAR } from '../../../../../FlightImageConstants';
import { addSpaceWithAmount } from '../../../../../../utils/PostSaleUtil';
import BaseButton, { BUTTON_VARIANT } from '../../../../../../Common/BaseButton';
import { ThemeContext } from '../../../../../../theme/theme.context';
import { getStaticData } from '../../../../../../staticData/staticData';
import { getFont } from '../../../../../../PostSalesStyles';
import { onReviewAndRebook } from '../../../Redux/Listing/utils';
import PriceBreakUp from './PriceBreakup';
import { FLIGHT_THROTTLE_OBJECT } from '../../../../details/FlightBookingDetailsConstant';

const closeImg = require('@mmt/legacy-assets/src/close_icon.webp');

class RescheduleFareConfirmationOverlay extends React.Component {
  static contextType = ThemeContext;
  render() {
    const { pay } = this.props.selectedFlightItem;
    const { fareBreakup, additionalInfoList } = this.props.selectedFlightItem;
    const corporateBooking = this.props.isCorporateBooking;
    const {
      rescheduleFareConfirmationText,
      totalPayableText,
      reviewAndBookText,
      decideAndCancelText,
      proceedCancellationText,
    } = getStaticData();
    const currency = this.props.currency || '₹';
    const fonts = getFont(true);
    const styles = createStyles(this.context.psTheme, fonts);

    return (
      <View>
        <View style={[styles.ResheuduleTitleMain]}>
          <Text style={styles.ResheuduleTitle} numberOfLines={1}>{rescheduleFareConfirmationText}</Text>
          <TouchableOpacity onPress={() => this.props.CloseOverlay()}>
            <Image style={styles.CloseImg} source={closeImg} />
          </TouchableOpacity>
        </View>
        {fareBreakup?.header && (
          <View style={styles.headerContainer}>
            <Image source={REFUND_CALENDAR} style={styles.headingIconStyle} />
            <Text style={styles.headingText}>{fareBreakup.header}</Text>
          </View>
        )}
        <View style={styles.OverlayDtls}>
          {fareBreakup
            ? fareBreakup &&
            <>
              {fareBreakup.fareBreakupItems.map((fareBreakupItem) => (
                <View style={[styles.OverlayDtlsContentMain]}>
                  <View style={styles.OverlayDtlsContent}>
                    <Text
                      style={[
                        styles.HeadingTxt,
                        fareBreakupItem.headerColor && { color: fareBreakupItem.headerColor },
                      ]}
                    >
                      {fareBreakupItem.header}
                    </Text>
                    <Text
                      style={[
                        styles.HeadingTxt,
                        fareBreakupItem.amountColor && { color: fareBreakupItem.amountColor },
                      ]}
                    >
                      {addSpaceWithAmount(fareBreakupItem.amount)}{' '}
                    </Text>
                  </View>
                  {fareBreakupItem.components &&
                    fareBreakupItem.components.map((fareBreakupCompoment) => (
                      <View style={styles.OverlayDtlsContent}>
                        <Text
                          style={[
                            styles.OverlayInnerTxt,
                            fareBreakupCompoment.titleColor && {
                              color: fareBreakupCompoment.titleColor,
                            },
                          ]}
                        >
                          {fareBreakupCompoment.title}
                        </Text>
                        <View style={styles.amountContainer}>
                          {fareBreakupCompoment.slashedAmount && (
                            <Text style={styles.slashedAmountTxt}>
                              {fareBreakupCompoment.slashedAmount}
                            </Text>
                          )}
                          <Text
                            style={[
                              styles.OverlayInnerTxt,
                              fareBreakupCompoment.amountColor && {
                                color: fareBreakupCompoment.amountColor,
                              },
                            ]}
                          >
                            {addSpaceWithAmount(fareBreakupCompoment.amount)}
                          </Text>
                        </View>
                      </View>
                    ))}
               
                </View>
              ))}
                 <View style={styles.TotalAmountWrapper}>
                    <Text
                      style={[
                        styles.TotalAmountTxtHeading,
                        fareBreakup?.totalPayable?.headerColor && {
                          color: fareBreakup.totalPayable.headerColor,
                        },
                      ]}
                    >
                      {fareBreakup ? fareBreakup.totalPayable.header : totalPayableText}
                    </Text>
                    <Text
                      style={[
                        styles.TotalAmountTxt,
                        fareBreakup?.totalPayable?.amountColor && {
                          color: fareBreakup.totalPayable.amountColor,
                        },
                      ]}
                    >
                      {fareBreakup
                        ? addSpaceWithAmount(fareBreakup.totalPayable.amount)
                        : addSpaceWithAmount(pay)}{' '}
                    </Text>
                  </View></>
            : <PriceBreakUp selectedFlightItem={this.props.selectedFlightItem} currency={currency}/>}
          <TouchableOpacity style={styles.ResheduleBtnWrapper}>
            <BaseButton
              text={reviewAndBookText}
              variant={BUTTON_VARIANT.CAPSULE}
              clickHandler={() => {
                this.props.CloseOverlay();
                this.props.onReviewAndRebook(corporateBooking, this.props.bookingId, {
                  odcPreviewResponse: this.props.odcPreviewResponse,
                  odcSegmentGroup: this.props.segmentGroup,
                });
              }}
              {...FLIGHT_THROTTLE_OBJECT}
              textStyle={{fontSize:fonts.fontSize.font16}}
            />
          </TouchableOpacity>
          {!isEmpty(additionalInfoList) && (
            <View style={styles.additionalInfoWrapper}>
              {additionalInfoList.map((infoItem) => (
                <View style={styles.additionalInfoContainer}>
                  <Text style={[styles.HeadingTxt, { color: infoItem.headingColor }]}>
                    {infoItem.heading}
                  </Text>
                  <Text style={styles.additionalInfoTxt}>{infoItem.description}</Text>
                </View>
              ))}
            </View>
          )}
          <View style={styles.ProceedCancWrapper}>
            <Text style={styles.btmOverlayTxt}>{decideAndCancelText}</Text>
            <TouchableOpacity onPress={() => this.props.onCancellationContinue()}>
              <Text style={[styles.ProceedCanTxt, { color: this.context.psTheme.color.primary }]}>
                {proceedCancellationText}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    );
  }

}

RescheduleFareConfirmationOverlay.propTypes = {
  CloseOverlay: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    selectedFlightItem: state.flightListing.selectedFlightItem,
  };
}
const mapDispatchToProps = (dispatch) => ({
  onReviewAndRebook: (corporateBooking, bookingId, info) =>
    dispatch(onReviewAndRebook(corporateBooking, bookingId, info)),
});

RescheduleFareConfirmationOverlay.propTypes = {
  selectedFlightItem: PropTypes.object.isRequired,
  onCancellationContinue: PropTypes.func.isRequired,
  onReviewAndRebook: PropTypes.func.isRequired,
};

const ConnectedRescheduleFareConfirmationOverlay=(props)=>{
  return <RescheduleFareConfirmationOverlay {...props} />
}

export default connect(mapStateToProps, mapDispatchToProps,null , {context:ThemeContext})(ConnectedRescheduleFareConfirmationOverlay);


