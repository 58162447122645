import { StyleSheet } from 'react-native';

const createStyles = (fonts, theme) =>
  StyleSheet.create({
    countryFilterTabs: {
      borderWidth: 1,
      borderColor: theme.color.grey4,
      flexDirection: 'row',
      borderRadius: 4,
      padding: 8,
      marginRight: 10,
    },
    radio: {
      borderColor: theme.color.lightTextColor,
      borderWidth: 2,
      height: 18,
      width: 18,
      backgroundColor: theme.color.white,
      borderRadius: 10,
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: 5,
    },
    radioActive: {
      borderColor: theme.color.azure,
    },
    radioCircle: {
      height: 11,
      width: 11,
      backgroundColor: theme.color.azure,
      borderRadius: 10,
    },
    nameText: {
      color: theme.color.black,
      fontSize: fonts.fontSize.font14,
      ...fonts.boldFontFamily,
    },
  });

export default createStyles;
