import React, { useState, useEffect, useRef } from 'react';
import {View, Text, Image, TouchableOpacity } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { CROSS_ICON } from '../../../HotelImageConstants';
import LineLoader from '@Frontend_Ui_Lib_App/LineLoader'; 
import { WebView } from 'react-native-webview';
import useApi from '../../../../Common/useApi';
import { HOTEL_EXPEDIA_CHAT_FIREBASE_SET_USER_DATA } from '../../../../utils/NetworkUtils';
import isEmpty from 'lodash/isEmpty';
import createStyles from './style';
import { getStaticData } from '../../../../staticData/staticData';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import {APP_STATE_CONSTANTS} from '../../HotelBookingDetailsConstant';
import PostSalesBookingTrackingHelper from '../../../../PostSalesBookingTrackingHelper';

// Define TypeScript interfaces for props and state
interface VendorChatDetails {
  chatURL: string;
  pnr: string;
  roomName?: string;
  lastUnreadMsg?: string;
  unreadMessagesCount?: number;
}

interface ExpediaChatBottomSheetProps {
  vendorChatDetails: VendorChatDetails[];
  handleCloseOverlay: () => void;
  clearDeeplinkPnr : () => void;
  customerName?: string;
  lobName: string;
  bookingId: string;
  deeplinkPnr: string;
  trackingPageName: string;
}

interface StateObj {
  showLoader: boolean;
  chatUrl: string;
  showVendorChatRooms: boolean;
}

const ExpediaChatBottomSheet: React.FC<ExpediaChatBottomSheetProps> = ({
  vendorChatDetails = [],
  handleCloseOverlay,
  clearDeeplinkPnr,
  customerName,
  lobName,
  bookingId,
  deeplinkPnr,
  trackingPageName,
}) => {
  const [stateObj, setStateObj] = useState<StateObj>({
    showLoader: false,
    chatUrl: '',
    showVendorChatRooms: true,
  });
  const selectedPnrRef = useRef('');
  const staticData = getStaticData();
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme);

  const [saveDataProgress, response, saveDataApi] = useApi(HOTEL_EXPEDIA_CHAT_FIREBASE_SET_USER_DATA);

  const saveUserActivity = (pnr: string, data : {userActive: boolean, unreadMessagesCount: number}) => {
    const body = {
      bookingId: bookingId,
      lobName: lobName,
      pnr: pnr,
      data: data,
    };
    saveDataApi.httpPost({
      body,
    });
  };

  const openChat = (url: string, pnr: string) => {
    PostSalesBookingTrackingHelper.trackLoadEvent(trackingPageName, `ExpediaChat_load_pnr::${pnr}`, bookingId);
    setStateObj({ showLoader: true, chatUrl: url, showVendorChatRooms: false });
    selectedPnrRef.current = pnr;
    setActive(pnr);
  };

  const hideLoader = (url: string) => {
    setStateObj({ showLoader: false, chatUrl: url, showVendorChatRooms: false });
  };

  const setInactive = (pnr: string) => {
    const userStateData = {
      'userActive': false,
      'unreadMessagesCount': 0,
    };
    saveUserActivity(pnr, userStateData);
  };

  const setActive = (pnr: string) => {
    const userStateData = {
      'userActive': true,
      'unreadMessagesCount': 0,
    };
    saveUserActivity(pnr, userStateData);
  };

  useEffect(() => {
    PostSalesBookingTrackingHelper.trackShownEvent(trackingPageName, 'ExpediaChatBottomSheet_shown', bookingId);
    if (!isEmpty(vendorChatDetails) && vendorChatDetails.length === 1){
      const {chatURL, pnr} = vendorChatDetails[0] || {};
      openChat(chatURL, pnr);
    }
    else if (deeplinkPnr){
      const selectedVendorChat = vendorChatDetails.find((chat) => chat.pnr === deeplinkPnr);
      if (selectedVendorChat) {
        openChat(selectedVendorChat.chatURL, selectedVendorChat.pnr);
      }
    }

    return () => {
      if (selectedPnrRef.current) {
        setInactive(selectedPnrRef.current);
        selectedPnrRef.current = '';
        clearDeeplinkPnr();
      }
    };
  }, []);

  return (
    <View style={styles.chatContainer}>
      <View style={AtomicCss.marginBottom18}>
        <View style={styles.overlayTopline} />
      </View>
      <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, styles.containerPadding]}>
        <TouchableOpacity onPress={()=> handleCloseOverlay()}>
          <Image style={styles.crossIcon} source={CROSS_ICON} />
        </TouchableOpacity>
        {!!customerName && (
          <Text style={[fsStyle.font16, { color: theme.color.black }, fonts.boldFontFamily]}>
            {staticData.expediaChatText.welcome} {customerName}
          </Text>
        )}
      </View>
      <View style={AtomicCss.paddingHz20}>
        {stateObj.showLoader && (
          <View style={styles.overlayContainer}>
            <View style={styles.loaderContainer}>
              <LineLoader customStyles={{ backgroundColor: theme.color.lightGrey8 }} />
              <View style={styles.paddingTop20}>
                <Text
                  style={[
                    fsStyle.font12,
                    fonts.regularFontFamily,
                    AtomicCss.greyText,
                    AtomicCss.textCenter,
                    AtomicCss.lineHeight14,
                  ]}
                >
                  {staticData.expediaChatText.loaderMsg}
                </Text>
              </View>
            </View>
          </View>
        )}

        {stateObj.showVendorChatRooms &&
          !isEmpty(vendorChatDetails) &&
          vendorChatDetails.length > 1 &&
          vendorChatDetails.map((chat, index) => {
            return (
              <TouchableOpacity
                key={index}
                onPress={() => openChat(chat.chatURL, chat.pnr)}
                style={[styles.pnrChatContainer]}
              >
                <View style={styles.vendorChatDtls}>
                  {!!chat.roomName && (
                    <Text
                      numberOfLines={1}
                      style={[fsStyle.font16, { color: theme.color.black }, fonts.blackFontFamily]}
                    >
                      {chat.roomName}
                    </Text>
                  )}
                </View>

                {!!chat.unreadMessagesCount && (
                  <View style={styles.notificationConatiner}>
                    <View style={styles.redCircle}>
                      <Text style={[fsStyle.font14, AtomicCss.whiteText, fonts.boldFontFamily]}>
                        {chat.unreadMessagesCount}
                      </Text>
                    </View>
                  </View>
                )}
              </TouchableOpacity>
            );
          })}

        {!!stateObj.chatUrl && (
          <>
            {!stateObj.showLoader && (
              <>
                <View style={styles.menuOverlay} />
                <View style={styles.pnrOverlay} />
              </>
            )}
             <WebView
              onLoadEnd={() => hideLoader(stateObj.chatUrl)}
              source={{ uri: stateObj.chatUrl }}
              style={{ width:"100%" , height: stateObj.showLoader ? 0 : 450 }}
              showsVerticalScrollIndicator={false}
            />
          </>
        )}
      </View>
    </View>
  );
};

export default ExpediaChatBottomSheet;
