import { colors } from '@mmt/legacy-commons/Styles/globalStyles';

const styles = {
  pageWrapper: {
    flex: 1,
  },
  footerBtnWrapper: {
    paddingTop: 5,
    paddingBottom: 12,
    paddingHorizontal: 12,
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
  subHeading: {
    color: colors.defaultTextColor,
  },
  checkboxRow: {
    alignItems: 'flex-start',
  },
  errorText: {
    color: colors.errorRed,
    marginTop: 4,
  },
  headerTitle: {
    color: colors.black,
  },
};

export default styles;
