import React from 'react';
import { View, TouchableOpacity } from 'react-native';
import createStyles from './styles';
import { useTheme } from '../../../../../../../theme/theme.context';

const OverlayMessage = React.memo(({ closeOverlay, content }) => {
  const {psTheme: theme} = useTheme();
  const styles = createStyles(theme);
  return (
  <View style={styles.overlayWrapper}>
    <TouchableOpacity onPress={() => closeOverlay()} activeOpacity={1} style={styles.overlay} />
    <View style={styles.overlayContent}>{content}</View>
  </View>
);});

export default OverlayMessage;
