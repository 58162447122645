import { Dimensions, Platform } from 'react-native';
import fecha from "fecha";

export const DAYS_COUNT = 122;
const deviceSize = (Dimensions && Dimensions.get('window')) || {};
const os = Platform.OS;
export const AVAILABILITY_COLORS = {
 url : 'https://rails.makemytrip.com/v1/calendar/route_availability',
 headers : {
    'Content-Type': 'application/json'
  },
 intensity : {
    high : 'High availability',
    medium : 'Medium availability',
    low : 'Low availability'
 },
 intensityShort : {
  '#18a160' : 'HIGH AVL',
  '#ffc24a' : 'MEDIUM AVL',
  '#ff1515' : 'LOW AVL'
 }
}

export const API_POST_METHOD = 'POST';
export const width = deviceSize.width || '100vw';
export const height = deviceSize.height || '100vh';
export const realWidth = height > width ? width : height;
export const DATE_FORMAT = "YYYYMMDD";
export const NOT_AVAILABLE = "NA";
export const CHECKOUT = 'checkout';

export const sf = (font = 14) => {
    if (typeof font !== 'number') {
      return font;
    }
    if (os === 'ios' || os === 'android') {
      return Math.round(font * 0.65);
    }
    if (realWidth <= 320) {
      return Math.round(font * 0.60);
    }
    if (realWidth < 768) {
      return Math.round(font * 0.65);
    }

    return font;
};

export function addDays(days = 1, theDate: Date) {
  const newDate = new Date();
  newDate.setHours(0, 0, 0);
  const changeDate = theDate || newDate;
  return new Date(changeDate.getTime() + (days * 24 * 60 * 60 * 1000));
}

export function getDates(startDate: string, endDate: string) {
  const dates = [];
  const stDate = startDate.replace(/(\d\d\d\d)(\d\d)(\d\d)/g, '$1-$2-$3');
  const enDate = endDate.replace(/(\d\d\d\d)(\d\d)(\d\d)/g, '$1-$2-$3');
  let currentDate = addDays(1, new Date(stDate));
  while (currentDate < new Date(enDate)) {
    dates.push(formatDate(currentDate));
    currentDate = addDays(1, currentDate);
  }
  return dates;
}

export const formatDate = (date : Date) => {
  const year = date.getFullYear();
  const month =  date.getMonth();
  const _date = date.getDate();
  let formattedDate = '';

  formattedDate+= year;
    if(month + 1 <= 9 )formattedDate = formattedDate + ('0' + (month + 1));
    else formattedDate = formattedDate + (month + 1);
    if(_date <= 9 )formattedDate = formattedDate + ('0' + _date);
    else formattedDate = formattedDate + _date;

  return formattedDate;
}

export function getMonths(numberOfDaysToAdd : number) {
  const value = [];
  const today = new Date();
  const minimumDate = new Date();
  const maximumDate = addDays(
    numberOfDaysToAdd === -1 ? 365 : numberOfDaysToAdd,
    today
  );
  let minMonth = minimumDate.getMonth();
  let minYear = minimumDate.getFullYear();
  const maxMonth = maximumDate.getMonth();
  const maxYear = maximumDate.getFullYear();
  do {
    value.push({ month: minMonth, year: minYear });
    if (minMonth === maxMonth && minYear === maxYear) {
      break;
    }
    if (minMonth === 11) {
      minMonth = 0;
      minYear += 1;
    } else {
      minMonth += 1;
    }
  } while (true);
  return value;
}

export function getFromToDate() {
  const today = new Date();
  const _today = new Date();
  const arpDay = new Date(_today.setDate(_today.getDate() + DAYS_COUNT));
  const fromDate = fecha.format(today, "YYYYMMDD");
  const toDate = fecha.format(arpDay, "YYYYMMDD");
  return {
    fromDate: fromDate,
    toDate: toDate,
    fromDateObj: today.setHours(0, 0, 0, 0),
    toDateObj: arpDay.setHours(0, 0, 0, 0),
  }
}
