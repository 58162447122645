const HOLIDAY_BOOKING_ROUTE_KEYS_ARRAY = [
  {
    key: 'holidayMimaTabDetail',
    component: () => require('./HolidayBookingTab').default
  },
  {
    key: 'holidayUploadDocuments',
    component: () => require('./DocumentsPage/DocumentsPage').default
  },
  {
    key: 'holidayCancellationReview',
    component: () => require('./CancellationPage/CancellationReview').default
  },
  {
    key: 'holidayBookingDetail',
    component: () => require('./HolidayBookingDetail').default
  },
  {
    key: 'holidayCancellationThankyou',
    component: () => require('./CancellationPage/CancellationSuccess').default
  },
  {
    key: 'holidayCancellationRefundMode',
    component: () => require('./CancellationPage/CancellationRefundMode').default
  },
  {
    key: 'holidayAddCarPickUpDetails',
    component: () => require('./CarPickupDetails').default
  },
  {
    key: 'holidaysAddVisa',
    component: () => require('./modules/AddVisa/pages/ApplyVisaPage').default
  },
  {
    key:'holidaysThankYouPage',
    component: () => require('./modules/AddVisa/pages/AddVisaThankYouPage').default 
  }
]

export default HOLIDAY_BOOKING_ROUTE_KEYS_ARRAY;