import React, { useEffect, useRef, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import {
  View,
  Text,
  Image
} from 'react-native';
import DetailsLoader from '../../components/CabBookingDetail/Spinner';
import CardWrapper from '../../modules/bookingDetails/components/CardWrapper';
import CarTrackerDetail from './components/CarTrackerDetail/index.jsx';
import {
  convertSecondsToHoursAndMinutes,
  findDistanceLeft,
  getDecrypedWayPoints,
  getFooterData,
  getHeaderData,
  getRegion,
  getTooltipText,
  onSameRoute,
  updateWaypointStates,
} from './data/helper';
import { fetchGoogleApiDirection} from './data/api';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import getMapStyle from './style';
import { errorIcon } from '../../CabImageConstants';
import CabBookingTrackingHelper from '../../CabBookingTrackingHelper';
import { CLICK_EVENT, PAGE_NAME } from '../../CabBookingDetailsConstant';

const CarTrackerCard = (props) => {
  const {
    currentCarLocation,
    toCarLocation,
    pickupLocation,
    destinationLocation,
    carPreviousLocation,
    eventName,
    bookingId,
    timestamp,
		showCabTrackerOverlay,
		appHeight,
    carTrackerLoading,
    carTrackerError,
		driverCardHeight,
    refreshInterval,
    cardData,
    setLiveTrackingErrorState
  } = props;

  const { psTheme: theme } = useTheme();
  const { fsStyle, ...fonts } = getFont(true);
  const mapStyles = getMapStyle(fonts, theme);
  const {liveTrackingCardData, heading} = cardData || {};

  const wayPoints = useRef([]);
  const [duration, setDuration] = useState('');

  const carCurrentCoordinates = useRef(null);
  const [distanceLeft, setDistanceLeft] = useState(0);
  const durationMsg = useRef('');
  const [toolTipText, setToolTipText] = useState('');
  const headerData = useRef({});

  const currentCarLocationRef = useRef();
  const toCarLocationRef = useRef();

  currentCarLocationRef.current = currentCarLocation;
  toCarLocationRef.current = toCarLocation;
  carCurrentCoordinates.current = !isEmpty(currentCarLocation) && getRegion(currentCarLocation);;

  useEffect(() => {
    if(!isEmpty(eventName) && !isEmpty(liveTrackingCardData)){
      durationMsg.current = getFooterData(eventName, distanceLeft, liveTrackingCardData);
      headerData.current = getHeaderData(eventName, distanceLeft, liveTrackingCardData, heading);
      if(!!duration){
        setToolTipText(getTooltipText(eventName, duration, liveTrackingCardData));
      }
    }
  }, [eventName, distanceLeft, duration]);


  useEffect(() => {
    const intervalId = setInterval(fetchCabDirection, refreshInterval);
    return () => {
      intervalId && clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    getWayPoints();
  }, [currentCarLocation, toCarLocation, eventName]);

  useEffect(() => {
    if(!isEmpty(currentCarLocationRef.current) && !isEmpty(toCarLocationRef.current)){
      fetchCabDirection();
    }
  }, [eventName]);


  const getWayPoints = () => {
    try {
      if (
        !isEmpty(wayPoints.current) &&
        wayPoints.current.length >= 2 &&
        !isEmpty(currentCarLocation) &&
        onSameRoute(wayPoints.current, currentCarLocation)
      ) {
        wayPoints.current = updateWaypointStates(wayPoints.current, currentCarLocation);
        setDistanceLeft(findDistanceLeft(wayPoints.current, currentCarLocation));
      } else {
        if (!isEmpty(currentCarLocation) && !isEmpty(toCarLocation)) {
          fetchCabDirection();
        }
      }
    } catch (e) {
      console.log('error in getWayPoints', e, wayPoints.current, currentCarLocation);
      setLiveTrackingErrorState && setLiveTrackingErrorState();
    }
  };

  const fetchCabDirection = async () => {
    if(currentCarLocationRef.current && toCarLocationRef.current){
      const { error, response } = await fetchGoogleApiDirection(
        currentCarLocationRef.current,
        toCarLocationRef.current,
        bookingId,
      );
      if (!!error) {
        // continue showing last updated state, nothing to be done here
      } else {
        wayPoints.current = getDecrypedWayPoints(response);
        setDistanceLeft(findDistanceLeft(wayPoints.current, currentCarLocationRef.current));
        setDuration(convertSecondsToHoursAndMinutes(response?.routes?.[0]?.legs?.[0].duration?.value));
      }
    }
  };

  const renderLoader = () => {
    return (
      <View style={[mapStyles.mapLoading, {flex: 1}]}>
        <DetailsLoader />
      </View>
    );
  };

  const renderErrorState = () => {
    CabBookingTrackingHelper.trackClickEvent(PAGE_NAME.TRIP_DETAILS, CLICK_EVENT.TRACKER_ERROR_CARD_SHOW);
    const {errorStateMsg} = liveTrackingCardData || {};
    return (
      <View style={[mapStyles.mapNotLoadedContainer, mapStyles.mar0Tp]}>
        <Image source={errorIcon} style={mapStyles.errorIcon} />
        {!!errorStateMsg && <Text style={mapStyles.errorText}>
          {errorStateMsg}
        </Text>}
      </View>
    );
	};

	return (
		<React.Fragment>
			{carTrackerError ? renderErrorState() :
				<View style={{ minHeight: 370 }}>
					{!carTrackerLoading && carCurrentCoordinates.current ? (
						<CarTrackerDetail
							carCurrentCoordinates={carCurrentCoordinates}
							pickupLocation={pickupLocation}
							destinationLocation={destinationLocation}
							wayPoints={wayPoints}
							currentCarLocation={currentCarLocation}
							toolTipText={toolTipText}
							toggleCabTrackerOverlay={props.toggleCabTrackerOverlay}
							timestamp={timestamp}
							durationMsg={durationMsg}
							headerData={headerData.current}
							carPreviousLocation={carPreviousLocation}
							showCabTrackerOverlay={showCabTrackerOverlay}
							appHeight={appHeight}
							driverCardHeight={driverCardHeight}
              toCarLocation={toCarLocation}
              liveTrackingCardData={liveTrackingCardData}
              distanceLeft={distanceLeft}
						/>
					) : renderLoader()}
				</View>
			}
		</React.Fragment>
	);
};

export default CardWrapper(CarTrackerCard);
