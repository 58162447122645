import { StyleSheet, PixelRatio } from 'react-native';

export default (colors) => StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    flex: 1,
  },
  noInternet: {
    flex: 4,
    justifyContent: 'center',
    alignItems: 'center',
  },
  noInternetImage: {
    height: '80%',
    marginHorizontal: PixelRatio.getPixelSizeForLayoutSize(24),
    marginVertical: PixelRatio.getPixelSizeForLayoutSize(8),
    width: '70%',
    resizeMode: 'contain',
  },
  noInternetTitle: {
    marginTop: 24,
    marginHorizontal: 24,
    color: colors.black,
    lineHeight: 34,
    textAlign: 'center',
  },
  noInternetSubtitle: {
    marginTop: 8,
    marginHorizontal: 24,
    color: colors.lightTextColor,
    lineHeight: 21,
    textAlign: 'center',
  },
  noInternetCta: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  serverErrorFooterContainer: {
    flex: 3,
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
  },
  buttonStyle: {
    alignSelf: 'flex-start',
    marginTop: '10%',
  },
  CapsuleBtnFill: {
    paddingVertical: 10,
    borderRadius: 34,
  },
  BtnTouch: {
    borderRadius: 34,
    elevation: 2,
    marginTop: 20,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 2,
  },
  btnStyle: {
    color: colors.white,
    backgroundColor: 'transparent',
    alignSelf: 'center',
  },
});


