/* eslint-disable max-len */
import React from 'react';
import Fecha from 'fecha';
import {Alert, Platform, Image} from 'react-native';
import Actions from '../../navigation/postSalesNavigation';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import BusBookingModule from '@mmt/legacy-commons/Native/BusBookingModule';
import BusBookingDetailsConstant from '../BusBookingDetailsConstant';
import HotelBookingDetailsConstant from '../../hotel/details/HotelBookingDetailsConstant';
import getStyles from '../styles/BusMamiCss';
import BusBookingTrackingHelper from '../BusBookingTrackingHelper';
import {INDIAN_PHONE_NUMBERS, DONWLOAD_FILE_EXT} from '../../PostSalesConstant';
import {isEmpty, cloneDeep, get} from 'lodash';
import CabBookingDetailsConstant from '../../cab/CabBookingDetailsConstant';
import {
  maleImage, femaleImage, whiteBackIcon, viewLocation, callHotel, chatWithHotel,
  resendConfirmation, validProof, checkinHotel, callMMTSupport, gotoHelp, chatWithUs, makeRequest, tick, doubleTick
} from '../BusImageConstants';
import { openDialer } from '../../Common/commonUtil';
import { label } from '@mmt/post-sales/src/staticData/staticData';
import WhiteFullCapsuleButton from '../../Common/ActionButton/WhiteFullCapsuleButton';
import COMMON_CONSTANT from '../../Common/commonConstant';
import RoundButtonWithIcon from '../../Common/ActionButton/RoundButtonWithIcon';
import CapsuleBtnFill from '../../Common/ActionButton/CapsuleBtnFill';
import RectFullButton from '../../Common/ActionButton/RectFullButton';
import { BUS_FETCH_INVOCE_PDF } from '../../utils/NetworkUtils';
import { downloadEticketVoucher } from '../../utils/PostSaleUtil';

export function fillDepartureArrivalDate(date, outputDateFormat) {
  return Fecha.format(new Date(date), outputDateFormat);
}

export function convertStringtoTitleCase(str) {
  const strArray = str.toLowerCase().split(' ');
  for (let i = 0; i < strArray.length; i++) {
    strArray[i] = strArray[i].charAt(0).toUpperCase() + strArray[i].slice(1);
  }
  return strArray.join(' ');
}

export function getSubTitleForCancellationHeader(busdetailInfo) {
  return `${busdetailInfo.busBookingInfo.busDetail.fromCity} ${label('bus.to')} ${busdetailInfo.busBookingInfo.busDetail.toCity} | ${busdetailInfo.busAdditionalInfo.departureDetail.departureDayInMonth} ${busdetailInfo.busAdditionalInfo.departureDetail.departureMonth}`;
}

export async function handleClickEvent(action, phoneNumber, response) {
  BusBookingTrackingHelper.trackClickEvent(response.bookingState.subState, response, 'Call_operator_phone number ');
  switch (action) {
    case BusBookingDetailsConstant.ACTION_CALL_OPERATOR:
      openDialer(phoneNumber);
      break;
    default:
      break;
  }
}

export function getTheSelectedData(selectedReason,index,otherInfo, cancellationReasonList, cancellationReason){
  if(!isEmpty(otherInfo)){
    cancellationReason = `${selectedReason.reason}:${otherInfo}`;
  }else{
    cancellationReason = `${selectedReason.reason}`;
  }

  let newCancellationRules = null;
  if(!isEmpty(cancellationReasonList)) {
    newCancellationRules = cloneDeep(cancellationReasonList);
    if(!isEmpty(newCancellationRules)) {
      for(let i = 0; i < cancellationReasonList.length; i++) {
        newCancellationRules[i].selected = false;
      }
      newCancellationRules[index].selected = true;
    }
  }
  return {newCancellationRules,cancellationReason};
};

const downloadInvoiceVoucher = async (response, isInvoice) => {
  const extension = isInvoice ? DONWLOAD_FILE_EXT.INVOICE : DONWLOAD_FILE_EXT.ETICKET;
  downloadEticketVoucher(
    response.bookingId,
    isInvoice
      ? BusBookingDetailsConstant.TRIGGER_CUSTOMER_INVOICE
      : BusBookingDetailsConstant.TRIGGER_CUSTOMER_ETicket,
    BUS_FETCH_INVOCE_PDF,
    BusBookingDetailsConstant.BUS_DOWNLOAD_VOUCHER,
    response.bookingId + extension,
  );
};

export async function handleBusClickEvent(card, action, response, pageName, overlayCallback = () => {}, trackOmniture = true) {
  if (trackOmniture) {
    BusBookingTrackingHelper.trackClickEvent(pageName, response, action.actionFamily);
  }

  switch (action.actionFamily) {
    case BusBookingDetailsConstant.ACTION_BUS_CALL_US:
    case BusBookingDetailsConstant.ACTION_CALL_HELPLINE:
      openDialer(BusBookingDetailsConstant.HELPLINE_NUMBER);
      break;
    case BusBookingDetailsConstant.ACTION_BUS_OPEN_OVERLAY:
    case BusBookingDetailsConstant.ACTION_BUS_OPEN_OVERLAY2:
      overlayCallback(card, action)
      break;
    case BusBookingDetailsConstant.ACTION_VIEW_PRICE_BREAKUP:
      Actions.commonPaymentPriceBreakUp({
        bookingDetailsResponse: response
      });
      break;
    case BusBookingDetailsConstant.ACTION_CANCEL_FULL_BOOKING:
      Actions.busCancellationDetails({
        response,
        card
      });
      break;
    case BusBookingDetailsConstant.ACTION_RAISE_SPECIAL_CLAIM:
      Actions.busSupportIssueSelect({
        response,
        card
      });
      break;
    case BusBookingDetailsConstant.ACTION_DOWNLOAD_TICKET:
      if (Platform.OS === 'ios') {
        downloadInvoiceVoucher(response, false);
      } else if (Platform.OS === 'web') {
        BusBookingModule.downloadTicketInvoice(response, "Bus_ETicket",null);
      }else {
        BusBookingModule.onButtonClicked(
          JSON.stringify(card), JSON.stringify(action),
          JSON.stringify(response), pageName
        );
      }
      break;
    case BusBookingDetailsConstant.ACTION_DOWNLOAD_INVOICE:
      if (Platform.OS === 'ios') {
        downloadInvoiceVoucher(response, true);
      }else if (Platform.OS === 'web'){
        BusBookingModule.downloadTicketInvoice(response, "Bus_CustomerInvoice",null);
      }else{
        if (response.bookingState.state.toUpperCase() === 'COMPLETED' || response.bookingState.state.toUpperCase() === 'CANCELLED') {
          BusBookingModule.onButtonClicked(
            JSON.stringify(card), JSON.stringify(action),
            JSON.stringify(response), pageName
          );
        } else {
          Alert.alert(action.actionAlertText);
        }
      }
      break;
    case BusBookingDetailsConstant.ACTION_CALL_OPERATOR:
      const operatorNumber = getOperatorPhoneNumber(response);
      openDialer(operatorNumber);
      break;
    case BusBookingDetailsConstant.ACTION_BUS_GOTO_HELP_SECTION:
      GenericModule.openActivity('mmt.intent.action.CUSTOMER_SUPPORT');
      break;
    case BusBookingDetailsConstant.ACTION_BUS_MAKE_REQUEST:
      BusBookingModule.startWriteToUsActivity();
      break;
    case BusBookingDetailsConstant.ACTION_BUS_WRITE_A_REVIEW:
      let reviewUrl = '';
      if(response.busAdditionalInfo && response.busAdditionalInfo.reviewDetail
        && response.busAdditionalInfo.reviewDetail.reviewData) {
          reviewUrl = response.busAdditionalInfo.reviewDetail.reviewData.reviewURL;
      }
      Actions.openPsWebView({
        url: reviewUrl,
        headerText: 'Back To Booking',
        headerIcon: whiteBackIcon
      });
      break;
    case BusBookingDetailsConstant.ACTION_BOOK_AGAIN:
      GenericModule.openActivity('mmt.intent.action.BUS_BOOK');
      break;
    case BusBookingDetailsConstant.ACTION_VIEW_MORE:
        Actions.primoViewMore({response})
      break;
    case BusBookingDetailsConstant.ACTION_TRACK_BUS:
    default:
      break;
  }
}

const getThrottleParams = (action) => {
  switch (action.actionFamily) {
    case BusBookingDetailsConstant.ACTION_DOWNLOAD_INVOICE:
      return {
        isThrottleRequired: true,
        throttleInterval: 2000,
      };
    default:
      return {
        isThrottleRequired: false,
        throttleInterval: 0,
      };
  }
};

export function getActionButton(card, response, clickHandler) {
  const actionButtons = [];
    for (const action of card.actionList) {
      const { isThrottleRequired, throttleInterval } = getThrottleParams(action);
      addActionButton(card, action, actionButtons, response, clickHandler, isThrottleRequired , throttleInterval);
  }
  return actionButtons;
}

function addActionButton(card, action, actionButtons, response, clickHandler,isThrottleRequired= false, throttleInterval = 0) {
  switch (action.actionType) {
    case COMMON_CONSTANT.WHITE_BUTTON_FULL:
    case COMMON_CONSTANT.WHITE_BUTTON_HALF:
      actionButtons.push(<WhiteFullCapsuleButton
        key={`WhiteFullCapsuleButton${action.actionLabeltext}`}
        card={card}
        action={action}
        response={response}
        clickHandler={clickHandler}
        isThrottleRequired={isThrottleRequired}
        throttleInterval={throttleInterval}
      />);
      break;
    case COMMON_CONSTANT.RECTANGLE_FULL_BUTTON:
      actionButtons.push(<RectFullButton
        key={`RectFullButton${action.actionLabeltext}`}
        card={card}
        action={action}
        response={response}
        clickHandler={clickHandler}
        isThrottleRequired={isThrottleRequired}
        throttleInterval={throttleInterval}
      />);
      break;
    case COMMON_CONSTANT.RECTANGLE_PRIMARY_FULL_WIDTH:
    case COMMON_CONSTANT.RECTANGLE_PRIMARY_HALF_WIDTH:
      actionButtons.push(<CapsuleBtnFill
        key={`CapsuleBtnFill${action.actionLabeltext}`}
        card={card}
        action={action}
        response={response}
        clickHandler={clickHandler}
        isThrottleRequired={isThrottleRequired}
        throttleInterval={throttleInterval}
      />);
      break;
    case COMMON_CONSTANT.ROUND_BUTTON:
      actionButtons.push(<RoundButtonWithIcon
        key={`RoundButtonWithIcon${action.actionLabeltext}`}
        card={card}
        action={action}
        response={response}
        clickHandler={clickHandler}
        isThrottleRequired={isThrottleRequired}
        throttleInterval={throttleInterval}
      />);
      break;
    default:
      break;
  }
}

export function isNotNullAndEmpty(object) {
  return (object != null && object.length > 0);
}

export function getButtonStyle(actionList) {
  const {actionType} = actionList[0];
  const styles = getStyles();
  if (actionType === HotelBookingDetailsConstant.RECTANGLE_PRIMARY_HALF_WIDTH
        || actionType === HotelBookingDetailsConstant.RECTANGLE_SECONDARY_HALF_WIDTH
        || actionType === HotelBookingDetailsConstant.ROUND_BUTTON) {
    return [styles.ActionBtnHalf, styles.transperantBgrnd];
  } else if (actionType === HotelBookingDetailsConstant.RECTANGLE_FULL_BUTTON) {
    return [styles.ActionBtnRec, styles.transperantBgrnd];
  }
  return [styles.ActionBtn, styles.transperantBgrnd];
}

export function findDaysBetweenDates(firstDate, secondDate) {
  const oneDay = 24 * 60 * 60 * 1000;
  return Math.round(Math.abs((secondDate - firstDate) / oneDay));
}

export function getRoundIcon(actionFamily) {
  if (actionFamily) {
    switch (actionFamily) {
      case HotelBookingDetailsConstant.ACTION_VIEW_LOCATION:
        return viewLocation;
      case HotelBookingDetailsConstant.ACTION_CALL_HOTEL:
        return callHotel;
      case HotelBookingDetailsConstant.ACTION_CHAT_WITH_HOTEL:
        return chatWithHotel;
      case HotelBookingDetailsConstant.ACTION_RESEND_CONFIRMATION:
        return resendConfirmation;
      case HotelBookingDetailsConstant.ACTION_VALID_ID_PROOF:
        return validProof;
      case HotelBookingDetailsConstant.ACTION_CHECK_IN_AT_HOTEL:
        return checkinHotel;
      case HotelBookingDetailsConstant.ACTION_CALL_MMT_SUPPORT:
        return callMMTSupport;
      case HotelBookingDetailsConstant.ACTION_GO_TO_HELP_SELECTION:
        return gotoHelp;
      case HotelBookingDetailsConstant.ACTION_CHAT_WITH_US:
        return chatWithUs;
      case HotelBookingDetailsConstant.ACTION_MAKE_REQUEST:
        return makeRequest;
      default:
        return {uri: `https://promos.makemytrip.com/MIMA/${actionFamily}.webp`};
    }
  }
}

export const REFUND_TRACKER = {
  refundInitiated: 'Refund Initiated',
  refundProcessed: 'Refund Processed',
  refundSuccess: 'Refund Success'
};

export function getRefundProgressStyle(refundStatus) {
  const progressStyles = [];
  const styles = getStyles();
  switch (refundStatus) {
    case REFUND_TRACKER.refundInitiated:
      progressStyles.push(styles.refundHalfbar);
      break;
    case REFUND_TRACKER.refundProcessed:
    case REFUND_TRACKER.refundSuccess:
      progressStyles.push(styles.refundFullbar);
      break;
    default:
      break;
  }
  progressStyles.push(styles.refundGreenBackground);
  return progressStyles;
}

export function getRefundIcons(refundStatus) {
  const image = [];
  const styles = getStyles();
  image.push(<Image
    key="PromiseSinleTickImage"
    style={styles.aminitiesIcon}
    source={tick}
  />);
  switch (refundStatus) {
    case REFUND_TRACKER.refundProcessed:
    case REFUND_TRACKER.refundSuccess:
      image.push(<Image
        key="PromiseDoubleTickImage"
        style={styles.aminitiesIcon}
        source={doubleTick}
      />);
      break;
    default:
      break;
  }
  return image;
}

export function getRefundStatusLine(refundStatus) {
  let refundStatusLine = 'IN PROGRESS';
  switch (refundStatus) {
    case REFUND_TRACKER.refundInitiated:
      refundStatusLine = 'IN PROGRESS';
      break;
    case REFUND_TRACKER.refundProcessed:
    case REFUND_TRACKER.refundSuccess:
      refundStatusLine = 'COMPLETE';
      break;
    default:
      break;
  }
  return refundStatusLine;
}

export function getBusSubTitleForStickyHeader(busDetailResponse) {
  return `${fillDepartureArrivalDate(busDetailResponse.busAdditionalInfo.departureDetail.departureDate, 'DD MMM')} | ${busDetailResponse.busBookingInfo.noOfPassengers} Travellers | ${busDetailResponse.busBookingInfo.busDetail.operator}`;
}

export function getTopCardSubTextPartialCancelled(response) {
  let refundStatusLine = '';
  let paxCancelled = 0;
  const passengers = response.busBookingInfo.passengerDetails;
  passengers.forEach((passengerDetail) => {
    if (passengerDetail.status === 'Cancelled') {
      paxCancelled += 1;
    }
  });
  if (paxCancelled === 0) {
    refundStatusLine = '';
  } else if (paxCancelled === 1) {
    refundStatusLine = '1 Seat Cancelled';
  } else {
    refundStatusLine = `${paxCancelled} Seats Cancelled`;
  }
  return refundStatusLine;
}

export function getOperatorPhoneNumber(response) {
  const intRegex = INDIAN_PHONE_NUMBERS;
  const phoneNumber = response.busBookingInfo.busDetail.contactNumbers.match(intRegex);
  if (!phoneNumber) { return BusBookingDetailsConstant.HELPLINE_NUMBER; }
  return phoneNumber[0];
}

export const passengerName = (passenger) => {
  const fullName = passenger.name ? passenger.name : '';
  return fullName;
};

export const passengerImage = (passenger) => {
  let paxImage = maleImage;
  if (passenger && passenger.gender) {
    switch (passenger.gender) {
      case 'Female':
      case 'Sr. Citizen Female':
        paxImage = femaleImage;
        break;
      case 'Male':
      case 'Sr. Citizen Male':
      default:
        break;
    }
  }
  return paxImage;
};

export const seatNumberInfo = (passenger) => {
  const seatno = `${passenger.seatNumber} ${convertStringtoTitleCase(passenger.seatType)}`;
  return seatno;
};


export const passengerAgeAndGenderText = (passenger) => {
  let completeText = passenger.age ? (`${passenger.age}yr`) : '';
  if (passenger.paxType) {
    switch (passenger.paxType) {
      case 'Male':
        completeText = `${completeText} Male`;
        break;
      case 'Sr. Citizen Male':
        completeText = `${completeText} Sr. Male`;
        break;
      case 'Female':
        completeText = `${completeText} Female`;
        break;
      case 'Sr. Citizen Female':
        completeText = `${completeText} Sr. Female`;
        break;
      case 'Child':
        completeText = `${completeText} Child`;
        break;
      case 'Infant':
        completeText = `${completeText} Infant`;
        break;
      default:
        break;
    }
  }
  return completeText;
};

export const allPaxList = (bookingDetails) => {
  if (bookingDetails) {
    const paxList = bookingDetails.busBookingInfo.passengerDetails;
    return paxList;
  }
  return null;
};

export const getBankName = (bookingDetails) => {
  let bankName = 'Payment Bank';
  let myWallet = '';
  if (bookingDetails) {
    const paymentDetails = bookingDetails.bookingPaymentDetail.paymentDetails;
    paymentDetails.forEach((paymentDetail) => {
      if ((paymentDetail.paymentGatewayCode) && !(paymentDetail.paymentGatewayCode === BusBookingDetailsConstant.BUS_MYWALLET)) {
        bankName = paymentDetail.bankName;
      }
      if ((paymentDetail.paymentGatewayCode) && (paymentDetail.paymentGatewayCode === BusBookingDetailsConstant.BUS_MYWALLET)) {
        myWallet = 'MyWallet';
      }
    });
  }
  return {bankName, myWallet};
};

export const isMyWallet = (bookingDetails) => {
  let mywallet = false;
  let bankPayment = false;
  if (bookingDetails) {
    const paymentdetails = bookingDetails.bookingPaymentDetail.paymentDetails;
    paymentdetails.forEach((paymentDetail) => {
      if (paymentDetail.paymentGatewayCode && paymentDetail.paymentGatewayCode === BusBookingDetailsConstant.BUS_MYWALLET) {
        mywallet = true;
      } else if (!(paymentDetail.paymentModeName && paymentDetail.paymentModeName === BusBookingDetailsConstant.BUS_ECOUPON)) {
        bankPayment = true;
      }
    });
  }

  if (mywallet && !bankPayment) {
    return true;
  }

  return false;
};


export const isMyWalletOnly = (refundCommDetail) => {
  const len = refundCommDetail.refundSegmentList.length;
  if (len === 1) {
    if (refundCommDetail.refundSegmentList[0].bankName === BusBookingDetailsConstant.BUS_MyWALLET) { return true; }
  }
  return false;
};

export const onFullCancelTapped = (bookingResponse, mmtAuth1, loggingTrackInfo1) => {
  // RailBookingTrackingHelper.trackClickEvent('TripDetails', this.props.response, 'fullCancelClicked');
  const cancellablepassengers = cancellablePaxList(bookingResponse);
  Actions.busreviewRefundCharges({
    bookingDetails: bookingResponse,
    selectedPax: cancellablepassengers,
    mmtAuth: mmtAuth1,
    loggingTrackInfo: loggingTrackInfo1,
    isFullCancellation: true
  });
};
export const onPartialCancelTapped = (bookingResponse, mmtAuth1, loggingTrackInfo1) => {
  // RailBookingTrackingHelper.trackClickEvent('TripDetails', this.props.response, 'partCancelClicked');

  const cancellablepassengers = cancellablePaxList(bookingResponse);
  if (cancellablepassengers && cancellablepassengers.length > 1) {
    Actions.busCancellationSelectPax({
      passengers: cancellablepassengers,
      bookingDetails: bookingResponse,
      mmtAuth: mmtAuth1,
      loggingTrackInfo: loggingTrackInfo1,
      showNoCancel: false
    });
  } else {
    // Show the full cancel
    onFullCancelTapped(bookingResponse, mmtAuth1, loggingTrackInfo1);
  }
};

export function cancellablePaxList(bookingDetails) {
  if (bookingDetails) {
    const paxList = bookingDetails.busBookingInfo.passengerDetails;
    return paxList.filter(paxDetails => (paxDetails.status !== 'Cancelled'));
  }

  return null;
}

/* utils for bus live tracking data */

export function getOriginLatLon(busLiveData) {
  if (isEmpty(busLiveData)) {
    return {};
  }
  return {
    latitude: busLiveData.routeDirection.locations[0].latitude,
    longitude: busLiveData.routeDirection.locations[0].longitude,
    latitudeDelta: CabBookingDetailsConstant.LATITUDE_DELTA,
    longitudeDelta: CabBookingDetailsConstant.LONGITUDE_DELTA
  };
}

export function getDestLatLon(busLiveData) {
  if (isEmpty(busLiveData)) {
    return {};
  }
  const len = busLiveData.routeDirection.locations.length;
  const destLatLon = busLiveData.routeDirection.locations[len - 1];
  return destLatLon;
}

export function getPolyLineData(busLiveData) {
  if (isEmpty(busLiveData)) {
    return [];
  }
  return busLiveData.routeDirection.locations;
}

export function getCurrentLocation(busLiveData) {
  if (isEmpty(busLiveData)) {
    return [];
  }
  return {
    latitude: busLiveData.vehicleInfo.position.latitude,
    longitude: busLiveData.vehicleInfo.position.longitude
  };
}

export function getRotationAngle(busLiveData) {
  if (isEmpty(busLiveData)) {
    return 0;
  }
  return busLiveData.vehicleInfo.position.rotationAngle;
}
export function getOperatorNumber(busLiveData) {
  if (isEmpty(busLiveData) || isEmpty(busLiveData.vehicleInfo.contactNumber)) {
    return [];
  }
  return [busLiveData.vehicleInfo.contactNumber[0]];
}

export function getPollFreqInSec(busDetailResponse, freqSec) {
  if (isEmpty(busDetailResponse) && isEmpty(busDetailResponse.busLiveTrackingInfo)) {
    return freqSec;
  }
  return busDetailResponse.busLiveTrackingInfo.pollingFreqInSeconds;
}

export function getPollFreqInMin(busDetailResponse, freqMin) {
  if (isEmpty(busDetailResponse) && isEmpty(busDetailResponse.busLiveTrackingInfo)) {
    return freqMin;
  }
  return busDetailResponse.busLiveTrackingInfo.pollingDurationInMinutes;
}

export function getInitialRegion(busLiveData) {
  if (isEmpty(busLiveData)) {
    return {};
  }
  const {latitude, longitude} = getCurrentLocation(busLiveData);
  return {
    latitude,
    longitude,
    latitudeDelta: CabBookingDetailsConstant.LATITUDE_DELTA,
    longitudeDelta: CabBookingDetailsConstant.LONGITUDE_DELTA
  };
  
}

export const isReturnTripVisible = (response = {}) => {
  const { specialOfferCardInfo } = response || {};
  const cardList = get(response, 'layoutDetail.cardList', []);
  const specialOffercard =
    !isEmpty(cardList) &&
    cardList.find(
      (card) =>
        card.id === BusBookingDetailsConstant.specialOffercard &&
        card.isVisible,
    );
  return !isEmpty(specialOfferCardInfo) && !isEmpty(specialOffercard);
};

export const isActionAvailbleInTopCard = (response = {}, actionFamilys) => {
  const { layoutDetail } = response;
  const { cardList } = layoutDetail || {}
  if (Array.isArray(cardList) && cardList.length) {
    const { actionList } = cardList[0];
    if (Array.isArray(actionList)){
      for(let i in actionList) {
        if (typeof actionList[i] === 'object' && actionList[i].hasOwnProperty('actionFamily')) {
          const { actionFamily } = actionList[i];
          if (actionFamilys.includes(actionFamily)) {
            return true;
          }
        }
      }
    }
  }
  return false;
}

export const isCallButtonVisibleHelpSection = (response = {}) => {
  const { helpAndSupport } = response;
  const { actionList } = helpAndSupport || {};
  if (Array.isArray(actionList)) {
    for(let i in actionList) {
      if(actionList[i]) {
        if (actionList[i].actionId === BusBookingDetailsConstant.CALL_SPECIALIST_TEAM) {
          return true;
        }
      }
    }
  }
  return false;
}