import myTripsNavigation from '../../../../navigation/navigation';
import { isEmpty } from 'lodash';
import { handleCTAClick, getMyRequestCardData } from '../../../utils';
import { titleCase } from '@mmt/legacy-commons/Helpers/stringHelpers';
import { FlightData, FlightSummaryDataProps, MsrTicket, SegInt } from '../cardInterfaces';
import { FlightConstants } from '../../../summaryConstants';

const getTravelerSummary = (flightDetails) => {
  const { passengers } = flightDetails;
  const count = passengers.length;
  const firstPaxName = titleCase(passengers[0].firstName);
  if (count === 1) {
    return firstPaxName;
  }
  return `${firstPaxName} + ${count - 1}`;
};

const getFlightAirlineName = (flightDetails) => {
  const uniqueAirlineNames = [...new Set(flightDetails.map(flightSegment => flightSegment.airLineName))];
  const count = uniqueAirlineNames.length;
  if (count > 1) {
    return `${flightDetails[0].airLineName} + ${count - 1}`;
  }
  return uniqueAirlineNames;
};

function getFlightsSummaryData(data: FlightData, msrTicket: MsrTicket): FlightSummaryDataProps {
  let from,
    to;
  let airLineDetails, passengerDetails, logoUrl, flightStartDate, flightEndDate, flightStartTime, flightEndTime, journeyTypeText, showRoundTripIcon;
  const { ctaInfoList: ctaDetails, infoCard } = data && data.bookingStateLayout || {};
  const actions = [];
  const pageData = {
    fromMyTrips: true,
  };
  const startsIn = data.startsIn;
  const lobName = data.lobType[0];
  const bookingId = data.bookingID;
  const uniqueLogoUrls = Array.from(new Set(data.flightSegment.map(segment => segment.logoUrl)));
  const openDetails = (trackClickEvent = true) => {
    myTripsNavigation.openFlightBookingDetail(data, trackClickEvent, pageData);
  };
  if (!isEmpty(data.flightSegment)) {
    const [flightSegment] = data.flightSegment;
    airLineDetails = getFlightAirlineName(data.flightSegment);
    passengerDetails = getTravelerSummary(flightSegment);
    logoUrl = uniqueLogoUrls.length === 1 ? uniqueLogoUrls[0] : uniqueLogoUrls;
    flightStartDate = flightSegment.startDateText;
    flightEndDate = flightSegment.endDateText;
    flightStartTime = flightSegment.startTimeText;
    flightEndTime = flightSegment.endTimeText;
    const citiesCode = data.intent && data.intent.split('-');
    from = data.flightSegment.some((seg: SegInt) => seg.origin === citiesCode[0])
      ? data.flightSegment.find((seg: SegInt) => seg.origin === citiesCode[0]).originCityName
      : flightSegment.originCityName;
    to = data.flightSegment.some((seg: SegInt) => seg.destination === citiesCode[1])
      ? data.flightSegment.find((seg: SegInt) => seg.destination === citiesCode[1]).destinationCityName
      : flightSegment.destinationCityName;
  }
  showRoundTripIcon = data.journeyType === FlightConstants.RoundTrip;
  if (data.journeyType === FlightConstants.RoundTrip || data.journeyType === FlightConstants.MultiCity) {
    journeyTypeText = data.journeyText;
  }

  let iconUrl = '';
  const { text, ctaInfo } = infoCard || {};
  const infoCardStatusText = text;
  const infoCardStatusCTA = ctaInfo && ctaInfo.text;
  if (ctaDetails && ctaDetails[0]) {
    actions.push({ label: ctaDetails[0]?.text, onPress: () => handleCTAClick(ctaDetails[0], data) })
  };
  if (ctaDetails && ctaDetails[1]) {
    actions.push({ label: ctaDetails[1]?.text, onPress: () => handleCTAClick(ctaDetails[1], data) })
  };

  const handleInfoClick = () => {
    if (infoCard) {
      handleCTAClick(infoCard.ctaInfo, data);
    }
  };

  return {
    status: data.status,
    airLineDetails,
    passengerDetails,
    logoUrl,
    flightStartDate,
    flightEndDate,
    flightStartTime,
    flightEndTime,
    startsIn,
    lobName,
    bookingId,
    iconUrl,
    from,
    to,
    actions,
    infoCardStatusText,
    infoCardStatusCTA,
    openDetails,
    myRequestsCard: getMyRequestCardData(msrTicket),
    handleInfoPress: handleInfoClick,
    showRoundTripIcon,
    journeyTypeText,
  };
}

export default getFlightsSummaryData;