import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import { getCommonHeaders, HELPING_HAND_URL } from "../../utils/NetworkUtils";

export const getCustomerSupportCardData = async (bookingId, uuid, pageName) => {
    const userInfo = await HotelBookingModule.getRequestHeader(bookingId, pageName);
    const payload = {
        bookingId,
        userUUID: uuid,
        pageName,
        channel: 'NEED_HELP',
    };
    try {
        const res = await fetch(HELPING_HAND_URL, {
            method: 'POST',
            headers: await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo),
            body: JSON.stringify(payload),
        });
        const data = await res.json();
        if (data.success) {
            return data;
        }
    }
    catch (err) {
        console.log('Error in fetching customer support card data', err);
    }
};