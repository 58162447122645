import React from 'react';
import PropTypes from 'prop-types';
import {Text, View} from 'react-native';
import styles from './styles/WarningMessageCss';

const WarningMessage = ({title}) => (
  <View style={styles.warningAddmsgCard}>
    <Text style={styles.warningAddmsg}>{title}</Text>
  </View>
);

WarningMessage.propTypes = {
  title: PropTypes.string.isRequired
};

export default WarningMessage;
