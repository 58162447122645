import React from 'react';
import { View, Text, Image } from 'react-native';
import styles from './TrackerCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getImagePath } from '../../../utils/PostSaleUtil';
import { isNonEmpty } from '@mmt/legacy-commons/Common/utils/StringUtils';
import HTMLView from 'react-native-htmlview';
import { onRefundCardHTMLLinkPress } from '../../../utils/RefundCardUtils';
import {colors} from '@mmt/legacy-commons/Styles/globalStyles';
import PostSalesBasePage from '../../PostSalesBasePage';
const tickIcon = getImagePath('refund_tracker_tickIcon.webp');
const crossIcon = getImagePath('errorRed.webp');
import { getStaticData } from '../../../staticData/staticData';

class Tracker extends PostSalesBasePage {
  constructor(props){
    super(props)
    this.state = {
      showViewMore: props?.refundModesMessageList?.map(() => false),
    };
  } 
  render() {
    const borderTopStyle = this.props.borderTopActive ? [styles.borderTopActive] : '';
    return (
      <View style={borderTopStyle}>
        <View style={styles.trackerContainer}>
          <View style={styles.flexShrink}>{this.getProgressStepViews(this.props)}</View>
        </View>
      </View>
    );
  }

  toggleViewMore(index){
    this.setState((prevState) => {
      const newShowViewMore = [...prevState.showViewMore];
      newShowViewMore[index] = !newShowViewMore[index];
      return { showViewMore: newShowViewMore };
    });
  }

  getProgressStepViews({ progressList, fonts, ...args }) {
    const {showViewMore} = this.state
    const noOfSteps = progressList.length;
    return progressList.map((progressStep, index) =>
      this.getProgressStepView({
        progressStep,
        index,
        noOfSteps,
        fonts,
        showViewMore,
        ...args,
      }),
    );
  }

  getProgressStepView({
    progressStep,
    index,
    noOfSteps,
    refundModesMessageList,
    onRefundModeSubMessageLinkPress,
    onRefundModeWebViewBackPress,
    bookingId,
    trackRefundEvent,
    navigation,
    isLostBookingId,
    fonts,
    showViewMore,
    allowViewMore
  }) {
    const parentStyle = [];
    const htmlStyle = {
      q: { /* fix fo Html Fonts Not working without adding blank tag */
      },
      p: {
        color: colors.grey,
        fontSize: 14,
        fontFamily: fonts.regularFontFamily,
        lineHeight: 18
      },
      a: {
        textDecorationLine: 'underline',
        color:colors. linkBlue
      }
    }
    const showIcon = progressStep.completed || progressStep.cancelled;
    const staticData = getStaticData(true)
    const getImage = (progressStep) => {
      if (progressStep.iconUrl) {
        return { uri: progressStep.iconUrl };
      }
      return progressStep.completed ? tickIcon : crossIcon;
    };
    parentStyle.push(styles.trackerDtls);
    if (progressStep.completed) {
      parentStyle.push(styles.trackerDtlsComplete);
    }
    if (index === noOfSteps - 1) {
      parentStyle.push(styles.lastItemStyle);
    }
    return (
      <View style={[parentStyle]}>
        <View style={styles.trackerInnerDtls}>
          <View style={showIcon ? styles.iconWrapper : styles.countWrapper}>
            {showIcon ? (
              <Image style={styles.showIcon} source={getImage(progressStep)} />
            ) : (
              <Text style={[styles.countTxt, fonts.boldFontFamily]}>{index + 1}</Text>
            )}
          </View>
          <View style={[!isLostBookingId && index === noOfSteps - 1 ? styles.specialContainer : '', styles.flexShrink]}>
            {!isLostBookingId && index === noOfSteps - 1 && <View style={styles.leftArrow} />}
            <Text
              style={[
                styles.trackerStatus,
                fonts.boldFontFamily,
                showIcon && !progressStep.completed ? AtomicCss.redText : null,
              ]}
            >
              {`${progressStep.heading} `}
            </Text>
            <Text style={[styles.trackerStatusTime, fonts.regularFontFamily]}>{progressStep.description}</Text>
            {index === noOfSteps - 1 && refundModesMessageList && (
              <View style={[styles.trackerStatusTime]}>
                {refundModesMessageList.map((item, index) => (
                  <View style={[AtomicCss.marginBottom10, AtomicCss.marginTop8]} key={index}>
                    {isNonEmpty(item.message) && (
                      <Text
                        style={[
                          AtomicCss.defaultText,
                          fonts.boldFontFamily,
                          AtomicCss.font14,
                          AtomicCss.lineHeight20,
                          AtomicCss.marginBottom3,
                        ]}
                      >
                        {item.message}
                      </Text>
                    )}
                    {((isNonEmpty(item.subMessage) && !showViewMore[index]) || !allowViewMore) && (
                      <HTMLView
                        value={item.subMessage}
                        onLinkPress={() =>
                          onRefundCardHTMLLinkPress(
                            onRefundModeSubMessageLinkPress,
                            onRefundModeWebViewBackPress,
                            bookingId,
                            trackRefundEvent,
                            navigation,
                          )
                        }
                        stylesheet={htmlStyle}
                      />
                    )}
                    {(isNonEmpty(item.subMessage) && allowViewMore) &&
                      <Text onPress={()=> this.toggleViewMore(index)} style={[AtomicCss.boldFont, styles.trackerStatusTime, AtomicCss.azure]}>
                        {showViewMore[index] ? staticData.viewMoreText : staticData.viewLessText}
                      </Text>
                    }
                  </View>
                ))}
              </View>
            )}
          </View>
        </View>
      </View>
    );
  }
}
export default Tracker;
