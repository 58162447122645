import {fonts} from '@mmt/legacy-commons/Styles/globalStyles';

const styles = {

  FullPageErrorPage: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 35,
    paddingVertical: 60,
    backgroundColor: '#fff'
  },

  ErrorImg1: {
    width: 63,
    height: 42
  },
  ErrorHeading: {
    fontSize: 20,
    lineHeight: 26,
    color: '#4a4a4a',
    fontFamily: fonts.bold,
    textAlign: 'center'
  },
  ErrorDisc: {
    fontSize: 14,
    fontFamily: fonts.regular,
    textAlign: 'center',
    color: '#747474',
    justifyContent: 'center',
    lineHeight: 20
  },
  lastDisc: {
    marginBottom: 80
  },
  adTechCardView: {
    marginTop: 10
  }
};

export default styles;
