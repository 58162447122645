import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { Image, StyleSheet, Text, View, TouchableOpacity, Easing, Animated } from 'react-native';
import { isEmpty } from 'lodash';
import getStyles from './HotelMamiCss';
import {
  getActionButton,
  handleClickEvent,
  isNotNullAndEmpty,
  getThrottleParams
} from '../utils/HotelBookingDetailUtil';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {colors} from '@mmt/legacy-commons/Styles/globalStyles';
import BankOfferCard from '../cards/BankOfferCard/BankOfferCard'
import HotelBookingDetailsConstant from '../HotelBookingDetailsConstant';
import PaymentCardExtraCharges from './PaymentCardExtraCharges';
import CashbackBanner from './CashbackBanner';
import HotelBookingTrackingHelper from '../../HotelBookingTrackingHelper';
import RedirectLink from '../../../Common/RedirectLink';
import { BLACK_INFO_ICON, PAYMENT_CARD_NEW_ICON, FLYER_EXCLUSIVE_ICON } from '../../HotelImageConstants';
import { getStaticData } from '../../../staticData/staticData';
import { copyToClipboard } from 'apps/post-sales/src/Common/commonUtil';
import { showLongToast } from '@mmt/legacy-commons/Common/Components/Toast';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import useApi from '../../../Common/useApi';
import {HOTEL_AUTO_DEBIT_MANDATE_URL} from '../../../utils/NetworkUtils';
import ProgressView from '../../../Common/ProgressView/ProgressView';
import FullPageOverlay from '../../../Common/FullPageOverlay';
import ViewState from 'packages/legacy-commons/Common/constants/ViewState';

function HotelPaymentCard({bookingId, response,showPayNow,pageName,card,actionHandlerGB,handlePAHCard, setDownloadInvoice}) {
  const { fsStyle, ...fonts } = getFont(true);
  const styles = getStyles(fonts);
  const cashbackStyle = getCashbackStyles(fonts);

  const [autoMandateInProgress, autoMandateResponse, autoMandateApi] = useApi(
    `${HOTEL_AUTO_DEBIT_MANDATE_URL}${bookingId}`,
  )
  const staticData = getStaticData();
  const {paymentDetails} = response.paymentDetails;
  const {pricingBreakup, paymentBreakup,cashbackInformationList, discountDeal, tajExclusiveIcon = "", showBankOfferCard = false, rtbStatus} = response || {};
  const { bankOfferCard } = paymentBreakup || {}

  useEffect(()=>{
    const {paymentBreakup, showBankOfferCard} = response;
    const { PAY_NOW_WITH_BANK_OFFER_SHOWN, PAY_NOW_SHOWN} = HotelBookingDetailsConstant;
    const eventValue = showBankOfferCard ? PAY_NOW_WITH_BANK_OFFER_SHOWN : PAY_NOW_SHOWN;
    if (paymentBreakup.bnplCardChargeInfo && showPayNow) {
      HotelBookingTrackingHelper.trackClickEvent(pageName, eventValue, response);
    }
  },[])

  const fetchAutoMandate = async(autoMandateResponse) =>{
    if (autoMandateResponse) {
      if (autoMandateResponse.mandateUrl && autoMandateResponse.status === SUCCESS) {
        const userInfo = await HotelBookingModule.getRequestHeader(bookingId, 'HOTEL_AUTO_DEBIT_MANDATE');
        Actions.openPsWebView({
          url: autoMandateResponse.mandateUrl,
          headerText: staticData.setupAutoPay,
          headerIcon: WHITE_LEFT_ARROW_ICON,
          injectedJavaScript: setWebViewAuth(userInfo.mmtAuth)
        });
      } else {
        const errorObj = {
          showRetry: true,
          onRetry: ()=> {
            autoMandateApi.httpGet();
          },
          message: staticData.errorText.somethingWentWrongTryAgainError,
          buttonLabel: staticData.tryAgainText
        }
        Actions.upiMandateErrorView(errorObj);
      }
    }
  }
  
  useEffect(()=>{
    fetchAutoMandate(autoMandateResponse)
  },[autoMandateResponse])

  const onCopyOfferCode = async (offerCode) => {
    const { copiedToClipboardText } = getStaticData(true);
    HotelBookingTrackingHelper.trackClickEvent(this.props.pageName, HotelBookingDetailsConstant.BANK_COUPON_COPIED, this.props.response);
    const copyStatus = await copyToClipboard(offerCode);
    if (copyStatus) {
      showLongToast(copiedToClipboardText);
    }
  };

  const handleClick = (action) => {
    if(action.actionFamily === HotelBookingDetailsConstant.ACTION_AUTO_DEBIT_MANDATE){
      autoMandateApi.httpGet();
      const clickEvent = `MI_HTL_${action.actionFamily}`;
      HotelBookingTrackingHelper.trackClickEvent(pageName, clickEvent, response);
    }
    if (action.actionFamily === HotelBookingDetailsConstant.ACTION_DOWNLOAD_INVOICE) {
      setDownloadInvoice();
    }
    else {
      handleClickEvent(card, { ...action, actionHandler: actionHandlerGB }, response, pageName);
    }
  };

  const invokeAmountPaidUI = (paymentBreakupMap) => {
    const views = [];
    if(paymentBreakupMap){
      for (let componentName in paymentBreakupMap) {
        views.push
        (
          <View key={componentName} style={[styles.Paymentrow, styles.mar4Bt]}>
            <Text style={[styles.PaymentLeft, styles.DefaultText, AtomicCss.font12, AtomicCss.lineHeight24, styles.italic, AtomicCss.alignLeft]}>- {componentName}</Text>
            {!isEmpty(paymentBreakupMap[componentName]) && <Text style={[styles.DefaultText, AtomicCss.font14, AtomicCss.lineHeight24, styles.italic, AtomicCss.alignLeft]}>{`${paymentBreakupMap[componentName]} `}</Text>}
          </View>
        )
      }
    }
    return views;
  }
  
  const showCashBackCard = () => {
    return !isEmpty(response.couponInformation);
  }

  const showExtraCharges = (extraChargeTotal) => {
    return(
      <PaymentCardExtraCharges
        response={response}
        extraChargeTotal={extraChargeTotal}
        handleClickIcon={handleClickIcon}
      />
    )
  }

  const handleClickIcon = (heading) => {
    handleClick({actionFamily: HotelBookingDetailsConstant.ACTION_SEE_PAYMENT_BREAK_UP_EXTRA_CHARGES, actionLabeltext: heading});
  }

  const showPaymentAmount = (paymentAmount) => {
    return (
      <View style={[styles.chargesInfoWrapper, AtomicCss.marginBottom4]}>  
        <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween]}>
          <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
            <Text style={[styles.gstTxt, AtomicCss.spaceBetween]}>{paymentAmount.text}</Text>
          </View>
          <Text style={[styles.gstTxt, AtomicCss.font14]}>{`${paymentAmount.value} `}</Text>
        </View>
        {!isEmpty(paymentAmount.subText) && <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
          <Text style={[styles.extraChargeTxt, AtomicCss.spaceBetween]}>{paymentAmount.subText}  </Text>
        </View>}
      </View>
    );
  }

  const renderProgressView = () => {
    const { loadingText } = getStaticData(true, true);
    return (
      <FullPageOverlay>
           <ProgressView message={loadingText} />
      </FullPageOverlay>
       
    );
 }

    return (
      <View style={[AtomicCss.whiteCardNew, AtomicCss.marginHz12, AtomicCss.borderRadius16]}>
        {autoMandateInProgress && renderProgressView()}
        <View style={styles.CardContentNew}>
          <View style={[styles.ContentPortion]}>
            <View>
              {isEmpty(paymentBreakup.bnplCardChargeInfo) &&
                <View style={[styles.Paymentrow, AtomicCss.marginBottom18]}>
                  <View style={[AtomicCss.flex1, AtomicCss.flexRow, AtomicCss.spaceBetween]}>
                    <Text style={[styles.BlackText, styles.blackFont, fsStyle.font16, AtomicCss.lineHeight20]}>{paymentBreakup.paymentBreakupHeading}</Text>
                  </View>
                </View>
              }
              <View style={[ AtomicCss.flexRow, styles.flyerExWrapper, AtomicCss.justifyEnd]}>
                { !isEmpty(discountDeal) && !isEmpty(discountDeal.title) &&
                  <>
                    <Image source={FLYER_EXCLUSIVE_ICON} style={styles.myIcon} />
                    <View style={[styles.flyerStyleWrapper, {borderColor: discountDeal.colorCode}]}>
                      <Text style={[styles.flyerStyle, fsStyle.font12, fonts.boldFontFamily, {color: discountDeal.colorCode, paddingHorizontal:3}]}>{discountDeal.title}</Text>
                    </View>
                  </>
                }
                { !!tajExclusiveIcon && <Image source={{uri: tajExclusiveIcon}} style={[styles.tagExclusiveImg, AtomicCss.marginBottom5, AtomicCss.marginLeft8]} /> }
              </View>
              <View style={[styles.Paymentrow]}>
                <View style={styles.PaymentLeft}>
                  <Text style={[styles.BlackText, styles.blackFont, fsStyle.font16, AtomicCss.lineHeight20, AtomicCss.alignLeft]}>{paymentBreakup.paymentBreakupTotalPrice.text}</Text>
                </View>
                <Text
                  style={[styles.BlackText, styles.blackFont, fsStyle.font16, AtomicCss.lineHeight20, AtomicCss.alignLeft]}
                >{`${paymentBreakup.paymentBreakupTotalPrice.value} `}
                </Text>
              </View>
              <View style={[styles.mar10Bt]}>
                {pricingBreakup && pricingBreakup.hotelCurrencyCost && pricingBreakup.hotelCurrencyCost.text && (
                  <View style={[styles.Paymentrow, styles.font12]}>
                    <View style={styles.PaymentLeft}>
                      <Text style={AtomicCss.alignLeft}>{pricingBreakup.hotelCurrencyCost.text}</Text>
                    </View>
                    <View>
                      <Text style={[AtomicCss.alignLeft, styles.font14]}>{`${pricingBreakup.hotelCurrencyCost.value} `}</Text>
                    </View>
                  </View>
                )}
              </View>

              {!isEmpty(paymentBreakup.paymentBreakupAmountPaid) && <View style={[styles.Paymentrow, styles.mar4Bt]}>
                <View style={styles.PaymentLeft}>
                  <Text style={[styles.DefaultText, fsStyle.font12, AtomicCss.lineHeight24, AtomicCss.alignLeft]}>{paymentBreakup.paymentBreakupAmountPaid.text}</Text>
                </View>
                <Text
                  style={[styles.DefaultText, fsStyle.font14, AtomicCss.lineHeight24, AtomicCss.alignLeft]}
                >{`${paymentBreakup.paymentBreakupAmountPaid.value} `}
                </Text>
              </View>}
              <View
                style={[isNotNullAndEmpty(paymentDetails) ? styles.showView : styles.hideView]}
              >
                {invokeAmountPaidUI(paymentBreakup.paymentBreakupMap)}
              </View>
              {!isEmpty(paymentBreakup.paymentBreakupToBePaid) && <View style={[styles.Paymentrow, styles.mar4Bt]}>
                <View style={[styles.PaymentLeft,AtomicCss.flexRow, AtomicCss.alignCenter]}>
                  <Text style={[styles.DefaultText, AtomicCss.font12, AtomicCss.lineHeight24, AtomicCss.alignLeft]}>{paymentBreakup.paymentBreakupToBePaid.text}</Text>
                  <TouchableOpacity style={AtomicCss.marginLeft5} onPress={actionHandlerGB}>
                    <Image style={styles.IconGrey} source={BLACK_INFO_ICON} />
                  </TouchableOpacity>
                </View>
                <Text
                  style={[styles.DefaultText, fsStyle.font14, AtomicCss.lineHeight24, AtomicCss.alignLeft]}
                >{`${paymentBreakup.paymentBreakupToBePaid.value} `}
                </Text>
              </View>}
              {paymentBreakup.paymentAmountToBePaid && paymentBreakup.paymentAmountToBePaid.text && showPaymentAmount(paymentBreakup.paymentAmountToBePaid)}
              {!isEmpty(paymentBreakup.paymentBreakupInstallmentsPaid) &&
                !isEmpty(paymentBreakup.paymentBreakupInstallmentsPaid.text) &&
                <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, styles.mar4Bt]}>
                  <Text style={[styles.DefaultText, AtomicCss.font12, AtomicCss.lineHeight24, styles.italic, AtomicCss.alignLeft]}>{paymentBreakup.paymentBreakupInstallmentsPaid.text}</Text>
                  <TouchableOpacity style={AtomicCss.marginLeft5} onPress={actionHandlerGB}>
                    <Image style={styles.IconGrey} source={BLACK_INFO_ICON} />
                  </TouchableOpacity>
                </View>
              }
              {paymentBreakup.extraChargeTotal && showExtraCharges(paymentBreakup.extraChargeTotal)}
              {paymentBreakup.msg && paymentBreakup.msg.length > 0 &&
                paymentBreakup.msg.map(message => (<Text style={[AtomicCss.marginTop2, styles.bnplCardInfoTxt]}>{message}</Text>))
              }
              {showBankOfferCard && <BankOfferCard bankOfferCard={bankOfferCard} clickPayNow={this.props.handlePAHCard} copyOfferCode={onCopyOfferCode}/>}

              {showCashBackCard() &&
              <View>
                <View style={[cashbackStyle.CardContent, AtomicCss.flexRow, AtomicCss.marginTop5]}>
                  <View>
                    <View style={response.couponInformation.couponName ? AtomicCss.flexRow : styles.hideView}>
                      <Text style={[cashbackStyle.discountText, AtomicCss.boldFont]}>{response.couponInformation.couponType}
                      </Text>
                      <Text
                        style={[cashbackStyle.couponTxt, AtomicCss.regularFont]}
                      >{response.couponInformation.couponName}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
              }
            </View>
          </View>
        </View>
        {paymentBreakup.bnplCardChargeInfo && showPayNow &&
          <RedirectLink
            content={staticData.hotelPaymentCardDetailsText.additionalText.payRemainingAmountText}
            clickHandler={() => { handlePAHCard(); }}
            isThrottleRequired={true}
            throttleInterval={1000}
            customStyle={styles.redirectLinkNewCustomStyles}
          />}
        {isNotNullAndEmpty(cashbackInformationList) &&
        cashbackInformationList.map((cashback) => {
          return (
            <CashbackBanner
              key={cashback.cashbackCode}
              iconUrl={cashback.iconUrl}
              cashbackStage={cashback.cashbackStage}
              cashbackText={cashback.cashbackText}
            />
          );
        })}
        {card && isNotNullAndEmpty(card.actionList) && 
          getActionButton(card, response, handleClick, getThrottleParams)}
      </View>
    );
  }

const getCashbackStyles = (fonts) => {
  return StyleSheet.create({
    CardContent: {
      backgroundColor: colors.white,
      flexDirection: 'row',
      marginTop: 10
    },
    discountText: {
      marginRight: 10,
      color: colors.green,
      ...fonts.font14
    },
    couponTxt: {
      ...fonts.font12,
      color: colors.greyLight,
      borderWidth: 1,
      borderColor: colors.darkGrey3,
      borderRadius: 5,
      marginTop: 0,
      marginBottom: 10,
      padding: 2
    },
    couponDiscription: {
      ...fonts.font12,
      color: colors.defaultTextColor
    }
  });
}
HotelPaymentCard.propTypes = {
  response: PropTypes.object.isRequired,
  card: PropTypes.object.isRequired,
  pageName: PropTypes.string.isRequired
};

export default HotelPaymentCard;
