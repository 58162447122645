import { StyleSheet, ViewStyle, TextStyle } from "react-native"

interface Theme {
    color: {
      black: string;
    };
}
  
interface Fonts {
    fontSize: { [key: string]: number };
    boldFontFamily: { [key: string]: object };
    regularFontFamily: { [key: string]: object };
}

interface Styles {
    pageWrapper: ViewStyle;
    successStyle: ViewStyle;
    buttonWrapper: ViewStyle;
    heading: TextStyle;
    desc: TextStyle;
    buttonBottomWrapper: ViewStyle;
  }
  
  
export const createStyles = (theme: Theme, fonts: Fonts) => {
    return StyleSheet.create<Styles>({
        pageWrapper:{
            flex: 1,
            justifyContent: 'center',
            paddingHorizontal: 20,
            alignItems: 'center'
        },
        successStyle: {
            width: 90,
            height: 90,
            resizeMode: 'contain',
        },
        buttonWrapper: {
            display:  'flex',
            height:  100,
            justifyContent:  'space-between'
        },
        heading: {
            fontSize: 18,
            ...fonts.boldFontFamily,
            color:  theme.color.black,
            textAlign: 'center',
            marginTop: 16,
            width: '70%'
    
        },
        desc: {
            fontSize: 16,
            ...fonts.regularFontFamily,
            color:  theme.color.black,
            textAlign: 'center',
            marginTop: 30,
        },
        buttonBottomWrapper: {
            width: 300,
            marginTop: 30,
        },
    })
}