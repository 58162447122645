import {HOTEL_DETAIL_ACTIONS} from './actions';

const createAction = (actionType, data) => {
  switch (actionType) {
    case HOTEL_DETAIL_ACTIONS.RESET_HOTEL_BOOKING_DETAIL:
      return {
        type: actionType,
        resetBookingId: data
      };
    case HOTEL_DETAIL_ACTIONS.SHOW_HELPING_HAND:
      return {
        type: actionType,
        pageNameForHelpingHand: data
      }
    case HOTEL_DETAIL_ACTIONS.FETCH_HOTEL_BOOKING_DETAILS_ENG:
    case HOTEL_DETAIL_ACTIONS.HOTEL_DETAILS_API_SUCCESS_ENG:
    case HOTEL_DETAIL_ACTIONS.HOTEL_BOOKING_DETAILS_API_ERROR_ENG:
      return {
        type: actionType,
        payload: data,
      }
    default:
      return {};
  }
};

export default createAction;
