import React, { memo } from 'react';
import PropTypes from 'prop-types';

import BaseButton, { BUTTON_VARIANT } from '../../../Common/BaseButton';

const ReviewButton = ({ buttonText, clickHandler, disabled }) => {
  return (
    <BaseButton
      variant={BUTTON_VARIANT.PRIMARY}
      clickHandler={clickHandler}
      text={buttonText}
      disabled={disabled}
    />
  );
};

ReviewButton.propTypes = {
  buttonText: PropTypes.string.isRequired,
  clickHandler: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default memo(ReviewButton);
