import React from 'react';
import {Image, Text, View} from 'react-native';
import getStyles from './HotelMamiCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {isNotNullAndEmpty, handleClickEvent, getActionButton} from '../utils/HotelBookingDetailUtil';
import { ROOMS_ICON } from '../../HotelImageConstants';
import { getStaticData } from '../../../staticData/staticData';
import HotelBookingDetailsConstant from '../HotelBookingDetailsConstant';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const GetMoreOutOfBookingCard = ({ response, card, odcActionHandler }) => {
	const { fsStyle, ...fonts } = getFont(true);
  const styles = getStyles(fonts);
	const staticData = getStaticData();

	const handleClick = (action) => {
		if(action.actionFamily === HotelBookingDetailsConstant.ACTION_ADD_GUEST && response && response.isCorporateBooking) {
			odcActionHandler(card, action, response, "");
		  } else {
			handleClickEvent(card, action, response, "");
		  }
  	};

	return (
		<View style={[AtomicCss.whiteCardNew, AtomicCss.marginHz12, AtomicCss.borderRadius16]}>
			<View style={[styles.headerSec, AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom12]}>
				<Image
					style={styles.CardIcon}
					source={ROOMS_ICON}
				/>
				<Text style={[fonts.blackFontFamily, fsStyle.font16, AtomicCss.blackText, AtomicCss.lineHeight20]}>
					{staticData.getMoreOutOfBookingCardText.heading}
				</Text>
			</View>
			<View style={[styles.ContentPortion, AtomicCss.marginBottom16, AtomicCss.paddingRight12, {paddingLeft: 44}]}>
				<Text style={[fsStyle.font14, AtomicCss.lineHeight20, styles.defaultGrey, AtomicCss.alignLeft]}>
					{staticData.getMoreOutOfBookingCardText.subHeading}
				</Text>
			</View>
			<View>
				{card && isNotNullAndEmpty(card.actionList) &&
					getActionButton(card, response, handleClick)}
			</View>
		</View>
	);
}

export default GetMoreOutOfBookingCard;
