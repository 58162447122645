import React from 'react';
import { Text, View, Image, TouchableOpacity, NativeModules } from 'react-native';

import createSyles from './myBizSwitcherCss';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import {
  MY_BIZ_LOGO,
} from '../../../../flights/FlightImageConstants';
import { useTheme } from '../../../../theme/theme.context';
import { getFont } from '../../../../PostSalesStyles';
import { MY_TRIPS_CORPORATE_DEEPLINK } from '../../summaryConstants';

const MybizSwitcher = ({bookingCountText}) => {
  const { psTheme: theme } = useTheme();
  const fonts = getFont(true);

  const styles = createSyles(theme, fonts);

  return (
    <>
      <TouchableOpacity
        style={styles.bookingNotificationWrapper}
        onPress={() => GenericModule.openDeepLink(MY_TRIPS_CORPORATE_DEEPLINK)}
      >
        <View style={styles.myBizNotification}>
          <Image style={styles.myBizLogo} source={MY_BIZ_LOGO} />
          <View style={styles.notification}>
            <Text style={styles.notificationText}>{bookingCountText}</Text>
          </View>
        </View>
      </TouchableOpacity>
    </>
  );
};

export default MybizSwitcher;

