import React from 'react';
import {View, Text, TouchableOpacity, Image} from 'react-native';
import PropTypes from 'prop-types';
import styles from './HeaderCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getFont } from '../../PostSalesStyles';

const crossIcon = require('@mmt/legacy-assets/src/close_icon.webp');

class Header extends React.Component {
  render() {
    const headerStyle = [styles.headerWrapper];
    if (this.props.headerShadow) {
      headerStyle.push(styles.headerShadow);
    }
    const fonts = getFont(true);

    return (
      <View style={headerStyle}>
        <TouchableOpacity style={styles.headerIconWrapper} onPress={() => this.props.handleClose()}>
          <Image style={[styles.crossIconStyle]} source={crossIcon} />
        </TouchableOpacity>
        <View style={[AtomicCss.flex1]}>
          <Text style={[styles.headingTxt, AtomicCss[this.props.headingTxt], fonts.font14, fonts.boldFontFamily]}>{this.props.title}</Text>
          {this.props.subTitle &&
          <Text style={[styles.headingSubTxt, fonts.font12, fonts.regularFontFamily]}>{this.props.subTitle}</Text>
              }
        </View>

      </View>
    );
  }
}
export default Header;

Header.propTypes = {
  headerShadow: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  headingTxt: PropTypes.string.isRequired,
  headingSubTxt: PropTypes.string,
  subTitle: PropTypes.string,
  handleClose: PropTypes.func.isRequired
};

Header.defaultProps = {
  headingSubTxt: undefined,
  subTitle: undefined
};
