import React from 'react';
import { View, Text, Image } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import createStyles from './style';
import { useTheme } from '../../../../../../../theme/theme.context';
import isEmpty from 'lodash/isEmpty';
import { getFont } from '../../../../../../../PostSalesStyles';

const PrimaryUserDetailsCard = ({ heading, description, email, phoneNo }) => {
  const fonts = getFont();
  const { psTheme: theme } = useTheme();
  const styles = createStyles(fonts, theme);
  return (
    <>
      {!isEmpty(heading) && <Text style={styles.headingText}>{heading}</Text>}
      {!isEmpty(description) && <Text style={styles.descriptionText}>{description}</Text>}
      {!isEmpty(phoneNo) && (
        <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
          <Image style={styles.contactIconStyle} source={{ uri: phoneNo.iconUrl }} />
          <Text style={styles.contactValue}>{phoneNo.value}</Text>
        </View>
      )}
      {!isEmpty(email) && (
        <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
          <Image style={styles.contactIconStyle} source={{ uri: email.iconUrl }} />
          <Text style={styles.contactValue}>{email.value}</Text>
        </View>
      )}
    </>
  );
};

export default PrimaryUserDetailsCard;
