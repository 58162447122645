import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { Dimensions } from 'react-native';
import HTMLView from 'react-native-htmlview';
import { getFont } from '../../../../../PostSalesStyles';
import { isEmptyArray, isNullOrEmptyObject } from '../../../../../Common/commonUtil';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { SPECIAL_CLAIM_ACITONS } from '../../constant';
import SectorCard from './components/SectorCard';
import { externalLinkPressHandler } from '../../../../../utils/PostSaleUtil';
import { getUploadDocumentsData } from '../../data/dataAdapter';
import { useTheme } from '../../../../../theme/theme.context';

const windW = Dimensions.get('window').width;

const UploadDocuments = ({
  handleBottomOverlay,
  uploadProofPageData,
  knowMoreText,
  uploadText,
  paxDetails,
  documentList,
  dispatchCancellationState,
  addMoreText,
  retryText,
  bookingId,
  ancUploadData,
}) => {
  const { fsStyle, ...fonts } = getFont(true);
  const {psTheme} = useTheme();
  const {color} = psTheme;
  const { title, subtitle, groupEntries, entries } = getUploadDocumentsData(uploadProofPageData);

  const openKnowMoreOverlay = () => {
    handleBottomOverlay(SPECIAL_CLAIM_ACITONS.know_more_upload);
  };

  const htmlViewStyle = {
    p: {
      ...fonts.regularFontFamily,
      fontSize: fonts.fontSize.font14,
      lineHeight: 20,
      color: color.defaultTextColor,
      marginBottom: 5,
    },
  };

  return (
    <View style={styles.cardWrapper}>
      <View style={styles.headingText}>
        <Text
          style={[
            fsStyle.font20,
            fonts.blackFontFamily,
            { color : color.black},
            AtomicCss.marginBottom8,
            AtomicCss.lineHeight28,
            AtomicCss.flex1,
          ]}
        >
          {title}
        </Text>
        <HTMLView
          stylesheet={htmlViewStyle}
          value={'<p>' + subtitle + '</p>'}
          onLinkPress={externalLinkPressHandler}
        />
        {!!knowMoreText && (
          <TouchableOpacity onPress={openKnowMoreOverlay}>
            <Text
              style={[
                fsStyle.font14,
                fonts.boldFontFamily,
                {color : color.azure},
                AtomicCss.lineHeight20,
                AtomicCss.flex1,
              ]}
            >
              {knowMoreText}
            </Text>
          </TouchableOpacity>
        )}
      </View>
      {!isEmptyArray(groupEntries) &&
        groupEntries.map((item, index) => {
          const isLastItem = index === groupEntries.length - 1;
          const documents = documentList[item.id];
          if (paxDetails.find((element) => item.id === element.id && element.selected)) {
            return (
              <SectorCard
                key={item.id}
                handleBottomOverlay={handleBottomOverlay}
                item={item}
                isLastItem={isLastItem}
                entries={entries}
                uploadText={uploadText}
                documents={documents}
                dispatchCancellationState={dispatchCancellationState}
                retryText={retryText}
                addMoreText={addMoreText}
                bookingId={bookingId}
              />
            );
          }
        })}
      {!isNullOrEmptyObject(ancUploadData) && (
        <SectorCard
          key={ancUploadData.id}
          handleBottomOverlay={handleBottomOverlay}
          item={ancUploadData}
          isLastItem={true}
          entries={ancUploadData.entries}
          uploadText={uploadText}
          documents={documentList[ancUploadData.id]}
          dispatchCancellationState={dispatchCancellationState}
          retryText={retryText}
          addMoreText={addMoreText}
          bookingId={bookingId}
        />
      )}
    </View>
  );
};
const styles = StyleSheet.create({
  cardWrapper: {
    width: windW - 55,
    paddingHorizontal: 5,
    marginBottom: 8,
  },
  headingText: {
    marginBottom: 24,
    flex: 1,
  },

  lineHeight24: {
    lineHeight: 24,
  },
});

export default UploadDocuments;
