import { useMemo } from 'react';

interface RefreshProps {
  isRefresh: boolean;
}

type UsePageRefreshProps = {
  refresh: RefreshProps;
  refreshDependency: string[];
  onRefresh?: () => void;
};

const usePageRefresh = ({ refresh, onRefresh, refreshDependency = [] }: UsePageRefreshProps) => {
  const safeRefreshDependency = refreshDependency ?? [];
  const uniqueKey = useMemo(() => {
      if (refresh?.isRefresh) {
        if (typeof onRefresh === 'function') {
          onRefresh();
        }
        return `${safeRefreshDependency.join('_')}_${Math.random()}`;
      }
      return safeRefreshDependency.join('_');
  }, [refresh, onRefresh, ...safeRefreshDependency]);
  return uniqueKey;
};

export default usePageRefresh;
