import React, { useEffect, useState } from "react";
import { ActionConst, Actions, history } from "./routerFluxMock";
import "../WebModules";
import { Provider } from "react-redux";
import { Route, Switch, Redirect } from "react-router";
import { View, I18nManager } from "react-native";
import { isEmpty } from "lodash";
import { Router } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import store from "../webStore";
import { injectAsyncReducer } from "@mmt/legacy-commons/AppState/asyncStorev2";
import {
  popRouter,
  pushRouter,
} from "@mmt/legacy-commons/Common/navigation/routerTypeRef";
import {
  ROUTE_PATHS,
  HOTELS_PATHS,
  FLIGHTS_PATHS,
  HOLIDAYS_PATHS,
  CAB_PATHS,
} from "./WebRouterConst";
import hotelDetailReducer from "@rn/apps/post-sales/src/hotel/reducer/hotelDetailReducer";
import trainReducer from "@rn/apps/post-sales/src/Rails/Reducer/TrainReducer";
import {
  _getMmtAuth,
  appendParams,
  _getAffiliateName,
  _isAffiliateUser,
  setSource,
  isRTL,
} from "../webUtils";
import ProgressView from "../components/ProgressView";
import flightListingReducer from "@rn/apps/post-sales/src/flights-funnel/Listing/utils/FlightsListingReducers";
import dateChangeReducer from "@rn/apps/post-sales/src/flights/modules/DateChange/Redux/PickDates/DateChangeReducer";
import customRouter from "./RouterCustomStack";
import { getURLParams } from "@rn/apps/post-sales/src/utils/NetworkUtils";
import GdprConsentForm from "MMT-UI/GdprConsentForm";
import busBookingDetailReducer from "@rn/apps/post-sales/src/bus/busBookingDetailReducer";
import { ESCALATE_ROUTES } from "../Modules/Escalate/constant";
import { setCookieByName } from "web/Modules/helper/networkUtil";
import { getAppData } from "@rn/apps/post-sales/src/utils/providerUtils";
import { RNAppDataProvider } from "core-rn-ui-factory";
import { RNAppDataProvider as TravelInsuranceDataProvider } from "@core_app_common/TravelInsuranceOld/commonComponents/AppDataProvider";

injectAsyncReducer("dateChangeReducer", dateChangeReducer);
injectAsyncReducer("flightListing", flightListingReducer);
injectAsyncReducer("hotelDetailReducer", hotelDetailReducer);
injectAsyncReducer("trainReducer", trainReducer);
injectAsyncReducer("busBookingDetailReducer", busBookingDetailReducer);

const directionMap = new Map();

export const withRouterState = (Component) => (props) => {
  if (validateParams(props)) {
    return (
      <Component
        {...props.location.state}
        {...props.match.params}
        {...props}
        {...fetchQueryParams(props.location.search)}
      />
    );
  }
  return null;
};

const validateParams = (props) => {
  const pathName = props.location.pathname;
  const search = props.location.search;
  const params = props.location.state;
  let bookingId = "";
  if (search) {
    const urlParams = new URLSearchParams(search);
    bookingId = urlParams.get("bookingId");
  }
  switch (pathName) {
    case ROUTE_PATHS.FLIGHT_CANCELLATION.pathName: {
      if (!params || isEmpty(params.data)) {
        Actions.flightBookingDetail({
          BOOKING_ID: bookingId,
          type: ActionConst.REPLACE,
        });
        return false;
      } else {
        return true;
      }
    }
    case ROUTE_PATHS.FLIGHT_CANCELLATION_REFUND_BREAKUP.pathName:
    case ROUTE_PATHS.FLIGHT_CANCELLATION_PAX_SELECTION.pathName:
      if (isEmpty(!params || params.cancellationPreviewResponseJson)) {
        Actions.flightBookingDetail({
          BOOKING_ID: bookingId,
          type: ActionConst.REPLACE,
        });
        return false;
      } else {
        return true;
      }
    case ROUTE_PATHS.FLIGHT_CANCELLATION_REFUND_MODE.pathName:
      if (!params || !validateFlightCancellationRefundModeParams(params)) {
        Actions.flightBookingDetail({
          BOOKING_ID: bookingId,
          type: ActionConst.REPLACE,
        });
        return false;
      } else {
        return true;
      }
    default:
      return true;
  }
};

const validateFlightCancellationRefundModeParams = (params) => {
  const {
    refundAmount,
    cancellationRefundMode,
    headerTitle,
    cancellationConfirmationText,
    penaltyResponse,
    cancellationRequest,
  } = params;
  return (
    refundAmount &&
    cancellationRefundMode &&
    headerTitle &&
    cancellationConfirmationText &&
    penaltyResponse &&
    cancellationRequest
  );
};

const fetchQueryParams = (search) => {
  if (search) {
    const urlParams = new URLSearchParams(search);
    let params = {
      BOOKING_ID: urlParams.get("bookingId"),
      bookingId: urlParams.get("bookingId"),
    };
    const act = urlParams.get("act");
    if (act) {
      switch (location.pathname) {
        case HOTELS_PATHS.HOTEL_BOOKING_DETAIL.pathName:
          params = {
            ...params,
            DEEP_LINK_PAGE: parseInt(act),
            documentVersion: urlParams.get("version"),
          };
          break;
        case FLIGHTS_PATHS.FLIGHT_BOOKING_DETAIL.pathName:
          params = {
            ...params,
            deepLinkData: JSON.stringify({
              deepLinkPage: parseInt(act),
              deepLinkExtraData: {
                CMP: urlParams.get("CMP"),
                SCCODE: urlParams.get("SCCODE"),
              },
            }),
          };
          break;
      }
    }
    return params;
  }
  return null;
};

const getPageKey = () => {
  let urlParams = new URLSearchParams(location.search);
  const isBookingIdPresent = urlParams.get("bookingId");
  if (isBookingIdPresent) {
    const isHotelBooking = Object.values(HOTELS_PATHS).some(
      (path) => path.pathName === location.pathname
    );
    if (isHotelBooking) return "hotel";

    const isFlightBooking = Object.values(FLIGHTS_PATHS).some(
      (path) => path.pathName === location.pathname
    );
    if (isFlightBooking) return "flight";

    const isHolidayBooking = Object.values(HOLIDAYS_PATHS).some(
      (path) => path.pathName === location.pathname
    );
    if (isHolidayBooking) return "holiday";

    const isCabBooking = Object.values(CAB_PATHS).some(
      (path) => path.pathName === location.pathname
    );
    if (isCabBooking) return "cab";
  }

  return "bookingSummary";
};

const WebRouter = () => {
  const [isFetching, setIsFetching] = useState(false);
  useEffect(() => {
    pushRouter("RNRF", null);
    return () => {
      popRouter("RNRF");
    };
  }, []);

  const corpODCDeeplink = () => {
    let locationPath = window.location.href;
    if (!!locationPath) {
      if (
        locationPath.indexOf("CorpFlightDateChangeStatus") != -1 &&
        locationPath.indexOf("authCode") != -1
      ) {
        return true;
      }
      return false;
    }
  };

  useEffect(() => {
    const isUserLoggedIn = !isEmpty(_getMmtAuth());
    const currentLocation = window.location.href;
    const act = getURLParams("act", currentLocation);
    const platform = getURLParams("platform", currentLocation);
    if (platform) {
      setSource(platform);
    }
    if (
      !isUserLoggedIn &&
      act !== "301" &&
      !currentLocation.includes(ESCALATE_ROUTES.ESCALATE) &&
      !corpODCDeeplink()
    ) {
      let url = _isAffiliateUser()
        ? `https://${_getAffiliateName()}.makemytrip.com/login/page`
        : `https://www.makemytrip.com/login/page`;
      const extraParams = {
        action: "postsales",
        pageKey: currentLocation.includes("/Communication")
          ? "communication"
          : getPageKey(),
      };
      if (currentLocation.includes(ESCALATE_ROUTES.ESCALATE_TO_TEAM_PAGE)) {
        delete extraParams.action;
        setCookieByName(
          "loginActionDetails",
          `"${window.location.href}"`,
          ".makemytrip.com"
        );
      }
      url = appendParams(url, extraParams);
      location.href = url;
      setIsFetching(true);
    }
  });

  const setDirection = (location) => {
    const { pathname } = location;
    if (directionMap.has(pathname) && !!directionMap.get(pathname)) {
      I18nManager.forceRTL(isRTL());
    } else {
      I18nManager.forceRTL(false);
    }
  };

  useEffect(() => {
    //Fire A Custom onLoad  event to the custom routerStack to store inital Route
    customRouter.handleRouting(location, "ONLOAD");
    setDirection(location);
    //Listen all the URL changes to maintain custom routerStack
    const unlistenHistory = history.listen((location, action) => {
      setDirection(location);
      return customRouter.handleRouting(location, action);
    });
    return () => {
      unlistenHistory();
    };
  }, []);

  if (isFetching) {
    return <ProgressView />;
  } else {
    return (
      <RNAppDataProvider appData={getAppData()}>
        <TravelInsuranceDataProvider appData={getAppData()}>
          <CookiesProvider>
            <Provider store={store}>
              <Router history={history} forceRefresh>
                <View style={{ flex: 1 }}>
                  <Switch>
                    {Object.values(ROUTE_PATHS).map((route) => {
                      directionMap.set(route.pathName, route.isRtl);
                      if (route.Component) {
                        return (
                          <Route
                            exact
                            key={route.pathName}
                            path={route.pathName}
                            component={withRouterState(route.Component)}
                          />
                        );
                      }
                    })}
                  </Switch>
                  <GdprConsentForm isPWA={true} />
                </View>
              </Router>
            </Provider>
          </CookiesProvider>
        </TravelInsuranceDataProvider>
      </RNAppDataProvider>
    );
  }
};

export default WebRouter;
