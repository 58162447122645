import React from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import { isEmpty } from 'lodash';
import Actions from '../../../../../navigation/postSalesNavigation';
import HTMLView from 'react-native-htmlview';
import styles from './RefundRequestInProcessCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {
  REFUND_PRICE_ICON,
  CLAIM_PROCESED_ICON,
  CLAIM_REJECTED_ICON,
  RIGHT_ARROW,
  CALL_AGENT_ICON,
} from '../../../../FlightImageConstants';
import { openDialer } from '../../../../../Common/commonUtil';
import { getStaticData } from '../../../../../staticData/staticData';
import { imageTransformY } from '../../../../../utils/PostSaleUtil';
import { getFont } from '../../../../../PostSalesStyles';

const RefundRequestInProcess = ({ header, refundTypeDetailList }) => {
  const { callAirlineText, informationText } = getStaticData();
  const { fsStyle, ...fonts } = getFont(true);

  const htmlStyle = {
    q: {
      /* fix fo Html Fonts Not working without adding blank tag */
    },
    p: {
      color: '#747474',
      fontSize: fonts.fontSize.font14,
      fontFamily: fonts.regular,
    },
    b: {
      color: '#4a4a4a',
      fontSize: fonts.fontSize.font14,
      fontFamily: fonts.bold,
    },
  };

  return (
    <View style={[AtomicCss.marginBottom10, AtomicCss.marginTop10]}>
      <View style={[styles.infoCardWrapper]}>
        <View style={styles.headingIconWrapper}>
          <Image style={styles.headingIconStyle} source={REFUND_PRICE_ICON} />
        </View>
        <View style={styles.infoCardContent}>
          {!isEmpty(header) && (
            <Text
              style={[
                AtomicCss.defaultText,
                fonts.blackFontFamily,
                fsStyle.font14,
                AtomicCss.marginBottom5,
                AtomicCss.alignLeft,
              ]}
            >
              {header}
            </Text>
          )}
          {!isEmpty(refundTypeDetailList) &&
            refundTypeDetailList.map((refundDetail, index) => {
              const {
                typeDesc,
                claimStatusMainText,
                claimStatusMainSubText,
                footerText,
                claimStatusType,
                contactNo,
              } = refundDetail;
              let imageIcon = CLAIM_PROCESED_ICON;
              let subTextStyle = styles.progressText;
              if (claimStatusType === 'REJECTED') {
                imageIcon = CLAIM_REJECTED_ICON;
                subTextStyle = styles.failedText;
              }
              return (
                <View style={styles.dtlsRow}>
                  {!isEmpty(typeDesc) && (
                    <Text
                      style={[
                        fonts.regularFontFamily,
                        AtomicCss.greyText,
                        AtomicCss.marginBottom16,
                        fsStyle.font11,
                        AtomicCss.alignLeft,
                      ]}
                    >
                      {typeDesc.join(' • ')}
                    </Text>
                  )}

                  <React.Fragment>
                    <View
                      style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom8]}
                    >
                      <Image style={styles.IconStyle} source={imageIcon} />
                      <View style={[AtomicCss.flex1]}>
                        {!isEmpty(claimStatusMainText) && (
                          <Text
                            style={[
                              fonts.boldFontFamily,
                              AtomicCss.blackText,
                              fsStyle.font12,
                              AtomicCss.alignLeft,
                            ]}
                          >
                            {claimStatusMainText}
                          </Text>
                        )}
                        {!isEmpty(claimStatusMainSubText) && (
                          <Text
                            style={[
                              fonts.boldFontFamily,
                              subTextStyle,
                              fsStyle.font12,
                              AtomicCss.alignLeft,
                            ]}
                          >
                            {claimStatusMainSubText}
                          </Text>
                        )}
                      </View>
                    </View>
                    <View style={styles.line} />
                    {!isEmpty(footerText) && (
                      <HTMLView
                        style={[styles.footerText, AtomicCss.alignLeft]}
                        value={footerText}
                        stylesheet={htmlStyle}
                        onLinkPress={(url) =>
                          Actions.openPsWebView({
                            headerText: informationText,
                            headerIcon: require('@mmt/legacy-assets/src/back-white.webp'),
                            url,
                          })
                        }
                      />
                    )}
                    {!isEmpty(contactNo) && (
                      <TouchableOpacity
                        onPress={() => openDialer(contactNo)}
                        style={[
                          AtomicCss.flexRow,
                          AtomicCss.spaceBetween,
                          AtomicCss.alignCenter,
                          AtomicCss.marginTop16,
                        ]}
                      >
                        <View style={[AtomicCss.flex1, AtomicCss.flexRow, AtomicCss.alignCenter]}>
                          <Image style={styles.callIconStyle} source={CALL_AGENT_ICON} />
                          <Text
                            style={[
                              AtomicCss.azure,
                              fonts.boldFontFamily,
                              fsStyle.font14,
                              AtomicCss.alignLeft,
                            ]}
                          >
                            {callAirlineText}
                          </Text>
                        </View>
                        <Image
                          style={[styles.rightArrowIcon, imageTransformY]}
                          source={RIGHT_ARROW}
                        />
                      </TouchableOpacity>
                    )}
                    {index !== refundTypeDetailList.length - 1 && (
                      <View style={styles.dottedLine} />
                    )}
                  </React.Fragment>
                </View>
              );
            })}
        </View>
      </View>
    </View>
  );
};

export default RefundRequestInProcess;
