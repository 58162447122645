import React from 'react';
import {Image, Text, View} from 'react-native';
import Actions from '../../navigation/postSalesNavigation';
import styles from '../GiftCardDetailsCss';
import {
  getButtonStyle
} from '../../hotel/details/utils/HotelBookingDetailUtil';
import {getActionButton, isNotNullAndEmpty} from '../GiftCardBookingDetailUtil';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import GiftCardBookingDetailsConstant from '../GiftCardBookingDetailsConstant';
import GiftCardBookingTrackerHelper from '../GiftCardBookingTrackerHelper';
import { WHITE_LEFT_ARROW_ICON, CROSS_FIND_ICON } from '../GiftCardImageConstants';
import { openDialer } from '../../Common/commonUtil';

const NeedHelpCard = ({giftCardBookingInfo, card, pageName}) => {

  const {faqUrl, howToRedeemUrl, callUsNo} = giftCardBookingInfo;

  const handleWebviewClick = (url) => {
    Actions.openPsWebView({
      url,
      headerText: '',
      headerIcon: WHITE_LEFT_ARROW_ICON
    });
  }

  const handleCallUsClick = () => {
    openDialer(callUsNo);
  }

  const clickHandler = (action) => {
    switch (action.actionFamily) {
      case GiftCardBookingDetailsConstant.ACTION_VIEW_FAQ: 
        if(isNotNullAndEmpty(faqUrl)) {
          GiftCardBookingTrackerHelper.trackClickEvent(pageName, 'gc_customersupport_faq_clicked')
          handleWebviewClick(faqUrl);
        }
        break;
      case GiftCardBookingDetailsConstant.ACTION_VIEW_HOW_TO_REDEEM: 
        if(isNotNullAndEmpty(howToRedeemUrl)) {
          GiftCardBookingTrackerHelper.trackClickEvent(pageName, 'gc_customersupport_htr_clicked')
          handleWebviewClick(howToRedeemUrl);
        }
        break;
      case GiftCardBookingDetailsConstant.ACTION_CALL_US:
        if(isNotNullAndEmpty(callUsNo)) {
          GiftCardBookingTrackerHelper.trackClickEvent(pageName, 'gc_customersupport_callus_clicked')
          handleCallUsClick();
        }
        break;
    }
  }
  
  return (
    <View style={[styles.MamiCard, styles.mar10Bt]}>
      <View style={styles.CardContent}>
        <Image style={styles.CardIcon} source={CROSS_FIND_ICON}/>
        <View style={[styles.ContentPortion, {paddingTop: 3}]}>
          <Text style={[styles.blackFont, styles.BlackText]}>{GiftCardBookingDetailsConstant.STILL_CANT_FIND_WHAT_YOU_NEED}</Text>
        </View>
      </View>
      <View style={getButtonStyle(card.actionList)}>
        {getActionButton(card, clickHandler)}
      </View>
    </View>
  );  
}

export default NeedHelpCard;
