import {NativeModules, Platform} from 'react-native';
import {
  getAbConfig,
  AbConfigKeyMappings,
  getAbConfigWaitingPromise
} from './AbConfig/AbConfigModule';
import { getRootTag } from '../AppState/RootTagHolder';

export const NpsParams = {
  RAILS: {
    lob: 'Rail',
    lobAbParam: 'Rail',
    category: 'Rail_ThankYouPage'
  },
  ACME: {
    lob: 'Acme',
    lobAbParam: 'Acme',
    category: 'Acme_ThankYouPage'
  },
  BUS: {
    lob: 'Bus',
    lobAbParam: 'Bus',
    category: 'Bus_ThankYouPage'
  },
  CABS: {
    lob: 'Cab',
    lobAbParam: 'Cab',
    category: 'Cab_ThankYouPage'
  },
  HOLIDAYS: {
    lob: 'Holidays',
    lobAbParam: 'Holidays',
    category: 'Holidays_ThankYouPage'
  },
  HOLIDAYS_INTL: {
    lob: 'Holidays_INTL',
    lobAbParam: 'Holidays',
    category: 'Holidays_INTL_ThankYouPage'
  },
  VISA: {
    lob: 'Visa',
    lobAbParam: 'Visa',
    category: 'Visa_ThankYouPage'
  }
};

export const showNps = async (bookingId, npsParams) => {
  try {
    await getAbConfigWaitingPromise();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('error while getAbConfigWaitingPromise()');
  }
  const lobsToShowNpsStr = getAbConfig(AbConfigKeyMappings.lobsToShowNps, '');
  const lobsToShowNps = lobsToShowNpsStr.split(',');
  if (lobsToShowNps.indexOf(npsParams.lobAbParam) === -1) {
    return;
  }
  if (NativeModules.NpsModule) {
    NativeModules.NpsModule.showNps({
      reactTag: getRootTag(),
      bookingId,
      lob: npsParams.lob,
      page: npsParams.category,
      category: npsParams.category,
      bookingStatus: 'Confirmed'
    });
  }
};

export const showRatingPrompt = (ratingInfo, npsInfo) => {
  if (NativeModules.NpsModule) {
    NativeModules.NpsModule.showRatingPrompt(ratingInfo, npsInfo);
  }
};

export const removeNps = () => {
  if (NativeModules.NpsModule) {
    if (Platform.OS === 'ios') {
      NativeModules.NpsModule.removeNps(getRootTag());
    } else if (Platform.OS === 'android') {
      NativeModules.NpsModule.removeNps();
    }
  }
};
