import React, { useEffect, useRef, useState } from 'react';
import { Text, StyleSheet, View } from 'react-native';
import LottieView from 'lottie-react-native';
import { colors, fonts } from '@mmt/legacy-commons/Styles/globalStyles';
var flightLoaderJson = require('@mmt/legacy-assets/src/LottieAnimations/FlightLoaderAnimation/data.json');

//screenshot of what this looks like: https://gommt-my.sharepoint.com/:i:/g/personal/mmt9115_go-mmt_com/EUWgyXJsHDNAnwCtwuHTfhwBE1Ygzh509dxCjq8ITx9qRQ?e=YSVYMH

const FlightLoaderAnim = ({text}) => {
    const animationVar = useRef(null);

    useEffect(() => {
        animationVar.current.play();
    })

    return (
        <View style={styles.animationContainer}>
        <LottieView
            ref={animationVar}
            style={{
            width: 100,
            height: 100,
            backgroundColor: '#fff',
            }}
            source={flightLoaderJson}
        />
        {!!text && <Text style={styles.textStyle}>{text}</Text>}
        </View>
    );
}

export default FlightLoaderAnim;

const styles = StyleSheet.create({
  animationContainer: {
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    
  },
  textStyle: {
    color: colors.defaultTextColor,
    fontSize: 16,
    fontFamily: fonts.black,
    lineHeight:20,
    marginTop:30
  },

});