import { getDays } from "./util";

export const Months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
// Current year, last 5 years, next 5 years
const Years = [
  new Date().getFullYear(),
  ...Array(5)
    .fill(new Date().getFullYear())
    .map((x, index) => x + index + 1),
].sort();

const Hours = Array(12)
  .fill(0)
  .map((_, index) => {
    if (index < 9) {
      return `0${index + 1}`;
    } else {
      return index + 1;
    }
  });

const Minutes = Array(60)
  .fill(0)
  .map((_, index) => {
    if (index < 10) {
      return `0${index}`;
    } else {
      return index;
    }
  });
const DayTime = ['AM', 'PM'];

export const DefaultDropDownData = {
  day: getDays(30),
  month: Months,
  year: Years,
  hour: Hours,
  minute: Minutes,
  ampm: DayTime,
};

export const dayTime = {
  AM: 'AM',
  PM: 'PM',
};

export const dateDropDownOptions = ['day', 'month', 'year'];
export const timeDropDownOptions = ['hour', 'minute', 'ampm'];
