import React from 'react';
import Actions from '../../../navigation/postSalesNavigation';
import { Alert, Platform, ScrollView, Text, View } from 'react-native';
import { isEmpty, get } from 'lodash';
import LinearGradient from 'react-native-linear-gradient';
import PropTypes from 'prop-types';
import Breadcrumb from '../Breadcrumb/index';
import StickyHeader from '../../../hotel/details/components/StickyHeader';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import styles from '../css/SpecialClaimReviewDetailsCss';
import SpecialClaimAddAttachmentModule from '@mmt/legacy-commons/Native/SpecialClaimAddAttachmentModule';
import { fillCheckInCheckOutData } from '../../../hotel/details/utils/HotelBookingDetailUtil';
import OverlayMessage from './OverlayMessage';
import { isNetworkAvailable } from '@mmt/legacy-commons/Common/utils/AppUtils';
import {
  FLIGHT_CANCELLATION_DONE_URL,
  getCommonHeaders,
  UPLOAD_ATTACHMENT_URL,
} from '../../../utils/NetworkUtils';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import { showShortToast } from '@mmt/legacy-commons/Common/Components/Toast';
import SpecialClaimBaseClass from './SpecialClaimBaseClass';
import ReviewButton from '../SelectPassengers/ReviewButton';
import { invalidateTrips } from '@mmt/legacy-commons/Helpers/genericHelper';
import SpecialClaimUtility from '../Helpers/SpecialClaimUtility';
import { openErrorPage, CreateGuid } from '../../../utils/PostSaleUtil';
import InfoListCard from './InfoListCard';
import RaiseRequestProcessCardV2 from './ClaimReason/RaiseRequestProcessCardV2';
import SegmentPaxInfo from './segmentPaxInfo';
import FalseClaimSegmentCard from './FalseClaimSegmentCard';
import UploadProofCard from './UploadProofCard';
import CreditShellOverlay from './CreditShellOverlay';
import SpecialClaimTrackingHelper from '../Helpers/SpecialClaimTrackingHelper';
import NeedMoreHelpOverlay from '../../../Common/NeedMoreHelpOverlay';
import { LOBNAMES, NEED_HELP_PAGE, NEED_MORE_HELP } from '../../../PostSalesConstant';
import UploadProofOverlay from '../../../Common/CommonOverlayMessage';
import UploadProofContent from './UploadProofOverlayDtls';
import { HEADER_ICON } from '../../FlightImageConstants';
import { getFont } from '../../../PostSalesStyles';
import { getStaticData } from "../../../staticData/staticData";

const headerIcon = HEADER_ICON;

let segmentLst = [];
let approvedStatusInfo;
let approvedSectorList = [];
let rejectedSectorList = [];
let uploadClaimDoc;

export default class SpecialClaimReviewDetails extends SpecialClaimBaseClass {
  constructor(props) {
    super(props, 'specialClaimReviewDetails', 'Specialclaimreview');
    this.showAgreeOverlay = this.showAgreeOverlay.bind(this);
    this.setProofList = this.setProofList.bind(this);
    this.toggleProofOverlay = this.toggleProofOverlay.bind(this);
    this.specialClaimData = JSON.parse(this.props.specialClaimData);
    this.selectedPassenger = this.props.selectedPassenger;
    this.validPaxId = this.props.paxFareSegmentIdList;
    this.state = {
      activeStep: 1,
      responseJson: this.props.responseJson,
      viewState: ViewState.SHOW_DETAIL,
      attachmentLst: new Map(),
      displayAgreeOverlay: false,
      creditShellPopup: false,
      creditShellPopupData: {},
      helpingHandOverlay: true,
      displayProofOverlay: false,
      proofList: [],
    };
    segmentLst = [];
    this.prepareSegmentData();
    this.data = new FormData();
    this.attachedFile = [];
    const { specialClaimValidationData } = this.state.responseJson;
    if (specialClaimValidationData) {
      approvedStatusInfo = specialClaimValidationData.approvedStatusInfo;
      if (!isEmpty(approvedStatusInfo)) {
        if (!isEmpty(approvedStatusInfo.segmentStatusList)) {
          approvedSectorList = approvedStatusInfo.segmentStatusList.filter(
            (segment) => segment.status === 'APPROVED' || segment.status === 'NO_VALIDATION',
          );
          rejectedSectorList = approvedStatusInfo.segmentStatusList.filter(
            (segment) => segment.status === 'REJECTED',
          );
        }
        uploadClaimDoc = approvedStatusInfo.uploadCard;
        const { validPaxRefIds } = specialClaimValidationData;
        this.validPaxId = this.getValidPaxIds(validPaxRefIds);
      }
    }
  }

  showAgreeOverlay(shouldContinue) {
    if (this.specialClaimData.addAttachment && this.attachedFile.length === 0) {
      const staticData = getStaticData();
      showShortToast(staticData.pleaseAddAttachmentText);
      return;
    }
    if (
      this.attachedFile.length === 0 &&
      !shouldContinue &&
      !isEmpty(approvedStatusInfo) &&
      !isEmpty(approvedStatusInfo.submitProofInfo)
    ) {
      this.setState({
        displayProofOverlay: true,
        proofList: [],
      });
    } else {
      this.setState({
        displayAgreeOverlay: true,
        displayProofOverlay: false,
      });
    }
  }

  setProofList(proofList) {
    this.setState({
      proofList: proofList,
      displayProofOverlay: false,
    });
  }

  toggleProofOverlay() {
    this.setState({
      displayProofOverlay: !this.state.displayProofOverlay,
    });
  }

  render() {
    return (
      <View style={styles.pageScroll}>
        {this.state.viewState === ViewState.LOADING && this.renderProgressView('Loading ...')}
        {this.state.viewState === ViewState.NO_INTERNET && this.renderNoNetworkView()}
        {this.state.viewState === ViewState.ERROR && this.renderErrorView()}
        {this.state.viewState === ViewState.SHOW_DETAIL && this.renderPage()}
      </View>
    );
  }

  componentDidMount() {
    super.componentDidMount();
    if (
      rejectedSectorList &&
      rejectedSectorList.length > 0 &&
      this.state.responseJson &&
      this.state.responseJson.specialClaimValidationData &&
      get(this.state.responseJson, 'specialClaimValidationData.isFullBlocked', false)
    ) {
      this.trackPageLoad('Specialclaimreviewfullblock', {});
    } else if (rejectedSectorList && rejectedSectorList.length > 0) {
      this.trackPageLoad('Specialclaimreviewpartblock', {});
    } else {
      this.trackPageLoad('Specialclaimreview', {});
    }
  }

  prepareSegmentData() {
    const slctdPaxKeys = Object.keys(this.selectedPassenger);
    for (let k = 0; k < slctdPaxKeys.length; k++) {
      for (
        let i = 0;
        i < this.state.responseJson.flightDetails.segmentGroupDetailList.length;
        i++
      ) {
        if (
          this.state.responseJson.flightDetails.segmentGroupDetailList[i].intentRefID ===
          parseInt(slctdPaxKeys[k])
        ) {
          const sgmtDtls = this.state.responseJson.flightDetails.segmentGroupDetailList[i]
            .segmentDetails;
          for (let j = 0; j < sgmtDtls.length; j++) {
            const obj = {};
            obj.originCityName = sgmtDtls[j].originCity;
            obj.originCityCode = sgmtDtls[j].originCityCode;
            obj.destinationCityName = sgmtDtls[j].destinationCity;
            obj.destinationCityCode = sgmtDtls[j].destinationCityCode;
            obj.travelDateTime = fillCheckInCheckOutData(
              sgmtDtls[j].travelDateTime,
              'dddd, DD MMM YYYY',
            ).toUpperCase();
            const count = this.countPax(slctdPaxKeys, k);
            obj.noOfTravellers = count;
            if (count > 0) {
              segmentLst.push(obj);
            }
          }
        }
      }
    }
  }

  countPax(slctdPaxKeys, k) {
    let count = 0;
    const paxMapKeys = Object.keys(this.selectedPassenger[slctdPaxKeys[k]]);
    for (let l = 0; l < paxMapKeys.length; l++) {
      if (this.selectedPassenger[slctdPaxKeys[k]][paxMapKeys[l]] === true) {
        count += 1;
      }
    }
    return count;
  }

  toggleHelpingHandOverlay = (value) => {
    this.setState({
      helpingHandOverlay: value,
    });
  };

  trackHelpingHandEvents = (type, page, click, data) => {
    const pageName = `${this.trackingPageName}_${page}`;
    const trackData = Object.assign({}, this.state.responseJson, {
      ruleTrace: data ? data.ruleTrace : '',
    });
    switch (type) {
      case 'load':
        SpecialClaimTrackingHelper.trackLoadEvent(
          pageName,
          trackData,
          this.specialClaimData,
          this.props.bookingState,
        );
        break;
      case 'click':
        SpecialClaimTrackingHelper.trackClickEvent(pageName, `Flight_${click}`);
        break;
      default:
        break;
    }
  };

  static getSteps(response) {
    if (response && response.scRefundMode) {
      return ['SELECT TRAVELLERS', 'REVIEW', 'CONFIRM'];
    }
    return ['SELECT TRAVELLERS', 'REVIEW'];
  }

  renderPage() {
    const { fsStyle, ...fonts } = getFont(true);
    return (
      <View style={styles.pageWrapper}>
        <StickyHeader
          iconSrc={headerIcon}
          title={this.getTitle()}
          headerColor={['#ffffff', '#ffffff']}
          titleStyle={[styles.headerTitle, fonts.boldFontFamily, fsStyle.font16]}
          onBackPress={this.goBack}
        />
        <Breadcrumb
          activeStep={this.state.activeStep}
          data={SpecialClaimReviewDetails.getSteps(this.state.responseJson)}
        />
        <ScrollView showsVerticalScrollIndicator={false} style={styles.pageScroll}>
          <View style={styles.pageScrollTopSpace} />
          {!isEmpty(rejectedSectorList) &&
            rejectedSectorList.map((rejectedSector) => (
              <FalseClaimSegmentCard {...rejectedSector.segmentRejectedInfo} />
            ))}
          {!isEmpty(approvedSectorList) &&
            approvedSectorList.map((segment) => (
              <SegmentPaxInfo
                segmentApprovedInfo={segment.segmentApprovedInfo}
                creditShellInfo={segment.creditShellInfo}
                showCreditShellPopup={this.showCreditShellPopup}
              />
            ))}

          {this.specialClaimData.refundAmtTxt ? (
            <View style={styles.refundCard}>
              <Text style={[styles.cardTitle, fonts.blackFontFamily, fsStyle.font16]}>
                {this.specialClaimData.refundAmtTitle}
              </Text>
              <Text style={[styles.paraText, fonts.regularFontFamily, fsStyle.font12]}>
                {this.specialClaimData.refundAmtTxt}
              </Text>
            </View>
          ) : null}

          {uploadClaimDoc && (
            <UploadProofCard
              {...uploadClaimDoc}
              onFileChanged={this.onFileChanged}
              fileList={this.state.proofList}
            />
          )}
          {approvedStatusInfo && approvedStatusInfo.claimProcess && (
            <RaiseRequestProcessCardV2 {...approvedStatusInfo.claimProcess} />
          )}
          {approvedStatusInfo && !isEmpty(approvedStatusInfo.importantInformation) && (
            <View style={styles.whiteCard}>
              <LinearGradient
                start={{ x: 0.1, y: 0.0 }}
                end={{ x: 0.0, y: 1.0 }}
                colors={['#ff3e5e', '#ff7f3f']}
                style={[styles.importantInofTag]}
              >
                <Text style={[styles.tagStyle, fonts.boldFontFamily, fsStyle.font10]}>
                  IMPORTANT INFORMATION
                </Text>
              </LinearGradient>
              <InfoListCard {...approvedStatusInfo} />
            </View>
          )}
          <View style={styles.pageScrollBtmSpace} />
        </ScrollView>
        <View style={styles.footerBtnWrapper}>
          <ReviewButton
            buttonText={
              approvedStatusInfo && approvedStatusInfo.actionCTA
                ? approvedStatusInfo.actionCTA.text
                : 'SUBMIT CLAIM'
            }
            clickHandler={this.showAgreeOverlay}
            isCorporateBooking={this.props.isCorporateBooking}
          />
        </View>
        {this.state.displayAgreeOverlay && (
          <OverlayMessage
            closePopupHandler={this.closePopup}
            proceedClickHandler={this.onContinueClicked}
            specialClaimData={this.specialClaimData}
          />
        )}
        {this.state.displayProofOverlay &&
          !isEmpty(approvedStatusInfo) &&
          !isEmpty(approvedStatusInfo.submitProofInfo) && (
            <UploadProofOverlay
              handleBottomOverlay={this.toggleProofOverlay}
              content={
                <UploadProofContent
                  closePopupHandler={this.closePopup}
                  proceedClickHandler={this.onContinueClicked}
                  specialClaimData={this.specialClaimData}
                  onFileChanged={this.onFileChanged}
                  attachmentLst={this.state.proofList}
                  setAttachmentList={this.setProofList}
                  submitProofInfo={approvedStatusInfo.submitProofInfo}
                  showAgreeOverlay={() => this.showAgreeOverlay(true)}
                  trackingPageName={this.trackingPageName}
                  isFullBlocked={get(
                    this.state.responseJson,
                    'specialClaimValidationData.isFullBlocked',
                    false,
                  )}
                />
              }
            />
          )}
        {this.state.creditShellPopup && (
          <CreditShellOverlay
            {...this.state.creditShellPopupData}
            btnClick={() => this.showCreditShellPopup(undefined, false)}
          />
        )}
        {this.state.helpingHandOverlay === true && (
          <NeedMoreHelpOverlay
            bookingId={this.state.responseJson ? this.state.responseJson.bookingID : ''}
            handleBottomOverlay={this.toggleHelpingHandOverlay}
            handleReload={this.toggleHelpingHandOverlay}
            component={NEED_MORE_HELP.HELPING_HAND}
            pageName={NEED_HELP_PAGE.FLIGHT_SPECIAL_CLAIM_REVIEW}
            hideLoader={true}
            trackEvent={this.trackHelpingHandEvents}
          />
        )}
      </View>
    );
  }

  onFileChanged = (updatedFileList) => {
    this.attachedFile = updatedFileList;
  };

  closePopup = () => {
    this.setState({
      displayAgreeOverlay: false,
    });
  };

  getTitle = () =>
    `${SpecialClaimUtility.getScHeaderTitle(this.state.responseJson.messageAttributes)}: ${
      this.specialClaimData.explTxt
    }`;

  goBack = () => {
    Actions.pop();
  };

  onContinueClicked = () => {
    const optional = uploadClaimDoc && uploadClaimDoc.optional;
    if (this.attachedFile.length > 0) {
      this.uploadFiles();
    } else if (optional) {
      this.submitRequestHandler();
    } else {
      const staticData = getStaticData();
      showShortToast(staticData.pleaseAddAttachmentText);
    }
    this.trackClickEvent(this.state.responseJson);
  };

  submitRequestHandler = (fileUrls) => {
    if (
      this.props.responseJson.scRefundMode &&
      !isEmpty(this.props.responseJson.scRefundMode.refundModeList)
    ) {
      this.setState({
        displayAgreeOverlay: false,
      });
      Actions.selectRefundPaymentMode({
        responseJson: this.props.responseJson,
        bookingId: this.props.responseJson.bookingID,
        specialClaimRequest: this.getSubmitClaimRequest(fileUrls),
        specialClaimData: this.specialClaimData,
        bookingState: this.props.bookingState,
        isCorporateBooking: this.props.isCorporateBooking,
      });
    } else {
      this.submitSpecialClaim(fileUrls);
    }
  };
  async uploadFiles() {
    try {
      const imageUploadReq = [];
      for (let i = 0; i < this.attachedFile.length; i += 1) {
        const imageStr = await this.uploadFile(this.attachedFile[i]);
        if (imageStr) {
          imageUploadReq.push(this.fileUploadAPI(imageStr, this.attachedFile[i]));
        }
        this.data.append('images', {
          string: JSON.stringify(imageStr),
          type: 'application/json; charset=utf-8',
        });
      }
      this.setState({
        viewState: ViewState.LOADING,
      });
      Promise.all(imageUploadReq)
        .then((res) => {
          this.setState({
            viewState: ViewState.SHOW_DETAIL,
          });
          this.uploadResponseHandler(res);
        })
        .catch((err) => {
          this.trackClickEvent(this.state.responseJson, {isProofUploaded: false});
          this.setState({
            viewState: ViewState.SHOW_DETAIL,
          });
          this.submitRequestHandler();
          showShortToast('Upload Failed');
        });
    } catch (err) {
      this.trackClickEvent(this.state.responseJson, {isProofUploaded: false});
      this.submitRequestHandler();
      showShortToast('Upload Failed');
    }
  }

  uploadResponseHandler = async (res) => {
    const staticData = getStaticData();
    const fileUrls = [];
    if (res && res.length) {
      for (let i = 0; i < res.length; i++) {
        const result = await res[i].json();
        if (res[i].ok && result.statusCode === 200) {
          const { fileName, documentID, url } = result;
          fileUrls.push({
            fileName,
            documentId: documentID,
            url,
          });
        }
      }
    }
    if (fileUrls.length === 0) {
      this.trackClickEvent(this.state.responseJson, {isProofUploaded: false});
      showShortToast(staticData.uploadFailedText);
    } else {
      this.trackClickEvent(this.state.responseJson, {isProofUploaded: true});
      showShortToast(staticData.documentUploadSuccessText);
    }
    this.submitRequestHandler(fileUrls);
  };

  async uploadFile(image) {
    const imagePath = image.filePath;
    let { imageResponse } = '';
    let imageStr = '';
    if (Platform.OS === 'ios') {
      this.attachedFile.forEach((obj) => {
        if (obj.filePath === imagePath) {
          imageResponse = obj.data;
        }
      }, this);
      imageStr = imageResponse;
    } else if (Platform.OS === 'web') {
      return image.base64String;
    } else {
      imageResponse = await SpecialClaimAddAttachmentModule.convertToBase64(imagePath);
      const imageByteCharacters = imageResponse.imageResponse;
      imageStr = imageByteCharacters;
    }
    return imageStr;
  }

  async fileUploadAPI(fileDataBase64Str, data) {
    const { fileName } = data || {};
    const token = fileName ? fileName.split('.') : [];
    const userInfo = await HotelBookingModule.getRequestHeader(
      this.props.bookingID,
      'SPECIAL_CLAIM_UPLOAD_DOC',
    );
    const requestBody = {
      authId: 'AUTOMATION',
      userId: 'AUTOMATION',
      source: 'MIMA',
      bookingID: this.props.bookingID,
      documentID: CreateGuid(),
      applicationName: 'mmtmidofficein',
      fileName,
      fileExtension: token[token.length - 1],
      fileContent: fileDataBase64Str,
      createdDate: new Date(),
    };
    return fetch(`${UPLOAD_ATTACHMENT_URL}`, {
      headers: await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo),
      method: 'POST',
      body: JSON.stringify(requestBody),
    });
  }

  getSubmitClaimRequest(fileUrls) {
    const scReq = {};
    scReq.bookingId = this.props.bookingID;
    scReq.lobCode = this.props.lobCode;
    scReq.isFullCancellation = false;
    scReq.fullCancellationReason = this.specialClaimData.scCode;
    scReq.refundOption = 'RefundToCustomer';
    scReq.paxFareSegmentIdList = this.validPaxId;
    scReq.fileUrls = fileUrls || [];
    scReq.submitWithProof = fileUrls && fileUrls.length > 0;
    return scReq;
  }

  onHardBackPress = () => {
    if (this.state.displayAgreeOverlay) {
      this.closePopup();
      return true;
    }
    return false;
  };

  showCreditShellPopup = (popupData, showPopup) => {
    this.setState({
      creditShellPopup: showPopup,
      creditShellPopupData: popupData,
    });
  };

  onTryAgainClicked = () => {
    this.setState({ viewState: ViewState.LOADING });
    this.submitRequestHandler();
  };

  async submitSpecialClaim(fileUrls) {
    try {
      this.setState({
        viewState: ViewState.LOADING,
      });
      const hasNetwork = await isNetworkAvailable();
      if (!hasNetwork) {
        Alert.alert('Please check your internet connection');
        return;
      }
      const userInfo = await HotelBookingModule.getRequestHeader(
        this.props.bookingID,
        'SPECIAL_CLAIM',
      );
      const response = await fetch(FLIGHT_CANCELLATION_DONE_URL, {
        method: 'POST',
        body: JSON.stringify(this.getSubmitClaimRequest(fileUrls)),
        headers: await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo, {
          psLob: LOBNAMES.FLIGHT,
        }),
      });
      if (response.ok) {
        const body = await response.json();
        if (body.errorPageData) {
          openErrorPage(
            body.errorPageData,
            undefined,
            this.onTryAgainClicked,
            this.props.bookingID,
            'specialclaim',
            Actions,
            { psLob: LOBNAMES.FLIGHT },
          );
        } else if (body.raiseSpecialClaimData && body.raiseSpecialClaimData.success) {
          invalidateTrips();
          Actions.specialClaimRequestThankYou({
            bookingId: body.bookingId,
            ...body.raiseSpecialClaimData.successData,
          });
          this.trackPageLoad('SpecialclaimThankyou', {
            uploadFailed: fileUrls && fileUrls.length === 0,
          });
        } else {
          this.setState({
            viewState: ViewState.ERROR,
          });
        }
      } else {
        this.setState({
          viewState: ViewState.ERROR,
        });
      }
    } catch (error) {
      this.setState({
        viewState: ViewState.ERROR,
      });
      console.log(error);
    }
  }

  trackPageLoad(pageName, otherData) {
    this.trackingPageName = this.isIntlFlightBooking(this.state.responseJson.lobCode)
      ? `Mob:Customer support:Intl Flight:${pageName}`
      : `Mob:customer support:Dom Flight:${pageName}`;
    SpecialClaimTrackingHelper.trackLoadEvent(
      this.trackingPageName,
      this.state.responseJson,
      Object.assign(this.specialClaimData, otherData),
      this.props.bookingState,
    );
  }

  getValidPaxIds(validPaxRefIds) {
    const result = [];
    this.props.paxFareSegmentIdList.forEach((paxFareSegmentIdObj) => {
      const { paxFareSegmentId } = paxFareSegmentIdObj;
      if (validPaxRefIds && validPaxRefIds.includes(paxFareSegmentId)) {
        result.push(paxFareSegmentIdObj);
      }
    });
    return result;
  }
}

SpecialClaimReviewDetails.propTypes = {
  specialClaimData: PropTypes.string.isRequired,
  bookingID: PropTypes.string.isRequired,
  lobCode: PropTypes.string.isRequired,
  selectedPassenger: PropTypes.object.isRequired,
  paxFareSegmentIdList: PropTypes.object.isRequired,
};
