import React from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import styles from '../indexCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { HELP_ICON, RIGHT_BLUE } from '../../../flights/FlightImageConstants';
import { isNotNullAndEmpty } from '../../../hotel/details/utils/HotelBookingDetailUtil';

const HelpCard = ({ data, onNeedHelpPress }) => {
  return (
    <View style={styles.helpCardWrapper}>
      <TouchableOpacity style={styles.hlpInnerCard} onPress={() => onNeedHelpPress(data.url)}>
        <Image source={HELP_ICON} style={styles.helpIcon} />
        <View
          style={[
            AtomicCss.flex1,
            AtomicCss.flexRow,
            AtomicCss.spaceBetween,
            AtomicCss.alignCenter,
          ]}
        >
          <View style={AtomicCss.flex1}>
            {isNotNullAndEmpty(data.text) && (
              <Text style={[AtomicCss.font14, AtomicCss.blackFont, AtomicCss.blackText]}>
                {data.text}
              </Text>
            )}
            {isNotNullAndEmpty(data.desc) && (
              <Text
                style={[
                  AtomicCss.font12,
                  AtomicCss.regularFont,
                  AtomicCss.gray2Text,
                  AtomicCss.marginTop5,
                ]}
              >
                {data.desc}
              </Text>
            )}
          </View>
          <Image source={RIGHT_BLUE} style={styles.rightArrow} />
        </View>
      </TouchableOpacity>
    </View>
  );
};

export default HelpCard;