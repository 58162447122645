import { Dimensions } from 'react-native';
import { getFont } from '../../../PostSalesStyles';

const windowHeight = Dimensions.get('screen').height;
const windowWidth = Dimensions.get('screen').width;
const fonts = getFont();

const styles = {
  overlayWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: windowHeight,
    width: windowWidth,
    elevation: 10,
    zIndex: 100,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    paddingBottom: 26,
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
    height: windowHeight,
    width: '100%',
    zIndex: 1,
  },
  overlayContent: {
    position: 'relative',
    zIndex: 1,
    width: '100%',
  },
  overlayContentDtls: {
    marginHorizontal: 40,
    borderRadius: 4,
    backgroundColor: '#fff',
    padding: 24,
    justifyContent: 'center',
    alignItems: 'center',
    elevation: 4,
    margin: 3,
  },
  fltIconWrapper: {
    width: 64,
    height: 64,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 50,
    marginBottom: 20,
  },
  fltIcon: {
    width: 46,
    height: 28,
  },
  fltcheckInTxt: {
    fontFamily: fonts.black,
    color: '#000',
  },
};

export default styles;
