import React from 'react';
import { View, Text, Image } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { COVID_LOGO_ICON } from '../../../../FlightImageConstants';
import { useTheme } from '../../../../../theme/theme.context';
import { getFont } from '../../../../../PostSalesStyles';
import createStyle from './MySafetyBannerCss';

const MySafetyBanner = ({ title, desc }) => {
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyle();
  return (
    <LinearGradient
      start={{ x: 1.0, y: 0.0 }}
      end={{ x: 0.0, y: 1.0 }}
      colors={[theme.color.green7, theme.color.white]}
      style={[AtomicCss.flexRow, AtomicCss.spaceBetween, styles.detailsInfo, AtomicCss.alignCenter]}
    >
      <Image style={styles.covidIcon} source={COVID_LOGO_ICON} />
      <View style={AtomicCss.flex1}>
        <Text
          style={[
            fonts.regularFontFamily,
            fsStyle.font12,
            AtomicCss.blackText,
            AtomicCss.marginBottom4,
            AtomicCss.alignLeft,
          ]}
        >
          {title}
        </Text>
        {desc &&
          desc.map((text) => (
            <Text
              style={[
                fonts.blackFontFamily,
                fsStyle.font14,
                AtomicCss.blackText,
                AtomicCss.alignLeft,
              ]}
            >
              {text}
            </Text>
          ))}
      </View>
    </LinearGradient>
  );
};

export default MySafetyBanner;
