import React from 'react';
import PropTypes from 'prop-types';
import {isEmpty} from 'lodash';
import Actions from '../../../navigation/postSalesNavigation';
import LinearGradient from 'react-native-linear-gradient';
import {View, Text, ScrollView, TouchableOpacity} from 'react-native';
import styles from '../../../cab/components/CabMamiCss';
import CabBookingDetailsConstant from '../../utils/ZoomCarConstants';
import {E_COUPON, MY_WALLET} from '../../../PostSalesConstant';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import BlackPriceBreakup from '../../../Common/BlackPriceBreakup';
import SelfDriverRefundOverlay from "./SelfDriverRefundOverlay";
import * as SelfDriveBookingTrackingHelper from '@mmt/legacy-commons/Common/utils/OmnitureTrackerUtils';
export const pageName = 'mob: selfDrive: booking cancellation_1 refund amount page';


class CancellationInfoCard extends React.Component {
  constructor(props) {
    super(props, 'SelfDriveCancellation');
    this.state = {
      isOverlayVisible: false,
      radiobtn: 'RefundToCustomer'
    };
    this.toggleOverlay = this.toggleOverlay.bind(this);
    this.response = this.props.response;
    this.paymentDetails = this.props.response.bookingPaymentDetail;
    this.penalty = this.props.penalty;
  }

  render() {
    const {
      totalRefundAmount,
      totalCancelationCharges,
      totalPaidAmount,
      refundText
    } = this.props.fareBreakUpObject;

    const activeDtls = this.state.fareBreakupDtls
      ? 'Hide Breakup'
      : 'Show Breakup';

    return (
      <View>
        <ScrollView>
          <View style={[styles.MamiCard, styles.mar10Bt]}>
            <View style={[styles.flexRow, styles.cancelBreadcrumb]}>
              <View style={styles.flexRow}>
                <Text style={[styles.mar5Rt, styles.activeBreadCrumb]}>1</Text>
                <Text style={styles.activeBreadCrumb}>View Refund Amount</Text>
              </View>
              <View style={styles.flexRow}>
                <Text style={[styles.mar5Rt, styles.darkGrey]}>2</Text>
                <Text style={styles.darkGrey}>Confirm Cancellation</Text>
              </View>
            </View>
            <View style={[styles.padding16, styles.borderbtm]}>
              <Text style={styles.bookedCabType}>
                {this.props.response.tripInfo.pickup.city} - {this.props.response.tripInfo.drop.city}
              </Text>
            </View>

            <ScrollView>
              <View style={[AtomicCss.whiteBg, AtomicCss.elevation1]}>
                <View
                  style={[
                    styles.borderbtm,
                    {
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between'
                    }
                  ]}
                >
                  <View style={[AtomicCss.flexRow, styles.CancPriceBreckup]}>
                    <View style={styles.CancLeftSection}>
                      <Text style={[styles.fbHeadingTxt]}>Total Paid</Text>
                      {!this.props.hideBreakup && (
                        <TouchableOpacity
                          onPress={this.handleFareBreakup}
                          activeOpacity={0.5}
                        >
                          <Text style={styles.linkTxt}>{activeDtls}</Text>
                        </TouchableOpacity>
                      )}
                      {}
                    </View>
                    <View>
                      <Text
                        style={[
                          AtomicCss.blackText,
                          AtomicCss.font14,
                          AtomicCss.boldFont,
                          AtomicCss.marginBottom8
                        ]}
                      >
                        &#8377; {totalPaidAmount}
                      </Text>
                      {}
                    </View>
                  </View>
                  {(this.props.hideBreakup ||
                    (this.props.paidComponent && this.state.fareBreakupDtls)) &&
                    this.addBreakup(this.props.paidComponent)}
                </View>

                <View style={[styles.borderbtm]}>
                  <View style={[AtomicCss.flexRow, styles.CancPriceBreckup]}>
                    <View style={styles.CancLeftSection}>
                      <Text style={[styles.fbHeadingTxt]}>Deductions</Text>
                      {}
                    </View>
                    <View>
                      <Text
                        style={[
                          AtomicCss.blackText,
                          AtomicCss.font14,
                          AtomicCss.boldFont,
                          AtomicCss.marginBottom8
                        ]}
                      >
                        {totalCancelationCharges && totalCancelationCharges > 0 ?
                          `- ₹ ${totalCancelationCharges} ` : `₹ ${totalCancelationCharges} `}
                      </Text>
                      {}
                    </View>
                  </View>

                  {this.props.deductionComponent &&
                    this.addBreakup(this.props.deductionComponent)}
                </View>

                <View style={[AtomicCss.flexRow, styles.CancPriceBreckup]}>
                  <View style={styles.CancLeftSection}>
                    <Text
                      style={[
                        totalRefundAmount <= 0
                          ? styles.impInfoRed
                          : styles.impInfoGreen
                      ]}
                    >
                      {refundText}
                    </Text>
                  </View>
                  {totalRefundAmount > 0 && (
                    <View>
                      <Text
                        style={[
                          styles.impInfoGreen,
                          AtomicCss.boldFont,
                          AtomicCss.marginBottom8
                        ]}
                      >
                        &#8377; {totalRefundAmount}
                      </Text>
                    </View>
                  )}
                  {totalRefundAmount <= 0 && (
                    <View>
                      <Text
                        style={[
                          styles.impInfoRed,
                          AtomicCss.boldFont,
                          AtomicCss.marginBottom8
                        ]}
                      >
                        &#8377; {totalRefundAmount}
                      </Text>
                    </View>
                  )}
                </View>
              </View>
            </ScrollView>
            <TouchableOpacity onPress={() => this.onConfirmClickHandler()}>
              <LinearGradient
                start={{x: 1.0, y: 0.0}}
                end={{x: 0.0, y: 1.0}}
                colors={['#065af3', '#53b2fe']}
                style={styles.CapsuleBtnFill}
              >
                <Text
                  style={[
                    styles.WhiteText,
                    styles.regularFont,
                    styles.CenterText,
                    styles.transperantBgrnd
                  ]}
                >
                  CONTINUE
                </Text>
              </LinearGradient>
            </TouchableOpacity>
          </View>
          <View style={styles.cabRefundZeroScreen} />
        </ScrollView>
        {this.state.isOverlayVisible && (
          <SelfDriverRefundOverlay
            backPress={this.onOverlayBackPress}
            selectedRefundMode="RefundToCustomer"
            bookingId={this.props.response.bookingId}
            pageName={pageName}
          />
        )}
      </View>
    );
  }
  getPaymentAmount(paymentDetails) {
    return paymentDetails ? paymentDetails.paymentAmount : 0;
  }

  handleFareBreakup = () => {
    this.setState({
      fareBreakupDtls: !this.state.fareBreakupDtls
    });
  };

  addBreakup = components => components.map((component) => {
    if (!isEmpty(component.key) && component.key === 'loyaltyDiscount' && this.penalty.loyaltyDetails) {
      return (<View style={[AtomicCss.flexRow, styles.priceBreakups]}>
        <BlackPriceBreakup
          priceBreakupBlackText={component.label}
          priceBreakupBlackValue={component.value}
          tierMetaInfo={this.penalty.loyaltyDetails.tierMetaInfo}
          tierType={this.penalty.loyaltyDetails.tierType}
        />
      </View>);
    }
    return (<View style={[AtomicCss.flexRow, styles.priceBreakups]}>
      <View style={styles.CancLeftSection}>
        <Text style={[styles.fareBrkupRowLineTxt]}>{component.label}</Text>
        {}
      </View>
      <View>
        <Text style={[styles.fareBrkupRowLineTxt]}>{component.value}{' '}</Text>
        {}
      </View>
    </View>);
  })

  getCardDetails(payment) {
    let details = '';
    if (payment.paymentDetails) {
      payment.paymentDetails.forEach((modeDetails) => {
        if (
          modeDetails.paymentModeName !== E_COUPON &&
          modeDetails.paymentGatewayCode !== MY_WALLET
        ) {
          details = `${modeDetails.bankName} ${
            modeDetails.newModeOfPaymentName
          } ${modeDetails.cardNo}`;
        }
      });
    }
    return details;
  }

  onConfirmClickHandler() {
    if (!(this.penalty.refundAmount > 0)) {
      this.toggleOverlay();
    } else {
      Actions.selfDriveCancellationRefundMode({
        response: this.response,
        penaltyResponse: this.penalty
      });
    }
    SelfDriveBookingTrackingHelper.trackClickEvent(
      pageName,
      CabBookingDetailsConstant.CLICK_CANCEL_CONTINUE
    );
  }

  toggleOverlay() {
    this.setState({
      isOverlayVisible: !this.state.isOverlayVisible
    });
  }

  onOverlayBackPress = () => {
    this.toggleOverlay();
  };

  onHardBackPress = () => {
    if (this.state.isOverlayVisible) {
      this.toggleOverlay();
      return true;
    }
    return false;
  };
}
CancellationInfoCard.propTypes = {
  response: PropTypes.object.isRequired,
  penalty: PropTypes.object.isRequired
};

export default CancellationInfoCard;
