import React from 'react';
import PropTypes from 'prop-types';
import {View, Text, Image} from 'react-native';
import styles from './CabMamiCss';
import {ON_TRIP_STATE, UPCOMING_STATE} from '../../PostSalesConstant';
import { getButtonStyle, isNotNullAndEmpty, handleClickEvent, getECouponAmt} from '../utils/CabBookingDetailUtil';
import {paymentIcon, offerIcon} from "../CabImageConstants";
import CardWrapper from '../modules/bookingDetails/components/CardWrapper';
import { getActionButton } from 'apps/post-sales/src/Common/commonUtil';

class PriceBreakUpCard extends React.Component {
  render() {
    const paymentDetails = this.props.response.bookingPaymentDetail;
    const response = this.props.response;
    const eCouponAmt = getECouponAmt(paymentDetails);
    return (
      <View style={[styles.cardHeading, styles.mar10Bt]}>
        <View>
          <View style={[styles.bookingidDetails, styles.mar8Bt]}>
            <View style={styles.flexRow}>
              <Image style={styles.CardIcon} source={paymentIcon} />
              <View>
                <Text style={[styles.blackFont, styles.BlackText, styles.font14, styles.mar5Bt]}>TOTAL PRICE
                </Text>
                {/*<Text style={[styles.regular, styles.font12, styles.darkGrey, styles.mar5Bt, styles.regularFont]}>INCLUDES TAXES
                </Text>*/}
                <View style={[eCouponAmt > 0 ? styles.showView : styles.hideView, styles.flexRow]}>
                  <Text
                    style={[styles.boldFont, styles.font12, styles.lightGreenTxt, styles.mar10Bt]}
                  >You saved &#8377; {eCouponAmt} &nbsp;
                  </Text>
                  <Image
                    style={styles.arrowRightGray}
                    source={offerIcon}
                  />
                </View>
              </View>
            </View>
            <View><Text
              style={[eCouponAmt > 0 ? styles.showView : styles.hideView, styles.font12, styles.lightGrey, styles.boldFont, styles.lineThought, styles.spaceBetween, styles.AlignRight]}
            >&#8377; {paymentDetails.sellingPrice }{' '}
                  </Text>
              <Text style={[styles.font20, styles.BlackText, styles.boldFont]}>&#8377; {paymentDetails.sellingPrice - eCouponAmt }{' '}</Text>
            </View>
          </View>
          <View style={[styles.ContentPortion, styles.leftText]}>
            <View style={[paymentDetails.paymentAmount > 0 ? styles.showView : styles.hideView, styles.Paymentrow, styles.mar15Bt]}>
              <View>
                <Text
                  style={[styles.BlackText, styles.regularFont, styles.font14, styles.mar5Bt, styles.lineHt22]}
                >Amount Paid
                </Text>
              </View>
              <View>
                <Text
                  style={[styles.flexStart, styles.font16, styles.BlackText, styles.regularFont]}
                >&#8377; {paymentDetails.paymentAmount}{' '}
                </Text>
              </View>
            </View>
            <View style={[styles.Paymentrow, styles.mar25Bt, this.getAmountPayableFLag(response, eCouponAmt) ? styles.showView : styles.hideView]}>
              <View style={styles.PaymentLeft}>
                <Text style={[styles.BlackText, styles.font14, styles.regularFont, styles.mar5Bt]}>
                 Amount Payable to Driver
                </Text>
              </View>
              <Text
                style={[styles.flexStart, styles.font16, styles.BlackText, styles.regularFont]}
              >&#8377; {paymentDetails.sellingPrice - eCouponAmt - paymentDetails.paymentAmount}{' '}
              </Text>
            </View>
            {this.getExtraCharges(response) && <View style={[styles.Paymentrow, styles.mar25Bt]}>
              <View style={styles.PaymentLeft}>
                <Text style={[styles.BlackText, styles.font14, styles.regularFont, styles.mar5Bt]}>
                  Extra Charges
                </Text>
              </View>
              <Text
                style={[styles.flexStart, styles.font16, styles.BlackText, styles.regularFont]}
              >&#8377; {response.clientDetails.extraChargesAmount}{' '}
              </Text>
            </View>}
          </View>
        </View>
        <View
          style={
            isNotNullAndEmpty(this.props.card.actionList)
              ? [
                  getButtonStyle(this.props.card.actionList),
                  styles.borderTop,
                  styles.buttonRadiusStyle
                ]
              : styles.hideView
          }
        >
          {getActionButton(this.props.card, this.props.response, this.handleClick, {}, true, false,styles.buttonRadiusStyle)}
        </View>
      </View>
    );
  }
    handleClick = (action) => {
      handleClickEvent(this.props.card, action, this.props.response, this.props.pageName);
    };

    getAmountPayableFLag(response, eCouponAmt) {
      let flag = false;
      const bState = response.bookingState.state;
      const details = response.bookingPaymentDetail;
      const payable = details.sellingPrice - eCouponAmt - details.paymentAmount;
      if ((bState === ON_TRIP_STATE || bState === UPCOMING_STATE) && payable > 0) { flag = true; }
      return flag;
    }

    getExtraCharges(response) {
      if ((response) && (response.clientDetails) && (response.clientDetails.extraChargesAmount)) {
        return true;
      }
      return false;
    }
}
PriceBreakUpCard.propTypes = {
  response: PropTypes.object.isRequired,
  card: PropTypes.object.isRequired,
  pageName: PropTypes.string.isRequired
};
export default CardWrapper(PriceBreakUpCard);
