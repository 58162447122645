import React, { useState } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import createStyle from './IssueSelectionCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {ISSUE_AT_HOTEL_OVERLAY} from '../../../PostSalesConstant';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import { getCommonHeaders, CALL_NOW_URL } from '../../../utils/NetworkUtils';
import { getUserDetails } from '@mmt/legacy-commons/Native/UserSession/UserSessionModule';
import {ViewState} from '../../../PostSalesConstant';
import {renderErrorView, renderProgressView} from '../../../Common/NeedMoreHelpOverlay/NeedMoreHelpUtils';
import { getStaticData } from '../../../staticData/staticData';
import { useTheme } from '../../../theme/theme.context';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const IssueL2Selection = (props) => {
    const {issueL2List} = props;
    const [itemActive, setItemActive] = useState('');
    const [retry, setRetry] = useState(0);
    const [viewState, setViewState] = useState();
    const [callDrivers, setCallDrivers] = useState(null);
    const { hotelDenyingCheckinText } = getStaticData();
    const { ...fonts } = getFont(true);
    const {psTheme: theme}=useTheme();
    const styles = createStyle(theme, fonts);

    if(viewState === ViewState.LOADING) {
        return renderProgressView(true);
    } else if(viewState === ViewState.LOADED) {
        props.updateHotelOverlayType(ISSUE_AT_HOTEL_OVERLAY.ISSUE_RESOLUTION, callDrivers);
    } else if(viewState === ViewState.ERROR) {
        return renderErrorView(() => callApi(callDrivers), true);
    }
    
    const handleOptionList = async (index, item) => {
        setItemActive(item);
        const callDrivers = {
            primarytype: props.mydeskLobName,
            secondarytype: "CheckIn-Denied",
            ternarytype: item,
        };
        setCallDrivers(callDrivers);
        await callApi(callDrivers);
    }

    const callApi = async (callDrivers) => {
        try {
            setViewState(ViewState.LOADING);
            const context = {...callDrivers, pageName: ""};
            const request = {
                bookingId: props.bookingId,
                context: context
            };
            const userInfo = await HotelBookingModule.getRequestHeader(props.bookingId, 'HOTEL_CHECKIN_DENIED_CALL_NOW');
            const userDetails = await getUserDetails();
            if(request && userDetails && userDetails.uuid) {
                request["uuid"] = userDetails.uuid;
            }
            const response = await
                fetch(CALL_NOW_URL, {
                method: 'POST',
                body: JSON.stringify(request),
                headers: await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo)
                });
            if (response && response.ok) {
                const body = await response.json();
                if (body.success) {
                    setViewState(ViewState.LOADED);
                } else {
                    setViewState(ViewState.ERROR);
                }
            } else {
                setViewState(ViewState.ERROR);
            }
        } catch (e) {
            setViewState(ViewState.ERROR);
        }
    }

    return (
        <>
            <View style={AtomicCss.marginBottom16}>
                <View style={styles.overlayTopline}></View>
            </View>
            <Text style={styles.title}>{hotelDenyingCheckinText}</Text>
            <View style={styles.scheduleListTimeWrapper}>
                    {issueL2List.map((item, index) => {
                    return (
                        <L2Option
                            item={item}
                            index={index}
                            handleOptionList={handleOptionList}
                            isActiveItem={itemActive}
                        />
                    )

                    })}
            </View>
        </>

    );
}


const L2Option = ({ item, handleOptionList, index, isActiveItem }) => {
    const {psTheme: theme}=useTheme();
    const styles = createStyle(theme);

    const isActiveSuffix = item === isActiveItem;
    const activeTab = isActiveSuffix ? 'activeTab' : '';
    const activeTabTxt = isActiveSuffix ? 'activeTabTxt' : '';
    return (
        <>
            <TouchableOpacity style={[styles.scheduleListTimeItem, styles[activeTab]]} onPress={() => handleOptionList(index, item)}>
                <Text style={[styles.scheduleTxt, styles[activeTabTxt]]}>{item}</Text>
            </TouchableOpacity>
        </>


    );
}

export default IssueL2Selection;
