import { Platform } from 'react-native';

export const labels = {
  DATEBOTTOMSHEET_TITLE: 'Train start date',
  DISCLAIMER: 'Disclaimer',
  STOP: 'Stop ',
  ONTIME: 'On-time ',
  LATE: ['Running  ', ' late'],
  DELAY: ' delay',
  TIME_LEFT: 'Time Left',
  DAY: 'Day',
  DAYS: 'Days',
  REMAINING: 'Remaining',
  DEFAULT_ERROR_MSG: 'Your internet connection is unstable. Please try again later.',
  ERRORVIEW_BUTTON_TEXT: 'BACK',
  ERRORVIEW_BUTTON_TEXT_BACK: 'BACK',
  ERRORVIEW_BUTTON_TEXT_REFRESH: 'TRY AGAIN',
  ORIGIN_STATION: 'Origin Station',
  CURRENT_STATION: 'Last Station',
  NEXT_STATION: 'Next Station',
  LAST_STATION: 'Last Station',
  YOUR_STOP: 'YOUR STOP',
  PLATFORM: 'Platform ',
  PLATFORM_BS: 'PL',
  STARTING_POINT: '(Starting Point)',
  ENDING_POINT: '(Ending Point)',
  NO_STOPPAGE: 'No Stoppage',
  ARR: 'ARR. ',
  DEP: 'DEP.  ',
  SCHD: 'Schd. ',
  OUTSIDE_TRAIN: 'Outside',
  INSIDE_TRAIN: 'Inside',
  HEADER_TITLE: 'Live Train Status',
  STARTING: 'Starting',
  SHOW_INTERMEDIATE: ' In-between Stations',
  SHOW_INTERMEDIATE_DIVERTED:'Diverted Stations',
  SHOW_INTERMEDIATE_CANCELLED:'Cancelled Stations',
  HIDE_INTERMEDIATE: 'Tap to hide stations',
  OUTSIDE_TRAIN_TITLE: 'Outside the train',
  INSIDE_TRAIN_TITLE: 'Inside the train',
  OUTSIDE_TRAIN_DESC: 'Powered by Internet',
  INSIDE_TRAIN_DESC_WITH_CELL_TOWER: 'Powered by GPS & Cell tower data',
  INSIDE_TRAIN_DESC_WITHOUT_CELL_TOWER: 'Powered by GPS',
  TRACKING_OPTION_BOTTOMSHEET_TITLE: 'Choose your location',
  ALARM_CLOCK_BOTTOMSHEET_TITLE: 'Station Alarm',
  SET_ALARM: 'SET ALARM',
  OKAY: 'OKAY',
  ALARM_SET:'Station Alarm Set!',
  ALARM_ALERT: 'We will alert ',
  ALARM_ALERT_BEFORE:' minutes before',
  TRAIN_REACHES:'train reaches',
  YOUR_STOP_MSG:'Your Stop',
  MINUTES: 'minutes',
  REMOVE_STATION_ALARM:'Station alarm removed successfully',
  ALERT_ME_BEFORE:'Alert Me Before',
  PROCEED: 'PROCEED',
  OFFLINE_ERROR: [
    'Oops, your location is too far from the train track.',
    'Switching to',
    "'Outside the train'",
  ],
  PIP_OFFLINE_ERROR: 'Oops, your location is too far from the train track.',
  LAST_UPDATED: 'last updated',
  FEEDBACK_TITLE: 'Rate our accuracy!',
  FEEDBACK_DEFAULT: 'Please tell us more about your experience...',
  FEEDBACK_SUBMIT: 'SEND FEEDBACK',
  FEEDBACK: 'Give feedback',
  FEEDBACK_TEXT_TITLE: 'Tell us more',
  TRAIN_CANCELLED: 'Train cancelled before ',
  TRAIN_DIVERTED: 'Train diverted before ',
  TRAIN_TERMINATED: 'Train has completed its journey at ',
  TRAIN_NOT_STARTED: 'Train is yet to start from ',
  LOCATION_POPUP:
    'Access to your exact location helps us serve you accurate Live Train Status even when no internet is available.',
  LOCATION_POPUP_PRIM_BTN: 'GO AHEAD',
  LOCATION_POPUP_SETTINGS:
    'Location permission is permanently disabled, Please go to app settings and allow location manually.',
  LOCATION_POPUP_SETTINGS_PRIM_BTN: 'GO TO SETTING',
  EMPTY_TIME: '__ __',
  FETCHING: 'Fetching',
  ALARM_TITLE:'Station Alarm',
  ALARM_CONTENT:`Your train {trainNo} will reach {stationName} in about {reminderTime} mins.`,
  LTS_OFFLINE_ERROR:
    "You are too far from the train track. Switching to 'Outside the train'",
  LTS_OFFLINE_GPS_ERROR: "No access to location. Switching to 'Outside the train",
};

export const Source =  {
  GPS : 'G',
  CELL_TOWER: 'C',
  GPS_CELL_TOWER: 'G&C',
};

export const switcher = {
  OFFLINE_ERROR: [
    [
      {
        bold: true,
        value: 'Oops!',
      },
      {
        bold: false,
        value: 'You are too far from the train track.',
      },
    ],
    [
      {
        bold: false,
        value: 'Switching mode to',
      },
      {
        bold: true,
        value: "'Outside the train'",
      },
    ],
  ],
  PERMISSION_ERROR: [
    [
      {
        bold: true,
        value: 'Oops!',
      },
      {
        bold: false,
        value: 'No access to location.',
      },
    ],
    [
      {
        bold: false,
        value: 'Switching mode to',
      },
      {
        bold: true,
        value: "'Outside the train'",
      },
    ],
  ],
  SWITCH_TO_INSIDE_MOUNT: [
    [
      {
        bold: false,
        value: 'Fetching the Live Train Status',
      },
      {
        bold: true,
        value: "'Inside the train'",
      },
    ],
  ],
  SWITCH_TO_INSIDE: [
    [
      {
        bold: false,
        value: 'Switching to',
      },
      {
        bold: true,
        value: "'Inside the train'",
      },
    ],
  ],
  SWITCH_TO_OUTSIDE_MOUNT: [
    [
      {
        bold: false,
        value: 'Fetching the Live Train Status',
      },
      {
        bold: true,
        value: "'Outside the train'",
      },
    ],
  ],
  SWITCH_TO_OUTSIDE: [
    [
      {
        bold: false,
        value: 'Switching to',
      },
      {
        bold: true,
        value: "'Outside the train'",
      },
    ],
  ],
  DEFAULT_MOUNT: [
    [
      {
        bold: true,
        value: 'Fetching the Live Train Status',
      }
    ],
  ],
};

export const AppStates = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  BACKGROUND: 'background',
};

export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const omnitureLabels = {
  LTS_OFFLINE_DROPDOWN_CLICKED: 'lts_offline_dropdown_clicked',
  OFFLINE_TRACKING_DONE: 'lts_offline_calculation_done',
  NO_NETWORK_ERROR: 'network_error',
};

export const toastLabels = {
  FAIL_RETRY: 'Unable to fetch recent updates at the moment. Please try again later.',
  FAIL_SCHEDULE: 'Unable to fetch live train status. Showing train schedule.',
};

export const trainListLabels = ['Distance', 'Station Name', 'Actual', 'Sched.'];

export const dimensions = {
  HEADER: 80, // Height of the component TrainHeader and TrainStickyHeader
  SECTION_HEADER: 71, // Height of the component DateLabel(Section List Header)
  TRAIN_LIST_CONTAINER_MARGIN_TOP: 25,
  NORMAL_INTERMEDIATE_STATION: {
    DEFAULT_OFFSET_WINDOW: 90,
  },
  LAST_INTERMEDIATE_STATION: {
    DEFAULT_OFFSET_WINDOW: 130,
  },
  NORMAL_STOPPING_STATION: {
    DEFAULT_OFFSET_WINDOW: {
      NO_INTERMEDIATES: 95,
      WITH_INTERMEDIATES: 131,
    },
  },
  LAST_STOPPING_STATION: {
    DEFAULT_OFFSET_WINDOW: 58,
  },
  DELAY_INDICATOR_WIDTH: {
    ONTIME: 52,
    DELAY_LESS_THAN_60: 92,
    DELAY_MORE_THAN_60: 112,
  },
};

export const ACTIONS_RAILS_LIVE_TRAIN_STATUS_INIT = 'ACTIONS_RAILS_LIVE_TRAIN_STATUS_INIT';
export const ACTIONS_RAILS_LIVE_TRAIN_STATUS_SET_LOADER =
  'ACTIONS_RAILS_LIVE_TRAIN_STATUS_SET_LOADER';
export const ACTIONS_RAILS_LIVE_TRAIN_STATUS_SHOW_ERROR =
  'ACTIONS_RAILS_LIVE_TRAIN_STATUS_SHOW_ERROR';
export const ACTIONS_RAILS_LIVE_TRAIN_STATUS_INIT_INTERMEDIATE =
  'ACTIONS_RAILS_LIVE_TRAIN_STATUS_INIT_INTERMEDIATE';
export const ACTIONS_RAIL_LIVE_TRAIN_STATUS_OFFLINE_TRACKING =
  'ACTIONS_RAIL_LIVE_TRAIN_STATUS_OFFLINE_TRACKING';
export const ACTIONS_RAIL_LIVE_TRAIN_STATUS_TRAIN_SCHEDULE =
  'ACTIONS_RAIL_LIVE_TRAIN_STATUS_TRAIN_SCHEDULE';
export const ACTIONS_RAILS_LIVE_TRAIN_STATUS_EMPTY = 'ACTIONS_RAILS_LIVE_TRAIN_STATUS_EMPTY';
export const ACTIONS_RAIL_LTS_OFFLINE_TRACKING_FETCHING = 'ACTIONS_RAIL_LTS_OFFLINE_TRACKING_FETCHING';

export const LIVE_TRAIN_STATUS_CONST = 'live_train_status';

export const TRIP_GUARANTEE_CONST = 'trip_guarantee'

export const asyncStorageKeys = {
  RIS_LTS_OPTIONS: 'RIS_LTS',
  TRAIN_INFO: 'RIS_LTS_',
  CT_INFO: 'LTS_Cell_Tower_Map_Response',
  FEEDBACK: 'LTS_FeedBack',
  LOGGING: 'LTS_Logging',
  ERROR_LOGGING: 'LTS_Error_Logging',
  RAILS_FEEDBACK_BACK_BUTTON:'rails_feedback_back_button',
  ALARM:'alarmInfo_',
  INSIDE_MODE_SUCCESS: 'lts_inside_mode_success',
};

export const URL = 'https://railsinfo-services.makemytrip.com/api/rails/train/';
export const RIS_BASE_URL = 'https://rails-ris.makemytrip.com/api/ris/train';
export const LIVE_STATUS_URL = `${URL}livestatus/`;
export const railsTGSHeaderUrl = 'rails-tgs.makemytrip.com';
export const LIVE_TRAIN_STATUS_REQUEST_URL_INTERMEDIATE_STATIONS = 'scheduleintermediate/v1';
export const LIVE_TRAIN_STATUS_REQUEST_URL_INTERMEDIATE_STATIONS_V2 = 'scheduleintermediate/v2';
export const LIVE_TRAIN_STATUS_REQUEST_URL_V2 = `${LIVE_STATUS_URL}v2`;
export const LIVE_TRAIN_STATUS_REQUEST_URL_V3 = `${LIVE_STATUS_URL}v3`;
export const LIVE_TRAIN_STATUS_REQUEST_URL_CELL_TOWER_MAPPING =
  'https://railsinfo-services.makemytrip.com/api/rails/train/ltsoffline/v1';
export const LTS_ERROR_LOGGING_URL = `${RIS_BASE_URL}/lts/v1/log-fe-error`;
export const TRAIN_SCHEDULE_URL = `${URL}schedule/v1`;

const outsideIcon = require('@mmt/legacy-assets/src/lts_outside_train_icon.webp');
const insideIcon = require('@mmt/legacy-assets/src/lts_inside_train_icon.webp');

export interface TRACKING_OPTIONS_INTERFACE {
  key: string;
  label: string;
  title: string;
  value: boolean;
  desc: string;
  icon: any;
}

export const timeouts = {
  LIVESTATUS: 10000,
  SCHEDULEINTERMEDIATE: 10000,
  OFFLINE: 10000,
};

export const TRACKING_OPTIONS: TRACKING_OPTIONS_INTERFACE[] = [
  {
    key: 'OUTSIDE TRAIN',
    label: labels.OUTSIDE_TRAIN,
    title: labels.OUTSIDE_TRAIN_TITLE,
    value: false,
    desc: labels.OUTSIDE_TRAIN_DESC,
    icon: outsideIcon,
  },
  {
    key: 'INSIDE TRAIN',
    label: labels.INSIDE_TRAIN,
    title: labels.INSIDE_TRAIN_TITLE,
    value: true,
    desc:
      Platform.OS === 'android'
        ? labels.INSIDE_TRAIN_DESC_WITH_CELL_TOWER
        : labels.INSIDE_TRAIN_DESC_WITHOUT_CELL_TOWER,
    icon: insideIcon,
  },
];

export const viewStates = {
  LOADING: 'loading',
  SHOW_DETAIL: 'detail',
  NO_INTERNET: 'no_internet',
  DEFAULT: 'default',
  ERROR: 'error',
  LISTING_API_ERROR: 'listing_api_error',
  RENDER_LIST: 'render_list',
  REFRESH: 'refresh',
};

export const fireBaseErrorType = {
  CT_ERROR: 'RailsCTError',
  GPS_ERROR: 'RailsGPSError',
  UPDATE_ERROR: 'RailsUpdateError',
  OVERALL_ERROR: 'RailsOverallError',
}

export const BOTTOMSHEET_HEIGHT = 395;

export const alarmData = ['05', '15', '30', '45', '60'];
export const alarmMinimumTimeDiff = 5;
export const setIntervalTime = 60000;
export const ALGORITHM = {
  V0: 'V0',
  V1: 'V1',
};
