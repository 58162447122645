import { StyleSheet } from "react-native";

  export const getStyles = (fonts, color) => {
    return StyleSheet.create({
      fcWrapper: {
        paddingBottom: 30,
      },
      headingSection: {
        paddingHorizontal: 20,
        paddingVertical: 23,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        borderBottomWidth: 1,
        borderBottomColor: color.grayBg,
      },
      headingText: {
        color: color.black22,
        ...fonts.blackFontFamily,
        fontSize: fonts.fontSize.font24,
        marginBottom: 5,
      },
      detailsSection: {
        paddingVertical: 14,
        backgroundColor: color.white,
      },
      crossIconWrapper: {
        width: 30,
        height: 30,
        top: -10,
        right: -5,
        alignItems: 'center',
        justifyContent: 'center',
      },
      crossIconStyle: {
        width: 20,
        height: 20,
      },
      canIconStyle: {
        width: 32,
        height: 32,
        marginRight: 10,
      },
      canFeeInfo: {
        backgroundColor: color.pink6,
        paddingHorizontal: 8,
        paddingVertical: 6,
        borderRadius: 4
      },
      canFeeInfoText: {
        color: color.red,
        ...fonts.regularFontFamily,
        fontSize: fonts.fontSize.font16,
      },
      redirectLinkWrapper: {},
      rightArrowStyle: {
        transform: [
          {
            rotate: '-90deg',
          },
        ],
        width: 15,
        height: 10,
      },
      redirectLink: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingVertical: 20,
        alignItems: 'center',
        paddingLeft: 44,
        paddingRight: 20,
        borderBottomWidth: 1,
        borderBottomColor: color.lightGrey3,
      },
      btnWrapper: {
        paddingHorizontal: 24,
        paddingVertical: 15
      },
      infoDtlsSectionTracker: {
        paddingHorizontal : 20,
        marginBottom: 0,
      }
    });
  }


  export const getHtmlStyle = (fonts, color) => {
    return {
      p : {
        color: color.red,
        ...fonts.regularFontFamily,
        fontSize: fonts.fontSize.font16,
      }
    }
  }