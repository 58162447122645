import React from 'react';

import CommonOverlayMessage from '../../../../../../Common/CommonOverlayMessage';
import RequestWheelChairBottomOverlay from '../../../../details/components/WheelChairPopup';
import { resetToFlightBookingDetail } from 'apps/post-sales/src/flights/FlightBookingUtil';

function WheelChairAddon({
  setWheelChairFlow,
  flightDetailResponse,
  ancillaryResponse,
  segmentId,
  bookingId,
  popupContainerStyle,
}: {
  setWheelChairFlow: (wheelChair: boolean) => void;
  flightDetailResponse: object;
  ancillaryResponse: object;
  bookingId: string;
  segmentId: string;
  popupContainerStyle: object;
}) {
  return (
    <CommonOverlayMessage
      handleBottomOverlay={() => setWheelChairFlow(false)}
      overlayContentStyle={popupContainerStyle}
      content={
        <RequestWheelChairBottomOverlay
          segmentAncillaryDetail={ancillaryResponse.segmentAncillaryDetails}
          flightDetailResponse={flightDetailResponse}
          activeSegment={segmentId}
          wheelChairMap={ancillaryResponse.wheelChairMap}
          reloadFlightDetails={() => resetToFlightBookingDetail(bookingId, false)}
          toggleWheelChairOverlay={() => setWheelChairFlow(false)}
        />
      }
    />
  );
}

export default WheelChairAddon;
