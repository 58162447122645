import React, { useEffect, useRef } from 'react';
import { View, Animated } from 'react-native';
import styles from './ProgressBarCss';

const ProgressBar = ({ status, toValue, containerWidth }) => {
  const xTranslateVal = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    animateProgress(toValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toValue]);

  const animateProgress = (x) => {
    const toValue = (containerWidth / 100) * x;
    Animated.timing(xTranslateVal, {
      toValue: toValue,
      duration: 1000,
      delay: 0,
    }).start();
  };

  return (
    <View style={styles.progressWrapper}>
      <View style={styles.textWrapper}>
        <Animated.View style={[styles.transparentFiller, { width: xTranslateVal }]} />
      </View>
      <Animated.View
        style={[styles.progress, styles[`progress${status}`], { width: xTranslateVal }]}
      />
    </View>
  );
};

export default ProgressBar;
