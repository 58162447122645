import React, { useEffect } from 'react';
import { View, Image, Text, TouchableOpacity, DeviceEventEmitter} from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import BusCancelRebookConstant from '../../../CancelRebookConstants';
import { openCalender } from '../../../CancelRebookUtil';
import createStyles from './ChangeDateCardCSS';
import { CALENDER_ICON, EDIT_BLUE_ICON } from '../../../../BusImageConstants';
import { getFont } from '../../../../../PostSalesStyles';
import { useTheme } from '../../../../../theme/theme.context';
import { getFormattedDateFromMillis, dateMonth, dayDateMonth } from '@mmt/legacy-commons/Helpers/dateHelpers';

const ChangeDateCard = ({ date, handleDateChange }) => {
  const { fsStyle, ...fonts } = getFont();
  const {psTheme : theme} = useTheme();
  const styles = createStyles(theme, fonts);

  useEffect(() => {
    return () => {
      DeviceEventEmitter.removeAllListeners(BusCancelRebookConstant.CALENDAR_EVENT_RECEIVED);
    };
  },[]);

  const handleEditClick = () => {
    DeviceEventEmitter.removeAllListeners(BusCancelRebookConstant.CALENDAR_EVENT_RECEIVED);
    DeviceEventEmitter.addListener(
      BusCancelRebookConstant.CALENDAR_EVENT_RECEIVED,
      handleDateChange,
    );
    openCalender();
  }

  return (
    <View style={styles.editDateWrapper}>
      <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
        <Image source={CALENDER_ICON} style={styles.calendarIcon} />
        <Text style={[fsStyle.font15, fonts.blackFontFamily, styles.linkText]}>
          {getFormattedDateFromMillis(date,dayDateMonth)}
        </Text>
        <Text style={[fsStyle.font14, AtomicCss.midGreyText, fonts.regularFontFamily]}>
          {' '}
          ({BusCancelRebookConstant.NEW_DATES})
        </Text>
      </View>
      <TouchableOpacity onPress={handleEditClick}>
        <Image source={EDIT_BLUE_ICON} style={styles.calendarIcon} />
      </TouchableOpacity>
    </View>
  );
};

export default ChangeDateCard;
