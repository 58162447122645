import React, {useEffect} from 'react';
import { View, Text, StyleSheet, Image, Dimensions } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { getFont } from '../../PostSalesStyles';
import { useTheme } from '../../theme/theme.context';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
import isEmpty from 'lodash/isEmpty'
import MMTSelectTrackingHelper from '../MmtSelectNewUserCard/MMTSelectTrackingHelper';

const MMTSelectCard = ({ cancellationPopupDetails, backgroundGradient, tierName, pageName }) => {
  if(isEmpty(cancellationPopupDetails) || backgroundGradient.length<2){
    return null;
  }
  const {...fonts} = getFont(true);
  const {psTheme} = useTheme();
  const styles = getStyles(psTheme, fonts);
  const { text, iconUrl } = cancellationPopupDetails || {};
  useEffect(()=>{
    MMTSelectTrackingHelper.trackMMTSDisplayEvent(pageName,`MMTS_to_cancel_${tierName}_lose_card_displayed`);
  },[]);
  return (
    <LinearGradient
      start={{ x: 0, y: 0 }}
      end={{ x: 1, y: 0 }}
      colors={[backgroundGradient[0], backgroundGradient[1]]}
      style={styles.loyalityCard}
    >
      {!isEmpty(iconUrl) &&  <View style={styles.iconWrapper}>
          <Image style={styles.loyalityIcon} source={{uri : iconUrl}}/>
      </View>}
      {!isEmpty(text) && <Text style={[styles.loyalityInfoText ]}>{text}</Text>}
    </LinearGradient>
  );
};

const getStyles = (theme, fonts) => {
    const {color} = theme;
    return StyleSheet.create({
        loyalityCard: {
            paddingHorizontal: 12,
            paddingVertical: 10,
            backgroundColor: color.white,
            ...getPlatformElevation(1),
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: 20
          },
      iconWrapper: {
        marginRight: 8,
        top: 2,
      },
      loyalityIcon: {
        width: 33,
        height: 33,
      },
      loyalityInfoText: {
        color: color.black,
        ...fonts.regularFontFamily,
        fontSize: fonts.fontSize.font14,
        lineHeight: 16,
        width: 250
      }
    });
}

export default MMTSelectCard;
