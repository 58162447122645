import { StyleSheet } from "react-native";
export default (theme, fonts) =>StyleSheet.create({
    pickAndDropDetails:{
        paddingBottom:12,
        marginBottom:8,
        borderBottomWidth:1,
        borderBottomColor:theme.color.gray1,
    },
    borderLine:{
        width:12,
        height:1,
        backgroundColor:theme.color.lightTextColor,
        margin:5
    },
    lineHeight24:{
        lineHeight:24
    }
})

