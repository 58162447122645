import React, { useEffect } from 'react';

import Thankyou from '../../../../Common/thankyou';
import { resetToHotelDetail } from '../../../details/utils/HotelBookingDetailUtil';
import PostSalesBookingTrackingHelper from '../../../../PostSalesBookingTrackingHelper';

interface TravelInsuranceThankyouPageProps {
  bookingId: string;
  description: string;
  message: string;
  buttonText: string;
  iconUrl: string;
}

const TravelInsuranceThankyouPage: React.FC<TravelInsuranceThankyouPageProps> = (props) => {
  const { bookingId, description, message, buttonText, iconUrl } = props;
  const actions = [
    {
      text: buttonText,
      clickHandler: () => {
        resetToHotelDetail(bookingId);
      },
      variant: 'PRIMARY',
      shadow: true,
      id: 'backToBooking',
    },
  ];
  useEffect(() => {
    PostSalesBookingTrackingHelper.trackLoadEvent(
      'Travel_Insurance_thank_you',
      {},
      bookingId,
    );
  }, []);
return (
    <Thankyou
      icon={{ uri: iconUrl }}
      description={description}
      message={message}
      actions={actions}
    />
  );
};

export default TravelInsuranceThankyouPage;
