import React from 'react';
import Actions from '../../../navigation/postSalesNavigation';
import PropTypes from 'prop-types';
import {ScrollView, Text, View} from 'react-native';
import Header from '@mmt/legacy-commons/Common/Components/Header/Header';
import styles from '../../styles/AcmeMamiCss';

class AcmeRedemptionSteps extends React.Component {
  static navigationOptions = {
    header: null
  }

  render() {
    return (
      <View style={[styles.whiteContainer, styles.transperantBgrnd]}>
        <Header
          title="How to redeem"
          onBackPress={this.onBackPress}
          type="Elevated"
        />
        <ScrollView>
          <View style={styles.PolicyDetailsContainer}>
            {this.showRedemptionSteps()}
          </View>
        </ScrollView>
      </View>
    );
  }

  onBackPress = () => {
    Actions.pop();
  }

  showRedemptionSteps() {
    const views = [];
    this.props.response.acmeBookingInfo.acmeBookingDetails[0].acmeAdditionalInfo.howToRedeem.forEach((redemptionStep) => {
      this.createRedemtionView(views, redemptionStep);
    });
    return views;
  }

  createRedemtionView(views, redemptionStep) {
    views.push(<View style={[styles.mar15Bt, styles.flexRow]} key={redemptionStep}>
      <View style={styles.ItemDisc} />
      <Text>{redemptionStep}</Text>
    </View>);
  }
}
AcmeRedemptionSteps.propTypes = {
  response: PropTypes.object.isRequired
};
export default AcmeRedemptionSteps;
