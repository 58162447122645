
import {StyleSheet} from 'react-native'
export default (theme,fonts) => 
StyleSheet.create(
  {
    contentTitle: {
      fontSize: fonts.fontSize.font34,
      color: theme.color.black,
      ...fonts.lightFontFamily,
      marginBottom: 24,
    },
    contentDesc: {
      fontSize: fonts.fontSize.font14,
      color: theme.color.defaultTextColor,
      ...fonts.regularFontFamily,
      marginBottom: 30,
      lineHeight: 22,
    },
    contentFooter: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      paddingBottom: 10,
    },
    actionBtn: {
      paddingVertical: 10,
      marginBottom: 10,
    },
    actionBtnText: {
      fontSize: fonts.fontSize.font16,
      color: theme.color.azure,
      ...fonts.mediumFontFamily
    },
    paddingBottom25: { paddingBottom: 25 },
  }
)

