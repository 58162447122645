import React from 'react';
import {Image, Text, TouchableOpacity, View} from 'react-native';
import styles from './LocationOverlayCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import Map from '@mmt/legacy-assets/src/map.webp';
import {CROSS_ICON} from '../../../../SelfDriveImageConstant';
import {openGoogleMapAlways} from '../../../../../Common/commonUtil';

const LocationOverlay = (props) => {
  const options = {label: null};
  return (
    <React.Fragment>
      <View style={styles.overlay} />
      <View style={styles.bottomOverlayWrapper}>
        <View style={[AtomicCss.flexRow, AtomicCss.marginBottom30]}>
          <View style={AtomicCss.flex1}>
            <Text
              style={[AtomicCss.blackText, AtomicCss.font20, AtomicCss.blackFont, AtomicCss.marginBottom8]}
            >{props.header}
            </Text>
            <Text style={[AtomicCss.defaultText, AtomicCss.font14, AtomicCss.marginBottom12]}>{props.subText}</Text>
            <View style={styles.underline} />
          </View>
          <TouchableOpacity onPress={() => props.handlePress('')}><Image
            style={styles.crossIcon}
            source={CROSS_ICON}
          />
          </TouchableOpacity>
        </View>
        <View>
          <View style={AtomicCss.marginBottom5}><Text
            style={[AtomicCss.darkGreyText, AtomicCss.font10, AtomicCss.boldFont]}
          >ADDRESS
          </Text>
          </View>
          <View style={AtomicCss.marginBottom5}><Text
            style={[AtomicCss.blackText, AtomicCss.boldFont, AtomicCss.font16]}
          >
            {props.tripInfo.address1}
          </Text>
          </View>
          <View style={AtomicCss.marginBottom16}><Text style={[AtomicCss.font14, AtomicCss.defaultText]}>{props.tripInfo.address2}</Text></View>
          <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom26]}>
            <Image style={styles.mapIcon} source={Map} />
            <Text
              style={[AtomicCss.azure, AtomicCss.blackFont, AtomicCss.font14, AtomicCss.textUppercase]}
              onPress={() => {
                openGoogleMapAlways(props.tripInfo.latStr,props.tripInfo.longStr,options);
                              }}
            >View on maps
            </Text>
          </View>
        </View>
        {<TouchableOpacity onPress={() => props.handlePress('')}>
          <View style={AtomicCss.marginTop15}><Text
            style={[AtomicCss.alignRight, AtomicCss.azure, AtomicCss.blackFont, AtomicCss.font16, AtomicCss.textUppercase]}
          >OKAY,
                        GOT IT
          </Text>
          </View>
         </TouchableOpacity>}
      </View>
    </React.Fragment>
  );
};

export default LocationOverlay;
