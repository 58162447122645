import { Dimensions } from 'react-native';
import { Platform } from 'react-native';
import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';

const windW = Dimensions.get('screen').width;

const styles1 = {
  cardWrapper: {
    ...Platform.select({
      android: { width: windW },
    }),
  },
  btnLinkWrapper: {
    paddingVertical: 16,
    paddingHorizontal: 16,
    ...getPlatformElevation(2),
    justifyContent: 'space-between',
    flexDirection: 'row',
    backgroundColor: '#fff',
    borderTopWidth: 1,
    borderTopColor: '#ddd',
  },
  fltDtlsWrapper: {
    backgroundColor: '#fff',
  },
  topHeading: {
    paddingHorizontal: 16,
    paddingTop: 10,
    paddingBottom: 12,
    marginBottom: 2,
    ...getPlatformElevation(3),
    backgroundColor: '#fff',
  },
  arrowStyle: {
    width: 10,
    height: 8,
    alignSelf: 'center',
    marginLeft: 5,
    marginRight: 5,
    marginTop: 3,
  },
};
export default styles1;
