import React from 'react';
import { View, Text, Image, StyleSheet, TouchableOpacity } from 'react-native';
import HTMLView from 'react-native-htmlview';
import { isEmpty } from 'lodash';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getFont } from '../../../../PostSalesStyles';
import { useTheme } from '../../../../theme/theme.context';
import Button from '../../../../Common/Button';
import { CROSS_ICON1 } from '../../../HotelImageConstants';
import LinearGradient from 'react-native-linear-gradient';
import ButtonWrapper from '@mmt/post-sales/src/Common/ButtonWrapper';

interface CtaInterface {
  actionFamily: string;
  actionLabelText: string;
}

interface HeaderTag {
  tagText: string;
  tagColor?: string;
  gradientColor: string[];
}

interface Action {
  actionLabeltext: string;
  actionId: string;
  actionFamily?: string;
  actionUrl?: string;
  actionHeaderText?: string;
}

interface OfferDetailsInterface {
  headerTag?: HeaderTag;
  actionList?: Action[];
  heading: string;
  cta: CtaInterface;
  defaultCTA: string;
  bankIcon: string;
  offerDesc: string;
  actualAmount: string;
  discountedAmount: string;
  couponCodeText: string;
  conditions: string;
}

interface BankOfferPopupInterface {
  offerDetails: OfferDetailsInterface;
  dismiss: () => void;
  handlePaymentOfferClick: () => void;
  handleCancelClick: (action?: Action) => void;
  showCrossIcon?: boolean;
}

const BankOfferPopup = ({
  handlePaymentOfferClick,
  offerDetails,
  handleCancelClick,
  dismiss,
  showCrossIcon,
}: BankOfferPopupInterface) => {
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme }: any = useTheme();
  const styles = createStyles(theme, fonts);
  const getHtmlStyles = (fonts, theme) => {
    return {
      p: {
        color: theme.color.black,
          ...fonts.font14,
        fontFamily: fonts.regular,
        lineHeight: 20
      },
      b: {
        fontFamily: fonts.black,
        color: theme.color.black
      },
      slice: {
        textDecorationLine: 'line-through',
        fontFamily: fonts.regular,
      }
    };
  }
  const htmlStyle = getHtmlStyles(fonts, theme);
  const {
    headerTag,
    actionList = [],
    heading,
    cta,
    defaultCTA,
    bankIcon = '',
    offerDesc,
    conditions,
  } = offerDetails;
  const { tagText, tagColor, gradientColor } = headerTag || {}

  return (
    <View style={[AtomicCss.marginBottom20]}>
          {!!tagText && !isEmpty(gradientColor) && <LinearGradient
            start={{x: 1.0, y: 0.0}}
            end={{x: 0.0, y: 1.0}}
            style={styles.headerTag}
            colors={gradientColor}
          >
            <Text style={[{fontSize: 16, color: tagColor, fontFamily: fonts.bold}]}>{tagText}</Text>
          </LinearGradient>}
      <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween, AtomicCss.paddingHz12]}>
        {!!heading && <Text style={[styles.title]}>{heading}</Text>}
        {showCrossIcon && 
        <TouchableOpacity
          style={styles.crossWrapper}
          onPress={() => {
            dismiss && dismiss();
          }}
        >
          <Image style={styles.closeIcon} source={CROSS_ICON1} />
        </TouchableOpacity>
        }
      </View>
      <View style={styles.headingWrapper}>
        <View style={[AtomicCss.flexRow]}>
          {
            !!bankIcon &&
            <View style={styles.bnkLogoWrapper}>
              <Image style={styles.logoStyle} source={{ uri: bankIcon }} />
            </View>
          }
          <View style={[AtomicCss.flex1]}>
            {!!offerDesc && <HTMLView value={offerDesc} stylesheet={htmlStyle} />}
            {!!conditions && <Text style={[styles.defaultText, fsStyle.font14, AtomicCss.paddingTop22]}>{conditions}</Text>}
          </View>
        </View>
      </View>
      {!isEmpty(actionList) && 
        actionList.map((callToAction: Action, index: number) => {
          const { actionLabeltext, actionId } = callToAction || {}
          return !!actionLabeltext && (
            <ButtonWrapper
              key={`${actionId}_${index}`}
              clickHandler={() => handleCancelClick(callToAction)}
              btnText={actionLabeltext}
              buttonType={index ? 'outline' : 'fill'}
              isThrottleRequired={true}
              throttleInterval={1000}
              btnWrpStyle={[AtomicCss.paddingHz12, AtomicCss.marginBottom12, AtomicCss.marginTop4]}
            />
          )
        })
      }
      {!isEmpty(cta) && (
        <View style={[AtomicCss.marginBottom20, AtomicCss.marginHz20]}>
          <Button
            theme={theme}
            clickHandler={handlePaymentOfferClick}
            fontSize={fsStyle.font16}
            btnBg="gradientBtn"
            btnType="flat"
            btnTxt={cta?.actionLabelText}
          />
        </View>
      )}
      {!!defaultCTA && (
        <TouchableOpacity onPress={() => handleCancelClick()}>
          <Text
            style={[styles.primaryText, fonts.boldFontFamily, fsStyle.font16, AtomicCss.textCenter]}
          >
            {defaultCTA}
          </Text>
        </TouchableOpacity>
      )}
    </View>
  );
};
const createStyles = (theme: any, fonts: any) => {
  return StyleSheet.create({
    defaultText: {
      color: theme.color.defaultTextColor,
    },
    blackText: {
      color: theme.color.black,
    },
    primaryText: {
      color: theme.color.azure,
    },
    headerTag: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'row',
      alignSelf: 'flex-start',
      paddingHorizontal: 8,
      paddingVertical: 4,
      marginLeft: 12,
      marginRight: 8,
      marginVertical: 8
    },
    title: {
      color: theme.color.black,
      ...fonts.font20,
      fontFamily: fonts.bold,
      marginBottom: 20,
      lineHeight: 27,
      flex: 1,
    },
    crossWrapper: {
      alignSelf: 'flex-start',
    },
    closeIcon: {
      width: 14,
      height: 14,
      alignSelf: 'flex-start',
      marginTop: 10,
    },
    headingWrapper: {
      paddingVertical: 10,
      marginBottom: 20,
      paddingHorizontal: 12,
    },
    bnkLogoWrapper: {
      marginRight: 10,
    },
    logoStyle: {
      width: 48,
      height: 48,
    },
    slacedPrice: {
      textDecorationLine: 'line-through',
      fontFamily: fonts.regular,
    },
  });
};
export default BankOfferPopup;
