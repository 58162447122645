/* eslint-disable max-len,react/no-typos,react/prop-types,react/no-string-refs */
import React from 'react';
import { Text, View, ScrollView, TouchableOpacity, Alert, Keyboard } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import PropTypes from 'prop-types';
import styles from '../Styles/RefundPaymentModeCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import OverlayMessage from '../Overlays/OverlayMessage';
import BottomOverlayDtls from '../Overlays/BottomOverlayDtls';
import OTPBottomOverlay from '../Overlays/OTPBottomOverlay';
import OTPLoader from '../Overlays/OTPLoader';
import StickyHeaderBack from '../../../bus/components/StickyHeader/StickyHeaderBack';
import ReviewStepHeader from '../../../bus/components/ReviewStepHeader/ReviewStepHeader';
import ReviewStepHeader2 from '../../../bus/components/ReviewStepHeader/ReviewStepHeader2';
import RefundModeList from '../../../bus/cancellation/RefundMode/RefundModeList';
import { registerForInstantRefund, sendOtp, verifyOtp } from '../../commonUtil';
import * as PSC from '../../../PostSalesConstant';
import { isNonEmpty } from '@mmt/legacy-commons/Common/utils/StringUtils';
import PostSalesBookingTrackingHelper from '../../../PostSalesBookingTrackingHelper';
import InstantRefundBanner from '../Overlays/InstantRefundBanner';
import { label } from '@mmt/post-sales/src/staticData/staticData';
import { getFont } from '../../../PostSalesStyles';

const gradientColor = ['#065af3', '#53b2fe'];
export default class BusSelectRefundMode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bottomOverlay: '',
      OTPValue: '',
      errorState: false,
      keyboard: false,
    };
    this.clientTxnId = null;
    this.instantRefund = false;
  }

  static navigationOptions = {
    header: null,
  };

  componentDidMount() {
    if (
      this.props.previewObject &&
      this.props.previewObject.instantRefundData &&
      this.props.previewObject.instantRefundData.showInstantRefundBanner
    ) {
      PostSalesBookingTrackingHelper.trackLoadEvent(
        PSC.REFUNDMODE_PAGE_NAMES.BUS,
        PSC.REGISTER_INSTANT_REFUND_SHOWN,
        this.props.bookingDetails.bookingId,
      );
    } else {
      PostSalesBookingTrackingHelper.trackLoadEvent(
        PSC.REFUNDMODE_PAGE_NAMES.BUS,
        PSC.INSTANT_REFUND_NOT_ELIGBLE,
        this.props.bookingDetails.bookingId,
      );
    }
  }

  componentWillMount() {
    this.keyboardDidShowListener = Keyboard.addListener(
      PSC.KEYBOARD_SHOW_EVENT,
      this._keyboardDidShow,
    );
    this.keyboardDidHideListener = Keyboard.addListener(
      PSC.KEYBOARD_HIDE_EVENT,
      this._keyboardDidHide,
    );
  }

  componentWillUnmount() {
    this.keyboardDidShowListener && this.keyboardDidShowListener.remove();
    this.keyboardDidHideListener && this.keyboardDidHideListener.remove();
  }

  _keyboardDidShow = () => {
    this.setState({ keyboard: true });
  };

  _keyboardDidHide = () => {
    this.setState({ keyboard: false });
  };

  handleBottomOverlay = (showOverlay) => {
    this.setState({ bottomOverlay: showOverlay, OTPValue: PSC.EMPTY_STRING });
  };

  resendOtp = () => {
    PostSalesBookingTrackingHelper.trackClickEvent(
      PSC.REFUNDMODE_PAGE_NAMES.BUS,
      PSC.INSTANT_REFUND_OTP_REQUESTED_AGAIN,
      this.props.bookingDetails.bookingId,
    );
    this.sendOtp();
  };

  async sendOtp() {
    this.clientTxnId = await sendOtp(this.props.bookingDetails.bookingId);
    if (isNonEmpty(this.clientTxnId)) {
      PostSalesBookingTrackingHelper.trackClickEvent(
        PSC.REFUNDMODE_PAGE_NAMES.BUS,
        PSC.INSTANT_REFUND_OTP_SUCCESS,
        this.props.bookingDetails.bookingId,
      );
      this.setState({ bottomOverlay: PSC.OTP_OVERLAY });
    } else {
      /* no screen for otp sending failure right now. Alert temp */
      PostSalesBookingTrackingHelper.trackClickEvent(
        PSC.REFUNDMODE_PAGE_NAMES.BUS,
        PSC.INSTANT_REFUND_OTP_FAILED,
        this.props.bookingDetails.bookingId,
      );
      Alert.alert(PSC.OTP_FAILED_ALERT);
    }
  }

  async verifyOtp() {
    const authorizationToken = await verifyOtp(
      this.props.bookingDetails.bookingId,
      this.state.OTPValue,
      this.clientTxnId,
    );
    if (!authorizationToken) {
      PostSalesBookingTrackingHelper.trackLoadEvent(
        PSC.REFUNDMODE_PAGE_NAMES.BUS,
        PSC.WRONG_OTP_ENTERED,
        this.props.bookingDetails.bookingId,
      );
      this.setState({ bottomOverlay: PSC.OTP_OVERLAY, errorState: true });
    } else {
      PostSalesBookingTrackingHelper.trackClickEvent(
        PSC.REFUNDMODE_PAGE_NAMES.BUS,
        PSC.INSTANT_REFUND_REQUESTED,
        this.props.bookingDetails.bookingId,
      );
      this.setState({ bottomOverlay: PSC.EMPTY_STRING });
      this.props.cancellationFunction(authorizationToken);
    }
  }

  handleOTPValue = (EnterText) => {
    this.setState({
      OTPValue: EnterText,
      errorState: false,
    });
  };

  handleOTPOverlay = () => {
    if (this.state.OTPValue === PSC.EMPTY_STRING) {
      this.setState({
        errorState: true,
      });
    } else {
      this.setState({ bottomOverlay: PSC.OTP_LOADER });
      this.verifyOtp();
    }
  };
  updateRefundMode = (updatedMode) => {
    this.instantRefund =
      isNonEmpty(updatedMode.accountNo) && isNonEmpty(updatedMode.accountIdentifier);
    if (this.instantRefund) {
      PostSalesBookingTrackingHelper.trackLoadEvent(
        PSC.REFUNDMODE_PAGE_NAMES.BUS,
        PSC.INSTANT_REFUND_AVAILABLE,
        this.props.bookingDetails.bookingId,
      );
    }
    this.props.updateRefundMode(updatedMode);
  };

  handleProceedButtonFn = () => {
    PostSalesBookingTrackingHelper.trackClickEvent(
      PSC.REFUNDMODE_PAGE_NAMES.BUS,
      PSC.REGISTER_INSTANT_REFUND_CLICKED,
      this.props.bookingDetails.bookingId,
    );
    registerForInstantRefund(PSC.LOBNAMES.BUS);
  };

  handleConfirmBtn = () => {
    if (this.instantRefund) {
      this.sendOtp();
    } else {
      PostSalesBookingTrackingHelper.trackClickEvent(
        PSC.REFUNDMODE_PAGE_NAMES.BUS,
        PSC.NO_INSTANT_REFUND_REQUESTED,
        this.props.bookingDetails.bookingId,
      );
      this.props.handleConfirmBtn();
    }
  };

  render() {
    const fonts = getFont(true);
    return (
      <View style={styles.pageWrapper}>
        <StickyHeaderBack
          busDetails={this.props.bookingDetails ? this.props.bookingDetails : null}
        />
        <ScrollView ref="pageScroll" showsVerticalScrollIndicator={false} style={styles.pageScroll}>
          {this.props.isFullCancellation === true && <ReviewStepHeader currentIndex={2} />}
          {this.props.isFullCancellation === false && <ReviewStepHeader2 currentIndex={3} />}

          <View style={[AtomicCss.whiteCard, { padding: 0 }]}>
            <RefundModeList
              bookingDetails={this.props.bookingDetails}
              previewObject={this.props.previewObject}
              refundMode={this.updateRefundMode}
              instantRefund={this.instantRefund}
            />
          </View>
          {this.props.previewObject.instantRefundData &&
            this.props.previewObject.instantRefundData.showInstantRefundBanner && (
              <InstantRefundBanner handleOverlay={this.handleBottomOverlay} />
            )}
        </ScrollView>
        <TouchableOpacity style={styles.footerBtnWrapper} onPress={() => this.handleConfirmBtn()}>
          <LinearGradient
            start={{ x: 1.0, y: 0.0 }}
            end={{ x: 0.0, y: 1.0 }}
            colors={gradientColor}
            style={styles.BtnStyle}
          >
            <Text style={[styles.buttonTxtStyle, fonts.blackFontFamily, fonts.font14]}>{label('bus.cancellation.initiateRefund')}</Text>
          </LinearGradient>
        </TouchableOpacity>

        {this.state.bottomOverlay === PSC.OVERLAY && (
          <OverlayMessage
            handleBottomOverlay={this.handleBottomOverlay}
            content={
              <BottomOverlayDtls
                handleBottomOverlay={this.handleBottomOverlay}
                handleProceedButton={this.handleProceedButtonFn}
              />
            }
          />
        )}
        {this.state.bottomOverlay === PSC.OTP_OVERLAY && (
          <OverlayMessage
            handleBottomOverlay={this.handleBottomOverlay}
            keyboard={this.state.keyboard}
            content={
              <OTPBottomOverlay
                bookingId={this.props.bookingDetails.bookingId}
                OTPValue={this.state.OTPValue}
                handleBottomOverlay={this.handleBottomOverlay}
                handleOTPValue={this.handleOTPValue}
                handleOTPOverlay={this.handleOTPOverlay}
                errorState={this.state.errorState}
                resendOtp={this.resendOtp}
              />
            }
          />
        )}

        {this.state.bottomOverlay === PSC.OTP_LOADER && (
          <OverlayMessage handleBottomOverlay={this.handleBottomOverlay} content={<OTPLoader />} />
        )}
      </View>
    );
  }
}

BusSelectRefundMode.proptypes = {
  previewObject: PropTypes.object,
  bookingDetails: PropTypes.object,
  handleConfirmBtn: PropTypes.func.isRequired,
};
