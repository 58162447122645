import {Platform} from 'react-native';
import withRouter from './webImport';

const withRouterWrapper = (connectRef) => {
  if (Platform.OS === 'web') {
    return withRouter(connectRef);
  }
  return connectRef;
};

export default withRouterWrapper;
