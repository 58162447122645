import React from 'react';
import { getHolidaySummaryData } from './holidayCardAdapter';
import {
    ActionsContent,
    DescriptionSection,
    MainContent,
    SummaryContainer,
    DescriptionCardInfoSection,
    JourneyStartsInfoText,
    StatusInfoSection,
    CardInfoSection,
    WhereToGoTopImage,
} from '../cardElements';
import MSRRefundInfoCard from '../MSRRefundInfoCard';
import { HolidayData, MsrTicket } from '../cardInterfaces';

interface HolidaySummaryCardProps {
    response: HolidayData;
    msrTicket: MsrTicket;
    isUpcomingOrOnTripFirstBooking: boolean,
    topImage: string,
}
function HolidaySummaryCard({ response, msrTicket, isUpcomingOrOnTripFirstBooking, topImage }: HolidaySummaryCardProps) {
    const cardData = getHolidaySummaryData(response, msrTicket);
    const {
        iconUrl,
        packageName,
        actions,
        passengerDetails,
        myRequestsCard,
        startsIn,
        startingFrom,
        startsOnText,
        endsText,
        startDate,
        endDate,
        infoCardStatusText,
        infoCardStatusCTA,
        lobName,
        bookingId,
        status,
        openDetails,
        handleInfoPress } = cardData || {};

    return (
        <SummaryContainer bookingId={response.bookingID}>
            {topImage && isUpcomingOrOnTripFirstBooking &&
                <WhereToGoTopImage topImageText={startsIn} topImage={topImage} />
            }
            <MainContent onPress={openDetails}>
                <CardInfoSection
                    iconUrl={iconUrl}
                    name={startingFrom}
                    passengerDetails={passengerDetails}
                />
                <DescriptionCardInfoSection descriptionText={packageName} />
                <DescriptionSection>
                    <JourneyStartsInfoText startsOnText={startsOnText} startDate={startDate} endsText={endsText} endDate={endDate} />
                    {infoCardStatusText && <StatusInfoSection statusText={infoCardStatusText} statusCta={infoCardStatusCTA} handleInfoPress={handleInfoPress} />}
                </DescriptionSection>
            </MainContent>
            <ActionsContent actions={actions} isInfoCardShown={!!infoCardStatusText}/>
            {myRequestsCard && <MSRRefundInfoCard data={myRequestsCard} lobName={lobName} status={status} bookingId={bookingId} />}
        </SummaryContainer>
    );
}

export default HolidaySummaryCard;