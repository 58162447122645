import { StyleSheet } from 'react-native';

const getStyle = (fonts, theme) => {
	const { color } = theme;
	return StyleSheet.create({
		bookingInfoDetails: {
			paddingTop: 16,
			paddingHorizontal: 14,
			paddingBottom: 16
		},
		cabRescheduleWrapper: {
			paddingBottom: 0,
		},
		dotWrapper: {
			flexDirection: 'row',
			alignItems: 'center',
		},
		dot: {
			width: 8,
			height: 8,
			borderRadius: 10,
			borderWidth: 1,
			borderColor: color.textGrey,
			marginRight: 5,
		},
		dotFilled: {
			width: 8,
			height: 8,
			borderRadius: 10,
			borderWidth: 1,
			borderColor: color.textGrey,
			backgroundColor: color.textGrey,
			marginLeft: 5,
		},
		borderLine: {
			width: 50,
			height: 2,
			backgroundColor: color.textGrey,
			borderRadius: 4,
		},
		arrowStyleWrapper: {
			position: 'absolute',
			top: 0,
			right: 0,
		},
		arrowstyle: {
			width: 24,
			height: 24,
		},
		infoRowSection: {
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',
			width: '90%'
		},
		detailsRowSection: {
			paddingBottom: 15,
			paddingLeft: 14,
			borderLeftWidth: 1,
			borderLeftColor: '#4a4a4a'
		},
		lastRow: {
			borderLeftWidth: 0,
			borderLeftColor: 'transparent',
			paddingBottom: 0,
		},
		dotFilledPosition: {
			position: 'absolute',
			left: -8,
			top: 7
		},
		dotPositionWrapper: {
			width: 20,
			height: 20,
			justifyContent: 'center',
			alignItems: 'center',
			backgroundColor: '#fff',
			position: 'absolute',
			left: -8,
			top: 0,
		},
		activeArrow: {
			transform: [
				{
					rotate: "180deg"
				}
			]
		},
		timeUpdatedTag: {
			borderColor: "#3023ae",
			borderWidth: 1,
			borderRadius: 16,
			paddingHorizontal: 5,
			paddingVertical: 2,
		},
		timeUpdatedText: {
			color: '#9152c8',
		},
		assuranceImg: {
			width: 12,
			height: 12,
		},
		uppercase: {
			textTransform: 'uppercase',
		},
		rightArrowStyle: {
			width: 10,
			height: 10,
			marginLeft: 3,
			marginRight: 3,
		},
		multiCityIconStyle: {
			width: 12,
			height: 12,
			marginLeft: 4,
			marginRight: 4,
		}
	})
}

export default getStyle;