import React from 'react';
import {View, Text, TouchableOpacity} from 'react-native';
import PropTypes from 'prop-types';
import {get} from 'lodash';
import HTMLView from 'react-native-htmlview';
import FormTextInput from './FormTextInput';
import FormRadioInput from './FormRadioInput';
import FormGroupInput from './FormGroupInput';
import FormSingleSelectInput from './FormSingleSelectInput';
import FormMultiSelectInput from './FormMultiSelectInput';
import FormSingleSelectDropdownInput from './FormSingleSelectDropdownInput';
import styles from './DynamicFormCss';
import {FORM_DATA_TYPE_NEW, DEPENDENTS} from './DynamicFormConstants';

export default class InputFieldMaster extends React.Component {
  constructor(props) {
    super(props);


    this.deleteIndex = 0;
    this.fieldsCount = 0;
    if (get(this.props, `${this.props.jsonKey}.${this.props.fieldName}`, undefined)) {
      this.fieldsCount = get(this.props, `${this.props.jsonKey}.${this.props.fieldName}`).length;
      if (this.props.visaForm.fields[this.props.fieldName].type === FORM_DATA_TYPE_NEW.MULTI) {
        this.fieldsCount = 1;
      }
    } else if (this.props.visaForm.fields[this.props.fieldName].mandatory) {
      this.fieldsCount = 1;
    } else if (!this.props.visaForm.fields[this.props.fieldName].mandatory && !this.state.visaForm.fields[this.props.fieldName].addMore) {
      this.fieldsCount = 1;
    } else if (!this.state.visaForm.fields[this.props.fieldName].mandatory && this.state.visaForm.fields[this.props.fieldName].addMore) {
      this.fieldsCount = 0;
    }

    this.state = {
      visaForm: props.visaForm,
      inputs: []
    };
  }

  componentWillReceiveProps(newProps) {
    if (this.props.visaForm !== newProps.visaForm) {
      this.setState({
        visaForm: newProps.visaForm
      }, () => {
        const fields = [];
        for (let i = 0; i < this.fieldsCount; i += 1) {
          fields.push(this.addView(fields.length));
        }
        this.setState({
          inputs: fields
        });
      });
    }
    if ((newProps.validate === !this.props.validate)) {
      const fields = [...this.state.inputs];
      fields.forEach((element, index) => {
        fields[index].validate = newProps.validate;
      });
      this.setState({
        inputs: fields
      });
    }
  }

  componentWillMount() {
    const fields = [...this.state.inputs];
    for (let i = 0; i < this.fieldsCount; i += 1) {
      fields.push(this.addView(fields.length));
    }
    this.state.inputs = [...fields];
  }

  getLabelndex = (len) => {
    if (this.state.visaForm.fields[this.props.fieldName].addMore) {
      return `${this.props.labelIndex}.${len}`;
    }
    return `${this.props.labelIndex}`;
  }

  addView = (len) => {
    const fields = [];
    switch (this.state.visaForm.fields[this.props.fieldName].type) {
      case FORM_DATA_TYPE_NEW.DATE:
        fields.push({
          type: FORM_DATA_TYPE_NEW.DATE,
          key: `${this.props.jsonKey}.${this.props.fieldName}[${len}]`,
          parent: this.props.parent,
          jsonKey: `${this.props.jsonKey}.${this.props.fieldName}[${len}]`,
          visaForm: this.state.visaForm,
          fieldName: this.props.fieldName,
          validate: this.props.validate,
          updateChildValues: this.props.updateChildValues,
          labelIndex: this.getLabelndex(len + 1)
        });
        break;
      case FORM_DATA_TYPE_NEW.TEXT:
        fields.push({
          type: FORM_DATA_TYPE_NEW.TEXT,
          key: `${this.props.jsonKey}.${this.props.fieldName}[${len}]`,
          parent: this.props.parent,
          jsonKey: `${this.props.jsonKey}.${this.props.fieldName}[${len}]`,
          visaForm: this.state.visaForm,
          fieldName: this.props.fieldName,
          validate: this.props.validate,
          updateChildValues: this.props.updateChildValues,
          labelIndex: this.getLabelndex(len + 1)
        });
        break;
      case FORM_DATA_TYPE_NEW.TEXTAREA:
        fields.push({
          type: FORM_DATA_TYPE_NEW.TEXTAREA,
          key: `${this.props.jsonKey}.${this.props.fieldName}[${len}]`,
          parent: this.props.parent,
          jsonKey: `${this.props.jsonKey}.${this.props.fieldName}[${len}]`,
          visaForm: this.state.visaForm,
          fieldName: this.props.fieldName,
          validate: this.props.validate,
          updateChildValues: this.props.updateChildValues,
          labelIndex: this.getLabelndex(len + 1)
        });
        break;
      case FORM_DATA_TYPE_NEW.SELECT:
        fields.push({
          type: FORM_DATA_TYPE_NEW.SELECT,
          key: `${this.props.jsonKey}.${this.props.fieldName}[${len}]`,
          parent: this.props.parent,
          jsonKey: `${this.props.jsonKey}.${this.props.fieldName}[${len}]`,
          visaForm: this.state.visaForm,
          fieldName: this.props.fieldName,
          validate: this.props.validate,
          updateChildValues: this.props.updateChildValues,
          labelIndex: this.getLabelndex(len + 1)
        });
        break;
      case FORM_DATA_TYPE_NEW.MULTI:
        fields.push({
          type: FORM_DATA_TYPE_NEW.MULTI,
          key: `${this.props.jsonKey}.${this.props.fieldName}[${len}]`,
          parent: this.props.parent,
          jsonKey: `${this.props.jsonKey}.${this.props.fieldName}`,
          visaForm: this.state.visaForm,
          fieldName: this.props.fieldName,
          validate: this.props.validate,
          updateChildValues: this.props.updateChildValues,
          labelIndex: this.getLabelndex(len + 1)
        });
        break;
      case FORM_DATA_TYPE_NEW.RADIO:
        fields.push({
          type: FORM_DATA_TYPE_NEW.RADIO,
          key: `${this.props.jsonKey}.${this.props.fieldName}[${len}]`,
          parent: this.props.parent,
          jsonKey: `${this.props.jsonKey}.${this.props.fieldName}[${len}]`,
          visaForm: this.state.visaForm,
          fieldName: this.props.fieldName,
          validate: this.props.validate,
          updateChildValues: this.props.updateChildValues,
          labelIndex: this.getLabelndex(len + 1)
        });
        break;
      case FORM_DATA_TYPE_NEW.GROUP:
        fields.push({
          type: FORM_DATA_TYPE_NEW.GROUP,
          key: `${this.props.jsonKey}.${this.props.fieldName}[${len}]`,
          parent: '',
          jsonKey: `${this.props.jsonKey}.${this.props.fieldName}[${len}]`,
          visaForm: this.state.visaForm,
          fieldName: this.props.fieldName,
          validate: this.props.validate,
          updateChildValues: this.props.updateChildValues,
          labelIndex: this.getLabelndex(len + 1)
        });
        break;
      case FORM_DATA_TYPE_NEW.DROPDOWN:
        fields.push({
          type: FORM_DATA_TYPE_NEW.DROPDOWN,
          key: `${this.props.jsonKey}.${this.props.fieldName}[${len}]`,
          parent: this.props.parent,
          jsonKey: `${this.props.jsonKey}.${this.props.fieldName}[${len}]`,
          visaForm: this.state.visaForm,
          fieldName: this.props.fieldName,
          validate: this.props.validate,
          updateChildValues: this.props.updateChildValues,
          labelIndex: this.getLabelndex(len + 1)
        });
        break;
      default: break;
    }
    return fields[0];
  }

  add = () => {
    const fields = [...this.state.inputs];
    fields.push(this.addView(fields.length));
    this.setState({
      inputs: fields
    });
  }

  delete = () => {
    const fields = [...this.state.inputs];
    const fieldValues = get(this.props, `${this.props.jsonKey}.${this.props.fieldName}`, undefined);

    if (this.state.visaForm.fields[this.props.fieldName].mandatory && fieldValues.length === 1 && fields.length === 1) {
      return;
    }
    if (fieldValues && fields) {
      fieldValues.pop();
      this.props.updateChildValues(`${this.props.jsonKey}.${this.props.fieldName}`, fieldValues);
      fields.pop();
      this.setState({
        inputs: fields
      });
    }
  }

  deleteWRTIndex = (index) => {
    this.deleteIndex += 1;
    const fields = [...this.state.inputs];
    const fieldValues = get(this.props, `${this.props.jsonKey}.${this.props.fieldName}`, undefined);

    if (this.state.visaForm.fields[this.props.fieldName].mandatory && fieldValues.length === 1 && fields.length === 1) {
      return;
    }
    if (fieldValues && fields) {
      fields.pop();
      for (let i = index; i < fields.length; i += 1) {
        fields[i].key = `${fields[i].key}_${this.deleteIndex}`;
      }
      fieldValues.splice(index, 1);
      this.props.updateChildValues(`${this.props.jsonKey}.${this.props.fieldName}`, fieldValues);
      this.setState({
        inputs: fields
      });
    }
  }

  renderContent = () => {
    const inputViews = [];
    this.state.inputs.forEach((element, index) => {
      if (element) {
        switch (element.type) {
          case FORM_DATA_TYPE_NEW.DATE:
          case FORM_DATA_TYPE_NEW.TEXT:
            inputViews.push(<FormTextInput
              focusOnNextField={this.props.focusOnNextField}
              textInputRefs={this.props.textInputRefs}
              key={element.key}
              parent={element.parent}
              jsonKey={element.jsonKey}
              visaForm={element.visaForm}
              fieldName={element.fieldName}
              validate={element.validate}
              updateChildValues={element.updateChildValues}
              labelIndex={element.labelIndex}
              textArea={false}
              setField={this.props.setField}
              showDropDown={this.props.showDropDown}
              deleteWRTIndex={this.deleteWRTIndex}
              indexInParent={index}
            />);
            break;
          case FORM_DATA_TYPE_NEW.TEXTAREA:
            inputViews.push(<FormTextInput
              focusOnNextField={this.props.focusOnNextField}
              textInputRefs={this.props.textInputRefs}
              key={element.key}
              parent={element.parent}
              jsonKey={element.jsonKey}
              visaForm={element.visaForm}
              fieldName={element.fieldName}
              validate={element.validate}
              updateChildValues={element.updateChildValues}
              labelIndex={element.labelIndex}
              setField={this.props.setField}
              showDropDown={this.props.showDropDown}
              deleteWRTIndex={this.deleteWRTIndex}
              indexInParent={index}
              textArea
            />);
            break;
          case FORM_DATA_TYPE_NEW.SELECT:
            inputViews.push(<FormSingleSelectInput
              focusOnNextField={this.props.focusOnNextField}
              textInputRefs={this.props.textInputRefs}
              key={element.key}
              parent={element.parent}
              jsonKey={element.jsonKey}
              visaForm={element.visaForm}
              fieldName={element.fieldName}
              validate={element.validate}
              updateChildValues={element.updateChildValues}
              labelIndex={element.labelIndex}
              setField={this.props.setField}
              showDropDown={this.props.showDropDown}
              deleteWRTIndex={this.deleteWRTIndex}
              indexInParent={index}
            />);
            break;
          case FORM_DATA_TYPE_NEW.MULTI:
            inputViews.push(<FormMultiSelectInput
              focusOnNextField={this.props.focusOnNextField}
              textInputRefs={this.props.textInputRefs}
              key={element.key}
              parent={element.parent}
              jsonKey={element.jsonKey}
              visaForm={element.visaForm}
              fieldName={element.fieldName}
              validate={element.validate}
              updateChildValues={element.updateChildValues}
              labelIndex={element.labelIndex}
              setField={this.props.setField}
              showDropDown={this.props.showDropDown}
              deleteWRTIndex={this.deleteWRTIndex}
              indexInParent={index}
            />);
            break;
          case FORM_DATA_TYPE_NEW.RADIO:
            inputViews.push(<FormRadioInput
              focusOnNextField={this.props.focusOnNextField}
              textInputRefs={this.props.textInputRefs}
              key={element.key}
              parent={element.parent}
              jsonKey={element.jsonKey}
              visaForm={element.visaForm}
              fieldName={element.fieldName}
              validate={element.validate}
              updateChildValues={element.updateChildValues}
              labelIndex={element.labelIndex}
              setField={this.props.setField}
              showDropDown={this.props.showDropDown}
              deleteWRTIndex={this.deleteWRTIndex}
              indexInParent={index}
            />);
            break;
          case FORM_DATA_TYPE_NEW.GROUP:
            inputViews.push(<FormGroupInput
              focusOnNextField={this.props.focusOnNextField}
              textInputRefs={this.props.textInputRefs}
              key={element.key}
              parent={element.parent}
              jsonKey={element.jsonKey}
              visaForm={element.visaForm}
              fieldName={element.fieldName}
              validate={element.validate}
              updateChildValues={element.updateChildValues}
              labelIndex={element.labelIndex}
              setField={this.props.setField}
              showDropDown={this.props.showDropDown}
              deleteWRTIndex={this.deleteWRTIndex}
              indexInParent={index}
            />);
            break;
          case FORM_DATA_TYPE_NEW.DROPDOWN:
            inputViews.push(<FormSingleSelectDropdownInput
              focusOnNextField={this.props.focusOnNextField}
              textInputRefs={this.props.textInputRefs}
              key={element.key}
              parent={element.parent}
              jsonKey={element.jsonKey}
              visaForm={element.visaForm}
              fieldName={element.fieldName}
              validate={element.validate}
              updateChildValues={element.updateChildValues}
              labelIndex={element.labelIndex}
              setField={this.props.setField}
              showDropDown={this.props.showDropDown}
              deleteWRTIndex={this.deleteWRTIndex}
              indexInParent={index}
            />);
            break;
          default: break;
        }
        if (index !== this.state.inputs.length - 1 && this.isFirstAddMore()) {
          inputViews.push(<View
            style={styles.cardVisaForm}
            key={`${element.key}horizontal`}
          />);
        }
      }
    });

    return inputViews;
  }

  isFirstAddMore = () => {
    const jsonKey = `${this.props.jsonKey}.${this.props.fieldName}`;
    const keys = jsonKey.split('.');
    let count = 0;
    keys.forEach((key, index) => {
      if (index >= 2 && !key.includes(DEPENDENTS) && this.state.visaForm.fields[key.split('[')[0]].addMore) {
        count += 1;
      }
    });
    return count === 1;
  }

  getContent = () => {
    if (this.state.visaForm.fields[this.props.fieldName] && this.state.visaForm.fields[this.props.fieldName].addMore && this.isFirstAddMore()) {
      return (
        <View style={styles.cardBgForm}>
          {this.renderContent()}
          <View style={[styles.flex1, styles.flexRow, styles.spaceBetween]}>
            <TouchableOpacity onPress={() => this.add()}>
              <Text style={[styles.regularFont, styles.blueColor, styles.font14]}> + ADD </Text>
            </TouchableOpacity>
          </View>
        </View>
      );
    } else if (this.state.visaForm.fields[this.props.fieldName] && this.state.visaForm.fields[this.props.fieldName].addMore) {
      return (
        <View>
          {this.renderContent()}
          <View style={[styles.flex1, styles.flexRow, styles.spaceBetween]}>
            <TouchableOpacity onPress={() => this.add()}>
              <Text style={[styles.regularFont, styles.blueColor, styles.font14]}> + ADD </Text>
            </TouchableOpacity>
          </View>
        </View>
      );
    }
    return (this.renderContent());
  }


  render() {
    const field = this.state.visaForm.fields[this.props.fieldName];
    return (
      <View style={[styles.mar15Top]}>
        <Text style={[styles.BlackText, styles.font14, styles.boldFont]}>
          {this.props.labelIndex}. {field.displayName}
          {field.mandatory && <Text style={styles.redText}>*</Text>}
        </Text>
        <HTMLView style={styles.mar12Bt} stylesheet={field.noteStyle} value={field.note} />
        {this.getContent()}
      </View>
    );
  }
}

InputFieldMaster.propTypes = {
  labelIndex: PropTypes.string.isRequired,
  parent: PropTypes.string.isRequired,
  jsonKey: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  visaForm: PropTypes.object.isRequired,
  updateChildValues: PropTypes.func.isRequired,
  validate: PropTypes.bool.isRequired,
  setField: PropTypes.func.isRequired,
  showDropDown: PropTypes.func.isRequired,
  textInputRefs: PropTypes.object.isRequired,
  focusOnNextField: PropTypes.func.isRequired
};
