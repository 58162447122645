import React, {useCallback} from 'react';
import {Image, Text, View} from 'react-native';
import styles from '../../acme/details/components/InclusionExclusionCss';
import AtomicCss from '../../acme/details/AtomicCss';
import {getActionButton, handleClickEvent, isNotNullAndEmpty} from '../utils/CabBookingDetailUtil';
import {mainCardIcon, inclusionIcon} from "../CabImageConstants";
import _ from 'lodash';
import CardWrapper from '../modules/bookingDetails/components/CardWrapper';

const AmenitiesCard  = ({card, response, pageName}) => {

    const handleClick = (action) => {
        handleClickEvent(card, action, response,pageName);
    };

    const getAmenities = (heading,amenitiesList) => {
        if (isNotNullAndEmpty(amenitiesList)) {
            return(
                <View>
                    <Text style={[AtomicCss.blackFont, AtomicCss.blackText, AtomicCss.font12, AtomicCss.marginBottom8]}>{heading}</Text>
                    {
                        amenitiesList.map((amenities) => (
                             <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom18]}>
                                <Image style={styles.activityTick} source={inclusionIcon} />
                                <Text style={styles.tickText}>{amenities}</Text>
                            </View>
                        ))
                    }
                </View>
            )

        }
    }

    return(
    <View style={[AtomicCss.whiteBg, AtomicCss.marginBottom10]}>
        <View style={[styles.CardContentFull, AtomicCss.paddingBottom15]}>
            <View style={[styles.CardContent]}>
                <Image style={styles.CardIcon} source={mainCardIcon} />
                <View style={AtomicCss.flex1}>
                    <Text style={[AtomicCss.blackFont, AtomicCss.blackText, AtomicCss.font14, AtomicCss.marginBottom24]}>
                        {card.heading}
                    </Text>
                    {
                        !_.isEmpty(card.subheading) &&
                        <Text style={[AtomicCss.regularFont, AtomicCss.blackText, AtomicCss.font14, AtomicCss.marginBottom24]}>
                            {card.subheading}
                        </Text>
                    }
                    {
                        response.primeSKUAmenities && isNotNullAndEmpty(response.primeSKUAmenities.vehicleAmenitiesSubheading) && response.primeSKUAmenities.vehicle &&
                        getAmenities(response.primeSKUAmenities.vehicleAmenitiesSubheading,response.primeSKUAmenities.vehicle)
                    }
                    {
                        response.primeSKUAmenities && isNotNullAndEmpty(response.primeSKUAmenities.driverAmenitiesSubheading) && response.primeSKUAmenities.driver &&
                        getAmenities(response.primeSKUAmenities.driverAmenitiesSubheading,response.primeSKUAmenities.driver)
                    }
                    {
                        response.primeSKUAmenities && isNotNullAndEmpty(response.primeSKUAmenities.serviceAmenitiesSubheading) && response.primeSKUAmenities.services &&
                        getAmenities(response.primeSKUAmenities.serviceAmenitiesSubheading,response.primeSKUAmenities.services)
                    }
                </View>
            </View>
        </View>
        <View style={isNotNullAndEmpty(card.actionList) ? styles.whiteBtnHalfOuter : styles.hideView}
        >
            {getActionButton(card, response, handleClick)}
        </View>
    </View>
    )
}

export default CardWrapper(AmenitiesCard);



