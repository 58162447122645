import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  selectMealInfo: {
    backgroundColor: '#FFEDD1',
    padding: 16,
    borderRadius: 4,
    marginTop: 10,
  },
  infoTitle: {
    color: '#CF8100',
    marginBottom: 8,
  },
  infoDesc: {
    color: '#4a4a4a',
  },
  triangle: {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderTopWidth: 0,
    borderRightWidth: 9,
    borderBottomWidth: 13,
    borderLeftWidth: 9,
    borderTopColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: '#FFEDD1',
    borderLeftColor: 'transparent',
    position: 'absolute',
    right: 50,
    top: 0,
  },
});

export default styles;
