import React from 'react';
import {View, Text, Image} from 'react-native';
import styles from '../../CSS/VisaMamiCss';
import PropTypes from 'prop-types';
import {
    isNullOrEmpty,
    getActionButton,
    getButtonStyle,
    isNotNullAndEmpty
} from '../../../hotel/details/utils/HotelBookingDetailUtil';
import {fillDateAndTime} from '@mmt/legacy-commons/Common/utils/DateUtils';
import {handleClickEvent} from '../../Util/VisaUtil';
import {arrowLightCreamIcon, arrowCreamIcon, trackerCheckIcon, invalidIcon} from '../../Constant/VisaImageConstant';
import isEmpty  from 'lodash/isEmpty';


const ERROR = 'Error';
const COMPLETED = 'Completed';
const ONGOING = 'Ongoing';
let cardHeight = 0;

class VisaTrackerCard extends React.Component {
    static navigationOptions = {
      header: null
    };
    constructor(props) {
      super(props);
    }

    _onCardLayout = (event) => {
      this.props.onCardLayout(event);
      const {height} = event.nativeEvent.layout;
      cardHeight = height;
      this.props.updateVisaTracker(cardHeight);
    }

    render() {
      return (
        <View style={[styles.MamiCard, styles.mar10Bt]}>
          <View style={styles.CardContentTracker}>
            {!isEmpty(this.props.response.visaStatusTrackerHeader) && <Text style={[styles.blackFont, styles.font14, styles.BlackText, styles.mar5Bt, styles.padding15Left]} >
                     {this.props.response.visaStatusTrackerHeader}
            </Text>}
            <View style={styles.trackerContainer} onLayout={this._onCardLayout}>
                {this.showTrackerList(this.props.visaSteps)}
            </View>
          </View>
          <View style={isNotNullAndEmpty(this.props.card.actionList) ? [getButtonStyle(this.props.card.actionList), styles.transperantBg] : styles.hideView}>
            {getActionButton(this.props.card, this.props.response, this.handleClick)}
                 </View>
        </View>
      );
    }

    handleClick = (action) => {
      handleClickEvent(this.props.card, action, this.props.response, this.props.pageName,this.props.refBookingId);
    };

    showTrackerList(visaSteps) {
      if (isNullOrEmpty(this.props.visaSteps)) { return null; }
      const Views = [];
      let index = 0;
      const length = visaSteps.length;
      visaSteps.forEach((visaStep) => {
        index++;
        this.createVisatrack(Views, visaStep, index, length);
      });
      return Views;
    }

    createVisatrack(views, visaStep, index, length) {
      views.push(<View style={styles.trackerSubContainer}>
        {this.getTrackingBall(visaStep.status, index)}
        {this.getTrackerTest(visaStep.status, visaStep, index, length)}
      </View>);
    }
    getTrackerTest(status, visaStep, index, length) {
      const trackerTestView = [];
      if (status == COMPLETED) {
        trackerTestView.push(<View style={(length === index) ? styles.visaTrackerTextLast : styles.visaTrackerText}>
          <View>
            {this.getHeading1(status, visaStep.visaHeading)}
            {this.getHeading2(status, visaStep.visaHeading2)}
            {this.getHeading3(status, visaStep.visaHeading3)}
            {this.getmsgList(visaStep.msgList)}
          </View>
        </View>);
      } else if (status == ONGOING) {
        trackerTestView.push(<View style={[(length === index) ? styles.visaTrackerTextLast : styles.visaTrackerText, styles.visaTrackerTextlightCream]}>
          <View style={[styles.flexRow, styles.marginLeft20Negative]}>
            <Image style={styles.tipIcon} source={arrowLightCreamIcon} />
            <View style={styles.lightCreamActiveBg}>
              {this.getHeading1(status, visaStep.visaHeading)}
              {this.getHeading2(status, visaStep.visaHeading2)}
              {this.getHeading3(status, visaStep.visaHeading3)}
              {this.getmsgList(visaStep.msgList)}
            </View>
          </View>
        </View>);
      } else {
        trackerTestView.push(<View style={[(length === index) ? styles.visaTrackerTextLast : styles.visaTrackerText, , styles.visaTrackerTextError]}>
          <View >
            {this.getHeading1(status, visaStep.visaHeading)}
            {this.getHeading2(status, visaStep.visaHeading2)}
            {this.getHeading3(status, visaStep.visaHeading3)}
            {this.getmsgList(visaStep.msgList)}
          </View>
                             </View>);
      }
      return trackerTestView;
    }

    getmsgList(msgList) {
      if (isNullOrEmpty(msgList)) { return null; }
      const msgListView = [];
      msgList.forEach((msg) => { this.getMsg(msg, msgListView); });
      return msgListView;
    }

    getMsg(msg, msgView) {
      if (msg.type === ERROR) {
        msgView.push(<View style={[styles.flexRow, styles.mar5Bt, styles.marginLeft20Negative]}>
          <Image style={styles.tipIcon} source={arrowCreamIcon} />
          <View style={styles.trackingInfoActiveCream}>
            {this.getMsgHeading1(msg.msgHeading1, msg.type)}
            {this.getMsgHeading2(msg.msgHeading2)}
            {this.getMsgHeading3(msg.msgHeading3)}
          </View>
        </View>);
      } else {
        msgView.push(<View style={styles.marginLeft15Negative}>
          <View style={styles.trackingInfoActiveGreen}>
            {this.getMsgHeading1(msg.msgHeading1, msg.type)}
            {this.getMsgHeading2(msg.msgHeading2)}
            {this.getMsgHeading3(msg.msgHeading3)}
          </View>
                     </View>);
      }
    }

    getMsgHeading1(msgHeading1, type) {
      if (isNullOrEmpty(msgHeading1)) { return null; }
      const msgHeading1View = [];
      if (type === ERROR) {
        msgHeading1View.push(<Text style={[styles.font12, styles.darkGrey, styles.boldFont, styles.mar3Bt, styles.activeTextError]}>
          {msgHeading1}
                             </Text>);
      } else {
        msgHeading1View.push(<Text style={[styles.font12, styles.darkGrey, styles.boldFont, styles.mar3Bt, styles.activeText]}>
          {msgHeading1}
                             </Text>);
      }

      return msgHeading1View;
    }

    getMsgHeading2(msgHeading2) {
      if (isNullOrEmpty(msgHeading2)) { return null; }
      const msgHeading2View = [];
      msgHeading2View.push(<Text style={[styles.font12, styles.darkGrey, styles.regularFont, styles.mar3Bt, styles.activeTextStatus]}>
        {msgHeading2}
      </Text>);
      return msgHeading2View;
    }

    getMsgHeading3(msgHeading3) {
      if (isNullOrEmpty(msgHeading3)) { return null; }
      const msgHeading3View = [];
      msgHeading3View.push(<Text style={[styles.font12, styles.defaultGrey, styles.regularFont, styles.mar3Bt, styles.italic]}>
        {msgHeading3}
      </Text>);
      return msgHeading3View;
    }

    getHeading1(status, visaHeading) {
      if (isNullOrEmpty(visaHeading)) { return null; }
      const heading1View = [];
      if (status === COMPLETED) {
        heading1View.push(<Text style={[styles.font12, styles.darkGrey, styles.boldFont, styles.mar3Bt]}>
          {visaHeading}
                          </Text>);
      } else if (status === ONGOING) {
        heading1View.push(<Text style={[styles.font12, styles.darkGrey, styles.boldFont, styles.mar3Bt, styles.activeText]}>
          {visaHeading}
                          </Text>);
      } else if (status === ERROR) {
        heading1View.push(<Text style={[styles.font12, styles.darkGrey, styles.boldFont, styles.mar3Bt, styles.activeTextError]}>
          {visaHeading}
        </Text>);
      } else {
        heading1View.push(<Text style={[styles.font12, styles.darkGrey, styles.boldFont, styles.mar3Bt, styles.activeText]}>
          {visaHeading}
                          </Text>);
      }
      return heading1View;
    }

    getHeading2(status, visaHeading2) {
      if (isNullOrEmpty(visaHeading2)) { return null; }
      const heading2View = [];
      if (status === COMPLETED) {
        heading2View.push(<Text style={[styles.font12, styles.darkGrey, styles.regularFont, styles.mar3Bt]}>
          {visaHeading2}
        </Text>);
      } else {
        heading2View.push(<Text style={[styles.font12, styles.darkGrey, styles.regularFont, styles.mar3Bt, styles.activeTextStatus]}>
          {visaHeading2}
                          </Text>);
      }
      return heading2View;
    }

    getHeading3(status, visaHeading3) {
      if (isNullOrEmpty(visaHeading3)) { return null; }
      const heading3View = [];
      if (visaHeading3 === '') {
        return null;
      }
      if (status === COMPLETED || status === ONGOING || status === ERROR) {
        heading3View.push(<Text style={[styles.font12, styles.defaultGrey, styles.regularFont, styles.mar3Bt, styles.italic]}>
          {visaHeading3}
                          </Text>);
      } else {
        heading3View.push(<Text style={[styles.font11, styles.darkGrey, styles.regularFont, styles.mar3Bt, styles.lineHeight16]}>
          {visaHeading3}
        </Text>);
      }
      return heading3View;
    }

    getTrackingBall(status, index) {
      const trackingBallView = [];
      if (status === COMPLETED) {
        trackingBallView.push(<View style={styles.trackingBall}>
          <Image style={styles.trackerCheck} source={trackerCheckIcon} />
                              </View>);
      } else if (status === ONGOING) {
        trackingBallView.push(<View style={[styles.trackingBall, styles.trackingBallLightCream]}>
          <Text style={{color: '#3b385e'}}>{index}</Text>
                              </View>);
      } else if (status === ERROR) {
        trackingBallView.push(<View style={[styles.trackingBall, styles.errorFill]}>
          <Image style={{width: 5, height: 18}} source={invalidIcon} />
        </View>);
      } else {
        trackingBallView.push(<View style={[styles.trackingBall, styles.trackingBallGrey]}>
          <Text style={{color: '#3b385e'}}>{index}</Text>
                              </View>);
      }
      return trackingBallView;
    }
}
VisaTrackerCard.propTypes = {

  visaSteps: PropTypes.array.isRequired,
  visaExpectedDate: PropTypes.string.isRequired,
  card: PropTypes.object.isRequired,
  response: PropTypes.object.isRequired,
  pageName: PropTypes.string.isRequired,
  onCardLayout: PropTypes.func,
  updateVisaTracker: PropTypes.func.isRequired
};

VisaTrackerCard.defaultProps = {
  onCardLayout: () => {
  }
};


export default VisaTrackerCard;
