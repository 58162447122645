import { HOLIDAY_THANK_YOU_VISA_URL } from 'apps/post-sales/src/utils/NetworkUtils';
import {HOLIDAY_ADD_VISA_THANK_YOU_EVENT} from '../../../../../HolidayBookingConstants';

const getBookingRequestData = (countriesData) => {
  return countriesData.reduce((acc, item) => {
    if (item.passengers.length > 0) {
      acc.push({
        visaType: item.visaTypeId,
        countryCode: item.countryCode,
        passengers: item.passengers.map((item2) => ({
          paxId: item2.paxId,
          dateOfBirth: item2.dateOfBirth,
        })),
      });
    }
    return acc;
  }, []);
};
export const getHoldAPIRequest = (bookingId, countriesData, searchDataKey) => {
  return {
    bookingId: bookingId,
    thankYouPageUrl: `${HOLIDAY_THANK_YOU_VISA_URL}?bookingId=${bookingId}`,
    searchDataKey,
    bookingRequests: getBookingRequestData(countriesData),
  };
};

const getBookingInfo = (holdResponse, bookingId) => {
  const { paymentDetails } = holdResponse || {};
  const { checkoutId } = paymentDetails || {};
  return {
    bookingId: bookingId,
    product: 'post_sales',
    checkoutId: '' + checkoutId,
  };
};

const getUserInfo = (holdResponse) => {
  const { paymentDetails } = holdResponse || {};
  const { mobile, email } = paymentDetails || {};
  return {
    mobile: mobile,
    travellerEmail: email,
  };
};

export const getPaymentRequestIOS = (holdResponse, bookingId) => ({
  bookingInfo: getBookingInfo(holdResponse, bookingId),
  userVO: getUserInfo(holdResponse),
  eventName: HOLIDAY_ADD_VISA_THANK_YOU_EVENT,
});

export const getPaymentDetails = (holdResponse, bookingId) => {
  const { paymentDetails } = holdResponse || {};
  const { checkoutId, mobile, email } = paymentDetails || {};
  return {
    checkoutId: '' + checkoutId,
    travellerEmail: email,
    channel: 'post_sales',
    mobile,
    bookingId,
  };
};

export const getPaymentRequestAndroid = (holdResponse, bookingId) => ({
  paymentDetails: getPaymentDetails(holdResponse, bookingId),
  eventName: HOLIDAY_ADD_VISA_THANK_YOU_EVENT,
});
