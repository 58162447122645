import React from 'react';
import { View, Text, Image } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import createStyles from '../styles';
import { getFont } from '@mmt/post-sales/src/PostSalesStyles';
import { useTheme } from '@mmt/post-sales/src/theme/theme.context';
import { DriverProfileTripsData } from '@mmt/post-sales/src/cab/types';

const LeftSectionCommon = ({
  data,
  isLastRow,
}: {
  data: DriverProfileTripsData | DriverProfileTripsData;
  isLastRow: boolean;
}) => {
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);

  const { subText, mainText, iconURL } = data || {};

  return (
    <View style={[styles.driverProfileLeftSection, !isLastRow && styles.borderBottom]}>
      <Image source={{ uri: iconURL }} style={styles.iconImageStyle} />
      <View style={AtomicCss.flex1}>
        <Text style={styles.leftSectionMainText}>{mainText}</Text>
        <Text style={styles.leftSectionSubText}>{subText}</Text>
      </View>
    </View>
  );
};

export default LeftSectionCommon;
