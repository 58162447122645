import { StyleSheet, Platform } from 'react-native';

export default (theme) => StyleSheet.create({
  overlayContentStyle: {
    backgroundColor: theme.color.white,
    padding: 20,
    paddingTop: 15,
    position: 'relative',
    zIndex: 1,
    width: '100%',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    marginBottom: Platform.select({
      ios: -60
    })
  },
  overlayWrapperStyle: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    elevation: 10,
    zIndex: 100,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    flexDirection: 'row',
  },
  overlayContent: {
    backgroundColor: theme.color.white,
    padding: 20,
    paddingBottom: Platform.OS === 'ios' ? 0 : 20,
    position: 'relative',
    zIndex: 1,
    width: '100%',
    marginBottom: Platform.select({
      ios: -60
    })
  },
  nameChangeCustomStyle: {
    marginBottom: Platform.select({
      ios: -60
    })
  }
});
