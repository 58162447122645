import React, { Component } from 'react';
import { Text, View, TouchableOpacity } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import styles from './RefundPaymentModeCss';

const RadioList = ({ item, index, handleRadio, activeRadio,lastItem, refundAmount }) => {

    const addSubText=(subTitleList)=>{
            const views = [];
            subTitleList.forEach((subtitle, index) => {
            views.push(<Text style={[styles.font13, styles.refundText, index%2==0?AtomicCss.boldFont:'']}>{subtitle}</Text>);
        });
        return views;
    };
    const activeRadioList = item.refundModeReason === activeRadio;
    const lastItemStyle = lastItem ? "lastStyle":''
    return (

        <TouchableOpacity style={[styles.radioRow,styles[lastItemStyle]]} onPress={() => handleRadio(index,item.refundModeReason)} activeOpacity={.7}>
            <View style={AtomicCss.flexRow}>
                <View style={styles.radio}>
                    {activeRadioList &&
                        <View style={styles.radioInside}></View>
                    }
                </View>
                <View style={styles.refundCardDetails}>
                    <View style={styles.refundTopDtls}>
                        <Text style={styles.refundLeftDtls}>{item.title}</Text>
                        <Text style={styles.refundPriceTxt}> {refundAmount}{' '}</Text>
                    </View>
                    <View style={styles.refundTextWrapper}>
                    {addSubText(item.subText)}

                    </View>
                </View>
            </View>

        </TouchableOpacity>

    );
}

export default RadioList