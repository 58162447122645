import React from 'react';
import { View, Image, Text, ScrollView, TouchableOpacity } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import createStyle from './styles';
import Button from '../../../../../../../Common/Button';
import isEmpty from 'lodash/isEmpty';
import { CHEVRON_RIGHT_BLUE_ICON, RED_CROSS_BG_ICON } from '../../../../../../FlightImageConstants';
import { getStaticData } from '../../../../../../../staticData/staticData';
import { resetToFlightBookingDetail } from '../../../../../../FlightBookingUtil';
import { openDialer } from '../../../../../../../Common/commonUtil';
import { getFont } from '../../../../../../../PostSalesStyles';
import { THEME } from '../../../../../../../PostSalesConstant';
import HTMLView from 'react-native-htmlview';
import { useTheme } from '../../../../../../../theme/theme.context';
const LastMinCancellationFailurePage = ({
  errorPageData,
  bookingId,
  segmentWiseInfo,
  isCorporateBooking,
}) => {
  const { airlineContactText, goBackToBookingText } = getStaticData();
  const { fsStyle, ...fonts } = getFont(true);
  const { errorDesc = '', description = '', heading = '', contactList = [] } = errorPageData || {};
  const { psTheme: theme } = useTheme();
  const styles = createStyle(theme);

  const _openUpdatedBooking = () => {
    resetToFlightBookingDetail(bookingId);
  };
  const openPhoneDialer = (phoneNo) => {
    openDialer(phoneNo.replace(',', ''));
  };
  const errorDescription = !isEmpty(errorDesc) ? errorDesc : description;
  return (
    <>
      <ScrollView>
        <View style={styles.fullPage}>
          <View style={styles.topSection}>
            <Image style={styles.mainconStyle} source={RED_CROSS_BG_ICON} />
            {!!heading && (
              <Text style={[styles.titleHeading, fonts.blackFontFamily, fsStyle.font20]}>
                {heading}
              </Text>
            )}
          </View>
          <View style={styles.detailSection}>
            <View style={[styles.detailCard]}>
              <View style={styles.detailsTop}>
                <View style={AtomicCss.marginBottom20}>
                  {!!errorDescription && <HTMLView value={errorDescription} />}
                </View>
                {!isEmpty(contactList) && (
                  <Text
                    style={[
                      fsStyle.font14,
                      AtomicCss.regularFont,
                      AtomicCss.blackText,
                      AtomicCss.marginBottom8,
                    ]}
                  >
                    {airlineContactText}
                  </Text>
                )}
                {contactList.map((item) =>
                  item.contactNoList.map((contact) => (
                    <View style={AtomicCss.marginTop10}>
                      <TouchableOpacity
                        style={styles.contactCard}
                        activeOpacity={0.8}
                        onPress={() => openPhoneDialer(contact.contactNo.join(', '))}
                      >
                        <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
                          <Image
                            source={{ uri: contact.mobileAirlineIconUrl }}
                            style={styles.flightIcon}
                          />
                          <Text style={[fsStyle.font14, AtomicCss.blackText, AtomicCss.boldFont]}>
                            {contact.airlineName} -{' '}
                            <Text style={AtomicCss.azure}>{contact.contactNo.join(', ')}</Text>
                          </Text>
                        </View>
                        <Image source={CHEVRON_RIGHT_BLUE_ICON} style={styles.arrowStyle} />
                      </TouchableOpacity>
                    </View>
                  )),
                )}
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
      <View style={styles.buttonBottomWrapper}>
        <Button
          btnWidth={'98%'}
          btnBg={'gradientBtn'}
          btnType="flat"
          btnTxt={goBackToBookingText}
          buttonTextCss={fsStyle.font16}
          theme={isCorporateBooking && THEME.MYBIZ}
          clickHandler={_openUpdatedBooking}
        />
      </View>
    </>
  );
};

export default LastMinCancellationFailurePage;
