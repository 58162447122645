import React from 'react';
import { View,Image, Text, TouchableOpacity } from 'react-native';
import styles from './BottomClickableCardCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { BLUE_RIGHT_ARROW_ICON } from '../../../HotelImageConstants';
import { getImageTransformStyling } from '../../../../utils/PostSaleUtil';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const BottomClickableCard = ({onPress, title, subTitle, icon, iconStyle}) => { 
	const { fsStyle, ...fonts } = getFont(true);
	return(
		<TouchableOpacity style={styles.facingAnyIssueCard} onPress={() => onPress()}>
			<View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.flex1]}>
				<Image style={iconStyle} source={icon} />
				<View style={AtomicCss.flex1}>
					<Text style={[fonts.blackFontFamily, fsStyle.font16, AtomicCss.blackText, AtomicCss.marginBottom5, AtomicCss.lineHeight18, AtomicCss.alignLeft]}>
						{title}
					</Text>       
					<Text style={[fonts.regularFontFamily, fsStyle.font14, AtomicCss.midGreyText, AtomicCss.lineHeight20, AtomicCss.flexWrap, AtomicCss.alignLeft]}>
						{subTitle}
					</Text>       
				</View>
			</View>
			<View>
				<Image style={[styles.rightBlueArrow, getImageTransformStyling()]} source={BLUE_RIGHT_ARROW_ICON} />
			</View>            
		</TouchableOpacity>
	)
}

export default BottomClickableCard;