import React, { useEffect } from 'react';
import { View, Text, StyleSheet, Dimensions, TouchableOpacity, Platform } from 'react-native';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import { getStaticData } from '../../../staticData/staticData';
import SuccessLoaderAnim from '../../../Common/SuccessTickAnim';
import Button from '../../../Common/Button';
import { isIntlHotelBooking } from '../../details/utils/HotelBookingDetailUtil';
import HotelBookingTrackingHelper from '../../HotelBookingTrackingHelper';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const windowHeight = (Platform.OS === 'android' ||  Platform.OS === 'web') ? Dimensions.get('window').height : Dimensions.get('screen').height;
const staticData = getStaticData();

const CancelledSuccess = ({ hotelName, handleClick, theme = '', hotelBookingResponse, successResponse }) => {
    const { fsStyle, ...fonts } = getFont(true);
    const styles = getStyles(fonts);
    const { cancellationText: {additionalText}, hotelSuccessfulSubmissionPageText } = staticData;

    useEffect(() => {
        const trackingPageName = isIntlHotelBooking(hotelBookingResponse?.lobCode)
         ? 'Mob:customer support:Hotel International:FullCancel:NonPromiseTY'
         : 'Mob:customer support:Hotel Domestic:FullCancel:NonPromiseTY';
        HotelBookingTrackingHelper.trackLoadEvent(
            trackingPageName,
            hotelBookingResponse
        );
    }, [])

    return (
        <View style={styles.OverlayContentWrapper}>
            <View style={styles.topCircle}>
                <View style={styles.loaderWrapper}>
                    <SuccessLoaderAnim /> 
                </View>
            </View>
            <View>
                <View style={styles.thanksMsg}>
                    <Text style={styles.titleHeading}>{additionalText.bookingCancelledText}</Text>
                    <Text style={styles.titleSubHeading}>{hotelName}</Text>
                    {successResponse && !!successResponse.message && <Text style={styles.description}>{successResponse.message}</Text>}
                </View>
                <View style={[AtomicCss.marginHz20, AtomicCss.marginBottom40]}>
                    <Button
                        btnBg="gradientBtn" 
                        btnType="flat"
                        fontSize={fsStyle.font16}
                        btnTxt={hotelSuccessfulSubmissionPageText.additionalText.viewUpdatedBookingText}
                        clickHandler={handleClick}
                        theme={theme}
                    />    
                </View>
            </View>
        </View>
    )
};

const getStyles = (fonts) => {
    return StyleSheet.create({
        OverlayContentWrapper: {
           height: windowHeight,
        },
        topCircle: {
            width: 300,
            height: 330,
            borderRadius: 280,
            backgroundColor: colors.cyan2,
            alignSelf: 'center',
            position: 'relative',
            marginTop: -100,
            transform: [{ scaleX: 2 }],
            marginBottom: 40
        },
        loaderWrapper: {
            marginTop: 240,
            alignItems: 'center',
            transform: [{ scaleX: .5 }],
        },
        titleHeading: {
            ...fonts.font20,
            fontFamily: fonts.black,
            color: colors.darkGreen,
            marginTop: 25,
            marginBottom: 4,
            lineHeight: 28
        },
        titleSubHeading: {
            ...fonts.font14,
            fontFamily: fonts.bold,
            color: colors.defaultTextColor,
            lineHeight: 28
        },
        description: {
            ...fonts.font12,
            fontFamily: fonts.regular,
            color: colors.defaultTextColor,
            lineHeight: 14,
            marginHorizontal: 30,
            marginVertical: 10,
        },
        thanksMsg: {
            alignItems: 'center',
            minHeight: windowHeight - 340
        }, 
        thanksText: {
            lineHeight: 26,
            ...fonts.font14,
            fontFamily: fonts.regular,
            color: colors.black,
            textAlign: 'center',
        },
        bottomWrapper: {
            position: 'absolute',
            zIndex: 4,
            bottom: 0,
            width: '100%'
        },
    })
}

export default React.memo(CancelledSuccess);
