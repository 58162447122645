import { StyleSheet } from 'react-native';

export default (theme, fonts) =>StyleSheet.create({
  pageWrapper: {
    flex: 2,
    backgroundColor: theme.color.white,
    justifyContent: 'center',
    alignItems: 'center',
  },
  calendarTickIcon: { width: 46, height: 41, marginBottom: 50 },
  footerBtnWrapper: {
    paddingHorizontal: 50,
    paddingTop: 15,
    width: '100%',
  },
  messageContent: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 50,
  },
  messageIcon: {
    width: 65,
    height: 40,
    marginBottom: 40,
  },
  messageTitle: {
    fontSize: fonts.fontSize.font20,
    ...fonts.boldFontFamily,
    color: theme.color.black28,
    marginBottom: 10,
    textAlign: 'center',
  },
  messageDesc: {
    fontSize: fonts.fontSize.font14,
    ...fonts.regularFontFamily,
    color: theme.color.gray,
    textAlign: 'center',
    lineHeight: 20,
  },
  marginBottom80: { marginBottom: 80 },
});

