import React from 'react';
import { TouchableOpacity, View, Text, Image, GestureResponderEvent } from 'react-native';
import { CROSS_ICON, FLIGHT_ICON } from '../../../../../flights/FlightImageConstants';
import FlightConst from '../../FlightBookingDetailsConstant';
import FlightBookingTrackingHelper from '../../helper/FlightBookingTrackingHelper';
import Button from '../../../../../Common/Button';
import { getFont } from '../../../../../PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import createStyles from './styles';

interface ReleaseHoldProps {
  releaseHold?: {
    title?: string;
    subTitle?: string;
    bookingIntent?: string;
    travelClass?: string;
    flightTravelDateOnly?: string;
    flightTimeRange?: string;
    flightType?: string;
    pnr?: string;
  };
  togglereleaseholdOverlay: (value?: boolean) => void;
}

const ReleaseHold: React.FC<ReleaseHoldProps> = ({ releaseHold, togglereleaseholdOverlay }) => {
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme);
  const {
    title,
    subTitle,
    bookingIntent,
    travelClass,
    flightTravelDateOnly,
    flightTimeRange,
    flightType,
    pnr
  } = releaseHold || {};

  const onConfirmClick = () => {
    FlightBookingTrackingHelper.trackClickEvent(
      'Details',
      'Flight_fullcancel',
    );
    togglereleaseholdOverlay(true);
  };

  const closeOverlay = () => {
    togglereleaseholdOverlay();
  };

  const generateFlightInfoText = () => {
    let flightInfoText = '';

    if (flightTravelDateOnly) {
      flightInfoText += `${flightTravelDateOnly} `;
    }
    if (flightTimeRange) {
      flightInfoText += `| ${flightTimeRange} `;
    }
    if (flightType) {
      flightInfoText += `| ${flightType} `;
    }
    if (pnr) {
      flightInfoText += `| ${pnr}`;
    }

    return flightInfoText;
  };

  return (
    <View style={styles.container}>
      <View style={styles.bottomSheet}>
        <TouchableOpacity
          onPress={closeOverlay}
          activeOpacity={0.7}
          hitSlop={{ top: 12, bottom: 12, left: 12, right: 12 }}
        >
          <Image source={CROSS_ICON} style={styles.crossImage} />
        </TouchableOpacity>
        {!!title && (
          <Text style={[styles.heading, fonts.blackFontFamily, fsStyle.font20]}>{title}</Text>
        )}
        {!!subTitle && (
          <Text style={[styles.subHeading, fonts.regularFontFamily, fsStyle.font12]}>{subTitle}</Text>
        )}
        <View style={styles.flightContainer}>
          <Image source={FLIGHT_ICON} style={styles.flightImage} />
          <View style={styles.flightContent}>
            <View style={styles.flightCodeWrapper}>
              {!!bookingIntent && (
                <Text style={[styles.flightCodeText, fonts.blackFontFamily, fsStyle.font14]}>{bookingIntent}</Text>
              )}
              {!!travelClass && (
                <Text style={[styles.economy, fonts.regularFontFamily, fsStyle.font12]}>{travelClass}</Text>
              )}
            </View>

            <Text style={[styles.flightInfo, styles.flightInfo, fonts.boldFontFamily, fsStyle.font12]}>
              {generateFlightInfoText()}
            </Text>
          </View>
        </View>
        <Button
          btnTxt={FlightConst.CONFIRM}
          clickHandler={onConfirmClick}
          btnWidth={'98%'}
          btnBg="gradientBtn"
        />
      </View>
    </View>
  );
};

export default ReleaseHold;