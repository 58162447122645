import { Platform, ToastAndroid, NativeModules } from 'react-native';
import Toast from '@mmt/legacy-commons/Common/Components/Toast2';

type Position = 'bottom' | 'top';
type Theme = 'dark' | 'light';

export const showShortToast = (
  message: string,
  position: Position = 'bottom',
  theme: Theme = 'dark',
) => {
  if (Platform.OS === 'ios') {
    NativeModules?.ToastIOS?.show(message, position, theme);
  } else if (Platform.OS === 'android') {
    ToastAndroid.show(message, ToastAndroid.SHORT);
  }
};

export const showLongToast = (
  message: string,
  position: Position = 'bottom',
  theme: Theme = 'dark',
) => {
  if (Platform.OS === 'ios') {
    NativeModules?.ToastIOS?.show(message, position, theme);
  } else if (Platform.OS === 'android') {
    ToastAndroid.show(message, ToastAndroid.LONG);
  } else if (Platform.OS === 'web') {
    Toast.show(message);
  }
};
