/* eslint-disable max-len */
import {trackOmniture} from '@mmt/legacy-commons/Common/utils/OmnitureTrackerUtils';
import {LINE_OF_BUSINESS_SD} from '../PostSalesConstant';
import ZoomCarConstants from './utils/ZoomCarConstants';
import ZoomCarConstant from './utils/ZoomCarConstants';
import {isEmpty} from 'lodash';
import SelfDriveEventsLogger from './SelfDriveEventsLogger';

class SelfDriveBookingTrackingHelper {
    trackLoadEvent = (pageName, response, cardList) => {
      SelfDriveEventsLogger.loadEvent(pageName, response);
      let params = {};
      params = this.getBasicOminitureVariables(pageName, response, cardList);
      trackOmniture(pageName, params);
    };
    trackErrorEvent = (pageName) => {
      SelfDriveEventsLogger.errorEvent(pageName);
      const params = {};
      params.pageName = pageName;
      params.m_v22 = pageName;
      trackOmniture(pageName, params);
    };
    trackClickEvent = (pageName, clickEvent) => {
      SelfDriveEventsLogger.clickEvent(pageName,clickEvent);
      const params = {};
      params.pageName = pageName;
      params.m_v15 = pageName;
      params.m_c54 = clickEvent;
      trackOmniture(pageName, params);
    };
    getBasicOminitureVariables(pageName, response, cards) {
      const params = {};
      try {
        params.pageName = pageName;
        params.m_v15 = pageName;
        const details = this.fetchBookingInfo(response, cards);
        if (!isEmpty(details)) {
          params.m_v3 = `${details.source} - ${details.destination}`;
          params.m_v14 = details.card_name;
          params.m_v13 = details.vendor;
          params.m_v16 = details.bookingId;
          params.m_v9 = details.booking_status;
          params.m_v19 = details.subState;
          params.m_v24 = LINE_OF_BUSINESS_SD;
          // params.m_v35 = details.mobile_no;
          // params.m_v34 = details.email_d;
          params.m_v7 = details.booking_date;
          params.m_v46 = details.price;
          params.m_v21 = details.start_time;
          params.m_c3 = details.start_date;
          params.m_c4 = details.drop_date;
          params.m_v25 = details.pax_count;
          params.m_v40 = details.cab_supplier_Type;
          params.m_v81 = details.cmp_code;
          params.m_v89 = details.seat_Type;
          params.m_v9 = details.trip_type;
          params.m_v6 = details.duration;
        }
        return params;
      } catch {
        return params;
      }
    }

    fetchBookingInfo(response, cards) {
      return {
        bookingId: response.bookingId,
        source: response.tripInfo.pickup.city,
        destination: response.tripInfo.drop.city,
        start_date: response.tripInfo.pickup.displayDate,
        drop_date: response.tripInfo.drop.displayDate,
        start_time: response.tripInfo.pickup.displayTime,
        drop_time: response.tripInfo.drop.displayTime,
        fuel_included: response.vehicleInfo.fuelInclusive,
        delivery_type: '',
        distance_filter: response.tripInfo.includedDistance,
        max_distance_filter: response.tripInfo.includedDistanceText,
        duration: response.tripInfo.duration,
        car_model: response.vehicleInfo.model,
        car_type: '',
        seating_capacity: response.vehicleInfo.seatCapacity,
        fuel_type: response.vehicleInfo.fuelType,
        price: this.fetchTotalPaid(response),
        vendor: response.additionalInfo.vendorName,
        transmission_type: response.vehicleInfo.transmissionType,
        inlcuded_kms: response.tripInfo.includedDistanceText,
        extra_per_km: response.tripInfo.extraChargeRate,
        pickup_date_time: response.tripInfo.pickup.displayDate,
        drop_date_time: response.tripInfo.drop.displayDate,
        home_address_line: '',
        dest_address_line: response.tripInfo.drop.address,
        car_address_line: response.tripInfo.pickup.address,
        booking_status: response.selfDriveBookingState.state,
        subState: response.selfDriveBookingState.subState,
        booking_date: '',
        card_name: this.fetchCardsName(cards),
        mobile_no: response.paxInfo.contactNo,
        email_d: response.paxInfo.emailId,
        pax_count: response.paxInfo.count,
        cab_supplier_Type: this.fetchSupplierType(response),
        cmp_code: ZoomCarConstants.CMP_CODE,
        seat_Type: this.fetchCarType(response),
        trip_type: ZoomCarConstants.TRIP_TYPE,
        lob: ZoomCarConstants.LOB
      };
    }

    fetchCarType(response) {
      return `${response.vehicleInfo.model} | ${response.vehicleInfo.transmissionType} | ${response.vehicleInfo.fuelType}`;
    }
    fetchSupplierType(response) {
      if (response.tripInfo.selfPickup) {
        return ZoomCarConstants.SELF_PICKUP;
      }
      return ZoomCarConstants.HOME_DELIVERY;
    }

    fetchCardsName(cards) {
      let cardName = '';
      Object.keys(cards).map((key) => {
        cardName = `${cardName}|${cards[key].cardName}`;
      });
      return cardName;
    }
    fetchTotalPaid(response) {
      return response.paymentInfo && response.paymentInfo.paymentBreakups.filter(k => k.key === ZoomCarConstant.TOTAL_PRICE_KEY)[0];
    }
    getCardState(state) {
      switch (state) {
        case ZoomCarConstants.TOP_STATUS_CARD_PROFILE_VERIFICATION_PENDING_NO_DRIVING_LICENSE:
          return ZoomCarConstants.TOP_STATUS_CARD_PROFILE_VERIFICATION_PENDING_NO_DRIVING_LICENSE;
        default:
          return '';
      }
      return '';
    }
}


export default new SelfDriveBookingTrackingHelper();
