import {NativeModules, Platform} from 'react-native';
import _isEmpty from 'lodash/isEmpty';
import {getMmtAuth, getUserDetails, isCorporateUser, ProfileType} from '../Native/UserSession/UserSessionModule';
import OmnitureModule from '../Native/OmnitureModule';
import GenericModule from '../Native/GenericModule';
import PaymentModule from '../Native/PaymentModule';
import { getHeaders } from '../Common/utils/NetworkUtils';
import { botmanSensorHeader } from '../Native/NetworkModule';

export const invalidateTrips = () => {
  if (Platform.OS === 'android') {
    NativeModules.GenericModule.invalidateTripDetails();
  }
};

export const getDeviceDetails = async () => {
  let deviceDetails = null;
  try {
    if (Platform.OS === 'ios') {
      const deviceInfo = await OmnitureModule.deviceInfo();
      if (!_isEmpty(deviceInfo)) {
        deviceDetails = {
          os: 'ios',
          osVersion: deviceInfo.dvc_os_ver,
          deviceType: deviceInfo.dvc_type,
          appVersion: deviceInfo.dvc_app_ver,
          deviceId: deviceInfo.dvc_did,
          networkType: deviceInfo.dvc_conn_type,
          deviceName: `${deviceInfo.dvc_manuf} ${deviceInfo.dvc_mdl}`,
        };
      }
    } else if (Platform.OS === 'android') {
      const deviceInfo = await GenericModule.getDeviceInfo();
      if (!_isEmpty(deviceInfo)) {
        const {
          androidVersion, phoneNumber, accountEmailId, ...rest
        } = deviceInfo;
        deviceDetails = {
          ...rest,
          os: 'android',
          osVersion: deviceInfo.androidVersion,
        };
      }
    }
  } catch (e) {
    console.error('Error in getDeviceDetails: ', e);
    deviceDetails = null;
  }
  return deviceDetails;
};

/**
 * @param androidDelimiter optional string parameter with default value ",".
 * AndroidDelimeter is used for Android as Android Native Function returns array of string of 2 elements
 * @returns string containing simSerialNumber
 */
export const getSimSerialNumber = async (androidDelimiter = ',') => {
  try {
    if (Platform.OS === 'ios') {
      const simSerialNumber = await PaymentModule.getSimSerialNumber();
      return simSerialNumber ?? '';
    } else if (Platform.OS === 'android') {
      const { simSerialNumber } = await GenericModule.getDeviceInfo();
      return simSerialNumber && !simSerialNumber?.includes('')
        ? (simSerialNumber ?? [''])?.join(androidDelimiter)
        : '';
    } else {
      return '';
    }
  } catch (e) {
    console.error('Error in getSimSerialNumber: ', e);
    return '';
  }
};

export const getCommonHeaders = async (baseHeaders) => {
  let userDetails = null;
  try {
    userDetails = await getUserDetails();
  } catch (e) {
    userDetails = null;
  }
  let userDetailsHeaderObject = {
    'mmt-my-business-subscription': 'b2c'
  };
  if (!_isEmpty(userDetails)) {
    try {
      userDetailsHeaderObject = {
        'mmt-uuid': userDetails.uuid,
        'uuid': userDetails.uuid,
        'mmt-auth': userDetails.mmtAuth,
        'mmt-my-business-subscription': userDetails.profileType === ProfileType.BUSINESS ? 'b2b' : 'b2c'
      };
    } catch (e) {
    }
  }

  let deviceDetailsHeaderObject = {};
  const deviceDetails = await getDeviceDetails();
  if (!_isEmpty(deviceDetails)) {
    try {
      deviceDetailsHeaderObject = {
        'mmt-os': deviceDetails.os,
        'mmt-os-version': deviceDetails.osVersion,
        'mmt-app-version': deviceDetails.appVersion,
        'mmt-app-version-code': deviceDetails.appVersionCode || -1,
        'mmt-device-id': deviceDetails.pdtDeviceId || deviceDetails.deviceId,
        'mmt-device-name': deviceDetails.deviceName || 'NA',
        'mmt-network-type': deviceDetails.networkType || 'NA',
        'mmt-uuid': userDetails?.uuid ?? null,
      };
    } catch (e) {

    }
  }
  if (Platform.OS === 'web') {
    deviceDetailsHeaderObject = {
      'mmt-os': 'web'
    };
  }

  const sensorHeader = await botmanSensorHeader();
  return {
    ...(baseHeaders || {}),
    ...deviceDetailsHeaderObject,
    ...userDetailsHeaderObject,
    ...sensorHeader,
  };
};


export const getWebcommonHeaders = async () => {
  const headers = {
    'content-type': 'application/json',
    'accept-language': 'en',
    'os':'PWA',
    'ver':'8.8.0' //Passing dummy version to support skywalker API.
  };
  try {
     //For mWeb ensure getMmtAuth method added to UserSessionModule in RNW repo
    const mmtAuth = await getMmtAuth()
    if(mmtAuth){
      headers['mmt-auth'] = mmtAuth;
    }
  } catch (e) {}
  return headers;
};

export const getSkyWalkerHeaders = async () => {
  let commonHeaders = {};
  if (Platform.OS !== 'web') {
    commonHeaders = await getHeaders();
  } else {
    commonHeaders = await getWebcommonHeaders();
  }
  console.log("HEADERS", JSON.stringify(commonHeaders));
  let deviceDetailsHeaderObject = {};
  const deviceDetails = await getDeviceDetails();
  console.log("HEADERS - D", JSON.stringify(deviceDetails));
  if (!_isEmpty(deviceDetails)) {
    try {
      deviceDetailsHeaderObject = {
        deviceid: deviceDetails.pdtDeviceId || deviceDetails.deviceId,
      };
    } catch (e) {}
  }
  return {
    ...commonHeaders,
    ...deviceDetailsHeaderObject,
  };
};

export const getSkyWalkerBody = async () => {
  try {
    //For mWeb ensure isCorporateUser method added to UserSessionModule in RNW repo
    const isUserCorporateSub = await isCorporateUser();
    const body = {
      isCorporateUser: Boolean(isUserCorporateSub),
    };
    return JSON.stringify(body);
  } catch (error) {getske
    return JSON.stringify({});
  }
};
