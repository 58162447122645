import React from 'react';
import PropTypes from 'prop-types';
import {Image, Text, View} from 'react-native';
import getStyles from './HotelMamiCss';
import {
  getActionButton, getButtonStyle, handleClickEvent,
  isNotNullAndEmpty
} from '../utils/HotelBookingDetailUtil';
import { getStaticData } from "../../../staticData/staticData";
import { CROSS_FIND_ICON } from '../../HotelImageConstants';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';


class NeedHelpCard extends React.Component {
  constructor(props) {
    super(props);
    this.fonts = getFont(true);
    this.styles = getStyles(this.fonts);
  }

  render() {
    const {
      needHelpCardText:{
        heading
      }
    } = getStaticData();
    return (
      <View style={[this.styles.MamiCard, this.styles.mar10Bt]}>
        <View style={this.styles.CardContent}>
          <Image style={this.styles.CardIcon} source={CROSS_FIND_ICON}/>
          <View style={this.styles.ContentPortion}>
            <Text style={[this.styles.blackFont, this.styles.BlackText, this.styles.mar5Bt, this.styles.AlignLeft]}>{heading}</Text>
          </View>
        </View>
        <View style={isNotNullAndEmpty(this.props.card.actionList) ? getButtonStyle(this.props.card.actionList) : this.styles.hideView}>
          {getActionButton(this.props.card, this.props.response, this.handleClick)}
        </View>
      </View>
    );
  }

  handleClick = (action) => {
    handleClickEvent(this.props.card, action, this.props.response,this.props.pageName);
  };
}

NeedHelpCard.propTypes = {
  response: PropTypes.object.isRequired,
  card: PropTypes.object.isRequired,
  pageName:PropTypes.string.isRequired
};

export default NeedHelpCard;
