import React from 'react';
import { View, Text, Image, StyleSheet, TouchableOpacity } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { colors, fonts } from '@mmt/legacy-commons/Styles/globalStyles';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import {CallIcon} from '../../HolidayBookingConstants';

const ContactUsRow = ({ data }) => {

    const getCallItemHeading = (type, city) => {
        if (!city) {
            return type;
        }
        return `${type}, ${city}`
    }

    const getOnClickAction = (number) => {
        if (number) {
            GenericModule.openDialer(number)
        }
    }

    const getCallItemSubHeading = (name, contact) => {
        if (!name) {
            return <Text style={AtomicCss.boldFont}>{contact}</Text>
        }
        return <React.Fragment>
            {name}  <Text style={AtomicCss.boldFont}>{contact}</Text>
        </React.Fragment>
    }

    return (
        <TouchableOpacity style={[AtomicCss.whiteBg, AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.spaceBetween, styles.contactRow]}
            onPress={() => getOnClickAction(data.contactNumber)}
        >
            <View>
                <Text style={[styles.title, AtomicCss.regularFont, AtomicCss.font12, AtomicCss.marginBottom5]}>
                    {getCallItemHeading(data.agentType, data.city)}
                </Text>
                <Text style={[AtomicCss.regularFont, AtomicCss.font14, AtomicCss.blackText]}>
                    {getCallItemSubHeading(data.name, data.contactNumber)}
                </Text>
            </View>
            <Image style={styles.callIcon} source={CallIcon} />
        </TouchableOpacity>
    );
}

const styles = StyleSheet.create({
    contactRow: {
        paddingHorizontal: 20,
        marginTop: 20,
        marginBottom: 12
    },
    callIcon: {
        marginTop: 18,
        marginRight: 10,
        width: 18,
        height: 18
    },
    title: {
        color: colors.lightTextColor,
    }
});

export default ContactUsRow