import React from 'react';
import { Image, Modal, Text, TouchableOpacity, View, PixelRatio, StyleSheet } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { colors, fonts, fontSizes } from '@mmt/legacy-commons/Styles/globalStyles';
import { CHEVRON_DOWN_BLUE_ICON3 } from '../../flights/FlightImageConstants';

function Divider({ style = {} }) {
  return <View style={[AtomicCss.flexRow, styles.dividerStyle, style]} />;
}

function DropdownComponent({
  selected = 'Select',
  allOptions = [],
  onSelect,
  icon = CHEVRON_DOWN_BLUE_ICON3,
}) {
  const [showModal, setShowModal] = React.useState(false);
  const [layout, setLayout] = React.useState(null);
  const onClick = () => {
    setShowModal(true);
  };

  return (
    <View style={AtomicCss.flex1}>
      <View
        style={styles.mainContainer}
        ref={(ref) => {
          if (ref) {
            ref.measure((x, y, w, h, px, py) => {
              if (!w) {
                return;
              }
              if (
                layout &&
                layout.w === w &&
                layout.h === h &&
                layout.px === px &&
                layout.py === py
              ) {
                return;
              }
              setLayout({
                px,
                py,
                h,
                w,
              });
            });
          }
        }}
      >
        <TouchableOpacity onPress={onClick}>
          <View style={AtomicCss.flexRow}>
            <Text style={[AtomicCss.blackText, AtomicCss.marginLeft10]}>{selected}</Text>
            <Image source={icon} style={[styles.iconStyle]} />
          </View>
        </TouchableOpacity>
      </View>
      <Modal
        onRequestClose={() => {
          setShowModal(false);
        }}
        transparent
        visible={showModal}
      >
        <View style={[AtomicCss.flexRow, AtomicCss.flex1]}>
          <TouchableOpacity
            activeOpacity={1}
            style={[AtomicCss.flexRow, AtomicCss.flex1, styles.modal]}
            onPress={() => {
              setShowModal(false);
            }}
          >
            <View style={[AtomicCss.flexRow, AtomicCss.flex1]} />
          </TouchableOpacity>

          <View
            style={[
              styles.modalContainer,
              { top: layout && layout.py, left: layout && layout.px, width: layout && layout.w },
            ]}
          >
            {allOptions.map((option, i) => {
              const isSelected = option === selected;
              return (
                <React.Fragment>
                  <TouchableOpacity
                    activeOpacity={0.7}
                    onPress={() => {
                      setShowModal(false);
                      onSelect && onSelect(option, i);
                    }}
                  >
                    <Text style={[styles.option, isSelected ? styles.selectedOption : {}]}>
                      {option}
                    </Text>
                  </TouchableOpacity>
                  {i < allOptions.length - 1 && <Divider height={1} />}
                </React.Fragment>
              );
            })}
            <Image source={icon} style={[styles.iconStyle, styles.modalArrow]} />
          </View>
        </View>
      </Modal>
    </View>
  );
}

const styles = {
  mainContainer: {
    borderRadius: 4,
    backgroundColor: '#fff',
    width: '100%',
    height: 40,
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: '#BEBEBE',
  },
  iconStyle: {
    width: 12,
    height: 12,
    marginRight: 12,
    marginTop: 4,
    position: 'absolute',
    right: 0,
  },
  option: {
    paddingHorizontal: 24,
    paddingVertical: 7,
    fontSize: fontSizes.lg * PixelRatio.getFontScale(),
    color: colors.black,
    paddingLeft: 15,
  },
  selectedOption: {
    color: colors.black,
    fontFamily: fonts.bold,
    paddingVertical: 12,
    borderRadius: 8,
    flex: 1,
  },
  modal: {
    backgroundColor: '#0005',
  },
  modalContainer: {
    position: 'absolute',
    paddingBottom: 4,
    borderRadius: 8,
    backgroundColor: '#fff',
  },
  dividerStyle: {
    height: PixelRatio.getPixelSizeForLayoutSize(StyleSheet.hairlineWidth),
    width: '100%',
    backgroundColor: colors.linegrey,
  },
  modalArrow: {
    transform: [{ rotate: '180deg' }],
    marginTop: 10,
  },
};

export default DropdownComponent;
