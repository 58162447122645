import {fonts} from '@mmt/legacy-commons/Styles/globalStyles'
import getPlatformElevation from '@mmt/legacy-commons/Common/Components/Card/getPlatformElevation'

const styles = {
    Card:{
        backgroundColor:'#fff',
        paddingVertical:15,
        paddingHorizontal:15,
        ...getPlatformElevation(2)
    },

    arrowStyle:{
        width:10,
        height:8,
        alignSelf:'center',
        marginLeft:12,
        marginRight:12,
        marginTop:3
    },

    toggleSwitchWrapper:
    {
        borderRadius:20,
        borderWidth:1,
        borderColor:'#f2f2f2',
        ...getPlatformElevation(1),
        paddingHorizontal:3,
        paddingVertical:3,
        backgroundColor:'#fff',
        width:200,
        alignSelf:'center'
    },

    switchButton:{
        flex:1,
        flexDirection:'row',
        alignItems:'center',
        paddingLeft:25,
        padding:3,
    },

    activeTickStyle:{
        width:16,
        height:16,
        position:'absolute',
        left:5
    },

    activeTab:{
        borderRadius:20,
        backgroundColor:'#008cff'
    },

    defaultTxt:{
        color:'#000',
        fontFamily:fonts.regular,
        fontSize:12
    },

    activeTxt:{
        color:'#fff',
        fontFamily:fonts.black,
        fontSize:12
    },

    tabWrapper:{
        borderBottomWidth:1,
        borderBottomColor:'#e7e7e7'
    },

    QRImage:{
        width:100,
        height:100
    },

    linkText:{
        color:'#008cff'
    },

    textRight:{
        textAlign:'right'
    }
}

export default styles;
