import React, {useMemo} from 'react';
import { View, Text, Image, TouchableOpacity, Dimensions, Platform, NativeModules } from 'react-native';
import getStyle from './uploadProffCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {
  BLUE_LIST_2_ICON,
  SUCCESS_TICK_ICON_SM,
  CROSS_GREY_ICON_WITHBG,
  BLUE_LIST_ICON_ERROR,
} from '../../../../../../FlightImageConstants';
import { isEmptyArray } from '../../../../../../../Common/commonUtil';
import { MAX_UPLOAD_LIMIT, UPLOAD_STATES } from '../../../../constant';
import ProgressBar from '../../../../../../../Common/ProgressBar';
import { getFont } from '../../../../../../../PostSalesStyles';
import { useTheme } from '../../../../../../../theme/theme.context';
import { noop } from 'lodash';

const UploadProofCard = ({
  uploadProofData,
  uploadText,
  documents,
  onAddAttachment,
  onDeleteAttachment,
  addMoreText,
  retryText,
  errorText = null,
  onAddAttachmentWeb,
}) => {
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme } = useTheme();
  const styles = getStyle(psTheme, fonts);
  const { title, subtitle } = !isEmptyArray(uploadProofData) && uploadProofData[0];
  const isWebPlatform = Platform.OS === 'web';
  const FileUploaderComp = useMemo(() => {
    if (isWebPlatform) {
      const {
        FileUploaderModule: { FileUploader },
      } = NativeModules;
      return FileUploader;
    }
    return null;
  }, [isWebPlatform]);
  const getTouchableOpacityComponent = (status) => {
    if (status === UPLOAD_STATES.SUCCESS) {
      return <Image style={styles.deleteIconStyle} source={CROSS_GREY_ICON_WITHBG} />;
    } else if (status === UPLOAD_STATES.ERROR) {
      return (
        <Text
          style={[styles.linkTxt, fonts.blackFontFamily, fsStyle.font14, AtomicCss.lineHeight20]}
        >
          {retryText}
        </Text>
      );
    } else {
      return <Image style={styles.deleteIconStyle} source={CROSS_GREY_ICON_WITHBG} />;
    }
  };
  const onFileChanged = (file) => {
    onAddAttachmentWeb(file);
  }
  const setAttachmentList = (file) => {
    console.log('setAttachmentList', file);
  }
  
  return !isEmptyArray(documents) ? (
    <React.Fragment>
      {documents.map((document, index) => {
        return (
          <View key={index} style={[AtomicCss.marginBottom10]}>
            <View
              style={[
                AtomicCss.flexRow,
                AtomicCss.alignCenter,
                AtomicCss.spaceBetween,
                styles.uploadCard,
                AtomicCss.marginBottom0,
              ]}
            >
              <View style={[AtomicCss.flexRow, styles.docName]}>
                {document.status === UPLOAD_STATES.ERROR ? (
                  <Image style={styles.uploadIcon} source={BLUE_LIST_ICON_ERROR} />
                ) : (
                  <Image style={styles.uploadIcon} source={BLUE_LIST_2_ICON} />
                )}
                <View style={[AtomicCss.flexColumn, AtomicCss.flex1]}>
                  <Text numberOfLines={1} style={[styles.uploadCardHeading, fsStyle.font14]} ellipsizeMode="tail">
                    {document.fileName}
                  </Text>
                  {document.status === UPLOAD_STATES.ERROR && !!errorText && (
                    <Text style={[styles.errorText, fsStyle.font13]}>{errorText}</Text>
                  )}
                </View>
              </View>
              {document.status === UPLOAD_STATES.SUCCESS && (
                <Image style={styles.successIconStyle} source={SUCCESS_TICK_ICON_SM} />
              )}
              <TouchableOpacity onPress={onDeleteAttachment.bind(null, document)}>
                {getTouchableOpacityComponent(document.status)}
              </TouchableOpacity>
            </View>
            {document.status === UPLOAD_STATES.INPROGRESS && (
              <View style={styles.progressBarWrppaer}>
                <ProgressBar
                  status="inProgress"
                  toValue={90}
                  containerWidth={Dimensions.get('window').width - 100}
                />
              </View>
            )}
          </View>
        );
      })}
      {documents.length < MAX_UPLOAD_LIMIT &&
        !documents.some((document) => document.status == UPLOAD_STATES.INPROGRESS) && (
          <View
            style={{
              flex: 1,
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
            }}
          >
            {!isWebPlatform ? (
               <TouchableOpacity
               activeOpacity={0.8}
               style={[styles.uploadCardWhite, AtomicCss.flexRow, AtomicCss.spaceBetween]}
               onPress={onAddAttachment}
             >
               {!!addMoreText && (
                 <Text
                   style={[
                     styles.linkTxt,
                     fonts.blackFontFamily,
                     fsStyle.font14,
                     AtomicCss.lineHeight20,
                   ]}
                 >
                   {'+ ' + addMoreText}
                 </Text>
               )}
             </TouchableOpacity>
            ) : !!FileUploaderComp && addMoreText && (
              <FileUploaderComp
                onFileChanged={onFileChanged}
                setAttachmentList={setAttachmentList}
                attachmentLst={[]}
                text={'+ ' + addMoreText}
              />
          )}
          </View>
        )}
    </React.Fragment>
  ) : (
    <>
      {isWebPlatform ? (
        <FileUploaderComp
          onFileChanged={onFileChanged}
          setAttachmentList={setAttachmentList}
          attachmentLst={[]}
          text={uploadText}
        />
      ) : (
        <TouchableOpacity
          activeOpacity={0.8}
          style={[styles.uploadCard, AtomicCss.flexRow, AtomicCss.spaceBetween]}
          onPress={onAddAttachment}
        >
          <View style={[AtomicCss.flexRow, AtomicCss.flex1, AtomicCss.spaceBetween]}>
            <Image style={styles.uploadIcon} source={BLUE_LIST_2_ICON} />
            <View style={styles.lftDtls}>
              <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.spaceBetween]}>
                <View>
                  <Text style={[styles.uploadCardHeading, fsStyle.font16]}>{title}</Text>
                  {!isEmptyArray(subtitle) &&
                    subtitle.map((sub) => (
                      <Text key={sub} style={[styles.uploadCardInfo, fsStyle.font13]}>
                        {sub}
                      </Text>
                    ))}
                </View>
                <TouchableOpacity onPress={onAddAttachment}>
                  <Text style={[styles.linkTxt, fonts.blackFontFamily, fsStyle.font14]}>
                    {uploadText}
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </TouchableOpacity>
      )}
    </>
  );
};

export default UploadProofCard;
