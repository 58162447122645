import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import {View, Text, Image, TouchableOpacity} from 'react-native';
import {fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import { themeColors } from '../../PostSalesStyles';
import Button from './Button';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getPlatformElevation from './getPlatformElevation';
import BasePage from '../../Common/PostSalesBasePage';
import {getButtonsForType} from '../HolidayBookingDetailUtils';
import {buttonDisplayTypes} from '../HolidayBookingConstants';
import { refundCardIcon, rightArrow } from '../HolidayBookingConstants';
import HTMLViewWrapper from '../../Common/HTMLViewWrapper';

class PaymentCard extends BasePage {
  render() {
    const {paymentCard, buttons, paymentDetails} = this.props;
    const roundButtons = getButtonsForType(buttons, buttonDisplayTypes.LINK_RECTANGLE_FULL, false);
    const linkButtons = getButtonsForType(buttons, buttonDisplayTypes.LINK_RECTANGLE_FULL, true);
    return (
      <View style={[AtomicCss.marginBottom8]}>
        <View style={[styles.infoCardWrapper]}>
          <View style={AtomicCss.flexRow}>
            <View style={styles.headingIconWrapper}>
              <Image style={styles.headingIconStyle} source={refundCardIcon} />
            </View>
            <View style={[styles.infoCardContent]}>
              <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween]}>
                <Text
                  style={[AtomicCss.defaultText, AtomicCss.blackFont, AtomicCss.font14, AtomicCss.marginBottom3]}>TOTAL
                  PRICE </Text>
                <View style={styles.totalRefundprice}>
                  <Text style={[AtomicCss.defaultText, AtomicCss.blackFont, AtomicCss.font14]}>
                    {paymentCard.totalPackageCost}{' '}</Text>
                </View>
              </View>

              {!isEmpty(paymentCard.amountReceived) &&
              <View style={[styles.paymentInnerDtls, AtomicCss.marginBottom3, {marginTop: 20}]}>
                <Text
                  style={[AtomicCss.defaultText, AtomicCss.regularFont, AtomicCss.font12,
                    AtomicCss.marginBottom3]}>AMOUNT RECEIVED</Text>
                <View style={styles.totalRefundprice}>
                  <Text style={[AtomicCss.defaultText, AtomicCss.regularFont, AtomicCss.font14]}>
                    {paymentCard.amountReceived}{' '}</Text>
                </View>
              </View>
              }
              {!isEmpty(paymentDetails.paymentSchedules)
              && paymentDetails.paymentSchedules.map((schedule, index) => {
                return (
                  <View>
                    {index === 0 && <>
                    <View style={[styles.payByIndoWrapper, {marginTop: 5}]}>
                      <Text style={styles.payTxt}>{schedule.label}</Text>
                      <Text style={styles.payTxt}>{schedule.value}{' '}</Text>
                    </View>
                    {!isEmpty(schedule.tcsNote) && 
                    <View style={AtomicCss.marginBottom10}> 
                      <HTMLViewWrapper htmlContent={schedule.tcsNote}/>
                    </View>}
                  </>
                    }
                    {index > 0 &&
                    <View
                      style={[styles.paymentInnerDtls]}>
                      <Text
                        style={[AtomicCss.defaultText, AtomicCss.regularFont, AtomicCss.font12,
                          AtomicCss.marginBottom3]}>{schedule.label}</Text>
                      <View style={styles.totalRefundprice}>
                        <Text
                          style={[AtomicCss.defaultText, AtomicCss.regularFont, AtomicCss.font14]}>
                          {schedule.value}{' '}</Text>
                      </View>
                    </View>
                    }
                  </View>
                );
              })
              }
              {!isEmpty(paymentCard.cashbackCouponName) &&
              <View style={[styles.cashBackCouponSection, AtomicCss.marginBottom12]}>
                <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom5]}>
                  <Text style={styles.couponTxt}>
                    Cashback Coupon
                  </Text>
                  <View style={styles.couponCodeWrapper}>
                    <Text
                      style={[AtomicCss.defaultText, AtomicCss.boldFont, AtomicCss.font12]}>
                      {paymentCard.cashbackCouponName}
                    </Text>
                  </View>
                </View>
                {!isEmpty(paymentCard.cashbackCouponString) &&
                <Text
                  style={[AtomicCss.font12, AtomicCss.regularFont, AtomicCss.defaultText]}>
                  {paymentCard.cashbackCouponString}
                </Text>
                }
              </View>
              }
              <View style={[styles.btnWrapper, {marginTop: 10}]}>
                {!isEmpty(roundButtons)
                && roundButtons.map((item, index) => {
                  return (
                    <Button
                      button={item}
                      handleClick={this.props.handleButtonClick}/>
                  );
                })
                }
              </View>

            </View>
          </View>

          {!isEmpty(paymentCard.paymentHeading) &&
          <Text
            style={[AtomicCss.midGreyText, AtomicCss.regularFont, AtomicCss.font12, AtomicCss.marginBottom20, AtomicCss.textCenter]}>{paymentCard.paymentHeading}</Text>
          }
        </View>

        {!isEmpty(linkButtons)
        && linkButtons.map((item, index) => {
          return (
            <TouchableOpacity
              style={styles.redirectBtnWrapper}
              activeOpacity={0.8}
              onPress={() => this.props.handleButtonClick(item)}
            >
              <Text style={styles.linkBtnTxt}>{item.text}</Text>
              <Image style={styles.rightArrowIcon} source={rightArrow}/>
            </TouchableOpacity>
          );
        })
        }
      </View>
    );
  }
}

const styles = {
  infoCardWrapper: {
    backgroundColor: '#fff',
    ...getPlatformElevation(2),
    marginBottom: 1
  },

  infoCardContent: {
    paddingVertical: 10,
    flex: 1,
    paddingRight: 16
  },

  infoCustom: {
    fontSize: 14,
    color: '#4a4a4a',
    lineHeight: 18,
    fontFamily: fonts.regular
  },
  headingIconWrapper: {
    width: 46,
    alignItems: 'center'
  },
  headingIconStyle: {
    width: 17,
    height: 21,
    top: 15
  },

  redirectBtnWrapper: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 50,
    paddingRight: 35,
    justifyContent: 'space-between',
    flexDirection: 'row',
    backgroundColor: '#fff',
    borderBottomWidth: 1,
    borderBottomColor: '#ededed',
    alignItems: 'center'
  },
  rightArrowIcon: {
    width: 9,
    height: 13,
    tintColor: themeColors.rightArrowTintColor
  },
  linkBtnTxt: {
    fontSize: 14,
    fontFamily: fonts.bold,
    color: themeColors.linkTextColor
  },
  disIcon: {
    width: 16,
    height: 16,
    marginRight: 5
  },
  greenTxt: {
    color: '#1a7971',
    fontSize: 12
  },
  totalRefundprice: {
    // paddingRight:8,
  },
  paymentInnerDtls: {
    flexDirection: 'row',
    justifyContent: 'space-between'

  },
  payByIndoWrapper: {
    borderRadius: 4,
    backgroundColor: '#ffedd1',
    padding: 10,
    marginBottom: 15,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    marginHorizontal: -8
  },
  cashBackCouponSection: {
    borderTopWidth: 1,
    borderTopColor: '#d9d9d9',
    marginRight: -16,
    paddingVertical: 15
  },
  payTxt: {
    fontFamily: fonts.black,
    color: '#cf8100',
    fontSize: 14
  },
  infoTxt: {
    color: '#cf8100',
    fontSize: 12,
    fontFamily: fonts.regular,
    marginHorizontal: 40
  },
  couponTxt: {
    color: '#1a7971',
    fontFamily: fonts.bold,
    fontSize: 14
  },
  couponCodeWrapper: {
    borderWidth: 1,
    borderColor: '#4a4a4a',
    borderRadius: 4,
    padding: 5,
    marginLeft: 10
  },
  btnWrapper: {
    width: '92%'
  }
};

PaymentCard.propTypes = {
  paymentCard: PropTypes.object.isRequired,
  paymentDetails: PropTypes.object.isRequired,
  buttons: PropTypes.array.isRequired
};

export default PaymentCard;
