
import React, {useState, useEffect, useRef} from 'react';
import { Image, ScrollView, Text, View, Alert, Keyboard, DeviceEventEmitter, Platform } from 'react-native';
import PropTypes from 'prop-types';
import Actions from '../../../../../navigation/postSalesNavigation';
import useRefundModeView from "./hooks/useRefundModeView";

import OverlayMessage from '../../../../../Common/InstantRefund/Overlays/OverlayMessage';
import OTPBottomOverlay from '../../../../../Common/InstantRefund/Overlays/OTPBottomOverlay';
import OTPLoader from '../../../../../Common/InstantRefund/Overlays/OTPLoader';
import InstantRefundBanner from '../../../../../Common/InstantRefund/Overlays/InstantRefundBanner';
import BottomOverlayDtls from '../../../../../Common/InstantRefund/Overlays/BottomOverlayDtls';
import NoNetworkView from '../../../../../Common/Cancellation/NoNetworkView';
import PageLoader from "../../../../../Common/Cancellation/PageLoader"
import BaseButton, { BUTTON_VARIANT } from '../../../../../Common/BaseButton';
import ConfirmationOverlay from '../../../../../Common/ConfirmationOverlay';

import * as PSC from "../../../../../PostSalesConstant";
import { getFont } from '../../../../../PostSalesStyles';
import { getStaticData } from '../../../../../staticData/staticData';
import { openErrorPage } from '../../../../../utils/PostSaleUtil';
import { openFlightCancellationThankYouPage, addNewLineInMiddle } from '../../../../FlightBookingUtil';
import { sendOtp, verifyOtp, registerForInstantRefund } from '../../../../../Common/commonUtil';
import { HEADER_BACK_ICON } from '../../../../FlightImageConstants';
import { useTheme } from '../../../../../theme/theme.context';

import { getCurrencyIcon } from "../../helper"

import Breadcrumb from "../../components/Breadcrumb";
import ErrorView from "../../components/ErrorView";
import StickyHeader from "../../components/StickyHeader";
import RefundModeList from './components/RefundModeList';

import { CANCELLATION_REFUNDMODE_PAGE_VIEW } from '../../constant';
import CancellationTrackingHelper from '../../../../cancellation/Helpers/CancellationTrackingHelper';
import PostSalesBookingTrackingHelper from '../../../../../PostSalesBookingTrackingHelper';

import isEmpty from 'lodash/isEmpty';

import createStyles from "./styles";
import NewRefundModeList from './components/NewRefundModeList';
import CommonBottomOverlayMessage from '../../../../../Common/CommonOverlayMessage/CommonBottomOverlayMessage';
import RefundBottomSheet from './components/RefundBottomSheet';
import { FLIGHT_THROTTLE_OBJECT } from '../../../details/FlightBookingDetailsConstant';
import CreditShellMessageBox from '../../components/CreditShellMessageBox'


const SelectRefundMode = (props) => {

  const { cancellationRefundMode,
    headerTitle,
    headerSubTItle,
    penaltyResponse,
    emiMessage,
    refundAmount,
    bookingId,
    trackingBundle,
    cancellationRequest,
    segmentWiseInfo,
    cancellationConfirmationText,
    isCorporateBooking,
    additionalText } = props;

    const { currency } = penaltyResponse || {};
    const { message = '', refundModeList : refundModeListData = [], isNewRefundMode = false } = cancellationRefundMode || {};
    const trackingPageName = 'RefundMode';

    const [viewState, refundModeList, onRetry] = useRefundModeView(refundModeListData, { bookingId });

    const [keyboard, setKeyboard] = useState(false);
    const [radiobtn, setRadioBtn] = useState('');
    const [OTPValue, setOTPValue] = useState(PSC.EMPTY_STRING);
    const [errorState, setErrorState] = useState(false);
    const [confirmationOverlay, setConfimationOverlay ] = useState(false);
    const [otpOverlay, setOtpOverlay] = useState(PSC.EMPTY_STRING);
    const [instantData, setInstantData] = useState(null);
    const clientTxnId = useRef(null);
    const [knowMore, setknowMore] = useState(null);

    const { psTheme: theme } = useTheme();
    const { fsStyle, ...fonts } = getFont(true);
    const styles = createStyles(theme, fonts);

  useEffect(() => {
    const refreshFlightListener = DeviceEventEmitter.addListener(PSC.InstantRefundReloadEvent.FLIGHT, onRetry);
    const keyboardShowListener = Keyboard.addListener(PSC.KEYBOARD_SHOW_EVENT, () => { setKeyboard(true) });
    const keyboardHideListener = Keyboard.addListener(PSC.KEYBOARD_HIDE_EVENT, () => { setKeyboard(false) });

    if (penaltyResponse && penaltyResponse.instantRefundData && penaltyResponse.instantRefundData.showInstantRefundBanner) {
      PostSalesBookingTrackingHelper.trackLoadEvent(PSC.REFUNDMODE_PAGE_NAMES.FLIGHT, PSC.REGISTER_INSTANT_REFUND_SHOWN, bookingId);
    } else {
      PostSalesBookingTrackingHelper.trackLoadEvent(PSC.REFUNDMODE_PAGE_NAMES.FLIGHT, PSC.INSTANT_REFUND_NOT_ELIGBLE, bookingId);
    }

    CancellationTrackingHelper.trackLoadEventWithFullPartialFlag(trackingPageName, trackingBundle, false);

    return () => {
      if(Platform.OS!=='web'){
        refreshFlightListener.remove();
      }
      keyboardShowListener.remove();
      keyboardHideListener.remove();
    }
  }, []);

  useEffect(() => {
    if(viewState === CANCELLATION_REFUNDMODE_PAGE_VIEW.ERROR && refundModeList && refundModeList.errorPageData){
      openErrorPage(
        refundModeList.errorPageData,
        undefined,
        onRetry,
        bookingId,
        'canellation',
        Actions,
        {
          psLob: PSC.LOBNAMES.FLIGHT,
          langAware: true,
          isCorporateBooking,
        },
      );
    }
  },[viewState, refundModeList])


    const populateKnowMore = (popKnowMore) => {
      setknowMore(popKnowMore);
    }
    
    const updateRefundMode = (refundMode) => {
      if (refundMode.accountNo && refundMode.accountIdentifier) {

        PostSalesBookingTrackingHelper.trackLoadEvent( PSC.REFUNDMODE_PAGE_NAMES.FLIGHT, PSC.INSTANT_REFUND_AVAILABLE, bookingId );

        setInstantData({
          requestInstantRefund: true,
          accountNo: refundMode.accountNo,
          accountIdentifier: refundMode.accountIdentifier,
        });
      } else {
        setInstantData(null);
      }
    }

    const sendInstantRefundOtp = async () => {
      clientTxnId.current = await sendOtp(bookingId);
      if (!isEmpty(clientTxnId.current)) {

        PostSalesBookingTrackingHelper.trackClickEvent( PSC.REFUNDMODE_PAGE_NAMES.FLIGHT, PSC.INSTANT_REFUND_OTP_SUCCESS, bookingId);

        setOtpOverlay(PSC.OTP_OVERLAY);
      } else {
        /* no screen for otp sending failure right now. Alert temp */
        const { errorText: { sendingOtpFailedError } } = getStaticData();
        PostSalesBookingTrackingHelper.trackClickEvent(PSC.REFUNDMODE_PAGE_NAMES.FLIGHT, PSC.INSTANT_REFUND_OTP_FAILED, bookingId);
        Alert.alert(sendingOtpFailedError);
      }
    }

    const resendOtp = () => {

      PostSalesBookingTrackingHelper.trackClickEvent(PSC.REFUNDMODE_PAGE_NAMES.FLIGHT, PSC.INSTANT_REFUND_OTP_REQUESTED_AGAIN,bookingId);

      sendInstantRefundOtp();
    };

    const verifyInstantRefundOtp = async () => {
      const authorizationToken = await verifyOtp(
        bookingId,
        OTPValue,
        clientTxnId.current,
      );
      if (!authorizationToken) {
        PostSalesBookingTrackingHelper.trackLoadEvent( PSC.REFUNDMODE_PAGE_NAMES.FLIGHT, PSC.WRONG_OTP_ENTERED, bookingId);

        setErrorState(true);
        setOtpOverlay(PSC.OTP_OVERLAY);
      } else {
        PostSalesBookingTrackingHelper.trackClickEvent( PSC.REFUNDMODE_PAGE_NAMES.FLIGHT, PSC.INSTANT_REFUND_REQUESTED, bookingId);

        setOtpOverlay(PSC.EMPTY_STRING);
        openFlightCancellationThankYouPage(
          { ...cancellationRequest, authorizationToken },
          trackingPageName,
          radiobtn,
          instantData,
          trackingBundle,
          segmentWiseInfo,
          isCorporateBooking
        );
      }
    }

    const confirmationPopup = () => {
      if (instantData) {
        sendInstantRefundOtp();
      } else {
        PostSalesBookingTrackingHelper.trackClickEvent( PSC.REFUNDMODE_PAGE_NAMES.FLIGHT, PSC.NO_INSTANT_REFUND_REQUESTED, bookingId );

        setConfimationOverlay((prev) => !prev);
      }
    };

    const renderConfirmationRightButton = () => {
      openFlightCancellationThankYouPage(
        cancellationRequest,
        trackingPageName,
        radiobtn,
        undefined,
        trackingBundle,
        segmentWiseInfo,
        isCorporateBooking,
      );
    };

    const handleChange = (refundModeObj) => {
      const modeName = refundModeObj && refundModeObj.refundModeReason;
      updateRefundMode(refundModeObj);
      setRadioBtn(modeName);
    };

    const handleBottomOverlay = (showOverlay) => {
      setOTPValue(PSC.EMPTY_STRING);
      setOtpOverlay(showOverlay);
      setknowMore(null);
    };

    const handleOTPValue = (EnterText) => {

      setOTPValue(EnterText);
      setErrorState(false);

    };

    const renderConfirmationPopup = () => {
      const { backCapsText, confirmText, areYouSureText } = getStaticData();
      return (
        <ConfirmationOverlay
          content={cancellationConfirmationText}
          leftBtnClick={confirmationPopup}
          title={areYouSureText}
          leftButtonText={backCapsText}
          rightButtonText={confirmText}
          isCorporateBooking={isCorporateBooking}
          rightBtnClick={renderConfirmationRightButton}
          outsideClick={confirmationPopup}
          additionalText= {additionalText}
        />
      );
    }

    const handleOTPOverlay = () => {
      if (OTPValue === PSC.EMPTY_STRING) {
        setErrorState(true);
      } else {
        setOtpOverlay(PSC.OTP_LOADER);
        verifyInstantRefundOtp();
      }
    };

    const handleProceedButtonFn = () => {
      PostSalesBookingTrackingHelper.trackClickEvent(
        PSC.REFUNDMODE_PAGE_NAMES.FLIGHT,
        PSC.REGISTER_INSTANT_REFUND_CLICKED,
        bookingId,
      );
      handleBottomOverlay(PSC.EMPTY_STRING);
      registerForInstantRefund(PSC.LOBNAMES.FLIGHT);
    };

    const renderOTPOverlay = () => {
      const {
        additionalText: { verificationInitiateRefundText },
        proceedText,
        enterOtpText,
        resendOtpText,
        errorText: { enterValidOtpError },
        cancelText,
        otpVerificationText,
      } = getStaticData();
      return (
        <OverlayMessage
          handleBottomOverlay={handleBottomOverlay}
          keyboard={keyboard}
          content={
            <OTPBottomOverlay
              heading={otpVerificationText}
              subHeading={verificationInitiateRefundText}
              leftButtonText={cancelText}
              rightButtonText={proceedText}
              errorText={enterValidOtpError}
              inputFieldTitleText={enterOtpText}
              note={resendOtpText}
              bookingId={bookingId}
              OTPValue={OTPValue}
              handleBottomOverlay={handleBottomOverlay}
              handleOTPValue={handleOTPValue}
              handleOTPOverlay={handleOTPOverlay}
              errorState={errorState}
              resendOtp={resendOtp}
            />
          }
        />
      );
    };

    const renderInstantRefund = () => {
      const {
        additionalText: { linkAccountTaggedWithYourPhoneText, instantRefundInAccountText },
        orText,
      } = getStaticData();
      return (
        <InstantRefundBanner
          title={orText}
          heading={instantRefundInAccountText}
          subHeading={addNewLineInMiddle(linkAccountTaggedWithYourPhoneText)}
          handleOverlay={handleBottomOverlay}
        />
      );
    };

    const renderInstantRefundOverlay = () => {
      const {
        proceedText,
        instantRefundCardText: {
          heading,
          item: { step1Text, step2Text, step3Text },
        },
        notNowText,
      } = getStaticData();
      return (
        <OverlayMessage
          handleBottomOverlay={handleBottomOverlay}
          content={
            <BottomOverlayDtls
              handleBottomOverlay={handleBottomOverlay}
              handleProceedButton={handleProceedButtonFn}
              leftButtonText={notNowText}
              rightButtonText={proceedText}
              heading={heading}
              step1={step1Text}
              step2={step2Text}
              step3={step3Text}
            />
          }
        />
      );
    };
  
    const renderOTPLoaderOverlay = () => (
      <OverlayMessage handleBottomOverlay={handleBottomOverlay} content={<OTPLoader />} />
    );
  
  const renderErrorPage = () => {
    const { errorText: { somethingWentWrongTryAgainError }, tryAgainText } = getStaticData();

    return (
      <ErrorView
        heading={somethingWentWrongTryAgainError}
        buttonLabel={tryAgainText}
        showRetry={true}
        onRetry={onRetry}
      />
    );
  }

  const renderHeader = () => {
    return (
      <StickyHeader
        iconSrc={HEADER_BACK_ICON}
        title={headerTitle}
        headerColor={[theme.color.white, theme.color.white]}
        titleStyle={[styles.DefaultText, fonts.blackFontFamily]}
        subTitle={headerSubTItle}
        subTitleStyle={[styles.DefaultText, fsStyle.font12]}
        onBackPress={Actions.pop}
      />
    );
  }

  const renderFooter = () => {
    const { confirmCancellationText } = getStaticData();
    return (
      <View style={{padding: 12}}>
        <BaseButton
          text={confirmCancellationText.toUpperCase()}
          clickHandler={confirmationPopup}
          variant={BUTTON_VARIANT.PRIMARY}
          {...FLIGHT_THROTTLE_OBJECT}
          disabled={isEmpty(radiobtn)}
        />
      </View>
    );
  };

  const renderNoNetworkView = () => {
    const { errorText: { notConnectedToTheInternetError, checkYourSettingsAndTryAgainError}, tryAgainText, backText } = getStaticData();
    return (
      <NoNetworkView
      title={backText}
      heading={notConnectedToTheInternetError}
      subHeading={checkYourSettingsAndTryAgainError}
      buttonText={tryAgainText}
      retryFunction={onRetry}
    />
    )
  }

  const renderContent = () => {
    const {
      additionalText: { selectAModeToReceiveRefundText, linkAccountTaggedWithYourPhoneText, instantRefundInAccountText, selectRefundMode },
      selectText,
      reviewText,
      confirmText,
      refundModeText,
      orText
    } = getStaticData();

    const REFUND_MODE_STEPS = [selectText, reviewText, refundModeText, confirmText];

    return (
      <ScrollView>
        <Breadcrumb activeStep={2} data={REFUND_MODE_STEPS} />
        <View style={[styles.MamiCard]}>
          {isNewRefundMode ? <View style={[styles.paddingAll]}>
            <Text style={[fonts.blackFontFamily, fonts.font16, styles.BlackText]}>
              {selectRefundMode}
            </Text>
          </View> :
            <View style={[styles.CardContent, styles.alignCenter]}>
              <Image style={styles.CardIcon} source={getCurrencyIcon(currency)} />
              <Text style={[fonts.blackFontFamily, styles.BlackText]}>
                {selectAModeToReceiveRefundText}
              </Text>
            </View>
          }
          {!isEmpty(message) && (
            <View style={styles.refundModeMessageCard}>
              <Text style={[styles.BlackText, styles.AlignLeft]}>
                {message}
              </Text>
            </View>
          )}
          {!isEmpty(emiMessage) && (
            <View style={styles.refundModeMessageCard}>
              <Text style={[styles.BlackText, styles.AlignLeft]}>{emiMessage}</Text>
            </View>
          )}
          <View style={[styles.ActionBtn, styles.mar15Top]}>
            {  isNewRefundMode ?
               <NewRefundModeList  popKnowMore={populateKnowMore} refundModes={refundModeList} instantData={instantData} radiobtn={radiobtn} handleChange={handleChange} refundAmount={refundAmount} /> :
               <RefundModeList refundModes={refundModeList} instantData={instantData} radiobtn={radiobtn} handleChange={handleChange} refundAmount={refundAmount} />
            }
          </View>
          <CreditShellMessageBox heading={cancellationRefundMode?.heading} subheading={cancellationRefundMode?.creditShellRefundMessageList}></CreditShellMessageBox>
        </View>
        {penaltyResponse && penaltyResponse.instantRefundData && penaltyResponse.instantRefundData.showInstantRefundBanner && renderInstantRefund()}
      </ScrollView>
    );
  }

  const renderPage = () => {
    return (
      <View style={styles.flex1}>
        <View style={[styles.whiteContainer, styles.flex1]}>
          {renderHeader()}
          {renderContent()}
          {renderFooter()}
          {knowMore && <CommonBottomOverlayMessage
            content={<RefundBottomSheet
              title={knowMore.title}
              icon={knowMore.iconURL}
              ctaText={knowMore.ctaText}
              handleBottomOverlay={handleBottomOverlay}
              knowMoreList={knowMore.subLists}
            />}
            isBorderRadius
            isCancellable
            dismiss={handleBottomOverlay}
          />}
        </View>
        {confirmationOverlay ? renderConfirmationPopup() : null}
        {otpOverlay === PSC.OVERLAY ? renderInstantRefundOverlay() : null}
        {otpOverlay === PSC.OTP_OVERLAY ? renderOTPOverlay() : null}
        {otpOverlay === PSC.OTP_LOADER ? renderOTPLoaderOverlay() : null}
      </View>)
  }

  if(viewState === CANCELLATION_REFUNDMODE_PAGE_VIEW.LOADING){
    return <PageLoader updateAPIData={onRetry} />
  }else if(viewState === CANCELLATION_REFUNDMODE_PAGE_VIEW.NO_INTERNET){
    return renderNoNetworkView();
  }else if(viewState === CANCELLATION_REFUNDMODE_PAGE_VIEW.ERROR){
    return renderErrorPage();
  }else{
    if(isEmpty(refundModeList) || !Array.isArray(refundModeList)){
      return renderErrorPage();
    }else{
      return renderPage();
    }
  }

}

SelectRefundMode.propTypes = {
    refundAmount: PropTypes.number.isRequired,
    headerTitle: PropTypes.string.isRequired,
    cancellationRefundMode: PropTypes.object.isRequired,
    overlayConfirmCancelClick: PropTypes.func.isRequired,
    cancellationConfirmationText: PropTypes.string.isRequired,
    trackingBundle: PropTypes.object.isRequired,
    emiMessage: PropTypes.string.isRequired,
    bookingId: PropTypes.string.isRequired,
    penaltyResponse: PropTypes.object.isRequired,
  };

export default SelectRefundMode;