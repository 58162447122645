import React, { useEffect } from 'react';
import { View, Text, FlatList, Image, TouchableOpacity } from 'react-native';

import { AIRLINE_PHONE_ICON } from '../../../../FlightImageConstants';
import createStyle from './NameChangeBottomSheetCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import FlightBookingTrackingHelper from '../../../../modules/details/helper/FlightBookingTrackingHelper';
import { getStaticData } from '../../../../../staticData/staticData';
import { getFont } from '../../../../../PostSalesStyles';
import { useTheme } from '../../../../../theme/theme.context';

import { isButton } from '../../helper';
import { isEmptyArray, openDialer } from '../../../../../Common/commonUtil';

export default function DepartureTimeIsCloseScreen({
  airlineContactInfo,
  handleBottomOverlay,
  responseJson,
}) {
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyle(theme, fonts);

  const { heading, description, contactList, actionButton } = airlineContactInfo || {};

  const staticData = getStaticData();
  const { okayGotItText } = staticData;

  const actionBtn = {
    title: actionButton && actionButton.length ? actionButton.find(isButton).text : okayGotItText,
    disable: false,
  };

  useEffect(() => {
    FlightBookingTrackingHelper.trackLoadEvent(
      'Special request : Name Change Pop up near to dep',
      responseJson,
      'nameChange',
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    FlightBookingTrackingHelper.trackNameCorrectionBtnClick(
      'Special request : Name Change Pop up near to dep',
      'OKAY GOT IT',
      responseJson,
    );
    handleBottomOverlay();
  };

  const renderAirlineContactCTA = ({ item, index }) => {
    const lastItem = 'lastItemCallWrapper';
    return (
      <TouchableOpacity
        style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.spaceBetween, styles[lastItem]]}
        activeOpacity={0.7}
        onPress={() => {
          if (!isEmptyArray(item.contactNo)) {
            openDialer(item.contactNo[0]);
          }
        }}
      >
        <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.flex1]}>
          {!!item.mobileAirlineIconUrl && (
            <Image style={styles.flightIcon} source={{ uri: item.mobileAirlineIconUrl }} />
          )}
          <View>
            <Text
              style={[
                fonts.blackFontFamily,
                fsStyle.font14,
                AtomicCss.defaultText,
                AtomicCss.marginBottom8,
              ]}
            >
              {item.airlineName}
            </Text>
            {item.contactNo &&
              item.contactNo.map((contact) => (
                <Text style={[fonts.boldFontFamily, fsStyle.font14, AtomicCss.defaultText]}>
                  {contact}
                </Text>
              ))}
          </View>
        </View>
        <Image source={AIRLINE_PHONE_ICON} style={styles.phoneIcon} />
      </TouchableOpacity>
    );
  };

  return (
    <React.Fragment>
      <Text style={[styles.title, AtomicCss.marginBottom8]}>{heading}</Text>
      <Text
        style={[
          fonts.regularFontFamily,
          fsStyle.font14,
          AtomicCss.defaultText,
          AtomicCss.lineHeight21,
          AtomicCss.marginBottom12,
        ]}
      >
        {description}
      </Text>
      <View style={styles.strokeStyle} />

      <View style={styles.callListWrapper}>
        {contactList &&
          contactList.map(({ contactNoList }) => (
            <FlatList
              showsVerticalScrollIndicator={false}
              data={contactNoList}
              renderItem={renderAirlineContactCTA}
              keyExtractor={(i) => i.actionId}
            />
          ))}
      </View>
      <View style={[AtomicCss.flexRow, AtomicCss.alignSelfFlexEnd]}>
        <TouchableOpacity onPress={handleClose}>
          <Text
            style={[
              fsStyle.font16,
              fonts.blackFontFamily,
              AtomicCss.azure,
              AtomicCss.alignRight,
              AtomicCss.lineHeight20,
            ]}
          >
            {actionBtn.title}
          </Text>
        </TouchableOpacity>
      </View>
    </React.Fragment>
  );
}
