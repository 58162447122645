import React from 'react';
import PropTypes from 'prop-types';
import {Image, Text, View} from 'react-native';
import { getStaticData } from '../../../staticData/staticData';
import getStyles from './HotelMamiCss';
import { PAYMENT_CARD_ICON } from '../../HotelImageConstants';
import AmountPendingTimeline from './PaymentTimeLineCard';
import { isEmpty } from 'lodash';
import AtomicCss  from '@mmt/legacy-commons/Styles/AtomicCss';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';

const HotelPaymentBreakupCard = (props) => {
    const staticData = getStaticData(true);
    const { paymentBreakup, pricingBreakup, tcsNote } = props.response;
    const { priceBreakup } = tcsNote || {};
    const {fsStyle, ...fonts} = getFont(true);
    const styles = getStyles(fonts);
    const {psTheme: theme} = useTheme();

    const invokeAmountPaidUI = (paymentBreakupMap) => {
        const views = [];
        if (paymentBreakupMap) {
            for (let componentName in paymentBreakupMap) {
                views.push(
                    <View style={[styles.Paymentrow, styles.padVertical10]} key={componentName}>
                        <Text style={[styles.PaymentLeft, styles.BlackText, styles.AlignLeft]}>{componentName}</Text>
                        <Text style={[styles.BlackText,styles.AlignLeft]}>{`${paymentBreakupMap[componentName]} `}</Text>
                    </View>
                )
            }
        }
        return views;
    }

    return (
        <View style={[styles.MamiCard, styles.mar10Bt]}>
          <View style={styles.CardContent}>
            <Image
              style={styles.CardIcon}
              source={PAYMENT_CARD_ICON}
            />
            <View style={[styles.ContentPortion]}>
              <View style={[styles.Paymentrow, styles.mar15Bt]}>
                  <View style={styles.PaymentLeft}>
                            <Text style={[styles.BlackText, styles.blackFont, styles.AlignLeft]}>{paymentBreakup.paymentBreakupAmountPaid.text}</Text>
                  </View>
                  <Text style={[styles.BlackText, styles.blackFont, styles.AlignRight]}>
                      {`${paymentBreakup.paymentBreakupAmountPaid.value} `}
                  </Text>
              </View>

              {!isEmpty(paymentBreakup.paymentBreakupMap) && <View style={[styles.Paymentrow, styles.mar10Bt]}>
                        <Text style={[styles.BlackText, styles.blackFont, styles.AlignLeft]}>{staticData.paymentBreakupText}</Text>
              </View>
              }

              {paymentBreakup.paymentBreakupMap && invokeAmountPaidUI(paymentBreakup.paymentBreakupMap)}
              {!isEmpty(pricingBreakup) && !isEmpty(pricingBreakup.groupBookingBreakUp) && <AmountPendingTimeline pricingBreakup={pricingBreakup.groupBookingBreakUp} />}
              {!isEmpty(priceBreakup) && <View style={[styles.tcsSection]}>
                <View style={styles.tcsInfoSection}>
                    <>
                    {!isEmpty(priceBreakup.text) && 
                    <Text style={[{color: theme.color.defaultTextColor}, fsStyle.font12, fonts.regularFontFamily, AtomicCss.lineHeight18]}>{priceBreakup.text}</Text>}
                    {!isEmpty(priceBreakup.ctaText) && <Text style={[{color: theme.color.azure},fsStyle.font12, fonts.boldFontFamily]} onPress={() =>props.openTCSOverlay()}>{priceBreakup.ctaText}</Text>}
                    </>
                </View>
               </View>}
            </View>
          </View>
        </View>
    );
}

HotelPaymentBreakupCard.propTypes = {
    response: PropTypes.object.isRequired
};

export default HotelPaymentBreakupCard;