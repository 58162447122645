import React, { useState } from 'react';
import {
    Image,
    Text,
    TouchableOpacity,
    TouchableWithoutFeedback,
    View,
} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import RadioButton from '../../../../../../../Common/RadioButton';
import { getFont } from '../../../../../../../PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import { getStaticData } from '../../../../../../../staticData/staticData';
import { addSpaceWithAmount } from '../../../../../../../utils/PostSaleUtil';
import { getInstantRefundGradient } from '../../../../../../../Common/commonUtil';
import isEmpty from 'lodash/isEmpty';
import { REFUND_TO_MY_WALLET_ICON } from '../../../../../../FlightImageConstants';
import createStyles from './styles';

interface SubText {
    iconURL: string;
    text: string;
}

interface RefundModeObj {
    refundModeReason: string;
    accountNo?: string;
    title: string;
    newSubText: SubText[];
    knowMore: any;
    amount: string;
}

interface NewRefundModeListProps {
    refundModes: RefundModeObj[];
    handleChange: (refundModeObj: RefundModeObj) => void;
    instantData: any;
    radiobtn: string;
    refundAmount: number;
    popKnowMore: any;
}

const NewRefundModeList: React.FC<NewRefundModeListProps> = ({
    refundModes,
    handleChange,
    instantData,
    radiobtn,
    refundAmount,
    popKnowMore
}) => {
    const { knowMoreText,instantRefundText } = getStaticData();
    const { psTheme: theme } = useTheme();
    const { fsStyle, ...fonts } = getFont(true);
    const styles = createStyles(theme, fonts);

    const addSubText = (subTitleList: SubText[]) => {
        const views: JSX.Element[] = [];
        subTitleList.forEach((newSubText) => {
            views.push(
                <View style={[styles.flexRow, styles.marginTop10]}>
                   { !isEmpty(newSubText) && !isEmpty(newSubText.iconURL) && <Image style={styles.listIcon} source={{ uri: newSubText.iconURL }} /> }
                   { !isEmpty(newSubText) && !isEmpty(newSubText.text) && <Text style={[fsStyle.font14, styles.AlignLeft]}>{newSubText.text}</Text> }
                </View>
            );
        });
        return views;
    };

    const toggleRefundBottomSheet = (knowMore: any) => {
        popKnowMore(knowMore);
    };

    return (
        <>
            {!isEmpty(refundModes) && refundModes.length > 0 && refundModes.map((refundModeObj) => {
                const  { title= '',accountNo = '', refundModeReason = '', knowMore = {}, newSubText= [] } = refundModeObj;

                return (
                    <TouchableWithoutFeedback key={refundModeReason} onPress={() => handleChange(refundModeObj)}>
                        <View style={styles.refundCardOuter}>
                            <View style={[styles.flexRow]}>
                                {isEmpty(accountNo) && (
                                    <RadioButton
                                        selected={radiobtn === refundModeReason && !instantData}
                                        onChange={() => handleChange(refundModeObj)}
                                    />
                                )}
                                {!isEmpty(accountNo) && (
                                    <RadioButton
                                        selected={radiobtn === refundModeReason && instantData && instantData.accountNo === accountNo}
                                        onChange={() => handleChange(refundModeObj)}
                                    />
                                )}
                                <>
                                    <View style={styles.refundCardWrap}>
                                        <View style={styles.refundCardLeft}>
                                            <View style={styles.flexRow}>
                                                {refundModeReason === 'RefundToWallet' && (
                                                    <Image style={styles.walletIcon} source={REFUND_TO_MY_WALLET_ICON} />
                                                )}
                                                <Text
                                                    style={[
                                                        fsStyle.font16,
                                                        fonts.boldFontFamily,
                                                        styles.BlackText,
                                                        styles.marginTop3,
                                                    ]}
                                                >
                                                    {title}
                                                </Text>
                                                {!isEmpty(accountNo) && refundModeReason === 'RefundToWallet' && (
                                                    <View style={[styles.paddingLeft10]}>
                                                        <LinearGradient
                                                            start={{ x: 1.0, y: 0.0 }}
                                                            end={{ x: 0.0, y: 1.0 }}
                                                            colors={getInstantRefundGradient()}
                                                            style={styles.instantRefundTag}
                                                        >
                                                            <Text style={styles.instantRefundTagTxxt}>{instantRefundText}</Text>
                                                        </LinearGradient>
                                                    </View>
                                                )}
                                            </View>
                                            <Text
                                                style={[
                                                    styles.blackText,
                                                    fonts.boldFontFamily,
                                                    fsStyle.font16,
                                                    styles.alignLeft,
                                                    styles.marginTop10,
                                                ]}
                                            >
                                                {' '}
                                                {addSpaceWithAmount(!!refundModeObj.amount ? refundModeObj.amount : refundAmount)}
                                            </Text>
                                            
                                            {newSubText && newSubText.length > 0 && addSubText(newSubText)}
                                            
                                            {!isEmpty(knowMore) && <TouchableOpacity onPress={() => toggleRefundBottomSheet(knowMore)}>
                                                <Text style={styles.knowMore}> {knowMoreText}</Text>
                                            </TouchableOpacity>
                                            }
                                        </View>
                                    </View>
                                </>
                            </View>
                        </View>
                    </TouchableWithoutFeedback>
                );
            })}
        </>
    );
};

export default NewRefundModeList;
