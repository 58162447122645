import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';

export function dataAdaptor(data, setStateAction, handleData){
  if (data) {
    if (data.error || !data.success) {
      if (data.error === ViewState.NO_INTERNET) {
        setStateAction(ViewState.NO_INTERNET);
      } else {
        setStateAction(ViewState.ERROR);
      }
    } else {
      if (handleData) {
        handleData(data);
      } else {
        setStateAction(ViewState.SHOW_DETAIL);
      }
    }
  }
};
