import BasePage from '../../../Common/PostSalesBasePage';
import NoInternetView from '@mmt/legacy-commons/Common/Components/Error/NoInternetView';
import ProgressView from '@mmt/legacy-commons/Common/Components/Progress/ProgressView';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import BusBookingTrackingHelper from '../../BusBookingTrackingHelper';
import React from 'react';

class SpecialClaimBaseClass extends BasePage {
  static navigationOptions = {
    header: null
  };

  constructor(props, routerKeyName, pageName) {
    super(props, routerKeyName);
    this.pageName = pageName;
    this.state = {
      viewState: ''
    };
    this.trackingPageName = '';
  }
  componentDidMount() {
    super.componentDidMount();
  }

  renderProgressView = message => (<ProgressView message={message} />);
  renderNoNetworkView = () => (
    <NoInternetView
      onRetry={() => {
        this.setState({viewState: ViewState.LOADING});
        this.fetchData();
      }}
    />);

  trackPageLoad(response, specialClaimData, bookingState) {
    this.trackingPageName = `Mob:Customer support: Bus:${this.pageName}`;
    // BusBookingTrackingHelper
    //   .trackLoadEvent(this.trackingPageName, response, specialClaimData, bookingState);
  }
  trackError() {
    // BusBookingTrackingHelper.trackErrorEvent(`Mob:Customer support:Bus:${this.pageName}`);
  }

  trackClickEvent(response) {
    this.trackingPageName = `Mob:Customer support:Bus:${this.pageName}`;
    // BusBookingTrackingHelper.trackClickEvent(this.trackingPageName, 'MI_FLT_SClaim_Yes');
  }

  fetchData() {
    // to be implemented by child classes
  }

  renderErrorView = () => (
    <ErrorView
      showRetry
      onRetry={() => {
        this.setState({viewState: ViewState.LOADING});
        this.fetchData();
        this.trackError();
      }}
    />);
}
export default SpecialClaimBaseClass;
