/* Postsales Images */
import { getImagePath } from '../utils/PostSaleUtil';

export const BACK_ARROW_BLACK_ICON = { uri: 'https://imgak.mmtcdn.com/mima/images/mobile/backArrowBlack.webp'};
export const TOP_STATUS_ICON_9 = { uri: 'https://imgak.mmtcdn.com/mima/images/mobile/topstatusIcon9.webp'};
export const TICKET_FAIL_RED_ICON = { uri: 'https://imgak.mmtcdn.com/mima/images/mobile/ic_failed.webp'};
export const NON_REFUNDABLE_ICON = { uri: 'https://imgak.mmtcdn.com/mima/images/mobile/non-refundableIcon.webp'};
export const PAYMENT_CARD_ICON = { uri: 'https://imgak.mmtcdn.com/mima/images/mobile/payment_icon.webp'};
export const CROSS_FIND_ICON = { uri: 'https://imgak.mmtcdn.com/mima/images/mobile/cantFind.webp'};
export const WHATSAPP_ICON = { uri: 'https://imgak.mmtcdn.com/mima/images/mobile/whatsApp.webp'};
export const BLUE_MESSAGE_ICON = { uri: 'https://imgak.mmtcdn.com/mima/images/mobile/message.webp'};
export const BLUE_SHARE_ICON = { uri: 'https://imgak.mmtcdn.com/mima/images/mobile/ShareBlue.webp'};
export const BLUE_COPY_LINK_ICON = { uri: 'https://imgak.mmtcdn.com/mima/images/mobile/copyLinkBlue.webp'};
export const MAIL_ICON = { uri: 'https://imgak.mmtcdn.com/mima/images/mobile/GcTyMail.webp'};
export const MESSAGE_ICON = { uri: 'https://imgak.mmtcdn.com/mima/images/mobile/GcTyMessage.webp'};
export const MMT_MAIL_ICON = { uri: 'https://imgak.mmtcdn.com/mima/images/mobile/GcTyMmtmail.webp'};
export const TRAVELLER_ICON = { uri: 'https://imgak.mmtcdn.com/mima/images/mobile/TravellerIcon.webp'};
export const CALL_ICON = { uri: 'https://imgak.mmtcdn.com/mima/images/mobile/GcTyCall.webp'};
export const VIEW_MORE_ARROW_DOWN = {uri: 'https://imgak.mmtcdn.com/mima/images/mobile/gcViewMoredownArrow.webp'}

/* Common Images */
export const WHITE_CROSS_ICON = require('@mmt/legacy-assets/src/whiteCross.webp');
export const WHITE_LEFT_ARROW_ICON = require('@mmt/legacy-assets/src/back-white.webp');
export const BLUE_COPY_TEXT_ICON = { uri: 'https://imgak.mmtcdn.com/mima/images/mobile/copyTextIcon.webp'};
export const ORDER_TRACKING_INFO_ICON = { uri: 'https://imgak.mmtcdn.com/mima/images/mobile/orderTrackIcon.webp'};

export const giftCardIcon = getImagePath('v5giftCardIcon.webp');
