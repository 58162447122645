import React from 'react';
import PropTypes from 'prop-types';
import {View} from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss'
import {getActionButton, handleClickEvent, isNotNullAndEmpty} from '../utils/HotelBookingDetailUtil';
import HotelBookingDetailsConstant from '../HotelBookingDetailsConstant';
import HotelBookingTrackingHelper from '../../HotelBookingTrackingHelper';

class OldExchangedBookingCard extends React.Component {
  render() {
    const hotelDetailResponse = this.props.response;
    if (!hotelDetailResponse.isExchangedOld) {
      return null;
    }
    return (
      <View style={[AtomicCss.marginBottom12]}>
        {this.props.card && isNotNullAndEmpty(this.props.card.actionList) &&
          getActionButton(this.props.card, this.props.response, this.handleClick)}
      </View>
    );
  }

    handleClick = (action) => {
      if (action.actionFamily === HotelBookingDetailsConstant.ACTION_VIEW_SEE_UPDATED_BOOKING) {
        const clickEvent = `MI_HTL_${action.actionFamily}`;
        this.props.clickHandler(this.props.response.linkedExchangedBookingID);
        HotelBookingTrackingHelper.trackClickEvent(this.props.pageName, clickEvent, this.props.response);
      } else {
        handleClickEvent(this.props.card, action, this.props.response, this.props.pageName);
      }
    };
}

OldExchangedBookingCard.propTypes = {
  response: PropTypes.object.isRequired,
  card: PropTypes.object.isRequired,
  clickHandler: PropTypes.func.isRequired,
  pageName: PropTypes.string.isRequired
};

export default OldExchangedBookingCard;
