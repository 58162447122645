export const FORM_DATA_TYPE_NEW = {
  TEXT: 'text',
  DATE: 'date',
  RADIO: 'radio',
  MULTI: 'multiselect',
  GROUP: 'group',
  TEXTAREA: 'textarea',
  SELECT: 'select',
  DROPDOWN: 'dropdown'
};
export const STATUS_CODE_SUCCESS = 0;

// Use this for tracking
export const TRACKING_PAGENAME = {
  LANDING_NEW: 'mob:funnel:visa:landing_new',
  LANDING_NEW_ERROR: 'mob:funnel:visa:landing_new_err',
  LANDING: 'mob:funnel:visa:landing',
  LANDING_ERROR: 'mob:funnel:visa:landing_err',
  DESTINATION_PICKER: 'mob:funnel:visa:destination_picker',
  PROGRESSIVE_SEARCH: 'mob:funnel:visa:search_widget',
  TYPE_OF_VISA: 'mob:funnel:visa:type_of_Visa',
  TYPE_OF_VISA_ERROR: 'mob:funnel:visa:type_of_Visa_err',
  PRICE_BREAK_UP: 'mob:funnel:visa:price_break_up',
  DOCUMENT_REQUIRED: 'mob:funnel:visa:documents_required',
  DOCUMENT_REQUIRED_ERROR: 'mob:funnel:visa:documents_required_err',
  DOCUMENT_VERIFICATION: 'mob:funnel:visa:document_verification',
  DOCUMENT_VERIFICATION_ERROR: 'mob:funnel:visa:document_verification_err',
  DOC_VIEWER: 'mob:funnel:visa:document_viewer',
  ALL_FAQS: 'mob:funnel:visa:FAQ',
  EDIT_TRAVELLERS: 'mob:funnel:visa:edit_travellers',
  UPLOAD_ETICKET: 'mob:funnel:visa:ticket_upload',
  NEXT_TRAVELLER: 'mob:funnel:visa:next_traveller',
  REVIEW: 'mob:funnel:visa:review',
  REVIEW_ERROR: 'mob:funnel:visa:review_err',
  APPLICATION_FORM: 'mob:funnel:visa:application_form',
  APPLICATION_FORM_PREFILLED: 'mob:funnel:visa:application_form_prefilled',
  PASSENGER_DOCS: 'mob:funnel:visa:passenger_docs',
  THANK_YOU: 'mob:funnel:visa:thankyou',
  THANK_YOU_ERROR: 'mob:funnel:visa:thankyou_error'
};

export const PAGENAME = {
  LANDINGNEW: 'landingNew',
  LANDING: 'landing',
  PROGRESSIVE_SEARCH: 'search widget',
  TYPE_OF_VISA: 'type of Visa',
  DOCUMENT_REQUIRED: 'documents required',
  DOCUMENT_VERIFICATION: 'document verification',
  DOC_VIEWER: 'document viewer',
  ALL_FAQS: 'FAQ',
  EDIT_TRAVELLERS: 'edit travellers',
  UPLOAD_ETICKET: 'ticket upload',
  NEXT_TRAVELLER: 'next traveller',
  REVIEW: 'review',
  APPLICATION_FORM: 'application form',
  PASSENGER_DOCS: 'passenger Docs'
};

export const INT_MAX_VAL = 2147483646;

export const PARENTCURLYBRACES = '{parent}';

export const PROMPT_OVERLAY_POSITIVE = 'yes';

export const PROMPT_OVERLAY_NEGETIVE = 'no';

export const PASSPORT_DATA_VERIFY_TEXT = 'Your passport is the only source of truth. Enter the information exactly as mentioned in your current passport.';

export const DEPENDENTS = 'dependents';

export const DAY_SLASH_INDEX = 2;

export const MONTH_SLASH_INDEX = 5;

export const DATE_LENGTH = 10;

export const PASSENGER_BOOKING_STATUS = {
  PASSENGER_INITIATED: 0,
  DOCUMENTS_PENDING: 1,
  DOCUMENTS_UPLOADED: 2,
  DOCUMENTS_VERIFIED: 3,
  DOCUMENTS_VERFICATION_COMPLETE: 4
};

export const DOC_OP = {
  UPLOAD: 'UPLOAD',
  VERIFY: 'VERIFY'
};

export const PageErrorData = {
  LANDING:
  {
    title: 'Uh oh!',
    subTitle: 'It looks like our servers took too long.',
    suggestion: 'Try refreshing the page'
  },
  TYPE_OF_VISA:
  {
    title: 'Uh oh!',
    subTitle: 'Visa isn’t available for the duration selected. Please re-check your travel dates',
    suggestion: 'Try refreshing the page'
  },
  DOCUMENT_REQUIRED:
  {
    title: 'Uh oh!',
    subTitle: 'It looks like our servers took too long.',
    suggestion: 'Try refreshing the page'
  },
  REVIEW:
  {
    title: 'Uh oh!',
    subTitle: 'It looks like our servers took too long.',
    suggestion: 'Try refreshing the page'
  },
  PASSENGER_DOCS:
  {
    title: 'Uh oh!',
    subTitle: 'It looks like our servers took too long.',
    suggestion: 'Try refreshing the page'
  },
  VERIFY_DOCS:
  {
    title: 'Uh oh!',
    subTitle: 'It looks like our servers took too long.',
    suggestion: 'Try refreshing the page'
  },
  PREVIEW_FORM:
  {
    title: 'Uh oh!',
    subTitle: 'Unable to submit application form. Try again',
    suggestion: 'Try refreshing the page'
  }
};


export const ViewState = {
  LOADING: 'loading',
  SHOW_DETAIL: 'detail',
  NO_INTERNET: 'no_internet',
  DEFAULT: 'default',
  ERROR: 'error',
  LOADING_DROPDOWN_DATA: 'loading_dropdown_data',
  SHOW_SELECTOR: 'show_selector',
  NO_INTERNET_WHILE_SELECTING_DROPDOWN: 'no_internet_while_selecting_dropdown'
};

export const TRAVELLER = 'traveller';

export const DEFAULT_PRIMARY_NAME = '';

export const DATE_FORMAT_DISPLAY = 'DD MMM \'YY';
