import React from 'react';
import PropTypes from 'prop-types';
import { Text, TouchableOpacity, View } from 'react-native';
import styles from '../ManagerView/managerViewCss';

const TabsHeader = (props) => (
  <View style={styles.tabs}>
    {props.data.map((item, index) => {
      const activeStyle = index === props.activeTab ? 'Active' : '';
      const lstItem = index === props.data.length - 1 ? [styles.tabLast] : '';
      return (
        <TouchableOpacity
          key={index}
          activeOpacity={0.9}
          onPress={() => props.handleTab(index)}
          style={[styles.tab, styles.tabFirst, styles[`tab${activeStyle}`], lstItem]}
        >
          <Text style={[styles.tabTitleSm, styles[`tabTitleSm${activeStyle}`]]}>
            {item.subTitle}
          </Text>
          <Text style={[styles.tabTitle, styles[`tabTitle${activeStyle}`]]}>{item.title}</Text>
          {!lstItem && <View style={styles.line} />}
        </TouchableOpacity>
      );
    })}
  </View>
);

TabsHeader.propTypes = {
  data: PropTypes.array.isRequired,
  activeTab: PropTypes.number.isRequired,
};

export default TabsHeader;
