import React from 'react';
import Actions from '../../../navigation/postSalesNavigation';
import {
  Platform,
  View,
  DeviceEventEmitter,
  Linking,
  Animated,
  BackHandler,
  Easing,
} from 'react-native';
import ReactNative from 'react-native';
import { isEmpty, isNil } from 'lodash';
import BasePage from '../../../Common/PostSalesBasePage';
import { SPECIAL_CLAIM_CONST, VOID_DGCA_CONSTANTS } from './FlightBookingDetailsConstant';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import SimpleHeader from '@mmt/legacy-commons/Common/Components/Header/SimpleHeader';
import NoInternetView from '@mmt/legacy-commons/Common/Components/Error/NoInternetView';
import styles from './css/FlightBookingDetailsCss';
import {
  DEEP_LINK_PAGE,
  FLIGHT_CANCELLATION_TYPE,
  NEED_MORE_HELP,
  NEED_HELP_PAGE,
  LOBNAMES,
  AUTH_FAILED_MESSAGE,
  ANCILLARY_DEPENDENT_DEEPLINK,
} from '../../../PostSalesConstant';
import styles1 from './css/OneWayOneLegCss';
import stylesOverlay from './components/SecureYourTripCard/SecureYourTripOverlay/SecureYourTripOverlayCss';
import TopCard from './components/StatusCard/TopCard';
import CompleteBooking from './components/CompleteBooking';
import ReleaseHold from './components/ReleaseHoldModal';
import ContactUsPopupComp from './components/StatusCard/ContactUsPopup';
import TopStickyCard from './components/StatusCard/TopStickyCard';
import FlightBookingDetailsConstant, {
  EventEmitterConst,
  TopCardType,
  TOP_CARD_BOOKING_STATUS,
  RefNameConst,
} from './FlightBookingDetailsConstant';
import ErrorPage from './components/Error/ErrorPage';
import * as FlightBookingUtil from '../../FlightBookingUtil';
import { getEmailId, getPrimaryContactNo } from '../../FlightBookingUtil';
import FlightBookingModule from '@mmt/legacy-commons/Native/FlightBookingModule';
import {
  isLostBookingData,
  islangValidationRequired,
  verifyBookingLang,
  shouldShowCsatPopupCard,
} from '../../../utils/PostSaleUtil';
import OverlayMessage from '../../../Common/OverlayMessage';
import FlightBookingTrackingHelper from './helper/FlightBookingTrackingHelper';

/** **** ACME imports START **** */
/*eslint-disable */
import ACMEHolidayGroupingView from '@mmt/holidays/src/Grouping/Components/ACMEHolidayGroupingView';
import { AFFILIATE_SOURCE } from '@mmt/acme/src/Constants';
/*eslint-enable */
import SecureYourTripOverlay from './components/SecureYourTripCard/SecureYourTripOverlay';
import CancellationInfoOverlay from './components/CancellationInfoComp';
import AarogyaSetuCard from './components/AarogyaSetuCard/AarogyaSetuCard';
import DateChangeBottomOverlay from './components/BottomOverlay/DateChangeBottomOverlay';
import CommonOverlayMessage from '../../../Common/CommonOverlayMessage';
import CommonOverlayMessageStyles from '../../../Common/CommonOverlayMessage/OverlayMessageCss';
import Overlay from './components/Overlay';
import NameChangeBottomSheet from '../NameChange/components/NameChangePopup';
import CheckListOverlay from './components/CheckListSafeTravel/CheckListOverlay';
import WebCheckInOverlay from './components/WebCheckIn/WebCheckInOverlay';
import SelectFlights from '../../../Holiday/Components/SelectFlights';
import NeedMoreHelpOverlay from '../../../Common/NeedMoreHelpOverlay';
import LostIDBookingDetailPage from '../../../Common/LostIdDetailPage';
import ReqstScheduleBottomInfoCard from '../../../Common/NeedMoreHelpOverlay/ReqstSchedulBottomInfoCard';
import { fetchUrlParam, isHelpingHandEnabled } from '../../../Common/commonUtil';
import { BAGGAGE, MEALS, SEATS } from '../AncillaryModule/constants';
/** **** ACME imports END **** */
import TNCFullPageOverlay from './components/FareLock/TNCFullPageOverlay';
import { _setFltTrackData } from '../../FlightOmnitureTrackerUtils';
import CompleteYourTrip from './components/CompleteYourTripCard';
import AddSeatMealBottomOverlay from './components/BottomOverlay/addSeatMealBottomOverlay/index';

import { BACK_WHITE_ICON, HEADER_BACK_ICON } from '../../FlightImageConstants';
import ProgressView from '../../../Common/ProgressView/ProgressView';
import FareServices from './components/FareServices';
import SpecialClaimBottomSheet from '../../cancellation/SpecialClaimNew/SpecialClaimBottomSheet';
import { getStaticData } from '../../../staticData/staticData';
import DownloadInvoiceBottomSheet from './components/DownloadInvoice';
import { isEnglishLangSelected } from '@mmt/legacy-commons/AppState/AppUserConfig';
import FIREBASE_CONFIG from '../../../firebase/const';
import PostsaleFirebaseApp from '../../../firebase/postsalesFirebase';
import { extractLastMinutePnrs } from '../../cancellation/LastMinuteCancellation/LastMinuteCancellationAdapter';
import CustomerSupportFloatingCard from '../../../Common/CustomerSupportCard/CustomerSupportFloatingCard';
import { scrollHelper } from '../../../Common/CustomerSupportCard/CustomerSupportCardHelper';
import { RuleTraceConst } from '../../../Common/CustomerSupportCard/CustomerSupportCardConst';
import { getCustomerSupportCardData } from '../../../Common/CustomerSupportCard/api';
import FeedbackBottomSticky from '../../../Common/CsatFeedbackCard/FeedbackBottomSticky';
import {
  getCardLists,
  getRefundCard,
  getInitialFltDetailState,
} from './data/bookingDetailsAdapter';
import TimerComponent from './components/TimerComponent';
import NewCommonOverlay from '../../../Common/NewCommonOverlay';
import {
  downloadAwsDoc,
  openThankYouPageAfterPayment,
  openInsuranceThanku,
  getFlightLayoutValue,
  handleMomentumToScrollEndUtil,
  onRefundBreakupClickHandler,
  refundEventTracker,
  externalLinkHandler,
  checkListClickHandler,
  nameChangePopupClickHandler,
  specialRequestCardClickHandler,
  baggageProtectCardClickHandler,
  openDigitTermsNCondPage,
  getErrorPageSubText,
  getErrorBtnObj,
  trackErrorEvent,
  getAcmeCity,
  onCancellationButtonClicked,
  getActiveSegmentForAncillary,
  displayWebCheckInOverlay,
  getSelectedSegmentGroupView,
  openPolicyPage,
  openDateChange,
  openSpecialClaimSelectPaxPage,
  continueClickHandler,
  dateChangeCardClkHandler,
  getTopStatusCardArrow,
  getLobName,
  getIntialisedState,
  shouldShowCsatPopup,
  isCancellationApplicable,
  getAddSeatMealPopupRendered,
  setAddSeatMealPopupRendered,
  getCancellationCardNormalMsgObj,
  cancellationInfoJson,
  getPriceBreakUpDatalist,
} from './utils/cardUtil';
import { isAnyAncillayNotAdded } from './utils/ancillaryUtil';
import {
  fetchFlightBookingDetails,
  fetchCompleteYourTripDetail,
  fetchFlightAncillaryDetails,
  fetchFlightBookingUniversalSearchDetails,
  fetchLastMinCancellationErrorData,
  getCsatControllerDataApi,
} from './data/api';
import CancelYourBookingOverlay from './components/FareLock/CancelYourBookingCard';
import FlightBookingCancellation from '../Cancellation/components/FlightBookingCancellation';
import {
  isNotPendingBooking,
  isPendingPaymentCardPresent,
} from 'core-ui-lib/flight/common/FlightBookingDetails/utils/helper';
import TCSBottomOverlay from '../../../Common/TCSBottomOverlay/TCSBottomOverlay';
import CommonBottomOverlayMessage from '../../../Common/CommonOverlayMessage/CommonBottomOverlayMessage';
import CustomerBookingConfirm from '../../../Common/CustomerBookingConfirm';
import AutoCompleteSearchPopup from './components/SearchBar/AutoCompleteSearchPopup';
import { getDataFromStorage, setDataInStorage } from '../../../utils/localstorage';
import RequestWheelChairBottomOverlay from './components/WheelChairPopup';
import RequestCtripODCViewMoreBottomOverlay from './components/ctripODCViewMorePopUp';
import AuthErrorView from 'apps/post-sales/src/Common/AuthError/AuthErrorView';
import { showShortToast } from 'packages/legacy-commons/Common/Components/Toast';

export default class FlightBookingDetails extends BasePage {
  static navigationOptions = {
    header: null,
  };

  constructor(props) {
    super(props, 'flightBookingDetail');
    this.bookingId = props.BOOKING_ID || props.bookingId;
    this.mobileNumber = '';
    this.deepLinkPageData = props.deepLinkData ? JSON.parse(props.deepLinkData) : undefined;
    this.pageData = props.pageData ? JSON.parse(props.pageData) : undefined;
    this.isLostBooking = isLostBookingData(this.pageData);
    this.isIntl = false;
    this.flightFinalLayout = 0;
    this.addSeatMealPopupRendered = false;
    this.firebaseonValueListener = null;
    this.isSurveyApiCalled = false;
    this.lastMinuteCancellationRef = React.createRef();
    this.segmentSelected = 0;
    this.langValidationRequired = islangValidationRequired(this.pageData);
    this.isCsatInlineCardEnabled = !shouldShowCsatPopupCard();
    // width of the card decreases on scroll. Initial value is 146
    this.customerSupportCardWidth = new Animated.Value(146);
    // width of the text decreases on scroll. Initial value is 1
    this.customerSupportTextWidth = new Animated.Value(1);
    (this.uniqueCrId = Date.now().toString(36) + Math.random().toString(36).substr(2)),
      (this.state = getInitialFltDetailState(this.isLostBooking));
    //Resetting the Flight track data
    _setFltTrackData(null);
    this.downloadData = {};
    this.triggerName = null;
  }

  componentWillMount() {
    if (DeviceEventEmitter) {
      DeviceEventEmitter.addListener(
        'flight_booking_details_datechange_thankyou_page',
        this.openModificationThankYouPage,
      );
      DeviceEventEmitter.addListener(
        'flight_booking_details_insurance_thankyou_page',
        this.openInsuranceThankuPage,
      );
      DeviceEventEmitter.addListener(
        'flight_booking_details_force_refresh',
        this.forceReloadFlightDetails,
      );
      DeviceEventEmitter.addListener(
        'flight_booking_details_storage_permission',
        this.checkPermission,
      );
    }
  }

  componentDidMount() {
    BackHandler.addEventListener('hardwareBackPress', this.onBackPressHandler);

    if (Platform.OS === 'ios') {
      this.props.newNavigation?.addListener('beforeRemove', this.preventSwipeBack);
    }

    if (!this.isLostBooking && !this.langValidationRequired) {
      this.getData();
    }
    this.addSeatMealPopupRendered = getAddSeatMealPopupRendered(this.bookingId);
    if (this.langValidationRequired) {
      this.validateBookingLang();
    }
    DeviceEventEmitter.addListener(
      EventEmitterConst.CTA_CLICKED_EVENT,
      () => {
        this.setState({
          isCtaClicked: true,
        });
      },
      this,
    );
    this.setCustomerSupportCardData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      (this.props.BOOKING_ID && this.bookingId !== this.props.BOOKING_ID) ||
      (prevProps.refresh !== this.props.refresh && this.props.refresh.isRefresh === true)
    ) {
      this.reloadWithNewBookingId(this.props.BOOKING_ID);
    } else if (this.deepLinkPageData && this.state.responseJson) {
      if (ANCILLARY_DEPENDENT_DEEPLINK.includes(this.deepLinkPageData.deepLinkPage)) {
        if (
          this.state.segmentAncillaryDetails &&
          prevState.segmentAncillaryDetails !== this.state.segmentAncillaryDetails
        ) {
          this.openDeepLink(this.state.responseJson, this.deepLinkPageData.deepLinkPage);
        }
      } else {
        this.openDeepLink(this.state.responseJson, this.deepLinkPageData.deepLinkPage);
      }
    } else {
      /* to scroll to a particular component */
      if (
        this.props.refElm &&
        this.state.responseJson &&
        this.insuranceViewRef &&
        prevProps.refElm &&
        prevProps.refElm !== prevState.refElm
      ) {
        const elmToScroll = this.fetchRefAsPerSectionToShow(this.props.refElm);
        this.scrollToItem(elmToScroll);
      }
    }
  }

  componentWillUnmount() {
    BackHandler.removeEventListener('hardwareBackPress', this.onBackPressHandler);

    if (Platform.OS === 'ios') {
      this.props.newNavigation?.removeListener('beforeRemove');
    }
    if (DeviceEventEmitter) {
      DeviceEventEmitter.removeAllListeners('flight_booking_details_datechange_thankyou_page');
      DeviceEventEmitter.removeAllListeners('flight_booking_details_insurance_thankyou_page');
      DeviceEventEmitter.removeAllListeners('flight_booking_details_force_refresh');
      DeviceEventEmitter.removeAllListeners('flight_booking_details_storage_permission');
      DeviceEventEmitter.removeAllListeners(EventEmitterConst.CTA_CLICKED_EVENT);
    }
    const { current } = this.lastMinuteCancellationRef || {};
    const { removeOnValueListeners } = current || {};
    removeOnValueListeners && removeOnValueListeners();
  }

  downloadETicketWithMarkUp = (markup, isCancellationPolicySelected) => {
    FlightBookingUtil.flightDownloadCustomerTicket(
      FlightBookingDetailsConstant.FLIGHT_CUSTOMER_BOOKING,
      this.state.responseJson.bookingID,
      markup,
      isCancellationPolicySelected,
    );
  };

  setIsCsatFeedbackHandled = (val) => {
    this.setState({
      isCsatPopupHandled: val,
      openCsatPopup: false,
      csatPopupReason: '',
    });
  };

  setCustomerSupportCardData = async () => {
    const data = await getCustomerSupportCardData(
      this.bookingId,
      this.pageData?.uuid,
      'flightBookingDetails',
    );
    !!data &&
      this.setState({
        customerSupportCardData: data,
      });
  };

  validateBookingLang = async () => {
    await verifyBookingLang(this.bookingId, getLobName(this.props.holidayBookingApi));
    this.getData();
  };

  fetchRefAsPerSectionToShow = (refName) => {
    if (refName === '_insuranceRef') {
      return this.insuranceViewRef;
    }
  };
  setRef = (element, refName) => {
    switch (refName) {
      case RefNameConst.FLIGHT_DATE_CHANGE_REF: {
        this.dateChangeViewRef = element;
        break;
      }
      case RefNameConst.FLIGHT_WEB_CHECKIN_REF: {
        this.webCheckinViewRef = element;
        break;
      }
      case RefNameConst.FLIGHT_CANCELLATION_REF: {
        this.cancellationViewRef = element;
        break;
      }
    }
  };
  setScrollViewRef = (element) => {
    this.scrollViewRef = element;
  };
  setInsuranceViewRef = (element) => {
    this.insuranceViewRef = element;
  };
  scrollToItem = (refToShow) => {
    refToShow.measureLayout(ReactNative.findNodeHandle(this.scrollViewRef), (x, y) => {
      this.scrollViewRef.scrollTo({
        x: 0,
        y,
        animated: true,
      });
      this.setState({
        refElm: this.props.refElm,
      });
    });
  };

  setTimeSpentInPage = (val) => {
    this.setState({
      isTimeSpentMoreThanSpecified: val,
    });
  };

  initLastMinuteFirebaseDatabase = async (response) => {
    try {
      const { config, basePath, tokenKey } = FIREBASE_CONFIG.LAST_MINUTE_CANCELLATION;
      const { firebaseTokenList = {} } = response || {};
      this.lastMinuteCancellationRef.current = new PostsaleFirebaseApp(
        config,
        tokenKey,
        firebaseTokenList,
        this.bookingId,
      );
      const { isInstanceAuthenticated, errorData } =
        await this.lastMinuteCancellationRef.current.init();
      const path = basePath + `${this.bookingId}`;
      isInstanceAuthenticated &&
        this.lastMinuteCancellationRef.current.onValue(path, (data) => {
          if (!isNil(data)) {
            this.setState({
              tentativeWindowBookingStatus: { ...data },
            });
            const segmentWiseInfo = response && response.baggageDetailList;
            if (segmentWiseInfo) {
              const { hasFailedSegmentData, failedSegmentData, successPnr } = extractLastMinutePnrs(
                segmentWiseInfo,
                data,
              );

              if (!isEmpty(hasFailedSegmentData) && !isEmpty(failedSegmentData)) {
                this.fetchErrorData(failedSegmentData, successPnr);
              }
            }
          }
        });
      if (errorData) {
        this.trackError(errorData);
        console.log('@Firebase Error: ', errorData);
      }
    } catch (err) {
      this.trackError(err);
    }
  };

  fetchErrorData = async (data = [], successPnr) => {
    const errorDataResponse = await fetchLastMinCancellationErrorData(
      data,
      successPnr,
      this.bookingId,
      this.state.responseJson,
      this.props.holidayBookingApi,
      this.trackError,
    );
    if (errorDataResponse && errorDataResponse.isNetworkAvailable) {
      return;
    } else if (errorDataResponse && !errorDataResponse.error) {
      this.setState((prevState) => ({
        errorPageData: {
          ...prevState.errorPageData,
          ...errorDataResponse.errorPageData,
        },
      }));
    }
  };

  reloadWithNewBookingId(bookingId, noReset) {
    if (this.isLostBooking) {
      this.setState({
        refreshLostDetail: {},
      });
      return;
    }
    const initialStateObject = this.getInitialState(bookingId);
    !noReset &&
      this.setState({
        ...initialStateObject,
      });
    this.getData();
  }

  addSeatHandler = () => {
    Actions.openAncillary({
      pageKey: 'selectSeats',
      activeSegment:
        this.state.responseJson.newFlightDetails.segmentGroupDetailList[0].segmentDetails[0]
          .segmentLineNo,
      holidayBooking: this.props.holidayBookingApi,
      holidayData: this.props.holidayData,
      flightDetailResponse: this.props.flightDetailResponse,
    });
    FlightBookingTrackingHelper.trackClickEvent(
      'Details',
      'odc_seatmeal_popup_addnowclicked',
      this.state.responseJson,
    );
  };

  shouldShowAddSeatMealPopup = () => {
    return !this.addSeatMealPopupRendered && this.shouldShowAddSeatMealStrip();
  };

  closeAddSeatMealPopUp = () => {
    FlightBookingTrackingHelper.trackClickEvent(
      'Details',
      'odc_seatmeal_popup_close',
      this.state.responseJson,
    );
    this.setState({
      showAddSeatMealPopup: false,
    });
  };

  getInitialState(bookingId) {
    this.bookingId = bookingId;
    this.mobileNumber = '';
    this.isIntl = false;
    return getIntialisedState();
  }

  render() {
    const staticData = getStaticData();
    const { emailid, mobile, releaseHold } = this.state.responseJson || {};
    const { contactUs } = (this.state.responseJson && this.state.responseJson.topStatusCard) || {};
    const priceBreakupData = getPriceBreakUpDatalist(this.state.responseJson);
    const ctaSearchErrorMessage = 'This operation is not currently supported for your booking';
    const { wheelChairMap } = this.state.ancillaryResponseJson || {};
    return (
      <View style={styles.pageWrapper}>
        <TimerComponent
          seTimeSpentMoreThanSpecified={this.setTimeSpentInPage}
          defaultTime={this.state.timeInSecsForCsatPopup}
        />
        {this.state.viewState === ViewState.LOADING && this.renderProgressView()}
        {this.state.viewState === ViewState.NO_INTERNET && this.renderNoNetworkView()}
        {this.state.viewState === ViewState.ERROR && this.renderErrorView()}
        {this.state.viewState === ViewState.AUTH_ERROR && this.renderAuthErrorView()}
        {this.state.viewState === ViewState.SHOW_DETAIL && this.renderPage()}
        {this.state.specialClaimSelectedItem && (
          <OverlayMessage
            popUpOutsideClick={this.dismissRaiseClaimPopup}
            leftBtnClick={() => {
              this.dismissRaiseClaimPopup(true);
            }}
            rightBtnClick={this.raiseClaimContinueClicked}
            title={this.state.specialClaimSelectedItem.popupHeaderTxt}
            leftBtnTxt={staticData.noNotNowText}
            rightBtnTxt={staticData.yesProceedText}
            content={this.state.specialClaimSelectedItem.popupTxt}
          />
        )}
        {this.state.showDateChangeOverlay && (
          <OverlayMessage
            popUpOutsideClick={this.dismissDateChangePopup}
            leftBtnClick={this.dismissDateChangePopup}
            rightBtnClick={this.dismissDateChangePopup}
            title={staticData.dateChangeNotSupportedText}
            leftBtnTxt={staticData.goBackText}
            rightBtnTxt={staticData.okayText}
            content={this.state.responseJson.newDateChangeEnabledMessage}
          />
        )}
        {this.state.showDateChangeSubmittedOverlay && (
          <CommonOverlayMessage
            handleBottomOverlay={this.dateChangePopupClose}
            content={
              <DateChangeBottomOverlay
                data={
                  this.state.responseJson.requestedDateChangeCard[
                    this.state.showDateChangeSubmittedOverlayIndex
                  ]
                }
                rightBtnTxt={staticData.okayGotItText}
                handleBottomOverlay={this.dateChangePopupClose}
              />
            }
          />
        )}
        {this.state.showNameChangeOverlay &&
          (this.state.responseJson.nameCorrectionDetails?.passengerList ? (
            <NameChangeBottomSheet
              onClose={this.nameChangePopupClose}
              data={this.state.responseJson.nameCorrectionDetails}
              onContinueClicked={this.nameChangePopupProceedClick}
              responseJson={this.state.responseJson}
            />
          ) : (
            showShortToast('Name change not allowed for this booking')
          ))}

        {this.state.showChecklistOverlay && (
          <CommonOverlayMessage
            handleBottomOverlay={() => this.toggleChecklistOverlay(false)}
            content={
              <CheckListOverlay
                handleBottomOverlay={() => this.toggleChecklistOverlay(false)}
                checkList={this.state.responseJson.checkList}
                checklistActionHandler={this.checklistActionHandler}
              />
            }
          />
        )}

        {this.state.bottomOverlay === FlightBookingDetailsConstant.ACTION_DOWNLOAD_VOUCHER &&
          priceBreakupData && (
            <CommonBottomOverlayMessage
              overlayWrapperStyle={styles.overlayWrapperStyle}
              overlayContentStyle={[styles.OverlayContent, { bottom: this.state.overlayPosition }]}
              content={
                <CustomerBookingConfirm
                  handleClick={this.handleCloseBottomOverlay}
                  {...priceBreakupData}
                  {...staticData.customerConfirmPopupText}
                  downloadEticket={this.downloadETicketWithMarkUp}
                  notCancellationpolicy={true}
                  trackHelper={this.trackHelperCustomerConfirmPopUp}
                />
              }
              dismiss={this.handleCloseBottomOverlay}
            />
          )}

        {this.state.showWebcheckinOverlay && !this.props.holidayBookingApi && (
          <CommonOverlayMessage
            handleBottomOverlay={() => this.toggleWebcheckinOverlay(false)}
            content={
              <WebCheckInOverlay
                flightDetailResponse={this.state.responseJson}
                handleBottomOverlay={() => this.toggleWebcheckinOverlay(false)}
                overlayDesc={this.state.responseJson.webcheckInOverlayBanner.overlayDesc}
                overlayActionList={
                  this.state.responseJson.webcheckInOverlayBanner.overlayActionList
                }
                holidayBooking={this.props.holidayBookingApi}
                holidayData={this.props.holidayData}
              />
            }
          />
        )}
        {this.state.showWheelChairOverlay && (
          <CommonOverlayMessage
            handleBottomOverlay={() => this.toggleWheelChairOverlay(null)}
            content={
              <RequestWheelChairBottomOverlay
                segmentAncillaryDetail={this.state.segmentAncillaryDetails}
                flightDetailResponse={this.state.responseJson}
                activeSegment={this.state.wheelChairActiveSegment}
                wheelChairMap={wheelChairMap}
                reloadFlightDetails={() => this.reloadWithNewBookingId(this.bookingId, false)}
                toggleWheelChairOverlay={() => this.toggleWheelChairOverlay(null)}
              />
            }
          />
        )}
        {this.state.ctripODCViewMoreOverlay && (
          <CommonOverlayMessage
            handleBottomOverlay={this.toggleCtripODCViewMoreOverlay}
            content={
              <RequestCtripODCViewMoreBottomOverlay
                handleBottomOverlay={this.toggleCtripODCViewMoreOverlay}
                ctripODCdetail={this.state.responseJson.delayedOdcDetails}
              />
            }
          />
        )}
        {this.state.serviceGuranteeOverlay && (
          <CommonOverlayMessage
            isBorderRadius={true}
            handleBottomOverlay={this.toggleServiceGuaranteeOverlay}
            content={
              <ContactUsPopupComp
                ctripContact={contactUs}
                handleServiceGuranteePopup={this.toggleServiceGuaranteeOverlay}
              />
            }
          />
        )}
        {!isNotPendingBooking(this.props.responsejson) &&
          this.state.releaseholdOverlay &&
          this.state.responseJson.releaseHold && (
            <CommonOverlayMessage
              isBorderRadius={true}
              handleBottomOverlay={this.togglereleaseholdOverlay}
              content={
                <ReleaseHold
                  releaseHold={releaseHold}
                  togglereleaseholdOverlay={this.togglereleaseholdOverlay}
                />
              }
            />
          )}
        {this.state.helpingHandOverlay === true && (
          <NeedMoreHelpOverlay
            bookingId={this.state.responseJson ? this.state.responseJson.bookingID : ''}
            handleBottomOverlay={this.toggleHelpingHandOverlay}
            handleReload={this.handleReload}
            component={NEED_MORE_HELP.HELPING_HAND}
            pageName={NEED_HELP_PAGE.FLIGHT_BOOKING_DETAILS}
            hideLoader={true}
            trackEvent={this.trackHelpingHandEvents}
            lobName={LOBNAMES.FLIGHT}
          />
        )}
        {this.state.selectFlight && (
          <SelectFlights
            pageName={'SelectFlightOverlay'}
            flightDetailsType={this.state.responseJson.flightDetails}
            handleButtonClick={this.handleButtonClick}
            externalLinkHandler={externalLinkHandler}
            onBackPopUp={this.closeSelectFlights}
          />
        )}
        {this.state.callbackBottomSheet &&
          !this.state.bottomSheet &&
          this.state.viewState === ViewState.SHOW_DETAIL &&
          this.showCallbackBottomSheet()}
        {this.state.bottomSheet === 'TNCFullPage' && this.state.responseJson && (
          <Animated.View style={[styles.tnCListWrapper, { top: this.state.top }]}>
            <TNCFullPageOverlay
              handleBottomSheet={this.handleBottomSheet}
              tncData={
                !isEmpty(this.state.responseJson.fareLockTermsAndConditionsData)
                  ? this.state.responseJson.fareLockTermsAndConditionsData
                  : this.state.responseJson.holdbookingTermsAndConditionsData
              }
            />
          </Animated.View>
        )}
        {!!this.state.fareServiceOverlayData && (
          <CommonOverlayMessage
            handleBottomOverlay={() => this.setFareServiceOverlayData()}
            content={
              <FareServices
                handleBottomOverlay={this.setFareServiceOverlayData}
                {...this.state.fareServiceOverlayData}
              />
            }
          />
        )}
        {!!this.state.showAddSeatMealPopup && !isEmpty(this.state.odcAncillaryPopUpMessage) && (
          <CommonOverlayMessage
            handleBottomOverlay={() => this.closeAddSeatMealPopUp()}
            isBorderRadius
            contentStyle={{ top: 20 }}
            isTopRequired
            content={
              <AddSeatMealBottomOverlay
                handleBottomOverlay={this.closeAddSeatMealPopUp}
                onPressHandler={this.addSeatHandler}
                reponse={this.state.responseJson}
                odcAncillaryPopUpMessage={this.state.odcAncillaryPopUpMessage}
              />
            }
          />
        )}
        {this.state.showSpecialClaimBottomSheet.value && (
          <CommonOverlayMessage
            handleBottomOverlay={this.closeSpecialClaimPopUp}
            isBorderRadius={true}
            overlayContentStyle={[CommonOverlayMessageStyles.overlayContent, { paddingTop: 10 }]}
            content={
              <Animated.View style={{ bottom: this.state.bottomSheetAnimated }}>
                <SpecialClaimBottomSheet
                  bookingDetailsResponse={this.state.responseJson}
                  closeSpecialClaimPopUp={this.closeSpecialClaimPopUp}
                  cancellationButtonData={this.state.showSpecialClaimBottomSheet.data}
                  cancelCardActionItemClick={this.cancelCardActionItemClick}
                />
              </Animated.View>
            }
          />
        )}
        {this.state.showsecureCardDetail && (
          <CommonOverlayMessage
            overlayWrapperStyle={[stylesOverlay.overlayWrapper]}
            overlayContentStyle={[stylesOverlay.overlayContent]}
            content={
              <SecureYourTripOverlay
                insuranceDetailRes={this.state.insuranceDetailRes}
                handleBottomOverlay={this.toggleSecureList}
                bookingId={this.state.responseJson.bookingID}
                bookingDetailRes={this.state.responseJson}
                isCorporateBooking={this.state.responseJson.isCorporateBooking}
              />
            }
          />
        )}
        {this.state.showDownloadInvoiceOverlay && (
          <CommonOverlayMessage
            handleBottomOverlay={this.closeDownloadInvoicePopup}
            isBorderRadius={true}
            overlayContentStyle={styles.overlayContentStyle}
            overlayWrapperStyle={styles.overlayWrapperStyle}
            content={
              <DownloadInvoiceBottomSheet
                bookingId={this.bookingId}
                closeOverlay={this.closeDownloadInvoicePopup}
              />
            }
          />
        )}
        {this.state.showCancellationInfoOverlay.value && (
          <CommonOverlayMessage
            handleBottomOverlay={this.closeCancellationInfoOverlay}
            isBorderRadius={true}
            overlayContentStyle={styles.overlayContentStyle}
            overlayWrapperStyle={styles.overlayWrapperStyle}
            content={
              <CancellationInfoOverlay
                bookingId={this.bookingId}
                cancellationJson={cancellationInfoJson(
                  this.state.responseJson,
                  this.state.showCancellationInfoOverlay,
                )}
                isNameChangeCorrection={
                  this.state.showCancellationInfoOverlay?.data?.popupInfo === 'voidNameChangeInfo'
                }
                isSourceDateChange={
                  this.state.showCancellationInfoOverlay?.data?.popupInfo ===
                  VOID_DGCA_CONSTANTS.DGCA_VOID_DATE_CHANGE_INFO
                }
                closeOverlay={this.closeCancellationInfoOverlay}
                sourceButtonData={this.state.showCancellationInfoOverlay.data}
                cancelCardActionItemClick={this.cancelCardActionItemClick}
                dateChangeCardActionClick={this.dateChangeCardActionClick}
              />
            }
          />
        )}
        {this.state.freeCancellationConfirmOverlay &&
          this.state.responseJson?.holdBookingCancelCardData && (
            <CommonOverlayMessage
              handleBottomOverlay={this.toggleCancellationBookingFreeConfirmation}
              isBorderRadius={true}
              overlayContentStyle={styles.overlayContentStyle}
              overlayWrapperStyle={styles.overlayWrapperStyle}
              content={
                <CancelYourBookingOverlay
                  handleBottomOverlay={this.toggleCancellationBookingFreeConfirmation}
                  cancellationCardData={this.state.responseJson.holdBookingCancelCardData}
                />
              }
            />
          )}
        {this.state.showVisaGuaranteeOverlay &&
          !isEmpty(this.state.responseJson?.visaGuaranteeDetails?.claimPopupInfo) && (
            <CommonBottomOverlayMessage
              overlayWrapperStyle={styles.overlayWrapperStyle}
              overlayContentStyle={styles.overlayContentStyle}
              content={
                <TCSBottomOverlay
                  tcsPopupInfo={this.state.responseJson?.visaGuaranteeDetails?.claimPopupInfo}
                  onClose={this.closeVpgInfoOverlay}
                />
              }
              dismiss={this.closeVpgInfoOverlay}
            />
          )}
        {!!emailid && !!mobile && !this.isCsatInlineCardEnabled && this.state.openCsatPopup && (
          <FeedbackBottomSticky
            bookingId={this.bookingId}
            emailid={emailid}
            mobile={mobile}
            pageName={FlightBookingTrackingHelper.getPageName('Details', this.state.responseJson)}
            setIsFeedbackShared={this.setIsCsatFeedbackHandled}
            showPopup={this.state.openCsatPopup}
            showPopupReason={this.state.csatPopupReason}
          />
        )}
        {this.state.showAutoSearchPopup && (
          <CommonOverlayMessage
            overlayWrapperStyle={styles.overlayWrapperStyle}
            handleBottomOverlay={this.toggleAutoSearchPopup}
            noScrollPopupOnKeyboardOpen={true}
            content={
              <AutoCompleteSearchPopup
                response={this.state.universalSearchResponse}
                onBackPress={this.toggleAutoSearchPopup}
                onSelect={this.ctaSelectDeeplinkHandler}
                flightsResponse={this.state.responseJson}
              />
            }
          />
        )}
        {this.state.ctaSearchErrorMessage && (
          <OverlayMessage
            popUpOutsideClick={() =>
              this.setState({
                ctaSearchErrorMessage: false,
              })
            }
            leftBtnClick={() =>
              this.setState({
                ctaSearchErrorMessage: false,
              })
            }
            title={staticData.sorryText}
            leftBtnTxt={staticData.okayText}
            content={ctaSearchErrorMessage}
          />
        )}
      </View>
    );
  }

  setFareServiceOverlayData = (val = null) => {
    this.setState({ fareServiceOverlayData: val });
  };

  handleBottomSheet = (val, link, heading) => {
    if (link) {
      Actions.openPsWebView({
        headerText: heading || '',
        headerIcon: BACK_WHITE_ICON,
        url: link,
      });
    } else {
      this.setState(
        {
          bottomSheet: val,
        },
        () => {
          Animated.timing(this.state.top, {
            toValue: val ? 0 : 1100,
            timing: 1500,
          }).start();
        },
      );
    }
  };

  showCallbackBottomSheet = () => {
    return (
      <ReqstScheduleBottomInfoCard
        handleCallbackBottomSheet={this.hideCallbackBottomSheet}
        handleTime={this.handleReload}
        bookingId={this.bookingId}
        pageName={NEED_HELP_PAGE.FLIGHT_BOOKING_DETAILS}
        trackEvent={this.trackHelpingHandEvents}
      />
    );
  };

  checkPermission = (status) => {
    if (status.success && !this.triggerName) {
      downloadAwsDoc(this.downloadData, this.bookingId);
      this.downloadData = {};
    } else if (status.success && !!this.triggerName) {
      if (this.triggerName === FlightBookingDetailsConstant.DOWNLOAD_INVOICE_POPUP) {
        this.setState({
          showDownloadInvoiceOverlay: true,
        });
      } else {
        FlightBookingUtil.flightDownloadTicket(this.triggerName, this.bookingId);
      }
      this.triggerName = null;
    }
  };

  forceReloadFlightDetails = (payload) => {
    if (payload) {
      this.reloadWithNewBookingId(payload.bookingId);
    }
  };

  openModificationThankYouPage = (data) => {
    openThankYouPageAfterPayment(data, this.bookingId);
  };

  openInsuranceThankuPage = (data) => {
    openInsuranceThanku(data, this.uniqueCrId, this.state.responseJson, this.bookingId);
  };

  getData() {
    if (isEmpty(this.bookingId)) {
      this.setState({ viewState: ViewState.ERROR });
    } else {
      this.fetchJSONAsync();
      this.fetchAncillaryJSONAsync();
    }
  }

  getCsatControllerData = async (responseJson) => {
    if (!this.isSurveyApiCalled) {
      this.isSurveyApiCalled = true;
      const csatControllerData = await getCsatControllerDataApi(
        responseJson,
        this.bookingId,
        this.props.holidayBookingApi,
      );
      if (csatControllerData && csatControllerData.isNetworkAvailable) {
        return;
      } else if (csatControllerData && !csatControllerData.error) {
        const { userResponse } = csatControllerData || {};
        const { isWithinLast_01_Day, isWithinLast_07_Days, waitingTimeInSecs } = userResponse || {};
        this.setState({
          isCsatFeedbackSharedWithin1Day:
            typeof isWithinLast_01_Day === 'boolean' ? isWithinLast_01_Day : true,
          isCsatFeedbackSharedWithin7Days:
            typeof isWithinLast_07_Days === 'boolean' ? isWithinLast_07_Days : true,
          timeInSecsForCsatPopup: waitingTimeInSecs ? waitingTimeInSecs : 45,
        });
      } else {
        this.setState({
          isCsatFeedbackSharedWithin1Day: true,
          isCsatFeedbackSharedWithin7Days: true,
          timeInSecsForCsatPopup: 45,
        });
      }
    }
  };

  raiseClaimClicked = (item) => {
    this.setState({
      specialClaimSelectedItem: item,
    });
    FlightBookingTrackingHelper.trackClickEvent(
      'Details',
      `Flight_Sclaim_${item.scCode}`,
      this.state.responseJson,
    );
  };

  dismissRaiseClaimPopup = (track) => {
    this.setState({
      specialClaimSelectedItem: undefined,
    });
    if (track) {
      FlightBookingTrackingHelper.trackClickEvent(
        'Sclaim_Popup',
        'Sclaim_Popup_Not_Now',
        this.state.responseJson,
      );
    }
  };

  dismissDateChangePopup = () => {
    this.setState({
      showDateChangeOverlay: false,
    });
    FlightBookingTrackingHelper.trackClickEvent(
      'DateChange_Popup',
      'DateChange_Popup_Dismiss',
      this.state.responseJson,
    );
  };

  raiseClaimContinueClicked = () => {
    this.onContinueClicked(this.state.specialClaimSelectedItem);
    this.dismissRaiseClaimPopup(false);
    FlightBookingTrackingHelper.trackClickEvent(
      'Sclaim_Popup',
      'Sclaim_Popup_Proceed',
      this.state.responseJson,
    );
  };

  shouldShowAddSeatMealStrip = () => {
    const { responseJson, segmentAncillaryDetails } = this.state;
    if (!responseJson || !responseJson.newFlightDetails || !segmentAncillaryDetails) {
      return false;
    }
    const segmentGroupDetails = responseJson.newFlightDetails.segmentGroupDetailList;
    return (
      this.state.viewState === ViewState.SHOW_DETAIL &&
      responseJson.isCorporateBooking &&
      isAnyAncillayNotAdded(segmentAncillaryDetails, segmentGroupDetails)
    );
  };

  openVpgInfoOverlay = () => {
    this.setState({ showVisaGuaranteeOverlay: true });
  };

  closeVpgInfoOverlay = () => {
    this.setState({ showVisaGuaranteeOverlay: false });
  };

  openSpecialClaimPopUp = (data) => {
    this.setState(
      {
        showSpecialClaimBottomSheet: { value: true, data },
      },
      () => {
        Animated.timing(this.state.bottomSheetAnimated, {
          toValue: 0,
          duration: 200,
        }).start();
      },
    );
  };

  openCancellationInfo = (data) => {
    this.setState(
      {
        showCancellationInfoOverlay: { value: true, data },
      },
      () => {
        Animated.timing(this.state.bottomSheetAnimated, {
          toValue: 0,
          duration: 200,
        }).start();
      },
    );
  };
  toggleCancellationBookingFreeConfirmation = (isConfirmClicked) => {
    if (!this.state.freeCancellationConfirmOverlay) {
      FlightBookingTrackingHelper.trackClickEvent('Details', 'hold_myt_cancel_ctaclick');
    }
    this.setState(
      {
        freeCancellationConfirmOverlay: !this.state.freeCancellationConfirmOverlay,
      },
      () => {
        Animated.timing(this.state.bottomSheetAnimated, {
          toValue: 0,
          duration: 200,
        }).start();
      },
    );
    if (!isEmpty(isConfirmClicked?.trim) && isConfirmClicked) {
      this.setState({
        proceedCancellation: !this.state.proceedCancellation,
      });
    }
  };
  toggleAutoSearchPopup = () => {
    if (!this.state.showAutoSearchPopup) {
      FlightBookingTrackingHelper.trackingNewClickEvent(
        'Details',
        'search_bar_clicked',
        this.state.responseJson,
      );
    }
    this.setState({
      showAutoSearchPopup: !this.state.showAutoSearchPopup,
    });
  };
  closeCancellationInfoOverlay = () => {
    Animated.timing(this.state.bottomSheetAnimated, {
      toValue: 0,
      duration: 200,
    }).start();
    setTimeout(() => {
      this.setState({
        showCancellationInfoOverlay: { value: false, data: null },
      });
    }, 0);
  };

  closeSpecialClaimPopUp = () => {
    Animated.timing(this.state.bottomSheetAnimated, {
      toValue: -500,
      duration: 200,
    }).start();
    setTimeout(() => {
      this.setState({
        showSpecialClaimBottomSheet: { value: false, data: null },
      });
    }, 150);
  };

  getFlightLayout = (no) => {
    return getFlightLayoutValue(no, this.flightFinalLayout - 80);
  };

  closeDownloadInvoicePopup = () => {
    this.setState({
      showDownloadInvoiceOverlay: false,
    });
  };
  onBookingCancellationSuccessHandler = (refreshPage) => {
    this.setState({ proceedCancellation: false });
    this.reloadWithNewBookingId(this.bookingId, !refreshPage);
  };

  onDownloadEticketClicked = (isDownloadIconClicked) => {
    if (Platform.OS === 'ios') {
      FlightBookingUtil.flightDownloadTicket(
        FlightBookingDetailsConstant.FLIGHT_ETICKET,
        this.bookingId,
      );
    } else if (Platform.OS === 'android') {
      this.triggerName = FlightBookingDetailsConstant.FLIGHT_ETICKET;
      FlightBookingModule.checkStoragePermission();
    } else {
      FlightBookingModule.downloadTicket(
        FlightBookingDetailsConstant.FLIGHT_ETICKET,
        this.bookingId,
      );
    }
    FlightBookingTrackingHelper.trackClickEvent(
      'Details',
      `Flight_E-ticket_${isDownloadIconClicked ? 'top' : 'cta'}`,
      { ...this.state.responseJson },
    );
  };

  updateFlightLayout = (layoutYDetails, conditionCase, segmentKey) => {
    const { flightDetailsLayout, responseJson } = this.state;
    switch (conditionCase) {
      case FlightBookingDetailsConstant.FLIGHT_SEGMENT_PARENT_HEIGHT:
        this.setState((prevState) => ({
          flightDetailsLayout: {
            ...prevState.flightDetailsLayout,
            [segmentKey]: {
              ...prevState.flightDetailsLayout[segmentKey],
              [FlightBookingDetailsConstant.FLIGHT_SEGMENT_PARENT_HEIGHT]: layoutYDetails,
            },
          },
        }));
        break;
      case FlightBookingDetailsConstant.FLIGHT_SEGMENT_CHILD_HEIGHT:
        this.setState((prevState) => ({
          flightDetailsLayout: {
            ...prevState.flightDetailsLayout,
            [segmentKey]: {
              ...prevState.flightDetailsLayout[segmentKey],
              [FlightBookingDetailsConstant.FLIGHT_SEGMENT_CHILD_HEIGHT]: layoutYDetails,
            },
          },
        }));
        break;
      case FlightBookingDetailsConstant.FLIGHT_SEGMENT_TRAVELLER_HEIGHT:
        this.setState((prevState) => ({
          flightDetailsLayout: {
            ...prevState.flightDetailsLayout,
            [segmentKey]: {
              ...prevState.flightDetailsLayout[segmentKey],
              [FlightBookingDetailsConstant.FLIGHT_SEGMENT_TRAVELLER_HEIGHT]: layoutYDetails,
            },
          },
        }));
        break;
      case FlightBookingDetailsConstant.FLIGHT_DETAILS_HEAD_HEIGHT:
        this.setState((prevState) => ({
          flightDetailsLayout: {
            ...prevState.flightDetailsLayout,
            head: layoutYDetails,
          },
        }));
        break;
    }
    const mappedSegment = responseJson.newFlightDetails.segmentGroupDetailList
      .map((item, index) => {
        if (item.segmentGroupStatus && item.segmentGroupStatus.status === 1) {
          return index;
        } else {
          return undefined;
        }
      })
      .filter((item) => item !== undefined);
    if (!isEmpty(mappedSegment)) {
      const maxedConfirmedSegment = Math.max(...mappedSegment);
      const key = `SegmentCard${maxedConfirmedSegment}`;
      if (flightDetailsLayout[key]) {
        this.flightFinalLayout =
          flightDetailsLayout.head +
          flightDetailsLayout[key][FlightBookingDetailsConstant.FLIGHT_SEGMENT_PARENT_HEIGHT] +
          flightDetailsLayout[key][FlightBookingDetailsConstant.FLIGHT_SEGMENT_CHILD_HEIGHT] +
          flightDetailsLayout[key][FlightBookingDetailsConstant.FLIGHT_SEGMENT_TRAVELLER_HEIGHT];
      }
    }
  };

  handleMomentumScrollEnd = ({ nativeEvent }) => {
    handleMomentumToScrollEndUtil(nativeEvent, this.state.responseJson, this.bookingId);
  };

  renderPage() {
    const { responseJson, completeYouTripJson, bottomSheet, refreshLostDetail } = this.state;
    if (this.isLostBooking) {
      return (
        <LostIDBookingDetailPage
          bookingId={this.bookingId}
          lobName={LOBNAMES.FLIGHT}
          pageData={this.pageData}
          refreshLostDetail={refreshLostDetail}
          getRefundCard={({ response, cancellationDetailList, onRefundBreakupBtnClicked }) =>
            getRefundCard(
              cancellationDetailList,
              response,
              true,
              onRefundBreakupBtnClicked,
              this.openDeepLink,
            )
          }
        />
      );
    }
    if (this.state.proceedCancellation) {
      const cancellationRequest = {
        bookingId: this.bookingId,
        isFullCancellation: true,
        fullCancellationReason: 'HOLD_RELEASE',
        validateClaim: false,
        refundThrough: '',
        submitWithProof: '',
        isHoldBooking: true,
      };
      return (
        <FlightBookingCancellation
          cancellationRequest={cancellationRequest}
          onSuccess={() => this.onBookingCancellationSuccessHandler(true)}
          onError={() => this.onBookingCancellationSuccessHandler(false)}
        />
      );
    }
    let views = [];
    views = this.getMainPageViews();
    const staticData = getStaticData();
    const { stickyHeader } = this.props;
    const arrowStyle = getTopStatusCardArrow(this.state.responseJson.bookingState.state);
    const topCardColor = this.getTopCardColor(
      this.state.responseJson.bookingState,
      this.state.responseJson.topStatusCard,
    );
    const getTopStickyCard = (showOtherInfo) => {
      return (
        <TopStickyCard
          bookingId={this.bookingId}
          bookingStatus={this.state.responseJson.bookingState.state}
          response={this.state.responseJson}
          styleColours={topCardColor}
          arrowStyle={arrowStyle}
          onBackPress={this.goBack}
          showOtherInfo={showOtherInfo}
          onDownloadEticketClicked={this.onDownloadEticketClicked}
          topStatusCard={this.state.responseJson.topStatusCard}
        />
      );
    };
    const { showAarogyaSetuBanner, aarogyaSetuBannerData } = this.state.responseJson;
    const { paymentDetails, pendingPaymentCard } = this.state.responseJson || {};

    return (
      <>
        <Animated.ScrollView
          ref={this.setScrollViewRef}
          stickyHeaderIndices={[0]}
          style={styles1.cardWrapper}
          onMomentumScrollEnd={this.handleMomentumScrollEnd}
          onScroll={() =>
            scrollHelper(this.customerSupportCardWidth, this.customerSupportTextWidth)
          }
        >
          <View style={{ elevation: 3 }}>{getTopStickyCard(true)}</View>
          {stickyHeader ? (
            <View style={{ elevation: 3, zIndex: 10, marginTop: -60 }}>
              <TopCard
                bookingId={this.bookingId}
                bookingState={this.state.responseJson.bookingState}
                response={this.state.responseJson}
                releaseHold={this.state.responseJson.releaseHold}
                togglereleaseholdOverlay={this.togglereleaseholdOverlay}
                topStatusCard={this.state.responseJson.topStatusCard}
                toggleServiceGuaranteeOverlay={this.toggleServiceGuaranteeOverlay}
                emailId={getEmailId(this.state.responseJson.accountDetail.contactDetails)}
                styleColours={topCardColor}
                arrowStyle={arrowStyle}
                onBackPress={this.goBack}
                segmentAncillaryDetails={this.state.segmentAncillaryDetails}
                holidayBooking={this.props.holidayBookingApi}
                holidayData={this.props.holidayData}
                showAddSeatMealStrip={this.shouldShowAddSeatMealStrip()}
                odcAncillaryPopUpMessage={this.state.odcAncillaryPopUpMessage}
                onPaymentCardBtnClick={this.onPaymentCardBtnClick}
                getStickyHeader={getTopStickyCard}
                onDownloadEticketClicked={this.onDownloadEticketClicked}
                toggleAutoSearchPopup={this.toggleAutoSearchPopup}
                searchBarDetailResponse={this.state.universalSearchResponse}
              />
            </View>
          ) : null}
          {isPendingPaymentCardPresent(this.state.responseJson) &&
            paymentDetails &&
            pendingPaymentCard && (
              <CompleteBooking
                paymentDetails={paymentDetails}
                pendingPaymentCard={pendingPaymentCard}
              />
            )}
          {showAarogyaSetuBanner && (
            <AarogyaSetuCard aarogyaSetuBannerData={aarogyaSetuBannerData} />
          )}
          <View
            style={{ elevation: 1 }}
            onLayout={(event) =>
              this.updateFlightLayout(
                event.nativeEvent.layout.y,
                FlightBookingDetailsConstant.FLIGHT_DETAILS_HEAD_HEIGHT,
              )
            }
          >
            {views}
            {Platform.OS !== 'web' &&
              !this.state.responseJson.isCorporateBooking &&
              isEnglishLangSelected() &&
              this.state.acmeCity && (
                <ACMEHolidayGroupingView
                  title={staticData.viewAllText}
                  subTitle={staticData.moreText}
                  key="ACMEAffiliateRecommendations"
                  city={this.state.acmeCity}
                  source={AFFILIATE_SOURCE.FLIGHTS}
                  prevPage={`${AFFILIATE_SOURCE.FLIGHTS}_listing`}
                  pageName={
                    this.state.responseJson.isInternational
                      ? 'Mob:customer support:Intl Flight'
                      : 'Mob:customer support:Dom Flight'
                  }
                />
              )}
          </View>
        </Animated.ScrollView>
        {!bottomSheet && responseJson.isCYTEnabled && (
          <CompleteYourTrip
            scrollViewRef={this.scrollViewRef}
            bottomSheet={bottomSheet}
            responseJson={responseJson}
            completeYouTripJson={completeYouTripJson}
            openDeepLink={this.openDeepLink}
            getFlightLayout={this.getFlightLayout}
          />
        )}
        {this.state.customerSupportCardData?.ruleTrace === RuleTraceConst.FLOATING && (
          <CustomerSupportFloatingCard
            cardWidth={this.customerSupportCardWidth}
            dynamicTextWidth={this.customerSupportTextWidth}
            deepLinkHandler={this.openDeepLinkFromNeedHelp}
            pageName={FlightBookingTrackingHelper.getPageName('Details', this.state.responseJson)}
            cardData={this.state.responseJson?.floatingNeedHelpCard}
            bookingId={this.bookingId}
            uuid={this.state.responseJson?.uuid}
            isInternational={this.state.responseJson?.isInternational}
            productCode={LOBNAMES.FLIGHT}
          />
        )}
      </>
    );
  }

  getMainPageViews() {
    const staticData = getStaticData();
    const handlerFunctions = {
      toggleChecklistOverlay: this.toggleChecklistOverlay,
      checklistActionHandler: this.checklistActionHandler,
      onNewBookingBtnClicked: this.onNewBookingBtnClicked,
      dateChangeViewDetailsClick: this.dateChangeViewDetailsClick,
      openDigitTermsNCond: this.openDigitTermsNCond,
      setInsuranceViewRef: this.setInsuranceViewRef,
      baggageDetailsBtnClick: this.baggageProtectCardBtnClick,
      onPaymentCardBtnClick: this.onPaymentCardBtnClick,
      specialClaimActionClick: this.raiseClaimClicked,
      cancellationButtonClick: this.cancelCardActionItemClick,
      onContinueClicked: this.onContinueClicked,
      dateChangeButtonClick: this.dateChangeCardActionClick,
      onChangeInTravelPlanBtnClick: this.onChangeInTravelPlanBtnClick,
      specialRequestCardBtnClick: this.specialRequestCardBtnClick,
      toggleSecureList: this.toggleSecureList,
      handleBottomSheet: this.handleBottomSheet,
      openDeepLink: this.openDeepLink,
      updateFlightLayout: this.updateFlightLayout,
      setRef: this.setRef,
      onRefundBreakupBtnClickhandler: this.onRefundBreakupBtnClicked,
      setFareServiceOverlayData: this.setFareServiceOverlayData,
      toggleAutoSearchPopup: this.toggleAutoSearchPopup,
      toggleWheelChairOverlay: this.toggleWheelChairOverlay,
      toggleCtripODCViewMoreOverlay: this.toggleCtripODCViewMoreOverlay,
      openVpgInfoOverlay: this.openVpgInfoOverlay,
    };

    const paramsForCardLists = {
      flightDetailResponse: this.state.responseJson,
      staticData,
      props: this.props,
      handlerFunctions,
      customerSupportCardData: this.state.customerSupportCardData,
      showsecureCardDetail: this.state.showsecureCardDetail,
      uniqueCrId: this.uniqueCrId,
      tentativeWindowBookingStatus: this.state.tentativeWindowBookingStatus,
      errorPageData: this.state.errorPageData,
      bookingId: this.bookingId,
      segmentAncillaryDetails: this.state.segmentAncillaryDetails,
      ancillaryResponseReceived: this.state.ancillaryResponseReceived,
      isCsatInlineCardEnabled: this.isCsatInlineCardEnabled,
      lobName: getLobName(this.props.holidayBookingApi),
    };

    const cardLists = getCardLists(paramsForCardLists);
    return cardLists;
  }

  openDeepLinkFromNeedHelp = (url) => {
    const params = fetchUrlParam(url);
    const deepLinkPageName = params.act;
    this.openDeepLink(this.state.responseJson, parseInt(deepLinkPageName));
  };

  onRefundBreakupBtnClicked = (requestNo, version) => {
    const lobName = getLobName(this.props.holidayBookingApi);
    onRefundBreakupClickHandler(requestNo, version, this.state.responseJson, lobName);
  };

  trackRefundEvent = (actionType) => {
    refundEventTracker(actionType, this.state.responseJson);
  };

  trackHelperCustomerConfirmPopUp = (markupselected) => {
    FlightBookingTrackingHelper.trackClickEvent('Details', markupselected, this.state.responseJson);
  };

  trackHelpingHandEvents = (type, page, click, data) => {
    const pageName = `Details_${page}`;
    const trackData = Object.assign({}, this.state.responseJson, {
      ruleTrace: data ? data.ruleTrace : '',
    });
    switch (type) {
      case 'load':
        FlightBookingTrackingHelper.trackLoadEvent(pageName, trackData);
        break;
      case 'click':
        FlightBookingTrackingHelper.trackClickEvent(pageName, `Flight_${click}`, trackData);
        break;
      default:
        break;
    }
  };

  getTopCardColor = (bookingstate, topStatusCard) => {
    const { stickyHeader } = this.props;
    if (stickyHeader) {
      const { state, subState } = bookingstate;
      if (subState && subState.toUpperCase() === 'CTRIP_UNCONFIRMED') {
        return ['#e8f0ff', '#e8f0ff'];
      }
      if (topStatusCard) {
        switch (topStatusCard.topCardType) {
          case TOP_CARD_BOOKING_STATUS.CTRIP_UNCONFIRMED:
          case TOP_CARD_BOOKING_STATUS.SPECIAL_CLAIM_RAISED:
          case TopCardType.TEMPORARY_BOOKING:
            return ['#e8f0ff', '#e8f0ff'];
          case TOP_CARD_BOOKING_STATUS.FULLY_AWAITED:
          case TOP_CARD_BOOKING_STATUS.PARTIALLY_AWAITED:
          case TOP_CARD_BOOKING_STATUS.FAILED_AWAITED:
          case TOP_CARD_BOOKING_STATUS.FAILED_AWAITED_CONFIRMED:
          case TopCardType.FARE_LOCK_HOLD:
            return ['#ffedd1', '#ffedd1'];
          case TOP_CARD_BOOKING_STATUS.PARTIALLY_FAILED:
            return ['#c2f4e9', '#c2f4e9'];
          case TopCardType.FARE_LOCK_EXPIRED:
          case TopCardType.TEMPORARY_BOOKING_EXPIRED:
            return ['#e7e7e7', '#e7e7e7'];
          case TopCardType.FARE_LOCK_FAILED:
          case TopCardType.FARE_LOCK_CANCELLED_BY_AIRLINE:
          case TopCardType.SEAT_NOT_AVAILABLE:
          case TopCardType.TEMPORARY_BOOKING_FAILED:
            return ['#fedcde', '#fedcde'];
          default:
            return ['#ffedd1', '#ffedd1'];
        }
      }
      switch (state) {
        case 'Completed':
          return ['#e7e7e7', '#e7e7e7'];
        case 'Cancelled':
          return ['#fbedcf', '#fbedcf'];
        case 'Refunded':
          return ['#fbedcf', '#fbedcf'];
        case 'Failed':
        case 'LostFailed':
          return ['#fedcde', '#fedcde'];
        case 'Upcoming':
          if (subState === 'Pending') {
            return ['#ffedd1', '#ffedd1'];
          } else {
            return ['#c2f4e9', '#c2f4e9'];
          }
        default:
          return ['#c2f4e9', '#c2f4e9'];
      }
    } else {
      return ['#FFFFFF', '#FFFFFF'];
    }
  };

  dateChangeCardActionClick = (action, pageName, isSourceDgcaVoidPopup = false) => {
    dateChangeCardClkHandler(
      action,
      pageName,
      this.state.responseJson,
      getLobName(this.props.holidayBookingApi),
      this.openCancellationInfo,
      isSourceDgcaVoidPopup,
    );
  };

  cancelCardActionItemClick = (
    actionItem,
    pageName,
    isSourceSpecialClaimSheet = false,
    isSourceCancellationInfoSheet = false,
  ) => {
    const { specialClaimVariant, specialClaimDataList, cancellationDgca, cancellationVoid } =
      this.state.responseJson;
    DeviceEventEmitter.emit(EventEmitterConst.CTA_CLICKED_EVENT); // need to check this
    const isOpenSpecialClaimPopUp =
      !!specialClaimDataList &&
      specialClaimVariant != undefined &&
      specialClaimVariant != null &&
      specialClaimVariant !== 1 &&
      specialClaimVariant !== -1;
    switch (actionItem.actionId) {
      case 'CANCEL_FLT_FULL':
        if (isOpenSpecialClaimPopUp && !isSourceSpecialClaimSheet) {
          this.openSpecialClaimPopUp({ actionItem, pageName });
          return;
        } else if (isSourceCancellationInfoSheet && (cancellationDgca || cancellationVoid)) {
          this.state.showSpecialClaimBottomSheet.value && this.closeSpecialClaimPopUp();
          this.openCancellationInfo({ actionItem, pageName });
          return;
        }
        onCancellationButtonClicked(
          FLIGHT_CANCELLATION_TYPE.FULL_CANCELLATION,
          this.bookingId,
          this.state.responseJson,
        );
        FlightBookingTrackingHelper.trackClickEvent(
          pageName,
          'FlightCancellation_CancelEntire',
          this.state.responseJson,
        );
        break;
      case 'CANCEL_FLT_FREE':
        this.toggleCancellationBookingFreeConfirmation();
        break;
      case 'CANCEL_FLT_PARTIAL':
        if (isOpenSpecialClaimPopUp && !isSourceSpecialClaimSheet) {
          this.openSpecialClaimPopUp({ actionItem, pageName });
          return;
        } else if (isSourceCancellationInfoSheet && (cancellationDgca || cancellationVoid)) {
          this.state.showSpecialClaimBottomSheet.value && this.closeSpecialClaimPopUp();
          this.openCancellationInfo({ actionItem, pageName });
          return;
        }
        onCancellationButtonClicked(
          FLIGHT_CANCELLATION_TYPE.PARTIAL_CANCELLATION,
          this.bookingId,
          this.state.responseJson,
        );
        FlightBookingTrackingHelper.trackClickEvent(
          pageName,
          'FlightCancellation_CancelPartial_pax',
          this.state.responseJson,
        );
        break;
      case 'CANCEL_FLT_PARTIAL_SEGGRP':
        {
          if (isOpenSpecialClaimPopUp && !isSourceSpecialClaimSheet) {
            this.openSpecialClaimPopUp({ actionItem, pageName });
            return;
          } else if (isSourceCancellationInfoSheet && (cancellationDgca || cancellationVoid)) {
            this.state.showSpecialClaimBottomSheet.value && this.closeSpecialClaimPopUp();
            this.openCancellationInfo({ actionItem, pageName });
            return;
          }
          const selectedSegmentGroupView = FlightBookingDetails.getSegmentView(actionItem);
          onCancellationButtonClicked(
            FLIGHT_CANCELLATION_TYPE.PARTIAL_CANCELLATION,
            this.bookingId,
            this.state.responseJson,
            selectedSegmentGroupView,
          );
          FlightBookingTrackingHelper.trackClickEvent(
            pageName,
            'FlightCancellation_CancelPartial_seg',
            this.state.responseJson,
          );
        }
        break;
      case 'CANCEL_CHRGS':
        {
          const staticData = getStaticData();
          const btn = {};
          btn.text = staticData.calculateRefundText;
          btn.btnAction = 'CANCEL_FLT_FULL';
          openPolicyPage(
            staticData.cancellationPolicyText,
            this.state.responseJson.farePenaltyList.Cancellation,
            true,
            btn,
            this.state.responseJson,
          );
          FlightBookingTrackingHelper.trackClickEvent(
            pageName,
            'FlightCancellation_CancelPolicy',
            this.state.responseJson,
          );
        }
        break;
      case 'SPECIAL_CLAIM':
      case 'SPECIAL_CLAIM_TEXT':
        {
          const { specialClaimDataList, specialClaimMetaData } = this.state.responseJson;
          Actions.specialClaimReasons({
            specialClaimDataList,
            specialClaimMetaData,
            onItemClick: this.onContinueClicked,
            bookingResponse: this.state.responseJson,
            bookingId: this.bookingId,
            mobileNumber: this.mobileNumber,
          });
          if (actionItem.actionId === 'SPECIAL_CLAIM') {
            FlightBookingTrackingHelper.trackClickEvent(
              pageName,
              'Special_claim_cancellation_card_buttonentry',
              this.state.responseJson,
            );
          } else {
            FlightBookingTrackingHelper.trackClickEvent(
              pageName,
              'Special_claim_cancellation_card_textentry',
              this.state.responseJson,
            );
          }
          FlightBookingTrackingHelper.trackLoadEvent(
            'Special claim options',
            this.state.responseJson,
          );
        }
        break;
      case 'EXPECTING_REFUND':
        this.openSpecialClaimPopUp({
          actionItem: { actionId: 'CANCEL_FLT_FULL' },
          src: 'EXPECTING_REFUND',
          pageName,
        });
        FlightBookingTrackingHelper.trackClickEvent(pageName, 'MI_FL_Sclaim_New_CanCard');
        break;
      default:
        break;
    }
  };

  onPaymentCardBtnClick = (action) => {
    switch (action) {
      case FlightBookingDetailsConstant.ACTION_DOWNLOAD_INVOICE:
        if (Platform.OS === 'ios') {
          this.setState({
            showDownloadInvoiceOverlay: true,
          });
        } else if (Platform.OS === 'android') {
          this.triggerName = FlightBookingDetailsConstant.DOWNLOAD_INVOICE_POPUP;
          FlightBookingModule.checkStoragePermission();
        } else {
          if (!this.state.responseJson?.isMyPartnerBooking) {
            FlightBookingModule.downloadTicket('Flight_Customer_Invoice', this.bookingId);
            return;
          }
        }
        FlightBookingTrackingHelper.trackClickEvent(
          'Details_paymentdetail',
          'Flight_invoice',
          this.state.responseJson,
        );
        break;
      case FlightBookingDetailsConstant.ACTION_PAYMENT_DETAILS_PAGE:
        Actions.flightPaymentPriceBreakUp({
          bookingDetailsResponse: this.state.responseJson,
          btnClickListener: this.onPaymentCardBtnClick,
          isCorporateBooking: this.state.responseJson.isCorporateBooking,
        });
        FlightBookingTrackingHelper.trackClickEvent(
          'Details_paymentdetail',
          'Flight_invoice',
          this.state.responseJson,
        );
        break;
      case FlightBookingDetailsConstant.ACTION_DOWNLOAD_VOUCHER:
        this.handleOpenBottomOverlay(action);
        FlightBookingTrackingHelper.trackClickEvent(
          'Details_paymentdetail',
          'Customer_BookingConfirmation',
          this.state.responseJson,
        );
        break;
      default:
        break;
    }
  };

  onContinueClicked = (specialClaimData) => {
    continueClickHandler(specialClaimData, this.state.responseJson);
  };

  onChangeInTravelPlanBtnClick = (action) => {
    switch (action.btnActionFamily) {
      case FlightBookingDetailsConstant.ACTION_MODIFICATION_OPTIONS:
        Actions.flightModificationOption({
          response: this.state.responseJson,
          dateChangeCardActionClick: this.dateChangeCardActionClick,
        });
        FlightBookingTrackingHelper.trackClickEvent(
          'Details',
          'ChangePlan_FlightModification',
          this.state.responseJson,
        );
        break;
      case FlightBookingDetailsConstant.ACTION_CANCELLATION_OPTIONS:
        Actions.flightCancellationOption({
          response: this.state.responseJson,
          cancelCardActionItemClick: this.cancelCardActionItemClick,
          onContinueClicked: this.onContinueClicked,
          normalMsgObj: getCancellationCardNormalMsgObj(this.state.responseJson),
        });
        FlightBookingTrackingHelper.trackClickEvent(
          'Details',
          'ChangePlan_FlightCancellation',
          this.state.responseJson,
        );
        break;
      default:
        break;
    }
  };

  static getSegmentView(actionItem) {
    return getSelectedSegmentGroupView(actionItem);
  }

  async fetchCompleteYourTripData() {
    const response = await fetchCompleteYourTripDetail(
      this.bookingId,
      this.props.holidayBookingApi,
    );
    if (response && response.isNetworkAvailable) {
      return;
    } else if (response && !response.error) {
      _setFltTrackData(response);
      this.setState({
        completeYouTripJson: response,
      });
    }
  }

  async fetchJSONAsync() {
    const flightDetailResponse = await fetchFlightBookingDetails(
      this.bookingId,
      this.props.holidayBookingApi,
      this.trackError,
    );
    if (flightDetailResponse?.isNetworkNotAvailable) {
      this.setState({ viewState: ViewState.NO_INTERNET });
      return;
    } else if (flightDetailResponse?.error) {
      const errorBody = flightDetailResponse.error;
      if (
        errorBody &&
        errorBody?.message &&
        errorBody.message.toUpperCase() === AUTH_FAILED_MESSAGE
      ) {
        this.setState({ viewState: ViewState.AUTH_ERROR });
      } else {
        this.setState({ viewState: ViewState.ERROR });
      }
    } else {
      const body = flightDetailResponse;
      this.fetchUniversalSearchJSONAsync(body);
      body.showMyraBtn = this.props.showMyraBtn;
      _setFltTrackData(body);
      const acmeCity = getAcmeCity(body);
      const displayWCOverlay = await displayWebCheckInOverlay(body);
      this.setState({
        responseJson: body,
        viewState: ViewState.SHOW_DETAIL,
        acmeCity,
        showWebcheckinOverlay: displayWCOverlay,
        helpingHandOverlay: !displayWCOverlay,
      });
      this.getCsatControllerData(body);
      body.newFlightDetails &&
        body.newFlightDetails.segmentGroupDetailList.forEach((segmentFlightDetails, index) => {
          this.setState((prevState) => ({
            flightDetailsLayout: {
              ...prevState.flightDetailsLayout,
              [`SegmentCard${index}`]: 0,
            },
          }));
        });
      this.isIntl = body.isInternational;
      if (body.accountDetail) {
        this.mobileNumber = getPrimaryContactNo(body.accountDetail.contactDetails);
      }
      if (this.state.ancillaryResponseReceived || body.isGccBooking || body.isKsaBooking) {
        let cmp;
        if (this.deepLinkPageData?.deepLinkExtraData?.CMP) {
          cmp = this.deepLinkPageData.deepLinkExtraData.CMP;
        }
        FlightBookingTrackingHelper.trackFlightBookingDetailLoadEvent(
          'Details',
          body,
          this.state.segmentAncillaryDetails,
          this.props.holidayBookingApi,
          cmp,
        );
      }
      if (this.props.holidayBookingApi) {
        FlightBookingTrackingHelper.trackFlightBookingDetailLoadEvent(
          `Flight_Detail_${this.props.collapseIndex}`,
          body,
          undefined,
          this.props.holidayBookingApi,
        );
      }
      if (body.initFirebaseLastMinuteConnection) {
        this.initLastMinuteFirebaseDatabase(body);
      }
      if (body.isCYTEnabled) {
        this.fetchCompleteYourTripData();
      }
    }
  }
  ctaSelectDeeplinkHandler = ({ actId }) => {
    this.toggleAutoSearchPopup();
    this.openDeepLink(this.state.responseJson, actId, {
      viaSearchBar: true,
    });
  };
  openDeepLink = (responseJson, deepLinkPage, extraInfo) => {
    let deepLinkHandled = false;
    const deepLinkPageNumber = parseInt(deepLinkPage, 10);
    switch (deepLinkPageNumber) {
      case DEEP_LINK_PAGE.FLIGHT_SPECIAL_CLAIM:
      case DEEP_LINK_PAGE.FLIGHT_SPECIAL_CLAIM_MEDICIAL_REASON:
      case DEEP_LINK_PAGE.FLIGHT_SPECIAL_CANCELLED_DIRECTLY_WITH_AIRLINE_REASON:
      case DEEP_LINK_PAGE.FLIGHT_SPECIAL_CANCELLED_BY_AIRLINE_REASON:
        {
          const { cancelCardDetailInfo } = responseJson;
          const isSpecialClaimEligbleCta = cancelCardDetailInfo?.actionItemInfoList?.some(
            (item) => item?.actionId === 'EXPECTING_REFUND',
          );
          if (isSpecialClaimEligbleCta) {
            this.openSpecialClaimPopUp({
              actionItem: { actionId: SPECIAL_CLAIM_CONST.CANCEL_FLT_FULL },
              src: SPECIAL_CLAIM_CONST.EXPECTING_REFUND,
              pageName: 'Details',
            });
            deepLinkHandled = true;
          }
        }
        break;
      case DEEP_LINK_PAGE.FLIGHT_DATE_CHANGE:
        if (!isEmpty(responseJson?.datechangeCacheDetail)) {
          openDateChange(undefined, responseJson, getLobName(this.props.holidayBookingApi));
          deepLinkHandled = true;
        }
        break;
      case DEEP_LINK_PAGE.FLIGHT_CANCELLATION:
        if (
          responseJson.cancelCardDetailInfo &&
          isCancellationApplicable(responseJson.cancelCardDetailInfo.actionItemInfoList)
        ) {
          onCancellationButtonClicked(
            FLIGHT_CANCELLATION_TYPE.PARTIAL_CANCELLATION,
            this.bookingId,
            this.state.responseJson,
          );
          deepLinkHandled = true;
        }
        break;
      case DEEP_LINK_PAGE.FLIGHT_SPECIAL_CLAIM_SELECTED:
        const { specialClaimDataList } = responseJson;
        if (!isEmpty(specialClaimDataList)) {
          let scCode =
            extraInfo && extraInfo.reason
              ? extraInfo.reason
              : this.deepLinkPageData?.deepLinkExtraData?.SCCODE;
          if (extraInfo?.viaSearchBar) {
            scCode = 'FDOP';
          }
          if (!isEmpty(scCode)) {
            const specialClaimDataPageToOpen = specialClaimDataList.find(
              (specialClaimData) => specialClaimData.scCode === scCode,
            );
            if (specialClaimDataPageToOpen) {
              openSpecialClaimSelectPaxPage(responseJson, specialClaimDataPageToOpen);
              deepLinkHandled = true;
            }
          }
        }
        break;
      case DEEP_LINK_PAGE.FLIGHT_WEB_CHECK_IN:
        {
          const segmentAncillaryDetail =
            this.state.segmentAncillaryDetails &&
            this.state.segmentAncillaryDetails.find(
              (segment) =>
                segment.webCheckInDescription === 'OPEN' ||
                segment.webCheckInDescription === 'SCHEDULED_OPEN' ||
                segment.webCheckInDescription === 'OPEN_MMT_SUPPORTED',
            );
          if (segmentAncillaryDetail) {
            const webCheckinDescription = segmentAncillaryDetail.webCheckInDescription;
            if (
              webCheckinDescription !== 'OPEN_MMT_SUPPORTED' &&
              webCheckinDescription !== 'SCHEDULED_OPEN' &&
              !(
                segmentAncillaryDetail.seatSelectionApplicable ||
                segmentAncillaryDetail.mealApplicable ||
                segmentAncillaryDetail.baggageApplicable
              )
            ) {
              const segmentGroupList = responseJson.newFlightDetails.segmentGroupDetailList;
              let segmentDetail;
              if (!isEmpty(segmentGroupList) && !isEmpty(segmentGroupList.segmentDetails)) {
                segmentDetail = segmentGroupList.segmentDetails.find(
                  (segmentToCheck) =>
                    segmentToCheck.segmentLineNo === segmentAncillaryDetail.segmentLineNo,
                );
              }
              if (segmentDetail && segmentDetail.webCheckInUrl) {
                if (segmentDetail.openInWebView) {
                  Actions.openPsWebView({
                    headerText: 'Flight Web Checkin',
                    headerIcon: BACK_WHITE_ICON,
                    url: segmentDetail.webCheckInUrl,
                  });
                } else {
                  Linking.openURL(segmentDetail.webCheckInUrl);
                }
              }
            } else {
              Actions.openAncillary({
                pageKey: 'webcheckin',
                bookingId: responseJson.bookingID,
                flightDetailResponse: responseJson,
                holidayBooking: this.props.holidayBookingApi,
                holidayData: this.props.holidayData,
                goToFlightBookingPage: false,
                isCorporateBooking: responseJson.isCorporateBooking,
              });
            }
            deepLinkHandled = true;
          }
        }
        break;
      case DEEP_LINK_PAGE.FLIGHT_ADD_SEAT:
        {
          const isSeatApplicable =
            this.state.segmentAncillaryDetails &&
            this.state.segmentAncillaryDetails.some((segment) => segment.seatSelectionApplicable);
          if (isSeatApplicable) {
            Actions.openAncillary({
              pageKey: 'selectAddons',
              addonType: SEATS,
              flightDetailResponse: this.props.flightDetailResponse,
              bookingId: responseJson.bookingID,
              activeSegment: getActiveSegmentForAncillary(
                responseJson,
                DEEP_LINK_PAGE.FLIGHT_ADD_SEAT,
              ),
              goToFlightBookingPage: false,
            });
            deepLinkHandled = true;
          }
        }
        break;
      case DEEP_LINK_PAGE.FLIGHT_ADD_MEAL:
        {
          const isMealApplicable =
            this.state.segmentAncillaryDetails &&
            this.state.segmentAncillaryDetails.some((segment) => segment.mealApplicable);
          if (isMealApplicable) {
            Actions.openAncillary({
              pageKey: 'selectAddons',
              bookingId: responseJson.bookingID,
              addonType: MEALS,
              goToFlightBookingPage: false,
              flightDetailResponse: this.props.flightDetailResponse,
            });
            deepLinkHandled = true;
          }
        }
        break;
      case DEEP_LINK_PAGE.FLIGHT_ADD_BAGGAGE:
        {
          const isBaggageApplicable =
            this.state.segmentAncillaryDetails &&
            this.state.segmentAncillaryDetails.some((segment) => segment.baggageApplicable);
          if (isBaggageApplicable) {
            Actions.openAncillary({
              pageKey: 'selectAddons',
              bookingId: responseJson.bookingID,
              addonType: BAGGAGE,
              goToFlightBookingPage: false,
              flightDetailResponse: this.props.flightDetailResponse,
            });
            deepLinkHandled = true;
          }
        }
        break;
      case DEEP_LINK_PAGE.FLIGHT_ADD_ANCILLARY:
        const ancillaryDetails = this.state.segmentAncillaryDetails;
        const isMealApplicable =
          ancillaryDetails && ancillaryDetails.some((segment) => segment.mealApplicable);
        const isSeatApplicable =
          ancillaryDetails && ancillaryDetails.some((segment) => segment.seatSelectionApplicable);
        const isBaggageApplicable =
          ancillaryDetails && ancillaryDetails.some((segment) => segment.baggageApplicable);
        if (isSeatApplicable || isMealApplicable || isBaggageApplicable) {
          Actions.openAncillary({
            pageKey: 'selectAddons',
            addonType: SEATS,
            bookingId: responseJson.bookingID,
            goToFlightBookingPage: false,
            flightDetailResponse: this.props.flightDetailResponse,
          });
          deepLinkHandled = true;
        }
        break;
      case DEEP_LINK_PAGE.EXTERNAL_LINK:
        const { openInWebView, url, header, webViewHeader } = extraInfo || {};
        if (openInWebView && url) {
          Actions.openPsWebView({
            headerText: webViewHeader || header,
            url: url,
            headerIcon: HEADER_BACK_ICON,
            whiteHeader: true,
          });
          deepLinkHandled = true;
        } else if (url) {
          Linking.openURL(url);
          deepLinkHandled = true;
        }
        break;
      case DEEP_LINK_PAGE.FLIGHT_DATE_CHANGE_POLICY: {
        // this will come always and will also be present in details page irrrespective of date change is supported or not.
        if (this.state.responseJson.farePenaltyList?.DateChange) {
          this.dateChangeCardActionClick('ACT_RES_CHARGES', 'Details');
          deepLinkHandled = true;
        }
        break;
      }
      case DEEP_LINK_PAGE.FLIGHT_CANCELLATION_POLICY: {
        // this will come always and will also be present in details page irrrespective of cancellation is available or not.
        if (this.state.responseJson.farePenaltyList?.Cancellation) {
          this.cancelCardActionItemClick(
            {
              actionCTA: 'ACT_CANCEL_CHRGS',
              actionId: 'CANCEL_CHRGS',
              actionText: 'Airline Cancellation Policy',
            },
            'Details',
          );
          deepLinkHandled = true;
        }
        break;
      }
      case DEEP_LINK_PAGE.FLIGHT_DOWNLOAD_INVOICE: {
        // as of now data will come after click on download button & download buttong will always come for upcoming bookings
        // so in this case if download is not available it will show and error message
        // and it will not impact anything else
        if (this.state.responseJson.showDownloadInvoice) {
          this.onPaymentCardBtnClick('DownloadInvoice');
          deepLinkHandled = true;
        }
        break;
      }
      case DEEP_LINK_PAGE.FLIGHT_PRICE_BREAKUP_CARD: {
        // this will come always and will also be present in details page.
        if (this.state.responseJson.fareBreakupNodeList) {
          // calling this function via deeplink will not impact anything.
          this.onPaymentCardBtnClick('PaymentDetailsPage');
          deepLinkHandled = true;
        }
        break;
      }
      case DEEP_LINK_PAGE.FLIGHT_NAME_CORRECTION: {
        const nameCorrection =
          this.state.responseJson.specialRequestCardDetailInfo?.actionItemInfoList.find(
            (action) => action.actionId === 'NAME_CHANGE',
          );
        if (!isEmpty(nameCorrection)) {
          this.specialRequestCardBtnClick('NAME_CHANGE');
          deepLinkHandled = true;
        }
        break;
      }
      case DEEP_LINK_PAGE.FLIGHT_DOWNLOAD_ETICKET: {
        if (
          !this.state.responseJson?.holidayBooking &&
          !FlightBookingUtil.isHoldBooking(this.state.responseJson.topStatusCard?.topCardType) &&
          (this.state.responseJson?.bookingState?.state?.toUpperCase() ===
            FlightBookingDetailsConstant.UPCOMING ||
            this.state.responseJson?.bookingState?.state?.toUpperCase() ===
              FlightBookingDetailsConstant.ONTRIP) &&
          !FlightBookingUtil.isFareLockExpired(this.state.responseJson.topStatusCard?.topCardType)
        ) {
          this.onDownloadEticketClicked();
          deepLinkHandled = true;
        }
        break;
      }
      default:
        break;
    }
    if (!deepLinkHandled) {
      if (extraInfo?.viaSearchBar) {
        this.setState({
          ctaSearchErrorMessage: true,
        });
      } else {
        const currentScreen = Actions.currentScene();
        if (currentScreen !== 'flightBookingDetail') {
          if (Platform.OS === 'android' || Platform.OS === 'ios') {
            FlightBookingUtil.resetToFlightBookingDetail(this.bookingId, false);
          } else {
            Actions.pop();
          }
        }
      }
    }
  };

  async fetchUniversalSearchJSONAsync(flightBookingDetailResponse) {
    if (!flightBookingDetailResponse?.showSearchBar) {
      return;
    }
    const SEARCH_BAR_KEY = `${LOBNAMES.FLIGHT}_searchbar_key`;
    const SEARCH_RESPONSE_KEY = `${LOBNAMES.FLIGHT}_searchBar_response`;
    this.isUniversalFetchCalled = true;
    let universalSearchResponse;
    const flightSearchDetailResponse = await getDataFromStorage(SEARCH_RESPONSE_KEY);
    const prevUniversalSearchKey = await getDataFromStorage(SEARCH_BAR_KEY);
    const newUniversalSearchKey = flightBookingDetailResponse?.universalSearchKey;
    if (
      !isEmpty(flightSearchDetailResponse) &&
      !isEmpty(newUniversalSearchKey) &&
      newUniversalSearchKey === prevUniversalSearchKey
    ) {
      if (isEmpty(this.state.universalSearchResponse)) {
        universalSearchResponse = JSON.parse(flightSearchDetailResponse);
        this.setState({
          universalSearchResponse,
        });
      }
    } else {
      universalSearchResponse = await fetchFlightBookingUniversalSearchDetails(this.bookingId);
      if (universalSearchResponse?.isNetworkNotAvailable || universalSearchResponse?.error) {
        return;
      } else {
        setDataInStorage(SEARCH_RESPONSE_KEY, JSON.stringify(universalSearchResponse));
        setDataInStorage(SEARCH_BAR_KEY, newUniversalSearchKey);
        this.setState({
          universalSearchResponse,
        });
      }
    }
  }
  async fetchAncillaryJSONAsync() {
    const ancillaryResponse = await fetchFlightAncillaryDetails(
      this.bookingId,
      this.props.holidayBookingApi,
    );
    let segmentAncillaryDetails;
    if (ancillaryResponse && ancillaryResponse.isNetworkNotAvailable) {
      return;
    } else if (ancillaryResponse && ancillaryResponse.error) {
      this.setState({ ancillaryResponseReceived: true });
    } else {
      this.setState({ ancillaryResponseReceived: true });
      if (!isEmpty(ancillaryResponse.segmentAncillaryDetails)) {
        const {
          segmentAncillaryDetails: segAncillaryDtl,
          odcAncillaryPopUpMessage,
          ...ancillaryRestResponse
        } = ancillaryResponse || {};
        segmentAncillaryDetails = segAncillaryDtl;
        this.setState({
          segmentAncillaryDetails,
          odcAncillaryPopUpMessage,
          ancillaryResponseJson: ancillaryRestResponse,
        });
      }
    }
    if (this.state.viewState === ViewState.SHOW_DETAIL && this.state.responseJson) {
      let cmp;
      if (this.deepLinkPageData?.deepLinkExtraData?.CMP) {
        cmp = this.deepLinkPageData.deepLinkExtraData.CMP;
      }
      FlightBookingTrackingHelper.trackFlightBookingDetailLoadEvent(
        'Details',
        this.state.responseJson,
        segmentAncillaryDetails,
        this.props.holidayBookingApi,
        cmp,
      );
    }
  }

  trackError = (error) => {
    trackErrorEvent(error, this.props.holidayBookingApi, this.props.collapseIndex, this.bookingId);
  };

  renderNoNetworkView = () => {
    const staticData = getStaticData();
    return (
      <View style={{ flex: 1 }}>
        <SimpleHeader title={staticData.backText} iconPress={this.goBack} />
        <NoInternetView
          heading={staticData.errorText.notConnectedToTheInternetError}
          subHeading={staticData.errorText.checkYourSettingsAndTryAgainError}
          buttonText={staticData.tryAgainText}
          onRetry={() => {
            this.setState({ viewState: ViewState.LOADING });
            this.getData();
          }}
        />
      </View>
    );
  };

  renderProgressView = () => {
    const { loadingYourTripText } = getStaticData(true, true);
    return <ProgressView message={loadingYourTripText ? loadingYourTripText : ''} />;
  };

  renderErrorView = () => {
    const staticData = getStaticData();
    return (
      <View style={{ flex: 1 }}>
        <SimpleHeader title={staticData.errorText.errorText} iconPress={this.goBack} />
        <ErrorPage
          title={staticData.errorText.somethingWentWrongError}
          btnAction={getErrorBtnObj()}
          onRefresh={(btnAction) => {
            this.onErrorBtnClicked(btnAction);
          }}
          subText={getErrorPageSubText()}
        />
      </View>
    );
  };

  renderAuthErrorView = () => (
    <AuthErrorView
      bookingId={this.bookingId}
      lobName={LOBNAMES.FLIGHT}
      onGoBackPress={this.goBack}
    />
  );

  onErrorBtnClicked = (btnAction) => {
    if (btnAction.action === FlightBookingDetailsConstant.ACTION_RELOAD) {
      this.setState({ viewState: ViewState.LOADING });
      this.getData();
      FlightBookingTrackingHelper.trackClickEvent(
        'mob:customer support: flight:TripDetailsError',
        'Retry',
        undefined,
      );
    }
  };

  showCsatPopupReason = () => {
    const {
      isCsatFeedbackSharedWithin1Day,
      isCsatFeedbackSharedWithin7Days,
      isCsatPopupHandled,
      csatPopupReason,
      isTimeSpentMoreThanSpecified,
      isCtaClicked,
    } = this.state;
    let popupReason = '';
    if (!csatPopupReason && !isCsatPopupHandled && !this.isCsatInlineCardEnabled) {
      if (
        isCtaClicked &&
        !isCsatFeedbackSharedWithin1Day &&
        isTimeSpentMoreThanSpecified &&
        !isCsatFeedbackSharedWithin7Days
      ) {
        popupReason = 'both';
      } else if (isCtaClicked && !isCsatFeedbackSharedWithin1Day) {
        popupReason = 'cta';
      } else if (isTimeSpentMoreThanSpecified && !isCsatFeedbackSharedWithin7Days) {
        popupReason = 'timeSpent';
      }
    }
    return popupReason;
  };

  shouldCsatPopupOpenHandler = () => {
    const cSatData = {
      isCtaClicked: this.state.isCtaClicked,
      isCsatFeedbackSharedWithin1Day: this.state.isCsatFeedbackSharedWithin1Day,
      isCsatFeedbackSharedWithin7Days: this.state.isCsatFeedbackSharedWithin7Days,
      isTimeSpentMoreThanSpecified: this.state.isTimeSpentMoreThanSpecified,
      isCsatPopupHandled: this.state.isCsatPopupHandled,
      openCsatPopup: this.state.openCsatPopup,
    };
    if (shouldShowCsatPopup(cSatData, this.isCsatInlineCardEnabled)) {
      const popupReason = this.showCsatPopupReason();
      this.setState({ openCsatPopup: true, csatPopupReason: popupReason });
      return true;
    }
    return false;
  };

  goBack = () => {
    if (this.shouldCsatPopupOpenHandler()) {
      return false;
    }
    if (this.shouldShowAddSeatMealPopup()) {
      this.backHandler();
    } else {
      Actions.pop();
    }
  };

  backHandler = () => {
    this.setState({
      showAddSeatMealPopup: true,
    });
    this.addSeatMealPopupRendered = true;
    setAddSeatMealPopupRendered(this.bookingId);
  };

  preventSwipeBack = (e) => {
    e.preventDefault();
    this.props.newNavigation?.removeListener('beforeRemove');
    return this.onBackPressHandler();
  };

  onBackPressHandler = () => {
    if (this.state.showCancellationInfoOverlay.value) {
      this.closeCancellationInfoOverlay();
      return true;
    }
    if (this.state.freeCancellationConfirmOverlay) {
      this.toggleCancellationBookingFreeConfirmation();
      return true;
    }
    if (this.state.showChecklistOverlay) {
      this.toggleChecklistOverlay();
      return true;
    }
    if (this.state.showNameChangeOverlay) {
      this.nameChangePopupClose();
      return true;
    }
    if (this.state.showDateChangeOverlay) {
      this.dismissDateChangePopup();
      return true;
    }
    if (this.state.showDateChangeSubmittedOverlay) {
      this.dateChangePopupClose();
      return true;
    }
    if (this.state.showAutoSearchPopup) {
      this.toggleAutoSearchPopup();
      return true;
    }
    if (this.state.showSpecialClaimBottomSheet.value) {
      this.closeSpecialClaimPopUp();
      return true;
    }
    if (this.state.showVisaGuaranteeOverlay) {
      this.closeVpgInfoOverlay();
      return true;
    }
    if (this.state.showWebcheckinOverlay) {
      this.toggleWebcheckinOverlay(false);
      return true;
    }
    if (this.shouldCsatPopupOpenHandler()) {
      return true;
    }
    if (this.shouldShowAddSeatMealPopup()) {
      this.backHandler();
      return true;
    }
  };

  openDigitTermsNCond = (ctaData, cardType) => {
    const bookingId = this.props.BOOKING_ID || this.bookingId;
    const downloadDetail = openDigitTermsNCondPage(
      ctaData,
      cardType,
      bookingId,
      this.state.responseJson,
    );
    if (!isEmpty(downloadDetail)) {
      this.downloadData = downloadDetail;
    }
  };

  baggageProtectCardBtnClick = (baggaeProtectionDetails) => {
    baggageProtectCardClickHandler(
      baggaeProtectionDetails,
      this.state.responseJson,
      this.bookingId,
    );
  };

  onNewBookingBtnClicked = (bookingId) => {
    Actions.refresh({
      BOOKING_ID: bookingId,
      bookingId,
      refresh: true,
    });
  };

  dateChangeViewDetailsClick = (index) => {
    this.setState({
      showDateChangeSubmittedOverlay: true,
      showDateChangeSubmittedOverlayIndex: index,
    });
  };

  shouldShowNameChangeOverlay = () => {
    this.setState({
      showNameChangeOverlay: true,
    });
  };

  specialRequestCardBtnClick = (actionId) => {
    specialRequestCardClickHandler(
      actionId,
      this.shouldShowNameChangeOverlay,
      this.state.responseJson,
      this.bookingId,
      this.openCancellationInfo,
    );
  };

  nameChangePopupClose = () => {
    this.setState({
      showNameChangeOverlay: false,
    });
  };

  nameChangePopupProceedClick = (reasonSelected = null) => {
    nameChangePopupClickHandler(
      reasonSelected,
      this.nameChangePopupClose,
      this.state.responseJson,
      this.bookingId,
    );
  };

  dateChangePopupClose = () => {
    this.setState({
      showDateChangeSubmittedOverlay: false,
      showDateChangeSubmittedOverlayIndex: undefined,
    });
  };

  toggleChecklistOverlay = (value) => {
    this.setState({
      showChecklistOverlay: value,
    });
  };

  handleOpenBottomOverlay = (overlay) => {
    this.setState({
      bottomOverlay: overlay,
    });

    Animated.timing(this.state.overlayPosition, {
      toValue: 0,
      easing: Easing.easeInOut,
      duration: 300,
      delay: 100,
    }).start();
  };

  handleCloseBottomOverlay = () => {
    setTimeout(() => {
      this.setState({
        bottomOverlay: '',
      });
    }, 400);

    Animated.timing(this.state.overlayPosition, {
      toValue: -1000,
      easing: Easing.easeInOut,
      duration: 300,
      delay: 100,
    }).start();
  };

  toggleWheelChairOverlay = (activeSegment) => {
    this.setState((prevState) => ({
      showWheelChairOverlay: !prevState.showWheelChairOverlay,
      wheelChairActiveSegment: activeSegment,
    }));
  };

  togglereleaseholdOverlay = (updateProceedCancellation) => {
    FlightBookingTrackingHelper.trackClickEvent('Details', 'hold_myt_cancel_ctaclick');
    this.setState((prevState) => ({
      releaseholdOverlay: !prevState.releaseholdOverlay,
      ...(updateProceedCancellation === true && {
        proceedCancellation: !prevState.proceedCancellation,
      }),
    }));
  };

  toggleCtripODCViewMoreOverlay = () => {
    this.setState((prevState) => ({
      ctripODCViewMoreOverlay: !prevState.ctripODCViewMoreOverlay,
    }));
  };

  toggleServiceGuaranteeOverlay = () => {
    this.setState((prevState) => ({ serviceGuranteeOverlay: !prevState.serviceGuranteeOverlay }));
  };

  toggleSecureList = (data = null) => {
    this.setState({
      showsecureCardDetail: !this.state.showsecureCardDetail,
      insuranceDetailRes: data,
    });
  };

  toggleWebcheckinOverlay = (value) => {
    this.setState({
      showWebcheckinOverlay: value,
    });
  };

  toggleHelpingHandOverlay = (value) => {
    this.setState({
      helpingHandOverlay: value,
    });
  };

  hideCallbackBottomSheet = () => {
    this.setState({
      callbackBottomSheet: false,
    });
  };

  handleReload = () => {
    this.setState({
      helpingHandOverlay: false,
      callbackBottomSheet: true,
    });
    //this.reloadWithNewBookingId(this.bookingId, true);
  };

  checklistActionHandler = (action) => {
    const downloadDetail = checkListClickHandler(
      action,
      this.props.holidayBookingApi,
      this.props.holidayPageName,
      this.state.responseJson,
      this.props.holidayData,
    );
    if (!isEmpty(downloadDetail)) {
      this.downloadData = downloadDetail;
    }
  };

  closeSelectFlights = () => {
    this.setState({
      selectFlight: false,
    });
  };
}

FlightBookingDetails.defaultProps = {
  stickyHeader: true,
  holidayBookingApi: false,
  showMyraBtn: true,
};
