/* eslint-disable max-len */
import { Platform } from 'react-native';
import {trackOmniture} from '@mmt/legacy-commons/Common/utils/OmnitureTrackerUtils';
import {fillDepartureArrivalDate} from './utils/CabBookingDetailUtil';
import {LINE_OF_BUSINESS} from '../PostSalesConstant';
import {fullYearDateFormat, twentyFourHourTimeFormat} from '@mmt/legacy-commons/Helpers/dateHelpers';
import CabBookingDetailsConstant from './CabBookingDetailsConstant';
import {findDaysBetweenDates} from '@mmt/legacy-commons/Common/utils/DateUtils';
import CabEventsLogger from './CabEventsLogger';
import isEmpty from 'lodash/isEmpty';

class CabBookingTrackingHelper {
    trackLoadEvent = (pageName, cabBookingResponse, extraData={}, pokusData) => {
      CabEventsLogger.loadEvent(pageName,cabBookingResponse);
      let params = {};
      params = this.getBasicOminitureVariables(pageName, cabBookingResponse, extraData, pokusData);
      trackOmniture(pageName, params);
    };
    trackErrorEvent = (pageName) => {
      CabEventsLogger.errorEvent(pageName)
      const params = {};
      params.pageName = pageName;
      params.m_v15 = pageName;
      trackOmniture(pageName, params);
    };
    trackClickEvent = (pageName, clickEvent) => {
      CabEventsLogger.clickEvent(pageName,clickEvent);
      const params = {};
      params.pageName = pageName;
      params.m_v15 = pageName;
      params.m_c54 = clickEvent;
      trackOmniture(pageName, params);
    };
    getBasicOminitureVariables(pageName, cabResponse, extraData={}, pokusData) {
      const arrivalDate = cabResponse && this.getArrivalDate(cabResponse);
      const vendor = cabResponse?.carBookingInfo?.carDetail?.vendor || '';
      const source = extraData?.CMP || '';
      const params =
        Platform.OS === 'web'
          ? {
              m_v5: arrivalDate,
              cmp: source,
              m_v90: cabResponse?.timeDiff || '',
              m_v20: cabResponse?.uuid || '',
            }
          : {
              m_v6: arrivalDate,
              m_v81: source,
              m_v103: cabResponse?.timeDiff || '',
              m_v54: cabResponse?.uuid || '',
            };
      try {
        params.pageName = pageName;
        params.m_v15 = pageName;
        if (!isEmpty(cabResponse)) {
          params.m_v40 = vendor;
          params.m_v3 = `${cabResponse.carBookingInfo.carDetail.fromCity}|${cabResponse.carBookingInfo.carDetail.toCity}`;
          params.m_v16 = cabResponse.bookingId;
          params.m_v9 = this.getCabType(cabResponse);
          // params.m_v19 = this.getCardState(cabResponse.bookingState.subState) ? this.getCardState(cabResponse.bookingState.subState) : cabResponse.bookingState.subState;
          params.m_v19 = `${cabResponse.bookingState.state} | ${cabResponse.currentEvent}`;
          params.m_v24 = LINE_OF_BUSINESS;
          params.m_v4 = `${this.getDepartureDate(cabResponse)} ${this.getDepartureTime(cabResponse)}`;
          params.Props56 = findDaysBetweenDates(new Date().getTime(), cabResponse.bookingDateTime);
					if(!!pokusData) {
						params.m_v87 = pokusData
					}
        }
        return params;
      } catch {
        return params;
      }
    }
    getCabType(cabResponse) {
      let cabType = '';
      if (cabResponse.carBookingInfo && cabResponse.carBookingInfo.carDetail) {
        switch (cabResponse.carBookingInfo.carDetail.tripType) {
          case 'Outstation_One_Way':
            cabType =  'OS_OW';
            break;
          case 'Airport_Cabs':
            cabType = cabResponse.carAdditionalInfo?.additionalLOBInfo?.tripInfo?.flags?.includes('AP') ?
            'AT_AP' : 'AT_AD';
            break;
          case 'Outstation_Return':
            cabType = 'OS_RT';
            break;
          case 'Hourly_Rentals':
            cabType = 'HR';
            break;
          case 'Multicity_Outstation_RoundTrip':
            cabType = 'OS_MC_RT';
            break;
          case 'Multicity_Outstation_OneWay':
            cabType = 'OS_MC_OW';
            break;
        }
        if(cabResponse.carBookingInfo?.carDetail?.remarks === 'ONSPOT'){
          cabType = cabType + '_Kiosk';
        }
      }
      return cabType;
    }

    getDepartureDate(cabResponse) {
      return (cabResponse.carAdditionalInfo && cabResponse.carAdditionalInfo.departureDetail) ?
        fillDepartureArrivalDate(cabResponse.carAdditionalInfo.departureDetail.departureDate, fullYearDateFormat) : '';
    }
    getDepartureTime(cabResponse) {
      return (cabResponse.carAdditionalInfo && cabResponse.carAdditionalInfo.departureDetail) ?
        fillDepartureArrivalDate(cabResponse.carAdditionalInfo.departureDetail.departureTime, twentyFourHourTimeFormat) : '';
    }
    getArrivalDate(cabResponse) {
      return (cabResponse.carAdditionalInfo && cabResponse.carAdditionalInfo.arrivalDetail) ?
        fillDepartureArrivalDate(cabResponse.carAdditionalInfo.arrivalDetail.arrivalDate, fullYearDateFormat) : '';
    }
    getCardState(state) {
      switch (state) {
        case CabBookingDetailsConstant.PREJOURNEY_DETAILS_AVAIL:
          return 'Driver_Details_Present_Pre_Departure';
        case CabBookingDetailsConstant.PREJOURNEY_DETAILS_UNAVAIL:
          return 'Driver_Details_Absent_Pre_Departure';
        case CabBookingDetailsConstant.POST_DETAILS_AVAIL:
          return 'Driver_Details_Present_Post_Departure';
        case CabBookingDetailsConstant.POST_DETAILS_UNAVAIL:
          return 'Driver_Details_Absent_Post_Departure';
        case CabBookingDetailsConstant.DEFAULT_COMPLETED:
        case CabBookingDetailsConstant.COMPLETTION_TO_FOREVER:
          return 'Trip_Complete_Success';
        case CabBookingDetailsConstant.DEFAULT_CANCELLED:
        case CabBookingDetailsConstant.CAN_INIT_TO_REFUND_DONE:
          return 'Refund_Under_Process';
        case CabBookingDetailsConstant.AFTER_REFUND_DONE:
          return 'Refund_Complete';
        default:
          return '';
      }
      return '';
    }
}


export default new CabBookingTrackingHelper();
