import React from 'react';
import {Text, View, Image, TouchableOpacity} from 'react-native';
import PropTypes from 'prop-types';
import {isEmpty} from 'lodash';
import CommonStyle from '@mmt/legacy-commons/Styles/AtomicCss';
import styles from './refundBreakupCss';
import {getImageIcon} from '../../flights/cancellation/Helpers/FlightCancellationUtility';
import {GreyInfoIcon} from '../../Holiday/HolidayBookingConstants';
import { getFont } from '../../PostSalesStyles';

const AmountBreakup = ({lbl, pricingComponents, showGrouping, handleBottomOverlay}) => {
  const fonts  = getFont(true);
  const addAmountBreakup = components => components.map(component => (
    <>
    <View
      key={component.desc}
      style={styles.fareBrkupRow}
    >
      {showGrouping &&
      <View style={[CommonStyle.flexRow, styles.descLeftWrap]}>
        {!isEmpty(component.img) && <Image style={styles.dbIconSm} source={getImageIcon(component.img)} />}
        <Text style={[styles.fareBrkupTxt, fonts.regularFontFamily]}>{component.desc}</Text>
      </View>
      }
      {!showGrouping &&
      <Text style={[styles.fareBrkupTxt, fonts.regularFontFamily]}>{component.desc}</Text>
      }
      <View style={[CommonStyle.flexRow, CommonStyle.alignSelfFlexEnd, styles.priceText]}>
        {
                    component.showSlasher &&
                    <Text
                      style={[styles.fareBrkupTxtPrice, styles.lineThrough, fonts.regularFontFamily]}
                    >{component.slashedValue}
                    </Text>
                }
        <Text style={[styles.fareBrkupTxtPrice, fonts.regularFontFamily]}>{` ${component.actualValue} `}</Text>
      </View>
    </View>
    {!isEmpty(component.subText) && showGrouping && 
    <View style={styles.fareBrkupRow}>
        <Text style={[CommonStyle.font12, CommonStyle.paddingLeft20, CommonStyle.alignCenter, fonts.regularFontFamily]}>
          {component.subText}
          {component.openOverlay && <TouchableOpacity onPress={() => handleBottomOverlay("TCSInfo")}>
            <Image style={styles.infoIcon} source={GreyInfoIcon} />
          </TouchableOpacity>}
        </Text>
    </View>}
    </>
  ));

  const addBreakup = () => pricingComponents.map((pricingBreakup, index) => {
    const arrayLength = pricingComponents.length;
    return (
      <View
        key={index}
        style={arrayLength === index + 1 ? {marginBottom: 0} : {marginBottom: 20}}
      >
        {
                    pricingBreakup.prop && pricingBreakup.prop.subtitle &&
                    <View>
                      <View style={styles.fareBrkupRowLine} />
                      <View style={styles.fareBrkupRowLineWrapperTxt}>
                        <Text style={[styles.fareBrkupRowLineTxt, fonts.regularFontFamily]}>
                          {pricingBreakup.prop.subtitle}
                        </Text>
                      </View>
                    </View>
                }
        {addAmountBreakup(pricingBreakup.components)}
      </View>
    );
  });

  return (
    <View style={styles.deductionBreakUpWrapper}>
      <View style={[styles.fareBreakupHeading]}>
        <View style={CommonStyle.flexRow}>
          <Text
            style={[styles.fbHeadingTxt, {color: lbl.color}, fonts.blackFontFamily]}
          >{lbl.text}
          </Text>
        </View>
        <Text
          style={[styles.totalPaidPriceTxt, {color: lbl.color}, fonts.blackFontFamily]}
        >{`${lbl.value} `}
        </Text>
      </View>
      {!isEmpty(pricingComponents) &&
        <View style={[styles.fareBreakupDtlsWrapper]}>
          {addBreakup()}
        </View>
            }
    </View>
  );
};

export default AmountBreakup;

AmountBreakup.propTypes = {
  lbl: PropTypes.object.isRequired,
  pricingComponents: PropTypes.array.isRequired,
  showGrouping: PropTypes.bool.isRequired
};
