import React, {Fragment} from 'react';
import TDRCollapsed from './TDRCollapsed';
import RailBookingTrackingHelper from '../../details/RailBookingTrackEventHelper';
import {RAIL_TRACKING_CONSTANT} from '../../details/RailBookingDetailConstant';
class InfoTDR extends React.Component {
    constructor(props) {
        super(props);
        this.tabbingFn = this.tabbingFn.bind(this);
        this.state = {
            activeCard: 0,
            collapseActiveCard: true
        };
    }

    onClickEventTracker = (omnitureClickEvent) => {
        RailBookingTrackingHelper.trackClickEvent(
          RAIL_TRACKING_CONSTANT.RAIL_BOOKING_DETAILS_PAGE,
          undefined,
          omnitureClickEvent,
        );
    };

    tabbingFn(index, nextState) {
        const { tdrRulesAccordianList } = this.props;
        if (nextState && Array.isArray(tdrRulesAccordianList) && tdrRulesAccordianList[index]) {
            this.onClickEventTracker(`tdr_${tdrRulesAccordianList[index].accordianHeading}`);
        }
        this.setState({
            activeCard: index,
            collapseActiveCard: nextState
        });
    }

    render() {
        const accordianList = this.props.tdrRulesAccordianList ? this.props.tdrRulesAccordianList : [];
        return (

            <Fragment>
                {
                    accordianList.map((item, index) => {
                        const isActive = index === this.state.activeCard ? this.state.collapseActiveCard : false
                        return (
                            <TDRCollapsed
                                key={index}
                                index={index}
                                isActive={isActive}
                                item={item}
                                tabbingFn={this.tabbingFn}
                            />
                        )
                    })
                }
            </Fragment>

        );
    }
}

export default InfoTDR;
