import React from 'react';
import { View, Image, Text } from 'react-native';
import styles from './FacilitiesCardCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import FacilitiesList from './FacilitiesList';
import {isNotNullAndEmpty, getActionButton, handleClickEvent, isNullOrEmpty} from '../../utils/HotelBookingDetailUtil';
import { FACILITIES_ICON } from '../../../HotelImageConstants';
import { getStaticData } from '../../../../staticData/staticData';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const FacilitiesCard = ({ response, card, pageName }) => {
  const { fsStyle, ...fonts } = getFont(true);
  const staticData = getStaticData();
  const hotelDetailList = response.hotelBookingInfo && response.hotelBookingInfo.hotelDetailsList && response.hotelBookingInfo.hotelDetailsList.length > 0 &&  response.hotelBookingInfo.hotelDetailsList[0];

  const handleClick = (action) => {
    handleClickEvent(card, action, response, pageName);
  };

  if(isNullOrEmpty(hotelDetailList.hotelAmenities)) {
    return null;
  }

  return (
    <View style={[AtomicCss.whiteCardNew, AtomicCss.marginHz12, AtomicCss.borderRadius16]}>
      <View style={AtomicCss.paddingRight20}>
        <View style={[styles.headerSec, AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom15]}>
          <Image source={FACILITIES_ICON} style={styles.headingIcon} />
          <Text style={[fonts.blackFontFamily, fsStyle.font16, AtomicCss.blackText, AtomicCss.lineHeight20, AtomicCss.flexWrap]}>
            {`${staticData.facilitiesAtText} ${hotelDetailList.name}`}
          </Text>
        </View>
        <FacilitiesList data={hotelDetailList.hotelAmenities} />
      </View>
      <View>
        {card && isNotNullAndEmpty(card.actionList) &&
          getActionButton(card, response, handleClick)}
      </View>
    </View>
  );
};

export default FacilitiesCard;
