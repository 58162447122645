import React from 'react';
import {Image, Text, View} from 'react-native';
import styles from '../GiftCardDetailsCss';
import GiftCardBookingDetailsConstant from '../GiftCardBookingDetailsConstant';
import { PAYMENT_CARD_ICON } from '../GiftCardImageConstants';

const PaymentBreakupCard = (props) => {
	const { paymentBreakup } = props.response;

	const invokeAmountPaidUI = (paymentBreakupMap) => {
		const views = [];
		if (paymentBreakupMap) {
			for (let componentName in paymentBreakupMap) {
				views.push(
					<View style={[styles.Paymentrow, styles.padVertical10]} key={componentName}>
						<Text style={[styles.PaymentLeft, styles.BlackText]}>{componentName}</Text>
						<Text style={styles.BlackText}>{`${paymentBreakupMap[componentName]} `}</Text>
					</View>
				)
			}
		}
		return views;
	}

	if (!paymentBreakup) {
		return null;
	}

	return (
		<View style={[styles.MamiCard, styles.mar10Bt]}>
			<View style={styles.CardContent}>
				<Image
					style={styles.CardIcon}
					source={PAYMENT_CARD_ICON}
				/>
				<View style={[styles.ContentPortion]}>
					<View style={[styles.Paymentrow, styles.mar15Bt]}>
						<View style={styles.PaymentLeft}>
							<Text style={[styles.BlackText, styles.blackFont]}>{paymentBreakup.paymentBreakupAmountPaid.text}</Text>
						</View>
						<Text style={[styles.BlackText, styles.blackFont, styles.AlignRight]}>
							{`${paymentBreakup.paymentBreakupAmountPaid.value} `}
						</Text>
					</View>
					<View style={[styles.Paymentrow, styles.mar10Bt]}>
						<Text style={[styles.BlackText, styles.blackFont]}>{GiftCardBookingDetailsConstant.PAYMENT_BREAKUP}</Text>
					</View>
					{paymentBreakup.paymentBreakupMap && invokeAmountPaidUI(paymentBreakup.paymentBreakupMap)}
				</View>
			</View>
		</View>
	);
}

export default PaymentBreakupCard;