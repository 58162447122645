import getPlatformElevation from '@mmt/legacy-commons/Common/Components/Card/getPlatformElevation';

const { StyleSheet } = require('react-native');

const getStyles = (color, fonts) => {
  return StyleSheet.create({
    container: {
      paddingVertical: 16,
      paddingHorizontal: 14,
      borderBottomWidth: 1,
      borderBottomColor: color.lightGrey3,
    },
    heading: {
      alignItems: 'flex-start',
      marginBottom: 15
    },
    headingText: {
      color: color.black,
      fontSize: fonts.fontSize.font16,
      ...fonts.boldFontFamily,
    },
    bookedText: {
      color: color.defaultTextColor,
      fontSize: fonts.fontSize.font12,
      ...fonts.regularFontFamily,
    },
    timeText: {
      color: color.black,
      fontSize: fonts.fontSize.font12,
      ...fonts.boldFontFamily,
    },
    tagWrapper: {
      borderLeftWidth: 1,
      borderStyle: 'dashed',
      borderLeftColor: color.greyText1,
      borderRightWidth: 1,
      borderRightColor: color.greyText1,
      padding: 3,
      flex: 1,
    },
    fullRefundWrap: {
      backgroundColor: color.darkCyan,
      justifyContent: 'center',
      alignItems: 'center',
      paddingVertical: 2,
    },
    nonRefundWrap: {
      backgroundColor: color.creamWhite,
      justifyContent: 'center',
      alignItems: 'center',
      paddingVertical: 2,
    },
    fullRefundText: {
      color: color.white,
      fontSize: fonts.fontSize.font12,
      ...fonts.boldFontFamily,
    },
    nonRefundText: {
      color: color.black,
      fontSize: fonts.fontSize.font12,
      ...fonts.boldFontFamily,
    },
    buttonRadiusStyle: {
      borderBottomLeftRadius: 16,
      borderBottomRightRadius: 16,
    },
    customStyle: {
      paddingLeft: 14,
      paddingRight: 14,
    },
    viewPolicyText: {
      color: color.azure,
      fontSize: fonts.fontSize.font14,
      ...fonts.boldFontFamily,
    }
  });
};

export const getHtmlstyles = (fonts, color) => {
  return StyleSheet.create({
    p : {
      ...fonts.regularFontFamily,
      color: color.defaultTextColor,
      fontSize: fonts.fontSize.font14
    },
    q : {
      color : color.lightGreen18,
      ...fonts.boldFontFamily
    },
    b : {
      ...fonts.boldFontFamily
    }
  })
}

export default getStyles;
