import React, {Component} from 'react';
import {FlatList, View, Keyboard, BackHandler} from 'react-native';
import {trim, filter} from 'lodash';
import PropTypes from 'prop-types';
import FormDropDownRow from './FormDropDownRow';
import CommonAutoCompleteHeader from '@mmt/legacy-commons/Common/Components/CommonAutoCompleteHeader';
import styles from './DynamicFormCss';
import ImageErrorView from '@mmt/legacy-commons/Common/Components/ImageErrorView';
import {ViewState} from './DynamicFormConstants';

class FormDropdownSelector extends Component {
    static navigationOptions = {
      header: null
    };

    constructor(props) {
      super(props);
      this.state = {
        query: '',
        viewState: ViewState.DEFAULT,
        filteredDestinations: this.props.fields
      };
      this.defaultDestinations = this.props.fields;
    }


    componentDidMount() {
      BackHandler.addEventListener('hardwareBackPress', this.handleBackButton);
    }

    componentWillUnmount() {
      BackHandler.removeEventListener('hardwareBackPress', this.handleBackButton);
    }

    handleBackButton = () => {
      this.onBackPressed();
      return true;
    }

    render() {
      return (
        <View style={styles.destinationFormContainer}>
          <CommonAutoCompleteHeader
            onBackClick={this.onBackPressed}
            onClear={this.clearText}
            query={this.state.query}
            hint="Purpose of Visit"
            fetchList={this.fetchList}
            hideClear
            hideTextInput
          />
          { this.state.viewState === ViewState.DEFAULT && this.populateVisaDestinations(this.defaultDestinations) }
          { this.state.viewState === ViewState.ERROR && this.renderErrorView() }
          { this.state.viewState === ViewState.SHOW_DETAIL && this.populateVisaDestinations(this.state.filteredDestinations) }
        </View>
      );
    }

    populateVisaDestinations(visaDestinations) {
      return (<FlatList
        data={visaDestinations}
        style={{paddingTop: 4}}
        keyboardShouldPersistTaps="handled"
        renderItem={({item}, index) => (
          <FormDropDownRow
            onDestinationSelected={this.onDestinationSelected}
            destination={item}
            key={`${item.key}${index}`}
            type="autocomplete"
          />
            )}
        keyExtractor={(item, index) => `${item.key}${index}`}
      />);
    }

      fetchList = (query) => {
        this.setState({
          query
        });
        const queryText = trim(query);
        if (queryText.length > 2) {
          this.filterDestinations(queryText);
        } else {
          this.setState({
            viewState: ViewState.DEFAULT
          });
        }
      };

      filterDestinations(queryText) {
        const searchText = queryText.toLowerCase();
        const filteredDestinations = filter(
          this.defaultDestinations,
          dest => dest.value.toLowerCase().indexOf(searchText) !== -1
        );
        if (filteredDestinations && filteredDestinations.length > 0) {
          this.setState({
            filteredDestinations,
            viewState: ViewState.SHOW_DETAIL
          });
        } else {
          this.setState({
            viewState: ViewState.ERROR
          });
        }
      }


      renderErrorView = () => {
        Keyboard.dismiss();
        return (
          <ImageErrorView
            retry={query => this.reportDestination(query)}
            title="No visa destination found"
            subTitle=""
            retryData=""
          />
        );
      }

    onBackPressed = () => {
      this.props.onBackPressed();
    };

    clearText = () => {
      this.setState({
        query: '',
        viewState: ViewState.DEFAULT
      });
    };

    onDestinationSelected = (destination) => {
      Keyboard.dismiss();
      this.props.onSelected(destination);
    };
}


FormDropdownSelector.propTypes = {
  onSelected: PropTypes.func.isRequired,
  fields: PropTypes.array.isRequired,
  onBackPressed: PropTypes.func.isRequired
};

export default FormDropdownSelector;
