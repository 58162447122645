import React, {useRef} from 'react';
import {
    View,
    StyleSheet,
    Animated,
    Image,
    TouchableOpacity,
    Text,
    Platform
} from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';

const FullPageOverlay = ({
                             to,
                             handleClose,
                             children,
                             headerName
                         }) => {
    const initialBottomPosition = -to - 20;
    const bottomPosition = useRef(new Animated.Value(initialBottomPosition))
        .current;


    const close = () => {
        Animated.timing(bottomPosition, {
            toValue: initialBottomPosition
        }).start((finished) => {
            if (finished) {
                handleClose();
            }
        });
    };


    return (
        <Animated.View
            style={[styles.container]}
        >
            {handleClose && (
                <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, styles.HeaderSection]}>
                    <TouchableOpacity
                        onPress={close}
                        activeOpacity={0.8}
                    >
                        <Image
                            source={require('@mmt/legacy-assets/src/ic_close_blue.webp')}
                            style={styles.closeBtnImg}
                        />
                    </TouchableOpacity>
                    <Text style={[AtomicCss.flex1, AtomicCss.font14, AtomicCss.boldFont, AtomicCss.defaultText]}>{headerName} </Text>
                </View>
            )}
            {children}
        </Animated.View>
    );
};

const styles = StyleSheet.create({

    container: {
        zIndex: 1,
        flex: 1,
        ...getPlatformElevation(Platform.select({ios: 2, android: 2, web:0})),
        backgroundColor: '#fff',
        position: 'absolute',
        width: '100%',
        height: '100%'
    },
    closeBtnImg: {
        width: 12,
        height: 12,
        padding: 4,
        overflow: 'hidden',
        marginRight: 10
    },
    HeaderSection: {
        width: '100%',
        height: 56,
        borderBottomWidth: 1,
        borderBottomColor: '#e7e7e7',
        paddingHorizontal: 14,
        marginTop: 24
    }
});

export default FullPageOverlay;
