import React from 'react';
import { View, Image, TouchableOpacity, StyleSheet } from 'react-native';
import DatePickerWeb from '@web/components/DatePicker';
import {CALENDAR_ICON} from "../../Utils/RailImageConstant";
import {fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import TdrFormComponent from "../TdrForm/TdrFormComponent";
import { getStaticData } from '../../../staticData/staticData';

const CalendarOverlay = ({ config, onClose }) => {
    return (
        <View style={styles.overlayContainer}>
            <TouchableOpacity style={styles.overlayBg} activeOpacity={0.5} onPress={onClose} />
            <View style={styles.overlayContent}>
                <DatePickerWeb config={config} enableLocale />
            </View>
        </View>
    );
}

const TdrDatePicker = ({maxDate, updateFunction, setPopOverContent, closeShowCalenderPopup, EFTDate}) => {
    const fromMonth = new Date(1900, 0);
    const today = new Date();
    const toMonth = new Date(today.getFullYear(), 11);
    const staticData = getStaticData();
    const {enterDateText, cancelText, okText} = staticData;

    const pickerConfig = {
        selectedDate: EFTDate || new Date(),
        handleDateChange: (selectedDay) => {
            updateFunction(selectedDay);
        },
        format : "YYYY-MM-DD",
        fromMonth: fromMonth,
        toMonth: toMonth,
        showCalBtn:true,
        onClose: closeShowCalenderPopup,
        confirmBtnText: okText,
        cancelBtnText: cancelText
    }
    return (
        <TouchableOpacity onPress={() => {
            setPopOverContent(
                <CalendarOverlay
                    config={pickerConfig}
                    onClose={() => setPopOverContent(null)}
                />
            );
        }}>
             <TdrFormComponent
                    label=""
                    placeholderTxt={enterDateText}
                    updateFunction = {updateFunction}
                    inputValue = {EFTDate}
                />
            <Image style={styles.calIcon} source={CALENDAR_ICON} />
        </TouchableOpacity>
    );
}

export default TdrDatePicker;


const styles = StyleSheet.create({
    fieldTxt: {
        fontSize: 12,
        marginBottom: 10,
        fontFamily: fonts.bold,
        color: '#000'
    },
    calIcon: {
        width: 15,
        height: 15,
        position: 'absolute',
        right: 10,
        paddingLeft: 10,
        top: 18,
        zIndex: 0,
    },
    errorText: {
        marginTop: 3,
        color: '#eb2026',
        fontSize: 12,
        fontFamily: 'Lato-Regular',
    },
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
    },
    overlayContainer: {
        justifyContent: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        zIndex: 1000,
        elevation: 3,
        alignItems: 'center'
    },
    overlayBg: {
        backgroundColor: 'rgba(0,0,0,.5)',
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        zIndex: 1
    },
    overlayContent: {
        backgroundColor: '#fff',
        position: 'relative',
        zIndex: 100,
        justifyContent: 'flex-end',
        borderRadius: 8,
        alignItems: 'center',
        margin: 20
    }
});
