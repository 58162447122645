import React from 'react';
import {Text, View} from "react-native";
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import styles from '../../../flights/modules/details/components/PaymentPriceBreakup/FareBreakUp/FareBreakUpCss'

function FareDetails ({paymentBreakup}) {
    return (
        <View style={[AtomicCss.whiteBg, AtomicCss.marginBottom10]}>
            <View style={[styles.CardContentFull]}>
                <View style={styles.cardHeadingWrapper}>
                    <Text style={styles.cardHeading}>Price Breakup</Text>
                </View>
                <View style={styles.fareBreakupInnerSection}>
                    {
                        paymentBreakup && paymentBreakup.map(payment =>
                                <View style={[styles.fareBreakupInnerRowSection]}>
                                <View style={[styles.rowSection]}>
                                <Text style={styles.fareBkHeadingTxt}>{payment.label}</Text>
                                <Text style={[styles.fareBkHeadingTxt]}>{payment.amountText}</Text>
                                </View>
                                    {
                                        payment.breakup && payment.breakup.map(breakup => (
                                            <View style={[styles.rowSection, AtomicCss.marginLeft20, AtomicCss.paddingTop3]}>
                                                <Text
                                                    style={[styles.fareBkHeadingTxt]}>{breakup.label}</Text>
                                                <Text
                                                    style={[styles.fareBkHeadingTxt]}>{breakup.amountText}</Text>
                                            </View>
                                        ))
                                    }

                                </View>
                        )
                    }


                </View>

            </View>
        </View>
    )
}

export  default  FareDetails;
