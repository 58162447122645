import React from 'react';
import { View, Text, TouchableOpacity, Image } from 'react-native';
import createStyle from '../DateChangeCard/DateChangeCardCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getFont } from '../../../../../PostSalesStyles';
import { useTheme } from '../../../../../theme/theme.context';
import isEmpty from 'lodash/isEmpty';

type ctripODCdetailItemType = {
  popupInfo: popupInfoType;
  heading: string;
  subheading: string;
};

type popupInfoType = {
  heading: string;
  desc: string;
  clickableText: string;
};

interface OverlayProps {
  delayedOdcDetails: ctripODCdetailItemType;
  toggleCtripODCViewMoreOverlay: () => void;
}

const CtripODCCard: React.FC<OverlayProps> = ({
  delayedOdcDetails,
  toggleCtripODCViewMoreOverlay,
}) => {
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyle(theme,fonts);
  const { heading, subheading, popupInfo } = delayedOdcDetails;
  const { clickableText } = popupInfo || {};
  return (
    <View
      style={[styles.dateChangeHeading, AtomicCss.flex1, AtomicCss.flexRow, AtomicCss.alignCenter]}
    >
      <View style={styles.dateChangeBorderLeftHighLiter}>
        <Text style={[styles.ctripODCHeading, fsStyle.font14, AtomicCss.defaultText, fonts.regularFontFamily]}>
          {heading}
        </Text>
        <View style={styles.ctripODCSubHeading}>
          <Text style={[fsStyle.font14, AtomicCss.defaultText, fonts.regularFontFamily]}>
            {subheading}
          </Text>
          {!isEmpty(clickableText) && (
            <TouchableOpacity
              style={styles.marginBetweenText}
              onPress={toggleCtripODCViewMoreOverlay}
            >
              <Text style={[fsStyle.font14, fonts.regularFontFamily, styles.linkBtnTxt]}>
                {clickableText}
              </Text>
            </TouchableOpacity>
          )}
        </View>
      </View>
    </View>
  );
};

export default CtripODCCard;
