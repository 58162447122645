import React from 'react';
import {ScrollView, View} from 'react-native';
import PropTypes from 'prop-types';
import Actions from '../../navigation/postSalesNavigation';
import styles from '../styles/CabMamiCss';
import FareDetails from './FareDetails';
import CommonHeader from '@mmt/legacy-commons/Common/Components/Header/CommonHeader';
import {commonheaderIcon} from '../CabImageConstants';

class CabPaymentDetails extends React.Component {
  static navigationOptions = {
    header: null
  }
  render() {
    return (
      <View style={[styles.GreyBg, {flex: 1}]}>
        <CommonHeader
          headerText="Price Breakup"
          imgSrc={commonheaderIcon}
          backPressHandler={this.onBackPress}
        />
        <ScrollView>
          <FareDetails
            response={this.props.response}
            pageName={this.trackPageName}
          />
        </ScrollView>
      </View>
    );
  }
    onBackPress = () => {
      Actions.pop();
    }
}
CabPaymentDetails.propTypes = {
  response: PropTypes.object.isRequired
};
export default CabPaymentDetails;

