import React from 'react';
import { View, Text, TouchableOpacity, Image } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getStyles, { getHtmlStyle } from './styles';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import { CROSS_ICON, infoIcon } from 'apps/post-sales/src/cab/CabImageConstants';
import isEmpty from 'lodash/isEmpty';
import HTMLView from 'react-native-htmlview';
import { RefundIssuedOverlayProps } from '../../../../types';

const RefundIssuedOverlay: React.FC<RefundIssuedOverlayProps> = ({
  handleBottomOverlay,
  pageName,
  cardData,
}) => {
  const { refundIssuedCardData } = cardData || {};
  const { refundBreakupData } = refundIssuedCardData || {};
  const { heading, breakupList, refundAmount, footerText } = refundBreakupData || {};
  const { fsStyle, ...fonts } = getFont();
  const { psTheme: theme } = useTheme();
  const styles = getStyles(fonts, theme);

  return (
    <View style={styles.container}>
      <View style={styles.headingSection}>
        {!!heading && (
          <View style={[AtomicCss.flex1]}>
            <Text style={styles.headingText}>{heading}</Text>
          </View>
        )}
        <TouchableOpacity onPress={() => handleBottomOverlay('')}>
          <Image style={styles.crossIconStyle} source={CROSS_ICON} />
        </TouchableOpacity>
      </View>
      <View style={styles.breakupListWrapper}>
        {!isEmpty(breakupList) &&
          breakupList.map((payment, index) => {
            const { text, amountText, isHtmlText, htmlTextColor, amountTextColor } = payment;
            return (
              <View key={index} style={styles.section}>
                {!!isHtmlText ? (
                  <HTMLView value={text} stylesheet={getHtmlStyle(fonts, theme, htmlTextColor)} />
                ) : (
                  <Text style={styles.paymentLabel}>{text}</Text>
                )}
                {!!amountText && (
                  <Text
                    style={[
                      styles.paymentValue,
                      { color: amountTextColor ? amountTextColor : theme.color.black },
                    ]}
                  >
                    {amountText}
                  </Text>
                )}
              </View>
            );
          })}
      </View>
      <View style={styles.footer}>
        {!isEmpty(refundAmount) && (
          <View style={styles.section}>
            {!!refundAmount.text && (
              <Text style={styles.refundAmountLabel}>{refundAmount.text}</Text>
            )}
            {!!refundAmount.amountText && (
              <Text style={styles.refundAmountValue}>{refundAmount.amountText}</Text>
            )}
          </View>
        )}
         {!!footerText && (
          <View style={[AtomicCss.flexRow, AtomicCss.marginTop5]}>
            <Image source={infoIcon} style={styles.iconImag} />
            <View style={{ flex: 1, flexShrink: 1 }}>
              <Text style={styles.footerText}>{footerText}</Text>
            </View>
          </View>
        )}
      </View>
    </View>
  );
};

export default RefundIssuedOverlay;
