import { StyleSheet } from 'react-native';

export default (theme, fonts) =>
  StyleSheet.create({
    overlayContentWrapper: {
      backgroundColor: theme.color.white,
      padding: 30,
      position: 'relative',
      zIndex: 1,
      width: '80%',
      borderRadius: 4,
      height: 215,
    },
    headingWrapper: {
      width: '100%',
    },
    iconStyle: {
      width: 60,
      height: 60,
      marginBottom: 10,
    },
    headingTxt: {
      ...fonts.blackFontFamily,
      color: theme.color.black,
      fontSize: 16,
      lineHeight: 25,
      textAlign: 'center',
    },
    subHeadingTxt: {
      ...fonts.regularFontFamily,
      color: theme.color.black,
      fontSize: 14,
      lineHeight: 25,
      textAlign: 'center',
    },
    btnWrapper: {
      marginTop: 14,
      width: '100%',
    },
    /* with gradient bg css*/
    overlayContentWithBgWrapper: {
      padding: 30,
      position: 'relative',
      zIndex: 1,
      width: '80%',
      height: 215,
      borderRadius: 4,
    },
    overlayContentWithBgWrapperFailed: {
      height: 224,
    },
    headingWrapperFailed: {
      marginTop: 12,
    },
    withBgStyle: {
      width: 300,
      height: 202,
      position: 'absolute',
      bottom: 0,
      right: 0,
    },
    withBgStyleFailed: {
      width: 188,
      height: 125,
    },
    headingWithBgTxt: {
      ...fonts.blackFontFamily,
      color: theme.color.black,
      fontSize: 24,
      lineHeight: 27,
      marginBottom: 4,
    },
    subHeadingWithBgTxt: {
      ...fonts.regularFontFamily,
      color: theme.color.black,
      fontSize: 14,
      lineHeight: 25,
    },
    btnWithBgWrapper: {
      marginTop: 20,
      width: 150,
    },
  });
