import React from 'react';
import getCabsSummaryData from './cabsCardAdapter';
import {
    ActionsContent,
    DescriptionSection,
    MainContent,
    SummaryContainer,
    JourneyInfoText,
    StatusInfoSection,
    CardInfoSection,
    WhereToGoTopImage,
} from '../cardElements';
import MSRRefundInfoCard from '../MSRRefundInfoCard';
import { CabSummaryDataProps, MsrTicket } from '../cardInterfaces';
interface CabsSummaryCardProps {
    response: CabSummaryDataProps;
    msrTicket: MsrTicket;
    isUpcomingOrOnTripFirstBooking: boolean;
    topImage: string;
}

function CabsSummaryCard({ response, msrTicket, isUpcomingOrOnTripFirstBooking, topImage }: CabsSummaryCardProps) {
    const cardData = getCabsSummaryData(response, msrTicket);
    const {
        iconUrl,
        from,
        to,
        paxInfo,
        actions,
        myRequestsCard,
        carName,
        startDateText,
        startsIn,
        endDateText,
        startTimeText,
        endTimeText,
        lobName,
        bookingId,
        status,
        infoCardStatusText,
        infoCardStatusCTA,
        openDetails,
        handleInfoPress } = cardData || {};

    return (
        <SummaryContainer bookingId={response.bookingID}>
            {topImage && isUpcomingOrOnTripFirstBooking &&
                <WhereToGoTopImage topImageText={startsIn} topImage={topImage} />
            }
            <MainContent onPress={openDetails}>
                <DescriptionSection>
                    <CardInfoSection
                        iconUrl={iconUrl}
                        name={carName}
                        passengerDetails={paxInfo}
                    />
                    <JourneyInfoText
                        from={from}
                        startDateTime={startTimeText}
                        startDateText={startDateText}
                        to={to}
                        endDateTime={endTimeText}
                        endDateText={endDateText}
                    />
                    {infoCardStatusText && <StatusInfoSection statusText={infoCardStatusText} statusCta={infoCardStatusCTA} handleInfoPress={handleInfoPress} />}
                </DescriptionSection>
            </MainContent>
            <ActionsContent actions={actions} isInfoCardShown={!!infoCardStatusText}/>
            {myRequestsCard && <MSRRefundInfoCard data={myRequestsCard} lobName={lobName} status={status} bookingId={bookingId} />}
        </SummaryContainer>
    )
}

export default CabsSummaryCard;