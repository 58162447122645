import React from 'react';
import PropTypes from 'prop-types';
import {View, Text, Image, Platform, StyleSheet, TouchableOpacity} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import {fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import {getTopCardColor} from '../HolidayBookingDetailUtils';
import {AndroidBackArrow, IOSBackArrow} from '../HolidayBookingConstants';
import {themeColors} from '../../PostSalesStyles';

class TopStickyCard extends React.Component {
  static navigationOptions = {
    header: null
  };

  render() {
    const {topCard, bookingState} = this.props;
    const travellersLabel = `${topCard.noOfTravellers} Travellers`;
    return (
      <LinearGradient
        start={{
          x: 1.0,
          y: 0.0
        }}
        end={{
          x: 0.0,
          y: 1.0
        }}
        colors={getTopCardColor(bookingState)}
        style={[styles.TopstickyHeader, styles.bgTransparent]}
      >
        <View style={styles.topStatusBar}>
          <View style={styles.stickyBarWrapper}>
            <TouchableOpacity style={styles.backArrowWrapper} onPress={this.props.goBack}>
              <Image
                style={ (styles.mar10Bt, Platform.OS === 'ios' ?[styles.IOSbackarrow]:  [styles.backarrow]) }
                source={Platform.OS === 'ios' ? IOSBackArrow : AndroidBackArrow}
              />
            </TouchableOpacity>
            <View style={styles.stickyInfoWrapper}>
              <View style={styles.stickyTxtWrapper}>
                <Text
                  numberOfLines={1}
                  ellipsizeMode="tail"
                  style={styles.stickyTxt}
                >{this.props.topCard.packageName}
                </Text>
              </View>
              <View style={styles.stickyTxtWrapper}>
                <Text style={styles.stickyTxt2}>{topCard.travelDates}</Text>
                <View style={styles.roundDiscGrey}/>
                <Text style={styles.stickyTxt2}>{travellersLabel}</Text>
              </View>
            </View>
          </View>
        </View>
      </LinearGradient>
    );
  }
}

const styles = StyleSheet.create({
  topStatusBar: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 54
  },
  backArrowWrapper: {
    width: 44,
    height: 44,
    alignItems: 'center',
    justifyContent: 'center'
  },
  backarrow: {
    width: 16,
    height: 16,
    tintColor: themeColors.backArrowTintColor
  },
  IOSbackarrow: {
    width: 10,
    height: 16
  },
  stickyBarWrapper: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center'
  },
  stickyTxtWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  oneWayIconStyle: {
    width: 10,
    height: 8,
    marginHorizontal: 5
  },
  stickyInfoWrapper: {
    paddingVertical: 10,
    alignItems: Platform.OS === 'ios' ? 'center' : 'flex-start',
    flex: 1
  },
  stickyTxt: {
    color: themeColors.topCardText,
    fontFamily: fonts.black,
    backgroundColor: 'transparent',
    marginBottom: 5
  },
  stickyTxt2: {
    color: themeColors.lightText,
    fontFamily: fonts.regular,
    backgroundColor: 'transparent',
    fontSize: 12
  },
  TopstickyHeader: {
    // zIndex:5,
  },
  ShareImgWrapper: {
    width: 40,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center'
  },
  shareIcon: {
    width: 19,
    height: 12
  },
  roundDiscGrey: {
    height: 4,
    width: 4,
    backgroundColor: '#4a4a4a',
    marginHorizontal: 4,
    borderRadius: 3
  },
  mar10Bt: {
    marginBottom: 10
  },
});

TopStickyCard.propTypes = {
  goBack: PropTypes.func.isRequired,
  topCard: PropTypes.object.isRequired,
  bookingState: PropTypes.object.isRequired
};

export default TopStickyCard;
