import {colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from '@mmt/legacy-commons/Common/Components/Card/getPlatformElevation';
import {Platform} from 'react-native';

const styles = {
  hide: {display: 'none'},
  flexRow: {flexDirection: 'row'},
  flex1: {flex: 1},
  elevation1: {elevation: 1},
  elevation2: {elevation: 2},
  elevation3: {elevation: 3},
  makeRelative: {position: 'relative'},
  alignCenter: {alignItems: 'center'},
  textCenter: {textAlign: 'center'},
  lightText: {color: colors.lightTextColor},
  AlignRight: {
    textAlign: 'right'
  },
  CenterText: {
    alignSelf: 'center'
  },
  defaultText: {color: colors.defaultTextColor},
  whiteCardSection: {
    backgroundColor: '#ffffff',
    ...getPlatformElevation(2),
    borderBottomWidth: 1,
    borderBottomColor: colors.lightGrey
  },
  linethrought: {textDecorationLine: 'line-through', textDecorationColor: '#C0C0C0'},
  spaceBetween: {justifyContent: 'space-between'},
  JustifyStart: {justifyContent: 'flex-start'},
  JustifyEnd: {justifyContent: 'flex-end'},
  JustifyCenter: {justifyContent: 'center'},
  whiteBg: {backgroundColor: colors.white},
  greyBg: {backgroundColor: '#f2f2f2'},
  lineHeight18: {lineHeight: 18},
  lineHeight20: {lineHeight: 20},
  marginTop10: {marginTop: 10},
  marginTop5: {marginTop: 5},
  marginTop15: {marginTop: 15},
  marginBottom0: {marginBottom: 0},
  marginBottom3: {marginBottom: 3},
  marginBottom5: {marginBottom: 5},
  marginBottom8: {marginBottom: 8},
  marginBottom10: {marginBottom: 10},
  marginBottom12: {marginBottom: 12},
  marginBottom15: {marginBottom: 15},
  marginBottom16: {marginBottom: 16},
  marginBottom20: {marginBottom: 20},
  marginBottom24: {marginBottom: 24},
  marginBottom26: {marginBottom: 26},
  marginLeft12: {marginLeft: 12},
  mar5Rt: {marginRight: 5},
  marginRight10: {marginRight: 10},
  mar15Rt: {marginRight: 15},
  paddingTop3: {paddingTop: 3},
  paddingTop10: {paddingTop: 10},
  padding20: {padding: 20},
  paddingLeft20: {paddingLeft: 20},
  paddingLeft25: {paddingLeft: 25},
  paddingLeft30: {paddingLeft: 30},
  paddingLeft5: {paddingLeft: 5},
  paddingRight16: {paddingRight: 16},
  paddingRight25: {paddingRight: 25},
  paddingBottom8: {paddingBottom: 8},
  paddingBottom12: {paddingBottom: 12},
  paddingBottom15: {paddingBottom: 15},
  paddingBottom20: {paddingBottom: 20},
  whiteCard: {
    backgroundColor: '#ffffff',
    padding: 15,
    marginVertical: 8,
    ...getPlatformElevation(2),
    borderBottomWidth: 1,
    borderBottomColor: colors.lightGrey
  },
  boldFont: {
    fontFamily: fonts.bold
  },
  blackFont: {fontFamily: fonts.black},
  regularFont: {fontFamily: fonts.regular},
  lightFont: {fontFamily: fonts.light},
  font10: {fontSize: 10},
  font11: {fontSize: 11},
  font12: {fontSize: 12},
  font13: {fontSize: 13},
  font14: {fontSize: 14},
  font15: {fontSize: 15},
  font16: {fontSize: 16},
  font17: {fontSize: 17},
  font18: {fontSize: 18},
  font20: { fontSize: 20 },
  font22: {fontSize: 22},
  font26: {fontSize: 26},
  blackText: {color: colors.black},
  whiteText: {color: colors.white},
  bgTransparent: {backgroundColor: colors.transparent},
  transparentText: {color: colors.transparent},
  transperantBgrnd: {
    ...Platform.select({
      ios: {
        backgroundColor: 'transparent'
      }
    })
  },
  lightGreyText: {color: colors.lightGrey},
  greyText: {color: colors.grey},
  defaultTextColor: {color: colors.defaultTextColor},
  darkGreyText: {color: colors.darkGrey},
  lightTextColor: {color: colors.lightTextColor},
  redText: {color: colors.red},
  greenText: {color: colors.green},
  errorRedText: {color: colors.errorRed},
  linkText: {color: colors.linkBlue},
  LightText: {color: colors.lightTextColor},
  azure: {color: colors.azure},
  lightGrey: {color: '#747474'},
  btn: {
    height: 48,
    borderRadius: 3,
    overflow: 'hidden',
    margin: 12
  },
  btnGradient: {
    height: '100%',
    width: '100%',
    paddingHorizontal: 15,
    alignItems: 'center',
    justifyContent: 'center'
  },
  btnWrapper:{width: '100%', backgroundColor: '#c2c2c2', height: '100%', alignItems: 'center', justifyContent: 'center'},
  paddingHz: {paddingLeft: 60, paddingRight: 60, paddingTop: 200}
};

export default styles;
