import React from 'react';
import PropTypes from 'prop-types';
import {Image, Text, View} from 'react-native';
import getStyles from '../styles/BusMamiCss';
import {getActionButton, handleBusClickEvent, isNotNullAndEmpty, getButtonStyle, onPartialCancelTapped} from '../utils/BusBookingDetailUtil';
import BusBookingDetailsConstant, { BusTrackingConstant } from '../BusBookingDetailsConstant';
import BusBookingTrackingHelper from '../BusBookingTrackingHelper';

class ChangePlans extends React.Component {
  render() {
    const styles = getStyles();
    return (
      <View style={[styles.MamiCard, styles.mar10Bt]}>
        {
          !this.props.fromCancellationPolicy &&
          <View style={styles.CardContent}>
            <View style={styles.ContentPortion}>
              <Text style={[styles.blackFont, styles.BlackText]}>CHANGE IN PLANS?</Text>
            </View>
          </View>
        }
        <View
          style={isNotNullAndEmpty(this.props.card.actionList) ? [getButtonStyle(this.props.card.actionList), styles.transperantBg] : styles.hideView}
        >
          {getActionButton(this.props.card, this.props.response, this.handleClick)}
        </View>
      </View>
    );
  }

  handleClick = (action) => {
    const {busAdditionalInfo} = this.props.response || {};
    const {reviewDetail} = busAdditionalInfo||{};
    const {extraData} = reviewDetail || {};
    const {flexiChangeData} = extraData || {};
    const {flexiChangeEligible} = flexiChangeData || {};
    if(flexiChangeEligible){
     this.props.confirmCancelOverlay(BusBookingDetailsConstant.CONFIRM_CANCEL_OVERLAY);
    }
    else{
      BusBookingTrackingHelper.trackClickEvent(
        BusTrackingConstant.BUS_BOOKING_DETAILS_PAGE,
        this.props.response,
        BusTrackingConstant.CANCEL_BOOKING,
      );
    onPartialCancelTapped(this.props.response, this.props.mmtAuth, this.props.loggingTrackInfo);
    }
  };
}

ChangePlans.propTypes = {
  response: PropTypes.object.isRequired,
  card: PropTypes.object.isRequired,
  pageName: PropTypes.string.isRequired,
  mmtAuth: PropTypes.string.isRequired,
  loggingTrackInfo: PropTypes.string.isRequired
};

export default ChangePlans;
