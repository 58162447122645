import {colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles';

const styles = {
  contentTitle: {
    fontSize: 28,
    color: colors.black,
    fontFamily: fonts.light,
    marginBottom: 18
  },
  contentDesc: {
    fontSize: 14,
    color: colors.defaultTextColor,
    fontFamily: fonts.bold,
    marginBottom: 20
  },
  contentFooter: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  actionBtn: {
    paddingVertical: 10
  },
  actionBtnText: {
    fontSize: 16,
    color: colors.azure,
    fontFamily: fonts.medium
  },
  OTPFieldWrapper: {
    borderColor: '#008cff',
    borderWidth: 1,
    borderRadius: 4,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10
  },
  errorStateStyle: {
    borderColor: 'red'
  },
  OTPInput: {
    height: 40,
    paddingHorizontal: 10,
    width: '70%',
    fontSize: 14,
    fontFamily: fonts.bold
  },
  OTPDurationWrapper: {
    paddingHorizontal: 10,
    paddingVertical: 12
  }
};

export default styles;

