import { StyleSheet } from 'react-native';

export default (theme, fonts) => StyleSheet.create({
    cdiWrapper: {
      flexDirection: 'row',
      paddingVertical: 12,
      paddingHorizontal: 16,
      backgroundColor: theme.color.creamWhite,
    },
    infoIconStyle: {
      width: 16,
      height: 16,
      marginRight: 8,
      marginTop: 4,
      tintColor: theme.color.lightYello,
    },
    infoText: {
      color: theme.color.lightYello,
      fontSize: fonts.fontSize.font13,
      lineHeight: 18,
      flex: 1,
    },
  });