import { useEffect, useState, useRef } from 'react';
import { extractLastMinutePnrs } from 'core-ui-lib/flight/common/Cancellation/helper';
import PostsaleFirebaseApp from '../../../../../../firebase/postsalesFirebase';
import isEmpty from 'lodash/isEmpty';
import { fetchLastMinuteErrorData} from '../../../data/api';
import { ViewState, FIREBASE_INIT_TIMEOUT  } from 'core-ui-lib/flight/common/Cancellation/constant.js';

const useFirebase = (firebaseConfig, bookingInfo = {}) => {
    const { bookingId, segmentWiseInfo, isCorporateBooking, cancellationDoneResponseJson} = bookingInfo;
    const lastMinuteCancellationRef = useRef();
    const [viewState,setViewState] = useState(ViewState.VIEW_DETAIL);
    const [lastMinuteErrorPageData, setLastMinuteErrorPageData] = useState(null);

    useEffect( () => {
        setTimeout(() => { initFirebaseDatabase() }, FIREBASE_INIT_TIMEOUT);
        return () => {
            const { current } = lastMinuteCancellationRef || {};
            const { removeOnValueListeners } = current || {};
            removeOnValueListeners && removeOnValueListeners();
        }
    },[]);

    const initReloadFirebase = () => {
        setViewState(ViewState.VIEW_DETAIL);
        setTimeout(() => { initFirebaseDatabase() }, FIREBASE_INIT_TIMEOUT);
    }

    const initFirebaseDatabase = async () => {
        try {
          const {config, basePath,  tokenKey} = firebaseConfig;
          const { firebaseTokenList = {} } = cancellationDoneResponseJson || {}
          lastMinuteCancellationRef.current = new PostsaleFirebaseApp(config, tokenKey, firebaseTokenList, bookingId);
          const path = basePath + `${bookingId}`;
          const {isInstanceAuthenticated, errorData} = await lastMinuteCancellationRef.current.init(); 
          isInstanceAuthenticated && lastMinuteCancellationRef.current.onValue(path, async (data) => {
            if (!isEmpty(data)) {
              const {
                hasFailedSegmentData,
                failedSegmentData,
                successPnr,
                anySegmentNotInProgress,
              } = extractLastMinutePnrs(segmentWiseInfo, data);
              if (!isEmpty(hasFailedSegmentData) && !isEmpty(failedSegmentData)) {
                let response = await fetchLastMinuteErrorData(bookingId, failedSegmentData, successPnr, isCorporateBooking);
                if (response.ViewState === ViewState.API_ERROR) {
                  setViewState(ViewState.API_ERROR);
                } else {
                  setLastMinuteErrorPageData(response.errorPageData);
                  setViewState(ViewState.ERROR);
                }
              } else if (anySegmentNotInProgress) {
                setViewState(ViewState.VIEW_SUCCESS);
              }
            }
          });
          if(errorData){
            console.log("@Firebase Error: ", errorData);
            setViewState(ViewState.API_ERROR);
          }
        } catch (err) {
          setViewState(ViewState.API_ERROR);
        }
      };
      return [viewState,lastMinuteErrorPageData,initReloadFirebase];
}


export default useFirebase;