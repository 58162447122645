/* eslint-disable max-len */
import React from 'react';
import Actions from '../../navigation/postSalesNavigation';
import PropTypes from 'prop-types';
import {ScrollView, View} from 'react-native';
import styles from '../styles/CabMamiCss';
import CommonHeader from '@mmt/legacy-commons/Common/Components/Header/CommonHeader';
import CabBookingTrackingHelper from '../CabBookingTrackingHelper';
import {getCardObject} from '../utils/CabBookingDetailUtil';
import CancelBooking from './CancelBooking';
import {commonheaderIcon} from "../CabImageConstants";

class CabCancellationDetailsStandAlone extends React.Component {
  constructor(props) {
    super(props);
    this.trackPageName = 'mob:customer support:Cabs:CancellationStandalone';
  }
  render() {
    return (
      <View style={[styles.whiteContainer, styles.transperantBgrnd]}>
        <CommonHeader
          headerText="Cancellation Options"
          imgSrc={commonheaderIcon}
          backPressHandler={this.onBackPress}
        />
        <ScrollView>
          <View style={styles.headerPaddingbtm}>
            <CancelBooking
              key="CancelBooking"
              response={this.props.response}
              pageName={this.trackPageName}
              card={getCardObject()}
            />
          </View>
        </ScrollView>
      </View>
    );
  }
  componentDidMount() {
    CabBookingTrackingHelper.trackLoadEvent('Cab Details: CabInvoiceDetails', this.props.response);
  }
    onBackPress = () => {
      Actions.pop();
    }
}
CabCancellationDetailsStandAlone.propTypes = {
  response: PropTypes.object.isRequired
};
export default CabCancellationDetailsStandAlone;
