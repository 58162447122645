import React from "react";
import createStyles from "../PaxStatusCardCss";
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss'
import TGSBookingConstants from "../../../../../Utils/TGSBookingConstants";
import { View, Text } from "react-native";
import { getFont } from '../../../../../../PostSalesStyles';
import { useTheme } from '../../../../../../theme/theme.context';
import Shimmer from '../../../../../../Rails/components/Loader/Shimmer';
const StatusList = ({ passenger, isLastElement, currentStatus, showPnrStatusLodar, travellerCard }) => {
    const lastItemStyle = isLastElement ? "lastEleStyle" : ''
    const { ticketStatus, name } = passenger || {};
    const { status, text } = currentStatus || ticketStatus || {};
    const fonts = getFont();
    const { psTheme: theme } = useTheme();
    const styles = createStyles(theme, fonts);
    const getStatusStyle = () => {
        let statusStyle = [styles.status];
        switch (status) {
            case TGSBookingConstants.CONFIRMED:
                statusStyle.push(styles.statusConfirm)
                break;
            case TGSBookingConstants.RAC:
                statusStyle.push(styles.statusRAC)
                break;
            case TGSBookingConstants.WAITING_LIST:
                statusStyle.push(styles.statusWaiting)
                break;
            case TGSBookingConstants.TDR:
                statusStyle.push(styles.statusTDR)
                break;
            case TGSBookingConstants.FAILED:
                statusStyle.push(styles.statusFailed)
                break;
            case TGSBookingConstants.CANCELLED:
                statusStyle.push(styles.statusCancelled)
                break;
        }
        return statusStyle;
    }
    const getStatusTextStyle = () => {
        let statusTextStyle = [styles.statusText, fonts.boldFontFamily];
        switch (status) {
            case TGSBookingConstants.CONFIRMED:
                statusTextStyle.push(styles.statusConfirmText)
                break;
            case TGSBookingConstants.RAC:
                statusTextStyle.push(styles.statusRACText)
                break;
            case TGSBookingConstants.WAITING_LIST:
                statusTextStyle.push(styles.statusWaitingText)
                break;
            case TGSBookingConstants.TDR:
                statusTextStyle.push(styles.statusTdrText)
                break;
            case TGSBookingConstants.FAILED:
                statusTextStyle.push(styles.statusFailedText)
                break;
            case TGSBookingConstants.CANCELLED:
                statusTextStyle.push(styles.statusCancelledText)
                break;
        }
        return statusTextStyle;
    }
    function getPaxStatusStyle() {
        const paxStyle = [styles.paxStatus]
        if (!travellerCard) {
            paxStyle.push(AtomicCss.justifyCenter)
        }
        return paxStyle;
    }

    return (
        <View style={[AtomicCss.flexRow, AtomicCss.marginBottom15, styles[lastItemStyle]]}>
            {!!name && <Text style={styles.paxName}>{name}</Text>}
            {showPnrStatusLodar && <Shimmer />}
            {!showPnrStatusLodar && <View style={[...getPaxStatusStyle()]}>
                <View style={[...getStatusStyle()]}>
                    {!!status && <Text style={getStatusTextStyle()}>{status}</Text>}
                </View>
                {!!text && <Text style={styles.statusReport}>{text}</Text>}
            </View>}
        </View>
    )
}

export default StatusList;