import { StyleSheet} from "react-native";
import { DEFAULT_THEME } from '../../../../../theme/default.theme';


const createStyles = <T extends typeof DEFAULT_THEME>(theme: T) => StyleSheet.create({
    infoMessageBox: {
      flexDirection:'row',
      backgroundColor: theme.color.white,
      paddingVertical:5,
      paddingHorizontal:5,
      marginHorizontal: 30,
      borderRadius: 6,
      borderWidth:1,
    },
  
    wcIconStyle: {
      width:24,
      height:24,
      marginRight:4,
    },
  
    wcTextStyle:{
      flex: 1,
      alignSelf: 'center',
      color: theme.color.defaultTextColor
    },
  
    wcTextStyleAccept:{
      color: theme.color.green
    },
    
    wcTextStyleReject:{
      color: theme.color.red6
    },
  
    wcBorderStyle:{
      borderColor: theme.color.lightGrey16
    },
  
    wcBorderStyleAccept:{
      borderColor: theme.color.green
    },
    
    wcBorderStyleReject:{
      borderColor: theme.color.red6
    },
  
  });

  export { createStyles };