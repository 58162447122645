import React from 'react';
import {Text, TouchableOpacity} from 'react-native';
import {colors} from '@mmt/legacy-commons/Styles/globalStyles';
import LinearGradient from 'react-native-linear-gradient';
import PropTypes from 'prop-types';
import getStyles from '../../styles/BusMamiCss';
import reviewStyles from './ReviewButtonCss';
import { getFont } from '../../../PostSalesStyles';

const ReviewButton = ({buttonText, clickHandler, disabled}) => {
  const fonts = getFont(true);
  const styles = getStyles();

  return (
    <TouchableOpacity style= {reviewStyles.ghostBtn} disabled={disabled} onPress={() => clickHandler()} >
      <LinearGradient
        start={{
          x: 1.0,
          y: 0.0
        }}
        end={{
          x: 0.0,
          y: 1.0
        }}
        colors={disabled ? [colors.disabledBtnBg, colors.disabledBtnBg] : [colors.darkBlue, colors.lightBlue]}
        style={[styles.fullWidthBtn]}
      >
        <Text style={[styles.WhiteText,
          styles.CenterText, fonts.font14, fonts.blackFontFamily, styles.transperantBgrnd]}
        >{buttonText}
        </Text>
      </LinearGradient>
    </TouchableOpacity>
  );
}

ReviewButton.propTypes = {
  buttonText: PropTypes.string.isRequired,
  clickHandler: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
};

export default ReviewButton;
