import React from 'react';
import {View, ScrollView, Text, Image, TouchableOpacity, Linking} from 'react-native';
import styles from '../../CSS/VisaMamiCss';
import StickyHeader from '../../../hotel/details/components/StickyHeader';
import logAction from '@mmt/legacy-commons/Helpers/actionsLogger';
import Actions from '../../../navigation/postSalesNavigation';
import PropTypes from 'prop-types';
import {convertStringtoTitleCase} from '@mmt/legacy-commons/Common/utils/StringUtils';
import VisaDetailConstant from '../../Constant/VisaDetailConstant';
import {getDocExtension, getAuthValue} from '../../Util/VisaUtil';
import VisaBookingModule from '@mmt/legacy-commons/Native/VisaBookingModule';
import VisaBookingTrackingHelper from "../../VisaBookingTrackingHelper";

import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import {getCommonHeaders} from '../../../utils/NetworkUtils';
import {formIcon, PassportIcon, TicketIcon, RightArrowBlue, TripHeaderIcon} from '../../Constant/VisaImageConstant';
import { getRootTag } from '@mmt/legacy-commons/AppState/RootTagHolder';

export default class VisaDocument extends React.Component {
    static navigationOptions = {
      header: null
    }
    componentDidMount(){
      if(Platform.OS !== 'web'){
        import ('@mmt/legacy-commons/Common/utils/fileUtils').then(
          downloadFileWithHeaders=>{
            this.downloadFileWithHeaders = downloadFileWithHeaders.downloadFileWithHeaders;
          });
      }
    }
    onBackPress = () => {
      Actions.pop();
      logAction(`${this.pageName}onBackPress`);
    };

    render() {
      const travellerInfo = this.props.response.passengerList;
      return (

        <View style={[styles.VisaMainContainer, styles.bgWhite]}>
          <StickyHeader
            headerColor={['#ffffff', '#ffffff']}
            iconSrc={TripHeaderIcon}
            title="Visa Documents"
            subTitle=""
            titleStyle={[styles.BlackText, styles.boldFont, styles.bgTransparent, styles.font16]}
            subTitleStyle={[styles.BlackText, styles.boldFont, styles.bgTransparent, styles.font16]}
            onBackPress={this.onBackPress}
          />
          <ScrollView>
            <View style={[styles.LandingAllDocument]} >
              {this.getTravellerDocument(travellerInfo)}
            </View>
          </ScrollView>
        </View>
      );
    }

    getTravellerDocument(travellerInfo) {
      const travellerViews = [];
      travellerInfo.forEach((passengerDetail, index) => {
        const passengerName = convertStringtoTitleCase(`${passengerDetail.passengerName.firstname} ${passengerDetail.passengerName.surname}`);
        travellerViews.push(<View><Text style={[styles.BlackText,
                styles.font16,
                styles.boldFont,
                styles.mar10Bt]}
        >
          {index + 1}. {passengerName} &nbsp;
        </Text>
          <View style={[styles.AllDocumentList, styles.mar20Bt]}>
            {this.getDocuments(passengerDetail, passengerName)}
          </View>
        </View>);
      });
      return travellerViews;
    }

    getDocuments(passengerDetail, passengerName) {
      const documenturlList = passengerDetail.documenturlList;
      const documentviews = [];
      documenturlList.forEach((documents, index) => {
        documentviews.push(<TouchableOpacity
          style={[styles.AllDocumentItem,
                styles.flexRow]}
          onPress={() => this.openDocument(documents, passengerName)}
        >
          <Image style={[this.getExtraInfo(documents.recordType, 'style'), styles.mar10Rt]} source={this.getExtraInfo(documents.recordType, 'image')} />
          <View style={[styles.spaceBetween, styles.flexRow, styles.flex1]}>
            <Text style={[styles.font11, styles.blueLink, styles.boldFont]}>{documents.recordName}</Text>
            <Image style={[styles.blueArrow, styles.mar2Tp]} source={RightArrowBlue} />
          </View>
        </TouchableOpacity>);
      });
      return documentviews;
    }

    getExtraInfo(recordType, Type) {
      switch (Type) {
        case 'image':
          switch
          (recordType) {
            case VisaDetailConstant.PASSPORT_FRONT:
            case VisaDetailConstant.PASSPORT_BACK:
            case VisaDetailConstant.PASSPORT_IMAGE:
              return PassportIcon;
            case VisaDetailConstant.HOTEL_VOUCHER:
            case VisaDetailConstant.E_TICKETS:
              return TicketIcon;
            default:
              return formIcon;
          }
        case 'style':
          switch (recordType) {
            case VisaDetailConstant.PASSPORT_FRONT:
            case VisaDetailConstant.PASSPORT_BACK:
            case VisaDetailConstant.PASSPORT_IMAGE:
              return styles.PassportIcon;
            case VisaDetailConstant.HOTEL_VOUCHER:
            case VisaDetailConstant.E_TICKETS:
              return styles.TicketIcon;
            default:
              return styles.formIcon;
          }
        default:
          break;
      }
    }

  async openDocument(documents, passengerName) {
    VisaBookingTrackingHelper.trackClickEvent(
      documents.recordName,
      this.props.response,
      'doc_clicked',
    );
    const docExtension = getDocExtension(documents.documentUrl);
    const { mmtAuth, loggingTrackingInfo } = await HotelBookingModule.getRequestHeader(
      this.props.response.bookingId,
      'Visa_Booking_Details',
    );
    const headers = await getCommonHeaders(mmtAuth, loggingTrackingInfo);
    delete headers.langAware;
    delete headers.psLob;
    headers['Authorization'] = getAuthValue();
    if (docExtension === 'pdf' || documents.recordType === 'Visa') {
 	if(Platform.OS === 'web') {
          VisaBookingModule.downloadFileWithHeaders(documents.documentUrl, documents.recordName + '.' + docExtension, headers);
        }
        else {
          this.downloadFileWithHeaders(documents.documentUrl , documents.recordName + '.' + docExtension, headers);
        }
    } else {
      Actions.visaDocumentViewer({
        url: documents.documentUrl,
        headerText: documents.recordName,
        response: this.props.response,
        headers,
        passengerName,
      });
    }
  }
}

VisaDocument.propTypes = {
  response: PropTypes.object.isRequired,
  pageName: PropTypes.string.isRequired
};
