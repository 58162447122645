import React from 'react';
import { ScrollView, Text, View } from 'react-native';
import { ActionConst } from '../../../navigation/postSalesNavigation';
import Actions from '../../../navigation/postSalesNavigation';
import PropTypes from 'prop-types';
import styles from '../css/SpecialClaimSelectPassengerCss';
import Breadcrumb from '../Breadcrumb/index';
import StickyHeader from '../../../hotel/details/components/StickyHeader';
import SelectPassengers from '../SelectPassengers/index';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import { FLIGHT_CANCELLATION_PREVIEW_URL, getCommonHeaders } from '../../../utils/NetworkUtils';
import { isNetworkAvailable } from '@mmt/legacy-commons/Common/utils/AppUtils';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import ReviewButton from '../SelectPassengers/ReviewButton';
import SpecialClaimBaseClass from './SpecialClaimBaseClass';
import { showLongToast } from '@mmt/legacy-commons/Common/Components/Toast';
import SpecialClaimUtility from '../Helpers/SpecialClaimUtility';
import { isNullOrEmpty } from '../../../hotel/details/utils/HotelBookingDetailUtil';
import CreditShellOverlay from './CreditShellOverlay';
import { openErrorPage } from '../../../utils/PostSaleUtil';
import SpecialClaimTrackingHelper from '../Helpers/SpecialClaimTrackingHelper';
import NeedMoreHelpOverlay from '../../../Common/NeedMoreHelpOverlay';
import { LOBNAMES, NEED_HELP_PAGE, NEED_MORE_HELP } from '../../../PostSalesConstant';
import { HEADER_ICON } from '../../FlightImageConstants';
import { getFont } from '../../../PostSalesStyles';
import { getStaticData } from "../../../staticData/staticData";

const headerIcon = HEADER_ICON;

let paxFareSgmntIdLst = [];

export default class SpecialClaimSelectPassenger extends SpecialClaimBaseClass {
  constructor(props) {
    super(props, 'specialClaimSelectPassenger', 'SpecialclaimSelect');
    paxFareSgmntIdLst = [];
    this.allPaxSelected = false;
    this.specialClaimData = this.props.specialClaimData
      ? JSON.parse(this.props.specialClaimData)
      : null;
    this.partialPax = this.props.partialPax ? JSON.parse(this.props.partialPax) : null;
    this.state = {
      activeStep: 0,
      responseJson: null,
      viewState: ViewState.LOADING,
      selectedPassenger: {},
      creditShellPopupData: undefined,
    };
  }

  render() {
    const { loadingText } = getStaticData();
    return (
      <View style={styles.pageScroll}>
        {this.state.viewState === ViewState.LOADING && this.renderProgressView(loadingText ? loadingText : '')}
        {this.state.viewState === ViewState.NO_INTERNET && this.renderNoNetworkView()}
        {this.state.viewState === ViewState.ERROR && this.renderErrorView()}
        {this.state.viewState === ViewState.SHOW_DETAIL && this.renderPage()}
      </View>
    );
  }

  componentDidMount() {
    super.componentDidMount();
    this.fetchJSONAsync();
  }

  showCreditShellPopup = (actionType) => {
    if (undefined === actionType) {
      this.setState({
        creditShellPopupData: this.state.responseJson.creditShellInfo,
      });
    } else if (actionType === 'DISMISS_POPUP') {
      this.setState({
        creditShellPopupData: undefined,
      });
    } else if (actionType === 'CONTINUE') {
      this.setState({
        creditShellPopupData: undefined,
      });
      this.proceedToReview();
    }
  };

  async fetchJSONAsync() {
    try {
      const hasNetwork = await isNetworkAvailable();
      if (!hasNetwork) {
        this.setState({ viewState: ViewState.NO_INTERNET });
        return;
      }
      const userInfo = await HotelBookingModule.getRequestHeader(
        this.props.bookingID,
        'FLIGHT_CANCELLATION_REVIEW',
      );
      const response = await fetch(FLIGHT_CANCELLATION_PREVIEW_URL, {
        method: 'POST',
        headers: await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo, {
          psLob: LOBNAMES.FLIGHT,
        }),
        body: JSON.stringify(this.getFlightCancellationPreviewRequest()),
      });
      if (response.ok) {
        const body = await response.json();
        if (body.errorPageData) {
          openErrorPage(
            body.errorPageData,
            undefined,
            this.onRetryClicked,
            this.props.bookingID,
            'specialClaim',
            Actions,
            { psLob: LOBNAMES.FLIGHT, isCorporateBooking: this.props.isCorporateBooking },
          );
        } else if (
          body.specialClaimValidationData &&
          body.specialClaimValidationData.rejectedStatusInfo
        ) {
          Actions.falseClaim({
            ...body.specialClaimValidationData.rejectedStatusInfo,
            bookingId: this.props.bookingID,
            type: ActionConst.REPLACE,
          });
          this.trackSpecialClaimFullBlockPageLoad(
            body,
            this.specialClaimData,
            this.props.bookingState,
          );
        } else {
          this.setState({
            responseJson: body,
            viewState: ViewState.SHOW_DETAIL,
            helpingHandOverlay: true,
          });
          this.trackPageLoad(body, this.specialClaimData, this.props.bookingState);
        }
      } else {
        this.setState({ viewState: ViewState.ERROR });
      }
    } catch (error) {
      this.setState({ viewState: ViewState.ERROR });
      console.log(error);
    }
  }

  trackSpecialClaimFullBlockPageLoad(response, specialClaimData, bookingState) {
    this.trackingPageName = this.isIntlFlightBooking(response.lobCode)
      ? 'Mob:Customer support:Intl Flight:Specialclaimselectfullblock'
      : 'Mob:customer support:Dom Flight:Specialclaimselectfullblock';
    SpecialClaimTrackingHelper.trackLoadEvent(
      this.trackingPageName,
      response,
      specialClaimData,
      bookingState,
    );
  }

  fetchData() {
    this.fetchJSONAsync();
  }

  toggleHelpingHandOverlay = (value) => {
    this.setState({
      helpingHandOverlay: value,
    });
  };

  trackHelpingHandEvents = (type, page, click, data) => {
    const pageName = `${this.trackingPageName}_${page}`;
    const trackData = Object.assign({}, this.state.responseJson, {
      ruleTrace: data ? data.ruleTrace : '',
    });
    switch (type) {
      case 'load':
        SpecialClaimTrackingHelper.trackLoadEvent(
          pageName,
          trackData,
          this.specialClaimData,
          this.props.bookingState,
        );
        break;
      case 'click':
        SpecialClaimTrackingHelper.trackClickEvent(pageName, `Flight_${click}`);
        break;
      default:
        break;
    }
  };

  static getSteps(response) {
    if (response && response.scRefundMode) {
      return ['SELECT TRAVELLERS', 'REVIEW', 'CONFIRM'];
    }
    return ['SELECT TRAVELLERS', 'REVIEW'];
  }

  renderPage() {
    const { fsStyle, ...fonts } = getFont(true);
    const staticData = getStaticData();
    return (
      <View style={styles.pageWrapper}>
        <StickyHeader
          iconSrc={headerIcon}
          title={this.getTitle()}
          headerColor={['#ffffff', '#ffffff']}
          titleStyle={[styles.headerTitle, fonts.boldFontFamily, fsStyle.font16]}
          onBackPress={this.goBack}
        />
        <Breadcrumb
          activeStep={this.state.activeStep}
          data={SpecialClaimSelectPassenger.getSteps(this.state.responseJson)}
        />
        <ScrollView showsVerticalScrollIndicator={false} style={styles.pageScroll}>
          <View style={styles.pageScrollTopSpace} />

          <View style={styles.whiteCard}>
            <Text style={[styles.cardTitle, fonts.lightFontFamily, fsStyle.font21]}>
              {this.specialClaimData.explTxt}
            </Text>
            <Text style={[styles.cardSubTitle, fonts.regularFontFamily, fsStyle.font14]}>
              {this.specialClaimData.explSubTxt}
            </Text>
            {this.state.responseJson && this.state.responseJson.flightDetails && this.addSegments()}
          </View>
          <View style={styles.pageScrollBtmSpace} />
        </ScrollView>

        <View style={styles.footerBtnWrapper}>
          <ReviewButton
            buttonText={staticData.reviewDetailsText}
            clickHandler={this.onContinueClicked}
            disabled={this.checkPasSelected()}
            isCorporateBooking={this.props.isCorporateBooking}
          />
        </View>

        {this.state.creditShellPopupData && (
          <CreditShellOverlay
            {...this.state.responseJson.creditShellInfo}
            btnClick={this.showCreditShellPopup}
          />
        )}
        {this.state.helpingHandOverlay === true && (
          <NeedMoreHelpOverlay
            bookingId={this.state.responseJson ? this.state.responseJson.bookingID : ''}
            handleBottomOverlay={this.toggleHelpingHandOverlay}
            handleReload={this.toggleHelpingHandOverlay}
            component={NEED_MORE_HELP.HELPING_HAND}
            pageName={NEED_HELP_PAGE.FLIGHT_SPECIAL_CLAIM_PAX}
            hideLoader={true}
            trackEvent={this.trackHelpingHandEvents}
          />
        )}
      </View>
    );
  }

  getTitle = () =>
    `${SpecialClaimUtility.getScHeaderTitle(this.state.responseJson.messageAttributes)}: ${
      this.specialClaimData.explTxt
    }`;

  addSegments = () =>
    this.state.responseJson.flightDetails.segmentGroupDetailList.map((segmentGroup) => (
      <SelectPassengers
        key={segmentGroup.intentRefID}
        segmentGroup={segmentGroup}
        passengerClickHandler={this.onPaxSelectionChanged}
        showStaticContentInEnglish={false}
      />
    ));

  goBack = () => {
    Actions.pop();
  };

  onPaxSelectionChanged = (segmentGroup, paxSelectionMap) => {
    const slctdPssngr = this.state.selectedPassenger;
    slctdPssngr[segmentGroup.intentRefID] = paxSelectionMap;
    this.setState({
      selectedPassenger: slctdPssngr,
    });
  };

  onContinueClicked = () => {
    paxFareSgmntIdLst = [];
    const unselectedPaxList = [];
    this.state.responseJson.flightDetails.segmentGroupDetailList.forEach((segmentGroup) => {
      const selectedPaxMap = this.state.selectedPassenger[segmentGroup.intentRefID];
      if (selectedPaxMap) {
        segmentGroup.segmentGroupPassengers.forEach((passenger) => {
          if (selectedPaxMap[passenger.paxReferenceNo]) {
            passenger.paxFareSegmentIdList.forEach((paxFareSgmntId) => {
              const obj = {};
              obj.paxFareSegmentId = paxFareSgmntId;
              obj.reason = this.specialClaimData.scCode;
              paxFareSgmntIdLst.push(obj);
            });
          } else {
            unselectedPaxList.push(passenger.paxType);
          }
        });
      }
    });
    if (unselectedPaxList.length !== 0 && !unselectedPaxList.includes(0)) {
      showLongToast("Infant/Child can't travel alone, please select.");
      return;
    }
    if (unselectedPaxList.length === 0) {
      this.allPaxSelected = true;
    } else {
      this.allPaxSelected = false;
    }
    if (this.state.responseJson.creditShellInfo) {
      this.showCreditShellPopup();
    } else {
      this.proceedToReview();
    }
  };

  proceedToReview() {
    Actions.autoRefundRouter({
      responseJson: this.state.responseJson,
      bookingID: this.props.bookingID,
      checkIfRefundPresent: this.checkIfRefundPresent,
      specialClaimData: this.props.specialClaimData,
      selectedPassenger: this.state.selectedPassenger,
      bookingState: this.props.bookingState,
      paxFareSegmentIdList: paxFareSgmntIdLst,
      lobCode: this.props.lobCode,
      title: this.getTitle(),
      mobileNumber: this.props.mobileNumber,
      allPaxSelected: this.allPaxSelected,
      trackingPageName: this.trackingPageName,
      isCorporateBooking: this.props.isCorporateBooking,
      request: SpecialClaimUtility.getCancellationPreviewRequestForAutoRefund(
        this.props.bookingID,
        this.specialClaimData.scCode,
        this.props.lobCode,
        paxFareSgmntIdLst,
        this.props.mobileNumber,
        true,
      ),
    });
  }

  checkIfRefundPresent = async (response) => {
    if (response && response.ok) {
      const body = await response.json();
      if (body.errorPageData) {
        openErrorPage(
          body.errorPageData,
          undefined,
          this.onRetryClicked,
          this.props.bookingID,
          'specialClaim',
          Actions,
          { psLob: LOBNAMES.FLIGHT },
        );
      } else if (isNullOrEmpty(body.businessErrorDescription) && body.tdcwaRefundReceived) {
        Actions.flightBookingRefundBreakup({
          headerTitle: this.getTitle(),
          cancellationPreviewResponseJson: body,
          cancellationRequest: SpecialClaimUtility.getCancellationPreviewRequestForAutoRefund(
            this.props.bookingID,
            this.specialClaimData.scCode,
            this.props.lobCode,
            paxFareSgmntIdLst,
            this.props.mobileNumber,
            true,
          ),
          allPaxSelected: this.allPaxSelected,
          specialClaimReason: this.specialClaimData.scCode,
        });
      } else if (
        body.specialClaimValidationData &&
        body.specialClaimValidationData.rejectedStatusInfo
      ) {
        Actions.falseClaim({
          ...body.specialClaimValidationData.rejectedStatusInfo,
          bookingId: this.props.bookingID,
          extraProps: {
            responseJson: body,
            specialClaimData: this.props.specialClaimData,
            selectedPassenger: this.state.selectedPassenger,
            bookingState: this.props.bookingState,
            paxFareSegmentIdList: paxFareSgmntIdLst,
            lobCode: this.props.lobCode,
            trackingPageName: this.trackingPageName,
            bookingID: this.props.bookingID,
          },
          type: ActionConst.REPLACE,
        });
        this.trackSpecialClaimFullBlockPageLoad(
          body,
          this.specialClaimData,
          this.props.bookingState,
        );
      } else {
        this.openClaimReviewPage(body);
      }
    } else {
      this.openClaimReviewPage(this.state.responseJson);
    }
  };

  openClaimReviewPage(body) {
    Actions.specialClaimReviewDetails({
      responseJson: body,
      specialClaimData: this.props.specialClaimData,
      selectedPassenger: this.state.selectedPassenger,
      bookingState: this.props.bookingState,
      paxFareSegmentIdList: paxFareSgmntIdLst,
      bookingID: this.props.bookingID,
      lobCode: this.props.lobCode,
      isCorporateBooking: this.props.isCorporateBooking,
    });
  }

  getFlightCancellationPreviewRequest() {
    const flightCancellationPreviewRequest = {};
    flightCancellationPreviewRequest.bookingId = this.props.bookingID;
    flightCancellationPreviewRequest.validationError = !!this.props.validationError;
    flightCancellationPreviewRequest.fullCancellationReason = this.specialClaimData.scCode;
    if (this.partialPax) {
      flightCancellationPreviewRequest.isFullCancellation = false;
    } else {
      flightCancellationPreviewRequest.isFullCancellation = true;
    }
    flightCancellationPreviewRequest.refundOption = 'RefundToCustomer';
    flightCancellationPreviewRequest.lobCode = this.props.lobCode;
    if (this.partialPax) {
      flightCancellationPreviewRequest.paxFareSegmentIdList = this.partialPax;
      flightCancellationPreviewRequest.paxIdList = [];
      flightCancellationPreviewRequest.segmentGroupIdList = [];
      flightCancellationPreviewRequest.phoneNo = this.props.mobileNumber;
    }
    flightCancellationPreviewRequest.validateClaim = false;
    return flightCancellationPreviewRequest;
  }

  checkPasSelected = () => {
    const keys = Object.keys(this.state.selectedPassenger);
    if (keys.length === 0) {
      return true;
    }
    const res = true;
    for (let i = 0; i < keys.length; i++) {
      const seg = this.state.selectedPassenger[keys[i]];
      const segKeys = Object.keys(seg);
      for (let j = 0; j < segKeys.length; j++) {
        if (seg[segKeys[j]] === true) {
          return false;
        }
      }
    }
    return res;
  };
}

SpecialClaimSelectPassenger.propTypes = {
  specialClaimData: PropTypes.string.isRequired,
  bookingID: PropTypes.string.isRequired,
  lobCode: PropTypes.string.isRequired,
  mobileNumber: PropTypes.string.isRequired,
  partialPax: PropTypes.string.isRequired,
  validationError: PropTypes.bool,
};

SpecialClaimSelectPassenger.defaultProps = {
  validationError: false,
};
