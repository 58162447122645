import React, { useCallback, useState } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import getStyles from './SelectReasonCardCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { THEME } from '../../../../PostSalesConstant';
import {getFont} from 'apps/post-sales/src/PostSalesStyles';
import RadioWpr from 'apps/post-sales/src/Common/RadioButton/RadioWpr';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
const SelectReasonCard = ({cancellationReasonData, setReason, theme = '' }) => {
    const { fsStyle, ...fonts } = getFont(true);
    const styles = getStyles(fonts);
    const { header, cancellationReasonList } = cancellationReasonData;
    const [radioBtn, setRadioBtn] = useState('');
    const {psTheme: styleTheme} = useTheme();

	const handleRadio = useCallback((index) => {
		setRadioBtn(index);
        const cancellationReason = cancellationReasonList[index];
        if(cancellationReason && cancellationReason.reasonText) {
            setReason(cancellationReason);
        }
	}, [cancellationReasonList]);

    const RadioList = ({item, index, activeState}) => {
        const activeBorder = index === activeState ? theme === THEME.MYBIZ ? styleTheme.color.orange3 :  styleTheme.color.azure : '';
        return (
        <>  
            <View style={styles.radioWrapper}>
                <RadioWpr
                    isSelected={index === activeState}
                    radioSize={18}
                    activeColorStyle={activeBorder}
                    customStyle={{marginRight:10, marginTop:1}}
                    onPress={() => handleRadio(index)} activeOpacity={.8}
                    children={
                        <Text style={styles.radioTxt}>{item.reason}</Text>
                    }
                />
            </View>
            </>
        );
        
    }
    return (
        <View style={[styles.cardWrapper]}>
            <Text style={[fsStyle.font20, fonts.blackFontFamily, AtomicCss.blackText, AtomicCss.marginBottom26, AtomicCss.lineHeight24, AtomicCss.flex1]}>{header}</Text>
            <View>
                {cancellationReasonList && cancellationReasonList.map((item, index) => {
                    if (item && item.reason) {
                        return (
                            <RadioList
                                item={item}
                                index={index}
                                activeState={radioBtn}
                            />
                        )
                    } else {
                        return null;
                    }
                })}
            </View>
        </View>
    );
}

export default React.memo(SelectReasonCard);