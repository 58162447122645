import { StyleSheet } from 'react-native';

export default () => {
  const styles = StyleSheet.create({
    covidIcon: { width: 35, height: 40, marginRight: 10 },
    detailsInfo: { paddingHorizontal: 16, paddingVertical: 12, marginBottom: 8 },
  });

  return styles;
};
