import { StyleSheet, Platform } from 'react-native';
import { colors, fonts } from '@mmt/legacy-commons/Styles/globalStyles';

const styles = StyleSheet.create({
    cashbackBanner :{
        borderWidth: 2,
        borderColor: colors.white,
        borderRadius: 5,
        margin: 10
    },
    cashbackIcon:{
        paddingLeft: 35,
        ...Platform.select({
            web: {
                paddingLeft: 0,
            }
        })
    },
    
    cashbackText :{
        fontFamily: fonts.regular,
        fontSize: 12,
        lineHeight: 14,
        margin: 12,
        paddingRight: 20,
        ...Platform.select({
            web: {
                flex: 1,
            }
        })
    },
    iconStyle: {
        width: 30, 
        height: 30
    }
})



export default styles;