import React from "react";
import { View, StyleSheet } from 'react-native';
import Shimmer from '@mmt/legacy-commons/Common/Components/Shimmer';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { SummaryContainer } from "../cards/cardElements";
import createStyles from './style';
import { useTheme } from "apps/post-sales/src/theme/theme.context";

interface ListingCardShimmerProps {
  count: number;
}

const ListingCardShimmer: React.FC<ListingCardShimmerProps> = ({ count }) => {
  const linkItem: undefined[] = new Array(count);

  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme);

  return (
    <SummaryContainer>
      <View style={styles.innerDetails}>
        <View style={[styles.rowSection, AtomicCss.marginBottom10]}>
          <View style={[styles.shimmerOne, styles.animatedOuter]}>
            <Shimmer />
          </View>
          <View style={[styles.shimmerOne, styles.animatedOuter]}>
            <Shimmer />
          </View>
        </View>
        {/* Logo Section */}
        <View style={[AtomicCss.flexRow, AtomicCss.marginBottom8]}>
          <View style={[styles.imageWrapper, styles.animatedOuter]}>
            <Shimmer />
          </View>
          <View style={[styles.shimmerFour, styles.animatedOuter]}>
            <Shimmer />
          </View>
        </View>
        {/* Trip Info Section */}
        <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.spaceBetween, AtomicCss.marginBottom12]}>
          <View style={styles.leftInfo}>
            <View style={[styles.shimmerTwo, styles.animatedOuter, AtomicCss.marginBottom5]}>
              <Shimmer />
            </View>
            <View style={[styles.shimmerThree, styles.animatedOuter, AtomicCss.marginBottom5]}>
              <Shimmer />
            </View>
            <View style={[styles.shimmerOne, styles.animatedOuter]}>
              <Shimmer />
            </View>
          </View>
          <View>
            <View style={[styles.shimmerTwo, styles.animatedOuter, AtomicCss.marginBottom5, styles.rightInfo]}>
              <Shimmer />
            </View>
            <View style={[styles.shimmerThree, styles.animatedOuter, AtomicCss.marginBottom5, styles.rightInfo]}>
              <Shimmer />
            </View>
            <View style={[styles.shimmerOne, styles.animatedOuter, styles.rightInfo]}>
              <Shimmer />
            </View>
          </View>
        </View>
        {/* Redirect Link Wrapper */}
        <View style={styles.linkWrapper}>
          {linkItem.map((_, index) => (
            <View key={index} style={[styles.shimmerLink, styles.animatedOuter]}>
              <Shimmer />
            </View>
          ))}
        </View>
        {/* Redirect Link Wrapper end */}
      </View>
    </SummaryContainer>
  );
};



export default ListingCardShimmer;