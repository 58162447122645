import React, { useRef } from 'react';
import {Text, View, FlatList, Platform} from 'react-native';
import ErrorCard from './ErrorCard';
import HelpCard from './HelpCard';
import NavHeader from './NavHeader';
import styles from '../indexCss';
import RefundCardHeader from './RefundCardHeader';
import CardLayoutSeperator from '../../CardLayoutSeperator';
import RefundDetails from './RefundDetails';
import PaymentCard from './PaymentCard';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {
  appendPlatformInfo,
  openWebView,
  setWebViewAuth,
} from '../../../utils/PostSaleUtil';
import FlightBookingModule from '@mmt/legacy-commons/Native/FlightBookingModule';
import { BACK_WHITE_ICON } from '../../../flights/FlightImageConstants';
import FlightBookingTrackingHelper from '../../../flights/modules/details/helper/FlightBookingTrackingHelper';

import { getFont } from '../../../PostSalesStyles';

const RefundDetailCard = ({ refundDetails, fetchData }) => {
  const fonts = getFont(true);
  const flatListRef = useRef();
  const onNavPress = (index) => {
    if (flatListRef) {
      flatListRef.current.scrollToIndex({ animated: true, index, viewOffset: 10 });
    }
    FlightBookingTrackingHelper.trackClickEvent(
      'refundBreakupV2',
      'MI_RefundBreakup_Tab_Click',
      refundDetails.refundBreakup[index],
    );
  };

  const onNeedHelpPress = async (url) => {
    const { bookingId } = refundDetails;
    const userInfo = await FlightBookingModule.getRequestHeader(
      bookingId,
      'NEED_HELP_REFUND_BREAKUP',
    );
    const uri = appendPlatformInfo(url, userInfo);
    if (Platform.OS == "web") {
      window.open(uri, '_blank').focus();
    } else {
      openWebView(
        'Need Help',
        BACK_WHITE_ICON,
        uri,
        null,
        bookingId,
        'NEED_HELP_REFUND_BREAKUP',
        setWebViewAuth(userInfo.mmtAuth),
        true,
      );
    }    
    FlightBookingTrackingHelper.trackClickEvent(
      'refundBreakupV2',
      'MI_RefundBreakup_MSR_Submit',
      refundDetails.refundBreakup,
    );
  };

  const renderRefundBreakUp = ({ item, index }) => {
    return (
      <React.Fragment>
        <View style={styles.refundCard}>
          <RefundCardHeader data={item.refundAmount} status={item.refundStatus} />
          {item.segmentBreakup &&
            item.segmentBreakup.map((segmentBreakup, index) => (
              <RefundDetails
                key={index}
                data={segmentBreakup}
                collapsable={item.segmentBreakup.length > 1}
              />
            ))}
          {item.refundModes && item.refundModes.length >= 1 && (
            <React.Fragment>
              <CardLayoutSeperator />
              <View style={styles.paymentCard}>
                {item.refundModeTitle && (
                  <Text
                    style={[
                      AtomicCss.font14,
                      fonts.boldFontFamily,
                      AtomicCss.blackText,
                      AtomicCss.flex1,
                    ]}
                  >
                    {item.refundModeTitle}
                  </Text>
                )}
                {item.refundModes.map((item) => (
                  <PaymentCard data={item} />
                ))}
              </View>
            </React.Fragment>
          )}
        </View>
      </React.Fragment>
    );
  };

  if (!refundDetails) {
    return <ErrorCard fetchData={fetchData} />;
  }
  return (
    <View style={AtomicCss.flex1}>
      <NavHeader onPressHandler={onNavPress} data={refundDetails} />
      <FlatList
        data={refundDetails.refundBreakup}
        ref={flatListRef}
        keyExtractor={(item) => item.requestNo}
        renderItem={renderRefundBreakUp}
        contentContainerStyle={styles.body}
        maxToRenderPerBatch={2}
        windowSize={2}
        onScrollToIndexFailed={() => {}}
        initialNumToRender={refundDetails.refundBreakup?.length}
      />
      {refundDetails.needHelp && (
        <HelpCard data={refundDetails.needHelp} onNeedHelpPress={onNeedHelpPress} />
      )}
    </View>
  );
};

export default RefundDetailCard;