import { NativeModules } from 'react-native';
import { DOWNLOAD_CAB_INVOICE_PDF } from "../const";
import ToastWeb from "../components/ToastWeb";
import {getRequestHeader} from "./NetworkModule";
import { getCommonHeaders } from "@rn/apps/post-sales/src/utils/NetworkUtils";
import { convertStringToBlob } from '../webUtils'


const downloadTicket = async (triggerName, bookingId) => {
    try {
      const cabVoucherBody = {
        userId: "PWA",
        triggerName: triggerName,
        bookingId: bookingId
      };
  
      const userInfo = await getRequestHeader(bookingId, triggerName);
      const headers = await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo);
      const voucherResponse = await fetch(DOWNLOAD_CAB_INVOICE_PDF, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(cabVoucherBody)
      });
      if (voucherResponse.ok) {
        const responseBody = await voucherResponse.json();
        if (responseBody && responseBody.success && responseBody.pdfResponse) {
          let blob = convertStringToBlob(
              responseBody.pdfResponse.body,
            "application/pdf"
          );
          const download = require("downloadjs");
          download(blob, "Trips_Cab_DownloadETicket.pdf", "application/pdf");
          ToastWeb.show("Downloaded successfully");
        }
        else if(responseBody && responseBody.pdfResponse==null)
        {
          ToastWeb.show("Your invoice will be available after the trip completion");
        }
      } else {
        ToastWeb.show("Your invoice will be available after the trip completion");
        console.log("FAILURE");
      }
    } catch (error) {
      ToastWeb.show("Your invoice will be available after the trip completion");
      console.log("FAILURE with error code as" + error);
    }
  }

  NativeModules.CabBookingModule = {
    downloadTicket,
};