import { StyleSheet, Dimensions } from 'react-native';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';

const windowWidth = Dimensions.get('window').width;

const getStyle = (theme, fonts) => {
  const {color} = theme;
  return StyleSheet.create({
   cardWrapper: {
     backgroundColor: color.white,
     elevation: 3,
     ...getPlatformElevation(2),
     marginBottom: 16,
     borderRadius: 8,
     borderWidth: 1,
     borderColor: color.greyBookedSeat,
   },
   cardWrapperLastItem: {
     marginBottom: 0,
   },
   destRow: {
     flexDirection: 'row',
     alignItems: 'center',
     flexWrap: 'wrap',
     width: windowWidth - 170,
   },
   cityText: {
     color: color.black28,
     lineHeight: 24,
   },
   arrowIcon: {
     width: 10,
     height: 7,
     marginHorizontal: 5,
   },
   smallText: {
     color: color.defaultTextColor,
     marginTop: 5,
     marginBottom: 0,
     lineHeight: 16,
   },
   passengerName: {
     fontSize: fonts.fontSize.font14,
     color: color.black,
   },
   passengersList: {
     paddingHorizontal: 12,
   },
   redInfoIcon: {
     width: 16,
     height: 16,
     marginRight: 10,
   },
   redItalicText: {
     color: color.red,
     fontSize: fonts.fontSize.font12,
     fontStyle: 'italic',
   },
   redInfoWrappe: {
     padding: 16,
     paddingTop: 6,
     flexDirection: 'row',
   },
   checkAllWrapperInner: {
     flexDirection: 'row',
     justifyContent: 'space-between',
     paddingVertical: 12,
     paddingHorizontal: 16,
   },
   ViewPolicyTxt: {
     fontSize: fonts.fontSize.font14,
     color: color.azure,
     paddingHorizontal: 20,
     marginTop: 30,
   },
   SelectNameRow: {
     flexDirection: 'row',
     justifyContent: 'space-between',
     flex: 1,
   },
   paxListWrapper: { backgroundColor: color.lightGrey6, borderRadius: 8, marginBottom: 12 },
   sectorPaxList: {
     paddingVertical: 19,
     paddingHorizontal: 35,
   },
   isMoreInfoWrapper: {
     borderBottomColor: color.transparent,
     borderBottomWidth: 0,
   },
   passengerRfundTxt: {
     fontSize: fonts.fontSize.font12,
     color: color.greyLight,
     marginTop: 5,
   },
   PaxslctionHeader: {
     flex: 1,
     justifyContent: 'space-between',
   },
   sectorHeader: {
     flexDirection: 'row',
     flex: 1,
     justifyContent: 'space-between',
   },
   airlinesIconWrapper: {
     width: 33,
     height: 33,
     alignSelf: 'center',
   },
   airlineIcon: {
     width: 32,
     height: 32,
   },
   paxDisabled: {
     backgroundColor: color.grey7,
     opacity: 0.7,
   },
   margin_bottom10: {
     marginBottom: 10,
   },
   passengerCancelled: {
     fontSize: fonts.fontSize.font14,
     color: color.black,
     textDecorationLine: 'line-through',
   },
   boxDisabled: {
     backgroundColor: color.grey7,
     opacity: 0.7,
   },
   cancelledTxt: {
     color: color.red,
     fontSize: fonts.fontSize.font12,
   },
   disableChkBox: {
     backgroundColor: color.greyBookedSeat,
     borderColor: color.gray3,
   },
   disableTxt: {
     color: color.greyLight,
     fontSize: fonts.fontSize.font14,
   },
   errorInfoTxt: {
     fontSize: fonts.fontSize.font14,
     color: color.red6,
   },
   infoTxtColor: {
     color: color.lightYello,
     fontSize: fonts.fontSize.font12,
   },
   moreDtlsSection: {
     padding: 10,
     flexDirection: 'row',
     flex: 1,
     backgroundColor: color.creamWhite,
     borderRadius: 4,
     marginBottom: 12,
   },
   textBlue: {
     color: color.azure,
   },
   txtInfo: {
     color: color.defaultTextColor,
     fontSize: fonts.fontSize.font12,
   },
   moreInfoWrapper: {
     backgroundColor: color.grayBg,
     borderRadius: 4,
     padding: 16,
     margin: 10,
   },
   infoIcon: {
     width: 16,
     height: 16,
     marginRight: 10,
     tintColor: color.lightYello,
     marginTop: 5,
   },
   seperatorWrapper: {
     paddingHorizontal: 20,
     overflow: 'hidden',
     marginBottom: 6,
   },
   uploadWrapper: {
     paddingHorizontal: 8,
   },
   proofWrapper: {
     borderTopWidth: 1,
     borderTopColor: color.greyBookedSeat,
     flexDirection: 'row',
     padding: 16,
     alignItems: 'center',
     lineHeight: 20,
   },
   greenTick: {
     width: 20,
     height: 20,
     marginRight: 4,
   },
   proofText: {
    lineHeight: 16,
    color: color.green,
   },
   blueInfoIcon: {
     width: 20,
     height: 20,
     tintColor: color.azure,
     marginLeft: 4,
   },
   infoTag: {
     flex: 1,
     flexDirection: 'row',
     paddingHorizontal: 16,
     marginBottom: 12,
   },
   infoWrapper: {
     backgroundColor: color.creamWhite,
     paddingHorizontal: 8,
     paddingVertical: 4,
     borderRadius: 4,
   },
   infoText: {
     color: color.lightYello,
     lineHeight: 12,
   },
 });
} 

export default getStyle;
