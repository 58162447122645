import {RESPONSE_TYPE, UPLOAD_STATES} from "./constant";

export const getResponseType = (
  detailsResponse,
  previewResponse,
  ancDetailsResponse,
  ancPreviewResponse,
) => {
  if (
    ((!!detailsResponse && detailsResponse.error) ||
    (!!previewResponse && previewResponse.error) ||
    (!!ancDetailsResponse && ancDetailsResponse.error) ||
    (!!ancPreviewResponse && ancPreviewResponse.error)) || 
    (!!detailsResponse &&
      !!detailsResponse.newSpecialClaimDataCard &&
      (!detailsResponse.newSpecialClaimDataCard.cardsList ||
        !detailsResponse.newSpecialClaimDataCard.actionLists))
  ) {
    return RESPONSE_TYPE.ERROR;
  }
  if (
    (!!detailsResponse && !detailsResponse.error) ||
    (!!ancDetailsResponse && !ancDetailsResponse.error)
  ) {
    return RESPONSE_TYPE.DETAIL_RESPONSE;
  }
  if (
    (!!previewResponse && !previewResponse.error) ||
    (!!ancPreviewResponse && !ancPreviewResponse.error)
  ) {
    return RESPONSE_TYPE.PREVIEW_RESPONSE;
  }
};

export const isProofOfAllSegmentsNotUploaded = (cancellationState) => {
  const doclist = cancellationState && cancellationState.documentList;
  const proofSegments = cancellationState && cancellationState.proofSegments;

  if (proofSegments && Array.isArray(proofSegments)) {
    for (const segment of proofSegments) {

      if (!doclist[segment] || !(doclist[segment] && doclist[segment].some((doc) => doc.status === UPLOAD_STATES.SUCCESS))) {
        return true
      }
    }
  }

    return false;
}
