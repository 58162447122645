import React from 'react';
import Actions from '../../../../../navigation/postSalesNavigation';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import {
  View,
  Text,
  ScrollView,
  Image,
  DeviceEventEmitter,
  Platform,
} from 'react-native';
import FareChangeOverlay from '@mmt/legacy-commons/Common/Components/MIMABottomOverlay/FareChangeOverlay';
import OverlayMessage from '@mmt/legacy-commons/Common/Components/MIMABottomOverlay/OverlayMessage';
import DateChangeBaseClass from '../../components/DateChangeBaseClass';
import {
  FLIGHT_DATE_CHANGE_REVIEW,
  FLIGHT_DATE_CHANGE_REVIEWV2,
} from '../../../../../utils/NetworkUtils';
import NoInternetView from '@mmt/legacy-commons/Common/Components/Error/NoInternetView';
import InfoListCard from '../RescheduleCharges/components/InfoListCard';
import FlightInfoDtls from '../../components/FlightInfoDtls';

import FareBreakUp from '../../components/FareBreakUp';
import DarkFooter from '../../../../../Common/DarkFooter';
import FlightBookingModule from '@mmt/legacy-commons/Native/FlightBookingModule';
import { DcViewState } from '../../constants';
import DateChangeTrackingHelper from '../../analytics/DateChangeTrackingHelper';
import ConfirmationOverlay from '../../../../../Common/ConfirmationOverlay';
import withRouterWrapper from '@mmt/acme/src/Web/withRouterWrapper';
import { refreshListing } from '../../Redux/Listing/FlightListingActions';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import InformationCard from '../../../../../Common/InformationCard';
import CommonOverlayMessage from '../../../../../Common/CommonOverlayMessage';
import CommonBottomOverlay from '../../../../../Common/CommonBottomOverlay';
import { isCorporateBookingId, openErrorPage } from '../../../../../utils/PostSaleUtil';
import FlightEventsLogger, {FLIGHT_LOG_ACTIONS} from '../../../details/helper/FlightEventsLogger';
import { INFO_RED_ICON, CALENDAR_GREEN_TICK_ICON2 } from '../../../../FlightImageConstants';
import { LOBNAMES } from '../../../../../PostSalesConstant';
import ChangeDateInfo from '../../components/UndoWebCheckIn/ChangeDateInfo';
import UndoWebCheckInComp from '../../components/UndoWebCheckIn';
import { getStaticData } from '../../../../../staticData/staticData';
import { makeHoldAPI, makeReviewAPI } from './data/api.js';
import { getOverlayData, getSubmitPageData } from './data/dataAdapter';
import { ThemeContext } from '../../../../../theme/theme.context';
import { getFont } from '../../../../../PostSalesStyles';
import createStyles, {createHtmlStyle}  from "./styles";
import { getReviewPageData } from './data/dataAdapter';
import Header from '../../components/Header';
class DateChangeReviewPage extends DateChangeBaseClass {
  static contextType = ThemeContext;
  constructor(props) {

    super(props, 'flightRescheduleReviewPage');
    this.pageName = 'datechangereview';
    this.state = {
      responseJson: null,
      holdResponse: null,
      viewState: DcViewState.LOADING,
      fareChangeOverlay: false,
      confirmationOverlay: false,
      specialDateChangePaymentOverlay: false,
      errorMsg: undefined,
      errorNextStep: undefined,
      undoWebcheckinOverlay: false,
    };
    this.reviewResponse = {};
    this.holdAPIErrorNextStep = undefined;
    this.reviewApiUri = isCorporateBookingId(this.props.bookingId)
      ? FLIGHT_DATE_CHANGE_REVIEW
      : FLIGHT_DATE_CHANGE_REVIEWV2;
    this.dateChangeThankyouPageListener = null;
  }

  componentWillMount() {

    if (DeviceEventEmitter) {
      this.dateChangeThankyouPageListener = DeviceEventEmitter.addListener(
        'submit_date_change_request_thankyou_page',
        this.openModificationThankYouPage,
      );
    }
  }

  componentWillUnmount() {
    if (this.dateChangeThankyouPageListener) {
      this.dateChangeThankyouPageListener.remove();
    }
  }

  openModificationThankYouPage = (data) => {
    FlightEventsLogger.logAction(FLIGHT_LOG_ACTIONS.ODC_THANKYOU_PAGE, data);
    Actions.dateChangeSubmitRequest({
      viewState: DcViewState.AFTER_PAYMENT,
      paymentResponse: data,
      pageType: 'AFTER_PAYMENT',
    });
  };

  render() {
    const { loadingReviewText, loadingText } = getStaticData();
    return (
      <View style={{ flex: 1 }}>
        {this.state.viewState === DcViewState.LOADING && this.renderProgressView(loadingReviewText)}
        {this.state.viewState === DcViewState.NO_INTERNET && this.renderNoNetworkView()}
        {this.state.viewState === DcViewState.ERROR && this.renderErrorView()}
        {this.state.viewState === DcViewState.SHOW_DETAIL && this.renderPage()}
        {this.state.viewState === DcViewState.HOLD_SUCCESS && this.renderPaymentPage()}
        {this.state.viewState === DcViewState.HOLD_LOADING && this.renderProgressView(loadingText)}
        {this.state.viewState === DcViewState.HOLD_API_FAILURE_RESPONSE &&
          this.renderHoldAPIFailureView()}
      </View>
    );
  }

  renderNoNetworkView = () => (
    <View style={{ flex: 1 }}>
      <NoInternetView
        onRetry={() => {
          this.setState({ viewState: DcViewState.LOADING });
          this.fetchJSONAsync(this.reviewApiUri);
        }}
      />
    </View>
  );

  fetchData = () => {
    this.setState({ viewState: DcViewState.LOADING });
    this.fetchJSONAsync(this.reviewApiUri);
  };

  undoWebcheckinHandler = () => {
    DateChangeTrackingHelper.trackUndoWCClickEvent(this.pageName, 'openUndoWCOverlay');
    this.setState({
      undoWebcheckinOverlay: true,
    });
  };

  closeUndoWebcheckinOverlay = () => {
    DateChangeTrackingHelper.trackUndoWCClickEvent(this.pageName, 'closeUndoWCOverlay');
    this.setState({
      undoWebcheckinOverlay: false,
    });
  };

  renderPage() {
    const fonts = getFont(true);
    const styles = createStyles(this.context.psTheme, fonts);
    const {
      reviewYourFlightText,
      reserveReviewText,
      makePaymentText,
      nowText,
      bookingIdSameText,
      confirmDateChangeText,
      backText,
      confirmText,
      confirmDescriptionText,
    } = getStaticData();
    const { flightInfoData,
      extraInfo,
      fareBreakupData,
      importantInformationData,
      infoListData,
      changeDateInfoData,
      footerInfo,
      undoWebCheckinInfo,
      refundMsg } = getReviewPageData(this.state.responseJson);
    const overlayData = getOverlayData(
      this.state.specialDateChangePaymentOverlay,
      this.state.responseJson,
    );
    const {fareBreakup,bnpl} = this.state.responseJson || {};

    return (
      <View style={styles.pageWrapper}>
        <Header
          title={reviewYourFlightText}
          onBackPress={() => Actions.pop()}
        />
        <ScrollView ref="pageScroll" showsVerticalScrollIndicator={false} style={styles.pageScroll}>
          <View style={styles.pageScrollTopSpace} />

          <View style={bnpl ? styles.showView : styles.hideView}>
            <View style={[styles.whiteCard, styles.ReviewCard]}>
             {fareBreakup && !!fareBreakup.amtpayable && <Text style={[styles.heading2Text, styles.marBt5]}>
                {makePaymentText} {fareBreakup.amtpayable} {nowText}
              </Text>}
              <Text style={styles.font12}>{reserveReviewText}</Text>
            </View>
          </View>

          <View style={[styles.flexRow, styles.padding10]}>
            <Image style={styles.infoIcon} source={INFO_RED_ICON} />
            <Text style={[styles.info_redTxt]}>{bookingIdSameText}</Text>
          </View>

          {!!extraInfo &&
            extraInfo.map((info) => (
              <View style={[styles.flexRow, styles.padding10, styles.padding20]}>
                <Image style={styles.infoIcon} source={INFO_RED_ICON} />
                <Text style={[styles.info_redTxt]}>{info}</Text>
              </View>
            ))}

        {flightInfoData &&  <FlightInfoDtls flightInfoData ={flightInfoData} />}

          {!isEmpty(importantInformationData.message) && (
            <InformationCard
              description={importantInformationData.message}
              cardBackgroundColor={this.context.psTheme.creamWhite9}
              textColor={this.context.psTheme.color.lightYello}
            />
          )}
          {!isEmpty(fareBreakupData.fareDetail) && <FareBreakUp fareBreakupData ={fareBreakupData}/>}

          {refundMsg && (
            <View style={[styles.refundInfoWrapper]}>
              <Text style={[styles.refundInfoTxt]}>{refundMsg}</Text>
            </View>
          )}
          {infoListData.impInfo && (
            <InfoListCard
              header={infoListData.impInfoHeader}
              data={infoListData.impInfo}
            />
          )}
        </ScrollView>
        {!isEmpty(changeDateInfoData.bannerInfo) && <ChangeDateInfo info={changeDateInfoData.bannerInfo} />}
        {!isEmpty(footerInfo.btnText) && !isEmpty(footerInfo.totalFare) && (
          <DarkFooter
            price={footerInfo.totalFare.value}
            footerInfoIcon={false}
            priceDtls={footerInfo.priceDtlsLabel}
            buttonLabel={footerInfo.btnText}
            clickHandler={isEmpty(footerInfo.bannerInfo) ? this.makePayment : this.undoWebcheckinHandler}
            textStyle = {styles.footerBtnTextStyle}
            btnStyle = {styles.footerBtnStyle}
            marginLeft={0}
            numberOfLines ={1}
          />
        )}

        {this.state.fareChangeOverlay && (
          <OverlayMessage
            CloseOverlay={this.closeFareChangeOverlay}
            content={
              <FareChangeOverlay
                CloseOverlay={this.closeFareChangeOverlay}
                ContinuePress={this.openPaymentPage}
                content={this.getContentTitle(
                  this.state.holdResponse.fareChangeHeader,
                  this.state.holdResponse.fareChangeText,
                )}
                holdResponse={this.state.holdResponse}
                reviewResponse={this.state.responseJson}
              />
            }
          />
        )}
        {this.state.confirmationOverlay && (
          <ConfirmationOverlay
            title={confirmDateChangeText}
            leftButtonText={backText}
            rightButtonText={confirmText}
            content={confirmDescriptionText}
            leftBtnClick={this.closeConfirmOverlay}
            rightBtnClick={this.makeHoldCall}
            outsideClick={this.closeConfirmOverlay}
            imgIcon={CALENDAR_GREEN_TICK_ICON2}
            imgIconStyle={styles.confirmOverlayIcon}
          />
        )}
        {this.state.specialDateChangePaymentOverlay && (
          <CommonOverlayMessage
            handleBottomOverlay={this.closeSubmitDateCHangeOverlay}
            content={
              <CommonBottomOverlay {...overlayData} isHtmlDescription htmlStyleSheet={createHtmlStyle(this.context.psTheme, fonts)} />
            }
          />
        )}
        {this.state.undoWebcheckinOverlay && (
          <UndoWebCheckInComp
            undoWebcheckinInfo={undoWebCheckinInfo}
            handleBottomOverlay={this.closeUndoWebcheckinOverlay}
            reviewInfo={this.state.responseJson}
            makePayment={this.makePayment}
            odcPreviewResponse={this.props.odcPreviewResponse}
            odcSegmentGroup={this.props.odcSegmentGroup}
          />
        )}
      </View>
    );
  }

  closeSubmitDateCHangeOverlay = () => {
    this.setState({
      specialDateChangePaymentOverlay: false,
    });
  };

  getContentTitle = (header, description) => {
    const content = {};
    content.title = header;
    content.desc = description;
    return content;
  };

  closeFareChangeOverlay = () => {
    this.setState({
      fareChangeOverlay: !this.state.fareChangeOverlay,
    });
    DateChangeTrackingHelper.trackClickableEvent('datechangereviewfareupdate', 'close', this.props.odcPreviewResponse, undefined);
  };

  closeConfirmOverlay = () => {
    this.setState({
      confirmationOverlay: !this.state.confirmationOverlay,
    });
    DateChangeTrackingHelper.trackClickableEvent('datechangereviewconfirmoverlay', 'close', this.props.odcPreviewResponse, undefined);
  };

  componentDidMount() {
    this.fetchJSONAsync(this.reviewApiUri);
  }

  async fetchJSONAsync(url) {
    const result = await makeReviewAPI(url, this.props);
    if (result.openErrorPage || result.err) {
      openErrorPage(
        result.errorPageData,
        this.onBackPressFromErrorPage,
        this.fetchData,
        result.bookingId,
        'datechange',
        Actions,
        { psLob: LOBNAMES.FLIGHT },
      );
    } else if (!result.error) {
      this.reviewResponse = result.responseJson;
    }
    this.setState({ ...result });
  }

  onBackPressFromErrorPage = () => {
    setTimeout(() => Actions.pop());
  };

  makeHoldCall = async () => {
    this.setState({
      viewState: DcViewState.HOLD_LOADING,
      confirmationOverlay: false,
    });
    const result = await makeHoldAPI(this.props);
    const { holdResponse } = result;
    if (holdResponse && holdResponse.errorPageData) {
      this.holdAPIErrorNextStep = holdResponse.failureNextStep;
      openErrorPage(
        holdResponse.errorPageData,
        this.onBackPressFromErrorPage,
        this.onHoldFailureRetryClicked,
        holdResponse.bookingId,
        'datechange',
        Actions,
        { psLob: LOBNAMES.FLIGHT },
      );
    } else if ((holdResponse && holdResponse.success) || result.viewState !== DcViewState.ERROR) {
      this.holdAPIErrorNextStep = undefined;
      this.setState(result);
    } else {
      this.setState(result);
    }
  };

  makePayment = () => {
    const { dateChangeType, buttonData,fareBreakup } = this.state.responseJson;
    if (dateChangeType === 'SPECIAL_DATE_CHANGE') {
      this.handleSpecialDateChangeBtnCLick(buttonData.actionType);
    } else {
      const { amtpayable } = fareBreakup || {};
      if(amtpayable  != undefined){
      if (amtpayable <= 0) {
        this.setState({ confirmationOverlay: !this.state.confirmationOverlay });
      } else {
        DateChangeTrackingHelper.trackClickableEvent('datechangereview', 'makePayment', '');
        this.makeHoldCall(); // todo refactoring
      }
    }
    }
  };

  handleSpecialDateChangeBtnCLick = (actionType) => {
    switch (actionType) {
      case 'DATE_CHANGE_PAYMENT': {
        DateChangeTrackingHelper.trackLoadEvent(
          'datechange _paymentPopup',
          this.state.responseJson,
        );
        this.setState({
          specialDateChangePaymentOverlay: true,
        });
        break;
      }
      case 'DATE_CHANGE_WARNING': {
        DateChangeTrackingHelper.trackLoadEvent('datechange_neartodept', this.state.responseJson);
        Actions.dateChangeSubmitRequest(
          getSubmitPageData('DATE_CHANGE_WARNING', DcViewState.DEFAULT, this.state.responseJson),
        );
        break;
      }
      case 'DATE_CHANGE_REQUEST': {
        DateChangeTrackingHelper.trackLoadEvent(
          'datechange _requestPopup',
          this.state.responseJson,
        );
        Actions.dateChangeSubmitRequest(
          getSubmitPageData('DATE_CHANGE_REQUEST', DcViewState.DEFAULT, this.state.responseJson),
        );
        break;
      }
      case 'PAYMENT_SUBMIT_REQUEST':
        this.closeSubmitDateCHangeOverlay();
        Actions.dateChangeSubmitRequest(
          getSubmitPageData('PAYMENT_SUBMIT_REQUEST', DcViewState.LOADING, this.state.responseJson),
        );
        break;
      case 'REVIEW_PAGE':
        this.closeSubmitDateCHangeOverlay();
        break;
      default:
        break;
    }
  };

  renderPaymentPage() {
    const {
      bookingId,
      nextstep,
      fareChanged,
      commitMessage,
      commitTitle,
    } = this.state.holdResponse;
    switch (nextstep.toUpperCase()) {
      case 'PAYMENT':
        if (fareChanged) {
          this.setState({
            fareChangeOverlay: !this.state.fareChangeOverlay,
            viewState: DcViewState.SHOW_DETAIL,
          });
          DateChangeTrackingHelper.trackErrorEvent('datechangereview', 'fareupdate', undefined);
        } else {
          if (Platform.OS === 'web') {
            if (this.state.holdResponse.paymentUrl) {
              // eslint-disable-next-line no-undef
              location.href = this.state.holdResponse.paymentUrl;
            }
          } else {
            FlightBookingModule.openFlightDateChangePaymentPage(
              JSON.stringify(this.state.holdResponse),
            );
          }
          this.setState({ viewState: DcViewState.SHOW_DETAIL, responseJson: this.reviewResponse });
        }
        break;
      case 'THANKYOU':
        Actions.dateChangeSucess({
          paymentSuccess: true,
          commitMsg: commitMessage,
          commitTitle,
          bookingId,
        });
        break;
      case 'FAILURE':
        Actions.dateChangeSucess({
          paymentSuccess: false,
          commitMsg: commitMessage,
          commitTitle,
          bookingId,
        });
        break;
      default:
        this.setState({ viewState: DcViewState.ERROR });
    }
  }

  openPaymentPage(holdResponse) {
    FlightEventsLogger.logAction(FLIGHT_LOG_ACTIONS.OPEN_ODC_PAYMENT_PAGE, holdResponse);
    if (Platform.OS === 'web') {
      if (holdResponse.paymentUrl) {
        // eslint-disable-next-line no-undef
        location.href = holdResponse.paymentUrl;
      }
    } else {
      FlightBookingModule.openFlightDateChangePaymentPage(JSON.stringify(holdResponse));
    }
    DateChangeTrackingHelper.trackClickableEvent('datechangereviewfareupdate', 'proceed', '');
  }

  renderHoldAPIFailureView() {
    return (
      <ErrorView
        message={this.state.errorMsg}
        showRetry
        onRetry={() => {
          this.onHoldFailureRetryClicked();
        }}
      />
    );
  }

  onHoldFailureRetryClicked = () => {
    let nextFailureStep = this.state.errorNextStep;
    if (!nextFailureStep) {
      nextFailureStep = this.holdAPIErrorNextStep;
    }
    if (nextFailureStep) {
      switch (nextFailureStep.toUpperCase()) {
        case 'LISTING':
          this.refreshListingPage();
          break;
        case 'PICK_DATES':
        case 'REVIEW':
          this.fetchData();
          break;
        default:
          this.fetchData();
          break;
      }
    } else {
      this.fetchData();
    }
  };

  refreshListingPage = () => {
    let somethingPopped;
    try {
      somethingPopped = Actions.popTo('flightListing');
    } catch (e) {
      // e.print();
    }
    if (somethingPopped) {
      this.props.refreshListingPage();
    }
  };
}

const mapDispatchToProps = (dispatch) => ({
  refreshListingPage: () => {
    dispatch(refreshListing());
  },
});


const ConnectedDateChangeReviewPage=(props)=>{
  return <DateChangeReviewPage {...props} />
}

export default withRouterWrapper(connect(null, mapDispatchToProps, null , {context:ThemeContext})(ConnectedDateChangeReviewPage));
