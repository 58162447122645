import React from 'react';
import Actions from '../../navigation/postSalesNavigation';
import PropTypes from 'prop-types';
import {isEmpty} from 'lodash';
import {Image, ScrollView, Text, TouchableWithoutFeedback, View} from 'react-native';
import StickyHeader from '../../hotel/details/components/StickyHeader';
import styles from '../styles/AcmeMamiCss';
import FullWidthButton from '../../hotel/details/ActionButton/FullWidthButton';
import AcmeCancellationReviewStrip from './AcmeCancellationReviewStrip';
import AcmeBookingTrackingHelper from '../utils/AcmeBookingTrackingHelper';
import BasePage from '../../Common/PostSalesBasePage';
import AcmeRefundOverlay from './AcmeRefundOverlay';
import {openAcmeCancellationThankYou} from '../utils/AcmeBookingDetailUtil';
import {refundIcon, headerIcon} from '../utils/AcmeImageConstant';


const trackingPageName = 'Mob:customer support:ACME:Fullcancellation';

class AcmeCancellationRefundMode extends BasePage {
  static navigationOptions = {
    header: null
  };

  constructor(props) {
    super(props, 'acmeCancellationRefundMode');
    this.refundModes = props.cancellationRefundMode.refundModeList;
    this.message = props.cancellationRefundMode.message;
    this.state = {
      radiobtn: this.refundModes[0].refundModeReason,
      overlay: false
    };
  }

  radio = (active) => {
    this.setState({
      radiobtn: active
    });
  };

  render() {
    return (

      <View style={styles.flex1}>
        <View style={[styles.whiteContainer]}>
          {this.renderHeader()}
          {this.renderContent()}
          {this.renderFooter()}
        </View>
        {this.state.overlay ? this.renderConfirmationPopup() : null}
      </View>

    );
  }

  renderHeader = () => (
    <StickyHeader
      iconSrc={headerIcon}
      title={this.props.headerTitle}
      headerColor={['#ffffff', '#ffffff']}
      titleStyle={[styles.DefaultText, styles.blackFont]}
      subTitle={this.props.headerSubTitle}
      subTitleStyle={[styles.DefaultText, styles.font12]}
      onBackPress={this.goBack}
    />
  );

  renderContent = () => (
    <ScrollView>
      <AcmeCancellationReviewStrip
        step1="REVIEW REFUND"
        step2="CHOOSE REFUND MODE"
        step1Active={false}
      />
      <View style={[styles.MamiCard]}>
        <View style={styles.CardContent}>
          <View>
            <Image style={styles.CardIcon} source={refundIcon} />
          </View>
          <View style={styles.ContentPortion}>
            <Text style={[styles.blackFont, styles.BlackText]}>SELECT A MODE TO RECEIVE REFUND
            </Text>
          </View>
        </View>
        {!isEmpty(this.message) &&
        <View style={styles.refundModeMessageCard}>
          <Text style={[styles.BlackText]}>{this.props.cancellationRefundMode.message}
          </Text>
        </View>}
        <View style={[styles.ActionBtn, styles.mar15Top]}>
          {this.addRefundModeOptions()}
        </View>
      </View>
    </ScrollView>
  );

  renderConfirmationPopup = () => (
    <AcmeRefundOverlay
      backPress={this.refundpopup}
      confirmClicklistener={this.overlayConfirmCancelClick}
    />
  );

  overlayConfirmCancelClick = () => {
    openAcmeCancellationThankYou(this.props.acmeBookingResponse, this.state.radiobtn);
    AcmeBookingTrackingHelper.trackClickEvent(trackingPageName, 'MI_ACME_OVERLAY_CONFIRM_CANCEL');
  };

  renderFooter = () => (
    <FullWidthButton
      buttonText="CONFIRM CANCELLATION"
      clickHandler={this.onConfirmCancelClicked}
    />
  );

  refundpopup = () => {
    this.overlayshown = !this.state.overlay;
    this.setState({overlay: !this.state.overlay});
  };

  overlayBackPress = () => {
    this.refundpopup();
    AcmeBookingTrackingHelper.trackClickEvent(trackingPageName, 'MI_ACME_OVERLAY_BACK');
  };

  onConfirmCancelClicked = () => {
    this.refundpopup();
  };

  addRefundModeOptions() {
    const views = [];
    this.refundModes.forEach((refundModeObj) => {
      views.push(<TouchableWithoutFeedback onPress={() => {
        this.radio(refundModeObj.refundModeReason);
      }}
      >
        <View style={styles.refundCardOuter}>
          <View style={[styles.flexRow]}>
            <View style={styles.MimaRadio}>
              <View
                style={[this.state.radiobtn === refundModeObj.refundModeReason ? styles.MimaRadioInside : '']}
              />
            </View>
            <View style={{width: '90%'}}>
              <View style={styles.refundCardWrap}>
                <View style={styles.refundCardLeft}>
                  <Text
                    style={[styles.font14, styles.boldFont, styles.BlackText]}
                  >{refundModeObj.title}
                  </Text>
                  {this.addSubText(refundModeObj.subText)}
                </View>
                <Text
                  style={[styles.BlackText,
                    styles.boldFont, styles.font16, styles.AlignRight]}
                > {this.props.refundAmount}{' '}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </TouchableWithoutFeedback>);
    });
    return views;
  }

  goBack = () => {
    Actions.pop();
  };

  onHardBackPress = () => {
    if (this.overlayshown) {
      this.refundpopup();
      return true;
    }
    return false;
  };

  addSubText(subTitleList) {
    const views = [];
    subTitleList.forEach((subtitle) => {
      views.push(<Text style={[styles.font13]}>{subtitle}</Text>);
    });
    return views;
  }
}

AcmeCancellationRefundMode.propTypes = {
  refundAmount: PropTypes.string.isRequired,
  headerTitle: PropTypes.string.isRequired,
  headerSubTitle: PropTypes.string.isRequired,
  cancellationRefundMode: PropTypes.object.isRequired,
  acmeBookingResponse: PropTypes.object.isRequired
};

export default AcmeCancellationRefundMode;

