const FlightBookingDetailsConstant = {
  ACTION_CHAT_WITH_US: 'ChatWithUs',
  CALL_AIRLINE_ACTION_BTN_TEXT: 'Call Airline',
  CALL_AIRLINE_ACTION_BTN_FAMILY: 'CallAirline',
  CHAT_WITH_US_ACTION_BTN_TEXT: 'Chat with Myra',
  CORPORATE_CALL_SUPPORT_TEXT: 'Contact Us',
  ACTION_CORPORATE_CALL: 'CallCorporateNumber',
  ACTION_MEAL: 'AddMeal',
  ACTION_SEAT: 'AddSeat',
  ACTION_BAGGAGE: 'AddBaggage',
  ACTION_WEBCHECKIN: 'WebCheckIn',
  ACTION_WHEELCHAIR: 'WheelChair',
  CHAT_WITH_US_ACTION_BTN_FAMILY: 'ChatWithMyra',
  ACTION_PAYMENT_DETAILS_PAGE: 'PaymentDetailsPage',
  ACTION_DOWNLOAD_INVOICE: 'DownloadInvoice',
  ACTION_DOWNLOAD_VOUCHER:'DownloadCustomerVoucher',
  ACTION_RELOAD: 'Reload',
  ACTION_CANCELLATION_OPTIONS: 'CancellationOptions',
  ACTION_MODIFICATION_OPTIONS: 'ModificationOptions',
  MY_WALLET: 'MYWALLET',
  E_COUPON: 'E-Coupon',
  INSTANT_CASHBACK: 'INSTANT CASHBACK',
  CONFIRM: 'CONFIRM',
  SEAT_TYPE: 4,
  MEAL_TYPE: 0,
  BAGGAGE_TYPE: 1,
  HAND_BAGGAGE: 0,
  LUGGAGE: 1,
  CANCELLED: 'CANCELLED',
  FAILED: 'FAILED',
  FLIGHT: 'flight',
  UPCOMING: 'UPCOMING',
  PENDING: 'PENDING',
  COMPLETED: 'COMPLETED',
  ONTRIP: 'ONTRIP',
  PRECISION: 0.001,
  ONE_WAY: 3,
  ROUND_TRIP: 2,
  MULTI_CITY: 1,
  DATE_NOT_SUPPORTED_AIRLINES: '2T, I5',
  POSTPONED: 'FLIGHTPOSTPONED',
  PREPONED: 'FLIGHTPREPONED',
  CANCEL: 'CANCEL',
  OTHERS: 'OTHERS',
  PARTIAL_CANCELLATION_ALLOWED_YES: 'YES',
  PARTIAL_CANCELLATION_ALLOWED_NO: 'NO',
  REFUNDED: 'REFUNDED',
  TOP_STATUS_CARD: 'TopStatusCard',
  CTA_CARD: 'CTACard',
  FLIGHT_DETAILS_CARD: 'FlightDtlsCard',
  SPECIAL_CLAIM_CARD: 'SpecialClaimCard',
  IMP_INFORMATION_CARD: 'ImpInformationCard',
  CUSTOMER_CONTACT_CARD: 'CustomerContactCard',
  SUPPORT_CARD: 'SupportCard',
  REFUND_CARD: 'RefundCard',
  FLIGHT_CAB_ADDON_CARD: 'FlightCabAddOnCard',
  FLIGHT_CAB_CROSS_SELL_CARD: 'FlightCabCrossSellCard',
  FLIGHT_HOTEL_CROSS_SELL_CARD: 'FlightHotelCrossSellCard',
  CANCEL_DETAIL_INFO_CARD: 'CancelDetailInfoCard',
  DATE_CHANGE_DETAIL_INFO_CARD: 'DateChangeDetailInfoCard',
  PAYMENT_CARD: 'PaymentCard',
  CANCEL_DETAIL_INFO_CARD_OR_CHANGE_IN_PLAN: 'CancelDetailInfoCardORChangeInPlan',
  DATE_CHANGE_DETAIL_INFO_CARD_OR_CHANGE_IN_PLAN: 'DateChangeDetailInfoCardORChangeInPlan',
  SPECIAL_CLAIM_CARD_OR_CHANGE_IN_PLAN: 'SpecialClaimCardORChangeInPlan',
  SPECIAL_REQUEST_CARD: 'SpecialRequestCard',
  DOOR_TO_DOOR_CARD: 'DoorToDoorCard',
  LOYALTY_CARD: 'LoyaltyCard',
  SCHEDULE_CHANGE_BELT: 2,
  SCHEDULE_CHANGE_GATE_CHECKIN: 1,
  INITIAL_SCHEDULE_CHANGE_STATE: 1,
  ALL_CHECKED_IN: 'ALL_CHECKED_IN',
  OPEN_MMT_SUPPORTED: 'OPEN_MMT_SUPPORTED',
  OPEN: 'OPEN',
  WINDOW_CLOSED: 'WINDOW_CLOSED',
  AIRLINE_NOT_SUPPORTED: 'AIRLINE_NOT_SUPPORTED',
  NOT_OPEN: 'NOT_OPEN',
  INITIAL_STATE: 1,
  TYPE_BAGGGEBELT: 2,
  CALL_AIRLINE: 'CALL_AIRLINE',
  FILE_SPECIAL_CLAIM: 'FILE_SPECIAL_CLAIM',
  CONTACT_US: 'CONTACT_US',
  ECONOMY: 'Economy',
  BUSINESS: 'Business',
  FIRST_CLASS: 'First Class',
  PREMIUM_ECONOMY: 'Premium Economy',
  ADULT: 'adult',
  CHILD: 'child',
  INFANT: 'infant',
  DETAILS_NOT_AVAILABLE: 'Details Not Available',
  HAND_BAG_ONLY_FARE: 'Hand Bag Only Fare',
  SEAT: 'Seat',
  MEAL: 'Meal',
  BAGGAGE: 'Baggage',
  ANCILLARY: 'ANCILLARY',
  DOWNLOAD_E_TICKET: 'DOWNLOAD E-TICKET',
  DOWNLOAD_INVOICE: 'DOWNLOAD INVOICE',
  ADD_TO_PASSBOOK: 'ADD TO PASSBOOK',
  ADD_ANCILLARY: 'ADD ANCILLARY',
  ADD_SEAT: 'ADD SEAT',
  ADD_MEAL: 'ADD MEAL',
  ADD_BAGGAGE: 'ADD BAGGAGE',
  TRIP_PROTECT_CARD: 'TripProtectCard',
  BAGGAGE_PROTECT_CARD: 'BaggageProtectCard',
  ASSURANCE_CARD: 'AssuranceCard',
  TP_ASSURANCE_CARD: 'TpInsuranceCard',
  RT_PCR_CARD: 'RTPCRCard',
  ALERT_INFO_CARD: 'alertinfocard',
  ACTION_CALL_MMT_SUPPORT_GCC: 'CallMMTSupport',
  CALL_MMT_SUPPORT_GCC_ACTION_BTN_TEXT: 'Call Us',
  FLIGHT_MSR: 'MsrMainCard',
  NEED_MORE_HELP_CARD: 'NeedMoreHelpCard',
  COPY_PNR_PROCEED: 'COPY PNR & PROCEED',
  FLIGHT_ADD_TECH_CARD: 'FlightAddTechCard',
  FARE_LOCK_ACTIVE_CARD: 'FareLockBookingCard',
  HOLD_BOOKING_ACTIVE_CARD: 'PendingPaymentCard',
  PENDING_PAYMENT_CARD: 'PendingPaymentCard',
  WHY_FARE_LOCK_FAIL_CARD: 'WhyFareLockFailCard',
  WHY_HOLD_BOOKING_FAIL_CARD: 'WhyHoldBookingFailCard',
  FARE_LOCK_TANDC_CARD: 'FareLockTAndCCard',
  HOLD_TANDC_CARD: 'HoldTermsAndConditionCard',
  FLIGHT_SEGMENT_PARENT_HEIGHT: 'FLIGHT_SEGMENT_PARENT_HEIGHT',
  FLIGHT_SEGMENT_CHILD_HEIGHT: 'FLIGHT_SEGMENT_CHILD_HEIGHT',
  FLIGHT_SEGMENT_TRAVELLER_HEIGHT: 'FLIGHT_SEGMENT_TRAVELLER_HEIGHT',
  FLIGHT_DETAILS_HEAD_HEIGHT: 'FLIGHT_DETAILS_HEAD_HEIGHT',
  DIGIT_INSURANCE_CARD: 'InsuranceListingCard',
  NEED_HELP_FOR_REFUND: 'NeedHelpForRefund',
  CSAT_FEEDBACK_CARD: 'CsatFeedbackCard',
  FLIGHT_FIREBASE_PROJECT: 'undowebcheckin-b56e7',
  FLIGHT_FIREBASE_DB_URL:
    'https://undowebcheckin-b56e7-default-rtdb.asia-southeast1.firebasedatabase.app/',
  ACTION_DOWNLOAD_ETICKET: 'DOWNLOAD_ETICKET',
  ACTION_ADD_TO_PASSBOOK: 'ADD_TO_PASSBOOK',
  FLIGHT_ETICKET: 'Flight_CustomerETicket',
  FLIGHT_CUSTOMER_BOOKING : 'Flight_Eticket_MyPartner',
  BASE_FARE: 'baseFare',
  FLIGHT_DOWNLOAD_PDF: "FLIGHT_DOWNLOAD_PDF",
  DOWNLOAD_INVOICE_POPUP: 'DOWNLOAD_INVOICE_POPUP',
  TRAVEL_PASS_CARD: 'TravelPassCard',
  TRAVEL_INSURANCE_CARD: 'TravelInsuranceCard',
  PART_PAYMENT_CARD: 'PartPaymentCard',
};

export default FlightBookingDetailsConstant;

export const LAST_MINUTE_CANCELLATION_STATUS = {
  CANCELLATION_IN_PROGRESS: 'CANCELLATION_IN_PROGRESS',
  CANCELLATION_SUCCESS: 'CANCELLATION_SUCCESS',
  CANCELLATION_FAILED: 'CANCELLATION_FAILED',
};
export const FLIGHT_DOWNLOAD_TRIGGERS = {
  E_TICKET: 'Flight_CustomerETicket',
  INVOICE: 'Flight_Customer_Invoice',
  BAGGAGE: 'Flight_BaggageTag',
  INSURANCE: 'InsuranceVoucher',
  BOARDING_PASS: 'BOARDING_PASS',
  FLIGHT_ETICKET_MYPARTNER: 'Flight_Eticket_MyPartner',
  DOWNLOAD_FAILED: 'DOWNLOAD_FAILED',
};
export const FLIGHT_BOOKING_ID_INITIALS = {
  US: 'UN',
  UAE: 'AN',
  ON: 'ON',
  KSA: 'SN',
  KN: 'KN',
};

export const HELPLINE_NUMBER = {
  GCC_BOOKING: '+91 124 478 1970',
  KSA_BOOKING: '+966 8008501184',
};

export const TOP_CARD_BOOKING_STATUS = {
  CTRIP_UNCONFIRMED: 1,
  FULLY_AWAITED: 2,
  PARTIALLY_AWAITED: 3,
  PARTIALLY_FAILED: 4,
  FAILED_AWAITED: 5,
  FAILED_AWAITED_CONFIRMED: 6,
  SPECIAL_CLAIM_RAISED: 7,
  
};

export const SEGMENT_STATUS = {
  CONFIRMED: 1,
  PARTIALLY_CONFIRMED: 2,
  FAILED: 3,
  AWAITED: 4,
  CANCELLED: 5,
};

export const TopCardType = {
  FARE_LOCK_HOLD: 8,
  FARE_LOCK_EXPIRED: 9,
  FARE_LOCK_FAILED: 10,
  FARE_LOCK_CANCELLED_BY_AIRLINE: 11,
  SEAT_NOT_AVAILABLE: 12,
  TEMPORARY_BOOKING: 13,
  TEMPORARY_BOOKING_FAILED: 14,
  TEMPORARY_BOOKING_EXPIRED: 15,
  TEMPORARY_BOOKING_CANCELLED: 16,
};

export const EventEmitterConst = {
  CTA_CLICKED_EVENT: 'cta-clicked',
};

export const RefNameConst = {
  FLIGHT_WEB_CHECKIN_REF: '_webCheckinRef',
  FLIGHT_CANCELLATION_REF: '_cancellationRef',
  FLIGHT_DATE_CHANGE_REF: '_dateChangeRef',
  FLIGHT_INSURANCE_REF: '_insuranceRef',
};

export const DEEP_LINK_EVENT_NAME = {
  FLIGHT_WEB_CHECKIN_CTA: 'webcheckinlink',
  FLIGHT_DATE_CHANE_CTA: 'datechangectalink',
  FLIGHT_CANCELLATION_CTA: 'cancellationctalink',
};

export const ViewState = {
  LOADING: 'loading',
  LOADING_PAY:'loading_pay',
  SHOW_DETAIL: 'detail',
  REFRESH: 'refresh',
  NO_INTERNET: 'no_internet',
  DEFAULT: 'default',
  ERROR: 'error',
  NO_RESULT: 'no_result',
  HOLD_SUCCESS: 'HOLD_SUCCESS',
  NOT_LOGGED_IN: 'not_logged_in',
  HOLD_LOADING: 'HOLD_LOADING',
  COMMIT :'commit',
  COMMIT_SUCCESS : 'commit_success',
  COMMIT_FAIL : 'commit_fail',
  NO_CABS : 'no_cabs',
  RESEND_VOUCHER: 'resend_voucher',
  FORCE_CLOSE: 'force_close',
  DOWNLOAD_ETICKET_IOS: 'DOWNLOAD_ETICKET_IOS',
};

export const WHEEL_CHAIR_POPUP_CLOSE_TIME = 2000;

export const WHEEL_CHAIR_PAX_STATUS = {
  PENDING: 4,
  ACCEPT: 0,
  REJECT: 3
}

export const WHEELCHAIR_TRACKING_CONSTANTS  ={
  DETAILS_PAGE: 'Details',
  THANK_YOU_PAGE: 'Add_wheelchair_thankyou',
  ERROR_MSG: 'Add_wheelchair_failed'
}

export const FLIGHT_THROTTLE_OBJECT = {
  throttleInterval: 1000,
  isThrottleRequired: true,
};

export const SPECIAL_CLAIM_CONST = {
  CANCEL_FLT_FULL: 'CANCEL_FLT_FULL',
  EXPECTING_REFUND: 'EXPECTING_REFUND',
}
export const CONNECTION_GUARANTEE_CONSTANTS = {
  TALK_TO_US: 'TALK_TO_US',
  CLAIM_NOW: 'CLAIM_NOW'
}

export const VOID_DGCA_CONSTANTS = {
  DGCA_VOID_DATE_CHANGE_INFO: 'dgcaVoidDateChangeInfo',
  PROCESS_DATECHANGE: 'process_datechange'
}

export const FLIGHT_DOWNLOAD_FILES_THROTTLE_INTERVAL = 3000;
