import { StyleSheet } from 'react-native';

export default  (theme) => StyleSheet.create({
    calStripWrapper: {
      borderTopColor: theme.color.lightGrey,
      borderTopWidth: 1,
      padding: 15,
    },
    listWrapper: {
      marginLeft: -10,
    },
    dateBtnWrapper: {
      borderRadius: 4,
      borderWidth: 1,
      borderColor: theme.color.gray8,
      paddingHorizontal: 20,
      paddingVertical: 12,
      marginRight: 10,
    },
    activeBtn: {
      borderWidth: 1,
      borderColor: theme.color.azure,
      backgroundColor: theme.color.azure,
    },
    monthWrapper: {
      width: 38,
      height: 18,
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      alignItems: 'center',
      justifyContent: 'center',
      transform: [{ rotate: '-90deg' }],
      marginLeft: -10,
    },
    calIconWrapper: {
      width: 41,
      height: 38,
      alignItems: 'center',
      justifyContent: 'center',
      borderWidth: 1,
      borderColor: theme.color.gray8,
      borderBottomRightRadius: 6,
      borderTopRightRadius: 6,
    },
    calIconStyle: {
      width: 24,
      height: 24,
    },
    upperText: {
      textTransform: 'uppercase',
    },
    marginTop11: {
      marginTop: 10.5,
    },
    monthTextBox: {
        color: theme.color.white,
    }
});
