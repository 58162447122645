import { StyleSheet } from 'react-native';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';

export default (theme, fonts) => {
  const { color } = theme;
  return StyleSheet.create({
    oldBookingSection: {
      backgroundColor: color.faintgrey,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      justifyContent: 'center',
      alignItems: 'center',
      paddingVertical: 8,
      width: '90%',
      left: '5%',
      marginTop:-10,
      zIndex:10,
    },
    paddingtest:{
      marginLeft:16,
      marginRight:16,
    },
		otpCustomStyle :{
      marginHorizontal: 16,
    },
		whiteCardNeg20: {
      marginTop: -20,
      zIndex: 5,
    },
  });
};
