import { updateForAllCases, getIntialState, onFieldTouch } from '../utils';
import {ACTION_TYPES} from './actionTypes'

// Dirty fields - input fields to validate & show error based on onFocus event 

export const initialState = {
  activeTab: 0,
  bottomOverlay: '',
  pickupDetails: [],
  showDropOffDetails: [],
  isDropOff: false,
  currentUpdateIndex: null,
  dirtyFields :[],
};

export const carPickupReducer = (state, { type, payload }) => {
  const oldPickupDetailsData = state.pickupDetails;

  switch (type) {
    case ACTION_TYPES.SET_TAB_CHANGE:
      return {
        ...state,
        activeTab: payload.index,
      };

    case ACTION_TYPES.SET_BOTTOM_OVERLAY:
      return {
        ...state,
        bottomOverlay: payload.bottomOverlay,
        isDropOff:payload.isDropOff
      };
    case ACTION_TYPES.SET_PICKUP_DETAILS:
      return getIntialState(state, payload.response);
    case ACTION_TYPES.UPDATE_PICKUP_DETAILS:
      const { index, data } = payload;

      if (payload.isDropOff) {
        oldPickupDetailsData[index].dropoff = data;
      } else {
        oldPickupDetailsData[index].pickup = data;
      }
      return {
        ...state,
        pickupDetails: oldPickupDetailsData,
      };
    case ACTION_TYPES.ON_FIELD_CHANGE:
      const { key, value, isDropOff } = payload;
      if (isDropOff) {
        oldPickupDetailsData[state.activeTab].dropoff[key] = value;
      } else {
        oldPickupDetailsData[state.activeTab].pickup[key] = value;
      }
      return {
        ...state,
        pickupDetails: oldPickupDetailsData,
        currentUpdateIndex: ['travellerName', 'contactNumber'].includes(key)
          ? null
          : state.currentUpdateIndex,
      };
    case ACTION_TYPES.ADD_DROP_OFF_DETAILS:
      return {
        ...state,
        showDropOffDetails: [...state.showDropOffDetails, state.activeTab],
      };
    case ACTION_TYPES.REMOVE_DROP_OFF_DETAILS:
      oldPickupDetailsData[state.activeTab].dropoff = payload.data;
      return {
        ...state,
        showDropOffDetails: [
          ...state.showDropOffDetails.filter((index) => index !== state.activeTab),
        ],
        pickupDetails: oldPickupDetailsData,
      };
    case ACTION_TYPES.UPDATE_FOR_ALL_CASES:
      return {
        ...state,
        pickupDetails: updateForAllCases(
          state.pickupDetails,
          state.showDropOffDetails,
          state.activeTab,
          payload.isDropOff,
        ),
        currentUpdateIndex:
          state.currentUpdateIndex === `${state.activeTab}${payload.isDropOff}`
            ? null
            : `${state.activeTab}${payload.isDropOff}`,
      };
      case ACTION_TYPES.ON_FIELD_TOUCH:
        return {
          ...state,
          dirtyFields: onFieldTouch({ dirtyFields: state.dirtyFields,activeTab: state.activeTab,...payload }),
        };
    default:
      return state;
  }
};
