import React from 'react';
import { getHotelSummaryData } from './hotelCardAdapter';

import {
  ActionsContent,
  DescriptionSection,
  MainContent,
  SummaryContainer,
  DescriptionCardInfoSection,
  HotelCardInfoSection,
  JourneyHotelInfoText,
  StatusInfoSection,
  WhereToGoTopImage
} from '../cardElements';
import MSRRefundInfoCard from '../MSRRefundInfoCard';
import { HotelData, MsrTicket } from '../cardInterfaces';

interface HotelSummaryCardProps {
  response: HotelData;
  msrTicket: MsrTicket;
  isUpcomingOrOnTripFirstBooking: boolean,
  topImage: string
}
const HotelSummaryCard = ({ response, msrTicket, isUpcomingOrOnTripFirstBooking, topImage }: HotelSummaryCardProps) => {
  const cardData = getHotelSummaryData(response, msrTicket);
  const {
    iconUrl,
    imageUrl,
    destCityName,
    dateTime,
    hotel,
    actions,
    myRequestsCard,
    checkInText,
    checkOutText,
    startDateText,
    endDateText,
    startTimeText,
    endTimeText,
    startsIn,
    infoCardStatusText,
    infoCardStatusCTA,
    lobName,
    bookingId,
    status,
    arrTimeRange,
    deptTimeRange,
    openDetails,
    handleInfoPress } = cardData || {};

  return (
    <>
      <SummaryContainer bookingId={response.bookingID}>
        {topImage && isUpcomingOrOnTripFirstBooking &&
          <WhereToGoTopImage topImageText={startsIn} topImage={topImage} />
        }
        <MainContent onPress={openDetails} >
          <DescriptionSection>
            <HotelCardInfoSection destCityName={destCityName} passengerDetails={dateTime} />
            <DescriptionCardInfoSection icon={iconUrl} imageUrl={imageUrl} descriptionText={hotel} />
            <JourneyHotelInfoText
              checkInText={checkInText}
              startDateText={startDateText}
              startDateTime={deptTimeRange || startTimeText}
              checkOutText={checkOutText}
              endDateText={endDateText}
              endDateTime={arrTimeRange || endTimeText}
            />
            {infoCardStatusText && <StatusInfoSection statusText={infoCardStatusText} statusCta={infoCardStatusCTA} handleInfoPress={handleInfoPress} />}
          </DescriptionSection>
        </MainContent>
        <ActionsContent actions={actions} isInfoCardShown={!!infoCardStatusText}/>
        {myRequestsCard && <MSRRefundInfoCard data={myRequestsCard} lobName={lobName} status={status} bookingId={bookingId} />}
      </SummaryContainer>
    </>
  )
}

export default HotelSummaryCard