
import {
  CAB_SPECIAL_CLAIM_URL,
  getCommonHeaders
} from '../../../../utils/NetworkUtils';

export const raiseSpecialClaim =  async (request, userInfo) => {
  try {
    const response = await fetch(CAB_SPECIAL_CLAIM_URL, {
      method: 'POST',
      body: JSON.stringify(request),
      headers: await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo),
    });

    const data = await response.json();
    return data;
  }
  catch (err) {
      console.log('Error in fetching customer support card data', err);
  }
}