import { colors } from '@mmt/legacy-commons/Styles/globalStyles';

const styles = {
  pageWrapper: {
    flex: 1,
  },
  whiteBg: {
    backgroundColor: colors.white,
  },
  headerTitle: {
    color: colors.black,
  },
  whiteCard: {
    backgroundColor: '#ffffff',
    elevation: 2,
    shadowColor: '#000000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 2,
    marginVertical: 6,
    padding: 16,
  },
  cancelInfoWrapper: {
    flex: 1,
  },
  errorInfoIcon: {
    width: 32,
    height: 32,
    marginRight: 10,
  },
  cancelTopHeading: {
    color: '#000',
    marginBottom: 5,
  },
  cancelDiscription: {
    lineHeight: 20,
    color: colors.defaultTextColor,
  },
  cancelPaxDtlsWrapper: {
    borderColor: '#e7e7e7',
    borderRadius: 4,
    borderWidth: 1,
    marginLeft: 45,
    marginTop: 12,
  },
  cancelPaxDtls: {
    padding: 12,
    borderBottomColor: '#e7e7e7',
    borderBottomWidth: 1,
  },
  userIcon: {
    width: 16,
    height: 12,
    marginRight: 10,
  },
  dbIcon: {
    width: 16,
    height: 16,
    marginRight: 10,
  },
  styleDisable: {
    opacity: 0.3,
  },
  btnWrapper: {
    padding: 16,
    paddingBottom: 0,
  },
  dividerWrapper: {
    alignItem: 'center',
    justifyContent: 'center',
    height: 50,
    position: 'relative',
    marginHorizontal: 16,
  },
  dividerLine: {
    width: '100%',
    height: 1,
    backgroundColor: '#ccc',
    position: 'absolute',
  },
  orDivider: {
    width: 32,
    height: 32,
    borderRadius: 50,
    borderColor: '#ccc',
    borderWidth: 1,
    backgroundColor: '#f2f2f2',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  orDividerText: {
    color: colors.defaultTextColor,
    alignSelf: 'center',
  },
  rightArrow: {
    width: 14,
    height: 18,
  },
  callUsTxt: {
    color: '#000',
    marginBottom: 5,
  },
  rtArrowPosition: {
    top: 12,
  },
};

export default styles;
