import React from 'react';
import { View, Text, Image, StyleSheet } from 'react-native';
import Actions from '../../../../../navigation/postSalesNavigation';
import HTMLView from 'react-native-htmlview';
import isEmpty from 'lodash/isEmpty';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';
import { EXCHANGE_FLIGHT_URL } from '../../../../../utils/NetworkUtils';
import Button from '../../../../../Common/Button';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import { getStaticData } from '../../../../../staticData/staticData';
import { ADJUSTED_ICON, USER_ICON, FLT_ICON, BACK_WHITE_ICON } from '../../../../FlightImageConstants';
import { getFont } from '../../../../../PostSalesStyles';

const FlightExchangeCard = (props) => {
  const { fsStyle, ...fonts } = getFont(true);
  const { title, description, eligiblePnrExchangeInfoList } = props.exchangeFlightCardInfo;
  const { informationText } = getStaticData();

  const getInjectedJavaScript = (mmtAuth) => {
    return `
      (function(){
          
        function getCookie(cname) {
          var name = cname + "=";
          var decodedCookie = decodeURIComponent(document.cookie);
          var ca = decodedCookie.split(';');
          for(var i = 0; i <ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
              c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
              return c.substring(name.length, c.length);
            }
          }
          return "";
        }

        var auth = getCookie('mmt-auth');
        if(auth != '${mmtAuth}') {
          document.cookie = 'mmt-auth=${mmtAuth}';
          location.reload();
        }
      })();
      true;
    `;
  };

  const getImageNameForRules = (ruleType) => {
    switch (ruleType) {
      case 'CREDIT_SHELL':
        return ADJUSTED_ICON;
      case 'RESTRICTION_BOOKING':
        return FLT_ICON;
      case 'RESTRICTION_TRAVELLER_NAME':
        return USER_ICON;
      default:
        return ADJUSTED_ICON;
    }
  };

  const onBtnClicked = async (actionType, bookingId, pnr) => {
    const url = EXCHANGE_FLIGHT_URL + `?bookingId=${bookingId}&pnr=${pnr}&source=app`;
    const { heading } = getExchangeDataForPnr(props.flightResponse, pnr) || {};
    const userInfo = await HotelBookingModule.getRequestHeader(
      bookingId,
      'FLIGHT_EXCHANGE_SEARCH_NEW_FLIGHT',
    );

    if (actionType === 'VIEW_NEW_FLIGHTS') {
      Actions.openPsWebView({
        headerText: heading || '',
        headerIcon: BACK_WHITE_ICON,
        url: url,
        injectedJavaScript: getInjectedJavaScript(userInfo.mmtAuth),
      });
    }
  };

  const getExchangeDataForPnr = (response, pnr) => {
    const { exchangeOdcPnrInfoList } = response;
    let result;
    if (exchangeOdcPnrInfoList) {
      exchangeOdcPnrInfoList.forEach((item) => {
        if (item.pnrNo === pnr) {
          result = item;
          return result;
        }
      });
    }
    return result;
  };

  return (
    <View style={[styles.ExchangeCardWrapper, AtomicCss.marginBottom10]}>
      {title && (
        <Text
          style={[
            fonts.blackFontFamily,
            AtomicCss.blackText,
            fsStyle.font20,
            AtomicCss.marginBottom10,
          ]}
        >
          {title}
        </Text>
      )}
      {description && (
        <Text
          style={[
            AtomicCss.defaultText,
            fonts.regularFontFamily,
            fsStyle.font14,
            AtomicCss.lineHeight20,
          ]}
        >
          {description}
        </Text>
      )}
      {!isEmpty(eligiblePnrExchangeInfoList) &&
        eligiblePnrExchangeInfoList.map((item) => (
          <View style={[styles.exchangeInnerWrapper]}>
            <View style={[AtomicCss.flexRow, AtomicCss.marginBottom5, AtomicCss.alignCenter]}>
              {item.title && (
                <Text
                  style={[
                    AtomicCss.flex1,
                    fonts.blackFontFamily,
                    AtomicCss.blackText,
                    fsStyle.font17,
                  ]}
                >
                  {item.title}
                </Text>
              )}
              {item.airlineImageMobileUrl && (
                <Image style={styles.airlineIconStyle} source={item.airlineImageMobileUrl} />
              )}
            </View>
            {item.newDescription && (
              <HTMLView
                value={item.newDescription}
                onLinkPress={(url) =>
                  Actions.openPsWebView({
                    headerText: informationText,
                    headerIcon: BACK_WHITE_ICON,
                    url,
                  })
                }
              />
            )}
            {item.ruleList.map((rule) => (
              <View style={[AtomicCss.flexRow, AtomicCss.marginBottom20]}>
                <View style={styles.imgPlaceHodler}>
                  {rule.imageType && (
                    <Image
                      style={styles.adjusIconStyle}
                      source={getImageNameForRules(rule.imageType)}
                    />
                  )}
                </View>
                <View style={AtomicCss.flex1}>
                  {rule.heading && (
                    <Text
                      style={[
                        fonts.blackFontFamily,
                        AtomicCss.blackText,
                        fsStyle.font14,
                        AtomicCss.marginBottom3,
                      ]}
                    >
                      {rule.heading}
                    </Text>
                  )}
                  {rule.description && (
                    <Text style={[fonts.regularFontFamily, AtomicCss.defaultText, fsStyle.font14]}>
                      {rule.description}
                    </Text>
                  )}
                </View>
              </View>
            ))}
            {!isEmpty(item.ctaList) &&
              item.ctaList.map((cta) => (
                <View style={styles.exchangeFlightCrd__btmSec}>
                  <Button
                    btnTxt={cta.actionText}
                    btnBg="gradientBtn"
                    btnType="flat"
                    btnWidth="100%"
                    buttonTextCss={fsStyle.font14}
                    clickHandler={() => onBtnClicked(cta.actionType, props.bookingId, item.pnr)}
                  />
                </View>
              ))}
          </View>
        ))}
    </View>
  );
};

const styles = StyleSheet.create({
  ExchangeCardWrapper: {
    padding: 16,
    ...getPlatformElevation(2),
    backgroundColor: '#fff',
  },
  imgPlaceHodler: {
    width: 32,
    height: 32,
    marginRight: 10,
    marginTop: 5,
  },
  adjusIconStyle: {
    width: 28,
    height: 23,
  },
  locIconStyle: {
    width: 23,
    height: 25,
  },
  userIconStyle: {
    width: 26,
    height: 24,
  },
  rtArrorStyle: {
    width: 9,
    height: 11,
    marginHorizontal: 6,
  },
  exchangeInfoSection: {
    borderRadius: 4,
    backgroundColor: '#ffedd1',
    paddingVertical: 10,
    paddingHorizontal: 12,
  },
  exchangeInnerWrapper: {
    padding: 16,
    ...getPlatformElevation(2),
    borderRadius: 4,
    backgroundColor: '#fff',
    marginTop: 10,
    marginHorizontal: 12,
  },
  airlineIconStyle: {
    width: 30,
    height: 30,
  },
  alreadyExcStyle: {
    backgroundColor: '#f6f6f6',
  },
  exchangeFlightCrd__btmSec: {
    paddingLeft: 15,
    paddingRight: 15,
    marginBottom: 15,
    alignItems: 'center',
  },
});

export default FlightExchangeCard;
