import { StyleSheet, TextStyle, ViewStyle, Platform } from "react-native";
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
import { DEFAULT_THEME } from "apps/post-sales/src/theme/default.theme";

interface Styles {
  contactUsCard: ViewStyle;
  contactUs: ViewStyle;
  rightBlueArrow: ViewStyle;
  myBizCardWrapper: ViewStyle;
}

const createStyles = <T extends typeof DEFAULT_THEME>(theme: T) => StyleSheet.create<Styles>({
  contactUsCard: {
    backgroundColor: theme.color.white,
    paddingHorizontal: 20,
    paddingRight: 25,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 12,
    marginBottom: 16
  },
  contactUs: {
    width: 28,
    height: 42,
    marginRight: 10
  },
  rightBlueArrow: {
    width: 9,
    height: 13,
    marginRight: 10
  },
  myBizCardWrapper: {
    backgroundColor: theme.color.white,
    borderRadius: 10,
    marginHorizontal: 12,
    padding: 16,
    ...getPlatformElevation(2),
    marginBottom: 16
  }
});

export default createStyles;
