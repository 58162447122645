import getPlatformElevation from '@mmt/legacy-commons/Common/Components/Card/getPlatformElevation';
import { StyleSheet } from 'react-native';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';

const styles = StyleSheet.create({
  issueCardWrapper: {
    backgroundColor: colors.white,
    padding: 8,
    borderTopWidth: 1,
    borderTopColor: colors.grey8,
    ...getPlatformElevation(2),
  },
  innerSectionDetails: {
    paddingHorizontal: 12,
    paddingVertical: 10,
    borderRadius: 4,
    backgroundColor: colors.lighterBlue,
  },
  arrowStyle: {
    width: 9,
    height: 13,
    marginRight: 14,
  },
  iconStyle: {
    width: 28,
    height: 40,
    marginRight: 10,
  },
});

export default styles;
