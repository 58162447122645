import AsyncStorage from '@react-native-async-storage/async-storage';
import hotelFailedBookingIcon from '@mmt/legacy-assets/src/postSales/summary/failedHotelIcon.webp';
import busFailedBookingIcon from '@mmt/legacy-assets/src/postSales/summary/failedBusIcon.webp';
import cabFailedBookingIcon from '@mmt/legacy-assets/src/postSales/summary/failedCabIcon.webp';
import flightFailedBookingIcon from '@mmt/legacy-assets/src/postSales/summary/failedFlightIcon.webp';
import giftFailedBookingIcon from '@mmt/legacy-assets/src/postSales/summary/failedGiftCardIcon.webp';
import metroFailedBookingIcon from '@mmt/legacy-assets/src/postSales/summary/failedTrainIcon.webp';
import visaFailedBookingIcon from '@mmt/legacy-assets/src/postSales/summary/failedVisaIcon.webp';
import acmeFailedBookingIcon from '@mmt/legacy-assets/src/postSales/summary/failedExperienceIcon.webp';
import holidayFailedBookingIcon from '@mmt/legacy-assets/src/postSales/summary/failedHolidaysIcon.webp';
import cancelledFlightIcon from '@mmt/legacy-assets/src/postSales/summary/cancelledFlightIcon.webp';
import cancelledHotelIcon from '@mmt/legacy-assets/src/postSales/summary/cancelledHotelIcon.webp';
import cancelledBusIcon from '@mmt/legacy-assets/src/postSales/summary/cancelledBusIcon.webp';
import cancelledCabIcon from '@mmt/legacy-assets/src/postSales/summary/cancelledCabIcon.webp';
import cancelledAcmeIcon from '@mmt/legacy-assets/src/postSales/summary/cancelledExpeIcon.webp';
import cancelledVisaBookingIcon from '@mmt/legacy-assets/src/postSales/summary/cancelledVisaIcon.webp';
import cancelledGiftBookinglIcon from '@mmt/legacy-assets/src/postSales/summary/cancelledGiftCardIcon.webp';
import cancelledholidayBookingIcon from '@mmt/legacy-assets/src/postSales/summary/cancelledHolidaysIcon.webp';
import cancelledMetroIcon from '@mmt/legacy-assets/src/postSales/summary/cancelledTrainIcon.webp';
import { BookingStates, Lobs } from '../../../summaryConstants';
import myTripsNavigation from '../../../navigation/navigation';
import { cancelledTgsIcon, failedTgsIcon } from '../../../../TripGuarantee/Utils/tripGuaranteeImageConstants';
import { getDataFromStorage } from "../../../../utils/localstorage";

let recentUnsuccessfulBkg = null;

export function getLostIDSummaryData(data) {
  let bookingIntent, bookingStatus, lobName, status, paymentAttempts, isPushedAsLostId, isBookingConfirmed;
  if (data.lostBookingDetails) {
    bookingIntent = data.lostBookingDetails.bookingIntent;
    bookingStatus = data.lostBookingDetails.bookingStatus;
    lobName = data.lostBookingDetails.lobname;
    isBookingConfirmed = data.lostBookingDetails.isBookingConfirmed;
    paymentAttempts = data?.lostBookingDetails?.paymentAttempts || [];
  } else {
    bookingIntent = data.bookingIntent;
    bookingStatus = data.bookingStatus;
    lobName = data.lobname;
    isBookingConfirmed = data.isBookingConfirmed;
    paymentAttempts = data?.paymentAttempts || [];
  }
  status = data.status;
  const isPending = status === BookingStates.PENDING;
  isPushedAsLostId = data.isPushedAsLostId;
  const paymentInfo = paymentAttempts && paymentAttempts[0] ? paymentAttempts[0] : {};
  const requestNo = paymentInfo.payId || '';
  const primaryLob = lobName || [];
  let iconUrl = '';
  let iconStyle = { height: 24, width: 24 };
  let openDetails = null;
  const getPageData = (raiseReqInfo = {}) => ({
    lostBooking: true,
    isPushedAsLostId,
    requestNo,
    primaryLob,
    fromMyTrips: true,
    refundCommStatus: data.refundCommStatus,
    lostRaiseReqInfo: raiseReqInfo,
    isBookingConfirmed: isBookingConfirmed,
  });
  switch (primaryLob) {
    case Lobs.Flight:
      iconUrl = isPending ? cancelledFlightIcon : flightFailedBookingIcon;
      iconStyle = { height: 18, width: 30 };
      openDetails = (raiseReqInfo, trackClickEvent = true) => {
        myTripsNavigation.openFlightBookingDetail(data, trackClickEvent, getPageData(raiseReqInfo));
      };
      break;
    case Lobs.Hotel:
      iconUrl = isPending ? cancelledHotelIcon : hotelFailedBookingIcon;
      iconStyle = isPending ? { height: 29, width: 27 } : { height: 20, width: 20 };
      openDetails = (raiseReqInfo, trackClickEvent = true) => {
        myTripsNavigation.openHotelBookingDetail(data, getPageData(raiseReqInfo), trackClickEvent);
      };
      break;
    case Lobs.Bus:
      iconUrl = isPending ? cancelledBusIcon : busFailedBookingIcon;
      iconStyle = { height: 20, width: 19 };
      openDetails = (raiseReqInfo, trackClickEvent = true) => {
        myTripsNavigation.openBusBookingDetail(data, getPageData(raiseReqInfo), trackClickEvent);
      };
      break;
    case Lobs.Trains:
    case Lobs.Rail:
      iconUrl = isPending ? cancelledMetroIcon : metroFailedBookingIcon;
      iconStyle = { width: 20, height: 26 };
      openDetails = (raiseReqInfo, trackClickEvent = true) => {
        myTripsNavigation.openRailBookingDetail(data, getPageData(raiseReqInfo), trackClickEvent);
      };
      break;
    case Lobs.Cabs:
    case Lobs.Cars:
      iconUrl = isPending ? cancelledCabIcon : cabFailedBookingIcon;
      iconStyle = { width: 23, height: 20 };
      openDetails = (raiseReqInfo, trackClickEvent = true) => {
        myTripsNavigation.openCabBookingDetail(data, getPageData(raiseReqInfo), trackClickEvent);
      };
      break;
    case Lobs.Metro:
      iconUrl = isPending ? cancelledMetroIcon : metroFailedBookingIcon;
      iconStyle = { width: 20, height: 26 };
      openDetails = (raiseReqInfo, trackClickEvent = true) => {
        myTripsNavigation.openMetroBookingDetail(data, getPageData(raiseReqInfo), trackClickEvent);
      };
      break;
    case Lobs.Acme:
      iconUrl = isPending ? cancelledAcmeIcon : acmeFailedBookingIcon;
      iconStyle = { height: 25, width: 22 };
      openDetails = (raiseReqInfo, trackClickEvent = true) => {
        myTripsNavigation.openActivityBookingDetails(
          data,
          getPageData(raiseReqInfo),
          trackClickEvent,
        );
      };
      break;
    case Lobs.SelfDrive:
      iconUrl = isPending ? cancelledCabIcon : cabFailedBookingIcon;
      iconStyle = { width: 24, height: 21 };
      openDetails = (raiseReqInfo, trackClickEvent = true) => {
        myTripsNavigation.openSelfDriveBookingDetail(
          data,
          getPageData(raiseReqInfo),
          trackClickEvent,
        );
      };
      break;
    case Lobs.Visa:
      iconUrl = isPending ? cancelledVisaBookingIcon : visaFailedBookingIcon;
      iconStyle = isPending ? { height: 32, width: 32 } : { width: 20, height: 22 };
      openDetails = (raiseReqInfo, trackClickEvent = true) => {
        myTripsNavigation.openVisaBookingDetail(data, getPageData(raiseReqInfo), trackClickEvent);
      };
      break;
    case Lobs.GiftCard:
      iconUrl = isPending ? cancelledGiftBookinglIcon : giftFailedBookingIcon;
      iconStyle = { width: 32, height: 32 };
      openDetails = (raiseReqInfo, trackClickEvent = true) => {
        myTripsNavigation.openGiftCardBookingDetail(
          data,
          getPageData(raiseReqInfo),
          trackClickEvent,
        );
      };
      break;
    case Lobs.HOLIDAYS:
      iconUrl = isPending ? cancelledholidayBookingIcon : holidayFailedBookingIcon;
      iconStyle = { height: 25, width: 17 };
      openDetails = (raiseReqInfo, trackClickEvent = true) => {
        myTripsNavigation.openHolidayBookingDetail(
          data,
          getPageData(raiseReqInfo),
          trackClickEvent,
        );
      };
      break;
      case Lobs.TGS:
        iconUrl = isPending ? cancelledTgsIcon : failedTgsIcon;
        iconStyle = { width: 32, height: 32 };
        openDetails = (raiseReqInfo, trackClickEvent = true) => {
          myTripsNavigation.openTripGuaranteeBookingDetail(
            data,
            getPageData(raiseReqInfo),
            trackClickEvent,
          );
        };
        break;
  }

  return {
    title: bookingIntent,
    subTitle: bookingStatus,
    openDetails,
    iconUrl,
    iconStyle,
    status,
    payStatusText: paymentInfo.payStatusText || null,
    paymentTextLine1: paymentInfo.paymentTextLine1 || null,
    paymentTextLine2: paymentInfo.paymentTextLine2 || null,
    requestNo,
    isTatBreached: paymentInfo.isTatBreached,
    tatBreachedText: paymentInfo.tatBreachedText || null,
    tatBreachedActions: paymentInfo.tatBreachedActions || null,
  };
}

export const setRecentBkgInfo = async () => {
  recentUnsuccessfulBkg = await getDataFromStorage('RECENT_UNSUCCESSFUL_BKG');
};

export const getRecentBkgInfo = () => {
  return recentUnsuccessfulBkg;
};
