import React, { useCallback, useRef } from 'react';
import { View, Animated, Platform } from 'react-native';


const DeepLinkScrollHandler = (props) => {
    const { actIds, deeplinkAct, scrollViewRef, headerHeight = 60, delay = 200, onScrollFinish } = props;
    const scrollAnimation = useRef(new Animated.Value(0)).current;

    const scrollCard = (cardRef, delay) => {
        scrollAnimation.addListener((animation) => {
            scrollViewRef.current && scrollViewRef.current.scrollTo({
                y: animation.value,
                animated: false,
            })
        })
        setTimeout(() => {
            const measureFun = (x, y, w, h, px, py) => {
                if (y - headerHeight) {
                    Animated.timing(scrollAnimation, {
                        toValue: y - headerHeight,
                        duration: y - headerHeight,
                        useNativeDriver: true
                    }).start((result) => {
                        onScrollFinish && onScrollFinish(result);
                    });
                }
            }

            if (Platform.OS === 'android') {
                cardRef.measureInWindow(measureFun)
            } else if (Platform.OS === 'ios') {
                cardRef.measure(measureFun)
            }

        }, delay);
    }

    const handleCard = useCallback((cardRef) => {
        if (cardRef == null)
            return
        if (Array.isArray(actIds)) {
            actIds.find(act => act == deeplinkAct) && scrollCard(cardRef, delay);
        }
    }, [actIds, deeplinkAct, delay]);

    return (
        <View ref={handleCard} collapsable={false}>
            {props.children}
        </View>
    )
};

export default React.memo(DeepLinkScrollHandler);