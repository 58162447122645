export const ACTION_ID = {
    CANCEL_POLICY:"Cancel_Policy",
    DOWNLOAD_INVOICE: "DOWNLOAD_INVOICE"
}

export const CARD_ID = {
    CANCELLATION_POLICY: "CancellationPolicy",
    RAIL_CHANGE_IN_PLANS_CARD: 'ChangeInPlansCard',
}

export const BOTTOM_OVERLAY_TYPE = {
    FREE_CANCELLATION: "FreeCancellation",
    TGS: "TGS"
}
export const RAIL_ANCILLARY = {
    TG_APPLIED : "TG",
    FREE_CANCELLATION: 'FC',
    NONE: 'None'
}

export const TGS_ACTIONS = {
    TRAVEL_WITH_RAC : "travel_with_rac",
    TRAVEL_WITH_RAC_NOREFUND  : "travel_with_rac_norefund",
    CANCEL_BOOKING_TGREFUND : "cancel_booking_tgrefund",
    CANCEL_BOOKING_3X: "cancel_booking_3x"
}

export const RAIL_TG_CANCEL_SUCCESS_ACTIONS = {
    BACK_TO_TICKET : "back_to_ticket",
    GO_TO_WALLET : "go_to_wallet"
}

export const RAIL_TG_CANCEL_API = {
    PREVIEW : "preview",
    REFUND_MODE : "refund_mode",
    COMMIT : "commit"
}

export const BOOKING_STATUS = {
    CNF : "CNF",
    RAC : "RAC",
    WAITING : "WL",
    RLWL : "RLWL",
    CAN : "CAN",
    NOSB : "NOSB",
    PQWL : "PQWL"
}

export const BOOKING_TYPE = {
    UPCOMING: "Upcoming",
    COMPLETED: "Completed",
    PENDING: "Pending",
    CANCELLED: "Cancelled",
    ONTRIP: "OnTrip",
    UNCONFIRMED: "Unconfirmed",
    REFUNDED: "Refunded",

}

export const COMPONENT_ID = {
    RAIL_ITINERY: "RAIL_ITINERY",
    PAYMENT_PENDING: "PaymentPending"
}


export const REFRESH_RAIL_DELAY = "REFRESH_RAIL_DELAY";

export const CLICK_EVENT = {
    BNPP_CARD_PAY_CLICK : "BNPP_2nd_pay_MIMA",
    BNPP_VIEW_DETAIL_CLICK: "BNPP_2nd_ViewDetails",
    BNPP_OVERLAY_PAY_CLICK: "BNPP_2nd_ViewDetails_Pay"
}

export const PAGE_NAME = {
    TRIP_DETAILS: "TripDetails"
}

export const LABEL_ID = {
    BNPP_REMAINING_AMOUNT: "rail_bnpp_remaining_amount_text"
}
