/*type RouterTypes = "RNRF" | "REACT_NAV"

type RouterInfo = {
  ref: any,
  type: RouterTypes
}*/
import logAction, {logException} from '../../Helpers/actionsLogger';

const routerStack = [];

//TODO @rajeshbatth remove all the logAction once crashes are fixed
const _getTypesFromStack = () => {
  const types = routerStack.map(r => r.type).join(', ');
  return `[${types}]`
}

export const pushRouter = (type, ref) => {
  logAction("before pushRouter("+type+") stack="+_getTypesFromStack())
  routerStack.push({ref, type});
  logAction("after pushRouter("+type+") stack="+_getTypesFromStack())
};
export const popRouter = (routerType) => {
  try {
    logAction('before popRouter(' + routerType + ') stack=' + _getTypesFromStack());

    if (routerStack.length === 0) {
      throw new Error('No routers pushed');
    }
    if (routerStack[routerStack.length - 1].type !== routerType) {
      throw new Error('attempting to pop incorrect router');
    }
    routerStack.pop();
    logAction('after popRouter(' + routerType + ') stack=' + _getTypesFromStack())
  } catch (e) {
    logException(e)
  }
};

export const getCurrentRouterInfo = () => {
  logAction("getCurrentRouterInfo() stack="+_getTypesFromStack())
  if (routerStack.length === 0) {
    throw new Error('pushRouterType not called');
  }
  return routerStack[routerStack.length - 1];
};
