import myTripsNavigation from '../../../../navigation/navigation';
import { titleCase } from '@mmt/legacy-commons/Helpers/stringHelpers';
import { getStaticData } from '../../../../../staticData/staticData';
import { getMyRequestCardData, handleCTAClick } from '../../../utils';
import { CabData, CabSummaryDataProps, MsrTicket } from '../cardInterfaces';
import { cabIcon } from 'apps/post-sales/src/cab/CabImageConstants';

const getCabsSummaryData = (data: CabData, msrTicket: MsrTicket): CabSummaryDataProps => {
  const [carDetails] = data.carDetails;
  const staticData = getStaticData();
  const { ctaInfoList: ctaDetails, infoCard } = data && data.bookingStateLayout || {};
  let cabType = `${carDetails.tripType} - ${carDetails.vehicleType}`;
  const pageData = {
    fromMyTrips: true,
  };

  const openDetails = () => {
    myTripsNavigation.openCabBookingDetail(data, pageData);
  };
  const actions: Array<{ label: string; onPress: () => void }> = [];
  const paxInfo = titleCase(`${data.leadPaxFirstName} ${data.leadPaxLastName}`);
  const startsIn = data.startsIn;
  const lobName = data.lobType[0];
  const bookingId = data.bookingID;
  const { cabText, startDateText, startTimeText, distanceText, driverExpectation } = carDetails || {};
  const { text, ctaInfo } = infoCard || {};
  const infoCardStatusText = text;
  const infoCardStatusCTA = ctaInfo && ctaInfo.text;
  if (ctaDetails && ctaDetails[0]) {
    actions.push({ label: ctaDetails[0]?.text, onPress: () => handleCTAClick(ctaDetails[0], data) })
  };
  if (ctaDetails && ctaDetails[1]) {
    actions.push({ label: ctaDetails[1]?.text, onPress: () => handleCTAClick(ctaDetails[1], data) })
  };

  const handleInfoClick = () => {
    if (infoCard) {
      handleCTAClick(infoCard.ctaInfo, data);
    }
  };

  return {
    status: data.status,
    iconUrl: cabIcon,
    from: carDetails.originCityName,
    to: carDetails.destinationCityName,
    paxInfo,
    cabType: titleCase(cabType),
    actions,
    myRequestsCard: getMyRequestCardData(msrTicket),
    carName: cabText,
    startDateText,
    startsIn,
    lobName,
    bookingId,
    endDateText: distanceText,
    startTimeText,
    endTimeText: driverExpectation,
    infoCardStatusText,
    infoCardStatusCTA,
    openDetails,
    handleInfoPress: handleInfoClick
  };
}

export default getCabsSummaryData;