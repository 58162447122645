import React from 'react';
import PropTypes from 'prop-types';
import { Text, View } from 'react-native';
import createStyles from './styles';
import { useTheme } from '../../../../../../../theme/theme.context';
import { getFont } from '../../../../../../../PostSalesStyles';

const InfoListCard = (props) => {
  const {psTheme: theme} = useTheme();
  const fonts = getFont(true);
  const styles = createStyles(theme, fonts);
  const lstVw = [];
  props.data.forEach((str, index) => {
    lstVw.push(
      <View key={index} style={styles.dottedListItem}>
        <View style={styles.dottedListDot} />
        <Text style={styles.dottedListText}>{str}</Text>
      </View>,
    );
  });

  return (
    <View style={styles.whiteCard}>
      <Text style={styles.cardTitle}>{props.header}</Text>
      <View style={styles.dottedList}>{lstVw}</View>
    </View>
  );
};
InfoListCard.propTypes = {
  data: PropTypes.array.isRequired,
  header: PropTypes.string.isRequired,
};

export default InfoListCard;
