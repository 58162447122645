import React from 'react';
import {View, Text, Image} from 'react-native';
import {icCotraveller} from '../BusImageConstants';
import PropTypes from 'prop-types';
import getStyles from '../styles/BusMamiCss';

class BusCancellationInfoStrip extends React.Component {
    static navigationOptions = {
      header: null
    };

    constructor(props) {
      super(props);
      this.busBookingInfo = this.props.response.busBookingInfo;
      this.bookingPaymentDetail = this.props.response.bookingPaymentDetail;
      this.penalty = this.props.penalty;
      this.styles = getStyles();
    }

    render() {
      const stage = this.props.modificationState;
      const styles = this.styles;
      return (
        <View style={[styles.MamiCard, styles.mar10Bt]}>
          <View style={styles.GuestInfoStrip}>
            <Text style={stage === 'ViewRefund' ? [styles.boldFont, styles.DefaultText] : styles.regularFont}>1 View Refund Amount</Text>
            <Text style={stage === 'Confirm' ? [styles.boldFont, styles.DefaultText] : styles.regularFont}>2 Confirm Cancellation</Text>
          </View>
          <View style={[styles.MamiCard]}>
            <View style={[styles.borderbtm, styles.bggreyLight]}>
              <View style={styles.RoomCancelledWrapper}>
                <View style={[styles.flexRow, styles.RoomCancelled]}>
                  <Text style={[styles.BlackText, styles.regularFont, styles.mar3Bt]}>{this.busBookingInfo.busDetail.operator} </Text>
                  <Text style={[styles.errorRedTxt, styles.regularFont]}>Cancelling all seats </Text>
                </View>
                <View style={[styles.flexRow, styles.alignCenter]}>
                  <Image style={styles.adultIcon} source={icCotraveller} />
                  <Text style={styles.regularFont}>  {this.busBookingInfo.noOfPassengers} Travellers </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      );
    }
}

BusCancellationInfoStrip.propTypes =
  {
    modificationState: PropTypes.string.isRequired,
    response: PropTypes.object.isRequired,
    penalty: PropTypes.object.isRequired
  };

export default BusCancellationInfoStrip;
