import { StyleSheet } from "react-native";
export default (theme, fonts) =>StyleSheet.create({
    helpCard :{
        backgroundColor:theme.color.white,
        marginLeft : 10,
        marginRight:10,
        padding : 5,
        paddingBottom :10,
        borderColor : theme.color.white,
       borderWidth:1,
       borderRadius:5
    },
    heading :{
        textAlign:'center',
        borderBottomWidth: 1,
        borderBottomColor: theme.color.lightGrey,
       marginBottom : 10,
      width : '95%',
      alignSelf : 'center'
    },
    serviceIconWrapper:{
        width:48,
        height:48,
        alignItems:'center',
        justifyContent:'center',
        backgroundColor:theme.color.smokeWhite,
        borderRadius:25,
    },
    infoText:{
        color: theme.color.blue,
        fontSize:15,
       ...(fonts.regularFontFamily),
        marginTop:10,
        textAlign:'center'
    },
    helpIconStyle:{
        width:28,
        height:28,
    }
})


