import { StyleSheet } from 'react-native';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
const styles = StyleSheet.create({
  scrollViewContainer: {
    backgroundColor: colors.grey21,
  },
  scrollWrapper: {
    backgroundColor: colors.grey21,
  },
  segmentCard: {
    paddingTop: 12,
    backgroundColor: colors.white,
    marginBottom: 12,
  },
  headingStyle: {
    borderBottomColor: colors.grey21,
    borderBottomWidth: 1,
    paddingBottom: 10,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.white,
  },
  leftStroke: {
    width: 4,
    height: 20,
    backgroundColor: colors.azure,
  },
  arrowWrapper: {
    alignItems: 'center',
  },
  journeyTypeWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  lineHeight8: {
    lineHeight: 8,
  },
  lineHeight22: {
    lineHeight: 22,
  },
  tripBgWrapper: {
    flexDirection: 'column',
    height: 76,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
  },
  topStatusCard: {
    paddingHorizontal: 12,
    paddingVertical: 16,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    overflow: 'hidden',
    flex: 1,
    justifyContent: 'center',
  },
});

export default styles;
