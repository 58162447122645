import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import Actions from '../../../../navigation/postSalesNavigation';
import SelectRefundAncillary from './SelectRefundAncillary';
import ReviewAncillaryRefund from './ReviewAncillaryRefund';
import AncillaryRefundMode from './AncillaryRefundMode';
import styles from './ClaimAncillaryRefundCss';
import { getStaticData } from '../../../../staticData/staticData';
import { CLAIM_ANCILLARY } from '../../../../utils/NetworkUtils';
import AncillaryClaimHeader from './AncillaryClaimHeader';
import AncillaryClaimProcess from './AncillaryClaimProcess';
import useHardwareBackpress from '../../../../Common/useHardwareBackpress';
import useApi from '../../../../Common/useApi';
import { isNullOrEmptyObject } from '../../../../Common/commonUtil';
import SpecialClaimTrackingHelper from '../../Helpers/SpecialClaimTrackingHelper';

const STEPS = {
  SELECT_ANCILLARY: 'SELECT ADD-ONS',
  UPOAD_PROOF: 'UPLOAD PROOF',
  REFUND_MODE: 'REFUND MODE',
};

const ClaimAncillaryRefund = ({ bookingId, scCode, lobCode, flightResponse }) => {
  const [activeStep, setActiveStep] = useState(STEPS.SELECT_ANCILLARY);
  const [selectedData, setSelectedData] = useState({});
  const [loaderMsg, setLoaderMsg] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState(null);
  const [reviewData, setReviewData] = useState(null);
  const { loadingText } = getStaticData();
  const [claimReqInProgress, ancillaryClaimData, claimApi] = useApi(
    `${CLAIM_ANCILLARY}${bookingId}`,
  );
  const pageName = 'AncillaryClaimAddon';

  const onBackPress = useCallback(() => {
    switch (activeStep) {
      case STEPS.SELECT_ANCILLARY:
        Actions.pop();
        break;
      case STEPS.UPOAD_PROOF:
        setActiveStep(STEPS.SELECT_ANCILLARY);
        break;
      case STEPS.REFUND_MODE:
        setActiveStep(STEPS.UPOAD_PROOF);
        break;
      default:
        break;
    }
  }, [activeStep]);

  useHardwareBackpress().addListener(onBackPress);

  useEffect(() => {
    SpecialClaimTrackingHelper.trackAncillaryClaimLoadEvent(pageName, flightResponse, { scCode });
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingId]);

  const loadData = () => {
    claimApi.httpGet({
      bookingId,
      uniqueId: 'FLIGHT_CLAIM_ANCILLARY',
    });
  };

  const onContinue = useCallback((data) => {
    SpecialClaimTrackingHelper.trackClickEvent(pageName, 'claim_proceed');
    setActiveStep(STEPS.UPOAD_PROOF);
    setSelectedData(data);
    setReviewData(null);
  }, []);

  const onReviewDetails = useCallback((fileUrls) => {
    setUploadedFiles(fileUrls);
    setActiveStep(STEPS.REFUND_MODE);
  }, []);

  const requestData = useMemo(
    () => ({
      bookingId,
      lobCode,
      claimReason: scCode,
    }),
    [bookingId, lobCode, scCode],
  );

  const showLoader = useCallback(
    (msg = loadingText) => {
      setLoaderMsg(msg);
    },
    [loadingText],
  );

  const hideLoader = useCallback(() => {
    setLoaderMsg(null);
  }, []);

  const displayContent = () => {
    const headerComp = headerComponent();
    const commonProps = {
      loaderMsg,
      headerComp,
      bookingId,
      selectedData,
      scCode,
      flightResponse,
    };
    switch (activeStep) {
      case STEPS.SELECT_ANCILLARY:
        return (
          <SelectRefundAncillary
            {...{
              ...commonProps,
              onContinue,
              cards,
              imgMap,
              multiCardSelection,
              actionLists,
            }}
          />
        );
      case STEPS.UPOAD_PROOF:
        return (
          <ReviewAncillaryRefund
            {...{
              ...commonProps,
              showLoader,
              hideLoader,
              onReviewDetails,
              requestData,
              reviewData,
              setReviewData,
            }}
          />
        );
      case STEPS.REFUND_MODE:
        return (
          <AncillaryRefundMode
            {...{
              ...commonProps,
              requestData,
              uploadedFiles,
              reviewData,
            }}
          />
        );
      default:
        return null;
    }
  };

  const { title, subtitle, cards, imgMap, multiCardSelection, actionLists } =
    ancillaryClaimData || {};

  const headerComponent = () => {
    const steps = Object.values(STEPS);
    return (
      <AncillaryClaimHeader
        {...{
          title,
          subtitle,
          onBackPress,
          activeStep: steps.indexOf(activeStep),
          steps,
        }}
      />
    );
  };

  return (
    <AncillaryClaimProcess
      {...{
        loading: loaderMsg || claimReqInProgress,
        loaderMsg,
        error: ancillaryClaimData
          ? ancillaryClaimData.error || !isNullOrEmptyObject(ancillaryClaimData.buinsessError)
          : null,
        onRetry: loadData,
        pageName,
      }}
    >
      <View style={styles.pageWrapper}>{displayContent()}</View>
    </AncillaryClaimProcess>
  );
};

export default ClaimAncillaryRefund;
