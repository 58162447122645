import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
const styles ={
    
    buttonBottomWrapper: { 
        position: 'absolute', 
        bottom: 12, 
        paddingHorizontal: 12, 
        width: '100%' 
    },
    headerSec:{
        paddingHorizontal:12,
        paddingTop:16,
        marginBottom:12
    },
    headingIcon:{
        width:24,
        height:24,
        marginRight:10,
        backgroundColor:"#f1f1f1"
    },
    docList:{
        paddingHorizontal:12,
        paddingVertical:16,
        backgroundColor:'#f8f8f8',
        marginHorizontal:12,
        marginBottom:12,
        borderRadius:16
    },
    bullet:{
        width: 6,
        height: 6,
        marginRight:10,
        marginTop:6,
        backgroundColor: '#4a4a4a',
        borderRadius:50,
      },
    insHeading:{
        borderLeftWidth:4,
        borderLeftColor:colors.azure,
        paddingLeft:16,
        flexDirection:'row',
        alignItems:'center'
    },
    accordianIcon:{
        width:32,
        height:32,
        marginHorizontal:4
    },
    policyNum:{
        marginLeft:28,
        marginTop:20,
        marginBottom:4,
    },
    policyDetail:{
        marginLeft:20,
        marginTop:16,
        marginBottom:12,        
    }
}
export default styles;