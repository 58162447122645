import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';

const styles = {
  infoCardWrapper: {
    backgroundColor: '#fff',
    justifyContent: 'space-between',
    flexDirection: 'row',
    ...getPlatformElevation(1),
    marginBottom: 1,
  },

  infoCardContent: {
    paddingLeft: 8,
    paddingVertical: 15,
    flex: 1,
  },

  infoCustom: {
    fontSize: 14,
    color: '#4a4a4a',
    lineHeight: 18,
  },

  headingIconWrapper: {
    width: 46,
    alignItems: 'flex-start',
    top: 14,
    left: 16,
  },
  headingIconStyle: {
    width: 21,
    height: 20,
  },

  rightArrowIcon: {
    width: 9,
    height: 13,
    right: 20,
  },

  linkBtnTxt: {
    fontSize: 14,
    color: '#008cff',
  },

  IconStyle: {
    width: 32,
    height: 32,
    marginRight: 4,
  },
  bagIcon2Style: {
    width: 16,
    height: 15,
    marginRight: 15,
    marginLeft: 3,
  },
  dtlsRow: {
    paddingBottom: 10,
  },
  progressText: {
    color: '#cf8100',
    marginTop: 3,
  },
  failedText: {
    color: '#eb2026',
    marginTop: 3,
  },
  callIconStyle: {
    width: 32,
    height: 32,
    marginRight: 10,
  },
  line: {
    borderBottomColor: '#e7e7e7',
    borderBottomWidth: 1,
    marginTop: 12,
  },
  footerText: {
    marginTop: 15,
    marginRight: 16,
  },
  dottedLine: {
    height: 1,
    borderRadius: 1,
    borderWidth: 1,
    borderColor: '#e7e7e7',
    borderStyle: 'dashed',
    marginTop: 20,
    marginRight: 6,
  },
};

export default styles;
