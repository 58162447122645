import { Platform } from 'react-native';
import {getDataFromStorage} from '@mmt/legacy-commons/AppState/LocalStorage';
import { LOBS } from '@mmt/legacy-commons/Common/constants/AppConstants';
import {SEARCH_CONTEXT} from './RailsConfig'
import {getCommonHeaders} from '@mmt/legacy-commons/Helpers/genericHelper';
import { getMWebHeaders } from './Utils/mwebHeaders';
import { getRailsLanguageByKey } from './vernacular/AppLanguage';
import { getPageNameAndEntityNameOfApi, getSessionID, syncSessionID } from './Utils/CommonUtils';
import isEmpty from 'lodash/isEmpty';
import { openBlockScreenIfInGDPRRegion } from '@mmt/legacy-commons/Common/utils/AppUtils';
import { LIVE_STATUS_URL, railsTGSHeaderUrl } from './pages/RIS/LiveTrainStatus/constants';
import {
  COMMON_LATENCY,
  getDataFromAsynStorage,
  ITENERARY_DATA,
  ITENERARY_ID,
} from './Utils/RailsConstant';
import RailsEnvVariables from 'apps/rails/src/Utils/RailsEnvVariables';
import { getIfCanEnableEnvSwitch } from './RailsAbConfig';
import { logGrafanaLatencyMetrics } from './Utils/RailsGrafanaTracker';
import { ENTITY_TYPE } from './types/grafanaTracking.types';

let interceptors = [];
const railsHeaderUrl = 'rails.makemytrip.com';
const railwaysHeaderUrl = 'railways.makemytrip.com';

export const getLanguageHeaders = () => {
  return {'rails-lang-code': getRailsLanguageByKey("beCode")}
};

const baseHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
};

let commonHeader = {};

export const initialiseCommonHeaders = async () => {
  commonHeader = await getCommonHeaders(baseHeaders);
};

function interceptor(fetch, ...args) {
  const startTime = performance.now();
  const reversedInterceptors = interceptors.reduce((array, interceptor) => [interceptor].concat(array), []);
  let promise = Promise.resolve(args);

  // Register request interceptors
  reversedInterceptors.forEach(({request, requestError}) => {
    if (request || requestError) {
      promise = promise.then(args => request(...args), requestError);
    }
  });

  // Register fetch call
  promise = promise.then(async (newArgs) => {
    const [url, headersObject = {}, ...rest] = newArgs;

    const { pageName, entityName } = getPageNameAndEntityNameOfApi(url);

    let interceptedUrl = url;
    const canChangeBaseUrl = RailsEnvVariables.getCanChangeBaseUrl() && getIfCanEnableEnvSwitch();
    if (canChangeBaseUrl && interceptedUrl.includes('pwa/mobile')) {
      interceptedUrl = `${RailsEnvVariables.getPWABaseUrl()}/pwa/mobile${url
        .split('/pwa/mobile')
        .pop()}`;
    } else if (
      canChangeBaseUrl &&
      !interceptedUrl.includes('pwa/mobile') &&
      !interceptedUrl.includes('/api/ris/') &&
      !interceptedUrl.includes('/api/rails/') &&
      interceptedUrl.includes('/api')
    ) {
      interceptedUrl = `${RailsEnvVariables.getNodeBaseUrl()}/api${url.split('/api').pop()}`;
    }
    let editedHeadersObject;
    const oldHeaders = headersObject ? headersObject.headers : null;
    if (url.includes(railsHeaderUrl) || url.includes(LIVE_STATUS_URL) || url.includes(railsTGSHeaderUrl) || url.includes(railwaysHeaderUrl)) {
      if (isEmpty(commonHeader)) {
        await initialiseCommonHeaders();
      }
      await syncSessionID(commonHeader);
      const sessionId = getSessionID();
      editedHeadersObject = {
        ...headersObject,
        headers: {
          ...commonHeader,
          ...getLanguageHeaders(),
          ...oldHeaders,
          'session_id': sessionId,
        }
      };
      const searchContext = await getDataFromStorage(SEARCH_CONTEXT);
      const iteneraryData = await getDataFromAsynStorage(ITENERARY_DATA);
      if (searchContext) {
        editedHeadersObject['headers'][SEARCH_CONTEXT] = JSON.stringify(searchContext);
      }
      if(iteneraryData)
      {
        editedHeadersObject['headers'][ITENERARY_ID] = JSON.stringify(iteneraryData[ITENERARY_ID]);
      }
    } else {
      editedHeadersObject = headersObject;
    }

    if (Platform.OS === 'web') {
      editedHeadersObject = {
        ...editedHeadersObject,
        headers: {
          ...editedHeadersObject.headers,
          ...getMWebHeaders()
        },
      };
    }
    const fetchArgs = [interceptedUrl, editedHeadersObject, ...rest];
    const response = await fetch(...fetchArgs);
    if (response.status === 403 && response?.headers?.map?.isgdpr === 'true') {
      await openBlockScreenIfInGDPRRegion(LOBS.TRAIN, true, LOBS.TRAIN);
    }

    const latency = performance.now() - startTime;
    logGrafanaLatencyMetrics(pageName, ENTITY_TYPE.API, entityName, COMMON_LATENCY.TOTAL, latency);

    return response;
  });

  // Register response interceptors
  reversedInterceptors.forEach(({response, responseError}) => {
    if (response || responseError) {
      promise = promise.then(response, responseError);
    }
  });
  return promise;
}

export const addInterceptor = (interceptor) => {
  interceptors.push(interceptor);
  initialiseCommonHeaders();
  return () => {
    const index = interceptors.indexOf(interceptor);
    if (index >= 0) {
      interceptors.splice(index, 1);
    }
  };
};

export const clearInterceptors = () => {
  interceptors = [];
};

export const Timeout = (time) => {
  const controller = new AbortController();
  setTimeout(() => controller.abort(), time * 1000);
  return controller;
};

const fetch2 = (...args) => interceptor(fetch, ...args);

export default fetch2;
