import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';
import { Platform, StyleSheet } from 'react-native';

export const getStyles = (fonts, theme) => {
  const { color } = theme;
  return StyleSheet.create({
    overlayWrapperStyle: {
      top: -90,
    },
    OverlayContentWrapper: {
      backgroundColor: color.white,
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      paddingBottom: 10,
    },
    arrowstyle: {
      width: 20,
      height: 20,
      top: 12,
    },
    collapsableWrap: {
      padding: 15,
    },
    section: {
      backgroundColor: color.white,
      marginBottom: 10,
      ...getPlatformElevation(Platform.select({ ios: 2, android: 3 })),
    },
    flex1: {
      flex: 1,
    },
    arrowStyle: {
      width: 12,
      height: 12,
    },
    cabImage: {
      width: 80,
      height: 40,
      marginRight: 15,
      resizeMode: 'contain',
    },
    carsTitle: {
      fontSize: fonts.fontSize.font18,
      ...fonts.boldFontFamily,
      color: color.black,
      paddingHorizontal: 10,
      marginBottom: 12,
    },
    carOptions: {
      marginTop: 25,
    },
    cabRow: {
      paddingHorizontal: 12,
      paddingVertical: 18,
      backgroundColor: color.white,
      borderBottomWidth: 1,
      borderBottomColor: color.lightBlue12,
    },
    cabInfo: {
      flex: 1,
    },
    cabBasicInfo: {
      flexDirection: 'row',
      marginBottom: 10,
    },
    scrollViewContent: {
      paddingVertical: 12,
    },
    cabName: {
      ...fonts.boldFontFamily,
      color: color.defaultTextColor,
      fontSize: fonts.fontSize.font14,
    },
    textSm: {
      ...fonts.boldFontFamily,
      color: color.lightTextColor,
      fontSize: fonts.fontSize.font12,
    },
    textXs: {
      ...fonts.regularFontFamily,
      color: color.lightTextColor,
      fontSize: fonts.fontSize.font10,
    },
    textMd: {
      ...fonts.regularFontFamily,
      color: color.lightTextColor,
      fontSize: fonts.fontSize.font12,
      marginRight: 15,
    },
    textBold: {
      ...fonts.blackFontFamily,
      color: color.black,
      fontSize: fonts.fontSize.font12,
    },
    cabPriceWrapper: {
      ...fonts.blackFontFamily,
      color: color.black,
      fontSize: fonts.fontSize.font18,
      textAlign: 'center',
      paddingLeft: 5,
      flexDirection: 'row',
    },
    bookingAmountText: {
      ...fonts.regularFontFamily,
      color: color.greyText1,
      fontSize: fonts.fontSize.font12,
    },

    paymentText: {
      ...fonts.italicFontFamily,
      color: color.blue15,
      fontSize: fonts.fontSize.font12,
    },
    paymentValueText: {
      ...fonts.boldFontFamily,
      color: color.black,
      fontSize: fonts.fontSize.font16,
    },
    bookingAmountValuecabPrice: {
      ...fonts.blackFontFamily,
      color: color.black,
      fontSize: fonts.fontSize.font18,
      textAlign: 'center',
    },
    textYellow: {
      color: color.lightYello,
    },
    textActive: {
      color: color.green,
    },
    textDisabled: {
      color: color.lightTextColor,
    },
    leftSection: {
      flexDirection: 'row',
      flex: 1,
      marginRight: 20,
    },

    CapsuleBtnFill: {
      paddingBottom: 12,
      paddingTop: 12,
      paddingLeft: 10,
      paddingRight: 10,
      borderRadius: 50,
      marginRight: 10,
      marginLeft: 10,
    },
    PositionAbsolute: {
      position: 'absolute',
    },
    thankYouWrapper: {
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: color.white,
      paddingHorizontal: '10%',
    },
    successIcon: {
      width: 40,
      height: 40,
      marginRight: 15,
      marginBottom: 10,
      alignSelf: 'center',
    },
    messageTitle: {
      fontSize: fonts.fontSize.font20,
      textAlign: 'center',
      lineHeight: 25,
      color: color.black,
      ...fonts.boldFontFamily,
      marginBottom: 27,
    },
    messageSubtitle: {
      fontSize: fonts.fontSize.font15,
      textAlign: 'center',
      lineHeight: 25,
      color: color.black,
      ...fonts.regularFontFamily,
      marginBottom: 27,
    },
    CommonFillBtn: {
      width: '100%',
    },
    transparantBgrnd: {
      ...Platform.select({
        ios: {
          backgroundColor: 'transparent',
        },
      }),
    },
    CommonFillBtnGradient: {
      borderRadius: 25,
      paddingVertical: 11,
      paddingHorizontal: 25,
      marginTop: 24,
    },
    CommonFillBtnText: {
      color: color.white,
      alignSelf: 'center',
      ...fonts.blackFontFamily,
    },
    tagInfo: {
      backgroundColor: color.greyBookedSeat,
      borderRadius: 4,
      alignItems: 'center',
      flexDirection: 'row',
      width: 120,
      paddingLeft: 7,
      height: 20,
      marginBottom: 15,
    },
    currentBookingTxt: {
      fontSize: fonts.fontSize.font10,
      ...fonts.boldFontFamily,
      color: color.darkGrey2,
    },
    cabListGrey: {
      backgroundColor: color.greyBookedSeat,
    },
    cabListWhite: {
      backgroundColor: color.white,
    },
    borderArrow: {
      borderBottomWidth: 10,
      borderBottomColor: color.transparent,
      borderLeftWidth: 0,
      borderLeftColor: color.transparent,
      borderRightWidth: 10,
      borderRightColor: color.white,
      borderTopWidth: 10,
      borderTopColor: color.transparent,
      height: 0,
      width: 0,
      right: 0,
      position: 'absolute',
    },
    cancellationText: {
      display: 'flex',
      width: '85%',
      marginLeft: 5,
    },
    subCabPad: {
      paddingVertical: 12,
    },
    persuationTxt: {
      marginLeft: 5,
      color: color.green,
      fontSize: fonts.fontSize.font13,
      ...fonts.boldFontFamily,
    },
    tickImageStyle: {
      width: 20,
      height: 16,
    },
    dot: {
      backgroundColor: color.black,
      width: 6,
      height: 6,
      borderRadius: 10,
      marginRight: 6,
    },
    dotList: {
      ...fonts.regularFontFamily,
      color: color.defaultTextColor,
      fontSize: fonts.fontSize.font13,
    },
    amenListWrapper: {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 5,
      marginHorizontal: 10,
    },

    paymentStatusCard: {
      width: 75,
      borderRadius: 6,
      paddingBottom: 5,
    },
    paystatusTitle: {
      paddingVertical: 3,
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
    },
    paymentStatusCardyellow: {
      backgroundColor: color.creamWhite,
    },
    paymentStatusCardgreen: {
      backgroundColor: color.limeGreen,
    },
    paymentStatusCardPaid: {
      backgroundColor: color.grayBg,
    },
    toggleBtnWrapper: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      borderTopWidth: 1,
      borderTopColor: color.grey8,
      padding: 10,
      marginHorizontal: 10,
      marginTop: 15,
    },
    divider: {
      borderTopWidth: 1,
      borderTopColor: color.grey8,
    },
    bookingAmountValue: {
      ...fonts.boldFontFamily,
      color: color.black,
      fontSize: fonts.fontSize.font18,
    },
    activeArrow: {
      transform: [{ rotate: '180deg' }],
    },
    wrapper: {
      display: 'flex',
    },
    centeredView: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: color.black,
      opacity: 0.85,
    },
    modalView: {
      margin: 20,
      backgroundColor: color.white,
      borderRadius: 20,
      padding: 35,
      alignItems: 'center',
      shadowColor: color.black,
      ...getPlatformElevation(Platform.select({ ios: 2, android: 3 })),
      elevation: 5,
    },
    button: {
      borderRadius: 8,
      padding: 10,
      elevation: 2,
    },
    buttonClose: {
      backgroundColor: color.lightCyan,
    },
    textStyle: {
      color: color.white,
      ...fonts.boldFontFamily,
      textAlign: 'center',
    },
    modalText: {
      marginBottom: 15,
      textAlign: 'center',
    },
    positiveButton: {
      width: 150,
      paddingTop: 10,
      marginRight: -10,
    },
    paymentBtmSheetWrapper:{
      padding:16,
      top: -90,
    },
  });

};
