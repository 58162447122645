import React from 'react';
import { View, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import { statusBarHeightForIphone } from '../../Styles/globalStyles';

const CustomSafeAreaView = ({ bgColor, positionTop }) => (
  <View
    style={[
      positionTop ? styles.iosSafeAreaViewTop : styles.iosSafeAreaViewBottom,
      { backgroundColor: bgColor },
    ]}
  />
);

const styles = StyleSheet.create({
  iosSafeAreaViewTop: {
    marginTop: -statusBarHeightForIphone,
    height: statusBarHeightForIphone,
    backgroundColor: '#fff',
    zIndex: 100000,
  },
  iosSafeAreaViewBottom: {
    marginBottom: -statusBarHeightForIphone,
    height: statusBarHeightForIphone,
    backgroundColor: '#fff',
  },
});

CustomSafeAreaView.propTypes = {
  bgColor: PropTypes.string,
  positionTop: PropTypes.bool,
};

CustomSafeAreaView.defaultProps = {
  bgColor: '#fff',
  positionTop: true,
};

export default CustomSafeAreaView;
