import React from 'react';
import { StyleSheet, View, Text, ActivityIndicator } from 'react-native';
import { colors, fonts } from '@mmt/legacy-commons/Styles/globalStyles';
import isEmpty from 'lodash/isEmpty';
import { themeColors, getFont } from '../../PostSalesStyles';
import SpinnerLoaderWpr from '../SpinnerLoaderWpr';

export default class ProgressView extends React.Component {

  render() {
    const { fsStyle,...fonts }  = getFont(true);
    const styleByProps = this.props.style ? this.props.style : {};
    return (
        <View style={[styles.progressContainer, styleByProps]}>
          <SpinnerLoaderWpr size={30}/>
          {!isEmpty(this.props.message) &&
              <Text style={[styles.textDark, {marginTop: 8}, fsStyle.font16, fonts.boldFontFamily]}>
                {this.props.message}
              </Text>
          }
        </View>
    );
  }
}

export class Loader extends React.Component {
  render() {
    const styleByProps = this.props.style ? this.props.style : {};
    return (
      <View style={[styles.progressContainer, styleByProps]}>
        <ActivityIndicator styleAttr="Inverse" style={styles.indicator} color={themeColors.loaderColor} size="large"/>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  progressContainer: {
    flex: 1,
    width: '100%',
    height: '100%',
    backgroundColor: '#ffffffd9',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textDark: {
    color: colors.black,
    fontSize: 16,
    fontFamily: fonts.bold,
    textAlign: 'center',
  },
});
