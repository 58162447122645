import { StyleSheet, Platform } from "react-native";

export default (theme, fonts) =>
  StyleSheet.create({
    fieldBox: {
      flexDirection: 'row',
      position: 'relative',
      zIndex: 2,
    },
    rightPanel: {
      flex: 1,
    },
    textWrapper: {
      borderBottomColor: theme.color.grey12,
      borderBottomWidth: 1,
      paddingTop: 10,
      paddingBottom: 5,
      width: '100%',
      marginBottom: 15,
    },
    codeWrapper: {
      borderBottomColor: theme.color.grey12,
      borderBottomWidth: 1,
      paddingBottom: 10,
      height: 35,
    },
    uppertext: {
      textTransform: 'uppercase',
    },
    iconWrapper: {
      width: 16,
      height: 16,
      marginRight: 10,
    },
    userIcon: {
      width: 17,
      height: 17,
      resizeMode: 'contain',
      overflow: 'visible'
    },
    errorState:{
      borderBottomColor: theme.color.red,
      color:theme.color.red,
      ...fonts.boldFontFamily,
    },
    inputContainerStyle :{
      paddingHorizontal:0,
      ...Platform.select({
        web: {
         marginBottom: 16,
         marginTop: 0,
        }
      })
    }
  });
