import React from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import getTripIdeasCardData from './TripIdeasCardAdapter';
import styles from './styles';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import TripSummaryOmnitureTracker from '../../../tracking/TripSummaryOmnitureTracker';
import { isEmpty } from 'lodash';
import LinearGradient from 'react-native-linear-gradient';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import rtArrow from '@mmt/legacy-assets/src/ic_arrow.webp';
import { getFont } from '../../../../PostSalesStyles';

const TripIdeasCard = ({ response, cardStyle = [] }) => {
  const { fsStyle, ...fonts } = getFont(true);
  const { title, titleColors, text, openDetails, images } = getTripIdeasCardData(response) || {};
  const openDetailsHandler = () => {
    openDetails();
    TripSummaryOmnitureTracker.getTripIdeasClickedTrackedEvent(response);
  };

  return (
    <View style={[styles.exploreTripCardWrapper, AtomicCss.whiteBg, ...cardStyle]}>
      <LinearGradient
        start={{ x: 0.0, y: 0.0 }}
        end={{ x: 1.0, y: 0.0 }}
        colors={[colors.orangeLight, colors.white]}
      >
        <TouchableOpacity
          style={[
            AtomicCss.flexRow,
            AtomicCss.spaceBetween,
            AtomicCss.alignCenter,
            AtomicCss.paddingAll16,
          ]}
          onPress={openDetailsHandler}
        >
          <View style={[AtomicCss.flex1, AtomicCss.flexRow]}>
            {!isEmpty(images) && (
              <View style={styles.imgWrapper}>
                {!isEmpty(images[0]) && (
                  <Image style={styles.imgStyle} source={{ uri: images[0] }} />
                )}
                {!isEmpty(images[1]) && (
                  <Image style={styles.imgStyleStack} source={{ uri: images[1] }} />
                )}
                {!isEmpty(images[2]) && (
                  <Image style={styles.imgStyleStackleft} source={{ uri: images[2] }} />
                )}
              </View>
            )}
            <View style={AtomicCss.flex1}>
              <View>
                <Text style={[styles.linkTxt, fonts.blackFontFamily, fsStyle.font16]}>
                  {!isEmpty(title) &&
                    title.map((title, index) => (
                      <Text
                        numberOfLines={2}
                        style={!isEmpty(titleColors) ? { color: titleColors[index] } : {}}
                      >
                        {title}{' '}
                      </Text>
                    ))}
                </Text>
                {!isEmpty(text) && (
                  <Text numberOfLines={2} style={[styles.innerDesc, fsStyle.font12]}>
                    {text}
                  </Text>
                )}
              </View>
            </View>
            <Image
              style={[styles.arrowStyle, AtomicCss.marginTop5, AtomicCss.marginLeft20]}
              source={rtArrow}
            />
          </View>
        </TouchableOpacity>
      </LinearGradient>
    </View>
  );
};

export default TripIdeasCard;
