import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from './GetPlatformElevationCss';

const styles = {
  contentTitle: {
    color: colors.defaultTextColor,
    marginBottom: 18,
  },
  contentDesc: {
    paddingTop: 3,
    color: colors.defaultTextColor,
  },
  contentFooter: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  actionBtn: {
    paddingVertical: 10,
  },
  actionBtnEnabledText: {
    color: colors.azure,
  },
  actionBtnDisabledText: {
    color: colors.grey,
  },
  checkboxRow: {
    flexWrap: 'wrap',
    marginBottom: 5,
  },
  agreementList: {
    ...getPlatformElevation(2),
    backgroundColor: colors.white,
    padding: 15,
    paddingBottom: 5,
    marginBottom: 10,
    borderRadius: 4,
  },
  checkbox: {
    height: 18,
    width: 18,
    backgroundColor: colors.white,
    borderColor: colors.disabledBtnBg,
    borderWidth: 1.5,
    borderRadius: 3,
    overflow: 'hidden',
    position: 'relative',
    marginRight: 12,
    marginTop: 5,
  },
  checkboxSelected: {
    borderColor: 'transparent',
    borderWidth: 0,
  },
  checkBtn: {
    flexDirection: 'row',
    paddingVertical: 10,
    paddingHorizontal: 3,
    alignItems: 'center',
  },
  checkedIcon: {
    position: 'absolute',
    height: 29,
    width: 29,
    left: -5,
    top: -4,
  },
  flex1: {
    flex: 1,
  },
  contentWidth: {
    width: 220,
  },
};

export default styles;
