import { StyleSheet } from 'react-native';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';

const getStyles = (fonts) => {
  return StyleSheet.create({
    overlayTopline: {
      width: 40,
      height: 4,
      borderRadius: 4,
      marginTop: 5,
      backgroundColor: colors.disabledButton,
      alignSelf: 'center',
    },
    title: {
      color: colors.black,
      ...fonts.font24,
      fontFamily: fonts.black,
      lineHeight: 32,
      paddingHorizontal: 10,
    },
    buttonBottomWrapper: {
      marginTop: 24,
      marginHorizontal: -16,
      width: '100%',
      padding: 10,
    },
    paymentCard: {
      borderRadius: 4,
      marginTop: 24,
    },
    topDetails: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    radio: {
      width: 18,
      height: 18,
      backgroundColor: colors.grayBg,
      borderRadius: 20,
      borderWidth: 1,
      borderColor: colors.purpleyGrey,
      marginRight: 10,
      marginTop: 2,
    },
    activeBorder: {
      borderColor: colors.azure,
    },
    radioInside: {
      width: 12,
      height: 12,
      backgroundColor: colors.azure,
      borderRadius: 20,
      marginLeft: 2,
      marginTop: 2,
    },
    radioSection: {
      padding: 19,
      backgroundColor: colors.lightGrey6,
      borderRadius: 4,
    },
    additionalSection: {
      paddingLeft: 28,
      marginTop: 4,
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    radioSectionActive: {
      backgroundColor: colors.lighterBlue,
      borderBottomColor: colors.lightBlue14,
    },
    radioActive: {
      borderColor: colors.azure,
    },
    extraText: {
      padding: 8,
    },
  });
}

export default getStyles;
