import React from 'react';
import { View, Image, Text, TouchableOpacity } from 'react-native';
import styles from './VerifiedStayCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import Actions from '../../../../navigation/postSalesNavigation';
import { BLUE_RIGHT_ARROW_ICON, VERIFIED_STAYS_ICON, WHITE_LEFT_ARROW_ICON } from '../../../HotelImageConstants';
import {getImageTransformStyling} from '../../../../utils/PostSaleUtil';
import HotelBookingTrackingHelper from '../../../HotelBookingTrackingHelper';
import { getStaticData } from '../../../../staticData/staticData';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const VerifiedStaysCard = ({response, pageName}) => {
  const { fsStyle, ...fonts } = getFont(true);
  const staticData = getStaticData();

  const onClick =() => {
    const clickEvent = 'MI_HTL_VerifiedStays';
    HotelBookingTrackingHelper.trackClickEvent(pageName, clickEvent, response);
    Actions.openPsWebView({
      url: response.mbgTnCURL,
      headerText: staticData.termsAndConditions,
      headerIcon: WHITE_LEFT_ARROW_ICON
    });
  }
  
  return (
    <TouchableOpacity style={[styles.verifiedStaysCard]} onPress={() => onClick()}>
      <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.flex1]}>
        <Image style={styles.verifiedStays} source={VERIFIED_STAYS_ICON} />
        <View style={[AtomicCss.flex1, AtomicCss.flexWrap, AtomicCss.flexRow]}>
          <Text style={[styles.greenTextColor, fonts.blackFontFamily, fsStyle.font14, AtomicCss.marginBottom3]}>
            {staticData.verifiedStaysCardText.heading}
          </Text>
          <Text style={[fonts.regularFontFamily, fsStyle.font12, AtomicCss.lightText]}>
            {staticData.verifiedStaysCardText.subHeading}
          </Text>
        </View>
      </View>
      <View>
        <Image style={[styles.rightBlueArrow, getImageTransformStyling()]} source={BLUE_RIGHT_ARROW_ICON} />
      </View>
    </TouchableOpacity>
  );
};

export default VerifiedStaysCard;
