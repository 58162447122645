import { StyleSheet, I18nManager } from 'react-native';
import { DEFAULT_THEME } from 'apps/post-sales/src/theme/default.theme';

export default <T extends typeof DEFAULT_THEME>(theme: T) => StyleSheet.create({
  altAccoStarStyle: {
    marginRight: I18nManager.isRTL ? 2 : 0,
    marginLeft: I18nManager.isRTL ? 0 : 2,
    width: 11,
    height: 11,
    tintColor: theme.color.grey41,
  },
  leftLeafImg: {
    width: 7,
    height: 14,
    marginRight: 2,
  },
  rightLeafImg: {
    width: 7,
    height: 14,
  },
  hotelCategoryStyle: {
    marginTop: -2,
    color: theme.color.grey41,
  },
  altAccoPropertyStarWpr: {
    display: 'flex',
    flexDirection: I18nManager.isRTL ? 'row-reverse' : 'row',
    justifyContent: I18nManager.isRTL ? 'flex-end' : 'flex-start',
  },
});
