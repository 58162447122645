import { trackOmniture, VAR_BOOKING_ID } from '@mmt/legacy-commons/Common/utils/OmnitureTrackerUtils';
import CsatFeedbackCardConst from './csatFeedbackConst';

class CSATFeedbackHelper {
  trackLoadEvent(pageName, bookingid, cardType = CsatFeedbackCardConst.INLINE_CARD) {
    const params = {
      pageName,
      m_v44: `MyTrips_CSAT_${cardType}_load`,
      [VAR_BOOKING_ID] : bookingid,
    };
    trackOmniture(pageName, params);
  }

  trackClickEvent(pageName, bookingid, responseType, cardType = CsatFeedbackCardConst.INLINE) {
    const params = {
      pageName,
      m_c1: cardType !== CsatFeedbackCardConst.DISMISSED ? 
        `MyTrips_CSAT_${cardType}_${responseType}_click` : `MyTrips_CSAT_${cardType}_${responseType}`,
      [VAR_BOOKING_ID]: bookingid,
    };
    trackOmniture(pageName, params);
  }

  trackErrorEvent(pageName, bookingid, cardType = CsatFeedbackCardConst.INLINE_CARD) {
    const params = {
      pageName,
      m_v50: `MyTrips_CSAT_${cardType}_error`,
      [VAR_BOOKING_ID]: bookingid,
    };
    trackOmniture(pageName, params);
  }

  trackSeenEvent(pageName, bookingid, cardType='Inlinecard', reasonType) {
    const params = {
      pageName,
      m_v44: !reasonType ? `MyTrips_CSAT_${cardType}_seen` : `MyTrips_CSAT_${cardType}_${reasonType}_seen`,
      [VAR_BOOKING_ID]: bookingid,
    };
    trackOmniture(pageName, params);
  }
}

export default new CSATFeedbackHelper();
