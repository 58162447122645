import ViewState from './ViewState';
import * as constants from './FlightListingActions';

const initialState = {
  viewState: ViewState.LOADING,
  searchResponse: null,
  searchMap: [],
  selectedFlightItem: null,
  bookingType: null,
  searchErrorMessage: '',
  headerErrorMessage: '',
  headerInfo: {
    title: '',
    subTitle: ''
  },
  refreshListing: false,
  message: undefined,
  errorPageData: undefined
};

const flightListingReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.ACTION_LOADING_FLIGHTS:
      return {...state, viewState: ViewState.LOADING, refreshListing: false};
    case constants.ACTION_ERROR:
      return {
        ...state,
        errorCode: action.data.errorCode,
        viewState: ViewState.ERROR,
        refreshListing: false
      };
    case constants.ACTION_ERROR_NEW:
      return {
        ...state,
        errorCode: action.data.errorCode,
        errorPageData: action.data.errorPageData,
        viewState: ViewState.LISTING_API_ERROR,
        refreshListing: false
      };
    case constants.ACTION_SHOW_DETAIL:
      return {
        ...state,
        viewState: ViewState.SHOW_DETAIL,
        response: action.response,
        searchResponse: action.searchResponse,
        searchMap: action.searchMap,
        headerInfo: action.headerInfo,
        bookingType: action.bookingType,
        filterInfo: action.filterInfo,
        refreshListing: false,
        message: action.message,
        sessionId: action.sessionId,
        cfarInfo: action.cfarInfo,
        odcImportantInfo: action.odcImportantInfo,
        calendarDataList: action.calendarDataList,
      };
    case constants.ACTION_RENDER_LIST:
      return {
        ...state,
        viewState: ViewState.RENDER_LIST,
        searchMap: action.searchMap,
        refreshListing: false
      };
    case constants.ACTION_SELECT_ITEM:
      return {
        ...state,
        selectedFlightItem: action.data.selectedFlightItem,
        refreshListing: false
      };
    case constants.ACTION_REFRESH:
      return {
        initialState,
        refreshListing: true
      };
    default: {
      return state;
    }
  }
};


export default flightListingReducer;
