import React from 'react';
import {Text, TouchableOpacity} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import styles from './styles/CommonMamiCss';
import PropTypes from 'prop-types';
import { themeColors } from '../PostSalesStyles';

const CapsuleFullBlueButton = ({action, clickHandler, disabled}) => (
  <TouchableOpacity
    style={[[styles.btnMainWrapper, styles.TypeCapsule]]}
    activeOpacity={.8} onPress={() => (!disabled && clickHandler(action))}>
    <LinearGradient
      start={{
        x: 1.0,
        y: 0.0
      }}
      end={{
        x: 0.0,
        y: 1.0
      }}
      colors={(disabled) ? ['#9b9b9b', '#d3d3d3'] : themeColors.CapsuleBtnFillColors}
      style={[styles.CapsuleBtnFill, styles.TypeCapsule]}
    >
      <Text style={[styles.btnTextWhite]}>{action.actionLabeltext}</Text>
    </LinearGradient>
  </TouchableOpacity>
);

CapsuleFullBlueButton.propTypes = {
  action: PropTypes.object.isRequired,
  clickHandler: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

CapsuleFullBlueButton.defaultProps = {
  disabled: false
}

export default CapsuleFullBlueButton;
