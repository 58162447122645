import React, { useEffect, useState } from 'react';
import { View, Image, Text, TouchableOpacity } from 'react-native';
import getStyle, { getHtmlstyles } from './style';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { cancellationIcon2, bordingPointBus } from '../../BusImageConstants';
import isEmpty from 'lodash/isEmpty';
import HTMLView from 'react-native-htmlview';
import { arrowDown } from '../../BusImageConstants';
import { getActionButton, handleBusClickEvent, onPartialCancelTapped } from '../../utils/BusBookingDetailUtil';
import { getStaticData } from 'apps/post-sales/src/staticData/staticData';
import BusBookingDetailsConstant, { ClickEvents, PageName } from '../../BusBookingDetailsConstant';
import { NewCancellationPolicyCardProps } from '../../types';
import BusBookingTrackingHelper from '../../BusBookingTrackingHelper';

const NewCancellationPolicyCard: React.FC<NewCancellationPolicyCardProps> = ({
  card,
  newCancellationPolicy,
  response,
  pageName,
  handleOverlay,
  mmtAuth,
  loggingTrackInfo
}) => {
  const { heading } = card;
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme } = useTheme();
  const { color } = psTheme;
  const styles = getStyle(fonts, color);
  const { cancellationPolicyList, currentPolicy, additionalText, cancellationWindowClosed } =
    newCancellationPolicy || {};
  const { slabText, cancellationWindowClosedText, disclaimerText } = additionalText || {};
  const [viewFull, setViewFull] = useState(
    cancellationPolicyList && cancellationPolicyList.length <= 2,
  );
  const htmlStyles = getHtmlstyles(fonts, color);
  const { noRefundText } = getStaticData(true);

  const handleClick = (action: any) => {
    if (action.actionFamily === 'CancelationPolicy') {
      handleOverlay(BusBookingDetailsConstant.CANCELLATION_POLICY_OVERLAY);
    } else {
      BusBookingTrackingHelper.trackClickEvent(
        PageName.TRIP_DETAILS,
        response,
        ClickEvents.CANCEL_BOOKING
      )
      onPartialCancelTapped(response, mmtAuth, loggingTrackInfo);
    }
  };

  return (
    <View style={styles.CancellationPolicyCardWrapper}>
      <View style={[AtomicCss.marginBottom20, AtomicCss.flexRow]}>
        <Image style={styles.CardIcon} source={cancellationIcon2} />
        <Text style={[styles.heading, AtomicCss.marginRight8]}>{heading}</Text>
        {cancellationWindowClosed && <Text style={styles.noRefund}>{noRefundText}</Text>}
      </View>
      {!cancellationWindowClosed && (
        <View style={AtomicCss.marginBottom15}>
          {viewFull &&
            !isEmpty(cancellationPolicyList) &&
            cancellationPolicyList.map((policy, index) => {
              const { text, subText, subTextColor } = policy;
              const isLast = index === cancellationPolicyList.length - 1;
              const activeDot = policy.state === 'active';
              const completedDot = policy.state === 'completed';
              return (
                <View style={[styles.listContainer]} key={index}>
                  <View style={[styles.trackerRow, isLast ? styles.lastRow : {}]}>
                    {isLast ? (
                      <View style={styles.boardingPoint}>
                        <Image style={styles.boardingBusStyle} source={bordingPointBus} />
                      </View>
                    ) : (
                      <View
                        style={[
                          styles.rowDot,
                          activeDot ? styles.activeDot : {},
                          completedDot ? styles.completedDot : {},
                        ]}
                      ></View>
                    )}
                    <View style={completedDot ? { opacity: 0.4 } : {}}>
                      {!isEmpty(text) && (
                        <View>
                          <HTMLView value={text} stylesheet={htmlStyles} />
                        </View>
                      )}
                      {!isEmpty(subText) && (
                        <Text style={[styles.cancellationPolicySubText, { color: subTextColor }]}>
                          {subText}
                        </Text>
                      )}
                    </View>
                  </View>
                </View>
              );
            })}

          {!viewFull && !isEmpty(currentPolicy) && (
            <View style={styles.listContainer}>
              <View style={styles.trackerRow}>
                <View style={[styles.rowDot, styles.activeDot]}></View>
                {!isEmpty(currentPolicy.text) && (
                    <HTMLView value={currentPolicy.text} stylesheet={htmlStyles} />
                )}
                {!isEmpty(currentPolicy.subText) && (
                  <Text style={styles.currentPolicyText}>
                    {currentPolicy.subText}
                  </Text>
                )}
              </View>
              <View style={[styles.trackerRow, styles.lastRow]}>
                <View style={[styles.rowDot]}></View>
                <TouchableOpacity
                  style={AtomicCss.flexRow}
                  onPress={() => {
                    setViewFull((prevState) => !prevState);
                  }}
                >
                  <Text style={styles.viewSlab}>{slabText}</Text>
                  <Image style={styles.arrowDown} source={arrowDown}></Image>
                </TouchableOpacity>
              </View>
            </View>
          )}

          {!!disclaimerText && <Text style={styles.disclaimerText}>{disclaimerText}</Text>}
        </View>
      )}
      {cancellationWindowClosed && !isEmpty(cancellationWindowClosedText) && (
        <View style={AtomicCss.paddingLeft30}>
          <HTMLView value={cancellationWindowClosedText} stylesheet={htmlStyles} />
        </View>
      )}
      {getActionButton(card, response, handleClick)}
    </View>
  );
};

export default NewCancellationPolicyCard;
