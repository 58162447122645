import {Dimensions, Platform} from 'react-native';
import {colors, fonts, isIPhoneX, statusBarHeightForIphone} from '@mmt/legacy-commons/Styles/globalStyles';

const windowHeight = Dimensions.get('screen').height;
const windowWidth = Dimensions.get('screen').width;

const styles = {
  overlayWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    elevation: 10,
    zIndex: 100,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    flexDirection: 'row',
    width: windowWidth,
    ...Platform.select({
            ios: {
              paddingBottom: isIPhoneX ? 60 : 20, height: windowHeight,
            },
            android: {
             paddingBottom: 26, height: windowHeight-30,
            },
            web: {
              paddingBottom: 0  , height: windowHeight,
            }
          })
  },
  overlay: {
    position: 'absolute',
    ...Platform.select({
      ios: {
        top: -statusBarHeightForIphone,
      },
      android: {
       top: -statusBarHeightForIphone - 20.0
      },
      web: {
        top: 0
      }
    }),
    left: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
    height: '100%',
    width: '100%',
    zIndex: 1
  },
  overlayContent: {
    backgroundColor: '#ffffff',
    padding: 20,
    position: 'relative',
    zIndex: 1,
    width: '100%'
  },
  contentTitle: {
    fontSize: 28,
    color: colors.black,
    fontFamily: fonts.light,
    marginBottom: 25
  },
  contentDesc: {
    fontSize: 14,
    color: colors.defaultTextColor,
    fontFamily: fonts.regular,
    marginBottom: 15,
    lineHeight: 20
  },
  contentFooter: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  actionBtn: {
    paddingVertical: 10
  },
  actionBtnText: {
    fontSize: 16,
    color: colors.azure,
    fontFamily: fonts.medium
  },
  ImagePlaceholder: {
    width: 34,
    marginRight: 12,
    alignItems: 'center'
  },
  seatIconStyle: {
    width: 16,
    height: 21
  },
  chkInProcessIconStyle: {
    width: 19,
    height: 13,
    marginTop: 3

  },
  boardingPassIconStyle: {
    width: 24,
    height: 12,
    marginTop: 3

  }
};

export default styles;
