import {colors, fonts} from '../../../../Styles/globalStyles';

const styles = {
  contentTitle: {
    fontSize: 28,
    color: colors.black,
    fontFamily: fonts.light,
    marginBottom: 18
  },
  contentDesc: {
    fontSize: 14,
    color: colors.defaultTextColor,
    fontFamily: fonts.light,
    marginBottom: 10
  },
  contentFooter: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  actionBtn: {
    paddingVertical: 10
  },
  actionBtnText: {
    fontSize: 16,
    color: colors.azure,
    fontFamily: fonts.medium
  },
  corporateColor: {
    color: '#f27237'
  }
};

export default styles;

