import React, { useEffect } from 'react';
import { View, Text, FlatList, Image, TouchableOpacity } from 'react-native';

import createStyle from './NameChangeBottomSheetCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import GreenTickIcon from '@mmt/legacy-assets/src/greenTick_plain.webp';
import FlightBookingTrackingHelper from '../../../../modules/details/helper/FlightBookingTrackingHelper';
import BaseButton, { BUTTON_VARIANT } from '../../../../../Common/BaseButton';
import { useTheme } from '../../../../../theme/theme.context';
import { getFont } from '../../../../../PostSalesStyles';
import { getStaticData } from '../../../../../staticData/staticData';

import { isEmptyArray } from '../../../../../Common/commonUtil';

export default function LastNameIsRepeated({ data, onClose, onContinueClicked, responseJson }) {
  const {
    title,
    subTitle,
    passengerDetailsNameCorrectionList,
    firstLastNameRepeatedActionButtonList,
  } = data;
  const { psTheme: theme } = useTheme();
  const { fsStyle, ...fonts } = getFont(true);
  const styles = createStyle(theme, fonts);
  const staticData = getStaticData();
  const { nameCorrectionTexts } = staticData;

  useEffect(() => {
    FlightBookingTrackingHelper.trackLoadEvent(
      'Special request : Name Change Pop up Last Name Reapeated',
      responseJson,
      'nameChange',
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const trackClickEvent = (clickEvent) => {
    FlightBookingTrackingHelper.trackNameCorrectionBtnClick(
      'Special request : Name Change Pop up LastName Reapeated',
      clickEvent,
      responseJson,
    );
  };

  const renderActions = ({ type, text }) => {
    switch (type) {
      case 'button':
        return (
          <BaseButton
            variant={BUTTON_VARIANT.PRIMARY}
            text={text || nameCorrectionTexts.okayGotIt}
            clickHandler={() => {
              trackClickEvent(text);
              onClose();
            }}
            buttonStyle={styles.buttonStyle}
            textStyle={fsStyle.font16}
          />
        );
      case 'hyperlink':
        return (
          <TouchableOpacity
            onPress={() => {
              trackClickEvent(text);
              onContinueClicked();
            }}
            activeOpacity={0.7}
            style={styles.makeBtnWrapper}
          >
            {!!text && (
              <Text
                style={[
                  fsStyle.font16,
                  fonts.blackFontFamily,
                  { color: theme.color.primary }
                ]}
              >
                {text}
              </Text>
            )}
          </TouchableOpacity>
        );

      default:
        return null;
    }
  };

  const renderPassengerDetailsCTA = ({ item }) => {
    return (
      <View style={[styles.detailSec, AtomicCss.marginBottom16]}>
        <View style={[AtomicCss.flexRow, AtomicCss.marginBottom20]}>
          <View style={[styles.leftStroke, { backgroundColor: theme.color.primary }]} />
          <View style={[AtomicCss.flexRow]}>
            <Text style={[fonts.blackFontFamily, fsStyle.font16, AtomicCss.blackText]}>
              {item.traveller}{' '}
            </Text>
            <Text style={[fonts.blackFontFamily, fsStyle.font16, AtomicCss.blackText]}>
              {item.travellerNo}
            </Text>
          </View>
        </View>
        {!!item.title && (
          <Text
            style={[
              fonts.regularFontFamily,
              fsStyle.font14,
              AtomicCss.midGreyText,
              AtomicCss.marginBottom8,
            ]}
          >
            {item.title}
          </Text>
        )}
        {!!item.fullName && (
          <Text
            style={[
              fonts.boldFontFamily,
              fsStyle.font14,
              AtomicCss.blackText,
              AtomicCss.marginBottom20,
            ]}
          >
            {item.fullName}
          </Text>
        )}
        {!!item.correctionString && (
          <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
            <Image source={GreenTickIcon} style={styles.greenTickIcon} />
            <Text style={[fonts.boldFontFamily, fsStyle.font14, AtomicCss.greenText]}>
              {item.correctionString}
            </Text>
          </View>
        )}
      </View>
    );
  };
  return (
    <View style={styles.scrollableWrapper}>
      <View style={styles.lastNameRepeatedWrapper}>
        <Text style={[styles.title, AtomicCss.marginBottom8]}>{title}</Text>
        <Text
          style={[
            fonts.regularFontFamily,
            fsStyle.font14,
            AtomicCss.defaultText,
            AtomicCss.lineHeight20,
            AtomicCss.marginBottom12,
          ]}
        >
          {subTitle}
        </Text>
        <View style={[styles.strokeStyle, { backgroundColor: theme.color.primary }]} />
        <FlatList
          showsVerticalScrollIndicator={false}
          data={passengerDetailsNameCorrectionList}
          renderItem={renderPassengerDetailsCTA}
          keyExtractor={(i) => i.title}
        />
      </View>
      {!isEmptyArray(firstLastNameRepeatedActionButtonList) && (
        <View style={styles.buttonBottomWrapper}>
          {firstLastNameRepeatedActionButtonList.map((action) => renderActions(action))}
        </View>
      )}
    </View>
  );
}
