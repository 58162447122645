import {Platform, StyleSheet} from 'react-native';
import {colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles';

const styles = StyleSheet.create({
  parentContainer: {
    flex: 1,
    backgroundColor: '#ffffff'
  },

  ErrorImg1: {
    width: 63,
    height: 42
  },
  FullPageErrorPage: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 35,
    paddingVertical: 60,
    backgroundColor: '#fff'
  },
  thankYouWrapper: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    paddingHorizontal: '10%'
  },
  transparantBgrnd: {
    ...Platform.select({
      ios: {
        backgroundColor: 'transparent'
      }
    })
  },
  thankYouHeader: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 47
  },
  headerIcon: {
    width: 24,
    height: 32,
    marginRight: 7
  },
  headerTitle: {
    fontSize: 14,
    color: '#053cb2',
    fontFamily: fonts.black
  },
  headerDesc: {
    fontSize: 12,
    color: '#053cb2'
  },
  successIcon: {
    width: 40,
    height: 40,
    marginRight: 15,
    marginBottom: 10,
    alignSelf: 'center'
  },
  messageTitle: {
    fontSize: 20,
    textAlign: 'center',
    lineHeight: 25,
    color: '#000000',
    fontFamily: fonts.bold,
    marginBottom: 27
  },
  messageText: {
    textAlign: 'center',
    fontSize: 12,
    color: '#000000'
  },
  CommonFillBtn: {
    width: '100%'
  },
  CommonFillBtnGradient: {
    borderRadius: 25,
    paddingVertical: 11,
    paddingHorizontal: 25,
    marginTop: 24
  },
  marginBottom20: {marginBottom: 20},
  ErrorHeading: {
    fontSize: 20,
    lineHeight: 26,
    color: '#4a4a4a',
    fontFamily: fonts.bold,
    textAlign: 'center'
  },
  ErrorDisc: {
    fontSize: 14,
    fontFamily: fonts.regular,
    textAlign: 'center',
    color: '#747474',
    justifyContent: 'center',
    lineHeight: 20
  },
  lastDisc: {
    marginBottom: 80
  },
  CommonFillBtnText: {
    color: colors.white,
    alignSelf: 'center',
    fontFamily: fonts.black
  },
  hideView: {
    display: 'none'
  }

});

export default styles;
