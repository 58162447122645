import React from 'react';
import {
  BackHandler,
  DeviceEventEmitter,
  Dimensions,
  Platform,
  ScrollView,
  Text,
  TouchableHighlight,
  View,
  Animated,
  TouchableWithoutFeedback
} from 'react-native';
import BasePage from '../Common/PostSalesBasePage';
import { getStyle } from './BusBookingDetailCss';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import { BUS_BOOKING_DETAILS_URL, getCommonHeaders } from '../utils/NetworkUtils';
import { isNetworkAvailable } from '@mmt/legacy-commons/Common/utils/AppUtils';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import ProgressView from '@mmt/legacy-commons/Common/Components/Progress/ProgressView';
import NoInternetView from '@mmt/legacy-commons/Common/Components/Error/NoInternetView';
import StickyHeader from './components/StickyHeader';
import { colors } from './components/TopStatusCardColor';
import TravellerCard from './components/TravellerCard';
import TopStatusCard from './components/TopStatusCard';
import BusBookingDetailsConstant, { BusTrackingConstant } from './BusBookingDetailsConstant';
import BusPriceCard from './components/BusPriceCard';
import NeedHelpCard from './components/NeedHelpCard';
import {
  getTopCardSubTextPartialCancelled,
  onPartialCancelTapped,
  handleBusClickEvent,
} from './utils/BusBookingDetailUtil';
import BusBookingTrackingHelper from './BusBookingTrackingHelper';
import ViewControllerModule from '@mmt/legacy-commons/Native/ViewControllerModule';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import SimpleHeader from '@mmt/legacy-commons/Common/Components/Header/SimpleHeader';
import { AUTH_FAILED_MESSAGE, CHAT_WITH_MYRA_CARD, COMMON_CARD, LOBNAMES } from '../PostSalesConstant';
import ChatWithMyraCard from '../Common/ChatWithMyraCard';
import {
  islangValidationRequired,
  isLostBookingData,
  isMyraEnabled,
  isBusFlexiEnabled,
  verifyBookingLang
} from '../utils/PostSaleUtil';
import SpecialClaimCard from './components/SpecialClaim/SpecialClaimCard';
import OverlayMessage from '../Common/OverlayMessage';
import Actions from '../navigation/postSalesNavigation';
import RefundTracker from '../Common/RefundTracker';
import { isNotNullAndEmpty } from '../hotel/details/utils/HotelBookingDetailUtil';
import AlertInfo from '../Common/CommonImpInfo';
import BusCancellationPolicyCard from './components/BusCancellationPolicyCard';
import BusTrackDetail from './liveTracking/boardingDropInfo/BusTrackDetail';
import { connect } from 'react-redux';
import { fetchLiveTrackingData } from './busBookingDetailAction';
import ItineraryCard from './components/ItineraryCard';
import MMTBlackCard from '../Common/MMTBlackCard';
import BenefitsCard from '../Common/BenefitsClaimedCard';
import MSRMainCard from '../Common/MSR/MainCard';
import SupportCard from './components/Support/SupportCard';
import TripAssuranceCard from './components/TripAssurance/TripAssuranceCard';
import TripPassCard from './components/TripPass/TripPassCard';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {
  topStatusAfterTripIcon,
  topStatusCancelledIcon,
  topStatusGetReadyIcon,
  topStatusConfirmedIcon,
  topStatusFailedIcon,
  topStatusOnTripIcon,
} from './BusImageConstants';
import AmenityDetails from './components/Amenities';
import { getAdTechCard } from '../Common/commonUtil';
import { POST_SALES_BUS_DETAILS_PAGE } from '@mmt/legacy-commons/adsConfig/adsConfigConstants';
import AdvisoryNotice from '../Common/AdvisoryNotice';
import AdvisoryOverlay from '../Common/AdvisoryNotice/AdvisoryOverLay';
import PrimoBanner from './components/PrimoBanner';
import { REFUND_OOT_TRACKING } from '../Common/RefundOutOfTATForm/constants';
import LostIDBookingDetailPage from '../Common/LostIdDetailPage';
import addBookingTrackingHelper, {
  ADD_BOOKING_TRACKING_EVENTS,
} from '../summary/components/AddBooking/tracking';
import ChangeTravelDate from './components/ChangeTravelDate';
import CommonBottomOverlayMessage from '../Common/CommonOverlayMessage/CommonBottomOverlayMessage';
import ChangeDateOverlay from './components/ChangeDateOverlay';
import CancellationPolicyOverlay from "./components/CancellationPolicyOverlay"
import { openCalender } from './page/CancelRebookUtil';
import BusCancelRebookConstant from './page/CancelRebookConstants';
import BusNotFoundOverlay from './components/BusNotFoundOverlay';
import ConfirmCancelOverlay from './components/ConfirmCancelOverlay';
import ReturnTripOfferCard from './modules/bookingDetails/components/ReturnTripOfferCard';
import { getStaticData, label } from '@mmt/post-sales/src/staticData/staticData';
import isEmpty from 'lodash/isEmpty';
export const BUS_BOOKING_DETAILS_PAGE = 'BusBookingDetails';
import NewCancellationPolicyCard from "./components/NewCancellationPolicyCard"
import NewCommonOverlay from '../Common/NewCommonOverlay';
import { ThemeContext } from '../theme/theme.context';
import HotelCrossSell from '../Common/CrossSell/Hotel';
import { getPokusConfig } from '@mmt/legacy-commons/Native/AbConfig/AbConfigModule';
import abConfigKeyMappings, { PokusLobs } from '@mmt/legacy-commons/Native/AbConfig/AbConfigKeyMappings';
import HelpSupport from './components/HelpSupport';
import AuthErrorView from '../Common/AuthError/AuthErrorView';
import CustomerSupportFloatingCard from '../Common/CustomerSupportCard/CustomerSupportFloatingCard';
import { RuleTraceConst } from '../Common/CustomerSupportCard/CustomerSupportCardConst';
import { getCustomerSupportCardData } from '../Common/CustomerSupportCard/api';
import { scrollHelper } from '../Common/CustomerSupportCard/CustomerSupportCardHelper';
import TravelInsuranceCard from './components/BusInsuranceCard/index';
import TripAssuredCard from './components/TripAssuredCard';
import TripAssuredClaimApproved from './components/TripAssuredClaimApproved';
import TripAssuredClaimRejected from './components/TripAssuredClaimRejected';
import TravelCashCard from '../Common/TravelCard';

class BusBookingDetail extends BasePage {
  static contextType = ThemeContext;
  static navigationOptions = {
    header: null,
  };

  constructor(props, context) {
    super(props, BUS_BOOKING_DETAILS_PAGE);
    this.bookingId =
      props.data && props.data.BOOKING_ID
        ? props.data.BOOKING_ID
        : props.bookingId
        ? props.bookingId
        : '';
    this.pageData = props.data && props.data.pageData ? JSON.parse(props.data.pageData) : undefined;
    this.isLostBooking = isLostBookingData(this.pageData);
    this.langValidationRequired = islangValidationRequired(this.pageData);
    this.mmtAuth = '';
    this.loggingTrackInfo = {};
    this.trackPageName = 'TripDetails';
    this.topStatusCardColor = [colors.blueStartColor, colors.blueEndColor];
    this.getCancellationRequestNo = this.getCancellationRequestNo.bind(this);
    this.state = {
      responseJson: null,
      specialClaimSelectedItem: null,
      specialClaimClicked: false,
      viewState: this.isLostBooking ? ViewState.SHOW_DETAIL : ViewState.LOADING,
      advisoryOverlay: false,
      overlayName: null,
      busSearchResponse: null,
    };
    this.toggleAdvisoryOverlay = this.toggleAdvisoryOverlay.bind(this);
    const {psTheme} = context;
    this.styles = getStyle(psTheme);
    // width of the card decreases on scroll. Initial value is 146
    this.customerSupportCardWidth = new Animated.Value(146);
    // width of the text decreases on scroll. Initial value is 1
    this.customerSupportTextWidth = new Animated.Value(1);
  }
  setCustomerSupportCardData = async () => {
    const data = await getCustomerSupportCardData(
      this.bookingId,
      this.pageData?.uuid,
      'busBookingDetails'
    );
    !!data &&
      this.setState({
        customerSupportCardData: data,
      });
  };
  handleOverlay = (overlayName = null) => {
    this.setState({ overlayName });
  };
  showBusFlexi = () => {
    return isBusFlexiEnabled() && Platform.OS==='android';
  }
  handleConfirmCancelClick = (button) => {
    this.handleOverlay();
    if (button.actionType == BusCancelRebookConstant.PROCEED_CANCELLATION) {
      BusBookingTrackingHelper.trackClickEvent(
        BusTrackingConstant.BUS_BOOKING_DETAILS_PAGE,
        this.state.responseJson,
        BusTrackingConstant.CANCEL_BOOKING,
      );
      onPartialCancelTapped(this.state.responseJson, this.mmtAuth, this.loggingTrackInfo);
    } else if(this.showBusFlexi() && button.actionType == BusCancelRebookConstant.CHANGE_TRAVEL_DATE){
      this.handleOverlay(BusBookingDetailsConstant.DATE_CHANGE_OVERLAY);
    }
  };

  toggleAdvisoryOverlay() {
    this.setState({ advisoryOverlay: !this.state.advisoryOverlay });
  }

  getCancellationRequestNo(cancellationDetailList) {
    const cancellationRequestNoList = [];
    if (isNotNullAndEmpty(cancellationDetailList)) {
      cancellationDetailList.forEach((cancellationDetail) => {
        cancellationRequestNoList.push(cancellationDetail.requestNo);
      });
    }
    return cancellationRequestNoList;
  }

  render() {
    return (
      <View style={AtomicCss.flex1}>
        {this.state.specialClaimClicked && this.renderOverlay()}
        {this.state.viewState === ViewState.ERROR && this.renderErrorView()}
        {this.state.viewState === ViewState.AUTH_ERROR && this.renderAuthErrorView()}
        {this.state.viewState === ViewState.SHOW_DETAIL && this.renderPage()}
        {this.state.viewState === ViewState.SHOW_DETAIL &&
          this.state.advisoryOverlay &&
          this.renderAdvisoryOverlay()}
        {this.state.viewState === ViewState.NO_INTERNET && this.renderNoNetworkView()}
        {this.state.viewState === ViewState.LOADING && this.renderProgressView()}
        {this.state.cardOverlay && this.renderCardOverlay()}
        {(this.state.customerSupportCardData?.ruleTrace === RuleTraceConst.FLOATING) && (
          <CustomerSupportFloatingCard
            cardWidth={this.customerSupportCardWidth}
            dynamicTextWidth={this.customerSupportTextWidth}
            cardData={this.state.responseJson?.floatingNeedHelpCard}
            bookingId={this.bookingId}
            pageName="Mob:customer support:Bus:TripDetails"
            uuid={this.state.responseJson?.uuid}
            isInternational={this.state.responseJson?.isInternational}
            productCode={LOBNAMES.BUS}
          />
        )}
      </View>
    );
  }

  renderAdvisoryOverlay() {
    return (
      <TouchableWithoutFeedback onPress={this.toggleAdvisoryOverlay}>
        <View style={this.styles.overlayContainer}>
          <TouchableHighlight style={this.styles.overlayBg}>
            <Text>.</Text>
          </TouchableHighlight>
          <AdvisoryOverlay
            key="BusBottomOverlay"
            pageName="BusBottomOverlay"
            advisoryNoticeText={this.state.responseJson && this.state.responseJson.advisoryNoticeText}
            closeoverlay={this.toggleAdvisoryOverlay}
            langAware={true}
          />
        </View>
      </TouchableWithoutFeedback>
    );
  }

  renderOverlay() {
    return (
      <OverlayMessage
        popUpOutsideClick={this.dismissRaiseClaimPopup}
        leftBtnClick={() => {
          this.dismissRaiseClaimPopup(true);
        }}
        rightBtnClick={this.raiseClaimContinueClicked}
        title={this.state.specialClaimSelectedItem.popupHeader}
        leftBtnTxt={this.state.specialClaimSelectedItem.popupNo}
        rightBtnTxt={this.state.specialClaimSelectedItem.popupYes}
        content={this.state.specialClaimSelectedItem.popupTxt}
        wrapperStyle={this.styles.overlay}
      />
    );
  }

  componentDidMount() {
    if(!this.isLostBooking && !this.langValidationRequired){
      this.fetchJSONAsync(BUS_BOOKING_DETAILS_URL + this.bookingId);
    }else{
      if(this.langValidationRequired){
        verifyBookingLang(this.bookingId, LOBNAMES.BUS)
        .finally(() => {
          if (!this.isLostBooking) {
            this.fetchJSONAsync(BUS_BOOKING_DETAILS_URL + this.bookingId);
          }
        });
      }
    }
    if (this.pageData?.isAddBookingFlow) {
      addBookingTrackingHelper.trackLoadEvent(
        ADD_BOOKING_TRACKING_EVENTS.Addbooking_detailspage_landed,
        this.pageData?.uuid,
        this.bookingId,
      );
    };
    this.setCustomerSupportCardData();
    BackHandler.addEventListener('hardwareBackPress', this.onHardBackPress);
  }


  componentWillUnmount() {
    DeviceEventEmitter.removeAllListeners(BusCancelRebookConstant.CALENDAR_EVENT_RECEIVED);
    BackHandler.removeEventListener('hardwareBackPress', this.onHardBackPress);
  }

  onHardBackPress = () => {
    this.goBack();
    return true;
  };

  componentWillReceiveProps(nextProps) {
    if(nextProps.data && nextProps.data.BOOKING_ID !=this.bookingId){
      this.bookingId = nextProps.data.BOOKING_ID;
    }
    if (
      nextProps.data !== this.props.data &&
      nextProps &&
      nextProps.data &&
      nextProps.data.refresh === true &&
      !this.isLostBooking
    ) {
      this.onRefresh();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data && this.props.data.refresh === true) {
      if (this.isLostBooking) {
        this.setState({
          refreshLostDetail: {},
        });
        return;
      }
    }
  }

  onRefresh = () => {
    this.setState({ viewState: ViewState.LOADING });
    this.fetchJSONAsync(BUS_BOOKING_DETAILS_URL + this.bookingId);
  };

  async fetchJSONAsync(url) {
    try {
      const hasNetwork = await isNetworkAvailable();
      if (!hasNetwork) {
        this.setState({ viewState: ViewState.NO_INTERNET });
        return;
      }

      const userInfo = await HotelBookingModule.getRequestHeader(
        this.bookingId,
        'BUS_BOOKING_DETAIL',
      );
      this.mmtAuth = userInfo.mmtAuth;
      this.loggingTrackInfo = userInfo.loggingTrackingInfo;

      const response = await fetch(url, {
        headers: await getCommonHeaders(this.mmtAuth, this.loggingTrackInfo),
      });

      if (response.ok) {
        const body = await response.json();

        this.setState({
          responseJson: body,
          viewState: ViewState.SHOW_DETAIL,
        });
        const isLiveTrackingEnabled =
          body.busLiveTrackingInfo && body.busLiveTrackingInfo.liveTrackingEnabled;
        if (isLiveTrackingEnabled) {
          this.props.fetchLiveTrackingData(
            this.state.responseJson,
            this.mmtAuth,
            this.loggingTrackInfo,
          );
        }
        BusBookingTrackingHelper.trackLoadEvent('TripDetails', this.state.responseJson);
      } else {
        const errorBody = await response?.json();
        if (errorBody && errorBody?.message && errorBody.message.toUpperCase() === AUTH_FAILED_MESSAGE) {
          this.setState({ viewState: ViewState.AUTH_ERROR });
        } else {
          BusBookingTrackingHelper.trackErrorEvent('TripDetails');
          this.setState({ viewState: ViewState.ERROR });
        }
      }
    } catch (error) {
      BusBookingTrackingHelper.trackErrorEvent('TripDetails');
      this.setState({ viewState: ViewState.ERROR });
    }
  }

  renderErrorView = () => (
    <View style={AtomicCss.flex1}>
      <SimpleHeader title={label('backText')} iconPress={this.goBack} />
      <ErrorView
        showRetry
        message={label('bus.somethingWentWrong')}
        buttonLabel={label('tryAgainText')}
        onRetry={() => {
          this.setState({ viewState: ViewState.LOADING });
          this.fetchJSONAsync(BUS_BOOKING_DETAILS_URL + this.bookingId);
        }}
      />
    </View>
  );

  renderAuthErrorView = () => <AuthErrorView bookingId={this.bookingId} lobName={LOBNAMES.BUS} onGoBackPress={this.goBack} />

  renderPage() {
    let views = [];
    if (this.isLostBooking) {
      return (
        <LostIDBookingDetailPage
          bookingId={this.bookingId}
          pageData={this.pageData}
          lobName={LOBNAMES.BUS}
          refreshLostDetail={this.state.refreshLostDetail}
          getRefundCard={({ response }) => this.renderRefundCard(response, true)}
        />
      );
    }
    views = this.getMainPageViews();

    return (
      <>
        <ScrollView 
        style={this.styles.GreyBg} 
        stickyHeaderIndices={[0]}
        onScroll={() =>
          scrollHelper(this.customerSupportCardWidth, this.customerSupportTextWidth)
        }>
          <StickyHeader
            color={this.topStatusCardColor}
            response={this.state.responseJson}
            onBackPress={this.goBack}
          />
          {views}
        </ScrollView>

        {this.state.overlayName === BusBookingDetailsConstant.DATE_CHANGE_OVERLAY &&
          this.getChangeDateOverlay()}
        {this.state.overlayName === BusBookingDetailsConstant.BUS_NOT_FOUND_OVERLAY &&
          this.getBusNotFoundOverlay()}
        {this.state.overlayName === BusBookingDetailsConstant.CONFIRM_CANCEL_OVERLAY &&
          this.getConfirmCancelOverlay()}
        {this.state.overlayName === BusBookingDetailsConstant.CANCELLATION_POLICY_OVERLAY &&
          this.getCancellationPolicyOverlay()
        }
      </>
    );
  }

  renderNoNetworkView = () => {
    const { errorText, tryAgainText, backText } = getStaticData();
    const { notConnectedToTheInternetError, checkYourSettingsAndTryAgainError } = errorText;

    return (
      <View style={{ flex: 1 }}>
        <SimpleHeader
          title={backText}
          iconPress={this.goBack}
        />
        <NoInternetView
          heading={notConnectedToTheInternetError}
          subheading={checkYourSettingsAndTryAgainError}
          buttonText={tryAgainText}
          onRetry={() => {
            this.setState({ viewState: ViewState.LOADING });
            this.fetchJSONAsync(BUS_BOOKING_DETAILS_URL + this.bookingId);
          }}
        />
      </View>
    );
  }

  renderProgressView = () => <ProgressView message={label('bus.loadingYourTrip')} />;

  goBack = () => {
    if (Platform.OS === 'ios') {
      ViewControllerModule.popViewController(1);
    } else {
      super.onBackIconPress();
    }
    return true;
  };

  raiseClaimClicked = (item,triggerEventTracker=true) => {
    if(triggerEventTracker){
    BusBookingTrackingHelper.trackClickEvent(
      BUS_BOOKING_DETAILS_PAGE,
      this.state.responseJson,
      'Raise Claim Clicked',
    );
  }
    this.setState({ specialClaimClicked: true, specialClaimSelectedItem: item });
  };

  dismissRaiseClaimPopup = (track) => {
    if (track) {
      BusBookingTrackingHelper.trackClickEvent(
        BUS_BOOKING_DETAILS_PAGE,
        this.state.responseJson,
        'Dismiss Raise Claim Clicked',
      );
    }
    this.setState({ specialClaimClicked: false, specialClaimSelectedItem: null });
  };

  raiseClaimContinueClicked = () => {
    this.onContinueClicked(this.state.specialClaimSelectedItem);
    this.dismissRaiseClaimPopup(false);
    BusBookingTrackingHelper.trackClickEvent(
      BUS_BOOKING_DETAILS_PAGE,
      this.state.responseJson,
      'Raise Claim Continue Clicked',
    );
  };

  onContinueClicked = (specialClaimData) => {
    Actions.busCancelledByOperator({
      specialClaimData: JSON.stringify(specialClaimData),
      partialPax: JSON.stringify(this.getPaxFareSegmentList(specialClaimData)),
      bookingId: this.bookingId,
      bookingState: this.state.responseJson.bookingState.state,
      response: this.state.responseJson,
      lobCode: this.state.responseJson.lobCode,
      mobileNumber: this.state.responseJson.mobile,
    });
  };

  getPaxFareSegmentList = (specialClaimData) => {
    const paxFareSegmentList = [];
    this.state.responseJson.busBookingInfo.passengerDetails.forEach((passenger) => {
      if (passenger.status && passenger.status.toLowerCase() !== 'cancelled') {
        const paxFareSegment = {};
        paxFareSegment.reason = specialClaimData.scCode;
        paxFareSegment.paxFareSegmentId = passenger.lineno;
        paxFareSegmentList.push(paxFareSegment);
      }
    });
    return paxFareSegmentList;
  };

  getMainPageViews() {
    const views = [];
    const cards = this.state.responseJson.layoutDetail.cardList;
    cards.forEach((card) => {
      this.addCard(card, views);
    });
    return views;
  }
  handleDateOverlayButton = () => {
    this.handleOverlay(null);
    openCalender();
  };
  notFoundCb = (response) => {
    this.setState({ busSearchResponse: response },()=>this.handleOverlay(BusBookingDetailsConstant.BUS_NOT_FOUND_OVERLAY));
  };
  handleDateChange = (date) => {
    DeviceEventEmitter.removeAllListeners(BusCancelRebookConstant.CALENDAR_EVENT_RECEIVED);
    Actions.selectBusPage({
      prevBookingDetails: this.state.responseJson,
      bookingID: this.state.responseJson.bookingId,
      newDate: date.calDepartureDate,
      notFoundCb:  this.notFoundCb,
    });
  };

  renderRefundCard = (response, lostBooking = false) => {
    const { cancellationDetailList } = response || {};
    const cancellationRequestNoList = this.getCancellationRequestNo(cancellationDetailList);
    if (isNotNullAndEmpty(cancellationRequestNoList)) {
      return (
        <RefundTracker
          key="RefundTracker"
          cancelRequests={cancellationRequestNoList}
          bookingId={this.bookingId}
          bookingResponse={response}
          lobName={LOBNAMES.BUS}
          isLostBookingId={lostBooking}
          pageName={REFUND_OOT_TRACKING.PAGES.BUS}
        />
      );
    }
    return null;
  };

  handleCardOverlay = (card, action) => {
    this.setState({
      cardOverlay: {
        card,
        action,
      },
    })
  }

  handleHelpAndSupportAction = (actionId) => {
    if (actionId === BusBookingDetailsConstant.REFUND_REQUEST) {
      const { specialClaimDataList } = this.state.responseJson;
      if (!isEmpty(specialClaimDataList)) {
        this.setState({
          specialClaimSelectedItem: specialClaimDataList[0]
        }, this.raiseClaimContinueClicked);
        this.onClickEventTracker(BusTrackingConstant.BUS_OPERATOR_HELP);
      }
    } else if (actionId === BusBookingDetailsConstant.HELP_SPECIALIST_TEAM) {
      const { layoutDetail } = this.state.responseJson;
      const filterCard = layoutDetail.cardList.filter(card => card.cardId === BusBookingDetailsConstant.SUPPORT);
      if (!isEmpty(filterCard)){
        const { actionList } = filterCard[0];
        if (!isEmpty(actionList)){
          handleBusClickEvent(filterCard[0], filterCard[0].actionList[0], this.state.responseJson, this.trackPageName, undefined, false);
        }
      }
      this.onClickEventTracker(BusTrackingConstant.BUS_SERVICE_HELP);
    } else if (actionId === BusBookingDetailsConstant.CALL_SPECIALIST_TEAM) {
      const { layoutDetail } = this.state.responseJson;
      const filterCard = layoutDetail.cardList.filter(card => card.cardId === BusBookingDetailsConstant.BUS_NEED_HELP_CARD);
      if (!isEmpty(filterCard)){
        const { actionList } = filterCard[0];
        if (!isEmpty(actionList)){
          handleBusClickEvent(filterCard[0], filterCard[0].actionList[0], this.state.responseJson, this.trackPageName, undefined, false);
        }
      }
      this.onClickEventTracker(BusTrackingConstant.BUS_CALL_HELP);
    }
  }

  renderCardOverlay = () =>{
    const { cardOverlay, responseJson } = this.state;
    const { action } = cardOverlay || {};
    const { helpAndSupport } = responseJson || {};
    const {actionId} = action;
    if (actionId === BusBookingDetailsConstant.HELP_AND_BUS_SUPPORT) {
      return (
          <HelpSupport
            handleBottomOverlay={() => {
              this.setState({
                cardOverlay: null
              })
            }}
            handleAction={this.handleHelpAndSupportAction}
            data={helpAndSupport}
          />)
    }
    return null;
  }

    addCard(card, views) {
      const topStatusCardProps = {
        key: 'TopStatusCard',
        response: this.state.responseJson,
        headText: card.heading,
        card: card,
        onBackPress: this.goBack,
        pageName: this.trackPageName,
        mmtAuth: this.mmtAuth,
        loggerTracker: this.loggingTrackInfo,
        subText: '',
        overlayCallback: this.handleCardOverlay
      };

      const {busCancellationPolicyList} = this.state.responseJson || {};
      const {newCancellationPolicy} = !isEmpty(busCancellationPolicyList) && busCancellationPolicyList[0] || {};

      switch (card.cardId) {
        case BusBookingDetailsConstant.TOP_STATUS_CARD_BOOKING_TO_BOARDING_TIME_BOOKING:
        case BusBookingDetailsConstant.TOP_STATUS_CARD_DEFAULT_CONFIRMED:
          this.topStatusCardColor = [colors.blueStartColor, colors.blueEndColor];
          views.push(<TopStatusCard
            {...topStatusCardProps}
            iconPath={topStatusConfirmedIcon}
            color={[colors.blueStartColor, colors.blueEndColor]}
          />);
          break;
        case BusBookingDetailsConstant.TOP_STATUS_CARD_CAN_INIT_TO_REFUND_DONE:
        case BusBookingDetailsConstant.TOP_STATUS_CARD_DEFAULT_CANCELLED:
        case BusBookingDetailsConstant.TOP_STATUS_CARD_AFTER_REFUND_DONE:
          this.topStatusCardColor = [colors.blueStartColor, colors.blueEndColor];
          views.push(<TopStatusCard
            {...topStatusCardProps}
            iconPath={topStatusCancelledIcon}
            color={[colors.darkBlueStartColor, colors.darkBlueEndColor]}
            subText={card.subheading}
          />);
          break;
        case BusBookingDetailsConstant.TOP_STATUS_CARD_BOARDING_TO_DEPARTURE_TIME:
          this.topStatusCardColor = [colors.blueStartColor, colors.blueEndColor];
          views.push(<TopStatusCard
            {...topStatusCardProps}
            iconPath={topStatusGetReadyIcon}
            color={[colors.blueStartColor, colors.blueEndColor]}
          />);
          break;
        case BusBookingDetailsConstant.TOP_STATUS_CARD_ON_TRIP:
          this.topStatusCardColor = [colors.blueStartColor, colors.blueEndColor];
          views.push(<TopStatusCard
            {...topStatusCardProps}
            iconPath={topStatusOnTripIcon}
            color={[colors.blueStartColor, colors.blueEndColor]}
          />);
          break;
        case BusBookingDetailsConstant.TOP_STATUS_CARD_COMPLETTION_OF_TRIP_TO_FOREVER:
        case BusBookingDetailsConstant.TOP_STATUS_CARD_DEFAULT_COMPLETED:
        case BusBookingDetailsConstant.TOP_STATUS_CARD_PARTIALY_CAN_AT_REST_ALL_COMPLETED:
          this.topStatusCardColor = [colors.blackStartColor, colors.blackEndColor];
          views.push(<TopStatusCard
            {...topStatusCardProps}
            iconPath={topStatusAfterTripIcon}
            color={[colors.blackStartColor, colors.blackEndColor]}
          />);
          break;

        case BusBookingDetailsConstant.TOP_STATUS_CARD_PARTIALY_CAN_AT_LEAST_1_UPCOMING:
          this.topStatusCardColor = [colors.blueStartColor, colors.blueEndColor];
          views.push(<TopStatusCard
            {...topStatusCardProps}
            iconPath={topStatusConfirmedIcon}
            color={[colors.blueStartColor, colors.blueEndColor]}
          />);
          break;
        case BusBookingDetailsConstant.TOP_STATUS_CARD_LOST_FAILED_BOOKING:
        case BusBookingDetailsConstant.TOP_STATUS_CARD_LOST_FAILED_BOOKING_CANCELLED:
          this.topStatusCardColor = [colors.blackStartColor, colors.blackEndColor];
          views.push(<TopStatusCard
            {...topStatusCardProps}
            iconPath={topStatusFailedIcon}
            color={[colors.blackStartColor, colors.blackEndColor]}
            subText={BusBookingDetailsConstant.TOP_STATUS_CARD_FAILED_SUBTEXT}
          />);
          break;
        case BusBookingDetailsConstant.BUS_ITINERARY_CARD:
          views.push(<ItineraryCard
            key="ItineraryCard"
            response={this.state.responseJson}
            pageName={this.trackPageName}
          />,
        );
        views.push(
          <BusTrackDetail
            response={this.state.responseJson}
            busLiveData={this.props.busLiveData}
            viewState={this.props.viewState}
          />,
        );
        break;
      case BusBookingDetailsConstant.BUS_HOTEL_CROSS_SELL:
        if (getPokusConfig(PokusLobs, abConfigKeyMappings.HotelCrossSell, false)) {
          views.push(
            <HotelCrossSell
              key="HotelCrossSell"
              bookingId={this.bookingId}
              omnitureTrackHandler={this.onClickEventTracker}
            />,
          );
        }
        break;
      case BusBookingDetailsConstant.RETURN_TRIP_OFFER:
          views.push(
            <ReturnTripOfferCard
              response={this.state.responseJson}
              card={card}
            />
            );
          break;
      case BusBookingDetailsConstant.CHANGE_TRAVEL_DATE_CARD:
        if(this.showBusFlexi()){
          views.push(
            <ChangeTravelDate
              response={this.state.responseJson}
              card={card}
              handleClick={() => this.handleOverlay(BusBookingDetailsConstant.DATE_CHANGE_OVERLAY)}
            />,
          );
          break;
        }

      case BusBookingDetailsConstant.BUS_TRAVELLER_CARD:
        views.push(
          <TravellerCard
            key="TravellerCard"
            response={this.state.responseJson}
            pageName={this.trackPageName}
          />,
        );
        break;
      case BusBookingDetailsConstant.BUS_PRICE_CARD:
        views.push(
          <BusPriceCard
            key="BusPriceCard"
            headText={card.heading}
            response={this.state.responseJson}
            pageName={this.trackPageName}
            card={card}
          />,
        );
        break;
      case COMMON_CARD.TRAVEL_CARD:
        if(!isEmpty(this.state.responseJson.travelCardResponse)){
          views.push(
            <TravelCashCard
              key="TravelCashCard"
              travelCardResponse={this.state.responseJson.travelCardResponse}
              uuid={this.state.responseJson.uuid}
              bookingID={this.state.responseJson.bookingId}
            />,
            );
          }
          break;  
      case BusBookingDetailsConstant.TRIP_ASSURANCE_CARD:
        views.push(
          <TripAssuranceCard
            key="TripAssuranceCard"
            response={this.state.responseJson}
            card={card}
          />,
        );
        break;
      case BusBookingDetailsConstant.SUPPORT:
        views.push(
          <SupportCard
            key={card.cardId}
            response={this.state.responseJson}
            card={card}
            pageName={this.trackPageName}
          />,
        );
        break;
      case BusBookingDetailsConstant.SPECIAL_CLAIM_CARD:
        views.push(
          <SpecialClaimCard
            key="RaiseClaim"
            specialClaimDataList={this.state.responseJson.specialClaimDataList}
            specialClaimActionClick={this.raiseClaimClicked}
          />,
        );
        break;
      case BusBookingDetailsConstant.BUS_REFUND_PROGRESS_CARD:
        views.push(this.renderRefundCard(this.state.responseJson));
        break;

      case CHAT_WITH_MYRA_CARD:
        if (isMyraEnabled(this.state.responseJson.lobCode)) {
          views.push(
            <ChatWithMyraCard
              key={card.cardId}
              response={this.state.responseJson}
              card={card}
              pageName={this.trackPageName}
            />,
          );
        }
        break;
      case BusBookingDetailsConstant.BUS_NEED_HELP_CARD:
        views.push(
          <NeedHelpCard
            headText={card.heading}
            key="NeedHelpCard"
            response={this.state.responseJson}
            card={card}
            pageName={this.trackPageName}
          />,
        );
        break;
      case BusBookingDetailsConstant.ALERT_INFO_CARD:
        views.push(
          <AlertInfo key="AlertInfo" infoList={this.state.responseJson.importantInformationList} />,
        );
        break;
      case BusBookingDetailsConstant.MSR_MAIN_CARD:
        views.push(
          <MSRMainCard
            key="MSRMainCard"
            ticketsList={(this.state.responseJson.msrTicketsForBooking || {}).ticketsList}
            card={card}
            pageName={this.trackPageName}
          />,
        );
        break;
      case BusBookingDetailsConstant.CANCELLATION_POLICY_CARD:
        !isEmpty(newCancellationPolicy) ?
        views.push(
          <NewCancellationPolicyCard
            key="NewCancellationPolicyCard"
            newCancellationPolicy={newCancellationPolicy}
            card={card}
            response={this.state.responseJson}
            pageName={this.trackPageName}
            handleOverlay={this.handleOverlay}
            mmtAuth={this.mmtAuth}
            loggingTrackInfo={this.loggingTrackInfo}
          />
        ) :
        views.push(
          <BusCancellationPolicyCard
            key="CancellationPolicyCard"
            headText={card.heading}
            response={this.state.responseJson}
            trackPageName={this.trackPageName}
            card={card}
            mmtAuth={this.mmtAuth}
            loggingTrackInfo={this.loggingTrackInfo}
            confirmCancelOverlay={this.handleOverlay}
          />
        );
        break;
      case BusBookingDetailsConstant.AMENITIES_CARD:
        views.push(
          <AmenityDetails key="AmenityCard" response={this.state.responseJson} card={card} />,
        );
        break;
      case COMMON_CARD.LOYALTY_CARD:
        if (this.state.responseJson.loyaltyDetails) {
          views.push(
            <MMTBlackCard
              {...this.state.responseJson.loyaltyDetails}
              bookingId={this.state.responseJson.bookingId}
              uuid={this.state.responseJson.uuid}
              onClick={this.onMMTBlackCardClickEvent}
              trackingPageName={this.trackPageName}
            />,
          );
        }
        break;
      case COMMON_CARD.BENEFITS_CLAIMED_CARD:
        if (this.state.responseJson.loyaltyDetails) {
          views.push(
            <BenefitsCard
              {...this.state.responseJson.loyaltyDetails}
              key="BenefitsClaimedCard"
              bookingId={this.state.responseJson.bookingId}
              trackingPageName={this.trackPageName}
            />
          );
        }
        break;
      case BusBookingDetailsConstant.BUS_ADD_TECH_CARD:
        !this.state.responseJson.isCorporateBooking && views.push(this.renderAdTechCard());
        break;
      case BusBookingDetailsConstant.ADVISORY_NOTICE:
        views.push(
          <AdvisoryNotice
            key="AdvisoryNoticeCard"
            heading={card.heading}
            description={card.subheading}
            card={card}
            response={this.state.responseJson}
            togglePopUp={this.toggleAdvisoryOverlay}
            langAware={true}
          />,
        );
        break;
      case BusBookingDetailsConstant.TRIP_PASS_CARD:
        views.push(
          <TripPassCard key="TripPassCard" response={this.state.responseJson} card={card} />,
        );
        break;
      case BusBookingDetailsConstant.PRIMO_BANNER:
        views.push(
          <PrimoBanner
            card={card}
            header={card.heading}
            subheader={card.subheading}
            key="BusPrimo"
            showAllFeatures={false}
            response={this.state.responseJson}
          />,
        );
        break;
        case BusBookingDetailsConstant.BUS_INSURANCE_CARD:
          views.push(
            <TravelInsuranceCard
              key={card.cardId}
              card={card}
              response={this.state.responseJson}
              onClickEventTracker={this.onClickEventTracker}
            />
          );
          break;
        case BusBookingDetailsConstant.BUS_RAP_CARD:
            views.push(
              <TripAssuredCard
                key={card.cardId}
                card={card}
                rapInsuranceData={this.state.responseJson.rapInsuranceData}
                specialClaimDataList={this.state.responseJson.specialClaimDataList}
                onClickEventTracker={this.onClickEventTracker}
                raiseClaimClicked={this.raiseClaimClicked}
              />
            );
            break;
        case BusBookingDetailsConstant.ClAIM_REJECTED_CARD:
            views.push(
              <TripAssuredClaimRejected
                key={card.cardId}
                card={card}
                tripAssuredClaimRejectData={this.state.responseJson.tripAssuredClaimRejectData}
                specialClaimDataList={this.state.responseJson.specialClaimDataList}
                onClickEventTracker={this.onClickEventTracker}
                raiseClaimClicked={this.raiseClaimClicked}
              />
            );
            break;
        case BusBookingDetailsConstant.CLAIM_APPROVED_CARD:
            views.push(
              <TripAssuredClaimApproved
                key={card.cardId}
                tripAssuredApprovedData={this.state.responseJson.tripAssuredApprovedData}
              />
            );
        break;
      default:
        break;
    }
  }
  getChangeDateOverlay = () => {
    return (
      <CommonBottomOverlayMessage
        dismiss={this.handleOverlay}
        content={
          <ChangeDateOverlay details={this.state.responseJson} handleOverlay={this.handleDateOverlayButton} handleDateChange={this.handleDateChange} />
        }
      />
    );
  };
  handleBackToSearch = () => {
    this.handleOverlay(null);
    openCalender();
  };
  getBusNotFoundOverlay = () => {
    return (
      <CommonBottomOverlayMessage
        dismiss={this.handleOverlay}
        content={
          <BusNotFoundOverlay
            response={this.state.busSearchResponse}
            handleOverlay={this.handleBackToSearch}
          />
        }
      />
    );
  };
  getConfirmCancelOverlay = () => {
    return (
      <CommonBottomOverlayMessage
        dismiss={this.handleOverlay}
        content={
          <ConfirmCancelOverlay
            response={this.state.responseJson}
            handleOverlay={this.handleConfirmCancelClick}
          />
        }
      />
    );
  };

  getCancellationPolicyOverlay = () => {
    return (
      <NewCommonOverlay handleBottomOverlay={this.handleOverlay} roundBorder={true}>
        <View style={this.styles.OverlayContentWrapper}>
          <CancellationPolicyOverlay
            response={this.state.responseJson}
            handleOverlay={this.handleOverlay}
          />
          </View>
      </NewCommonOverlay>
    )
  }

  onMMTBlackCardClickEvent = (omnitureClickEvent) => {
    BusBookingTrackingHelper.trackClickEvent(
      this.trackPageName,
      this.state.responseJson,
      omnitureClickEvent,
    );
  };

  onClickEventTracker = (omnitureClickEvent) => {
    BusBookingTrackingHelper.trackClickEvent(
      this.trackPageName,
      this.state.responseJson,
      omnitureClickEvent,
    );
  };

  renderAdTechCard = () => {
    return getAdTechCard(POST_SALES_BUS_DETAILS_PAGE);
  };
}

const mapStateToProps = (state) => {
  const { busLiveData, viewState } = state.busBookingDetailReducer;
  return {
    busLiveData,
    viewState,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchLiveTrackingData: (busDetailResponse, mmtAuth, loggingTrackInfo) =>
    dispatch(fetchLiveTrackingData(busDetailResponse, mmtAuth, loggingTrackInfo)),
});

const ConnectedBusBookingDetail =(props)=>{
  return <BusBookingDetail {...props} />
}
export default connect(mapStateToProps, mapDispatchToProps)(ConnectedBusBookingDetail);
