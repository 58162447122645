import { StyleSheet } from 'react-native';

export const getStyle = (fonts, color) => {
  return StyleSheet.create({
    headingSection: {
      paddingHorizontal: 10,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10
    },
    headingText: {
      color: color.black22,
      ...fonts.blackFontFamily,
      fontSize: fonts.fontSize.font24,
      marginBottom: 5,
    },
    crossIconWrapper: {
      width: 30,
      height: 30,
      top: -10,
      right: -5,
      alignItems: 'center',
      justifyContent: 'center',
    },
    crossIconStyle: {
      width: 20,
      height: 20,
    },
    okayButton:{
      backgroundColor:color.white,
      paddingVertical:10,
      justifyContent:'center',
      alignItems:'center',
      marginTop:10,
      marginHorizontal:10,
      borderWidth: 2,
      borderColor: color.azure,
      borderRadius: 8
    },
    footerText: {
      color: color.defaultTextColor,
      ...fonts.regularFontFamily,
      fontSize: fonts.fontSize.font14,
    },
    okayText: {
      color: color.azure,
      ...fonts.boldFontFamily,
      fontSize: fonts.fontSize.font16,
    }
  });
};
