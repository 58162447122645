import React from 'react';
import { useTheme } from '../../../../../theme/theme.context';
import { View, Image, Text, TouchableOpacity, Animated } from 'react-native';
import getStyle from '../../Css/RefundRequestSubmittedCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getFont } from '../../../../../PostSalesStyles';
import Seperator from '../../../../../Common/CardLayoutSeperator';
import { SM_BLUE_RT_ARROW } from '../../../../FlightImageConstants';
import { SPECIAL_CLAIM_ACITONS } from '../../constant';
import { isEmptyArray } from '../../../../../Common/commonUtil';
import {getThankYouPageData} from "../../data/dataAdapter"

const DetailSectionCard = ({
  delayText,
  cardsList, 
  actionLists,
  handleBottomOverlay,
  onBackPress,
  imgMap,
}) => {
  const {psTheme} = useTheme();
  const {color} = psTheme;
  const styles = getStyle(psTheme);
  const { fsStyle, ...fonts } = getFont(true);

  const {
    backToYourBooking,
    refundDetails,
    flightDetails,
    submitSpecialClaim,
    viewMore,
    viewMoreData,
  } = getThankYouPageData(cardsList, actionLists);

  const viewMoreHandler = () => {
    handleBottomOverlay(SPECIAL_CLAIM_ACITONS.view_more, viewMoreData);
  };

  return (
    <View style={styles.detailSection}>
      <View style={[styles.detailCard, AtomicCss.marginBottom15]}>
        <Animated.View style={{ opacity: delayText }}>
          {!!submitSpecialClaim && (
            <View style={styles.detailsTop}>
              <Text
                style={[
                  color.defaultTextColor,
                  fonts.regularFontFamily,
                  fsStyle.font14,
                  AtomicCss.lineHeight20,
                  AtomicCss.marginBottom16,
                ]}
              >
                {submitSpecialClaim.title}
              </Text>
              <View style={[AtomicCss.alignCenter, AtomicCss.flexRow]}>
                {!isEmptyArray(submitSpecialClaim.subtitle) &&
                  submitSpecialClaim.subtitle.map((sub) => (
                    <Text
                      key={sub}
                      style={[
                        color.defaultTextColor,
                        fonts.regularFontFamily,
                        fsStyle.font14,
                        AtomicCss.lineHeight20,
                        AtomicCss.marginRight2,
                      ]}
                    >
                      {sub}
                    </Text>
                  ))}
                <TouchableOpacity onPress={onBackPress} style={AtomicCss.flexRow}>
                  {!!backToYourBooking && (
                    <Text
                      style={[
                        color.azure,
                        fonts.blackFontFamily,
                        fsStyle.font14,
                        AtomicCss.lineHeight20,
                      ]}
                    >
                      {backToYourBooking}
                    </Text>
                  )}
                  <Image style={styles.arrowStyle} source={SM_BLUE_RT_ARROW} />
                </TouchableOpacity>
              </View>
            </View>
          )}
          {!!flightDetails && (
            <View style={styles.segmentCard}>
              {!!flightDetails.title && (
                <Text
                  style={[
                    color.black,
                    fonts.blackFontFamily,
                    fsStyle.font14,
                    AtomicCss.lineHeight20,
                    AtomicCss.marginBottom12,
                  ]}
                >
                  {flightDetails.title}
                </Text>
              )}
              {!isEmptyArray(flightDetails.groupEntries) &&
                flightDetails.groupEntries.map((groupEntry, index) => (
                  <View
                    key={index}
                    style={[
                      styles.segmentHeadingWrapper,
                      index === flightDetails.groupEntries.length - 1
                        ? styles.lastItem
                        : AtomicCss.marginBottom12,
                    ]}
                  >
                    {imgMap[groupEntry.img] && imgMap[groupEntry.img].mobile && (
                      <Image
                        style={styles.logoStyle}
                        source={{ uri: imgMap[groupEntry.img].mobile }}
                      />
                    )}
                    <View>
                      <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
                        <Text
                          style={[
                            color.defaultTextColor,
                            fonts.blackFontFamily,
                            fsStyle.font14,
                            styles.lineHeight24,
                          ]}
                        >
                          {groupEntry.title}{' '}
                        </Text>
                      </View>
                      <Text
                        style={[
                          color.grey,
                          fonts.regularFontFamily,
                          fsStyle.font13,
                          styles.lineHeight16,
                        ]}
                      >
                        {groupEntry.subtitle}
                      </Text>
                    </View>
                  </View>
                ))}
            </View>
          )}
          <View style={styles.seperatorWrapper}>
            <Seperator />
          </View>
          {!!refundDetails && (
            <View style={styles.innerDtls}>
              <View style={[styles.dtlsRow, AtomicCss.marginBottom16]}>
                <Text style={[fsStyle.font14, color.black, AtomicCss.lineHeight18]}>
                  <Text style={[fonts.blackFontFamily]}>{refundDetails.title}</Text>
                  {!isEmptyArray(refundDetails.subtitle) &&
                    refundDetails.subtitle.map((sub) => (
                      <Text key={sub} style={[fonts.boldFontFamily, color.greyLight]}>
                        {sub}
                      </Text>
                    ))}
                </Text>
              </View>
              {!isEmptyArray(refundDetails.entries) &&
                refundDetails.entries.map((entry, index) => (
                  <View key={index} style={[styles.dtlsRow, AtomicCss.marginBottom8]}>
                    <View
                      style={[
                        AtomicCss.flexRow,
                        AtomicCss.alignCenter,
                        AtomicCss.spaceBetween,
                        AtomicCss.flex1,
                      ]}
                    >
                      <Text
                        style={[
                          fonts.regularFontFamily,
                          fsStyle.font14,
                          AtomicCss.lineHeight18,
                          AtomicCss.defaultText,
                        ]}
                      >
                        {entry.title}
                      </Text>
                      {!isEmptyArray(entry.subtitle) &&
                        entry.subtitle.map((sub) => (
                          <Text
                            key={sub}
                            style={[
                              fonts.boldFontFamily,
                              fsStyle.font14,
                              AtomicCss.lineHeight18,
                              color.defaultTextColor,
                            ]}
                          >
                            {sub}
                          </Text>
                        ))}
                    </View>
                  </View>
                ))}
              <TouchableOpacity style={[styles.dtlsRow]} onPress={viewMoreHandler}>
                {!!viewMore && (
                  <Text
                    style={[
                      fonts.blackFontFamily,
                      fsStyle.font14,
                      AtomicCss.lineHeight18,
                      color.azure,
                    ]}
                  >
                    {viewMore}
                  </Text>
                )}
              </TouchableOpacity>
            </View>
          )}
        </Animated.View>
      </View>
    </View>
  );
};

export default DetailSectionCard;
