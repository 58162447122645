import React from 'react';
import HotelBookingTrackingHelper from '../../HotelBookingTrackingHelper';
import { getStaticData } from '../../../staticData/staticData';
import NeedMoreHelpOverlay from '../../../Common/NeedMoreHelpOverlay';
import { NEED_MORE_HELP } from '../../../PostSalesConstant';

export const trackHelpingHandEvent = (type, page, click, data, sourcePage, response) => {
    const pageName = `${sourcePage}_${page}`;
    const trackData = Object.assign({}, response, {ruleTrace: data ? data.ruleTrace : ''});
    switch (type) {
      case 'load':
        HotelBookingTrackingHelper.trackLoadEvent(pageName, trackData);
        break;
      case 'click':
        HotelBookingTrackingHelper.trackClickEvent(pageName, `MI_Hotel_${click}_clicked`, trackData);
        break;
      default:
        break;
    }
}
  
export const getHelpingHandData = (bookingResponse, pageName, trackHelpingHandEvents, langAware = true) => {
  const staticData = getStaticData(langAware);
  return {
      bookingId: bookingResponse && bookingResponse.bookingID,
      pageName: pageName,
      connectWithUsText: staticData.connectWithUsCapsText,
      trackHelpingHandEvents: trackHelpingHandEvents
  };
}

export const renderNeedHelpOverlay = (helpingHandData, setHelpingHandOverlay, hideLoader = false, addPadding = false) => {
  return helpingHandData && (
   <NeedMoreHelpOverlay
      bookingId={helpingHandData.bookingId ? helpingHandData.bookingId : ''}
      handleBottomOverlay={setHelpingHandOverlay}
      handleReload={setHelpingHandOverlay}
      component={NEED_MORE_HELP.HELPING_HAND}
      pageName={helpingHandData.pageName}
      hideLoader={hideLoader}
      trackEvent={helpingHandData.trackHelpingHandEvents}
      addPadding={addPadding}
    />
  )
}