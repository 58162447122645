import {
  DATE_MONTH,
  POSTSALES_DATE_TIME,
  reformatDateString,
} from '@mmt/legacy-commons/Helpers/dateTimehelpers';
import myTripsNavigation from '../../../navigation/navigation';
import upcomingBookingIcon from '@mmt/legacy-assets/src/postSales/summary/upcomingGiftCardIcon.webp';
import completedBookingIcon from '@mmt/legacy-assets/src/postSales/summary/completedGiftCardIcon.webp';
import cancelledBookingIcon from '@mmt/legacy-assets/src/postSales/summary/cancelledGiftCardIcon.webp';
import failedBookingIcon from '@mmt/legacy-assets/src/postSales/summary/failedGiftCardIcon.webp';

import { Constant } from '../../../summaryConstants';
import { getStaticData } from '../../../../staticData/staticData';
import {
  getDateTimeWithPaxInfo,
  unsuccessfulBookingTextHandler,
} from '../../../data/tripListManager';
import { msrUpdateAction } from '../../../../utils/MsrUtils';
import { getNeedHelpData } from '../../../utils';

function getGiftCardSummaryData(data, msrTicket) {
  const staticData = getStaticData();
  const issueDate = reformatDateString(data.minDeptDate, POSTSALES_DATE_TIME, DATE_MONTH);
  const [giftCardDetails] = data.giftCardDetails;
  const pageData = {
    fromMyTrips: true,
  };
  const openDetails = () => {
    myTripsNavigation.openGiftCardBookingDetail(data, pageData);
  };

  const actions = [];
  const failedBooking = data.status === 'FAILED';
  const msrAction = msrUpdateAction(msrTicket);
  if (msrAction) {
    actions.push(msrAction);
  }
  const needHelpData = getNeedHelpData(msrTicket, data.bookingID, data.needHelpData, data.showQuickResolve);
  let dateTime = `${Constant.PURCHASED_ON} ${issueDate}`;
  let tripType = `${giftCardDetails.tripType}`;

  if (data.status === 'FAILED') {
    dateTime = getDateTimeWithPaxInfo(data) || unsuccessfulBookingTextHandler(dateTime);
    tripType = staticData.bookingSummaryText.additionalText.giftCardBookingFailedText;
  }

  let iconUrl = '';
  switch (data.status) {
    case 'UPCOMING':
      iconUrl = upcomingBookingIcon;
      break;
    case 'COMPLETED':
      iconUrl = completedBookingIcon;
      break;
    case 'CANCELLED':
      iconUrl = cancelledBookingIcon;
      break;
    case 'FAILED':
      iconUrl = failedBookingIcon;
      break;
    default:
      iconUrl = upcomingBookingIcon;
      break;
  }

  return {
    dateTime,
    iconUrl,
    giftCardName: `${giftCardDetails.giftCardName}`,
    tripType,
    persuasions: [],
    actions,
    status: data.status,
    openDetails,
    failedBooking,
    needHelpData,
  };
}
export default getGiftCardSummaryData;
