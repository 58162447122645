import {colors,fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from '@mmt/legacy-commons/Common/Components/Card/getPlatformElevation';

const styles = {
  MamiCardGrey: {
    backgroundColor: '#ddd',
    paddingLeft: 10,
    paddingRight: 10
  },
  CardContentFull: {
    backgroundColor: colors.white,
    padding: 15,
    paddingBottom: 0
  },
  CardContent: {
    backgroundColor: colors.white,
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 20
  },
  CardIcon: {
    width: 24,
    height: 24,
    marginRight: 10
  },
  marL10per: {paddingLeft: '10%'},
  messageIcon: {width: 16, height:16, marginRight: 8},
  callIcon: {width: 16, height: 16, marginRight: 8},
  elevation1:{
    backgroundColor: '#ffffff',
    ...getPlatformElevation(1),
  },
redirectBtnWrapper:
  {
    ...getPlatformElevation(2),
    marginBottom:2
  },

  redirectBtn:{
    paddingTop:15,
    paddingBottom:15,
    paddingLeft:50,
    paddingRight:35,
    justifyContent:'space-between',
    flexDirection:'row',
    backgroundColor:'#fff',
    alignItems:'center',
    borderTopWidth:1,
    borderTopColor:'#ddd'
  },

  rightArrowIcon:{
    width:9,
    height:13
  },

  linkBtnTxt:{
    fontSize:14,
    fontFamily:fonts.bold,
    color:"#008cff"
  },
};

export default styles;
