import React from "react";
import { Image, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import Button from "../../../Common/Button";
import CommonBottomOverlayMessage from '../../../Common/CommonOverlayMessage/CommonBottomOverlayMessage';
import { CALENDER_ICON } from "../../HotelImageConstants";
import { getFont } from "apps/post-sales/src/PostSalesStyles";

const ModificationCardContent = React.memo(({ heading, subHeading, ctaText, ctaHandler, dismiss }) => {
  const { fsStyle, ...fonts } = getFont(true);
  return (
    <View>
      <TouchableOpacity onPress={dismiss} >
        <View style={styles.overlayTopline}></View>
      </TouchableOpacity>
      <View>
        <View style={[AtomicCss.marginBottom20]}><Image source={CALENDER_ICON} style={styles.imgCalendarIcon} /></View>
        <View>
          <Text style={[fsStyle.font24, AtomicCss.blackText, fonts.blackFontFamily, AtomicCss.marginBottom20]}>{heading}</Text>
          <Text style={[fsStyle.font14, AtomicCss.blackText, fonts.regularFontFamily, styles.lineHeight25]}>{subHeading}</Text>
        </View>
        <View style={styles.buttonWrap}>
          <Button fontSize={16} clickHandler={ctaHandler} btnBg="gradientBtn" btnType="flat" btnTxt={ctaText} />
        </View>
      </View>
    </View>
  )
});

const ModificationOverlay = (props) => {
  return (
    <CommonBottomOverlayMessage customStyle={AtomicCss.borderTopRadius16} dismiss={props.dismiss} content={
      <ModificationCardContent {...props} />
    } />
  )
}

export default React.memo(ModificationOverlay);

const styles = StyleSheet.create({
  overlayTopline: {
    width: 40,
    height: 4,
    borderRadius: 4,
    backgroundColor: '#dddddd',
    alignSelf: 'center',
  },
  imgCalendarIcon: {
    width: 48,
    height: 48
  },
  buttonWrap: {
    marginTop: 50
  },
  lineHeight25: {
    lineHeight: 25
  }
});