import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import {Image, Text, TouchableOpacity, View} from 'react-native';
import getStyles from '../ActionBtnCss';
import { imageTransformY } from '../../utils/PostSaleUtil';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { CHEVRON_RIGHT_BLUE_ICON3 } from '../commonImageConstants';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const RectFullButton = ({action, clickHandler, isCorporateBooking = false, fontFamily = null, lowerRound = false, upperRound = false, buttonRadiusStyle={},  isThrottleRequired= false, throttleInterval = 1000}) => {
  const { fsStyle } = getFont(true);
  const lastClickTimeRef = useRef(0);
  const styles = getStyles();
  const throttledClickHandler = useCallback(() => {
    const currentTime = Date.now();
    if (currentTime - lastClickTimeRef.current >= throttleInterval) {
      lastClickTimeRef.current = currentTime;
      clickHandler(action);
    }
  }, [clickHandler, throttleInterval]);

  const wrapperThrottle = () => {
    if (isThrottleRequired) {
      throttledClickHandler();
    } else {
      clickHandler(action);
    }
  };
  return (<TouchableOpacity onPress={ wrapperThrottle }>
    <View style={[isCorporateBooking ? AtomicCss.blueMyBizBg : styles.RecFullBtn, lowerRound? buttonRadiusStyle:"", upperRound? buttonRadiusStyle:""]}>
      <Text style={[isCorporateBooking ? AtomicCss.myBizBtnTextStyle : styles.BlueText, fontFamily, fsStyle.font14, {paddingRight: 1}]}>{action.actionLabeltext}</Text>
      <Image style={[styles.RecFullBtnArrow, imageTransformY]} source={CHEVRON_RIGHT_BLUE_ICON3}/>
    </View>
  </TouchableOpacity>)};


RectFullButton.propTypes = {
  action: PropTypes.object.isRequired,
  clickHandler: PropTypes.func.isRequired
};

export default RectFullButton;
