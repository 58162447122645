import { View, StyleSheet, Dimensions } from 'react-native';

export default (theme, fonts) => {
  const { color } = theme;
  return StyleSheet.create({
    headingWrapper: {
      paddingHorizontal: 16,
      marginTop: 20,
    },
    confirmedTextStyle: {
      color: color.green8,
    },
    completedTextStyle: {
      color: color.textGrey,
    },
    modifiedTextStyle: {
      color: color.darkBrown,
      textAlign: 'center',
      fontSize: fonts.fontSize.font16,
    },
    failedTextStyle: {
      color: color.red29,
      textAlign: 'center',
      fontSize: fonts.fontSize.font16,
    },
    cancelledTextStyle: {
      color: color.darkBrown,
      fontSize: fonts.fontSize.font14,
    },
    awaitedTextStyle: {
      color: color.darkBlue13,
      paddingBottom: 12,
      textAlign: 'center',
      fontSize: fonts.fontSize.font16,
    },
    title:{
      fontSize: fonts.fontSize.font14,
      ...fonts.blackFontFamily,
    },

    subtitle:{
      fontSize: fonts.fontSize.font14,
      ...fonts.regularFontFamily,
    },
  });
};
