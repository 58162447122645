import React from 'react';
import PropTypes from 'prop-types';
import {View, Text, Image, TouchableOpacity, StyleSheet} from 'react-native';
import AtomicCss from '../../commonStyles/AtomicCss';
import {passengerImage, passengerName, passengerAgeAndGenderText, seatNumberInfo} from '../../utils/BusBookingDetailUtil';
import {chkboxicon, chkboxiconActive, seatIcon} from '../../BusImageConstants';
import { getFont } from '../../../PostSalesStyles';

class checkboxRow extends React.Component {
  constructor(props) {
    super(props);
    this.passenger = this.props.passenger;
    this.state = {
      chkboxicon: true
    };
    this.SelectRoom = this.SelectRoom.bind(this);
  }

  SelectRoom() {
    this.setState({
      chkboxicon: !this.state.chkboxicon
    });
    this.props.onPaxSelection(this.passenger, this.state.chkboxicon);
  }

    static navigationOptions = {
      header: null
    };

    render() {
      const fonts = getFont(true);
      return (
        <TouchableOpacity
          style={[AtomicCss.flexRow, styles.borderbtm, styles.PickAppCard]}
          onPress={this.SelectRoom}
        >
          <Image
            style={styles.chkboxicon}
            source={this.state.chkboxicon ? chkboxicon : chkboxiconActive}
          />
          <View>
            <View style={[AtomicCss.spaceBetween, AtomicCss.flexRow,
                        AtomicCss.alignCenter, AtomicCss.marginBottom10,
                        AtomicCss.marginTop5, AtomicCss.marginRight10]}
            >
              <Text style={[AtomicCss.blackText, fonts.font14,
                            fonts.boldFontFamily, styles.CheckBoxContent]}
              >
                {passengerName(this.passenger)}
              </Text>
            </View>
            <View style={[AtomicCss.paddingBottom8, AtomicCss.flexRow]}>
              <View style={[AtomicCss.marginRight10, AtomicCss.flexRow, styles.genderYearWidth]}>
                <Image style={styles.maleIcon} source={passengerImage(this.passenger)} />
                <Text style={[fonts.font12, fonts.regularFontFamily, AtomicCss.defaultTextColor]}>
                  {passengerAgeAndGenderText(this.passenger)}
                </Text>
              </View>
              <View style={AtomicCss.flexRow}>
                <Image style={styles.seatIcon} source={seatIcon} />
                <Text style={[fonts.font12, fonts.regularFontFamily,
                                AtomicCss.defaultTextColor, AtomicCss.marginRight10]}
                >{seatNumberInfo(this.passenger)}
                </Text>
              </View>
            </View>
          </View>

        </TouchableOpacity>
      );
    }
}

const styles = StyleSheet.create({
  borderbtm: {
    borderBottomWidth: 1,
    borderBottomColor: '#ddd'
  },
  PickAppCard: {
    paddingHorizontal: 15,
    paddingVertical: 15,
    backgroundColor: '#ffffff'
  },
  chkboxicon: {
    width: 28,
    height: 28,
    marginRight: 8,
    marginTop: 5,
    backgroundColor: 'transparent'
  },
  CheckBoxContent: {
    width: '60%'
  },
  confirmed: {
    fontSize: 12,
    color: '#00a19c',
    textAlign: 'right'
  },

  status: {width: '30%'},
  maleIcon: {width: 16, height: 16, marginRight: 5},
  seatIcon: {width: 16, height: 20, marginRight: 5},
  genderYearWidth: {width: '40%'}

});

checkboxRow.propTypes = {
  passenger: PropTypes.object.isRequired,
  onPaxSelection: PropTypes.func.isRequired
};

export default checkboxRow;
