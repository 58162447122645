import React from 'react';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';

import { View, Text } from 'react-native';
import DealTag from './DealTag';
import { isEmpty } from 'lodash';
import BottomSemiCircle from './BottomSemiCircle';
import OfferCard from './OfferCard';
import HotelCardList from './HotelCardList';
import { useTheme } from '../../../theme/theme.context';
import createStyles from './hotelCrossSellCss';
import { getFont } from '../../../PostSalesStyles';
import { HotelCrossSellProps } from './hotelType';

const HotelCrossSell: React.FC<HotelCrossSellProps> = (props) => {
  const { hotelData, omnitureTrackHandler } = props;
  const { headerText, exploreButtonText } = hotelData;
  const { headerData, data } = hotelData.data?.BASE_SHEET?.apiData?.HOTEL_CROSS_SELL || {};
  const { header } = headerData || {};
  const { tabsData } = data || {};
  const { HOTELS } = tabsData || {};
  const { hotelList, offerCard } = HOTELS || {};
  const { offerImg, offerMainImag, deeplink } = offerCard || {};
  const { psTheme: theme } = useTheme();
  const fonts = getFont(true);
  const styles = createStyles(theme);
  if (isEmpty(hotelList)) {
    return null;
  }

  return (
    <View style={styles.crossSellCard}>
      <DealTag imgUrl={offerImg} />
      <View style={styles.innerDetails}>
        <Text
          style={[
            fonts.blackFontFamily,
            AtomicCss.blackText,
            fonts.font14,
            AtomicCss.marginBottom10,
          ]}
        >
          {header}
        </Text>
        <View style={[AtomicCss.marginBottom10]}>
          <HotelCardList
            hotelList={hotelList}
            header={headerText}
            omnitureTrackHandler={omnitureTrackHandler}
          />
          {isEmpty(offerMainImag) ? null : (
            <OfferCard
              offerImg={offerMainImag}
              deeplink={deeplink}
              buttonText={exploreButtonText}
              omnitureTrackHandler={omnitureTrackHandler}
            />
          )}
        </View>
      </View>
      <BottomSemiCircle />
    </View>
  );
};

export default HotelCrossSell;
