/* eslint-disable object-curly-spacing */
import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, StyleSheet, Platform } from 'react-native';

import { colors } from '../../../Styles/globalStyles';
import TouchableRipple from '../TouchableRipple';
import Label from '../Label/label';

import { getKey, getDisplayText, isSelected, _onPress } from './radio.utils';

export default function TabbedRadio(props) {
  const {
    label,
    options = [],
    keyResolver,
    displayTextResolver,
    selected,
    selectedValueResolver,
    onSelect,
    valueResolver
  } = props;
  return (
    <View testID={props?.id}>
      {label && <Label label={label} />}
      <View style={styles.tabsContainer}>
        {options.map((option, index) => {
          const isOptionSelected = isSelected(option, selected, selectedValueResolver);
          return (
            <TouchableRipple key={getKey(option, keyResolver)} onPress={_onPress(option, valueResolver, onSelect)}>
              <View style={getTabStyles(index, options, isOptionSelected)} testID={` ${props?.id}_${index === 0 ? 'male' : 'female'}`}>
                <Text style={isOptionSelected ? styles.selectedText : styles.optionText}>
                  {getDisplayText(option, displayTextResolver)}
                </Text>
              </View>
            </TouchableRipple>
          );
        })}
      </View>
    </View>
  );
}

function getTabStyles(index, options = [], isOptionSelected) {
  let style = styles.tabs;
  if (index === 0) {
    style = [style, styles.firstTab];
  }
  if (index === options.length - 1) {
    style = [style, styles.lastTab];
  }
  if (isOptionSelected) {
    style = [style, styles.selectedTab];
  }
  return style;
}

const styles = StyleSheet.create({
  tabsContainer: {
    flexDirection: 'row'
  },
  tabs: {
    borderWidth: 1,
    borderRightWidth: 0.5,
    borderLeftWidth: 0.5,
    borderColor: colors.lightTextColor,
    marginTop: Platform.select({android: 3}),
    width: 80,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center'
  },
  firstTab: {
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    borderLeftWidth: 1
  },
  lastTab: {
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    borderRightWidth: 1
  },
  selectedTab: {
    borderBottomColor: colors.azure,
    borderBottomWidth: 2
  },
  optionText: {
    color: colors.defaultTextColor
  },
  selectedText: {
    color: colors.azure
  }
});

TabbedRadio.propTypes = {
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  keyResolver: PropTypes.func, // eslint-disable-line react/require-default-props
  valueResolver: PropTypes.func, // eslint-disable-line react/require-default-props
  displayTextResolver: PropTypes.func, // eslint-disable-line react/require-default-props
  selected: PropTypes.any, // eslint-disable-line react/require-default-props
  selectedValueResolver: PropTypes.func // eslint-disable-line react/require-default-props
};

TabbedRadio.defaultProps = {
  label: ''
};
