import {StyleSheet} from 'react-native';

export default (theme, fonts) => 
StyleSheet.create({
    AlignLeft: {
        textAlign: 'left'
      },
      refundCardOuter: {
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 10,
        paddingTop: 10
      },
      flexRow: {
        flexDirection: 'row'
      },
      refundCardWrap: {
        flexDirection: 'row',
        justifyContent: 'space-between'
      },
      BlackText: {
        color: theme.color.black
      },
      refundCardLeft: {
        width: '75%'
      },
      instantRefundTag: {
        height: 18,
        borderRadius: 8,
        width: 115,
        alignItems: 'center',
        justifyContent: 'center'
      },
      instantRefundTagTxxt: {
        fontFamily: fonts.bold,
        fontSize: 10,
        color: theme.color.white,
        alignSelf: 'center'
      },
});