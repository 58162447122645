import isEmpty from 'lodash/isEmpty';
import myTripsNavigation from '../../../navigation/navigation';
import upcomingBookingIcon from '@mmt/legacy-assets/src/postSales/summary/upcomingExpeIcon.webp';
import completedBookingIcon from '@mmt/legacy-assets/src/postSales/summary/completedExpeIcon.webp';
import cancelledBookingIcon from '@mmt/legacy-assets/src/postSales/summary/cancelledExpeIcon.webp';
import failedBookingIcon from '@mmt/legacy-assets/src/postSales/summary/failedExperienceIcon.webp';
import { isNonEmpty } from '@mmt/legacy-commons/Common/utils/StringUtils';
import { Constant } from '../../../summaryConstants';
import { getStaticData } from '../../../../staticData/staticData';
import {
  DATE_MONTH,
  POSTSALES_DATE_TIME,
  reformatDateString,
} from '@mmt/legacy-commons/Helpers/dateTimehelpers';
import {
  getDateTimeWithPaxInfo,
  unsuccessfulBookingTextHandler,
} from '../../../data/tripListManager';
import { titleCase } from '@mmt/legacy-commons/Helpers/stringHelpers';
import { msrUpdateAction} from '../../../../utils/MsrUtils';
import { getNeedHelpData } from '../../../utils';

export function getActivitySummaryData(data, msrTicket) {
  const staticData = getStaticData();
  const { acmeDetails, status } = data;
  const [acme1] = acmeDetails;
  const actions = [];
  const failedBooking = status === 'FAILED';
  const pageData = {
    fromMyTrips: true,
  };
  const openDetails = () => {
    myTripsNavigation.openActivityBookingDetails(data, pageData);
  };

  const msrAction = msrUpdateAction(msrTicket);
  if (msrAction) {
    actions.push(msrAction);
  }
  let cityName = '';
  let dateTimeWithPaxInfo = '';
  if (!isEmpty(acme1) && isNonEmpty(acme1.cityName)) {
    cityName = `${Constant.EXPERIENCE_IN} ${acme1.cityName}`;
  }

  if (!isEmpty(acme1) && isNonEmpty(acme1.startDateTime) && isNonEmpty(data.leadPaxFirstName)) {
    const startDateTime = reformatDateString(acme1.startDateTime, POSTSALES_DATE_TIME, DATE_MONTH);
    dateTimeWithPaxInfo = `${startDateTime} • ${titleCase(data.leadPaxFirstName)}`;
  }
  const needHelpData = getNeedHelpData(msrTicket, data.bookingID, data.needHelpData, data.showQuickResolve);

  let iconUrl = '';
  switch (data.status) {
    case 'UPCOMING':
      iconUrl = upcomingBookingIcon;
      break;
    case 'COMPLETED':
      iconUrl = completedBookingIcon;
      break;
    case 'CANCELLED':
      dateTimeWithPaxInfo = getDateTimeWithPaxInfo(
        data,
        staticData.cancelledText,
        dateTimeWithPaxInfo,
        titleCase(data.leadPaxFirstName),
      );
      iconUrl = cancelledBookingIcon;
      break;
    case 'FAILED':
      iconUrl = failedBookingIcon;
      dateTimeWithPaxInfo =
        getDateTimeWithPaxInfo(data) || unsuccessfulBookingTextHandler(dateTimeWithPaxInfo);
      cityName = staticData.bookingSummaryText.additionalText.experienceBookingFailedText;
      break;
    default:
      iconUrl = upcomingBookingIcon;
      break;
  }

  return {
    status: data.status,
    iconUrl: iconUrl,
    cityName,
    productName: !isEmpty(acme1) ? acme1.productName : '',
    persuasions: [],
    actions,
    openDetails,
    dateTimeWithPaxInfo,
    failedBooking,
    needHelpData,
  };
}

export default {};
