import React from 'react';
import Persuasion from '@Frontend_Ui_Lib_App/Persuasion/lib/Persuasion';
import { StyleSheet } from 'react-native';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { Fonts, Theme, RescheduleAwaitingCardProps } from 'apps/post-sales/src/cab/types';

const RescheduleAwaitingCard: React.FC<RescheduleAwaitingCardProps> = ({ cardData }) => {
  const { rescheduleRequestedText, imageUrl, backgroundColor } = cardData || {};
  const { psTheme } = useTheme();
  const { fsStyle, ...fonts } = getFont();
  const styles = getStyle(fonts, psTheme);

  return (
    <Persuasion
      content={rescheduleRequestedText}
      bgColor={[backgroundColor]}
      startIcon={imageUrl}
      customStyle={styles}
      startIconHolderStyle={[styles.startIconHolderStyle]}
    />
  );
};

const getStyle = (fonts: Fonts, theme: Theme) => {
  return StyleSheet.create({
    container: {
      borderRadius: 16,
      marginVertical: 5,
      marginHorizontal: 10,
    },
    wrapperStyle: {
      borderRadius: 8,
      marginVertical: 5,
      marginHorizontal: 10,
    },
    startIconHolderStyle: {
      width: 24,
      height: 24,
    },
  });
};

export default RescheduleAwaitingCard;
