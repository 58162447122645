import React from 'react';
import {connect} from 'react-redux';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss'
import createStyles from "./travellerDetailsCardCss"
import isEmpty from 'lodash/isEmpty';
import { PAX_ICON, REFRESH_ICON } from '../../../../Utils/tripGuaranteeImageConstants';
import PaxStatusCard from '../PaxStatusCard';
import { getFont } from '../../../../../PostSalesStyles';
import { useTheme } from '../../../../../theme/theme.context';
import { fetchPnrStatusInfo } from '../../../../../Rails/RailAction/RailInfoActionService';
import TGSBookingTrackEventHelper from '../../../../Utils/TGSBookingTrackEventHelper';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import { CLICK_TYPE, PAGE_NAME } from '../../../../Utils/TGSBookingConstants';
const TravellerDetailsCard = (props) => {
    const {card, response ,allowRefresh,passengerDetails,fetchPnrStatusInfo,showPnrStatusLodar,lastUpdateTime,lastbookingId} = props || {};
    const { passengerDetailsInfo } = response || {};
    const {headerInfo} = response || {};
    const {pnrNo,booking} = headerInfo || {};
    const {bookingID} = booking || {};
    const { heading, subHeading } = card || {};
    const fonts = getFont()
    const {psTheme : theme} = useTheme();
    const styles = createStyles(theme,fonts);
    const handleRefresh = async () =>{
        TGSBookingTrackEventHelper.trackClickEvents(PAGE_NAME.TRIP_DETAILS, CLICK_TYPE.REFERESH_PNR_ICON_CLICK);
        if(!!pnrNo  && !!bookingID){
        const userInfo = await HotelBookingModule.getRequestHeader(bookingID, 'TGS_BOOKING_DETAIL');
        if(!isEmpty(userInfo))
        fetchPnrStatusInfo(pnrNo,bookingID,userInfo.mmtAuth,userInfo.loggingTrackingInfo)
        }
    }
    const showRefreshIcon = () =>{
        if(!allowRefresh && lastbookingId){
          return bookingID !== lastbookingId;
        }
        return true;
    }
    return (
        <>
            <View style={[AtomicCss.flexRow, AtomicCss.marginBottom20]}>
                <View style={styles.iconWrapper}>
                    <Image style={styles.paxIconStyle} source={PAX_ICON} />
                </View>
                <View style={AtomicCss.flex1}>
                    {!!heading &&
                        <Text style={[AtomicCss.font14, fonts.blackFontFamily, AtomicCss.blackText, AtomicCss.marginBottom6]}>{heading}</Text>}
                    <View style={AtomicCss.flexRow}>
                        {!!lastUpdateTime && (!!lastbookingId && bookingID === lastbookingId)  ?
                            <Text style={[AtomicCss.font14, fonts.regularFontFamily, AtomicCss.blackText]}>{lastUpdateTime}</Text>
                            :  !!subHeading && <Text style={[AtomicCss.font14, fonts.regularFontFamily, AtomicCss.blackText]}>{subHeading}</Text>}
                        {showRefreshIcon() && <TouchableOpacity onPress={handleRefresh}>
                            <Image style={styles.refreshIconStyle} source={REFRESH_ICON} />
                        </TouchableOpacity>}
                    </View>
                </View>
            </View>
            {!isEmpty(passengerDetailsInfo) ? <View style={styles.detailsSection}>
                <PaxStatusCard
                passengerDetailsInfo={passengerDetailsInfo}
                fetchedPassengerDetails={passengerDetails}
                showPnrStatusLodar={showPnrStatusLodar}
                travellerCard ={true} />
            </View> : null}

        </>
    )
}

const mapStatesToProps = (state, ownProps) => {
    const {
        passengerDetails,
        showPnrStatusLodar,
        lastUpdateTime,
        allowRefresh,
        lastbookingId
    } = state.trainReducer;
    return {
        ...ownProps,
        passengerDetails,
        showPnrStatusLodar,
        lastUpdateTime,
        allowRefresh,
        lastbookingId
    };
};

const mapDispatchToProps = dispatch => ({
    fetchPnrStatusInfo: (pnr, bookingId, mmtAuth, loggingTrackInfo) => dispatch(fetchPnrStatusInfo(pnr, bookingId, mmtAuth, loggingTrackInfo))
});

export default connect(mapStatesToProps, mapDispatchToProps)(TravellerDetailsCard)
