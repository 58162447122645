import React from 'react';
import {View, Text, Image, TouchableOpacity} from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getStyles from './BottomOverlayDetailsCss';
import { CROSS_ICON1 } from '../../HotelImageConstants';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const CloseIcon = CROSS_ICON1;

const BottomOverlayDetails = ({onCloseOverlay, infoList, heading}) => {
    const { ...fonts } = getFont(true);
    const styles = getStyles(fonts);
    return (
        <View style={{marginBottom: 30}}>
            <TouchableOpacity style={styles.crossWrapper} onPress={()=> onCloseOverlay()}>
                <Image style={styles.closeIconStyle} source={CloseIcon}/>
            </TouchableOpacity>
            <View style={AtomicCss.alignItemsStart}><Text style={styles.contentTitle}>{heading}</Text></View>
            {infoList && infoList.map(info => (
                <View style={[AtomicCss.flexRow,AtomicCss.marginBottom10]}>
                    <View style={styles.bulletIcon}></View>
                    <Text style={styles.contentDesc}>{info}</Text>
                </View>
            ))}
        </View>
    );
}


export default BottomOverlayDetails;