import React from 'react';
import { View, Text, Image } from 'react-native';
import { getFont } from '../../../../../PostSalesStyles';
import BusCancelRebookConstant from '../../../CancelRebookConstants';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { TRAVELLER_ICON } from '../../../../BusImageConstants';
import createStyles from './TravellerIconCSS';
import { useTheme } from '../../../../../theme/theme.context';
import isEmpty  from 'lodash/isEmpty';
const TravellerCard = ({ reviewData }) => {
  const { fsStyle, ...fonts } = getFont();
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme);
  const { passengerDetails } = reviewData || {};
  if(isEmpty(passengerDetails)){
    return null
  }
  return (
    <>
      {passengerDetails.map((passenger, index) => {
        const { name, gender, seatNumber, age } = passenger || {};
        return (
          <View key={`${index + '' + seatNumber}`}>
            <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom12]}>
              <View style={styles.travellerIconWrapper}>
                <Image source={TRAVELLER_ICON} style={styles.travellerIcon} />
              </View>
              <Text style={[fsStyle.font13, fonts.boldFontFamily, AtomicCss.blackText]}>
                {BusCancelRebookConstant.TRAVELLER_TEXT}
              </Text>
            </View>
            <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween, AtomicCss.alignCenter]}>
              <View>
                {!!name && (
                  <Text
                    style={[
                      fsStyle.font16,
                      AtomicCss.defaultText,
                      AtomicCss.blackText,
                      AtomicCss.marginBottom3,
                    ]}
                  >
                    {name}
                  </Text>
                )}
                <View style={AtomicCss.flexRow}>
                  {!!gender && (
                    <Text style={[fsStyle.font10, fonts.regularFontFamily, AtomicCss.lightText]}>
                      {gender},
                    </Text>
                  )}
                  {!!age && (
                    <Text style={[fsStyle.font10, fonts.regularFontFamily, AtomicCss.lightText]}>
                      {age}
                      {BusCancelRebookConstant.YEAR}
                    </Text>
                  )}
                </View>
              </View>
              {!!seatNumber && (
                <Text style={[fsStyle.font12, fonts.regularFontFamily, AtomicCss.defaultText]}>
                  {BusCancelRebookConstant.SEAT_NO}
                  {seatNumber}
                </Text>
              )}
            </View>
          </View>
        );
      })}
    </>
  );
};

export default TravellerCard;
