import React from 'react';
import { View, Text, ScrollView, TouchableOpacity, Image, Platform } from 'react-native';
import Actions from '../../../../navigation/postSalesNavigation';
import LinearGradient from 'react-native-linear-gradient';
import AtomicCss from '../../../commonStyles/AtomicCss';
import IssueInfoCard from './IssueInfoCard';
import DescribeIssueCard from './DescribeIssueCard';
import AmenityIssueCard from './AmenityIssueCard';
import ACIssueCard from './ACIssueCard';
import UploadCard from './UploadCard';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import StickyHeader from '../StickyHeader';
import getStyles from '../../../styles/BusMamiCss';
import BusBookingDetailsConstant from '../../../BusBookingDetailsConstant';
import BusBookingTrackingHelper from '../../../BusBookingTrackingHelper';
import SpecialClaimAddAttachmentModule from '@mmt/legacy-commons/Native/SpecialClaimAddAttachmentModule';
import NoNetworkView from '../../../../Common/Cancellation/NoNetworkView';
import PageLoadingError from '../../../../Common/Cancellation/PageLoadingError';
import { showShortToast } from '@mmt/legacy-commons/Common/Components/Toast';
import {rightArrow, headerIcon} from '../../../BusImageConstants';
import { label } from '@mmt/post-sales/src/staticData/staticData';
import { PageName } from './../../../BusBookingDetailsConstant';

class IssueDetail extends React.Component {

  constructor(props) {
    super(props);
    this.pageName = 'Issue Detail';
    this.pageTitle = label('bus.issue.pageTitle');
    this.state = {
      issueList: props.issueList,
      description: '',
      viewState: ViewState.LOADING,
      acIssueOption: '',
      attachmentList:  (Platform.OS === "web") ? ([]) : (new Map()),
      amenitiesList: []
    }
    this.mmtAuth = '';
  }

  componentDidMount() {
    this.setState({ viewState: ViewState.SHOW_DETAIL });
  }

  goBack = () => {
    Actions.pop();
  };

  pageSubTitle = () => {
    const styles = getStyles();
    return <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
      <Text style={[AtomicCss.boldFont, AtomicCss.font14, AtomicCss.defaultText]}>{this.props.response.busBookingInfo.busDetail.fromCity}</Text>
            <Image style={styles.rtArrowStyle} source={rightArrow} />
      <Text style={[AtomicCss.boldFont, AtomicCss.font14, AtomicCss.defaultText]}>{this.props.response.busBookingInfo.busDetail.toCity}</Text>
      <Text style={[AtomicCss.boldFont, AtomicCss.font14, AtomicCss.defaultText]}> | {this.props.response.busAdditionalInfo.departureDetail.departureDayInMonth} {this.props.response.busAdditionalInfo.departureDetail.departureMonth}</Text>
    </View>
  }

  retry = () => {
    //this.submitIssue();
  }

  onClickEventTracker = (omnitureClickEvent) => {
    BusBookingTrackingHelper.trackClickEvent(
      PageName.TRIP_DETAILS,
      undefined,
      omnitureClickEvent,
    );
  };


  refreshDetail = () => {
    this.setState({ viewState: ViewState.LOADING });
  }

  render() {
    return (
      <View style={AtomicCss.flex1}>
        {/* {this.state.viewState === ViewState.LOADING && <PageLoader updateAPIData={this.retry} />} */}
        {this.state.viewState === ViewState.NO_INTERNET && <NoNetworkView retryFunction={this.refreshDetail} />}
        {this.state.viewState === ViewState.ERROR && <PageLoadingError retryFunction={this.refreshDetail} />}
        {this.state.viewState === ViewState.SHOW_DETAIL && this.renderPage()}
      </View>
    );
  }

  getAttachmentBase64String() {
    const result = [];
    this.state.attachmentList.forEach((obj) => {
      result.push(obj.filePath);
    }, this);

    let imagePath = result[0];

    let imageResponse = '';

    if (Platform.OS === 'ios' || Platform.OS === 'web') {
      this.state.attachmentList.forEach((obj) => {
        if (obj.filePath === imagePath) {
          imageResponse = obj.data;
        }
      }, this);
    } else {
      imageResponse = SpecialClaimAddAttachmentModule.convertToBase64(imagePath);
    }

    return imageResponse;
  }

  prepareSubmitRequest() {

    let description = this.state.description;
    if (this.state.amenitiesList && this.state.amenitiesList.length > 0)
      description = description + ` | ${label('bus.claim.amenitiesListNotProvided')}-` + this.state.amenitiesList.map(amenity => (' ' + amenity.name));

    if (this.state.acIssueOption)
      description = description + ` | ${label('bus.claim.acNotWorking')} ` + this.state.acIssueOption;

    let issueList = this.groupByIssueForRequest(this.state.issueList, description);
    let attachmentBase64Str = this.getAttachmentBase64String();

    let attachmentReqList = [];
    attachmentReqList.push(attachmentBase64Str);

    return {
      bookingId: this.props.response.bookingId,
      actionType: "CreateTicket",
      specialClaim: true,
      description: description,
      attachment: attachmentReqList,
      issue: issueList
    };
  }

  trackError = () => {
    BusBookingTrackingHelper.trackErrorEvent('Bus_Support_Issue_Submit');
  }

  getIssueBreadCumb = () => {
    let steps = this.props.response.busSupportData.scSteps;
    const styles = getStyles();

    return (<View style={styles.breadCumbOuter}>
      {
        steps && steps.map((step, index) => (
          <Text style={index != 0 ? styles.activeBreadCumb : styles.nonActiveBreadcumb}>{index + 1}  {step}</Text>
        ))
      }
    </View >);
  }

  updateDescription = (description) => {
    this.setState({ description });
  }

  updateACOption = (acIssueOption) => {
    this.setState({ acIssueOption });
  }

  proceed = () => {
    let response = this.props.response;
    let mmtAuth = this.mmtAuth;

    if (!this.state.description || this.state.description.length < 30) {
      showShortToast(label('bus.claim.descriptionMin30Characters'));
      return;
    }
    else {
      let submitRequest = this.prepareSubmitRequest();
      this.onClickEventTracker('BusserviceIssue_Proceed2');
      Actions.busSupportIssueSubmit({
        response,
        submitRequest,
        mmtAuth
      });
    }
  }

  updateAmentiesList = (amenitiesList) => {
    this.setState({ amenitiesList })
  }

  groupByIssue = (issueList) => {
    let issueMap = {};

    issueList.map((issueObj, index) => {
      let subIssueList = issueMap[issueObj.issue.name];
      if (!subIssueList) {
        issueMap[issueObj.issue.name] = issueObj.subIssue.name;
      }
      else {
        issueMap[issueObj.issue.name] = subIssueList + ', ' + issueObj.subIssue.name;
      }
    })
    return issueMap;
  }

  groupByIssueForRequest = (issueList, description) => {
    let issueMap = {};
    let issueListNew = [];

    issueList.map((issueObj, index) => {
      let obj = issueMap[issueObj.issue.id];
      if (!obj) {
        let subobj = {
          name: issueObj.issue.name,
          busSupportType: issueObj.issue.id,
          subIssue: [
            {
              name: issueObj.subIssue.name,
              attachment: null,
              description: description,
              myDeskTicket: issueObj.subIssue.myDeskTicket,
              redbusTicket: issueObj.subIssue.redbusTicket
            }
          ]
        };
        issueMap[issueObj.issue.id] = subobj;
      }
      else {
        let issueObjNew = issueMap[issueObj.issue.id];
        let subobjList = issueObjNew.subIssue;

        let subIssueObj = {
          name: issueObj.subIssue.name,
          attachment: null,
          description: description,
          myDeskTicket: issueObj.subIssue.myDeskTicket,
          redbusTicket: issueObj.subIssue.redbusTicket
        }

        subobjList.push(subIssueObj);
        issueObjNew.subIssue = subobjList;
      }
    })

    Object.entries(issueMap).map((item, index) => {
      issueListNew.push(item[1]);
    })

    return issueListNew;
  }

  updateAttachmentList = (map) => {
    this.onClickEventTracker('BusserviceIssue_Upload_Attachment');
    this.setState({
      attachmentList: map
    });
  }

  renderPage() {
    let amenityIssue = this.props.issueList.filter(issue => issue.id === BusBookingDetailsConstant.AMENITY_ISSUE);
    let acIssue = this.props.issueList.filter(issue => issue.id === BusBookingDetailsConstant.AC_ISSUE);

    let amenityIssueExist = amenityIssue && amenityIssue.length > 0;
    let amenityOptions = amenityIssueExist && amenityIssue[0].subIssue.options;

    let acIssueExist = acIssue && acIssue.length > 0;
    let acOptions = acIssueExist && acIssue[0].subIssue.options;
    let attachmentEnabled = this.props.attachment;
    const styles = getStyles();
    return (
      <View style={AtomicCss.flex1}>
        <StickyHeader
          iconSrc={headerIcon}
          title={this.pageTitle}
          headerColor={['#ffffff', '#ffffff']}
          titleStyle={[styles.DefaultText, styles.blackFont]}
          subTitle={this.pageSubTitle}
          subTitleStyle={[styles.DefaultText, styles.regularFont, styles.font12]}
          onBackPress={this.goBack}
        />
        <ScrollView>
          {this.getIssueBreadCumb()}
          <IssueInfoCard issueList={this.props.issueList} groupByIssue={this.groupByIssue} />
          <DescribeIssueCard updateDescription={this.updateDescription} busSupportData={this.props.response.busSupportData}/>
          {amenityIssueExist && <AmenityIssueCard
            listOptions={amenityOptions}
            updateAmentiesList={this.updateAmentiesList}
            amenitiesList={this.state.amenitiesList} />}
          {acIssueExist && <ACIssueCard listOptions={acOptions} updateACOption={this.updateACOption} />}
          {attachmentEnabled && <UploadCard response={this.props.response}
            maxAttachment={this.props.response.busSupportData.maxAttachment}
            updateAttachmentList={this.updateAttachmentList}
            uploadTitle={this.props.response.busSupportData.uploadTitle}
            uploadDescription={this.props.response.busSupportData.uploadDescription}
            attachmentList={this.state.attachmentList} />}
        </ScrollView>
        <TouchableOpacity style={AtomicCss.btn} activeOpacity={0.7} onPress={this.proceed}>
          <LinearGradient
            colors={[colors.lightBlue, colors.darkBlue]}
            start={{ x: 0, y: 0 }}
            end={{ x: 1.0, y: 0 }}
            style={AtomicCss.btnGradient}
          >
            <Text style={[AtomicCss.font12, AtomicCss.whiteText, AtomicCss.boldFont]}>
              {this.props.response.busSupportData.proceedButton}
            </Text>
          </LinearGradient>
        </TouchableOpacity>
      </View>
    );
  }
}

export default IssueDetail;
