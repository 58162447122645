import React from 'react';
import { View, Text, TouchableOpacity, Image, ActivityIndicator } from 'react-native';
import { backIcon, refreshIcon } from '../../../BusImageConstants';
import styles from './headerCss';

const Header = ({
  navigation,
  title,
  subTitle,
  containerStyle,
  isRefreshVisible,
  onRefresh,
  isLoading,
}) => {
  return (
    <View style={[styles.header, containerStyle]}>
      <View style={styles.leftContent}>
        <TouchableOpacity style={styles.backBtn} onPress={navigation}>
          <Image source={backIcon} style={styles.backIcon} />
        </TouchableOpacity>
        <View style={styles.titleWrapper}>
          <Text style={styles.title} numberOfLines={1}>
            {title}
          </Text>
          {subTitle ? (
            <Text style={styles.subTitle} numberOfLines={1}>
              Updated {subTitle}
            </Text>
          ) : null}
        </View>
      </View>
      {!isLoading ? (
        <TouchableOpacity style={styles.shareBtn} onPress={onRefresh}>
          <Image source={refreshIcon} style={styles.RefreshIcon} />
        </TouchableOpacity>
      ) : null}
      {isLoading && (
        <ActivityIndicator
          size="small"
          style={[styles.RefreshIcon, { marginRight: 15, justifyContent: 'center' }]}
          color="#008b8b"
        />
      )}
    </View>
  );
};

export default Header;
