import { StyleSheet } from "react-native";

const getPickDatesCss = ( theme, fonts) => StyleSheet.create ({
    pageWrapper: {
      flex: 1,
      backgroundColor: theme.color.grayBg,
    },
    pageScroll: {
      flex: 1,
    },
    pageScrollBtmSpace: {
      height: 75,
    },
    pageScrollTopSpace: {
      height: 0,
    },
    footerBtnWrapper: {
      padding: 12,
      position: 'absolute',
      zIndex: 2,
      bottom: 0,
      left: 0,
      width: '100%',
    },
    whiteCard: {
      backgroundColor: theme.color.white,
      padding: 15,
      elevation: 2,
      shadowColor: theme.color.black,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.2,
      shadowRadius: 2,
    },
    reqMessageWrapper: {
      padding: 15,
      paddingTop: 8,
    },
    reqMessage: {
      fontSize: fonts.fontSize.font14,
      color: theme.color.defaultTextColor,
      ...fonts.regularFontFamily,
      marginBottom: 6,
    },
    submitReqBtnText: {
      fontSize: fonts.fontSize.font12,
      color:  theme.color.submitDateChangeReqBtnText,
      ...fonts.blackFontFamily,
      paddingVertical: 6,
    },
    marginTop30: {
      marginTop: 30,
    },
    rulesBtn: {
      marginTop: 10,
    },
    linkText: {
      color: theme.color.linkTextDateChange,
    },
    boldText: {
      ...fonts.boldFontFamily,
    },
    font12: {
      fontSize: fonts.fontSize.font12,
    },
    italicText: {
      ...fonts.regularFontFamily,
      fontSize: fonts.fontSize.font12,
      fontStyle: 'italic',
    },
    infoIcon: {
      width: 16,
      height: 16,
      marginRight: 10,
    },
    info_redTxt: {
      color: theme.color.red6,
      fontSize: fonts.fontSize.font12,
      lineHeight: 16,
      fontStyle: 'italic',
    },
    rtAlertTxt: {
      flexDirection: 'row',
      padding: 10,
      paddingRight:30,
    },
    doneBtn: {
      width: 130,
      height: 40,
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      bottom: 20,
      left: 0,
      right: 0,
      marginTop: 0,
      marginRight: 'auto',
      marginBottom: 0,
      marginLeft: 'auto',
      borderRadius: 30,
      backgroundColor: theme.color.primary,
      zIndex: 10,
      color: theme.color.grey22,
      textTransform: 'uppercase',
    }
});

export default getPickDatesCss;
