import React from 'react';
import { View, Text } from 'react-native';
import createStyles from '../styles';
import { getFont } from '@mmt/post-sales/src/PostSalesStyles';
import { useTheme } from '@mmt/post-sales/src/theme/theme.context';
import { DriverProfileCustomerReviewsData } from '@mmt/post-sales/src/cab/types';

const WhatCustomersSay = ({ data }: { data: DriverProfileCustomerReviewsData }) => {
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);

  const { heading, list } = data || {};
  return (
    <View>
      {!!heading && <Text style={styles.customerReviewHeading}>{heading}</Text>}
      <View style={styles.customerReviewList}>
        {list?.map((reviewText, index) => {
          return (
            <View style={styles.whatCustomerWrapper} key={index}>
              <Text style={styles.customerReviewText}>{reviewText}</Text>
            </View>
          );
        })}
      </View>
    </View>
  );
};

export default WhatCustomersSay;
