import React, {useEffect} from 'react';
import { View, Image, Text, TouchableOpacity, StyleSheet, ViewStyle, TextStyle, ImageStyle } from 'react-native';
import createStyle from './style';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { BLUE_RIGHT_ARROW_ICON, MESSEGE_ICON } from '../../../HotelImageConstants';
import { getImageTransformStyling } from '../../../../utils/PostSaleUtil';
import { getUnreadMsgCount } from '../../utils/HotelBookingDetailUtil';
import { getStaticData } from '../../../../staticData/staticData';
import { useTheme } from '../../../../theme/theme.context';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import PostSalesBookingTrackingHelper from '../../../../PostSalesBookingTrackingHelper';

// Define types for props

interface VendorChatDetails {
  unreadMessagesCount?: number;
}
interface ExpediaChatCardProps {
  vendorChatDetails: VendorChatDetails[];
  handleOpenOverlay: () => void;
  trackingPageName: string;
  bookingID: string;
}

const ExpediaChatCard: React.FC<ExpediaChatCardProps> = ({vendorChatDetails, handleOpenOverlay, trackingPageName, bookingID }) => {
  useEffect(() => {
    PostSalesBookingTrackingHelper.trackShownEvent(trackingPageName, 'ExpediaChatCard_shown', bookingID);
}, [trackingPageName, bookingID]);

  const { fsStyle, ...fonts } = getFont(true);
  const staticData = getStaticData();
  const { psTheme: theme } = useTheme();
  const styles = createStyle(theme);
  const unreadMsgCount = getUnreadMsgCount(vendorChatDetails);
  const {cardHeading, cardSubHeading} = staticData.expediaChatText;
  let infoText;
  if (unreadMsgCount === 0) {
    infoText = cardSubHeading;
  } else {
    infoText = `${unreadMsgCount} ${unreadMsgCount === 1
      ? staticData.expediaChatText.newMessage
      : staticData.expediaChatText.newMessages
      }`;
  }

  return (
    <View style={styles.cardWrapper}>
      <TouchableOpacity style={styles.expediaChatCard} onPress={handleOpenOverlay}>
        <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.flex1]}>
          <View style={AtomicCss.marginRight10}>
            <Image style={styles.messageProperty} source={MESSEGE_ICON} />
            {!!unreadMsgCount && (
              <View style={styles.redCircle}>
                <Text style={[fsStyle.font12, AtomicCss.lineHeight16, { color: theme.color.white }, fonts.boldFontFamily, styles.marginTop2]}>
                  {unreadMsgCount}
                </Text>
              </View>
            )}
          </View>
          <View style={[AtomicCss.flex1]}>
            <Text style={[fonts.blackFontFamily, fsStyle.font14, AtomicCss.blackText, AtomicCss.marginBottom3]}>
              {cardHeading}
            </Text>
            <Text style={[fonts.regularFontFamily, fsStyle.font14, AtomicCss.defaultText, AtomicCss.lineHeight16]}>
              {infoText}
            </Text>
          </View>
        </View>
        <View>
          <Image style={[styles.rightBlueArrow, getImageTransformStyling()]} source={BLUE_RIGHT_ARROW_ICON} />
        </View>
      </TouchableOpacity>
    </View>
  );
};

export default ExpediaChatCard;
