import React from 'react';
import { View, Text } from 'react-native';
import get from 'lodash/get';
import  isEmpty from 'lodash/isEmpty';
import styles from '../FlightDtlsCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getStaticData } from '../../../../../../staticData/staticData';
import { appendDotWithText } from '../../../../../../utils/PostSaleUtil';

import { getFont } from '../../../../../../PostSalesStyles';

const SegmentGroupContent = ({ itinaryDetails }) => {
  const {
    isFailed,
    isAllCancelled,
    isCompleted,
    segmentGroupStatus,
    segmentGrpStatus,
    durationInMinsText,
    stopOvers,
    segmentDetails,
    stopOversText,
  } = itinaryDetails;
  const flightTravelDateV2 =
    segmentDetails &&
    segmentDetails.length > 0 &&
    segmentDetails[0].flightTravelDateV2;
  const travelClass =
    segmentDetails &&
    segmentDetails.length > 0 &&
    get(segmentDetails[0], 'flightDesignator.travelClass', '');
  const { fsStyle, ...fonts } = getFont(true);
  const { failedText, cancelledText, completedText , nonStopText, stopsText} = getStaticData() || {};

  const getStopovers = () => {
    if (stopOversText) {
      return stopOversText;
    }
    if (!isEmpty(stopOvers)) {
      return `${stopOvers.length} ${stopsText}`;
    }
    return nonStopText;
  };
  const infoTxtStyle = isAllCancelled
      ? [styles.canccelledTxt, fonts.boldFontFamily]
      : [styles.CompletedTxt, fonts.boldFontFamily];

  return (
    <View style={[AtomicCss.flexRow, AtomicCss.flexWrap]}>
      <Text style={[styles.travelDate, fonts.boldFontFamily, fsStyle.font12]}>
        {flightTravelDateV2}{' '}
      </Text>
      <Text style={[fsStyle.font12, AtomicCss.greyText]}>
        {appendDotWithText(getStopovers())}
      </Text>
      {!isEmpty(durationInMinsText) && <Text style={[AtomicCss.font12, AtomicCss.greyText]}>
        {appendDotWithText(durationInMinsText)}
      </Text>}
      <Text style={[AtomicCss.font12, AtomicCss.greyText]}>
        {appendDotWithText(travelClass)}
      </Text>
      {segmentGroupStatus && !isEmpty(segmentGroupStatus.tagName) && (
        <Text
          style={[
            styles.sectorStatusText,
            fonts.boldFontFamily,
            segmentGrpStatus,
            fsStyle.font12,
          ]}
        >
          {appendDotWithText(segmentGroupStatus.tagName)}
        </Text>
      )}
      {!segmentGroupStatus && (isAllCancelled || isFailed || isCompleted) && (
        <Text
          style={
            isFailed ? [styles.failedTxt, fonts.boldFontFamily, fsStyle.font12] : infoTxtStyle
          }
        >
          {isFailed
            ? appendDotWithText(failedText)
            : isAllCancelled
            ? appendDotWithText(cancelledText)
            : appendDotWithText(completedText)}
        </Text>
      )}
    </View>
  );
};

export default SegmentGroupContent;
