import React from 'react';
import {StyleSheet, View} from 'react-native';

//Screenshot of this seperator: https://gommt-my.sharepoint.com/:i:/g/personal/mmt9115_go-mmt_com/EQVG1lnw-tlKnhnms2CIj4EB5saqwFh1Gx2Evjp3oyHsFQ?e=JujRP2
const Seperator = ({marginVertical}) =>
  <View style={[styles.seperator, {marginVertical: !!marginVertical ? marginVertical : null }]}>
    <View style={[styles.semiCircle, styles.leftSemiCircle]} />
    <View style={styles.dashedLineWrapper}>
      <View style={styles.dashedLine} />
    </View>
    <View style={[styles.semiCircle, styles.rightSemiCircle]} />
  </View>

  const styles = StyleSheet.create({
    seperator: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginVertical: 16,
      marginHorizontal: -20
    },
    semiCircle: {
      height: 16,
      width: 16,
      borderRadius: 8,
      backgroundColor: '#f1f1f1'
    },
    leftSemiCircle: {
      marginLeft: -8
    },
    rightSemiCircle: {
      marginRight: -8
    },
    dashedLineWrapper: {
      height: 2,
      flex: 1,
      overflow: 'hidden'
    },
    dashedLine: {
      flex: 1,
      marginHorizontal: 0,
      borderStyle: 'dashed',
      borderWidth: 1,
      borderColor: '#f1f1f1',
      height: 3
    }
  })

  export default Seperator;