import React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import { isEmpty } from 'lodash';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getFont } from '../../../../../../PostSalesStyles';
import { voucherTnCHandler } from '../../../../../Utils/TgsBookingDetailUtil';

const CancelledBooking = ({ cancelledData }) => {
  const { heading, termsAndConditions, tcHeading } = cancelledData || {};
  const { url } = termsAndConditions || {};
  const fonts = getFont();
  const handleTC = () => {
    if (!isEmpty(termsAndConditions)) {
      voucherTnCHandler(termsAndConditions);
    }
  };
  return (
    <View>
      {!!heading && (
        <Text
          style={[
            AtomicCss.font12,
            AtomicCss.defaultText,
            fonts.regularFontFamily,
            AtomicCss.marginBottom15,
            AtomicCss.textCenter,
          ]}
        >
          {heading}
        </Text>
      )}
      {!!tcHeading && !!url && (
        <TouchableOpacity
          style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.justifyCenter]}
          onPress={handleTC}
        >
          <Text style={[AtomicCss.azure, AtomicCss.font13]}>{tcHeading}</Text>
        </TouchableOpacity>
      )}
    </View>
  );
};

export default CancelledBooking;
