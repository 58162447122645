import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import { trackOmniture } from '@mmt/legacy-commons/Common/utils/OmnitureTrackerUtils';
import { findDaysBetweenDates } from '@mmt/legacy-commons/Common/utils/DateUtils';
import { BAGGAGE, MEALS, SEATS, WEBCHECKIN } from '../constants';
import { getApplicableAncillaryRule } from './AncillaryUtils';
import FlightEventsLogger from '../../details/helper/FlightEventsLogger';
import { _trackFltBookingDate, _dateFormatForTracker } from '../../../FlightOmnitureTrackerUtils';

class AncillaryTrackingUtil {
  _trackLoadEvent = (pageName, response) => {
    try {
      FlightEventsLogger.loadEvent(pageName, response);
      const trackingPageName = this._getPageName(pageName, response);
      let params = this._getTrackingParams(trackingPageName, response);
      params = this._appendNotSupportedDetails(pageName, response, params);
      trackOmniture(trackingPageName, params, true);
    } catch (e) {
      console.log(e);
    }
  };

  _trackLoadEventCyt = (pageName, response, cytExperimentValue = null) => {
    try {
      FlightEventsLogger.loadEvent(pageName, response);
      const trackingPageName = this._getPageName(pageName, response);
      let params = this._getTrackingParams(trackingPageName, response);
      params = this._appendNotSupportedDetails(pageName, response, params);
      if (!isNil(cytExperimentValue)) {
        params.m_c1 = `CYT_experiment_flag_${cytExperimentValue}`;
      }
      trackOmniture(trackingPageName, params);
    } catch (e) {
      console.log(e);
    }
  };

  _trackErrorEvent = (pageName, reason) => {
    try {
      FlightEventsLogger.errorEvent(pageName, { error: reason });
      const params = {};
      const trackingPageName = this._getPageName(`${pageName} error`, null);
      params.m_v15 = trackingPageName;
      params.m_c22 = reason;
      trackOmniture(trackingPageName, params);
    } catch (e) {
      console.log(e);
    }
  };

  _trackClickEvent = (pageName, clickEvent, response) => {
    try {
      FlightEventsLogger.clickEvent(pageName, clickEvent, response);
      const params = {};
      const trackingPageName = this._getPageName(pageName, response);
      params.m_c54 = `MI_addon_${this._getLob(response)}_${clickEvent}`;
      trackOmniture(trackingPageName, params);
    } catch (e) {
      console.log(e);
    }
  };

  _getLob = (response) => {
    let lob = '';
    try {
      lob = response && response.isInternational ? 'Intl' : 'Dom';
    } catch (e) {
      console.log(e);
    }
    return lob;
  };

  _getPageName = (pageName, response) => {
    let trackingPageName = 'Mob:customer support:';
    try {
      trackingPageName += this._getLob(response);
    } catch (error) {
      console.log(`Error while setting ancillary tracking page name - ${error}`);
    }
    trackingPageName += ` Flight add-on:${pageName}`;
    return trackingPageName;
  };

  _getTrackingParams = (trackingPageName, response) => {
    const params = {};
    try {
      params.m_v15 = trackingPageName;
      params.m_v16 = response.bookingID;
      params.m_v54 = response.uuid;
      // params.m_v35 = response.mobile;
      params.m_v7 = findDaysBetweenDates(response.bookingDateTime, new Date()) + ` Days`;
      params.m_v3 = '';
      params.m_v6 = '';
      params.m_v40 = '';
      params.m_v21 = '';
      params.m_v13 = '';
      params.m_v5 =
        response && response.bookingDateTime
          ? _trackFltBookingDate(response.bookingDateTime.split('T')[0])
          : '';
      params.m_v4 = '';
      if (!isEmpty(response.segmentAncillaryDetails)) {
        response.segmentAncillaryDetails.forEach((seg) => {
          params.m_v3 += `${seg.departureCity}-${seg.arrivalCity}|`;
          params.m_v6 += `${findDaysBetweenDates(seg.travelDateTime, new Date())}|`;
          params.m_v40 += `${seg.airlineCode}|`;
          params.m_v21 += `${
            !isEmpty(seg.paxAncillaryDetails) ? seg.paxAncillaryDetails.length : 0
          }|`;
          params.m_v4 += seg.travelDateTime
            ? `${_dateFormatForTracker(seg.travelDateTime.split('T')[0])}|`
            : '';
          if (isEmpty(seg.webCheckInDescription)) params.m_v13 += '-';
          else params.m_v13 += `${seg.webCheckInDescription}|`;
        });
      }
      if (!isEmpty(response.seatSelectionType)) {
        params.m_v13 += response.seatSelectionType;
      }
      params.m_v9 = this._getJourneyTpe(response.journeyType);
      params.m_v14 = this._getZcDbStatus(response);
      params.m_v24 = response.isInternational ? 'mob intl flights' : 'mob domestic flights';
      params.m_c22 = response && response.ruleTrace ? response.ruleTrace : '';
    } catch (error) {
      console.log(`error in setting ancillary omniture params - ${error}`);
    }
    return params;
  };

  _getJourneyTpe = (journeyType) => {
    switch (journeyType) {
      case 1:
        return 'Multi';
      case 2:
        return 'RT';
      case 3:
        return 'OW';
      default:
        return '';
    }
  };

  _getZcDbStatus = (response) => {
    let tmp = '';
    if (response.isMMTPrivilegeEligible) {
      tmp += 'DB|';
    }
    if (response.isEligibleForZeroCancellation) {
      tmp += 'ZC|';
    }
    return tmp;
  };

  _getAddOnPageName = (topCard) => {
    let name = '';
    if (topCard === MEALS) {
      name = 'Meal Baggage';
    } else {
      name = 'Baggage Meal';
    }
    return name;
  };

  _getSeatPageName = (fromWebCheckin) => {
    let name = 'Seats';
    if (fromWebCheckin) {
      name += ' Webcheckin';
    }
    return name;
  };

  _appendNotSupportedDetails = (pageName, response, params) => {
    let notSupportedText = '';
    try {
      response.segmentAncillaryDetails.forEach((seg) => {
        switch (pageName) {
          case 'Seats':
          case 'Seats Webcheckin':
            notSupportedText += this._getNoTSupportedReason(response, seg, SEATS);
            break;
          case 'Webcheckin':
            notSupportedText += this._getNoTSupportedReason(response, seg, WEBCHECKIN);
            break;
          case 'Add on Meal Baggage':
          case 'Add on Baggage Meal':
            const mealText = this._getNoTSupportedReason(response, seg, MEALS);
            if (!isEmpty(mealText)) notSupportedText += `Meal ${mealText}`;
            const baggageText = this._getNoTSupportedReason(response, seg, BAGGAGE);
            if (!isEmpty(baggageText)) notSupportedText += `Baggage ${baggageText}`;
            break;
          default:
            break;
        }
      });
    } catch (error) {
      console.log(`error while evaluating not supported for ancillary sectors - ${error}`);
    }
    params.m_c22 = notSupportedText;
    return params;
  };

  _getNoTSupportedReason = (response, segmentDetail, type) => {
    let notSupportedText = '';
    const ancRule = getApplicableAncillaryRule(response, segmentDetail.segmentLineNo, type);
    if (!isEmpty(ancRule) && !ancRule.isApplicable)
      notSupportedText += `${segmentDetail.departureCityCode}-${segmentDetail.arrivalCityCode}_${ancRule.description}|`;
    if (isEmpty(notSupportedText)) return '-|';
    return notSupportedText;
  };
}

export default new AncillaryTrackingUtil();
