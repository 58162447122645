import { StyleSheet } from "react-native";
import getPlatformElevation from "../Components/getPlatformElevation";

export default (theme, fonts) =>
  StyleSheet.create({
    topStickySection: {
      ...getPlatformElevation(2),
      backgroundColor: theme.color.white,
      zIndex: 10,
    },
    TabsWrapper: {
      backgroundColor: theme.color.white,
      flexDirection: 'row',
    },
    pickUpDetailsWrapper: {
      paddingVertical: 24,
      paddingHorizontal: 16,
      backgroundColor: theme.color.white,
    },
    cardWrapper: {
      borderRadius: 4,
      borderWidth: 1,
      borderColor: theme.color.gray8,
      marginBottom: 20,
    },
    bottomTxt: {
      fontSize: 11,
      color: theme.color.darkGrey2,
      lineHeight: 14,
      flex: 1,
      marginLeft: 12,
      ...fonts.regularFontFamily,
    },
    btnWrapper: {
      paddingHorizontal: 20,
      marginBottom: 20,
    },
    errorWrapper: {
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: theme.color.pink5,
      padding: 12,
      paddingRight:18
    },
    infoIcon: {
      width: 24,
      height: 24,
      marginRight: 12,
      tintColor: theme.color.red14,
    },
    buttonStyle:{
      backgroundColor: theme.color.white,
    }
  });
