import { setConfig } from 'react-native-ad-wrapper';
import { getHeaders } from '../Common/utils/NetworkUtils';
import { getUserDetails } from '../Native/UserSession/UserSessionModule';

const configObj = {
  userCallbackForView(id, element) {
    // This callback will be called when ad in the view
    // console.log('Id : ', id);
    // console.log('view callback');
  },
  userCallbackForOnPress(id, element) {
    // This callback will be called when ad is clicked
    // console.log('Id : ', id);
    // console.log('onPress callback');
  },
  onError(error, id) {
   
  },
  API_CALL: "SINGLE",
  ORG: 'MMT',
  AUTH_COOKIE_KEY: 'mmt-auth',
  REGION: 'IN',
  PROFILE_TYPE: 'PERSONAL',
  DEVICE_LANGUAGE: 'eng',
  DEVICE_ID: '',
  OS_VERSION: '',
  MMT_APP_VERSION: '',
  TIME_ZONE: 'IST',

  mode: 'PROD',
};
const getConfigModule = async (overrideConfig) => {
  try {
    const headers = await getHeaders();
    const user = await getUserDetails();
    const { mmtAuth } = user;
    if ('region' in headers) {
      configObj.REGION = headers.region;
    }
    if ('language' in headers) {
      configObj.DEVICE_LANGUAGE = headers.language;
    }
    if ('deviceId' in headers) {
      configObj.DEVICE_ID = headers.deviceId;
    }
    if ('os' in headers) {
      configObj.OS_VERSION = headers.os;
    }
    if ('ver' in headers) {
      configObj.MMT_APP_VERSION = headers.ver;
    }
    configObj.AUTH_COOKIE_KEY = mmtAuth;

    if(overrideConfig?.onErrorCallback){
      configObj.onError = overrideConfig.onErrorCallback
    }
    return configObj;
  } catch (e) {
    console.log(e);
    return configObj;
  }
};
export default async (overrideConfig) => {
  try {
    const config = await getConfigModule(overrideConfig);
    setConfig(config);
  } catch (error) {
    console.log(error);
  }
};
