import { themeColors } from '../../../PostSalesStyles';

export const colors = {
  blueStartColor: themeColors.blueStartColor,
  blueEndColor: themeColors.blueEndColor,
  blackStartColor: themeColors.blackStartColor,
  blackEndColor: themeColors.blackEndColor,
  pinkStartColor: themeColors.pinkStartColor,
  pinkEndColor: themeColors.pinkEndColor,
  orangeStartColor: '#ff3e5e',
  orangeEndColor: '#ff7f3f',
  darkBlueStartColor: '#053cb2',
  darkBlueEndColor: '#0c6fd9',
  whiteStartColor: '#fbedcf',
  lightBlue: '#eaf5ff',
  lightGrey: '#cccccc',
  lightOrange: '#fedcde',
  veryLightBlue: '#e8f0ff',
  lightGreen: '#d1f8e6',
  lightYellow: '#ffedd1',
  lightPink: '#ffd3d4'
};
