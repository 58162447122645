import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  bestPriceQuarenteeCard: {
    borderRadius: 8,
    padding: 15,
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    ...getPlatformElevation(3),
    marginHorizontal: 12,
    marginBottom: 12,
  },
  imgTimerIcon: {
    width: 27,
    height: 29,
  },
  cardInfo: {
    width: '90%',
    paddingLeft: 10,
  },
});

export default styles;
