import {
  MONTH_QUOTE_HALF_YEAR,
  POSTSALES_DATE_TIME,
  reformatDateString,
} from '@mmt/legacy-commons/Helpers/dateTimehelpers';
import { FILTERS, Lobs, PAGE_BOOKINGS_STATUS, PLANNINGS_STATUS, ViewState } from '../summaryConstants';
import { loadShowMoreFilteredData, fetchTickets, loadData } from './api';
import isEmpty from 'lodash/isEmpty';
import { getWhere2GoImageData } from '../utils';
import {BookingData, BookingSummaryResponseType, CardDetails, SelectedFilter, summaryStateObjectType, initialStateObjectType, SummarySection, WhereToGoImageData, MsrTicketAPIResponse, LostBookingIdAPIResponse, PlanningsData, PlanningCard, planningStateObjectType, LostIdBookingData, PlanningsSectionData, LostIdSectionData} from '../types'
import { getStaticData } from 'apps/post-sales/src/staticData/staticData';
import { showLongToast } from 'packages/legacy-commons/Common/Components/Toast';

export function makePageData(bookingSummaryResponse: BookingSummaryResponseType) {
  const { newBookingDetails, userBookingSummary, filters } = bookingSummaryResponse;
  const { showAdTechCard, statusWiseMessage, responseCode , otherLanguageBookingInfo, bookingsInOtherLanguage, cardDetail, uuid, addBookingCTA, persuasion } = userBookingSummary || {};
  if (newBookingDetails == null) {
    throw new Error('Mytrips: Invalid response');
  }
  return {
    newBookingDetails,
    showAdTechCard,
    statusWiseMessage,
    responseCode,
    otherLanguageBookingInfo,
    bookingsInOtherLanguage,
    cardDetail,
    uuid,
    addBookingCTA,
    filters,
    persuasion,
  };
}

export function filterData(bookingSectionData: [{data: BookingData[], lobType: string}], selectedLobId: string) {
  const bookings = bookingSectionData.map((section) => {
    if (selectedLobId) {
      return section.data.filter(({ lobType }) => lobType[0] === selectedLobId);
    }
    return section.data;
  });
  const bookingsFlattened = [].concat(...bookings);
  return groupBookingsByDate({ bookings: bookingsFlattened });
}

export function groupBookingsByDate({ bookings, pageTitle }: {bookings: BookingData[], pageTitle?: string}) {
  const sections = {};
  bookings.forEach((booking) => {
    if (
      booking.lobType &&
      [
        Lobs.AdTechUpcomingCard,
        Lobs.AdTechCancelledCard,
        Lobs.AdTechCompletedCard,
        Lobs.AdTechFailedCard,
      ].includes(booking.lobType[0])
    ) {
      sections.AdTechSection = [booking];
    } else if (
      booking.lobType &&
      [Lobs.Planning].includes(booking.lobType[0]) &&
      booking.status == ''
    ) {

    } else {
      const dateYear = reformatDateString(booking.maxArrDate, POSTSALES_DATE_TIME, MONTH_QUOTE_HALF_YEAR);
      if (sections[dateYear]) {
        sections[dateYear].push(booking);
      } else {
        sections[dateYear] = [booking];
      }
    }
  });
  return Object.keys(sections).map((key) => {
    const value = sections[key];
    return {
      title: key,
      data: value,
      pageTitle,
    };
  });
}



export function prepareBookingsData(sumaryResponse:BookingSummaryResponseType , bookings: BookingData[], status: string) {
  if (
    !sumaryResponse ||
    !sumaryResponse.newBookingDetails ||
    sumaryResponse.newBookingDetails.length === 0
  ) {
    return {
      updatedBookings: bookings ? [...bookings]: [],
      hasNextPage: false,
      updatedStatusWiseMessage: sumaryResponse.statusWiseMessage,
    };
  }
  const { newBookingDetails } = sumaryResponse;
  let updatedBookings = bookings && bookings.length ? [...bookings] : [];
  const currentPageData = newBookingDetails.filter(statusData => statusData.status === status)[0];
  let currentPageNewBookings = currentPageData ? currentPageData.bookingDetails : [];
  const hasNextPage = currentPageData ? currentPageData.hasNext : false;
  const updatedStatusWiseMessage = currentPageData
    ? { [status.toLowerCase()]: currentPageData.statusMessage }
    : null;
  updatedBookings.push(...currentPageNewBookings);
  return { updatedBookings, hasNextPage, updatedStatusWiseMessage };
}

export const modifiedBookingListResponse = (bookings: BookingData[], pageName: string) => {
  return groupBookingsByDate({ bookings: bookings });
};

export const getMyTripsCardsInfo = (cardData: CardDetails) => {
  let stickyCards = [];
  let nonStickyCards = [];

  !isEmpty(cardData) &&
    cardData.forEach((data) => {
      const { isSticky } = data;
      if (isSticky) stickyCards = [...stickyCards, data];
    });

  return [stickyCards, nonStickyCards];
};


export function getSummaryStateArray(summaryState: summaryStateObjectType) {
  let array: SummarySection[] = [];
  Object.keys(summaryState).forEach((key, index) =>
    array.push(getSummarySection(summaryState[key], key, index)),
  );
  return array;
}
export function getSummarySection(summaryStatus: initialStateObjectType, key: string, index: number) {
  return {
    title: key,
    data: (summaryStatus && summaryStatus.bookings) || [],
    index,
    isExpanded: true,
  };
}


export const getShowMoreData = async (bookingStatus: string, page: number, summaryState: summaryStateObjectType, selectedFilters: {current: SelectedFilter[]}) => {
  if ( isEmpty(selectedFilters.current)) {
    const bookingsData = await loadData(bookingStatus, page, true, summaryState);
    if (!bookingsData){
      const {errorText} = getStaticData();
      const {soomethingWentWrongOnlyError} = errorText || {};
      showLongToast(soomethingWentWrongOnlyError);
      return {viewState: ViewState.SHOW_MORE_LOADED, error: true};
    }
    return bookingsData;
  } else {
    const filteredBookingsData = await loadShowMoreFilteredData(bookingStatus, page, summaryState, selectedFilters);
    return filteredBookingsData;
  }
};

export const getMsrTicketsData = async () => {
  const data = await fetchTickets();
    if (data) {
      return {
        myRequestsCardData: data?.myRequestsCardData,
        bookingIdtoTicketDetailmap: data?.bookingIdtoTicketDetailmap
      };
    }
};


export const getFilteredData = (_bookings: BookingSummaryResponseType, currentStatusOldBookings:BookingData[], pageNumber: number, bookingStatus: string, lostIdBookingData : LostBookingIdAPIResponse | {} ={}, planningsData: planningStateObjectType| {} ={},  filterPageNumber: number = 1, ) => {
  let payload = {};
  if (bookingStatus === PAGE_BOOKINGS_STATUS.ALL) {
    payload = {
      [PAGE_BOOKINGS_STATUS.ONTRIP]: null,
      [PAGE_BOOKINGS_STATUS.UPCOMING]: null,
      [PAGE_BOOKINGS_STATUS.COMPLETED]: null,
      [PAGE_BOOKINGS_STATUS.CANCELLED]: null,
      [PAGE_BOOKINGS_STATUS.FAILED]: null,
    };
  } 
   else {
    payload = {
      [bookingStatus]: null,
    };
  }
  if(!isEmpty(lostIdBookingData?.lostBookingList)){
    payload[PAGE_BOOKINGS_STATUS.AWAITING] = null;
  }
  if(!isEmpty(planningsData?.bookings)){
    payload[PAGE_BOOKINGS_STATUS.PLANNING] = null;
  }
  let whereToGoImageBool = true;
  Object.keys(payload).map((key, index) => {
    if (key === PAGE_BOOKINGS_STATUS.AWAITING && !isEmpty(lostIdBookingData?.lostBookingList)){
      const data = {
        key: key,
        bookings: [{title: '', data: modifyLostBookings(lostIdBookingData?.lostBookingList)}],
      };
      payload[key] = data;
    } else if (key === PAGE_BOOKINGS_STATUS.PLANNING){
      const data = {
        key: key,
        bookings: [{title: '', data: planningsData?.bookings}],
      };
      payload[key] = data;
    }
    else {
      const { updatedBookings, hasNextPage, updatedStatusWiseMessage } = prepareBookingsData(
        _bookings,
        currentStatusOldBookings,
        key,
      );
      if (whereToGoImageBool && (key === PAGE_BOOKINGS_STATUS.UPCOMING || key === PAGE_BOOKINGS_STATUS.ONTRIP ) && updatedBookings.length) {
        getWhere2GoImageData(updatedBookings[0]).then((image) => {
          updatedBookings[0].whereToGoImage = image;
          updatedBookings[0].showWhere2GOImage = true;
        });

        whereToGoImageBool = false;
      }
      const data = {
        key: key,
        bookings: modifiedBookingListResponse(updatedBookings, key),
        hasNextPage: hasNextPage,
        currentPage: pageNumber,
        currentFilteredPage: filterPageNumber,
        currentPageResponse: _bookings && _bookings.newBookingDetails && _bookings.newBookingDetails.filter((data) => data.status === key),
        statusWiseMessage: updatedStatusWiseMessage,
      };
      payload[key] = data;
  }
  });
  return payload;
};

export const getWhere2GoImage = ( whereToGoImageData: WhereToGoImageData ) => {
  const whereToGoImageObject = whereToGoImageData?.data?.location?.edges && whereToGoImageData?.data?.location?.edges[0]?.node?.assets?.edges[0]?.node?.storageMeta;
  const whereToGoImage = whereToGoImageObject && whereToGoImageObject.cdnRoot + whereToGoImageObject.cdnPath;
  return whereToGoImage;
}

export const getTicketData = (data: MsrTicketAPIResponse)=> {
  const bookingIdtoTicketDetailmap = new Map();
  const msrTickets = data.ticketsList;
  const myRequestsCardData = data.myRequestsCTA;
  msrTickets.map((item, index) => {
    const bookingId = item.entityKey;
    if (bookingId && bookingId.length > 0 && !bookingIdtoTicketDetailmap.has(bookingId)) {
      bookingIdtoTicketDetailmap.set(bookingId, item);
    }
  });
  return { myRequestsCardData, bookingIdtoTicketDetailmap};
}


function mapPlanningCards(cards: {[key:string]: PlanningCard[]}, planningStatus: string) {
  const plannings = [...cards[planningStatus]];
  if(isEmpty(plannings)){
    return [];
  }
  return plannings.map((planning: PlanningCard) => {
    planning.lobType = [Lobs.Planning];
    planning.bookingID = planning.ticketId;
    planning.status = planningStatus === PLANNINGS_STATUS.IN_PROGRESS ? planningStatus : '';
    planning.maxArrDate = planning.updateAtDate;
    return planning;
  });
}

export const preparePlanningData = (plannings: PlanningsData) => {
  return {
    bookings: [
      ...mapPlanningCards(plannings.cards, PLANNINGS_STATUS.IN_PROGRESS),
      ...mapPlanningCards(plannings.cards, PLANNINGS_STATUS.CLOSED_QUERIES),
    ],
  };
}

export const  modifyLostBookings = (bookings: LostIdBookingData[]) =>  {
  if(isEmpty(bookings)){
    return [];
  }
  return bookings.map((item: LostIdBookingData) => {
    return {
      ...item,
      bookingID: item.bookingId,
      isLostBooking: true,
      lobType: [item.lobname.toUpperCase()],
    };
  });
};

export const getFilteredPlanningsData = (planningsData: PlanningsSectionData, filters: SelectedFilter[]) => {
  let filteredPlanningsData={ bookings: []};
  const filtersBookingStatusValues = filters.find((filter)=> filter.key === FILTERS.BOOKING_STATUS)?.values.map((value)=> value.id && value.id.toUpperCase());
  if(filtersBookingStatusValues?.includes(PAGE_BOOKINGS_STATUS.PLANNING)){
    if(isEmpty(planningsData?.bookings)) {
      filteredPlanningsData.bookings=[];
    }else {
      filteredPlanningsData.bookings =  planningsData?.bookings &&  planningsData?.bookings[0].data;
    }
    
  }
  return filteredPlanningsData;
};

export const getFilteredLostIdData = (lostIdBookingData: LostIdSectionData, filters: SelectedFilter[]) => {
  let filteredLostBookingData = { lostBookingList: []};
  if(isEmpty(lostIdBookingData?.bookings)){
    return filteredLostBookingData;
  }
  const lostIdBookings = lostIdBookingData?.bookings  && lostIdBookingData?.bookings[0].data;
  const filtersBookingStatusValues = filters.find((filter)=> filter.key === FILTERS.BOOKING_STATUS)?.values.map((value)=> value.id && value.id.toUpperCase());
  const filtersCategoryValues = filters.find((filter)=> filter.key === FILTERS.CATEGORY)?.values.map((value)=> value.id && value.id.toUpperCase());

  if(filtersBookingStatusValues?.includes(PAGE_BOOKINGS_STATUS.AWAITING)){
      if(!isEmpty(filtersCategoryValues)){
        filteredLostBookingData.lostBookingList = !isEmpty(lostIdBookings) && lostIdBookings.filter((booking)=>
          filtersCategoryValues?.includes(booking.lobname)
      );
      } else {
        filteredLostBookingData.lostBookingList = lostIdBookings;
      }
  }
  return filteredLostBookingData;
};