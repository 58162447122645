import React from 'react';
import { Text, View } from 'react-native';
import { getFont } from '../../../../../../PostSalesStyles';
import { useTheme } from '../../../../../../theme/theme.context';
import { getStyle, getHtmlStyles } from './ContactUsPopupCss';
import HTMLView from 'react-native-htmlview';
import {openDialer} from '../../../../../../Common/commonUtil'

const ContactUsPopupComp = ({ ctripContact, handleServiceGuranteePopup }) => {
  const { title, subtitle, ctaText } = ctripContact || {};
  const fonts = getFont(true);
  const { psTheme } = useTheme();
  const styles = getStyle(fonts, psTheme);
  const htmlStyle = getHtmlStyles(fonts, psTheme);
  return (
    <View style={styles.container}>
      {!!title && <Text style={styles.title}>{title}</Text>}
      <View style={styles.barStyles}></View>
      {!!subtitle &&
        subtitle.map((text) => (
          <HTMLView
            value={text}
            onLinkPress={(url) => {
                openDialer(url)
            }}
            stylesheet={htmlStyle}
          />
        ))}
      <View style={styles.ctaTextWrap}>
        {!!ctaText && (
          <Text onPress={handleServiceGuranteePopup} style={styles.ctaText}>
            {ctaText}
          </Text>
        )}
      </View>
    </View>
  );
};

export default ContactUsPopupComp;
