import React from 'react';
import Actions from '../../../navigation/postSalesNavigation';
import PropTypes from 'prop-types';
import {Image, ScrollView, Text, View} from 'react-native';
import Header from '@mmt/legacy-commons/Common/Components/Header/Header';
import styles from '../../styles/AcmeMamiCss';


class AcmeThingsToCarry extends React.Component {
  static navigationOptions = {
    header: null
  }

  render() {
    return (
      <View style={[styles.whiteContainer, styles.transperantBgrnd]}>
        <Header
          title="Things To Carry"
          onBackPress={this.onBackPress}
          type="Elevated"
        />
        <ScrollView>
          <View style={styles.PolicyDetailsContainer}>
            {this.showThingsToCarry()}
          </View>
        </ScrollView>
      </View>
    );
  }

  onBackPress = () => {
    Actions.pop();
  }

  showThingsToCarry() {
    const views = [];
    this.props.response.acmeBookingInfo.acmeBookingDetails[0].acmeAdditionalInfo.whatToCarry.forEach((policyObject) => {
      this.createHotelPolicyView(views, policyObject);
    });
    return views;
  }

  createHotelPolicyView(views, policyObject) {
    views.push(<View style={[styles.mar15Bt, styles.flexRow]} key={policyObject}>
      <View style={styles.ItemDisc} />
      <Text>{policyObject}</Text>
    </View>);
  }
  onBackPressed = () => {
    Actions.pop();
  }
}
AcmeThingsToCarry.propTypes = {
  response: PropTypes.object.isRequired
};
export default AcmeThingsToCarry;
