import React from 'react';
import { Image, View } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { MYSAFETY_DELLOITE_ICON } from '../../../HotelImageConstants';
import SliderImages from '../../components/SliderImages';
import { getActionButton, handleClickEvent, isNotNullAndEmpty } from '../../utils/HotelBookingDetailUtil';
import styles from './PropertyMainCardCss';
import HotelTopCard from './PropertyTopCard';

const PropertyMainCard = ({response, card, pageName}) => {
  const hotelDetailList = response.hotelBookingInfo && response.hotelBookingInfo.hotelDetailsList && response.hotelBookingInfo.hotelDetailsList.length > 0 &&  response.hotelBookingInfo.hotelDetailsList[0];
  const mmtValueStaysImgURI = 'https://imgak.mmtcdn.com/mima/images/value-stays.png';
  const myBizAssuredImgURI = 'https://imgak.mmtcdn.com/Midoffice/Corporate/myBizAssured_logo_m.png';
  const discountingCampaignImageUrl = response?.discountingCampaignIconUrl || `https://promos.makemytrip.com/sale_images/limited_time_sale_2x.png`;
	
  const handleClick = (action) => {
    handleClickEvent(card, action, response, pageName);
  };
	
	return (
    <View style={[AtomicCss.whiteCardNew, styles.cardWrapper, AtomicCss.borderRadius16]}>
      <View style={AtomicCss.flex1}>
        {isNotNullAndEmpty(hotelDetailList.hotelImageURLList) && (
          <SliderImages
            imageList={hotelDetailList.hotelImageURLList}
            arrows={true}
            pageInfo={true}
            pageInfoBottom={true}
          />
        )}
      </View>
      {(response.mmtvalueStays || hotelDetailList.mySafety || response.isLuxuryHotel) && (
        <View
          style={[
            AtomicCss.paddingLeft12,
            AtomicCss.paddingBottom12,
            AtomicCss.flexRow,
            AtomicCss.flexWrap,
          ]}
        >
          {response.mmtvalueStays && (
            <View style={AtomicCss.marginRight8}>
              <Image
                source={{
                  uri: response.isCorporateBooking ? myBizAssuredImgURI : mmtValueStaysImgURI,
                }}
                style={styles.ValueStaysImg}
              />
            </View>
          )}
          {response?.isDiscountingCampaign && discountingCampaignImageUrl && (
            <View style={[AtomicCss.marginRight8]}>
              <Image source={{ uri: discountingCampaignImageUrl }} style={styles.discountingCampaingnImg} />
            </View>
          )}
          {response.isLuxuryHotel && response.luxTagImg && (
            <View style={AtomicCss.marginRight8}>
              <Image
                source={{
                  uri: response.luxTagImg,
                }}
                style={styles.luxImg}
                resizeMode='contain'
              />
            </View>
          )}
          {hotelDetailList.mySafety && (
            <View style={AtomicCss.marginRight8}>
              <Image source={MYSAFETY_DELLOITE_ICON} style={styles.mySafetyImg} />
            </View>
          )}
        </View>
      )}
      <HotelTopCard
        rating={hotelDetailList.hotelCategoryCode}
        propertyName={hotelDetailList.name}
        category={hotelDetailList.hotelCategory}
        showAltAccoRating={hotelDetailList.showAltAccoRating}
      />
      <View
        style={[
          styles.btnWrapper,
          AtomicCss.flexRow,
          AtomicCss.alignCenter,
          AtomicCss.spaceBetween,
        ]}
      >
        {card && isNotNullAndEmpty(card.actionList) && getActionButton(card, response, handleClick)}
      </View>
    </View>
  );
};

export default PropertyMainCard;
