import React from 'react';
import { View, Text, Image, I18nManager } from 'react-native';
import isEmpty from 'lodash/isEmpty';
import RefundCard from './RefundCard';
import ClaimRaised from '../../flights/modules/details/components/ClaimRaised';
import RectFullButton from '../../hotel/details/ActionButton/RectFullButton';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import RefundFeedback from './RefundFeedback';
import RaiseSupportReqCard from './LostIdRefundCard/RaiseSupportReqCard';
import { LOBNAMES } from '../../PostSalesConstant';
import { getFont } from '../../PostSalesStyles';
import { fonts } from '@mmt/legacy-commons/Styles/globalStyles';
import { getStaticData } from '../../staticData/staticData';

const rupeeIcon = I18nManager.isRTL
  ? require('@mmt/legacy-assets/src/ic_refundAraCurrency.webp')
  : require('@mmt/legacy-assets/src/refundIcon.webp');

class RefundTrackerWithClaim extends React.Component {
  constructor(props) {
    super(props);
    this.fonts = getFont([LOBNAMES.FLIGHT].includes(props.lobName) || props.isLostBookingId);
  }

  renderTotalRefund = (trackerData) => {
    const { totalRefundAmt, isRefundProcessed } = trackerData;
    const { totalRefundText, totalRefundProcessedText, currency } =
      this.props.bookingResponse || {};
    const header =
      isRefundProcessed && totalRefundProcessedText ? totalRefundProcessedText : totalRefundText;
    if (this.displayTotalRefund(trackerData)) {
      return (
        <View
          style={[
            AtomicCss.flexRow,
            AtomicCss.alignCenter,
            styles.totalAmtView,
            this.props.isRoundedCornerAndPadding
              ? [AtomicCss.borderRadius10, AtomicCss.marginHz12]
              : null
          ]}
        >
          <Image
            style={[
              I18nManager.isRTL ? styles.currencyIconStyle : styles.headingIconStyle,
              AtomicCss.marginLeft10,
            ]}
            source={rupeeIcon}
          />
          <Text style={[AtomicCss.defaultText, this.fonts.blackFontFamily, AtomicCss.font14]}>
            {header} {`${currency} ${totalRefundAmt}`}
          </Text>
        </View>
      );
    }
    return null;
  };

  refreshTracker = () => {
    this.props.refundTrackerReq();
  };

  renderCountView = (count) => {
    return (
      <View style={styles.countWrapper}>
        <Text style={[styles.countTxt, this.fonts.boldFontFamily]}>{count}</Text>
      </View>
    );
  };

  renderRefundFeedback = ({
    requestNo = '',
    refundNotReceivedDetails,
    refundReceivedOrNotDetails,
    isLostBookingId,
  }) => {
    if (!isEmpty(refundReceivedOrNotDetails)) {
      return (
        <RefundFeedback
          {...refundReceivedOrNotDetails}
          bookingId={this.props.bookingId}
          requestNo={requestNo}
          refundNotReceivedDetails={refundNotReceivedDetails}
          lobName={this.props.lobName}
          isLostBookingId={isLostBookingId}
          pageName={this.props.pageName}
          fonts={this.fonts}
        />
      );
    }
    return null;
  };

  renderRaiseReqCard = ({ tatBreachedText, tatBreachedActions }, requestNo = '') => {
    const { isLostBookingId, bookingId, lobName, pageName } = this.props;
    if (isEmpty(tatBreachedText)) {
      return null;
    }
    return (
      <RaiseSupportReqCard
        tatBreachedText={tatBreachedText}
        tatBreachedActions={tatBreachedActions}
        bookingId={bookingId}
        requestNo={requestNo}
        lobName={lobName}
        isLostBookingId={isLostBookingId}
        pageName={pageName}
        fonts={this.fonts}
      />
    );
  };

  render() {
    const staticData = getStaticData();
    const { viewRefundCalculationText } = staticData;
    const { refundTrackerData, isLostBookingId } = this.props;
    const views = [];
    if (refundTrackerData && !isEmpty(refundTrackerData.refundStatusList)) {
      views.push(this.renderTotalRefund(refundTrackerData));
      this.fetchRefundDetails(views, refundTrackerData, viewRefundCalculationText);
    }
    return (
      <View
        style={[
          this.props.isRoundedCornerAndPadding ? null : AtomicCss.marginBottom10,
          isLostBookingId ? AtomicCss.marginTop10 : null,
        ]}
      >
        {views}
      </View>
    );
  }

  displayTotalRefund = (trackerData) => {
    const { refundStatusList } = trackerData;
    const { hideTotalRefund } = this.props.bookingResponse || {};
    return hideTotalRefund === false && refundStatusList && refundStatusList.length > 1;
  };

  fetchRefundDetails = (cardViews, refundTrackerData, viewRefundCalculationText) => {
    const { isLostBookingId, lostRequestNo, showIconImg, customStyle, redirectLinkCustomStyle, allowViewMore } = this.props;
    const { currency } = this.props.bookingResponse || {};
    let lostIdRefundFeedbackCard = null;
    let lostIdRaiseReqCard = null;
    refundTrackerData.refundStatusList.forEach((item, index) => {
      const views = [];
      const countView = this.displayTotalRefund(refundTrackerData)
        ? this.renderCountView(index + 1)
        : null;
      if (item) {
        if (item.specialClaimRaisedButNotProcessed) {
          views.push(
            <ClaimRaised
              key={index}
              cardText={item.specialClaimRefundMessage}
              countView={countView}
            />,
          );
        } else {
          const requestNo = isLostBookingId
            ? lostRequestNo
            : !isEmpty(refundTrackerData.requestNoList) && refundTrackerData.requestNoList[index];
          const refundReceivedOrNotDetails =
            !isEmpty(refundTrackerData.refundReceivedOrNotDetailsList) &&
            refundTrackerData.refundReceivedOrNotDetailsList[index];
          const refundFeedbackCard = this.renderRefundFeedback({
            ...item,
            requestNo,
            refundReceivedOrNotDetails:
              refundReceivedOrNotDetails || refundTrackerData.refundReceivedOrNotDetails,
            isLostBookingId,
          });
          const raiseSupportReqCard =
            !lostIdRaiseReqCard && refundTrackerData.isTatBreached === false
              ? this.renderRaiseReqCard(refundTrackerData, requestNo)
              : null;
          if (isLostBookingId) {
            lostIdRefundFeedbackCard = lostIdRefundFeedbackCard || refundFeedbackCard;
            lostIdRaiseReqCard = lostIdRaiseReqCard || raiseSupportReqCard;
          }
          views.push(
            <RefundCard
              key={index}
              countView={countView}
              {...item}
              refundReceivedOrNotDetails={refundReceivedOrNotDetails}
              requestNo={requestNo}
              refreshTracker={this.refreshTracker}
              bookingId={this.props.bookingId}
              trackRefundEvent={this.props.trackRefundEvent}
              onRefundModeWebViewBackPress={this.props.onRefundModeWebViewBackPress}
              onRefundModeSubMessageLinkPress={this.props.onRefundModeSubMessageLinkPress}
              isRoundedCornerAndPadding={this.props.isRoundedCornerAndPadding}
              currency={currency}
              lobName={this.props.lobName}
              isLostBookingId={isLostBookingId}
              refundFeedbackCard={isLostBookingId ? null : refundFeedbackCard}
              pageName={this.props.pageName}
              fonts={this.fonts}
              customStyle={customStyle}
              showIconImg={showIconImg}
              allowViewMore={allowViewMore}
            />,
          );
          if (item.actionList && item.actionList.length) {
            const actionView = [];
            item.actionList.forEach((action) => {
              if (
                action.actionType != 'ViewRefundBreakup' ||
                (action.actionType == 'ViewRefundBreakup' &&
                  refundTrackerData.refundBreakupVersionData &&
                  refundTrackerData.refundBreakupVersionData.version == 1)
              ) {
                actionView.push(
                  <RectFullButton
                    action={{ actionLabeltext: action.actionText }}
                    clickHandler={() => this.props.actionaHandler(action.actionType, item, action)}
                    customStyle={redirectLinkCustomStyle}
                  />,
                );
              }
            });
            actionView.length &&
              views.push(
                <View
                  style={[
                    { backgroundColor: '#fff' },
                    this.props.isRoundedCornerAndPadding
                      ? [AtomicCss.borderRadius10, styles.overflowHidden]
                      : null,
                  ]}
                >
                  {actionView}
                </View>,
              );
          } else if (this.props.showRefundCalculationBtn && !item.isSpecialClaimTracker) {
            if (
              refundTrackerData.refundBreakupVersionData &&
              refundTrackerData.refundBreakupVersionData.version == 1
            ) {
              views.push(
                <View style={[{ backgroundColor: '#fff' }]}>
                  <RectFullButton
                    action={{ actionLabeltext: viewRefundCalculationText }}
                    clickHandler={() =>
                      this.props.refundCalculationBtnClick(refundTrackerData.requestNoList[index])
                    }
                  />
                </View>,
              );
            }
          }
          if (
            index == refundTrackerData.refundStatusList.length - 1 &&
            refundTrackerData.refundBreakupVersionData &&
            refundTrackerData.refundBreakupVersionData.version == 2 &&
            refundTrackerData.refundBreakupVersionData.actionCta
          ) {
            views.push(
              <RectFullButton
                action={{
                  actionLabeltext: refundTrackerData.refundBreakupVersionData.actionCta.actionText,
                }}
                clickHandler={
                  this.props.refundCalculationBtnClick &&
                  this.props.refundCalculationBtnClick.bind(
                    null,
                    refundTrackerData.requestNoList[index],
                    2,
                  )
                }
              />,
            );
          }
        }

        if (this.props.card && this.props.card.showWalletToBankUrl) {
          views.push(
            <RectFullButton
              action={{ actionLabeltext: this.props.card.heading }}
              clickHandler={this.callWalletToBank}
            />,
          );
        }
        if (this.props.card && this.props.renderRefundButton) {
          views.push(this.props.renderRefundButton(this.props.card));
        }
      }
      views.length &&
        cardViews.push(
          <View
            style={[
              this.props.isRoundedCornerAndPadding
                ? [AtomicCss.whiteCardNew, AtomicCss.marginHz12, customStyle?customStyle:{}]
                : null,
              !isEmpty(this.props.cardStyle)
              ? this.props.cardStyle
              : null
            ]}
          >
            {views}
          </View>,
        );
    });

    !!lostIdRefundFeedbackCard &&
      cardViews.push(
        <View
          style={
            this.props.isRoundedCornerAndPadding
              ? [AtomicCss.whiteCardNew, AtomicCss.marginHz12]
              : null
          }
        >
          {lostIdRefundFeedbackCard}
        </View>,
      );

    lostIdRaiseReqCard && cardViews.push(lostIdRaiseReqCard);
  };

  callWalletToBank = () => {
    GenericModule.openDeepLink(this.props.card.walletToBankTransferDeepLink);
  };
}

const styles = {
  headingIconStyle: {
    width: 20,
    height: 19,
    marginRight: 10,
  },
  currencyIconStyle: {
    width: 24,
    height: 24,
    marginRight: 10,
  },
  totalAmtView: {
    paddingVertical: 15,
    backgroundColor: '#fff',
    paddingHorizontal: 10,
  },
  countWrapper: {
    width: 24,
    height: 24,
    backgroundColor: '#e7e7e7',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 50,
    top: -3,
  },
  countTxt: {
    color: '#747474',
  },
  overflowHidden: {
    overflow: 'hidden',
  }
};

export default RefundTrackerWithClaim;
