import { StyleSheet } from "react-native";
import { DEFAULT_THEME } from "apps/post-sales/src/theme/default.theme";

export default  <T extends typeof DEFAULT_THEME>(theme: T, fonts: any) => StyleSheet.create({
    container: {
        width: '100%',
        height: 160,
        alignItems: 'center',
        justifyContent: 'center',
    },
    carousalContainer: {
        width: '100%',
        height: '100%',
    },
    image: {
        height: '100%',
        width: '100%',
        borderRadius: 16,
    },
    imgContainer: {
        flex: 1,
    },
    slideBg: {
        backgroundColor: 'rgba(0,0,0,0.4)',
        width: '100%',
        height: '100%',
        position: 'absolute',
        borderRadius: 16
    },
    textStyle: {
        position: 'absolute',
        bottom: 20,
        left: 20,
        width: '80%',
        color: theme.color.white,
        fontSize: fonts.fontSize.font16,
        ...fonts.blackFontFamily
    }
});
