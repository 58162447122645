import React, { useState, useEffect } from 'react';
import { Keyboard } from 'react-native';
import styles from './NeedMoreHelpOverlayCss';
import { LOBNAMES, NEED_MORE_HELP } from '../../PostSalesConstant';
import HelpingHandOverlay from './BottomOverlay/HelpingHandOverlay';
import ReqstComfirmOverlay from './BottomOverlay/ReqstConfirmOverlay';
import ReqstCallScheduleOverlay from './BottomOverlay/ReqstCallScheduleOverlay';
import ReqstWaitingOverlay from './BottomOverlay/ReqstWaitingOverlay';
import ErrorOverlay from './BottomOverlay/ErrorOverlay';
import { openCustomerFormInWebView } from '../../utils/PostSaleUtil';
import CommonOverlayMessage from '../CommonOverlayMessage';
import { isHelpingHandEnabled, openDialer } from '../commonUtil';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';

const NeedMoreHelpOverlay = ({
  component,
  bookingId,
  pageName,
  handleReload,
  layoutData,
  handleBottomOverlay,
  hideLoader,
  trackEvent,
  callDrivers,
  addPadding = false,
  lobName,
}) => {
  const [bottom, setBottom] = useState(0);
  const [overlayType, setOverlayType] = useState(component);
  const [config, setConfig] = useState('');
  const [timeSlot, setTimeSlot] = useState('');

  let isCallScheduled = false;

  const callScheduled = () => {
    isCallScheduled = true;
  };

  const overlayOutsideClick = () => (!isCallScheduled ? handleBottomOverlay(' ') : handleReload());

  const overlayActionHandler = (overlay, item) => {
    if (overlay === NEED_MORE_HELP.CONNECT_HELPDESK) {
      if (item && item.url) {
        actionHandler(item);
      }
    } else if (overlay === NEED_MORE_HELP.CALL_US) {
      if (item && item.contactNo) {
        openDialer(item.contactNo);
      }
    } else {
      setOverlayType(overlay);
    }
  };

  const actionHandler = async (action) => {
    if (action && action.url) {
      openCustomerFormInWebView(action, bookingId, 'CONNECT_HELPDESK');
    }
  };

  const handleClick = (params) => {
    setConfig(params[0]);
    setTimeSlot(params[1]);
    overlayActionHandler(NEED_MORE_HELP.REQUEST_CONFIRM);
  };

  const renderContent = () => {
    switch (overlayType) {
      case NEED_MORE_HELP.REQUEST_CALLBACK:
      case NEED_MORE_HELP.HELPING_HAND:
        if (LOBNAMES.FLIGHT === lobName){
          if (!isHelpingHandEnabled()) {
            return null;
          } else {
              return (
                <HelpingHandOverlay
                  handleBottomOverlay={overlayActionHandler}
                  bookingId={bookingId}
                  setConfig={setConfig}
                  pageName={pageName}
                  overlayOutsideClick={overlayOutsideClick}
                  hideLoader={hideLoader}
                  bottom={bottom}
                  trackEvent={trackEvent}
                  callDrivers={callDrivers}
                  addPadding={addPadding}
                  lobName={lobName}
                />
              );
          }
        } else {
            return (
              <HelpingHandOverlay
                handleBottomOverlay={overlayActionHandler}
                bookingId={bookingId}
                setConfig={setConfig}
                pageName={pageName}
                overlayOutsideClick={overlayOutsideClick}
                hideLoader={hideLoader}
                bottom={bottom}
                trackEvent={trackEvent}
                callDrivers={callDrivers}
                addPadding={addPadding}
                lobName={lobName}
              />
            );
        }
      case NEED_MORE_HELP.REQUEST_CONFIRM:
        return (
          <ReqstComfirmOverlay
            bookingId={bookingId}
            configId={config}
            timeSlot={timeSlot}
            handleTime={handleReload}
            callScheduled={callScheduled}
            overlayOutsideClick={overlayOutsideClick}
            trackEvent={trackEvent}
            pageName={pageName}
            callDrivers={callDrivers}
            lobName={lobName}
          />
        );
      case NEED_MORE_HELP.REQUEST_SCHEDULE:
        return (
          <ReqstCallScheduleOverlay
            bookingId={bookingId}
            handleClick={handleClick}
            overlayOutsideClick={overlayOutsideClick}
            trackEvent={trackEvent}
            pageName={pageName}
            callDrivers={callDrivers}
            lobName={lobName}
          />
        );
      case NEED_MORE_HELP.REQUEST_WAITING:
        return (
          <ReqstWaitingOverlay
            bookingId={bookingId}
            handleTime={handleReload}
            callScheduled={callScheduled}
            configId={config}
            overlayOutsideClick={overlayOutsideClick}
            trackEvent={trackEvent}
            pageName={pageName}
            callDrivers={callDrivers}
            lobName={lobName}
          />
        );
      case NEED_MORE_HELP.REQUEST_ERROR:
        return (
          <ErrorOverlay
            handleBottomOverlay={overlayActionHandler}
            layoutData={layoutData}
            overlayOutsideClick={overlayOutsideClick}
            pageName={pageName}
            callDrivers={callDrivers}
          />
        );
      default:
        return null;
    }
  };

  const _keyboardDidShow = (e) => {
    setBottom(e.endCoordinates.height);
  };

  const _keyboardDidHide = (e) => {
    setBottom(0);
  };

  useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', _keyboardDidShow);
    const keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', _keyboardDidHide);
    return () => {
      keyboardDidShowListener.remove();
      keyboardDidHideListener.remove();
    };
  }, []);

  if (
    hideLoader &&
    [NEED_MORE_HELP.REQUEST_CALLBACK, NEED_MORE_HELP.HELPING_HAND].includes(overlayType)
  ) {
    return renderContent();
  } else {
    return (
      <OverlayWrapper
        content={renderContent()}
        overlayOutsideClick={overlayOutsideClick}
        bottom={bottom}
        addPadding={addPadding}
      />
    );
  }
};

const OverlayWrapper = ({ content, overlayOutsideClick, bottom, addPadding }) => {
  return (
    <CommonOverlayMessage
      overlayWrapperStyle={[styles.overlayWrapper, addPadding ? AtomicCss.paddingBottom20 : null]}
      overlayContentStyle={[styles.overlayContent, { bottom }]}
      handleBottomOverlay={overlayOutsideClick}
      content={content}
    />
  );
};

export default NeedMoreHelpOverlay;
