import React from 'react';
import { StyleSheet, View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import { SummaryContainer } from './cardElements';

const SkeltonLoaderCard = ({
  gradientColor = [colors.skeltonGradientStartColor, colors.skeltonGradientEndColor],
}) => {
  return (
    <SummaryContainer>
      <View style={styles.mainContent}>
        <LinearGradient
          colors={gradientColor}
          start={{ x: 1.0, y: 0.0 }}
          end={{ x: 0.1, y: 0.1 }}
          style={styles.imageGradient}
        />
        <View style={styles.rightGradient}>
          <LinearGradient
            colors={gradientColor}
            start={{ x: 0, y: 0 }}
            end={{ x: 1, y: 0 }}
            style={[styles.headerTextSkeltonWidth, styles.textSkeltonHeight]}
          />
          <LinearGradient
            colors={gradientColor}
            start={{ x: 0, y: 0 }}
            end={{ x: 1, y: 0 }}
            style={[styles.otherTextSkeltonWidth, styles.textSkeltonHeight]}
          />
          <LinearGradient
            colors={gradientColor}
            start={{ x: 0, y: 1 }}
            end={{ x: 1, y: 1 }}
            style={[styles.otherTextSkeltonWidth, styles.textSkeltonHeight]}
          />
        </View>
      </View>
    </SummaryContainer>
  );
};

const styles = StyleSheet.create({
  imageGradient: {
    height: 40,
    width: 40,
    borderRadius: 2,
    marginRight: 10,
    marginVertical: 5,
  },
  textSkeltonHeight: {
    height: 13,
  },
  headerTextSkeltonWidth: {
    width: 130,
  },
  otherTextSkeltonWidth: {
    width: 200,
  },
  mainContent: {
    paddingVertical: 20,
    paddingHorizontal: 10,
    flexDirection: 'row',
  },
  rightGradient: {
    justifyContent: 'space-between',
  },
});

export default SkeltonLoaderCard;
