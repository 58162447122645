import { StyleSheet } from 'react-native';

const createStyles = (theme) => StyleSheet.create({
    pageWrapper: {
      flex: 1,
    },
    cardsWrapper: {
      backgroundColor: theme.color.lighterBlue,
      top: 0,
      marginBottom: 20,
    },
  });
export default createStyles;
