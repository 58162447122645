import React, { Component, useState } from 'react';
import { Image, Text, TouchableOpacity, View } from 'react-native';
import PropTypes from 'prop-types';
import createStyles from './styles';
import CommonStyle from '@mmt/legacy-commons/Styles/AtomicCss';
import { getImageIcon } from "../../../../helper";
import { getStaticData } from '../../../../../../../staticData/staticData';
import { getFont } from '../../../../../../../PostSalesStyles';
import { useTheme } from '../../../../../../../theme/theme.context';
import { addSpaceWithAmount } from '../../../../../../../utils/PostSaleUtil';
import isEmpty from 'lodash/isEmpty';

const CancellationDeductionBreak = (props) => {

  const [fareBreakupDtls, setFareBreakupDtls] = useState(true);

  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);
  const { title, total, items = [] } = props;
  const { viewBreakupText, hideBreakupTest } = getStaticData();
  const activeDtls = fareBreakupDtls ? hideBreakupTest : viewBreakupText;

  const addDeductionBreakup = (pricingComponent) =>
    pricingComponent.map((pricingBreakup, index) => {
      const arrayLength = pricingComponent.length;
      const { fsStyle, ...fonts } = getFont(true);
      return (
        <View
          key={pricingBreakup.subtitle}
          style={arrayLength === index + 1 ? { marginBottom: 0 } : { marginBottom: 20 }}
        >
          <View>
            <View style={styles.fareBrkupRowLine} />
            <View style={styles.fareBrkupRowLineWrapperTxt}>
              <Text style={[fonts.regularFontFamily, fsStyle.font12]}>
                {pricingBreakup.subtitle}
              </Text>
            </View>
          </View>
          {addDeductionComponent(pricingBreakup.components)}
        </View>
      );
    });

  const handleFareBreakup = () => {
    let clickEvent = 'CancellationCharges_View_Breakup';
    if (fareBreakupDtls) {
      clickEvent = 'CancellationCharges_Hide_Breakup';
    }
    props.showHideBreakUpClick && props.showHideBreakUpClick(clickEvent);
    setFareBreakupDtls((prev) => !prev);
  };

  const addDeductionComponent = (components) =>
    components.map((component) => {
      const { fsStyle, ...fonts } = getFont(true);
      return (
        <View key={component.description} style={styles.fareBrkupRow}>
          <View style={[CommonStyle.flexRow, styles.descLeftWrap]}>
            <Image style={styles.dbIconSm} source={getImageIcon(component.img)} />
            <Text style={[styles.fareBrkupTxt, fonts.regularFontFamily, fsStyle.font14]}>
              {component.description}
            </Text>
          </View>
          <View style={[CommonStyle.flexRow, CommonStyle.alignSelfFlexEnd, styles.priceText]}>
            {component.showSlasher && (
              <Text
                style={[
                  styles.fareBrkupTxtPrice,
                  fonts.regularFontFamily,
                  styles.lineThrough,
                  styles.priceGap,
                  fsStyle.font14,
                ]}
              >
                {addSpaceWithAmount(component.slashedValue)}
              </Text>
            )}
            <Text style={[styles.fareBrkupTxtPrice, fonts.regularFontFamily, fsStyle.font14]}>
              {addSpaceWithAmount(component.actualValue)}
            </Text>
          </View>
        </View>
      );
    });

  const getRefundAttributeMessage = () => {
    const views = [];
    const { fsStyle, ...fonts } = getFont(true);
    props.messages.forEach((attribute) => {
      if (attribute) {
        const attributeObj = attribute;
        const imageIcon = getImageIcon(attributeObj.attributImg);
        views.push(
          <View key={attributeObj.attributSubTitle} style={CommonStyle.flexRow}>
            {imageIcon && <Image style={styles.redInfoIconSm} source={imageIcon} />}
            <Text
              style={[
                styles.refundbottomDisc,
                fonts.regularFontFamily,
                { color: attributeObj.attributSubTitleColor },
                fsStyle.font12,
              ]}
            >
              {attributeObj.attributSubTitle}
            </Text>
          </View>,
        );
      }
    });
    return views;
  };

  const getUncancelledFare = () => {
    const views = [];
    const { fsStyle, ...fonts } = getFont(true);
    if (props.uncancelledTicketFare) {
      views.push(
        <View style={[styles.fareBrkupRow, styles.unCancelledWrapper]}>
          <Text
            style={[
              styles.unCancelledTxt,
              fonts.blackFontFamily,
              { color: props.uncancelledTicketFare.color },
              fsStyle.font16,
            ]}
          >
            {props.uncancelledTicketFare.text}
          </Text>
          <Text
            style={[
              styles.unCancelledTxt,
              fonts.blackFontFamily,
              { color: props.uncancelledTicketFare.color },
              fsStyle.font16,
            ]}
          >
            {addSpaceWithAmount(props.uncancelledTicketFare.value)}
          </Text>
        </View>,
      );
    }
    return views;
  };

  return (
    <View style={styles.DeductionBreakUpWrapper}>
      <View style={[styles.fareBreakupHeading, CommonStyle.marginBottom10]}>
        <View style={CommonStyle.flexRow}>
          <Text
            style={[
              styles.fbHeadingTxt,
              fonts.blackFontFamily,
              { color: props.deductions.lbl.color },
              fsStyle.font16,
            ]}
          >
            {title}
          </Text>
          {!isEmpty(items) && (
            <TouchableOpacity onPress={handleFareBreakup} activeOpacity={0.5}>
              <Text style={[styles.linkTxt, fonts.boldFontFamily, fsStyle.font12]}>
                {activeDtls}
              </Text>
            </TouchableOpacity>
          )}
        </View>
        <Text
          style={[
            styles.totalPaidPriceTxt,
            fonts.blackFontFamily,
            { color: props.deductions.lbl.color },
            fsStyle.font16,
          ]}
        >
          {addSpaceWithAmount(total)}
        </Text>
      </View>
      {fareBreakupDtls &&
        addDeductionBreakup(items)}
      {getUncancelledFare()}
      <View style={[styles.fareBreakupDtlsWrapper]}>
        <View style={[styles.fareBrkupRow, styles.totalRefund]}>
          <Text
            style={[
              styles.totalRefundTxt,
              fonts.blackFontFamily,
              { color: props.refundAmount.color },
              fsStyle.font20,
            ]}
          >
            {props.refundAmount.totalRefundlabel}
          </Text>
          <Text
            style={[
              styles.totalRefundTxt,
              fonts.blackFontFamily,
              { color: props.refundAmount.color },
              fsStyle.font20,
            ]}
          >
            {addSpaceWithAmount(props.refundAmount.totalRefund)}
          </Text>
        </View>
      </View>
      {getRefundAttributeMessage()}
    </View>
  );

}

CancellationDeductionBreak.propTypes = {
  deductions: PropTypes.object.isRequired,
  refundAmount: PropTypes.object.isRequired,
  uncancelledTicketFare: PropTypes.object.isRequired,
  messages: PropTypes.object.isRequired,
  showHideBreakUpClick: PropTypes.func.isRequired,
};

export default CancellationDeductionBreak;
