import React from "react";
import { View, Text } from 'react-native'
import { getFont } from '../../../../../../PostSalesStyles';
import { addSpaceWithAmount } from "../../../../../../utils/PostSaleUtil";
import createStyles from '../css/RescheduleFareConfirmationOverlayCss'
import { useTheme } from '../../../../../../theme/theme.context';
import { getStaticData } from '../../../../../../staticData/staticData';

const PriceBreakUp = ({ selectedFlightItem, currency }) => {
  const { farediff, nf, anc, oldTotal, dct, dcf, dcMarkup, pay } = selectedFlightItem;
  const priceBreakup = selectedFlightItem.priceBreakup;

  const {
    fareDifferenceText,
    newFareText,
    dateChangeFeesText,
    airlineFeesText,
    makeMyTripFeesText,
    netAddnlDifferenceText,
    netDifferenceText,
    oldFareInclAncillariesText,
    oldFareText
  } = getStaticData();
  const getOldFareText = (ancAmount) => {
    return ancAmount > 0 ? oldFareInclAncillariesText : oldFareText;
  }
  const fonts = getFont(true);
  const { psTheme: theme } = useTheme()
  const styles = createStyles(theme, fonts);
  return (
    <View>
      <View style={[styles.OverlayDtlsContentMain]}>
        <View style={styles.OverlayDtlsContent}>
          <Text style={styles.HeadingTxt}>{fareDifferenceText}</Text>
          <Text style={styles.HeadingTxt}>
            {currency}{' '}
            {priceBreakup
              ? addSpaceWithAmount(priceBreakup.fareDifference)
              : addSpaceWithAmount(farediff)}{' '}
          </Text>
        </View>
        <View style={styles.OverlayDtlsContent}>
          <Text style={styles.OverlayInnerTxt}>{newFareText}</Text>
          <Text style={styles.OverlayInnerTxt}>
            {currency}{' '}
            {priceBreakup ? addSpaceWithAmount(priceBreakup.newFare) : addSpaceWithAmount(nf)}{' '}
          </Text>
        </View>
        <View style={styles.OverlayDtlsContent}>
          <Text style={styles.OverlayInnerTxt}>{getOldFareText(anc)}</Text>
          <Text style={styles.OverlayInnerTxt}>
            {currency}{' '}
            {priceBreakup
              ? addSpaceWithAmount(priceBreakup.oldFareWithAncillary)
              : addSpaceWithAmount(oldTotal)}{' '}
          </Text>
        </View>
      </View>
      <View style={[styles.OverlayDtlsContentMain]}>
        <View style={styles.OverlayDtlsContent}>
          <Text style={styles.HeadingTxt}>{dateChangeFeesText}</Text>
          <Text style={styles.HeadingTxt}>
            {currency}{' '}
            {priceBreakup
              ? addSpaceWithAmount(priceBreakup.dateChangeFee)
              : addSpaceWithAmount(dct)}{' '}
          </Text>
        </View>
        <View style={styles.OverlayDtlsContent}>
          <Text style={styles.OverlayInnerTxt}>{airlineFeesText}</Text>
          <Text style={styles.OverlayInnerTxt}>
            {currency}{' '}
            {priceBreakup ? addSpaceWithAmount(priceBreakup.airlineDateChangeFee) : dcf}{' '}
          </Text>
        </View>
        {((priceBreakup && priceBreakup.mmtServiceFee) || dcMarkup) > 0 && (
          <View style={styles.OverlayDtlsContent}>
            <Text style={styles.OverlayInnerTxt}>{makeMyTripFeesText}</Text>
            <Text style={styles.OverlayInnerTxt}>
              {currency}{' '}
              {priceBreakup
                ? addSpaceWithAmount(priceBreakup.mmtServiceFee)
                : addSpaceWithAmount(dcMarkup)}{' '}
            </Text>
          </View>
        )}
      </View>
      {((priceBreakup && priceBreakup.totalPayable >= 0) || pay >= 0) && (
        <View style={styles.TotalAmountWrapper}>
          <Text style={styles.TotalAmountTxt}>{netAddnlDifferenceText}</Text>
          <Text style={styles.TotalAmountTxt}>
            {currency}{' '}
            {priceBreakup
              ? addSpaceWithAmount(priceBreakup.totalPayable)
              : addSpaceWithAmount(pay)}{' '}
          </Text>
        </View>
      )}
      {((priceBreakup && priceBreakup.totalPayable < 0) || pay < 0) && (
        <View style={styles.TotalAmountWrapper}>
          <Text style={styles.TotalAmountTxt}>{netDifferenceText}</Text>
          <Text style={styles.TotalAmountTxt}>
            {currency}{' '}
            {priceBreakup
              ? addSpaceWithAmount(priceBreakup.totalPayable)
              : addSpaceWithAmount(pay)}{' '}
          </Text>
        </View>
      )}
    </View>
  );
};

export default PriceBreakUp;