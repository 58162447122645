import React from 'react';
import { View, Text,Image ,TouchableOpacity} from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import isEmpty from 'lodash/isEmpty';
import createStyles from "./helpCss";
import TGSBookingConstants, { CLICK_TYPE, PAGE_NAME } from '../../../../Utils/TGSBookingConstants';
import {callHelpLine} from "../../../../Utils/TgsBookingDetailUtil"
import { getFont } from '../../../../../PostSalesStyles';
import { useTheme } from '../../../../../theme/theme.context';
import TGSBookingTrackEventHelper from '../../../../Utils/TGSBookingTrackEventHelper';
const HelpCard = ({card,response}) => {
    const {heading} = card || {};
    const {contactUs}  = response || {};
    const fonts = getFont();
    const {psTheme : theme} = useTheme();
    const styles = createStyles(theme,fonts);
    const handleHelp = (action) =>{
      TGSBookingTrackEventHelper.trackClickEvents(PAGE_NAME.TRIP_DETAILS, CLICK_TYPE.CALL_US_CLICK)
      if(action === TGSBookingConstants.CALL_US ){
        callHelpLine();
      }
    }
    const getHelpCard = (type) => {
        const { heading, icon,action } = type || {};
        const { url } = icon || {};
        return (<TouchableOpacity  onPress={()=>handleHelp(action)}>
            {!isEmpty(url) && <View style={styles.serviceIconWrapper}>
                <Image style={styles.helpIconStyle} source={{uri:url}} />
            </View> }
            {!!heading && <Text style={styles.infoText}>{heading}</Text>}
        </TouchableOpacity>)
    }
    return (
            <View style={[styles.helpCard,AtomicCss.marginBottom10]}>
                <View style={styles.heading}>
                <Text style={[AtomicCss.font16, AtomicCss.blackText, AtomicCss.marginBottom14,fonts.boldFontFamily]}>{heading}</Text>
                </View>
               <View  style={[AtomicCss.flexRow,AtomicCss.justifyCenter]}>
                 {!isEmpty(contactUs) && getHelpCard(contactUs)}
                 </View>
            </View>
    )
}

export default HelpCard;
