import React from 'react';
import { View, Text, TouchableOpacity, ScrollView } from 'react-native';
import isEmpty from 'lodash/isEmpty';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import createStyles from './ImportantInfoBottomSheetCss';
import { getFont } from '../../../../PostSalesStyles';
import { useTheme } from '../../../../theme/theme.context';
import { getStaticData } from 'apps/post-sales/src/staticData/staticData';


const ImportantInfoBottomSheet = ({ handleBottomOverlay, data }) => {
  const { fsStyle, ...fonts } = getFont();
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);
  const { heading, infoList } = data;
  const staticData = getStaticData();
  return (
    <View style={styles.overlayContentWrapper}>
      {!!heading && (
        <Text
          style={[
            fonts.blackFontFamily,
            fsStyle.font20,
            AtomicCss.blackText,
            AtomicCss.marginBottom12,
          ]}
        >
          {heading}
        </Text>
      )}

      {!isEmpty(infoList) && (
        <ScrollView>
          <View style={styles.headBottomBar} />
          <View style={[AtomicCss.marginTop10, AtomicCss.marginBottom12]}>
            {infoList.map((item, i) => {
              return (
                <React.Fragment key={i}>
                  <Text
                    style={[
                      AtomicCss.defaultText,
                      fsStyle.font14,
                      fonts.regularFontFamily,
                      AtomicCss.lineHeight20,
                      AtomicCss.marginBottom15,
                    ]}
                  >
                    <Text style={[fsStyle.font20]}>•</Text> {item.info}
                  </Text>
                </React.Fragment>
              );
            })}
          </View>
        </ScrollView>
      )}

      <TouchableOpacity style={[AtomicCss.justifyCenter]} onPress={handleBottomOverlay}>
        <Text
          style={[
            fsStyle.font16,
            fonts.boldFontFamily,
            {color: theme.color.azure},
            styles.upperText,
            AtomicCss.textCenter,
          ]}
        >
          {staticData.okayGotItText}
        </Text>
      </TouchableOpacity>
    </View>
  );
};

export default ImportantInfoBottomSheet;
