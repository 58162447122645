import React from 'react';
import PropTypes from 'prop-types';
import {Image, StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import {getParsedString, getPromiseDateTimeObj} from '../../../utils/MyPromiseUtil';
import HotelBookingDetailsConstant from '../HotelBookingDetailsConstant';
import {appendRupeeSymbol, isNotNullAndEmpty} from '../utils/HotelBookingDetailUtil';
import {colors} from '@mmt/legacy-commons/Styles/globalStyles';
import { getStaticData } from '../../../staticData/staticData';
import { GREEN_TICK_ICON, DOUBLE_GREEN_TICK_ICON } from '../../HotelImageConstants';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const singleTick = GREEN_TICK_ICON;
const doubleTick = DOUBLE_GREEN_TICK_ICON;

export default class DetailRefundProcessCard extends React.Component {
  constructor(props) {
    super(props);
    this.fonts = getFont(true);
    this.styles = getStyles(this.fonts);
  }

  render() {
    return (
      <View style={this.styles.parentContainer}>
        {this.renderProgressBarLeftSection()}
        {this.renderRefundProgressBar()}
        {this.renderProgressBarRightSection()}
      </View>

    );
  }

  renderProgressBarLeftSection() {
    const dateTimeObj = this.getDateTimeObj();
    return (<View style={this.styles.refundLeftSection}>
      <View>
        <Text style={{fontFamily: this.fonts.bold}}>{dateTimeObj.startDate}</Text>
        <Text style={{fontSize: 12}}>{dateTimeObj.startTime}</Text>
      </View>
      <View>
        <Text style={{fontFamily: this.fonts.bold}}>{dateTimeObj.endDate}</Text>
        <Text style={{fontSize: 12}}>{dateTimeObj.endTime}</Text>
      </View>
    </View>);
  }

  getDateTimeObj() {
    const dateTimeObj = {};
    if (this.props.promise) {
      const promiseDateTime = getPromiseDateTimeObj(this.props.promise);
      dateTimeObj.startDate = promiseDateTime.startDate;
      dateTimeObj.startTime = promiseDateTime.startTime;
      dateTimeObj.endDate = promiseDateTime.endDate;
      dateTimeObj.endTime = promiseDateTime.endTime;
    } else {
      dateTimeObj.startDate = this.props.refundObj.startDate ? this.props.refundObj.startDate : '';
      dateTimeObj.startTime = this.props.refundObj.startTime ? this.props.refundObj.startTime : '';
      dateTimeObj.endDate = this.props.refundObj.endDate ? this.props.refundObj.endDate : '';
      dateTimeObj.endTime = this.props.refundObj.endTime ? this.props.refundObj.endTime : '';
    }
    return dateTimeObj;
  }

  renderRefundProgressBar() {
    return (<View style={this.styles.refundProcessLine}>
      <View style={this.props.progressStyle} />
    </View>);
  }

  renderProgressBarRightSection() {
    return (<View style={this.styles.refundRightSection}>
      <View style={this.styles.refundRightProcessDtls}>
        {this.renderTopRightSection()}
        {this.renderRightMiddleSection()}
        {this.renderBottomRightSection()}
      </View>
      <Text
        style={this.styles.refundCompletedSubText}
      >{this.getRefundCompletedSubText()}
      </Text>
      {this.showRefundDetails()}
    </View>);
  }

  renderTopRightSection() {
    let startStateMsg = this.props.refundObj.startMsg;
    if (this.props.promise) {
      startStateMsg = this.props.promise.userviewstartstate;
    }
    return this.renderIconWithStatus(singleTick, startStateMsg, true);
  }

  renderBottomRightSection() {
    let endStateText = this.props.refundObj.endMsg;
    if (this.props.promise) {
      endStateText = this.props.promise.userviewendstate;
    }
    return this.renderIconWithStatus(doubleTick, endStateText, true);
  }

  renderRightMiddleSection() {
    const staticData = getStaticData();
    if (this.props.promise) {
      return (<View>
        <Text
          style={this.styles.penaltyText}
        >{this.getWalletText()}
        </Text>
        <TouchableOpacity
          style={this.showWalletLink() ? {display: 'flex'} : {display: 'none'}}
          onPress={() => GenericModule.openActivity('mmt.intent.action.MY_EARNINGS')}
        >
          <Text style={this.styles.walletLink}>{staticData.viewWallet}</Text>
        </TouchableOpacity>
      </View>);
    }
    return null;
  }

  renderIconWithStatus(iconSource, text, showIcon) {
    const imageStyle = showIcon ? this.styles.singleTickIcon : this.styles.hideView;
    return (<View style={{flexDirection: 'row'}}>
      <Image style={imageStyle} source={iconSource} />
      <Text style={{color: colors.black}}>{text}</Text>
    </View>);
  }

  getRefundCompletedSubText() {
    if (this.props.promise && (this.props.promise.currentstatus === HotelBookingDetailsConstant.REQUEST_CLOSED_BREACHED_WARNING_TAT
      || this.props.promise.currentstatus === HotelBookingDetailsConstant.REQUEST_CLOSED_WITHIN_WARNING_TAT
      || this.props.promise.currentstatus === HotelBookingDetailsConstant.REQUEST_CLOSED_BREACHED_PROMISE_TAT)) {
      return getParsedString(this.props.promise.reqtypeuserdata.requestpenaltymsg, this.props.promise, this.props.refundObj.refundAmount, this.props.refundObj.totalPGDays ? this.props.refundObj.totalPGDays : 0);
    }
    return '';
  }

  getWalletText() {
    if (this.props.promise.currentstatus === HotelBookingDetailsConstant.REQUEST_CLOSED_BREACHED_PROMISE_TAT
      || this.props.promise.currentstatus === HotelBookingDetailsConstant.REQUEST_OPEN_BREACHED_PROMISE_TAT
      || this.props.promise.currentstatus === HotelBookingDetailsConstant.REQUEST_OPEN_BREACHED_L1_ESCALATION
      || this.props.promise.currentstatus === HotelBookingDetailsConstant.REQUEST_OPEN_BREACHED_L2_ESCALATION) {
      return getParsedString(this.props.promise.reqtypeuserdata.requestsubmsg2, this.props.promise, this.props.refundObj.refundAmount, this.props.refundObj.totalPGDays ? this.props.refundObj.totalPGDays : 0);
    }
    return '';
  }

  showWalletLink() {
    return this.props.promise.currentstatus === HotelBookingDetailsConstant.REQUEST_CLOSED_BREACHED_PROMISE_TAT
      || this.props.promise.currentstatus === HotelBookingDetailsConstant.REQUEST_CLOSED_BREACHED_L1_ESCALATION
      || this.props.promise.currentstatus === HotelBookingDetailsConstant.REQUEST_CLOSED_BREACHED_L2_ESCALATION
      || this.props.promise.currentstatus === HotelBookingDetailsConstant.REQUEST_REOPENED_CLOSED
      || this.props.promise.currentstatus === HotelBookingDetailsConstant.REQUEST_REOPENED;
  }

  showRefundDetails() {
    const staticData = getStaticData();
    if (this.shouldShowPaymentDetails()) {
      return (<View style={{marginLeft: 20}}>
        <Text style={this.styles.paymentDetailsHead}>
          {staticData.paymentDetailsText}
        </Text>
        {this.populatePaymentLayout()}
      </View>);
    }
    return null;
  }

  shouldShowPaymentDetails() {
    return this.props.refundObj.refundComm
      && isNotNullAndEmpty(this.props.refundObj.refundComm.refundSegmentList);
  }

  populatePaymentLayout() {
    const views = [];
    this.props.refundObj.refundComm.refundSegmentList.forEach((refundSegment) => {
      const paymentInfoObj = this.getPaymentDetails(refundSegment);
      views.push(<View style={{marginBottom: 20}} key={paymentInfoObj.paymentHeader}>
        <Text style={{fontFamily: this.fonts.bold}}>{paymentInfoObj.paymentHeader}</Text>
        <Text>{paymentInfoObj.paymentSourceDetailsOne}</Text>
        <Text>{paymentInfoObj.paymentSourceDetailsTwo}</Text>
      </View>);
    });
    return views;
  }

  getPaymentDetails(refundSegment) {
    const paymentInfo = {};
    const refundAmount = appendRupeeSymbol(Math.round(refundSegment.refundAmount));
    const bankName = refundSegment.bankName;
    paymentInfo.paymentHeader = this.getPaymentHeaderString(bankName, refundAmount);
    if (bankName === HotelBookingDetailsConstant.REFUND_MODE_ORIGINAL
      || bankName === HotelBookingDetailsConstant.REFUND_SOURCE_MY_WALLET) {
      paymentInfo.paymentSourceDetailsOne = '';// Add Logged in Email Id
      paymentInfo.paymentSourceDetailsTwo = '';
    } else {
      let cardNumber = '';
      if (refundSegment.cardNumber) {
        cardNumber = `Card No: ${refundSegment.cardNumber}`;
      }
      let rrnNumber = '';
      if (refundSegment.rrn) {
        rrnNumber = `RRN No: ${refundSegment.rrn}`;
      }
      paymentInfo.paymentSourceDetailsOne = cardNumber;
      paymentInfo.paymentSourceDetailsTwo = rrnNumber;
    }
    return paymentInfo;
  }

  getPaymentHeaderString(bankName, refundAmount) {
    const staticData = getStaticData();
    const bank = HotelBookingDetailsConstant.REFUND_SOURCE_MY_WALLET === bankName ? staticData.mmtWalletText : bankName;
    return `${bank} (${refundAmount})`;
  }
}

const getStyles = (fonts) => {
  return StyleSheet.create({
    parentContainer: {
      backgroundColor: '#fff',
      borderBottomWidth: 1,
      borderBottomColor: '#e4e4e4',
      flexDirection: 'row',
      padding: 15,
      elevation: 1
    },
    refundLeftSection: {
      justifyContent: 'space-between',
      alignItems: 'center',
      height: 200,
      marginRight: 10
    },
    refundProcessLine: {
      width: 3,
      backgroundColor: '#ccc',
      height: 200,
      borderRadius: 25
    },
    refundRightSection: {
      marginLeft: 5,
      width: '75%'
    },
    singleTickIcon: {
      width: 16,
      height: 16,
      marginTop: 3,
      marginRight: 5
    },
    penaltyText: {
      marginLeft: 21,
      marginBottom: 5,
      fontFamily: fonts.bold,
      color: colors.black
    },
    walletLink: {
      color: '#008cff',
      fontFamily: fonts.bold,
      marginLeft: 21
    },
    refundCompletedSubText: {
      paddingLeft: 20,
      ...fonts.font12,
      marginBottom: 20
    },
    paymentDetailsHead: {
      fontFamily: fonts.bold,
      color: colors.black,
      marginBottom: 10
    },
    refundRightProcessDtls: {
      justifyContent: 'space-between',
      height: 200
    },
    showView: {
      display: 'flex'
    },
    hideView: {
      display: 'none'
    }
  
  });
}

DetailRefundProcessCard.propTypes = {
  promise: PropTypes.object,
  refundObj: PropTypes.object.isRequired,
  progressStyle: PropTypes.array.isRequired
};

DetailRefundProcessCard.defaultProps = {
  promise: null
};
