import {Dimensions, Platform, StyleSheet} from 'react-native';
import {colors, fonts, isIPhoneX, statusBarHeightForIphone} from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';

const windowHeight = Dimensions.get('screen').height;
const windowWidth = Dimensions.get('screen').width;

export const styles = {
    MAMIBlackFooter: {
        backgroundColor: '#4a4a4a',
        padding: 10,
        zIndex: 9,
        alignItems: 'flex-end',
        justifyContent: 'flex-end'
    },
    FooterLeft: {
        flex: 1,
        borderRightWidth: 1,
        borderRightColor: '#ffffff',
        flexDirection: 'column'
    },
    FooterRight: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'flex-end',
        alignSelf:'flex-end',
    },
    CapsuleBtnFill: {
        paddingBottom: 12,
        paddingTop: 12,
        paddingLeft: 10,
        paddingRight: 10,
        borderRadius: 50,
        overflow: 'hidden',
        marginLeft: 10,
    },
    FooterBtn: {
        width: 150,
    },
    CenterText : {
        alignSelf: 'center',
    },
    breadCumbOuter: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        backgroundColor: colors.gray4,
        paddingVertical: 8

    },
    nonActiveBreadcumb: {
        color: colors.greyLight,
        fontSize: 12
    },
    activeBreadCumb: {
        color: colors.defaultTextColor,
        fontSize: 12,
        fontFamily: fonts.black
    },pickerWrapper:{
        flexDirection:'row',
        padding:16,
        backgroundColor:'#fff',
        justifyContent:'space-between',
        ...getPlatformElevation(2)
    },
    pickerBox:{
        borderColor:'#e7e7e7',
        borderWidth:1,
        backgroundColor:'#f7f7f7',
        paddingVertical:6,
        paddingHorizontal:12,
        width:'49%',
        borderRadius:3,
        justifyContent:'center',
    },
    daysCount:{
        width:50,
        height:20,
        alignSelf:'center',
        alignItems:'center',
        justifyContent:'center',
        borderRadius:20,
        position:'absolute',
        zIndex:10,

    },
    borderDaysRightBox:{
        height:22,
        width:20,
        borderColor:'#e7e7e7',
        borderWidth:1,
        borderLeftWidth:0,
        position:'absolute',
        backgroundColor:'#fff',
        borderBottomLeftRadius:0,
        borderBottomRightRadius:10,
        borderTopLeftRadius:0,
        borderTopRightRadius:10,
        left:-1,
    },
    borderDaysLeftBox:{
        height:22,
        width:20,
        borderColor:'#e7e7e7',
        borderWidth:1,
        borderRightWidth:0,
        position:'absolute',
        backgroundColor:'#fff',
        borderBottomLeftRadius:10,
        borderBottomRightRadius:0,
        borderTopLeftRadius:10,
        borderTopRightRadius:0,
        right:-1,
        alignSelf:'flex-end'
    },
    activeBorder:{
        borderColor:'#b5dcff',
    },
    tripEndBox:{
        paddingHorizontal:30,
    },
    daysCountWrapper:{
        justifyContent:'center'
    },
    activePickerStyles:{
        borderColor:'#b5dcff',
    },
    activePickerheadingStyles:{
        color:'#008cff'
    },card: {
        backgroundColor: colors.white,
    },
    cabPrimaryDetails: {
        backgroundColor: colors.white,
        padding:15,
        borderBottomColor: colors.lightGrey3,
        borderBottomWidth: 1,
        ...getPlatformElevation(Platform.select({ ios: 3, android: 4 }))
    },
    cardIcon: {
        height: 25,
        width: 24,
        marginRight: 8
    },
    cardTitle: {
        fontSize: 14,
        fontFamily: fonts.black,
        color: colors.black2f,
        marginBottom: 10
    },
    content: {
        flex: 1
    },
    tripDateRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 10
    },
    pickUpTime: {
        fontSize: 12,
        fontFamily: fonts.bold,
        color: colors.black
    },
    dropTime: {
        fontSize: 12,
        fontFamily: fonts.regular,
        color: colors.disabledBtnBg
    },
    locationName: {
        fontSize: 14,
        fontFamily: fonts.bold,
        color: colors.defaultTextColor,
        marginBottom: 5
    },
    tripDistance: {
        fontSize: 12,
        fontFamily: fonts.bold,
        color: colors.greyLight,
        borderBottomColor: colors.gray3,
        borderBottomWidth: 1,
        paddingHorizontal: 7,
        paddingBottom: 3,
        marginBottom: 3,
    },
    smallText: {
        fontSize: 12,
        fontFamily: fonts.regular,
        color: colors.greyLight
    },
    blackText: {
        fontFamily: fonts.black,
        color: colors.black,
        fontSize: 12
    },
    textRight: {
        textAlign: 'right'
    },
    tripDurationDetails: {
        marginHorizontal: 10,
        alignItems: 'center',
    },
    locationDetails: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    pickUpPointDetails: {
        flex: 1
    },
    dropPointDetails: {
        flex: 1,
        alignItems: 'flex-end'
    },
    roundTripSeparator: {
        position: 'relative',
        alignItems: 'center'
    },
    separatorLine: {
        position: 'absolute',
        height: 2,
        width: '100%',
        left: 0,
        top: 28,
        backgroundColor: colors.cabsGreyBg,
        zIndex: 1
    },
    separatorDaysGhost: {
        height: 50,
        paddingHorizontal: 40,
        borderColor: colors.cabsGreyBg,
        borderWidth: 1,
        justifyContent: 'center',
        borderRadius: 20,
        marginVertical: 10,
        position: 'relative',
        zIndex: 2,
        backgroundColor: colors.white
    },
    seperatorDaysGhostText: {
        fontSize: 14,
        fontFamily: fonts.bold,
        color: colors.black
    },
    mapCard: {
        paddingTop: 15,
        borderTopColor: colors.lightGrey3,
        borderTopWidth: 1
    },
    mapInfoRow: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 10
    },
    mapInfoLabel: {
        color: colors.lightTextColor,
        fontSize: 12,
        fontFamily: fonts.regular,
        minWidth: 140
    },
    mapInfoValue: {
        color: colors.black,
        fontSize: 12,
        fontFamily: fonts.bold,
    },
    mapInfoSmallText: {
        color: colors.lightTextColor,
        fontSize: 12,
        fontFamily: fonts.regular
    },
    mapView: {
        overflow: 'hidden',
        height: 218,
        borderRadius: 8,
        marginTop: 10
    },
    mapImg: {
        height: '100%'
    },
    tripDurationTrackWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 6
    },
    tripDurationTrackCircle: {
        width: 6,
        height: 6,
        borderRadius: 4,
        borderWidth: 1,
        borderColor: colors.darkGrey2,
        backgroundColor: colors.white
    },
    tripDurationTrack: {
        width: 60,
        backgroundColor: colors.darkGrey2,
        height: 1
    },
    tripDurationTrackCircleGreen: {
        borderColor: colors.cyan,
        backgroundColor: colors.cyan
    },
    cabFeatureList: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        borderTopColor: colors.lightGrey3,
        borderTopWidth: 1,
        paddingTop: 15,
        marginTop: 15
    },
    cabFeature: {
        minWidth: '25%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    cabFeatureLast: {
        justifyContent: 'flex-end'
    },
    cabFeatureFirst: {
        justifyContent: 'flex-start'
    },
    cabFeatureIcon: {
        height: 20,
        width: 20,
        marginRight: 5
    },
    cabFeatureName: {
        color: colors.defaultTextColor,
        fontSize: 14,
        fontFamily: fonts.bold
    },
    odometerRow: {
        flexDirection: 'row',
        alignItems: 'center',
        borderTopColor: colors.lightGrey3,
        borderTopWidth: 1,
        paddingTop: 15,
        marginTop: 15
    },
    odometerRowLabel: {
        color: colors.lightTextColor,
        fontSize: 12,
        fontFamily: fonts.regular,
        marginRight: 15
    },
    odometerRowValue: {
        color: colors.black,
        fontSize: 12,
        fontFamily: fonts.black
    },
    sepLine:{
        width:52,
        height:1,
        backgroundColor:"#cccccc",
        marginHorizontal:20
    },
    cabImage:{
        width:68,
        height:34
    },
    infoImageSize : {
        width:19,
        height:19,
        marginLeft : 9,
        marginTop : 3
    },
    cabTopInfo:{
        flexDirection:"row",
        justifyContent:"space-between",
        paddingBottom:10,
        borderBottomColor:"#ccc",
        borderBottomWidth:1,
        marginBottom:10
    },
    coupon:{
        borderColor:"#6f6f6f",
        borderWidth:1,
        marginLeft:4,
        borderRadius:8,
        paddingVertical:1,
        paddingHorizontal:6
    },
    borderBottom:{
        borderBottomWidth :1,
        borderBottomColor : '#ccc',
        paddingBottom : 15,
        marginBottom: 15
    },
    lastItem : {
        borderBottomWidth :0,
        borderBottomColor : 'transparent',
        paddingBottom : 0,
        marginBottom: 0
    },
    refundOption: {
        width:24,
        height : 24,
        marginRight : 15,
        top: 5
    },
    rightArrow: {
        width : 26,
        height: 9
    },
    cancelSubTxt: {
        fontSize: 12,
        color: colors.gray,
        fontFamily: fonts.regular,
        lineHeight: 22
    },
    overlayContainer:
        {
            justifyContent: 'flex-end',
            position: 'absolute',
            marginTop: -statusBarHeightForIphone,
            height: '100%',
            width: '100%',
            elevation: 3
        },
    overlayBg: {
        backgroundColor: 'rgba(0,0,0,.5)',
        position: 'absolute',
        height: '100%',
        width: '100%',
        zIndex: 1
    },
    overlayContent: {
        backgroundColor: '#fff',
        paddingVertical: 20,
        paddingHorizontal: 20,
        borderTopRightRadius: 20,
        borderTopLeftRadius :20,
        position: 'relative',
        zIndex: 100,
        marginBottom: -statusBarHeightForIphone,
        justifyContent: 'flex-end'
    },
    actionBlueText: {
        color: '#008cff',
        fontSize: 16,
        fontFamily: fonts.bold
    },
    cancelTxt: {
        fontSize: 16,
        color: '#4a4a4a',
        fontFamily: fonts.regular,
        lineHeight: 22
    },
    overlayHead: {
        fontSize: 23,
        color: '#000'
    },
    CancelledIcon: {
        width: 85,
        height: 80,
        marginRight: 10,
        marginTop: -16
    },
    positiveButton: {
        width: 150,
        paddingTop: 10,
        marginRight: -10
    },
    flexRow: {flexDirection: 'row'},
    mar15Bt: {marginBottom: 15, marginRight: 15},
    alignCenter: {alignSelf: 'center'},
    spaceBetween: {justifyContent: 'space-between'},
    mar25Bt: {marginBottom: 25},
    flex1: {flex: 1},
    font12: {fontSize: 12},
    bottomOverlayWrapper:{
        height:"auto",
        padding: 30,
        backgroundColor : "#fff",
        width:"100%",
        ...getPlatformElevation(2),
        borderTopLeftRadius : 8,
        borderTopRightRadius : 8
    },
    crossIcon:{
        width:23,
        height:23,
        color:"#000"
    },
    mapIcon:{width:24,height:24,marginRight:8},
    locationImg:{
        width:280,
        height:180,
        marginRight:10,
        borderRadius:6,

    },
    locationImgOuter:{marginRight : -30},
    underline:{backgroundColor:"#008cff", width:30,height:4,marginBottom:0 },
    greenColorForTxt : {
        color : colors.green
    },
    overlay: {
        opacity:0.6,
        position: 'absolute',
        ...Platform.select({
            ios: {
                top: -statusBarHeightForIphone,
            },
            android: {
                top: 0
            },
            web: {
                top: 0
            }
        }),
        left: 0,
        backgroundColor: 'rgba(0,0,0,0.5)',
        height: '100%',
        width: '100%',
        zIndex: 1
    }
};
export default styles;
