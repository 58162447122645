import React from 'react';
import PropTypes from 'prop-types';
import { Image, Text, TouchableOpacity, View, StyleSheet } from 'react-native';
import styles from './fltInfoCss';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import CancellationInfoList from './CancellationInfoList';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { transformDate } from '@mmt/legacy-commons/Helpers/dateHelpers';
import HTMLView from 'react-native-htmlview';
import Actions from '../../../../../navigation/postSalesNavigation';
import { isNull, isEmpty } from 'lodash';
import { BLUE_DOWN_ARROW, BLACK_RIGHT_ARROW, BACK_WHITE_ICON } from '../../../../FlightImageConstants';
import { imageTransformY } from '../../../../../utils/PostSaleUtil';
import { getFont } from '../../../../../PostSalesStyles';

const EXPANDED = 1;
const COLLAPSED = 0;
class FlightCancInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentState: EXPANDED,
    };
  }

  toggleDropDown() {
    const newState = 1 - this.state.currentState;
    this.setState({
      currentState: newState,
    });
  }

  getBoxDisabled() {
    const { segmentPenaltyData } = this.props;
    return (
      segmentPenaltyData &&
      segmentPenaltyData.cancellation &&
      segmentPenaltyData.cancellation.disabled
    );
  }

  render() {
    const { fsStyle, ...fonts } = getFont(true);
    const {
      bookingIntent,
      penaltyNodes,
      travelDateText,
      segmentStatus,
      segMessage,
      cancellation,
      infoList,
      dateChangePolicy,
      isDoubleSeatEnabled,
    } = this.props.segmentPenaltyData;
    const hideShadow = this.state.currentState === COLLAPSED ? [styles.hideshadow] : '';
    const activeWindowMsg = this.getActiveWindowMsg(penaltyNodes);
    const dropDownArrowStyle =
      this.state.currentState === EXPANDED
        ? [styles.arrowActiveStyle, styles.blueArrowStyle]
        : [styles.blueArrowStyle];
    const isBoxDisabled = this.getBoxDisabled();
    const boxDisabledStyle = isBoxDisabled ? styles.boxDisabled : {};
    return (
      <View>
        <View style={[styles.whiteWrapper, hideShadow]}>
          <View style={[styles.cancInfoHeading, boxDisabledStyle]}>
            <View
              style={[
                AtomicCss.flexRow,
                AtomicCss.alignCenter,
                AtomicCss.marginBottom5,
                AtomicCss.flexWrap,
                { maxWidth: 300}
              ]}
            >
              {this.getSegmentIntentView(bookingIntent, isDoubleSeatEnabled)}
              <Text style={[fsStyle.font12, AtomicCss.defaultText, fonts.regularFontFamily]}>
                {travelDateText}
              </Text>
            </View>
            {segmentStatus.toUpperCase() === 'UPCOMING' && (
              <TouchableOpacity
                style={[styles.arrowWrapper, AtomicCss.alignCenter]}
                onPress={() => this.toggleDropDown()}
              >
                <Image style={[dropDownArrowStyle]} source={BLUE_DOWN_ARROW} />
              </TouchableOpacity>
            )}
            {isBoxDisabled && (
              <Text style={[styles.errorFltInfo, fsStyle.font14]}>{cancellation.infoMessage} </Text>
            )}
          </View>
          {this.state.currentState === EXPANDED && penaltyNodes && (
            <View>
              {(activeWindowMsg || segMessage) && (
                <View style={styles.tripInfoWrapper}>
                  {segMessage && <HTMLView stylesheet={styles} value={`<p>• ${segMessage}</p>`} />}
                  {activeWindowMsg && (
                    <Text
                      style={[
                        AtomicCss.defaultText,
                        fsStyle.font14,
                        fonts.regularFontFamily,
                        AtomicCss.marginBottom5,
                      ]}
                    >
                      {activeWindowMsg}
                    </Text>
                  )}
                  {!isNull(infoList) &&
                    !isEmpty(infoList) &&
                    infoList.map((listItem) => {
                      return (
                        <View style={[AtomicCss.flexRow]}>
                          <Text
                            style={[
                              AtomicCss.defaultText,
                              fsStyle.font14,
                              fonts.regularFontFamily,
                              AtomicCss.marginBottom5,
                            ]}
                          >
                            •
                          </Text>
                          <HTMLView
                            stylesheet={htmlStyle()}
                            value={`<span> ${listItem}</span>`}
                            onLinkPress={(url) =>
                              Actions.openPsWebView({
                                headerIcon: BACK_WHITE_ICON,
                                url,
                              })
                            }
                          />
                        </View>
                      );
                    })}
                </View>
              )}
              <CancellationInfoList penaltyNodes={penaltyNodes} />
            </View>
          )}
        </View>
        {!!dateChangePolicy &&
          dateChangePolicy.map((info) => (
            <View
              style={[
                AtomicCss.flexRow,
                AtomicCss.marginBottom10,
                AtomicCss.marginLeft10,
                AtomicCss.alignCenter,
                AtomicCss.blackText,
                fonts.blackFontFamily,
              ]}
            >
              <Text style={[AtomicCss.marginRight10, fsStyle.font14, fonts.boldFontFamily]}>
                {'\u2022'}
              </Text>
              <Text
                style={[AtomicCss.flex1, fsStyle.font14, AtomicCss.defaultText, fsStyle.font14]}
              >
                {info}
              </Text>
            </View>
          ))}
      </View>
    );
  }

  getActiveWindowMsg = (penaltyDataList) => {
    if (penaltyDataList) {
      for (let i = 0; i < penaltyDataList.length; i += 1) {
        if (penaltyDataList[i].selected && penaltyDataList[i].desc) {
          return `•  ${penaltyDataList[i].desc}`;
        }
      }
    }
    return undefined;
  };

  getSegmentIntentView = (segmentIntent, isDoubleSeatEnabled) => {
    const segmentCityArr = segmentIntent.split('-');
    const isBoxDisabled = this.getBoxDisabled();
    const boxDisabledStyle = isBoxDisabled ? styles.boxDisabled : {};
    const { fsStyle, ...fonts } = getFont(true);
    return (
      <View
        style={[
          AtomicCss.flexRow,
          AtomicCss.alignCenter,
          AtomicCss.blackText,
          fonts.blackFontFamily,
          boxDisabledStyle,
        ]}
      >
        <Text
          style={
            !isDoubleSeatEnabled ? [AtomicCss.blackText, fonts.boldFontFamily, fsStyle.font14] : []
          }
        >
          {segmentCityArr[0]}
        </Text>
        {segmentCityArr.length > 2 && this.getStopCityViews(segmentCityArr)}
        <Image style={[styles.arrowBlkStyle, imageTransformY]} source={BLACK_RIGHT_ARROW} />
        <Text
          style={
            !isDoubleSeatEnabled ? [AtomicCss.blackText, fonts.boldFontFamily, fsStyle.font14] : []
          }
        >
          {' '}
          {segmentCityArr[segmentCityArr.length - 1]}{' '}
        </Text>
      </View>
    );
  };

  getSegmentDate = (travelDate, airlineName, segmentStatus) => {
    let result = '';
    result += transformDate(travelDate.replace('T', ' '), 'YYYY-MM-DD HH:mm:ss', 'DD MMM');
    if (airlineName) {
      result += `, ${airlineName}`;
    }
    if (segmentStatus && segmentStatus.toUpperCase() !== 'UPCOMING') {
      result += ` (${segmentStatus})`;
    }
    return result;
  };

  getStopCityViews(segmentCityArr) {
    const { fsStyle, ...fonts } = getFont(true);
    const views = [];
    for (let i = 1; i < segmentCityArr.length - 1; i += 1) {
      views.push(
        <>
          <Image style={[styles.arrowBlkStyle, imageTransformY]} source={BLACK_RIGHT_ARROW} />
          <Text style={[AtomicCss.blackText, fonts.regularFontFamily, fsStyle.font12]}>
            {segmentCityArr[i]}
          </Text>
        </>,
      );
    }
    return views;
  }
}

export default FlightCancInfo;

FlightCancInfo.propTypes = {
  segmentPenaltyData: PropTypes.object.isRequired,
};

const htmlStyle = () =>
  StyleSheet.create({
    span: {
      fontSize: getFont(true).fontSize.font14,
      color: colors.defaultTextColor,
    },
  });
