const styles = {
  trackerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#fff',
  },
  trackerDtls: {
    position: 'relative',
    marginLeft: 10,
  },
  trackerInnerDtls: {
    position: 'relative',
    marginBottom: 20,
    flexDirection: 'row',
    paddingRight: 30,
  },
  progressLine: {
    width: 4,
    backgroundColor: '#e7e7e7',
    position: 'absolute',
    height: '80%',
    left: 10,
    top: 0,
  },
  countWrapper: {
    width: 24,
    height: 24,
    backgroundColor: '#e7e7e7',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 50,
    left: -10,
  },
  countTxt: {
    color: '#747474',
  },
  trackerStatus: {
    color: '#4a4a4a',
    marginBottom: 5,
  },
  trackerStatusTime: {
    color: '#747474',
  },
  trackerPriceTxt: {
    color: '#cf8100',
    fontSize: 13,
  },
  tickIcon: {
    width: 24,
    height: 24,
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 11,
  },
  defaultActiveStyle: {
    backgroundColor: '#f2c544',
    width: 4,
    position: 'absolute',
    top: 0,
  },
  stepOneLineActive: {
    height: '35%',
  },
  stepTwoLineActive: {
    height: '78%',
  },
  stepThreeLineActive: {
    height: '100%',
  },
  lastItem: {
    marginBottom: 0,
  },
  borderTopActive: {
    borderTopColor: '#e5e5e5',
    paddingTop: 15,
    borderTopWidth: 1,
  },
};
export default styles;
