import React from 'react';
import PropTypes from 'prop-types';
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  ScrollView,
  StyleSheet,
  Platform,
} from 'react-native';
import getStyles from '../styles/BusMamiCss';
import { handleClickEvent, convertStringtoTitleCase } from '../utils/BusBookingDetailUtil';
import { INDIAN_PHONE_NUMBERS } from '../../PostSalesConstant';
import { callIcon, gpsIcon, primo } from '../BusImageConstants';
import HTMLView from 'react-native-htmlview';
import { isEmpty } from 'lodash';
import { label } from '@mmt/post-sales/src/staticData/staticData';
import get from "lodash/get";
import { getFont } from '../../PostSalesStyles';

class ItineraryCard extends React.Component {
  constructor(props) {
    super(props);
    this.busBookingInfo = this.props.response.busBookingInfo;
    this.busAdditionalInfo = this.props.response.busAdditionalInfo;
    this.fonts = getFont(true);
  }
  static navigationOptions = {
    header: null,
  };

  render() {
    const busDetail = this.busBookingInfo.busDetail;
    const travellerInfo = this.busBookingInfo.passengerDetails;
    const isGps = this.props.response.busLiveTrackingInfo.liveTrackingEnabled;
    const { fromCity, toCity } = busDetail;
    const seatCount = get(this, 'busBookingInfo.passengerDetails.length', 0);
    const { fonts } = this;
    const styles = getStyles();

    return (
      <View style={[styles.MamiCard, styles.mar10Bt]}>
        <View style={styles.CardContentFull}>
          <View style={styles.flexRow}>
            <View style={styles.HotelMainInfo}>
              <Text style={[fonts.font12, styles.BlackText, styles.mar5Bt, fonts.regularFontFamily]}>
                {label('bus.itinerary.busFromTo', null, { fromCity, toCity })}
              </Text>
              {
                !isEmpty(busDetail.operator) &&
                <Text style={[fonts.font18, fonts.boldFontFamily, styles.BlackText]}>
                {busDetail.operator}
              </Text>
              }
              <Text style={[fonts.font12, fonts.regularFontFamily, styles.BlackText]}>
                {convertStringtoTitleCase(busDetail.type)}
              </Text>
            </View>
            {this.props.response.primoApplicable && (
              <View style={styles.right}>
                <Image style={styles.primoIcon} source={primo} />
              </View>
            )}
          </View>
          <View style={styles.gpsRow}>
            <View style={[styles.row, styles.mar15Rt, styles.alignCenter]}>
              {isGps && (
                <View style={[styles.HotelRGRow, styles.mar15Rt]}>
                  <Image source={gpsIcon} style={styles.GpsIcon} />
                  <Text style={fonts.regularFontFamily}>{label('bus.itinerary.gpsEnabled')}</Text>
                </View>
              )}
              <ScrollView horizontal showsHorizontalScrollIndicator={false}>
                {busDetail && !isEmpty(busDetail.contactNumbers) && (
                  <Image style={styles.GpsIcon} source={callIcon} />
                )}
                {busDetail && !isEmpty(busDetail.contactNumbers) && this.addOperatorPhone(busDetail.contactNumbers)}
              </ScrollView>
            </View>
          </View>
          <View style={[styles.border]} />

          <View style={[styles.gpsSeatRow]}>
            <Text style={[styles.HotelLabel, fonts.boldFontFamily, fonts.font12]}>
              {seatCount} {seatCount > 1 ? label('bus.itinerary.seats') : label('bus.itinerary.seat')}
            </Text>
            <View>{this.addSeatInfo(travellerInfo)}</View>
          </View>
          {this.busAdditionalInfo &&
            this.busAdditionalInfo.reviewDetail &&
            this.busAdditionalInfo.reviewDetail.extraData &&
            this.busAdditionalInfo.reviewDetail.extraData.vaccinatedStaff &&
            !isEmpty(this.busAdditionalInfo.reviewDetail.extraData.vaccinatedStaffMsg) && (
              <View style={[styles.depositCard]}>
                <HTMLView
                  value={`<div>${this.busAdditionalInfo.reviewDetail.extraData.vaccinatedStaffMsg}</div>`}
                  stylesheet={htmlstyles}
                />
              </View>
            )}
        </View>
      </View>
    );
  }
  addSeatInfo(travellerInfo) {
    const views = [];
    travellerInfo.forEach((passengerDetail, index) => {
      this.addSeatCard(views, passengerDetail, index);
    });
    return views;
  }
  addSeatCard(views, passengerDetail, index) {
    const seatNumber = get(passengerDetail, 'seatNumber', '');
    const { fonts } = this;
    const styles = getStyles();

    views.push(
      <View key={index}>
        <Text style={[styles.HotelRooms, fonts.regularFontFamily, fonts.font12]}>
          {label('bus.seatInfo', null, {seatNumber})}{" "}
          {convertStringtoTitleCase(passengerDetail.seatType)}
        </Text>
      </View>,
    );
  }

  addOperatorPhone(phoneNumbers) {
    const views = [];
    let phoneNumberList;
    const intRegex = INDIAN_PHONE_NUMBERS;
    phoneNumberList = phoneNumbers.match(intRegex);
    if (!phoneNumberList) {
      phoneNumberList = [];
      phoneNumberList.push(phoneNumbers);
    }
    phoneNumberList.forEach((phoneNumber, index) => {
      if (phoneNumber.length > 1) {
        this.addOperatorPhoneCard(views, phoneNumber, index);
      }
    });
    return views;
  }
  addOperatorPhoneCard(views, phoneNumber, index) {
    const { fonts } = this;
    const styles = getStyles();
    views.push(
      <View key={index}>
        <TouchableOpacity
          onPress={() => handleClickEvent('CallOperator', phoneNumber, this.props.response)}
        >
          <Text style={[fonts.font12, fonts.mediumFontFamily, styles.blueColor]}>{`${phoneNumber} `}</Text>
        </TouchableOpacity>
      </View>,
    );
  }
}
ItineraryCard.propTypes = {
  response: PropTypes.object.isRequired,
};

const htmlstyles =
  Platform.OS === 'android'
    ? StyleSheet.create({
        strong: {
          fontWeight: 'bold',
        },
      })
    : undefined;

export default ItineraryCard;
