import React from 'react'
import { View, Text, Image } from "react-native";
import { createStyles } from './wheelChairInfoMsgStyles';
import { getFont } from '../../../../../PostSalesStyles';
import { useTheme } from '../../../../../theme/theme.context';
import { WHEELCHAIR_PAX_GREEN, WHEELCHAIR_PAX_RED, WHEELCHAIR_PAX_GREY } from '../../../../FlightImageConstants';
import { WHEEL_CHAIR_PAX_STATUS } from '../../FlightBookingDetailsConstant';
import { SuffixStyleType } from './wheelChairTypes';


const WheelchairInfoMessages: React.FC<{description: string | null, status: number}> = ({description = '', status = WHEEL_CHAIR_PAX_STATUS.PENDING})=> {

  if(!description){
    return null;
  }
  
  const { fsStyle }  = getFont(true);
  const {psTheme : theme} = useTheme();
  const styles = createStyles(theme);

    const suffixStyle: SuffixStyleType = status === WHEEL_CHAIR_PAX_STATUS.ACCEPT ? 'Accept' : ( status === WHEEL_CHAIR_PAX_STATUS.REJECT ? 'Reject' : '')
    const fareIcon = status === WHEEL_CHAIR_PAX_STATUS.ACCEPT ? WHEELCHAIR_PAX_GREEN : ( status === WHEEL_CHAIR_PAX_STATUS.REJECT ? WHEELCHAIR_PAX_RED : WHEELCHAIR_PAX_GREY )

  return (
    <View style={[styles.infoMessageBox, styles[`wcBorderStyle${suffixStyle}`]]}>
        <Image style={styles.wcIconStyle} source={fareIcon} />
        <Text style={[fsStyle.font10, [styles.wcTextStyle, styles[`wcTextStyle${suffixStyle}`]]]}>{description}</Text>
    </View>
  )
}

export default WheelchairInfoMessages;