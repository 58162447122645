import { Platform, StyleSheet } from 'react-native';
import { colors, fonts } from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';

const styles = StyleSheet.create({
  card: {
    backgroundColor: colors.white,
    marginBottom: 16,
    padding: 16,
    borderBottomColor: colors.lightGrey3,
    borderBottomWidth: 1,
    ...getPlatformElevation(Platform.select({ ios: 3, android: 4 })),
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: colors.lightGray,
    paddingBottom: 16,
    marginBottom: 12,
  },
  blueSmartIcon: {
    width: 29,
    height: 23,
    marginRight: 12,
  },
  bullet: {
    width: 8,
    height: 8,
    marginRight: 8,
    backgroundColor: colors.green,
    borderRadius: 50,
    top: 4,
  },
  collapsedArea: {
    borderTopWidth: 1,
    borderTopColor: colors.lightGray,
    paddingTop: 12,
  },
  downArrowStyle: {
    width: 15,
    height: 10,
    transform: [
      {
        rotate: '0deg',
      },
    ],
  },
  activeArrow: {
    transform: [
      {
        rotate: '180deg',
      },
    ],
  },
});

export const getHtmlStyle = () => {
  return StyleSheet.create({
    q: {
      fontFamily: fonts.regular,
      color: colors.black1,
      fontSize: 14,
      flexWrap: 'wrap'
    },
    b: {
      fontFamily: fonts.bold,
      color: colors.black1,
    }
  })
}
export default styles;
