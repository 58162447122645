import React, { useState } from 'react';
import { ScrollView, View, Text, TouchableOpacity } from 'react-native';
import FilterCardSection from '../FilterCardSection';
import BaseButton, { BUTTON_VARIANT } from 'apps/post-sales/src/Common/BaseButton';
import CommonBottomOverlayMessage from 'apps/post-sales/src/Common/CommonOverlayMessage/CommonBottomOverlayMessage';
import FilterShimmer from '../FilterShimmer';
import { getStaticData } from 'apps/post-sales/src/staticData/staticData';
import { Filter } from '../../../../types';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import createStyles from "./style";
import CommonOverlayMessage from 'apps/post-sales/src/Common/CommonOverlayMessage';
import ProgressView from 'apps/post-sales/src/Common/ProgressView/ProgressView';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import isEmpty from 'lodash/isEmpty';

interface FilterOverlayProps {
  handleClose: () => void;
  isLoading: { status: boolean };
  title: string;
  data: { id: string; title: string, values: Filter[] }[];
  handleTab: (id: string, text: string) => void;
  selectedFilters: string[];
  handleClick: () => void;
  buttonDisabled: boolean
}

const FilterOverlay: React.FC<FilterOverlayProps> = (props) => {
  const { handleClose, isLoading, title, data, handleTab, selectedFilters, handleClick, buttonDisabled } = props || {};
  const { confirmText } = getStaticData();
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme);
  const { loadingDataText, errorFiltersText } = getStaticData();
  const [showOverlayLoader, setShowOverlayLoader] = useState(false);
  const handleConfirmClick = () => {
    setShowOverlayLoader(true);
    handleClick();
  }
  return (
    <>
      {showOverlayLoader ? <CommonOverlayMessage content={<ProgressView message={loadingDataText} />} /> :
        <>
          <TouchableOpacity
            onPress={handleClose}
            activeOpacity={1}
            style={styles.overlayWrapper}
          />
          <CommonBottomOverlayMessage
            dismiss={handleClose}
            contentMaxHeight={420}
            content={
              isLoading.status ? (
                <View style={styles.overlayHeight}>
                  <Text>{title}</Text>
                  <FilterShimmer />
                </View>
              ) : !isEmpty(data) ? <ScrollView>
                <FilterCardSection
                  isOverlayFilterApplied = {true}
                  title={title}
                  tabList={data}
                  handleTab={handleTab}
                  selectedTab={selectedFilters}
                />
                <BaseButton
                  variant={BUTTON_VARIANT.PRIMARY}
                  clickHandler={handleConfirmClick}
                  btnType="flat"
                  disabled={buttonDisabled}
                  text={confirmText}
                  buttonStyle = {[AtomicCss.marginBottom30, AtomicCss.borderRadius8]}
                />
              </ScrollView>
                : <View><Text>{errorFiltersText}</Text></View>
            }
          />
        </>}


    </>
  );
};

export default FilterOverlay;