import { trackOmniture } from '@mmt/legacy-commons/Common/utils/OmnitureTrackerUtils';
import { Platform } from 'react-native';
import { LOGGER_MAP } from './PostSalesConstant';

class PostSalesBookingTrackingHelper {
  trackClickEvent = (pageName, clickEvent, bookingId, ticketId, { lobName } = {}) => {
    const params = {};
    params.pageName = pageName;
    params.m_v15 = pageName;
    params.m_c1 = params.m_c54 = clickEvent;
    params.m_v16 = bookingId;

    if(ticketId) {
      params.m_v81 = Platform.select({android:'ANDROID', ios:'IOS', web:'PWA'})
      params.m_c39 = ticketId;
      params.m_c40 = 'MIMA_DETAIL';
      params.m_c20 = 'MIMA_DETAIL';
      params.m_c42 = 'CUSTOMER';
      params.m_c43 = 'Success';
      if(pageName == "mytrips"){
        params.m_c40 = 'MIMA_LISTING';
        params.m_c20 = 'MIMA_LISTING';
      }
    }
    if (lobName) {
      params.m_v24 = lobName;
    }
    trackOmniture(pageName, params);
  };

  trackLoadEvent = (pageName, eventName, bookingId) => {
    const params = {};
    params.pageName = pageName;
    params.m_v15 = pageName;
    params.m_c22 = eventName;
    params.m_v16 = bookingId;
    trackOmniture(pageName, params);
  };

  trackErrorWithData = (pageName, bookingId, data, lobName) => {
    const postSalesTrackingLogger = LOGGER_MAP[lobName] || null;
    if(postSalesTrackingLogger){
      postSalesTrackingLogger?.errorEvent(pageName, data);
    }
    const params = {};
    params.pageName = pageName;
    params.m_v15 = pageName;
    if (bookingId) {
      params.m_c22 = `${bookingId}_${data}`;
      params.m_v16 = bookingId;
    } else {
      params.m_c22 = data;
    }
    trackOmniture(pageName, params);
  };

  trackLoyalityCardShown = (pageName, event) => {
    const params = {};
    params.pageName = pageName;
    params.m_v15 = pageName;
    params.m_c54 = event;
    trackOmniture(pageName, params);
  };

  trackLoyalityCardShown = (pageName, event) => {
    const params = {};
    params.pageName = pageName;
    params.m_v15 = pageName;
    params.m_c54 = event;
    trackOmniture(pageName, params);
  };

  trackShownEvent = (pageName, eventName, bookingId) => {
    const params = {};
    params.pageName = pageName;
    params.m_v15 = pageName;
    params.m_c1 = eventName;
    params.m_v16 = bookingId;
    trackOmniture(pageName, params);
  };

  trackMsrCardShownEvent = (pageName, eventName, bookingId, ticketId) => {
    const params = {};
    params.pageName = pageName;
    params.m_v15 = pageName;
    params.m_c54 = eventName;
    params.m_v16 = bookingId;
    params.m_v112 = ticketId;
    trackOmniture(pageName, params);
  }
}

export default new PostSalesBookingTrackingHelper();
