import React, { useEffect } from 'react';
import TravelInsurance from '@core_app_common/TravelInsuranceOld';
import { LOBNAMES } from 'apps/post-sales/src/PostSalesConstant';
import { travelInsuranceAppData } from '../../../../../Common/travelInsuranceThemeContext';
import { MIMA_BASE_URL, getCommonHeaders } from 'apps/post-sales/src/utils/NetworkUtils';
import FlightBookingModule from '@mmt/legacy-commons/Native/FlightBookingModule';
import FlightBookingTrackingHelper from '../../helper/FlightBookingTrackingHelper';
import { Platform, DeviceEventEmitter } from 'react-native';
import { PAYMENT_EVENT_LISTNER } from '../../../AncillaryModule/constants';
import Actions from 'apps/post-sales/src/navigation/postSalesNavigation';
import { resetToFlightBookingDetail } from 'apps/post-sales/src/flights/FlightBookingUtil';
import { isEmpty } from 'lodash';
import { getStaticData } from 'apps/post-sales/src/staticData/staticData';

interface TravelInsuranceCardProp {
  bookingId: string;
}

const TravelInsuranceCard: React.FC<TravelInsuranceCardProp> = ({ bookingId, response }) => {
  const onClickTracking = (eventName: string) => {
    FlightBookingTrackingHelper.trackingNewClickEvent(
      'Details',
      `TravelInsurance|${eventName}`,
      {},
    );
  };
  const onLoadTracking = () => {
    FlightBookingTrackingHelper.trackLoadEvent('Details', {}, 'TravelInsurance_card_shown');
  };

  useEffect(() => {
    return () => {
      if (Platform.OS !== 'web') {
        const count = DeviceEventEmitter.listenerCount(PAYMENT_EVENT_LISTNER);
        if (count > 0) {
          DeviceEventEmitter.removeAllListeners(PAYMENT_EVENT_LISTNER);
        }
      }
    };
  }, []);

  const tracking = {
    onClickTracking,
    onLoadTracking,
  };
  const actionList = (actionId: string) => {
    if (actionId === 'backToBooking') {
      resetToFlightBookingDetail(bookingId);
    }
  };
  const paymentHandler = (holdResponse) => {
    const { paymentUrl } = holdResponse;
    if (Platform.OS === 'web') {
      window.location.href = paymentUrl;
    } else {
      DeviceEventEmitter.removeAllListeners(PAYMENT_EVENT_LISTNER);
      DeviceEventEmitter.addListener(PAYMENT_EVENT_LISTNER, (paymentRes) => {
        DeviceEventEmitter.removeAllListeners(PAYMENT_EVENT_LISTNER);
        if (!isEmpty(paymentRes?.PAYMENT_RESPONSE_VO)) {
          const paymentJson = JSON.parse(paymentRes.PAYMENT_RESPONSE_VO);
           if (!isEmpty(paymentJson.response)) {
            const { description, title, buttonText, isSuccess } =
              JSON.parse(paymentJson.response) || {};
            const imageUrl = isSuccess
              ? 'https://imgak.mmtcdn.com/mima/images/mobile/AncillaryAddOnsSuccessfull_Icon.webp'
              : 'https://imgak.mmtcdn.com/mima/images/mobile/AncillaryBookingFailed_Icon.webp';
            Actions.travelInsuranceThankYou({
              description: description,
              message: title,
              success: imageUrl,
              iconUrl: imageUrl,
              bookingId: bookingId,
              buttonText: buttonText,
            });
          } else {
            actionList('backToBooking');
          }
        } else {
          actionList('backToBooking');
        }
      });
      FlightBookingModule.openAncillaryPaymentPage(JSON.stringify(holdResponse));
    }
  };
  const {travelInsuranceText = {}} = getStaticData();
  const { errorHeading, errorDesc }  = travelInsuranceText;
  const nativeModules = {
    backendBaseUrl: MIMA_BASE_URL,
    getTravelInsuranceHeaders: async () => {
      const userInfo = await FlightBookingModule.getRequestHeader(bookingId, 'TravelInsurance');
      const headers = await getCommonHeaders(
        userInfo.authToken,
        userInfo?.loggingTrackingInfo || '',
        {
          psLob: LOBNAMES.FLIGHT,
        },
      );
      return headers;
    },
    icons: {
      closeIcon: 'https://imgak.mmtcdn.com/mima/images/mobile/crossIcon1.webp',
      rightArrowIcon: 'https://imgak.mmtcdn.com/goibibo/images/arrow_oneway.png',
      newTagImg: 'https://imgak.mmtcdn.com/mima/images/mobile/digiYatra_newTag.webp',
    },
    staticText: {
      errorHeading,
      errorDesc,
    },
  };
  return (
    <TravelInsurance
      bookingId={bookingId}
      lobName={LOBNAMES.FLIGHT}
      tracking={tracking}
      themeData={travelInsuranceAppData}
      response={response}
      paymentHandler={paymentHandler}
      nativeModules={nativeModules}
    />
  );
};
export default TravelInsuranceCard;
