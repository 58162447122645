import React, { memo } from 'react';
import ProgressView from '@mmt/legacy-commons/Common/Components/Progress/ProgressView';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';

const ViewStateHandler = ({ loading, loaderMsg, children, error, onRetry, errImg, showErrImage }) => {
  if (loading) {
    return <ProgressView message={loaderMsg} />;
  }

  if (error) {
    return (
      <ErrorView
        {...{
          showImage: showErrImage,
          errImg,
          showRetry: onRetry ? true : false,
          onRetry,
        }}
      />
    );
  }
  return children;
};

export default memo(ViewStateHandler);
