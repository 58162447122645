import React from 'react';
import { View, Text, Image } from 'react-native';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import createSyles from '../reasonSelectionCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';

import {
  NAME_CHANGE_ARROW,
  GREEN_TICK_ICON2,
  CROSS_RED_CIRCLE_ICON,
} from '../../../../../../FlightImageConstants';
import { getStaticData } from '../../../../../../../staticData/staticData';

import { NAME_CORRECTION_RULE_IDS } from '../../../../constant';
import { getFont } from '../../../../../../../PostSalesStyles';
import { useTheme } from '../../../../../../../theme/theme.context';

const NameCorrectionExampleSection = ({ ruleId }) => {
  const { psTheme: theme } = useTheme();
  const { fsStyle, ...fonts } = getFont(true);
  const styles = createSyles(theme, fonts);
  const staticData = getStaticData();
  const consideredByAirlineText = get(staticData,'nameChangeCloseToDepartureText.items.spellingCorrectionSectionText.additionalText.consideredByAirlineText','');
  const notPermittedText = get(staticData,'nameChangeCloseToDepartureText.items.spellingCorrectionSectionText.additionalText.notPermittedText','');
 

  const getExampleByRuleId = () => {
    switch (ruleId) {
      case NAME_CORRECTION_RULE_IDS.Incorrect_Spelling:
        return (
          <View style={styles.innerBottomWrapper}>
            <View style={[styles.innerWrapper, AtomicCss.marginBottom8]}>
              <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom10]}>
                <Image style={styles.greenTickIcon} source={GREEN_TICK_ICON2} />
                {!isEmpty(consideredByAirlineText) && <Text
                  style={[
                    AtomicCss.flex1,
                    fsStyle.font12,
                    AtomicCss.greenText,
                    fonts.boldFontFamily,
                  ]}
                >
                  {consideredByAirlineText}
                </Text>
                }
              </View>
              <View style={[styles.nameCorrectionRow, AtomicCss.marginBottom16]}>
                <Text
                  style={[
                    AtomicCss.defaultText,
                    fsStyle.font14,
                    fonts.regularFontFamily,
                    styles.width100,
                  ]}
                >
                  P
                  <Text style={[AtomicCss.blackText, fonts.boldFontFamily, styles.underline]}>
                    ar
                  </Text>
                  teek Mittal
                </Text>
                <Image style={[styles.arrowIcon]} source={NAME_CHANGE_ARROW} />
                <Text style={[AtomicCss.defaultText, fsStyle.font14, fonts.regularFontFamily]}>
                  P
                  <Text style={[AtomicCss.greenText, fonts.boldFontFamily, styles.underline]}>
                    ar
                  </Text>
                  teek Mittal
                </Text>
              </View>
            </View>
            <View style={styles.innerWrapper}>
              <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom10]}>
                <Image style={styles.greenTickIcon} source={CROSS_RED_CIRCLE_ICON} />
                {!isEmpty(notPermittedText) && <Text
                  style={[AtomicCss.flex1, fsStyle.font12, AtomicCss.redText, fonts.boldFontFamily]}
                >
                  {notPermittedText}
                </Text>
                }
              </View>
              <View style={[styles.nameCorrectionRow, AtomicCss.marginBottom8]}>
                <Text
                  style={[
                    AtomicCss.defaultText,
                    fsStyle.font14,
                    fonts.regularFontFamily,
                    styles.width100,
                  ]}
                >
                  <Text style={[AtomicCss.blackText, fonts.boldFontFamily, styles.underline]}>
                    Sanjana{' '}
                  </Text>
                  Mittal
                </Text>
                <Image style={[styles.arrowIcon]} source={NAME_CHANGE_ARROW} />
                <Text style={[AtomicCss.defaultText, fsStyle.font14, fonts.regularFontFamily]}>
                  <Text style={[AtomicCss.redText, fonts.boldFontFamily, styles.underline]}>
                    Prateek{' '}
                  </Text>
                  Mittal
                </Text>
              </View>
            </View>
          </View>
        );
      case NAME_CORRECTION_RULE_IDS.Change_Middle_Name:
        return (
          <View style={styles.innerBottomWrapper}>
            <View style={styles.innerWrapper}>
              <View style={[styles.nameCorrectionRow]}>
                <Text style={[AtomicCss.defaultText, fsStyle.font14, AtomicCss.regularFont]}>
                  <Text style={[AtomicCss.defaultText, fsStyle.font12]}>eg. </Text>
                  Prateek{' '}
                  <Text style={[AtomicCss.blackText, fonts.boldFontFamily, styles.lineThorugh]}>
                    Kumar
                  </Text>{' '}
                  Mittal
                </Text>
              </View>
            </View>
          </View>
        );
      case NAME_CORRECTION_RULE_IDS.Swap_Last_First_Name:
        return (
          <View style={styles.innerBottomWrapper}>
            <View style={styles.innerWrapper}>
              <View style={[styles.nameCorrectionRow]}>
                <Text style={[AtomicCss.defaultText, fsStyle.font12]}>eg. </Text>
                <Text
                  style={[
                    AtomicCss.defaultText,
                    fsStyle.font14,
                    fonts.regularFontFamily,
                    styles.width100,
                  ]}
                >
                  Mittal Prateek
                </Text>
                <Image style={[styles.arrowIcon]} source={NAME_CHANGE_ARROW} />
                <Text style={[AtomicCss.defaultText, fsStyle.font14, fonts.regularFontFamily]}>
                  Prateek Mittal
                </Text>
              </View>
            </View>
          </View>
        );
      default:
        return null;
    }
  };
  return getExampleByRuleId();
};

export default NameCorrectionExampleSection;
