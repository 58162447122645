import React from 'react';
import Actions from '../../navigation/postSalesNavigation';
import AcmeBookingDetailsConstant from './AcmeBookingDetailsConstant';
import RectFullButton from '../../hotel/details/ActionButton/RectFullButton';
import CapsuleBtnFill from '../../hotel/details/ActionButton/CapsuleBtnFill';
import CapsuleBtn from '../../hotel/details/ActionButton/CapsuleBtn';
import RoundButtonWithIcon from '../../hotel/details/ActionButton/RoundButtonWithIcon';
import WhiteFullCapsuleButton from '../../hotel/details/ActionButton/WhiteFullCapsuleButton';
import logAction from '@mmt/legacy-commons/Helpers/actionsLogger';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import styles from '../styles/AcmeMamiCss';
import {ACME_BOOKING_CANCELLATION_URL} from '../../utils/NetworkUtils';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import AcmeBookingTrackingHelper from './AcmeBookingTrackingHelper';
import {has} from 'lodash'
import { openDialer } from '../../Common/commonUtil';

const actionButtonList = ['CallHelpline', 'TermsAndConditions', 'ImportantThingsToCarry', 'FAQ', 'Schedule', 'CallVendor', 'FullCancel', 'CancellationPolicy', 'HowToRedeem'];

export function getButtonStyle(actionList) {
  const {actionType} = actionList[0];
  if (actionType === AcmeBookingDetailsConstant.RECTANGLE_PRIMARY_HALF_WIDTH
    || actionType === AcmeBookingDetailsConstant.RECTANGLE_SECONDARY_HALF_WIDTH
    || actionType === AcmeBookingDetailsConstant.ROUND_BUTTON) {
    return [styles.ActionBtnHalf, styles.transperantBgrnd];
  } else if (actionType === AcmeBookingDetailsConstant.RECTANGLE_FULL_BUTTON) {
    return [styles.ActionBtnRec, styles.transperantBgrnd];
  }
  return [styles.ActionBtn, styles.transperantBgrnd];
}

export function getActionButton(card, response, clickHandler) {
  const actionButtons = [];
  for (const action of card.actionList) {
    if (actionButtonList.includes(action.actionFamily)) {
      // Hide cancellation button only if auto redemption is Available.
      // 0 is for NO, 1 for YES
      if (action.actionFamily === 'FullCancel'
        && has(response, 'acmeBookingInfo.autoredemption')
        && response.acmeBookingInfo.autoredemption === AcmeBookingDetailsConstant.AUTO_REDEEM_TYPE.YES
        ){ continue; }
      addActionButton(card, action, actionButtons, response, clickHandler);
    }
  }
  return actionButtons;
}

export function handleClickEvent(card, action, response, pageName) {
  logAction(`${pageName} ${card.cardId} ${action.actionFamily}clicked`);
  const clickEvent = `MI_ACME_${action.actionFamily}`;
  AcmeBookingTrackingHelper.trackClickEvent(pageName, clickEvent);
  switch (action.actionFamily) {
    case AcmeBookingDetailsConstant.ACTION_CALL_VENDOR: {
      const operatorNumber =
        response.acmeBookingInfo.acmeBookingDetails[0].addressDetails.contactno;
      openDialer(operatorNumber);
      break;
    }
    case AcmeBookingDetailsConstant.ACTION_TERMS_CONDITIONS: {
      Actions.acmeTermsConditions(response);
      break;
    }
    case AcmeBookingDetailsConstant.ACTION_THINGS_TO_CARRY:
      Actions.acmeThingsToCarry({response});
      break;
    case AcmeBookingDetailsConstant.ACTION_FAQ: {
      Actions.acmeFaq(response);
      break;
    }
    case AcmeBookingDetailsConstant.ACTION_CANCELLATION_POLICY:
      Actions.acmeCancellationPolicy({response});
      break;
    case AcmeBookingDetailsConstant.ACTION_SCHEDULE:
      Actions.acmeSchedule({response});
      break;
    case AcmeBookingDetailsConstant.ACTION_FULL_CANCEL:
      openCancellationPreview(response);
      break;
    case AcmeBookingDetailsConstant.ACTION_CALL_HELPLINE:
      openDialer(AcmeBookingDetailsConstant.HELPLINE_NUMBER);
      break;
    case AcmeBookingDetailsConstant.ACTION_HOW_TO_REDEEM:
      Actions.acmeRedemptionSteps({response});
      break;
    default:
      break;
  }
}

function addActionButton(card, action, actionButtons, response, clickHandler) {
  switch (action.actionType) {
    case AcmeBookingDetailsConstant.RECTANGLE_FULL_BUTTON:
      actionButtons.push(<RectFullButton
        key={`RectFullButton${action.actionLabeltext}`}
        card={card}
        action={action}
        response={response}
        clickHandler={clickHandler}
      />);
      break;
    case AcmeBookingDetailsConstant.RECTANGLE_PRIMARY_FULL_WIDTH:
    case AcmeBookingDetailsConstant.RECTANGLE_PRIMARY_HALF_WIDTH:
      actionButtons.push(<CapsuleBtnFill
        key={`CapsuleBtnFill${action.actionLabeltext}`}
        card={card}
        action={action}
        response={response}
        clickHandler={clickHandler}
      />);
      break;
    case AcmeBookingDetailsConstant.RECTANGLE_SECONDARY_FULL_WIDTH:
    case AcmeBookingDetailsConstant.RECTANGLE_SECONDARY_HALF_WIDTH:
      actionButtons.push(<CapsuleBtn
        key={`CapsuleBtn${action.actionLabeltext}`}
        card={card}
        action={action}
        response={response}
        clickHandler={clickHandler}
      />);
      break;
    case AcmeBookingDetailsConstant.ROUND_BUTTON:
      actionButtons.push(<RoundButtonWithIcon
        key={`RoundButtonWithIcon${action.actionLabeltext}`}
        card={card}
        action={action}
        response={response}
        clickHandler={clickHandler}
      />);
      break;
    case AcmeBookingDetailsConstant.WHITE_BUTTON_FULL:
    case AcmeBookingDetailsConstant.WHITE_BUTTON_HALF:
      actionButtons.push(<WhiteFullCapsuleButton
        key={`WhiteFullCapsuleButton${action.actionLabeltext}`}
        card={card}
        action={action}
        response={response}
        clickHandler={clickHandler}
      />);
      break;
    default:
      break;
  }
}

export async function openCancellationPreview(response) {
  const userInfo = await HotelBookingModule.getRequestHeader(response.bookingId, 'ACME_CANCELLATION_PREVIEW');
  const cancellationRequest = getCancellationRequest(response.bookingId, 'true', 'RefundToCustomer');
  Actions.acmeCancellationReview({
    url: ACME_BOOKING_CANCELLATION_URL,
    mmtAuth: userInfo.mmtAuth,
    emailId: userInfo.emailId,
    loggingTrackInfo: userInfo.loggingTrackingInfo,
    cancellationPreviewRequest: cancellationRequest,
    acmeBookingResponse: response
  });
}

export async function openAcmeCancellationThankYou(response, refundMode) {
  const userInfo = await HotelBookingModule.getRequestHeader(response.bookingId, 'ACME_CANCELLATION_DONE');
  const cancellationRequest = getCancellationRequest(response.bookingId, 'false', refundMode);
  Actions.acmeCancellationThankyou({
    url: ACME_BOOKING_CANCELLATION_URL,
    mmtAuth: userInfo.mmtAuth,
    loggingTrackInfo: userInfo.loggingTrackingInfo,
    cancellationDoneRequest: cancellationRequest,
    acmeBookingResponse: response
  });
}

export function getCancellationRequest(bookingId, isCancellationPreview, refundOption) {
  const hotelCancellationRequest = {};
  hotelCancellationRequest.bookingId = bookingId;
  hotelCancellationRequest.preview = isCancellationPreview;
  hotelCancellationRequest.refundOption = refundOption;
  hotelCancellationRequest.userId = 'WEBLIVE';
  return hotelCancellationRequest;
}

export async function resetToAcmeDetail(bookingId) {
  const {mmtAuth, loggingTrackingInfo} = await HotelBookingModule.getRequestHeader(bookingId, 'ACME_BOOKING_DETAIL');
  const data = {};
  data.BOOKING_ID = bookingId;
  data.MMT_AUTH = mmtAuth;
  data.LOGGING_TRACKINFO = loggingTrackingInfo;
  Actions.acmeBookingDetail({data});
}

export function findDaysBetweenDates(firstDate, secondDate) {
  const oneDay = 24 * 60 * 60 * 1000;
  return Math.round(Math.abs((secondDate - firstDate) / oneDay));
}

export const maybePluralize = (count, noun, suffix = 's') => `${count} ${noun}${count !== 1 ? suffix : ''}`;
