import { StyleSheet } from 'react-native';
import { Fonts, Theme } from '../../../types';

export const getStyles = (fonts : Fonts, theme : Theme) => {
  const {color} = theme;
  return StyleSheet.create({
    paymentOptionHeading: {
      fontSize: fonts.fontSize.font16,
      ...fonts.blackFontFamily,
      color: color.black,
    },
    paymentOptionCard: {
      backgroundColor: color.white,
    },
    rowSection: {
      flexDirection: 'row',
    },
    radio: {
      borderColor: color.lightTextColor,
      borderWidth: 2,
      height: 18,
      width: 18,
      backgroundColor: color.white,
      borderRadius: 10,
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: 5,
    },
    radioActive: {
      borderColor: color.azure,
    },
    radioCircle: {
      height: 11,
      width: 11,
      backgroundColor: color.azure,
      borderRadius: 10,
    },
    tagStyle: {
      width: 157,
      height: 24,
      marginTop: 4,
    },
    heading: {
      fontSize: fonts.fontSize.font14,
      ...fonts.blackFontFamily,
      color: color.black,
    },
    subHeading: {
      fontSize: fonts.fontSize.font14,
      ...fonts.regularFontFamily,
      color: color.greyLight,
    },
    priceText: {
      fontSize: fonts.fontSize.font16,
      ...fonts.boldFontFamily,
      color: color.black,
    },
    radioStyle: {
      marginRight:5,
      alignSelf : 'flex-start',
      marginTop: 2
    }
  });
};
