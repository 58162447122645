import { Platform } from "react-native";

const PLATFORM = Platform.OS;
export const deviceType = () => PLATFORM;

export const isIos = () => PLATFORM === "ios";
export const isMweb = () => PLATFORM === "web";
export const isApp = () => PLATFORM !== "web";
export const isAndroid = () => PLATFORM === "android";

export const channelCode = () => {
  const channelCode = PLATFORM;
  switch (channelCode) {
    case "web":
      return "PWA";
    case "android":
      return "ANDROID";
    case "ios":
      return "IOS";
    default:
      return channelCode;
  }
}

export const bookMode = () => {
  const channelCode = PLATFORM;
  switch (channelCode) {
    case "web":
      return "P";
    case "android":
      return "A";
    case "ios":
      return "I";
    default:
      return "D";
  }
};

