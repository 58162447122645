import { get, isEmpty } from 'lodash';
import { Platform } from 'react-native';
import {
  trackClickEvent,
  trackViewEvent,
  trackOmniture,
} from '@mmt/legacy-commons/Common/utils/OmnitureTrackerUtils';
import { getAffiliateName, isAffiliateUser } from '../utils/NetworkUtils';
import { isMinimalBooking } from './HolidayBookingDetailUtils';
import { CURRENCY_DETAILS, OMNITURE_COMPANY_DETAILS } from '../PostSalesConstant';
import HolidayBookingEventsLogger from "./HolidayBookingEventsLogger";

class HolidayBookingTrackingHelper {
  constructor(){
    this.commonParams = {};
    this.bookingType = 'domestic';
    this.isBundlePackage = 'FIT';
  }
  getGccBookingInfo(response) {
    let sbuValue = '';
    let countryValue = '';
    let currencyValue = '';
    const { currency } = response;
    const companyOmitureDetail = OMNITURE_COMPANY_DETAILS[response.companyName];
    sbuValue += (companyOmitureDetail && companyOmitureDetail.countryValue) || 'india';
    countryValue = (companyOmitureDetail && companyOmitureDetail.countryValue) || 'in';
    currencyValue = CURRENCY_DETAILS[currency] || currency;
    return `domain_sbu:${sbuValue}|domain_country:${countryValue}|domain_currency:${currencyValue}|domain_language:English`;
  }
  setCommonParams = (pageName, response, deviceId, version ) => {
    try {
      const params = {};
      const {
        bookingStatus,
        holidayBasePageResponse,
        isDomBooking,
        isBundlePackage,
        bookingId,
        travellerPageDetails,
        uuid,
      } = response || {};
      if (isDomBooking === false) {
        this.bookingType = 'outbound';
      }
      const {bookingSummary} = holidayBasePageResponse||{};
      this.isBundlePackage = isBundlePackage;
      params.evar1 = `${Platform.OS}_${version}`;
      params.evar3 = !isEmpty(bookingSummary) && bookingSummary.departureCity;
      params.evar4 =  !isEmpty(bookingSummary) && bookingSummary.departureDate;
      params.evar5 = !isEmpty(bookingSummary) && bookingSummary.arrivalDate;
      params.evar6 = !isEmpty(bookingSummary) && bookingSummary.duration;
      params.evar8 = version;
      params.evar9 =
        holidayBasePageResponse &&
        this.getTripType(get(holidayBasePageResponse, 'packageInclusions') || {});
      params.evar14 = bookingStatus;
      params.evar15 = pageName;
      params.evar16 = bookingId;
      params.evar19 = deviceId;
      params.evar21 = travellerPageDetails && travellerPageDetails.noOfAdults;
      params.evar23 = travellerPageDetails && travellerPageDetails.noOfChildren;
      params.evar24 = 'Holidays';
      params.evar7 = 0; // todo
      params.evar27 =
        holidayBasePageResponse && get(holidayBasePageResponse, 'travellerDetails.noOfTravellers');
      params.evar31 =
        !isEmpty(bookingSummary) &&
        bookingSummary.citiesIncluded
          .split(';')
          .map((city) => city.slice(0, city.indexOf(':')))
          .join('-');
      params.evar54 = uuid;
      params.evar80 = this.getGccBookingInfo(response);
      
      this.commonParams = params;
    } catch (e) {
      console.log(e);
    }
  }
  getPrePageName = () => {
    const prePageName = `mob:cs:in ${this.bookingType} Holidays :`;
    return prePageName;
  }
  getEVar108 = (pageName, subPageName='') =>{
    try{
        const prePageName = `mob:cs:in ${this.bookingType} Holidays :`;
        const evar108 = isMinimalBooking(this.bookingType)
        ? `${prePageName}${pageName}| ${
            Platform.OS === 'web' && isAffiliateUser() ? getAffiliateName() : 'MMT'
          }_
      }_${this.bookingType === 'domestic' ? 'DOM' : 'OBT'}${!isEmpty(subPageName) ? '_' + subPageName : ''}`
        : `${prePageName}${pageName}| ${
            Platform.OS === 'web' && isAffiliateUser() ? getAffiliateName() : 'MMT'
          }_${this.isBundlePackage ? 'DFD' : 'FIT'}_${this.bookingType === 'domestic' ? 'DOM' : 'OBT'}${
            !isEmpty(subPageName) ? '_' + subPageName : ''
          }`;
        return evar108;
    } catch(e){
      console.log(e);
    }
  }

  getTripType = ({ flights, hotels, transfers, activities } = {}) => {
    let tripTypeStr = [];
    if (hotels) {
      tripTypeStr.push('Hotels');
    }
    if (flights) {
      tripTypeStr.push('Flights');
    }
    if (activities) {
      tripTypeStr.push('Activities');
    }
    if (transfers) {
      tripTypeStr.push('Transfers');
    }
    return tripTypeStr.join('_');
  }

  getErrorParams = (pageName, bookingId, data, supplierName) => {
    const params = {};
    params.pageName = pageName;
    params.m_v15 = pageName;
    if (bookingId) {
      params.m_v22 = params.m_c22 = `${bookingId}_${data}`;
    } else {
      params.m_v22 = params.m_c22 = data;
    }
    if (supplierName) {
      params.m_c40 = supplierName;
    }
    return { params, prePageName: 'mob:cs:in domestic holidays:' };
  };

  trackViewEvent = (pageName, viewEvent, subPageName) => {
    const prePageName = this.getPrePageName();
    HolidayBookingEventsLogger.loadEvent(`${prePageName}${pageName}`,viewEvent);
    trackViewEvent(`${prePageName}${pageName}`, viewEvent, {...this.commonParams, evar108: this.getEVar108(pageName, subPageName)});
  }

  trackClickEvent = (pageName, clickEvent, subPageName) => {
    HolidayBookingEventsLogger.clickEvent(pageName, clickEvent);
    trackClickEvent(`${this.getPrePageName()}${pageName}`, clickEvent, { ...this.commonParams, evar108: this.getEVar108(pageName, subPageName), m_c1: clickEvent });
  };

  trackErrorEvent = (pageName, bookingId, data, supplierName) => {
    HolidayBookingEventsLogger.errorEvent(pageName, data);
    const {params, prePageName} = this.getErrorParams(pageName, bookingId, data, supplierName);
    trackOmniture(`${prePageName}${pageName}`, params);
  }
}
export default new HolidayBookingTrackingHelper();
