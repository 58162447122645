import React, { FC, useState, useEffect } from 'react';
import { Image, Text, View, TouchableOpacity, ActivityIndicator } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import getStyles from '../../styles/HotelLateCheckInCss';
import {
  LATE_CHECK_IN_IMAGE,
  WHITE_TICK_GREEN_BG_ICON,
  BLUE_RIGHT_ARROW_ICON,
} from '../../HotelImageConstants';
import { SPECIAL_REQUEST_WRITE_TO_HOTEL_URL } from 'apps/post-sales/src/utils/NetworkUtils';
import { WRITE_TO_REQUEST_RAISED_BY, ENG_CONSTANT } from 'apps/post-sales/src/PostSalesConstant';
import useApi from 'apps/post-sales/src/Common/useApi';
import { isEmpty } from 'lodash';
import { showShortToast } from 'packages/legacy-commons/Common/Components/Toast';
interface ActionObj {
  actionId: string;
  actionType: string;
  actionLabeltext: string;
}
interface RequestState {
  heading: string;
  spotHeading: string;
  subHeading: string;
  buttonAction: ActionObj;
}
interface RequestDetail {
  mainRequestType: string;
  unlabelledRequestType: string;
}
interface MyBizLateCheckIn {
  requestDetailMapping: RequestDetail;
  requestedState: RequestState;
  nonRequestedState: RequestState;
  lateCheckinRequested: boolean;
}
interface HotelLateCheckInCardProps {
  myBizLateCheckIn: MyBizLateCheckIn;
  bookingID: string;
}
const HotelLateCheckInCard: FC<HotelLateCheckInCardProps> = ({ bookingID, myBizLateCheckIn }) => {
  const [isLateCheckinRequested, setIsLateCheckinRequested] = useState<boolean>(
    myBizLateCheckIn.lateCheckinRequested
  );
  const [isRequestPending, responseObj, api] = useApi(SPECIAL_REQUEST_WRITE_TO_HOTEL_URL);
  const {psTheme} = useTheme();
  useEffect(() => {
    if (responseObj) {
      if (responseObj.error) {
        handleError();
      } else {
        handleSuccess();
      }
    }
  }, [responseObj]);
  const styles = getStyles(psTheme);
  const fonts = getFont(true);
  const handleClickRequest = () => {
    try {
      if (isRequestPending) return;
      const request = {
        documentno: bookingID,
        requestType: myBizLateCheckIn?.requestDetailMapping?.mainRequestType,
        subRequestType: myBizLateCheckIn?.requestDetailMapping?.unlabelledRequestType,
        message: '',
        mailFrom: WRITE_TO_REQUEST_RAISED_BY,
      };
      api.httpPost({
        bookingId: bookingID,
        uniqueId: 'REQUEST_WRITE_TO_HOTEL',
        body: request,
      });
    } catch (err) {
      handleError();
    }
  };
  const handleSuccess = () => {
    setIsLateCheckinRequested(true);
  };
  const handleError = () => {
    showShortToast(ENG_CONSTANT.SOME_THING_WENT_WRONG_TRY_AGAIN);
    setIsLateCheckinRequested(false);
  };
  const { heading, spotHeading, subHeading, buttonAction } = isLateCheckinRequested
    ? myBizLateCheckIn.requestedState
    : myBizLateCheckIn.nonRequestedState;
  return (
    <View style={styles.planArriveCard}>
      <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom10]}>
        <View style={[AtomicCss.makeRelative]}>
          {isLateCheckinRequested && !isRequestPending && (
            <Image source={WHITE_TICK_GREEN_BG_ICON} style={styles.tickSuccess} />
          )}
          <Image source={LATE_CHECK_IN_IMAGE} style={styles.hotelImgStyle} />
        </View>
        <Text
          style={[
            AtomicCss.flex1,
            fonts.font16,
            fonts.blackFontFamily,
            AtomicCss.blackText
          ]}
        >
          {heading}
          <Text
            style={[
              isLateCheckinRequested && !isRequestPending
                ? styles.afterComText
                : styles.defaultText,
            ]}
          >
            {` ${spotHeading}`}
          </Text>
          {!isLateCheckinRequested && ' ?'}
        </Text>
      </View>
      <Text
        style={[
          AtomicCss.defaultText,
          fonts.font14,
          fonts.regularFontFamily,
          AtomicCss.marginBottom12,
        ]}
      >
        {subHeading}
      </Text>
      <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.justifyEnd]}>
        {!isEmpty(buttonAction) &&
          (buttonAction.actionType === 'RoundButton' ? (
            <TouchableOpacity
              onPress={handleClickRequest}
              style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.justifyEnd]}
            >
              {isRequestPending && !isLateCheckinRequested ? (
                <ActivityIndicator size="small" color={psTheme.color.red10} />
              ) : (
                <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
                  <Text style={[styles.defaultText, fonts.font14, fonts.blackFontFamily]}>{buttonAction.actionLabeltext} </Text>
                  <Image style={styles.rightArrowIcon} source={BLUE_RIGHT_ARROW_ICON} />
                </View>
              )}
            </TouchableOpacity>
          ) : (
            <Text style={[fonts.font14, fonts.blackFontFamily, AtomicCss.azure]}>
              {buttonAction.actionLabeltext}
            </Text>
          ))}
      </View>
    </View>
  );
};
export default HotelLateCheckInCard;
