import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import createStyles from './NewBusCardCss';
import BusCancelRebookConstant from '../../../../CancelRebookConstants';
import isEmpty from 'lodash/isEmpty';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import PayableTag from '../../../../../common/PayableTag';
import { getFont } from '../../../../../../PostSalesStyles';
import { useTheme } from '../../../../../../theme/theme.context';
import { getFormattedDateFromMillis, twentyFourHourTimeFormat } from '@mmt/legacy-commons/Helpers/dateHelpers';

const NewBookingCard = ({ item }) => {
  const {
    busType,
    availability,
    departureTime,
    arrivalTime,
    durationText,
    busFareString,
    seatMapRedirectUrl,
    totalReviews,
    overallRating,
    persuasions,
  } = item || {};

  const departure = getFormattedDateFromMillis(departureTime,twentyFourHourTimeFormat)
  const arrival = getFormattedDateFromMillis(arrivalTime,twentyFourHourTimeFormat)
  const {fsStyle,...fonts} = getFont();
  const {psTheme : theme} = useTheme();
  const styles = createStyles(theme,fonts);

  function handlePress() {
    if (seatMapRedirectUrl) GenericModule.openDeepLink(seatMapRedirectUrl);
  }

  return (
    <TouchableOpacity style={styles.newBoookingWrapper} activeOpacity={0.7} onPress={handlePress}>
      <View
        style={[
          AtomicCss.flexRow,
          AtomicCss.alignCenter,
          AtomicCss.spaceBetween,
          AtomicCss.marginBottom20,
        ]}
      >
        <View style={AtomicCss.flex1}>
          <View style={styles.seatsLeft}>
            {!!availability && (
              <Text style={[fsStyle.font10, AtomicCss.redText, fonts.regularFontFamily]}>
                {availability + ' ' + BusCancelRebookConstant.SEATS_LEFT}
              </Text>
            )}
          </View>
          {!!busType && (
            <Text
              style={[
                fsStyle.font12,
                AtomicCss.lightText,
                fonts.boldFontFamily,
                AtomicCss.marginBottom5,
              ]}
            >
              {busType}
            </Text>
          )}
          <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom5]}>
            {!!departure && (
              <Text style={[fsStyle.font22, AtomicCss.blackText, fonts.blackFontFamily]}>
                {departure}
              </Text>
            )}
            {
              <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginHz8]}>
                <View style={styles.borderLine}></View>
                {!!durationText && (
                  <Text
                    style={[
                      fsStyle.font12,
                      AtomicCss.lightText,
                      fonts.regularFontFamily,
                      AtomicCss.marginHz4,
                    ]}
                  >
                    {durationText}
                  </Text>
                )}
                <View style={styles.borderLine}></View>
              </View>
            }
            {!!arrival && (
              <Text style={[fsStyle.font22, AtomicCss.blackText, fonts.blackFontFamily]}>
                {arrival}
              </Text>
            )}
          </View>
        </View>
        <PayableTag
          tag={{ subHeading: busFareString, heading: BusCancelRebookConstant.EXTRA_PAYABLE }}
          borderBottomColor={theme.color.white}
        />
      </View>
      <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
            <LinearGradient
              start={{ x: 1.0, y: 0.0 }}
              end={{ x: 0.0, y: 1.0 }}
              colors={['#219393', '#26BD99']}
              style={styles.ratingBg}
            >
              {!!overallRating && <Text style={[fsStyle.font10, AtomicCss.whiteText, fonts.blackFontFamily]}>
              {' '}{overallRating}
              </Text>}
            </LinearGradient>
            {!!totalReviews && <Text style={[fsStyle.font10, AtomicCss.lightText, fonts.regularFontFamily]}>
              {totalReviews} {BusCancelRebookConstant.RATINGS_TEXT}
            </Text>}
        {!isEmpty(persuasions) && (
          <View style={AtomicCss.flexRow}>
            {persuasions.map((tag, index) => (
              <Text
                style={[
                  fsStyle.font10,
                  AtomicCss.lightText,
                  fonts.regularFontFamily,
                  styles.ratingText,
                ]}
              >
               {' '}•{' '}{tag}
              </Text>
            ))}
          </View>
        )}
      </View>
    </TouchableOpacity>
  );
};

export default NewBookingCard;
