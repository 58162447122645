import React from 'react';
import { View, Text, Image } from 'react-native';
import createStyles from '../styles';
import { getFont } from '@mmt/post-sales/src/PostSalesStyles';
import { useTheme } from '@mmt/post-sales/src/theme/theme.context';
import { DriverProfilePersonalInfoData } from '@mmt/post-sales/src/cab/types';
import { renderSections } from '../sectionRenderer';

const ProfileDetails = ({ data }: { data: DriverProfilePersonalInfoData }) => {
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);

  const { driverName, driverPhotoUrl, leftSectionList } = data || {};

  return (
    <View style={styles.profileContainer}>
      <View style={styles.driverName}>
        {!!driverName && (
          <Text style={styles.driverNameText} numberOfLines={1}>
            {driverName}
          </Text>
        )}
        {renderSections(leftSectionList)}
      </View>
      <Image
        source={{
          uri: driverPhotoUrl,
        }}
        style={styles.driverImageStyle}
      />
    </View>
  );
};

export default ProfileDetails;
