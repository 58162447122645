import { StyleSheet} from 'react-native';
import {colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles';


const styles = StyleSheet.create({
  getAirportCabCard: {
    backgroundColor: colors.selago,
    padding: 16,
  },
  titleWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 16,
  },
  locIconStyle: {
    width: 15,
    height: 15,
    marginRight: 10,
  },
  innerDetails: {
    padding: 8,
    flexDirection: 'row'
  },
  arrowLgStyle: {
    width: 6,
    height: 165
  },
  arrowSmStyle: {
    width: 6,
    height: 80
  },
  arrowXsStyle: {
    width: 6,
    height: 30,
  },
  rightDetails: {
    paddingLeft: 24,
    flex: 1.
  },
  locationImgStyle: {
    width: 9,
    height: 12,
  },
  locationInfo: {
    width: '70%'
  },
  locationTxt: {
    color: colors.royalBlue,
    fontFamily: fonts.bold,
    marginLeft: 5,
    fontSize: 11,
  },
  airportImgStyle: {
    width: '100%',
    height: 123,
  },
  barCodeStyle: {
    width: 54,
    height: 54,
  },
  stepTwoDetails: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  barCodeTextWrapper: {
    borderRadius: 4,
    paddingVertical: 4,
    paddingHorizontal: 8,
    borderWidth: 1,
    borderColor: colors.black
  },
});


export default styles;
