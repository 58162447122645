import React from 'react';
import { View, Text, TouchableOpacity, Image, Linking } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getStaticData } from '../../../../../../staticData/staticData';
import { getFont } from '../../../../../../PostSalesStyles';
import HTMLView from 'react-native-htmlview';

const FareServices = ({ fareServices, setFareServiceOverlayData }) => {
  const fonts = getFont(true);
  if (!fareServices || fareServices.length === 0) {
    return null;
  }
  const data = fareServices[0];
  const { description } = data || {};
  if (!description || description.length === 0) {
    return null;
  }
  const { moreText } = getStaticData();
  const fareServiceHtmlStyle = {
    p: {
      fontSize: 12,
    },
    b: {
      fontSize: 12,
      fontFamily: fonts.bold,
    },
  };
  return (
    <View style={[AtomicCss.flexRow, AtomicCss.flex1, AtomicCss.flexWrap]}>
      <HTMLView
        style={AtomicCss.alignSelfCenter}
        value={`<p> - ${description[0]}</p>`}
        stylesheet={fareServiceHtmlStyle}
      />
      {description.length > 1 && (
        <TouchableOpacity
          style={[AtomicCss.alignSelfCenter]}
          onPress={() => setFareServiceOverlayData(data)}
        >
          <Text
            style={[AtomicCss.blueText, fonts.boldFontFamily, AtomicCss.font12, AtomicCss.flexWrap]}
          >
            {' '}
            +{description.length - 1} {moreText}
          </Text>
        </TouchableOpacity>
      )}
    </View>
  );
};

export default FareServices;
