import React from 'react';
import { View, Platform ,Animated, DeviceEventEmitter } from 'react-native';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import noop from 'lodash/noop';
import get from 'lodash/get'
import HolidayBookingModule from '@mmt/legacy-commons/Native/HolidayBookingModule';
import {getContactEmaiPhone} from 'core-ui-lib/flight/common/FlightBookingDetails/utils/helper';
import { getCommonLayoutDetail } from 'core-ui-lib/flight/common/FlightBookingDetails/data/dataAdapter';
import { getappOnlyCardDetail } from 'core-ui-lib/flight/common/FlightBookingDetails/data/appCardAdapter';
import CheckListSafeTravel from '../components/CheckListSafeTravel';
import FlightExchangeCard from '../components/FlightExchangeCard';
import ExchangePNRCard from '../components/ExchangedPNRCard';
import InformationCard from '../../../../Common/InformationCard';
import RefundTracker from '../../../../Common/RefundTracker';
import CardCTA from '../components/CTACard';
import MyBizCard from '../components/MyBizCard';
import ImportantInformation from '../components/ImportantInformation';
import TripProtectCard from '../components/TripProtectCard';
import BaggageProtectCard from '../components/BaggageProtectCard';
import PrimaryContact from '../components/PrimaryContact';
import Support from '../components/Support';
import CabsCrossSellFlights from '../components/CabsAirportXSell';
import FlightCrossLobAddonCard from '../components/FlightCrossLobAddonCard';
import PaymentCard from '../components/PaymentCard';
import RaiseRefundCard from '../components/RaiseRefundCard';
import { isEnglishLangSelected } from '@mmt/legacy-commons/AppState/AppUserConfig';
import CancellationCard from '../components/CancellationCard';
import DateChangeCard from '../components/DateChangeCard';
import ChangePlan from '../components/ChangePlan';
import AlertInfo from '../../../../Common/CommonImpInfo';
import SpecialRequestCard from '../components/SpecialRequestCard';
import RTPCRCard from '../components/RTPCRCard';
import DoorToDoorPage from '../components/DoorToDoorPage';
import MMTBlackCard from '../../../../Common/MMTBlackCard';
import FlightMSRCard from '../components/FlightMSRCard/MainCard';
import CustomerSupportCard from '../../../../Common/CustomerSupportCard';
import RefundIssueCard from '../components/RefundIssueCard';
import HotelCrossSell from '../components/HotelCrossSell';
import BookMyForexCard from '../../../../Common/BookMyForexCard';
import CsatFeedbackCard from '../../../../Common/CsatFeedbackCard';
import SecureYourTripCard from '../components/SecureYourTripCard';
import TermAndConditionCard from '../components/FareLock/TermAndConditionCard';
import CompleteYourBookingCard from '../components/FareLock/CompleteYourBookingCard';
import WhyFareLockFailedCard from '../components/FareLock/WhyFareLockFailedCard';
import FlightDtlsCardMain from '../components/FlightDtlsCard';

import Actions from '../../../../navigation/postSalesNavigation';
import { RuleTraceConst } from '../../../../Common/CustomerSupportCard/CustomerSupportCardConst';
import { fetchUrlParam, getAdTechCard, getMmtSelectData } from '../../../../Common/commonUtil';
import MMTSelectNewUser from '../../../../Common/MmtSelectNewUserCard';
import { REFUND_OOT_TRACKING } from '../../../../Common/RefundOutOfTATForm/constants';
import {
  POST_SALES_FLIGHT_DETAILS_PAGE,
  POST_SALES_WEB_FLIGHT_DETAILS_PAGE,
} from '@mmt/legacy-commons/adsConfig/adsConfigConstants';
import FlightBookingDetailsConstant, { EventEmitterConst, ViewState } from '../FlightBookingDetailsConstant';
import { CALENDAR_DATE_CHANGE_ICON, BACK_WHITE_ICON } from '../../../FlightImageConstants';
import { COMMON_CARD, LOBNAMES } from '../../../../PostSalesConstant';
import FlightBookingTrackingHelper from '../helper/FlightBookingTrackingHelper';
import { getStaticData } from '../../../../staticData/staticData';
import CtripODCCard from "../components/CtripODCCard";
import {
  externalLinkPressHandler,
  isMyraEnabled
} from '../../../../utils/PostSaleUtil';
import {
  getScCardHeader,
  shouldShowChangeInPlanCard,
  getFlightCancellationRequestNo,
  showAirlineContact, isFareLockBooking,
  getSegmentAncillaryMessageObj
} from '../../../FlightBookingUtil';
import {
  onRTPCRBtnClick,
  onMMTBlackCardClickEvent,
  tripProtectCardBtnClick,
  supportCardClickHandler,
  addOnCardClickHandler,
  getLobName,
  refundEventTracker,
} from '../utils/cardUtil';
import BenefitsClaimedCard from '../../../../Common/BenefitsClaimedCard';
import TravelCashCard from '../../../../Common/TravelCard';
import TravelPassCard from '../components/TravelPassCard';
import TravelInsuranceCard from '../components/TravelInsurance';
import PartPaymentCard from '../components/PartPayment/PartPaymentDetailsCard';

export const downloadHolidayTicket = (item, bookingId) => {
  const vouchers = [];
  vouchers.push({
    triggerName: item,
  });
  if (Platform.OS === 'web') {
    HolidayBookingModule.downloadTickets(JSON.stringify(vouchers), bookingId);
  } else {
    HolidayBookingModule.downloadTickets(JSON.stringify(vouchers));
  }
};

export const shouldAddSeatMealPopupRendered = async (bookingId) => {
  const addSeatmealPopupkey = `addSeatMealPopup${bookingId}`;
  const addSeatMealPopUpShowed = await AsyncStorage.getItem(addSeatmealPopupkey);
  const  shouldAddSeatMealPopupRender = !addSeatMealPopUpShowed
    ? false
    : JSON.parse(addSeatMealPopUpShowed);
    return shouldAddSeatMealPopupRender;
};

export const getInitialFltDetailState = (isLostBooking) => {
  return {
    responseJson: null,
    universalSearchResponse: null,
    viewState: isLostBooking ? ViewState.SHOW_DETAIL : ViewState.LOADING,
    specialClaimSelectedItem: undefined,
    showDateChangeOverlay: false,
    segmentAncillaryDetails: [],
    ancillaryResponseJson: null,
    ancillaryResponseReceived: false,
    acmeCity: undefined,
    showDateChangeSubmittedOverlay: false,
    showDateChangeSubmittedOverlayIndex: undefined,
    showChecklistOverlay: false,
    showWebcheckinOverlay: false,
    showWheelChairOverlay: false,
    wheelChairActiveSegment: null,
    selectFlights: false,
    flightDetailsLayout: {},
    completeYouTripJson: {},
    helpingHandOverlay: false,
    callbackBottomSheet: true,
    bottomSheet: '',
    top: new Animated.Value(1100),
    fareServiceOverlayData: null,
    showAddSeatMealPopup: false,
    showSpecialClaimBottomSheet: { value: false, data: null },
    bottomSheetAnimated: new Animated.Value(-500),
    odcAncillaryPopUpMessage: null,
    showDownloadInvoiceOverlay: false,
    errorPageData: {},
    tentativeWindowBookingStatus: {},
    showCancellationInfoOverlay: { value: false, data: null },
    isTimeSpentMoreThanSpecified: false,
    isCtaClicked: false,
    openCsatPopup: false,
    isCsatPopupHandled: false,
    serviceGuranteeOverlay: false,
    releaseholdOverlay:false,
    freeCancellationConfirmOverlay: false,
    partPayCancelConfirmOverlay: false,
    showVisaGuaranteeOverlay: false,
    showAutoSearchPopup: false,
    ctaSearchErrorMessage: false,
    showNameChangeOverlay: false,
    ctripODCViewMoreOverlay: false,
    bottomOverlay: '',
    overlayPosition: new Animated.Value(-1000),
  };
}

export const externalLinkHandler = (action, title, iosURL) => {
  let externalUrl = action.url;
  if (Platform.OS === 'ios' && iosURL) {
    externalUrl = iosURL;
  }
  if (externalUrl) {
    if (action.openInWebView) {
      Actions.openPsWebView({
        headerText: action.webViewTitle || title,
        headerIcon: BACK_WHITE_ICON,
        url: externalUrl,
      });
    } else {
      externalLinkPressHandler(externalUrl);
    }
  }
};

export const  onRefundBreakupBtnClickHandler = (requestNo, version, responseJson= {}) => {
  if (version && version == 2) {
    Actions.fltRefundBreakupV2({
      bookingId: responseJson.bookingID,
      lobCode: responseJson.lobCode,
      apiPSLob: LOBNAMES.FLIGHT,
    });
  } else {
    Actions.fltRefundBreakup({
      bookingId: responseJson.bookingID,
      requestNo,
      lobCode: responseJson.lobCode,
      lobName: getLobName(),
      apiPSLob: LOBNAMES.FLIGHT,
    });
  }
  DeviceEventEmitter.emit(EventEmitterConst.CTA_CLICKED_EVENT);
  FlightBookingTrackingHelper.trackClickEvent(
    'Details',
    'Flight_refund_Details',
    responseJson,
  );
  FlightBookingTrackingHelper.trackLoadEvent('Details_refunddetail', responseJson);
};


export const  onRefundModeSubMessageLinkPress = (params, responseJson, openDeepLink) => {
  if (params.bookingId !== responseJson.bookingId) {
    Actions.flightBookingDetail({ BOOKING_ID: params.bookingId, type: 'push' });
  } else {
    openDeepLink(responseJson, params.act);
  }
};

export const getRefundCard = (
  cancellationDetailList,
  response,
  lostBooking = false,
  onRefundBreakupBtnClicked,
  openDeepLink,
) => {
  const staticData = getStaticData();
  const cancellationRequestNoList = getFlightCancellationRequestNo(cancellationDetailList);
  const {bookingID: bookingId} = response || {};
  if (!isEmpty(cancellationRequestNoList)) {
    return (
      <RefundTracker
        key="RefundTracker"
        claimRaisedTitle={staticData.refundClaimRaisedText}
        buttonText={staticData.viewRefundCalculationText}
        cancelRequests={cancellationRequestNoList}
        bookingId={bookingId}
        showRefundCalculationBtn
        refundCalculationBtnClick={
          lostBooking ? onRefundBreakupBtnClicked : (requestNo, version)=>onRefundBreakupBtnClickHandler(requestNo, version, response)
        }
        bookingResponse={response}
        trackRefundEvent={lostBooking ? null : (actionType)=>refundEventTracker(actionType, response)}
        onRefundModeSubMessageLinkPress={lostBooking ? noop : (params)=>onRefundModeSubMessageLinkPress(params,response, openDeepLink)}
        navigationObj={Actions}
        lobName={LOBNAMES.FLIGHT}
        isLostBookingId={lostBooking}
        pageName={REFUND_OOT_TRACKING.PAGES.FLIGHT}
      />
    );
  }
  return null;
};

const openDeepLinkFromNeedHelp = (url, openDeepLink, flightDetailResponse) => {
  const params = fetchUrlParam(url);
  const deepLinkPageName = params.act;
  openDeepLink(flightDetailResponse, parseInt(deepLinkPageName));
};

const renderAdTechCard = () => {
  return getAdTechCard(
    Platform.OS !== 'web' ? POST_SALES_FLIGHT_DETAILS_PAGE : POST_SALES_WEB_FLIGHT_DETAILS_PAGE,
  );
};

export const getCardLists = ({
  flightDetailResponse = {},
  staticData = {},
  props,
  handlerFunctions,
  customerSupportCardData,
  showsecureCardDetail,
  uniqueCrId,
  tentativeWindowBookingStatus,
  errorPageData,
  segmentAncillaryDetails,
  ancillaryResponseReceived,
  isCsatInlineCardEnabled,
}) => {
  const {
    toggleChecklistOverlay,
    checklistActionHandler,
    onNewBookingBtnClicked,
    dateChangeViewDetailsClick,
    openDigitTermsNCond,
    setInsuranceViewRef,
    baggageProtectCardBtnClick,
    onPaymentCardBtnClick,
    specialClaimActionClick,
    cancellationButtonClick,
    onContinueClicked,
    dateChangeButtonClick,
    onChangeInTravelPlanBtnClick,
    specialRequestCardBtnClick,
    toggleSecureList,
    handleBottomSheet,
    openDeepLink,
    updateFlightLayout,
    setRef,
    setFareServiceOverlayData,
    toggleCancellationBookingFreeConfirmation,
    toggleWheelChairOverlay,
    toggleCtripODCViewMoreOverlay,
    openVpgInfoOverlay
  } = handlerFunctions;

  const views = [];

  const {
    uuid,
    cancellationDetailList,
    cardList,
    checkList,
    exchangeFlightCardInfo,
    exchangeBookingCardInfo,
    requestedDateChangeCard,
    ctaInfo,
    impInfo,
    isCorporateBooking,
    bookingId,
    accountDetail,
    insuranceDetails,
    tripMoneyInsuranceDetails,
    tpInsuranceDetails,
    rtpcrDetail,
    baggageProtectDetail,
    assuranceDetails,
    isGccBooking,
    shouldShowSupportCard,
    showAirlineContactNo,
    showMyra,
    childBookingIdList,
    childBookingMessages,
    specialClaimDataList,
    scTopMessage,
    messageAttributes,
    cancelCardDetailInfo,
    dbMessage,
    zcGlobalMsg,
    cancellationCardNormalMsgObj,
    cfarApplicable,
    datechangeCacheDetail,
    isFreeDateChangeAssured,
    differentialPenaltyNodes,
    importantInformationList,
    specialRequestCardDetailInfo,
    doorToDoorInfo,
    loyaltyDetails,
    allTicketsForBooking,
    needHelp,
    needMoreHelpMIMACard,
    fareLockBookingCardData,
    holdBookingCardData,
    fareLockTermsAndConditionsData,
    holdbookingTermsAndConditionsData,
    fareLockWhyFailedCardData,
    holdbookingFailedCardData,
    isInsuranceApplicable,
    forexDetail,
    cfarImagePath,
  } = getCardListDetail(flightDetailResponse, props.showMyraBtn);
  const gccBooking = flightDetailResponse && flightDetailResponse.isGccBooking;
  const {visaGuaranteeDetails} = flightDetailResponse;
  const showVpgCard = visaGuaranteeDetails?.showVisaGuaranteeCard;
  if (!isEmpty(checkList)) {
    views.push(
      <CheckListSafeTravel
        btnText={staticData.viewAllText}
        flightResponse={flightDetailResponse}
        onViewAllClick={toggleChecklistOverlay}
        checkList={checkList}
        holidayPageName={props.holidayPageName}
        inHolidayFlow={props.holidayBookingApi}
        checklistActionHandler={checklistActionHandler}
      />,
    );
  }
  if(!isEmpty(flightDetailResponse.delayedOdcDetails) ){
    views.push(
      <CtripODCCard
      key={'ctrip_odc_card'}
      toggleCtripODCViewMoreOverlay={toggleCtripODCViewMoreOverlay}
      delayedOdcDetails={flightDetailResponse.delayedOdcDetails}
      />
    )
  }
  if (!isEmpty(exchangeFlightCardInfo)) {
    views.push(
      <FlightExchangeCard
        flightResponse={flightDetailResponse}
        exchangeFlightCardInfo={exchangeFlightCardInfo}
        bookingId={bookingId}
      />,
    );
  }
  if (!isEmpty(exchangeBookingCardInfo)) {
    views.push(
      <ExchangePNRCard
        exchangeBookingCardInfo={exchangeBookingCardInfo}
        btnClickListener={onNewBookingBtnClicked}
      />,
    );
  }
  if (!isEmpty(requestedDateChangeCard)) {
    requestedDateChangeCard.forEach((data, index) => {
      views.push(
        <InformationCard
          iconSource={CALENDAR_DATE_CHANGE_ICON}
          title={data.tittle}
          description={data.description}
          cardBackgroundColor="#ffedd1"
          clickableText={staticData.viewDetailsText}
          textColor="#cf8100"
          clickEventActionType={index}
          clickEvent={dateChangeViewDetailsClick}
        />,
      );
    });
  }

  let changeInPlanCardAdded = false;
  !isEmpty(cardList) && cardList.forEach((card) => {
    if (card.cardId === 'TopStatusCard') {
      flightDetailResponse.topCard = card;
    } else {
      switch (card.cardId) {
        case FlightBookingDetailsConstant.PART_PAYMENT_CARD: {
          if(flightDetailResponse?.partPaymentCompleteBookingData) {
            views.push(
              <PartPaymentCard bookingId={bookingId} response={flightDetailResponse?.partPaymentCompleteBookingData} />
            )
          }
          break;
        }
        case FlightBookingDetailsConstant.CTA_CARD:
          if (!isEmpty(ctaInfo)) {
            views.push(<CardCTA key="CTA" ctaInfo={ctaInfo} />);
          }
          if (isCorporateBooking) {
            views.push(<MyBizCard key="MyBiz" title={staticData.myBizCardText.heading} />);
          }
          break;
        case FlightBookingDetailsConstant.FLIGHT_DETAILS_CARD:
          const { topStatusCard } = flightDetailResponse || {};
          const { topCardType } = topStatusCard || {};
          const segmentAncillaryGenericMessageObj = getSegmentAncillaryMessageObj(segmentAncillaryDetails);
          flightDetailResponse.newFlightDetails.segmentGroupDetailList.map(
            (segmentFlightDetails, index) => {
              const key = `SegmentCard${index}`;
              if (
                segmentFlightDetails.segmentDetails &&
                segmentFlightDetails.segmentDetails.length > 0
              ) {
                views.push(
                  <FlightDtlsCardMain
                    key={key}
                    updateFlightLayout={updateFlightLayout}
                    layoutKey={key}
                    tentativeWindowBookingStatus={tentativeWindowBookingStatus}
                    segIndex={index}
                    flightDetailResponse={flightDetailResponse}
                    segmentFlightDetails={segmentFlightDetails}
                    baggageAirlineInfo={flightDetailResponse.baggageDetailList[index]}
                    errorPageData={errorPageData}
                    bookingId={bookingId}
                    segmentAncillaryDetails={segmentAncillaryDetails}
                    ancillaryResponseReceived={ancillaryResponseReceived}
                    mobileNumber={getContactEmaiPhone(flightDetailResponse).contactNumber}
                    collapsed={props.collapseIndex >= 0 ? props.collapseIndex != index : null}
                    holidayBooking={props.holidayBookingApi}
                    holidayData={props.holidayData}
                    openDeepLink={openDeepLink}
                    setFareServiceOverlayData={setFareServiceOverlayData}
                    isCorporateBooking={flightDetailResponse.isCorporateBooking}
                    toggleWheelChairOverlay={toggleWheelChairOverlay}
                    holidayPageName={props.holidayPageName}
                    refElement={setRef}
                    isFareLockBooking={isFareLockBooking(topCardType)}
                    segmentAncillaryGenericMessageObj={segmentAncillaryGenericMessageObj}
                    toggleCtripODCViewMoreOverlay={toggleCtripODCViewMoreOverlay}
                  />,
                );
              }
            },
          );
          break;

        case FlightBookingDetailsConstant.TRAVEL_INSURANCE_CARD:
          if(flightDetailResponse?.insuranceQuoteDetails) {
            views.push(<TravelInsuranceCard bookingId={bookingId} response={flightDetailResponse?.insuranceQuoteDetails} />);
          }
          break;

        case FlightBookingDetailsConstant.TRAVEL_PASS_CARD:
          if (flightDetailResponse?.travelPassDetails) {
            views.push(<TravelPassCard travelData={flightDetailResponse?.travelPassDetails} />);
          }
          break;

        case FlightBookingDetailsConstant.IMP_INFORMATION_CARD:
          if (!isEmpty(impInfo)) {
            views.push(
              <ImportantInformation
                infoList={impInfo}
                key="ImpInfo"
                bookingId={bookingId}
                title={staticData.importantInformationText}
              />,
            );
          }
          break;
          case FlightBookingDetailsConstant.TRIP_PROTECT_CARD:
            if (!isEmpty(tripMoneyInsuranceDetails)) {
              tripMoneyInsuranceDetails.forEach((item, index) => {
                views.push(
                  <TripProtectCard
                    key={`TripMoneyInsuranceInfo-${index}`}
                    insuranceDetails={item}
                    insuranceBtnClick={(insranceData, cardType) =>
                      tripProtectCardBtnClick(insranceData, cardType, flightDetailResponse)
                    }
                    policyBtnClick={openDigitTermsNCond}
                    cardType="TRIP_MONEY_INSURANCE"
                    refElement={setInsuranceViewRef}
                  />
                );
              });
            } else if (!isEmpty(insuranceDetails)) {
              views.push(
                <TripProtectCard
                  key="TripProtectInfo"
                  insuranceDetails={insuranceDetails}
                  insuranceBtnClick={(insranceData, cardType) =>
                    tripProtectCardBtnClick(insranceData, cardType, flightDetailResponse)
                  }
                  policyBtnClick={openDigitTermsNCond}
                  cardType="INSURANCE"
                  refElement={setInsuranceViewRef}
                />,
              );
            }
            break;
        case FlightBookingDetailsConstant.BAGGAGE_PROTECT_CARD:
          if (!isEmpty(baggageProtectDetail)) {
            views.push(
              <BaggageProtectCard
                key="BaggageProtectInfo"
                baggaeProtectionDetails={baggageProtectDetail}
                baggageProtectCardBtnClick={baggageProtectCardBtnClick}
              />,
            );
          }
          break;
        case FlightBookingDetailsConstant.CUSTOMER_CONTACT_CARD:
          if (accountDetail) {
            views.push(
              <PrimaryContact
                title={staticData.primaryContactText}
                accountDetail={accountDetail}
                key="PrimaryContact"
              />,
            );
          }
          break;
        case FlightBookingDetailsConstant.SUPPORT_CARD:
          if (shouldShowSupportCard) {
            views.push(
              <Support
                key="Support"
                supportCardClickHandler={() => supportCardClickHandler(flightDetailResponse)}
                showAirlineContactBtn={showAirlineContactNo}
                showMyraBtn={showMyra}
                isCorpBooking={isCorporateBooking}
                isGccBooking={isGccBooking}
              />,
            );
          }

          break;
        case FlightBookingDetailsConstant.REFUND_CARD:
          const refundCard = getRefundCard(
            cancellationDetailList,
            flightDetailResponse,
            false,
            noop,
            openDeepLink
          );
          if (refundCard) {
            views.push(refundCard);
          }
          break;
        case FlightBookingDetailsConstant.FLIGHT_CAB_CROSS_SELL_CARD: {
          
          if(isEnglishLangSelected()) {
            views.push(
              <CabsCrossSellFlights
                flightBookingId={bookingId}
                isCorporateBooking={flightDetailResponse.isCorporateBooking}
              />,
            );
          }
          
          break;
        }
        case FlightBookingDetailsConstant.FLIGHT_CAB_ADDON_CARD:
          if (!isEmpty(childBookingIdList) || showVpgCard || flightDetailResponse?.airportServicesDetails) {
            views.push(
              <FlightCrossLobAddonCard
                key="CrossSellAddOn"
                title={childBookingMessages?.addOnBoughtMessage || staticData.youAlsoBookedText}
                bookingId={bookingId}
                flightDetailResponse={flightDetailResponse}
                childBookingIdList={childBookingIdList}
                addOnNotPresentMessage={childBookingMessages?.addOnNotPresentMessage}
                showVpgCard={showVpgCard}
                visaGuaranteeDetails={visaGuaranteeDetails}
                openVpgInfoOverlay={openVpgInfoOverlay}
              />,
            );
          }
          break;
        case FlightBookingDetailsConstant.PAYMENT_CARD:
          views.push(
            <PaymentCard
              key="PaymentDetails"
              flightDetailResponse = {flightDetailResponse}
              title={staticData.totalPaidText}
              btnTxt={staticData.viewPaymentDetailsText}
              paymentDetails={flightDetailResponse.paymentDetails}
              downloadInvoiceEnable={flightDetailResponse.showDownloadInvoice}
              onBtnClick={onPaymentCardBtnClick}
              ctripMsg={flightDetailResponse.ctripMsg}
            />,
          );
          break;
        case COMMON_CARD.TRAVEL_CARD:
          if(!isEmpty(flightDetailResponse.travelCardResponse)){
            views.push(
              <TravelCashCard
                key="TravelCashCard"
                bookingID={bookingId}
                uuid={uuid}
                travelCardResponse={flightDetailResponse.travelCardResponse}
              />,
            );
          }
          break;
        case FlightBookingDetailsConstant.SPECIAL_CLAIM_CARD:
          if (!isEmpty(specialClaimDataList)) {
            views.push(
              <RaiseRefundCard
                key="RaiseClaim"
                specialClaimDataList={specialClaimDataList}
                specialClaimActionClick={specialClaimActionClick}
                scTopMessage={scTopMessage}
                scTopHeader={getScCardHeader(messageAttributes)}
              />,
            );
          }
          break;
        case FlightBookingDetailsConstant.CANCEL_DETAIL_INFO_CARD:
          if (!isEmpty(cancelCardDetailInfo)) {
            views.push(
              <CancellationCard
                key="CancelInfo"
                pageName="Details"
                cancelCardDetailInfo={cancelCardDetailInfo}
                cancellationButtonClick={cancellationButtonClick}
                dbMessage={dbMessage}
                imagePath={cfarImagePath}
                normalMsgObj={cancellationCardNormalMsgObj}
                zcGlobalMsg={zcGlobalMsg}
                onContinueClicked={onContinueClicked}
                isCorporateBooking={isCorporateBooking}
                isCfarBooking={cfarApplicable}
                refElement= {setRef}
                gccBooking={gccBooking}
              />,
            );
          }
          break;
        case FlightBookingDetailsConstant.DATE_CHANGE_DETAIL_INFO_CARD:
          if (!isEmpty(datechangeCacheDetail)) {
            views.push(
              <DateChangeCard
                key="DateChange"
                pageName="Details"
                delayedOdcDetails={flightDetailResponse.delayedOdcDetails}
                dateChangeCacheDetail={datechangeCacheDetail}
                dateChangeButtonClick={dateChangeButtonClick}
                differentialPenaltyList={differentialPenaltyNodes}
                isFreeDateChangeAssured={isFreeDateChangeAssured}
                refElement={setRef}
                toggleCtripODCViewMoreOverlay={toggleCtripODCViewMoreOverlay}
              />,
            );
          }
          break;
        case FlightBookingDetailsConstant.SPECIAL_CLAIM_CARD_OR_CHANGE_IN_PLAN:
          if (!changeInPlanCardAdded) {
            if (shouldShowChangeInPlanCard(flightDetailResponse)) {
              changeInPlanCardAdded = true;
              views.push(
                <ChangePlan
                  key="ChangeinPlan"
                  clickHandler={onChangeInTravelPlanBtnClick}
                  response={flightDetailResponse}
                />,
              );
            } else if (!isEmpty(specialClaimDataList)) {
              views.push(
                <RaiseRefundCard
                  key="RaiseClaim"
                  specialClaimDataList={specialClaimDataList}
                  specialClaimActionClick={specialClaimActionClick}
                  scTopMessage={scTopMessage}
                  scTopHeader={getScCardHeader(messageAttributes)}
                />,
              );
            }
          }
          break;
        case FlightBookingDetailsConstant.CANCEL_DETAIL_INFO_CARD_OR_CHANGE_IN_PLAN:
          if (!changeInPlanCardAdded) {
            if (shouldShowChangeInPlanCard(flightDetailResponse)) {
              changeInPlanCardAdded = true;
              views.push(
                <ChangePlan
                  key="ChangeinPlan"
                  clickHandler={onChangeInTravelPlanBtnClick}
                  response={flightDetailResponse}
                />,
              );
            } else if (!isEmpty(cancelCardDetailInfo)) {
              views.push(
                <CancellationCard
                  key="CancelInfo"
                  pageName="Details"
                  cancelCardDetailInfo={cancelCardDetailInfo}
                  cancellationButtonClick={cancellationButtonClick}
                  dbMessage={dbMessage}
                  normalMsgObj={cancellationCardNormalMsgObj}
                  imagePath={cfarImagePath}
                  zcGlobalMsg={zcGlobalMsg}
                  onContinueClicked={onContinueClicked}
                  isCfarBooking={cfarApplicable}
                  refElement= {setRef}
                  gccBooking={gccBooking}
                />,
              );
            }
          }
          break;
        case FlightBookingDetailsConstant.DATE_CHANGE_DETAIL_INFO_CARD_OR_CHANGE_IN_PLAN:
          if (!changeInPlanCardAdded) {
            if (shouldShowChangeInPlanCard(flightDetailResponse)) {
              changeInPlanCardAdded = true;
              views.push(
                <ChangePlan
                  key="ChangeinPlan"
                  clickHandler={onChangeInTravelPlanBtnClick}
                  response={flightDetailResponse}
                />,
              );
            } else if (!isEmpty(datechangeCacheDetail)) {
              views.push(
                <DateChangeCard
                  key="DateChange"
                  pageName="Details"
                  delayedOdcDetails = {flightDetailResponse.delayedOdcDetails}
                  dateChangeCacheDetail={datechangeCacheDetail}
                  dateChangeButtonClick={dateChangeButtonClick}
                  differentialPenaltyList={differentialPenaltyNodes}
                  isFreeDateChangeAssured={isFreeDateChangeAssured}
                  refElement={setRef}
                  toggleCtripODCViewMoreOverlay={toggleCtripODCViewMoreOverlay}
                />,
              );
            }
          }
          break;
        case FlightBookingDetailsConstant.ALERT_INFO_CARD:
          views.push(<AlertInfo key="AlertInfo" infoList={importantInformationList} />);
          break;
        case FlightBookingDetailsConstant.SPECIAL_REQUEST_CARD:
          if (!isEmpty(specialRequestCardDetailInfo)) {
            views.push(
              <SpecialRequestCard
                {...specialRequestCardDetailInfo}
                onBtnClick={specialRequestCardBtnClick}
              />,
            );
          }
          break;
        case FlightBookingDetailsConstant.ASSURANCE_CARD:
          if (!isEmpty(assuranceDetails) && !isEmpty(assuranceDetails.journeys)) {
            views.push(
              <TripProtectCard
                key="TripProtectInfo"
                insuranceDetails={assuranceDetails}
                insuranceBtnClick={(insuranceData, cardType) =>
                  tripProtectCardBtnClick(insuranceData, cardType, flightDetailResponse)
                }
                policyBtnClick={openDigitTermsNCond}
                cardType="ASSURANCE"
              />,
            );
          }
          break;
        case FlightBookingDetailsConstant.TP_ASSURANCE_CARD:
          if (!isEmpty(tpInsuranceDetails)) {
            views.push(
              <TripProtectCard
                key="TpInsuranceCard"
                insuranceDetails={tpInsuranceDetails}
                insuranceBtnClick={(insuranceData, cardType) =>
                  tripProtectCardBtnClick(insuranceData, cardType, flightDetailResponse)
                }
                policyBtnClick={openDigitTermsNCond}
                cardType="INSURANCE"
              />,
            );
          }
          break;
        case FlightBookingDetailsConstant.RT_PCR_CARD:
          if (!isEmpty(rtpcrDetail)) {
            views.push(
              <RTPCRCard key="RTPCRCard" {...rtpcrDetail} onRTPCRBtnClick={onRTPCRBtnClick} />,
            );
          }
          break;
        case FlightBookingDetailsConstant.DOOR_TO_DOOR_CARD:
          if (!isEmpty(doorToDoorInfo)) {
            views.push(
              <DoorToDoorPage
                {...doorToDoorInfo}
                defaultActionButtonTitleText={staticData.doorToDoorCardText.heading}
              />,
            );
          }
          break;
        case COMMON_CARD.LOYALTY_CARD:
          if(isGccBooking && !isEmpty(loyaltyDetails)){
            const mmtSelectData = getMmtSelectData(loyaltyDetails);
            const { bookingState } = flightDetailResponse || {};
            views.push(
              <MMTSelectNewUser mmtSelectData={mmtSelectData} bookingState={bookingState} pageName={FlightBookingTrackingHelper.getPageName('Details', flightDetailResponse)}/>
            )
          }else if (!isEmpty(loyaltyDetails)) {
            views.push(
              <MMTBlackCard
                {...loyaltyDetails}
                bookingId={bookingId}
                uuid={uuid}
                onClick={(omnitureClickEvent) =>
                  onMMTBlackCardClickEvent(omnitureClickEvent, flightDetailResponse)
                }
                trackingPageName={FlightBookingTrackingHelper.getPageName(
                  'Details',
                  flightDetailResponse,
                )}
                navigation={Actions}
              />,
            );
          }
          break;
        case COMMON_CARD.BENEFITS_CLAIMED_CARD:
          if (isGccBooking && loyaltyDetails) {
            const mmtSelectData = getMmtSelectData(loyaltyDetails);
            const { bookingState } = flightDetailResponse || {};
            views.push(
                <MMTSelectNewUser 
                  mmtSelectData={mmtSelectData} 
                  pageName={FlightBookingTrackingHelper.getPageName(
                    'Details',
                    flightDetailResponse,
                  )}
                  bookingState={bookingState} />
              );
          } else if (loyaltyDetails && !isCorporateBooking) {
            views.push(
              <BenefitsClaimedCard
                {...loyaltyDetails}
                key="BenefitsClaimedCard"
                bookingId={bookingId}
                trackingPageName={FlightBookingTrackingHelper.getPageName(
                  'Details',
                  flightDetailResponse,
                )}
              />
            );
          }
          break;
        case FlightBookingDetailsConstant.FLIGHT_MSR:
          views.push(
            <FlightMSRCard
              pageName="Details"
              ticketsList={(allTicketsForBooking || {}).ticketsList}
              allTicketsForBooking={flightDetailResponse.allTicketsForBooking}
              trackingPageName={FlightBookingTrackingHelper.getPageName(
                'Details',
                flightDetailResponse,
              )}
            />,
          );
          break;
        case FlightBookingDetailsConstant.NEED_MORE_HELP_CARD:
          if (customerSupportCardData?.ruleTrace === RuleTraceConst.INLINE) {
            views.push(
              <CustomerSupportCard
                needMoreHelpCard={needMoreHelpMIMACard}
                deepLinkHandler={(url) => {
                  openDeepLinkFromNeedHelp(url, openDeepLink, flightDetailResponse);
                }}
                pageName={FlightBookingTrackingHelper.getPageName('Details', flightDetailResponse)}
                bookingId={bookingId}
              />,
            );
          }
          break;
        case FlightBookingDetailsConstant.NEED_HELP_FOR_REFUND:
          if (!isEmpty(needHelp)) {
            views.push(<RefundIssueCard {...needHelp} bookingId={bookingId} />);
          }
          break;
        case FlightBookingDetailsConstant.FLIGHT_ADD_TECH_CARD:
          !isCorporateBooking && views.push(
              <View style={{alignItems: 'center'}}>
                {renderAdTechCard()}
              </View>
            );
          break;
        case FlightBookingDetailsConstant.FARE_LOCK_ACTIVE_CARD:
        case FlightBookingDetailsConstant.PENDING_PAYMENT_CARD:
          const priceHoldCardData =
            card.cardId === FlightBookingDetailsConstant.FARE_LOCK_ACTIVE_CARD
              ? fareLockBookingCardData
              : holdBookingCardData;
          if (!isEmpty(priceHoldCardData)) {
            views.push(<CompleteYourBookingCard
                {...priceHoldCardData}
                toBePaidText={staticData.toBePaidText}
                isHoldBooking={card.cardId === FlightBookingDetailsConstant.HOLD_BOOKING_ACTIVE_CARD}
              />);
          }
          break;
        case FlightBookingDetailsConstant.FARE_LOCK_TANDC_CARD:
        case FlightBookingDetailsConstant.HOLD_TANDC_CARD:
          const priceHoldTermsAndConditionsData = card.cardId === FlightBookingDetailsConstant.FARE_LOCK_TANDC_CARD
            ? fareLockTermsAndConditionsData
            : holdbookingTermsAndConditionsData;
          if (!isEmpty(priceHoldTermsAndConditionsData)) {
            views.push(
              <TermAndConditionCard
                handleBottomSheet={handleBottomSheet}
                {...priceHoldTermsAndConditionsData}
              />,
            );
          }
          break;
        case FlightBookingDetailsConstant.WHY_FARE_LOCK_FAIL_CARD:
        case FlightBookingDetailsConstant.WHY_HOLD_BOOKING_FAIL_CARD:
          const priceHoldFailedCardData = card.cardId === FlightBookingDetailsConstant.WHY_FARE_LOCK_FAIL_CARD
            ? fareLockWhyFailedCardData
            : holdbookingFailedCardData;
          if (!isEmpty(priceHoldFailedCardData)) {
            views.push(<WhyFareLockFailedCard {...priceHoldFailedCardData} />);
          }
          break;
        case FlightBookingDetailsConstant.DIGIT_INSURANCE_CARD:
          if (isInsuranceApplicable) {
            views.push(
              <SecureYourTripCard
                key="SecureYourTrip"
                toggleSecureList={toggleSecureList}
                showsecureCardDetail={showsecureCardDetail}
                cardType="DIGITINSURANCE"
                bookingId={bookingId}
                bookingDetailRes={flightDetailResponse}
                uniqueCrId={uniqueCrId}
                isCorporateBooking={isCorporateBooking}
              />,
            );
          }
          break;
        case FlightBookingDetailsConstant.CSAT_FEEDBACK_CARD:
          if (isCsatInlineCardEnabled) {
            views.push(
              <CsatFeedbackCard
                psLob={()=>{getLobName(props.holidayBookingApi)}}
                bookingDetailsResponse={flightDetailResponse}
                pageName={FlightBookingTrackingHelper.getPageName('Details', flightDetailResponse)}
                bookingId={bookingId}
              />,
            );
          }
          break;
        case COMMON_CARD.BOOK_MY_FOREX_CARD:
          if (!isEmpty(forexDetail)) {
            views.push(
              <BookMyForexCard
                cardData={forexDetail}
                pageName={FlightBookingTrackingHelper.getPageName('Details', flightDetailResponse)}
              />,
            );
          }
          break;
        case FlightBookingDetailsConstant.FLIGHT_HOTEL_CROSS_SELL_CARD:
          views.push(
            <HotelCrossSell
              {...pick(flightDetailResponse, [
                'bookingDateTime',
                'isInternational',
                'newFlightDetails',
                'passengerList',
                'bookingID',
              ])}
            />,
          );
          break;
        default:
          break;
      }
    }
  });
  return views;
};

export const getCardListDetail = (flightDetailResponse, showMyraBtn) => {
  const commonLayoutDetail = getCommonLayoutDetail(flightDetailResponse);
  const appCardDetail = getappOnlyCardDetail(flightDetailResponse);
  const { lobCode, isCorporateBooking } = commonLayoutDetail;
  const isGccBooking =
    (flightDetailResponse && flightDetailResponse.isGccBooking) ||
    flightDetailResponse.isKsaBooking;
  const showMyra = showMyraBtn && !isGccBooking && isMyraEnabled(lobCode);
  const showAirlineContactNo = showAirlineContact(flightDetailResponse);
  const shouldShowSupportCard = showMyra || showAirlineContactNo || isCorporateBooking;
  const cfarImagePath = get(flightDetailResponse,'farePenaltyList.Cancellation.headerImage',null);
  return {
    ...commonLayoutDetail,
    ...appCardDetail,
    isGccBooking,
    shouldShowSupportCard,
    showAirlineContactNo,
    showMyra,
    cfarImagePath
  };
};
