import { nativeLog } from './utils/PostSaleUtil';

export default class PostSalesEventLogger {

    static loadEvent(pageName, eventData) {
        const action = `${pageName}_load`;
        eventData = this.extractUsefulData(eventData);
        this.logPostSalesAction(action, eventData);
    }

    static clickEvent(pageName, clickEvent, eventData) {
        const action = `${pageName}-${clickEvent}_clk`;
        eventData = this.extractUsefulData(eventData);
        this.logPostSalesAction(action, eventData);
    }

    static errorEvent(pageName, eventData) {
        const action = `${pageName}_error`;
        eventData = this.extractUsefulData(eventData);
        this.logPostSalesAction(action, eventData);
    }

    static successEvent(eventName, eventData) {
        const action = `${eventName}_success`;
        this.logPostSalesAction(action, eventData);
    }

    static failureEvent(eventName, eventData) {
        const action = `${eventName}_fail`;
        this.logPostSalesAction(action, eventData);
    }

    static logPostSalesAction(action, eventData) {
        try {
            const logData = eventData ? JSON.stringify(eventData) : null;
            nativeLog(`PS_${action} ${logData}`);
        } catch(err) {
            console.log("Exception in PostSalesEventLogger.js");
        }
    }

    static extractUsefulData(eventData) {
        try {
            if(eventData) {
                const { bookingID, bookingId, uuid, error } = eventData;
                return {
                    bookingId: bookingID || bookingId,
                    uuid,
                    error
                }
            }
            return null;
        } catch (error) {
            return null;
        }
    }
}
