import React from 'react';
import Actions from '../../../navigation/postSalesNavigation';
import PropTypes from 'prop-types';
import {Image, ScrollView, Text, View} from 'react-native';
import getStyles from './HotelMamiCss';
import CommonHeader from '../components/CommonHeader';
import {isIntlHotelBooking} from '../utils/HotelBookingDetailUtil';
import HotelBookingTrackingHelper from '../../HotelBookingTrackingHelper';
import HTMLView from 'react-native-htmlview';
import { getStaticData } from '../../../staticData/staticData';
import { ROUNDED_HOTEL_ICON, WHITE_CROSS_ICON } from '../../HotelImageConstants';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

class ViewHotelPolicy extends React.Component {
  static navigationOptions = {
    header: null
  };

  constructor(props) {
    super(props);
    this.fonts = getFont(true);
    this.styles = getStyles(this.fonts);
  }

  render() {
    const staticData = getStaticData();
    const hotelDetailList = this.props.response.hotelBookingInfo.hotelDetailsList[this.props.response.hotelBookingInfo.hotelDetailsList.length - 1];
    const HotelIcon = ROUNDED_HOTEL_ICON;
    let type = hotelDetailList.propertyType ? hotelDetailList.propertyType : staticData.hotelText;
    const headerTxt = this.props.response.hotelBookingInfo.hotelDetailsList[0].hotelUIFields && this.props.response.hotelBookingInfo.hotelDetailsList[0].hotelUIFields.hotelPolicyText || type + ' Policy';

    return (
      <View style={[this.styles.whiteContainer, this.styles.transperantBgrnd]}>
        <CommonHeader
          headerText={headerTxt}
          imgSrc={WHITE_CROSS_ICON}
          backPressHandler={this.onBackPress}
        />
        <ScrollView>
          <View style={this.styles.PolicyHeading}>
            <Image style={this.styles.HotelIcon} source={HotelIcon} />
            <Text style={[this.styles.BlackText, this.styles.blackFont, this.styles.PolicyHeadingText]}>
              {this.props.response?.hotelPolicyText || staticData.hotelPolicyText}
            </Text>
          </View>
          <View style={this.styles.PolicyDetailsContainer}>
            {this.showHotelPolicy(hotelDetailList.hotelPolicy)}
          </View>
        </ScrollView>
      </View>
    );
  }

  componentDidMount() {
    const trackPageName = isIntlHotelBooking(this.props.response?.lobCode)
      ? 'mob:customer support:Hotel International:HotelPolicy'
      : 'mob:customer support:Hotel Domestic:HotelPolicy';
    HotelBookingTrackingHelper.trackLoadEvent(trackPageName, this.props.response);
  }

  onBackPress = () => {
    Actions.pop();
  };

  showHotelPolicy(hotelPolicy) {
    const views = [];
    const hotelPolicyList = hotelPolicy.split('#');
    hotelPolicyList.forEach((hotelPolicyObject) => {
      this.createHotelPolicyView(views, hotelPolicyObject);
    });
    return views;
  }

  createHotelPolicyView(views, hotelPolicyObject) {
    views.push(<View style={[this.styles.mar15Bt, this.styles.flexRow]}>
      <View style={this.styles.ItemDisc} />
      <HTMLView
        value={`<div>${hotelPolicyObject}</div>`}
      />
    </View>);
  }

  onBackPressed = () => {
    Actions.pop();
  };
}

ViewHotelPolicy.propTypes = {
  response: PropTypes.object.isRequired
};
export default ViewHotelPolicy;
