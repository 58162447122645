import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
import { StyleSheet } from 'react-native';

const getStyles = (fonts, psTheme) => {
  const {color} = psTheme;
  return StyleSheet.create({
    tgsTopCard: {
      backgroundColor: color.limeGreen,
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
      paddingVertical: 20,
    },
    cardStatus: {
      alignItems: 'center',
      paddingHorizontal: 16,
      marginBottom: 20,
    },
    title: {
      // textAlign: 'center',
      color: color.green8,
      textTransform: 'uppercase',
      ...fonts.blackFontFamily,
      fontSize: fonts.fontSize.font16,
    },
    info: {
      color: color.green,
      ...fonts.regularFontFamily,
      fontSize: fonts.fontSize.font14,
    },
    tgsWhiteCardWrapper: {
      zIndex: 5
    },
    tgsWhiteCard: {
      ...getPlatformElevation(2),
      backgroundColor: color.white,
      padding: 12,
    },
    chartInfo: {
      backgroundColor: color.yellow3,
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      paddingVertical: 8,
      paddingHorizontal: 11,
      flexDirection: 'row',
      alignItems: 'center',
    },
    secureInfo: {
      backgroundColor: color.lighterBlue,
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
      paddingVertical: 8,
      paddingHorizontal: 11,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    secureInfoTxt: {
      color: color.vividBlue,
      fontSize: fonts.fontSize.font14,
      ...fonts.regularFontFamily,
    },
    guaranty: {
      backgroundColor: color.cruise,
    },
    guarantyText: {
      color: color.green,
    },
    guarantyTextIcon: {
      tintColor: color.green
    },
    secureGreen: {
      backgroundColor: color.cruise,
    },
    infoImg: {
      width: 14,
      height: 14,
    },
    freeCancellation: {
      width: 24,
      height: 24,
      marginRight: 3,
    },
    tripGuaranty: {
      width: 33,
      height: 22,
      marginRight: 3,
    },
    greentickStyle: {
      width: 16,
      height: 16,
      marginRight: 5
    },
    chartInfoTxt: {
      color: color.lightYello,
      fontSize: fonts.fontSize.font12,
      ...fonts.boldFontFamily,
    },

    copyIcon: {
      width: 9,
      height: 11,
      marginLeft: 6,
    },

    download: {
      width: 16,
      height: 16,
    },

    btnWrapper: {
      paddingHorizontal: 16,
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: 12,
    },

    buttonStyle: {
      borderRadius: 10,
      paddingHorizontal: 20,
      paddingVertical: 10,
      backgroundColor: color.white,
      width: '49%',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'center',
      ...getPlatformElevation(2),
    },
    buttonTxtStyle: {
      fontSize: fonts.fontSize.font12,
      ...fonts.boldFontFamily,
      textAlign: 'center',
      color: color.azure,
    },
    borderRadiusCard: {
      borderRadius: 8,
    },
    borderRadiusSubCard: {
      borderRadius: 16
    }
  });
}


export default getStyles;