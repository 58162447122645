import React from 'react';
import {Text, Image, TouchableOpacity, StyleSheet, View} from 'react-native';
import {fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import { ps_colors, themeColors } from '../../PostSalesStyles';
import {downLoadBtn, flightBtn, hotelBtn, carBtn, visaBtn, insuranceBtn} from '../HolidayBookingConstants';

const ButtonList = (props) => {
  let btnImg;
  switch (props.btnIcon) {
    case 'activitiesIcon':
    case 'downloadIcon':
      btnImg = downLoadBtn;
      break;
    case 'flightsIcon':
      btnImg = flightBtn;
      break;
    case 'hotelsIcon':
      btnImg = hotelBtn;
      break;
    case 'transfersIcon':
      btnImg = carBtn;
      break;
    case 'visaIcon':
      btnImg = visaBtn;
      break;
    case 'insuranceIcon':
      btnImg = insuranceBtn;
      break;
  }

  return (
    <TouchableOpacity style={styles.btnWrapper} activeOpacity={0.6} onPress={props.onPress}>
      <Image style={styles.btnIconStyle} source={btnImg} />
      <Text style={styles.btnTxtStyle}>{props.btnListTxt}</Text>
      {!!props.statusText && (
        <View style={styles.statusPill}>
          <Text style={styles.statusText}>{props.statusText}</Text>
        </View>
      )}
    </TouchableOpacity>
  );
};
const styles = StyleSheet.create({
  btnWrapper: {
    alignItems: 'center',
    flex: 1
  },
  btnIconStyle: {
    width: 36,
    height: 36
  },
  btnTxtStyle: {
    fontFamily: fonts.bold,
    lineHeight: 15,
    color: themeColors.primary,
    textAlign: 'center'
  },
  statusPill: {
    height: 14,
    paddingHorizontal: 5,
    borderRadius: 40,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop:10,
    backgroundColor:ps_colors.red
  },
  statusText: { color: ps_colors.white, fontSize: 8, fontFamily: fonts.bold },
});

export default ButtonList;
