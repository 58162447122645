import React from 'react';
import { View, Text, Image } from 'react-native';
import styles from './tripInfoCardCss';
import {isEmpty} from 'lodash';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {getButtonStyle} from "../../../../Common/commonUtil";
import {getActionButton, handleZcClickEvent} from "../../../utils/SelfDriveBookingDetailUtil";
import {TIME_ICON} from '../../../SelfDriveImageConstant';

const TripInfoCard = (props) => {
    return (
        <View style={[AtomicCss.marginBottom10, styles.cardBorder]}>
            <View style={[styles.infoCardWrapper]}>
                <View style={styles.headingIconWrapper}>
                    <Image style={styles.headingIconStyle} source={TIME_ICON} />
                </View>
                <View style={styles.infoCardContent}>
                    <Text style={[AtomicCss.defaultText, AtomicCss.blackFont, AtomicCss.font14, AtomicCss.marginBottom15]}>{props.card.header}</Text>
                    <Text style={[AtomicCss.regularFont, AtomicCss.font14, AtomicCss.defaultText, AtomicCss.lineHeight20,AtomicCss.marginBottom10]}>{props.card.subHeaders}</Text>
                    {fetchDescription(props.card.descriptions)}
                    {!isEmpty(props.card.actionList) &&
                    <View
                        style={!isEmpty(props.card.actionList) ? getButtonStyle(props.card.actionList) : styles.hideView}>
                        {!isEmpty(props.card) ? getActionButton(props.card, props.response, handleClick) : null}
                    </View>
                    }

                </View>
            </View>
        </View>
    )

     function fetchDescription (descriptions)  {
        let views = [];
        Object.keys(descriptions).map(key=>{
            views.push(
                <Text style={[AtomicCss.regularFont, AtomicCss.font14, AtomicCss.defaultText, AtomicCss.lineHeight20]}>{descriptions[key]}</Text>
            )
        })
        return views;
    }

    function handleClick (action)  {
        handleZcClickEvent(props.card, action, props.response, "Cancel Card", handleEndTrip).then(r => "");
    }

    function handleEndTrip (e)  {
        console.log('End Trip Called', e)
        props.handleEndTrip(e);
    }
}


export default TripInfoCard
