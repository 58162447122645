import React from 'react';
import { Text, View, TouchableOpacity, Image } from 'react-native';
import { getStaticData } from '../../../../../../../staticData/staticData';
import { useTheme } from '../../../../../../../theme/theme.context';
import createStyles from './styles';
import FooterLeftFilter from './FooterLeftFilter';
import { getFont } from '../../../../../../../PostSalesStyles';

const filterImg = require('@mmt/legacy-assets/src/filter.webp');

const FooterFilters = (props) => {
  const { psTheme } = useTheme();
  const rightFooterColor = { backgroundColor: psTheme.color.primary };
  const fonts = getFont(true);
  const styles = createStyles(psTheme, fonts);
  const { sortAndFilterText } = getStaticData();
  const _toggleQuckFltr = (currFltrObj) => {
    props.toggleFilters(props.currSort, props.currSortOrder, currFltrObj, -1);
  };
  return (
    <View style={styles.footer}>
      <View style={styles.footerLeft}>
        <FooterLeftFilter
          filterInfo={props.filterInfo}
          currFltrObj={props.currFltrObj}
          toggleFltr={_toggleQuckFltr}
        />

        <TouchableOpacity
          style={[rightFooterColor, styles.sortItem]}
          onPress={() =>
            props.toggleFilters(props.currSort, props.currSortOrder, props.currFltrObj)
          }
        >
          <Image style={styles.sortIcon} source={filterImg} />
          <Text style={[styles.sortLabel, styles.whiteText]}>{sortAndFilterText}</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default FooterFilters;
