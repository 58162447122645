import { View, TouchableOpacity } from 'react-native';
import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '../../theme/theme.context';
import createStyle from './radioButtonCss';

const RadioButton = ({ selected, onChange, radioOuterStyle, radioInnerStyle }) => {
  const { psTheme: theme } = useTheme();
  const styles = createStyle(theme);

  return (
    <TouchableOpacity
      style={[styles.defaultRadio, selected && styles.outerCircle, radioOuterStyle]}
      onPress={onChange}
    >
      {selected && <View style={[styles.innerCircle, radioInnerStyle]} />}
    </TouchableOpacity>
  );
};

export default RadioButton;

RadioButton.propTypes = {
  selected: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  radioOuterStyle: PropTypes.object,
  radioInnerStyle:PropTypes.object
};

RadioButton.defaultProps = {
  radioOuterStyle: {},
  radioInnerStyle:{}
}
