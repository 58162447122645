import React, { useState } from 'react';
import { View } from 'react-native';
import { TripViewCardProps} from './types';
import createStyles from './styles';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { useTheme } from '@mmt/post-sales/src/theme/theme.context';
import TVWidget from '@growth/tripview/src/components/molecules/TVWidget';
import { Brand } from '@growth/tripview/src/utils/BrandHolder';
import { LOBNAMES } from '@mmt/post-sales/src/PostSalesConstant';
import HotelBookingDetailsConstant from '../../HotelBookingDetailsConstant';
import { isMweb } from 'apps/post-sales/src/hotel/hotelUtils.js';

const TripViewCard: React.FC<TripViewCardProps> = (props) => {
  const { response, pageName, tripViewCardRef } = props;
  const { bookingID, isInternational } = response || {};
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme } = useTheme();
  const styles = createStyles(psTheme, fonts);

  const [showTripViewCard, setShowTripViewCard] = useState<boolean>(true);

  const { TRIP_VIEW_SRC, HOTEL_FUNNEL_STEP } = HotelBookingDetailsConstant;
  const tripViewPageContext = {
    lob: LOBNAMES.HOTEL.toLowerCase(),
    lob_category: isInternational ? 'ih' : 'dh',
    sub_lob: LOBNAMES.HOTEL.toLowerCase(),
    funnel_step: HOTEL_FUNNEL_STEP,
    page_name: 'cs:htl:details',
  };
  const isTripViewLoaded = (isDataAvailable: boolean) => {
    !isDataAvailable && setShowTripViewCard(false);
  }

  if(!showTripViewCard || isMweb()) return null;

  return (
    <View ref={tripViewCardRef} style={styles.tripViewWrapper}>
      <TVWidget 
        brand={Brand.MMT}
        src={TRIP_VIEW_SRC}
        bookingID={bookingID}
        pageName={pageName}
        pageContext={tripViewPageContext}
        onLoaded={isTripViewLoaded}
      />
    </View>
  );
}

export default TripViewCard;