import React from 'react';
import {View, Image} from 'react-native';
import styles from "./styles.js";
import {fonts,colors} from '@mmt/legacy-commons/Styles/globalStyles';
import HTMLView from 'react-native-htmlview';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';

export const htmlStyles = (color)=> ({
    span: {
        color: color,
        fontSize: 12,
        fontFamily: fonts.regular,
        lineHeight: 14
      },
    b: {
        fontFamily: fonts.bold
      },
    text: {
        color: colors.red
    }
});

const CashbackBanner = ({ iconUrl, cashbackText, backgroundColor, color }) => {
  return (
    <View style={[AtomicCss.flex1, AtomicCss.flexRow, AtomicCss.justifyCenter, AtomicCss.alignCenter, styles.cashbackBanner, {backgroundColor:backgroundColor}]}>
      <View style={styles.cashbackIcon}>
        <Image source={{uri:iconUrl}} style={styles.iconStyle}/>
      </View>
      <View style={styles.cashbackText}>
          <HTMLView
            value={cashbackText}
            stylesheet={color ? htmlStyles(color):''}
            >
            </HTMLView>
        </View>
        </View>
  );
};


export default CashbackBanner;