import React, { useState } from 'react';
import { LayoutAnimation, Platform, UIManager, View} from 'react-native';
import { BookingInfoDetailsProps } from '../../../../types';
import getStyles from './BookingInfoDetailsCss';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import  { MAX_CITY_LENGTH } from '../../../../CabBookingDetailsConstant';
import { truncateString } from '../../../../utils/CabBookingDetailUtil';
import CabBookingTrackingHelper from 'apps/post-sales/src/cab/CabBookingTrackingHelper';
import CollapsedBookingInfo from './CollapsedBookingInfo';
import ExpendedBookingInfo from './ExpendedBookingInfo';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

if (Platform.OS === 'android') {
  if (UIManager.setLayoutAnimationEnabledExperimental) {
    UIManager.setLayoutAnimationEnabledExperimental(true);
  }
}

const BookingInfoDetails = ({
  carBookingInfo,
  isExpanded = false,
  expandCarDetail,
  pageName,
}: BookingInfoDetailsProps) => {
  const { psTheme } = useTheme();
  const { color } = psTheme;
  const { fsStyle, ...fonts } = getFont();
  const styles = getStyles(color, fonts);
  const {
    newFromCity,
    fromCityDesc,
    dateTime,
    newToCity,
    toCityDesc,
    tripTypeText,
    isRoundTrip,
    returnDateTime,
    internationalPickupTimeText,
    isTimeUpdated,
    timeUpdatedText,
    cabReassignedText,
    rescheduleRequestedData
  } = carBookingInfo || {};

  const dest1Trunket = truncateString(fromCityDesc, MAX_CITY_LENGTH);
  const dest2Trunket = truncateString(toCityDesc, MAX_CITY_LENGTH);
  const [bookingInfo, setBookingInfo] = useState(isExpanded || !dest1Trunket || !dest2Trunket);

  const bookingInfoClick = (clickEventName: string) => {
    CabBookingTrackingHelper.trackClickEvent(pageName, clickEventName);
    expandCarDetail && expandCarDetail(!bookingInfo);
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    setBookingInfo(!bookingInfo);
  };

  return (
    <View style={styles.bookingInfoDetails}>
      {!bookingInfo && (
        <CollapsedBookingInfo
          tripTypeText={tripTypeText}
          dest1Trunket={dest1Trunket}
          dest2Trunket={dest2Trunket}
          isRoundTrip={isRoundTrip}
          dateTime={dateTime}
          returnDateTime={returnDateTime}
          internationalPickupTimeText={internationalPickupTimeText}
          bookingInfoClick={bookingInfoClick}
          isTimeUpdated={isTimeUpdated}
          timeUpdatedText={timeUpdatedText}
          cabReassignedText={cabReassignedText}
          rescheduleRequestedData={rescheduleRequestedData}
        />
      )}
      {bookingInfo && (
        <ExpendedBookingInfo
          tripTypeText={tripTypeText}
          fromCity={newFromCity}
          fromCityDesc={fromCityDesc}
          toCity={newToCity}
          toCityDesc={toCityDesc}
          isRoundTrip={isRoundTrip}
          dateTime={dateTime}
          returnDateTime={returnDateTime}
          bookingInfoClick={bookingInfoClick}
        />
      )}
    </View>
  );
};

export default BookingInfoDetails;
