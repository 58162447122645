import React from 'react';
import {ScrollView, View} from 'react-native';
import PropTypes from 'prop-types';
import Actions from '../../../navigation/postSalesNavigation';
import getStyles from './HotelMamiCss';
import GuestCard from './GuestCard';
import HotelStayDatesCard from './HotelStayDatesCard';
import HotelRoomDetailsCard from './HotelRoomDetailsCard';
import HotelBookingDetailsConstant from '../HotelBookingDetailsConstant';
import {getCardObject, isIntlHotelBooking} from '../utils/HotelBookingDetailUtil';
import CommonHeader from '../components/CommonHeader';
import HotelBookingTrackingHelper from '../../HotelBookingTrackingHelper';
import { WHITE_LEFT_ARROW_ICON } from '../../HotelImageConstants';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

class HotelModificationStandalone extends React.Component {
  static navigationOptions = {
    header: null
  };

  constructor(props) {
    super(props);
    this.fonts = getFont(true);
    this.styles = getStyles(this.fonts);
    this.trackPageName = isIntlHotelBooking(props.response?.lobCode)
      ? 'mob:customer support:Hotel International:ModificationStandalone'
      : 'mob:customer support:Hotel Domestic:ModificationStandalone';
  }
  render() {
    const hotelStayCardObj = getCardObject(this.props.response, HotelBookingDetailsConstant.HOTEL_STAY_DATES_CARD);
    const guestCardObj = getCardObject(this.props.response, HotelBookingDetailsConstant.HOTEL_GUEST_NAMES_CARD);
    const roomDetailsCard = getCardObject(this.props.response, HotelBookingDetailsConstant.HOTEL_ROOM_DETAIL_CARD);
    return (
      <View style={this.styles.transperantBgrnd}>
        <CommonHeader
          headerText="Modify"
          imgSrc={WHITE_LEFT_ARROW_ICON}
          backPressHandler={this.onBackPress}
        />
        <ScrollView>
          <View style={this.styles.headerPaddingbtm}>
            {hotelStayCardObj && <HotelStayDatesCard
              pageName={this.trackPageName}
              response={this.props.response}
              card={getCardObject(this.props.response, HotelBookingDetailsConstant.HOTEL_STAY_DATES_CARD)}
            />}
            {guestCardObj && <GuestCard
              pageName={this.trackPageName}
              response={this.props.response}
              card={getCardObject(this.props.response, HotelBookingDetailsConstant.HOTEL_GUEST_NAMES_CARD)}
            />}
            {roomDetailsCard && <HotelRoomDetailsCard
              pageName={this.trackPageName}
              response={this.props.response}
              card={getCardObject(this.props.response, HotelBookingDetailsConstant.HOTEL_ROOM_DETAIL_CARD)}
            />}
          </View>
        </ScrollView>
      </View>
    );
  }
  componentDidMount() {
    HotelBookingTrackingHelper.trackLoadEvent(this.trackPageName, this.props.response);
  }
  onBackPress = () => {
    Actions.pop();
  }
}
HotelModificationStandalone.propTypes = {
  response: PropTypes.object.isRequired
};
export default HotelModificationStandalone;
