import React, { useCallback, useRef } from 'react';
import { View, Text, TouchableOpacity, Image, Platform } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {
  backArrow,
  crossIcon,
  backArrowIOS,
  downloadIcon,
  downloadGrey,
} from '../../../../CabImageConstants';
import { getFont } from '../../../../../PostSalesStyles';
import { useTheme } from '../../../../../theme/theme.context';
import createStyles from './HeaderCss';
import { HeaderProps } from '../../../../types';
import { getThrottleParams } from '../../../../utils/CabBookingDetailUtil';
import CabBookingDetailsConstant from '../../../../CabBookingDetailsConstant';

const Header: React.FC<HeaderProps> = (props) => {
  const fonts = getFont();
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);
  const { isCompleted, topStatusStyleProps, handleClickEvent, isCrossIcon, showDownloadIcon } =
    props;
  const { headerBg, crossIconColor } = topStatusStyleProps || {};
  const { throttleInterval } = getThrottleParams({
    actionFamily: CabBookingDetailsConstant.ACTION_DOWNLOAD_INVOICE,
  });
  const lastClickTimeRef = useRef(0);
  const throttledClickHandler = useCallback(() => {
    const currentTime = Date.now();
    if (currentTime - lastClickTimeRef.current >= throttleInterval) {
      lastClickTimeRef.current = currentTime;
      handleClickEvent();
    }
  }, [handleClickEvent, throttleInterval]);

  return (
    <View
      style={[
        headerBg,
        AtomicCss.flexRow,
        AtomicCss.spaceBetween,
        AtomicCss.marginTop12,
        AtomicCss.marginRight5,
        AtomicCss.marginLeft12,
      ]}
    >
      <TouchableOpacity onPress={props.goBack} style={styles.backButton}>
        {isCrossIcon ? (
          <Image style={[styles.crossIconStyle, crossIconColor]} source={crossIcon} />
        ) : (
          <Image
            style={Platform.OS === 'ios' ? [styles.backArrowIos] : [styles.backArrow]}
            source={Platform.OS === 'ios' ? backArrowIOS : backArrow}
          />
        )}
      </TouchableOpacity>
      {showDownloadIcon && (
        <TouchableOpacity onPress={throttledClickHandler}>
          <View style={AtomicCss.flexRow}>
            <Text style={[styles.eTicketText, isCompleted ? styles.greyColor : {}]}>{CabBookingDetailsConstant.eTicketText}</Text>
            <Image style={styles.downloadIcon} source={isCompleted ? downloadGrey : downloadIcon} />
          </View>
        </TouchableOpacity>
      )}
    </View>
  );
};

export default Header;
