import React,{useContext, useEffect, useState} from 'react';
import { View, Text, Image, TouchableOpacity} from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getFont } from '../../PostSalesStyles';
import useApi from '../useApi';
import { CSAT_FEEDBACK_GET_DETAILS } from '../../utils/NetworkUtils';
import { isEmpty } from 'lodash';
import { getStaticData } from '../../staticData/staticData';
import { openWebView } from '../../utils/PostSaleUtil';
import { CROSS_ICON, WHITE_CROSS_ICON } from '../../flights/FlightImageConstants';
import CSATFeedbackHelper from './tracking';
import CsatFeedbackCardConst from './csatFeedbackConst';
import { ThemeContext } from '../../theme/theme.context';
import createStyle from './FeedbackBottomStickyCss';

const fonts = getFont(true);

const QueryResolveCard = ({ queryFeed, bookingId, setIsFeedbackShared, styles, pageName }) => {
    const [active, setActive] = useState(-1);
    const handleClick = (index, redirectUrl, responseType) => {
        const isUserHappy = responseType ? CsatFeedbackCardConst.YES : CsatFeedbackCardConst.NO;
        CSATFeedbackHelper.trackClickEvent(pageName, bookingId, isUserHappy, CsatFeedbackCardConst.NUDGE_CARD);
        setActive(index);
        const { yourFeedbackIsValuable } = getStaticData(true, true);
        openWebView(
            yourFeedbackIsValuable,
            WHITE_CROSS_ICON,
            redirectUrl,
            null,
            bookingId,
            '',
            null,
            true,
        );
        setIsFeedbackShared(true);
    }
    return (
        <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween]}>
            {queryFeed.map((item,index) => {
                const {ctaText: label, redirectUrl, selectedImageUrl, unselectedImageUrl, responseType}  = item || {};
                const activeSuffix =  index === active ? 'Active' : '';
                const iconSource =  index === active ? selectedImageUrl : unselectedImageUrl;
                return (
                    <TouchableOpacity 
                        onPress={() => handleClick(index, redirectUrl, responseType)} 
                        style={[
                            styles.queryBtn, 
                            styles[`queryBtn${activeSuffix}`],
                            AtomicCss.paddingHz16,
                            AtomicCss.paddingVertical8,
                            AtomicCss.flexRow,
                            AtomicCss.alignCenter
                        ]}
                    >
                        <Image source={{uri:iconSource}} style={[styles.iconStyle, AtomicCss.marginRight12]} />
                        <View style={AtomicCss.flex1}>
                            <Text style={[
                                activeSuffix === 'Active' ? fonts.boldFontFamily : null, 
                                styles.resolveTxt, 
                                styles[`resolveTxt${activeSuffix}`]]}
                            >
                                {label}
                            </Text>
                        </View>
                    </TouchableOpacity>
                )
            })}
        </View>
    )
}

const FeedbackBottomSticky = ({ bookingId, emailid, mobile, pageName, setIsFeedbackShared, showPopupReason}) => {
    const [ , csatCardRes, csatCardApi] = useApi(`${CSAT_FEEDBACK_GET_DETAILS}/${bookingId}`);
    const { psTheme } = useContext(ThemeContext);
    const cardType = CsatFeedbackCardConst.NUDGE_CARD; 
    useEffect(() => {
        CSATFeedbackHelper.trackLoadEvent(pageName, bookingId, cardType);
        const body = {
            userEmailId: emailid,
            userMobileNo: mobile,
            bookingId
        };
        csatCardApi.httpPost({ bookingId, uniqueId: 'CSAT_FEEDBACK_CARD', body });
    }, [bookingId, emailid, mobile]);

    useEffect(() => {
        if(csatCardRes && csatCardRes.error) {
            CSATFeedbackHelper.trackErrorEvent(pageName, bookingId, cardType);
        }
    }, [csatCardRes])

    const { success, description } = csatCardRes || {};
    const { ctaList, text } = description || {};

    useEffect(() => {
        if (success && !!text && !isEmpty(ctaList) && !!showPopupReason) {
            CSATFeedbackHelper.trackSeenEvent(pageName, bookingId, cardType, showPopupReason);
        }
    }, [success, ctaList, text, showPopupReason]);

    if (!success || !text || isEmpty(ctaList) || !showPopupReason) return null;

    const handleClose = () => {
        CSATFeedbackHelper.trackClickEvent(pageName, bookingId, CsatFeedbackCardConst.DISMISSED, cardType);
        setIsFeedbackShared(true);
    }

    const styles = createStyle(psTheme);

    return (
        <>
            <View style={styles.bottomStickyWrapper}>
                <View style={[
                    styles.topHeadWithCrossIcon,
                    AtomicCss.flexRow,
                    AtomicCss.paddingHz8,
                    AtomicCss.paddingVertical5,
                ]}
                >
                    <TouchableOpacity onPress={handleClose}>
                        <Image 
                            source={CROSS_ICON} 
                            style={[
                                styles.closeIcon,
                                AtomicCss.marginRight16,
                                AtomicCss.marginTop4,
                                AtomicCss.marginLeft5
                            ]} 
                        />
                    </TouchableOpacity>
                    <Text style={[AtomicCss.font12, AtomicCss.whiteText, fonts.blackFontFamily]}>{text}</Text>
                </View>
                <View style={styles.queryBotWrapper}>
                    <View style={[
                        styles.queryBotResWrapper,
                        AtomicCss.paddingHz24,
                        AtomicCss.paddingVertical14,
                        AtomicCss.whiteBg
                    ]}
                    >
                        <QueryResolveCard queryFeed={ctaList} bookingId={bookingId} setIsFeedbackShared={setIsFeedbackShared} styles={styles} pageName={pageName}/>
                    </View>
                </View>
            </View>
        </>
    )
}
export default FeedbackBottomSticky;