import React from 'react';
import {
  View,
  Text,
  Image,
} from 'react-native';
import isEmpty from 'lodash/isEmpty';
import styles from '../FlightDtlsCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import FlightConst from '../../../FlightBookingDetailsConstant';

import {
  ONEWAY_ARROW_ICON,
} from '../../../../../FlightImageConstants';
import { getStaticData } from '../../../../../../staticData/staticData';
import {  imageTransformY } from '../../../../../../utils/PostSaleUtil';
import { getCitiesHeading } from '../../../utils/flightItinaryUtil';

import { getFont } from '../../../../../../PostSalesStyles';


const HeaderComponent =({ itinaryDetails})=> {
    const { journeyType ,segmentGroupIntent, stopOvers, segmentDetails, flightSegmentModificationList} = itinaryDetails;
    const { fsStyle, ...fonts } = getFont(true);
    const firstLastCity = (index, len) => {
        if (index === 0 || index === len - 1) {
          return [styles.firstLstCityTxt, fonts.boldFontFamily, fsStyle.font20];
        }
        return '';
      };

    const { multicityFlightsText } = getStaticData();
    if (journeyType === FlightConst.MULTI_CITY) {
      return(
        <View style={[AtomicCss.flexRow, AtomicCss.marginBottom5]}>
          <Text style={[styles.cityName, fonts.boldFontFamily, fsStyle.font20]}>
            {multicityFlightsText}
          </Text>
        </View>
      );
    } else if (!isEmpty(flightSegmentModificationList) || !isEmpty(segmentGroupIntent)) {
      const cityNameArr = getCitiesHeading(flightSegmentModificationList);
      const cityName = !isEmpty(cityNameArr) ? cityNameArr : segmentGroupIntent.split('-');
      if (!isEmpty(stopOvers)) {
        return(
          <View
            style={[
              AtomicCss.flexRow,
              AtomicCss.marginBottom5,
              AtomicCss.alignCenter,
              { flexWrap: 'wrap', width: '90%' },
            ]}
          >
            {cityName.map((item, index) => (
              <React.Fragment>
                <Text
                  style={[
                    fonts.regularFontFamily,
                    AtomicCss.blackText,
                    AtomicCss.font14,
                    firstLastCity(index, cityName.length),
                    item.style ? item.style : {}
                  ]}
                >
                  {item.name || item}
                </Text>
                {index !== cityName.length - 1 && (
                  <Image style={[styles.arrowStyle, imageTransformY]} source={ONEWAY_ARROW_ICON} />
                )}
              </React.Fragment>
            ))}
          </View>
        );
      } else {
        return(
          <View style={[AtomicCss.flexRow, AtomicCss.marginBottom5]}>
            <Text style={[styles.cityName, fonts.boldFontFamily, fsStyle.font20, cityName[0].style ? cityName[0].style : {}]}>{cityName[0].name ? cityName[0].name : cityName[0]}</Text>
            <Image style={[styles.arrowStyle, imageTransformY]} source={ONEWAY_ARROW_ICON} />
            <Text style={[styles.cityName, fonts.boldFontFamily, fsStyle.font20, cityName[1].style ? cityName[1].style : {}]}>{cityName[1].name ? cityName[1].name : cityName[1]}</Text>
          </View>
        );
      }
    } else {
      const cityFrom = segmentDetails[0].originCity;
      const cityTo = segmentDetails[segmentDetails.length - 1].destinationCity;
      return(
        <View style={[AtomicCss.flexRow, AtomicCss.marginBottom5]}>
          <Text style={[styles.cityName, fonts.boldFontFamily, fsStyle.font20]}>{cityFrom}</Text>
          <Image style={[styles.arrowStyle, imageTransformY]} source={ONEWAY_ARROW_ICON} />
          <Text style={[styles.cityName, fonts.boldFontFamily, fsStyle.font20]}>{cityTo}</Text>
        </View>
      )
    }
  }

  export default HeaderComponent;
