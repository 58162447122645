import {trackOmniture} from '@mmt/legacy-commons/Common/utils/OmnitureTrackerUtils';
import {findDaysBetweenDates} from './Util/VisaUtil';
import VisaBookingEventsLogger from './VisaBookingEventsLogger';

const PIPE = '|';
const  SPACE_STRING = " ";

class VisaBookingTrackingHelper {
  trackClickEvent = (pageName, visaResponse, clickEvent) => {
    VisaBookingEventsLogger.clickEvent(pageName, clickEvent);
    let params = {};
    params = this.getBasicOminitureVariables(pageName, visaResponse);
    params.m_c1 = params.m_c54 = clickEvent;
    trackOmniture(pageName, params);
  };

    trackLoadEvent = (pageName, visaResponse) => {
        VisaBookingEventsLogger.loadEvent(pageName,visaResponse);
        let params = {};
        params = this.getBasicOminitureVariables(pageName, visaResponse);
        console.log(params);
        trackOmniture(pageName, params);
    };

    getBasicOminitureVariables(pageName, visaResponse){
        const params = {};
        if (visaResponse) {
            params.m_v2 = visaResponse.visaBookingInfo.visaDetailList[0].destCountry;
            params.m_v4 = visaResponse.visaBookingInfo.visaDetailList[0].dateOfEntry;
            params.m_v5 = visaResponse.visaBookingInfo.visaDetailList[0].dateOfExit;
            params.m_v6 = findDaysBetweenDates(new Date().getTime(), visaResponse.visaBookingInfo.visaDetailList[0].dateOfEntry) - findDaysBetweenDates(new Date().getTime(), visaResponse.visaBookingInfo.visaDetailList[0].dateOfExit);
            params.m_v7 = findDaysBetweenDates(new Date().getTime(), visaResponse.bookingDateTime);
            let paxinfo = visaResponse.passengerList.length.toString() + PIPE;
            visaResponse.passengerList.forEach((passenger) => {
                paxinfo = paxinfo + passenger.age.toString() + SPACE_STRING;
            });
            params.m_v11 = paxinfo;
            params.m_v16 = visaResponse.bookingId;
            params.m_v24 = visaResponse.lobName;
            params.m_v91 = visaResponse.visaBookingInfo.visaDetailList[0].visaDescription + PIPE + visaResponse.pricingSummary &&  visaResponse.pricingSummary.pricingSummaryDetail && visaResponse.pricingSummary.pricingSummaryDetail.sellingPrice.toString();
            params.m_v95 = visaResponse.pricingSummary &&  visaResponse.pricingSummary.pricingSummaryDetail && visaResponse.pricingSummary.pricingSummaryDetail.sellingPrice;
            params.m_c23 = 'My_Trips';
        }
        params.m_v15 = pageName;
        return params;
    }
}

export default new VisaBookingTrackingHelper();