import React from 'react';
import { View } from 'react-native';
import { Shimmer, PlaceholderContainerWrapper } from '../../ShimmerV2';
import styles from '../indexCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';

const LoadingCard = () => (
  <View style={styles.body}>
    <PlaceholderContainerWrapper>
      <View style={styles.refundCard}>
        <Shimmer height={12} width={100} marginBottom={30} />
        <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween]}>
          <Shimmer height={12} width={100} marginBottom={13} />
          <Shimmer height={12} width={100} marginBottom={13} />
        </View>
        <Shimmer height={12} width={50} />
        <View style={styles.detailCard}>
          <View style={AtomicCss.flexRow}>
            <Shimmer height={24} width={24} marginRight={12} marginTop={18} marginLeft={16} style={{ borderRadius: 4 }} />
            <View>
              <Shimmer height={12} width={190} marginTop={18} marginBottom={11} />
              <Shimmer height={12} width={80} marginBottom={20} />
            </View>
          </View>
          <View style={styles.shimmerContainer} />
          <View style={AtomicCss.flexRow}>
            <Shimmer height={24} width={24} marginRight={12} marginTop={18} marginBottom={16} marginLeft={16} style={{ borderRadius: 4 }} />
            <Shimmer height={12} width={160} marginTop={22} marginBottom={11} />
          </View>
          <View style={styles.shimmerBody} />
          <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween]}>
            <Shimmer height={12} width={120} marginTop={20} marginLeft={16} />
            <Shimmer height={12} width={62} marginTop={20} marginRight={16} />
          </View>
          <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween]}>
            <Shimmer height={12} width={65} marginTop={20} marginLeft={16} />
            <Shimmer height={12} width={43} marginTop={20} marginRight={16} />
          </View>
          <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween]}>
            <Shimmer height={12} width={65} marginTop={20} marginLeft={16} />
            <Shimmer height={12} width={43} marginTop={20} marginRight={16} />
          </View>
          <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween]}>
            <Shimmer height={12} width={65} marginTop={20} marginLeft={16} />
            <Shimmer height={12} width={43} marginTop={20} marginRight={16} />
          </View>
          <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween]}>
            <Shimmer height={12} width={65} marginTop={20} marginBottom={30} marginLeft={16} />
            <Shimmer height={12} width={43} marginTop={20} marginBottom={30} marginRight={16} />
          </View>
          <View style={styles.shimmerBody} />
          <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween]}>
            <Shimmer height={12} width={120} marginTop={20} marginBottom={20} marginLeft={16} />
            <Shimmer height={12} width={62} marginTop={20} marginBottom={20} marginRight={16} />
          </View>
        </View>
      </View>
    </PlaceholderContainerWrapper>
  </View>
);

export default LoadingCard;