import React from 'react';
import { View, Text, Image } from 'react-native';
import getStyles from './TrackerCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import isEmpty from 'lodash/isEmpty'
import FlexiCancelPolicy from './FlexiCancelPolicy';
import { CANCELLATION_POLICY_TRACKER_TYPES } from '../../HotelBookingDetailsConstant';
import { CALENDAR_CANCELLATION_ICON } from '../../../HotelImageConstants';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const Tracker = (props) => {
  const { fsStyle, ...fonts } = getFont(true);
  const styles = getStyles(fonts);
  const { item, index, isLastItem, flexiCancellationData } = props
  let suffixStyle = CANCELLATION_POLICY_TRACKER_TYPES[item.status];
  if (item.isBnplInfo) {
    suffixStyle = 'Credit'
  }
  const lastItemStyle = isLastItem ? 'lastItemStyle' : '';

  return (
    <View style={[styles.trackerDtls, styles[`trackerDtls${suffixStyle}`], styles[lastItemStyle]]}>
      <View style={styles.trackerInnerDtls}>
        <View style={[styles.countWrapper, styles[`countWrapper${suffixStyle}`]]}>
          {!!item.icon ? 
            <View style={[styles.iconWrapper, styles[`iconWrapper${suffixStyle}`]]}>
              <Image style={styles.tickIcon} source={{uri: item.icon}} /> 
            </View>
            :
            <View style={[styles.circle, styles[`circle${suffixStyle}`]]}></View>
          }
        </View>
        <View style={[AtomicCss.flex1, AtomicCss.marginRight10, AtomicCss.marginBottom16]}>
          {!!item.otherInfo &&
            <View style={styles.expiredWrapper}>
              <Image source={CALENDAR_CANCELLATION_ICON} style={styles.calIconStyle} />
              <Text style={styles.dateInfo}>{item.otherInfo}</Text>
            </View>
          }
          {!!item.title && <Text style={[styles.trackerStatus, styles[`trackerStatus${suffixStyle}`]]}>
            {item.title}
          </Text>}
          {!!item.desc &&
            <Text style={styles.trackerStatusTime}>{item.desc} </Text>
          }
          {item.showFlexi && !isEmpty(flexiCancellationData) ? 
            <View style={AtomicCss.marginTop10}>
              <FlexiCancelPolicy flexiDetails={flexiCancellationData} />
            </View> 
          :
            <>
          {!!item.progressInfo &&
            <View style={[styles.statusWrapper, styles[`statusWrapper${suffixStyle}`]]}>
              {!!item.progressTitle &&
                <Text style={[styles.trackerStatusHead, styles[`trackerStatusHead${suffixStyle}`]]}>{item.progressTitle}</Text>
              }
              <Text style={[fsStyle.font14, AtomicCss.lineHeight22, AtomicCss.defaultText]}>
                {item.progressInfo}
              </Text>
            </View>
          }
            </>
          }
        </View> 
      </View>
    </View>
  )
}

export default Tracker;