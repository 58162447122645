import { PixelRatio, StyleSheet, Dimensions } from 'react-native';
import { themeColors } from '../../../PostSalesStyles';
import { colors, fontSizes } from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';

const summaryCardStyle = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    marginVertical: 4,
    marginHorizontal: 0,
  },
  vaccinationContainer: {
    backgroundColor: colors.white,
    marginVertical: 2,
    marginHorizontal: 0,
  },
  mainContent: {
    flexDirection: 'row',
    flex: 1,
  },
  iconContainer: {
    width: 32,
    height: 32,
    margin: 16,
    alignItems: 'center',
    justifyContent: 'center',
  },
  flightIconContainer: {
    width: 30,
    height: 30,
    margin: 16,
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconContainerFixed: {
    width: 32,
    height: 32,
    margin: 16,
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
  },
  staticIcon: {
    width: 24,
    height: 24,
  },
  fixedIcon: {
    width: 20,
    height: 26,
  },
  dynamicIcon: {
    width: 30,
    height: 18,
    justifyContent: 'center',
    alignItems: 'center',
  },
  descriptionSection: {
    flex: 1,
    marginVertical: 16,
    flexDirection: 'column',
  },
  journeyTextContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginRight: 36,
  },
  statusConfirmed: {
    color: colors.green,
    textAlign: 'left',
    marginTop: 8,
  },
  statusCancelled: {
    color: '#cf8100',
    textAlign: 'left',
    marginTop: 8,
  },
  statusFailed: {
    color: '#eb2026',
    textAlign: 'left',
    marginTop: 8,
  },
  statusCompleted: {
    color: '#747474',
    textAlign: 'left',
    marginTop: 8,
  },
  statusAwaited: {
    color: '#de8a00',
    marginTop: 8,
  },
  statusPending: {
    color: '#CF8100',
    textAlign: 'left',
    marginTop: 8,
  },
  title: {
    color: colors.black,
    textAlign: 'left',
  },
  vaccinationTitle: {
    color: colors.black,
  },
  journeyDirectionIcon: {
    width: 10,
    height: 8,
    marginTop: 6, // match title text baseline
    marginHorizontal: 12,
  },
  roundTripJourneyDirectionIcon: {
    width: 10,
    height: 11,
    marginTop: 6, // match title text baseline
    marginHorizontal: 12,
  },
  coachInfoText: {
    marginTop: 4,
    marginRight: 36,
    textAlign: 'left',
    color: colors.defaultTextColor,
  },
  selfdriveInfoText: {
    marginTop: 4,
    marginRight: 36,
    color: colors.defaultTextColor,
  },
  paxRoomInfoContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  paxRoomInfoDivider: {
    marginTop: 6,
    marginHorizontal: 4,
    width: 1,
    height: 12,
    backgroundColor: colors.darkGrey,
  },
  paxInfoContainer: {
    marginTop: 6,
    alignItems: 'center',
    flexDirection: 'row',
  },
  paxInfoImageContainer: {
    width: 12,
    height: 12,
    alignItems: 'center',
    justifyContent: 'center',
  },
  paxInfoImage: {
    width: 8,
    height: 10,
  },
  roomInfoImage: {
    width: 12,
    height: 12,
  },
  paxInfoText: {
    marginLeft: 4,
    color: colors.darkGrey,
  },
  arrowContainer: {
    width: 24,
    height: 24,
    margin: 12,
    marginTop: 16,
    marginLeft: 4,
    alignItems: 'center',
    justifyContent: 'center',
  },
  arrowIcon: {
    width: 7,
    height: 12,
  },
  rightArrowContainer: {
    marginRight: 16,
    marginTop: 40,
  },
  rightArrowIcon: {
    width: 9,
    height: 13,
    tintColor: themeColors.rightArrowTintColor,
  },
  actionsContainer: {
    borderWidth: PixelRatio.getPixelSizeForLayoutSize(StyleSheet.hairlineWidth),
    borderColor: '#eff3f8',
    flex: 1,
    flexDirection: 'row',
    paddingLeft: 64,
  },
  actionsDivider: {
    width: PixelRatio.getPixelSizeForLayoutSize(StyleSheet.hairlineWidth),
    backgroundColor: '#eff3f8',
  },
  actionsItem: {
    height: 48,
    flex: 1,
    justifyContent: 'center',
  },
  actionsItemText: {
    color: themeColors.linkTextColor,
  },
  safetyInfo: {
    backgroundColor: '#edfef4',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    padding: 10,
    marginHorizontal: 10,
  },
  goSafeIconStyle: {
    width: 26,
    height: 19,
    marginRight: 10,
  },
  otherInfoDtls: {
    paddingVertical: 10,
    borderTopColor: '#e5e5e5',
    borderTopWidth: 1,
    marginTop: 15,
    marginRight: -35,
  },
  otherInfoTxt: {
    color: '#747474',
    paddingRight: 20,
    flex: 1,
  },
  otherInfoIcon: {
    marginRight: 10,
    width: 21,
    height: 20,
  },
  lockFareStyle: {
    height: 25,
    width: 35,
  },
  vaccineInfoText: {
    marginTop: 4,
    marginRight: 50,
    color: colors.defaultTextColor,
  },
  btnWrap: {
    backgroundColor: colors.grayBg,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  iconImg: {
    resizeMode: 'cover',
  },
  popContent: {
    padding: 20,
    paddingTop: 30,
  },
  centeredView: {
    flex: 1,
    backgroundColor: colors.grey20,
  },
  modalView: {
    backgroundColor: colors.white,
    borderRadius: 4,
    width: Dimensions.get('screen').width - 40,
    maxWidth: 350,
    ...getPlatformElevation(3),
  },
  iconClose: {
    width: 20,
    height: 20,
  },
  closeWrap: {
    position: 'absolute',
    right: 0,
    top: 0,
    padding: 15,
  },
  bookingStatusStyle: {
    borderRadius: 14,
    paddingLeft: 4,
    paddingVertical: 4,
    paddingRight: 8,
    alignSelf: 'flex-start',
    marginBottom: 8,
  },
  clockIcon: {
    width: 15,
    height: 15,
    marginRight: 6,
  },
});

export const getNeedHelpStripStyle = (theme) => {
  return StyleSheet.create({
    needHelpCardWrapper: {
      backgroundColor: theme.color.lightPrimaryColor,
      paddingHorizontal: 16,
      paddingVertical: 13,
      borderRadius: 4,
      margin: 16,
      marginTop: 0,
      marginBottom: 8,
    },
    callIcon: {
      width: 12,
      height:  12,
      marginRight: 4,
      tintColor: theme.color.primary
    },
    actionLabel: {
      color: theme.color.linkTextColor
    }
  })
}

export default summaryCardStyle;
