import React, { useEffect } from 'react';
import HotelCrossSell from './hotelCrossSell';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import useApi from '../../useApi';
import { COMMON_HOTEL_CROSS_SELL_URL } from '../../../utils/NetworkUtils';
import { HotelCrossSellWrapperProps } from './hotelType';

const HotelCrossSellWrapper: React.FC<HotelCrossSellWrapperProps> = (props) => {
  const { bookingId, omnitureTrackHandler } = props;

  const [inProgress, response, api] = useApi(COMMON_HOTEL_CROSS_SELL_URL);

  const fetchCrossData = async () => {
    let payload = await GenericModule.getSkyWalkerRequestBody();
    if (Platform.OS === 'android') {
      payload = JSON.parse(payload.skywalkerRequest);
    }
    const body = {
      context: payload.context,
      user: payload.user,
      filters: {
        bookingId,
      },
    };
    api.httpPost({ bookingId, uniqueId: 'COMMON_CROSS_SELL', body });
  };

  useEffect(() => {
    fetchCrossData();
  }, []);

  if (inProgress || !response){
    return null;
  }
  return <HotelCrossSell hotelData={response} omnitureTrackHandler={omnitureTrackHandler} />;
};

export default React.memo(HotelCrossSellWrapper);
