import React, { useCallback, useState } from 'react';
import { getSectionCardListMap } from '../../utils/utils';
import { ViewState } from 'apps/post-sales/src/PostSalesConstant';
import PageLoader from 'apps/post-sales/src/Common/Cancellation/PageLoader';
import ErrorPage from '../../../../../v2/commonComponents/ErrorPage';
import { actionHandler } from '../../utils/actionHandler';
import FetchFlightData from '../../../../../v2/utils/fetchFlightData';
import RenderPage from '@core_app_common/RenderPage';
import {FLIGHT_ACTION_IDS, PAGE_NAME, PAGE_REQUESTED} from '../../../../../v2/commonConstants';
import { TrackingOmnitureLoadData } from '@mmt/post-sales/src/flights/v2/utils/trackingUtil';
import isEmpty from 'lodash/isEmpty';
import { getPageData } from '../../../../../v2/utils/commonUtils';
import SpinLoader from 'core-rn-ui-factory/src/components/SpinLoader';
interface RenderSellingPageProps {
  bookingId: string;
  onLoad: (data: TrackingOmnitureLoadData) => void;
  onClick: (actionId: string, actionData?: string) => void;
  onError: (error: string) => void;
  reload?: () => void;
  refreshPage: boolean;
  extraPropsData: object;
}
interface ErrorData {
  title: string;
  heading: string;
  descriptions: string[];
  imageUrl: string;
  actionList: Action[];
}

interface Action {
  actionTitle: string;
  actionId: string;
}

const RenderSellingPage: React.FC<RenderSellingPageProps> = ({
  bookingId,
  onLoad,
  onClick,
  onError,
  refreshPage,
  extraPropsData,
}) => {
  const { OPEN_FLIGHT_DETAIL } = FLIGHT_ACTION_IDS;
  const [errorData, setErrorData] = useState<ErrorData | null>(null);
  const [viewState, setViewState] = useState<string | null>();
  const [isApiLoading, setApiLoading] = useState(false);

  const fetchFlightBookingDetails = async () => {
    const flightResponse = await FetchFlightData.getData(bookingId, onError);
    return flightResponse;
  };

  const handleClick = useCallback(
    (actionId: string, actionData = {}) => {
      actionHandler({
        actionId,
        actionData,
        onLoad,
        onClick,
        onError,
        bookingId,
        setErrorData,
        setViewState,
        handleClick,
        refreshPage,
        setApiLoading,
        fetchFlightBookingDetails,
      });
    },
    [bookingId, refreshPage],
  );
  const renderPage = () => {
    if (viewState === ViewState.ERROR) {
      return (
        <ErrorPage
          handleClick={handleClick}
          goBack={() => handleClick(OPEN_FLIGHT_DETAIL)}
          data={!isEmpty(errorData) ? errorData : undefined}
        />
      );
    }
    return (
      <>
        {isApiLoading && <SpinLoader size={'large'}/>}
        <RenderPage
          actionCallback={handleClick}
          fetchFlightBookingDetails={fetchFlightBookingDetails}
          cardListMap={getSectionCardListMap()}
          pageData={getPageData({bookingId, pageRequested: PAGE_REQUESTED.SELLING, pageName: PAGE_NAME.SELLING})}
          extraPropsData={extraPropsData || {}}
        />
     
      </>
    );
  };
  if (viewState === ViewState.LOADING) { return <PageLoader />; }
  return renderPage();
};

export default RenderSellingPage;
