import React from 'react';
import {View, Text, Image, TouchableOpacity} from 'react-native';
import styles from './inclusionExclusionCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {EXCLUSION_ICON} from '../../../SelfDriveImageConstant'

export default class ExclusionTab extends React.Component {
  constructor() {
    super();
    this.state = {
      active: true
    };
  }

    handleClick = () => {
      this.setState({active: !this.state.active});
    }

    render() {
      return (


        <View style={styles.wrapper}>
          <TouchableOpacity
            onPress={this.handleClick}
            style={[AtomicCss.flexRow, AtomicCss.spaceBetween, styles.head]}
          >
            <Text
              style={[AtomicCss.blackText, AtomicCss.font14, AtomicCss.blackFont]}
            >{this.props.response.exclusionHeader}
            </Text>
          </TouchableOpacity>
          {this.state.active &&
            <View>
              {this.fetchExclusions(this.props.response.exclusions)}
            </View>
                }
        </View>
      );
    }

    fetchExclusions = function (exclusions) {
      const views = [];
      Object.keys(exclusions).map((key) => {
        views.push(<View key={key} style={[AtomicCss.marginBottom10, AtomicCss.flexRow, AtomicCss.alignCenter]}><Image source={EXCLUSION_ICON} style={styles.exclusion} /><Text style={[AtomicCss.greyText, AtomicCss.font14, AtomicCss.paddingLeft5]}>{exclusions[key]}</Text></View>);
      });
      return views;
    }
}

