import React from 'react';
import { GlobalMessageBoxProps } from './types';
import { useTheme } from '../../../../../theme/theme.context';
import { getFont } from '../../../../../PostSalesStyles';
import { createStyle } from './styles';
import { Linking, View, Text, TouchableOpacity, Image} from 'react-native';

const GlobalMessageBox: React.FC<GlobalMessageBoxProps> = (props: GlobalMessageBoxProps) => {
  const { message, icon, ctaText = '', redirectionUrl = '' } = props;
  if (!message) {
    return null;
  }
  const { psTheme: theme } = useTheme();
  const { ...fonts } = getFont(true);
  const { color } = theme;
  const styles = createStyle(fonts, color);
  const onClickHandler = () => {
    Linking.canOpenURL(redirectionUrl).then((supported) => {
      if (supported) {
        Linking.openURL(redirectionUrl);
      }
    }, (err) => console.error('An error occurred', err));
  };
  return (
    <View style={styles.container}>
      <Image style={styles.icon} source={{uri: icon}}/>
      <Text style={styles.text}>
        {message}{' '}
        {!!ctaText && <TouchableOpacity onPress={onClickHandler}>
          <Text style={styles.linkText}>{ctaText}</Text>
        </TouchableOpacity>}
      </Text>
    </View>
  );
};

export default GlobalMessageBox;