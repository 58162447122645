import React from 'react';
import { View } from 'react-native';
import isEmpty from 'lodash/isEmpty';
import Actions from '../../navigation/postSalesNavigation';
import { getCommonHeaders, REFUND_TRACKER_API } from '../../utils/NetworkUtils';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import RefundCard from './RefundCard';
import ClaimRaised from '../../flights/modules/details/components/ClaimRaised';
import RectFullButton from '../../hotel/details/ActionButton/RectFullButton';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import RefundTrackerWithClaim from '../RefundTrackerNew';
import { openCustomerFormInWebView } from '../../utils/PostSaleUtil';
import { REFUND_OOT_TRACKING } from '../RefundOutOfTATForm/constants';
import PostSalesBookingTrackingHelper from '../../PostSalesBookingTrackingHelper';
import { getStaticData } from '../../staticData/staticData';
import { LOBNAMES } from '../../PostSalesConstant';
import PostSalesBasePage from '../PostSalesBasePage';

class RefundTracker extends PostSalesBasePage {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      refundTrackerData: '',
    };
    this.navigation = Actions;
  }

  componentDidMount() {
    this.refundTracker();
  }

  async createPromise(bookingid, canRequest, headers) {
    const refundTrackerUri = `${REFUND_TRACKER_API + bookingid}/${canRequest}`;
    return new Promise((resolve, reject) =>
      fetch(refundTrackerUri, {
        method: 'GET',
        headers,
      }).then((response) => resolve(response.json())),
    );
  }

  getLobName = () => {
    if (this.props.isLostBookingId) {
      return LOBNAMES.POST_SALES;
    }
    return this.props.lobName;
  }

  refundTracker = async () => {
    const staticData = getStaticData();
    const { trainRefundStatusText } = staticData;
    const cancellationRequestList = [];
    const userInfo = await HotelBookingModule.getRequestHeader(
      this.props.bookingID,
      'REFUND_TRACKER',
    );
    const headers = await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo, {
      psLob: this.getLobName(),
    });
    for (let i = 0; i < this.props.cancelRequests.length; i += 1) {
      cancellationRequestList.push(
        this.createPromise(this.props.bookingId, this.props.cancelRequests[i], headers),
      );
    }
    Promise.all(cancellationRequestList).then((result) => {
      if (result.length === 0 || result[0].refundStatusList == null) {
        return;
      }

      const refundTrackerData = {
        heading: result.cardHeading || trainRefundStatusText,
        refundBreakupVersionData: {version:1},
        description: '', // Add description -- the amount should be +
        refundStatusList: [],
        requestNoList: [],
        lobCode: result[0].lobCode,
        totalRefundAmt: 0,
        trackerWithClaim: false,
        isRefundProcessed: true,
        refundReceivedOrNotDetailsList: [],
        tatBreachData: null,
      };
      for (let i = 0; i < result.length; i++) {
        if (result[i] && result[i].refundStatusList && result[i].refundStatusList[0]) {
          const skipTotalRefundCal = result[i].refundStatusList[0].skipTotalRefundCal;
          result[i].refundStatusList[0].isSpecialClaimTracker = result[i].specialClaim;
          result[i].refundStatusList[0].requestNo = result[i].requestNo;
          if (
            refundTrackerData.isRefundProcessed &&
            !result[i].refundStatusList[0].isRefundProcessed
          ) {
            refundTrackerData.isRefundProcessed = false;
          }
          refundTrackerData.totalRefundAmt +=
            !skipTotalRefundCal && result[i].refundDetails
              ? result[i].refundDetails.totalRefundAmount
              : 0;
          if (result[i].refundNotReceivedDetails) {
            result[i].refundStatusList[0].refundNotReceivedDetails =
              result[i].refundNotReceivedDetails;
          }
          refundTrackerData.trackerWithClaim =
            result[i].trackerWithClaim || refundTrackerData.trackerWithClaim;
          refundTrackerData.refundStatusList.push(result[i].refundStatusList[0]);
          refundTrackerData.requestNoList.push(result[i].requestNo);
          refundTrackerData.refundReceivedOrNotDetailsList.push(
            result[i].refundReceivedOrNotDetails,
          );
          if (result[i].refundBreakupVersion) {
            refundTrackerData.refundBreakupVersionData.version = result[i].refundBreakupVersion;
          }
          if (result[i].refundStatusList[0] && result[i].refundStatusList[0].actionList) {
            const viewRefundBreakup = result[i].refundStatusList[0].actionList.find(
              (action) => action.actionType === 'ViewRefundBreakup',
            );
            if (viewRefundBreakup) {
              refundTrackerData.refundBreakupVersionData.actionCta = viewRefundBreakup;
            }
          }
          if (!refundTrackerData.isTatBreached && result[i].isTatBreached) {
            refundTrackerData.isTatBreached = result[i].isTatBreached;
            refundTrackerData.tatBreachedText = result[i].tatBreachedText;
            refundTrackerData.tatBreachedActions = result[i].tatBreachedActions;
          }
        }
      }

      this.setState({
        loaded: true,
        refundTrackerData,
      });
    });
  };

  actionaHandler = (type, item, action) => {
    const { bookingResponse, bookingId, trackRefundEvent, pageName = REFUND_OOT_TRACKING.PAGES.DEFAULT, lobName } = this.props;
    trackRefundEvent && trackRefundEvent(type);
    switch (type) {
      case "ViewRefundDelayed":
        if(item.refundNotReceivedDetails) {
          PostSalesBookingTrackingHelper.trackClickEvent(pageName, REFUND_OOT_TRACKING.CLICK.CHECK_REFUND_INFO, bookingId);
          this.navigation.refundDelayInfo({
            data: item.refundNotReceivedDetails,
            bookingId,
            requestNo: item.requestNo,
            lobName: this.props.lobName,
            pageName,
          })
        }
        break;
      case 'ViewRefundBreakup':
        PostSalesBookingTrackingHelper.trackClickEvent(pageName, REFUND_OOT_TRACKING.CLICK.VIEW_REFUND_BREAKUP, bookingId);
        if (this.state.refundTrackerData.refundBreakupVersionData.version == 2) {
          if (lobName === LOBNAMES.RAIL) {
            this.navigation.railRefundBreakupV2({
              bookingId,
              apiPSLob: this.props.lobName,
              lobCode: bookingResponse ? bookingResponse.lobCode : '',
              trackRefundEvent: trackRefundEvent,
            });
          } else {
            this.navigation.refundBreakupV2({
              bookingId,
              apiPSLob: this.props.lobName,
              lobCode: bookingResponse ? bookingResponse.lobCode : '',
              trackRefundEvent: trackRefundEvent,
            });
          }
        } else {
          if (lobName === LOBNAMES.RAIL) {
            this.navigation.railRefundBreakupPage({
              bookingId,
              requestNo: item.requestNo,
              apiPSLob: this.props.lobName,
              lobCode: bookingResponse ? bookingResponse.lobCode : '',
              trackRefundEvent: trackRefundEvent,
              lobName
            });
          } else if (lobName === LOBNAMES.BUS) {
            this.navigation.busRefundBreakup({
              bookingId,
              requestNo: item.requestNo,
              apiPSLob: this.props.lobName,
              lobCode: bookingResponse ? bookingResponse.lobCode : '',
              trackRefundEvent: trackRefundEvent,
              lobName
            });
          } else {
            this.navigation.refundBreakup({
              bookingId,
              requestNo: item.requestNo,
              apiPSLob: this.props.lobName,
              lobCode: bookingResponse ? bookingResponse.lobCode : '',
              trackRefundEvent: trackRefundEvent,
              lobName
            });
          }
        }
        break;
      case 'connect_helpdesk':
        if (action && action.url) {
          openCustomerFormInWebView(action, bookingId, 'CONNECT_HELPDESK', this.navigation);
        }
        break;
      default:
        break;
    }
  };

  render() {
    const staticData = getStaticData();
    const { viewRefundCalculationText } = staticData;
    const { loaded, refundTrackerData } = this.state;
    const {customStyle, showIconImg, redirectLinkCustomStyle, allowViewMore} =  this.props
    const {
      showRefundCalculationBtn,
      bookingId,
      refundCalculationBtnClick,
      bookingResponse,
      trackRefundEvent,
      isRoundedCornerAndPadding,
    } = this.props;

    const views = [];
    if (loaded) {
      /* uncommented this because if any lob does n't require to render this component
        we can disable this flag(trackerWithClaim) from backend */

      if(refundTrackerData.trackerWithClaim) {
        return <RefundTrackerWithClaim
          showRefundCalculationBtn={showRefundCalculationBtn}
          bookingId={bookingId}
          refundCalculationBtnClick={refundCalculationBtnClick}
          bookingResponse={bookingResponse}
          refundTrackerData={refundTrackerData}
          refundTrackerReq={this.refundTracker}
          trackRefundEvent={trackRefundEvent}
          actionaHandler={this.actionaHandler}
          onRefundModeWebViewBackPress={this.props.onRefundModeWebViewBackPress}
          onRefundModeSubMessageLinkPress={this.props.onRefundModeSubMessageLinkPress}
          isRoundedCornerAndPadding={isRoundedCornerAndPadding}
          lobName={this.props.lobName}
          pageName={this.props.pageName}
          isCorporateBooking={!isEmpty(bookingResponse) ? bookingResponse.isCorporateBooking : false}
          cardStyle={this.props.cardStyle}
          customStyle={customStyle}
          showIconImg={showIconImg}
          redirectLinkCustomStyle={redirectLinkCustomStyle}
          allowViewMore={allowViewMore}
        />
      }
      if (refundTrackerData && !isEmpty(refundTrackerData.refundStatusList)) {
        this.fetchRefundDetails(views, refundTrackerData, viewRefundCalculationText);
      }
      return views;
    }
    return null;
  }

  fetchRefundDetails = (views, refundTrackerData, viewRefundCalculationText) =>
    refundTrackerData.refundStatusList.forEach((item, index) => {
      if (item) {
        if (item.specialClaimRaisedButNotProcessed) {
          views.push(<ClaimRaised key={index} cardText={item.specialClaimRefundMessage} title={this.props.claimRaisedTitle}/>);
        } else {
          views.push(
            <RefundCard
              key={index}
              {...item}
              marginZero={
                (this.props.showRefundCalculationBtn && !item.isSpecialClaimTracker) ||
                (this.props.card &&
                  (this.props.card.showWalletToBankUrl || this.props.renderRefundButton))
              }
              onRefundModeWebViewBackPress={this.props.onRefundModeWebViewBackPress}
              onRefundModeSubMessageLinkPress={this.props.onRefundModeSubMessageLinkPress}
              bookingId={this.props.bookingID}
              isRoundedCornerAndPadding={this.props.isRoundedCornerAndPadding}
              trackRefundEvent={this.props.trackRefundEvent}
              currency={this.props.bookingResponse.currency}
              canReqVsPenaltyMap = {this.props.bookingResponse?.canRequestNoVsPenaltyMap}
              isCfarSuccess = {this.props.bookingResponse?.isCfarSuccess}
            />,
          );
          if (item.actionList && item.actionList.length) {
            const actionView = [];
            item.actionList.forEach((action) => {
              if (
                action.actionType != 'ViewRefundBreakup' ||
                (action.actionType == 'ViewRefundBreakup' &&
                  refundTrackerData.refundBreakupVersionData.version == 1)
              ) {
                actionView.push(
                  <RectFullButton
                    action={{ actionLabeltext: action.actionText }}
                    clickHandler={() => this.actionaHandler(action.actionType, item, action)}
                  />,
                );
              }
            });
            actionView.length &&
              views.push(
                <View style={[{ backgroundColor: '#fff' }, AtomicCss.marginBottom10]}>
                  {actionView}
                </View>,
              );
          } else if (this.props.showRefundCalculationBtn && !item.isSpecialClaimTracker) {
            if (refundTrackerData.refundBreakupVersionData.version == 1) {
              views.push(
                <View style={AtomicCss.marginBottom10}>
                  <RectFullButton
                    action={{ actionLabeltext: this.props.buttonText || viewRefundCalculationText }}
                    clickHandler={() =>
                      this.props.refundCalculationBtnClick(refundTrackerData.requestNoList[index])
                    }
                  />
                </View>,
              );
            }
          }
          if (
            index == refundTrackerData.refundStatusList.length - 1 &&
            refundTrackerData.refundBreakupVersionData.version == 2 &&
            refundTrackerData.refundBreakupVersionData.actionCta
          ) {
            views.push(
              <RectFullButton
                action={{
                  actionLabeltext: refundTrackerData.refundBreakupVersionData.actionCta.actionText,
                }}
                clickHandler={this.actionaHandler.bind(
                  this,
                  refundTrackerData.refundBreakupVersionData.actionCta.actionType,
                  item,
                  refundTrackerData.refundBreakupVersionData.actionCta,
                )}
              />,
            );
          }
        }
        if (this.props.card && this.props.card.showWalletToBankUrl) {
          views.push(
            <RectFullButton
              action={{ actionLabeltext: this.props.card.heading }}
              clickHandler={this.callWalletToBank}
            />,
          );
        }
        if (this.props.card && this.props.renderRefundButton) {
          views.push(this.props.renderRefundButton(this.props.card));
        }
      }
    });
  callWalletToBank = () => {
    GenericModule.openDeepLink(this.props.card.walletToBankTransferDeepLink);
  };
}

export default RefundTracker;
