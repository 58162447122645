import React from 'react'
import { View, Text, Image, ScrollView } from 'react-native'
import ListCard from './ListCard'
import isEmpty from 'lodash/isEmpty';
import { OFFER_ICON } from '../../../../../Utils/tripGuaranteeImageConstants';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import createStyles from "./whyBookUsCss";
import { useTheme } from '../../../../../../theme/theme.context';
import { getFont } from '../../../../../../PostSalesStyles';
const WhyBookUs = ({ data }) => {
    const { heading, subHeading,subHeading2, reasons } = data || {};
    const fonts = getFont();
    const {psTheme : theme} = useTheme();
    const styles = createStyles(theme,fonts);
    return (
        <>
            <View style={[AtomicCss.flexRow, AtomicCss.marginBottom10]}>
                <View style={styles.iconWrapper}>
                    <Image style={styles.offerIconStyle} source={OFFER_ICON} />
                </View>
                <View style={AtomicCss.flex1}>
                    {!!heading && <Text style={[AtomicCss.font14, fonts.blackFontFamily, AtomicCss.blackText, AtomicCss.marginBottom3]}>
                        {heading}</Text>}
                    {!!subHeading && <Text style={[AtomicCss.font11, fonts.boldFontFamily, AtomicCss.blackText,AtomicCss.marginBottom5]}>{subHeading}</Text>}
                    {!!subHeading2 && <Text style={[AtomicCss.font11, fonts.boldFontFamily, AtomicCss.blackText,AtomicCss.marginBottom5]}>{subHeading2}</Text>}
                </View>
            </View>
            {!isEmpty(reasons) && <View style={[styles.detailsSection, AtomicCss.marginBottom26]}>
                <ScrollView showsHorizontalScrollIndicator={false} horizontal={true}>
                    {reasons.map((item, index) => {
                        return (
                            <ListCard
                                item={item}
                                index={index}
                            />
                        )
                    })}

                </ScrollView>
            </View>}
        </>
    )
}

export default WhyBookUs;