import React, { useState, useEffect } from 'react';
import { View, Text } from 'react-native';
import CabAddonList from './CabAddonList';
import AddonList from './AddonList';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getFont } from '../../../../../PostSalesStyles';
import { getCrossSellDataApi } from '../../data/api';
import isEmpty from 'lodash/isEmpty';
import { LOBNAMES, POST_SALES_TRACKING_EVENTS } from 'apps/post-sales/src/PostSalesConstant';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import createStyles from './indexCss';
import { addOnCardClickHandler } from '../../utils/cardUtil';
import FlightBookingTrackingHelper from '../../helper/FlightBookingTrackingHelper';
import PaxClaimDetails from './PaxClaimDetails'
import RectFullButton from '../../../../../Common/ActionButton/RectFullButton';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';

interface FlightCrossLobAddonCardProps {
  bookingId: string;
  childBookingIdList: object;
  flightDetailResponse: object;
  title: string;
  addOnNotPresentMessage: string;
  showVpgCard: boolean;
  visaGuaranteeDetails?: VisaGuaranteeDetails;
  openVpgInfoOverlay?: () => void;
}

interface VisaGuaranteeDetails {
  heading: string;
  description: string;
  vendorLogo: string;
  paxInsuranceClaimList?: object;
  claimUrl?: string;
  status: string;
  statusColor: string;
  actionId: string;
  callToAction: string;
  tmBookingId?: string;
  claimInfoPopupTriggerText?: string;
  claimPopupInfo?: object;
}

interface visaUIDetails {
  visaDetailsDeepLinkForApp: string;
  visaDetailsDeepLinkForWeb: string;
  headerMessage: string;
  subHeaderMessage: string;
  status: string;
  statusColor: string;
  actionId: string;
  iconForApp: string;
}

interface carUIDetails {
  statusColor: string;
  status: string;
  subHeaderMessage: string;
  subHeaderMessage2: string;
  fromCity: string;
  toCity: string;
  updatePickupDetailsDeepLinkForApp: string;
  updatePickupDetailsDeepLinkForWeb: string;
  callToAction: string;
  iconForApp: string;
}

interface childBookingData {
  bookingId: string;
  lobType: string;
  crossplatformLayoutDetail: {
    visaUIDetails: visaUIDetails;
    carUIDetails: carUIDetails;
  };
}

interface childBookingInfoResponse {
  error: object;
  childBookingDetailInfoList: childBookingData[];
}

const FlightCrossLobAddonCard: React.FC<FlightCrossLobAddonCardProps> = ({
  bookingId,
  childBookingIdList,
  flightDetailResponse,
  title,
  addOnNotPresentMessage,
  showVpgCard,
  visaGuaranteeDetails,
  openVpgInfoOverlay
}) => {
  const { fsStyle, ...fonts } = getFont(true);
  const [responseJson, setResponseJson] = useState<childBookingInfoResponse | null>();
  const [showMainContent, setShowMainContent] = useState<boolean>(false);
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme);

  useEffect(() => {
    const { airportServicesDetails } = flightDetailResponse as any;
    if (!isEmpty(childBookingIdList)){
      fetchJSONAsync();
    }
    else if (showVpgCard || airportServicesDetails){
      setShowMainContent(true);
    }
  }, []);

  const fetchJSONAsync = async () => {
    const childBookingInfoResponse = await getCrossSellDataApi(bookingId, childBookingIdList);
    if (!isEmpty(childBookingInfoResponse?.childBookingDetailInfoList)) {
      setResponseJson(childBookingInfoResponse);
    } else {
      setResponseJson(null);
    }
    setShowMainContent(true);
  };

  const trackClickEvent = (actionId?: String) => {
    FlightBookingTrackingHelper.trackClickEvent('Details', actionId, flightDetailResponse);
  };

  const openAddOnDeepLink = ({actionUrl, actionId}) => {
    !isEmpty(actionUrl) && GenericModule.openDeepLink(actionUrl);
    trackClickEvent && trackClickEvent(actionId);
  };

  const getAirportServicesCard = ()=>{
    const views: React.JSX.Element[] = [];
    const { airportServicesDetails } = flightDetailResponse as any;
    if (airportServicesDetails && Array.isArray(airportServicesDetails)){
      airportServicesDetails.forEach(({ header, iconUrl, subheaderList })=>{
        const subHeaderMessageTemplate = Array.isArray(subheaderList) ? subheaderList.map(({ subheaderText })=>`<span>${subheaderText}</span>`).join('<br>') : '';
        views.push(
           <AddonList
             headerMessage={header}
             subHeaderMessage={subHeaderMessageTemplate}
             iconForApp={iconUrl}
             loadTrackEvent={'Airport_zo_card_shown'}
             flightDetailResponse={flightDetailResponse}
        />
        );
      });
    }
    return views;
  };

  const getCrossLobCards = () => {
    const views: React.JSX.Element[] = [];
    responseJson?.childBookingDetailInfoList?.map((childBookingData: childBookingData) => {
      const { lobType, crossplatformLayoutDetail } = childBookingData || {};
      if (!isEmpty(crossplatformLayoutDetail)) {
        switch (lobType) {
          case LOBNAMES.VISA: {
            const { visaUIDetails } = crossplatformLayoutDetail || {};
            const { visaDetailsDeepLinkForApp, visaDetailsDeepLinkForWeb } = visaUIDetails || {};
            views.push(
              <AddonList
                trackClickEvent={trackClickEvent}
                deepLinkForApp={visaDetailsDeepLinkForApp}
                deepLinkForWeb={visaDetailsDeepLinkForWeb}
                {...visaUIDetails}
              />,
            );
            break;
          }
          case LOBNAMES.CAR: {
            const { carUIDetails } = crossplatformLayoutDetail || {};
            views.push(
              <CabAddonList
                addOnCardClick={() => addOnCardClickHandler(childBookingData, flightDetailResponse)}
                {...carUIDetails}
              />,
            );
            break;
          }
          default: {
            break;
          }
        }
      }
    });
    return views;
  };

  const getVisaGuaranteeCard = () => {
    const views: React.JSX.Element[] = [];
    if (!visaGuaranteeDetails) {
      return views;
    }
    const {
      heading, description, vendorLogo, paxInsuranceClaimList, 
      claimUrl, status, statusColor, callToAction, actionId,
      claimInfoPopupTriggerText, claimPopupInfo
    } = visaGuaranteeDetails;

    views.push(
      <AddonList
        trackClickEvent={trackClickEvent}
        headerMessage={heading}
        subHeaderMessage={description}
        status={status}
        statusColor={statusColor}
        actionId={actionId}
        iconForApp={vendorLogo}
      />,
    );

    views.push(<View style={styles.bottomLine}></View>)

    claimInfoPopupTriggerText &&
      !isEmpty(claimPopupInfo) &&
        views.push(
          <RectFullButton
            action={{
              actionLabeltext: claimInfoPopupTriggerText
            }}
            clickHandler={openVpgInfoOverlay}
          />
        )
    callToAction &&
      claimUrl &&
        views.push(
          <RectFullButton
            action={{
              actionLabeltext: callToAction,
              actionId: actionId,
              actionUrl: claimUrl
            }}
            clickHandler={openAddOnDeepLink}
          />
        )
        
    if(!isEmpty(paxInsuranceClaimList)){
      views.push(
        <View style={[styles.fltInnerDtlsWrappers]}>
          <PaxClaimDetails
            paxInsuranceClaimList={paxInsuranceClaimList}
          />
        </View>
      )
    }
    return views;

  }

  if (showMainContent) {
    return (
      <View style={[styles.whiteWrapper, AtomicCss.marginBottom10]}>
        <View style={styles.cardContent}>
          {!isEmpty(title) && (
            <Text
              style={[
                fonts.blackFontFamily,
                styles.title,
                AtomicCss.paddingTop3,
                AtomicCss.textCenter,
                AtomicCss.flex1,
              ]}
            >
              {title}
            </Text>
          )}
        </View>
        <View>
          {getAirportServicesCard()}
          {!isEmpty(responseJson) && getCrossLobCards()}
          {showVpgCard && getVisaGuaranteeCard()}
          {isEmpty(responseJson) && !showVpgCard && !isEmpty(addOnNotPresentMessage) && (
            <Text style={[styles.cabNotPresentStyle, fonts.regularFontFamily, fsStyle.font14]}>
              {addOnNotPresentMessage}
            </Text>
          )}
        </View>
      </View>
    );
  }
  return null;
};

export default FlightCrossLobAddonCard;
