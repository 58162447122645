import { colors, fonts } from '@mmt/legacy-commons/Styles/globalStyles';

const styles = {
  whiteCard: {
    borderRadius: 8,
    backgroundColor: colors.white,
  },
  processDtlsWrapper: {
    paddingVertical: 20,
    paddingLeft:26,
    paddingRight:12
  },
  tabsBtn:{
    paddingVertical: 14,
    paddingHorizontal: 15,
    borderBottomWidth: 3,
    borderBottomColor:'#f2f2f2',
    flex:1,
    alignItems:'center',
  },
  tabsBtnTxt:{
    color:'#4a4a4a',
    fontFamily:fonts.regular,
    fontSize:14,
  },
  tabsBtnTxtactiveStyle:{
    color:'#4a4a4a',
    fontFamily:fonts.bold,
  },
  tabsBtnactiveStyle:{
    borderBottomColor:colors.azure
  },
  processRowDtls:{
    paddingLeft:25,
    borderLeftWidth:1,
    borderLeftColor:'#e1e7ee',
    paddingBottom:25,
  },
  lastRowStyle:{
    paddingBottom:0,
    borderLeftWidth:0,
    borderLeftColor:'transparent',
  },
  processTitle:{
    color:colors.defaultTextColor,
    fontFamily:fonts.bold,
    fontSize:14,
    marginBottom:5,
  },
  processDesc:{
    color:'#747474',
    fontFamily:fonts.regular,
    fontSize:12,
  },
  iconStyle:{
    width:15,
    height:15,
  },
  galleryWrapper:{
    flexDirection:'row',
    flexWrap:'wrap',
    marginTop:10,
  },
  galleryImgWrapper:{
    width:40,
    height:40,
    borderRadius:4,
    marginRight:10,
    backgroundColor:'#ddd',
    alignItems:'center',
    justifyContent:'center',
  },
  galleryImg:{
    width:40,
    height:40,
    borderRadius:4,
  },
  galleryMoreTxt:{
    color:colors.white,
    fontFamily:fonts.bold,
    fontSize:12,
    position:'absolute',
    zIndex:11,
  },
  greyBtnWrapper:{
    borderRadius:6,
    backgroundColor:'#c2c2c2',
    padding:6,
    alignItems:'center',
    justifyContent:'center',
    width:195,
  },
  greyBtnTxt:{
    color:colors.white,
    fontFamily:fonts.regular,
    fontSize:12,
  },
  btnWrapper:{
    paddingRight:20,
    paddingVertical:10,
  },
  imgHolder:{
    width:32,
    height:32,
    backgroundColor:'#f5f5f5',
    position:'absolute',
    left:-17,
    borderRadius:32,
    justifyContent:'center',
    alignItems:'center',
  },
  headingStyle:{
    paddingHorizontal: 16,
    flexDirection: "row",
    paddingTop: 12
  },
  infoHeadingIconStyle:{
    marginRight: 10
  },
  cardBorder:{
    borderTopColor:'#e4e4e4',
    borderBottomColor:'#e4e4e4',
    borderTopWidth:1,
    borderBottomWidth:1
  },
  bulletCircle: {
    width: 6,
    height: 6,
    backgroundColor: '#5a5a5a',
    borderRadius: 20,
    top: 5,
    left: 5
  },
  infoCardDisc: {
    lineHeight: 18,
    paddingLeft: 20,
    flex: 1,
    color:'#747474',
    fontFamily:fonts.regular,
    fontSize:12
  },
};

export default styles;
