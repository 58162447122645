import React from 'react';
import {ScrollView, Text, View} from 'react-native';
import Actions from '../../../navigation/postSalesNavigation';
import PropTypes from 'prop-types';
import styles from './BusCancelledByOperatorCss';
import Breadcrumb from './Breadcrumb';
import StickyHeader from '../../../hotel/details/components/StickyHeader';
import BusOperator from './BusOperator';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import ReviewButton from './ReviewButton';
import SpecialClaimBaseClass from './SpecialClaimBaseClass';
import {headerIcon} from '../../BusImageConstants';
import { label } from '@mmt/post-sales/src/staticData/staticData';
import { getFont } from '../../../PostSalesStyles';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import BusBookingTrackingHelper from './../../BusBookingTrackingHelper';
import { PageName } from './../../BusBookingDetailsConstant';

export default class BusCancelledByOperator extends SpecialClaimBaseClass {
  constructor(props) {
    super(props, 'SpecialClaimBusCancelled', 'SpecialclaimSelect');
    this.allPaxSelected = false;
    this.specialClaimData = this.props.specialClaimData ? JSON.parse(this.props.specialClaimData) : null;
    this.partialPax = this.props.partialPax ? JSON.parse(this.props.partialPax) : null;
    this.state = {
      activeStep: 0,
      responseJson: null,
      viewState: ViewState.SHOW_DETAIL,
      selectedPassenger: {}
    };
    this.STEPS = [label('bus.claim.claimDetails'), label('bus.claim.confirmCancellation')];
    this.fonts = getFont(true);
  }

  render() {
    return (
      <View style={styles.pageScroll}>
        {this.state.viewState === ViewState.LOADING && this.renderProgressView(label('bus.loading'))}
        {this.state.viewState === ViewState.NO_INTERNET && this.renderNoNetworkView()}
        {this.state.viewState === ViewState.ERROR && this.renderErrorView()}
        {this.state.viewState === ViewState.SHOW_DETAIL && this.renderPage()}
      </View>
    );
  }

  componentDidMount() {
    super.componentDidMount();
  }

  getCancelRequest() {
    const request = {
      bookingId: this.props.bookingId,
      preview: true,
      refundOption: 'RefundToCustomer',
      lineNumbers: this.getLineNumbers()
    };

    return request;
  }

  onClickEventTracker = (omnitureClickEvent) => {
    BusBookingTrackingHelper.trackClickEvent(
      PageName.TRIP_DETAILS,
      undefined,
      omnitureClickEvent,
    );
  };

  getLineNumbers() {
    const linenos = [];
    this.props.response.busBookingInfo.passengerDetails.forEach((paxDetails) => {
      if (paxDetails.lineno && paxDetails.status && paxDetails.status === 'Confirmed'){
        linenos.push(paxDetails.lineno.toString());
      }
    });
    return linenos;
  }

  renderPage() {
    const { fonts } = this;
    return (
      <View style={styles.pageWrapper}>
        <StickyHeader
          iconSrc={headerIcon}
          title={this.getTitle()}
          headerColor={['#ffffff', '#ffffff']}
          titleStyle={[styles.headerTitle, fonts.boldFontFamily, fonts.font16]}
          onBackPress={this.goBack}
        />
        <Breadcrumb data={this.STEPS} activeStep={this.state.activeStep} />
        <ScrollView showsVerticalScrollIndicator={false} style={styles.pageScroll}>
          <View style={styles.pageScrollTopSpace} />

          <View style={styles.whiteCard}>
            <Text style={[styles.cardTitle, fonts.lightFontFamily, fonts.font21, AtomicCss.lineHeight34]}>
              {this.specialClaimData.explTxt}
            </Text>
            <Text style={[styles.cardSubTitle, fonts.regularFontFamily, fonts.font14]}>
              {this.specialClaimData.explSubTxt}
            </Text>
            {this.addSegments()}
          </View>
          <View style={styles.pageScrollBtmSpace} />
        </ScrollView>
        <View style={styles.footerBtnWrapper}>
          <ReviewButton
            buttonText={this.specialClaimData.reviewDetails}
            clickHandler={this.onContinueClicked}
            disabled={false}
          />
        </View>
      </View>
    );
  }

  getTitle = () => `${label('bus.claim.claimRefund')}: ${this.specialClaimData.scName}`;

  addSegments = () => {
    return(
      <BusOperator
        response={this.props.response}
        passengerClickHandler={this.onPaxSelectionChanged}
      />
    )
  }

  goBack = () => {
    super.onBackIconPress();
  };

  onPaxSelectionChanged = (segmentGroup, paxSelectionMap) => {
    const slctdPssngr = this.state.selectedPassenger;
    slctdPssngr[segmentGroup.intentRefID] = paxSelectionMap;
    this.setState({
      selectedPassenger: slctdPssngr
    });
  };

  onContinueClicked = () => {
    const request = this.getCancelRequest();
    this.onClickEventTracker('Buscancelled_Operator_ReviewDetails')
    Actions.autoRefundBusRouter({
      bookingID: this.props.bookingId,
      checkIfRefundPresent: this.checkIfRefundPresent,
      request: request,
      response: this.props.response,
      specialClaimData: this.props.specialClaimData,
      selectedPassenger: this.state.selectedPassenger,
      bookingState: this.props.bookingState,
      bookingId: this.props.bookingId,
      lobCode: this.props.lobCode,
      linenos: this.getLineNumbers()
    });
  };

  checkIfRefundPresent = () => {
      Actions.busCancelledByOperatorReview({
        response: this.props.response,
        specialClaimData: this.props.specialClaimData,
        selectedPassenger: this.state.selectedPassenger,
        bookingState: this.props.bookingState,
        bookingId: this.props.bookingId,
        lobCode: this.props.lobCode,
        linenos: this.getLineNumbers()
      });
  };

}

BusCancelledByOperator.propTypes = {
  specialClaimData: PropTypes.string.isRequired,
  bookingId: PropTypes.string.isRequired,
  lobCode: PropTypes.string.isRequired,
  mobileNumber: PropTypes.string.isRequired
};
