
import { StyleSheet } from 'react-native';

export default (theme, fonts) =>
  StyleSheet.create({
    OverlayContentWrapper: {
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
    },
    headerWrapper: {
      marginBottom: 8,
      marginLeft: -20,
      marginRight: -20,
      paddingHorizontal: 20,
    },
    title: {
      ...fonts.blackFontFamily,
      fontSize: fonts.fontSize.font22,
      color: theme.color.black,
    },
    btnWrapper: {
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
      marginTop: 10,
    },
  });
