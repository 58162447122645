import React from 'react';
import {View, Text, Image} from 'react-native';
import  isEmpty  from 'lodash/isEmpty';
import Button from './Button';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getPlatformElevation from './getPlatformElevation';
import BasePage from '../../Common/PostSalesBasePage';
import {cancelledTicketIcon, ZCBig} from '../HolidayBookingConstants';


class CancellationCard extends BasePage {
  static navigationOptions = {
    header: null
  };

  render() {
    const {card, buttons} = this.props;
    return (
      <View style={[AtomicCss.marginBottom10, styles.cardBorder]}>
        <View style={[styles.infoCardWrapper]}>
          <View style={styles.headingIconWrapper}>
            <Image style={styles.headingIconStyle} source={cancelledTicketIcon}/>
          </View>
          <View style={styles.infoCardContent}>
            <Text
              style={[AtomicCss.defaultText, AtomicCss.blackFont, AtomicCss.font14, AtomicCss.marginBottom15]}>{card.heading}</Text>
            {card.zeroCancellation &&
            <Image style={styles.ZCBigIcon} source={ZCBig}/>
            }
            <Text
              style={[AtomicCss.regularFont, AtomicCss.font14, AtomicCss.defaultText, AtomicCss.lineHeight20]}>
              {card.subHeading}
            </Text>
            <View style={styles.buttonWrapper}>
              {!isEmpty(buttons)
              && buttons.map(item => (
                <Button
                  button={item}
                  handleClick={this.props.handleButtonClick}/>
              ))
              }
            </View>
          </View>
        </View>
      </View>
    );
  }
}

const styles = {
  ZCBigIcon: {
    width: 120,
    height: 22,
    marginBottom: 12
  },
  cardBorder: {
    borderTopColor: '#e4e4e4',
    borderBottomColor: '#e4e4e4',
    borderTopWidth: 1,
    borderBottomWidth: 1
  },
  infoCardWrapper: {
    backgroundColor: '#fff',
    justifyContent: 'space-between',
    flexDirection: 'row',
    ...getPlatformElevation(2),
    marginBottom: 1
  },

  infoCardContent: {
    paddingHorizontal: 8,
    paddingVertical: 15,
    flex: 1
  },
  headingIconWrapper: {
    width: 46,
    alignItems: 'flex-start',
    top: 14,
    left: 16
  },
  headingIconStyle: {
    width: 24,
    height: 19
  },
  buttonWrapper: {
    paddingTop: 20,
    paddingBottom: 20,
    width: '90%'
  }
};


export default CancellationCard;
