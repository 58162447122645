import { StyleSheet } from "react-native";

export default (theme, fonts) =>StyleSheet.create({
    linkBtn: {
        backgroundColor: '#e7f0ff',
        borderRadius: 4,
        flexDirection: 'row',
        alignItems: 'center',
        paddingRight: 8,
        paddingLeft:2,
        margin:5,
        width: '45%',
       
    },
    imageWrapper: {
        width: 40,
        height: 40,
        alignItems: 'center',
        justifyContent: 'center'
    },

    arrowStyle: {
        width: 7,
        height: 10,
        marginLeft: 5,
    },
    IconStyle:{
        width:26,
        height:26  
    },
})