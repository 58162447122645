import { ActionTakerProps } from '../types';
import * as omniture from '@mmt/legacy-commons/Common/utils/OmnitureTrackerUtils';
import { ROLE_TYPES } from '../constants';
import { diffDays, getDateObjectFromDate, today } from '@mmt/legacy-commons/Helpers/dateHelpers';
import _isEmpty from 'lodash/isEmpty';
import { trackCabsCorporateEvent, getPagePrefix, PAGE_NAMES } from '../../cabsAnalytics';

export const trackLoadData = (response: ActionTakerProps) => {
  let loadEvent = {};
  const PagePrefix = getPagePrefix();
  if (!!response?.response && _isEmpty(response?.errors) && !_isEmpty(response?.response)) {
    const {
      omniture: omnitureData,
      role,
      cab_details: cabDetails,
      status,
      booking_reason: bookingReason,
      trip_info,
      fare_breakup: fareBreakup,
    } = response?.response;
    const departureDate = getDateObjectFromDate(omnitureData?.departure_date, 'DD-MM-YYYY');
    const pageName = `${PagePrefix}:${PAGE_NAMES.APPROVER_REQUESTOR}:${
      role === ROLE_TYPES.APPROVER
        ? ROLE_TYPES.APPROVER.toLowerCase()
        : ROLE_TYPES.REQUESTER.toLowerCase()
    }`;
    const isOutOfPolicy = !!fareBreakup?.corp_policy?.is_out_of_policy;
    const isPriceChange = !!fareBreakup?.corp_policy?.is_price_change;
    loadEvent = {
      [omniture.VAR_SRC_DEST]: `${omnitureData?.from_city} | ${omnitureData?.to_city}`,
      [omniture.VAR_PAGE_NAME]: pageName,
      [omniture.VAR_AP]: diffDays(departureDate, today()),
      [omniture.VAR_LOB_KEY]: `domestic cabs`,
      [omniture.VAR_START_DATE]: omnitureData?.departure_date,
      [omniture.VAR_TRIP_TYPE]: `${omnitureData?.trip_type}`,
      [omniture.VAR_PRODUCT_ID]: `${cabDetails?.type} | ${cabDetails?.model_name} | MySafety${
        !!cabDetails?.my_safety ? 'Yes' : 'No'
      }`,
      [omniture.VAR_CABS_STATUS]: `Mob_Cab_MyBiz_Request_${status}`,
      [omniture.VAR_USER_CONTEXT]: `Mob_Cab_MyBiz_Request_For_${bookingReason?.reason}`,
    };
    if (!!trip_info) {
      loadEvent = {
        ...loadEvent,
        [omniture.VAR_ERROR_KEY]: `Mob_Cab_MyBiz_Error_Sold_Out`,
      };
    }
    if (isPriceChange || isOutOfPolicy) {
      loadEvent = {
        ...loadEvent,
        [omniture.VAR_PRICE]: `Mob_Cab_MyBiz${isPriceChange ? '_Price_Change' : ''}${
          isOutOfPolicy ? '_OOP' : ''
        }_After_Request`,
      };
    }
    trackCabsCorporateEvent(pageName, loadEvent, true);
  } else {
    loadEvent = {
      [omniture.VAR_LOB_KEY]: `mob domestic cabs|corporate`,
      [omniture.VAR_PAGE_NAME]: `${PagePrefix}:${PAGE_NAMES.APPROVER_REQUESTOR}:error`,
      [omniture.VAR_ERROR_KEY]: `Mob_Cab_MyBiz_Load_Error_${
        response?.errors?.error_list?.[0]?.code || ''
      }`,
    };
    trackCabsCorporateEvent(
      `${PagePrefix}:${PAGE_NAMES.APPROVER_REQUESTOR}:error`,
      loadEvent,
      true,
    );
  }
};

export const trackClickEvent = (isApprover: boolean, eventName: string) => {
  const PagePrefix = getPagePrefix();
  trackCabsCorporateEvent(
    `${PagePrefix}:${PAGE_NAMES.APPROVER_REQUESTOR}:${
      isApprover ? ROLE_TYPES.APPROVER.toLowerCase() : ROLE_TYPES.REQUESTER.toLowerCase()
    }`,
    eventName,
    false,
  );
};
