import React from 'react';
import { StyleSheet, View, Image, Text, TouchableOpacity } from 'react-native';
import WhiteCardWrapper from '../../../Common/WhiteCardWrapper';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getStyle, { htmlstyles } from './style';
import HTMLView from 'react-native-htmlview';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import Button from 'apps/post-sales/src/Common/Button';
import { getStaticData } from 'apps/post-sales/src/staticData/staticData';
import isEmpty from 'lodash/isEmpty';
import BaseButton from '../../../Common/BaseButton';
import { PaymentPendingOverlayProps } from '../../types';
import { CROSS_ICON2 } from '../../Utils/RailImageConstant';
import { PAY_PENDING_ICON } from '../../Utils/RailImageConstant';
import RailBookingTrackingHelper from "../../details/RailBookingTrackEventHelper"
import { CLICK_EVENT, LABEL_ID } from '../../details/constants';

const PaymentPendingOverlay = ({ response, pageName, handleBottomOverlay, handlePendingPayClick } : PaymentPendingOverlayProps) => {
  const { bnppDetail } = response || {};
  const { paymentPendingOverlayData, paymentCheckoutUrl } = bnppDetail || {};
  const { heading, dataList, priceBreakup, actionList } = paymentPendingOverlayData || {};

  const { psTheme } = useTheme();
  const { color } = psTheme;
  const { fsStyle, ...fonts } = getFont(true);
  const styles = getStyle(fonts, psTheme);

  const onPayClick = () => {
    RailBookingTrackingHelper.trackClickEvent(
      pageName,
      response,
      CLICK_EVENT.BNPP_OVERLAY_PAY_CLICK,
    );
    handlePendingPayClick(paymentCheckoutUrl)
  }

  return (
    <React.Fragment>
      <View style={[styles.headingSection]}>
        <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.spaceBetween]}>
          <View style={[AtomicCss.flex1, AtomicCss.flexRow, AtomicCss.alignCenter]}>
            <Image style={styles.payPendingIconStyle} source={PAY_PENDING_ICON} />
            {!isEmpty(heading) && <Text style={styles.headingText}>{heading}</Text>}
          </View>
          <TouchableOpacity
            style={styles.crossIconWrapper}
            onPress={handleBottomOverlay}
          >
            <Image style={styles.crossIconStyle} source={CROSS_ICON2} />
          </TouchableOpacity>
        </View>
      </View>
      <View style={styles.bodySection}>
        {!isEmpty(dataList) &&
          dataList.map((item, index) => {
            const { text } = item;
            return (
              <View style={AtomicCss.marginBottom5}>
                <HTMLView value={text} stylesheet={htmlstyles(fonts, psTheme)} />
              </View>
            );
          })}
        {!isEmpty(priceBreakup) &&
          priceBreakup.map((item, index) => {
            const { labelId, text, amount, subList } = item;
            const isRemaingingAmount = labelId === LABEL_ID.BNPP_REMAINING_AMOUNT;
            return (
              <View style={styles.partialPayFareBreakup}>
                <View
                  style={[
                    AtomicCss.flexRow,
                    AtomicCss.alignCenter,
                    AtomicCss.spaceBetween,
                    AtomicCss.marginBottom8,
                  ]}
                >
                  <Text style={isRemaingingAmount ? styles.remainingText : styles.priceText}>{text}</Text>
                  <Text style={isRemaingingAmount ? styles.remainingText : styles.priceText}>{amount}</Text>
                </View>

                {!isEmpty(subList) &&
                  subList.map((item, index) => {
                    const { text, amount } = item;
                    return (
                      <View
                        style={[
                          AtomicCss.flexRow,
                          AtomicCss.alignCenter,
                          AtomicCss.spaceBetween,
                          AtomicCss.marginBottom5,
                        ]}
                      >
                        <Text style={styles.priceSubText}>{text}</Text>
                        <Text style={styles.priceSubText}>{amount}</Text>
                      </View>
                    );
                  })}
              </View>
            );
          })}
      </View>
      <View style={[AtomicCss.paddingHz20, AtomicCss.paddingVertical20, AtomicCss.marginBottom30]}>
        {!isEmpty(paymentCheckoutUrl) && !isEmpty(actionList) &&
          actionList.map((action, index) => {
            const { actionType: variant, actionLabeltext, actionId } = action || {};
            return (
              <BaseButton
                variant={variant}
                buttonStyle={styles.buttonStyle}
                textStyle={styles.buttonTxtStyle}
                clickHandler={onPayClick}
                text={actionLabeltext}
              ></BaseButton>
            );
          })}
      </View>
    </React.Fragment>
  );
};

export default PaymentPendingOverlay;
