import { StyleSheet } from 'react-native';

const getStyles = (theme, fonts) => {
  const { color } = theme;
  return StyleSheet.create({
    fcWrapper: {
      paddingBottom: 30,
    },
    headingSection: {
      paddingHorizontal: 20,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
    headingText: {
      color: color.black22,
      ...fonts.blackFontFamily,
      fontSize: fonts.fontSize.font20,
    },
    detailsSection: {
      paddingVertical: 14,
      backgroundColor: color.white,
    },
    crossIconWrapper: {
      width: 30,
      height: 30,
      top: -4,
      right: -5,
      alignItems: 'center',
      justifyContent: 'center',
    },
    okayButton: {
      backgroundColor: color.white,
      paddingVertical: 10,
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 10,
      marginHorizontal: 10,
      borderWidth: 1,
      borderColor: color.azure,
      borderRadius: 8,
    },
    okayText: {
      color: color.azure,
      ...fonts.boldFontFamily,
      fontSize: fonts.fontSize.font16,
    },
    crossIconStyle: {
      width: 22,
      height: 22,
      tintColor: color.greyText1,
    },
    canIconStyle: {
      width: 32,
      height: 32,
      marginRight: 10,
    },
    timeHeading: {
      ...fonts.regularFontFamily,
      color: color.defaultTextColor,
      fontSize: fonts.fontSize.font12,
    },
    lastRowStyle: {
      borderBottomColor: color.transparent,
      borderBottomWidth: 0,
    },

    bodySection: {
      paddingVertical: 12,
      paddingBottom: 20,
      paddingHorizontal: 16,
      backgroundColor: color.white,
    },
    footer: {
      paddingVertical: 0,
      paddingHorizontal: 16,
      marginBottom: 30,
    },
    freeCanWrapper: {
      borderColor: color.greyBookedSeat,
      borderRadius: 16,
      backgroundColor: color.grayBg,
      borderWidth: 1,
    },
    headingInfoWrapper: {
      backgroundColor: color.grayBg,
      flexDirection: 'row',
      borderTopLeftRadius: 16,
      borderTopRightRadius: 16,
    },
    headingTextWrapper: {
      borderRightColor: color.lightGray,
      borderRightWidth: 1,
      paddingVertical: 8,
      paddingHorizontal: 12,
      justifyContent: 'center',
      width: '55%',
    },
    innerInfoDetails: {
      backgroundColor: color.white,
      borderRadius: 16,
    },
    innerInfo: {
      borderRightColor: color.lightGray,
      borderRightWidth: 1,
      paddingVertical: 8,
      paddingHorizontal: 12,
      justifyContent: 'center',
      width: '55%',
    },
    innerInfoRight: {
      paddingVertical: 8,
      paddingHorizontal: 12,
      justifyContent: 'center',
      width: '45%',
    },
    innerDetails: {
      borderRadius: 16,
      backgroundColor: color.lightGray,
      marginTop: -12,
      paddingVertical: 1,
      paddingBottom: 0,
    },
    borderBtmZero: {
      borderBottomColor: color.transparent,
      borderBottomWidth: 0,
    },
    closeLinkWrapper: {
      backgroundColor: color.white,
      paddingVertical: 10,
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 10,
      marginHorizontal: -16,
    },
    rowSection: {
      borderBottomColor: color.lightGray,
      borderBottomWidth: 1,
      flexDirection: 'row',
    },
    tHeadingText: {
      fontSize: fonts.fontSize.font12,
      ...fonts.boldFontFamily,
      color: color.black74,
    },
    statusText: {
      color: color.lightGreen18,
      ...fonts.boldFontFamily,
    },
    footerText: {
      fontSize: fonts.fontSize.font12,
      ...fonts.regularFontFamilyFontFamily,
      color: color.black74,
    },
    iconStyle: {
      width: 22,
      height: 22,
      top: -3,
      marginRight: 2
    },
    internationalTimeText: {
      color: color.black74,
      ...fonts.boldFontFamily,
      fontSize: fonts.fontSize.font12,
    },
  });
};
export default getStyles;

export const getHtmlStyle = (fonts, theme) => {
  const { color } = theme;
  return StyleSheet.create({
    p: {
      ...fonts.regularFontFamily,
      color: color.textGrey,
      fontSize: fonts.fontSize.font12,
    },
    q: {
      ...fonts.boldFontFamily,
      color: color.lightGreen18,
    },
    b: {
      ...fonts.boldFontFamily,
    },
  });
};
