import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
import {StyleSheet} from 'react-native'
export default (theme,fonts) => 
  StyleSheet.create({
    cardHead: {
      fontSize: fonts.fontSize.font21,
      color: theme.color.defaultTextColor,
      ...fonts.lightFontFamily,
      marginBottom: 10,
    },
    linkTxt: {
      color: theme.color.submitDateChangeReqBtnText,
      fontSize: fonts.fontSize.font14,
      ...fonts.regularFontFamily,
    },
    paxDtlsRow: {
      ...getPlatformElevation(2),
      borderRadius: 4,
      backgroundColor: theme.color.white,
      padding: 10,
      marginBottom: 5,
    },
    paxName: {
      color: theme.color.black,
      ...fonts.boldFontFamily,
      fontSize: fonts.fontSize.font14,
      marginBottom: 3,
    },
    mngId: {
      color: theme.color.black,
      ...fonts.regularFontFamily,
      fontSize: fonts.fontSize.font14,
    },
  }
);

