import React, { useState, useEffect } from 'react';
import { View, Text } from 'react-native';
import styles from './ReqstCallScheduleCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import ScheduleTimeItem from './ScheduleTimeItem';
import Button from '../../../Button';
import { ViewState } from '../../../../PostSalesConstant';
import { CALLBACK_SLOTS_URL } from '../../../../utils/NetworkUtils';
import { renderProgressView, renderErrorView } from '../../NeedMoreHelpUtils';
import { usePostRequestApi } from '../../../PostRequestApi';

const ReqstCallScheduleOverlay = (props) => {
  const trackPage = 'helpinghand_confirm_callbacklater';
  const [timeActive, setTimeActive] = useState('');
  const [retry, setRetry] = useState(0);

  const request = {
    bookingId: props.bookingId,
    tenant: 0,
    primarytype: props.callDrivers && props.callDrivers.primarytype,
    secondarytype: props.callDrivers && props.callDrivers.secondarytype,
    ternarytype: props.callDrivers && props.callDrivers.ternarytype,
    pageName: !props.callDrivers ? props.pageName : '',
    lobName: props.lobName,
  };

  const [viewState, response] = usePostRequestApi(
    CALLBACK_SLOTS_URL,
    request,
    props.bookingId,
    'CALLBACK_SLOTS',
    retry,
  );

  useEffect(() => {
    if (response) {
      props.trackEvent && props.trackEvent('load', trackPage);
    }
  }, [response]);

  const handleRetry = () => {
    setRetry(retry + 1);
  };

  const handleTimeList = (index, slot) => {
    setTimeActive(slot);
  };

  if (viewState === ViewState.LOADING) {
    return renderProgressView();
  }

  if (viewState === ViewState.ERROR) {
    return renderErrorView(handleRetry);
  }

  if (viewState === ViewState.LOADED && response && response.layoutData) {
    return (
      <View style={[styles.OverlayContentWrapper]}>
        <View style={AtomicCss.marginBottom16}>
          <View style={styles.overlayTopline} />
        </View>
        <Text style={styles.title}>{response.layoutData.cardHeader}</Text>

        <View style={styles.scheduleListTimeSection}>
          {response.layoutData.callBackSlots &&
            response.layoutData.callBackSlots.length > 0 &&
            response.layoutData.callBackSlots.map((item, index) => {
              return (
                <ScheduleTimeItem
                  item={item}
                  index={index}
                  handleTimeList={handleTimeList}
                  timeActive={timeActive}
                  isLastItem={index === response.layoutData.callBackSlots.length - 1}
                />
              );
            })}
        </View>
        <View style={styles.btnWrapper}>
          <Button
            btnType="flat"
            btnBg="gradientBtn"
            btnTxt={response.layoutData.ctaText}
            fontSize="font14"
            disable={timeActive === ''}
            clickHandler={(action) => {
              props.trackEvent && props.trackEvent('click', trackPage, response.layoutData.ctaText);
              props.handleClick(action);
            }}
            btnClickAction={[response.configId, timeActive]}
          />
        </View>
      </View>
    );
  }
  return null;
};

export default ReqstCallScheduleOverlay;
