import {Platform} from 'react-native';
import { httpGet } from '../data/api';
import { ApiRequestTypes } from '../summary/summaryConstants';
import { setDataInStorage, getDataFromStorage, removeDataFromStorage, getDataFromWebLocalStorage } from '@mmt/legacy-commons/AppState/LocalStorage';
import CommonTextJson from './defaultData/commonText.eng.json';
import FlightTextJson from './defaultData/flightText.eng.json';
import HotelTextJson from './defaultData/hotelText.eng.json';
import contactUsTextJson from './defaultData/contactUsText.eng.json';
import { addDays } from '@mmt/legacy-commons/Helpers/dateHelpers';
import { merge } from 'lodash';
import { getUserSelectedLanguage } from '@mmt/legacy-commons/AppState/AppUserConfig';
import { LOBNAMES } from '../PostSalesConstant';
import TrainTextJson from './defaultData/trainsText.eng.json';
import busTextJson from "./defaultData/busText.eng.json";
import PostSalesVernac from '../PostSalesVernac';

export const LANGUAGE_COOKIE = 'lang';
export const ENGLISH = 'eng';
export const HINDI = 'hin';
export const ARABIC = 'ara';

let isStaticContentLoading = false;
const defaultData = {
    ...CommonTextJson,
    ...FlightTextJson,
    ...HotelTextJson,
    ...TrainTextJson,
    ...contactUsTextJson,
    ...busTextJson,
};
let staticData = {
  [ENGLISH]: defaultData,
};


const version = 23;

const storageKey = `_PostSalesData_V${version}`

export const fetchStaticData = async () => {
  if(!isStaticContentLoading) {
    isStaticContentLoading = true;
    const lang = getUserSelectedLanguage();
    const fetchData = await shouldFetchConstantData(lang);
    if (fetchData) {
      let data = null;
      try {
        const result = await httpGet('/staticContent', null, {
          uniqueIdType: ApiRequestTypes.STATIC_TEXT_JSON,
          psLob: LOBNAMES.POST_SALES,
          isStaticContent: true
        });
        isStaticContentLoading = false;
        if (result && result.staticData) {
          data = {};
          for (let key in result.staticData) {
            if (key && key.toUpperCase() === 'HEADER') {
              data.appHeaderText = result.staticData[key];
            } else {
              data = {
                ...data,
                ...result.staticData[key],
              };
            }
          }
        }
      } catch (error) {
        isStaticContentLoading = false;
        console.log('Error while fetching static data');
        data = defaultData;
      }
      if (data) {
        data.expiryDate = getExpireDate();
        const finalDataToStore = merge({}, defaultData, data);
        setStaticData(finalDataToStore, lang);
        await setDataInStorage(`${lang}${storageKey}`, getStaticData(lang));
      }
    } else {
      isStaticContentLoading = false;
    }
    removeOldVersionData(lang);
  }
};

export const getData = async (lang) => {
  const data = await getDataFromStorage(`${lang}${storageKey}`);
  if (data) {
    return data;
  }
  return defaultData;
};

const isStaticAlreadyPresent = async (lang) => {
  const data = await getDataFromStorage(`${lang}${storageKey}`);
  if (data !== null && data.expiryDate) {
    const expiryDate = data.expiryDate;
    if (new Date(expiryDate) < new Date()) {
      await removeDataFromStorage(`${lang}${storageKey}`);
      return false;
    }
    setStaticData(data, lang);
    return true;
  } else {
    return false;
  }
};

const shouldFetchConstantData = async (lang) => {
  return lang !== ENGLISH && !(await isStaticAlreadyPresent(lang));
};

const getExpireDate = () => {
  const now = new Date();
  return addDays(now, 1);
};

export const getStaticData = (langAware = true, restrictLang) => {
  const currentLang = getUserSelectedLanguage();
  const applicableLang = PostSalesVernac.applicableLang(currentLang);
  if (restrictLang) {
    return staticData[applicableLang] || {};
  }
  const lang = langAware ? applicableLang : ENGLISH;
  // In case of refresh on web, no API might be called, and thus fetchInterceptor -> fetchStaticData() would not get called, thus staticData gets initialized back with English content only.
  if (Platform.OS === 'web' && !staticData[lang]) {
    setStaticData(getDataFromWebLocalStorage(`${lang}${storageKey}`), lang);
  }
  return staticData[lang] || staticData[ENGLISH];
};

export const getCurrentLangStaticData = () => {
  const currentLang = getUserSelectedLanguage();
  return staticData[currentLang] || staticData[ENGLISH];
}
export const setStaticData = (data, lang) => {
  staticData[lang] = data;
};

const removeOldVersionData = (lang) => {
  for (let i = 1; i <= version - 1; i++) {
    removeDataFromStorage(`${lang}_PostSalesData_V${i}`);
  }
};

export const getPSLobLanguage = (lob) => {
  const useSelectedLang = getUserSelectedLanguage();
  return PostSalesVernac.applicableLang(useSelectedLang, lob);
};

export const label = (str, options, params) => {
  try {
      let _label = getStaticData()[str] || staticData[ENGLISH][str];
      if (options) {
          const keys = Object.keys(options);
          keys.forEach(k => {
              _label = options[k] ? transformers[k](_label, str) : _label;
          });
      }
      if (params) {
          _label = transformers.parameterize(_label, params);
      }
      return _label.trim();
  } catch (e) {
      return str;
  }
}


const transformers = {
  capitalize: (str) => {
      try {
          return str
              .toLowerCase()
              .split(" ")
              .map((s) => (s[0].toUpperCase() + s.slice(1)))
              .join(" ");
      } catch (e) {
          return str;
      }
  },
  sentenceCase: (str) => {
      try {
          return str[0].toUpperCase() + str.slice(1);
      } catch (e) {
          return str;
      }
  },
  lowercase: (str) => {
      try {
          return str.toLowerCase();
      } catch (e) {
          return str;
      }
  },
  uppercase: (str) => {
      try {
          return str.toUpperCase();
      } catch (e) {
          return str;
      }
  },
  defaultLang: (str, key) => {
      return staticData[ENGLISH][key];
  },
  parameterize: (str, params) => {
      return str.replace(/{{\w*}}/ig, (match) => {
          const key = match.replace(/[{}]/ig, '') || "";
          return params[key];
      });
  }
}