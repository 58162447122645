import { StyleSheet } from "react-native";
import { DEFAULT_THEME } from "../../../../../../theme/default.theme";

const createStyles = <Theme extends typeof DEFAULT_THEME>(theme: Theme) =>
  StyleSheet.create({
    psngItemTxt: {
      color: theme.color.black,
      marginBottom: 5,
      textAlign: 'left',
      flexWrap: 'wrap',
    },
    tvlrInfoWrapper: {
      backgroundColor: theme.color.grayBg,
      borderRadius: 4,
      paddingHorizontal: 8,
      paddingVertical: 12,
      marginTop: 0,
      overflow: 'hidden',
    },
    topSection: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    headingTxt: {
      color: theme.color.black74,
      marginBottom: 15,
      marginRight: 5,
      flexWrap: 'wrap',
    },
    paxInfoWrapper: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 15,
    },
    paxInfoColumnWrapper: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      marginRight: 5,
    },
    trvlrNameSectionWidth: {
      width: '30%',
      marginLeft: 10,
    },
    claimStatus: {
      width: '40%',
      textAlign: 'right',
    },
    infoMessageBox: {
      flexDirection: 'row',
      backgroundColor: theme.color.white,
      paddingVertical: 5,
      paddingHorizontal: 5,
      marginLeft: 10,
      borderRadius: 6,
      borderWidth: 1,
    },
    wcBorderStyle: {
      borderColor: theme.color.lightGrey16,
    },
    wcTextStyle: {
      flex: 1,
      color: theme.color.defaultTextColor,
      textAlign: 'justify'
    },
  });

export default createStyles;
