import React from 'react';
import {
  View,
  Text,
  Image,
  StyleSheet,
  Dimensions,
  TouchableOpacity
} from 'react-native';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';
import {
  ActivityIcon,
  DownloadIcon,
  HolidayIcon,
  refreshIcon,
  TickSuccessIcon,
  DOWNLOAD_VOUCHER_STATES,
} from '../HolidayBookingConstants';
import { ActivityIndicator } from 'react-native';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';

const windW = Dimensions.get('screen').width;

class VoucherList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMore: false,
      numberOfLines: null
    };
  }


  icon(type) {
    switch (type) {
      case 'ADDON':
      case 'ADDON_VIATOR':
        return ActivityIcon;
      case 'HOLIDAY':
        return HolidayIcon;
      default:
        return HolidayIcon;
    }
  }

  iconStyle(type) {
    switch (type) {
      case 'ADDON':
      case 'ADDON_VIATOR':
        return styles.activityImg;
      case 'HOLIDAY':
        return styles.holidayImg;
      default:
        return styles.holidayImg;
    }
  }

  componentDidMount() {
    const {item} = this.props;
    if (item.text && item.text.length > 140) {
      this.setState({
        showMore: true,
        numberOfLines: 3
      });
    }
  }

  handleShowMore() {
    if (this.state.numberOfLines === 3) {
      this.setState({
        numberOfLines: 25
      });
    } else {
      this.setState({
        numberOfLines: 3
      });
    }
  }

  render() {
    const { item, status } = this.props;
    const lstItem = this.props.lstItem ? [styles.lastItemstyle] : '';
    const ShowMoreTxt = this.state.numberOfLines === 3 ? 'show more' : 'show less';
    let subHeading = '';
    if (!isEmpty(item.noOfVouchers)) {
      subHeading = `${item.noOfVouchers}`;
    }
    if (!isEmpty(subHeading) && !isEmpty(item.text)) {
      subHeading += ' - ';
    }

    return (
      <React.Fragment>
        <View style={[styles.cardWrapper, AtomicCss.flexRow, AtomicCss.marginBottom10, lstItem]}>
          <View style={styles.width40IconWrapper}>
            <Image style={this.iconStyle(item.type)} source={this.icon(item.type)}/>
          </View>
          <View style={[AtomicCss.flex1, AtomicCss.flexRow]}>
            <View style={[styles.dtlsSection, AtomicCss.flex1, AtomicCss.marginRight15]}>
              <Text
                style={[AtomicCss.blackFont, AtomicCss.blackText, AtomicCss.font16,
                  AtomicCss.marginBottom5]}
              >{item.heading}
              </Text>
              <View>
                {(!isEmpty(item.text) || !isEmpty(subHeading)) &&
                <Text
                  numberOfLines={this.state.numberOfLines}
                  style={[AtomicCss.defaultText, AtomicCss.font14, AtomicCss.lineHeight18]}
                >
                  {!isEmpty(subHeading) &&
                  <Text
                    style={AtomicCss.boldFont}
                  >{subHeading}
                  </Text>
                  }
                  {item.text}
                </Text>
                }
              </View>
              {this.state.showMore &&
              <TouchableOpacity
                style={{paddingVertical: 5}}
                activeOpacity={0.6}
                onPress={() => this.handleShowMore()}
              >
                <Text
                  style={[AtomicCss.azure, AtomicCss.regularFont, AtomicCss.font14]}
                >
                  {ShowMoreTxt}
                </Text>
              </TouchableOpacity>
              }
              {status && !isEmpty(status.message) && (
                <View
                  style={
                    status.state === DOWNLOAD_VOUCHER_STATES.RETRY
                      ? [styles.failedWrapper, { backgroundColor: colors.orange5 }]
                      : styles.failedWrapper
                  }
                >
                  <Text
                    style={
                      status.state === DOWNLOAD_VOUCHER_STATES.RETRY
                        ? [styles.failedTxt, AtomicCss.redText]
                        : styles.failedTxt
                    }
                  >
                    {status.message}
                  </Text>
                </View>
              )}
            </View>
            {status && status.state === DOWNLOAD_VOUCHER_STATES.PROGRESS && (
              <View style={styles.actionIconWrapper}>
                <ActivityIndicator size="small" color={colors.primary} />
              </View>
            )}
            {status && status.state === DOWNLOAD_VOUCHER_STATES.INITIAL && (
              <TouchableOpacity
                style={styles.dwnLoadWrapper}
                onPress={() => this.props.downloadVoucher(item)}
              >
                <Image style={styles.downloadIconStyle} source={DownloadIcon} />
              </TouchableOpacity>
            )}
            {status && status.state === DOWNLOAD_VOUCHER_STATES.SUCCESS && (
              <TouchableOpacity
                style={styles.actionIconWrapper}
                onPress={() => this.props.downloadVoucher(item)}
              >
                <Image style={styles.successTickStyle} source={TickSuccessIcon} />
              </TouchableOpacity>
            )}
            {status && status.state === DOWNLOAD_VOUCHER_STATES.RETRY && (
              <TouchableOpacity
                style={styles.actionIconWrapper}
                onPress={() => this.props.downloadVoucher(item)}
              >
                <Image style={styles.retryImg} source={refreshIcon} />
              </TouchableOpacity>
            )}
          </View>
        </View>
      </React.Fragment>
    );
  }
}

const styles = StyleSheet.create({
  cardWrapper: {
    width: windW,
    backgroundColor: '#fff',
    ...getPlatformElevation(2),
    paddingVertical: 16,
    paddingHorizontal: 13,
    marginBottom: 3
  },
  activityDayNumber: {
    width: 20,
    height: 20,
    backgroundColor: '#e5e5e5',
    borderRadius: 50,
    marginRight: 10
  },
  width40IconWrapper: {
    width: 40,
    height: 40,
    marginRight: 12,
    paddingTop: 4,
    alignItems: 'center'
  },
  flightImg: {
    width: 21,
    height: 13
  },
  hotelImg: {
    width: 24,
    height: 24
  },
  carImg: {
    width: 20,
    height: 19
  },
  activityImg: {
    width: 15,
    height: 23
  },
  dayItemRow: {
    borderBottomWidth: 1,
    borderBottomColor: '#e5e5e5',
    paddingVertical: 15
  },
  pendingInfo: {
    color: '#cf8100'
  },
  lastItemstyle: {
    marginTop: 0
  },
  dwnLoadWrapper: {
    width: 40,
    height: 40
  },
  downloadIconStyle: {
    width: 17,
    height: 19,
    alignSelf: 'center'
  },
  activitImg: {
    width: 18,
    height: 20
  },
  holidayImg: {
    width: 15,
    height: 23,
  },
  retryImg: {
    width: 15,
    height: 15,
    tintColor: colors.azure,
  },
  successTickStyle: {
    width: 15,
    height: 15,
  },
  actionIconWrapper: {
    width: 32,
    height: 32,
    backgroundColor: colors.lightBlue7,
    marginLeft: 20,
    borderRadius: 50,
    alignItems: 'center',
    justifyContent: 'center',
  },
  failedWrapper: {
    paddingVertical: 6,
    paddingHorizontal: 10,
    alignSelf: 'flex-start',
    marginTop: 4,
  },
  failedTxt: {
    fontSize: 10,
  },
});

VoucherList.propTypes = {
  downloadVoucher: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  lstItem: PropTypes.bool.isRequired
};

export default VoucherList;
