import React from 'react';
import {ScrollView, View} from 'react-native';
import Actions from '../../../navigation/postSalesNavigation';
import PropTypes from 'prop-types';
import PromiseRefundCard from './PromiseRefundCard';
import CommonHeader from '../components/CommonHeader';
import DetailsRefundProcessCard from './DetailsRefundProcessCard';
import RegularRefundCard from './RegularRefundCard';
import {isIntlHotelBooking} from '../utils/HotelBookingDetailUtil';
import HotelBookingTrackingHelper from '../../HotelBookingTrackingHelper';
import logAction from '@mmt/legacy-commons/Helpers/actionsLogger';
import { WHITE_LEFT_ARROW_ICON } from '../../HotelImageConstants';
import { getStaticData } from '../../../staticData/staticData';
import PostSalesBasePage from 'apps/post-sales/src/Common/PostSalesBasePage';

const backImg = WHITE_LEFT_ARROW_ICON;

export default class RefundDetails extends PostSalesBasePage {
  static navigationOptions = {
    header: null
  };

  render() {
    const staticData = getStaticData();
    return (
      <View style={{flex: 1}}>
        <CommonHeader
          headerText={staticData.refundDetailsText}
          imgSrc={backImg}
          backPressHandler={this.onBackPress}
        />
        <ScrollView>
          {this.renderContent()}
        </ScrollView>
      </View>
    );
  }

  renderContent = () => {
    if (this.props.promise) {
      return this.renderFromPromise();
    }

    return this.renderWithOutPromise();
  };

  renderWithOutPromise() {
    logAction('Refund_Details_WithOut_Promise');
    return (<View>
      <RegularRefundCard
        response={this.props.response}
        card={this.props.card}
        refundObj={this.props.refundObj}
        showButton={false}
        showRefundProgress={false}
        progressStyle={this.props.progressStyle}
      />
      <DetailsRefundProcessCard
        refundObj={this.props.refundObj}
        progressStyle={this.props.progressStyle}
      />
    </View>);
  }

  renderFromPromise() {
    logAction('Refund_Details_With_Promise');
    return (<View>
      <PromiseRefundCard
        response={this.props.response}
        promise={this.props.promise}
        card={this.props.card}
        refundObj={this.props.refundObj}
        showButton={false}
        showRefundProgress={false}
      />
      <DetailsRefundProcessCard
        promise={this.props.promise}
        refundObj={this.props.refundObj}
        progressStyle={this.props.progressStyle}
      />
    </View>);
  }

  componentDidMount() {
    const hotelLob = isIntlHotelBooking(this.props.response?.lobCode)
      ? 'international' : 'domestic';
    let pageName = `Mob:customer support:${hotelLob}:RefundTracker:Regular`;
    if (this.props.promise) {
      pageName = `Mob:customer support:${hotelLob}:RefundTracker:WithPromise`;
    }
    HotelBookingTrackingHelper.trackLoadEvent(pageName, this.props.response);
  }

  onBackPress = () => {
    Actions.pop();
  };
}

RefundDetails.propTypes = {
  response: PropTypes.object.isRequired,
  promise: PropTypes.object,
  card: PropTypes.object.isRequired,
  refundObj: PropTypes.object.isRequired,
  progressStyle: PropTypes.array.isRequired
};
RefundDetails.defaultProps = {
  promise: undefined
};
