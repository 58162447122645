import React from 'react';
import {View, Text, TouchableOpacity} from 'react-native';
import PropTypes from 'prop-types';
import styles from './FareChangeOverlayCss';
import { getStaticData } from '../../../../../../apps/post-sales/src/staticData/staticData';



export default class FareChangeOverlay extends React.Component {
  render() {
    const {proceedText,goBackCapsText} = getStaticData(true);
    return (
      <View>
        <Text style={styles.contentTitle}>{this.props.content.title}</Text>
        <Text style={styles.contentDesc}>{this.props.content.desc}</Text>
        <View style={styles.contentFooter}>
          <TouchableOpacity
            style={styles.actionBtn}
            onPress={() => this.props.CloseOverlay()}
          >
            <Text
              style={
                this.props.isCorporateBooking
                  ? [styles.actionBtnText, styles.corporateColor]
                  :styles.actionBtnText
              }
            >
              {goBackCapsText}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.actionBtn}
            onPress={() =>
              this.props.ContinuePress(
                this.props.holdResponse,
                this.props.reviewResponse
              )
            }
          >
            <Text
              style={
                this.props.isCorporateBooking
                  ? [styles.actionBtnText, styles.corporateColor]
                  :   styles.actionBtnText
              }
            >
            {proceedText}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }
}

FareChangeOverlay.propTypes = {
  CloseOverlay: PropTypes.func.isRequired,
  ContinuePress: PropTypes.func.isRequired,
  content: PropTypes.object.isRequired
};

