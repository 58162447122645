import React, {useMemo} from 'react';
import {Image, ScrollView, Text, View} from 'react-native';
import isEmpty from 'lodash/isEmpty';
import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import {
  BusIcon,
  CarIcon,
  screenWidth,
  holidayType,
  buttonTypes,
  TRANSFER_STATUS_CODE
} from '../HolidayBookingConstants';
import RedirectBtn from './RedirectBtn';
import TopMessage from './TopMessage';
import LableRow from './LabelRow';
import {removeTags, initCap} from '../HolidayBookingDetailUtils';
import SeeMore from './SeeMore';
import {isNonEmpty} from '@mmt/legacy-commons/Common/utils/StringUtils';
import HolidayBookingTrackingHelper from '../HolidayBookingTrackingHelper';
import Actions from '../../navigation/postSalesNavigation';
import { ps_colors } from '../../PostSalesStyles';

const iconConfig = {
  [holidayType.COACH]: {
    iconStyle: 'busIconStyle',
    icon: BusIcon
  },
  [holidayType.CAR]: {
    iconStyle: 'carIconStyle',
    icon: CarIcon
  },
  [holidayType.TRANSFER]: {
    iconStyle: 'carIconStyle',
    icon: CarIcon
  },
  [holidayType.RAIL]: {
    iconStyle: 'busIconStyle',
    icon: BusIcon
  }
};

const TransferDetailTab = (props) => {
  const {transferDetail, topMessage, setContactInfo, contactList, pageName, bookingId,bannerText } = props;
  const {holidayContactList} = contactList;
  const contactMapping = useMemo(() => {
    const contactMap = [];
    for (let i = 0; i < transferDetail.length; i += 1) {
      const {lineno, type} = transferDetail[i];
      const list = [];
      for (let j = 0; j < holidayContactList.length; j += 1) {
        const contact = holidayContactList[j];
        if (contact.contactType === type && contact.lineNo === lineno) {
          list.push(holidayContactList[j]);
        }
      }
      contactMap[i] = list;
    }
    return contactMap;
  }, [holidayContactList, transferDetail]);

  const transferAction = (contactIndex, button) => {
    HolidayBookingTrackingHelper.trackClickEvent(pageName, `Transfer_Contact_Vendor_${contactIndex}`);
    if (button.type === buttonTypes.CONTACTS) {
      setContactInfo(contactMapping[contactIndex]);
    }
    else if(button.type === buttonTypes.ADD_CAR_PICKUP_DETAILS || button.type === buttonTypes.VIEW_EDIT_CAR_PICKUP_DETAILS || button.type === buttonTypes.VIEW_CAR_PICKUP_DETAILS){
      Actions.holidayAddCarPickUpDetails({
        bookingId
      });
    }
  };


  const getBannerStyle = () => {
    switch (bannerText) {
      case TRANSFER_STATUS_CODE.YELLOW:
        return {
          bgColor: ps_colors.lightYello,
          img: "voucherIcon",
        };
      case TRANSFER_STATUS_CODE.GREEN:
        return {
          bgColor: ps_colors.lightGreen14,
          img: "greenVoucherIcon",
        };
      case TRANSFER_STATUS_CODE.RED:
        return {
          bgColor: ps_colors.pink4,
          img: "redAlertIcon",
        };
  
      default:
        return {
          bgColor: ps_colors.lightYello,
          img: "voucherIcon",
        };
    }
  };

  const { bgColor, img } = getBannerStyle();

  return (
    <ScrollView style={{width: screenWidth}}>
      {topMessage && (
        <TopMessage img={img} info={topMessage} bgcolor={bgColor}/>
      )}
      <View>
        {transferDetail.map((item, index) => {
          return (
            <View
              style={[styles.cardWrapper]}
              key={index}
            >
              <View
                style={[
                  AtomicCss.flexRow,
                  AtomicCss.marginBottom10,
                  AtomicCss.alignCenter
                ]}
              >
                <Image
                  style={styles[iconConfig[item.type].iconStyle]}
                  source={iconConfig[item.type].icon}
                />
                <Text
                  style={[
                    AtomicCss.blackFont,
                    AtomicCss.defaultTextColor,
                    AtomicCss.font14,
                  ]}
                  >
                  {initCap(item.headerText)}
                </Text>
              </View>
              <View style={styles.activityInnerDtls}>
                {item.subHeaderText ? (
                  <View style={AtomicCss.marginBottom10}>
                    <Text
                      style={[
                        AtomicCss.blackFont,
                        AtomicCss.font16,
                        AtomicCss.blackText
                      ]}
                    >{item.subHeaderText}
                    </Text>
                  </View>
                ) : null}
                <LableRow label="Guest(s):" value={item.noOfPassengers}/>
                <View style={AtomicCss.marginBottom5}>
                  {isNonEmpty(item.description) && (
                    <SeeMore
                      numberOfLines={3}
                      text={removeTags(item.description)}
                      style={[
                        AtomicCss.regularFont,
                        AtomicCss.font14,
                        AtomicCss.defaultText
                      ]}
                    />
                  )}
                </View>
                <LableRow label="Car(s):" value={item.noOfCars}/>
                {item.bookingStatusMessage && (
                  <Text style={styles.infoTxt}>
                    {item.bookingStatusMessage}
                  </Text>
                )}
              </View>
              {!isEmpty(item.buttons) && item.buttons.map((button) => {
                return (
                  <RedirectBtn
                    btnName={button.text}
                    key={button.type}
                    onPress={() => {
                      transferAction(index, button);
                    }}
                  />
                );
              })}
            </View>
          );
        })}
      </View>
    </ScrollView>
  );
};


const styles = {
  cardWrapper: {
    width: screenWidth,
    backgroundColor: '#fff',
    ...getPlatformElevation(2),
    paddingLeft: 16,
    paddingTop: 16,
    marginBottom: 3
  },
  busIconStyle: {
    width: 19,
    height: 21,
    marginRight: 20
  },
  carIconStyle: {
    width: 20,
    height: 19,
    marginRight: 20
  },
  activityInnerDtls: {
    paddingLeft: 40,
    paddingRight: 16,
    marginBottom: 20
  },
  infoTxt: {
    color: '#cf8100',
    fontFamily: fonts.regular,
    fontSize: 14,
    lineHeight: 20,
    fontStyle: 'italic'
  }
};

export default TransferDetailTab;
