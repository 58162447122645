import React from 'react';
import Actions from '../navigation/postSalesNavigation';
import GiftCardBookingDetailsConstant from './GiftCardBookingDetailsConstant';
import CapsuleBtnFill from '../hotel/details/ActionButton/CapsuleBtnFill';
import RectFullButton from '../hotel/details/ActionButton/RectFullButton';
import styles from './GiftCardDetailsCss';
import logAction from '@mmt/legacy-commons/Helpers/actionsLogger';
import GiftCardBookingTrackerHelper from './GiftCardBookingTrackerHelper';

export const getButtonStyle = (actionList) => {
    const {actionType} = actionList[0];
    if (actionType === GiftCardBookingDetailsConstant.RECTANGLE_FULL_BUTTON) {
      return [styles.ActionBtnRec, styles.transperantBgrnd];
    }
    return [styles.ActionBtn, styles.transperantBgrnd];
  }

export const handleClickEvent = (card, action, response, pageName) => {
  logAction(`${pageName} ${card.cardId} ${action.actionFamily}clicked`);
  switch (action.actionFamily) {
    case GiftCardBookingDetailsConstant.ACTION_VIEW_PAYMENT_BREAK_UP:
      Actions.viewGiftCardPaymentBreakup({
        response
      });
      GiftCardBookingTrackerHelper.trackClickEvent(pageName, 'gc_customersupport_farebreakup_clicked');
      break;
    default:
      break;
  }
}

export const getActionButton = (card, clickHandler) => {
  const actionButtons = [];
  for (const action of card.actionList) {
    switch (action.actionType) {
      case GiftCardBookingDetailsConstant.RECTANGLE_FULL_BUTTON:
        actionButtons.push(<RectFullButton
          key={`RectFullButton${action.actionLabeltext}`}
          card={card}
          action={action}
          clickHandler={clickHandler}
        />);
        break;
      case GiftCardBookingDetailsConstant.RECTANGLE_PRIMARY_FULL_WIDTH:
        actionButtons.push(<CapsuleBtnFill
          key={`CapsuleBtnFill${action.actionLabeltext}`}
          card={card}
          action={action}
          clickHandler={clickHandler}
        />);
        break;
      default:
        break;
    }
  }
  return actionButtons;
}

export const isNotNullAndEmpty = (object) => {
  return (object != null && object.length > 0);
}


export const validateTrackingDetails = (trackingDetails) => {
  return trackingDetails != undefined && trackingDetails != null;
}

export const getTypeOfGiftCard = (response) => {
  if(response.giftCardBookingInfo && response.giftCardBookingInfo.giftCardDetails) {
    const deliveryMode = response.giftCardBookingInfo.giftCardDetails.deliveryMode;
    if(deliveryMode === 1) {
      return 'mail';
    } else if(deliveryMode === 2) {
      return 'link';
    } else if(deliveryMode === 3) {
      return 'courier';
    }
  }
  return '';
}