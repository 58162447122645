import React from "react";
import { getFont } from '../../PostSalesStyles';
import { useTheme } from '../../theme/theme.context';
import getStyles from './PopupTriggerInfoCardCss';
import { View, Text, TouchableOpacity, Image } from "react-native";
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';

const PopupTriggerInfoCard = ({
  containerStyle,
  textStyle,
  infoIconImageUrl,
  imageUrl,
  imageStyle,
  text,
  actionParamenter,
  handleBottomSheetOverlay,
  iconImageStyle
}) => {
  const { psTheme } = useTheme();
  const { fsStyle, ...fonts } = getFont(true);
  const styles = getStyles(fonts, psTheme);
  return (
    <View style={[styles.secureInfo, containerStyle]}>
      <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
        <Image style={imageStyle} source={imageUrl} />
        <Text style={[styles.secureInfoTxt, textStyle]}>{text}</Text>
      </View>
      <TouchableOpacity onPress={() => handleBottomSheetOverlay(actionParamenter)}>
        <Image style={[styles.infoImg, iconImageStyle]} source={infoIconImageUrl} />
      </TouchableOpacity>
    </View>
  );
};

export default PopupTriggerInfoCard;
