import React from 'react';
import PropTypes from 'prop-types';
import {View, StyleSheet} from 'react-native';
import { useTheme } from '../../theme/theme.context';
import BaseButton, { BUTTON_VARIANT } from '../BaseButton';
import { fonts } from '@mmt/legacy-commons/Styles/globalStyles';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';


const WhiteFullCapsuleButton = ({ action, clickHandler, isThrottleRequired= false, throttleInterval = 1000 }) => {
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme);
 return (
   <View style={AtomicCss.flex1}>
     <BaseButton
       variant={BUTTON_VARIANT.WHITE_CAPSULE}
       clickHandler={() => clickHandler(action)}
       text={action.actionLabeltext ? action.actionLabeltext.toUpperCase() : ''}
       textStyle={[styles.textStyle, AtomicCss.font12]}
       buttonStyle={styles.whiteBtnFull}
       isThrottleRequired ={isThrottleRequired}
       throttleInterval = {throttleInterval}
     />
   </View>
 );
};


WhiteFullCapsuleButton.propTypes = {
  action: PropTypes.object.isRequired,
  clickHandler: PropTypes.func.isRequired
};

const createStyles = (theme)=>{
  return StyleSheet.create({
    whiteBtnFull: {
      paddingTop: 10,
      paddingBottom: 10,
      marginRight: 10
    },
    textStyle:{
      color: theme.color.primary,
      fontFamily: fonts.black
    }
  })
}

export default WhiteFullCapsuleButton;
