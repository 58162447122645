import React from 'react';
import {View, Text, Image} from 'react-native';
import Actions from '../../../navigation/postSalesNavigation';
import PropTypes from 'prop-types';
import Button from '../../../Common/Button';
import getStyles from './OverlayCss';
import AtomicCss from '../../../acme/details/AtomicCss';
import HotelBookingTrackingHelper from '../../HotelBookingTrackingHelper';
import { getStaticData } from '../../../staticData/staticData';
import { CARD_ICON } from '../../HotelImageConstants';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

class OverlayContent extends React.Component {
  constructor(props) {
    super(props);
    this.fonts = getFont(true);
    this.styles = getStyles(this.fonts);
    this.fsStyle = this.fonts.fsStyle;
  }
  handleChargeCardProcess=(pageName) => {
    HotelBookingTrackingHelper.trackClickEvent(pageName, 'Pay_Same_Mode_Clicked', this.props.responseJson);
    Actions.pahxChargeCard({
      response: this.props.responseJson,
      pageName: pageName
    });
  }

  render() {
    const staticData = getStaticData();
    const cardIcon = CARD_ICON;
    const {
      title, cardType, bankName, cardNo, cvvMessage, pageName, isUPIBooking, maskedCardNo
    } = this.props;
    return (
      <View>
        <Text style={[this.styles.OverlayTitle, AtomicCss.marginBottom20]} >{title}</Text>
        <View>
          <View style={this.styles.bankCardWrapper}>
            <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween, AtomicCss.alignCenter, AtomicCss.marginBottom16]}>
              <Image style={this.styles.cardIconStyle} source={cardIcon} />
              <Text style={[this.fsStyle.font10, AtomicCss.greyText, this.fonts.regularFontFamily]}>{cardType}</Text>
            </View>
            {
              isUPIBooking
              ? null
              : <Text style={[this.fsStyle.font14, this.fonts.blackFontFamily, AtomicCss.blackText, AtomicCss.marginBottom8]}>{bankName}</Text>
            }
            <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom15]}>
              {this.maskCardNo()}
              <Text style={[this.fsStyle.font14, this.fonts.boldFontFamily]}>{maskedCardNo || cardNo}</Text>
            </View>
            <Text style={[AtomicCss.greyText, this.fsStyle.font12, AtomicCss.lineHeight18, this.fonts.regularFontFamily]}>{cvvMessage}</Text>
          </View>
          <View style={[this.styles.btnWrapper, AtomicCss.marginBottom10]}>
            <Button
              btnTxt={`${isUPIBooking ? staticData.payNowViaUPIText : staticData.chargeCardNowText.toUpperCase() }`}
              btnWidth={240}
              clickHandler={() => this.handleChargeCardProcess(pageName)}
            />
          </View>
        </View>

      </View>

    );
  }

  maskCardNo() {
    const {isUPIBooking, maskedCardNo} = this.props;
    if (maskedCardNo) return null;
    const views = [];
    const quadots = 3;
    for (let i = 0; i < quadots; i += 1) {
      views.push(
        <View style={(!isUPIBooking || i === quadots - 1) ? [AtomicCss.flexRow, AtomicCss.marginRight10] : [AtomicCss.flexRow]}>
          <Text style={[this.styles.bulletsPoints]}>{'\u2B24'}</Text>
          <Text style={this.styles.bulletsPoints}>{'\u2B24'}</Text>
          <Text style={this.styles.bulletsPoints}>{'\u2B24'}</Text>
          <Text style={this.styles.bulletsPoints}>{'\u2B24'}</Text>
        </View>
      );
    }
    return views;
  }
}
OverlayContent.propTypes = {
  responseJson: PropTypes.object.isRequired,
  isUPIBooking: PropTypes.bool.isRequired
};
export default OverlayContent;
