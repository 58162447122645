import railsConfig from "../RailsConfig";
import fetch2 from "../fetch2";
import langLabels from "./labels.json";
// import { langLabels } from "./routes";
import { updateFechaWithLang } from "./calendarUtil";
import { getPokusConfig, getPokusConfigWaitingPromise } from '@mmt/legacy-commons/Native/AbConfig/AbConfigModule';
import { setBnplInitLang } from '@mmt/legacy-commons/Common/Components/BookNowPayLater/Components/BnplLabels';
import { getDataFromAsynStorage, setDataToAsyncStorage } from "../Utils/RailsConstant";
import { railsAppTamilVernac } from "../RailsAbConfig";

const RAILS_VERNAC_LANG = "rails_vernac_language_";

export const HIN = "hin";
export const EN = "eng";
export const TAM = "tam";
export const VERNAC_DEFAULT_LANG = EN;
export const languageConfig = {
  [HIN]: {
    id: 'hi',
    beCode: "hi",
    value: "Hindi",
    label: "हिंदी",
    altLang: "eng",
    cookieCode: "hin",
    altLabel: "ENG",
  },
  [EN]: {
    id: 'en',
    beCode: "en",
    value: "English",
    altLang: "hin",
    cookieCode: "eng",
    label: "ENG",
    altLabel: "हिंदी",
  },
  [TAM]: {
    id: 'tam',
    beCode: "tam",
    value: "Tamil",
    label: "Tamil",
    altLang: "eng",
    cookieCode: "tam",
    altLabel: "ENG",
  },
};

const {
  initLanguage,
  _label,
  shouldShowHindiTooltip,
  getRailsLanguageByKey,
  disableVernacular,
  transformString,
} = (function() {
  const fallbackLang = VERNAC_DEFAULT_LANG;
  let detectedLanguage = "";
  let railsLanguageLabels = {};
  const transformers = {
    capitalize: (str) => {
      try {
        return str
          .toLowerCase()
          .split(" ")
          .map((s) => (s[0].toUpperCase() + s.slice(1)))
          .join(" ");
      } catch (e) {
        return str;
      }
    },
    sentenceCase: (str) => {
      try {
        return str[0].toUpperCase() + str.slice(1);
      } catch (e) {
        return str;
      }
    },
    lowercase: (str) => {
      try {
        return str.toLowerCase();
      } catch (e) {
        return str;
      }
    },
    uppercase: (str) => {
      try {
        return str.toUpperCase();
      } catch (e) {
        return str;
      }
    },
    defaultLang: (str, key) => {
      return langLabels[key]; 
    },
    parameterize: (str, params) => {
      return str.replace(/{{\w*}}/ig, (match) => {
        const key = match.replace(/[{}]/ig, '') || "";
        return params[key];
      });
    }
  }

  const isLangSupported = (lang) => {
    return Object.keys(languageConfig).indexOf(lang) > -1
  }

  const getVernacConfig = async (lang) => {
    try {
      const url = `${railsConfig.vernacularConfig}?selectedLang=${languageConfig[lang].beCode}`;
      const response = await fetch2(url);
      const data = await response.json();
      if (data.status !== 'SUCCESS' || Object.keys(data.translations).length === 0) {
        throw new Error('API Error');
      }
      setDataToAsyncStorage(`${RAILS_VERNAC_LANG}${lang}`, JSON.stringify({
        lastUpdatedOn: new Date().getDate(),
        cachedLabels: data.translations,
      }));
      return {
        updatedLang: lang,
        labels: data.translations,
        apiCallFailed : false,
      }
    } catch (err) {
      console.log("Rails Language Api Error ", err.message);
      return {
        updatedLang : fallbackLang,
        labels: langLabels,
        apiCallFailed : true,
      }
    }
  }

  const updateSelectedLanguage = async (lang) => {
    const { updatedLang, labels, apiCallFailed } = await getVernacConfig(lang);
      return {
        updatedLang,
        apiCallFailed,
        labels,
      };
  }

  const getLabelValue = (str, options, params) => {
    try {
      let label = railsLanguageLabels[str] || langLabels[str] || str;
      if (options) {
        const keys = Object.keys(options);
        keys.forEach(k => {
          label = options[k] ? transformers[k](label, str) : label;
        });
      }
      if (params) {
        label = transformers.parameterize(label, params);
      }
      return label.trim();
    } catch (e) {
      return str;
    }
  }

  const transformString = (str, option, params) => {
    return transformers[option](str)
  }

  const shouldShowHindiTooltip = (isSeo = false) => {
    return isSeo && detectedLanguage === EN;
  }

  const updateLanguageLabels = (lang, labels) => {
    railsLanguageLabels = {
      ...langLabels,
      ...labels,
    }
  }

  const init = async (lang = "eng", refreshLanguage) => {
    const enableVernac = await isAbOnForVernac() && isLangSupported(lang);
    detectedLanguage = enableVernac ? lang : fallbackLang;

    try {
      const labelData = await getDataFromAsynStorage(`${RAILS_VERNAC_LANG}${detectedLanguage}`);
      const labelDataInJSON = JSON.parse(labelData);
      const { lastUpdatedOn, cachedLabels } = labelDataInJSON;

      if (Object.keys(cachedLabels).length > 0) {
        updateLanguageLabels(detectedLanguage, cachedLabels);
        updateFechaWithLang(detectedLanguage);
        setBnplInitLang(detectedLanguage);
        refreshLanguage({
          language: detectedLanguage,
          status: "LOADED_FROM_CACHE",
        });
        if (!lastUpdatedOn === new Date().getDate()){
          refreshVernac(detectedLanguage, refreshLanguage);
        }
      }
    } catch (e) {
      if (detectedLanguage === EN){
        updateLanguageLabels(fallbackLang, langLabels);
        updateFechaWithLang(fallbackLang);
        setBnplInitLang(fallbackLang);
        refreshLanguage({
          language: fallbackLang,
          status: "LOADED_FROM_CACHE",
        });
        refreshVernac(detectedLanguage, refreshLanguage);
      } else {
        await refreshVernac(detectedLanguage, refreshLanguage);
      }
    }
  }

  const refreshVernac  = async(detectedLanguage, refreshLanguage) => {
    const {
      updatedLang,
      labels,
      apiCallFailed
    } = await updateSelectedLanguage(detectedLanguage);

    detectedLanguage = updatedLang;
    updateLanguageLabels(updatedLang, labels);

    updateFechaWithLang(updatedLang);
    setBnplInitLang(updatedLang);
    refreshLanguage({
      language: updatedLang,
      status: "DONE",
      apiCallFailed
    });
  }

  const getRailsLanguageByKey = (key) => {
    return languageConfig[detectedLanguage || fallbackLang][key];
  }

  const isAbOnForVernac = async (lang) => {
    if (lang !== TAM) {
      return true;
    }
    return railsAppTamilVernac();
  }

  return {
    initLanguage: init,
    _label: getLabelValue,
    shouldShowHindiTooltip: shouldShowHindiTooltip,
    getRailsLanguageByKey: getRailsLanguageByKey,
    disableVernacular: disableVernacular,
    transformString: transformString,
  }
})();

export {
  _label,
  shouldShowHindiTooltip,
  getRailsLanguageByKey,
  disableVernacular,
  transformString,
};

export default initLanguage;
