import React from 'react';
import { View, Image, Text, ViewStyle } from 'react-native';
import createStyle from './style';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { LEFT_LEAF_ICON, RIGHT_LEAF_ICON, GREY_STAR_ICON } from '../../HotelImageConstants';
import { useTheme } from '../../../theme/theme.context';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

interface AltAccoRatingComponentProps {
  category: string;
  customStyles?: ViewStyle;
}

const AltAccoRatingComponent: React.FC<AltAccoRatingComponentProps> = ({category, customStyles}) => {
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyle(theme);

  return (
    <View style={[styles.altAccoPropertyStarWpr, customStyles]}>
      <Image style={styles.leftLeafImg} source={LEFT_LEAF_ICON} />
      <Text style={[fsStyle.font14, fonts.semiBoldFontFamily, styles.hotelCategoryStyle]}>{category}</Text>
      <Image style={styles.altAccoStarStyle} source={GREY_STAR_ICON} />
      <Image style={styles.rightLeafImg} source={RIGHT_LEAF_ICON} />
    </View>
  );
};

export default AltAccoRatingComponent;
