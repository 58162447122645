import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, Image, StyleSheet, TouchableOpacity } from 'react-native';
import { getRoundIcon } from '../../../../../hotel/details/utils/HotelBookingDetailUtil';
import { getFont } from '../../../../../PostSalesStyles';
class ButtonWithIcon extends React.Component {
  render() {
    const fonts = getFont(true);
    return (
      <TouchableOpacity onPress={() => this.props.clickHandler(this.props.action)}>
        <View style={styles.SupportTxt}>
          <Image
            style={[styles.RbBtnWithIconImg, styles.mar3Bt]}
            source={getRoundIcon(this.props.action.actionFamily)}
          />
          <Text style={[styles.RbBtnWithIconText, fonts.boldFontFamily]}>
            {this.props.action.actionLabeltext}
          </Text>
        </View>
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  SupportTxt: {
    flex: 1,
    paddingLeft: 6,
    paddingRight: 6,
  },
  RbBtnWithIconImg: {
    width: 36,
    height: 36,
    alignSelf: 'center',
  },
  mar3Bt: {
    marginBottom: 3,
  },
  RbBtnWithIconText: {
    textAlign: 'center',
    color: '#008cff',
    lineHeight: 20,
  },
});

export default ButtonWithIcon;

ButtonWithIcon.propTypes = {
  action: PropTypes.object.isRequired,
  clickHandler: PropTypes.func.isRequired,
};
