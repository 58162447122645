import React from "react";
import {View} from "react-native";
import CabDatePicker from "./CabDatePicker";
import WheelPicker from "./widget";
import Fecha from 'fecha';
import { getFormattedDateTime} from "./CancelAndRebookUtill";
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import Header from "../../../Common/HeaderCross";
import RecBlueBtn from '@mmt/legacy-commons/Common/Components/Buttons/RecBlueBtn';
import {CancelAndRebookConstants} from './CancelAndRebookConstants';
import Actions from '../../../navigation/postSalesNavigation';
import { nativeLog } from "../../../utils/PostSaleUtil";
import isEmpty from "lodash/isEmpty";
import CabBookingTrackingHelper from "../../CabBookingTrackingHelper";
import { CLICK_EVENT, PAGE_NAME } from "../../CabBookingDetailsConstant";

class ChangeDate extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            formData : this.props.formData,
            fromDate: this.props.formData.fromDate.date,
            toDate: this.props.formData.toDate && this.props.formData.toDate.date,
            date : this.props.isPickUp ? (this.props.formData.fromDate.date) : (this.props.formData.toDate.date),
            newDate : ''
        }
        this.onDateChange=this.onDateChange.bind(this);
        this.isPickup = this.props.isPickUp
        this.showTxtBtnTxt = (this.props.isPickUp && this.props.isRoundTrip);
        this.tripStatusChanged = this.props.tripStatusChanged || false
    }

    updateFormData(epochDate, isPickup){

        if(isPickup){
            this.setState((prevData) => ({
                formData : {
                    ...prevData.formData,
                    fromDate:{
                        date: epochDate,
                        month:getFormattedDateTime(epochDate,'MMM'),
                        day:getFormattedDateTime(epochDate,'DD'),
                        week:getFormattedDateTime(epochDate,'ddd'),
                        time:getFormattedDateTime(epochDate,'hh:mm A'),
                        year: getFormattedDateTime(epochDate,'YYYY')
                    }
                }
            }))
        }else{
            this.setState((prevData) => ({
                formData : {
                    ...prevData.formData,
                    toDate:{
                        date: epochDate,
                        month:getFormattedDateTime(epochDate,'MMM'),
                        day:getFormattedDateTime(epochDate,'DD'),
                        week:getFormattedDateTime(epochDate,'ddd'),
                        time:getFormattedDateTime(epochDate,'hh:mm A'),
                        year: getFormattedDateTime(epochDate,'YYYY')
                    }
                }
            }))
        }
    }

    onDateChange(valueGroup){
        const {bookingId} = this.props.response || {};
        const {date, time, meridiem} = valueGroup || {};
        if(!isEmpty(date) && !isEmpty(time) && !isEmpty(meridiem)){
            let newDate = date + ' ' + time + ' ' + meridiem;
            let epoc = Fecha.parse(newDate , 'DD MMM YYYY,ddd hh : mm A').valueOf();
            nativeLog((`epoc : ${JSON.stringify(epoc)}, bookingId : ${bookingId}, valueGroup : ${JSON.stringify(valueGroup)}`));
            this.updateFormData(epoc,this.isPickup)
        }
    }

    onBackPress(){
        Actions.pop();
    };

    getTheUpdatedDate(updateDateData, isPickUp){
       this.setState({formData:updateDateData})
        Actions.cabModifyBooking({response : this.props.response, keyValue : 'date', formData : updateDateData, tripStatusChanged: this.tripStatusChanged})
    }

    closeReturnDateOverlay = (updateDateData) => {
        this.setState({formData:updateDateData})
        Actions.cabModifyBooking({response : this.props.response, keyValue : 'date', formData : updateDateData})
    }



    onClickOfDone = (updateDateData, isPickUp, key,changeDateCallback) => {
        CabBookingTrackingHelper.trackClickEvent(PAGE_NAME.MODIFY_BOOKING, CLICK_EVENT.DATE_TIME_CHANGE_DONE_CLICK);
        if(key === "getTheUpdatedDateCheck"){
            changeDateCallback(updateDateData);
        }
        else if(key === "getTheUpdatedDate"){
            this.getTheUpdatedDate(updateDateData, isPickUp);
        }else if(key === "closeReturnDateOverlay"){
            this.closeReturnDateOverlay(updateDateData);
        }
    }

    render() {
        const {bookingId} = this.props.response || {};
        return(
            <View style={[AtomicCss.flex1, AtomicCss.greyBg]}>
                <Header
                    title="Modify Booking"
                    headerShadow
                    headingTxt="font18"
                    headingSubTxt="font12"
                    handleClose={this.onBackPress}
                />
                <CabDatePicker isPickUp={this.props.isPickUp} formData={this.state.formData} showReturnDate={this.props.showReturnDate}/>
                <WheelPicker isPickUp={this.props.isPickUp} date={this.state.date} onDateChange={this.onDateChange}
                             title={(this.props.isPickUp)? (CancelAndRebookConstants.MODIFY_DATE_TEXT) : (CancelAndRebookConstants.MODIFY_END_DATE_TEXT)} formData={this.state.formData}
                             bookingId={bookingId}/>
                <View style={{position: 'absolute', bottom:0, height:60, width:'100%'}}>
                <RecBlueBtn label={(this.showTxtBtnTxt)?(CancelAndRebookConstants.NEXT):(CancelAndRebookConstants.DONE)} onPress={() => {this.onClickOfDone(this.state.formData, this.props.isPickUp, this.props.methodToCall,this.props.changeDateCallback)}}/>
                </View>
            </View>
        )
    }

}
export default ChangeDate;
