import React from 'react';
import {Image, Text, View, Platform} from 'react-native';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {convertStringtoTitleCase} from '@mmt/legacy-commons/Common/utils/StringUtils';
import {getActionButton, getButtonStyle} from '../../../hotel/details/utils/HotelBookingDetailUtil';
import styles from '../../CSS/VisaMamiCss';
import VisaDetailConstant from '../../Constant/VisaDetailConstant';
import {handleClickEvent} from '../../Util/VisaUtil';
import {guestIcon, infoIcon} from '../../Constant/VisaImageConstant';


class VisaApplicantCard extends React.Component {
    static navigationOptions = {
      header: null
    };
    render() {
      const travellerInfo = this.props.response.passengerList;
      const colorScheme = this.props.response.colorScheme;
      return (
        <View style={[styles.MamiCard, styles.mar10Bt]}>
          <View style={styles.applicatCardContent}>
            <View>
              <View style={styles.mar15Bt}>
                <Image style={styles.CardIcon} source={guestIcon} />
              </View>
              <View>
                <Image style={[this.isAdditionalDocumentsRequired() ? styles.showView : styles.hideView, styles.infoRed]} source={infoIcon} />
              </View>

            </View>
            <View style={styles.ContentPortion}>
              <Text style={[styles.BlackText,
                  styles.blackFont,
                  styles.font14,
                  styles.mar15Bt,
                  styles.PolicyHeadingText]}
              >
                {travellerInfo.length} APPLICANTS
              </Text>
              <View style={styles.infoSection}>
                    <Text style={[styles.infoSectionTxt, styles[colorScheme]]}>
                        {this.props.response.applicantCardMsg}
                    </Text>
                </View>
              <Text style={[
                  this.isAdditionalDocumentsRequired() ? styles.showView : styles.hideView,
                  styles.additionalCard
                  ]}
              >
                      If documents are incomplete, our executives will call & help in completing the procedure .
              </Text>

              {this.addTravellers(travellerInfo)}
            </View>

          </View>
          <View
            style={this.props.card != null ? !_.isEmpty(this.props.card.actionList) ? getButtonStyle(this.props.card.actionList) : styles.hideView : styles.hideView}
          >


            {this.props.card != null ? getActionButton(this.props.card, this.props.response, this.handleClick) : null}
          </View>
        </View>
      );
    }

    getUpdatedCard = () => {
      if (Platform.OS === 'ios') {
        const card = {...this.props.card};
        const actionList = [];
        this.props.card.actionList.forEach((action) => {
          if (action.actionFamily !== VisaDetailConstant.VISA_DOWNLOAD_VISA) {
            actionList.push(action);
          }
        });
        card.actionList = actionList;
        return card;
      }
      return this.props.card;
    }

    handleClick = (action) => {
      handleClickEvent(this.props.card, action, this.props.response, this.props.pageName, this.props.payRefId);
    };

    addTravellers(travellerInfo) {
      const views = [];
      travellerInfo.forEach((passengerDetail, index) => {
        this.addTravellerCard(views, passengerDetail, index);
      });
      return views;
    }
    addTravellerCard(views, passengerDetail, index) {
      views.push(<View key={passengerDetail.paxid} style={[styles.mar24Bt]}>
        {this.getPassengerDetails(views, passengerDetail)}
        {this.getSubMessage(index, views, passengerDetail)}
      </View>);
    }

    getPassengerDetails(views, passengerDetail) {
      views.push(<View key={`${passengerDetail.paxid} name`} style={[styles.flexRow]}>
        <Text style={[styles.passengerName]}>{convertStringtoTitleCase(`${passengerDetail.passengerName.firstname} ${passengerDetail.passengerName.surname}`)} &nbsp;
        </Text>
        <Text style={[styles.passengerGender]}> {passengerDetail.age}yr, {convertStringtoTitleCase(passengerDetail.gender)}
        </Text>
                 </View>);
    }

    getSubMessage(index, views, passengerDetail) {
      views.push(<View key={`${passengerDetail.paxid} message`} style={styles.flexRow}>
        <Text style={[styles.flexStart, styles.font11, this.getSubMessageColor(passengerDetail.countryVisaStatusList[0].visaStatusDescription[0],passengerDetail.colour), this.getSubMessagetextStyle(passengerDetail.countryVisaStatusList[0].visaStatusDescription[0])]}>
          {passengerDetail.countryVisaStatusList[0].visaStatusDescription[0]} &nbsp;
        </Text>
        <Text style={[
            styles.flexStart,
            styles.font11,
            styles.italic,
            this.getSubMessageColor(passengerDetail.countryVisaStatusList[0].visaStatusDescription[1], passengerDetail.colour),
            this.getSubMessagetextStyle(passengerDetail.countryVisaStatusList[0].visaStatusDescription[1])]}
        >
          {passengerDetail.countryVisaStatusList[0].visaStatusDescription[1]}
        </Text>
                 </View>);
    }

    isAdditionalDocumentsRequired() {
      if (this.props.response.additionalDocumentRequired) { return true; }
      return false;
    }

    getSubMessageColor(visaDescription, color) {
      if(!_.isEmpty(color)){
        return styles[color];
      }
      if (!_.isEmpty(visaDescription)) {
        const visaDesc = visaDescription.toLowerCase();
        if (visaDesc.includes(VisaDetailConstant.UNDER_PROCESS)) {
          return styles.darkGrey;
        }
        if(visaDesc.includes(VisaDetailConstant.DOCUMENTS_COMPLETE)|| visaDesc.includes(VisaDetailConstant.DOCUMENTS_UPLOADED)){
          return styles.greenText;
        }
        if (visaDesc.includes(VisaDetailConstant.VISA_DENIED)
        || visaDesc.includes(VisaDetailConstant.VISA_DELAYED)
        || visaDesc.includes(VisaDetailConstant.DOCUMENTS_INCOMPLETE)
        ) {
          return styles.redText;
        }
        if (visaDesc.includes(VisaDetailConstant.VISA_EXPECTED)
        || visaDesc.includes(VisaDetailConstant.VISA_APPROVED)
        ) {
          return styles.lightGreenTxt;
        }
      }
    }

    getSubMessagetextStyle(visaDescription) {
      if (!_.isEmpty(visaDescription)) {
        const visaDesc = visaDescription.toLowerCase();
        if (visaDesc.includes(VisaDetailConstant.VISA_DENIED)
            ||
            visaDesc.includes(VisaDetailConstant.DOCUMENTS_INCOMPLETE)
        || visaDesc.includes(VisaDetailConstant.VISA_EXPECTED)
        || visaDesc.includes(VisaDetailConstant.VISA_APPROVED)
        ) {
          return styles.boldFont;
        }
      }

      return styles.regularFont;
    }
}


VisaApplicantCard.propTypes = {
  response: PropTypes.object.isRequired,
  card: PropTypes.object.isRequired,
  pageName: PropTypes.string.isRequired
};

export default VisaApplicantCard;
