export const MEALS = 'MEALS';
export const BAGGAGE = 'BAGGAGE';
export const SEATS = 'SEATS';

export const ANCILLARY_TYPE = {
  4: 'Seat',
  0: 'Meal',
  1: 'Baggage',
  7: 'Web Check-in',
};

export const PAYMENT_EVENT_LISTNER = 'flight_booking_details_ancillary_thankyou_page';