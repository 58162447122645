import React from 'react';
import HTMLView from 'react-native-htmlview';
import PropTypes from 'prop-types';
import { Image, Text, TouchableOpacity, View } from 'react-native';
import styles from './tripProtectSegCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {
  BLUE_DOWN_ARROW,
  BLACK_RIGHT_ARROW,
  DELAY_ICON,
  DISRUPT_FLT_IMG,
  MISS_FLT_IMG,
  DIGIT_IMG,
  ASSURANCE_ICON,
} from '../../../../FlightImageConstants';
import { getFont } from '../../../../../PostSalesStyles';

const EXPANDED = 1;
const COLLAPSED = 0;
class TripProtectionSegment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentState: EXPANDED,
    };
  }

  productImage(type) {
    switch (type) {
      case 'MMTFD':
        return DELAY_ICON;
      case 'MMTNS':
        return MISS_FLT_IMG;
      case 'MMTFC':
        return DISRUPT_FLT_IMG;
      case 'MMTPK':
        return ASSURANCE_ICON;
      default:
        return MISS_FLT_IMG;
    }
  }

  toggleDropDown() {
    const newState = 1 - this.state.currentState;
    this.setState({
      currentState: newState,
    });
  }

  render() {
    const { journey } = this.props;
    const { fsStyle, ...fonts } = getFont(true);
    const hideShadow = this.state.currentState === COLLAPSED ? [styles.hideshadow] : '';
    const dropDownArrowStyle =
      this.state.currentState === EXPANDED
        ? [styles.arrowActiveStyle, styles.blueArrowStyle]
        : [styles.blueArrowStyle];

    return (
      <View style={[styles.whiteWrapper, hideShadow]}>
        <View style={styles.cancInfoHeading}>
          <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom5]}>
            {this.getSegmentIntentView(journey.intentName)}
          </View>
          <Text style={[fsStyle.font12, AtomicCss.defaultText, fonts.regularFontFamily]}>
            {journey.travelDate && journey.travelDate.concat(', ').concat(journey.airlineName)}
          </Text>
          <TouchableOpacity
            style={[styles.arrowWrapper, AtomicCss.alignCenter]}
            onPress={() => this.toggleDropDown()}
          >
            <Image style={[dropDownArrowStyle]} source={BLUE_DOWN_ARROW} />
          </TouchableOpacity>
        </View>
        {this.state.currentState === EXPANDED && (
          <View style={styles.collapsedDetails}>
            {journey.products &&
              journey.products.map((product) => {
                const htmlContent = '<p>'.concat(product.desc).concat('</p>');
                return (
                  <View
                    style={[
                      AtomicCss.flexRow,
                      AtomicCss.flex1,
                      AtomicCss.alignCenter,
                      AtomicCss.marginBottom15,
                    ]}
                  >
                    {this.productImage(product.code) !== null && (
                      <Image
                        style={styles.digitIconStyle}
                        source={this.productImage(product.code)}
                      />
                    )}
                    <View style={AtomicCss.flex1}>
                      <View style={{ flexDirection: 'row' }}>
                        <Text
                          style={[
                            fonts.blackFontFamily,
                            AtomicCss.blackText,
                            fsStyle.font12,
                            AtomicCss.marginBottom3,
                          ]}
                        >
                          {product.name} {product.code === 'MMTFD' ? 'by' : ''}{' '}
                        </Text>
                        {product.code === 'MMTFD' && (
                          <Image style={styles.digitImg} source={DIGIT_IMG} />
                        )}
                      </View>
                      <HTMLView value={htmlContent} stylesheet={styles} />
                    </View>
                  </View>
                );
              })}
          </View>
        )}
      </View>
    );
  }

  getSegmentIntentView = (segmentIntent) => {
    const fonts = getFont(true);
    const segmentCityArr = segmentIntent.split('-');
    return (
      <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
        <Text style={[AtomicCss.blackText, fonts.blackFontFamily]}>{segmentCityArr[0]}</Text>
        <Image style={styles.arrowBlkStyle} source={BLACK_RIGHT_ARROW} />
        <Text style={[AtomicCss.blackText, fonts.blackFontFamily]}>{segmentCityArr[1]}</Text>
      </View>
    );
  };
}

export default TripProtectionSegment;

TripProtectionSegment.propTypes = {
  journey: PropTypes.object.isRequired,
};
