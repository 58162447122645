import React from 'react';
import { View, Text, Image, TouchableOpacity, StyleSheet } from 'react-native';
import BasePage from '../../../../../Common/PostSalesBasePage';
import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';
import { convertStringtoTitleCase } from '@mmt/legacy-commons/Common/utils/StringUtils';
import { AIRLINE_PHONE_ICON } from '../../../../FlightImageConstants';
import { openDialer } from '../../../../../Common/commonUtil';
import { getFont } from '../../../../../PostSalesStyles';

class AirlineContactCard extends BasePage {
  static navigationOptions = {
    header: null,
  };

  render() {
    const { item } = this.props;
    const { fsStyle, ...fonts } = getFont(true);
    if (!item.airLineContactNo) {
      return null;
    }

    return (
      <React.Fragment>
        <View style={styles.headingWrapper}>
          <Image style={styles.iconStyle} source={{ uri: item.airlineLogoUrl }} />
          <Text style={[styles.airLineNameTxt, fonts.blackFontFamily, fsStyle.font18]}>
            {convertStringtoTitleCase(item.airLineName)}
          </Text>
        </View>
        <View style={[styles.whiteWrapper]}>
          {this.getContactNoList(item.airLineContactNo).map((contactNo) => (
            <TouchableOpacity
              style={styles.airLineContactListRow}
              activeOpacity={0.5}
              onPress={() => openDialer(contactNo)}
            >
              <Text style={[styles.textStyle, fonts.boldFontFamily, fsStyle.font14]}>
                {contactNo}
              </Text>
              <Image style={styles.phoneIcon} source={AIRLINE_PHONE_ICON} />
            </TouchableOpacity>
          ))}
        </View>
      </React.Fragment>
    );
  }

  getContactNoList(airLineContactNos) {
    return airLineContactNos.split(',');
  }
}
const styles = StyleSheet.create({
  whiteWrapper: {
    backgroundColor: '#ffffff',
    marginBottom: 10,
    ...getPlatformElevation(1),
  },
  headingWrapper: {
    ...getPlatformElevation(2),
    backgroundColor: '#fff',
    padding: 16,
    marginBottom: -1,
    flexDirection: 'row',
  },
  airLineContactListRow: {
    padding: 16,
    borderBottomColor: '#e7e7e7',
    borderBottomWidth: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: 50,
  },
  airLineNameTxt: {
    color: '#000',
  },
  textStyle: {
    color: '#4a4a4a',
  },
  iconStyle: {
    width: 24,
    height: 24,
    marginRight: 15,
  },
  phoneIcon: {
    width: 18,
    height: 18,
  },
});
export default AirlineContactCard;
