import IndigoTheme from '../IndigoTheme';
import { ps_colors } from '../PostSalesStyles';


// TODO: we will remove IndigoTheme once we add unique colors in this file and migrate all lobs to use colors from theme

const INDIGO_COLOR_THEME = {
  ...IndigoTheme,
  ...ps_colors,
  //   Add unique colors here
  primary: '#001B94',
  primaryGradient:['#001B94',"#001B94"],
  filterTextColor:"#fff",
  filterActiveBorderColor:"#fff",
  lightPrimaryColor:"#eaf5ff",
  linkButtonTextColor:"#001B94",
  primaryBorderLight:"#b5dcff",
};

export const INDIGO_THEME = {
  id: "6eholidays",
  color: INDIGO_COLOR_THEME,
};
