import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { fonts, colors } from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';

const PackageDetailsMinimal = ({
    bookingSummary
}) => {
    return (
        <View style={styles.card}>
            <View style={styles.header} >
                <Text style={styles.title}>{bookingSummary.packageName}</Text>
            </View>
            {
                bookingSummary.message &&
                <View style={styles.badge}>
                    <Text style={styles.badgeText}>
                        {bookingSummary.message}
                    </Text>
                </View>
            }
        </View>
    );

}

const styles = StyleSheet.create({
    card: {
        backgroundColor: colors.white,
        marginBottom: 8,
        ...getPlatformElevation(2),
    },
    header: {
        paddingHorizontal: 16,
        paddingVertical: 12,
        backgroundColor: colors.white,
        ...getPlatformElevation(2)
    },
    title: {
        color: colors.black,
        fontFamily: fonts.bold,
        fontSize: 16
    },
    subTitle: {
        color: colors.black,
        fontFamily: fonts.regular,
        fontSize: 12,
        marginTop: 3
    },
    badge: {
        backgroundColor: colors.skyBlue4,
        padding: 10,
        borderRadius: 4,
        marginHorizontal: 16,
        marginVertical: 12
    },
    badgeText: {
        color: colors.lightPurple,
        fontFamily: fonts.bold,
        fontSize: 12,
    }
})

export default PackageDetailsMinimal;
