import React from 'react';
import { Text, TouchableOpacity, Image, ImageSourcePropType } from 'react-native';
import createStyles from './style';
import allFilterIcon from '@mmt/legacy-assets/src/allFilterIcon.png';
import downArrowIcon from '@mmt/legacy-assets/src/downArrowIcon.png';
import { FILTERS } from '../../../../summaryConstants';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

interface Tab {
  id: string;
  title: string;
  type: string;
  iconStyle: string;
}

interface TabsProps {
  index: number;
  isLastItem: boolean;
  disable: number;
  onHandleTabChange: (index: number, id: string, type: string) => void;
  item: Tab;
  activeTab: number;
}

const Tabs: React.FC<TabsProps> = (props) => {
  const { index, isLastItem, disable, onHandleTabChange, item, activeTab } = props;
  const { psTheme: theme } = useTheme();
  const fonts = getFont(true);
  const styles = createStyles(theme, fonts);
  const tabStyle = [styles.tabStyle];
  const tabStyleTxt = [styles.tabStyleTxt];
  const isFirstItem = isLastItem ? "firstElement" : "";

  if (index === activeTab) {
    tabStyle.push(styles.tabActive);
    tabStyleTxt.push(styles.tabTxtActive);
  }

  const disabledState: boolean = index === disable;

  if (disabledState) {
    tabStyleTxt.push(styles.disabledTxt);
  }

  return (
    <>
      <TouchableOpacity
        style={[tabStyle, styles[isFirstItem]]}
        onPress={() => onHandleTabChange(index, item.id, item.type)}
        disabled={disabledState}
      >
        <Text style={tabStyleTxt}>{props.item.title}</Text>
        {props.item.id === FILTERS.ALL_FILTERS ? (
          <Image source={allFilterIcon as ImageSourcePropType} style={styles.allFilterTabIconStyle} />
        ) : (
          <Image source={downArrowIcon as ImageSourcePropType} style={styles.downArrowIconStyle} />
        )}
      </TouchableOpacity>
    </>
  );
};

export default Tabs;