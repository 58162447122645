import React, {useEffect, useRef} from 'react';
import { Animated, Easing, StyleSheet, View } from 'react-native';
import LottieView from 'lottie-react-native';

const SuccessLoaderAnim = () => {
  const progress = useRef(new Animated.Value(0)).current;
  useEffect(()=>{
    Animated.timing(progress, {
      toValue: 1,
      duration: 1000,
      easing: Easing.linear,
    }).start();
  }, []);

  return (
    <View style={styles.animationContainer}>
      <LottieView
        progress={progress}
        style={{
          width: 64,
          height: 64,
        }}
        source={require('@mmt/legacy-assets/src/LottieAnimations/SuccessTick/data.json')}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  animationContainer: {
    //backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    marginBottom:16
  }
});

export default SuccessLoaderAnim;