import _, { isEmpty } from 'lodash';
import React from 'react';
import { View, Text, Image, Platform, TouchableOpacity, NativeModules } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import styles from './SwipeCardCss';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import { CYT_GREEN_TICK_ICON } from '../../../../../FlightImageConstants';
import FlightBookingTrackingHelper from '../../../helper/FlightBookingTrackingHelper';
import { getFont } from '../../../../../../PostSalesStyles';
import { _getFltTrackData } from '../../../../../FlightOmnitureTrackerUtils';
import { getGdprData, shouldShowGdprBlockerScreen } from '../../../../../../utils/PostSaleUtil';
import { getStaticData } from '../../../../../../staticData/staticData';

const SwipeCard = ({ listData, scrollToView, callDeepLinkCYT, bookingID, ...props }) => {
  const { fsStyle, ...fonts } = getFont(true);
  const FltTrackData = _getFltTrackData();
  const handleSwipeCardPress = async (item) => {
    if (item.deepLink) {
      if (Platform.OS !== 'web') {
        const { UserSessionModule } = NativeModules;
        const showGdprBlockerScreen = await shouldShowGdprBlockerScreen();
        if(showGdprBlockerScreen && item.lobName === 'CABS' ){
          const { gdprText } = getStaticData(false);
          const { lobs: { cabs = 'Cabs'} = {} } = gdprText || {} 
          UserSessionModule.openGDPRBlockerScreen(cabs, null, getGdprData(gdprText));
        }else{
        FlightBookingTrackingHelper.trackClickEventForCYT(
          'Details',
          `${item.lobName.toLowerCase()}_addon_clicked_${
            item.isCompleted ? 'checked' : 'unchecked'
          }`,
          null,
          !isEmpty(FltTrackData) ? FltTrackData.ominatureValue : '',
        );
        GenericModule.openDeepLink(item.deepLink);
        }
      }
    } else if (item.isCompleted) {
      scrollToView(item.cardValue, item);
    } else {
      callDeepLinkCYT(item.cardValue, item);
    }
  };
  return (
    <>
      {listData.map((item, index) => (
        <TouchableOpacity
          key={index}
          activeOpacity={0.5}
          onPress={() => handleSwipeCardPress(item)}
        >
          <View style={styles.rowFront}>
            <View style={styles.rowView}>
              <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
                {!isEmpty(item.itemIcon) && (
                  <Image source={{ uri: item.itemIcon }} style={styles.iconStyle} />
                )}
                <View>
                  {!isEmpty(item.text) && (
                    <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
                      <Text
                        style={[
                          styles.rowText,
                          fonts.regularFontFamily,
                          !isEmpty(item.subText) && {
                            fontFamily: fonts.bold,
                            color: colors.black,
                            fontWeight: 'bold',
                          },
                          fsStyle.font12,
                        ]}
                      >
                        {item.text}
                      </Text>
                      {item.isCompleted ? (
                        <Image source={CYT_GREEN_TICK_ICON} style={styles.greenTickStyle} />
                      ) : (
                        <></>
                      )}
                    </View>
                  )}
                  <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
                    {!isEmpty(item.subTextIcon) && (
                      <Image source={{ uri: item.subTextIcon }} style={styles.calcIconStyle} />
                    )}
                    {!isEmpty(item.subText) && (
                      <Text style={[styles.rowSubTxt, fonts.regularFontFamily, fsStyle.font12]}>
                        {item.subText}{' '}
                      </Text>
                    )}
                  </View>
                </View>
              </View>
              <View style={styles.rightArrow}></View>
            </View>
          </View>
        </TouchableOpacity>
      ))}
    </>
  );
};
export default SwipeCard;
