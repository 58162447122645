import React from 'react';
import { Text, TouchableOpacity, View, Image } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import rightArrowIcon from '@mmt/legacy-assets/src/ic_arrow.webp';
import createStyle from "./style";
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { MyRequestsCardDataInterface } from '../../../types';
import { openWebView } from 'apps/post-sales/src/Common/MSR/Utils';
import TripSummaryOmnitureTracker from '../../../TripSummaryOmnitureTracker';


interface MSRTripsCardProps{
    myRequestsCardData: MyRequestsCardDataInterface
}
const MSRTripsCard = ({ myRequestsCardData }:MSRTripsCardProps) => {
    const { myRequestText, msrPageURL, myRequestIcon, myRequestCount } = myRequestsCardData || {};
    const { psTheme: theme } = useTheme();
    const fonts = getFont();
    const styles = createStyle(theme, fonts);

    const openSupportWebView = () => {
        TripSummaryOmnitureTracker.trackNormalClickEvent('msr', 'my_requests_click');
        openWebView(msrPageURL)
    };
    return (
        <TouchableOpacity style={styles.myRequestCard} onPress={openSupportWebView}>
            <View style={[AtomicCss.flex1, AtomicCss.flexRow]}>
                <View style={AtomicCss.marginRight10}>
                    <Image style={styles.requestIconStyle} source={{ uri: myRequestIcon }} />
                </View>
                <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
                    <Text style={styles.myRequestTextStyle}>{myRequestText}</Text>
                    <View style={styles.dot} />
                    <Text style={styles.openStates}>{myRequestCount}</Text>
                </View>
            </View>
            <Image style={styles.rightArrowStyle} source={rightArrowIcon} />
        </TouchableOpacity>
    );
};


export default MSRTripsCard;
