import React from 'react';
import {View, Text, Image, TouchableWithoutFeedback, TouchableHighlight, Dimensions} from 'react-native';
import Actions from '../../navigation/postSalesNavigation';
import LinearGradient from 'react-native-linear-gradient';
import BasePage from '../../Common/PostSalesBasePage';
import getStyles from '../styles/BusMamiCss';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import ProgressView from '@mmt/legacy-commons/Common/Components/Progress/ProgressView';
import SimpleHeader from '@mmt/legacy-commons/Common/Components/Header/SimpleHeader';
import NoInternetView from '@mmt/legacy-commons/Common/Components/Error/NoInternetView';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import BusCancellationInfoStrip from './BusCancellationInfoStrip';
import ChooseRefundModeCard from './ChooseRefundModeCard';
import CapsuleBtnFill from '../../hotel/details/ActionButton/CapsuleBtnFill';
import {isNetworkAvailable} from '@mmt/legacy-commons/Common/utils/AppUtils';
import logAction from '@mmt/legacy-commons/Helpers/actionsLogger';
import {getSubTitleForCancellationHeader} from '../utils/BusBookingDetailUtil';
import StickyHeader from '../../hotel/details/components/StickyHeader';
import {getCommonHeaders, BUS_CANCELLATION_URL} from '../../utils/NetworkUtils';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import BusBookingTrackingHelper from '../BusBookingTrackingHelper';
import BusBookingDetailsConstant from '../BusBookingDetailsConstant';
import {headerIcon, cancellingIcon} from '../BusImageConstants';

export const Choose_Bus_Refund_PAGE = 'ChooseBusRefund';
export default class ChooseBusRefund extends BasePage {
  static navigationOptions = {
    header: null
  }

  constructor(props) {
    super(props, Choose_Bus_Refund_PAGE);
    this.state = {
      overlay: false,
      selectedRefundMode: 'RefundToCustomer',
      viewState: ViewState.SHOW_DETAIL
    };

    this.penaltyResponse = this.props.penaltyResponse;
    this.cancellationResponse = null;
    this.errorText = null;
    this.bookingId = this.props.response.bookingId;
    this.busBookingInfo = this.props.response.busBookingInfo;
    this.mmtAuth = props.mmtAuth;
    this.loggingTrackingInfo = props.loggingTrackingInfo;
  }


  render() {
    const styles = getStyles();
    return (
      <View style={styles.flex1}>
        {this.state.viewState === ViewState.LOADING && this.renderProgressView()}
        {this.state.viewState === ViewState.NO_INTERNET && this.renderNoNetworkView()}
        {this.state.viewState === ViewState.ERROR && this.renderErrorView()}
        {this.state.viewState === ViewState.SHOW_DETAIL && this.renderPage()}
      </View>
    );
  }

  renderProgressView = () => (<ProgressView message="Cancelling Ticket..." />);


  renderNoNetworkView = () => (
    <View style={{flex: 1}}>
      <SimpleHeader
        title="Back"
        iconPress={this.goBack}
      />
      <NoInternetView
        onRetry={() => {
          this.setState({viewState: ViewState.LOADING});
          this.cancelBusAsync(false);
        }}
      />
    </View>
  );

  renderErrorView = () => (
    <View style={{flex: 1}}>
      <SimpleHeader
        title="Back"
        iconPress={this.goBack}
      />
      <ErrorView
        showRetry
        onRetry={() => {
          this.setState({viewState: ViewState.LOADING});
          this.cancelBusAsync(false);
        }}
        message={this.errorText}
      />
    </View>
  );

  updateSelectedRefundMode=(refundMode) => {
    this.setState({selectedRefundMode: refundMode});
  }

  renderPage = () => {
    const styles = getStyles();
    return(
    <View style={styles.GreyBg}>
      <View style={[styles.MamiCard, styles.mar10Bt]}>
        <StickyHeader
          iconSrc={headerIcon}
          title="Full Cancellation"
          headerColor={['#ffffff', '#ffffff']}
          titleStyle={[styles.DefaultText, styles.blackFont]}
          subTitle={getSubTitleForCancellationHeader(this.props.response)}
          subTitleStyle={[styles.DefaultText, styles.regularFont, styles.font12]}
          onBackPress={this.goBack}
        />
        <BusCancellationInfoStrip
          response={this.props.response}
          penalty={this.penaltyResponse}
          modificationState="Confirm"
        />
        <ChooseRefundModeCard
          penalty={this.penaltyResponse}
          response={this.props.response}
          selectedRefundMode={this.state.selectedRefundMode}
          updateSelectedRefundMode={this.updateSelectedRefundMode}
        />
        <TouchableWithoutFeedback onPress={this.refundpopup}>
          <LinearGradient
            start={{x: 1.0, y: 0.0}}
            end={{x: 0.0, y: 1.0}}
            colors={['#065af3', '#53b2fe']}
            style={[styles.CapsuleBtnFill, styles.mar150Bt]}
          >
            <Text style={[styles.WhiteText, styles.CenterText, styles.transperantBgrnd]}>CONFIRM CANCELLATION</Text>
          </LinearGradient>
        </TouchableWithoutFeedback>
      </View>
      {this.state.overlay ? this.renderConfirmationPopup() : null}
    </View>
  )};

  renderConfirmationPopup() {
    const styles = getStyles();
    return (
      <View style={{
        backgroundColor: 'rgba(0,0,0,0.5)',
        position: 'absolute',
        height: Dimensions.get('screen').height,
        width: '100%',
        justifyContent: 'flex-end',
        top: 0,
        left: 0
            }}
      >
        <View style={styles.refundPopup}>
          <View style={[styles.flexRow, styles.mar15Bt]}>
            <View>
              <Image style={styles.cancelIcon} source={cancellingIcon} />
            </View>
            <View>
              <Text style={[styles.font12, styles.BlackText, styles.regularFont]}>Cancelling all Seats</Text>
              <Text style={[styles.font24, styles.BlackText, styles.regularFont]}>Are you sure?</Text>
            </View>
          </View>
          <Text style={[styles.font16, styles.mar30Bt, styles.DefaultText, styles.regularFont, styles.lineHeight20]}>Once
            confirmed, your booking will be cancelled and refund will be initiated.
          </Text>
          <View style={styles.flexRow}>
            <Text
              style={[styles.AlignRight, styles.BlueText, styles.font16, styles.mar32Rt, styles.pad10Top]}
              onPress={this.refundpopup}
            >BACK
            </Text>
            <CapsuleBtnFill
              style={styles.transperantBgrnd}
              key="CapsuleBtnFill_OKAY,CANCEL"
              action={this.getbuttonAction()}
              clickHandler={this.onConfirmCancel}
            />
          </View>
        </View>
        <TouchableHighlight
          style={[styles.transparentBg, styles.transperantBgrnd]}
          onPress={this.refundpopup}
        ><Text />
        </TouchableHighlight>
      </View>
    );
  }

  refundpopup =() => {
    BusBookingTrackingHelper.trackClickEvent('Cancellation: Cancellation confirmation', this.props.response, 'Confirm Cancel');
    this.setState({overlay: !this.state.overlay});
  }

    getbuttonAction = () => {
      const action = {};
      action.actionLabeltext = 'OKAY, CANCEL';
      return action;
    };

  onConfirmCancel = () => {
    this.setState({viewState: ViewState.LOADING});
    this.cancelBusAsync(false);
  };

  getTicketList = () => {
    let ticketList = [];

    this.props.response.msrTicketList && this.props.response.msrTicketList.ticketsList
      && this.props.response.msrTicketList.ticketsList.map((ticket, index) => {
      if(ticket.primaryType === BusBookingDetailsConstant.TICKET_PRIMARY_TYPE
          && ticket.secondaryType === BusBookingDetailsConstant.TICKET_SECONDARY_TYPE
          && ticket.ternaryType === BusBookingDetailsConstant.TICKET_TERNARY_TYPE) {
            ticketList.push(ticket);
          }
    })

    return ticketList;
  }

  async cancelBusAsync(prev) {
    try {
      const hasNetwork = await isNetworkAvailable();
      if (!hasNetwork) {
        this.setState({viewState: ViewState.NO_INTERNET});
        return;
      }
      const request = {
        bookingId: this.bookingId,
        preview: prev,
        myDeskTickets: this.getTicketList(),
        refundOption: this.state.selectedRefundMode
      };
      const {mmtAuth, loggingTrackingInfo} = await HotelBookingModule.getRequestHeader(this.props.bookingid, 'Bus_Cancellation_Confirm');
      const response = await
        fetch(BUS_CANCELLATION_URL, {
          method: 'POST',
          body: JSON.stringify(request),
          headers: await getCommonHeaders(mmtAuth, loggingTrackingInfo)
        });

      if (response.ok) {
        const body = await
          response.json();
        this.cancellationResponse = body;
        this.errorText = this.cancellationResponse.errorText;
        if (this.cancellationResponse.cancellationDone) {
          const params = {};
          params.BOOKING_ID = this.bookingId;
          params.MMT_AUTH = this.mmtAuth;
          params.LOGGING_TRACKINFO = this.loggingTrackingInfo;
          params.refresh = true;
          BusBookingTrackingHelper.trackLoadEvent('Cancellation: Cancellation confirmation_final', this.props.response, 'Confirm Cancel Final');
          Actions.busBookingDetail({data: params});
        } else {
          BusBookingTrackingHelper.trackErrorEvent('Bus_Cancellation_Confirm_Final');
          this.setState({viewState: ViewState.ERROR});
        }
      } else {
        BusBookingTrackingHelper.trackErrorEvent('Bus_Cancellation_Confirm_Final');
        this.setState({viewState: ViewState.ERROR});
      }
    } catch (error) {
      BusBookingTrackingHelper.trackErrorEvent('Bus_Cancellation_Confirm_Final');
      this.setState({viewState: ViewState.ERROR});
    }
  }

    goBack = () => {
      Actions.pop();
      logAction(`${this.pageName}onBackPress`);
    };
}
