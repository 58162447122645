import { StyleSheet } from "react-native";
import { Fonts, Theme } from '../../../types';


export default (fonts: Fonts, theme: Theme) => {
    const { color } = theme;
    return StyleSheet.create({
        btnGradient: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingHorizontal: 18,
            borderRadius: 50,
            paddingVertical: 6,
            alignSelf: 'flex-start',
        },
        iconImgStyle: {
            width: 24,
            height: 24,
            marginRight: 6,
        },
        textStyle: {
            ...fonts.boldFontFamily,
            fontSize: fonts.fontSize.font12,
            color: color.green,
        },
    });
};