import React from 'react';
import { View, Image, Text } from 'react-native';
import createStyles from '../NameCrectRqstSbmtedCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { ONEWAY_ARROW_ICON } from '../../../../../FlightImageConstants';
import HTMLView from 'react-native-htmlview';
import USER_ICON from '@mmt/legacy-assets/src/postSales/user_icon.webp';
import { isEmptyArray } from '../../../../../../Common/commonUtil';
import { formatThankYouPopupData } from '../../../data/dataAdapter';
import { getFont } from '../../../../../../PostSalesStyles';
import { useTheme } from '../../../../../../theme/theme.context';
import { getStaticData } from '../../../../../../staticData/staticData';

const DetailSectionCard = ({ data, passengerList, reasonSelected, bookingId }) => {
  const { psTheme: theme } = useTheme();
  const { fsStyle, ...fonts } = getFont(true);
  const styles = createStyles(theme);
  const staticData = getStaticData();
  const { nameCorrectionTexts } = staticData || {};

  const { correctionRqst, expected } = formatThankYouPopupData({
    changeNameSuccessData: data,
    passengerInfo: passengerList,
    reasonSelected,
    bookingId,
    NAME_CHANGE_TEXTS: nameCorrectionTexts,
  });

  const { title, description } = expected;

  return (
    <View style={styles.detailSection}>
      <View style={[styles.detailCard, AtomicCss.marginBottom15]}>
        {/* <Animated.View style={{ opacity: delayText }}> */}
        <View style={styles.detailsTop}>
          {!!title && (
            <Text
              style={[
                AtomicCss.blackText,
                fonts.blackFontFamily,
                fsStyle.font14,
                AtomicCss.lineHeight20,
                AtomicCss.marginBottom6,
              ]}
            >
              {title}
            </Text>
          )}
          {description &&
            description.length > 0 &&
            description.map((des) => (
              <HTMLView
                value={`<p>${des}</p>`}
                textComponentProps={[
                  AtomicCss.defaultText,
                  fonts.regularFontFamily,
                  fsStyle.font14,
                  AtomicCss.lineHeight20,
                  AtomicCss.marginBottom16,
                ]}
              />
            ))}
        </View>
        {correctionRqst && !isEmptyArray(correctionRqst.correction) && (
          <View style={styles.segmentCard}>
            <Text
              style={[
                AtomicCss.blackText,
                fonts.blackFontFamily,
                fsStyle.font14,
                AtomicCss.lineHeight20,
                AtomicCss.marginBottom12,
              ]}
            >
              {correctionRqst.title}
            </Text>
            {correctionRqst.correction &&
              correctionRqst.correction.map((currentCur) => {
                return (
                  <View style={[styles.segmentHeadingWrapper]}>
                    <Image style={styles.userStyle} source={USER_ICON} />
                    <View>
                      <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.flexWrap]}>
                        <Text
                          style={[
                            AtomicCss.defaultText,
                            fonts.regularFontFamily,
                            fsStyle.font14,
                            styles.lineHeight20,
                          ]}
                        >
                          {currentCur.before}
                        </Text>
                        <Image style={styles.arrowIcon} source={ONEWAY_ARROW_ICON} />
                        <Text
                          style={[
                            AtomicCss.blackText,
                            fonts.boldFontFamily,
                            fsStyle.font14,
                            styles.lineHeight20,
                          ]}
                        >
                          {currentCur.after}
                        </Text>
                      </View>
                    </View>
                  </View>
                );
              })}
          </View>
        )}
        {/* </Animated.View> */}
      </View>
    </View>
  );
};

export default DetailSectionCard;
