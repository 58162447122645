import React from 'react';
import PropTypes from 'prop-types';
import {isEmpty} from 'lodash';
import styles from './LocationCss';
import {Image, Text, TouchableOpacity, View} from 'react-native';
import AtomicCss from '../AtomicCss';
import {getActionButton, getButtonStyle, handleClickEvent} from '../../utils/AcmeBookingDetailUtil';
import {isNotNullAndEmpty} from '../../../hotel/details/utils/HotelBookingDetailUtil';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import {openGoogleMap} from "../../../Common/commonUtil";
import {locationCardIcon , mapImage} from '../../utils/AcmeImageConstant';


class LocationCard extends React.Component {
  render() {
    const addressDetail = this.props.response.acmeBookingInfo.acmeBookingDetails[0].addressDetails;
    return (
      <View style={[AtomicCss.whiteBg, AtomicCss.marginBottom10]}>
        <View style={[styles.CardContentFull, AtomicCss.paddingBottom8]}>
          <View style={[styles.CardContent]}>
            <Image style={styles.CardIcon} source={locationCardIcon} />
            <View style={AtomicCss.flex1}>
              <Text
                style={[AtomicCss.blackFont, AtomicCss.blackText,
                  AtomicCss.font14, AtomicCss.paddingTop3, AtomicCss.marginBottom20]}
              >
                {addressDetail.locality ? addressDetail.locality : 'Address'}
              </Text>
              <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween]}>
                <View style={[AtomicCss.flex1, AtomicCss.marginRight18]}>
                  <Text
                    style={[AtomicCss.font14, AtomicCss.lineHeight20,
                      AtomicCss.regularFont, AtomicCss.defaultText]}
                  >
                    {addressDetail.address}
                  </Text>
                </View>
                {
                  !isEmpty(addressDetail.latitude) && !isEmpty(addressDetail.longitude) && addressDetail.showMap &&
                  <TouchableOpacity onPress={() => this.openGoogleMap(addressDetail.latitude, addressDetail.longitude)}>
                    <Image style={styles.mapIcon} source={mapImage} />
                  </TouchableOpacity>
                }
              </View>
            </View>
          </View>
        </View>
        {
          !isEmpty(this.props.response.acmeBookingInfo.acmeBookingDetails[0].addressDetails.contactno)
          &&
          <View
            style={isNotNullAndEmpty(this.props.card.actionList)
              ? getButtonStyle(this.props.card.actionList)
              : styles.hideView}
          >
            {getActionButton(this.props.card, this.props.response, this.handleClick)}
          </View>
        }
      </View>);
  }

  openGoogleMap(latitude, longitude) {
    return openGoogleMap(latitude,longitude);
  }

  handleClick = (action) => {
    handleClickEvent(this.props.card, action, this.props.response, this.props.pageName);
  };
}

LocationCard.propTypes = {
  response: PropTypes.object.isRequired,
  card: PropTypes.object.isRequired,
  pageName: PropTypes.string.isRequired
};
export default LocationCard;
