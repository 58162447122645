import React from 'react';
import { View } from 'react-native';
import Checkbox from 'apps/post-sales/src/Common/Checkbox';
import Button from '../../Button';
import { getFont } from '../../../PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import createStyles from '../styles';
import { getStaticData } from '../../../staticData/staticData';
import { PROCEED } from '../../../PostSalesConstant';

interface CancellationPolicyProps {
  notCancellationpolicy: boolean;
  isCancellationPolicySelected: boolean;
  handleCancellationPolicyChange: () => void;
  isMarkupEnabled: boolean;
  handleDownload: () => void;
}

const CancellationPolicy: React.FC<CancellationPolicyProps> = ({
  notCancellationpolicy,
  isCancellationPolicySelected,
  handleCancellationPolicyChange,
  isMarkupEnabled,
  handleDownload,
}) => {
  const { customerConfirmPopupText } = getStaticData(false);
  const { additionalText } = customerConfirmPopupText || {};
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme);
  return (
    <View>
      {!notCancellationpolicy && (
        <View style={styles.checkBoxWrapper}>
          <Checkbox
            label={additionalText?.addCancellationPolicyText}
            onChange={handleCancellationPolicyChange}
            checked={isCancellationPolicySelected}
          />
        </View>
      )}
      <View style={styles.buttoncomp}>
        <Button
          btnBg="gradientBtn"
          btnType="flat"
          btnTxt={isMarkupEnabled ? additionalText?.downloadWithMarkupText : additionalText?.downloadWithoutMarkupText}
          clickHandler={handleDownload}
          btnClickAction={PROCEED}
        />
      </View>
    </View>
  );
};

export default CancellationPolicy;
