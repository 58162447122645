const styles = {
  overlayContainer: {
    justifyContent: 'flex-end',
    position: 'absolute',
    bottom: 0,
    left: 0,
    height: '100%',
    width: '100%',
    zIndex: 1000,
    elevation: 3,
  },
  overlayBg: {
    backgroundColor: 'rgba(0,0,0,.5)',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    zIndex: 3,
    elevation: 3,
  },
  heading: {
    marginBottom: 20,
    color: '#000',
    lineHeight: 52,
  },
  subHeading: {
    marginBottom: 20,
    color: '#000',
    lineHeight: 22,
  },
  overlayContent: {
    backgroundColor: '#fff',
    paddingVertical: 20,
    paddingHorizontal: 20,
    shadowColor: '#330000',
    shadowOpacity: 0.3,
    shadowRadius: 2,
    elevation: 4,
    zIndex: 4,
    position: 'absolute',
    bottom: 0,
    marginBottom: -400,
    width: '100%',
    shadowOffset: {
      width: 1,
      height: 0,
    },
  },
  btn: {
    color: '#008cff',
  },
  underline: {
    textDecorationLine: 'underline',
  },
  submitBtn: {
    borderRadius: 22,
    paddingVertical: 14,
    paddingHorizontal: 20,
  },
};

export default styles;
