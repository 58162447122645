import React from 'react';
import {View, Platform} from 'react-native';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import {isNetworkAvailable} from '@mmt/legacy-commons/Common/utils/AppUtils';
import FlightBookingModule from '@mmt/legacy-commons/Native/FlightBookingModule';
import Actions from '../../navigation/postSalesNavigation';
import {
  getCommonHeaders,
  SUBMIT_REQUEST_URL
} from '../../utils/NetworkUtils';
import ProgressView from '../ProgressView/ProgressView';
import SubmitRequestTrackingHelper from './SubmitRequestTrackingHelper';
import {getDefaultErrorPageData, getImagePath, openThankYouPage} from '../../utils/PostSaleUtil';
import CommonErrorPage from "../CommonErrorPage";
import { getStaticData } from '../../staticData/staticData';
import PostSalesBasePage from '../PostSalesBasePage';
import isEmpty from "lodash/isEmpty";

const errorIcon = getImagePath('src/Assets/ic_cancellation_reuqest.webp');

class CommonSubmitRequest extends PostSalesBasePage {
  static navigationOptions = {
    header: null
  };

  constructor(props) {
    super(props, 'commonSubmitRequest');
    this.state = {
      viewState: ViewState.LOADING
    };
  }

  componentDidMount() {
    this.makeSubmitRequestCall();
  }

  render() {
    const { loadingText } = getStaticData();
    return (
      <View style={{flex: 1}}>
        {this.state.viewState === ViewState.LOADING && <ProgressView message={loadingText ? loadingText : ''}/>}
        {this.state.viewState === ViewState.ERROR && <CommonErrorPage {...this.state.errorData} popCurrentPage={false}/>}
      </View>
    );
  }

  onRetryClick = () => {
    this.setState({viewState: ViewState.LOADING});
    this.makeSubmitRequestCall();
  }

  getErrorData = (response) => {
    const errorData = {...response};
    errorData.mobileHeaderData = {};
    errorData.mobileHeaderData.title = response.mobileHeaderData.header;
    errorData.mobileHeaderData.description = response.mobileHeaderData.subText;
    return {...errorData, ...this.getCommonErrorData()};
  }

  getDefaultErrorData = () => {
    const errorData = getDefaultErrorPageData();
    return {...errorData, ...this.getCommonErrorData()};
  }

  getCommonErrorData = () => {
    const errorData = {};
    errorData.tryAgainClick = this.onRetryClick;
    errorData.errorImage = errorIcon;
    errorData.errorImageStyle = {
      width: 60,
      height: 55,
      marginBottom: 25
    };
    errorData.bookingId = this.props.bookingId;
    errorData.flowName = this.props.flowName;
    return errorData;
  }

  makeSubmitRequestCall = async () => {
    try {
      const {
        bookingId, uniquePageId, errorCode, psLob, extraPayLoad
      } = this.props;
      const hasNetwork = await isNetworkAvailable();
      if (!hasNetwork) {
        this.setState({viewState: ViewState.NO_INTERNET});
        return;
      }
      let request = {
        bookingId,
        uniquePageId,
        errorCode
      };
      if (!isEmpty(extraPayLoad)) {
        request = { ...request, ...extraPayLoad };
      }
      const userInfo = await FlightBookingModule.getRequestHeader('', 'SUBMIT_REQUEST');
      const response = await
        fetch(SUBMIT_REQUEST_URL, {
          method: 'POST',
          headers: await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo, { psLob }),
          body: JSON.stringify(request)
        });
      if (response.ok) {
        const body = await
          response.json();
        if (body.success) {
          Actions.pop()
          this.props.openThankYouPage(body);
          SubmitRequestTrackingHelper.trackLoadEvent(`${this.props.flowName}_MIMAError request Success`, bookingId, errorCode);
        } else {
          Actions.pop()
          this.props.openFailurePage(body);
          SubmitRequestTrackingHelper.trackLoadEvent(`${this.props.flowName}_requesterror`, bookingId, errorCode);
        }
      } else {
        Actions.pop()
        this.props.openFailurePageWithDefaultData();
        SubmitRequestTrackingHelper.trackLoadEvent(`${this.props.flowName}_requesterror`, bookingId, errorCode);
      }
    } catch (error) {
      Actions.pop();
      this.props.openFailurePageWithDefaultData();
      SubmitRequestTrackingHelper.trackLoadEvent(`${this.props.flowName}_requesterror`, this.props.bookingId, error.message);
      console.log(error.message);
    }
  };
}

export default CommonSubmitRequest;
