import React from 'react';
import { View, Text } from 'react-native';
import styles from './DocumentDetailCardCss';
import Seperator from '../../../CardLayoutSeperator';
import UploadProofCard from '../UploadProofCard';

const DocumentDetailCard = ({
  attachmentSectionData,
  setAttachmentSectionData,
  bookingId,
  pageName = '',
  formType,
}) => {
  const { attachmentHeading, subAttachmentHeading } = attachmentSectionData || {};
  return (
    <>
      <View style={[styles.cardWrapper, styles.cardWrapperLastItem]}>
        <View style={styles.checkAllWrapper}>
          <View style={[styles.checkAllWrapperInner]}>
            <View style={styles.sectorHeader}>
              <View>
                {!!attachmentHeading && (
                  <Text style={styles.statusHeadText}>{attachmentHeading}</Text>
                )}
                {!!subAttachmentHeading && (
                  <Text style={styles.smallText}>{subAttachmentHeading}</Text>
                )}
              </View>
            </View>
          </View>
        </View>
        <View style={styles.seperatorWrapper}>
          <Seperator />
        </View>
        <View style={styles.uploadWrapper}>
          <UploadProofCard
            setAttachmentSectionData={setAttachmentSectionData}
            bookingId={bookingId}
            pageName={pageName}
            formType={formType}
          />
        </View>
      </View>
    </>
  );
};

export default DocumentDetailCard;
