import {colors} from '@mmt/legacy-commons/Styles/globalStyles';

const styles = {
  MamiCardGrey: {
    backgroundColor: '#ddd',
    paddingLeft: 10,
    paddingRight: 10
  },
  CardContentFull: {
    backgroundColor: colors.white,
    padding: 15,
    paddingBottom: 0
  },
  CardContent: {
    backgroundColor: colors.white,
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 10
  },
  CardIcon: {
    width: 24,
    height: 24,
    marginRight: 10
  },
  marL10per: {paddingLeft: '10%'},
  messageIcon: {
    width: 13,
    height: 9,
    marginRight: 8,
    marginTop: 3
  },
  callIcon: {width: 16, height: 16, marginRight: 8}
};

export default styles;
