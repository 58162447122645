/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import {View, Text, Image, StyleSheet, ScrollView, TouchableOpacity} from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {ZC_IMAGE} from '../../BusImageConstants';
import { rupeeAmount } from '@mmt/legacy-commons/Helpers/currencyUtils';
import _ from 'lodash';
import { getStaticData, label } from '@mmt/post-sales/src/staticData/staticData';
import { getFont } from '../../../PostSalesStyles';
import { iconDark, fcBus } from '../../BusImageConstants';
import { ThemeContext } from 'apps/post-sales/src/theme/theme.context';
class PartialCancellationBreakupDtls extends React.Component {
  static contextType = ThemeContext;

  constructor(props) {
    super(props);
    this.state = {
      fareBreakupDtls: false
    };
    this.fonts = getFont(true);
  }

    static navigationOptions = {
      header: null
    };

    render() {
      const {totalRefundAmount, totalCancelationCharges, totalPaidAmount} = this.props.fareBreakUpObject;
      const refundText = totalRefundAmount <= 0 ? label('bus.cancellation.noRefund') : label('bus.cancellation.yourRefund');
      const activeDtls = this.state.fareBreakupDtls ? label('bus.cancellation.hideBreakup') : label('bus.cancellation.showBreakup');
      const { fonts } = this;
      const {isFreeCancellation} = this.props.cancelPreviewObj || {};
      const {color} = this.context.psTheme;
      const styles = getStyles(fonts, color);
      return (

        <ScrollView>
          <View style={[AtomicCss.whiteBg, AtomicCss.elevation1, AtomicCss.paddingBottom20]}>
            <View style={[styles.borderbtm, {display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}]}>
              <View style={[AtomicCss.flexRow, styles.CancPriceBreckup]}>
                <View style={styles.CancLeftSection}>
                  <Text style={[styles.fbHeadingTxt, fonts.blackFontFamily, fonts.font16]}>{this.paxCountPaidString()}
                  </Text>
                  {!this.props.hideBreakup &&
                    <TouchableOpacity onPress={this.handleFareBreakup} activeOpacity={0.5}>
                      <Text style={styles.linkTxt}>{activeDtls}</Text>
                    </TouchableOpacity>
                  }
                  {}
                </View>
                <View>
                  <Text style={[AtomicCss.blackText, fonts.font14,
                            fonts.boldFontFamily, AtomicCss.marginBottom8]}
                  >&#8377; {totalPaidAmount}{' '}
                  </Text>
                  {}
                </View>
              </View>
              {(this.props.hideBreakup || (this.props.paidComponent && this.state.fareBreakupDtls)) && this.addBreakup(this.props.paidComponent)}
            </View>

            <View style={[styles.borderbtm]}>
              <View style={[AtomicCss.flexRow, styles.CancPriceBreckup]}>
                <View style={styles.CancLeftSection}>
                  <Text style={[[styles.fbHeadingTxt, fonts.blackFontFamily, fonts.font16]]}>{label('bus.cancellation.deductions')}
                  </Text>
                  {}
                </View>
                <View>
                  <Text style={[AtomicCss.blackText, fonts.font14,
                            fonts.boldFontFamily, AtomicCss.marginBottom8]}
                  >- &#8377; {totalCancelationCharges}{' '}
                  </Text>
                  {}
                </View>
              </View>

              {this.props.deductionComponent && this.addBreakup(this.props.deductionComponent, isFreeCancellation)}

            </View>


            <View style={[AtomicCss.flexRow, styles.CancPriceBreckup]}>
              <View style={styles.CancLeftSection}>
                <Text style={[(totalRefundAmount <= 0 ? styles.impInfoRed : styles.impInfoGreen), fonts.boldFontFamily, fonts.font16]}>
                  {refundText}
                </Text>
              </View>
              { totalRefundAmount > 0 &&
                <View>
                  <Text style={[styles.impInfoGreen, AtomicCss.marginBottom8, fonts.boldFontFamily, fonts.font16]}
                  >&#8377; {totalRefundAmount}{' '}
                  </Text>
                </View> }
              { totalRefundAmount <= 0 &&
              <View>
                <Text style={[styles.impInfoRed, fonts.boldFontFamily, AtomicCss.marginBottom8]}
                >&#8377; {totalRefundAmount}{' '}
                </Text>
              </View> }
            </View>
            { this.props.zcSubText && !_.isEmpty(this.props.zcSubText) &&
              <View style={[AtomicCss.flexRow,styles.zcSubText]}>
              <Text style={[AtomicCss.greenText, fonts.regularFontFamily]}>{this.props.zcSubText}</Text>
              </View>
            }
          </View>
        </ScrollView>

      );
    }
    paxCountPaidString = () => {
      if (this.props.isFullCancellation) {
        return label('bus.cancellation.totalPaid');
      }
      if (this.props.selectedPax) {
        if (this.props.selectedPax.length > 1) {
          return label('bus.cancellation.totalPaid1', null, { count: this.props.selectedPax.length });
        }
        return label('bus.cancellation.totalPaid2', null, { count: this.props.selectedPax.length });
      }
      return label('bus.cancellation.totalPaid');
    }

    handleFareBreakup = () => {
      this.setState({
        fareBreakupDtls: !this.state.fareBreakupDtls
      });
    };

    addBreakup = (components, isFreeCancellation=false) => {
      const {freeText, youTookTCText} = getStaticData(true);
      const { fonts } = this;
      const {color} = this.context.psTheme;
      const styles = getStyles(fonts, color);

      return components.map(component => {
        const { fonts } = this;
        const showFreeCancellation = isFreeCancellation && component.labelId === "base_fare_penalty";
        if (component.labelId === "waiver_amount" && this.props.isZCBooking) {
          return (
            <View style={[AtomicCss.flexRow, styles.priceBreakups]}>
              <View style={styles.CancLeftSection}>
                <Image source={ZC_IMAGE} style={{ height: 20, width: 20, paddingRight:5 }} />
                <Text style={[styles.fareBrkupRowLineTxt, fonts.regularFontFamily, fonts.font14]}>
                  {component.label}
                </Text>
                {}
              </View>
              <View>
                <Text style={[styles.fareBrkupRowLineTxt, fonts.regularFontFamily, fonts.font14]}>
                  <Text style={[styles.lineThrough,AtomicCss.regularFont]}>
                    {component.strikedValue}
                  </Text>
                  &nbsp;&nbsp;
                    <Text style={[styles.impInfoGreen, AtomicCss.regularFont, AtomicCSS.marginLeft5]}>
                      {component.value}
                    </Text>
                </Text>
              </View>
            </View>
          );
        }
        else {
          return (
            <View style={[AtomicCss.flexRow, styles.priceBreakups]}>
              <View style={styles.CancLeftSection}>
                <Text style={[styles.fareBrkupRowLineTxt, fonts.regularFontFamily, fonts.font14]}>
                  {component.label}
                </Text>
                {}
              </View>
              <View>
                <View style={[AtomicCss.flexRow, AtomicCss.justifyEnd]}>
                  {showFreeCancellation && (
                    <TouchableOpacity
                      onPress={() => this.props.iconClickHandler()}
                    >
                      <Image source={iconDark} style={styles.iconImage} />
                    </TouchableOpacity>
                  )}
                  {!!component.value && <Text
                    style={[
                      showFreeCancellation && AtomicCss.lineThrought,
                      styles.fareBrkupRowLineTxt,
                      fonts.regularFontFamily,
                      fonts.font14,
                    ]}
                  >
                    {component.value}
                  </Text>}
                  {showFreeCancellation && (
                    <Text style={styles.freeText}>{freeText.toUpperCase()}</Text>
                  )}
                </View>
                {showFreeCancellation && this.props.iconClicked && (
                  <View style={styles.tooltipStatusWrapper}>
                    <View style={styles.triangle}></View>
                    <View style={[styles.TootlTipInnerInfo, AtomicCss.flexRow]}>
                      <View style={AtomicCss.marginRight5}>
                        <Text
                          style={[styles.freeTextPopup, fonts.regularFontFamily, fonts.font12]}
                        >
                          {freeText.toUpperCase()}
                        </Text>
                        <Text
                          style={[
                            isFreeCancellation && AtomicCss.lineThrought,
                            styles.fareBrkupRowLineTxt,
                            fonts.regularFontFamily,
                            fonts.font14,
                          ]}
                        >
                          {component.value}
                        </Text>
                      </View>
                      <Text
                        style={[
                          styles.youTookFcText,
                          AtomicCss.marginRight5,
                          fonts.regularFontFamily,
                          fonts.font12
                        ]}
                      >
                        {youTookTCText}
                      </Text>
                      <Image source={fcBus} style={styles.fcImage} />
                    </View>
                  </View>
                )}
              </View>
            </View>
          );
          }
    });
    }
}

PartialCancellationBreakupDtls.propTypes = {
  fareBreakUpObject: PropTypes.object.isRequired,
  selectedPax: PropTypes.array,
  isFullCancellation: PropTypes.bool.isRequired,
  deductionComponent: PropTypes.array.isRequired,
  paidComponent: PropTypes.array.isRequired,
  hideBreakup: PropTypes.bool.isRequired
};

PartialCancellationBreakupDtls.defaultProps = {
  selectedPax: null
};

const getStyles = (fonts, color) => {
  return  StyleSheet.create({
    CancPriceBreckup: {
      justifyContent: 'space-between',
      paddingTop: 16,
      paddingBottom: 2,
      paddingRight: 16,
      paddingLeft: 16,
      zIndex: -1
    },
    borderbtm: {
      borderBottomWidth: 1,
      borderBottomColor: color.grey8
    },
    CancLeftSection: {
      flexDirection: 'row'
    },
    impInfoGreen: {
      color: color.cyan,
      fontSize: 16
    },
    priceBreakups: {
      justifyContent: 'space-between',
      paddingRight: 16,
      paddingLeft: 16,
      paddingBottom: 5
    },
    rightComponent: {
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: 30
    },
    impInfoRed: {
      color: color.red4,
      fontSize: 16
    },
    linkTxt: {
      color: color.lightBlue17,
      fontSize: 12,
      marginLeft: 10,
      marginTop: 2
    },
    fbHeadingTxt: {
      color: color.black,
      fontSize: 16
    },
    fareBrkupRowLineTxt: {
      fontSize: 14,
      color: color.grey
    },
    lineThrough: {
      textDecorationLine: 'line-through'
    },
    zcSubText: {
      paddingTop:15,
      paddingBottom:8,
      paddingLeft:15,
      marginTop :-15
    },
    iconImage: {
      height: 20,
      width: 20,
      marginRight: 5
    },
    freeText : {
      color: color.lightGreen18,
      marginLeft: 5
    },
    tooltipStatusWrapper: {
      marginTop: 20,
      position: 'absolute'
    },
    triangle: {
      width: 0,
      height: 0,
      backgroundColor: color.transparent,
      borderStyle: 'solid',
      borderLeftWidth: 8,
      borderRightWidth: 8,
      borderBottomWidth: 10,
      borderLeftColor: color.transparent,
      borderRightColor: color.transparent,
      borderBottomColor: color.black,
      transform: [{ rotate: '0deg' }],
      margin: 0,
      borderWidth: 0,
      borderColor: color.transparent,
    },
    TootlTipInnerInfo: {
      backgroundColor: color.black,
      padding: 8,
      borderRadius: 8,
      alignSelf: 'flex-start',
      right: 75
    },
    fcImage : {
      height: 30,
      width: 30
    },
    youTookFcText: {
      color: color.white,
      maxWidth: 100
    },
    freeTextPopup: {
      color: color.white
    }
  });
}


export default PartialCancellationBreakupDtls;
