import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Image, Platform } from 'react-native';
import HTMLView from 'react-native-htmlview';
import PropTypes from 'prop-types';
import styles from './FareBreakUpCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import BlackPriceBreakup from '../../../../../../Common/BlackPriceBreakup';
import isEmpty from 'lodash/isEmpty';
import { addSpaceWithAmount, appendCurrency, showECouponForMyPartnerBookings } from '../../../../../../utils/PostSaleUtil';
import { getFont } from '../../../../../../PostSalesStyles';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
class FareBreakUp extends React.Component {
  constructor(props) {
    super(props);
  }
  static navigationOptions = {
    header: null,
  };

  render() {
    const { fsStyle, ...fonts } = getFont(true);
    const htmlStyle = StyleSheet.create({
      q: {
        fontFamily: fonts.regular,
      },
      p: {
        color: '#1a7971',
        fontSize: fonts.fontSize.font12,
      },
      b: {
        fontFamily: fonts.bold,
        fontSize: fonts.fontSize.font12,
      },
    });
    const tcsInfo = StyleSheet.create({
      p: {
        color: colors.grey1,
        fontSize: fonts.fontSize.font12,
        fontFamily: fonts.regular,
        textAlign: 'justify',
      }
    });
    return (
      <View style={[AtomicCss.whiteBg, AtomicCss.marginBottom10]}>
        <View style={[styles.CardContentFull]}>
          <View style={styles.cardHeadingWrapper}>
            <Text style={[styles.cardHeading, fonts.blackFontFamily, fsStyle.font20]}>
              {this.props.heading || 'Price Breakup'}
            </Text>
          </View>
          <View style={styles.fareBreakupInnerSection}>
            {this.getFareBreakUpViews()}
            {!isEmpty(this.props.discountDetails)  && this.getDiscountViews()}
            {!isEmpty(this.props.loyaltyDetails) &&
              !isEmpty(this.props.loyaltyDetails.tierMetaInfo) &&
              !isEmpty(this.props.loyalityDiscountDetail) &&
              this.getBlackView()}
          </View>
          {!isEmpty(this.props.cashbackCouponText) && (
            <View style={styles.priceInfoWrapper}>
              <HTMLView
                value={`<q><p>${this.props.cashbackCouponText}</p></q>`}
                stylesheet={htmlStyle}
              />
            </View>
          )}
          {!isEmpty(this.props.failedAncillaryRefundText) && (
            <View style={[styles.priceInfoWrapper, { backgroundColor: '#dde8ff' }]}>
              <Text style={[fonts.regularFontFamily, { color: '#053cb2' }, fsStyle.font12]}>
                {this.props.failedAncillaryRefundText}
              </Text>
            </View>
          )}
          {!isEmpty(this.props.totalAmount) &&
          this.props.totalAmount.amount &&
          (
            <View style={[styles.totalPrice]}>
              <Text
                style={[
                  styles.totalPricetxt,
                  fsStyle.font16,
                  fonts.blackFontFamily,
                  fsStyle.font16,
                ]}
              >
                {this.props.totalAmountText}
              </Text>
              <Text
                style={[
                  styles.totalPricetxt,
                  fsStyle.font18,
                  fonts.blackFontFamily,
                  fsStyle.font16,
                ]}
              >
                {addSpaceWithAmount(this.props.totalAmount.amount)}
              </Text>
            </View>
          )}
          {!isEmpty(this.props.tcsInfoText) && (
            <View style={[styles.tcsInfoWrapper]}>
              <HTMLView
                value={`<p>${this.props.tcsInfoText}</p>`}
                onLinkPress={ () => {this.props.tcsInfoOverlay()}}
                stylesheet={tcsInfo}
              />
            </View>
          )}
        </View>
      </View>
    );
  }

  getFareBreakUpViews = () => {
    const { fsStyle } = getFont(true);
    const views = [];
    const { isMyPartnerBooking, bookingID } = this.props;
    const fareBreakupArr = this.props.pricebreakuplist;
    for (let i = 0; i < fareBreakupArr.length; i += 1) {
        if (fareBreakupArr[i].amt !== 0) {  
      views.push(
          <View key={i} style={styles.fareBreakupInnerRowSection}>
            <View style={[styles.rowSection]}>
              <View style={{flexDirection:"row", flex:1}}>
                <Text style={[styles.fareBkHeadingTxt, fsStyle.font14]}>
                  {fareBreakupArr[i].name}
                </Text>
                { !showECouponForMyPartnerBookings(isMyPartnerBooking, bookingID) && Platform.OS === 'web' && fareBreakupArr[i].id === "commissionAmt" && <ToolTip commissionBreakUp={this.props.commissionBreakUp} currency={this.props.currency} /> }
              </View>
              <Text style={[styles.fareBkHeadingTxt, fsStyle.font14]}>
                {addSpaceWithAmount(fareBreakupArr[i].price)}
              </Text>
            </View>
          </View>,
        );
      }
    }
    return views;
  };

  getDiscountViews = () => {
    const { fsStyle, ...fonts } = getFont(true);
    const discountArr = this.props.discountDetails;
    return discountArr.map((item) => (
      showECouponForMyPartnerBookings(isMyPartnerBooking, bookingID) && <View style={styles.fareBreakupInnerRowSection}>
        <View style={[styles.rowSection]}>
          <View style={AtomicCss.flexRow}>
            <Text style={[styles.successTxt, fonts.boldFontFamily, fsStyle.font14]}>
              {item.name}
            </Text>
            <View style={styles.cuponCode}>
              <Text>{item.subText}</Text>
            </View>
          </View>
          <Text style={[styles.successTxt, fonts.boldFontFamily, fsStyle.font14]}>
            {addSpaceWithAmount(item.priceWithCurrency)}
          </Text>
        </View>
      </View>
    ));
  };

  getBlackView = () => {
    const fareBreakupBlack = this.props.loyalityDiscountDetail;
    return (
      <View style={styles.fareBreakupInnerRowSection}>
        <View style={[styles.rowSection]}>
          <BlackPriceBreakup
            priceBreakupBlackText={fareBreakupBlack.displayName}
            priceBreakupBlackValue={fareBreakupBlack.displayValue}
            tierMetaInfo={this.props.loyaltyDetails.tierMetaInfo}
            tierType={this.props.loyaltyDetails.tierType}
          />
        </View>
      </View>
    );
  };
}

export default FareBreakUp;

FareBreakUp.propTypes = {
  pricebreakuplist: PropTypes.array.isRequired,
  cashbackCouponText: PropTypes.string,
  discountDetails: PropTypes.array.isRequired,
  failedAncillaryRefundText: PropTypes.string,
  tcsInfoText: PropTypes.string,
  loyalityDiscountDetail: PropTypes.object,
  loyaltyDetails: PropTypes.object,
};

FareBreakUp.defaultProps = {
  cashbackCouponText: undefined,
  failedAncillaryRefundText: undefined,
  tcsInfoText: undefined,
};
