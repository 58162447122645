import React from 'react';
import { View, Text, StyleSheet, Dimensions } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
import SelectPaxSection from './SelectPaxSection';
import { getFont } from '../../../../../../../PostSalesStyles';
import { useTheme } from '../../../../../../../theme/theme.context';

const windW = Dimensions.get('window').width;
const windH = Dimensions.get('window').height;

const SelectMode = ({
  rule,
  flightActionHeader,
  passengerList,
  selectedPax,
  updateSelectedPax,
  nameRvwCorrectionValidationText,
  editInfo,
  ncSubmitResponse,
  globalInputError,
}) => {
  const { heading, description } = flightActionHeader;
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);
  return (
    <View style={styles.mainWrapper}>
      <View style={styles.cardWrapper}>
        <View style={styles.headingText}>
          {!!heading && (
            <Text
              style={[
                fsStyle.font20,
                fonts.blackFontFamily,
                AtomicCss.blackText,
                AtomicCss.marginBottom8,
                AtomicCss.lineHeight28,
                AtomicCss.flex1,
              ]}
            >
              {heading}
            </Text>
          )}
          {!!description && (
            <Text
              style={[
                fsStyle.font14,
                fonts.regularFontFamily,
                AtomicCss.defaultText,
                styles.lineHeight22,
                AtomicCss.flex1,
              ]}
            >
              {description}
            </Text>
          )}
        </View>
        {passengerList.map((passenger, index) => {
          return (
            <SelectPaxSection
              rule={rule}
              passenger={passenger}
              selectedPax={selectedPax[passenger.paxReferenceNo]}
              isSelected={!!selectedPax[passenger.paxReferenceNo]}
              setSelectedPax={() => {
                updateSelectedPax(passenger);
              }}
              editInfo={editInfo}
              nameRvwCorrectionValidationText={nameRvwCorrectionValidationText}
              ncSubmitResponse={ncSubmitResponse}
              globalInputError={globalInputError}
              isAlreadyRaised={passenger.isAlreadyRaised}
            />
          );
        })}
      </View>
    </View>
  );
};
const createStyles = (theme) =>
  StyleSheet.create({
    lastItemStyle: {
      marginBottom: 5,
    },
    cardWrapper: {
      width: windW - 55,
      paddingHorizontal: 5,
    },
    headingText: {
      marginBottom: 24,
      flex: 1,
    },
    paddingAll20: {
      padding: 20,
    },
    lineHeight22: {
      lineHeight: 22,
    },
    cancelledFltCard: {
      ...getPlatformElevation(2),
      backgroundColor: theme.color.grey22,
      padding: 20,
      borderRadius: 8,
      overflow: 'hidden',
      marginBottom: 12,
    },
    buttonBottomWrapper: {
      position: 'absolute',
      top: windH - 130,
      bottom: 16,
      paddingHorizontal: 12,
      width: '100%',
      flex: 1,
      textAlign: 'center',
      alignItems: 'center',
    },
  });
export default SelectMode;
