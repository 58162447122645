import { StyleSheet } from 'react-native';
import { Fonts, Theme } from 'apps/post-sales/src/cab/types';

export default (theme: Theme, fonts: Fonts) => {
  const { color } = theme;
  return StyleSheet.create({
		container: {
			padding: 12,
			borderRadius: 10,
		},
		topRow: {
			flexDirection: 'row',
			justifyContent: 'space-between',
		},
		title: {
			fontSize: 16,
			...fonts.boldFontFamily,
			marginBottom: 8,
			lineHeight: 20,
			color: color.black,
		},
		link: {
			fontSize: 14,
			...fonts.boldFontFamily,
			color: '#008cff',
			marginBottom: 10,
			marginLeft: 'auto',
		},
		location: {
			fontSize: 14,
			...fonts.regularFontFamily,
			lineHeight: 20,
			color: '#4a4a4a',
		},
	});
};
