import React from "react";
import { appendUrlPrefix } from "../../webUtils";
import loadable from "@loadable/component";
import ProgressView from "../../components/ProgressView";

const ACME_PATHS = {
    ACME_BOOKING_DETAIL: {
        pathName: appendUrlPrefix('/acme'),
        pageKey: "acmeBookingDetail",
        Component: loadable(() => import("@rn/apps/post-sales/src/acme/details/AcmeBookingDetail"), { fallback: <ProgressView /> }),
        customFunction: (redirectUrl, pathName, params) => {
            if (params.data) {
                params.bookingId = params.data.BOOKING_ID;
            }
            redirectUrl(pathName, params);
        }
    },
    ACME_SCHEDULE: {
        pathName: appendUrlPrefix('/acme/schedule'),
        pageKey: "acmeSchedule",
        Component: loadable(() => import("@rn/apps/post-sales/src/acme/details/components/AcmeSchedule"), { fallback: <ProgressView /> }),
    },
    ACME_THINGS_TO_CARRY: {
        pathName: appendUrlPrefix('/acme/things_to_carry'),
        pageKey: "acmeThingsToCarry",
        Component: loadable(() => import("@rn/apps/post-sales/src/acme/details/components/AcmeThingsToCarry"), { fallback: <ProgressView /> }),
    },
    ACME_TERMS_AND_CONDITIONS: {
        pathName: appendUrlPrefix('/acme/terms_and_conditions'),
        pageKey: "acmeTermsConditions",
        Component: loadable(() => import("@rn/apps/post-sales/src/acme/details/components/AcmeTermsAndConditions"), { fallback: <ProgressView /> }),
    },
    ACME_FAQ: {
        pathName: appendUrlPrefix('/acme/Faq'),
        pageKey: "acmeFaq",
        Component: loadable(() => import("@rn/apps/post-sales/src/acme/details/components/AcmeFaq"), { fallback: <ProgressView /> }),
    },
    ACME_REDEMPTION_STEPS:{
        pathName: appendUrlPrefix('/acme/redemptionSteps'),
        pageKey: "acmeRedemptionSteps",
        Component: loadable(() => import("@rn/apps/post-sales/src/acme/details/components/AcmeRedemptionSteps"), { fallback: <ProgressView /> }),
    },
    ACME_CANCELLATION_REVIEW: {
        pathName: appendUrlPrefix('/acme/cancellation/review'),
        pageKey: "acmeCancellationReview",
        Component: loadable(() => import("@rn/apps/post-sales/src/acme/cancellation/AcmeCancellationReview"), { fallback: <ProgressView /> }),
    },
    ACME_CANCELLATION_POLICY: {
        pathName: appendUrlPrefix('/acme/cancellation/policy'),
        pageKey: "acmeCancellationPolicy",
        Component: loadable(() => import("@rn/apps/post-sales/src/acme/details/components/AcmeCancellationPolicyStandAlone"), { fallback: <ProgressView /> }),
    },
    ACME_CANCELLATION_REFUND_MODE: {
        pathName: appendUrlPrefix('/acme/cancellation/refundMode'),
        pageKey: "acmeCancellationRefundMode",
        Component: loadable(() => import("@rn/apps/post-sales/src/acme/cancellation/AcmeCancellationRefundMode"), { fallback: <ProgressView /> }),
    },
    ACME_CANCELLATION_THANK_YOU:{
        pathName: appendUrlPrefix('/acme/cancellation/thankYou'),
        pageKey: "acmeCancellationThankyou",
        Component: loadable(() => import("@rn/apps/post-sales/src/acme/cancellation/AcmeCancellationThankyou"), { fallback: <ProgressView /> }),
    }
}

export default ACME_PATHS;