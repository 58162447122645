import { colors } from '@mmt/legacy-commons/Styles/globalStyles';

const styles = {
  whiteCard: {
    backgroundColor: '#ffffff',
    elevation: 1,
    shadowColor: '#000000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 2,
    marginBottom: 3,
    padding: 16,
  },
  pageWrapper: {
    flex: 1,
    backgroundColor: '#f2f2f2',
    paddingBottom: 70,
  },
  footerBtnWrapper: {
    padding: 12,
    paddingTop: 15,
    width: '100%',
    position: 'absolute',
    bottom: 0,
  },
  messageContent: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 50,
  },
  messageIcon: {
    width: 60,
    height: 60,
    marginBottom: 40,
    top: 20,
  },
  messageTitle: {
    color: colors.black28,
    marginBottom: 10,
    textAlign: 'center',
  },
  messageDesc: {
    color: colors.defaultTextColor,
    textAlign: 'center',
    lineHeight: 19,
  },
  innerSection: {
    padding: 16,
  },
  questionIcon: {
    width: 20,
    height: 20,
    marginRight: 10,
  },
  headingTxt: {
    color: '#3c3c3c',
  },
  moreInfoWrapper: {
    paddingLeft: 30,
  },
  successTxt: {
    color: '#1a7971',
  },
  desc: {
    color: colors.defaultTextColor,
    lineHeight: 19,
  },
  defaultTxt: {
    color: colors.defaultTextColor,
  },
  fasterRefundSection: {
    borderTopWidth: 1,
    borderTopColor: '#e7e7e7',
    paddingTop: 20,
    marginTop: 15,
  },
  refundTag: {
    width: 120,
    borderRadius: 10,
    padding: 4,
    alignItems: 'center',
    marginBottom: 12,
  },
  refundTxt: {
    color: colors.white,
  },
  callIconStyle: {
    width: 18,
    height: 18,
  },
  contactRow: {
    paddingVertical: 16,
    borderBottomWidth: 1,
    borderBottomColor: '#e7e7e7',
  },
  borderBottom0: {
    borderBottomWidth: 0,
    borderBottomColor: 'transparent',
  },
};

export default styles;
