import React , {useContext} from 'react';
import { View, Text, Image, ScrollView } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import createStyle from './rydeBannerCardCss';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import { ThemeContext } from '../../../theme/theme.context';
import { getFont } from '../../../PostSalesStyles';

const RydeBannerCard = ({cardData, isRydeUpgrade}) => {
    const { heading, heading2, iconUrl, featureList } = cardData || {};
    const subHeadingStyle = !isRydeUpgrade ? 'subHeadingBold' : ''
    const { psTheme: theme } = useContext(ThemeContext)
    const fonts = getFont(true);
    const styles = createStyle(theme,fonts)
    return (
        <LinearGradient
            colors={[theme.color.greyOrange,theme.color.padua]}
            start={{ x: 0, y: 0 }}
            end={{ x: 0, y: 1 }}
            style={styles.cardWrapper}
             >
            {!!isRydeUpgrade && !!heading && (
                <Text style={styles.headingTxt}>
                {heading}{' '}
                <View>
                    <Image style={[styles.rydeImage2]} source={{ uri: iconUrl }} />
                </View>{' '}
                <Text style={styles.heading2Txt}>{heading2}</Text>
                </Text>
            )}
            {!isRydeUpgrade && <View style={styles.rydeHeadingContainer}>
                {!!heading && <Text style={styles.rydeHeading} >{heading}</Text>}
                {!!iconUrl && <Image style={styles.rydeImage} source={{uri: iconUrl}} />}
                {!!heading2 && <Text style={styles.subHeadingBold}>{heading2}</Text>}
            </View>}
            {!!featureList && featureList.length > 0  && <View style={{ marginHorizontal: -16, }}>
                <ScrollView contentContainerStyle={styles.scrollStyle} horizontal={true} showsHorizontalScrollIndicator={false}>
                    <View style={styles.benefitsScrollWrapper}>
                        {featureList.map((item,index)=>{
                            return(
                                <RidebenefitsCard
                                    item={item}
                                    index={index}
                                    style={styles}
                                    key={index}
                                />
                            )
                        })}
                       
                    </View>
                </ScrollView>
            </View>}
        </LinearGradient>
    )
}

const RidebenefitsCard = ({item,index,style}) => {
    const firstEle = index === 0 ? "firstItemStyle":''
    return (
        <View style={[style.whiteCard,style[firstEle]]}>
            <View style={[style.rowSection]}>
                <View style={style.imgWrapper}>
                    {!!item.iconUrl && <Image style={style.imgStyle} source={{uri: item.iconUrl}}/>}
                </View>
                <View style={AtomicCss.flex1}>
                    {!!item.heading && <Text style={style.title}>{item.heading}</Text>}
                    {!!item.subHeading && <Text style={style.desc}>{item.subHeading}</Text>}
                </View>
            </View>
        </View>
    )
}

export default RydeBannerCard
