import React, { useCallback, useMemo, useState } from 'react';
import { Image, Platform, SectionList, Text, View, TouchableOpacity } from 'react-native';
import { isEmpty } from 'lodash';
import { backIcon } from '@mmt/legacy-commons/Helpers/displayHelper';
import { fetchTickets, fetchV2Bookings } from './data/api';
import {
  filterData,
  getBookingStatusSections,
  showEmptyScreenActiveTab,
  SortTypes,
  prepareTripIdeasData,
  getPagesWithPlanningData,
  getMyTripsCardsInfo
} from './data/tripListManager';
import ViewPagerWithTabs from './components/viewPagerWithTabs';
import LobCards from './components/cards';
import SimpleHeader from '@mmt/legacy-commons/Common/Components/Header/SimpleHeader';
import { resetSummaryData } from '../data/cache';
import myTripsNavigation from './navigation/navigation';
import ProgressView from '../Common/ProgressView/ProgressView';
import refreshIcon from '@mmt/legacy-assets/src/postSales/summary/reloadTrips.webp';
import arrowIcon from '@mmt/legacy-assets/src/redirect_blue.webp';
import Button from '../Common/Button';
import TripSummaryOmnitureTracker from './tracking/TripSummaryOmnitureTracker';
import { getStaticData, ENGLISH, LANGUAGE_COOKIE } from '../staticData/staticData';
import {
  BookingState,
  Lobs,
  BookingStates,
  ApiRequestTypes,
  BookingStatusCode,
  PAGE_BOOKINGS_STATUS,
  MyTripsCards,
  MyTripsCardsTitle,
  MyTripsCardsVisibility
} from './summaryConstants';
import SwitchCard from './components/SwitchCard';
import { setCookie } from '../Common/commonUtil';
import styles from './summaryCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import useApi from '../Common/useApi';
import { LOST_BOOKING_SUMMARY_URL, getHomeLink, TRIP_IDEAS_SUMMARY_URL, HOLIDAY_PLANNING_DETAILS_URL } from '../utils/NetworkUtils';
import { EMPTY_LIST_ICON, NO_TRIPS_ICON, NO_TRIPS_ICON_LARGE } from '../flights/FlightImageConstants';
import {
  POST_SALES_BOOKING_SUMMARY_PAGE,
  POST_SALES_WEB_BOOKING_SUMMARY_PAGE,
} from '@mmt/legacy-commons/adsConfig/adsConfigConstants';
import { getAdTechCard } from '../Common/commonUtil';
import LinearGradient from 'react-native-linear-gradient';
import { setRecentBkgInfo } from './components/cards/lostID/lostIdCardAdapter';
import PaymentStatusCard from './components/cards/lostID/paymentStatusCard';
import { getFont } from '../PostSalesStyles';
import { LOBNAMES } from '../PostSalesConstant';
import { getTranslatedTextFor, isIosClient, isTripIdeaCardEnabled } from '../utils/PostSaleUtil';
import TripIdeasCard from './components/cards/TripIdeas';
import { isEnglishLangSelected } from '@mmt/legacy-commons/AppState/AppUserConfig';
import { isPreSalesEnabled } from '../utils/PostSaleUtil';
import BaseButton, { BUTTON_VARIANT } from '../Common/BaseButton';

const ViewState = {
  LOADING: 'loading',
  LOADED: 'loaded',
  EMPTY: 'empty',
  ERROR: 'error',
};

function MyTripsHeader({ onRefresh, hideBack }) {
  const fonts = getFont(true);
  const Header = SimpleHeader;
  const _backIcon = Platform.OS === 'ios' ? (hideBack ? null : backIcon) : backIcon;
  const staticData = getStaticData();
  return (
    <Header
      icon={_backIcon}
      title={staticData.myTripsText}
      rightIcon={refreshIcon}
      textFont={fonts.bold}
      rightIconPress={() => {
        onRefresh();
        TripSummaryOmnitureTracker.trackNormalClickEvent(
          'mob:customer support:all:mytrips',
          'MyTrips_RefreshTrips',
        );
      }}
      iconPress={() => {
        myTripsNavigation.exitApp();
      }}
    />
  );
}

export function MyTripsLangSwitchCard({ bookingsInOtherLanguage, otherLanguageBookingInfo }) {
  if (bookingsInOtherLanguage && !isEmpty(otherLanguageBookingInfo)) {
    const languageSwitchHandler = (type) => {
      if (type === 'SWITCH_ENG') {
        if (Platform.OS === 'web') {
          setCookie(LANGUAGE_COOKIE, ENGLISH);
          location.reload();
        } else {
          const appSettingsDeepLink = isIosClient() ? "mmyt://app/pref" : "mmyt://app/settings/";
          GenericModule.openDeepLink(appSettingsDeepLink);
        }
      }
    };
    const {
      action: { title, type },
      heading,
      subHeading,
    } = otherLanguageBookingInfo;
    return (
      <SwitchCard
        clickHandler={languageSwitchHandler}
        type={type}
        title={title}
        heading={heading}
        subHeading={subHeading}
      />
    );
  }
  return null;
}

export const renderEmptyRecentFailedView = ({ title, data }) => {
  const { fsStyle, ...fonts}  = getFont(true);
  const { noRecentFailedBkgText } = getStaticData();
  if (title && title.includes('RECENT')) {
    return (
      <>
        <Text style={[styles.recentCardHeading, fonts.boldFontFamily, fsStyle.font13]}>
          {title.replace('RECENT', getTranslatedTextFor('RECENT'))}
        </Text>
        {data.length === 0 && (
          <View style={styles.emptyListWrapper}>
            <Image source={EMPTY_LIST_ICON} style={styles.emptyListIcon} />
            <Text
              style={[
                fsStyle.font14,
                fonts.regularFontFamily,
                AtomicCss.defaultText,
                AtomicCss.lineHeight20,
                AtomicCss.textCenter,
              ]}
            >
              {noRecentFailedBkgText}
            </Text>
          </View>
        )}
      </>
    );
  }
  return null;
};

const renderCardList = (cardData, currentPage, tripIdeasData, bookingCount) => {
  let visibleCardCount = 0;
  const result =
    !!cardData &&
    cardData.map((data, idx) => {
      const { cardId, isSticky, visibleTabs = { [currentPage]: -1 } } = data;

        // apply marginTop10 when there are other cards on top
      const applyMarginTop10 = idx !== 0 && visibleCardCount > 0;
      // apply marginTop3 when there are bookings and is the first card
      const applyMarginTop3 = visibleCardCount === 0 && bookingCount > 0 && !isSticky;

      const cardStyle = [
          applyMarginTop10 ? AtomicCss.marginTop10 : null,
          applyMarginTop3 ? AtomicCss.marginTop3 : null
      ];

      let card = null;
      /* if for current Tab we receive 2, than the card should always appear
        if for current Tab we receive 1, then we shall show the card only if there are bookings
        if for current Tab we receive 0, then we shall show the card only if there are no bookings */
      const showCard =
        visibleTabs[currentPage] === MyTripsCardsVisibility.ALWAYS ||
        (visibleTabs[currentPage] === MyTripsCardsVisibility.WITHOUT_BOOKING && bookingCount < 1) ||
        (visibleTabs[currentPage] === MyTripsCardsVisibility.WITH_BOOKING && bookingCount > 0);
      if (showCard) {
        visibleCardCount +=1;
        if (cardId === MyTripsCards.TRIP_IDEAS_CARD && tripIdeasData) {
          card = renderTripIdeasCard(tripIdeasData);
        }
        else if (cardId === MyTripsCards.AD_TECH_CARD) {
          card = renderAdTechCard();
        }
      }
      if(cardId === MyTripsCards.AD_TECH_CARD) {
        return (
          <View
            style={[
              AtomicCss.paddingAll10,
              AtomicCss.alignCenter,
              isSticky ? AtomicCss.whiteBg : '',
              styles.shadowWrapper,
              ...cardStyle
            ]}
          >
            {card}
          </View>
        )
      }
      return <View style={[...cardStyle, isSticky ? AtomicCss.whiteBg : '']}>{card}</View>
    });
  return result;
};

export const renderView = ({
  nonStickyBottomCard,
  stickyBottomCard,
  currentStatus,
  tripIdeasData,
  currentPageBookingCount,
}) => {
  return (
    <View style={[AtomicCss.flex1, AtomicCss.spaceBetween, AtomicCss.flexColumn]}>
      <View style={[AtomicCss.flexColumn, AtomicCss.flex1]}>
        {!!nonStickyBottomCard &&
          renderCardList(
            nonStickyBottomCard,
            currentStatus,
            tripIdeasData,
            currentPageBookingCount,
          )}
      </View>
      <View>
        {!!stickyBottomCard &&
          renderCardList(
            stickyBottomCard,
            currentStatus,
            tripIdeasData,
            currentPageBookingCount,
          )}
      </View>
    </View>
  );
};

const ALL_BOOKINGS = 'All Bookings';
const SORT_OPTIONS = Object.values(SortTypes);

function BookingList({
  bookings,
  showSortOptions = false,
  recentLostIdDurationInDays,
  recentBookingDisplayLimit = 2,
  headerSection,
  footerSection,
  bookingIdtoTicketDetailmap,
  bottomCardSection,
  tripIdeasData,
  refreshPlanningData,
  hasNonStickyBottomCards,
  bookingCount,
  stickyTopCard
}) {
  const { fsStyle, ...fonts} = getFont(true);
  const selectedLob = ALL_BOOKINGS;
  const selectedSort = SORT_OPTIONS[0];
  const modifiedBookings = useMemo(() => [...bookings], [bookings]);
  const calledBookingIDs = [];
  const prevSelectedSortVal = React.useRef(selectedSort);
  const [filteredBookings, setFilteredBookings] = React.useState([]);
  const [displayOlderBookings, setDisplayOlderBookings] = useState(false);
  const { showFailedBkgText, hideAllText } = getStaticData();
  const [showRecentBookingCount, setShowRecentBookingCount] = useState(recentBookingDisplayLimit);
  const [visibleItemMap, setVisibleItemMap] = useState({});
  const [tripIdeasSeen, setTripIdeasSeen] = useState(false);

  React.useEffect(() => {
    const lobsSet = new Set();
    modifiedBookings.forEach((section) => {
      section.data.forEach((bookingData) => {
        lobsSet.add(bookingData.lobType[0]);
      });
    });
  }, [modifiedBookings]);
  React.useEffect(() => {
    const selectedLobId = Lobs[selectedLob];
    const sortChanged = prevSelectedSortVal.current !== selectedSort;
    if (!selectedLobId && !sortChanged) {
      setFilteredBookings(modifiedBookings);
      return;
    }
    prevSelectedSortVal.current = selectedSort;
    setFilteredBookings(filterData(modifiedBookings, selectedLobId, selectedSort));
  }, [modifiedBookings, selectedLob, selectedSort]);
  const onCheckViewableItems = ({ viewableItems, changed }) => {
    viewableItems &&
      viewableItems.forEach(({ key, section }) => {
        if (
          section &&
          section.pageTitle &&
          section.pageTitle.includes(BookingStates.UNSUCCESSFUL)
        ) {
          setVisibleItemMap((preMap) => ({
            ...preMap,
            [key]: true,
          }));
        }
      });
    const activeBookingCandidates = [
      BookingState.UPCOMING.toUpperCase(),
      BookingState.ONTRIP.toUpperCase(),
    ];
    const bIDs = changed.map((item) => {
      if (!item) {
        return;
      }
      const { hotelDetails, status } = item.item || {};
      if (!hotelDetails || !hotelDetails[0] || !status) {
        return;
      }
      return (
        status &&
        activeBookingCandidates.includes(status.toUpperCase()) &&
        hotelDetails[0].contactlessCheckin &&
        item.key
      );
    });
    const newBIDs = bIDs.filter(
      (bID) =>
        // return only those bookingID which are not present in calledBIDs
        bID && !calledBookingIDs.includes(bID),
    );
    if (newBIDs.length > 0) {
      // setToCallBookingIDs(newBIDs);
    }
  };

  const checkTripIdeasSeen = () => {
    if (tripIdeasData && tripIdeasData.success && !tripIdeasSeen) {
      TripSummaryOmnitureTracker.trackTripIdeasSeenEvent(tripIdeasData);
      setTripIdeasSeen(true);
    }
  };
  const renderOlderBookingCount = ({ title, data }) => {
    if (title && title.includes('OLDER')) {
      return (
        <View style={styles.olderWrapper}>
          <Text
            style={[
              fsStyle.font13,
              fonts.boldFontFamily,
              AtomicCss.blackText,
              AtomicCss.marginBottom8,
            ]}
          >
            {title.replace('OLDER', getTranslatedTextFor('OLDER'))}
          </Text>
          {title !== 'OLDER (0)' && (
            <TouchableOpacity
              activeOpacity={0.8}
              style={[AtomicCss.flexRow, AtomicCss.alignCenter]}
              onPress={() => {
                !displayOlderBookings &&
                  TripSummaryOmnitureTracker.trackNormalClickEvent(
                    'mob:customer support:all:mytrips',
                    'older_unsuccessful_showall_clicked',
                  );
                setDisplayOlderBookings(!displayOlderBookings);
              }}
            >
              <Text style={[fsStyle.font14, fonts.boldFontFamily, AtomicCss.azure]}>
                {!displayOlderBookings ? showFailedBkgText : hideAllText}
              </Text>
              <Image
                source={arrowIcon}
                style={[styles.downArrow, displayOlderBookings ? styles.activeStyle : null]}
              />
            </TouchableOpacity>
          )}
        </View>
      );
    }
    return null;
  };

  const hideTitleWhen = ({ pageTitle, title }) => {
    if (pageTitle === BookingStates.UNSUCCESSFUL) {
      if (title.includes('OLDER') || title.includes('RECENT')) {
        return true;
      } else if (!displayOlderBookings) {
        return true;
      }
    }
    return false;
  };
  return (
    <View collapsable={false}>
      {stickyTopCard}
      <SectionList
        sections={filteredBookings}
        keyExtractor={(item) => item.bookingID || item.bookingId}
        renderItem={({ item, index, section }) => {
          const { title } = section || {};
          if (
            title === MyTripsCardsTitle.AD_TECH_CARD_TITLE
          )
            return null;
          if (
            section.pageTitle === BookingStates.UNSUCCESSFUL &&
            section.title.includes('RECENT')
          ) {
            const { moreUnsuccessfulText } = getStaticData();
            return (
              <>
                {index < showRecentBookingCount ? (
                  <RenderLobCard
                    item={item}
                    displayOlderBookings={displayOlderBookings}
                    section={section}
                    bookingIdtoTicketDetailmap={bookingIdtoTicketDetailmap}
                    visibleItemMap={visibleItemMap}
                    onRefreshPlannings={refreshPlanningData}
                  />
                ) : null}
                {section.data.length > showRecentBookingCount &&
                  index === showRecentBookingCount &&
                  moreUnsuccessfulText && (
                    <TouchableOpacity
                      style={styles.recentMore}
                      onPress={() => {
                        TripSummaryOmnitureTracker.trackNormalClickEvent(
                          'mob:customer support:all:mytrips',
                          'recent_unsuccessful_viewmore_clicked',
                        );
                        setShowRecentBookingCount(section.data.length);
                      }}
                    >
                      <Text
                        style={[
                          fsStyle.font14,
                          fonts.boldFontFamily,
                          AtomicCss.azure,
                          AtomicCss.lineHeight20,
                        ]}
                      >
                        {moreUnsuccessfulText
                          .replace('{remainingCount}', section.data.length - showRecentBookingCount)
                          .replace('{noOfDays}', recentLostIdDurationInDays)}
                      </Text>
                    </TouchableOpacity>
                  )}
              </>
            );
          }
          return (
            <RenderLobCard
              item={item}
              displayOlderBookings={displayOlderBookings}
              section={section}
              bookingIdtoTicketDetailmap={bookingIdtoTicketDetailmap}
              visibleItemMap={visibleItemMap}
              onRefreshPlannings={refreshPlanningData}
            />
          );
        }}
        onViewableItemsChanged={onCheckViewableItems}
        onEndReached={checkTripIdeasSeen}
        viewabilityConfig={{
          itemVisiblePercentThreshold: 50, // if 50% of the item is visible, execute onViweableItemsChanged prop
        }}
        ListHeaderComponent={headerSection}
        ListFooterComponent={() => (
          <>
            {footerSection}
            {bottomCardSection}
            <View style={{ height: showSortOptions ? 72 : 0 }} />
          </>
        )}
        stickySectionHeadersEnabled={true}
        contentContainerStyle={
          !hasNonStickyBottomCards && !bookingCount > 0
            ? [AtomicCss.justifyCenter, AtomicCss.flexGrow1]
            : ''
        }
        renderSectionHeader={({ section }) => {
          if (section.title === 'AdTechSection') {
            if (!isEmpty(section.data) && !isEmpty(section.data[0].sectionTitle)) {
              return (
                <Text style={[styles.vaccineSectionHeader, fonts.regularFontFamily, fsStyle.font12]}>
                  {section.data[0].sectionTitle}
                </Text>
              );
            }
            return null;
          } else {
            return (
              <>
                {(section.title !== 'Others (0)' || section.title !== 'Upcoming (0)') &&
                  !hideTitleWhen(section) && (
                    <Text style={[styles.sectionHeader, fonts.semiBoldFontFamily, fsStyle.font16 ]}>
                      {section.title}
                    </Text>
                  )}
                {renderEmptyRecentFailedView(section)}
                {renderOlderBookingCount(section)}
              </>
            );
          }
        }}
      />
    </View>
  );
}

export const RenderLobCard = ({
  item,
  displayOlderBookings,
  section,
  bookingIdtoTicketDetailmap,
  visibleItemMap,
  onRefreshPlannings,
}) => {
  const isUnsuccessfulRecentTab = section && section.pageTitle === BookingStates.UNSUCCESSFUL;
  const getReqNos = useMemo(
    () =>
      isUnsuccessfulRecentTab && item.refundDetails && item.refundDetails.psrRequests
        ? item.refundDetails.psrRequests.map((data) => data.psrNo)
        : null,
    [item.refundDetails, isUnsuccessfulRecentTab],
  );

  if (
    isUnsuccessfulRecentTab &&
    !displayOlderBookings &&
    !item.isRecentLostBooking &&
    (item.status === BookingStates.FAILED || item.bookingStatus === BookingStatusCode.FAILED)
  ) {
    return null;
  }

  return (
    <>
      <LobCards
        response={item}
        bookingIdtoTicketDetailmap={bookingIdtoTicketDetailmap}
        visibleItemMap={visibleItemMap}
        onRefreshPlannings={onRefreshPlannings}
      />
      {visibleItemMap[item.bookingID || item.bookingId] &&
        isUnsuccessfulRecentTab &&
        !item.isLostBooking &&
        item.isRecentLostBooking &&
        getReqNos && (
          <View style={{ backgroundColor: '#fff', top: -5 }}>
            <PaymentStatusCard bookingId={item.bookingID} getReqNos={getReqNos} />
          </View>
        )}
    </>
  );
};

function renderAdTechCard(cardStyle = []) {
  return(
    <View style={[...cardStyle, AtomicCss.borderRadius4]}>
      {getAdTechCard(
        Platform.OS !== 'web' ? POST_SALES_BOOKING_SUMMARY_PAGE : POST_SALES_WEB_BOOKING_SUMMARY_PAGE,
      )}
    </View>
  )
}

function renderTripIdeasCard(tripIdeasData, cardStyle = []) {
  if (Platform.OS !== 'web' && isEnglishLangSelected()) {
    if (!tripIdeasData.success) {
      TripSummaryOmnitureTracker.trackTripIdeasErrorEvent(tripIdeasData);
    } else {
      return <TripIdeasCard response={tripIdeasData} cardStyle={cardStyle} />;
    }
  }
}

function EmptyDataScreen({ loadData, mainText, subText, buttonText, showLargeIcon = false }) {
  const {fsStyle, ...fonts} = getFont(true);
  return (
    <View style={[styles.errorStateWraper, AtomicCss.flex1]}>
      <Image
        style={showLargeIcon ? styles.errorImgLrgStyle : styles.errorImgStyle}
        source={showLargeIcon ? NO_TRIPS_ICON_LARGE : NO_TRIPS_ICON}
      />
      {!isEmpty(mainText) && (
        <Text style={[styles.errorStateTxt, fonts.blackFontFamily, fsStyle.font16]}>{mainText}</Text>
      )}
      {!isEmpty(subText) && (
        <Text style={[styles.errorStateSubTxt, fonts.regularFontFamily, fsStyle.font12]}>{subText}</Text>
      )}
      {loadData && !!buttonText && (
        <View style={styles.buttonWrapper}>
            <BaseButton
              variant={BUTTON_VARIANT.CAPSULE}
              text={buttonText}
              clickHandler={loadData}
              textStyle={{...fonts.regularFontFamily, ...fsStyle.font14}}
            />
        </View>
      )}
    </View>
  );
}
function BookingSummary(props) {
  const [viewState, setViewState] = useState(ViewState.LOADING);
  const [lostIdProgress, lostIdData, lostIdApi] = useApi(LOST_BOOKING_SUMMARY_URL);
  const [tripIdeasProgress, tripIdeasData, tripIdeasApi] = useApi(TRIP_IDEAS_SUMMARY_URL);
  const [planningProgress, plannings, planningsApi] = useApi(HOLIDAY_PLANNING_DETAILS_URL);
  const [bookings, setBookings] = useState({});
  const [tripIdeasDataLoaded, setTripIdeasDataLoaded] = useState(false);
  const [bookingIdtoTicketDetailmap, setTicketsMap] = React.useState([]);
  const [currentStatus, updateCurrentStatus] = useState(PAGE_BOOKINGS_STATUS.UPCOMING);
  const hideBack = props?.hideBack === 'true';
  const staticData = getStaticData();
  const { bookingSummaryText, refreshText } = staticData;
  const { fsStyle, ...fonts} = getFont(true);
  const {
    statusWiseMessage,
    errorMessage,
    emptyBookingSummaryMessage,
    showAdTechCard,
    otherLanguageBookingInfo,
    bookingsInOtherLanguage,
    cardDetail = {}
  } = bookings || {};

  let pages = getBookingStatusSections(bookings, lostIdData);
  if (!isEmpty(plannings) && plannings.success) {
    pages = getPagesWithPlanningData(pages, plannings);
  }

  const currentPageBookingCount = useMemo(() => {
    const pageData = pages.filter((page) => {
      const { pageTitle } = page;
      return pageTitle.includes(currentStatus);
    });
    const filteredBooking =
      pageData?.length &&
      pageData[0].data?.filter((pageInfo) => {
        const { title } = pageInfo;
        return (
          title !== MyTripsCardsTitle.AD_TECH_CARD_TITLE
        );
      });
    return Array.isArray(filteredBooking) ? filteredBooking.length : 0;
  }, [pages, currentStatus]);

  const [stickyBottomCard, nonStickyBottomCard, nonStickyBottomCardCount] = useMemo(() => getMyTripsCardsInfo(
    cardDetail.bottomCardList || []
  ), [cardDetail.bottomCardList]);

  const [nonStickyTopCard, stickyTopCard] = useMemo(() => {
    // getMyTripsCardsInfo returns sticky and nonSticky cards among other infos. Extracing them for rendering
    const cards = cardDetail?.topCardList && getMyTripsCardsInfo(cardDetail.topCardList || []);
    const nonStickyCard =  cards?.length > 1 && renderCardList(cards[1], currentStatus, tripIdeasData, currentPageBookingCount);
    const stickycard = cards?.length > 0 && renderCardList(cards[0], currentStatus, tripIdeasData, currentPageBookingCount);
    return [nonStickyCard, stickycard];
  }, [cardDetail.topCardList, currentPageBookingCount, tripIdeasData, currentStatus]);

  const openHomePageSection = () => {
    if (Platform.OS === 'android') {
      myTripsNavigation.exitApp();
    } else {
      GenericModule.openDeepLink(Platform.OS === 'web' ? getHomeLink() : 'mmyt://app/home/');
    }
  };

  const setMsrTickets = () => {
    fetchTickets().then((data) => {
      if (data) {
        setTicketsMap(data);
      }
    });
  };

  React.useEffect(() => {
    setMsrTickets();
  }, []);

  const loadData = useCallback(() => {
    setViewState(ViewState.LOADING);
    fetchV2Bookings()
      .then((_bookings) => {
        resetSummaryData();
        setBookings(_bookings);
        if (_bookings.responseCode === 'FAILED') {
          setViewState(ViewState.ERROR);
        } else {
          setViewState(_bookings.bookingDetails.length > 0 ? ViewState.LOADED : ViewState.EMPTY);
        }
      })
      .catch(() => {
        TripSummaryOmnitureTracker.trackErrorEvent(
          'mob:error:customer support:APIResponse',
          'MY_ININERARY_API_ERROR',
        );
        setViewState(ViewState.ERROR);
      });
  }, []);

  const loadLostIdData = useCallback(() => {
    lostIdApi.httpGet({
      bookingId: null,
      uniqueId: ApiRequestTypes.LOSTID_SUMMARY,
      psLob: LOBNAMES.POST_SALES,
    });
  }, [lostIdApi]);

  const loadPlanningData = useCallback(() => {
    planningsApi.httpGet({
      uniqueId: ApiRequestTypes.PLANNING_DETAILS,
    });
  }, [planningsApi]);

  const loadTripIdeasData = useCallback(
    (_bookings) => {
      if (Object.keys(_bookings).length > 0) {
        tripIdeasApi.httpPost({
          body: {
            bookings: prepareTripIdeasData(_bookings),
          },
        });
      }
    },
    [tripIdeasApi],
  );

  const loadBookingData = useCallback(() => {
    loadData();
    loadLostIdData();
    setRecentBkgInfo();
  }, [loadData, loadLostIdData]);

  React.useEffect(() => {
    loadBookingData();
  }, [loadBookingData]);

  React.useEffect(() => {
    if (isTripIdeaCardEnabled()) {
      loadTripIdeasData(bookings);
    }
  }, [bookings]);

  React.useEffect(() => {
    if (tripIdeasData && tripIdeasData.success && !tripIdeasProgress && !tripIdeasDataLoaded) {
      TripSummaryOmnitureTracker.trackTripIdeasLoadEvent(tripIdeasData);
      setTripIdeasDataLoaded(true);
    }
  }, [tripIdeasData, tripIdeasDataLoaded, tripIdeasProgress]);

  React.useEffect(() => {
    bookings && TripSummaryOmnitureTracker.trackLoadEvent(bookings ? bookings.bookingDetails : []);
  }, [bookings]);

  React.useEffect(() => {
    if (isPreSalesEnabled() && isEnglishLangSelected()) {
      loadPlanningData();
    }
  }, [loadPlanningData]);

  const topRecentBkg = useMemo(() => {
    const unsuccessTabdata = pages.find((item) =>
      item.pageTitle.includes(BookingStates.UNSUCCESSFUL),
    );
    const recentData = unsuccessTabdata
      ? unsuccessTabdata.data.find((item) => item.title.includes('RECENT'))
      : null;
    const bookingDate =
      recentData && recentData.data && recentData.data[0]
        ? recentData.data[0].bookingDateTime || recentData.data[0].maxArrDate
        : null;
    return bookingDate ? `${bookingDate}__${unsuccessTabdata.recentCount}` : null;
  }, [pages]);

  const updateCurrentTab = (tabName) => {
    let currentPage = BookingStates.UPCOMING;
    if (tabName.indexOf(PAGE_BOOKINGS_STATUS.CANCELLED) !== -1)
      currentPage = PAGE_BOOKINGS_STATUS.CANCELLED;
    else if (tabName.indexOf(PAGE_BOOKINGS_STATUS.COMPLETED) !== -1)
      currentPage = PAGE_BOOKINGS_STATUS.COMPLETED;
    else if (tabName.indexOf(PAGE_BOOKINGS_STATUS.UNSUCCESSFUL) !== -1)
      currentPage = PAGE_BOOKINGS_STATUS.UNSUCCESSFUL;
    else if (tabName.indexOf(PAGE_BOOKINGS_STATUS.PLANNING) !== -1)
      currentPage = PAGE_BOOKINGS_STATUS.PLANNING;
    updateCurrentStatus(currentPage);
  };

  if (viewState === ViewState.LOADING || planningProgress || lostIdProgress) {
    const { loadingYourTripText } = getStaticData(true, true);
    return (
      <View style={{ flex: 1 }}>
        <ProgressView message={loadingYourTripText ? loadingYourTripText : ''} />
      </View>
    );
  }

  if (viewState === ViewState.ERROR && (!pages || pages.length === 0)) {
    return (
      <View style={AtomicCss.flex1}>
        <MyTripsHeader onRefresh={loadBookingData} hideBack={hideBack} />
        <EmptyDataScreen
          loadData={loadBookingData}
          mainText={
            !isEmpty(errorMessage)
              ? errorMessage.heading
              : bookingSummaryText.additionalText.noBookingErrorText
          }
          buttonText={!isEmpty(errorMessage) ? errorMessage.ctaText : refreshText}
        />
        {renderView({
          nonStickyBottomCard,
          stickyBottomCard,
          currentStatus,
          tripIdeasData,
          currentPageBookingCount: 0
        })}
      </View>
    );
  }

  if (!pages || pages.length === 0) {
    TripSummaryOmnitureTracker.trackErrorEvent(
      'mob:error:customer support:APIResponse',
      'MY_ININERARY_NO_BOOKINGS_ERROR',
    );
    return (
      <View style={styles.emptyWrapper}>
        <MyTripsHeader onRefresh={loadBookingData} hideBack={hideBack} />
        {Platform.OS !== 'web' && (
          <MyTripsLangSwitchCard
            bookingsInOtherLanguage={bookingsInOtherLanguage}
            otherLanguageBookingInfo={otherLanguageBookingInfo}
          />
        )}
        {!isEmpty(emptyBookingSummaryMessage) && (
          <EmptyDataScreen
            loadData={openHomePageSection}
            mainText={emptyBookingSummaryMessage.heading}
            subText={emptyBookingSummaryMessage.text}
          />
        )}
        {Platform.OS === 'web' && (
          <MyTripsLangSwitchCard
            bookingsInOtherLanguage={bookingsInOtherLanguage}
            otherLanguageBookingInfo={otherLanguageBookingInfo}
          />
        )}
        {renderView({
          nonStickyBottomCard,
          stickyBottomCard,
          currentStatus,
          tripIdeasData,
          currentPageBookingCount: 0
        })}
      </View>
    );
  }

  const pageNames = pages.map((s) => {
    return s.recentCount ? `(${s.recentCount}) ${s.pageTitle}` : s.pageTitle;
  });
  const { recentLostIdDurationInDays, recentBookingDisplayLimit } = lostIdData || {};
  const hasNonStickyBottomCards = !!(currentPageBookingCount > 0
    ? nonStickyBottomCardCount[`NON_STICKYCARD_${currentStatus}`].nonEmptyListingCount
    : nonStickyBottomCardCount[`NON_STICKYCARD_${currentStatus}`].emptyListingCount);
  return (
    <View style={currentPageBookingCount > 0 ? styles.mainWrapper : [AtomicCss.greyBg, AtomicCss.flex1]}>
      <MyTripsHeader onRefresh={loadBookingData} hideBack={hideBack} />
      {Platform.OS !== 'web' && (
        <MyTripsLangSwitchCard
          bookingsInOtherLanguage={bookingsInOtherLanguage}
          otherLanguageBookingInfo={otherLanguageBookingInfo}
        />
      )}
      <ViewPagerWithTabs tabs={pageNames} topRecentBkg={topRecentBkg} displayTabs={pageNames} updateCurrentTab={updateCurrentTab}>
        {pages.map((page, index) => (
          <View style={AtomicCss.flex1} key={index}>
            <BookingList
              key={index}
              stickyTopCard={stickyTopCard}
              headerSection={
                <>
                  {nonStickyTopCard}
                </>
              }
              footerSection={
                showEmptyScreenActiveTab(page) &&
                !isEmpty(statusWiseMessage) && (
                  <EmptyDataScreen
                    loadData={openHomePageSection}
                    mainText={statusWiseMessage[page.pageTitle.toLowerCase()].heading}
                    subText={statusWiseMessage[page.pageTitle.toLowerCase()].text}
                    showLargeIcon={!hasNonStickyBottomCards}
                  />
                )
              }
              bottomCardSection={
                !!nonStickyBottomCard &&
                renderCardList(
                  nonStickyBottomCard,
                  currentStatus,
                  tripIdeasData,
                  currentPageBookingCount,
                )
              }
              tripIdeasData={tripIdeasData}
              bookings={page.data}
              refreshPlanningData={loadPlanningData}
              showSortOptions={false}
              recentLostIdDurationInDays={recentLostIdDurationInDays}
              recentBookingDisplayLimit={recentBookingDisplayLimit}
              bookingIdtoTicketDetailmap={bookingIdtoTicketDetailmap}
              bookingCount={currentPageBookingCount}
              hasNonStickyBottomCards={hasNonStickyBottomCards}
            />
           </View>
          ))}
      </ViewPagerWithTabs>
      {Platform.OS === 'web' && (
        <MyTripsLangSwitchCard
          bookingsInOtherLanguage={bookingsInOtherLanguage}
          otherLanguageBookingInfo={otherLanguageBookingInfo}
        />
      )}
      {!!stickyBottomCard &&
        renderCardList(
          stickyBottomCard,
          currentStatus,
          tripIdeasData,
          currentPageBookingCount,
        )}
    </View>
  );
}

export default BookingSummary;
