import { StyleSheet, Platform, Dimensions } from 'react-native';
import { colors, fonts } from '@mmt/legacy-commons/Styles/globalStyles';

const styles = StyleSheet.create({
  greenText: {
    color: '#1a7971',
  },
  blueText: {
    color: '#008cff',
  },
  imageStyle: {
    width: 80,
    height: 56,
    backgroundColor: '#ebebeb',
    borderRadius: 4
  },
  BlackText: {
    color: colors.black
  },
  blackFont: {
    fontFamily: fonts.black
  },
  font12: {
    fontSize: 12
  },
  GreyBg: {
    backgroundColor: '#f6f6f6'
  },
  topStatusCard: {
    paddingTop: 10,
    paddingBottom: 20,
    zIndex: 10,
    position: 'relative',
    ...Platform.select({
      ios: {
        backgroundColor: 'transparent'
      }
    }),
    marginTop: -67
  },
  topStatusBar: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  backarrow: {
    width: 16,
    height: 16
  },
  mar10Bt: {
    marginBottom: 10
  },
  mar15Bt: {
    marginBottom: 15
  },
  StatusCardWrapper: {
    paddingLeft: 50,
    paddingRight: 50
  },
  transperantBgrnd: {
    ...Platform.select({
      ios: {
        backgroundColor: 'transparent'
      }
    })
  },
  alignCenter: {
    alignItems: 'center'
  },
  font18: {
    fontSize: 18
  },
  flexRow: {
    flexDirection: 'row'
  },
  booingDtlsTxt: {
    color: '#747474',
    fontSize: 12,
    fontFamily: fonts.regular,
    lineHeight: 14,
    marginRight: 15
  },
  booingDtlsbold: {
    color: '#747474',
    fontSize: 12,
    fontFamily: fonts.bold,
    lineHeight: 14
  },
  AlignRight: {
    textAlign: 'right'
  },
  topStatusIcon: {
    width: 70,
    height: 70
  },
  failedIcon: {
    width: 61,
    height: 35,
    alignSelf: 'center',
    marginTop: 10,
    marginBottom: 10
  },
  AlignCenter: {
    textAlign: 'center'
  },
  blueText: {
    color: '#008cff',
  },
  gradientStyle: {
    padding: 16,
    borderRadius: 4,
  },
  socialLink: {
    width: 34,
    height: 34,
    marginRight: 5,
  },
  shareLinkStyle: {
    borderRadius: 4,
    backgroundColor: "#fff",
    borderColor: '#008cff',
    borderWidth: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 10,
    marginBottom: 16
  },
  shareBlueStyle: {
    width: 24,
    height: 24,
    marginRight: 10,
  },
  copyLinkStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 5
  },
  copyBlueStyle: {
    width: 20,
    height: 10,
    marginRight: 6
  },
  MamiCard: {
    backgroundColor: '#fff'
  },
  CardContent: {
    backgroundColor: '#fff',
    borderBottomWidth: 1,
    borderBottomColor: '#e4e4e4',
    flexDirection: 'row',
    padding: 15,
    elevation: 1,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1
    },
    shadowOpacity: 0.3,
    shadowRadius: 1
  },
  CardIcon: {
    width: 24,
    height: 24,
    marginRight: 15
  },
  ContentPortion: {
    flex: 1,
  },
  mar5Bt: {
    marginBottom: 5
  },
  ActionBtn: {
    backgroundColor: '#fff',
    paddingBottom: 10,
    marginTop: 15
  },
  hideView: {
    display: 'none'
  },
  CardContentOuter: {
    flex: 1,
    height: window.height,
    width: window.width,
    marginBottom: 10
  },
  CardContentFull: {
    backgroundColor: colors.white,
    padding: 18,
    paddingBottom: 20,
    borderBottomWidth: 0.5,
    borderBottomColor: '#e4e4e4'
  },
  cardContent: {
    backgroundColor: colors.white,
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 20
  },
  CardIcon2: {
    width: 24,
    height: 24,
    marginRight: 10
  },
  hostReplyInfo: {
    marginTop: -13
  },
  defaultText: {
    color: colors.defaultTextColor
  },
  Paymentrow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  PaymentLeft: {
    flex: 0.7
  },
  spaceAround: {
    justifyContent: 'space-around'
  },
  centerText: {
    textAlignVertical: 'center'
  },
  flex1: {
    flex: 1
  },
  gradientStyle: {
    padding: 16,
    borderRadius: 4,
  },
  blueText: {
    color: '#008cff',
  },
  shareLinkStyle: {
    borderRadius: 4,
    backgroundColor: "#fff",
    borderColor: '#008cff',
    borderWidth: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 10,
    marginBottom: 16
  },
  shareBlueStyle: {
    width: 24,
    height: 24,
    marginRight: 10,
  },
  copyLinkStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 5
  },
  copyBlueStyle: {
    width: 20,
    height: 10,
    marginRight: 6
  },
  GcTyIconBox: {
    width: 30,
    height: 30,
    borderRadius: 50,
    backgroundColor: '#fff',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 8
  },
  GcTyIcon: {
    width: 16,
    height: 13,
  },
  borderbtm: {
    borderBottomWidth: 1,
    borderBottomColor: '#ddd'
  },
  padVertical10: {
    paddingVertical: 10
  },
  greenText2: {
    color: '#3eb584'
  },
  pad10Top: {
    paddingTop: 10
  },
  ActionBtnRec: {
    backgroundColor: '#fff'
  },
  ActionBtn: {
    backgroundColor: '#fff',
    paddingBottom: 10,
    marginTop: 15
  },
  overlayBg: {
    backgroundColor: 'rgba(1,1,0.5,.5)',
    position: 'absolute',
    top: 0,
    left: 0,
    height: Dimensions.get('window').height,
    width: Dimensions.get('window').width,
    zIndex: 999,
    elevation: 7
  },
  detailsInfoSection: {
    marginTop: 10,
    backgroundColor: colors.creamWhite,
    borderRadius: 4,
    paddingHorizontal: 10,
    paddingVertical: 12,

  },
  orderTrackIconStyle: {
    width: 30,
    height: 30,
  },
  gcSummaryCopyIconStyle: {
    width: 16,
    height: 16,
    marginLeft: 10,
  },
  gcSummaryGiftCardWrapper: {
    backgroundColor: colors.white,
    marginBottom: 10,
    paddingVertical: 20,
    paddingHorizontal: 16,
  },
  gcSummaryGiftCardInnerDtls: {
    paddingVertical: 12,
    backgroundColor: colors.creamWhite,
    borderRadius: 4,
  },
  gcSummaryHeadingText: {
    color: colors.black,
    fontFamily: fonts.black,
    fontSize: 14,
    paddingHorizontal: 10,
  },

  gcSummaryGiftDetailsSection: {
    paddingVertical: 20,
    borderBottomColor: colors.lightYello,
    borderBottomWidth: 1,
  },
  lastSection: {
    borderBottomColor: 'transparent',
    borderBottomWidth: 0,
  },
  gcSummaryGiftCardTopInfo: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingRight: 10,
  },
  gcSummaryBorderLeftHighLiter: {
    width: 3,
    backgroundColor: colors.lightYello,
    height: 30,
    marginRight: 10,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8
  },
  gcSummaryGiftCardNumberDetails: {
    paddingHorizontal: 8,
    paddingVertical: 10,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: colors.lightGray,
    marginHorizontal: 10,
    backgroundColor: colors.white,
    flexDirection: 'row'
  },
  gcSummaryDownArrow: {
    width: 16,
    height: 16,
  },
  gcSummaryViewMoreWrapper: {
    flexDirection: 'row',
    paddingHorizontal: 10,
  },
  gcSummaryTextRight: {
    textAlign: 'right'
  },
  gcSummarySuccessText: {
    color: colors.lightGreen16,
  }
});

export default styles;
