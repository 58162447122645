import { StyleSheet } from "react-native";
import { Fonts, Theme } from '../../../types';

export default (fonts: Fonts, theme: Theme) => {
    const { color } = theme;
    return StyleSheet.create({
        tripAssuredWrapper: {
            backgroundColor: color.white,
            padding: 16,
            marginBottom: 10,
            marginTop: 10,
            marginRight: 8,
        },
        tripTagContainer: {
            marginBottom: 12,
        },
        circleTickStyle: {
            width: 16,
            height: 17,
            marginRight: 8,
        },
        pointWrapper: {
            flexDirection: 'row',
            alignItems: 'flex-start',
            marginBottom: 12
        },
        lastIndexStyle: {
            marginBottom: 0,
        },
        subHeadingStyle: {
            marginBottom: 12,
            ...fonts.regularFontFamily,
            fontSize: fonts.fontSize.font14,
            color: color.grey
        },
        headingStyle: {

            ...fonts.boldFontFamily,
            fontSize: fonts.fontSize.font16,
            color: color.black,
            marginBottom: 10,
        },
        pointNameStyle: {
            ...fonts.regularFontFamily,
            fontSize: fonts.fontSize.font14,
            color: color.grey,
        },
    });
}


export const getHtmlStyle = (fonts: Fonts, theme: Theme) => {
    const { color } = theme;
    return StyleSheet.create({
        p: {
            fontSize: fonts.fontSize.font16,
            ...fonts.regularFontFamily,
        },
        b: {
            ...fonts.boldFontFamily,
        },
    });
};