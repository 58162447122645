import React from 'react';
import {Image, Text, View, Platform, BackHandler} from 'react-native';
import Actions, { ActionConst } from '../../../navigation/postSalesNavigation';
import getStyles from './HotelMamiCss';
import {getPaymentDueText, isIntlHotelBooking, resetToHotelDetail} from '../utils/HotelBookingDetailUtil';
import PropTypes from 'prop-types';
import HotelBookingDetailsConstant from '../HotelBookingDetailsConstant';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import CtaButton from '@mmt/legacy-commons/Common/Components/Buttons/CtaButton';
import HotelBookingTrackingHelper from '../../HotelBookingTrackingHelper';
import BasePage from '../../../Common/PostSalesBasePage';
import logAction from '@mmt/legacy-commons/Helpers/actionsLogger';
import { getStaticData } from '../../../staticData/staticData';
import { PAHX_SUCCESS_ICON } from '../../HotelImageConstants';
import { getFont, themeColors } from '../../../PostSalesStyles';
import BaseButton, { BUTTON_VARIANT } from '../../../Common/BaseButton';
import ButtonWrapper from 'apps/post-sales/src/Common/ButtonWrapper';

const icon = PAHX_SUCCESS_ICON;

class HotelSuccessfulSubmissionPage extends BasePage {
  constructor(props) {
    super(props,'openSubmitPage');
    this.fonts = getFont(true);
    this.styles = getStyles(this.fonts);
    this.trackPageName = '';
  }

  static navigationOptions = {
    header: null
  };

  render() {

    const {paymentSummary} = this.props.response;
    if (this.props.isFromNameChangeFlow) {
      return this.getViewForNameChangeFlow(this.props.response.submitNameChange, icon);
    } else if (this.props.isFromResendVoucher) {
      return this.getViewForResendVoucher(this.props.response.resendVoucherSuccess,this.props.response.showResendVoucherMessage, icon);
    }
    return this.getViewForPAHXSubmissionFlow(this.props.response.pahxResponse.success, icon, paymentSummary);
  }

  goToHotelDetails = () => {
    if(Platform.OS === "web"){
      const bookingId = this.props.response.bookingID
      Actions.hotelBookingDetail({ bookingId, type: ActionConst.REPLACE});
    }else{
      Actions.popTo('hotelBookingDetail');
    }
  };

  updateHotelDetails = async () => {
    const userInfo = await HotelBookingModule.getRequestHeader(this.props.response.bookingID, 'UPDATE_HOTEL_BOOKING_DETAILS');
    let BOOKING_ID = this.props.response.bookingID;
    resetToHotelDetail(BOOKING_ID);
    HotelBookingTrackingHelper.trackClickEvent(this.trackingPageName, 'MI_HTL_NameChangeViewDetails', this.props.response);
  };

  componentDidMount() {
    BackHandler.addEventListener('hardwareBackPress', this.onHardBackPress);
    super.componentDidMount();
    if (this.props.isFromNameChangeFlow) {
      this.trackLoadEvent('NameChangeSuccess');
    } else if (this.props.isFromResendVoucher) {
      if (this.props.response.resendVoucherSuccess) {
        this.trackLoadEvent('ResendConfirmation:ThankYou_Success');
      } else {
        this.trackLoadEvent('ResendConfirmation:ThankYou_Fail');
      }
    } else {
      this.trackLoadEvent('PAHXNoShowSuccess');
    }
  }

  componentWillUnmount() {
    BackHandler.removeEventListener('hardwareBackPress', this.onHardBackPress);
  }

  trackLoadEvent = (pageName) => {
    this.trackingPageName = isIntlHotelBooking(this.props.response?.lobCode)
      ? `mob:customer support:Hotel International:${pageName}`
      : `mob:customer support:Hotel Domestic:${pageName}`;
    HotelBookingTrackingHelper.trackLoadEvent(this.trackingPageName, this.props.response);
  };

  getViewForPAHXSubmissionFlow = (result, icon, paymentSummary) => {
    const staticData = getStaticData();
    return (
      <View style={this.styles.ErrorStateWrapper}>
        <Image style={[this.styles.dateChangeIcon, this.styles.mar50Bt, this.styles.CenterText]} source={icon}/>
        <View style={[result === true ? this.styles.showView : this.styles.hideView, this.styles.mar50Bt]}>
          <Text style={[this.styles.ErrorPageText, this.styles.boldFont]}>{staticData.hotelSuccessfulSubmissionPageText.additionalText.cancellationInitiatedText}</Text>
          <Text style={[this.styles.AlignCenter, this.styles.lineHt22]}>
            {this.props.paymentSummary.bookingCancelledAndNoShowPenaltyText || `Your Booking will be cancelled and the
            amount of ${getPaymentDueText(paymentSummary, this.props.response.paymentDetails)} (No-
            Show Penalty) will be charged on your card`}</Text>
        </View>
        <View style={[result === false ? this.styles.showView : this.styles.hideView, this.styles.mar50Bt]}>
          <Text style={[this.styles.ErrorPageText, this.styles.boldFont]}>{staticData.hotelSuccessfulSubmissionPageText.additionalText.submissionSuccessfulText}</Text>
          <Text style={[this.styles.AlignCenter, this.styles.lineHt22]}>{staticData.hotelSuccessfulSubmissionPageText.additionalText.amakemytripSupportAgentWillgetInTouchText} {'\n'} {staticData.hotelSuccessfulSubmissionPageText.additionalText.tillThenNoChangesText}</Text>
        </View>
        <ButtonWrapper 
          btnText={staticData.goBackToBookingHotelText}
          btnTxtStyle={this.styles.font12}
          clickHandler={this.goToHotelDetails}
        />
      </View>
    )
  };


  getViewForNameChangeFlow = (nameChangeResponse, icon) => {
    const staticData = getStaticData();
    return (
      <View style={this.styles.ErrorStateWrapper}>
        <Image style={[this.styles.dateChangeIcon, this.styles.mar50Bt, this.styles.CenterText]} source={icon}/>
        <View style={this.styles.mar50Bt}>
          <Text style={[this.styles.ErrorPageText, this.styles.boldFont]}>{staticData.hotelSuccessfulSubmissionPageText.additionalText.thankYouText}</Text>
          <Text
            style={[this.styles.AlignCenter, this.styles.lineHt22]}>{nameChangeResponse.description}</Text>
        </View>
        <View style={this.styles.retryView}>
          <CtaButton label={staticData.hotelSuccessfulSubmissionPageText.additionalText.viewUpdatedBookingText}  buttonGradient={themeColors.gradientBtnColor} onPress={() => {
            this.updateHotelDetails();
          }}/>
        </View>
      </View>
    )
  };

  getViewForResendVoucher = (result, showResendVoucherMessage, icon) => {
    const staticData = getStaticData();
    return (
      <View style={this.styles.ErrorStateWrapper}>
        <Image style={[this.styles.dateChangeIcon, this.styles.mar50Bt, this.styles.CenterText]} source={icon}/>
        {this.showResendVoucherUI(result, showResendVoucherMessage)}
        <ButtonWrapper 
          btnText={staticData.goBackToBookingHotelText}
          btnTxtStyle={this.styles.font12}
          clickHandler={this.goToHotelDetails}
        />
      </View>
    );
  };

  showResendVoucherUI(result, showResendVoucherMessage) {
    const staticData = getStaticData();
    if (result) {
      return (<View style={this.styles.mar50Bt}>
        <Text style={[this.styles.ErrorPageText, this.styles.boldFont]}>{staticData.hotelSuccessfulSubmissionPageText.additionalText.voucherSentSuccessfullyText}</Text>
        <Text style={[this.styles.AlignCenter, this.styles.lineHt22]}>{showResendVoucherMessage || `Your Voucher has been sent to ${this.props.email} and via SMS and WhatsApp to ${this.props.phoneNumber}`}</Text>
      </View>);
    } else {
      return (<View style={this.styles.mar50Bt}>
        <Text style={[this.styles.ErrorPageText, this.styles.boldFont]}>{staticData.hotelSuccessfulSubmissionPageText.additionalText.voucherFailedText}</Text>
        <Text style={[this.styles.AlignCenter, this.styles.lineHt22]}>{staticData.hotelSuccessfulSubmissionPageText.additionalText.oopsUnableToSendYourVoucherText}</Text>
      </View>);
    }
  }

  onHardBackPress = () => {
    Actions.popTo('hotelBookingDetail');
    logAction('HardwareBackPressed');
    return true;
  };
}

HotelSuccessfulSubmissionPage.propTypes = {
  response: PropTypes.object.isRequired,
  isFromNameChangeFlow: PropTypes.bool,
  isFromResendVoucher: PropTypes.bool,
  email: PropTypes.string,
  phoneNumber: PropTypes.string
};
HotelSuccessfulSubmissionPage.defaultProps = {
  isFromNameChangeFlow: false,
  isFromResendVoucher: false,
  email:'',
  phoneNumber:''
};

export default HotelSuccessfulSubmissionPage;
