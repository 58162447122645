import React from 'react';
import { View, Text } from 'react-native';
import TripAssuredTag from '../TripAssuredTag';
import { getActionButton } from '../../utils/BusBookingDetailUtil';
import createStyle from './styleCss';
import { getHtmlStyle } from './styleCss';
import isEmpty from 'lodash/isEmpty';
import HTMLView from 'react-native-htmlview';
import { getFont } from '../../../PostSalesStyles';
import { useTheme } from '../../../theme/theme.context';



interface SpecialClaimDataList {
    popupHeader: string;
    popupNo: string;
    popupYes: string;
    popupTxt: string;
};
interface Action {
    actionRank: number;
    actionId: string;
    actionType: string;
    actionLabeltext: string;
    actionFamily: string;
}
interface TripAssuredClaimRejectedProps {
        tripAssuredClaimRejectData: {
            heading: string;
            subHeading?: string;
            tripAssuredTagData?: {
                text: string;
                iconUrl: string;
            };
        };
        specialClaimDataList?: SpecialClaimDataList;
    card: {
        cardId: string;
        actionList?: Action[];
    }
    onClickEventTracker: (arg: string) => void;
    raiseClaimClicked: (arg: SpecialClaimDataList, triggerEventTracker?: boolean) => void;
}

const TripAssuredClaimRejected: React.FC<TripAssuredClaimRejectedProps> = ({ tripAssuredClaimRejectData,specialClaimDataList, card,onClickEventTracker, raiseClaimClicked }) => {
    if (isEmpty(tripAssuredClaimRejectData)) {
        return null;
    }
    const specialClaimData = specialClaimDataList?.[0] || null;
    const { heading, subHeading, tripAssuredTagData } = tripAssuredClaimRejectData;
    const { fsStyle, ...fonts } = getFont(true);
    const { psTheme: theme } = useTheme();
    const styles = createStyle(fonts, theme);
    const handleRaiseTicket = () => {
        onClickEventTracker(card?.actionList?.[0]?.actionFamily);
        raiseClaimClicked(specialClaimData,false);
    }
    return (
        <View style={styles.claimRejectedWrapper}>
            <View style={styles.claimRejectedContainer}>
                <View style={styles.tripTagContainer}>
                    {!isEmpty(tripAssuredTagData) && <TripAssuredTag tripAssuredTagData={tripAssuredTagData} />}
                </View>
                {!!heading && <Text style={styles.headingStyle}>{heading}</Text>}
                {!!subHeading && <HTMLView
                    style={styles.subHeadingStyle}
                    value={subHeading}
                    stylesheet={getHtmlStyle(fonts, theme)}
                />}
            </View>
            <View>
                <View style={styles.redirectLink}>
                    {!isEmpty(specialClaimData) && getActionButton(card,{},handleRaiseTicket)}
                </View>

            </View>
        </View>
    )
};


export default TripAssuredClaimRejected;