import { StyleSheet } from 'react-native';
import { Fonts, Theme } from '../../../types';

export const getStyles = (fonts : Fonts, theme : Theme) => {
  const { color } = theme;
  return StyleSheet.create({
    fbWrapper: {
      paddingHorizontal: 16,
      paddingTop: 16,
    },
    headingSection: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    headingText: {
      fontSize: fonts.fontSize.font24,
      ...fonts.blackFontFamily,
      color: color.black,
    },
    crossIconStyle: {
      width: 24,
      height: 24,
    },
    bodyWrapper: {
      paddingTop: 20,
    },
    section: {
      borderBottomWidth: 1,
      borderBottomColor: color.grey23,
      paddingBottom: 8,
      paddingTop: 8,
    },
    flexStyle: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    innerInfo: {
      paddingLeft: 10,
      paddingTop: 8,
    },
    paymentLabel:{
      fontSize: fonts.fontSize.font12,
      ...fonts.regularFontFamily,
      color: color.black,
    },
    paymentValue:{
      fontSize: fonts.fontSize.font12,
      ...fonts.boldFontFamily,
      color: color.black,
    }
  });
};
