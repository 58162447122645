const GITheme = {
    primary: '#2274E0',
    background: 'rgb(255,0,0)',
    card: 'red',
    text: '#FFFFFF',
    topCardText:'#FFFFFF',
    border: 'red',
    notification: 'red',
    lightText:'rgba(242, 242, 242, 0.56)',
    gradientBtnColor: ['#2274E0', '#2274E0'],
    linkTextColor: '#2274E0',
    loaderColor: '#2274E0',
    radioColor: '#2274E0',
    backArrowTintColor: '#FFFFFF',
    postSalesLoaderColor: '#2274E0',
    RectFullButtonText:'#2274E0',
    RectFullButtonBackground: '#FFFFFF',
    CapsuleBtnFillColors: ['#2274E0','#2274E0'],
    CapsuleBtnFillTextColor: '#FFFFFF',
    viewMoreText: '#2274E0',
    WhiteFullCapsuleText: '#2274E0',
    rightArrowTintColor: '#2274E0',
    actionTitleColor:'#2274E0',
    activeTabColor:'#2274E0',
    RoundButtonWithIconText:'#2274E0',
    FullWidthButtonGradientColor:['#2274E0', '#2274E0'],
    CapsuleBtnFillColors:['#2274E0', '#2274E0'],
    CapsuleBtnTextColor: '#2274E0',
    topCardGradientColor:['#2274E0','#2274E0'],
    whiteBtnTxt:'#2274E0',
    whiteButtonBorderColor: '#2274E0',
    redirectLinkTextColor: '#2274E0',
    roundBtnBlueText: '#ffffff',
    roundBtnLightBlueText: '#ffffff',
    blueRoundBtnBg: '#2274E0',
    lightBlueRoundBtnBg: '#7195C8',
    roundBtnBlueIconBg:'white',
    roundBtnWhiteIconBg:'white',
    roundBtnLightBlueIconBg:'white',
    confirmedWithCompletePaymentStartColor: '#2274E0',
    confirmedWithCompletePaymentEndColor: '#2274E0',
    confirmedWithPartialPaymentStartColor: '#2274E0',
    confirmedWithPartialPaymentEndColor: '#2274E0',
    cancelledStartColor: '#2274E0',
    cancelledEndColor: '#2274E0',
    failedStartColor: '#2274E0',
    failedEndColor:'#2274E0',
    completeStartColor: '#2274E0',
    completeEndColor: '#2274E0',
    onTripStart: '#2274E0',
    onTripEnd: '#2274E0',
    topCardFontColor1:'#FFFFFF',
    topCardFontColor2:'#FFFFFF',
    Visa_blueStartColor:'#2274E0',
    Visa_blueEndColor:'#2274E0',
    Visa_OrangeStartColor:'#2274E0',
    Visa_OrangeEndColor:'#2274E0',
    Visa_GreenStartColor:'#2274E0',
    Visa_GreenEndColor:'#2274E0',
    Visa_purpleStartColor:'#2274E0',
    Visa_purpleEndColor: '#2274E0',
    Visa_blackStartColor: '#2274E0',
    Visa_blackEndColor: '#2274E0',
    Visa_pinkStartColor: '#2274E0',
    Visa_pinkEndColor: '#2274E0',
    Visa_YellowStartColor: '#2274E0',
    Visa_YellowEndColor: '#2274E0',
    Visa_greyPinkStartColor: '#2274E0',
    Visa_GreyPinkEndColor: '#2274E0',
    Visa_lightblueStartColor: '#2274E0',
    Visa_lightblueendColor: '#2274E0',
    blueStartColor:'#2274E0',
    blueEndColor: '#2274E0',
    blackStartColor: '#2274E0',
    blackEndColor: '#2274E0',
    pinkStartColor: '#2274E0',
    pinkEndColor: '#2274E0'
};
export default GITheme;