import { colors, fonts } from '@mmt/legacy-commons/Styles/globalStyles';

const getStyles = (fonts) => {
  return {
    toastWrapper: {
      zIndex: 1,
      marginBottom: -50,
      position: 'absolute',
      bottom: 0,
      width: '90%',
      left: '5%'
  
    },
    toastTextWrapper: {
      backgroundColor: colors.darkGrey,
      borderRadius: 6
    },
    toastText: {
      color: colors.white,
      ...fonts.font12,
      paddingHorizontal: 16
    },
    toastViewText: {
      color: '#33d18f',
      fontFamily: fonts.bold,
      ...fonts.font10,
    },
    hostMessageView: {
      paddingVertical: 20,
      paddingHorizontal: 16
    }
  
  };
}

export default getStyles;
