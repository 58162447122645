import {openWebView, trackRedirectMsr, trackMsrCardShown} from '../Common/MSR/Utils';

export function msrUpdateAction(msrTicket) {
  if(msrTicket){
    if(msrTicket.msrUrl && msrTicket.msrUpdate && msrTicket.msrUpdate.length > 0){
      trackMsrCardShown(msrTicket, "mob:customer support:all:mytrips");
      return {
       label: msrTicket.msrUpdate,
       onPress:() => {
         trackRedirectMsr(msrTicket, "mob:customer support:all:mytrips");
         openWebView(msrTicket.msrUrl);
       }
     };
    }
  }
  return null;
}

export function getFilteredMsrTicket (response,bookingIdtoTicketDetailmap){
  let filteredTicket;
  if(bookingIdtoTicketDetailmap.size >0 ){
    const bookingId = response.bookingID;
    if (bookingIdtoTicketDetailmap.has(bookingId)){
      filteredTicket = bookingIdtoTicketDetailmap.get(bookingId);
    }
  } 
  return filteredTicket;
}
