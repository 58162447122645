import React from 'react';
import CommonOverlayMessage from 'apps/post-sales/src/Common/CommonOverlayMessage';
import DownloadInvoiceBottomSheet from '../../../../details/components/DownloadInvoice';
import { styles } from './styles';

function BookingInvoice({
  setBookingInvoice,
  bookingId,
}: {
  setBookingInvoice: (args: boolean) => void;
  bookingId: string;
}) {
  return (
    <CommonOverlayMessage
      handleBottomOverlay={() => setBookingInvoice(false)}
      isBorderRadius={true}
      overlayContentStyle={styles.overlayContentStyle}
      overlayWrapperStyle={styles.overlayWrapperStyle}
      content={
        <DownloadInvoiceBottomSheet bookingId={bookingId} closeOverlay={() => setBookingInvoice(false)} />
      }
    />
  );
}

export default BookingInvoice;
