import React from 'react';
import PropTypes from 'prop-types';
import {View, Text, Image} from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {styles} from './CabAmendmentStyles';

class AmendInformationCard extends React.Component {
  render() {
    return (
      <View style={styles.cabInfoWrapper}>
        <View style={[styles.cabInfoContent]}>
          <View style={styles.cabInfoSection}>
            <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween]}>
              <View style={AtomicCss.flex1}>
                <Text style={[AtomicCss.font16, AtomicCss.blackText, AtomicCss.boldFont, AtomicCss.marginBottom8]}>
                  {this.props.amendResponse.clientDetails.carBrand}
                </Text>
                <Text
                  style={[AtomicCss.midGreyText, AtomicCss.font12, AtomicCss.regularFont, AtomicCss.marginBottom16]}
                >
                  {this.props.amendResponse.clientDetails.acSeatInfo}
                </Text>
              </View>
              <Image style={styles.carImg} source={{uri: this.props.response.carBookingInfo.carDetail.cabUrl}} />
            </View>
            <View style={AtomicCss.flexRow}>
              <Text style={[AtomicCss.font12, AtomicCss.midGreyText, AtomicCss.regularFont]}>{this.props.amendResponse.clientDetails.newPickupDateLabel}</Text>
              <Text style={[AtomicCss.font12, AtomicCss.blackText, AtomicCss.regularFont, AtomicCss.marginLeft8]}>
                {this.props.amendResponse.clientDetails.newPickupDate}
              </Text>
            </View>
            <View style={[AtomicCss.flexRow, AtomicCss.paddingTop7]} >
              <Text style={[AtomicCss.font12, AtomicCss.midGreyText, AtomicCss.regularFont]}>{this.props.amendResponse.clientDetails.oldPickupDateLabel}</Text>
              <Text style={[AtomicCss.font12, AtomicCss.blackText, AtomicCss.regularFont, AtomicCss.marginLeft8]}>
                {this.props.amendResponse.clientDetails.oldPickupDate}
              </Text>
            </View>
          </View>
          <View style={[styles.priceRow, AtomicCss.marginBottom10]}>
            <Text style={[AtomicCss.blackText, AtomicCss.boldFont, AtomicCss.font16]}>{this.props.amendResponse.clientDetails.newPriceLabel}</Text>
            <Text style={[AtomicCss.blackText, AtomicCss.boldFont, AtomicCss.font16]}>{this.props.amendResponse.clientDetails.newPrice}</Text>
          </View>
          <View style={[styles.priceRow, AtomicCss.marginBottom5]}>
            <Text style={[AtomicCss.midGreyText, AtomicCss.regularFont, AtomicCss.font14]}>{this.props.amendResponse.clientDetails.oldPriceLabel}</Text>
            <Text style={[AtomicCss.midGreyText, AtomicCss.regularFont, AtomicCss.font14]}>{this.props.amendResponse.clientDetails.oldPrice}</Text>
          </View>
          <View style={[styles.priceRow, AtomicCss.marginBottom5]}>
            <Text style={[AtomicCss.midGreyText, AtomicCss.regularFont, AtomicCss.font14]}>{this.props.amendResponse.clientDetails.fareDiffLabel}</Text>
            <Text style={[this.props.amendResponse.clientDetails.fareDiffWaived ? AtomicCss.lineThrought : null, AtomicCss.midGreyText, AtomicCss.regularFont, AtomicCss.font14]}>{this.props.amendResponse.clientDetails.fareDiff}</Text>
          </View>
          <View style={[styles.priceRow, AtomicCss.marginBottom5]}>
            <Text style={[AtomicCss.midGreyText, AtomicCss.regularFont, AtomicCss.font14]}>{this.props.amendResponse.clientDetails.amendFeeLabel}</Text>
            <Text style={[AtomicCss.midGreyText, AtomicCss.regularFont, AtomicCss.font14]}>{this.props.amendResponse.clientDetails.amendfee}</Text>
          </View>

          {
            this.props.amendResponse.clientDetails.ecouponAmount &&
            <View style={styles.fareBreakupInnerRowSection}>
              <View style={[styles.priceRow]}>
                <View style={AtomicCss.flexRow}>
                  <Text style={styles.successTxt}>{this.props.amendResponse.clientDetails.eCouponLabel}</Text>
                  <View style={styles.cuponCode}>
                    <Text>{this.props.amendResponse.clientDetails.eCouponCode}</Text>
                  </View>
                </View>
                <Text style={[styles.successTxt]}>{this.props.amendResponse.clientDetails.ecouponAmount}</Text>
              </View>
            </View>
          }

        </View>
        <View style={[styles.priceRow, styles.paidPrice]}>
          <Text style={[AtomicCss.blackText, AtomicCss.boldFont, AtomicCss.font16]}>{this.props.amendResponse.clientDetails.alreadyPaidLabel}</Text>
          <Text style={[AtomicCss.blackText, AtomicCss.boldFont, AtomicCss.font16]}>{this.props.amendResponse.clientDetails.alreadyPaidAmount}</Text>
        </View>
        <View style={[styles.priceRow, styles.driverPay]}>
          <View>
            <Text style={[AtomicCss.blackText, AtomicCss.blackFont, AtomicCss.font16, AtomicCss.marginBottom6]}>
              {this.props.amendResponse.clientDetails.youPayLabel}
            </Text>
            {this.props.amendResponse.clientDetails.payToDriver &&
            <Text style={[AtomicCss.blackText, AtomicCss.regularFont, AtomicCss.font12]}>{this.props.amendResponse.clientDetails.payDriverText}</Text>}
          </View>
          <Text style={[AtomicCss.blackText, AtomicCss.boldFont, AtomicCss.font18]}>{this.props.amendResponse.clientDetails.tobePaid}</Text>
        </View>
      </View>
    );
  }
}

AmendInformationCard.propTypes = {
  response: PropTypes.object.isRequired,
  amendResponse: PropTypes.object.isRequired
};

export default AmendInformationCard;
