import React, { useCallback, useEffect } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getFont } from '../../../PostSalesStyles';
import { useTheme } from '../../../theme/theme.context';
import WhiteCard from '../../../Common/WhiteCard';
import LiveTrakingInfoCard from '../LiveTrakingInfoCard';
import { getStaticData } from 'apps/post-sales/src/staticData/staticData';
import {
  ArrivalCardProps,
  LatestTrainData,
  TrainScheduleCardProps,
} from '../../types';
import isEmpty from 'lodash/isEmpty';
import getStyles, { getWhiteCardStyles } from './RailScheduleCss';
import { getSegmentInfo, getTimeStatus } from '../../details/data/helper';
import { REFRESH_ICON_BLUE } from '../../Utils/RailImageConstant';
import { Image } from 'react-native';
import {
  COMPONENT_ID,
  REFRESH_API_HIT,
  TRAIN_DELAY_INFO,
} from '../../details/constants';
import WhiteCardWrapper from '../../../Common/WhiteCardWrapper';
import { getTimeInfoCardData } from '../../details/data/dataAdapter';
import useRailDelay from '../../details/Hooks/railDelayHook';
import { connect } from 'react-redux';
import { ACTIONS_UPDATE_LATEST_TRAIN_DELAY_INFO, updateTrainDelayInfo } from '../../RailAction/RailInfoActionService';
import throttle from 'lodash/throttle';

const TrainTimingInfoCard = ({ heading, time, timeStatus, delay }: ArrivalCardProps) => {
  const { psTheme } = useTheme();
  const { color } = psTheme;
  const { fsStyle, ...fonts } = getFont(true);
  const styles = getStyles(fonts, color);
  const delayStyle = delay ? styles.delayTag : '';
  return (
    <View style={[styles.scheduleArrv]}>
      {!isEmpty(heading) && <Text style={styles.headingStyle}>{heading}</Text>}
      <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginTop10, {maxWidth: '60%'}]}>
        {!isEmpty(time) && <Text style={styles.subHeadingStyle}>{time}</Text>}
        {!isEmpty(timeStatus) && <Text style={[styles.TimeTag, delayStyle]}>{timeStatus}</Text>}
      </View>
    </View>
  );
};

const TrainScheduleCard = ({
  response,
  trainScheduleData,
  trainDelayDispatch,
  latestTrainData
}: TrainScheduleCardProps) => {
  const { boardingPointName } = getSegmentInfo(response);
  const { psTheme } = useTheme();
  const { color } = psTheme;
  const { fsStyle, ...fonts } = getFont(true);
  const styles = getStyles(fonts, color);
  const whiteCardStyles = getWhiteCardStyles(color);
  const staticData = getStaticData();
  const fetchRailDelay = useRailDelay(trainDelayDispatch);
  const { trainScheduleText } = staticData;
  const { bookingID } = response;
  const trainData = !isEmpty(latestTrainData) && latestTrainData || trainScheduleData;
  let { lastUpdatedMsg, delayInMins, sourcePlateformNo, trainDelayed } = trainData;
  const timeStatus = getTimeStatus(trainDelayed, delayInMins, staticData);
  const { scheduleCardHeading, actualCardHeading, scheduledTime, actualTime } = getTimeInfoCardData(
    trainData,
    response,
    staticData,
  );

  useEffect(()=>{
    return (()=>{
      trainDelayDispatch({});
    })
  }, [])

  const onPressHandler = () => {
    fetchRailDelay(bookingID);
  }

  const throttleOnPressFunc = useCallback(throttle( onPressHandler, 3000), []);

  return (
    <View>
      <View style={[styles.headingWrapper]}>
        <View style={AtomicCss.flex1}>
          <Text style={styles.trainScheduleTextStyle}>{trainScheduleText}</Text>
        </View>
        <View style={{width: '40%', alignItems: 'flex-end'}}>
          <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
            <Text style={styles.updatedMsgStyles}>{lastUpdatedMsg}</Text>
            <TouchableOpacity
              activeOpacity={0.6}
              style={[AtomicCss.alignSelfFlexEnd]}
              onPress={ throttleOnPressFunc }
            >
              <Image style={styles.refreshIconStyle} source={REFRESH_ICON_BLUE} />
            </TouchableOpacity>
          </View>
        </View>
      </View>
      <View style={styles.detailsSection}>
        <WhiteCard styles={whiteCardStyles}>
          <View style={[styles.scheduleContainer]}>
            <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween, AtomicCss.marginBottom15]}>
              <View style={[AtomicCss.flexRow]}>
                {!isEmpty(boardingPointName) && (
                  <Text
                    style={[
                      AtomicCss.blackText,
                      AtomicCss.boldFont,
                      AtomicCss.marginRight5,
                      AtomicCss.font16,
                    ]}
                  >
                    {boardingPointName}
                  </Text>
                )}
                {!isEmpty(sourcePlateformNo) && (
                  <Text style={[styles.platformWrap]}>{sourcePlateformNo}</Text>
                )}
              </View>
            </View>
            <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween]}>
              <TrainTimingInfoCard
                heading={scheduleCardHeading}
                time={scheduledTime}
                timeStatus={''}
                delay={false}
              />
              <TrainTimingInfoCard
                heading={actualCardHeading}
                time={actualTime}
                timeStatus={timeStatus}
                delay={trainDelayed}
              />
            </View>
          </View>
        </WhiteCard>
        <View style={[AtomicCss.marginTop10]}>
          <WhiteCard styles={whiteCardStyles}>
            <View
              style={[
                styles.scheduleContainer,
                AtomicCss.flexRow,
                AtomicCss.spaceBetween,
                AtomicCss.alignCenter,
              ]}
            >
              <LiveTrakingInfoCard response={response} />
            </View>
          </WhiteCard>
        </View>
      </View>
    </View>
  );
};

const mapStateToProps = (state) => {
  const {
    latestTrainData
  } = state.trainReducer;
  return {
    latestTrainData
  };
}

const mapDispatchToProps = (dispatch) => ({
  trainDelayDispatch: (latestTrainData: LatestTrainData) =>
    dispatch(updateTrainDelayInfo(latestTrainData)),
});


export default connect(mapStateToProps, mapDispatchToProps)(WhiteCardWrapper(TrainScheduleCard));
