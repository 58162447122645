import { Image, StyleSheet, Text, View } from 'react-native';
import React from 'react';
// @ts-ignore
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { colors, fonts } from 'packages/legacy-commons/Styles/globalStyles';

interface Props {
  data: {
    cab_title: string;
    cab_image: string;
    utilities: Array<string>;
    cab_sub_title: string;
  } | null;
}

const CabCard = ({ data }: Props) => {
  if (!data) {
    return null;
  }
  const { cab_title, cab_image, utilities = [], cab_sub_title } = data;
  const cabSubTitle = cab_sub_title?.replace(/\b\w/g, c => c.toUpperCase());
  return (
    // @ts-ignore
    <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
      <View style={styles.carImageWrapper}>
        <Image source={{ uri: cab_image }} style={styles.cabImages} />
      </View>
      <View style={AtomicCss.flex1}>
        <Text
          style={styles.cabSubTititle}>
          {cabSubTitle}
        </Text>
        <Text
          style={[
            AtomicCss.font16,
            AtomicCss.blackFont,
            AtomicCss.blackText,
            AtomicCss.marginBottom3,
          ]}
        >
          {cab_title}
        </Text>
        <Text style={[AtomicCss.font12, AtomicCss.regularFont, AtomicCss.blackText,{fontWeight:'400'}]}>
          {utilities.join('  |  ')}
        </Text>
      </View>
    </View>
  );
};

export default CabCard;

const styles = StyleSheet.create({
  carImageWrapper: {
    backgroundColor: '#F4F5F6',
    marginRight: 12,
    borderRadius:16,
  },
  cabImages: {
    width: 80,
    height: 60,
    borderRadius:16,
  },
  cabSubTititle:{
    fontFamily:fonts.regular,
    fontSize:12,
    fontWeight:'400',
    color:colors.greyText1
  }
});
