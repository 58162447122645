import getPlatformElevation from '@mmt/legacy-commons/Common/Components/Card/getPlatformElevation';
const styles = {

    bottomOverlayWrapper:{
        height:"auto",
        padding: 30,
        backgroundColor : "#fff",
        width:"100%",
        ...getPlatformElevation(2),
        borderTopLeftRadius : 8,
        borderTopRightRadius : 8
    },
    overlay:{
        height:"100%",
        width:"100%",
        position:"absolute",
        top:0,
        backgroundColor:"#000",
        opacity:0.6,
        ...getPlatformElevation(2),
    },
    crossIcon:{
        width:23,
        height:23,
        color:"#000"
    },
    mapIcon:{width:24,height:24,marginRight:8},
    locationImg:{
        width:280,
        height:180,
        marginRight:10,
        borderRadius:6,

    },
    locationImgOuter:{marginRight : -30},
    underline:{backgroundColor:"#008cff", width:30,height:4,marginBottom:0 }

};

export default styles;

