import React from 'react';
import { View } from 'react-native';

import {
  locationMarkerGray,
  locationMarkerRed,
  phoneIcon2,
  userIcon,
  commentsIcon,
} from '../../../HolidayBookingConstants';

import Checkbox from '../../../../Common/Checkbox';
import { useTheme } from '../../../../theme/theme.context';
import {
  BottomOverlayTypes,
  carPickupDetailsText,
  othersTravelMode,
  transferMode,
} from '../../constants';
import InputFieldRow from '../InputFieldRow';
import TopPickUpCard from '../TopPickUpCard';
import createStyle from './pickupDetailsCss';
import { dateTimeFormat, getFormattedDateFromMillis } from '@mmt/legacy-commons/Helpers/dateHelpers';

const PickupDetailsForm = ({
  formData,
  handleBottomOverlay,
  rightAction,
  isDropOff = false,
  toCity,
  handleOnChange,
  index,
  onUpdateForAllCases,
  currentUpdateIndex,
  disableForm,
  dirtyFields,
  onFieldTouch
}) => {
  const updateAllChecked = currentUpdateIndex === `${index}${isDropOff}`;
  const { psTheme: theme } = useTheme();

  if (!formData) return null;

  const _handleChange = (key, value) => {
    handleOnChange(key, value, isDropOff);
  };

  const _handleBottomOverlay = (bottomOverlay) => {
    handleBottomOverlay(bottomOverlay, isDropOff);
  };

  const _onFieldTouch = (fieldName) => {
    onFieldTouch(isDropOff, fieldName);
  };

  const styles = createStyle(theme);
  const isTravelModeOthers = formData.travelMode != othersTravelMode;
  const pickUpCardSubtitle = isDropOff
    ? `${carPickupDetailsText.fromHotelIn} ${toCity}`
    : `${carPickupDetailsText.toHotelIn} ${toCity}`;

  return (
    <View>
      <TopPickUpCard
        title={isDropOff ? carPickupDetailsText.dropOffDetails : carPickupDetailsText.pickupDetails}
        subTitle={pickUpCardSubtitle}
        rightAction={rightAction}
      />
      <View style={styles.searchInptWrapper}>
        <InputFieldRow
          icon={userIcon}
          title={carPickupDetailsText.travellerName}
          placeHolder={carPickupDetailsText.enterTravellerName}
          value={formData.travellerName}
          isMandatory={true}
          handleOnChange={(text) => _handleChange('travellerName', text)}
          disabled={disableForm}
          isDirtyField={dirtyFields['travellerName']}
          onFieldTouch={_onFieldTouch}
          name="travellerName"
        />
        {!disableForm && (
          <Checkbox
            checked={updateAllChecked}
            onChange={() => onUpdateForAllCases(isDropOff)}
            label={carPickupDetailsText.updateAllCheckbox}
            containerStyle={styles.checkboxContainer}
          />
        )}
        <InputFieldRow
          icon={phoneIcon2}
          title={carPickupDetailsText.contactNumber}
          placeHolder={carPickupDetailsText.contactNo}
          value={formData.contactNumber}
          isMandatory={true}
          handleOnChange={(text) => _handleChange('contactNumber', text)}
          disabled={disableForm}
          isDirtyField={dirtyFields['contactNumber']}
          onFieldTouch={_onFieldTouch}
          name="contactNumber"
        />
        <InputFieldRow
          title={carPickupDetailsText.travelMode}
          placeHolder={carPickupDetailsText.selectTransportMode}
          value={transferMode[`${formData.travelMode}`].label}
          isMandatory={true}
          handleBottomOverlay={_handleBottomOverlay}
          bottomSheetType={BottomOverlayTypes.SELECT_TRANSFER_MODE}
          disabled={disableForm}
          isDirtyField={dirtyFields['travelMode']}
          onFieldTouch={_onFieldTouch}
          name="travelMode"
        />
        <View>
          <View style={styles.locationTrackBorder} />
          <InputFieldRow
            icon={locationMarkerGray}
            title={carPickupDetailsText.pickupPoint}
            placeHolder={carPickupDetailsText.pickupPoint}
            value={formData.pickupPoint}
            isMandatory={true}
            handleOnChange={(text) => _handleChange('pickupPoint', text)}
            disabled={disableForm}
            isDirtyField={dirtyFields['pickupPoint']}
            onFieldTouch={_onFieldTouch}
            name="pickupPoint"
          />
          <InputFieldRow
            title={carPickupDetailsText.departureDate}
            placeHolder={carPickupDetailsText.departureDateAndTime}
            value={
              formData.departureDate &&
              getFormattedDateFromMillis(formData.departureDate, dateTimeFormat)
            }
            isMandatory={true}
            handleBottomOverlay={_handleBottomOverlay}
            bottomSheetType={BottomOverlayTypes.SELECT_DEPARTURE_TIME}
            disabled={disableForm}
            isDirtyField={dirtyFields['departureDate']}
            onFieldTouch={_onFieldTouch}
            name="departureDate"
          />
          <InputFieldRow
            title={carPickupDetailsText.arrivalDate}
            placeHolder={carPickupDetailsText.arrivalDateAndTime}
            value={
              formData.arrivalDate &&
              getFormattedDateFromMillis(formData.arrivalDate, dateTimeFormat)
            }
            isMandatory={true}
            handleBottomOverlay={_handleBottomOverlay}
            bottomSheetType={BottomOverlayTypes.SELECT_ARRIVAL_TIME}
            disabled={disableForm}
            isDirtyField={dirtyFields['arrivalDate']}
            onFieldTouch={_onFieldTouch}
            name="arrivalDate"
          />
          {isTravelModeOthers && (
            <InputFieldRow
              title={`${transferMode[formData.travelMode].label} ${carPickupDetailsText.number}`}
              placeHolder={`${carPickupDetailsText.enter} ${
                transferMode[formData.travelMode].label
              } ${carPickupDetailsText.number}`}
              value={formData.number}
              isMandatory={true}
              handleOnChange={(text) => _handleChange('number', text)}
              disabled={disableForm}
              isDirtyField={dirtyFields['number']}
              onFieldTouch={_onFieldTouch}
              name="number"
            />
          )}
        </View>
        <InputFieldRow
          icon={locationMarkerRed}
          title={carPickupDetailsText.dropOffPoint}
          placeHolder={carPickupDetailsText.dropOffPoint}
          value={formData.dropOffPoint}
          isMandatory={true}
          handleBottomOverlay={_handleBottomOverlay}
          handleOnChange={(text) => _handleChange('dropOffPoint', text)}
          disabled={disableForm}
          isDirtyField={dirtyFields['dropOffPoint']}
          onFieldTouch={_onFieldTouch}
          name="dropOffPoint"
        />
        <InputFieldRow
          icon={commentsIcon}
          title={carPickupDetailsText.additionalInfo}
          placeHolder={carPickupDetailsText.commentHere}
          value={formData.additionalInfo}
          handleBottomOverlay={_handleBottomOverlay}
          handleOnChange={(text) => _handleChange('additionalInfo', text)}
          disabled={disableForm}
          isDirtyField={dirtyFields['additionalInfo']}
          onFieldTouch={_onFieldTouch}
          name="additionalInfo"
        />
      </View>
    </View>
  );
};

export default PickupDetailsForm;
