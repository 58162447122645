import { StyleSheet } from "react-native";
import { DEFAULT_THEME } from "apps/post-sales/src/theme/default.theme";

export default <T extends typeof DEFAULT_THEME>(theme: T, fonts: any) => StyleSheet.create({
    backArrowWrapper: {
      paddingRight: 5,
    },
    backArrow: {
      width: 16,
      height: 16,
      marginRight: 5,
      tintColor: theme.color.greyText1,
    },
  
    backArrowIos: {
      width: 8,
      height: 12,
      marginRight: 5,
      tintColor:  theme.color.greyText1,
    },
    clearAllText: {
      color: theme.color.lightGrey16,
      fontSize: fonts.fontSize.font14,
      ...fonts.boldFontFamily
    },
    clearAlltextBlue: {
      color: theme.color.azure
    }
  });