import LinearGradient from 'react-native-linear-gradient';
import PropTypes from 'prop-types';
import { Image, Platform, StyleSheet, Text, TouchableOpacity, View, I18nManager } from 'react-native';
import React, {Component} from 'react';
import {colors, fonts} from '../../../Styles/globalStyles';
import AtomicCss from "../../../Styles/AtomicCss";

const imageTransformY = {transform: [{scaleX: I18nManager.isRTL ? -1 : 1}]};

class CommonHeader extends Component {
  render() {
    const {headerText, imgSrc, backPressHandler, whiteHeader} = this.props;
    return (
      <LinearGradient
        start={{
          x: 1.0,
          y: 0.0
        }}
        end={{
          x: 0.0,
          y: 1.0
        }}
        colors={whiteHeader ? ['#fff', '#fff'] : ['#5f88ff', '#a768ff']}
        style={[styles.topHeader, whiteHeader ? [{borderColor: '#e8e8e8', elevation: 1}] : {}]}
      >
        <View style={styles.stickyBarWrapper}>
          <TouchableOpacity style={{padding: 14}} onPress={backPressHandler}>
            <Image style={[styles.backarrow, imageTransformY]} source={imgSrc} />
          </TouchableOpacity>
          <View>
            <Text
              numberOfLines={1}
              ellipsizeMode="tail"
              style={[styles.title, whiteHeader ? { color: colors.black } : {}, AtomicCss.alignLeft]}
            >{headerText}
            </Text>
          </View>
        </View>
      </LinearGradient>
    );
  }
}

const styles = StyleSheet.create({
  title: {
    color: colors.white,
    marginBottom: 3,
    paddingLeft: 14,
    ...Platform.select({
      ios: {
        backgroundColor: 'transparent'
      }
    })
  },
  backarrow: {
    width: 16,
    height: 16
  },
  topHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 56
  },
  stickyBarWrapper: {
    flexDirection: 'row',
    width: '75%',
    alignItems: 'center'
  }
});

CommonHeader.propTypes = {
  headerText: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  backPressHandler: PropTypes.func.isRequired,
  whiteHeader: PropTypes.bool
};
CommonHeader.defaultProps = {
  whiteHeader: false
};
export default CommonHeader;
