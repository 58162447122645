import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import SelectModeList from './SelectModeList';

const SelectMode = ({ data, setRefundModeList, bookingId, pageName = '' }) => {
  return (
    <View style={styles.cardWrapper}>
      <View style={styles.headingText}>
        <Text
          style={[
            AtomicCss.font20,
            AtomicCss.blackFont,
            AtomicCss.blackText,
            AtomicCss.marginBottom16,
            AtomicCss.lineHeight20,
            AtomicCss.flex1,
          ]}
        >
          {data.heading}
        </Text>
        <Text
          style={[
            AtomicCss.font16,
            AtomicCss.regularFont,
            AtomicCss.defaultText,
            styles.lineHeight22,
            AtomicCss.flex1,
          ]}
        >
          {data.subHeading}
        </Text>
      </View>
      <SelectModeList
        refundModeDataList={data.refundModeDataList}
        setRefundModeList={setRefundModeList}
        pageName={pageName}
        bookingId={bookingId}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  cardWrapper: {
    paddingHorizontal: 20,
  },
  headingText: {
    marginBottom: 24,
    flex: 1,
  },
  paddingAll20: {
    padding: 20,
  },
  lineHeight22: {
    lineHeight: 22,
  },
});
export default SelectMode;
