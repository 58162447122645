import React from 'react';
import {Image, Text, View} from 'react-native';
import styles from './InclusionExclusionCss';
import PropTypes from 'prop-types';
import AtomicCss from '../AtomicCss';
import {getActionButton, handleClickEvent} from '../../utils/AcmeBookingDetailUtil';
import {isNotNullAndEmpty} from '../../../hotel/details/utils/HotelBookingDetailUtil';
import { mainCardIcon, inclusionIcon, exclusionIcon} from '../../utils/AcmeImageConstant';


class InclusionExclusionCard extends React.Component {
  static navigationOptions = {
    header: null
  };

  render() {
    this.getActionListToDisplay();
    return (
      <View style={[AtomicCss.whiteBg, AtomicCss.marginBottom10]}>
        <View style={[styles.CardContentFull, AtomicCss.paddingBottom15]}>
          <View style={[styles.CardContent]}>
            <Image style={styles.CardIcon} source={mainCardIcon} />
            <View style={AtomicCss.flex1}>
              <Text
                style={[AtomicCss.blackFont, AtomicCss.blackText, AtomicCss.font14, AtomicCss.marginBottom24]}
              >
                WHAT IS INCLUDED
              </Text>
              {this.getInclusions()}
              {this.getExclusions()}
            </View>
          </View>
        </View>
        <View
          style={isNotNullAndEmpty(this.props.card.actionList) ? styles.whiteBtnHalfOuter : styles.hideView}
        >
          {getActionButton(this.props.card, this.props.response, this.handleClick)}
        </View>
      </View>
    );
  }

  getActionListToDisplay() {
    const actionListToShow = [];
    this.props.card.actionList.forEach((action) => {
      if ((action.actionFamily === 'TermsAndConditions' && isNotNullAndEmpty(this.props.response.acmeBookingInfo.acmeBookingDetails[0].acmeAdditionalInfo.tnc))
        || (action.actionFamily === 'ImportantThingsToCarry' && isNotNullAndEmpty(this.props.response.acmeBookingInfo.acmeBookingDetails[0].acmeAdditionalInfo.whatToCarry))
        || (action.actionFamily === 'FAQ' && isNotNullAndEmpty(this.props.response.acmeBookingInfo.acmeBookingDetails[0].acmeAdditionalInfo.faq))
        || (action.actionFamily === 'Schedule' && isNotNullAndEmpty(this.props.response.acmeBookingInfo.acmeBookingDetails[0].acmeAdditionalInfo.schedule))
        || (action.actionFamily === 'HowToRedeem' && isNotNullAndEmpty(this.props.response.acmeBookingInfo.acmeBookingDetails[0].acmeAdditionalInfo.howToRedeem))) {
        actionListToShow.push(action);
      }
    });
    this.props.card.actionList = actionListToShow;
  }

  getInclusions() {
    const views = [];
    const inclusionList =
      this.props.response.acmeBookingInfo.acmeBookingDetails[0].acmeAdditionalInfo.inclusion;
    inclusionList.forEach((inclusion) => {
      views.push(<View
        key={inclusion}
        style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom18]}
      >
        <Image style={styles.activityTick} source={inclusionIcon} />
        <Text style={styles.tickText}>{inclusion}</Text>
      </View>);
    });
    return views;
  }

  getExclusions() {
    const views = [];
    const exclusionList =
      this.props.response.acmeBookingInfo.acmeBookingDetails[0].acmeAdditionalInfo.exclusion;
    exclusionList.forEach((exclusion) => {
      views.push(<View
        key={exclusion}
        style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom18]}
      >
        <Image
          style={styles.activityCross}
          source={exclusionIcon}
        />
        <Text style={styles.CrossText}>{exclusion}</Text>
      </View>);
    });
    return views;
  }

  handleClick = (action) => {
    handleClickEvent(this.props.card, action, this.props.response, this.props.pageName);
  };
}

InclusionExclusionCard.propTypes = {
  response: PropTypes.object.isRequired,
  card: PropTypes.object.isRequired,
  pageName: PropTypes.string.isRequired
};

export default InclusionExclusionCard;
