import React from 'react';
import {View, Text, Image} from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import PropTypes from 'prop-types';
import styles from './CabMamiCss';
import {getTenDigitMobileNumber} from '../utils/CabBookingDetailUtil';
import CardWrapper from '../modules/bookingDetails/components/CardWrapper';
import {mailIcon, callIcon} from '../CabImageConstants';
import { isEmpty } from 'lodash';
import { getPaxName } from '../utils/CabBookingDetailUtil';
import HeadingComponent from '../modules/bookingDetails/components/HeadingComponent';

class TravellerCard extends React.Component {
  render() {
    const { firstName, lastName, card, mobileNumber, emailId } =
      this.props || {};
    const { heading } = card || {};
    const paxName = getPaxName(firstName, lastName);
    return (
      <React.Fragment>
        <HeadingComponent heading={heading} showIcon={true} containerStyle={styles.card}/>
        <View style={[AtomicCss.marginBottom16, AtomicCss.paddingHz15]}>
          {!isEmpty(paxName) && (
            <Text
              style={[
                AtomicCss.boldFont,
                AtomicCss.blackText,
                AtomicCss.font14,
                AtomicCss.marginBottom12,
              ]}
            >
              {paxName}
            </Text>
          )}

          {!isEmpty(mobileNumber) && (
            <View style={[AtomicCss.flexRow, AtomicCss.marginRight15, AtomicCss.marginBottom2]}>
              <Image style={styles.callIcon} source={callIcon} />
              <Text
                style={[
                  AtomicCss.midGreyText,
                  AtomicCss.marginTop2,
                  AtomicCss.regularFont,
                  AtomicCss.font13,
                ]}
              >
                {getTenDigitMobileNumber(mobileNumber)}
              </Text>
            </View>
          )}
          {!isEmpty(emailId) && (
            <View style={[AtomicCss.flexRow]}>
              <Image style={styles.mailIcon} source={mailIcon} />
              <Text
                style={[
                  AtomicCss.marginTop2,
                  AtomicCss.midGreyText,
                  AtomicCss.regularFont,
                  AtomicCss.font13,
                ]}
              >
                {emailId}
              </Text>
            </View>
          )}
        </View>
      </React.Fragment>
    );
  }
}
TravellerCard.propTypes = {
  lastName: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  emailId: PropTypes.string.isRequired,
  mobileNumber: PropTypes.string.isRequired,
};
const WrappedTravellerCard = CardWrapper(TravellerCard);
export default WrappedTravellerCard;
