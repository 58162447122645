import React from 'react';
import { View, Image, Text, TouchableOpacity } from 'react-native';
import { openDialer } from '../../Common/commonUtil';
import createStyles from './talkToUsCardCss';
import { getFont } from '../../PostSalesStyles';
import { useTheme } from '../../theme/theme.context';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { RIGHT_ARROW, TALK_TO_US_ICON } from '../../flights/FlightImageConstants';
import { imageTransformY } from '../../utils/PostSaleUtil';
import { CORPORATE_HELPLINE_NUMBER } from '../../PostSalesConstant';
import { callSupportAPI } from '../../summary/data/api';

interface ContactUsProps {
  heading: string;
  description: string;
  bookingId: string;
  productCode: string;
  isInternational: boolean;
  uuid: string;
  pageName: string;
}

const ContactUs: React.FC<ContactUsProps> = ({ heading, description, bookingId, productCode, isInternational, uuid, pageName }) => {
  const { fsStyle, ...fonts } = getFont();
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme);

  return (
    <>
      <TouchableOpacity
        style={styles.contactUsCard}
        onPress={() => {
          openDialer(CORPORATE_HELPLINE_NUMBER);
          callSupportAPI(bookingId, productCode, isInternational, uuid, pageName);
        }}
      >
        <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.flex1]}>
          <Image style={styles.contactUs} source={TALK_TO_US_ICON} />
          <View style={[AtomicCss.flex1, AtomicCss.marginRight30]}>
            <Text
              style={[
                fonts.blackFontFamily,
                fonts.font14,
                AtomicCss.blackText,
                AtomicCss.marginBottom3,
              ]}
            >
              {heading}
            </Text>
            <Text style={[fonts.regularFont, fonts.font14, AtomicCss.defaultText]}>
              {description}
            </Text>
          </View>
        </View>
        <View>
          <Image style={[styles.rightBlueArrow, imageTransformY]} source={RIGHT_ARROW} />
        </View>
      </TouchableOpacity>
    </>
  );
};

export default ContactUs;
