import React from 'react';
import {Image, Text, View} from 'react-native';
import PropTypes from 'prop-types';
import styles from '../../styles/AcmeMamiCss';
import {isNotNullAndEmpty} from '../../../hotel/details/utils/HotelBookingDetailUtil';
import {refundIcon} from '../../utils/AcmeImageConstant';


class RefundBreakUpCard extends React.Component {
  static navigationOptions = {
    header: null
  };

  render() {
    const refundCommDetails = this.props.response.refundCommDetailsList;
    const {customerCurrency} = this.props.response;
    return (
      <View style={[styles.MamiCard, styles.mar10Bt]}>
        <View style={styles.CardContent}>
          <Image style={styles.CardIcon} source={refundIcon} />
          <View style={styles.ContentPortion}>
            <Text style={[styles.BlackText, styles.blackFont, styles.font14, styles.mar25Bt, styles.PolicyHeadingText]}>
              REFUND PROCESSED TO
            </Text>
            {this.addRefundInfo(refundCommDetails, customerCurrency)}

          </View>
        </View>
      </View>
    );
  }
  addRefundInfo(refundCommDetails, customerCurrency) {
    const views = [];
    if (isNotNullAndEmpty(refundCommDetails)) {
      refundCommDetails.forEach((refundCommDetail) => {
        refundCommDetail.refundSegmentList.forEach((refundSegment, index) => {
          if (refundSegment.bankName === 'MyWallet') {
            this.addWalletCard(views, refundSegment, index, customerCurrency);
          } else {
            this.addBankCard(views, refundSegment, index, customerCurrency);
          }
        });
      });
    }
    return views;
  }
  addBankCard(views, refundSegment, index, customerCurrency) {
    views.push(<View key={index} style={[styles.Paymentrow, styles.mar15Bt]}>
      <View style={styles.width250}>
        <Text style={[styles.defaultGrey, styles.boldFont, styles.font14, styles.mar5Bt]}>{refundSegment.bankName}</Text>
        <Text style={[styles.font12, styles.regularFont, styles.lightGrey, styles.mar5Bt, isNotNullAndEmpty(refundSegment.rrn) ? styles.showView : styles.hideView]}>RRN No. {refundSegment.rrn}</Text>
        <Text style={[styles.font12, styles.regularFont, styles.lightGrey, styles.mar5Bt, isNotNullAndEmpty(refundSegment.cardNumber) ? styles.showView : styles.hideView]}>Card No. {refundSegment.cardNumber}</Text>
        <Text style={[styles.font12, styles.regularFont, styles.lightYellow, styles.mar5Bt, styles.lineHeight18]}>
          The refund amount will reflect in your account as per following timelines.(Private bank: 3-7 days, Government bank: 7-14 days, UPI:0-8 hours)MyWallet:Instantly , Gift Card:0-24 hours)
        </Text>
      </View>
      <Text style={[styles.flexStart, styles.font14, styles.defaultGrey, styles.boldFont]}>{customerCurrency} {refundSegment.refundAmount}{' '}</Text>
    </View>);
  }
  addWalletCard(views, refundSegment, index, customerCurrency) {
    views.push(<View key={index} style={[styles.Paymentrow, styles.mar15Bt]}>
      <View style={styles.PaymentLeft}>
        <Text style={[styles.defaultGrey, styles.font14, styles.boldFont, styles.mar5Bt]}>
          MakeMyTrip Wallet Bonus
        </Text>
        <Text style={[styles.font12, styles.darkGrey, styles.regularFont]}>
          The refund amount will reflect in your MyWallet instantly. Any bonus cash is subjected to expiry conditions.
        </Text>
      </View>
      <Text style={[styles.flexStart, styles.font14, styles.defaultGrey, styles.boldFont]}>{customerCurrency} {refundSegment.refundAmount}{' '}</Text>
    </View>);
  }
}

RefundBreakUpCard.propTypes = {
  response: PropTypes.object.isRequired
};

export default RefundBreakUpCard;
