import { NativeModules, Platform } from 'react-native';
import { getRootTag } from '../AppState/RootTagHolder';

if (Platform.OS === 'ios') {
  const popVC = NativeModules.ViewControllerModule.popViewController;
  NativeModules.ViewControllerModule.popViewController = () => {
    const reactTag = getRootTag();
    popVC(reactTag);
  };

  const thankYouDismiss = NativeModules.ViewControllerModule.thankyouDismiss;
  NativeModules.ViewControllerModule.thankyouDismiss = () => {
    const reactTag = getRootTag();
    thankYouDismiss(reactTag);
  };

  const dismissVC = NativeModules.ViewControllerModule.dismissPresentedViewController;
  NativeModules.ViewControllerModule.dismissPresentedViewController = () => {
    const reactTag = getRootTag();
    dismissVC(reactTag);
  };
}


export default NativeModules.ViewControllerModule;
