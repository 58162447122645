import React from 'react';
import { View, Text } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import isEmpty from 'lodash/isEmpty';
import { getThrottleParams, handleClickEvent } from '../../../../utils/CabBookingDetailUtil';
import { PayDriverRatingCardProps } from '../../../../types';
import CardWrapper from '../CardWrapper';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import createStyles from './styles';
import BaseButton from 'apps/post-sales/src/Common/BaseButton';
import CabBookingDetailsConstant from 'apps/post-sales/src/cab/CabBookingDetailsConstant';

const PayDriverRatingCard: React.FC<PayDriverRatingCardProps> = ({
  card,
  response,
  cardData,
  onDownloadInvoiceClick
}) => {
  const { paymentInfo } = cardData || {};
  const { actionList } = card || {};
  const downloadInvoiceAction =
    !isEmpty(actionList) &&
    actionList.find(
      (item) =>
        item.actionFamily === CabBookingDetailsConstant.ACTION_DOWNLOAD_INVOICE,
    );
  const {
    paymentToDriverText,
    isAllSettled,
    extraKmsChargeInfo,
    extrakms,
    amountDue,
    amountPaid,
    paymentBreakups,
    currency,
    allSettledText,
    totalPaid,
  } = paymentInfo || {};

  const { fsStyle, ...fonts } = getFont();
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);
  const handleButtonClick = (item) => {
    handleClickEvent(card, item, response, 'pay');
  };

  return (
    <View style={styles.PayDriverRatingCard}>
      {isAllSettled ? (
        <View style={styles.payToDriverTopSection}>
          <Text
            style={[
              AtomicCss.blackText,
              AtomicCss.textCenter,
              AtomicCss.font26,
              AtomicCss.marginBottom3,
              AtomicCss.blackFont,
            ]}
          >
            {allSettledText}
          </Text>
        </View>
      ) : (
        <View style={styles.payToDriverTopSection}>
          {!!paymentToDriverText && (
            <Text
              style={[
                AtomicCss.defaultText,
                AtomicCss.textCenter,
                AtomicCss.font12,
                AtomicCss.marginBottom3,
                AtomicCss.regularFont,
              ]}
            >
              {paymentToDriverText}
            </Text>
          )}
          {!!amountDue && (
            <Text
              style={[
                AtomicCss.blackText,
                AtomicCss.textCenter,
                AtomicCss.font26,
                AtomicCss.marginBottom3,
                AtomicCss.blackFont,
              ]}
            >
              {currency} {amountDue}
            </Text>
          )}
          {!!extrakms && (
            <Text
              style={[
                AtomicCss.defaultText,
                AtomicCss.textCenter,
                AtomicCss.font12,
                AtomicCss.marginBottom3,
                AtomicCss.regularFont,
              ]}
            >
              {extrakms}
            </Text>
          )}
        </View>
      )}
      <View style={styles.paymentInfoWrapper}>
        {!isEmpty(totalPaid) && !isEmpty(totalPaid.amountText) && (
          <View style={[styles.rowSection, AtomicCss.marginBottom5]}>
            <Text
              style={[
                AtomicCss.regularFont,
                AtomicCss.midGreyText,
                AtomicCss.font14,
              ]}
            >
              <Text style={[AtomicCss.blackFont, AtomicCss.blackText]}>
                {CabBookingDetailsConstant.amountPaidText}
              </Text>
            </Text>
            <Text
              style={[
                AtomicCss.blackFont,
                AtomicCss.midGreyText,
                AtomicCss.font14,
                styles.textRight,
              ]}
            >
              {totalPaid.amountText}
            </Text>
          </View>
        )}
        {!isEmpty(paymentBreakups) &&
          paymentBreakups.map((item) => {
            return (
              <View style={[styles.rowSection, AtomicCss.marginBottom5]}>
                <Text style={styles.breakupLabel}>{item.modeLabel}</Text>
                <Text style={[styles.breakupLabel]}>
                  {item.amountText}
                </Text>
              </View>
            );
          })}
        {!!amountDue && amountDue > 0 && (
          <View style={[styles.amountPendingSection, AtomicCss.marginBottom24]}>
            <View
              style={[
                AtomicCss.flexRow,
                AtomicCss.spaceBetween,
                AtomicCss.marginBottom3,
              ]}
            >
              <Text
                style={[
                  AtomicCss.blackFont,
                  AtomicCss.font12,
                  styles.brownText,
                ]}
              >
                {CabBookingDetailsConstant.amountDueText}
              </Text>
              <Text
                style={[
                  AtomicCss.blackFont,
                  AtomicCss.font12,
                  styles.brownText,
                ]}
              >
                {amountDue}
              </Text>
            </View>
            {!!extrakms && (
              <Text
                style={[
                  AtomicCss.regularFont,
                  AtomicCss.font12,
                  styles.brownText,
                ]}
              >
                {extrakms}
              </Text>
            )}
          </View>
        )}
        {!isEmpty(actionList) && (
          <View style={styles.btnWrapper}>
            {actionList.map((item) => {
              if (
                item.actionFamily ===
                CabBookingDetailsConstant.ACTION_DOWNLOAD_INVOICE
              ) {
                return null;
              }
              return (
                <View key={item.actionId} style={styles.buttonView}>
                  <BaseButton
                    variant={'CAPSULE'}
                    buttonStyle={styles.viewBreakupButtonStyle}
                    textStyle={styles.viewBreakupTextStyle}
                    text={item.actionLabeltext}
                    clickHandler={() => handleButtonClick(item)}
                  />
                </View>
              );
            })}
          </View>
        )}
        {!isEmpty(downloadInvoiceAction) && (
          <View
            key={downloadInvoiceAction.actionId}
            style={[AtomicCss.marginBottom8, styles.buttonView]}
          >
            <BaseButton
              variant={'CAPSULE'}
              buttonStyle={styles.viewBreakupButtonStyle}
              textStyle={styles.viewBreakupTextStyle}
              text={downloadInvoiceAction.actionLabeltext}
              clickHandler={onDownloadInvoiceClick}
              isThrottleRequired={getThrottleParams(downloadInvoiceAction).isThrottleRequired}
              throttleInterval={getThrottleParams(downloadInvoiceAction).throttleInterval}
            />
          </View>
        )}
      </View>
    </View>
  );
};

export default CardWrapper(PayDriverRatingCard);
