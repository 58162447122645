import React, { useEffect, useState } from 'react';
import { BackHandler, View } from 'react-native';
import { BUTTON_VARIANT } from '../../Common/BaseButton';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import { getFont } from '../../PostSalesStyles';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import SimpleHeader from '@mmt/legacy-commons/Common/Components/Header/SimpleHeader.android';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import NoInternetView from '@mmt/legacy-commons/Common/Components/Error/NoInternetView';
import { getStaticData } from '../../staticData/staticData';
import ProgressView from '../../Common/ProgressView/ProgressView';
import { createStyles } from './previewTGChargesCss';
import { goToWallet, popToRailBookingDetails, resetToRailBookingDetails } from '../details/RailBookingDetailsUtil';
import { SUCCESS_GREEN_TICK } from '../Utils/RailImageConstant';
import GenericModule from 'packages/core/native/GenericModule';
import AtomicCss from '../commonStyles/AtomicCss';
import { CancellationReason, TGCancelResponse, Train } from '../types';
import { ThankYouButtonAction, UseApiResponse } from '../../types';
import { checkViewState, getCommitRequest } from './adapter/data';
import SuccessPage from '../../Common/thankyou';
import { RefundToWallet } from '../../PostSalesConstant';
import { RAIL_TG_PREMIUM_RETURN_URL } from '../../utils/NetworkUtils';
import useApi from '../../Common/useApi';
import { RAIL_TG_CANCEL_API, RAIL_TG_CANCEL_SUCCESS_ACTIONS } from '../details/constants';


interface Props {
  isFullCancellation: boolean;
  bookingDetails: {
    bookingID: string;
    tgsCancellationReasonList: CancellationReason[];
    train: Train[];
    railRacTgInfo: any;
  }; 
  refundOption: string;
  actionKey: string;
  reason: string
}

const CommitTGCharges: React.FC<Props> = ({ isFullCancellation, bookingDetails, refundOption, actionKey, reason }) => {
  const [viewState, setViewState] = useState<string>(ViewState.LOADING);
  const [commitResponseInProgress, commitResponse, commitResponseAPI] : UseApiResponse<TGCancelResponse> = useApi(RAIL_TG_PREMIUM_RETURN_URL) as UseApiResponse<TGCancelResponse>;
  const [errorText, setErrorText] = useState<string>("");


  const backHandler = () => {
    resetToBookingDetails();
    return true;
  };

useEffect(() => {
      BackHandler.addEventListener('hardwareBackPress', backHandler);
      return () => {
          BackHandler.removeEventListener('hardwareBackPress', backHandler);
      };
    
}, [backHandler]);

useEffect( ()=>{
    commitResponseAPI.httpPost({
          body: getCommitRequest(bookingDetails, actionKey, isFullCancellation, refundOption, reason)
    })
    
},[])

useEffect( () => {
  if(commitResponse){
      checkViewState(commitResponse, RAIL_TG_CANCEL_API.COMMIT, setViewState, setErrorText);
  }
},[commitResponse])

  const fonts = getFont();
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);

  const renderProgressView = () => {
    const staticData = getStaticData();
    const { loadingText } = staticData;
    return (
      <ProgressView
        message={loadingText ? loadingText : ''}
      />
    );
  };

  async function retry() {
    setViewState(ViewState.LOADING);
    if(commitResponseAPI){
        return commitResponseAPI.httpPost({body : getCommitRequest(bookingDetails, actionKey, isFullCancellation, refundOption, reason) });
    }
}    

  const renderErrorView = () => {
    const staticData = getStaticData();
    const { goBackToBookingText, backText } = staticData;
    return (
      <View style={AtomicCss.flex1}>
        <SimpleHeader title={backText} iconPress={popToBookingDetail} />
        <ErrorView
          showRetry
          buttonLabel={goBackToBookingText}
          message={ errorText }
          onRetry={popToBookingDetail}
        />
      </View>
    );
  };

  const resetToBookingDetails = () => {
    resetToRailBookingDetails(bookingDetails.bookingID);
  };

  const popToBookingDetail = () => {
    popToRailBookingDetails();
  };

  const goToWalletClickHandler = () => {
    const { goToWalletDeeplink = null} = commitResponse ? commitResponse : {};
    if(goToWalletDeeplink){
      goToWallet(goToWalletDeeplink);
    }
  } 

  const goToSuccessPage = () => {
    const isRefundOptionWallet = refundOption === RefundToWallet;
    const staticData = getStaticData();
    const { tgPremiumInitiated, apologiesForPartialSeat, goBackToTicket, goToMyWallet, refundInitiated} = staticData ;
    const {refundSuccessActions = []} = commitResponse ? commitResponse : {};
    const myActions : ThankYouButtonAction[] = [];
    refundSuccessActions.forEach( action => {
      switch(action.actionId){
        case RAIL_TG_CANCEL_SUCCESS_ACTIONS.BACK_TO_TICKET:{
          myActions.push({
            id: action.actionId,
            text: action.actionLabeltext,
            clickHandler: resetToBookingDetails,
            variant: BUTTON_VARIANT.PRIMARY,
            shadow: true,
            buttonStyle: {}
          })
          break;
        }
        case RAIL_TG_CANCEL_SUCCESS_ACTIONS.GO_TO_WALLET : {
          myActions.push({
            id: action.actionId,
            text: action.actionLabeltext,
            clickHandler: goToWalletClickHandler,
            variant: BUTTON_VARIANT.OUTLINED,
            shadow: true,
            buttonStyle: {
              backgroundColor: theme.color.white,
            }
          })
          break;
        }
        default:
          break;
      }
    })
    return (
      <SuccessPage
          icon= {SUCCESS_GREEN_TICK}
          message= {isRefundOptionWallet ? tgPremiumInitiated: refundInitiated}
          description={apologiesForPartialSeat}
          actions= { myActions }
          
      />
    )
  }

  const renderNoNetworkView = () => {
    const staticData = getStaticData();
    const { backText, tryAgainText} = staticData ;
     return <View style={ AtomicCss.flex1}>
      <SimpleHeader title={backText} iconPress={popToBookingDetail} />
      <NoInternetView
        buttonText={tryAgainText}
        onRetry={() => {
          setViewState( ViewState.LOADING );
          retry();
        }}
      />
    </View>
  };

  return (
    <View style={{ flex: 1 }}>
      {viewState === ViewState.LOADING && renderProgressView()}
      {viewState === ViewState.NO_INTERNET && renderNoNetworkView()}
      {viewState === ViewState.SHOW_DETAIL && goToSuccessPage()}
      {viewState === ViewState.ERROR && renderErrorView()}
    </View>
  );
};

export default CommitTGCharges;
