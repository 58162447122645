import React, {useEffect, useState} from 'react';
import {View, ScrollView, Platform, Text, TouchableOpacity} from 'react-native';
import PropTypes from 'prop-types';
import styles from './refundBreakupCss';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import ProgressView from '../ProgressView/ProgressView';
import {getCommonHeaders, REFUND_CALCULATION_URL} from '../../utils/NetworkUtils';
import AmountBreakup from './AmountBreakup';
import SimpleHeader from '@mmt/legacy-commons/Common/Components/Header/SimpleHeader';
import ViewControllerModule from '@mmt/legacy-commons/Native/ViewControllerModule';
import {isGccFlightBooking, isKsaFlightBooking} from '../../flights/FlightBookingUtil';
import FlightBookingDetailsConstant, {HELPLINE_NUMBER} from '../../flights/modules/details/FlightBookingDetailsConstant';
import {CORPORATE_HELPLINE_NUMBER, ENG_CONSTANT, HELPLINE_NO, LOBNAMES} from '../../PostSalesConstant';
import {checkMyPartner, isCorporateBooking, openCustomerFormInWebView} from '../../utils/PostSaleUtil';
import ErrorPage from '../../flights/modules/details/components/Error/ErrorPage';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getStaticData } from '../../staticData/staticData';
import OverlayMessage from '../InstantRefund/Overlays/OverlayMessage';
import TCSBottomSheet from '../TCSBottomSheet';
import Actions from '../../navigation/postSalesNavigation';
import PostSalesVernac from '../../PostSalesVernac';
import { getRootTag } from '@mmt/legacy-commons/AppState/RootTagHolder';
import { getFont } from '../../PostSalesStyles';


const ViewState = {
  LOADING: 'loading',
  LOADED: 'loaded',
  ERROR: 'error'
};
const crossIcon = require('@mmt/legacy-assets/src/ic_cross__gray.webp');
const RefundBreakup = ({bookingId, requestNo, lobCode,trackRefundEvent, navigation, apiPSLob}) => {
  const {
    flightErrorText:{
      repeatedIssueError,
      repeatedIssueGccFlightBookingError,
      repeatedIssueKSAFlightBookingError,
      repeatedIssueCorporateError,
      repeatedMyPartnerIssueError,
      repeatedMyPartnerGccIssueError,
    },
    errorText:{
      somethingWentWrongError,
      errorText
    },
    loadingText,
    refundCalculationText,
    tryAgainText,
  } = getStaticData(true);
  const [viewState, setViewState] = useState(ViewState.LOADING);
  const [response, setResponse] = useState(undefined);
  const [isOverlay, setIsOverlay]= useState('');

  useEffect(() => {
    refundBreakupAPI();
  }, [bookingId, requestNo]);

  const handleBottomOverlay = (showOverly) => {
    setIsOverlay(showOverly);
  }

  const refundBreakupAPI = async () => {
    try {
      setViewState(ViewState.LOADING);
      const userInfo = await HotelBookingModule.getRequestHeader(bookingId, 'REFUND_BREAKUP_DETAILS');
      const headers = await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo, { psLob: apiPSLob });
      const apiResponse = await fetch(
        `${REFUND_CALCULATION_URL + bookingId}/${requestNo}`,
        { headers }
      );
      if (apiResponse && apiResponse.ok) {
        const body = await apiResponse.json();
        if (body.success) {
          setViewState(ViewState.LOADED);
          setResponse(body);
        } else {
          setViewState(ViewState.ERROR);
        }
      } else {
        setViewState(ViewState.ERROR);
      }
    } catch (e) {
      setViewState(ViewState.ERROR);
    }
  };

  const onBackIconPress = () => {
    // simulate hardware back press
    Actions.pop();
  };
  const getErrorBtnObj = () => {
    const btnObj = {};
    btnObj.text = tryAgainText;
    btnObj.action = FlightBookingDetailsConstant.ACTION_RELOAD;
    return btnObj;
  };

  const getErrorPageSubText = () => {
    if (isGccFlightBooking(bookingId)) {
      return repeatedIssueGccFlightBookingError
    } else if (isKsaFlightBooking(bookingId)) {
      return repeatedIssueKSAFlightBookingError
    } else if (isCorporateBooking(lobCode)) {
      return repeatedIssueCorporateError
    } else if (checkMyPartner()) {
        // Check for MyPartner GCC
        if(!isNullOrEmpty(bookingId) && bookingId.startsWith("MU")) {
          return repeatedMyPartnerGccIssueError
        }
        return repeatedMyPartnerIssueError
    } else {
      return repeatedIssueError
    }
  };

  const renderProgressView = () => (<ProgressView message={loadingText ? loadingText : ''} />);

  const renderErrorView = () => (
    <View style={{flex: 1}}>
      <SimpleHeader
        title={errorText}
        iconPress={onBackIconPress}
      />
      <ErrorPage
        title={somethingWentWrongError}
        btnAction={getErrorBtnObj()}
        onRefresh={() => {
              refundBreakupAPI();
            }}
        subText={getErrorPageSubText()}
      />
    </View>
  );

  const onClickConnectUs = (action) => {
    trackRefundEvent && trackRefundEvent('CONNECT_HELPDESK_REFUND_BREAKUP');
    if(action && action.url) {
      openCustomerFormInWebView(action, bookingId, 'CONNECT_HELPDESK');
    }
  }

  if (viewState === ViewState.LOADING) {
    return renderProgressView();
  }

  if (viewState === ViewState.ERROR) {
    return renderErrorView();
  }

  if (viewState === ViewState.LOADED && response) {
    const { needHelp } = response;
    const SimpleHeaderContent = SimpleHeader;
    const fonts = getFont(true);
    return (
      <View style={styles.pageWrapper}>
        <SimpleHeaderContent
          icon={crossIcon}
          title={refundCalculationText}
          textFont={fonts.mediumFontFamily.fontFamily}
          iconPress={onBackIconPress}
        />
        <ScrollView showsVerticalScrollIndicator={false}>
          <View style={[styles.whiteCard]}>
            <View style={styles.autoRefundBreakupMain}>
              {response.totalAmtPaidGrp &&
              <AmountBreakup {...response.totalAmtPaidGrp} showGrouping={false} />
              }
              {response.totalDeductionsGrp &&
              <AmountBreakup {...response.totalDeductionsGrp} handleBottomOverlay={handleBottomOverlay} showGrouping />
              }
              <View style={[styles.fareBreakupDtlsWrapper, {paddingHorizontal: 16}]}>
                <View style={[styles.fareBrkupRow, styles.totalRefund]}>
                  <Text style={[styles.totalRefundTxt, {color: response.refundAmount.color}]}>
                    {response.refundAmount.text}
                  </Text>
                  <Text style={[styles.totalRefundTxt, {color: response.refundAmount.color}]}>
                    {`${response.refundAmount.value} `}
                  </Text>
                </View>
              </View>
              {response.refundModeBreakupList && response.refundModeBreakupList.length > 0 && response.refundModeBreakupList.map(item =>
                (<View style={[AtomicCss.marginBottom10, AtomicCss.flexRow, AtomicCss.paddingRight16, AtomicCss.paddingLeft16]}>
                  <View style={[AtomicCss.flex1]}>
                    <Text
                      style={[AtomicCss.defaultText, AtomicCss.font14, AtomicCss.lineHeight18, AtomicCss.marginBottom3]}
                    >
                      {item.key}
                    </Text>
                  </View>
                  <Text style={[AtomicCss.defaultText]}>{`${item.value} `}</Text>
                </View>))
              }
            </View>
          </View>
          {needHelp && <View style={[AtomicCss.alignCenter, AtomicCss.marginTop15]}>
            <Text style={[AtomicCss.font16, AtomicCss.boldFont]}>{needHelp.title}</Text>
            {needHelp.action && <TouchableOpacity onPress={() => onClickConnectUs(needHelp.action)}>
              <Text style={[AtomicCss.font14, AtomicCss.boldFont, AtomicCss.blueText, AtomicCss.marginTop8]}>{needHelp.action.text}</Text>
            </TouchableOpacity>}
          </View>}
        </ScrollView>
        {isOverlay === 'TCSInfo' &&
                <OverlayMessage
                    content={
                        <TCSBottomSheet
                            isOverlayVisble={isOverlay}
                            handleBottomOverlay={handleBottomOverlay}
                            overlayContent={response.overlayContent}
                        />
                    }
                    handleBottomOverlay={handleBottomOverlay}
                />
        }
      </View>);
  }
  return null;
};

export default RefundBreakup;

RefundBreakup.propTypes = {
  bookingId: PropTypes.string.isRequired,
  requestNo: PropTypes.string.isRequired,
  lobCode: PropTypes.string.isRequired
};
