import React from "react";
import styles from './busCrossSellCss';
import { Text, View, Image, TouchableOpacity } from "react-native";
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss'
import rightArrowIcon from '@mmt/legacy-assets/src/ic_arrow.webp';
import { imageTransformY } from '../../../utils/PostSaleUtil';
import upcomingBookingIcon from '@mmt/legacy-assets/src/postSales/summary/upcomingBusIcon.webp';

import * as RailBookingTrackingHelper from '@mmt/legacy-commons/Common/utils/OmnitureTrackerUtils';

const BusCrossSell = ({ cardData, summaryCard, page }) => {
  const handleDeepLink = () => {
    RailBookingTrackingHelper.trackClickEvent(page, 'busCrossSell');
    GenericModule.openDeepLink(cardData.busLobDeepLink)
  }
  const rightArrow = () => {
    return (
      <Image style={[styles.rightArrow, imageTransformY]} source={rightArrowIcon} />
    )
  }
  const getBusIcon = () => {
    return (<Image style={styles.busIcon} source={upcomingBookingIcon} />);
  }
  const crossSellData = () => {
    return (
      <>
        {getBusIcon()}
        <View style={[AtomicCss.flexColumn, AtomicCss.marginTop15, AtomicCss.marginBottom15, AtomicCss.marginLeft10, AtomicCss.marginRight15]}>
          {!!cardData.heading && <Text style={[AtomicCss.font17, AtomicCss.blackText, AtomicCss.blackFont, AtomicCss.marginBottom10]}>{cardData.heading}</Text>}
          {!!cardData.subHeading && <Text style={AtomicCss.regularFont}>{cardData.subHeading}</Text>}
        </View>
        {rightArrow()}
      </>
    )
  }
  return (
    <TouchableOpacity onPress={handleDeepLink} style={[AtomicCss.flexRow, AtomicCss.whiteBg, AtomicCss.alignCenter, !summaryCard ? AtomicCss.marginTop10 : AtomicCss.marginTop2, !summaryCard ? AtomicCss.marginBottom18 : AtomicCss.marginBottom2]}>
      {crossSellData()}
    </TouchableOpacity>)
}

export default BusCrossSell;
