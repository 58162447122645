import React from 'react';
import PropTypes from 'prop-types';
import {View, Text, Image, ScrollView} from 'react-native';
import isEmpty from 'lodash/isEmpty';
import Button from './Button';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {screenWidth} from '../HolidayBookingConstants';
import Header from './Header';
import {callToModifyIcon} from '../HolidayBookingConstants';

const cancellationHeading = 'Call MakeMyTrip to Modify Holiday Package';

class ModifyModal extends React.Component {
  static navigationOptions = {
    header: null
  };

  render() {
    const {modificationPage} = this.props;
    return (
      <View style={styles.container}>
        <Header
          title="Modify Holiday Package"
          headerShadow
          pageName={this.props.pageName}
          goBack={this.props.onBackPopUp}/>
        <ScrollView>
          <React.Fragment>
            <View style={AtomicCss.whiteBg}>
              <View
                style={[styles.cardWrapper, AtomicCss.marginBottom10]}
              >
                <View style={[AtomicCss.marginBottom24, AtomicCss.alignCenter]}>
                  <Image
                    style={styles.imageIcon}
                    source={callToModifyIcon}/>
                </View>
                <View style={[AtomicCss.marginBottom16, AtomicCss.alignCenter]}>
                  <Text
                    style={[AtomicCss.boldFont, AtomicCss.blackText, AtomicCss.font20,
                      AtomicCss.textCenter, {lineHeight: 26},
                      {
                        marginTop: 20,
                        marginHorizontal: 10
                      }
                    ]}>
                    {modificationPage.header}
                  </Text>
                </View>
                <View>
                  <View style={[styles.cancellationInfoListItem]}>
                    <Text
                      style={[AtomicCss.flex1, AtomicCss.regularFont, AtomicCss.font14,
                        AtomicCss.defaultText, AtomicCss.lineHeight20,
                        AtomicCss.textCenter, {
                          marginTop: 20
                        }]}
                    >
                      {modificationPage.content}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </React.Fragment>
        </ScrollView>
        {!isEmpty(modificationPage.buttons) &&
        <View style={styles.buttonWrapper}>
          <Button
            pageName={this.props.pageName}
            button={modificationPage.buttons[0]}
            handleClick={this.props.handleButtonClick}
            btnTxt={modificationPage.buttons[0].text}
          />
        </View>
        }
      </View>
    );
  }
}

const styles = {
  container: {
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    position: 'absolute',
    elevation: 3,
    zIndex: 3,
    width: '100%',
    height: '100%'
  },
  cardWrapper: {
    width: screenWidth,
    backgroundColor: '#ffffff',
    paddingHorizontal: 16,
    paddingTop: 16
  },
  buttonWrapper: {
    paddingHorizontal: 10,
    paddingBottom: 20,
    backgroundColor: '#ffffff'
  },
  imageIcon: {
    width: 115,
    height: 97
  },
  bulletText: {
    fontSize: 6,
    paddingHorizontal: 5,
    paddingTop: 3
  },
  cancellationInfoListItem: {
    paddingVertical: 8,
    marginTop: 20,
    lineHeight: 20
  }
};

ModifyModal.propTypes = {
  modificationPage: PropTypes.object.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
  onBackPopUp: PropTypes.func.isRequired,
  pageName: PropTypes.string.isRequired
};

export default ModifyModal;
