import { StyleSheet } from 'react-native';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
const createStyles = (fonts, theme) =>
  StyleSheet.create({
    countryVisa: {
      margin: 10,
      backgroundColor: theme.color.white,
    },
    headingWrapper: {
      padding: 16,
      ...getPlatformElevation(2),
      marginBottom: 2,
      backgroundColor: theme.color.grey22,
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      borderRadius: 4,
    },
    visaSection: {
      borderBottomWidth: 1,
      borderBottomColor: theme.color.lightGrey,
      marginBottom: 4,
      paddingBottom: 4,
    },
    innerCardDetails: {
      paddingVertical: 12,
      paddingHorizontal: 5,
    },
    labelText: {
      ...fonts.blackFontFamily,
      color: theme.color.defaultTextColor,
      fontSize: fonts.fontSize.font16,
    },
  });

export default createStyles;
