import React from 'react';
import LinearGradient from 'react-native-linear-gradient';
import {BackHandler, Image, Platform, StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import PropTypes from 'prop-types';
import Actions from '../../../navigation/postSalesNavigation';
import {isEmpty} from 'lodash';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import ProgressView from '@mmt/legacy-commons/Common/Components/Progress/ProgressView';
import SimpleHeader from '@mmt/legacy-commons/Common/Components/Header/SimpleHeader';
import NoInternetView from '@mmt/legacy-commons/Common/Components/Error/NoInternetView';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import {getCommonHeaders, SELF_DRIVE_CANCELLATION_URL} from '../../../utils/NetworkUtils';
import {isNetworkAvailable} from '@mmt/legacy-commons/Common/utils/AppUtils';
import {colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import SelfDriveBookingTrackingHelper from "../../SelfDriveBookingTrackingHelper";
export const SELF_DRIVE_CANCEL_BOOKING = 'Cancellation: Cancel booking';
import {CANCEL_ICON} from '../../SelfDriveImageConstant';
import * as NpsModule from '@mmt/legacy-commons/Native/NpsModule';
import {
  getRatingData, isNotNullAndEmpty
} from '../../../Common/commonUtil';

export default class SelfDriveCancelRequest extends React.Component {

  constructor(props) {
    super(props);
    this.trackingPageName = SELF_DRIVE_CANCEL_BOOKING;
    this.bookingId = this.props.bookingId;
    this.mmtAuth = null;
    this.loggingTrackingInfo = null;
    this.penaltyResponse = null;
    this.state = {
      viewState: ViewState.LOADING,
      responseJson: null,
      errorText: "Something went wrong, Please try again!"
    };
    this.showRatingPrompt = true;
  }

  render() {
    return (
      <View style={{flex: 1}}>
        {this.state.viewState === ViewState.LOADING && this.renderProgressView()}
        {this.state.viewState === ViewState.NO_INTERNET && this.renderNoNetworkView()}
        {this.state.viewState === ViewState.ERROR && this.renderErrorView()}
        {this.state.viewState === ViewState.SHOW_DETAIL && this.renderPage()}
      </View>
    );
  }

    renderProgressView = () => (<ProgressView message="Loading Details..." />);


    renderNoNetworkView = () => (
      <View style={{flex: 1}}>
        <SimpleHeader
          title="Back"
          iconPress={this.goBack}
        />
        <NoInternetView
          onRetry={() => {
                    this.setState({viewState: ViewState.LOADING});
                    this.fetchJSONAsync().then(r => "");
                }}
        />
      </View>
    );

    renderErrorView = () => (
      <View style={{flex: 1}}>
        <SimpleHeader
          title="Back"
          iconPress={this.goBack}
        />
        <ErrorView
          showRetry
          onRetry={() => {
                    this.setState({viewState: ViewState.LOADING});
                    this.fetchJSONAsync().then(r => "cancel commit");
                }}
          message={this.state.errorText}
        />
      </View>
    );

  renderPage = () => {
    const { ratingData, npsData } = getRatingData(this.trackingPageName, this.state.responseJson);
    if (ratingData && this.showRatingPrompt) {
      NpsModule.showRatingPrompt(ratingData, npsData);
      this.showRatingPrompt = false;
    }

    return (
      <View style={styles.thankYouWrapper}>

        <Image style={styles.successIcon} source={CANCEL_ICON} />
        <Text
          style={styles.messageTitle}
        >Booking Successfully cancelled
        </Text>
        <TouchableOpacity
          style={[styles.CommonFillBtn, styles.transparantBgrnd]}
          onPress={() =>
            this.goToBookingDetails()}
        >
          <LinearGradient
            start={{
              x: 1.0,
              y: 0.0
            }}
            end={{
              x: 0.0,
              y: 1.0
            }}
            colors={[colors.darkBlue, colors.lightBlue]}
            style={styles.CommonFillBtnGradient}
          >
            <Text style={[styles.CommonFillBtnText, styles.transparantBgrnd]}>See Updated Booking</Text>
          </LinearGradient>
        </TouchableOpacity>
      </View>

    );
  }

    goToBookingDetails = () => {
      if (this.bookingId) {
        const params = {};
        params.BOOKING_ID = this.bookingId;
        params.MMT_AUTH = this.mmtAuth;
        params.refresh = true;
        params.LOGGING_TRACKINFO = this.loggingTrackingInfo;
        Actions.selfDriveBookingDetail({data: params});
      } else {
        Actions.popTo('selfDriveBookingDetail');
      }
      return true
    }

    componentDidMount() {
      this.fetchJSONAsync().then(r => "");
      BackHandler.addEventListener('hardwareBackPress', this.goToBookingDetails);
      SelfDriveBookingTrackingHelper.trackClickEvent('SelfDrive Cancel Commit', 'MMT_Commit')
    }

    componentWillUnmount() {
      BackHandler.removeEventListener('hardwareBackPress', this.goToBookingDetails);
    }

    async fetchJSONAsync() {
      try {
        const hasNetwork = await isNetworkAvailable();
        if (!hasNetwork) {
          this.setState({viewState: ViewState.NO_INTERNET});
          return;
        }
        const request = {
          bookingId: this.bookingId,
          action: 'Commit',
          fullCancellation: {
            refundAdjustment: this.props.selectedRefundMode
          }
        };
        const {mmtAuth, loggingTrackingInfo} = await HotelBookingModule.getRequestHeader(this.props.bookingId, 'SelfDrive_Cancellation_Details');
        this.mmtAuth = mmtAuth;
        this.loggingTrackingInfo = loggingTrackingInfo;
        const response = await
          fetch(SELF_DRIVE_CANCELLATION_URL, {
            method: 'POST',
            body: JSON.stringify(request),
            headers: await getCommonHeaders(mmtAuth, loggingTrackingInfo)
          });

       this.penaltyResponse = await response.json();
        if (this.penaltyResponse.status === true) {
          this.setState({
            viewState: ViewState.SHOW_DETAIL,
            responseJson: this.penaltyResponse,
          });
        } else {
          this.setState({
            viewState: ViewState.ERROR,
            errorText: !isEmpty(this.penaltyResponse.errorText) ? this.penaltyResponse.errorText : this.state.errorText
          });
        }
      } catch (error) {
        this.setState({viewState: ViewState.ERROR});
      }
    }

    goBack = () => {
      Actions.pop();
    };
}

const styles = StyleSheet.create({
  parentContainer: {
    flex: 1,
    backgroundColor: '#ffffff'
  },
  thankYouWrapper: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    paddingHorizontal: '10%'
  },
  transparantBgrnd: {
    ...Platform.select({
      ios: {
        backgroundColor: 'transparent'
      }
    })
  },
  thankYouHeader: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 47
  },
  headerIcon: {
    width: 24,
    height: 32,
    marginRight: 7
  },
  headerTitle: {
    fontSize: 14,
    color: '#053cb2',
    fontFamily: fonts.black
  },
  headerDesc: {
    fontSize: 12,
    color: '#053cb2'
  },
  successIcon: {
    width: 40,
    height: 40,
    marginRight: 15,
    marginBottom: 10,
    alignSelf: 'center'
  },
  messageTitle: {
    fontSize: 20,
    textAlign: 'center',
    lineHeight: 25,
    color: '#000000',
    fontFamily: fonts.bold,
    marginBottom: 27
  },
  messageText: {
    textAlign: 'center',
    fontSize: 12,
    color: '#000000'
  },
  CommonFillBtn: {
    width: '100%'
  },
  CommonFillBtnGradient: {
    borderRadius: 25,
    paddingVertical: 11,
    paddingHorizontal: 25,
    marginTop: 24
  },
  CommonFillBtnText: {
    color: colors.white,
    alignSelf: 'center',
    fontFamily: fonts.black
  },
  hideView: {
    display: 'none'
  }
});

SelfDriveCancelRequest.defaultProps = {
  instantData: null
}

SelfDriveCancelRequest.propTypes = {
  bookingId: PropTypes.string.isRequired,
  selectedRefundMode: PropTypes.string.isRequired,
};
