import {ACTION_BUS_LIVE_TRACKING, ACTION_BUS_LIVE_TRACKING_ERROR, ACTION_BUS_LIVE_TRACKING_LOADING} from './busBookingDetailAction';
import ViewState from '../flights-funnel/Listing/utils/ViewState';


const initialStates = {
  busLiveJson: null
};

export default (state = initialStates, action) => {
  switch (action.type) {
    case ACTION_BUS_LIVE_TRACKING: {
      const {
        busLiveData, busDetailResponse, mmtAuth, loggingTrackInfo, viewState
      } = action.data;
      return {
        ...state,
        busLiveData,
        busDetailResponse,
        mmtAuth,
        loggingTrackInfo,
        viewState
      };
    }
    case ACTION_BUS_LIVE_TRACKING_ERROR: {
      return {
        ...state,
        busLiveJson: null,
        viewState: ViewState.ERROR
      };
    }

    case ACTION_BUS_LIVE_TRACKING_LOADING: {
      const {viewState} = action.data;
      return {
        ...state,
        viewState
      };
    }
    default:
      return state;
  }
};

