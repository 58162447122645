import { colors } from '@mmt/legacy-commons/Styles/globalStyles';

const styles = {
  pageWrapper: {
    flex: 1,
  },
  whiteBg: {
    backgroundColor: colors.white,
  },
  FullBookingTopInfo: {
    paddingVertical: 15,
    paddingHorizontal: 16,
  },
  TopGradientInfo: {
    paddingVertical: 7,
    paddingHorizontal: 8,
  },
  RedInfoText: {
    color: '#e53442',
    fontSize: 13,
  },
  pageScrollBtmSpace: {
    height: 70,
  },
  whiteCard: {
    backgroundColor: '#ffffff',
    elevation: 2,
    shadowColor: '#000000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 2,
    marginVertical: 6,
  },
  DarkFooter: {
    backgroundColor: '#282828',
    paddingVertical: 8,
    paddingHorizontal: 10,
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
  },
  marTop0: {
    marginTop: 0,
  },
  offerStrip: {
    marginHorizontal: 5,
    backgroundColor: '#c8f8f6',
    paddingVertical: 10,
    paddingLeft: 35,
    paddingRight: 15,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    position: 'relative',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  offerStripIcon: {
    width: 20,
    height: 20,
    position: 'absolute',
    left: 10,
    top: 10,
  },
  offerStripText: {
    fontSize: 14,
    color: '#00a19c',
    lineHeight: 18,
  },
  offerStripTextBold: {
    fontSize: 14,
    color: '#00a19c',
    lineHeight: 18,
  },
  offerStripFree: {
    backgroundColor: '#c8f8f6',
  },
  offerStripTextFree: {
    color: '#00a19c',
  },
  offerStripTextBoldFree: {
    color: '#00a19c',
  },
  offerStripZero: {
    backgroundColor: '#d6f6e9',
  },
  offerStripTextZero: {
    color: '#2bb87d',
  },
  offerStripTextBoldZero: {
    color: '#2bb87d',
  },
  offerStripDoubleBlack: {
    backgroundColor: '#cfd8dc',
  },
  offerStripTextDoubleBlack: {
    color: '#282828',
  },
  offerStripTextBoldDoubleBlack: {
    color: '#282828',
  },
  serviceFeeTxt: {
    fontSize: 12,
    color: '#4a4a4a',
    marginTop: 10,
  },
  priceIncreseInfoTxt: {
    fontSize: 12,
    color: '#e58f13',
    marginTop: 7,
  },
  headerTitle: {
    color: colors.black,
    textAlign: 'left',
  },
  buttonStyle: {
    paddingHorizontal: 10,
    paddingVertical: 20,
  },
  actionStyle: {
    // fontFamily: fonts.bold,
  },
  freeCancellationText: {
    backgroundColor: colors.limeGreen,
    marginHorizontal: 10,
    marginVertical: 10,
  },
  titleStyle: {
    color: colors.darkGreen,
    marginBottom: 4,
    marginLeft: 8,
    marginTop: 4,
  },
  cfarHeadingText: {
    color: colors.green,
    marginTop: 4,
    marginLeft: 16,
    marginBottom: 4,
  },
  subTitleStyle: {
    color: colors.green,
    marginBottom: 4,
    marginLeft: 8,
  },
};

export default styles;
