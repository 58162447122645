import React from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import styles from '../indexCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getStaticData } from '../../../staticData/staticData';
import { REFUND_GREY } from '../../../flights/FlightImageConstants';

const ErrorCard = (props) => {
  const { errorText, uhoh, retryText } = getStaticData();
  const { couldntFetchRefundText, fetchRefundErrorDesc } = errorText || {};
  return (
    <View style={styles.body}>
      <View style={styles.refundCard}>
        <View style={styles.errorCard}>
          <Image source={REFUND_GREY} style={styles.errorIcon} />
          <Text
            style={[
              AtomicCss.font16,
              AtomicCss.blackFont,
              AtomicCss.defaultText,
              AtomicCss.marginBottom5,
              AtomicCss.textCenter,
            ]}
          >
            {uhoh}
          </Text>
          <Text
            style={[
              AtomicCss.font16,
              AtomicCss.blackFont,
              AtomicCss.defaultText,
              AtomicCss.marginBottom12,
              AtomicCss.textCenter,
            ]}
          >
            {couldntFetchRefundText}
          </Text>
          <Text
            style={[
              AtomicCss.font14,
              AtomicCss.regularFont,
              AtomicCss.defaultText,
              AtomicCss.marginBottom26,
              AtomicCss.textCenter,
            ]}
          >
            {fetchRefundErrorDesc}
          </Text>
          <TouchableOpacity onPress={props.fetchData}>
            <Text
              style={[
                AtomicCss.font16,
                AtomicCss.blackFont,
                AtomicCss.azure,
                AtomicCss.marginTop5,
                AtomicCss.textCenter,
              ]}
            >
              {retryText}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

export default ErrorCard;
