import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#e7e7e7',
  },
  detailsSection:{
    paddingVertical:12,
    paddingHorizontal:16,
  },
  scrollWrapper: {
    flexDirection: 'row',
    marginBottom: 20,
    paddingLeft: 20,
  },
  cardWrapper: {
    borderRadius: 4,
    width: 240,
    borderWidth: 1,
    borderColor: '#e7e7e7',
    padding: 16,
    backgroundColor: '#fff',
    marginRight: 20,
  },
  iconStyle: {
    width: 44,
    height: 44,
  },
  stsTextWrapper: {
    paddingVertical: 4,
    paddingHorizontal: 8,
    backgroundColor: '#f2f2f2',
    marginRight: -16,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
  },
  stsTextStyle: {
    color: '#1a7971',
  },
  marginBottom4: {
    marginBottom: 4,
  },
});

export default styles;
