import React from 'react';
import {View, Text, Image, TouchableOpacity, StyleSheet, I18nManager} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import PropTypes from 'prop-types';

import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {formatDate, openWebView, trackrRedirectToMsr} from './Utils';
import { themeColors } from '../../PostSalesStyles';
import { EscalateMessage, FeedbackCard } from './MSRComponents';

export default function SingleRequestTicketCard(props) {
  const {ticket, pageName, customStyle, showCardIcon} = props;
  if (!ticket) {
    return null;
  }
  if (!Object.keys(ticket).length) {
    return null;
  }

  let {ticketTagClr, actionBtnText} = ticket;
  if (!ticketTagClr) {
    ticketTagClr = 'grey';
  }
  if (!actionBtnText) {
    actionBtnText = 'View Details';
  }

  return (
    <LinearGradient
      start={{x: 1.0, y: 0.0}}
      end={{x: 0.0, y: 1.0}}
      colors={['#e7f0ff', '#ffffff']}
      style={[styles.msrEntryCardOuter, AtomicCss.marginBottom10, customStyle? customStyle:{}, I18nManager.isRTL ? AtomicCss.paddingRight16 : AtomicCss.paddingLeft16]}
    >
      <View style={AtomicCss.marginBottom20}>
        <Text style={[AtomicCss.defaultText, AtomicCss.blackFont, AtomicCss.font12]}>
          YOU CONTACTED US FOR
        </Text>
      </View>
      <View style={AtomicCss.flexRow}>
        {showCardIcon && 
          <Image
            style={[styles.msrEntryCardIcon, AtomicCss.marginRight10]}
            source={{uri: ticket.iconUrl}}
          />
        }
        <View style={[AtomicCss.flex1]}>
          <View style={styles.msrEntryCardTopSec}>
            <View style={AtomicCss.marginBottom5}>
              <Text style={[AtomicCss.blackText, AtomicCss.blackFont, AtomicCss.font16]}>
                {ticket.secondaryType}
              </Text>
            </View>
            <View style={AtomicCss.marginBottom8}>
              <Text style={[AtomicCss.defaultText, AtomicCss.font12]}>
                Created {formatDate(ticket.createdAt)}
              </Text>
            </View>
          </View>
          <View style={[AtomicCss.marginBottom12]}>
            <Text style={[AtomicCss.font11, AtomicCss.midGreyText, AtomicCss.lineHeight18]}>
              <Text
                style={[
                  ticketTagColourStatusTextColourMap[ticketTagClr],
                  AtomicCss.font12,
                  AtomicCss.boldFont
                ]}
              >
                {ticket.statusText}
              </Text>{' '}
              (Updated {formatDate(ticket.updatedAt)})
            </Text>
            {ticket.expectedClosureDate && (
              <Text
                style={[
                  AtomicCss.marginTop5,
                  AtomicCss.font12,
                  AtomicCss.midGreyText,
                  AtomicCss.lineHeight18
                ]}
              >
                Expected resolution by
                <Text style={[styles.expectedClosureDate, AtomicCss.font12, AtomicCss.boldFont]}>
                  {` `}
                  {formatDate(ticket.expectedClosureDate)}
                </Text>
              </Text>
            )}
          </View>
          <TouchableOpacity
            style={[styles.msrTrackDirection, AtomicCss.alignCenter, {marginBottom: 4}]}
            onPress={() => {
              trackrRedirectToMsr(ticket, 0, pageName);
              openWebView(ticket.msrUrl);
            }}
          >
            <Text
              style={[
                {color: themeColors.linkTextColor},
                AtomicCss.boldFont,
                AtomicCss.font12,
                I18nManager.isRTL ? AtomicCss.marginLeft5 : AtomicCss.marginRight5
              ]}
            >
              {actionBtnText}
            </Text>
            <Image
              style={styles.msrEntryCardArrow}
              source={require('@mmt/legacy-assets/src/blueRightArrow.webp')}
            />
          </TouchableOpacity>
          {ticket.escalationDetails && ticket.escalationDetails.showEscalate &&
            <EscalateMessage 
              escalationDetails={ticket.escalationDetails}
              pageName={pageName}
              ticket={ticket}
            />
          }
          {ticket.feedbackDetails && ticket.feedbackDetails.showFeedbackOption &&
            <FeedbackCard 
              feedbackDetails={ticket.feedbackDetails}
              pageName={pageName}
              ticket={ticket}
            />
          }
        </View>
      </View>
    </LinearGradient>
  );
}

SingleRequestTicketCard.defaultProps = {
  ticket: null
};

SingleRequestTicketCard.propTypes = {
  ticket: PropTypes.shape({
    iconUrl: PropTypes.string.isRequired,
    secondaryType: PropTypes.string.isRequired,
    createdAt: PropTypes.number.isRequired,
    updatedAt: PropTypes.number.isRequired,
    expectedClosureDate: PropTypes.number,
    assignee: PropTypes.string,
    msrUrl: PropTypes.string.isRequired,
    actionBtnText: PropTypes.string.isRequired
  })
};

const styles = StyleSheet.create({
  msrEntryCardOuter: {
    paddingVertical: 16,
    paddingLeft: 16
  },

  msrTrackDirection: {
    flexDirection: I18nManager.isRTL ? 'row-reverse' : 'row',
  },

  msrEntryCardTopSec: {
    borderBottomWidth: 1,
    borderBottomColor: '#e7e7e7',
    marginBottom: 8
  },
  msrEntryCardArrow: {
    width: 6,
    height: 10
  },
  msrEntryCardIcon: {
    width: 20,
    height: 20
  },
  greenTxt: {color: '#1a7971'},
  yellowText: {color: '#cc7f01'},
  expectedClosureDate: {
    color: '#000'
  }
});

const ticketTagColourStatusTextColourMap = {
  // ticketTagClr
  black: AtomicCss.blackText,
  yellow: styles.yellowText,
  red: AtomicCss.errorRedText,
  green: styles.greenTxt
};
