import {fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from '@mmt/legacy-commons/Common/Components/Card/getPlatformElevation';


const styles ={
  infoCardWrapper:{
    backgroundColor:'#fff',
    justifyContent:'space-between',
    flexDirection:'row',
    ...getPlatformElevation(2),
    marginBottom:1,
  },

  infoCardContent:{
      paddingLeft:8,
      paddingVertical:15,
      flex:1
  },

  infoCustom:{
    fontSize:14,
    color:'#4a4a4a',
    lineHeight:18,
    fontFamily:fonts.regular,
  },

  headingIconWrapper:{
    width:46,
    alignItems:'flex-start',
    top:14,
    left:16,
  },
    headingIconStyle:{
    width:21,
    height:20,
    },


  rightArrowIcon:{
    width:9,
    height:13,
    right:20,
  },

  linkBtnTxt:{
    fontSize:14,
    fontFamily:fonts.bold,
    color:"#008cff"
  },

  IconStyle:{
    width:32,
    height:32,
    marginRight:4,
  },
  bagIcon2Style:{
    width:16,
    height:15,
    marginRight:15,
    marginLeft:3,
  },
  dtlsRow:{
    borderBottomColor:'#e5e5e5',
    borderBottomWidth:1,
    paddingBottom:10,
    marginBottom:10,
  },
  progressText:{
    color:'#cf8100'
  },
  failedText:{
    color:'#eb2026'
  },
  callIconStyle:{
    width:32,
    height:32,
    marginRight:10,
  },



  summaryCardWrapper:{
    borderTopLeftRadius : 8,
    borderTopRightRadius : 8,
    backgroundColor:'#fff',
    // justifyContent:'space-between',
    // flexDirection:'row',
    ...getPlatformElevation(2),
    marginBottom:1,
    marginTop:10,
    paddingHorizontal : 15,
    paddingTop:10
  },
  cabIcon:{
    width:80,
    height:40,
    resizeMode: 'contain'
  },
  revIcon:{
    width:32,
    height:32
  },
  timeInfoIcon:{width:66,height:28},
  locationIcon:{width:24,height:24,marginRight:8},
  googlemap:{width:48,height:48, marginLeft:20},
  inclusions:{borderTopColor:"#e7e7e7",borderTopWidth:1, paddingTop:15,marginTop:15},
  summaryInfo:{...getPlatformElevation(2),backgroundColor:"#fff", marginTop:20, marginHorizontal:-16,paddingHorizontal:15, paddingVertical:20 },
  cabNumber:{
    backgroundColor:"#eaf5ff",
    borderRadius:4,
    paddingHorizontal:4,
    paddingVertical:4
  },

  cardBorder:{
    borderTopColor:'#e4e4e4',
    borderBottomColor:'#e4e4e4',
    borderTopWidth:1,
    borderBottomWidth:1,
  },
  pendingVerify:{
    backgroundColor:"#ffedd1",
    borderRadius:4,
    paddingHorizontal:12,
    paddingVertical:12
  },
  redirectionIcon:{
    width:12,
    height:12,
    marginLeft:8
  },
  buttonOuter:{
 justifyContent:"center",
 alignItems:"center"

  },
  calcelledIcon:{
    width:24,height:24, marginRight:10
  },


  tracker:{
    position:"absolute",
    width:1,
    height:"100%",
    backgroundColor:"#979797",
    left:11,
    top:24
  },
  trackerDot:{
    backgroundColor:"#2ac1b1",
    width:7,
    height:7,
    marginRight:17,
    marginLeft:8,
    borderRadius:30,
    marginTop:20
  },
  rightSection: {
    flex: 1,
  },

}

export default styles;
