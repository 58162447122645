import Actions from 'apps/post-sales/src/navigation/postSalesNavigation';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect } from 'react';
import { View, TouchableOpacity, Text, BackHandler } from 'react-native';
import LinearGradient from 'react-native-linear-gradient'
import { getFont } from '../../../../../PostSalesStyles';
import { useTheme } from '../../../../../theme/theme.context';
import createStyles from "./thankYouCss";

function ThankYouComponent({ response }) {
  const fonts = getFont();
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme,fonts)
  const {thankYouPageDetails, newBookingId} = response || {};
  const { thankyouText, thankyouSubtext, cta } = thankYouPageDetails || {};
  const [ctaData] = cta || [];
  const { title } = ctaData || {};
  const handleCtaUrl = () => {
      goToNewBooking();
  };

  const goToNewBooking = () => {
    if(!isEmpty(newBookingId)){
      Actions.busBookingDetail({ data: { BOOKING_ID: newBookingId, refresh: true }});
    }
    return true;
  };
  useEffect(()=>{
    BackHandler.addEventListener('hardwareBackPress', goToNewBooking);
    return ()=>{
      BackHandler.removeEventListener('hardwareBackPress', goToNewBooking);
    }
  },[])
  return (
    <View style={styles.thankYouWrapper}>
      {/* <Image style={styles.successIcon} source={cancelIcon} /> */}
      {!!thankyouText && <Text style={styles.messageTitle}>{thankyouText}</Text>}
      {!!thankyouSubtext && <Text style={styles.messageSubtitle}>{thankyouSubtext}</Text>}
      <TouchableOpacity
        style={[styles.CommonFillBtn, styles.transparantBgrnd]}
        onPress={handleCtaUrl}
      >
        <LinearGradient
          start={{
            x: 1.0,
            y: 0.0,
          }}
          end={{
            x: 0.0,
            y: 1.0,
          }}
          colors={[theme.color.darkBlue, theme.color.lightBlue]}
          style={styles.CommonFillBtnGradient}
        >
          {!!title && (
            <Text style={[styles.CommonFillBtnText, styles.transparantBgrnd]}>{title}</Text>
          )}
        </LinearGradient>
      </TouchableOpacity>
    </View>
  );
}

export default ThankYouComponent;
