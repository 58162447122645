/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import {isEmpty} from 'lodash';
import LinearGradient from 'react-native-linear-gradient';
import Actions from '../../../navigation/postSalesNavigation';
import {Image, ScrollView, Text, TouchableOpacity, View} from 'react-native';
import styles from '../../../cab/components/CabMamiCss';
import StickyHeader from '../../../hotel/details/components/StickyHeader';
import BasePage from '../../../Common/PostSalesBasePage';
import {appendRupeeSymbol} from '../../../hotel/details/utils/HotelBookingDetailUtil';
import * as PSC from '../../../PostSalesConstant';
import irstyles from '../../../Common/InstantRefund/Styles/RefundPaymentModeCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import PageLoader from '../../../Common/Cancellation/PageLoader';
import NoNetworkView from '../../../Common/Cancellation/NoNetworkView';
import PageLoadingError from '../../../Common/Cancellation/PageLoadingError';
import {isNetworkAvailable} from '@mmt/legacy-commons/Common/utils/AppUtils';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import {getCommonHeaders, SELF_DRIVE_CANCELLATION_URL} from '../../../utils/NetworkUtils';
import {isNonEmpty} from '@mmt/legacy-commons/Common/utils/StringUtils';
import SelfDriverRefundOverlay from "./SelfDriverRefundOverlay";
import {getSubTitle} from "../../utils/SelfDriveBookingDetailUtil";
import SelfDriveBookingTrackingHelper from "../../SelfDriveBookingTrackingHelper";
import {REFUND_ICON , HEADER_ICON } from '../../SelfDriveImageConstant';

export const pageName =
  'mob: cabs: booking cancellation_2 confirm cancellation page';
export const pageN = 'CancellationRefundMode';

class SelfDriveCancellationRefundMode extends BasePage {
  constructor(props) {
    super(props, pageN);
    this.state = {
      isOverlayVisible: false,
      bottomOverlay: PSC.EMPTY_STRING,
      radiobtn: 'RefundToCustomer',
      errorState: false,
      viewState: ViewState.SHOW_DETAIL,
      keyboard: false
    };
    this.radio = this.radio.bind();
    this.penalty = this.props.penaltyResponse;
    this.response = this.props.response;
    this.bookingId = this.props.response.bookingId;
  }

  static navigationOptions = {
    header: null
  };

  toggleOverlay = () => {
    this.setState({
      isOverlayVisible: !this.state.isOverlayVisible
    });
  }

  radio = (active) => {
    this.setState({
      radiobtn: active
    });
  };

  renderPage() {
    return (
      <View style={irstyles.pageWrapper}>
        <StickyHeader
          iconSrc={HEADER_ICON}
          title="Self Drive Cancellation"
          headerColor={['#ffffff', '#ffffff']}
          titleStyle={[styles.DefaultText, styles.blackFont]}
          subTitle={getSubTitle(this.response)}
          subTitleStyle={[
            styles.DefaultText,
            styles.regularFont,
            styles.font12
          ]}
          onBackPress={this.goBack}
        />
        <ScrollView>
          <View style={[styles.MamiCard, styles.mar10Bt]}>
            {/* Breadcrumb */}
            <View style={[styles.flexRow, styles.cancelBreadcrumb]}>
              <View style={styles.flexRow}>
                <Text style={[styles.mar5Rt, styles.darkGrey]}>1</Text>
                <Text style={styles.darkGrey}>View Refund Amount</Text>
              </View>
              <View style={styles.flexRow}>
                <Text style={[styles.mar5Rt, styles.activeBreadCrumb]}>2</Text>
                <Text style={styles.activeBreadCrumb}>
                  Confirm Cancellation
                </Text>
              </View>
            </View>

            <View style={[styles.padding16, styles.borderbtm]}>
              <Text style={styles.bookedCabType}>
                {this.props.response.tripInfo.pickup.city} - {this.props.response.tripInfo.drop.city}
              </Text>
            </View>

            <View>
              <View
                style={
                  !isEmpty(this.penalty) &&
                  this.penalty.refundAmount > 0
                    ? styles.showView
                    : styles.hideView
                }
              >
                <View
                  style={[
                    styles.padding16,
                    styles.borderbtmLight,
                    styles.flexRow
                  ]}
                >
                  <Image
                    source={REFUND_ICON}
                    style={[styles.refundIcon, styles.mar10Rt]}
                  />
                  <Text
                    style={[styles.blackFont, styles.BlackText, styles.font14]}
                  >
                    SELECT REFUND MODE
                  </Text>
                </View>
                {!isEmpty(this.penalty.cancellationRefundMode.message) &&
                <View style={styles.refundModeMessageCard}>
                  <Text style={[styles.BlackText]}>{this.penalty.cancellationRefundMode.message}
                  </Text>
                </View>}
                <View style={[styles.ActionBtn, styles.mar15Top]}>
                  {this.getRefundModeList(this.penalty.cancellationRefundMode.refundModeList)}
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
        <TouchableOpacity style={irstyles.footerBtnWrapper} onPress={() => this.handleConfirmClickBtn()}>
          <LinearGradient
            start={{x: 1.0, y: 0.0}}
            end={{x: 0.0, y: 1.0}}
            colors={['#065af3', '#53b2fe']}
            style={[styles.CapsuleBtnFill, styles.marginTop10]}
          >
            <Text
              style={[
                styles.WhiteText,
                styles.regularFont,
                styles.CenterText,
                styles.transperantBgrnd
              ]}
            >
              CONFIRM CANCELLATION
            </Text>
          </LinearGradient>
        </TouchableOpacity>
        {this.state.isOverlayVisible && (
          <SelfDriverRefundOverlay
            backPress={this.onOverlayBackPress}
            selectedRefundMode={this.state.radiobtn}
            bookingId={this.props.response.bookingId}
            response={this.props.response}
            parentBookingId={this.props.response.parentBookingId}
            pageName={pageName}
            email={this.props.response.paxInfo.emailId}
          />
        )}
      </View>
    );
  }

  handleBottomOverlay = (showOverlay) => {
    this.setState({bottomOverlay: showOverlay, OTPValue: PSC.EMPTY_STRING});
  }

  handleConfirmClickBtn = () => {
      this.toggleOverlay();
  }

  refreshCar = () => {
    this.setState({viewState: ViewState.LOADING});
  }

  retry = () => {
    this.updateAPIData().then(r => "refund mode selection page");
  }

  async updateAPIData() {
    try {
      const hasNetwork = await isNetworkAvailable();
      if (!hasNetwork) {
        this.setState({viewState: ViewState.NO_INTERNET});
        return;
      }
      const {mmtAuth, loggingTrackingInfo} = await HotelBookingModule.getRequestHeader(this.bookingId, PSC.SELF_DRIVE_CANCEL_DETAILS);
      const request = {
        bookingId: this.bookingId,
        action: 'Preview',
        fullCancellation: {
          refundAdjustment: 'RefundToCustomer'
        }
      };
      const response = await fetch(SELF_DRIVE_CANCELLATION_URL, {
        method: PSC.POST,
        body: JSON.stringify(request),
        headers: await getCommonHeaders(mmtAuth, loggingTrackingInfo)
      });

      if (response.ok) {
        this.penaltyResponse = await response.json();
        this.setState({viewState: ViewState.SHOW_DETAIL});
      } else {
        this.setState({viewState: ViewState.ERROR});
      }
    } catch (error) {
      this.setState({viewState: ViewState.ERROR});
    }
  }

  render() {
    return (
      <View style={AtomicCss.flex1}>
        {this.state.viewState === ViewState.LOADING && <PageLoader updateAPIData={this.retry} />}
        {this.state.viewState === ViewState.NO_INTERNET && <NoNetworkView retryFunction={this.refreshCar} />}
        {this.state.viewState === ViewState.ERROR && <PageLoadingError retryFunction={this.refreshCar} />}
        {this.state.viewState === ViewState.SHOW_DETAIL && this.renderPage()}
      </View>
    );
  }

  getRefundModeList(refundModeList) {
    const refundModes = [];

    Object.keys(refundModeList).forEach((mode) => {
      const modeName = (refundModeList[mode].refundModeReason === PSC.DEAFULTREFUNDMODE) ? 'RefundToCustomer' : 'RefundToWallet';
      refundModes.push(<TouchableOpacity
        style={[
            styles.showView
          ]}
        onPress={() => {
          this.radio(modeName);
        }}
      >
        <View style={styles.refundCardOuter}>
          <View style={[styles.refundCardWrap]}>
            <View style={styles.flexRow}>
              <View style={styles.MimaRadio}>
                { !isNonEmpty(refundModeList[mode].accountNo) && <View
                  style={[
                    this.state.radiobtn === modeName && styles.MimaRadioInside
                  ]}
                />}
              </View>
              <View style={styles.refundCardLeft}>
                <Text
                  style={[styles.BlackText, styles.font14, styles.mediumFont]}
                >
                  {refundModeList[mode].title}
                </Text>
                <Text
                  style={[styles.font12, styles.DefaultText, styles.mar3Bt]}
                >
                </Text>
                {
                    refundModeList[mode].subText.map((subText, index) => (<Text
                      style={[
                          styles.font12,
                          index % 2 === 1 ? styles.lightGrey : styles.BlackText,
                          styles.lineHeight18
                        ]}
                    >
                      {subText}
                    </Text>))
                  }
              </View>
            </View>
            <Text style={[styles.BlackText, styles.boldFont, styles.font14]}>
              {appendRupeeSymbol(Math.round(this.penalty.refundAmount))}
            </Text>
          </View>
        </View>
      </TouchableOpacity>);
    });
    return refundModes;
  }

  componentDidMount() {
    super.componentDidMount();
    SelfDriveBookingTrackingHelper.trackLoadEvent('SelfDriveRefundMode', this.response,"")
  }

  onHardBackPress = () => {
    if (this.state.isOverlayVisible) {
      this.toggleOverlay();
      return true;
    }
    return false;
  };

  goBack = () => {
    Actions.pop();
  };

  onOverlayBackPress = () => {
    this.toggleOverlay();
  };

}
SelfDriveCancellationRefundMode.propTypes = {
  response: PropTypes.object.isRequired,
  penaltyResponse: PropTypes.object.isRequired
};
export default SelfDriveCancellationRefundMode;
