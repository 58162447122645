import React, { useEffect, useReducer, useMemo } from 'react';
import { ActivityIndicator, Dimensions, Image, ScrollView, Text, View } from 'react-native';

import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getFormattedDateFromMillis, dateMonth } from '@mmt/legacy-commons/Helpers/dateHelpers';
import BaseButton, { BUTTON_VARIANT } from '../../Common/BaseButton';
import CenterOverlayMessage from '../../Common/CenterOverlayMessage';
import CommonBottomOverlayMessage from '../../Common/CommonOverlayMessage/CommonBottomOverlayMessage';
import DateTimePicker from '../../Common/DateTimePicker';
import useApi from '../../Common/useApi';
import Actions from '../../navigation/postSalesNavigation';
import { getFont } from '../../PostSalesStyles';
import { useTheme } from '../../theme/theme.context';
import {
  GET_HOLIDAY_CAR_PICKUP_DETAILS,
  SUBMIT_HOLIDAY_CAR_PICKUP_DETAILS
} from '../../utils/NetworkUtils';
import Header from '../Components/Header';
import Tabs from '../Components/Tab';
import createStyle from './CarPickupDetailsCss';
import {
  handleBottomOverlayAction,
  handleOnChangeAction,
  onFieldTouchAction,
  onHandleTabChangeAction,
  onRemoveDetailsCardAction,
  updateForAllCasesAction,
  updatePickupDetailsDataAction
} from './carPickUpState/actions';
import { ACTION_TYPES } from './carPickUpState/actionTypes';
import { carPickupReducer, initialState } from './carPickUpState/reducer';

import { PickupDetailsForm, SelectTransportMode, SubmitPopUp } from './components';

import { BottomOverlayTypes, carPickupDetailsText, pageName, REDIRECT_TO_DETAIL_TIMEOUT } from './constants';
import { createSubmitRequestBody } from './data/requestAdaptor';
import { checkFields, checkValidation, getDateMonth } from './utils';
import ErrorPage from '../../flights/modules/details/components/Error/ErrorPage';
import { InfoIcon } from '../HolidayBookingConstants';

const AddCarPickUpDetails = ({ bookingId }) => {
  const [inProgress, response, api] = useApi(`${GET_HOLIDAY_CAR_PICKUP_DETAILS}/${bookingId}`);
  const [submitInProgress, submitResponse, submitApi] = useApi(SUBMIT_HOLIDAY_CAR_PICKUP_DETAILS);

  const [state, dispatch] = useReducer(carPickupReducer, initialState);

  const { pickupDetails, activeTab, bottomOverlay, showDropOffDetails, isDropOff, currentUpdateIndex, dirtyFields } = state;

  useEffect(() => {
    let timeOutRef;
    if (submitResponse) {
      timeOutRef = setTimeout(() => {
        Actions.holidayBookingDetail({data:{BOOKING_ID:bookingId}});
      }, REDIRECT_TO_DETAIL_TIMEOUT);
    }
    return () => clearTimeout(timeOutRef);
  }, [submitResponse]);

  useEffect(() => {
    if (response && response.pickupDetails) {
      dispatch({
        type: ACTION_TYPES.SET_PICKUP_DETAILS,
        payload: { response },
      });
    } else if (response?.pickupDetails !== null && !response?.error) {
      api.httpGet();
    }
  }, [response]);

  const { psTheme: theme } = useTheme();
  const fonts = getFont(true);
  const styles = createStyle(theme, fonts);

  const onHandleTabChange = (index) => {
    onHandleTabChangeAction(index, dispatch);
  };

  const handleBottomOverlay = (bottomOverlay, isDropOff) => {
    handleBottomOverlayAction(bottomOverlay, isDropOff, dispatch);
  };

  const onDismissOverlay = () => handleBottomOverlay('');

  const updatePickupDetailsData = (index, data, isDropOff) => {
    updatePickupDetailsDataAction(index, data, isDropOff, dispatch);
  };

  const updateForAllCases = (isDropOff) => {
    updateForAllCasesAction(isDropOff, dispatch);
  };

  const handleOnChange = (key, value, isDropOff = false) => {
    handleOnChangeAction(key, value, isDropOff, dispatch);
  };

  const onRemoveDetailsCard = () => {
    const data = response.pickupDetails[activeTab].dropoff;
    onRemoveDetailsCardAction(data, dispatch);
  };

  const handleSubmit = () => {
    if (activeTab === pickupDetails.length - 1) {
      const requestBody = createSubmitRequestBody(pickupDetails, response, bookingId, showDropOffDetails);
      submitApi.httpPost({ body: requestBody });
    } else {
      onHandleTabChange(activeTab + 1);
    }
  };
  const goBack =()=> Actions.pop();

  const isCurrentTabValid = () => {
    if (pickupDetails.length) {
      const isPickUpValid = checkFields(pickupDetails[activeTab].pickup);
      const isDropOffValid = showDropOffDetails.includes(activeTab)
        ? checkFields(pickupDetails[activeTab].dropoff)
        : true;

      return isPickUpValid && isDropOffValid;
    } else {
      return false;
    }
  };

  const isAllTabsValid = useMemo(()=>{
    return checkValidation(pickupDetails, showDropOffDetails)
  },[activeTab, showDropOffDetails]);

  const checkSubmitValidation = () => {
    if (activeTab === pickupDetails.length - 1 && pickupDetails.length !== 1) {
      return !isAllTabsValid && !isCurrentTabValid() ;
    } else {
      return !isCurrentTabValid();
    }
  }; 

  const onFieldTouch = (isDropOff, fieldName)=>{
    onFieldTouchAction(isDropOff, fieldName, dispatch)
  }

  const dateOffset = useMemo(() => {
    if (pickupDetails.length && pickupDetails[activeTab]) {
      // Adding 1 day - 1 minute so that user is able to select time on selected date. FYI: min time is not coming from backned 
      const maxDeparture = new Date(pickupDetails[activeTab].maxArrDate);
      maxDeparture.setHours(23, 59, 0, 0);

      const minDeparture = new Date(pickupDetails[activeTab].minDeptDate);

      let minArrival =
        (isDropOff
          ? pickupDetails[activeTab].dropoff.departureDate
          : pickupDetails[activeTab].pickup.departureDate) || minDeparture;

      // Arrival date can be same as departure date, so adding 1 minute to minArrival
      minArrival = new Date(minArrival);
      minArrival.setMinutes(minArrival.getMinutes() + 1);

      return {
        minArrival,
        maxArrival: maxDeparture,
        minDeparture: minDeparture,
        maxDeparture,
        selectedDepartureDate: isDropOff
          ? pickupDetails[activeTab].dropoff.departureDate || minDeparture
          : pickupDetails[activeTab].pickup.departureDate || minDeparture,

        selectedArrivalDate: isDropOff
          ? pickupDetails[activeTab].dropoff.arrivalDate || minDeparture
          : pickupDetails[activeTab].pickup.arrivalDate || minDeparture,
      };
    }

    return {
      minArrival: null,
      maxArrival: null,
      minDeparture: null,
      maxDeparture: null,
      selectedDepartureDate: new Date(),
      selectedArrivalDate: new Date(),
    };
  }, [activeTab, isDropOff, pickupDetails]);

  const tabItems = pickupDetails.length ? pickupDetails.map((item) => `${item.cityName} (${getFormattedDateFromMillis(item.date, dateMonth )})` ) : [];

  if (response?.pickupDetails === null || response?.error) {
    return (
      <ErrorPage
        title={carPickupDetailsText.somethingWentWrongError}
        btnAction={{ text: carPickupDetailsText.tryAgain }}
        onRefresh={api.httpGet}
      />
    );
  }

  return (
    <>
      <View style={AtomicCss.flex1}>
        <View style={styles.topStickySection}>
          <Header
            title={carPickupDetailsText.headerText}
            subTitle={(response && response.packageName) || ''}
            headerShadow={true}
            pageName={pageName}
            goBack={goBack}
          />
          {response?.disableForm && (
            <View style={styles.errorWrapper}>
              <Image source={InfoIcon} style={styles.infoIcon} />
              <Text style={[AtomicCss.font12, AtomicCss.redText]}>
                {carPickupDetailsText.cantEditErrorText}
              </Text>
            </View>
          )}

          <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
            <View style={styles.TabsWrapper}>
              {tabItems.map((item, index) => {
                return (
                  <Tabs
                    item={item}
                    index={index}
                    onHandleTabChange={onHandleTabChange}
                    activeTab={activeTab}
                    key={String(`${item}${index}`)}
                  />
                );
              })}
            </View>
          </ScrollView>
        </View>
        {inProgress && (
          <View style={[AtomicCss.alignCenter, AtomicCss.justifyCenter, AtomicCss.flex1]}>
            <ActivityIndicator />
          </View>
        )}

        {!!pickupDetails.length && (
          <ScrollView>
            <View style={styles.pickUpDetailsWrapper}>
              <View style={styles.cardWrapper}>
                <PickupDetailsForm
                  handleBottomOverlay={handleBottomOverlay}
                  formData={pickupDetails[activeTab].pickup}
                  handleOnChange={handleOnChange}
                  index={activeTab}
                  updatePickupDetailsData={updatePickupDetailsData}
                  toCity={pickupDetails[activeTab]?.toCity}
                  onUpdateForAllCases={updateForAllCases}
                  currentUpdateIndex={currentUpdateIndex}
                  disableForm={response && response.disableForm}
                  dirtyFields={dirtyFields[activeTab].pickup}
                  onFieldTouch={onFieldTouch}
                />
              </View>

              {showDropOffDetails.includes(activeTab) ? (
                <View style={styles.cardWrapper}>
                  <PickupDetailsForm
                    handleBottomOverlay={handleBottomOverlay}
                    formData={pickupDetails[activeTab].dropoff}
                    handleOnChange={handleOnChange}
                    index={activeTab}
                    updatePickupDetailsData={updatePickupDetailsData}
                    isDropOff={true}
                    toCity={pickupDetails[activeTab]?.toCity}
                    rightAction={!response?.disableForm && { text: carPickupDetailsText.remove, onPress: onRemoveDetailsCard }}
                    onUpdateForAllCases={updateForAllCases}
                    currentUpdateIndex={currentUpdateIndex}
                    disableForm={response && response.disableForm}
                    dirtyFields={dirtyFields[activeTab].dropoff}
                    onFieldTouch={onFieldTouch}
                  />
                </View>
              ) : response && !response.disableForm && (
                <View
                  style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom20]}
                >
                  <BaseButton
                    variant={BUTTON_VARIANT.OUTLINED_CAPSULE}
                    text={carPickupDetailsText.addMore}
                    buttonStyle={styles.buttonStyle}
                    clickHandler={() =>
                      dispatch({
                        type: ACTION_TYPES.ADD_DROP_OFF_DETAILS,
                      })
                    }
                  />
                  <Text style={styles.bottomTxt}>{carPickupDetailsText.addMoreSubtitle}</Text>
                </View>
              )
              }
            {response && !response.disableForm &&   <BaseButton
                variant={BUTTON_VARIANT.PRIMARY}
                clickHandler={handleSubmit}
                disabled={checkSubmitValidation()}
                text={
                  activeTab === pickupDetails.length - 1
                    ? carPickupDetailsText.submit
                    : carPickupDetailsText.next
                }
              />}
            </View>
          </ScrollView>
        )}
      </View>

      {bottomOverlay === BottomOverlayTypes.SELECT_TRANSFER_MODE && (
        <CommonBottomOverlayMessage
          dismiss={onDismissOverlay}
          overlayWrapperStyle={{ height: Dimensions.get('window').height }}
          content={
            <SelectTransportMode
              onSelect={(value) => {
                handleOnChange('travelMode', value, isDropOff);
                onDismissOverlay();
              }}
              selectedTransferMode={pickupDetails[activeTab].pickup.travelMode}
            />
          }
        />
      )}
      {bottomOverlay === BottomOverlayTypes.SELECT_DEPARTURE_TIME && (
        <CenterOverlayMessage
          handleBottomOverlay={onDismissOverlay}
          content={
            <DateTimePicker
              headerText={carPickupDetailsText.selectDepartureDate}
              selectedDate={dateOffset.selectedDepartureDate}
              onSelect={(value) => {
                handleOnChange('departureDate', value, isDropOff);
                onDismissOverlay();
              }}
              minDate={dateOffset.minDeparture}
              maxDate={dateOffset.maxDeparture}
            />
          }
        />
      )}
            {bottomOverlay === BottomOverlayTypes.SELECT_ARRIVAL_TIME && (
        <CenterOverlayMessage
          handleBottomOverlay={onDismissOverlay}
          content={
            <DateTimePicker
              headerText={carPickupDetailsText.selectArrivalDate}
              selectedDate={dateOffset.selectedArrivalDate}
              onSelect={(value) => {
                handleOnChange('arrivalDate', value, isDropOff);
                onDismissOverlay();
              }}
              minDate={dateOffset.minArrival}
              maxDate={dateOffset.maxArrival}
            />
          }
        />
      )}

      {(submitInProgress || submitResponse) && (
        <CenterOverlayMessage
          content={<SubmitPopUp response={submitResponse} inProgress={submitInProgress} />}
        />
      )}
    </>
  );
};

export default AddCarPickUpDetails;
