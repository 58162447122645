import React, { useCallback, useEffect, useState } from 'react';
import { DeviceEventEmitter, View } from 'react-native';
import { SPECIAL_CLAIM_BOTTOM_SHEET_STATES, CANCELLATION_REASONS } from '../../Constant';
import ReasonScreen from './ReasonScreen';
import FlightLoaderAnim from '../../../../Common/FlightLoaderAnimation';
import styles from './SpecialClaimBottomSheetCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import LoadingCard from '../../../../Common/LoadingCard';
import SubmitRefundRequestScreen from './SubmitRefundRequestScreen';
import useApi from '../../../../Common/useApi';
import {
  FLIGHT_CANCELLATION_PREVIEW_URL,
  CLAIM_ANCILLARY_REVIEW,
  CLAIM_ANCILLARY,
} from '../../../../utils/NetworkUtils';
import Actions from '../../../../navigation/postSalesNavigation';
import { isEmptyArray } from '../../../../Common/commonUtil';
import { getStaticData } from '../../../../staticData/staticData';
import FlightBookingTrackingHelper from '../../../modules/details/helper/FlightBookingTrackingHelper';
import { resetToFlightBookingDetail } from '../../../FlightBookingUtil';
import { LOBNAMES } from '../../../../PostSalesConstant';
import { EventEmitterConst } from '../../../modules/details/FlightBookingDetailsConstant';

const SpecialClaimBottomSheet = ({
  bookingDetailsResponse,
  closeSpecialClaimPopUp,
  cancelCardActionItemClick,
  cancellationButtonData,
}) => {
  const [screen, setScreen] = useState(SPECIAL_CLAIM_BOTTOM_SHEET_STATES.REASON_SCREEN);
  const [previewInProgress, previewResponse, previewApi] = useApi(FLIGHT_CANCELLATION_PREVIEW_URL);
  const [detailsProgress, detailsResponse, detailsApi] = useApi(FLIGHT_CANCELLATION_PREVIEW_URL);
  const [ancPreviewInProgress, ancPreviewResponse, ancPreviewApi] = useApi(CLAIM_ANCILLARY_REVIEW);
  const [ancDetailsInProgress, ancDetailsResponse, ancDetailsApi] = useApi(
    CLAIM_ANCILLARY + '/' + bookingDetailsResponse.bookingID + '?newSpecialClaim=true',
  );
  const [scCode, setScCode] = useState();
  const {
    specialClaimVariant,
    flightDetails,
    bookingID,
    specialClaimDataList,
    newSpecialClaimDataListHeader,
    newSpecialClaimCancellationHeader,
    specialClaimV2Enable,
  } = bookingDetailsResponse;

  const goBackToBooking = useCallback(() => {
    resetToFlightBookingDetail(bookingID);
  }, [bookingID]);

  useEffect(() => {
    if (
      (!!detailsResponse && detailsResponse.error) ||
      (!!previewResponse && previewResponse.error) ||
      (!!ancDetailsResponse && ancDetailsResponse.error) ||
      (!!ancPreviewResponse && ancPreviewResponse.error)
    ) {
      Actions.specialClaimError({ goBackToBooking });
      return;
    }
    if (
      !!detailsResponse &&
      !!detailsResponse.newSpecialClaimDataCard &&
      (!detailsResponse.newSpecialClaimDataCard.cardsList ||
        !detailsResponse.newSpecialClaimDataCard.actionLists)
    ) {
      Actions.specialClaimError({ goBackToBooking });
      return;
    }
    if (
      (!!detailsResponse && !detailsResponse.error) ||
      (!!ancDetailsResponse && !ancDetailsResponse.error)
    ) {
      Actions.specialClaimNew({ detailsResponse: detailsResponse || ancDetailsResponse, scCode });
      return;
    }
    if (
      (!!previewResponse && !previewResponse.error) ||
      (!!ancPreviewResponse && !ancPreviewResponse.error)
    ) {
      setScreen(SPECIAL_CLAIM_BOTTOM_SHEET_STATES.SUBMIT_REFUND_SCREEN);
    }
  }, [
    detailsResponse,
    previewResponse,
    ancDetailsResponse,
    ancPreviewResponse,
    goBackToBooking,
    scCode,
  ]);
  const { checkingWithAirlineText } = getStaticData();

  const getPaxFareSegmentList = (scCode) => {
    const paxFareSegmentList = [];
    if (!!flightDetails || !isEmptyArray(flightDetails.segmentGroupDetailList)) {
      flightDetails.segmentGroupDetailList.forEach((segmentGroupDetail) => {
        if (!isEmptyArray(segmentGroupDetail.segmentDetails)) {
          segmentGroupDetail.segmentDetails.forEach((segmentDetail) => {
            if (!isEmptyArray(segmentDetail.segmentPassengerDetail)) {
              segmentDetail.segmentPassengerDetail.forEach((segmentPassengerDetail) => {
                if (!segmentPassengerDetail.isPaxCancelled) {
                  const paxFareSegment = {};
                  paxFareSegment.reason = scCode;
                  paxFareSegment.paxFareSegmentId = segmentPassengerDetail.paxFareSegmentId;
                  paxFareSegmentList.push(paxFareSegment);
                }
              });
            }
          });
        }
      });
    }
    return paxFareSegmentList;
  };

  const submitRefundRequest = () => {
    DeviceEventEmitter.emit(EventEmitterConst.CTA_CLICKED_EVENT);
    switch (scCode) {
      case CANCELLATION_REASONS.ANCILLARY_REFUND:
        ancDetailsApi.clearResponse();
        ancDetailsApi.httpGet({
          bookingID,
          uniqueId: 'ANCILLARY_REFUND',
          psLob: LOBNAMES.POST_SALES,
        });
        FlightBookingTrackingHelper.trackClickEvent(
          FlightBookingTrackingHelper.getPageName(
            'Specialclaim_initiaterequest_new',
            bookingDetailsResponse,
          ),
          'MI_FLT_Sclaim_New_anc_Initiation',
        );
        break;
      default:
        detailsApi.clearResponse();
        const paxFareSegmentIdList = getPaxFareSegmentList(scCode);
        const body = {
          bookingId: bookingID,
          isFullCancellation: false,
          validateClaim: true,
          newSpecialClaim: true,
          fullCancellationReason: scCode,
          refundOption: 'RefundToCustomer',
          lobCode: bookingID,
          paxFareSegmentIdList: paxFareSegmentIdList,
        };
        detailsApi.httpPost({
          body: body,
          bookingId: bookingID,
          uniqueId: 'CANCELLATION_DETAIL',
          psLob: LOBNAMES.FLIGHT,
        });
        FlightBookingTrackingHelper.trackClickEvent(
          FlightBookingTrackingHelper.getPageName(
            'Specialclaim_initiaterequest_new',
            bookingDetailsResponse,
          ),
          'MI_FLT_Sclaim_New_Initiation',
        );
    }
  };

  const handleTab = (tabName) => {
    setScCode(tabName);
    const pageName = FlightBookingTrackingHelper.getPageName(
      'MI_FLT_CITP_SClaim',
      bookingDetailsResponse,
    );
    switch (tabName) {
      case CANCELLATION_REASONS.PLANS_CHANGED:
        cancelCardActionItemClick(
          cancellationButtonData.actionItem,
          cancellationButtonData.pageName,
          true,
          true,
        );
        FlightBookingTrackingHelper.trackClickEvent(pageName, 'MI_FLT_CITP_SClaim');
        break;
      case CANCELLATION_REASONS.ANCILLARY_REFUND:
        const ancrbody = {
          bookingId: bookingID,
          newSpecialClaim: true,
        };
        ancPreviewApi.httpPost({
          body: ancrbody,
          bookingId: bookingID,
          uniqueId: 'SC_ANC_PREVIEW',
          psLob: LOBNAMES.FLIGHT,
        });
        FlightBookingTrackingHelper.trackClickEvent(pageName, 'MI_FLT_Sclaim_New_Anc');
        break;
      default:
        if (specialClaimV2Enable) {
          Actions.specialClaimV2({ bookingID, actionId: tabName, bookingDetailsResponse });
          closeSpecialClaimPopUp();
        }
        else {
          const paxFareSegmentIdList = getPaxFareSegmentList(tabName);
          const body = {
            bookingId: bookingID,
            isFullCancellation: false,
            validateClaim: false,
            newSpecialClaim: true,
            fullCancellationReason: tabName,
            refundOption: 'RefundToCustomer',
            lobCode: bookingID,
            paxFareSegmentIdList: paxFareSegmentIdList,
          };
          previewApi.httpPost({
            body: body,
            bookingId: bookingID,
            uniqueId: 'SC_PREVIEW',
            psLob: LOBNAMES.FLIGHT,
          });
          DeviceEventEmitter.emit(EventEmitterConst.CTA_CLICKED_EVENT);
          FlightBookingTrackingHelper.trackClickEvent(pageName, 'MI_FLT_Sclaim_New');
      }
    }
  };

  const handleBack = () => {
    setScreen(SPECIAL_CLAIM_BOTTOM_SHEET_STATES.REASON_SCREEN);
    previewApi.clearResponse();
    detailsApi.clearResponse();
    ancPreviewApi.clearResponse();
    ancDetailsApi.clearResponse();
  };

  const loadSpecialClaimScreen = () => {
    if (previewInProgress || detailsProgress || ancPreviewInProgress || ancDetailsInProgress) {
      return null;
    }
    switch (screen) {
      case SPECIAL_CLAIM_BOTTOM_SHEET_STATES.REASON_SCREEN:
        return (
          <ReasonScreen
            reasons={specialClaimDataList}
            header={
              cancellationButtonData.src === 'EXPECTING_REFUND'
                ? newSpecialClaimDataListHeader
                : newSpecialClaimCancellationHeader
            }
            handleTab={handleTab}
            specialClaimVariant={specialClaimVariant}
            src={cancellationButtonData.src}
          />
        );
      case SPECIAL_CLAIM_BOTTOM_SHEET_STATES.SUBMIT_REFUND_SCREEN:
        return (
          <SubmitRefundRequestScreen
            submitRefundRequest={submitRefundRequest}
            previewResponse={previewResponse || ancPreviewResponse}
            handleBack={handleBack}
          />
        );
    }
  };

  return (
    <View style={styles.OverlayContentWrapper}>
      <View style={AtomicCss.marginBottom10}>
        <View style={styles.overlayTopline} />
      </View>
      {(previewInProgress || ancPreviewInProgress) && <LoadingCard rows={2} />}
      {(detailsProgress || ancDetailsInProgress) && (
        <View style={styles.submitReqWrapper}>
          <FlightLoaderAnim text={checkingWithAirlineText + '...'} />
        </View>
      )}
      {loadSpecialClaimScreen()}
    </View>
  );
};

export default SpecialClaimBottomSheet;
