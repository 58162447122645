import { StyleSheet, Dimensions } from "react-native";
import getPlatformElevation from 'packages/legacy-commons/Styles/getPlatformElevation';
import { DEFAULT_THEME } from "apps/post-sales/src/theme/default.theme";

export default <T extends typeof DEFAULT_THEME>(theme: T, fonts: any) =>StyleSheet.create({
    filterOverlayWrapper: {
        width: Dimensions.get('window').width,
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 11,
        elevation: 4,
    },
    filterMainWrapper: {
        backgroundColor: theme.color.white,
    },
    filterHeaderWrapper: {
        ...getPlatformElevation(2),
        backgroundColor: theme.color.white,
    },
    filterHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingHorizontal: 20,
        paddingVertical: 20,
        marginBottom: 3,
        alignItems: 'center',
    },

    btnWrapper: {
        padding: 20,
    },
    selectedFilterWrapper: {
        flexDirection: 'row',
        paddingVertical: 12,
        paddingHorizontal: 16,
        borderTopWidth: 1,
        borderTopColor: theme.color.grey2,
        flexWrap: 'wrap',
    },
    selectedFilter: {
        backgroundColor: theme.color.lighterBlue,
        borderWidth: 1,
        borderColor: theme.color.azure,
        borderRadius: 8,
        paddingVertical: 5,
        paddingHorizontal: 10,
        marginRight: 8,
        paddingRight: 25,
    },
    selectedFilterText: {
        color: theme.color.azure,
        fontSize: fonts.fontSize.font14,
        ...fonts.boldFontFamily
    },
    crossIconWrapper: {
        position: 'absolute',
        right: 5,
        transform: [
            {
                rotate: '45deg',
            },
        ],
    },
    crossStyle: {
        color: theme.color.azure,
        ...fonts.regularFontFamily,
        fontSize: fonts.fontSize.font22,
    },
    animatedOuter: {
        backgroundColor: theme.color.grey18,
        position: 'relative',
        overflow: 'hidden',
        width: 80,
        height: 45,
        borderRadius: 8,
        marginRight: 8,
    },
});