import React from 'react'
import { View, Text, Image } from 'react-native';
import { getFont } from '../../../../PostSalesStyles';
import { useTheme } from '../../../../theme/theme.context';
import AtomicCss from '../../../commonStyles/AtomicCss';
import { VOUCHER_2X_IMG, MAP_LOCATION_IMG} from '../../../Utils/RailImageConstant';
import createStyles from "./termsAndConditionsCss";
const TnC = ({ termsAndCondition }) => {
    const { psTheme: theme } = useTheme();
    const fonts = getFont();
    const tncStyle =  createStyles(theme,fonts);
    const { flightMsg, validityMsg } = termsAndCondition || {};
    const { description, departureDetail, arrivalDetail } = flightMsg || {};
    let { label: depLabel, airports: depAirports } = departureDetail || {};
    let { label: arrLabel, airports: arrAirports } = arrivalDetail || {};
    return (
        <View>
            <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom10]}>
                <Image style={tncStyle.voucher2xStyle} source={VOUCHER_2X_IMG} />
                {!!validityMsg &&
                    <Text style={[ AtomicCss.font10, fonts.regularFontFamily, AtomicCss.flex1]}>
                        {validityMsg}
                    </Text>
                }
            </View>
            <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
                <Image style={tncStyle.mapLocationStyle} source={MAP_LOCATION_IMG} />
                {!!description &&
                    <Text style={[ AtomicCss.font10, fonts.regularFontFamily, AtomicCss.flex1]}>
                        {description}
                    </Text>
                }
            </View>
            <View style={tncStyle.moreDetls}>
                {departureDetail &&
                    <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom5]}>
                        {!!depLabel && <Text style={[tncStyle.titleTxt, fonts.boldFontFamily]}>{depLabel}</Text>}
                        {!!depAirports && <Text style={[AtomicCss.font10, fonts.regularFontFamily, AtomicCss.flex1]}> {depAirports} </Text>}
                    </View>
                }
                {arrivalDetail &&
                    <View style={[AtomicCss.flexRow, AtomicCss.alignCenter,AtomicCss.marginTop10]}>
                        {!!arrLabel && <Text style={[tncStyle.titleTxt, fonts.boldFontFamily]}>{arrLabel}</Text>}
                        {!!arrAirports && <Text style={[ AtomicCss.font10, fonts.regularFontFamily, AtomicCss.flex1]}> {arrAirports} </Text>}
                    </View>
                }
            </View>
        </View>
    )
}

export default TnC;