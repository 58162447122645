import React from 'react';
import {Text, View} from 'react-native';
import PropTypes from 'prop-types';
import styles from '../../flights/modules/details/components/PaymentPriceBreakup/PayModeBreakup/PayModeBreakupCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getStaticData } from '../../staticData/staticData';
import { getFont } from '../../PostSalesStyles';
class PayModeBreakup extends React.Component {

    render() {
        const { fsStyle } = getFont(true);
        const {paymentBreakupList, paymentBreakup} = this.props;
        const staticData = getStaticData(true);
        const { paymentBreakupText } = staticData;
        return (
            <View style={[AtomicCss.whiteBg, AtomicCss.marginBottom10]}>
                <View style={[styles.CardContentFull]}>
                    <View style={styles.cardHeadingWrapper}>
                        <Text style={styles.cardHeading}>{ paymentBreakupText }</Text>
                    </View>
                    <View style={styles.fareBreakupInnerSection}>

                        {this.getPayModeViews(paymentBreakupList)}
                    </View>

                    <View style={[styles.totalPrice]}>
                        <Text style={[styles.totalPricetxt, fsStyle.font16]}>{paymentBreakup.paymentBreakupAmountPaid.text}</Text>
                        <Text style={[styles.totalPricetxt, fsStyle.font18]}>
                            {paymentBreakup.paymentBreakupAmountPaid.value} 
                        </Text>
                    </View>
                </View>
            </View>
        );
    }

    getPayModeViews(paymentBreakupList) {
        const views = [];
        for (let componentName in paymentBreakupList) {
            views.push(
                <View style={[styles.rowSection]}>
                    <View style={AtomicCss.flexRow}>
                        <View>
                            <Text style={[styles.fareBkHeadingTxt, AtomicCss.marginBottom3]}>{componentName}</Text>
                        </View>
                    </View>
                    <View>
                        <Text style={[styles.fareBkHeadingTxt, AtomicCss.marginBottom3]}>
                            {paymentBreakupList[componentName]}
                        </Text>
                    </View>
                </View>
            )
        }
        return views;
    }
}

export default PayModeBreakup;

PayModeBreakup.propTypes = {
    paymentBreakup: PropTypes.object.isRequired,
    paymentBreakupList: PropTypes.array.isRequired,
};

