import React from 'react';
import { Text, View } from 'react-native';
import styles from './InfoListCardCss';
import { getFont } from '../../../../PostSalesStyles';

const InfoListCard = ({ importantInformation }) => {
  const { fsStyle, ...fonts } = getFont(true);
  return (
    <View style={styles.dottedList}>
      {importantInformation.map((info) => (
        <View style={styles.dottedListItem}>
          <View style={styles.dottedListDot} />
          <Text style={[styles.dottedListText, fonts.regularFontFamily, fsStyle.font14]}>
            {info}
          </Text>
        </View>
      ))}
    </View>
  );
};

export default InfoListCard;
