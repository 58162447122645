import React from 'react';
import { View, TouchableOpacity } from 'react-native';
import styles from './CenterOverlayMessageCss';

const CenterOverlayMessage = ({ content, handleBottomOverlay }) => {
  return (
    <View style={styles.overlayWrapper}>
      <TouchableOpacity
        onPress={handleBottomOverlay}
        activeOpacity={1}
        style={styles.overlay}
      ></TouchableOpacity>
      {content}
    </View>
  );
};
export default CenterOverlayMessage;
