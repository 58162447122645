import React, { useState, useEffect, useRef } from "react";
import PropTypes from 'prop-types';
import {
  View,
  ScrollView,
  BackHandler,
  Platform,
  StyleSheet,
  Text,
  DeviceEventEmitter,
  Alert
} from 'react-native';
import Actions from '../../navigation/postSalesNavigation';

import {getCommonHeaders, HOLIDAY_DOCUMENTS_UPLOAD_URL} from '../../utils/NetworkUtils';

import DocumentsPageHeader from './Header';
import DocumentsRequiredCard from './DocumentsRequiredCard';
import DocumentsPendingHeader from './HeaderCard/DocumentsPending';
import DocumentsPendingTimelineMissedHeader from './HeaderCard/DocumentsPendingTimelineMissed';
import DocumentsUploadedHeader from './HeaderCard/DocumentsUploaded';
import DocumentUploadCard from './DocumentUploadCard';

// Overlay imports
import Overlay from './Overlay';
import WhyDocumentsOverlayContent from './OverlayContent/WhyDocuments';
import NoDocumentsUploadedOverlayContent from './OverlayContent/NoDocumentsUploaded';
import NotAllDocumentsUploadedOverlayContent from './OverlayContent/NotAllDocumentsUploaded';

import ViewControllerModule from '@mmt/legacy-commons/Native/ViewControllerModule';
import SpecialClaimAddAttachmentModule from '@mmt/legacy-commons/Native/SpecialClaimAddAttachmentModule';

import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from '@mmt/legacy-commons/Common/Components/Card/getPlatformElevation';
import {CrossIcon} from '../HolidayBookingConstants';
import HolidayBookingTrackingHelper from '../HolidayBookingTrackingHelper';

const OVERLAY_CONSTANTS = {
  CLOSED: '',
  WHY_DOCUMENTS: 'WHY_DOCUMENTS',
  NO_DOCUMENTS_UPLOADED: 'NO_DOCUMENTS_UPLOADED',
  NOT_ALL_DOCUMENTS_UPLOADED: 'NOT_ALL_DOCUMENTS_UPLOADED'
};
const UPLOAD_STATUS_CONSTANTS = {
  REQUIRED: 0,
  PENDING: 1,
  UPLOADED: 2,
  TIMELINE_MISSED: 3
};

const json_log = (x) => console.log(JSON.stringify(x, null, 2));

let FETCH_ABORT_CONTROLLER = null;

const onCancelRequest = () => {
  if (FETCH_ABORT_CONTROLLER) {
    FETCH_ABORT_CONTROLLER.abort();
  }
};

const fetchAsync = async (url, data, mmtAuth, loggingTrackInfo) => {
  json_log({url, data});
  if (FETCH_ABORT_CONTROLLER) {
    FETCH_ABORT_CONTROLLER = null;
  }
  FETCH_ABORT_CONTROLLER = new AbortController();
  const response = await fetch(url, {
    method: 'POST',
    body: data,
    headers: {
      ...(await getCommonHeaders(mmtAuth, loggingTrackInfo)),
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf8'
    },
    signal: FETCH_ABORT_CONTROLLER.signal
  });
  json_log({response});
  if (!response.ok) {
    return [null, '4003'];
  }
  try {
    const body = await response.json();
    json_log({body});
    return [body, null];
  } catch (e) {
    console.log(e);
    return [null, '4003'];
  }
};

export default function DocumentsPage(props) {
  const [overlay, setOverlay] = useState(OVERLAY_CONSTANTS.CLOSED);
  const [attachmentsMap, setAttachmentsMap] = useState(new Map());
  const fileSelectedListener = useRef(null);
  const [travellerDocumentsData, setTravellersDocumentsData] = useState(() => {
    const {travellerDocDetails} = props.documentPageDetails;
    if (!travellerDocDetails) {
      return {};
    }
    if (!Object.keys(travellerDocDetails).length) {
      return {};
    }
    const _travellerDocumentsData = {};
    let hasAttachmentsMapUpdated = false;
    travellerDocDetails.forEach((details, index) => {
      const traveller = index + 1;
      _travellerDocumentsData[traveller] = {};
      try {
        Object.keys(details.docsList).forEach((docType) => {
          const docData = details.docsList[docType];
          _travellerDocumentsData[traveller][docType] = {
            required: 1,
            docs: {},
            uploaded: 0
          };
          const uploadedDocs = [];
          docData.forEach((doc) => {
            const __docData = {
              attachments: {},
              travellerId: null,
              uploadStatus: 'New',
              errorCode: null
            };
            if (doc.fileName !== null && doc.uploadStatus === 'Submitted') {
              __docData.attachments = {
                [doc.fileName]: {
                  fileName: doc.fileName,
                  displayFileName: doc.fileName
                }
              };
              __docData.uploadStatus = 'Submitted';
              uploadedDocs.push(doc.docName);
              hasAttachmentsMapUpdated = true;
            }
            _travellerDocumentsData[traveller][docType].docs[doc.docName] = __docData;
          });
          if (uploadedDocs.length === docData.length) {
            attachmentsMap.set(`${traveller}`, true);
            _travellerDocumentsData[traveller][docType].uploaded = 1;
          } else {
            _travellerDocumentsData[traveller][docType].uploaded = 0;
          }
        });
      } catch (e) {
        console.log(e);
      }
    });
    if (hasAttachmentsMapUpdated) {
      setAttachmentsMap(attachmentsMap, 'travellerDocumentsData');
    }
    return _travellerDocumentsData;
  });
  const [uploadDocumentData, setUploadDocumentData] = useState({});
  const [uploadFileData, setUploadFileData] = useState({});
  useEffect(() => {
    HolidayBookingTrackingHelper.trackViewEvent(
      props.pageName,
      'UploadDocsTravellerList',
    );
  }, []);

  const getUploadURLAndPayload = async ({
    filePath,
    fileName,
    bookingId,
    fileExtension,
    docType,
    travellerId,
    base64String
  }) => {
    let imageResponse = '';
    const data = {};
    if (Platform.OS === 'ios') {
      attachmentsMap.forEach((obj) => {
        if (obj.filePath === filePath) {
          imageResponse = obj.data;
        }
      }, this);
      data.content = imageResponse;
    } else if (Platform.OS === 'web') {
      data.content = base64String;
    } else {
      const map = await SpecialClaimAddAttachmentModule.convertToBase64WithNoWrap(filePath);
      data.content = map.imageResponse;
    }
    const url = `${HOLIDAY_DOCUMENTS_UPLOAD_URL}/${fileName}/${bookingId}/${fileExtension}/${docType}/${travellerId}`;
    return [url, JSON.stringify(data)];
  };

  const triggerFileUploadFlow = async (_uploadDocumentData) => {
    let {traveller, documentType, uploadDocumentType, travellerId, docType} = {};
    if (_uploadDocumentData) {
      // eslint-disable-next-line prefer-destructuring
      traveller = _uploadDocumentData.traveller;
      // eslint-disable-next-line prefer-destructuring
      documentType = _uploadDocumentData.documentType;
      // eslint-disable-next-line prefer-destructuring
      uploadDocumentType = _uploadDocumentData.uploadDocumentType;
      // eslint-disable-next-line prefer-destructuring
      travellerId = _uploadDocumentData.travellerId;
      // eslint-disable-next-line prefer-destructuring
      docType = _uploadDocumentData.docType;
    } else {
      // eslint-disable-next-line prefer-destructuring
      traveller = uploadDocumentData.traveller;
      // eslint-disable-next-line prefer-destructuring
      documentType = uploadDocumentData.documentType;
      // eslint-disable-next-line prefer-destructuring
      uploadDocumentType = uploadDocumentData.uploadDocumentType;
      // eslint-disable-next-line prefer-destructuring
      travellerId = uploadDocumentData.travellerId;
      // eslint-disable-next-line prefer-destructuring
      docType = uploadDocumentData.docType;
    }

    handleButtonClick(`Traveller${traveller}_${uploadDocumentType.split(' ').join('')}`);

    const _travellerDocumentsData = {...travellerDocumentsData};
    attachmentsMap.delete(`${traveller}`);
    setAttachmentsMap(attachmentsMap, 'triggerFileUploadFlow');
    _travellerDocumentsData[traveller][documentType].docs[uploadDocumentType] = {
      attachments: {},
      travellerId,
      uploadStatus: 'Uploading',
      errorCode: null
    };

    uploadFileData.displayFileName = `${uploadDocumentType.split(' ').join('')}.${
      uploadFileData.extension
    }`;

    _travellerDocumentsData[traveller][documentType].docs[uploadDocumentType].attachments[
      uploadFileData.displayFileName
    ] = uploadFileData;
    setTravellersDocumentsData(_travellerDocumentsData);

    const [url, data] = await getUploadURLAndPayload({
      filePath: uploadFileData.filePath,
      fileName: uploadFileData.displayFileName,
      bookingId: props.bookingId,
      fileExtension: uploadFileData.extension,
      base64String: uploadFileData.base64String,
      docType,
      travellerId
    });
    const [response, error] = await fetchAsync(
      url,
      data,
      props.mmtAuth,
      props.loggingTrackInfo
    );
    const __travellerDocumentsData = {..._travellerDocumentsData};
    if (error || (response && !response.uploadStatus)) {
      handleButtonClick(
        `Traveller${traveller}_UnableToUpload_${uploadDocumentType.split(' ').join('')}`
      );
      __travellerDocumentsData[traveller][documentType].docs[uploadDocumentType].uploadStatus =
        'Error';
      __travellerDocumentsData[traveller][documentType].docs[uploadDocumentType].errorCode =
        error || '4003';
    } else {
      handleButtonClick(
        `Traveller${traveller}_SuccessfullyUploaded_${uploadDocumentType.split(' ').join('')}`
      );
      __travellerDocumentsData[traveller][documentType].docs[uploadDocumentType].uploadStatus =
        'Submitted';
      __travellerDocumentsData[traveller][documentType].docs[uploadDocumentType].errorCode = null;
      props.refreshTripDetailsPage();
    }

    const docKeys = Object.keys(__travellerDocumentsData[traveller][documentType].docs);
    const uploadedDocs = docKeys
      .map((_uploadDocumentType) => {
        const docData = __travellerDocumentsData[traveller][documentType].docs[_uploadDocumentType];
        if (
          docData.uploadStatus === 'Submitted' &&
          docData.attachments &&
          Object.keys(docData.attachments).length
        ) {
          return Object.keys(docData.attachments)[0];
        }
        return null;
      })
      .filter((x) => x !== null);
    if (docKeys.length === uploadedDocs.length) {
      __travellerDocumentsData[traveller][documentType].uploaded = 1;
    } else {
      __travellerDocumentsData[traveller][documentType].uploaded = 0;
    }

    setTravellersDocumentsData(__travellerDocumentsData);
  };

  const onCancelUpload = ({traveller, documentType, uploadDocumentType, travellerId}) => {
    attachmentsMap.delete(`${traveller}`);
    setAttachmentsMap(attachmentsMap, 'onCancelUpload');
    onCancelRequest();
    const _travellerDocumentsData = {...travellerDocumentsData};
    _travellerDocumentsData[traveller][documentType].docs[uploadDocumentType] = {
      attachments: {},
      travellerId,
      uploadStatus: 'New',
      errorCode: null
    };
    const docKeys = Object.keys(_travellerDocumentsData[traveller][documentType].docs);
    const uploadedDocs = docKeys
      .map((_uploadDocumentType) => {
        const docData = _travellerDocumentsData[traveller][documentType].docs[_uploadDocumentType];
        if (
          docData.uploadStatus === 'Submitted' &&
          docData.attachments &&
          Object.keys(docData.attachments).length
        ) {
          return Object.keys(docData.attachments)[0];
        }
        return null;
      })
      .filter((x) => x !== null);
    if (docKeys.length === uploadedDocs.length) {
      _travellerDocumentsData[traveller][documentType].uploaded = 1;
    } else {
      _travellerDocumentsData[traveller][documentType].uploaded = 0;
    }
    setTravellersDocumentsData(_travellerDocumentsData);
  };

  const onRetryUpload = (_uploadDocumentsData) => {
    triggerFileUploadFlow(_uploadDocumentsData);
  };

  const onDocumentPreview = ({fileName}) => {
    if (fileName) {
      Alert.alert('', 'Preview is coming soon!');
    }
  };

  useEffect(() => {
    if (Object.keys(uploadFileData).length && Object.keys(uploadDocumentData).length) {
      json_log({
        src: 'useEffect',
        uploadFileData,
        uploadDocumentData
      });
      triggerFileUploadFlow();
    }
  }, [uploadFileData, uploadDocumentData]);

  const handleButtonClick = (linkName) => {
    HolidayBookingTrackingHelper.trackClickEvent(
      props.pageName,
      `UploadDocsTravellerList_${linkName}`,
    );
  };

  const goBack = () => {
    props.onBackPopUp();
  };

  const onBackPress = () => {
    handleButtonClick('Back');
    if (props.response.holidayDocumentsCard.uploadStatus === UPLOAD_STATUS_CONSTANTS.REQUIRED) {
      setOverlay(OVERLAY_CONSTANTS.NO_DOCUMENTS_UPLOADED);
      return true;
    } else if (props.response.holidayDocumentsCard.uploadStatus === UPLOAD_STATUS_CONSTANTS.PENDING) {
      setOverlay(OVERLAY_CONSTANTS.NOT_ALL_DOCUMENTS_UPLOADED);
      return true;
    }
    goBack();
  };

  const onFileSelectedFromNativeEnd = (fileData) => {
    const _fileData = {...fileData, extension: fileData.fileName.split('.')[1]};
    setUploadFileData(_fileData);
  };

  const onUploadButtonPress = (_uploadDocumentData) => {
    if (!props.documentPageDetails.submitToVendor) {
        setUploadDocumentData(_uploadDocumentData);
        setUploadFileData({});
      if(Platform.OS !== 'web'){
        SpecialClaimAddAttachmentModule.onButtonClicked();
      }
    }
  };
  const onFileSelectedFromWeb=(fileDataList, uploadDocData)=>{
    setUploadDocumentData(uploadDocData);
    const fileData = fileDataList[0];
    const _fileData = {...fileData, extension: fileData.fileName.split('.')[1]};
    setUploadFileData(_fileData);
  }

  useEffect(() => {
    BackHandler.addEventListener('hardwareBackPress', onBackPress);
    fileSelectedListener.current = DeviceEventEmitter.addListener('special_claim_review_page', onFileSelectedFromNativeEnd);

    if (props.response) {
      if (
        props.response.holidayDocumentsCard &&
        Object.keys(props.response.holidayDocumentsCard).length
      ) {
        switch (props.response.holidayDocumentsCard.uploadStatus) {
          case UPLOAD_STATUS_CONSTANTS.REQUIRED:
            HolidayBookingTrackingHelper.trackClickEvent(props.pageName,'NoneUploaded');
            HolidayBookingTrackingHelper.trackClickEvent(props.pageName, 'InTimeline');
            break;
          case UPLOAD_STATUS_CONSTANTS.PENDING:
            HolidayBookingTrackingHelper.trackClickEvent(props.pageName,  'PartialUploaded');
            HolidayBookingTrackingHelper.trackClickEvent(props.pageName,  'InTimeline');
            break;
          case UPLOAD_STATUS_CONSTANTS.UPLOADED:
            HolidayBookingTrackingHelper.trackClickEvent(props.pageName,  'AllUploaded');
            HolidayBookingTrackingHelper.trackClickEvent(props.pageName,  'InTimeline');
            break;
          case UPLOAD_STATUS_CONSTANTS.TIMELINE_MISSED:
            HolidayBookingTrackingHelper.trackClickEvent(props.pageName,  'OutOfTimeline');
            break;
          default:
            break;
        }
      }
    }

    return () => {
      BackHandler.removeEventListener('hardwareBackPress', onBackPress);
      if (fileSelectedListener.current) {
        fileSelectedListener.current.remove();
      }
    };
  }, []);

  const renderDocumentsHeader = () => {
    switch (props.response.holidayDocumentsCard.uploadStatus) {
      case UPLOAD_STATUS_CONSTANTS.PENDING:
        return <DocumentsPendingHeader documentPageDetails={props.documentPageDetails} />;
      case UPLOAD_STATUS_CONSTANTS.UPLOADED:
        return <DocumentsUploadedHeader documentPageDetails={props.documentPageDetails} />;
      case UPLOAD_STATUS_CONSTANTS.TIMELINE_MISSED:
        if (props.documentPageDetails.submitToVendor) {
          return <DocumentsUploadedHeader documentPageDetails={props.documentPageDetails} />;
        }
        return (
          <DocumentsPendingTimelineMissedHeader documentPageDetails={props.documentPageDetails} />
        );
      default:
        return null;
    }
  };

  const renderDocumentsCard = (position) => {
    const isUploaded =
      props.response.holidayDocumentsCard.uploadStatus === UPLOAD_STATUS_CONSTANTS.UPLOADED;
    if (position === 'top' && isUploaded) {
      return null;
    }
    if (position === 'bottom' && !isUploaded) {
      return null;
    }
    return (
      <DocumentsRequiredCard
        documentPageDetails={props.documentPageDetails}
        handleOpenWhyDocumentsOverlay={handleOpenWhyDocumentsOverlay}
      />
    );
  };

  const renderTravellerDocumentDetails = () => {
    const {travellerDocDetails} = props.documentPageDetails || {};
    if (!travellerDocDetails) {
      return null;
    }
    const isPending =
      props.response.holidayDocumentsCard.uploadStatus === UPLOAD_STATUS_CONSTANTS.PENDING;
    return (
      <React.Fragment>
        {travellerDocDetails.map((details, index) => {
          return (
            <DocumentUploadCard
              key={details.travellerId}
              {...details}
              isExpanded={index === 0}
              traveller={index + 1}
              onUploadButtonPress={onUploadButtonPress}
              travellerDocumentsData={travellerDocumentsData}
              onRetryUpload={onRetryUpload}
              uploadMessage={props.documentPageDetails.uploadMessage}
              handleButtonClick={handleButtonClick}
              onCancelUpload={onCancelUpload}
              attachmentsMap={attachmentsMap}
              setAttachmentsMap={setAttachmentsMap}
              isPending={isPending}
              onDocumentPreview={onDocumentPreview}
              onFileSelectedFromWeb={onFileSelectedFromWeb}
            />
          );
        })}
      </React.Fragment>
    );
  };

  const handleOpenWhyDocumentsOverlay = () => {
    handleButtonClick('WhyDoINeedToUploadDocs');
    setOverlay(OVERLAY_CONSTANTS.WHY_DOCUMENTS);
  };

  const onCloseOverlay = () => setOverlay(OVERLAY_CONSTANTS.CLOSED);

  const renderOverlay = () => {
    if (overlay === OVERLAY_CONSTANTS.WHY_DOCUMENTS) {
      return (
        <Overlay
          component={
            <WhyDocumentsOverlayContent
              isOverlayVisble
              onClose={onCloseOverlay}
              documentPageDetails={props.documentPageDetails}
              handleButtonClick={handleButtonClick}
            />
          }
          onClose={onCloseOverlay}
          handleButtonClick={() => handleButtonClick('WhyDoINeedToUploadDocs_OK')}
        />
      );
    }
    if (overlay === OVERLAY_CONSTANTS.NO_DOCUMENTS_UPLOADED) {
      return (
        <Overlay
          component={
            <NoDocumentsUploadedOverlayContent
              isOverlayVisble
              onClose={onCloseOverlay}
              goBack={goBack}
              documentPageDetails={props.documentPageDetails}
              handleButtonClick={handleButtonClick}
            />
          }
          onClose={onCloseOverlay}
          handleButtonClick={() => handleButtonClick('NudgesOnBack_UploadNow')}
        />
      );
    }
    if (overlay === OVERLAY_CONSTANTS.NOT_ALL_DOCUMENTS_UPLOADED) {
      return (
        <Overlay
          component={
            <NotAllDocumentsUploadedOverlayContent
              isOverlayVisble
              onClose={onCloseOverlay}
              goBack={goBack}
              documentPageDetails={props.documentPageDetails}
              handleButtonClick={handleButtonClick}
            />
          }
          onClose={onCloseOverlay}
          handleButtonClick={() => handleButtonClick('NudgesOnBack_UploadNow')}
        />
      );
    }
    return null;
  };

  return (
    <View style={styles.PageWrapper}>
      <DocumentsPageHeader
        headerText="Upload Documents"
        imgSrc={CrossIcon}
        whiteHeader
        backPressHandler={onBackPress}
        elevation={5}
      />
      <ScrollView>
        {renderDocumentsHeader()}
        {renderDocumentsCard('top')}
        {renderTravellerDocumentDetails()}
        {renderDocumentsCard('bottom')}
        <View style={styles.btnWrapper}>
          <Text style={[AtomicCss.greyText, AtomicCss.regularFont, AtomicCss.textCenter]}>
            {props.documentPageDetails.note}
          </Text>
        </View>
      </ScrollView>
      {renderOverlay()}
    </View>
  );
}

DocumentsPage.propTypes = {
  response: PropTypes.shape({
    holidayDocumentsCard: PropTypes.shape({
      uploadStatus: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  documentPageDetails: PropTypes.shape({
    topHeaderText: PropTypes.string,
    topHeaderSubText: PropTypes.string,
    customerTimeline: PropTypes.string.isRequired,
    heading: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    paxDocRelationNewState: PropTypes.shape([
      PropTypes.shape({
        heading: PropTypes.string.isRequired,
        paxName: PropTypes.string.isRequired,
        docs: PropTypes.string.isRequired,
        docsConditions: PropTypes.string
      })
    ]),
    uploadMessage: PropTypes.shape({
      [PropTypes.string]: PropTypes.shape({
        text: PropTypes.string.isRequired,
        subText: PropTypes.string.isRequired,
        buttons: PropTypes.array.isRequired
      })
    }),
    bottomSheet: PropTypes.object.isRequired,
    note: PropTypes.string.isRequired,
    noOfTotalDoc: PropTypes.number.isRequired,
    submitToVendor: PropTypes.bool.isRequired
  }).isRequired,
  pageName: PropTypes.string.isRequired,
  bookingId: PropTypes.string.isRequired,
  refreshTripDetailsPage: PropTypes.func.isRequired
};

const styles = StyleSheet.create({
  PageWrapper: {
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: '#f2f2f2',
    elevation: 3,
    zIndex: 3,
    position: 'absolute',
    width: '100%',
    height: '100%'
  },
  btnWrapper: {
    margin: 16,
    marginTop: 0
  },
  infoSection: {
    ...getPlatformElevation(2),
    backgroundColor: '#fff',
    padding: 16,
    marginBottom: 30
  },
  infoIconStyle: {
    width: 20,
    height: 20,
    marginRight: 10
  },
  successFullInfoCard: {
    ...getPlatformElevation(1),
    backgroundColor: '#c1f1dd',
    padding: 16,
    marginTop: 1
  },
  headingTxt: {
    color: '#1a7971',
    fontSize: 16,
    fontFamily: fonts.bold
  },
  tickIcon: {
    width: 14,
    height: 14,
    marginRight: 10
  },
  infoTxt: {
    fontSize: 14,
    color: '#013251',
    fontFamily: fonts.regular,
    marginLeft: 25
  },
  crossIconWrapper: {
    position: 'absolute',
    right: 10,
    top: 10
  },
  crossIconStyle: {
    width: 24,
    height: 24
  }
});
