import { StyleSheet } from "react-native";

const getStyles = (fonts) => {
  return StyleSheet.create({
    container: {
      backgroundColor: "#e7e7e7",
      paddingBottom: 20,
    },
    paddingAll20: {
      padding: 20,
    },
    headingIcon: {
      width: 24,
      height: 24,
      marginRight: 10,
      backgroundColor: "#f1f1f1",
    },
    headerSec: {
      paddingHorizontal: 12,
      paddingTop: 16,
    },
    propertyTag: {
      width: 76,
      height: 24,
      position: "absolute",
      zIndex: 99,
    },
    textWrapper: {
      margin: 10,
    },
    scrollWrapper: {
      flexDirection: "row",
      marginBottom: 20,
      paddingLeft: 20,
    },
    cardWrapper: {
      borderRadius: 4,
      width: 200,
      borderWidth: 1,
      borderColor: "#e7e7e7",
      backgroundColor: "#fff",
      marginRight: 20,
    },
    iconStyle: {
      width: 44,
      height: 44,
    },
    stsTextWrapper: {
      paddingVertical: 4,
      paddingHorizontal: 8,
      backgroundColor: "#f2f2f2",
      marginRight: -16,
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
    },
    stsTextStyle: {
      color: "#1a7971",
    },
    marginBottom4: {
      marginBottom: 4,
    },
    guestWrapper: {
      paddingLeft: 25,
    },
    imageGalleryScroll: {
      flexDirection: "row",
      marginBottom: 20,
      width: "100%",
    },
    gallerySec: {
      flexDirection: "row",
    },
    hotelImg: {
      width: "100%",
      height: 125,
      marginRight: 10,
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
    },
    normalText: {
      fontWeight: "normal",
    },
    imgTag: {
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      color: "#ffffff",
      borderTopLeftRadius: 4,
    },
    label:{
      color: "white",
      fontFamily: fonts.black,
      position: "absolute",
      zIndex: 99,
      top: 2,
      left: 8,
    }
  });
}

export default getStyles;