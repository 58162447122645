import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    busIcon:{
        margin : 15,
        marginTop : 5,
        height: 20, 
        width: 19 ,
        marginBottom : 5  
    },
    rightArrow:{
        height:15,
        width:10
    }
})

export default styles;