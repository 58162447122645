import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { View, Text, Image } from 'react-native';
import PropTypes from 'prop-types';
import styles from './PrimaryContactCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getPrimaryContactNo } from '../../../../FlightBookingUtil';
import { imageTransformY } from '../../../../../utils/PostSaleUtil';

import { GUEST_ICON, EMAIL_ICON, CONTACT_NO_ICON } from '../../../../FlightImageConstants';
import { getFont } from '../../../../../PostSalesStyles';

class PrimaryContact extends React.Component {
  render() {
    const { fsStyle, ...fonts } = getFont(true);
    const userName = this.props.accountDetail.primaryContactName.unParsedName;
    const emailId = this.getPrimaryEmailId();
    const contactNo = getPrimaryContactNo(this.props.accountDetail.contactDetails);
    return (
      <View style={[AtomicCss.whiteBg, AtomicCss.marginBottom10, styles.elevation1]}>
        <View style={[styles.CardContentFull, AtomicCss.marginBottom24]}>
          <View style={[styles.CardContent]}>
            <Image style={[styles.CardIcon, imageTransformY]} source={GUEST_ICON} />
            <Text style={[fonts.blackFontFamily, AtomicCss.blackText, fsStyle.font14]}>
              {this.props.title}
            </Text>
          </View>
          <View style={[styles.marL10per, AtomicCss.paddingRight16]}>
            {!isEmpty(userName) && (
              <Text
                style={[
                  fsStyle.font14,
                  fonts.boldFontFamily,
                  AtomicCss.blackText,
                  AtomicCss.marginBottom10,
                  AtomicCss.alignLeft,
                ]}
              >
                {userName}
              </Text>
            )}
            {!isEmpty(emailId) && (
              <View style={[AtomicCss.flexRow, AtomicCss.marginBottom5, AtomicCss.alignCenter]}>
                <Image style={styles.messageIcon} source={EMAIL_ICON} />
                <Text
                  style={[
                    AtomicCss.flex1,
                    fsStyle.font12,
                    AtomicCss.defaultText,
                    fonts.regularFontFamily,
                    AtomicCss.alignLeft,
                  ]}
                >
                  {emailId}
                </Text>
              </View>
            )}
            {!isEmpty(contactNo) && (
              <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
                <Image style={[styles.callIcon, imageTransformY]} source={CONTACT_NO_ICON} />
                <Text
                  style={[
                    AtomicCss.flex1,
                    fsStyle.font12,
                    AtomicCss.defaultText,
                    fonts.regularFontFamily,
                    AtomicCss.alignLeft,
                  ]}
                >
                  {contactNo}
                </Text>
              </View>
            )}
          </View>
        </View>
      </View>
    );
  }

  getPrimaryEmailId() {
    const { contactDetails } = this.props.accountDetail;
    if (!isEmpty(contactDetails)) {
      for (let i = 0; i < contactDetails.length; i += 1) {
        if (contactDetails[i].contactType === 0) {
          return contactDetails[i].contactValue;
        }
      }
    }
    return undefined;
  }
}

PrimaryContact.propTypes = {
  accountDetail: PropTypes.object.isRequired,
};

export default PrimaryContact;
