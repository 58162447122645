import React, { useEffect, useState } from 'react';
import { DeviceEventEmitter } from 'react-native';
import { AncillaryModule } from 'react-native-core-ui-module';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import { getUserSelectedLanguage } from '@mmt/legacy-commons/AppState/AppUserConfig';
import FlightBookingModule from '@mmt/legacy-commons/Native/FlightBookingModule';
import NetworkModule from '@mmt/legacy-commons/Native/NetworkModule';
import { appFonts, getLang } from '../../../PostSalesStyles';
import Actions from '../../../navigation/postSalesNavigation';
import TrackingUtil from './utils/TrackingUtils';
import { getUserProfileType } from 'packages/legacy-commons/AppState/AppUserConfig';
import { PROFILE_TYPE, LANGUAGE_MAP } from '@mmt/legacy-commons/Common/constants/AppConstants';
import { useNavigation } from '@react-navigation/native';

import { AncillaryPageProps } from './types';
import { PAYMENT_EVENT_LISTNER } from './constants';
import { getTheme, useTheme } from 'apps/post-sales/src/theme/theme.context';
import { getStaticData } from '@mmt/post-sales/src/staticData/staticData';
import { nativeLog } from '../../../utils/PostSaleUtil';

const Ancillary = React.memo((props: AncillaryPageProps) => {
  const { bookingId, flightDetailResponse, pageKey, activeSegment, addonType, segmentGroupId, source = PAGE_REQUESTED.FLIGHT_DETAILS } = props;
  const navigation = useNavigation();
  const [headers, setHeaders] = useState(null);
  const { psTheme } = useTheme();
  const getHeaderDetail = async () => {
    const [userHeader, commonHeader] = await Promise.all([
      HotelBookingModule.getRequestHeader(bookingId, ''),
      NetworkModule.getHeaders(),
    ]);
    const { mmtAuth = '', loggingTrackingInfo = '' } = userHeader;
    setHeaders({
      'mmt-auth': mmtAuth,
      'logging-trackInfo': loggingTrackingInfo,
      v: 'v2',
      ...commonHeader,
    });
  };

  useEffect(() => {
    getHeaderDetail();
  }, []);

  const lang = getUserSelectedLanguage();

  const onBackHandle = () => {
    Actions.pop();
  };

  const goToBookingDetailPage = () => {
    switch (source) {
      case PAGE_REQUESTED.SELLING: {
        Actions.flightSellingPage({
          BOOKING_ID: bookingId,
          refresh: { isRefresh: true },
        });
        break;
      }
      default: {
        Actions.flightBookingDetail({
          BOOKING_ID: bookingId,
          refresh: { isRefresh: true },
        });
      }
    }
  };

  const openInWebView = (args) => {
    Actions.openPsWebView(args);
  };

  const makePayment = ({ holdResponse }, thankyouPageCallback) => {
    const count = DeviceEventEmitter.listenerCount(PAYMENT_EVENT_LISTNER);
    if (count > 0) {
      DeviceEventEmitter.removeAllListeners(PAYMENT_EVENT_LISTNER);
    }
    DeviceEventEmitter.addListener(PAYMENT_EVENT_LISTNER, (paymentRes) => {
      DeviceEventEmitter.removeAllListeners(PAYMENT_EVENT_LISTNER);
      thankyouPageCallback(paymentRes);
    });
    FlightBookingModule.openAncillaryPaymentPage(JSON.stringify(holdResponse));
  };

  useEffect(() => {
    return () => {
      const count = DeviceEventEmitter.listenerCount(PAYMENT_EVENT_LISTNER);
      if (count > 0) {
        DeviceEventEmitter.removeAllListeners(PAYMENT_EVENT_LISTNER);
      }
    };
  }, []);

  if (!headers) {
    return null;
  }

  let theme = {
    color: {},
    component: {},
  };
  let fontFactor = 1;
  let lineHeightFactor: Number | null = null;
  let langStaticData = {};

  if (getUserProfileType() === PROFILE_TYPE.BUSINESS) {
    theme = getTheme(PROFILE_TYPE.BUSINESS);
  } else {
    theme.component = {
      ...theme.component,
      tab: {
        bgGradiantColor: ['#008ADE', '#0052BA'],
        active: {
          bgColor: psTheme.color.lighterBlue,
          color: psTheme.color.primary,
          subTextColor: psTheme.color.red,
        },
        inActive: {
          bgColor: psTheme.color.white,
          color: psTheme.color.black,
          subTextColor: psTheme.color.red,
        },
      },
    };
  }
  if (getUserSelectedLanguage() === LANGUAGE_MAP.tam) {
    fontFactor = 0.9;
    lineHeightFactor = 1.25;
  }
  if (getUserSelectedLanguage() !== LANGUAGE_MAP.eng) {
    langStaticData = getStaticData();
  }

  const listeners = {
    addListener: (backHandlerEvent) => {
      navigation.addListener('beforeRemove', backHandlerEvent);
    },
    removeListener: () => {
      navigation.removeListener('beforeRemove');
    },
  };
  return (
    <AncillaryModule
      bookingId={bookingId}
      activeSegment={activeSegment}
      segmentGroupId={segmentGroupId}
      pageKey={pageKey}
      flightDetailResponse={flightDetailResponse}
      affiliate="MMT"
      requestHeader={headers}
      fonts={appFonts[getLang(true)]}
      theme={theme}
      onBackHandle={onBackHandle}
      paymentMethod={makePayment}
      lang={lang}
      trackingUtil={TrackingUtil}
      fontFactor={fontFactor}
      lineHeightFactor={lineHeightFactor}
      staticLangData={langStaticData}
      listeners={listeners}
      goToBooking={goToBookingDetailPage}
      openInWebView={openInWebView}
      addonType={addonType}
      nativeLogging={nativeLog}
    />
  );
});

export default Ancillary;
