import { StyleSheet } from 'react-native';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';

const refundCard = {
  refundCard: {
    ...getPlatformElevation(2),
    backgroundColor: colors.white,
    padding: 20,
    borderRadius: 8,
    overflow: 'hidden',
    marginBottom: 12,
  },
};

const refundCardHeader = {
  refundCardHeader: {
    ...getPlatformElevation(2),
    marginBottom: 2,
    backgroundColor: '#ffffff',
    margin: -20,
    padding: 20,
  },
  statusTag: {
    alignSelf: 'flex-start',
    height: 21,
    paddingHorizontal: 6,
    backgroundColor: '#D1F8E6',
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 16,
  },
  statusTagRed: {
    backgroundColor: '#FFD3D4',
  },
};

const departure = {
  departure: {
    paddingHorizontal: 16,
    paddingVertical: 18,
    borderBottomColor: '#E7E7E7',
    borderBottomWidth: 1,
  },
  airlineIconWrapper: {
    height: 24,
    width: 24,
    marginRight: 12,
    flexWrap: 'wrap',
    position: 'relative',
  },
  airlineIconSingle: {
    height: 24,
    width: 24,
  },
  airlineIconDouble: {
    height: 16,
    width: 16,
  },
  iconRight: {
    position: 'absolute',
    right: 0,
    bottom: 0,
  },
  airlineIconTriple: {
    height: 12,
    width: 12,
  },
  emptyIcon: {
    height: 12,
    width: 12,
    backgroundColor: '#F2F2F2',
  },
};

const refundBreakup = {
  refundBreakupDetails: {
    padding: 16,
  },
  userIcon: {
    height: 24,
    width: 24,
    marginRight: 12,
  },
  refundDesc: {
    borderBottomColor: '#F2F2F2',
    borderBottomWidth: 1,
    flexDirection: 'row',
    paddingBottom: 16,
  },
  zeroCancellation: {
    borderBottomColor: '#F2F2F2',
    borderBottomWidth: 1,
    flexDirection: 'row',
    paddingVertical: 16,
    alignItems: 'center',
  },
  zeroIcon: {
    height: 21,
    width: 16,
    marginRight: 18,
  },
  doubleBlack: {
    borderBottomColor: '#F2F2F2',
    borderBottomWidth: 1,
    flexDirection: 'row',
    paddingVertical: 16,
    alignItems: 'center',
  },
  doubleBlackIcon: {
    height: 24,
    width: 24,
    marginRight: 12,
  },
  imgIcon: {
    height: 16,
    width: 16,
    marginRight: 5,
  },
  refundBreakupSection: {
    paddingVertical: 20,
    borderBottomColor: '#F2F2F2',
    borderBottomWidth: 1,
  },
  zeroIconSm: {
    height: 17,
    width: 13,
    marginRight: 6,
  },
};

const detailsCard = {
  detailCard: {
    borderColor: '#rgba(0, 0, 0, 0.2)',
    borderWidth: 1,
    borderRadius: 8,
    marginTop: 20,
  },
  downArrowContainer: {
    backgroundColor: '#eaf5ff',
    height: 24,
    width: 24,
    borderRadius: 12,
    alignItems: 'center',
    justifyContent: 'center',
  },
  downArrow: {
    height: 9,
    width: 5,
    transform: [{ rotate: '90deg' }],
  },
  upArrow: {
    transform: [{ rotate: '-90deg' }],
  },
};

const loadingCard = {
  shimmerContainer: {
    ...getPlatformElevation(2),
    marginBottom: 2,
    backgroundColor: '#ffffff',
    height: 1,
  },
  shimmerBody: {
    backgroundColor: '#E7E7E7',
    height: 1,
    marginHorizontal: 16,
  },
};

const styles = StyleSheet.create({
  ...refundCard,
  ...refundCardHeader,
  ...departure,
  ...refundBreakup,
  ...detailsCard,
  ...loadingCard,
  body: {
    paddingHorizontal: 12,
    paddingVertical: 18,
  },
  paymentCard: {
    backgroundColor: '#f2f2f2',
    borderRadius: 4,
    padding: 16,
  },
  helpCardWrapper: {
    backgroundColor: '#ffffff',
    marginTop: 2,
    padding: 8,
    ...getPlatformElevation(2),
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
  },
  hlpInnerCard: {
    paddingHorizontal: 12,
    paddingVertical: 10,
    borderRadius: 4,
    backgroundColor: '#EAF5FF',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  helpIcon: {
    width: 28,
    height: 40,
    marginRight: 16,
  },
  rightArrow: {
    height: 12,
    width: 8,
  },
  errorCard: {
    alignItems: 'center',
    paddingVertical: 60,
  },
  errorIcon: {
    height: 80,
    width: 80,
    marginBottom: 30,
  },
  navHeader: {
    backgroundColor: colors.white,
    padding: 20,
    paddingTop: 10,
    ...getPlatformElevation(2),
    zIndex: 1
  },
  navContainer: {
    borderBottomWidth: 1,
    borderBottomColor: '#DDDDDD',
  },
  navList: {
    marginHorizontal: -20,
    marginTop: 16,
    flexDirection: 'row',
  },
  navBtn: {
    minHeight: 54,
    marginRight: 10,
    paddingHorizontal: 12,
    paddingVertical: 5, 
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: '#ECECEC',
    borderRadius: 4,
    width:200
  },
  navBtnActive: {
    backgroundColor: colors.azure,
    borderWidth: 0,
  },
  navBtnTitle: {
    color: colors.white,
  },
  navBtnDesc: {
    color: '#B3DCFF',
  },
  noBreakup: {
    paddingHorizontal: 16,
    paddingVertical: 20,
  },
});

export default styles;
