import React,{useState} from 'react';
import { View, Text,TextInput} from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getStyles from './bottomSheet.css'
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import ButtonWrapper from 'apps/post-sales/src/Common/ButtonWrapper';
import InputField from '@Frontend_Ui_Lib_App/InputField';


const CancelOtherReason = ({ mandateText , cta, setReason, handleClick,isCorporateTheme}) => {
    const { fsStyle, ...fonts } = getFont(true);
    const styles = getStyles(fonts);
    const [focusTxtArea,setFocusTxtArea]= useState(false)
    const [text,setText] = useState('');
    const [isError,setIsError]=useState(false)
    const [btnBg,setBtnBg] = useState('disableGrey');
    const [isDisabled,setIsDisabled] = useState(true);


    const handleFocus = () =>{
        setFocusTxtArea(true)
     }
     const handleChange = (e) => {
        if(e.length > 0){
         setBtnBg('gradientBtn');
         setIsDisabled(false)
         setIsError(false)
         setText(e);
        }else{
         setBtnBg('disableGrey');
         setIsDisabled(true) 
         setIsError(true)
        }
     }
     const errorTextArea = isError === true ? 'errorTextArea':'txtAreaFocus';
     const errorLableText = isError === true ? 'errorLableText':'';
    return (
        <React.Fragment>
            {mandateText && <Text style={[fonts.regularFontFamily, fsStyle.font14, AtomicCss.defaultText, AtomicCss.lineHeight20, AtomicCss.marginBottom5,styles[errorLableText]]}>{mandateText}</Text> }
            <View style={[styles.otherReasonWrapper,AtomicCss.marginBottom20]}>
                <InputField
                    customStyle={{
                        inputFieldStyle: [styles.textarea,styles[errorTextArea]]
                    }}
                    placeholder=""
                    onChangeText={handleChange}
                    onFocus={handleFocus}
                    inputProps={{
                        multiline: true
                      }}
                />
            </View>
            { cta && <View style={AtomicCss.marginBottom40}>

                <ButtonWrapper
                    disabled={isDisabled}
                    btnText={cta.actionLabelText}
                    clickHandler={ () => {
                        if( !isDisabled ){
                            setReason({reasonText: text});
                            handleClick(true)
                        }
                    } }
                    bgColors = {isCorporateTheme ? "myBiz" : null}
                />
            </View>}
        </React.Fragment>

    )
};


export default CancelOtherReason