import { StyleSheet } from 'react-native';

export default (theme, fonts) =>
  StyleSheet.create({
    listItemFlight: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingBottom: 5,
      borderTopColor: theme.color.lightGrey,
      borderTopWidth: 1,
      backgroundColor: theme.color.lightGrey,
    },
    listItem: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: 15,
      backgroundColor: theme.color.white,
    },
    listItemWrapper: {
      flexDirection: 'column',
      backgroundColor: theme.color.white,
    },
    transitVisaWrapper: {
      margin: 10, 
      justifyContent: 'center', 
      flexDirection: 'row', 
      backgroundColor: theme.color.creamWhite,
      alignItems: 'center'
    },
    transitVisaBullet: {
      padding: 5, 
      color: theme.color.red30, 
      fontSize: fonts.fontSize.font20
    },
    transitVisaText: {
      padding: 5,
      color: theme.color.black
    },
    leftSide: {
      flexDirection: 'row',
    },
    airlineIconWrapper: {
      marginRight: 15,
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
    },
    airlineIcon: {
      height: 26,
      width: 26,
    },
    airlineName: {
      fontSize: fonts.fontSize.font12,
      ...fonts.boldFontFamily,
      color: theme.color.black,
      marginLeft: 10,
    },
    stopsWrapper: {
      flex: 1,
      backgroundColor: theme.color.white,
      position: 'relative',
    },
    flightTimeRow: {
      justifyContent: 'space-between',
      flexDirection: 'row',
      width: '100%',
    },
    flightTime: {
      ...fonts.blackFontFamily,
      color: theme.color.black,
      fontSize: fonts.fontSize.font14,
    },
    flightDuration: {
      paddingHorizontal: 15,
      textAlign: 'center',
      ...fonts.regularFontFamily,
      color: theme.color.lightTextColor,
      fontSize: fonts.fontSize.font12,
    },
    stopView: {
      marginTop: 6,
      position: 'relative',
      marginBottom: 5,
      flexDirection: 'row',
    },
    stopsText: {
      textAlign: 'center',
      paddingTop: 3,
      color: theme.color.lightTextColor,
      fontSize: fonts.fontSize.font10,
    },
    priceWrapper: {
      marginLeft: 15,
    },
    price: {
      textAlign: 'right',
      color: theme.color.lightBlue17,
      fontSize: fonts.fontSize.font16,
      ...fonts.boldFontFamily,
    },
    priceTypeWrapper: {
      borderRadius: 20,
      backgroundColor: theme.color.hummingBird,
      marginTop: 5,
    },
    priceType: {
      textAlign: 'right',
      color: theme.color.green,
      fontSize: fonts.fontSize.font10,
      ...fonts.regularFontFamily,
      paddingHorizontal: 10,
      paddingVertical: 2,
    },
    solidDisc: {
      width: 7,
      height: 7,
      borderRadius: 10,
      backgroundColor: theme.color.lightGrey17,
      position: 'absolute',
    },
    solidDiscFirst: {
      left: 0,
    },
    solidDiscLast: {
      right: 0,
    },
    greenDisc: {
      width: 18,
      height: 18,
      borderRadius: 100,
      backgroundColor: theme.color.cngColor2,
      borderColor: theme.color.white,
      borderWidth: 6,
      position: 'absolute',
      top: -5,
      zIndex: 3,
    },
    left33Per: {
      left: '33%',
    },
    left66Per: {
      left: '66%',
    },
    left20Per: {
      left: '20%',
    },
    left45Per: {
      left: '45%',
    },
    left90Per: {
      left: '90%',
    },
    left95Per: {
      left: '95%',
    },
    stopSeperator: {
      width: '100%',
      height: 1,
      marginTop: 3,
      backgroundColor: theme.color.lightGrey17,
    },
    listingFltPriceDtls: {
      flexDirection: 'row',
      paddingHorizontal: 15,
      paddingVertical: 9,
      alignItems: 'center',
      backgroundColor: theme.color.grey22,
      justifyContent: 'space-between',
      borderBottomColor: theme.color.lightGrey,
      borderBottomWidth: 1,
    },
    timeDestWrapperIF: {
      width: 60,
    },
    timeDestIFTxtTop: {
      fontSize: fonts.fontSize.font16,
      color: theme.color.black,
      ...fonts.boldFontFamily,
      marginBottom: 5,
      textAlign: 'center',
    },
    timeDestIFTxtBottom: {
      fontSize: fonts.fontSize.font10,
      color: theme.color.textGrey,
      ...fonts.regularFontFamily,
      textAlign: 'center',
    },
    timeDestarrivalIF: {
      paddingLeft: 10,
    },
    totalDuration: {
      fontSize: fonts.fontSize.font14,
      ...fonts.regularFontFamily,
      color: theme.color.black,
    },
    totalDurationWrapper: {
      paddingTop: 5,
      marginLeft: 10,
    },
    lineRow: {
      width: '100%',
      height: 1,
      backgroundColor: theme.color.lightGrey17,
      marginTop: -9,
      position: 'relative',
      zIndex: 0.0,
    },
    operatedByAirlineName: {
      fontSize: fonts.fontSize.font9,
      ...fonts.latoRegularFontFamily,
      color: theme.color.grey30,
      marginLeft: 10,
    },
    outOfPolicyTag: {
      backgroundColor: theme.color.lightRed3,
      borderRadius: 10,
      paddingLeft: 5,
      paddingRight: 2,
      paddingVertical: 2,
      alignSelf: 'flex-end',
      position: 'absolute',
      right: 0,
      top: -24,
      width: 100,
    },
    outOfPolicyTagTxt: {
      color: theme.color.red24,
      fontSize: fonts.fontSize.font10,
      ...fonts.boldFontFamily,
    },
    infoRedIcon: { width: 16, height: 16, marginLeft: 4 },
  });
