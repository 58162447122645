import React, { useEffect } from 'react';
import styles from './PartPayDetailsStyle';
import { PartPaymentCardProps, RequestData } from './PartPayDetailsType';
import { isEmpty } from 'lodash';
import { View, Text, Image } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import Button from '../../../../../../Common/Button';
import { getFont } from '@mmt/post-sales/src/PostSalesStyles';
import { REFUND_PAYMENT_ICON } from '@mmt/post-sales/src/flights/FlightImageConstants';
import useApi from '@mmt/post-sales/src/Common/useApi';
import { PART_PAYMENT_STATUS } from '@mmt/post-sales/src/utils/NetworkUtils';

const PartPayDetails: React.FC<PartPaymentCardProps> = ({
  bookingId,
  response,
  lobName,
  tracking,
  nativeInfo,
  paymentHandler,
}) => {
  if (isEmpty(response)) return null;
  const { onClickTracking = () => {}, onLoadTracking = () => {} } = tracking || {};
  useEffect(() => {
    onLoadTracking();
  }, [response]);

  const { fsStyle, ...fonts } = getFont(true);
  const { header, subHeader, btnData, footerText, requestData, paymentBreakup } = response || {};

  const [partPayInProgress, partPayResponse, partPayApi] = useApi(PART_PAYMENT_STATUS);
  const makeApiCall = async (requestData: RequestData) => {
      if (partPayApi) {
        partPayApi.httpPost({
          body: requestData,
        });
      }
    }

  useEffect(() => {
    if (partPayResponse) {
      paymentHandler(partPayResponse);
    }
  }, [partPayResponse]);

  const onPayNowClick = async () => {
    onClickTracking("Complete_Payment_CTA");
    makeApiCall(requestData);
  };

  if (!header || !btnData || !paymentBreakup ) return null;

  return (
    <View style={[AtomicCss.marginBottom10, styles.cardBorder]}>
      <View style={[styles.infoCardWrapper]}>
        <View style={[AtomicCss.flexColumn, styles.infoCardWrapper]}>
          <View style={[AtomicCss.flexRow]}>
            <View style={styles.headingIconWrapper}>
              <Image style={styles.headingIconStyle} source={REFUND_PAYMENT_ICON} />
            </View>
            <View style={[styles.infoCardContent]}>
              <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween]}>
                <Text
                  style={[
                    AtomicCss.defaultText,
                    fonts.blackFontFamily,
                    fsStyle.font16,
                    AtomicCss.marginBottom12,
                  ]}
                >
                  {header}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View style={[AtomicCss.marginLeft20, AtomicCss.marginRight12]}>
          {!!paymentBreakup?.flPrice && (
            <View
              style={[
                AtomicCss.flexRow,
                AtomicCss.spaceBetween,
                styles.totalRefundprice,
                AtomicCss.marginTop10,
                AtomicCss.marginBottom10,
              ]}
            >
              {!!paymentBreakup?.flPrice?.text && (
                <Text style={[fonts.regularFontFamily, AtomicCss.defaultText, fsStyle.font14]}>
                  {paymentBreakup.flPrice.text.toUpperCase() + paymentBreakup.flPrice.paidOn}
                </Text>
              )}
              {!!paymentBreakup?.flPrice?.value && (
                <Text style={[fonts.regularFontFamily, AtomicCss.defaultText, fsStyle.font14]}>
                  {paymentBreakup.flPrice.value}
                </Text>
              )}
            </View>
          )}
        </View>
        {!!paymentBreakup?.flPeriod && (
          <View style={styles.priceInfoWrapper}>
            <View
              style={[
                AtomicCss.flexRow,
                AtomicCss.spaceBetween,
                AtomicCss.alignCenter,
                AtomicCss.marginBottom8,
              ]}
            >
              <Text style={[styles.priceInfoTxt, fonts.boldFontFamily, fsStyle.font12]}>
                {paymentBreakup.flPeriod.text.toUpperCase()}
              </Text>
              {!!paymentBreakup?.flPeriod?.value && (
                <Text style={[styles.priceInfoTxt, fsStyle.font14, fonts.boldFontFamily]}>
                  {paymentBreakup.flPeriod.value}
                </Text>
              )}
            </View>
            {!!footerText && (
              <Text style={[styles.priceInfoTxt, fonts.boldFontFamily, fsStyle.font12]}>
                {footerText}
              </Text>
            )}
          </View>
        )}
        {!isEmpty(btnData?.title) && (
          <View style={styles.btnWrapper}>
            <Button
              clickHandler={onPayNowClick}
              btnTxt={btnData?.title}
              btnBg="gradientBtn"
              btnWidth={230}
              buttonTextCss={fsStyle.font14}
            />
          </View>
        )}
      </View>
      {!isEmpty(subHeader) && (
        <View style={styles.bottomRowSection}>
          {!isEmpty(subHeader) && (
            <Text
              style={[
                AtomicCss.marginBottom8,
                AtomicCss.defaultText,
                fsStyle.font14,
                fonts.regularFontFamily,
              ]}
            >
              {subHeader}
            </Text>
          )}
        </View>
      )}
    </View>
  );
};

export default PartPayDetails;