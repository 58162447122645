import {Image, Text, View} from 'react-native';
import React from 'react';
import {CancelAndRebookConstants} from './CancelAndRebookConstants';
import {styles} from "./CancelAndRebookStyles";
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';

const renderSeparator = (modifiedTripDetails) => (
    <View style={styles.roundTripSeparator}>
        <View style={styles.separatorLine} />
        <View style={styles.separatorDaysGhost}>
            <View style={styles.tripDurationDetails}>
                  <Text style={styles.tripDistance}>{modifiedTripDetails.distanceText} </Text>
                   <Text style={styles.smallText1}>{modifiedTripDetails.durationText}{modifiedTripDetails.approxText}</Text>
            </View>
        </View>
    </View>
)

const RoundTripInfo = ({modifiedTripDetails, cabDetails}) => {
    return(
        <View style={styles.card}>
            <View style={styles.cabPrimaryDetails}>
                <View style={styles.content}>
                    <View style={AtomicCss.marginBottom15}>
                        <Text style={[AtomicCss.blackText,AtomicCss.font16,AtomicCss.boldFont]}>Updated Booking Details</Text>
                    </View>
                    <View style={styles.cabTopInfo}>
                        <View style={AtomicCss.flex1}>
                            <Text style={[AtomicCss.font14,AtomicCss.boldFont,AtomicCss.defaultText,AtomicCss.marginBottom5]}>{cabDetails.brand}</Text>
                            <Text style={[AtomicCss.font12,AtomicCss.boldFont,AtomicCss.defaultText]}>{cabDetails.category}</Text>
                        </View>
                        <Image source={{ uri : cabDetails.imageUrl}} style={styles.cabImage} />
                    </View>
                    <View style={styles.body}>
                        <View style={{ flex: 1 }}>
                            <Text style={[styles.pickUpTime , styles.greenColorForTxt]}>{CancelAndRebookConstants.PICKUP_BY}</Text>
                            <View style={styles.tripDateRow}>
                                <Text style={styles.pickUpTime}>{modifiedTripDetails.pickupTimeText }</Text>
                                <Text style={styles.dropTime}>{modifiedTripDetails.pickupDayText}</Text>
                            </View>
                            <View style={styles.locationDetails}>
                                <View style={styles.pickUpPointDetails}>
                                    <Text style={styles.locationName} numberOfLines={1}>{modifiedTripDetails.pickupCity}</Text>
                                    <Text style={styles.smallText}>{modifiedTripDetails.pickupAddress}</Text>
                                </View>
                                <View style={styles.dropPointDetails}>
                                    <Text style={styles.locationName} numberOfLines={1}>{ modifiedTripDetails.dropCity}</Text>
                                    <Text style={[styles.smallText, styles.textRight]}>{modifiedTripDetails.dropAddress}</Text>
                                </View>
                            </View>
                        </View>
                        {renderSeparator(modifiedTripDetails)}
                        <View style={{ flex: 1 }}>
                            <Text style={[styles.pickUpTime , styles.greenColorForTxt]}>{CancelAndRebookConstants.DROP_BY}</Text>
                            <View style={styles.tripDateRow}>
                                <Text style={styles.pickUpTime}>{modifiedTripDetails.dropTimeText}</Text>
                                <Text style={styles.dropTime}>{modifiedTripDetails.dropDayText}</Text>
                            </View>
                            <View style={styles.locationDetails}>
                                <View style={styles.pickUpPointDetails}>
                                    <Text style={styles.locationName} numberOfLines={1}>{modifiedTripDetails.dropCity}</Text>
                                    <Text style={styles.smallText}>{modifiedTripDetails.dropAddress}</Text>
                                </View>
                                <View style={styles.dropPointDetails}>
                                    <Text style={styles.locationName} numberOfLines={1}>{modifiedTripDetails.pickupCity}</Text>
                                    <Text style={[styles.smallText, styles.textRight]}>{modifiedTripDetails.pickupAddress}</Text>
                                </View>
                            </View>
                        </View>
                    </View>

                </View>
            </View>
        </View>
    )
}

export default RoundTripInfo;
