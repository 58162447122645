import React, { useState } from 'react';
import { View, Text } from 'react-native';
import { StyleSheet } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import ButtonWpr from 'apps/post-sales/src/cab/components/ButtonWpr';
import { CommentSectionData, FeedbackData, FeedbackTYdata, Fonts, Theme } from 'apps/post-sales/src/cab/types';
import { dislikeThumbIcon, likeThumbIcon } from 'apps/post-sales/src/cab/CabImageConstants';
import CabBookingTrackingHelper from 'apps/post-sales/src/cab/CabBookingTrackingHelper';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';

interface HelpFullDirectionCardProps {
	feedbackData: FeedbackData;
	commentSection: CommentSectionData;
	pageName: string;
}

export const HelpFullDirectionCard: React.FC<HelpFullDirectionCardProps> = ({ feedbackData, commentSection, pageName }) => {
	const { text, thankyouData } = feedbackData || {};
	const [feedbackTYdata, setFeedbackTYdata] = useState<FeedbackTYdata | null>(null);
	const fonts = getFont();
	const {psTheme: theme} = useTheme();
	const styles = getStyle(fonts, theme);
	const onFeedbackGiven = (type: string) => {
		setFeedbackTYdata(thankyouData?.response);
		CabBookingTrackingHelper.trackClickEvent(pageName, `Mob_Cabs_MIMA_PickupDirections_${type}_Click`);
	};

	return (
		<>
			{feedbackTYdata === null &&
				<View style={styles.directionHelpFul}>
					{!!text && <Text style={styles.text2Style}>{text}</Text>}
					<View style={[AtomicCss.flexRow]}>
						<ButtonWpr
							buttonText="No"
							buttonType="outline"
							buttonWidth="dynamic"
							btnStyle={styles.hlpfullBtnStyles}
							btnWrpStyle={styles.btnWprStyle}
							btnTxtStyle={styles.btnTxtStyle}
							startIcon={dislikeThumbIcon}
							onButtonPress={() => onFeedbackGiven('no')}
						/>
						<ButtonWpr
							buttonText="Yes"
							buttonType="outline"
							buttonWidth="dynamic"
							btnStyle={styles.hlpfullBtnStyles}
							btnWrpStyle={styles.btnWprStyle}
							btnTxtStyle={styles.btnTxtStyle}
							startIcon={likeThumbIcon}
							onButtonPress={() => onFeedbackGiven('yes')}
						/>
					</View>
				</View>
			}
			{!!feedbackTYdata &&
				<View style={styles.directionHelpFul}>
					<Text style={[AtomicCss.font16, AtomicCss.blackText, AtomicCss.regularFont, AtomicCss.marginBottom6]}>{feedbackTYdata?.text}</Text>
					<Text style={[AtomicCss.textCenter, AtomicCss.regularFont, AtomicCss.midGreyText]}>{feedbackTYdata?.desc}</Text>
				</View>
			}
		</>
	);
};

const getStyle = (fonts : Fonts, theme: Theme) => {
	return StyleSheet.create({
		directionHelpFul: {
			borderRadius: 16,
			backgroundColor: theme.color.lighterBlue,
			padding: 16,
			marginBottom: 20,
			justifyContent: 'center',
			alignItems: 'center',
		},
		btnWprStyle: {
			marginTop: 10,
			marginRight: 10,
		},
		hlpfullBtnStyles: {
			paddingVertical: 8,
		},
		btnTxtStyle: {
			fontSize: fonts.fontSize.font12,
			textTransform: 'capitalize',
			...fonts.boldFontFamily,
		},
		text2Style: {
			fontSize: fonts.fontSize.font16,
			...fonts.regularFontFamily,
			color: theme.color.black,
		}
	});
}
