import React from 'react';
import PropTypes from 'prop-types';
import { Text, TouchableOpacity, View } from 'react-native';
import createStyles from '../RejectionOverlay/RejectionOverlayDtlsCss';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { useTheme } from '../../../../../../../theme/theme.context';


const RadioList = (props) => {
  const { item, index } = props;
  const {psTheme: theme} = useTheme();
  const fonts = getFont(true);
  const styles = createStyles(theme,fonts);

  const activeState = index === props.activeState ? [styles.RadioInside] : '';

  return (
    <TouchableOpacity onPress={() => props.handleRadio(index)} activeOpacity={0.8}>
      <View style={styles.RadioWrapper}>
        <View style={styles.Radio}>
          <View style={activeState} />
        </View>
        <Text style={styles.RadioTxt}>{item}</Text>
      </View>
    </TouchableOpacity>
  );
};

RadioList.defaultProps = {
  activeState: '',
};
RadioList.propTypes = {
  item: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  activeState: PropTypes.number,
  handleRadio: PropTypes.func.isRequired,
};
export default RadioList;
