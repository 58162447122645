import React from 'react';
import {Image, Platform, ScrollView, Text, View} from 'react-native';
import {isEmpty} from 'lodash';
import Actions from '../../navigation/postSalesNavigation';
import PropTypes from 'prop-types';
import styles from './ErrorCss';
import SimpleHeader from '@mmt/legacy-commons/Common/Components/Header/SimpleHeader';
import LinkBtn from '../LinkBtn';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import BasePage from '../PostSalesBasePage';
import { openDialer } from '../commonUtil';
import {getImagePath, openErrorPage} from '../../utils/PostSaleUtil';
import { getStaticData } from "../../staticData/staticData"
import { getFont } from '../../PostSalesStyles';
import BaseButton, { BUTTON_VARIANT } from '../BaseButton';

const callIcon = getImagePath('ic_call_flight.webp');
const thankYouPageImage = getImagePath('date_change_successful_icon.webp');

const callIConStyle = {
  height: 36,
  width: 36
};

class CommonErrorPage extends BasePage {
  constructor(props) {
    super(props, 'commonError');
    this.navigation = props.navigationRef ? props.navigationRef : Actions;
  }

  render() {
    const {
      mobileHeaderData, heading, description, errorImage, errorImageStyle,
      ctaList, contactList, langAware, renderComponent = null, isCorporateBooking
    } = this.props;
    const {
      orText
    } = getStaticData(langAware);
    const { fsStyle } = getFont(langAware);
    return (
      <View style={styles.pageWrapper}>
        <SimpleHeader
          title={mobileHeaderData?.title}
          iconPress={this.onBackPress}
          textFont={getFont(langAware).medium}
        />
        <ScrollView showsVerticalScrollIndicator={false} style={styles.pageScroll}>
          <View style={[styles.whiteCard, { marginTop: -2 }]}>
            <View style={styles.cancelErrorWrapper}>
              <Image style={errorImageStyle} source={errorImage} />
              <Text style={styles.cancelTopHeading}>{heading}</Text>
              {!isEmpty(description) && <Text style={styles.cancelDescription}>{description}</Text>}
            </View>
            {renderComponent}
            {!isEmpty(ctaList) &&
              ctaList.map((btnData) => (
                <View style={AtomicCss.marginBottom12}>
                  <BaseButton
                    text={btnData.actionText}
                    clickHandler={() => this.onCtaClicked(btnData)}
                    variant={BUTTON_VARIANT.CAPSULE}
                    textStyle={[fsStyle.font14]}
                    shadow
                  />
                </View>
              ))}
          </View>
          {!isEmpty(contactList) && (
            <View>
              {!isEmpty(ctaList) && (
                <View style={styles.dividerWrapper}>
                  <View style={styles.dividerLine} />
                  <View style={styles.orDivider}>
                    <Text style={styles.orDividerText}>{orText}</Text>
                  </View>
                </View>
              )}
              <View style={styles.customerSupportWrapper}>
                {contactList.map((btnData) => (
                  <LinkBtn
                    icon={callIcon}
                    iconStyle={callIConStyle}
                    linkTxt={btnData.actionText}
                    onBtnClick={(actionType) => {
                      this.onContactBtnClick(actionType, btnData.contactNoList);
                    }}
                    action={btnData.actionId}
                  />
                ))}
              </View>
            </View>
          )}
        </ScrollView>
      </View>
    );
  }

  componentDidMount() {
    try {
      const { trackClickableEvent, ctaList } = this.props || {};
      if (trackClickableEvent && ctaList){
        ctaList.map((btnData) => {
          trackClickableEvent(btnData?.actionId, 'shown')
        })
      }
    } catch {
        console.log("Tracking Error")
    }
    super.componentDidMount();
  }

  onHardBackPress = () => {
    this.onBackPress();
    return true;
  }

  onBackPress = () => {
    this.navigation.pop();
    if (this.props.onBackPressHandler) {
      this.props.onBackPressHandler();
    }
  }

  onContactBtnClick = (actionId, contactNoList) => {
    if (!isEmpty(contactNoList)) {
      switch (actionId) {
        case 'MAKEMYTRIP': 
        case 'MYBIZ':
          openDialer(contactNoList[0].contactNo[0]);
          break;
        case 'AIRLINE':
          if (contactNoList.length === 1 && contactNoList[0].contactNo.length === 1) {
            openDialer(contactNoList[0].contactNo[0]);
          } else {
            const contacts = contactNoList.map((contact) => {
              const contactToShow = {};
              contactToShow.airLineName = contact.airlineName;
              contactToShow.airlineLogoUrl = contact.mobileAirlineIconUrl;
              contactToShow.airLineContactNo = contact.contactNo.join(',');
              return contactToShow;
            });
            this.navigation.airlineContactInfo({airlineNameContacts: contacts});
          }
          break;
        default:
          break;
      }
    }
  };

  onCtaClicked = (btnData) => {
    try {
      const { trackClickableEvent } = this.props || {};
      trackClickableEvent && trackClickableEvent(btnData?.actionId, 'click')
    } catch {
      console.log("Tracking Error")
    }
    switch (btnData.actionId) {
      case 'TRY_AGAIN':
        this.navigation.pop();
        if (this.props.tryAgainClick) {
          this.props.tryAgainClick();
        } else if (Platform.OS === 'web' && this.props.currentPageData && this.props.currentPageData.pageName) {
          const {pageName, currentPageData} = this.props.currentPageData;
          Actions[pageName](currentPageData);
        }
        break;
      case 'SUBMIT_REQUEST':
        this.submitRequest();
        break;
      case 'BACK_TO_BOOKING':
        this.navigation.popTo('flightBookingDetail');
        break;
      default:
        break;
    }
  };

  submitRequest = () => {
    const {bookingId, uniquePageId, errorCode, psLob, submitRequestExtraPayload} = this.props;
    this.navigation.commonSubmitRequest({
      bookingId,
      uniquePageId,
      errorCode,
      openThankYouPage: this.openThankYouPage,
      openFailurePage: this.openFailurePage,
      openFailurePageWithDefaultData: this.openFailurePageWithDefaultData,
      flowName: this.props.flowName,
      navigationRef: this.navigation,
      psLob,
      extraPayLoad: submitRequestExtraPayload,
    });
  }

  openThankYouPage = (response) => {
    const {
      heading, description, ctaList, bookingId
    } = response;
    this.navigation.commonSubmitRequestThankYou({
      icon: thankYouPageImage,
      iconStyle: {
        width: 80,
        height: 80,
        marginBottom: 8
      },
      title: heading,
      descriptionList: [description],
      btnTitle: ctaList[0].actionText,
      bookingId,
      lob:this.props.psLob
    });
  };

  openFailurePage = (response) => {
    const errorData = {
      ...response,
      type: 'replace'
    };
    errorData.mobileHeaderData = {};
    errorData.mobileHeaderData.title = response.mobileHeaderData.header;
    errorData.mobileHeaderData.description = response.mobileHeaderData.subText;
    openErrorPage(errorData, undefined, this.submitRequest, this.props.bookingId, this.props.flowName, this.navigation, { psLob: this.props.psLob });
  };

  openFailurePageWithDefaultData = () => {
    const errorData = {
      type: 'replace'
    };
    errorData.heading = 'Something went wrong';
    errorData.description = 'We are not able to raise your request because of some technical issue.';
    errorData.mobileHeaderData = {};
    errorData.mobileHeaderData.title = 'Error';
    const ctaList = ' [{\n' +
      '            "actionId": "TRY_AGAIN",\n' +
      '            "actionText": "TRY_AGAIN",\n' +
      '            "actionUrl": null,\n' +
      '            "type": 1\n' +
      '        }]';
    errorData.ctaList = JSON.parse(ctaList);
    openErrorPage(errorData, undefined, this.submitRequest, this.props.bookingId, this.props.flowName, this.navigation, { psLob: this.props.psLob });
  };
}

export default CommonErrorPage;

CommonErrorPage.propTypes = {
  mobileHeaderData: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subText: PropTypes.string
  }).isRequired,
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  errorImage: PropTypes.oneOfType([
    PropTypes.shape({
      uri: PropTypes.string,
      headers: PropTypes.objectOf(PropTypes.string)
    }),
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.shape({
      uri: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
      headers: PropTypes.objectOf(PropTypes.string)
    }))
  ]).isRequired,
  errorImageStyle: PropTypes.object.isRequired,
  ctaList: PropTypes.arrayOf(PropTypes.shape({
    actionText: PropTypes.string.isRequired,
    type: PropTypes.number.isRequired,
    actionId: PropTypes.string.isRequired,
    clickListener: PropTypes.func.isRequired
  })),
  contactList: PropTypes.arrayOf(PropTypes.shape({
    actionText: PropTypes.string.isRequired,
    btnIcon: PropTypes.oneOfType([
      PropTypes.shape({
        uri: PropTypes.string,
        headers: PropTypes.objectOf(PropTypes.string)
      }),
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.shape({
        uri: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
        headers: PropTypes.objectOf(PropTypes.string)
      }))
    ]).isRequired,
    btnStyle: PropTypes.object.isRequired,
    actionId: PropTypes.string.isRequired,
    contactNoList: PropTypes.arrayOf(PropTypes.shape({
      mobileAirlineIconUrl: PropTypes.string,
      airlineName: PropTypes.string,
      contactNo: PropTypes.arrayOf(PropTypes.string)
    }))
  })),
  errorCode: PropTypes.string.isRequired,
  uniquePageId: PropTypes.string,
  bookingId: PropTypes.string.isRequired,
  onBackPressHandler: PropTypes.func,
  tryAgainClick: PropTypes.func.isRequired,
  flowName: PropTypes.string.isRequired,
  popCurrentPage: PropTypes.bool
};

CommonErrorPage.defaultProps = {
  contactList: [],
  ctaList: [],
  uniquePageId: undefined,
  onBackPressHandler: undefined,
  popCurrentPage: true
};
