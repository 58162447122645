import React from 'react';
import {View, Text, Image} from 'react-native';
import PropTypes from 'prop-types';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getStyles from './ChargeSuccessfullCss';
import Button from '../../../Common/Button';
import {resetToHotelDetail} from '../utils/HotelBookingDetailUtil';
import HotelBookingTrackingHelper from '../../HotelBookingTrackingHelper';
import { getStaticData } from '../../../staticData/staticData';
import { SUCCESS_GOLD_BG_ICON } from '../../HotelImageConstants';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import PostSalesBasePage from 'apps/post-sales/src/Common/PostSalesBasePage';

export default class ChargeSuccessfullPage extends PostSalesBasePage {
  static navigationOptions = {
    header: null
  };
  constructor(props) {
    super(props, 'chargeSuccessfullPage');
    this.fonts = getFont(true);
    this.styles = getStyles(this.fonts);
    this.fsStyle = this.fonts.fsStyle;
  }
  componentDidMount() {
    HotelBookingTrackingHelper.trackClickEvent(this.props.pageName + ' : PayNow_Success', 'Cardchargesuccesstabshown', this.props.response);
    super.componentDidMount();
  }

  render() {
    const staticData= getStaticData();
    const imagePath = SUCCESS_GOLD_BG_ICON;
    return (
      <View style={[this.styles.pageWrapper]}>
          <View style={[this.styles.cardWrapper, this.styles.marginBottom60]}>
          <Image style={this.styles.imageIcon} source={imagePath} />
          <View style={[AtomicCss.marginBottom16, AtomicCss.alignCenter]}>
            <Text style={[this.fonts.boldFontFamily, AtomicCss.blackText, this.fsStyle.font20, AtomicCss.textCenter, {lineHeight: 26}]}>{this.props.msg}</Text>
          </View>

          <View style={[this.styles.cancellationInfoListItem, AtomicCss.flexRow]}>
            <Text style={[AtomicCss.flex1, this.fonts.regularFontFamily, this.fsStyle.font14, AtomicCss.defaultText, AtomicCss.lineHeight20, AtomicCss.textCenter]}>{this.props.subMsg}</Text>
          </View>

        </View>
        <View style={this.styles.buttonWrapper}>
          <Button btnWidth={260} btnBg="gradientBtn" btnTxt={staticData.seeUpdatedBookingText} clickHandler={() => this.goToBookingDetails()} />
        </View>

      </View>

    );
  }

  onHardBackPress = () => {
    this.goToBookingDetails();
    return true;
  };

  goToBookingDetails = () => {
    HotelBookingTrackingHelper.trackClickEvent(this.props.pageName + ' : PayNow_Success', 'CardChargeSuccessTabClicked', this.props.response);
    resetToHotelDetail(this.props.bookingID);
  };
}

ChargeSuccessfullPage.propTypes = {
  msg: PropTypes.string.isRequired,
  subMsg: PropTypes.string.isRequired,
  bookingID: PropTypes.string.isRequired,
  pageName: PropTypes.string.isRequired
};
