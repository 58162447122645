import React, {useEffect} from 'react';
import PropTypes from 'prop-types';

import DocumentsRequiredCard from './DocumentsRequiredCard';
import DocumentsPendingCard from './DocumentsPendingCard';
import DocumentsUploadedCard from './DocumentsUploadedCard';
import {UPLOAD_STATUS_CONSTANTS} from './Util';
import HolidayBookingTrackingHelper from '../../HolidayBookingTrackingHelper';

function DocumentsCard(props) {
  const {response} = props;

  useEffect(() => {
    if (response) {
      if (response.holidayDocumentsCard && Object.keys(response.holidayDocumentsCard).length) {
        HolidayBookingTrackingHelper.trackClickEvent(props.trackPageName, 'IsDocUploadCardShown_True');
        switch (response.holidayDocumentsCard.uploadStatus) {
          case UPLOAD_STATUS_CONSTANTS.REQUIRED:
            HolidayBookingTrackingHelper.trackClickEvent(props.trackPageName, 'NoneUploaded');
            HolidayBookingTrackingHelper.trackClickEvent(props.trackPageName, 'InTimeline');
            break;
          case UPLOAD_STATUS_CONSTANTS.PENDING:
            HolidayBookingTrackingHelper.trackClickEvent(props.trackPageName, 'PartialUploaded');
            HolidayBookingTrackingHelper.trackClickEvent(props.trackPageName,  'InTimeline');
            break;
          case UPLOAD_STATUS_CONSTANTS.UPLOADED:
            HolidayBookingTrackingHelper.trackClickEvent(props.trackPageName, 'AllUploaded');
            HolidayBookingTrackingHelper.trackClickEvent(props.trackPageName, 'InTimeline');
            break;
          case UPLOAD_STATUS_CONSTANTS.TIMELINE_MISSED:
            HolidayBookingTrackingHelper.trackClickEvent(props.trackPageName, 'OutOfTimeline');
            break;
          default:
            break;
        }
      } else {
        HolidayBookingTrackingHelper.trackClickEvent(props.trackPageName, `IsDocUploadCardShown_False`);
      }
    }
  }, []);

  if (!response) {
    return null;
  }
  if (!Object.keys(response).length) {
    return null;
  }

  if (!response.holidayDocumentsCard) {
    return null;
  }

  if (!Object.keys(response.holidayDocumentsCard).length) {
    return null;
  }

  switch (response.holidayDocumentsCard.uploadStatus) {
    case UPLOAD_STATUS_CONSTANTS.REQUIRED:
      return (
        <DocumentsRequiredCard
          response={props.response}
          handleButtonClick={props.handleButtonClick}
        />
      );
    case UPLOAD_STATUS_CONSTANTS.PENDING:
      return (
        <DocumentsPendingCard
          response={props.response}
          handleButtonClick={props.handleButtonClick}
        />
      );
    case UPLOAD_STATUS_CONSTANTS.UPLOADED:
      return (
        <DocumentsUploadedCard
          response={props.response}
          handleButtonClick={props.handleButtonClick}
        />
      );
    case UPLOAD_STATUS_CONSTANTS.TIMELINE_MISSED:
      if (props.submitToVendor) {
        return (
          <DocumentsUploadedCard
            response={props.response}
            handleButtonClick={props.handleButtonClick}
          />
        );
      }
      return (
        <DocumentsRequiredCard
          response={props.response}
          handleButtonClick={props.handleButtonClick}
        />
      );
    default:
      return null;
  }
}

DocumentsCard.propTypes = {
  response: PropTypes.shape({
    holidayDocumentsCard: PropTypes.shape({
      uploadStatus: PropTypes.string.isRequired,
      heading: PropTypes.string.isRequired,
      text: PropTypes.string,
      subText: PropTypes.string.isRequired,
      paxDocRelationNewState: PropTypes.shape([
        PropTypes.shape({
          heading: PropTypes.string.isRequired,
          paxName: PropTypes.string.isRequired,
          docs: PropTypes.string.isRequired,
          docsConditions: PropTypes.string
        })
      ]),
      paxDocRelationPendingState: PropTypes.shape([
        PropTypes.shape({
          header: PropTypes.bool.isRequired,
          name: PropTypes.string.isRequired,
          documentType: PropTypes.string.isRequired
        })
      ]),
      buttons: PropTypes.shape([
        PropTypes.shape({
          text: PropTypes.string.isRequired
        })
      ])
    }).isRequired
  }).isRequired,
  trackPageName: PropTypes.string.isRequired,
  handleButtonClick: PropTypes.func.isRequired
};

export default DocumentsCard;
