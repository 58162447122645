import React from "react";
import { StyleSheet, Text, TouchableOpacity, View, Linking, Platform } from "react-native";
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getStaticData } from "../../../../../../staticData/staticData";
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import { getFont, themeColors } from "../../../../../../PostSalesStyles";
import isEmpty from "lodash/isEmpty";
import FlightConst from '../../../FlightBookingDetailsConstant';
import { fetchUrlParam, openGoogleMap } from '../../../../../../Common/commonUtil';
import * as FlightBookingTrackingHelper from '@mmt/cabs/src/MyBizApproval/events';

const SrcDestDetail = ({ segmentInformation, flightDetailResponse }) => {
  const fonts = getFont(true);
  const {
    departureInfo,
    arrivalInfo,
    duration,
    flightInfo,
    flightTravelDateTime,
    departureTerminalObj,
    flightArrivalDateTime,
    arrivalTerminalObj,
    scheduleChangeInfo,
  } = segmentInformation || {};
  const { viewLocationText } = getStaticData();
  let postpondTxt = "";
  const fltInfoTagStyle = [styles.fltInfoTagStyle];
  if (scheduleChangeInfo !== null) {
    postpondTxt =
      scheduleChangeInfo.messageType === FlightConst.CANCEL ||
      scheduleChangeInfo.messageType === FlightConst.OTHERS
        ? ""
        : [styles.postpondTxt];

    if (
      scheduleChangeInfo.messageType === FlightConst.CANCEL ||
      scheduleChangeInfo.messageType === FlightConst.OTHERS
    ) {
      fltInfoTagStyle.push(styles.cancelledStyles);
    } else {
      fltInfoTagStyle.push(styles.postpondStyles);
    }
  }

  const viewLocation = (url) => {
    if (Platform.OS === "web") {
      Linking.openURL(url);
    } else {
      const params = fetchUrlParam(url);
      if (params && params.query) {
        const latLong = params.query.split(",");
        if (latLong && latLong.length > 1) {
          openGoogleMap(latLong[0], latLong[1]);
        }
      }
    }
    FlightBookingTrackingHelper.trackClickEvent(
      "Details",
      "Flight_Card_Location",
      flightDetailResponse
    );
    
  };

  return (
    <React.Fragment>
      <View style={[styles.fltInfoCardWrapper, AtomicCss.marginBottom15]}>
        <View style={styles.lftDtls}>
          <View
            style={[
              AtomicCss.flexRow,
              AtomicCss.marginBottom5,
              AtomicCss.alignCenter,
            ]}
          >
            {flightTravelDateTime ? (
              <Text
                style={[
                  AtomicCss.blackText,
                  AtomicCss.font16,
                  fonts.blackFontFamily,
                  postpondTxt,
                ]}
              >
                {flightTravelDateTime}
              </Text>
            ) : (
              <React.Fragment>
                <Text
                  style={[
                    AtomicCss.blackText,
                    AtomicCss.font18,
                    fonts.blackFontFamily,
                    postpondTxt,
                  ]}
                >
                  {departureInfo.timeObj.time}
                </Text>
                <Text
                  style={[
                    AtomicCss.font13,
                    fonts.regularFontFamily,
                    AtomicCss.defaultText,
                    postpondTxt,
                  ]}
                >
                  , {departureInfo.dateObj.date}
                </Text>
              </React.Fragment>
            )}
          </View>
          {departureInfo.scheduleChangeText && (
            <Text
              style={[
                AtomicCss.defaultText,
                fonts.regularFontFamily,
                AtomicCss.marginBottom3,
                AtomicCss.font12,
                AtomicCss.alignLeft,
              ]}
            >
              {departureInfo.scheduleChangeText}
            </Text>
          )}
          <Text
            style={[
              fonts.boldFontFamily,
              AtomicCss.defaultText,
              AtomicCss.font14,
              AtomicCss.marginBottom3,
              AtomicCss.alignLeft,
            ]}
          >
            {departureInfo.place}- {departureInfo.placeShortName}
          </Text>
          <Text
            style={[
              AtomicCss.greyText,
              fonts.regularFontFamily,
              AtomicCss.marginBottom3,
              AtomicCss.font12,
              AtomicCss.alignLeft,
            ]}
          >
            {departureInfo.airportInfo}
          </Text>
          {!isEmpty(departureTerminalObj?.latlong) && (
            <TouchableOpacity
              onPress={() => viewLocation(departureTerminalObj.latlong)}
            >
              <Text
                style={[
                  styles.linkColor,
                  AtomicCss.alignLeft,
                  fonts.boldFontFamily,
                ]}
              >
                {viewLocationText}
              </Text>
            </TouchableOpacity>
          )}
        </View>

        <View style={styles.duration}>
          <Text style={[styles.durationTxt, fonts.boldFontFamily]}>
            {duration}
          </Text>
        </View>

        <View style={styles.rtDtls}>
          <View
            style={[
              AtomicCss.flexRow,
              AtomicCss.marginBottom5,
              AtomicCss.alignCenter,
              AtomicCss.alignSelfFlexEnd,
            ]}
          >
            {flightArrivalDateTime ? (
              <Text
                style={[
                  AtomicCss.blackText,
                  AtomicCss.font16,
                  fonts.blackFontFamily,
                  postpondTxt,
                ]}
              >
                {flightArrivalDateTime}
              </Text>
            ) : (
              <React.Fragment>
                <Text
                  style={[
                    AtomicCss.blackText,
                    AtomicCss.font18,
                    fonts.blackFontFamily,
                    postpondTxt,
                  ]}
                >
                  {arrivalInfo.timeObj.time}
                </Text>
                <Text
                  style={[
                    AtomicCss.font13,
                    fonts.regularFontFamily,
                    AtomicCss.defaultText,
                    postpondTxt,
                  ]}
                >
                  , {arrivalInfo.dateObj.date}
                </Text>
              </React.Fragment>
            )}
          </View>
          {arrivalInfo.scheduleChangeText && (
            <Text
              style={[
                AtomicCss.defaultText,
                fonts.regularFontFamily,
                AtomicCss.marginBottom3,
                AtomicCss.font12,
                AtomicCss.alignSelfFlexEnd,
              ]}
            >
              {arrivalInfo.scheduleChangeText}
            </Text>
          )}
          <Text
            style={[
              fonts.boldFontFamily,
              AtomicCss.defaultText,
              AtomicCss.font14,
              AtomicCss.marginBottom3,
              AtomicCss.alignSelfFlexEnd,
              { textAlign: "right" },
            ]}
          >
            {arrivalInfo.place}- {arrivalInfo.placeShortName}
          </Text>
          <Text
            style={[
              AtomicCss.greyText,
              fonts.regularFontFamily,
              AtomicCss.marginBottom3,
              styles.textRight,
              AtomicCss.font12,
              AtomicCss.alignSelfFlexEnd,
            ]}
          >
            {arrivalInfo.airportInfo}
          </Text>
          {!isEmpty(arrivalTerminalObj) && !isEmpty(arrivalTerminalObj.latlong) && (
            <TouchableOpacity
              style={AtomicCss.alignSelfFlexEnd}
              onPress={() => viewLocation(arrivalTerminalObj.latlong)}
            >
              <Text style={[styles.linkColor, fonts.boldFontFamily]}>
                {viewLocationText}
              </Text>
            </TouchableOpacity>
          )}
        </View>
      </View>
    </React.Fragment>
  );
};

export default SrcDestDetail;

const styles = StyleSheet.create({
  fltInfoCardWrapper: {
    flex: 1,
    flexDirection: "row",
  },
  fltIcon: {
    width: 24,
    height: 24,
    borderRadius: 4,
    marginRight: 12,
  },
  marginBottom25: {
    marginBottom: 25,
  },
  linkColor: {
    fontSize: 12,
    color: themeColors.linkTextColor,
  },
  lftDtls: {
    alignSelf: "flex-start",
    flex: 1,
  },
  rtDtls: {
    alignItems: "flex-start",
    flex: 1,
  },
  textRight: {
    textAlign: "right",
  },
  duration: {
    borderBottomWidth: 1,
    borderBottomColor: "#747474",
    paddingBottom: 4,
    alignSelf: "flex-start",
  },
  durationTxt: {
    fontSize: 12,
    color: "#747474",
  },
  fltInfoTagStyle: {
    borderRadius: 4,
    paddingVertical: 5,
    paddingHorizontal: 10,
    marginRight: 10,
  },
  fltInfoTagWrapper: {
    flexDirection: "row",
    alignItems: "flex-start",
  },
  fltInfoTagTxt: {
    fontSize: 14,
    color: "#fff",
  },
  postpondStyles: {
    backgroundColor: colors.red,
  },
  cancelledStyles: {
    backgroundColor: colors.lightYello,
  },
  successStyles: {
    backgroundColor: colors.red,
  },
  refrashIconStyle: {
    width: 18,
    height: 16,
  },
  postpondTxt: {
    color: "#cf8100",
  },
  operatedByTxt: {
    color: "#cf8100",
    fontSize: 14,
  },
  knowMoreStyle: {
    color: colors.azure,
  },
});
