import { colors, fonts } from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
const styles = {
    OverlayScheduleWrapper:{
        paddingHorizontal: 16,
        paddingTop: 25,
        paddingBottom: 16,
        backgroundColor: '#fff',
        borderTopLeftRadius: 12,
        borderTopRightRadius: 12,
        marginTop: 2,
        left: 0, 
        right: 0, 
        bottom: 0,
    },
    headingTxt: {
        color: colors.black,
        fontSize: 14,
        fontFamily: fonts.black,
        lineHeight: 18,
    },
    elevationWrapper: {
        height: 1,
        backgroundColor: 'white',
        marginBottom: 3,
        ...getPlatformElevation(1), 
        position: 'absolute',
        width: '130%',
    },
    close:{
        width:20,
        height:20,
        top:10,
        position: 'absolute',
        right: 15
    }
};

export default styles;