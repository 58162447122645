import { getImagePath } from '../utils/PostSaleUtil';

export const FALSE_CLAIM_ICON = getImagePath('false_special_claim.webp');
export const BLUE_LIST_2_ICON = getImagePath('blueList2Icon.webp');
export const REFRESH_BLUE = require('@mmt/legacy-assets/src/refreshBlue.webp');
export const DOWNLOAD_WITH_LINE = require('@mmt/legacy-assets/src/downloadWithLine.webp');
export const CHEVRON_RIGHT_BLUE_ICON = require('@mmt/legacy-assets/src/iconArrowBlue.webp');
export const SPEAKER_ICON = getImagePath('speaker.webp');
export const MIC_ICON = getImagePath('mic.webp');
export const CALL_ICON = getImagePath('callIcon.webp');
export const ERROR_ICON = getImagePath('errorImg.webp');
export const USER_IMG_ICON = getImagePath('userImg.webp');
export const MIC_MUTE_ICON = getImagePath('micMute.webp');
export const SPEAKER_MUTE_ICON = getImagePath('speakerMute.webp');
export const CHEVRON_RIGHT_BLUE_ICON3 = require('@mmt/legacy-assets/src/ic_arrow.webp');
export const BLUE_PHONE_ICON = getImagePath('bluePhoneIcon.webp');
export const CALL_BLUE_BG_ICON = getImagePath('ic_callhotel.webp');
export const CHAT_BLUE_BG_ICON = getImagePath('ic_chatwithus.webp');
export const CHECKIN_BLUE_BG_ICON = getImagePath('ic_checkinathotel.webp');
export const HEADSET_BLUE_BG_ICON = getImagePath('ic_callmmtsupport.webp');
export const HELP_BLUE_BG_ICON = getImagePath('write_to_us.webp');
export const HOSTEL_ICON = getImagePath('hostel.webp');
export const ID_BLUE_BG_ICON = getImagePath('ic_valid_id_proof.webp');
export const LETTER_INSTRUCTIONS_BLUE_BG_ICON = getImagePath('ic_gotohelpsection.webp');
export const LIVE_TRACKING_ICON = getImagePath('LiveTracking.webp');
export const RESEND_BLUE_BG_ICON = getImagePath('ic_resendconfirmation.webp');
export const ROUNDED_CHAT_BLUE_BG_ICON = getImagePath('ic_chatwithhotel.webp');
export const RUPEE_SYMBOL_WHITE = getImagePath('rupeeSymbol.webp');
export const TICKET_SUCCESS_BLUE_BG_ICON = getImagePath('PNRcheck.webp');
export const TRAIN_COACH_ICON = getImagePath('TrainCoach.webp');
export const WHITE_DOWNLOAD_ICON = getImagePath('whiteDownload.webp');
export const WHITE_MAP_ICON = getImagePath('mapIconWhite.webp');
export const WISH_WAND_ICON = getImagePath('ic_makerequest.webp');
export const STAR_FILLED =  getImagePath('filledStar.webp');
export const BLUE_TICK =  getImagePath('blueTick.webp');
export const STAR_EMPTY =  getImagePath('emptyStar.webp');
