/* eslint-disable max-len */
import React from 'react';
import {Image, Text, TouchableOpacity, View} from 'react-native';
import PropTypes from 'prop-types';
import irstyles from '../Styles/RefundPaymentModeCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {
  OVERLAY
} from '../../../PostSalesConstant';
import { getStaticData } from '../../../staticData/staticData';
import PostSalesBasePage from '../../PostSalesBasePage';

const rupeeIcon = require('@mmt/legacy-assets/src/InstantRefund/ruppeIcon.webp');
const rightArrow = require('@mmt/legacy-assets/src/InstantRefund/rightArrow.webp');

const staticData = getStaticData();
const { additionalText: {instantRefundInAccountText, linkAccountTaggedWithYourPhoneText}} = staticData;

export default class InstantRefundBanner extends PostSalesBasePage {
  render() {
    const {
      title,
      heading,
      subHeading
    } = this.props
    return (
      <View>
        <View style={irstyles.dividerWrapper}>
          <View style={irstyles.dividerLine} />
          <View style={irstyles.orDivider}>
            <Text style={irstyles.orDividerText}>{title}</Text>
          </View>
        </View>
        <View style={[AtomicCss.whiteCardInstantRefund, irstyles.instantRefundWRapper, this.props.wrapperStyle]}>
          <TouchableOpacity style={[irstyles.whiteCard, AtomicCss.flexRow, AtomicCss.spaceBetween]} activeOpacity={0.8} onPress={() => this.props.handleOverlay(OVERLAY)}>
            <View style={AtomicCss.flex1}>
              <Text style={[irstyles.callUsTxt, AtomicCss.marginBottom10]}>{heading}</Text>
              <View style={AtomicCss.flexRow}>
                <Image style={irstyles.rupeeIconMdStyle} source={rupeeIcon} />
                <Text style={[irstyles.textInfoDtls, AtomicCss.regularFont, AtomicCss.defaultText]}>{subHeading}</Text>
              </View>
            </View>
            <Image style={[irstyles.rightArrow]} source={rightArrow} />
          </TouchableOpacity>
        </View>
      </View>
    );
  }
}

InstantRefundBanner.propTypes = {
  handleOverlay: PropTypes.func.isRequired,
  heading:PropTypes.string,
  subHeading:PropTypes.string,
  title:PropTypes.string,
  wrapperStyle: PropTypes.object
};

InstantRefundBanner.defaultProps = {
  heading: instantRefundInAccountText,
  subHeading: linkAccountTaggedWithYourPhoneText,
  title: staticData.orText
};
