import React from 'react';
import LobCards from '../cards/SummaryCard';
import { BookingIdToTicketDetailMapType, BookingData } from '../../types';

interface LobCardProps {
  item: BookingData; 
  bookingIdtoTicketDetailmap: BookingIdToTicketDetailMapType; 
  index: number;
}

const LobCard: React.FC<LobCardProps> = ({
  item,
  bookingIdtoTicketDetailmap,
  index,
}) => {
  return (
    <LobCards
      response={item}
      bookingIdtoTicketDetailmap={bookingIdtoTicketDetailmap}
      whereToGoImageData={item.whereToGoImage}
      isUpcomingOrOnTripFirstBooking={item.showWhere2GOImage}
    />
  );
};

export default LobCard;