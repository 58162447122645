import {colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles.js';

const styles = {
  title: {
    fontSize: 14,
    fontFamily: fonts.black,
    color: colors.darkBlue
  },
  locationAccuracyWrapper: {
    backgroundColor: colors.red3,
    padding: 9,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  locationAccuracyIcon: {
    height: 23,
    width: 37
  },
  locationAccuracyTitle: {
    fontSize: 14,
    fontFamily: fonts.bold,
    color: colors.red
  },
  locationAccuracyDesc: {
    fontSize: 12,
    fontFamily: fonts.regular,
    color: colors.defaultTextColor,
    marginTop: 5
  },
  bgImage: {
    width: '100%',
    flex: 1,
  }
}

export default styles;
