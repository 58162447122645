import React, {Fragment} from 'react';
import _, {isEmpty} from 'lodash';
import {Image, StyleSheet, Text, View} from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import Actions from '../../../navigation/postSalesNavigation';
import {isNotNullAndEmpty} from "../../../Metro/MetroBookingDetailUtil";
import getStyles from '../../styles/BusMamiCss';
import {
    cancellationStyles
} from '../../../utils/CancellationPolicyCardUtils';
import {
  getActionButton,
  getButtonStyle
} from '../../utils/BusBookingDetailUtil';
import BusBookingDetailsConstant from '../../BusBookingDetailsConstant';
import BannerCard from "../../common/BannerCard";
import {BACK_ARROW,ZC_IMAGE} from '../../BusImageConstants';

function TripAssuranceCard(props) {
    const styles = getStyles();
    function handleClick() {
      Actions.openPsWebView({
        url: props.response.busBookingInfo.cancellationAssurancePolicyLink,
        headerText: BusBookingDetailsConstant.TRIP_ASSURANCE_TnC,
        headerIcon: BACK_ARROW
      });
    }

    return (
        <Fragment>
          <View style={{marginBottom: 10}}>
      <View style={props.disableCard ? [cancellationStyles.CancellationPolicyCardWrapper, cancellationStyles.disabledCard] : [cancellationStyles.CancellationPolicyCardWrapper]}>
        <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween]}>
            <Image style={styles.CardIcon} source={ZC_IMAGE} />
            <View style={AtomicCss.flex1}>
              <Text style={[AtomicCss.blackFont, AtomicCss.blackText, AtomicCss.font14]}>
                {props.card.heading ? props.card.heading : BusBookingDetailsConstant.TRIP_ASSURANCE_LABEL}
              </Text>
            </View>
          </View>
          <View style={[AtomicCss.paddingBottom8, AtomicCss.paddingTop10,styles1.infoDtlsSection]}>
            {
              <Text style={[AtomicCss.defaultTextColor, AtomicCss.lineHeight18]}>
                {props.card.subheading}
              </Text>
            }
          </View>
          {!isEmpty(props.card.bannerMessage) && <BannerCard
              yellowBg={true}
              bannerText={props.card.bannerMessage}/>
          }
        </View>
              <View style={styles1.CardContent}></View>
        <View
            style={isNotNullAndEmpty(props.card.actionList) ? [getButtonStyle(props.card.actionList), styles.transperantBg] : styles.hideView}
          >
            {getActionButton(props.card, props.response, handleClick)}
        </View>
        </View>
        </Fragment>
    );
}
const styles1 = StyleSheet.create({
    infoDtlsSection:{
      paddingLeft: 30,
      marginBottom:1
    },
    infoDtlsSectionTracker:{
        paddingLeft:10,
        marginBottom:0
    },
    CardContent: {
        backgroundColor: '#fff',
        borderBottomWidth: 1,
        borderBottomColor: '#e4e4e4',
    }
});

export default TripAssuranceCard;
