export const DATE_CHANGE_RESULT_PAGE = 'DateChange:ResultsPage_Available';
export const DATE_CHANGE_RESULT_PAGE_ERROR = 'DateChange:ResultsPage_Available:Error';
export const DATE_CHANGE_SUCCESS = 'DateChange:ThankYou_Success';
export const NAME_CHANGE_SUCCESS = 'NameChange:ThankYou_Success';
export const NAME_CHANGE_SEE_UPDATED_BOOKING = 'NAME_CHANGE_SeeUpdatedBooking';

export const CHECK_IN_CHECK_OUT_SELECT_CLICK = 'ChangeDates_CalendarCheckInCheckOut';
export const CHECK_IN_CHECK_OUT_DONE_CLICK = 'ChangeDates_CalendarDone';
export const CHECK_AVAILABILITY_DATECHANGE_CLICK = 'ChangeDates_CheckAvailability';
export const CHANGE_DATE_CONTINUE_CONFIRM = 'ChangeDates_ContinueOrConfirm';
export const CHANGE_DATE_CONFIRM_OVERLAY_CONTINUE_CONFIRM = 'ChangeDatesConfirmOverlay_ConfirmNow_Click';
export const CHANGE_DATE_CANCEL_HOTEL_CLICK = 'ChangeDates_Cancel';
export const CHANGE_DATE_GO_BACK_HOTEL_CLICK = 'ChangeDates_GoBack';
export const CHANGE_DATE_SEE_UPDATED_BOOKING = 'ChangeDates_SeeUpdatedBooking';
export const CHANGE_DATE_GO_BACK_TO_BOOKING = 'ChangeDates_GoBackToBooking';
export const ADD_GUEST_GO_BACK_TO_BOOKING = 'AddGuest_GoBackToBooking';
export const CHANGE_DATE_CONTACT_US = 'ChangeDates_OverlayContactUs';
export const ADD_GUEST_CONTACT_US = 'AddGuest_OverlayContactUs';
export const CHANGE_DATE_RETRY = 'ChangeDates_OverlayRetry';
export const ADD_GUEST_RETRY = 'AddGuest_OverlayRetry';
export const ADD_GUEST_SUCCESS = 'AddGuest:ThankYou_Success';
export const ADD_GUESTS_SEE_UPDATED_BOOKING = 'AddGuest_SeeUpdatedBooking';
export const ADD_GUEST_CONTINUE_CONFIRM = 'AddGuest_ContinueOrConfirm';
export const ADD_GUEST_CONFIRM_OVERLAY_CONTINUE_CONFIRM = 'AddGuestConfirmOverlay_ConfirmNow_Click';
export const ADDGUEST_PLUS_MINUS_CLICK = 'AddGuest_AdditionalGuestsPlusMinus';
export const REMOVE_ROOM = 'AddGuest_RemoveRoom';
export const AGE_SELECT = 'AddGuest_AgeSelect';
export const CHECK_AVAILABILITY_ADDGUEST_CLICK = 'AddGuest_CheckAvailability';
export const ROOM_CARD_EDIT_PAX = 'AddGuest_EditRoomCard';
export const ROOM_CARD_EDIT_DC = 'ChangeDates_EditRoomCard';
export const DC_ROOM_IMAGE_CLICK = 'Date_mod_Room_image_click';
export const ADD_GUEST_CHECK_DIFF_PAX = 'AddGuest_ChkDiff';
export const CHANGE_DATE_CHECK_DIFF_PAX = 'ChangeDates_ChkDiff';
export const SOLD_OUT_AVAIL_OVERLAY_DC = 'ChangeDates_AvailSoldOutOverlay';
export const SOLD_OUT_AVAIL_OVERLAY_PAX = 'AddGuest_AvailSoldOutOverlay';
export const SOLD_OUT_OVERLAY_DC = 'ChangeDates_SoldOutOverlay';
export const SOLD_OUT_OVERLAY_PAX = 'AddGuest_SoldOutOverlay';
export const SMTHNG_WRONG_OVERLAY_DC = 'ChangeDates_SmthngWrongOverlay';
export const SMTHNG_WRONG_OVERLAY_PAX = 'AddGuest_SmthngWrongOverlay';
export const ADD_GUEST_CONFIRM_OVERLAY_SHOWN = 'AddGuest_ConfirmOverlayShown';
export const CHANGE_DATE_CONFIRM_OVERLAY_SHOWN = 'ChangeDates_ConfirmOverlayShown';
export const BACK_TO_BOOKING_CLICK =  'GoBackToBooking_Click';
export const EDIT_CLICK = 'Edit_Click';
export const CHK_DIFF_PAX = 'CheckDiffPax_Click';
export const CHK_DIFF_DC = 'CheckDiffDates_Click';
export const RETRY = 'Retry_Click';
export const CONNECT_WITH_US = 'ConnectWithUs_Click';
export const ADD_GUEST_REVIEW_PRICE_OVERLAY = 'AddGuest_ReviewPriceOverlay';
export const CHANGE_DATE_REVIEW_PRICE_OVERLAY = 'ChangeDates_ReviewPriceOverlay';
export const PROCEED_CLICK = 'Proceed_Click';
export const ROOM_CHANGED_TRACKING_SUFFIX = 'roomChanged';
export const CANCELLATION_POLICY_CHANGED_TRACKING_SUFFIX = 'cpChanged';

export const GUEST_TYPE = {
  ADULT: 'A',
  CHILD: 'C',
};

export const MOD_ERROR_TYPES = {
  NONE: 0,
  DEFAULT: 1,
  PAX_COUNT_UNCHANGED: 2,
  CHILD_AGE_MISSING: 3,
};

export const MOD_COUNTER_LIMITS = {
  INC: 0,
  DEC: 1,
};

export const MODIFICATION_ACTION = {
  BACK_TO_BOOKING: 'BackToBooking',
  TRY_AGAIN: 'TryAgain',
}