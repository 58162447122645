import React from 'react';
import { View, Image } from 'react-native';
import { getFont } from '../../../PostSalesStyles';
import { useTheme } from '../../../theme/theme.context';
import createStyles from './DealTagCss';
import { DealTagProps } from './hotelType';

const DealTag: React.FC<DealTagProps> = ({ imgUrl }) => {
  const { psTheme: theme } = useTheme();
  const fonts = getFont(true);
  const styles = createStyles(theme, fonts);
  if (!imgUrl) {
    return null;
  }
  return (
    <View style={styles.dealTagWrapper}>
        <Image style={styles.myIconStyle} source={{uri: imgUrl}} />
    </View>
  );
};

export default DealTag;
