import { StyleSheet } from "react-native";

export default (theme, fonts) =>
  StyleSheet.create({
    wrapper: {
        paddingLeft: 20,
    },
    voucher2xStyle: {
        width: 16,
        height: 18,
        marginRight: 10,
    },
    mapLocationStyle: {
        width: 16,
        height: 16,
        marginRight: 10
    },
    moreDetls: {
        paddingLeft: 25,
        paddingTop: 10
    },
    titleTxt: {
        flex: .3,
        fontSize: 10,
        marginRight: 10,
        color: theme.color.defaultTextColor
    }
})