import React from 'react';
import {View, TouchableOpacity, Keyboard, Platform} from 'react-native';
import styles from './OverlayMessageCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';

export default class CommonOverlayMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bottom: 0
    };
  }
  componentDidMount() {
    this.keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', this._keyboardDidShow.bind(this));
    this.keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', this._keyboardDidHide.bind(this));
  }

  componentWillUnmount() {
    this.keyboardDidShowListener && this.keyboardDidShowListener.remove();
    this.keyboardDidHideListener && this.keyboardDidHideListener.remove();
  }
  _keyboardDidShow(e) {
    if (this.props.noScrollPopupOnKeyboardOpen) {
      this.setState({bottom: 0});
    } else {
      this.setState({bottom: e.endCoordinates.height});
    }
  }

  _keyboardDidHide(e) {
    this.setState({bottom: 0});
  }


  render() {
    const { isBorderRadius, isTopRequired, topLine, customStyle } = this.props;
    const isRadius = isBorderRadius === true ? 'isBorderRadius':'';
    const topClass = isTopRequired === true && Platform.OS === 'ios' ? 'contentTop' : '';
    return (
      <View style={[this.props.overlayWrapperStyle || styles.overlayWrapper, customStyle?customStyle:{}]}>
        <TouchableOpacity onPress={() => this.props.handleBottomOverlay ? this.props.handleBottomOverlay(' ') : ()=>{}} activeOpacity={1} style={styles.overlay} />
        {!this.props.animatedOverlay && <View style={this.props.overlayContentStyle || [styles.overlayContent, styles[isRadius], styles[topClass], {bottom: this.state.bottom}]}>
          {!!topLine && (
            <View style={styles.topLineWrapper}>
              <View style={styles.overlayTopline}></View>
            </View>
          )}
          {this.props.content}
        </View>}
        {this.props.animatedOverlay && 
        <View style={[styles.animatedOverlayWrapper, styles[topClass], {bottom: this.state.bottom}]}>
          {this.props.content}
        </View>}
      </View>

    );
  }
}
