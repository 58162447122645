import React, { Component ,useState} from 'react';
import { isEmpty} from 'lodash';
import {Text, View, TouchableOpacity,Image } from 'react-native';
import styles from './CancelledDtlsCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import downArrow from '@mmt/legacy-assets/src/holidays/cancellation/right_arrow_blue.webp';
import { isNotNullAndEmpty } from '../../../hotel/details/utils/HotelBookingDetailUtil';
import { ZeroCancelIcon } from '../../HolidayBookingConstants';
import HolidayBookingTrackingHelper from '../../HolidayBookingTrackingHelper';

const FareBreak = (props) => {
  const [fareBreakupDtls, setFareBreakupDtls] = useState(false);
  const { farebreakupData, totalCostNode } = props;
  const activeDtls = fareBreakupDtls
    ? !isEmpty(totalCostNode.buttons) && isNotNullAndEmpty(totalCostNode.buttons[0].text) &&
      totalCostNode.buttons[0].text.replace('View', 'Hide')
    : totalCostNode.buttons[0].text;
  const activeArrow =
    fareBreakupDtls === false
      ? styles.downArrowStyle
      : [styles.downArrowStyle, styles.activeArrowStyle];
  const handleFareBreakup = () => {
    HolidayBookingTrackingHelper.trackClickEvent(
      props.pageName,
      fareBreakupDtls ? 'View_PackageCostBreakup_Collapse' : 'View_PackageCostBreakup_Expand',
    );
    setFareBreakupDtls((prevFareBreakupDtls) => !prevFareBreakupDtls);
  };
  const getLabel = (item) => {
    if (item.extraInfo === 'ZC') {
      return '(' + item.label + ')';
    } else {
      return item.label;
    }
  }
  return (
        <View style={styles.fareBreakUpWrapper}>
          <View style={[styles.fareBreakupHeading,AtomicCss.marginBottom3]}>
            <View style={AtomicCss.flexRow}>
              <Text style={styles.fbHeadingTxt}>{totalCostNode.amount.text}</Text>
            </View>
            <Text style={styles.totalPaidPriceTxt}>{totalCostNode.amount.price}{' '}</Text>
          </View>
          {totalCostNode.amount && !isEmpty(totalCostNode.amount.subText) && 
          <View style={{marginTop:4, marginBottom:4}}>
            <Text style={styles.fareBrkupTxt}>{totalCostNode.amount.subText}</Text>
          </View>}
          <TouchableOpacity style={[AtomicCss.flexRow,AtomicCss.alignCenter]} onPress={handleFareBreakup} activeOpacity={.5}>
            <Text style={styles.linkTxt}>{activeDtls}</Text>
            <Image style={activeArrow} source={downArrow}/>
          </TouchableOpacity>
          {fareBreakupDtls &&
          <View style={[styles.fareBreakupDtlsWrapper]}>
              {farebreakupData && farebreakupData.length>0 && farebreakupData.map((item)=>(<View style={styles.fareBrkupRow}>
                  <Text style={[styles.fareBrkupTxt, item.isBold ? AtomicCss.boldFont:'' ]}>{item.extraInfo === 'ZC'&& (<Image style={styles.zcIconSm} source={ZeroCancelIcon} />)} {getLabel(item)}</Text>
                  <Text style={[styles.fareBrkupTxtPrice, item.isBold ? AtomicCss.boldFont:''] }>{item.value}{' '}</Text>
              </View>))}
          </View>
        }
      
      </View>
    );
}
export default FareBreak;
