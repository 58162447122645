import React, { useState, useEffect, useCallback } from 'react';
import { View, Text, TouchableOpacity, Image } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import styles from './styles';
import { isEmptyArray, isNullOrEmptyObject } from '../commonUtil';
import { openWebView } from '../../utils/PostSaleUtil';
import { CSAT_FEEDBACK_GET_DETAILS } from '../../utils/NetworkUtils';
import useApi from '../useApi';
import { useFocusEffect } from '@react-navigation/native';
import { WHITE_CROSS_ICON } from '../../flights/FlightImageConstants';
import { getStaticData } from '../../staticData/staticData';
import { getFont } from '../../PostSalesStyles';
import CSATFeedbackHelper from './tracking'


const CsatFeedbackCard = ({ bookingDetailsResponse, psLob, pageName, bookingId}) => {

  const [active, setActive] = useState(null);
  const [surveyCardProcess, surveyCardData, surveyCardApi]= useApi(`${CSAT_FEEDBACK_GET_DETAILS}/${bookingDetailsResponse.bookingID}`)

useEffect(() => {
  if(surveyCardData && surveyCardData.error){
    CSATFeedbackHelper.trackErrorEvent(pageName, bookigId, surveyCardData.error);
  }
},[surveyCardData])

  useFocusEffect(
    useCallback(() => {
      CSATFeedbackHelper.trackLoadEvent(pageName, bookingId);
      setActive(null)
      surveyCardApi.clearResponse()
      const body = {
        userEmailId: bookingDetailsResponse.emailid,
        userMobileNo: bookingDetailsResponse.mobile,
        bookingId: bookingDetailsResponse.bookingID,
      };
      surveyCardApi.httpPost({ bookingId:bookingDetailsResponse.bookingID, uniqueId: 'CSAT_FEEDBACK_API', body });
    }, [bookingDetailsResponse.bookingID])
  );

  const handleBtn = (index, redirectURL) => {
    CSATFeedbackHelper.trackClickEvent(pageName, bookingId, index === 0 ? "Yes": "No")
    setActive(index);
    const { yourFeedbackIsValuable } = getStaticData(true, true);

    openWebView(
      yourFeedbackIsValuable,
        WHITE_CROSS_ICON,
        redirectURL,
        null,
        bookingDetailsResponse.bookingID,
        '',
        null,
        true,
      );

  };
  if(isNullOrEmptyObject(surveyCardData) ||  surveyCardData.feedbackAlreadySubmitted || surveyCardData.error || isNullOrEmptyObject(surveyCardData.description)) return null;
  const { header = null, description = {} } = surveyCardData;
  const { text = null, ctaList = []} = description;
  const fonts = getFont(true)

  return (
    <View style={[AtomicCss.whiteCard, AtomicCss.marginHorizontal12, styles.whiteCard]}>
      <View style={styles.topHeading}>
        {!!header && (<Text style={[ AtomicCss.font14, AtomicCss.blackText, fonts.boldFontFamily, AtomicCss.lineHeight18, ]}>
            {header}
          </Text>
          )}
      </View>
      <View style={styles.queryResWrapper}>
        {!!text && ( <Text style={[AtomicCss.font12,  AtomicCss.blackText,  fonts.boldFontFamily,  AtomicCss.marginBottom12, ]} >
            {text}
          </Text>
        )}
        <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween]}>
          {!isEmptyArray(ctaList) &&
            ctaList.map((item, index) => {
              
              const {selectedImageUrl=null, unselectedImageUrl=null}= item
              const activeSuffix = index === active ? 'Active' : '';
              const iconSource =  index === active ?selectedImageUrl : unselectedImageUrl;
              return (
                <TouchableOpacity
                  onPress={() => handleBtn(index, item.redirectUrl)}
                  style={[styles.queryBtn, styles[`queryBtn${activeSuffix}`]]}
                >
                  <Image source={{uri:iconSource}} style={styles.iconStyle} />
                  <View>
                    {!!item.ctaText && (
                      <Text style={[styles.resolveTxt, styles[`resolveTxt${activeSuffix}`], fonts.boldFontFamily]}>
                        {item.ctaText}
                      </Text>
                    )}
                    {!!item.ctaSubText && (
                      <Text style={[styles.statusTxt, styles[`statusTxt${activeSuffix}`], fonts.regularFontFamily]}>
                        {item.ctaSubText}
                      </Text>
                    )}
                  </View>
                </TouchableOpacity>
              );
            })}
        </View>
      </View>
    </View>
  );
};
export default CsatFeedbackCard;
