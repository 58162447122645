import React, { useState, useEffect } from 'react';
import { View, Text, Image } from 'react-native';
import styles from './ReqstWaitingOverlayCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import agentIcon from '@mmt/legacy-assets/src/agentIcon.webp';
import { IMMEDIATE_CALLBACK_URL } from '../../../../utils/NetworkUtils';
import Timer from '@mmt/legacy-commons/Common/Components/Timer';
import { renderProgressView, renderErrorView } from '../../NeedMoreHelpUtils';
import { ViewState } from '../../../../PostSalesConstant';
import { usePostRequestApi } from '../../../PostRequestApi';
import BaseButton, { BUTTON_VARIANT } from '../../../BaseButton';
import HorizontalLoader from "../../../HorizontalLoader";

const ReqstWaitingOverlay = (props) => {
  const trackPage = 'helpinghand_submit_callback';
  const [retry, setRetry] = useState(0);

  const request = {
    bookingId: props.bookingId,
    tenant: 0,
    primarytype: props.callDrivers && props.callDrivers.primarytype,
    secondarytype: props.callDrivers && props.callDrivers.secondarytype,
    ternarytype: props.callDrivers && props.callDrivers.ternarytype,
    configId: props.configId,
    pageName: !props.callDrivers ? props.pageName : '',
    lobName: props.lobName,
  };

  const [viewState, response] = usePostRequestApi(
    IMMEDIATE_CALLBACK_URL,
    request,
    props.bookingId,
    'IMMEDIATE_CALLBACK',
    retry,
  );

  const handleRetry = () => {
    setRetry(retry + 1);
  };

  useEffect(() => {
    if (response) {
      props.trackEvent && props.trackEvent('load', trackPage);
    }
  }, [response]);

  if (viewState === ViewState.LOADING) {
    return renderProgressView();
  }

  if (viewState === ViewState.ERROR) {
    return renderErrorView(handleRetry);
  }

  if (viewState === ViewState.LOADED && response && response.layoutData) {
    props.callScheduled();
    return (
      <View style={styles.OverlayContentWrapper}>
        <View style={AtomicCss.marginBottom16}>
          <View style={styles.overlayTopline} />
        </View>
        <View style={styles.agentImgWrapper}>
          <Image style={styles.agentIconStyle} source={agentIcon} />
        </View>
        <View style={AtomicCss.marginBottom20}>{<HorizontalLoader />}</View>
        <View style={styles.titleWrapper}>
          <Text style={styles.title}>{response.layoutData.headerText}</Text>
        </View>
        <View>
          {response.layoutData.subHeaderText1 && (
            <Text
              style={[
                AtomicCss.defaultText,
                AtomicCss.font14,
                AtomicCss.boldFont,
                AtomicCss.marginBottom20,
              ]}
            >
              {response.layoutData.subHeaderText1}
            </Text>
          )}
          {response.layoutData.subHeaderText2 && (
            <Text
              style={[
                AtomicCss.defaultText,
                AtomicCss.font14,
                AtomicCss.regularFont,
                AtomicCss.marginBottom26,
              ]}
            >
              {response.layoutData.subHeaderText2}
            </Text>
          )}
          {response.scheduledDateTime - new Date().getTime() > 0 && (
            <Timer
              flipToHHMMSS
              timeInMillis={response.scheduledDateTime - new Date().getTime()}
              timerTextStyle={[
                AtomicCss.defaultText,
                AtomicCss.font14,
                AtomicCss.regularFont,
                AtomicCss.marginBottom26,
              ]}
              onEnd={props.handleTime}
            />
          )}
        </View>
        <View style={AtomicCss.marginBottom20}>
          <BaseButton
            variant={BUTTON_VARIANT.PRIMARY}
            text={response.layoutData.ctaText}
            clickHandler={(action) => {
              props.trackEvent && props.trackEvent('click', trackPage, response.layoutData.ctaText);
              props.handleTime(action);
            }}
          />
        </View>
      </View>
    );
  }
  return null;
};

export default ReqstWaitingOverlay;
