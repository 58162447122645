import React from 'react';
import {
  View,
  Text,
  ScrollView,
  StyleSheet,
  Platform,
  FlatList,
  TouchableOpacity,
} from 'react-native';
import getPlatformElevation from '@mmt/legacy-commons/Common/Components/Card/getPlatformElevation';
import Actions from '../../../../../../../navigation/postSalesNavigation';
import SimpleHeader from '@mmt/legacy-commons/Common/Components/Header/SimpleHeader.android';
import SimpleHeaderIOS from '@mmt/legacy-commons/Common/Components/Header/SimpleHeader.ios';
import { getStaticData } from '../../../../../../../staticData/staticData';
import { getFont } from '../../../../../../../PostSalesStyles';
import { useTheme } from '../../../../../../../theme/theme.context';

const CountryCodeList = (props) => {
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme } = useTheme();
  const { color } = psTheme;
  const styles = getStyles(fonts, psTheme);
  const { nationalityText } = getStaticData();
  const SimpleHeaderContent = Platform.OS === 'ios' ? SimpleHeaderIOS : SimpleHeader;


  return (
    <View style={styles.container}>
      <View style={styles.selectOptionsOuter}>
        <SimpleHeaderContent
          title={nationalityText}
          iconPress={() => props.onClose()}
        />
        <ScrollView style={styles.overlaySection}>
          <FlatList
            data={props.countryList}
            renderItem={({ item }) => (
              <TouchableOpacity
                onPress={() => {
                  props.onCountrySelected(item.name, item.phoneCode, props.paxIndexGroup);
                  props.onClose();
                }}
                style={styles.selectOptions}
              >
                <Text
                  style={[
                    fonts.regularFontFamily,
                    fsStyle.font18,
                    { color: color.defaultTextColor },
                  ]}
                >
                  {item.name}
                </Text>
              </TouchableOpacity>
            )}
            keyExtractor={(item) => item.key}
          />
        </ScrollView>
      </View>
    </View>
  );
};

const getStyles = (fonts, theme) => {
  const { color } = theme;
  return StyleSheet.create({
    InputText: { width: 282, marginBottom: 16 },
    passengerInfo: {
      paddingTop: 16,
      paddingLeft: 16,
      paddingRight: 16,
      paddingBottom: 30,
      backgroundColor: color.white,
      marginBottom: 12,
    },
    container: {
      flex: 1,
      backgroundColor: color.grayBg,
    },
    btnWrapper: {
      paddingHorizontal: 9,
    },
    marginTop20: { marginTop: 20 },
    Icon: { width: 24, height: 24, marginRight: 8 },
    separator: { borderBottomWidth: 1, paddingBottom: 16, borderColor: color.grayBg },
    selectBox: {
      borderColor: color.disabledButton,
      borderWidth: 1,
      borderRadius: 4,
      paddingVertical: 8,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingLeft: 10,
      paddingRight: 25,
      height: 40,
    },
    selectBoxText: {
      ...fonts.boldFontFamily,
      fontSize: fonts.fontSize.font14,
      color: color.defaultTextColor,
    },
    selectOptionsOuter: {
      backgroundColor: color.white,
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 9999,
      ...getPlatformElevation(2),
    },
    selectOptions: {
      paddingVertical: 12,
    },

    addGuestLabel: {
      ...Platform.select({
        ios: {
          marginBottom: 6,
        },
        android: {
          marginBottom: 8,
        },
      }),
    },
    addGuestLabelText: {
      color: color.greyLight,
      fontSize: fonts.fontSize.font12,
      ...fonts.regularFontFamily,
    },
    countrySection: { width: 104, marginRight: 8 },
    width170: { width: 170 },
    overlaySection: { paddingHorizontal: 20, paddingVertical: 20 },
  });
};

export default CountryCodeList;
