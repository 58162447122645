import React from 'react';
import PropTypes from 'prop-types';
import {View, Text, Image, TouchableHighlight, TouchableOpacity, StyleSheet} from 'react-native';
import {statusBarHeightForIphone} from '@mmt/legacy-commons/Styles/globalStyles';
import CapsuleBtnFill from '../../../hotel/details/ActionButton/CapsuleBtnFill';
import {cancelIcon} from '../../BusImageConstants';
import { getFont } from '../../../PostSalesStyles';

class OverlayAlert extends React.Component {
    static navigationOptions = {
      header: null
    }

    render() {
      const fonts = getFont(true);

      return (
        <View style={styles.overlayContainer}>
          <TouchableHighlight style={styles.overlayBg} onPress={this.props.bgTapAction}>
            <Text>.</Text>
          </TouchableHighlight>
          <View style={styles.overlayContent}>
            <View style={styles.flexRow}>
              <Image source={cancelIcon} style={styles.CancelledIcon} />
              <View style={styles.mar15Bt}>
                <Text style={[styles.overlayHead, fonts.regularFontFamily, fonts.font23]}>{this.props.titleText}
                </Text>
              </View>
            </View>
            <View style={[styles.mar25Bt]}>
              <Text style={[styles.cancelTxt, fonts.regularFontFamily, fonts.font16]}>
                {this.props.subTitleText}
              </Text>
            </View>
            <View style={[styles.flexRow, styles.spaceBetween]}>
              <TouchableOpacity onPress={this.props.negativeBtnOnTap} style={styles.alignCenter}>
                <Text style={[styles.actionBlueText, fonts.boldFontFamily, fonts.font16]}>{this.props.negativeBtnText}</Text>
              </TouchableOpacity>
              <View style={[styles.alignCenter, styles.flexRow, styles.positiveButton]}>
                <CapsuleBtnFill
                  action={{actionLabeltext: this.props.positiveBtnText}}
                  clickHandler={this.props.positiveBtnOnTap}
                  style={styles.alignCenter}
                />
              </View>
            </View>
          </View>
        </View>

      );
    }
}

OverlayAlert.propTypes = {
  positiveBtnText: PropTypes.string.isRequired,
  negativeBtnText: PropTypes.string.isRequired,
  positiveBtnOnTap: PropTypes.func.isRequired,
  negativeBtnOnTap: PropTypes.func.isRequired,
  bgTapAction: PropTypes.func.isRequired,
  titleText: PropTypes.string.isRequired,
  subTitleText: PropTypes.string.isRequired
};


const styles = StyleSheet.create({
  overlayContainer:
        {
          justifyContent: 'flex-end',
          position: 'absolute',
          marginTop: -statusBarHeightForIphone,
          height: '100%',
          width: '100%',
          zIndex: 1000,
          elevation: 3
        },
  overlayBg: {
    backgroundColor: 'rgba(0,0,0,.5)',
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 1
  },
  overlayContent: {
    backgroundColor: '#fff',
    paddingVertical: 20,
    paddingHorizontal: 20,
    position: 'relative',
    zIndex: 100,
    marginBottom: -statusBarHeightForIphone,
    justifyContent: 'flex-end'
  },
  actionBlueText: {
    color: '#008cff',
    fontSize: 16,
  },
  cancelTxt: {
    fontSize: 16,
    color: '#4a4a4a',
    lineHeight: 22
  },
  overlayHead: {
    fontSize: 23,
    color: '#000'
  },
  CancelledIcon: {
    width: 60,
    height: 60,
    marginRight: 0,
    marginTop: -16
  },
  positiveButton: {
    width: 150,
    paddingTop: 10,
    marginRight: -10
  },
  flexRow: {flexDirection: 'row'},
  mar15Bt: {marginBottom: 15, marginRight: 15},
  alignCenter: {alignSelf: 'center'},
  spaceBetween: {justifyContent: 'space-between'},
  mar25Bt: {marginBottom: 25},
  flex1: {flex: 1},
  font12: {fontSize: 12}

});
export default OverlayAlert;
