import React, { useEffect, useState } from 'react';
import { Text, View, ScrollView } from 'react-native';
import Actions from '../../../navigation/postSalesNavigation';
import { isEmpty} from 'lodash';
import Header from '../../Components/Header';
import Button from '../../Components/Button';
import styles from './RefundPaymentModeCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import Breadcrumb from '../Breadcrumb';
import RadioList from './RadioList';
import OverlayMessage from '../../../Common/CommonOverlayMessage';
import ConfirmCancelledOverlay from './ConfirmCancelledOverlay';
import { openHolidayCancellationThankYou } from '../../HolidayBookingDetailUtils';
import { isNotNullAndEmpty } from '../../../hotel/details/utils/HotelBookingDetailUtil';
import { backPressHandler } from '../../../utils/PostSaleUtil';
import HolidayBookingTrackingHelper from '../../HolidayBookingTrackingHelper';

const RefundPaymentMode = (props) => {
  const {
    refundBreakup,
    steps,
    confirmCancellationOverlay,
    cancellationRefundMode,
    refundModeButton,
    cancelTopCard,
  } = props.cancellationResponse;
  useEffect(() => {
    HolidayBookingTrackingHelper.trackViewEvent(
      props.pageName,
      'View Refund Page',
    );
  },[]);
  const { refundModeList, message } = cancellationRefundMode;
  const [activeStep, setActiveStep] = useState(1);
  const [activeRadio, setActiveRadio] = useState(
    isNotNullAndEmpty(cancellationRefundMode.refundModeList) &&
      cancellationRefundMode.refundModeList[0].refundModeReason,
  );
  const [bottomOverlay, setBottomOverlay] = useState('');

    const handleRadio = (active, type) => {
        setActiveRadio(type);
    }

    const handleBottomOverlay = (showOverlay) => {
        setBottomOverlay(showOverlay);
    }

    const handleButtonClick= () => {
        setBottomOverlay('ConfirmCancel'); 
    }

    const goBack = () => {
        backPressHandler(Actions);
    };    
  const handleConfirmClick = () => {
    HolidayBookingTrackingHelper.trackClickEvent(
      props.pageName,
      'Confirm_Cancellation',
    );
    openHolidayCancellationThankYou(props.holidayResponse, activeRadio);
  };

  
    return (
        <View style={styles.pageWrapper}>
            <Header
                title={cancelTopCard.heading}
                subTitle={props.headerSubTitle}
                navigation={props.navigation}
                goBack={goBack}
            />
            <Breadcrumb activeStep={activeStep} data={steps} />
            <ScrollView>
                <View style={[AtomicCss.whiteCard, styles.whiteCardDtls]}>
                    <Text style={[AtomicCss.marginBottom10, AtomicCss.blackFont, AtomicCss.blackText, AtomicCss.font14]}>{refundModeButton.message}</Text>
                    {!isEmpty(message) && <View style={styles.payMoreInfo}>
                        <Text style={styles.payMoreInfoTxt}>{message}</Text>
                    </View>}

                    <View style={AtomicCss.marginBottom20}>
                        {isNotNullAndEmpty(refundModeList) && refundModeList.map((item, index) => {
                            return (
                                <RadioList
                                    item={item}
                                    index={index}
                                    handleRadio={handleRadio}
                                    activeRadio={activeRadio}
                                    refundAmount={isNotNullAndEmpty(refundBreakup.amount.price) && refundBreakup.amount.price}
                                    lastItem={index === refundModeList.length - 1}
                                />
                            )
                        })}
                    </View>

                </View>
            </ScrollView>
            <View style={styles.footerBtnWrapper}>
                <Button
                    pageName={isNotNullAndEmpty(refundModeButton.button) && refundModeButton.button.text}    
                    btnTxt={isNotNullAndEmpty(refundModeButton.button) && refundModeButton.button.text}
                    button={refundModeButton.button}
                    handleClick={handleButtonClick}
                />
            </View>
            {/* Bottom Sheet */}
            {bottomOverlay === "ConfirmCancel" &&
                <OverlayMessage
                    isBorderRadius={true}
                    handleBottomOverlay={handleBottomOverlay}
                    content={
                        <ConfirmCancelledOverlay
                            confirmCancellationData={confirmCancellationOverlay}
                            handleBackClick={handleBottomOverlay}
                            handleConfirmClick={handleConfirmClick}
                        />
                    }
                />
            }
        </View>
    );
}
export default RefundPaymentMode;
