import { StyleSheet } from 'react-native';
import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';

export default (theme, fonts) => {
  const styles = StyleSheet.create({
    cardWrapper: {
      marginBottom: 12,
      paddingVertical: 24,
      paddingHorizontal: 16,
    },
    rowSection: {
      flexDirection: 'row',
    },
    benefitsScrollWrapper: {
      flexDirection: 'row',
    },
    HeadingContainer: {
      flexDirection: 'row',
      width: '100%',
      marginBottom: 10,
      flex: 1,
      flexWrap: 'wrap',
    },
    headingTxt: {
      color: theme.color.black,
      justifyContent: 'space-between',
      ...fonts.blackFontFamily,
      fontSize: fonts.fontSize.font16,
      marginBottom: 10,
    },
    rydeImage: {
      width: 36,
      height: 22,
      resizeMode: 'contain',
    },
    rydeImage2: {
      top: 7,
      width: 36,
      height: 22,
      resizeMode: 'contain',
    },
    borderBtm: {
      borderBottomColor: theme.color.lightGrey,
      borderBottomWidth: 1,
    },
    whiteCard: {
      backgroundColor: theme.color.white,
      paddingVertical: 12,
      paddingHorizontal: 8,
      width: 228,
      borderRadius: 4,
      ...getPlatformElevation(2),
      marginRight: 10,
    },
    title: {
      color: theme.color.blackRussian,
      fontSize:fonts.fontSize.font14,
      ...fonts.boldFontFamily,
      marginBottom: 5,
    },
    desc: {
      color: theme.color.darkGrey2,
      fontSize: fonts.fontSize.font12,
      ...fonts.regularFontFamily,
    },
    imgWrapper: {
      width: 27,
      height: 27,
      marginRight: 10,
    },
    imgStyle: {
      width: '100%',
      height: 27,
      resizeMode: 'cover',
    },
    firstItemStyle: {
      marginLeft: 15,
    },
    subHeadingBold: {
      color: theme.color.red18,
      marginLeft: 5,
      fontSize: fonts.fontSize.font18,
    },
    rydeHeadingContainer: {
      flexDirection: 'row',
      flexWrap: 'nowrap',
      marginBottom: 10,
    },
    rydeHeading: {
      color: theme.color.black,
      ...fonts.blackFontFamily,
      fontSize: fonts.fontSize.font16,
      marginRight: 5,
    },
    heading2Txt: {
      color: theme.color.purple2,
      fontSize: fonts.fontSize.font18,
    },
  });
  return styles;
};
