import React from 'react';
import { View, Text } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import createStyles from '../changeDateOverlayCss';
import HTMLViewWrapper from '../../../../Common/HTMLViewWrapper';
import PayableTag from '../../../common/PayableTag';
import { getFont } from '../../../../PostSalesStyles';
import { useTheme } from '../../../../theme/theme.context';
function StatusTracker({ data }) {

  const { fsStyle, ...fonts } = getFont();
  const {psTheme : theme} = useTheme();
  const styles = createStyles(theme, fonts);

  return (
    <View style={styles.statusTracker}>
      {data.map((item, i) => {
        const { heading, subHeading, headingOfferCard } = item || {};
        const lastEle = i === data.length - 1 ? 'lastEleStyle' : '';
        return (
          <View style={[styles.statusTrackerRow, styles[lastEle]]} key={i}>
            <View style={styles.countWrapper}>
              <Text style={styles.countText}>{i + 1}</Text>
            </View>
            <View>
              {!!heading && (
                <Text
                  style={[
                    AtomicCss.blackText,
                    fonts.boldFontFamily,
                    fsStyle.font14,
                    AtomicCss.marginBottom5,
                  ]}
                >
                  {heading}
                </Text>
              )}

              <View style={headingOfferCard ? styles.extracharge : {}}>
                {!!subHeading && (
                  <HTMLViewWrapper
                    style={[AtomicCss.defaultText, fsStyle.font12]}
                    contentWidth={headingOfferCard ? '63%' : '100%'}
                    htmlContent={subHeading}
                  ></HTMLViewWrapper>
                )}
                {!!headingOfferCard && <PayableTag tag={headingOfferCard} />}
              </View>
            </View>
          </View>
        );
      })}
    </View>
  );
}
export default StatusTracker;
