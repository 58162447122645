import { Platform } from 'react-native';
import {trackOmniture} from '@mmt/legacy-commons/Common/utils/OmnitureTrackerUtils';
import {
  findDaysBetweenDates, getPreviousDateInMilliseconds, getTimeInMillisecond,
  isNotNullAndEmpty
} from './details/utils/HotelBookingDetailUtil';
import { UAE_COMPANY_CODE, US_COMPANY_CODE } from '../PostSalesConstant';
import {CURRENCY_DETAILS, OMNITURE_COMPANY_DETAILS} from '../PostSalesConstant';
import HotelEventsLogger from './HotelEventsLogger'
import isEmpty from 'lodash/isEmpty';
import { getUserSelectedLanguage } from '@mmt/legacy-commons/AppState/AppUserConfig';

const HTL_BOOKING_PAH = 'MI_BKGTYPE_PAH';
const HTL_BOOKING_PTAH = 'MI_BKGTYPE_PTAH';
const HTL_BOOKING_FULLY_PAID = 'MI_BKGTYPE_FULLYPAID';
const HTL_BOOKING_BNPL = 'MI_BKGTYPE_BNPL';
const HTL_BOOKING_OTHER = 'MI_BKGTYPE_OTHER';
const DEEP_LINK_CMP = "CMP";

class HotelBookingTrackingHelper {
  trackLoadEvent = (pageName, hotelBookingResponse, extraData) => {
    HotelEventsLogger.loadEvent(pageName, hotelBookingResponse);
    let params = {};
    params = this.getBasicOminitureVariables(pageName, hotelBookingResponse, extraData);
    pageName = this.appendAdditionalInfoInPageName(pageName, hotelBookingResponse);
    trackOmniture(pageName, params, true);
  };

  getBasicOminitureVariables(pageName, hotelBookingResponse, extraData) {
    const params = {};
    try {
      params.pageName = pageName;
      params.m_v15 = pageName;
      params.m_v9 = hotelBookingResponse.layoutDetail.bookingSubState;
      params.m_c19 = this.getHotelBookingType(hotelBookingResponse);
      params.m_v3 = hotelBookingResponse.hotelBookingInfo.hotelDetailsList[0].city;
      params.m_v6 = hotelBookingResponse.hotelBookingInfo.totalNumberOfNights;
      params.m_v14 = this.getCancellationState(hotelBookingResponse);
      params.m_v16 = hotelBookingResponse.bookingID;
      params.m_v4 = hotelBookingResponse.hotelBookingInfo.checkInDate;
      params.m_c13 = hotelBookingResponse.hotelBookingInfo.hotelDetailsList[0].hotelCategory;
      // params.m_v35 = hotelBookingResponse.hotelBookingInfo.primaryCustomerDetails.mobileNumber;
      this.addPropertyDetails(params, hotelBookingResponse);
      this.addGroupBookingVars(params, hotelBookingResponse);
      this.addBookingType(params, hotelBookingResponse);
      if (Platform.OS === 'web') {
        params.m_v37 = this.getGccBookingInfo(hotelBookingResponse);
      }
      params.m_v39 = 'Y';
      params.m_v21 = findDaysBetweenDates(
        new Date().getTime(),
        hotelBookingResponse.hotelBookingInfo.checkInDate
      );
      params.m_v7 = findDaysBetweenDates(new Date().getTime(), hotelBookingResponse.bookingDateTime);
      if(extraData ){
          if (extraData[DEEP_LINK_CMP]){
            params.m_v81 =  extraData[DEEP_LINK_CMP];
          }
          if (extraData.nrTrackingInfo) {
              params.m_c1 = extraData.nrTrackingInfo;
          }
          if (extraData.bankOfferInfo) {
            params.m_c54 = extraData.bankOfferInfo;
          }
      }
      params.m_c22 = hotelBookingResponse && hotelBookingResponse.ruleTrace ? hotelBookingResponse.ruleTrace : '';
      if(hotelBookingResponse && hotelBookingResponse?.pokusVariantKey){
        params.m_v87 = hotelBookingResponse.pokusVariantKey;
      }
      return params;
    } catch {
      return params;
    }
  }

  addGroupBookingVars(params, hotelBookingResponse) {
    if (hotelBookingResponse && hotelBookingResponse.isGroupBooking) {
      params.m_v108 = "Hotel|Groupbooking";
    } else {
      params.m_v108= "";
    }
  }
  trackHotelchangeDateLoadEvent(pageName, hotelBookingResponse, hotelSeacrchPriceResponse, isSuccess) {
    HotelEventsLogger.loadEvent(pageName,hotelBookingResponse);
    let params = {};
    params = this.getBasicOminitureVariables(pageName, hotelBookingResponse);
    if (isSuccess) { params.m_v14 = `${this.getOldToNewPayMode(hotelSeacrchPriceResponse)}_${this.getpostPonePrePone(hotelSeacrchPriceResponse)}_${this.getSameRoomDifferenRoom(hotelSeacrchPriceResponse)}${this.getSameorDifferentMealPlan(hotelSeacrchPriceResponse)}${this.getSamePlanDifferentPlan(hotelSeacrchPriceResponse)}_${this.getRefundorExtraPay(hotelSeacrchPriceResponse)}`; } else { params.m_v14 = `${this.getpostPonePrePone(hotelSeacrchPriceResponse)}_NoRoomAvailable`; }
    pageName = this.appendAdditionalInfoInPageName(pageName, hotelBookingResponse);
    trackOmniture(pageName, params, true);
  }

  trackHotelAddGuestLoadEvent(pageName, hotelBookingResponse, hotelSeacrchPriceResponse, isSuccess, nrStatusProp1) {
    HotelEventsLogger.loadEvent(pageName,hotelBookingResponse)
    let params = {};
    params = this.getBasicOminitureVariables(pageName, hotelBookingResponse);
    if (isSuccess) { params.m_v14 = `${this.getOldToNewPayMode(hotelSeacrchPriceResponse)}_${this.getAdultChild(hotelSeacrchPriceResponse)}_${this.getSameRoomDifferenRoom(hotelSeacrchPriceResponse)}${this.getSameorDifferentMealPlan(hotelSeacrchPriceResponse)}${this.getSamePlanDifferentPlan(hotelSeacrchPriceResponse)}_${this.getRefundorExtraPay(hotelSeacrchPriceResponse)}`; } else { params.m_v14 = `${this.getAdultChild(hotelSeacrchPriceResponse)}_NoRoomAvailable`; }
    if (nrStatusProp1) {
      params.m_c1 = nrStatusProp1;
    }
    pageName = this.appendAdditionalInfoInPageName(pageName, hotelBookingResponse);
    trackOmniture(pageName, params, true);
  }


  trackClickEvent = (pageName, clickEvent, hotelBookingResponse) => {
    HotelEventsLogger.clickEvent(pageName, clickEvent, hotelBookingResponse);
    const params = {};
    pageName = this.appendAdditionalInfoInPageName(pageName, hotelBookingResponse);
    params.pageName = pageName;
    params.m_v15 = pageName;
    params.m_c54 = clickEvent;
    params.m_c22 = hotelBookingResponse && hotelBookingResponse.ruleTrace ? hotelBookingResponse.ruleTrace : '';
    this.addPropertyDetails(params, hotelBookingResponse);
    this.addGroupBookingVars(params, hotelBookingResponse);
    this.addBookingType(params, hotelBookingResponse);
    trackOmniture(pageName, params);
  };

  trackShowEvent = (pageName, clickEvent, hotelBookingResponse) => {
    HotelEventsLogger.clickEvent(pageName, clickEvent, hotelBookingResponse);
    const params = {};
    pageName = this.appendAdditionalInfoInPageName(pageName, hotelBookingResponse);
    params.pageName = pageName;
    params.m_v15 = pageName;
    params.m_c1 = clickEvent;
    params.m_c22 = hotelBookingResponse && hotelBookingResponse.ruleTrace ? hotelBookingResponse.ruleTrace : '';
    this.addPropertyDetails(params, hotelBookingResponse);
    this.addGroupBookingVars(params, hotelBookingResponse);
    this.addBookingType(params, hotelBookingResponse);
    trackOmniture(pageName, params);
  };

  trackErrorWithData = (pageName, bookingId, data, hotelBookingResponse) => {
    HotelEventsLogger.errorEvent(pageName, { bookingId, error: data });
    const params = {};
    pageName = this.appendAdditionalInfoInPageName(pageName, hotelBookingResponse);
    params.pageName = pageName;
    params.m_v15 = pageName;
    params.m_c22 = `${bookingId}_${data}`;
    this.addPropertyDetails(params, hotelBookingResponse);
    this.addBookingType(params, hotelBookingResponse);
    trackOmniture(pageName, params);
  };

  trackErrorEvent = (pageName, hotelBookingResponse) => {
    HotelEventsLogger.errorEvent(pageName,hotelBookingResponse);
    const params = {};
    pageName = this.appendAdditionalInfoInPageName(pageName, hotelBookingResponse);
    params.pageName = pageName;
    params.m_v15 = pageName;
    this.addPropertyDetails(params, hotelBookingResponse);
    this.addBookingType(params, hotelBookingResponse);
    trackOmniture(pageName, params);
  };

  getHotelBookingType(hotelItineraryResponse) {
    let roomCount;
    if (isNotNullAndEmpty(hotelItineraryResponse.hotelBookingInfo.roomDetails)) {
      if (hotelItineraryResponse.hotelBookingInfo.roomDetails.length == 1) {
        roomCount = '_SingleRoom';
      } else {
        roomCount = '_MultipleRooms';
      }
    }
    if (hotelItineraryResponse && hotelItineraryResponse.paymentDetails
      && hotelItineraryResponse.hotelBookingInfo) {
      if (hotelItineraryResponse.hotelBookingInfo.ispah) {
        return HTL_BOOKING_PAH + roomCount;
      } else if (hotelItineraryResponse.isBNPLBooking) {
        return HTL_BOOKING_BNPL + roomCount;
      } else if (Math.round(hotelItineraryResponse.paymentSummary.taxToBePaidAtHotel, 0) > 0) {
        return HTL_BOOKING_PTAH + roomCount;
      } else if (hotelItineraryResponse.paymentSummary.paymentStatus === 'Full Payment Received') {
        return HTL_BOOKING_FULLY_PAID + roomCount;
      }
      return HTL_BOOKING_OTHER + roomCount;
    }
    return roomCount;
  }

  getCancellationState(hotelBookingResponse) {
    const currentTime = new Date().getTime();
    const freeCancellationEligibiltyDate = hotelBookingResponse.freeCancellationEligibityDate;
    if (hotelBookingResponse.isMMTPrivilegeEligible) {
      if (currentTime
        < getPreviousDateInMilliseconds(hotelBookingResponse.hotelBookingInfo.checkInDate)) {
        return 'FREECANCELLATION_DB_EXISTS';
      }
      return 'FREECANCELLATION_DB_EXPIRED';
    }

    if (this.checkForFreeCancellation(freeCancellationEligibiltyDate, hotelBookingResponse)) {
      if (getTimeInMillisecond(freeCancellationEligibiltyDate) < currentTime) {
        return 'FREECANCELLATION_NORMAL_EXPIRED';
      }
      return 'FREECANCELLATION_NORMAL_EXISTS';
    }
    return 'NONREFUNDABLE';
  }

  checkForFreeCancellation(freeCancellationEligibiltyDate, hotelbookingDetailresponse) {
    return (freeCancellationEligibiltyDate != null
      && freeCancellationEligibiltyDate !== '1753-01-01T00:00:00'
      && getTimeInMillisecond(freeCancellationEligibiltyDate) >
      getTimeInMillisecond(hotelbookingDetailresponse.bookingDateTime));
  }

  getpostPonePrePone(searchHotelPriceResponse) {
    const date_OldCheckOut = new Date(searchHotelPriceResponse.oldCheckOutDate);
    const date_newCheckOut = new Date(searchHotelPriceResponse.newCheckOutDate);
    if (date_OldCheckOut > date_newCheckOut) {
      return 'Prepone';
    }
    return 'Postpone';
  }
  getAdultChild(searchResponse) {
    const adultCount = searchResponse.newAdultCount - searchResponse.adultCount;
    const childCount = searchResponse.newChildCount - searchResponse.childCount;
    return `${adultCount}Adult_${childCount}Child`;
  }
  getOldToNewPayMode(searchHotelPriceResponse) {
    let newPayMode = searchHotelPriceResponse.availableRoomDetails[0].paymentMode;
    const oldPayMode = searchHotelPriceResponse.availableRoomDetails[0].originalPaymentMode;
    if (newPayMode === 'PAH_WITH_CC' || newPayMode === 'PAH_WITHOUT_CC') { newPayMode = 'PAH'; }
    return `${newPayMode}TO${oldPayMode}`;
  }

  getSameRoomDifferenRoom(searchHotelPriceResponse) {
    if (searchHotelPriceResponse.sameRoomAvailable) { return 'SameRoom'; }
    return 'DifferentRoom';
  }

  getSameorDifferentMealPlan(searchHotelPriceResponse) {
    if (searchHotelPriceResponse.availableRoomDetails[0].sameMealPlanAvailable) { return 'SameMeal'; }
    return 'AnyPlan';
  }

  getSamePlanDifferentPlan(searchHotelPriceResponse) {
    if (searchHotelPriceResponse.availableRoomDetails[0].sameCancellationPolicy) { return 'SamePlan'; }
    return 'AnyPlan';
  }

  getRefundorExtraPay(searchHotelPriceResponse) {
    if (searchHotelPriceResponse.availableRoomDetails[0].applicableRefund > 0) { return 'Refund'; }
    return 'PayExtra';
  }

  getGccBookingInfo(response) {
    let sbuValue = '';
    let countryValue = '';
    let currencyValue = '';
    const currency = response.currency;
    const companyOmitureDetail = OMNITURE_COMPANY_DETAILS[response.companyName];
    sbuValue += (companyOmitureDetail && companyOmitureDetail.countryValue) || 'india';
    countryValue = (companyOmitureDetail && companyOmitureDetail.countryValue) || 'in';
    currencyValue = CURRENCY_DETAILS[currency] || currency;

    return `domain_sbu:${sbuValue}|domain_country:${countryValue}|domain_currency${currencyValue}|domain_language:${getUserSelectedLanguage()}`;
  }

  appendAdditionalInfoInPageName(pageName, hotelBookingResponse) {
    if (hotelBookingResponse && hotelBookingResponse.companyName && hotelBookingResponse.isGccBooking) {
      pageName = `${pageName} | ${hotelBookingResponse.companyName}`;
    }
    if (hotelBookingResponse && hotelBookingResponse.myPartnerBooking) {
      pageName = `${pageName} |mypartner`;
    }
    return pageName;
  }

  addPropertyDetails(params, hotelBookingResponse ) {
    const { hotelBookingInfo } = hotelBookingResponse || {};
    const { hotelDetailsList } = hotelBookingInfo || {};
    const [hotelDetail] = !isEmpty(hotelDetailsList) ? hotelDetailsList : [];
    const { propertyType, code, propertySubType } = hotelDetail || {};
    const propertyValue = !isEmpty(propertySubType) ? propertySubType : propertyType;
    params.m_c57 = propertyValue;
    params.products = `;${code}`;
  }

  addBookingType(params, hotelBookingResponse) {
    const { isDayUseBooking, isDayUseBookingNew, isBNPLBooking, isBNPLBookingWithoutAutoCharge, isGroupBooking, hotelBookingInfo, paymentDetails } = hotelBookingResponse || {};
    const { ispah } = hotelBookingInfo || {};
    const { amountDue } = paymentDetails || {};

    let bookingType = 'NA';
    if (isDayUseBooking || isDayUseBookingNew) {
        bookingType = 'DayUse';
    } else if (isBNPLBooking || isBNPLBookingWithoutAutoCharge) {
        bookingType = 'BR1';
    } else if (isGroupBooking) {
        bookingType = 'Group';
    } else if (ispah) {
        bookingType = 'PAH';
    } else if(Math.round(amountDue) <= 0) {
        bookingType = 'PAS';
    }
    params.m_c73 = bookingType;
  }
}


export default new HotelBookingTrackingHelper();
