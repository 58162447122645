import React, {useState} from 'react';
import {StyleSheet, Text, View, Image, TouchableOpacity} from 'react-native';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import CtaButton from '@mmt/legacy-commons/Common/Components/Buttons/CtaButton';
import PropTypes from 'prop-types';
import noError from '@mmt/legacy-assets/src/no_cabs.webp';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getStaticData } from '../../../../staticData/staticData';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const ErrorPage = ({
  style = {},
  showRetry = false,
  onRetry = PropTypes.func,
  message,
  buttonLabel,
  showImage = false,
  showHelpingHand,
  helpingHandLabel,
  helpingHandClickHandler,
  connectUsViewStyle,
  buttonGradient,
  isCorporateBooking,
  langAware = true
}) => {
  const { fsStyle, ...fonts } = getFont(true);
  const styles = getStyles(fonts);
  const staticData = getStaticData(langAware);
  const { errorText: { somethingWentWrongTryAgainError }, tryAgainText } = staticData;
    return (
      <View style={[styles.container, style]}>
        {showImage &&
        <Image style={styles.noErrorImage} source={noError} resizeMode="contain" />}
        <Text style={[styles.textDark, styles.messageText]}>
          {!!message ? message : somethingWentWrongTryAgainError}
        </Text>
        {showRetry &&
        <View style={styles.retryView}>
          <CtaButton label={!!buttonLabel ? buttonLabel : tryAgainText} onPress={onRetry} buttonGradient={buttonGradient} />
        </View>}
        {showHelpingHand && <TouchableOpacity style={[styles.connectUsView, connectUsViewStyle]} onPress={helpingHandClickHandler}>
            <Text style={[fsStyle.font16, AtomicCss.lineHeight20, isCorporateBooking ? AtomicCss.lightBlueMyBizText : AtomicCss.blueText, fonts.blackFontFamily]}>{helpingHandLabel}</Text>
        </TouchableOpacity>}
      </View>
    );
}

export default ErrorPage;

const getStyles = (fonts) => {
  return StyleSheet.create({
    container: {
      flex: 1,
      width: '100%',
      height: '100%',
      backgroundColor: '#ffffffd9',
      alignItems: 'center',
      justifyContent: 'center'
    },
    noErrorImage: {
      height: 180,
      marginVertical: 16
    },
    textDark: {
      color: colors.black,
      ...fonts.font16,
      fontFamily: fonts.bold,
      textAlign: 'center'
    },
    retryView: {
      marginTop: 16
    },
    connectUsView: {
      position: 'absolute',
      bottom: 56,
      paddingVertical: 12,
      paddingHorizontal: 20
    },
    messageText: {
      marginTop: 8,
      paddingRight: 30, 
      paddingLeft: 30
    },
    CapsuleBtnFill: {
      paddingBottom: 12,
      paddingTop: 12,
      paddingLeft: 10,
      paddingRight: 10,
      borderRadius: 5,
      marginRight: 10,
      marginLeft: 10
    },
    FooterBtn: {
      width: 120
    }
  });
}
