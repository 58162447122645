import React from 'react';
import {View, Text, Image, TouchableOpacity} from 'react-native';
import isEmpty from 'lodash/isEmpty';
import Button from './Button';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getPlatformElevation from './getPlatformElevation';
import BasePage from '../../Common/PostSalesBasePage';

const visaDocumentIcon = require('@mmt/legacy-assets/src/visaDocumentIcon.webp');

class VisaStatusCard extends BasePage {
  static navigationOptions = {
    header: null
  };

  render() {
    const {card, buttons, handleButtonClick} = this.props;
    return (
      <View style={[AtomicCss.marginBottom10, styles.cardBorder]}>
        <View style={[styles.infoCardWrapper]}>
          <View style={styles.headingIconWrapper}>
            <Image style={styles.headingIconStyle} source={visaDocumentIcon} />
          </View>
          <View style={styles.infoCardContent}>
            <Text
              style={[AtomicCss.defaultText, AtomicCss.blackFont, AtomicCss.font14, AtomicCss.marginBottom15]}
            >{card.heading}
            </Text>
            <Text
              style={[AtomicCss.regularFont, AtomicCss.font14, AtomicCss.defaultText, AtomicCss.lineHeight16]}
            >
              {card.subHeading}
            </Text>
            {card.linkText && (<TouchableOpacity >
                <Text style={[styles.linkText, AtomicCss.boldFont]}>{card.linkText}</Text>
            </TouchableOpacity>)}
            <View style={styles.buttonWrapper}>
              {!isEmpty(buttons)
              && buttons.map(item => (
                <Button
                  button={item}
                  handleClick={handleButtonClick}
                />
              ))
              }
            </View>
          </View>
        </View>
      </View>
    );
  }
}

const styles = {
  ZCBigIcon: {
    width: 120,
    height: 22,
    marginBottom: 12
  },
  cardBorder: {
    borderTopColor: '#e4e4e4',
    borderBottomColor: '#e4e4e4',
    borderTopWidth: 1,
    borderBottomWidth: 1
  },
  infoCardWrapper: {
    backgroundColor: '#fff',
    justifyContent: 'space-between',
    flexDirection: 'row',
    ...getPlatformElevation(2),
    marginBottom: 1
  },

  infoCardContent: {
    paddingHorizontal: 8,
    paddingVertical: 15,
    flex: 1
  },
  headingIconWrapper: {
    width: 46,
    alignItems: 'flex-start',
    top: 14,
    left: 16
  },
  headingIconStyle: {
    width: 24,
    height: 25,
    tintColor: '#000'
  },
  buttonWrapper: {
    paddingTop: 20,
    paddingBottom: 20,
    width: '90%'
  },
  linkText: {
    color: '#008cff',
    fontSize: 14
  }
};


export default VisaStatusCard;
