import { StyleSheet } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss'

export const htmlStyle = (fonts, psTheme) => {
    const {color} = psTheme;
    return StyleSheet.create({
      div: {
        color: color.darkBlue11,
        ...fonts.boldFontFamily,
        ...AtomicCss.marginTop5
      },
      span: {
        color: color.red25
      }
    });
  }