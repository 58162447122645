import {Platform} from 'react-native';
import {colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles';

const styles = {
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: colors.white,
    minHeight: 60,
    ...Platform.select({
      ios: {
        zIndex: 4
      }
    })
  },
  leftContent: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1
  },
  backBtn: {
    padding: 20,
    marginRight: 5,
    zIndex: 1
  },
  backIcon: {
    height: 16,
    width: 16,
    tintColor: colors.disabledBtnBg
  },
  title: {
    fontSize: 18,
    color: colors.defaultTextColor,
    fontFamily: fonts.bold
  },
  subTitle: {
    fontSize: 12,
    color: colors.disabledBtnBg,
    fontFamily: fonts.regular,
    marginTop: 3
  },
  shareBtn: {
    padding: 20
  },
  shareIcon: {
    height: 24,
    width: 24
  },
  RefreshIcon: {
    height: 24,
    width: 24
  },
};

export default styles;
