import React from 'react';
import { Text, View, TouchableOpacity } from 'react-native';
import createStyles from './styles';
import FlightListingTrackingHelper from '../../../../analytics/FlightListingTrackingHelper';
import { getFont } from '../../../../../../../PostSalesStyles';
import { useTheme } from '../../../../../../../theme/theme.context';

const FooterTab = (props) => {
  const toggleDot = () => {
    props.toggleQuckFltr(props.index);
    FlightListingTrackingHelper.trackClickEvent(
      'Mob:Customer support:datechangelisting',
      `MI_datechangelisting:quick filter ${props.item}`,
    );
  };
  const { item, selected } = props;
  const fonts =  getFont(true);
  const {psTheme: theme} = useTheme();
  const styles = createStyles(theme, fonts);
  return (
    <View style={styles.FooterTabWidth}>
      <TouchableOpacity style={styles.sortItem} onPress={toggleDot}>
        {selected && <View style={styles.FooterActiveDot} />}
        <Text style={styles.sortLabel}>{item}</Text>
      </TouchableOpacity>
    </View>
  );
};

export default FooterTab;
