import React from 'react';
import {Image, Text, View} from 'react-native';
import getStyles from './HotelMamiCss';
import {
  appendCurrencySymbol, appendRupeeSymbol, appendRupeeSymbolAndRound,
  isNotNullAndEmpty
} from '../utils/HotelBookingDetailUtil';
import PropTypes from 'prop-types';
import LinearGradient from 'react-native-linear-gradient';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import BlackPriceBreakup from '../../../Common/BlackPriceBreakup';
import { getStaticData } from '../../../staticData/staticData';
import { PAYMENT_CARD_ICON } from '../../HotelImageConstants';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const priceBreakUpImage = PAYMENT_CARD_ICON;
class PriceBreakUpCard extends React.Component {
  constructor(props) {
    super(props);
    this.fonts = getFont(true);
    this.styles = getStyles(this.fonts);
  }

  static navigationOptions = {
    header: null
  };

  getPricingBreakupView = (pricingBreakup) => {
    const { pricingBreakupMap, pricingBreakupDiscountObj, pricingBreakupBlackObj } = pricingBreakup;
    const {loyaltyDetails,isMyPartnerBooking} = this.props.response;
    const {tdsStructureObj, commissionStructureObj, commisionBreakup} = this.props.response.pricingBreakup;
    const views = [];
    if (pricingBreakupMap) {
      for (let componentName in pricingBreakupMap) {
        views.push(
          <View style={[this.styles.Paymentrow, this.styles.borderbtm, this.styles.padVertical10]} key={componentName}>
            <Text style={[this.styles.PaymentLeft, this.styles.AlignLeft]}>{componentName}</Text>
            <Text style={[this.styles.BlackText,this.styles.AlignLeft]}>{`${pricingBreakupMap[componentName]} `}</Text>
          </View>
        )
      }

      if(isMyPartnerBooking && Platform.OS === 'web' ) {
        if(commissionStructureObj) {
          views.push(
            <View style={[styles.Paymentrow, styles.font12, styles.borderbtm, styles.padVertical10]} key={commissionStructureObj.text}>
              <View style={{flex: 1, flexDirection: "row", justifyContent: 'flex-start'}}>
              <Text style={[styles.AlignLeft]}>{commissionStructureObj.text}</Text>
              <ToolTip commissionBreakUp={commisionBreakup} />
              </View>
              <Text style={[styles.AlignLeft]}>{`${commissionStructureObj.totalCost} `}</Text>
            </View>
          )
        }

        if(tdsStructureObj) {
          views.push(
            <View style={[styles.Paymentrow, styles.font12, styles.borderbtm, styles.padVertical10, {zIndex: -1}]} key={tdsStructureObj.text}>
              <Text style={[styles.PaymentLeft, styles.AlignLeft]}>{tdsStructureObj.text}</Text>
              <Text style={[styles.AlignLeft]}>{`${tdsStructureObj.totalCost} `}</Text>
            </View>
          )
        }
      }
      else if (pricingBreakupDiscountObj) {
        views.push(
          <View style={[this.styles.Paymentrow, this.styles.font12, this.styles.borderbtm, this.styles.padVertical10]} key={pricingBreakupDiscountObj.discountText}>
            <Text style={[this.styles.PaymentLeft, this.styles.greenText, this.styles.AlignLeft]}>{pricingBreakupDiscountObj.discountText} ({pricingBreakupDiscountObj.discountCode})</Text>
            <Text style={[this.styles.greenText,this.styles.AlignLeft]}>{`${pricingBreakupDiscountObj.discountValue} `}</Text>
          </View>
        )
      }
    }
    if (loyaltyDetails && loyaltyDetails.tierMetaInfo && pricingBreakupBlackObj) {
      views.push(
        <View style={[this.styles.Paymentrow, this.styles.borderbtm, this.styles.padVertical10]}>
          <BlackPriceBreakup
            priceBreakupBlackText={pricingBreakupBlackObj.blackText}
            priceBreakupBlackValue={`${pricingBreakupBlackObj.blackValue} `}
            tierMetaInfo={loyaltyDetails.tierMetaInfo}
            tierType={loyaltyDetails.tierType}
            />
        </View>)
    }
    return views;
  }

  render() {
    const staticData = getStaticData();
    const roomDetails = this.props.response.hotelBookingInfo.roomDetails;
    const {paymentDetails} = this.props.response.paymentDetails;
    const {paymentSummary, pricingBreakup} = this.props.response;
    return (
      <View style={[this.styles.MamiCard, this.styles.mar10Bt]}>
        <View style={this.styles.CardContent}>
          <Image style={this.styles.CardIcon}
                 source={priceBreakUpImage}/>
          <View style={this.styles.ContentPortion}>
            <View style={[this.styles.Paymentrow, this.styles.mar10Bt]}>
              <View style={this.styles.PaymentLeft}>
                <Text style={[this.styles.BlackText, this.styles.blackFont, this.styles.AlignLeft]}>
                  {staticData.hotelPriceBreakupText.additionalText.priceBreakupText}
                </Text>
              </View>
            </View>

            {pricingBreakup && pricingBreakup.tag &&
              <View style={[this.styles.flexRow]}>
                <LinearGradient colors={['#3023ae', '#c86dd7']}
                start={{x: 1.0, y: 1.0}}
                end={{x: 0.0, y: 1.0}}
                style={[this.styles.myTrpPricBrkupTag]}>
                <Text style={[this.styles.WhiteText, this.styles.boldFont, this.styles.font12, this.styles.AlignLeft]}>{pricingBreakup.tag}</Text>
                </LinearGradient>
              </View>
            }

            {this.getPricingBreakupView(pricingBreakup)}

            {pricingBreakup && pricingBreakup.pricingTotalCost && pricingBreakup.pricingTotalCost.text && (
              <View style={[this.styles.Paymentrow, this.styles.pad10Top]}>
                <View style={this.styles.PaymentLeft}>
                  <Text style={[this.styles.BlackText, this.styles.blackFont, this.styles.AlignLeft]}>{pricingBreakup.pricingTotalCost.text}</Text>
                </View>
                <Text
                  style={[this.styles.BlackText, this.styles.blackFont, this.styles.AlignLeft]}>{`${pricingBreakup.pricingTotalCost.totalCost} `}</Text>
              </View>
            )}

            {pricingBreakup && pricingBreakup.hotelCurrencyCost && pricingBreakup.hotelCurrencyCost.text && (
              <View style={[this.styles.Paymentrow, AtomicCss.paddingBottom5]}>
                <Text style={[this.styles.PaymentLeft, this.styles.AlignLeft]}>{pricingBreakup.hotelCurrencyCost.text}</Text>
                <Text style={[this.styles.font14,this.styles.AlignLeft]}>{`${pricingBreakup.hotelCurrencyCost.value} `}</Text>
              </View>
            )}
          </View>
        </View>
      </View>
    );
  }
}
PriceBreakUpCard.propTypes = {
  response: PropTypes.object.isRequired,
};

export default PriceBreakUpCard;
