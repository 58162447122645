import { useTheme } from '../../../../../../theme/theme.context';
import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { getFont } from '../../../../../../PostSalesStyles';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import createStyles from "./trainInfoCss";
const TrainInfoCard = ({ trainDetails }) => {
    const { trainName, trainNo, departure, arrival } = trainDetails || {};
    const { location: departureLocation, stationName: departureStation, dateTime: departureDateTime } = departure || {};
    const { location: arrivalLocation, stationName: arrivalStation, dateTime: arrivalDateTime } = arrival || {}
    const fonts = getFont();
    const { psTheme: theme } = useTheme();
    const styles = createStyles(theme, fonts);
    return (
        <View style={styles.trainInfoSection}>
            <View style={[AtomicCss.flexRow, AtomicCss.marginBottom14, AtomicCss.spaceBetween, AtomicCss.alignCenter]}>
                <Text style={[AtomicCss.font14, fonts.blackFontFamily, AtomicCss.blackText]}>{trainName || ' '}</Text>
                <Text style={styles.trainNumber}>{trainNo || ' '}</Text>
            </View>
            <View style={[AtomicCss.flexRow, AtomicCss.marginBottom5, AtomicCss.spaceBetween, AtomicCss.alignCenter]}>
                <Text style={[AtomicCss.font14, fonts.boldFontFamily, AtomicCss.blackText]}>{departureLocation || ' '}</Text>
                <Text style={[AtomicCss.font14, fonts.boldFontFamily, AtomicCss.blackText]}>{arrivalLocation || ' '}</Text>
            </View>
            <View style={[AtomicCss.flexRow, AtomicCss.marginBottom5, AtomicCss.spaceBetween, AtomicCss.alignCenter]}>
                <Text style={[styles.destination, fonts.boldFontFamily]}>{departureStation || ' '}</Text>
                <Text style={[styles.destination, AtomicCss.alignRight, fonts.boldFontFamily]}>{arrivalStation || ' '}</Text>
            </View>
            <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween, AtomicCss.alignCenter]}>
                <Text style={[styles.destination, fonts.regularFontFamily]}>{departureDateTime || ' '}</Text> 
                <Text style={[styles.destination, AtomicCss.alignRight, fonts.regularFontFamily]}>{arrivalDateTime || ' '}</Text>
            </View>
        </View>
    )
}

export default TrainInfoCard;