import React from 'react';
import { View, ScrollView, Text } from 'react-native';
import PropTypes from 'prop-types';
import getStyles from '../../details/components/HotelMamiCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { connect } from 'react-redux';
import {
  fetchHotelDetailsResponse,
  getRoomInfoIterable,
  handleModificationAction,
  isIntlHotelBooking,
} from '../../details/utils/HotelBookingDetailUtil';
import AddGuestCard from './AddGuestCard';
import HotelBookingTrackingHelper from '../../HotelBookingTrackingHelper';
import { CHECK_AVAILABILITY_ADDGUEST_CLICK, MOD_ERROR_TYPES } from '../HotelModificationConstants';
import { NEED_HELP_PAGE } from '../../../PostSalesConstant';
import {
  getHelpingHandData,
  renderNeedHelpOverlay,
  trackHelpingHandEvent,
} from '../../details/utils/HelpingHandHelper';
import { getStaticData } from '../../../staticData/staticData';
import SimpleHeader from '@mmt/legacy-commons/Common/Components/Header/SimpleHeader';
import Actions from '../../../navigation/postSalesNavigation';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import { LANG_AWARE } from '../../HotelConstants';
import withRouterWrapper from '../../../Common/Web/withRouterWrapper';
import ProgressView from '../../../Common/ProgressView/ProgressView';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import { getFont, themeColors } from '../../../PostSalesStyles';
import { ERROR_TYPES } from '../../details/HotelBookingDetailsConstant';
import BaseButton, { BUTTON_VARIANT } from '../../../Common/BaseButton';
import NoInternetView from '@mmt/legacy-commons/Common/Components/Error/NoInternetView';
import ButtonWrapper from 'apps/post-sales/src/Common/ButtonWrapper';

class AddGuest extends React.Component {
  constructor(props) {
    super(props);
    this.fonts = getFont(true);
    this.styles = getStyles(this.fonts);
    this.fsStyle = this.fonts.fsStyle;
    this.state = {
      helpingHandOverlay: false,
      roomListDetails: [],
      errorType: MOD_ERROR_TYPES.NONE,
    };
    this.onButtonClick = this.onButtonClick.bind(this);
    this.onRoomListDetailsChange = this.onRoomListDetailsChange.bind(this);
    this.trackingPageName = '';
    this.modificationData = {};
  }

  static navigationOptions = {
    header: null,
  };

  componentDidMount() {
    const { response, bookingId} = this.props;
    if (response) {
      this.trackAddGuestLoadEvent(response)
      this.modificationData = this.getModificationData();
      this.setState({
        roomListDetails: getRoomInfoIterable(response.hotelBookingInfo.roomDetails),
      })
    } else {
      this.props.fetchBookingDetails(bookingId);
    }
  }

  componentDidUpdate(prevProps) {
    const { showLoader, showError, response } = this.props;
    if (!showLoader && !showError && !prevProps.response && response) {
      this.trackAddGuestLoadEvent(response);
      this.modificationData = this.getModificationData();
      this.setState({
        roomListDetails: getRoomInfoIterable(response.hotelBookingInfo.roomDetails),
      })
    }
  }

  trackAddGuestLoadEvent = (hotelbookingDetailResponse) => {
    this.trackingPageName = isIntlHotelBooking(hotelbookingDetailResponse?.lobCode)
      ? 'Mob:customer support:Hotel International:AddGuest:AdditionalGuestsChosen'
      : 'Mob:customer support:Hotel Domestic:AddGuest:AdditionalGuestsChosen';
    HotelBookingTrackingHelper.trackLoadEvent(this.trackingPageName, hotelbookingDetailResponse);
  }

  getModificationData() {
    const { hotelModificationData = {} } = this.props.response || {};
    const {
      childAgeCandidates,
      maxPaxCount,
      minAdultCount,
      minChildCount,
      warnings,
      adultLabel,
      childLabel,
      adultAgeCriteria,
      childAgeCriteria
    } = hotelModificationData;
    return { childAgeCandidates, maxPaxCount, minAdultCount, minChildCount, warnings, adultLabel, childLabel, adultAgeCriteria,childAgeCriteria };
  }

  onRoomListDetailsChange(roomListDetails) {
    this.setState({ roomListDetails });
  }

  onButtonClick(request) {
    const extraArgs = {
      childAgeCandidates: this.modificationData.childAgeCandidates,
      card: this.props.card,
    };
    this.trackingPageName = isIntlHotelBooking(this.props.response?.lobCode)
      ? 'Mob:customer support:Hotel International:AddGuest:AdditionalGuestsChosen'
      : 'Mob:customer support:Hotel Domestic:AddGuest:AdditionalGuestsChosen';
    HotelBookingTrackingHelper.trackClickEvent(
      this.trackingPageName,
      CHECK_AVAILABILITY_ADDGUEST_CLICK,
      this.props.response,
    );
    const { errorType } = handleModificationAction(
      'ADDGUEST',
      request,
      this.props.response,
      extraArgs,
      this.showHelpingHand,
      LANG_AWARE.MODIFICATION
    );
  }

  toggleHelpingHandOverlay = (value) => {
    this.setState({
      helpingHandOverlay: value,
    });
  };

  trackHelpingHandEvents = (type, page, click, data) => {
    trackHelpingHandEvent(type, page, click, data, this.trackingPageName, this.props.response);
  };

  showHelpingHand = () => {
    this.setState({
      helpingHandOverlay: true,
    });
  };

  onBackPress = () => {
    Actions.pop();
  }

  trackRetryClickEvent = (clickEvent) => {
    this.trackingPageName = isIntlHotelBooking(this.props.response?.lobCode)
      ? 'Mob:customer support:Hotel International:AddGuest:Preview:Error'
      : 'Mob:customer support:Hotel Domestic:AddGuest:Preview:Error';
    HotelBookingTrackingHelper.trackClickEvent(
      this.trackingPageName,
      clickEvent,
      this.props.response,
    );
  }

  renderProgressView = () => (
    <ProgressView />
  );

  renderNoNetworkView = () => {
    const staticData = getStaticData(LANG_AWARE.MODIFICATION);
    const { bookingId } = this.props;
    return (
      <View style={AtomicCss.flex1}>
        <SimpleHeader title={staticData.backText} iconPress={this.onBackPress} />
        <NoInternetView
            onRetry={() => {
              this.props.fetchBookingDetails(bookingId);
              trackRetryClickEvent('no_internet_retry_clicked');
            }}
        />
      </View>
    );
  };

  renderErrorView = () => {
    const staticData = getStaticData(LANG_AWARE.MODIFICATION);
    const { bookingId, hotelDetailsErrorData } = this.props;
    const { errorType } = hotelDetailsErrorData || {};
    if (errorType === ERROR_TYPES.NO_INTERNET) {
      return this.renderNoNetworkView();
    }
    return (
      <View style={AtomicCss.flex1}>
        <SimpleHeader title={staticData.backText} iconPress={this.onBackPress} />
        <ErrorView
          showRetry
          onRetry={() => {
            this.props.fetchBookingDetails(bookingId);
            this.trackRetryClickEvent('retry_clicked');
          }}
          buttonGradient={themeColors.gradientBtnColor}
          message={staticData.errorText.somethingWentWrongTryAgainError}
          buttonLabel={staticData.tryAgainText}
        />
      </View>
    );
  };

  render() {
    const { showLoader, showError, response } = this.props;
    if (showError) {
      return this.renderErrorView();
    } else if (showLoader || !response) {
      return this.renderProgressView();
    }
    const {
      addGuestCardText: { heading, subHeading }, modifyGuestsText, proceedText
    } = getStaticData(LANG_AWARE.MODIFICATION);
    const hotelDetailResponse = this.props.response.hotelBookingInfo;
    const origRoomDetails = getRoomInfoIterable(hotelDetailResponse.roomDetails);
    return (
      <View style={[this.styles.container, this.styles.flex1]}>
        <SimpleHeader
          title={modifyGuestsText}
          iconPress={this.onBackPress}
          elevation={2}
          textColor={colors.darkGrey4}
          textFont={this.fonts.bold}
          textSize={18}
        />
        <ScrollView>
          <View style={[AtomicCss.makeRelative, this.styles.addGuestPageContainer]}>
            <>
              <View
                style={[
                  this.styles.addGuestPageWrapper,
                  AtomicCss.marginHz12,
                  AtomicCss.flex1,
                  AtomicCss.marginBottom12,
                  AtomicCss.paddingBottom20,
                ]}
              >
                <View style={this.styles.AddGuestCardWrapper}>
                  <View style={this.styles.AddGuestHeadingText}>
                    <Text
                      style={[
                        this.fsStyle.font20,
                        this.fonts.blackFontFamily,
                        AtomicCss.blackText,
                        AtomicCss.marginBottom16,
                        AtomicCss.lineHeight20,
                        AtomicCss.flex1,
                      ]}
                    >
                      {heading}
                    </Text>
                    <Text
                      style={[
                        this.fsStyle.font16,
                        this.fonts.regularFontFamily,
                        AtomicCss.defaultText,
                        this.styles.lineHeight22,
                        AtomicCss.flex1,
                      ]}
                    >
                      {subHeading}
                    </Text>
                  </View>
                  <AddGuestCard
                    response={this.props.response}
                    modificationData={this.modificationData}
                    onRoomListDetailsChange={this.onRoomListDetailsChange}
                    errorType={this.state.errorType}
                  />
                </View>
              </View>
            </>
          </View>
        </ScrollView>
        <View style={[AtomicCss.marginTop40, AtomicCss.marginBottom20, AtomicCss.alignCenter]}>
          <ButtonWrapper
            clickHandler={() => this.onButtonClick({
              response: this.props.response,
              roomListDetails: this.state.roomListDetails,
              origRoomDetails: origRoomDetails,
            })}
            btnText={proceedText}
            buttonTextStyle={this.fsStyle.font16}
            buttonWidth='90%'
          />
        </View>
        {this.state.helpingHandOverlay === true &&
          renderNeedHelpOverlay(
            getHelpingHandData(
              this.props.response,
              NEED_HELP_PAGE.HOTEL_ADD_GUEST,
              this.trackHelpingHandEvents,
              LANG_AWARE.MODIFICATION
            ),
            this.toggleHelpingHandOverlay,
            true,
          )}
      </View>
    );
  }

  getNoOfGuest() {
    return (
      this.props.response.hotelBookingInfo.totalNumberOfChilds +
      this.props.response.hotelBookingInfo.totalNumberOfAdults
    );
  }
}

AddGuest.propTypes = {
  card: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  const { hotelDetailsResponseEng, isHotelBookingAPIErrorEng, isHotelBookingDetailsFetching, hotelDetailsErrorData } = state.hotelDetailReducer;
  return {
    response: hotelDetailsResponseEng,
    showError: isHotelBookingAPIErrorEng,
    showLoader: isHotelBookingDetailsFetching,
    hotelDetailsErrorData: hotelDetailsErrorData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchBookingDetails: (bookingId, langAware = LANG_AWARE.MODIFICATION) => {
    dispatch(fetchHotelDetailsResponse(bookingId, langAware));
  },
});

export default withRouterWrapper(connect(mapStateToProps, mapDispatchToProps)(AddGuest));
