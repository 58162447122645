
import PANConstant from './PanConstant';
import { PanDetailState, PanDetailAction } from './types';

export default (state: PanDetailState, action: PanDetailAction): PanDetailState  => {
  switch (action.type) {
    case PANConstant.VALIDATING_PAN:
      return {
        ...state,
        isPanVerified: false,
        isPanVerifing: true,
        panNumber: action?.data?.panNumber,
      };
    case PANConstant.SET_PAN_VERIFY_ERROR:
      return {
        ...state,
        errorText: action?.data?.errorText,
        isPanVerifing: false,
        panNumber: action?.data?.panNumber,
      };
    case PANConstant.SET_PAN_VERIFIED:
      return {
        ...state,
        isPanVerified: true,
        isPanVerifing: false,
        panHolderName: action?.data?.panHolderName,
        panHolderUnmaskedName: action?.data?.panHolderUnmaskedName,
      };
    case PANConstant.RESET_PAN_VERIFIED:
      return {
        ...state,
        isPanVerified: false,
        panNumber: undefined,
        errorText: undefined,
        panHolderName: undefined,
      };
    case PANConstant.TOGGLE_CHECKBOX:
      return {
        ...state,
        isChecked: !state?.isChecked,
      };
    case PANConstant.CHANGE_PAN:
      return {
        ...state,
        showPanEditor: true,
        isUserPanSaved: false,
        panSubmitMsg: undefined,
      };
    case PANConstant.PAN_SUBMIT_LOADING:
      return {
        ...state,
        errorText: undefined,
      };
    case PANConstant.PAN_SUBMIT_SUCCESS:
      return {
        ...state,
        panSubmitMsg: action?.data?.message,
        isPanSubmit: true,
        showPanEditor: false,
        isPanVerified: false,
        panNumber: undefined,
        errorText: undefined,
        panHolderName: undefined,
      };
    case PANConstant.PAN_SUBMIT_ERROR:
      return {
        ...state,
        errorText: action?.data?.errorText,
      };
    default:
      return state;
  }
};
