import React, { useState } from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { getStaticData } from 'apps/post-sales/src/staticData/staticData';
import isEmpty from 'lodash/isEmpty';
import createStyles from './style';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import {FILTERS_MAX_VALUES_SHOWN} from '../../../../summaryConstants';

interface TabProps {
  item: { id: string; title: string };
  handleTab: (id: string, text: string) => void;
  selectedTab: string[];
}

const Tab: React.FC<TabProps> = ({ item, handleTab, selectedTab }) => {
  const { fsStyle, ...fonts } = getFont(true);
  const activeTab = !isEmpty(selectedTab) && selectedTab.includes(item.id) ? 'activeTabStyle' : '';
  const activeTabText = !isEmpty(selectedTab) && selectedTab.includes(item.id) ? 'activeTabTextStyle' : '';
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);
  return (
    <TouchableOpacity style={[styles.tab, activeTab ? styles[activeTab] : {}]} onPress={() => handleTab(item.id, item.text)}>
      <Text style={[styles.tabText, activeTabText? styles[activeTabText]: {}]}>{item.text}</Text>
    </TouchableOpacity>
  );
};

interface FilterCardSectionProps {
  title: string;
  tabList: { id: string; text: string }[];
  handleTab: (id: string, text: string) => void;
  selectedTab: string[];
  isOverlayFilterApplied : boolean;
}

const FilterCardSection: React.FC<FilterCardSectionProps> = ({
  title,
  tabList,
  handleTab,
  selectedTab,
  isOverlayFilterApplied = false
}) => {
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);
  const handleFilterClick = (id: string, text: string) => {
    handleTab(id, text);
  };
  const { showMoreText, showLessText } = getStaticData(true);
  const [seenTabList, setSeenTabList] = useState(tabList.slice(0, FILTERS_MAX_VALUES_SHOWN));
  const [showMore, setShowMore] = useState(tabList.length > FILTERS_MAX_VALUES_SHOWN ? showMoreText : ' ');

  const handleSeeMoreClick = () => {
    if (showMore === showMoreText) {
      setSeenTabList(tabList);
      setShowMore(showLessText);
    } else {
      setSeenTabList(tabList.slice(0, FILTERS_MAX_VALUES_SHOWN));
      setShowMore(showMoreText);
    }
  };

  return (
    <View style={ isOverlayFilterApplied ?  [styles.filterRowSection,AtomicCss.paddingLeft0, { borderBottomWidth:0, paddingTop:0 }] :styles.filterRowSection}>
      <Text style={[styles.titleText, AtomicCss.marginBottom16]}>
        {title}
      </Text>
      <View style={styles.filterTab}>
        {seenTabList &&
          seenTabList.map((item) => {
            return (
              <Tab
                item={item}
                handleTab={handleFilterClick}
                selectedTab={selectedTab}
                key={item.id}
              />
            );
          })}
        {!isEmpty(showMore) && (
          <TouchableOpacity style={AtomicCss.paddingVertical16} onPress={handleSeeMoreClick}>
            <Text style={styles.linkText}>{showMore}</Text>
          </TouchableOpacity>
        )}
      </View>
    </View>
  );
};

export default FilterCardSection;