import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';

const styles = {
  verifiedStaysCard: {
    backgroundColor: '#fff',
    borderRadius: 10,
    marginHorizontal: 12,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    ...getPlatformElevation(2),
    marginBottom: 12,
  },
  verifiedStays: {
    width: 68,
    height: 56,
  },
  rightBlueArrow: {
    width: 16,
    height: 16,
    marginRight: 10,
  },
  greenTextColor: {
    color: '#0aa34f',
  },
};
export default styles;
