import { StyleSheet } from 'react-native';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';

const getStyles = (fonts) => {
  return StyleSheet.create({
    cardWrapper: {
      backgroundColor: colors.white,
      borderRadius: 8,
      paddingHorizontal: 24,
      paddingVertical: 24,
    },
    radioWrapper: {
      flexDirection: 'row',
      backgroundColor: colors.lightGrey6,
      borderRadius: 4,
      paddingVertical: 16,
      paddingHorizontal: 20,
      marginBottom: 8,
    },
    radio: {
      width: 18,
      height: 18,
      backgroundColor: colors.white,
      borderRadius: 20,
      borderWidth: 2,
      borderColor: colors.purpleyGrey,
      marginRight: 15,
      marginTop: 5,
    },
    radioOutside: {
      borderColor: colors.azure,
    },
    myBizRadioOutside:{
      borderColor: colors.orange3,
    },
    radioInside: {
      width: 10,
      height: 10,
      backgroundColor: colors.azure,
      borderRadius: 20,
      marginLeft: 2,
      marginTop: 2,
    },
    myBizRadioInside:{
      width: 10,
      height: 10,
      backgroundColor: colors.orange3,
      borderRadius: 20,
      marginLeft: 2,
      marginTop: 2
    },
    radioTxt: {
      ...fonts.font14,
      color: colors.black,
      fontFamily: fonts.bold,
      lineHeight: 20,
      paddingTop: 3,
    },
    myBizRadio:{
      backgroundColor: colors.orange3,
    },
    radioColor: {
      color: colors.azure,
    },
  });
}
export default getStyles;
