import React from 'react';
import { ScrollView } from 'react-native';
import InsuranceDetailCard from './InsuranceDetailCard';
import {
  screenWidth,
} from '../HolidayBookingConstants';
import { useTheme } from '../../theme/theme.context';
import isEmpty from 'lodash/isEmpty';

interface InsuranceDetailsProps {
  insuranceDetails: {
    insuranceList: {
      heading: string,
      companyName: string,
      insuranceIconUrl: string,
      tagText: string,
      tagTextColor: string,
      data: {
        key: string,
        value: string
      }[]
    }[]
  }
}

const InsuranceDetailList = (props: InsuranceDetailsProps) => {
  if (isEmpty(props.insuranceDetails) || isEmpty(props.insuranceDetails.insuranceList)){
    return null;
  }
  const {psTheme: theme} = useTheme();
  return (
    <ScrollView style={[{backgroundColor: theme.color.grayBg, width: screenWidth}]}>
      {props.insuranceDetails.insuranceList.map((item, index) => (
        <InsuranceDetailCard insuranceDetail={item} />
      ))}
    </ScrollView>
  );
};

export default InsuranceDetailList;