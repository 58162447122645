import {Linking} from 'react-native';
import {fetchUrlParam} from '../Common/commonUtil';
import {openWebView} from './PostSaleUtil';
import {colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles';
const back_white_icon = require('@mmt/legacy-assets/src/back-white.webp');
const WEB_VIEW = "1";
const BROWSER = "2";
export const onRefundCardHTMLLinkPress = async (onLinkPressed, onBackPressed, bookingId, trackRefundEvent, url, navigation) => {
  if(trackRefundEvent) trackRefundEvent("REFUND_TRACKER_DEEPLINK_CLICK");
    const params = fetchUrlParam(url);
    if(params.destination == WEB_VIEW){
      const headerText = params.headerText ? params.headerText : '';
      openWebView(headerText, back_white_icon, url, onBackPressed, bookingId, 'REFUND_TRACKER_WEBVIEW', true, navigation);
    }
    else if(params.destination == BROWSER)
      Linking.openURL(url);
    else if(onLinkPressed)
        onLinkPressed(params);
    else Linking.openURL(url);
};
export const htmlStyle = {
    q: { /* fix fo Html Fonts Not working without adding blank tag */
    },
    p: {
      color: colors.grey,
      fontSize: 14,
      fontFamily: fonts.regular,
      lineHeight: 18
    },
    a: {
      textDecorationLine: 'underline',
      color:colors. linkBlue
    }
}
