import { Dimensions, StyleSheet } from 'react-native';
const windW = Dimensions.get('window').width;

export default (theme, fonts) => {
  const {color} = theme;
  return StyleSheet.create({
    cardWrapper: {
      width: windW - 55,
      paddingHorizontal: 5,
    },
    whiteBg: {
      backgroundColor: color.white,
    },
    footerBtnWrapper: {
      paddingTop: 5,
      paddingBottom: 12,
      paddingHorizontal: 12,
      position: 'absolute',
      bottom: 0,
      width: '100%',
    },
    headingText: {
      marginBottom: 20,
      flex: 1,
    },
    lineHeight24: {
      lineHeight: 24,
    },
    infoWrapper: {
      backgroundColor: color.creamWhite,
      paddingHorizontal: 8,
      paddingVertical: 6,
      borderRadius: 4,
    },
    infoText: {
      color: color.lightYello,
      lineHeight: 12,
    },
    refundCardWrap: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    refundCardDetails: {
      flex: 1,
    },
    refundTopDtls: {
      flexDirection: 'row',
      marginBottom: 5,
    },
    radio: {
      width: 18,
      height: 18,
      backgroundColor: color.grey22,
      borderRadius: 20,
      borderWidth: 1,
      marginRight: 10,
      marginTop: 2,
    },
    activeBorder: {
      borderColor: color.azure,
    },
    RadioInside: {
      width: 12,
      height: 12,
      backgroundColor: color.azure,
      borderRadius: 20,
      marginLeft: 2,
      marginTop: 2,
    },
    refundTag: {
      paddingHorizontal: 8,
      paddingVertical: 4,
      borderRadius: 12,
    },
    refundTagText: {
      color: color.grey22,
    },
    refundText: {
      color: color.greyLight,
      flex: 1,
      lineHeight: 18,
    },
    refundLeftDtls: {
      color: color.black,
      lineHeight: 20,
      marginRight: 8,
    },
    RefundPriceTxt: {
      color: color.defaultTextColor,
      fontSize: fonts.fontSize.font15,
    },
    subHeading: {
      fontSize: fonts.fontSize.font12,
      color: color.defaultTextColor,
    },
    selectWrapperUp: {
      backgroundColor: color.lightGrey6,
      borderRadius: 4,
      padding: 16,
      marginBottom: 8,
    },
    selectWrapperDown: {
      borderWidth: 1,
      borderColor: color.greyBookedSeat,
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
      paddingHorizontal: 12,
      paddingVertical: 16,
      borderTopWidth: 0,
    },
    activeBg: {
      backgroundColor: color.lightPrimaryColor,
    },
    dissabled: {
      opacity: 0.4,
    },
    checkboxRow: {
      alignItems: 'flex-start',
    },
    checkBoxText: {
      color: color.greyLight,
      fontSize: fonts.fontSize.font14,
    },
    errorText: {
      color: color.errorRed,
      fontSize: fonts.fontSize.font14,
      marginTop: 4,
    },
  });
}
