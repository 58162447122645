import React, { useEffect } from 'react';
import { View, TouchableOpacity } from 'react-native';
import styles from './NewCommonOverlayCss';

const NewCommonOverlay = ({ handleBottomOverlay, children, roundBorder, styleContent }) => {
  
  useEffect(() => {
    const bodyEle = document.getElementsByTagName('body')[0];
    const initOverflow = bodyEle.style.overflow;
    bodyEle.style.overflow = 'hidden';
    return () => {
      bodyEle.style.overflow = initOverflow;
    }
  }, []);
  
  return (
    <View style={[styles.overlayWrapper]}>
      <TouchableOpacity onPress={() => handleBottomOverlay(' ')} activeOpacity={1} style={styles.overlay} />
      <View style={[styles.overlayContent, roundBorder ? styles.roundBorder : '', styleContent]}>
        {children}
      </View>
    </View>
  );
}

export default NewCommonOverlay;