import React from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import { isEmpty } from 'lodash';
import styles from './ReasonRefundRqstCardCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { imageTransformY } from '../../../../../utils/PostSaleUtil';
import { BG_BLUE_RIGHT_ARROW } from '../../../../FlightImageConstants';
import { getFont } from '../../../../../PostSalesStyles';

const ReasonCanList = ({ item, lastItem, icon, onItemClick }) => {
  const { fsStyle, ...fonts } = getFont(true);
  const lastItemSuffix = lastItem ? 'lastItem' : '';
  return (
    <TouchableOpacity
      onPress={() => onItemClick(item)}
      style={[styles.listRow, styles[`listRow${lastItemSuffix}`], AtomicCss.flexRow]}
    >
      <Image style={[styles.iconStyle, imageTransformY]} source={icon} />
      <View style={[styles.dtlsSection, styles[`dtlsSection${lastItemSuffix}`]]}>
        {!isEmpty(item.scName) && (
          <Text
            style={[
              fonts.blackFontFamily,
              fsStyle.font14,
              AtomicCss.blackText,
              AtomicCss.marginBottom8,
              AtomicCss.alignLeft,
            ]}
          >
            {item.scName}
          </Text>
        )}
        {!isEmpty(item.popupTxt) && (
          <Text
            style={[
              AtomicCss.defaultText,
              fsStyle.font12,
              fonts.regularFontFamily,
              styles.descInfo,
              AtomicCss.alignLeft,
            ]}
          >
            {item.popupTxt}
          </Text>
        )}
      </View>
      <Image style={[styles.redirectIcon, imageTransformY]} source={BG_BLUE_RIGHT_ARROW} />
    </TouchableOpacity>
  );
};

export default ReasonCanList;
