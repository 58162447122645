import React from 'react';
import {View, Text, Image} from 'react-native';
import styles from './BreadcrumbCss';
import {
   isNotNullAndEmpty
} from '../../../hotel/details/utils/HotelBookingDetailUtil';

const GreenTick = require('@mmt/legacy-assets/src/holidays/cancellation/light_green_tick.webp');

const CancellationBreadcrumb = (props) => (

    <View style={styles.steps}>
      {
          isNotNullAndEmpty(props.data) && props.data.map((step, index) => {
              const activeStyle = (index === props.activeStep) ? 'Active' : '';
              const DoneStyle = (index < props.activeStep) ? 'Done' : '';
              return(
                  <View key={index} style={styles.stepItem}>
                      <View style={[styles.stepNumberWrapper, styles['stepNumberWrapper'+activeStyle + DoneStyle]]}>
                      {(index >= props.activeStep) &&
                          <Text style={styles.stepNumber}>{index+1}</Text>
                        }
                        {(index < props.activeStep) &&
                          <Image style={styles.greenTickIcon} source = {GreenTick}/>
                        }
                      </View>
                      <Text style={[styles.stepName, styles['stepName'+activeStyle]]}>{step}</Text>
                  </View>
              )
          })
      }
    </View>
);

export default CancellationBreadcrumb;