import { Dimensions, StyleSheet } from "react-native";
// import getPlatformElevation from '../../../../../Styles/getPlatformElevation';
export default (theme, fonts) =>StyleSheet.create({
    payableWrapper:{
        backgroundColor:theme.color.lighterBlue,
        textAlign:'center',
        alignItems:'center',
        width:98
    },
    infoIcon:{
        width:12,
        height:12,
        position:'absolute',
        right:7,
        top:3,
    },
    topWrapper:{
        backgroundColor:theme.color.cyanBlue2,
        borderRadius:2,
        width:'108%',
        paddingVertical:2,
        paddingHorizontal:10,
        marginBottom:3
    },
    bottomWrapper:{
        flexDirection:'row',
        alignItems:'center',
        justifyContent:'center',
        width:'100%',
        paddingBottom:12
    },
    arrowWrapper:{
        flexDirection:'row',
        alignItems:'center',
        position:'absolute',
        bottom:0,
        overflow:'hidden',
    },
    borderArrow: {
        borderBottomWidth: 7.8,
        borderBottomColor: theme.color.creamWhite,
        borderLeftWidth: 7.8,
        borderLeftColor: "transparent",
        borderRightWidth: 7.8,
        borderRightColor: "transparent",
        borderTopWidth: 7.8,
        borderTopColor: "transparent",
        height: 0,
        width: 0,
        top:0,
        overflow:'hidden'
    }
})
