import React from 'react';
import {Text, View, Image} from 'react-native';
import PropTypes from 'prop-types';
import styles from '../../flights/modules/details/components/PaymentPriceBreakup/FareBreakUp/FareBreakUpCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import RailBookingDetailConstant from "../details/RailBookingDetailConstant";
import {ZC_IMAGE} from '../Utils/RailImageConstant';
import { getStaticData } from '../../staticData/staticData';
import { getFont } from '../../PostSalesStyles';
import isEmpty from 'lodash/isEmpty';

class FareBreakUp extends React.Component {
    render() {
        const { fsStyle } = getFont(true);
        const staticData = getStaticData(true);
        const { priceBreakUpText } = staticData;
        return (<View style={[AtomicCss.whiteBg, AtomicCss.marginBottom10]}>
            <View style={[styles.CardContentFull]}>
                {!isEmpty(priceBreakUpText) && <View style={styles.cardHeadingWrapper}>
                    <Text style={styles.cardHeading}>{priceBreakUpText}</Text>
                </View>}
                <View style={styles.fareBreakupInnerSection}>
                    {this.getFareBreakUpViews()}
                    {this.getDiscountViews()}
                </View>
                    <View style={[styles.totalPrice]}>
                        <Text
                            style={[styles.totalPricetxt, fsStyle.font16]}>{this.props.paymentBreakup.paymentBreakupTotalPrice.text}</Text>
                        <Text
                            style={[styles.totalPricetxt, fsStyle.font18]}>{this.props.paymentBreakup.paymentBreakupTotalPrice.value} </Text>
                    </View>
            </View>
        </View>);
    }

    getFareBreakUpViews = () => {
        const views = [];
        const fareBreakupArr = this.props.fareBreakUpDetail;
        for (let i = 0; i < fareBreakupArr.length; i += 1) {
            if(fareBreakupArr[i].labelId.startsWith(RailBookingDetailConstant.E_COUPON_TYPE_DISCOUNT) || fareBreakupArr[i].labelId.startsWith(RailBookingDetailConstant.CASHBACK_TYPE)) {
                continue;
            }
            if(fareBreakupArr[i].labelId === 'cancellation_assurance_amount') {
                views.push(<View key={i} style={styles.fareBreakupInnerRowSection}>
                    <View style={[styles.rowSection]}>
                        <Text style={styles.fareBkHeadingTxt}>
                            <Image source={ZC_IMAGE} style={{ height: 20, width: 20, paddingRight:5 }} />
                            {fareBreakupArr[i].text}
                        </Text>
                        <Text style={[styles.fareBkHeadingTxt]}>
                            {fareBreakupArr[i].amountInDoubleStr}
                        </Text>
                    </View>
                </View>);
            }
            else {
                views.push(<View key={i} style={styles.fareBreakupInnerRowSection}>
                    <View style={[styles.rowSection]}>
                        <Text style={styles.fareBkHeadingTxt}>{fareBreakupArr[i].text}</Text>
                        <Text style={[styles.fareBkHeadingTxt]}>
                            {fareBreakupArr[i].amountInDoubleStr}
                        </Text>
                    </View>
                </View>);
            }
        }
        return views;
    };

    getDiscountViews = () => {
        const discountArr = this.props.fareBreakupExtraNodeList;
        return discountArr.map(item => (<View style={styles.fareBreakupInnerRowSection}>
            <View style={[styles.rowSection]}>
                <View style={AtomicCss.flexRow}>
                    <Text style={styles.successTxt}>{item.name}</Text>
                    <View style={styles.cuponCode}>
                        <Text>{item.subText}</Text>
                    </View>
                </View>
                <Text style={[styles.successTxt]}>{item.amt} </Text>
            </View>
        </View>));
    };
}

export default FareBreakUp;

FareBreakUp.propTypes = {
    fareBreakupExtraNodeList: PropTypes.array.isRequired,
    fareBreakUpDetail: PropTypes.array.isRequired,
    cashbackCouponText: PropTypes.string,
};

FareBreakUp.defaultProps = {
    cashbackCouponText: undefined
};
