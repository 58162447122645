import { trackOmniture } from '@mmt/legacy-commons/Common/utils/OmnitureTrackerUtils';

class FlightHotelCrossSellTrackingHelper {
  trackLoadEvent(pageName, eventKey) {
    const params = {
      pageName,
      m_v44: `hotel_cross_sell_${eventKey}_shown`,
    };
    trackOmniture(pageName, params);
  }
  trackClickEvent(pageName, actionName) {
    const params = {
      pageName,
      m_c1: `hotel_cross_sell_${actionName}_clicked`,
    };
    trackOmniture(pageName, params);
  }
}
export default new FlightHotelCrossSellTrackingHelper();
