import { StyleSheet } from 'react-native';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import { getScreenWidth } from '../../../../utils/PostSaleUtil';
import { themeColors } from '../../../../PostSalesStyles';
const windW = getScreenWidth();

export default (theme, fonts) =>
  StyleSheet.create({
    topStickySection: {
      ...getPlatformElevation(2),
      backgroundColor: '#fff',
      zIndex: 10,
    },
    contentWrapper: {
      backgroundColor: '#e7e7e7',
      flex: 1,
    },
    cardWrapper: {
      width: windW,
      marginBottom: 10,
    },
    btnWrapper: {
      borderWidth: 1,
      borderColor: '#ccc',
      backgroundColor: '#fff',
      paddingHorizontal: 12,
      paddingVertical: 8,
      borderRadius: 4,
      marginRight: 8,
      marginBottom: 8,
    },
    btnWrapperActive: {
      borderColor: theme.color.linkTextColor,
    },
    btnText: {
      ...fonts.font14,
      fontFamily: fonts.regular,
      color: colors.defaultTextColor,
    },
    btnTextActive: {
      color: theme.color.linkTextColor,
    },
    halfSize: {
      height: 100,
      borderRadius: 4,
      marginBottom: 8,
    },
    fullSize: {
      height: 200,
      borderRadius: 4,
      marginBottom: 8,
    },
  });
