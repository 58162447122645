import React from 'react';
import { View, NativeModules, TouchableOpacity } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { Text } from 'react-native';
import { useTheme } from '../../../../../theme/theme.context';
import { getFont } from '../../../../../PostSalesStyles';
import getStyles, { getHtmlstyles } from './CabCancellationCardStyles';
import WhiteCard from '../WhiteCard';
import isEmpty from 'lodash/isEmpty';
import { getActionButton } from 'apps/post-sales/src/Common/commonUtil';
import { getPolicyData, handleClickEvent } from '../../../../utils/CabBookingDetailUtil';
import { CabCanellationCardProps } from '../../../../types';
import CabBookingDetailsConstant from '../../../../CabBookingDetailsConstant';
import CardWrapper from '../CardWrapper';
import CustomCardImage from '../CustomCardImage';
import { getGdprData, shouldShowGdprBlockerScreen } from '../../../../../utils/PostSaleUtil';
import { getStaticData } from '../../../../../staticData/staticData';
import HTMLView from 'react-native-htmlview';
import CabBookingTrackingHelper from 'apps/post-sales/src/cab/CabBookingTrackingHelper';
import HeadingComponent from '../HeadingComponent';

const CancellationAndModificationCard = ({
  response,
  trackPageName,
  card,
  handleBottomSheetOverlay,
}: CabCanellationCardProps) => {
  const { clientDetails } = response || {};
  const { cancellationDetails } = clientDetails || {};
  const { cancellationInfo, cancellationTagList } = cancellationDetails || {};
  const { psTheme } = useTheme();
  const { fsStyle, ...fonts } = getFont(true);
  const { color } = psTheme;
  const styles = getStyles(color, fonts);
  const { heading, subheading, cardId } = card || {};

  const handleClick = async (action: object) => {
    const { UserSessionModule } = NativeModules;
    const showGdprBlockerScreen = await shouldShowGdprBlockerScreen();

    if (showGdprBlockerScreen && action && action.actionFamily === CabBookingDetailsConstant.ACTION_MODIFY_BOOKING) {
      const { gdprText } = getStaticData(false);
      const { lobs: { cabs = 'Cabs'} = {} } = gdprText || {}
      UserSessionModule.openGDPRBlockerScreen(cabs, null, getGdprData(gdprText));
    } else {
      handleClickEvent(card, action, response, trackPageName);
    }
  };

  const onViewPolicyClick = () => {
    let clickEvent = CabBookingDetailsConstant.CLICK_CANCELLATION_POLICY;
    let heading = CabBookingDetailsConstant.cancellationPolicyText;
    if(cardId === CabBookingDetailsConstant.MODIFICATION_POLICY_CARD){
      clickEvent = CabBookingDetailsConstant.CLICK_MODIFICATION_POLICY;
      heading = CabBookingDetailsConstant.modificationPolicyText;
    }
    CabBookingTrackingHelper.trackClickEvent(trackPageName, clickEvent);
    handleBottomSheetOverlay(CabBookingDetailsConstant.CancellationPolicy, heading);
  };

  const { viewPolicyText } = CabBookingDetailsConstant
  const policyData = getPolicyData(response);
  return (
    <React.Fragment>
      <View style={styles.container}>
        <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween]}>
        <HeadingComponent heading={heading} showIcon={true} containerStyle={styles.heading}/>
          {!!viewPolicyText && !isEmpty(policyData) && (
            <TouchableOpacity style={AtomicCss.alignItemEnd} onPress={onViewPolicyClick}>
              <Text style={styles.viewPolicyText}>{viewPolicyText}</Text>
            </TouchableOpacity>
          )}
        </View>
        {!!subheading && (
            <HTMLView value={subheading} stylesheet={getHtmlstyles(fonts, color)} />
        )}
      </View>
      <View>
        {getActionButton(
          card,
          response,
          handleClick,
          {},
          true,
          false,
          styles.buttonRadiusStyle,
          styles.customStyle,
        )}
      </View>
    </React.Fragment>
  );
};

export default CardWrapper(CancellationAndModificationCard);
