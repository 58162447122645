const GiftCardBookingDetailsConstant = {
    TOP_STATUS_CARD_COMPLETED: 'TopStatusCard',
    TOP_STATUS_CARD_CANCELLED: 'TopStatusCard_Cancelled',
    GIFTCARD_MAIN_CARD: 'GiftcardMainCard',
    GIFTCARD_PAYMENT_CARD: 'GiftcardPaymentCard',
    CANCELLATION_POLICY_CARD: 'CancellationPolicyCard',
    NEED_HELP_CARD: 'NeedHelpCard',
    REFUND_CARD: 'RefundCard',

    ACTION_VIEW_PAYMENT_BREAK_UP: 'ViewPaymentBreakup',
    ACTION_CALL_US: 'CallUs',
    ACTION_VIEW_FAQ: 'FrequentlyAskedQuestions',
    ACTION_VIEW_HOW_TO_REDEEM: 'HowToRedeem',

    RECTANGLE_PRIMARY_FULL_WIDTH: 'CapsuleFullBtnFill',
    RECTANGLE_FULL_BUTTON: 'RecFullBtn',
    RESEND_VOUCHER: 'ResendVoucher',

    CANCELLATION_POLICY: 'CANCELLATION POLICY',
    STILL_CANT_FIND_WHAT_YOU_NEED: 'STILL CAN’T FIND WHAT YOU NEED?',
    PAYMENT_BREAKUP: 'Payment Breakup',
    PRICE_BREAKUP: 'PRICE BREAKUP',
    WHATSAPP: 'WhatsApp',
    SMS: 'SMS',
    SHARE_LINK: 'SHARE LINK',
    COPY_LINK: 'Copy Link',
    RECIPIENT_NAME_EMAIL: 'Recipients Name & E-mail',
    YOUR_MESSAGE: 'Your Message',
    SENDER_EMAIL: 'Sender Email',
    RECIPIENT_NAME: 'Recipients Name',
    RECIPIENT_NUMBER: 'Recipients Mobile Number',
    RECIPIENT_ADDRESS: 'Recipients Address',
    GIFTCARD_SENT: 'Your gift card has been sent',
    GIFTCARD_FAILED: "Booking couldn't be completed.",
    WORTH: ' worth ',
    CROSS: ' x ',
    RESEND_VOUCHER_SUCCESS_TEXT: 'Voucher triggered successfully',
    RESEND_VOUCHER_FAILURE_TEXT: 'Please try again later!!',
    RESEND_GIFTCARD: 'RESEND_GIFTCARD',
    RESEND_GIFT_CARD_CTA_TEXT: 'RESEND',
    RESEND_GIFT_CARD_EDIT_CTA_TEXT: 'EDIT',
    RESEND_GIFT_CARD_DISMISS_CTA_TEXT: 'DISMISS',
    ENTER_EMAIL_ID: 'Please enter a valid email address',
    RESEND_GIFT_CARD_OVERLAY: 'ResendGiftCardOverlay',
    ORDER_TRACKING_TEXT: 'Tracking ID : ',
    COPY_TO_CLIPBOARD: 'Tracking Id Copied to Clipboard',
    
    RECIEPIENT_DETAILS: 'Recipient Details',
    RECIPIENT: 'Recipient',
    AMOUNT: 'Amount',
    GIFT_CARD_NUMBER: 'Gift Card Number',
    GIFT_CARD_DETAILS: 'Gift Card Details',
    RESEND: 'RESEND',
    VIEW_MORE: 'View More',
    DELIVERY_MODES:{
        EMAIL: 1,
        WHATSAPP: 2,
        COURRIER: 3
    },
    DEFAULT_SIZE_BEFORE_PRESSING_VIEW_MORE: 2

};

export default GiftCardBookingDetailsConstant;
