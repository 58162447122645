import React from 'react';
import { View, Text, Image} from 'react-native';
import styles from './ReqstConfirmOverlay/ReqstConfirmOverlayCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import agentIcon from '@mmt/legacy-assets/src/agentIcon.webp';
import Button from '../../Button';
import { NEED_MORE_HELP } from '../../../PostSalesConstant';
import { getImagePath } from '../../../utils/PostSaleUtil';

const succesTick = getImagePath('circular_green.webp');

const ErrorOverlay = (props) => {
        
    return (
        <View style={styles.OverlayContentWrapper}>
            <View style={AtomicCss.marginBottom16}>
                <View style={styles.overlayTopline}></View>
            </View>
            <View style={styles.agentImgWrapper}>
                <Image style={styles.agentIconStyle} source={agentIcon} />
            </View>
            <View style={styles.titleWrapper}>
                <Text style={styles.title}>{props.layoutData.headerText}<Image style={styles.successTickStyle} source={succesTick} /></Text>
            </View>
            <Text style={[AtomicCss.defaultText, AtomicCss.font14, AtomicCss.regularFont, AtomicCss.marginBottom26]}>{props.layoutData.subHeaderText}</Text>
            <View style={AtomicCss.marginBottom20}>
                <Button
                    btnType="flat"
                    btnBg="gradientBtn"
                    btnTxt={props.layoutData.ctaText}
                    fontSize="font14"
                    clickHandler={props.handleBottomOverlay}
                    btnClickAction={NEED_MORE_HELP.REQUEST_SCHEDULE}
                />
            </View>
        </View>
    );
}

export default ErrorOverlay;