import React from 'react'
import RadioButton from '@Frontend_Ui_Lib_App/RadioButton/lib/RadioButton';    
import { useTheme } from '../../../src/theme/theme.context';

const RadioWpr = ({label,isSelected,onPress,radioSize=18,customStyle,children,activeColorStyle}) => {
  const { psTheme } = useTheme();
  return (
         <RadioButton
            children={children}
            label={label}
            isSelected={isSelected}
            onPress={onPress}
            activeColor={activeColorStyle ? activeColorStyle : psTheme.color.primary}
            radioBgColor={psTheme.color.white}
            radioSize={radioSize}
            customStyle={customStyle}
        />
  )
}

export default RadioWpr