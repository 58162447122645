import React from 'react';
import PropTypes from 'prop-types';
import {View, Text, Image} from 'react-native';
import styles from './CabMamiCss';
import {mapImg2} from '../CabImageConstants';
import CardWrapper from '../modules/bookingDetails/components/CardWrapper';

class TrackYourCabCard extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <View style={[styles.card]}>
        <View style={[styles.rowCenter, styles.marginTop5]}>
          <Image source={mapImg2} style={styles.trackingIcon} />
          <View style={[styles.flexOne, styles.marginBottom8]}>
            <Text style={[{color: '#000'}, styles.font14, styles.blackFont, styles.marginBottom8]}>{this.props.response.clientDetails.trackCardHeading}</Text>
            <Text style={[{color: '#000'}, styles.font14, styles.fontRegular]}>{this.props.response.clientDetails.trackCardSubText}</Text>
          </View>
        </View>
      </View>
    );
  }
}

TrackYourCabCard.propTypes = {
  response: PropTypes.object.isRequired
};

export default CardWrapper(TrackYourCabCard);
