import React from 'react';
import { View, Text, TouchableOpacity, Image } from 'react-native';
import styles from './ContactlessCheckinCardCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { KEY_ICON, BLUE_RIGHT_ARROW_ICON} from '../../../HotelImageConstants';
import {ContactLessCheckinConstants} from '../../HotelBookingDetailsConstant';
import { handleContactLessCheckinClick } from '../../utils/HotelBookingDetailUtil';
import { getImageTransformStyling } from '../../../../utils/PostSaleUtil';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const ContactlessCheckinCard = ({ response, pageName }) => {
  const { fsStyle, ...fonts } = getFont(true);
	const {contactlessCheckin: contaclessCheckinFlag, contactlessCheckinResponse} = response || {};
	const {contactlessCheckin} = contactlessCheckinResponse || {};
	const { DOC_STATUS } = ContactLessCheckinConstants;

	if (!contaclessCheckinFlag || !contactlessCheckin) {
		return null;
	}
	
	let wrapperStyle, iconStyle;
	switch (contactlessCheckin.status) {
		case DOC_STATUS.PENDING:
		wrapperStyle = 'innerWrapperPending';
		break;
		case DOC_STATUS.DOC_UPLOADED:
		wrapperStyle = 'innerWrapperReview';
		break;
		case DOC_STATUS.APPROVED:
		wrapperStyle = 'innerWrapperApproved';
		break;
		case DOC_STATUS.REJECTED:
		iconStyle = 'iconWrapperRejected';
		break;
		default:
		wrapperStyle = 'innerWrapperPending';
  	}

  return (
		<TouchableOpacity onPress={() => handleContactLessCheckinClick(contactlessCheckin.url, contactlessCheckin.status, pageName, response)} style={[AtomicCss.whiteCardNew, AtomicCss.marginHz12, styles.paddingAll8, AtomicCss.borderRadius16]}>
			<View style={[styles.innerWrapper, styles[wrapperStyle]]}>
				<View style={[styles.iconWrapper, styles[iconStyle]]}>
					<Image source={KEY_ICON} style={styles.iconStyles} />
				</View>
				<View style={[AtomicCss.flexRow, AtomicCss.flex1, AtomicCss.alignCenter, AtomicCss.spaceBetween]}>
					<View style={[AtomicCss.flex1, AtomicCss.marginRight5]}>
						<Text style={[fsStyle.font14, fonts.blackFontFamily, AtomicCss.blackText, AtomicCss.lineHeight20, styles.marginBottom4, AtomicCss.alignLeft]}>{contactlessCheckin.title}</Text>
						<Text style={[fsStyle.font13, fonts.regularFontFamily, AtomicCss.midGreyText, AtomicCss.lineHeight20, AtomicCss.alignLeft]}>{contactlessCheckin.subTitle}</Text>
					</View>
					<View style={styles.arrowWrapper}>
						{contactlessCheckin.status !== DOC_STATUS.REJECTED &&
							<Image source={BLUE_RIGHT_ARROW_ICON} style={[styles.blueArrow, getImageTransformStyling()]} />
						}
					</View>
				</View>
			</View>
			{contactlessCheckin.status === DOC_STATUS.REJECTED &&
				<View>
					<View style={[AtomicCss.flexRow, AtomicCss.spaceBetween, styles.bottomLink]}>
						<Text style={[fonts.boldFontFamily, fsStyle.font14, AtomicCss.azure, AtomicCss.lineHeight20]}>{contactlessCheckin.buttonText}</Text>
						<Image style={[styles.blueArrow, getImageTransformStyling()]} source={BLUE_RIGHT_ARROW_ICON} />
					</View>
				</View>}
		</TouchableOpacity>
  );
};

export default ContactlessCheckinCard;