import React from 'react';
import {View, Text, StyleSheet} from 'react-native';
import { styles } from './CancelAndRebookStyles';
import {CancelAndRebookConstants} from './CancelAndRebookConstants';

class CabBreadCumb extends React.Component {
  render() {
    return (
      <View style={styles.breadCumbOuter}>
        <Text style={(this.props.state === CancelAndRebookConstants.EDIT_TEXT) ? (styles.activeBreadCumb) : (styles.nonActiveBreadcumb)}>{CancelAndRebookConstants.EDIT}</Text>
        <Text style={(this.props.state === CancelAndRebookConstants.SELECT_TEXT) ? (styles.activeBreadCumb) : (styles.nonActiveBreadcumb)}>{CancelAndRebookConstants.SELECT}</Text>
        <Text style={(this.props.state === CancelAndRebookConstants.REVIEW_TEXT) ? (styles.activeBreadCumb) : (styles.nonActiveBreadcumb)}>{CancelAndRebookConstants.REVIEW}</Text>
        <Text style={(this.props.state === CancelAndRebookConstants.COMMIT_TEXT) ? (styles.activeBreadCumb) : (styles.nonActiveBreadcumb)}>{CancelAndRebookConstants.COMMIT}</Text>
      </View>
    );
  }
}

export default CabBreadCumb;

