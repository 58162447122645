import {fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from '@mmt/legacy-commons/Common/Components/Card/getPlatformElevation';


const styles ={
  infoCardWrapper:{
    backgroundColor:'#fff',
    justifyContent:'space-between',
    flexDirection:'row',
    ...getPlatformElevation(2),
    marginBottom:1,
  },

  infoCardContent:{
      paddingLeft:8,
      paddingVertical:15,
      flex:1
  },

  infoCustom:{
    fontSize:14,
    color:'#4a4a4a',
    lineHeight:18,
    fontFamily:fonts.regular,
  },
  headingIconWrapper:{
    width:46,
    alignItems:'flex-start',
    top:14,
    left:16,
  },
  headingIconStyle:{
    width:24,
    height:24,
  },

  redirectBtnWrapper:{
    paddingTop:20,
    paddingBottom:20,
    paddingLeft:60,
    paddingRight:35,
    justifyContent:'space-between',
    flexDirection:'row',
    backgroundColor:'#fff',
    borderBottomWidth:1,
    borderBottomColor:'#ededed',
    alignItems:'center',

  },

  rightArrowIcon:{
    width:9,
    height:13
  },

  linkBtnTxt:{
    fontSize:14,
    fontFamily:fonts.bold,
    color:"#008cff"
  },
  buttonWrapper:{
    paddingTop:20,
    width:'90%'
  },
  doneIconStyle:{
    width:18,
    height:23,
    marginRight:15,
},
priceIconStyle:{
    width:18,
    height:23,
    marginRight:15,
},
useCaseTxt:{
    fontSize:12,
    fontFamily:fonts.regular,
    color:'#747474',
    flex:1
},
userIconStyle:{
  width:24,
  height:24,
  marginRight:10,
},
userCancelledWrapper:{
  borderBottomColor:'#e7e7e7',
  borderBottomWidth:1,
  paddingBottom:15,
  marginBottom:15,
},
refundStatusInfoTxt:{
  padding:10,
  borderRadius:4
},
statusInfoTxt:{
  fontSize:14,
  color:'#747474',
  fontFamily:fonts.bold
},
refundPaymentDtls:{
  borderBottomColor:'#e7e7e7',
  borderBottomWidth:1,
  paddingBottom:5,
  marginBottom:10,
},
paymentInnerSction:{
  flexDirection:'row',
  justifyContent:'space-between'
},
}


export default styles;
