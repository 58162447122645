/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';
import getStyles from '../../details/components/HotelMamiCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {
  isNotNullAndEmpty,
  showRoundToast,
} from '../../details/utils/HotelBookingDetailUtil';
import Counter from './Counter';
import AgeSection from './AgeSection';
import {
  HOTEL_DEFAULT_ICON,
} from '../../HotelImageConstants';
import { GUEST_TYPE, MOD_COUNTER_LIMITS } from '../HotelModificationConstants';
import { getStaticData } from '../../../staticData/staticData';
import { LANG_AWARE } from '../../HotelConstants';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const RoomCard = (props) => {
  const { fsStyle, ...fonts } = getFont(true);
  const styles = getStyles(fonts);
  const {
    roomDetail = {},
    onRoomDetailChange,
    modificationData,
    onRoomDeletion,
    heading,
    showRemoveDeleteOption = true,
  } = props;
  const [modifiedRoomDetail, setModifiedRoomDetail] = useState(roomDetail);

  const { removeText } = getStaticData(LANG_AWARE.MODIFICATION);

  useEffect(() => {
    if (roomDetail !== modifiedRoomDetail) {
      // Update Parent Component about roomDetail change
      onRoomDetailChange(modifiedRoomDetail);
    }
  }, [modifiedRoomDetail]);

  const onCounterClick = (roomNo, paxType, count, limitReached) => {
    const { warnings } = modificationData || {};
    if (limitReached === MOD_COUNTER_LIMITS.INC) {
      if (paxType === GUEST_TYPE.ADULT) {
        showRoundToast(warnings ? warnings.maxAdultCount : '');
      } else {
        showRoundToast(warnings ? warnings.maxChildCount : '');
      }
      return;
    } else if (limitReached === MOD_COUNTER_LIMITS.DEC) {
      if (paxType === GUEST_TYPE.ADULT) {
        showRoundToast(warnings ? warnings.minAdultCount : '');
      } else {
        showRoundToast(warnings ? warnings.minChildCount : '');
      }
      return;
    }
    if (paxType === GUEST_TYPE.ADULT) {
      setModifiedRoomDetail((roomDetail) => ({
        ...roomDetail,
        totalNumberOfAdults: count,
      }));
    } else {
      const childAgeList = [...modifiedRoomDetail.childAgeList];
      if (childAgeList.length > count) {
        //  change in length removes extra elements from the end.
        childAgeList.length = count;
      } else {
        const lenDiff = count - childAgeList.length;
        childAgeList.push(...Array(lenDiff).fill('0'));
      }
      setModifiedRoomDetail((roomDetail) => ({
        ...roomDetail,
        totalNumberOfChilds: count,
        childAgeList,
      }));
    }
    props.onCounterClick(roomNo, paxType, count);
  };

  const onAgeSelect = (roomNo, paxType, childAgeList) => {
    if (paxType === GUEST_TYPE.CHILD) {
      setModifiedRoomDetail((roomDetail) => ({
        ...roomDetail,
        childAgeList,
      }));
    }
    props.onAgeSelect();
  };

  const getRoomImageURI = () => {
    if (isNotNullAndEmpty(roomDetail.roomImageURL)) {
      return { uri: roomDetail.roomImageURL[0] };
    } else if (roomDetail.imageURL) {
      return { uri: roomDetail.imageURL };
    } else {
      return HOTEL_DEFAULT_ICON;
    }
  };

  const getMealPlanText = () => {
    let mealPlanText = roomDetail.mealPlan;
    if (mealPlanText.includes('&amp;')) {
      mealPlanText = mealPlanText.replace('&amp;', '&');
    }
    return mealPlanText;
  };

  const getRoomInfoSubtitle = (roomDetail) => {
    const { totalGuestBreakupText = '' } = roomDetail || {};
    return `${getMealPlanText()} • ${totalGuestBreakupText}`;
  };

  const addPassengerDetail = (roomDetail) => {
    const views = [];
    addPassengerDetailView(views, roomDetail, GUEST_TYPE.ADULT);
    addPassengerDetailView(views, roomDetail, GUEST_TYPE.CHILD);
    return views;
  };

  const addPassengerDetailView = (views, roomDetail, paxType) => {
    const {
      addGuestCardText: {
        additionalText: {
          addGuestAdultAgeLimitText, addGuestChildAgeLimitText
        }
      },
      adultsText,
      childrenText
    } = getStaticData(LANG_AWARE.MODIFICATION);
    const {
      minAdultCount,
      minChildCount,
      maxPaxCount,
      adultLabel = adultsText,
      childLabel = childrenText,
      adultAgeCriteria = addGuestAdultAgeLimitText,
      childAgeCriteria = addGuestChildAgeLimitText,
    } = modificationData;
    const passengerText = paxType === GUEST_TYPE.ADULT ? adultLabel : childLabel;
    const passengerAgeText = paxType === GUEST_TYPE.ADULT ? adultAgeCriteria : childAgeCriteria;

    views.push(
      <View style={[paxType === GUEST_TYPE.ADULT && AtomicCss.marginBottom20]}>
        <View
          key={paxType}
          style={[styles.flexRow, styles.alignCenter, styles.JustifySpaceBetween]}
        >
          <View style={styles.flex1}>
            <Text style={[styles.font16, styles.boldFont, styles.BlackText]}>{passengerText}</Text>
            {!!passengerAgeText && (
              <Text
                style={[
                  styles.font13,
                  styles.regularFont,
                  AtomicCss.midGreyText,
                  AtomicCss.marginTop5,
                ]}
              >
                {passengerAgeText}
              </Text>
            )}
          </View>
          <View>
            <Counter
              defaultState={
                paxType === GUEST_TYPE.ADULT
                  ? roomDetail.totalNumberOfAdults
                  : roomDetail.totalNumberOfChilds
              }
              minCounterLimit={paxType === GUEST_TYPE.ADULT ? minAdultCount : minChildCount}
              roomNo={roomDetail.roomNumber}
              paxType={paxType}
              onCounterClick={onCounterClick}
              maxPaxCount={maxPaxCount}
            />
          </View>
        </View>
      </View>,
    );
  };

  return (
    <View style={styles.roomCardWrapper}>
      <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween, AtomicCss.marginBottom12]}>
        <Text style={styles.roomCardHeading}>{heading}</Text>
        {showRemoveDeleteOption && (
          <TouchableOpacity onPress={() => onRoomDeletion(roomDetail.roomNumber)}>
            <Text style={[styles.font13, styles.blackFont, AtomicCss.azure, styles.lineHeight20]}>
              {removeText}
            </Text>
          </TouchableOpacity>
        )}
      </View>
      <View style={styles.roomCardDetailsWrapper}>
        <View style={AtomicCss.marginBottom20}>
          <View style={[styles.flexRow, styles.JustifySpaceBetween, styles.roomInfoWrapper]}>
            <View style={[AtomicCss.flex1]}>
              <Text
                style={[
                  fonts.blackFontFamily,
                  fsStyle.font17,
                  AtomicCss.blackText,
                  AtomicCss.marginBottom5,
                  AtomicCss.lineHeight24,
                ]}
              >
                {roomDetail.roomTypeName}
              </Text>
              <Text
                style={[
                  fonts.regularFontFamily,
                  fsStyle.font13,
                  AtomicCss.defaultText,
                  AtomicCss.lineHeight20,
                ]}
              >
                {getRoomInfoSubtitle(roomDetail)}
              </Text>
            </View>
            <Image source={getRoomImageURI()} style={styles.roomInfoImg} resizeMethod={'resize'} />
          </View>
        </View>
        {addPassengerDetail(roomDetail)}
        {!!modifiedRoomDetail.totalNumberOfChilds && (
          <View>
            <AgeSection
              modifiedRoomDetail={modifiedRoomDetail}
              onAgeSelect={onAgeSelect}
              ageModificationData={modificationData}
            />
          </View>
        )}
      </View>
    </View>
  );
};

RoomCard.propTypes = {
  onCounterClick: PropTypes.func.isRequired,
  onRoomDeletion: PropTypes.func.isRequired,
  roomDetail: PropTypes.object.isRequired,
  modificationData: PropTypes.object.isRequired,
  onRoomDetailChange: PropTypes.func.isRequired,
};

export default React.memo(RoomCard);
