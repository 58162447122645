import React from 'react';
import PropTypes from 'prop-types';
import {Text, TouchableOpacity, StyleSheet, View, Image} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import AtomicCss from '../../../Styles/AtomicCss';

class RecGradientBtn extends React.PureComponent {
  render() {
    return (
      <TouchableOpacity
        onPress={() => this.props.onPress()}
        disabled={this.props.disabled}
      >
        <LinearGradient
          start={{x: 1.0, y: 0.0}}
          end={{x: 0.0, y: 1.0}}
          colors={this.props.gradArray}
          style={[styles.RecBlueBtn, this.props.style]}
        >
          <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.justifyCenter]}>
            { this.props.btnImage &&
            <Image style={styles.buttonIcon} source={this.props.btnImage} />}
            <Text
              style={[AtomicCss.whiteText,
                  AtomicCss.font16,
                  AtomicCss.blackFont,
                AtomicCss.bgTransparent,
                this.props.textStyle
              ]}
              numberOfLines={this.props.numOfLines}
            >
              {this.props.label}
            </Text>
          </View>
        </LinearGradient>
      </TouchableOpacity>
    );
  }
}

RecGradientBtn.propTypes = {
  label: PropTypes.string.isRequired,
  btnImage: Image.propTypes.source,
  onPress: PropTypes.func.isRequired,
  gradArray: PropTypes.array.isRequired,
  numOfLines: PropTypes.number,
  style: PropTypes.object,
  textStyle: PropTypes.object,
  disabled: PropTypes.bool
};
RecGradientBtn.defaultProps = {
  btnImage: null,
  numOfLines: 0,
  style: {},
  textStyle: {},
  disabled: false
};

const styles = StyleSheet.create({
  RecBlueBtn: {
    paddingVertical: 12,
    marginHorizontal: 10,
    borderRadius: 4,
    overflow: 'hidden',
    marginBottom: 10,
    marginTop: 10
  },
  buttonIcon: {width: 26, height: 26, marginRight: 10}
});

export default RecGradientBtn;
