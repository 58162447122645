import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';

const styles = {
  infoCardWrapper: {
    backgroundColor: '#fff',
    justifyContent: 'space-between',
    flexDirection: 'row',
    ...getPlatformElevation(2),
    marginBottom: 1,
  },

  infoCardContent: {
    paddingHorizontal: 8,
    paddingVertical: 15,
    flex: 1,
  },

  infoCustom: {
    fontSize: 14,
    color: '#4a4a4a',
    lineHeight: 18,
  },
  headingIconWrapper: {
    width: 46,
    alignItems: 'flex-start',
    top: 14,
    left: 16,
  },
  headingIconStyle: {
    width: 16,
    height: 20,
  },

  redirectBtnWrapper: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 60,
    paddingRight: 35,
    justifyContent: 'space-between',
    flexDirection: 'row',
    backgroundColor: '#fff',
    borderBottomWidth: 1,
    borderBottomColor: '#ededed',
    alignItems: 'center',
  },

  rightArrowIcon: {
    width: 9,
    height: 13,
  },

  linkBtnTxt: {
    color: '#008cff',
  },
  buttonWrapper: {
    paddingTop: 20,
    width: '90%',
  },
  ZCIconStyle: {
    width: 18,
    height: 24,
  },
  DBIconStyle: {
    width: 24,
    height: 24,
  },
  greenTxt: {
    color: '#1a7971',
    lineHeight: 18,
  },
  policySection: {
    marginTop: 15,
    marginBottom: 5,
    flexDirection: 'row',
  },
};

export default styles;
