import React, { useEffect } from 'react';
import { View, Image, Text, StyleSheet, TouchableOpacity } from 'react-native';
import Actions from '../../../navigation/postSalesNavigation';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getPlatformElevation from '@mmt/legacy-commons/Common/Components/Card/getPlatformElevation';
import { AGENT_CALL_ICON } from '../../../flights/FlightImageConstants';
import { isEmptyArray } from '../../commonUtil';
import { getStaticData } from '../../../staticData/staticData';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import PostSalesBookingTrackingHelper from '../../../PostSalesBookingTrackingHelper';
import { getFont } from '../../../PostSalesStyles';

const RaiseSupportRequestCard = ({
  tatBreachedText,
  tatBreachedActions,
  bookingId,
  requestNo,
  lobName,
  pageName,
  fonts,
  isLostBookingId,
}) => {
  const { fsStyle } = getFont(true);
  useEffect(() => {
    PostSalesBookingTrackingHelper.trackClickEvent(pageName, 'lostRefundRequestCardShown', bookingId);
  }, [pageName, bookingId]);


  const onRaiseRequest = (actionId) => {
    PostSalesBookingTrackingHelper.trackClickEvent(pageName, 'raiseLostRefundRequest', bookingId);
    if (actionId === 'RAISE_LOST_REFUND_TICKET') {
      const { raiseRefundTicketText } = getStaticData();
      Actions.refundOutOfTATForm({
        bookingId,
        requestNo,
        headerTitle: raiseRefundTicketText,
        lobName,
        formType: 'RTD',
        isLostBookingId,
      });
    }
  };
  return (
    <View style={styles.bodyDetails}>
      <View style={[styles.refundDetails, AtomicCss.alignCenter]}>
        <Image source={AGENT_CALL_ICON} style={styles.refundIcon} />
        <Text
          style={[
            fsStyle.font14,
            fonts.regularFontFamily,
            AtomicCss.defaultText,
            AtomicCss.alignCenter,
            {maxWidth: 100}
          ]}
        >
          {tatBreachedText}
        </Text>
        {!isEmptyArray(tatBreachedActions) &&
          tatBreachedActions.map((action) => (
            <TouchableOpacity onPress={() => onRaiseRequest(action.type)} style={styles.raiseReq}>
              <Text style={[fsStyle.font14, fonts.blackFontFamily, AtomicCss.azure, {maxWidth: 160}]}>{action.title}</Text>
            </TouchableOpacity>
          ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  bodyDetails: {
    paddingHorizontal: 12,
  },
  refundDetails: {
    ...getPlatformElevation(2),
    backgroundColor: colors.paleBlue,
    paddingHorizontal: 16,
    paddingVertical: 12,
    borderRadius: 8,
    overflow: 'hidden',
    marginBottom: 12,
    flexDirection: 'row',
  },
  raiseReq: {
    marginLeft: 5,
  },
  refundIcon: {
    width: 28,
    height: 28,
    marginRight: 12,
  },
});

export default RaiseSupportRequestCard;
