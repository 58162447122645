import { StyleSheet } from 'react-native';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';

export default (theme) =>
  StyleSheet.create({
    contactUsCard: {
      backgroundColor: theme.color.white,
      borderRadius: 16,
      marginHorizontal: 12,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 12,
      padding: 12,
      ...getPlatformElevation(2),
    },
    contactUs: {
      width: 28,
      height: 42,
      marginRight: 10,
    },
    rightBlueArrow: {
      width: 16,
      height: 16,
      marginRight: 10,
    },
    cardWrapper: {
      backgroundColor: theme.color.white,
      borderRadius: 10,
      marginHorizontal: 12,

      padding: 16,
      ...getPlatformElevation(2),
      marginBottom: 16,
    },
    contactCard: {
      backgroundColor: theme.color.white,
      borderRadius: 10,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
  });
