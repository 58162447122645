import React from 'react';
import PropTypes from 'prop-types';
import {
  getRoomInfo,
  getRoomInfoIterable,
  isIntlHotelBooking,
} from '../../details/utils/HotelBookingDetailUtil';
import RoomCard from './RoomCard';
import HotelBookingTrackingHelper from '../../HotelBookingTrackingHelper';
import { ADDGUEST_PLUS_MINUS_CLICK, AGE_SELECT, REMOVE_ROOM } from '../HotelModificationConstants';
import { showLongToast } from '@mmt/legacy-commons/Common/Components/Toast';
import { getStaticData } from '../../../staticData/staticData';
import { LANG_AWARE } from '../../HotelConstants';

class AddGuestCard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onRoomDeletion = this.onRoomDeletion.bind(this);
    this.onRoomDetailChange = this.onRoomDetailChange.bind(this);
    this.onCounterClick = this.onCounterClick.bind(this);
    this.onAgeSelect = this.onAgeSelect.bind(this);
    const { roomDetails } = this.props.response.hotelBookingInfo;
    this.state = {
      roomInfoIterable: getRoomInfoIterable(roomDetails),
    };
    this.roomCount = this.state.roomInfoIterable.length;
    this.trackingPageName = '';
    this.origRoomDetails = this.props.response.hotelBookingInfo.roomDetails.map((room) =>
      this.getRoomCardProps(room),
    );
  }

  componentDidMount() {
    this.trackingPageName = isIntlHotelBooking(this.props.response?.lobCode)
    ? 'Mob:customer support:Hotel International:AddGuest:AdditionalGuestsChosen'
    : 'Mob:customer support:Hotel Domestic:AddGuest:AdditionalGuestsChosen';
  }

  componentDidUpdate() {
    this.props.onRoomListDetailsChange(this.state.roomInfoIterable);
  }

  render() {
    const { roomNoText } = getStaticData(LANG_AWARE.MODIFICATION)
    return (
      <>
        {this.state.roomInfoIterable.map(({ roomno }, index) => {
          const headingText = roomNoText.replace('{room_no}', index + 1);
          const origRoomDetails = this.origRoomDetails.find(
            ({ roomNumber }) => roomNumber == roomno,
          );
          return (
            <RoomCard
              key={origRoomDetails.roomNumber}
              onCounterClick={this.onCounterClick}
              roomDetail={origRoomDetails}
              onRoomDeletion={this.onRoomDeletion}
              modificationData={this.props.modificationData}
              onRoomDetailChange={this.onRoomDetailChange}
              showRemoveDeleteOption={this.roomCount > 1}
              heading={headingText}
              onAgeSelect={this.onAgeSelect}
            />
          );
        })}
      </>
    );
  }

  getRoomCardProps(room) {
    const {
      roomNumber,
      totalNumberOfAdults,
      totalNumberOfChilds,
      roomImageURL,
      roomTypeName,
      mealPlan,
      imageURL,
      childAgeList,
      totalGuestBreakupText,
    } = room;
    return {
      roomNumber,
      totalNumberOfAdults,
      totalNumberOfChilds,
      roomImageURL,
      roomTypeName,
      mealPlan,
      imageURL,
      childAgeList,
      totalGuestBreakupText,
    };
  }

  onRoomDeletion(roomNo) {
    HotelBookingTrackingHelper.trackClickEvent(
      this.trackingPageName,
      `${REMOVE_ROOM}_${roomNo + 1}`,
      this.props.response,
    );
    if (this.roomCount <= 1) {
      showLongToast('Cannot remove all rooms, use full cancellation option instead.');
      return;
    }
    this.roomCount--;
    const roomInfoIterable = this.state.roomInfoIterable.filter(({ roomno }) => roomno !== roomNo);
    this.setState({ roomInfoIterable });
  }

  onRoomDetailChange(roomDetails) {
    const roomInfoIterable = this.state.roomInfoIterable.slice();
    const index = roomInfoIterable.findIndex(({ roomno }) => roomno == roomDetails.roomNumber);
    roomInfoIterable[index] = getRoomInfo(roomDetails);
    this.setState({ roomInfoIterable });
  }

  onCounterClick() {
    HotelBookingTrackingHelper.trackClickEvent(
      this.trackingPageName,
      ADDGUEST_PLUS_MINUS_CLICK,
      this.props.response,
    );
  }

  onAgeSelect() {
    HotelBookingTrackingHelper.trackClickEvent(
      this.trackingPageName,
      AGE_SELECT,
      this.props.response,
    );
  }
}

AddGuestCard.propTypes = {
  response: PropTypes.object.isRequired,
  modificationData: PropTypes.object.isRequired,
  onRoomListDetailsChange: PropTypes.func.isRequired,
};

export default AddGuestCard;
