import React from 'react';
import { Dimensions, Text, View, StyleSheet, Image } from 'react-native';
import { useTheme } from '../../../../../theme/theme.context';
import { getFont } from '../../../../../PostSalesStyles';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import ButtonWrapper from 'apps/post-sales/src/Common/ButtonWrapper';
import CabBookingDetailsConstant from 'apps/post-sales/src/cab/CabBookingDetailsConstant';
import { ErrorPageProps, Fonts, Theme } from 'apps/post-sales/src/cab/types';

const ErrorPage: React.FC<ErrorPageProps> = ({ heading, subHeading, imageUrl, retryHandler }) => {
  const height = Dimensions.get('screen').height;
  const { psTheme } = useTheme();
  const { fsStyle, ...fonts } = getFont();
  const styles = getStyle(fonts, psTheme);

  return (
    <View style={[AtomicCss.alignCenter, AtomicCss.justifyCenter, styles.paddingAll30, { height }]}>
      <Image source={imageUrl} style={styles.calendarIconStyle} />
      {!!heading && (
        <Text
          style={[
            styles.headingText,
            AtomicCss.textCenter,
            AtomicCss.marginBottom12,
            AtomicCss.lineHeight20,
          ]}
        >
          {heading}
        </Text>
      )}
      {!!subHeading && <Text style={styles.subHedingText}>{subHeading}</Text>}
      {retryHandler && typeof retryHandler === "function" && (
        <ButtonWrapper
          clickHandler={retryHandler}
          btnText={CabBookingDetailsConstant.TRY_AGAIN}
          btnWrpStyle={styles.buttonWrapStyle}
          btnTxtStyle={styles.btnTextStyle}
        />
      )}
    </View>
  );
};

const getStyle = (fonts: Fonts, theme: Theme) => {
  return StyleSheet.create({
    paddingAll30: {
      padding: 30,
    },
    calendarIconStyle: {
      width: 127,
      height: 115,
      marginBottom: 20,
    },
    headingText: {
      fontSize: fonts.fontSize.font20,
      ...fonts.boldFontFamily,
      color: theme.color.black,
    },
    subHedingText: {
      fontSize: fonts.fontSize.font14,
      ...fonts.regularFontFamily,
      color: theme.color.defaultTextColor,
    },
    buttonWrapStyle: {
      paddingVertical: 15,
      backgroundColor: theme.color.skyBlue9,
    },
    btnTextStyle: {
      fontSize: fonts.fontSize.font16,
      ...fonts.boldFontFamily,
    },
  });
};

export default ErrorPage;