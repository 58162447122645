import React from 'react';
import PropTypes from 'prop-types';
import {Text, View} from 'react-native';
import getStyles from './HotelMamiCss';
import {
  appendCurrencySymbol, getActionButton, getButtonStyle, handleClickEvent,
  isNotNullAndEmpty, getCardNoFromPayment
} from '../utils/HotelBookingDetailUtil';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

class PAHXPayNowPersuasionCard extends React.Component {
  constructor(props) {
    super(props);
    this.fonts = getFont(true);
    this.styles = getStyles(this.fonts);
  }
  render() {
    const {paymentSummary} = this.props.response;
    if (this.props.response.ispahx && this.props.response.paymentDetails.amountDue > 0) {
      return (
        <View style={[this.styles.MamiCardGrey, this.styles.mar10Bt, this.styles.paddingHz12]}>
          <View style={[this.styles.impInfoGreenOuter, this.styles.mar10Bt]}>
            <Text style={this.styles.impInfoGreen}><Text style={this.styles.boldFont}>{this.props.response?.pahPersuationInfo || `HATE TO CARRY CASH OR
              CARD TO HOTEL?`}
            </Text>
            </Text>

            <Text style={{fontSize: 11}}>You can pay the pending amount
                of {this.getAmountDue(paymentSummary)} in one click using the credit card {getCardNoFromPayment(this.props.response.paymentDetails)} authorized at time of booking.
            </Text>

          </View>
          <View
            style={isNotNullAndEmpty(this.props.card.actionList) ? getButtonStyle(this.props.card.actionList) : this.styles.hideView}
          >
            {getActionButton(this.props.card, this.props.response, this.handleClick)}
          </View>
        </View>
      );
    }
    return null;
  }

  handleClick = (action) => {
    handleClickEvent(this.props.card, action, this.props.response, this.props.pageName);
  };

  getAmountDue(paymentSummary) {
    if (this.props.response.paymentDetails.amountDueLcy > 0) {
      return appendCurrencySymbol(paymentSummary.currencyCode, Math.round(this.props.response.paymentDetails.amountDueLcy), 0);
    }
    return 0;
  }
}


PAHXPayNowPersuasionCard.propTypes = {
  response: PropTypes.object.isRequired,
  card: PropTypes.object.isRequired,
  pageName: PropTypes.string.isRequired
};

export default PAHXPayNowPersuasionCard;
