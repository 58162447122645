import React from 'react';
import {View, StyleSheet, Animated, Easing} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

export default class Shimmer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      fadeAnim: new Animated.Value(0.4),
      marginLeft: new Animated.Value(-100)
    };
  }

  componentDidMount() {
    this.animate();
  }

  animate() {
    Animated.loop(
      Animated.parallel([
        Animated.timing(this.state.fadeAnim, {
          toValue: 1,
          duration: 1500,
          easing: Easing.linear
        }),
        Animated.timing(this.state.marginLeft, {
          toValue: 200,
          duration: 1500,
          easing: Easing.linear
        })
      ]))
      .start();
  }

  render() {
    let {fadeAnim, marginLeft} = this.state;
    return (
      <Animated.View
        style={[styles.animatedInner, {
          opacity: fadeAnim,
          marginLeft: marginLeft.interpolate({
            inputRange: [0, 1],
            outputRange: ['0%', '1%']
          })
        }]}
      >
        <LinearGradient
          start={{
            x: 1.0,
            y: 0.0
          }}
          end={{
            x: 0.0,
            y: 1.0
          }}
          colors={['#f3f4f7', '#f3f4f7']}
        />
      </Animated.View>
    );
  }
}
const styles = StyleSheet.create({
  animatedInner: {
    width: '70%',
    height: '100%',
    backgroundColor: '#f0eeee'

  }
});
