import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import isEmpty from 'lodash/isEmpty';
import { getFont } from '../../../../../PostSalesStyles';

const LayOverConnector = (props) => {
  const { fsStyle, ...fonts } = getFont(true);
  return (
    <View style={[styles.layoverWrapper]}>
      <View style={styles.horiZontalLine}></View>
      <View style={styles.layoverDtls}>
        <Text
          style={[
            AtomicCss.defaultText,
            fonts.regularFontFamily,
            fsStyle.font12,
            AtomicCss.textCenter,
          ]}
        >
          <Text
            style={[
              styles.layOverTxt,
              fonts.boldFontFamily,
              fsStyle.font12,
              AtomicCss.lineHeight18,
            ]}
          >
            {props.time}
          </Text>{' '}
          • {props.msg}
        </Text>
        {!isEmpty(props.additionalInfo) && (<Text
          style={[
            AtomicCss.defaultText,
            fsStyle.font12,
            AtomicCss.textCenter
          ]}
        >
          {props.additionalInfo}
        </Text>)}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  layoverWrapper: {
    marginTop: 15,
  },
  layoverDtls: {
    borderColor: '#d5d5d5',
    paddingHorizontal: 15,
    borderWidth: 1,
    borderRadius: 50,
    backgroundColor: '#fff',
    marginHorizontal: 15,
    alignItems: 'center',
    paddingVertical: 12,
  },
  horiZontalLine: {
    height: 1,
    backgroundColor: '#d5d5d5',
    width: '100%',
    position: 'absolute',
    top: '49%',
  },
  layOverTxt: {
    color: '#000',
    flexWrap: 'wrap',
    marginBottom: 5,
  },
  layoverInfoTxt: {
    flexDirection: 'row',

    // flexWrap:'wrap',
    textAlign: 'center',
  },
});

export default LayOverConnector;
