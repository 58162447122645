import { PanDetailState } from './types';

export default {
    VALIDATING_PAN: 'validating_pan',
    SET_PAN_VERIFY_ERROR: 'set_pan_verify_error',
    SET_PAN_VERIFIED: 'set_pan_verified',
    RESET_PAN_VERIFIED: 'reset_pan_verified',
    TOGGLE_CHECKBOX: 'toggle_checkbox',
    PAN_SUBMIT_LOADING: 'pan_submit_loading',
    PAN_SUBMIT_SUCCESS: 'pan_submit_success',
    PAN_SUBMIT_ERROR: 'pan_submit_error',
    CHANGE_PAN: 'change_pan',
    CHANGE_PAN_DETAIL: 'ChangePanDetail',
    SUBMIT_PAN_DETAIL: 'SubmitPanDetail',
};

export const PAN_INITIAL_STATE: PanDetailState = {
    isChecked: false,
    isPanVerified: false,
    showPanEditor: false,
    isPanVerifing: false,
    panNumber: undefined,
    panSubmitMsg: undefined,
    errorText:undefined,
    panHolderName:undefined,
};
