import React from "react";
import { Text, View, Image, TouchableOpacity } from "react-native";
import CommonBottomOverlayMessage from "../../../Common/CommonOverlayMessage/CommonBottomOverlayMessage";
import { HOTEL_DETAILS_BOTTOM_OVERLAY } from "../HotelBookingDetailsConstant";
import { CROSS_ICON } from "../../HotelImageConstants";
import getStyles from "../components/HotelMamiCss";
import TCSBottomOverlay from "../../../Common/TCSBottomOverlay/TCSBottomOverlay";
import AtomicCss  from '@mmt/legacy-commons/Styles/AtomicCss';
import  isEmpty from "lodash/isEmpty";
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

export const showTCSModificationOverlay = (bottomOverlay, responseJson, handleOpenBottomOverlay, handleCloseBottomOverlay, color)=> {
    if (bottomOverlay === HOTEL_DETAILS_BOTTOM_OVERLAY.TCS_MODIFICATION_OVERLAY) {
      const {tcsNote} = responseJson || {};
      const {modification} = tcsNote || {};
      const {fsStyle, ...fonts} = getFont();
      const styles = getStyles(fonts);
      if (modification){
        const {title, text, ctaText} = modification;
        return (
          <CommonBottomOverlayMessage
            isBorderRadius
            dismiss={handleCloseBottomOverlay}
            content = {
            <View style={{color: color.white}}>
            <View style={styles.topLine}></View>
              <View style={[styles.heading, AtomicCss.flexRow, AtomicCss.alignCenter]}>
                  <TouchableOpacity style={styles.crossIconWrapper} onPress={handleCloseBottomOverlay}>
                      <Image style={styles.crossIconStyle} source={CROSS_ICON} />
                  </TouchableOpacity>
                  {!isEmpty(title) && <Text style={[fonts.boldFontFamily, fsStyle.font16, {color: color.black}]}>{title}</Text>}
              </View>
              <View style={styles.bodySection}>
                  {!isEmpty(text) && <Text style={[{color: color.defaultTextColor}, fsStyle.font14, fonts.regularFontFamily , AtomicCss.marginBottom15]}>{text}</Text>}
                  {!isEmpty(ctaText) && <Text style={[{color: color.azure}, fsStyle.font14, fonts.boldFontFamily]} onPress={() => handleOpenBottomOverlay('TCS_RULES_OVERLAY')}>{ctaText}</Text>}
            </View>
            </View>}
            isCancellable
          />
        );
      }
      else {
        return null;
      }
    }
    return null;
  }
  
  export const showTCSRulesOverlay = (bottomOverlay, responseJson, handleCloseBottomOverlay )=> {
    if (bottomOverlay === HOTEL_DETAILS_BOTTOM_OVERLAY.TCS_RULES_OVERLAY) {
    const {tcsNote} = responseJson || {};
    const {modification} = tcsNote || {};
    if (modification) {
      return (<CommonBottomOverlayMessage
        isBorderRadius
        dismiss={handleCloseBottomOverlay}
        content={<TCSBottomOverlay tcsPopupInfo={modification.popup} onClose={handleCloseBottomOverlay} />}
        isCancellable />);
      }
    }
    return null;
  }