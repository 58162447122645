import React, {useEffect} from 'react';
import { View, Text, StyleSheet, Image, TouchableOpacity } from 'react-native';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import isEmpty from 'lodash/isEmpty'
import { Fonts, InternationalCabData, Theme } from 'apps/post-sales/src/cab/types';
import { openUrl } from '../../../../CabImageConstants';
import { CLICK_EVENT, PAGE_NAME } from '../../../../CabBookingDetailsConstant';
import CabBookingTrackingHelper from '../../../../CabBookingTrackingHelper';
import Actions from '../../../../../navigation/postSalesNavigation';

interface Props {
  internationalCabData: InternationalCabData;
}

const InternationalCabTrackCard = ({ internationalCabData }: Props) => {
  const { psTheme } = useTheme();
  const { fsStyle, ...fonts } = getFont();
  const styles = getStyles(psTheme, fonts);
  if(isEmpty(internationalCabData)) return null;
  const { followLinkText, trackHereText, trackLink } = internationalCabData;

  useEffect(()=>{
    CabBookingTrackingHelper.trackClickEvent(PAGE_NAME.TRIP_DETAILS, CLICK_EVENT.VENDOR_TRANSFER_URL_SHOW);
  }, [])

  const trackHereClick = () => {
    CabBookingTrackingHelper.trackClickEvent(PAGE_NAME.TRIP_DETAILS, CLICK_EVENT.VENDOR_TRANSFER_URL_CLICK);
    if(!!trackLink){
      Actions.openPsWebView({
        url : trackLink
      });
    }
  }

  return (
    <View style={styles.cabBookedInfoSection}>
      <View style={styles.followTextWrapp}>
     {!!followLinkText && <Text style={styles.followText}>{followLinkText}</Text>}
     </View>
    <TouchableOpacity onPress={trackHereClick} style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.paddingHz15]}>
    <Image
      source={openUrl}
      style={styles.linkImg}
    />
    {!!trackHereText && <Text style={styles.trackHereText}>{trackHereText}</Text>}
    </TouchableOpacity>
    </View>
  );
};

const getStyles = (theme: Theme, fonts: Fonts) => {
  const { color } = theme;
  return StyleSheet.create({
    cabBookedInfoSection: {
      margin: 2,
      padding: 14,
      backgroundColor: color.smokeWhite,
      borderBottomLeftRadius: 14,
      borderBottomRightRadius: 14,
      flexDirection: 'row'
    },
    followText : {
      ...fonts.boldFontFamily,
      color : color.textGrey,
      fontSize: fonts.fontSize.font1
    },
    followTextWrapp : {
      width: '60%'
    },
    linkImg : {
      width: 20,
      height: 20,
      marginRight: 2
    },
    trackHereText: {
      ...fonts.boldFontFamily,
      color : color.azure,
      fontSize: fonts.fontSize.font14
    }
  });
};


export default InternationalCabTrackCard;
