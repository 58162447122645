import { StyleSheet } from "react-native";
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
export default (theme, fonts) =>StyleSheet.create({
whiteCard: {
    ...getPlatformElevation(2),
    backgroundColor: theme.color.white,
    borderRadius: 8,
    paddingHorizontal: 16,
    paddingVertical: 20,
    marginBottom: 12,
    marginTop : 12,
    marginLeft :10,
    marginRight : 10
},
pedZero:{
    paddingHorizontal:0,
    paddingVertical:0,
},
 downloadIconStyle: {
    width: 24,
    height: 24,
    marginRight: 10,
  },
  invoiceBtn: {
    paddingTop: 5,
    paddingBottom : 5,
    backgroundColor: theme.color.lighterBlue,
    borderRadius: 8,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 10,
    marginTop:12
  },
  btnTxt: {
    color: theme.color.azure,
    fontSize: 12,
    ...(fonts.boldFontFamily),
  }
});
