import React, { FC, MouseEventHandler } from 'react';
import { TouchableOpacity, View, Text, Clipboard, Image, Linking } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss'
import styles from '../GiftCardDetailsCss';
import { getFont } from '../../PostSalesStyles';
import Button from '../../Common/Button';
import { showShortToast } from '@mmt/legacy-commons/Common/Components/Toast';
import { validateTrackingDetails } from '../GiftCardBookingDetailUtil';
import GiftCardBookingDetailsConstant from '../GiftCardBookingDetailsConstant';
import { BLUE_COPY_TEXT_ICON, ORDER_TRACKING_INFO_ICON } from '../GiftCardImageConstants';

interface TrackingDetailsInfo {
  trackingDetails: {
    isTracking: boolean;
    trackingUrl: string;
    trackingId: string;
    trackingText: string;
    text: string;
    icon: string;
  };
}

const TrackingDetailsCard: FC<TrackingDetailsInfo> = ({ trackingDetails }) => {
  const { ...fonts } = getFont();
  const { isTracking, trackingUrl, trackingId, trackingText, text, icon } = trackingDetails;

  const copyTrackingId = () => {
    Clipboard.setString(trackingId);
    showShortToast(GiftCardBookingDetailsConstant.COPY_TO_CLIPBOARD);
  };

  const openTrackingPage = () => {
    if (validateTrackingDetails(trackingUrl)) {
      Linking.canOpenURL(trackingUrl).then((supported) => {
        supported && Linking.openURL(trackingUrl);
      }, err => console.log(err));
    } 
  };

  return (
    <View style={styles.detailsInfoSection}>
      { validateTrackingDetails(isTracking) && isTracking === true ?
        <>
          <Text style={[fonts.black, fonts.font14, fonts.blackFontFamily, AtomicCss.marginBottom3]}> {text}</Text>

          <View style={[AtomicCss.marginTop5]}>
            <Button btnTxt={trackingText}
              btnBg="whiteWithBorder"
              fontSize={fonts.font14}
              btnType="flat"
              btnClickAction='true'
              clickHandler={openTrackingPage}
            />
          </View>
          <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.alignSelfCenter, AtomicCss.marginTop5]}>
            <Text style={[AtomicCss.defaultText, fonts.font12, fonts.regular]}> {GiftCardBookingDetailsConstant.ORDER_TRACKING_TEXT} <Text style={[fonts.bold]}> {trackingId}</Text> </Text>
            <TouchableOpacity onPress={copyTrackingId}>
              <Image style={styles.copyIconStyle} source={BLUE_COPY_TEXT_ICON} />
            </TouchableOpacity>
          </View>

        </>

        : <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
          <View style={[AtomicCss.marginRight10]}>
            <Image style={styles.orderTrackIconStyle} source={ORDER_TRACKING_INFO_ICON} />
          </View>
          <Text style={[AtomicCss.flex1, fonts.blackFontFamily, fonts.font14]}> {text} </Text>
        </View>

      }
    </View>
  );
};

export default TrackingDetailsCard;