import { StyleSheet } from 'react-native';

const createStyles = (fonts, theme) =>
  StyleSheet.create({
    headingText: {
      fontSize: fonts.fontSize.font14,
      ...fonts.boldFontFamily,
      color: theme.color.black,
      marginBottom: 6,
    },
    descriptionText: {
      fontSize: fonts.fontSize.font12,
      color: theme.color.defaulttextColor,
      ...fonts.regularFontFamily,
      marginBottom: 10,
    },
    rowSection: {
      marginTop: 12,
      flexDirection: 'row',
      alignItems: 'center',
    },
    contactIconStyle: {
      width: 32,
      height: 32,
      marginRight: 10,
    },
    contactValue: {
      ...fonts.blackFontFamily,
      fontSize: fonts.fontSize.font14,
      color: theme.color.black,
    },
  });
export default createStyles;
