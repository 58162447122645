import { ImageStyle, StyleSheet, TextStyle, ViewStyle } from "react-native"
import getPlatformElevation from 'apps/post-sales/src/Holiday/Components/getPlatformElevation';


interface Theme {
  color: {
    white: string;
    creamWhite: string;
    lightGrey3: string;
  };
}

interface Fonts {
  fontSize: { [key: string]: number };
  boldFontFamily: { [key: string]: object };
  regularFontFamily: { [key: string]: object };
}

interface Styles {
  cardWrapper: ViewStyle;
  limitText: ViewStyle;
  topSection: ViewStyle;
  iconWrapper: ViewStyle;
  tripGuaranty: ViewStyle;
  rightArrowStyle: ViewStyle;
  redirectLink: ViewStyle;
  section: ViewStyle;
  trackerHeading: ViewStyle;
}

export const createStyles = (theme: Theme, fonts: Fonts) => {
    return StyleSheet.create<Styles>({
        cardWrapper:{
            backgroundColor: theme.color.white,
            paddingVertical:20,
            paddingHorizontal:16,
            justifyContent:'space-between',
            marginTop:10,
            borderRadius: 8,
            ...getPlatformElevation(2)
        },
        limitText:{
          backgroundColor: theme.color.creamWhite,
          padding: 1
        },
        topSection: {
          paddingHorizontal: 12,
          paddingTop: 16,
          paddingBottom: 4,
          flexDirection: 'row',
        },
        iconWrapper: {
          width: 30,
          height: 20,
          marginRight: 3,
          top: 3,
        },
        tripGuaranty: {
          width: 28,
          height: 18,
        },
        rightArrowStyle: {
          width: 15,
          height: 15,
        },
        redirectLink: {
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingVertical: 20,
          alignItems: 'center',
          paddingLeft: 44,
          paddingRight: 20,
          borderTopWidth: 1,
          borderTopColor: theme.color.lightGrey3,
        },
        section: {
          paddingHorizontal: 44,
          paddingVertical: 16,
          flexDirection: 'row'
        },
        trackerHeading:{
          marginBottom:20,
        }
    })
}