import {getImagePath} from '../../utils/PostSaleUtil';
//postsales - TGS
 export const TGS_ICON = getImagePath('tgsIcon.webp');

 export const PAX_ICON = getImagePath('paxIcon.webp')
 export const OFFER_ICON = getImagePath('offerIcon.webp') 
 export const CARD_ICON = getImagePath('cardPayIcon.webp') 
 export const CONTACT_ICON = getImagePath('contactIconV2.webp') 
 export const REFRESH_ICON = getImagePath('refreshBlue.webp') 
 export const SHARE_ICON = getImagePath('share.png')  
 export const DOWNLOAD_ICON = getImagePath('downloadIconV2.webp')
 export const VOUCHER_OFFER = getImagePath('vouherOffer.png')
 export const DARK_GREEN_TICK = getImagePath('darkGreenTick.webp');
export const DARK_GREEN_INFO = getImagePath('infoIconV2.webp');
export const DARK_YELLOW_INFO = getImagePath('tgsSummaryInfo.png');
//Common


export const CALL_ICON = require('@mmt/legacy-assets/src/call-icon2.webp');
export const IOS_BACK_ARROW = require('@mmt/legacy-assets/src/backArrowBlackIOS.webp');
export const HEADER_BACK_ICON = require('@mmt/legacy-assets/src/trip_header_back_icon.webp');
export const BACK_ARROW = require('@mmt/legacy-assets/src/back.webp');
export const INFO_ICON = require('@mmt/legacy-assets/src/info_icon.webp');
export const RIGHT_ARROW  = require('@mmt/legacy-assets/src/ic_arrow.webp');
export const DOWN_ARROW  = require('@mmt/legacy-assets/src/down_arrow_blue.webp');
export const UP_ARROW  = getImagePath('ic_upArrow.png');
export const COPY_ICON  = require('@mmt/legacy-assets/src/copy_icon.webp');
export const DOWNLOAD_LINE_ICON  = require('@mmt/legacy-assets/src/downloadIcon.webp');



//lostId Icons

export const cancelledTgsIcon =  getImagePath('cancelledTgsIcon.webp')
export const failedTgsIcon =  getImagePath('failedTgsIcon.webp')