import React from 'react';
import {
  FlatList,
  Image,
  ScrollView,
  Text,
  View,
  Dimensions,
} from 'react-native';
import PropTypes from 'prop-types';
import Carousel from '../../../Common/Carousel/Carousel';
import  Actions  from '../../../navigation/postSalesNavigation';
import getStyles from './HotelMamiCss';
import {
  isIntlHotelBooking,
  isNotNullAndEmpty,
} from '../utils/HotelBookingDetailUtil';
import CommonHeader from '../components/CommonHeader';
import HotelBookingTrackingHelper from '../../HotelBookingTrackingHelper';
import { getStaticData } from '../../../staticData/staticData';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { HOTEL_DEFAULT_ICON, WHITE_CROSS_ICON } from '../../HotelImageConstants';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

export const DEVICE_WINDOW = Dimensions.get('screen');
const windowWidth = DEVICE_WINDOW.width;

class ViewHotelAmenities extends React.Component {
  static navigationOptions = {
    header: null,
  };

  constructor(props) {
    super(props);
    this.fonts = getFont(true);
    this.styles = getStyles(this.fonts);
    this.carouselSize = {
      width: windowWidth,
      height: 210,
    };
    this.state = {
      currentIndex: 1,
    };
  }

    render() {
      const staticData = getStaticData();
      const hotelDetailList = this.props.response.hotelBookingInfo.hotelDetailsList[0];
      return (
        <View style={this.styles.whitebg}>
          <CommonHeader
            headerText={hotelDetailList.name}
            imgSrc={WHITE_CROSS_ICON}
            backPressHandler={this.onBackPress}
          />
          <Carousel
            delay={3000}
            style={this.carouselSize}
            autoplay
            currentPage={0}
            onAnimateNextPage={page => this.assignIndex(page, hotelDetailList)}
          >
            {this.addHotelImages(hotelDetailList.hotelImageURLList)}
          </Carousel>
          <Text
            style={[isNotNullAndEmpty(hotelDetailList.hotelImageURLList) ? this.styles.showView : this.styles.hideView, this.styles.carouselCount]}
          >{this.state.currentIndex}/{isNotNullAndEmpty(hotelDetailList.hotelImageURLList) ? hotelDetailList.hotelImageURLList.length : 0}
          </Text>
          <Text style={[this.styles.blackFont, this.styles.DefaultText, this.styles.ScrolableHead, this.styles.transperantBgrnd, AtomicCss.alignLeft]}>
            {staticData.viewHotelAmenitiesText.additionalText.hotelAmenitiesCapsText}
          </Text>
          <ScrollView showsVerticalScrollIndicator={false}>
            <View style={this.styles.listOuter}>
              {this.checkForAmenitiesView(hotelDetailList.hotelAmenities)}
            </View>
          </ScrollView>
        </View>
      );
    }

  onBackPress = () => {
    Actions.pop();
  };

  componentDidMount() {
    const trackPageName = isIntlHotelBooking(this.props.response?.lobCode)
      ? 'mob:customer support:Hotel International:HotelAmenities'
      : 'mob:customer support:Hotel Domestic:HotelAmenities';
    HotelBookingTrackingHelper.trackLoadEvent(
      trackPageName,
      this.props.response,
    );
  }

  addHotelImages(hotelImageList) {
    const views = [];
    if (isNotNullAndEmpty(hotelImageList)) {
      hotelImageList.forEach((hotelImageUrl) => {
        views.push(
          <View style={this.carouselSize}>
            <Image
              style={this.styles.imgg}
              resizeMethod="resize"
              source={hotelImageUrl == null ? HOTEL_DEFAULT_ICON : {uri: hotelImageUrl}}
            />
          </View>,
        );
      });
    } else {
      views.push(
        <View style={this.carouselSize}>
          <Image
            style={this.styles.imgg}
            resizeMethod="resize"
            source={{
              uri: 'https://promos.makemytrip.com/MIMA/hotelDefault.jpg',
            }}
          />
        </View>,
      );
    }
    return views;
  }

  renderFlatList(views, hotelAmenitiesList) {
    views.push(
      <FlatList
        data={hotelAmenitiesList}
        ItemSeparatorComponent={() => <View style={{ borderBottomColor: '#ddd', borderBottomWidth: 1 }}></View>}
        renderItem={({ item: amenityItem }) => (
          <Text style={[this.styles.listItems, AtomicCss.alignLeft]}>{amenityItem}</Text>
        )}
      />,
    );
  }

  renderNoAmenitiesView(views) {
    const staticData = getStaticData();
    views.push(
      <Text style={this.styles.listItems}>
        {staticData.viewHotelAmenitiesText.additionalText.noAmenitiesAvailableText}
      </Text>,
    );
  }

  checkForAmenitiesView(hotelAmenities) {
    const views = [];
    if (isNotNullAndEmpty(hotelAmenities)) {
      this.renderFlatList(views, hotelAmenities);
    } else {
      this.renderNoAmenitiesView(views);
    }
    return views;
  }

  assignIndex(page, hotelDetailList) {
    if (isNotNullAndEmpty(hotelDetailList.hotelImageURLList)) {
      this.setState({ currentIndex: page + 1 });
    } else {
      this.setState({ currentIndex: 0 });
    }
  }
}

ViewHotelAmenities.propTypes = {
  response: PropTypes.object.isRequired,
};
export default ViewHotelAmenities;
