import React, { useState } from 'react';
import { Text, View, TouchableOpacity, Image } from 'react-native';
import map from 'lodash/map';
import createStyles from './styles';
import { SORT_STYLE_MAP } from '../../../../../constants';
import { useTheme } from '../../../../../../../../theme/theme.context';
import { getFont } from '../../../../../../../../PostSalesStyles';

const tickImg = require('@mmt/legacy-assets/src/TickActive.webp');

const FilterRow = (props) => {
  const fonts = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);

  const [selected, setSelected] = useState(props.selected);
  const [selectedItem, setSelectedItem] = useState(props.order);

  const selectFilter = (index) => {
    props.changeSorter(props.data.type, index);
    const selectedNew = selectedItem === index ? !selected : true;
    setSelected(selectedNew);
    setSelectedItem(index);
  };
  const { data } = props;
  return (
    <View style={styles.filterItem}>
      <View style={styles.leftView}>
        <View style={styles.SortIconWrapper}>
          <Image style={styles[SORT_STYLE_MAP[data.type]]} source={data.sortIcon} />
        </View>
        <Text style={styles.labelLarge}>{data.label}</Text>
      </View>
      <View style={styles.rightView}>
        {map(data.filterTypes, (label, index) => {
          const activeStyle =
            selectedItem === index && props.selected ? styles.labelSmallActive : {};
          return (
            <TouchableOpacity
              key={index}
              style={styles.actionRow}
              onPress={() => selectFilter(index)}
            >
              <Text style={[styles.labelSmall, activeStyle]}>{label}</Text>
              {selectedItem === index && props.selected && (
                <Image style={styles.tickIcon} source={tickImg} />
              )}
            </TouchableOpacity>
          );
        })}
      </View>
    </View>
  );
};
export default FilterRow;
