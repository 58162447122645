import React from 'react';
import isEmpty from 'lodash/isEmpty';
import {DeviceEventEmitter, Linking,Platform } from 'react-native';
import TGSBookingConstants, { CLICK_TYPE } from "./TGSBookingConstants";
import styles from '../styles/TripGuaranteeMamiCss';
import { openDialer } from '../../Common/commonUtil';
import { HELPLINE_NO } from '../../PostSalesConstant';
import Actions from '../../navigation/postSalesNavigation';
import TgsBookingModule from '@mmt/legacy-commons/Native/TgsBookingModule';
import RectFullButton from '../../Common/RectFullButton';
import { DOWNLOAD_ICON, RIGHT_ARROW } from './tripGuaranteeImageConstants';
import RectRoundIconBtn from '../../Common/RectRoundIconBtn'
import { getFont } from '../../PostSalesStyles';
import { useTheme } from '../../theme/theme.context';
import createDetailStyles from '../pages/details/indexCss'
import { proceedDownload } from '../../utils/PostSaleUtil';
  export function getActionButton(card, response, clickHandler, fontFamily) {
    const fonts = getFont()
    const {psTheme : theme} = useTheme();
    const styles = createDetailStyles(theme,fonts);
   const actionButtons = [];
   for (const action of card.actionList) {
     addActionButton(card, action, actionButtons, response, clickHandler, fontFamily,styles);
   }
   return actionButtons;
 }
 export function getButtonStyle(actionList) {
  if(isEmpty(actionList)) return [];
   const { actionType } = actionList[0];
   if (actionType === TGSBookingConstants.RECTANGLE_PRIMARY_HALF_WIDTH
     || actionType === TGSBookingConstants.RECTANGLE_SECONDARY_HALF_WIDTH) {
     return [styles.ActionBtnHalf, styles.transperantBgrnd];
   } else if (actionType === TGSBookingConstants.RECTANGLE_FULL_BUTTON) {
     return [styles.ActionBtnRec, styles.transperantBgrnd];
   }
   return [styles.ActionBtn, styles.transperantBgrnd];
 }

  function addActionButton(card, action, actionButtons, response, clickHandler, fontFamily,styles) {
   switch (action.actionType) {
     case TGSBookingConstants.RECTANGLE_FULL_BUTTON:
       actionButtons.push(<RectFullButton
         key={`RectFullButton${action.actionLabeltext}`}
         card={card}
         btnText={action.actionLabeltext}
         response={response}
         clickHandler={clickHandler}
         fontFamily={fontFamily}
         showTopBorder = {true}
         icon={RIGHT_ARROW}
       />);
       break;
       case TGSBookingConstants.DOWNLOAD_RECT_FULL_BUTTON:
        actionButtons.push(<RectRoundIconBtn
          key={`DownloadRectFullButton${action.actionLabeltext}`}
          btnText={action.actionLabeltext}
          handleClick={clickHandler}
          downloadIcon={DOWNLOAD_ICON}
          btnTextStyle={styles.btnTxt}
          btnStyle={styles.invoiceBtn}
          iconStyle={styles.downloadIconStyle}
        />);
        break;
     default:
       break;
   }
 }
 export async function handleTgsClickEvent(card, action, response) {
  switch (action.actionFamily) {
    case TGSBookingConstants.ACTION_PAYMENT_DETAILS_PAGE:
      Actions.tgsPriceBreakUp({
        bookingDetailsResponse: response,
      });
      break;
    default:
      break;
  }
}
export const voucherTnCHandler = (data) => {
  const { url, openInWebView, webViewTitle } = data || {};
  if (url && openInWebView) {
    Actions.openPsWebView({
      url,
      headerText: webViewTitle,
    });
  } else if (url) {
    Linking.openURL(url);
  }
};

 export function callHelpLine(){
   openDialer(HELPLINE_NO)
 }
 function setStatusText(pax,output){
  if (pax.CurrentStatusNew) {
    switch (pax.CurrentStatusNew) {
      case 'TDR':
        output.status = 'TDR';
        output.text  = pax.seatCompleteText;
        break;
      case 'CAN':
        output.status = 'CANCELLED';
        output.text  = 'CANCELLED';
        break;
      case String(pax.CurrentStatusNew.match(/.*WL.*/)):
        output.status = 'WAITING LIST';
        output.text  = pax.seatCompleteText;
        break;
      case String(pax.CurrentStatusNew.match(/.*RAC.*/)):
        output.status = 'RAC';
        output.text  = pax.seatCompleteText;
        break;
      case '':
        output.status = 'FAILED';
        output.text  = pax.seatCompleteText;
        break;
      case 'CNF':
        output.status = 'CONFIRMED';
        output.text  = pax.seatCompleteText;
        break;
      default:
        output.status = 'CONFIRMED';
        output.text  = pax.seatCompleteText;
        break;
    }
}
 }
 export function getCurrentPassengerStatus(pnrPaxResponse,passenger){
  let output = null;
  if (!isEmpty(pnrPaxResponse)) {
    pnrPaxResponse.PassengerStatus.forEach((pax) => {
      if (Number(pax.Number) === Number(passenger.paxId)) {
        output = {};
        setStatusText(pax,output);
      }
    });
  }
  return output;
 }

 export function downloadInvoice(response){
  const {headerInfo} = response || {};
    const {booking} = headerInfo || {};
    const {bookingID} = booking || {};
  if(!!bookingID){
    if(Platform.OS === 'web'){
      TgsBookingModule.downloadTickets(bookingID, TGSBookingConstants.TGS_INVOICE_TRIGGER)
    }
    else if(Platform.OS === 'android'){
      handleEventListner(response,TGSBookingConstants.TGS_INVOICE_TRIGGER,bookingID);
            TgsBookingModule.checkStoragePermission();

    }
  }
 }

 function handleEventListner(response, triggerName,bookingID) {
  const listener = DeviceEventEmitter.addListener(
    TGSBookingConstants.TGS_STORAGE_PERMISSION,
    (status) => {
      checkPermission(status, response, triggerName,getRequestBody(triggerName,bookingID));
      listener.remove();
    }
  );
}
function getRequestBody(triggerName,bookingId){
  return  {
    triggerName,
    bookingId
};
}
async function checkPermission(status, response, triggerName,requestBody){
  if (status.success) {
    switch (triggerName) {
      case TGSBookingConstants.TGS_INVOICE_TRIGGER:
        proceedDownload(response, triggerName, TGSBookingConstants.UNIQUE_ID_TYPE, TGSBookingConstants.DOWNLOAD_FAILED,requestBody);
        return;
      default:
        break;
    }
  }
}


export const getClickType = (key) => {
  switch(key){
      case 'pnrStatus':
          return CLICK_TYPE.PNR_STATUS_CLICK;
      case 'trainSchedule':
          return CLICK_TYPE.TRAIN_SCHEDULE_CLICK;
      case 'liveTrainStatus':
          return CLICK_TYPE.LIVE_TRAIN_STATUS_CLICK;
      case 'foodInTrain':
          return CLICK_TYPE.FOOD_IN_TRAIN_CLICK;
      case 'liveStation':
          return CLICK_TYPE.LIVE_STATION_CLICK;
      case 'coachPosition':
          return CLICK_TYPE.COACH_POSITION_CLICK;
      case 'trainAvail':
          return CLICK_TYPE.TRAIN_AVAILABLILITY_CLICK;
      case 'vacantChart':
          return CLICK_TYPE.VACANTCHART_CLICK;
      case "freeCancellation":
          return CLICK_TYPE.FREE_CANCELLATION_CLICK;
      case 'tripGuarantee':
          return CLICK_TYPE.TRIP_GUARANTEE_CLICK;
      case 'bookNowPayLater':
          return CLICK_TYPE.BUY_NOW_PAY_LATER_CLICK;
      case 'train':
          return CLICK_TYPE.BOOKTRAIN_CLICK;
      case 'cab':
          return CLICK_TYPE.BOOKCAB_CLICK;
      case 'bus':
          return CLICK_TYPE.BOOKBUS_CLICK;
      case 'flight':
          return CLICK_TYPE.BOOKFLIGHT_CLICK
  }
  return '';
}
