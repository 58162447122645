import {colors} from '@mmt/legacy-commons/Styles/globalStyles';

const styles = {
  MamiCardGrey: {
    backgroundColor: '#ddd',
    paddingLeft: 10,
    paddingRight: 10
  },
  CardContentFull: {
    backgroundColor: colors.white,
    padding: 15,
    paddingBottom: 0
  },
  CardContent: {
    backgroundColor: colors.white,
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 20
  },
  EmptyContent: {
    backgroundColor: colors.white,
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 100
  },
  EmptyContentBottom: {
    backgroundColor: colors.white,
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 30
  },
  CardIcon: {
    width: 24,
    height: 24,
    marginRight: 10
  },
  tdrHeadingIcon: {
    width: 60,
    height: 60,
    marginRight: 10
  },
  tdrHeadingContent: {
    backgroundColor: colors.white,
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: 20
  },
  marL10per: {paddingLeft: '10%'},
  maleIcon: {width: 16, height: 16, marginRight: 5},
  seatIcon: {width: 16, height: 20, marginRight: 5},
  confirmGreen: {color: '#00a19c'},
  waitlisted: {color: '#cf8100'},
  adTechCardView: {
    marginTop: 10
  }
};

export default styles;
