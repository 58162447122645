import React from 'react';
import {StyleSheet, View, Image, Text} from 'react-native';
import PropTypes from 'prop-types';
import {TotalDocIcon} from '../../HolidayBookingConstants';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';
import {fonts, colors} from '@mmt/legacy-commons/Styles/globalStyles';

export default function DocumentsPendingHeader(props) {
  const {documentPageDetails} = props;
  if (!documentPageDetails) {
    return null;
  }
  if (!Object.keys(documentPageDetails).length) {
    return null;
  }

  return (
    <View style={[styles.whiteCard, styles.innerSection, AtomicCss.marginBottom12]}>
      <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
        <Image style={styles.iconStyle} source={TotalDocIcon} />
        <View style={styles.statusTextInfo}>
          <Text style={[styles.statusTextInfo, AtomicCss.marginBottom10]}>
            {documentPageDetails.topHeaderText}
          </Text>
        </View>
      </View>
      <Text style={styles.moreInfo}>{documentPageDetails.topHeaderSubText}</Text>
    </View>
  );
}

DocumentsPendingHeader.propTypes = {
  documentPageDetails: PropTypes.shape({
    topHeaderText: PropTypes.string,
    topHeaderSubText: PropTypes.string
  }).isRequired
};

const styles = StyleSheet.create({
  whiteCard: {
    ...getPlatformElevation(2),
    backgroundColor: '#fff',
    marginBottom: 3
  },
  innerSection: {
    padding: 16
  },
  iconStyle: {
    width: 24,
    height: 24,
    marginRight: 10
  },
  moreInfo: {
    paddingLeft: 33,
    color: colors.black,
    fontSize: 14,
    fontFamily: fonts.regular
  },
  statusTextInfo: {
    color: '#cc7f01',
    fontSize: 16,
    fontFamily: fonts.bold
  },
  statusHrLine: {
    height: 3,
    width: 54,
    borderRadius: 2,
    backgroundColor: '#c6c6c6'
  },
  uploaded: {
    backgroundColor: '#cc7f01'
  }
});
