import PostSalesEventLogger from '../../PostSalesEventLogger';

export default class TripGuaranteeBookingEventsLogger {

    static loadEvent(pageName, eventData) {
        pageName = this.updateAction(pageName);
        PostSalesEventLogger.loadEvent(pageName, eventData);
    }

    static clickEvent(pageName, clickEvent, eventData) {
        pageName = this.updateAction(pageName);
        PostSalesEventLogger.clickEvent(pageName, clickEvent, eventData);
    }

    static errorEvent(pageName, eventData) {
        pageName = this.updateAction(pageName);
        PostSalesEventLogger.errorEvent(pageName, eventData);
    }

    static successEvent(eventName, eventData) {
        eventName = this.updateAction(eventName);
        PostSalesEventLogger.successEvent(eventName, eventData);
    }

    static failureEvent(eventName, eventData) {
        eventName = this.updateAction(eventName);
        PostSalesEventLogger.failureEvent(eventName, eventData);
    }

    static logAction(action, actionData) {
        action = this.updateAction(action);
        PostSalesEventLogger.logPostSalesAction(action, actionData);
    }

    static updateAction(action) {
        return `TRIP_GUARANTEE_${action}`;
    }
}