const RAIL_ROUTE_CONFIG = [
  {
    key: 'railBookingDetail',
    component: () => require('./details/RailBookingDetails').default
  },
  {
    key: 'railCancellationSelectPax',
    component: () => require('./cancellation/SelectTraveller').default
  },
  {
    key: 'reviewRefundCharges',
    component: () => require('./cancellation/ReviewRefundCharges').default
  },
  {
    key: 'reviewTGCharges',
    component: () => require('./tgPremiumReturn/PreviewTGCharges').default
  },
  {
    key: 'reviewTGRefundMode',
    component: () => require('./tgPremiumReturn/RefundMode/index').default
  },
  {
    key: 'commitTGCancel',
    component: () => require('./tgPremiumReturn/CommitTGCharges').default
  },
  {
    key: 'reviewRailRefundMode',
    component: () => require('./cancellation/RefundMode/RailRefundMode').default
  },
  {
    key: 'railBookingCancelled',
    component: () => require('./ErrorState/BookingCancel').default
  },
  {
    key: 'railPriceBreakUp',
    component: () => require('./RailPriceBreakUp/PaymentPriceBreakup').default
  },
  {
    key: 'railTdrProcedure',
    component: () => require('./components/AccordianTDR/TDRProcedure').default
  },
  {
    key: 'tdrSelectPassengers',
    component: () => require('./Tdr/TdrSelectTraveller/TdrSelectTraveller').default
  },
  {
    key: 'tdrForm',
    component: () => require('./Tdr/TdrForm/TdrForm').default
  },
  {
    key: 'railFileTdr',
    component: () => require('./Tdr/FileTdrOption/FileTdrOption').default
  },
  {
    key: 'tdrThankYou',
    component: () => require('./Tdr/TdrFiledDetails/TdrFiledThanks').default
  },
  {
    key:'railRefundBreakupPage',
    component: () => require('../Common/RefundBreakupPage').default
  },
  {
    key: 'railRefundBreakupV2',
    component: () => require('../Common/RefundBreakUpPageV2').default
  },
];

export default RAIL_ROUTE_CONFIG;