import { StyleSheet } from 'react-native';

export default (theme) =>
  StyleSheet.create({
    topCardBg: {
      backgroundColor: theme.color.yellow6,
      paddingHorizontal: 14,
      paddingVertical: 12,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      borderBottomColor: theme.color.gray8,
      borderBottomWidth: 1,
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
    },
    upperText: {
      textTransform: 'uppercase',
    },
    topCardBgOther: {
      borderTopColor: theme.color.gray8,
      borderTopWidth: 1,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
  });
