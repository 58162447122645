import { isEmpty } from "lodash";
import React from "react";
import {
  ScrollView, View
} from "react-native";
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import StaffDetailCard from './StaffDetailCard';
import createStyle from "./StaffDetailsCSS";
import { useTheme } from '../../../../theme/theme.context'

const StaffDetailTabPage = ({data, tabId}) => {
  const { psTheme: theme } = useTheme();
  const styles = createStyle(theme);
  return (
    <View style={[styles.cardWrapper, AtomicCss.paddingHz10]}>
      {!isEmpty(data) && data.map((item, index) => (
        <StaffDetailCard
          key={index}
          cardId={tabId}
          data={item} />
      ))}
    </View>
  );
}

export default React.memo(StaffDetailTabPage);
