import React from 'react';
import { View, Text } from 'react-native';
import Actions from '../../../../../navigation/postSalesNavigation';
import HTMLView from 'react-native-htmlview';
import { isEmpty } from 'lodash';
import styles from './ReasonRefundRqstCardCss';
import ReasonCanList from './reasonCanList';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {
  FNO_ICON,
  TDCWA_ICON,
  FDOP_ICON,
  NOSHOW_ICON,
  VISAREJ_ICON,
  BACK_WHITE_ICON,
  MEAL_ICON3,
  HEALTH_WARNING_ICON,
} from '../../../../FlightImageConstants';
import { getFont } from '../../../../../PostSalesStyles';

const ClaimReasonCard = ({
  claimReasonHeader,
  specialClaimDataList,
  onItemClick,
  componnetType,
  webViewHeaderText,
  specialClaimVariant,
}) => {
  const { fsStyle, ...fonts } = getFont(true);

  const htmlStyle = {
    q: {
      /* fix fo Html Fonts Not working without adding blank tag */
    },
    p: {
      color: colors.defaultTextColor,
      fontSize: fonts.fontSize.font14,
      fontFamily: fonts.regular,
      textAlign: 'left',
    },
    b: {
      color: colors.white,
      fontSize: fonts.fontSize.font14,
      fontFamily: fonts.black,
      textAlign: 'left',
    },
  };

  const getIcon = (scCode) => {
    switch (scCode) {
      case 'FNO':
        return FNO_ICON;
      case 'TDCWA':
        return TDCWA_ICON;
      case 'FDOP':
        return FDOP_ICON;
      case 'NOSHOW':
        return NOSHOW_ICON;
      case 'VISAREJ':
        return VISAREJ_ICON;
      case 'ancclaim':
        return MEAL_ICON3;
      case 'MEDICAL_WAIVER_DEATH_WAIVER':
        return HEALTH_WARNING_ICON;
      default:
        return NOSHOW_ICON;
    }
  };
  return (
    <View style={[styles.whiteCard, AtomicCss.marginBottom12, AtomicCss.paddingTop10]}>
      <View style={componnetType === 'CTIP' ? styles.paddingtop4 : styles.topInfoSection}>
        {!isEmpty(claimReasonHeader.header) && (
          <Text
            style={[
              AtomicCss.blackText,
              fsStyle.font16,
              fonts.blackFontFamily,
              AtomicCss.marginBottom8,
              AtomicCss.alignLeft,
            ]}
          >
            {claimReasonHeader.header}
          </Text>
        )}
        {!isEmpty(claimReasonHeader.descriptionHtml) && (
          <HTMLView
            value={claimReasonHeader.descriptionHtml}
            stylesheet={htmlStyle}
            onLinkPress={(url) =>
              Actions.openPsWebView({
                headerText: webViewHeaderText,
                headerIcon: BACK_WHITE_ICON,
                url,
              })
            }
          />
        )}
        {isEmpty(claimReasonHeader.descriptionHtml) && !isEmpty(claimReasonHeader.description) && (
          <Text
            style={[
              AtomicCss.defaultText,
              fsStyle.font14,
              fonts.regularFontFamily,
              AtomicCss.alignLeft,
            ]}
          >
            {claimReasonHeader.description}
          </Text>
        )}
      </View>
      <View style={styles.reasonWrapper}>
        {specialClaimDataList.map((item, index) => {
          if (specialClaimVariant === 0 && item.scCode === 'CITP') return null;
          return (
            <ReasonCanList
              item={item}
              lastItem={index === specialClaimDataList.length - 1}
              icon={getIcon(item.scCode)}
              onItemClick={onItemClick}
            />
          );
        })}
      </View>
    </View>
  );
};

export default ClaimReasonCard;
