import React from 'react';
import { View, Text } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import PercentageCircle from '../../../../../../Common/CircularProgressBar';
import LinearGradient from 'react-native-linear-gradient';
import { getStaticData } from '../../../../../../staticData/staticData';
import styles from './CYTToastButtonCss';
import { getFont } from '../../../../../../PostSalesStyles';

const CYTToastButton = ({ completedPercentage, heading }) => {
  const { fsStyle, ...fonts } = getFont(true);
  const { completeYourTripText } = getStaticData();
  return (
    <View style={styles.toastWrapper}>
      <LinearGradient
        start={{ x: 0.0, y: 1.0 }}
        end={{ x: 1.0, y: 0.0 }}
        colors={['#001037', '#066AA6']}
        style={styles.toastStyle}
      >
        <PercentageCircle
          percent={completedPercentage}
          radius={40}
          borderWidth={10}
          color="#F2C94C"
          shadowColor="#60768a"
          bgColor="#021b43"
        />
        <Text
          style={[
            AtomicCss.whiteText,
            fsStyle.font12,
            fonts.blackFontFamily,
            AtomicCss.marginLeft12,
            styles.upperText,
          ]}
        >
          {heading ? heading : completeYourTripText}
        </Text>
      </LinearGradient>
    </View>
  );
};
export default CYTToastButton;
