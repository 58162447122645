import {fonts} from '@mmt/legacy-commons/Styles/globalStyles';

const styles = {
  overlayContainer:
    {
      justifyContent: 'flex-end',
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      zIndex: 1000,
      elevation: 8
    },
  overlayBg: {
    backgroundColor: 'rgba(0,0,0,.5)',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    zIndex: 1
  },
  overlayContent: {
    backgroundColor: '#fff',
    paddingVertical: 40,
    paddingHorizontal: 20,
    position: 'relative',
    zIndex: 100,
    justifyContent: 'flex-end'
  },
  flexRow: {flexDirection: 'row'},
  marginBottom15: {marginBottom: 15},
  marginBottom32: {marginBottom: 32},
  overlayHead: {
    fontSize: 24,
    color: '#000',
    fontFamily: fonts.light
  },
  flex1: {flex: 1},
  rightContent:
    {
      fontSize: 16,
      color: '#4a4a4a',
      fontFamily: fonts.regular,
      lineHeight: 22
    },
  alignCenter: {alignItems: 'center'},
  actionTextLightBlue: {
    color: '#4dafff',
    fontSize: 16,
    fontFamily: fonts.regular
  },
  JustifySpaceBetween: {justifyContent: 'space-between'},
  actionBlueText: {
    color: '#008cff',
    fontSize: 16,
    fontFamily: fonts.bold
  },
  marginBottom35: {marginBottom: 35}
};

export default styles;
