import React from 'react';
import { View, Text, StyleSheet, Image, TouchableOpacity } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import _isEmpty from 'lodash/isEmpty';

import StarRating from './StarRating';
import { USER_PLATFORM } from '../../Constants/SimilarPropConstants';
import { getCurrencySymbol, ceilAndFormat } from '../../utils';

const PropertiesItem = ({ item, currency }) => {
  const {
    image,
    name,
    locationPersuasion,
    starRating,
    displayPriceBreakDown,
    ctaMap
  } = item;

  const basePrice = ceilAndFormat(displayPriceBreakDown.basePrice);
  const actualPrice = ceilAndFormat(displayPriceBreakDown.displayPrice);

  const cardClickHandler = () => {
    if (USER_PLATFORM.WEB) {
      window.location.href = ctaMap.url;
    } else {
      GenericModule.openDeepLink(ctaMap.url);
    }
  }

  return (
    <TouchableOpacity style={styles.propertyItem} activeOpacity={0.7}
      onPress={() => cardClickHandler()}>
      {!_isEmpty(image) && <Image source={{ uri: `https:${image}` }} style={styles.propImg} />}
      <View style={styles.itemDesc}>
        <View style={styles.itemDescLeft}>
          {displayPriceBreakDown.savingPerc > 0 && (
            <LinearGradient
              colors={['#75dcac', '#5dbcb2']}
              start={{ x: 0, y: 0 }}
              end={{ x: 1, y: 0 }}
              style={styles.discountCont}>
              <Text style={styles.discountText}>{`${displayPriceBreakDown.savingPerc}% OFF TODAY!`}</Text>
            </LinearGradient>)
          }
          {!_isEmpty(name) && <Text numberOfLines={1} style={styles.hotelName}>{name}</Text>}
          {!_isEmpty(locationPersuasion) && <Text style={styles.location}>{locationPersuasion}</Text>}
          <StarRating
            defaultStarImg={require('../images/ic_star_default.png')}
            activeStarImg={require('../images/ic_star_selected.png')}
            starValue={starRating}
            iconWidth={7}
            iconHeight={7}
          />
        </View>
        <View style={styles.itemDescRight}>
          {!_isEmpty(actualPrice) && <Text style={styles.newPrice}>{`${getCurrencySymbol(currency)} ${actualPrice}`}</Text>}
          {!_isEmpty(basePrice) && actualPrice !== basePrice && <Text style={styles.oldPrice}>{`${getCurrencySymbol(currency)} ${basePrice}`}</Text>}
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default PropertiesItem;

const styles = StyleSheet.create({
  propertyItem: {
    width: 216,
    backgroundColor: '#fff',
    marginRight: 6,
    borderRadius: 4,
    overflow: 'hidden',
    borderColor: '#ccc',
    borderWidth: 1,
  },
  propImg: {
    width: '100%',
    height: 105,
    resizeMode: 'cover',
  },
  itemDesc: {
    padding: 16,
    flexDirection: 'row',
  },
  itemDescLeft: {
    flex: 1,
  },
  discountCont: {
    alignSelf: 'flex-start',
    borderRadius: 30,
    paddingHorizontal: 8,
    paddingVertical: 2,
    position: 'absolute',
    top: -24,
    left: 0,
  },
  discountText: {
    color: '#fff',
    fontFamily: 'Lato',
    fontSize: 8,
    textTransform: 'uppercase',
  },
  hotelName: {
    fontFamily: 'Lato',
    fontSize: 14,
    fontWeight: '700',
    color: '#000',
  },
  location: {
    fontFamily: 'Lato',
    fontSize: 9,
    color: '#9b9b9b',
    marginBottom: 4,
  },
  itemDescRight: {
    marginLeft: 20,
    flexShrink: 0,
    textAlign: 'right',
  },
  priceText: {
    fontFamily: 'Lato',
    fontSize: 10,
    color: '#4a4a4a',
    textAlign: 'right',
  },
  oldPrice: {
    fontFamily: 'Lato',
    fontSize: 10,
    color: '#4a4a4a',
    textAlign: 'right',
    textDecorationLine: 'line-through',
  },
  newPrice: {
    fontFamily: 'Lato',
    fontSize: 12,
    fontWeight: '900',
    color: '#000',
    textAlign: 'right',
  },
});
