import { StyleSheet } from 'react-native';
import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';

export default (theme, fonts) => {
  const { color } = theme;
  return StyleSheet.create({
    heading: {
      marginBottom: 16,
    },
    stepsHeading: {
      fontSize: fonts.fontSize.font14,
      color: color.blackFont,
      ...fonts.boldFontFamily,
    },
    stespSubHeading: {
      fontSize: fonts.fontSize.font12,
      color: color.defaultTextColor,
      ...fonts.regularFontFamily,
    },
    textRight: {
      textAlign: 'right',
    },
    howToBoardWrapper: {
      padding: 12,
    },
    imageWrapper: {
      borderWidth: 1,
      borderColor: color.lightGrey16,
      borderRadius: 8,
      width: '48%',
    },
    kioskImageStle: {
      height: 140,
      width: '100%',
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      resizeMode: 'cover',
    },
    textInfoWrapper: {
      backgroundColor: color.black,
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
      paddingVertical: 10,
      paddingHorizontal: 10,
    },
    qrCodeWrapper: {
      padding: 4,
      backgroundColor: color.grayBg,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 8,
    },
    qrCodeImageStyle: {
      width: 116,
      height: 116,
      backgroundColor: color.white,
      borderRadius: 8,
    },
    qrCodeTextWrapper: {
      paddingHorizontal: 20,
      flex: 1,
    },
    fullScreen: {
      width: 15,
      height: 15,
      marginLeft: 5,
    },
    textStyle: {
      fontSize: fonts.fontSize.font14,
    },
    buttonStyle: {
      borderRadius: 10,
      paddingVertical: 10,
      ...getPlatformElevation(0),
    },
    modalContainer: {
      flex: 1,
      backgroundColor: 'black',
      alignItems: 'center',
      justifyContent: 'center',
    },
    fullImage: {
      flex: 1,
      width: '100%',
      height: '100%',
    },
    closeButton: {
      position: 'absolute',
      top: 40,
      right: 20,
      zIndex: 1,
    },
    closeIcon: {
      width: 20,
      height: 20,
    },
  });
};
