import { trackOmniture, EVAR_47 } from '@mmt/legacy-commons/Common/utils/OmnitureTrackerUtils';
import { getUserPnrContext } from '../Utils/RisUtils';

export const RIS_LANDING_PNR_DETAILS_SUCCESS = 'mob:funnel:ris:pnr:pnrdetails';
export const RIS_LANDING_PNR_TRAVELLERS_DETAILS_PAGE = 'rails:tgs:travellers';
export const RIS_TGS_THANK_YOU_SUCCESS_PAGE = 'rails:tgs:thankyou:success';
export const RIS_LANDING_TRAIN_SCHEDULE_SUCCESS = 'mob:funnel:ris:pwa:schedule:scheduledtls';
export const RIS_LANDING_TRAIN_LIVE_STATION_SUCCESS = 'mob:funnel:ris:pwa:livestation:livestationdtls';
export const RIS_LANDING_PNR_DETAILS_PAGE_ERROR = 'mob:funnel:ris:pnr:pnrerror';
export const EVENT_RIS_PNR_COACH_POSITION = 'mob:funnel:ris:pwa:coachposition:coachpositiondtls';
export const EVENT_RIS_PNR_REFRESH_CLICKED = 'ris_pnr_refresh_clicked';
export const PAGE_RIS_LTS_INFO = 'mob:funnel:ris:livetrain:ltsdetails';
export const PAGE_RIS_LTS_ERROR = 'mob:funnel:ris:pnr:livetrainerror';
export const PAGE_RIS_LTS_UNSTABLE_INTERNET = 'mob:funnel:ris:lts:ltserror:nointernet';
export const PAGE_RIS_LTS_NOT_RUN_DATE = 'mob:funnel:ris:lts:ltserror:norundate';
export const PAGE_RIS_LTS_TECHNICAL_ISSUE = 'mob:funnel:ris:lts:ltserror:technicalissue';
export const BS_RIS_LTS_TRACKING_MODE = 'mob:funnel:ris:lts:bottomsheet:modeselection';
export const BS_RIS_LTS_TOO_FAR_TRACK = 'mob:funnel:ris:lts:bottomsheet:toofarfromtrack';
export const BS_RIS_LTS_OFFLINE_MODE = 'mob:funnel:ris:lts:bottomsheet:switchingtooffline';
export const BS_RIS_LTS_ONLINE_MODE = 'mob:funnel:ris:lts:bottomsheet:switchingtoonline';
export const EVENT_RIS_LTS_LANDING_SEARCH_CLICK = 'rails_ris_lts_landing_search_click';
export const EVENT_RIS_LTS_DATE_CHANGE_CLICK = 'rails_ris_lts_detail_datechange_proceed_click';
export const EVENT_RIS_LTS_REFRESH_CLICK = 'rails_ris_lts_detail_lastupdated_click';
export const EVENT_RIS_LTS_FOOTER_CLICK = 'rails_ris_lts_detail_summarybottomsheet_click';
export const EVENT_RIS_LTS_BACK_CLICK = 'rails_ris_lts_detail_backbtn_click';
export const EVENT_RIS_LTS_FEEDBACK_SUBMIT_CLICK = 'rails_ris_lts_detail_feedbacksubmit_click';
export const PAGE_RIS_LTS_PIP_LOAD = 'rails:ris:lts:offline:pip';
export const RIS_LTS_PIP_MID_VIEW_EXPANDED = 'rails_ris_lts_pip_midview_expand';
export const RIS_LTS_PIP_FULL_VIEW_EXPANDED = 'rails_ris_lts_pip_returntodetail';
export const RIS_LTS_STATION_ALARM_BOTTOMSHEET = 'rails_ris_lts_stationalarm_settimebottomsheet';
export const RIS_LTS_STATION_ALARM_SUCCESS_BOTTOMSHEET = 'rails_ris_lts_stationalarm_successbottomsheet';
export const RIS_LTS_STATION_ALARM_SET_BOTTOMSHEET = 'rails_ris_lts_stationalarm_settimebottomsheet';
export const RIS_LTS_STATION_OK_CLICKED = 'rails_ris_lts_stationalarm_okay_clicked'
export const RIS_LTS_STATION_BOTTOMSHEET_SET_ALARM_CLICKED= 'rails_ris_lts_stationalarm_setalarm_clicked'
export const RIS_LTS_STATION_BOTTOMSHEET_DISMISSED = 'rails_ris_lts_stationalarm_bottomsheetdismiss_clicked'
export const RIS_LTS_STATION_ALARM_TIME = 'rail_ris_lts_stationalarm_alarmtime_'
export const RIS_LTS_STATION_APPLY_ALARM = 'rails_ris_lts_stationalarm_applyalarm'
export const RIS_LTS_STATION_REMOVE_ALARM = 'rails_ris_lts_stationalarm_removealarm'

export const LTS_TRAIN_CANCELLED = 'LTS:train:cancelled';
export const LTS_TRAIN_PARTIALLY_CANCELLED = 'LTS:train:partially_cancelled';
export const LTS_TRAIN_SCHEDULED = 'LTS:train:scheduled';
export const LTS_TRAIN_RUNNING = 'LTS:train:running';
export const LTS_TRAIN_DIVERTED = 'LTS:train:diverted';

export const LTS_INSIDE_TRAIN_CLICKED = 'rails_lts_inside_train_clicked';
export const LTS_OUTSIDE_TRAIN_CLICKED = 'rails_lts_outside_train_clicked';

export const LTS_OFFLINE_SWITCH_FAIL_CTE = 'rails:lts:switchfailed:ctstationempty';
export const LTS_OFFLINE_SWITCH_FAIL_CTCSF = 'rails:lts:switchfailed:ctlastcurrentstsncheckfail';
export const LTS_OFFLINE_SWITCH_FAIL_CTTCF = 'rails:lts:switchfailed:cttimestampcheckfail';
export const LTS_OFFLINE_SWITCH_FAIL_CTCNF = 'rails:lts:switchfailed:ctcellidnotfound';
export const LTS_OFFLINE_SWITCH_FAIL_CTNV = 'rails:lts:switchfailed:ctcellidnotvalid';
export const LTS_OFFLINE_SWITCH_FAIL_CTUNE = 'rails:lts:switchfailed:ctunknownerror';


export const LTS_OFFLINE_SWITCH_FAIL_GPSE = 'rails:lts:switchfailed:gpsstationempty';
export const LTS_OFFLINE_SWITCH_FAIL_GPSUF = 'rails:lts:switchfailed:gpsuseristoofar';
export const LTS_OFFLINE_SWITCH_FAIL_GPSTLM = 'rails:lts:switchfailed:gpstrainlocationmissing';
export const LTS_OFFLINE_SWITCH_FAIL_GPSTCF = 'rails:lts:switchfailed:gpstimestampcheckfail';
export const LTS_OFFLINE_SWITCH_FAIL_GPSDNV = 'rails:lts:switchfailed:gpsdistancenotvalid';
export const LTS_OFFLINE_SWITCH_FAIL_GPSUNE = 'rails:lts:switchfailed:gpsunknownerror';

export const LTS_GPS_BOTTOM_SHEET_SHOWN = 'rails:lts:gps:permission:widget:shown';
export const LTS_OFFLINE_SWITCH_GPS_YES = 'rails_lts_gps_permission_yes_clicked';
export const LTS_OFFLINE_SWITCH_GPS_NO = 'rails_lts_gps_permission_no_clicked';

export const PNR_DETAILS_CONFIRMED_OPTIONS_VIEW_ALL_CLICKED = 'rails_ris_pnr_detail_confirmedoptions_viewall_clicked';
export const PNR_DETAILS_CONFIRMED_OPTIONS_CARD_CLICKED = 'rails_ris_pnr_detail_confirmedoptions_card_clicked';

export function trackOmnitureClickEvent(pageName, eventName) {
  const params = {
    pageName,
    m_v15: pageName,
    m_c61: eventName,
    m_v24: 'pnrStatusDetails'
  };
  trackOmniture(pageName, params);
}

export function trackOmnitureLoadEvent(pageName) {
  const params = {
    pageName,
    m_v15: pageName,
    m_v24: 'pnrStatusDetails'
  };
  trackOmniture(pageName, params);
}

export const trackOmnitureLTSClickEvent = (pageName, eventName) => {
  const params = {
    pageName,
    m_pagename: pageName,
    m_v15: pageName,
    m_c61: eventName,
  };
  trackOmniture(pageName, params);
};

export const trackOmnitureLTSLoadEvent = (pageName, eventName) => {
  const params = {
    pageName,
    m_pagename: pageName,
    m_v15: pageName,
    m_v22: eventName,
  };
  trackOmniture(pageName, params);
};

export const trackOmnitureAlarmLoadEvent = (pageName) => {
  const params = {
    pageName,
    m_pagename: pageName,
    m_v15: pageName,
  };
  trackOmniture(pageName, params);
};

export const trackOmnitureTrainCallout = (pageName,eventName) => {
  const params = {
    pageName,
    m_pagename: pageName,
    m_v22: eventName,
  };
  trackOmniture(pageName, params);
};

export const trackOmnitureLTSVisitEvent = trackingParams => {
  const pageName = PAGE_RIS_LTS_INFO;
  if (trackingParams){
    const params = {
      pageName,
      m_pagename: pageName,
      m_v15: pageName,
      m_v98: trackingParams,
    };
    trackOmniture(pageName, params);
  }
};

export const trackOmnitureLTSPipLoadEvent = (pageName, eventName) => {
  const params = {
    pageName,
    m_pagename: pageName,
    m_v15: pageName,
  };
  trackOmniture(pageName, params);
};

export const trackOmnitureLTSPipViewEvent = (pageName, eventName) => {
  const params = {
    pageName,
    m_pagename: pageName,
    m_v15: pageName,
    m_c61: eventName,
  };
  trackOmniture(pageName, params);
};

export const trackTGSeligibility = async (pageName, eventName) => {
  const params = {
    pageName: pageName,
    m_v99: eventName,
    m_v15: pageName
  };
  console.log('Booking eligibility', JSON.stringify(params));
  trackOmniture(pageName, params,true);
};

export const trackTGSPageDetails = async (pageName,eventName) => {
  const params = {
    pageName: pageName,
    m_v15:pageName,
    [EVAR_47]: eventName,
  };
  trackOmniture(pageName, params, true);
};

export const trackTGSInEligibility = async (pageName, eventName) => {
  const params = {
    pageName: pageName,
    m_v98: eventName,
    m_v15: pageName
  };
  console.log('TGS Ineligibility', JSON.stringify(params));
  trackOmniture(pageName, params,true);
};

export const trackTrainsBusCrossSell = (pageName, eventName) => {
  const params = {
    pageName: pageName,
    m_v106: eventName,
    m_v15: pageName,
  };
  console.log('Trains Bus Cross Sell Carousel visible', JSON.stringify(params));
  trackOmniture(pageName, params,true);
};

export const trackPageVisits = async (pageName) => {
  const params = {
    pageName: pageName,
    m_v15: pageName
  };
  console.log('Page Visit', JSON.stringify(params));
  trackOmniture(pageName,params,true);
}


export const trackPNRAdvancePurchase = (pageName, eventName) => {
  const params = {
    pageName,
    m_v15: pageName,
    m_v7: eventName,
  };
  trackOmniture(pageName, params,true);
};

export const trackPNRPrice = (pageName, eventName) => {
  const params = {
    pageName,
    m_v15: pageName,
    m_v64: eventName,
  };
  trackOmniture(pageName, params,true);
};

export const trackPNRSourceDestination = (pageName, eventName) => {
  const params = {
    pageName,
    m_v15: pageName,
    m_v3: eventName,
  };
  trackOmniture(pageName, params,true);
};

export const trackPnrId = (pageName, eventName) => {
  const params = {
    pageName,
    m_v15: pageName,
    m_v16: eventName,
  };
  trackOmniture(pageName, params,true);
};
