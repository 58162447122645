import { StyleSheet } from 'react-native';

export default (theme) =>StyleSheet.create({
  starStyle: {
    width: 11,
    height: 11,
    marginRight: 5,
  },
  activeStyle: {
    tintColor: theme.color.black,
  },
  disabledStyle: {
    tintColor: theme.color.gray7,
  },
  hotelImg: {
    width: 96,
    height: 96,
    borderRadius: 16,
  },
  careTakerImg: {
    width: 37,
    height: 37,
    borderRadius: 50,
    marginRight:5
  },
  borderBottom: {
    borderBottomWidth: 1,
    borderColor: theme.color.lightGrey,
    paddingBottom: 12,
  },
  ValueStaysImg: {
    height: 24,
    width: 124,
  },
  discountingCampaingnImg: {
    height: 22,
    width: 114,
  },
  mySafetyImg: {
    height: 21,
    width: 86,
  },
  luxImg: {
    height: 21,
    width: 70,
  },
  htlImageWrapper: {
    width: 96,
    height: 96,
  },
  viewPhotosWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    bottom: 5,
    left: 7,
    position: 'absolute',
  },
  arrowStyle: {
    tintColor: 'white',
    width: 15,
    height: 15,
  },
  topCardActionWrapper: {
    borderLeftWidth: 3,
    paddingLeft: 12,
    marginLeft: -12,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
    alignItems: 'flex-end'
  },
  paymentDueWrapper:{
    borderLeftColor: theme.color.red17
  },
  messageHostWrapper:{
    borderLeftColor: theme.color.lightBlue17
  },
  topCardInfoText: {
    marginBottom: 3,
    fontFamily: 'Lato-Bold',
    fontSize: 16
  },
  paymentDueInfoText: {
    color: theme.color.red17
  },
  messageHostInfoText: {
    color: theme.color.textGrey
  },
  topCardBtn: {
    borderRadius: 12,
    paddingHorizontal: 23,
    paddingVertical: 10,
    fontSize: 12
  },
  seperator: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  semiCircle: {
    height: 16,
    width: 16,
    borderRadius: 8,
    backgroundColor: theme.color.limeGreen,
    marginVertical: 6,
  },
  leftSemiCircle: {
    marginLeft: -23,
  },
  rightSemiCircle: {
    marginRight: -23,
  },
  dashedLineWrapper: {
    height: 1,
    flex: 1,
    overflow: 'hidden',
  },
  dashedLine: {
    flex: 1,
    borderStyle: 'dashed',
    borderWidth: 2,
    borderColor: theme.color.faintgrey,
    height: 3,
    borderRadius: 1
  },
  redirectLinkWapper: {
    marginTop: -15,
  },
  supportIcon: {
    width: 40,
    height: 40,
    marginRight: 16,
  },
  rewardsIcon: {
    width: 40,
    height: 40,
    marginRight: 16,
  },
  dividor: {
    borderBottomWidth: 1,
    borderColor: theme.color.lightGrey,
    marginHorizontal:-12
  },
  bottomWrapper: {
    padding: 16,
    backgroundColor: theme.color.white,
    borderRadius: 16,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
});
