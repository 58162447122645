import React from 'react';
import PropTypes from 'prop-types';
import {Text, TouchableOpacity, Image, StyleSheet} from 'react-native';
import {getFont} from "../../PostSalesStyles";
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';

class LinkBtn extends React.Component {
  render() {
    const fonts = getFont(this.props.useUserSelectedLangFont);
    const linkText = this.props.isCorporateBooking ? colors.orange4 : colors.azure
    return (
      <TouchableOpacity
        style={styles.linkBtnWrapper}
        activeOpacity={0.5}
        onPress={() => { this.props.onBtnClick(this.props.action); }}
      >
        <Image style={this.props.iconStyle} source={this.props.icon} />
        <Text style={[styles.linkBtnTxt, fonts.boldFontFamily, {color: linkText}]}>{this.props.linkTxt}</Text>
      </TouchableOpacity>

    );
  }
}
const styles = StyleSheet.create({
  linkBtnWrapper: {
    paddingTop: 6,
    alignItems: 'center',
    width: '25%',
  },
  linkBtnTxt: {
    fontSize: 13,
    textAlign: 'center',
    marginTop: 4,
    paddingHorizontal: 2,
  },
  linkBtnStyle: {
    width: 44,
    height: 44,
  }
});
export default LinkBtn;

LinkBtn.propTypes = {
  icon: PropTypes.string.isRequired,
  iconStyle: PropTypes.object,
  linkTxt: PropTypes.string.isRequired,
  onBtnClick: PropTypes.func.isRequired,
  action: PropTypes.object.isRequired
};

LinkBtn.defaultProps = {
  iconStyle: styles.linkBtnStyle
};
