import React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import PropTypes from 'prop-types';
import AtomicCss from '../details/AtomicCss';

class AcmeCancellationReviewStrip extends React.Component {
  render() {
    return (
      <View style={styles.InfoStrip}>
        <View style={[AtomicCss.flexRow, styles.mar24Rt]}>
          <View style={this.props.step1Active ? this.getActiveViewStyle() : this.getNonActiveViewStyle()}>
            <Text style={[AtomicCss.boldFont, styles.RowCountText]}>
              1
            </Text>
          </View>
          <Text
            style={this.props.step1Active ? this.getActiveTextStyle() : this.getNonActiveTextStyle()}
          >
            {this.props.step1}
          </Text>
        </View>
        <View style={AtomicCss.flexRow}>
          <View style={this.props.step1Active ? this.getNonActiveViewStyle() : this.getActiveViewStyle()}>
            <Text
              style={[styles.RowCountText, AtomicCss.boldFont]}
            >2
            </Text>
          </View>
          <Text
            style={this.props.step1Active ? this.getNonActiveTextStyle() : this.getActiveTextStyle()}
          >{this.props.step2}
          </Text>
        </View>
      </View>
    );
  }

  getActiveViewStyle() {
    return [styles.RowCount, styles.RowCountActive];
  }

  getNonActiveViewStyle() {
    return [styles.RowCount];
  }

  getActiveTextStyle() {
    return [AtomicCss.boldFont, AtomicCss.font11, AtomicCss.blackText];
  }

  getNonActiveTextStyle() {
    return [AtomicCss.boldFont, AtomicCss.font11, AtomicCss.lightGreyText];
  }
}

const styles = StyleSheet.create({
  InfoStrip: {
    flexDirection: 'row',
    paddingBottom: 10,
    paddingTop: 10,
    paddingLeft: 15,
    paddingRight: 15,
    marginBottom: 10,
    alignItems: 'center',
    backgroundColor: '#fff',
    elevation: 3,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.3,
    shadowRadius: 2
  },
  mar24Rt: {
    marginRight: 24
  },
  RowCount: {
    backgroundColor: '#9b9b9b',
    width: 14,
    height: 14,
    borderRadius: 50,
    marginRight: 4,

    alignItems: 'center',
    justifyContent: 'center'
  },
  RowCountText: {
    color: '#fff',
    fontSize: 11
  },
  RowCountActive: {backgroundColor: '#000'}
});

AcmeCancellationReviewStrip.propTypes = {
  step1: PropTypes.string.isRequired,
  step2: PropTypes.string.isRequired,
  step1Active: PropTypes.bool.isRequired
};
export default AcmeCancellationReviewStrip;
