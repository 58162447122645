import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import findIndex from 'lodash/findIndex';
import { CANCELLATION_VALIDATION } from './Constant';

export const isApiSuccess = (cancellationValidation) => {
  const status = get(cancellationValidation, 'status', '');
  return !isEmpty(status) && status.toLowerCase() === CANCELLATION_VALIDATION.SUCESS.toLowerCase();
};

export const isEligibleForClaim = (actionList, specialClaimDataList) => {
  const isAnyActionEligibleForClaim = actionList.filter(
    (action) => findIndex(specialClaimDataList, { scCode: action }) !== -1,
  );
  if (isAnyActionEligibleForClaim && isAnyActionEligibleForClaim.length > 0) {
    return true;
  }
  return false;
};

export const isSegmentEligibleForClaim = (segmentGroup, cancellationValidation) => {
  const specialClaimDataList = get(cancellationValidation, 'specialClaimDataList', '');
  if (
    isApiSuccess(cancellationValidation) &&
    specialClaimDataList &&
    specialClaimDataList.length > 0
  ) {
    const activeSegment = getActiveSegment(segmentGroup, cancellationValidation);
    if (
      !!activeSegment &&
      !!activeSegment.actionList &&
      activeSegment.actionList.length > 0 &&
      isEligibleForClaim(activeSegment.actionList, specialClaimDataList)
    ) {
      return true;
    }
    return false;
  }
  return false;
};

export const getActiveSegment = (segmentGroup, cancellationValidation) => {
  if (cancellationValidation && cancellationValidation.status === 'success') {
    const activeSegmentLineNo = get(segmentGroup, 'segmentDetails[0].segmentLineNo', '');
    const segments = get(cancellationValidation, 'segments', false);
    if (segments) {
      const activeSegment = segments.find((segment) => segment.segLineNo === activeSegmentLineNo);
      if (activeSegment) {
        return activeSegment;
      }
    }
  }
  return false;
};

const getActivePax = (activeSegment, paxlineId) => {
  const paxList = get(activeSegment, 'paxList', []);
  if (!!paxList && paxList.length > 0) {
    const activePax = paxList.find((pax) => pax.paxLineNo === paxlineId);
    if (activePax) {
      return activePax;
    }
  }
  return false;
};

export const isSegmentNotValidForCancellation = (segmentGroup, cancellationValidation) => {
  if (isApiSuccess(cancellationValidation)) {
    const activeSegment = getActiveSegment(segmentGroup, cancellationValidation);
    if (!!activeSegment && !!activeSegment.actionList && activeSegment.actionList.length > 0) {
      return true;
    }
    return false;
  }
  return false;
};

export const isPaxNotValidForCancellation = (paxlineno, segmentGroup, cancellationValidation) => {
  if (isApiSuccess(cancellationValidation)) {
    const activeSegment = getActiveSegment(segmentGroup, cancellationValidation);
    const activePax = getActivePax(activeSegment, paxlineno);
    if (activePax && !!activePax.actionList && activePax.actionList.length > 0) {
      return true;
    }
  }
  return false;
};

export const isAnySegmentNotValidForCancellation = (response) => {
  const cancellationValidation = get(response, 'cancellationValidation', null);
  const segmentGroupDetailList = get(response, 'flightDetails.segmentGroupDetailList', null);
  if (cancellationValidation && segmentGroupDetailList) {
    if (isApiSuccess(cancellationValidation)) {
      return true;
    }
    return false;
  }
  return false;
};

export const isPaxNotCancelled = (paxLineNo, segmentGroup) => {
  const segmentGroupPax = get(segmentGroup, 'segmentGroupPassengers', []);
  if (segmentGroupPax) {
    const paxNotCancelled = segmentGroupPax.filter((pax) => pax.paxLineNo === paxLineNo);
    if (paxNotCancelled && paxNotCancelled.length > 0) {
      return true;
    }
    return false;
  }
  return false;
};

export const checkIfAnypaxNotValid = (segmentGroup, cancellationValidation) => {
  if (isApiSuccess(cancellationValidation)) {
    const activeSegment = getActiveSegment(segmentGroup, cancellationValidation);
    if (!!activeSegment && !!activeSegment.paxList) {
      const isAnypaxNotValid = activeSegment.paxList.find(
        (pax) => !!pax.actionList && isPaxNotCancelled(pax.paxLineNo, segmentGroup),
      );
      if (isAnypaxNotValid) {
        return true;
      }
    }
    return false;
  }
  return false;
};

export const checkIfAnypaxEligibleForClaim = (segmentGroup, cancellationValidation) => {
  const specialClaimDataList = get(cancellationValidation, 'specialClaimDataList', '');
  if (
    isApiSuccess(cancellationValidation) &&
    specialClaimDataList &&
    specialClaimDataList.length > 0
  ) {
    const activeSegment = getActiveSegment(segmentGroup, cancellationValidation);
    if (!!activeSegment && !!activeSegment.paxList) {
      const isAnypaxEligibleForClaim = activeSegment.paxList.find(
        (pax) =>
          !!pax.actionList &&
          isPaxNotCancelled(pax.paxLineNo, segmentGroup) &&
          pax.actionList.length > 0 &&
          isEligibleForClaim(pax.actionList, specialClaimDataList),
      );
      if (isAnypaxEligibleForClaim) {
        return true;
      }
    }
    return false;
  }
  return false;
};

export const isOneSegmentAndNotvalid = (response) => {
  const flightDetails = get(response, 'flightDetails', false);
  if (flightDetails && !isEmpty(flightDetails.segmentGroupDetailList)) {
    const noOfsegGrp = flightDetails.segmentGroupDetailList.length;
    if (noOfsegGrp > 1) {
      return false;
    }

    const segments = flightDetails.segmentGroupDetailList[0].segmentDetails;
    if (!!segments && segments.length === 1) {
      const activeSegment = getActiveSegment(
        flightDetails.segmentGroupDetailList[0],
        response.cancellationValidation,
      );
      return !!activeSegment && !!activeSegment.actionList && activeSegment.actionList.length > 0;
    }
    return false;
  }
  return false;
};

export const getMathingScCodes = (actionList, specialClaimDataList) => {
  const scDataList = [];
  specialClaimDataList.map((scItem) => {
    if (actionList.indexOf(scItem.scCode) > -1) {
      scDataList.push(scItem);
    }
  });
  return scDataList;
};

export const getAllPossibleScActionLists = (cancellationValidation, type, segmentGroup) => {
  const specialClaimDataList = get(cancellationValidation, 'specialClaimDataList', '');
  if (isApiSuccess(cancellationValidation) && specialClaimDataList) {
    const activeSegment = getActiveSegment(segmentGroup, cancellationValidation);
    if (activeSegment) {
      if (type === 'segment' && activeSegment.actionList) {
        return getMathingScCodes(activeSegment.actionList, specialClaimDataList);
      } else if (type === 'pax' && activeSegment.paxList) {
        const allActionLists = [];
        activeSegment.paxList.map((pax) => {
          if (pax.actionList) {
            allActionLists.push(...pax.actionList);
          }
        });
        return getMathingScCodes(allActionLists, specialClaimDataList);
      }
    }
    return [];
  }
  return [];
};

export const getPaxHeading = (paxLineNo, segmentGroup, cancellationValidation) => {
  if (isApiSuccess(cancellationValidation)) {
    const activeSegment = getActiveSegment(segmentGroup, cancellationValidation);
    const activePax = getActivePax(activeSegment, paxLineNo);
    if (activePax && activePax.heading) {
      return activePax.heading;
    }
    return false;
  }
  return false;
};

export const getPaxDesc = (paxLineNo, segmentGroup, cancellationValidation) => {
  if (isApiSuccess(cancellationValidation)) {
    const activeSegment = getActiveSegment(segmentGroup, cancellationValidation);
    const activePax = getActivePax(activeSegment, paxLineNo);
    if (activePax && activePax.description) {
      return activePax.description;
    }
    return false;
  }
  return false;
};

export const shouldNotRedirectToBreakUpPage = (response) => {
  const cancellationValidation = get(response, 'cancellationValidation', null);
  const segmentGroupDetailList = get(response, 'flightDetails.segmentGroupDetailList', null);
  if (cancellationValidation && segmentGroupDetailList) {
    const flightDetails = get(response, 'flightDetails', false);
    if (isApiSuccess(cancellationValidation)) {
      if (flightDetails && flightDetails.segmentGroupDetailList) {
        const noOfsegGrp = flightDetails.segmentGroupDetailList.length;
        if (noOfsegGrp === 1) {
          const segments = flightDetails.segmentGroupDetailList[0].segmentDetails;
          if (segments && segments.length === 1) {
            const activeSegment = getActiveSegment(
              flightDetails.segmentGroupDetailList[0],
              response.cancellationValidation,
            );
            if (activeSegment && activeSegment.actionList && activeSegment.actionList.length > 0)
              return true;
            else
              return (
                activeSegment &&
                activeSegment.paxList &&
                checkIfAnypaxNotValid(
                  flightDetails.segmentGroupDetailList[0],
                  cancellationValidation,
                )
              );
          }
        }
        return isAnySegmentNotValidForCancellation(response);
      }
    }
    return false;
  }
  return false;
};

export const getReasonForRefund = (segment, type, response) => {
  const activSeg = getActiveSegment(segment, response.cancellationValidation);
  if (type === 'segment') {
    return activSeg.status;
  } else if (type === 'pax') {
    const paxStatus = activSeg.paxList.find((pax) => pax && pax.status);
    return paxStatus.status ? paxStatus.status : 'statusNotFound';
  } else return 'statusNotFound';
};
