import React, { useState, useEffect } from 'react';
import { Image, Text, View, TouchableOpacity, Platform } from 'react-native';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import FlightBookingModule from '@mmt/legacy-commons/Native/FlightBookingModule';
import { resetToFlightBookingDetail } from '../../../../../FlightBookingUtil';
import { ViewState } from '../../../../../../PostSalesConstant';
import FlightBookingTrackingHelper from '../../../helper/FlightBookingTrackingHelper';
import fetchInsHoldDetail from '../insuranceHoldApi';
import Buttons from '../../../../components/Button'
import PageLoader from '../../../../../../Common/Cancellation/PageLoader';
import { SUCCESS_TICK_ICON, CROSS_RED_CIRCLE_ICON } from '../../../../../FlightImageConstants';
import styles from './insuranceThankucss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getStaticData } from '../../../../../../staticData/staticData';
import { getFont } from '../../../../../../PostSalesStyles';

const InsuranceThankYou = ({
  bookingId,
  commitRes,
  holdDetailApi,
  isHoldFailed = false,
  uniqueCrId,
  responseJson,
}) => {
  const { fsStyle, ...fonts } = getFont(true);
  const [holdResponse, setHoldResponse] = useState(holdDetailApi);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [commitDetail, setCommitDetail] = useState(null);
  const [viewState, setViewState] = useState(ViewState.LOADING);
  const onCommitSucces = () => {
    const paymentResponse = JSON.parse(commitRes.PAYMENT_RESPONSE_VO);
    if (paymentResponse) {
      const respObj = JSON.parse(paymentResponse.response);
      if (respObj.success) {
        FlightBookingTrackingHelper.trackLoadEvent(
          'Insurance_Post_Sale_Payment_Success',
          responseJson,
        );
        setCommitDetail(respObj);
        setPaymentSuccess(true);
        setViewState(ViewState.LOADED);
      } else {
        setViewState(ViewState.ERROR);
      }
    } else {
      FlightBookingTrackingHelper.trackLoadEvent(
        'Insurance_Post_Sale_Payment_Failure',
        responseJson,
      );
      setViewState(ViewState.LOADED);
    }
  };

  useEffect(() => {
    if (commitRes && commitRes.Payment_Status === 'PAYMENT_SUCCESS') {
      onCommitSucces();
    } else if (isHoldFailed) {
      FlightBookingTrackingHelper.trackLoadEvent(
        'Insurance_Post_Sale_HoldApi_Failure',
        responseJson,
      );
      setViewState(ViewState.LOADED);
      setHoldResponse(holdResponse ? holdResponse : null);
    } else {
      setViewState(ViewState.ERROR);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commitRes, holdResponse, isHoldFailed]);

  const {
    digitInsuranceText: {
      greatChoiceText,
      failureText,
      thnakuHeading,
      failureHeading,
      thankuDesc,
      hloldFaileDesc,
      CommitfailureDesc,
      holdFailureTitle,
    },
    goBackToBookingText,
    tryAgainText,
  } = getStaticData();

  const getCommitMsgDescription = (thankuDesc, hloldFaileDesc, CommitfailureDesc) => {
    let msgDesc = '';
    if (viewState === ViewState.ERROR && holdResponse) {
      msgDesc = get(holdResponse, 'errorPageData.description', hloldFaileDesc);
    } else if (paymentSuccess) {
      msgDesc = thankuDesc;
    } else {
      msgDesc = CommitfailureDesc;
    }
    return msgDesc;
  };

  const getCommitMsgTitleHeading = (greatChoiceText, failureText) => {
    let title = '';
    if (paymentSuccess) {
      title = greatChoiceText;
    } else {
      title = failureText;
    }
    return title;
  };

  const getCommitMsgTitle = (thnakuHeading, failureHeading, holdFailureTitle) => {
    let title = '';
    if (viewState === ViewState.ERROR && holdResponse) {
      title = get(holdResponse, 'errorPageData.heading', holdFailureTitle);
    } else if (commitDetail && get(commitDetail, 'commitTitle', '')) {
      title = get(commitDetail, 'commitTitle', '');
    } else if (paymentSuccess) {
      title = thnakuHeading;
    } else {
      title = failureHeading;
    }
    return title;
  };

  const _openUpdatedBooking = (reloadSameBooking) => {
    resetToFlightBookingDetail(bookingId, '_insuranceRef', reloadSameBooking);
  };
  const fetchJSONAsync = async () => {
    try {
      FlightBookingTrackingHelper.trackClickEventNew(
        'Insurance_Post_Sale_Failure',
        'Insurance_Post_Sale_Payment_Failure_Retry',
        responseJson,
      );
      const apiResponse = await fetchInsHoldDetail(bookingId, uniqueCrId);
      const body = apiResponse;
      if (!isEmpty(body) && body.paymentDetails) {
        if (Platform.OS === 'ios') {
          FlightBookingModule.openInsurancePaymentPage(JSON.stringify(body));
        } else {
          FlightBookingModule.openAncillaryPaymentPage(JSON.stringify(body));
        }
        setHoldResponse(body);
        setViewState(ViewState.LOADED);
      } else {
        setViewState(ViewState.ERROR);
      }
    } catch (err) {
      setViewState(ViewState.ERROR);
    }
  };
  const retryPayment = () => {
    setViewState(ViewState.LOADING);
    setHoldResponse(null);
    setCommitDetail(null);
    fetchJSONAsync();
  };

  const title = getCommitMsgTitleHeading(greatChoiceText, failureText);
  const subTitle = getCommitMsgTitle(thnakuHeading, failureHeading, holdFailureTitle);
  const description = getCommitMsgDescription(thankuDesc, hloldFaileDesc, CommitfailureDesc);

  if (viewState === ViewState.LOADING) {
    return <PageLoader loaderMessage={''} />;
  }
  return (
    <View style={styles.pageWrapper}>
      <View>
        {paymentSuccess && (
          <View>
            <View style={styles.messageContent}>
              <Image style={styles.messageIcon} source={SUCCESS_TICK_ICON} />
              <Text style={[styles.messageTitle, fonts.boldFontFamily, fsStyle.font20]}>
                {title}
              </Text>
              <Text style={[styles.messageSubTitle, fonts.boldFontFamily, fsStyle.font20]}>
                {subTitle}
              </Text>
              <Text style={[styles.messageDesc, fsStyle.font16]}>{description}</Text>
            </View>
            <View style={styles.footerBtnWrapper}>
              <Buttons
                text={goBackToBookingText}
                type="Rounded"
                color="blue"
                onPressHandler={() => _openUpdatedBooking(true)}
              />
            </View>
          </View>
        )}

        {(!paymentSuccess || viewState === ViewState.ERROR) && (
          <View>
            <View style={styles.messageContent}>
              <Image style={styles.ErrorImage} source={CROSS_RED_CIRCLE_ICON} />
              <Text style={[styles.errMessageTitle, fonts.boldFontFamily, fsStyle.font20]}>
                {title}
              </Text>
              <Text style={[styles.messageSubTitle, fonts.boldFontFamily, fsStyle.font30]}>
                {subTitle}
              </Text>
              <Text style={[styles.messageDesc, fonts.regularFontFamily, fsStyle.font16]}>
                {description}
              </Text>
            </View>
            <View style={styles.footerBtnWrapper}>
              <Buttons
                text={tryAgainText}
                type="Rounded"
                color="blue"
                onPressHandler={retryPayment}
              />
              <TouchableOpacity
                style={[styles.goBackBtn, AtomicCss.marginTop28]}
                onPress={() => _openUpdatedBooking(false)}
                activeOpacity={0.7}
              >
                <Text style={[AtomicCss.azure, fsStyle.font18, fonts.boldFontFamily]}>
                  {goBackToBookingText}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        )}
      </View>
    </View>
  );
};

export default InsuranceThankYou;
