import { Dimensions, StyleSheet } from 'react-native';

const windowHeight = Dimensions.get('window').height;
const createStyles = (fonts, theme)=> StyleSheet.create({
  modalWrapper: {
    justifyContent: 'space-between',
    backgroundColor: theme.color.grey22,
    height: windowHeight - 150,
  },

  collapedContainer: {
    backgroundColor: theme.color.grey22,
    height: '100%',
  },

  answerRow: {
    flexDirection: 'row',
    marginLeft: 0,
  },

  bulletsPoints: {
    fontSize: 6,
    paddingHorizontal: 10,
    marginTop: 5,
    color: theme.color.textGrey,
  },
  InclusionHead: {
    fontSize: fonts.fontSize.font24,
    color: theme.color.black,
  },
  closeIconStyle: {
    width: 21,
    height: 21,
    marginTop: 5,
  },
  headerWrapper: {
    flexDirection: 'row',
    paddingRight: 20,
    paddingBottom: 20,
  },
});

export default createStyles;
