import { StyleSheet } from "react-native";
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
export default (theme, fonts) =>
  StyleSheet.create({
    tgsTopCard: {
      backgroundColor: theme.color.limeGreen,
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
      paddingVertical: 20,
    },
    title: {
      textAlign: 'center',
      color: "#0b4f57",
      ...(fonts.blackFontFamily),
      fontSize: 13,
    },
    info: {
      textAlign: 'center',
      marginTop: 6,
      color: theme.color.green,
      ...(fonts.regularFontFamily),
      fontSize: 14,
    },
    tgsWhiteCard: {
      ...getPlatformElevation(2),
      backgroundColor: theme.color.white,
      borderRadius: 16,
      padding: 12,
    },
    chartInfo: {
      backgroundColor: theme.color.grayBg,
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      paddingVertical: 8,
      paddingHorizontal: 11,
      flexDirection: 'row',
      alignItems: 'center',
    },
    chartInfoCancelled : {
      backgroundColor: theme.color.creamWhite,
    },
    greentickStyle: {
      width: 16,
      height: 16,
      marginRight: 10,
    },
    chartInfoTxt: {
      color: theme.color.green,
      fontSize: 12,
      width:"95%"
    },
    chartInfoCancelledTxt:{
      color: theme.color.lightYello,
      fontSize: 12,
      width:"95%"
    },
    invoiceBtn: {
      padding: 11,
      backgroundColor: theme.color.lighterBlue,
      borderRadius: 8,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      marginHorizontal: 20,
    },
    downloadIconStyle: {
      width: 24,
      height: 24,
      marginRight: 10,
    },
    btnTxt: {
      color: theme.color.primary,
      fontSize: 14,
      ...(fonts.boldFontFamily),
    },
    footerInfo: {
      justifyContent: 'center',
      alignItems: 'center',
      marginTop : 18
    },
    footerInfoText: {
      color: theme.color.green,
      fontSize: 12,
      ...(fonts.regularFontFamily),
    },
    ticketAdded: {
      backgroundColor: theme.color.limeGreen
    },
    ticketIssued: {
      backgroundColor: theme.color.creamWhite
    },
    ticketCancelled:{
      backgroundColor: theme.color.lightPink 
    },
    tgTitleWrapper: {
      flexDirection: 'row',
      paddingVertical: 20,
      paddingHorizontal: 10,
      justifyContent: 'center',
      alignItems: 'center'
    },
    tgsIconStyle: {
      width: 50,
      height: 32,
      marginRight: 13,
    },
    guaranteeTitleTxt: {
      color: theme.color.darkGrey4,
      ...(fonts.blackFontFamily),
      fontSize: 18,
    },
    
  });

  