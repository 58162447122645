export const UNDO_WEBCHECKIN_CONFIG_PROD = {
    FLIGHT_FIREBASE_DB_URL: 'https://undowebcheckin.firebaseio.com/',
    BASE_PATH: 'UndoWebCheckin/MMT/',
    CORP_BASE_PATH: 'UndoWebCheckin/MMTB2B/',
    TOKEN_KEY: 'UNDO_WEBCHECKIN_TOKEN',
  };

  export const UNDO_WEBCHECKIN_CONFIG_STAGING = {
    FLIGHT_FIREBASE_DB_URL: 'https://undowebcheckin-qa.firebaseio.com/',
    BASE_PATH: 'UndoWebCheckin/MMT/',
    CORP_BASE_PATH: 'UndoWebCheckin/MMTB2B/',
    TOKEN_KEY: 'UNDO_WEBCHECKIN_TOKEN',
  };

  export const LAST_MINUTE_FIREBASE_CONFIG_PROD = {
    FLIGHT_FIREBASE_DB_URL: 'https://tentative-cancellation.firebaseio.com/',
    BASE_PATH: 'TENTATIVE_CANCELLATION_DATA/MMT/',
    TOKEN_KEY: 'TENTATIVE_CANCELLATION_TOKEN',
  };

  export const LAST_MINUTE_FIREBASE_CONFIG_STAGING = {
    FLIGHT_FIREBASE_DB_URL: 'https://tentative-cancellation-qa.firebaseio.com/',
    BASE_PATH: 'TENTATIVE_CANCELLATION_DATA/MMT/',
    TOKEN_KEY: 'TENTATIVE_CANCELLATION_TOKEN',
  };

  export const FIREBASE_COMMON_CONFIG = {
    FLIGHT_FIREBASE_PROJECT: 'makemytrip-production',
    API_KEY_WEB: 'AIzaSyC9kiZU37TWEAyALu85vbMy0WLcWeB_z-Y',   //Will only work on Prod, for local use api key from firebase console
    STORAGE_BUCKET: 'makemytrip-production.appspot.com',
    MSG_SENDER_ID: '211828810752',
    APP_ID_WEB: '1:211828810752:web:194d9e94bb71ec781c24e5'
  };

  export const CAB_LIVE_TRACKING_CONFIG_STAGING = {
    CAB_FIREBASE_DB_URL: 'https://makemytrip-grounds-car-tracking-qa.firebaseio.com/',
    BASE_PATH: 'CAR_TRACKING_INFO/MMT/',
    TOKEN_KEY: 'CAR_REALTIMETRACKING_TOKEN',
  }

  export const CAB_LIVE_TRACKING_CONFIG_PROD = {
    CAB_FIREBASE_DB_URL: 'https://makemytrip-grounds-car-tracking.firebaseio.com/',
    BASE_PATH: 'CAR_TRACKING_INFO/MMT/',
    TOKEN_KEY: 'CAR_REALTIMETRACKING_TOKEN',
  }