import { StyleSheet } from 'react-native';

const createStyles = (fonts, theme) =>
  StyleSheet.create({
    innerCardWrapper: {
      padding: 16,
      marginBottom: 1,
      backgroundColor: theme.color.white,
    },
    headingText: {
      ...fonts.blackFontFamily,
      color: theme.color.defaultTextColor,
      fontSize: fonts.fontSize.font16,
      marginBottom: 6,
    },
    descriptionText: {
      fontSize: fonts.fontSize.font14,
      ...fonts.regularFontFamily,
      color: theme.color.defaultTextColor,
      marginBottom: 10,
    },
    contactIconStyle: {
      width: 20,
      height: 15,
      marginRight: 10,
    },
    contactValue: {
      ...fonts.blackFontFamily,
      fontSize: fonts.fontSize.font14,
      color: theme.color.black,
    },
  });
export default createStyles;
