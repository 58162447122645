import React from 'react';
import { getFont} from '../../PostSalesStyles';
import Header from '@Frontend_Ui_Lib_App/Header';
import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';
const crossIcon = require('@mmt/legacy-assets/src/close_icon.webp');


const HeaderWrapper = ({
  titleText,
  descText,
  leftIcon,
  closeIcon,
  clickHandler,
  wrapperStyle,
  titleStyle,
  descriptionStyle,
  iconStyle,
  rightComponent,
  titleNoOfLines,
  leftComponent,

}) => {

  const { fsStyle, ...fonts } = getFont(true);

  return (
      <Header
        title={titleText} 
        description= {descText}
        leftIcon= {
            {
                icon: closeIcon ? closeIcon : crossIcon,
                onPress: clickHandler,
                customStyles: {
                    iconStyle: [{ width: 14, height: 14 }, iconStyle],
                }
            }
        }
        customStyles={
            { 
                wrapperStyle: [getPlatformElevation(2),{zIndex: 2}, wrapperStyle],
                titleStyle: [fsStyle.font14,fonts.boldFontFamily, titleStyle],
                descriptionStyle: [descriptionStyle]
            }
        }
        rightComponent={rightComponent}
        leftComponent={leftComponent}
        titleNoOfLines={titleNoOfLines ? titleNoOfLines:1}
      />
  );
};

export default HeaderWrapper;