import { isNetworkAvailable } from '@mmt/legacy-commons/Common/utils/AppUtils';
import { getCommonHeaders } from '@mmt/legacy-commons/Helpers/genericHelper';
import { getIfCanEnableGrafanaLogger } from 'apps/rails/src/RailsAbConfig';
import {
  LOB,
  ORG,
  RAILS_GRAFANA_DATA,
  getDataFromAsynStorage,
  removeDataFromAsyncStorage,
  setDataToAsyncStorage,
} from 'apps/rails/src/Utils/RailsConstant';
import isEmpty from 'lodash/isEmpty';
import { Platform } from 'react-native';
import railsConfig from '../RailsConfig';
import fetch2 from '../fetch2';
import { Latency, EntityType, PageNames, ErrorTrack } from '../types/grafanaTracking.types';

const queueLogs: any = [];
let queueLogsInProgress = false;

async function queueForUploading(data: Latency | ErrorTrack) {
  try {
    queueLogsInProgress = true;
    let latencyData = await getDataFromAsynStorage(RAILS_GRAFANA_DATA);
    if (isEmpty(latencyData)) {
      latencyData = {};
    }
    if (data.errorMessage) {
      latencyData.errors = latencyData.errors ?? [];
      latencyData.errors.push(data);
    } else {
      latencyData.latencies = latencyData.latencies ?? [];
      latencyData.latencies.push(data);
    }
    await setDataToAsyncStorage(RAILS_GRAFANA_DATA, latencyData);
    const next = queueLogs.shift();
    if (next) {
      next();
    } else {
      queueLogsInProgress = false;
    }
  } catch (error) {
    console.error('Error in queueForUploading ', error);
  }
}

export const logGrafanaLatencyMetrics = (
  pageName: PageNames,
  entityType: EntityType,
  entityName: string,
  latencyType: string,
  latencyInMilli: number,
) => {
  try {
    if (__DEV__) {
      return;
    }
    if (latencyInMilli === 0) {
      throw { message: 'latencyInMilli cannot be zero' };
    } else if (
      isEmpty(pageName) ||
      isEmpty(entityType) ||
      isEmpty(entityName) ||
      isEmpty(latencyType)
    ) {
      throw { message: 'Logging details cannot be empty' };
    }
    const roundedLatency = Math.ceil(latencyInMilli);
    const latency = {
      pageName,
      entityType,
      entityName,
      latencyInMilli: roundedLatency,
      latencyType,
    };
    queueLogs.push(() => {
      queueForUploading(latency);
    });
    if (!queueLogsInProgress) {
      queueLogs.shift()();
    }
  } catch (error) {
    console.error('Error in logGrafanaMetrics ', error);
  }
};

export const logGrafanaErrorMetrics = (
  pageName: PageNames,
  entityType: EntityType,
  entityName: string,
  errorMessage: string,
  errorCode: number,
) => {
  try {
    if (__DEV__) {
      return;
    }
    if (isEmpty(errorMessage) || !errorCode) {
      throw { message: 'errorMessage or errorCode cannot be empty' };
    } else if (isEmpty(pageName) || isEmpty(entityType) || isEmpty(entityName)) {
      throw { message: 'Logging details cannot be empty' };
    }
    const error = {
      pageName,
      entityType,
      entityName,
      errorMessage,
      errorCode,
    };
    queueLogs.push(() => {
      queueForUploading(error);
    });
    if (!queueLogsInProgress) {
      queueLogs.shift()();
    }
  } catch (error) {
    console.error('Error in logGrafanaErrorMetrics ', error);
  }
};

export const uploadGrafanaMetrics = async () => {
  try {
    if (__DEV__) {
      return;
    }
    const latencies = await getDataFromAsynStorage(RAILS_GRAFANA_DATA);
    if (isEmpty(latencies)) {
      return 'NOTHING TO UPLOAD';
    }
    const metricBody = {
      organization: ORG,
      lobName: LOB,
      platform: Platform.OS,
      ...latencies,
    };
    if (getIfCanEnableGrafanaLogger() && isNetworkAvailable()) {
      const commonHeaders = await getCommonHeaders();
      const headers = {
        ...commonHeaders,
        'Content-Type': 'application/json',
      };
      const response = await fetch2(railsConfig.feMetricUrl, {
        method: 'POST',
        headers,
        body: JSON.stringify(metricBody),
      });
      if (response.status === 200) {
        removeDataFromAsyncStorage(RAILS_GRAFANA_DATA);
      }
    }
  } catch (error) {
    console.error('Error while logging new load metrics to grafana');
  }
};
