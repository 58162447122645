import React from 'react';
import { View, StyleSheet, Text, Image } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getFont } from '../../../../../../PostSalesStyles';
import isEmpty from 'lodash/isEmpty';
import LinearGradient from 'react-native-linear-gradient';

const AirlineDetail = ({ segmentInformation, transferMode }) => {
  const fonts = getFont(true);
  const { flightInfo } = segmentInformation || {};
  const { imageSrc, name, number: fltNo, OperatatedByObj } = flightInfo || {};
  return (
    <View style={AtomicCss.marginBottom15}>
    <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
      {!isEmpty(imageSrc) && <Image style={styles.fltIcon} source={{ uri: flightInfo.imageSrc }} />}
      <View style={AtomicCss.flex1}>
        {!isEmpty(name) && (
          <Text
            style={[
              AtomicCss.font14,
              AtomicCss.defaultText,
              fonts.regularFontFamily,
              AtomicCss.alignCenter,
              AtomicCss.marginBottom3,
              AtomicCss.alignLeft,
            ]}
          >
            <Text style={AtomicCss.blackText}>{name}</Text>
          </Text>
        )}
        {!isEmpty(fltNo) && (
          <Text
            style={[
              AtomicCss.font14,
              AtomicCss.defaultText,
              fonts.regularFontFamily,
              AtomicCss.alignCenter,
              AtomicCss.marginBottom3,
              AtomicCss.alignLeft,
            ]}
          >
            {fltNo}
          </Text>
        )}
        {!isEmpty(OperatatedByObj) && !isEmpty(OperatatedByObj.heading) && (
          <Text style={[styles.operatedByTxt, fonts.regularFontFamily]}>
            {OperatatedByObj.heading}
          </Text>
        )}
      </View>
    </View>
     {!isEmpty(transferMode) && (
      <LinearGradient start={{
          x: 1.0,
          y: 0.0,
        }}
        end={{
          x: 0.0,
          y: 1.0,
        }}
        style={styles.transferMode}
        colors={['#EF8050', '#ED605C']}
      >
        <Text style={[
          AtomicCss.font12,
          AtomicCss.whiteText,
          fonts.regularFontFamily,
        ]}>{transferMode}</Text>
      </LinearGradient>
    )}
    </View>
  );
};

export default AirlineDetail;

const styles = StyleSheet.create({
  fltIcon: {
    width: 24,
    height: 24,
    borderRadius: 4,
    marginRight: 12,
  },
  duration: {
    borderBottomWidth: 1,
    borderBottomColor: '#747474',
    paddingBottom: 4,
    alignSelf: 'flex-start',
  },
  operatedByTxt: {
    color: '#cf8100',
    fontSize: 14,
  },
  transferMode: {
    paddingHorizontal: 6,
    paddingVertical: 6,
    borderRadius: 4,
    marginTop: 5,
    flexDirection: 'row',
    alignSelf: 'flex-start'
  }
});
