import React from 'react';
import {Image, Text, View} from 'react-native';
import styles from '../../acme/details/components/InclusionExclusionCss';
import PropTypes from 'prop-types';
import AtomicCss from '../../acme/details/AtomicCss';
import _ from 'lodash';
import CardWrapper from '../modules/bookingDetails/components/CardWrapper';

const mainCardIcon = require('@mmt/legacy-assets/src/covidCard.webp');
const inclusionIcon = require('@mmt/legacy-assets/src/green.webp');

const MySafetyCard  = ({response,card}) => {
      return (
        <View style={[AtomicCss.whiteBg, AtomicCss.marginBottom10]}>
          <View style={[styles.CardContentFull, AtomicCss.paddingBottom15]}>
            <View style={[styles.CardContent]}>
              <Image style={styles.CardIcon} source={mainCardIcon} />
              <View style={AtomicCss.flex1}>
                <Text
                  style={[AtomicCss.blackFont, AtomicCss.blackText, AtomicCss.font14, AtomicCss.marginBottom24]}>
                    {card.heading}  <Text style={[AtomicCss.font12, AtomicCss.regularFont]}>{card.subheading}</Text>
                </Text>
                  {
                      response && response.carAdditionalInfo && response.carAdditionalInfo.additionalLOBInfo &&  response.carAdditionalInfo.additionalLOBInfo.tripInfo &&
                      !_.isEmpty(response.carAdditionalInfo.additionalLOBInfo.tripInfo.safety) &&
                      response.carAdditionalInfo.additionalLOBInfo.tripInfo.safety.map((item) => (
                          <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom18]}>
                              <Image style={styles.activityTick} source={inclusionIcon} />
                              <Text style={[AtomicCss.flex1,AtomicCss.regularFont, AtomicCss.font14, AtomicCss.blackText]}>{item}</Text>
                          </View>
                      ))
                  }
              </View>
            </View>
          </View>
        </View>
      );
}
MySafetyCard.propTypes = {
  response: PropTypes.object.isRequired,
  card: PropTypes.object.isRequired,
  pageName: PropTypes.string.isRequired
};

export default CardWrapper(MySafetyCard);
