import { trackOmniture } from '@mmt/legacy-commons/Common/utils/OmnitureTrackerUtils';
import { getDateDetails } from 'apps/rails/src/Utils/RisUtils';
import { getFormattedDate } from '@mmt/legacy-commons/Helpers/dateHelpers';
import { getTrackingPageName } from './TgsBookingDetailUtil';
import TgsBookingEventsLogger from './TGSBookingEventsLogger';

class TGSBookingTrackEventHelper {
  trackClickEvent = (pageName, tgsResponse, clickEvent) => {
    TgsBookingEventsLogger.clickEvent(pageName, clickEvent, tgsResponse);
    let params = {};
    params = this.getBasicOminitureVariables(pageName, tgsResponse);
    params.m_c54 = `Mob_CustomerSupport_TGS_${pageName}_${clickEvent}`;
    trackOmniture(`Mob_CustomerSupport_TGS_${pageName}`, params);
  };

  trackClickEvents = (pageName, clickEvent) => {
    TgsBookingEventsLogger.clickEvent(pageName, clickEvent);
    const params = {};
    params.m_c54 = `Mob_CustomerSupport_TGS_${pageName}_${clickEvent}`;
    trackOmniture(`Mob_CustomerSupport_TGS_${pageName}`, params);
  };

  getBasicOminitureVariables = (pageName, tgsResponse) => {
    const params = {};
    if (tgsResponse !== undefined) {
      // params.m_v35 = tgsResponse.primaryContactNumber;
      params.m_v16 = tgsResponse.bookingID;
      // params.m_v34 = tgsResponse.primaryEmailID;
      params.m_v9 = tgsResponse.bookingStatus;
      if (
        tgsResponse &&
        tgsResponse.train &&
        tgsResponse.train[0] &&
        tgsResponse.train[0].pnrDetails &&
        tgsResponse.train[0].pnrDetails[0] &&
        tgsResponse.train[0].pnrDetails[0].segmentInfo &&
        tgsResponse.train[0].pnrDetails[0].segmentInfo[0]
      ) {
        const segmentInfo = tgsResponse.train[0].pnrDetails[0].segmentInfo[0];
        params.m_v3 = `${segmentInfo.originCityName} | ${segmentInfo.destinationCityName}`;
        const dateDetails = getDateDetails(segmentInfo.boardingDate);
        const { dayDifference, dateNumeric } = dateDetails || {};
        params.m_v7 = dayDifference;
        params.m_v4 = getFormattedDate(dateNumeric, 'DD-MM-YYYY', 'DD:MM:YYYY');
      }
    }
    params.m_v24 = 'TGS';
    params.m_v15 = `Mob:Customer Support:TGS:${pageName}`;
    params.m_pageName = `Mob:Customer Support:TGS:${pageName}`;
    return params;
  };

  trackLoadEvent = (pageName, tgsResponse) => {
    TgsBookingEventsLogger.loadEvent(pageName, tgsResponse);
    let params = {};
    params = this.getBasicOminitureVariables(pageName, tgsResponse);
    trackOmniture(`Mob:Customer Support:TGS:${pageName}`, params);
  };

  trackErrorEvent = (pageName) => {
    TgsBookingEventsLogger.errorEvent(pageName);
    const params = {};
    const fullPageName =
      pageName !== undefined
        ? `Mob_CustomerSupport_TGS_${pageName}_Load_Error`
        : 'Mob_CustomerSupport_TGS_Load_Error';
    params.pageName = fullPageName;
    params.m_v22 = fullPageName;
    trackOmniture(fullPageName, params);
  };

  trackBannerShow = (bannerName, pageName) => {
    TgsBookingEventsLogger.loadEvent(pageName, bannerName);
    const params = {};
    params.prop54 = bannerName;
    trackOmniture(pageName, params);
  };
}

export default new TGSBookingTrackEventHelper();
