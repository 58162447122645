import React from 'react';
import PropTypes from 'prop-types';
import {Image, StyleSheet, Text, View} from 'react-native';
import AtomicCss from '../AtomicCss';
import {
  fillDateAndTime,
  getTimeInMillis,
  isNotNullAndEmpty
} from '../../../hotel/details/utils/HotelBookingDetailUtil';
import {getActionButton, getButtonStyle, handleClickEvent} from '../../utils/AcmeBookingDetailUtil';
import {policyImg} from '../../utils/AcmeImageConstant';

class AcmeCancellationPolicyCard extends React.Component {
  render() {
    const policyData = this.getCancellationPolicyObject();
    return (
      <View style={[AtomicCss.whiteBg, AtomicCss.marginBottom10]}>
        <View style={styles.CardContentFull}>
          <View style={[styles.CardContent, AtomicCss.alignCenter]}>
            <Image
              style={styles.CardIcon}
              source={policyImg}
            />
            <View style={AtomicCss.flex1}>
              <Text style={[AtomicCss.blackFont, AtomicCss.blackText, AtomicCss.font14]}>
                {policyData.title}
              </Text>
            </View>
          </View>
          <View style={[AtomicCss.flex1, AtomicCss.paddingLeft30, AtomicCss.paddingRight16]}>
            <Text style={policyData.style}>
              {policyData.subtitle}
            </Text>
          </View>
        </View>
        <View
          style={isNotNullAndEmpty(this.props.card.actionList)
            ? getButtonStyle(this.props.card.actionList)
            : styles.hideView}
        >
          {getActionButton(this.props.card, this.props.response, this.handleClick)}
        </View>
      </View>
    );
  }

  handleClick = (action) => {
    handleClickEvent(this.props.card, action, this.props.response, this.props.pageName);
  };

  getCancellationPolicyObject() {
    const result = {};
    const currentTime = new Date().getTime();
    const freeCancellationEligibiltyDate = this.props.response.acmeBookingInfo.acmeBookingDetails[0].freeCancellationEligibityDate;
    if (getTimeInMillis(freeCancellationEligibiltyDate) < currentTime) {
      result.title = 'Cancellation charges are applicable';
      result.subtitle = 'Free cancellation has expired for this booking. Cancellation charges are applicable';
      result.style = styles.redText;
    } else {
      const dateToShow = fillDateAndTime(freeCancellationEligibiltyDate, 'DD MMM');
      result.title = `Free cancellation till ${dateToShow}`;
      result.subtitle = 'Get full refund if you cancel today. Partial cancellation is not allowed.';
      result.style = styles.greenText;
    }
    return result;
  }
}

const styles = StyleSheet.create({
  CardContentFull: {
    backgroundColor: '#fff',
    borderBottomWidth: 1,
    borderBottomColor: '#e4e4e4',
    padding: 15,
    elevation: 1
  },
  CardContent: {
    backgroundColor: '#fff',
    flexDirection: 'row'
  },
  CardIcon: {
    width: 24,
    height: 24,
    marginRight: 10
  },
  greenText: {
    color: '#1a7971',
    fontSize: 14,
    lineHeight: 20,
    marginBottom: 20
  },
  redText: {
    color: '#e53442',
    fontSize: 14,
    lineHeight: 20,
    marginBottom: 20
  }
});

AcmeCancellationPolicyCard.propTypes = {
  response: PropTypes.object.isRequired,
  card: PropTypes.object.isRequired,
  pageName: PropTypes.string.isRequired
};

export default AcmeCancellationPolicyCard;
