import React from 'react';
import {
  View,
  Text,
  ScrollView,
  StyleSheet,
  Alert,
  Platform,
  BackHandler,
  TouchableOpacity,
  Image,
} from 'react-native';
import ViewControllerModule from '@mmt/legacy-commons/Native/ViewControllerModule';
import Actions from '../../../navigation/postSalesNavigation';
import { fonts } from '@mmt/legacy-commons/Styles/globalStyles';
import TdrFormComponent from './TdrFormComponent';
import OverlayAlert from '../../components/OverlayAlert/OverlayAlert';
import StickyHeader from '../../components/StickyHeaderBack/StickyHeader';
import Breadcrumb from '../../../Common/Breadcrumb';
import { trainFromToSubTitleWithDate, isFloat } from '../../details/RailBookingDetailsUtil';
import RecBlueBtn from '@mmt/legacy-commons/Common/Components/Buttons/RecBlueBtn';
import RailBookingTrackingHelper from '../../details/RailBookingTrackEventHelper';
import TdrDatePicker from '../TdrDatePicker';
import { getStaticData } from '../../../staticData/staticData';
import { getFont } from '../../../PostSalesStyles';
import { CALENDAR_ICON } from '../../Utils/RailImageConstant';
import DobCalendar from '../../../Common/Calendar';
import { FileTdrConstants } from '../../Utils/constants';

export const TDR_OVERLAY_TITLE = 'Please enter GC/EFT details.';
export const STEPS = ['SELECT REASON', 'SELECT TRAVELLERS AND CONFIRM'];

class TdrForm extends React.Component {
  static navigationOptions = {
    header: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      showOverlay: false,
      popOverContent: null,
      showCalenderPopup: false,
      EFTDate: null,
    };
    this.EFTNumber = '';
    this.EFTAmount = '';
    this.DATE_PICKER_MIN_DATE = this.props.tdrRules.tdrLayoutResponse.tdrForm.datePickerMinDate;
    this.DATE_PICKER_MAX_DATE = this.props.tdrRules.tdrLayoutResponse.tdrForm.datePickerMaxDate;
    this.STEP = this.props.tdrRules.tdrLayoutResponse.tdrForm.steps;
    this.HEADER_TEXT = this.props.tdrRules.tdrLayoutResponse.tdrForm.headerText;
    this.BOTTOM_BUTTON = this.props.tdrRules.tdrLayoutResponse.tdrForm.bottomButton;
    this.EFTEC_NUMBER_HEADING = this.props.tdrRules.tdrLayoutResponse.tdrForm.eftECNumberHeading;
    this.EFTEC_AMOUNT_HEADING = this.props.tdrRules.tdrLayoutResponse.tdrForm.eftECAmountHeading;
    this.EFTEC_DATE_HEADING = this.props.tdrRules.tdrLayoutResponse.tdrForm.eftECDateHeading;
    this.TDR_OVERLAY_TITLES =
      this.props.tdrRules.tdrLayoutResponse.tdrForm.overlayAlert.tdrOverlayTitle;
    this.SUB_TITLE_TEXT = this.props.tdrRules.tdrLayoutResponse.tdrForm.overlayAlert.subTitleText;
    this.POSITIVE_BTN_TEXT =
      this.props.tdrRules.tdrLayoutResponse.tdrForm.overlayAlert.positiveBtnText;
    this.NEGATIVE_BTN_TEXT =
      this.props.tdrRules.tdrLayoutResponse.tdrForm.overlayAlert.negativeBtnText;
    this.UPDATE_AMOUNT_ALERT_TITLE =
      this.props.tdrRules.tdrLayoutResponse.tdrForm.updateAmountAlertTitle;
    this.UPDATE_AMOUNT_ALERT_MSG =
      this.props.tdrRules.tdrLayoutResponse.tdrForm.updateAmountAlertMsg;
  }

  componentWillMount() {
    BackHandler.addEventListener('hardwareBackPress', this.onHardBackPress);
  }

  onHardBackPress = () => {
    if (Platform.OS === 'ios') {
      ViewControllerModule.popViewController(this.props.rootTag);
    } else {
      BackHandler.exitApp();
    }
    return false;
  };

  componentWillUnmount() {
    BackHandler.removeEventListener('hardwareBackPress', this.onHardBackPress);
  }

  setPopOverContent = (cal) => {
    this.setState({ popOverContent: cal });
  };

  closeShowCalenderPopup = () => {
    this.setState({ showCalenderPopup: false });
  };

  dateSelectOverlay = () => {
    this.setState({ showCalenderPopup: true });
  };

  render() {
    const { fsStyle, ...fonts } = getFont(true);
    const staticData = getStaticData();
    const { pleaseFillDetailsText } = staticData;
    return (
      <View style={styles.TdrFormWrapper}>
        <ScrollView>
          <View style={styles.formWrapper}>
            <StickyHeader
              heading={this.HEADER_TEXT}
              subheading={trainFromToSubTitleWithDate(this.props.bookingDetails.train)}
            />
            <Breadcrumb activeStep={1} data={this.STEP} />
            <View style={styles.headingWrapper}>
              <Text style={[styles.headingTxt, fsStyle.font16, fonts.boldFontFamily]}>
                {pleaseFillDetailsText}
              </Text>
            </View>
            <TdrFormComponent
              label={this.EFTEC_NUMBER_HEADING}
              placeholderTxt={this.EFTEC_NUMBER_HEADING}
              updateFunction={this.updateNumber}
            />
            <TdrFormComponent
              label={this.EFTEC_AMOUNT_HEADING}
              placeholderTxt={this.EFTEC_AMOUNT_HEADING}
              updateFunction={this.updateAmount}
            />
            <View style={styles.datePickerStyle}>
              <Text style={[styles.fieldTxt, fsStyle.font12, fonts.boldFontFamily]}>
                {this.EFTEC_DATE_HEADING}
              </Text>
              <View style={styles.dateInput}>
                <Text style={{ marginTop: 8, fontFamily: fonts.robotoBold }}>
                  {this.state.EFTDate}
                </Text>
                <TouchableOpacity activeOpacity={0.5} onPress={this.dateSelectOverlay}>
                  <Image
                    style={{
                      width: 15,
                      height: 15,
                      position: 'absolute',
                      right: 10,
                      paddingLeft: 10,
                      top: 12,
                      zIndex: 0,
                    }}
                    source={CALENDAR_ICON}
                  />
                </TouchableOpacity>
              </View>
            </View>

            {this.state.showOverlay && (
              <OverlayAlert
                positiveBtnText={this.POSITIVE_BTN_TEXT}
                negativeBtnText={this.NEGATIVE_BTN_TEXT}
                positiveBtnOnTap={this.goToTdrForm}
                negativeBtnOnTap={this.goBack}
                titleText={this.TDR_OVERLAY_TITLES}
                subTitleText={this.SUB_TITLE_TEXT}
                bgTapAction={this.bgTapped}
              />
            )}
          </View>
        </ScrollView>
        <View style={styles.btnLocation}>
          <RecBlueBtn label={this.BOTTOM_BUTTON} onPress={this.filTdr} />
        </View>
        {this.state.showCalenderPopup && (
          <DobCalendar
            handleClose={this.closeShowCalenderPopup}
            confirmPress={(date) => {
              this.updateDate(date);
            }}
            initDOB={this.state.EFTDate}
            confirmBtnTitle={FileTdrConstants.SAVE_DATE}
            popupTitle={FileTdrConstants.SELECT_EC_DATE}
            confirmBtnStyle={styles.confirmBtnStyle}
          />
        )}
      </View>
    );
  }
  bgTapped = () => {
    this.setState({
      showOverlay: false,
    });
  };

  goBack = () => {
    Actions.pop();
  };

  filTdr = () => {
    const request = this.props.request;
    request.gcNumer = this.EFTNumber;
    request.gcAmount = this.EFTAmount;
    request.gcDate = this.state.EFTDate;

    Actions.tdrThankYou({
      request,
      selectedReason: this.props.selectedReason,
      bookingDetails: this.props.bookingDetails,
      tdrAddress: this.props.tdrAddress,
      tdrRules: this.props.tdrRules,
    });
    RailBookingTrackingHelper.trackClickEvent(
      'TdrForm',
      this.props.bookingDetails,
      'FiledTdrFromECGForm',
    );
  };

  handleAlert = (title, msg) => {
    Alert.alert(title, msg);
  };

  updateNumber = (data) => {
    this.EFTNumber = data;
  };

  updateAmount = (data) => {
    if (!isFloat(data)) {
      this.handleAlert(this.UPDATE_AMOUNT_ALERT_TITLE, this.UPDATE_AMOUNT_ALERT_MSG);
    }
    this.EFTAmount = data;
  };

  updateDate = (data) => {
    this.setState({ EFTDate: data });
    this.closeShowCalenderPopup();
  };
}
const styles = StyleSheet.create({
  dateInput: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexDirection: 'row',
    flex: 1,
    paddingLeft: 8,
    borderWidth: 1,
    borderColor: '#bebebe',
    fontSize: 14,
    color: '#000',
    borderRadius: 4,
    height: 40,
  },
  formWrapper: {
    backgroundColor: '#fff',
    flex: 1,
    padding: 16,
  },
  btnLocation: {
    bottom: 0,
  },
  headingWrapper: {
    marginBottom: 10,
    paddingBottom: 10,
    marginTop: 10,
  },
  fieldTxt: {
    marginBottom: 10,
    color: '#000',
  },
  headingTxt: {
    color: '#000',
  },
  datePickerStyle: {
    marginBottom: 30,
  },
  TdrFormWrapper: {
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: '#f2f2f2',
  },
  popOverContent: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 2,
  },
  confirmBtnStyle: {
    marginVertical: 20,
    color: '#000',
  },
});
export default TdrForm;
