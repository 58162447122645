import React from 'react';
import isEmpty from 'lodash/isEmpty';
import {View, Image, StyleSheet, Text} from 'react-native';
import PastPaymentCard from './PastPaymentCards';
import {fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import { getFont } from '../../PostSalesStyles';
import getPlatformElevation from './getPlatformElevation';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import BasePage from '../../Common/PostSalesBasePage';
import {clockIcon} from '../HolidayBookingConstants';

class PastPayments extends BasePage {
  render() {
    const {paymentDetails} = this.props ;
    const {amountPaidBreakup} = paymentDetails || {};
    const themeFonts = getFont();


    return (
      <View style={AtomicCss.marginBottom10}>
        <View style={styles.headingTitle}>
          <Text style={[AtomicCss.blackFont, AtomicCss.font16, AtomicCss.blackText]}>Past
            Payments</Text>
        </View>
        <PastPaymentCard {...this.props}/>
        <View style={[styles.totalPrice]}>
          <View
            style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.spaceBetween, AtomicCss.marginBottom10]}>
            <Text style={[styles.totalPricetxt, AtomicCss.font16]}>TOTAL PAID</Text>
            <Text
              style={[styles.totalPricetxt, AtomicCss.font18]}>{paymentDetails.totalAmountReceived}{' '}</Text>
          </View>
          <View>
          {!isEmpty(amountPaidBreakup) && !isEmpty(amountPaidBreakup.values) && amountPaidBreakup.values.map((breakup)=> 
            <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween, AtomicCss.marginBottom5]}>
              <Text style={[AtomicCss.blackText, themeFonts.boldFontFamily , themeFonts.font14, AtomicCss.flex1]}>{breakup.label}
                <Text style={[AtomicCss.midGreyText, themeFonts.font12, themeFonts.regularFontFamily]}>{breakup.tcsInfo}</Text></Text>
              <Text style={[AtomicCss.blackText, themeFonts.boldFontFamily, themeFonts.font14]}>{breakup.value}</Text>
            </View>)
          }
          </View>
          {!isEmpty(paymentDetails.amountToBePaid) &&
          <View style={[styles.remainingPaymentSection, AtomicCss.alignCenter]}>
            <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
              <Image style={styles.clockIconStyle} source={clockIcon}/>
              <Text style={[AtomicCss.blackFont, styles.orangeText, AtomicCss.font12]}> Remaining
                Payment</Text>
            </View>
            <Text
              style={[AtomicCss.blackFont, styles.orangeText, AtomicCss.font12]}>{paymentDetails.amountToBePaid}{' '}</Text>
          </View>
          }
        </View>
      </View>
    );
  }
}


const styles = StyleSheet.create({
  PriceBrekupWrapper: {
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: '#f2f2f2'
  },
  headingTitle: {
    padding: 14,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 2,
    backgroundColor: '#fff',
    ...getPlatformElevation(2)
  },

  totalPrice: {
    paddingHorizontal: 16,
    paddingVertical: 14,
    marginTop: 1,
    backgroundColor: '#fff',
    borderBottomWidth: 1,
    borderBottomColor: '#ddd'
  },
  totalPricetxt: {
    fontSize: 16,
    fontFamily: fonts.black,
    color: '#4a4a4a'
  },
  remainingPaymentSection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 10,
    paddingVertical: 5,
    backgroundColor: '#ffedd1',
    borderRadius: 3,
    marginHorizontal: -8
  },
  clockIconStyle: {
    width: 20,
    height: 20,
    marginRight: 10
  },
  orangeText: {
    color: '#cf8100'

  }
});

export default PastPayments;
