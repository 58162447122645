import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {StyleSheet} from 'react-native';

export const getStyle = (fonts, color) => {
    return StyleSheet.create({
      cardContainer: {
        backgroundColor: color.blackWithOpacity,
        borderRadius: 4,
        paddingHorizontal: 10,
        paddingTop: 10,
        marginTop: 5,
        marginHorizontal:20,
        borderLeftWidth: 3,
        borderLeftColor: color.yello,
      },
      cardText: {
        ...AtomicCss.flexRow,
        ...AtomicCss.alignCenter,
        ...AtomicCss.marginBottom10,
      },
      contactUsText: {
        color: color.lightBlue17,
        ...fonts.regularFontFamily
      }
    });
  }

  export const getHtmlStyles = (fonts, color) => {
      return StyleSheet.create({
        div: {
          ...fonts.regularFontFamily,
          color: color.black
        },
        b: {
          ...fonts.blackFontFamily,
        },
        span: {
          color: color.lightSeaGreenTextColor
        }
      });
  }

