import React from 'react';
import {View} from "react-native"
import isEmpty from 'lodash/isEmpty';
import WhyBookUs from './WhyBookUs';
import BookReturnTicket from "./BookReturnTicket"
import NextOffer from "./NextOffer"
const TripGuaranteeFeatures = ({ response }) => {
    const { tgsFeatures } = response || {};
    const { whyBookUs, nextOffer, returnTicket } = tgsFeatures || {};
    return (
        <View>
            {!isEmpty(tgsFeatures) ?
            <>
            {!isEmpty(whyBookUs) ? <WhyBookUs data={whyBookUs} /> : null}
            {!isEmpty(nextOffer) ? <NextOffer data={nextOffer} /> : null}
            {!isEmpty(returnTicket) ? <BookReturnTicket data={returnTicket} /> : null}
            </> : null}
        </View>

    )
}


export default TripGuaranteeFeatures