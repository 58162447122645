import { StyleSheet } from 'react-native';

const getStyle = (theme) => {
  const {color} = theme;
  return StyleSheet.create({
   OverlayContentWrapper: {
     backgroundColor: color.white,
     paddingTop: 12,
     paddingHorizontal: 10,
     position: 'relative',
     zIndex: 1,
     width: '100%',
     borderTopLeftRadius: 8,
     borderTopRightRadius: 8,
     paddingBottom: 50,
   },
   uploadProofWrapper: {
     //paddingHorizontal:16,
   },
   overlayTopline: {
     width: 40,
     height: 4,
     borderRadius: 4,
     backgroundColor: color.disabledButton,
     alignSelf: 'center',
   },
   title: {
     color: color.black,
     marginBottom: 30,
     lineHeight: 32,
   },
   uploadProofStyle: {
     width: 48,
     height: 48,
     marginBottom: 16,
   },
   strokeStyle: {
     width: 30,
     height: 4,
     backgroundColor: color.azure,
     marginBottom: 26,
   },
   lineHeight22: { lineHeight: 22 },
   marginBottom60: {
     marginBottom: 60,
   },
 });
}

export default getStyle;
