import React from 'react';
import AdvisoryNotice from '../../../../../Common/AdvisoryNotice';
import CardWrapper from '../CardWrapper';

const AdvisoryNoticeCard: React.FC<any> = (props) => {
  return (
    <>
      <AdvisoryNotice {...props} />
    </>
  );
};

export default CardWrapper(AdvisoryNoticeCard);
