import {fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
import { themeColors } from '../../../PostSalesStyles';

const styles = {
  cardBorder: {
    borderTopColor: '#e4e4e4',
    borderBottomColor: '#e4e4e4',
    borderTopWidth: 1,
    borderBottomWidth: 1
  },
  infoCardWrapper: {
    backgroundColor: '#fff',
    justifyContent: 'space-between',
    ...getPlatformElevation(2),
    marginBottom: 1,
    paddingBottom: 10

  },
  headingSection: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 15,
    paddingHorizontal: 16
  },
  infoSection: {
    flexDirection: 'row',
    padding: 10,
    marginLeft: 15,
    marginRight: 15,
    marginBottom: 15,
    backgroundColor: '#ffedd1',
    borderRadius: 4

  },
  infoSectionTxt: {
    color: '#cf8100',
    fontFamily: fonts.regular,
    fontSize: 14,
    flex: 1
  },
  infSectionTxtBold: {
    fontFamily: fonts.bold,
    fontWeight: 'bold'
  },
  errorIconStyle: {
    width: 16,
    height: 16,
    marginRight: 10,
    tintColor: '#db7e00',
    top: 1

  },
  headingIconStyle: {
    width: 20,
    height: 19,
    marginRight: 10

  },
  innerDtlsWrapper: {
    paddingHorizontal: 15
  },
  redirectBtnWrapper: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 60,
    paddingRight: 35,
    justifyContent: 'space-between',
    flexDirection: 'row',
    backgroundColor: '#fff',
    borderBottomWidth: 1,
    borderBottomColor: '#ededed',
    alignItems: 'center'

  },

  rightArrowIcon: {
    width: 9,
    height: 13,
    tintColor: themeColors.rightArrowTintColor
  },

  linkBtnTxt: {
    fontSize: 14,
    fontFamily: fonts.bold,
    color: themeColors.linkTextColor
  },
  applicantRowSection: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 15
  },
  rowLeft: {
    width: 120
  },
  rowLeftText: {
    color: '#747474',
    fontFamily: fonts.regular,
    fontSize: 12
  },
  title: {
    color: '#000',
    fontFamily: fonts.bold,
    fontSize: 14
  },
  applicantAgeTxt: {
    color: '#4a4a4a',
    fontFamily: fonts.regular,
    fontSize: 12
  },
  moreInfo: {
    fontFamily: fonts.regular,
    color: '#747474',
    fontSize: 11,
    marginTop: 4
  },
  statusTxt: {
    color: '#00a49d'
  },
  pendingStatus: {
    color: '#db7e00', fontFamily: fonts.bold
  }
};


export default styles;
