import { StyleSheet } from 'react-native';

const createStyles = ( theme) =>
  StyleSheet.create({
    visaSelectCard: {
      marginBottom: 10,
      flexDirection: 'row',
    },
    travllerNameWrapper: {
      paddingBottom: 15,
      flex: 1,
      marginLeft: 10,
    },
    checkbox: {
      height: 20,
      width: 20,
      backgroundColor: theme.color.white,
      borderColor: theme.color.lightTextColor,
      borderWidth: 1.5,
      borderRadius: 3,
      overflow: 'hidden',
      position: 'relative',
    },

    checkboxSelected: {
      borderColor: 'transparent',
      borderWidth: 0,
    },
    checkedIcon: {
      position: 'absolute',
      height: 22,
      width: 22,
      left: -1,
      top: -1,
    },
  });
export default createStyles;
