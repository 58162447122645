import { StyleSheet, Platform } from 'react-native';
import { ps_colors } from '../../../../../PostSalesStyles';

const createStyle = (theme) => StyleSheet.create({
  thankYouWrapper: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.color.white,
    paddingHorizontal: '10%',
  },
  transparantBgrnd: {
    ...Platform.select({
      ios: {
        backgroundColor: 'transparent',
      },
    }),
  },
  thankYouHeader: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 47,
  },
  headerIcon: {
    width: 24,
    height: 32,
    marginRight: 7,
  },
  headerDesc: {
    fontSize: 12,
    color: theme.color.lightPurple,
  },
  successIcon: {
    width: 40,
    height: 40,
    marginRight: 15,
    marginBottom: 10,
    alignSelf: 'center',
  },
  messageText: {
    textAlign: 'center',
    fontSize: 12,
    color: theme.color.black,
  },
  CommonFillBtn: {
    width: '100%',
  },
  CommonFillBtnGradient: {
    borderRadius: 25,
    paddingVertical: 11,
    paddingHorizontal: 25,
    marginTop: 24,
  },
  hideView: {
    display: 'none',
  },
  fullPage: {
    backgroundColor: theme.color.white,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  msgSection: {
    paddingHorizontal: 20,
    textAlign: 'center',
  },
  titleHeading: {
    color: theme.color.darkCyan,
    marginBottom: 24,
    textAlign: 'center',
  },
  goBackToBookingSection: {
    marginTop: 20,
  },
  goBackToBookingText: {
    color: theme.color.azure,
    textTransform: 'capitalize',
  },
  progressContainer: {
    alignItems: 'center',
  },
  contactUsWrapper: {
    marginTop: 44,
  },
});

export const createHtmlStyle = (theme) => StyleSheet.create({
  div: {
    color: theme.color.black,
    textAlign: 'center',
  },
  span: {
    color: theme.color.black,
  },
  b: {
    color: theme.color.black,
  },
});

export default createStyle;
