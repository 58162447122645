import React from 'react';
import SkeltonLoaderCard from './skeltonLoaderCard';

const SkeltonLoaderComponent = ({ cardCount, gradientColor }) => {
  return [...Array(cardCount).keys()].map((cardNumber) => (
    <SkeltonLoaderCard key={cardNumber} gradientColor={gradientColor} />
  ));
};

export default SkeltonLoaderComponent;
