import React from 'react';
import { View, Text, Image, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import AtomicCss from '../../commonStyles/AtomicCss';
import {
  passengerImage,
  passengerName,
  passengerAgeAndGenderText,
} from '../../utils/BusBookingDetailUtil';
import { label } from '@mmt/post-sales/src/staticData/staticData';
import { getFont } from '../../../PostSalesStyles';

class CancellationBreakup extends React.Component {
  static navigationOptions = {
    header: null,
  };

  render() {
    return (
      <View
        style={[styles.borderbtm, styles.bggreyLight, styles.CancelPerson, AtomicCss.elevation1]}
      >
        {this.renderHeaderText()}
        {this.renderPaxView()}
      </View>
    );
  }

  renderHeaderText = () => {
    let paxCount = this.props.passengers && this.props.passengers.length || 0;

    const travelerText =
      paxCount > 1
        ? label('bus.travellers', { capitalize: true })
        : label('bus.traveller', { capitalize: true });
      
    const fonts = getFont(true);
    return (
      <Text
        style={[
          AtomicCss.blackText,
          AtomicCss.marginBottom10,
          fonts.font14,
          fonts.blackFontFamily,
        ]}
      >
        {label('bus.cancellation.cancellingPaxTraveller', null, { paxCount, travelerText })}
      </Text>
    );
  };
  renderPaxView = () => {
    const views = [];
    if (this.props.passengers) {
      this.props.passengers.forEach((passenger) => {
        views.push(this.passengerView(passenger));
      });
    }
    return views;
  };

  passengerView = (passenger) => {
    const fonts = getFont(true);
    return (
      <View
        style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom10]}
        key={`${passenger.paxId}`}
      >
        <Image style={[styles.femaleIcon, styles.mar8LR]} source={passengerImage(passenger)} />
        <Text style={[fonts.font12, AtomicCss.blackText]}>{`${passengerName(passenger)} `}</Text>
        <Text style={[fonts.font12, AtomicCss.lightGrey]}>
          {passengerAgeAndGenderText(passenger)}
        </Text>
      </View>
    );
  }
}

CancellationBreakup.propTypes = {
  passengers: PropTypes.array.isRequired,
};
const styles = StyleSheet.create({
  borderbtm: {
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
  },
  bggreyLight: {
    backgroundColor: '#f9f9f9',
  },
  CancelPerson: { paddingVertical: 12, paddingHorizontal: 16 },
  femaleIcon: {
    width: 16,
    height: 16,
  },
  mar8LR: { marginHorizontal: 8 },
});

export default CancellationBreakup;
