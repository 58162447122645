import React from 'react';
import Actions from '../../../../navigation/postSalesNavigation';
import NoInternetView from '@mmt/legacy-commons/Common/Components/Error/NoInternetView';
import ProgressView from '../../../../Common/ProgressView/ProgressView';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import BasePage from '../../../../Common/PostSalesBasePage';
import { getStaticData } from '../../../../staticData/staticData';

export default class DateChangeBaseClass extends BasePage {
  goBack = () => {
    Actions.pop();
  };

  renderProgressView = (message) => {
    const {loadingText} = getStaticData();
   return (<ProgressView message={message || loadingText} />)};

  renderNoNetworkView() {
    return <NoInternetView onRetry={() => this.props.fetchSearchData(this.props.searchKey)} />;
  }

  renderErrorView = () => (
    <ErrorView
      showRetry
      onRetry={() => {
        this.fetchData();
      }}
    />
  );
}
