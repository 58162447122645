import NetworkModule from '@mmt/legacy-commons/Native/NetworkModule';
import fecha from 'fecha';
import { Actions } from '../navigation';
import {Platform} from 'react-native';
import { trackTGSPageDetails } from 'apps/rails/src/RisAnalytics/RisAnalytics'
import {
  getDataFromStorage,
  KEY_PNR_DETAIL_RECENT_SEARCH_HISTORY, KEY_TRAIN_RECENT_SEARCH_HISTORY,
  KEY_SEAT_AVAIL_RECENT_SEARCH_HISTORY, KEY_TRAIN_SCHD_RECENT_SEARCH_HISTORY,
  setDataInStorage, KEY_LIVE_STATION_RECENT_SEARCH_HISTORY,
  UNIQUE_PNR_TRACKING,
  TGS_PAGE_TRACKER,
  USER_PNR_CONTEXT
} from '@mmt/legacy-commons/AppState/LocalStorage';
import RailsModule from '@mmt/legacy-commons/Native/RailsModule';
import {fillDateAndTime} from '@mmt/legacy-commons/Common/utils/DateUtils';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import { getUserDetails } from '@mmt/legacy-commons/Native/UserSession/UserSessionModule';
import isEmpty from 'lodash/isEmpty';
import { isNetworkAvailable } from '@mmt/legacy-commons/Common/utils/AppUtils';
import { asyncStorageKeys } from '../pages/RIS/LiveTrainStatus/constants';
import { getDeviceDetails } from '@mmt/legacy-commons/Helpers/genericHelper';
import fetch2 from '../fetch2';
import { DATE_MONTH_YEAR_FORMAT, getDataFromAsynStorage, setDataToAsyncStorage,removeDataFromAsyncStorage,UNIQUE_PNR_PAGENAMES } from './RailsConstant';
import { NOT_AVAILABLE } from '../pages/CommonCalendar/Utils/CalenderUtils';
import { generateTgFunnelEvars } from '../RisAnalytics/RisAnalytics';

const getHeaders = async () => {
  const headers = await NetworkModule.getHeaders();
  return headers;
};

const RECENT_SEARCH_CARDS_LIMIT = 5;
const UNIQUE_PNRS_LIMIT = 10;

export const sendLtsFeedBack = async () => {
  const hasNetwork = await isNetworkAvailable();
  if (hasNetwork) {
    const feedbacks = await getDataFromStorage(asyncStorageKeys.FEEDBACK);
    if (!isEmpty(feedbacks) && feedbacks.length > 0) {
      const userDetails = await getUserDetails();
      const headerInfo = await getHeaders();
      const apiEndpoint =
        'https://railsinfo-services.makemytrip.com/api/rails/train/ltsoffline/feedback/v1';
      const header = {
        'Content-Type': 'application/json',
      };
      const body = {
        uuid: userDetails?.uuid,
        phoneNumber:
          isEmpty(userDetails?.mobile?.countryCode) || isEmpty(userDetails?.mobile?.mobileNumber)
            ? undefined
            : `+${userDetails?.mobile?.countryCode}${userDetails?.mobile?.mobileNumber}`,
        deviceId: headerInfo?.deviceid,
        feedbackDetails: feedbacks,
      };
      const response = await fetch2(apiEndpoint, {
        method: 'POST',
        headers: header,
        body: JSON.stringify(body),
      });
      if (response?.ok && response?.status === 200) setDataInStorage(asyncStorageKeys.FEEDBACK, []);
    }
  }
};

export const loggingLts = (async () => {
  const userDetails = await getUserDetails();
  const headerInfo = await getHeaders();
  const deviceDetails = await getDeviceDetails();
  const apiEndpoint =
    'https://railsinfo-services.makemytrip.com/api/rails/lts/accuracylogging/v1';
  const header = {
    'Content-Type': 'application/json',
  };
  const bodyDetails = {
    uuid: userDetails?.uuid,
    dvid: headerInfo?.deviceid ?? 'XXXXXXXXXXXXXXX',
    aV: deviceDetails?.appVersion ?? 'X.X.X',
  };
  return async () => {
    try {
      const logs = await getDataFromStorage(asyncStorageKeys.LOGGING);
      if (!isEmpty(logs) && logs.length > 0) {
        const body = {
          ...bodyDetails,
          data: logs,
        };
        const response = await fetch(apiEndpoint, {
          method: 'POST',
          headers: header,
          body: JSON.stringify(body),
        });
        if (response?.ok && response?.status === 200)
          setDataInStorage(asyncStorageKeys.LOGGING, []);
      }
    } catch (error) {
      console.log('Error in sendLtsLoggingData: ', error);
    }
  };
})();

export const isBooker = async () => {
  try {
    const userDetails = await getUserDetails();
    if (isEmpty(userDetails?.uuid)) return false;
    const apiEndpoint = 'https://railsinfo-services.makemytrip.com/api/rails/usertype/mmtnonbooker';
    const header = {
      'Content-Type': 'application/json',
    };
    const body = {
      uuid: userDetails?.uuid,
      trackingParams: {
        affiliateCode: 'MMT001',
        channelCode: _getChannelCode(),
      },
    };
    const response = await fetch2(apiEndpoint, {
      method: 'POST',
      headers: header,
      body: JSON.stringify(body),
    });
    const jsonResponse = await response.json();
    if (jsonResponse?.isMMTNonBooker !== undefined) return !jsonResponse?.isMMTNonBooker;
    return false;
  } catch (error) {
    return false;
  }
};

export const fetchTrainsList = async (query, signal = null) => {
  try {
    const url = `https://ground-auto-suggest.makemytrip.com/rails/autosuggest/trains?search_query=${query}&limit=10&version=v2`;
    const response = await fetch2(url, {
      method: 'GET',
      headers: await getHeaders(),
      signal
    });
    if (!response.ok) {
      return null;
    }
    const data = await response.json();
    return data;
  } catch (e) {
    if (e?.name === "AbortError"){
      return {
        msg: e.name
      }
    }
    console.log('err', e);
    return null;
  }
};

export const fetchStationList = async (trainNumber) => {
  try {
    const body = {
      trainNumber: `${trainNumber}`,
      trackingParams: {
        affiliateCode: 'MMT001',
        channelCode: _getChannelCode(),
      },
    };
    const url = 'https://railsinfo-services.makemytrip.com/api/rails/train/stations/v1';
    const response = await fetch2(url, {
      method: 'POST',
      headers: await getHeaders(),
      body: JSON.stringify(body)
    });
    if (!response.ok) {
      return null;
    }
    return await response.json();
  } catch (e) {
    console.log('err', e);
    return null;
  }
};

export const fetchUpcomingJourneysList = async () => {
  try {
    const userDetails = await getUserDetails();
    if (isEmpty(userDetails?.uuid)) return false;
    const apiEndpoint = 'https://railsinfo-services.makemytrip.com/api/rails/train/journeycards';
    const header = {
      'Content-Type': 'application/json',
    };
    const body = {
      uuid: userDetails?.uuid,
      trackingParams: {
        affiliateCode: 'MMT001',
        channelCode: _getChannelCode(),
      },
    };
    const response = await fetch2(apiEndpoint, {
      method: 'POST',
      headers: header,
      body: JSON.stringify(body),
    });
    if (!response.ok) return null;
    const jsonResponse = await response.json();
    if (isEmpty(jsonResponse?.JourneyCards) || jsonResponse?.JourneyCards?.length === 0) return null;
    const filteredUpcomingJourneys = (jsonResponse?.JourneyCards ?? [])?.filter((journey) => {
      if (
        !isEmpty(journey?.Train?.Number) &&
        !isEmpty(journey?.TrainStartDate) &&
        journey?.TrainStartDate?.split('-').length === 3
      )
        return true;
      else return false;
    });
    return filteredUpcomingJourneys;
  }
  catch (error) {
    console.log('Error in fetchingUpcomingJourneysList: ', error);
    return null;
  }
}

export const getPopularTrainsFromAPI = async () => {
  try {
    const url = 'https://railsinfo-services.makemytrip.com/api/rails/train/popular/v1';
    const response = await fetch2(url, {
      method: 'GET',
      headers: await getHeaders()
    });
    if (!response.ok) {
      return null;
    }
    const responseJson = await response.json();
    if (responseJson?.Error || responseJson?.popularTrainsList?.length === 0) {
      return null;
    }
    return responseJson?.popularTrainsList?.map(
      ({ popularTrainNo, popularTrainName, sourceCity, destinationCity }) => {
      return {
          trainNumber: popularTrainNo,
          trainName: popularTrainName,
          sourceCity,
          destinationCity,
      };
    });
  } catch (e) {
    console.error('getPopularTrainsFromAPI error', e);
    return null;
  }
}

export const POPULAR_TRAIN_LIST = [{
  trainNumber: '01014',
  trainName: 'Coimbatore Mumbai LTT Express'
}, {
  trainNumber: '01020',
  trainName: 'Bhubaneswar Mumbai Konark Express'
}, {
  trainNumber: '01078',
  trainName: 'Jammu Tawi Pune Jhelum Express'
}, {
  trainNumber: '01111',
  trainName: 'Mumbai Madgaon Special Train'
}, {
  trainNumber: '01133',
  trainName: 'Mumbai Mangaluru Special Train'
}];

export const getTrainStatusDates = () => {
  const dateList = [];
  const date = new Date();
  date.setDate(date.getDate() - 2);
  date.setHours(0, 0, 0, 0);
  for (let i = 0; i < 4; i += 1) {
    const newDate = new Date(date.getTime());
    newDate.setDate(date.getDate() + i);
    dateList.push({
      ...getDateDetails(newDate),
      valid: true,
    });
  }
  return dateList;
};

export const getLTSDateListWithDaysOfRun = (daysOfRun = []) => {
  const dateList = [];
  const date = new Date();
  date.setDate(date.getDate() - 4);
  date.setHours(0, 0, 0, 0);
  for (let i = 0; i < 7; i += 1) {
    const newDate = new Date(date.getTime());
    newDate.setDate(date.getDate() + i);
    const dateDetails = {
      ...getDateDetails(newDate.getTime()),
      valid: daysOfRun?.[newDate.getDay()] ?? true,
    };
    dateList.push(dateDetails);
  }
  return dateList;
}

export const getDateDetails = (date, addDays=0) => {
  const days = [{value: 'Day Before Yesterday', short: 'Day Before'}, {value: 'Yesterday', short: 'Yesterday'}, {value: 'Today', short: 'Today'}, {value: 'Tomorrow', short: 'Tomorrow'}, {value: 'Day After Tomorrow', short: 'Day After'}];
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0);
  newDate.setDate(newDate.getDate() + addDays);
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const dayDifference = (newDate.getTime() - today.getTime()) / (24 * 60 * 60 * 1000);
  const day = days?.[dayDifference + 2]
  const dateDetails = {
    dateFormatted: fecha.format(newDate, 'dddd, DD MMMM YY', fecha.defaultI18n),
    dateFormattedMini: fecha.format(newDate, 'ddd, DD MMM', fecha.defaultI18n),
    dateNumeric: fecha.format(newDate, 'DD-MM-YYYY', fecha.defaultI18n),
    dateFormattedSmall: fecha.format(newDate, 'DD MMM YY', fecha.defaultI18n),
    date: newDate.getTime(),
    dateTitle: !isEmpty(day) ? day.value : fecha.format(newDate, 'dddd', fecha.defaultI18n),
    dateTitleShort: !isEmpty(day) ? day.short : fecha.format(newDate, 'ddd', fecha.defaultI18n),
    dateDay: (!isEmpty(day) ? day.short + ', ' : '') + fecha.format(newDate, 'dddd', fecha.defaultI18n),
    dayDifference: dayDifference,
  };
  return dateDetails;
}

// Input Format DD-MM-YYYY output is 'dddd, DD MMM YY'
export const getPnrRecentCardDateFormat = (dateInput) => {
  try {
    const date = fecha.parse(dateInput, 'DD-MM-YYYY');
    return fecha.format(date, 'dddd, DD MMM YY');
  } catch (e) {
    return '';
  }
};

export const getPnrStatusDateFormat = (dateInput) => {
  try {
    const date = fecha.parse(dateInput, 'DD-MM-YYYY');
    return fecha.format(date, ' D  MMM');
  } catch (e) {
    return '';
  }
};


export const getPnrStatusJourneyDateFormat = (dateInput) => {
  try {
    const date = fecha.parse(dateInput, 'DD-MM-YYYY');
    return fecha.format(date, 'DD MMM YY');
  } catch (e) {
    return '';
  }
};

export function validatePnr(text) {
  let pnrNumber = '';
  const numbers = '0123456789';
  for (let i = 0; i < text.length; i += 1) {
    if (numbers.indexOf(text[i]) > -1) {
      pnrNumber += text[i];
    }
  }
  return pnrNumber;
}

export const getNewTrainSearchHistory = (trainDetail, recentSearchList) => {
  if (!recentSearchList && trainDetail) {
    return [trainDetail];
  }
  if (!trainDetail) {
    return null;
  }
  const similarSearch = recentSearchList
    .find(searchDetail => searchDetail.trainNumber === trainDetail.trainNumber);
  if (similarSearch) {
    recentSearchList.splice(recentSearchList.indexOf(similarSearch), 1);
  }
  let updatedRecentSearchList = [trainDetail, ...recentSearchList];
  if (updatedRecentSearchList.length > RECENT_SEARCH_CARDS_LIMIT) {
    updatedRecentSearchList = updatedRecentSearchList.slice(0, RECENT_SEARCH_CARDS_LIMIT);
  }
  return updatedRecentSearchList;
};

export function getNewSeatAvailSearchHistory(from, to, date, recentSearchList) {
  if (!recentSearchList) {
    return [{from, to, date}];
  }
  const similarSearch = recentSearchList
    .find(searchDetail => searchDetail.from.cityName === from.cityName && searchDetail.to.cityName === to.cityName
      && searchDetail.date === date);
  if (similarSearch) {
    recentSearchList.splice(recentSearchList.indexOf(similarSearch), 1);
  }
  let updatedRecentSearchList = [{from, to, date}, ...recentSearchList];
  if (updatedRecentSearchList.length > RECENT_SEARCH_CARDS_LIMIT) {
    updatedRecentSearchList = updatedRecentSearchList.slice(0, RECENT_SEARCH_CARDS_LIMIT);
  }
  return updatedRecentSearchList;
}

export function getNewPnrSearchHistory(pnrDetail, recentSearchList) {
  if (!recentSearchList) {
    return [pnrDetail];
  }
  const similarSearch = recentSearchList
    .find(searchDetail => searchDetail.pnrNumber === pnrDetail.pnrNumber);
  if (similarSearch) {
    recentSearchList.splice(recentSearchList.indexOf(similarSearch), 1);
  }
  let updatedRecentSearchList = [pnrDetail, ...recentSearchList];
  if (updatedRecentSearchList.length > RECENT_SEARCH_CARDS_LIMIT) {
    updatedRecentSearchList = updatedRecentSearchList.slice(0, RECENT_SEARCH_CARDS_LIMIT);
  }
  return updatedRecentSearchList;
}

export function getNewLiveStationSearchHistory(stationDetail, recentSearchList) {
  if (!recentSearchList) {
    return [stationDetail];
  }
  const similarSearch = recentSearchList
    .find(searchDetail => searchDetail.code === stationDetail.code);
  if (similarSearch) {
    recentSearchList.splice(recentSearchList.indexOf(similarSearch), 1);
  }
  let updatedRecentSearchList = [stationDetail, ...recentSearchList];
  if (updatedRecentSearchList.length > RECENT_SEARCH_CARDS_LIMIT) {
    updatedRecentSearchList = updatedRecentSearchList.slice(0, RECENT_SEARCH_CARDS_LIMIT);
  }
  return updatedRecentSearchList;
}

export const getRailsLandingPnrCardData = async () => {
  try {
    const cardData = await RailsModule.getRailsLandingPnrCardData();
    return JSON.parse(cardData);
  } catch (ex) {
    console.info('error', ex);
  }
  return undefined;
};

export const getRailsLandingLiveTrainCardData = async () => {
  try {
    const cardData = await RailsModule.getRailsLandingLiveTrainCardData();
    return JSON.parse(cardData);
  } catch (ex) {
    console.info('error', ex);
  }
  return undefined;
};

export const getRailsLandingTrainScheduleCardData = async () => {
  try {
    const cardData = await RailsModule.getRailsLandingTrainScheduleCardData();
    return JSON.parse(cardData);
  } catch (ex) {
    console.info('error', ex);
  }
  return undefined;
};

export const getRailsLandingSeatAvailCardData = async () => {
  try {
    const cardData = await RailsModule.getRailsLandingSeatAvailabilityCardData();
    return JSON.parse(cardData);
  } catch (ex) {
    console.info('error', ex);
  }
  return undefined;
};

export const getRailsLandingLiveStationCardData = async () => {
  try {
    const cardData = await RailsModule.getRailsLandingLiveStationCardData();
    return JSON.parse(cardData);
  } catch (ex) {
    console.info('error', ex);
  }
  return undefined;
};

export async function saveTrainRecentSearch(recentSearchList) {
  setDataInStorage(KEY_TRAIN_RECENT_SEARCH_HISTORY, recentSearchList);
}

export async function saveTrainSchdRecentSearch(recentSearchList) {
  setDataInStorage(KEY_TRAIN_SCHD_RECENT_SEARCH_HISTORY, recentSearchList);
}

export async function saveLiveStationRecentSearch(recentSearchList) {
  setDataInStorage(KEY_LIVE_STATION_RECENT_SEARCH_HISTORY, recentSearchList);
}

export const getPnrRecentSearch = async () => {
  const recentSearchPnrHistory = await getDataFromStorage(KEY_PNR_DETAIL_RECENT_SEARCH_HISTORY);
  if (!recentSearchPnrHistory) {
    return undefined;
  }
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  return recentSearchPnrHistory.filter((pnrDetail) => {
    try {
      const endDate = fecha.parse(pnrDetail.travelEndDate, 'DD-MM-YYYY');
      return endDate.getTime() >= date.getTime();
    } catch (e) {
      return true;
    }
  });
};

export const getSeatAvailSearch = async () => {
  const recentSearch = await getDataFromStorage(KEY_SEAT_AVAIL_RECENT_SEARCH_HISTORY);
  if (!recentSearch) {
    return undefined;
  }
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  return recentSearch.filter((journey) => {
    try {
      const depDate = fecha.parse(fillDateAndTime(journey.date, 'DD-MM-YYYY'), 'DD-MM-YYYY');
      return depDate.getTime() >= date.getTime();
    } catch (e) {
      return true;
    }
  });
};

export const getArrivalDeptTime = (arrTime) => {
  if (!arrTime) {
    return '';
  }
  let timeString = arrTime;
  const H = +timeString.substr(0, 2);
  let h = H % 12 || 12;
  const ampm = (H < 12 || H === 24) ? ' AM' : ' PM';
  if (h < 10) {
    h = `0${h}`;
  }
  timeString = h + timeString.substr(2, 3) + ampm;
  return timeString;
};

export const openPNRDetailPage = (pnrNumber) => {
  RailsModule.openPnrDetail(pnrNumber);

  // Commenting this code for now as we are observing some issues while loading the PWAPage from react.
  // if (Platform.OS === 'ios') {
  //   openIOSPWAPageForPnr(pnrNumber);
  // } else {
  //   RailsModule.openPnrDetail(pnrNumber);
  // }
};

const openPWAPageForPnr = async (pnrNumber) => {
  const appVersion = await GenericModule.getAppVersion();

  // TODO MAKE PROPER URL AND ADD PROPER DETAILS HEADER AND ALL
  const params = {
    trainNum: trainDetail.trainNumber,
    av: appVersion,
    src: Platform.OS
  };
};
const openIOSPWAPageForPnr = async (pnrNumber) => {
  const url = 'https://www.makemytrip.com/rails/pnrsearch/?pnr=${pnrNumber}'; // URL should be pnr url.
  console.log('PnrForm', url);
  Actions.railsPWA({
    url,
    title: 'PNR DETAILETC',
    isPreviousPage: true
  });
};

export async function saveSeatAvailRecentSearch(recentSearchList) {
  setDataInStorage(KEY_SEAT_AVAIL_RECENT_SEARCH_HISTORY, recentSearchList);
}

export async function savePnrRecentSearch(recentSearchList) {
  setDataInStorage(KEY_PNR_DETAIL_RECENT_SEARCH_HISTORY, recentSearchList);
}

export const convertEpochToTimeFormat = (epochDateFormat) => {
  const date = new Date(epochDateFormat);
  const hour = `00${date.getHours()}`;
  const minutes = `00${date.getMinutes()}`;
  return `${hour.slice(-2)}:${minutes.slice(-2)}`;
};

export const isDateChanged = (departureTimeinEpoch, duration) => {
  const splitDurationOfJourney = duration.split(' ');
  const hour = splitDurationOfJourney[0].replace('h', '') * 60;
  let minutes = 0;
  if (splitDurationOfJourney.length === 2) {
    minutes = splitDurationOfJourney[1].replace('m', '') * 1;
  }
  const timeinMinutes = hour + minutes;
  const arrivalTime = new Date(departureTimeinEpoch + (timeinMinutes * 60000));
  const departureTime = new Date(departureTimeinEpoch);
  return arrivalTime.getDate() > departureTime.getDate();
};

export function isPhonePePlatform() {
  if (window?.navigator?.userAgent?.includes('phonepe-webview')) {
    return true;
  }
  return false;
}


export const _getChannelCode = () => {
  let channelCode;
  if (Platform.OS === 'web') {
    channelCode = 'PWA';
  } else if (Platform.OS === 'android') {
    channelCode = 'ANDROID';
  } else if (Platform.OS === 'ios') {
    channelCode = 'IOS';
  } else {
    channelCode = Platform.OS;
  }
  return channelCode;
};

export function getNewUniquePnrsHistory(pnrDetail, recentUniquePnrs) {
  if (!recentUniquePnrs) {
    return [pnrDetail];
  }
  const similarSearch = recentUniquePnrs.find(searchDetail => searchDetail.pnrNumber === pnrDetail.pnrNumber);
  if (similarSearch) {
    const indexOfSearch = recentUniquePnrs.indexOf(similarSearch);
    recentUniquePnrs.splice(indexOfSearch,1);
  }
  let updatedUniquePnrs = [pnrDetail, ...recentUniquePnrs];
  if (updatedUniquePnrs.length > UNIQUE_PNRS_LIMIT) {
    updatedUniquePnrs = updatedUniquePnrs.slice(0, UNIQUE_PNRS_LIMIT);
  }
  return updatedUniquePnrs;
}

export const getUniquePnrsFromStorage = async () => {
  const uniquePnrsFromStorage = await getDataFromAsynStorage(UNIQUE_PNR_TRACKING);
  if (!uniquePnrsFromStorage) {
    return undefined;
  }
  const date = new Date();
  return uniquePnrsFromStorage.filter((uniquePnr) => {
    try {
      const endDate = fecha.parse(uniquePnr.travelEndDate, DATE_MONTH_YEAR_FORMAT, fecha.defaultI18n);
      return endDate.getTime() >= date.getTime();
    } catch (e) {
      return true;
    }
  });
};

export async function saveUniquePnrsHistory (uniquePnrsList) {
  setDataToAsyncStorage(UNIQUE_PNR_TRACKING, uniquePnrsList);
}

export async function tgsTrackPageDetails(pageName, eventName) {
  const tgsPageTracker = await getDataFromAsynStorage(TGS_PAGE_TRACKER);
  if (!tgsPageTracker) {
    setDataToAsyncStorage(TGS_PAGE_TRACKER, eventName);
    trackTGSPageDetails(pageName, eventName);
    return;
  }
  if (!tgsPageTracker?.split('|')?.includes(eventName)) {
    const omnitureData = tgsPageTracker + '|' + eventName;
    trackTGSPageDetails(pageName, omnitureData);
    setDataToAsyncStorage(TGS_PAGE_TRACKER, omnitureData);
  }
  else {
    trackTGSPageDetails(pageName, tgsPageTracker);
  }
}

export function removeTGSOmniture() {
   removeDataFromAsyncStorage(TGS_PAGE_TRACKER);
}

async function saveUserPnrContext (userPnrContext) {
  setDataToAsyncStorage(USER_PNR_CONTEXT, userPnrContext);
}

export async function getUserPnrContext(){
  const userPnrContext = await getDataFromAsynStorage(USER_PNR_CONTEXT);
  if (!userPnrContext) {
    return undefined;
  }
  return userPnrContext;
}

export const populateApClassAndPriceFromPnrStatusApi = async (apiResponse, funnelContext) => {
  const journeyDate = apiResponse?.TrainDetails?.TrainStartingDate;
  const ticketClass = apiResponse?.PnrDetails?.Class;
  const tgPremiumAmount = apiResponse?.TgsEligibility?.response?.tgPremiumAmount;
  let apWindow = NOT_AVAILABLE;

  if (journeyDate) {
    const currDate = new Date();
    const formattedJourneyDate = new Date(journeyDate);
    apWindow = Math.ceil((formattedJourneyDate.getTime() - currDate.getTime()) / (24 * 60 * 60 * 1000));
  }

  const apClassAndTgPrice = `${apWindow}days_${ticketClass}_${tgPremiumAmount}`;
  await saveUserPnrContext(`${apClassAndTgPrice}|${funnelContext}`);
};

export const strToMins = (t) => {
  const s = t?.split(':');
  return Number(s[0]) * 60 + Number(s[1]);
};

export const getTimeDifference = () => {
  // Create a Date object for the current time
  const currentTime = new Date();

  // Set the target time for comparison (12:20 AM of the same day)
  const targetDateSameDay = new Date();
  targetDateSameDay.setHours(0, 20, 0, 0);

  // Set the target time for comparison (12:20 AM of the next day)
  const targetDateNextDay = new Date(targetDateSameDay);
  targetDateNextDay.setDate(targetDateNextDay.getDate() + 1);

  // Choose the appropriate target time based on the current time
  const targetDate = currentTime.getHours() < 12 ? targetDateSameDay : targetDateNextDay;

  // Calculate the time difference in milliseconds
  const timeDifference = Math.abs(currentTime.getTime() - targetDate.getTime());

  // Convert the time difference to seconds
  return Math.floor(timeDifference / 1000);
};
