import React from 'react';
import { View, Text, TouchableOpacity, Image, Platform, UIManager, LayoutAnimation } from 'react-native';

import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getStyle from './styles';

import TrackerRow from './TrackerRow';

import { MulticityJourneyDetailProps } from '../../types';
import { ArrowDown, roundTrip } from '../../CabImageConstants';
import rightArrow from '@mmt/legacy-assets/src/arrowForward.png';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

if (Platform.OS === 'android') {
	if (UIManager.setLayoutAnimationEnabledExperimental) {
		UIManager.setLayoutAnimationEnabledExperimental(true);
	}
}

const MulticityJourneyDetail = ({ expandCarDetail, isExpanded, locationData, carBookingInfo }: MulticityJourneyDetailProps) => {
	const { dateTime, returnDateTime, isRoundTrip } = carBookingInfo || {};
	const { fsStyle, ...fonts } = getFont();
	const { psTheme } = useTheme();
	const styles = getStyle(fonts, psTheme);

	const expandSection = () => {
		LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
		expandCarDetail();
	};

	return (
		<View style={[styles.bookingInfoDetails]}>
			{carBookingInfo?.tripTypeText &&
				<View>
					<Text style={[AtomicCss.font12, AtomicCss.boldFont, AtomicCss.midGreyText, AtomicCss.marginBottom10]}>{carBookingInfo?.tripTypeText}</Text>
				</View>
			}
			{!isExpanded &&
				<>
					<TouchableOpacity onPress={expandSection} style={[AtomicCss.flexRow, AtomicCss.marginBottom5]}>
						{locationData &&
							<View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.flex1, AtomicCss.flexWrap, AtomicCss.marginRight16]}>
								{locationData?.map((route, index, arr) => {
									const lastIndex = arr.length - 1;
									return (
										<View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
											<Text style={[AtomicCss.font16, AtomicCss.blackFont, AtomicCss.blackText]}>{route.cityName}</Text>
											{lastIndex === index ? null : <Image source={rightArrow} style={styles.rightArrowStyle} />}
										</View>
									);
								})}
							</View>
						}
						<View style={styles.arrowStyleWrapper}>
							<Image style={styles.arrowstyle} source={ArrowDown} />
						</View>
					</TouchableOpacity>
					<View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
						{!!dateTime && <Text style={[AtomicCss.font12, AtomicCss.boldFont, AtomicCss.defaultText]}>{dateTime}</Text>}
						{isRoundTrip && !!returnDateTime &&
							<>
								<Image source={roundTrip} style={styles.multiCityIconStyle} />
								<Text style={[AtomicCss.font12, AtomicCss.boldFont, AtomicCss.defaultText]}>{returnDateTime}</Text>
							</>
						}
					</View>
				</>
			}
			{isExpanded &&
				<View style={[AtomicCss.paddingLeft5, AtomicCss.paddingRight5]}>
					{locationData?.map((route, index, arr) => {
						const isFirstRow = index === 0;
						const isLastRow = index === arr.length - 1;
						const dateAtLocation = isFirstRow ? dateTime : isLastRow && isRoundTrip ? returnDateTime : undefined;

						return (
							<TrackerRow
								name={route.cityName}
								address={route.address}
								dateAtLocation={dateAtLocation}
								key={index}
								isLastRow={isLastRow}
							/>
						);
					})}
					<TouchableOpacity style={[styles.arrowStyleWrapper, styles.activeArrow]} onPress={expandSection}>
						<Image style={styles.arrowstyle} source={ArrowDown} />
					</TouchableOpacity>
				</View>
			}

		</View>
	);
};

export default MulticityJourneyDetail;
