import React from 'react';
import {View, Text, Image, TouchableOpacity} from 'react-native';
import styles from './inclusionExclusionCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {greenTick, dropDownArrow} from "../../CabImageConstants";
import CabBookingTrackingHelper from '../../CabBookingTrackingHelper';
import { CLICK_EVENT, PAGE_NAME } from '../../CabBookingDetailsConstant';

export default class InclusionTab extends React.Component {
  constructor() {
    super();
    this.state = {
      active: true
    };
  }

    handleClick = (type) => {
      let clickEvent = "";
      if(type==='cancellationPolicy'){
        clickEvent = this.state.active ? CLICK_EVENT.COLLAPSE_CANCELLATION_POLICY_CLICK : CLICK_EVENT.EXPAND_CANCELLATION_POLICY_CLICK;
      }else if(type==='inclusion'){
        clickEvent = this.state.active ? CLICK_EVENT.COLLAPSE_NEW_INCLUCSION_CLICK : CLICK_EVENT.EXPAND_NEW_INCLUCSION_CLICK;
      }
      CabBookingTrackingHelper.trackClickEvent(PAGE_NAME.MODIFY_BOOKING, clickEvent);
      this.setState({active: !this.state.active});
    }

    render() {
      const dropDownArrowStyle = (this.state.active) ? [styles.arrowActiveStyle, styles.blueArrowStyle] : [styles.blueArrowStyle];
      return (
        <View style={(this.props.isLast) ? (styles.wrapperForLastLine) : (styles.wrapper)}>
          <TouchableOpacity onPress={() => this.handleClick(this.props.type)} style={[AtomicCss.flexRow, AtomicCss.spaceBetween, styles.head]}>
            <Text style={[AtomicCss.blackText, AtomicCss.font14, AtomicCss.blackFont]}>{this.props.heading}</Text>
            <Image style={[dropDownArrowStyle]} source={dropDownArrow} />
          </TouchableOpacity>
          {this.state.active &&
            <View>
              {this.fetchInclusions(this.props.inclusionData)}
            </View>
                }
        </View>
      );
    }

    fetchInclusions = function (inclusions) {
      const views = Object.keys(inclusions).map((key) => {
        return(<View style={[AtomicCss.marginBottom10, AtomicCss.flexRow, AtomicCss.alignCenter]}><Image source={greenTick} style={styles.exclusion} /><Text style={[AtomicCss.greyText, AtomicCss.font14, AtomicCss.paddingLeft5]}>{inclusions[key]}</Text></View>);
      });
      return views;
    }
}
