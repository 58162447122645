import { trackOmniture } from '@mmt/legacy-commons/Common/utils/OmnitureTrackerUtils';
import { findNumberOfDaysBetweenDates } from '@mmt/legacy-commons/Common/utils/DateUtils';
import { isNotNullAndEmpty } from '../../../hotel/details/utils/HotelBookingDetailUtil';
import FlightEventsLogger from '../../modules/details/helper/FlightEventsLogger';
import {
  _getFltTrackData,
  _getJourneyType,
  _trackSegmentData,
  _trackFltBookingDate,
} from '../../FlightOmnitureTrackerUtils';

const intlLob = ['LOB02520', 'LOB02720', 'LOB01520'];
class SpecialClaimTrackingHelper {
  trackLoadEvent = (pageName, flightCancellationResponse, specialClaimResponse, bookingState) => {
    FlightEventsLogger.loadEvent(pageName, flightCancellationResponse);
    let params = {};
    params = this.getTrackingOmniture(
      pageName,
      flightCancellationResponse,
      specialClaimResponse,
      bookingState,
    );
    trackOmniture(pageName, params, true);
  };

  getTrackingOmniture(pageName, response, claimResponse, bookingState) {
    const params = {};
    const segInfo =
      response && response.flightDetails
        ? _trackSegmentData(response.flightDetails.segmentGroupDetailList)
        : {};
    params.pageName = pageName;
    params.m_v15 = pageName;
    params.m_v7 = '';
    params.m_v6 = segInfo.travelDateGap;
    params.m_v16 = response.bookingID;
    params.m_v39 = 'Y';
    params.m_v40 = this._getFlightCode(response);
    params.m_v13 = claimResponse.scCode;
    params.m_v9 =
      response && response.flightDetails ? _getJourneyType(response.flightDetails.journeyType) : '';
    if (bookingState) {
      params.m_v14 = this._getBookingStatus(response, bookingState);
    }
    params.m_v24 = this._getLob(response);
    params.m_v21 = segInfo.adultCount;
    params.m_v3 = segInfo.bookingIntent;
    params.m_v4 = segInfo.depDate;
    if (response.specialClaimValidationData) {
      params.m_v22 = this.getEVA22(response, claimResponse);
    }
    if (claimResponse && claimResponse.uploadFailed) {
      params.m_v22 = `${params.m_v22 || ''} Attachment Failure`;
    }
    const flightData = _getFltTrackData();
    if (flightData) {
      params.m_v7 = flightData.bookingDateTime
        ? findNumberOfDaysBetweenDates(flightData.bookingDateTime, new Date()) + ` Days`
        : '';
      params.m_v5 = flightData.bookingDateTime
        ? _trackFltBookingDate(flightData.bookingDateTime.split('T')[0])
        : '';
    }
    params.m_c22 = response && response.ruleTrace ? response.ruleTrace : '';
    params.m_v20 = (response?.specialClaimValidationData?.approvedStatusInfo?.uploadCard?.optional ? 'Upload_proof_Optional' : 'Upload_proof_Mandatory') || null
    return params;
  }

  getEVA22(cancellationResponse, specialClaimData) {
    let eva22 = specialClaimData.scCode;
    const { specialClaimValidationData } = cancellationResponse;
    if (specialClaimValidationData != null) {
      if (specialClaimValidationData.status !== 'APPROVED') {
        eva22 += '_Fullblock';
      } else if (specialClaimValidationData.rejectedStatusInfo != null) {
        eva22 += '_Partblock';
      } else {
        eva22 += '_normal';
      }
      let subEvent = null;
      let creditShell = null;
      if (
        specialClaimValidationData.approvedStatusInfo != null &&
        specialClaimValidationData.approvedStatusInfo.segmentStatusList != null
      ) {
        for (const segmentStatus of specialClaimValidationData.approvedStatusInfo
          .segmentStatusList) {
          if (segmentStatus != null && segmentStatus.status) {
            subEvent =
              subEvent == null ? segmentStatus.status : `${subEvent}|${segmentStatus.status}`;
          }
          if (segmentStatus.creditShellInfo != null && segmentStatus.creditShellInfo.type) {
            creditShell =
              creditShell == null
                ? segmentStatus.creditShellInfo.type
                : `${creditShell}|${segmentStatus.creditShellInfo.type}`;
          }
        }
      }
      if (subEvent) {
        eva22 += `_${subEvent}`;
      }
      if (creditShell) {
        eva22 += `_${creditShell}`;
      }
    }
    return eva22;
  }

  trackClickEvent = (pageName, clickEvent, otherData) => {
    try {
      const {isProofUploaded = null} = otherData || {}
      FlightEventsLogger.clickEvent(pageName, clickEvent);
      const params = {};
      params.pageName = pageName;
      params.m_v15 = pageName;
      params.m_c54 = clickEvent;
      if (isProofUploaded !== null){
        params.m_v20 = isProofUploaded ? 'Proof_upload_success' : 'Proof_upload_failed';
      }
      trackOmniture(pageName, params);
    } catch (error) {
      console.warn(error);
    }
  };

  trackErrorEvent = (pageName) => {
    FlightEventsLogger.errorEvent(pageName);
    const params = {};
    params.pageName = pageName;
    params.m_v15 = pageName;
    trackOmniture(pageName, params);
  };

  _getFlightCode = (response) => {
    const { flightDetails } = response;
    let flighCode = '';
    if (flightDetails && isNotNullAndEmpty(flightDetails.segmentGroupDetailList)) {
      flightDetails.segmentGroupDetailList.forEach((segmentGroupObj) => {
        segmentGroupObj.segmentDetails.forEach((segment) => {
          flighCode += `${segment.airLineCode}|`;
        });
      });
    }
    return flighCode;
  };

  _getJourneyType = (response) => {
    const { flightDetails } = response;
    const { journeyType } = flightDetails || {};
    switch (journeyType) {
      case 1:
        return 'Multi City';
      case 2:
        return 'Round Trip';
      case 3:
        return 'One Way';
      default:
        return '';
    }
  };

  _getNoOfAdults = (response) => {
    const { passengerList } = response;
    let adultCount = 0;
    if (passengerList) {
      passengerList.forEach((passengerObj) => {
        if (passengerObj.paxType === 0) {
          adultCount += 1;
        }
      });
    }
    return adultCount;
  };

  _getOriginDest = (response) => {
    const { flightDetails } = response;
    const { bookingIntent } = flightDetails || {};
    return bookingIntent;
  };

  _getBookingStatus = (response, state) => {
    let result = state;
    if (response.bnplbooking) {
      result += ' | BNPL';
    } else if (response.isCancellationAssured) {
      result += ' | ZC';
    } else if (response.isMMTPrivilegeEligible) {
      result += ' | DoubleBlack';
    }
    return result;
  };

  _getLob = (response) => {
    const lob = response.lobCode;
    let isIntl;
    intlLob.forEach((obj) => {
      if (obj === lob) {
        isIntl = true;
      }
    });
    if (isIntl) {
      return 'mob intl flights';
    }
    if (lob === 'LOB03810') {
      return 'mob dom flights | corporate';
    }
    if (lob === 'LOB03820') {
      return 'mob intl flights | corporate';
    }
    return 'mob dom flights';
  };

  _isIntlBooking(lobCode) {
    return intlLob.indexOf(lobCode) > -1;
  }

  trackAncillaryClaimLoadEvent = (pageName, flightResponse, specialClaimResponse) => {
    FlightEventsLogger.loadEvent(pageName, flightResponse);
    let params = {};
    pageName =
      flightResponse && intlLob.indexOf(flightResponse.lobCode) !== -1
        ? `Mob:Customer support:Intl Flight:${pageName}`
        : `Mob:customer support:Dom Flight:${pageName}`;
    params = this.getTrackingOmniture(pageName, flightResponse, specialClaimResponse);
    trackOmniture(pageName, params);
  };

  trackAncillaryClaimErrorEvent = (pageName) => {
    FlightEventsLogger.errorEvent(pageName);
    const params = {};
    pageName = `Mob:Customer support:Flight:${pageName}Error`;
    params.pageName = pageName;
    params.m_v15 = pageName;
    trackOmniture(pageName, params);
  };
}
export default new SpecialClaimTrackingHelper();
