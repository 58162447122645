import { StyleSheet } from "react-native";

export default (theme, fonts) =>StyleSheet.create({
    iconWrapper: {
        width: 26,
        height: 26,
        marginRight: 10,
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    voucherIconStyle: {
        width: 25,
        height: 17,
    },
    detailsSection: {
        paddingLeft: 30,
        paddingRight: 0,
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
    }
})

