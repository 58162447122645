import React from 'react';
import { Text, View } from 'react-native';
import { getFont } from '../../../PostSalesStyles';

const MessageStrip = ({ text, theme = 'yellow' }) => {
  const fonts = getFont(true);
  return (
    <View style={[styles.msgStrip, styles[`msgStrip_${theme}`]]}>
      <Text style={[styles[`msgText_${theme}`], fonts.regularFontFamily]}>{text}</Text>
    </View>
  );
};

const styles = {
  msgStrip: {
    padding: 10,
    justifyContent: 'space-between',
    backgroundColor: '#ffedd1',
    borderRadius: 4,
    marginTop: 10,
  },
  msgStrip_red: {
    backgroundColor: '#ffd3d4',
  },
  msgText_yellow: {
    color: '#cf8100',
  },
  msgText_red: {
    color: '#eb2026',
  },
};

export default MessageStrip;
