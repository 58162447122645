import React from 'react';
import {Image, Text, View} from 'react-native';
import getStyles from './HotelMamiCss';
import PropTypes from 'prop-types';
import {
  getActionButton, getButtonStyle, handleClickEvent,
  isNotNullAndEmpty
} from '../utils/HotelBookingDetailUtil';
import { CROSS_FIND_ICON } from '../../HotelImageConstants';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';


class MakeRequestCard extends React.Component {
  constructor(props) {
    super(props);
    this.fonts = getFont(true);
    this.styles = getStyles(this.fonts);
  }
  render() {
    return (
      <View style={[this.styles.MamiCard, this.styles.mar10Bt]}>
        <View style={this.styles.CardContent}>
          <Image style={this.styles.CardIcon} source={CROSS_FIND_ICON}/>
          <View style={this.styles.ContentPortion}>
            <Text style={[this.styles.blackFont, this.styles.BlackText, this.styles.mar5Bt]}>STILL CAN’T FIND WHAT
              YOU NEED</Text>
            <Text style={this.styles.font12}>{this.props.response?.hotelReqInfo || `Want to check in early, or plan a surprise when you walk
              into the room. You can always send a request to the Hotel.`}</Text>
          </View>
        </View>
        <View style={isNotNullAndEmpty(this.props.card.actionList) ? getButtonStyle(this.props.card.actionList) : this.styles.hideView}>
          {getActionButton(this.props.card, this.props.response, this.handleClick)}
        </View>
      </View>
    );
  }

  handleClick = (action) => {
    handleClickEvent(this.props.card, action, this.props.response,this.props.pageName);
  };
}

MakeRequestCard.propTypes = {
  response: PropTypes.object.isRequired,
  card: PropTypes.object.isRequired,
  pageName:PropTypes.string.isRequired
};

export default MakeRequestCard;
