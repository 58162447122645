import { StyleSheet } from "react-native";
export default (theme, fonts) =>StyleSheet.create({
    tncWrapper: {
        paddingHorizontal: 16,
        paddingTop:10,
    },
    tncList: {
        paddingVertical: 14,
    },
    arrowStyle: {
        width: 14,
        height: 9,
        marginLeft: 10,
    },
    activeArrow:{
        transform:[
            {
                rotate:'-180deg'
            }
        ]
    },
    locationIconStyle: {
        width: 16,
        height: 16,
        marginRight: 10,
    },
    voucherIconStyle: {
        width: 15,
        height: 17,
        marginRight: 10,
    },
    moreDetls: {
        paddingLeft: 24,
    },
    labelText:{
        width:70
    },
    description : {
        width : "70%"
    }
})

