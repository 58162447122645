import { StyleSheet } from 'react-native';

const createStyles = (fonts, theme) =>
  StyleSheet.create({
    VisaProgressingStepsCard: {
      paddingVertical: 16,
    },
    stepsText: {
      color: theme.color.lightTextColor,
      fontSize: fonts.fontSize.font12,
      ...fonts.blackFontFamily,
      marginBottom: 8,
    },
    iconStyle: {
      width: 44,
      height: 44,
      marginRight: 12,
    },
    headingText: {
      color: theme.color.black,
      ...fonts.boldFontFamily,
      marginBottom: 6,
      fontSize: fonts.fontSize.font14,
    },
    descriptionText: {
      color: theme.color.defaultTextColor,
      ...fonts.regularFontFamily,
      fontSize: fonts.fontSize.font14,
    },
  });
export default createStyles;
