/* eslint-disable max-len */
import React from 'react';
import { Platform } from 'react-native';
import Actions, { ActionConst } from '../../../../../navigation/postSalesNavigation';
import PropTypes from 'prop-types';
import {
  CancellationPreviewRequest as CancelPreviewRequest,
  FLIGHT_CANCELLATION_REASON,
  FLIGHT_CANCELLATION_REFUND_OPTIONS,
  FLIGHT_CANCELLATION_TYPE,
  LOBNAMES,
} from '../../../../../PostSalesConstant';
import { showLongToast } from '@mmt/legacy-commons/Common/Components/Toast';
import BasePage from '../../../../../Common/PostSalesBasePage';
import CancellationTrackingHelper from '../../../../cancellation/Helpers/CancellationTrackingHelper';
import {
  getCancelRequestKeyName,
  removeKeyFromAsyncStorage,
  storeObjInAsyncStorage,
} from '../../../../../Common/commonUtil';
import { openErrorPage } from '../../../../../utils/PostSaleUtil';
import { shouldNotRedirectToBreakUpPage } from '../../helper';
import { getStaticData } from '../../../../../staticData/staticData';
import ProgressView from '../../../../../Common/ProgressView/ProgressView';
import { getJourneyIntentDtl } from '../../data/dataAdapter';
import { fetchFlightCancellationDetails } from '../../data/api';
import { getFlightFullCancellationRequest } from "core-ui-lib/flight/common/Cancellation/requestAdapter";

export default class CancellationComponent extends BasePage {
  constructor(props) {
    super(props);
    this.trackingPageName = 'PreCancellationLoader';
  }

  render() {
    const { loadingText } = getStaticData();
    return <ProgressView message={loadingText ? loadingText : ''} />;
  }

  componentDidMount() {
    this.getPropsData();
    this.cancelRequestKey = getCancelRequestKeyName(CancelPreviewRequest.FLIGHT, this.bookingId);
    removeKeyFromAsyncStorage(this.cancelRequestKey);
    this.fetchJSONAsync();
  }

  getPropsData() {
    const data = JSON.parse(this.props.data);
    this.bookingId = data.bookingId;
    this.flightCancellationType = data.flightCancellationType;
    this.segmentsGroupView = data.segmentsGroupView;
    this.pageData = this.props.pageData && JSON.parse(this.props.pageData);
  }

  async fetchJSONAsync() {
    const {
      errorText: { unableToFetchCancellationDetailError },
    } = getStaticData();

    const cancelRequest = getFlightFullCancellationRequest(
      this.bookingId,
      FLIGHT_CANCELLATION_REASON.CITP,
      FLIGHT_CANCELLATION_REFUND_OPTIONS.RefundToCustomer,
    );
    cancelRequest.flightCancellationType = this.flightCancellationType;
    await storeObjInAsyncStorage(this.cancelRequestKey, cancelRequest);
    const responseObj = await fetchFlightCancellationDetails(this.bookingId, cancelRequest);
    
    if (responseObj && responseObj.exception){
      showLongToast(unableToFetchCancellationDetailError);
      CancellationTrackingHelper.trackErrorEvent(
        this.trackingPageName,
        this.getErrorTrackingBundle(responseObj.errorMsg),
        undefined,
      );
      Actions.pop();
    }
    else {
      responseObj && this.validateCancellationResponse(responseObj);
    }
  }

  validateCancellationResponse = (responseObj) => {
    const {
      errorText: { unableToFetchCancellationDetailError },
      cancelFlightBookingText,
    } = getStaticData();
    if (responseObj && responseObj.isLoaded && !responseObj.exception) {
      const { cancellationResponse } = responseObj;
      this.cancellationResponse = cancellationResponse;
      const headerTitle = cancelFlightBookingText;
      const cancellationRequest = getFlightFullCancellationRequest(this.bookingId, FLIGHT_CANCELLATION_REASON.CITP, FLIGHT_CANCELLATION_REFUND_OPTIONS.RefundToCustomer);
      cancellationRequest.flightCancellationType = this.flightCancellationType;
      if (responseObj.error && responseObj.hasErrorData) {
        if (Platform.OS === 'web') {
          openErrorPage(
            cancellationResponse.errorPageData,
            this.onBackPressFromError,
            this.onTryAgainClicked,
            this.bookingId,
            'cancellation',
            ActionConst.REPLACE,
            {
              pageName: 'flightCancellationPage',
              currentPageData: {
                bookingId: this.bookingId,
                data: this.props.data,
                type: ActionConst.REPLACE,
              },
              isCorporateBooking: this.props.isCorporateBooking,
            },
            {
              flightCancellationRequest: cancellationRequest
            },
          );
        } else {
          openErrorPage(
            {...cancellationResponse.errorPageData, type: 'replace'},
            false,
            this.onTryAgainClicked,
            this.bookingId,
            'cancellation',
            Actions,
            {
              psLob: LOBNAMES.FLIGHT,
              langAware: true,
              isCorporateBooking: this.props.isCorporateBooking,
            },
            {
              flightCancellationRequest: cancellationRequest
            },
          );
        }
        CancellationTrackingHelper.trackErrorEvent(
          this.trackingPageName,
          this.getErrorTrackingBundle(cancellationResponse.errorPageData.errorCode),
          cancellationResponse.errorPageData.meta ? cancellationResponse.errorPageData.meta.supplier : undefined,
        );
      } 
      else if (!responseObj.error && responseObj.success) {
        const headerSubTItle = getJourneyIntentDtl(cancellationResponse);

        if (
          this.flightCancellationType === FLIGHT_CANCELLATION_TYPE.PARTIAL_CANCELLATION ||
          shouldNotRedirectToBreakUpPage(cancellationResponse)
        ) {
          Actions.partialPaxSelection({
            headerTitle,
            headerSubTItle,
            cancellationPreviewResponseJson: cancellationResponse,
            cancellationRequest,
            trackingBundle: this.getTrackingBundle(),
            segmentsGroupView: this.segmentsGroupView,
            type: ActionConst.REPLACE,
            pageData: this.pageData
          });
        } else if (cancellationResponse.isCancelAnyway) {
          Actions.cancelAnyWayRequest({
            headerTitle,
            cancellationPreviewResponseJson: cancellationResponse,
            cancellationRequest,
            headerSubTItle,
            trackingBundle: this.getTrackingBundle(),
            type: ActionConst.REPLACE,
          });
        } else {
          Actions.flightBookingRefundBreakup({
            headerTitle,
            cancellationPreviewResponseJson: cancellationResponse,
            cancellationRequest,
            trackingBundle: this.getTrackingBundle(),
            headerSubTItle,
            allPaxSelected: true,
            isCorporateBooking: this.props.isCorporateBooking,
            pageData: this.pageData,
            isFull: this.flightCancellationType === FLIGHT_CANCELLATION_TYPE.FULL_CANCELLATION,
            type: ActionConst.REPLACE,
          });
        }
      } else {
        Actions.cancellationErrorPage({
          headerTitle,
          cancellationPreviewResponseJson: cancellationResponse,
          cancellationRequest,
          trackingBundle: this.getErrorTrackingBundle(cancellationResponse.businessErrorDescription),
          type: ActionConst.REPLACE,
        });
      }
    } else {
      showLongToast(unableToFetchCancellationDetailError);
      CancellationTrackingHelper.trackErrorEvent(
        this.trackingPageName,
        this.getErrorTrackingBundle('response_error'),
        undefined,
      );
      Actions.pop();
    }
  };

  getTrackingBundle() {
    const trackingBundle = {};
    trackingBundle.response = this.cancellationResponse;
    trackingBundle.isInternational =
      this.cancellationResponse && this.cancellationResponse.international;
    trackingBundle.flightCancellationType = this.flightCancellationType;
    trackingBundle.isFullSelection =
      this.flightCancellationType === FLIGHT_CANCELLATION_TYPE.FULL_CANCELLATION;
    return trackingBundle;
  }

  getErrorTrackingBundle(error) {
    const trackingBundle = this.getTrackingBundle();
    trackingBundle.error = error;
    return trackingBundle;
  }

  onTryAgainClicked = () => {
    this.fetchJSONAsync();
  };

  onBackPressFromError = () => {
    setTimeout(() => Actions.pop());
  };
}

CancellationComponent.propTypes = {
  data: PropTypes.string.isRequired,
};
