import React from 'react';
import { isEmpty} from 'lodash';
import { Text, View, Image, TouchableOpacity } from 'react-native';
import styles from './CancelledDtlsCss';
import CommonStyle from '@mmt/legacy-commons/Styles/AtomicCss';
import {ZeroCancelIcon, GreyInfoIcon} from '../../HolidayBookingConstants';

const DeductionBreakup = ({ handleBottomOverlay , deductions, deductionHeader, refundBreakup, customerCurrency, myCashMessage}) => {
    const price = refundBreakup.amount && refundBreakup.amount.price && parseInt(refundBreakup.amount.price.split(' ')[1]);
    const getPriceStyle = ()=>{
       if(!isNaN(price) && price > 0){
           return [styles.totalRefundTxt];
       }
       else {
           return [styles.totalRefundTxt, styles.errorTxt];
       }
    }
    return (
        <View style={styles.DeductionBreakUpWrapper}>
            <View style={[styles.fareBreakupHeading]}>
                <View style={CommonStyle.flexRow}>
                    <Text style={styles.fbHeadingTxt}>{deductionHeader.text}</Text>
                </View>
                <Text style={styles.totalPaidPriceTxt}>{deductionHeader.price}{' '}</Text>
            </View>
            <View style={[styles.fareBreakupDtlsWrapper]}>
                <View style={styles.fareBrkupRowWrapper}>
                    {deductions && deductions.length > 0 && deductions.map((deduction, index)=>{
                        if(!deduction.isTCS){
                            return (
                            <View style={styles.fareBrkupRow}>
                                    <View style={styles.flex1}>
                                        <View style={CommonStyle.flexRow}>
                                            {deduction.isZC  && (<Image style={styles.zcIconSm} source={ZeroCancelIcon} /> )}
                                            <Text style={styles.fareBrkupTxt}>{deduction.text}</Text>
                                        </View>
                                        {!isEmpty(deduction.subText) && <View style={[CommonStyle.flexRow, CommonStyle.alignCenter]}>
                                            <Text style={styles.infoTxt}>{deduction.subText}</Text>
                                        </View>}
                                </View>
                                <Text style={styles.fareBrkupTxtPrice}>
                                    <Text style={deduction.isZC ? CommonStyle.greenText:''}>
                                        {deduction.price}{' '}
                                    </Text>
                                </Text>
                            </View>)
                        }
                        else {
                            return (
                                <View style={styles.fareBrkupRow}>
                                    <View style={styles.flex1}>
                                        <Text style={styles.fareBrkupTxt}>{deduction.text} </Text>
                                        <View style={[CommonStyle.flexRow, CommonStyle.alignCenter]}>
                                            <Text style={styles.infoTxt}>{deduction.subText}</Text>
                                            <TouchableOpacity onPress={() => handleBottomOverlay("TCSInfo")}>
                                                <Image style={styles.infoIcon} source={GreyInfoIcon} />
                                            </TouchableOpacity>
                                        </View>
                                    </View>
                                    <Text style={styles.fareBrkupTxtPrice}>{deduction.price}{' '}</Text>
                                </View>
                                );
                        }
                        
                    })}
                    {!isEmpty(myCashMessage) && <View style={styles.myCashRow}>
                        <Text style={styles.fareBrkupTxt}>{myCashMessage}</Text>
                    </View>}
                </View>
                <View style={[styles.fareBrkupRow, styles.totalRefund]}>
                    <Text style={getPriceStyle()}>{refundBreakup.amount.text}</Text>
                    <Text style={getPriceStyle()}>{refundBreakup.amount.price}{' '}</Text>
                </View>
            </View>
        </View>
    );
}
export default DeductionBreakup;
