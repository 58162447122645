import { StyleSheet } from 'react-native';
import { Dimensions } from "react-native";
const windW = Dimensions.get("window").width;
const windowH = Dimensions.get("window").height;

export default (theme) =>
  StyleSheet.create({
    whiteColor: {
      backgroundColor: theme.color.white,
    },
    topStickySection: {
      zIndex: 0,
    },
    TabsWrapper: {
      borderBottomWidth: 1,
      marginTop: 2,
      paddingTop: 5,
      borderBottomColor: theme.color.faintgrey,
      backgroundColor: theme.color.white,
      flexDirection: "row",
      zIndex: 1,
      textAlign: "center",
    },
    OverlayContent: {
      backgroundColor: theme.color.white,
      paddingTop: 12,
      paddingBottom: 20,
      paddingHorizontal: 30,
      zIndex: 12,
      width: "100%",
      borderTopLeftRadius: 16,
      borderTopRightRadius: 16,
      position: "absolute",
      bottom: 0,
    },
    cardWrapper: {
      width: windW,
      paddingVertical: 10,
    },
    AssistanceHead: {
      backgroundColor: theme.color.white,
      padding: 16,
    },
    container: {
      backgroundColor: theme.color.lightGrey,
      flex: 1,
    },
    topWrapper: {
      backgroundColor: theme.color.limeGreen,
      padding: 16,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      marginBottom: 10,
    },
    OverlayContent: {
      backgroundColor: theme.color.white,
      paddingTop: 12,
      paddingBottom: 20,
      paddingHorizontal: 30,
      zIndex: 12,
      width: "100%",
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      position: "absolute",
      bottom: 0,
    },
    bottomWrapper: {
      paddingHorizontal: 30,
    },
    mealOverlay: {
      overflow: "hidden",
      height: windowH - 120,
      paddingBottom: 60,
      paddingHorizontal: 0,
    },
    overlayTopline: {
      width: 40,
      height: 4,
      borderRadius: 4,
      backgroundColor: theme.color.faintgrey,
      alignSelf: "center",
    },
    cardLinkWrapper: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      marginHorizontal: 12,
    },
    buttonBottomWrapper: {
      position: "absolute",
      bottom: 12,
      paddingHorizontal: 46,
      width: "100%",
      flex: 1,
      textAlign: "center",
      alignItems: "center",
      zIndex: 2,
    },
    blackFooterWrapper: {
      position: "absolute",
      bottom: -6,
      backgroundColor: theme.color.black28,
      paddingVertical: 12,
      paddingHorizontal: 10,
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    },
    btmTextInfo: {
      color: theme.color.gray30,
    },
    infoIconStyle: {
      width: 18,
      height: 18,
      marginLeft: 5,
    },
  
    careHead: {
      marginRight: 12,
    },
    headerSec: {
      paddingHorizontal: 12,
      paddingTop: 16,
    },
    headingIcon: {
      width: 24,
      height: 24,
      marginRight: 18,
      backgroundColor: theme.color.grey2,
    },
    person: {
      width: 56,
      height: 56,
      marginRight: 10,
      borderRadius: 50,
    },
    host: {
      width: 68,
      height: 18,
      marginTop: 5,
    },
    call: {
      width: 40,
      height: 40,
    },
    message: {
      width: 40,
      height: 40,
    },
    docList: {
      paddingLeft: 15,
      paddingRight: 15,
    },
    bullet: {
      width: 4,
      height: 4,
      marginRight: 10,
      marginTop: 6,
      backgroundColor: theme.color.defaultTextColor,
      borderRadius: 50,
    },
    tag: {
      backgroundColor: theme.color.grayBg,
      borderRadius: 2,
      paddingHorizontal: 8,
      paddingVertical: 4,
      marginRight: 5,
    },
    responBorder: {
      borderTopWidth: 1,
      borderColor: theme.color.lightGrey,
      paddingTop: 16,
    },
  
  
    FreeCancelCardWrapper: {
      borderBottomWidth: 1,
      borderBottomColor: theme.color.lightGrey18,
    },
    moreDesc:{
      marginBottom:16,
    },
    iconStyle:{
      width:20,
      height:20,
      marginRight:10,
    },
    hostImgWrapper:{
      width:70,
      height:70,
      marginLeft:10,
      borderRadius:50
    },
    hostImg:{
      width:70,
      height:70,
      borderRadius:50
    },
    cottageIcon:{
      width:16,
      height:16,
      marginRight:5,
    },
    infoSection:{
      paddingTop:12,
      width:'70%'
    },
    careTackerSection:{
      padding:12,
    },
    careTakerIcon:{
      width:16,
      height:16,
      marginRight:10,
    },
    actionWrapper:{
      borderTopWidth:1,
      borderTopColor:theme.color.lightGray,
      paddingTop:12,
      marginHorizontal:-14,
      paddingHorizontal:12
    },
    gradientBorder:{
      height:1,
      width:"100%",
      lineHeight:10
    },
  });


