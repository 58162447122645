import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import createStyles from './style';
import { useTheme } from '../../theme/theme.context';
import isEmpty  from 'lodash/isEmpty';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const TabList = ({ handleTab, item, index, activeCounterTab }) => {
    const {psTheme: theme} = useTheme();
    const fonts = getFont();
    const styles = createStyles(fonts, theme);
    const activeTab = index === activeCounterTab;
    const activeOuterStyle = activeTab ? 'radioActive' : '';
    return (
        <TouchableOpacity style={styles.countryFilterTabs} onPress={() => handleTab(index, item)}>
            <View style={[styles.radio, styles[activeOuterStyle]]}>
                {activeTab &&
                    <View style={styles.radioCircle} />
                }
            </View>
            {!isEmpty(item) && <Text style={styles.nameText}>{item}</Text>}
        </TouchableOpacity>
    );
};


export default TabList;
