import { StyleSheet, Text, View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { Placeholder, PlaceholderContainer } from 'react-native-loading-placeholder';

import React from 'react';
import Card from '@mmt/ui/components/card';

const placeholderBg = '#f1f1f1';

const Gradient = () => (
  <LinearGradient
    colors={[placeholderBg, '#f3f3f3', '#d3d3d3', '#f5f5f5']}
    start={{ x: 1.0, y: 0.0 }}
    end={{ x: 0.1, y: 0.1 }}
    style={{
      flex: 1,
    }}
  />
);

const dummyLoaderPromise = new Promise(() => {});

const Loader = () => {
  return (
    <PlaceholderContainer
      style={{
        paddingHorizontal: 10,
        paddingVertical: 12,
        backgroundColor: '#ffffff',
        borderRadius: 4,
        borderWidth: 1,
        borderColor: '#D8D8D8',
        marginHorizontal: 10,
      }}
      animatedComponent={<Gradient />}
      duration={800}
      delay={100}
    >
      <View style={{ flex: 1, paddingHorizontal: 8 }}>
        <Placeholder
          style={{
            height: 12,
            backgroundColor: placeholderBg,
          }}
          replace
          loader={dummyLoaderPromise}
        />
        <Card style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Placeholder
            style={{
              width: 80,
              height: 50,
              marginTop: 12,
              backgroundColor: placeholderBg,
            }}
            replace
            loader={dummyLoaderPromise}
          />
          <View style={{ flex: 1, marginLeft: 8 }}>
            <Placeholder
              style={{
                height: 12,
                width: 60,
                marginTop: 2,
                backgroundColor: placeholderBg,
              }}
              replace
              loader={dummyLoaderPromise}
            />
            <Placeholder
              style={{
                height: 20,
                width: 80,
                marginTop: 2,
                backgroundColor: placeholderBg,
              }}
              replace
              loader={dummyLoaderPromise}
            />
            <Placeholder
              style={{
                width: 112,
                height: 12,
                marginTop: 2,
                backgroundColor: placeholderBg,
              }}
              replace
              loader={dummyLoaderPromise}
            />
          </View>
        </Card>

        <Placeholder
          style={{
            height: 43,
            marginTop: 8,
            backgroundColor: placeholderBg,
          }}
          replace
          loader={dummyLoaderPromise}
        />
        <Placeholder
          style={{
            height: 43,
            marginTop: 8,
            backgroundColor: placeholderBg,
          }}
          replace
          loader={dummyLoaderPromise}
        />
      </View>
    </PlaceholderContainer>
  );
};

export default Loader;
