import React, { useState } from 'react';
import {ScrollView, View, Image, Text, Linking, TouchableOpacity} from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import LinearGradient from 'react-native-linear-gradient';
import styles from '../GiftCardDetailsCss';
import GiftCardBookingDetailsConstant from '../GiftCardBookingDetailsConstant';
import {isNotNullAndEmpty} from '../GiftCardBookingDetailUtil';
import GiftCardBookingTrackingHelper from '../GiftCardBookingTrackerHelper';
import { MAIL_ICON, MESSAGE_ICON, MMT_MAIL_ICON, TRAVELLER_ICON, CALL_ICON , VIEW_MORE_ARROW_DOWN } from '../GiftCardImageConstants';
import GiftCardDetails from './GiftCardDetails';
import { getFont } from "../../PostSalesStyles";
import {  useTheme } from '../../theme/theme.context';


const YellowGiftCard = ({ giftCardBookingInfo, GiftCardSuccessfully, GiftCardShipped, pageName, handleClick }) => {
	const { emailDetails, courierDetails } = giftCardBookingInfo;
	const {fsStyle, ...fonts} = getFont(true);
	const { psTheme: theme } = useTheme();

	const openTrackDeliveryUrl = (url) => {
		GiftCardBookingTrackingHelper.trackClickEvent(pageName, 'gc_customersupport_tracking_clicked');
		Linking.openURL(url);
	}

	const [listToShow, setListToShow] = useState(GiftCardBookingDetailsConstant.DEFAULT_SIZE_BEFORE_PRESSING_VIEW_MORE)
	const handleViewMore = () => {
		setListToShow(giftCardBookingInfo.cardInfo.length)
	}

	return (
		<ScrollView>
			{GiftCardSuccessfully && giftCardBookingInfo && giftCardBookingInfo.cardInfo && 
			<View style={styles.gcSummaryGiftCardWrapper}>
			<View style={styles.gcSummaryGiftCardInnerDtls}>
				<Text style={[styles.gcSummaryHeadingText]}>{GiftCardBookingDetailsConstant.RECIEPIENT_DETAILS}</Text>
					{giftCardBookingInfo.cardInfo.map((item, index) => {
						if (index < listToShow) {
							return (
								<GiftCardDetails
									item={item}
									index={index}
									handleClick={handleClick}
									deliveryMode={giftCardBookingInfo.giftCardDetails.deliveryMode}
									lastSectionStyle = {((index === giftCardBookingInfo.cardInfo.length - 1) || (listToShow === GiftCardBookingDetailsConstant.DEFAULT_SIZE_BEFORE_PRESSING_VIEW_MORE && index !== 0))? 'lastSection' : ""}
								/>
							)
						}
					})}
					{listToShow === GiftCardBookingDetailsConstant.DEFAULT_SIZE_BEFORE_PRESSING_VIEW_MORE && giftCardBookingInfo && giftCardBookingInfo.cardInfo && giftCardBookingInfo.cardInfo.length > GiftCardBookingDetailsConstant.DEFAULT_SIZE_BEFORE_PRESSING_VIEW_MORE && 
						<TouchableOpacity style={styles.gcSummaryViewMoreWrapper} onPress={handleViewMore}>
							<Text style={[fonts.boldFontFamily, {color:theme.color.azure}, fsStyle.font12]}>{GiftCardBookingDetailsConstant.VIEW_MORE}</Text>
							<Image style={styles.gcSummaryDownArrow} source={VIEW_MORE_ARROW_DOWN}/>
						</TouchableOpacity>
					}
				</View>
			</View>}
			

			<View>
				{GiftCardShipped && courierDetails &&
					<LinearGradient start={{ x: 0.0, y: 1.0 }} end={{ x: 1.0, y: 0.0 }} colors={['rgba(243,212,82,0.35)', 'rgba(240,152,25,0.35)']} style={[styles.gradientStyle, AtomicCss.marginTop15]}>
						<Text style={[AtomicCss.font16, AtomicCss.marginBottom6, AtomicCss.blackFont, AtomicCss.blackText, AtomicCss.lineHeight18, AtomicCss.marginBottom16]}>{courierDetails.heading}</Text>
						{isNotNullAndEmpty(courierDetails.recipientName) && <View style={[AtomicCss.flexRow, AtomicCss.marginBottom16]}>
							<View style={styles.GcTyIconBox}><Image style={styles.GcTyIcon} source={TRAVELLER_ICON} /></View>
							<View style={AtomicCss.flex1}>
								<Text style={[AtomicCss.regularFont, AtomicCss.font12, AtomicCss.defaultText, AtomicCss.marginBottom5]}>{GiftCardBookingDetailsConstant.RECIPIENT_NAME}</Text>
								<Text style={[AtomicCss.boldFont, AtomicCss.font12, AtomicCss.blackText]}>
									{courierDetails.recipientName}
								</Text>
							</View>
						</View>}
						{isNotNullAndEmpty(courierDetails.recipientNumber) && <View style={[AtomicCss.flexRow, AtomicCss.marginBottom16]}>
							<View style={styles.GcTyIconBox}>
								<Image style={styles.GcTyIcon} source={CALL_ICON} />
							</View>
							<View style={AtomicCss.flex1}>
								<Text style={[AtomicCss.regularFont, AtomicCss.font12, AtomicCss.defaultText, AtomicCss.marginBottom5]}>{GiftCardBookingDetailsConstant.RECIPIENT_NUMBER}</Text>
								<Text style={[AtomicCss.boldFont, AtomicCss.font12, AtomicCss.blackText]}>
									{courierDetails.recipientNumber}
								</Text>
							</View>
						</View>}
						{isNotNullAndEmpty(courierDetails.recipientAddress) && <View style={[AtomicCss.flexRow, AtomicCss.marginBottom16]}>
							<View style={styles.GcTyIconBox}>
								<Image style={styles.GcTyIcon} source={MMT_MAIL_ICON} />
							</View>
							<View style={AtomicCss.flex1}>
								<Text style={[AtomicCss.regularFont, AtomicCss.font12, AtomicCss.defaultText, AtomicCss.marginBottom5]}>{GiftCardBookingDetailsConstant.RECIPIENT_ADDRESS}</Text>
								<Text style={[AtomicCss.boldFont, AtomicCss.font12, AtomicCss.blackText]}>
									{courierDetails.recipientAddress}
								</Text>
							</View>
						</View>}
						{isNotNullAndEmpty(courierDetails.trackDeliveryUrl) && <TouchableOpacity style={styles.copyLinkStyle} onPress={() => openTrackDeliveryUrl(courierDetails.trackDeliveryUrl)}>
							<Text style={[AtomicCss.font14, AtomicCss.blackFont, styles.blueText]}>
								{courierDetails.trackDeliveryText}
							</Text>
						</TouchableOpacity>}
					</LinearGradient>
				}
			</View>
		</ScrollView>
	)
}

export default YellowGiftCard;