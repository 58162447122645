import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import { Platform } from 'react-native';

const styles = {
  contentTitle: {
    color: colors.black,
    marginBottom: 24,
  },
  contentDesc: {
    fontSize: 16,
    color: colors.defaultTextColor,
    marginBottom: 30,
    lineHeight: 22,
  },
  contentFooter: {
    paddingVertical: 15,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  uploadIconWrapper: {
    width: 60,
    height: 60,
    borderRadius: 6,
    backgroundColor: '#fdedd2',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    marginTop: Platform.OS === 'web' ? -55 : -45,
    zIndex: 11,
  },
  submittedWrapper: {
    paddingTop: 50,
  },
  uploadIconstyle: {
    width: 40,
    height: 40,
  },
  desc: {
    color: '#000',
    marginBottom: 15,
  },
  actionBtn: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  actionBtnText: {
    color: '#008cff',
  },
  continueBtnTxtWeb: {
    fontSize: 14,
    marginTop: 16,
    color: '#008cff',
  },
};

export default styles;
