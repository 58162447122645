import React, { useState } from 'react';
import { View, Image, Text, TouchableOpacity, ScrollView, NativeSyntheticEvent, NativeScrollEvent } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import getStyle from './styles';
import { KioskStep } from './kioskStepsList';
import { HelpFullDirectionCard } from './helpFullDirectionCard';
import ButtonWpr from 'apps/post-sales/src/cab/components/ButtonWpr';
import { openGoogleMap } from 'apps/post-sales/src/Common/commonUtil';
import { OverlayData } from 'apps/post-sales/src/cab/types';
import { closeIcon } from 'apps/post-sales/src/cab/CabImageConstants';
import CabBookingTrackingHelper from 'apps/post-sales/src/cab/CabBookingTrackingHelper';

const KioskDirectionsOverlay
	= ({ overlayData, handleBottomOverlay, pageName }: { overlayData: OverlayData; handleBottomOverlay: () => void, pageName: string }) => {
		const { heading, subHeading, openMapData, desc, steps, feedbackData, commentSection } = overlayData || {};
		const fonts = getFont();
		const { psTheme: theme } = useTheme();
		const styles = getStyle(fonts, theme);

		const navigateToGoogleMap = () => {
			if (!openMapData?.directions) {return;}
			CabBookingTrackingHelper.trackClickEvent(pageName, 'Mob_Cabs_MIMA_KioskDirectionOverlay_OpenMaps_Clicked');
			const {latitude, longitude} = openMapData?.directions;
			return openGoogleMap(latitude, longitude);
		};

		const handleOverlayClose = (ctaType: string) => {
			CabBookingTrackingHelper.trackClickEvent(pageName, `Mob_Cabs_MIMA_PickupDirections_${ctaType}_Click`);
			handleBottomOverlay?.();
		};

		const handleScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
			const { layoutMeasurement, contentOffset, contentSize } = event.nativeEvent;
			const isScrolledToBottom = layoutMeasurement.height + contentOffset.y >= contentSize.height - 20;
			if (isScrolledToBottom) {
				CabBookingTrackingHelper.trackClickEvent(pageName, 'Mob_Cabs_MIMA_PickupDirections_ScrollEnd');
			}
		};

		return (
			<View style={{backgroundColor : theme.color.white}}>
				<View style={[styles.headingSection]}>
					<TouchableOpacity onPress={() => handleOverlayClose('Close')}>
						<Image style={styles.crossIconStyle} source={closeIcon} />
					</TouchableOpacity>
					<View
						style={[
							AtomicCss.flexRow,
							AtomicCss.alignCenter,
							AtomicCss.spaceBetween,
							AtomicCss.flex1,
						]}
					>
						{!!heading && <Text style={styles.headingText}>{heading}</Text>}
						<TouchableOpacity onPress={navigateToGoogleMap}>
							<Text style={[AtomicCss.boldFont, AtomicCss.azure, AtomicCss.lineHeight16]}>
								{openMapData?.text}
							</Text>
						</TouchableOpacity>
					</View>
				</View>
				<View style={styles.detailsSection}>
					<ScrollView showsVerticalScrollIndicator={true} onScroll={handleScroll}>
						<View style={styles.bodySection}>
							<View style={styles.headingDetails}>
								<Text
									style={[
										AtomicCss.blackText,
										AtomicCss.boldFont,
										AtomicCss.font16,
										AtomicCss.marginBottom3,
										AtomicCss.lineHeight20,
									]}
								>
									{subHeading}
								</Text>
								<Text
									style={[
										AtomicCss.defaultText,
										AtomicCss.regularFont,
										AtomicCss.font14,
										AtomicCss.lineHeight20,
									]}
								>
									{desc}
								</Text>
							</View>
							{steps.map((item, index) => {
								return (
									<KioskStep
										item={item}
										isLastRow={index === steps.length - 1}
										key={index}
										id={index + 1}
									/>
								);
							})}
							<HelpFullDirectionCard
								feedbackData={feedbackData}
								commentSection={commentSection}
								pageName={pageName}
							/>
						</View>
					</ScrollView>
				</View>
				<View style={styles.btnWrpper}>
					<ButtonWpr
						buttonText="OKAY"
						btnTxtStyle={styles.btnTxtStyle}
						onButtonPress={() => handleOverlayClose('Ok')}
					/>
				</View>
			</View>
		);
	};

export default KioskDirectionsOverlay;
