import React from 'react';
import {Text, ScrollView, Image, View} from 'react-native';
import styles from './busTrackerCss';
import {trackingPointer} from '../../../BusImageConstants';
import {connect} from 'react-redux';
import { label } from '@mmt/post-sales/src/staticData/staticData';

class BusTracker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      minNumberOfItemsToShowInTracker: 2,
      isMoreOptionEnable: true,
      busLiveData: props.busLiveData
    };
  }

  // const isBoardingFromStartingPoint = route[0].id === this.props.bookingData.boardingPointId;

  isTrackingPointerVisible = (busStop) => {
    if (busStop.status === 'CROSSED' && busStop.type === 'BP') {
      return true;
    }
    return false;
  }

  // calculateTrackerPositionFromTop = (busStop) => {
  //   const {distanceCovered, distanceFromNextStop} = busStop;
  //   if (distanceCovered && distanceFromNextStop) {
  //     return `${((distanceCovered / distanceFromNextStop) * 100).toFixed(3)}%`;
  //   }
  //   return 0;
  // }

  renderTrackingPointer = (busStop) => {
    if (this.isTrackingPointerVisible(busStop)) {
      return (
        <View style={[styles.trackingPointer, {top: '60%'}]}>
          <Image source={trackingPointer} style={styles.trackingPointerIcon} />
        </View>
      );
    }
    return null;
  }

  isBusStopCrossed = busStop => busStop.status === 'CROSSED'

  renderGreenTrackingLine = () => <View style={[styles.greenTrackingLine, {height: '100%'}]} />

  getStopTitle = (busStop) => {
    let title = this.isBusStopCrossed(busStop) ? label('bus.trackDetail.crossed') : label('bus.trackDetail.nextStop');
    if (busStop.type === 'PERSONALISED_BP') {
      title = this.isBusStopCrossed(busStop) ? label('bus.trackDetail.youBoardedAt') : label('bus.trackDetail.youBoardAt');
    } else if ((busStop.type === 'PERSONALISED_DP')) {
      title = label('bus.trackDetail.youDropAt');
    }
    return title;
  }

  renderBusStop = (busStop, isLastStop, moreButton) => {
    const activeSuffix = this.isBusStopCrossed(busStop) ? 'Active' : '';
    const crossedStopSuffix = this.isBusStopCrossed(busStop) ? 'Crossed' : '';
    return (
      <View style={styles.busStop} key={busStop.id}>
        {this.isBusStopCrossed(busStop) ? this.renderGreenTrackingLine(busStop) : null}
        <View style={[styles.locatioListStyleType, styles[`locatioListStyleType${activeSuffix}`]]} />
        {!isLastStop ? <View style={styles.locationTrackBorder} /> : null}
        {this.renderTrackingPointer(busStop)}
        <View>
          <Text style={styles.busStopTitle}>{this.getStopTitle(busStop)}</Text>
          <Text style={styles.busStopName}>{busStop.name}</Text>
          <Text style={[styles.busStopEstimateTime, styles[`busStopEstimateTime${crossedStopSuffix}`]]}>
            {label('bus.trackDetail.estArrTime', null, { estimatedTime: busStop.estimatedTime })}
            <Text style={styles.busStopScheduleTime}> {`(${label('bus.trackDetail.schdTime', null, { scheduleTime: busStop.scheduleTime })})`}</Text>
          </Text>
        </View>
        {moreButton || null}
      </View>
    );
  }

  // Function to hide more option and show all remaining locations
  showAllStops = (numberOfRemainingItems) => {
    if (numberOfRemainingItems > 0) {
      const len = this.state.minNumberOfItemsToShowInTracker + numberOfRemainingItems;
      this.setState({
        isMoreOptionEnable: false,
        minNumberOfItemsToShowInTracker: len
      });
    }
  }

  // function to render more option button
  renderMoreButton = () => {
    const numberOfRemainingItems = this.props.busLiveData.positions.length - this.state.minNumberOfItemsToShowInTracker;
    if (numberOfRemainingItems > 0) {
      return (
        <Text
          onPress={() => this.showAllStops(numberOfRemainingItems)}
          style={styles.moreLink}
        >
          {label('bus.trackDetail.moreStops', null, { numberOfRemainingItems })}
        </Text>
      );
    }
    return null;
  }

  renderBusStopList = () => {
    const route = this.props.busLiveData.positions;
    const lastStopIndex = route ? route.length - 1 : 0;
    const busStopList = [];
    const currentStopIndex = 0;
    const {minNumberOfItemsToShowInTracker, isMoreOptionEnable} = this.state;
    for (let i = 0; i < minNumberOfItemsToShowInTracker; i++) {
      if (isMoreOptionEnable && i === currentStopIndex + minNumberOfItemsToShowInTracker - 1) {
        busStopList.push(this.renderBusStop(route[i], false, this.renderMoreButton()));
      } else {
        const isLastStop = i === lastStopIndex;
        busStopList.push(this.renderBusStop(route[i], isLastStop));
      }
    }
    return busStopList;
  }

  render() {
    return (
      <ScrollView>
        <View style={styles.busStopList}>
          {this.renderBusStopList()}
        </View>
      </ScrollView>
    );
  }
}

const mapStateToProps = state => ({
  busLiveData: state.busBookingDetailReducer.busLiveData
}
);

export default connect(mapStateToProps, null)(BusTracker);
