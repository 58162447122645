import { StyleSheet } from 'react-native';
import {colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles'
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';

const styles = StyleSheet.create({
  steps: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.white,
    paddingVertical: 9,
    paddingHorizontal: 15,
    ...getPlatformElevation(2),
    position: 'relative',
    zIndex: 2,
    marginTop: -2
  },
  stepItem: {
    flexDirection: 'row',
    marginRight: 10,
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 150
  },
  stepNumberWrapper: {
    backgroundColor: colors.disabledBtnBg,
    width: 13,
    height: 13,
    borderRadius: 13,
    marginRight: 3
  },
  stepNumberWrapperActive: {
    backgroundColor: colors.black
  },
  stepNumber: {
    fontSize: 10,
    color: colors.white,
    lineHeight: 13,
    textAlign: 'center'
  },
  stepName: {
    color: colors.disabledBtnBg,
    fontSize: 10
  },
  stepNameActive: {
    color: colors.black
  }
});

export default styles;

