import { StyleSheet } from 'react-native';
import {  fonts } from '@mmt/legacy-commons/Styles/globalStyles';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';

const styles = StyleSheet.create({
    imgWrapper:{
        width:40,
        height:40,
        marginRight:16,
        flexDirection:'row',
        position:"relative",
        justifyContent: "center"
    },
    imgStyle:{
        width:40,
        height: 50,
        position:"absolute",
        top: 6,
        borderRadius: 3
    },
    imgStyleStack:{
        top: 3,
        width:35,
        height: 4,
        position:"absolute",
        opacity: 0.6,
        borderRadius:3,
        backgroundColor: colors.black
    },
    imgStyleStackleft:{
        top: 0,
        width: 30,
        height: 4,
        position:"absolute",
        opacity: 0.3,
        borderRadius: 3,
        backgroundColor: colors.black
    },
    linkTxt:{
        color: colors.black,
        marginBottom:4,
        lineHeight:20
    },
    innerDesc:{
        color:colors.defaultTextColor,
        fontFamily: fonts.regular,
        lineHeight:15
    },
    arrowStyle:{
        width:8,
        height:13,
        alignSelf: 'flex-start'
    },
   
});

export default styles;
