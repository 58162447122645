import { StyleSheet, Platform } from 'react-native';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';

export const styles = StyleSheet.create({
  overlayContentStyle: {
    backgroundColor: colors.white,
    padding: 20,
    paddingTop: 15,
    position: 'relative',
    zIndex: 1,
    width: '100%',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    marginBottom: Platform.select({
      ios: -60,
    }),
  },
  overlayWrapperStyle: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    elevation: 10,
    zIndex: 100,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    flexDirection: 'row',
  },
});
