import React from 'react';
import PropTypes from 'prop-types';
import {View, Text, Image} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import _ from 'lodash';
import styles from './CabBookingDetailStyles';
import styles1 from '../CabMamiCss';
import CabBookingDetailsConstant from '../../CabBookingDetailsConstant';
import CabOnSpotInfoCard from '../CabOnSpotInfoCard';
import {callCabdriver, checkOnSpotDataAvailable, chatCabdriver} from '../../utils/CabBookingDetailUtil';
import CapsuleWhiteBtn from '../../../Rails/components/buttons/CapsuleWhiteBtn';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {driverIcon} from '../../CabImageConstants';
import MoreInfoForOnSportCab from '../MoreInfoForOnSpotCab';
import ButtonWrapper from 'apps/post-sales/src/Common/ButtonWrapper';

const orangeGradient = ['#ff7f3f', '#ff3e5e'];
const greenGradient = ['#219393', '#26bd99'];

class CabAndDriverDetailsCard extends React.Component {
  render() {
    if (checkOnSpotDataAvailable(this.props.response)) {
      const carOnSpotBoardingInfo = (this.props.response.carAdditionalInfo &&
          this.props.response.carAdditionalInfo.additionalLOBInfo) ? this.props.response.carAdditionalInfo.additionalLOBInfo.onSpotBoardingInfo : null;

      return (
        (this.props.response.clientDetails.showNewOnSpotCard) ?
        (carOnSpotBoardingInfo &&
        <MoreInfoForOnSportCab carOnSpotBoardingInfo={carOnSpotBoardingInfo}
          closePopup={this.props.closePopup}
          response={this.props.response}/>) :
        (<View style={styles.card}>
          <View style={[styles1.row]}>
            <CabOnSpotInfoCard
              bookingId={this.props.response.bookingId}
              response={this.props.response}
              carOnSpotBoardingInfo={carOnSpotBoardingInfo}
              tripType={this.props.response.carBookingInfo.carDetail.tripType}
              closePopup={this.props.closePopup}
            />
          </View>
        </View>)
      );
    }

    const statusGradient = this.props.response.clientDetails.driverStatusText === 'DELAYED' ? orangeGradient : greenGradient;
    const otp = this.props.response.clientDetails.isBoarded ?
      this.props.response.carAdditionalInfo.additionalLOBInfo.driverDetailsInfo.otp : null;
    const otpText = !_.isEmpty(otp) ? otp : 'Not Required';

    return (
      <View style={styles.card}>
        <View style={[styles.cardPrimaryDetails]}>
          <Image source={driverIcon} style={styles.cardIcon} />
          <View style={styles.content}>

            <View style={[styles.header, AtomicCss.marginBottom15]}>
              <Text style={[styles.cardTitle, AtomicCss.marginBottom0]}>{this.props.response.clientDetails.driverCardHeading}</Text>
              {this.props.response.clientDetails.isBoarded && <Text style={styles.carOtp}>{`OTP ${otpText}`}</Text>}
              {!this.props.response.clientDetails.isBoarded ?
                <LinearGradient
                  colors={statusGradient}
                  start={{x: 0.0, y: 0.0}}
                  end={{x: 1.0, y: 0.0}}
                  style={styles.cabStatusGradient}
                >
                  <Text style={styles.cabStatusText}>{this.props.response.clientDetails.driverStatusText}</Text>
                </LinearGradient> : null
                }
            </View>
            <View style={styles.body}>
              {this.props.response.clientDetails.isBoarded ?
                    this.renderBoardedCabDetails() :
                    <Text style={styles.cabDesc}>{this.props.response.clientDetails.driverDelayText}</Text>
                }
            </View>

          </View>
        </View>

        {this.checkDriverCallCard() && <View style={styles.btnSingle}>
          <CapsuleWhiteBtn
            onPress={this.handleCallDriver}
            WhiteBtnTxt="CALL DRIVER"
          />
        </View>}

        {this.checkDriverChatCard() && <View style={[styles.btnSingle,AtomicCss.marginTop10]}>
          <CapsuleWhiteBtn
              onPress={this.handleChatDriver}
              WhiteBtnTxt="CHAT WITH DRIVER"
          />
        </View>}


      </View>
    );
  }

  handleCallDriver = () => {
    callCabdriver(this.props.response);
  }

  handleChatDriver = () => {
    chatCabdriver(this.props.response);
  }

  showAarogyaBanner = () => {
      return !_.isEmpty(this.props.response) &&
          !_.isEmpty(this.props.response.carAdditionalInfo) &&
          !_.isEmpty(this.props.response.carAdditionalInfo.additionalLOBInfo) &&
          !_.isEmpty(this.props.response.carAdditionalInfo.additionalLOBInfo.driverDetailsInfo) &&
          !_.isEmpty(this.props.response.carAdditionalInfo.additionalLOBInfo.driverDetailsInfo.aarogyaSetuHealthStatus) &&
          (this.props.response.carAdditionalInfo.additionalLOBInfo.driverDetailsInfo.aarogyaSetuHealthStatus.toUpperCase() === 'SAFE') &&
          !_.isEmpty(this.props.response.carAdditionalInfo.additionalLOBInfo.driverDetailsInfo.aarogyaSetuMessage);
  }


  renderBoardedCabDetails = () => {
    const driverRating = !_.isEmpty(this.props.response.carAdditionalInfo.additionalLOBInfo.driverDetailsInfo.driverRating) ?
      this.props.response.carAdditionalInfo.additionalLOBInfo.driverDetailsInfo.driverRating : CabBookingDetailsConstant.DRIVER_DEFAULT_RATING;
    const driverPhoto = this.props.response.carAdditionalInfo.additionalLOBInfo.driverDetailsInfo.driverPhotoUrl;
    const defaultDriverPhoto = require('@mmt/legacy-assets/src/Images/CabDetails/driverphoto.webp');
    const carModel = this.props.response.carAdditionalInfo.additionalLOBInfo.driverDetailsInfo.carModel;
    const aarogyaIcon = require('@mmt/legacy-assets/src/Images/CabDetails/green_circular.webp');

    return (
      <React.Fragment>
        <View style={styles.boardingDetails}>
          <View style={styles.carDetails}>
            {!_.isEmpty(carModel) && <Text style={styles.carName}>{this.props.response.carAdditionalInfo.additionalLOBInfo.driverDetailsInfo.carModel}</Text>}
            <Text style={styles.carNumber}>{this.props.response.carAdditionalInfo.additionalLOBInfo.driverDetailsInfo.carNo}</Text>
          </View>
          <View style={styles.driverDetails}>
            <View style={styles.driverNameWrapper}>
              <Text style={styles.driverName}>{this.props.response.carAdditionalInfo.additionalLOBInfo.driverDetailsInfo.driverName}</Text>
              <LinearGradient
                colors={greenGradient}
                start={{x: 0.0, y: 0.0}}
                end={{x: 1.0, y: 0.0}}
                style={styles.ratingGradient}
              >
                <Text style={styles.driverRatingText}>
                  {driverRating + CabBookingDetailsConstant.DRIVER_RATING_DELIMITER}
                  <Text style={styles.totalRatingText}>{CabBookingDetailsConstant.DRIVER_MAX_RATING}</Text>
                </Text>
              </LinearGradient>
            </View>
            <View style={styles.driverThumbWrapper}>
              <Image
                source={_.isEmpty(driverPhoto) ? defaultDriverPhoto : {uri: driverPhoto}}
                style={styles.driverThumb}
              />
            </View>
          </View>
        </View>

        {this.showAarogyaBanner() &&
        <View style={styles.coronaWrapper}>
          <Image
            source={aarogyaIcon}
            style={styles.aarogyaSetuIconStyle}
          />
          <Text styles={[AtomicCss.flex1, AtomicCss.defaultText, AtomicCss.font12, AtomicCss.regularFont]}>
            {this.props.response.carAdditionalInfo.additionalLOBInfo.driverDetailsInfo.aarogyaSetuMessage}
          </Text>
        </View>
      }
      </React.Fragment>
    );
  };

  checkDriverCallCard = () => (this.props.response.clientDetails.isBoarded) && (this.props.response.liveTrackingEnabled)
  checkDriverChatCard = () => (this.props.response.clientDetails.driverChatEnabled)
}

CabAndDriverDetailsCard.propTypes = {
  response: PropTypes.object.isRequired,
  closePopup: PropTypes.func.isRequired
};

export default CabAndDriverDetailsCard;
