import React from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import styles from './NeedMoreHelpCardCss';

const NeedMoreHelpCard = ({header, subHeader, onClick}) => {
  
  const secureIcon = require('@mmt/legacy-assets/src/secureIcon.webp');
  const rightArrow = require('@mmt/legacy-assets/src/blueRightArrow.webp');

  return (
    <>
      <TouchableOpacity onPress={() => {onClick()}}>
      <View style={[styles.CardContent, AtomicCss.marginBottom8]}>
        <View style={styles.CardContentInner}>
          <View style={[AtomicCss.flex1, AtomicCss.flexRow]}>
            <Image style={styles.CardIcon} source={secureIcon} />
            <View style={[AtomicCss.flex1]}>
                <Text style={[AtomicCss.blackFont, AtomicCss.blackText, AtomicCss.font14, AtomicCss.marginBottom6]}>
                  {header}
                </Text>
                <Text style={[AtomicCss.boldFont, AtomicCss.safeBlueColor, AtomicCss.font14]}>
                  {subHeader}
                </Text>
            </View>
          </View>
          <Image style={styles.rightArrowIcon} source={rightArrow} />
        </View>
      </View>
      </TouchableOpacity>
    </>
  );
}

export default NeedMoreHelpCard;