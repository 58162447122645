import React from 'react';
import { View, Text } from 'react-native';
import isEmpty from 'lodash/isEmpty';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getStaticData } from '../../../../../../staticData/staticData';
import { getFont } from '../../../../../../PostSalesStyles';
import { useTheme } from '../../../../../../theme/theme.context';
import createStyles from './stylesCss';

interface PaxClaimDetailsProps {
  paxInsuranceClaimList?: PaxInsuranceClaim[];
}

interface PaxInsuranceClaim {
  paxName?: string;
  claimStatus?: string;
  statusReason?: string;
}

const PaxClaimDetails: React.FC<PaxClaimDetailsProps> = ({ paxInsuranceClaimList }) => {
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme);
  const { travellersText, claimRequestStatusTxt } = getStaticData();

  return (
    <React.Fragment>
      <View style={[styles.tvlrInfoWrapper]}>
        <View style={styles.topSection}>
          <Text
            style={[
              styles.headingTxt,
              fonts.boldFontFamily,
              fsStyle.font11,
              styles.trvlrNameSectionWidth,
            ]}
          >
            {travellersText}
          </Text>
          <Text
            style={[
              styles.headingTxt,
              fonts.boldFontFamily,
              fsStyle.font11,
              styles.claimStatus,
            ]}
          >
            {claimRequestStatusTxt}
          </Text>
        </View>

        {paxInsuranceClaimList?.map((item, index) => (
          <View key={index} style={[AtomicCss.flexColumn, AtomicCss.marginBottom15]}>
            <View style={styles.paxInfoWrapper}>
              <View style={[styles.paxInfoColumnWrapper, styles.trvlrNameSectionWidth]}>
                {!isEmpty(item?.paxName) && (
                  <Text
                    style={[
                      fonts.boldFontFamily,
                      fsStyle.font12,
                      styles.psngItemTxt
                    ]}
                  >
                    {item.paxName}
                  </Text>
                )}
              </View>
              {!isEmpty(item?.claimStatus) && (
                <View style={[styles.paxInfoColumnWrapper, styles.claimStatus]}>
                  <Text
                    style={[
                      fsStyle.font12,
                      fonts.boldFontFamily,
                      styles.psngItemTxt,
                      { textAlign: 'right' }
                    ]}
                  >
                    {item.claimStatus}
                  </Text>
                </View>
              )}
            </View>
            {!isEmpty(item?.statusReason) && 
              <View style={[styles.infoMessageBox, styles.wcBorderStyle]}>
                <Text style={[fsStyle.font10, [styles.wcTextStyle]]}>{item.statusReason}</Text>
              </View>
            }
          </View>
        ))}
      </View>
    </React.Fragment>
  );
};

export default PaxClaimDetails;