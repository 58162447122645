// @flow
import React from 'react';
import isEmpty from 'lodash/isEmpty';
import {
  ActionsContent,
  CoachInfoText,
  DescriptionSection,
  MainContent,
  NewStatusText,
  RightArrow,
  StaticIcon,
  SummaryContainer,
  TitleText,
  NeedHelpStrip
} from '../cardElements';
import getVisaSummaryData from './visaCardAdapter';

function VisaSummaryCard({ response, msrTicket }) {
  const cardData = getVisaSummaryData(response, msrTicket);
  const { needHelpData } = cardData;
  return (
    <SummaryContainer bookingId={response.bookingID}>
      <MainContent onPress={cardData.openDetails}>
        <StaticIcon icon={cardData.iconUrl} iconStyle={{ width: 23, height: 20 }} />
        <DescriptionSection>
          <TitleText text={cardData.countryName} />
          <CoachInfoText text={cardData.description} />
          <NewStatusText status={cardData.status} text={cardData.paxInfo} />
        </DescriptionSection>
        <RightArrow />
      </MainContent>
      {!isEmpty(needHelpData) &&  needHelpData.map((needHelp)=><NeedHelpStrip {...needHelp}/>)}
      <ActionsContent actions={cardData.actions} />
    </SummaryContainer>
  );
}

export default VisaSummaryCard;
