import { Platform, NativeModules } from 'react-native'
import cookie from 'react-cookies';
import { PWA_COOKIE_NAME, SUPPORTZ_COOKIE_DOMAIN } from "./../networkConstants";
import { REQUEST_METHOD } from "./../constants";
import { getRequestHeader } from 'web/WebModules/NetworkModule';
import { setCookie, getCookie, itemType } from "MMT-UI/storages/client/cookieStorage";

export async function getCommonHeaders(auth, loggingInfo, { psLob, langAware = true } = {}) {
    const {NetworkModule} = NativeModules;
    await clearCookieByName(PWA_COOKIE_NAME, SUPPORTZ_COOKIE_DOMAIN);
    const headers = await NetworkModule.getHeaders();
    headers['mmt-auth'] = auth;
    headers['Content-Type'] = 'application/json';
    headers['logging-trackinfo'] = loggingInfo;
    //device-id was required at MIMA for Pokus driven experiment
    headers.deviceid = Platform.OS.toUpperCase();
    headers.psLob = psLob;
    headers.langAware = langAware;
    return headers;
}

export async function clearCookieByName(cookieName, cookieDomain) {
    cookie.remove(cookieName, {
        path: '/',
        domain: cookieDomain
    });
}

export function setCookieByName(
    name,
    value,
    domain,
    type = itemType.ES
  ) {
    let options = { isPiiData : false};
    if (domain) {
      options.domain = domain;
    }
    options.path = '/';
    setCookie(name, value, options, type);
  }

  export function getCookieByName(name) {
    return getCookie(name, { isPiiData: false}, itemType.ES);
  }

export async function httpGet(url,queryString,headers = {},sourceInfoParams) {
    const {bookingId, uniqueIDType,act} = sourceInfoParams || {};
    const userInfo = await getRequestHeader(bookingId,uniqueIDType,act) || {};
    if(queryString)
        url = addQueryParamsToUrl(url, queryString);
    const commonHeaders = await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo);
   const response = await window.fetch(url, {
        method: REQUEST_METHOD.GET,
        headers: {...commonHeaders,...headers},
    })
    return response.json();
}

const addQueryParamsToUrl = (url, params) => {
    const urlObj = new URL(url);
    Object.keys(params).forEach(key => urlObj.searchParams.append(key, params[key]));
    return urlObj.toString();
}

export async function httpPost(url, data,headers={},sourceInfoParams) {
    const { bookingId, uniqueIDType,act } = sourceInfoParams || {};
    const userInfo = await getRequestHeader(bookingId,uniqueIDType,act);
    const commonHeaders = await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo);
    const updatedHeaders = {...commonHeaders,...headers}

    const response = await window.fetch(url, {
        method: REQUEST_METHOD.POST,
        headers: {...updatedHeaders},
        body: JSON.stringify(data)
    })
    return response.json();
}
