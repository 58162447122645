import { StyleSheet } from "react-native";
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
export default (theme, fonts) =>StyleSheet.create({
    cardBorder:{
        borderTopColor:theme.color.yello,
        borderTopWidth:4,
        ...getPlatformElevation(2), 
        marginTop:12,
        marginBottom:12,
      },
      infoCardWrapper:{
        backgroundColor:theme.color.white,
        justifyContent:'space-between',
        flexDirection:'row',
        borderBottomWidth:1,
        borderBottomColor:theme.color.lightGrey3,
        ...getPlatformElevation(1), 
      },
    
      infoCardContent:{
          paddingHorizontal:6,
          paddingVertical:15,
          flex:1
      },
    
      headingIconWrapper:{
        width:46,
        alignItems:'flex-start',
        top:14,
        left:16,
      },

      headingIconStyle:{
        width:25,
        height:25
      }
      
});