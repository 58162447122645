import React, { useEffect, useRef } from 'react';
import { StyleSheet, Platform } from 'react-native';
import LottieView from 'lottie-react-native';
import pulseIconJson from '@mmt/legacy-assets/src/LottieAnimations/PulsatingRedDot/data.json';

const PulseIconAnim = () => {
  const animationVar = useRef(null);

  useEffect(() => {
    animationVar.current.play();
  }, []);
  if (Platform.OS === 'web') {
    return <LottieView ref={animationVar} style={styles.iconStyle} source={pulseIconJson} loop={true} />;
  }
  return <LottieView ref={animationVar} style={styles.iconStyle} source={pulseIconJson} />;
};

export default PulseIconAnim;

const styles = StyleSheet.create({
  iconStyle: {
    width: 22,
    height: 22,
  },
});
