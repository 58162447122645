import React, { useState, useEffect } from 'react';
import { View, Text, Image, TouchableOpacity, Platform } from 'react-native';
import styles from './HelpingHandOverlayCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import rtArrow from '@mmt/legacy-assets/src/blueRightArrow.webp';
import callImg from '@mmt/legacy-assets/src/callIconBlue.webp';
import chatIconBlue from '@mmt/legacy-assets/src/chatIconBlue.webp';
import { NEED_MORE_HELP, ViewState } from '../../../../PostSalesConstant';
import { renderProgressView, renderErrorView } from '../../NeedMoreHelpUtils';
import { HELPING_HAND_URL } from '../../../../utils/NetworkUtils';
import { usePostRequestApi } from '../../../PostRequestApi';
import agentIcon from '@mmt/legacy-assets/src/agentIcon.webp';
import CommonOverlayMessage from '../../../CommonOverlayMessage';

const HelpingHandOverlay = ({
  bookingId,
  pageName,
  setConfig,
  handleBottomOverlay,
  hideLoader,
  overlayOutsideClick,
  bottom,
  trackEvent,
  addPadding = false,
  lobName,
}) => {
  let trackPage = 'helpinghand';
  const [retry, setRetry] = useState(0);

  const request = {
    bookingId,
    pageName,
    lobName,
  };

  const [viewState, response] = usePostRequestApi(
    HELPING_HAND_URL,
    request,
    bookingId,
    'HEPLING_HAND',
    retry,
  );

  useEffect(() => {
    if (response && response.layoutData) {
      trackPage = updatePageName(trackPage, response.layoutData);
      trackEvent && trackEvent('load', trackPage, null, response);
    }
  }, [response]);

  const handleRetry = () => {
    setRetry(retry + 1);
  };

  const updatePageName = (page, data) => {
    if (data && data.cards && data.cards.length) {
      data.cards.forEach((card) => {
        page = appendPageType(page, card.callBackType);
      });
    }
    return page;
  };

  const appendPageType = (page, type) => {
    switch (type) {
      case '0':
        return page + '_callback';
      case '1':
        return page + '_callbacklater';
      case 'connect_helpdesk':
        return page + '_raise';
      case 'call_us':
        return page + '_callus';
      default:
        return page;
    }
  };

  if (viewState === ViewState.LOADING) {
    return hideLoader ? null : renderProgressView();
  }

  if (viewState === ViewState.ERROR) {
    return hideLoader ? null : renderErrorView(handleRetry);
  }

  const getItemIcon = (type) => {
    switch (type) {
      case '0':
        return { icon: callImg, type: NEED_MORE_HELP.REQUEST_WAITING, clickEvent: 'help_callback' };
      case '1':
        return {
          icon: callImg,
          type: NEED_MORE_HELP.REQUEST_SCHEDULE,
          clickEvent: 'help_callbacklater',
        };
      case 'connect_helpdesk':
        return {
          icon: chatIconBlue,
          type: NEED_MORE_HELP.CONNECT_HELPDESK,
          clickEvent: 'help_raise',
        };
      case 'call_us':
        return { icon: callImg, type: NEED_MORE_HELP.CALL_US, clickEvent: 'help_callus' };
      default:
        return { icon: callImg };
    }
  };

  const actionHandler = (type) => {
    switch (type) {
      case 'dismiss':
        trackEvent && trackEvent('click', trackPage, 'help_dismiss', response);
        overlayOutsideClick && overlayOutsideClick();
        break;
      default:
        break;
    }
  };

  const renderContent = () => (
    <View style={styles.OverlayContentWrapper}>
      <View style={AtomicCss.marginBottom16}>
        <View style={styles.overlayTopline} />
      </View>
      {response.layoutData.headerText && (
        <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween]}>
          <Text style={[styles.title, AtomicCss.flex1]}>{response.layoutData.headerText}</Text>
          <Image source={agentIcon} style={styles.myraIconStyle} />
        </View>
      )}

      {response.layoutData.subHeaderText && (
        <React.Fragment>
          <Text
            style={[
              AtomicCss.font14,
              AtomicCss.regularFont,
              AtomicCss.defaultText,
              AtomicCss.marginBottom12,
            ]}
          >
            {response.layoutData.subHeaderText}
          </Text>
          <View style={[styles.blueLineStyle, AtomicCss.marginBottom15]} />
        </React.Fragment>
      )}

      {response.layoutData.cards &&
        response.layoutData.cards.length > 0 &&
        response.layoutData.cards.map((card, index) => {
          const { icon, type, clickEvent } = getItemIcon(card.callBackType);
          return (
            <TouchableOpacity
              activeOpacity={0.7}
              style={[styles.connectHlpCard, AtomicCss.marginBottom10]}
              onPress={() => {
                trackEvent && trackEvent('click', trackPage, clickEvent, response);
                handleBottomOverlay(type, card);
              }}
            >
              <Image style={styles.iconStyle} source={icon} />
              <View style={AtomicCss.flex1}>
                <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween]}>
                  <Text style={styles.helpCardTitle}>{card.header}</Text>
                  <Image style={styles.rtArrow} source={rtArrow} />
                </View>
                <Text style={[AtomicCss.font14, AtomicCss.greyText, AtomicCss.regularFont]}>
                  {card.subHeader}
                </Text>
              </View>
            </TouchableOpacity>
          );
        })}
      {response.layoutData && response.layoutData.action && (
        <TouchableOpacity onPress={() => actionHandler(response.layoutData.action.type)}>
          <Text
            style={[
              AtomicCss.blueText,
              AtomicCss.marginTop10,
              AtomicCss.marginBottom15,
              AtomicCss.boldFont,
              AtomicCss.font16,
            ]}
          >
            {response.layoutData.action.title}
          </Text>
        </TouchableOpacity>
      )}
    </View>
  );

  if (viewState === ViewState.LOADED && response && response.layoutData) {
    setConfig(response.configId);
    if (hideLoader) {
      return (
        <OverlayWrapper
          content={renderContent()}
          overlayOutsideClick={overlayOutsideClick}
          bottom={bottom}
          addPadding={addPadding}
        />
      );
    } else {
      return renderContent();
    }
  }
  return null;
};

export const OverlayWrapper = ({ content, overlayOutsideClick, bottom, addPadding }) => {
  const isMWeb = Platform.OS === 'web'
  return (
    <CommonOverlayMessage
      overlayWrapperStyle={[styles.overlayWrapper, isMWeb ? AtomicCss.paddingBottom0 : addPadding ? AtomicCss.paddingBottom100 : null]}
      overlayContentStyle={[styles.overlayContent, { bottom }]}
      handleBottomOverlay={overlayOutsideClick}
      content={content}
    />
  );
};

export default HelpingHandOverlay;
