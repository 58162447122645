import React, { useEffect } from 'react';
import { View, Text, Image, StyleSheet, Dimensions, TouchableOpacity, Platform } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import { RED_CROSS_ICON } from '../../HotelImageConstants';
import Button from '../../../Common/Button';
import { getStaticData } from '../../../staticData/staticData';
import { isIntlHotelBooking } from '../../details/utils/HotelBookingDetailUtil';
import HotelBookingTrackingHelper from '../../HotelBookingTrackingHelper';
import Actions from '../../../navigation/postSalesNavigation';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const windowHeight = (Platform.OS === 'android' ||  Platform.OS === 'web') ? Dimensions.get('window').height : Dimensions.get('screen').height;

const CancellationError = ({ errorDesc, hotelName, showHelpingHand, helpingHandClickHandler, backToBookingHandler, onRetry, showRetry = true, showMytrip, showBackToBookingCTA = true, heading = '', theme = '', hotelBookingResponse = {}, cancellationCommitResponse = {} }) => {
    const { fsStyle, ...fonts } = getFont(true);
    const styles = getStyles(fonts);
    useEffect(() => {
        const { response } = cancellationCommitResponse || {};
        const { errorText } = response || {};
        const trackingPageName = isIntlHotelBooking(hotelBookingResponse?.lobCode)
         ? 'Mob:customer support:Hotel International:FullCancel:NonPromiseError'
         : 'Mob:customer support:Hotel Domestic:FullCancel:NonPromiseError';
        HotelBookingTrackingHelper.trackErrorWithData(
            trackingPageName, hotelBookingResponse.bookingID, errorText, hotelBookingResponse
        );
    }, []);

    const staticData = getStaticData();
    const { cancellationText: {additionalText}, tryAgainText, myTripsText, backToBookingText, contactUsText } = staticData;
    return (
        <View style={styles.OverlayContentWrapper}>
            <View style={styles.topCircle}>
                <View style={styles.loaderWrapper}>
                    <Image source={RED_CROSS_ICON} style={styles.redCrossIcon} />
                </View>
            </View>
            <View style={AtomicCss.alignCenter}>
                <Text style={styles.titleHeading}>{heading}</Text>
                <Text style={styles.titleSubHeading}>{errorDesc? errorDesc : hotelName}</Text>
            </View>
            <View style={styles.bottomWrapper}>
                {showRetry && <Button 
                    btnBg="gradientBtn" 
                    btnType="flat"
                    fontSize={fsStyle.font16}
                    btnTxt={tryAgainText}
                    clickHandler={onRetry}
                    theme={theme}
                />}
                {showMytrip &&
                <View style={[AtomicCss.marginTop10, AtomicCss.marginBottom10]}>
                    <Button 
                        btnBg="gradientBtn" 
                        btnType="flat"
                        fontSize={fsStyle.font16}
                        btnTxt={myTripsText}
                        clickHandler={Actions.exit}
                        theme={theme}
                    />
                </View>
                }
                {(showBackToBookingCTA || showHelpingHand) && <View style={[AtomicCss.flexRow, showHelpingHand ? AtomicCss.spaceBetween : AtomicCss.justifyCenter, AtomicCss.marginTop30, AtomicCss.flex1, AtomicCss.marginBottom12]}>
                    {showBackToBookingCTA && <TouchableOpacity onPress={() => backToBookingHandler(false)}>
                        <Text style={[fsStyle.font16, AtomicCss.azure, fonts.blackFontFamily, AtomicCss.lineHeight18]}>{backToBookingText}</Text>
                    </TouchableOpacity>}
                    {showHelpingHand && <TouchableOpacity onPress={() => helpingHandClickHandler(true)}>
                        <Text style={[fsStyle.font16, AtomicCss.azure, fonts.blackFontFamily, AtomicCss.lineHeight18]}>{contactUsText.toUpperCase()}</Text>
                    </TouchableOpacity>}
                </View>}
            </View>
        </View>
    )
};

const getStyles = (fonts) => {
    return StyleSheet.create({
        OverlayContentWrapper: {
           height: windowHeight,
        },
        topCircle: {
            width: 300,
            height: 330,
            borderRadius: 280,
            backgroundColor: colors.lightPink,
            alignSelf: 'center',
            position: 'relative',
            marginTop: -100,
            transform: [{ scaleX: 2 }],
            marginBottom: 40
        },
        loaderWrapper: {
            marginTop: 200,
            alignItems: 'center',
            transform: [{ scaleX: .5 }],
        },
        titleHeading: {
            ...fonts.font20,
            fontFamily: fonts.black,
            color: colors.red,
            marginTop: 25,
            marginBottom: 4,
            lineHeight: 28,
            textAlign: 'center'
          },
        titleSubHeading: {
            ...fonts.font14,
            fontFamily: fonts.bold,
            color: colors.defaultTextColor,
            marginHorizontal:40,
            lineHeight: 28
        },
        bottomWrapper: {
            position: 'absolute',
            zIndex: 4,
            bottom: 0,
            width: '100%',
            padding: Platform.OS === 'web' ? 20 : 0,
        },
        redCrossIcon: {
            width: 76,
            height: 76
        }
    });
}

export default React.memo(CancellationError);