import React, { useState, useRef } from 'react';
import { Text, TouchableOpacity, View, FlatList } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import createStyles from './calendarStripCss';

import { getFont } from '../../PostSalesStyles';
import { useTheme } from '../../theme/theme.context';

const Item = ({
  date,
  dayName,
  index,
  onSelectDate,
  calendarDataList,
  monthName,
  isSelected,
}) => {
  const activeWrapper = isSelected ? 'activeBtn' : '';
  const { fsStyle, ...fonts } = getFont();
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme);
  const activeText = isSelected ? [fonts.boldFontFamily, AtomicCss.whiteText] : '';

  const checkMonth = () => {
    if (index >= 1 && calendarDataList[index - 1].month !== monthName) {
      return (
        <LinearGradient
          start={{ x: 1.0, y: 0.0 }}
          end={{ x: 0.0, y: 1.0 }}
          colors={['#525252', '#171717']}
          style={[styles.monthWrapper, styles.marginTop11]}
        >
          <Text
            style={[styles.monthTextBox, fsStyle.font10, fonts.boldFontFamily, styles.upperText]}
          >
            {monthName}
          </Text>
        </LinearGradient>
      );
    }
  };
  return (
    <>
      {checkMonth()}
      <TouchableOpacity
        style={[styles.dateBtnWrapper, styles[activeWrapper]]}
        onPress={() => onSelectDate(date)}
      >
        <Text
          style={[fsStyle.font11, fonts.blackFontFamily, AtomicCss.defaultText, activeText]}
        >{dayName}</Text>
      </TouchableOpacity>
    </>
  );
};

const CalendarStrip = ({ calendarDataList, onDateSelect }) => {
  const [monthLabel, setMonthLabel] = useState(calendarDataList[0].month);
  const [selectedDate, setSelectedDate] = useState();

  const { fsStyle, ...fonts } = getFont();
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme);

  const handleSelectedDate = (date) => {
    setSelectedDate(date);
    onDateSelect(date);
  };
  const handleViewableItemsChanged = ({ viewableItems }) => {
    if (viewableItems && viewableItems.length > 0 && viewableItems[0].item.month !== monthLabel) {
      const { index } = viewableItems[0];
      setMonthLabel(calendarDataList[index - 1].month);
    }
  };
  const viewabilityConfigCallbackPairs = useRef([
    {
      onViewableItemsChanged: handleViewableItemsChanged,
      viewabilityConfig: {
        minimumViewTime: 1,
        viewAreaCoveragePercentThreshold: 100,
      },
    },
  ]);
  const renderItem = ({ item, index }) => {
    const { day: dayName, formattedDate, month: monthName, isSelected } = item;
    return (
      <Item
        date={formattedDate}
        dayName={dayName}
        monthName={monthName}
        index={index}
        isSelected={formattedDate === selectedDate || !!isSelected}
        onSelectDate={handleSelectedDate}
        calendarDataList={calendarDataList}
      />
    );
  };
  return (
    <View
      style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.flex1, styles.calStripWrapper]}
    >
      <LinearGradient
        start={{ x: 1.0, y: 0.0 }}
        end={{ x: 0.0, y: 1.0 }}
        colors={['#525252', '#171717']}
        style={styles.monthWrapper}
      >
        <Text style={[styles.monthTextBox, fsStyle.font10, fonts.boldFontFamily, styles.upperText]}>
          {monthLabel}
        </Text>
      </LinearGradient>
      <View style={[styles.listWrapper, AtomicCss.flex1]}>
        <FlatList
          showsHorizontalScrollIndicator={false}
          horizontal
          data={calendarDataList}
          renderItem={renderItem}
          keyExtractor={(item) => item.formattedDate}
          viewabilityConfigCallbackPairs={viewabilityConfigCallbackPairs.current}
        />
      </View>
    </View>
  );
};

export default CalendarStrip;
