import { View, Image, Text } from 'react-native';
import React, { useEffect } from 'react';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import LinearGradient from 'react-native-linear-gradient';
import createStyles from './benefitsClaimedStyle';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import isEmpty from 'lodash/isEmpty';
import PostSalesBookingTrackingHelper from '../../../../PostSalesBookingTrackingHelper';
import RowCardContainer from '../../../../Common/Components/RowCardContainer';
import CardContainer from '../../../../Common/Components/CardContainer';

interface ILongStayBenefitsCardProps {
  benefitDetailsList: Array<IBenefitDetailsList>;
  heading: string;
  seperatorIconUrl: ISeperatorIconUrl,
  trackingPageName: string;
  showSeperatorIcon: boolean;
}
interface IBenefitDetailsList {
  description: string;
}

interface ISeperatorIconUrl {
  WEB : string;
}

const LongStayBenefitsCard = ({
  benefitDetailsList,
  heading,
  seperatorIconUrl,
  trackingPageName,
  showSeperatorIcon,
}: ILongStayBenefitsCardProps) => {
  useEffect(() => {
    PostSalesBookingTrackingHelper.trackShownEvent(
      trackingPageName,
      'LongStayBenefitsCard_shown',
    );
  }, [trackingPageName]);

  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme);
  const gradientColor = theme.color.longStayBenefitGradientColor;

  return (
    <CardContainer containerStyle={styles.benefitWrapper}>
      {showSeperatorIcon && !isEmpty(seperatorIconUrl?.WEB) && (
        <View style={styles.seperatorIcon}>
          <Image source={{uri : seperatorIconUrl.WEB}} style={styles.plusIcon} />
        </View>
      )}
      <LinearGradient
        colors={gradientColor}
        start={{ x: 1.0, y: 0.0 }}
        end={{ x: 0.0, y: 1.0 }}
        style={[styles.cardDetailsWrapper]}
      >
        <View style={styles.benefitWhiteBg}>
          <RowCardContainer>
            <View style={AtomicCss.flex1}>
              {!isEmpty(heading) && (
                <Text
                  style={[
                    {color: theme.color.purple4},
                    fsStyle.font18,
                    fonts.blackFontFamily,
                    AtomicCss.marginBottom12,
                    AtomicCss.marginTop12,
                  ]}
                >
                  {heading}
                </Text>
              )}
              {!isEmpty(benefitDetailsList) &&
                benefitDetailsList.map((item, index) => {
                  return (
                    <View key={index}>
                      {!isEmpty(item.description) && (
                        <View style={[AtomicCss.flexRow, AtomicCss.marginBottom5]}>
                          <View style={styles.bulletCircle} />
                          <Text
                            style={[
                              {color:theme.color.defaultTextColor},
                              fsStyle.font12,
                              AtomicCss.lineHeight16,
                              fonts.regularFontFamily,
                              AtomicCss.marginLeft12,
                            ]}
                          >
                            {item.description}
                          </Text>
                        </View>
                      )}
                    </View>
                  );
                })}
            </View>
          </RowCardContainer>
        </View>
      </LinearGradient>
    </CardContainer>
  );
};

export default LongStayBenefitsCard;
