import cookie from "react-cookies";
import * as CryptoJS from "crypto-js";
import {
  AGENT_ID,
  CCDE,
  DEEPLINK_DECRYPT_KEY,
  ENGLISH,
  FLIGHT_DOWNLOAD_TRIGGERS,
  LANGUAGE_COOKIE,
  LanguageOptions,
  MMT_AUTH,
  AFFILIATES,
  MY_BUSINESS_SUBSCRIPTION,
  EXTERNAL_PLATFORM,
  SUPPORTZ_INDIA,
  SUPPORTZ_GLOBAL
} from './const';
import { getCookie as gdprGetCookie, itemType } from 'MMT-UI/storages/client/cookieStorage';
import _ from "lodash";
import { getDataFromWebLocalStorage } from '@mmt/legacy-commons/AppState/LocalStorage';

let source = "pwa";
let cookies = null;

export const isSupportzIndiaDomain = () => {
  return !window || !window.location || window.location.origin === SUPPORTZ_INDIA;
}
export const isSupportzGlobalDomain = () => {
  return window && window.location && window.location.origin === SUPPORTZ_GLOBAL;
}

export const handleDomainRedirection = () => {
  const currentUrl = new URL(window.location.href);
  const updateDomain = (newDomain) => {
    if (currentUrl && currentUrl.hostname) {
      currentUrl.hostname = currentUrl.hostname.replace(/(\.com|\.global)$/, newDomain);
      window.location.href = currentUrl.toString();
    }
  };
  // Extract query parameters
  const params = Object.fromEntries(currentUrl.searchParams.entries());
  const myBusinessSubscriptionCookie = _gdprGetCookie('myBusinessSubscription');
  if (myBusinessSubscriptionCookie === 'myPartner' || myBusinessSubscriptionCookie === 'b2b') {
    if (isSupportzGlobalDomain()) {
      updateDomain('.com');
    }
    return;
  }
  if (params.region) {
    if (params.region === 'in' && isSupportzGlobalDomain()) {
      updateDomain('.com');
    } else if (isSupportzIndiaDomain() && (params.region === 'ae' || params.region === 'us') && window && window.localeSettings && window.localeSettings.isGlobalFlagEnabled) {
      updateDomain('.global');
    }
  } else {
    // const ccdeCookie = _getCCDECookie();
    // Redirect based on cookie
    // if (isSupportzIndiaDomain() && ccdeCookie && ccdeCookie !== 'in') {
    //   updateDomain('.global');
    //   return;
    // } uncomment once a2a is live
  }
}

export const _getLangCookie = () => {
  const lang = _gdprGetCookie(LANGUAGE_COOKIE);
  return lang;
};

export const _getExternalPlatformCookie = () => {
  const externalPlatform = _gdprGetCookie(EXTERNAL_PLATFORM);
  return externalPlatform;
};

export const _gdprGetCookie = (cookieName) => {
  return gdprGetCookie(cookieName, { isPiiData: true }, itemType.ES);
}

export const _getCCDECookie = () => {
  const ccde = _gdprGetCookie(CCDE);
  return ccde;
};

export const _isAffiliateUser = () => {
  for (let i = 0; i < Object.keys(AFFILIATES).length; i++) {
    if (window.location.origin.includes(Object.keys(AFFILIATES)[i])) {
      return true;
    }
  }
  return false;
};
export const _getAffiliateName = () => {
  return window.location.hostname.split(".")[0];
};

export const getAffiliateMmtAuth = () => {
  return _gdprGetCookie(`${MMT_AUTH}-${AFFILIATES[_getAffiliateName()]}`);
}

export const _getMmtAuth = () => {
  let mmtAuth;
  if (_isAffiliateUser()) {
    mmtAuth = getAffiliateMmtAuth();
  }
  else {
    mmtAuth = _gdprGetCookie(MMT_AUTH);
  }
  return mmtAuth;
};

export const _getAgentIdCookie = () => {
  const agentId = _gdprGetCookie(AGENT_ID);
  return agentId;
};

export const _getMyBusinessSubscriptionCookie = () => {
  const myBusinessSubscription = _gdprGetCookie(MY_BUSINESS_SUBSCRIPTION);
  return myBusinessSubscription;
};

export const isRTL = () => {
  const lang = _getLangCookie();
  const { dir } = LanguageOptions[lang] || LanguageOptions[ENGLISH];
  return dir === "rtl";
};

export const openURL = (url, target = "_self") => {
  window.open(url, target);
};

export const _getDownloadFileName = (val, bookingId) => {
  let documentType = "";
  switch (val) {
    case FLIGHT_DOWNLOAD_TRIGGERS.E_TICKET:
      documentType = "ETicket";
      break;
    case FLIGHT_DOWNLOAD_TRIGGERS.INVOICE:
      documentType = "Invoice";
      break;
    default:
      break;
  }
  return bookingId + "_" + documentType + ".pdf";
};

export const convertStringToBlob = (input, blobContentType) => {
  let byteCharacters = atob(input);
  let byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  let byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: blobContentType });
};

export const appendUrlPrefix = (path) => {
  const isProd = process.env.NODE_ENV === "production";
  const routePath = isProd ? "/mweb" + path : path;
  return routePath;
};
export const getRefreshURL = (params) => {
  let urlParams = new URLSearchParams(location.search);
  let url = location.pathname;
  let index = 0;
  urlParams.forEach((value, key) => {
    const val = params[key] ? params[key] : value;
    if (index == 0) {
      url += "?" + key + "=" + val;
    } else {
      url += "&" + key + "=" + val;
    }
    index++;
  });
  return url;
};

export const appendParams = (pathname, extraParams) => {
  let urlParams = new URLSearchParams(location.search);
  let url = pathname;
  let index = 0;
  Object.entries(extraParams).map(([key, value]) => {
    if (index == 0) {
      url += "?" + key + "=" + value;
    } else {
      url += "&" + key + "=" + value;
    }
    index++;
  });
  urlParams.forEach((value, key) => {
    url += "&" + key + "=" + value;
  });
  return url;
};

export const appendURLParams = (url, params, urlParams) => {
  const getBookingId = (params) => {
    return (
      params.BOOKING_ID ||
      params.bookingId ||
      params.bookingid ||
      (params.data && params.data.BOOKING_ID)
    );
  };
  let index = 0;
  urlParams.map((key) => {
    const val = key == "bookingId" ? getBookingId(params) : params[key];
    if (val) {
      if (index == 0) {
        url += "?" + key + "=" + val;
      } else {
        url += "&" + key + "=" + val;
      }
      index++;
    }
  });
  return url;
};

export const getCurrentRoutePath = () => location.pathname + location.search;

export const getRouteParams = (searchParams = []) => {
  const urlParams = new URLSearchParams(window.location.search);
  const params = {};
  if (searchParams && searchParams.length) {
    searchParams.forEach((arg) => {
      let decodedParams = urlParams.get(arg);
      if(decodedParams){
        decodedParams = decodedParams.replace(/\s/g, '+');
      }
      params[arg] = decodedParams;
    });
  }
  return params;
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const decryptDeeplinkParams = (val) => {
  try {
    const decryptKey = CryptoJS.enc.Base64.parse(DEEPLINK_DECRYPT_KEY);
    const decryptedData = CryptoJS.AES.decrypt(val, decryptKey, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    return decryptedData.toString(CryptoJS.enc.Utf8);
  } catch (err) {
    console.error(err);
    return val;
  }
};

export const isLoggedInUser = () => {
  const mmtAuth = _getMmtAuth();
  return !!mmtAuth;
};

export const setSource = (platform) => {
  source = platform;
};

export const getSource = () => {
  return source;
};

export function getCookie(name) {
  if (cookies) {
    return cookies[name];
  }
  const cookieArr = document.cookie.split("; ");
  cookies = {};
  for (let i = cookieArr.length - 1; i >= 0; i--) {
    const cookieStr = cookieArr[i].split("=");
    cookies[cookieStr[0]] = cookieStr[1];
  }
  return cookies[name];
}


export function getStaticData(lang) {
  const version = 21;
  const storageKey = `_PostSalesData_V${version}`
  const data = getDataFromWebLocalStorage(`${lang}${storageKey}`);
  return data;
}

export function isDesktop() {
    const userAgent = typeof window.navigator === "undefined" ? "" : navigator.userAgent;
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
    return !mobile
}