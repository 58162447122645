import React, { memo, useEffect, useState } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import { isEmptyArray } from '../../../../Common/commonUtil';
import { getCommonHeaders, REFUND_TRACKER_API } from '../../../../utils/NetworkUtils';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import { getFont } from '../../../../PostSalesStyles';
import isEmpty from 'lodash/isEmpty';

const PaymentStatusCard = ({ getReqNos, bookingId, data }) => {
  const { fsStyle, ...fonts}  = getFont(true);
  const [response, setResponse] = useState(null);

  const createPromise = async (bookingid, canRequest, headers) => {
    const refundTrackerUri = `${REFUND_TRACKER_API + bookingid}/${canRequest}`;
    return new Promise((resolve, reject) =>
      fetch(refundTrackerUri, {
        method: 'GET',
        headers,
      }).then((response) => resolve(response.json())),
    );
  };

  const refundTracker = async () => {
    const cancellationRequestList = [];
    const userInfo = await HotelBookingModule.getRequestHeader(
      bookingId,
      'RECENT_FAILED_REFUND_TRACKER',
    );
    const headers = await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo);
    for (let i = 0; i < getReqNos.length; i += 1) {
      cancellationRequestList.push(createPromise(bookingId, getReqNos[i], headers));
    }
    Promise.all(cancellationRequestList).then((result) => {
      if (result.length === 0 || isEmpty(result[0].refundStatusList)) {
        return;
      }
      setResponse(result);
    });
  };

  useEffect(() => {
    refundTracker();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isEmptyArray(response)) {
    return null;
  }

  return (
    <View style={styles.refundDetailWrapper}>
      {response.map((item) =>
        !isEmptyArray(item.refundStatusList)
          ? item.refundStatusList.map((i) => {
              const {
                refundState,
                refundStateText,
                refundModesSmryMessageList,
                refundModesMessageList,
              } = i || {};
              const msgIterable = !isEmptyArray(refundModesSmryMessageList)
                ? refundModesSmryMessageList
                : refundModesMessageList;
              if (data && refundStateText) {
                data['refundCommStatus'] = refundStateText;
              }
              return (
                <>
                  {!!refundStateText && (
                    <View style={[styles.refundDetail, styles[`refundDetail${refundState}`]]}>
                      <Text
                        style={[
                          styles.refundStatusTxt,
                          fonts.boldFontFamily,
                          styles[`refundStatusTxt${refundState}`],
                          fsStyle.font11
                        ]}
                      >
                        {refundStateText}
                      </Text>
                    </View>
                  )}
                  {!isEmptyArray(msgIterable)
                    ? msgIterable.map((info) => (
                        <Text style={[styles.refundCardTxt, fonts.boldFontFamily, fsStyle.font14]}>
                          {info.message}
                        </Text>
                      ))
                    : null}
                </>
              );
            })
          : null,
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  refundDetailWrapper: {
    borderTopColor: '#eee',
    borderTopWidth: 1,
    paddingTop: 16,
    marginLeft: 60,
    marginBottom: 15,
  },
  refundCardTxt: {
    color: colors.black,
    marginBottom: 4,
    paddingRight: 15,
  },
  refundDetail: {
    paddingHorizontal: 6,
    paddingVertical: 4,
    flexDirection: 'row',
    alignSelf: 'flex-start',
    borderRadius: 4,
    marginBottom: 4,
  },
  refundDetailINITIATED: {
    backgroundColor: colors.creamWhite,
  },
  refundDetailREFUNDED: {
    backgroundColor: colors.limeGreen,
  },
  refundDetailPROCESSED: {
    backgroundColor: colors.creamWhite,
  },
  refundStatusTxt: {
    textTransform: 'uppercase',
  },
  refundStatusTxtINITIATED: {
    color: colors.darkBrown,
  },
  refundStatusTxtREFUNDED: {
    color: colors.green,
  },
  refundStatusTxtPROCESSED: {
    color: colors.darkBrown,
  },
});

export default memo(PaymentStatusCard);
