import { StyleSheet } from "react-native";
import { DEFAULT_THEME } from "apps/post-sales/src/theme/default.theme";

export default <T extends typeof DEFAULT_THEME>(theme: T, fonts: any)=> StyleSheet.create({
    tabStyle: {
        borderWidth: 1,
        borderColor: theme.color.lightGray,
        borderRadius: 8,
        padding: 8,
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 8,
        flexDirection: 'row'

    },
    tabStyleTxt: {
        color: theme.color.defaultTextColor,
        ...fonts.regularFontFamily,
        fontSize: fonts.fontSize.font14,
    },
    tabActive: {
        borderColor: theme.color.azure,
        backgroundColor: theme.color.lighterBlue,

    },
    tabTxtActive: {
        color: theme.color.azure,
        ...fonts.blackFontFamily,
    },
    disabledTxt: {
        color: 'rgba(74,74,74,.3)'
    },
    firstElement: {
        marginLeft: 16,
    },
    downArrowStyle: {
        width: 16,
        height: 16,
        marginLeft: 5,
        top: 1,
        tintColor: theme.color.defaultTextColor
    },
    allFilterIconStyle: {
        width: 11,
        height: 10,
        marginLeft: 5,
        top: 2,
        tintColor: theme.color.defaultTextColor
    },
    iconActiveStyle: {
        tintColor: theme.color.azure
    },
    allFilterTabIconStyle: {
        width: 12,
        height: 12,
        marginLeft: 4,
    },
    downArrowIconStyle: {
        marginTop: 4,
        width: 20,
        height: 20,
    },

});