import React, { useEffect } from 'react';
import { View, Text, ScrollView, Image } from 'react-native';
import styles from './RefundProcessedCss';
import Button from '../../Button';
import { BG_SUCCESS_TICK_2_ICON } from '../../../flights/FlightImageConstants';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getStaticData } from '../../../staticData/staticData';
import PostSalesBookingTrackingHelper from '../../../PostSalesBookingTrackingHelper';
import { REFUND_OOT_TRACKING } from '../constants';
import useHardwareBackpress from "../../useHardwareBackpress";

const RefundProcessed = ({ onGoBack, data = {}, bookingId, pageName = '' }) => {
  const { backToBookingText } = getStaticData(false);

  useEffect(() => {
    PostSalesBookingTrackingHelper.trackLoadEvent(`${pageName}:${REFUND_OOT_TRACKING.PAGES.TY}`, '', bookingId);
  }, []);

  useHardwareBackpress().addListener(onGoBack);

  return (
    <>
      <ScrollView>
        <View style={styles.fullPage}>
          <View style={styles.topSection}>
            <Image style={styles.mainconStyle} source={BG_SUCCESS_TICK_2_ICON} />
            <Text style={styles.titleHeading}>{data.heading}</Text>
          </View>
          <View style={styles.detailSection}>
            <View style={[styles.detailCard]}>
              <View style={styles.detailsTop}>
                <Text
                  style={[
                    AtomicCss.blackText,
                    AtomicCss.blackFont,
                    AtomicCss.font14,
                    AtomicCss.lineHeight20,
                    AtomicCss.marginBottom6,
                  ]}
                >
                  {data.subHeading}
                </Text>
                <Text
                  style={[
                    AtomicCss.defaultText,
                    AtomicCss.regularFont,
                    AtomicCss.font14,
                    AtomicCss.lineHeight20,
                  ]}
                >
                  {data.textBelowSubHead}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
      <View style={[styles.buttonBottomWrapper]}>
        {onGoBack && (
          <Button
            clickHandler={onGoBack}
            btnWidth={'98%'}
            btnBg="gradientBtn"
            btnType="flat"
            fontSize="font16"
            btnTxt={backToBookingText}
          />
        )}
      </View>
    </>
  );
};

export default RefundProcessed;
