import {
  getFlightItemListMap,
  getListingHeader,
  getBookingType,
  getFareComponent,
  getFilterInfo,
} from '../../data/dataAdapter';
import { filterListing, getActiveFilters} from 'core-ui-lib/flight/common/ODC/listingHelper';
import { getCalendarDataList } from '../../utils';
import FlightListingTrackingHelper from '../../analytics/FlightListingTrackingHelper';
import isEmpty from 'lodash/isEmpty';
import { trackError, trackPageLoad, trackClickEventListingPopup } from './utils';
import { getListingAPI } from '../../data/api';
import { getStaticData } from '../../../../../staticData/staticData';

export const ACTION_LOADING_FLIGHTS = 'ACTION_LOADING_FLIGHTS';
export const ACTION_SHOW_DETAIL = 'ACTION_SHOW_DETAIL';
export const ACTION_SELECT_ITEM = 'ACTION_SELECT_ITEM';
export const ACTION_ERROR = 'ACTION_ERROR';
export const ACTION_ERROR_NEW = 'ACTION_ERROR_NEW';
export const ACTION_RENDER_LIST = 'ACTION_RENDER_LIST';
export const ACTION_REFRESH = 'ACTION_REFRESH';



export const fetchSearchData = (
  searchKey,
  segmentGroup,
  bookingId,
  corporateBooking,
  isMyPartnerBooking,
  isCfarBooking,
) => async (dispatch) => {
  dispatch(setLoadingAction());
  try {
    const response = await getListingAPI(bookingId, segmentGroup, isCfarBooking);
    if (!response.ok) {
      trackError('No_Respone', bookingId, undefined);
      dispatch(setErrorAction(searchKey, ''));
      return null;
    }
    const responseBody = await response.json();
    if (!responseBody) {
      trackError('Search_Results_Failure', bookingId, undefined);
      dispatch(setErrorAction(searchKey, ''));
      return null;
    } else if (responseBody.errorPageData) {
      let errorStatus = 'NO_STATUS_IN_RESPONSE';
      if (
        responseBody.flightSearchInfoClientResponse &&
        responseBody.flightSearchInfoClientResponse.flightSearchInfoResponseBody &&
        !isEmpty(responseBody.flightSearchInfoClientResponse.flightSearchInfoResponseBody.error)
      ) {
        const error =
          responseBody.flightSearchInfoClientResponse.flightSearchInfoResponseBody.error[0];
        errorStatus = `${error.ec}_${error.em}_${error.ed}`;
      }
      else if (responseBody.errorPageData?.errorCode){
        errorStatus = responseBody.errorPageData?.errorCode
      }
      trackError(
        errorStatus,
        bookingId,
        !isEmpty(responseBody.errorPageData) &&
        !isEmpty(responseBody.errorPageData.meta) &&
        responseBody.errorPageData.meta.supplier,
      );
      dispatch(
        setNewErrorAction(
          searchKey,
          responseBody.errorPageData.errorCode,
          responseBody.errorPageData,
          responseBody
        ),
      );
      return null;
    } else if (responseBody.status === 'COMPLETED') {
      trackPageLoad(responseBody, bookingId);
      dispatch(listingSuccess(responseBody, responseBody.sessionId, responseBody.cfarInfo, responseBody.odcImportantInfo));
      return responseBody;
    } else if (
      responseBody.flightSearchInfoClientResponse &&
      responseBody.flightSearchInfoClientResponse.flightSearchInfoResponseBody &&
      responseBody.flightSearchInfoClientResponse.flightSearchInfoResponseBody.status ===
      'COMPLETED'
    ) {
      trackPageLoad(
        responseBody.flightSearchInfoClientResponse.flightSearchInfoResponseBody,
        bookingId,
      );
      responseBody.flightSearchInfoClientResponse.flightSearchInfoResponseBody.custCurrency =
        responseBody.custCurrency;
      dispatch(
        listingSuccess(
          responseBody.flightSearchInfoClientResponse.flightSearchInfoResponseBody,
          responseBody.flightSearchInfoClientResponse.sessionId,
          responseBody.cfarInfo,
          responseBody.odcImportantInfo
        ),
      );
      return responseBody.flightSearchInfoClientResponse.flightSearchInfoResponseBody;
    } else if (
      responseBody.flightSearchInfoClientResponse &&
      responseBody.flightSearchInfoClientResponse.flightSearchInfoResponseBody &&
      responseBody.flightSearchInfoClientResponse.flightSearchInfoResponseBody.status === 'FAILED'
    ) {
      let errorStatus = 'STATUS_FAILED';
      let errorCode = '';
      if (
        responseBody.flightSearchInfoClientResponse &&
        responseBody.flightSearchInfoClientResponse.flightSearchInfoResponseBody &&
        responseBody.flightSearchInfoClientResponse.flightSearchInfoResponseBody.error != null &&
        responseBody.flightSearchInfoClientResponse.flightSearchInfoResponseBody.error.length > 0
      ) {
        const error =
          responseBody.flightSearchInfoClientResponse.flightSearchInfoResponseBody.error[0];
        errorStatus = `${error.ec}_${error.em}_${error.ed}`;
        errorCode = error.ec;
      }
      trackError(errorStatus, bookingId, undefined);
      dispatch(setErrorAction(searchKey, errorCode));
      return null;
    }
    trackError('No_Response', bookingId, undefined);
    dispatch(setErrorAction(searchKey, ''));
    return null;
  } catch (e) {
    console.error(e);
    trackError('EXCEPTION', bookingId, undefined);
    dispatch(setErrorAction(searchKey, ''));
    return null;
  }
};

export const updateSelectedItemAction = (listItem) => (dispatch, getState) => {
  const isFdcApplicable = !!listItem && !!listItem.fareBreakup && !!listItem.fareBreakup.header;
  const selectedFlightItem = getFareComponent(listItem, getState().flightListing.searchResponse);
  if (listItem.odc.tdc) {
    trackClickEventListingPopup(
      'Item_Fare_Reconfirm',
      getState().flightListing.bookingType,
      listItem.airlineName,
      isFdcApplicable,
    );
  } else {
    trackClickEventListingPopup(
      'Item_Cancel',
      getState().flightListing.bookingType,
      listItem.airlineName,
      isFdcApplicable,
    );
  }
  if (!!listItem.fareBreakup || !!listItem.priceBreakup) {
    const selectedFlightItemNew = {
      recomId: listItem.odc.recomId,
      sessionId: getState().flightListing.sessionId,
      crKey: getState().flightListing.searchResponse.crKey,
    };
    if (listItem.fareBreakup) {
      selectedFlightItemNew.fareBreakup = listItem.fareBreakup;
      selectedFlightItemNew.additionalInfoList = listItem.additionalInfoList;
    } else {
      selectedFlightItemNew.priceBreakup = listItem.priceBreakup;
    }
    dispatch({
      type: ACTION_SELECT_ITEM,
      data: { selectedFlightItem: selectedFlightItemNew },
    });
  } else {
    dispatch({
      type: ACTION_SELECT_ITEM,
      data: { selectedFlightItem },
    });
  }
};

const setLoadingAction = () => ({
  type: ACTION_LOADING_FLIGHTS,
});

export const refreshListing = () => ({
  type: ACTION_REFRESH,
});

export const listingLoader = () => (dispatch) => {
  dispatch(setLoadingAction());
};

const setErrorAction = (searchKey, errorCode) => ({
  type: ACTION_ERROR,
  data: { searchKey, errorCode },
});

const setNewErrorAction = (searchKey, errorCode, errorPageData, listingErrorResponse) => ({
  type: ACTION_ERROR_NEW,
  data: { searchKey, errorCode, errorPageData, listingErrorResponse },
});

export const listingSuccess = (response, sessionId, cfarInfo, odcImportantInfo) => {
  const {travellersText, travellersText2} = getStaticData();
  return {
  type: ACTION_SHOW_DETAIL,
  response,
  searchResponse: response.odcSummary,
  searchMap: getFlightItemListMap(response),
  headerInfo: getListingHeader(response, travellersText, travellersText2),
  bookingType: getBookingType(response.meta),
  filterInfo: getFilterInfo(response),
  message: response.message,
  sessionId,
  cfarInfo,
  odcImportantInfo,
  calendarDataList: getCalendarDataList(response),
  };
};




export const dispatchFilterListing = (response, sortType, sortOrder, currFltrObj) => (dispatch) => {
  let searchMap = filterListing(response, sortType, sortOrder, currFltrObj);
  dispatch({
    type: ACTION_RENDER_LIST,
    searchMap,
  });
  FlightListingTrackingHelper.trackClickEvent(
    'Mob:Customer support:datechangelisting:sortNfilter',
    `apply ${getActiveFilters(sortType, sortOrder, currFltrObj)}`,
  );
};
