
import LottieView from 'lottie-react-native';
import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, Text, View, Animated, Easing } from 'react-native';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import { getStaticData } from '../../../staticData/staticData';
import { LANG_AWARE } from '../../HotelConstants';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

// https://www.figma.com/file/ER9EZ9T0FovoVLCXTysX7o/2B.-Hotels---Mobile?node-id=8240%3A6549
// Screen - Loading State
const loaderShimmer = require('@mmt/legacy-assets/src/LottieAnimations/modificationLoader/data.json');

const LoadingView = () => {
	const { ...fonts } = getFont(true);
  const styles = getStyles(fonts);
	const { checkingAvailability } = getStaticData(LANG_AWARE.MODIFICATION);
	const animationVar = useRef(null);

	useEffect(() => {
		animationVar.current.play();
	});

	return (
		<View style={styles.animationContainer}>
			<LottieView
				ref={animationVar}
				style={styles.lottieView}
				source={loaderShimmer}
				/>
			<Text style={styles.textStyle}>{checkingAvailability}</Text>
		</View>
	)
}

const getStyles = (fonts) => {
	return StyleSheet.create({
		animationContainer: {
			backgroundColor: '#fff',
			alignItems: 'center',
			justifyContent: 'center',
			flex: 1,
		},
		textStyle: {
			color: colors.defaultTextColor,
			...fonts.font16,
			fontFamily: fonts.black,
			lineHeight: 20,
			marginTop: 30
		},
		lottieView: { width: 140, height: 140, backgroundColor: '#fff' }
	})
}

export default LoadingView;
