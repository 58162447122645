import React, { useState } from 'react';
import { Text, View, TouchableOpacity, Image } from 'react-native';
import createStyles from '../styles';
import { isFilterDisabled } from '../../../../utils/FlightListingUtil';
import { getFont } from '../../../../../../../../../PostSalesStyles';
import { useTheme } from '../../../../../../../../../theme/theme.context';

const FlightDeparture = (props) => {
  const { psTheme } = useTheme();
  const fonts = getFont(true);
  const styles = createStyles(psTheme, fonts);
  const [filterActiveItem, setFilterActiveItem] = useState(props.selected);
  const fltrDisabled = isFilterDisabled(props.fltrMtDta);
  const handleFilterActiveItem = () => {
    setFilterActiveItem(!filterActiveItem);
    props.toggleDepFilter(!filterActiveItem, props.index);
  };

  const { data, fltrMtDta, currency } = props;
  const icon = filterActiveItem ? data.DepImgActive : data.DepImg;
  return (
    <>
      <TouchableOpacity
        style={
          !filterActiveItem
            ? styles.filterDepartureWrapper
            : [styles.filterDepartureWrapper, { backgroundColor: psTheme.color.primary }]
        }
        activeOpacity={1}
        onPress={handleFilterActiveItem}
        disabled={fltrDisabled}
      >
        <Image style={[styles.depIcon, styles[data.iconStyle]]} source={icon} />
        <Text
          style={
            fltrDisabled
              ? [styles.DepTxt, styles.textGray]
              : !filterActiveItem
              ? styles.DepTxt
              : [styles.DepTxt, styles.TextWhite]
          }
        >
          {data.DepTime}
        </Text>
        <Text
          style={
            fltrDisabled
              ? [styles.stopPriceTxt, styles.textGray]
              : !filterActiveItem
              ? styles.stopPriceTxt
              : [styles.stopPriceTxt, styles.TextWhite]
          }
        >
          {fltrDisabled ? '' : `${currency || '₹'} ${fltrMtDta.chPay}`}
        </Text>
      </TouchableOpacity>
    </>
  );
};
export default FlightDeparture;
