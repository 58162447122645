import {
  DATE_MONTH,
  POSTSALES_DATE_TIME,
  reformatDateString,
} from '@mmt/legacy-commons/Helpers/dateTimehelpers';
import myTripsNavigation from '../../../../navigation/navigation';
import { titleCase } from '@mmt/legacy-commons/Helpers/stringHelpers';
import { getStaticData } from '../../../../../staticData/staticData';
import _isEmpty from 'lodash/isEmpty';
import { handleCTAClick, getMyRequestCardData } from '../../../utils';
import { MsrTicket, TrainData, TrainDetailsProps, TrainSummaryDataProps } from '../cardInterfaces';
import { trainIcon } from 'apps/post-sales/src/Rails/Utils/RailImageConstant';

function getTravelerSummary(busDetails: TrainDetailsProps, data: TrainData) {
  const { passengers } = busDetails || {};
  if (_isEmpty(passengers)) {
    const { leadPaxFirstName, leadPaxLastName } = data;
    return `${leadPaxFirstName} ${leadPaxLastName || ''}`;
  }
  const count = passengers.length;
  const firstPaxName = titleCase(passengers[0].firstName);
  if (count === 1) {
    return firstPaxName;
  }
  return `${firstPaxName} + ${count - 1}`;
}

export function getTrainSummaryData(data: TrainData, msrTicket: MsrTicket): TrainSummaryDataProps {
  const staticData = getStaticData();
  const { trainBookingText } = staticData;
  const departDate = data.minDeptDateMobile || reformatDateString(data.minDeptDate, POSTSALES_DATE_TIME, DATE_MONTH);
  const [railDetails] = data.railDetails;
  const destinationRailDetailsIndex = data.railDetails && data.railDetails.length - 1;

  const { ctaInfoList: ctaDetails, infoCard } = data && data.bookingStateLayout || {};

  const pageData = {
    fromMyTrips: true,
  };

  const openDetails = () => {
    myTripsNavigation.openRailBookingDetail(data, pageData);
  };

  const actions: Array<{ label: string; onPress: () => void }> = [];

  let bookingInfo = trainBookingText;
  const travelerSummary = getTravelerSummary(railDetails, data);
  let dateTime = `${departDate} • ${travelerSummary}`;
  let passengerDetails = travelerSummary;
  const { trainNumber, trainName, startDateText, startTimeText } = railDetails || {};
  const { endDateText, endTimeText } = data.railDetails && data.railDetails[destinationRailDetailsIndex]|| {};
  const { text, ctaInfo } = infoCard || {};
  const startsIn = data.startsIn;
  const lobName = data.lobType[0];
  const bookingId = data.bookingID;
  const infoCardStatusText = text;
  const infoCardStatusCTA = ctaInfo && ctaInfo.text;

  if (ctaDetails && ctaDetails[0]) {
    actions.push({ label: ctaDetails[0]?.text, onPress: () => handleCTAClick(ctaDetails[0], data ) })
  };
  if (ctaDetails && ctaDetails[1]) {
    actions.push({ label: ctaDetails[1]?.text, onPress: () => handleCTAClick(ctaDetails[1], data ) })
  };

  const handleInfoClick = () => {
    if (infoCard) {
      handleCTAClick(infoCard.ctaInfo, data);
    }
  };

  return {
    status: data.status,
    iconUrl: trainIcon,
    dateTime: dateTime,
    bookingInfo,
    from: `${railDetails.originCityName} (${railDetails.origin})`,
    to: `${railDetails.destinationCityName} (${railDetails.destination})`,
    actions,
    myRequestsCard: getMyRequestCardData(msrTicket),
    passengerDetails,
    railNo: trainNumber,
    railPass: trainName,
    railStartDateText: startDateText,
    startsIn,
    lobName,
    bookingId,
    railEndDateText: endDateText,
    railStartDateTime: startTimeText,
    railEndDateTime: endTimeText,
    infoCardStatusText,
    infoCardStatusCTA,
    openDetails,
    handleInfoPress: handleInfoClick
  };
}

export default {};