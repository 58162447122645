import React, { useEffect } from 'react';
import { Text, View, TouchableOpacity } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { getFont } from '../../../../../PostSalesStyles';
import createStyle from './SelectRefundModeCss';
import { useTheme } from '../../../../../theme/theme.context';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import SPECIAL_CLAIM_ACITON_TYPES from '../../data/specialClaimActionTypes';
import { isEmptyArray } from '../../../../../Common/commonUtil';
import RadioButton from '../../../../../Common/RadioButton';
import { getRefundModeData } from '../../data/dataAdapter';

const SelectRefundPaymentMode = ({ data, refundMode, dispatchCancellationState }) => {
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyle(theme, fonts);
  const {color} = theme;
  const onPress = (id) => {
    dispatchCancellationState({ type: SPECIAL_CLAIM_ACITON_TYPES.SET_REFUND_MODE, refundMode: id });
  };

  const { title, subtitle, entries } = getRefundModeData(data);

  return (
    <View style={styles.mainWrapper}>
      <View style={styles.cardWrapper}>
        <View style={styles.headingText}>
          {title && (
            <Text
              style={[
                fsStyle.font20,
                fonts.blackFontFamily,
                {color : color.black},
                AtomicCss.marginBottom8,
                AtomicCss.lineHeight28,
                AtomicCss.flex1,
              ]}
            >
              {title}
            </Text>
          )}
          {subtitle && (
            <View style={[AtomicCss.flex1, AtomicCss.flexRow]}>
              <View style={styles.infoWrapper}>
                <Text style={[styles.infoText, fonts.blackFontFamily, fsStyle.font10]}>
                  {subtitle}
                </Text>
              </View>
            </View>
          )}
        </View>
        {!isEmptyArray(entries) &&
          entries.map((entry, index) => {
            const { title, subtitle, topHeader } = entry;
            return (
              <TouchableOpacity
                key={entry.id}
                onPress={onPress.bind(null, entry.id)}
                activeOpacity={0.8}
              >
                <View
                  style={[styles.selectWrapperUp, [entry.id === refundMode ? styles.activeBg : '']]}
                >
                  <View style={AtomicCss.flexRow}>
                    <RadioButton
                      selected={entry.id === refundMode}
                      onChange={onPress.bind(null, entry.id)}
                      radioOuterStyle={styles.radio}
                    />
                    <View style={styles.refundCardDetails}>
                      <View style={styles.refundTopDtls}>
                        <Text style={[styles.refundLeftDtls, fonts.boldFontFamily, fsStyle.font16]}>
                          {title}
                        </Text>
                        {!!topHeader && (
                          <LinearGradient
                            start={{ x: 0.0, y: 1.0 }}
                            end={{ x: 1.0, y: 0.0 }}
                            colors={theme.color.primaryGradient}
                            style={styles.refundTag}
                          >
                            <Text
                              style={[styles.refundTagText, fonts.boldFontFamily, fsStyle.font10]}
                            >
                              {topHeader}
                            </Text>
                          </LinearGradient>
                        )}
                      </View>
                      <View>
                        {!!subtitle && (
                          <Text
                            style={[styles.refundText, fsStyle.font13, fonts.regularFontFamily]}
                          >
                            {subtitle}
                          </Text>
                        )}
                      </View>
                    </View>
                  </View>
                </View>
              </TouchableOpacity>
            );
          })}
      </View>
    </View>
  );
};
export default SelectRefundPaymentMode;
