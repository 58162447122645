import {StyleSheet} from 'react-native';

export default (theme, fonts) =>
StyleSheet.create({
  ErrorStateWrapper: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    paddingHorizontal: 15
  },
  pageWrapper:{
    flex: 2,
    backgroundColor: theme.color.white,
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingBottom: '25%'
  },
  footerBtnWrapper: {
    paddingHorizontal: 50,
    paddingTop: 15,
    width: '100%'
  },
  messageContent: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 50
  },
  messageIcon: {
    width: 65,
    height: 40,
    marginBottom: 40
  },
  messageTitle: {
    fontSize: 20,
    fontFamily: fonts.bold,
    color: theme.color.black28,
    marginBottom: 10,
    textAlign: 'center'
  },
  messageDesc: {
    fontSize: 14,
    fontFamily: fonts.regular,
    color: theme.color.gray,
    textAlign: 'center',
    lineHeight: 20
  },
  ErrorPageText: {
    fontSize: 20,
    textAlign: 'center',
    lineHeight: 25,
    color: '#3c3c3c'

  },
  AlignCenter: {
    textAlign: 'center'
  },
  lineHt22: {
    lineHeight: 22
  },
  mar20Bt: {
    marginBottom: 20
  },
  CommonFillBtn: {
    borderRadius: 25,
    paddingVertical: 8,
    paddingHorizontal: 25
  },
  CenterText: {
    alignSelf: 'center'
  },
  WhiteText: {
    color: theme.color.white
  },
  boldFont: {
    fontFamily: fonts.bold
  },
  blackFont: {
    fontFamily: fonts.black
  },
  transperantBgrnd: {
    ...Platform.select({
      ios: {
        backgroundColor: 'transparent'
      }
    })
  },
});
