import React from 'react';
import Actions from '../../../../../navigation/postSalesNavigation';
import { connect } from 'react-redux';
import { View, DeviceEventEmitter, Platform } from 'react-native';
import DateChangeBaseClass from '../../components/DateChangeBaseClass';
import {
  getOdcApprovalCommonHeaders,
  getURLParams,
  FLIGHT_CORP_DATE_CHANGE_REVIEW,
  FLIGHT_CORP_DATE_CHANGE_HOLD,
  getUserPlatform,
} from '../../../../../utils/NetworkUtils';
import styles from './corpReviewPageCss';
import CustomerView from './components/CustomerView';
import ManagerView from './components/ManagerView';
import { isNetworkAvailable } from '@mmt/legacy-commons/Common/utils/AppUtils';
import FlightBookingModule from '@mmt/legacy-commons/Native/FlightBookingModule';
import { DateChangeConstants, DcViewState } from '../../constants';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import DateChangeTrackingHelper from '../../analytics/DateChangeTrackingHelper';
import withRouterWrapper from '../../../../../Common/Web/withRouterWrapper';
import {refreshListing} from '../../../../../flights-funnel/Listing/utils/FlightListingActions'
import { openErrorPage } from '../../../../../utils/PostSaleUtil';
import { LOBNAMES } from '../../../../../PostSalesConstant';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { isEmpty, isNil } from 'lodash';
import NoInternetView from '@mmt/legacy-commons/Common/Components/Error/NoInternetView';
import { getCustomerViewRequest, getHoldRequest, getManagerViewRequest, getUserInfo } from './data/requestAdapter';
import { fetchReviewResponse } from './data/api';
import { getCorpReviewData, getCorpReviewError } from './data/dataAdapter';

class CorpDateChangeReviewPage extends DateChangeBaseClass {
  constructor(props) {
    super(props, 'corpFlightRescheduleReviewPage');
    let requester;
    const currentLocation = window && window.location && window.location.href;
    this.action = getURLParams('act', currentLocation);
    const urlSnapshot =
      !props.actionUrl && this.action === '301' ? currentLocation : props.actionUrl;
    if (urlSnapshot) {
      this.bookingId = getURLParams('bookingId', urlSnapshot);
      this.workflowId = getURLParams('workflowId', urlSnapshot);
      this.authCode = getURLParams('authCode', currentLocation);
    }
    if (props.actionUrl) {
      requester = getURLParams('requester', props.actionUrl);
    }
    if (!props.actionUrl && this.action === '301') {
      requester = null;
    }
    this.state = {
      responseJson: null,
      viewState: DcViewState.LOADING,
      renderPwaApprovalPage: !props.actionUrl && this.action === '301',
      errorMsg: undefined,
      errorNextStep: undefined,
      statusView: requester !== undefined,
      reviewView: requester === undefined,
    };
    if (requester !== undefined && requester) {
      this.pageName = 'dateChangeReviewStatus-Requester';
    } else if (requester !== undefined && !requester) {
      this.pageName = 'dateChangeReviewStatus-Manager';
    } else {
      this.pageName = 'datechangereview';
    }
    this.reviewResponse = {};
    this.holdAPIErrorNextStep = undefined;
    this.thankyouPageAfterApprovalListener = null;
  }
  componentDidMount() {
    this.fetchJSONAsync(FLIGHT_CORP_DATE_CHANGE_REVIEW);
  }

  componentWillMount() {
    if (DeviceEventEmitter) {
      this.thankyouPageAfterApprovalListener = DeviceEventEmitter.addListener(
        'flight_datechange_thankyou_page_after_approval',
        this.openModificationThankYouPage,
      );
    }
  }

  componentWillUnmount() {
    if (this.thankyouPageAfterApprovalListener) {
      this.thankyouPageAfterApprovalListener.remove();
    }
  }

  openModificationThankYouPage = (data) => {
    if (data) {
      const paymentResponse = JSON.parse(data.PAYMENT_RESPONSE_VO);
      const emailId = data.emailID;
      const bookingId = data.BOOKING_ID;
      if (paymentResponse) {
        const respObj = JSON.parse(paymentResponse.response);
        Actions.dateChangeSucess({
          paymentSuccess: respObj.success,
          commitMsg: respObj.commitMessage,
          commitTitle: respObj.commitTitle,
          emailId,
          bookingId,
          managerView : this.state.statusView
        });
      } else {
        Actions.dateChangeSucess({
          paymentSuccess: false,
          bookingId,
          managerView : this.state.statusView
        });
      }
    } else {
      Actions.dateChangeSucess({
        paymentSuccess: false,
        bookingId: this.bookingId,
        managerView : this.state.statusView
      });
    }
  };

  fetchData = () => {
    this.setState({ viewState: DcViewState.LOADING });
    this.fetchJSONAsync(FLIGHT_CORP_DATE_CHANGE_REVIEW);
  };

  fetchJSONAsync = async (url) => {
    try {
      const hasNetwork = await isNetworkAvailable();
      if (!hasNetwork) {
        this.setState({ viewState: DcViewState.NO_INTERNET });
        return;
      }
      let userInfo = await getUserInfo(this.bookingId,this.action,'FLIGHT_DATE_CHANGE_REVIEW');

      let request = this.state.statusView ? getManagerViewRequest(this.bookingId,this.workflowId) : getCustomerViewRequest(this.props);
      await Promise.all([this.fetchODCPreviewDetails(url, request, userInfo, this.pageName)])
        .then((values) => {
          const reviewResponse = {};
          if (!isEmpty(values) && values[0] !== 'ERROR') {
            reviewResponse.reviewDetail = getCorpReviewData(values[0]);
          } else {
            const error = getCorpReviewError(values);
            if (error){
              throw error;
            }
          }
          this.setState({
            responseJson: reviewResponse,
            viewState: DcViewState.SHOW_DETAIL,
          });
          this.reviewResponse = reviewResponse;
        })
        .catch((error) => {
          DateChangeTrackingHelper.trackErrorEvent(this.pageName, error, undefined);
          this.setState({ viewState: DcViewState.ERROR });
        });
    } catch (error) {
      DateChangeTrackingHelper.trackErrorEvent(this.pageName, error, undefined);
      this.setState({ viewState: DcViewState.ERROR });
    }
  };

  async fetchODCPreviewDetails(url, request, userInfo, pageName) {
    const body = await fetchReviewResponse(userInfo,this.authCode,url,request);
    try {
      if (body.errorPageData) {
        openErrorPage(
          body.errorPageData,
          this.onBackPressFromErrorPage,
          this.fetchData,
          body.bookingId,
          'datechange',
          Actions,
          { psLob: LOBNAMES.FLIGHT, isCorporateBooking: this.props.isCorporateBooking },
        );
        DateChangeTrackingHelper.trackErrorEvent(
          pageName,
          'dateChangeReviewODCPreview_Failure',
          body.errorPageData.meta.supplier,
        );
      } else {
        DateChangeTrackingHelper.trackLoadEvent(pageName, body, true);
        return body;
      }
    } catch(e) {
      DateChangeTrackingHelper.trackErrorEvent(pageName, 'dateChangeReviewODCPreview_Failure', '');
      return 'ERROR';
    }
    return body;
  }

  makeHoldCall = async (
    bookingId,
    workflowId,
    crKey,
    rKey,
    policyKey,
    skipApproval,
    setViewState,
    setHoldResponse,
    setReasonRescheduleOverlay,
  ) => {
    setViewState(DcViewState.HOLD_LOADING);
    if (setReasonRescheduleOverlay) {
      setReasonRescheduleOverlay(false);
    }
    try {
      const hasNetwork = await isNetworkAvailable();
      if (!hasNetwork) {
        setViewState(DcViewState.NO_INTERNET);
        return;
      }
      const request = getHoldRequest(workflowId,policyKey,crKey,this.props.sessionId,this.props.recomId,bookingId,skipApproval);

      let userInfo = await getUserInfo(bookingId,this.action,'FLIGHT_CORP_DATECHANGE_HOLD');

      const body = await fetchReviewResponse(userInfo,this.authCode,FLIGHT_CORP_DATE_CHANGE_HOLD,request);
      if (body.errorPageData) {
        this.holdAPIErrorNextStep = body.failureNextStep;
        openErrorPage(
          body.errorPageData,
          this.onBackPressFromErrorPage,
          this.onHoldFailureRetryClicked,
          body.bookingId,
          'datechange',
          Actions,
          { psLob: LOBNAMES.FLIGHT },
        );
        DateChangeTrackingHelper.trackErrorEvent(
          `${this.pageName}paymentcall`,
          'ODCReview_PaymentCall_Failure',
          body.errorPageData.meta && body.errorPageData.meta.supplier,
        );
      } else if (body.success) {
        this.holdAPIErrorNextStep = undefined;
        DateChangeTrackingHelper.trackLoadEvent(`${this.pageName}paymentcall`, body, true);
        setHoldResponse(body);
        setViewState(DcViewState.HOLD_SUCCESS);
      } else {
        this.holdAPIErrorNextStep = undefined;
        this.setState({
          errorMsg: body.failureMessage,
          errorNextStep: body.failureNextStep,
        });
        setViewState(DcViewState.HOLD_API_FAILURE_RESPONSE);
      }
    } catch (error) {
      DateChangeTrackingHelper.trackErrorEvent(
        `${this.pageName}datechangereview`,
        'ODCReview_PaymentCall_Failure',
        error,
      );
      setViewState(DcViewState.ERROR);
    }
  };

  render() {
    return (
      <View style={AtomicCss.flex1}>
        {this.state.viewState === DcViewState.LOADING &&
          this.renderProgressView(DateChangeConstants.LOADING_REVIEW)}
        {this.state.viewState === DcViewState.NO_INTERNET && this.renderNoNetworkView()}
        {this.state.viewState === DcViewState.ERROR && this.renderErrorView()}
        {this.state.viewState === DcViewState.SHOW_DETAIL && this.renderPage()}
      </View>
    );
  }

  renderNoNetworkView = () => (
    <View style={AtomicCss.flex1}>
      <NoInternetView
        onRetry={() => {
          this.setState({ viewState: DcViewState.LOADING });
          this.fetchJSONAsync(FLIGHT_CORP_DATE_CHANGE_REVIEW);
        }}
      />
    </View>
  );
  renderPaymentPage = (holdResponse, setFareChangeOverlay, setViewState, skipValue) => {
    const { bookingId, nextstep, fareChanged, commitMessage, commitTitle } = holdResponse;
    switch (nextstep.toUpperCase()) {
      case 'PAYMENT':
        if (fareChanged) {
          setFareChangeOverlay();
          DateChangeTrackingHelper.trackErrorEvent(this.pageName, 'fareupdate', undefined);
        } else {
          this.openPaymentPage(holdResponse, skipValue);
        }
        setViewState(DcViewState.SHOW_DETAIL);
        break;
      case 'THANKYOU':
        Actions.dateChangeSucess({
          paymentSuccess: true,
          commitMsg: commitMessage,
          commitTitle,
          bookingId,
          managerView : this.state.statusView
        });
        break;
      case 'FAILURE':
        Actions.dateChangeSucess({
          paymentSuccess: false,
          commitMsg: commitMessage,
          commitTitle,
          bookingId,
          managerView : this.state.statusView
        });
        break;
      default:
        setViewState(DcViewState.ERROR);
    }
  };

  openPaymentPage = (holdResponse, skipValue) => {
    if (holdResponse && Platform.OS === 'ios' && skipValue === 'PROCEED_TO_BOOK') {
      holdResponse.eventType = 'after_approval';
    }
    FlightBookingModule.openFlightDateChangePaymentPage(JSON.stringify(holdResponse));
    DateChangeTrackingHelper.trackClickableEvent(
      `${this.pageName}fareupdate`,
      'ODCReview_Proceed_Clicked',
      this.state.odcPreviewResponse,
      this.props.isCorporateBooking
    );
  };

  renderHoldAPIFailureView = () => {
    return (
      <ErrorView
        message={this.state.errorMsg}
        showRetry
        onRetry={() => {
          this.onHoldFailureRetryClicked();
        }}
      />
    );
  };

  renderPage() {
    const { statusView, reviewView, renderPwaApprovalPage } = this.state;
    return (
      <View style={styles.pageWrapper}>
        {statusView && (
          <ManagerView
            reviewResponse={this.state.responseJson}
            workflowId={this.workflowId}
            onBackPressFromErrorPage={this.onBackPressFromErrorPage}
            renderPaymentPage={this.renderPaymentPage}
            makeHoldCall={this.makeHoldCall}
            openPaymentPage={this.openPaymentPage}
            renderHoldAPIFailureView={this.renderHoldAPIFailureView}
            pageName={this.pageName}
            fetchData={this.fetchData.bind(this)}
            renderPwaApprovalPage={renderPwaApprovalPage}
            odcPreviewResponse={this.props.odcPreviewResponse}
            odcSegmentGroup={this.props.segmentGroupPaxDcRequested}
            isCorporateBooking={this.props.isCorporateBooking}
          />
        )}
        {reviewView && (
          <CustomerView
            {...this.state.responseJson}
            fetchData={this.fetchData}
            onBackPressFromErrorPage={this.onBackPressFromErrorPage}
            renderPaymentPage={this.renderPaymentPage}
            makeHoldCall={this.makeHoldCall}
            openPaymentPage={this.openPaymentPage}
            renderHoldAPIFailureView={this.renderHoldAPIFailureView}
            pageName={this.pageName}
            sessionId={this.props.sessionId}
            recomId={this.props.recomId}
            odcPreviewResponse={this.props.odcPreviewResponse}
            odcSegmentGroup={this.props.odcSegmentGroup}
            isCorporateBooking={this.props.isCorporateBooking}
          />
        )}
      </View>
    );
  }

  onHoldFailureRetryClicked = () => {
    let nextFailureStep = this.state.errorNextStep;
    if (!nextFailureStep) {
      nextFailureStep = this.holdAPIErrorNextStep;
    }
    if (nextFailureStep) {
      switch (nextFailureStep.toUpperCase()) {
        case 'LISTING':
          this.refreshListingPage();
          break;
        case 'PICK_DATES':
        case 'REVIEW':
          this.fetchData();
          break;
        default:
          this.fetchData();
          break;
      }
    } else {
      this.fetchData();
    }
  };

  onBackPressFromErrorPage = () => {
    Actions.pop();
  };
  refreshListingPage = () => {
    let somethingPopped;
    try {
      somethingPopped = Actions.popTo('flightListing');
    } catch (e) {
      // e.print();
    }
    if (somethingPopped) {
      this.props.refreshListingPage();
    }
  };
}

const mapDispatchToProps = (dispatch) => ({
  refreshListingPage: () => {
    dispatch(refreshListing());
  },
});

export default withRouterWrapper(connect(null, mapDispatchToProps)(CorpDateChangeReviewPage));
