import {colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles';

const styles = {
  PartCancellationHead: {
    backgroundColor: colors.white,
    paddingVertical: 18,
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#ddd'
  },
  ActionBtn: {
    backgroundColor: colors.white,
    paddingBottom: 10,
    marginTop: 15
  },
  borderbtm: {
    borderBottomWidth: 1,
    borderBottomColor: '#ddd'
  },
  refundCardOuter: {
    paddingLeft: 15,
    paddingRight: 15,
    paddingBottom: 10,
    paddingTop: 10
  },
  refundCardWrap: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  MimaRadio: {
    width: 18,
    height: 18,
    backgroundColor: '#fff',
    borderRadius: 20,
    overflow: 'hidden',
    borderWidth: 1,
    borderColor: '#008cff',
    marginRight: 20,
    marginTop: 5
  },
  MimaRadioInside: {
    width: 12,
    height: 12,
    backgroundColor: '#008cff',
    borderRadius: 20,
    overflow: 'hidden',
    marginLeft: 2,
    marginTop: 2
  },
  refundCardLeft: {
    width: '75%'
  },
  refundText: {
    marginLeft: 38
  },
  instantRefundTag: {
    height: 18,
    borderRadius: 8,
    width: 115,
    alignItems: 'center',
    justifyContent: 'center'
  },
  instantRefundTagTxxt: {
    fontFamily: fonts.bold,
    fontSize: 10,
    color: '#fff',
    alignSelf: 'center'
  }
};

export default styles;
