import React from 'react';
import {View, Platform} from 'react-native';
import getStyles from './HotelMamiCss';
import BookingConfirmedHeading from '../cards/BookingConfirmedHeading';
import BookingWhiteCard from '../cards/BookingWhiteCard';
import BookingConfirmedBottom from '../cards/BookingConfirmedBottom';
import Header from '../cards/Header';
import isEmpty from 'lodash/isEmpty';
import { showShareIcon, showDownloadIcon } from '../utils/HotelBookingDetailUtil';
import PendingPaymentCard from '../../../Common/PendingPaymentCard/PendingPaymentCard';
import BookingPendingCard from '../cards/BookingPendingCard';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const TopStatusCard = ({response, card, title, subTitle,subTitleSec, description, color, titleStyle, subTitleStyle, showTime, showArrows, pageName, handleIssueAtHotelCard, bookingCardStyle, bottomText, showDatesAndRoomDetails = true, onBackPress, showInstallmentOverlay, handlePAHCard, handleMessageHost, actionHandler, handleOpenBottomOverlay}) => {
  const { ...fonts } = getFont(true);
  const styles = getStyles(fonts);
  const topStatusColorStyle = {backgroundColor: color};
  const { pendingPaymentCard, paymentDetails, paymentChargeDate, hotelBookingInfo, waiverRequestInfo } = response || {};
  const {hotelUIFields} = hotelBookingInfo?.hotelDetailsList?.[0] || {};
  const { pendingCardData } = hotelBookingInfo || {};

  return (
    <View style={styles.topCardWrapper}>
      <View style={[styles.headerWrapper, topStatusColorStyle]}>
        <Header
            download={showDownloadIcon(response)}
            share={showShareIcon(response)}
            headerRtSection={true}
            response={response}
            onBackPress={onBackPress}
            bgColor={color}
            pageName={pageName}
          />
      </View>
      <View style={[styles.topWrapper, topStatusColorStyle]}>
        <BookingConfirmedHeading
          title={title}
          subTitle={subTitle}
          titleStyle={titleStyle}
          subTitleStyle={subTitleStyle}
          subTitleSec={subTitleSec}
          hotelUIFields={hotelUIFields}
          waiverRequestInfo={waiverRequestInfo}
          description={description}
        />
        {
          !isEmpty(pendingCardData) && <BookingPendingCard pendingCardData={pendingCardData} />
        }
        { Platform.OS === "web" && !isEmpty(pendingPaymentCard) && !isEmpty(paymentDetails) && !isEmpty(paymentChargeDate) && paymentDetails.amountDue>0 &&
            <PendingPaymentCard
              key="PaymentPendingCard"
              title={pendingPaymentCard.title}
              subTitle={pendingPaymentCard.subTitle}
              tagTextStart={pendingPaymentCard.tagTextStart}
              tagTextEnd={pendingPaymentCard.tagTextEnd}
              ctaText={pendingPaymentCard.ctaText}
              ctaUrl={pendingPaymentCard.ctaUrl}
              paymentChargeDate={paymentChargeDate}
          />
        }
        <BookingWhiteCard
          response={response}
          card={card}
          showTime={showTime}
          showArrows={showArrows}
          pageName={pageName}
          handleIssueAtHotelCard={handleIssueAtHotelCard}
          topStatusColorStyle={topStatusColorStyle}
          bookingCardStyle={bookingCardStyle}
          showDatesAndRoomDetails={showDatesAndRoomDetails}
          showInstallmentOverlay={showInstallmentOverlay}
          handlePAHCard={handlePAHCard}
          handleMessageHost={handleMessageHost}
          actionHandler={actionHandler}
          handleOpenBottomOverlay={handleOpenBottomOverlay}
        />
        <BookingConfirmedBottom
          bookingId={response.bookingID}
          textStyle={subTitleStyle}
          bottomText={bottomText}
        />
      </View>
    </View>
  );
}

export default TopStatusCard;
