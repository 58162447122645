import React from 'react';
import { Text } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import VisaSelectTravellerRow from '../VisaSelectTravellerRow';
import isEmpty from 'lodash/isEmpty';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';

const SelectTravellerCard = ({ label, passengers, onSelectPassenger, selectedPassengers }) => {
  const fonts = getFont();
  const { psTheme: theme } = useTheme();
  const isSelected = (itm) => {
    return (
      !isEmpty(selectedPassengers) &&
      selectedPassengers.findIndex((item) => item.paxId === itm.paxId) !== -1
    );
  };
  return (
    <>
      {!isEmpty(label) && (
        <Text
          style={[
            fonts.boldFontFamily,
            { color: theme.color.defaultTextColor },
            { fontSize: fonts.fontSize.font14 },
            AtomicCss.marginBottom15,
          ]}
        >
          {label}
        </Text>
      )}
      {!isEmpty(passengers) &&
        passengers.map((item, index) => (
          <VisaSelectTravellerRow
            item={item}
            selected={isSelected(item)}
            onSelectPassenger={onSelectPassenger}
            travellerIndex={index}
          />
        ))}
    </>
  );
};

export default SelectTravellerCard;
