import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Text, View, Image } from 'react-native';
import createStyles from './FlightInfoDtlsCss';
import FltAmentiesDtls from './FltAmentiesDtls';
import { getDateInFormat } from 'core-ui-lib/flight/common/ODC/helper';
import { getStaticData } from '../../../../../staticData/staticData';
import { useTheme } from '../../../../../theme/theme.context';
import { getFont } from '../../../../../PostSalesStyles';
import {getBaggageInfo, getTerminalData} from '../../data/dataAdapter';
import PolicyCard from '../PolicyCard';

const BlackArrow = require('@mmt/legacy-assets/src/Arrow.webp');

const FlightInfoDtls = (props) => {
  const jrnyVwLst = [];
  const {psTheme: theme} = useTheme();
  const fonts = getFont(true);
  const styles = createStyles(theme, fonts);

  const getLegViewCard = (legs) => {
    const { baggageAllowanceText, terminalText } = getStaticData();
    const legViewLst = [];
    legs.forEach((leg) => {
      legViewLst.push(
        <View style={styles.whiteCard}>
          <View style={styles.flexRow}>
            <View style={[styles.spaceBetween]}>
              <View>
                <Text style={styles.FltTime}>{leg.dt}</Text>
                <Text style={styles.FltDateInfo}>
                  {getDateInFormat(leg.dd, 'D MMM').toUpperCase()}
                </Text>
              </View>
              <View>
                <Text style={styles.FltTime}>{leg.at}</Text>
                <Text style={styles.FltDateInfo}>
                  {getDateInFormat(leg.ad, 'D MMM').toUpperCase()}
                </Text>
              </View>
            </View>
            <View style={styles.flex1}>
              <View style={styles.fltInfoCircleTop} />
              <View style={styles.fltInfoInnerDtls}>
                <View style={styles.marginBottom20}>
                  <Text style={[styles.font12, styles.boldText]}>
                    {leg.faname}
                    <Text style={styles.LightText}>{getTerminalData(leg.ftno, terminalText)}</Text>
                  </Text>
                  <Text style={[styles.font12, styles.boldText]}>
                    {' '}
                    {leg.fcc}, <Text style={styles.LightText}>{leg.fc}</Text>
                  </Text>
                </View>
                {!isEmpty(leg.techStopInfo) && (
                  <View style={[styles.marginBottom20, { position: 'relative' }]}>
                    <View style={[styles.fltInfoCircleTop, styles.fltInfoCircleCenter]} />
                    <Text style={[styles.font12, styles.boldText]}>{leg.techStopInfo}</Text>
                  </View>
                )}

                <View style={styles.fltAmentiesWrapper}>
                  <FltAmentiesDtls legDetails={leg} />
                </View>
                <View style={[styles.marginBottom20]}>
                  <Text
                    style={[
                      styles.font11,
                      styles.boldText,
                      getBaggageInfo(leg) === '' ? styles.hidden : null,
                    ]}
                  >
                    {baggageAllowanceText}
                  </Text>
                  <Text style={[styles.font11]}>{getBaggageInfo(leg)}</Text>
                </View>
                <View>
                  <Text style={[styles.font12, styles.boldText]}>
                    {' '}
                    {leg.taname}
                    <Text style={styles.LightText}>{getTerminalData(leg.ttno, terminalText)} </Text>
                  </Text>
                  <Text style={[styles.font12, styles.boldText]}>
                    {' '}
                    {leg.tcc}, <Text style={styles.LightText}>{leg.tc}</Text>
                  </Text>
                </View>
              </View>
              <View style={styles.fltInfoCircleBottom} />
            </View>
          </View>
        </View>,
      );
    });
    return legViewLst;
  };
  props.flightInfoData.forEach((journey) => {
    const { dateChangePolicyCard, cancellationPolicyCard } = journey?.policy || {};
    jrnyVwLst.push(
      <View>
        <View style={styles.FlightInfoTopSection}>
          <View style={[styles.flexRow, styles.spaceBetween]}>
            <View>
              <View style={styles.flexRow}>
                <Text style={styles.FltDestTxt}>{journey.fromCity}</Text>
                <Image style={styles.BlackArrow} source={BlackArrow} />
                <Text style={styles.FltDestTxt}>{journey.toCity}</Text>
              </View>
              <Text style={styles.FltDate}>
                {journey.depDate}
              </Text>
            </View>
            <View>
              <Text style={styles.FltTime}>{journey.duration}</Text>
              <Text style={styles.FltStop}>
                {journey.stopText}
              </Text>
            </View>
          </View>
        </View>
        {getLegViewCard(journey.legs)}
        {!!cancellationPolicyCard && (
          <PolicyCard
            cardData={cancellationPolicyCard}
          />
        )}
        {!!dateChangePolicyCard && (
          <PolicyCard
            cardData={dateChangePolicyCard}
          />
        )}
      </View>,
    );
  });

  return <View>{jrnyVwLst}</View>;
};

FlightInfoDtls.propTypes = {
  dateChangeReviewData: PropTypes.object.isRequired,
};

export default FlightInfoDtls;
