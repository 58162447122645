import React from 'react';
import { View, Text, Image } from 'react-native';
import styles from './TrackerCss';
import BasePage from '../../../../../Common/PostSalesBasePage';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { YELLOW_TICK_ICON, GREY_CROSS_ICON } from '../../../../FlightImageConstants';
import { getFont } from '../../../../../PostSalesStyles';

class Tracker extends BasePage {
  render() {
    const fonts = getFont(true);
    const borderTopStyle = this.props.borderTopActive ? [styles.borderTopActive] : '';
    const defaultActiveStyle = [styles.defaultActiveStyle];
    this.calculateCompletedSteps(this.props.progressList, defaultActiveStyle);
    const noOfSteps = this.props.progressList.length;
    return (
      <View style={borderTopStyle}>
        <View style={styles.trackerContainer}>
          <View style={[styles.progressLine, noOfSteps > 5 ? { height: '95%' } : null]}>
            <View style={defaultActiveStyle} />
          </View>
          <View style={styles.trackerDtls}>
            {this.getProgressStepViews(this.props.progressList, fonts)}
          </View>
        </View>
      </View>
    );
  }

  calculateCompletedSteps(progressList, defaultActiveStyle) {
    progressList.forEach((progressItem, index) => {
      if (progressItem.completed) {
        if (index === 0) {
          defaultActiveStyle.push(styles.stepOneLineActive);
        } else if (index === 1) {
          defaultActiveStyle.push(styles.stepTwoLineActive);
        } else if (index === progressList.length - 1) {
          defaultActiveStyle.push(styles.stepThreeLineActive);
        }
      }
    });
  }

  getProgressStepViews(progressList, fonts) {
    const noOfSteps = progressList.length;
    return progressList.map((progressStep, index) =>
      Tracker.getProgressStepView(progressStep, index, noOfSteps, fonts),
    );
  }

  static getProgressStepView(progressStep, index, noOfSteps, fonts) {
    const { fsStyle } = getFont(true);
    const parentStyle = [];
    parentStyle.push(styles.trackerInnerDtls);
    if (index === noOfSteps - 1) {
      parentStyle.push(styles.lastItem);
    }
    return (
      <View style={parentStyle}>
        <View style={styles.countWrapper}>
          {progressStep.completed || progressStep.cancelled ? (
            <Image
              style={styles.tickIcon}
              source={progressStep.completed ? YELLOW_TICK_ICON : GREY_CROSS_ICON}
            />
          ) : (
            <Text style={[styles.countTxt, fonts.boldFontFamily]}>{index + 1}</Text>
          )}
        </View>
        <View>
          <Text
            style={[
              styles.trackerStatus,
              AtomicCss.alignLeft,
              fonts.boldFontFamily,
              fsStyle.font13,
            ]}
          >
            {progressStep.heading}
          </Text>
          <Text
            style={[
              styles.trackerStatusTime,
              AtomicCss.alignLeft,
              fonts.regularFontFamily,
              fsStyle.font12,
            ]}
          >
            {progressStep.description}
          </Text>
        </View>
      </View>
    );
  }
}

export default Tracker;
