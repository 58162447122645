import React from "react";
import { appendUrlPrefix } from "../../webUtils";
import loadable from "@loadable/component";
import ProgressView from "../../components/ProgressView";

const TM_INSURANCE_PATHS = {
  TM_INSURANCE_BOOKING_DETAIL: {
    pathName: appendUrlPrefix("/Insurance"),
    pageKey: "tmInsuranceBookingDetail",
    Component: loadable(
      () => import("@rn/apps/post-sales/src/Insurance/pages/InsuranceDetail"),
      { fallback: <ProgressView /> }
    ),
  },
  TM_INSURANCE_EDIT_TRAVELLER: {
    pathName: appendUrlPrefix("/editTraveller"),
    pageKey: "tmInsuranceEditTraveller",
    Component: loadable(
      () =>
        import(
          "@rn/apps/post-sales/src/Insurance/pages/InsuranceDetail/components/EditTravellerDetails"
        ),
      { fallback: <ProgressView /> }
    ),
  },
  TM_INSURANCE_EDIT_PASSPORT: {
    pathName: appendUrlPrefix("/editPassportDetails"),
    pageKey: "tmInsurancePassport",
    Component: loadable(
      () =>
        import(
          "@rn/apps/post-sales/src/Insurance/pages/InsuranceDetail/components/PassportPopUp"
        ),
      { fallback: <ProgressView /> }
    ),
  },
  TM_INSURANCE_DOWNLOAD_POLICY: {
    pathName: appendUrlPrefix("/downloadPolicy"),
    pageKey: "tmInsuranceDownloadPolicy",
    Component: loadable(
      () =>
        import(
          "@rn/apps/post-sales/src/Insurance/pages/InsuranceDetail/components/DownloadPolicyPopUp"
        ),
      { fallback: <ProgressView /> }
    ),
  },
};

export default TM_INSURANCE_PATHS;
