import { StyleSheet, Dimensions, Platform } from 'react-native';
import { colors, fontSizes } from '@mmt/legacy-commons/Styles/globalStyles';

const windH = Dimensions.get('window').height;

const styles = StyleSheet.create({
  mainWrapper: {
    backgroundColor: colors.lightBlue12,
    flex: 1,
  },
  summaryContainer: {
    backgroundColor: colors.lightBlue12,
    flex: 1,
  },
  sectionHeader: {
    paddingHorizontal: 12,
    paddingVertical: 14,
    color: colors.defaultTextColor,
    backgroundColor: colors.lightBlue12,
    textAlign: 'left',
  },
  vaccineSectionHeader: {
    paddingLeft: 15,
    paddingRight: 35,
    paddingTop: 5,
    paddingBottom: 7,
    color: colors.red17,
    backgroundColor: colors.pink2,
  },
  whiteCard: {
    flex: 1,
    marginHorizontal: 54,
    alignItems: 'center',
    justifyContent: 'center',
  },
  ImgStyle: {
    width: 120,
    height: 120,
  },
  description: {
    textAlign: 'center',
    marginHorizontal: 32,
    marginTop: 8,
    fontSize: fontSizes.md,
    color: colors.darkGrey,
  },
  primaryCta: {
    width: undefined,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 32,
    marginBottom: 32,
    marginHorizontal: 100,
  },
  errorStateWraper: {
    alignItems: 'center',
    padding: 20,
    justifyContent: 'center',
    width: '100%',
  },
  errorStateTxt: {
    textAlign: 'center',
    color: colors.defaultTextColor,
    marginBottom: 4,
    marginTop: 10,
  },
  errorStateSubTxt: {
    textAlign: 'center',
    color: colors.defaultTextColor,
  },
  buttonWrapper: {
    padding: 15,
    width: 220,
    alignSelf: 'center',
  },
  errorImgWrapper: {
    width: 120,
    height: 120,
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 20,
  },
  errorImgStyle: {
    width: 54,
    height: 54,
  },
  errorImgLrgStyle: {
    width: 82,
    height: 90,
  },
  emptyWrapper: {
    backgroundColor: colors.grayBg,
    flex: 1,
  },
  topStatusCard: {
    paddingHorizontal: 15,
    paddingVertical: 7,
  },
  topStatusText: {
    color: colors.errorRed,
    lineHeight: 15,
  },
  emptyListWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '75%',
    textAlign: 'center',
    alignSelf: 'center',
    height: windH - 450,
  },
  emptyListIcon: {
    width: 81,
    height: 50,
    marginBottom: 16,
  },
  olderWrapper: {
    paddingHorizontal: 10,
    marginTop: 10,
    ...Platform.select({
      web: {
        backgroundColor: colors.lightBlue12,
      },
    }),
  },
  downArrow: {
    width: 9,
    height: 13,
    left: 10,
    transform: [
      {
        rotate: '90deg',
      },
    ],
  },
  activeStyle: {
    transform: [
      {
        rotate: '-90deg',
      },
    ],
  },
  recentCardHeading: {
    color: '#000000',
    paddingVertical: 12,
    paddingHorizontal: 10,
    backgroundColor: colors.lightBlue12,
  },
  recentMore: {
    padding: 15,
    backgroundColor: '#fff',
    marginVertical: 15,
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorScreenContainer: {
    backgroundColor: colors.grayBg,
    position: 'absolute',
    bottom: -2,
    width: '100%',
    height: '100%',
  },
  emptyScreenContainer: {
    backgroundColor: colors.grayBg,
    width: '100%',
    height: '100%',
  },
  loadMoreButtonWrapper: {
    paddingVertical: 12,
    justifyContent: 'center',
    alignItems: 'center',
  },
  shadowWrapper: {
    shadowColor: colors.black,
    shadowRadius: 2,
    shadowOpacity: 0.2,
  },
  plusIcon: { width: 20, height: 20 }
});

export default styles;
