import { StyleSheet } from 'react-native';
import {colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles';

const styles = StyleSheet.create({

    giftCardBottomOverlay:{
        height:'auto',
        /*paddingTop: 10,*/
        paddingHorizontal:16,
        paddingBottom:20,
        margin:-20,
        paddingTop:25,
        backgroundColor:'#fff',
        borderTopLeftRadius:4,
        borderTopRightRadius:4
    },
    title:{
        fontSize:22,
        fontFamily: fonts.black,
        lineHeight:26,
        color: colors.black,
        marginBottom:4
    },
    subTitle:{
        fontSize:12,
        fontFamily: fonts.regular,
        lineHeight:16,
        color: colors.defaultTextColor,
        marginBottom:18
    },
    textInputWrapper:{
        borderWidth:1,
        borderColor: colors.azure,
        borderRadius:4,
        paddingHorizontal:10,
        flexDirection:'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom:54
    },
    textInputLabel: {
        height: 40,
        borderRadius: 4,
        color: '#000000',
        fontSize: 16,
        fontFamily: fonts.bold,
        width: '100%',
        marginRight:12,
        marginTop:4,
        backgroundColor: 'transparent'
    },
    buttonWrapper:{
        alignSelf:'flex-end',
    },
    editTextLabel: {
        position: 'absolute',
        right: 20,
        zIndex: -1
    }
});

export default styles;
