import React,{useCallback} from 'react';
import Actions from '../../navigation/postSalesNavigation';
import {View, Text, DeviceEventEmitter} from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import BaseButton, { BUTTON_VARIANT } from '../BaseButton';
import { EventEmitterConst } from '../../flights/modules/details/FlightBookingDetailsConstant';
import { getUserProfileType, getUserCountryCode } from '@mmt/legacy-commons/AppState/AppUserConfig';
import {  PROFILE_TYPE } from '@mmt/legacy-commons/Common/constants/AppConstants';
import { getCommonHeaders } from '@mmt/legacy-commons/Helpers/genericHelper';
import { setWebViewAuth } from '../../utils/PostSaleUtil';

const WhatNextCard = React.memo(({heading, description, action, descriptionCTA, trackRefundEvent}) => {

    const actionHandler = useCallback (async(action) => {
        trackRefundEvent && trackRefundEvent(action.type);
        if (action && action.url) {
          if (action.type === 'DESCRIPTION_CTA'){
            DeviceEventEmitter.emit(EventEmitterConst.CTA_CLICKED_EVENT);
            const isCorp = getUserProfileType() === PROFILE_TYPE.BUSINESS;
            let url = action.url;
            if (url.includes('?')) {
              url += `&region=${getUserCountryCode()}`;
            } else {
              url += `?region=${getUserCountryCode()}`;
            }
            if (isCorp) {
              url += '&isCorporate=true';
            }
            GenericModule.openDeepLink(url);
          }
          else {
            try {
              headers = await getCommonHeaders();
            } catch (e) {
              headers = {}
            }
            Actions.openPsWebView({
              headerText: action.title,
              injectedJavaScript: setWebViewAuth(headers['mmt-auth']),
              headerIcon: require('@mmt/legacy-assets/src/back-white.webp'),
               url: action.url,
            })
          }
        }
      },[])
    return (
        <React.Fragment>
            <View style={styles.whatNextCard}>
                <Text style={[AtomicCss.defaultText, AtomicCss.boldFont, AtomicCss.font14, AtomicCss.marginBottom5]}>{heading}</Text>
                <Text style={[AtomicCss.defaultText, AtomicCss.regularFont, AtomicCss.font13]}>{description}
                {descriptionCTA
                   && (
                   <Text
                     onPress={() => actionHandler(descriptionCTA)}
                     style={[AtomicCss.boldFont, AtomicCss.font15, AtomicCss.blueText]}
                   >
                     {` ${descriptionCTA.title}`}
                   </Text>
                   )
                  }
                </Text>
            </View>
            {action && <View style={styles.btnWrapper}>
              <BaseButton
              text={action.title}
              variant={BUTTON_VARIANT.CAPSULE}
              clickHandler={() => actionHandler(action)}
              shadow
              />
            </View>}
        </React.Fragment>
    )
})

const styles = {
    whatNextCard:{
        borderTopWidth:1,
        borderTopColor:'#e7e7e7',
        paddingVertical: 15,
        marginTop: 20,
    },
    btnWrapper:{
      marginTop: 5,
      paddingRight:40
    }
}

export default WhatNextCard;