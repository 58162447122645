export const PAYMENT_EVENT_LISTNER: string = 'flight_booking_details_ancillary_thankyou_page';
export const FLIGHT_ACTION_IDS: Record<string, string>  = {
    FULL_CANCELLATION: 'CANCEL_FLT_FULL',
    CANCEL_HOLD_BOOKING: 'CANCEL_HOLD_BOOKING',
    PARTIAL_CANCELLATION: 'CANCEL_FLT_PARTIAL',
    CANCELATION_POLICY: 'CANCEL_CHRGS',
    CHANGE_DATE: 'CHANGE_DATE',
    ACT_RES_FLIGHT: 'ACT_RES_FLIGHT',
    ACT_RES_CHARGES: 'ACT_RES_CHARGES',
    NAME_CHANGE: 'NAME_CHANGE',
    FLT_CANCELLED_WITH_AIRLINE: 'FLT_CANCELLED_WITH_AIRLINE',
    FLT_CANCELLED: 'FLT_CANCELLED',
    MEDICAL_EMERGENCY: 'MEDICAL_EMERGENCY',
    FLT_RESCHEDULED: 'FLT_RESCHEDULED',
    ADD_MEAL: 'ADD_MEAL',
    ADD_SEAT: 'ADD_SEAT',
    ADD_BAGGAGE: 'ADD_BAGGAGE',
    IMPT_INFO_LINK: 'IMPT_INFO_LINK_CLICK',
    TRACK_ISSUE: 'TRACK_ISSUE',
    PARTIAL_CANCELLATION_SEGGRP: 'PARTIAL_CANCELLATION_SEGGRP',
    DOWNLOAD_INVOICE: 'DOWNLOAD_INVOICE',
    BOOKING_INVOICE: 'BOOKING_INVOICE',
    SPECIAL_CLAIM: 'SPECIAL_CLAIM',
    SPECIAL_CLAIM_TEXT: 'SPECIAL_CLAIM_TEXT',
    EXPECTING_REFUND: 'EXPECTING_REFUND',
    BACK: 'BACK',
    OPEN_DETAILED_PAGE: 'OPEN_TRIP_DETAIL_PAGE',
    ADD_TO_GOOGLE_WALLET: 'ADD_TO_GOOGLE_WALLET',
    ADD_TO_PASSBOOK: 'ADD_TO_PASSBOOK',
    OPEN_VISA_TNC_PAGE: 'OPEN_VISA_TNC_PAGE',
    OPEN_VISA_CLAIM_REFUND: 'OPEN_VISA_CLAIM_REFUND',
    TRACK_REFUND: 'TRACK_REFUND',
    CLOSE_BOTTOMSHEET: 'CLOSE_BOTTOMSHEET',
    TRAVEL_INSURANCE_BUY: 'TRAVEL_INSURANCE_BUY',
    NEW_INSURANCE_PRODUCT_BUY: 'NEW_INSURANCE_PRODUCT_BUY',
    PART_PAYMENT_COMPLETE: 'PART_PAYMENT_COMPLETE',
    TRACK_ISSUE_REFUND: 'TRACK_ISSUE_REFUND',
    WHEEL_CHAIR: 'ADD_WHEELCHAIR',
    WEB_CHECKIN: 'WEB_CHECKIN',
    LOAD_OLD_FLOW: 'LOAD_OLD_FLOW',
    REFRESH_PAGE: 'REFRESH_PAGE',
    LOAD_TRACKING: 'LOAD_TRACKING',
    CLICK_TRACKING: 'CLICK_TRACKING',
    ERROR_TRACKING: 'ERROR_TRACKING',
    ERROR: 'ERROR',
    HOLD_BOOKING_TNC: 'HOLD_BOOKING_TNC',
    DOWNLOAD_ETICKET: 'DOWNLOAD_ETICKET',
    BOARDING_PASS: 'BOARDING_PASS',
    DOWNLOAD_INVOICE_POPUP: 'DOWNLOAD_INVOICE_POPUP',
    SHOW_SHORT_TOAST: 'SHOW_SHORT_TOAST',
    VIEW_VISA_DETAILS: 'VIEW_VISA_DETAILS',
    UPDATE_CAB_DETAILS: 'UPDATE_CAB_DETAILS',
    VIEW_CAB_DETAILS: 'VIEW_CAB_DETAILS',
    SEARCH_MORE_FLIGHT: 'SEARCH_MORE_FLIGHT',
    BOOK_ANOTHER_FLIGHT: 'BOOK_ANOTHER_FLIGHT',
    PRICE_LOCK_DEEPLINK: 'PRICE_LOCK_DEEPLINK',
    TRANSFER_REFUND: 'TRANSFER_REFUND',
    VIEW_REFUND_DELAYED: 'VIEW_REFUND_DELAYED',
    VIEW_REFUND_BREAKUP: 'VIEW_REFUND_BREAKUP',
    REFUND_OUT_OF_TAT_FORM: 'refundOutOfTATForm',
    VPG_CLAIM_REFUND: 'VPG_CLAIM_REFUND',
    VPG_RESUBMIT_CLAIM: 'VPG_RESUBMIT_CLAIM',
    KNOW_MORE_ABOUT_POLICY: 'KNOW_MORE_ABOUT_POLICY',
    FILE_INSURANCE_CLAIM: 'FILE_INSURANCE_CLAIM',
    READ_POLICY_TNC: 'READ_POLICY_TNC',
    VIEW_PROTECTION_DETAILS: 'VIEW_PROTECTION_DETAILS',
    TM_INSURANCE_ROUTE: 'TM_INSURANCE_ROUTE',
    CROSS_SELL: 'CROSS_SELL',
    TRAVEL_INSURANCE_POPUP: 'TravelInsurancePopup',
    CHAT_BOT_EVENT: 'CHAT_BOT_EVENT',
    OPEN_FLIGHT_DETAIL: 'OPEN_FLIGHT_DETAIL',
    FAST_FORWARD_BUY: 'FAST_FORWARD_BUY',
    AIRPORT_SERVICE_BUY: 'AIRPORT_SERVICE_BUY',
    PROVIDE_BANK_DETAILS: 'PROVIDE_BANK_DETAILS',
    CALL_NOW_CLICK: 'CALL_NOW_CLICK',
    COD_TNC: 'VIEW_COD_T&C',
    INSURANCE_PRODUCT_VIEW_TNC : 'INSURANCE_PRODUCT_VIEW_TNC',
    LOUNGE_ACCESS_QR: 'LOUNGE_ACCESS_QR',
    FOOD_BEVERAGE_QR: 'FOOD_BEVERAGE_QR',
};

export const ACTION_ID_ADDONS_TYPE_MAPPING: Record<string, string>  = {
    ADD_MEAL: 'MEALS',
    ADD_SEAT: 'SEATS',
    ADD_BAGGAGE: 'BAGGAGE',
};

export const ACTION_ID_PAGE_KEY_MAPPING: Record<string, string>  = {
    ADD_MEAL: 'selectAddons',
    ADD_SEAT: 'selectSeats',
    ADD_BAGGAGE: 'selectAddons'
};


export const PAGE_REQUESTED: Record<string, string> = {
    FLIGHT_DETAILS: '',
    REFUND_TRACKER: 'REFUND_TRACKER',
    SELLING: 'SELLING_PAGE',
}

export const PAGE_NAME: Record<string, string> = {
    SELLING: 'sellingPageDetails',
    REFUND_TRACKER: 'refundTracker',
    FLIGHT_DETAILS: 'flightDetails',
}