import React from 'react';
import { View, StyleSheet, TouchableOpacity, Text } from 'react-native';
import noop from 'lodash/noop';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import BookingStatusHeading from '../BookingStatusHeading';
import Header from '../Header';
import MoreInfoDetails from '../MoreInfoDetails';
import { useTheme } from '../../../../../theme/theme.context';
import createStyles from './TopStatusStyles';
import { handleClickEvent } from '../../../../utils/CabBookingDetailUtil';
import CabBookingDetailsConstant from '../../../../CabBookingDetailsConstant';
import { cardProps, TopStatusCardProps } from '../../../../types';
import { getTopStatusWrapper } from '../../utils/cssUtil';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import styles from '../../../../components/CabMamiCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getTopStatusStyle } from '../../utils/cssUtil';

const TopStatusCard: React.FC<TopStatusCardProps> = (props) => {
  const { psTheme: theme } = useTheme();
  const { fsStyle, ...fonts } = getFont(true);
  const topStatusStyles = createStyles(theme, fonts);
  const {
    card,
    addCard = noop,
    response,
    onBackPress = noop,
    cardData = {},
    updateCarDetails,
    onConfirmBooking,
    pageName,
    appHeight,
  } = props;
  const { subCardList, heading, subheading, cardId, desc } = card || {};
  const { bookingState } = response || {};
  const { state } = bookingState || {};
  const views: any[] = [];
  !isEmpty(subCardList) && Array.isArray(subCardList) &&
    subCardList.map((item: cardProps) => {
      item.isVisible && views.push(addCard(item, [], true));
    });

  const { addressPendingActionList, longTailCancelActionList, downloadAction } = cardData;
  const { updateTripDetailAction } = addressPendingActionList || {};
  const longTailCancelAction = !isEmpty(longTailCancelActionList) && longTailCancelActionList[0];
  const topStatusStyleProps = getTopStatusStyle(
    cardId,
    topStatusStyles,
    false,
    theme.color,
    props?.showCabTrackerOverlay,
  );
  const { headerBg, statusStyleColor } = topStatusStyleProps || {};

  const onDownloadIconPress = () => {
    handleClickEvent(card, downloadAction, response, pageName);
  };

  const isCompleted =
    card.cardId === CabBookingDetailsConstant.TOP_STATUS_CARD_COMPLETTION_OF_TRIP_TO_FOREVER;

  return (
    <View
      style={[
        { ...headerBg },
        !props.showCabTrackerOverlay && { ...AtomicCss.marginBottom10 },
        { height: props.showCabTrackerOverlay ? appHeight : 'auto' },
      ]}
    >
      {!props.showCabTrackerOverlay && (
        <View style={topStatusStyles.headerWrapper}>
          <Header
            showDownloadIcon={!isEmpty(downloadAction)}
            headerRtSection={true}
            cardId={card.cardId}
            handleClickEvent={onDownloadIconPress}
            goBack={onBackPress}
            topStatusStyleProps={topStatusStyleProps}
            isCompleted={isCompleted}
          />
        </View>
      )}
      {
        <View
          style={[
            props.showCabTrackerOverlay
              ? topStatusStyles.topWrapperExpanded
              : topStatusStyles.topWrapper,
            headerBg,
          ]}
        >
          {!props.showCabTrackerOverlay && (
            <BookingStatusHeading
              statusStyleColor={statusStyleColor}
              title={heading}
              subTitle={desc}
            />
          )}
          {!isEmpty(updateTripDetailAction) && updateTripDetailAction.actionLabeltext && (
            <View style={[AtomicCss.marginTop20, AtomicCss.marginBottom26]}>
              <TouchableOpacity onPress={updateCarDetails}>
                <Text
                  style={[
                    AtomicCss.azure,
                    AtomicCss.font14,
                    AtomicCss.blackFont,
                    AtomicCss.textCenter,
                  ]}
                >
                  {updateTripDetailAction.actionLabeltext}
                </Text>
              </TouchableOpacity>
            </View>
          )}
          {!isEmpty(views) && views}
          {!props.showCabTrackerOverlay && (
            <MoreInfoDetails statusStyleColor={statusStyleColor} subTitle={subheading} />
          )}
          {!isEmpty(longTailCancelAction) && longTailCancelAction.actionLabeltext && (
            <View style={[AtomicCss.marginTop10, AtomicCss.marginBottom10]}>
              <TouchableOpacity onPress={() => onConfirmBooking(longTailCancelAction)}>
                <Text
                  style={[
                    AtomicCss.azure,
                    AtomicCss.font14,
                    AtomicCss.blackFont,
                    AtomicCss.textCenter,
                  ]}
                >
                  {longTailCancelAction.actionLabeltext}
                </Text>
              </TouchableOpacity>
            </View>
          )}
        </View>
      }
    </View>
  );
};

export default TopStatusCard;
