import React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { useTheme } from '../../../../theme/theme.context';
import createStyle from './topPickupCardCss';

function TopPickUpCard({ title, subTitle, type, rightAction = null }) {
  const { psTheme: theme } = useTheme();
  const styles = createStyle(theme);
  return (
    <View style={[styles.topCardBg]}>
      <View>
        {!!title && (
          <Text
            style={[
              AtomicCss.font12,
              AtomicCss.defaultText,
              AtomicCss.regularFont,
              styles.upperText,
              AtomicCss.marginBottom3,
            ]}
          >
            {title}
          </Text>
        )}
        <Text style={[AtomicCss.font14, AtomicCss.blackFont, AtomicCss.blackText]}>{subTitle}</Text>
      </View>
      {rightAction && !!rightAction.text && (
        <TouchableOpacity onPress={rightAction.onPress}>
            <Text style={[AtomicCss.font14, AtomicCss.blackFont, AtomicCss.azure]}>
              {rightAction.text}
            </Text>
        </TouchableOpacity>
      )}
    </View>
  );
}
export default TopPickUpCard;
