import {Image, StyleSheet, Text, TouchableHighlight, View, Platform} from "react-native";
import React from "react";
import {colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import {errorIcon} from "../../bus/BusImageConstants";
import handleClickEvent, {getActionButton, getButtonStyle} from "../commonUtil";
import _ from 'lodash';
import { getFont } from '../../PostSalesStyles';

const AdvisoryNotice = ({heading,description, card, togglePopUp, langAware = false}) => {
    const fonts = getFont(langAware);
    const handleClick = (action) => {
        if(action.actionId === 'ReadMore' || action.actionFamily === 'ReadMore'){
            togglePopUp();
        }
    };

return(
    <View style={[styles.MamiCard, styles.mar10Bt]}>
        <View style={styles.CardContent}>
            <Image style={styles.travellersIcon} source={errorIcon} />
            <View style={styles.ContentPortion}>
                <Text style={[styles.BlackText, fonts.blackFontFamily, fonts.font14, styles.mar20Bt, styles.PolicyHeadingText]}>
                    {heading}
                </Text>
                <View style={[styles.mar15Bt]}>
                    <Text style={[styles.BlackText, fonts.boldFontFamily, fonts.font14, styles.mar10Bt]}>
                        {description}
                    </Text>
                </View>
            </View>
        </View>
        <View style={!_.isEmpty(card.actionList) ? [getButtonStyle(card.actionList), styles.transperantBg] : styles.hideView}>
           {getActionButton(card, null, handleClick)}
        </View>
    </View>
)
}
export  default AdvisoryNotice;
const styles = StyleSheet.create({
    overlayBg: {
        backgroundColor: 'rgba(0,0,0,.5)',
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        zIndex: 100
    },
    overlayContainer: {
        justifyContent: 'flex-end',
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        zIndex: 1000,
        elevation: 3,
        backgroundColor: '#31a498'
    },
    MamiCard: {
        backgroundColor: '#fff'
    },
    mar10Bt: {
        marginBottom: 10
    },
    mar10Lf : {
       marginLeft: 10
    },
    CardContent: {
        backgroundColor: '#fff',
        borderBottomWidth: 1,
        borderBottomColor: '#e4e4e4',
        flexDirection: 'row',
        padding: 15,
        elevation: 1,
    },
    BlackText: {
        color: colors.black
    },
    blackFont: {
        fontFamily: fonts.black
    },
    mar20Bt: {
        marginBottom: 20
    },
    PolicyHeadingText: {
        paddingTop: 3
    },
    mar15Bt: {
        marginBottom: 15
    },
    travellersIcon: {
        width: 24,
        height: 24,
        marginRight: 10
    },
    transperantBg: {
        ...Platform.select({
            ios: {
                backgroundColor: 'transparent'
            }
        })
    },
    hideView: {
        display: 'none'
    },
    ContentPortion: {
        flex: 1
    },
});

