import React from 'react';
import {View, Text, TouchableOpacity} from 'react-native';
import PropTypes from 'prop-types';
import styles from './OverlayMessageCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getFont } from '../../PostSalesStyles';
import isEmpty from 'lodash/isEmpty';
import PostSalesBasePage from '../PostSalesBasePage';

export default class OverlayMessage extends PostSalesBasePage {
  render() {
    const fonts = getFont(true);
    const {wrapperStyle = {}} = this.props;
    return (
      <View style={[styles.overlayWrapper, wrapperStyle ]}>
        <TouchableOpacity
          onPress={() => this.props.popUpOutsideClick()}
          activeOpacity={1}
          style={styles.overlay}
        />
        <View style={styles.overlayContent}>
          <View>
            <Text style={[styles.contentTitle, fonts.lightFontFamily, fonts.font27, AtomicCss.lineHeight38]}>{this.props.title}</Text>
              <Text style={[styles.contentDesc, fonts.regularFontFamily, fonts.font14]}>{this.props.content}
            </Text>
            <View style={styles.contentFooter}>
              {!isEmpty(this.props.leftBtnTxt) && (
                <TouchableOpacity style={styles.actionBtn} onPress={() => this.props.leftBtnClick()}>
                  <Text style={[styles.actionBtnText, fonts.font16, fonts.mediumFontFamily]}>{this.props.leftBtnTxt}</Text>
                </TouchableOpacity>
              )}
              {!isEmpty(this.props.rightBtnTxt) && (
                <TouchableOpacity style={styles.actionBtn} onPress={() => this.props.rightBtnClick()}>
                  <Text style={[styles.actionBtnText, fonts.boldFontFamily, fonts.font16]}>{this.props.rightBtnTxt}</Text>
                </TouchableOpacity>
              )}
            </View>
          </View>
        </View>
      </View>

    );
  }
}

OverlayMessage.propTypes = {
  popUpOutsideClick: PropTypes.func.isRequired,
  leftBtnClick: PropTypes.func.isRequired,
  rightBtnClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  leftBtnTxt: PropTypes.string.isRequired,
  rightBtnTxt: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired
};
