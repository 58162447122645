import React, { useCallback } from 'react';
import { useFocusEffect } from '@react-navigation/native';

const TimerComponent = ({ defaultTime = 45, seTimeSpentMoreThanSpecified }) => {
  // useFocusEffect(
  //   useCallback(() => {
  //     const timerId = setTimeout(() => {
  //       seTimeSpentMoreThanSpecified(true);
  //     }, defaultTime * 1000);
  //     return () => {
  //       if (timerId) {
  //         clearTimeout(timerId);
  //       }
  //     };
  //   }, [defaultTime, seTimeSpentMoreThanSpecified]),
  // );
  return null;
};

export default TimerComponent;
