import React, {useEffect} from 'react';
import {View, Text, Image, BackHandler} from 'react-native';
import PropTypes from 'prop-types';
import {isEmpty} from 'lodash';
import styles from './RequestSubmittedCss';
import Buttons from '@mmt/legacy-commons/Common/Components/Buttons';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {resetToFlightBookingDetail} from '../../flights/FlightBookingUtil';
import BaseButton, { BUTTON_VARIANT } from '../BaseButton';
import { LOBNAMES } from '../../PostSalesConstant';
import { resetToRailBookingDetails } from '../../Rails/details/RailBookingDetailsUtil';

/**
 * This page is developed from zeplin https://zpl.io/2pJRMdo
 * @param icon image url like require(path of image)
 * @param iconStyle style for image like height and width as an object
 * @param title Date Change Request submitted
 * @param descriptionList description text list
 * @param btnTitle text of button
 * @param bookingId booking id of flight Lob to redirect to once user clicks on button
 */
const SubmitRequestThankYouPage = ({
  icon, iconStyle, title, descriptionList, btnTitle, bookingId, lob, btnStyle={}, textStyle={}
}) => {
  const onBackPressHandler = () => {
    switch(lob){
      case LOBNAMES.RAIL:
        resetToRailBookingDetails(bookingId);
        break;
      case LOBNAMES.FLIGHT:
        resetToFlightBookingDetail(bookingId);
        break;
    }
    return true;
  };

  useEffect(() => {
    BackHandler.addEventListener('hardwareBackPress', onBackPressHandler);
    return () => {
      BackHandler.removeEventListener('hardwareBackPress', onBackPressHandler);
    };
  }, [onBackPressHandler]);
  return (
    <View style={styles.pageWrapper}>
      <View style={styles.messageContent}>
        <Image style={iconStyle} source={icon} />
        <Text style={[styles.messageTitle,AtomicCss.marginBottom24, textStyle]}>
          {title}
        </Text>
        {!isEmpty(descriptionList) && descriptionList.map((description, index) => (
          <Text style={[styles.messageDesc, index !== descriptionList.length - 1 ? AtomicCss.marginBottom10 : '']}>
            {description}
          </Text>
              ))}
      </View>
      <View style={[styles.footerBtnWrapper, btnStyle]}>
        <BaseButton 
        text={btnTitle}
        variant={BUTTON_VARIANT.CAPSULE}
        clickHandler={onBackPressHandler}
        shadow
        />
      </View>
    </View>
  );
};

export default SubmitRequestThankYouPage;

SubmitRequestThankYouPage.propTypes = {
  title: PropTypes.string.isRequired,
  descriptionList: PropTypes.arrayOf(PropTypes.string).isRequired,
  icon: PropTypes.oneOfType([
    PropTypes.shape({
      uri: PropTypes.string,
      headers: PropTypes.objectOf(PropTypes.string)
    }),
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.shape({
      uri: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
      headers: PropTypes.objectOf(PropTypes.string)
    }))
  ]).isRequired,
  iconStyle: PropTypes.object.isRequired,
  btnTitle: PropTypes.string.isRequired,
  bookingId: PropTypes.string.isRequired
};
