import React from 'react';
import {View, Image, Text} from 'react-native';
import AtomicCss from '../../../bus/commonStyles/AtomicCss';
import styles from '../../styles/CabMamiCss';
import {getActionButton, getButtonStyle, isNotNullAndEmpty, handleClickEvent} from '../../utils/CabBookingDetailUtil';
import {refundIcon} from "../../CabImageConstants";

class SupportCard extends React.Component
{
  handleClick = (action) => {
    handleClickEvent(this.props.card, action, this.props.response, this.props.pageName);
  };
  render(){
    return(
      <View style={[styles.MamiCard,styles.marginVertical20, styles.mar10Bt]}>
        <View style={styles.CardContent}>
          <View style={styles.flexRow}>
            <Image style={styles.CardIcon} source={refundIcon} />
            <View style={styles.description}>
              <Text style={[styles.blackFont, styles.BlackText, AtomicCss.marginBottom15, styles.font14]}>{this.props.response.carSupportData.scTitle}</Text>
              <Text style={[AtomicCss.flex1, AtomicCss.regularFont, AtomicCss.font14, AtomicCss.defaultText, AtomicCss.lineHeight20]}>
                {this.props.response.carSupportData.scDescription}
              </Text>
            </View>
          </View>
        </View>
        <View
          style={isNotNullAndEmpty(this.props.card.actionList) ? [getButtonStyle(this.props.card.actionList), styles.transperantBg] : styles.hideView}
        >
          {getActionButton(this.props.card, this.props.response, this.handleClick)}
        </View>
      </View>
    )
  }
}

export default SupportCard;
