import React from 'react';
import {ScrollView, View, KeyboardAvoidingView, Platform} from 'react-native';
import styles from './GiftCardDetailsCss';
import {colors} from '@mmt/legacy-commons/Styles/globalStyles';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {
  getCommonHeaders,
  GIFTCARD_BOOKING_DETAILS_URL, RESEND_VOUCHER
} from '../utils/NetworkUtils';
import {isNetworkAvailable} from '@mmt/legacy-commons/Common/utils/AppUtils';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import GiftCardBookingDetailsConstant from './GiftCardBookingDetailsConstant';
import ProgressView from '@mmt/legacy-commons/Common/Components/Progress/ProgressView';
import SimpleHeader from '@mmt/legacy-commons/Common/Components/Header/SimpleHeader';
import NoInternetView from '@mmt/legacy-commons/Common/Components/Error/NoInternetView';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import BasePage from '../Common/PostSalesBasePage';
import AuthErrorView from '../Common/AuthError/AuthErrorView'
import StickyHeader from '../hotel/details/components/StickyHeader';
import TopStatusCard from './components/TopStatusCard';
import NeedHelpCard from './components/NeedHelpCard';
import CancellationPolicyCard from './components/CancellationPolicyCard';
import GiftCardPaymentCard from './components/GiftCardPaymentCard';
import GiftCardMainCard from './components/GiftCardMainCard';
import RefundTracker from '../Common/RefundTracker';
import GiftCardBookingTrackingHelper from './GiftCardBookingTrackerHelper';
import {getTypeOfGiftCard, isNotNullAndEmpty} from './GiftCardBookingDetailUtil';
import { BACK_ARROW_BLACK_ICON, TOP_STATUS_ICON_9, TICKET_FAIL_RED_ICON } from './GiftCardImageConstants';
import {showShortToast} from '@mmt/legacy-commons/Common/Components/Toast';
import Actions from '../navigation/postSalesNavigation';
import { backPressHandler, isLostBookingData } from '../utils/PostSaleUtil';
import { AUTH_FAILED_MESSAGE, LOBNAMES } from '../PostSalesConstant';
import OverlayMessage from './resendgiftcard/OverlayMessage';
import ResendGiftCardOverlay from './resendgiftcard/ResendGiftCardOverylay';
import {validateEmail} from '@mmt/legacy-commons/Common/Components/ContactDetails/contactDetails.utils';
import { REFUND_OOT_TRACKING } from '../Common/RefundOutOfTATForm/constants';
import LostIDBookingDetailPage from '../Common/LostIdDetailPage';
import addBookingTrackingHelper, { ADD_BOOKING_TRACKING_EVENTS } from '../summary/components/AddBooking/tracking';

const GIFTCARD_BOOKING_DETAILS_PAGE = 'giftCardBookingDetail';
const headerIcon = BACK_ARROW_BLACK_ICON;


class GiftCardBookingDetail extends BasePage {

  static navigationOptions = {
    header: null
  }

  constructor(props) {
    super(props, GIFTCARD_BOOKING_DETAILS_PAGE);
    this.bookingId = props.data? props.data.BOOKING_ID : (props.bookingId ? props.bookingId :'');
    this.pageData = props.data && props.data.pageData ? JSON.parse(props.data.pageData) : undefined;
    this.isLostBooking = isLostBookingData(this.pageData);
    this.mmtAuth = '';
    this.trackPageName = '';
    this.gcId = '';
    this.loggingTrackInfo = {};
    this.state = {
      responseJson: null,
      viewState: this.isLostBooking ? ViewState.SHOW_DETAIL : ViewState.LOADING,
      bottomOverlay: '',
      emailId: '',
    };
  }

  componentWillMount() {
    this.setState({
      responseJson: null,
      viewState: this.isLostBooking ? ViewState.SHOW_DETAIL : ViewState.LOADING,
    });
  }

  componentDidMount() {
    super.componentDidMount();
    if (!this.isLostBooking) {
      this.fetchJSONAsync(GIFTCARD_BOOKING_DETAILS_URL + this.bookingId);
    }

    if (this.pageData?.isAddBookingFlow) {
      addBookingTrackingHelper.trackLoadEvent(
        ADD_BOOKING_TRACKING_EVENTS.Addbooking_detailspage_landed,
        this.pageData?.uuid,
        this.bookingId,
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data && this.props.data.refresh === true) {
      if (this.isLostBooking) {
        this.setState({
          refreshLostDetail: {},
        });
        return;
      }
    }
  }

  handleResendVoucherOverlay =(recipientgcId) => {
    this.setState({
      bottomOverlay: GiftCardBookingDetailsConstant.RESEND_GIFT_CARD_OVERLAY,
      emailId:  null
    })
    this.gcId = recipientgcId;
  }

  handleBottomOverlay = (showOverlay) => {
    this.setState({
      bottomOverlay: showOverlay,
      viewState: ViewState.SHOW_DETAIL
    })
  }

  handleResendVoucher =() => {
    const emailValidation = validateEmail(this.state.emailId);
    if(!emailValidation.valid) {
      alert(GiftCardBookingDetailsConstant.ENTER_EMAIL_ID)
      return;
    }
    this.setState({
      viewState: ViewState.RESEND_VOUCHER
    })
    this.resendVoucherAsync();
  }

  handleEmailIdInput =  (emailId)  => {
    this.setState({emailId : emailId})
  }

  async resendVoucherAsync() {
    try {
      const hasNetwork = await isNetworkAvailable();
      if (!hasNetwork) {
        this.setState({viewState: ViewState.NO_INTERNET});
        return;
      }
      this.setState({ bottomOverlay:  ''});
      let request =  {
        bookingId: this.bookingId,
        email: this.state.emailId,
        gcId: this.gcId
      };
      let response = {};
      let body = {};
      const userInfo = await HotelBookingModule.getRequestHeader(this.bookingId, '');
      this.mmtAuth = userInfo.mmtAuth;
      this.loggingTrackInfo = userInfo.loggingTrackingInfo;

      const url = RESEND_VOUCHER;
      response = await fetch(url, {
          headers: await getCommonHeaders(this.mmtAuth, this.loggingTrackInfo),
          method: 'POST',
          body: JSON.stringify(request)
          });
      body = await response.json();
      if (response && response.ok && body && body.responseMessage) {
        showShortToast(body.responseMessage);
        this.trackLoadEvent();
      } else {
        showShortToast(GiftCardBookingDetailsConstant.RESEND_VOUCHER_FAILURE_TEXT);
      }
      this.setState({
        viewState: ViewState.SHOW_DETAIL
      });
    } catch (error) {
      this.trackError(error.message);
      this.setState({
        viewState: ViewState.SHOW_DETAIL
      });
    }
  }

  renderRefundCard = (response, card, cancellationRequestNoList, lostBooking = false) => {
    if (cancellationRequestNoList.length) {
      return <RefundTracker
        key="RefundTracker"
        cancelRequests={cancellationRequestNoList}
        bookingId={this.bookingId}
        card={card}
        bookingResponse={response}
        lobName={LOBNAMES.GIFT}
        pageName={REFUND_OOT_TRACKING.PAGES.GC}
        isLostBookingId={lostBooking}
      />
    }
    return null;
  }

  renderPage() {
    if (this.isLostBooking) {
      return (
        <LostIDBookingDetailPage
          bookingId={this.bookingId}
          pageData={this.pageData}
          lobName={LOBNAMES.GIFT}
          refreshLostDetail={this.state.refreshLostDetail}
          getRefundCard={({ response, canReqNoList }) => this.renderRefundCard(response, null, canReqNoList, true)}
        />
      );
    }
    let views = [];
    views = this.getMainPageViews();
    return (
      <View>
        <ScrollView
          style={styles.GreyBg}
          stickyHeaderIndices={[0]}
        >
          <StickyHeader
            iconSrc={headerIcon}
            headerColor={[colors.greyBookedSeat, colors.greyBookedSeat]}
            title={this.getTitle()}
            titleStyle={[styles.BlackText, styles.blackFont]}
            subTitle={this.getSubtitle()}
            subTitleStyle={[styles.BlackText, styles.font12]}
            onBackPress={this.goBack}
					/>
          {views}
        </ScrollView>
      </View>
    );
  }

  async fetchJSONAsync() {
    try {
      const hasNetwork = await isNetworkAvailable();
      if (!hasNetwork) {
        this.setState({viewState: ViewState.NO_INTERNET});
        return;
      }
      let response = {};
      let body = {};
      const userInfo = await HotelBookingModule.getRequestHeader(this.bookingId, 'GIFTCARD_BOOKING_DETAIL');
      this.mmtAuth = userInfo.mmtAuth;
      this.loggingTrackInfo = userInfo.loggingTrackingInfo;
      const url = GIFTCARD_BOOKING_DETAILS_URL + this.bookingId;
      response = await fetch(url, {headers: await getCommonHeaders(this.mmtAuth, this.loggingTrackInfo)});
      body = await response.json();
      if (response && response.ok && body) {
        this.trackPageName = `mob:customer support:giftcards: ${getTypeOfGiftCard(body)}`;
        this.setState({
          responseJson: body,
          viewState: ViewState.SHOW_DETAIL,
        });
        this.trackLoadEvent();
      } else {
        if (body && body?.message && body.message.toUpperCase() === AUTH_FAILED_MESSAGE) {
          this.setState({ viewState: ViewState.AUTH_ERROR });
        } else {
          this.setState({viewState: ViewState.ERROR});
          this.trackError(response.status);
        }
      }
    } catch (error) {
      console.log('response error', error);
      this.setState({viewState: ViewState.ERROR});
      this.trackError(error.message);
      console.log(error);
    }
  }

  trackLoadEvent() {
    GiftCardBookingTrackingHelper.trackLoadEvent(this.trackPageName, this.state.responseJson);
  }

  getMainPageViews() {
    const views = [];
    const cards = this.state.responseJson.layoutDetail.cardList;
    cards.forEach((card) => {
      this.addCard(card, views);
    });
    return views;
  }

  addCard(card, views) {
    const {giftCardBookingInfo} = this.state.responseJson;
    switch (card.cardId) {
      case GiftCardBookingDetailsConstant.TOP_STATUS_CARD_COMPLETED:
        views.push(<TopStatusCard
          key='TopStatusCard'
          bookingID={this.bookingId}
          iconPath={TOP_STATUS_ICON_9}
          headText={GiftCardBookingDetailsConstant.GIFTCARD_SENT}
          color={[colors.greyBookedSeat, colors.greyBookedSeat]}
          onBackPress={this.goBack}
          response={this.state.responseJson}
          handleClick={this.handleResendVoucherOverlay}
        />);
        break;
      case GiftCardBookingDetailsConstant.TOP_STATUS_CARD_CANCELLED:
        views.push(<TopStatusCard
          key='TopStatusCard'
          bookingID={this.bookingId}
          iconPath={TICKET_FAIL_RED_ICON}
          iconStyle={styles.failedIcon}
          headText={GiftCardBookingDetailsConstant.GIFTCARD_FAILED}
          color={[colors.palePink, colors.palePink]}
          onBackPress={this.goBack}
        />);
        break;
      case GiftCardBookingDetailsConstant.GIFTCARD_MAIN_CARD:
        views.push(<GiftCardMainCard
          key="GiftCardMainCard"
          response={this.state.responseJson}
          pageName={this.trackPageName}
          handleClick={this.handleResendVoucherOverlay}
        />);
        break;
      case GiftCardBookingDetailsConstant.GIFTCARD_PAYMENT_CARD:
        views.push(<GiftCardPaymentCard
          key="GiftCardPaymentCard"
          response={this.state.responseJson}
          card={card}
          pageName={this.trackPageName}
        />);
        break;
      case GiftCardBookingDetailsConstant.CANCELLATION_POLICY_CARD:
        if (giftCardBookingInfo.cancellationPolicyInfo) {
          views.push(<CancellationPolicyCard
            key="CancellationPolicyCard"
            cancellationPolicyInfo={giftCardBookingInfo.cancellationPolicyInfo}
          />);
        }
        break;
      case GiftCardBookingDetailsConstant.NEED_HELP_CARD:
        if (giftCardBookingInfo) {
          views.push(<NeedHelpCard
            key="NeedHelpCard"
            giftCardBookingInfo={giftCardBookingInfo}
            card={card}
            pageName={this.trackPageName}
          />);
        }
        break;
      case GiftCardBookingDetailsConstant.REFUND_CARD:
        const { cancellationDetailList } = this.state.responseJson || {};
        const cancellationRequestNoList = this.getCancellationRequestNo(cancellationDetailList);
        views.push(this.renderRefundCard(this.state.responseJson, card, cancellationRequestNoList));
        break;
      default:
        break;
    }
  }

  renderProgressView = () => (<ProgressView message="Loading Your Trip..." />);
  renderLoadingView =() => ( <ProgressView message="Resending Gift Card Details..." /> );

  renderNoNetworkView = () => (
    <View style={{flex: 1}}>
      <SimpleHeader
        title="Back"
        iconPress={this.goBack}
      />
      <NoInternetView
        onRetry={() => {
          this.setState({viewState: ViewState.LOADING});
          this.fetchJSONAsync(GIFTCARD_BOOKING_DETAILS_URL + this.bookingId);
        }}
      />
    </View>
  );

  renderErrorView = () => (
    <View style={{flex: 1}}>
      <SimpleHeader
        title="Back"
        iconPress={this.goBack}
      />
      <ErrorView
        showRetry
        onRetry={() => {
          this.setState({viewState: ViewState.LOADING});
          this.fetchJSONAsync(GIFTCARD_BOOKING_DETAILS_URL + this.bookingId);
        }}
      />
    </View>
  );

  renderAuthErrorView = () => <AuthErrorView bookingId={this.bookingId} lobName={LOBNAMES.GIFT} onGoBackPress={this.goBack} />

  render() {
    const defaultWaterMark = this.getDefaultWaterMark();
    return (
        <>
      <View style={styles.flex1}>
        {this.state.viewState === ViewState.LOADING && this.renderProgressView()}
        {this.state.viewState === ViewState.NO_INTERNET && this.renderNoNetworkView()}
        {this.state.viewState === ViewState.ERROR && this.renderErrorView()}
        {this.state.viewState === ViewState.AUTH_ERROR && this.renderAuthErrorView()}
        {this.state.viewState === ViewState.SHOW_DETAIL && this.renderPage()}
        {this.state.viewState === ViewState.RESEND_VOUCHER && this.renderLoadingView()}
      </View>
          {this.state.bottomOverlay === GiftCardBookingDetailsConstant.RESEND_GIFT_CARD_OVERLAY &&
          <View style={styles.overlayBg}>
          <KeyboardAvoidingView style={[styles.flex1]} behavior={Platform.OS === 'ios' ? 'padding' : 'height'}>
          <OverlayMessage
              isTopRequired={true}
              handleBottomOverlay={() => this.handleBottomOverlay()}
              content={
                <ResendGiftCardOverlay
                    handleBottomOverlay={this.handleBottomOverlay}
                    handleResendVoucher={this.handleResendVoucher}
                    handleEmailIdInput={this.handleEmailIdInput}
                    defaultWaterMark={defaultWaterMark}
                    overlayMessage={this.state.responseJson.resendVoucherOverlay}
                />
              }
          />
          </KeyboardAvoidingView>
          </View>
          }
        </>
    );
  }

	goBack = () => {
    super.onBackIconPress();
  };

  getDefaultWaterMark = () => {
    return GiftCardBookingDetailsConstant.ENTER_EMAIL_ID
  }

  getTitle() {
    return this.state.responseJson.giftCardBookingInfo && this.state.responseJson.giftCardBookingInfo.giftCardDetails && this.state.responseJson.giftCardBookingInfo.giftCardDetails.name;
  }

  getSubtitle() {
    const giftCardBookingInfo = this.state.responseJson.giftCardBookingInfo;
    let subTitle = '';
    if (giftCardBookingInfo && giftCardBookingInfo.giftCardDetails && giftCardBookingInfo.giftCardDetails.value) {
      subTitle = giftCardBookingInfo.giftCardDetails.value;
      if (giftCardBookingInfo.giftCardDetails.quantity) {
        subTitle += GiftCardBookingDetailsConstant.CROSS + giftCardBookingInfo.giftCardDetails.quantity;
      }
    }
    return subTitle;
  }

  getCancellationRequestNo(cancellationDetailList) {
    const cancellationRequestNoList = [];
    if (isNotNullAndEmpty(cancellationDetailList)) {
      cancellationDetailList.forEach((cancellationDetail) => {
        cancellationRequestNoList.push(cancellationDetail.requestNo);
      });
    }
    return cancellationRequestNoList;
  }

}

export default GiftCardBookingDetail;
