import React from 'react';
import { View} from 'react-native';
import Shimmer from '@mmt/legacy-commons/Common/Components/Shimmer';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import createStyles from './style';
import { useTheme } from '../../../../../../theme/theme.context';

const FilterShimmer: React.FC = () => {
    const { psTheme: theme } = useTheme();
    const styles = createStyles(theme);

  return (
    <View style={styles.innerDetails}>
      <View style={[styles.rowSection, AtomicCss.marginBottom10]}>
        <View style={[styles.shimmerOne, styles.animatedOuter]}>
          <Shimmer />
        </View>
        <View style={[styles.shimmerOne, styles.animatedOuter]}>
          <Shimmer />
        </View>
        <View style={[styles.shimmerOne, styles.animatedOuter]}>
          <Shimmer />
        </View>
      </View>
      <View style={[styles.rowSection, AtomicCss.marginBottom10]}>
        <View style={[styles.shimmerOne, styles.animatedOuter]}>
          <Shimmer />
        </View>
        <View style={[styles.shimmerOne, styles.animatedOuter]}>
          <Shimmer />
        </View>
        <View style={[styles.shimmerOne, styles.animatedOuter]}>
          <Shimmer />
        </View>
      </View>
      <View style={[styles.rowSection, AtomicCss.marginBottom10]}>
        <View style={[styles.shimmerOne, styles.animatedOuter]}>
          <Shimmer />
        </View>
        <View style={[styles.shimmerOne, styles.animatedOuter]}>
          <Shimmer />
        </View>
        <View style={[styles.shimmerOne, styles.animatedOuter]}>
          <Shimmer />
        </View>
      </View>
      <View style={[styles.rowSection, AtomicCss.marginBottom10]}>
        <View style={[styles.shimmerOne, styles.animatedOuter]}>
          <Shimmer />
        </View>
        <View style={[styles.shimmerOne, styles.animatedOuter]}>
          <Shimmer />
        </View>
        <View style={[styles.shimmerOne, styles.animatedOuter]}>
          <Shimmer />
        </View>
      </View>
    </View>
  );
}



export default FilterShimmer;