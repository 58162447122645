import React from 'react';
import {Image, ScrollView, Text, View} from 'react-native';
import PropTypes from 'prop-types';
import Actions from '../../../navigation/postSalesNavigation';
import styles from './MetroReviewRefundChargeCss';
import DeductionBreakup from './DeductionBreakup';
import Breadcrumb from '../../components/Breadcrumb';
import StickyHeader from '../../../hotel/details/components/StickyHeader';
import BasePage from '../../../Common/PostSalesBasePage';
import BlueRectangleButton from '../../../Common/BlueRectangleButton';
import FareBreakup from './FareBreakup';
import ConfirmationOverlay from '../../../Common/ConfirmationOverlay';
import {getImageIcon} from '../../MetroBookingDetailUtil';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import ProgressView from '@mmt/legacy-commons/Common/Components/Progress/ProgressView';
import NoInternetView from '@mmt/legacy-commons/Common/Components/Error/NoInternetView';
import {getCommonHeaders, METRO_CANCEL_URL} from '../../../utils/NetworkUtils';
import {isNetworkAvailable} from '@mmt/legacy-commons/Common/utils/AppUtils';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import MetroBookingTrackingHelper from '../../MetroBookingTrackingHelper';
import MetroConstant from '../../MetroConstant';
import {HEADER_ICON} from '../../MetroImageConstant';

export const STEPS = [
  'SELECT',
  'REVIEW',
  'CONFIRM'
];

export const REFUND_MODE_STEPS = [
  'SELECT',
  'REVIEW',
  'REFUND MODE',
  'CONFIRM'
];

export default class MetroReviewRefundCharge extends BasePage {
  constructor(props) {
    super(props, 'metroCancelReviewRefundCharge');
    this.state = {
      activeStep: 1,
      overlay: false,
      viewState: ViewState.LOADING,
      cancelResponse: null
    };
    this.purchaseId = props.detailResponse.bookingInfo.purchaseId;
    this.bookingId = props.detailResponse.bookingId;
    this.trackPageName = 'Mob:Customer Support:Metro:CancellationPreview';
  }

  componentDidMount() {
    super.componentDidMount();
    this.fetchCancelDetail();
    MetroBookingTrackingHelper.trackLoadEvent(this.trackPageName, this.props.detailResponse);
  }

  getCancelRequest(action, refundModeReason) {
    const cancelRequest = {
      action,
      refundOption: refundModeReason ? refundModeReason : MetroConstant.REFUND_TO_CUSTOMER,
      functionType: 'CancellationRequest',
      bookingId: this.bookingId,
      purchaseId: this.purchaseId,
      fullCancel: this.props.isFullCancel,
      rjtIdList: this.props.rjtIdList,
      ticketIdList: this.props.ticketIdList,
      ticketLineNoList: this.props.ticketLineNoList
    };
    return cancelRequest;
  }

  onHardBackPress = () => {
    if (this.state.overlay) {
      this.confirmationPopup();
      return true;
    }
    return false;
  };

  render() {
    return (
      <View style={{flex: 1}}>
        {this.state.viewState === ViewState.LOADING && this.renderProgressView('Loading...')}
        {this.state.viewState === ViewState.SHOW_DETAIL && this.renderPage()}
        {this.state.viewState === ViewState.NO_INTERNET && this.renderNoNetworkView()}
        {this.state.viewState === ViewState.ERROR && this.renderErrorView()}
      </View>
    );
  }

  renderNoNetworkView = () => (
    <NoInternetView
      onRetry={() => {
        this.setState({viewState: ViewState.LOADING});
        this.fetchCancelDetail();
      }}
    />);

  renderErrorView = () => (
    <ErrorView
      showRetry
      message={this.state.cancelResponse && this.state.cancelResponse.errorText ? this.state.cancelResponse.errorText : 'Something went wrong, Please try again!'}
      onRetry={() => {
        this.setState({viewState: ViewState.LOADING});
        this.fetchCancelDetail();
      }}
    />);

  renderProgressView = message => (<ProgressView message={message} />);

  renderPage = () => (
    <View style={styles.pageWrapper}>
      <StickyHeader
        iconSrc={HEADER_ICON}
        title={this.props.headerTitle}
        headerColor={['#ffffff', '#ffffff']}
        titleStyle={[styles.headerTitle]}
        subTitle={this.props.headerSubTitle}
        subTitleStyle={[styles.DefaultText, styles.font12]}
        onBackPress={super.onBackIconPress}
      />
      <Breadcrumb
        activeStep={this.state.activeStep}
        data={(this.checkIfRefundApplicable()) ? REFUND_MODE_STEPS : STEPS}
      />
      <ScrollView showsVerticalScrollIndicator={false} style={styles.pageScroll}>
        <View style={styles.paxCancelledTopDtls} />
        <View style={styles.whiteCard}>
          {this.getRefundMessage()}
          <View style={styles.autoRefundBreakupMain}>

            <FareBreakup
              amountPaidBreakup={this.state.cancelResponse.cancelData.breakups.totalAmountPaid}
              paidmessages={this.getTotalPaidAttributes(this.state.cancelResponse.cancelData)}
            />
            <DeductionBreakup
              deductions={this.state.cancelResponse.cancelData.breakups.deductions}
              refundAmount={this.state.cancelResponse.cancelData.refundAmount}
              messages={this.getRefundAttributes(this.state.cancelResponse.cancelData)}
            />
          </View>
        </View>
      </ScrollView>
      <View style={styles.footerBtnWrapper}>
        <View style={styles.buttonStyle}>
          <BlueRectangleButton
            buttonText="SELECT REFUND MODE"
            clickHandler={this.onContinueClicked}
          />
        </View>
      </View>
      {this.state.overlay ? this.renderConfirmationPopup() : null}
    </View>
  )

  getTotalPaidAttributes(cancelData) {
    const cancelledSegmentInfo = {};
    cancelledSegmentInfo.messages = [];

    cancelData.breakups.totalAmountPaid.addAttributes && cancelData.breakups.totalAmountPaid.addAttributes.forEach((attribute) => {
      if (cancelData.propertiesMapping.properties[attribute]) {
        const attributeObj = cancelData.propertiesMapping.properties[attribute];
        const message = {};
        message.attributImg = attributeObj.img;
        message.attributSubTitle = attributeObj.subtitle;
        message.attributSubTitleColor = attributeObj.subtitleColor;
        cancelledSegmentInfo.messages.push(message);
      }
    });
    return cancelledSegmentInfo.messages;
  }
  getRefundAttributes(cancelData) {
    const cancelledSegmentInfo = {};
    cancelledSegmentInfo.messages = [];
    cancelData.refundAddAttributes && cancelData.refundAddAttributes.forEach((attribute) => {
      if (cancelData.propertiesMapping.properties[attribute]) {
        const attributeObj = cancelData.propertiesMapping.properties[attribute];
        const message = {};
        message.attributImg = attributeObj.img;
        message.attributSubTitle = attributeObj.subtitle;
        message.attributSubTitleColor = attributeObj.subtitleColor;
        cancelledSegmentInfo.messages.push(message);
      }
    });
    return cancelledSegmentInfo.messages;
  }
  renderConfirmationPopup() {
    return (
      <ConfirmationOverlay
        content={this.getCancelConfirmationText}
        leftBtnClick={this.confirmationPopup}
        rightBtnClick={() => this.overlayConfirmCancelClick()}
        outsideClick={this.confirmationPopup}
      />
    );
  }

  confirmationPopup = () => {
    this.setState({overlay: !this.state.overlay});
  };

  overlayConfirmCancelClick = (refundModeReason, fullCancel, ticketCount, refundAmount) => {
    Actions.metroCancelThankYou({
      bookingId: this.bookingId,
      isFullCancel: fullCancel,
      ticketCount,
      cancelRequest: this.getCancelRequest(MetroConstant.COMMIT, refundModeReason),
      refundAmount
    });
  }

  getRefundMessage = () => {
    const views = [];
    this.state.cancelResponse.cancelData.addAttributes && this.state.cancelResponse.cancelData.addAttributes.forEach((attribute) => {
      if (this.state.cancelResponse.cancelData.propertiesMapping.properties[attribute]) {
        const attributeObj = this.state.cancelResponse.cancelData.propertiesMapping.properties[attribute];
        const imageIcon = getImageIcon(attributeObj.img);
        views.push(<View
          key={attributeObj.subtitle}
          style={[styles.claimStatusInfoWrapper]}
        >
          {imageIcon && <Image
            style={styles.priceInfoIcon}
            source={imageIcon}
          />}
          <Text
            style={[styles.priceInfoTxt, {color: attributeObj.subtitleColor}]}
          >{attributeObj.subtitle}
          </Text>
        </View>);
      }
    });
    return views;
  };

  onContinueClicked = () => {
    if (this.checkIfRefundApplicable()) {
      Actions.metroCancelReviewRefundMode({
        refundAmount: this.state.cancelResponse.cancelData.refundAmount.value,
        emailId: this.props.detailResponse.emailId,
        cancelRefundMode: this.state.cancelResponse.cancelRefundMode,
        headerTitle: this.props.headerTitle,
        headerSubTitle: this.props.headerSubTitle,
        isFullCancel: this.props.isFullCancel,
        ticketCount: this.props.detailResponse.bookingInfo.ticketInfoList.length,
        overlayConfirmCancelClick: this.overlayConfirmCancelClick,
        getCancellationConfirmationText: this.getCancelConfirmationText
      });
    } else {
      this.confirmationPopup();
    }
  };

  checkIfRefundApplicable() {
    return Math.round(this.state.cancelResponse.cancelData.refundAmount.value, 0) > 0;
  }

  getCancelConfirmationText = () => {
    let message = '';

    if (Math.round(this.state.cancelResponse.cancelData.refundAmount.value, 0) === 0) {
      message = 'You are not eligible for any refund. ';
    }
    message = `${message}This is the final step of cancellation. Once cancelled, the ticket can not be reinstated.`;
    return message;
  };

  async fetchCancelDetail() {
    try {
      const hasNetwork = await isNetworkAvailable();
      if (!hasNetwork) {
        this.setState({viewState: ViewState.NO_INTERNET});
        return;
      }

      const cancelRequest = this.getCancelRequest(MetroConstant.PREVIEW, MetroConstant.REFUND_TO_CUSTOMER);
      const {mmtAuth, loggingTrackingInfo} = await HotelBookingModule.getRequestHeader(this.bookingId, 'Metro_Cancel');

      const response = await
        fetch(METRO_CANCEL_URL, {
          method: 'POST',
          headers: await getCommonHeaders(mmtAuth, loggingTrackingInfo),
          body: JSON.stringify(cancelRequest)
        });

      if (response && response.ok) {
        const body = await
          response.json();

        if (body.status) {
          MetroBookingTrackingHelper.trackLoadEvent(this.trackPageName, this.props.detailResponse);
          this.setState({
            cancelResponse: body,
            viewState: ViewState.SHOW_DETAIL
          });
        } else {
          MetroBookingTrackingHelper.trackErrorEvent(MetroConstant.ERRORTRACK);
          this.setState({
            cancelResponse: body,
            viewState: ViewState.ERROR
          });
        }
      } else {
        MetroBookingTrackingHelper.trackErrorEvent(MetroConstant.ERRORTRACK);
        this.setState({
          viewState: ViewState.ERROR
        });
      }
    } catch (error) {
      MetroBookingTrackingHelper.trackErrorEvent(MetroConstant.ERRORTRACK);
      this.setState({
        viewState: ViewState.ERROR
      });
    }
  }
}

MetroReviewRefundCharge.propTypes = {
  detailResponse: PropTypes.object.isRequired,
  ticketLineNoList: PropTypes.array.isRequired,
  purchaseId: PropTypes.string.isRequired
};
