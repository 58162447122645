import { StyleSheet } from 'react-native';
import { Fonts, Theme } from '../../types';

const getStyles = (fonts : Fonts, theme : Theme) => {
  const {color} = theme;
  return StyleSheet.create({
    headingSection: {
      paddingHorizontal: 20,
      paddingTop: 20,
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    headingText: {
      color: color.black22,
      ...fonts.blackFontFamily,
      fontSize: fonts.fontSize.font16,
    },
    titleText: {
      ...fonts.blackFontFamily,
      fontSize: fonts.fontSize.font14,
      color: color.lightGreen18,
      marginBottom: 5,
    },
    description: {
      ...fonts.regularFontFamily,
      fontSize: fonts.fontSize.font14,
      color: color.defaultTextColor,
    },
    crossIconStyle: {
      width: 22,
      height: 22,
    },
    crossIconWrapper: {
      width: 30,
      height: 30,
      right: -10,
    },
    btnWrapper: {
      paddingHorizontal: 16,
      paddingBottom: 25,
    },
    modifyInfoBox: {
      marginHorizontal: 20,
      marginTop: 4,
      marginBottom: 12,
    },
    viewBreakupTextStyle: {
      fontSize: fonts.fontSize.font14,
      ...fonts.boldFontFamily,
      textAlign: 'center',
    },
    buttonView: {
      marginVertical: 5
    },
  });
};

export default getStyles;
