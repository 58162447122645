import { isNullOrEmpty } from "core-ui-lib/helper/Object/ObjectFunctions";
import { FLIGHT_CANCELLATION_TYPE } from "../../../PostSalesConstant";
import { GREEN_REFUND_ICON, RED_REFUND_ICON, BLACK_REFUND_ICON, ZERO_CANCEL_ICON, DB_SM_ICON, SM_DOUBLE_SEAT_ICON, RED_INFO_ICON } from "../../FlightImageConstants";
import { getStaticData } from '../../../staticData/staticData';
import { RUPEES_ICON } from "../../FlightImageConstants";
import { resetToFlightBookingDetail } from "../../FlightBookingUtil";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import Fecha from 'fecha';

const ksaCurrencyIcon = require('@mmt/legacy-assets/src/postSales/ic_ksa_currency.webp');

import {
    getActiveSegment,
    isSegmentNotValidForCancellation,
    isOneSegmentAndNotvalid,
    isAllSegNotValid,
    checkIfAnypaxNotValid,
    getAllPossibleScActionLists,
    isPaxNotCancelled,
    isApiSuccess,
    isPaxNotValidForCancellation,
    isSegmentEligibleForClaim,
    checkIfAnypaxEligibleForClaim,
    shouldNotRedirectToBreakUpPage
  } from  "core-ui-lib/flight/common/Cancellation/utils/CancellationValidationUtil";

  export {
    isOneSegmentAndNotvalid,
    isAllSegNotValid,
    getActiveSegment,
    isSegmentNotValidForCancellation,
    checkIfAnypaxNotValid,
    getAllPossibleScActionLists,
    isPaxNotCancelled,
    isApiSuccess,
    isPaxNotValidForCancellation,
    isSegmentEligibleForClaim,
    checkIfAnypaxEligibleForClaim,
    shouldNotRedirectToBreakUpPage
};

export const checkPaxSelected = (selectedPassenger) => {
  const keys = Object.keys(selectedPassenger);
  if (keys.length === 0) {
    return true;
  }
  const res = true;
  for (let i = 0; i < keys.length; i += 1) {
    const seg = selectedPassenger[keys[i]];
    const segKeys = Object.keys(seg);
    for (let j = 0; j < segKeys.length; j += 1) {
      if (seg[segKeys[j]] === true) {
        return false;
      }
    }
  }
  return res;
};

export const checkIfAllPaxSelected = (paxDataList, selectedPaxList) => {
  if (paxDataList) {
    const noOfPax = paxDataList.length;
    for (let count = 0; count < noOfPax; count += 1) {
      const passengerInfo = paxDataList[count];
      if (!selectedPaxList[passengerInfo.paxReferenceNo]) {
        return false;
      }
    }
  }
  return true;
};

export function fillCheckInCheckOutData(date, outputDateFormat) {
  if (!isNullOrEmpty(date)) {
    const dateObject = Fecha.parse(date.split('T')[0], 'YYYY-MM-DD');
    return Fecha.format(new Date(dateObject), outputDateFormat);
  }
  return date;
}

export function getTravelerInfo(rawResponse,segmentPassengerDetail) {
  const {
    passengerList
  } = rawResponse;
  const paxDetails = [];
  if (!passengerList) {
    return null;
  }

  segmentPassengerDetail.forEach((segmentPax) => {
    passengerList.forEach((pax) => {
      if(pax.paxLineNo == segmentPax.paxLineNo) {
        paxDetails.push({
          name: pax.passengerName.unParsedName,
          pnrNo: pax.pnrNo,
          paxFareSegmentId: segmentPax.paxFareSegmentId,
          isPaxCancelled: segmentPax.isPaxCancelled,
          rawObj: pax,
        });
      }
    });
  });


  return paxDetails;
}

export const checkIfCardChargeableBooking = (cancellationResponse) => {

  const { cancellationPreView } = cancellationResponse || {};
  const { bnplbooking, bloombooking, finalAmountDue } = cancellationPreView || {};
  return (bnplbooking || bloombooking) && finalAmountDue > 0;
}

export const checkIfRefundModeApplicable = (cancellationResponse, isCardChargeableBooking) => {
  const { cancellationPreView } = cancellationResponse || {};
  const { totalRefundAmount } = cancellationPreView || {};
  return (
    !isCardChargeableBooking && Math.round( totalRefundAmount, 0 ) > 0
  );
}

export const getAllPassengersToCancel = (bookingResponse = {}) => {
  const passengers = [];
  const { newFlightDetails : { segmentGroupDetailList = [] } = {} } = bookingResponse || {};
  segmentGroupDetailList.map((segmentgroup) =>
    segmentgroup.segmentDetails && segmentgroup.segmentDetails.map((segmentDetails) =>
      getTravelerInfo(
        bookingResponse,
        segmentDetails.segmentPassengerDetail
      ).map((traveller) => passengers.push(traveller))
    )
  );

  return passengers;
}

export const getPaxFareSegmentIdListFromPassengers = (selectedPassengers,allPassengers) => {

  const _paxFareSegmentIdList = [];
  if (selectedPassengers) {
    selectedPassengers.forEach((segPax) => {
      _paxFareSegmentIdList.push({
        paxFareSegmentId: segPax.paxFareSegmentId,
        reason: 'CITP',
      });
    });
  } else {
    allPassengers.forEach((segPax) => {
      if (!segPax.isPaxCancelled) {
        _paxFareSegmentIdList.push({
          paxFareSegmentId: segPax.paxFareSegmentId,
          reason: 'CITP',
        });
      }
    });
  }
  return _paxFareSegmentIdList;
}

export const isSpecialClaimFlow = (cancellationRequest) => {
  return (
    cancellationRequest && FLIGHT_CANCELLATION_TYPE.SPECIAL_CLAIM === cancellationRequest.flightCancellationType
  );
}

export const getSegmentWiseInfo = (cancellationPreviewResponseJson) => {
  const { previewDetail, flightDetails } = cancellationPreviewResponseJson;
  const cancelledSegmentInfoList = [];
  const segmentMap = [];
  !isEmpty(flightDetails) &&
    !isEmpty(flightDetails.segmentGroupDetailList) &&
    flightDetails.segmentGroupDetailList.forEach((segmentGroup) => {
      segmentGroup.segmentDetails.forEach((segment) => {
        segmentMap[segment.segmentLineNo] = segment;
      });
    });
  !isEmpty(previewDetail) &&
    !isEmpty(previewDetail.itineraryDetails) &&
    previewDetail.itineraryDetails.forEach((itineraryDetail) => {
      const segment = segmentMap[itineraryDetail.segId];
      cancelledSegmentInfoList.push(segment);
    });
  return cancelledSegmentInfoList;
}

export const getCancellationConfirmationText = (cancellationPreviewResponseJson = {}, isCardChargeableBooking, isSpecialClaim) => {
  const {
    additionalText: {
      cardWillBeChargedText,
      finalStepOfCancellationText,
      confirmYourCancellationRequestText,
      claimRequestWillBeSubmitAndRefundText
    },
  } = getStaticData();
  if (isCardChargeableBooking) {
    return cardWillBeChargedText;
  } else if (isSpecialClaim) {
    return claimRequestWillBeSubmitAndRefundText;
  } else if (cancellationPreviewResponseJson.emiBooking) {
    return finalStepOfCancellationText;
  }
  return confirmYourCancellationRequestText;
};

export const getButtonText = (cancellationPreviewResponseJson,isSpecialClaim,isRefundModeApplicable,submitRequestText) => {
  const {
    selectRefundModeText,
    confirmCancellationText,
    makePaymentToCancelText,
  } = getStaticData();
  if (!isSpecialClaim) {
    if (isRefundModeApplicable) {
      return selectRefundModeText;
    }
    if (cancellationPreviewResponseJson && cancellationPreviewResponseJson.cancellationPreView.bnplbooking) {
      return makePaymentToCancelText;
    }
    return confirmCancellationText;
  }

  return submitRequestText;
};

export const getCancellationAttributesLists = (previewDetail, attributeKey) => {
  const cancellationAttributesLists =[];
  const attributes = get( previewDetail,attributeKey,[]);
  const properties = get(previewDetail,'propertiesMapping.properties',{});
  attributes &&
  attributes.forEach((attribute) => {
      if (properties && properties[attribute]) {
          cancellationAttributesLists.push(properties[attribute]);
      }
  });
  return cancellationAttributesLists;
};

export const getCancellationAttributesMessageList = (previewDetail, attributeKey) => {
  const cancellationAttributesLists = [];
  const attributes = get(previewDetail, attributeKey, []);
  const properties = get(previewDetail, 'propertiesMapping.properties', {});

  attributes &&
    attributes.forEach((attribute) => {
      if (properties && properties[attribute]) {
        cancellationAttributesLists.push({
          attributImg: properties[attribute].img,
          attributSubTitle: properties[attribute].subtitle,
          attributSubTitleColor: properties[attribute].subtitleColor
        });
      }
    });

  return cancellationAttributesLists;
};

export const getCancelledSegmentInfo = (cancellationPreviewResponseJson) => {

  if (!(cancellationPreviewResponseJson &&
      cancellationPreviewResponseJson.previewDetail &&
      cancellationPreviewResponseJson.previewDetail.itineraryDetails)) {
      return null;
  }

  const { previewDetail } = cancellationPreviewResponseJson;
  const cancelledSegmentInfoList = [];
  const segmentMap = [];
  const paxMap = [];
  cancellationPreviewResponseJson.flightDetails.segmentGroupDetailList.forEach((segmentGroup) => {
    segmentGroup.segmentDetails.forEach((segment) => {
      segmentMap[segment.segmentLineNo] = segment;
    });
    segmentGroup.segmentGroupPassengers.forEach((passenger) => {
      paxMap[passenger.paxLineNo] = passenger;
    });
  });
  previewDetail.itineraryDetails.forEach((itineraryDetail) => {
    const segment = segmentMap[itineraryDetail.segId];
    if (segment) {
      const paxNames = [];
      itineraryDetail.pIds.forEach((paxId) => {
        const paxInfo = paxMap[paxId];
        if (paxInfo && paxInfo.passengerName) {
          paxNames.push(`${paxInfo.passengerName.firstName} ${paxInfo.passengerName.lastName}`);
        }
      });
      const { originCity, destinationCity } = segment;
      const travelDate = segment.flightTravelDateV2;
      const cancelledSegmentInfo = {};
      cancelledSegmentInfo.messages = [];
      cancelledSegmentInfo.travelInfo = `${originCity} - ${destinationCity}, ${travelDate}`;
      cancelledSegmentInfo.paxNames = paxNames;
      itineraryDetail.addAttributes.forEach((attribute) => {
        if (cancellationPreviewResponseJson.previewDetail.propertiesMapping.properties[attribute]) {
          const attributeObj =
            cancellationPreviewResponseJson.previewDetail.propertiesMapping.properties[attribute];
          const message = {};
          message.attributImg = attributeObj.img;
          message.attributSubTitle = attributeObj.subtitle;
          message.attributSubTitleColor = attributeObj.subtitleColor;
          cancelledSegmentInfo.messages.push(message);
        }
      });
      cancelledSegmentInfoList.push(cancelledSegmentInfo);
    }
  });
  return cancelledSegmentInfoList;
}

export const getCancellationBreakupData = (previewResponse, breakupType) => {

  const { previewDetail, errorPageData } = previewResponse || {};

  if (!previewDetail || errorPageData || !breakupType ) {
    return null;
  }

  const { breakups } = previewDetail;

  const pricingComponents = breakups && breakups[breakupType] && breakups[breakupType].pricingComponents;
  const amountList = pricingComponents && pricingComponents.map((parentComponent) => {
    return {
      subtitle: parentComponent.prop && parentComponent.prop.subtitle,
      components: parentComponent.components && parentComponent.components.map((componentData) => {
        return {
          description: componentData.desc,
          showSlasher: componentData.showSlasher,
          img: componentData.img,
          actualValue: componentData.actualValue,
          slashedValue: componentData.slashedValue
        }
      })
    }
  })
  return {
    list: pricingComponents ? amountList : [],
    total: breakups && breakups[breakupType] && breakups[breakupType].lbl && breakups[breakupType].lbl.value,
    title: breakups && breakups[breakupType] && breakups[breakupType].lbl && breakups[breakupType].lbl.text,
    color: breakups && breakups[breakupType] && breakups[breakupType].lbl && breakups[breakupType].lbl.color
  }
}

export const getCancellationRefundAmountData = (previewResponse) => {
  const { previewDetail, errorPageData } = previewResponse || {};

  if (!previewDetail || errorPageData) {
    return null;
  }

  const { refundAmount } = previewDetail;

  return {
    totalRefund: refundAmount ? refundAmount.value : '',
    totalRefundlabel: refundAmount ? refundAmount.text : '',
    color: refundAmount ? refundAmount.color : ''
  };

}

export const getCurrencyIcon = (currency) => {

  if (!currency || currency.toLowerCase() === 'inr' || currency === '₹') {
    return RUPEES_ICON;
  }
  return ksaCurrencyIcon;
};

export const goToBookingDetails = (bookingId) => {
  resetToFlightBookingDetail(bookingId);
};

export const getImageIcon = (imgName) => {
  if (imgName) {
    switch (imgName) {
      case 'REFUND':
        return GREEN_REFUND_ICON;
      case 'NO_REFUND':
        return RED_REFUND_ICON;
      case 'PART_REFUND':
        return BLACK_REFUND_ICON;
      case 'ZC':
        return ZERO_CANCEL_ICON;
      case 'ZC_BENEFIT':
        return ZERO_CANCEL_ICON;
      case 'ZC_EXPIRED':
        return ZERO_CANCEL_ICON;
      case 'DB':
        return DB_SM_ICON;
      case 'DB_EXPIRED':
        return DB_SM_ICON;
      case 'DB_BENEFIT':
        return DB_SM_ICON;
      case 'RED_BUBBLE':
        return RED_INFO_ICON;
      case 'DOUBLE_SEAT':
        return SM_DOUBLE_SEAT_ICON;
      default:
        return undefined;
    }
  }
  return undefined;
};
