import {fonts} from '@mmt/legacy-commons/Styles/globalStyles';

import {Platform} from 'react-native';

const styles = {
  pageWrapper: {flex: 1, backgroundColor: '#f6f6f6'},
  topHeading: {
    paddingTop: 20, paddingBottom: 23, flexDirection: 'row', paddingLeft: 20, paddingRight: 20, alignItems: 'center'
  },
  crossImg: {width: 24, height: 24},
  overlayTopHeadding: {
    color: '#000', paddingLeft: 36
  },
  containerOverlay: {paddingHorizontal: 34},
  centerContainer: {justifyContent: 'center', flexDirection: 'row', alignItems: 'center'},
  appendBottom24: {marginBottom: 24},
  appendBottom4: {marginBottom: 4},
  overlayCouponCode: {
    borderWidth: 2, borderColor: '#000', borderRadius: 5, paddingVertical: 5, paddingHorizontal: 15
  },
  overlayCouponText: {
    color: '#000', letterSpacing: 2.5
  },
  appendBottom44: {marginBottom: 44},
  bookingId: {
    color: '#9b9b9b', textAlign: 'center', marginBottom: 4
  },
  bookingIdNo: {
    color: '#000', textAlign: 'center', marginBottom: 4
  },
  mapIcon: {width: 300, height: 300},
  IOSSpacing: {...Platform.select({ios: {marginTop: 10}})}
};

export default styles;
