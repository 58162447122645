import Actions from '../../navigation/postSalesNavigation';
import {Platform} from 'react-native';
import {getCommonHeaders} from '@mmt/legacy-commons/Helpers/genericHelper';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import PostSalesBookingTrackingHelper from '../../PostSalesBookingTrackingHelper';
import { setWebViewAuth } from '../../utils/PostSaleUtil';

function getDayMonthYear(timestamp) {
  return {
    day: timestamp.getDate()
  };
}

// eslint-disable-next-line import/prefer-default-export
export function formatDate(timestamp) {
  const currentTime = new Date();
  const currentTimeData = getDayMonthYear(currentTime);
  const _timestamp = new Date(timestamp);
  const timestampData = getDayMonthYear(_timestamp);
  const diffData = {
    day: currentTimeData.day - timestampData.day
  };
  const hours = Math.floor((currentTime.getTime() - _timestamp.getTime()) / 1000 / 60 / 60);
  if (hours < 24 && diffData.day === 0) {
    // Within same day
    return constructDateString(_timestamp, {time: true});
  }
  return constructDateString(_timestamp, {dayAndTime: true});
}

function constructDateString(timestamp, formatOptions = {}) {
  let hours = timestamp.getHours();
  const timestampSuffix = hours > 12 ? 'PM' : 'AM';
  hours %= 12;
  hours = hours === 0 ? 12 : hours;
  hours = hours < 10 ? `0${hours}` : `${hours}`;
  let minutes = timestamp.getMinutes();
  minutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
  if (formatOptions.time) {
    return `${hours}:${minutes} ${timestampSuffix}`;
  }
  if (formatOptions.dayAndTime) {
    const monthMap = {
      0: 'Jan',
      1: 'Feb',
      2: 'Mar',
      3: 'Apr',
      4: 'May',
      5: 'Jun',
      6: 'Jul',
      7: 'Aug',
      8: 'Sep',
      9: 'Oct',
      10: 'Nov',
      11: 'Dec'
    };
    return `${timestamp.getDate()} ${
      monthMap[timestamp.getMonth()]
    } ${hours}:${minutes} ${timestampSuffix}`;
  }
  return '';
}

const PLATFORM_SOURCE = Platform.OS === 'android' ? 'ANDROID' : 'IOS';
export const openWebView = async (msrUrl) => {
  let url = `${msrUrl}&source=${PLATFORM_SOURCE}`;
  let appVersion = null;
  try {
    appVersion = await GenericModule.getAppVersion();
  } catch (e) {}
  if (appVersion) {
    url = `${url}&appVersion=${appVersion}`;
  }

  let headers = {};
  try {
    headers = await getCommonHeaders();
    delete headers['mmt-app-version-code'];
  } catch (e) {}
  headers.Cookie = `mmt-auth=${headers['mmt-auth']};`;
  Actions.openPsWebView({
    url,
    headerText: 'My Support Requests',
    headerIcon: require('@mmt/legacy-assets/src/back.webp'),
    headers,
    sharedCookiesEnabled: true,
    injectedJavaScript: setWebViewAuth(headers['mmt-auth']),
    whiteHeader: true
  });
};

export const trackrRedirectToMsr = (ticket, cardRank, pageName) => {
  try {
    const linkName = ticket.actionBtnText.split(' ').join('');
    const l2Name = ticket.secondaryType.split(' ').join('');
    const issueText = ticket.statusText.split(' ').join('');
    const msrClickEvent = `MSR_clicked-link:${linkName}_issue:${issueText}_L2:${l2Name}_${cardRank}`;
    PostSalesBookingTrackingHelper.trackClickEvent(pageName, msrClickEvent,ticket.entityKey, ticket.id);
  } catch (e) {
    console.log(e);
  }
}

export const trackRedirectMsr = (ticket, pageName) => {
  try {
    const msrUpdate = `msr: ${ticket.msrUpdate}`;
    PostSalesBookingTrackingHelper.trackClickEvent(pageName, msrUpdate,ticket.entityKey, ticket.id);
  } catch (e) {
    console.log(e);
  }
}

export const trackMsrCardShown = (ticket, pageName) => {
  try {
    const msrUpdate = `msr: ${ticket.msrUpdate}`;
    PostSalesBookingTrackingHelper.trackMsrCardShownEvent(pageName, msrUpdate,ticket.entityKey, ticket.id);
  } catch (e) {
    console.log(e);
  }
}
