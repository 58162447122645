import React from 'react';
import {Text, View} from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';

const LableRow = (props) => {
  const {label, value} = props;
  if (!value) {
    return null;
  }
  return (
    <View
      style={[
        AtomicCss.flexRow,
        AtomicCss.alignCenter,
        AtomicCss.marginBottom10
      ]}
    >
      {label ? (
        <Text
          style={[
            AtomicCss.regularFont,
            AtomicCss.font14,
            AtomicCss.defaultText
          ]}
        >
          {label}{' '}
        </Text>
      ): null}
      <Text
        style={[
          AtomicCss.regularFont,
          AtomicCss.font14,
          AtomicCss.defaultText
        ]}
      >
        {value}
      </Text>
    </View>
  );
};

export default LableRow;
