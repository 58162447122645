import React from 'react';
import PropTypes from 'prop-types';
import LinearGradient from 'react-native-linear-gradient';
import Actions from '../../../../../navigation/postSalesNavigation';
import { StyleSheet, Text, View, Image, TouchableOpacity } from 'react-native';
import ContainerView from '@mmt/legacy-commons/Common/Components/ContainerView';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import Header from '../../../../../Common/HeaderCross';
import BasePage from '../../../../../Common/PostSalesBasePage';

import { getStaticData } from '../../../../../staticData/staticData';
import { ERROR_INFO_ICON } from '../../../../FlightImageConstants';
import { openDialer } from '../../../../../Common/commonUtil';
import { getFont } from '../../../../../PostSalesStyles';

class PolicyError extends BasePage {
  constructor(props) {
    super(props, 'flightBookingPolicyError');
  }
  render() {
    const { fsStyle, ...fonts } = getFont(true);
    const {
      flightBookingPolicyError: {
        heading,
        subHeading,
        additionalText: { callMakeMyTripText },
      },
    } = getStaticData();
    return (
      <ContainerView style={styles.container}>
        <Header headerShadow title={heading} headingTxt="font18" handleClose={this.onBackPress} />
        <View style={styles.contentContainer}>
          <Image source={ERROR_INFO_ICON} style={styles.errorInfoIcon} />
          <View>
            <Text style={[styles.contentTitle, fonts.boldFontFamily, fsStyle.font24]}>
              {subHeading}
            </Text>
            <View>
              <Text style={[styles.contentSubtitle, fonts.regularFontFamily, fsStyle.font14]}>
                {this.props.msg}
              </Text>
            </View>
          </View>
          <TouchableOpacity style={[styles.BtnTouch, { width: '100%' }]} onPress={this.call}>
            <LinearGradient
              start={{
                x: 1.0,
                y: 0.0,
              }}
              end={{
                x: 0.0,
                y: 1.0,
              }}
              colors={['#065af3', '#53b2fe']}
              style={[styles.CapsuleBtnFill]}
            >
              <Text style={[styles.btnStyle, fonts.boldFontFamily, fsStyle.font12]}>
                {callMakeMyTripText}
              </Text>
            </LinearGradient>
          </TouchableOpacity>
        </View>
      </ContainerView>
    );
  }

  onBackPress = () => {
    Actions.pop();
  };

  call = () => {
    openDialer(this.props.contactNo);
  };
}

PolicyError.propTypes = {
  msg: PropTypes.string.isRequired,
  contactNo: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#f2f2f2',
    flex: 1,
  },
  errorInfoIcon: {
    width: 32,
    height: 32,
    marginRight: 10,
  },
  contentTitle: {
    marginTop: 24,
    marginHorizontal: 24,
    color: colors.black,
    lineHeight: 28,
    textAlign: 'center',
  },
  contentSubtitle: {
    marginTop: 8,
    marginHorizontal: 24,
    color: colors.lightTextColor,
    lineHeight: 21,
    textAlign: 'center',
  },
  contentContainer: {
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    paddingVertical: 20,
    paddingHorizontal: 20,
    backgroundColor: '#fff',
  },
  CapsuleBtnFill: {
    paddingVertical: 16,
  },
  BtnTouch: {
    elevation: 2,
    marginTop: 20,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 2,
  },
  btnStyle: {
    color: colors.white,
    backgroundColor: 'transparent',
    alignSelf: 'center',
  },
});

export default PolicyError;
