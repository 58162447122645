import { NativeModules } from 'react-native';

const downloadVisa = async (data) => {
    const visaURlList=JSON.parse(data);
    for (let i = 0; i < visaURlList.length; i++) {
        await downloadSingleVisa(visaURlList[i].documentUrl, visaURlList[i].recordName);
    }
} 

 const downloadSingleVisa = async (url, fileName, mode ='cors')=> {
    try {
        const res = await fetch(url, {mode});
        const blob = await res.blob();
        const download = require("downloadjs");
        await download(blob, fileName + '.pdf', "application/pdf");
    } catch (error) {
        console.log('FAILURE with error code as' + error);
    }
}

const downloadFileWithHeaders = async (url, fileName, headers)=>{
    try {
        const res = await fetch(url, headers);
        const blob = await res.blob();
        const download = require("downloadjs");
        await download(blob, fileName + '.pdf', "application/pdf");
    } catch (error) {
        console.log('FAILURE with error code as' + error);
    }
}
const openLink = (url)=> {
    window.open(url);
}
NativeModules.VisaBookingModule = {
    downloadVisa,
    downloadSingleVisa,
    downloadFileWithHeaders,
    openLink
};