import { StyleSheet } from 'react-native';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';

const styles = StyleSheet.create({
  rowFront: {
    paddingHorizontal: 12,
    backgroundColor: colors.white,
    borderBottomColor: colors.grey21,
    borderBottomWidth: 1,
    justifyContent: 'center',
    height: 63,
  },
  rowView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  rowText: {
    lineHeight: 20,
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: colors.defaultTextColor,
  },
  rowSubTxt: {
    color: colors.defaultTextColor,
  },
  iconStyle: {
    width: 40,
    height: 40,
    marginRight: 12,
  },
  greenTickStyle: {
    width: 20,
    height: 20,
  },
  calcIconStyle: {
    width: 14,
    height: 14,
    marginRight: 6,
  },
  rightArrow: {
    borderWidth: 1,
    borderTopColor: colors.lightTextColor,
    borderRightColor: colors.lightTextColor,
    borderLeftColor: colors.white,
    borderBottomColor: colors.white,
    width: 10,
    height: 10,
    marginRight: 12,
    transform: [{ rotate: '45deg' }],
  },
});
export default styles;
