import { colors } from '@mmt/legacy-commons/Styles/globalStyles';

export const styles = {
    sclaimHeader: {
      color: '#282828',
      paddingLeft: 10,
      paddingRight: 20,
      paddingBottom: 4,
    },
    sclaimDesc: {
      color: colors.black,
      paddingLeft: 57,
      paddingRight: 20,
      paddingBottom: 25,
    },
    validationMsg: {
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 10,
      color: '#1a7971',
    },
    warningIcon: {
      width: 35,
      height: 35,
      marginBottom: 2,
    },
    messageContent: {
      flexDirection: 'row',
      alignItems: 'center',
      paddingLeft: 8,
      paddingRight: 8,
    },
    sclaimInfo: {
      color: '#cf8100',
      paddingLeft: 38,
      paddingRight: 20,
      paddingBottom: 2,
    },
    sclaimDescMultiSegDes: {
      paddingLeft: 48,
      paddingTop: 4,
      paddingBottom: 8,
    },
  };