import { PROFILE_TYPE } from '@mmt/legacy-commons/Common/constants/AppConstants';
import DefaultTheme from "../DefaultTheme";
import { ps_colors } from "../PostSalesStyles";


const DEFAULT_COLOR_THEME = {
  ...ps_colors,
  ...DefaultTheme,
  primary: '#008CFF',
  primaryGradient:['#065AF3',"#53B2FE"],
  filterTextColor:"#fff",
  filterActiveBorderColor:"#fff",
  lightPrimaryColor:"#eaf5ff",
  linkButtonTextColor:"#008CFF",
  primaryBorderLight:"#b5dcff",
};

export const DEFAULT_THEME = {
  id: PROFILE_TYPE.PERSONAL,
  color: DEFAULT_COLOR_THEME,
};
