import { colors } from '@mmt/legacy-commons/Styles/globalStyles';

const styles = {
  pageWrapper: {
    flex: 1,
  },
  whiteBg: {
    backgroundColor: colors.white,
  },
  paxCancelledTopDtls: {
    backgroundColor: '#f6f6f6',
    elevation: 2,
    marginBottom: 1,
    marginTop: 0,
    shadowColor: '#000000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 2,
  },
  paxCancelledinnderDtls: {
    paddingVertical: 12,
    paddingHorizontal: 16,
  },
  fltDtlsTxt: {
    color: '#000',
  },
  chklistIcon: {
    width: 13,
    height: 16,
    marginRight: 8,
    marginTop: 2,
  },
  UserIcon: {
    width: 16,
    height: 12,
    marginRight: 8,
    marginTop: 2,
  },
  refundtopDisc: {
    marginBottom: 10,
  },
  refundtopDiscPax: {
    marginBottom: 10,
  },
  refundbottomDisc: {
    marginBottom: 15,
    paddingRight: 5,
  },
  fareBreakupAttDisc: {
    marginBottom: 5,
    paddingRight: 25,
    paddingTop: 10,
  },
  topGreyBorder: {
    borderTopColor: '#e7e7e7',
    borderTopWidth: 1,
  },
  whiteCard: {
    backgroundColor: '#ffffff',
    elevation: 2,
    shadowColor: '#000000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 2,
    marginVertical: 8,
  },
  refundContainer: {
    marginVertical: 8,
  },
  claimStatusInfoWrapper: {
    flexDirection: 'row',
    paddingVertical: 2,
    paddingHorizontal: 16,
  },
  dbIconSm: {
    width: 16,
    height: 16,
    marginRight: 5,
  },
  zcIconSm: {
    width: 16,
    height: 16,
    marginRight: 8,
  },
  redInfoIconSm: {
    width: 16,
    height: 16,
    marginRight: 8,
  },
  redFareInfoIconSm: {
    width: 16,
    height: 16,
    marginRight: 8,
    marginTop: 10,
  },
  disabledDb: {
    opacity: 0.2,
  },
  priceInfoIcon: {
    width: 18,
    height: 17,
    marginRight: 10,
    marginTop: 2,
  },
  priceInfoTxt: {
    flex: 1,
    color: '#1a7971',
  },
  attributeText: {
    flex: 1,
    color: '#1a7971',
    marginTop: 5,
    marginBottom: 5,
  },
  autoRefundBreakupMain: {
    borderTopColor: '#e7e7e7',
    borderTopWidth: 1,
    borderBottomColor: '#e7e7e7',
    borderBottomWidth: 1,
  },
  fareBreakUpWrapper: {
    paddingTop: 12,
    paddingHorizontal: 16,
    paddingBottom: 10,
  },
  DeductionBreakUpWrapper: {
    paddingTop: 12,
    paddingHorizontal: 16,
    paddingBottom: 0,
    borderTopColor: '#e7e7e7',
    borderTopWidth: 1,
  },
  fareBreakupHeading: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  fbHeadingTxt: {
    color: '#000',
  },
  totalPaidPriceTxt: {
    alignSelf: 'flex-end',
    color: '#000',
  },
  linkTxt: {
    color: '#008cff',
    marginLeft: 10,
    marginTop: 2,
  },
  fareBreakupDtlsWrapper: {
    paddingVertical: 12,
  },
  fareBrkupRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 4,
  },
  fareBrkupTxt: {
    color: '#000',
  },
  unCancelledWrapper: {
    borderTopColor: '#e7e7e7',
    borderTopWidth: 1,
    paddingTop: 10,
  },
  unCancelledTxt: {
    color: '#000',
  },
  descLeftWrap: {
    width: '60%',
  },
  priceText: {
    textAlign: 'right',
  },
  fareBrkupTxtPrice: {
    color: '#747474',
  },
  fareBrkupRowLine: {
    height: 1,
    backgroundColor: '#f2f2f2',
    width: '60%',
  },
  fareBrkupRowLineWrapperTxt: {
    marginTop: -8,
    backgroundColor: '#fff',
    alignSelf: 'flex-start',
    marginBottom: 5,
    paddingRight: 5,
  },
  fareBrkupRowWrapper: {
    marginBottom: 20,
  },
  lstFBWrapper: {
    marginBottom: 0,
  },
  totalRefund: {
    borderTopColor: '#e7e7e7',
    borderTopWidth: 1,
    paddingTop: 15,
  },
  totalRefundTxt: {
    color: '#1a7971',
  },
  footerBtnWrapper: {
    padding: 12,
  },
  lineThrough: {
    textDecorationLine: 'line-through',
  },
  headerTitle: {
    color: colors.black,
  },
  moreTraveller: {
    color: '#000',
    marginLeft: 20,
  },
  doubleSeatIcon: {
    width: 24,
    height: 24,
    marginRight: 10,
    marginTop: 2,
  },
};

export default styles;
