import React from 'react';
import {View, Text, Image, TouchableOpacity, StyleSheet} from 'react-native';
import getStyles from '../../details/components/HotelMamiCss';
import Actions from '../../../navigation/postSalesNavigation';
import {fillCheckInCheckOutData} from '../../details/utils/HotelBookingDetailUtil';
import PropTypes from 'prop-types';
import BasePage from '../../../Common/PostSalesBasePage';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss'
import { getStaticData } from "../../../staticData/staticData";
import { imageTransformY } from "../../../utils/PostSaleUtil";
import { LEFT_BLACK_ARROW_ICON } from '../../HotelImageConstants';
import { LANG_AWARE } from '../../HotelConstants';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import HeaderWrapper from 'apps/post-sales/src/Common/HeaderWrapper';
class StickyHeader extends BasePage {

  constructor(props) {
    super(props);
    this.fonts = getFont(true);
    this.styles = getStyles(this.fonts);
  }
  
  GoBackHandler = () => {
    if(this.props.backHandler) {
      this.props.backHandler();
    } else {
      Actions.pop();
    }
  }

    static navigationOptions = {
      header: null
    };


    render() {
      const {
        hotelMainCardText:{
          additionalText:{
            guestText,
            guestsText,
            roomText,
            roomsText
          }
        }
      } = getStaticData(LANG_AWARE.MODIFICATION);
      return (
        <HeaderWrapper
          titleText={this.props.hotelName}
          descText={`${this.props.checkinCheckoutText} | ${this.props.roomDetails} ${this.props.roomDetails > 1 ? roomsText : roomText} | ${this.props.guestCount} ${this.props.guestCount > 0 ? guestsText : guestText}`}
          closeIcon={LEFT_BLACK_ARROW_ICON}
          clickHandler={this.GoBackHandler}
          iconStyle={imageTransformY}
          wrapperStyle={[]}
          titleStyle={[this.styles.DefaultText, this.styles.blackFont]}
          descriptionStyle={[this.styles.DefaultText, this.styles.font12]}
        />
      );
    }
}

StickyHeader.propTypes =
    {
      checkoutdate: PropTypes.string.isRequired,
      checkinDate: PropTypes.string.isRequired,
      hotelName: PropTypes.string.isRequired,
      roomDetails: PropTypes.number.isRequired,
      guestCount: PropTypes.number.isRequired

    };

export default StickyHeader;
