import { label } from  '@mmt/post-sales/src/staticData/staticData';
import React, { Component } from 'react';
import { View, StyleSheet, Text } from 'react-native';
import AtomicCss from '../../../commonStyles/AtomicCss';
import CheckBox from '../CheckBox';

class AmenityIssueCard extends Component {

  constructor(props) {
    super(props);
    this.pageName = 'AmenityIssueCard';
  }

  renderOptions = (listOptions) => {
    return listOptions && listOptions.map( (option, index) => {
      let issueObject = {
        id: `${option}_${index}`, 
        name: option
        };
      
      return (
      <View style={styles.checkBoxWrapper}>
        <CheckBox title={option}
          pageName={this.pageName}
          listObj={this.props.amenitiesList}
          currentObj={issueObject}
          updateList={this.props.updateAmentiesList}
        />
      </View>
    )
      });
  }

  render() {
    if(!this.props.listOptions)
      return null;

    return (
      <View style={[AtomicCss.whiteCardSection, styles.sectionCard, AtomicCss.marginBottom10]}>
        <Text style={[AtomicCss.defaultText, AtomicCss.font16, AtomicCss.boldFont, AtomicCss.marginBottom18]}>{label('bus.issue.amenitiesNotProvided')}</Text>
        {this.renderOptions(this.props.listOptions)}
        {/* 
          <View style={styles.checkBoxWrapper}>
            <CheckBox title={"Water Bottle"}/>
          </View>
          <View style={styles.checkBoxWrapper}>
            <CheckBox title={"Snacks"}/>
          </View>
          <View style={[styles.checkBoxWrapper, styles.noBorderBottom]}>
            <CheckBox title={"Food"}/>
          </View>
         */}

      </View>
    );
  }
}

const styles = StyleSheet.create({
  sectionCard: { padding: 16, paddingBottom: 4 },
  checkBoxWrapper: { borderBottomWidth: 1, borderBottomColor: '#f2f2f2' },
  noBorderBottom: { borderBottomWidth: 0 }
});

export default AmenityIssueCard;