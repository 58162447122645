import React from 'react';
import { View, Text, Image, TouchableOpacity, StyleSheet } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import PropTypes from 'prop-types';

import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { openWebView, trackrRedirectToMsr } from './Utils';
import { isEmpty } from 'lodash';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import { CHAT_ICON, BG_BLUE_RIGHT_ARROW } from '../../../../FlightImageConstants';
import { getFont } from '../../../../../PostSalesStyles';
import { EscalateMessage, FeedbackCard } from '../../../../../Common/MSR/MSRComponents';

export default function SingleRequestTicketCard(props) {
  const { fsStyle, ...fonts } = getFont(true);
  const { ticket, allTicketsForBooking, pageName, trackingPageName } = props;
  const { heading, description } = allTicketsForBooking;
  if (!ticket) {
    return null;
  }
  if (!Object.keys(ticket).length) {
    return null;
  }

  let { ticketTagClr, actionBtnText } = ticket;
  if (!ticketTagClr) {
    ticketTagClr = 'grey';
  }
  if (!actionBtnText) {
    actionBtnText = 'View Details';
  }

  return (
    <LinearGradient
      start={{ x: 1.0, y: 0.0 }}
      end={{ x: 0.0, y: 1.0 }}
      colors={['#e7f0ff', '#ffffff']}
      style={[styles.msrEntryCardOuter, AtomicCss.marginBottom10]}
    >
      <View style={AtomicCss.marginBottom20}>
        <Text
          style={[
            AtomicCss.defaultText,
            fonts.blackFontFamily,
            fsStyle.font12,
            AtomicCss.textUppercase,
          ]}
        >
          {description}
        </Text>
      </View>
      <View style={AtomicCss.flexRow}>
        <Image style={[styles.msrEntryCardIcon, AtomicCss.marginRight10]} source={CHAT_ICON} />
        <View style={[AtomicCss.flex1]}>
          <View style={styles.msrEntryCardTopSec}>
            <View style={AtomicCss.marginBottom5}>
              <Text style={[AtomicCss.blackText, fonts.blackFontFamily, fsStyle.font16]}>
                {ticket.secondaryType}
              </Text>
            </View>
            <View style={AtomicCss.marginBottom8}>
              <Text style={[AtomicCss.defaultText, fsStyle.font12]}>
              {ticket.nameChangeInfoText}
              </Text>
            </View>
          </View>
          <View style={[AtomicCss.marginBottom12, AtomicCss.paddingRight25]}>
            <Text style={[fsStyle.font11, AtomicCss.midGreyText]}>
              <Text
                style={[
                  ticketTagColourStatusTextColourMap[ticketTagClr],
                  fsStyle.font12,
                  fonts.boldFontFamily,
                  AtomicCss.lineHeight18,
                ]}
              >
                {ticket.statusText}
              </Text>{' '}
              {!isEmpty(ticket.updatedAtText) && <Text>({ticket.updatedAtText})</Text>}
            </Text>
            {ticket.expectedClosureDateText && (
              <Text
                style={[
                  AtomicCss.marginTop5,
                  fsStyle.font12,
                  AtomicCss.midGreyText,
                  AtomicCss.lineHeight18,
                ]}
              >
                <Text style={[styles.expectedClosureDate, fsStyle.font12, fonts.boldFontFamily]}>
                  {ticket.expectedClosureDateText}
                </Text>
              </Text>
            )}
          </View>
          <TouchableOpacity
            style={[AtomicCss.flexRow, AtomicCss.alignCenter, { marginBottom: 4 }]}
            onPress={() => {
              trackrRedirectToMsr(ticket, 0, pageName);
              openWebView(ticket.msrUrl, heading, pageName);
            }}
          >
            <Text
              style={[
                AtomicCss.azure,
                fonts.boldFontFamily,
                fsStyle.font12,
                AtomicCss.marginRight5,
              ]}
            >
              {actionBtnText}
            </Text>
            <Image style={styles.msrEntryCardArrow} source={BG_BLUE_RIGHT_ARROW} />
          </TouchableOpacity>
          {ticket.escalationDetails && ticket.escalationDetails.showEscalate && (
            <EscalateMessage
              escalationDetails={ticket.escalationDetails}
              pageName={trackingPageName}
              ticket={ticket}
            />
          )}
          {ticket.feedbackDetails && ticket.feedbackDetails.showFeedbackOption && (
            <FeedbackCard
              feedbackDetails={ticket.feedbackDetails}
              pageName={trackingPageName}
              ticket={ticket}
            />
          )}
        </View>
      </View>
    </LinearGradient>
  );
}

SingleRequestTicketCard.defaultProps = {
  ticket: null,
};

SingleRequestTicketCard.propTypes = {
  ticket: PropTypes.shape({
    iconUrl: PropTypes.string.isRequired,
    secondaryType: PropTypes.string.isRequired,
    createdAt: PropTypes.number.isRequired,
    updatedAt: PropTypes.number.isRequired,
    expectedClosureDate: PropTypes.number,
    assignee: PropTypes.string,
    msrUrl: PropTypes.string.isRequired,
    actionBtnText: PropTypes.string.isRequired,
  }),
};

const styles = StyleSheet.create({
  msrEntryCardOuter: {
    paddingVertical: 16,
    paddingLeft: 16,
  },

  msrEntryCardTopSec: {
    borderBottomWidth: 1,
    borderBottomColor: '#e7e7e7',
    marginBottom: 8,
  },
  msrEntryCardArrow: {
    width: 6,
    height: 10,
  },
  msrEntryCardIcon: {
    width: 20,
    height: 19,
    marginTop: 3,
    tintColor: colors.darkGrey,
  },
  greenTxt: { color: '#1a7971' },
  yellowText: { color: '#cc7f01' },
  expectedClosureDate: {
    color: '#000',
  },
});

const ticketTagColourStatusTextColourMap = {
  // ticketTagClr
  black: AtomicCss.blackText,
  yellow: styles.yellowText,
  red: AtomicCss.errorRedText,
  green: styles.greenTxt,
};
