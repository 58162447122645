import isEmpty from 'lodash/isEmpty';
import { LAST_MINUTE_CANCELLATION_STATUS } from '../../modules/details/FlightBookingDetailsConstant';

export const extractLastMinutePnrs = (segmentWiseInfo, data) => {
  const modifiedData = {};
  const segmentKeys = segmentWiseInfo.map((item) => item.segmentLineNo);
  for (const key in data) {
    // eslint-disable-next-line radix
    if (segmentKeys.indexOf(parseInt(key.split('-')[1])) !== -1) {
      modifiedData[key.split('-')[1]] = {
        ...data[key],
        flightCancellationError: {
          ...data[key].flightCancellationError,
          pnrNo: key.split('-')[0],
        },
      };
    }
  }
  const anySegmentNotInProgress = segmentKeys.every((item) =>
    !isEmpty(modifiedData[item]) && !isEmpty(modifiedData[item].state)
      ? modifiedData[item].state.cancellationStatus !==
        LAST_MINUTE_CANCELLATION_STATUS.CANCELLATION_IN_PROGRESS
      : true,
  );
  if (anySegmentNotInProgress) {
    const failedSegmentData = [];
    const successPnr = [];
    //Checking for which pnr-segmentid has cancellationFailed status
    const hasFailedSegmentData = Object.keys(modifiedData).filter(
      (item) =>
        !isEmpty(modifiedData[item]) &&
        !isEmpty(modifiedData[item].state) &&
        modifiedData[item].state.cancellationStatus ===
          LAST_MINUTE_CANCELLATION_STATUS.CANCELLATION_FAILED,
    );
    //Getting successful pnr according to the segmentId
    for (const key in modifiedData) {
      if (
        !isEmpty(modifiedData[key]) &&
        !isEmpty(modifiedData[key].flightCancellationError) &&
        !isEmpty(modifiedData[key].state) &&
        modifiedData[key].state.cancellationStatus ===
          LAST_MINUTE_CANCELLATION_STATUS.CANCELLATION_SUCCESS
      )
        successPnr.push(modifiedData[key].flightCancellationError.pnrNo);
    }
    hasFailedSegmentData.forEach((item) => {
      if (!isEmpty(modifiedData[item]) && !isEmpty(modifiedData[item].flightCancellationError)) {
        failedSegmentData.push(modifiedData[item].flightCancellationError);
      }
    });
    return { hasFailedSegmentData, failedSegmentData, successPnr, anySegmentNotInProgress };
  }
  return {
    hasFailedSegmentData: null,
    failedSegmentData: null,
    successPnr: [],
    anySegmentNotInProgress,
  };
};

export const getCumulativeStatus = (tentativeWindowBookingStatus, pnrSegmentNo) => {
  let tentativeWindowStatus = {};

  if (isEmpty(tentativeWindowBookingStatus)) {
    return tentativeWindowStatus;
  }

  const cancellationSuccessPnrSegmentPax = [];
  const cancellationFailedPnrSegmentPax = [];
  const currentSegTentativeWindowBookingStatus = {};

  Object.keys(tentativeWindowBookingStatus).forEach((key) => {
    if (key.includes(pnrSegmentNo)) {
      currentSegTentativeWindowBookingStatus[key] = tentativeWindowBookingStatus[key];
    }
  });

  const currentSegTentativeWindowBookingStatusKeys = Object.keys(
    currentSegTentativeWindowBookingStatus,
  );

  for (let index = 0; index < currentSegTentativeWindowBookingStatusKeys.length; index++) {
    const key = currentSegTentativeWindowBookingStatusKeys[index];
    if (
      currentSegTentativeWindowBookingStatus[key].state.cancellationStatus ===
      LAST_MINUTE_CANCELLATION_STATUS.CANCELLATION_IN_PROGRESS
    ) {
      tentativeWindowStatus = currentSegTentativeWindowBookingStatus[key];
      break;
      //If any one is in progress return
    } else if (
      currentSegTentativeWindowBookingStatus[key].state.cancellationStatus ===
      LAST_MINUTE_CANCELLATION_STATUS.CANCELLATION_SUCCESS
    ) {
      cancellationSuccessPnrSegmentPax.push(currentSegTentativeWindowBookingStatus[key]);
    } else if (
      currentSegTentativeWindowBookingStatus[key].state.cancellationStatus ===
      LAST_MINUTE_CANCELLATION_STATUS.CANCELLATION_FAILED
    ) {
      cancellationFailedPnrSegmentPax.push(currentSegTentativeWindowBookingStatus[key]);
    }
  }

  if (
    cancellationSuccessPnrSegmentPax.length === currentSegTentativeWindowBookingStatusKeys.length ||
    cancellationFailedPnrSegmentPax.length === currentSegTentativeWindowBookingStatusKeys.length
  ) {
    tentativeWindowStatus =
      currentSegTentativeWindowBookingStatus[currentSegTentativeWindowBookingStatusKeys[0]];
  }
  return tentativeWindowStatus;
};
