export const CancelAndRebookConstants = {
    LOADING: "Loading...",
    MODIFY_BOOKING_HEADING: "Modify Booking",
    SPACE: ' ',
    TYPE_FOR_SEARCH: "Type for search",
    FETCH_LOCATION: "Fetching the Location",
    CHECK_AVAILABILITY: "CHECK AVAILABILITY",
    EDIT_BOOKING_DETAILS: "Edit Booking Details",
    FROM: "From",
    TO: "To",
    PICKUP: "PICKUP",
    RETURN: "RETURN",
    TRIP_START: "Trip Start",
    EDIT: "1 Edit",
    SELECT: "2 Select",
    REVIEW: "3 Review",
    COMMIT: "4 Commit",
    CONFIRMATION: "Confirmation",
    INVALID_DATES: "Invalid Dates",
    INVALID_Location: "From and To location should not be same",
    PAYMENT: "payment",
    REFUND: "refund",
    CONFIRM: 'confirm',
    POST: "POST",
    RETURN_SUB_TEXT: "Add date and time to change to return trip",
    OK: "ok",
    ALERT_MSG: "Do you want to change this booking to a round trip?",
    MODIFY_DATE_TEXT: "YOUR TRIP STARTS ON",
    MODIFY_END_DATE_TEXT: "YOUR TRIP ENDS ON",
    EDIT_TEXT: "Edit",
    SELECT_TEXT: "Select",
    REVIEW_TEXT: "Review",
    COMMIT_TEXT: "Commit",
    SUMMARY_URL: 'https://applinks.makemytrip.com/HJggnDBVT4',
    DONE: "DONE",
    NEXT: "NEXT",
    PICKUP_BY: "Pickup by",
    DROP_BY: "Drop by",
    ONLY_ONE_PICKUP_AND_DROP: "Only One Pickup and Drop",
    USER_ALERT_BTN_TEXT: "GOT IT"
}
