import React from 'react';
import PropTypes from 'prop-types';
import { TouchableOpacity, Text, StyleSheet } from 'react-native';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
import { useTheme } from '../../../../theme/theme.context';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const AgeBtn = (props) => {
  const { ...fonts } = getFont(true);
  const {psTheme: theme} =useTheme();
  const styles = createStyle(theme, fonts);
  const { label, selected, ageSelect, cardIndex, childIndex } = props;
  const containerStyle = [styles.container];
  const textStyle = [styles.text];
  if (label == selected) {
    containerStyle.push(styles.activeContainer);
    textStyle.push(styles.activeText);
  }
  return (
    <TouchableOpacity
      style={containerStyle}
      onPress={() => ageSelect(cardIndex, childIndex, label)}
    >
      <Text style={textStyle}>{label}</Text>
    </TouchableOpacity>
  );
};

const createStyle = (theme, fonts)=>StyleSheet.create({
  container: {
    ...getPlatformElevation(2),
    backgroundColor: theme.color.white,
    paddingHorizontal: 12,
    paddingVertical: 6,
    borderRadius: 4,
    margin: 4,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  activeContainer: {
    backgroundColor: theme.color.primary,
  },
  activeText: {
    color: theme.color.white,
  },
  text: {
    color: colors.black,
    textAlign: 'center',
    ...fonts.font20
  },
});

AgeBtn.propTypes = {
  cardIndex: PropTypes.number.isRequired,
  childIndex: PropTypes.number.isRequired,
  ageSelect: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  selected: PropTypes.string.isRequired,
};

export default AgeBtn;
