import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import getStyles from '../../details/components/HotelMamiCss';
import PropTypes from 'prop-types';
import { MOD_COUNTER_LIMITS } from '../HotelModificationConstants';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import CounterWpr from '../../../Common/CounterWpr'
class Counter extends React.Component {
  constructor(props) {
    super(props);
    this.fonts = getFont(true);
    this.styles = getStyles(this.fonts);
    this.state = {
      count: this.props.defaultState,
    };
  }

  static navigationOptions = {
    header: null,
  };

  increment() {
    if (this.state.count < this.props.maxPaxCount) {
      this.setState({
        count: this.state.count + 1,
      });
      this.props.onCounterClick(this.props.roomNo, this.props.paxType, this.state.count + 1);
    } else {
      this.props.onCounterClick(
        this.props.roomNo,
        this.props.paxType,
        this.state.count,
        MOD_COUNTER_LIMITS.INC,
      );
    }
  }

  decrement() {
    if (this.state.count > this.props.minCounterLimit) {
      this.setState({
        count: this.state.count - 1,
      });
      this.props.onCounterClick(this.props.roomNo, this.props.paxType, this.state.count - 1);
    } else {
      this.props.onCounterClick(
        this.props.roomNo,
        this.props.paxType,
        this.state.count - 1,
        MOD_COUNTER_LIMITS.DEC,
      );
    }
  }

  render() {
    return (
      <View style={[this.styles.flexRow, this.styles.alignCenter, this.styles.addMinusWrapper]}>
        <CounterWpr
            counterWrapperStyle={
             this.styles.counterWrapperStyle
            }
            counterValue={`0${this.state.count}`}
            decrementHandler={()=>this.decrement()}
            incrementHandler={()=>this.increment()}
            valueStyle={
              [this.styles.addCount,this.styles.boldFont]
            }
            
            minusStyle={
              [
                this.styles.minusSign,
                this.state.count > this.props.minCounterLimit && this.styles.activeMinusLink,
              ]
            }
            plusInner={this.state.count < this.props.maxPaxCount ? []:[this.styles.disabledColor]}
            plusOuter={this.state.count < this.props.maxPaxCount ? []:[this.styles.disabledColor]}
        />
      </View>
    );
  }
}

Counter.propTypes = {
  onCounterClick: PropTypes.func.isRequired,
  defaultState: PropTypes.number.isRequired,
  minCounterLimit: PropTypes.number.isRequired,
  roomNo: PropTypes.number.isRequired,
  paxType: PropTypes.string.isRequired,
  maxPaxCount: PropTypes.number.isRequired,
};

Counter.defaultProps = {
  minCounterLimit: 1,
};

export default Counter;
