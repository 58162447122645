import { appendUrlPrefix, getCurrentRoutePath } from "../webUtils";
import { SessionStorage} from "../localstorage";
import {history} from './routerFluxMock'
class RouterCustomStackClass {
  ROUTE_ACTION_TYPES = {
    PUSH: "PUSH",
    POP: "POP",
    REPLACE: "REPLACE",
    ONLOAD: "ONLOAD",
  };

  ROUTER_STACK_SESSION_STORAGE_KEY = "routerStack";

  handleRouting(location, action) {
    const routerStack = this.getRouterStack();

    switch (action) {
      case this.ROUTE_ACTION_TYPES.PUSH:
        this.pushRoute(location);
        break;
      case this.ROUTE_ACTION_TYPES.POP:
        this.popRouter();
        break;
      //In case of Replace action, pop the last route and push the current
      case this.ROUTE_ACTION_TYPES.REPLACE:
        this.popRouter(this.ROUTE_ACTION_TYPES.REPLACE);
        this.pushRoute(location);
        break;

      // If routerStack does not exist, add currentRoute
      // else if routerStack exist and last route is not current route then add the current route
      case this.ROUTE_ACTION_TYPES.ONLOAD:
        if (!routerStack) this.pushRoute(location);
        else if (
          routerStack[routerStack.length - 1].route !== getCurrentRoutePath()
        )
          this.pushRoute(location);
        break;
    }
  }

  getRouteDataForGivenPath(route) {
    const routerStack = this.getRouterStack();
    const routeIndex = routerStack.map((e) => e.route).indexOf(route);
    return routeIndex !== -1
      ? { ...routerStack[routeIndex], index: routeIndex }
      : null;
  }

  //This method expect a pathname to be routed  and pop routerStack till the position of given pathname (ex: popToRoute('/bookingSummary))
  popToRoute(route) {
    const routerStack = this.getRouterStack();
    const givenRouteData = this.getRouteDataForGivenPath(route);
    const currentRouteData = this.getRouteDataForGivenPath(
      getCurrentRoutePath()
    );
    const bookingSummaryRouteData = this.getRouteDataForGivenPath(
      appendUrlPrefix("/bookingSummary")
    );

    //When provided route doesn't exist in routerStack, i.e givenRouteData=null, (for Deep Link)
    if (!givenRouteData) {
      let historyGoBackIndex;
      let routerStackNewLength;
      // Find summary if summary available add it to the stack  (i.e [''bookingSummary`,backRoute])
      if (bookingSummaryRouteData) {
        routerStackNewLength = 2;
        historyGoBackIndex =
          currentRouteData.position - bookingSummaryRouteData.position - 1;
      } else {
        routerStackNewLength = 1;
        historyGoBackIndex =
          currentRouteData.position - routerStack[0].position;
      }
      routerStack.length = routerStackNewLength;
      this.setRouterStack(routerStack);
      history.go(-historyGoBackIndex);
      history.replace(route);
    } else {
      const popToIndex = currentRouteData.position - givenRouteData.position;

      //Deleting routes from routerStack by setting new length. Length of new routerStack =givenRouteIndex+1(length till the currentRoute index);
      routerStack.length = givenRouteData.index + 1;
      this.setRouterStack(routerStack);
      history.go(-popToIndex);
    }
  }

  //This method handles all POP event based on condition
  popRouter(actionType = undefined) {
    const routerStack = this.getRouterStack();
    if (actionType === this.ROUTE_ACTION_TYPES.REPLACE) {
      routerStack.pop();
      this.setRouterStack(routerStack);
    } else {
      const currentRoute = getCurrentRoutePath();
      const lastRoute = routerStack[routerStack.length - 1];
      const secondLastRoute =
        routerStack.length >= 2 ? routerStack[routerStack.length - 2] : null;

      if (secondLastRoute && secondLastRoute.route === currentRoute) {
        routerStack.pop();
        this.setRouterStack(routerStack);
        if (lastRoute.backRoute) {
          this.popToRoute(lastRoute.backRoute);
        }
      } else this.pushRoute(location);
    }
  }

  pushRoute(location) {
    const routerStack = this.getRouterStack();
    const route = getCurrentRoutePath();
    const backRoute = location?.state?.backRoute || null;

    //Check if routerStack is empty
    if (!routerStack) {
      this.setRouterStack([{ route, backRoute, position: history.length }]);
      return;
    }
    if (
      routerStack.map((e) => e.route).includes(route) &&
      routerStack[routerStack.length - 1].route === route
    ) {
      return;
    }
    // return this.popRouter();

    routerStack.push({ route, backRoute, position: history.length });
    this.setRouterStack(routerStack);
  }

  getRouterStack() {
    try {
      return JSON.parse(
        SessionStorage.getItem(this.ROUTER_STACK_SESSION_STORAGE_KEY)
      );
    } catch (error) {
      console.log("Unable to parse sessionStorage", error);
    }
  }
  setRouterStack(stack) {
      SessionStorage.setItem(
      this.ROUTER_STACK_SESSION_STORAGE_KEY,
      JSON.stringify(stack)
    );
  }
}

const RouterCustomStack = new RouterCustomStackClass();

export default RouterCustomStack;
