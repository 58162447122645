import { setItem as setItemInLocalStorage, getItem as getItemFromLocalStorage, removeItem, itemType } from 'MMT-UI/storages/client/localStorage';

import { setItemBySession, getItemBySession, removeItemBySession, itemType as SessionStorageItemType } from 'MMT-UI/storages/client/sessionStorage';

const createPromise = (getValue: () => string, callback?: (error: any) => boolean): Promise<string| boolean> => {
    return new Promise((resolve,reject) => {
        try{
        const value = getValue();
        resolve(value)
        } catch(err: any){
            callback && callback(err);
            reject(err);
        }
    })
}

export const getDataFromStorage = async  (key: string, callback?: (err: string) => boolean, shouldParseJson =false, type = itemType.ES) => {
    return createPromise(() => getItemFromLocalStorage(key, { isPiiData:true, shouldParseJson }, type), callback);
 }

export const setDataInStorage =  async <T extends {}>(key: string, value: string | boolean | T, callback?: (err: string) => boolean, isPiiData=true, type = itemType.ES) => {
    return createPromise( () => setItemInLocalStorage(key,value,{ isPiiData, shouldStringifyJson: typeof value !== 'string'}, type), callback);
}

 export const removeDataFromStorage = async (key: string, callback?: (err: string) => boolean) => {
    return createPromise( () => removeItem(key), callback);
 }

 export const getDataFromStorageSync =  (key: string, shouldParseJson =false, type = itemType.ES): string => {
    return getItemFromLocalStorage(key, { isPiiData:true, shouldParseJson }, type)
 }

 export const setDataInStorageSync = <T extends {}>(key: string, value: string | boolean | T , isPiiData=true, type = itemType.ES): boolean => {
    return setItemInLocalStorage(key,value,{ isPiiData, shouldStringifyJson: typeof value !== 'string'}, type);
}

 export class SessionStorage {

    static getItem(key: string,shouldParseJson =false, type = SessionStorageItemType.ES){
        return getItemBySession(key, { isPiiData:true, shouldParseJson }, type);
     }


    static setItem <T extends {}>(key: string, value: string | boolean | T, isPiiData=true, type = SessionStorageItemType.ES ){
        setItemBySession(key, value,{ shouldStringifyJson : typeof value !== 'string', isPiiData }, type)
    }

    static removeItem(key: string){
        removeItemBySession(key);
     }

 }