import React from 'react';
import { View, Text, StyleSheet, Image, TouchableOpacity } from 'react-native';
import AtomicCss from '../../commonStyles/AtomicCss';
import { NO_CANCELLATION_ICON } from '../../Utils/RailImageConstant';
import {
  cancellablePaxList,
  getButtonStyle,
  trainCancelledClickHandler,
} from '../../details/RailBookingDetailsUtil';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { useTheme } from '../../../theme/theme.context';
import { Action, CancellationCardProps } from '../../types';
import { ACTION_ID, CARD_ID } from '../../details/constants';
import isEmpty from 'lodash/isEmpty';
import getStyles from "./CancellationCardCss";
import { getActionButton } from '../../../Common/commonUtil';
import WhiteCardWrapper from '../../../Common/WhiteCardWrapper';

const CancellationCard = ({
  handleBottomSheetOverlay,
  response,
  card,
  disableCard,
  tdrRules,
  mmtAuth,
  loggingTrackInfo,
  handleViewTdrClick,
  onClickEventTracker,
}: CancellationCardProps) => {
  const { psTheme } = useTheme();
  const { color } = psTheme;
  const { fsStyle, ...fonts } = getFont(true);
  const styles = getStyles(fonts, color);
  const { header, subHeader } = card || {};

  const handleClick = (action: Action) => {

    if (action.actionId === ACTION_ID.CANCEL_POLICY) {
      handleBottomSheetOverlay(CARD_ID.CANCELLATION_POLICY);
    } else {
      if (!disableCard) {
       
        onClickEventTracker(action.actionFamily);
        trainCancelledClickHandler(
          response,
          action,
          tdrRules,
          mmtAuth,
          loggingTrackInfo,
          handleViewTdrClick,
        );
      }
    }
  };

  return (
    <View style={disableCard ? styles.disabledCard : []}>
      <View style={styles.topSection}>
        <View style={[styles.headingWrapper, AtomicCss.marginBottom10, AtomicCss.alignCenter]}>
          <View style={[AtomicCss.flex1, AtomicCss.flexRow, AtomicCss.alignCenter]}>
            <View style={styles.iconWrapper}>
              <Image style={styles.cancellationIconStyle} source={NO_CANCELLATION_ICON} />
            </View>
            {!isEmpty(header) && <Text style={styles.headingStyle}>{header}</Text>}
          </View>
        </View>
        {!isEmpty(subHeader) && (
          <View style={styles.detailsSection}>
            <Text
              style={styles.subHeadingStyle}
            >
              {subHeader}
            </Text>
          </View>
        )}
      </View>
      <View>
        {getActionButton(
          card,
          response,
          handleClick,
          {},
          true,
          false,
          styles.buttonRadiusStyle,
        )}
      </View>
    </View>
  );
};


export default WhiteCardWrapper(CancellationCard);
