import React from 'react';
import AtomicCss from '../../Rails/commonStyles/AtomicCss';
import {StyleSheet, Text, TextInput,TouchableOpacity, View} from 'react-native';
import styles from '../../Rails/Tdr/TdrSelectReason/TdrSelectReasonCss';
import {fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import {OTHERS, REASON_FOR_CANCELLATION} from "../../PostSalesConstant";
import RadioButton from '../../Common/RadioButton';

const ReasonForCancellation = ({ reasons, getTheSelectedData }) => {
  return (
    <View style={[AtomicCss.whiteBg, AtomicCss.elevation1, AtomicCss.marginTop10, AtomicCss.paddingBottom8]}>
      <View style={[reasonForCancellationStyles.PartCancellationHead, AtomicCss.elevation1]}>
        <Text style={[AtomicCss.blackFont, AtomicCss.blackText, AtomicCss.font16]}>
          {REASON_FOR_CANCELLATION}
        </Text>
      </View>
      {getReasonList(reasons, getTheSelectedData, styles)}
    </View>
  )
}

const getReasonList = (reasons, getTheSelectedData, styles) => reasons.map((reason, index) => (
  <React.Fragment>
    <TouchableOpacity onPress={() => getTheSelectedData(reason, index)} style={[
      AtomicCss.flexRowWithTopMargin20,
      AtomicCss.alignEnd,
      AtomicCss.paddingLeft20,
    ]}>
      <RadioButton
        selected={reason.selected}
        onChange={() => getTheSelectedData(reason, index)}
      />
      <Text style={[AtomicCss.blackText, AtomicCss.font14, AtomicCss.regularFont, AtomicCss.flex1, AtomicCss.tdrText]}>
        {reason.reason}
      </Text>
    </TouchableOpacity>
    {
      (reason.reason === OTHERS && reason.selected === true) && (
        <TextInput
          multiline
          placeholder="Enter Your Reason"
          style={reasonForCancellationStyles.textarea}
          onChangeText={text => getTheSelectedData(reason, index, text)}
        />
      )
    }
  </React.Fragment>
));

const reasonForCancellationStyles = StyleSheet.create({
    textarea: {
        borderColor: '#e1e7ee',
        borderWidth: 1,
        borderRadius: 6,
        marginTop: 16,
        minHeight: 112,
        padding: 12,
        lineHeight: 17,
        fontSize: 14,
        color: '#647a97',
        fontFamily: fonts.regular,
        textAlignVertical: 'top',
        marginLeft: 16,
        marginRight: 16,
        marginBottom: 16
    },
    PartCancellationHead: {
        backgroundColor: '#ffffff',
        paddingVertical: 18,
        paddingLeft: 10,
        borderBottomWidth: 1,
        borderBottomColor: '#ddd'
    },
})
export  default ReasonForCancellation;
