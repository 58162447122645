import React, { Component } from 'react';
import { Text, View, TouchableOpacity, Image, ScrollView } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import FlightSort from './FlightSort/FlightSort';
import FlightFilters from './FlightFilters';
import { getInitFilters } from 'core-ui-lib/flight/common/ODC/listingHelper';
import FlightListingTrackingHelper from '../../../../analytics/FlightListingTrackingHelper';
import createStyle from './styles';
import { ThemeContext } from '../../../../../../../theme/theme.context';
import BaseButton, { BUTTON_VARIANT } from '../../../../../../../Common/BaseButton';
import { getStaticData } from '../../../../../../../staticData/staticData';
import { getFlightSortingTab } from '../../../../utils';
import { getFont } from '../../../../../../../PostSalesStyles';

const closeImg = require('@mmt/legacy-assets/src/close_filter.webp');
const cross = require('@mmt/legacy-assets/src/b2bFilterCross.webp');

export default class FilterOverlay extends Component {
  static navigationOptions = {
    header: null,
  };

  static contextType = ThemeContext;

  constructor(props) {
    super(props);
    this.state = {
      filterActiveTab: this.props.activeTab,
      activeFilterItemStyle: null,
    };
    this.currSort = this.props.currSort;
    this.currSortOrder = this.props.currSortOrder;
    this.currFltrObj = this.props.currFltrObj;
  }

  componentDidMount() {
    FlightListingTrackingHelper.trackClickEvent(
      'Mob:Customer support:datechangelisting:sortNfilter',
      'openSorterFilter',
    );
  }

  filterTabAction = (index) => {
    this.setState({
      filterActiveTab: index,
    });
  };

  activeFilterItem = (index) => {
    if (this.state.activeFilterItemStyle === index) {
      this.setState({
        activeFilterItemStyle: '',
      });
    } else {
      this.setState({
        activeFilterItemStyle: index,
      });
    }
  };

  setSorter = (sortType, sortOrder) => {
    this.currSort = sortType;
    this.currSortOrder = sortOrder;
  };

  _clearFilters = () => {
    this.currFltrObj = getInitFilters(this.props.filterInfo);
    this.props.toggleFilters(this.currSort, this.currSortOrder, this.currFltrObj);
  };

  render() {
    const fonts = getFont(true);
    const styles = createStyle(this.context.psTheme, fonts);
    let corporateBooking = this.props.isCorporateBooking;
    const color = corporateBooking ? [this.context.psTheme.color.creamWhite8, this.context.psTheme.color.grey32] : [this.context.psTheme.color.pink4, this.context.psTheme.color.darkBlue11];
    const FlightSortingTab = getFlightSortingTab();
    const { sortAndFilterText, clearAllText, applyText } = getStaticData();
    return (
      <View style={styles.overlayWrapper}>
        <LinearGradient
          start={{ x: 1.0, y: 0.0 }}
          end={{ x: 0.0, y: 1.0 }}
          colors={color}
          style={styles.headerGradient}
        >
          <View style={styles.header}>
            <TouchableOpacity onPress={this.props.toggleFilters}>
              <Image style={styles.closeIcon} source={corporateBooking ? cross : closeImg} />
            </TouchableOpacity>
            <Text style={[styles.headerTitle]}>{sortAndFilterText}</Text>
            {this.state.filterActiveTab === 1 && (
              <TouchableOpacity style={{ marginLeft: 100,maxWidth:'30%' }} onPress={this._clearFilters}>
                <Text style={styles.clearAllText} numberOfLines={1}>{clearAllText}</Text>
              </TouchableOpacity>
            )}
          </View>
          <View style={styles.FltFilterTab}>
            {FlightSortingTab.map((item, index) => {
              let ActiveTab = index === this.state.filterActiveTab;
              return (
                <TouchableOpacity
                  style={[styles.FltFilterTabItem, ActiveTab ? styles.FilterActiveStyle : null]}
                  onPress={() => this.filterTabAction(index)}
                >
                  <Text style={styles.tabText}>{item}</Text>
                </TouchableOpacity>
              );
            })}
          </View>
        </LinearGradient>
        <ScrollView style={styles.overlayBody}>
          {this.state.filterActiveTab === 0 && (
            <FlightSort
              currSort={this.currSort}
              currSortOrder={this.currSortOrder}
              sorter={this.setSorter}
            />
          )}
          {this.state.filterActiveTab === 1 && (
            <FlightFilters
              filterInfo={this.props.filterInfo}
              currFltrObj={this.currFltrObj}
              ActiveFilterItem={this.activeFilterItem}
              ActiveFilterItemStyle={this.state.activeFilterItemStyle}
              currency={this.props.currency}
            />
          )}
        </ScrollView>
        <BaseButton
          text={applyText}
          variant={BUTTON_VARIANT.PRIMARY}
          clickHandler={() =>
            this.props.toggleFilters(this.currSort, this.currSortOrder, this.currFltrObj)
          }
          buttonStyle={styles.applyBtn}
          textStyle={{fontSize:fonts.fontSize.font16,...fonts.boldFontFamily}}
        />
      </View>
    );
  }
}
