import React, { useState } from 'react';
import { View, Text, TouchableOpacity, Image } from 'react-native';
import { getFont } from '../../../../PostSalesStyles';
import { useTheme } from '../../../../theme/theme.context';
import Checkbox from '../../../../Common/Checkbox';
import createStyles from "./AgreeOverlayCss";
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';

function AgreeOverlay({ specialClaimData, proceedClickHandler }) {

  const fonts = getFont();
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);

  const agreemntArr = [];
  const [proceedEnabled, setProceedEnabled] = useState();
  const [agreementSelectedList, setAgreementSelectedList] = useState([]);

  if (specialClaimData) {
    if (specialClaimData.checkCond1) {
      agreemntArr.push(specialClaimData.checkCond1);
    }
    if (specialClaimData.checkCond2) {
      agreemntArr.push(specialClaimData.checkCond2);
    }
    if (specialClaimData.checkCond3) {
      agreemntArr.push(specialClaimData.checkCond3);
    }
  }
  const checkboxHandler = (index) => {
    let agrmntSlctLst = [...agreementSelectedList];
    agrmntSlctLst[index] = !agrmntSlctLst[index];
    let prcdEnbld = true;
    if (agrmntSlctLst.length < agreemntArr.length) {
      prcdEnbld = false;
    }
    else {
      for (let i = 0; i < agreemntArr.length; i++) {
        if (agrmntSlctLst[i] === false) {
          prcdEnbld = false;
          break;
        }
      }
    }
    setAgreementSelectedList(agrmntSlctLst);
    setProceedEnabled(prcdEnbld);
  }

  return (
    <View>
      <Text style={styles.contentTitle}>{specialClaimData.checkCondHeader}</Text>
      <View style={styles.agreementList}>
        <Text style={styles.contentDesc}>{specialClaimData.checkCondUnderstood}</Text>
        {agreemntArr.map((agg, index) => {
      return <Checkbox label={agg} containerStyle={[AtomicCss.flexRow,AtomicCss.paddingVertical10, AtomicCss.paddingHz3,AtomicCss.alignCenter]} checked={agreementSelectedList[index]} onChange={() => checkboxHandler(index)} size={23} />  
    })}
      </View>
      <View style={styles.contentFooter}>
        <TouchableOpacity disabled={!proceedEnabled} style={styles.actionBtn} onPress={proceedClickHandler}>
          <Text style={proceedEnabled ? styles.actionBtnEnabledText : styles.actionBtnDisabledText}>{specialClaimData.checkCondProceed}</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}

export default AgreeOverlay;
