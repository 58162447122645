import React from 'react';
import LinearGradient from 'react-native-linear-gradient';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { View, Text, Image, ImageBackground, Platform } from 'react-native';
import isEmpty from 'lodash/isEmpty';
import createStyles from './TollRefundDetailsCss';
import { getFont } from '../../../../../PostSalesStyles';
import { TollRefundDetailsProps } from '../../../../types';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { useTheme } from '../../../../../theme/theme.context';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import { forwardIcon, cashRefundIcon, myCashIcon } from '../../../../CabImageConstants';

const TollRefundDetails: React.FC<TollRefundDetailsProps> = (props) => {
  const {
    card: { heading = '', actionList = [] } = {},
    response: { clientDetails: { tollRefund = {} } = {} } = {},
  } = props || {};
  const { discription = '', URL = '' } = tollRefund;
  const { fsStyle, ...fonts } = getFont();
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);

  const handleDeepLink = () => {
    if (!isEmpty(URL)) {
      if (Platform.OS === 'web') {
        window.open(url, '_blank').focus();
      } else {
        GenericModule.openDeepLink(URL);
      }
    }
  };

  return (
    <LinearGradient
      start={{ x: 1, y: 0 }}
      end={{ x: 0.7, y: 1 }}
      colors={theme.color.green2Gradient}
      style={styles.tollRefund}
    >
      <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom10]}>
        <Image style={styles.myCaseIconStyle} source={myCashIcon} />
        {!isEmpty(heading) && <Text style={styles.cardHeading}>{heading}</Text>}
      </View>
      {!isEmpty(discription) && <Text style={styles.discriptionStyle}>{discription}</Text>}
      {!isEmpty(URL) && !isEmpty(actionList) && <TouchableOpacity style={[AtomicCss.flexRow, AtomicCss.alignCenter]} onPress={handleDeepLink}>
          <Text style={styles.actionLableStyle}>{actionList[0].actionLabeltext}</Text>
        <Image style={styles.arrowStyle} source={forwardIcon} />
      </TouchableOpacity>}
      <ImageBackground source={cashRefundIcon} style={styles.refundBgImage}></ImageBackground>
    </LinearGradient>
  );
};

export default TollRefundDetails;
