import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { Text, View, Image, TouchableOpacity } from 'react-native';
import createStyles from './FlightInfoDtlsCss';
import { getAirlineImage } from '../../../../../flights-funnel/Listing/utils/FlightListingUtil';
import {
  CHEVRON_UP_BLUE_ICON2,
  CHEVRON_DOWN_BLUE_ICON2,
} from '../../../../FlightImageConstants';
import { useTheme } from '../../../../../theme/theme.context';
import { getFont } from '../../../../../PostSalesStyles';
import { getOperatedByAirlineText } from '../../data/dataAdapter';

const FltAmentiesDtls = (props) => {
  const [collapsedDtls, setCollapsedDtls] = useState(false);
  const {psTheme: theme} = useTheme();
  const fonts = getFont(true);
  const styles = createStyles(theme, fonts);
  const collpasedCon = () => {
    setCollapsedDtls(!collapsedDtls);
  };

  const operatedByAirline = getOperatedByAirlineText(props.legDetails.an, props.legDetails.opan);
  return (
    <View>
      <TouchableOpacity
        activeOpacity={0.85}
        disabled={isEmpty(props.legDetails.amenities)}
        onPress={() => collpasedCon()}
      >
        <View style={styles.FltAmentiesTopSection}>
          <View style={styles.flexRow}>
            <Image style={styles.Flt_Icon} source={{ uri: getAirlineImage(props.legDetails.ac) }} />
            <View>
              <Text style={styles.fltName}>{props.legDetails.an}</Text>
              <Text style={styles.fltCode}>
                {props.legDetails.ac} {props.legDetails.fno}
              </Text>
            </View>
            {!isEmpty(operatedByAirline) && (
              <Text style={styles.operatedByText}>{operatedByAirline}</Text>
            )}
          </View>
          {!isEmpty(props.legDetails.amenities) && (
            <Image
              style={styles.CollapsedArrow}
              source={collapsedDtls ? CHEVRON_UP_BLUE_ICON2 : CHEVRON_DOWN_BLUE_ICON2}
            />
          )}
        </View>
      </TouchableOpacity>
      {collapsedDtls && (
        <View style={[styles.FltAmentiesCollapsedSection]}>
          {!isEmpty(props.legDetails.amenities[0]) && (
            <View style={[styles.flexRow, styles.marginRight20]}>
              <Image
                style={styles.MealIcon}
                source={{ uri: props.legDetails.amenities[0].image }}
              />
              <Text style={[styles.font12, styles.LightText]}>
                {props.legDetails.amenities[0].type}
              </Text>
            </View>
          )}
          {!isEmpty(props.legDetails.amenities[1]) && (
            <View style={styles.flexRow}>
              <Image
                style={styles.WifiIcon}
                source={{ uri: props.legDetails.amenities[1].image }}
              />
              <Text style={[styles.font12, styles.LightText]}>
                {props.legDetails.amenities[1].type}
              </Text>
            </View>
          )}
        </View>
      )}
    </View>
  );
};

FltAmentiesDtls.propTypes = {
  legDetails: PropTypes.object.isRequired,
};

export default FltAmentiesDtls;
