import React from 'react';
import PropTypes from 'prop-types';
import {View, Text, TouchableOpacity, Image} from 'react-native';
import styles from './AgreeOverlayCss';
import BasePage from '../../../Common/PostSalesBasePage';
import {tick2} from '../../BusImageConstants';
import { label } from '@mmt/post-sales/src/staticData/staticData';
import { getFont } from '../../../PostSalesStyles';

let agreemntArr = [];
let agreementSelectLst = [];

export default class AgreeOverlay extends BasePage {
  constructor(props) {
    super(props, 'AgreeOverlay');
    agreemntArr = [];
    agreementSelectLst = [];
    if (this.props.specialClaimData && this.props.specialClaimData.checkCond1) {
      agreemntArr.push(this.props.specialClaimData.checkCond1);
      agreementSelectLst.push(false);
    }
    if (this.props.specialClaimData && this.props.specialClaimData.checkCond2) {
      agreemntArr.push(this.props.specialClaimData.checkCond2);
      agreementSelectLst.push(false);
    }
    if (this.props.specialClaimData && this.props.specialClaimData.checkCond3) {
      agreemntArr.push(this.props.specialClaimData.checkCond3);
      agreementSelectLst.push(false);
    }
    this.state = {
      agreementSelectList: agreementSelectLst,
      proceedEnabled: false
    };
  }

  render() {
    const condLstView = [];
    const fonts = getFont(true);

    agreemntArr.forEach(function (obj, index) {
      const cndSlctd = this.state.agreementSelectList[index];
      const cndSlctdStyle = cndSlctd ? 'Selected' : '';
      condLstView.push(<TouchableOpacity activeOpacity={0.9} style={styles.checkBtn} onPress={() => this.checkboxHandler(index)}>
        <View style={[styles.checkbox, styles[`checkbox${cndSlctdStyle}`]]}>
          {cndSlctd && <Image style={styles.checkedIcon} source={tick2} />}
        </View>
        <View style={styles.flex1}>
          <Text style={[styles.contentDesc, fonts.regularFontFamily, fonts.font14]}>{obj}</Text>
        </View>
                       </TouchableOpacity>);
    }, this);

    return (
      <View>
        <Text style={[styles.contentTitle, fonts.lightFontFamily, fonts.font21]}>{label('bus.claim.agreeText')}</Text>
        <View style={styles.agreementList}>
          <Text style={[styles.contentDesc, fonts.regularFontFamily, fonts.font14]}>{label('bus.claim.understood')} </Text>
          {condLstView}
        </View>
        <View style={styles.contentFooter}>
          <TouchableOpacity disabled={!this.state.proceedEnabled} style={styles.actionBtn} onPress={() => this.props.proceedClickHandler()}>
            <Text style={[this.state.proceedEnabled ? styles.actionBtnEnabledText : styles.actionBtnDisabledText, fonts.mediumFontFamily, fonts.font16]}>{label('bus.claim.yesProceed')}</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }

  checkboxHandler(index) {
    const agrmntSlctLst = this.state.agreementSelectList;
    agrmntSlctLst[index] = !agrmntSlctLst[index];
    let prcdEnbld = true;

    for (let i = 0; i < agrmntSlctLst.length; i++) {
      if (agrmntSlctLst[i] === false) {
        prcdEnbld = false;
        break;
      }
    }

    this.setState({
      agreementSelectList: agrmntSlctLst,
      proceedEnabled: prcdEnbld
    });
  }
}

AgreeOverlay.propTypes = {
  proceedClickHandler: PropTypes.func.isRequired,
  specialClaimData: PropTypes.object.isRequired
};
