import {
  getInitialCountriesData,
  getTotalCountriesBreakup,
  getTotalPrice,
  getCountriesDataForVisaType,
  getCountriesDataForPassengers,
  getCustCurrency,
} from '../utils';
import ACTION_TYPES from './actions';

export const initialState = {
  totalPrice: 0,
  custCurrency: '',
  countriesBreakup: [],
  countriesData: [],
};

export const addVisaReducer = (state, { type, payload }) => {
  switch (type) {
    case ACTION_TYPES.GET_API_SUCCESS:
      return {
        ...state,
        countriesData: getInitialCountriesData(payload.response),
        custCurrency: getCustCurrency(payload.response),
      };

    case ACTION_TYPES.SELECT_VISA_TYPE:
      const countriesData = getCountriesDataForVisaType(state, payload);
      const breakup = getTotalCountriesBreakup(countriesData);
      const newTotalPrice1 = getTotalPrice(countriesData);
      return {
        ...state,
        countriesBreakup: breakup,
        totalPrice: newTotalPrice1,
        countriesData: countriesData,
      };
    case ACTION_TYPES.SELECT_PASSENGER:
      const newCountriesData = getCountriesDataForPassengers(state, payload);
      const countriesBreakup = getTotalCountriesBreakup(newCountriesData);
      const newTotalPrice = getTotalPrice(newCountriesData);
      return {
        ...state,
        countriesBreakup: countriesBreakup,
        totalPrice: newTotalPrice,
        countriesData: newCountriesData,
      };

    default:
      return state;
  }
};
