import React, { useEffect } from 'react';
import { View, Image, Text } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { TravelCardType } from './type';
import { useTheme } from '../../theme/theme.context';
import { getFont } from '../../PostSalesStyles';
import isEmpty from 'lodash/isEmpty';
import useApi from '../useApi';
import { TRAVEL_CARD_URL } from '../../utils/NetworkUtils';
import { createStyles } from './style';

export default function TravelCard({ uuid, travelCardResponse, bookingID }: TravelCardType) {
  const { lobList, amount } = travelCardResponse || {};
  const [_, travelCardDetails, travelCardApi] = useApi(TRAVEL_CARD_URL);
  //@ts-ignore
  const { data, success, error } = travelCardDetails || {};
  const { title, sub_title, persuasion_text: description, icon } = data || {};
  const fonts = getFont();
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);

  useEffect(() => {
    let requestBody = {
      data: { lobs: lobList, travel_card_paid_amount: amount },
      uuid,
      org: 'MMT',
      bookingId: bookingID,
    };
    //@ts-ignore
    travelCardApi.httpPost({ body: requestBody });
  }, []);
  if (error || !success) {
    return null;
  }
  return (
    !isEmpty(data) && (
      <LinearGradient
        start={{ x: 0.4, y: 2 }}
        end={{ x: 1, y: 0.5 }}
        colors={[theme.color.white, theme.color.violet]}
        style={styles.travelCardCardStyle}
      >
        {!!icon && <Image source={{ uri: icon }} style={styles.infoIconStyle} />}
        <View>
          {!!title && <Text style={styles.titleStyle}>{title}</Text>}

          {!!sub_title && <Text style={styles.subTitleStyle}>{sub_title}</Text>}
        </View>
        {!!description && <Text style={styles.descStyle}>{description}</Text>}
      </LinearGradient>
    )
  );
}
