export const ERROR_CTAS_ACTION_ID = {
  BACK_TO_BOOKING: 'back_to_booking',
  HELPING_HAND: 'helping_hand',
  RETRY: 'retry',
  MYTRIP: 'mytrip',
}

export const MODIFICATION_POPUP_TYPES = {
  CONFIRMATION: "confirmation",
}

export const STAY_CONNECTED_CARD_CTA_TYPES = {
  CALL_MMT: 'CALL_MMT',
  CALL_PROPERTY: 'CALL_PROPERTY',
}

export const LANG_AWARE = {
  MODIFICATION: true
}

export const CANCELLATION_REASON_TYPES = {
  DATE_CHANGE:1,
  ADD_GUEST:2,
  BETTER_PROPERTY:3,
  NAME_CHANGE:4,
  PLAN_CANCELLED:5,
  OTHER:6
}

export const MODIFICATION_PRICE_CATEGORY = {
  Pay_NA: "Pay_NA",
  NoPay: "NoPay",
  PayExtra_0_200: "PayExtra_0_200",
  PayExtra_200_500: "PayExtra_200_500",
  PayExtra_500_1000: "PayExtra_500_1000",
  PayExtra_1000_1500: "PayExtra_1000_1500",
  PayExtra_1500_2000: "PayExtra_1500_2000",
  PayExtra_2000_3000: "PayExtra_2000_3000",
  PayExtra_3000: "PayExtra_3000"
}

export const INSURANCE_TYPE = {
  ASSURED: 6
}


