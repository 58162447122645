import React from 'react';
import {View, ScrollView, StyleSheet, Platform} from 'react-native';
import HeaderCross from './HeaderCross';
import PastPayments from './PastPayments';
import FareBreakUp from './FareBreakUp';


class PaymentDetails extends React.Component {
  static navigationOptions = {
    header: null
  };

  render() {
    return (
      <React.Fragment>
        <View style={styles.wrapper}>
          <HeaderCross
            title="Payment Details"
            headerShadow={true}
            headingTxt="font18"
            pageName={this.props.pageName}
            goBack={this.props.onBackPopUp}
            navigation={this.props.navigation}
          />
          <ScrollView style={{marginTop: 10}}>
            <PastPayments {...this.props}/>
            <FareBreakUp {...this.props}/>
          </ScrollView>
        </View>
      </React.Fragment>
    );
  }
}

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: '#f2f2f2',
    position: Platform.OS === 'web'? 'fixed': 'absolute',
    elevation: 3,
    zIndex: 3,
    width: '100%',
    height: '100%'
  }
});

export default PaymentDetails;
