import { StyleSheet, Platform } from 'react-native';

const createStyles = (theme, fonts) => StyleSheet.create({
  topCardStyle: {
    backgroundColor: theme.color.white,
  },
  cardWrapper: {
    marginBottom: 78,
  },
  pageWrapper: {
    marginTop: 16,
    marginHorizontal: 12,
    borderRadius: 16,
    marginBottom: 12,
    borderBottomWidth: 0,
    borderBottomColor: 'transparent',
  },
  buttonBottomWrapper: {
    position: 'absolute',
    bottom: 0,
    backgroundColor: theme.color.white,
    paddingHorizontal: 12,
    width: '100%',
    flex: 1,
    textAlign: 'center',
    alignItems: 'center',
    paddingVertical: 12
  },
  persuationText: {
    fontSize: 14,
    color: theme.color.green, 
    flex: 1,
    marginBottom: 12,
    ...fonts.boldFontFamily
  },
  selectModeWrapper: {
    position: 'relative',
    zIndex: 2,
  },
  uploadDocWrapper: {
    position: 'relative',
    zIndex: 4,
    top: 0,
  },
  stepBarWrapper: {
    paddingHorizontal: 24,
    marginTop: 20,
  },
  OverlayContent: {
    backgroundColor: theme.color.white,
    position: 'relative',
    zIndex: 12,
    width: '100%',
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    bottom: 0,
    overflow: 'hidden',
    ...Platform.select({
      web: {
        overflowY: 'auto',
      },
    }),
  },
  paymentModeWrapper: {
   flex:1
  },
  CancellationWrapper: {
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: theme.color.lightGrey
  },
  bottomSheetWprStyle:{
    padding:16
  },
});

export default createStyles;
