import React from 'react';
import PropTypes from 'prop-types';
import {Image, ScrollView, Text, TouchableOpacity, View} from 'react-native';
import getStyles from './HotelMamiCss';
import Header from '@mmt/legacy-commons/Common/Components/Header/Header';
import PostSalesHotelsBaseClass from './PostSalesHotelsBaseClass';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import {isEmpty} from 'lodash';
import { GUEST_ICON } from '../../HotelImageConstants';
import { openDialer } from '../../../Common/commonUtil';
import { getStaticData } from '../../../staticData/staticData';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const guestIcon = GUEST_ICON;


class CallHotel extends PostSalesHotelsBaseClass {


  constructor(props) {
    super(props, 'openCallHotel', 'CallHotelPage');
    this.fonts = getFont(true);
    this.styles = getStyles(this.fonts);
  }

  render() {
    const staticData = getStaticData();

    let hotelDetailResponse;
    let hotelBookingInfo;

    if (this.props.phoneNumbers == null) {
      hotelDetailResponse = this.props.response.hotelBookingInfo;
      hotelBookingInfo = hotelDetailResponse.hotelDetailsList[hotelDetailResponse.hotelDetailsList.length - 1];
    }
    
    return (
      <View style={[this.styles.whiteContainer, this.styles.transperantBgrnd]}>
        <Header title={this.props.headerText || staticData.callHotelCardText.additionalText.callHotelText}
                      onBackPress={this.onBackPress} type='Elevated' />
        <ScrollView>
          <View style={this.styles.PolicyHeading}>
            <Image style={this.styles.HotelIcon} source={guestIcon}/>
            <Text style={[this.styles.BlackText, this.styles.blackFont, this.styles.PolicyHeadingText]}>
              {staticData.callHotelCardText.additionalText.phoneNumbersText}
            </Text>
          </View>
          <View style={this.styles.PolicyDetailsContainer}>
            {this.showPhoneNumbers(hotelBookingInfo ? hotelBookingInfo.phoneNumber : this.props.phoneNumbers )}
          </View>
        </ScrollView>
      </View>
    );
  }

  showPhoneNumbers(phoneNumbers) {
    const views = [];
    const phoneNumberList = phoneNumbers.split(',');
    phoneNumberList.forEach((phoneNumberObj) => {
      if(!isEmpty(phoneNumberObj.trim())) {
        this.createPhoneNumbersView(views, phoneNumberObj.trim());
      }
    });
    return views;
  }

  createPhoneNumbersView(views, phoneNumberObj) {
    views.push(<View key={phoneNumberObj} style={[this.styles.mar15Bt, this.styles.flexRow]}>
      <View style={this.styles.ItemDisc}/>
      <TouchableOpacity onPress={() => {
        this.openCallHotel(phoneNumberObj);
      }}><Text>{phoneNumberObj}</Text></TouchableOpacity>
    </View>);
  }

  openCallHotel = (phoneNumber) => {
    openDialer(phoneNumber);
  };
}
CallHotel.propTypes = {
  response: PropTypes.object.isRequired,
  phoneNumbers: PropTypes.string,
};

export default CallHotel;
