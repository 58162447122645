import { StyleSheet } from 'react-native';

export default (theme, fonts) => StyleSheet.create( {
  FICardWrapper: {
    elevation: 2,
    shadowColor: theme.color.black,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 2,
    backgroundColor: theme.color.white,
    borderRadius: 4,
    marginBottom: 10,
  },
  FICardHeader: {
    paddingVertical: 12,
    paddingHorizontal: 15,
    borderBottomColor: theme.color.lightGrey3,
    borderBottomWidth: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  FICardHeaderText: {
    fontSize: fonts.fontSize.font16,
    color: theme.color.black28,
   ...fonts.boldFontFamily,
  },
  onewayIcon: {
    width: 10,
    height: 7,
    marginHorizontal: 5,
  },
  FICardBody: {
    padding: 15,
    paddingBottom: 0,
  },
  FICardInfoWrapper: {
    position: 'relative',
  },
  listLeftBrd: {
    backgroundColor: theme.color.blue11,
    width: 1,
    height: '100%',
    position: 'absolute',
    top: 5,
    left: 2,
  },
  FICardRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 12,
    position: 'relative',
    paddingLeft: 15,
  },
  FICardDot: {
    borderColor: theme.color.blue11,
    borderWidth: 1,
    backgroundColor: theme.color.white,
    borderRadius: 5,
    height: 5,
    width: 5,
    position: 'absolute',
    left: 0,
    top: 5,
  },
  FICardDotActive: {
    borderColor: theme.color.lightYellow3,
    backgroundColor: theme.color.lightYellow3,
  },
  FICardRowLabel: {
    fontSize: fonts.fontSize.font10,
    color: theme.color.black28,
    ...fonts.boldFontFamily,
  },
  FICardRowText: {
    fontSize: fonts.fontSize.font12,
    color: theme.color.black28,
    ...fonts.regularFontFamily,
  },
  FICardRowTextActive: {
    color: theme.color.lightYellow3,
    ...fonts.boldFontFamily,
  },
});
