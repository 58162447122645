import {Dimensions, Platform} from 'react-native';
import {getImagePath} from '../utils/PostSaleUtil';

export const COMMA = ',';

export const colors = {
  blueStartColor: '#5f88ff',
  blueEndColor: '#a768ff',
  blackStartColor: '#1f1c2c',
  blackEndColor: '#928dab',
  orangeStartColor: '#ff3e5e',
  orangeEndColor: '#ff7f3f',
  darkBlueStartColor: '#912086',
  darkBlueEndColor: '#3023ae',
  darkBlueCancelledStart: '#053cb2',
  darkBlueCancelledEnd: '#0c6fd9',
  confirmedWithCompletePaymentStartColor: '#c2f4e9',
  confirmedWithCompletePaymentEndColor: '#c2f4e9',
  confirmedWithPartialPaymentStartColor: '#e8f0ff',
  confirmedWithPartialPaymentEndColor: '#e8f0ff',
  cancelledStartColor: '#fbedcf',
  cancelledEndColor: '#fbedcf',
  failedStartColor: '#fedcde',
  failedEndColor: '#fedcde',
  completeStartColor: '#e7e7e7',
  completeEndColor: '#e7e7e7',
  onTripStart: '#c2f4e9',
  onTripEnd: '#c2f4e9'
};

// Postsales Icons
export const HotelStaticImg =  getImagePath('hotelIcon2.webp');
export const HotelIcon =  getImagePath('hotelIcon2.webp');
export const StarFilled =  getImagePath('filledStar.webp');
export const StarEmpty =  getImagePath('emptyStar.webp');
export const succussIcon =  getImagePath('SuccessfullIcon.webp'); 
export const onTripIcon =  getImagePath('ic_ontrip.webp');
export const completedIcon =  getImagePath('completedIcon.webp');
export const flightIcon =  getImagePath('fltIcon.webp');
export const rightArrowBlack =  getImagePath('rightArrowBlack.webp');
export const SupportIcon =  getImagePath('supportIcon.webp');
export const CarIcon =  getImagePath('carIcon.webp');
export const BusIcon =  getImagePath('busIcon.webp');
export const CruiseIcon =  getImagePath('cruiseIcon.webp');
export const Hotel =  getImagePath('ic_hotelIcon.webp');
export const ActivityIcon =  getImagePath('actIcon.webp');
export const RailIcon =  getImagePath('actIcon.webp');
export const HolidayIcon =  getImagePath('holidayIcon.webp');
export const DownloadIcon =  getImagePath('downloadIcon.webp');
export const MaleIcon =  getImagePath('male.webp');
export const FemaleIcon =  getImagePath('female.webp');
export const WalletIcon =  getImagePath('walletIcon.webp');
export const CardIcon =  getImagePath('cardIcon.webp');
export const GiftIcon =  getImagePath('giftIcon.webp');
export const MailIcon =  getImagePath('mailIcon.webp');
export const DocIconPending =  getImagePath('docIconPending.webp');
export const CollapseArrow =  getImagePath('collapseArrow.webp');
export const downLoadBtn =  getImagePath('downLoadBtn.webp');
export const flightBtn =  getImagePath('flightBtn.webp');
export const hotelBtn =  getImagePath('hotelBtn.webp');
export const carBtn =  getImagePath('carBtn.webp');
export const visaBtn =  getImagePath('visaBtn.webp');
export const insuranceBtn = getImagePath('insuranceBtn.webp');
export const cancelledTicketIcon =  getImagePath('cancelledTicketIcon.webp');
export const ZCBig =  getImagePath('ZCBig.webp');
export const changeIcon =  getImagePath('changeIcon.webp');
export const infoIcon =  getImagePath('ic_help.webp');
export const mapIcon =  getImagePath('mapIcon.webp');
export const emailIcon =  getImagePath('emailIcon.webp');
export const phoneIcon =  getImagePath('ic_phoneIcon.webp');
export const refreshIcon =  getImagePath('refreshIcon.webp');
export const crossIcon1 =  getImagePath('crossIcon1.webp');
export const changeCalIcon =  getImagePath('ic_cal.webp');
export const callBtn =  getImagePath('ic_call_icon.webp');
export const chatBtn =  getImagePath('ic_chat_icon.webp');
export const callToModifyIcon =  getImagePath('callToModify.webp');
export const myraIcon =  getImagePath('myraIcon.webp')
export const clockIcon =  getImagePath('ic_clock_icon.webp');
export const refundCardIcon = getImagePath('refundCardIcon.webp');
export const successIcon =  getImagePath('ic_success.webp');
export const againIcon =  getImagePath('ic_again.webp');
export const failureIcon =  getImagePath('hotel_add_pax_names_failure.webp');
export const VoucherIcon =  getImagePath('voucherIcon.webp');
export const LockIcon =   getImagePath('lock.webp');
export const TickSuccessIcon =  getImagePath('tickSuccess.webp');
export const RedCopyIcon =  getImagePath('red_copy.webp');
export const TotalDocIcon =  getImagePath('totalDocIcon.webp');
export const InfoBlueIcon =  getImagePath('infoBlueIcon.webp');
export const ErrorVisaIcon =  getImagePath('errorIcon_visa.webp'); 
export const PlaneReturnIcon =  getImagePath('planeReturn.webp');
export const CallIcon =  getImagePath('ic_callBlue.webp');
export const WebCheckInImg =  getImagePath('webCheckinFlt.webp');
export const WebCheckIconImgRaised =  getImagePath('webcheckinDelayIcon.webp');
export const VisaIcon =  getImagePath('visaIcon.webp');
export const GuestIcon =  getImagePath('guest_icon.webp');
export const TripInfoIcon =  getImagePath('tripInfoIcon.webp');
export const mealInclIcon = require('@mmt/legacy-assets/src/ic_mealInclIcon.webp');
export const transferInclIcon = require('@mmt/legacy-assets/src/ic_transferInclIcon.webp');
export const hotelInclIcon = require('@mmt/legacy-assets/src/ic_hotelInclIcon.webp');
export const activityInclIcon = require('@mmt/legacy-assets/src/ic_activityInclIcon.webp');
export const ZeroCancelIcon = getImagePath('zeroCancellationIcon.webp');
export const GreyInfoIcon = getImagePath('info-icon1.webp');
export const alertIcon = getImagePath('alertIcon.png');
export const greenClockIcon = getImagePath('clock_green2.png');
export const greenVoucherIcon = getImagePath("greenVoucherIcon.png");
export const additionalInclusionIcon = getImagePath('closeIcon.webp');
export const holidaysIcon = getImagePath('v5holidaysIcon.webp');

// Common Icons
export const refundIcon = getImagePath('refundIcon.webp'); // used in other lobs
export const changTvlrIcon =  getImagePath('changTvlrIcon.webp'); // used in other lobs
export const cancelledIcon =  getImagePath('cancelled.webp'); // used in other lobs
export const fdIcon =  getImagePath('fdIcon.webp');  // used in other lobs
export const logoMYSafety =  getImagePath('logoMYSafety.webp'); // used in other lobs
export const ArrowIcon =  getImagePath('Arrow.webp'); // used in other lobs
export const IOSBackArrow =  getImagePath('backArrowBlackIOS.webp'); // used in other lobs
export const BlueArrow =  getImagePath('blueArrow.webp'); // used in other lobs
export const partialIcon =  getImagePath('partialIcon.webp'); // used in other lobs
export const callIcon =  getImagePath('callIconBlue.webp'); // used in other lobs
export const chatIcon =  getImagePath('chatIconBlue.webp'); // used in other lobs
export const BackWhiteIcon = require('@mmt/legacy-assets/src/back-white.webp');
export const CloseIcon = require('@mmt/legacy-assets/src/ic_close_blue.webp');
export const CrossIcon = require('@mmt/legacy-assets/src/back.webp');
export const DocIcon = require('@mmt/legacy-assets/src/docIcon.webp');
export const rightArrow = require('@mmt/legacy-assets/src/blueRightArrow.webp');
export const backArrow = require('@mmt/legacy-assets/src/backArrowAndroid.webp');
export const blueDropIcondown = require('@mmt/legacy-assets/src/blueDropIcondown.webp');
export const blueDropIconUp = require('@mmt/legacy-assets/src/blueDropIconUp.webp');
export const backArrowIOS = require('@mmt/legacy-assets/src/backIosGrey.webp');
export const searchIcon = require('@mmt/legacy-assets/src/search.webp');
export const genericCardDefaultImage = require('@mmt/legacy-assets/src/Images/no_image_default.webp');
export const rightArrowBlue = require('@mmt/legacy-assets/src/right_arrow_blue.webp');
export const AndroidBackArrow = require('@mmt/legacy-assets/src/backArrowBlack.webp');
export const CrossDocumentIcon = require('@mmt/legacy-assets/src/crossIcon.webp');
export const RightArrowIcon = require('@mmt/legacy-assets/src/ic_arrow.webp');
export const CheckListIcon = require('@mmt/legacy-assets/src/ic_checklist.webp');
export const CheckListIconGray = require('@mmt/legacy-assets/src/ic_checklist_gray.webp');
export const TripHeaderIcon = require('@mmt/legacy-assets/src/trip_header_back_icon.webp');
export const InfoIcon = require('@mmt/legacy-assets/src/info_icon.webp');
export const rightArrowBlueDropdown = require('@mmt/legacy-assets/src/blueArrow.webp');

export const userIcon = getImagePath('userIcon2.webp')
export const locationMarkerGray = getImagePath('locationMarkerGray.webp')
export const locationMarkerRed = getImagePath('locationMarkerRed.webp')
export const phoneIcon2 = getImagePath('phoneIcon.webp')
export const successBgCarPickUpDetails = getImagePath('successBg_carPickupDetails.webp')
export const failedBgCarPickUpDetails = getImagePath('failedBg_carPickupDetails.webp')
export const flightIcon2 = getImagePath('flights_icon.webp')
export const busIcon = getImagePath('bus_icon.webp')
export const railsIcon = getImagePath('rails_icon.webp')
export const commentsIcon = getImagePath('commentsIcon.png')
export const otherTransportIcon = getImagePath('otherTransport.png');
export const insuranceIcon = getImagePath('insuranceIcon.webp');

export const screenWidth = Platform.OS === 'ios' ?  Dimensions.get('screen').width : Dimensions.get('window').width ;
export const screenHeight = Platform.OS === 'ios' ?  Dimensions.get('screen').height : Dimensions.get('window').height ;
export const HARDWARE_BACK_PRESS = 'hardwareBackPress';
export const WEB_CHECKIN_REQUEST_RAISED = 'Web Check In Request Raised';

export const tabsType = {
  VISA: 'VISA',
  FLIGHTS: 'FLIGHTS',
  HOTELS: 'HOTELS',
  TRANSFERS: 'TRANSFERS',
  ACTIVITIES: 'ACTIVITIES',
  INSURANCE: 'INSURANCE',
};

export const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export const holidayType = {
  COACH: 1,
  CAR: 2,
  RAIL: 3,
  CRUISE: 4,
  ACTIVITY: 5,
  TRANSFER: 6,
  HOTEL: 7,
  AGENT: 8,
  BUS: 9
};

export const buttonTypes = {
  PHONE: 'PHONE',
  CHAT: 'CHAT',
  LINK: 'LINK',
  CANCEL: 'CANCEL',
  MODIFY: 'MODIFY',
  INCLUSIONEXCLUSION: 'INCLUSIONEXCLUSION',
  PAYMENTDETAILS: 'PAYMENTDETAILS',
  DOWNLOADINVOICE: 'DOWNLOADINVOICE',
  DOWNLOADVOUCHER: 'DOWNLOADVOUCHER',
  CONTACTS: 'CONTACTS',
  ITINERARY: 'ITINERARY',
  PACKAGEDETAILS: 'PACKAGEDETAILS',
  TRAVELLERDETAILS: 'TRAVELLERDETAILS',
  DOWNLOADALLVOUCHERS: 'DOWNLOADALLVOUCHERS',
  SUBMIT: 'SUBMIT',
  ITINERARY_CARD: 'ITINERARY_CARD',
  CANCELPOLICY: 'CANCELPOLICY',
  CANCELLATION: 'CANCELLATION',
  DATECHANGE: 'DATECHANGE',
  UPLOADDOCUMENTS: 'UPLOADDOCUMENTS',
  VIEWUPLOADEDDOC: 'VIEWUPLOADEDDOC',
  WEBCHECKIN: 'WEBCHECKIN',
  SELECT_FLIGHT: 'SELECTFLIGHT',
  TRAVELPROFILE: 'TRAVELPROFILE',
  VIEWDETAILS: 'VIEWDETAILS',
  ADDDOCUMENTS: 'ADDDOCUMENTS',
  CONTACT_RO: 'CONTACTRO',
  CUSTOMER_FORM: 'CUSTOMERFORM',
  WTD_REFUND: 'WTD_REFUND',
  PROCEEDCANCELLATION:'PROCEEDCANCELLATION',
  ADD_CAR_PICKUP_DETAILS:'ADD_CAR_PICKUP_DETAILS',
  VIEW_CAR_PICKUP_DETAILS:'VIEW_CAR_PICKUP_DETAILS',
  VIEW_EDIT_CAR_PICKUP_DETAILS: 'VIEW_EDIT_CAR_PICKUP_DETAILS',
  INCLUSIONS_POPUP : 'INCLUSIONS_POPUP',
  EASYPAY_LINK: 'EASYPAY_LINK',
  RATEUS: 'RATEUS'
};

export const bookingState = {
  UPCOMING: 'Upcoming',
  ONTRIP: 'OnTrip',
  COMPLETED: 'Completed',
  CANCELLED: 'Cancelled',
  REFUNDED: 'Refunded'
};

export const bookingSubState = {
  UPCOMINGPAYMENTCOMPLETE: 'UpcomingPaymentComplete',
  UPCOMINGPAYMENTINCOMPLETE: 'UpcomingPaymentIncomplete'
};

export const itineraryType = {
  Flight: 'Air',
  Hotel: 'Hotel',
  Free: 'Free',
  Car: 'Car',
  Activity: 'Activity',
  Transfers: 'Transfers',
  Cruise: 'Cruise',
  Rail: 'Rail',
  Bus: 'Coach'
};

export const buttonDisplayTypes = {
  CAPSULE_WHITE: 'CAPSULE_WHITE',
  CAPSULE_WHITE_BORDER: 'CAPSULE_WHITE_BORDER',
  CAPSULE_GRADIENT: 'CAPSULE_GRADIENT',
  RECTANGLE_GRADIENT: 'RECTANGLE_GRADIENT',
  LINK_RECTANGLE_FULL: 'LINK_RECTANGLE_FULL'
};

export const holidayDownloadAction = {
  INVOICE: 'Holiday_CustomerInvoice',
  FLIGHT: 'Holiday_FlightVoucher',
  HOTEL: 'Holiday_HotelVoucher',
  CAR: 'Holiday_CarVoucher',
  ACTIVITY: 'Holiday_ActivityVoucher',
  HOLIDAY: 'Holiday_Voucher',
  BAGGAGE_TAG: 'Baggage_Tag'
};

export const HolidayBookingDetailsConstant = {
  TOP_CARD: 'topCard',
  BOOKING_SUMMARY_CARD: 'bookingSummary',
  CONTACT_CARD: 'contactCard',
  PAYMENT_CARD: 'paymentCard',
  TRAVELLERS_CARD: 'travellerDetails',
  CANT_FIND_CARD: 'cantFind',
  VISA_CARD: 'visaCard',
  KNOW_MORE_CARD: 'knowMore',
  CHANGE_PLANS_CARD: 'changePlans',
  CANCEL_BOOKING_CARD: 'cancellation',
  DATE_CHANGE_CARD: 'dateChange',
  ITINERARY_CARD: 'itineraryCard',
  REFUND_CARD: 'RefundCard',
  DOCUMENTS_UPLOAD_CARD: 'holidayDocumentsCard',
  WEB_CHECKIN_CARD: 'CheckListCard',
  VISA_STATUS_CARD: 'visaStatusCard',
  NEED_HELP_CARD: 'NeedHelpCard',
  WTD_CARD: 'RefundCard',
  AFFILIATE_FOOTER_CARD:'AffiliateFooterCard',
  PACKAGE_ADDONS: 'packageAddOns',
  INDIAN_CURRENCIES: ['₹', '&#8377;', 'INR', 'AED'],
  HOLIDAY_STORAGE_PERMISSION: 'holiday_booking_details_storage_permission',
  DOWNLOAD_FAILED: 'DOWNLOAD_FAILED',
  DOWNLOAD_SUCCESS: 'DOWNLOAD_SUCCESS',
  ADD_VISA_CARD:'addVisaCard'
};

export const HolidayCancellationConstant = {
  REFUND_MODE_MSG: 'Select a Refund Mode',
  CANCELLATION_HEADER: 'Cancellation',
  CANCEL_HOLIDAY_PACKAGE: 'Cancel Holiday Package',
  FETCHING_CANCELLATION_DETAILS: 'Fetching Cancellation Details',
  INITIALIZING_CANCELLATION: 'Initializing cancellation...'
};

export const GENERIC_ERROR_MSG = 'Something went wrong! Please try again!';


export const PLATFORM_IOS = 'ios';
export const PLATFORM_ANDROID = 'android';
export const PLATFORM_WEB = 'web';

export const USER_PLATFORM = {
  IOS: Platform.OS === PLATFORM_IOS,
  ANDROID: Platform.OS === PLATFORM_ANDROID,
  WEB: Platform.OS === PLATFORM_WEB
};

export const RESPONSE_SUCCESS = 'SUCCESS';
export const DEVICE_WINDOW = Platform.OS === 'ios' ? Dimensions.get('screen') : Dimensions.get('window');
export const fontSizes = {
  xxs: 8,
  xs: 10,
  xs2: 11,
  sm: 12,
  reg: 14,
  md: 16,
  lg: 18,
  lg2: 20,
  lg3: 22
};
export const zIndexes = {
  z0: 0,
  z1: 1,
  z2: 2,
  z3: 3,
  z4: 4,
  z5: 5
};

export const gradient = {
  white: ['#ffffff', '#ffffff'],
  green: ['#219393', '#26bd99'],
  blue: ['#3023ae', '#c86dd7'],
  blue2: ['#00d2ff', '#3a7bd5'],
  blue3: ['#763ece', '#411da0'],
  blue4: ['#3f4447', '#7fb7e2'],
  blue5: ['#3023ae', '#912086'],
  lightBlue: ['#53b2fe', '#065af3'],
  lightBlue2: ['#065af3', '#53b2fe'],
  ratingPoor: ['#ff6d3f', '#ff3e5e'],
  ratingAverage: ['#f3cd52', '#f09819'],
  ratingGood: ['#219393', '#26bd99'],
  purple: ['#a768ff', '#5f88ff'],
  orange: ['#ff7f3f', '#ff3e5e'],
  yellow: ['#f3d452', '#f09819'],
  red: ['#ff7f3f', '#ff3e5e'],
  grey: ['#989898', '#989898'],
  pink: ['#faaca8', '#ddd6f3'],
  lightPink: ['#ffffff', '#ffeeed'],
  maroon: ['#f5515f80', '#9f046980'],
  grey2: [colors.grey2, colors.grey3, colors.grey4, colors.grey5]
};

export const WEBBODY_TOP_SPACING = 60;

export const PACKAGE_INCLUSIONS = {
  MEALS: 'MEALS',
  TRANSFERS: 'TRANSFERS',
  HOTEL: 'HOTEL',
  ACTIVITY: 'ACTIVITY',
  AIRPORT_CAB: 'AIRPORT_CAB',
  BREAKFAST: 'BREAKFAST',
  LUNCH: 'LUNCH',
  DINNER: 'DINNER',
}

export const VIEW_HOTEL_DETAILS_TEXT = 'View Hotel Details';
export const SIMILAR_HOTELS_LIKE_TEXT = 'Or Similar Hotels like';
export const SIMILAR_TEXT = 'Or Similar';
export const VIEW_MORE_TEXT = 'View More';
export const VIEW_LESS_TEXT = 'View Less';
export const CONNECT_WITH_HELPDESK = "Connect With Our Helpdesk";
export const CONTACT_US = "Contact Us";

export const NEED_HELP_CARD_ITEMS = {
  AGENT_DETAILS: "AGENT_DETAILS",
  CUSTOMER_FORM: "CUSTOMER_FORM",
}

export const HOLIDAY_MINIMAL_BOOKING_TYPE = {
  FD: "FD",
  OFFLINE: "OFFLINE",
  ONLINE: "ONLINE",
}

export const HOLIDAY_DOWNLOAD_TRIGGERS = {
  HOLIDAY_BOOKING_CARD: 'Holiday_BookingCard',
  HOLIDAY_ADD_ON_VOUCHER: 'Holiday_OfflineVoucher',
  DOWNLOAD_SUCCESS: 'DOWNLOAD_SUCCESS',
};

export const HOLIDAY_VOUCHER_TYPE = {
  HOLIDAY: 'HOLIDAY',
  ADDON: 'ADDON',
  ADDON_VIATOR: 'ADDON_VIATOR',
};

export const DOWNLOAD_VOUCHER_STATES = {
  INITIAL: 'initial',
  PROGRESS: 'progress',
  SUCCESS: 'success',
  RETRY: 'retry',
};

export const PENDING_TEXT = "Pending"

export const TRANSFER_STATUS_CODE = {
  YELLOW: 0,
  GREEN: 1,
  RED: 2,
};

export const PAX_LABELS = {
  ADULTS: 'Adults',
  ADULT: 'Adult',
  CHILDREN: 'Children',
  CHILD: 'Child',
  INFANTS: 'Infants',
  SPACE: ' '
};

export const HOLIDAY_ADD_VISA_THANK_YOU_EVENT = 'holiday_booking_details_add_visa_thankyou_page';

export const PAYMENT_STATUS = {
  SUCCESS: 'SUCCESS',
};

export const HOLIDAY_CAR_PICKUP_DETAILS_PAGE_IDENTIFIER = 'carpickupdetails';
