import myTripsNavigation from "../../../../navigation/navigation";
import { isEmpty } from 'lodash';
import { getMyRequestCardData, handleCTAClick } from '../../../utils';
import { HotelData, HotelSummaryDataProps, MsrTicket } from '../cardInterfaces';
import { hotelIcon } from "apps/post-sales/src/hotel/HotelImageConstants";

export function getHotelSummaryData(data: HotelData, msrTicket: MsrTicket): HotelSummaryDataProps {
  const { hotelDetails, startsIn, arrTimeRange, deptTimeRange} = data;
  const lobName = data.lobType[0];
  const bookingId = data.bookingID;
  const destinationHotelDetailsIndex = hotelDetails && hotelDetails.length - 1;
  const { ctaInfoList: ctaDetails, infoCard } = data && data.bookingStateLayout || {};

  const destCityName = hotelDetails && hotelDetails[0].destinationCityName;
  let hotel;
  if (isEmpty(hotelDetails)) {
    hotel = data.bookingID;
  } else {
    const [room1] = hotelDetails;
    hotel = room1.hotelName;
  }

  const actions: Array<{ label: string; onPress: () => void }> = [];
  const pageData = {
    fromMyTrips: true,
  };
  const openDetails = () => {
    myTripsNavigation.openHotelBookingDetail(data, pageData);
  };
  let paxInfo =
    data.totalPax > 1
      ? `${data.leadPaxFirstName} + ${data.totalPax - 1} `
      : `${data.leadPaxFirstName}`;

  let dateTime = '';
  dateTime = `${paxInfo}`;
  const {
    checkInText,
    checkOutText,
    startDateText,
    startTimeText,
    imageUrl,
  } = (hotelDetails && hotelDetails[0]) || {};
  const hotelImage = !isEmpty(imageUrl) ? imageUrl : hotelIcon;
  const { endDateText, endTimeText } = hotelDetails && hotelDetails[destinationHotelDetailsIndex] || {};
  const { text, ctaInfo } = infoCard || {};
  const infoCardStatusText = text;
  const infoCardStatusCTA = ctaInfo && ctaInfo.text;

  if (ctaDetails && ctaDetails[0]) {
    actions.push({ label: ctaDetails[0]?.text, onPress: () => handleCTAClick(ctaDetails[0], data) })
  };
  if (ctaDetails && ctaDetails[1]) {
    actions.push({ label: ctaDetails[1]?.text, onPress: () => handleCTAClick(ctaDetails[1], data) })
  };

  const handleInfoClick = () => {
    if (infoCard) {
      handleCTAClick(infoCard.ctaInfo, data);
    }
  };

  return {
    status: data.status,
    iconUrl: hotelImage,
    imageUrl,
    destCityName,
    dateTime,
    hotel,
    actions,
    myRequestsCard: getMyRequestCardData(msrTicket),
    checkInText,
    checkOutText,
    startDateText,
    endDateText,
    startTimeText,
    endTimeText,
    startsIn,
    lobName,
    bookingId,
    infoCardStatusText,
    infoCardStatusCTA,
    arrTimeRange,
    deptTimeRange,
    openDetails,
    handleInfoPress: handleInfoClick
  };
}

export default {};