import React from 'react';
import { ScrollView, Text, TextInput, View, Platform } from 'react-native';
import getStyles from './HotelMamiCss';
import CommonHeader from '../../details/components/CommonHeader';
import {
  addCardListManually, getActionButton, getButtonStyle,
  isNotNullAndEmpty,
  isNullOrEmpty
} from '../utils/HotelBookingDetailUtil';
import Actions, { ActionConst } from '../../../navigation/postSalesNavigation';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import {getCommonHeaders, HOTEL_RESEND_VOUCHER_URL} from '../../../utils/NetworkUtils';
import {isNetworkAvailable} from '@mmt/legacy-commons/Common/utils/AppUtils';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import HotelBookingTrackingHelper from '../../HotelBookingTrackingHelper';
import PostSalesHotelsBaseClass from './PostSalesHotelsBaseClass';
import ViewStates from '../utils/ViewStates';
import {EMAIL_VALIDATION_REGEX} from '../../../PostSalesConstant';
import {PHONE_NUMBER_VALIDATION_REGEX} from '../../../PostSalesConstant';
import logAction from '@mmt/legacy-commons/Helpers/actionsLogger';
import { getStaticData } from '../../../staticData/staticData';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import HotelBookingDetailsConstant from '../HotelBookingDetailsConstant';
import { WHITE_LEFT_ARROW_ICON } from '../../HotelImageConstants';
import { getFont,ps_colors } from 'apps/post-sales/src/PostSalesStyles';
import InputField from '@Frontend_Ui_Lib_App/InputField/lib/InputField';
const headerIcon = WHITE_LEFT_ARROW_ICON;


class ResendHotelVoucher extends PostSalesHotelsBaseClass {

  constructor(props) {
    super(props,'openResendVoucherPage','ResendConfirmation');
    this.fonts = getFont(true);
    this.styles = getStyles(this.fonts);
    this.state = {
      email: '',
      phoneNumber: '',
      viewState: ViewState.DEFAULT
    };
    this.card = [];
  }

  componentWillMount() {
    const { submitText } = getStaticData();
    this.card = addCardListManually(1, 'Cancel_RecPriFull', 'CapsuleFullBtnFill', submitText, 'SendVoucherSubmit');
  }
  render() {
      const staticData = getStaticData();
    let headerTxt = staticData.resendHotelVoucherText.heading

      return (

      <View style={this.styles.whiteContainer}>
        <CommonHeader headerText={headerTxt} imgSrc={headerIcon}
                      backPressHandler={this.onBackPress}/>
        {this.state.viewState === ViewStates.DEFAULT && this.initLoading()}
        {this.state.viewState === ViewStates.LOADING && this.renderProgressView(staticData.resendHotelVoucherText.additionalText.resendHotelVoucherText)}
        {this.state.viewState === ViewStates.FETCH_DATA && this.resendVoucherConfirmation()}
        {this.state.viewState === ViewStates.ERROR && this.renderErrorView()}
        {this.state.viewState === ViewStates.NO_INTERNET && this.renderNoNetworkView()}
      </View>
    );
  }



  initLoading() {
    const staticData = getStaticData();
    return (
      <ScrollView>
        <View style={this.styles.HotelVoucherWrapper}>
          <Text style={[this.styles.font12, this.styles.lineHeight16, this.styles.mar15Bt, AtomicCss.alignLeft]}>{staticData.resendHotelVoucherText.additionalText.pleaseEnterEmailIdsOrPhoneNumberText}</Text>
          <View style={[this.styles.HotelVoucherForm, this.styles.mar20Bt]}>
            <View style={[this.styles.flexRow, this.styles.alignCenter, this.styles.mar30Bt]}>
              <Text style={[this.styles.boldFont, this.styles.DefaultText, this.styles.formText, AtomicCss.alignLeft]}>{staticData.resendHotelVoucherText.additionalText.emailText}</Text>
              <View style={[AtomicCss.flex1]}>
              <InputField
                placeholderTextColor={ps_colors.lightTextColor}
                customStyle={{
                  inputFieldStyle: [this.styles.inputFieldBookingDtls]
                }}
                placeholder={staticData.resendHotelVoucherText.additionalText.enterEmailIdText}
                onChangeText={(email) => this.setState({email})}
                value={this.state.email}
                inputProps={{
                  autoFocus:true,
                  keyboardType:'email-address',
                  returnKeyType:'go',
                  underlineColorAndroid:'transparent',
                }}
               />
              </View>
            </View>
            <View style={[this.styles.flexRow, this.styles.alignCenter, this.styles.mar15Bt]}>
              <Text style={[this.styles.boldFont, this.styles.DefaultText, this.styles.formText, AtomicCss.alignLeft]}>{staticData.resendHotelVoucherText.additionalText.phoneText}</Text>
              <View style={[AtomicCss.flex1]}>
                <InputField
                  placeholderTextColor={ps_colors.lightTextColor}
                  customStyle={{
                    inputFieldStyle: [this.styles.inputFieldBookingDtls]
                  }}
                    placeholder={staticData.resendHotelVoucherText.additionalText.enterTenDigitMobileNumberText}
                    onChangeText={(phoneNumber) => this.setState({phoneNumber})}
                    value={this.state.phoneNumber}
                    inputProps={{
                      keyboardType:'numeric',
                      underlineColorAndroid:'transparent',
                      maxLength:10
                  }}
                />
              </View>
            </View>
          </View>
          <View
            style={isNotNullAndEmpty(this.card[0].actionList) ? getButtonStyle(this.card[0].actionList) : this.styles.hideView}>
            {getActionButton(this.card[0], this.props.response, this.handleClick)}
          </View>
        </View>
      </ScrollView>);
  }

  handleClick = (action) => {
    const staticData = getStaticData(true);
    let alertText = '';
    const isEmailEmpty = this.state.email === '';
    const isPhoneEmpty = this.state.phoneNumber === '';
    if (isEmailEmpty && isPhoneEmpty) {
      alertText = staticData.resendHotelVoucherText.additionalText.emailPhoneCannotBeLeftBlank;
    } else {
      if (!isEmailEmpty && !EMAIL_VALIDATION_REGEX.test(this.state.email) && isNullOrEmpty(alertText)) {
        alertText = staticData.resendHotelVoucherText.additionalText.emailCannotBeLeftBlankOrInvalidText;
      }
      if (!isPhoneEmpty && (this.state.phoneNumber.length !== 10 || !PHONE_NUMBER_VALIDATION_REGEX.test(this.state.phoneNumber)) && isNullOrEmpty(alertText)) {
        alertText = staticData.resendHotelVoucherText.additionalText.phoneNumberCannotBeLeftBlankOrInvalidText;
        }
    }
    if (isNotNullAndEmpty(alertText)) {
      alert(alertText);
      return;
    }
    this.setState({
      viewState: ViewStates.LOADING
    });
    this.resendVoucher();
  };


  async resendVoucher() {
    try {
      const hasNetwork = await
        isNetworkAvailable();
      if (!hasNetwork) {
        this.setState({viewState: ViewState.NO_INTERNET});
        return;
      }

      const request = {
        phoneNo: this.state.phoneNumber,
        bookingId: this.props.response.bookingID,
        triggerName: 'Hotel_CustomerVoucher',
        sendAttachment: true,
        emailId: this.state.email,
        sendToBooker: false
      };
      const userInfo = await
        HotelBookingModule.getRequestHeader(this.props.response.bookingID, 'HOTEL_RESEND_VOUCHER');
      const response = await
        fetch(HOTEL_RESEND_VOUCHER_URL, {
          method: 'POST',
          body: JSON.stringify(request),
          headers: await
            getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo)
        });
      if (response.ok) {
        const body = await
          response.json();
          this.props.response.resendVoucherSuccess = body.success;
          this.props.response.showResendVoucherMessage = body.showResendVoucherMessage;
          this.setState({
            viewState: ViewStates.FETCH_DATA
          });
      } else {
        this.setState({
          viewState: ViewStates.ERROR
        });
      }
    } catch (error) {
      this.setState({
        viewState: ViewStates.ERROR
      });
      console.log(error);
    }
  }

  resendVoucherConfirmation = () => {
    let params = {
      response:this.props.response,
      isFromResendVoucher:true,
      email : this.state.email,
      phoneNumber : this.state.phoneNumber
    }
    if(Platform.OS === "web"){
      params = { ...params, type: ActionConst.REPLACE }
    }
    Actions.openSubmitPage(params);
    logAction('resend voucher confirmation clicked');
    HotelBookingTrackingHelper.trackClickEvent(this.trackingPageName,'ResendConfirmation_Submit', this.props.response);
  };

  fetchData(){
    this.resendVoucher();
  }

}



export default ResendHotelVoucher;

