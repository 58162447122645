import React from 'react';
import FlightSummaryCard from '../flights';
import HotelSummaryCard from '../hotels';
import TrainSummaryCard from '../trains';
import BusSummaryCard from '../bus';
import CabsSummaryCard from '../cabs';
import ActivitySummaryCard from '../activities';
import VisaSummaryCard from '../visa';
import HolidaySummaryCard from '../holidays';
import GiftCardSummaryCard from '../giftcard';
import HolidayPlanningSummaryCard from '../holidayPlanning';
import TripGuaranteeSummaryCard from "../TripGuarantee";
import { Lobs } from '../../../summaryConstants';
import { getFilteredMsrTicket } from '../../../../../utils/MsrUtils';
import LostIDSummaryCard from '../lostID';
import { BookingIdToTicketDetailMapType } from '../../../types';

import InsuranceCard from '../insurance';
interface SummaryCardProps {
  response: any,
  bookingIdtoTicketDetailmap: BookingIdToTicketDetailMapType,
  isUpcomingOrOnTripFirstBooking: boolean,
  whereToGoImageData: string,
}
const SummaryCard = ({ response, bookingIdtoTicketDetailmap, isUpcomingOrOnTripFirstBooking , whereToGoImageData }: SummaryCardProps) => {
  const filteredTicket = getFilteredMsrTicket(response, bookingIdtoTicketDetailmap);

  const { isLostBooking, lostBooking } = response || {};
  if (isLostBooking || lostBooking) {
    return <LostIDSummaryCard response={response}  />;
  }
  const primaryLob = response.lobType && response.lobType[0];
  if (response.isHolidayBooking === '1' || response.bookingID && response.bookingID.startsWith('NL')) {
    return <HolidaySummaryCard response={response} msrTicket={filteredTicket} isUpcomingOrOnTripFirstBooking={isUpcomingOrOnTripFirstBooking} topImage={whereToGoImageData} />;
  } else {
    switch (primaryLob) {
      case Lobs.Flight:
        return <FlightSummaryCard response={response} msrTicket={filteredTicket} isUpcomingOrOnTripFirstBooking={isUpcomingOrOnTripFirstBooking} topImage={whereToGoImageData} />;
      case Lobs.Hotel:
        return <HotelSummaryCard response={response} msrTicket={filteredTicket} isUpcomingOrOnTripFirstBooking={isUpcomingOrOnTripFirstBooking} topImage={whereToGoImageData} />;
      case Lobs.Bus:
        return <BusSummaryCard response={response} msrTicket={filteredTicket} isUpcomingOrOnTripFirstBooking={isUpcomingOrOnTripFirstBooking} topImage={whereToGoImageData}  />;
      case Lobs.Trains:
        return <TrainSummaryCard response={response} msrTicket={filteredTicket} isUpcomingOrOnTripFirstBooking={isUpcomingOrOnTripFirstBooking} topImage={whereToGoImageData}  />;
      case Lobs.Cars:
        return <CabsSummaryCard response={response} msrTicket={filteredTicket} isUpcomingOrOnTripFirstBooking={isUpcomingOrOnTripFirstBooking} topImage={whereToGoImageData}  />;
      case Lobs.Acme:
        return <ActivitySummaryCard response={response} msrTicket={filteredTicket} isUpcomingOrOnTripFirstBooking={isUpcomingOrOnTripFirstBooking} topImage={whereToGoImageData}  />;
      case Lobs.Visa:
        return <VisaSummaryCard response={response} msrTicket={filteredTicket} isUpcomingOrOnTripFirstBooking={isUpcomingOrOnTripFirstBooking} topImage={whereToGoImageData} />;
      case Lobs.GiftCard:
        return <GiftCardSummaryCard response={response} msrTicket={filteredTicket}  isUpcomingOrOnTripFirstBooking={isUpcomingOrOnTripFirstBooking} topImage={whereToGoImageData} />;
      case Lobs.Planning:
        return <HolidayPlanningSummaryCard response={response} msrTicket={filteredTicket} isUpcomingOrOnTripFirstBooking={isUpcomingOrOnTripFirstBooking} topImage={whereToGoImageData} />;
      case Lobs.TGS:
        return <TripGuaranteeSummaryCard response={response} msrTicket={filteredTicket} isUpcomingOrOnTripFirstBooking={isUpcomingOrOnTripFirstBooking} topImage={whereToGoImageData} />
      case Lobs.Insurance:
        return <InsuranceCard response={response}/>  
      default:
        return null;
    }
  }
}

export default React.memo(SummaryCard);
