import {
  DATE_MONTH,
  POSTSALES_DATE_TIME,
  HOUR_MIN_A,
  reformatDateString
} from '@mmt/legacy-commons/Helpers/dateTimehelpers';
import { titleCase } from '@mmt/legacy-commons/Helpers/stringHelpers';
import myTripsNavigation from "../../../navigation/navigation";
import upcomingBookingIcon from '@mmt/legacy-assets/src/postSales/summary/upcomingHotelIcon.webp';
import completedBookingIcon from '@mmt/legacy-assets/src/postSales/summary/completedHotelIcon.webp';
import cancelledBookingIcon from '@mmt/legacy-assets/src/postSales/summary/cancelledHotelIcon.webp';
import failedBookingIcon from '@mmt/legacy-assets/src/postSales/summary/failedHotelIcon.webp';
import { isNonEmpty } from '@mmt/legacy-commons/Common/utils/StringUtils';
import { getStaticData } from '../../../../staticData/staticData';
import {
  getDateTimeWithPaxInfo,
  unsuccessfulBookingTextHandler,
} from '../../../data/tripListManager';
import { isEmpty } from 'lodash';
import summaryCardStyle from '../baseCardStyles';
import { msrUpdateAction } from '../../../../utils/MsrUtils';
import { getNeedHelpData, getTalkToUsDetails } from "../../../utils";
import { getFont } from '../../../../PostSalesStyles';
import { BookingStatusCode } from '../../../summaryConstants';

export function getHotelSummaryData(data, msrTicket) {
  const staticData = getStaticData();

  const { fsStyle , ...fonts} = getFont(true);
  const checkInDate = data.minDeptDateMobile || reformatDateString(data.minDeptDate, POSTSALES_DATE_TIME, DATE_MONTH);
  const checkoutDate = data.maxArrDateMobile || reformatDateString(data.maxArrDate, POSTSALES_DATE_TIME, DATE_MONTH);
  const { hotelDetails, leadPaxFirstName, leadPaxLastName, hotelCommonFields } = data;
  const { totalNoOfRooms } = hotelCommonFields || {};
  const paxName = `${leadPaxFirstName} ${leadPaxLastName || ''}`;
  let hotel;
  let hotelCityInfo = '';
  const failedBooking = data.status === 'FAILED';
  
  const talkToUsDetails = getTalkToUsDetails(
    data.hotelDetails?.[0]?.productCode,
    data.uuid,
    data.isInternationalHotel,
    true
  );
  if (isEmpty(hotelDetails)) {
    hotel = data.bookingID;
  } else {
    const [room1] = hotelDetails;
    if (isNonEmpty(room1.hotelDestinationText)) {
      hotelCityInfo = room1.hotelDestinationText;
    }
    hotel = room1.hotelName;
  }

  const actions = [];
  const msrAction = msrUpdateAction(msrTicket);
  if (msrAction) {
    actions.push(msrAction);
  }
  const pageData = {
    fromMyTrips: true,
  };
  const openDetails = () => {
    myTripsNavigation.openHotelBookingDetail(data, pageData);
  };
  let paxInfo =
    data.totalPax > 1
      ? `${data.leadPaxFirstName} + ${data.totalPax - 1} `
      : `${data.leadPaxFirstName}`;

  let dateTime = '';
  if(data.isHotelDayUseBookingNew && data.dayUseHrString) {
    let checkInTime = reformatDateString(data.minDeptDate, POSTSALES_DATE_TIME, HOUR_MIN_A)
    let checkOutTime = reformatDateString(data.maxArrDate, POSTSALES_DATE_TIME, HOUR_MIN_A)
    dateTime = `${checkInDate} (${checkInTime} - ${checkOutTime},${data.dayUseHrString}) • ${paxInfo}`;
  } else {
    dateTime = `${checkInDate} - ${checkoutDate}${failedBooking ? '' : ` • ${paxInfo}`}`;
  }

  let { paymentInfo } = data || {};
  let pendingPaymentChargeDate = '';
  let tagStartText = '';
  let tagEndText = '';
  let paymentDueText = '';
  const needHelpData = getNeedHelpData(msrTicket, data.bookingID, data.needHelpData, data.showQuickResolve, talkToUsDetails);

  let iconUrl = '';
  let style;
  const yellowTextStyle = { ...summaryCardStyle.statusAwaited, fontFamily: fonts.bold, ...fsStyle.font14 };
  switch (data.status) {
    case 'UPCOMING':
      iconUrl = upcomingBookingIcon;
      if (data.isRTBBooking && data.rtbStatus === 'AWAITED') {
        dateTime = staticData.awaitingHostConfirmationText;
        style = yellowTextStyle;
      } else if (data.isNegotiatedRatesBooking && data.bookingStatus === BookingStatusCode.INSTANT_UNCONFIRMED){
        dateTime = staticData.awaitingHotelConfirmationText;
        style = yellowTextStyle;
      }
      break;
    case 'PENDING':
      if(!isEmpty(paymentInfo)) {
        pendingPaymentChargeDate = paymentInfo.paymentChargeDate;
        tagStartText = paymentInfo.tagStartText;
        tagEndText = paymentInfo.tagEndText;
        paymentDueText = paymentInfo.paymentDueText;
        iconUrl = upcomingBookingIcon;
      }
      break;
    case 'COMPLETED':
      iconUrl = completedBookingIcon;
      break;
    case 'CANCELLED':
      iconUrl = cancelledBookingIcon;
      dateTime = getDateTimeWithPaxInfo(data, staticData.cancelledText, dateTime, paxInfo);
      break;
    case 'FAILED':
      iconUrl = failedBookingIcon;
      hotelCityInfo = staticData.bookingSummaryText.additionalText.hotelBookingFailedText;
      dateTime = getDateTimeWithPaxInfo(data) || unsuccessfulBookingTextHandler(dateTime);
      break;
    default:
      iconUrl = upcomingBookingIcon;
      break;
  }
  return {
    status: data.status,
    iconUrl: iconUrl,
    hotelCityInfo,
    dateTime,
    hotel,
    paxInfo: `${titleCase(paxName)}`,
    roomCount: totalNoOfRooms,
    persuasions: [],
    actions,
    openDetails,
    style,
    failedBooking,
    pendingPaymentChargeDate,
    tagStartText,
    tagEndText,
    paymentDueText,
    needHelpData,
    bookingID: data.bookingID,
  };
}

export default {};
