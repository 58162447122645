import React, { useState, useRef, useEffect, useCallback } from 'react';
import { View, Animated, ScrollView, ActivityIndicator, Keyboard, Platform } from 'react-native';
import styles from './RefundOutOfTATFormCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import Header from '@mmt/legacy-commons/Common/Components/Header/SimpleHeader';
import UploadDocuments from './UploadDocuments';

import SelectMode from './SelectMode';
import StepBar from '../StepBar';
import AddCommentCard from './AddCommentCard';
import Actions from '../../navigation/postSalesNavigation';
import { getCommonHeaders } from '../../utils/NetworkUtils';
import {
  CUSTOMER_FORM_PAGE_TYPES,
  CUSTOMER_FORM_REQUEST_TYPES,
  REFUND_OOT_TRACKING,
} from './constants';
import useApi from '../useApi';
import { CUSTOMER_FORM_GET, CUSTOMER_FORM_POST } from '../../utils/NetworkUtils';
import ViewStateHandler from '../ViewStateHandler';
import { getStaticData } from '../../staticData/staticData';
import { isEmpty } from 'lodash';
import { escapeHTML } from '../../utils/PostSaleUtil';
import LinearGradient from 'react-native-linear-gradient';
import RefundProcessed from './RefundProcessed';
import RefundRequestFailed from './RefundRequestFailed';
import { KEYBOARD_HIDE_EVENT, KEYBOARD_SHOW_EVENT, LOBNAMES } from '../../PostSalesConstant';
import { resetToHotelDetail } from '../../hotel/details/utils/HotelBookingDetailUtil';
import { resetToFlightBookingDetail } from '../../flights/FlightBookingUtil';
import PostSalesBookingTrackingHelper from '../../PostSalesBookingTrackingHelper';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import useHardwareBackpress from '../useHardwareBackpress';
import ButtonWrapper from '../ButtonWrapper';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
const { fsStyle, ...fonts } = getFont(true);

const RefundOutOfTATForm = ({
  requestNo,
  bookingId,
  headerTitle,
  lobName,
  pageName = REFUND_OOT_TRACKING.PAGES.DEFAULT,
  formType = 'RT',
  fromSummary,
  isLostBookingId,
}) => {
  const [trackerSlide, setTrackerSlide] = useState('');
  const [refundModeList, setRefundModeList] = useState([]);
  const [attachmentSectionsData, setAttachmentSectionsData] = useState({});
  const [showGenericError, setShowGenericError] = useState(false);
  const [showGenericLoader, setShowGenericLoader] = useState(false);
  const [attachmentSections, setAttachmentSections] = useState([]);

  const [formGetReqInProgress, formGetReqResponse, formGetReqApi] = useApi(
    CUSTOMER_FORM_GET + `?formType=${formType}&bookingId=${bookingId}&requestNo=${requestNo}`,
  );
  const [formSubmitReqInProgress, formSubmitReqResponse, formSubmitReqApi] = useApi(
    CUSTOMER_FORM_POST,
  );

  const textareaVal = useRef('');
  const formInputMap = useRef({});
  const widthAnimation = useRef(Array.from({ length: 2 }, (_) => new Animated.Value(36))).current;
  const fadeAnimation = useRef(new Animated.Value(0)).current;
  const leftAnimation = useRef(new Animated.Value(40)).current;
  const shift = useRef(new Animated.Value(0)).current;

  const { loadingText, proceedText, createTicketText } = getStaticData(false);

  useEffect(() => {
    fetchCustomerForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingId]);

  useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener(KEYBOARD_SHOW_EVENT, _keyboardDidShow);
    const keyboardDidHideListener = Keyboard.addListener(KEYBOARD_HIDE_EVENT, _keyboardDidHide);
    return () => {
      keyboardDidShowListener.remove();
      keyboardDidHideListener.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (trackerSlide === CUSTOMER_FORM_PAGE_TYPES.REFUND_OUT_OF_TAT_FORM1) {
      PostSalesBookingTrackingHelper.trackShownEvent(pageName, REFUND_OOT_TRACKING.SHOW.REFUND_FORM_STEP_1);
    } else if (trackerSlide === CUSTOMER_FORM_PAGE_TYPES.REFUND_OUT_OF_TAT_FORM2) {
      PostSalesBookingTrackingHelper.trackShownEvent(pageName, REFUND_OOT_TRACKING.SHOW.REFUND_FORM_STEP_2);
    } else if (trackerSlide === CUSTOMER_FORM_PAGE_TYPES.REFUND_OUT_OF_TAT_FORM_SUBMITTED) {
      PostSalesBookingTrackingHelper.trackShownEvent(pageName, REFUND_OOT_TRACKING.SHOW.TICKET_SUCCESS);
    }
  }, [trackerSlide]);

  const _keyboardDidShow = (event) => {
    Animated.timing(shift, {
      toValue: -event.endCoordinates.height + styles.pageWrapper.marginBottom,
      duration: event.duration,
      useNativeDriver: true,
    }).start();
  };

  const _keyboardDidHide = (event) => {
    Animated.timing(shift, {
      duration: event.duration,
      useNativeDriver: true,
      toValue: 0,
    }).start();
  };

  useEffect(() => {
    if (formGetReqResponse) {
      setShowGenericError(showError(formGetReqResponse.error, formGetReqResponse.pageType));
      setTrackerSlide(formGetReqResponse.pageType);
      setRefundModeList(getRefundModeList(formGetReqResponse.refundModeDataList));
    }
  }, [formGetReqResponse]);

  useEffect(() => {
    if (formSubmitReqResponse) {
      const isError = showError(formSubmitReqResponse.error, formSubmitReqResponse.pageType);
      if (
        (trackerSlide == CUSTOMER_FORM_PAGE_TYPES.REFUND_OUT_OF_TAT_FORM2 ||
          trackerSlide == CUSTOMER_FORM_PAGE_TYPES.ERRORPAGE_TICKET_RAISED) &&
        isError
      ) {
        setTrackerSlide(CUSTOMER_FORM_PAGE_TYPES.ERRORPAGE_TICKET_RAISED);
      } else {
        setTrackerSlide(formSubmitReqResponse.pageType);
        onHandleTabChange(trackerSlide);
      }
      setShowGenericError(isError);
      setShowGenericLoader(false);
    }
    if (formSubmitReqResponse && formSubmitReqResponse.attachmentSectionList) {
      setAttachmentSections(formSubmitReqResponse.attachmentSectionList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formSubmitReqResponse]);

  useEffect(() => {
    setShowGenericLoader(formGetReqInProgress);
  }, [formGetReqInProgress]);

  const showError = (error, pageType) => {
    return (
      error ||
      !CUSTOMER_FORM_PAGE_TYPES[pageType] ||
      CUSTOMER_FORM_PAGE_TYPES[pageType] == CUSTOMER_FORM_PAGE_TYPES.ERRORPAGE
    );
  };

  const setTextareaVal = useCallback((comment) => {
    textareaVal.current = comment;
  }, []);

  const getRefundModeList = (refundModeDataList) => {
    return (refundModeDataList || []).filter(({ checkBoxData }) => {
      return checkBoxData && checkBoxData.checked;
    });
  };

  const setAttachmentSectionData = useCallback((attachmentSectionList, index) => {
    setAttachmentSectionsData((prevData) => ({
      ...prevData,
      [index]: attachmentSectionList ? attachmentSectionList : undefined,
    }));
  }, []);

  const fetchCustomerForm = useCallback(() => {
    formGetReqApi.httpGet({
      bookingId,
      uniqueId: 'GET_CUSTOMER_FORM',
    });
  }, [formGetReqApi, bookingId]);

  const submitPartialCustomerForm = useCallback(() => {
    formInputMap.current = {
      refundModeList,
      requestNo,
      otherInfo: formGetReqResponse && formGetReqResponse.otherInfo,
    };
    const submitFormObj = {
      formInputMap: formInputMap.current,
      bookingId,
      requestType: CUSTOMER_FORM_REQUEST_TYPES.INTERMEDIATE_FORM,
      formType,
      intermediatePage: CUSTOMER_FORM_PAGE_TYPES.REFUND_OUT_OF_TAT_FORM2,
    };
    formSubmitReqApi.httpPost({
      bookingId,
      uniqueId: 'CUSTOMER_FORM_SUBMIT',
      body: {
        ...submitFormObj,
      },
    });
  }, [
    refundModeList,
    formGetReqResponse,
    formInputMap,
    formSubmitReqApi,
    requestNo,
    bookingId,
    formType,
  ]);

  const submitCustomerForm = useCallback(async () => {
    const formInputMapModified = {
      ...formInputMap.current,
      issueDescription: escapeHTML(textareaVal.current),
    };
    const submitFormObj = {
      formInputMap: formInputMapModified,
      attachmentUrls: getAttachmentList(),
      bookingId,
      requestType: CUSTOMER_FORM_REQUEST_TYPES.POST_FORM,
      formType,
    };
    
    let headers = {} ;
    try{
      const userInfo = await HotelBookingModule.getRequestHeader(bookingId ? bookingId : '',"CUSTOMER_FORM");
      const loggingTrackInfo = JSON.parse(userInfo.loggingTrackingInfo);
      loggingTrackInfo.referrer = "TRIP_DETAILS";
      headers = await getCommonHeaders(userInfo.mmtAuth,  JSON.stringify(loggingTrackInfo ));
    } catch (e) {}
     formSubmitReqApi.httpPost({
       bookingId,
       uniqueId: 'CUSTOMER_FORM_SUBMIT',
       body: {
         ...submitFormObj,
       },
     },{headers});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    bookingId,
    formSubmitReqApi,
    formInputMap,
    textareaVal,
    formSubmitReqResponse,
    attachmentSectionsData,
    formType,
  ]);

  const onTryAgain = () => {
    PostSalesBookingTrackingHelper.trackClickEvent(
      `${pageName}:${REFUND_OOT_TRACKING.PAGES.ERROR}`,
      REFUND_OOT_TRACKING.CLICK.TRY_AGAIN,
      bookingId,
      null,
      { lobName },
    );
    setShowGenericLoader(true);
    submitCustomerForm();
  };

  const onBackPress = () => {
    if (trackerSlide == CUSTOMER_FORM_PAGE_TYPES.REFUND_OUT_OF_TAT_FORM2) {
      leftAnimation.setValue(40);
      fadeAnimation.setValue(0);
      setTrackerSlide(CUSTOMER_FORM_PAGE_TYPES.REFUND_OUT_OF_TAT_FORM1);
      setAttachmentSectionsData({});
      textareaVal.current = '';
    } else {
      Actions.pop();
    }
  };

  useHardwareBackpress().addListener(onBackPress);

  const onBackToBooking = (refresh) => {
    const trackPageName = `${pageName}:${
      trackerSlide == CUSTOMER_FORM_PAGE_TYPES.ERRORPAGE_TICKET_RAISED
        ? REFUND_OOT_TRACKING.PAGES.ERROR
        : REFUND_OOT_TRACKING.PAGES.TY
    }`;
    PostSalesBookingTrackingHelper.trackClickEvent(
      trackPageName,
      REFUND_OOT_TRACKING.CLICK.BACK_TO_BOOKING,
      bookingId,
      null,
      { lobName },
    );
    if(isLostBookingId && Platform.OS === 'web') {
      Actions.pop();
      return;
    }
    if (fromSummary || isEmpty(lobName)) {
      Actions.exit();
      return;
    }

    switch (lobName) {
      case LOBNAMES.HOTEL:
        resetToHotelDetail(bookingId, refresh);
        return;
      case LOBNAMES.GIFT:
        Actions.giftCardBookingDetail({ data: { refresh } });
        return;
      case LOBNAMES.CAR:
        Actions.cabBookingDetail({ data: { refresh } });
        return;
      case LOBNAMES.BUS:
        Actions.busBookingDetail({ data: { BOOKING_ID: bookingId, refresh }});
        return;
      case LOBNAMES.FLIGHT:
        resetToFlightBookingDetail(bookingId, refresh);
        return;
      case LOBNAMES.METRO:
        Actions.metroBookingDetail({ data: { refresh } });
        return;
      case LOBNAMES.HOLIDAY:
        Actions.holidayBookingDetail({ data: { refresh } });
        return;
      case LOBNAMES.SELF_DRIVE:
        Actions.selfDriveBookingDetail({ data: { refresh } });
        return;
      case LOBNAMES.RAIL:
        Actions.railBookingDetail({ data: { refresh } });
        return;
      case LOBNAMES.ACME:
        Actions.acmeBookingDetail({ data: { refresh } });
        return;
      case LOBNAMES.VISA:
        Actions.visaBookingDetail({ data: { refresh } });
        return;
        case LOBNAMES.TGS:
          Actions.tripGuaranteeBookingDetail({ data: { refresh } });
          return;
      default:
        Actions.exit();
        return;
    }
  };

  const onHandleTabChange = (currentSlide) => {
    if (currentSlide === CUSTOMER_FORM_PAGE_TYPES.REFUND_OUT_OF_TAT_FORM1) {
      Animated.timing(widthAnimation[0], {
        toValue: 12,
        duration: 500,
      }).start();

      Animated.timing(fadeAnimation, {
        toValue: 1,
        duration: 500,
      }).start();
      Animated.timing(leftAnimation, {
        toValue: 0,
        duration: 500,
      }).start();
    } else if (currentSlide === CUSTOMER_FORM_PAGE_TYPES.REFUND_OUT_OF_TAT_FORM2) {
      Animated.timing(widthAnimation[1], {
        toValue: 6,
        duration: 500,
      }).start();
      Animated.timing(fadeAnimation, {
        toValue: 1,
        duration: 500,
      }).start();
      Animated.timing(leftAnimation, {
        toValue: 0,
        duration: 500,
      }).start();
    }
  };

  const onSubmit = useCallback(() => {
    if (trackerSlide === CUSTOMER_FORM_PAGE_TYPES.REFUND_OUT_OF_TAT_FORM1) {
      PostSalesBookingTrackingHelper.trackClickEvent(
        `${pageName}:${REFUND_OOT_TRACKING.PAGES.SELECT_MODE}`,
        REFUND_OOT_TRACKING.CLICK.PROCEED_TO_UPLOAD,
        bookingId,
        null,
        { lobName },
      );
      submitPartialCustomerForm();
    } else if (trackerSlide === CUSTOMER_FORM_PAGE_TYPES.REFUND_OUT_OF_TAT_FORM2) {
      PostSalesBookingTrackingHelper.trackClickEvent(
        `${pageName}:${REFUND_OOT_TRACKING.PAGES.UPLOAD}`,
        `${REFUND_OOT_TRACKING.CLICK.SUBMIT_FORM}${isLostBookingId ? '_LostID' : ''}`,
        bookingId,
        null,
        { lobName },
      );
      submitCustomerForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackerSlide, submitPartialCustomerForm, submitCustomerForm]);

  const areAttachmentsUploaded = (formResponse) => {
    if (!formResponse || isEmpty(formResponse.attachmentSectionList)) {
      return false;
    }
    const iterable = formResponse.attachmentSectionList;
    return !iterable.some(
      ({ isRequired }, index) => isRequired && isEmpty(attachmentSectionsData[index]),
    );
  };

  const getAttachmentList = () => {
    const attachmentList = [];
    const iterable = attachmentSections || [];
    iterable.forEach((_, index) => {
      !isEmpty(attachmentSectionsData[index]) && attachmentList.push(attachmentSectionsData[index]);
    });
    return attachmentList;
  };

  const setRefundModeDataList = useCallback((refundModeDataList) => {
    setRefundModeList(getRefundModeList(refundModeDataList));
  }, []);

  const loadMainView = () => (
    <View style={[styles.contentWrapper, AtomicCss.flex1]}>
      <View style={styles.topStickySection}>
        <Header
          title={headerTitle}
          subtitle={{ text: formGetReqResponse ? formGetReqResponse.productDetail : '' }}
          headerShadow={true}
          iconPress={onBackPress}
        />
      </View>

      <ViewStateHandler
        {...{
          loading: showGenericLoader,
          loaderMsg: loadingText,
          error: showGenericError,
          onRetry: fetchCustomerForm,
        }}
      >
        <ScrollView>
          <View style={[AtomicCss.makeRelative, styles.pageWrapper]}>
            {trackerSlide === CUSTOMER_FORM_PAGE_TYPES.REFUND_OUT_OF_TAT_FORM1 && (
              <>
                <View
                  style={[
                    AtomicCss.whiteCard,
                    AtomicCss.marginHorizontal12,
                    AtomicCss.flex1,
                    AtomicCss.marginBottom12,
                    AtomicCss.paddingBottom12,
                  ]}
                >
                  <View style={styles.stepBarWrapper}>
                    <StepBar count={2} isActive={0} widthAnimation={widthAnimation} />
                  </View>
                  <Animated.View key={trackerSlide} style={[styles.selectModeWrapper]}>
                    {formGetReqResponse && !isEmpty(formGetReqResponse.refundModeDataList) && (
                      <SelectMode
                        data={formGetReqResponse}
                        setRefundModeList={setRefundModeDataList}
                        pageName={pageName}
                        bookingId={bookingId}
                      />
                    )}
                  </Animated.View>
                </View>
              </>
            )}
            {trackerSlide === CUSTOMER_FORM_PAGE_TYPES.REFUND_OUT_OF_TAT_FORM2 && (
              <Animated.View key={'keyboard'} style={{ transform: [{ translateY: shift }] }}>
                <View
                  style={[
                    AtomicCss.whiteCard,
                    AtomicCss.marginHorizontal12,
                    Platform.OS === 'web' ? '' : AtomicCss.flex1,
                    AtomicCss.marginBottom12,
                    AtomicCss.paddingBottom12,
                  ]}
                >
                  <View style={styles.stepBarWrapper}>
                    <StepBar count={2} isActive={1} widthAnimation={widthAnimation} />
                  </View>
                  <Animated.View
                    key={`${trackerSlide}_1`}
                    style={[styles.uploadDocWrapper, { left: leftAnimation }]}
                    opacity={fadeAnimation}
                  >
                    {formSubmitReqResponse && (
                      <UploadDocuments
                        data={formSubmitReqResponse}
                        setAttachmentSectionData={setAttachmentSectionData}
                        bookingId={bookingId}
                        pageName={pageName}
                        formType={formType}
                      />
                    )}
                  </Animated.View>
                </View>
                <View
                  style={[
                    AtomicCss.whiteCard,
                    AtomicCss.marginHorizontal12,
                    AtomicCss.flex1,
                    AtomicCss.marginBottom12,
                    AtomicCss.paddingBottom12,
                  ]}
                >
                  <Animated.View
                    key={`${trackerSlide}_2`}
                    style={[styles.uploadDocWrapper, { left: leftAnimation }]}
                    opacity={fadeAnimation}
                  >
                    <AddCommentCard setTextareaVal={setTextareaVal} />
                  </Animated.View>
                </View>
              </Animated.View>
            )}
          </View>
        </ScrollView>
        <View style={[styles.buttonBottomWrapper]}>
          {trackerSlide === CUSTOMER_FORM_PAGE_TYPES.REFUND_OUT_OF_TAT_FORM1 &&
            !formSubmitReqInProgress && (
              <ButtonWrapper
                clickHandler={onSubmit}
                btnText={proceedText}
                disabled={isEmpty(refundModeList)}
                btnTxtStyle={fsStyle.font16}
              />
            )}
          {trackerSlide === CUSTOMER_FORM_PAGE_TYPES.REFUND_OUT_OF_TAT_FORM2 &&
            !formSubmitReqInProgress && (
              <ButtonWrapper
                clickHandler={onSubmit}
                btnText={createTicketText}
                disabled={!areAttachmentsUploaded(formSubmitReqResponse)}
                btnTxtStyle={fsStyle.font16}
              />
            )}
          {formSubmitReqInProgress && (
            <View style={[AtomicCss.flexRow, AtomicCss.justifyCenter]}>
              <LinearGradient
                start={{ x: 1.0, y: 0.0 }}
                end={{ x: 0.0, y: 1.0 }}
                colors={[colors.darkBlue, colors.lightBlue]}
                style={styles.loaddingWrapper}
              >
                <ActivityIndicator
                  size="small"
                  color={colors.white}
                  style={styles.activityIndicator}
                />
              </LinearGradient>
            </View>
          )}
        </View>
      </ViewStateHandler>
    </View>
  );

  if (trackerSlide == CUSTOMER_FORM_PAGE_TYPES.ERRORPAGE_TICKET_RAISED) {
    return (
      <ViewStateHandler
        {...{
          loading: showGenericLoader,
          loaderMsg: loadingText,
          error: false,
        }}
      >
        <RefundRequestFailed
          onGoBack={() => {
            onBackToBooking(false);
          }}
          onTryAgain={onTryAgain}
          pageName={pageName}
          bookingId={bookingId}
        />
      </ViewStateHandler>
    );
  } else if (trackerSlide == CUSTOMER_FORM_PAGE_TYPES.REFUND_OUT_OF_TAT_FORM_SUBMITTED) {
    return (
      <RefundProcessed
        data={formSubmitReqResponse}
        onGoBack={() => {
          onBackToBooking(true);
        }}
        pageName={pageName}
        bookingId={bookingId}
      />
    );
  } else {
    return loadMainView();
  }
};

export default RefundOutOfTATForm;
