import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NO_INTERNET_LOGO } from '../../../../FlightImageConstants';
import { StyleSheet, Text, View, Image, PixelRatio } from 'react-native';
import ContainerView from '@mmt/legacy-commons/Common/Components/ContainerView';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import { getFont } from '../../../../../PostSalesStyles';
import BaseButton, { BUTTON_VARIANT } from '../../../../../Common/BaseButton';

class ErrorPage extends Component {
  render() {
    const { fsStyle, ...fonts } = getFont(true);
    return (
      <ContainerView style={styles.container}>
        <View style={styles.noInternet}>
          <Image source={NO_INTERNET_LOGO} style={styles.noInternetImage} />
        </View>
        <View style={styles.serverErrorFooterContainer}>
          <View>
            <Text style={[styles.noInternetTitle, fonts.boldFontFamily, fsStyle.font24]}>
              {this.props.title}
            </Text>
            <View>
              <Text style={[styles.noInternetSubtitle, fonts.regularFontFamily, fsStyle.font14]}>
                {this.props.subText}
              </Text>
            </View>
          </View>
          {this.props.btnAction && <BaseButton
            variant={BUTTON_VARIANT.CAPSULE}
            buttonStyle={[styles.BtnTouch, { width: 190 }, styles.CapsuleBtnFill]}
            text={this.props.btnAction.text}
            textStyle={[styles.btnStyle, fonts.boldFontFamily, fsStyle.font12]}
            clickHandler={() => {
              this.props.onRefresh(this.props.btnAction);
            }}
          />}
        </View>
      </ContainerView>
    );
  }
}

ErrorPage.propTypes = {
  onRefresh: PropTypes.func.isRequired,
  btnAction: PropTypes.object.isRequired,
  subText: PropTypes.string.isRequired,
  title: PropTypes.string,
};

ErrorPage.defaultProps = {
  title: 'Sorry, Something went wrong.',
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    flex: 1,
  },
  noInternet: {
    flex: 4,
    justifyContent: 'center',
    alignItems: 'center',
  },
  noInternetImage: {
    height: '80%',
    marginHorizontal: PixelRatio.getPixelSizeForLayoutSize(24),
    marginVertical: PixelRatio.getPixelSizeForLayoutSize(8),
    width: '70%',
    resizeMode: 'contain',
  },
  noInternetTitle: {
    marginTop: 24,
    marginHorizontal: 24,
    color: colors.black,
    lineHeight: 34,
    textAlign: 'center',
  },
  noInternetSubtitle: {
    marginTop: 8,
    marginHorizontal: 24,
    color: colors.lightTextColor,
    lineHeight: 21,
    textAlign: 'center',
  },
  noInternetCta: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  serverErrorFooterContainer: {
    flex: 3,
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
  },
  buttonStyle: {
    alignSelf: 'flex-start',
    marginTop: '10%',
  },
  CapsuleBtnFill: {
    paddingVertical: 10,
    borderRadius: 34,
  },
  BtnTouch: {
    borderRadius: 34,
    elevation: 2,
    marginTop: 20,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 2,
  },
  btnStyle: {
    color: colors.white,
    backgroundColor: 'transparent',
    alignSelf: 'center',
  },
});

export default ErrorPage;
