import React from 'react';
import {Image, Text, View} from 'react-native';
import styles from './CancellationCardCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {isEmpty} from 'lodash';
import {getActionButton} from "../../../../Common/commonUtil";
import {getButtonStyle, handleActionClicks, handleZcClickEvent} from "../../../utils/SelfDriveBookingDetailUtil";
import { CANCELLED_ICON } from '../../../SelfDriveImageConstant';

const CancellationCard = ({card, response}) => {
    return (
        <View style={[AtomicCss.marginBottom10, styles.cardBorder]}>
            <View style={[styles.infoCardWrapper]}>
                <View style={styles.headingIconWrapper}>
                    <Image style={styles.headingIconStyle} source={CANCELLED_ICON}/>
                </View>
                <View style={styles.infoCardContent}>
                    <Text
                        style={[AtomicCss.defaultText, AtomicCss.blackFont, AtomicCss.font14, AtomicCss.marginBottom15]}>{card.header}</Text>
                    <Text
                        style={[AtomicCss.regularFont, AtomicCss.font14, AtomicCss.defaultText, AtomicCss.lineHeight20]}>{card.subHeaders}</Text>
                    {!isEmpty(card.actionList) &&
                    <View
                        style={!isEmpty(card.actionList) ? getButtonStyle(card.actionList) : styles.hideView}>
                        {!isEmpty(card) ? getActionButton(card, response, handleClick) : null}
                    </View>
                    }
                </View>
            </View>
            {/*<TouchableOpacity onPress={(action) => handlePolicy(ZcConstants.ACTION_CANCELLATION_POLICY)} style={styles.redirectBtnWrapper} activeOpacity={.8}>
                <Text style={styles.linkBtnTxt}>View Cancellation Policy</Text>
                <Image style={styles.rightArrowIcon} source={rightArrow}/>
            </TouchableOpacity>*/}
        </View>
    )

     function handleClick (action)  {
        handleZcClickEvent(card, action, response, "Cancel Card").then(r => "");
    }

    function handlePolicy (action)  {
        handleActionClicks(card, action, response, "Cancel Policy Card").then(r => "");
    }

}


export default CancellationCard
