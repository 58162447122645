 export const ACTION_TYPES = {
    SET_TAB_CHANGE: 'SET_TAB_CHANGE',
    SET_BOTTOM_OVERLAY: 'SET_BOTTOM_OVERLAY',
    SET_PICKUP_DETAILS: 'SET_PICKUP_DETAILS',
    ADD_DROP_OFF_DETAILS: 'ADD_DROP_OFF_DETAILS',
    REMOVE_DROP_OFF_DETAILS: 'REMOVE_DROP_OFF_DETAILS',
    UPDATE_PICKUP_DETAILS: 'UPDATE_PICKUP_DETAILS',
    ON_FIELD_CHANGE: 'ON_FIELD_CHANGE',
    UPDATE_FOR_ALL_CASES: 'UPDATE_FOR_ALL_CASES',
    ON_FIELD_TOUCH: 'ON_FIELD_TOUCH',
  };
  