import React from 'react';
import { Text, View, TouchableOpacity, Image } from 'react-native';
import { getStaticData } from '../../../../../../staticData/staticData';
import createStyles from './RtFilterFooterCss';
import { useTheme } from '../../../../../../theme/theme.context';
import { getFont } from '../../../../../../PostSalesStyles';

const iconFilterImg = require('@mmt/legacy-assets/src/iconFilter.webp');
const iconSorterImg = require('@mmt/legacy-assets/src/iconSorter.webp');

const RtFilterFooter = (props) => {
  const fonts = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);

  const { sortText, filterText } = getStaticData();
  const { toggleFilters, currFltrObj, currSort, currSortOrder } = props;
  return (
    <View style={styles.footer}>
      <TouchableOpacity
        style={styles.sortItem}
        onPress={() => toggleFilters(currSort, currSortOrder, currFltrObj, 0)}
      >
        <View style={styles.iconWrapper}>
          <Image style={styles.sortIcon} source={iconSorterImg} />
          <Text style={[styles.sortLabel, styles.whiteText]}>{sortText}</Text>
        </View>
      </TouchableOpacity>
      <TouchableOpacity
        style={styles.sortItem}
        onPress={() => toggleFilters(currSort, currSortOrder, currFltrObj, 1)}
      >
        <View style={styles.iconWrapper}>
          <Image style={styles.sortIcon} source={iconFilterImg} />
          <Text style={[styles.sortLabel, styles.whiteText]}>{filterText}</Text>
        </View>
      </TouchableOpacity>
    </View>
  );
};
export default RtFilterFooter;
