import React from 'react';
import {Text} from 'react-native';
import PropTypes from 'prop-types';
import {colors, fonts} from '../../Styles/globalStyles';

export default class Timer extends React.Component {
  constructor(props) {
    super(props);
    this.timer = 0;
    this.state = {
      timeInMillis: this.props.timeInMillis,
      time: this.secondsToTime(this.props.timeInMillis)
    };
  }

  componentDidMount() {
    if (this.state.timeInMillis <= 0) {
      this.props.onEnd();
      return;
    }
    if (this.timer === 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  countDown = () => {
    // Minus one second, set state so a re-render happens.
    let timeInMillis = this.state.timeInMillis - 1000;
    const timeOver = timeInMillis < 0;
    if (timeOver) {
      timeInMillis = 0;
      this.setState({
        timeInMillis,
        time: this.secondsToTime(timeInMillis)
      });
      clearInterval(this.timer);
      this.props.onEnd();
      return;
    }
    this.setState({
      timeInMillis,
      time: this.secondsToTime(timeInMillis)
    });
  };

  secondsToTime = (millis) => {
    const {flipToHHMMSS, showSeconds} = this.props;
    let leftSecs = millis / 1000;
    const days = Math.floor(leftSecs / (60 * 60 * 24));
    leftSecs %= (60 * 60 * 24);
    const hours = Math.floor(leftSecs / (60 * 60));
    leftSecs %= (60 * 60);
    const minutes = Math.floor(leftSecs / 60);
    leftSecs %= 60;
    const seconds = Math.ceil(leftSecs);
    if (flipToHHMMSS && days <= 0) {
      return `${this.appendZero(hours)}h : ${this.appendZero(minutes)}m: ${this.appendZero(seconds)}s`;
    }
    return `${this.appendZero(days)}d : ${this.appendZero(hours)}h : ${this.appendZero(minutes)}m${showSeconds ? `: ${this.appendZero(seconds)}s` : ''}`;
  };

  appendZero = (number) => {
    if (number < 10) {
      return `0${number}`;
    }
    return number;
  };

  render() {
    const {time} = this.state;
    if (!time || time.length === 0) {
      return null;
    }
    return (
      <Text style={[styles.text, this.props.timerTextStyle]}>{time}</Text>
    );
  }
}

Timer.propTypes = {
  timeInMillis: PropTypes.number.isRequired,
  timerTextStyle: PropTypes.object,
  flipToHHMMSS: PropTypes.bool,
  showSeconds: PropTypes.bool,
  onEnd: PropTypes.func
};
Timer.defaultProps = {
  timerTextStyle: {},
  flipToHHMMSS: false,
  showSeconds: false,
  onEnd: () => {}
};
const styles = {
  text: {
    color: colors.white,
    fontSize: 12,
    fontFamily: fonts.bold,
    backgroundColor: colors.transparent
  }
};
