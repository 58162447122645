import React from 'react';
import { View, Text, ScrollView, TouchableOpacity, Platform } from 'react-native';
import  Actions  from '../../../../navigation/postSalesNavigation';
import LinearGradient from 'react-native-linear-gradient';
import AtomicCss from '../../../../bus/commonStyles/AtomicCss';
import IssueInfoCard from './IssueInfoCard';
import DescribeIssueCard from './DescribeIssueCard';
import UploadCard from './UploadCard';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import StickyHeader from '../../../../hotel/details/components/StickyHeader';
import styles from '../../../styles/CabMamiCss';
import SpecialClaimAddAttachmentModule from '@mmt/legacy-commons/Native/SpecialClaimAddAttachmentModule';
import NoNetworkView from '../../../../Common/Cancellation/NoNetworkView';
import PageLoadingError from '../../../../Common/Cancellation/PageLoadingError';
import { showShortToast } from '@mmt/legacy-commons/Common/Components/Toast';
import {getCabSubTitle, getCabTitle} from '../../../utils/CabBookingDetailUtil';
import {CAB_CLAIM_CONST} from '../../../../PostSalesConstant';
import { getNewUUID } from '../../../../utils/PostSaleUtil';
import CabBookingTrackingHelper from '../../../CabBookingTrackingHelper';
import {headerIcon2} from "../../../CabImageConstants";
import BusBookingTrackingHelper from '../../../../bus/BusBookingTrackingHelper';

class CarIssueDetail extends React.Component {

  constructor(props) {
    super(props);
    this.pageName = CAB_CLAIM_CONST.CLAIM_ISSUE_SELECT_TEXT;
    this.pageTitle = CAB_CLAIM_CONST.CLAIM_RAISE_REQUEST_TEXT;
    this.state = {
      issueList: props.issueList,
      description: '',
      viewState: ViewState.LOADING,
      acIssueOption: '',
      attachmentList: (Platform.OS === "web") ? ([]) : (new Map())
    }
    this.mmtAuth = '';
  }

  componentDidMount() {
    CabBookingTrackingHelper.trackLoadEvent('mob: cabs: mima support issue detail page',  this.props.response)
    this.setState({ viewState: ViewState.SHOW_DETAIL });
  }

  goBack = () => {
    Actions.pop();
  };

  refreshDetail = () => {
    this.setState({ viewState: ViewState.LOADING });
  }

  render() {
    return (
      <View style={AtomicCss.flex1}>
        {this.state.viewState === ViewState.NO_INTERNET && <NoNetworkView retryFunction={this.refreshDetail} />}
        {this.state.viewState === ViewState.ERROR && <PageLoadingError retryFunction={this.refreshDetail} />}
        {this.state.viewState === ViewState.SHOW_DETAIL && this.renderPage()}
      </View>
    );
  }

  getAttachmentBase64String() {
    const result = [];
    this.state.attachmentList.forEach((obj) => {
      result.push(obj.filePath);
    }, this);

    let imageResponse = '';
    let attachmentArray = [];
    let counter = 0;

    if (Platform.OS === 'ios') {
      this.state.attachmentList.forEach((obj) => {
        counter++;
        attachmentArray.push(this.getAttachmentObj(counter, obj.data));
      }, this);
    } else {
      result.forEach((obj) => {
        counter++;
        imageResponse = this.getImageResponse(obj);
        imageResponse.then((data) => {
          attachmentArray.push(this.getAttachmentObj(counter, data.imageResponse));
        });
      });
    }

    return attachmentArray;
  }

  getAttachmentObj(counter, imageResponse) {
    let attachmentObj = {
      attachmentNo: counter,
      fileName: getNewUUID(),
      fileExtension: "png",
      base64EncodedFileContent: imageResponse
    };
    return attachmentObj;
  }

  async getImageResponse(image) {
    const response = await SpecialClaimAddAttachmentModule.convertToBase64(image);
    return response;
  }

  prepareSubmitRequest() {

    let description = this.state.description;

    let issueList = this.groupByIssueForRequest(this.state.issueList);
    let attachmentReqList = this.getAttachmentBase64String();

    return {
      bookingId: this.props.response.bookingId,
      action: 'submitClaim',
      description: description,
      attachments: attachmentReqList,
      issues: issueList
    };
  }

  trackError = () => {
    BusBookingTrackingHelper.trackErrorEvent('Cab_Support_Issue_Submit');
  }

  getIssueBreadCumb = () => {
    let steps = this.props.response.carSupportData.scSteps;
    return (<View style={styles.breadCumbOuter}>
      {
        steps && steps.map((step, index) => (
          <Text style={index != 0 ? styles.activeBreadCumb : styles.nonActiveBreadcumb}>{index + 1}  {step}</Text>
        ))
      }
    </View >);
  }

  updateDescription = (description) => {
    this.setState({ description });
  }

  updateACOption = (acIssueOption) => {
    this.setState({ acIssueOption });
  }

  proceed = () => {
    let response = this.props.response;

    if (!this.state.description || this.state.description.length < 30) {
      showShortToast(CAB_CLAIM_CONST.CLAIM_FILL_DESCRIPTION_TEXT);
      return;
    }
    else {
      CabBookingTrackingHelper.trackClickEvent('mob: cabs: mima support issue detail page', 'submit issue')
      let submitRequest = this.prepareSubmitRequest();

      Actions.carSupportIssueSubmit({
        response,
        submitRequest
      });
    }
  }

  groupByIssue = (issueList) => {
    let issueMap = {};
    issueList.map((issueObj, index) => {
      let subIssueList = issueMap[issueObj.issue.name];
      if (!subIssueList) {
        issueMap[issueObj.issue.name] = issueObj.subIssue.name;
      }
      else {
        issueMap[issueObj.issue.name] = subIssueList + ', ' + issueObj.subIssue.name;
      }
    })
    return issueMap;
  }

  groupByIssueForRequest = (issueList) => {

    let issueListNew = [];
    issueList.map((issueObj, index) => {
      let obj = {
        categoryId: issueObj.issue.id,
        subcategoryId: issueObj.subIssue.id
      };
      issueListNew.push(obj);
    })

    return issueListNew;
  }

  updateAttachmentList = (map) => {
    this.setState({
      attachmentList: map
    });
  }

  renderPage() {

    return (
      <View style={AtomicCss.flex1}>
        <StickyHeader
          iconSrc={headerIcon2}
          title={getCabTitle(this.props.response)}
          headerColor={['#ffffff', '#ffffff']}
          titleStyle={[styles.DefaultText, styles.blackFont]}
          subTitle={getCabSubTitle(this.props.response)}
          subTitleStyle={[styles.DefaultText, styles.regularFont, styles.font12]}
          onBackPress={this.goBack}
        />
        <ScrollView>
          {this.getIssueBreadCumb()}
          <IssueInfoCard issueList={this.props.issueList} groupByIssue={this.groupByIssue} />
          <DescribeIssueCard updateDescription={this.updateDescription} carSupportData={this.props.response.carSupportData}/>
          <UploadCard response={this.props.response}
            maxAttachment={this.props.response.carSupportData.maxAttachment}
            updateAttachmentList={this.updateAttachmentList}
            uploadTitle={this.props.response.carSupportData.uploadTitle}
            uploadDescription={this.props.response.carSupportData.uploadDescription}
            attachmentList={this.state.attachmentList} />
        </ScrollView>
        <TouchableOpacity style={AtomicCss.btn} activeOpacity={0.7} onPress={this.proceed}>
          <LinearGradient
            colors={[colors.lightBlue, colors.darkBlue]}
            start={{ x: 0, y: 0 }}
            end={{ x: 1.0, y: 0 }}
            style={AtomicCss.btnGradient}
          >
            <Text style={[AtomicCss.font12, AtomicCss.whiteText, AtomicCss.boldFont]}>
              {this.props.response.carSupportData.proceedButton}
            </Text>
          </LinearGradient>
        </TouchableOpacity>
      </View>
    );
  }
}

export default CarIssueDetail;
