import React from 'react';
import {View, Text, Image, TouchableOpacity} from 'react-native';
import {calenderIcon, addressBoxIcon, swapIcon} from "../../CabImageConstants";
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import styles from './EditBookingDetailsFormStyle';
import {CancelAndRebookConstants} from './CancelAndRebookConstants';
import { checkIfExist } from '../../utils/CabBookingDetailUtil';
import CabBookingTrackingHelper from '../../CabBookingTrackingHelper';
import { CLICK_EVENT, PAGE_NAME } from '../../CabBookingDetailsConstant';
class EditBookingDetailsForm extends React.Component {

  handleDateChangeOnPress(isPickUp, action){
    if(isPickUp){
      CabBookingTrackingHelper.trackClickEvent(PAGE_NAME.MODIFY_BOOKING, CLICK_EVENT.PICKUP_DATA_CLICK);
    }else{
      CabBookingTrackingHelper.trackClickEvent(PAGE_NAME.MODIFY_BOOKING, CLICK_EVENT.RETURN_DATE_CLICK);
    }
    action(isPickUp)
  }

  onAddressClick = (type) => {
    if(type === 'from'){
      CabBookingTrackingHelper.trackClickEvent(PAGE_NAME.MODIFY_BOOKING, CLICK_EVENT.PICKUP_POINT_CLICK);
    }else if(type === 'to'){
      CabBookingTrackingHelper.trackClickEvent(PAGE_NAME.MODIFY_BOOKING, CLICK_EVENT.DESTINATION_POINT_CLICK);
    }
    this.props.openpopUp()
  }

  onSwapLocation = () => {
    CabBookingTrackingHelper.trackClickEvent(PAGE_NAME.MODIFY_BOOKING, CLICK_EVENT.SWAP_LOCATION_CLICK);
    this.props.onSwap();
  }


  render() {
    return (
      <View style={[AtomicCss.whiteCard, AtomicCss.paddingHz24, AtomicCss.marginTop0]}>
        <Text style={[AtomicCss.font16, AtomicCss.boldFont, AtomicCss.blackText, AtomicCss.marginBottom15]}>{CancelAndRebookConstants.EDIT_BOOKING_DETAILS}</Text>
        <View style={AtomicCss.flexRow}>
          <View style={AtomicCss.flex1}>
            {checkIfExist(this.props,'bookingDetailResponse.carBookingInfo.carDetail.carType') && <Text style={[AtomicCss.font14, AtomicCss.boldFont, AtomicCss.defaultText]}>
              {this.props.bookingDetailResponse.carBookingInfo.carDetail.carType}
            </Text>}
            {checkIfExist(this.props,'bookingDetailResponse.carBookingInfo.carDetail.carBrand')&&<Text style={[AtomicCss.font12, AtomicCss.blackFont, AtomicCss.defaultText, AtomicCss.marginTop5]}>
              {this.props.bookingDetailResponse.carBookingInfo.carDetail.carBrand}
            </Text>}
          </View>
          {checkIfExist(this.props,'bookingDetailResponse.carBookingInfo.carDetail.cabUrl') &&
          <Image source={{uri : this.props.bookingDetailResponse.carBookingInfo.carDetail.cabUrl}} style={styles.cabImage} />}
        </View>
        <View style={AtomicCss.makeRelative}>
          <TouchableOpacity style={styles.fieldBox} activeOpacity={0.8} onPress={() => this.onAddressClick("from")}>
            <Image source={addressBoxIcon} style={styles.addressBoxIcon} />
            <View style={AtomicCss.flex1}>
              <Text style={[AtomicCss.font12, AtomicCss.boldFont, AtomicCss.lightText]}>{CancelAndRebookConstants.FROM}</Text>
             {checkIfExist(this.props,'formData.from.name')&& <Text style={[AtomicCss.font16, AtomicCss.blackFont, AtomicCss.blackText]} numberOfLines={1}>
                { this.props.formData.from.name}
              </Text>}
            </View>
          </TouchableOpacity>
          <TouchableOpacity style={styles.fieldBox} activeOpacity={0.8} onPress={() => this.onAddressClick("to")}>
            <Image source={addressBoxIcon} style={styles.addressBoxIcon} />
            <View style={AtomicCss.flex1}>
              <Text style={[AtomicCss.font12, AtomicCss.boldFont, AtomicCss.lightText]}>{CancelAndRebookConstants.TO}</Text>
              {checkIfExist(this.props,'formData.to.name') &&<Text style={[AtomicCss.font16, AtomicCss.blackFont, AtomicCss.blackText]} numberOfLines={1}>
                { this.props.formData.to.name}
              </Text>}
            </View>
          </TouchableOpacity>
          <TouchableOpacity style={styles.swapBtn} activeOpacity={0.8} onPress={() => this.onSwapLocation()}>
            <Image source={swapIcon} style={styles.swapIcon} />
          </TouchableOpacity>
        </View>
        <View style={AtomicCss.flexRow}>
          <TouchableOpacity style={[styles.fieldBox, AtomicCss.flex1, AtomicCss.alignItemsStart]} activeOpacity={0.8}
                            onPress={() => this.handleDateChangeOnPress(true,this.props.openPopUpForDateChange)}>
            <Image source={calenderIcon} style={styles.calendarIcon} />
            <View>
              <Text style={[AtomicCss.font12, AtomicCss.boldFont, AtomicCss.lightText]}>{CancelAndRebookConstants.PICKUP}</Text>
              {checkIfExist(this.props,'formData.fromDate.day') && checkIfExist(this.props,'formData.fromDate.month')
              && checkIfExist(this.props,'formData.fromDate.week') &&
              <Text style={[AtomicCss.font14, AtomicCss.blackFont, AtomicCss.blackText, AtomicCss.marginBottom3]}>
                {this.props.formData.fromDate.day}  { CancelAndRebookConstants.SPACE }
                {this.props.formData.fromDate.month}  { CancelAndRebookConstants.SPACE }
                {this.props.formData.fromDate.week}
              </Text>}
              {checkIfExist(this.props,'formData.fromDate.time') &&
              <Text style={[AtomicCss.font12, AtomicCss.regularFont, AtomicCss.defaultText]}>{this.props.formData.fromDate.time} </Text>}
            </View>
          </TouchableOpacity>
          {
            ((this.props.bookingDetailResponse.carBookingInfo.carDetail.tripType === 'Outstation_One_Way' ||
                this.props.bookingDetailResponse.carBookingInfo.carDetail.tripType === 'Airport_Cabs') && (!this.props.tripStatusChanged)) ?
                (<TouchableOpacity style={[styles.fieldBox, AtomicCss.flex1, AtomicCss.marginLeft8, AtomicCss.alignItemsStart]} activeOpacity={0.8}
                onPress={() => {
                  CabBookingTrackingHelper.trackClickEvent(PAGE_NAME.MODIFY_BOOKING, CLICK_EVENT.RETURN_DATE_CLICK);
                  this.props.changeTripToRoundTrip();
                  }}>
              <Image source={calenderIcon} style={styles.calendarIcon} />
              <View style={{flex:1}}>
                <Text style={[AtomicCss.font12, AtomicCss.boldFont, AtomicCss.lightText]}>{CancelAndRebookConstants.RETURN}</Text>
                <Text style={[AtomicCss.font10, AtomicCss.regularFont, AtomicCss.lightText, AtomicCss.marginTop5]}>{CancelAndRebookConstants.RETURN_SUB_TEXT}</Text>
              </View>
            </TouchableOpacity>) :
                (<TouchableOpacity style={[styles.fieldBox, AtomicCss.flex1, AtomicCss.marginLeft8, AtomicCss.alignItemsStart]} activeOpacity={0.8}
                                         onPress={() => this.handleDateChangeOnPress(false,this.props.openPopUpForDateChange)}>
              <Image source={calenderIcon} style={styles.calendarIcon} />
              <View>
                <Text style={[AtomicCss.font12, AtomicCss.boldFont, AtomicCss.lightText]}>{CancelAndRebookConstants.RETURN}</Text>
                {checkIfExist(this.props,'formData.toDate.day') && checkIfExist(this.props,'formData.toDate.month')
              && checkIfExist(this.props,'formData.toDate.week') &&<Text style={[AtomicCss.font14, AtomicCss.blackFont, AtomicCss.blackText, AtomicCss.marginBottom3]}>
                  {this.props.formData.toDate.day} { CancelAndRebookConstants.SPACE }
                  {this.props.formData.toDate.month}  { CancelAndRebookConstants.SPACE }
                  {this.props.formData.toDate.week}
                </Text>}
                {checkIfExist(this.props,'formData.toDate.time')&&<Text style={[AtomicCss.font12, AtomicCss.regularFont, AtomicCss.defaultText]}>{this.props.formData.toDate.time} </Text>}
              </View>
            </TouchableOpacity>)
          }

        </View>
      </View>
    );
  }
}

export default EditBookingDetailsForm;
