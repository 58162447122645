import React from 'react';
import HTMLView from 'react-native-htmlview';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {View, Text, TouchableOpacity} from 'react-native';
import styles from './MimaBottomOverlayDtlsCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';

const CommonBottomOverlay = ({
  title, descriptionList, isHtmlDescription, htmlStyleSheet, btnData
}) => (
  <View style = {styles.bottomSeperation}>
    <Text style={styles.contentTitle}>{title}</Text>
    {!_.isEmpty(descriptionList) && !isHtmlDescription && descriptionList.map(description => (
      <Text style={styles.contentDesc}>{description}</Text>
        ))}
    {!_.isEmpty(descriptionList) && isHtmlDescription && descriptionList.map(description => (
      <HTMLView
        style={AtomicCss.marginBottom10}
        value={description}
        stylesheet={htmlStyleSheet}
      />
          ))}
    {!_.isEmpty(btnData) &&
    <View style={[styles.contentFooter, btnData.length === 1 ? styles.alignSelfFlexEnd : '']}>
        {btnData.map(data => (
          <TouchableOpacity
            style={styles.actionBtn}
            onPress={() => data.btnClickListener(data.clickActionData)}
          >
            <Text style={styles.actionBtnText}>{data.text}</Text>
          </TouchableOpacity>
        ))
        }
    </View>
      }
  </View>
);

CommonBottomOverlay.propTypes = {
  title: PropTypes.string.isRequired,
  descriptionList: PropTypes.arrayOf(PropTypes.string).isRequired,
  isHtmlDescription: PropTypes.bool,
  htmlStyleSheet: PropTypes.object,
  btnData: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    clickActionData: PropTypes.string.isRequired,
    btnClickListener: PropTypes.func.isRequired
  })).isRequired
};

CommonBottomOverlay.defaultProps = {
  isHtmlDescription: false,
  htmlStyleSheet: {}
};

export default CommonBottomOverlay;
