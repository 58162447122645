import { isEmpty } from 'lodash';
import React from "react";
import { Image, Text, View } from "react-native";
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { PROPERTY_DIMENSION_ICON } from '../../../HotelImageConstants';
import PropertyLayoutCardInfo from "./PropertyLayoutCardInfo";
import getStyles from './PropertyLayoutCSS';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const PropertyLayout = ({ response, card, pageName }) => {
	const { fsStyle, ...fonts } = getFont(true);
	const styles = getStyles(fonts);
	const propertyLayoutInfo = !isEmpty(response) && !isEmpty(response.hotelBookingInfo) && response.hotelBookingInfo.propertyLayoutInfo;

	if (isEmpty(propertyLayoutInfo)) {
		return null;
	}
	const { heading, subHeading, spaceDetails, title } = propertyLayoutInfo;

	return (

		<View style={[AtomicCss.whiteCardNew, AtomicCss.marginHz12, AtomicCss.borderRadius16]}>
			<View
				style={[
					styles.headerSec,
					AtomicCss.flexRow,
					AtomicCss.alignStart,
					AtomicCss.marginBottom15,
				]}
			>
				<Image source={PROPERTY_DIMENSION_ICON} style={styles.headingIcon} />
				<View>
					<Text
						style={[
							fonts.blackFontFamily,
							fsStyle.font16,
							AtomicCss.blackText,
							AtomicCss.marginBottom5,
							AtomicCss.lineHeight20,
						]}
					>
						{title}
					</Text>
					<Text
						style={[
							fsStyle.font14,
							fonts.regularFontFamily,
							AtomicCss.marginBottom5,
							AtomicCss.defaultText,
							AtomicCss.lineHeight20,
						]}
					>
						{heading}
					</Text>
					<Text
						style={[
							fonts.blackFontFamily,
							fsStyle.font14,
							AtomicCss.blackText,
							AtomicCss.marginBottom5,
							AtomicCss.lineHeight20,
						]}
					>
						{subHeading}
					</Text>
				</View>
			</View>
			<PropertyLayoutCardInfo data={spaceDetails} />
		</View>

	);
};

export default React.memo(PropertyLayout);
