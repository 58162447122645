import { Platform, StyleSheet } from 'react-native';
import { colors, fonts } from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';

const styles = StyleSheet.create({
  card: {
    backgroundColor: colors.white,
    marginBottom: 16,
    padding: 16,
    borderBottomColor: colors.lightGrey3,
    borderBottomWidth: 1,
    ...getPlatformElevation(Platform.select({ ios: 3, android: 4 })),
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: colors.lightGray,
    paddingBottom: 16,
    marginBottom: 12,
  },
  blueSmartIcon: {
    width: 29,
    height: 23,
    marginRight: 12,
  },
  bullet: {
    width: 8,
    height: 8,
    marginRight: 8,
    backgroundColor: colors.green,
    borderRadius: 50,
    top: 4,
  },
  couponsIcon: {
    width: 16,
    height: 10,
    marginRight: 8,
  },
  couponText: {
    color: colors.governorBay,
  },
  marginRight24: {
    marginRight: 24,
  },
  copyIcon: {
    width: 12,
    height: 12,
    marginLeft: 8,
  },
  payModeStyle: {
    borderTopColor: colors.lightGray,
    borderTopWidth: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 12,
    paddingTop: 12,
  },
  redirectBtnWrapper: {
    paddingTop: 16,
    paddingLeft: 32,
    paddingRight: 16,
    justifyContent: 'space-between',
    flexDirection: 'row',
    backgroundColor: colors.white,
    borderTopWidth: 1,
    borderTopColor: colors.gray1,
    alignItems: 'center',
    marginLeft: -32,
    marginRight: -16,
    marginTop: 20,
  },

  rightArrowIcon: {
    width: 24,
    height: 24,
  },
  linkBtnTxt: {
    fontSize: 14,
    fontFamily: fonts.bold,
    color: colors.azure,
  },
});
export default styles;
