import { USER_PLATFORM, SIMILAR_HOTELS_LOCAL, SIMILAR_HOTELS_WEB, SIMILAR_HOTELS } from './Constants/SimilarPropConstants';
import { NativeModules } from 'react-native';

export const getCurrencySymbol = (currency) => {
  if (currency !== 'INR') {
    return currency;
  }
  return '₹';
};

// ceil and convert price to INR format
export const ceilAndFormat = (value) => {
  if (typeof value !== 'number') {
    return value;
  }
  return Math.ceil(value).toString().replace(/(\d)(?=(\d\d)+\d$)/g, '$1,');
}

export const getCookieItem = (sKey) => {
  const { UserSessionModule } = NativeModules;
  if (!sKey) {
    return null;
  }
  if (document && document.cookie) {
    return UserSessionModule.gdprGetCookie(sKey, { isPiiData: true }, UserSessionModule.cookieType && UserSessionModule.cookieType.ES) || null;
  }
}

export const setCookieItem = (sKey, sValue, vEnd, sPath, sDomain, bSecure) => {

  const { UserSessionModule } = NativeModules;

  if (!sKey || /^(?:expires|max-age|path|domain|secure)$/i.test(sKey)) {
    return false;
  }
  UserSessionModule.gdprSetCookie(sKey, sValue, {maxAge: vEnd, path: sPath, domain: sDomain, secure: bSecure, isPiiData: true }, UserSessionModule.cookieType && UserSessionModule.cookieType.ES)
  return true;
}

const _setMCID = (id) => {
  if (!getCookieItem('mcid') && id) {
    const now = new Date();
    let time = now.getTime();
    time += 354 * 24 * 60 * 60 * 1000; /* one year cookie */
    now.setTime(time);
    setCookieItem('mcid', id, now, '/', '.makemytrip.com');
  }
};

export const generateUUIDForUser = () => {
  let d = new Date().getTime();
  if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
    d += performance.now(); // use high-precision timer if available
  }
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
};

export const getMCID = () => {
  if (typeof s !== 'undefined' && s.marketingCloudVisitorID) {
    return s.marketingCloudVisitorID;
  }
  return generateUUIDForUser();
}

export const getWebVisitorId = () => {
  const vid = getCookieItem('mcid') || getMCID();
  _setMCID(vid);
  return vid;
}

export const getClient = () => {
  return USER_PLATFORM.WEB ? 'PWA' : (USER_PLATFORM.ANDROID ? 'ANDROID' : 'IOS');
}

export const getBaseUrl = (debug) => {
  return debug ? SIMILAR_HOTELS_LOCAL : (USER_PLATFORM.WEB ? SIMILAR_HOTELS_WEB : SIMILAR_HOTELS);
}
