import React from 'react';
import { View, Text, Image } from 'react-native';
import createStyles from './PropertyTopCardCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { GREY_STAR_ICON } from '../../../../HotelImageConstants';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import AltAccoRatingComponent from '../../../../Common/altAccoRatingComponent/altAccoRatingComponent';

const HotelTopCard = ({ rating, propertyName, category, showAltAccoRating }) => {
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme)
  const renderStar = () => {
    let stars = [];
    const activeState = rating;
    for (let i = 0; i < 5; i++) {
      if (i < activeState) {
        stars.push(
          <Image style={[styles.starStyle, styles.activeStyle]} source={GREY_STAR_ICON}/>
        );
      } else {
        stars.push(
          <Image style={[styles.starStyle, styles.disabledStyle]} source={GREY_STAR_ICON}/>
        );
      }
    }
    return <View style={[AtomicCss.flexRow]}>{stars}</View>;
  };

  return (
    <View
      style={AtomicCss.flexRow}
    >
      <View style={[AtomicCss.marginRight18, AtomicCss.flex1]}>
        <View
          style={[
            AtomicCss.flexRow,
            AtomicCss.alignCenter,
            AtomicCss.flex1,
            AtomicCss.flexWrap,
          ]}
        >
          <Text
            style={[
              fsStyle.font24,
              fonts.blackFontFamily,
              AtomicCss.blackText,
              AtomicCss.marginLeft12,
            ]}
          >
            {propertyName}
          </Text>
        </View>
        {rating > 0 && !showAltAccoRating && <View style={[AtomicCss.marginLeft12, AtomicCss.marginTop8]}>
          {renderStar()}
        </View>}

        {showAltAccoRating && !!category &&
          <AltAccoRatingComponent
            category={category}
            customStyles={[AtomicCss.marginHz12, AtomicCss.marginTop12]}
          />
        }
      </View>
     
    </View>
  );
};
export default HotelTopCard;
