
export const RAIL_DETAILS = 'RAIL_DETAILS';
export const RAIL_TDR_RULES_LAYOUT = 'RAIL_TDR_RULES_LAYOUT';
export const RAIL_TDR_REQUEST = 'RAIL_TDR_REQUEST';

const initialState = {
  railResponseFromStore: [],
  amoutToRefund: 0,
  railTicketDownloadFailed: false,
  railCancellationDetails: [],
  noOfPaxToCancel: 0,
  noOfSelectedPax: [],
  noGoBack: false,
  yesCancel: false,
  railCanResponseFromStore: {},
  railTdrRulesAndLayoutFromStore: {},
  railTdrRequestFromStore: {}
};

export default function (state = initialState, action) {
  switch (action.type) {
    case RAIL_DETAILS:
      return {
        ...state,
        railResponseFromStore: action.payload
      };

    case RAIL_TDR_RULES_LAYOUT:
      return {
        ...state,
        railTdrRulesAndLayoutFromStore: action.payload
      };

    case RAIL_TDR_REQUEST:
      return {
        ...state,
        railTdrRequestFromStore: action.payload
      };
  }
  return state;
}
