/* eslint-disable max-len */
import React from 'react';
import Actions from '../../navigation/postSalesNavigation';
import PropTypes from 'prop-types';
import {Image, ScrollView, Text, View} from 'react-native';
import styles from '../styles/CabMamiCss';
import CommonHeader from '@mmt/legacy-commons/Common/Components/Header/CommonHeader';
import CabBookingTrackingHelper from '../CabBookingTrackingHelper';
import CapsuleBtnFill from '../../hotel/details/ActionButton/CapsuleBtnFill';
import CabBookingDetailsConstant from '../CabBookingDetailsConstant';
import {cancelIcon2, commonheaderIcon} from "../CabImageConstants";
const CAB_CHANGEPLANS_DETAILS = 'CabChangePlansDetails';

class CabDateChangeDetails extends React.Component {
  constructor(props) {
    super(props);
    this.datechange = [];
  }
  render() {
    return (
      <View style={[styles.whiteContainer, styles.transperantBgrnd]}>
        <CommonHeader
          headerText="Date/Time Change"
          imgSrc={commonheaderIcon}
          backPressHandler={this.onBackPress}
        />
        <ScrollView>
          <View style={styles.PolicyHeading}>
            <Image style={styles.CrossIcon} source={cancelIcon2} />
            <Text style={[styles.BlackText, styles.blackFont, styles.PolicyHeadingText]}>Date time or Pick up location change</Text>
          </View>
          <View style={styles.PolicyDetailsContainer}>
            {this.showDateTimeChange(this.datechange)}
            <CapsuleBtnFill
              action={{actionLabeltext: 'Cancel Booking'}}
              clickHandler={this.cancelBooking}
            />
          </View>
        </ScrollView>
      </View>
    );
  }

  componentDidMount() {
    CabBookingTrackingHelper.trackLoadEvent('Cab Details: CabDateChangeDetails', this.props.response);
  }

  showDateTimeChange(datechange) {
    const views = [];
    datechange.push('Sorry, we are currently working on this feature. In the mean time, we recommend you to cancel existing booking and make a fresh booking.\nCancellation charges (if any) applicable.');
    datechange.forEach((datechangeObject) => {
      this.createDateChangeView(views, datechangeObject);
    });
    return views;
  }

  cancelBooking = () => {
    Actions.cabCancellationDetails({
      response: this.props.response,
      card: this.props.card
    });
    CabBookingTrackingHelper.trackClickEvent(CAB_CHANGEPLANS_DETAILS, CabBookingDetailsConstant.CLICK_CANCEL_BOOKING);
  }

  createDateChangeView(views, datechangeObject) {
    views.push(<View style={[styles.mar15Bt, styles.flexRow]} key={datechangeObject}>
      <View style={styles.ItemDisc} />
      <Text>{datechangeObject}</Text>
               </View>);
  }
    onBackPress = () => {
      Actions.pop();
    }
}
CabDateChangeDetails.propTypes = {
  response: PropTypes.object.isRequired,
  card: PropTypes.object.isRequired
};
export default CabDateChangeDetails;
