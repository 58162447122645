/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import Actions from '../../../navigation/postSalesNavigation';
import { View, DeviceEventEmitter } from 'react-native';
import AtomicCss from '../../commonStyles/AtomicCss';
import { cancellablePaxList } from '../../utils/BusBookingDetailUtil';
import OverlayAlert from '../../components/OverlayAlert/OverlayAlert';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import BusBookingTrackingHelper from '../../BusBookingTrackingHelper';
import {
  CancellationPreviewRequest as CancelPreviewRequest,
  CANCELLATIONSELECTREFUND,
  FETCHCANCELLATIONPENALTY,
  InstantRefundReloadEvent,
  POST,
} from '../../../PostSalesConstant';
import { isNetworkAvailable } from '@mmt/legacy-commons/Common/utils/AppUtils';
import { BUS_CANCELLATION_URL, getCommonHeaders } from '../../../utils/NetworkUtils';
import { getCancelRequestKeyName, retrieveObjFromAsyncStorage } from '../../../Common/commonUtil';
import PageLoader from '../../../Common/Cancellation/PageLoader';
import NoNetworkView from '../../../Common/Cancellation/NoNetworkView';
import PageLoadingError from '../../../Common/Cancellation/PageLoadingError';
import BusSelectRefundMode from '../../../Common/InstantRefund/RefundModes/BusSelectRefundMode';
import { label } from '@mmt/post-sales/src/staticData/staticData';
import get from 'lodash/get';

class BusRefundMode extends React.Component {
  static navigationOptions = {
    header: null,
  };

  constructor(props) {
    super(props, 'BusRefundMode');
    this.state = {
      showAlert: false,
      instantrefund: false,
      viewState: ViewState.SHOW_DETAIL,
    };
    this.previewObject = this.props.previewObject;
    this.bookingId = this.props.bookingDetails.bookingId;
    this.cancelRequestKey = getCancelRequestKeyName(CancelPreviewRequest.BUS, this.bookingId);
    this.selectedRefundMode = 'RefundToCustomer';
    this.instantData = null;
  }

  componentWillMount() {
    DeviceEventEmitter.addListener(InstantRefundReloadEvent.BUS, this.refreshBus);
  }

  render() {
    return (
      <View style={AtomicCss.flex1}>
        {this.state.viewState === ViewState.LOADING && <PageLoader updateAPIData={this.retry} />}
        {this.state.viewState === ViewState.NO_INTERNET && (
          <NoNetworkView retryFunction={this.refreshBus} />
        )}
        {this.state.viewState === ViewState.ERROR && (
          <PageLoadingError retryFunction={this.refreshBus} />
        )}
        {this.state.viewState === ViewState.SHOW_DETAIL && this.renderPage()}
      </View>
    );
  }

  renderPage() {
    return (
      <View style={AtomicCss.flex1}>
        <BusSelectRefundMode
          bookingDetails={this.props.bookingDetails}
          isFullCancellation={this.props.isFullCancellation}
          previewObject={this.props.previewObject}
          handleConfirmBtn={this.onClickHandler}
          instantRefundSelected={this.state.instantrefund}
          cancellationFunction={this.pushCancelView}
          updateRefundMode={this.updateRefundMode}
        />
        {this.state.showAlert && (
          <OverlayAlert
            positiveBtnText={label('bus.cancellation.yesCancel')}
            negativeBtnText={label('bus.cancellation.noGoBack')}
            positiveBtnOnTap={this.cancelBookingBtnTapped}
            negativeBtnOnTap={this.backButtonTapped}
            titleText={`${label('bus.cancellation.areYouSure')}\n`}
            subTitleText={this.subTitleMessage()}
            bgTapAction={this.backButtonTapped}
          />
        )}
      </View>
    );
  }

  subTitleMessage = () => {
    const refundAmount = get(this.props, 'previewObject.refundAmount', 0);
    return refundAmount === 0
      ? label('bus.cancellation.zeroRefundMsg')
      : label('bus.cancellation.finalCancelMsg');
  };

  cancelBookingBtnTapped = () => {
    this.backButtonTapped();
    this.pushCancelView();
    BusBookingTrackingHelper.trackClickEvents(
      'Cancellation Screen Shown',
      'Cancellation_Confirmed',
    );
  };

  pushCancelView = (authorizationToken) => {
    Actions.busBookingCancelled({
      isFullCancellation: this.props.isFullCancellation,
      cancelledPaxLineId: this.props.cancelledLineNos,
      totalPaxCount: this.totalPaxCount(),
      bookingID: this.props.bookingDetails.bookingId,
      mmtAuth: this.props.mmtAuth,
      loggingTrackInfo: this.props.loggingTrackInfo,
      bookingDetails: this.props.bookingDetails,
      refundAmount: this.props.previewObject.refundAmount,
      selectedRefundMode: this.selectedRefundMode,
      instantData: this.instantData,
      msrTicketList:
        this.props.bookingDetails.msrTicketsForBooking &&
        this.props.bookingDetails.msrTicketsForBooking.ticketsList,
      reason: this.props.cancellationReason,
      authorizationToken: authorizationToken,
    });
  };

  updateRefundMode = (updatedMode) => {
    this.selectedRefundMode = updatedMode.refundModeReason;
    this.instantData = {
      requestInstantRefund: true,
      accountNo: updatedMode.accountNo,
      accountIdentifier: updatedMode.accountIdentifier,
    };
  };

  backButtonTapped = () => {
    this.setState({ showAlert: false });
    BusBookingTrackingHelper.trackClickEvents(
      'Cancellation Screen Shown',
      'Cancellation_NotConfirmed',
    );
  };

  totalPaxCount = () => {
    let totalPaxCount = 0;
    const paxList = cancellablePaxList(this.props.bookingDetails);
    if (paxList) {
      totalPaxCount = paxList.length;
    }
    return totalPaxCount;
  };

  onClickHandler = () => {
    BusBookingTrackingHelper.trackClickEvent(
      'chooseRefundMode',
      this.props.bookingDetails,
      'confirm_refund_initiate_clicked',
    );
    this.setState({ showAlert: true });
  };

  refreshBus = () => {
    this.setState({ viewState: ViewState.LOADING });
  };

  retry = () => {
    this.updateAPIData();
  };

  async updateAPIData() {
    try {
      const hasNetwork = await isNetworkAvailable();
      if (!hasNetwork) {
        this.setState({ viewState: ViewState.NO_INTERNET });
        return;
      }
      const request = await retrieveObjFromAsyncStorage(this.cancelRequestKey);
      const commonHeaders = await getCommonHeaders(this.props.mmtAuth, this.props.loggingTrackInfo);
      const response = await fetch(BUS_CANCELLATION_URL, {
        method: POST,
        body: JSON.stringify(request),
        headers: commonHeaders,
      });
      if (response.ok) {
        const body = await response.json();
        this.previewObject = body;
        this.setState({
          viewState: ViewState.SHOW_DETAIL,
        });
        BusBookingTrackingHelper.trackLoadEvent(
          CANCELLATIONSELECTREFUND,
          this.props.bookingDetails,
          FETCHCANCELLATIONPENALTY,
        );
      } else {
        BusBookingTrackingHelper.trackErrorEvent(CANCELLATIONSELECTREFUND);
        this.setState({ viewState: ViewState.ERROR });
      }
    } catch (error) {
      BusBookingTrackingHelper.trackErrorEvent(CANCELLATIONSELECTREFUND);
      this.setState({ viewState: ViewState.ERROR });
    }
  }
}

BusRefundMode.propTypes = {
  bookingDetails: PropTypes.object.isRequired,
  mmtAuth: PropTypes.string.isRequired,
  loggingTrackInfo: PropTypes.string.isRequired,
  previewObject: PropTypes.object.isRequired,
  isFullCancellation: PropTypes.bool.isRequired,
  cancelledLineNos: PropTypes.array,
};

BusRefundMode.defaultProps = {
  cancelledLineNos: null,
};

export default BusRefundMode;
