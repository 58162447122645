const CUSTOM_STYLE = {
  button: {
    primaryButtonStyle: {
      buttonStyle: {},
      textStyle: {},
    },
    capsubleButtonStyle: {},
    outlineButtonStyle: {},
    outlinedCapsuleButtonStyle: {},
    startIconStyle: {},
    startIconHolderStyle: {},
    whiteCapsuleButtonStyle: {
      buttonStyle: {},
      textStyle: {},
    },
  },
  label: {},
  info: {
    infoWrapper: {},
    textWrapper: {},
  },
  bottomSheet: {
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
  checkbox: {
    activeColor: '#008CFF',
    boxRadius: 2,
    size: 20,
    tickSize: 12,
  },
  radioButton: {
    activeColor: '#008CFF',
  },
  stepper: {
    lineColor: '#cbcbcb',
  },
};

const FONTS = {
  regular: { fontFamily: 'Lato-Regular' },
  medium: { fontFamily: 'Lato-Medium' },
  semiBold: { fontFamily: 'Lato-Semibold' },
  bold: { fontFamily: 'Lato-Bold' },
  black: { fontFamily: 'Lato-Black' },
};

const HEADINGS = {
  xLarge: { fontSize: 36, ...FONTS.black, lineHeight: 38 },
  large: { fontSize: 32, ...FONTS.black, lineHeight: 34 },
  medium: { fontSize: 24, ...FONTS.black, lineHeight: 26 },
  small: { fontSize: 22, ...FONTS.black,lineHeight: 24 },
  base: { fontSize: 18, ...FONTS.black, lineHeight: 20 },
};

const LABELS = {
  largeW1: { fontSize: 18, ...FONTS.regular, lineHeight: 20 },
  largeW2: { fontSize: 18, ...FONTS.bold, lineHeight: 20 },
  largeW3: { fontSize: 18, ...FONTS.black, lineHeight: 20 },
  mediumW1: { fontSize: 16, ...FONTS.regular, lineHeight: 18 },
  mediumW2: { fontSize: 16, ...FONTS.bold, lineHeight: 18 },
  mediumW3: { fontSize: 16, ...FONTS.black, lineHeight: 18 },
  baseW1: { fontSize: 14, ...FONTS.regular, lineHeight: 16 },
  baseW2: { fontSize: 14, ...FONTS.bold, lineHeight: 16 },
  baseW3: { fontSize: 14, ...FONTS.black, lineHeight: 16 },
  smallW1: { fontSize: 12, ...FONTS.regular, lineHeight: 14 },
  smallW2: { fontSize: 12, ...FONTS.bold, lineHeight: 14 },
  smallW3: { fontSize: 12, ...FONTS.black, lineHeight: 14 },
};

const COMPONENTS = {
  nudge: {
    linearGradientColor: {
      webCheckIn: ['#FFF4E3', '#FFFFFF'], // also add in GI theme,
      travelInsaurance: ['#F1E3FF', '#FFFFFF'],
      claimGradient: ['#FFE6E6', '#FFFFFF'],
      rescheduledGradient: ['#FFEFE4', '#FFFFFF'],
      assistanceGradient: ['#E6F9FF', '#FFFFFF'],
      webCheckInTag: ['#f3d452', '#f09819'],
      travelInsauranceTag: ['#3023ae', '#c86dd7'],
      claimTag: ['#f5515f', '#9f0404'],
      rescduledTag: ['#c06d0c', '#f2c21a'],
      assistanceTag: ['#edd287', '#b8860b'],
      transferModeTag: ['#F5515F', '#9F0469'],
    },
    color: {
      webCheckInColor: '#007E7D',
      assistanceColor: '#c29421',
      travelInsauranceColor: '#5320c7',
      rescheduledColor: '#c6780f',
      claimColor: '#c8292f',
      webCheckInFoldedTagColor: '#9e730a',
      assistanceFoldedTagColor: '#9b7108',
      travelInsauranceFoldedTagColor: '#591d95',
      rescheduledFoldedTagColor: '#693a00',
      claimFoldedTagColor: '#a30708',
    },
  },
  itinerary: {
    status: {
      success: {bgColor: ['#007E7D', '#007E7D'], shade: 'white'},
      warning: {bgColor: ['#CF8100', '#CF8100'], shade: 'white'},
      error: {bgColor: ['#F5515F', '#9F0404'], shade: 'white'},
      disabled: {bgColor: ['#D8D8D8', '#D8D8D8'], shade: 'low'},
      default: {bgColor: ['#D8D8D8', '#D8D8D8'], shade: 'low'},
    },
  },
};

const ICONS = {
  cross: 'https://imgak.mmtcdn.com/mima/images/crossIcon2.png',
  rightArrow: 'https://imgak.mmtcdn.com/mojo/revampEmailVoucher/BlueArrow.png',
  disabledRightArrow: 'https://imgak.mmtcdn.com/mima/images/mobile/rightArrowGrey.webp',
  backArrow: 'https://imgak.mmtcdn.com/mima/images/mobile/backArrowBlack.webp',
  // todo: refresh icon should be blue in color replace below icon with blue
  refreshIcon: 'https://imgak.mmtcdn.com/mima/images/mobile/refreshIcon.webp',
  blue_tick: 'https://imgak.mmtcdn.com/mima/images/mobile/blueTick.webp',
  download: 'https://imgak.mmtcdn.com/mima/images/mobile/downloadIcon.webp',
  greyArrow: 'https://imgak.mmtcdn.com/mima/images/mobile/blueArrow.webp',
  forwardArrow:
    'https://imgak.mmtcdn.com/mojo/revampEmailVoucher/BlueArrow.png',
  STAR_GREY:
    'https://imgak.mmtcdn.com/mojo/mypartner/endUserVoucher/greyStar.png',
  STAR_BLACK:
    'https://imgak.mmtcdn.com/mojo/mypartner/endUserVoucher/blackStar.png',
  greyAccordionArrow:
    'https://imgak.mmtcdn.com/mima/images/mobile/holidays/chevronLeft.webp',
  BLUE_ARROW_ICON: 'https://imgak.mmtcdn.com/mima/images/mobile/blueArrow.webp',
  rightBlueArrow:
  'https://imgak.mmtcdn.com/mima/images/mobile/rightArrow_v2.webp',
  rightArrowOneWay: 'https://imgak.mmtcdn.com/goibibo/images/arrow.png',
  roundTrip: 'https://imgak.mmtcdn.com/mima/images/mobile/roundtrip_arrow.webp',
  deleteIcon: 'https://imgak.mmtcdn.com/mima/images/mobile/deleteIcon.webp',
  document: 'https://imgak.mmtcdn.com/mima/images/mobile/blueList2Icon.webp',
  phone: 'https://imgak.mmtcdn.com/mima/images/mobile/MMT/callOutline.webp',
  successTick: 'https://imgak.mmtcdn.com/mima/images/mobile/MMT/tickGreen.webp',
  errorIcon: 'https://imgak.mmtcdn.com/mima/images/mobile/errorImage.webp',
};

const COLOR = {
  textColor: {
    primary: '#008CFF',
    primaryIngo: '#3543BF',
    primaryHost: 'F2A526',
    primaryDiscount: '#378BA8',
    white: '#FFFFFF',
    error: '#EC2127',
    warning: '#CF8100',
    recommendationText: '#CB9B12',
    success: '#007E7D',
    disabled: '#0000004D',
    highEmphasis: '#000000',
    mediumEmphasis: '#4A4A4A',
    lowEmphasis: '#757575',
    high: '#000000',
    medium: '#4A4A4A',
    low: '#757575',
    link: '#008CFF',
    screen: '#F2F2F2',
    rating: '#E1E1E1',
    transparent: '#ffffff00',
  },

  backgroundColor: {
    white: '#FFFFFF',
    input: '#F6F6F6',
    error: '#FCDADB',
    warning: '#FFEDD1',
    success: '#E6FFF9',
    tagSuccess: '#BDCACB',
    primaryIngo: '#EAF5FF',
    primary: '#EAF5FF',
    secondary: '#CCE8FF',
    hostPrimary: '#FCEDD4',
    hostSecondary: '#F9D293',
    hostTertiary: '#D78411',
    infoCard: '#FFFAF1',
    disabled: '#e9e9e9',
    transparent: '#ffffff00',
    screen: '#F2F2F2',
    highEmphasis: '#e9e9e9',
    mediumEmphasis: '#4A4A4A',
  },
  borderColor: {
    stroke: '#D8D8D8',
    card: '#D8D8D8',
    primaryBtn: '#008CFF',
    error: '#EC2127',
    success: '#007E7D',
    warning: '#CF8100',
    transparent: '#ffffff00',
    mediumEmphasis: '#cbcbcb',
    highEmphasis: '#4A4A4A',
    disabled: '#757575',
    links: '#008CFF',
    newNotification: '#FF3E5E',
    imptInfo: '#F3D452',
  },
  linearGradientColor: {
    primaryGradient: ['#53B2FE', '#065AF3'],
    hostPrimaryGradient: ['#F2A526', '#D78411'],
    msrPrimaryGradient: ['#FFFFFF', '#D3E7FF'],
    successOfferTag: ['#F1F9FC', '#F6FCFB', '#E6F1F2'],
    newNotification: ['#FF7F3F', '#FF3E5E'],
  },
};

export {
  FONTS as fonts,
  COLOR as color,
  HEADINGS as headings,
  LABELS as labels,
  CUSTOM_STYLE as customStyle,
  ICONS as icons,
  COMPONENTS as components,
};
