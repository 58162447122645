import React from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import styles from './CabVoucherInfoCardCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { COPY_ICON, COUPONS_ICON } from '../../CabImageConstants';
import {
  copyToClipboard,
  isEmptyArray,
  isNullOrEmptyObject,
} from '../../../Common/commonUtil';
import HTMLViewWrapper from '../CabVendorInfoCard/HTMLViewWrapper';
import { isNonEmpty } from '@mmt/legacy-commons/Common/utils/StringUtils';
import { showLongToast } from '@mmt/legacy-commons/Common/Components/Toast';
import { getStaticData } from '../../../staticData/staticData';
import { vendorTnCHandler } from '../../utils/CabBookingDetailUtil';
import CardWrapper from '../../modules/bookingDetails/components/CardWrapper';

const CabVoucherInfoCard = ({ data, customStyles, pageName }) => {
  const { heading, description, icon, voucherInfo,tncCta } = data || {};
  const {url:bluSmartImageUrl}= icon || {};
  const renderVoucherInfo = (data,tncCta) => {
    const { voucherCode } = voucherInfo || {};
    const { heading, description } = data;
    const onCopyVoucherCode = async () => {
      const { copiedToClipboardText } = getStaticData(false);
      const copyStatus = await copyToClipboard(voucherCode);
      if (copyStatus) {
        showLongToast(copiedToClipboardText);
      }
    };

    return (
      <View
        style={[
          AtomicCss.flexRow,
          AtomicCss.alignItemsStart,
          AtomicCss.paddingLeft16,
          AtomicCss.marginBottom16,
        ]}
      >
        <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, styles.marginRight24]}>
          <Image source={COUPONS_ICON} style={styles.couponsIcon} />
          <Text style={[AtomicCss.font12, AtomicCss.blackFont, AtomicCss.defaultText]}>
            {heading}
          </Text>
        </View>
        <View style={AtomicCss.flex1}>
          <TouchableOpacity
            activeOpacity={0.7}
            style={[
              AtomicCss.flexRow,
              AtomicCss.flex1,
              AtomicCss.alignCenter,
              AtomicCss.marginBottom5,
            ]}
            onPress={onCopyVoucherCode}
          >
            <Text style={[AtomicCss.font12, AtomicCss.boldFont, styles.couponText]}>{voucherCode}</Text>
            <Image source={COPY_ICON} style={styles.copyIcon} />
          </TouchableOpacity>
          <View>
            <Text style={[AtomicCss.font12, AtomicCss.regularFont, AtomicCss.lightText]}>
              {description}
            </Text>
            {!isNullOrEmptyObject(tncCta) && (
              <TouchableOpacity activeOpacity={0.7} onPress={() => vendorTnCHandler(tncCta, pageName)}>
                <Text style={[AtomicCss.font12, AtomicCss.boldFont, AtomicCss.azure]}>
                  {tncCta.text}
                </Text>
              </TouchableOpacity>
            )}
          </View>
        </View>
      </View>
    );
  };

  return (
    <View style={customStyles}>
      <View style={styles.header}>
        {isNonEmpty(bluSmartImageUrl) && <Image source={{ uri: bluSmartImageUrl }} style={styles.blueSmartIcon} />}
        <View style={AtomicCss.flex1}><HTMLViewWrapper htmlContent={heading} /></View>
      </View>

      <View style={AtomicCss.marginBottom12}>
        <HTMLViewWrapper htmlContent={description} />
      </View>

      {!isNullOrEmptyObject(voucherInfo) && (
        <>
          <View style={[AtomicCss.flexRow, AtomicCss.marginBottom12]}>
            <HTMLViewWrapper htmlContent={voucherInfo.heading} />
          </View>
          {!isEmptyArray(voucherInfo.additionalInfo) &&
            renderVoucherInfo(voucherInfo.additionalInfo[0],tncCta)}
        </>
      )}
    </View>
  );
};

export default CardWrapper(CabVoucherInfoCard);
