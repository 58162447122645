import {fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import {Platform} from 'react-native';

const styles = {
	pageWrapper:{
		flex: 1,
		backgroundColor:'#f6f6f6',
	},
	topHeading: {
		paddingTop: 20,
		paddingBottom: 20,
		flexDirection: 'row',
		paddingLeft: 20,
		paddingRight: 20,
		alignItems: 'center'
	},
	crossImg: {
		width: 24,
		height: 24,
	},
	overlayTopHeadding:{fontSize: 20, color:'#000', paddingLeft: 36, fontFamily: fonts.bold},
	containerOverlay: {paddingHorizontal: 34},
	QRImpText: {fontSize: 16, color: '#000', fontFamily: fonts.regular, marginBottom: 40},
	centerContainer: {justifyContent: 'center', flexDirection: 'row', alignItems: 'center'},
	appendBottom24: {marginBottom: 24},
    appendBottom4: {marginBottom: 4},
	overlayCouponCode: {borderWidth: 2,borderColor: '#000',borderRadius: 5,paddingVertical:5, paddingHorizontal: 15 },
	overlayCouponText:{fontSize: 32, color: '#000', fontFamily: fonts.black, letterSpacing: 2.5},
	appendBottom44:{marginBottom: 44},
	bookingId:{fontSize: 14,color: '#9b9b9b', textAlign: 'center', marginBottom: 4, fontFamily: fonts.regular},
	bookingIdNo: {fontSize: 14,color: '#000', textAlign: 'center', marginBottom: 4, fontFamily: fonts.black},
    mapIcon: {width: 352, height: 352},
	IOSSpacing: {
		...Platform.select({
				ios: {
                    marginTop: 10
				}
			}),
	},
	}

export default styles;
