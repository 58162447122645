const AcmeBookingDetailsConstant = {
  TOP_STATUS_CARD_UPCOMING: 'TopStatusCard_Upcoming',
  TOP_STATUS_CARD_COMPLETED: 'TopStatusCard_Completed',
  TOP_STATUS_CARD_CANCELLED: 'TopStatusCard_Cancelled',
  TOP_STATUS_CARD_FAILED: 'TopStatusCard_Failed',
  MAIN_CARD: 'TripDetailsCard',
  LOCATION_CARD: 'LocationCard',
  INCLUSION_EXCLUSION_CARD: 'InclusionExculsionCard',
  PASSENGER_DETAILS_CARD: 'PassengerDetailsCard',
  CANCELLATION_POLICY_CARD: 'CancellationCard',
  PAYMENT_CARD: 'PaymentDetailsCard',
  PICKUP_CARD: 'PickupCard',
  REFUND_BREAKUP_CARD: 'RefundBreakUpCard',
  REFUND_PROGRESS_CARD: 'RefundProgressCard',
  ACTIVITY_VOUCHER_CARD: 'VoucherCard',
  RECTANGLE_FULL_BUTTON: 'RecFullBtn',
  ROUND_BUTTON: 'RbBtnWithIcon',
  RECTANGLE_PRIMARY_FULL_WIDTH: 'CapsuleFullBtnFill',
  RECTANGLE_PRIMARY_HALF_WIDTH: 'CapsuleHalfBtnFill',
  RECTANGLE_SECONDARY_FULL_WIDTH: 'CapsuleFullBtn',
  RECTANGLE_SECONDARY_HALF_WIDTH: 'CapsuleHalfBtn',
  WHITE_BUTTON_FULL: 'CapsuleWhiteBtn',
  WHITE_BUTTON_HALF: 'CapsuleWhiteBtnHalf',

  ACTION_CALL_VENDOR: 'CallVendor',
  ACTION_TERMS_CONDITIONS: 'TermsAndConditions',
  ACTION_THINGS_TO_CARRY: 'ImportantThingsToCarry',
  ACTION_FAQ: 'FAQ',
  ACTION_FULL_CANCEL: 'FullCancel',
  ACTION_CALL_HELPLINE: 'CallHelpline',
  ACTION_CANCELLATION_POLICY: 'CancellationPolicy',
  ACTION_SCHEDULE: 'Schedule',
  ACTION_RESEND_VOUCHER: 'ResendConfirmation',
  ACTION_HOW_TO_REDEEM: 'HowToRedeem',
  HELPLINE_NUMBER: '(0124) 462 8747',
  CHOOSE_REFUND_MODE: 'CHOOSE REFUND MODE',
  CONFIRM_CANCELLATION: 'CONFIRM CANCELLATION',
  ALERT_INFO_CARD: 'alertinfocard',
  INDIAN_CURRENCIES: ['₹', '&#8377;', 'INR', 'AED'],
  UNIT_TYPE: {
    VOUCHER: "1",
    ADULT: "2",
    SENIOR_CITIZEN: "3",
    CHILD: "4",
    INFANT: "5",
  },
  AUTO_REDEEM_TYPE: {
    YES: 1,
    NO: 0
  }
};

export default AcmeBookingDetailsConstant;
