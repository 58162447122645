import { Dimensions, StyleSheet, I18nManager } from 'react-native';

import getPlatformElevation from '@mmt/legacy-commons/Common/Components/Card/getPlatformElevation';

export default (theme, fonts) =>
  StyleSheet.create({
    scrollableWrapper: {
      maxHeight: Dimensions.get('window').height - 200,
      paddingBottom: 0,
    },
    lastNameRepeatedWrapper: {
      marginBottom: 120,
      maxHeight: Dimensions.get('window').height - 300,
    },
    overlayContentWrapper: {
      backgroundColor: theme.color.white,
      paddingTop: 12,
      paddingBottom: 20,
      paddingHorizontal: 10,
      position: 'relative',
      zIndex: 1,
      width: '100%',
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
    },

    selectReasonWrapper: {
      backgroundColor: theme.color.white,
      paddingTop: 12,
      paddingBottom: 20,
      paddingHorizontal: 0,
      position: 'relative',
      zIndex: 1,
      width: '100%',
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      maxHeight: Dimensions.get('window').height - 100,
    },
    submitReqWrapper: {
      minHeight: 490,
    },
    callListWrapper: {
      marginBottom: 60,
    },
    lastItemCallWrapper: {
      borderBottomWidth: 1,
      borderBottomColor: theme.color.greyBookedSeat,
      marginBottom: 20,
      paddingBottom: 20,
    },
    callWrapper: {
      paddingBottom: 20,
    },
    buttonBottomWrapper: {
      alignItems: 'center',
      position: 'absolute',
      bottom: 0,
      width: '100%',
    },
    buttonWrapper: {
      paddingBottom: 10,
      width: '100%',
      alignItems: 'center',
    },
    buttonStyle: {
      minWidth: '96%',
    },
    makeBtnWrapper: {
      paddingTop: 20,
      backgroundColor: theme.color.white,
      width: '100%',
      alignItems: 'center',
    },
    flightIcon: {
      width: 32,
      height: 32,
      marginRight: 16,
    },
    phoneIcon: {
      width: 24,
      height: 24,
    },
    leftStroke: {
      width: 3,
      height: 16,
      backgroundColor: theme.color.primary,
      position: 'absolute',
      left: -16,
    },
    greenTickIcon: {
      width: 13,
      height: 13,
      marginRight: 5,
    },
    detailSec: {
      backgroundColor: theme.color.white,
      elevation: 3,
      ...getPlatformElevation(2),
      borderRadius: 4,
      borderWidth: 1,
      borderColor: theme.color.greyBookedSeat,
      paddingVertical: 20,
      paddingHorizontal: 16,
    },
    overlayTopline: {
      width: 40,
      height: 4,
      borderRadius: 4,
      backgroundColor: theme.color.faintgrey,
      alignSelf: 'center',
    },
    title: {
      color: theme.color.black,
      fontSize: fonts.fontSize.font22,
      ...fonts.blackFontFamily,
      marginBottom: 30,
      lineHeight: 32,
    },
    strokeStyle: {
      width: 30,
      height: 4,
      backgroundColor: theme.color.primary,
      marginBottom: 26,
    },
    arrowStyle: {
      width: 8,
      height: 13,
      marginLeft: 10,
      marginTop: 5,
    },
    iconStyle: {
      width: 24,
      height: 24,
      marginRight: 12,
      marginTop: 3,
    },
    listWrapper: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 12,
      backgroundColor: theme.color.paleBlue,
      borderColor: theme.color.faintBlue,
      borderWidth: 1,
      borderRadius: 4,
      padding: 16,
    },
    lastItemStyle: {
      marginBottom: 0,
    },
    titleTxt: {
      ...fonts.blackFontFamily,
      fontSize: fonts.fontSize.font16,
      color: theme.color.black,
      marginBottom: 4,
      lineHeight: 24,
    },
    desc: {
      ...fonts.regularFontFamily,
      fontSize: fonts.fontSize.font14,
      color: theme.color.textGrey,
      lineHeight: 20,
    },
    scheduleLinkBtn: {
      borderTopColor: theme.color.grayBg,
      paddingTop: 20,
      borderTopWidth: 1,
    },
    backArrowWrapper: {
      width: 24,
      height: 24,
      marginLeft: -4,
      marginRight: 4,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    backArrowStyle: {
      width: 13,
      height: 12,
      transform: [{scaleX: I18nManager.isRTL ? -1 : 1}]
    },
  });
