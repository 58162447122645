/* eslint-disable max-len */
import React from 'react';
import Actions from '../../navigation/postSalesNavigation';
import PropTypes from 'prop-types';
import {Image, ScrollView, Text, View} from 'react-native';
import styles from '../styles/CabMamiCss';
import CommonHeader from '@mmt/legacy-commons/Common/Components/Header/CommonHeader';
import CabBookingTrackingHelper from '../CabBookingTrackingHelper';
import {cancelIcon2, commonheaderIcon} from "../CabImageConstants";

class CarCancellationPolicyRules extends React.Component {
  constructor(props) {
    super(props);
    this.carCancelPolicy = this.props.response.productinclusion.cancellationPolicy;
  }

  render() {
    return (
      <View style={[styles.whiteContainer, styles.transperantBgrnd]}>
        <CommonHeader
          headerText="Cancellation Policy"
          imgSrc={commonheaderIcon}
          backPressHandler={this.onBackPress}
        />
        <ScrollView>
          <View style={styles.PolicyHeading}>
            <Image style={styles.CrossIcon} source={cancelIcon2} />
            <Text style={[styles.BlackText, styles.blackFont, styles.PolicyHeadingText]}>CANCELLATION POLICY</Text>
          </View>
          <View style={styles.PolicyDetailsContainer}>
            {this.showCarPolicy(this.carCancelPolicy)}
          </View>
        </ScrollView>
      </View>
    );
  }
  componentDidMount() {
    CabBookingTrackingHelper.trackLoadEvent('Cab Cancellation: CancellationPolicy', this.props.response);
  }

  showCarPolicy(carCancelPolicy) {
    const views = [];
    carCancelPolicy.forEach((carPolicyObject) => {
      this.createCarPolicyView(views, carPolicyObject);
    });
    return views;
  }

  createCarPolicyView(views, carPolicyObject) {
    views.push(<View style={[styles.mar15Bt, styles.flexRow]} key={carPolicyObject.cancellationPolicy}>
      <View style={styles.ItemDisc} />
      <Text>{carPolicyObject.cancellationPolicy}</Text>
               </View>);
  }
    onBackPress = () => {
      Actions.pop();
    }
}
CarCancellationPolicyRules.propTypes = {
  response: PropTypes.object.isRequired
};
export default CarCancellationPolicyRules;
