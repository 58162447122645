import { isEmpty } from 'lodash';
import { trackOmniture } from '@mmt/legacy-commons/Common/utils/OmnitureTrackerUtils';
import { checkMyPartner, isCorporateBookingId } from '../../../../utils/PostSaleUtil';
import FlightEventsLogger from '../../details/helper/FlightEventsLogger';
import {
  _getFltTrackData,
  _trackFltBookingDate,
  _trackSegmentData,
} from '../../../FlightOmnitureTrackerUtils';
import {
  getAdultCount,
  getJourneyType,
  getBookingState,
  getBookingIntentDetail,
  getAirlineClass,
  getOldAirlineName,
  getReviewRoute,
  getNetAmtToPay,
  isIntlFlightBooking,
  getNewAirlineName,
} from '../helper';
import { areAllSegmentsDisabled } from '../utils';
import { validationCardTypes } from '../constants';

class DateChangeTrackingHelper {
  trackLoadEvent = (pageName, response, isCorporate) => {
    try {
      FlightEventsLogger.loadEvent(pageName, response);
      let isIntl;
      if (pageName === 'datechangethankyousuccess' || pageName === 'datechangethankyoufail') {
        isIntl = this.getPageNameForDateChangeThankYouScreen(response.bookingID);
      } else {
        isIntl = isIntlFlightBooking(response.lobCode);
      }
      const trackingPageName = this.getPageName(
        isIntl,
        isCorporate ? `${pageName}|corporate` : checkMyPartner() ? `${pageName}|myPartner` : pageName,
      );

      let params = {};
      if (
        pageName === 'datechangereview' ||
        pageName === 'datechange _paymentPopup' ||
        pageName === 'datechangereview_request' ||
        pageName === 'datechange _requestPopup' ||
        pageName === 'datechang_neartodept'
      ) {
        params = this.getReviewTrackingOmniture(trackingPageName, response);
      } else if (pageName === 'datechangethankyousuccess' || pageName === 'datechangethankyoufail') {
        params = this.getThankyouTrackingOmniture(trackingPageName, response.bookingID);
      } else {
        params = this.getTrackingOmniture(trackingPageName, response);
      }
      trackOmniture(trackingPageName, params);
    } catch(error){
        console.log("Error in track Load Event", error);
    }
  };

  trackLoadNoTrueChangeThankYouPage = (pageName, bookingId) => {
    try {
      FlightEventsLogger.loadEvent(pageName, { bookingId });
      const isIntl = this.getPageNameForDateChangeThankYouScreen(bookingId);
      const trackingPageName = this.getPageName(isIntl, pageName);
      const params = this.getThankyouTrackingOmniture(trackingPageName, bookingId);
      trackOmniture(trackingPageName, params);
    } catch(error){
      console.log("Error in track Load Event", error);
    }
   
  };

  getPageName = (isIntl, pageName) =>
    isIntl
      ? `Mob:Customer support:Intl flight :${pageName}`
      : `Mob:customer support:Dom flight :${pageName}`;

  getIsIntl = (pageName, response) => {
    let isIntl;
    if (pageName === 'datechangethankyousuccess' || pageName === 'datechangethankyoufail') {
      isIntl = this.getPageNameForDateChangeThankYouScreen(response?.bookingID);
    } else {
      isIntl = isIntlFlightBooking(response?.lobCode);
    }
    return isIntl;
  }

  trackErrorEvent = (pageName, message, supplierName, isCorporate) => {
    try {
    FlightEventsLogger.errorEvent(pageName, { error: message });
    const trackingPageName = `Mob:Customer support:Flight:${pageName}${
      isCorporate ? '|corporate' : checkMyPartner() ? '|myPartner' : ''
    }:error`;
    const params = {};
    params.pageName = trackingPageName;
    params.m_v15 = trackingPageName;
    params.m_c22 = message;
    if (supplierName) {
      params.m_c40 = supplierName;
    }
    trackOmniture(trackingPageName, params);
  }catch(error){
    console.log("Error in track Error Event", error);
  }
  };

  trackErrorWithOutMessageAppend = (pageName, errorReason) => {
    try {
      FlightEventsLogger.errorEvent(pageName, { error: errorReason });
      const trackingPageName = pageName;
      const params = {};
      params.pageName = trackingPageName;
      params.m_v15 = trackingPageName;
      params.m_c22 = errorReason;
      trackOmniture(trackingPageName, params);
    } catch(error){
      console.log("Error in track Error Event Without Message Append", error);
    }
  };

  trackValidationErrorWithReason =(pageName, response, errorReason)=> {
    try {
      const isIntl = isIntlFlightBooking(response.lobCode);
      const trackingPageName = this.getPageName(isIntl, pageName);
      const params = {};
      params.pageName = trackingPageName;
      params.m_v15 = trackingPageName;
      params.m_c22 = errorReason;
      trackOmniture(trackingPageName, params);
    } catch(error){
      console.log("Error in track Validation Error Event", error);
    }
  };

  trackClickableEvent = (pageName, clickEvent, response, isCorporate, type = 'click') => {
    try {
      FlightEventsLogger.clickEvent(pageName, clickEvent);
      const params = {};
      const isIntl = this.getIsIntl(pageName, response);
      let trackingPageName = this.getPageName(isIntl, pageName);
      if (isCorporate || isCorporateBookingId(response?.bookingId)) {
        trackingPageName = `${pageName}|corporate`;
      } else if (checkMyPartner()) {
        trackingPageName = `${pageName}|myPartner`;
      }
      params.pageName = trackingPageName;
      params.m_v15 = trackingPageName;
      let clickEventName = null;
      if (type == 'click'){
        clickEventName = clickEvent + '_clicked';
      } else if(type == 'shown') {
        clickEventName = clickEvent + '_shown';
      }
      params.m_c54 = clickEventName;
      trackOmniture(trackingPageName, params);
    }catch(error){
      console.log("Error in track Click Event", error);
    }
  };

  trackUndoWCClickEvent = (pageName, clickEvent, { isCorporate, bookingId, bookingID } = {}) => {
    try {
      FlightEventsLogger.clickEvent(pageName, clickEvent);
      const isIntl = this.getPageNameForDateChangeThankYouScreen(bookingId || bookingID);
      let trackingPageName = this.getPageName(isIntl, pageName);
      const params = {};
      if (isCorporate) {
        trackingPageName += '|corporate';
      } else if (checkMyPartner()) {
        trackingPageName += 'myPartner';
      }
      params.pageName = trackingPageName;
      params.m_v15 = trackingPageName;
      let clickEventName = 'MI_';
      if (isIntl) {
        clickEventName += 'intl_';
      } else {
        clickEventName += 'dom_';
      }
      clickEventName += clickEvent;
      params.m_c54 = clickEventName;
      trackOmniture(trackingPageName, params);
    } catch (err) {
      console.log(err);
    }
  };

  getTrackingOmniture(pageName, response) {
    try {
      const params = {};
    const segInfo =
      response && response.flightDetails
        ? _trackSegmentData(response.flightDetails.segmentGroupDetailList)
        : {};
    params.m_v9 = getBookingState(response);
    params.m_c19 = getJourneyType(response);
    params.m_c3 = segInfo.bookingIntent || getBookingIntentDetail(response);
    params.pageName = pageName;
    params.m_v15 = pageName;
    params.m_v16 = response.bookingID;
    params.m_v39 = 'Y';
    params.m_c12 = getAirlineClass(response);
    params.m_c24 = response.lobName;
    params.m_c4 = segInfo.adultCount || getAdultCount(response);
    params.m_c40 = segInfo.airlineCode || getOldAirlineName(response);
    params.m_c22 = response && response.ruleTrace ? response.ruleTrace : '';
    params.m_v4 = segInfo.depDate;
    const flightData = _getFltTrackData();
    if (flightData) {
      params.m_v5 = flightData.bookingDateTime
        ? _trackFltBookingDate(flightData.bookingDateTime.split('T')[0])
        : '';
    }
    return params;
    }
    catch(error){
      console.log("Error in getTrackingOmniture", error);
    }
  }

  getReviewTrackingOmniture(pageName, response) {
    try {
      const params = {};
      params.pageName = pageName;
      params.m_v15 = pageName;
      params.m_v16 = response.bookingId;
      params.m_c3 = getReviewRoute(response);
      params.m_c40 = `${response.origAirline} | ${getNewAirlineName(response)}`;
      params.m_c46 = getNetAmtToPay(response);
      params.m_v39 = 'Y';
      params.m_c19 = response.journeyType;
      params.m_c22 = response && response.ruleTrace ? response.ruleTrace : '';
      params.m_c1 = response && !!response.fareDetail && !!response.fareDetail.header && 'fdc_active';
      return params;
    }catch(error){
      console.log("Error in getReviewTrackingOmniture", error);
    }
    
  }

  getDateChangeDisabledOmniture=(pageName, response, cancellationObj) =>{
    try {
      const params = {};
      const pnr =
        response.passengerList && response.passengerList.length > 0
          ? response.passengerList[0].pnrNo
          : '';
      const airCode =
        response.paxFaresList && response.paxFaresList.length > 0
          ? response.paxFaresList[0].airLineCode
          : '';
      const bookingID = response.bookingID.slice(0, 3);
      params.m_c54 = `fodcerror_${airCode}_${bookingID}_${pnr}`;
      params.m_c22 =
        !isEmpty(cancellationObj) && !isEmpty(cancellationObj.infoMessage)
          ? `fodcerror_${cancellationObj.infoMessage.replaceAll(' ', '_').replaceAll('.', '')}`
          : '';
      trackOmniture(this.getPageName(response.isInternational, pageName), params);
    } catch(error){
      console.log("Error in getDateChangeDisabledOmniture", error);
    }
    
  }

  getThankyouTrackingOmniture(pageName, bookingId) {
    try {
      const params = {};
      params.pageName = pageName;
      params.m_v15 = pageName;
      params.m_v16 = bookingId;
      return params;
    } catch(error){
      console.log("Error in getThankyouTrackingOmniture", error);
    }
    
  }
  trackValidationError=(response, odcValidationSegment)=> {
    const reason = odcValidationSegment ? odcValidationSegment.status : '';
    if (areAllSegmentsDisabled(response.odcValidation)) {
        this.trackValidationErrorWithReason(
        'datechangepickdate : Validation error _Full',
        response,
        reason,
      );
    } else {
        this.trackValidationErrorWithReason(
        'datechangepickdate : Validation error _Part',
        response,
        reason,
      );
    }
  };
  
  trackValidationClickEvent=(response, component)=>{
    let reason = '';
    const pageName = 'DateChange Validation ';
    switch (component) {
      case validationCardTypes.SPECIAL_CLAIM:
        reason = 'PopUpProceed';
        break;
      case validationCardTypes.SUBMIT:
        reason = 'Submit Request';
        break;
      case validationCardTypes.CALL:
        reason = 'Callus';
        break;
      default:
        break;
    }
    this.trackClickableEvent(pageName, reason, response);
  };

  getPageNameForDateChangeThankYouScreen = (bookingId) => bookingId && bookingId.startsWith('NN');
}

export default new DateChangeTrackingHelper();
