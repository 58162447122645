//postsales
import {getImagePath} from '../utils/PostSaleUtil';

export const topStatusConfirmedIcon = getImagePath('topstatusIcon1.webp');
export const topStatusCancelledIcon = getImagePath('topstatusIcon8.webp');
export const topStatusGetReadyIcon = getImagePath('topstatusIcon3.webp');
export const topStatusFailedIcon = getImagePath('topstatusIcon7.webp');
export const topStatusOnTripIcon = getImagePath('topstatusIcon4.webp');
export const maleImage = getImagePath('male.webp');
export const femaleImage = getImagePath('female.webp');
export const viewLocation = getImagePath('ic_viewlocation.webp');
export const callHotel = getImagePath('ic_callhotel.webp');
export const chatWithHotel = getImagePath('ic_chatwithhotel.webp');
export const resendConfirmation = getImagePath('ic_resendconfirmation.webp');
export const validProof = getImagePath('ic_valid_id_proof.webp');
export const checkinHotel = getImagePath('ic_checkinathotel.webp');
export const callMMTSupport = getImagePath('ic_callmmtsupport.webp');
export const gotoHelp = getImagePath('ic_gotohelpsection.webp');
export const chatWithUs = getImagePath('ic_chatwithus.webp');
export const makeRequest = getImagePath('ic_makerequest.webp');
export const errorIcon = getImagePath('errorIcon.webp');
export const busIcon = getImagePath('bus_live.webp');
export const notificationIcon = getImagePath('busNotification.webp');
export const trackingPointer = getImagePath('trackingPointer.webp');
export const refreshIcon = getImagePath('refreshIcon.webp');
export const locationAccuracy = getImagePath('locationAccuracy.webp');
export const errorIcon2 = getImagePath('ErrorIcon1.webp');
export const seatIcon = getImagePath('seater.webp');
export const cancelIcon = getImagePath('topstatusIcon8.webp');
export const tick2 = getImagePath('checkbox_tick.webp');
export const tick3 = getImagePath('ic_fp_tick_white_copy_2.webp');
export const deleteIcon = getImagePath('ic_delete.webp');
export const oneway = getImagePath('oneway.webp');
export const mailIcon = getImagePath('mail.webp');
export const callMmtIcon = getImagePath('call_mmt.webp');
export const refundsIcon = getImagePath('refunds.webp');
export const submittedIcon = getImagePath('submittedIcon.webp');
export const icCotraveller = getImagePath('ic_cotraveller.webp');
export const cancellationIcon = getImagePath('cancellation.webp');
export const callIcon = getImagePath('call_icon.webp');
export const gpsIcon = getImagePath('ic-gps-outline.webp');
export const guestIcon = getImagePath('guest_icon.webp');
export const oneWayJourney = getImagePath('oneway.webp');
export const primo = getImagePath('primo.webp');
export const offerIcon = getImagePath('offerIcon.png');
export const arrowDown = getImagePath('blueDropIcondown.png');
export const cancellationIcon2 = getImagePath('cancel_Icon.png');
export const crossIcon = getImagePath('crossIcon.png');
export const bordingPointBus = getImagePath('bus_simple.png');
export const iconDark = getImagePath('infoDark.png');
export const fcBus = getImagePath('cancelForFreeIcon.webp');

//common
export const topStatusAfterTripIcon = require('@mmt/legacy-assets/src/topstatusIcon9.webp');
export const whiteBackIcon = require('@mmt/legacy-assets/src/white_backarrow.webp');
export const tick = require('@mmt/legacy-assets/src/ic_tick.webp');
export const doubleTick = require('@mmt/legacy-assets/src/double_tick.webp');
export const locationIcon = require('@mmt/legacy-assets/src/postSales/busLiveTracking/locationIcon.webp');
export const closeIcon = require('@mmt/legacy-assets/src/postSales/busLiveTracking/close.webp');
export const backIcon = require('@mmt/legacy-assets/src/back.webp');
export const chkboxicon = require('@mmt/legacy-assets/src/chkboxinactive.webp');
export const chkboxiconActive = require('@mmt/legacy-assets/src/chkboxactive.webp');
export const headerIcon = require('@mmt/legacy-assets/src/trip_header_back_icon.webp');
export const flightIcon = require('@mmt/legacy-assets/src/BusImage.webp');
export const refundIcon = require('@mmt/legacy-assets/src/refundIcon.webp');
export const rightArrow = require('@mmt/legacy-assets/src/ic_arrow.webp');
export const rightArrow2 = getImagePath('oneWayArrow.webp');
export const whiteCrossIcon = require('@mmt/legacy-assets/src/whiteCross.webp');
export const paymentIcon = require('@mmt/legacy-assets/src/payment_icon.webp');
export const busOfferIcon = require('@mmt/legacy-assets/src/offer_green_small.webp');
export const cancellingIcon = require('@mmt/legacy-assets/src/cancelling-icon.webp');
export const dotHollowGrayIcon = require('@mmt/legacy-assets/src/dot_hollow_gray.webp');
export const ZC_IMAGE = getImagePath('freeCancellation.webp');
export const BACK_ARROW = require('@mmt/legacy-assets/src/back-white.webp');

export const EDIT_BLUE_ICON = getImagePath('editBlueIcon.webp');
export const CHANGE_TRAVEL_DATE_ICON =  getImagePath('editIconGrayBg.png')
export const INFO_ICON =  getImagePath('infoIcon.png')
export const PATH_ICON =  getImagePath('pathIcon.jpg')
export const BUS_YELLOW_ICON =  getImagePath('BusYellowIcon.png')
export const RIGHT_ARROW =  getImagePath('rightArrow.png')
export const BLUE_ARROW =  getImagePath('blueArrow.webp')
export const TRAVELLER_ICON =  getImagePath('travellerIcon.png')
export const CALENDER_ICON =  getImagePath('calendar_blue.webp')
export const busImage = getImagePath('v5busIcon.webp');
export const rightBlueArrow = getImagePath('rightArrow.webp');







