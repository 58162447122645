import React from 'react';
import  isEmpty from 'lodash/isEmpty';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { CROSS_ICON } from '../../../../../Rails/Utils/RailImageConstant';
import getStyles, { getHtmlStyle } from './styles';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import { CancellationPolicyOverlayProps } from '../../../../types';
import { getStaticData } from 'apps/post-sales/src/staticData/staticData';
import HTMLView from 'react-native-htmlview';
import { getPolicyData } from 'apps/post-sales/src/cab/utils/CabBookingDetailUtil';
import CabBookingDetailsConstant from 'apps/post-sales/src/cab/CabBookingDetailsConstant';
import { infoIcon } from 'apps/post-sales/src/cab/CabImageConstants';

const CancellationPolicyOverlay: React.FC<CancellationPolicyOverlayProps> = (props) => {
  const { handleBottomOverlay, response, heading } = props;
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme } = useTheme();
  const styles = getStyles(psTheme, fonts);
  const { okayText } = getStaticData(true);
  const policyFooterText = response?.clientDetails?.CarCancellationPolicyFooter;
  const cancellationPolicyTimeText = response?.internationalCabData?.cancellationPolicyTimeText;
  const policyData = getPolicyData(response);
  const { timeText, chargesText } = CabBookingDetailsConstant;
  return (
    <React.Fragment>
      <View style={[styles.headingSection, AtomicCss.flexRow]}>
        {!!heading && <View style={[AtomicCss.flex1, AtomicCss.flexRow, AtomicCss.alignCenter]}>
          <Text style={styles.headingText}>{heading}</Text>
        </View>}
        <TouchableOpacity style={styles.crossIconWrapper} onPress={handleBottomOverlay}>
          <Image style={styles.crossIconStyle} source={CROSS_ICON} />
        </TouchableOpacity>
      </View>
      <View style={styles.bodySection}>
        <View style={styles.freeCanWrapper}>
          <View style={styles.headingInfoWrapper}>
            <View style={[styles.headingTextWrapper]}>
              <Text style={[styles.tHeadingText]}>{timeText}</Text>
            </View>
            <View style={[styles.headingTextWrapper, styles.borRightZero]}>
              <Text style={[styles.tHeadingText]}>{chargesText}</Text>
            </View>
          </View>
          {!isEmpty(policyData) && (
            <View style={styles.innerInfoDetails}>
              {policyData.map((item, index) => {
                const { timeData, chargesData } = item;
                const { heading, subHeading } = timeData || {};
                const { penaltyText } = chargesData || {};
                const isLastRow = index === policyData.length - 1;
                const lastRowStyle = isLastRow ? 'lastRowStyle' : '';
                return (
                  <View key={index} style={[styles.rowSection, styles[lastRowStyle]]}>
                    <View style={[styles.innerInfo]}>
                      {!!heading && (
                        <HTMLView
                          value={`<p>${heading}</p>`}
                          stylesheet={getHtmlStyle(fonts, psTheme)}
                        />
                      )}
                      {!!subHeading && (
                        <Text style={[styles.timeHeading, AtomicCss.marginTop5]}>{subHeading}</Text>
                      )}
                    </View>
                    {!!penaltyText && (
                      <View style={[styles.innerInfoRight]}>
                        <HTMLView
                          value={`<p>${penaltyText}</p>`}
                          stylesheet={getHtmlStyle(fonts, psTheme)}
                        />
                      </View>
                    )}
                  </View>
                );
              })}
            </View>
          )}
        </View>
      </View>
      {!!cancellationPolicyTimeText && (
        <View style={[AtomicCss.marginBottom10, AtomicCss.flexRow, AtomicCss.paddingHz16]}>
          <Image style={styles.iconStyle} source={infoIcon} />
          <Text style={styles.internationalTimeText}>{cancellationPolicyTimeText}</Text>
        </View>
      )}
      {!!policyFooterText && (
        <View style={styles.footer}>
          <Text style={styles.footerText}>{policyFooterText}</Text>
        </View>
      )}
      <TouchableOpacity style={styles.okayButton} onPress={handleBottomOverlay}>
        <Text style={styles.okayText}>{okayText}</Text>
      </TouchableOpacity>
    </React.Fragment>
  );
};

export default CancellationPolicyOverlay;
