import { StyleSheet } from 'react-native';

const createStyles = (fonts, theme) =>
  StyleSheet.create({
    innerCardWrapper: {
      padding: 16,
      margin: 10,
      backgroundColor: theme.color.white,
      marginBottom: 80,
    },
    faqRow: {
      borderBottomColor: theme.color.grey4,
      borderBottomWidth: 1,
      paddingBottom: 16,
      paddingTop: 14,
    },
    lastElement: {
      borderBottomColor: 'transparent',
      borderBottomWidth: 0,
    },
    thirdRowStyles: {
      borderBottomColor: 'transparent',
      borderBottomWidth: 0,
    },
   
    viewMoreText: {
      color: theme.color.azure,
      ...fonts.boldFontFamily,
      fontSize: fonts.fontSize.font14,
    },
    titleText: {
      ...fonts.blackFontFamily,
      color: theme.color.defaultTextColor,
      fontSize: fonts.fontSize.font16,
    },
    arrowDownStyle: {
      width: 12,
      height: 7,
      right: 10,
      marginLeft: 10,
    },
    activeArrow: {
      transform: [
        {
          rotate: '-180deg',
        },
      ],
    },
  });

export const createHtmlQuestionStyles = (fonts, theme)=>({
  p: {
    ...fonts.boldFontFamily,
    color: theme.color.defaultTextColor,
    fontSize: fonts.fontSize.font14,
  },
});

export const createHtmlAnswerStyles = (fonts, theme)=> ({
  p: {
    color: theme.color.defaultTextColor,
    fontSize: fonts.fontSize.font12,
  },
});


export default createStyles;
