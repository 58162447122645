import React from 'react';
import PropTypes from 'prop-types';
import {Platform, StyleSheet, Text, TouchableOpacity, View, Image, BackHandler} from 'react-native';
import {colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import {resetToHotelDetail} from '../utils/HotelBookingDetailUtil';
import BasePage from '../../../Common/PostSalesBasePage';
import CtaButton from '@mmt/legacy-commons/Common/Components/Buttons/CtaButton';
import { getStaticData } from '../../../staticData/staticData';
import { PAHX_SUCCESS_ICON } from '../../HotelImageConstants';
import { getFont, themeColors } from '../../../PostSalesStyles';

const icon = PAHX_SUCCESS_ICON;

export default class PSThankYouPage extends BasePage {
    static navigationOptions = {
      header: null
    };

    constructor(props) {
      super(props, 'psThankYouPage');
      this.fonts = getFont(true);
      this.styles = getStyles(this.fonts);
    }

    componentDidMount() {
      BackHandler.addEventListener('hardwareBackPress', this.onHardBackPress);
      super.componentDidMount();
    }

    componentWillUnmount() {
      BackHandler.removeEventListener('hardwareBackPress', this.onHardBackPress);
    }

    render() {
      return this.renderPage()
    }

    onHardBackPress = () => {
      this.goToBookingDetails();
      return true;
    };

    renderPage = () => {
      const staticData = getStaticData();
      return (
        <View style={this.styles.thankYouWrapper}>
          <Image style={[this.styles.dateChangeIcon, this.styles.mar50Bt, this.styles.CenterText]} source={icon} />
          <Text
            style={this.styles.messageTitle}
          >{this.props.msg}
          </Text>
          <Text style={[this.styles.messageText, this.styles.successMsg]}>{this.props.subMsg}</Text>
          <TouchableOpacity
            style={[this.styles.CommonFillBtn, this.styles.transparantBgrnd]}
            onPress={() =>
                      this.goToBookingDetails()}
          />
          <CtaButton
            label={staticData.seeUpdatedBookingCapitalizeText}
            style={[this.styles.CommonFillBtn]}
            onPress={() => {
                      this.goToBookingDetails();
                  }}
            buttonGradient={themeColors.gradientBtnColor}
          />
        </View>
      );
    };

    goToBookingDetails = () => {
      resetToHotelDetail(this.props.bookingID);
    };
}

const getStyles = (fonts) => {
  return StyleSheet.create({
    parentContainer: {
      flex: 1,
      backgroundColor: '#ffffff'
    },
    thankYouWrapper: {
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#ffffff',
      paddingHorizontal: '10%'
    },
    transparantBgrnd: {
      ...Platform.select({
        ios: {
          backgroundColor: 'transparent'
        }
      })
    },
    messageTitle: {
      ...fonts.font20,
      textAlign: 'center',
      lineHeight: 25,
      color: '#000000',
      fontFamily: fonts.bold,
      marginBottom: 27
    },
    messageText: {
      textAlign: 'center',
      ...fonts.font12,
      color: '#000000',
      paddingBottom: 16
    },
    CommonFillBtn: {
      width: '100%'
    },
    CommonFillBtnGradient: {
      borderRadius: 25,
      paddingVertical: 11,
      paddingHorizontal: 25,
      marginTop: 24
    },
    CommonFillBtnText: {
      color: colors.white,
      alignSelf: 'center',
      fontFamily: fonts.black
    }
  });
}

PSThankYouPage.propTypes = {
  msg: PropTypes.string.isRequired,
  subMsg: PropTypes.string.isRequired,
  bookingID: PropTypes.string.isRequired,
  lob: PropTypes.string.isRequired
};
