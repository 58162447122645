import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
import { StyleSheet, Dimensions } from 'react-native';
import { DEFAULT_THEME } from "apps/post-sales/src/theme/default.theme";

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export default <T extends typeof DEFAULT_THEME>(theme: T, fonts: any) => StyleSheet.create({
    mainWrapper: {
        flex: 1,
        backgroundColor: theme.color.grayBg,
    },
    scrollWrapperStyle: {
        backgroundColor: theme.color.red,
    },
    adsPlaceholder: {
        ...getPlatformElevation(2),
        backgroundColor: theme.color.white,
        borderTopWidth: 1,
        borderTopColor: theme.color.grey8,
        height: 64,
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 12,
        paddingVertical: 10,
    },
    adsText: {
        color: theme.color.defaultTextColor,
        fontSize: fonts.fontSize.font22,
    },
    innerWrapper: {
        paddingVertical: 50,
        flex: 1,
    },
    bgImageStyle: {
        width: windowWidth,
        height: windowHeight,
        position: 'absolute'
    },
})