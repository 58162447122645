import React, { useState, useMemo, useEffect } from 'react';
import { View, Text, ScrollView, StyleSheet } from 'react-native';
import ReviewButton from '../../../SelectPassengers/ReviewButton';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import CheckboxRow from '../../../../../Common/Checkbox';
import { ALERT_MESSAGE_SPECIAL_CLIAM } from '../../../../../PostSalesConstant';
import OptionsList from '../../../../../Common/OptionsListWithRadio/OptionsListWithRadio';
import { colors, fonts } from '@mmt/legacy-commons/Styles/globalStyles';
import {
  isEmptyArray,
  isNullOrEmpty,
  isNullOrEmptyObject,
} from '../../../../../Common/commonUtil';
import { CLAIM_ANCILLARY_DONE } from '../../../../../utils/NetworkUtils';
import AncillaryClaimProcess from '../AncillaryClaimProcess';
import Actions from '../../../../../navigation/postSalesNavigation';
import useApi from '../../../../../Common/useApi';
import SpecialClaimTrackingHelper from '../../../Helpers/SpecialClaimTrackingHelper';
import { getFont } from '../../../../../PostSalesStyles';

const AncillaryRefundMode = ({
  loaderMsg,
  bookingId,
  headerComp,
  reviewData,
  requestData,
  uploadedFiles,
  selectedData,
  flightResponse,
  scCode,
}) => {
  const [claimDoneInProgress, claimDoneData, claimDoneApi] = useApi(CLAIM_ANCILLARY_DONE);
  const { cards, actionLists } = reviewData || {};
  const pageName = 'AncillaryClaimRefundMode';
  const { fsStyle } = getFont(true);

  useEffect(() => {
    SpecialClaimTrackingHelper.trackAncillaryClaimLoadEvent(pageName, flightResponse, { scCode });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refundModeInfo = useMemo(() => {
    if (!isEmptyArray(cards)) {
      const data = cards.find((card) => card.id === 'REFUND_MODE');
      if (data && !isEmptyArray(data.entries)) {
        data.refundModeList = data.entries.map((mode) => ({
          title: mode.title,
          subText: mode.subtitle,
          label: mode.topHeader,
          refundModeReason: mode.id,
        }));
      }
      return data;
    }
    return null;
  }, [cards]);

  const action = useMemo(() => {
    if (!isEmptyArray(actionLists)) {
      const data = actionLists.find((card) => card.actionId === 'sc_refund_mode_raise');
      return data;
    }
    return null;
  }, [actionLists]);

  const { title, subtitle, refundModeList, footerText } = refundModeInfo || {};

  const [selectedMode, setSelectedMode] = useState(refundModeList[0].refundModeReason);
  const [isDeclared, setIsDeclared] = useState(false);

  const onModeChangeHandler = (mode) => {
    setSelectedMode(mode);
  };

  const onDeclarationChangeHandler = () => {
    setIsDeclared(!isDeclared);
  };

  const actionHandler = () => {
    SpecialClaimTrackingHelper.trackClickEvent(pageName, 'submit_ancillary_claim');
    claimDoneApi.httpPost({
      bookingId,
      uniqueId: 'FLIGHT_CLAIM_ANCILLARY_DONE',
      body: {
        ...requestData,
        ancillarieslines: Object.values(selectedData),
        refundOption: selectedMode,
        fileUrls: uploadedFiles || [],
        submitWithProof: !isEmptyArray(uploadedFiles),
      },
    });
  };

  if (claimDoneData) {
    const tyPageName = 'AncillaryClaimThankyou';
    if (!isEmptyArray(claimDoneData.cards)) {
      const successData = claimDoneData.cards.find(
        (card) => card.id === 'AncillaryClaimSuccessData',
      );
      const whatNextCard = claimDoneData.cards.find((card) => card.id === 'whatNextCard');
      if (successData) {
        SpecialClaimTrackingHelper.trackAncillaryClaimLoadEvent(tyPageName, flightResponse, {
          scCode,
        });
        Actions.specialClaimRequestThankYou({
          bookingId,
          heading: successData.title,
          description: successData.subtitle,
          whatNextCard,
        });
      } else {
        SpecialClaimTrackingHelper.trackAncillaryClaimErrorEvent(tyPageName);
      }
    } else if (!isNullOrEmptyObject(claimDoneData.error)) {
      SpecialClaimTrackingHelper.trackAncillaryClaimErrorEvent(tyPageName);
    }
  }

  return (
    <AncillaryClaimProcess
      {...{
        loading: loaderMsg || claimDoneInProgress,
        loaderMsg,
        error: claimDoneData
          ? claimDoneData.error || !isNullOrEmptyObject(claimDoneData.buinsessError)
          : null,
        onRetry: actionHandler,
        pageName,
      }}
    >
      {headerComp}
      <ScrollView contentContainerStyle={AtomicCss.paddingBottom70}>
        <View style={AtomicCss.whiteCard}>
          <Text style={[AtomicCss.headingTxt, AtomicCss.marginBottom10]}>{title}</Text>
          <Text style={[styles.subHeading, AtomicCss.marginBottom20, fsStyle.font12]}>
            {subtitle}
          </Text>
          {!isNullOrEmpty(refundModeList) && (
            <OptionsList
              list={refundModeList}
              selectedOptions={selectedMode}
              keyName="refundModeReason"
              onChangeHandler={onModeChangeHandler}
            />
          )}
        </View>
        <View style={AtomicCss.whiteCard}>
          <CheckboxRow
            label={footerText}
            errorText={ALERT_MESSAGE_SPECIAL_CLIAM}
            errorTextStyle={[styles.errorText, fsStyle.font14]}
            showErrorText
            containerStyle={styles.checkboxRow}
            checked={isDeclared}
            onChange={onDeclarationChangeHandler}
          />
        </View>
      </ScrollView>
      {action && (
        <View style={styles.footerBtnWrapper}>
          <ReviewButton
            buttonText={action.actionText}
            clickHandler={actionHandler}
            disabled={!isDeclared}
          />
        </View>
      )}
    </AncillaryClaimProcess>
  );
};

export default AncillaryRefundMode;

const styles = StyleSheet.create({
  footerBtnWrapper: {
    padding: 12,
    position: 'absolute',
    zIndex: 2,
    bottom: 0,
    left: 0,
    width: '100%',
    backgroundColor: '#ffffff',
  },
  subHeading: {
    color: colors.defaultTextColor,
    fontFamily: fonts.regular,
  },
  checkboxRow: {
    alignItems: 'flex-start',
  },
  errorText: {
    color: colors.errorRed,
    fontFamily: fonts.regular,
    marginTop: 4,
  },
  pageScrollBtmSpace: {
    height: 58,
  },
});
