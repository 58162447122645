const IndigoTheme = {
      primary: '#001B94',
      background: 'rgb(255,0,0)',
      card: 'red',
      text: '#FFFFFF',
      topCardText:'#FFFFFF',
      border: 'red',
      notification: 'red',
      lightText:'rgba(242, 242, 242, 0.56)',
      gradientBtnColor: ['#001B94', '#001B94'],
      linkTextColor: '#001B94',
      loaderColor: '#001B94',
      radioColor: '#001B94',
      backArrowTintColor: '#FFFFFF',
      postSalesLoaderColor: '#001B94',
      RectFullButtonText:'#001B94',
      RectFullButtonBackground: '#FFFFFF',
      CapsuleBtnFillColors: ['#001B94','#001B94'],
      CapsuleBtnFillTextColor: '#FFFFFF',
      viewMoreText: '#001B94',
      WhiteFullCapsuleText: '#001B94',
      rightArrowTintColor: '#001B94',
      actionTitleColor:'#001B94',
      activeTabColor:'#001B94',
      RoundButtonWithIconText:'#001B94',
      FullWidthButtonGradientColor:['#001B94', '#001B94'],
      CapsuleBtnTextColor: '#001B94',
      topCardGradientColor:['#7195C8','#001B94'],
      whiteBtnTxt:'#001B94',
      whiteButtonBorderColor: '#001B94',
      redirectLinkTextColor: '#001B94',
      roundBtnBlueText: '#ffffff',
      roundBtnLightBlueText: '#ffffff',
      blueRoundBtnBg: '#001B94',
      lightBlueRoundBtnBg: '#7195C8',
      roundBtnBlueIconBg:'white',
      roundBtnWhiteIconBg:'white',
      roundBtnLightBlueIconBg:'white',
      confirmedWithCompletePaymentStartColor: '#001B94',
      confirmedWithCompletePaymentEndColor: '#001B94',
      confirmedWithPartialPaymentStartColor: '#001B94',
      confirmedWithPartialPaymentEndColor: '#001B94',
      cancelledStartColor: '#001B94',
      cancelledEndColor: '#001B94',
      failedStartColor: '#001B94',
      failedEndColor:'#001B94',
      completeStartColor: '#001B94',
      completeEndColor: '#001B94',
      onTripStart: '#001B94',
      onTripEnd: '#001B94',
      topCardFontColor1:'#FFFFFF',
      topCardFontColor2:'#FFFFFF',
      Visa_blueStartColor:'#001B94',
      Visa_blueEndColor:'#001B94',
      Visa_OrangeStartColor:'#001B94',
      Visa_OrangeEndColor:'#001B94',
      Visa_GreenStartColor:'#001B94',
      Visa_GreenEndColor:'#001B94',
      Visa_purpleStartColor:'#001B94',
      Visa_purpleEndColor: '#001B94',
      Visa_blackStartColor: '#001B94',
      Visa_blackEndColor: '#001B94',
      Visa_pinkStartColor: '#001B94',
      Visa_pinkEndColor: '#001B94',
      Visa_YellowStartColor: '#001B94',
      Visa_YellowEndColor: '#001B94',
      Visa_greyPinkStartColor: '#001B94',
      Visa_GreyPinkEndColor: '#001B94',
      Visa_lightblueStartColor: '#001B94',
      Visa_lightblueendColor: '#001B94',
      blueStartColor:'#001B94',
      blueEndColor: '#001B94',
      blackStartColor: '#001B94',
      blackEndColor: '#001B94',
      pinkStartColor: '#001B94',
      pinkEndColor: '#001B94'
  };
  export default IndigoTheme;
