import { NativeModules } from 'react-native';
import { getRequestHeader } from './NetworkModule';
import { Actions, defaultURLParams } from "../router/routerFluxMock";
import { appendURLParams, openURL } from "../webUtils";
import {
    EXISTING_BASE_URL,
    FLIGHT_DOWNLOAD_TRIGGERS,
    FLIGHT_DOWNLOAD_TICKET,
    CONTENT_TYPE,
    FLIGHT_DOWNLOAD_BAGGAGE_TAG,
    ENGLISH,
    GET_AWS_OBJECT,
    DOWNLOAD_HOTEL_VOUCHER_PDF
} from '../const';
import { convertStringToBlob, _getDownloadFileName, _getLangCookie } from '../webUtils';
import { FLIGHT_ROUTE_URLS } from '../router/Routes/Flights';
import  customRouter  from '../router/RouterCustomStack';

const stringifyData = (data) => data && JSON.stringify(data);

const openBusBookingDetail = (bookingId, pageData) => {
   Actions.busBookingDetail({
    data: {
        BOOKING_ID: bookingId,
        pageData: stringifyData(pageData)
    }
})
}

const openCabBookingDetail = (bookingId, pageData) => {
    Actions.cabBookingDetail({
        data : { 
            BOOKING_ID: bookingId,
            pageData: stringifyData(pageData)
        }
    })
}

const openRailBookingDetail = (bookingId, pageData) => {
    Actions.railBookingDetail({
        BOOKING_ID: bookingId,
        pageData: stringifyData(pageData)
    })
}

const openSelfDriveBookingDetail = (bookingId, response, pageData) => {
    if(response.selfDriveDetails[0].vendorName === "zoomcar"){
        Actions.downloadApp({
            BOOKING_ID: bookingId
        })
    }else{
        Actions.selfDriveBookingDetail({
            BOOKING_ID: bookingId,
            pageData: stringifyData(pageData)
        })
    }
}

const openHotelBookingDetail = (bookingId, response, pageData) => {
    Actions.hotelBookingDetail({
        BOOKING_ID: bookingId,
        pageData: stringifyData(pageData)
    })
}

const openHolidayBookingDetail = (bookingId, phoneNumber, pageData) => {
    Actions.holidayBookingDetail({
        data: {
            BOOKING_ID: bookingId,
            PARENT_PAGE: 'holidayBookingDetail',
            pageData: stringifyData(pageData)
        }
    })
    // openURL(`https://supportz.makemytrip.com/agent/login/recon/holidaydetails/home/${bookingId}`)
}

const openFlightBookingDetail = (bookingId, pageData) => {
    Actions.flightBookingDetail({
        BOOKING_ID: bookingId,
        pageData: stringifyData(pageData)
    })
}

const openMetroBookingDetail = (bookingId) => {
    openURL(`${EXISTING_BASE_URL}Metro?bookingId=${bookingId}&isPwa=true`)
}

const openAcmeBookingDetail = (bookingId, pageData) => {
    Actions.acmeBookingDetail({
        data: {
            BOOKING_ID: bookingId,
            pageData: stringifyData(pageData)
        }
    });
}

const openVisaBookingDetail = (bookingId, pageData) => {
    Actions.visaBookingDetail({
        data: bookingId,
        pageData: stringifyData(pageData)
    });
}


export const downloadTicket = async (triggerName, bookingId) => {
    if(triggerName && bookingId) {
        const requestBody = {
            sendEticket: !!(triggerName === FLIGHT_DOWNLOAD_TRIGGERS.E_TICKET || triggerName === FLIGHT_DOWNLOAD_TRIGGERS.FLIGHT_ETICKET_MYPARTNER),
            sendInvoice: triggerName === FLIGHT_DOWNLOAD_TRIGGERS.INVOICE,
            userId: 'PWA',
            triggerName,
            bookingId
        };
        await downloadRquest(requestBody, bookingId, triggerName);
        checkIfNotEnglish(triggerName) && await downloadRquest(requestBody, bookingId, triggerName, false);
    }
}

const downloadInsurancePolicy = async(awskey, bookingId, triggerName) => {
    if(awskey && bookingId) {
        const reqBody = {
            bookingId,
            awskey
        }
        await downloadRquest(reqBody, bookingId, triggerName);
    }
}

const downloadRquest = async (
  requestBody,
  bookingId,
  triggerName,
  langAware
) => {
  try {
    const userInfo = await getRequestHeader(bookingId, triggerName);
    import("@rn/apps/post-sales/src/utils/NetworkUtils")
      .then(async (module) => {
        const getCommonHeaders = module.getCommonHeaders;
        const headers = await getCommonHeaders(
          userInfo.mmtAuth,
          userInfo.loggingTrackingInfo
        );
        headers.langAware = langAware;
        const voucherResponse = await fetch(getDownloadURL(triggerName), {
          method: "POST",
          headers,
          body: JSON.stringify(requestBody),
        });

        if (voucherResponse.ok) {
          const responseBody = await voucherResponse.json();
          if (
            responseBody &&
            (responseBody.success || responseBody.status) &&
            (responseBody.pdfBytes || responseBody.fileContent)
          ) {
            let blob = convertStringToBlob(
              responseBody.pdfBytes || responseBody.fileContent,
              CONTENT_TYPE.APPLICATION_PDF
            );
            const download = require("downloadjs");
            download(
              blob,
              _getDownloadFileName(triggerName, bookingId),
              CONTENT_TYPE.APPLICATION_PDF
            );
          } else {
            // showShortToast("Error");
          }
        } else {
          // showShortToast("Error");
        }
      })
      .catch((error) => {
        console.error("Failed to load module", error);
      });
  } catch (error) {
    console.error(error);
    // showShortToast("Error");
  }
};

const saveTicket = (bytes, fileName) => {
    try {
        let blob = convertStringToBlob(bytes, CONTENT_TYPE.APPLICATION_PDF);
        const download = require("downloadjs");
        download(blob, fileName, CONTENT_TYPE.APPLICATION_PDF);
    } catch (error) {
        // showShortToast("Error");
    }
}

const saveFile = (blob, fileName, contentType) => {
    try {
      const download = require("downloadjs");
      download(blob, fileName, contentType);
    } catch (error) {
      console.error("Error while saving the file:", error);
    }
  };
  

const checkIfNotEnglish = (triggerName) => {
    const langCookie = _getLangCookie();
    const lang = langCookie ? langCookie.toLowerCase() : null;
    if(lang && lang !== ENGLISH && triggerName === FLIGHT_DOWNLOAD_TRIGGERS.E_TICKET) {
        return true;
    }
    return false;
}

const getDownloadURL = (triggerName) => {
    switch (triggerName) {
        case FLIGHT_DOWNLOAD_TRIGGERS.E_TICKET:
        case FLIGHT_DOWNLOAD_TRIGGERS.INVOICE:
        case FLIGHT_DOWNLOAD_TRIGGERS.FLIGHT_ETICKET_MYPARTNER:
            return FLIGHT_DOWNLOAD_TICKET;
        case FLIGHT_DOWNLOAD_TRIGGERS.BAGGAGE:
            return FLIGHT_DOWNLOAD_BAGGAGE_TAG;
        case FLIGHT_DOWNLOAD_TRIGGERS.BOARDING_PASS:
        case FLIGHT_DOWNLOAD_TRIGGERS.INSURANCE:
            return GET_AWS_OBJECT;
    }
}

const onButtonClicked = (card, action, response) => {
    const actionObj = JSON.parse(action);
    const responseObj = JSON.parse(response);

    switch(actionObj.actionFamily){
        case "DownloadVoucher":
            downloadVoucherHandler(responseObj, "Hotel_CustomerVoucher");
            break;
        case "DownloadCustomerVoucher":
            downloadVoucherHandler(responseObj, "HotelVoucher_MyPartner_New");
            break;
    }
}

const downloadVoucherHelper = async (hotelVoucherBody, headers) => {
    try {
        const voucherResponse = await fetch(DOWNLOAD_HOTEL_VOUCHER_PDF, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(hotelVoucherBody)
        })

        if (voucherResponse.ok) {
            const responseBody = await voucherResponse.json();
            if (responseBody && responseBody.success && responseBody.pdf) {
                var blob = convertStringToBlob(responseBody.pdf, "application/pdf");
                const download = require("downloadjs");
                download(blob, "Trips_Hotel_DownloadETicket.pdf", "application/pdf");
            }
        }
    } catch (error) {
    }
}

const downloadVoucherHandler = async (response, triggerName) => {
    try {
        const bookingId = response.bookingID;

        let hotelVoucherBody = {
            triggerName: triggerName,
            bookingId: bookingId,
        };

        const userInfo = await getRequestHeader(bookingId, triggerName);
        import("@rn/apps/post-sales/src/utils/NetworkUtils")
        .then(async (module) => {
           const getCommonHeaders = module.getCommonHeaders;
           let headers = await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo);
            downloadVoucherHelper(hotelVoucherBody, headers)

            let { language } = headers;
            if (language && language !== ENGLISH) {
                // Download English Voucher as well.
                headers = await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo, { langAware: false });
                downloadVoucherHelper(hotelVoucherBody, headers);
            }
       })
       .catch((error) => {
            console.error("Failed to load module", error);
        });
        
    } catch (error) {
    }
}

const openFlightDateChangePaymentPage = async (response) => {
}

 function resetToFlightBookingDetail(bookingId) {
    const url = appendURLParams(FLIGHT_ROUTE_URLS.DETAIL_PAGE, { bookingId }, defaultURLParams);
    customRouter.popToRoute(url);
  }

NativeModules.FlightBookingModule = {
    getRequestHeader,
    openFlightBookingDetail,
    openBusBookingDetail,
    openCabBookingDetail,
    openRailBookingDetail,
    openSelfDriveBookingDetail,
    openHotelBookingDetail,
    openHolidayBookingDetail,
    openMetroBookingDetail,
    openAcmeBookingDetail,
    openVisaBookingDetail,
    downloadTicket,
    downloadInsurancePolicy,
    onButtonClicked,
    openFlightDateChangePaymentPage,
    resetToFlightBookingDetail,
    saveTicket,
    saveFile
};
