import React from 'react';
import { View, TouchableOpacity, Image, Platform } from 'react-native';
import createStyles from './HeaderCss'
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { BACK_ARROW, IOS_BACK_ARROW, SHARE_ICON } from '../../../../Utils/tripGuaranteeImageConstants';
import TGSBookingConstants from "../../../../Utils/TGSBookingConstants";
import { useTheme } from '../../../../../theme/theme.context';
import { getFont } from '../../../../../PostSalesStyles';
const Header = ({ response, goBack, isCrossIcon }) => {
    const fonts = getFont();
    const {psTheme : theme} = useTheme();
    const styles = createStyles(theme,fonts);
    const headerStyle = [styles.headerWrapper];
    const { topStatusCardInfo} = response || {};
    const { status} = topStatusCardInfo || {};
    const getHeaderBg = () => {
        let style = [];
        switch (status) {
            case TGSBookingConstants.PREPARED_CONFIRMED:
            case TGSBookingConstants.NOT_PREPARED:
            case TGSBookingConstants.PARTIALLY_CONFIRMED:
                style.push(styles.lightGreen)
                break;
            case TGSBookingConstants.NOT_CONFIRMED: 
            style.push(styles.lightYellow)
                break;
            case TGSBookingConstants.NOT_PREPARED_CANCELLED : 
            style.push(styles.lightPink)
            break;
            default:
                 break;
        }
        return style
    }
    return (
        <View style={[headerStyle, ...getHeaderBg()]}>
            <TouchableOpacity style={styles.backArrowWrapper} onPress={() => goBack()}>
                {isCrossIcon === true ?
                    <Image style={[styles.crossIconStyle, crossIconColor]} source={BACK_ARROW} />
                    :
                    <Image style={[styles.icon,AtomicCss.marginRight18]} source={Platform.OS === 'ios' ? IOS_BACK_ARROW : BACK_ARROW} />
                }
            </TouchableOpacity>

        </View> 
    )
}


export default Header