import React from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';
import styles from './TicketCss';
import CheckboxRow from './CheckboxRow';
import { fillCheckInCheckOutData } from '../../Common/commonUtil';
import MetroConstant from '../MetroConstant';
import {
  getSelectedPaxSize,
  getSelectedTicketIds,
  getSelectedTicketKey,
  isPaxAlreadySelected
} from '../MetroBookingDetailUtil';
import {SELECTED_CHECK_BOX,CHKBOX_ICON,ONE_WAY_JOURNEY } from '../MetroImageConstant';

export default class Ticket extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTicket: new Map()
    };
    this.setAllPaxCheckStatus(false);

  }

  render() {
    const allPaxSelected = this.isAllTicketSelected();
    console.log("Ticket Map: ", this.state.selectedTicket)
    const allSelectedStyle = allPaxSelected ? 'Selected' : '';
    return (
      <View style={styles.cardWrapper}>
        <View style={styles.checkAllWrapper}>
          <View style={[styles.checkAllWrapperInner]}>
            <TouchableOpacity
              style={[styles.checkBtn, styles.checkAllBtn, styles.PaxslctionHeader]}
              activeOpacity={0.9}
              onPress={() => this.checkAll()}
              disabled={false}
            >
              <View style={styles.SectorHeader}>
                <View style={{ flexDirection: 'row' }}>
                  <View
                    style={[styles.checkbox, styles.checkboxAll, styles[`checkbox${allSelectedStyle}`]]}
                  >
                    {allPaxSelected
                      && <Image style={styles.checkedIcon} source={CHKBOX_ICON} />}
                  </View>
                  <View>{this.addTicketInfo()}
                  {this.props.journeyType === MetroConstant.RETURN_JOURNEY && this.getSegmentSubTitle()}
                  </View>
                </View>
              </View>
            </TouchableOpacity>
          </View>
        </View>
        <View style={styles.passengersList}>
          {this.addTicket(this.props.source)}
        </View>
      </View>
    );
  }

  addTicketInfo() {
    const views = [];
    if (this.props.journeyType === MetroConstant.FORWARD_JOURNEY) {
      views.push(
        <View key={this.props.source}>
          <View style={[styles.destRow]}>
            <Text style={styles.cityText}>{this.props.source}</Text>
            <Image style={styles.arrowIcon} source={ONE_WAY_JOURNEY}/>
            <Text style={styles.cityText}>{this.props.destination}</Text>
            <View style={styles.airlinesIconWrapper}>
              <Image style={styles.airlineIcon} source={{uri: "airlineLogoUrl"}}/>
            </View>
          </View>
          <Text style={styles.smallText}>
            {fillCheckInCheckOutData(this.props.travelDateTime, 'ddd, DD MMM YYYY') + ',  ' + "Hyderabad Metro"}
          </Text>
        </View>
      );
    }
    else {
      views.push(
        <View key={this.props.source}>
          <View>
          <View style={[styles.destRow]}>
            <Text style={styles.cityText}>{this.props.source}</Text>
            <Image style={styles.arrowIcon} source={ONE_WAY_JOURNEY}/>
            <Text style={styles.cityText}>{this.props.destination}</Text>
            <View style={styles.airlinesIconWrapper}>
              <Image style={styles.airlineIcon} source={{uri: "airlineLogoUrl"}}/>
            </View>
          </View>
            <View style={[styles.destRow]}>
              <Text style={styles.cityText}>{this.props.destination}</Text>
              <Image style={styles.arrowIcon} source={ONE_WAY_JOURNEY}/>
              <Text style={styles.cityText}>{this.props.source}</Text>
              <View style={styles.airlinesIconWrapper}>
                <Image style={styles.airlineIcon} source={{uri: "airlineLogoUrl"}}/>
              </View>
            </View>
          </View>
          <Text style={styles.smallText}>
            {fillCheckInCheckOutData(this.props.travelDateTime, 'ddd, DD MMM YYYY') + ',  ' + "Hyderabad Metro"}
          </Text>

        </View>
      );
    }
    return views;
  }

  getSegmentSubTitle = () => {
      return (
        <View><Text style={styles.rtInfo}>Metro does not allow cancellation of one of the ticket for this particular journey.
        However you can cancel complete return journey.</Text></View>);
  }

  addTicket() {
    const paxViews = [];
    let addPax = true;
    if (this.props.bookingInfo.ticketInfoList) {
      let fIndex = 1;
      let rIndex = 1;
      this.props.bookingInfo.ticketInfoList.forEach((ticket) => {
        paxViews.forEach(pax => {
          if ((pax.key === ticket.ticketId && this.props.journeyType === MetroConstant.FORWARD_JOURNEY) ||
             (pax.key === ticket.rjtId && this.props.journeyType === MetroConstant.RETURN_JOURNEY)) {
            addPax = false;
          }
        });

        if(addPax) {
          if(this.props.journeyType === MetroConstant.FORWARD_JOURNEY) {
            paxViews.push(<CheckboxRow
              key={ticket.ticketId}
              index={fIndex}
              ticketData={ticket}
              selected={this.state.selectedTicket.get(ticket.ticketId)}
              clickHandler={this.onTicketClicked}
            />);
            fIndex++;
          }
          else if (this.props.journeyType === MetroConstant.RETURN_JOURNEY) {
            paxViews.push(<CheckboxRow
              key={ticket.rjtId}
              index={rIndex}
              ticketData={ticket}
              selected={this.state.selectedTicket.get(ticket.ticketId)}
              clickHandler={this.onTicketClicked}
            />);
            rIndex++;
          }
        }
      });
    }
    return paxViews;
  }

  onTicketClicked = (ticket, selected) => {
    if(this.props.journeyType === MetroConstant.RETURN_JOURNEY) {
      this.props.bookingInfo.ticketInfoList.forEach(tick => {
        if (tick.rjtId === ticket.rjtId && getSelectedPaxSize(this.state.selectedTicket) <=1 ) {
          this.state.selectedTicket.set(tick.ticketId, selected);
          this.props.ticketClickHandler(tick, selected);
        }
        else if(!selected && getSelectedTicketIds(this.state.selectedTicket).includes(tick.ticketId) && getSelectedPaxSize(this.state.selectedTicket) === 2 ) {
          this.state.selectedTicket.clear();
          this.props.ticketClickHandler(tick, selected);
        }
      });
    }

    if (this.props.journeyType === MetroConstant.FORWARD_JOURNEY) {
      this.props.bookingInfo.ticketInfoList.forEach((tick) => {
        if (tick.ticketId === ticket.ticketId && getSelectedPaxSize(this.state.selectedTicket) === 0) {
          this.state.selectedTicket.set(tick.ticketId, selected);
          this.props.ticketClickHandler(tick, selected);
        }
         else if(!selected && tick.ticketId === getSelectedTicketKey(this.state.selectedTicket) && getSelectedPaxSize(this.state.selectedTicket) === 1 ) {
           this.state.selectedTicket.clear();
           this.props.ticketClickHandler(tick, selected);
         }
      });
    }

    this.setState({
      selectedTicket: this.state.selectedTicket
    });
  };

  isAllTicketSelected() {
    let selectedList = [...this.state.selectedTicket.values()];
    let totalPax = this.props.bookingInfo.ticketInfoList.length;
    let truePax = selectedList.filter(val => val === true).length;
    return totalPax === truePax;
  }

  checkAll() {
    const allSelected = !this.isAllTicketSelected();
    this.props.bookingInfo.ticketInfoList.forEach((ticket) => {
      this.state.selectedTicket.set(ticket.ticketId,allSelected);
      this.props.ticketClickHandler(ticket, allSelected);
    });

    this.setState({
      selectedTicket: this.state.selectedTicket
    });
  }

  setAllPaxCheckStatus = (select) => {
    if (this.props.bookingInfo.ticketInfoList) {
      this.props.bookingInfo.ticketInfoList.forEach((ticket) => {
        this.state.selectedTicket.set(ticket.ticketId,select);
      });
    }
  };

}

Ticket.propTypes = {
  key: PropTypes.string.isRequired,
  ticketClickHandler: PropTypes.func.isRequired,
  source: PropTypes.string.isRequired,
  destination: PropTypes.string.isRequired,
  travelDateTime: PropTypes.string.isRequired,
  journeyType: PropTypes.string.isRequired,
  bookingInfo: PropTypes.object.isRequired
};

