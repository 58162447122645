import React from 'react';
import { View, StyleSheet, Image } from 'react-native';

const StarRating = (props) => {
    const { defaultStarImg, activeStarImg, starValue, iconWidth, iconHeight, customStyle } = props;

    const renderDefaultIcon = () => {
        let temp = [];
        for (let i = 0; i < 5; i++) {
            temp.push(<Image key={i} style={{width: iconWidth, height: iconHeight, resizeMode: 'cover'}} source={defaultStarImg} />);
        }
        return temp;
    }
    const renderActiveIcon = () => {
        let temp = [];
        for (let i = 0; i < 5; i++) {
            temp.push(<Image key={i} style={{width: iconWidth, height: iconHeight, resizeMode: 'cover'}} source={activeStarImg} />);
        }
        return temp;
    }

    const calculatedWidth = iconWidth * starValue;

    return (
        <View style={[styles.starCont, {...customStyle}]}>
            <View style={[styles.activeIcon, {width: calculatedWidth}]}>{renderActiveIcon()}</View>
            <View style={styles.defaultIcon}>{renderDefaultIcon()}</View>
        </View>
    );
}

export default StarRating;

const styles = StyleSheet.create({
  starCont: {
    alignSelf: 'flex-start',
  },
  activeIcon: {
    flexDirection: 'row',
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 1,
    overflow: 'hidden',
  },
  defaultIcon: {
    flexDirection: 'row',
  },
});
