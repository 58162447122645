import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
import { StyleSheet } from 'react-native';

export default (theme, fonts) => StyleSheet.create({
  filterItemRow: {
    paddingLeft: 15,
    paddingRight: 15,
    borderBottomWidth: 1,
    borderBottomColor: theme.color.lightGrey,
    paddingVertical: 20,
  },
  FiltersHeading: {
    fontSize: fonts.fontSize.font14,
    color: theme.color.gray,
    ...fonts.boldFontFamily,
    marginBottom: 15,
  },
  filterStopMain: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  filterStopWrapper: {
    width: 100,
    height: 100,
    ...getPlatformElevation(1),
    backgroundColor: 'transparent',
    borderRadius: 4,
    padding: 10,
  },
  stopCountTxt: {
    fontSize: fonts.fontSize.font24,
    color: theme.color.grey29,
    ...fonts.regularFontFamily,
    textAlign: 'center',
  },
  stopTxt: {
    fontSize: fonts.fontSize.font12,
    color: theme.color.grey29,
    ...fonts.regularFontFamily,
    paddingBottom: 3,
    borderBottomWidth: 1,
    borderBottomColor: theme.color.grey30,
    textAlign: 'center',
    marginBottom: 7,
  },
  stopPriceTxt: {
    fontSize: fonts.fontSize.font10,
    color: theme.color.grey29,
    ...fonts.regularFontFamily,
    textAlign: 'center',
  },

  filterStopWrapperActive: {
    width: 100,
    height: 100,
    ...getPlatformElevation(1),
    backgroundColor: theme.color.azure,
    borderRadius: 4,
    padding: 10,
  },

  filterDepartureWrapper: {
    width: 75,
    ...getPlatformElevation(1),
    backgroundColor: 'transparent',
    borderRadius: 4,
    paddingVertical: 10,
    paddingHorizontal: 5,
  },

  depIcon: {
    width: 24,
    height: 22,
    alignSelf: 'center',
    marginBottom: 3,
  },
  DepmorningIcon: {
    width: 24,
    height: 18,
  },
  DepTxt: {
    fontSize: fonts.fontSize.font9,
    color: theme.color.grey29,
    ...fonts.regularFontFamily,
    paddingBottom: 5,
    borderBottomWidth: 1,
    borderBottomColor: theme.color.grey30,
    textAlign: 'center',
  },
  filterFareTypeWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  fareTypeImg: {
    width: 23,
    height: 22,
    marginRight: 30,
  },
  ChkBox: {
    width: 18,
    height: 18,
  },
  fareTypeTxt: {
    fontSize: fonts.fontSize.font14,
    color: theme.color.lightTextColor,
    ...fonts.regularFontFamily,
    paddingTop: 3,
  },
  innerDtlsWrap: {
    flexDirection: 'row',
  },
  headingBottomBorder: {
    borderBottomWidth: 1,
    borderBottomColor: theme.color.lightGrey,
    paddingBottom: 15,
    marginHorizontal: -15,
    paddingHorizontal: 15,
    marginBottom: 5,
  },
  AirlineImg: {
    width: 27,
    height: 27,
    marginRight: 30,
  },
  AirlineNameTxt: {
    ...fonts.regularFontFamily,
    fontSize: fonts.fontSize.font14,
    color: theme.color.lightTextColor,
    marginTop: 3,
  },
  AirlinePriceTxt: {
    ...fonts.regularFontFamily,
    fontSize: fonts.fontSize.font12,
    color: theme.color.lightTextColor,
    marginRight: 20,
    marginTop: 2,
  },
  filterAirlineWrapper: {
    borderBottomWidth: 1,
    borderBottomColor: theme.color.grey30,
    paddingVertical: 15,
    marginHorizontal: -15,
    paddingHorizontal: 15,
  },
  ShowAllAirlines: {
    paddingTop: 15,
    paddingBottom: 0,
  },
  ShowAllAirlinesTxt: {
    color: theme.color.azure,
    fontSize: fonts.fontSize.font14,
    ...fonts.regularFontFamily,
  },
  mealIcon: {
    width: 24,
    height: 19,
    marginRight: 30,
  },
  noBaggageicon: {
    width: 23,
    height: 19,
    marginRight: 30,
  },
  noTravelIcon: {
    width: 20,
    height: 24,
    marginRight: 30,
  },
  OtherFiltersTxt: {
    ...fonts.regularFontFamily,
    fontSize: fonts.fontSize.font14,
    color: theme.color.lightTextColor,
    marginTop: 3,
  },
  FilterBgBlue: {
    backgroundColor: theme.color.azure,
  },
  TextWhite: {
    color: theme.color.white,
  },
  TextBlue: {
    color: theme.color.azure,
  },
  borBottom0: {
    borderBottomColor: theme.color.grey22,
    borderBottomWidth: 0,
  },
  textGray: {
    color: theme.color.rippleColor,
  },
});
