import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';

const styles = {
  footerBtnWrapper: {
    padding: 12,
    position: 'absolute',
    zIndex: 2,
    bottom: 0,
    left: 0,
    width: '100%',
  },
  whiteWrapper: {
    backgroundColor: '#ffffff',
    marginBottom: 10,
    ...getPlatformElevation(2),
  },
  cancInfoHeading: {
    ...getPlatformElevation(2),
    padding: 16,
    backgroundColor: '#fff',
    marginBottom: 2,
    alignItems: 'center',
  },
  bullet: {
    height: 4,
    width: 4,
    borderColor: colors.black,
    borderWidth: 1,
    borderRadius: 4,
    marginRight: 10,
    backgroundColor: colors.black,
    marginTop: 7,
  },
  descriptionDetail: {
    paddingTop: 20,
    marginLeft: 16,
    paddingRight: 32,
    paddingBottom: 10,
    backgroundColor: '#fff',
  },
  descIconContainer: {
    width: 56,
    height: 56,
    borderRadius: 28,
    backgroundColor: colors.grey5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  mbrdescIconStyle: {
    width: 29,
    height: 34,
    marginLeft: 12,
  },
  ibrbdescIconStyle: {
    width: 36,
    height: 20,
    marginLeft: 0,
  },
  trkdescIconStyle: {
    width: 26,
    height: 32,
    marginLeft: 8,
  },
  grntdescIconStyle: {
    width: 29,
    height: 24,
    marginLeft: 4,
  },
};

export default styles;
