import {Image, Text, View} from "react-native";
import React from "react";
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import styles from './PrimoBannerCss';
import {getActionButton, handleBusClickEvent} from "../../utils/BusBookingDetailUtil";
import {primo} from "../../BusImageConstants";
import {isEmpty} from 'lodash';

const PrimoBanner = ({header,subheader, response, card, showAllFeatures}) => {
    const handleClick = (action) => {
        handleBusClickEvent(card, action, response, "PrimoBanner");
    };

    return(
        <View style={[styles.whiteCard, AtomicCss.marginBottom12, styles.cardBorder]}>
           <View style={styles.cardContent}>
            <View style={[styles.headingStyle,AtomicCss.flexRow]}>
                <Image style={styles.cardIcon} source={primo} />
                <View style={styles.cardSubtext}>
                    {!isEmpty(header) && <Text style={[AtomicCss.defaultText, AtomicCss.blackFont, AtomicCss.font14, AtomicCss.textUppercase]}>{header}</Text>}
                    {!isEmpty(subheader) && <Text style={[AtomicCss.darkGreyText, AtomicCss.mediumFont, AtomicCss.font14, AtomicCss.paddingTop7]}>{subheader}</Text>}
                </View>
            </View>
            <View style={styles.processDtlsWrapper}>
                {
                    !isEmpty(response.busAdditionalInfo.reviewDetail.extraData.primoFeatures.features) &&
                    response.busAdditionalInfo.reviewDetail.extraData.primoFeatures.features.map((feature,index) => {
                        if(showAllFeatures || index < 3)
                            return (
                                <View style={styles.processRowDtls}>
                                    {
                                        feature.icon && !isEmpty(feature.icon.url) &&
                                        <View style={styles.imgHolder}>
                                            <Image style={styles.iconStyle} source={{uri:feature.icon.url}}/>
                                        </View>
                                    }
                                    <Text style={styles.processTitle}>{feature.heading}</Text>
                                    <Text style={styles.processDesc}>{feature.description}</Text>
                                </View>
                            )
                    })
                }
            </View>
           </View>
            {
                card && card.actionList &&
                <View style={[styles.actionBtnRec, styles.transperantBg]}>
                    {getActionButton(card, response, handleClick)}
                </View>
            }
        </View>
    )
}

export default PrimoBanner;
