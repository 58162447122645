import React, { useEffect } from 'react';
import { BackHandler, Platform } from 'react-native';
import { useNavigation } from '@react-navigation/native';

const useHardwareBackpress = () => {
  let handleBack = BackHandler;
  // const navigation = useNavigation();
  let onBackPress = null;

  const addListener = (backHandler, addBeforeRemove = false) => {
    useEffect(() => {
      onBackPress = () => {
        backHandler && backHandler();
        return true;
      };

      handleBack.addEventListener('hardwareBackPress', onBackPress);

      // addBeforeRemove && navigation.addListener('beforeRemove', (e) => {
      //   console.log('IOS Back');
      //   preventSwipeBack(e, onBackPress);
      // });

      return () => {
        handleBack.removeEventListener('hardwareBackPress', onBackPress);
        // addBeforeRemove && navigation.removeListener('beforeRemove');
      };
    }, [backHandler]);

    const preventSwipeBack = (e, onBackPress) => {
      e.preventDefault();
      // navigation.removeListener('beforeRemove');
      return onBackPress();
    };

    return null;
  };
  const removeListener = () => {
    handleBack.removeEventListener('hardwareBackPress', onBackPress);
    // navigation.removeListener('beforeRemove');
  };
  return { addListener, removeListener };
};

export default useHardwareBackpress;
