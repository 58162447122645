import React from 'react';
import {ScrollView} from 'react-native';
import RefundCard from './RefundCard'
import BasePage from '../../../../Common/PostSalesBasePage';


class RefundUseCase extends BasePage {
    constructor(props) {
        super(props, 'RefundEndTracker')
    }

    render() {

        return (
            <ScrollView>
                <RefundCard step="stepTwoComplete" TrakerRefundStatus="active" status="processed"
                            title={this.props.card.header}
                response={this.props.response}
                subHeader={this.props.card.subHeaders}
                actionList={this.props.card.actionList}/>
            </ScrollView>
        )
    }
}

export default RefundUseCase
