import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
import { StyleSheet } from 'react-native';

export default (theme, fonts) =>
  StyleSheet.create({
    overlayTopline: {
      width: 40,
      height: 4,
      borderRadius: 4,
      backgroundColor: '#c2c2c2',
      alignSelf: 'center',
    },
    title: {
      color: colors.black,
      ...fonts.font20,
      fontFamily: fonts.black,
      marginBottom: 30,
    },
    arrowStyle: {
      width: 8,
      height: 13,
      marginLeft: 10,
    },
    callImgStyle: {
      width: 32,
      height: 32,
      marginRight: 12,
    },
    linkTxt: {
      fontFamily: fonts.bold,
      ...fonts.font16,
      color: '#008cff',
      marginBottom: 4,
    },
    desc: {
      fontFamily: fonts.regular,
      ...fonts.font12,
      color: '#747474',
      paddingRight: 32,
      width: '75%',
    },
    scheduleLinkBtn: {
      borderTopColor: '#f2f2f2',
      paddingTop: 20,
      borderTopWidth: 1,
    },
    scheduleListItem: {
      borderRadius: 8,
      borderTopLeftRadius: 4,
      paddingHorizontal: 12,
      paddingVertical: 14,
      borderWidth: 1,
      borderColor: '#ddd',
      ...getPlatformElevation(1),
      backgroundColor: '#fff',
      marginRight: 12,
    },
    activeTab: {
      borderColor: theme.color.primary,
      backgroundColor: '#f8fcff',
    },
    activeTabTxt: {
      color: theme.color.primary,
      fontFamily: fonts.bold,
    },
    scheduleTxt: {
      color: '#000',
      fontFamily: fonts.regular,
      ...fonts.font14,
    },
    listWrapper: {
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    scheduleListTimeWrapper: {
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    scheduleListTimeItem: {
      borderRadius: 8,
      borderTopLeftRadius: 4,
      paddingHorizontal: 12,
      paddingVertical: 14,
      borderWidth: 1,
      borderColor: '#ddd',
      ...getPlatformElevation(1),
      backgroundColor: '#fff',
      marginRight: 12,
      marginBottom: 12,
    },
    disabledStyle: {
      opacity: 0.4,
    },
    rowWrapper: {
      borderBottomWidth: 1,
      borderBottomColor: '#ddd',
      marginBottom: 15,
    },
    lastItemStyle: {
      marginBottom: 0,
      borderBottomColor: 'transparent',
      borderBottomWidth: 1,
      paddingBottom: 0,
    },
  });
