import {
  getUserDetails,
  isUserLoggedIn,
} from '@mmt/legacy-commons/Native/UserSession/UserSessionModule';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import OmnitureModule from '@mmt/legacy-commons/Native/OmnitureModule';
import { isAndroid, isIos } from './device';
import { getDataFromStorage, setDataInStorage } from '@mmt/legacy-commons/AppState/LocalStorage';
import isEmpty from 'lodash/isEmpty';
import { RAILS_SESSION_ID, RAILS_SESSION_ID_EXPIRY_IN_MINUTES } from './RailsConstant';
import { PAGE } from '../types/grafanaTracking.types';
import { apiSets } from '../Utils/grafanaTrackedApiSet';

let deviceDetails = null;
let userDetails = null;
let sessionID = '';
let deviceID = '';

const createDeviceDetails = async () => {
  if (!deviceDetails) {
    if (isIos()) {
      const deviceInfo = await OmnitureModule.deviceInfo();
      if (!isEmpty(deviceInfo)) {
        deviceDetails = {
          os: 'ios',
          osVersion: deviceInfo.dvc_os_ver,
          deviceType: deviceInfo.dvc_type,
          appVersion: deviceInfo.dvc_app_ver,
          deviceId: deviceInfo.dvc_did,
          networkType: deviceInfo.dvc_conn_type,
          deviceName: `${deviceInfo.dvc_manuf} ${deviceInfo.dvc_mdl}`,
        };
      }
    } else if (isAndroid()) {
      const deviceInfo = await GenericModule.getDeviceInfo();
      if (!isEmpty(deviceInfo)) {
        const {
          androidVersion,
          phoneNumber,
          accountEmailId,
          ...rest
        } = deviceInfo;
        deviceDetails = {
          ...rest,
          os: 'android',
          osVersion: deviceInfo.androidVersion,
          deviceId: deviceInfo.pdtDeviceId,
          deviceType: 'Mobile',
        };
      }
    }
  }
  return deviceDetails;
};

export const getNativeUserDetails = async () => {
  if (!userDetails) {
    const loggedIn = await isUserLoggedIn();
    userDetails = loggedIn ? await getUserDetails() : null;
  }
  return userDetails;
};

export const getDeviceDetails = createDeviceDetails;

export const getQueryParamsFromDeepLink = (url: string) => {
  const urlSplits = decodeURIComponent(url).split('?');
  const pairs = urlSplits[2].split('&');
  interface Map {
    [key: string]: string;
  }
  const result: Map = {};

  pairs.forEach((pair) => {
    const keyValues = pair.split('=');
    result[keyValues[0]] = decodeURIComponent(keyValues[1] || '');
  });

  return result;
};

const randomString = () =>
  Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);

const generateRandomID = () => {
  const id = `${randomString() + randomString()}-${randomString()}-${randomString()}-${randomString()}-${randomString() + randomString() + randomString()}`;
  return id;
};

const generateNewSessionID = () => {
  const id = deviceID ?? generateRandomID();
  const sessionID = `${id}_${Platform.OS}_${Date.now()}_${randomString()}`;
  setDataInStorage(RAILS_SESSION_ID, sessionID);
  return sessionID;
};

const getCurrentSessionIDFromStorage = async () => {
  const id = await getDataFromStorage(RAILS_SESSION_ID);
  return id ?? '';
}

export const syncSessionID = async (headers) => {
  if (isEmpty(deviceID)) deviceID = headers?.['mmt-device-id'] ?? '';
  if (isEmpty(sessionID)) sessionID = await getCurrentSessionIDFromStorage();
}

export const resetSessionID = () => {
  sessionID = generateNewSessionID();
};

export const getSessionID = () => {
  try {
    const sessionIDExpired = isEmpty(sessionID)
      ? true
      : (Date.now() - Number(sessionID?.split('_')?.[2])) >= (RAILS_SESSION_ID_EXPIRY_IN_MINUTES * 60 * 1000);
    if (sessionIDExpired) sessionID = generateNewSessionID();
  } catch (err) {
    console.log('Error in getting session ID: ', err);
  } finally {
    return isEmpty(sessionID) ? generateNewSessionID() : sessionID;
  }
};

export const getPwaServerUrl = () => "https://rails.makemytrip.com/";

const findApiInSet = (url, apiSet, pageName) => {
  for (const currentApi of apiSet) {
    const { api, name } = currentApi;
    if (url.includes(api)) {
      return { pageName, entityName: name };
    }
  }
  return null;
};

export const getPageNameAndEntityNameOfApi = (url) => {
  for (const [pageName, apiSet] of Object.entries(apiSets)) {
    const result = findApiInSet(url, apiSet, PAGE[pageName]);
    if (result) {
      return result;
    }
  }

  return { pageName: '', entityName: '' };
};
