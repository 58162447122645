import React from 'react';
import PropTypes from 'prop-types';
import {Image, Text, View} from 'react-native';
import styles from '../styles/CabMamiCss';
import {getActionButton, getButtonStyle, isNotNullAndEmpty, handleClickEvent} from '../utils/CabBookingDetailUtil';
import {cancellationIcon} from "../CabImageConstants";

class CancelBooking extends React.Component {
  render() {
    return (
      <View style={[styles.MamiCard, styles.mar10Bt, styles.borderTop]}>
        {
          !this.props.fromCancellationPolicy &&
          <View style={styles.CardContent}>
            <Image
              style={styles.CardIcon}
              source={cancellationIcon}
            />
            <View style={styles.ContentPortion}>
              <Text style={[styles.blackFont, styles.BlackText]}>DO YOU WANT TO CANCEL BOOKING?</Text>
            </View>
          </View>
        }
        <View
          style={isNotNullAndEmpty(this.props.card.actionList) ? [getButtonStyle(this.props.card.actionList), styles.transperantBg] : styles.hideView}
        >
          {getActionButton(this.props.card, this.props.response, this.handleClick)}
        </View>
      </View>
    );
  }

  handleClick = (action) => {
   handleClickEvent(this.props.card, action, this.props.response, this.props.pageName);
  };
}

CancelBooking.propTypes = {
  response: PropTypes.object.isRequired,
  card: PropTypes.object.isRequired,
  pageName: PropTypes.string.isRequired
};

export default CancelBooking;
