import React, {Component} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';

import {fonts} from '@mmt/legacy-commons/Styles/globalStyles'

class WarningMsgCard extends Component{

 constructor(props){
  super(props);
 }

  render()
  {
  return (
    <View style={[styles.warningAddmsgCard, AtomicCss.flexRow, AtomicCss.justifyCenter]}>
      <Text style={styles.warningAddmsg}>{this.props.titleNormal} </Text><Text style={styles.warningAddmsgBold}>{this.props.titleBold}</Text>
    </View>
);
}
}

const styles = StyleSheet.create({
  warningAddmsgCard:{
    backgroundColor:"#ffedd1",
    borderBottomWidth:1,
    borderBottomColor:"#ffce80",
    paddingHorizontal:15,
    paddingVertical:10,
    borderRadius:5,
    overflow:'hidden'
  },
  warningAddmsg:{
    color:"#cf8100",
    fontFamily:fonts.regular
  },
  warningAddmsgBold:{
    color:"#cf8100",
    fontFamily:fonts.black
  }
});

export default WarningMsgCard;