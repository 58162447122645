import React from 'react';
import { isEmpty } from 'lodash';
import { View, ScrollView, StyleSheet, Text } from 'react-native';
import Button from '../../../../../Common/Button';
import { TripModificationProps } from '../../../../types';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import createStyles from './styles';
import CardWrapper from '../CardWrapper';

const TripModification: React.FC<TripModificationProps> = ({
  card,
  response,
  getModifiedBooking,
}) => {
  const { actionList } = card || {};
  if (isEmpty(actionList)) return null;
  const { actionLabeltext } = actionList[0];
  const { newBookingId } = response || {};
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);
  return (
    <React.Fragment>
        <Button
          btnWidth={'100%'}
          btnBg="gradientBtn"
          btnType="flat"
          fontSize={fsStyle.font12}
          btnTxt={actionLabeltext}
          clickHandler={() => {
            getModifiedBooking(newBookingId);
          }}
        />
    </React.Fragment>
  );
};

export default CardWrapper(TripModification);
