import React from 'react';
import { View, Text } from 'react-native';
import styles from './ReqstCallScheduleCss'
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import TimeSlot from './timeSlot';

const ScheduleList = ({ item, handleTimeList, index, timeActive, isLastItem }) => {
const lastItemStyle = isLastItem ? 'lastItemStyle' : '';
    return (
        <View style={[styles.rowWrapper,styles[lastItemStyle]]}>
            <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
                <Text style={[AtomicCss.boldFont,AtomicCss.defaultText,AtomicCss.font12]} >{item.headerText}</Text>
            </View>
            <View style={styles.scheduleListTimeWrapper}>
                {item.slotDetails.map((timeSlot, index) => {
                    return (
                        <TimeSlot
                            item={timeSlot}
                            index={index}
                            handleTimeList={handleTimeList}
                            timeActive={timeActive}
                        />
                    )
                })}
            </View>
        </View>
    );
}


export default ScheduleList;