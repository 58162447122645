import React from 'react';
import { View, Text, Image, TouchableOpacity, I18nManager } from 'react-native';
import PropTypes from 'prop-types';
import styles from './HeaderCss';

const imageTransformY = { transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }] };

const backImg = require('@mmt/legacy-assets/src/back.webp');

export default class Header extends React.Component {

  render() {
    const headerType = this.props.type ? `headerType${this.props.type}` : '';
    return (<View style={[styles.headerWrapper, styles[headerType]]}>
      <TouchableOpacity onPress={() => this.props.onBackPress()}>
        <Image style={[styles.backArrow, imageTransformY]} source={backImg} />
      </TouchableOpacity>
      <View>
        <Text style={styles.headerTitle}>{this.props.title}</Text>
        {this.props.type === 'Details' &&
        <Text style={styles.headerDescText}>{this.props.subTitle}</Text>
              }
      </View>
            </View>);
  }
}

Header.propTypes = {
  onBackPress: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};
