import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import {Text, TouchableOpacity, View} from 'react-native';
import getStyles from '../ActionBtnCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';

const CapsuleBtn = ({action, clickHandler, isThrottleRequired= false, throttleInterval = 1000}) => {
  const lastClickTimeRef = useRef(0);
  const styles = getStyles();
  const throttledClickHandler = useCallback(() => {
    const currentTime = Date.now();
    if (currentTime - lastClickTimeRef.current >= throttleInterval) {
      lastClickTimeRef.current = currentTime;
      clickHandler(action);
    }
  }, [clickHandler, throttleInterval]);

  const wrapperThrottle = () => {
    if (isThrottleRequired) {
      throttledClickHandler();
    } else {
      clickHandler(action);
    }
  };
  return (
  <TouchableOpacity style={AtomicCss.flex1} onPress={ wrapperThrottle }>
    <View
      style={[styles.CapsuleBtn, AtomicCss.marginBottom10]}
    >
      <Text
        style={[styles.BlueText, AtomicCss.alignSelfCenter]}
      >{action.actionLabeltext}
      </Text>
    </View>
  </TouchableOpacity>
  )
};

CapsuleBtn.propTypes = {
  action: PropTypes.object.isRequired,
  clickHandler: PropTypes.func.isRequired
};

export default CapsuleBtn;
