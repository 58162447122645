import React from 'react';
import PropTypes from 'prop-types';
import {View, Text, Image} from 'react-native';
import getStyles from '../styles/BusMamiCss';
import {getActionButton, getButtonStyle, handleBusClickEvent, isNotNullAndEmpty} from '../utils/BusBookingDetailUtil';
import {paymentIcon, offerIcon} from '../BusImageConstants';
import { getFont } from '../../PostSalesStyles';

class BusPriceCard extends React.Component {
    static navigationOptions = {
      header: null
    };

    render() {
      const paymentDetails = this.props.response.bookingPaymentDetail;
      const fonts = getFont(true);
      const styles = getStyles();
      return (
        <View style={[styles.MamiCard, styles.mar10Bt]}>
          <View style={styles.CardContent}>
            <View style={styles.flexRow}>
              <Image style={styles.CardIcon} source={paymentIcon} />
              <View>
                <Text style={[fonts.blackFontFamily, styles.BlackText, styles.mar8Bt, fonts.font14]}>
                  {this.props.headText}
                </Text>
                <View style={[styles.hideView]}>
                  <Text style={[styles.lightGreenTxt, styles.font12, styles.boldFont]}>You saved &#8377; {paymentDetails.sellingPrice - paymentDetails.paymentAmount} </Text>
                  <Image style={styles.busOffer} source={offerIcon} />
                </View>
              </View>
            </View>
            <View style={styles.marginLeftAuto}>
              <Text style={[fonts.font18, styles.BlackText, fonts.blackFontFamily]}>&#8377; {paymentDetails.paymentAmount} </Text>
              <Text style={[fonts.font12, styles.darkRed, styles.AlignRight, styles.lineThought, styles.hideView]}>&#8377; {paymentDetails.sellingPrice} </Text>
            </View>
          </View>
          <View
            style={isNotNullAndEmpty(this.props.card.actionList) ? [getButtonStyle(this.props.card.actionList), styles.transperantBg] : styles.hideView}
          >
            {getActionButton(this.props.card, this.props.response, this.handleClick)}
          </View>
        </View>
      );
    }
    handleClick = (action) => {
      handleBusClickEvent(this.props.card, action, this.props.response, this.props.pageName);
    };
    showDiscountLine(paymentDetails) {
      return true;
    }
}

BusPriceCard.propTypes = {
  response: PropTypes.object.isRequired,
  card: PropTypes.object.isRequired,
  pageName: PropTypes.string.isRequired
};
export default BusPriceCard;
