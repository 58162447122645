import { StyleSheet } from 'react-native';
import getPlatformElevation from '../getPlatformElevation';

const createStyles = (fonts, theme) => StyleSheet.create({
    pageWrapper: {
        flex: 1,
        backgroundColor: theme.color.grayBg,
    },
    visaProgressInfoCard: {
        paddingHorizontal: 16,
        paddingVertical: 12,
        ...getPlatformElevation(2),
        borderTopWidth: 1,
        borderTopColor: theme.color.grey24,
        marginBottom: 5,
    },
    subHeading:{
        color:theme.color.defaultTextColor,
        fontSize: fonts.fontSize.font12,
        ...fonts.regularFontFamily,
    },
    daysLeftInfo: {
        backgroundColor: theme.color.lightRed,
        paddingVertical: 4,
        paddingHorizontal: 6,
        borderRadius: 4,
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'flex-start',
    },
    clockIconStyle: {
        width: 12,
        height: 12,
        marginRight: 6,
    },
    progressInfoText: {
        color: theme.color.white,
        ...fonts.boldFontFamily,
        fontSize: fonts.fontSize.font12,
    },
    cardHeading: {
        color: theme.color.black2f,
        fontSize: fonts.fontSize.font14,
        ...fonts.blackFontFamily,
        marginBottom: 6,

    },
    btnWrapper: {
        alignSelf: 'flex-start',
    },
    bottomTag: {
        color:theme.color.black,
        fontSize: fonts.fontSize.font12,
        ...fonts.boldFontFamily,
    },
    expressVisaWrapper: {
        borderRadius: 5,
        paddingHorizontal: 8,
        paddingVertical: 4,
        backgroundColor: theme.color.grayBg,
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 15,
    },
    expressVisaIcon: {
        width: 30,
        height: 30,
        marginRight: 10,
    },
    buttonTextStyle :{
        color: theme.color.white,
    },
});

export default createStyles;