import CrossSellCard from './CrossSellCard';

import React, { Dispatch } from 'react';
import crossSellReducer, {
  CrossSellActionProps,
  CrossSellStateProps,
  initialState,
} from '../../reducers/crossSellReducer';

export const Store = React.createContext<CrossSellStateProps>(initialState);
Store.displayName = 'Store';

interface Props {
  data: {
    hotel_details: {
      latitude: number;
      longitude: number;
      name: string;
      locus_id: string;
    };
    booking_date: Date;
    checkout_date: Date;
    checkin_date: Date;
  };
}

const CabsCrossSellCard = (props: Props) => {
  const [globalState, dispatch] = React.useReducer(crossSellReducer, initialState);

  return (
    <Store.Provider value={[globalState, dispatch]}>
      <CrossSellCard {...props} />
    </Store.Provider>
  );
};

export default CabsCrossSellCard;
