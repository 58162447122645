import { Dimensions, StyleSheet } from 'react-native';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';

const styles = StyleSheet.create({
  OverlayContentWrapper: {
    backgroundColor: colors.white,
    paddingHorizontal: 12,
    position: 'relative',
    zIndex: 1,
    width: '100%',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  submitReqWrapper: {
    minHeight: 490,
  },
  overlayTopline: {
    width: 40,
    height: 4,
    borderRadius: 4,
    backgroundColor: colors.faintgrey,
    alignSelf: 'center',
  },
  title: {
    color: colors.black,
    marginBottom: 30,
    lineHeight: 32,
  },
  arrowStyle: {
    width: 8,
    height: 13,
    marginLeft: 10,
    marginTop: 5,
  },
  iconStyle: {
    width: 16,
    height: 20,
    marginRight: 12,
    marginTop: 3,
  },
  listWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 12,
    backgroundColor: colors.paleBlue,
    borderColor: colors.faintBlue,
    borderWidth: 1,
    borderRadius: 4,
    padding: 16,
  },
  lastItemStyle: {
    marginBottom: 0,
  },
  titleTxt: {
    color: colors.black,
    marginBottom: 4,
    lineHeight: 24,
  },
  desc: {
    color: colors.textGrey,
    lineHeight: 20,
  },
  scheduleLinkBtn: {
    borderTopColor: colors.grayBg,
    paddingTop: 20,
    borderTopWidth: 1,
  },
  backArrowWrapper: {
    width: 24,
    height: 24,
    marginLeft: -4,
    marginRight: 4,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  backArrowStyle: {
    width: 20,
    height: 20,
  },

  downloadImgStyle: {
    width: 17,
    height: 18,
  },
  downloadImgStyleIos: {
    marginTop: 12,
    width: 25,
    height: 25,
  },

  successTickStyle: {
    width: 15,
    height: 12,
  },
  failedIconStyle: {
    width: 15,
    height: 12,
  },
  failedTxt: {
    color: colors.red,
  },
  invoiceListWrapper: {
    marginBottom: 40,
    maxHeight: 300,
    marginHorizontal: -40,
  },
  activeStylesds: {
    backgroundColor: 'red',
  },
  toastWrapper: {
    zIndex: 1,
    marginBottom: -70,
    marginHorizontal: '5%',
  },
  toastDtls: {
    borderRadius: 32,
    width: 200,
    alignItems: 'center',
    elevation: 11,
    zIndex: 11,
    marginLeft: Dimensions.get('window').width / 2 - 107,
    backgroundColor: colors.textGrey,
    height: 35,
  },
  toastText: {
    color: colors.white,
    lineHeight: 20,
    padding: 4,
  },
  errorScreenWrapper: {
    height: 300,
    alignItems: 'center',
    justifyContent: 'center',
  },
  errorIcon: {
    width: 52,
    height: 47,
    marginBottom: 40,
  },
});
export default styles;
