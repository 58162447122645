import React from 'react';
import { StyleSheet } from 'react-native';
import Counter from '@Frontend_Ui_Lib_App/Counter/lib/Counter';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
const CounterWpr = ({
  counterValue,
  decrementHandler,
  incrementHandler,
  valueStyle,
  minusStyle,
  plusOuter,
  plusInner,
  counterWrapperStyle,
  editable,
  onChangeHandler,
}) => {
  const { fsStyle, ...fonts } = getFont(true);
  const {psTheme: theme} = useTheme();
  const styles = getStyles(fonts,theme);

  return (
    <Counter
      editable={editable}
      onChangeHandler={onChangeHandler}
      customContainerStyle={[styles.wrapperStyle, counterWrapperStyle]}
      counterValue={counterValue}
      decrementHandler={decrementHandler}
      incrementHandler={incrementHandler}
      textStyle={[styles.textStyleWrapper, valueStyle]}
      minusViewStyle={[styles.minusStyle, minusStyle]}
      plusInnerStyle={[styles.plusInnerStyle, plusInner]}
      plusOuterStyle={[styles.plusOuterStyle, plusOuter]}
    />
  );
};

const getStyles = (fonts,theme) => {
  return StyleSheet.create({
    textStyleWrapper: {
      ...fonts.font18,
      color: theme.color.black,
      width: 40,
      textAlign: 'center',
      fontFamily: fonts.bold,
    },
    minusStyle: {
      width: 12,
      height: 2,
      backgroundColor: theme.color.gray4,
    },
    plusInnerStyle: {
      backgroundColor: theme.color.cyan3,
    },
    plusOuterStyle: {
      backgroundColor: theme.color.cyan3,
    },
    wrapperStyle: {
      width: 140,
    },
  });
};
export default CounterWpr;
