import React, { useEffect } from 'react';
import CabsCrossSellFlights from '../../../../details/components/CabsAirportXSell';
import { RNView, RNCommonStyle, RNHeading } from 'core-rn-ui-factory';
import { FLIGHT_ACTION_IDS } from '@mmt/post-sales/src/flights/v2/commonConstants';

interface Props {
  data: {
    title: string;
    id: string;
    isCorporateBooking: boolean;
    bookingId: string;
  };
  actionCallback: (
    actionIdIgnore: string,
    dataIgnore: {
      trackingEventName: string;
      trackingExtraInfo: string;
    }
  ) => void;
}

const CABS_SHOW: string = 'Cabs_Shown';

const { CLICK_TRACKING } = FLIGHT_ACTION_IDS;
const CabsCard: React.FC<Props> = ({ data, actionCallback }: Props) => {
  useEffect(() => {
    const trackingData = {
      trackingEventName: CABS_SHOW,
      trackingExtraInfo: '',
    };
    actionCallback(CLICK_TRACKING, trackingData);
  }, []);
  const { bookingId, isCorporateBooking, id, title } = data || {};
  if (!bookingId) {
    return null;
  }
  return (
    <RNView style={RNCommonStyle.mt8} testID={id}>
      <RNHeading shade="highEmphasis" size="base" testID="cabsTitleText">
        {title}
      </RNHeading>
      <CabsCrossSellFlights
        flightBookingId={bookingId}
        isCorporateBooking={isCorporateBooking}
        source="Flight_My_Trips"
      />
    </RNView>
  );
};
export default CabsCard;
