import React from 'react';
import {PureComponent} from 'react';
import {View, TouchableOpacity, StyleSheet} from 'react-native';
import HeadingText from './HeadingText';
import TDRAnswer from './TDRAnswer';


class TDRCollapsed extends PureComponent {

  render() {
    return (
      <View style={this.props.isActive ? [styles.CollapsedContainer1]:[styles.CollapsedContainer1]}>
        <TouchableOpacity onPress={()=> this.props.tabbingFn(this.props.index, !this.props.isActive)} activeOpacity={0.9}>
          <HeadingText {...this.props} tabbing={this.props.isActive}/>
        </TouchableOpacity>
          {this.props.isActive &&
            <TDRAnswer {...this.props} />
          }
      </View>
    );
  }
}

const styles = StyleSheet.create({
  CollapsedContainer1: {
    backgroundColor: '#fff',
    borderBottomWidth: 1,
    borderBottomColor: '#ededed'
  }
});

export default TDRCollapsed;
