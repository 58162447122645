import AsyncStorage from '@react-native-async-storage/async-storage';
import { Platform, DeviceEventEmitter, Linking, Animated } from 'react-native';
import isEmpty from 'lodash/isEmpty';
import Actions from '../../../../navigation/postSalesNavigation';
import HolidayBookingModule from '@mmt/legacy-commons/Native/HolidayBookingModule';
import HolidayBookingTrackingHelper from '../../../../Holiday/HolidayBookingTrackingHelper';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import { IOS_AAROGYA_SETU_LINK } from '../../../../utils/NetworkUtils';
import { openDialer } from '../../../../Common/commonUtil';
import {
  downloadAwsDocument,
  FLIGHT_DOWNLOAD_TRIGGERS,
  isGccFlightBooking,
  isKsaFlightBooking,
} from '../../../FlightBookingUtil';
import { ANDROID_BACK_ARROW, IOS_BACK_ARROW, BACK_WHITE_ICON } from '../../../FlightImageConstants';
import { oneHour } from '@mmt/legacy-commons/Helpers/dateHelpers';
import FlightBookingModule from '@mmt/legacy-commons/Native/FlightBookingModule';
import CSATFeedbackHelper from '../../../../Common/CsatFeedbackCard/tracking';
import FlightBookingTrackingHelper from '../helper/FlightBookingTrackingHelper';
import FlightBookingDetailsConstant, {
  EventEmitterConst,
  VOID_DGCA_CONSTANTS,
  ViewState,
} from '../FlightBookingDetailsConstant';
import {
  CORPORATE_HELPLINE_NUMBER,
  DEEP_LINK_PAGE,
  FLIGHT_CANCELLATION_TYPE,
  HELPLINE_NO,
  LOBNAMES,
} from '../../../../PostSalesConstant';
import {
  isCorporateBooking,
  openErrorPage,
  externalLinkPressHandler,
  checkMyPartner,
  isScrollViewCloseToBottom,
  nativeLog,
} from '../../../../utils/PostSaleUtil';
import FlightEventsLogger, { FLIGHT_LOG_ACTIONS } from '../helper/FlightEventsLogger';
import { getStaticData } from '../../../../staticData/staticData';
import { getContactEmaiPhone } from './flightItinaryUtil';
import { getRootTag } from '@mmt/legacy-commons/AppState/RootTagHolder';
import get from 'lodash/get';
import { getDataFromStorage, setDataInStorage } from '../../../../utils/localstorage';
import myTripsNavigation from 'apps/post-sales/src/summary/navigation/navigation';
import { showShortToast } from 'packages/legacy-commons/Common/Components/Toast';

export const downloadAwsDoc = (downloadData, bookingId) => {
  const { triggerName, awsKey } = downloadData;
  switch (triggerName) {
    case FLIGHT_DOWNLOAD_TRIGGERS.INSURANCE:
    case FLIGHT_DOWNLOAD_TRIGGERS.BOARDING_PASS:
      downloadAwsDocument(awsKey, bookingId, triggerName);
      break;
  }
};

export const getPriceBreakUpDatalist = (flightDetailResponse) => {
  if (isEmpty(flightDetailResponse)) {
    return null;
  }
  const { fareBreakupNodeList, currency, paymentDetails, fareBreakUpDetail } = flightDetailResponse;
  let baseCharges = 0,
    totalAmt = 0,
    feeSurcharges = 0;
  const discountAmount = 0;

  if (paymentDetails?.paymentAmount) {
    totalAmt = paymentDetails.paymentAmount;
  }

  if (fareBreakUpDetail?.feesSurcharges) {
    feeSurcharges = fareBreakUpDetail.feesSurcharges;
  }
  if (fareBreakupNodeList) {
    fareBreakupNodeList.map((item, index) => {
      if (item.id === FlightBookingDetailsConstant.BASE_FARE) {
        baseCharges = item?.amt;
      }
    });
  }
  return { baseCharges, totalAmt, feeSurcharges, discountAmount, currency };
};

export const openThankYouPageAfterPayment = (data, bookingIdNo) => {
  let paymentfalse = true;
  if (data) {

    nativeLog(bookingIdNo);
    if (data.PAYMENT_RESPONSE_VO) {
      FlightEventsLogger.logAction(FLIGHT_LOG_ACTIONS.DATE_CHANGE_PAYMENT_PAGE, { "PAYMENT_RESPONSE_VO": data.PAYMENT_RESPONSE_VO });
      const paymentResponse = JSON.parse(data.PAYMENT_RESPONSE_VO);
      const emailId = data.emailID;
      const bookingId = data.BOOKING_ID;
      if (paymentResponse) {
        paymentfalse = false;
        const respObj = JSON.parse(paymentResponse.response);
        Actions.dateChangeSucess({
          paymentSuccess: respObj.success,
          commitMsg: respObj.commitMessage,
          commitTitle: respObj.commitTitle,
          emailId,
          bookingId,
        });
      }
    }
  }
  if (paymentfalse) {
    Actions.dateChangeSucess({
      paymentSuccess: false,
      bookingId: bookingIdNo,
    });
  }
};

export const openInsuranceThanku = (data, uniqueCrId, responseJson, bookingId) => {
  Actions.insuranceThankyou({
    commitRes: data,
    uniqueCrId: uniqueCrId,
    responseJson: responseJson,
    bookingId: bookingId,
  });
};

export const getFlightLayoutValue = (no, flightFinalLayout) => {
  let getLayoutValue = 0;
  switch (no) {
    case DEEP_LINK_PAGE.FLIGHT_ADD_SEAT:
    case DEEP_LINK_PAGE.FLIGHT_ADD_MEAL:
      getLayoutValue = flightFinalLayout;
      break;
    case DEEP_LINK_PAGE.FLIGHT_TICKET_BOOKED:
      getLayoutValue = 0;
      break;
    case DEEP_LINK_PAGE.FLIGHT_WEB_CHECK_IN:
      getLayoutValue = 0;
      break;
    default:
      getLayoutValue = 0;
      break;
  }
  return getLayoutValue;
};

export const handleMomentumToScrollEndUtil = ({ nativeEvent, responseJson, bookingId }) => {
  if (nativeEvent && isScrollViewCloseToBottom(nativeEvent)) {
    if (responseJson && responseJson.layoutDetail && responseJson.layoutDetail.cardList) {
      const csatCard = responseJson.layoutDetail.cardList.find(
        (card) => card.cardId === FlightBookingDetailsConstant.CSAT_FEEDBACK_CARD,
      );
      if (csatCard) {
        CSATFeedbackHelper.trackSeenEvent(
          FlightBookingTrackingHelper.getPageName('Details', responseJson),
          bookingId,
        );
      }
    }
  }
};

export const onRTPCRBtnClick = ({ openInWebView, url, webViewHeaderText }) => {
  if (openInWebView) {
    Actions.openPsWebView({
      headerText: webViewHeaderText ? webViewHeaderText : '',
      headerIcon: BACK_WHITE_ICON,
      url,
    });
  } else {
    Linking.openURL(url);
  }
};

export const onMMTBlackCardClickEvent = (omnitureClickEvent, responseJson) => {
  FlightBookingTrackingHelper.trackClickEvent('Details', omnitureClickEvent, responseJson);
};

export const onRefundBreakupClickHandler = (requestNo, version, responseJson, lobName) => {
  if (version && version === 2) {
    Actions.fltRefundBreakupV2({
      bookingId: responseJson.bookingID,
      lobCode: responseJson.lobCode,
      apiPSLob: LOBNAMES.FLIGHT,
    });
  } else {
    Actions.fltRefundBreakup({
      bookingId: responseJson.bookingID,
      requestNo,
      lobCode: responseJson.lobCode,
      lobName: lobName,
      apiPSLob: LOBNAMES.FLIGHT,
    });
  }
  DeviceEventEmitter.emit(EventEmitterConst.CTA_CLICKED_EVENT);
  FlightBookingTrackingHelper.trackClickEvent('Details', 'Flight_refund_Details', responseJson);
  FlightBookingTrackingHelper.trackLoadEvent('Details_refunddetail', responseJson);
};

export const refundEventTracker = (actionType, responseJson) => {
  switch (actionType) {
    case 'ViewRefundDelayed':
      FlightBookingTrackingHelper.trackClickEvent('Details', 'Flight_refund_Delayed', responseJson);
      FlightBookingTrackingHelper.trackLoadEvent('Details_refundDelayed', responseJson);
      break;
    case 'ViewRefundBreakup':
      FlightBookingTrackingHelper.trackClickEvent('Details', 'Flight_refund_Details', responseJson);
      FlightBookingTrackingHelper.trackLoadEvent('Details_refunddetail', responseJson);
      break;
    case 'BANK_DETAIL':
      FlightBookingTrackingHelper.trackClickEvent(
        'Details',
        'flight_enterbankdetail',
        responseJson,
      );
      break;
    case 'TRACK_REFUND_REQUEST':
      FlightBookingTrackingHelper.trackClickEvent(
        'Details',
        'flight_trackrefunddetails',
        responseJson,
      );
      break;
    case 'connect_helpdesk':
      FlightBookingTrackingHelper.trackClickEvent('Details', 'Flight_help_refund', responseJson);
      break;
    case 'CONNECT_HELPDESK_REFUND_BREAKUP':
      FlightBookingTrackingHelper.trackClickEvent(
        'Details_refunddetail',
        'Flight_help_refundbreakup',
        responseJson,
      );
      break;
    case 'REFUND_TRACKER_DEEPLINK_CLICK':
      FlightBookingTrackingHelper.trackClickEvent(
        'Refund_Tracker',
        'flight_refund_tracker_deeplink',
        responseJson,
      );
      break;
    default:
      break;
  }
};

export const downloadFltTicket = (item, bookingId) => {
  const vouchers = [];
  vouchers.push({
    triggerName: item,
  });
  if (Platform.OS === 'web') {
    HolidayBookingModule.downloadTickets(JSON.stringify(vouchers), bookingId);
  } else {
    HolidayBookingModule.downloadTickets(JSON.stringify(vouchers));
  }
};

export const externalLinkHandler = (action, title, iosURL) => {
  let externalUrl = action.url;
  if (Platform.OS === 'ios' && iosURL) {
    externalUrl = iosURL;
  }
  if (externalUrl) {
    if (action.openInWebView) {
      Actions.openPsWebView({
        headerText: action.webViewTitle || title,
        headerIcon: BACK_WHITE_ICON,
        url: externalUrl,
      });
    } else {
      externalLinkPressHandler(externalUrl);
    }
  }
};

export const checkListClickHandler = (
  action,
  holidayBookingApi,
  holidayPageName,
  responseJson,
  holidayData,
) => {
  const staticData = getStaticData();
  switch (action.type) {
    case 'READ_GUIDELINES':
      holidayBookingApi
        ? HolidayBookingTrackingHelper.trackClickEvent(holidayPageName, 'CheckList_Read_Guidelines')
        : FlightBookingTrackingHelper.trackClickEvent(
          'Details_Checklist',
          `Flight_List_${action.type}`,
        );
      externalLinkHandler(action, staticData.guidelinesText);
      break;
    case 'AAROGYA_SETU':
      holidayBookingApi
        ? HolidayBookingTrackingHelper.trackClickEvent(
          holidayPageName,
          'CheckList_Download_AarogyaSetu',
        )
        : FlightBookingTrackingHelper.trackClickEvent(
          'Details_Checklist',
          'Flight_List_Aarogya',
          responseJson,
        );
      const iosURL = action.iosURL || IOS_AAROGYA_SETU_LINK;
      externalLinkHandler(action, staticData.aarogyaSetuText, iosURL);
      break;
    case 'WEB_CHECKIN':
      if (action.url) {
        holidayBookingApi
          ? HolidayBookingTrackingHelper.trackClickEvent(holidayPageName, 'Checklist_Web_Checkin')
          : FlightBookingTrackingHelper.trackClickEvent(
            'Details_Checklist',
            'Flight_List_Webcheckin_Outside',
            responseJson,
          );
        DeviceEventEmitter.emit(EventEmitterConst.CTA_CLICKED_EVENT);
        externalLinkHandler(action, staticData.flightWebCheckInText);
      } else {
        holidayBookingApi
          ? HolidayBookingTrackingHelper.trackClickEvent(holidayPageName, 'Checklist_Web_Checkin')
          : FlightBookingTrackingHelper.trackClickEvent(
            'Details_Checklist',
            'Flight_List_Webcheckin_Inside',
            responseJson,
          );
        Actions.openAncillary({
          pageKey: 'webcheckin',
          bookingId: responseJson.bookingID,
          flightDetailResponse: responseJson,
          holidayBooking: holidayBookingApi,
          holidayData: holidayData,
          isCorporateBooking: responseJson.isCorporateBooking,
          flightDetailResponse: this.props.flightDetailResponse,
        });
      }
      break;
    case 'BAGGAGE_TAG':
      holidayBookingApi
        ? HolidayBookingTrackingHelper.trackClickEvent(
          holidayPageName,
          'Checklist_Download_Baggage_Tag',
        )
        : FlightBookingTrackingHelper.trackClickEvent(
          'Details_Checklist',
          'Flight_List_Baggage',
          responseJson,
        );
      if (holidayBookingApi) {
        if (Platform.OS === 'ios') {
          responseJson.reactTag = getRootTag();
        } else {
          downloadFltTicket('Flight_BaggageTag', responseJson.bookingID);
        }
      } else {
        if (Platform.OS === 'ios') {
          responseJson.reactTag = getRootTag();
          FlightBookingModule.downloadTicket(JSON.stringify(responseJson), 'Flight_BaggageTag');
        } else if (Platform.OS === 'web') {
          FlightBookingModule.downloadTicket('Flight_BaggageTag', responseJson.bookingID);
        } else {
          FlightBookingModule.downloadTicket('Flight_BaggageTag');
        }
      }
      break;
    case 'BOARDING_PASS':
      if (action.url) {
        let downloadData;
        const bookingId = responseJson.bookingID;
        if (Platform.OS === 'ios') {
          const rootTag = getRootTag();
          FlightBookingModule.downloadInsurancePolicy(bookingId, action.url, rootTag);
        } else if (Platform.OS === 'web') {
          FlightBookingModule.downloadInsurancePolicy(action.url, bookingId, 'BOARDING_PASS');
        } else {
          downloadData = {
            triggerName: FLIGHT_DOWNLOAD_TRIGGERS.BOARDING_PASS,
            awsKey: action.url,
          };
          FlightBookingModule.checkStoragePermission();
        }
        FlightBookingTrackingHelper.trackClickEvent(
          'Details_Checklist',
          'Flight_List_WC_Download',
          responseJson,
        );
        return downloadData;
      }
      break;
    default:
      holidayBookingApi
        ? HolidayBookingTrackingHelper.trackClickEvent(holidayPageName, `Checklist_${action.type}`)
        : FlightBookingTrackingHelper.trackClickEvent(
          'Details_Checklist',
          `Flight_List_${action.type}`,
          responseJson,
        );
      externalLinkHandler(action, '');
      break;
  }
};

export const nameChangePopupClickHandler = (
  reasonSelected = null,
  nameChangePopupClose,
  responseJson,
  bookingId,
) => {
  if (!isEmpty(responseJson.nameCorrectionDetails?.passengerList)) {
    Actions.nameChangeSelectPaxNew({
      nameCorrectionDetails: responseJson.nameCorrectionDetails,
      bookingId: bookingId,
      reasonSelected,
      response: responseJson,
    });
    nameChangePopupClose();
    FlightBookingTrackingHelper.trackClickEvent(
      'Special request: Name Change Pop up',
      'Special request: Name Change Pop up proceed',
      responseJson,
    );
  } else {
    nameChangePopupClose();
    showShortToast('Name change not allowed for this booking');
    return null;
  }
};

export const cancellationInfoJson = (responseJson, showCancellationInfoOverlay) => {
  const { cancellationVoid, cancellationDgca, nameChangeDgca, dateChangeDgca, dateChangeVoid } = responseJson || {};
  const { data: cancellationOverlayData } = showCancellationInfoOverlay || {};
  if (
    cancellationOverlayData &&
    cancellationOverlayData.popupInfo &&
    cancellationOverlayData.popupInfo === 'dgcaNameChangeInfo'
  ) {
    return nameChangeDgca;
  } else if (cancellationOverlayData?.popupInfo === VOID_DGCA_CONSTANTS.DGCA_VOID_DATE_CHANGE_INFO) {
    return dateChangeVoid || dateChangeDgca;
  }
  else {
    return cancellationVoid || cancellationDgca;
  }
};

export const specialRequestCardClickHandler = (
  actionId,
  shouldShowNameChangeOverlay,
  responseJson,
  bookingId,
  openCancellationInfo,
) => {
  if (actionId === 'NAME_CHANGE') {
    DeviceEventEmitter.emit(EventEmitterConst.CTA_CLICKED_EVENT);
    const actionText = get(responseJson, 'nameChangeDgca.actionLists[0].actionText');
    if (responseJson.dgcaBooking && responseJson.nameChangeDgca && actionText) {
      openCancellationInfo({
        actionItem: {
          actionCTA: 'ACT_CANCEL_FLT_PARTIAL',
          actionId: 'CANCEL_FLT_PARTIAL',
          actionText,
        },
        popupInfo: 'dgcaNameChangeInfo',
        page: 'Details',
      });
    } else if (responseJson.cancellationVoid) {
      openCancellationInfo({
        actionItem: {
          actionId: 'CANCEL_FLT_FULL',
        },
        popupInfo: 'voidNameChangeInfo',
        page: 'Details',
      });
    } else if (
      !responseJson.nameCorrectionDetails.newNameCorrectionFlow &&
      responseJson.nameCorrectionDetails.isCloseToDeparture
    ) {
      Actions.nameChangeCloseToDeparture({
        closeToDepartureInfo: responseJson.nameCorrectionDetails.closeToDepartureInfo,
        flightDetailHeader: responseJson.nameCorrectionDetails.flightDetailHeader,
        response: responseJson,
        bookingId: bookingId,
      });
    } else {
      shouldShowNameChangeOverlay();
    }
    FlightBookingTrackingHelper.trackClickEvent('Details', 'Name correction', responseJson);
  }
};

export const baggageProtectCardClickHandler = (
  baggaeProtectionDetails,
  bookingId,
  responseJson,
) => {
  Actions.baggageProtectionPolicy({
    baggaeProtectionDetails,
    reportBaggageLossBtnClick: (url) => {
      onReportBaggageLossClicked(url, responseJson);
    },
    bookingId,
    responseJson,
  });
  FlightBookingTrackingHelper.trackClickEvent('Details', 'BaggageProtectDetail', responseJson);
};

const onReportBaggageLossClicked = (url, responseJson) => {
  Linking.openURL(url);
  FlightBookingTrackingHelper.trackClickEvent('Details', 'ReportBaggageLoss', responseJson);
};

export const openCustomerSupportODC = (responseJson) => {
  const { modificationNotSupportedText } = getStaticData();
  Actions.openPsWebView({
    headerText: modificationNotSupportedText,
    url: `https://supportz.makemytrip.com/Mima/CustomerSupport/?bookingId=${responseJson.bookingID}&lobType=Flight`,
    headerIcon: BACK_WHITE_ICON,
  });
  FlightBookingTrackingHelper.trackClickEvent('Details', 'DateChangeNotSupported', responseJson);
};

export const openDigitTermsNCondPage = (ctaData, cardType, bookingId, responseJson) => {
  let downloadData = {};
  if (ctaData.type === 'S3_Doc') {
    if (Platform.OS === 'ios') {
      const rootTag = getRootTag();
      FlightBookingModule.downloadInsurancePolicy(bookingId, ctaData.redirectionUrl, rootTag);
    } else if (Platform.OS === 'web') {
      FlightBookingModule.downloadInsurancePolicy(
        ctaData.redirectionUrl,
        bookingId,
        'InsuranceVoucher',
      );
    } else {
      downloadData = {
        triggerName: FLIGHT_DOWNLOAD_TRIGGERS.INSURANCE,
        awsKey: ctaData.redirectionUrl,
      };
      FlightBookingModule.checkStoragePermission();
    }
    FlightBookingTrackingHelper.trackClickEvent('Details', 'TripProtectPolicy', responseJson);
  }
  else if (ctaData.type === 'TM_INSURANCE_ROUTE') {
    const tmOrderId = ctaData.tmOrderId;
    const normalizeBookingId = tmOrderId
    ? bookingId + `?tmBookingId=${tmOrderId}`
    : tmOrderId;
    myTripsNavigation.openInsuranceBookingDetail({ bookingID:normalizeBookingId }, {}, false);
  }
   else {
    if (Platform.OS === 'web') {
      Linking.openURL(ctaData.redirectionUrl);
    } else {
      Actions.openPsWebView({
        headerText:
          cardType === 'INSURANCE'
            ? 'Trip Protection Terms & Conditions'
            : 'Fly Assure Terms & Conditions',
        url: ctaData.redirectionUrl,
        headerIcon: BACK_WHITE_ICON,
      });
    }
    FlightBookingTrackingHelper.trackClickEventNew(
      'Details',
      'Insurance_Claim_Clicked',
      responseJson,
    );
  }
  return downloadData;
};

export const tripProtectCardBtnClick = (insuranceDetails, cardType, responseJson) => {
  const {
    tripProtectCardText: {
      items: {
        insuranceBtnClickText: { tripProtectionText, flyAssureText },
      },
    },
  } = getStaticData();
  let title = tripProtectionText;
  let clickEvent = 'TripProtectDetail';
  if (cardType === 'ASSURANCE') {
    title = flyAssureText;
    clickEvent = 'FlyAssuranceDetail';
  }

  Actions.tripProtectionPolicy({
    insuranceDetails,
    title,
    bookingId: responseJson ? responseJson.bookingID : '',
  });
  FlightBookingTrackingHelper.trackClickEvent('Details', clickEvent, responseJson);
};

export const getErrorBtnObj = () => {
  const staticData = getStaticData();
  const btnObj = {};
  btnObj.text = staticData.tryAgainText;
  btnObj.action = FlightBookingDetailsConstant.ACTION_RELOAD;
  return btnObj;
};

export const getErrorPageSubText = (responseJson) => {
  const staticData = getStaticData();
  if (responseJson) {
    if (isGccFlightBooking(responseJson.bookingID)) {
      return staticData.flightErrorText.repeatedIssueGccFlightBookingError;
    } else if (isKsaFlightBooking(responseJson.bookingID)) {
      return staticData.flightErrorText.repeatedIssueKSAFlightBookingError;
    } else if (responseJson && isCorporateBooking(responseJson.lobCode)) {
      return staticData.flightErrorText.repeatedIssueCorporateError;
    } else if ((responseJson && responseJson.myPartnerBooking) || checkMyPartner()) {
      return staticData.flightErrorText.repeatedMyPartnerIssueError;
    } else {
      return staticData.flightErrorText.repeatedIssueError;
    }
  } else {
    return staticData.flightErrorText.repeatedIssueError;
  }
};

export const trackErrorEvent = (error, holidayBookingApi, bookingId, collapseIndex) => {
  if (holidayBookingApi) {
    FlightBookingTrackingHelper.trackErrorWithData(
      `mob:customer support:Holidays Domestic:Flight_Detail_${collapseIndex}_error`,
      bookingId,
      error,
      undefined,
    );
  } else {
    FlightBookingTrackingHelper.trackErrorWithData(
      'mob:customer support: flight:TripDetailsError',
      bookingId,
      error,
      undefined,
    );
  }
};

export const getAcmeCity = (response) => {
  const { flightDetails, bookingState, isGccBooking, isKsaBooking } = response;
  const { journeyType, bookingIntent, segmentGroupDetailList } = flightDetails || {};
  const isMulticityFlight = journeyType === 1;
  let acmeCity;
  if (
    !isMulticityFlight &&
    bookingState &&
    bookingState.state.toLowerCase() === 'upcoming' &&
    !isGccBooking &&
    !isKsaBooking
  ) {
    // This means we can show acme products
    // if its a round trip then show acme cards till arrival time
    if (journeyType === 2 && segmentGroupDetailList && segmentGroupDetailList.length > 1) {
      const segmentDetail =
        segmentGroupDetailList[0].segmentDetails &&
          segmentGroupDetailList[0].segmentDetails.length > 0
          ? segmentGroupDetailList[0].segmentDetails[0]
          : null;
      if (
        segmentDetail &&
        new Date().getTime() < new Date(segmentDetail.arrivalDateTime) &&
        segmentDetail.destinationCityCode
      ) {
        acmeCity = segmentDetail.destinationCityCode;
      }
    } else if (bookingIntent) {
      const airportCodes = bookingIntent.split('-');
      acmeCity = airportCodes[airportCodes.length - 1];
    }
  }
  return acmeCity;
};

export const onCancellationButtonClicked = (
  cancellationType,
  bookingId,
  responseJson,
  selectedSegmentGroupView = {},
) => {
  const data = {};
  data.bookingId = bookingId;
  data.flightCancellationType = cancellationType;
  data.segmentsGroupView = selectedSegmentGroupView;
  Actions.flightCancellationPage({
    data: JSON.stringify(data),
    bookingId: bookingId,
    pageData: JSON.stringify(responseJson),
    isCorporateBooking: responseJson.isCorporateBooking,
  });
};

export const getActiveSegmentForAncillary = (responseJson, type) => {
  const ancillarySegmentList = responseJson?.applicableAncillary?.segmentApplicableAncillary;
  for (let i = 0; i < ancillarySegmentList?.length; i += 1) {
    const { seatSelectionApplicable, mealApplicable, baggageApplicable } = ancillarySegmentList[i];
    switch (type) {
      case DEEP_LINK_PAGE.FLIGHT_ADD_BAGGAGE:
        if (baggageApplicable) {
          return ancillarySegmentList[i].segmentLineNo;
        } else {
          break;
        }
      case DEEP_LINK_PAGE.FLIGHT_ADD_MEAL:
        if (mealApplicable) {
          return ancillarySegmentList[i].segmentLineNo;
        } else {
          break;
        }
      case DEEP_LINK_PAGE.FLIGHT_ADD_SEAT:
        if (seatSelectionApplicable) {
          return ancillarySegmentList[i].segmentLineNo;
        } else {
          break;
        }
      default:
        if (seatSelectionApplicable || mealApplicable || baggageApplicable) {
          return ancillarySegmentList[i].segmentLineNo;
        } else {
          break;
        }
    }
  }
  return responseJson.newFlightDetails.segmentGroupDetailList[0].segmentDetails[0].segmentLineNo;
};

export const displayWebCheckInOverlay = async (response) => {
  const { bookingID, webcheckInOverlayBanner } = response;
  if (!webcheckInOverlayBanner) {
    return false;
  }
  const { cutOffTimeInHours } = webcheckInOverlayBanner;
  const wcOverlayData = await getDataFromStorage('WC_OVERLAY_VISIBILITY');
  const savedData = !isEmpty(wcOverlayData) ? JSON.parse(wcOverlayData) : null;
  if (!isEmpty(savedData) && savedData[bookingID]) {
    const currentTime = new Date().getTime();
    if (currentTime - savedData[bookingID] > oneHour * (cutOffTimeInHours || 24)) {
      savedData[bookingID] = currentTime;
      await setDataInStorage('WC_OVERLAY_VISIBILITY', JSON.stringify(savedData));
      return true;
    }
    return false;
  }
  await setDataInStorage(
    'WC_OVERLAY_VISIBILITY',
    JSON.stringify({
      [bookingID]: new Date().getTime(),
    }),
  );
  return true;
};

export const getSelectedSegmentGroupView = (actionItem) => {
  let selectedSegmentGroupView;
  if (actionItem.actionCTA) {
    const splitArr = actionItem.actionCTA.split('_');
    if (splitArr.length > 0) {
      selectedSegmentGroupView = splitArr[splitArr.length - 1];
    }
  }
  return selectedSegmentGroupView;
};

export const cancellationPolicyBtnClick = (action, responseJson) => {
  switch (action) {
    case 'CANCEL_FLT_FULL':
      onCancellationButtonClicked(
        FLIGHT_CANCELLATION_TYPE.FULL_CANCELLATION,
        responseJson.bookingID,
        responseJson,
      );
      FlightBookingTrackingHelper.trackClickEvent(
        'Details_CancelPolicy',
        'Calculate_Refund',
        responseJson,
      );
      break;
    case 'ACT_RES_FLIGHT':
      Actions.flightRescheduleFlightsPage({
        flightDateChangeData: JSON.stringify(responseJson),
      });
      FlightBookingTrackingHelper.trackClickEvent(
        'Details_DateChangePolicy',
        'Continue',
        responseJson,
      );
      break;
    default:
      break;
  }
};

export const openPolicyPage = (pageName, penaltyData, showTopMsgCard, actionBtn, responseJson) => {
  if (penaltyData.segmentGroupPenaltyList && penaltyData.segmentGroupPenaltyList.length > 0) {
    Actions.flightCancellationPolicy({
      penaltyData,
      pageName,
      dbMsg: responseJson.dbMessage,
      zcMsg: responseJson.zcGlobalMsg,
      isTopMsgCardHtml: responseJson.isFreeDateChangeAssured,
      showTopMsgCard,
      actionBtn,
      onBtnClick: (action) => cancellationPolicyBtnClick(action, responseJson),
      response: responseJson,
    });
  } else {
    let msg =
      'Sorry, there is some issue in fetching penalties for this booking. Please connect with us.';
    if (penaltyData.descObj && penaltyData.descObj.descObjText) {
      msg = penaltyData.descObj.descObjText;
    }
    let contactNo = HELPLINE_NO;
    if (isCorporateBooking(responseJson.lobCode)) {
      contactNo = CORPORATE_HELPLINE_NUMBER;
    }
    Actions.flightBookingPolicyError({
      msg,
      contactNo,
    });
  }
};

export const getPaxFareSegmentList = (responseJson, specialClaimData) => {
  const paxFareSegmentList = [];
  responseJson.flightDetails.segmentGroupDetailList.forEach((segmentGroupDetail) => {
    segmentGroupDetail.segmentDetails.forEach((segmentDetail) => {
      segmentDetail.segmentPassengerDetail.forEach((segmentPassengerDetail) => {
        if (!segmentPassengerDetail.isPaxCancelled) {
          const paxFareSegment = {};
          paxFareSegment.reason = specialClaimData.scCode;
          paxFareSegment.paxFareSegmentId = segmentPassengerDetail.paxFareSegmentId;
          paxFareSegmentList.push(paxFareSegment);
        }
      });
    });
  });
  return paxFareSegmentList;
};

export const onAirlineContactClicked = (contacts) => {
  if (contacts.length === 1 && contacts[0].airLineContactNo.split(',').length === 1) {
    openDialer(contacts[0].airLineContactNo.split(',')[0]);
  } else {
    Actions.airlineContactInfo({ airlineNameContacts: contacts });
  }
};

export const supportCardClickHandler = (actionItem, responseJson) => {
  switch (actionItem.actionFamily) {
    case FlightBookingDetailsConstant.CALL_AIRLINE_ACTION_BTN_FAMILY:
      onAirlineContactClicked(responseJson.airLineNameContacts);
      FlightBookingTrackingHelper.trackClickEvent('Details', 'Flight_CallAirline', responseJson);
      break;
    case FlightBookingDetailsConstant.CHAT_WITH_US_ACTION_BTN_FAMILY:
      GenericModule.openMyraPage();
      FlightBookingTrackingHelper.trackClickEvent('Details', 'Flight_Myra', responseJson);
      break;
    case FlightBookingDetailsConstant.ACTION_CORPORATE_CALL:
      openDialer(CORPORATE_HELPLINE_NUMBER);
      FlightBookingTrackingHelper.trackClickEvent(
        'Details',
        'Flight_Corporate_Contact_Us',
        responseJson,
      );
      break;
    case FlightBookingDetailsConstant.ACTION_CALL_MMT_SUPPORT_GCC:
      openDialer(responseJson.gccContactNo);
      FlightBookingTrackingHelper.trackClickEvent('Details', 'Flight_GCC_Contact_Us', responseJson);
      break;
    default:
      break;
  }
};

export const openDateChange = (pageName, responseJson, lobName) => {
  if (responseJson.dateChangeSupported) {
    Actions.flightRescheduleFlightsPage({
      flightDateChangeData: JSON.stringify(responseJson),
    });
    if (pageName) {
      FlightBookingTrackingHelper.trackClickEvent(pageName, 'Flight_DateChange', responseJson);
    }
  } else if (responseJson.dateChangeErrorData) {
    openErrorPage(
      responseJson.dateChangeErrorData,
      undefined,
      undefined,
      responseJson.bookingID,
      'datechange',
      Actions,
      { psLob: lobName },
    );
    if (pageName) {
      FlightBookingTrackingHelper.trackClickEvent(pageName, 'DateChange_NA', responseJson);
      const trackingErrorPageName = responseJson.isInternational
        ? 'Mob:customer support:Intl flight :datechangeNot Supported :error'
        : 'Mob:customer support:Dom flight :datechangeNot Supported :error';
      FlightBookingTrackingHelper.trackErrorForDateChangeNotSupported(
        responseJson,
        trackingErrorPageName,
        responseJson.dateChangeErrorData.errorCode,
        responseJson.dateChangeErrorData.meta
          ? responseJson.dateChangeErrorData.meta.supplier
          : undefined,
      );
    }
  } else {
    openCustomerSupportODC(responseJson);
    if (pageName) {
      FlightBookingTrackingHelper.trackClickEvent(pageName, 'DateChange_NA', responseJson);
    }
  }
};

export const openSpecialClaimSelectPaxPage = (responseJson, specialClaimData) => {
  Actions.specialClaimSelectPassenger({
    specialClaimData: JSON.stringify(specialClaimData),
    partialPax: JSON.stringify(getPaxFareSegmentList(responseJson, specialClaimData)),
    bookingID: responseJson.bookingID,
    bookingState: responseJson.bookingState.state,
    lobCode: responseJson.lobCode,
    mobileNumber: getContactEmaiPhone(responseJson).contactNumber,
    isCorporateBooking: responseJson.isCorporateBooking,
  });
};

export const continueClickHandler = (specialClaimData, responseJson) => {
  FlightBookingTrackingHelper.trackClickEvent(
    'Special claim options',
    `Specialclaim_${specialClaimData.scCode}`,
    responseJson,
  );
  if (specialClaimData && specialClaimData.scCode === 'ancclaim') {
    Actions.claimAncillaryRefund({
      bookingId: responseJson.bookingID,
      lobCode: responseJson.lobCode,
      scCode: specialClaimData.scCode,
      flightResponse: responseJson,
    });
  } else {
    openSpecialClaimSelectPaxPage(responseJson, specialClaimData);
  }
};

export const dateChangeCardClkHandler = (
    action, 
    pageName, 
    responseJson, 
    lobName, 
    openDgcaVoidPopup, 
    isSourceDgcaVoidPopup
  ) => {
  const {
    dateChangeDgca,
    dateChangeVoid,
  } = responseJson;
  DeviceEventEmitter.emit(EventEmitterConst.CTA_CLICKED_EVENT);
  switch (action) {
    case 'ACT_RES_CHARGES':
      const { continueText, dateChangePolicyText } = getStaticData();
      if (responseJson.dateChangeSupported) {
        const btn = {};
        btn.text = continueText;
        btn.btnAction = 'ACT_RES_FLIGHT';
        openPolicyPage(
          dateChangePolicyText,
          responseJson.farePenaltyList.DateChange,
          responseJson.isFreeDateChangeAssured ? true : false,
          btn,
          responseJson,
        );
        FlightBookingTrackingHelper.trackClickEvent(pageName, 'DateChangepolicy', responseJson);
      } else {
        openPolicyPage(
          dateChangePolicyText,
          responseJson.farePenaltyList.DateChange,
          false,
          undefined,
          responseJson,
        );
        FlightBookingTrackingHelper.trackClickEvent(pageName, 'DateChange_NA', responseJson);
      }
      break;
    case 'ACT_RES_FLIGHT':
      if (!isSourceDgcaVoidPopup && (dateChangeDgca || dateChangeVoid)) {
        openDgcaVoidPopup({
          actionItem: {
            actionCTA: action
          },
          popupInfo: VOID_DGCA_CONSTANTS.DGCA_VOID_DATE_CHANGE_INFO,
          pageName,
        });
        return;
      }
      openDateChange(pageName, responseJson, lobName);
      break;
    default:
      break;
  }
};

export const addOnCardClickHandler = async (childBookingData, responseJson) => {
  const { carUIDetails } = childBookingData.crossplatformLayoutDetail;
  if (carUIDetails.callToAction === 'UPDATE DETAILS') {
    if (Platform.OS === 'web') {
      GenericModule.openDeepLink(carUIDetails.updatePickupDetailsDeepLinkForWeb);
    } else {
      GenericModule.openDeepLink(carUIDetails.updatePickupDetailsDeepLinkForApp);
    }
    FlightBookingTrackingHelper.trackClickEvent('Details', 'CabAddOnUpdateClick', responseJson);
  } else {
    const data = {};
    const userInfo = await HotelBookingModule.getRequestHeader(
      childBookingData.bookingId,
      'CAB_BOOKING_DETAIL',
    );
    data.BOOKING_ID = childBookingData.bookingId;
    data.MMT_AUTH = userInfo.mmtAuth;
    data.LOGGING_TRACKINFO = userInfo.loggingTrackingInfo;
    data.PARENT_PAGE = 'FLIGHT_DETAILS_PAGE';
    data.PARENT_BOOKING_ID = responseJson.bookingID;
    if (Platform.OS === 'web') {
      FlightBookingModule.openCabBookingDetail(childBookingData.bookingId);
    } else {
      Actions.cabBookingDetail({ data });
    }
    FlightBookingTrackingHelper.trackClickEvent('Details', 'CabAddOnClick', responseJson);
  }
};

export const getTopStatusCardArrow = (bookingstate) => {
  switch (bookingstate) {
    case 'Failed':
      if (Platform.OS === 'ios') {
        return IOS_BACK_ARROW;
      }
      return ANDROID_BACK_ARROW;
    default:
      if (Platform.OS === 'ios') {
        return IOS_BACK_ARROW;
      }
      return ANDROID_BACK_ARROW;
  }
};

export const getLobName = (holidayBookingApi) => {
  return holidayBookingApi ? LOBNAMES.HOLIDAY : LOBNAMES.FLIGHT;
};

export const getIntialisedState = () => {
  return {
    responseJson: null,
    viewState: ViewState.LOADING,
    specialClaimSelectedItem: undefined,
    showDateChangeOverlay: false,
    segmentAncillaryDetails: [],
    ancillaryResponseReceived: false,
    showDateChangeSubmittedOverlay: false,
    showDateChangeSubmittedOverlayIndex: undefined,
    showNameChangeOverlay: false,
    acmeCity: undefined,
    showChecklistOverlay: false,
    showWebcheckinOverlay: false,
    helpingHandOverlay: false,
    showWheelChairOverlay: false,
    ancillaryResponseJson: null,
    wheelChairActiveSegment: null,
    showSpecialClaimBottomSheet: { value: false, data: null },
    bottomSheetAnimated: new Animated.Value(-500),
  };
};

//csatFeddback should be displayed if it is not already shared  and the below condns are met

export const shouldShowCsatPopup = (cSatData, isCsatInlineCardEnabled) => {
  const {
    isCtaClicked,
    isCsatFeedbackSharedWithin1Day,
    isCsatFeedbackSharedWithin7Days,
    isTimeSpentMoreThanSpecified,
    isCsatPopupHandled,
    openCsatPopup,
  } = cSatData;
  if (!openCsatPopup && !isCsatPopupHandled && !isCsatInlineCardEnabled) {
    const res =
      (isCtaClicked && !isCsatFeedbackSharedWithin1Day) ||
      (isTimeSpentMoreThanSpecified && !isCsatFeedbackSharedWithin7Days);
    return res;
  }
  return false;
};

export const isCancellationApplicable = (actionItems) => {
  if (isEmpty(actionItems)) {
    return false;
  }
  return actionItems.some((actionItem) => actionItem.actionId.toUpperCase().includes('CANCEL'));
};

export const getAddSeatMealPopupRendered = async (bookingId) => {
  const addSeatmealPopupkey = `addSeatMealPopup${bookingId}`;
  const addSeatMealPopUpShowed = await getDataFromStorage(addSeatmealPopupkey);
  const result = !addSeatMealPopUpShowed ? false : JSON.parse(addSeatMealPopUpShowed);
  return result;
};

export const setAddSeatMealPopupRendered = async (bookingId) => {
  const addSeatmealPopupkey = `addSeatMealPopup${bookingId}`;
  await setDataInStorage(addSeatmealPopupkey, JSON.stringify(true));
};

export const getCancellationCardNormalMsgObj = (flightDetailResponse) => {
  if (
    flightDetailResponse.farePenaltyList &&
    flightDetailResponse.farePenaltyList.Cancellation &&
    flightDetailResponse.farePenaltyList.Cancellation.descObj
  ) {
    return flightDetailResponse.farePenaltyList.Cancellation.descObj;
  }
  return null;
};
