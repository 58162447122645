import React from 'react';
import { View, Text, TouchableOpacity, Image } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getHtmlStyle } from './styles';
import getStyles from './styles';
import { greenTick } from '../../../CabImageConstants';
import CabBookingTrackingHelper from '../../../CabBookingTrackingHelper';
import { CLICK_EVENT, OVERLAY_TYPE, PAGE_NAME } from '../../../CabBookingDetailsConstant';
import isEmpty from "lodash/isEmpty"
import { dropDownArrow, infoIcon } from '../../../CabImageConstants';
import HTMLView from 'react-native-htmlview';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import { BlackFooterProps } from '../../../types';
import BaseButton, { BUTTON_VARIANT } from 'apps/post-sales/src/Common/BaseButton';

const BlackFooter: React.FC<BlackFooterProps> = ({
  paymentStickyFooterData,
  openPriceBreakup,
  onConfirmation,
  freeCancellationText,
  handleOverlay,
  overlay
}) => {

  if(isEmpty(paymentStickyFooterData)){
    return null;
  }

  const onPaymentIconClick = () => {
    CabBookingTrackingHelper.trackClickEvent(
      PAGE_NAME.MODIFY_BOOKING,
      CLICK_EVENT.PAYMENT_INFO_ICON_CLICK,
    );
    openPriceBreakup();
  };

  const onDueAmountClick = () => {
    CabBookingTrackingHelper.trackClickEvent(
      PAGE_NAME.MODIFY_BOOKING,
      CLICK_EVENT.PAYMENT_OPTION_EXPEND_CLICK,
    );
    if(!!overlay){
      handleOverlay();
    }else{
      handleOverlay(OVERLAY_TYPE.PAYMENT_OPTION);
    }
  }

  const { dueNowText, payRestText, ctaText } = paymentStickyFooterData || {};
  const { fsStyle, ...fonts } = getFont();
  const { psTheme: theme } = useTheme();
  const styles = getStyles(fonts, theme);
  const { color } = theme;

  return (
    <>
      <View style={styles.container}>
        <View style={AtomicCss.marginRight5}>
          <Image style={styles.cancelIcon} source={greenTick} />
        </View>
        {!!freeCancellationText && <HTMLView stylesheet={getHtmlStyle(fonts, theme)} value={freeCancellationText} />}
      </View>
      <View style={styles.payNowWrapper}>
        <View>
          <TouchableOpacity
            onPress={onDueAmountClick}
            feedbackColor={color.transparent}
          >
            <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
              {!!dueNowText && <Text style={styles.dueAmountText}>{dueNowText}</Text>}
              <View style={styles.paymentModalIconContainer}>
                <Image
                  style={styles.dropDownImg}
                  source={dropDownArrow}
                />
              </View>
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={onPaymentIconClick}
          >
            <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginTop5]}>
              {!!payRestText && <Text style={styles.payNowInfo}>{payRestText}</Text>}
              <View>
                <Image
                  style={styles.infoIcon}
                  source={infoIcon}
                />
              </View>
            </View>
          </TouchableOpacity>
        </View>
        <View>
        <BaseButton
          variant={BUTTON_VARIANT.CAPSULE}
          clickHandler={onConfirmation}
          text={ctaText.toUpperCase()}
          buttonStyle={styles.buttonBlue}
          textStyle={styles.buttonText}
          isThrottleRequired={true}
          throttleInterval={1000}
        />
        </View>
      </View>
    </>
  );
};

export default BlackFooter;
