import React from 'react';
import { Image, Text, TouchableOpacity, View } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { useTheme } from '../../../theme/theme.context';
import { getStyle } from './styles';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { crossIcon } from '../../BusImageConstants';
import isEmpty from 'lodash/isEmpty';
import { getStaticData } from 'apps/post-sales/src/staticData/staticData';
import { CancellationPolicyOverlayProps } from '../../types';

const CancellationPolicyOverlay: React.FC<CancellationPolicyOverlayProps> = ({
  handleOverlay,
  response,
}) => {
  const { psTheme } = useTheme();
  const { color } = psTheme;
  const { fsStyle, ...fonts } = getFont(true);
  const styles = getStyle(fonts, color);
  const { cancellationPolicyText, okayText } = getStaticData(true);

  const getFooterViewsList = (): JSX.Element[] => {
    let viewsList: JSX.Element[] = [];
    const {clientDetails} = response || {};
    const {busCancellationPolicyFooter} = clientDetails || {};
    !isEmpty(busCancellationPolicyFooter) && busCancellationPolicyFooter.map((footer, index) => {
      viewsList.push(
        <View
          key={index}
          style={[
            AtomicCss.flexRow,
            { marginTop: 10, paddingHorizontal: 10 },
          ]}
        >
          <Text style={[AtomicCss.lineHeight18,styles.footerText]}>{footer}</Text>
        </View>,
      );
    });
    return viewsList;
  };

  return (
    <React.Fragment>
      <View style={[styles.headingSection, AtomicCss.flexRow]}>
        <View style={[AtomicCss.flex1, AtomicCss.flexRow, AtomicCss.alignCenter]}>
          <Text style={styles.headingText}>{cancellationPolicyText}</Text>
        </View>
        <TouchableOpacity style={styles.crossIconWrapper} onPress={() => handleOverlay()}>
          <Image style={styles.crossIconStyle} source={crossIcon} />
        </TouchableOpacity>
      </View>
      <View style={AtomicCss.marginBottom20}>
        {!isEmpty(response.clientDetails) &&
          !isEmpty(response.clientDetails.busCancellationPolicyFooter) &&
          response.clientDetails.busCancellationPolicyFooter.length !== 0 &&
          getFooterViewsList()}
      </View>
      <View>
        <TouchableOpacity style={styles.okayButton} onPress={() => handleOverlay()}>
          <Text style={styles.okayText}>{okayText}</Text>
        </TouchableOpacity>
      </View>
    </React.Fragment>
  );
};

export default CancellationPolicyOverlay;
