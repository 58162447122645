import React from 'react';
import {View, Text, ScrollView} from 'react-native';
import PropTypes from 'prop-types';
import Actions from '../../../navigation/postSalesNavigation';
import Header from './Header';
import ReviewStepHeader from './ReviewStepHeader';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import RecBlueBtn from '@mmt/legacy-commons/Common/Components/Buttons/RecBlueBtn.js';
import AmendInformationCard from './AmendInformationCard';
import BasePage from '../../../Common/PostSalesBasePage';
import * as PSC from '../../../PostSalesConstant';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import PageLoader from '../../../Common/Cancellation/PageLoader';
import CabAmendFullScreenPage from './CabAmendFullScreenPage';
import CabBookingDetailsConstant from '../../CabBookingDetailsConstant';
import CabBookingTrackingHelper from '../../CabBookingTrackingHelper';
import {isNetworkAvailable} from '@mmt/legacy-commons/Common/utils/AppUtils';
import * as NetworkUtils from '../../../utils/NetworkUtils';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import ConfirmationOverlay from '../../../Common/ConfirmationOverlay';
import {styles} from './CabAmendmentStyles';

class CabReviewAmendment extends BasePage {
  constructor(props) {
    super(props, CabBookingDetailsConstant.CAB_AMEND_PREVIEW_PAGE);
    this.state = {
      viewState: ViewState.LOADING,
      apiError: false,
      overlay: false,
      payOverlay: false
    };
    this.amendResponse = {};
    this.errorMessage = '';
    this.errorSubText = '';
    this.amendData = this.props.amendData;
  }

  componentDidMount() {
    this.previewAmendment();
  }

  render() {
    return (
      <View style={AtomicCss.flex1}>
        {this.state.viewState === ViewState.LOADING && <PageLoader />}
        {this.state.viewState === ViewState.NO_INTERNET && this.renderNoNetworkView()}
        {this.state.viewState === ViewState.ERROR && this.rendorError()}
        {this.state.viewState === ViewState.SHOW_DETAIL && this.renderPage()}
      </View>
    );
  }

  renderPage() {
    return (
      <View style={styles.whiteContainer}>
        <Header handleBack={() => super.onBackIconPress()} title={PSC.CHANGE_PICKUP} toCity={this.amendResponse.clientDetails.toCity} fromCity={this.amendResponse.clientDetails.fromCity} />
        <ReviewStepHeader currentStep={1} headersMap={this.amendResponse.clientDetails.headers} />
        <ScrollView>
          {this.amendResponse.clientDetails.showNocostBanner &&
          <View style={styles.GreenBanner}>
            <Text
              style={[AtomicCss.font14, AtomicCss.greenText, AtomicCss.blackFont, AtomicCss.marginBottom5]}
            >{this.amendResponse.clientDetails.noCostBannerText ? this.amendResponse.clientDetails.noCostBannerText : PSC.SAME_CAR_MESSAGE}
            </Text>
          </View>
              }
          <AmendInformationCard response={this.props.response} amendResponse={this.amendResponse} />

        </ScrollView>
        <RecBlueBtn label={this.amendResponse.clientDetails.confirmButtonText} onPress={this.handleClick} />
        {this.state.overlay ? this.renderConfirmationPopup() : null}
        {this.state.payOverlay ? this.renderPaymentDeciderPopup() : null}
      </View>

    );
  }


    renderConfirmationPopup = () => (
      <ConfirmationOverlay
        content={this.amendResponse.clientDetails.popupText}
        leftBtnClick={this.confirmationPopup}
        rightBtnClick={this.handleConfirmButton}
        outsideClick={this.confirmationPopup}
        leftButtonText={this.amendResponse.clientDetails.leftbtnText}
        rightButtonText={this.amendResponse.clientDetails.rightbtnText}
        title={this.amendResponse.clientDetails.overLayTitle}
      />
    );

    renderPaymentDeciderPopup = () => (
      <ConfirmationOverlay
        content={this.amendResponse.clientDetails.paymentPopupText}
        leftBtnClick={this.paymentLaterRequested}
        rightBtnClick={this.paymentNowRequested}
        outsideClick={this.paymentConfirmationPopup}
        leftButtonText={this.amendResponse.clientDetails.payLaterBtnText}
        rightButtonText={this.amendResponse.clientDetails.payNowBtnText}
        title={this.amendResponse.clientDetails.paymentOverLayTitle}
      />
    );

    confirmationPopup = () => {
      this.setState({overlay: !this.state.overlay});
    };

    paymentConfirmationPopup = () => {
      this.setState({payOverlay: !this.state.payOverlay});
    };

    paymentLaterRequested = () => {
      this.amendData.dateChangeData.carAmendmentPayLater = true;
      this.handleConfirmButton();
    };

    paymentNowRequested = () => {
      this.amendData.dateChangeData.carAmendmentPayLater = false;
      this.handleConfirmButton();
    };

  handleClick = () => {
    if (this.amendResponse.clientDetails.showPaymentRequestPopup) {
      this.paymentConfirmationPopup();
    } else {
      this.confirmationPopup();
    }
  };

    handleConfirmButton = () => {
      CabBookingTrackingHelper.trackClickEvent(CabBookingDetailsConstant.CAB_AMEND_PREVIEW_PAGE, CabBookingDetailsConstant.CLICK_CHANGEPICKUP_DETAILS);
      Actions.cabCommitAmendment({
        response: this.props.response,
        amendData: this.amendData
      });
    }

    rendorError = () => (
      <CabAmendFullScreenPage
        headerText={CabBookingDetailsConstant.CHANGE_PICKUP_HEADER}
        errorText={this.state.apiError ? CabBookingDetailsConstant.DEFAULT_ERROR_MESSAGE : this.errorMessage}
        errorSubText={this.state.apiError ? undefined : this.errorSubText}
        btnText={this.state.apiError ? CabBookingDetailsConstant.TRY_AGAIN : CabBookingDetailsConstant.BACK_TO_DETAILS}
        btnClickHandler={this.state.apiError ? () => {
                this.setState({viewState: ViewState.LOADING});
                this.previewAmendment();
            } : this.popToBookingDetail}
        headerBackButton={super.onBackIconPress}
        fromCity={this.props.response.carBookingInfo.carDetail.fromCity}
        toCity={this.props.response.carBookingInfo.carDetail.toCity}
      />);

    popToBookingDetail() {
      Actions.popTo('cabBookingDetail');
    }

    async previewAmendment() {
      try {
        const hasNetwork = await isNetworkAvailable();
        if (!hasNetwork) {
          this.setState({viewState: ViewState.NO_INTERNET});
          return;
        }
        const url = NetworkUtils.CAB_VALIDATE_AMENDMENT_URL;
        const userInfo = await HotelBookingModule.getRequestHeader(this.props.amendData.bookingId, CabBookingDetailsConstant.CHECK_CAB_AMEND_SERVICE);
        const commonHeaders = await NetworkUtils.getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo);
        const response = await fetch(url, {
          method: PSC.POST,
          body: JSON.stringify(this.props.amendData),
          headers: commonHeaders
        });

        if (response && response.ok) {
          const data = await response.json();
          this.amendResponse = data;
          CabBookingTrackingHelper.trackLoadEvent(CabBookingDetailsConstant.CAB_AMEND_PREVIEW_PAGE, this.props.response);
          if (this.amendResponse.success && this.amendResponse.amendmentAllowed) {
            this.setState({viewState: ViewState.SHOW_DETAIL});
          } else {
            this.errorMessage = this.amendResponse.responseText;
            this.errorSubText = this.amendResponse.clientDetails ? this.amendResponse.clientDetails.errorSubText : undefined;
            this.setState({viewState: ViewState.ERROR});
          }
        } else {
          this.setState({viewState: ViewState.ERROR, apiError: true});
          this.trackError();
        }
      } catch (error) {
        this.trackError();
        this.setState({viewState: ViewState.ERROR, apiError: true});
      }
    }

    trackError = () => {
      CabBookingTrackingHelper.trackErrorEvent('mob: cabs: validate Amendment page:Error');
    }
}

CabReviewAmendment.propTypes = {
  response: PropTypes.object.isRequired,
  amendData: PropTypes.object.isRequired
};

export default CabReviewAmendment;
