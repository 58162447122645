import React, { useEffect } from 'react';
import {
  View,
  ScrollView,
  StyleSheet,
  Text,
  Image,
  Platform,
  BackHandler,
  TouchableOpacity,
} from 'react-native';
import Actions from '../../navigation/postSalesNavigation';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import ViewControllerModule from '@mmt/legacy-commons/Native/ViewControllerModule';
import SimpleHeader from '@mmt/legacy-commons/Common/Components/Header/SimpleHeader';
import getPlatformElevation from '@mmt/legacy-commons/Common/Components/Card/getPlatformElevation';
import { openWebView } from '../MSR/Utils';
import { showShortToast } from '@mmt/legacy-commons/Common/Components/Toast';
import { getStaticData } from '../../staticData/staticData';
import { openIndividualPhotograph } from '../../hotel/details/utils/HotelBookingDetailUtil';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import { isEmpty } from 'lodash';
import PostSalesBookingTrackingHelper from '../../PostSalesBookingTrackingHelper';
import { REFUND_OOT_TRACKING } from '../RefundOutOfTATForm/constants';
import BaseButton, { BUTTON_VARIANT } from '../BaseButton';
import { getRootTag } from '@mmt/legacy-commons/AppState/RootTagHolder';
import ButtonWrapper from '../ButtonWrapper';

const THUMBNAIL_COUNT = 2;

const ImageSection = React.memo(({ uris, title, subTitle }) => {
  const nonThumbnailImageCount = uris.length - THUMBNAIL_COUNT;
  return (
    <View style={[AtomicCss.flexRow, AtomicCss.flex1, AtomicCss.marginTop10]}>
      {uris.slice(0, THUMBNAIL_COUNT).map((uri, index) => (
        <TouchableOpacity onPress={() => openIndividualPhotograph(uris, index, title, subTitle)}>
          <Image source={{ uri }} style={styles.bankStateStyle} />
        </TouchableOpacity>
      ))}
      {nonThumbnailImageCount > 0 && (
        <View style={AtomicCss.makeRelative}>
          <TouchableOpacity
            style={styles.moreImageStyle}
            activeOpacity={0.9}
            onPress={() => openIndividualPhotograph(uris, THUMBNAIL_COUNT, title, subTitle)}
          >
            <Text
              style={[AtomicCss.whiteText, AtomicCss.font24, AtomicCss.boldFont]}
            >{`+${nonThumbnailImageCount}`}</Text>
          </TouchableOpacity>
        </View>
      )}
    </View>
  );
});

const RefundDelayInfoPage = (props) => {

  const { data, bookingId, pageName = REFUND_OOT_TRACKING.PAGES.DEFAULT } = props;

  useEffect(() => {
    PostSalesBookingTrackingHelper.trackShownEvent(pageName, REFUND_OOT_TRACKING.SHOW.REFUND_PROCESS, bookingId);
  }, [bookingId, pageName])

  const onBackIconPress = () => {
    // simulate hardware back press
    const somethingPoped = Actions.pop();
    if (somethingPoped != null && !somethingPoped) {
      if (Platform.OS === 'ios') {
        ViewControllerModule.popViewController(getRootTag());
      } else {
        BackHandler.exitApp();
      }
    }
  };

  const clickHandler = (action) => {
    const { requestNo, bookingId, lobName, pageName = REFUND_OOT_TRACKING.PAGES.DEFAULT } = props;
    const { isFormFilled, formUrl, webViewText } = action;
    const {
      errorText: { somethingWentWrongError },
    } = getStaticData();
    if (isFormFilled) {
      PostSalesBookingTrackingHelper.trackClickEvent(`${pageName}:${REFUND_OOT_TRACKING.PAGES.DELAY_INFO}`, REFUND_OOT_TRACKING.CLICK.TRACK_TICKET_STATUS, bookingId);
      // Redirect user to the MSR Ticket already raised.
      if (formUrl) {
        openWebView(formUrl);
      } else {
        showShortToast(somethingWentWrongError);
      }
    } else {
      PostSalesBookingTrackingHelper.trackClickEvent(`${pageName}:${REFUND_OOT_TRACKING.PAGES.DELAY_INFO}`, REFUND_OOT_TRACKING.CLICK.CREATE_TICKET, bookingId);
      Actions.refundOutOfTATForm({
        bookingId,
        requestNo,
        headerTitle: webViewText,
        lobName,
        pageName,
      });
    }
  };

  const { bookingIdText } = getStaticData();
  const { checklistDetailsList, refundOutOfTATForm, heading } = data;
  return (
    <View style={styles.pageWrapper}>
      <SimpleHeader
        title={heading}
        iconPress={onBackIconPress}
      />
      <ScrollView>
        <View style={[styles.whiteCard, AtomicCss.marginHz12, styles.cardWrapper]}>
          <View style={[styles.innerDtls]}>
            {checklistDetailsList &&
              checklistDetailsList.length &&
              checklistDetailsList.map((item, index) => (
                <View
                  style={[
                    styles.infoRow,
                    checklistDetailsList.length === index + 1 ? styles.lastItem : null,
                  ]}
                >
                  <View style={styles.iconWrapper}>
                    <Image style={styles.iconStyle} source={{ uri: item.iconUri }} />
                  </View>
                  <Text
                    style={[
                      AtomicCss.boldFont,
                      AtomicCss.blackText,
                      AtomicCss.lineHeight20,
                      AtomicCss.font16,
                      AtomicCss.marginBottom5,
                    ]}
                  >
                    {item.heading}
                  </Text>
                  <Text
                    style={[
                      AtomicCss.regularFont,
                      AtomicCss.defaultText,
                      AtomicCss.font14,
                      AtomicCss.lineHeight20,
                    ]}
                  >
                    {item.subheading}
                  </Text>
                  {!!item.otherInfoList && item.otherInfoList.length > 0 && (
                    <View style={[styles.otherInfoContainer]}>
                      {item.otherInfoList.map(({ heading, descriptionList }) => (
                        <>
                          <Text
                            style={[
                              AtomicCss.boldFont,
                              AtomicCss.font13,
                              AtomicCss.lineHeight22,
                              AtomicCss.yellowText,
                            ]}
                          >
                            {heading}
                          </Text>
                          {!isEmpty(descriptionList) &&
                            descriptionList.map((description) => (
                              <Text
                                style={[
                                  AtomicCss.boldFont,
                                  AtomicCss.font13,
                                  AtomicCss.lineHeight22,
                                  AtomicCss.yellowText,
                                ]}
                              >
                                {description}
                              </Text>
                            ))}
                        </>
                      ))}
                    </View>
                  )}
                  {!!item.imageUris && item.imageUris.length && (
                    <ImageSection
                      uris={item.imageUris}
                      title={item.imageHeading}
                      subTitle={item.imageSubheading}
                    />
                  )}
                </View>
              ))}
          </View>
        </View>
        {refundOutOfTATForm && refundOutOfTATForm.showCard && (
          <View style={[styles.whiteCard, AtomicCss.marginHz12, styles.bottomCardWrapper]}>
            <View style={[styles.infoRowBottom]}>
              <View style={styles.iconBottomWrapper}>
                <Image style={styles.iconStyle} source={{ uri: refundOutOfTATForm.iconUri }} />
              </View>
              <View style={[AtomicCss.flex1, AtomicCss.marginTop10]}>
                <Text
                  style={[
                    AtomicCss.boldFont,
                    AtomicCss.blackText,
                    AtomicCss.lineHeight20,
                    AtomicCss.font16,
                    AtomicCss.marginBottom5,
                  ]}
                >
                  {refundOutOfTATForm.cardBoldText}
                </Text>
                <Text
                  style={[
                    AtomicCss.regularFont,
                    AtomicCss.defaultText,
                    AtomicCss.font14,
                    AtomicCss.lineHeight20,
                  ]}
                >
                  {refundOutOfTATForm.cardText}
                </Text>
                <View style={[styles.bottomOtherInfoContainer]}>
                  <Text
                    style={[
                      AtomicCss.regularFont,
                      AtomicCss.defaultText,
                      AtomicCss.font12,
                      AtomicCss.lineHeight16,
                    ]}
                  >
                    {`${bookingIdText} `}
                    <Text
                      style={[
                        AtomicCss.boldFont,
                        AtomicCss.defaultText,
                        AtomicCss.lineHeight16,
                        AtomicCss.font12,
                        AtomicCss.marginBottom5,
                      ]}
                    >
                      {bookingId}
                    </Text>
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.btnWrapper}>
              <ButtonWrapper
                btnText={refundOutOfTATForm.cardLinkText}
                clickHandler={()=>clickHandler(refundOutOfTATForm)}
                disabled={!refundOutOfTATForm.ctaEnabled}
              />
            </View>
          </View>
        )}
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  whiteCard: {
    backgroundColor: colors.white,
    marginVertical: 8,
    borderRadius: 10,
    ...getPlatformElevation(2),
  },
  pageWrapper: {
    flex: 1,
    backgroundColor: colors.lightGrey,
  },
  cardWrapper: {
    marginTop: 12,
    marginBottom: 4,
  },
  bottomCardWrapper: {
    marginBottom: 20,
  },
  btnWrapper: {
    backgroundColor: 'transparent',
    paddingLeft: 64,
    paddingRight: 20,
    marginBottom: 24,
    marginTop: 22,
  },
  infoRow: {
    borderLeftColor: colors.lightGrey14,
    borderLeftWidth: 2,
    paddingLeft: 32,
    paddingBottom: 20,
  },
  infoRowBottom: {
    paddingHorizontal: 16,
    paddingVertical: 20,
    flexDirection: 'row',
  },
  iconStyle: {
    width: 32,
    height: 32,
  },
  iconWrapper: {
    width: 42,
    height: 42,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    left: -22,
    top: -7,
  },
  iconBottomWrapper: {
    width: 32,
    height: 32,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 16,
  },
  bankStateStyle: {
    width: 72,
    height: 72,
    marginRight: 8,
    borderRadius: 4,
  },
  moreImageStyle: {
    width: 72,
    height: 72,
    backgroundColor: colors.darkCharcoal,
    position: 'absolute',
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0.85,
    marginRight: 8,
  },
  innerDtls: {
    paddingLeft: 32,
    paddingRight: 18,
    paddingVertical: 24,
  },
  lastItem: {
    borderLeftWidth: 2,
    borderLeftColor: 'transparent',
    paddingBottom: 0,
  },
  otherInfoContainer: {
    backgroundColor: colors.creamWhite,
    borderRadius: 3,
    padding: 8,
    marginTop: 10,
  },
  bottomOtherInfoContainer: {
    backgroundColor: colors.grayBg,
    borderRadius: 3,
    marginTop: 10,
    padding: 6,
    marginRight: 6,
  },
});

export default RefundDelayInfoPage;
