import React, { memo } from 'react';
import OptionListItem from "./OptionsListItem";

const OptionsListWithRadio = ({ list, onChangeHandler, selectedOptions ,keyName, isCorporateBooking}) => {
    let options = [];
    if (list && list.length > 0) {
        for (let i = 0; i < list.length; i++) {
            options.push(<OptionListItem {...list[i]} isCorporateBooking={isCorporateBooking} value={list[i][keyName]} isActive={selectedOptions === list[i][keyName]} index={i} onChangeHandler={onChangeHandler} />)
        }
    }
    return options;
}

export default memo(OptionsListWithRadio);