import { StyleSheet } from 'react-native';
import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';

export default (theme, fonts) =>
StyleSheet.create({
  contentTitle: {
    fontSize: 21,
    color: theme.color.defaultTextColor,
    fontFamily: fonts.light,
    marginBottom: 18
  },
  contentDesc: {
    fontSize: 14,
    paddingTop: 3,
    color: theme.color.defaultTextColor,
    fontFamily: fonts.regular
  },
  contentFooter: {
    marginBottom: 30,
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  actionBtn: {
    paddingVertical: 10
  },
  actionBtnEnabledText: {
    fontSize: 16,
    color: theme.color.azure,
    fontFamily: fonts.medium
  },
  actionBtnDisabledText: {
    fontSize: 16,
    color: theme.color.grey,
    fontFamily: fonts.medium
  },
  checkboxRow: {
    flexWrap: 'wrap',
    marginBottom: 5
  },
  agreementList: {
    ...getPlatformElevation(2),
    backgroundColor: theme.color.white,
    padding: 15,
    paddingBottom: 5,
    marginBottom: 10,
    borderRadius: 4
  },
  checkbox: {
    height: 18,
    width: 18,
    backgroundColor: theme.color.white,
    borderColor: theme.color.disabledBtnBg,
    borderWidth: 1.5,
    borderRadius: 3,
    overflow: 'hidden',
    position: 'relative',
    marginRight: 12,
    marginTop: 5
  },
  checkboxSelected: {
    borderColor: 'transparent',
    borderWidth: 0
  },
  checkBtn: {
    flexDirection: 'row',
    paddingVertical: 10,
    paddingHorizontal: 3,
    alignItems: 'center'
  },
  checkedIcon: {
    position: 'absolute',
    height: 29,
    width: 29,
    left: -5,
    top: -4
  },
  flex1: {
    flex: 1
  }
});
