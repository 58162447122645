import { StyleSheet } from "react-native";
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
export default (theme, fonts) =>StyleSheet.create({
    trainInfoSection:{
        borderBottomWidth:1,
        borderTopWidth:1,
        borderColor:theme.color.greyBookedSeat,
        paddingVertical:16,
        paddingHorizontal:14,
    },
    trainNumber:{
        fontSize:14,
        color: theme.color.black,
        ...(fonts.regularFontFamily),
    },
    destination:{
        fontSize:12,
        color:theme.color.greyLight,
        flex:1,
        ...(fonts.boldFontFamily),
        alignSelf:'flex-start',
    }
})

