import { Dimensions, StyleSheet } from "react-native";
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
export default (theme, fonts) =>StyleSheet.create({
    topSection: {
        ...getPlatformElevation(2),
        backgroundColor: theme.color.grey22,
        paddingTop : 16,
        paddingHorizontal : 10,
        paddingBottom: 12,
        borderRadius:8,
        position:'relative',
        marginLeft : 10,
        marginRight: 10,
    },
    iconWrapper: {
        width: 24,
        height: 24,
        marginRight: 10,
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    cardIconStyle: {
        width: 22,
        height: 22,
        backgroundColor : theme.color.grayBg,
        borderRadius : 2
    },
    redirectLink: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingVertical: 20,
        backgroundColor: theme.color.grey22,
        alignItems: 'center',
        borderBottomRightRadius: 8,
        borderBottomLeftRadius: 8,
        paddingLeft: 44,
        paddingRight: 20,
    },
    gradientStyle: {
        height:4,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        position: 'absolute',
        width: Dimensions.get('window').width -23,
         zIndex: 2,
         top: -2,
         left:-1
    },
})
