export const CCDE = "ccde";
export const LANGUAGE_COOKIE = "lang";
export const AGENT_ID = "agentId";
export const MMT_AUTH = "mmt-auth";
export const EXTERNAL_PLATFORM = "external_platform";
export const MY_BUSINESS_SUBSCRIPTION = "myBusinessSubscription"
export const GDPR_REGION_COOKIE = "isGdprRegion";
export const ENGLISH = 'eng';
export const ARABIC = 'ara';
export const HINDI = 'hin';
export const EXISTING_BASE_URL = "https://supportz.makemytrip.com/Mima/"
export const MIMA_API_BASE_URL = 'https://supportz.makemytrip.com/api/';
export const FLIGHT_DOWNLOAD_TICKET = `${MIMA_API_BASE_URL}flight/getPdfFlightVoucher`;
export const FLIGHT_DOWNLOAD_BAGGAGE_TAG = `${MIMA_API_BASE_URL}flight/getPdfBaggageTag`;
export const GET_AWS_OBJECT = `${MIMA_API_BASE_URL}common/getAWSObject/`;
export const DOWNLOAD_HOTEL_VOUCHER_PDF = `${MIMA_API_BASE_URL}hotel/generatePdf`;
export const RAILS_DOWNLOAD_TICKET = `${MIMA_API_BASE_URL}generateRailTicketPdf`;
export const RAILS_DOWNLOAD_INVOICE = `${MIMA_API_BASE_URL}generateRailTicketPdf`;
export const DOWNLOAD_HOLIDAY_VOUCHERS_PDF = `${MIMA_API_BASE_URL}/holiday/getHolidayVouchers`;
export const TGS_DOWNLOAD_INVOICE = `${MIMA_API_BASE_URL}tg/previewVoucherPdf`;
export const DOWNLOAD_HOLIDAY_HOTEL_VOUCHER_PDF = `${MIMA_API_BASE_URL}/holiday/getholidayhotelvoucher`;
export const DOWNLOAD_HOLIDAY_FLIGHT_VOUCHER_PDF = `${MIMA_API_BASE_URL}/holiday/getflighteticket`;
export const DOWNLOAD_HOLIDAY_ADDON_VOUCHERS_PDF = `${MIMA_API_BASE_URL}/holiday/getmiscvoucher`;
export const DOWNLOAD_HOLIDAY_INVOICE_PDF = `${MIMA_API_BASE_URL}/holiday/getholidayinvoice`;
export const DOWNLOAD_CAB_INVOICE_PDF = `${MIMA_API_BASE_URL}generatecabTicketPdf`;
export const GET_UUID = `${MIMA_API_BASE_URL}get/uuid`;
export const POKUS_DETAILS = 'https://pokus.makemytrip.com/experiment/assignVariantBackend'

export const DOWNLOAD_BUS_INVOICE_PDF = `${MIMA_API_BASE_URL}generateBusVoucherPdf`;
export const BUS_ERROR_MSG = "Downloading E-Ticket/Invocie Failed. Please try Again";
export const DEEPLINK_DECRYPT_KEY = 'TUkxMjM0QCNERTJQTCFOSw==';

export const SUPPORTZ_INDIA  = 'https://supportz.makemytrip.com';
export const SUPPORTZ_GLOBAL  = 'https://supportz.makemytrip.global';

export const DEEP_LINK_ACTIONS = {
    MY_TRIPS: '0',
    BOOKING_DETAIL: '1',
    HOTEL_NAME_CHANGE: '105',
    HOTEL_DATE_CHANGE: '106',
    FLIGHT_DATE_CHANGE: '201',
    FLIGHT_SPECIAL_CLAIM: '202',
    FLIGHT_CANCELLATION: '203',
    FLIGHT_SPECIAL_CLAIM_SELECTED: '204',
    FLIGHT_WEB_CHECK_IN: '205',
    FLIGHT_ADD_SEAT: '206',
    FLIGHT_ADD_MEAL: '207',
    FLIGHT_ADD_BAGGAGE: '208',
    FLIGHT_ADD_ANCILLARY: '209',
    EXTERNAL_LINK: '210',
    HOTEL_ADDGUEST: '108',
    HOTEL_CANCELLATION: '109',
    HOTEL_DOWNLOAD_INVOICE: "121",
    HOTEL_DOWNLOAD_VOUCHER: "122",
    HOTEL_DOWNLOAD_POLICY: "123"
};

export const AFFILIATES = {
    '6eholidays': 'igo',
    'giholidays': 'gi',
    'airindiaexpressholidays': 'affcgobizmmtaix'
}

export const FLIGHT_DOWNLOAD_TRIGGERS = {
    E_TICKET: 'Flight_CustomerETicket',
    INVOICE: 'Flight_Customer_Invoice',
    BAGGAGE: 'Flight_BaggageTag',
    INSURANCE: 'InsuranceVoucher',
    BOARDING_PASS: 'BOARDING_PASS',
    FLIGHT_ETICKET_MYPARTNER: 'Flight_Eticket_MyPartner'
}

export const RAILS_DOWNLOAD_TRIGGERS = {
    E_TICKET: 'Rail_Eticket',
    INVOICE: 'Rail_CustomerInvoice',
}

export const TGS_DOWNLOAD_TRIGGERS = {
    INVOICE: 'TGS_CustomerInvoice',
}

export const CONTENT_TYPE = {
    APPLICATION_PDF: "application/pdf",
};

export const COUNTRY_CODE = {
  IN: "IN",
  US: "US",
  AE: "AE",
};

export const COUNTRY_INFO_MAPPING = {
    IN: {
      region: "in",
      currency: "inr",
      language: "eng",
    },
    US: {
      region: "us",
      currency: "usd",
      language: "eng",
    },
    AE: {
      region: "ae",
      currency: "aed",
      language: "eng",
    },
};

export const Font = {
    LATO: 'lato',
    ALMARAI: 'almarai',
    MUKTA: 'mukta'
};

export const LanguageOptions = {
    eng: {
        text: "English",
        dir: "ltr",
        langCode: ENGLISH,
        font: Font.LATO
    },
    ara: {
        text: "Arabic",
        dir: "rtl",
        langCode: ARABIC,
        font: Font.ALMARAI
    },
    hin: {
        text: "Hindi",
        dir: "ltr",
        langCode: HINDI,
        font: Font.MUKTA
    }
};

export const getPokusBody = (data) => {
    return { "user": { "userId": data?.data?.uuid }, "lob": "POST_SALES", "attributes": { "device_os": "pwa" }  }
}

export const DOWNLOAD_VOUCHER_STATES = {
    INITIAL: 'initial',
    PROGRESS: 'progress',
    SUCCESS: 'success',
    RETRY: 'retry'
}
