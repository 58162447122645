import React from 'react';
import {Image, Text, View} from 'react-native';
import {isEmpty} from 'lodash';
import PropTypes from 'prop-types';
import AtomicCss from '../AtomicCss';
import styles from './LocationCss';
import {
  handleClickEvent,
  isNotNullAndEmpty
} from '../../../hotel/details/utils/HotelBookingDetailUtil';
import {getActionButton} from '../../utils/AcmeBookingDetailUtil';
import {pickupCardIcon} from '../../utils/AcmeImageConstant';
import { removeHTMLTags } from '../../../utils/PostSaleUtil';

class PickUpCard extends React.Component {
  static navigationOptions = {
    header: null
  };

  render() {
    return (
      <View style={[AtomicCss.whiteBg, AtomicCss.marginBottom10]}>
        <View style={[styles.CardContentFull, AtomicCss.paddingBottom24]}>
          <View style={[styles.CardContent, AtomicCss.spaceBetween]}>
            <View style={[AtomicCss.flexRow]}>
              <Image style={styles.CardIcon} source={pickupCardIcon} />
              <Text style={[AtomicCss.blackFont, AtomicCss.blackText, AtomicCss.font14]}>
                PICK UP DETAILS
              </Text>
            </View>
          </View>
          <View style={AtomicCss.flexRow}>
            <View style={styles.CardIcon} />
            <View style={[AtomicCss.flex1]}>
              <Text
                style={[AtomicCss.font14, AtomicCss.blackText, AtomicCss.boldFont, AtomicCss.marginBottom5]}
              >Pickup
                Address
              </Text>
              <View style={styles.infoTxt}>
                <Text
                  style={[styles.driverDetails, AtomicCss.regularFont]}
                >{removeHTMLTags(this.props.response.acmeBookingInfo.acmeBookingDetails[0].acmeAdditionalInfo.pickupAddress)}
                </Text>
              </View>
            </View>
          </View>
          {!isEmpty(this.props.response.acmeBookingInfo.acmeBookingDetails[0].acmeAdditionalInfo.dropOffAddress) &&
          <View style={AtomicCss.flexRow}>
            <View style={styles.CardIcon} />
            <View style={[AtomicCss.flex1]}>
              <Text
                style={[AtomicCss.font14, AtomicCss.blackText, AtomicCss.boldFont, AtomicCss.marginBottom5]}
              >DropOff
                Address
              </Text>
              <View style={styles.infoTxt}>
                <Text
                  style={[styles.driverDetails, AtomicCss.regularFont]}
                >{this.props.response.acmeBookingInfo.acmeBookingDetails[0].acmeAdditionalInfo.dropOffAddress}
                </Text>
              </View>
            </View>
          </View>
          }
        </View>

        <View
          style={isNotNullAndEmpty(this.props.card.actionList) ? styles.whiteBtnHalfOuter : styles.hideView}
        >
          {getActionButton(this.props.card, this.props.response, this.handleClick)}
        </View>
      </View>
    );
  }

  handleClick = (action) => {
    handleClickEvent(this.props.card, action, this.props.response, this.props.pageName);
  };
}

PickUpCard.propTypes = {
  response: PropTypes.object.isRequired,
  card: PropTypes.object.isRequired,
  pageName: PropTypes.string.isRequired
};
export default PickUpCard;
