export const responseState = {
  RESPONSE_IS_LOADING: 'RESPONSE_IS_LOADING',
  RESPONSE_IS_FETCHED: 'RESPONSE_IS_FETCHED',
  PREVIEW_RESPONSE_FAILED: 'PREVIEW_RESPONSE_FAILED',
  RESPONSE_IS_FAILED: 'RESPONSE_IS_FAILED',
  PROCEED_ENABLED: 'PROCEED_ENABLED',
  LISTING_RESPONSE_FETCHED: 'LSITING_RESPONSE_FETCHED',
  LISTING_RESPONSE_FAILED_NEW: 'LISTING_RESPONSE_FAILED_NEW',
  LISTING_RESPONSE_FAILED: 'LISTING_RESPONSE_FAILED',
  LISTING_RESPONSE_LOADING: 'LISTING_RESPONSE_LOADING',
  LISTING_RESPONSE_ERROR_CLOSE: 'LISTING_RESPONSE_ERROR_CLOSE',
  LISTING_RESPONSE_ERROR_OPEN: 'LISTING_RESPONSE_ERROR_OPEN',
  LISTING_RESPONSE_ERROR_LOADER: 'LISTING_RESPONSE_ERROR_LOADER',
  CANCEL_CONFIRM_POPUP_OPEN: 'CANCEL_CONFIRM_POPUP_OPEN',
  CANCEL_CONFIRM_POPUP_CLOSE: 'CANCEL_CONFIRM_POPUP_CLOSE',
  NO_INTERNET: 'NO_INTERNET',
  RT_DATE_ERROR: 'RT_DATE_ERROR',
};
export const steps = {
  SELECT_DATES: 1,
  PICK_FLIGHTS: 2,
  PAY_AND_BOOK: 3,
};

export const actionTypes = {
  RESPONSE_FETCHED: 'RESPONSE_FETCHED',
  RESPONSE_FAILED: 'RESPONSE_FAILED',
  LISTING_RESPONSE_FAILED_NEW: 'LISTING_RESPONSE_FAILED_NEW',
  PREVIEW_RESPONSE_FAILED: 'PREVIEW_FAILED',
  RESPONSE_LOADING: 'RESPONSE_LOADING',
  PROCEED_ENABLED: 'PROCEED_ENABLED',
  NO_INTERNET: 'NO_INTERNET',
};
export const listingActionTypes = {
  LISTING_RESPONSE_FETCHED: 'LISTING_RESPONSE_FETCHED',
  LISTING_RESPONSE_FAILED: 'LISTING_RESPONSE_FAILED',
  LISTING_RESPONSE_FAILED_NEW: 'LISTING_RESPONSE_FAILED_NEW',
  LISTING_RESPONSE_ERROR_CLOSE: 'LISTING_RESPONSE_ERROR_CLOSE',
  LISTING_RESPONSE_ERROR_OPEN: 'LISTING_RESPONSE_ERROR_OPEN',
  LISTING_RESPONSE_ERROR_LOADER: 'LISTING_RESPONSE_ERROR_LOADER',
};
export const cancelRebookActionTypes = {
  CANCEL_PREVIEW_FETCHED: 'CANCEL_PREVIEW_FETCHED',
  CANCEL_PREVIEW_FAILED: 'CANCEL_PREVIEW_FAILED',
  CANCEL_PREVIEW_LOADING: 'CANCEL_PREVIEW_LOADING',
  CANCEL_CONFIRM_POPUP_OPEN: 'CANCEL_CONFIRM_POPUP_OPEN',
  CANCEL_CONFIRM_POPUP_CLOSE: 'CANCEL_CONFIRM_POPUP_CLOSE',
};
export const rtActionTypes = {
  RT_DATE_ERROR: 'RT_DATE_ERROR',
};

export const validationCardTypes = {
  SPECIAL_CLAIM: 'SpecialClaim',
  NO_DATE_CHANGE: 'No_Date_Change',
  SUBMIT: 'Submit',
  CALL: 'Call',
};



export const STATUS = {
  FAILURE: 'FAILURE',
  SUCCESS: 'SUCCESS',
  PENDING: 'PENDING',
};

export const DcViewState = {
  LOADING: 'loading',
  SHOW_DETAIL: 'detail',
  NO_INTERNET: 'no_internet',
  DEFAULT: 'default',
  ERROR: 'error',
  HOLD_API_FAILURE_RESPONSE: 'hold_error',
  RAISE_API_FAILURE_RESPONSE: 'raise_error',
  RAISE_SUCCESS: 'RAISE_SUCCESS',
  NO_RESULT: 'no_result',
  HOLD_SUCCESS: 'HOLD_SUCCESS',
  HOLD_LOADING: 'HOLD_LOADING',
  RAISE_LOADING: 'RAISE_LOADING',
  APPROVE_REQUEST_LOADING: 'APPROVE_REQUEST_LOADING',
  APPROVE_REQUEST_ERROR: 'APPROVE_REQUEST_ERROR',
  APPROVE_REQUEST_SUCCESS: 'APPROVE_REQUEST_SUCCESS',
  AFTER_PAYMENT: 'AFTER_PAYMENT',
  LISTING_API_ERROR: 'listing_api_error',
  RENDER_LIST: 'render_list',
};



export const OUT_OF_POLICY = 'OUT OF POLICY';



export const SORT_STYLE_MAP = {
  price: 'PricefilterIcon',
  departure: 'departurefilterIcon',
  arrival: 'arrivalfilterIcon',
  duration: 'DurationfilterIcon',
};

export const OK = 'OK';
export const CANCEL = 'Cancel';
export const SUBMIT = 'Submit';
export const CONTINUE = 'CONTINUE';
export const TERMINAL = 'Terminal';
export const DEPART = 'DEPART';
export const DateChangeConstants = {
  DATE_CHANGE_CHARGES: 'DATE CHANGE CHARGES',
  PAY_NOW: 'PAY NOW',
  CONTINUE: 'CONTINUE',
  REVIEW_FLIGHT: 'Review Your Flight',
  IMP_INFO: 'Important Information',
  OUT_OF_POLICY: 'Out of policy',
  GST_DETAILS: 'GST Details',
  CHECK_IN_BAG: 'check in baggage',
  CABIN_BAG: 'cabin baggage',
  BAG_ALLOWANCE: 'Baggage Allowance',
  HEADING_REJECT: 'Reason For Rejection',
  DESC_REJECT: 'Please enter your reason for rejecting this booking',
  HEADING_REASON: 'Reason for Reschedule',
  DESC_REASON: 'Please enter your reason for making this booking',
  SKIP_APPROVAL: 'SKIP APPROVAL',
  TRAVELLER_DETAILS: 'Traveler Details',
  LOADING_REVIEW: 'Loading Review...',
 OutOfPolicyHeading : 'THIS FLIGHT IS OUT OF POLICY',
 OutOfPolicySubHeading : 'Either the price exceeds the budget limit set by your company or booking this close to the travel date is inhibited by your company',
 OutOfPolicySubHeading2 : 'The Booking ID for your new booking will remain same.',
};

export const CorpReviewErrors = {
  DATA_NOT_AVAIL:  'Data not available',
  POLICY_API_FAIL: 'Policy Api failed'
}

export const AIRLINE_IMG_URL = 'https://imgak.mmtcdn.com/flights/assets/media/mobile/common/hdpi';