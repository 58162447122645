import React from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';
import PropTypes from 'prop-types';
import FareBreakUp from './FareBreakUp';
import Header from '../../../../Common/HeaderCross';
import PayModeBreakup from './PayModeBreakup';
import SecurityCard from "../SecurityCard";
import SelfDriveBookingTrackingHelper from "../../../SelfDriveBookingTrackingHelper";
import { backPressHandler } from '../../../../utils/PostSaleUtil';
class PaymentPriceBreakup extends React.Component {

    render() {
        return (
            <View style={styles.PriceBreakupWrapper}>
                <Header
                    title="Payment Details"
                    headerShadow
                    headingTxt="font18"
                    handleClose={this.goBackToDetails}
                />
                <ScrollView style={{marginTop: 10}}>
                    {<FareBreakUp
                        fareBreakUpDetail={this.props.bookingDetailsResponse.pricingInfo.pricingbreakups}
                        paymentBreakup={this.props.bookingDetailsResponse.paymentInfo.paymentBreakups}
                    />}
                    {
                        this.props.bookingDetailsResponse.paymentInfo &&
                        <PayModeBreakup
                            paymentBreakup={this.props.bookingDetailsResponse.paymentInfo.paymentBreakups}
                        />
                    }
                    { this.props.bookingDetailsResponse.securityDepositDetails && <SecurityCard depositDetails={this.props.bookingDetailsResponse.securityDepositDetails}
                    response={this.props.bookingDetailsResponse}/>}
                </ScrollView>
            </View>
        );
    }

    goBackToDetails = () => {
        SelfDriveBookingTrackingHelper.trackClickEvent('View Price Detail', 'ViewPrice_BreakUpDetail');
        backPressHandler();
    }

    componentDidMount() {
        SelfDriveBookingTrackingHelper.trackLoadEvent('Details_paymentdetail', this.props.bookingDetailsResponse,'');
    }
}

const styles = StyleSheet.create({
    PriceBreakupWrapper: {
        flex: 1,
        justifyContent: 'space-between',
        backgroundColor: '#fff'
    },
    btnWrapper: {
        paddingLeft: 15,
        paddingRight: 15,
        marginBottom: 15,
        alignItems: 'center'
    }
});

export default PaymentPriceBreakup;

PaymentPriceBreakup.propTypes = {
    bookingDetailsResponse: PropTypes.object.isRequired,
};
