import React from 'react';
import { View, Text, Image } from 'react-native';
import TGSBookingConstants from '../../../../Utils/TGSBookingConstants';
import TrainInfoCard from './TrainInfoCard';
import PaxStatusCard from '../PaxStatusCard';
import TripGuaranteeInfo from '../TripGuaranteeInfoCard';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import createStyles from './TopStatusCardCss';
import isEmpty from 'lodash/isEmpty';
import VoucherInfo from '../VoucherInfo';
import { downloadInvoice, getActionButton } from '../../../../Utils/TgsBookingDetailUtil';
import TermsAndConditionCard from '../TermsAndConditionsCard';
import CancelledBooking from './CancelledBooking';
import { getFont } from '../../../../../PostSalesStyles';
import { useTheme } from '../../../../../theme/theme.context';
import {
  DARK_GREEN_INFO,
  DARK_GREEN_TICK,
  DARK_YELLOW_INFO,
  TGS_ICON,
} from '../../../../Utils/tripGuaranteeImageConstants';
import TGSBookingTrackEventHelper from '../../../../Utils/TGSBookingTrackEventHelper';
import { PAGE_NAME, CLICK_TYPE } from '../../../../Utils/TGSBookingConstants';
const TopStatusCard = ({ response, card }) => {
  const { topStatusCardInfo, headerInfo, passengerDetailsInfo, extraPolicies } = response || {};
  const { heading, subHeading, booking, pnrNo } = headerInfo || {};
  const { heading: bookingHeading, bookingID } = booking || {};
  const {
    status,
    heading: chartInfoTxt,
    subHeading: guaranteeTxt,
    tripGuaranteeInfo,
    voucherInfo,
    railDetails: trainDetails,
    cancelledData,
  } = topStatusCardInfo || {};
  const { actionList } = card || {};
  const fonts = getFont();
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);
  const getAddedStyle = () => {
    let topStatusStyle = [styles.tgsTopCard];
    switch (status) {
      case TGSBookingConstants.PREPARED_CONFIRMED:
      case TGSBookingConstants.NOT_PREPARED:
      case TGSBookingConstants.PARTIALLY_CONFIRMED:
        topStatusStyle.push(styles.ticketAdded);
        break;
      case TGSBookingConstants.NOT_CONFIRMED:
        topStatusStyle.push(styles.ticketIssued);
        break;
      case TGSBookingConstants.NOT_PREPARED_CANCELLED:
        topStatusStyle.push(styles.ticketCancelled);
        break;
      default:
    }
    return topStatusStyle;
  };

  const getImage = () => {
    return (
      <>
        {status === TGSBookingConstants.NOT_PREPARED ||
        (status === TGSBookingConstants.NOT_CONFIRMED && isEmpty(extraPolicies)) ? (
          <Image style={styles.greentickStyle} source={DARK_GREEN_INFO} />
        ) : status === TGSBookingConstants.NOT_PREPARED_CANCELLED ? (
          <Image style={styles.greentickStyle} source={DARK_YELLOW_INFO} />
        ) : (
          <Image style={styles.greentickStyle} source={DARK_GREEN_TICK} />
        )}
      </>
    );
  };
  const handleClick = () => {
    TGSBookingTrackEventHelper.trackClickEvents(
      PAGE_NAME.TRIP_DETAILS,
      CLICK_TYPE.DOWNLOAD_INVOICE_CLICK,
    );
    downloadInvoice(response);
  };

  return (
    <>
      <View style={[...getAddedStyle(), AtomicCss.marginBottom10]}>
        <View style={[AtomicCss.alignCenter, AtomicCss.paddingHz16, AtomicCss.marginBottom20]}>
          {heading && <Text style={[styles.title, fonts.blackFontFamily]}>{heading}</Text>}
          {subHeading && (
            <Text style={[styles.info, fonts.regularFontFamily]}>
              {subHeading} {pnrNo}
            </Text>
          )}
        </View>
        {/* Trip Guarantee White Card */}
        <View style={AtomicCss.paddingHz16}>
          <View style={styles.tgsWhiteCard}>
            <View
              style={
                status === TGSBookingConstants.NOT_PREPARED_CANCELLED
                  ? [styles.chartInfo, styles.chartInfoCancelled]
                  : styles.chartInfo
              }
            >
              {getImage()}
              {!!chartInfoTxt && (
                <Text
                  style={
                    status === TGSBookingConstants.NOT_PREPARED_CANCELLED
                      ? styles.chartInfoCancelledTxt
                      : styles.chartInfoTxt
                  }
                >
                  {chartInfoTxt}
                </Text>
              )}
            </View>
            <View style={styles.tgTitleWrapper}>
              <Image style={styles.tgsIconStyle} source={TGS_ICON} />
              {!!guaranteeTxt && (
                <Text style={[styles.guaranteeTitleTxt, fonts.blackFontFamily]}>
                  {guaranteeTxt}
                </Text>
              )}
            </View>

            {!isEmpty(tripGuaranteeInfo) && status === TGSBookingConstants.NOT_PREPARED && (
              <TripGuaranteeInfo tripGuaranteeInfo={tripGuaranteeInfo} />
            )}

            {!isEmpty(voucherInfo) && <VoucherInfo voucherInfo={voucherInfo} />}

            {!isEmpty(passengerDetailsInfo) &&
              (status === TGSBookingConstants.PREPARED_CONFIRMED ||
                status === TGSBookingConstants.PARTIALLY_CONFIRMED) && (
                <PaxStatusCard passengerDetailsInfo={passengerDetailsInfo} />
              )}

            {!isEmpty(trainDetails) &&
              status !== TGSBookingConstants.NOT_CONFIRMED &&
              status !== TGSBookingConstants.NOT_PREPARED_CANCELLED && (
                <TrainInfoCard trainDetails={trainDetails} />
              )}

            {!isEmpty(extraPolicies) && status === TGSBookingConstants.NOT_CONFIRMED && (
              <TermsAndConditionCard extraPolicies={extraPolicies} useToggle={true} />
            )}
            {!isEmpty(cancelledData) && <CancelledBooking cancelledData={cancelledData} />}
            {!isEmpty(actionList) && getActionButton(card, response, handleClick)}
          </View>
        </View>
        {/* Trip Guarantee White Card */}
        <View style={[styles.footerInfo, AtomicCss.flexRow]}>
          {!!bookingHeading && (
            <Text style={[styles.footerInfoText, fonts.regularFontFamily]}>
              {bookingHeading} :{' '}
            </Text>
          )}
          {!!bookingID && (
            <Text style={[styles.footerInfoText, fonts.regularFontFamily]}>{bookingID}</Text>
          )}
        </View>

      </View>
    </>
  );
};

export default TopStatusCard;
