import React from 'react';
import { Text, TouchableOpacity, View, Image } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getStyles } from './styles';
import { getFont } from '../../../../PostSalesStyles';
import { useTheme } from '../../../../theme/theme.context';
import { CROSS_ICON, crossIcon } from '../../../CabImageConstants';
import isEmpty from 'lodash/isEmpty';
import { FareBreakupOverlayProps } from '../../../types';
import CabBookingDetailsConstant from '../../../CabBookingDetailsConstant';

const FareBreakupOverlay: React.FC<FareBreakupOverlayProps> = ({
  paymentBreakup,
  handleBottomOverlay,
}) => {
  const { fsStyle, ...fonts } = getFont();
  const { psTheme: theme } = useTheme();
  const styles = getStyles(fonts, theme);

  const {fareBreakupText} = CabBookingDetailsConstant;

  return (
    <View style={styles.fbWrapper}>
      <View style={[styles.headingSection]}>
        <View style={[AtomicCss.flex1]}>
          <Text style={styles.headingText}>{fareBreakupText}</Text>
        </View>
        <TouchableOpacity onPress={() => handleBottomOverlay('')}>
          <Image style={styles.crossIconStyle} source={CROSS_ICON} />
        </TouchableOpacity>
      </View>
      <View style={styles.bodyWrapper}>
        {!isEmpty(paymentBreakup) &&
          paymentBreakup.map((payment, index) => {
            const { label, amountText } = payment;
            return (
              <View key={index} style={[styles.section]}>
                <View style={[styles.flexStyle]}>
                  {!!label && <Text style={styles.paymentLabel}>{label}</Text>}
                  {!!amountText && <Text style={styles.paymentValue}>{amountText}</Text>}
                </View>
                {!isEmpty(payment.breakup) && (
                  <View style={styles.innerInfo}>
                    {payment.breakup.map((breakup, innerIndex) => {
                      const { label, amountText } = breakup;
                      return (
                        <View key={innerIndex} style={[styles.flexStyle, AtomicCss.marginBottom8]}>
                          {!!label && <Text style={styles.paymentLabel}>{label}</Text>}
                          {!!amountText && <Text style={styles.paymentValue}>{amountText}</Text>}
                        </View>
                      );
                    })}
                  </View>
                )}
              </View>
            );
          })}
      </View>
    </View>
  );
};

export default FareBreakupOverlay;
