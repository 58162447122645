import React from 'react';
import {Text, TouchableOpacity, StyleSheet} from 'react-native';
import { getFont } from '../../../PostSalesStyles';
import {fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import { ThemeContext } from '../../../theme/theme.context';


class CapsuleWhiteBtn extends React.Component {
    static navigationOptions = {
      header: null
    };

    static contextType = ThemeContext;
    render() {
      const { fsStyle, ...fonts } = getFont(true);
      const styles = createStyles(this.context.psTheme);
      return (
        <TouchableOpacity style={styles.CapsuleWhiteBtn} onPress={this.props.onPress}>
          <Text style={[styles.text, fonts.blackFontFamily, fsStyle.font12]}>{this.props.WhiteBtnTxt}</Text>
        </TouchableOpacity>
      );
    }
}
const createStyles = (theme)=> StyleSheet.create({
  CapsuleWhiteBtn: {
    backgroundColor: theme.color.white,
    borderRadius: 30,
    paddingTop: 11,
    paddingBottom: 11,
    elevation: 3,
    shadowColor: '#000',
    shadowOffset: {width: 0, height: 2},
    shadowOpacity: 0.3,
    shadowRadius: 2,
    paddingHorizontal: 20
  },
  text: {
    color: theme.color.primary,
    fontSize: 12,
    alignSelf: 'center'
  }
});

export default CapsuleWhiteBtn;
