import React from 'react';
import {View} from 'react-native';
import PropTypes from 'prop-types';
import SimpleHeader from '@mmt/legacy-commons/Common/Components/Header/SimpleHeader.ios';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import ErrorPage from '../../hotel/details/pages/ErrorPage';
import { renderNeedHelpOverlay } from '../../hotel/details/utils/HelpingHandHelper';
import { getStaticData } from '../../staticData/staticData';
import PostSalesBasePage from '../PostSalesBasePage';

const staticData = getStaticData();
export default class PageLoadingError extends PostSalesBasePage {

  constructor(props) {
    super(props);
    this.state = {
      helpingHandOverlay: false
    };
  }

  setHelpingHandOverlay = (value) => {
    this.setState({
      helpingHandOverlay: value
    });
  }

  render() {
    const {
      title,
      message,
      retryFunction,
      buttonLabel
    }  = this.props
    return (
      <View style={{flex: 1}}>
        <SimpleHeader
          title={ title ? title : backText }
          iconPress={super.onBackIconPress}
        />
        {!this.props.showHelpingHand && <ErrorView
          message={this.props.message}
          buttonLabel={this.props.buttonLabel}
          showRetry
          buttonLabel={buttonLabel}
          message={message}
          onRetry={retryFunction}
        />}
        {this.props.showHelpingHand && this.props.helpingHandData && <ErrorPage
          message={this.props.message}
          buttonLabel={this.props.buttonLabel}
          showRetry
          onRetry={this.props.retryFunction}
          showHelpingHand={this.props.showHelpingHand}
          helpingHandLabel={this.props.helpingHandData.connectWithUsText}
          helpingHandClickHandler={() => this.setHelpingHandOverlay(true)}
        />}
        {this.props.showHelpingHand && this.state.helpingHandOverlay === true &&
          renderNeedHelpOverlay(this.props.helpingHandData, this.setHelpingHandOverlay)
        }
      </View>
    );
  }
}

PageLoadingError.defaultProps = {
  title: staticData.backText,
  message: staticData.errorText.somethingWentWrongTryAgainError,
  buttonLabel: staticData.tryAgainText
};

PageLoadingError.propTypes = {
  retryFunction: PropTypes.func.isRequired,
  title: PropTypes.string,
  message: PropTypes.string,
  buttonLabel: PropTypes.string,
};
