import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';
import {fonts} from '@mmt/legacy-commons/Styles/globalStyles';

const styles = {
  headerWrapper: {
    backgroundColor: '#fff',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
    paddingVertical: 12,
    flexDirection: 'row',
    alignItems: 'center',
    zIndex: 10

  },
  headerShadow: {
    ...getPlatformElevation(2)
  },
  headingTxt: {
    fontSize: 14,
    color: '#4a4a4a',
    fontFamily: fonts.bold,
    lineHeight: 24
  },
  headingSubTxt: {
    fontSize: 12,
    color: '#4a4a4a',
    fontFamily: fonts.regular
  },


  crossIconStyle: {
    width: 14,
    height: 14
  },

  headerIconWrapper: {
    width: 44,
    height: 44,
    alignItems: 'center',
    justifyContent: 'center',
    left: -10
  }
};
export default styles;
