import { StyleSheet, I18nManager } from 'react-native';

export default (theme, fonts) =>StyleSheet.create({
  headerWrapper: {
    padding: 15,
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
    zIndex: 1,
    elevation: 1,
    backgroundColor: theme.color.grey22,
    marginBottom: 1,
    shadowColor: theme.color.black,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 2,
  },
  headerTypeDetails: {
    paddingVertical: 10,
  },
  headerTypeTab: {
    paddingBottom: 35,
  },
  headerTitle: {
    ...fonts.fsStyle.font16,
    color: theme.color.black,
    ...fonts.boldFontFamily,
    maxWidth:'92%'
  },
  headerDescText: {
    fontSize: fonts.fontSize.font16,
    ...fonts.regularFontFamily,
    color: theme.color.disabledBtnBg,
    marginTop: 3,
    maxWidth:'94%'
  }, 
  stickyBarWrapper: {
    paddingHorizontal: 15,
    paddingVertical: 5,
  },
  backArrow: {
    width: 16,
    height: 16,
    transform: [{scaleX: I18nManager.isRTL ? -1 : 1}]
  },
  headerView:{
    width:'100%'
  }
});

