import React from 'react';
import { View, Image, Text } from 'react-native';
import { getStaticData } from '../../../../../staticData/staticData';
import { FALSE_CLAIM_ICON } from '../../../../FlightImageConstants';
import styles from './downloadInvoiceBottomSheetCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getFont } from '../../../../../PostSalesStyles';
import BaseButton, { BUTTON_VARIANT } from '../../../../../Common/BaseButton';
import { FLIGHT_DOWNLOAD_FILES_THROTTLE_INTERVAL } from '../../FlightBookingDetailsConstant';

const DownloadInvoiceError = ({ onRetry }) => {
  const { fsStyle, ...fonts } = getFont(true);
  const { retryText, tryAgainInSomeTime, invoicesNotDownloadedText } = getStaticData();
  return (
    <React.Fragment>
      <View style={styles.errorScreenWrapper}>
        <Image style={styles.errorIcon} source={FALSE_CLAIM_ICON} />
        <Text
          style={[
            AtomicCss.fontWeightBold,
            fsStyle.font22,
            fonts.regularFontFamily,
            AtomicCss.blackText,
            AtomicCss.marginBottom10,
          ]}
        >
          {invoicesNotDownloadedText}
        </Text>
        <Text style={[fsStyle.font16, fonts.regularFontFamily, AtomicCss.blackText]}>
          {tryAgainInSomeTime}
        </Text>
      </View>
      <BaseButton
        variant={BUTTON_VARIANT.PRIMARY}
        clickHandler={onRetry}
        textStyle={fsStyle.font16}
        text={retryText}
        throttleInterval={FLIGHT_DOWNLOAD_FILES_THROTTLE_INTERVAL}
        isThrottleRequired={true}
      />
    </React.Fragment>
  );
};

export default DownloadInvoiceError;
