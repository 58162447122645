import React from 'react';
import { View, Text, StyleSheet, Image, TouchableOpacity } from 'react-native';
import { PAYMENT_ICON } from '../../Utils/RailImageConstant';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getPlatformElevation from 'apps/post-sales/src/Holiday/Components/getPlatformElevation';
import { getThrottleParams, handleRailClickEvent } from '../../details/RailBookingDetailsUtil';
import isEmpty from 'lodash/isEmpty';
import { useTheme } from '../../../theme/theme.context';
import { getFont } from '../../../PostSalesStyles';
import { PaymentMadeProps } from '../../types';
import getStyles from './PaymentModeCardCss';
import { getActionButton } from '../../../Common/commonUtil';
import WhiteCardWrapper from '../../../Common/WhiteCardWrapper';

const PaymentModeCard = ({ response, card, pageName } : PaymentMadeProps) => {
  const {psTheme} = useTheme();
  const {color} = psTheme;
  const {fsStyle, ...fonts} = getFont(true);
  const styles = getStyles(fonts, color);
  const { couponInformation, paymentBreakup } = response || {};
  const { paymentBreakupAmountPaid, totalPaidAmountBreakUpMap } = paymentBreakup || {};
  const { text: header, value: totalAmountPaid } = paymentBreakupAmountPaid || {};
  const {couponName, couponType, couponMessage} = couponInformation || {};

  const handleClick = (action : any) => {
    handleRailClickEvent(card, action, response, pageName);
  };

  return (
    <>
      <View style={styles.topSection}>
        <View style={[styles.headingWrapper, AtomicCss.marginBottom10, AtomicCss.alignCenter]}>
          <View style={[AtomicCss.flex1, AtomicCss.flexRow, AtomicCss.alignCenter]}>
            <View style={styles.iconWrapper}>
              <Image style={styles.cardIconStyle} source={PAYMENT_ICON} />
            </View>
            {!isEmpty(header) && <Text style={styles.headerStyle}>
              {header}
            </Text>}
          </View>
          {!isEmpty(totalAmountPaid) && <Text style={styles.headerStyle}>
            {totalAmountPaid}
          </Text>}
        </View>
        {!isEmpty(totalPaidAmountBreakUpMap) && Object.keys(totalPaidAmountBreakUpMap).map((key, value) => {
          return (
            <View
              style={[
                AtomicCss.flexRow,
                styles.detailsSection,
                AtomicCss.spaceBetween,
                AtomicCss.marginBottom5,
              ]}
            >
              <Text style={styles.subHeaderStyle}>
                {key}
              </Text>
            </View>
          );
        })}
        {!isEmpty(couponInformation) && (
          <View>
            <View style={[styles.CardContent, AtomicCss.flexRow, AtomicCss.marginTop5]}>
              <View>
                <View style={couponName ? AtomicCss.flexRow : styles.hideView}>
                  {!isEmpty(couponType) && <Text style={[styles.discountText, fonts.boldFontFamily, fsStyle.font14]}>
                    {couponType}
                  </Text>}
                  {!isEmpty(couponName) && <Text style={[styles.couponTxt, fonts.regularFontFamily, fsStyle.font12]}>
                    {couponName}
                  </Text>}
                </View>
                {!isEmpty(couponMessage) && <Text style={[styles.couponDiscription, fonts.regularFontFamily, fsStyle.font12]}>
                  {couponMessage}
                </Text>}
              </View>
            </View>
          </View>
        )}
      </View>
      <View>{getActionButton(card, response, handleClick, {} , true, false, styles.buttonRadiusStyle, getThrottleParams)}</View>
    </>
  );
};



export default WhiteCardWrapper(PaymentModeCard);
