import React from 'react';
import { ScrollView, View } from 'react-native';
import PropTypes from 'prop-types';
import BasePage from '../../../../../Common/PostSalesBasePage';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import Header from '../../../../../Common/HeaderCross';
import TripProtectionSegment from './TripProtectionSegment';
import Actions from '../../../../../navigation/postSalesNavigation';

class TripProtectionPolicyPage extends BasePage {
  constructor(props) {
    super(props, 'tripProtectionPolicy');
  }

  static navigationOptions = {
    header: null,
  };

  render() {
    return (
      <View style={{ flex: 1, backgroundColor: '#f2f2f2' }}>
        <Header
          title={this.props.title}
          headerShadow
          headingTxt="font18"
          handleClose={this.onBackPress}
        />
        <ScrollView>
          <View style={AtomicCss.marginTop10}>
            {this.getInsuranceList(this.props.insuranceDetails.journeys)}
          </View>
        </ScrollView>
      </View>
    );
  }

  onBackPress = () => {
    Actions.pop();
  };

  getInsuranceList = (journeys) =>
    journeys.map((journey) => <TripProtectionSegment journey={journey} />);
}

export default TripProtectionPolicyPage;

TripProtectionPolicyPage.propTypes = {
  insuranceDetails: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};
