import React, { useState } from 'react';
import { View } from 'react-native';
import FiltersStop from './FilterStop';
import { getFilters } from '../../../../../utils';
import FilterDeparture from './FilterDeparture';
import FiltersAirlines from './FilterAirlines';

const FlightFilters = (props) => {
  const [currFltrObj, setCurrFltrObj] = useState(props.currFltrObj);
  const _renderStopRows = () => {
    const stpRwVw = [];
    const { filterInfo, currency } = props;
    const FILTERS = getFilters();
    filterInfo.itinFltrLst.forEach((itinFltrInfo, index) => {
      stpRwVw.push(
        <FiltersStop
          data={FILTERS.stops}
          itinFltrInfo={itinFltrInfo}
          currStopFltr={currFltrObj.itinFltrLst[index].currStpLst}
          index={index}
          toggleStopFilter={_setStpFltrLst}
          currency={currency}
        />,
      );
    });
    return stpRwVw;
  };

  const _renderDepRows = () => {
    const depRwVw = [];
    const { filterInfo, currency } = props;
    const FILTERS = getFilters();
    filterInfo.itinFltrLst.forEach((itinFltrInfo, index) => {
      depRwVw.push(
        <FilterDeparture
          data={FILTERS.departure}
          itinFltrInfo={itinFltrInfo}
          currDepFltr={currFltrObj.itinFltrLst[index].currDepTmLst}
          index={index}
          toggleDepFilter={_setDepTmFltrLst}
          currency={currency}
        />,
      );
    });
    return depRwVw;
  };

  const _renderOpAlsRows = () => {
    const opAlRwVw = [];
    const { filterInfo, currency } = props;
    opAlRwVw.push(
      <FiltersAirlines
        alFltrInfo={filterInfo.alFltrLst}
        currency={currency}
        currOpAlFltrLst={currFltrObj.alFltrLst}
        toggleOpAlFilter={_setOpAlFltrLst}
      />,
    );
    return opAlRwVw;
  };

  const _setStpFltrLst = (arr, index) => {
    const newCurrFltrObj = currFltrObj;
    newCurrFltrObj.itinFltrLst[index].currStpLst = arr;
    setCurrFltrObj(newCurrFltrObj);
  };

  const _setDepTmFltrLst = (arr, index) => {
    const newCurrFltrObj = currFltrObj;
    newCurrFltrObj.itinFltrLst[index].currDepTmLst = arr;
    setCurrFltrObj(newCurrFltrObj);
  };

  const _setOpAlFltrLst = (arr) => {
    const newCurrFltrObj = currFltrObj;
    newCurrFltrObj.alFltrLst = arr;
    setCurrFltrObj(newCurrFltrObj);
  };

  return (
    <View>
      {_renderStopRows()}
      {_renderDepRows()}
      {_renderOpAlsRows()}
    </View>
  );
};

export default FlightFilters;
