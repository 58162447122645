import { StyleSheet } from 'react-native';

const getStyle = (theme) => {
  const {color} = theme;
  return StyleSheet.create({
    OverlayContentWrapper: {
      backgroundColor: color.white,
      paddingTop: 12,
      paddingHorizontal: 5,
      position: 'relative',
      zIndex: 1,
      width: '100%',
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      paddingBottom: 50,
    },
    overlayTopline: {
      width: 40,
      height: 4,
      borderRadius: 4,
      backgroundColor: color.disabledButton,
      alignSelf: 'center',
    },
    title: {
      color: color.black,
      marginBottom: 30,
      lineHeight: 32,
    },
    uploadProofStyle: {
      width: 48,
      height: 48,
      marginBottom: 16,
    },
    strokeStyle: {
      width: 30,
      height: 4,
      backgroundColor: color.azure,
      marginBottom: 30,
    },
    lineHeight22: { lineHeight: 22 },
    lineHeight24: { lineHeight: 24 },
    marginBottom60: { marginBottom: 60 },
    marginBottom30: { marginBottom: 30 },
    linkWrapper: {
      paddingHorizontal: 16,
      paddingVertical: 12,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: color.paleBlue,
      borderWidth: 1,
      borderColor: color.faintBlue,
      borderRadius: 8,
      marginBottom: 60,
    },
    rtArrowStyle: {
      width: 12,
      height: 12,
    },
    backArrowWrapper: {
      width: 24,
      height: 24,
      marginLeft: -4,
      marginRight: 8,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    backArrowStyle: {
      width: 12,
      height: 12,
    },
    rejectIconStyle: {
      width: 20,
      height: 20,
    },
  });
} 

export default getStyle;
