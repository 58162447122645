import React from 'react';
import { View, Text, Image } from 'react-native';
import PropTypes from 'prop-types';
import _ from 'lodash';
import DateChangeTrackingHelper from '../modules/DateChange/analytics/DateChangeTrackingHelper';
import styles from './RequestSubmittedCss';
import Buttons from '@mmt/legacy-commons/Common/Components/Buttons';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { resetToFlightBookingDetail } from '../FlightBookingUtil';
import useHardwareBackpress from '../../Common/useHardwareBackpress';

/**
 * This page is developed from zeplin https://zpl.io/2pJRMdo
 * @param icon image url like require(path of image)
 * @param iconStyle style for image like height and width as an object
 * @param title Date Change Request submitted
 * @param extraInfo Payment Received
 * @param descriptionBoldText Our agents will call you in 2 hours
 * @param descriptionList description text list
 * @param btnTitle text of button
 * @param bookingId booking id of flight Lob to redirect to once user clicks on button
 */
const ThankYouPage = ({
  icon,
  iconStyle,
  title,
  extraInfo,
  descriptionBoldText,
  descriptionList,
  btnTitle,
  bookingId,
  omniturePageName,
}) => {
  DateChangeTrackingHelper.trackLoadNoTrueChangeThankYouPage(omniturePageName, bookingId);
  const onBackPressHandler = () => {
    resetToFlightBookingDetail(bookingId);
  };

  useHardwareBackpress().addListener(onBackPressHandler);

  DateChangeTrackingHelper.trackLoadEvent(omniturePageName, bookingId);
  return (
    <View style={styles.pageWrapper}>
      <View style={styles.messageContent}>
        <Image style={iconStyle} source={icon} />
        <Text
          style={[
            styles.messageTitle,
            _.isEmpty(extraInfo) ? AtomicCss.marginBottom24 : AtomicCss.marginBottom10,
          ]}
        >
          {title}
        </Text>
        {!_.isEmpty(extraInfo) && <Text style={styles.extraMessage}>{extraInfo}</Text>}
        {!_.isEmpty(descriptionBoldText) && (
          <Text style={styles.boldMessageDesc}>{descriptionBoldText}</Text>
        )}
        {!_.isEmpty(descriptionList) &&
          descriptionList.map((description, index) => (
            <Text
              style={[
                styles.messageDesc,
                index !== descriptionList.length - 1 ? AtomicCss.marginBottom10 : '',
              ]}
            >
              {description}
            </Text>
          ))}
      </View>
      <View style={styles.footerBtnWrapper}>
        <Buttons
          text={btnTitle}
          type="Rounded"
          color="blue"
          onPressHandler={() => {
            resetToFlightBookingDetail(bookingId);
          }}
        />
      </View>
    </View>
  );
};

export default ThankYouPage;

ThankYouPage.propTypes = {
  title: PropTypes.string.isRequired,
  extraInfo: PropTypes.string,
  descriptionBoldText: PropTypes.string,
  descriptionList: PropTypes.arrayOf(PropTypes.string).isRequired,
  icon: PropTypes.oneOfType([
    PropTypes.shape({
      uri: PropTypes.string,
      headers: PropTypes.objectOf(PropTypes.string),
    }),
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.shape({
        uri: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
        headers: PropTypes.objectOf(PropTypes.string),
      }),
    ),
  ]).isRequired,
  iconStyle: PropTypes.object.isRequired,
  btnTitle: PropTypes.string.isRequired,
  bookingId: PropTypes.string.isRequired,
};

ThankYouPage.defaultProps = {
  extraInfo: '',
  descriptionBoldText: '',
};
