import React, { useMemo } from 'react';
import { View, Image, Text, TouchableOpacity } from 'react-native';
import CarouselWpr from '../../../../../Common/CarousalWpr';
import { getFont } from '../../../../../PostSalesStyles';
import { useTheme } from '../../../../../theme/theme.context';
import getStyles from './styles';
import { CROSS_ICON } from '../../../../CabImageConstants';
import { CarPhotosData } from '../../../../types';
import CabBookingTrackingHelper from '@mmt/post-sales/src/cab/CabBookingTrackingHelper';

interface CarPhotosBtmSheetProps {
  data: CarPhotosData;
  handleBottomOverlay: (actionId: string, heading?: string) => void;
  pageName: string;
}

const CarPhotosBtmSheet: React.FC<CarPhotosBtmSheetProps> = ({
  data,
  handleBottomOverlay,
  pageName,
}) => {
  const { overlayData, imagesList } = data || {};

  const carousalData = useMemo(
    () =>
      imagesList?.map((image, i) => ({
        id: i + 1,
        imgSrc: image,
      })) || [],
    [imagesList],
  );

  const { fsStyle, ...fonts } = getFont();
  const { psTheme } = useTheme();
  const styles = getStyles(psTheme, fonts);

  return (
    <View style={styles.container}>
      <View style={styles.headingSection}>
        {!!overlayData?.heading && <Text style={styles.headingText}>{overlayData.heading}</Text>}
        <TouchableOpacity
          style={styles.crossIconWrapper}
          onPress={() => {
            CabBookingTrackingHelper.trackClickEvent(
              pageName,
              'Mob_Cabs_MIMA_CarPhotosOverlay_Close_Clicked',
            );
            handleBottomOverlay('close');
          }}
        >
          <Image style={styles.crossIconStyle} source={CROSS_ICON} />
        </TouchableOpacity>
      </View>

      <View style={styles.modifyInfoBox}>
        <CarouselWpr data={carousalData} slideTimer={3000} />
      </View>
    </View>
  );
};

export default CarPhotosBtmSheet;
