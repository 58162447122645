import React from 'react';
import { View, Text, Image } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { fonts, colors } from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';

const addonIcon = require('@mmt/legacy-assets/src/ic_addon.webp')

const AddonCard = React.memo(({ addOnCard }) => {

    return (
        <View style={[AtomicCss.marginBottom10, styles.cardBorder]}>
            <View style={[styles.infoCardWrapper]}>
                <View style={styles.headingIconWrapper}>
                    <Image style={styles.headingIconStyle} source={addonIcon} />
                </View>
                <View style={styles.infoCardContent}>
                    <Text style={[AtomicCss.defaultText, AtomicCss.blackFont, AtomicCss.font14, AtomicCss.marginBottom15]}>{addOnCard.heading}</Text>
                    {
                        addOnCard.items.map(item =>
                        (<View style={[AtomicCss.flexRow, AtomicCss.marginBottom10, AtomicCss.alignCenter]}>
                            <View style={styles.bullet}></View>
                            <Text style={[AtomicCss.boldFont, AtomicCss.font14, AtomicCss.blackText, AtomicCss.lineHeight20]}>{item}</Text>
                        </View>)
                        )
                    }
                </View>
            </View>
        </View>
    )

});

const styles = {

    cardBorder: {
        borderTopColor: colors.lightGrey3,
        borderBottomColor: colors.lightGrey3,
        borderTopWidth: 1,
        borderBottomWidth: 1
    },

    infoCardWrapper: {
        backgroundColor: colors.white,
        justifyContent: 'space-between',
        flexDirection: 'row',
        ...getPlatformElevation(2),
        marginBottom: 1,
    },

    infoCardContent: {
        paddingRight: 8,
        paddingVertical: 15,
        flex: 1
    },

    infoCustom: {
        fontSize: 14,
        color: colors.defaultTextColor,
        lineHeight: 18,
        fontFamily: fonts.regular,
    },

    headingIconWrapper: {
        paddingTop: 2,
        width: 46,
        alignItems: 'center',
    },

    headingIconStyle: {
        width: 24,
        height: 24,
        top: 10,
    },

    redirectBtnWrapper: {
        paddingTop: 20,
        paddingBottom: 20,
        paddingLeft: 50,
        paddingRight: 35,
        justifyContent: 'space-between',
        flexDirection: 'row',
        backgroundColor: colors.white,
        borderBottomWidth: 1,
        borderBottomColor: colors.gray1,
        alignItems: 'center',
    },

    rightArrowIcon: {
        width: 9,
        height: 13
    },

    linkBtnTxt: {
        fontSize: 14,
        fontFamily: fonts.bold,
        color: colors.azure
    },

    bullet: {
        width: 4,
        height: 4,
        borderRadius: 4,
        backgroundColor: colors.black,
        marginRight: 10
    },

    buttonWrapper: {
        paddingTop: 20,
        width: '90%'
    },

}

export default AddonCard