import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import AtomicCss from '../../commonStyles/AtomicCss';
import { getFont } from '../../../PostSalesStyles';
import { useTheme } from '../../../theme/theme.context';
import { TrainInfoCardProps } from '../../types';
import { getSegmentInfo } from '../../details/data/helper';
import isEmpty from 'lodash/isEmpty';
import getStyle from './style';
import { connect } from 'react-redux';

const TrainInfoCard = ({ response, latestTrainData }: TrainInfoCardProps) => {
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme } = useTheme();
  const { color } = psTheme;
  const styles = getStyle(color, fonts);
  const railDetailResponse = getSegmentInfo(response);
  const {
    trainName,
    trainNo,
    boardingPointName,
    reservationUptoName,
    fromStationCode,
    durationStr,
    destinationStationCode,
    formattedArrivalTime,
    formattedDepartureTime,
  } = railDetailResponse || {};
  let { trainScheduleData } =
    (!isEmpty(response) && !isEmpty(response.train) && response.train[0]) || {};
    trainScheduleData = !isEmpty(latestTrainData) && latestTrainData || trainScheduleData;
  const { sourcePlateformNo, destinationPlateformNo } = trainScheduleData || {};

  return (
    <View style={styles.trainInfoSection}>
      <View style={[AtomicCss.flexRow, AtomicCss.marginBottom16, AtomicCss.alignCenter]}>
        {!isEmpty(trainName) && <Text style={styles.trainNameStyle}>{trainName} </Text>}
        {!isEmpty(trainNo) && <Text style={styles.trainNumber}>#{trainNo}</Text>}
      </View>
      <View
        style={[
          AtomicCss.flexRow,
          AtomicCss.marginBottom10,
          AtomicCss.spaceBetween,
          AtomicCss.alignCenter,
        ]}
      >
        {!isEmpty(boardingPointName) && (
          <Text style={styles.boardingDetailStyle}>{boardingPointName} </Text>
        )}
        {!isEmpty(reservationUptoName) && (
          <Text style={styles.boardingDetailStyle}>{reservationUptoName}</Text>
        )}
      </View>
      <View
        style={[
          AtomicCss.flexRow,
          AtomicCss.marginBottom10,
          AtomicCss.spaceBetween,
          AtomicCss.alignCenter,
        ]}
      >
        <View style={[AtomicCss.flexRow, AtomicCss.flex1, AtomicCss.alignCenter]}>
          {!isEmpty(fromStationCode) && (
            <Text style={[styles.destination]}>{fromStationCode}</Text>
          )}
          {!isEmpty(sourcePlateformNo) && (
            <Text style={[styles.platformWrap]}>{sourcePlateformNo}</Text>
          )}
        </View>

        <View style={[styles.layoverWrap]}>
          <View style={styles.dividerSection} />
          {!isEmpty(durationStr) && <Text style={[styles.layover]}>{durationStr}</Text>}
        </View>
        <View
          style={[AtomicCss.flexRow, AtomicCss.flex1, AtomicCss.JustifyEnd, AtomicCss.alignCenter]}
        >
          {!isEmpty(destinationPlateformNo) && (
            <Text style={[styles.platformWrap, styles.platformRight]}>
              {destinationPlateformNo}
            </Text>
          )}
          {!isEmpty(destinationStationCode) && <Text style={[styles.destination]}>{destinationStationCode}</Text>}
        </View>
      </View>
      <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween, fonts.regularFontFamily]}>
        {!isEmpty(formattedArrivalTime) && (
          <Text style={[styles.destination, AtomicCss.regularFont]}>{formattedArrivalTime}</Text>
        )}
        {!isEmpty(formattedDepartureTime) && (
          <Text style={[styles.destination, styles.textAlignRight, fonts.regularFontFamily]}>
            {formattedDepartureTime}
          </Text>
        )}
      </View>
    </View>
  );
};


const mapStateToProps = (state) => {
  const {
    latestTrainData
  } = state.trainReducer;
  return {
    latestTrainData
  };
}

export default connect(
	mapStateToProps,
	null
)(TrainInfoCard);
