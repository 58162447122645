import React from 'react';
import PropTypes from 'prop-types';
import {ScrollView, Text, View} from 'react-native';
import Actions from '../../../navigation/postSalesNavigation';
import CabBookingTrackingHelper from '../../CabBookingTrackingHelper';
import CabBookingDetailsConstant from '../../CabBookingDetailsConstant';
import PageLoader from '../../../Common/Cancellation/PageLoader';
import BasePage from '../../../Common/PostSalesBasePage';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import {isNetworkAvailable} from '@mmt/legacy-commons/Common/utils/AppUtils';
import * as NetworkUtils from '../../../utils/NetworkUtils';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import NoInternetView from '@mmt/legacy-commons/Common/Components/Error/NoInternetView';
import CabAmendFullScreenPage from './CabAmendFullScreenPage';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import * as PSC from '../../../PostSalesConstant';
import Header from './Header';
import ReviewStepHeader from './ReviewStepHeader';
import RecBlueBtn from '@mmt/legacy-commons/Common/Components/Buttons/RecBlueBtn';
import {styles} from './CabAmendmentStyles';
import * as DateUtils from '@mmt/legacy-commons/Common/utils/DateUtils';
import {showShortToast} from '@mmt/legacy-commons/Common/Components/Toast';
import WheelPicker from '../cancelAndRebook/widget';
import { getIsoDate } from '../cancelAndRebook/CancelAndRebookUtill';

class CabChangePickupDetails extends BasePage {
  constructor(props) {
    super(props, CabBookingDetailsConstant.CAB_AMEND_DETAILS_PAGE);
    this.state = {
      viewState: ViewState.LOADING,
      apiError: false,
      newpickupDate: new Date()
    };
    this.amendPolicy = {};
    this.amendData = {};
    this.amendData.bookingId = this.props.response.bookingId;
    this.amendData.amendType = 0;
    this.errorMessage = '';
  }
  componentDidMount() {
    this.fetchCabAmendPolicy();
  }

  render() {
    return (
      <View style={styles.flex1}>
        {this.state.viewState === ViewState.LOADING && <PageLoader />}
        {this.state.viewState === ViewState.NO_INTERNET && this.renderNoNetworkView()}
        {this.state.viewState === ViewState.ERROR && this.rendorError()}
        {this.state.viewState === ViewState.SHOW_DETAIL && this.renderPage()}
      </View>
    );
  }

  renderPage() {
    return (
      <View style={[AtomicCss.flex1, AtomicCss.greyBg]}>
        <Header handleBack={() => super.onBackIconPress()} title={PSC.CHANGE_PICKUP} toCity={this.amendPolicy.clientDetails.toCity} fromCity={this.amendPolicy.clientDetails.fromCity} />
        <ReviewStepHeader currentStep={0} headersMap={this.amendPolicy.clientDetails.headers} />
        <ScrollView>
          <View style={styles.banner}>
            <Text style={[AtomicCss.font16, AtomicCss.blackFont, AtomicCss.blackText]}>
              {this.amendPolicy.clientDetails.pickDatesText}
            </Text>
          </View>

          <View style={[styles.cabInfoContentDate]}>
            <Text style={[AtomicCss.greyText, AtomicCss.regularFont, AtomicCss.font12, AtomicCss.marginBottom15]}>{this.amendPolicy.clientDetails.datePickerText}</Text>

            <View style={AtomicCss.flex1}>
               <WheelPicker
                  date={Date.parse(this.amendPolicy?.clientDetails?.oldPickupDate ?
                       new Date(this.amendPolicy?.clientDetails?.oldPickupDate) :
                       new Date())}
                  onDateChange={this.onDateChange}
              />
            </View>
          </View>
        </ScrollView>
        <RecBlueBtn label={this.amendPolicy.clientDetails.confirmButtonText} onPress={this.reviewAmendment} />
      </View>
    );
  }

    onDateChange = (valueGroup) => {
      const newDate = valueGroup?.date + ' ' + valueGroup?.time + ' ' + valueGroup?.meridiem;
      const isoDateString = getIsoDate(newDate);
      if (this.amendData && this.amendData.dateChangeData) {
        this.amendData.dateChangeData.newPickupDate = isoDateString;
      } else {
        this.amendData.dateChangeData = {
          newPickupDate: isoDateString,
        };
      }
    }

    onTimeChange = (time) => {
      if (this.amendData.dateChangeData) {
        this.amendData.dateChangeData.newPickupTime = time;
      } else {
        this.amendData.dateChangeData = {
          newPickupTime: time
        };
      }
    }

    reviewAmendment = () => {
      const maxDate = new Date(this.amendPolicy.clientDetails.maxDate);
      const minDate = new Date(this.amendPolicy.clientDetails.minDate);
      const maxDateDisplay = DateUtils.fillDateAndTime(maxDate, CabBookingDetailsConstant.CAR_AMENDMENT_DATE_DISPLAY);
      const minDateDisplay = DateUtils.fillDateAndTime(minDate, CabBookingDetailsConstant.CAR_AMENDMENT_DATE_DISPLAY);
      const dateUnavailableText = this.amendPolicy.clientDetails.availableDatesText ? this.amendPolicy.clientDetails.availableDatesText :
          `Please choose dates between ${minDateDisplay} and ${maxDateDisplay}`;

      if (!this.amendData.dateChangeData) {
        showShortToast('Please pick a new Date');
        return;
      }

      if (this.amendData.dateChangeData.newPickupDate > maxDate ||
            this.amendData.dateChangeData.newPickupDate < minDate) {
        showShortToast(dateUnavailableText);
        CabBookingTrackingHelper.trackClickEvent(CabBookingDetailsConstant.CAB_AMEND_DETAILS_PAGE, CabBookingDetailsConstant.CLICK_SELECT_PICKUPDATES);
      } else {
        CabBookingTrackingHelper.trackClickEvent(CabBookingDetailsConstant.CAB_AMEND_DETAILS_PAGE, CabBookingDetailsConstant.CLICK_SELECT_PICKUPDATES);
        Actions.cabReviewAmendment({
          response: this.props.response,
          amendData: this.amendData
        });
      }
    }

    async fetchCabAmendPolicy() {
      try {
        const hasNetwork = await isNetworkAvailable();
        if (!hasNetwork) {
          this.setState({viewState: ViewState.NO_INTERNET});
          return;
        }
        const url = NetworkUtils.CAB_AMENDMENT_POLICIES_URL + this.props.response.bookingId;
        const userInfo = await HotelBookingModule.getRequestHeader(this.props.response.bookingId, CabBookingDetailsConstant.CHECK_CAB_AMEND_SERVICE);
        const commonHeaders = await NetworkUtils.getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo);
        const response = await
          fetch(url, {headers: commonHeaders});

        if (response && response.ok) {
          const body = await
            response.json();
          this.amendPolicy = body;
          CabBookingTrackingHelper.trackLoadEvent(CabBookingDetailsConstant.CAB_AMEND_DETAILS_PAGE, this.props.response);
          if (this.amendPolicy.success && this.amendPolicy.amendAllowed) {
            this.setState({viewState: ViewState.SHOW_DETAIL});
          } else {
            this.errorMessage = this.amendPolicy.responseText;
            this.errorSubText = this.amendPolicy.clientDetails ? this.amendPolicy.clientDetails.errorSubText : undefined;
            this.setState({viewState: ViewState.ERROR});
          }
        } else {
          this.setState({viewState: ViewState.ERROR, apiError: true});
          this.trackError();
        }
      } catch (error) {
        this.trackError();
        this.setState({viewState: ViewState.ERROR, apiError: true});
      }
    }

    trackError = () => {
      CabBookingTrackingHelper.trackErrorEvent('mob: cabs: fetch amendmentPolicy page:Error');
    }

    renderNoNetworkView = () => (
      <NoInternetView
        onRetry={() => {
                this.setState({viewState: ViewState.LOADING});
                this.fetchCabAmendPolicy();
            }}
      />);

    rendorError = () => (
      <CabAmendFullScreenPage
        headerText={CabBookingDetailsConstant.CHANGE_PICKUP_HEADER}
        errorText={this.state.apiError ? CabBookingDetailsConstant.DEFAULT_ERROR_MESSAGE : this.errorMessage}
        errorSubText={this.state.apiError ? undefined : this.errorSubText}
        btnText={this.state.apiError ? CabBookingDetailsConstant.TRY_AGAIN : CabBookingDetailsConstant.CANCEL_CAB}
        btnClickHandler={this.state.apiError ? () => {
            this.setState({viewState: ViewState.LOADING});
            this.fetchCabAmendPolicy();
        } : () =>{this.handleCancellationRequest()}}
        headerBackButton={super.onBackIconPress}
        fromCity={this.props.response.carBookingInfo.carDetail.fromCity}
        toCity={this.props.response.carBookingInfo.carDetail.toCity}
      />);

  handleCancellationRequest = () => {
    Actions.cabCancellationDetails({
      response: this.props.response
    });
    CabBookingTrackingHelper.trackClickEvent(CabBookingDetailsConstant.CAB_AMEND_DETAILS_PAGE, CabBookingDetailsConstant.CLICK_CANCEL_BOOKING_FOR_NOAMENDMENT);
  };
}

CabChangePickupDetails.propTypes = {
  response: PropTypes.object.isRequired
};

export default CabChangePickupDetails;
