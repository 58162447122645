import React, {useState, useRef} from 'react';
import {View, Image, StyleSheet, I18nManager, FlatList, SafeAreaView, TouchableOpacity, Platform} from 'react-native';
import { getScreenWidth } from '../../../utils/PostSaleUtil';
import { HOTEL_DEFAULT_ICON } from '../../HotelImageConstants';
import { isNullOrEmpty } from '../utils/HotelBookingDetailUtil';
import { useTheme } from '../../../theme/theme.context';

const SliderImages = ({imageList, showFullWidth}) => {
  const windowWidth = getScreenWidth();
  const { psTheme: theme } = useTheme();
  const itemWidth = showFullWidth ? windowWidth : windowWidth - 24;
  const styles = getStyles(theme, itemWidth, windowWidth);
  const [activeIndex, setActiveIndex] = useState(0);
  const flatListRef = useRef(null);
  
  const BULLET_COUNT = 5;

  const renderImg = ({item}) => {
    return (
      <View style={styles.carousalContainer}>
        <Image
          style={styles.image}
          resizeMethod="resize"
          source={item == null ? HOTEL_DEFAULT_ICON : {uri: item}}
        />
      </View>
    );
  }

  const onViewableItemsChanged = useRef(({ viewableItems }) => {
    if (viewableItems.length > 0) {
      setActiveIndex(viewableItems[0].index);
    }
  }).current;

  const viewabilityConfig = {
    itemVisiblePercentThreshold: 50,
  };

  const scrollToIndex = (index) => {
    flatListRef.current.scrollToIndex({ index, animated: true });
    setActiveIndex(index);
  };

  const getItemLayout = (data, index) => {
    const offset = itemWidth * index;
    return {
      length: itemWidth,
      offset: I18nManager.isRTL && Platform.OS === 'ios' ? (itemWidth * (data.length - 1 - index)) : offset,
      index,
    };
  }

  const onScrollToIndexFailed = (info) => {
    console.error('Scroll to index failed', info);
  };

  const getBulletRange = () => {
    const start = Math.max(0, activeIndex - Math.floor(BULLET_COUNT / 2));
    const end = Math.min(imageList.length - 1, start + BULLET_COUNT - 1);
    return { start, end };
  };

  const { start, end } = getBulletRange();

  if(isNullOrEmpty(imageList)) {
    return null;
  }
  
  return (
    <SafeAreaView style={styles.container}>
      {Platform.OS === 'web' ? (
        <FlatList
          ref={flatListRef}
          data={imageList}
          renderItem={renderImg}
          keyExtractor={(_, index) => index.toString()}
          horizontal
          pagingEnabled
          showsHorizontalScrollIndicator={false}
          getItemLayout={getItemLayout}
          onScrollToIndexFailed={onScrollToIndexFailed}
          contentContainerStyle={styles.contentContainer}
        />
      ) : (
        <FlatList
          ref={flatListRef}
          data={imageList}
          renderItem={renderImg}
          keyExtractor={(_, index) => index.toString()}
          horizontal
          pagingEnabled
          showsHorizontalScrollIndicator={false}
          onViewableItemsChanged={onViewableItemsChanged}
          viewabilityConfig={viewabilityConfig}
          getItemLayout={getItemLayout}
          onScrollToIndexFailed={onScrollToIndexFailed}
          contentContainerStyle={styles.contentContainer}
        />
      )}

      <View style={styles.bulletsContainer}>
        {imageList.slice(start, end + 1).map((_, index) => (
          <TouchableOpacity
            key={start + index}
            style={[styles.bullet, start + index === activeIndex && styles.activeBullet]}
            onPress={() => scrollToIndex(start + index)}
          />
        ))}
      </View>
    </SafeAreaView>
  );
};

const getStyles = (theme, itemWidth, windowWidth) => {
  return StyleSheet.create({
    container: {
     flex: 1,
     height: 270,
     width: '100%',
    },
    contentContainer: {
      flexDirection: I18nManager.isRTL ? 'row-reverse' : 'row',
      alignItems: 'flex-start',
    },
    carousalContainer: {
      width: itemWidth,
      height: 250,
      marginBottom:12,
    },
    fullWindowWidth: {
      width: windowWidth,
    },
    marginWindowWidth: {
      width: windowWidth - 24,
    },
    image: {
      height: '100%',
      width: '100%',
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
    },
    bulletsContainer: {
      flexDirection: I18nManager.isRTL ? 'row-reverse' : 'row',
      justifyContent: 'center',
      marginVertical: 20,
    },
    bullet: {
      width: 10,
      height: 10,
      borderRadius: 5,
      backgroundColor: theme.color.grey8,
      marginHorizontal: 5,
    },
    activeBullet: {
      backgroundColor: theme.color.black,
    },
  });
}

export default SliderImages;