import { StyleSheet } from 'react-native';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#e7e7e7',
    flex: 1,
  },
  roomCardWrapper: {
    paddingVertical: 8,
  },
  dividor: {
    borderBottomWidth: 1,
    borderColor: '#d4daea',
    marginHorizontal: 12,
  },
  roomDetailList: {
    paddingHorizontal: 22,
  },
  sizeIcon: {
    width: 14,
    height: 14,
    marginRight: 10,
  },
  bedIcon: {
    width: 15,
    height: 10,
    marginRight: 10,
  },
  gardenIcon: {
    width: 16,
    height: 13,
    marginRight: 10,
  },
  listHeading: {
    marginBottom: 20,
    paddingHorizontal: 16,
    flex: 1,
  },
  facilityList: {
    paddingHorizontal: 22,
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  listWidth: {
    width: '47%',
    marginBottom: 20,
  },
  timerIcon: {
    width: 16,
    height: 16,
    marginRight: 10,
  },
  topStickySection: {
    ...getPlatformElevation(2),
    backgroundColor: '#fff',
    zIndex: 10,
  },
});
export default styles;
