import React from 'react';
import { View, Text } from 'react-native';
import styles from './SpecialClaimBottomSheetScreensCss';
import { isEmptyArray, isNullOrEmpty } from '../../../../Common/commonUtil';
import { getFont } from '../../../../PostSalesStyles';

interface RequestProcessCardProps {
  trackerInfo: {
    title: string;
    entries: Array<{ title: string }>;
  };
  footerText: string;
  footerSubText: Array<string>;
}

const RequestProcessCard: React.FC<RequestProcessCardProps> = ({ trackerInfo, footerText, footerSubText }) => {
  const { title, entries } = trackerInfo;
  const { fsStyle, ...fonts } = getFont(true);
  return (
    <View style={[styles.innerWrapper]}>
      <View style={[styles.processCardWrapper]}>
        {!!title && (
          <Text style={[styles.headingTxt, fonts.blackFontFamily, fsStyle.font16]}>{title}</Text>
        )}
        {!isEmptyArray(entries) &&
          entries.map((entry, index) => (
            <View key={index} style={styles.processRow}>
              <View style={styles.circle}>
                <View style={styles.circleInner}>
                  <Text style={[styles.circleCounter, fonts.blackFontFamily, fsStyle.font12]}>
                    {index + 1}
                  </Text>
                </View>
              </View>
              <View
                style={[
                  styles.refundProcessRow,
                  index === entries.length - 1 ? styles.borderLstItem : null,
                ]}
              >
                <Text style={[styles.titleStyle, fsStyle.font14]}>{entry.title}</Text>
              </View>
            </View>
          ))}
      </View>
      <View style={styles.moreDtlsSection}>
        {!isEmptyArray(footerSubText) && (
          <Text style={[styles.infoTxtColor, fsStyle.font14]}>
            {!isNullOrEmpty(footerText) && <Text style={[styles.infoTxtBold]}>{footerText}</Text>}
            {footerSubText[0]}
          </Text>
        )}
      </View>
    </View>
  );
};

export default RequestProcessCard;
