import React, { useState } from 'react';
import { ActivityIndicator, StyleSheet, Text, TextStyle, TouchableOpacity } from 'react-native';
import { ButtonProps } from './types';
import LinearGradient from 'react-native-linear-gradient';
import { standardGradients } from '../../base/colors';
import { buttonStyle } from '../../base/textStyles';

interface PrimaryButtonProps extends ButtonProps {
  disabled?: boolean;
  textStyle?: TextStyle;
  gradientColors?: string[];
  showLoader?: boolean;
}

function PrimaryButton(props: PrimaryButtonProps) {
  const {
    text,
    containerStyle,
    disabled,
    onPress,
    textStyle,
    gradientColors,
    showLoader = false,
  } = props;
  useState();
  return (
    <LinearGradient
      style={[styles.container, containerStyle]}
      start={{ x: 0.0, y: 1.0 }}
      end={{ x: 1.0, y: 1.0 }}
      colors={disabled ? standardGradients.disabled : gradientColors || standardGradients.blueCta}
    >
      <TouchableOpacity style={styles.touchable} onPress={onPress} disabled={disabled}>
        {showLoader && <ActivityIndicator size="small" color="white" />}
        {!showLoader && <Text style={[buttonStyle, styles.text, textStyle]}>{text}</Text>}
      </TouchableOpacity>
    </LinearGradient>
  );
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 4,
    alignSelf: 'stretch',
    height: 44,
    marginHorizontal: 8,
    marginVertical: 16,
    shadowRadius: 7,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowColor: '#350000',
    elevation: 1,
    zIndex: 4,
  },
  touchable: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    borderRadius: 14,
    paddingVertical: 12,
    fontWeight:'900',
  },
});

export default PrimaryButton;
