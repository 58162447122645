import React, { useState } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import createStyles from './style';
import { useTheme } from '../../../../../../../theme/theme.context';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import isEmpty from 'lodash/isEmpty';
import HTMLView from 'react-native-htmlview';

const VisatypeRow = ({ item, index, handleVisaType, activeVisa }) => {
  const { psTheme: theme } = useTheme();
  const fonts = getFont();
  const styles = createStyles(theme);
  const activeVisaState = index === activeVisa;
  const activeOuterStyle = activeVisaState ? 'radioActive' : '';
  const {name, description} = item || {};
  return (
    <View style={styles.visaTypeRowCard}>
      <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween, AtomicCss.alignCenter]}>
        <TouchableOpacity
          style={[AtomicCss.flexRow, AtomicCss.flex1]}
          onPress={() => handleVisaType(index)}
        >
          <View style={[styles.radio, styles[activeOuterStyle]]}>
            {activeVisaState ? <View style={styles.radioCircle} /> : null}
          </View>
          {!isEmpty(name) && <Text
            style={[
              { color: theme.color.black, fontSize: fonts.fontSize.font14 },
              fonts.blackFontFamily,
            ]}
          >
            {name}
          </Text>}
        </TouchableOpacity>
      </View>
      {!isEmpty(description) && <View style={styles.visaTypeMoreInfo}>
        <HTMLView
        value = {description} />
      </View>}
    </View>
  );
};

const VisaTypeCard = ({ visaType, onSelectIndex }) => {
  const fonts = getFont();
  const { psTheme: theme } = useTheme();
  const [activeVisa, setActiveVisa] = useState(0);
  const handleVisaType = (active) => {
    setActiveVisa(active);
    onSelectIndex(active);
  };
  const {label, type} = visaType || {};
  return (
    <>
      {!isEmpty(label) && (<Text
        style={[
          {
            color: theme.color.defaultTextColor,
            fontSize: fonts.fontSize.font14,
            fontFamily: fonts.boldFontFamily.fontFamily,
          },
          AtomicCss.marginBottom12,
        ]}
      >
        {label}
      </Text>)}
      {!isEmpty(type) &&
        type.map((item, index) => (
            <VisatypeRow
              item={item}
              index={index}
              key={item.visaTypeId}
              handleVisaType={handleVisaType}
              activeVisa={activeVisa}
            />
          )
        )}
    </>
  );
};

export default VisaTypeCard;
