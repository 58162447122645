const DefaultTheme = {
      primary: '#008cff',
      background: 'rgb(255,0,0)',
      card: 'red',
      text: '#FFFFFF',
      border: 'red',
      notification: 'red',
      topCardText:'#4a4a4a',
      lightText:'#000',
      rightArrowTintColor:'#008cff',
      gradientBtnColor:['#065af3', '#53b2fe'],
      whiteBtnTxt:'#008cff',
      WhiteFullCapsuleText: '#26329F',
      linkTextColor: '#008cff',
      loaderColor: '#008cff',
      radioColor: '#008cff',
      postSalesLoaderColor: '#008b8b',
      RectFullButtonText:'#008cff',
      RectFullButtonBackground:'#FFFFFF',
      CapsuleBtnFillColors: ['#065af3', '#53b2fe'],
      CapsuleBtnFillTextColor:'#FFFFFF',
      viewMoreText:'#008cff',
      actionTitleColor:'#008cff',
      activeTabColor:'#008cff',
      FullWidthButtonGradientColor:['#065af3', '#53b2fe'],
      CapsuleBtnTextColor:'#008cff',
      whiteButtonBorderColor:'#008cff',
      rectFullBlueButtonGradientColor:['#065af3', '#53b2fe'],
      redirectLinkTextColor: '#008cff',
      blueIconbg: '#008cff',
      lightBlueIconbg:'#7195C8',
      roundBtnBlueText: '#ffffff',
      roundBtnLightBlueText:'#008cff',
      blueRoundBtnBg: '#008cff',
      lightBlueRoundBtnBg: '#eaf5ff',
      roundBtnBlueIconBg: '#008cff',
      roundBtnWhiteIconBg: 'white',
      confirmedWithCompletePaymentStartColor: '#c2f4e9',
      confirmedWithCompletePaymentEndColor: '#c2f4e9',
      confirmedWithPartialPaymentStartColor: '#e8f0ff',
      confirmedWithPartialPaymentEndColor: '#e8f0ff',
      cancelledStartColor: '#fbedcf',
      cancelledEndColor: '#fbedcf',
      failedStartColor: '#fedcde',
      failedEndColor: '#fedcde',
      completeStartColor: '#e7e7e7',
      completeEndColor: '#e7e7e7',
      onTripStart: '#c2f4e9',
      onTripEnd: '#c2f4e9',
      topCardFontColor1:'#4a4a4a',
      topCardFontColor2:'#1a7971',
      Visa_blueStartColor:'#3a7bd5',
      Visa_blueEndColor:'#00d2ff',
      Visa_OrangeStartColor:'#ff7f3f',
      Visa_OrangeEndColor:'#ff3e5e',
      Visa_GreenStartColor:'#219393',
      Visa_GreenEndColor:'#43e1a8',
      Visa_purpleStartColor:'#912086',
      Visa_purpleEndColor:'#3023ae',
      Visa_blackStartColor: '#928dab',
      Visa_blackEndColor: '#1f1c2c',
      Visa_pinkStartColor: '#f5515f',
      Visa_pinkEndColor: '#9f0469',
      Visa_YellowStartColor: '#f3d452',
      Visa_YellowEndColor: '#f09819',
      Visa_greyPinkStartColor: '#ddd6f3',
      Visa_GreyPinkEndColor: '#faaca8',
      Visa_lightblueStartColor:'#5f88ff',
      Visa_lightblueendColor: '#a768ff',
      blueStartColor: '#5f88ff',
      blueEndColor: '#a768ff',
      blackStartColor: '#1f1c2c',
      blackEndColor: '#928dab',
      pinkStartColor: '#912086',
      pinkEndColor: '#3023ae',
      rectFullButtonBgColor: '#ffffff',
      myBizGradientColor: ['#f85a6e', '#f27237'],
      insuranceTagGradientColor: ['#C06D0C', '#F2C21A']
  };
  export default DefaultTheme;
