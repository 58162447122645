import React from 'react';
import {View, Text, StyleSheet} from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from '@mmt/legacy-commons/Common/Components/Card/getPlatformElevation'


export default class refundDetails extends React.Component {

    constructor() {
        super();
        this.state = {
            active: true
        }
    }

    handleClick = () => {
        this.setState({active: !this.state.active})
    }


    render() {
        return (
            <View style={[styles.whiteWrapper]}>
                {this.fetchRefundInfo(this.props.response.refundFAQ)}
            </View>
        )
    }

    fetchRefundInfo = function (refundInfo) {
        let views = [];
        Object.keys(refundInfo).map(key => {
            views.push(
                <View style={[AtomicCss.flexRow, AtomicCss.marginBottom10]}>
                    <View style={styles.bullet}/>
                    <Text
                        style={[AtomicCss.flex1, AtomicCss.defaultText, AtomicCss.font14, AtomicCss.regularFont, AtomicCss.lineHeight20]}>
                        {refundInfo[key]}</Text>
                </View>
            )
        })
        return views;
    }
}

const styles = StyleSheet.create({
    whiteWrapper: {
        padding: 16,
        backgroundColor: '#ffffff',
        ...getPlatformElevation(2),
        marginTop: -2,
        borderBottomColor: '#ddd',
        borderBottomWidth: 1,
    },
    heighLitedTxt: {
        fontFamily: fonts.bold,
        color: '#1a7971'
    },
    bullet: {
        width: 6,
        height: 5,
        borderRadius: 5,
        backgroundColor: '#4a4a4a',
        marginRight: 10,
        top: 7,
    },
})


