import React from 'react';
import PropTypes from 'prop-types';
import getStyles from '../ActionBtnCss';
import BaseButton, { BUTTON_VARIANT } from '../BaseButton';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';


const CapsuleBtnFill = ({ action, clickHandler,isThrottleRequired= false, throttleInterval = 1000 }) => {

  const fonts = getFont(true); 
  const styles = getStyles();

  return (<BaseButton
    variant={BUTTON_VARIANT.CAPSULE}
    text={action.actionLabeltext}
    clickHandler={() => clickHandler(action)}
    buttonStyle={[styles.CapsuleBtnFill, AtomicCss.marginBottom10]}
    textStyle={[AtomicCss.whiteText, fonts.boldFontFamily, AtomicCss.alignSelfCenter, fonts.font14]}
    isThrottleRequired={isThrottleRequired}
    throttleInterval={throttleInterval}
  />
  )
};


CapsuleBtnFill.propTypes = {
  action: PropTypes.object.isRequired,
  clickHandler: PropTypes.func.isRequired
};
export default CapsuleBtnFill;
