import React from 'react';
import PropTypes from 'prop-types';
import {Image, Text, View} from 'react-native';
import getStyles from './HotelMamiCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {
  fillCheckInCheckOutData, getActionButton, handleClickEvent,
  isNotNullAndEmpty, isUpcomingBooking
} from '../utils/HotelBookingDetailUtil';
import { CALENDAR_ICON, MOON_CLOUD_ICON } from '../../HotelImageConstants';
import { getStaticData } from '../../../staticData/staticData';
import _ from 'lodash';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import Persuasion from '@Frontend_Ui_Lib_App/Persuasion';

class HotelStayDatesCard extends React.Component {
  constructor(props) {
    super(props);
    this.fonts = getFont(true);
    this.styles = getStyles(this.fonts);
    this.fsStyle = this.fonts.fsStyle;
    this.state = {
      responseJson: this.props.response
    };
  }

  render() {
    const {
      hotelMainCardText:{
        additionalText:{
          nightsText,
          nightText,
          afterText,
          beforeText,
          yourCheckInText,
          youCheckOutText,
          checkInDetailsText,
          stayingForText,
          stayTimingText
        }
      }
    } = getStaticData(true);
    const { isDayUseBookingNew } = this.state.responseJson;
    const hotelDetailResponse = this.state.responseJson?.hotelBookingInfo;
    const { checkinDetails, checkoutDetails } = hotelDetailResponse || {};
    const { dayUseTimeText, dayUseDurationText, hourText2 } = checkinDetails || {};
    const { hide } = checkoutDetails || {};
    return (
      <View style={[AtomicCss.whiteCardNew, AtomicCss.marginHz12, AtomicCss.borderRadius16]}>

        <View style={this.styles.CardContentNew}>
          <View style={this.styles.ContentPortion}>
            <Text style={[this.styles.blackFont, this.styles.BlackText, this.styles.mar20Bt, AtomicCss.font16, AtomicCss.lineHeight20, AtomicCss.alignLeft]}>
              {_.has(hotelDetailResponse, 'commonFields.stayText2') ? hotelDetailResponse.commonFields.stayText2 :  checkInDetailsText}
            </Text>
            <View style={[AtomicCss.flexRow, AtomicCss.marginBottom12, AtomicCss.alignLeft]}>
              <View style={isDayUseBookingNew ? AtomicCss.width100 : this.styles.TimingBlock}>
                <Text style={[this.styles.mar3Bt, this.styles.font12, this.styles.lineHeight20, isDayUseBookingNew ? this.styles.defaultGrey : {...this.styles.blackColor, ...this.styles.boldFont}]}>{isDayUseBookingNew ? stayTimingText : yourCheckInText}</Text>
                <Text style={[this.styles.boldFont, this.styles.blackColor, this.styles.font16, this.styles.lineHeight20, this.styles.mar3Bt]}>
                  {_.has(hotelDetailResponse, 'checkinDetails.dayDateText') ? hotelDetailResponse.checkinDetails.dayDateText : fillCheckInCheckOutData(hotelDetailResponse.checkInDate, 'ddd, DD MMM')}
                  <Text style={[this.fsStyle.font12, AtomicCss.blackText, AtomicCss.lineHeight16, this.fonts.regularFontFamily]}>
                    &nbsp;&nbsp;{_.has(hotelDetailResponse, 'checkinDetails.year') ? hotelDetailResponse.checkinDetails.year : fillCheckInCheckOutData(hotelDetailResponse.checkInDate, 'YYYY')}
                  </Text>
                </Text>
                {
                  isDayUseBookingNew ? (
                    <Text style={[AtomicCss.blackText, this.fsStyle.font16, this.fonts.blackFontFamily]}>
                      {dayUseTimeText || ''}
                    <View style={[AtomicCss.paddingLeft5]}>
                      <Text style={[this.fsStyle.font14, AtomicCss.defaultText, this.fonts.regularFontFamily]}>{dayUseDurationText || ''}</Text>
                    </View>
                  </Text>
                  ) : (
                    <Text
                    style={[this.styles.font13, this.styles.DefaultText, this.styles.lineHeight20, AtomicCss.midGreyText]}
                  >
                    {hourText2 || `${afterText} ${hotelDetailResponse.checkInTime}`}
                  </Text>
                  )
                }
              </View>

              {!hide && <View style={this.styles.TimingBlock}>
                <Text style={[this.styles.mar3Bt, this.styles.font12, this.styles.lineHeight20, this.styles.boldFont, this.styles.blackColor]}>{youCheckOutText}</Text>
                <Text style={[this.styles.boldFont, this.styles.blackColor, this.styles.font16, this.styles.lineHeight20, this.styles.mar3Bt]}>
                  {_.has(hotelDetailResponse, 'checkoutDetails.dayDateText') ? hotelDetailResponse.checkoutDetails.dayDateText : fillCheckInCheckOutData(hotelDetailResponse.checkOutDate, 'ddd, DD MMM')}
                  <Text style={[this.fsStyle.font12, AtomicCss.blackText, AtomicCss.lineHeight16, this.fonts.regularFontFamily]}>
                    &nbsp;&nbsp;{_.has(hotelDetailResponse, 'checkoutDetails.year') ? hotelDetailResponse.checkoutDetails.year : fillCheckInCheckOutData(hotelDetailResponse.checkOutDate, 'YYYY')}
                  </Text>
                </Text>
                <Text
                  style={[this.styles.font13, this.styles.DefaultText, this.styles.lineHeight20, AtomicCss.midGreyText]}
                >
                  {_.has(hotelDetailResponse, 'checkoutDetails.hourText2') ? hotelDetailResponse.checkoutDetails.hourText2 : `${beforeText} ${hotelDetailResponse.checkOutTime}`}
                </Text>
              </View>}

            </View>
            {isUpcomingBooking(this.props.response) &&
              <Persuasion
                bgColor={['transparent']}
                content={this.props.response.hotelBookingInfo.hotelDetailsList[0].hotelUIFields && this.props.response.hotelBookingInfo.hotelDetailsList[0].hotelUIFields.freeDateChangeText}
                customStyle={{
                  contentStyle: [this.fsStyle.font12, AtomicCss.defaultText, this.fonts.regularFontFamily, AtomicCss.flex1, AtomicCss.lineHeight18],
                  startIconStyle: [this.styles.dateChangeImg],
                  wrapperStyle: [this.styles.dateChangeWrapper]
                }}
                startIcon={CALENDAR_ICON}
              />
            }
          </View>
        </View>

        {this.props.card && isNotNullAndEmpty(this.props.card.actionList) &&
          getActionButton(this.props.card, this.props.response, this.handleClick)}
      </View>
    );
  }

  handleClick = (action) => {
    handleClickEvent(this.props.card, { ...action, actionHandler: this.props.actionHandlerGB }, this.props.response,this.props.pageName);
  };
}

HotelStayDatesCard.propTypes = {
  response: PropTypes.object.isRequired,
  card: PropTypes.object.isRequired,
  pageName:PropTypes.string.isRequired
};
export default HotelStayDatesCard;
