import React from 'react';
import { View, Text, StyleSheet, Image } from 'react-native';
import HTMLView from 'react-native-htmlview';
import { isNonEmpty } from '@mmt/legacy-commons/Common/utils/StringUtils';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const getHtmlStyles = (fonts) => {
  return {
    p: {
      color: colors.defaultTextColor,
      ...fonts.font14,
      fontFamily: fonts.regular,
      lineHeight: 16,
      textAlign: 'left'
    },
    b: {
      ...fonts.font14,
      fontFamily: fonts.bold
    }
  };
}

const HotelDetailCard = ({ hotelName, destination, checkinCheckoutRoomsText, hotelImg}) => {
  const { fsStyle, ...fonts } = getFont(true);
  const htmlStyle = getHtmlStyles(fonts);
  return (
    <View style={styles.hotelCardWrapper}>
      <View style={AtomicCss.flex1}>
        <Text style={[fsStyle.font20, fonts.blackFontFamily, styles.headText, AtomicCss.alignLeft]}>
          {hotelName}
        </Text>
        <Text style={[fsStyle.font14, fonts.regularFontFamily, AtomicCss.midGreyText, AtomicCss.marginBottom16, AtomicCss.lineHeight16, AtomicCss.alignLeft]}>
          {destination}
        </Text>
        {isNonEmpty(checkinCheckoutRoomsText) && <HTMLView 
          value={checkinCheckoutRoomsText}
          stylesheet={htmlStyle}
        />}
      </View> 
      <Image source={hotelImg} style={styles.hotelImgStyle} />
    </View>
  );
};

const styles = StyleSheet.create({
  hotelCardWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 20,
  },
  hotelImgStyle: {
    width: 76,
    height: 76,
    borderRadius: 8,
  },
  headText: {
    color: colors.darkGrey4,
    lineHeight: 24,
    marginBottom: 6,
  }
});

export default HotelDetailCard;
