export const ACTION_BUTTONS = {
  PENDING_SKIPPED: 'pending_skipped',
  PROCEED_TO_BOOK: 'PROCEED_TO_BOOK',
  SKIP: 'skip',
  CTA_BOOK: 'CTA_BOOK',
  SELECT_NEW: 'SELECT_NEW',
  CTA_SKIP: 'CTA_SKIP',
  REJECT: 'REJECT',
  CTA_APPROVE: 'CTA_APPROVE',
  CTA_REJECT: 'CTA_REJECT',
};
