import React, { useState } from 'react';
import { View, Text, TouchableOpacity, Image, ImageSourcePropType, TextStyle, ViewStyle } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { ArrowDown } from '../../../../CabImageConstants';
import CabBookingDetailsConstant from 'apps/post-sales/src/cab/CabBookingDetailsConstant';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import CabBookingTrackingHelper from 'apps/post-sales/src/cab/CabBookingTrackingHelper';
import {getStyle} from "./style"

interface CarBookingInfo {
  newFromCity: string;
  fromCityDesc: string;
  dateTime: string;
  tripTypeText: string;
  hourlyRentalHours: string;
  hourlyRentalDistance: string;
}

interface Props {
  pageName: string;
  carBookingInfo: CarBookingInfo | null;
  isExpanded: boolean;
  expandCarDetail: (expanded: boolean) => void;
}

const HourlyRentalCabDetail: React.FC<Props> = ({ pageName, carBookingInfo, isExpanded, expandCarDetail }) => {
  const {
    newFromCity,
    fromCityDesc,
    dateTime,
    tripTypeText,
    hourlyRentalHours,
    hourlyRentalDistance,
  } = carBookingInfo || {};
  const { pickUpText, extraChargeText } = CabBookingDetailsConstant;

  const { fsStyle, ...fonts } = getFont();
  const { psTheme } = useTheme();
  const styles = getStyle(fonts, psTheme);
  const [bookingInfo, setBookingInfo] = useState(false);

  const handleBookingInfo = (clickEventName: string) => {
    CabBookingTrackingHelper.trackClickEvent(pageName, clickEventName);
    expandCarDetail && expandCarDetail(!bookingInfo);
    setBookingInfo(!bookingInfo);
  };

  return (
    <View style={styles.hourlyRentalWrapper}>
      <View style={styles.rentalTopWrapper}>
        {!!tripTypeText && <Text style={[styles.triptype, AtomicCss.marginBottom5]}>{tripTypeText}</Text>}
        <View style={AtomicCss.flexRow}>
          {!!hourlyRentalHours && <Text style={styles.hrHours}>{hourlyRentalHours}</Text>}
          {!!hourlyRentalDistance && <Text style={[styles.hrDistance, AtomicCss.marginLeft8]}>{hourlyRentalDistance}</Text>}
        </View>
        {!!extraChargeText && <Text style={styles.extraCharge}>{extraChargeText}</Text>}
      </View>
      <View>
        {!bookingInfo && (
          <>
            <TouchableOpacity
              style={[AtomicCss.flexRow, AtomicCss.marginBottom5]}
              onPress={() => handleBookingInfo(CabBookingDetailsConstant.LOCATION_EXPEND_CLICK)}
            >
              {!!pickUpText && <Text style={styles.pickUpText}>{pickUpText}</Text>}
              <View style={[styles.arrowStyleWrapper]}>
                <Image style={styles.arrowstyle} source={ArrowDown as ImageSourcePropType} />
              </View>
            </TouchableOpacity>
            {!!fromCityDesc && <Text
              numberOfLines={1}
              style={[styles.fromCityDesc, AtomicCss.marginBottom3, AtomicCss.marginRight30]}
            >
              {fromCityDesc}
            </Text>}
            {!!dateTime && <Text style={styles.dateTime}>{dateTime}</Text>}
          </>
        )}
        {bookingInfo && (
          <>
            <TouchableOpacity
              style={[AtomicCss.flexRow, AtomicCss.marginBottom5]}
              onPress={() => handleBookingInfo(CabBookingDetailsConstant.LOCATION_COLLAPS_CLICK)}
            >
              {!!pickUpText && <Text style={styles.pickUpText}>{pickUpText}</Text>}
              <View style={[styles.arrowStyleWrapper, styles.activeArrow]}>
                <Image style={styles.arrowstyle} source={ArrowDown as ImageSourcePropType} />
              </View>
            </TouchableOpacity>
            {!!newFromCity && <Text
              numberOfLines={1}
              style={[styles.fromCityDesc, AtomicCss.marginBottom3, AtomicCss.marginRight30]}
            >
              {newFromCity}
            </Text>}

            {!!fromCityDesc && <Text style={[styles.fromCityDesc2, AtomicCss.marginBottom8]}>{fromCityDesc}</Text>}
            {!!dateTime && <Text style={styles.dateTime}>{dateTime}</Text>}
          </>
        )}
      </View>
    </View>
  );
};

export default HourlyRentalCabDetail;
