export const CANCELLATION_STEPS = {
    VIEW_REFUND: 1,
    REFUND_MODE: 2,
    CANCELLATION_REASON: 0
}

export const CANCELLATION_REASON = {
    CHANGE_DATE: "change_date",
    ADD_GUEST: "add_guest",
    CHANGE_NAME: "change_name",
    PROCEED_CANCEL:"proceed_cancel"
}

export const BOTTOM_OVERLAY_STATES = {
    CONFIRM_TO_CANCEL: 'CONFIRM_TO_CANCEL',
    CANCELLED_SUCCESS: 'CANCELLED_SUCCESS',
    CANCELLATION_POLICY: 'CANCELLATION_POLICY',
    CANCEL_LOADER: 'CANCEL_LOADER',
    CANCELLATION_ERROR: 'CANCELLATION_ERROR',
    TCS_OVERLAY: 'TCS_OVERLAY',
    BANK_OFFER_OVERLAY: 'BANK_OFFER_OVERLAY',
    FREE_CANCELLATION_OVERLAY: 'FREE_CANCELLATION_OVERLAY',
    CANCELLATION_REASON_CONFIRM_OVERLAY: "CANCELLATION_REASON_CONFIRM_OVERLAY"
};

export const MAX_STEPS_IN_CANCELLATION = 3;

export const REFUND_TO_CUSTOMER = 'RefundToCustomer';

export const CANCELLATION_ACTION = {
    SUBMIT_CLAIM_REFUND: 'submit_claim_refund',
    SUBMIT_CLAIM_LATER: 'submit_claim_later',
    KEEP_BOOKING: 'keep_booking',
    CANCEL_BOOKING: 'cancel_booking',
}