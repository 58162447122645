import React from 'react';
import PropTypes from 'prop-types';
import Actions from '../../../../../../navigation/postSalesNavigation';
import _ from 'lodash';
import { Text, View, Image, ScrollView, FlatList, Platform, BackHandler } from 'react-native';
import Header from '../../../components/Header';
import OverlayMessage from '@mmt/legacy-commons/Common/Components/MIMABottomOverlay/OverlayMessage';
import MimaBottomOverlayDtls from '@mmt/legacy-commons/Common/Components/MIMABottomOverlay/MimaBottomOverlayDtls/index';
import ErrorView from './ErrorView';
import RescheduleFareConfirmationOverlay from './RescheduleFareConfirmationOverlay';
import createStyles from '../css/FlightListingCss';
import FlightListItem from './FlightListItem';
import { DcViewState } from '../../../constants';
import DateChangeBaseClass from '../../../components/DateChangeBaseClass';
import FlightListingTrackingHelper from '../../../analytics/FlightListingTrackingHelper';
import FooterFilters from './FooterFilters';
import FilterOverlay from './FlightListingFilter/FilterOverlay';
import { getInitFilters } from 'core-ui-lib/flight/common/ODC/listingHelper';
import { showShortToast } from '@mmt/legacy-commons/Common/Components/Toast';
import RtFilterFooter from './RtFilterFooter';
import InformationCard from '../../../../../../Common/InformationCard';
import { openErrorPage, checkMyPartner } from '../../../../../../utils/PostSaleUtil';
import { isCorporateUser } from '@mmt/legacy-commons/Native/UserSession/UserSessionModule';
import { LOBNAMES } from '../../../../../../PostSalesConstant';
import { getStaticData } from '../../../../../../staticData/staticData';
import { getListingContentTitle, getErrorDetails, getSteps } from '../../../utils';
import { ThemeContext } from '../../../../../../theme/theme.context';
import { getFont } from '../../../../../../PostSalesStyles';
import ImportantInfoCard from '../../../../../../flights-funnel/Listing/Components/ImportantInfo/ImportantInfoCard';
import ImportantInfoBottomSheet from '../../../../../../flights-funnel/Listing/Components/ImportantInfo/ImportantInfoBottomSheet';
import CalendarStrip from '../../../../../../Common/CalendarStrip';
import Breadcrumb from '../../../../components/Breadcrumb';
import DateChangeTrackingHelper from '../../../analytics/DateChangeTrackingHelper';

const airplaneImg = require('@mmt/legacy-assets/src/plane_icon.webp');

export default class FlightListing extends DateChangeBaseClass {
  static contextType = ThemeContext;
  constructor(props) {
    super(props, 'flightListing', '');
    this.state = {
      activeStep: 1,
      cancellationOverlay: false,
      fareConfirmationOverlay: false,
      showFilters: false,
      showToast: true,
      differentSupplier: false,
      importantInformationOverlay: false,
    };
    this.currSort = '';
    this.currSortOrder = 0;
    this.currFltrObj = {};
    this.activeTab = 0;
    this.showLoader = true;
    this.headerInfo = {};
  }

  toggleFilters = (sortType, sortOrder, currFltrObj, activeTab) => {
    if (activeTab !== -1) {
      this.setState({
        showFilters: !this.state.showFilters,
      });
    }
    if (this.state.showFilters || activeTab === -1) {
      if (sortType === 'price' || sortType === 'departure' || sortType === 'duration') {
        this.currSort = sortType;
        this.currSortOrder = sortOrder;
      }
      if (currFltrObj) {
        this.currFltrObj = currFltrObj;
        this.props.filterListing(
          this.props.response,
          this.currSort,
          this.currSortOrder,
          currFltrObj,
        );
      }
      this.setState({showToast:true});
    }
    if (activeTab !== undefined) {
      this.activeTab = activeTab;
    }
  };

  componentDidMount = async () => {
    super.componentDidMount();
    BackHandler.addEventListener('hardwareBackPress', this.onBackPressHandler);
    this.isCorporateBooking = await isCorporateUser();
    this.isMyPartnerBooking = checkMyPartner();
    this.fetchData();
  };

  componentWillUnmount() {
    BackHandler.removeEventListener('hardwareBackPress', this.onBackPressHandler);
  }


  onBackPressHandler = () => {
    if (this.state.importantInformationOverlay){
      this.toggleImportantInformationOverlay();
      return true;
    }
    if (this.state.cancellationOverlay){
      this.closeCancelRebookOverlay();
      return true;
    }
    if (this.state.fareConfirmationOverlay) {
      this.closeFareConfirmationOverlay()
      return true;
    }
    if (this.state.showFilters) {
      this.setState({
        showFilters: false,
      });
      return true;
    }
  };

  fetchData(formattedDate) {
    let segmentGroup = [...this.props.segmentGroup];
    if (formattedDate) {
        segmentGroup[0].newDate = formattedDate;
    }
    this.props.fetchSearchData(
      this.props.searchKey,
      this.props.segmentGroup,
      this.props.bookingId,
      this.isCorporateBooking,
      this.isMyPartnerBooking,
      this.props.isCfarBooking,
    );
  }

  onDateSelect = (date) => {
    FlightListingTrackingHelper.trackClickEvent('Mob:Customer support:datechangelisting', 'MI_datechangelisting:calendardateclicked');
    this.fetchData(date);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.refreshListing) {
      this.fetchData();
    }
  }

  render() {
    const fonts = getFont(true);
    const styles = createStyles(this.context.psTheme, fonts);
    return (
      <View
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        {this.renderStateWise(styles)}
      </View>
    );
  }

  onHardBackPress = () => {
    this.goBack();
    return true;
  };

  renderStateWise(styles) {
    const { fetchingResultsText } = getStaticData();
    if (this.props.viewState === DcViewState.LOADING || this.showLoader === true) {
      this.showLoader = false;
      return this.renderProgressView(fetchingResultsText);
    } else if (this.props.viewState === DcViewState.ERROR) {
      return this.renderErrorView();
    } else if (this.props.viewState === DcViewState.SHOW_DETAIL) {
      this.headerInfo = this.props.headerInfo || {};
      this.bookingType = this.props.bookingType;
      this.currFltrObj = getInitFilters(this.props.filterInfo);
      this.response = this.props.response;
    } else if (this.props.viewState === DcViewState.LISTING_API_ERROR) {
      return this.showNewErrorPageFromResponse(this.onBackPressFromErrorPage);
    }
    return this.renderSuccessView(styles);
  }

  showNewErrorPageFromResponse = (backPressHandler) => {
    Actions.pop();
    openErrorPage(
      this.props.errorPageData,
      backPressHandler,
      this.props.errorCode === 'NO_FLIGHTS_FOUND_1011'
        ? this.onBackPressFromErrorPage
        : this.tryAgainClick,
      this.props.bookingId,
      'datechange',
      Actions,
      { 
        psLob: LOBNAMES.FLIGHT, 
        isCorporateBooking: this.isCorporateBooking,
        trackClickableEvent: this.trackClickableEvent
      },
    );
  };

  trackClickableEvent = (ctaName, type) => {
    DateChangeTrackingHelper.trackClickableEvent('datechangelisting', ctaName, this.props.listingErrorResponse, this.isCorporateBooking, type);
  }

  onBackPressFromErrorPage = () => {
    setTimeout(() => {
      this.goBack();
    });
  };
  renderErrorView = () => (
    <View style={[{ width: '100%' }, { flex: 1 }]}>
      <ErrorView
        retry={() => this.tryAgainClick(true)}
        errorDetail={getErrorDetails(this.props.errorCode)}
      />
    </View>
  );

  tryAgainClick = (goBackDirectly) => {
    if (this.props.errorCode === '1011') {
      if (goBackDirectly) {
        this.goBack();
      } else {
        this.onBackPressFromErrorPage();
      }
    } else {
      this.fetchData()
    }
  };

  toggleImportantInformationOverlay = () => {
    this.setState({
      importantInformationOverlay: !this.state.importantInformationOverlay,
      showToast: false,
    });
  }

  renderSuccessView(styles) {
    const cfarMessage = _.get(this.props.cfarInfo, 'title', '');
    const {headerInfo} = this.props;
    const isOneWay = headerInfo?.journeyType?.toUpperCase() === 'ONE WAY';
    const { rescheduleYourFlightText } = getStaticData();

    return (
      <View style={styles.pageWrapper}>
        <Header
          title={rescheduleYourFlightText}
          onBackPress={this.goBack}
        />
        <Breadcrumb activeStep={this.state.activeStep} data={getSteps()} />
        <ScrollView showsVerticalScrollIndicator={false} style={styles.pageScroll}>
          {!_.isEmpty(this.props.message) && (
            <InformationCard
              description={this.props.message}
              cardBackgroundColor={this.context.psTheme.color.creamWhite}
              textColor={this.context.psTheme.color.lightYello}
            />
          )}
          {!_.isEmpty(cfarMessage) && (
            <InformationCard
              description={cfarMessage}
              cardBackgroundColor={this.context.psTheme.color.creamWhite}
              textColor={this.context.psTheme.color.lightYello}
            />
          )}
          <ImportantInfoCard
            handleBottomOverlay={this.toggleImportantInformationOverlay}
            data={this.props.odcImportantInfo}
          />
          <View style={styles.pageScrollTopSpace} />
          <View style={styles.listingHeader}>
            <Image style={styles.planeIcon} source={airplaneImg} />
            <View style={styles.listingHeaderInfo}>
              {this.headerInfo && this._renderHeaderTitle([this.headerInfo.title], styles)}
              {this.headerInfo && (
                <Text style={styles.listingHeaderDesc}>{this.headerInfo.subTitle}</Text>
              )}
            </View>
          </View>
          {isOneWay && !_.isEmpty(this.props.calendarDataList) && (
              <CalendarStrip
                calendarDataList={this.props.calendarDataList}
                onDateSelect={(date) => this.onDateSelect(date)}
                defaultDate={this.props.calendarDataList[2].formattedDate}
              />
          )}
         <View style={styles.pageScrollTopSpace} />
          {this._renderFlastList(styles)}

          <View style={styles.pageScrollBtmSpace} />
        </ScrollView>

        {this.response &&
          !_.isEmpty(this.response.itJrnyLst) &&
          this.response.itJrnyLst.length === 1 && (
            <FooterFilters
              filterInfo={this.props.filterInfo}
              currSort={this.currSort}
              currSortOrder={this.currSortOrder}
              currFltrObj={this.currFltrObj}
              toggleFilters={this.toggleFilters}
              isCorporateBooking={this.isCorporateBooking}
            />
          )}
        {this.response &&
          !_.isEmpty(this.response.itJrnyLst) &&
          this.response.itJrnyLst.length === 2 && (
            <RtFilterFooter
              currSort={this.currSort}
              currSortOrder={this.currSortOrder}
              currFltrObj={this.currFltrObj}
              toggleFilters={this.toggleFilters}
            />
          )}
        {this.state.showFilters && (
          <FilterOverlay
            isCorporateBooking={this.isCorporateBooking}
            currSort={this.currSort}
            currSortOrder={this.currSortOrder}
            currFltrObj={this.currFltrObj}
            toggleFilters={this.toggleFilters}
            filterInfo={this.props.filterInfo}
            activeTab={this.activeTab === -1 ? 0 : this.activeTab}
            currency={this.props.response.custCurrency}
          />
        )}

        {this.state.fareConfirmationOverlay && (
          <OverlayMessage
            CloseOverlay={this.closeFareConfirmationOverlay}
            content={
              <RescheduleFareConfirmationOverlay
                isCorporateBooking={this.isCorporateBooking}
                CloseOverlay={this.closeFareConfirmationOverlay}
                onCancellationContinue={this.goToCancel}
                currency={this.props.response.custCurrency}
                bookingId={this.props.bookingId}
                odcPreviewResponse={this.props.odcPreviewResponse}
                segmentGroup={this.props.segmentGroup}
              />
            }
          />
        )}
        {this.state.importantInformationOverlay && (
          <OverlayMessage
            CloseOverlay={this.toggleImportantInformationOverlay}
            content={
              <ImportantInfoBottomSheet
                handleBottomOverlay={this.toggleImportantInformationOverlay}
                data={this.props.odcImportantInfo}
              />
            }
          />
        )}

        {this.state.cancellationOverlay && (
          <OverlayMessage
            CloseOverlay={this.closeCancelRebookOverlay}
            content={
              <MimaBottomOverlayDtls
                isCorporateBooking={this.isCorporateBooking}
                CloseOverlay={this.closeCancelRebookOverlay}
                ContinuePress={this.goToCancel}
                content={getListingContentTitle(this.state.differentSupplier)}
              />
            }
          />
        )}
      </View>
    );
  }

  _renderFlastList = (styles) => {
    const { flightFoundText } = getStaticData();
    return (
      <View style={[styles.flexRow]}>
        <FlatList
          data={this.props.searchMap}
          extraData={this.state}
          keyboardShouldPersistTaps="handled"
          renderItem={this.renderItem}
          keyExtractor={(item) => item}
        />
        {this.state.showToast &&
          !this.state.showFilters &&
          showShortToast(`${this.props.searchMap.length} ${flightFoundText}`)}
      </View>
    );
  };

  _renderHeaderTitle = (titleArr, styles) => {
    let titleList = titleArr;
    if (Array.isArray(titleList[0])) {
      titleList = titleList[0];
    }
    return titleList.map((title) => <Text style={styles.listingHeaderTitle}>{title}</Text>);
  };

  renderItem = ({ index, item }) => (
    <FlightListItem
      index={index}
      key={item}
      isCorporateBooking={this.isCorporateBooking}
      listItemResponse={item}
      onItemSelect={() => this.onItemSelect(item)}
      bookingType={this.props.bookingType}
      currency={this.props.response.custCurrency}
    />
  );

  goBack = () => {
    this.props.listingLoader();
    Actions.pop();
  };

  goToCancel = () => {
    FlightListingTrackingHelper.trackClickEvent(
      'Mob:Customer support:datechangelisting',
      'MI_datechangelisting:cancelConfirm',
    );
    if (Platform.OS === 'web') {
      Actions.flightBookingDetail({
        BOOKING_ID: this.props.bookingId,
      });
    } else {
      Actions.popTo('flightBookingDetail');
    }
  };

  closeCancelRebookOverlay = () => {
    FlightListingTrackingHelper.trackClickEvent(
      'Mob:Customer support:datechangelisting',
      'MI_datechangelisting:cancelGoback',
    );
    this.setState({
      cancellationOverlay: !this.state.cancellationOverlay,
      showToast: false,
    });
  };

  closeFareConfirmationOverlay = () => {
    this.setState({
      fareConfirmationOverlay: !this.state.fareConfirmationOverlay,
      showToast: false,
    });
  };

  onItemSelect = (listItem) => {
    if (listItem.odc.tdc) {
      this.setState({
        fareConfirmationOverlay: true,
        showToast: false,
        differentSupplier: listItem.odc.differentSupplier,
      });
    } else {
      this.setState({
        cancellationOverlay: true,
        showToast: false,
        differentSupplier: listItem.odc.differentSupplier,
      });
    }
    this.props.updateSelectedItemAction(listItem);
  };
}

FlightListing.propTypes = {
  searchKey: PropTypes.string.isRequired,
  viewState: PropTypes.string.isRequired,
  searchMap: PropTypes.array.isRequired,
  headerInfo: PropTypes.object.isRequired,
  fetchSearchData: PropTypes.func.isRequired,
  updateSelectedItemAction: PropTypes.func.isRequired,
  onCancellationContinue: PropTypes.func.isRequired,
  errorCode: PropTypes.string,
  bookingId: PropTypes.string.isRequired,
  message: PropTypes.string,
};

FlightListing.defaultProps = {
  errorCode: '',
  message: undefined,
};
