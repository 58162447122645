import { Text } from 'react-native';
import React from 'react';
import baseCardStyle from '../../baseCardStyles'
import { getFont } from '../../../../../PostSalesStyles';
const MulticityInfoText = ({ multicityText, vehicleType }) => {
    const { fsStyle, ...fonts}  = getFont(true);
    function getMulticityStyle() {
        return [baseCardStyle.coachInfoText, fonts.regularFont, fsStyle.font13];
    }
    return (
        <Text style={getMulticityStyle()} numberOfLines={1}>
            <Text>{multicityText}</Text>
           {vehicleType && <Text> - {vehicleType}</Text>}
        </Text>
    )
}

export default MulticityInfoText;