import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import {View, Text, Platform, StyleSheet, Image} from 'react-native';
import {fonts, colors} from '@mmt/legacy-commons/Styles/globalStyles';
import {isNetworkAvailable} from '@mmt/legacy-commons/Common/utils/AppUtils';
import getPlatformElevation from './getPlatformElevation';
import {
  getCommonHeaders,
  MIMA_BASE_URL
} from '../../utils/NetworkUtils';
import ProgressView from '../../Common/ProgressView/ProgressView';
import CapsuleFullBlueButton from '../../Common/CapsuleBlueFullButton';
import {successIcon, againIcon, failureIcon} from '../HolidayBookingConstants';
import HolidayBookingTrackingHelper from '../HolidayBookingTrackingHelper';

const ViewState = {
  LOADING: 'loading',
  NO_INTERNET: 'no_internet',
  ERROR: 'error',
  DETAIL: 'detail'
};

const errorMessage = 'Due to some technical issue, we were not able to raise your request. Please try again after some time.';
const errorHeading = 'Request Submission Failed';
const buttonText = 'GO BACK TO BOOKING';

class SubmitRequest extends React.Component {
  static navigationOptions = {
    header: null
  };

  constructor(props) {
    super(props);
    this.state = {
      response: null,
      viewState: ViewState.LOADING
    };
  }

  componentDidMount() {
    this.fetchJSONAsync();
  }

  componentDidUpdate() {
    if (this.state.viewState !== ViewState.LOADING) {

      let status = (this.state.viewState === ViewState.DETAIL) ?
        "Confirmed" : "Error";

      if (this.props.requestType.toUpperCase() === "CANCELLATION") {
        HolidayBookingTrackingHelper.trackClickEvent(
          this.props.pageName,
          "Submit_Cancellation_Request"
        )
      } else if (this.props.requestType.toUpperCase() === "AMENDMENT") {
        HolidayBookingTrackingHelper.trackClickEvent(
          this.props.pageName,
          "Submit_DateChange_Request"
        )
      }

    }
  }

  async fetchJSONAsync() {
    try {
      const hasNetwork = await isNetworkAvailable();
      if (!hasNetwork) {
        this.setState({viewState: ViewState.NO_INTERNET});
        return;
      }
      const response = await
        fetch(`${MIMA_BASE_URL}holidays/createMyDeskTicket`, {
          method: 'POST',
          headers: await getCommonHeaders(this.props.mmtAuth, this.props.loggingTrackInfo),
          body: JSON.stringify(this.myDeskRequest())
        });
      const body = await response.json();
      if (response && response.ok) {
        this.setState({
          response: body,
          viewState: ViewState.DETAIL
        });
      } else {
        this.setState({viewState: ViewState.ERROR});
      }
    } catch (error) {
      this.setState({viewState: ViewState.ERROR});
    }
  }

  myDeskRequest() {
    return {
      bookingId: this.props.bookingId,
      emailId: this.props.emailId,
      mobileNo: this.props.mobileNo,
      requestType: this.props.requestType
    };
  }


  render() {
    return (
      <View style={styles.modalWrapper}>
        {this.state.viewState === ViewState.LOADING && this.renderProgressView()}
        {this.state.viewState === ViewState.DETAIL && this.renderPage()}
        {(this.state.viewState === ViewState.ERROR
          || this.state.viewState === ViewState.NO_INTERNET) && this.renderErrorView()}
      </View>
    );
  }

  renderProgressView = () => (<ProgressView message="Submitting your request..."/>);

  renderPage() {
    return (
      <View style={styles.mainContainer}>
        <View style={styles.messageContent}>
          <Image
            style={styles.messageImg}
            source={this.getIconStyle(this.state.response.statusCode)}/>
          <Text style={styles.messageTitle}>
            {this.state.response.thankyouHeading}
          </Text>
          <Text style={styles.messageDesc}>
            {this.state.response.message}
          </Text>
        </View>
        <View style={[styles.footerBtnWrapper]}>
          {this.goBackToBookingCTA(this.state.response.buttonText)}
        </View>
      </View>
    );
  }

  renderErrorView() {
    return (
      <View style={styles.mainContainer}>
        <View style={[styles.messageContent]}>
          <Image style={styles.messageImg} source={failureIcon}/>
          <Text style={styles.messageTitle}>{errorHeading}
          </Text>
          <Text style={styles.messageDesc}>
            {errorMessage}
          </Text>
        </View>
        <View style={[styles.footerBtnWrapper]}>
          {this.goBackToBookingCTA(buttonText)}
        </View>
      </View>
    );
  }

  goBackToBookingCTA(actionLabeltext) {
    const action = {
      actionLabeltext
    };
    return <CapsuleFullBlueButton
      action={action}
      clickHandler={this.props.onBackPopUp}
    />;
  }

  getIconStyle = (code) => {
    if (code === 1) {
      return failureIcon;
    } else if (code === 2) {
      return againIcon;
    } else if (code === 3) {
      return successIcon;
    }
    return failureIcon;
  };
}

const styles = StyleSheet.create({
  modalWrapper: {
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    position: Platform.OS === 'web' ? 'fixed' :'absolute',
    elevation: 3,
    zIndex: 3,
    width: '100%',
    height: '100%',
    alignItems: 'center',
    paddingBottom: '5%',
    paddingTop: '25%',
    paddingHorizontal: 15
  },
  btnWrapper: {padding: 15},
  dividerWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 50,
    position: 'relative',
    marginBottom: 5
  },
  dividerLine: {
    width: '100%',
    height: 1,
    backgroundColor: '#ccc',
    position: 'absolute'
  },
  orDivider: {
    width: 32,
    height: 32,
    borderRadius: 50,
    borderColor: '#ccc',
    borderWidth: 1,
    backgroundColor: '#f2f2f2',
    justifyContent: 'center',
    alignSelf: 'center'

  },
  buttonWrapper: {
    paddingHorizontal: 10,
    paddingVertical: 15
  },
  card: {
    backgroundColor: '#fff',
    marginBottom: 12,
    padding: 15,
    ...getPlatformElevation(2)
  },
  nameOuter: {
    width: '35%',
    marginRight: '5%',
    top: 0
  },
  nameInput: {
    borderBottomColor: '#c2c2c2',
    borderBottomWidth: 1,
    paddingVertical: 10
  },
  titleOuter: {
    width: 48,
    marginRight: '5%'
  },
  selectBox: {
    borderBottomColor: '#c2c2c2',
    borderBottomWidth: 1,
    paddingVertical: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  selectBoxText: {
    fontFamily: fonts.bold,
    fontSize: 14,
    color: '#4a4a4a'
  },
  selectOptionsOuter: {
    backgroundColor: '#fff',
    position: 'absolute',
    top: 18,
    width: 48,
    paddingLeft: 5,
    borderRadius: 2,
    borderColor: '#f2f2f2',
    borderWidth: 1,
    zIndex: 9999,
    ...getPlatformElevation(2)
  },
  selectOptions: {
    paddingVertical: 4
  },
  arrow: {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderTopWidth: 6,
    borderRightWidth: 5,
    borderBottomWidth: 0,
    borderLeftWidth: 5,
    borderTopColor: '#c2c2c2',
    borderRightColor: 'transparent',
    borderBottomColor: 'transparent',
    borderLeftColor: 'transparent'
  },
  addGuestLabel: {
    ...Platform.select({
      ios: {
        top: 4,
        marginBottom: 6
      },
      android: {
        top: 4,
        marginBottom: 8
      }
    })
  },
  addGuestLabelText: {
    color: '#747474',
    fontSize: 12,
    fontFamily: fonts.regular
  },
  pageWrapper: {
    flex: 1,
    backgroundColor: colors.white,
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '5%',
    paddingTop: '15%',
    paddingHorizontal: 15
  },
  mainContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: (Platform.OS === 'ios') ? 20 : 0
  },
  footerBtnWrapper: {
    paddingHorizontal: 50,
    marginBottom: 35,
    width: '100%',
    bottom: 0
  },
  messageContent: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0
  },
  messageImg: {
    height: 80,
    width: 80,
    marginBottom: 20
  },
  messageTitle: {
    fontSize: 20,
    fontFamily: fonts.bold,
    color: colors.black28,
    marginBottom: 10,
    textAlign: 'center'
  },
  messageDesc: {
    fontSize: 14,
    fontFamily: fonts.regular,
    color: colors.gray,
    textAlign: 'center',
    marginBottom: 15,
    marginTop: 5
  }
});

SubmitRequest.propTypes = {
  bookingId: PropTypes.string.isRequired,
  emailId: PropTypes.string.isRequired,
  mobileNo: PropTypes.string.isRequired,
  requestType: PropTypes.string.isRequired,
  onBackPopUp: PropTypes.func.isRequired,
  mmtAuth: PropTypes.string.isRequired,
  loggingTrackInfo: PropTypes.object.isRequired,
  pageName: PropTypes.string,
};

export default SubmitRequest;
