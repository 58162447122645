import { StyleSheet, Text, View, Image, TouchableOpacity, ViewStyle, TextStyle, ImageStyle } from 'react-native';
import React, { useState, useEffect } from 'react';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import searchIcon from '@mmt/legacy-assets/src/Images/searchIcon.png';
import getPlatformElevation from '../../../../../Holiday/Components/getPlatformElevation';
import { useTheme } from '../../../../../theme/theme.context';
import { ThemeType } from '../../../../../postSalesStylesType';

interface SearchBarProps {
  placeholderList: string[];
  toggleAutoSearchPopup: () => void;
}
interface Styles {
  searchBar: ViewStyle;
  searchText: TextStyle;
  searchIcon: ImageStyle;
  hide: TextStyle;
}

const createStyles = (theme: ThemeType) => StyleSheet.create<Styles>({
  searchBar: {
    borderRadius: 8,
    borderWidth: 1,
    borderColor: theme.color.lightGrey,
    backgroundColor: theme.color.white,
    paddingHorizontal: 8,
    paddingVertical: 6,
    ...getPlatformElevation(1),
    height: 40,
    overflow: 'hidden',
  },
  searchText: {
    position: 'absolute',
    color: theme.color.defaultTextColor,
    width: 250,
  },
  hide: {
    opacity: 0,
  },
  searchIcon: {
    width: 24,
    height: 24,
    marginRight: 8,
  },
});

const SearchBar: React.FC<SearchBarProps> = ({ placeholderList, toggleAutoSearchPopup }) => {

  const [activePlaceholderIndex, setActivePlaceholderIndex] = useState(0);
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme);

  useEffect(() => {
    if (placeholderList?.length > 0) {
      const interval = setInterval(() => {
        setActivePlaceholderIndex(prevIndex =>
          (prevIndex + 1) % placeholderList.length
        );
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [placeholderList]);

  return (
    <TouchableOpacity
      onPress={() => toggleAutoSearchPopup()}
      style={[styles.searchBar, AtomicCss.flexRow, AtomicCss.spaceBetween, AtomicCss.alignCenter]}
    >
      <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
        <Image style={styles.searchIcon} source={searchIcon} />
        <View style={[AtomicCss.textCenterVertical, AtomicCss.marginBottom20]}>
          {placeholderList?.map((placeholder, index) => {
            return (
              <Text
                key={index}
                style={[styles.searchText, index === activePlaceholderIndex ? {} : styles.hide]}
              >
                {placeholder}
              </Text>
            );
          })}
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default SearchBar;


