import React from "react"
import DayPicker, { DateUtils } from 'react-day-picker';
import '../../../../../../web/Assets/css/style.css';
import '../../../../../../web/Assets/css/dayPickerReset.css';
import { View, Text, StyleSheet } from 'react-native'
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss'
import { getStaticData } from "../../staticData/staticData";
import { LANG_AWARE } from "../HotelConstants";
import { LOCALE_MAP } from "../../PostSalesConstant";
import { getUserSelectedLanguage } from '@mmt/legacy-commons/AppState/AppUserConfig';

export default class Calender extends React.Component {

    constructor(props) {
        super(props);
        this.handleResetClick = this.handleResetClick.bind(this);
        this.state = this.getInitialState();
    }

    getInitialState() {
        var today = new Date();

        var tomorrow = new Date(today.getTime() + (1000 * 60 * 60 * 24));
        return {
            from: this.props.from,
            to: this.props.to,
            enteredTo: null, // Keep track of the last day for mouseEnter.
        };
    }

    getResetState() {
        return {
            from: null,
            to: null,
            enteredTo: null, // Keep track of the last day for mouseEnter.
        };
    }

    handleResetClick = () => {
        this.setState(this.getResetState());
    }

    isSelectingFirstDay(from, to, day) {
        const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
        const isRangeSelected = from && to;
        return !from || isBeforeFirstDay || isRangeSelected;
    }

    handleDayClick = (day, { disabled, selected }) => {
        const { from, to } = this.state;
        const { updateDate } = this.props;

        if (disabled) {
            return;
        }

        if (from && to && day >= from && day <= to) {
            this.handleResetClick();
            this.setState({
                from: day,
                to: null,
                enteredTo: null,
            });
        }

        if (this.isSelectingFirstDay(from, to, day)) {
            this.setState({
                from: day,
                to: null,
                enteredTo: null,
            });
            updateDate(day, null);
        } else {
            if(from !== day){
                var dayDiff = Math.ceil((day - from) / (1000 * 60 * 60 * 24)) + 1;

                if (dayDiff > 30) {
                    this.setState({
                        dayDiff
                    })
                } else {
                    this.setState({
                        to: day,
                        enteredTo: day,
                        dayDiff
                    });
                    updateDate(from, day);
                }
            }
        }
    }

    handleDayMouseEnter(day) {
        const { from, to } = this.state;
        if (!this.isSelectingFirstDay(from, to, day)) {
            this.setState({
                enteredTo: day,
            });
        }
    }

    setScroll() {

        let startDate = this.props.from;
        if (startDate && startDate != null) {
            let month;
            if (new Date(startDate).getMonth() >= new Date().getMonth())
                month = new Date(startDate).getMonth() - new Date().getMonth();
            else
                month = 12 + new Date(startDate).getMonth() - new Date().getMonth()

            setTimeout(() => {
                try {

                    let offset = document.getElementsByClassName("DayPicker-Month")[month].offsetTop - 20;
                    document.getElementsByClassName("DayPicker-Months")[0].scrollTop = offset;
                } catch (e) {
                }
            }, 10);
        }
    }

    render() {
        this.setScroll();
        const { from, to, dayDiff } = this.state;
        const modifiers = { start: from, end: to };
        const options = { year: "numeric", month: "short", day: "numeric" };
        const today = new Date();
        const { fromText, toText, canNotBeGreaterText } = this.props;
        const { monthsLongText,  weekDaysLongText, weekDaysShorterText } = getStaticData(LANG_AWARE.MODIFICATION);
        const monthsLong = Object.values(monthsLongText);
        const weekDaysLong = Object.values(weekDaysLongText);
        const weekDaysText = Object.values(weekDaysShorterText);
        return (
            <View style={styles.container}>
                <View style={styles.header}>
                    <View style={styles.headerContent}>
                        <View style={(from && !to) ? [styles.dateFiled, styles.active] : styles.dateFiled}>
                            <Text style={[styles.subHeading, AtomicCss.alignLeft]}> {fromText} </Text>
                            <Text style={[styles.dateContainer,AtomicCss.alignLeft]}>  {!from && '--'} {from && `${from.toLocaleDateString(LOCALE_MAP[getUserSelectedLanguage()] || 'en-GB', options)}`} </Text>
                        </View>
                        <View style={(to) ? [styles.dateFiled, styles.active] : styles.dateFiled}>
                            <Text style={[styles.subHeading, AtomicCss.alignLeft]}> {toText} </Text>
                            <Text style={[styles.dateContainer,AtomicCss.alignLeft]}> {!to && '--'} {to && `${to.toLocaleDateString(LOCALE_MAP[getUserSelectedLanguage()] || 'en-GB', options)}`}</Text>
                        </View>
                    </View>
                </View>
                <View style={{ flex: 1 }}>
                    {dayDiff && dayDiff > 30 && <Text style={[styles.errorText, AtomicCss.alignLeft]} id="range_error">{canNotBeGreaterText} </Text>}
                </View>
                <DayPicker
                    numberOfMonths={13}
                    selectedDays={[from, { from, to }]}
                    modifiers={modifiers}
                    onDayClick={this.handleDayClick}
                    disabledDays={{ before: today }}
                    weekdaysShort={weekDaysText}
                    weekdaysLong={weekDaysLong}
                    months={monthsLong}
                />
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'column',
        flexWrap: 'wrap',
    },
    header: {
        borderBottomColor: '#e7e7e7',
        borderBottomStyle: 'solid',
        borderBottomWidth: 2,
        position: "relative",
        flexDirection: 'row',
        flexWrap: 'wrap',
        flex: 1
    },
    headerContent: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 10,
        flex: 1
    },
    dateFiled: {
        flex: .5,
        paddingLeft: 16,
        marginBottom: -2,
        position: "relative",
    },
    active: {
        borderBottomColor: '#008cff',
        borderBottomStyle: 'solid',
        borderBottomWidth: 3
    },
    subHeading: {
        fontSize: 12,
        color: "#9b9b9b",
        marginTop: 10,
        whiteSpace: 'nowrap'
    },
    dateContainer: {
        fontSize: 20,
        marginVertical: 8,
        fontWeight: "600",
        whiteSpace: 'nowrap'
    },
    errorText: {
        backgroundColor: "#ffd3d4",
        color: "#eb2026",
        width: "100%",
        zindex: 1,
        position: "relative",
        top: 0,
        margin: 0,
        textAlign: "center",
        fontSize: 12,
        fontFamily: "Lato-Bold",
        paddingHorizontal: 16,
        paddingVertical: 4
    }
});
