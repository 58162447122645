import React from 'react';
import {View, Text, Image} from 'react-native';
import PropTypes from 'prop-types';
import Button from '../../../Common/Button';
import getStyles from './OverlayCss';
import AtomicCss from '../../../acme/details/AtomicCss';
import { getStaticData } from '../../../staticData/staticData';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const HotelODCNotAllowedOverlayContent = ({title, message, handleCloseOverlay}) => {
    const { ...fonts } = getFont(true);
    const styles = getStyles(fonts);
    const staticData = getStaticData();
    return (
        <View>
            <Text style={[styles.OverlayTitle, AtomicCss.marginBottom15]} >{title}</Text>
            <View style={[{marginBottom: 30}]}>
                <Text>
                    {message}
                </Text>
            </View>
            <View style={[styles.btnWrapper, AtomicCss.marginBottom10]}>
            <Button
              btnTxt={staticData.closeBtnText}
              btnWidth={200}
              btnBg="b2bGradientBtn"
              clickHandler={handleCloseOverlay}
            />
          </View>
        </View>
    )
};

HotelODCNotAllowedOverlayContent.propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    handleCloseOverlay: PropTypes.object.isRequired
}

export default HotelODCNotAllowedOverlayContent;