import React from 'react';
import PropTypes from 'prop-types';
import { Image, Text, TouchableOpacity, View } from 'react-native';
import styles from './TicketCss';
import {CHKBOX_ICON_ACTIVE, CHKBOX_ICON,SELECTED_PAX_CHECKBOX} from '../MetroImageConstant';

export default class CheckboxRow extends React.Component {

  checkboxHandler() {
    const ticketSelected = !this.props.selected;
    this.props.clickHandler(this.props.ticketData, ticketSelected);
  }

  render() {
    const selectedStyle = this.props.selected ? 'Selected' : '';
    return (
      <TouchableOpacity
        activeOpacity={0.9}
        style={[styles.checkBtn, styles.sectorPaxList]}
        onPress={() => this.checkboxHandler()}
      >
        <View style={[styles.checkbox, styles[`checkbox${selectedStyle}`]]}>
          {this.props.selected &&
            <Image style={styles.checkedIcon}
                   source={ CHKBOX_ICON }
            />
          }
        </View>
        <View>
          <Text style={styles.passengerName}>{`Passenger ${this.props.index}`}</Text>
        </View>
      </TouchableOpacity>
    );
  }
}

CheckboxRow.propTypes = {
  ticketData: PropTypes.object.isRequired,
  clickHandler: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired
};

