import { getItinaryDetails, getSegmentDetails, isAllPaxCancelledForSegment, getCitiesHeading } from "core-ui-lib/flight/common/FlightBookingDetails/utils/flightItinaryUtil";
import {getContactEmaiPhone} from 'core-ui-lib/flight/common/FlightBookingDetails/utils/helper';
import isEmpty  from "lodash/isEmpty";
import FlightConst, {
    SEGMENT_STATUS,
    TopCardType,
  } from "../FlightBookingDetailsConstant";


  export {
    getSegmentDetails,
    getItinaryDetails,
    getContactEmaiPhone,
    isAllPaxCancelledForSegment,
    getCitiesHeading
  };
export const isFareLockExpired = (flightDetailResponse) => {
    if (
      flightDetailResponse &&
      flightDetailResponse.topStatusCard
    ) {
      return (
        flightDetailResponse.topStatusCard.topCardType ===
        TopCardType.FARE_LOCK_EXPIRED
      );
    }
    return false;
  };

  export const   getFailedStatus =(segmentGroupDetail) =>{
    const failedSegment = segmentGroupDetail.segmentDetails.filter(
      (segment) => !isEmpty(segment.pnrNo) &&  segment.pnrNo.toUpperCase() === FlightConst.FAILED
    );
    if (failedSegment && failedSegment.length > 0) {
      return true;
    }

    return false;
  }

  export const  getSegmentStatusStyle =(segmentGroupStatus, styles) =>{
    const status = segmentGroupStatus.status;
    if (
      status === SEGMENT_STATUS.CONFIRMED ||
      status === SEGMENT_STATUS.PARTIALLY_CONFIRMED
    ) {
      return styles.confirmedStatusColor;
    } else if (status === SEGMENT_STATUS.FAILED) {
      return styles.failedStatusColor;
    } else if (status === SEGMENT_STATUS.AWAITED) {
      return styles.awaitedStatusColor;
    } else if (status === SEGMENT_STATUS.CANCELLED) {
      return styles.cancelledStatusColor;
    }
    return "";
  }
