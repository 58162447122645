import React from 'react';
import { View, Text, Image } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import HTMLView from 'react-native-htmlview';
import HotelCard from './hotelCard';
import { useTheme } from '../../../theme/theme.context';
import { getFont } from '../../../PostSalesStyles';
import createStyles, { htmlStyle } from './HotelCardListCss';
import { BLUE_TICK } from '../../commonImageConstants'; 
import { HotelCardListProps } from './hotelType';

const HOTEL_LEN = 3;


const HotelCardList: React.FC<HotelCardListProps> = (props) => {
  const { hotelList, header, omnitureTrackHandler } = props;
  const hotelView = Array(HOTEL_LEN).fill(null);

  const fonts = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);
  const htmlStyles = htmlStyle(theme, fonts);

  for (let i = 0; i < HOTEL_LEN; i++) {
    hotelView[i] = hotelList[i];
  }

  return (
    <View style={[styles.offerCard]}>
      <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
        <Image style={styles.tickIconStyle} source={BLUE_TICK} />
        <HTMLView value={`<p>${header}</p>`} stylesheet={htmlStyles} />
      </View>
      <View style={styles.htlInfoSection}>
        {hotelView.map((hotel, i) => {
          if (!hotel) {
            return null;
          }
          const { name, starRating, mainImages, displayFare, appDeepLink } = hotel;
          const { displayPriceBreakDown } = displayFare || {};
          const { displayPrice, nonDiscountedPrice } = displayPriceBreakDown || {};
          const imgUrl = Array.isArray(mainImages) && mainImages[0] ? mainImages[0] : null;
          return (
            <HotelCard
              heading={name}
              key={i}
              starRating={starRating}
              imageUrl={imgUrl}
              displayPrice={displayPrice}
              nonDiscountedPrice={nonDiscountedPrice}
              deeplink={appDeepLink}
              omnitureTrackHandler={omnitureTrackHandler}
            />
          );
        })}
      </View>
    </View>
  );
};

export default HotelCardList;
