import React from 'react';
import { Image, Text, TouchableOpacity, View, Modal } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import baseCardStyle, {getNeedHelpStripStyle} from './baseCardStyles';
import roundTripArrowIcon from '@mmt/legacy-assets/src/roundtrip_arrow1.webp';
import rightArrowIcon from '@mmt/legacy-assets/src/ic_arrow.webp';
import iconClose from '@mmt/legacy-assets/src/ic_close_black.webp';
import cabInfoIcon from '@mmt/legacy-assets/src/postSales/summary/cabInfoIcon.webp';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getImagePath, getScreenWidth, imageTransformY } from '../../../utils/PostSaleUtil';
import { getStaticData } from "../../../staticData/staticData";
import upcomingBookingTimerIcon from '@mmt/legacy-assets/src/postSales/summary/upcomingFlightTimerIcon.webp';
import completedBookingTimerIcon from '@mmt/legacy-assets/src/postSales/summary/completedFlightTimerIcon.webp';
import cancelledBookingTimerIcon from '@mmt/legacy-assets/src/postSales/summary/cancelledFlightTimerIcon.webp';
import Button from '../../../Holiday/Components/Button';
import { Shimmer, PlaceholderContainerWrapper } from '../../../Common/ShimmerV2';
import { isEmpty } from 'lodash';
import { getFont, ps_colors } from '../../../PostSalesStyles';
import { BookingStates } from '../../summaryConstants';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';

const clockIcon = require('@mmt/legacy-assets/src/ic_clock.webp');

const backIcon = getImagePath('oneWayArrow.webp');

export function SummaryContainer({ children, bookingId }) {
  return (
    <View style={baseCardStyle.container} testID={bookingId}>
      {children}
    </View>
  )
}

export function MainContent({ children, onPress }) {
  return (
    <TouchableOpacity onPress={onPress} activeOpacity={0.7}>
      <View style={baseCardStyle.mainContent}>
        {children}
      </View>
    </TouchableOpacity>
  )
}

export function StaticIcon({ icon, iconStyle = {}, transform = null }) {
  return (
    <View style={baseCardStyle.iconContainer}>
      <Image source={icon} style={[baseCardStyle.staticIcon, iconStyle, transform]} />
    </View>
  );
}

export function FixedIcon({ icon, iconStyle = {} }) {
  return (
    <View style={baseCardStyle.iconContainerFixed}>
      <Image source={icon} style={[baseCardStyle.fixedIcon, iconStyle]} />
    </View>
  )
}

export function DynamicIcon({ iconUrl }) {
  return (
    <View style={baseCardStyle.flightIconContainer}>
      <Image source={iconUrl} style={baseCardStyle.dynamicIcon} />
    </View>
  )
}

export function DescriptionSection({ children }) {
  return (
    <View style={baseCardStyle.descriptionSection}>
      {children}
    </View>
  )
}

export function StatusWithIconBg({ imageStyle, src,textStyle,status,viewStyle }) {
  return (
    <View style={[AtomicCss.flexRow, viewStyle, AtomicCss.paddingAll5, AtomicCss.marginTop10, AtomicCss.borderRadius4]}>
      <Image style={imageStyle} source={src} />
      <Text style={[textStyle, AtomicCss.font11, AtomicCss.boldFont]}>{status}</Text>
    </View>
  )
}
export function StatusText({ text, status, cancelledDate }) {
  const { fsStyle, ...fonts}  = getFont(true);
  if (status === 'CANCELLED') {
    return (
      <Text>
        <Text style={[baseCardStyle.statusCancelled, fonts.boldFontFamily, fsStyle.font14 ]}>Cancelled</Text>
        {" | "}
        <Text style={baseCardStyle.cancelledDate}>{cancelledDate || text}</Text>
      </Text>
    )
  }
  return <Text style={[baseCardStyle.statusConfirmed, fonts.boldFontFamily]}>{text}</Text>;
}

export function NewStatusText({
  text,
  status,
  style = baseCardStyle.statusConfirmed,
}) {
  const { fsStyle, ...fonts}  = getFont(true);
  if (status === 'CANCELLED') {
    style = baseCardStyle.statusCancelled;
  } else if (status === 'COMPLETED') {
    style = baseCardStyle.statusCompleted;
  }
  if (status === 'FAILED') {
    style = baseCardStyle.statusFailed;
  }
  if (status === BookingStates.PENDING) {
    style = baseCardStyle.statusPending;
  }
  return <Text style={[style, fonts.boldFontFamily, fsStyle.font14]}>{text}</Text>;
}

// we have to limit the width of from/to text to max of journeyTextContainer,
// to do this we can calculate the width of container. however it would impact performance of overall list
let spaceTaken = 2 * baseCardStyle.container.marginHorizontal;
spaceTaken += baseCardStyle.iconContainer.width;
spaceTaken += baseCardStyle.iconContainer.margin * 2;
spaceTaken += baseCardStyle.arrowContainer.margin * baseCardStyle.arrowContainer.marginLeft;
spaceTaken += baseCardStyle.journeyDirectionIcon.marginHorizontal * 2;

function getTitleStyle() {
  const { fsStyle, ...fonts}  = getFont(true);
  return [baseCardStyle.title, fonts.blackFontFamily, fsStyle.font16];
}

export function TitleText({ text }) {
  const width = getScreenWidth();
  const maxWidth = width - spaceTaken;
  return <Text style={[...getTitleStyle(), { maxWidth }]}>{text}</Text>;
}

export function JourneyText({ from, to, showRoundTripArrowIcon, numberOfLines, isHourlyRental }) {
  const width = getScreenWidth();
  const maxWidthForTo = (width - spaceTaken) / 1.9;
  const maxWidthForFrom = (width - spaceTaken) / 1.9;
  return (
    <View style={baseCardStyle.journeyTextContainer}>
      <Text style={[...getTitleStyle(), { maxWidth: maxWidthForFrom }]} numberOfLines={numberOfLines || 2}>{from}</Text>
      {!isHourlyRental && (
        <Image
          style={[(showRoundTripArrowIcon ? baseCardStyle.roundTripJourneyDirectionIcon : baseCardStyle.journeyDirectionIcon), imageTransformY]}
          tintColor={colors.black} source={showRoundTripArrowIcon ? roundTripArrowIcon : backIcon}
        />
      )}
      {!isHourlyRental && (
        <Text style={[...getTitleStyle(), { maxWidth: maxWidthForTo }]} numberOfLines={numberOfLines || 2}>{to}</Text>
      )
      }
    </View>
  )
}

export function PaymentInfoCard({ status, paymentDueText }) {
  const gradientCard = {
    pending: ps_colors.blueGradient,
    expire: ps_colors.greyGradient,
    cancelled: ps_colors.yellowGradient,
  };
  return (
      <LinearGradient
      colors={gradientCard[status]}
      start={{ x: 0, y: 0 }}
      end={{ x: 1.0, y: 0 }}
      style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginTop10, baseCardStyle.bookingStatusStyle]}
      >
        <Image source={clockIcon} style={baseCardStyle.clockIcon} />
        <Text style={[AtomicCss.whiteText, AtomicCss.font12, AtomicCss.regularFont]}>
          {paymentDueText}
        </Text>
      </LinearGradient>
    );
}

export function JourneyTextRail({ from, to }) {
  const width = getScreenWidth();
  const maxWidth = (width - spaceTaken) / 2;
  return (
    <View style={baseCardStyle.journeyTextContainer}>
      <Text style={[...getTitleStyle(), { maxWidth }]}>{from}</Text>
      <Image
        style={baseCardStyle.journeyDirectionIcon}
        tintColor={colors.black} source={backIcon}
      />
      <Text style={[...getTitleStyle(), { maxWidth }]}>{to}</Text>
    </View>
  )
}

function getCoachInfoStyle() {
  const { fsStyle, ...fonts}  = getFont(true);
  return [baseCardStyle.coachInfoText, fonts.regularFont, fsStyle.font13];
}

export function CoachInfoText({ text }) {
  return (
    <Text style={getCoachInfoStyle()} numberOfLines={1}>
      {text}
    </Text>
  );
}

export function CabUnconfirmedText() {
  const { fsStyle, ...fonts}  = getFont(true);
  const staticData = getStaticData();
  return (
    <View style={baseCardStyle.otherInfoDtls}>
      <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
        <Image style={baseCardStyle.otherInfoIcon} source={cabInfoIcon} />
        <Text style={[baseCardStyle.otherInfoTxt, fonts.regularFontFamily, fsStyle.font12]}>{staticData.bookingSummaryText.additionalText.enterAirportCabDetailsText}</Text>
      </View>
    </View>
  )
}
export function SelfdriveInfoText({ text }) {
  const { fsStyle, ...fonts}  = getFont(true);
  return <Text style={[baseCardStyle.selfdriveInfoText, fonts.regularFontFamily, fsStyle.font13]}>{text}</Text>;
}

export function FlightInfoText({ text }) {
  return <Text style={getCoachInfoStyle()}>{text}</Text>;
}

export function BusCoachInfoText({ text }) {
  return (
    <Text style={getCoachInfoStyle()} numberOfLines={1} ellipsizeMode={'tail'}>
      {text}
    </Text>
  );
}

export function HotelCityInfoText({ text }) {
  return (
    <Text style={getCoachInfoStyle()} numberOfLines={1} ellipsizeMode={'tail'}>
      {text}
    </Text>
  )
}

export function InsuranceInfoText({ text }) {
  return (
    <Text style={getCoachInfoStyle()} numberOfLines={1} ellipsizeMode={'tail'}>
      {text}
    </Text>
  )
}

export function HolidayDescriptionText({ text }) {
  return (
    <Text style={getCoachInfoStyle()} numberOfLines={1} ellipsizeMode={'tail'}>
      {text}
    </Text>
  )
}

export function ActivityInfoText({ text }) {
  return (
    <Text style={getCoachInfoStyle()} numberOfLines={1} ellipsizeMode={'tail'}>
      {text}
    </Text>
  )
}
export function TrainCoachInfoText({ text }) {
  return (
    <Text style={getCoachInfoStyle()} numberOfLines={1} ellipsizeMode={'tail'}>
      {text}
    </Text>
  )
}

export function GiftCardInfoText({ text }) {
  return (
    <Text style={getCoachInfoStyle()} numberOfLines={1} ellipsizeMode={'tail'}>
      {text}
    </Text>
  )
}

export function PaxRoomInfo({ roomCount, paxName }) {
  return (
    <View style={baseCardStyle.paxRoomInfoContainer}>
      <PaxInfo text={paxName} />
      <View style={baseCardStyle.paxRoomInfoDivider} />
      <RoomInfo roomCount={roomCount} />
    </View>
  )
}

export function PaxInfo({ text }) {
  const { fsStyle, ...fonts}  = getFont(true);
  return (
    <View style={baseCardStyle.paxInfoContainer}>
      <View style={baseCardStyle.paxInfoImageContainer}>
      </View>
      <Text style={[baseCardStyle.paxInfoText, fonts.regularFontFamily, fsStyle.font12]}>{text}</Text>
    </View>
  )
}

export function RoomInfo({ roomCount }) {
  const { fsStyle, ...fonts}  = getFont(true);
  return (
    <View style={baseCardStyle.paxInfoContainer}>
      <View style={baseCardStyle.paxInfoImageContainer}>
      </View>
      <Text style={[baseCardStyle.paxInfoText, fonts.regularFontFamily, fsStyle.font12]}>
        {roomCount}
        {' '}
        Room(s)
      </Text>
    </View>
  )
}

export function RightArrow() {
  return (
    <View style={baseCardStyle.rightArrowContainer}>
      <Image style={[baseCardStyle.rightArrowIcon, imageTransformY]} source={rightArrowIcon} />
    </View>
  )
}

export function ActionsContent({ actions }) {
  if (!actions || actions.length === 0) {
    return null;
  }
  const { fsStyle, ...fonts}  = getFont(true);
  return (
    <View style={baseCardStyle.actionsContainer}>
      {actions.map((action, index) => (
        <React.Fragment key={index}>
          <TouchableOpacity style={baseCardStyle.actionsItem} onPress={action.onPress}>
            <Text style={[baseCardStyle.actionsItemText, fonts.semiBoldFontFamily, fsStyle.font14]}>
              {action.label}
            </Text>
          </TouchableOpacity>
          {index < actions.length - 1 && <View style={baseCardStyle.actionsDivider} />}
        </React.Fragment>
      ))}
    </View>
  )
}

export function FareLockIcon({ bookingStatus }) {
  let iconUrl = '';
  switch (bookingStatus) {
    case 'UPCOMING':
      iconUrl = upcomingBookingTimerIcon;
      break;
    case 'COMPLETED':
      iconUrl = completedBookingTimerIcon;
      break;
    case 'CANCELLED':
      iconUrl = cancelledBookingTimerIcon;
      break;
    default:
      iconUrl = upcomingBookingTimerIcon;
      break;
  }
  return (
    <View style={baseCardStyle.iconContainer}>
      <Image source={iconUrl} style={[baseCardStyle.lockFareStyle]} />
    </View>
  );
}

export function Popup({ popUpData, modalVisible, handleModal, handleClick, showCancelShimmer, centerImage }) {
  const { fsStyle, ...fonts }  = getFont(true);
  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={modalVisible}
      onRequestClose={handleModal}
    >
      <TouchableOpacity style={[baseCardStyle.centeredView, AtomicCss.alignCenter, AtomicCss.justifyCenter]} onPress={handleModal}>
        <View style={baseCardStyle.modalView}>
          <TouchableOpacity onPress={handleModal} style={baseCardStyle.closeWrap}>
            <View>
              <Image source={iconClose} style={baseCardStyle.iconClose} />
            </View>
          </TouchableOpacity>
          {showCancelShimmer && (
            <View style={[AtomicCss.marginTop28]}>
              <PlaceholderContainerWrapper>
                <View style={baseCardStyle.popContent}>
                  <Shimmer height={64} width={64} style={{ borderRadius: 32 }} />
                  <View style={AtomicCss.alignLeft}>
                    <Shimmer height={12} width="70%" marginTop={30} />
                    <Shimmer height={12} width="40%" marginTop={15} />
                  </View>
                  <Shimmer height={30} width="75%" marginTop={60} style={{ borderRadius: 20 }} />
                  <Shimmer height={30} width="75%" marginTop={15} style={{ borderRadius: 20 }} />
                </View>
              </PlaceholderContainerWrapper>
            </View>
          )}
          {!showCancelShimmer && (
            <View>
              <View style={baseCardStyle.popContent}>
                <View style={[AtomicCss.alignCenter]}><Image source={centerImage} style={baseCardStyle.iconImg} /></View>
                {!isEmpty(popUpData.heading) && <View style={AtomicCss.marginTop15}><Text style={[fsStyle.font18, AtomicCss.blackText, fonts.blackFontFamily]}>{popUpData.heading}</Text></View>}
                {!isEmpty(popUpData.subHeading) && <View style={AtomicCss.marginTop15}><Text style={[fsStyle.font12, AtomicCss.defaultText, AtomicCss.regularFont]}>{popUpData.subHeading}</Text></View>}
              </View>
              <View style={[baseCardStyle.btnWrap, AtomicCss.justifyCenter, AtomicCss.alignCenter, AtomicCss.paddingAll20]}>
                {!isEmpty(popUpData.buttons) && popUpData.buttons.map((buttonCTA) => (
                  <View>
                    <Button
                      btnWidth={220}
                      isDisabled={false}
                      btnBg={buttonCTA.type}
                      button={buttonCTA}
                      btnTxt={buttonCTA.text}
                      fontSize={12}
                      buttonTextCss={fsStyle.font12}
                      handleClick={handleClick}
                    />
                  </View>
                ))}
              </View>
            </View >
          )
          }
        </View >
      </TouchableOpacity >
    </Modal >
  );
};


export function NeedHelpStrip({title, action}) {
  const {psTheme: theme} = useTheme();
  const styles = getNeedHelpStripStyle(theme);
  const fonts = getFont(true);
  return(
    <View style={styles.needHelpCardWrapper}>
        <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween, AtomicCss.alignCenter]}>
            <Text style={[AtomicCss.font12, fonts.regularFontFamily , AtomicCss.blackText]}>{title}</Text>
            <TouchableOpacity style={[AtomicCss.flexRow, AtomicCss.alignCenter]} onPress={action.onPress}>
                {!isEmpty(action.iconUrl) && <Image source={{uri:action.iconUrl}} style={styles.callIcon} />}
                {!isEmpty(action.label) && <Text style={[AtomicCss.font14, fonts.boldFontFamily, styles.actionLabel]}>{action.label}</Text>}
            </TouchableOpacity>
        </View>
    </View>
  )
}
