import {colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import { themeColors } from '../../../PostSalesStyles';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';

const styles = {
	pageWrapper:{
		flex: 1
	},
	whiteBg:{
		backgroundColor: colors.white
	},
	paxCancelledTopDtls:{
		backgroundColor:colors.smokeWhite,
		elevation:2,
		marginBottom:1,
		marginTop:0,
		shadowColor: colors.black,
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.2,
		shadowRadius: 2,
	},
	paxCancelledinnderDtls:{
		paddingVertical:12,
		paddingHorizontal:16,
	},
	fltDtlsTxt:{
		fontFamily:fonts.black,
		color: colors.black,
		fontSize:14,
	},
	chklistIcon:{
		width:13,
		height:16,
		marginRight:8,
		marginTop:2,
	},
	UserIcon:{
		width:16,
		height:12,
		marginRight:8,
		marginTop:2,
	},
	refundtopDisc:{
		fontFamily:fonts.regular,
		fontSize:12,
	},
	topGreyBorder:{
		borderTopColor: colors.lightGrey,
		borderTopWidth:1,
	},
	whiteCard:{
		backgroundColor: colors.white,
		elevation: 2,
		shadowColor: colors.black,
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.2,
		shadowRadius: 2,
		marginVertical: 0,
	},
	packageHeading:{
		...getPlatformElevation(2),
		backgroundColor: colors.smokeWhite,
		padding:16,
	},
	statusInfoWrapper:{
		paddingVertical:12,
		paddingHorizontal:16,
	},
	priceInfoIcon:{
		width:18,
		height:17,
		marginRight:10,
		marginTop:2,
	},
	priceInfoTxt:{
		flex:1,
		fontFamily:fonts.bold,
		fontSize:14,
		color: colors.green,
	},
	dbInfoTxt:{
		flex:1,
		fontFamily:fonts.bold,
		fontSize:14,
		color: colors.green,
	},
	zcInfoTxt:{
		flex:1,
		fontFamily:fonts.bold,
		fontSize:14,
		color: colors.green,
	},
	autoRefundBreakupMain:{
		borderTopColor: colors.lightGrey,
		borderTopWidth:1,
		borderBottomColor: colors.lightGrey,
		borderBottomWidth:1,
	},
	fareBreakUpWrapper:{
		paddingTop:12,
		paddingHorizontal:16,
		paddingBottom:10,
	},
	DeductionBreakUpWrapper:{
		paddingTop:12,
		paddingHorizontal:16,
		paddingBottom:0,
		borderTopColor: colors.lightGrey,
		borderTopWidth:1,
	},
	fareBreakupHeading:{
		flexDirection:'row',
		justifyContent:'space-between',
	},
	fbHeadingTxt:{
		fontFamily:fonts.black,
		color: colors.black,
		fontSize:16,
	},
	totalPaidPriceTxt:{
		alignSelf:'flex-end',
		fontFamily:fonts.black,
		color: colors.black,
		fontSize:16,
	},
	linkTxt:{
		fontFamily:fonts.bold,
		color: themeColors.linkTextColor,
		fontSize:12,
		marginTop:2,
	},
	fareBreakupDtlsWrapper:{
		paddingVertical:12,
		paddingBottom:0
	},
	fareBrkupRow:{
		flexDirection:'row',
		justifyContent:'space-between',
		paddingVertical:4,
	},
	fareBrkupTxt:{
		fontFamily:fonts.regular,
		color: colors.defaultTextColor,
		fontSize:14,
	},
	fareBrkupTxtPrice:{
		fontFamily:fonts.regular,
		color: colors.greyLight,
		fontSize:14,
	},
	fareBrkupRowLine:{
		height:1,
		backgroundColor: colors.grayBg,
		width:'60%'
	},
	fareBrkupRowLineWrapperTxt:{
		marginTop:-8,
		backgroundColor: colors.white,
		alignSelf:'flex-start',
		marginBottom:5,
		fontFamily:fonts.italic,
		paddingRight:5,
	},
	fareBrkupRowLineTxt:{
		fontSize:12,
		fontFamily:fonts.regular,
	},
	fareBrkupRowWrapper:{
		marginBottom:20,
	},
	lstFBWrapper:{
		marginBottom:0,
	},
	totalRefund:{
		borderTopColor: colors.lightGrey,
		borderTopWidth:1,
		paddingTop:15,
		paddingBottom:15,
	},
	totalRefundTxt:{
		fontSize:20,
		color: colors.green,
		fontFamily:fonts.black,
	},
	footerBtnWrapper:{
		padding: 12,
	},
	dbIconSm:{
		width:16,
		height:16,
		marginRight:8,
	},
	dbIconMd:{
		width:24,
		height:24,
		marginRight:8,
	},
	zcIconMd:{
		width:18,
		height:24,
		marginRight:8,
		marginTop:2,
	},
	disabledDb:{
		opacity:.2
	},
	errorTxt:{
		color:colors.red,
	},
	zcIconSm:{
		width:12,
		height:16,
		marginRight:8,
	},
	priceIconRed:{
		width:19,
		height:18,
		marginRight:8,
		marginTop:5,
	},
	lineThrough:{
		textDecorationLine: 'line-through',
	},
	priceGap:{
		marginRight:5
	},
	downArrowStyle:{
		width:12,
		height:7,
		marginLeft:5,
	},
	activeArrowStyle:{
		transform:[
				{rotate:'180deg'}
		]
	},
	infoIcon:{
		width:20,
		height:20,
		tintColor: colors.azure
	},
	infoTxt:{
		color: colors.greyLight,
		fontFamily:fonts.regular,
		fontSize:12,
		lineHeight:15,
		width:'60%'
	},
	flex1:{
		flex:1
	},
	policyArrow:{
		width:9,
		height:13,
		top:1
	},
	viewWrapper:{
		paddingLeft:5,
		marginTop:5,
	},
	cutPrice:{
		textDecorationLine: 'line-through', 
		textDecorationStyle: 'solid'
	},
	myCashRow:{
		backgroundColor: colors.creamWhite,
		padding: 10,
		borderRadius: 10
	}
};

export default styles;
