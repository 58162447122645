import { StyleSheet } from 'react-native';

export default (theme, fonts) => StyleSheet.create({
  pageWrapper: {
    flex: 1,
  },
  pageScroll: {
    flex: 1,
    backgroundColor: theme.color.white,
  },
  pageScrollBtmSpace: {
    height: 70,
  },
  pageScrollTopSpace: {
    height: 15,
    backgroundColor: theme.color.grayBg,
  },
  importantInformationCard: {
    backgroundColor: theme.color.grayBg,
  },
  footerBtnWrapper: {
    padding: 12,
    position: 'absolute',
    zIndex: 2,
    bottom: 0,
    left: 0,
    width: '100%',
  },
  whiteCard: {
    backgroundColor: theme.color.white,
    padding: 15,
    marginVertical: 8,
    elevation: 2,
  },
  reqMessageWrapper: {
    padding: 15,
    paddingTop: 8,
  },
  reqMessage: {
    fontSize: fonts.fontSize.font14,
    color: theme.color.defaultTextColor,
    ...fonts.regularFontFamily,
    marginBottom: 6,
  },
  submitReqBtnText: {
    fontSize: fonts.fontSize.font12,
    color: theme.color.azure,
    ...fonts.blackFontFamily,
    paddingVertical: 6,
  },
  listingHeader: {
    flexDirection: 'row',
    borderTopColor: theme.color.lightGrey,
    borderTopWidth: 1,
    padding: 15,
    alignItems: 'center',
  },
  planeIcon: {
    width: 30,
    height: 17,
    marginRight: 10,
  },
  listingHeaderTitle: {
    fontSize: fonts.fontSize.font14,
    color: theme.color.black,
    ...fonts.boldFontFamily,
    marginBottom: 5,
  },
  listingHeaderDesc: {
    fontSize: fonts.fontSize.font12,
    color: theme.color.gray,
    ...fonts.regularFontFamily,
    marginTop: 3,
  },
  msgPlaceHolder: {
    backgroundColor: theme.color.creamWhite,
    borderRadius: 4,
    padding: 12,
    marginTop: 15,
    marginHorizontal: 10,
  },
  msginfoTxt: {
    color: theme.color.lightYello,
    ...fonts.boldFontFamily,
    fontSize: fonts.fontSize.font12,
  },
});
