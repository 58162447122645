import React, { useEffect } from 'react';
import { View, Image, Text, TouchableOpacity, Platform, StyleSheet } from 'react-native';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getStyles from '../../components/HotelMamiCss';
import HotelBookingTrackingHelper from '../../../HotelBookingTrackingHelper';
import { CHEVRON_RIGHT_BLUE_ICON3, BLACK_LEFT_ARROW_ICON2} from '../../../HotelImageConstants';
import { getImageTransformStyling, setWebViewAuth } from 'apps/post-sales/src/utils/PostSaleUtil';
import Actions from 'apps/post-sales/src/navigation/postSalesNavigation';
import { isGCCBooking } from 'apps/post-sales/src/Common/commonUtil';
import {getCommonHeaders} from '@mmt/legacy-commons/Helpers/genericHelper';
import { getFont, ps_colors } from 'apps/post-sales/src/PostSalesStyles';
import { getStaticData } from 'apps/post-sales/src/staticData/staticData';

const BPGMimaCard = ({ bpgCard, pageName, hotelDetailsResponse }) => {
  const {fsStyle, ...fonts} = getFont();
  const styles = getStyles(fonts);
  const { bestPriceGuaranteeText, termsAndConditionText } = getStaticData();
  const { header, description, icon, tncText, tncLink, actionList } = bpgCard

  useEffect(()=>{
    HotelBookingTrackingHelper.trackShowEvent(pageName, 'bpg_card_shown', hotelDetailsResponse);
  }, [])

  const openWebView = async (_url, options = {}) => {
    const PLATFORM_SOURCE = Platform.OS === 'android' ? 'ANDROID' : 'IOS';
    let url = _url;
    if(url.includes('?')) {
      url += `&source=${PLATFORM_SOURCE}`;
    } else {
      url += `?source=${PLATFORM_SOURCE}`;
    }
    let appVersion = null;
    try {
      appVersion = await GenericModule.getAppVersion();
    } catch (e) {}
    if (appVersion) {
      url = `${url}&appVersion=${appVersion}`;
    }
    let headers = {};
    try {
      headers = await getCommonHeaders();
      delete headers['mmt-app-version-code'];
      delete headers['langAware'];
    } catch (e) {}
    const isGcc = await isGCCBooking();
    const authValue = headers['mmt-auth'];
    const javaScript = setWebViewAuth(authValue, isGcc);
    Actions.openPsWebView({
      url,
      headers,
      headerText: options.headerText,
      headerIcon: options.headerIcon,
      sharedCookiesEnabled: options.sharedCookiesEnabled,
      whiteHeader: options.whiteHeader,
      injectedJavaScript: javaScript,
    });
  };

  const getActionButtons = (buttonList, index) => {
    const { text = "", url = "" } = buttonList
    return <View style={[bpgStyles.bottomLink]}>
      <TouchableOpacity 
        key={index}
        activeOpacity={0.8}
        style={[AtomicCss.flexRow, AtomicCss.spaceBetween, AtomicCss.alignCenter, AtomicCss.flex1]}
        onPress={ () => {
          HotelBookingTrackingHelper.trackClickEvent(pageName, `bpg_cta_clicked`, hotelDetailsResponse);
          openWebView(url, {
            headerText: bestPriceGuaranteeText,
            headerIcon: BLACK_LEFT_ARROW_ICON2,
            whiteHeader: true,
            sharedCookiesEnabled: true
          }); 
        }}
      >
        <Text style={[styles.BlueText, fsStyle.font14]}>{text}</Text>
        <Image style={[styles.RecFullBtnArrow, getImageTransformStyling()]} source={CHEVRON_RIGHT_BLUE_ICON3}/>
      </TouchableOpacity>
    </View>
  }
	
  return (
    <View style={[AtomicCss.whiteCardNew, AtomicCss.marginHz12, AtomicCss.borderRadius16]}>
      <View style={[styles.CardContentNew]}>
        <View style={[styles.ContentPortion, AtomicCss.flexRow, AtomicCss.spaceBetween]}>
          <View>
            <Text style={[styles.blackFont, styles.BlackText, styles.mar20Bt, fsStyle.font16]}>
              {header}
            </Text>
            <View style={[AtomicCss.flex1, AtomicCss.paddingRight10]}>
                <Text
                  style={[
                    fsStyle.font14,
                    fonts.regularFontFamily,
                    AtomicCss.lineHeight14
                  ]}
                >
                  {description}
                </Text>
                <TouchableOpacity
                  activeOpacity={0.8}
                  onPress={() => {
                    openWebView(tncLink, {
                      headerText: termsAndConditionText,
                      headerIcon: BLACK_LEFT_ARROW_ICON2,
                      whiteHeader: true,
                      sharedCookiesEnabled: true
                    });
                  }}
                >
                  <Text
                    style={[
                      AtomicCss.azure,
                      fsStyle.font12,
                      fonts.regularFontFamily,
                      AtomicCss.lineHeight18,
                      AtomicCss.alignLeft
                    ]}
                  >
                    {tncText}
                  </Text>
                </TouchableOpacity>
            </View>
          </View>
        </View>  
        <Image style={bpgStyles.imageStyle} source={{uri: icon}} />
      </View>
      {actionList?.length ? actionList.map(getActionButtons) : null}
    </View>
  );
}
const bpgStyles = StyleSheet.create({
  bottomLink: {
    paddingVertical: 16,
    paddingLeft: 12,
    paddingRight: 24,
    borderTopWidth: 1,
    borderColor: ps_colors.lightGrey,
  },
  imageStyle: { 
    width:79,
    height:64,
    marginTop: 25
  }
})

export default BPGMimaCard;
