export const ZcConstants = {
    TOTAL_PAID : 'totalpaid',
    DISCOUNT : 'discount',
    TOTAL_PENALTY : 'totalpenalty',
    MY_WALLET : 'My Wallet',

    TOP_STATUS_CARD_PROFILE_VERIFICATION_PENDING_NO_DRIVING_LICENSE: 'TopStatusCard_PROFILE_VERIFICATION_PENDING_NO_DRIVING_LICENSE',
    TOP_STATUS_CARD_PROFILE_VERIFICATION_PENDING_INCOMPLETE_DOCUMENTS: 'TopStatusCard_PROFILE_VERIFICATION_PENDING_INCOMPLETE_DOCUMENTS',
    TOP_STATUS_CARD_PROFILE_VERIFICATION_IN_PROGRESS: 'TopStatusCard_PROFILE_VERIFICATION_IN_PROGRESS',
    TOP_STATUS_CARD_BOOKING_CONFIRMED:'TopStatusCard_BOOKING_CONFIRMED',
    TOP_STATUS_CARD_CAR_NOT_ASSIGNED: 'TopStatusCard_CAR_NOT_ASSIGNED',
    TOP_STATUS_CARD_CAR_ASSIGNED: 'TopStatusCard_CAR_ASSIGNED',
    TOP_STATUS_CARD_PROFILE_VERIFIED: 'TopStatusCard_PROFILE_VERIFIED',
    TOP_STATUS_CARD_PROFILE_NOT_VERIFIED: 'TopStatusCard_PROFILE_NOT_VERIFIED',
    TOP_STATUS_CARD_DRIVER_ASSIGNED: 'TopStatusCard_DRIVER_ASSIGNED',
    TOP_STATUS_CARD_CAR_ON_THE_WAY: 'TopStatusCard_CAR_ON_THE_WAY',
    TOP_STATUS_CARD_FINDING_A_CAR: 'TopStatusCard_FINDING_A_CAR',
    TOP_STATUS_CARD_ENROUTE: 'TopStatusCard_ENROUTE',
    TOP_STATUS_CARD_CUSTOMER_CANCELLED: 'TopStatusCard_CUSTOMER_CANCELLED',
    TOP_STATUS_CARD_VENDOR_CANCELLED: 'TopStatusCard_VENDOR_CANCELLED',
    TOP_STATUS_CARD_PROFILE_REJECTED: 'TopStatusCard_PROFILE_REJECTED',
    TOP_STATUS_CARD_TRIP_END_DROP_CHECKLIST_DONE: 'TopStatusCard_TRIP_END_DROP_CHECKLIST_DONE',
    TOP_STATUS_CARD_TRIP_END_PAYMENT_APPLICABLE: 'TopStatusCard_TRIP_END_PAYMENT_APPLICABLE',
    TOP_STATUS_CARD_TRIP_END: 'TopStatusCard_TRIP_END',
    TOP_STATUS_CARD_BOOKING_FAILED: 'TopStatusCard_BOOKING_FAILED',

    PROFILE_VERIFICATION_CARD: 'profileVerificationCard',
    ACTION_SUBMIT_DL: 'SubmitDL',
    PROFILE_VERIFICATION_DONE_CARD: 'profileVerificationDoneCard',
    CAR_CARD: 'carCard',
    TRAVEL_DETAIL_CARD: 'travelDetailCard',
    MODIFY_BOOKING: 'modifyBookingCard',
    PROFILE_VERIFICATION_OVERLAY: 'ProfileVerificationOverlay',
    CAR_ASSIGNED_DETAIL_CARD: 'assignedCarCard',
    CAR_TRIP_INFO_CARD: 'extendTrip',
    CAR_PICK_DROP_PROCESS_CARD: 'pickDropProcessCard',
    VALIDATION_TRIP_END_CARD: 'validationProgressCard',
    CAR_DELIVERY_DETAILS: 'carDeliveryDetailCard',
    CAR_CANCELLATION_CARD: 'cancellationCard',
    CUSTOMER_SUPPORT_CARD: 'needHelpGiaCard',
    MORE_ABOUT_BOOKING_CARD: 'moreAboutBookingCard',
    WHY_BOOKING_CARD_FAILED: 'whyBookingFailCard',
    ZC_REFUND_TRACKER_CARD: 'refundProgressCard',
    ZC_ITINERARY_CARD: 'SelfDriveItineraryCard',
    ZC_PAID_CARD: 'paidCard',
    ZC_CHANGE_IN_PLANS_CARD: 'ChangeInPlansCard',
    ZC_CONTACT_OPERATOR_CARD: 'contactOperatorsCard',
    RECTANGLE_FULL_BUTTON: 'RecFullBtn',
    ROUND_BUTTON: 'RbBtnWithIcon',
    RECTANGLE_PRIMARY_FULL_WIDTH: 'CapsuleFullBtnFill',
    RECTANGLE_PRIMARY_HALF_WIDTH: 'CapsuleHalfBtnFill',
    RECTANGLE_SECONDARY_FULL_WIDTH: 'CapsuleFullBtn',
    RECTANGLE_SECONDARY_HALF_WIDTH: 'CapsuleHalfBtn',
    WHITE_BUTTON_FULL: 'CapsuleWhiteBtn',
    WHITE_BUTTON_HALF: 'CapsuleWhiteBtnHalf',
    WRITE_TO_US_PAGE: 'mmt.intent.action.WRITE_TO_US',
    ACTION_CALL_DRIVER: 'CallDriver',
    ACTION_CALL_MMT_SUPPORT: 'CallMMTSupport',
    ACTION_CAB_WTU: 'CabWriteToUs',
    ACTION_CAB_CALL_US: 'CabCallUs',
    HELPLINE_NUMBER: '0124 4628747',
    ACTION_VIEW_PRICE_BREAKUP: 'SeePriceBreakup',
    CLICK_CALL: 'Mob_Cabs_MIMA_Call_Us_Click',
    CLICK_WTU: 'Mob_Cabs_MIMA_Write_To_Us_Click',
    CLICK_FARE_BREAKUP: 'Mob_Cabs_MIMA_Fare_Breakup_Click',
    CLICK_CANCEL_BOOKING: 'Mob_Cabs_MIMA_Cancel_Booking_Click',
    CLICK_CANCEL_BOOKING_FOR_NOAMENDMENT: 'Mob_Cabs_MIMA_Cancel_Booking_NOAMENDMENT_Click',
    CLICK_CANCELLATION_POLICY: 'Mob_Cabs_MIMA_Cancellation_Policy_Click',
    CLICK_DOWNLOAD_VOUCHER: 'Mob_Cabs_MIMA_Download_Voucher_Click',
    CLICK_DOWNLOAD_INVOICE: 'Mob_Cabs_MIMA_Downlaod_Invoice_Click',
    CLICK_DRIVER_DETAILS: 'Mob_Cabs_MIMA_Driver_Details_Click',
    CLICK_TERMS_AND_CONDITIONS: 'Mob_Cabs_MIMA_Terms_Conditions_Click',
    CLICK_INVOICE_DETAILS: 'Mob_Cabs_MIMA_Invoice_Details_Click',
    CLICK_CABDATECHANGE_DETAILS: 'Mob_Cabs_MIMA_DateChange_Details_Click',
    CLICK_CANCEL_CONTINUE: 'Mob_Cabs_MIMA_Continue_to_Cancel_Click',
    CLICK_CONFIRM_CANCEL: 'Mob_Cabs_MIMA_Confirm_Cancellation_Click',
    CLICK_CANCELBOOKING_DETAILS: 'Mob_Cabs_MIMA_CancelBookingDetails_Click',
    CLICK_CAB_CROSS_SELL_UPDATE_DEEPLINK: 'MI_Cab_Cross_Sell_Update_Deeplink',
    CLICK_CHANGEPICKUP_DETAILS: 'Mob_Cabs_MIMA_ChangePickupDetails_Click',
    CLICK_SELECT_PICKUPDATES: 'Mob_Cabs_MIMA_SELECTDATE_Click',
    CLICK_SELECT_PICKUPDATES_UNAVAILABLE: 'Mob_Cabs_MIMA_SELECTDATE_UNAVAILABLE_Click',
    CLICK_REVIEW_AMENDMENT: 'Mob_Cabs_MIMA_REVIEW_AMENDMENT_CLick',
    CLICK_UPDATED_BOOKING_DETAILS: 'Mob_Cabs_MIMA_UpdatedBooking_Details_Click',
    TRY_AGAIN: 'Try Again',
    BACK_TO_DETAILS: 'Back to Booking Details',
    CLICK_REFRESH_MAP: 'Mob_Cabs_MIMA_Refresh_Map_Click',
    CLICK_ZOOM_OUT_MAP: 'Mob_Cabs_MIMA_Zoom_Out_Map_Click',
    CLICK_ZOOM_IN_MAP: 'Mob_Cabs_MIMA_Zoom_In_Map_Click',
    CLICK_RECENTER_MAP: 'Mob_Cabs_MIMA_Recenter_Map_Click',
    DEFAULT_TRIP_DETAILS_PAGE: 'Mob:Customer Support:Cab:TripDetails',
    CANCELED_IMAGE: "cancelledImage",
    CONFIRMED_IMAGE: "confirmedImage",
    FAILED_IMAGE: "failedImage",
    IMPORTANT_INFO_CARD: 'importantInfoCard',
    ADVISORY_NOTICE : 'AdvisoryNoticeCard',
    JOURNEY_OVER_IMAGE: "journeyOverImage",
    ALARM_IMAGE: "alarmImage",
    UPCOMING: "upcoming",
    CONFIRMED: "confirmed",
    CANCELLED: "cancelled",
    COMPLETED: "completed",
    TOTAL_PAID_KEY: 'TOTALPAID',
    TOTAL_PRICE_KEY: 'TOTALPRICE',
    ADDITIONAL_AMOUNT: 'ADDITIONAL',
    OUTSTANDING_AMOUNT: 'OUTSTANDING',
    GET_REFUND_AMOUNT: 'REFUND',
    ACTION_PAY_NOW_CTA: 'payNow',
    CTA_INFO: 'PAYNOW',
    SECURITY_DEPOSIT_KEY: 'SECURITYDEPOSIT',
    E_COUPON_KEY: 'eCoupon',
    ACTION_PAYMENT_DETAILS_PAGE: 'SeePriceBreakup',
    ACTION_MORE_INFO_POLICIES: 'Policies',
    ACTION_MORE_INFO_INCLUSION_EXCLUSION: 'InclusionExclusion',
    ACTION_MORE_INFO_GET_REFUND: 'GetRefund',
    ACTION_MORE_INFO_ASKED_QUESTION: 'AskedQuestions',
    ACTION_FULL_CANCEL: 'FullCancellation',
    ACTION_CANCELLATION_POLICY: 'cancellationPolicy',
    ACTION_END_TRIP_NOW: 'endTripNow',
    ACTION_EXTEND_TRIP_NOW: 'extentTrip',
    ACTION_SEE_UPLOADED_DOCS: 'uploadedDocs',
    ACTION_SEE_UPLOADED_DOCUMENTS : 'seeUploadedDocs',
    ACTION_CALL_TO_AGENT: 'CallToAgent',
    ACTION_FILL_CHECK_LIST: 'FillCheckListOnCarAssigned',
    PICKUP_OVERLAY: 'pickup',
    DROP_OVERLAY: 'dropUp',
    ACTION_CONTACT_MMT: 'ContactMMT',
    ACTION_CONTACT_VENDOR: 'ContactVendor',
    KNOW_MORE_OVERLAY: 'cabInfoOverlay',
    CMP_CODE:'selfdrive mima',
    TRIP_TYPE:'Round Trip',
    LOB :'selfdrive',
    SELF_PICKUP :'Self Pickup',
    HOME_DELIVERY: 'Home Delivery',
    REFUND_PROGRESS_CARD: 'refundProgressCard',
    ACTION_PROCEED_CHECKLIST : 'checklistOverlay',
    ACTION_PROFILE_VERIFICATION : 'seeUploadedDocs',
    ACTION_UPLOAD_DOCUMENTS : 'uploadedDocs'
};

export default ZcConstants;
