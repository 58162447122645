import React from "react";
import {Image, StyleSheet, Text, View} from "react-native";
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { styles} from './CancelAndRebookStyles';
import {CancelAndRebookConstants} from "./CancelAndRebookConstants";

export default class CabInfo extends  React.Component{
    render() {
        return(
            <View style={styles.card}>
                <View style={styles.cabPrimaryDetails}>
                    <View style={styles.content}>
                        <View style={AtomicCss.marginBottom15}>
                            <Text style={[AtomicCss.blackText,AtomicCss.font16,AtomicCss.boldFont]}>Updated Booking Details</Text>
                        </View>
                        <View style={styles.cabTopInfo}>
                            <View style={AtomicCss.flex1}>
                                <Text style={[AtomicCss.font14,AtomicCss.boldFont,AtomicCss.defaultText,AtomicCss.marginBottom5]}>{this.props.cabDetails.brand}</Text>
                                <Text style={[AtomicCss.font12,AtomicCss.boldFont,AtomicCss.defaultText]}>{this.props.cabDetails.category}</Text>
                            </View>
                            <Image source={{ uri : this.props.cabDetails.imageUrl}} style={styles.cabImage} />
                        </View>

                        <View style={styles.body}>
                            <View style={{ flex: 1 }}>
                                <Text style={[styles.pickUpTime, styles.greenColorForTxt]}>{CancelAndRebookConstants.PICKUP_BY}</Text>
                                <View style={styles.tripDateRow}>
                                    <Text style={styles.pickUpTime}>{this.props.modifiedTripDetails.pickupTimeText}</Text>
                                    <Text style={styles.dropTime}>{this.props.modifiedTripDetails.pickupDayText}</Text>
                                </View>
                                <View style={styles.locationDetails}>
                                    <View style={styles.pickUpPointDetails}>
                                        <Text style={styles.locationName} numberOfLines={1}>{this.props.modifiedTripDetails.pickupCity}</Text>
                                        <Text style={styles.smallText}>{this.props.modifiedTripDetails.pickupAddress}</Text>
                                    </View>
                                    <View style={styles.tripDurationDetails}>
                                        <Text style={styles.tripDistance}> {this.props.modifiedTripDetails.distanceText} </Text>
                                        <Text style={styles.smallText1}>{this.props.modifiedTripDetails.durationText}</Text>
                                        <Text style={styles.smallText1}>{this.props.modifiedTripDetails.approxText}</Text>
                                    </View>
                                    <View style={styles.dropPointDetails}>
                                        <Text style={styles.locationName} numberOfLines={1}>{this.props.modifiedTripDetails.dropCity}</Text>
                                        <Text style={[styles.smallText, styles.textRight]}>{this.props.modifiedTripDetails.dropAddress}</Text>
                                    </View>
                                </View>
                            </View>
                        </View>

                    </View>
                </View>
            </View>
        )
    }
}
