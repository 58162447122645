import PropTypes from 'prop-types';
import React from 'react';
import { DeviceEventEmitter, ScrollView, View, Platform } from 'react-native';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import NoInternetView from '@mmt/legacy-commons/Common/Components/Error/NoInternetView';
import BasePage from '../../../Common/PostSalesBasePage';
import ErrorPage from '../../details/pages/ErrorPage';
import { getModificationRequest, handleClickEvent, isIntlHotelBooking, resetToHotelDetail } from '../../details/utils/HotelBookingDetailUtil';
import DateModificationCard from './V2/DateModificationCard';
import RoomDetailsCard from './V2/RoomDetailsCard';
import PriceBreakupCard from './V2/PriceBreakupCard';
import MAMIBlackFooter from '../common/MAMIBlackFooter';
import Actions from '../../../navigation/postSalesNavigation';
import { NEED_HELP_PAGE } from '../../../PostSalesConstant';
import { getStaticData } from '../../../staticData/staticData';
import { getCommonHeaders, HOTEL_CHANGE_DATE_AVAIL_URL, HOTEL_CHANGE_DATE_URL } from '../../../utils/NetworkUtils';
import { getButtonGradientColor } from '../../../utils/PostSaleUtil';
import SimpleHeader from '@mmt/legacy-commons/Common/Components/Header/SimpleHeader';
import { invalidateTrips } from '@mmt/legacy-commons/Helpers/genericHelper';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import { HOTEL_DETAILS_BOTTOM_OVERLAY, MODIFICATION_TYPE } from '../../details/HotelBookingDetailsConstant';
import { getHelpingHandData, renderNeedHelpOverlay, trackHelpingHandEvent } from '../../details/utils/HelpingHandHelper';
import HotelBookingTrackingHelper from '../../HotelBookingTrackingHelper';
import { LANG_AWARE } from '../../HotelConstants';
import { getModificationConfirmationPopupData, getModificationPolicyTrackerInfo } from '../../hotelUtils';
import ChangeInReviewPopup from '../common/ChangeInReviewPopup';
import ConfirmationPopup from '../common/ConfirmationPopup';
import HotelModificationpersuationMessage from '../common/hotelModificationPersuationMessage';
import LoadingView from '../common/LoadingView';
import ModificationFailureOverlay from '../common/ModificaionFailureOverlay';
import ModificationCard from '../common/ModificationCard';
import ModificationOverlay from '../common/ModificationOverlay';
import PaymentDetailCard from '../common/PaymentDetailCard';
import { CHK_DIFF_DC, CHANGE_DATE_CONTINUE_CONFIRM, RETRY, ROOM_CARD_EDIT_DC, SMTHNG_WRONG_OVERLAY_DC, SOLD_OUT_AVAIL_OVERLAY_DC, CONNECT_WITH_US, CHANGE_DATE_REVIEW_PRICE_OVERLAY, SOLD_OUT_OVERLAY_DC, DC_ROOM_IMAGE_CLICK } from '../HotelModificationConstants';
import BottomSheetWpr from '../../../Common/BottomSheetWpr';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import isEmpty from 'lodash/isEmpty';
export const HOTEL_DATECHANGE_VIEWROOM = 'hotelChangeDateViewPrice';

class HotelChangeDateViewPrice extends BasePage {
  constructor(props) {
    super(props, HOTEL_DATECHANGE_VIEWROOM);
    this.state = {
      responseJson: null,
      viewState: ViewState.LOADING,
      helpingHandOverlay: false,
      showChangeInReviewPopup: false,
      mamiViewState: '',
      modificationFailureOverlayProps: {},
      confirmationPopupProps: {},
    };
    this.showHelpingHand = this.props.hotelDetailResponse && this.props.hotelDetailResponse.isHelpingHandEnabled;
    this.helpingHandData = getHelpingHandData(this.props.hotelDetailResponse, NEED_HELP_PAGE.HOTEL_DATE_CHANGE, this.trackHelpingHandEvents, LANG_AWARE.MODIFICATION);
    this.mimaBlackFooterRef = React.createRef();
    this.nrTrackingInfo = null;
    this.modificationThankyouPageListener = null;
    this.paymentbackPressListener = null;
  }

    static navigationOptions = {
      header: null
    };

    componentDidMount() {
      super.componentDidMount();
      this.fetchJSONAsync(HOTEL_CHANGE_DATE_URL);
      this.trackingPageName = isIntlHotelBooking(this.props.hotelDetailResponse?.lobCode)
        ? 'Mob:customer support:Hotel International:DateChange:ResultsPage_Available'
        : 'Mob:customer support:Hotel Domestic:DateChange:ResultsPage_Available';
    }

    onHardBackPress = () => {
      if (this.state.viewState !== ViewState.ERROR && this.props.hotelDetailResponse.isHelpingHandEnabled && this.props.showHelpingHand) {
        this.props.showHelpingHand();
      }
      Actions.pop();
      return true;
    }

    openPropertyDetails = () => {
      const { hotelDetailResponse: response } = this.props;
      Actions.hotelPropertyDetails({
        response
      });
    }

    openRoomPhotos = () => {
      HotelBookingTrackingHelper.trackClickEvent(this.trackingPageName, DC_ROOM_IMAGE_CLICK, this.props.hotelDetailResponse);
      Actions.viewRoomPhotos({
        response: this.state.responseJson
      });
    }

    editBtnHandler = () => {
      HotelBookingTrackingHelper.trackClickEvent(
        this.trackingPageName,
        ROOM_CARD_EDIT_DC,
        this.props.hotelDetailResponse
      )
      if (Platform.OS === 'web') {
        Actions.hotelBookingDetail({ bookingId: this.props.bookingid, isODC: true});
      } else {
        Actions.pop();
        if(!isEmpty(this.props.action) && typeof this.props.action?.actionHandler === 'function'){
          this.props.action.actionHandler(HOTEL_DETAILS_BOTTOM_OVERLAY.CALENDAR_OVERLAY);
        }
      }
    }

    handleCancelClick = () => {
      const action = {
        actionFamily: 'CancelFullBooking'
      };
      handleClickEvent(this.props.card, action, this.props.hotelDetailResponse, "", LANG_AWARE.MODIFICATION);
    }

    handleDateChangeFailure = (unavailabilityInstructions, reviewPriceResponse) => {
      if (!unavailabilityInstructions || unavailabilityInstructions.type == 0) {
        this.setmamiViewState(ViewState.ERROR);
        return;
      }
      let modificationFailureOverlayProps;
      if (unavailabilityInstructions.type == 2) {
        const { noRoomAvailable, checkForDiffDate } = getStaticData(LANG_AWARE.MODIFICATION);
        const { newDatesText } = this.state.responseJson || {};
        modificationFailureOverlayProps = {
          overlayTitle: noRoomAvailable,
          overlayType: SOLD_OUT_OVERLAY_DC,
          overlayPrimaryBtnProps: {
            btnTxt: checkForDiffDate,
            onTap: this.onHardBackPress,
            clickEvent: CHK_DIFF_DC,
          },
          overlayEditProps: {
            title: newDatesText,
          }
        }
      }
      this.setState({
          unavailabilityInstructions,
          responseJson: {...this.state.responseJson, unavailabilityInstructions, success: reviewPriceResponse.success, errorPageData: null, },
          showChangeInReviewPopup: unavailabilityInstructions.type == 1,
          showModificationFailurePopup: unavailabilityInstructions.type == 2,
          showModificationOverlay: false,
          modificationFailureOverlayProps,
        });
    }

    handleChangeInReviewProceed = () =>{
      this.setState({
        showChangeInReviewPopup: false,
        unavailabilityInstructions: null
      })
      this.mimaBlackFooterRef.current && this.mimaBlackFooterRef.current.fetchJSONAsync(HOTEL_CHANGE_DATE_AVAIL_URL);
    }

    hideChangeInReviewPopup = () => {
      this.setState({ showChangeInReviewPopup: false })
    }

    hideConfirmationPopup = () => {
      this.setState({ showConfirmationPopup: false })
    }

    showConfirmationPopup = () => {
      this.setState({ showConfirmationPopup: true })
    }

    handleReviewConfirm = () => {
      HotelBookingTrackingHelper.trackClickEvent(
        this.trackingPageName,
        CHANGE_DATE_CONTINUE_CONFIRM,
        this.props.hotelDetailResponse
      );
      this.showConfirmationPopup();
    }

    onConfirmPopClick = () => {
      const { corporateBooking } = this.state.responseJson;
      this.mimaBlackFooterRef.current && this.mimaBlackFooterRef.current.countinueHandler();
      if (corporateBooking) {
         this.setState({
          showConfirmationPopup: false
        });
      } else {
        this.setState({
          showModificationOverlay: true,
          showConfirmationPopup: false
        });
      }
    }

    setmamiViewState = (viewState) => {
      if(viewState === ViewState.NO_INTERNET) {
        this.setState({
          viewState:ViewState.NO_INTERNET
        });
        return;
      }
      this.setState({
        showModificationOverlay: viewState !== ViewState.DEFAULT ? true : false,
        mamiViewState: viewState
      });
    }

    handleModificationOverlay = ()=>{
      this.setState({
        showModificationOverlay:false
      });
    }

    setErrorPageData = (resp) => {
      const { errorPageData } = resp || {};
      this.setState({
        responseJson: {
          ...this.state.responseJson,
          errorPageData,
        }
      })
    }

    trackError() {
      this.trackingPageName = isIntlHotelBooking(this.props.hotelDetailResponse?.lobCode)
        ? 'Mob:customer support:Hotel International:DateChange:ResultsPage_Available:Error'
        : 'Mob:customer support:Hotel Domestic:DateChange:ResultsPage_Available:Error';
      HotelBookingTrackingHelper.trackErrorEvent(this.trackingPageName, this.props.hotelDetailResponse);
    }

    handleTryAgain = () => {
      this.setState({
        viewState: ViewState.LOADING,
        showModificationOverlay: false,
        showModificationFailurePopup: false
      });
      this.fetchJSONAsync(HOTEL_CHANGE_DATE_URL);
    }

    trackSuccessLoadEvent= (hotelSearchPrice, hotelDetailResponse) => {
      this.trackingPageName = isIntlHotelBooking(hotelDetailResponse?.lobCode)
      ? 'Mob:customer support:Hotel International:DateChange:ResultsPage_Available'
      : 'Mob:customer support:Hotel Domestic:DateChange:ResultsPage_Available';
      this.nrTrackingInfo = getModificationPolicyTrackerInfo(hotelSearchPrice)
      HotelBookingTrackingHelper.trackHotelAddGuestLoadEvent(
        this.trackingPageName,
        hotelDetailResponse,
        hotelSearchPrice,
        true,
        this.nrTrackingInfo
      );
    }

    renderPage() {
      const searchPriceResponse = this.state.responseJson;
      return this.getSuccessView(searchPriceResponse);
    }

    handleCloseBottomSheet = () => {
      this.setState({
        showModificationFailurePopup: false
      })
    }

    getSuccessView(searchPriceResponse) {
      const {
          persuationMessage, paymentBreakup, corporateBooking
      } = searchPriceResponse;

      const { reviewChanges } = getStaticData(LANG_AWARE.MODIFICATION);
      const { dateModificationCard, roomDetailsCard, priceBreakupCard, dateChangeV2=false} = this.state.responseJson;
      return (
        <View style={{ width: "100%", flex:1}}>
          <SimpleHeader
            title= {reviewChanges}
            iconPress={this.onHardBackPress}
          />
          <ScrollView style={{backgroundColor:'#e7e7e7'}}>
            {dateChangeV2 &&
              <>
                <DateModificationCard
                  dateModificationCard={dateModificationCard}
                  onDateChange={this.editBtnHandler}
                />
                <RoomDetailsCard
                  roomDetailsCard={roomDetailsCard}
                  openPropertyDetails={this.openPropertyDetails}
                  openRoomPhotos={this.openRoomPhotos}
                />
                <PriceBreakupCard
                  priceBreakupCard={priceBreakupCard}
                />
              </>
            }
            {!dateChangeV2 &&
              <>
                <ModificationCard response={searchPriceResponse} editBtnHandler={this.editBtnHandler} />
                <HotelModificationpersuationMessage persuationdata={persuationMessage} />
                <PaymentDetailCard paymentBreakup={paymentBreakup} />
              </>
            }
          </ScrollView>
          <MAMIBlackFooter
            ref={this.mimaBlackFooterRef}
            response={this.state.responseJson}
            hotelDetailResponse={this.props.hotelDetailResponse}
            action="DATECHANGE"
            showHelpingHand={this.showHelpingHand}
            helpingHandLabel={this.helpingHandData && this.helpingHandData.connectWithUsText}
            helpingHandClickHandler={() => this.setHelpingHandOverlay(true)}
            cnacelHandler={this.handleCancelClick}
            handleDateChangeFailure={this.handleDateChangeFailure}
            paymentBreakup={paymentBreakup}
            handleClick={this.handleReviewConfirm}
            handleViewState={this.setmamiViewState}
            setErrorPageData={this.setErrorPageData}
            closeModificationOverlay={this.handleModificationOverlay}
          />
          {this.showHelpingHand && this.state.helpingHandOverlay === true &&
            renderNeedHelpOverlay(this.helpingHandData, this.setHelpingHandOverlay)
          }
          {
            this.state.showChangeInReviewPopup && this.state.unavailabilityInstructions &&
            <ChangeInReviewPopup
              {...this.state.unavailabilityInstructions}
              bookingId={this.props.bookingid}
              dismiss={this.hideChangeInReviewPopup}
              handleCTAClick={this.handleChangeInReviewProceed}
              overlayType={CHANGE_DATE_REVIEW_PRICE_OVERLAY}
              hotelDetailsResponse={this.props.hotelDetailResponse}
              pageName={this.trackingPageName}
            />
          }
          {this.state.showConfirmationPopup && (
          <BottomSheetWpr
              visible={this.state.showConfirmationPopup}
              setVisible={this.hideConfirmationPopup}
              containerStyles={AtomicCss.paddingAll16}
              children={
                  <ConfirmationPopup
                    pageName={this.trackingPageName}
                    paymentBreakup={paymentBreakup}
                    dismiss={this.hideConfirmationPopup}
                    corporateBooking={corporateBooking}
                    hotelDetailsResponse={this.props.hotelDetailResponse}
                    handleCTAClick={this.onConfirmPopClick}
                    {...this.state.confirmationPopupProps}
                    
                />
                }
            />
        )}
          {
           this.state.showModificationOverlay &&
           <ModificationOverlay
              isLoading={this.state.mamiViewState === ViewState.LOADING}
              isSuccess={this.state.mamiViewState === ViewState.COMMIT_SUCCESS}
              isError={this.state.mamiViewState === ViewState.ERROR}
              isForceClose={this.state.mamiViewState === ViewState.FORCE_CLOSE}
              modificationType={MODIFICATION_TYPE.DATE_CHANGE}
              response={this.state.responseJson}
              handleTryAgain={this.handleTryAgain}
              showHelpingHand={this.showHelpingHand}
              helpingHandClickHandler={this.showHelpingHandInModificationOverlay}
              helpingHandData={this.helpingHandData}
              hotelDetailsResponse={this.props.hotelDetailResponse}
              pageName={this.trackingPageName}
              nrTrackingInfo={this.nrTrackingInfo}
            />
          }
            { this.state.showModificationFailurePopup && (
              <BottomSheetWpr
                visible={this.state.showModificationFailurePopup}
                containerStyles={AtomicCss.paddingAll16}
                setVisible={()=>{}}
                children={
                  <ModificationFailureOverlay
                  {...this.state.unavailabilityInstructions}
                  response={this.state.responseJson}
                  {...this.state.modificationFailureOverlayProps}
                  hotelDetailsResponse={this.props.hotelDetailResponse}
                  pageName={this.trackingPageName}
                  handleTryAgain={this.handleTryAgain}
                  dismiss={this.handleCloseBottomSheet}
                />
          }
          />
        )}
        </View>
      );
    }
    componentWillMount() {
      if (DeviceEventEmitter) {
        DeviceEventEmitter.removeAllListeners('hotel_modification_thankyou_page');
        this.modificationThankyouPageListener = DeviceEventEmitter.addListener('hotel_modification_thankyou_page', this.openModificationThankYouPage);
        this.paymentbackPressListener = DeviceEventEmitter.addListener('hotel_modification_back_press', this.handlePaymentBackPress);
      }
    }

    componentWillUnmount() {
      if (this.modificationThankyouPageListener) {
        this.modificationThankyouPageListener.remove();
      }
      if (this.paymentbackPressListener) {
        this.paymentbackPressListener.remove();
      }
    }

    handlePaymentBackPress = () => {
      this.setmamiViewState(ViewState.FORCE_CLOSE)
      setTimeout(()=>{
        this.setmamiViewState(ViewState.DEFAULT)
      }, 1000);
    }

    openModificationThankYouPage = (data) => {
      if (data && data.Payment_Status === 'PAYMENT_SUCCESS') {
        invalidateTrips();
        this.setmamiViewState(ViewState.COMMIT_SUCCESS)
      } else {
        this.setmamiViewState(ViewState.ERROR)
      }
    };

    trackHelpingHandEvents = (type, page, click, data) => {
      trackHelpingHandEvent(type, page, click, data, this.trackingPageName, this.props.hotelDetailResponse);
    }

    setHelpingHandOverlay = (value) => {
      this.setState({
        helpingHandOverlay: value
      });
    }

    showHelpingHandInModificationOverlay = () => {
      this.setState({
        showModificationOverlay: false,
      })
      this.setHelpingHandOverlay(true);
    }

    render() {
      return (
        <View
          style={{
                    width: '100%', height: '100%', justifyContent: 'space-between', alignItems: 'center'
                }}
        >
          {this.renderStateWise()}
        </View>
      );
    }

    renderStateWise() {
      if (this.state.viewState === ViewState.LOADING) {
        return this.renderProgressView();
      } else if (this.state.viewState === ViewState.ERROR) {
        this.trackError();
        return this.renderErrorView();
      } else if (this.state.viewState === ViewState.NO_INTERNET) {
        return this.renderNoNetworkView();
      }
      return this.renderPage();
    }

    async fetchJSONAsync(url, newSelectedDate = {}) {
      const {
        appVersion, exprimentData, deviceId, visitorId
      } = Platform.OS === "web" ? HotelBookingModule.getHotelDateChangeStaticData(this.props.bookingid) : await HotelBookingModule.getHotelDateChangeStaticData();
      const {mmtAuth, loggingTrackingInfo} = await HotelBookingModule.getRequestHeader(this.props.bookingid, 'Hotel_DateChange_ViewRoom');
      const { tryAgainText, noRoomAvailable, errorText: { soomethingWentWrongOnlyError }, checkForDiffDate } = getStaticData(LANG_AWARE.MODIFICATION);
      const {newCheckinDate, newCheckOutDate} = this.props;
      const {newCheckinDate: selectedNewCheckinDate, newCheckOutDate: selectedNewCheckOutDate} = newSelectedDate;
      try {
        const request =
                getModificationRequest(
                  appVersion,
                  exprimentData,
                  deviceId,
                  visitorId,
                  isEmpty(selectedNewCheckinDate) ? newCheckinDate : selectedNewCheckinDate,
                  isEmpty(selectedNewCheckOutDate) ? newCheckOutDate : selectedNewCheckOutDate,
                  this.props.bookingid,
                  false,
                  this.props.hotelDetailResponse.isCorporateBooking
                );
        const response = await
          fetch(url, {
            headers: await getCommonHeaders(mmtAuth, loggingTrackingInfo),
            body: JSON.stringify(request),
            method: 'POST'
          });
        if (response.ok) {
          const body = await response.json();
            if (body.unavailabilityInstructions) {
              let modificationFailureOverlayProps;
              const confirmationPopupProps = {
                ...getModificationConfirmationPopupData(body),
              }
              if (body.unavailabilityInstructions.type == 2) {
                modificationFailureOverlayProps = {
                  overlayTitle: noRoomAvailable,
                  overlayType: SOLD_OUT_AVAIL_OVERLAY_DC,
                  overlayPrimaryBtnProps: {
                    btnTxt: checkForDiffDate,
                    onTap: this.onHardBackPress,
                    clickEvent: CHK_DIFF_DC,
                  },
                  overlayEditProps: {
                    title: body.newDatesText,
                  }
                }
              } else {
                modificationFailureOverlayProps = {
                  overlayTitle: soomethingWentWrongOnlyError,
                  overlayType: SMTHNG_WRONG_OVERLAY_DC,
                  overlayPrimaryBtnProps: {
                    btnTxt: tryAgainText,
                    onTap: this.handleTryAgain,
                    clickEvent: RETRY,
                  },
                  overlayEditProps: null,
                }
              }
              this.setState({
                unavailabilityInstructions: body.unavailabilityInstructions,
                showModificationFailurePopup: true,
                responseJson: body,
                viewState: ViewState.SHOW_DETAIL,
                modificationFailureOverlayProps,
                confirmationPopupProps,
              });
            } else if (body.success) {
              this.trackSuccessLoadEvent(body, this.props.hotelDetailResponse);
              const confirmationPopupProps = {
                ...getModificationConfirmationPopupData(body),
              }
              this.setState({
                responseJson: body,
                viewState: ViewState.SHOW_DETAIL,
                confirmationPopupProps,
              });
          } else {
            this.setState({viewState: ViewState.ERROR});
          }
        } else {
          this.setState({viewState: ViewState.ERROR});
        }
      } catch (error) {
        this.setState({viewState: ViewState.ERROR});
      }
    }


    renderProgressView = () => (<LoadingView />);
    renderNoNetworkView = () => {
      const { backText } = getStaticData(LANG_AWARE.MODIFICATION);
      return (
        <View style={[{width: '100%'}, {flex: 1}]}>
          <SimpleHeader
            title={backText}
            iconPress={this.onHardBackPress}
          />
          <NoInternetView
            buttonStyle={getButtonGradientColor(this.props.hotelDetailResponse.isCorporateBooking)}
            onRetry={() => {
                  this.setState({viewState: ViewState.LOADING});
                  this.fetchJSONAsync(HOTEL_CHANGE_DATE_URL);
              }}
          />
        </View>
      )
    };

    onRetryErrorPage = () => {
      HotelBookingTrackingHelper.trackClickEvent(this.trackingPageName, RETRY, this.props.hotelDetailResponse);
      this.setState({ viewState: ViewState.LOADING });
      this.fetchJSONAsync(HOTEL_CHANGE_DATE_URL);
    }

    onHelpingHandClickErrorPage = () => {
      HotelBookingTrackingHelper.trackClickEvent(this.trackingPageName, CONNECT_WITH_US, this.props.hotelDetailResponse);
      this.setHelpingHandOverlay(true);
    }

    renderErrorView = () => {
      const { backText } = getStaticData(LANG_AWARE.MODIFICATION);
      return (
        <View style={[{width: '100%'}, {flex: 1}]}>
          <SimpleHeader
            title={backText}
            iconPress={this.onHardBackPress}
          />
          <ErrorPage
            showRetry
            buttonGradient={getButtonGradientColor(this.props.hotelDetailResponse.isCorporateBooking)}
            isCorporateBooking={this.props.hotelDetailResponse.isCorporateBooking}
            onRetry={this.onRetryErrorPage}
            showHelpingHand={this.showHelpingHand}
            helpingHandLabel={this.helpingHandData && this.helpingHandData.connectWithUsText}
            helpingHandClickHandler={this.onHelpingHandClickErrorPage}
            langAware={LANG_AWARE.MODIFICATION}
          />
          {this.showHelpingHand && this.state.helpingHandOverlay === true &&
            renderNeedHelpOverlay(this.helpingHandData, this.setHelpingHandOverlay)
          }
        </View>
      )
    };
}
HotelChangeDateViewPrice.propTypes =
    {
      bookingid: PropTypes.string.isRequired,
      hotelDetailResponse: PropTypes.object.isRequired
    };

export default HotelChangeDateViewPrice;
