import { StyleSheet, Dimensions } from 'react-native';
import { DEFAULT_THEME } from 'apps/post-sales/src/theme/default.theme';

export default <T extends typeof DEFAULT_THEME>(theme: T) => StyleSheet.create({
  containerPadding: {
    paddingHorizontal: 36,
    paddingTop: 8,
    paddingBottom: 16,
  },
  chatContainer: {
    marginHorizontal: -20,
    marginTop: -12,
  },
  overlayContainer: {
    height: 450,
    alignItems: 'center',
    justifyContent: 'center',
  },
  loaderContainer: {
    width: '100%',
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  overlayTopline: {
    width: 40,
    height: 4,
    borderRadius: 4,
    backgroundColor: theme.color.disabledButton,
    alignSelf: 'center',
  },
  crossIcon: {
    width: 20,
    height: 20,
    marginRight: 20,
    tintColor: theme.color.greyText1,
  },
  paddingTop20: { paddingTop: 20 },
  pnrChatContainer: {
    display: 'flex',
    alignItems: 'center',
    marginHorizontal: 16,
    paddingVertical: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderTopColor: theme.color.lightGrey,
    borderTopWidth: 1,
  },
  redCircle: {
    width: 24,
    height: 24,
    borderRadius: 15,
    backgroundColor: theme.color.red31,
    justifyContent: 'center',
    alignItems: 'center',
  },
  vendorChatDtls: {
    width: '70%',
  },
  notificationConatiner: {
    alignItems: 'flex-end',
    width: '30%',
  },
  pnrOverlay: {
    height: 15,
    width: '100%',
    backgroundColor: theme.color.white,
    position: 'absolute',
    top: 80,
    zIndex: 100,
    left: 20,
  },
  menuOverlay:{
    height: 30,
    width: 30,
    backgroundColor: theme.color.white,
    position: 'absolute',
    top: 20,
    zIndex: 100,
    left: 40,
  },
});
