import { StyleSheet } from 'react-native';
import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';

const styles = StyleSheet.create({
  completeTripToast: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    bottom: 100,
    zIndex: 5,
    ...getPlatformElevation(2),
    width: '100%',
  },
});
export default styles;
