import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';

const styles = {
  infoCardWrapper: {
    backgroundColor: '#ffedd1',
    ...getPlatformElevation(2),
    paddingVertical: 20,
    paddingHorizontal: 16,
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  infoCardHading: {
    flexDirection: 'row',
  },
  headingTxt: {
    color: '#cf8100',
    flex: 1,
    marginBottom: 15,
  },
  infoCardDisc: {
    color: '#4a4a4a',
    lineHeight: 20,
    paddingLeft: 20,
    paddingRight: 20
  },
  bulletCircle: {
    width: 6,
    height: 6,
    backgroundColor: '#5a5a5a',
    borderRadius: 20,
    top: 5,
    left: 5,
  },
  infoIcon: {
    width: 20,
    height: 20,
    marginRight: 10,
  },
  redirectBtnWrapper: {
    paddingHorizontal: 15,
    paddingVertical: 15,
    justifyContent: 'space-between',
    flexDirection: 'row',
    backgroundColor: '#fff',
    ...getPlatformElevation(2),
    alignItems: 'center',
    marginTop: 2,
    marginBottom: 1,
  },
  rightArrowIcon: {
    width: 9,
    height: 13,
  },
  linkBtnTxt: {
    color: '#008cff',
  },
};
export default styles;
