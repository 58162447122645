import { StyleSheet } from 'react-native';

export default (fonts, theme) => {
  return StyleSheet.create({
    headingSection: {
      padding: 16,
      paddingBottom: 0,
      paddingLeft: 22,
    },
    container: {
      backgroundColor: theme.color.grayBg,
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
    },
    overlayStyle: {
      padding: 0,
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
    },
    headingText: {
      color: theme.color.black22,
      ...fonts.blackFontFamily,
      ...fonts.font18,
    },
    bodySection: {
      paddingVertical: 12,
      paddingHorizontal: 20,
    },
    crossIconStyle: {
      width: 24,
      height: 24,
      tintColor: theme.color.greyText1,
    },
    crossIconWrapper: {
      width: 30,
      height: 30,
      top: 0,
      right: 0,
      alignItems: 'center',
      justifyContent: 'center',
    },
  });
};
