import _trim from 'lodash/trim';
import {isValidEmail, isValidMobile} from '../../../Helpers/validationHelpers';

export function validateEmail(email) {
  if (!_trim(email)) {
    return {
      error: 'Empty email',
      valid: false
    };
  }
  if (!isValidEmail(email)) {
    return {
      error: 'Invalid email',
      valid: false
    };
  }
  return {
    error: '',
    valid: true
  };
}

export function validateMobile(mobile) {
  if (!_trim(mobile)) {
    return {
      error: 'Empty mobile',
      valid: false
    };
  }
  if (_trim(mobile).length < 10 || !isValidMobile(_trim(mobile))) {
    return {
      error: 'Invalid mobile',
      valid: false
    };
  }
  return {
    error: '',
    valid: true
  };
}
