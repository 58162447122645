import React, {Component} from 'react';
import {View, TouchableOpacity, Image} from 'react-native';
import PropTypes from 'prop-types';
import InputFieldMaster from './InputFieldMaster';
import styles from './DynamicFormCss';
import {CloseIcon} from '../../HolidayBookingConstants';
import {getIndex} from './DynamicFormUtils';


class FormGroupInput extends Component {
  constructor(props) {
    super(props, 'DynamicForm');
    this.arrLength = 0;
    this.state = {
      vals: [],
      validate: this.props.validate
    };
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      validate: newProps.validate
    });
  }

  componentWillMount() {
    this.props.updateChildValues(`${this.props.jsonKey}.parent`, '');
    this.props.updateChildValues(`${this.props.jsonKey}.value`, 'true');
    const values = [];
    this.props.visaForm.fields[this.props.fieldName].dependents.true.forEach((element) => {
      values.push({
        labelIndex: `${this.props.labelIndex}.${values.length + 1}`,
        key: `${this.props.jsonKey}${element}`,
        parent: '',
        jsonKey: `${this.props.jsonKey}.dependents`,
        fieldName: element,
        visaForm: this.props.visaForm,
        updateChildValues: this.props.updateChildValues,
        validate: this.state.validate,
        setField: this.props.setField,
        showDropDown: this.props.showDropDown
      });
    });
    this.state.vals = values;
  }

  renderContent = () => {
    const content = [];
    this.state.vals.forEach((element) => {
      content.push(<InputFieldMaster
        focusOnNextField={this.props.focusOnNextField}
        textInputRefs={this.props.textInputRefs}
        labelIndex={element.labelIndex}
        key={element.key}
        parent={element.parent}
        jsonKey={element.jsonKey}
        fieldName={element.fieldName}
        visaForm={element.visaForm}
        updateChildValues={element.updateChildValues}
        validate={this.state.validate}
        setField={element.setField}
        showDropDown={element.showDropDown}
      />);
    });
    return content;
  }

  shouldShowCancelIcon = () => {
    if (this.props.visaForm.fields[this.props.fieldName].mandatory) {
      return this.props.visaForm.fields[this.props.fieldName] &&
      this.props.visaForm.fields[this.props.fieldName].addMore &&
      getIndex(this.props.jsonKey) !== '0';
    }
    return this.props.visaForm.fields[this.props.fieldName] &&
      this.props.visaForm.fields[this.props.fieldName].addMore;
  }

  render() {
    return (
      <View>
        {this.shouldShowCancelIcon() &&
        <View style={[styles.flex1, styles.flexRow, styles.mar12Bt, styles.visaFormCloseIcon]}>
          <TouchableOpacity onPress={() => this.props.deleteWRTIndex(this.props.indexInParent)}>
            <Image source={CloseIcon} style={styles.visaFormCloseIconImage} />
          </TouchableOpacity>
        </View>}
        {this.renderContent()}
      </View>
    );
  }
}

FormGroupInput.propTypes = {
  labelIndex: PropTypes.string.isRequired,
  parent: PropTypes.string.isRequired,
  jsonKey: PropTypes.string.isRequired,
  visaForm: PropTypes.object.isRequired,
  fieldName: PropTypes.string.isRequired,
  validate: PropTypes.bool.isRequired,
  updateChildValues: PropTypes.func.isRequired,
  setField: PropTypes.func.isRequired,
  showDropDown: PropTypes.func.isRequired,
  deleteWRTIndex: PropTypes.func.isRequired,
  indexInParent: PropTypes.number.isRequired,
  textInputRefs: PropTypes.object.isRequired,
  focusOnNextField: PropTypes.func.isRequired
};


export default FormGroupInput;
