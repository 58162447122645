import { Platform } from 'react-native';
import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';
import {fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import { ps_colors } from '../../../PostSalesStyles';

const styles = {
  infoCardWrapper: {
    backgroundColor: ps_colors.white,
    justifyContent: 'space-between',
    flexDirection: 'row',
    ...getPlatformElevation(2),
    marginBottom: 1
  },
  infoCardContent: {
    paddingHorizontal: 8,
    paddingVertical: 15,
    flex: 1
  },
  infoCustom: {
    fontSize: 14,
    color: ps_colors.textGrey,
    lineHeight: 18,
    fontFamily: fonts.regular
  },
  headingIconWrapper: {
    width: 46,
    alignItems: 'flex-start',
    top: 14,
    left: 16
  },
  headingIconStyle: {
    width: 20,
    height: 19
  },
  transperantBg: {
    ...Platform.select({
      ios: {
        backgroundColor: 'transparent'
      }
    })
  },
  hideView: {
    display: 'none'
  },
  redirectBtnWrapper: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 60,
    paddingRight: 35,
    justifyContent: 'space-between',
    flexDirection: 'row',
    backgroundColor: ps_colors.white,
    borderBottomWidth: 1,
    borderBottomColor: ps_colors.gray1,
    alignItems: 'center'
  },

  rightArrowIcon: {
    width: 9,
    height: 13
  },

  linkBtnTxt: {
    fontSize: 14,
    fontFamily: fonts.bold,
    color: ps_colors.azure
  }
};

export default styles;
