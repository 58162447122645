import React from 'react';
import { View, Text, Image } from 'react-native';
import get from 'lodash/get';
import { WARNING_ICON } from '../../FlightImageConstants';
import ReasonRefundRqstCard from './ClaimReason/ReasonRefundRqstCard';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {
  getAllPossibleScActionLists,
  shouldNotRedirectToBreakUpPage,
  getActiveSegment,
} from '../CancellationValidationUtil';
import { getFont } from '../../../PostSalesStyles';

export const getHeading = (segment, shouldShowWarning) => {
  const { fsStyle, ...fonts } = getFont(true);
  const heading = get(segment, 'heading', '');
  if (heading) {
    return (
      <View style={[AtomicCss.flex1, styles.messageContent]}>
        {shouldShowWarning && <Image source={WARNING_ICON} style={styles.warningIcon} />}
        <Text
          style={[
            AtomicCss.flex1,
            AtomicCss.alignLeft,
            ...(shouldShowWarning
              ? [styles.sclaimHeader, fonts.blackFontFamily, fsStyle.font16]
              : [styles.sclaimInfo, fonts.regularFontFamily, fsStyle.font14]),
          ]}
        >
          {heading}
        </Text>
      </View>
    );
  }
  return null;
};

export const getDescription = (segment, multiSeg = false) => {
  const description = get(segment, 'description', '');
  const { fsStyle, ...fonts } = getFont(true);
  if (description) {
    return (
      <View style={[AtomicCss.flex1, AtomicCss.paddingRight10]}>
        <Text
          style={[
            ...(multiSeg
              ? [styles.sclaimDescMultiSegDes]
              : [styles.sclaimDesc, fonts.regularFontFamily, fsStyle.font13]),
            AtomicCss.alignLeft,
          ]}
        >
          {description}
        </Text>
      </View>
    );
  }
  return null;
};

export const SingleSegmentSinglePaxCom = React.memo(({ cancelRresponse, onItemClick }) => {
  const heading = get(cancelRresponse, 'cancellationValidation.segments[0]', '');
  const description = get(cancelRresponse, 'cancellationValidation.segments[0]', '');

  return (
    <View>
      {getHeading(heading, true)}
      {getDescription(description)}
      <ReasonRefundRqstCard
        claimReasonHeader=""
        componnetType="CTIP"
        specialClaimDataList={getAllPossibleScActionLists(
          cancelRresponse.cancellationValidation,
          'segment',
          cancelRresponse.flightDetails.segmentGroupDetailList[0],
        )}
        onItemClick={onItemClick}
      />
    </View>
  );
});

export const ValidationMsgCom = React.memo(({ response }) => {
  const { fsStyle, ...fonts } = getFont(true);
  const flightDetails = get(response, 'flightDetails', {});
  const noOfsegGrp = flightDetails.segmentGroupDetailList.length;
  if (noOfsegGrp === 1) {
    const segments = flightDetails.segmentGroupDetailList[0].segmentDetails;
    if (segments && segments.length === 1) {
      const activeSegment = getActiveSegment(
        flightDetails.segmentGroupDetailList[0],
        response.cancellationValidation,
      );
      const description = get(activeSegment, 'description', '');
      if (description && activeSegment && !shouldNotRedirectToBreakUpPage(response)) {
        return (
          <View style={[AtomicCss.flex1, AtomicCss.paddingRight10]}>
            <Text style={[styles.validationMsg, fonts.boldFontFamily, fsStyle.font14]}>
              {description}
            </Text>
          </View>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  } else {
    return null;
  }
});

const styles = {
  sclaimHeader: {
    color: '#282828',
    paddingLeft: 10,
    paddingRight: 20,
    paddingBottom: 4,
  },
  sclaimDesc: {
    color: colors.black,
    paddingLeft: 57,
    paddingRight: 20,
    paddingBottom: 25,
  },
  validationMsg: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 10,
    color: '#1a7971',
  },
  warningIcon: {
    width: 35,
    height: 35,
    marginBottom: 2,
  },
  messageContent: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 8,
    paddingRight: 8,
  },
  sclaimInfo: {
    color: '#cf8100',
    paddingLeft: 38,
    paddingRight: 20,
    paddingBottom: 2,
  },
  sclaimDescMultiSegDes: {
    paddingLeft: 48,
    paddingTop: 4,
    paddingBottom: 8,
  },
};
