import React from 'react';
import {Text,TouchableOpacity, StyleSheet, View} from 'react-native';
import { getFont } from '../../../../../PostSalesStyles';
import { useTheme } from '../../../../../theme/theme.context';
import { DEFAULT_THEME } from '../../../../../theme/default.theme';
import { WheelChairTabsProps } from './wheelChairTypes';
import { Dimensions } from 'react-native';

const WheelchairTabs: React.FC<WheelChairTabsProps> = ({segmentLineNo, onHandleTabChange, activeTab: activeSegmentLineNo, arrivalCityCode, departureCityCode}) => {

    const { fsStyle, ...fonts }  = getFont(true);
    const {psTheme : theme} = useTheme();
    const styles = createStyles(theme, fonts);
    const isActiveTab = segmentLineNo === activeSegmentLineNo;

    return(
        <>
        <TouchableOpacity style={[styles.tabStyle, segmentLineNo === activeSegmentLineNo ? styles.tabActive : {}]} onPress={()=> onHandleTabChange(segmentLineNo)}>
            <View style={{flexDirection: 'row'}}>
                <Text style={[styles.tabStyleTxt, fsStyle.font16, isActiveTab ? styles.tabTxtActive : {}]}>{departureCityCode}</Text>
                <Text style={[styles.tabStyleTxt, fsStyle.font16, isActiveTab ? styles.tabTxtActive : {}]}>-</Text>
                <Text style={[styles.tabStyleTxt, fsStyle.font16, isActiveTab ? styles.tabTxtActive : {}]}>{arrivalCityCode}</Text>
            </View>
        </TouchableOpacity>
        </>
    )
}

const createStyles = <T extends typeof DEFAULT_THEME>(theme: T, fonts: any) => StyleSheet.create({
    tabStyle:{
        paddingBottom:10,
        paddingHorizontal:12,
        width:Dimensions.get('window').width / 2,
        justifyContent:'center',
        alignItems:'center'
    },
    tabStyleTxt:{
        color: theme.color.defaultTextColor,
        fontFamily: fonts.regularFontFamily.fontFamily,
    },
    tabActive:{
        borderBottomColor: theme.color.lightBlue17,
        borderBottomWidth:3,
    },
    tabTxtActive:{
        color: theme.color.lightBlue17,
        fontFamily: fonts.boldFontFamily.fontFamily,  
    },
});

export default WheelchairTabs