import React from 'react';
import {Image, Text, View} from 'react-native';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import {getCommonHeaders, HOTEL_ADD_GUEST_NAMES_URL} from '../../../utils/NetworkUtils';
import {isNetworkAvailable} from '@mmt/legacy-commons/Common/utils/AppUtils';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import getStyles from '../../styles/AddGuestNamesCss';
import HotelBookingDetailsConstant from '../HotelBookingDetailsConstant';
import {
  getHotelName,
  getPageName,
  getPrimaryCustomerEmailId, resetToHotelDetail
} from '../utils/HotelBookingDetailUtil';
import PropTypes from 'prop-types';
import HotelBookingTrackingHelper from '../../HotelBookingTrackingHelper';
import ProgressView from '../../../Common/ProgressView/ProgressView';
import CapsuleFullBlueButton from '../../../Common/CapsuleBlueFullButton';
import withRouterWrapper from '../../../Common/Web/withRouterWrapper';
import {connect} from 'react-redux';
import createAction from '../../reducer/actionCreator';
import {HOTEL_DETAIL_ACTIONS} from '../../reducer/actions';
import {ALERT_RED_BG_CLOUD_ICON, PAX_GOLD_BG_ICON} from '../../HotelImageConstants';
import PostSalesBasePage from 'apps/post-sales/src/Common/PostSalesBasePage';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const successIcon = PAX_GOLD_BG_ICON;
const failureIcon = ALERT_RED_BG_CLOUD_ICON;
const PAGE_NAME = 'AddGuestNamesResult:';

class AddGuestNamesResult extends PostSalesBasePage {

  constructor(props) {
    super(props, 'addGuestNamesResult');
    this.fonts = getFont(true);
    this.styles = getStyles(this.fonts);
    this.state = {
      response: null,
      viewState: ViewState.LOADING
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.fetchJSONAsync();
  }

  async fetchJSONAsync() {
    try {
      const hasNetwork = await isNetworkAvailable();
      if (!hasNetwork) {
        this.setState({viewState: ViewState.NO_INTERNET});
        return;
      }
      const userInfo = await HotelBookingModule.getRequestHeader(this.bookingId, 'HOTEL_ADD_GUEST_NAMES');
      const response = await
        fetch(HOTEL_ADD_GUEST_NAMES_URL, {
          method: 'POST',
          headers: await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo),
          body: JSON.stringify(this.getAddGuestNamesRequest())
        });
      const body = await response.json();
      if (response && response.ok && body.success) {
        this.setState({
          response: body,
          viewState: ViewState.SHOW_DETAIL
        });
        HotelBookingTrackingHelper.trackLoadEvent(this.getTrackingPageName(), this.props.response);
      } else {
        this.setState({viewState: ViewState.ERROR});
        this.trackError(response.status);
      }
    } catch (error) {
      this.setState({viewState: ViewState.ERROR});
      this.trackError(error.message);
    }
  }

  getAddGuestNamesRequest() {
    return {
      bookingId: this.props.bookingId,
      passengers: this.props.passengers
    };
  }

  render() {
    return (
      <View style={{flex: 1}}>
        {this.state.viewState === ViewState.LOADING &&
        <ProgressView message={'We are adding names...'}/>}
        {this.state.viewState === ViewState.SHOW_DETAIL && this.renderPage()}
        {this.state.viewState === ViewState.ERROR && this.renderErrorView()}
      </View>
    );
  }

  renderErrorView() {
    return (
      <View style={this.styles.pageWrapper}>
        <View style={this.styles.messageContent}>
          <Image style={this.styles.messageImg} source={failureIcon}/>
          <Text style={this.styles.messageTitle}>We are unable to update the {this.getPluralString('name')} at the moment.
            {'\n'}{'\n'}
            Please try again after some time.
          </Text>
        </View>
        <View style={this.styles.footerBtnWrapper}>
          {this.goBackToBookingCTA('GO BACK TO BOOKING')}
        </View>
      </View>
    );
  }

  renderPage() {
    return (
      <View style={this.styles.pageWrapper}>
        <View style={this.styles.messageContent}>
          <Image style={this.styles.messageImg} source={successIcon}/>
          <Text style={this.styles.messageTitle}>
            {this.state.response?.successMessage || `You have successfully added ${this.getAddedPaxCount()} ${this.getPluralString('name')} to your hotel booking!`}
          </Text>
          <Text style={this.styles.messageDesc}>
            For your upcoming booking at {getHotelName(this.props.response)}, you have added
            {' '}{this.getAddedPaxNames()} to the list of guests.</Text>
          <Text style={this.styles.messageDesc}>
            The updated booking voucher has been e-mailed to
            {'\n'}{getPrimaryCustomerEmailId(this.props.response)}
          </Text>
        </View>
        <View style={this.styles.footerBtnWrapper}>
          {this.goBackToBookingCTA('SEE UPDATED BOOKING')}
        </View>
      </View>
    );
  }

  getAddedPaxCount() {
    return this.props.passengers.length;
  }

  getPluralString = (str) => {
    const paxCount = this.getAddedPaxCount();
    return paxCount > 1 ? str.concat('s') : str;
  };

  getAddedPaxNames() {
    let paxString = '';
    this.props.passengers.forEach((passenger, index) => {
      let joiner = '';
      if (index === this.props.passengers.length - 2) {
        joiner = ' and ';
      } else if (index < this.props.passengers.length - 2) {
        joiner = ', ';
      }
      paxString += passenger.firstName + ' ' + passenger.lastName + joiner;
    });
    return paxString;
  }

  goBackToBookingCTA(actionLabeltext) {
    const action = {
      actionLabeltext,
      actionFamily: HotelBookingDetailsConstant.ACTION_VIEW_SEE_UPDATED_BOOKING
    };
    return <CapsuleFullBlueButton
      action={action}
      clickHandler={(action) => this.seeUpdatedBooking()}
    />;
  }

  seeUpdatedBooking() {
    resetToHotelDetail(this.props.bookingId);
    this.props.resetHotelDetail(this.props.bookingId);
  }

  trackError() {
    HotelBookingTrackingHelper.trackErrorEvent(this.getTrackingPageName(), this.props.response);
  }

  getTrackingPageName() {
    const pageName = getPageName(this.props.response, PAGE_NAME);
    return pageName.concat('_')
      .concat(this.state.viewState == ViewState.SHOW_DETAIL ? 'success'
        : 'error');
  }

  onHardBackPress = () => {
    this.seeUpdatedBooking();
    return true;
  };
}

const mapDispatchToProps = dispatch => ({
  resetHotelDetail: resetBookingId =>
    dispatch(createAction(HOTEL_DETAIL_ACTIONS.RESET_HOTEL_BOOKING_DETAIL, resetBookingId))
});


export default withRouterWrapper(connect(null, mapDispatchToProps)(AddGuestNamesResult));

AddGuestNamesResult.propTypes = {
  bookingId: PropTypes.string.isRequired,
  passengers: PropTypes.array.isRequired,
  response: PropTypes.object.isRequired
};
