import React, { useEffect, useRef, useState } from 'react';
import { millisToSeconds } from '../../utils/PostSaleUtil';
import PercentageCircle from '../CircularProgressBar';

const CircularCountdown = ({
  initialSeconds,
  onComplete,
  ...props
}) => {
  const [counter, setCounter] = useState(millisToSeconds(initialSeconds));
  const counterInterval = useRef();
  useEffect(() => {
    if(counterInterval.current) clearTimeout(counterInterval.current)
    const parsedCounter = parseInt(counter)
    if (parsedCounter > 0) {
      counterInterval.current = setTimeout(() => {
        setCounter(counter => (counter <= 10) ? `0${counter - 1}` : counter - 1)
      }, 1000)
    } else if (parsedCounter === 0) {
      onComplete()
    }
    return () => {
      clearTimeout(counterInterval.current);
    }
  }, [counter])
  return <PercentageCircle
    percent={(counter / millisToSeconds(initialSeconds)) * 100}
    counter={counter}
    showCountdown
    {...props}
  />
}


export default React.memo(CircularCountdown);