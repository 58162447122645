import {colors} from '@mmt/legacy-commons/Styles/globalStyles';

const styles = {
    checkbox: {
      height: 20,
      width: 20,
      backgroundColor: colors.white,
      borderColor: colors.iconGrey,
      borderWidth: 1.5,
      borderRadius: 3,
      overflow: 'hidden',
      position: 'relative',
    },
    checkboxSelected: {
      borderColor: 'transparent',
      borderWidth: 0
    },
    checkedIcon: {
      position: 'absolute',
      height: 24,
      width: 24,
      left: -2,
      top: -2
    },
    checkBoxWrapper:{paddingTop: 13, paddingBottom: 13}
  };

  export default styles;
