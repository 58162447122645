import React from 'react';
import Actions from '../../../navigation/postSalesNavigation';
import PropTypes from 'prop-types';
import {View} from 'react-native';
import getStyles from './HotelMamiCss';
import HotelBookingDetailsConstant from '../HotelBookingDetailsConstant';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import {getCommonHeaders, PAYNOW_SUBMIT_URL} from '../../../utils/NetworkUtils';
import {isNetworkAvailable} from '@mmt/legacy-commons/Common/utils/AppUtils';
import {appendRupeeSymbol, getCardNoFromPayment, getAmountDue} from '../utils/HotelBookingDetailUtil';
import PostSalesHotelsBaseClass from './PostSalesHotelsBaseClass';
import ViewStates from '../utils/ViewStates';
import { WHITE_LEFT_ARROW_ICON } from '../../HotelImageConstants';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

class HotelPayNow extends PostSalesHotelsBaseClass {
  constructor(props) {
    super(props, 'hotelPayNow', 'PayNowTy');
    this.fonts = getFont(true);
    this.styles = getStyles(this.fonts);
    this.state = {
      viewState: ViewStates.LOADING
    };
  }

  render() {
    return (
      <View style={this.styles.flex1}>
        {this.state.viewState === ViewStates.LOADING && this.renderProgressView('Initializing Payment …')}
        {this.state.viewState === ViewStates.NO_INTERNET && this.renderNoNetworkView()}
        {this.state.viewState === ViewStates.ERROR && this.openEasyPayPage()}
        {this.state.viewState === ViewStates.FETCH_DATA && this.gotoThankYouPage()}
      </View>
    );
  }
  fetchData() {
    this.fetchJSONAsync();
  }

  gotoThankYouPage() {
    const amount = getAmountDue(this.props.response);
    const subMsg = `Thanks! Payment of ${amount} has been completed using ${getCardNoFromPayment(this.props.response.paymentDetails)} card. We have mailed the updated booking voucher to you.`;
    Actions.psThankYouPage({
      msg: 'Payment Successful',
      subMsg,
      bookingID: this.props.response.bookingID,
      lob: 'Hotel'
    });
  }

  openEasyPayPage() {
    Actions.openPsWebView({
      url: `https://payments.makemytrip.com/easypay?bookingId=${this.props.response.bookingID}&mobile=${this.props.response.hotelBookingInfo.primaryCustomerDetails.phoneNumber}`,
      headerText: 'Pay Now',
      headerIcon: WHITE_LEFT_ARROW_ICON
    });
    HotelBookingModule.trackOmniture(
      JSON.stringify(this.props.response),
      this.props.response.deepLinkSource, HotelBookingDetailsConstant.REACT_HOTEL_EASY_PAY_WEB_VIEW_PAGE, ''
    );// explicitly done here to make the web view page generic
  }

  componentDidMount() {
    this.fetchJSONAsync();
  }

  async fetchJSONAsync() {
    try {
      const hasNetwork = await isNetworkAvailable();
      if (!hasNetwork) {
        this.setState({viewState: ViewStates.NO_INTERNET});
        return;
      }
      const request = {
        bookingId: this.props.response.bookingID
      };
      const {mmtAuth, loggingTrackingInfo} = await HotelBookingModule.getRequestHeader(this.props.response.bookingID, 'Submit_PAY_Now');
      const response =
                await fetch(PAYNOW_SUBMIT_URL, {
          method: 'POST',
          body: JSON.stringify(request),
          headers: await getCommonHeaders(mmtAuth, loggingTrackingInfo)
        });
      if (response.ok) {
        const respBody = await response.json();
        if (respBody.mojoSuccess) {
          this.setState({viewState: ViewStates.FETCH_DATA});
        } else {
          this.setState({viewState: ViewStates.ERROR});
        }
      } else {
        this.setState({viewState: ViewStates.ERROR});
      }
    } catch (error) {
      this.setState({viewState: ViewStates.ERROR});
    }
  }
}

HotelPayNow.propTypes = {
  response: PropTypes.object.isRequired
};
export default HotelPayNow;
