import { StyleSheet } from 'react-native';

export default (theme, fonts) =>
  StyleSheet.create({
    
  paxCancelledinnderDtls: {
    paddingVertical: 12,
    paddingHorizontal: 16,
  },
  fltDtlsTxt: {
    color: theme.color.black,
  },
  UserIcon: {
    width: 16,
    height: 12,
    marginRight: 8,
    marginTop: 2,
  },
  refundtopDisc: {
    marginBottom: 10,
  },
  refundtopDiscPax: {
    marginBottom: 10,
  },
  topGreyBorder: {
    borderTopColor: theme.color.lightGrey,
    borderTopWidth: 1,
  },
  zcIconSm: {
    width: 16,
    height: 16,
    marginRight: 8,
  },
  moreTraveller: {
    color: theme.color.black,
    marginLeft: 20,
  },
});
