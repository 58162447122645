import React from 'react';
import { View, Text } from 'react-native';
import BaseButton, { BUTTON_VARIANT } from '../../../Common/BaseButton';
import { useTheme } from '../../../theme/theme.context';
import { getFont } from '../../../PostSalesStyles';
import createStyles from './indexStyleCss';
import handleClickEvent from '../../../Common/commonUtil';
import isEmpty from 'lodash/isEmpty';
import AtomicCss from '../../commonStyles/AtomicCss';

interface NeedHelpUsProps {
  card?: {
    heading?: string;
    actionList?: { actionLabeltext?: string }[];
    subHeading: string;
  };
  pageName: string;
  phoneNumber: string;
}

const NeedHelpUs: React.FC<NeedHelpUsProps> = (props) => {
  const { card, pageName, phoneNumber } = props;
  const { heading, actionList, subHeading } = card || {};
  const cardAction = Array.isArray(actionList) && actionList.length > 0 ? actionList[0] : {};
  const { psTheme: theme } = useTheme();
  const fonts = getFont(true);
  const styles = createStyles(theme, fonts);

  const handleClick = () => {
    handleClickEvent(card, cardAction, null, pageName, phoneNumber);
  };
  if (isEmpty(cardAction.actionLabeltext) || isEmpty(phoneNumber)) {
    return null;
  }
  return (
    <View style={styles.topContainer}>
      <View style={styles.needhelpCard}>
        <View style={styles.ContentPortion}>
          <Text style={[styles.header]}>{heading || ''}</Text>
        </View>
      </View>

      <View style={styles.container}>
        {subHeading ? (
          <Text style={[styles.subHeading, AtomicCss.defaultTextColor]}>{subHeading}</Text>
        ) : null}
        <BaseButton
          variant={BUTTON_VARIANT.CAPSULE}
          text={cardAction.actionLabeltext}
          clickHandler={handleClick}
          isThrottleRequired={false}
        />
      </View>
    </View>
  );
};

export default NeedHelpUs;
