import React from 'react';
import {View, Text, TouchableOpacity} from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import Header from "../../../../Common/HeaderCross";
import SearchInput from "../SearchInput";
import {isNetworkAvailable} from '@mmt/legacy-commons/Common/utils/AppUtils';
import _ from 'lodash';
import {CancelAndRebookConstants} from '../CancelAndRebookConstants';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import ProgressView from '@mmt/legacy-commons/Common/Components/Progress/ProgressView';
import Actions from '../../../../navigation/postSalesNavigation';

class SearchLocation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: this.props.formData,
            locationList: null,
            toLocation: this.props.formData.to.name,
            fromLocation: this.props.formData.from.name,
            changedToLocation: this.props.formData.to.name,
            changedFromLocation: this.props.formData.from.name,
            isPickupLocation: false,
            toLocationDetails: null,
            fromLocationDetails: null,
            viewState: ViewState.DEFAULT,
            showLoaderForToLocation: false,
            showLoaderForFromLocation : false
        }
    }

    searchFromLocation = (input) => {
        this.setState({isPickupLocation : true})
        this.fetchMatchedLocation(input)

    }

    searchToLocation = (input) => {
        this.setState({isPickupLocation : false})
        this.fetchMatchedLocation(input)
    }

    onBackPress = () => {
        Actions.pop();
    };

    setNewlySelectedLocation = (fromLocation, toLocation) => {
        const newLocation = this.state.formData;
        if(fromLocation != null || !_.isEmpty(fromLocation)){
            newLocation["from"]["name"] = fromLocation.description;
            newLocation["from"]["locationId"] = fromLocation.Place_id
        }
        if(toLocation != null || !_.isEmpty(toLocation)){
            newLocation["to"]["name"] = toLocation.description;
            newLocation["to"]["locationId"] = toLocation.Place_id
        }
        Actions.cabModifyBooking({response : this.props.response, keyValue : 'date', formData : newLocation});
    }

    onChangeOfLocation = (newLocation, isPickupLocation) => {
        const val = { 'description' : newLocation.description , 'Place_id':newLocation.place_id };
        if(isPickupLocation){
            this.setState({fromLocation : val.description})
            this.setState({changedFromLocation : val.description})
            this.setState({fromLocationDetails : val});
            this.setNewlySelectedLocation(val,this.state.toLocationDetails)
        }else{
            this.setState({toLocation : val.description})
            this.setState({changedToLocation : val.description})
            this.setState({toLocationDetails : val});
            this.setNewlySelectedLocation(this.state.fromLocationDetails,val)
        }
    }

    async fetchMatchedLocation(input){
        try{
            const hasNetwork = await isNetworkAvailable();
            if (!hasNetwork) {
                return;
            }
            const url = `https://gocars.goibibo.com/v1/google/places/text_search?input=${input}&limit=10&media=source&rf=at&vertical=GoCars&flavour=ios&flow_name=QuickBook`;
            const response = await fetch(url, {headers: {'Authorization' : 'Basic Zm9vOmJhcg=='}});
            if (response && response.ok) {
                const body = await response.json();
                if(body.status === 'OK'){
                    this.setState({locationList:body})
                }
            }

        }catch(error){
           console.error('An error occurred', error)
        }
    }

    handleFocus = (flag) => {
        if(flag){
           this.setState({fromLocation : null,changedFromLocation : null, isPickupLocation : true, showCurrentLocation: true })
        }else{
             this.setState({toLocation : null,changedToLocation : null , isPickupLocation : false, showCurrentLocation : true})
        }
    }

    renderPage = () => (
        <View style={[AtomicCss.flex1, AtomicCss.greyBg]}>
            <Header
                title={CancelAndRebookConstants.MODIFY_BOOKING_HEADING}
                headingTxt="font18"
                headingSubTxt="font12"
                handleClose={this.onBackPress}
            />
            <View style={[AtomicCss.makeRelative, AtomicCss.paddingHz24]}>
                <SearchInput heading="From" formValue={this.state.changedFromLocation} onClickOfInputTab={this.searchFromLocation} type='pickupLocation' handleFocus={this.handleFocus} showLoader={this.state.showLoaderForFromLocation}/>
                <SearchInput heading="To" formValue={this.state.changedToLocation} onClickOfInputTab={this.searchToLocation} type='dropOfLocation' handleFocus={this.handleFocus} showLoader={this.state.showLoaderForToLocation}/>
                {
                    (this.state.locationList && this.state.locationList.predictions) ?
                        ( <View style={[AtomicCss.flexRow, AtomicCss.marginTop20]} activeOpacity={0.7}>
                            <View>
                                <Text style={[AtomicCss.font12, AtomicCss.boldFont, AtomicCss.lightText, AtomicCss.marginBottom3]}>Location list</Text>
                                {
                                    this.state.locationList && this.state.locationList.predictions &&
                                    this.state.locationList.predictions.map(location => (
                                        <TouchableOpacity onPress={() => this.onChangeOfLocation(location, this.state.isPickupLocation)}>
                                            <Text style={[AtomicCss.font16, AtomicCss.regularFont, AtomicCss.defaultText, AtomicCss.marginTop15]}>{location.structured_formatting.main_text}</Text>
                                            <Text style={[AtomicCss.font12, AtomicCss.lightFont, AtomicCss.darkGreyText, AtomicCss.marginTop5]}>{location.structured_formatting.secondary_text}</Text>
                                        </TouchableOpacity>
                                    ))
                                }
                            </View>
                        </View>):
                        (<View>
                        </View>)
                }
            </View>
        </View>
    )

    renderProgressView = () => (<ProgressView />);

    render() {
        return (
            <View style={AtomicCss.flex1}>
                {this.state.viewState === ViewState.LOADING && this.renderProgressView()}
                {this.state.viewState === ViewState.DEFAULT && this.renderPage()}
            </View>
        );
    }
}

export default SearchLocation;
