import React, {useState, useMemo } from 'react';
import Actions from '../../../navigation/postSalesNavigation';
import { View, Text, Image, I18nManager, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import styles from './RefundCardCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {fonts,colors} from '@mmt/legacy-commons/Styles/globalStyles';
import Tracker from '../Tracker';
import { isNonEmpty } from '@mmt/legacy-commons/Common/utils/StringUtils';
import WhatNextCard from '../whatNextCard';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import { getImagePath, setWebViewAuth } from '../../../utils/PostSaleUtil';
import {
  CASH_IN_HAND_ICON,
  RUPEE_GREY_BG_ICON,
} from '../../../hotel/HotelImageConstants';
import LostIdRefundCard from '../LostIdRefundCard';
import HTMLView from "react-native-htmlview";
import {onRefundCardHTMLLinkPress, htmlStyle} from '../../../utils/RefundCardUtils';
import { getStaticData } from '../../../staticData/staticData';

const back_white_icon = require('@mmt/legacy-assets/src/back-white.webp');
const userIcon = require('@mmt/legacy-assets/src/userIcon.webp');
const rupeeIcon = I18nManager.isRTL ? require('@mmt/legacy-assets/src/ic_refundAraCurrency.webp') : require('@mmt/legacy-assets/src/refundIcon.webp');
const doneIcon = getImagePath('double-tick.webp');
const priceIcon = require('@mmt/legacy-assets/src/Images/priceIcon.webp');
const directlyCancIcon = require('@mmt/legacy-assets/src/directlyCancIcon.webp');

const RefundCard = ({
  progressList,
  myPromiseList,
  refundModesMessageList,
  refundAmount,
  heading,
  headingv2,
  refundStatusText,
  whatNext,
  countView,
  specialClaimRefundReason,
  refreshTracker,
  refundModeBreakupList,
  bookingId,
  trackRefundEvent,
  refundText,
  noRefundText,
  onRefundModeWebViewBackPress,
  onRefundModeSubMessageLinkPress,
  isRoundedCornerAndPadding,
  currency,
  navigation,
  isLostBookingId,
  refundTrackerStatus,
  refundFeedbackCard,
  fonts,
  customStyle,
  showIconImg=true,
  allowViewMore=false
}) => {
  const staticData = getStaticData();
  const {  norefundOnCancellationText, ifWeDelayWePayText, trainOurPromiseText } = staticData;
  const getTitle = () => {
    const refundAmountValue = parseInt(refundAmount.split(' ')[1], 0);
    if (refundAmountValue <= 0) {
      return norefundOnCancellationText;
    }
    if (isNonEmpty(refundStatusText)) {
      return refundStatusText;
    }
    return `REFUND OF ${refundAmount} - PROCESSED`;
  };

  const currencyImageObject = useMemo(() => {
    let currencyImageObj = {};
    currencyImageObj.src = RUPEE_GREY_BG_ICON;
    currencyImageObj.style = styles.headingRupeeIconStyle;
    if (!currency || currency.toLowerCase() === 'inr' || currency === '₹') {
      return currencyImageObj;
    }
    currencyImageObj.src = CASH_IN_HAND_ICON;
    currencyImageObj.style = styles.ksaCurrencyImageStyle;
    return currencyImageObj;
  }, [currency]);

  const backPressHandler = () => {
    refreshTracker && refreshTracker();
  };

  const text = refundText || noRefundText;
  const actionHandler = async(action) => {
    trackRefundEvent && trackRefundEvent(action.type);
    if (action && action.url) {
      if (action.type === 'DESCRIPTION_CTA') {
        GenericModule.openDeepLink(action.url);
      } else {
        const { webViewHeader } = whatNext;
        const userInfo = await HotelBookingModule.getRequestHeader(
          bookingId,
          `REFUND_TRACKER_${webViewHeader}`,
        );
        Actions.openPsWebView({
          headerText: webViewHeader,
          headerIcon: back_white_icon,
          url: action.url,
          backPressCb: backPressHandler,
          injectedJavaScript: setWebViewAuth(userInfo.mmtAuth),
        });
      }
    }
  };

  const renderTrackerCards = () => {
    return (
      <>
        {myPromiseList &&
          myPromiseList.length > 0 &&
          myPromiseList.map((item) => (
            <React.Fragment>
              <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom15]}>
                {showIconImg && <Image style={styles.doneIconStyle} source={doneIcon} />}

                <Text style={[styles.useCaseTxt, fonts.regularFontFamily]}>
                  <Text style={[AtomicCss.defaultText, fonts.boldFontFamily]}>{trainOurPromiseText}</Text>
                  {item.promiseCommitmentText}
                </Text>
              </View>
              <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom10]}>
                {showIconImg && <Image style={styles.priceIconStyle} source={priceIcon} />}
                <Text style={styles.useCaseTxt}>
                  <Text style={[AtomicCss.defaultText, AtomicCss.boldFont]}>
                    {ifWeDelayWePayText}
                  </Text>
                  {item.promisePenaltyText}
                </Text>
              </View>
            </React.Fragment>
          ))}
        <Tracker
          borderTopActive={myPromiseList && myPromiseList.length > 0}
          progressList={progressList}
          refundModesMessageList={refundModesMessageList}
          onRefundModeSubMessageLinkPress={onRefundModeSubMessageLinkPress}
          onRefundModeWebViewBackPress={onRefundModeWebViewBackPress}
          bookingId={bookingId}
          trackRefundEvent={trackRefundEvent}
          navigation={navigation}
          isLostBookingId={isLostBookingId}
          fonts={fonts}
          allowViewMore={allowViewMore}
        />

        {!isLostBookingId && refundModeBreakupList && refundModeBreakupList.length > 0 && (
          <View
            style={{
              borderTopWidth: 1,
              borderTopColor: '#e7e7e7',
              paddingTop: 15,
              marginTop: 15,
            }}
          >
            {refundModeBreakupList.map((item) => (
              <View style={[AtomicCss.marginBottom10, AtomicCss.flexRow]}>
                <View style={[AtomicCss.flex1]}>
                  {isNonEmpty(item.header) && (
                    <Text
                      style={[
                        AtomicCss.defaultText,
                        fonts.boldFontFamily,
                        AtomicCss.font14,
                        AtomicCss.lineHeight20,
                        AtomicCss.marginBottom3,
                      ]}
                    >
                      {item.header}
                    </Text>
                  )}
                  {isNonEmpty(item.subHeader) && (
                    <Text
                      style={[
                        AtomicCss.greyText,
                        fonts.regularFontFamily,
                        AtomicCss.font14,
                        AtomicCss.lineHeight20,
                      ]}
                    >
                      {item.subHeader}
                    </Text>
                  )}
                </View>
                <Text style={[AtomicCss.defaultText, fonts.boldFontFamily]}>{`${item.value} `}</Text>
              </View>
            ))}
          </View>
        )}

        {whatNext && <WhatNextCard {...whatNext} actionHandler={actionHandler} />}
        {refundFeedbackCard}
      </>
    );
  };

  const getLostIdTracker = () => {
    return (
      <LostIdRefundCard
        trackerData={renderTrackerCards()}
        refundAmount={refundAmount}
        refundModeBreakupList={refundModeBreakupList}
        refundTrackerStatus={refundTrackerStatus}
        refundStatusText={refundStatusText}
        heading={heading}
        headingv2={headingv2}
        refundText={refundText}
      />
    );
  };
  const htmlStyleText = StyleSheet.create({
    span:{
      color:colors.defaultTextColor,
      fontFamily:fonts.black,
    },
    div:{
      color:colors.defaultTextColor,
      fontFamily:fonts.black,
    },
    p:{
      color:colors.defaultTextColor,
      fontFamily:fonts.black,
    },
    b:{
      color:colors.defaultTextColor,
      fontFamily:fonts.black,
    }
  });
  return (
    <View
      style={[
        isLostBookingId
          ? styles.infoCardWrapperLost
          : isRoundedCornerAndPadding
          ? styles.infoCardWrapperNew
          : styles.infoCardWrapper,
        customStyle ? customStyle : {}   
      ]}
    >
      {isLostBookingId ? (
        getLostIdTracker()
      ) : (
        <>
          {showIconImg && 
            <View style={styles.headingIconWrapper}>
              {countView ? (
                countView
              ) : (
                <Image style={currencyImageObject.style} source={currencyImageObject.src} />
              )}
            </View>
          }
          <View style={[styles.infoCardContent, customStyle ? AtomicCss.paddingRight8 : {}]}>
            {isNonEmpty(text) &&
            <HTMLView
                style={[AtomicCss.defaultText, fonts.blackFontFamily, AtomicCss.font14, AtomicCss.marginBottom20, AtomicCss.alignLeft]}
                value={text}
                stylesheet={htmlStyleText}
            />
            }
            {!isNonEmpty(text) &&
            <Text style={[AtomicCss.defaultText, fonts.blackFontFamily, AtomicCss.font14, AtomicCss.marginBottom20, AtomicCss.alignLeft]}>
              {getTitle()}
            </Text>
            }

            {!!specialClaimRefundReason && (
              <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, styles.cliamReasonWrapper]}>
                {showIconImg && <Image style={styles.userIconStyle} source={directlyCancIcon} />}
                <Text
                  style={[
                    AtomicCss.defaultText,
                    AtomicCss.font14,
                    fonts.boldFontFamily,
                    AtomicCss.paddingRight20,
                  ]}
                >
                  {specialClaimRefundReason}
                </Text>
              </View>
            )}

            {!!heading && (
              <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, styles.userCancelledWrapper]}>
                {showIconImg && <Image style={styles.userIconStyle} source={userIcon} />}
                <Text
                  style={[
                    AtomicCss.defaultText,
                    AtomicCss.font14,
                    fonts.boldFontFamily,
                    AtomicCss.paddingRight20,
                  ]}
                >
                  {heading}
                </Text>
              </View>
            )}

            {renderTrackerCards()}
          </View>
        </>
      )}
    </View>
  );
};

RefundCard.propTypes = {
  progressList: PropTypes.array.isRequired,
  // messageStripObj: PropTypes.string.isRequired,
  myPromiseList: PropTypes.array.isRequired,
  refundModesMessageList: PropTypes.array.isRequired,
  refundAmount: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  refundStatusText: PropTypes.string.isRequired,
};

export default RefundCard;
