/* For Mobile RIS pages context Ids */
export const MOBILE_RIS_PNR_LANDING_SNACKBAR = 'UkFJTCNQTlIgTEFORElORyNTTkFDS0JBUg==';
export const MOBILE_RIS_PNR_LANDING_CID = ['UkFJTCNQTlIgTEFORElORyNUT1AgRk9MRCBCQU5ORVIgMg==','UkFJTCNQTlIgTEFORElORyNUT1AgRk9MRCBCQU5ORVIgMw=='];
export const MOBILE_RIS_PNR_RESULT_CID = ['UkFJTCNQTlIgUkVTVUxUIFBBR0UjVE9QIEZPTEQ=','UkFJTCNQTlIgUkVTVUxUIFBBR0UjVE9QIEZPTEQgQkFOTkVSIDI=','UkFJTCNQTlIgUkVTVUxUIFBBR0UjVE9QIEZPTEQgQkFOTkVSIDM='];
export const MOBILE_RIS_LTS_LANDING_SNACKBAR = 'UkFJTCNMVFMgTEFORElORyNTTkFDS0JBUg==';
export const MOBILE_RIS_LTS_LANDING_CID = ['UkFJTCNMVFMgTEFORElORyNUT1AgRk9MRA==','UkFJTCNMVFMgTEFORElORyNUT1AgRk9MRCBCQU5ORVIgMg==','UkFJTCNMVFMgTEFORElORyNUT1AgRk9MRCBCQU5ORVIgMw=='];
export const MOBILE_RIS_COACH_POSITION_LANDING_CID = ['UkFJTCNDT0FDSCBQT1NJVElPTiBMQU5ESU5HI0JBTk5FUjE=','UkFJTCNDT0FDSCBQT1NJVElPTiBMQU5ESU5HI0JBTk5FUjI=','UkFJTCNDT0FDSCBQT1NJVElPTiBMQU5ESU5HI0JBTk5FUjM='];
export const MOBILE_RIS_COACH_POSITION_LANDING_SNACKBAR = 'UkFJTCNDT0FDSCBQT1NJVElPTiBMQU5ESU5HI1NOQUNLQkFS';
export const MOBILE_RIS_COACH_POSITION_RESULT_CID = ['UkFJTCNDT0FDSCBQT1NJVElPTiBMSVNUSU5HI1RPUCBGT0xE','UkFJTCNDT0FDSCBQT1NJVElPTiBMSVNUSU5HI1RPUCBGT0xEIEJBTk5FUiAy','UkFJTCNDT0FDSCBQT1NJVElPTiBMSVNUSU5HI1RPUCBGT0xEIEJBTk5FUiAy'];
export const MOBILE_RIS_TRAIN_SCHEDULE_LANDING_SNACKBAR = 'UkFJTCNUUkFJTiBTQ0hFRFVMRSBMQU5ESU5HI1NOQUNLQkFS';
export const MOBILE_RIS_TRAIN_SCHEDULE_LANDING_CID = ['UkFJTCNUUkFJTiBTQ0hFRFVMRSBMQU5ESU5HI1RPUCBGT0xE','UkFJTCNUUkFJTiBTQ0hFRFVMRSBMQU5ESU5HI1RPUCBGT0xEIEJBTk5FUiAy','UkFJTCNUUkFJTiBTQ0hFRFVMRSBMQU5ESU5HI1RPUCBGT0xEIEJBTk5FUiAz'];
export const MOBILE_RIS_TRAIN_SCHEDULE_RESULT_CID = 'UkFJTCNUUkFJTiBTQ0hFRFVMRSBMSVNUSU5HI1RPUCBGT0xE';
export const MOBILE_RIS_LIVE_STATION_LANDING_SNACKBAR = 'UkFJTCNMSVZFIFNUQVRJT04gTEFORElORyNTTkFDS0JBUg==';
export const MOBILE_RIS_LIVE_STATION_LANDING_CID = ['UkFJTCNMSVZFIFNUQVRJT04gTEFORElORyNUT1AgRk9MRA==','UkFJTCNMSVZFIFNUQVRJT04gTEFORElORyNUT1AgRk9MRCBCQU5ORVIgMg==','UkFJTCNMSVZFIFNUQVRJT04gTEFORElORyNUT1AgRk9MRCBCQU5ORVIgMw=='];
export const MOBILE_RIS_LIVE_STATION_RESULT_CID = 'UkFJTCNMSVZFIFNUQVRJT04gTElTVElORyNUT1AgRk9MRA==';
export const MOBILE_RIS_PNR_LANDING_ADFEED1 = 'UkFJTCNQTlIgTEFORElORyNBREZFRUQx';
export const MOBILE_RIS_PNR_LANDING_ADFEED2 = 'UkFJTCNQTlIgTEFORElORyNBREZFRUQy';
export const MOBILE_RIS_PNR_LANDING_ADFEED3 = ['UkFJTCNQTlIgTEFORElORyNBREZFRUQzX0JBTk5FUjE=','UkFJTCNQTlIgTEFORElORyNBREZFRUQzX0JBTk5FUjI=','UkFJTCNQTlIgTEFORElORyNBREZFRUQzX0JBTk5FUjM='];
export const MOBILE_RIS_LTS_LANDING_ADFEED1 = 'UkFJTCNMVFMgTEFORElORyNBREZFRUQx';
export const MOBILE_RIS_LTS_LANDING_ADFEED2 = 'UkFJTCNMVFMgTEFORElORyNBREZFRUQy';
export const MOBILE_RIS_LTS_LANDING_ADFEED3 = ['UkFJTCNMVFMgTEFORElORyNBREZFRUQzX0JBTk5FUjE=','UkFJTCNMVFMgTEFORElORyNBREZFRUQzX0JBTk5FUjI=','UkFJTCNMVFMgTEFORElORyNBREZFRUQzX0JBTk5FUjM='];
export const MOBILE_RIS_COACH_POSITION_LANDING_ADFEED1 = 'UkFJTCNDT0FDSCBQT1NJVElPTiBMQU5ESU5HI0FERkVFRDE=';
export const MOBILE_RIS_COACH_POSITION_LANDING_ADFEED2 = 'UkFJTCNDT0FDSCBQT1NJVElPTiBMQU5ESU5HI0FERkVFRDI=';
export const MOBILE_RIS_COACH_POSITION_LANDING_ADFEED3 = ['UkFJTCNDT0FDSCBQT1NJVElPTiBMQU5ESU5HI0FERkVFRDNfQkFOTkVSMQ==','UkFJTCNDT0FDSCBQT1NJVElPTiBMQU5ESU5HI0FERkVFRDNfQkFOTkVSMg==','UkFJTCNDT0FDSCBQT1NJVElPTiBMQU5ESU5HI0FERkVFRDNfQkFOTkVSMw=='];
export const MOBILE_RIS_TRAIN_SCHEDULE_LANDING_ADFEED1 = 'UkFJTCNUUkFJTiBTQ0hFRFVMRSBMQU5ESU5HI0FERkVFRDE=';
export const MOBILE_RIS_TRAIN_SCHEDULE_LANDING_ADFEED2 = 'UkFJTCNUUkFJTiBTQ0hFRFVMRSBMQU5ESU5HI0FERkVFRDI=';
export const MOBILE_RIS_TRAIN_SCHEDULE_LANDING_ADFEED3 = ['UkFJTCNUUkFJTiBTQ0hFRFVMRSBMQU5ESU5HI0FERkVFRDNfQkFOTkVSMQ==','UkFJTCNUUkFJTiBTQ0hFRFVMRSBMQU5ESU5HI0FERkVFRDNfQkFOTkVSMg==','UkFJTCNUUkFJTiBTQ0hFRFVMRSBMQU5ESU5HI0FERkVFRDNfQkFOTkVSMw=='];
export const MOBILE_RIS_LIVE_STATION_LANDING_ADFEED1 = 'UkFJTCNMSVZFIFNUQVRJT04gTEFORElORyNBREZFRUQx';
export const MOBILE_RIS_LIVE_STATION_LANDING_ADFEED2 = 'UkFJTCNMSVZFIFNUQVRJT04gTEFORElORyNBREZFRUQy';
export const MOBILE_RIS_LIVE_STATION_LANDING_ADFEED3 = ['UkFJTCNMSVZFIFNUQVRJT04gTEFORElORyNBREZFRUQzX0JBTk5FUjE=','UkFJTCNMSVZFIFNUQVRJT04gTEFORElORyNBREZFRUQzX0JBTk5FUjI=','UkFJTCNMSVZFIFNUQVRJT04gTEFORElORyNBREZFRUQzX0JBTk5FUjM='];

/* For Mobile Acme Landing Pages */
export const MOBILE_ACME_LANDING_CID = 'QUNNRSNMQU5ESU5HI01JRERMRQ==';

/* For Mobile Rails Landing Pages */
export const MOBILE_RAILS_LANDING_CID =['UkFJTCNMQU5ESU5HIFBBR0UjVE9QIEZPTEQ=','UkFJTCNMQU5ESU5HIFBBR0UjVE9QIEZPTEQgQkFOTkVSIDI=','UkFJTCNMQU5ESU5HIFBBR0UjVE9QIEZPTEQgQkFOTkVSIDM=']// 'UkFJTCNMQU5ESU5HIFBBR0UjVE9QIEZPTEQ=';
export const MOBILE_RAILS_LANDING_PAGE =['UkFJTCNMQU5ESU5HIFBBR0UjQkVMT1cgRk9MRA==','UkFJTCNMQU5ESU5HIFBBR0UjQkVMT1cgRk9MRCBCQU5ORVIgMg==','UkFJTCNMQU5ESU5HIFBBR0UjQkVMT1cgRk9MRCBCQU5ORVIgMw=='];
export const MOBILE_RAILS_LANDING_SNACKBAR = 'UkFJTCNMQU5ESU5HIFBBR0UjU05BQ0tCQVI=';
export const MOBILE_RAILS_NO_TRAINS_CID = ['UkFJTCNERUFEIEVORCBTUlAjVE9QIEZPTEQ=','UkFJTCNERUFEIEVORCBTUlAjVE9QIEZPTEQgQkFOTkVSIDI=','UkFJTCNERUFEIEVORCBTUlAjVE9QIEZPTEQgQkFOTkVSIDM='];
export const MOBILE_RAILS_THANK_YOU_ANDROID_CID = ['RkxJR0hUI0xBTkRJTkcjQ09TTU9TQ0FSRDE=','UkFJTCNUSEFOSyBZT1UgUEFHRSNUT1AgRk9MRCBCQU5ORVIgMg==','UkFJTCNUSEFOSyBZT1UgUEFHRSNUT1AgRk9MRCBCQU5ORVIgMw==']//'UkFJTCNUSEFOSyBZT1UgUEFHRSNUT1AgRk9MRA==';
export const MOBILE_RAILS_THANK_YOU_IOS_CID = ['RkxJR0hUI0xBTkRJTkcjQ09TTU9TQ0FSRDE=','UkFJTCNUSEFOSyBZT1UgUEFHRSNUT1AgRk9MRCBCQU5ORVIgMg==','UkFJTCNUSEFOSyBZT1UgUEFHRSNUT1AgRk9MRCBCQU5ORVIgMw==']//'UkFJTCNUSEFOSyBZT1UjVE9QIEZPTEQ=';
export const MOBILE_RAILS_BUS_COMMON_LANDING_CID = ['UkFJTCNSQUlMIEJVUyBDT01NT04gTEFORElORyNUT1AgRk9MRA==','UkFJTCNSQUlMIEJVUyBDT01NT04gTEFORElORyNUT1AgRk9MRCBCQU5ORVIgMg==','UkFJTCNSQUlMIEJVUyBDT01NT04gTEFORElORyNUT1AgRk9MRCBCQU5ORVIgMw==']// ['Q09NTU9OI0xVTU9TI0JBTk5FUiAx','Q09NTU9OI0xVTU9TI0JBTk5FUiAy','Q09NTU9OI0xVTU9TI0JBTk5FUiAz'];
export const MOBILE_RAILS_BUS_COMMON_LANDING_SNACKBAR = 'UkFJTCNSQUlMIEJVUyBDT01NT04gTEFORElORyNTTkFDS0JBUg=='
export const MOBILE_RAILS_BUS_COMMON_LANDING_ADFEED1 = [
    'UkFJTCNSQUlMIEJVUyBDT01NT04gTEFORElORyNBREZFRUQx',
    'UkFJTCNSQUlMIEJVUyBDT01NT04gTEFORElORyNBREZFRUQxIEJBTk5FUjI=',
    'UkFJTCNSQUlMIEJVUyBDT01NT04gTEFORElORyNBREZFRUQxIEJBTk5FUjM=',
];
export const MOBILE_RAILS_BUS_COMMON_LANDING_ADFEED2 = 'UkFJTCNSQUlMIEJVUyBDT01NT04gTEFORElORyNBREZFRUQy';
export const MOBILE_RAILS_BUS_COMMON_LANDING_ADFEED3 = ['UkFJTCNSQUlMIEJVUyBDT01NT04gTEFORElORyNBREZFRUQzX0JBTk5FUjE=','UkFJTCNSQUlMIEJVUyBDT01NT04gTEFORElORyNBREZFRUQzX0JBTk5FUjI=','UkFJTCNSQUlMIEJVUyBDT01NT04gTEFORElORyNBREZFRUQzX0JBTk5FUjM='];
export const MOBILE_RAILS_LISTING_ADFEED1 = 'UkFJTCNMSVNUSU5HI0FERkVFRF8x';
export const MOBILE_RAILS_LISTING_ADFEED2 = ['UkFJTCNMSVNUSU5HI0FERkVFRF8x','UkFJTCNMSVNUSU5HI0FERkVFRF8y'];
export const MOBILE_RAILS_LISTING_ADFEED3 = ['UkFJTCNMSVNUSU5HI0FERkVFRF8x','UkFJTCNMSVNUSU5HI0FERkVFRF8y','UkFJTCNMSVNUSU5HI0FERkVFRF8z'];
export const RAIL_THANK_YOU_PAGE_APP_CID = 'UkFJTCNUSEFOSyBZT1UgUEFHRSNCSUcgQUQ=';

/* For mWeb Rails Landing Pages */
export const MWEB_RAILS_LANDING_CID = 'UkFJTCNUUkFJTlMgRlVOTkVMIExBTkRJTkcjVE9QIEZPTEQ=';
export const MWEB_RAILS_NO_TRAINS_CID = 'UkFJTCNUUkFJTlMgREVBREVORCBTUlAjVE9QIEZPTEQ=';
export const MWEB_RAILS_THANK_YOU_CID = 'UkFJTCNUUkFJTlMgVEhBTksgWU9VI1RPUCBGT0xE';

/* For Wallet LandingPage */
export const MOBILE_WALLET_LANDING_CID = 'Q09NTU9OI1dBTExFVCNCRUxPVyBUUkFOU0FDVElPTlM=';
export const MOBILE_WALLET_ALL_TRANSACTION_CID = 'Q09NTU9OI1dBTExFVCBWSUVXIEFMTCBUUkFOU0FDVElPTlMjQk9UVE9N';

/* For PostSales Detail Pages */
export const POST_SALES_FLIGHT_DETAIL_CID = 'RkxJR0hUI0ZMSUdIVERFVEFJTFMjTUlERExF';
export const POST_SALES_HOTEL_DETAIL_CID = 'SE9URUwjSE9URUxERVRBSUxTI01JRERMRQ==';
export const POST_SALES_BUS_DETAIL_CID = 'QlVTI0JVU0JPT0tJTkdWT1VDSEVSI01JRERMRQ==';
export const POST_SALES_CAB_DETAIL_CID = 'QlVTI0JVU0JPT0tJTkdWT1VDSEVSI01JRERMRQ==';

export const POST_SALES_RAIL_DETAIL_CID = 'UkFJTCNSQUlMU0JPT0tJTkdWT1VDSEVSI01JRERMRQ==';
export const POST_SALES_WEB_FLIGHT_DETAIL_CID = 'RkxJR0hUI0JPT0tJTkcgREVUQUlMUyNNSURETEU=';
export const POST_SALES_WEB_HOTEL_DETAIL_CID = 'SE9URUwjQk9PS0lORyBERVRBSUxTI01JRERMRQ==';
export const POST_SALES_BOOKING_SUMMARY_CID = 'UE9TVF9TQUxFUyNNWVRSSVBTI01JRERMRQ==';
export const POST_SALES_WEB_BOOKING_SUMMARY_CID = 'UE9TVF9TQUxFUyNNWVRSSVBTI01JRERMRQ==';
export const POST_SALES_BOOKING_SUMMARY_CID_GCC = 'UE9TVF9TQUxFUyNHQ0MgTVlUUklQUyNCQU5ORVIgMQ==';
export const POST_SALES_WEB_BOOKING_SUMMARY_CID_GCC = 'UE9TVF9TQUxFUyNHQ0MgTVlUUklQUyNCQU5ORVIgMQ==';

export const POST_SALES_BUS_CANCELLATION_SUCCESS_CID = 'QlVTI0JVU0JPT0tJTkdWT1VDSEVSI01JRERMRQ==';
export const POST_SALES_CAB_CANCELLATION_SUCCESS_CID = 'QlVTI0JVU0JPT0tJTkdWT1VDSEVSI01JRERMRQ==';
export const POST_SALES_TRAIN_CANCELLATION_SUCCESS_CID = 'QlVTI0JVU0JPT0tJTkdWT1VDSEVSI01JRERMRQ==';
export const POST_SALES_TRAIN_TDR_SUCCESS_CID = 'QlVTI0JVU0JPT0tJTkdWT1VDSEVSI01JRERMRQ==';
/* For Holidays Landing Page */
export const HOLIDAYS_LANDING_CID_1 = 'SE9MSURBWSNMQU5ESU5HIFBBR0UjQkFOTkVSIDE=';
export const HOLIDAYS_LANDING_CID_2 = 'SE9MSURBWSNMQU5ESU5HIFBBR0UjQkFOTkVSIDI=';
export const HOLIDAYS_LANDING_CID_3 = 'SE9MSURBWSNMQU5ESU5HIFBBR0UjQkFOTkVSIDM=';

// Cabs
export const CABS_FUNNEL_LANDING_CID_IOS = 'Q0FCI0NBQlMgRlVOTkVMIExBTkRJTkcjQk9UVE9N';
export const CABS_FUNNEL_LANDING_CID_ANDROID = 'Q0FCI0NBQlMgRlVOTkVMIExBTkRJTkcgI0JPVFRPTQ==';

// Bus
export const BUS_FUNNEL_LANDING_MULTIADFEED_CID = [
  'QlVTI0xBTkRJTkcjQURGRUVEIDIgQkFOTkVSIDE=',
  'QlVTI0xBTkRJTkcjQURGRUVEIDIgQkFOTkVSIDI=',
  'QlVTI0xBTkRJTkcjQURGRUVEIDIgQkFOTkVSIDM=',
];
export const BUS_FUNNEL_LANDING_BANNER_CID = 'QlVTI0xBTkRJTkcjTUlERExFIEJBTk5FUg==';
export const BUS_FUNNEL_LANDING_SNACKBAR_CID = 'QlVTI0xBTkRJTkcjU05BQ0tCQVI=';


/* Page name constant */
export const RIS_PNR_LANDING = 'PNR_LANDING';
export const RIS_PNR_LANDING_ADFEED1 = 'PNR_LANDING_ADFEED1';
export const RIS_PNR_LANDING_ADFEED2 = 'PNR_LANDING_ADFEED2';
export const RIS_PNR_LANDING_ADFEED3 = 'PNR_LANDING_ADFEED3';
export const RIS_PNR_LANDING_SNACKBAR = 'PNR_LANDING_SNACKBAR';
export const RIS_PNR_RESULT = 'RIS_PNR_RESULT';
export const RIS_TLS_LANDING = 'TLS_LANDING';
export const RIS_TLS_LANDING_ADFEED1 = 'TLS_LANDING_ADFEED1';
export const RIS_TLS_LANDING_ADFEED2 = 'TLS_LANDING_ADFEED2';
export const RIS_TLS_LANDING_ADFEED3 = 'TLS_LANDING_ADFEED3';
export const RIS_TLS_LANDING_SNACKBAR = 'TLS_LANDING_SNACKBAR';
export const RIS_SCHEDULE_LANDING_SNACKBAR = 'RIS_SCHEDULE_LANDING_SNACKBAR';
export const RIS_SCHEDULE_LANDING = 'RIS_SCHEDULE_LANDING';
export const RIS_SCHEDULE_LANDING_ADFEED1 = 'RIS_SCHEDULE_LANDING_ADFEED1';
export const RIS_SCHEDULE_LANDING_ADFEED2 = 'RIS_SCHEDULE_LANDING_ADFEED2';
export const RIS_SCHEDULE_LANDING_ADFEED3 = 'RIS_SCHEDULE_LANDING_ADFEED3';
export const RIS_SCHEDULE_RESULT = 'RIS_SCHEDULE_RESULT';
export const RIS_STATION_LANDING_SNACKBAR = 'RIS_STATION_LANDING_SNACKBAR';
export const RIS_STATION_LANDING = 'RIS_STATION_LANDING';
export const RIS_STATION_LANDING_ADFEED1 = 'RIS_STATION_LANDING_ADFEED1';
export const RIS_STATION_LANDING_ADFEED2 = 'RIS_STATION_LANDING_ADFEED2';
export const RIS_STATION_LANDING_ADFEED3 = 'RIS_STATION_LANDING_ADFEED3';
export const RIS_STATION_RESULT = 'RIS_STATION_RESULT';
export const RIS_COACH_LANDING = 'RIS_COACH_LANDING';
export const RIS_COACH_LANDING_ADFEED1 = 'RIS_COACH_LANDING_ADFEED1';
export const RIS_COACH_LANDING_ADFEED2 = 'RIS_COACH_LANDING_ADFEED2';
export const RIS_COACH_LANDING_ADFEED3 = 'RIS_COACH_LANDING_ADFEED3';
export const RIS_COACH_LANDING_SNACKBAR = 'RIS_COACH_LANDING_SNACKBAR';
export const RIS_COACH_RESULT = 'RIS_COACH_RESULT';
export const RAIL_FUNNEL_LANDING_SNACKBAR = 'RAIL_FUNNEL_LANDING_SNACKBAR';
export const RAIL_FUNNEL_LANDING = 'RAIL_FUNNEL_LANDING';
export const RAIL_LANDING_PAGE = 'LANDING_PAGE';
export const TRAIN_BUS_COMMON_LANDING_SNACKBAR = 'TRAIN_BUS_COMMON_LANDING_SNACKBAR';
export const TRAIN_BUS_COMMON_LANDING = 'TRAIN_BUS_COMMON_LANDING';
export const TRAIN_BUS_COMMON_LANDING_ADFEED1  = 'TRAIN_BUS_COMMON_LANDING_ADFEED1 ';
export const TRAIN_BUS_COMMON_LANDING_ADFEED2  = 'TRAIN_BUS_COMMON_LANDING_ADFEED2 ';
export const TRAIN_BUS_COMMON_LANDING_ADFEED3  = 'TRAIN_BUS_COMMON_LANDING_ADFEED3 ';
export const TRAIN_LISTING_ADFEED1  = 'TRAIN_LISTING_ADFEED1';
export const TRAIN_LISTING_ADFEED2  = 'TRAIN_LISTING_ADFEED2';
export const TRAIN_LISTING_ADFEED3  = 'TRAIN_LISTING_ADFEED3';
export const RAIL_NO_TRAINS = 'RAIL_NO_TRAINS';
export const RAILS_THANK_YOU_ANDROID = 'RAILS_THANK_YOU_ANDROID';
export const RAIL_THANK_YOU_PAGE_APP = 'RAIL_THANK_YOU_PAGE_APP';
export const RAILS_THANK_YOU_IOS = 'RAILS_THANK_YOU_IOS';
export const RAILS_THANK_YOU = 'RAILS_THANK_YOU';
export const CABS_LANDING_ANDROID = 'CABS_LANDING_ANDROID';
export const CABS_LANDING_IOS = 'CABS_LANDING_IOS';
export const WALLET_LANDING = 'WALLET_LANDING';
export const WALLET_ALL_TRANSACTION = 'WALLET_ALL_TRANSACTION';
export const POST_SALES_FLIGHT_DETAILS_PAGE = 'POST_SALES_FLIGHT_DETAILS_PAGE';
export const POST_SALES_HOTEL_DETAILS_PAGE = 'POST_SALES_HOTEL_DETAILS_PAGE';
export const POST_SALES_BUS_DETAILS_PAGE = 'POST_SALES_BUS_DETAILS_PAGE';
export const POST_SALES_CAB_DETAILS_PAGE = 'POST_SALES_CAB_DETAILS_PAGE';
export const POST_SALES_RAIL_DETAILS_PAGE = 'POST_SALES_RAIL_DETAILS_PAGE';
export const POST_SALES_WEB_FLIGHT_DETAILS_PAGE = 'POST_SALES_WEB_FLIGHT_DETAILS_PAGE';
export const POST_SALES_WEB_HOTEL_DETAILS_PAGE = 'POST_SALES_WEB_HOTEL_DETAILS_PAGE';
export const POST_SALES_BOOKING_SUMMARY_PAGE = 'POST_SALES_BOOKING_SUMMARY_PAGE';
export const POST_SALES_BOOKING_SUMMARY_PAGE_GCC = 'POST_SALES_BOOKING_SUMMARY_PAGE_GCC';
export const POST_SALES_WEB_BOOKING_SUMMARY_PAGE_GCC = 'POST_SALES_WEB_BOOKING_SUMMARY_PAGE';
export const POST_SALES_WEB_BOOKING_SUMMARY_PAGE = 'POST_SALES_WEB_BOOKING_SUMMARY_PAGE';
export const HOLIDAYS_LANDING_BANNER_1 = 'HOLIDAYS_LANDING_BANNER_1'
export const HOLIDAYS_LANDING_BANNER_2 = 'HOLIDAYS_LANDING_BANNER_2'
export const HOLIDAYS_LANDING_BANNER_3 = 'HOLIDAYS_LANDING_BANNER_3';
export const ACME_LANDING = 'ACME_LANDING'

export const POST_SALES_BUS_CANCELLATION_SUCCESS_PAGE = 'POST_SALES_BUS_CANCELLATION_SUCCESS_PAGE';
export const POST_SALES_CAB_CANCELLATION_SUCCESS_PAGE = 'POST_SALES_CAB_CANCELLATION_SUCCESS_PAGE';
export const POST_SALES_TRAIN_CANCELLATION_SUCCESS_PAGE = 'POST_SALES_TRAIN_CANCELLATION_SUCCESS_PAGE';
export const POST_SALES_TRAIN_TDR_SUCCESS_PAGE = 'POST_SALES_TRAIN_TDR_SUCCESS_PAGE';

export const BUS_FUNNEL_LANDING_MULTIADFEED = 'BUS_FUNNEL_LANDING_MULTIADFEED';
export const BUS_FUNNEL_LANDING_BANNER = 'BUS_FUNNEL_LANDING_BANNER';
export const BUS_FUNNEL_LANDING_SNACKBAR = 'BUS_FUNNEL_LANDING_SNACKBAR';
