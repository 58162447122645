import {StyleSheet} from 'react-native';
export default (theme, fonts) =>
StyleSheet.create({ 
  pageWrapper: {
    flex: 1,
  },
  pageScroll: {
    flex: 1
  },
  paxCancelledTopDtls: {
    backgroundColor: theme.color.smokeWhite,
    elevation: 2,
    marginBottom: 1,
    marginTop: 0,
    shadowColor: theme.color.black,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 2,
  },
  refundContainer: {
    marginVertical: 8,
  },
  autoRefundBreakupMain: {
    borderTopColor: theme.color.lightGrey,
    borderTopWidth: 1,
    borderBottomColor: theme.color.lightGrey,
    borderBottomWidth: 1,
  },
  footerBtnWrapper: {
    padding: 12,
  },
  headerTitle: {
    color: theme.color.black
  },
});
