import React from 'react';
import {View} from 'react-native';
import PropTypes from 'prop-types';
import styles from './DynamicFormCss';
import InputFieldMaster from './InputFieldMaster';
import {getHypotheticalParentNode} from './DynamicFormUtils';

class FormContainer extends React.Component {
    static navigationOptions = {
      header: null
    };

    constructor(props) {
      super(props);
      this.masterFields = [];
      this.fieldValuesKey = 'visaForm.fieldValues';
      this.state = {
        visaForm: this.props.visaForm
      };
    }

    componentWillReceiveProps(newProps) {
      if (this.props.visaForm !== newProps.visaForm) {
        this.setState({
          visaForm: newProps.visaForm
        });
      }
    }

  renderFormSection = () => {
    this.masterFields = [];
    this.state.visaForm.fieldsOrder.forEach((element) => {
      this.masterFields.push(<InputFieldMaster
        focusOnNextField={this.props.focusOnNextField}
        textInputRefs={this.props.textInputRefs}
        labelIndex={`${this.masterFields.length + 1}`}
        key={element}
        parent={getHypotheticalParentNode('', this.props.visaForm.fields[element])}
        jsonKey={this.fieldValuesKey}
        fieldName={element}
        visaForm={this.state.visaForm}
        updateChildValues={this.props.updateChildValues}
        validate={this.props.validateForm}
        setField={this.props.setField}
        showDropDown={this.props.showDropDown}
        formIndex={this.props.formIndex}
      />);
    });
    return this.masterFields;
  }

  render() {
    return (
      <View style={[styles.whitebg]}>
        <View style={styles.FormSection} >
          {this.renderFormSection()}
        </View>
      </View>
    );
  }
}

FormContainer.propTypes = {
  visaForm: PropTypes.object.isRequired,
  validateForm: PropTypes.bool.isRequired,
  updateChildValues: PropTypes.func.isRequired,
  setField: PropTypes.func.isRequired,
  showDropDown: PropTypes.func.isRequired,
  textInputRefs: PropTypes.object.isRequired,
  focusOnNextField: PropTypes.func.isRequired
};

export default FormContainer;
