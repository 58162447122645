import React from 'react';
import { View, Text, TouchableOpacity, Image, Linking, Platform, DeviceEventEmitter } from 'react-native';
import isEmpty from 'lodash/isEmpty';
import styles, { getHtmlViewStyle } from '../FlightDtlsCss';
import HTMLView from 'react-native-htmlview';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getStaticData } from '../../../../../../staticData/staticData';
import WebCheckInStatus from '../../WebCheckIn/WebCheckInStatus';
import SrcDestDetail from '../SrcDestDetail';
import FareServices from '../FareServices';
import TravellerInfoCard from '../TravellerInfoCard';
import WhatNextCard from '../../WhatNextCard';
import RefundRequestInProcess from '../../RefundRequestInProcess';
import LastMinuteCancellation from '../LastMinuteCancellation';
import AirlineDetail from '../AirlineDetail';
import { getFont } from '../../../../../../PostSalesStyles';
import { getSegmentStatusForCard, getFISIcon } from '../../../utils/helper';
import {
  getContactEmaiPhone,
  getSegmentDetails,
} from '../../../utils/flightItinaryUtil';
import { externalLinkHandler } from '../../../../../FlightBookingUtil';
import FlightConst, { EventEmitterConst } from '../../../FlightBookingDetailsConstant';
import {
  CHECKIN_BAG,
  GREY_INFO_ICON,
  PRIORITY_CHECKIN_ICON,
  WIFI_ICON,
  LOUNGE_ICON,
  ADD_BAG,
  FARE_ICON,
  FLEX_ICON,
} from '../../../../../FlightImageConstants';
import { useTheme } from '../../../../../../theme/theme.context';
import VirtualInterlining from '../../VirtualInterlining';
import LayOverConnector from '../../LayOverConnector';
import Actions from '../../../../../../navigation/postSalesNavigation';
import { getRootTag } from '@mmt/legacy-commons/AppState/RootTagHolder';
import FlightBookingTrackingHelper from '../../../helper/FlightBookingTrackingHelper';
import HolidayBookingTrackingHelper from '../../../../../../Holiday/HolidayBookingTrackingHelper';
import FlightBookingModule from '@mmt/legacy-commons/Native/FlightBookingModule';

const FlightItinaryDetail = ({
  itinaryDetails,
  segmentDetail,
  index: segIndex,
  flightDetailResponse,
  segmentAncillaryDetails,
  holidayBooking,
  holidayPageName,
  isCorporateBooking,
  holidayData,
  layoutKey,
  updateFlightLayout,
  showExtraInfo,
  setFareServiceOverlayData,
  openDeepLink,
  errorPageData,
  tentativeWindowBookingStatus,
  segmentAncillaryGenericMessage,
}) => {
  const { stopOvers } = itinaryDetails;
  const staticData = getStaticData();
  const {psTheme} = useTheme();
  const { webCheckinInfo, segmentInformation } =
    getSegmentDetails(
      segmentDetail,
      flightDetailResponse,
      segmentAncillaryDetails,
      stopOvers,
      segIndex,
      () => {},
      staticData
    );
  const {
    segmentStatusDesc,
    cardTheme: styleData,
    airlineLink,
    title,
    bookingSegmentAction,
  } = getSegmentStatusForCard(segmentDetail, styles);
  const {
    flightAdditionalInfo,
    flightInfo,
    segmentNotification,
    flightDesignator,
    baggageDetail,
    purchasedBaggageInfo,
    isSegmentFailed,
    fastForwardNotificationInfo,
    fastForwardNotificationHeader,
    wiFiNotificationHeader,
    fastForwardNotificationSubHeader,
    loungeNotificationHeader,
    scActionList,
    travellerInfo: travellerDetail,
    cancelledByAirline,
    segmentRefundDetail,
    layoverDetail,
    scheduleChangeInfo,
    baggageExternalUrl,
    seatMessage,
  } = segmentInformation || {};
  const {
    handBaggageInfo: handBaggage,
    luggageInfo: luggage,
    doubleSeatPolicyList,
  } = baggageDetail || {};
  const { travellerInfo } = travellerDetail;
  const {
    flightBaggageDetailsCardText: {
      items: { buyBagInfo, luggageInfo, handBagInfo },
      additionalText: {
        webCheckinText,
        addSeatText,
        addMealText,
        addBaggageText,
        whatYouCanDoNextText,
      },
    },
    detailsNotAvailableText,
    handBagOnlyFareText,
    tAndCText,
  } = getStaticData();

  const fonts = getFont(true);

  const htmlViewStyle = getHtmlViewStyle(fonts);
  const { mmtConnect } = flightDetailResponse || {};
  const wcStatusCardActionHandler = (action, segIndex) => {
    const cardIndex = segIndex > 1 ? segIndex : '';
    switch (action.type) {
      case 'VISIT_AIRLINE':
        if (action.openInWebView) {
          const { flightWebCheckInText } = getStaticData();
          Actions.openPsWebView({
            headerText: flightWebCheckInText,
            headerIcon: require('@mmt/legacy-assets/src/back-white.webp'),
            url: action.url,
          });
        } else {
          Linking.openURL(action.url);
        }
        holidayBooking
          ? HolidayBookingTrackingHelper.trackClickEvent(
          holidayPageName,
          'Flights_Web_Checkin',
          )
          : FlightBookingTrackingHelper.trackClickEvent(
          'Details',
          `Flight_Card${cardIndex}_Webcheckin_Outside`,
          flightDetailResponse,
          );
        break;
      case 'BOARDING_PASS':
        const bookingId = flightDetailResponse.bookingID;
        if (Platform.OS === 'ios') {
          const rootTag = getRootTag();
          FlightBookingModule.downloadInsurancePolicy(bookingId, action.url, rootTag);
        } else if (Platform.OS === 'web') {
          FlightBookingModule.downloadInsurancePolicy(action.url, bookingId, 'BOARDING_PASS');
        } else {
          FlightBookingModule.downloadInsurancePolicy(action.url);
        }
        holidayBooking
          ? HolidayBookingTrackingHelper.trackClickEvent(
          holidayPageName,
          'Boarding Pass',
          )
          : FlightBookingTrackingHelper.trackClickEvent(
          'Details',
          `Flight_Card${cardIndex}_WC_Download`,
          flightDetailResponse,
          );
        break;
      case 'WEBCHECKIN':
        Actions.openAncillary({
          pageKey: 'webcheckin',
          bookingId: flightDetailResponse.bookingID,
          flightDetailResponse: flightDetailResponse,
          holidayBooking: holidayBooking,
          holidayData: holidayData,
          isCorporateBooking: isCorporateBooking,
        });
        holidayBooking
          ? HolidayBookingTrackingHelper.trackClickEvent(
          holidayPageName,
          'Flights_Web_Checkin',
          )
          : FlightBookingTrackingHelper.trackClickEvent(
          'Details',
          `Flight_Card${cardIndex}_Webcheckin_Inside_1`,
          flightDetailResponse,
          );
        DeviceEventEmitter.emit(EventEmitterConst.CTA_CLICKED_EVENT);
        break;
      default:
        break;
    }
  };
  return (
    <React.Fragment>
      {!isEmpty(webCheckinInfo) && (
        <WebCheckInStatus
          {...webCheckinInfo}
          segIndex={segIndex + 1}
          holidayBooking={holidayBooking}
          holidayPageName={holidayPageName}
          isCorporateBooking={isCorporateBooking}
          holidayData={holidayData}
          flightDetailResponse={flightDetailResponse}
          wcStatusCardActionHandler={wcStatusCardActionHandler}
        />
      )}
      {!isEmpty(segmentStatusDesc) && Object.keys(styleData).length > 0 && (
        <View style={[styles.useCaseWrapper, styleData.backGround]}>
          <Image
            style={
              styleData.iconStyle ? styleData.iconStyle : styles.beltIconStyle
            }
            source={styleData.imageSource}
          />
          <Text style={styleData.textStyle}>
            {segmentStatusDesc}
            {airlineLink && (
              <Text
                onPress={() => externalLinkHandler(bookingSegmentAction)}
                style={AtomicCss.azure}
              >
                {` ${title}`}
              </Text>
            )}
          </Text>
        </View>
      )}

      {!isEmpty(flightAdditionalInfo) &&
        flightAdditionalInfo.map((item) => {
          return <View
            style={[
              styles.useCaseWrapper,
              item.state === FlightConst.INITIAL_STATE
                ? styles.datechangeUseCase
                : styles.bgOrange,
            ]}
          >
            <Image
              style={
                item.type === FlightConst.TYPE_BAGGGEBELT
                  ? styles.beltIconStyle
                  : styles.gateIconStyle
              }
              source={getFISIcon(item.type, item.state)}
            />
            <Text
              style={
                item.state === FlightConst.INITIAL_STATE
                  ? [styles.dateChangeUseCaseTxt, fonts.boldFontFamily]
                  : [styles.bgOrangeTxt, fonts.boldFontFamily]
              }
            >
              {item.text}
            </Text>
          </View>;
        })}

      <View
        style={[styles.fltInnerDtlsWrappers]}
        onLayout={({ nativeEvent }) => {
          updateFlightLayout(
            nativeEvent.layout.y,
            FlightConst.FLIGHT_SEGMENT_CHILD_HEIGHT,
            layoutKey
          );
        }}
      >
        <AirlineDetail segmentInformation={segmentInformation} transferMode={segmentDetail?.transferMode}/>
        <LastMinuteCancellation
          tentativeWindowBookingStatus={tentativeWindowBookingStatus}
          errorPageData={errorPageData}
          segmentDetail={segmentDetail}
          scheduleChangeInfo={scheduleChangeInfo}
          isCorporateBooking={isCorporateBooking}
          bookingId={flightDetailResponse.bookingID}
          segmentInformation={segmentInformation}
        />
        <SrcDestDetail segmentInformation={segmentInformation} flightDetailResponse={flightDetailResponse}/>
        <View style={styles.cabinBagWrapper}>
          {!isEmpty(fastForwardNotificationHeader) &&
            !isEmpty(fastForwardNotificationInfo) && (
              <View
                style={[
                  AtomicCss.flexRow,
                  AtomicCss.alignCenter,
                  AtomicCss.marginBottom5,
                ]}
              >
                <Image
                  style={[styles.priotyChkInIconStyle, AtomicCss.alignSelfStart]}
                  source={PRIORITY_CHECKIN_ICON}
                />
                <View style={[AtomicCss.flex1, AtomicCss.flexRow]}>
                  <Text
                    style={[
                      fonts.regularFontFamily,
                      AtomicCss.greyText,
                      AtomicCss.font12,
                      styles.priortiyCheckinTxt
                    ]}
                  >
                    {fastForwardNotificationHeader}
                  </Text>
                  <Text style={[fonts.regularFontFamily, AtomicCss.greyText, AtomicCss.font12]}>{' - '} </Text>
                  <Text style={[fonts.boldFontFamily, AtomicCss.flex1, AtomicCss.greyText, AtomicCss.font12]}>
                  {fastForwardNotificationSubHeader}
                  </Text>
                </View>
              </View>
            )}
          {!isEmpty(wiFiNotificationHeader) && (
            <View
              style={[
                AtomicCss.flexRow,
                AtomicCss.alignCenter,
                AtomicCss.marginBottom5,
              ]}
            >
              <Image style={styles.priotyChkInIconStyle} source={WIFI_ICON} />
              <View style={[AtomicCss.flex1, AtomicCss.flexRow]}>
                <Text
                  style={[
                    fonts.regularFontFamily,
                    AtomicCss.greyText,
                    AtomicCss.font12,
                  ]}
                >
                  {wiFiNotificationHeader}
                </Text>
              </View>
            </View>
          )}
          {!isEmpty(loungeNotificationHeader) && (
            <View
              style={[
                AtomicCss.flexRow,
                AtomicCss.alignCenter,
                AtomicCss.marginBottom5,
              ]}
            >
              <Image style={styles.priotyChkInIconStyle} source={LOUNGE_ICON} />
              <View style={[AtomicCss.flex1, AtomicCss.flexRow]}>
                <Text
                  style={[
                    fonts.regularFontFamily,
                    AtomicCss.greyText,
                    AtomicCss.font12,
                  ]}
                >
                  {loungeNotificationHeader}
                </Text>
              </View>
            </View>
          )}
          {!isEmpty(segmentNotification) && (
            <View
              style={[
                AtomicCss.flexRow,
                AtomicCss.alignCenter,
                AtomicCss.marginBottom5,
              ]}
            >
              <Image style={styles.infoIconStyle} source={GREY_INFO_ICON} />
              <View style={[AtomicCss.flex1, AtomicCss.flexRow]}>
                <Text
                  style={[
                    fonts.regularFontFamily,
                    AtomicCss.greyText,
                    AtomicCss.font12,
                  ]}
                >
                  {segmentNotification}
                </Text>
              </View>
            </View>
          )}

          {showExtraInfo && (
            <React.Fragment>
              <View
                style={[
                  AtomicCss.flexRow,
                  AtomicCss.alignCenter,
                  AtomicCss.marginBottom5,
                ]}
              >
                <Image style={[AtomicCss.alignSelfStart, styles.chkIconStyle2]} source={CHECKIN_BAG} />
                <View
                  style={[
                    AtomicCss.flex1,
                    AtomicCss.flexRow,
                    AtomicCss.flexWrap,
                  ]}
                >
                  <View style={AtomicCss.flexRow}>
                    <Text
                      style={[
                        fonts.regularFontFamily,
                        AtomicCss.greyText,
                        AtomicCss.font12,
                      ]}
                    >
                      {handBagInfo.labelText}
                    </Text>
                    <Text
                      style={[
                        fonts.regularFontFamily,
                        AtomicCss.greyText,
                        AtomicCss.font12,
                      ]}
                    >
                      {' '}
                      -{' '}
                    </Text>
                    <View style={[AtomicCss.flexColumn, AtomicCss.alignItemsStart]}>
                      <Text
                        style={
                          !isEmpty(handBaggage) && !isEmpty(handBaggage.text)
                            ? [
                              fonts.boldFontFamily,
                              AtomicCss.greyText,
                              AtomicCss.font12,
                              AtomicCss.flex1,
                              AtomicCss.flexWrap,
                              {maxWidth: 220}
                            ]
                            : [
                              fonts.boldFontFamily,
                              AtomicCss.redText,
                              AtomicCss.font12,
                              AtomicCss.flex1,
                              AtomicCss.flexWrap,
                              {maxWidth: 220}
                            ]
                        }
                      >
                        {!isEmpty(handBaggage) && !isEmpty(handBaggage.text)
                          ? handBaggage.text
                          : detailsNotAvailableText}
                      </Text>
                      {!isEmpty(baggageExternalUrl) && (
                        <TouchableOpacity
                          onPress={() => Linking.openURL(baggageExternalUrl)}
                        >
                          <Text
                            style={[
                              { color: psTheme.color.actionTitleColor },
                              AtomicCss.font10,
                              fonts.boldFontFamily,
                            ]}
                          >
                            {' '}
                            {tAndCText}
                          </Text>
                        </TouchableOpacity>
                      )}
                    </View>
                  </View>
                </View>
              </View>

              <View>
              {!isEmpty(luggage) && (
                <View
                  style={[
                    AtomicCss.flexRow,
                    AtomicCss.alignCenter,
                    AtomicCss.marginBottom5,
                  ]}
                >
                  <Image style={styles.chkIconStyle1} source={CHECKIN_BAG} />
                  <View style={[AtomicCss.flex1, AtomicCss.flexRow]}>
                    <Text
                      style={[
                        fonts.regularFontFamily,
                        AtomicCss.greyText,
                        AtomicCss.font12,
                      ]}
                    >
                      {luggageInfo.labelText}
                    </Text>
                    <Text
                      style={[
                        fonts.regularFontFamily,
                        AtomicCss.greyText,
                        AtomicCss.font12,
                      ]}
                    >
                      {' '}
                      -{' '}
                    </Text>
                    {!isEmpty(luggage) && (
                      <Text
                        style={
                          luggage.color === 'grey'
                            ? [
                                fonts.boldFontFamily,
                                AtomicCss.greyText,
                                AtomicCss.font12,
                              ]
                            : [
                                fonts.boldFontFamily,
                                AtomicCss.redText,
                                AtomicCss.font12,
                              ]
                        }
                      >
                        {luggage.detailsNotAvailable ? detailsNotAvailableText : luggage.text}
                    </Text>
                    )}
                  </View>
                </View>
              )}
                {!isEmpty(doubleSeatPolicyList) &&
                  doubleSeatPolicyList.map((policy) => {
                    return (
                      <View
                        style={[
                          AtomicCss.flexRow,
                          AtomicCss.marginBottom5,
                          AtomicCss.marginTop5,
                        ]}
                      >
                        <Image
                          style={styles.baggageInfoIcon}
                          source={GREY_INFO_ICON}
                        />
                        <Text
                          style={[
                            fonts.boldFontFamily,
                            styles.policyTitle,
                            AtomicCss.font14,
                            AtomicCss.flex1,
                          ]}
                        >
                          {policy.title}
                          <Text
                            style={[
                              fonts.boldFontFamily,
                              styles.policyDesc,
                              AtomicCss.font14,
                            ]}
                          >
                            {' '}
                            : {policy.description}
                          </Text>
                        </Text>
                      </View>
                    );
                  })}
              </View>
            </React.Fragment>
          )}

          {!isEmpty(flightDesignator) &&
            !isEmpty(flightDesignator.flightFareType) && (
              <View
                style={[
                  AtomicCss.flexRow,
                  AtomicCss.alignCenter,
                  AtomicCss.marginBottom5,
                ]}
              >
                <Image style={styles.addFareStyle} source={FARE_ICON} />
                <View style={[AtomicCss.flex1, AtomicCss.flexRow]}>
                  <Text style={[styles.addFareTxt, fonts.boldFontFamily]}>
                    {flightDesignator.flightFareType}
                  </Text>
                </View>
              </View>
            )}

          {!isEmpty(flightDesignator) &&
            !isEmpty(flightDesignator.flightFareFamily) && (
              <View
                style={[
                  AtomicCss.flexRow,
                  AtomicCss.marginBottom5,
                  AtomicCss.alignCenter,
                ]}
              >
                <Image style={[AtomicCss.alignSelfStart, styles.addFareStyle]} source={FLEX_ICON} />
                <View
                  style={[
                    AtomicCss.flex1,
                    AtomicCss.flexRow,
                    AtomicCss.alignCenter,
                  ]}
                >
                  <Text
                    style={[
                      styles.addFareTxt,
                      fonts.boldFontFamily,
                      AtomicCss.alignSelfStart,
                    ]}
                  >
                    {flightDesignator.flightFareFamily}
                  </Text>
                  <FareServices
                    fareServices={flightDesignator.fareServices}
                    setFareServiceOverlayData={setFareServiceOverlayData}
                  />
                </View>
              </View>
            )}
        </View>

        {showExtraInfo && (
          <TravellerInfoCard
            updateFlightLayout={updateFlightLayout}
            travellerInfo={travellerInfo}
            purchasedBaggageInfo={purchasedBaggageInfo}
            isSegmentFailed={isSegmentFailed}
            layoutKey={layoutKey}
            flightInfo={flightInfo}
            seatMessage={seatMessage}
          />
        )}
        {!isEmpty(segmentAncillaryGenericMessage) &&
          segmentAncillaryGenericMessage.map((message, index) => {
            if (message) {
              return (
                <HTMLView
                  key={`genericMessage-${index}`}
                  style={{ paddingTop: 10 }}
                  stylesheet={htmlViewStyle}
                  value={message}
                />
              );
            }
            return null;
          })}
        {!isEmpty(scheduleChangeInfo) &&
          !isEmpty(scheduleChangeInfo.actionList)  &&
            (
            <WhatNextCard
              scActionList={scheduleChangeInfo.actionList}
              headerTitle={scheduleChangeInfo.whatNextHeader}
              response={flightDetailResponse}
              mobileNumber={
                getContactEmaiPhone(flightDetailResponse).contactNumber
              }
              bookingId={flightDetailResponse.bookingID}
              title={whatYouCanDoNextText}
              openDeepLink={openDeepLink}
            />
          )}
        {segmentRefundDetail && (
          <RefundRequestInProcess {...segmentRefundDetail} />
        )}
        {mmtConnect && !isEmpty(layoverDetail && layoverDetail.viInfo) && <VirtualInterlining data={layoverDetail.viInfo}/> }
        {!mmtConnect && isEmpty(layoverDetail && layoverDetail.viInfo) && layoverDetail &&
        !!layoverDetail.time && (
            <LayOverConnector {...layoverDetail} />
          )
        }
      </View>
    </React.Fragment>
  );
};

export default FlightItinaryDetail;
