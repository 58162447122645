/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import {View} from 'react-native';
import styles from '../styles/CabMamiCss';
import {getActionButton, getButtonStyle, handleClickEvent, isNotNullAndEmpty} from '../utils/CabBookingDetailUtil';

class CabMainCard extends React.Component {

  render() {
    return (
      <View style={[styles.MamiCard, styles.mar10Bt]}>
        <View
          style={isNotNullAndEmpty(this.props.card.actionList) ? getButtonStyle(this.props.card.actionList) : styles.hideView}
        >
          {getActionButton(this.props.card, this.props.response, this.handleClick)}
        </View>
      </View>
    );
  }

    handleClick = (action) => {
      handleClickEvent(this.props.card, action, this.props.response, this.props.pageName);
    };
}
CabMainCard.propTypes = {
  response: PropTypes.object.isRequired,
  card: PropTypes.object.isRequired,
  pageName: PropTypes.string.isRequired
};

export default CabMainCard;
