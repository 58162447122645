import React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import FlightBookingDetailsConstant, { LAST_MINUTE_CANCELLATION_STATUS } from "../../../FlightBookingDetailsConstant";
import { getStaticData } from "../../../../../../staticData/staticData";
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import { getFont } from "../../../../../../PostSalesStyles";
import { getCumulativeStatus } from "../../../../../cancellation/LastMinuteCancellation/LastMinuteCancellationAdapter";
import isEmpty from "lodash/isEmpty";
import Actions from '../../../../../../navigation/postSalesNavigation';

const LastMinuteCancellation = ({
  tentativeWindowBookingStatus,
  errorPageData,
  segmentDetail,
  scheduleChangeInfo,
  isCorporateBooking,
  bookingId,
  segmentInformation
}) => {
  const fonts = getFont(true);
  const fltInfoTagStyle = [styles.fltInfoTagStyle];
  const fltLastMinuteTagStyle = [styles.fltInfoTagStyle];
  const { knowMoreText, lastMinuteCancellation } = getStaticData();
  const pnrSegmentNo =
    !isEmpty(segmentDetail) &&
    `${segmentDetail.pnrNo}-${segmentDetail.segmentLineNo}`;
  const tentativeWindowStatus = getCumulativeStatus(
    tentativeWindowBookingStatus,
    pnrSegmentNo
  );
  const { state } = tentativeWindowStatus || {};
  const { cancellationStatus = "" } = state || {};

  let cancellationText = "";

  if (scheduleChangeInfo !== null) {
    if (
      scheduleChangeInfo.messageType === FlightBookingDetailsConstant.CANCEL ||
      scheduleChangeInfo.messageType === FlightBookingDetailsConstant.OTHERS
    ) {
      fltInfoTagStyle.push(styles.cancelledStyles);
    } else {
      fltInfoTagStyle.push(styles.postpondStyles);
    }
  }
  if (!isEmpty(cancellationStatus)) {
    switch (cancellationStatus) {
      case LAST_MINUTE_CANCELLATION_STATUS.CANCELLATION_IN_PROGRESS:
        fltLastMinuteTagStyle.push(styles.cancelledStyles);
        cancellationText = lastMinuteCancellation.inProgressText;
        break;
      case LAST_MINUTE_CANCELLATION_STATUS.CANCELLATION_FAILED:
        fltLastMinuteTagStyle.push(styles.postpondStyles);
        cancellationText = lastMinuteCancellation.failedText;
        break;
      default:
        fltLastMinuteTagStyle.push(styles.successStyles);
        cancellationText = lastMinuteCancellation.sucessText;
        break;
    }
  }

  const redirectToCouldnCancel = () => {
    const { airLineContactNo, flightDesignator, airlineLogoUrl } = segmentInformation || {};
    const {flightName} = flightDesignator || {};
    Actions.errorInLastMinuteCancellation({
      isCorporateBooking: isCorporateBooking,
      bookingId: bookingId,
      errorPageData,
      segmentWiseInfo: [
        {
          flightDesignator: {
            flightName: flightName,
          },
          airlineLogoUrl: airlineLogoUrl,
          airLineContactNo: airLineContactNo,
        },
      ],
    });
  };
  return (
    <React.Fragment>
      {!isEmpty(cancellationStatus) && (
        <View
          style={[
            AtomicCss.flexRow,
            AtomicCss.marginBottom15,
            AtomicCss.flexRow,
            AtomicCss.alignCenter,
          ]}
        >
          <View style={[fltLastMinuteTagStyle]}>
            <Text style={styles.fltInfoTagTxt}>{cancellationText}</Text>
          </View>
          {cancellationStatus ===
            LAST_MINUTE_CANCELLATION_STATUS.CANCELLATION_FAILED && (
            <TouchableOpacity onPress={redirectToCouldnCancel}>
              <Text style={styles.knowMoreStyle}>{knowMoreText}</Text>
            </TouchableOpacity>
          )}
        </View>
      )}
         {!cancellationStatus && scheduleChangeInfo !== null && !isEmpty(scheduleChangeInfo.message) && (
          <View style={styles.fltInfoTagWrapper}>
            <View style={[fltInfoTagStyle, AtomicCss.marginBottom15]}>
              <Text style={[styles.fltInfoTagTxt, fonts.boldFontFamily]}>
                {scheduleChangeInfo.message}
              </Text>
            </View>
          </View>
        )}
    </React.Fragment>
  );
};

export default LastMinuteCancellation;

const styles = StyleSheet.create({
  fltInfoTagTxt: {
    fontSize: 14,
    color: "#fff",
  },
  knowMoreStyle: {
    color: colors.azure,
  },
  fltInfoTagWrapper: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  fltInfoTagStyle: {
    borderRadius: 4,
    paddingVertical: 5,
    paddingHorizontal: 10,
    marginRight: 10,
  },
  postpondStyles: {
    backgroundColor: colors.red,
  },
  cancelledStyles: {
    backgroundColor: colors.lightYello,
  },
  successStyles: {
    backgroundColor: colors.red,
  },
});
