import {colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles';

const styles = {
  radioItem: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 0,
    paddingVertical: 10
  },

  radio: {
    width: 20,
    height: 20,
    borderRadius: 10,
    borderWidth: 2,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
    marginTop: 0
  },
  radioSelected: {
    borderColor: '#008cff'
  },
  radioSelectedCircle: {
    width: 14,
    height: 14,
    borderRadius: 20,
    marginLeft:0,
    marginTop:0
  },
  infoDtls: {
    flex: 1,
    marginLeft: 5
  },
  infoDtlsTxt: {
    color: colors.black,
    fontSize: 16,
    fontFamily: fonts.regular
  }
};
export default styles;
