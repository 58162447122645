import React from 'react';
import {View, Text, Image, TouchableOpacity} from 'react-native';
import isEmpty from 'lodash/isEmpty';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import BasePage from '../../Common/PostSalesBasePage';

class ContactItem extends BasePage {
  render() {
    const {agentInfo, index, count} = this.props;
    const agentType = agentInfo.mmtAgent;
    return (
      <View style={styles.cardBorder}>
        <View style={[styles.infoCardWrapper]}>
          <View style={styles.infoCardContent}>
            {!isEmpty(agentType) &&
            <Text
              style={[AtomicCss.defaultText, AtomicCss.regularFont, AtomicCss.font14]}>{agentType}
            </Text>
            }
            {(!isEmpty(agentInfo.agentName) || !isEmpty(agentInfo.agentMobileNo)) &&
            <View>
              <Text
                style={[AtomicCss.defaultText, AtomicCss.regularFont, AtomicCss.font14]}>{agentInfo.agentName}
              </Text>
              <Text
                style={[AtomicCss.defaultText, AtomicCss.blackFont, AtomicCss.font14]}>{agentInfo.agentMobileNo}
              </Text>
            </View>
            }
            {!isEmpty(agentInfo.agentMailID) &&
            <Text
              style={[AtomicCss.defaultText, AtomicCss.regularFont, AtomicCss.font14]}>{agentInfo.agentMailID}
            </Text>
            }
          </View>
        </View>
        {(index < (count - 1)) &&
        <View style={styles.cardSep}/>
        }
      </View>
    );
  }
}

const styles = {
  cardSep: {
    height: 2,
    width: '100%',
    marginLeft: 45,
    backgroundColor: '#e4e4e4'
  },
  cardBorder: {
    borderTopColor: '#e4e4e4',
    borderBottomColor: '#e4e4e4',
    borderBottomWidth: 0
  },
  infoCardWrapper: {
    backgroundColor: '#fff',
    justifyContent: 'space-between',
    marginBottom: 0,
    paddingHorizontal: 48
  },
  infoCardContent: {
    paddingRight: 8,
    paddingVertical: 15,
    flex: 1
  },
  infoCustom: {
    fontSize: 14,
    color: '#4a4a4a',
    lineHeight: 18,
    fontFamily: fonts.regular
  },
  headingIconWrapper: {
    width: 46,
    alignItems: 'center'
  },
  headingIconStyle: {
    width: 20,
    height: 20,
    top: 15
  },
  redirectBtnWrapper: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 50,
    paddingRight: 35,
    justifyContent: 'space-between',
    flexDirection: 'row',
    backgroundColor: '#fff',
    borderBottomWidth: 1,
    borderBottomColor: '#ededed',
    alignItems: 'center'
  },
  rightArrowIcon: {
    width: 9,
    height: 13
  },
  linkBtnTxt: {
    fontSize: 14,
    fontFamily: fonts.bold,
    color: '#008cff'
  },
  buttonWrapper: {
    paddingTop: 20,
    width: '90%'
  }
};


export default ContactItem;
