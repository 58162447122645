import {colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles';

const styles = {
  cardHeading: {
    paddingVertical: 12,
    paddingHorizontal: 14,
  },
  MamiCardGrey: {
    backgroundColor: '#ddd',
    paddingLeft: 10,
    paddingRight: 10
  },
  CardContentFull: {
    backgroundColor: colors.white,
    padding: 15,
    paddingBottom: 0,
  },
  CardContent: {
    backgroundColor: colors.white,
    flexDirection: 'row',
    paddingBottom: 5
  },
  CardIcon: {
    width: 24,
    height: 24,
    marginRight: 10
  },
  activityTick: {
    width: 8,
    height: 6,
    marginRight: 12
  },
  activityCross: {
    width: 8,
    height: 8,
    marginRight: 12
  },
  tickText: {
    flex: 1, fontSize: 14, color: '#249995', fontFamily: fonts.regular
  },
  CrossText: {
    flex: 1, fontSize: 14, color: '#eb2026', fontFamily: fonts.regular
  },
  viewMoreText: {
    flex: 1, fontSize: 14, color: '#008cff', fontFamily: fonts.regular
  },
  borderTop: { borderTopWidth: 1, borderTopColor: '#ddd' },
  buttonRadiusStyle: {
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
  },
  hideView: {
    display: 'none',
  },
};

export default styles;
