import { ViewStyle, TextStyle, ImageStyle, StyleSheet } from 'react-native';
import { DEFAULT_THEME } from 'apps/post-sales/src/theme/default.theme';

interface Styles {
  paymentDetailsWrapper: ViewStyle;
  borderBottom: ViewStyle;
  paymentDetailsHeading: TextStyle;
  paymentDetailsContainer: ViewStyle;
  defaultText: TextStyle;
  totalAmountContent: ViewStyle;
}

const createStyles = <Theme extends typeof DEFAULT_THEME>(theme: Theme) =>
  StyleSheet.create<Styles>({
    paymentDetailsWrapper: {},
    borderBottom: {
      borderBottomWidth: 1,
      borderBottomColor: theme.color.grey21,
      paddingBottom: 12,
      marginBottom: 12,
    },
    paymentDetailsHeading: {
      color: theme.color.black,
      paddingBottom: 12,
    },
    paymentDetailsContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingBottom: 8,
    },
    defaultText: {
      color: theme.color.black,
    },
    totalAmountContent: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  });

export default createStyles;