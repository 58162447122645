import { StyleSheet } from "react-native";
export default (theme, fonts) => StyleSheet.create({
    OverlayContentWrapper: {
        backgroundColor: theme.color.white,
        paddingTop: 12,
        position: 'relative',
        zIndex: 100,
        width: '100%',
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        paddingBottom: 50,
        overflow: 'hidden',
    },
    innerWrapper: {
        marginBottom: 180,
    },
    overlayTopline: {
        width: 40,
        height: 4,
        borderRadius: 4,
        backgroundColor: theme.color.disabledButton,
        alignSelf: 'center',
        borderRadius: 4,
        zIndex: 11,
        top: 5,
        opacity: .8,
    },
    headingTxt: {
        fontSize: 24,
        color: theme.color.black22,
        ...(fonts.blackFontFamily)
    },
})