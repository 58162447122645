import React from 'react';
import {View, Text, Image} from 'react-native';
import styles from './ClaimProcessListCss';
import {mailIcon, callMmtIcon, refundsIcon} from '../../BusImageConstants';
import { getFont } from '../../../PostSalesStyles';

export default class ClaimProcessList extends React.Component {
  render() {
    const fonts = getFont(true);
    return (
      <View style={[styles.whiteCard, styles.listWrapper]}>
        <View style={styles.listItem}>
          <Image style={[styles.listItemIcon, styles.Email_Icon]} source={mailIcon} />

          {this.props.processList && this.props.processList.length>0 && <Text style={[styles.listItemTitle, fonts.boldFontFamily]}>{this.props.processList[0].header}</Text>}
          {this.props.processList && this.props.processList.length>0 && <Text style={[styles.listItemDesc, fonts.regularFontFamily]}>{this.props.processList[0].description}</Text>}
        </View>
        <View style={styles.listItem}>
          <Image style={[styles.listItemIcon, styles.Call_Icon]} source={callMmtIcon} />
          {this.props.processList && this.props.processList.length>1 && <Text style={[styles.listItemTitle, fonts.boldFontFamily]}>{this.props.processList[1].header}</Text> }
          {this.props.processList && this.props.processList.length>1 && <Text style={[styles.listItemDesc, fonts.regularFontFamily]}> {this.props.processList[1].description} </Text> }
        </View>
        <View style={styles.listItem}>
          <Image style={[styles.listItemIcon, styles.Refund_Icon]} source={refundsIcon} />
          {this.props.processList && this.props.processList.length>2 && <Text style={[styles.listItemTitle, fonts.boldFontFamily]}>{this.props.processList[2].header}</Text> }
          {this.props.processList && this.props.processList.length>2 && <Text style={[styles.listItemDesc, fonts.regularFontFamily]}> {this.props.processList[2].description} </Text> }
        </View>
      </View>
    );
  }
}
