import React from 'react';
import {View, StyleSheet, TouchableOpacity, Dimensions} from 'react-native';
import PropTypes from 'prop-types';

import {colors} from '@mmt/legacy-commons/Styles/globalStyles';

const windowHeight = Dimensions.get('screen').height;
const windowWidth = Dimensions.get('screen').width;

export default function Overlay(props) {
  return (
    <View style={styles.overlayWrapper}>
      <TouchableOpacity onPress={() => {
        props.handleButtonClick();
        props.onClose();
      }} activeOpacity={1} style={styles.overlay} />
      <View style={styles.overlayContent}>{props.component}</View>
    </View>
  );
}

Overlay.propTypes = {
  onClose: PropTypes.func.isRequired,
  component: PropTypes.element.isRequired
};

const styles = StyleSheet.create({
  overlayWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: windowHeight,
    width: windowWidth,
    elevation: 10,
    zIndex: 100,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    flexDirection: 'row'
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
    height: '100%',
    width: '100%',
    zIndex: 1
  },
  overlayContent: {
    backgroundColor: colors.white,
    padding: 30,
    position: 'relative',
    zIndex: 1,
    width: '100%'
  }
});
