import React from 'react';
import Actions from '../../../navigation/postSalesNavigation';
import PropTypes from 'prop-types';
import {Image, ScrollView, SectionList, Text, TouchableOpacity, View} from 'react-native';
import StickyHeader from './StickyHeader';
import {isIntlHotelBooking} from '../utils/HotelBookingDetailUtil';
import getStyles from './HotelMamiCss';
import PostSalesHotelsBaseClass from './PostSalesHotelsBaseClass';
import HotelBookingTrackingHelper from '../../HotelBookingTrackingHelper';
import logAction from '@mmt/legacy-commons/Helpers/actionsLogger';
import { getStaticData } from '../../../staticData/staticData';
import { CLOCK_AT_3_ICON, BOW_ICON, LEFT_BLACK_ARROW_ICON, CHEVRON_RIGHT_BLUE_ICON3 } from '../../HotelImageConstants';
import { imageTransformY } from '../../../utils/PostSaleUtil';
import ContactUs from '../cards/ContactUs';
import isEmpty  from 'lodash/isEmpty';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const headerIcon = LEFT_BLACK_ARROW_ICON;
const arrowIcon = CHEVRON_RIGHT_BLUE_ICON3;
const checkinIcon = CLOCK_AT_3_ICON;
const specialRequestIcon = BOW_ICON;

export default class MakeRequest extends PostSalesHotelsBaseClass {
  constructor(props) {
    super(props, 'makeRequestPage', 'SpecialrequestsStandAlone');
    this.fonts = getFont(true);
    this.styles = getStyles(this.fonts);
    const {response} = this.props;
    this.trackPageName = isIntlHotelBooking(response?.lobCode)
    ? 'Mob:customer support:Hotel International:MakeRequestPage'
    : 'Mob:customer support:Hotel Domestic:MakeRequestPage';
  }

  subRequestDetail(subRequestData) {
    const clickEvent = `MI_HTL_${subRequestData.requestType}`;
    Actions.submitRequestPage({ subRequestData, response: this.props.response, bookingId: this.props.response.bookingID });
    logAction(`${subRequestData.requestType} clicked`);
    HotelBookingTrackingHelper.trackClickEvent('SpecialrequestsStandAlone', clickEvent, this.props.response);
  }

  render() {
    const staticData = getStaticData();
    const {response} = this.props;
    const {contactUsData = {}, showContactUsCard = false} = !isEmpty(response) ? response : {};
    const { header, infoText, disable = false} = contactUsData || {};
    return (
      <View style={this.styles.makeRequestWrapper}>
        <StickyHeader
          iconSrc={headerIcon}
          title={staticData.makeARequestText}
          headerColor={['#ffffff', '#ffffff']}
          titleStyle={[this.styles.makeRequestHeader]}
          subTitle=''
          subTitleStyle={[this.styles.DefaultText, this.styles.font12]}
          onBackPress={this.onBackPress}
        />
        {showContactUsCard ? <ScrollView style={[this.styles.makeRequestHeaderPaddingBottom]}>
          <ContactUs
            key="ContactUsCard"
            response={response}
            pageName={this.trackPageName}
            header={header}
            info={infoText}
            disable={disable}
          />
        </ScrollView>:
        <ScrollView>
          <View style={[this.styles.makeRequestHeaderPaddingBottom]}>
            {this.getMainRequestDetailView(this.props.response.requestDetailMapping)}
          </View>
        </ScrollView>}
      </View>
    );
  }

  getData(items) {
    const array = [];
    for (const key in items) { array.push({ title: (items[key][0] && items[key][0].mainRequestTypeTranslated || key), data: items[key] }); }
    return array;
  }

  getMainRequestDetailView(requestDetailMapping) {
    return (
      <SectionList
        style={[this.styles.makeRequestHeaderPaddingBottom]}
        sections={this.getData(requestDetailMapping)}
        renderSectionHeader={({section}) => (
          <View key={section.title} style={[this.styles.flexRow, this.styles.requestInfoOuter]}>
            <View style={[this.styles.makeRequestSubHeader]}>
              <Image
                style={section.title === 'Check In/ Check Out' ?
                  this.styles.checkinRequestImage : this.styles.specialRequestImage}
                source={section.title === 'Check In/ Check Out' ? checkinIcon : specialRequestIcon}
              />
              <Text style={this.styles.makeRequestSubHeadding}>{ section.title }</Text>
            </View>
          </View>
        )
        }
        renderSectionFooter={({section}) =>
              (<View style={{paddingVertical: 20}} />)
          }

        renderItem={({item}) => (
          <View style={[this.styles.ActionBtnRec, this.styles.transperantBgrnd]}>
            <TouchableOpacity onPress={() => this.subRequestDetail(item)}>
              <View style={this.styles.RecFullBtn}>
                <Text style={this.styles.BlueText}>{item.requestType}</Text>
                <Image style={[this.styles.RecFullBtnArrow, imageTransformY]} source={arrowIcon} />
              </View>
            </TouchableOpacity>
          </View>
        )}
        keyExtractor={(item, index) => item.id}
      />
    );
  }
}

MakeRequest.propTypes = {
  response: PropTypes.object.isRequired
};

