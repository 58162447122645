import React from 'react';
import {Image, Text, View} from 'react-native';
import {isEmpty} from 'lodash';
import PropTypes from 'prop-types';
import styles from './PrimaryContactCss';
import AtomicCss from '../AtomicCss';
import {primaryCardIcon, emailIcon, contactIcon} from '../../utils/AcmeImageConstant';


class PrimaryContact extends React.Component {
  render() {
    const customerDetail = this.props.response.primaryCustomerDetails;
    return (
      <View style={[AtomicCss.whiteBg, AtomicCss.marginBottom10]}>
        <View style={[styles.CardContentFull, AtomicCss.marginBottom24]}>
          <View style={[styles.CardContent]}>
            <Image style={styles.CardIcon} source={primaryCardIcon} />
            <Text style={[AtomicCss.blackFont, AtomicCss.blackText, AtomicCss.font14]}>
              PRIMARY CONTACT
            </Text>
          </View>
          <View style={[styles.marL10per, AtomicCss.paddingRight16]}>
            <Text
              style={[AtomicCss.font14, AtomicCss.boldFont, AtomicCss.blackText, AtomicCss.marginBottom10]}
            >
              {`${customerDetail.firstName} ${customerDetail.lastName}`}
            </Text>
            <View style={[AtomicCss.flexRow, AtomicCss.marginBottom5]}>
              <Image style={styles.messageIcon} source={emailIcon} />
              <Text
                style={[AtomicCss.flex1, AtomicCss.font12, AtomicCss.defaultText, AtomicCss.regularFont]}
              >{customerDetail.emailId}
              </Text>
            </View>
            <View style={AtomicCss.flexRow}>
              <Image style={styles.callIcon} source={contactIcon} />
              <Text
                style={[AtomicCss.flex1, AtomicCss.font12, AtomicCss.defaultText, AtomicCss.regularFont]}
              >{this.getContactNo(customerDetail)}
              </Text>
            </View>
          </View>

        </View>
      </View>
    );
  }

  getContactNo(customerDetail) {
    return isEmpty(customerDetail.mobileNumber)
      ? customerDetail.phoneNumber
      : customerDetail.mobileNumber;
  }
}

PrimaryContact.propTypes = {
  response: PropTypes.object.isRequired
};

export default PrimaryContact;
