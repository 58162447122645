import React from 'react';
import PropTypes from 'prop-types';
import {getActionButton, isNotNullAndEmpty} from '../../../hotel/details/utils/HotelBookingDetailUtil';
import styles from '../../CSS/VisaMamiCss';
import {handleClickEvent} from '../../Util/VisaUtil';
import {Image, Text, View} from 'react-native';
import {faqIcon} from '../../Constant/VisaImageConstant';


class VisaAdditionalDocumentCard extends React.Component {
  render() {
    return (<View style={[styles.MamiCard, styles.mar10Bt]} >
      <View style={styles.VisaCardContent}>
        <View style={styles.flexRow}>
          <Image style={styles.CardIcon} source={faqIcon} />
          <View style={styles.flex1}>
            <Text style={[styles.visaAdditionalDocumentCard]}>
                            ADDITONAL DOCUMENTS NEEDED

            </Text>
            <Text style={[styles.visaAdditionalDocumentCardMessage]}>
              {this.props.message}
            </Text>
          </View>
        </View>
      </View>
      <View style={isNotNullAndEmpty(this.props.card.actionList) ? styles.whiteBtnHalfOuter : styles.hideView}>
        {getActionButton(this.props.card, this.props.response, this.handleClick)}
      </View>
    </View>);
  }

    handleClick = (action) => {
      handleClickEvent(this.props.card, action, this.props.response, this.props.pageName);
    };
}

VisaAdditionalDocumentCard.propTypes = {
  card: PropTypes.object.isRequired,
  pageName: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired
};

export default VisaAdditionalDocumentCard;
