import { StyleSheet } from 'react-native';

export default (theme, fonts) =>
  StyleSheet.create({
    claimStatusInfoWrapper: {
      flexDirection: 'row',
      paddingVertical: 2,
      paddingHorizontal: 16,
    },
    doubleSeatIcon: {
      width: 24,
      height: 24,
      marginRight: 10,
      marginTop: 2,
    },
    priceInfoIcon: {
      width: 18,
      height: 17,
      marginRight: 10,
      marginTop: 2,
    },
    attributeText: {
      flex: 1,
      color: theme.color.green,
      marginTop: 5,
      marginBottom: 5,
    },
    priceInfoTxt: {
      flex: 1,
      color: theme.color.green,
    },
  });

