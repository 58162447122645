import React from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import getStyle from '../../Css/NeedUploadProofOverlayCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getFont } from '../../../../../PostSalesStyles';
import { UPLOAD_PROOF } from '../../../../FlightImageConstants';
import { isEmptyArray } from '../../../../../Common/commonUtil';
import {getNeedUploadProofData} from "../../data/dataAdapter"
import { useTheme } from '../../../../../theme/theme.context';

const NeedUploadProofOverlay = ({
  handleBottomOverlay,
  data,
  segments,
  reqSegments,
  okayGotItText,
}) => {
  const { fsStyle, ...fonts } = getFont(true);
  const {psTheme} = useTheme();
  const {color} = psTheme;
  const styles = getStyle(psTheme);
  const closeOverlay = () => {
    handleBottomOverlay('');
  };
  const {
    title,
    subtitle,
    footerText,
    segmentIncludedSubtitle
  } = getNeedUploadProofData(data, segments, reqSegments);
  return (
    <View style={styles.OverlayContentWrapper}>
      <View style={AtomicCss.marginBottom24}>
        <View style={styles.overlayTopline}></View>
      </View>
      <View style={styles.uploadProofWrapper}>
        <Image source={UPLOAD_PROOF} style={styles.uploadProofStyle} />
        {!!title && (
          <Text
            style={[styles.title, fonts.blackFontFamily, AtomicCss.marginBottom8, fsStyle.font22]}
          >
            {title}
          </Text>
        )}
        <View style={styles.strokeStyle}></View>
        {!isEmptyArray(segmentIncludedSubtitle)
          ? segmentIncludedSubtitle.map((sub) => (
              <Text
                key={sub}
                style={[
                  fonts.boldFontFamily,
                  fsStyle.font16,
                  {color : color.black},
                  styles.lineHeight22,
                  AtomicCss.marginBottom12,
                ]}
              >
                {sub}
              </Text>
            ))
          : !isEmptyArray(subtitle) &&
            subtitle.map((sub) => (
              <Text
                key={sub}
                style={[
                  fonts.boldFontFamily,
                  fsStyle.font16,
                  {color : color.black},
                  styles.lineHeight22,
                  AtomicCss.marginBottom12,
                ]}
              >
                {sub}
              </Text>
            ))}
        {!!footerText && (
          <Text
            style={[
              fonts.regularFontFamily,
              fsStyle.font14,
              {color : color.black},
              styles.lineHeight22,
              styles.marginBottom60,
            ]}
          >
            {footerText}{' '}
          </Text>
        )}
        <TouchableOpacity style={[AtomicCss.flexRow]} onPress={closeOverlay}>
          <Text
            style={[
              fsStyle.font16,
              fonts.blackFontFamily,
              {color : color.azure},
              AtomicCss.alignRight,
              AtomicCss.lineHeight20,
              AtomicCss.flex1,
            ]}
          >
            {okayGotItText}
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default NeedUploadProofOverlay;
