import React from 'react';
import {View, Easing, Animated, TouchableOpacity} from 'react-native';
import PropTypes from 'prop-types';
import getStyles from './OverlayCss';
import OverlayContent from './OverlayContent';
import { getStaticData } from '../../../staticData/staticData';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

class PAHBottomOverlay extends React.Component {
  constructor(props) {
    super(props);
    this.fonts = getFont(true);
    this.styles = getStyles(this.fonts);
    this.state = {
      toastPostion: new Animated.Value(-400)
    };
  }
  componentDidMount() {
    const bottom = 0
    this.startAnimate(bottom);
  }

  startAnimate(bottom) {
    Animated.timing(this.state.toastPostion, {
      toValue: bottom,
      easing: Easing.easeInOut
    }).start();
  }

  render() {
    const staticData = getStaticData();
    return (
      <View style={this.styles.overlayWrapper}>
        <TouchableOpacity onPress={() => this.props.handleCloseOverlay()} activeOpacity={.9} style={this.styles.overlay}></TouchableOpacity>
        <Animated.View style={[this.styles.overlayContent, {bottom: this.state.toastPostion}]}>
          <OverlayContent
            title={this.props.responseJson.paymentBreakup.bnplCardChargeInfo.cardHeading}
            cardType={`${(this.props.responseJson.isUPIBooking) ? staticData.upiText : staticData.creditCardText}`}
            bankName={this.props.responseJson.paymentBreakup.bnplCardChargeInfo.bankName}
            cardNo={this.props.responseJson.paymentBreakup.bnplCardChargeInfo.cardNo}
            maskedCardNo={this.props.responseJson.paymentBreakup.bnplCardChargeInfo.maskedCardNo}
            cvvMessage={this.props.responseJson.paymentBreakup.bnplCardChargeInfo.cvvMessage}
            responseJson={this.props.responseJson}
            handleCloseOverlay={this.props.handleCloseOverlay}
            pageName={this.props.pageName}
            isUPIBooking={this.props.responseJson.isUPIBooking}
          />
        </Animated.View>
      </View>

    );
  }
}
PAHBottomOverlay.propTypes = {
  responseJson: PropTypes.object.isRequired,
  pageName: PropTypes.string.isRequired,
  handleCloseOverlay: PropTypes.object.isRequired
};
export default PAHBottomOverlay;
