import React from 'react';
import { View, Image, Text, TouchableOpacity } from 'react-native';
import {TG_ICON, CROSS_ICON2} from '../../../Utils/RailImageConstant';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import BaseButton, { BUTTON_VARIANT } from '../../../../Common/BaseButton';
import { getFont } from '../../../../PostSalesStyles';
import { useTheme } from '../../../../theme/theme.context';
import {createStyles} from './TGSOverlayCss';
import isEmpty from 'lodash/isEmpty';
import RefundInfoSection from './RefundInfoSection';
import Actions from '../../../../navigation/postSalesNavigation';
import backArrow from '@mmt/legacy-assets/src/back-white.webp';

const TGSOverlay = ({handleBottomOverlay, tgsUpgradeCardData = {},proceedHandler, response}) => {
    const fonts = getFont();
    const { psTheme: theme } = useTheme();
    const styles =  createStyles(theme,fonts);
    const { topHeaderText, overlayData} = tgsUpgradeCardData || {};
    const {subHeaderText, benefitText, benefitList, btnText, tandCText} = overlayData || {};
    const continueClickHandler = () => {
      handleBottomOverlay();
      proceedHandler();
    }

    const handleClick = () => {
      const {train} = response || {};
      const {zcplusLink} = train.length && train[0] || {};
      if(!isEmpty(zcplusLink)){
        Actions.openPsWebView({
          url: zcplusLink,
          headerText: response.zcplusCardData && response.zcplusCardData.zcplusHeadingApp,
          headerIcon: backArrow
        });
      }
    }

    return (
    <>
      <View style={[styles.headingSection]}>
        <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.spaceBetween, AtomicCss.marginBottom10]}>
          <View style={[AtomicCss.flexRow, AtomicCss.flex1, AtomicCss.alignCenter]}>
            <Image style={styles.tgIconStyle} source={TG_ICON} />
            {!isEmpty(topHeaderText) && <Text style={[styles.headingText, AtomicCss.fontWeightBold]}>{topHeaderText}</Text>}
          </View>
          <TouchableOpacity onPress={ handleBottomOverlay }>
              <Image style={styles.crossIconStyle} source={CROSS_ICON2}/>
          </TouchableOpacity>
        </View>
        {!isEmpty(subHeaderText) && <Text style={[fonts.fsStyle.font14, AtomicCss.midGreyText]}>{subHeaderText}</Text>}
        {!isEmpty(tandCText) && <TouchableOpacity onPress={handleClick}><Text style={styles.tandCText}>{tandCText}</Text></TouchableOpacity>}
      </View>
      <View style={styles.detailsSection}>
        { !isEmpty(benefitText) && <Text style={[fonts.fsStyle.font20, AtomicCss.blackText, fonts.boldFontFamily, AtomicCss.marginBottom20, AtomicCss.fontWeightBold]}>{benefitText}</Text> }
        { !isEmpty(benefitList) && benefitList.map( (benefitData,index) => {
          const {benefitImageUrl, benefitText, benefitHeader, benefitTextList} = benefitData || {};
          return (
            <View style={styles.rowSection}>
              <View style={styles.iconWrapper}>
                {!isEmpty(benefitImageUrl) && <Image style={[styles.iconStyles]} source={{uri:benefitImageUrl}} />}
              </View>
              <View style={[AtomicCss.flex1]}>
                { !isEmpty(benefitHeader) && <Text style={[AtomicCss.blackText, fonts.boldFontFamily, fonts.fsStyle.font16, AtomicCss.marginBottom8, AtomicCss.fontWeightBold]}>{benefitHeader}</Text>}
                {!isEmpty(benefitText) && <Text style={[AtomicCss.defaultText, fonts.fsStyle.font14, fonts.regularFontFamily]}>{benefitText}</Text>}
                  { !isEmpty(benefitTextList) && <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween]}>
                    {benefitTextList.map( benefitTextItem => {
                    const {header, description} = benefitTextItem || {};
                    return (
                      <View style={[AtomicCss.flex1, AtomicCss.marginRight10]}>
                        {!isEmpty(header) && <Text style={[AtomicCss.blackText, fonts.fsStyle.font12, fonts.boldFontFamily, AtomicCss.marginBottom5, AtomicCss.fontWeightBold]}>{header}</Text>}
                        {!isEmpty(description) && <Text style={[AtomicCss.defaultText, fonts.fsStyle.font12, fonts.regularFontFamily]}>{description}</Text>}
                      </View>
                    )
                })}
                </View>}
                { index===0 && <RefundInfoSection tgsUpgradeCardData={tgsUpgradeCardData}/>}
              </View>
            </View>
          )
        })}
        {!isEmpty(btnText) && <BaseButton
            text={btnText}
            variant={BUTTON_VARIANT.CAPSULE}
            clickHandler={continueClickHandler}
            shadow
        />}
      </View>
    </>)
}

export default TGSOverlay;