import { View, StyleSheet, Dimensions } from 'react-native';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
export default (theme, fonts) => {
  const { color } = theme;
  return StyleSheet.create({
    downloadIconStyle:{
      width:24,
      height:24,
      marginRight:28,
  },
    crossIconStyle: {
      width: 14,
      height: 14,
    },
    whiteCrossIcon: {
      tintColor: color.white,
    },
    downloadIcon: {
      width: 24,
      height: 24,
      marginRight: 16,
      tintColor: color.lightGreen18,
      top: -3
    },
    eTicketText:{
      marginRight: 5,
      color: color.lightGreen18,
      ...fonts.boldFontFamily
    },
    greyColor : {
      color: color.greyText
    },
    backArrowWrapper: {
      paddingRight: 5
    },
    backArrow: {
      width: 24,
      height: 24,
      marginRight: 5,
      tintColor: color.darkGrey4,
    },
    backArrowIos: {
      width: 11,
      height: 18,
    },
    backButton : {
      height: 24,
      width: 24,
      alignItems: 'center'
    }
  });
};
