import React from 'react';
import {View, Text, Image, TouchableOpacity} from 'react-native';
import styles from './inclusionExclusionCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import BasePage from '../../../../Common/PostSalesBasePage';
import {EXTRA_CHARGE_ICON} from '../../../SelfDriveImageConstant';

export default class ExtraCharge extends BasePage {
    constructor() {
        super();
        this.state = {
            active: true
        }
    }

    handleClick = () => {
        this.setState({active: !this.state.active})
    }

    render() {
        return (
            <View style={styles.wrapper}>
                <TouchableOpacity onPress={this.handleClick}
                                  style={[AtomicCss.flexRow, AtomicCss.spaceBetween, styles.head]}>
                    <Text style={[AtomicCss.blackText, AtomicCss.font14, AtomicCss.blackFont]}>Extra charges based on
                        usage</Text>
                </TouchableOpacity>
                {this.state.active &&
                <View>
                    {this.fetchCharges(this.props.response.extraChargeInfo)}
                </View>
                }
            </View>
        )
    }

    fetchCharges = function (charges) {
        let views = [];
        Object.keys(charges).map(key => {
            views.push(
                <View style={[AtomicCss.marginBottom10, AtomicCss.flexRow, AtomicCss.alignCenter]}><Image
                    source={EXTRA_CHARGE_ICON} style={styles.extraCharge}/><Text
                    style={[AtomicCss.greyText, AtomicCss.font14]}>{charges[key]}</Text></View>
            )
        })
        return views;
    }
}


