import { getCommonHeaders, getOdcApprovalCommonHeaders } from '../../../../../../utils/NetworkUtils';
import { LOBNAMES } from 'apps/post-sales/src/PostSalesConstant';
export const fetchReviewResponse = async (userInfo, authCode, url, request) => {
  let response;
  try {
    const headers = await getOdcApprovalCommonHeaders(userInfo, authCode);
    response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(request),
    });
  } catch (e) {
    console.log(e);
  } finally {
    return await getResponseBody(response);
  }
};

export const fetchRaiseRequest = async (userInfo, request, url) => {
  let response;
  try {
    response = await fetch(url, {
      method: 'POST',
      headers: await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo, {
        psLob: LOBNAMES.FLIGHT,
      }),
      body: JSON.stringify(request),
    });
  } catch (e) {
    console.log(e);
  } finally {
    return await getResponseBody(response);
  }
};

export const getWorkFlowDetails = async (url, userInfo, authCode) => {
  let response;
  try {
    const headers = await getOdcApprovalCommonHeaders(userInfo, authCode);
    response = await fetch(url, {
      method: 'GET',
      headers: headers,
    });
  } catch (e) {
    console.log(e);
  } finally {
    return await getResponseBody(response);
  }
};

const getResponseBody = async (response) => {
  if (response && response.ok) {
    const resp = await response.json();
    return resp;
  } else {
    return Promise.reject('ERROR');
  }
};
