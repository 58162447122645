import React, { useState, useEffect } from 'react';
import { ScrollView, View } from 'react-native';
import NoUpcomingText from './components/NoUpcomingText';
import WhereToGoSlider from './components/SnapSlider';
import createStyle from './style';
import { getFont } from '../../../../PostSalesStyles';
import { useTheme } from '../../../../theme/theme.context';
import { loadWhereToGoData, loadSocialMediaData } from '../../data/api';
import { WhereToGOImage, WhereToGoNoTripsObject } from '../../types';
import { isEmpty } from 'lodash';


const WhereToGo = () => {
    const [whereToGoData, setWhereToGoData] = useState<WhereToGoNoTripsObject>();
    const loadData = async () => {
        let data = await loadWhereToGoData();
        const socialMediaData = await loadSocialMediaData();
        if(socialMediaData) {
            socialMediaData.isSocialMediaData = true;
            if(data) {
                data.push(socialMediaData) 
            } else {
                data = [ socialMediaData ]
            }
        }
        setWhereToGoData(data);
    };
    useEffect(() => {
        loadData();
    }, []);
    const { psTheme: theme } = useTheme();
    const fonts = getFont();
    const styles = createStyle(theme, fonts);

    return (
        <ScrollView>
            <View style={styles.innerWrapper}>
                <NoUpcomingText />
                {!isEmpty(whereToGoData) && <WhereToGoSlider data={whereToGoData} />}
            </View>
        </ScrollView>
    );
};

export default WhereToGo;

