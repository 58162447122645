
import React, { useState, useEffect } from 'react';
import { CANCELLATION_THANKYOU_PAGE_VIEW } from "../../../constant";
import { fetchFlightCancellationDone } from "../../../data/api";
import { isNetworkAvailable } from '@mmt/legacy-commons/Common/utils/AppUtils';

const useCancellationDone = (cancellationRequest, { bookingId }) => {

    const [viewState, setViewState] = useState(CANCELLATION_THANKYOU_PAGE_VIEW.LOADING);
    const [cancellationDonePageData, setCancellationDonePageData] = useState(null);
    const [errorType, setErrorType] = useState(null);

    const cancellationDoneAPI = async () => {

        const hasNetwork = await isNetworkAvailable();
        if (!hasNetwork) {
            setViewState(CANCELLATION_THANKYOU_PAGE_VIEW.NO_INTERNET);
            return;
        }

        const response = await fetchFlightCancellationDone(bookingId, cancellationRequest);

        setCancellationDonePageData(response.rawResponse);
        setErrorType(response.error ? response.errorType : null);
        setViewState(response.error ? CANCELLATION_THANKYOU_PAGE_VIEW.ERROR : CANCELLATION_THANKYOU_PAGE_VIEW.LOADED);
    }

    useEffect(() => {
        cancellationDoneAPI();
    },[])

    const onRetry = () => {
        cancellationDoneAPI();
        setErrorType(null);
        setViewState(CANCELLATION_THANKYOU_PAGE_VIEW.LOADING);
    }

    return [viewState, cancellationDonePageData, errorType, onRetry];

}

export default useCancellationDone;