import React from 'react';
import {View,Image} from 'react-native';
import getStyles from './HotelMamiCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import HTMLView from 'react-native-htmlview';
import {cashBackStageConstant} from '../HotelBookingDetailsConstant'
import {colors} from '@mmt/legacy-commons/Styles/globalStyles'
import {
    isNotNullAndEmpty
  } from '../utils/HotelBookingDetailUtil';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
const getHtmlStyles = (fonts) => {
    return {
        span: { ...fonts.regularFontFamily, ...fonts.font12, ...AtomicCss.blackText},
        b: { ...fonts.boldFontFamily }
    };
}
function getCashBackStyle(stage){
    switch(stage){
        case cashBackStageConstant.CASHBACK_IN_PROCESS:
            return {
                backgroundColor:colors.creamWhite,
                borderLeftColor: colors.primary,
                borderLeftWidth:4,
            }
        case cashBackStageConstant.CASHBACK_PROCESSED:
            return {
                backgroundColor:colors.cruise
            }
        case cashBackStageConstant.BC_CASHBACK_NOT_PROCESSED:
        case cashBackStageConstant.BC_CASHBACK_PROCESSED:
            return {
                backgroundColor:colors.lightPink
            }
        default:
            return {}
    }
}
const CashbackBanner = ({ iconUrl, cashbackStage, cashbackText })=>{
    const { ...fonts } = getFont(true);
    const styles = getStyles(fonts);
    const htmlStyle = getHtmlStyles(fonts);
    const cashBackStyle= getCashBackStyle(cashbackStage);
    return (
        isNotNullAndEmpty(cashbackText)?<View style={styles.cancelDetails}>
            <View style={[styles.cashbackStage,cashBackStyle]}>
                <View style={styles.cashbackIcon}>
                    <Image source={{uri:iconUrl}} style={styles.priceIcon} />
                </View>
                <View style={AtomicCss.flex1}>
                    <HTMLView 
                        value={cashbackText}
                        stylesheet={htmlStyle}
                    />
                </View>
            </View>
        </View>:null
    )
} 

export default CashbackBanner;