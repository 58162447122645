import React, { useState } from 'react';
import { View, Text, Image, TouchableOpacity, Animated, Easing } from 'react-native';
import { getFont } from '../../../../../PostSalesStyles';
import { useTheme } from '../../../../../theme/theme.context';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import createStyles from '../../changeDateOverlayCss';
import { BLUE_ARROW } from 'apps/post-sales/src/bus/BusImageConstants';
function FAQList({ faq, index, lastElemet }) {
  const { question, answer } = faq || {};
  const [showAnswer, setShowAnswer] = useState(false);
  const lastElemetStyle = lastElemet ? 'lastElemetStyle' : '';
  const { fsStyle, ...fonts } = getFont();
  const {psTheme : theme} = useTheme();
  const styles = createStyles(theme,fonts);
  const handleAccordian = () => {
    setShowAnswer(!showAnswer);
  };
  return (
    <View style={styles.faqListRow}>
      <TouchableOpacity
        style={styles.faqListRowHeading}
        onPress={handleAccordian}
        activeOpacity={0.7}
      >
        <Text
          style={[
            fonts.boldFontFamily,
            fsStyle.font14,
            AtomicCss.defaultText,
            AtomicCss.marginBottom5,
          ]}
        >
          {question}
        </Text>
        <Image style={[showAnswer ? styles.activeArrow : {},styles.rightArrow]} source={BLUE_ARROW} />
      </TouchableOpacity>
      {showAnswer && (
        <View style={[AtomicCss.marginBottom20, styles[lastElemetStyle]]}>
          <Text style={[fonts.regularFontFamily, fsStyle.font14, AtomicCss.defaultText]}>
            {answer}
          </Text>
        </View>
      )}
    </View>
  );
}

export default FAQList;
