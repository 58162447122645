import {colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles';

const styles = {
  pageWrapper: {
    flex: 1,
    backgroundColor: '#f2f2f2'
  },
  whiteBg: {
    backgroundColor: colors.white
  },

  whiteCard: {
    backgroundColor: '#ffffff',
    elevation: 2,
    shadowColor: '#000000',
    shadowOffset: {width: 0, height: 2},
    shadowOpacity: 0.2,
    shadowRadius: 2,
    padding: 16

  },
  errorInfoIcon: {
    width: 32,
    height: 32,
    marginRight: 10
  },
  cancelTopHeading: {
    fontFamily: fonts.bold,
    color: '#000',
    fontSize: 20,
    marginTop: 20,
    marginBottom: 12,
    lineHeight: 26
  },
  cancelDescription: {
    fontSize: 14,
    lineHeight: 21,
    fontFamily: fonts.regular,
    color: colors.defaultTextColor,
    textAlign: 'center'

  },
  cancelErrorWrapper: {
    marginTop: 33,
    marginBottom: 24,
    justifyContent: 'center',
    alignItems: 'center'
  },
  btnWrapper: {
    paddingBottom: 0
  },
  dividerWrapper: {
    alignItem: 'center',
    justifyContent: 'center',
    height: 56,
    position: 'relative',
    marginHorizontal: 16


  },
  dividerLine: {
    width: '100%',
    height: 1,
    backgroundColor: '#ccc',
    position: 'absolute'
  },
  orDivider: {
    width: 32,
    height: 32,
    borderRadius: 50,
    borderColor: '#ccc',
    borderWidth: 1,
    backgroundColor: '#f2f2f2',
    justifyContent: 'center',
    alignSelf: 'center'

  },
  orDividerText: {
    fontSize: 12,
    fontFamily: fonts.regular,
    color: colors.defaultTextColor,
    alignSelf: 'center'
  },
  customerSupportWrapper: {
    backgroundColor: '#fff',
    justifyContent: 'space-around',
    flexDirection: 'row',
    paddingVertical: 20,
    borderBottomWidth: 1,
    borderBottomColor: '#ededed'
  }
};

export default styles;
