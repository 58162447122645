import React, { Component } from 'react';
import {Text, View, StyleSheet } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {colors} from '@mmt/legacy-commons/Styles/globalStyles';
import {isNotNullAndEmpty} from '../../../hotel/details/utils/HotelBookingDetailUtil';

const PayInfoCard = ({pastPayments}) => {
    return(
        <View style={[styles.PayInfoWrapper,AtomicCss.marginBottom10]}>
        {isNotNullAndEmpty(pastPayments) && pastPayments.map((payment, index)=>{
            return (
            <View style={[styles.rowSection, index === pastPayments.length-1 ? styles.lastItem:'']}>
                <View style={AtomicCss.flex1}>
                    <Text numberOfLines={1} style={[AtomicCss.defaultText,AtomicCss.font12,AtomicCss.regularFont,AtomicCss.marginBottom3]}> 
                        <Text style={AtomicCss.boldFont}>
                            {payment.ModeOfPayment} {' '}
                        </Text>
                        {payment.cardno}
                    </Text>
                    <Text style={[AtomicCss.midGreyText,AtomicCss.font10,AtomicCss.regularFont]}>{payment.paymentReceivedDate}</Text>
                </View>
                <Text style={[AtomicCss.defaultText, AtomicCss.font14, AtomicCss.boldFont]}>{payment.amountPaid}{' '}</Text>
            </View>
        )})}
        </View>
    )
};

const styles = StyleSheet.create({
    PayInfoWrapper:{
        backgroundColor:colors.grey7,
        paddingHorizontal:16,
        borderRadius:4,
        marginHorizontal:16,
    },
    rowSection:{
        borderBottomWidth:1,
        borderBottomColor:colors.gainsBoro,
        paddingVertical:10,
        flexDirection:'row',
        justifyContent:'space-between'
    },
    lastItem:{
        borderBottomWidth:0,
        borderBottomColor:'transparent', 
    }
})
export default PayInfoCard