import { StyleSheet } from 'react-native';

export default (theme, fonts) => {
  return StyleSheet.create({
    dealTagWrapper: {
      flexDirection: 'row',
      alignSelf: 'flex-start',
      marginLeft: 15,
      marginTop: -10,
      height: 22,
      paddingRight: 10,
      alignItems: 'center',
    },
    myIconStyle: {
      width: 120,
      height: 27,
    },
    dealText: {
      color: theme.color.blue15,
      fontFamily: fonts.bold,
      ...fonts.font12,
    },
  });
};
