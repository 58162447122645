import { StyleSheet } from "react-native";

export default (theme, fonts) =>StyleSheet.create({
    iconWrapper: {
        width: 26,
        height: 26,
        marginRight: 10,
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    offerIconStyle: {
        width: 24,
        height: 24,
        alignSelf: 'center'
    },

})

