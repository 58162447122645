import { StyleSheet } from 'react-native';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
import { DEFAULT_THEME } from 'apps/post-sales/src/theme/default.theme';

const getStyles = <Theme extends typeof DEFAULT_THEME> (theme: Theme, fonts: any)=> StyleSheet.create({
  cardBorder: {
    borderTopColor: theme.color.lightGrey3,
    borderBottomColor: theme.color.lightGrey3,
    borderTopWidth: 1,
    borderBottomWidth: 1,
  },
  infoCardWrapper: {
    backgroundColor: theme.color.grey22,
    justifyContent: 'space-between',
    ...getPlatformElevation(2),
    marginBottom: 1,
    paddingBottom: 10,

  },
  headingSection: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 15,
    paddingHorizontal: 16,
  },
  headingText: {
    lineHeight: 20,
    ...fonts.blackFontFamily,
    fontSize: fonts.fontSize.font16,
  },
  infoSection: {
    flexDirection: 'row',
    padding: 10,
    marginLeft: 50,
    marginRight: 15,
    marginBottom: 15,
    backgroundColor: theme.color.grayBg,
    borderRadius: 4,

  },
  infoSectionTxt: {
    ...fonts.boldFontFamily,
    fontSize: 14,
    flex: 1,
    marginBottom: 6,
  },
  infSectionTxtBold: {
    ...fonts.boldFontFamily,
  },
  insuranceIconStyle: {
    width: 16,
    height: 16,
    marginRight: 10,
    tintColor: theme.color.orange5,
  },
  companyIconStyle: {
    width: 16,
    height: 16,
    marginRight: 10,
  },
  innerDtlsWrapper: {
    paddingHorizontal: 15,
    marginLeft: 40,
  },
  itemRow: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 2,
  },
  itemDetail:{
    paddingVertical:10,
  },
  rowLeftText: {
    color: theme.color.black,
    ...fonts.boldFontFamily,
    fontSize: fonts.fontSize.font14,
  },
  title: {
    color: theme.color.textGrey,
    ...fonts.regularFontFamily,
    fontSize: fonts.fontSize.font14,
  },
});


export default getStyles;