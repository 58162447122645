import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
import { StyleSheet } from 'react-native';

const getStyle = (theme, fonts) => {
  const {color} = theme;
  return StyleSheet.create({
    cardWrapper: {
      backgroundColor: color.white,
      elevation: 3,
      ...getPlatformElevation(2),
      marginBottom: 16,
      borderRadius: 8,
      borderWidth: 1,
      borderColor: color.greyBookedSeat,
    },
    lastItemStyle: {
      marginBottom: 90,
    },
    checkAllWrapper: {
      backgroundColor: colors.gr,
      //////Doubts
      borderRadius: 8,
    },
    checkBtn: {
      flexDirection: 'row',
      paddingVertical: 10,
      paddingHorizontal: 15,
      alignItems: 'center',
    },
    checkAllBtn: {
      alignItems: 'flex-start',
    },
    checkbox: {
      backgroundColor: color.white,
      overflow: 'hidden',
      position: 'relative',
      marginRight: 15,
    },
    checkboxSelected: {
      borderColor: color.transparent,
      borderWidth: 0,
    },
    checkboxAll: {
      marginTop: 6,
    },
    checkedIcon: {
      width: 19,
      height: 19,
      position: 'absolute',
      left: 0,
      top: -1,
      borderRadius: 3,
    },
    destRow: {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 2,
    },
    cityText: {
      ...fonts.boldFontFamily,
      color: color.black,
      lineHeight: 24,
    },
    arrowIcon: {
      width: 10,
      height: 7,
      marginHorizontal: 5,
    },
    smallText: {
      ...fonts.regularFontFamily,
      color: color.defaultTextColor,
      marginBottom: 0,
      lineHeight: 16,
    },
    passengerName: {
      ...fonts.boldFontFamily,
      fontSize: fonts.fontSize.font14,
      color: color.black,
    },
    passengersList: {
      paddingHorizontal: 8,
    },
    redInfoIcon: {
      width: 16,
      height: 16,
      marginRight: 10,
    },
    redItalicText: {
      ...fonts.regularFontFamily,
      color: color.red,
      fontSize: fonts.fontSize.font12,
      fontStyle: 'italic',
    },
    redInfoWrappe: {
      padding: 16,
      paddingTop: 6,
      flexDirection: 'row',
    },
    checkAllWrapperInner: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    ViewPolicyTxt: {
      ...fonts.boldFontFamily,
      fontSize: fonts.fontSize.font14,
      color: color.azure,
      paddingHorizontal: 20,
      marginTop: 30,
    },
    SelectNameRow: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      flex: 1,
    },
    paxListWrapper: {
      backgroundColor: color.lightGrey6,
      borderRadius: 4,
      paddingLeft: 40,
      paddingBottom: 10,
      paddingTop: 10,
      marginBottom: 8,
    },
    sectorPaxList: {
      paddingVertical: 19,
      paddingHorizontal: 35,
    },
    isMoreInfoWrapper: {
      borderBottomColor: color.transparent,
      borderBottomWidth: 0,
    },
    passengerRfundTxt: {
      ...fonts.regularFontFamily,
      fontSize: fonts.fontSize.font12,
      color: color.greyLight,
      marginTop: 5,
    },
    paxslctionHeader: {
      flex: 1,
      justifyContent: 'space-between',
    },
    sectorHeader: {
      flexDirection: 'row',
      flex: 1,
      justifyContent: 'space-between',
    },
    sectorHeaderOpacity: {
      opacity: 0.5,
    },
    airlinesIconWrapper: {
      width: 33,
      height: 33,
      alignSelf: 'center',
    },
    airlineIcon: {
      width: 32,
      height: 32,
    },
    paxDisabled: {
      backgroundColor: color.grey7,
      opacity: 0.7,
    },
    margin_bottom10: {
      marginBottom: 10,
    },
    passengerCancelled: {
      ...fonts.boldFontFamily,
      fontSize: fonts.fontSize.font14,
      color: color.black,
      textDecorationLine: 'line-through',
    },
    boxDisabled: {
      backgroundColor: color.grey7,
      opacity: 0.7,
    },
    cancelledTxt: {
      ...fonts.regularFontFamily,
      color: color.red,
      fontSize: fonts.fontSize.font12,
    },
    disableChkBox: {
      backgroundColor: color.greyBookedSeat,
      borderColor: color.gray3,
    },
    disableTxt: {
      ...fonts.regularFontFamily,
      color: color.greyLight,
      fontSize: fonts.fontSize.font14,
    },
    errorInfoTxt: {
      fontSize: fonts.fontSize.font14,
      color: color.red6,
    },
    infoTxtColor: {
      ...fonts.regularFontFamily,
      color: color.lightYello,
      fontSize: fonts.fontSize.font12,
    },
    collapsedSection: {
      flexDirection: 'row',
      flex: 1,
      borderRadius: 4,
      paddingLeft: 40,
      paddingVertical: 15,
      backgroundColor: color.lightGrey6,
      marginBottom: 8,
    },
    textBlue: {
      color: color.azure,
    },
    txtInfo: {
      ...fonts.regularFontFamily,
      color: color.textGrey,
      fontSize: fonts.fontSize.font12,
    },
    moreInfoWrapper: {
      backgroundColor: color.grayBg,
      borderRadius: 4,
      padding: 16,
      margin: 10,
    },
    infoIcon: {
      width: 20,
      height: 20,
      marginRight: 8,
      tintColor: color.lightYello,
    },
    seperatorWrapper: {
      paddingHorizontal: 20,
      overflow: 'hidden',
      marginBottom: 4,
    },
    lineHeight22: {
      lineHeight: 22,
    },
    refundInfoCard: {
      flexDirection: 'row',
      flex: 1,
      borderRadius: 4,
      padding: 12,
      marginBottom: 8,
    },
    refundInfoCardRequested: {
      backgroundColor: color.creamWhite,
    },
    refundInfoCardCancelled: {
      backgroundColor: color.grayBg,
    },
    refundInfoTextRequested: {
      ...fonts.boldFontFamily,
      color: color.lightYello,
      fontSize: fonts.fontSize.font13,
    },
    refundInfoTextCancelled: {
      ...fonts.boldFontFamily,
      color: color.greyLight,
      fontSize: fonts.fontSize.font13,
    },
  });
}
export default getStyle;


