import React from 'react';
import { Text, View, Image, TouchableOpacity } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss'
import styles from './pickDropProcessCardCss';
import Button from '../../../../Common/Button';
import {isEmpty} from 'lodash';
import BasePage from '../../../../Common/PostSalesBasePage';
import ZoomCarConstant from "../../../utils/ZoomCarConstants";
import {CAR_IMG,LOCK_IMG, LOCATION_IMG, INFO_ICON_1} from '../../../SelfDriveImageConstant';
let galleryImgPickUpList = []
let galleryImgDropOffList = []

class pickDropProcess extends BasePage {

  constructor(props) {
    super(props);
    this.state = {
      activeState: ZoomCarConstant.PICKUP_OVERLAY,
      galleryImg: 3,
      ctaEnabled: this.props.response.pickupProcess.filledCheckListCTAEnabled,
      ctaLabel: this.props.response.pickupProcess.ctaLabel,
      galleryImgList:[],
    }
    this.handleTab = this.handleTab.bind(this);
  }

  handleTab(active, ctaLabel, ctaEnabled, list) {
    this.setState({
      activeState: active,
      ctaLabel: ctaLabel,
      ctaEnabled: ctaEnabled,
      galleryImgList: list
    })
  }

    pushInstruction = function (instructions) {
      console.log('instructions ===>' + JSON.stringify(instructions));
        const views = [];
        instructions && instructions.map((instruction) => (
            views.push(<View style={[AtomicCss.flexRow, AtomicCss.marginBottom5, AtomicCss.marginTop5]}>
                <View style={styles.bulletCircle} />
                <Text style={[styles.infoCardDisc]}>
                    {instruction}
                </Text>
            </View>)
        ))
        return views;
    }

  render() {
    const activePickupState = this.state.activeState === ZoomCarConstant.PICKUP_OVERLAY ? "activeStyle" : '';
    let activeDropupState = this.state.activeState === ZoomCarConstant.DROP_OVERLAY ? "activeStyle" : '';
    let locations,carStatus,unlocks;
    if(this.state.activeState === ZoomCarConstant.PICKUP_OVERLAY){
       locations = this.props.response.pickupProcess && this.props.response.pickupProcess.details.filter(d => d.iconType ==="location")[0];
       carStatus = this.props.response.pickupProcess && this.props.response.pickupProcess.details.filter(d => d.iconType ==="carStatus")[0];
       unlocks = this.props.response.pickupProcess && this.props.response.pickupProcess.details.filter(d => d.iconType ==="unlock")[0];
    } else {
       locations = this.props.response.dropoffProcess && this.props.response.dropoffProcess.details.filter(d => d.iconType ==="location")[0];
       carStatus = this.props.response.dropoffProcess && this.props.response.dropoffProcess.details.filter(d => d.iconType ==="carStatus")[0];
       unlocks = this.props.response.dropoffProcess && this.props.response.dropoffProcess.details.filter(d => d.iconType ==="unlock")[0];
    }

    return (
      <View style={[styles.whiteCard, AtomicCss.marginBottom12, styles.cardBorder]}>

          <View style={[styles.headingStyle]}>
              <Image style={styles.infoHeadingIconStyle} source={INFO_ICON_1}/>
              <View>
                  <Text
                      style={[AtomicCss.defaultText, AtomicCss.blackFont, AtomicCss.font14, AtomicCss.textUppercase]}>How
                      to pick-up and drop-off</Text>
              </View>
          </View>

        <View style={AtomicCss.flexRow}>
          <TouchableOpacity style={[styles.tabsBtn, styles[`tabsBtn${activePickupState}`]]} onPress={() => this.handleTab(ZoomCarConstant.PICKUP_OVERLAY,this.props.response.pickupProcess.ctaLabel, this.props.response.pickupProcess.filledCheckListCTAEnabled, galleryImgPickUpList)}>
            <Text style={[styles.tabsBtnTxt, styles[`tabsBtnTxt${activePickupState}`]]}>{this.props.response.pickupProcess.header}</Text>
          </TouchableOpacity>
          <TouchableOpacity style={[styles.tabsBtn, styles[`tabsBtn${activeDropupState}`]]} onPress={() => this.handleTab(ZoomCarConstant.DROP_OVERLAY, this.props.response.dropoffProcess.ctaLabel, this.props.response.dropoffProcess.filledCheckListCTAEnabled, galleryImgDropOffList)}>
            <Text style={[styles.tabsBtnTxt, styles[`tabsBtnTxt${activeDropupState}`]]}>{this.props.response.dropoffProcess.header}</Text>
          </TouchableOpacity>
        </View>
        <View style={styles.processDtlsWrapper}>
          {!isEmpty(carStatus) &&
          <View style={styles.processRowDtls}>
            <View style={styles.imgHolder}>
              <Image style={styles.iconStyle} source={CAR_IMG} />
            </View>
            <Text style={styles.processTitle}>{carStatus.header}</Text>
            <Text style={styles.processDesc} >{carStatus.subheader}</Text>
              {
                  this.pushInstruction(carStatus.instructions)
              }
          </View>}
          {!isEmpty(locations) && <View style={styles.processRowDtls}>
            <View style={styles.imgHolder}>
              <Image style={styles.iconStyle} source={LOCATION_IMG} />
            </View>
            <Text style={styles.processTitle}>{locations.header}</Text>
            <Text style={styles.processDesc} >{locations.subheader}</Text>
              {
                  this.pushInstruction(locations.instructions)
              }
          </View>}
          { !isEmpty(unlocks) && <View style={[styles.processRowDtls, styles.lastRowStyle]}>
            <View style={styles.imgHolder}>
              <Image style={styles.iconStyle} source={LOCK_IMG} />
            </View>
            <Text style={styles.processTitle}>{unlocks.header}</Text>
            <Text style={[styles.processDesc, AtomicCss.marginBottom8]} >{unlocks.subheader}</Text>
              {
                  this.pushInstruction(unlocks.instructions)
              }
            {this.state.ctaEnabled && <View style={styles.btnWrapper}>
              <Button btnTxt={this.state.ctaLabel}
                btnBg="gradientBtn"
                clickHandler={this.props.handleClick}
                btnClickAction={this.state.activeState}
              />
            </View>}
          </View>}
        </View>

      </View>
    );
  }
}

export default pickDropProcess;
