/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import Actions from '../../navigation/postSalesNavigation';
import { View, Text, Image, Alert, BackHandler } from 'react-native';
import AtomicCss from '../commonStyles/AtomicCss';
import styles from './AllErrorScreenCss';
import CapsuleBtnFill from '../../hotel/details/ActionButton/CapsuleBtnFill';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import ProgressView from '@mmt/legacy-commons/Common/Components/Progress/ProgressView';
import NoInternetView from '@mmt/legacy-commons/Common/Components/Error/NoInternetView';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import SimpleHeader from '@mmt/legacy-commons/Common/Components/Header/SimpleHeader';
import { isNetworkAvailable } from '@mmt/legacy-commons/Common/utils/AppUtils';
import { getCommonHeaders, BUS_CANCELLATION_URL } from '../../utils/NetworkUtils';
import BusBookingTrackingHelper from '../BusBookingTrackingHelper';
import { showShortToast } from '@mmt/legacy-commons/Common/Components/Toast';
import { invalidateTrips } from '@mmt/legacy-commons/Helpers/genericHelper';
import { POST } from '../../PostSalesConstant';
import * as NpsModule from '@mmt/legacy-commons/Native/NpsModule';
import BusBookingDetailsConstant from '../BusBookingDetailsConstant';
import isEmpty from 'lodash/isEmpty';
import { POST_SALES_BUS_CANCELLATION_SUCCESS_PAGE } from '@mmt/legacy-commons/adsConfig/adsConfigConstants';
import { getAdTechCard } from '../../Common/commonUtil';

import { getRatingData, isNotNullAndEmpty } from '../../Common/commonUtil';
import { errorIcon2 } from '../BusImageConstants';
import { getStaticData, label } from '@mmt/post-sales/src/staticData/staticData';

export default class BusBookingCancel extends React.Component {
  static navigationOptions = {
    header: null,
  };
  constructor(props) {
    super(props);
    this.trackingPageName = 'BusBookingCancel';
    this.state = {
      viewState: ViewState.LOADING,
      responseJson: null,
    };
    this.showRatingPrompt = true;
    this.errorMessage = label('bus.somethingWentWrong');
  }
  componentDidMount() {
    this.makeCancelBookingAPI();
    BackHandler.addEventListener('hardwareBackPress', this.clickHandler);
  }

  componentWillUnmount() {
    BackHandler.removeEventListener('hardwareBackPress', this.clickHandler);
  }

  goBack = () => {
    Actions.pop();
  };

  render() {
    return (
      <View style={{ flex: 1 }}>
        {this.state.viewState === ViewState.LOADING && this.renderProgressView()}
        {this.state.viewState === ViewState.NO_INTERNET && this.renderNoNetworkView()}
        {this.state.viewState === ViewState.ERROR && this.renderErrorView()}
        {this.state.viewState === ViewState.SHOW_DETAIL && this.renderSuccessPage()}
      </View>
    );
  }
  clickHandler = () => {
    const params = {};
    params.BOOKING_ID = this.props.bookingID;
    params.MMT_AUTH = this.props.mmtAuth;
    params.LOGGING_TRACKINFO = this.props.loggingTrackInfo;
    params.refresh = true;
    Actions.busBookingDetail({ data: params });
    BusBookingTrackingHelper.trackClickEvent(
      'cancellationCompleted',
      this.props.bookingDetails,
      'backToBookingDetailClicked',
    );
    return true;
  };
  renderProgressView = () => <ProgressView message={label('bus.loading')} />;

  renderErrorView = () => {
    const { errorPageData } = this.state.responseJson || {};
    const { heading, description } = errorPageData || {};
    const { ctaList } = errorPageData || {};
    const { actionText } = ctaList && ctaList.length ? ctaList[0] : {};
    const { errorText, tryAgainText } = getStaticData();
    const { somethingWentWrongTryAgainError } = errorText;

    return (
      <View style={{ flex: 1 }}>
        <SimpleHeader iconPress={this.goBack} />
        <ErrorView
          showRetry
          message={heading || somethingWentWrongTryAgainError }
          errorDesc={description}
          buttonLabel={actionText || tryAgainText}
          onRetry={() => {
            this.clickHandler();
          }}
        />
      </View>
    );
  };

  renderNoNetworkView = () => {
    const { errorText, tryAgainText, backText } = getStaticData();
    const { notConnectedToTheInternetError, checkYourSettingsAndTryAgainError } = errorText;

    return (
      <View style={{ flex: 1 }}>
        <SimpleHeader title={backText} iconPress={this.goBack} />
        <NoInternetView
          heading={notConnectedToTheInternetError}
          subHeading={checkYourSettingsAndTryAgainError}
          buttonText={tryAgainText}
          onRetry={() => {
            this.setState({ viewState: ViewState.LOADING });
            this.makeCancelBookingAPI();
          }}
        />
      </View>
    );
  }
  renderSuccessPage = () => {
    let cancelledCount = 0;
    if (this.props.cancelledPaxLineId) {
      cancelledCount = this.props.cancelledPaxLineId.length;
    }
    BusBookingTrackingHelper.trackLoadEvent('cancellationCompleted', this.props.bookingDetails);
    const { totalPaxCount } = this.props;
    const titleMessage = this.props.isFullCancellation
      ? `${label('bus.cancellation.successfullyCancelledMsg')} `
      : label('bus.cancellation.partiallyCancelledMsg1', null, {
          cancelledCount,
          totalPaxCount,
          traveller:
            totalPaxCount > 1
              ? label('bus.travellers', { lowercase: true })
              : label('bus.traveller', { lowercase: true }),
        });

    const fullCancelSubTitle = `${label('bus.cancellation.bookingCancelled')} ${
      this.props.refundAmount > 0
        ? label('bus.cancellation.refundUnderProcess')
        : label('bus.cancellation.noRefundApplicable')
    }`;
    let partialCancelSubTitle = `${label('bus.cancellation.bookingCancelled')} ${
      this.props.refundAmount > 0
        ? label('bus.cancellation.refundUnderProcess')
        : label('bus.cancellation.noRefundApplicable')
    }`;
    const remainingPaxCount = this.props.totalPaxCount - cancelledCount;
    if (remainingPaxCount > 0) {
      partialCancelSubTitle =
        remainingPaxCount > 1
          ? label('bus.cancellation.partiallyCancelledWithPaxCount', null, { remainingPaxCount })
          : label('bus.cancellation.partiallyCancelledWithPaxCount1', null, { remainingPaxCount });
    }

    const { ratingData, npsData } = getRatingData(this.trackingPageName, this.state.responseJson);
    if (ratingData && this.showRatingPrompt) {
      NpsModule.showRatingPrompt(ratingData, npsData);
      this.showRatingPrompt = false;
    }

    return (
      <View style={styles.FullPageErrorPage}>
        <Image style={[styles.ErrorImg1, AtomicCss.marginBottom20]} source={errorIcon2} />
        <Text style={[styles.ErrorHeading, AtomicCss.marginBottom20]}>{titleMessage}</Text>
        <Text style={[styles.ErrorDisc, styles.lastDisc]}>
          {this.props.isFullCancellation ? fullCancelSubTitle : partialCancelSubTitle}
        </Text>
        <CapsuleBtnFill
          action={{ actionLabeltext: ` ${label('bus.cancellation.viewUpdatedBooking')} ` }}
          clickHandler={this.clickHandler}
        />
        <View style={styles.adTechCardView}>{this.renderAdTechCard()}</View>
      </View>
    );
  };
  renderAdTechCard = () => {
    return getAdTechCard(POST_SALES_BUS_CANCELLATION_SUCCESS_PAGE);
  };
  requestBody = () => {
    const requestObject = {
      refundOption: this.props.selectedRefundMode,
      bookingID: this.props.bookingID,
      preview: false,
      lineNumbers: this.props.cancelledPaxLineId,
      instantRefundData: this.props.instantData,
    };
    return requestObject;
  };
  getTicketList = () => {
    let ticketList = [];

    this.props.msrTicketList &&
      this.props.msrTicketList.map((ticket, index) => {
        if (
          ticket.primaryType === BusBookingDetailsConstant.TICKET_PRIMARY_TYPE &&
          ticket.secondaryType === BusBookingDetailsConstant.TICKET_SECONDARY_TYPE &&
          ticket.ternaryType === BusBookingDetailsConstant.TICKET_TERNARY_TYPE
        ) {
          ticketList.push(ticket.id);
        }
      });

    return ticketList;
  };

  async makeCancelBookingAPI() {
    try {
      const hasNetwork = await isNetworkAvailable();
      if (!hasNetwork) {
        Alert.alert(label('bus.checkInternetConnnection'));
        return;
      }
      const commonHeaders = await getCommonHeaders(this.props.mmtAuth, this.props.loggingTrackInfo);

      const request = {
        bookingId: this.props.bookingID,
        preview: false,
        refundOption: this.props.selectedRefundMode,
        lineNumbers: this.props.cancelledPaxLineId,
        myDeskTickets: this.getTicketList(),
        instantRefundData: this.props.instantData,
        reason: this.props.reason,
        refundAmount: this.props.refundAmount,
        authorizationToken: this.props.authorizationToken,
      };

      const response = await fetch(BUS_CANCELLATION_URL, {
        method: POST,
        body: JSON.stringify(request),
        headers: commonHeaders,
      });
      if (response.ok) {
        const body = await response.json();
        this.cancellationResponse = body;
        this.errorText = this.cancellationResponse.errorText;
        if (this.cancellationResponse.cancellationDone) {
          this.setState({
            viewState: ViewState.SHOW_DETAIL,
            responseJson: this.cancellationResponse,
          });
          invalidateTrips();
          BusBookingTrackingHelper.trackLoadEvent(
            'Cancellation: Cancellation confirmation_final',
            this.props.response,
            'Confirm Cancel Final',
          );
        } else {
          BusBookingTrackingHelper.trackErrorEvent('Bus_Cancellation_Confirm_Final');
          this.setState({ viewState: ViewState.ERROR, responseJson: this.cancellationResponse });
        }
      } else {
        const body = await response.json();
        BusBookingTrackingHelper.trackErrorEvent('Bus_Cancellation_Confirm_Final');
        if (body && !isEmpty(body.errorText)) {
          showShortToast(body.errorText);
        } else {
          showShortToast(label('bus.somethingWentWrong'));
        }
        Actions.pop();
      }
    } catch (error) {
      BusBookingTrackingHelper.trackErrorEvent('Bus_Cancellation_Confirm_Final');
      showShortToast(label('bus.somethingWentWrong'));
      Actions.pop();
    }
  }

  trackError = () => {
    BusBookingTrackingHelper.trackErrorEvent('cancellationCompletedError');
  };
}

BusBookingCancel.propTypes = {
  isFullCancellation: PropTypes.bool.isRequired,
  totalPaxCount: PropTypes.number.isRequired,
  cancelledPaxLineId: PropTypes.array.isRequired,
  mmtAuth: PropTypes.string.isRequired,
  loggingTrackInfo: PropTypes.string.isRequired,
  bookingID: PropTypes.string.isRequired,
  bookingDetails: PropTypes.object.isRequired,
  refundAmount: PropTypes.number.isRequired,
  selectedRefundMode: PropTypes.string,
  instantData: PropTypes.object,
};

BusBookingCancel.defaultProps = {
  selectedRefundMode: 'RefundToCustomer',
  instantData: null,
};
