import React from 'react';
import PropTypes from 'prop-types';
import {View, Text, StyleSheet} from 'react-native';
import AtomicCss from '../../commonStyles/AtomicCss';
import { label } from '@mmt/post-sales/src/staticData/staticData';
import { getFont } from '../../../PostSalesStyles';

const tickUnicode = '\u2713';

class ProcessSteps extends React.Component {
    static navigationOptions = {
        header: null
    };

    render() {
        const fonts = getFont(true);

        return (
            <View style={styles.GuestInfoStrip}>
                <View style={[AtomicCss.flexRow]}>
                    <Text style={[styles.RowCount, this.activeStyleForCount(1), fonts.font11]}>{this.props.currentIndex > 1 ? tickUnicode : '1'}
                    </Text>
                    <Text style={[fonts.boldFontFamily, AtomicCss.lightTextColor,
                        fonts.font11,
                        (this.props.currentIndex >= 1 ? styles.ActiveText : {})]}
                    >{label('bus.cancellation.select')}
                    </Text>
                </View>
                <View style={AtomicCss.flexRow}>
                    <Text style={[styles.RowCount, this.activeStyleForCount(2), fonts.font11]}>{this.props.currentIndex > 2 ? tickUnicode : '2'}
                    </Text>
                    <Text style={[fonts.boldFontFamily, AtomicCss.lightTextColor,
                        fonts.font11, (this.props.currentIndex >= 2 ? styles.ActiveText : {})]}
                    >{label('bus.cancellation.review')}
                    </Text>
                </View>
                <View style={AtomicCss.flexRow}>
                    <Text style={[styles.RowCount, this.activeStyleForCount(3), fonts.font11]}>{this.props.currentIndex > 3 ? tickUnicode : '3'}
                    </Text>
                    <Text style={[fonts.boldFontFamily, AtomicCss.lightTextColor,
                        fonts.font11, (this.props.currentIndex >= 3 ? styles.ActiveText : {})]}
                    >{label('bus.cancellation.confirm')}
                    </Text>
                </View>
            </View>
        );
    }

    activeStyleForCount = (currentIndex) => {
        let style = {};
        if (this.props.currentIndex === currentIndex) {
            style = styles.RowCountActive;
        } else if (this.props.currentIndex > currentIndex) {
            style = styles.tick;
        }
        return style;
    }
}

ProcessSteps.propTypes = {
    currentIndex: PropTypes.number.isRequired
};

const styles = StyleSheet.create({
    GuestInfoStrip: {
        backgroundColor: '#fff',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingBottom: 10,
        paddingTop: 10,
        paddingLeft: 15,
        paddingRight: 15,
        marginBottom: 10,
        alignItems: 'center',
        elevation: 2
    },
    RowCount: {
        backgroundColor: '#9b9b9b',
        width: 14,
        height: 14,
        borderRadius: 7,
        marginRight: 4,
        color: '#fff',
        fontSize: 11,
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        overflow: 'hidden'
    },
    RowCountActive: {backgroundColor: '#000'},
    ActiveText: {color: '#000'},
    tick: {
        color: '#93d51f',
        backgroundColor: '#000'
    }
});

export default ProcessSteps;
