import React from 'react';
import PropTypes from 'prop-types';
import {View, Text, Image, TouchableOpacity} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import styles from './MetroCss';
import {isNotNullAndEmpty, handleClickEvent, getActionButton} from '../MetroBookingDetailUtil';
import {WHITE_ARROW_ICON} from '../MetroImageConstant';

class RefundStatusCard extends React.Component {
  render() {
    return (
      <LinearGradient start={{x: 1.0, y: 0.0}} end={{x: 0.0, y: 1.0}} colors={this.props.color} style={[styles.topStatusCard]} >
        <View style={[styles.topStatusBar, styles.statusCardOuter]}>
          <TouchableOpacity style={styles.backIconPress} onPress={this.props.onBackPress}>
            <Image style={[styles.mar10Bt, styles.backarrow]} source={WHITE_ARROW_ICON} />
          </TouchableOpacity>
          <Image style={[styles.mar3Bt, styles.cabConfirmIcon]} source={this.props.iconPath} />
        </View>
        <View style={styles.StatusCardWrapper}>
          <Text style={[styles.WhiteText, styles.AlignCenter, styles.font18, styles.mar20Bt, styles.blackFont]}>
            {this.props.headText}
          </Text>
          <Text style={[styles.WhiteText, styles.AlignCenter, styles.mar20Bt, styles.font14, styles.regularFont, isNotNullAndEmpty(this.props.subText) ? styles.showView : styles.hideView]}>
            {this.props.subText}
          </Text>
          <View style={isNotNullAndEmpty(this.props.subTextDetails) ? styles.showView : styles.hideView}>
            <Text style={[styles.WhiteText, styles.AlignCenter, styles.mar20Bt, styles.font14]}>
              {this.props.subTextDetails}
            </Text>
          </View>
          <View style={isNotNullAndEmpty(this.props.subTextHeader) ? styles.showView : styles.hideView}>
            <Text style={[styles.WhiteText, styles.AlignCenter, styles.font12, styles.lightFont, styles.mar3Bt, styles.regularFont]}>
                    BOOKING ID
              <Text style={styles.boldFont}>&nbsp; {this.props.response.bookingId}</Text>
            </Text>
          </View>
          <View style={isNotNullAndEmpty(this.props.card.actionList) ? styles.whiteBtnHalfOuter : styles.hideView}>
            {getActionButton(this.props.card, this.props.response, this.handleClick)}
          </View>
        </View>
      </LinearGradient>
    );
  }
    handleClick = (action) => {
      handleClickEvent(this.props.card, action, this.props.response, this.props.pageName);
    };
}
RefundStatusCard.propTypes = {
  response: PropTypes.object.isRequired,
  iconPath: PropTypes.number.isRequired,
  headText: PropTypes.string.isRequired,
  color: PropTypes.array.isRequired,
  subText: PropTypes.string,
  subTextHeader: PropTypes.string,
  subTextDetails: PropTypes.string,
  card: PropTypes.object.isRequired,
  pageName: PropTypes.string.isRequired
};
RefundStatusCard.defaultProps = {
  subTextDetails: '',
  subText: '',
  subTextHeader: ''
};
export default RefundStatusCard;
