import Fecha from 'fecha';
import RailBookingDetailConstant, { RAIL_TRACKING_CONSTANT } from './RailBookingDetailConstant';
import React from 'react';
import { Alert, Platform, Text, View, DeviceEventEmitter } from 'react-native';
import RailBookingModule from '@mmt/legacy-commons/Native/RailBookingModule';
import RailBookingTrackEventHelper from './RailBookingTrackEventHelper';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import Actions from '../../navigation/postSalesNavigation';
import {
  setDataInStorage
} from '../../utils/localstorage';
import styles from '../styles/RailsMamiCss';
import { convertUTCDateToLocalDate } from '@mmt/legacy-commons/Common/utils/DateUtils';
import COMMON_CONSTANT from '../../Common/commonConstant';
import { getRootTag } from '@mmt/legacy-commons/AppState/RootTagHolder';
import _, { flatten, get, isEmpty } from 'lodash';
import {
  MALE_IMAGE,
  FEMALE_IMAGE,
  INFANT_IMAGE,
  TOP_STATUS_ICON_1,
  TOP_STATUS_ICON_9,
  TOP_STATUS_ICON_4,
  TOP_STATUS_ICON_8,
  ONTRIP_IMAGE,
  IC_FAILED_IMAGE,
  READY_TO_BOARD_IMAGE,
} from '../Utils/RailImageConstant';
import { openDialer } from '../../Common/commonUtil';
import { getStaticData } from '../../staticData/staticData';
import { downloadEticketVoucher, proceedDownload } from '../../utils/PostSaleUtil';
import { getImgSource, rendorForEachElement } from '../../utils/CancellationPolicyCardUtils';
import { BOOKING_STATUS, BOOKING_TYPE, RAIL_ANCILLARY, TGS_ACTIONS } from './constants';
import { CANCELLATION_REQUEST_TYPE, CLAIM_REQUEST_TYPE, DONWLOAD_FILE_EXT, EMPTY_JSON_STRING, PAYMENT_REPONSE } from '../../PostSalesConstant';
import { getSegmentInfo } from './data/helper';
import RailBookingTrackingHelper from "./RailBookingTrackEventHelper";
import { RAIL_FETCH_INVOCE_PDF } from '../../utils/NetworkUtils';

export const currentBookingStatus = (passenger) => {
  let bookingStatus = '';
  let statusColor = '#00a19c';
  if (passenger.currentStatus) {
    switch (passenger.currentStatus) {
      case 'TDR':
        bookingStatus = 'TDR Requested';
        statusColor = '#4c8df5';
        break;
      case 'CAN':
        bookingStatus = 'Cancelled';
        statusColor = '#9b9b9b';
        break;
      case String(passenger.currentStatus.match(/.*WL.*/)):
        bookingStatus = 'Waitlisted';
        statusColor = '#eb2026';
        break;
      case String(passenger.currentStatus.match(/.*RAC.*/)):
        bookingStatus = 'RAC';
        statusColor = '#cf8100';
        break;
      case '':
        bookingStatus = 'Failed';
        statusColor = '#9b9b9b';
        break;
      case 'CNF':
        bookingStatus = 'Confirmed';
        break;
      default:
        bookingStatus = 'Confirmed';
    }
  }
  return {
    bookingStatus,
    statusColor,
  };
};
export const passengerImage = (passenger) => {
  let paxImage = MALE_IMAGE;
  if (passenger && passenger.paxType) {
    switch (passenger.paxType) {
      case 'Adult Female':
      case 'Sr. Citizen Female':
        paxImage = FEMALE_IMAGE;
        break;
      case 'Child':
        if (passenger.paxGender === 'F') {
          paxImage = FEMALE_IMAGE;
        }
        break;
      case 'Infant':
        paxImage = INFANT_IMAGE;
        break;
      case 'Adult Male':
      case 'Sr. Citizen Male':
      default:
        break;
    }
  }
  return paxImage;
};

export const trainFromToSubTitleWithDate = (trainDetails) => {
  let subTitleString = '';
  if (
    trainDetails &&
    trainDetails[0] &&
    trainDetails[0].pnrDetails &&
    trainDetails[0].pnrDetails[0] &&
    trainDetails[0].pnrDetails[0].segmentInfo &&
    trainDetails[0].pnrDetails[0].segmentInfo[0]
  ) {
    const segmentInfo = trainDetails[0].pnrDetails[0].segmentInfo[0];
    if (segmentInfo.boardingPointName && segmentInfo.destinationStationName) {
      subTitleString = `${segmentInfo.fromStationCode} - ${segmentInfo.destinationStationCode}`;
    }
    if (segmentInfo.jouneyDate) {
      const jouneyDate = Fecha.format(Fecha.parse(segmentInfo.jouneyDate, 'YYYY-MM-DD'), 'MMM D');
      if (jouneyDate) {
        subTitleString = `${subTitleString} • ${jouneyDate}`;
      }
    }
  }
  return subTitleString;
};

export const cancellablePaxList = (bookingDetails) => {
  if (
    bookingDetails &&
    bookingDetails.train &&
    bookingDetails.train[0] &&
    bookingDetails.train[0].pnrDetails &&
    bookingDetails.train[0].pnrDetails[0] &&
    bookingDetails.train[0].pnrDetails[0].segmentInfo &&
    bookingDetails.train[0].pnrDetails[0].segmentInfo[0] &&
    bookingDetails.train[0].pnrDetails[0].segmentInfo[0].passenger
  ) {
    const paxList = bookingDetails.train[0].pnrDetails[0].segmentInfo[0].passenger;
    return paxList.filter((paxDetails) => paxDetails.cancelled === false);
  }
  return null;
};

export const allPaxList = (bookingDetails) => {
  if (
    bookingDetails &&
    bookingDetails.train &&
    bookingDetails.train[0] &&
    bookingDetails.train[0].pnrDetails &&
    bookingDetails.train[0].pnrDetails[0] &&
    bookingDetails.train[0].pnrDetails[0].segmentInfo &&
    bookingDetails.train[0].pnrDetails[0].segmentInfo[0] &&
    bookingDetails.train[0].pnrDetails[0].segmentInfo[0].passenger
  ) {
    const paxList = bookingDetails.train[0].pnrDetails[0].segmentInfo[0].passenger;
    return paxList;
  }
  return null;
};

export const goToRailBookingDetails = (bookingId) => {
  Actions.railBookingDetail({
    data:{
      BOOKING_ID: bookingId,
      refresh: true
    }
  });
}

export const resetToRailBookingDetails = (bookingId) => {
  Actions.reset('railBookingDetail',{
    data:{
      BOOKING_ID: bookingId,
      refresh: true
    }
  });
}

export const getCancelledPaxLineIds = (bookingDetails) => {
  const paxLineId = [];

  const paxList = allPaxList(bookingDetails);
  if (paxList) {
      paxList.forEach((paxDetails) => {
          if (paxDetails.ticketLineNo) {
          paxLineId.push(paxDetails.ticketLineNo);
          }
      });
  }
  return paxLineId;
}

export const totalPaxCount = (bookingDetails) => {
  let totalPaxCount = 0;
  const paxList = allPaxList(bookingDetails);
  if (paxList) {
    totalPaxCount = paxList.length;
  }
  return totalPaxCount;
}


export const popToRailBookingDetails = () => {
  Actions.popTo('railBookingDetail');
}


export const activePaxCount = (bookingDetails) => {
  let activePaxCount = 0;
  const paxList = allPaxList(bookingDetails);
  if (paxList) {
    activePaxCount = paxList.reduce((prevCount, currentPax) => {
      if (currentPax.currentStatus !== 'CAN') {
        prevCount += 1;
      }
      return prevCount;
    }, activePaxCount);
  }
  return activePaxCount;
};

export const findDaysBetweenDates = (firstDate, secondDate) => {
  const oneDay = 24 * 60 * 60 * 1000;
  const secondDateObj = Fecha.parse(secondDate.split('T')[0], 'YYYY-MM-DD');
  return Math.round(Math.abs((secondDateObj.getTime() - firstDate) / oneDay));
};

export const getImageIconForTopStatusCard = (card) => {
  switch (card.cardId) {
    case RailBookingDetailConstant.TOP_STATUS_CARD_CAN_INIT_TO_REFUND_DONE:
    case RailBookingDetailConstant.TOP_STATUS_CARD_AFTER_REFUND_DONE:
    case RailBookingDetailConstant.TOP_STATUS_CARD_PARTIALY_CAN_AT_REST_ALL_COMPLETED:
    case RailBookingDetailConstant.TOP_STATUS_CARD_DEFAULT_CANCELLED:
      return TOP_STATUS_ICON_8;
    case RailBookingDetailConstant.TOP_STATUS_CARD_DEFAULT_CONFIRMED:
    case RailBookingDetailConstant.TOP_STATUS_CARD_PARTIALY_CAN_AT_LEAST_1_UPCOMING:
      return TOP_STATUS_ICON_1;
    case RailBookingDetailConstant.TOP_STATUS_CARD_LOST_FAILED_BOOKING:
      return IC_FAILED_IMAGE;
    case RailBookingDetailConstant.TOP_STATUS_CARD_ON_TRIP:
      return ONTRIP_IMAGE;
    case RailBookingDetailConstant.TOP_STATUS_CARD_24_HRS_TO_ON_TRIP:
      return READY_TO_BOARD_IMAGE;
    case RailBookingDetailConstant.TOP_STATUS_CARD_DEFAULT_COMPLETED:
      return TOP_STATUS_ICON_9;
    default:
      return TOP_STATUS_ICON_8;
  }
};

export const getThrottleParams = (action) => {
  switch( action.actionFamily ){
    case RailBookingDetailConstant.ACTION_DOWNLOAD_TICKET:
    case RailBookingDetailConstant.ACTION_DOWNLOAD_INVOICE:
      return {
        isThrottleRequired: true,
        throttleInterval: 2000
      }
    default:
      return {
        isThrottleRequired: false,
        throttleInterval: 0
      }
  }
}

export const getseatinfoText = (bookingDetails) => {
  const segmentInfo = bookingDetails.train[0].pnrDetails[0].segmentInfo[0];
  const passengersList = segmentInfo.passenger;

  let confirmCount = 0;
  let failedCount = 0;
  let cancelCount = 0;
  let cancelOrTdrCount = 0;
  let totalCount = 0;
  const departureTime = `${segmentInfo.boardingDate}T${segmentInfo.departureTime}`;
  const arrivalTime = `${segmentInfo.destinationArrivalDate}T${segmentInfo.arrivalTime}`;
  let tripType = 'upcoming';

  const currentDate = new Date();
  const departureDate = new Date(departureTime);
  const arrivalDate = new Date(arrivalTime);
  let stateImage = TOP_STATUS_ICON_1;
  if (departureDate.getTime() - currentDate.getTime() > 0) {
    tripType = 'upcoming';
  } else if (currentDate.getTime() - arrivalDate.getTime() > 0) {
    tripType = 'completed';
    stateImage = TOP_STATUS_ICON_9;
  } else if (
    departureDate.getTime() - currentDate.getTime() > 0 &&
    currentDate.getTime() - arrivalDate.getTime() > 0
  ) {
    tripType = 'onBoard';
    stateImage = TOP_STATUS_ICON_4;
  }
  passengersList.forEach((passenger) => {
    totalCount += 1;
    switch (passenger.currentStatus) {
      case '':
        failedCount += 1;
        break;
      case 'CAN':
        cancelCount += 1;
        break;
      case 'TDR':
        cancelOrTdrCount += 1;
        break;
      default:
        confirmCount += 1;
        break;
    }
  });
  if (
    failedCount > 0 ||
    cancelOrTdrCount === totalCount ||
    cancelCount === totalCount ||
    (tripType !== 'completed' && cancelCount !== 0 && cancelCount < totalCount)
  ) {
    stateImage = TOP_STATUS_ICON_8;
  }
  return {
    tripType,
    stateImage,
  };
};

export const createTdrCommitRequest = (bookingDetails, selectedReason, selectedPax) => {
  const request = {};
  const segments = [];
  const segment = {};
  segment.partialPax = selectedReason.partialPossible;
  segment.paxList = selectedPax;
  segment.segmentId = 10000;
  segment.reason = selectedReason.typeOfTdr;
  segment.tdrType = selectedReason.ruleID;
  segment.pnrNo = bookingDetails.train[0].pnrDetails[0].pnrNo;
  segments.push(segment);

  request.bookingId = bookingDetails.bookingID;
  request.segment = segments;
  request.userId = 'MMT/MIMA';
  request.action = 'fileTdr';
  request.mmtAuth = bookingDetails.mmtAuth;

  return request;
};

export function isNumber(data) {
  for (let i = 0; i < data.charCodeAt(i); i++) {
    if (data.charCodeAt(i) < 48 || data.charCodeAt(i) > 57) {
      return false;
    }
  }
  return true;
}

export function isFloat(data) {
  for (let i = 0; i < data.charCodeAt(i); i++) {
    if ((data.charCodeAt(i) < 48 || data.charCodeAt(i) > 57) && data.charCodeAt(i) != 46) {
      return false;
    }
  }
  return true;
}

async function downloadTicketInvoice(triggerName, response, isInvoice=false) {
  if (Platform.OS === 'ios') {
    downloadInvoiceVoucher(response, triggerName, isInvoice);
  } else if (Platform.OS === 'android') {
    handleEventListner(response, triggerName);
    RailBookingModule.checkStoragePermission();
  } else {
    RailBookingModule.downloadTickets(response.bookingID, triggerName);
  }
}

const downloadInvoiceVoucher = async (response, triggerName, isInvoice) => {
  const extension = isInvoice ? DONWLOAD_FILE_EXT.INVOICE : DONWLOAD_FILE_EXT.ETICKET;
  downloadEticketVoucher(
    response.bookingID,
    triggerName,
    RAIL_FETCH_INVOCE_PDF,
    RailBookingDetailConstant.RAIL_DOWNLOAD_VOUCHER,
    response.bookingID + extension,
  );
};

function handleEventListner(response, triggerName) {
  const listener = DeviceEventEmitter.addListener(
    RailBookingDetailConstant.RAIL_STORAGE_PERMISSION,
    (status) => {
      checkPermission(status, response, triggerName);
      listener.remove();
    },
  );
}
const checkPermission = async (status, response, triggerName) => {
  if (status.success) {
    switch (triggerName) {
      case RailBookingDetailConstant.ACTION_TICKET_TRIGGER:
      case RailBookingDetailConstant.ACTION_INVOICE_TRIGGER:
        proceedDownload(
          response,
          triggerName,
          RailBookingDetailConstant.UNIQUE_ID_TYPE,
          RailBookingDetailConstant.DOWNLOAD_FAILED,
        );
        return;
      default:
        break;
    }
  }
};

export async function handleClickEvent(action, response) {
  switch (action.actionFamily) {
    case RailBookingDetailConstant.ACTION_DOWNLOAD_TICKET:
      downloadTicketInvoice(RailBookingDetailConstant.ACTION_TICKET_TRIGGER, response, false);
      break;
    case RailBookingDetailConstant.ACTION_DOWNLOAD_INVOICE:
      downloadTicketInvoice(RailBookingDetailConstant.ACTION_INVOICE_TRIGGER, response, true);
      break;
    case RailBookingDetailConstant.ACTION_CALL_MAKEMYTRIP:
      if (
        response &&
        response.zcplusCardData &&
        !_.isEmpty(response.zcplusCardData.customerCareNumber)
      ) {
        openDialer(response.zcplusCardData.customerCareNumber);
      }
      break;
    default:
      break;
  }
}
export function getButtonStyle(actionList) {
  const { actionType } = actionList[0];
  if (
    actionType === RailBookingDetailConstant.RECTANGLE_PRIMARY_HALF_WIDTH ||
    actionType === RailBookingDetailConstant.RECTANGLE_SECONDARY_HALF_WIDTH ||
    actionType === RailBookingDetailConstant.ROUND_BUTTON
  ) {
    return [styles.ActionBtnHalf, styles.transperantBgrnd];
  } else if (actionType === RailBookingDetailConstant.RECTANGLE_FULL_BUTTON) {
    return [styles.ActionBtnRec, styles.transperantBgrnd];
  }
  return [styles.ActionBtn, styles.transperantBgrnd];
}

export async function handleRailClickEvent(card, action, response, pageName) {
  RailBookingTrackEventHelper.trackClickEvent(pageName, response, action.actionFamily);
  switch (action.actionFamily) {
    case RailBookingDetailConstant.ACTION_RAIL_CALL_US:
    case RailBookingDetailConstant.ACTION_CALL_HELPLINE:
      openDialer(RailBookingDetailConstant.HELPLINE_NUMBER);
      RailBookingTrackingHelper.trackClickEvent('tripDetails', 'HelpLine Call Us');
      break;
    case COMMON_CONSTANT.ACTION_CHAT_WITH_US:
      if (Platform.OS === 'ios') {
        GenericModule.startWriteToUsActivity(getRootTag());
      } else {
        GenericModule.openMyraPage();
      }
      RailBookingTrackingHelper.trackClickEvent('tripDetails', 'ChatWithMyra');
      break;
    case RailBookingDetailConstant.ACTION_RAIL_GOTO_HELP_SECTION:
      GenericModule.openActivity('mmt.intent.action.CUSTOMER_SUPPORT');
      break;
    case RailBookingDetailConstant.ACTION_PNR_STATUS:
      checkPNRStatus(response.shouldShowPnr, response);
      break;
    case RailBookingDetailConstant.ACTION_DOWNLOAD_TICKET:
      downloadTicketInvoice(RailBookingDetailConstant.ACTION_TICKET_TRIGGER, response, false);
      break;
    case RailBookingDetailConstant.ACTION_DOWNLOAD_INVOICE:
      downloadTicketInvoice(RailBookingDetailConstant.ACTION_INVOICE_TRIGGER, response, true);
      break;
    case RailBookingDetailConstant.ACTION_PAYMENT_DETAILS_PAGE:
      Actions.railPriceBreakUp({
        bookingDetailsResponse: response,
        btnClickListener: this.handleRailClickEvent,
      });
      break;
    case RailBookingDetailConstant.LIVE_TRAIN_STATION:
      openLiveTrainStation(response);
      break;
    case RailBookingDetailConstant.RAIL_LIVE_PNR_CHECK:
      openPnrStatus(response);
      break;
    case RailBookingDetailConstant.TRAIN_COACH_POSITION:
      openCoachPosition(response);
      break;
    default:
      break;
  }
}
export async function trainCancelledClickHandler(
  bookingResponse,
  action,
  tdrRules,
  mmtAuth,
  loggingTrackInfo,
  handleViewTdrClick,
) {
  switch (action.actionFamily) {
    case RailBookingDetailConstant.ACTION_CANCEL_FULL_BOOKING:
      onFullCancelTapped(bookingResponse, tdrRules, mmtAuth, loggingTrackInfo);
      break;
    case RailBookingDetailConstant.ACTION_CANCEL_PARTIAL_BOOKING:
      onPartialCancelTapped(bookingResponse, tdrRules, mmtAuth, loggingTrackInfo);
      break;
    case RailBookingDetailConstant.ACTION_TDR_FILE:
      onViewTDRTapped(bookingResponse, tdrRules, mmtAuth, loggingTrackInfo, handleViewTdrClick);
      break;
    case RailBookingDetailConstant.ACTION_TRAIN_CANCELLED:
      onTrainCancelledTapped(bookingResponse, tdrRules, handleViewTdrClick);
      break;
    case RailBookingDetailConstant.RAIL_TDR_PROCEDURE:
      Actions.railTdrProcedure({ bookingDetailsResponse: bookingResponse });
      break;
    default:
      break;
  }
}

export function openLiveTrainStation(response) {
  let url = null;
  if (Platform.OS === 'web') {
    url =
      'https://www.makemytrip.com/railways/railStatus/?q1=' +
      response.train[0].pnrDetails[0].segmentInfo[0].trainNo +
      '&q2=' +
      response.train[0].pnrDetails[0].segmentInfo[0].fromStationCode +
      'q3=' +
      response.train[0].pnrDetails[0].segmentInfo[0].boardingDate;
  } else {
    url = `mmyt://rails/?page=liveStationList&deeplink=true&code=${response.train[0].pnrDetails[0].segmentInfo[0].boardingPoint}`;
  }
  RailBookingTrackEventHelper.trackClickEvent('TripDetails', response, 'liveTrainStationClicked');
  GenericModule.openDeepLink(url);
}
export function openPnrStatus(response) {
  let url = null;
  if (Platform.OS === 'web') {
    url = `https://www.makemytrip.com/railways/pnrsearch/?pnr=' + ${response.train[0].pnrDetails[0].pnrNo}`;
  } else {
    url = `mmyt://rails/?page=pnrStatusPage&deeplink=true&pnr=${response.train[0].pnrDetails[0].pnrNo}`;
  }
  RailBookingTrackEventHelper.trackClickEvent('TripDetails', response, 'checkPnrClicked');
  GenericModule.openDeepLink(url);
}
export function openCoachPosition(response) {
  let url = null;
  if (Platform.OS === 'web') {
    url = `https://www.makemytrip.com/railways/coach-position-details/?trainNum=${response.train[0].pnrDetails[0].segmentInfo[0].trainNo}`;
  } else {
    url = `mmyt://rails/?page=coachPositionList&deeplink=true&trainNumber=${response.train[0].pnrDetails[0].segmentInfo[0].trainNo}`;
  }
  RailBookingTrackEventHelper.trackClickEvent('TripDetails', response, 'checkCoachPositionClicked');
  GenericModule.openDeepLink(url);
}

function checkPNRStatus(shouldShowPNR, response) {
  let url = null;
  if (shouldShowPNR) {
    if (Platform.OS === 'web') {
      url = `https://www.makemytrip.com/railways/pnrsearch/?pnr=' + ${response.train[0].pnrDetails[0].pnrNo}`;
    } else {
      url = `mmyt://app/rails/pnrsearch/?pnr=${response.train[0].pnrDetails[0].pnrNo}`;
    }
    RailBookingTrackEventHelper.trackClickEvent('TripDetails', response, 'checkPnrClicked');
  } else {
    if (Platform.OS === 'web') {
      url =
        'https://www.makemytrip.com/railways/railStatus/?q1=' +
        response.train[0].pnrDetails[0].segmentInfo[0].trainNo +
        '&q2=' +
        response.train[0].pnrDetails[0].segmentInfo[0].fromStationCode +
        'q3=' +
        response.train[0].pnrDetails[0].segmentInfo[0].boardingDate;
    } else {
      url = `mmyt://app/rails/railStatus/?q1=${response.train[0].pnrDetails[0].segmentInfo[0].trainNo}`;
    }
    RailBookingTrackEventHelper.trackClickEvent('TripDetails', response, 'liveTrainStatusClicked');
  }
  GenericModule.openDeepLink(url);
}

export function onFullCancelTapped(response, tdrRules, mmtAuth, loggingTrackInfo) {
  RailBookingTrackingHelper.trackClickEvent('TripDetails', response);
  Actions.reviewRefundCharges({
    bookingDetails: response,
    mmtAuth,
    loggingTrackInfo,
    isFullCancellation: true,
  });
}

export function getTheSelectedData(
  selectedReason,
  index,
  otherInfo,
  cancellationReasonList,
  cancellationReason,
) {
  if (!_.isEmpty(otherInfo)) {
    cancellationReason = `${selectedReason.reason}:${otherInfo}`;
  } else {
    cancellationReason = `${selectedReason.reason}`;
  }

  const newCancellationRules = _.cloneDeep(cancellationReasonList);
  for (let i = 0; i < cancellationReasonList.length; i++) {
    newCancellationRules[i].selected = false;
  }
  newCancellationRules[index].selected = true;
  return { newCancellationRules, cancellationReason };
}

export function onPartialCancelTapped(response, tdrRules, mmtAuth, loggingTrackInfo) {
  onPartialCanRevFn(response, tdrRules, mmtAuth, loggingTrackInfo);
  RailBookingTrackingHelper.trackClickEvent('TripDetails', response);
}

export function onPartialCanRevFn(response, tdrRule, mmtAuth, loggingTrackInfo) {
  const paxList = cancellablePaxList(response);
  if (paxList && paxList.length > 1) {
    Actions.railCancellationSelectPax({
      passengers: paxList,
      bookingDetails: response,
      mmtAuth,
      loggingTrackInfo,
    });
  } else {
    onFullCancelTapped(response, tdrRule, mmtAuth, loggingTrackInfo);
  }
}
export function onViewTDRTapped(response, tdrRules, mmtAuth, loggingTrackInfo, handleViewTdrClick) {
  const staticData = getStaticData();
  const {
    tdrText: {
      onVTTTText: { headingText, additionalText, subheadingText },
    },
    noNotNowText,
    yesProceedText,
  } = staticData;
  let onVTTTitleText,
    onVTTTSubTitleHighLightedText,
    onVTTTSubTitleText,
    onVTTTCancelledButton,
    onVTTTProceedButton;
  if (tdrRules && tdrRules.tdrLayoutResponse && tdrRules.tdrLayoutResponse.onViewTdrTapped) {
    const { titleText, subTitleHighLightedText, subTitleText, cancelledButton, proceedButton } =
      tdrRules.tdrLayoutResponse.onViewTdrTapped || {};
    onVTTTitleText = titleText;
    onVTTTSubTitleHighLightedText = subTitleHighLightedText;
    onVTTTSubTitleText = subTitleText;
    onVTTTCancelledButton = cancelledButton;
    onVTTTProceedButton = proceedButton;
  } else {
    onVTTTitleText = headingText;
    onVTTTSubTitleHighLightedText = additionalText.onVTTTSubTitleHighLightedText;
    onVTTTSubTitleText = subheadingText;
    onVTTTCancelledButton = noNotNowText;
    onVTTTProceedButton = yesProceedText;
  }

  const titleText = onVTTTitleText;
  const subTitleHighLightedText = onVTTTSubTitleHighLightedText;
  const subTitleText = onVTTTSubTitleText;
  handleViewTdrClick(
    railFileTdrFn(response, mmtAuth, loggingTrackInfo),
    onVTTTCancelledButton,
    onVTTTProceedButton,
    titleText,
    subTitleHighLightedText,
    subTitleText,
    true,
  ); // TO DO
  RailBookingTrackingHelper.trackClickEvent('TripDetails', response, 'viewTDRClicked');
}
export function railFileTdrFn(response, mmtAuth, loggingTrackInfo) {
  Actions.railFileTdr({
    bookingDetails: response,
    trainCancelled: false,
    mmtAuth,
    loggingTrackInfo,
  });
}

export const isTdrFiled = (railDetails) => {
  if (railDetails && railDetails.train[0]) {
    const paxDetails = railDetails.train[0].pnrDetails[0].segmentInfo[0].passenger;
    if (paxDetails.filter((x) => x.tdrFiled === true).length > 0) {
      return true;
    }
  }
  return false;
};
export function onTrainCancelledTapped(response, tdrRules, handleViewTdrClick) {
  const staticData = getStaticData();
  const {
    onTrainCancellTappedText: { headingText, additionalText, subheadingText },
  } = staticData;
  let onTCTTitleText,
    onTCTSubTitleHighLightedText,
    onTCTSubTitleText,
    onTCTCancelledButton,
    onTCTProceedButton;
  if (tdrRules.tdrLayoutResponse) {
    onTCTTitleText = tdrRules.tdrLayoutResponse.onTrainCancelledTapped.titleText;
    onTCTSubTitleHighLightedText =
      tdrRules.tdrLayoutResponse.onTrainCancelledTapped.subTitleHighLightedText;
    onTCTSubTitleText = tdrRules.tdrLayoutResponse.onTrainCancelledTapped.subTitleText;
    onTCTCancelledButton = tdrRules.tdrLayoutResponse.onTrainCancelledTapped.cancelledButton;
    onTCTProceedButton = tdrRules.tdrLayoutResponse.onTrainCancelledTapped.proceedButton;
  } else {
    onTCTTitleText = headingText;
    onTCTSubTitleHighLightedText = additionalText.onTCTSubTitleHighLightedText;
    onTCTSubTitleText = subheadingText;
    onTCTCancelledButton = additionalText.onTCTCancelledButton;
    onTCTProceedButton = subheadingText;
  }

  RailBookingTrackingHelper.trackClickEvent('TripDetails', response, 'partCancelClicked');
  const titleText = onTCTTitleText;
  const subTitleHighLightedText = onTCTSubTitleHighLightedText;
  const subTitleText = onTCTSubTitleText;
  handleViewTdrClick(
    onTrainCanRevFn(response, tdrRules),
    onTCTCancelledButton,
    onTCTProceedButton,
    titleText,
    subTitleHighLightedText,
    subTitleText,
  );
}
export function enableFileTDRButtonAction(response) {
  const boardingDateTime = response.train[0].pnrDetails[0].segmentInfo[0].boardingDateTime;
  const tdrDate = new Date(boardingDateTime);
  tdrDate.setHours(tdrDate.getHours() + 72);
  const currentDate = convertUTCDateToLocalDate(new Date());
  return currentDate < tdrDate;
}
export function enableTrainCancelledTDRButtonAction(response) {
  const boardingDateTime = response.train[0].pnrDetails[0].segmentInfo[0].boardingDateTime;
  const rightTdrDate = new Date(boardingDateTime);
  rightTdrDate.setHours(rightTdrDate.getHours() + 24);
  const leftTdrDate = new Date(boardingDateTime);
  leftTdrDate.setHours(leftTdrDate.getHours() - 24);
  const currentDate = convertUTCDateToLocalDate(new Date());
  return currentDate > leftTdrDate && currentDate < rightTdrDate;
}
export function onTrainCanRevFn(response, tdrRules) {
  let selectedReason, tdrAddress;
  if (tdrRules.tdrLayoutResponse) {
    selectedReason = tdrRules.tdrLayoutResponse.onTrainCancelledTapped.selectedReason;
    tdrAddress = tdrRules.tdrLayoutResponse.onTrainCancelledTapped.tdrAddress;
  } else {
    selectedReason = {};
    tdrAddress = 'MMT001';
  }
  Actions.tdrThankYou({
    bookingDetails: response,
    request: createTdrCommitRequestData(response, selectedReason),
    tdrRules,
    selectedReason,
    tdrAddress,
  });

  RailBookingTrackingHelper.trackClickEvent('TripDetails', response, 'viewTDRClicked');
}

export function createTdrCommitRequestData(response, selectedReason) {
  const selectedPax = getPaxList(response);
  return createTdrCommitRequest(response, selectedReason, selectedPax);
}
export function getPaxList(response) {
  const paxList = [];
  const selectedPax = [];
  const passengerList =
    response.train[0] &&
    response.train[0].pnrDetails[0] &&
    response.train[0].pnrDetails[0].segmentInfo[0] &&
    response.train[0].pnrDetails[0].segmentInfo[0].passenger;

  passengerList.forEach((passenger) => {
    if (selectedPax.findIndex((x) => x.paxId == passenger.paxId) === -1) {
      selectedPax.push(passenger);
    }
  });

  selectedPax.forEach((pax) => {
    paxList.push(pax.ticketLineNo);
  });
  return paxList;
}
export function isChartPrepered(response) {
  return response.train[0].pnrDetails[0].chartPreapred;
}
export function handleAlert(title, msg) {
  Alert.alert(title, msg);
}
export function invokeAmountPaidUI(paymentBreakupMap) {
  const views = [];
  if (paymentBreakupMap) {
    for (const componentName in paymentBreakupMap) {
      views.push(
        <View key={componentName} style={[styles.Paymentrow, styles.mar15Bt]}>
          <Text style={styles.PaymentLeft}>- {componentName}</Text>
          <Text>{paymentBreakupMap[componentName]} </Text>
        </View>,
      );
    }
  }
  return views;
}

export function buildPnrRequest(pnrNo, bookingId) {
  return {
    pnrID: pnrNo,
    bookingId,
  };
}

export function prepareSeatText(detailPaxResponsse, pnrPaxResponse) {
  if (!isEmpty(pnrPaxResponse)) {
    let outputText = '';
    pnrPaxResponse.PassengerStatus.forEach((pax) => {
      if (Number(pax.Number) === Number(detailPaxResponsse.paxId)) {
        outputText = pax.seatCompleteText;
      }
    });
    return outputText;
  }
  return detailPaxResponsse.passengerCurrentStatusText;
}

export function preparePnrData(response) {
  return {
    passengerDetails: response.PassengerDetails,
    chartPrepared: response.TrainDetails.chartPrepared,
    chartStatus: response.TrainDetails.chartStatus,
    lastUpdateTime: response.lastUpdatedPnrStatus,
    allowRefresh: response.allowRefresh,
    lastbookingId: response.bookingID || response.BookingId,
  };
}

export function fetchChartMessage(card, chartStatus) {
  if (!isEmpty(chartStatus)) {
    return chartStatus;
  }
  return card.chartStatus;
}

export function fetchLastPnrUpdatedMessage(response, lastUpdateTime) {
  if (!isEmpty(lastUpdateTime)) {
    return lastUpdateTime;
  }
  return response.lastUpdatedPnrStatus;
}

export const getCancellationPolicyView = (response, cancellationNowImageUsed) => {
  if (isEmpty(response)) {
    return [];
  }
  const { cancellationPolicy } = response;
  const { cancelPolicyList: list } = cancellationPolicy || {};
  let viewsList = [];
    for (let index = 0; index < list.length; index++) {
      let [imgSource, currentTimeline] = getImgSource(
        list[index].cancellationPolicyStartTime,
        list[index].cancellationPolicyEndTime,
      );
      if (currentTimeline) {
        cancellationNowImageUsed.current = true;
      }
      viewsList.push(
        rendorForEachElement(
          imgSource,
          list[index].cancellationTimelineTitle,
          list[index].penaltyAmount,
          false,
          currentTimeline,
        ),
      );
    }

  return viewsList;
};

export const checkForAndroidPaymentSuccess = (data) => {
  let success = data.Payment_Status === PAYMENT_REPONSE.PAYMENT_SUCCESS;
  try{
      const paymentData = JSON.parse(data.PAYMENT_RESPONSE_VO);
      const {response=EMPTY_JSON_STRING} = paymentData;
      const commitResponse = JSON.parse(response);
      success = success && commitResponse.success;
  }catch(err){
      console.log(err);
      success=false;
  }
  return success;
}

export const checkForIosPaymentSuccess = (data) => {
  return data.paymentSuccess && data.success;
}

const getBookingInfo = (holdResponse, bookingId) => {
  const { paymentDetails } = holdResponse || {};
  const { checkoutId, product } = paymentDetails || {};
  return {
    bookingId: bookingId,
    product: product,
    checkoutId: checkoutId.toString(),
  };
};

const getUserInfo = (holdResponse) => {
  const { paymentDetails } = holdResponse || {};
  const { mobile, travellerEmail } = paymentDetails || {};
  return {
    mobile,
    travellerEmail,
  };
};

export const getPaymentRequest = (holdResponse, bookingId) => {
  if( Platform.OS === 'android' ){
    return {
      paymentDetails: getPaymentDetails(holdResponse, bookingId),
      eventName: RailBookingDetailConstant.RAIL_TGS_THANKYOU_EVENT,
    }
  }else if( Platform.OS === 'ios'){
    return {
      bookingInfo: getBookingInfo(holdResponse, bookingId),
      userVO: getUserInfo(holdResponse),
      eventName: RailBookingDetailConstant.RAIL_TGS_THANKYOU_EVENT,
    }
  }
}
export const getPaymentDetails = (holdResponse, bookingId) => {
  const { paymentDetails } = holdResponse || {};
  const { checkoutId, mobile, travellerEmail, product } = paymentDetails || {};
  return {
    checkoutId: checkoutId.toString(),
    travellerEmail,
    channel: product,
    mobile,
    bookingId,
  };
};

export const getStatusStyle = (styles, item, isLastElement) => {
  let statusStyle = [styles.status];
  let statusTextStyle = [styles.statusText];
  const lastItemStyle = isLastElement ? 'lastEleStyle' : '';
  const {bookingState} = item || {};
  switch (item.type) {
    case BOOKING_STATUS.CNF:
    case BOOKING_STATUS.NOSB:
      if(bookingState === BOOKING_TYPE.COMPLETED){
        statusStyle.push(styles.statusConfirmComplete), statusTextStyle.push(styles.statusConfirmCompleteText);
      }else{
        statusStyle.push(styles.statusConfirm), statusTextStyle.push(styles.statusConfirmText);
      }
      break;
    case BOOKING_STATUS.RAC:
      statusStyle.push(styles.statusRAC), statusTextStyle.push(styles.statusRACText);
      break;
    case BOOKING_STATUS.WAITING:
    case BOOKING_STATUS.RLWL:
    case BOOKING_STATUS.PQWL:
      statusStyle.push(styles.statusWaiting), statusTextStyle.push(styles.statusWaitingText);
      break;

    case BOOKING_STATUS.CAN:
      statusStyle.push(styles.statusCAN), statusTextStyle.push(styles.statusCANText);
      break;
  }
  return {
    statusStyle,
    statusTextStyle,
    lastItemStyle,
  };
};

export const getRACTrackingEvent = ( actionType ) => {
  switch(actionType){
    case TGS_ACTIONS.TRAVEL_WITH_RAC:
      return RAIL_TRACKING_CONSTANT.RAC_TG_REFUND
    case TGS_ACTIONS.CANCEL_BOOKING_TGREFUND:
      return RAIL_TRACKING_CONSTANT.RAC_CONFIRM_CANCEL
    case TGS_ACTIONS.CANCEL_BOOKING_TGREFUND:
      return RAIL_TRACKING_CONSTANT.RAC_TG_2X_CONFIRM_CANCEL
    default:
      return ""
  }
}

export const getIsRailRACCardVisible = (response = {}) => {
  const cardList = get(response, 'layoutDetail.cardList', []);
  const railTGSCard =
    !isEmpty(cardList) &&
    cardList.filter(
      (card) =>
        card.cardId === RailBookingDetailConstant.RAIL_TGS_Card &&
        card.isVisible,
    );

  return !isEmpty(railTGSCard);
}

export const getRailAncillary = (response = {}) => {
  if( isFreeCancellationApplicable(response) ){
    return RAIL_ANCILLARY.FREE_CANCELLATION;
  }else if( getIsRailRACCardVisible( response ) ){
    return RAIL_ANCILLARY.TG_APPLIED;
  }else{
    return RAIL_ANCILLARY.NONE;
  }
}

const isFreeCancellationApplicable = (response) => {
  const cardList = get(response, 'layoutDetail.cardList', []);
  const subCardList = cardList.map( card => {
    if( card.subCardList && !isEmpty(card.subCardList)){
      return card.subCardList;
    }else{
      return [];
    }
  })
  const flattenSubCardList = flatten(subCardList);
  const railIternaryCard =
    !isEmpty(flattenSubCardList) &&
    flattenSubCardList.filter(
      (card) =>
        card && card.cardId === RailBookingDetailConstant.RAIL_ITINERARY_CARD &&
        card.isVisible,
    );
  return !isEmpty(railIternaryCard) && !!railIternaryCard[0].freeCancellationApplicable;
}

export const goToWallet = (url) => {
  if( !isEmpty(url) ){
    if (Platform.OS === 'web') {
      window.open(url, '_blank').focus();
    } else {
      GenericModule.openDeepLink(url);
    }
  }
}

export const setWishingToTravelWithRAC =  async (bookingId , travelWithRACClickHandler ) => {
  const travellingTravelWithRACKey = `travellingTravelWithRAC${bookingId}`;
  await setDataInStorage(travellingTravelWithRACKey, true);
  travelWithRACClickHandler();
};

export const getCancellationDetailInfo = (response) => {
  const segmentInfo = getSegmentInfo(response) || {};
  const cancelDetailList = segmentInfo.cancellationDetailList || response.cancellationDetailList;
  const cancelDetailNoList = [];
  if (!isEmpty(cancelDetailList)) {
    cancelDetailList.forEach((cancellationDetail) => {
      if (
        cancellationDetail.requestType === CANCELLATION_REQUEST_TYPE ||
        cancellationDetail.requestType === CLAIM_REQUEST_TYPE
      ) {
        cancelDetailNoList.push(cancellationDetail.requestNo);
      }
    });
  }

  return {
    cancelDetailNoList
  }
}
