import React from 'react';
import {View, Text, Image} from 'react-native';
import {mailIcon, callMmtIcon, refundsIcon} from '../../../../../../bus/BusImageConstants';
import { getFont } from '../../../../../../PostSalesStyles';
import { useTheme } from '../../../../../../theme/theme.context';
import createStyles from "./ClaimProcessListCss";

const ClaimProcessList = ({processList}) => {
  const fonts = getFont()
  const {psTheme : theme} = useTheme();
  const styles = createStyles(theme,fonts);
    return (
      <View style={[styles.whiteCard, styles.listWrapper]}>
        <View style={styles.listItem}>
          <Image style={[styles.listItemIcon, styles.Email_Icon]} source={mailIcon} />

          {processList && processList.length>0 && <Text style={[styles.listItemTitle, fonts.boldFontFamily]}>{processList[0].header}</Text>}
          {processList && processList.length>0 && <Text style={[styles.listItemDesc, fonts.regularFontFamily]}>{processList[0].description}</Text>}
        </View>
        <View style={styles.listItem}>
          <Image style={[styles.listItemIcon, styles.Call_Icon]} source={callMmtIcon} />
          {processList && processList.length>1 && <Text style={[styles.listItemTitle, fonts.boldFontFamily]}>{processList[1].header}</Text> }
          {processList && processList.length>1 && <Text style={[styles.listItemDesc, fonts.regularFontFamily]}> {processList[1].description} </Text> }
        </View>
        <View style={styles.listItem}>
          <Image style={[styles.listItemIcon, styles.Refund_Icon]} source={refundsIcon} />
          {processList && processList.length>2 && <Text style={[styles.listItemTitle, fonts.boldFontFamily]}>{processList[2].header}</Text> }
          {processList && processList.length>2 && <Text style={[styles.listItemDesc, fonts.regularFontFamily]}> {processList[2].description} </Text> }
        </View>
      </View>
    );
}

export default ClaimProcessList;
