import PropTypes from 'prop-types';
import Actions from '../../../navigation/postSalesNavigation';
import React from 'react';
import {ScrollView, Alert, Text, TextInput, View} from 'react-native';
import getStyles from './HotelMamiCss';
import PostSalesHotelsBaseClass from './PostSalesHotelsBaseClass';
import StickyHeader from './StickyHeader';
import ViewStates from '../utils/ViewStates';

import HotelBookingTrackingHelper from '../../HotelBookingTrackingHelper';
import FullButton from '../ActionButton/FullButton';
import {isNetworkAvailable} from '@mmt/legacy-commons/Common/utils/AppUtils';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import {WRITE_TO_REQUEST_RAISED_BY} from '../../../PostSalesConstant';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import {getCommonHeaders, SPECIAL_REQUEST_WRITE_TO_HOTEL_URL} from '../../../utils/NetworkUtils';
import logAction from '@mmt/legacy-commons/Helpers/actionsLogger';
import { getStaticData } from '../../../staticData/staticData';
import { LEFT_BLACK_ARROW_ICON } from '../../HotelImageConstants';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss'
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import ButtonWrapper from 'apps/post-sales/src/Common/ButtonWrapper';
import InputField from '@Frontend_Ui_Lib_App/InputField';
const headerIcon = LEFT_BLACK_ARROW_ICON;

export default class RequestWriteToHotel extends PostSalesHotelsBaseClass {
  constructor(props) {
    super(props, 'requestWriteToHotel', 'Persuadetocall');
    this.fonts = getFont(true);
    this.styles = getStyles(this.fonts);
    this.fsStyle = this.fonts.fsStyle;
    this.state = {
      emailText: '',
      height:0,
      viewState: ViewStates.DEFAULT
    };
    this.action = {};
    this.card = [];
  }

    onSendMessage = () => {
      const staticData = getStaticData();
      if (this.state.emailText === '') {
        Alert.alert('', staticData.requestWriteToHotelText.additionalText.pleaseEnterYourMessageText);
      } else {
        HotelBookingTrackingHelper.trackClickEvent(this.trackingPageName, 'MI_HTL_Specialrequestsubmitted', this.props.response);
        this.setState({
          viewState: ViewStates.LOADING
        });
        this.fetchData();
      }
    }

    async fetchData() {
      const staticData = getStaticData();
      try {
        const subMsg = staticData.requestWriteToHotelText.additionalText.yourRequestHasBennSentToHotelierText;
        const hasNetwork = await isNetworkAvailable();
        if (!hasNetwork) {
          this.setState({viewState: ViewState.NO_INTERNET});
        }
        const request = {
          documentno: this.props.response.bookingID,
          requestType: this.props.requestData.mainRequestType,
          subRequestType: this.props.requestData.unlabelledRequestType,
          message: this.state.emailText,
          mailFrom: WRITE_TO_REQUEST_RAISED_BY
        };
        const userInfo = await HotelBookingModule.getRequestHeader(this.props.response.bookingID, 'REQUEST_WRITE_TO_HOTEL');
        const response = await
          fetch(SPECIAL_REQUEST_WRITE_TO_HOTEL_URL, {
            method: 'POST',
            body: JSON.stringify(request),
            headers: await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo)
          });

        if (response.ok) {
          const body = await
            response.json();
          if (body.success) {
            Actions.psThankYouPage({
              msg: staticData.requestWriteToHotelText.additionalText.messageSentSuccessfulText,
              subMsg,
              bookingID: this.props.response.bookingID,
              lob: 'Hotel'
            });
            this.setState({
              viewState: ViewStates.DEFAULT
            });
          } else {
            alert(body.responseText);
            this.setState({
              viewState: ViewStates.DEFAULT
            });
          }
        } else {
          this.setState({
            viewState: ViewStates.ERROR
          });
        }
      } catch (e) {
        this.setState({
          viewState: ViewStates.ERROR
        });
        logAction(e);
      }
    }

    render() {
      const staticData = getStaticData();
      let type = this.props.response.hotelBookingInfo.hotelDetailsList[0].propertyType ?
            this.props.response.hotelBookingInfo.hotelDetailsList[0].propertyType : staticData.hotelText;

      const titleTxt = this.props.response.hotelBookingInfo.hotelDetailsList[0].hotelUIFields && this.props.response.hotelBookingInfo.hotelDetailsList[0].hotelUIFields.writeToHotelText;
      return (
        <View style={this.styles.makeRequestWrapper} >
          <StickyHeader
            iconSrc={headerIcon}
            title={titleTxt}
            headerColor={['#ffffff', '#ffffff']}
            titleStyle={[this.styles.makeRequestHeader]}
            subTitle=""
            subTitleStyle={[this.styles.DefaultText, this.styles.font12]}
            onBackPress={this.onBackPress}
          />
          {this.state.viewState === ViewStates.DEFAULT && this.initLoading()}
          {this.state.viewState === ViewStates.LOADING && this.renderProgressView(staticData.requestWriteToHotelText.additionalText.sendingRequest)}
          {this.state.viewState === ViewStates.ERROR && this.renderErrorView()}
          {this.state.viewState === ViewStates.NO_INTERNET && this.renderNoNetworkView()}
        </View>
      );
    }

    initLoading() {
      const staticData = getStaticData();
      let type = this.props.response.hotelBookingInfo.hotelDetailsList[0].propertyType ?
            this.props.response.hotelBookingInfo.hotelDetailsList[0].propertyType : staticData.hotelText;

      const buttonTxt = this.props.response?.hotelBookingInfo?.hotelDetailsList[0]?.hotelUIFields?.sendMessageText
      const replyTimeText = this.props.response.hotelBookingInfo?.hotelDetailsList[0]?.hotelUIFields?.takeUpto48HrsToReplyText || '';

      return (
        <View style={{flex:1, justifyContent: 'space-between'}}>
          <ScrollView style={{flex:1}} keyboardShouldPersistTaps="always" keyboardDismissMode="on-drag" >
            <InputField
              customStyle={{
                wrapperStyle:[
                [this.styles.writeToHotelText, this.styles.writeToHotelOuter, { height: Math.max(240, this.state.height) }]
                ],
                inputFieldStyle: [this.styles.writeToHotelFormField, {height: Math.max(200, this.state.height)}]
                }}
                placeholder={this.props.requestData.message_textbox}
                value={this.state.emailText}
                onChangeText={emailText => this.setState({emailText})}
                inputProps={{
                multiline: true,
                maxLength:250,
                underlineColorAndroid:"transparent",
                onContentSizeChange: (event) => {
                   this.setState({height: event.nativeEvent.contentSize.height});
                }

              }}
            />
            
            <View style={[this.styles.writeHotelGenericView]}>
              <Text style={[this.styles.writeHotelGenricText, this.styles.pad15Top]}>
                {replyTimeText} {this.props.response.hotelBookingInfo.primaryCustomerDetails.eMailID}.
              </Text>
            </View>
          </ScrollView>
          <View style={this.styles.alignBottom}>
            <ButtonWrapper
              btnText={buttonTxt}
              clickHandler={this.onSendMessage}
              btnWrpStyle={[this.styles.checkAvlBtn, this.styles.mar10Bt]}
              btnTxtStyle={{textTransform: 'inherit'}}
            />
          </View>
        </View>
      );
    }
}

RequestWriteToHotel.propTypes = {
  requestData: PropTypes.object.isRequired,
  response: PropTypes.object.isRequired
};
