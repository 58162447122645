import React from 'react';
import {ScrollView, View} from 'react-native';
import styles from './inclusionExclusionCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import InclusionTab from './Inclusions';
import ExclusionTab from './Exclusions';
import RefundDetails from './refundDetails';
import Header from '../../../../Common/HeaderCross';
import ExtraCharge from './ExtraCharge';
import Accordian from '../Accordian/Accordian';
import { backPressHandler } from '../../../../utils/PostSaleUtil';

class InclusionExclusion extends React.Component {
    static navigationOptions = {
      header: null
    };

    render() {
      return (
        <View style={[AtomicCss.flex1, styles.backgroundColorContainer]}>

          <Header
            title={this.props.title}
            headerShadow
            headingTxt="font18"
            handleClose={this.goBackToDetails}
          />
          <ScrollView contentContainerStyle={styles.inclusionsWrapper}>
            {this.props.inclusions && <InclusionTab response={this.props.bookingDetailsResponse.otherInfo} />}
            {this.props.inclusions && <ExclusionTab response={this.props.bookingDetailsResponse.otherInfo} />}
            {this.props.inclusions && <ExtraCharge response={this.props.bookingDetailsResponse.otherInfo} />}
            {this.props.refundDetails && <RefundDetails response={this.props.bookingDetailsResponse.otherInfo} />}
            {this.props.frequentQuestions && <Accordian response={this.props.bookingDetailsResponse.otherInfo} />}
          </ScrollView>
        </View>
      );
    }
    goBackToDetails = () => {
      backPressHandler();
    }
}
export default InclusionExclusion;
