import { StyleSheet } from 'react-native';
import { fonts, colors } from '@mmt/legacy-commons/Styles/globalStyles';

const styles = StyleSheet.create({
    OverlayContentWrapper: {
        backgroundColor: colors.white,
        paddingTop: 12,
        paddingBottom: 20,
        position: 'relative',
        zIndex: 1,
        width: '100%',
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        paddingBottom: 50,
        // maxHeight: 550,
        elevation:2,
        overflow:'hidden'
    },
    overlayTopline: {
        width: 40,
        height: 4,
        borderRadius: 4,
        backgroundColor: colors.disabledButton,
        alignSelf: 'center',
        position:'absolute',
        zIndex:11,
        top:10,
        opacity:.8,

    },
    innerWrapper: {
        paddingHorizontal: 20,
    },
    title: {
        color: colors.defaultTextColor,
        fontSize: 22,
        fontFamily: fonts.black,
        marginBottom: 5,
        lineHeight: 32
    },

    innerInfoSection: {
        paddingHorizontal: 40,
    },
    listWrapper: {
        width: '40%',
        alignItems: "center",
        flexDirection: 'row',
        marginRight:10,
        marginBottom: 16
    },
    benefitList: {
        marginBottom:20,
        flexDirection: 'row',
        width: "100%",
        flexWrap: "wrap",
        justifyContent: 'space-between'
    },
    imgWrapper: {
        width: 30,
        height: 30,
        alignItems: 'center',
        marginRight:5,
    },
    iconStyle:{
        width:28,
        height:28,
    },
    travelKStyle:{
        width:23,
        height:28,
    },
    liveMapStyle:{
        width:23,
        height:28,
    },
    topillustration:{
        marginBottom:40,
    },
    topCurve:{
        backgroundColor: colors.cyan2,
        height:300,
        marginTop:-200,
        borderBottomLeftRadius:500,
        borderBottomRightRadius:500,
        width:'150%',
        marginLeft:'-25%'
    },
    giftIconillustrationWrapper:{
        justifyContent:'center',
        alignItems:'center'
    },
    giftIconillustration:{
        width:80,
        height:80,
        position:'absolute',
        top:-50,
    },
    rideImgStyle: {
        top: 2,
        width: 38,
        height: 22,
        marginHorizontal: 1,
        resizeMode: 'contain',
      },
});

export default styles;