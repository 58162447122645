import React from 'react';
import { View, Image, Text, TouchableOpacity } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import createStyle from './LinkSectionStyleCss';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import { rightBlueArrow } from '../../BusImageConstants';

interface LinkSectionProps {
  title: string;
  info: string;
  actionId: string;
  iconUrl: string;
  onPress: (actionId: string) => void;
}

const LinkSection: React.FC<LinkSectionProps> = (props) => {
  const { title, info, actionId, iconUrl, onPress } = props;
  const { psTheme } = useTheme();
  const styles = createStyle(psTheme);

  const onPressHandle = () => {
    onPress(actionId);
  };

  return (
    <TouchableOpacity style={[styles.rowSection, AtomicCss.marginBottom12]} onPress={onPressHandle}>
      <View style={[AtomicCss.flex1, AtomicCss.flexRow, AtomicCss.alignCenter]}>
        <Image style={styles.iconStyle} source={{ uri: iconUrl }} />
        <View style={[AtomicCss.flex1]}>
          <Text style={[AtomicCss.font14, AtomicCss.defaultText, AtomicCss.boldFont]}>{title || ''}</Text>
          <Text
            style={[
              AtomicCss.font14,
              AtomicCss.midGreyText,
              AtomicCss.regularFont,
              AtomicCss.marginTop5,
            ]}
          >
            {info || ''}
          </Text>
        </View>
      </View>
      <Image style={styles.rightArrowStyle} source={rightBlueArrow} />
    </TouchableOpacity>
  );
};

export default LinkSection;
