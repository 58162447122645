import React from 'react';
import PropTypes from 'prop-types';
import LinearGradient from 'react-native-linear-gradient';
import {Image, Text, TouchableOpacity, View, Platform} from 'react-native';
import styles from '../../CSS/VisaMamiCss';
import {
  getActionButton,
  isNotNullAndEmpty
} from '../../../hotel/details/utils/HotelBookingDetailUtil';
import {handleClickEvent} from '../../Util/VisaUtil';
import VisaDetailConstant from '../../Constant/VisaDetailConstant';
import VisaBookingTrackingHelper from '../../VisaBookingTrackingHelper';
import {headerIcon} from '../../Constant/VisaImageConstant';

class TopStatusCard extends React.Component {
  render() {
    return (
      <LinearGradient
        start={{
                    x: 1.0,
                    y: 0.0
                }}
        end={{
                    x: 0.0,
                    y: 1.0
                }}
        colors={this.props.color}
        style={[styles.topStatusCard]}
      >
        <View style={styles.topStatusBar}>
          <TouchableOpacity style={{padding: 16}} onPress={this.props.onBackPress}>
            <Image
              style={styles.backarrow}
              source={headerIcon}
            />
          </TouchableOpacity>
          <Image
            style={[styles.mar10Bt, styles.ApprovedIcon, styles.mar10Bt]}
            source={this.props.iconPath}
          />
          <TouchableOpacity style={{padding: 16}} />
        </View>
         <View style={[styles.StatusCardWrapper, styles.transperantBgrnd]}>
         {!!this.props.headText && <Text
            style={this.props.headtextStyle}
          >{this.props.headText}
          </Text>}
          <Text style={this.props.subTextStyle}>{this.props.subText}
          </Text>
          <Text style={[styles.WhiteText,
                          styles.regularFont,
                styles.mar12Bt, styles.AlignCenter, styles.bgTransparent]}
          >Booking ID &nbsp;

            <Text
              style={[styles.WhiteText, styles.boldFont]}
            >{this.props.bookingId}
            </Text>
          </Text>
          <View style={isNotNullAndEmpty(this.props.card.actionList) ? styles.whiteBtnHalfOuter : styles.hideView}>
            {getActionButton(this.props.card, this.props.response, this.handleClick)}
          </View>
        </View>
      </LinearGradient>
    );
  }

  handleClick = (action) => {
    if (action.actionFamily === VisaDetailConstant.TRACK_VISA_STATUS) {
      VisaBookingTrackingHelper.trackClickEvent(
        this.props.pageName,
        this.props.response,
        'track_status',
      );
      this.props.scrollToVisaTracker();
    } else {
      handleClickEvent(this.props.card, action, this.props.response, this.props.pageName);
    }
  };

    showActionView = () => {
      let showView = true;
      if (Platform.OS === 'ios') {
        this.props.card.actionList.forEach((action) => {
          if (action.actionFamily === VisaDetailConstant.VISA_DOWNLOAD_VISA) {
            showView = false;
          }
        });
      }
      return showView;
    }
}

TopStatusCard.propTypes = {
  response: PropTypes.object.isRequired,
  iconPath: PropTypes.number.isRequired,
  headText: PropTypes.string.isRequired,
  color: PropTypes.array.isRequired,
  subText: PropTypes.string,
  card: PropTypes.object.isRequired,
  pageName: PropTypes.string.isRequired,
  headtextStyle: PropTypes.array.isRequired,
  subTextStyle: PropTypes.any.isRequired,
  bookingId: PropTypes.any.isRequired,
  scrollToVisaTracker: PropTypes.func.isRequired
};

TopStatusCard.defaultProps = {
  subText: ''
};

export default TopStatusCard;
