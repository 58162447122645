import {trackOmniture} from '@mmt/legacy-commons/Common/utils/OmnitureTrackerUtils';
import {METRO_LOB} from '../PostSalesConstant';
import MetroBookingEventsLogger from './MetroBookingEventsLogger';

class MetroBookingTrackingHelper {

    trackLoadEvent = (pageName, metroResponse) => {
      MetroBookingEventsLogger.loadEvent(pageName, metroResponse);
      let params = this.getBasicOminitureVariables(pageName, metroResponse);
      trackOmniture(pageName, params);
    };

    trackErrorEvent = (pageName) => {
      MetroBookingEventsLogger.errorEvent(pageName);
      const params = {};
      params.pageName = pageName;
      params.m_v15 = pageName;
      trackOmniture(pageName, params);
    };

    trackClickEvent = (pageName, clickEvent) => {
      MetroBookingEventsLogger.clickEvent(pageName,clickEvent);
      const params = {};
      params.pageName = pageName;
      params.m_v15 = pageName;
      params.m_c54 = clickEvent;
      trackOmniture(pageName, params);
    };

    getBasicOminitureVariables(pageName, metroResponse) {
      const params = {};
      try {
        params.pageName = pageName;
        params.m_v15 = pageName;
        if (metroResponse !== undefined) {
          params.m_v3 = `${metroResponse.bookingInfo.source} - ${metroResponse.bookingInfo.destination}`;
          params.m_v16 = metroResponse.bookingId;
          params.m_v9 = metroResponse.bookingState.state;
          params.m_v19 = metroResponse.bookingState.subState;
          params.m_v24 = METRO_LOB;
          params.m_v13 = metroResponse.paymentSummary.metroCity;
          params.m_v46 = metroResponse.bookingInfo.totalSellingPrice;
          params.m_v25 = this.getPaxCount(metroResponse);
          params.m_v40 = metroResponse.bookingInfo.bookingType;
        }
        return params;
      } catch {
        return params;
      }
    }

  getPaxCount(metroResponse) {
      if (metroResponse.bookingInfo && metroResponse.bookingInfo.ticketInfoList) {
        if(metroResponse.bookingInfo.serviceType === 'FORWARD')
          return metroResponse.bookingInfo.ticketInfoList.length;
        else
          return metroResponse.bookingInfo.ticketInfoList.length / 2;
      }
      return 1;
    }
}

export default new MetroBookingTrackingHelper;
