import { logMessage } from '@mmt/legacy-commons/Helpers/actionsLogger';
import RefundPaxCancelledDtls from '../CancellationRefundDtls/RefundPaxCancelledDtls';
import React from 'react';
import * as FlightBookingUtils from '../../FlightBookingUtil';
import {
  GREEN_REFUND_ICON,
  RED_REFUND_ICON,
  BLACK_REFUND_ICON,
  ZERO_CANCEL_ICON,
  DB_SM_ICON,
  RED_INFO_ICON,
} from '../../FlightImageConstants';
import isEmpty from 'lodash/isEmpty';

export const goToBookingDetails = (bookingId) => {
  FlightBookingUtils.resetToFlightBookingDetail(bookingId);
};

export function getCancelledSegmentInfo(cancellationPreviewResponseJson) {
  const { previewDetail } = cancellationPreviewResponseJson;
  const cancelledSegmentInfoList = [];
  const segmentMap = [];
  const paxMap = [];
  cancellationPreviewResponseJson.flightDetails.segmentGroupDetailList.forEach((segmentGroup) => {
    segmentGroup.segmentDetails.forEach((segment) => {
      segmentMap[segment.segmentLineNo] = segment;
    });
    segmentGroup.segmentGroupPassengers.forEach((passenger) => {
      paxMap[passenger.paxLineNo] = passenger;
    });
  });
  previewDetail.itineraryDetails.forEach((itineraryDetail) => {
    const segment = segmentMap[itineraryDetail.segId];
    if (segment) {
      const paxNames = [];
      itineraryDetail.pIds.forEach((paxId) => {
        const paxInfo = paxMap[paxId];
        if (paxInfo && paxInfo.passengerName) {
          paxNames.push(`${paxInfo.passengerName.firstName} ${paxInfo.passengerName.lastName}`);
        }
      });
      const { originCity, destinationCity } = segment;
      const travelDate = segment.flightTravelDateV2;
      const cancelledSegmentInfo = {};
      cancelledSegmentInfo.messages = [];
      cancelledSegmentInfo.travelInfo = `${originCity} - ${destinationCity}, ${travelDate}`;
      cancelledSegmentInfo.paxNames = paxNames;
      itineraryDetail.addAttributes.forEach((attribute) => {
        if (cancellationPreviewResponseJson.previewDetail.propertiesMapping.properties[attribute]) {
          const attributeObj =
            cancellationPreviewResponseJson.previewDetail.propertiesMapping.properties[attribute];
          const message = {};
          message.attributImg = attributeObj.img;
          message.attributSubTitle = attributeObj.subtitle;
          message.attributSubTitleColor = attributeObj.subtitleColor;
          cancelledSegmentInfo.messages.push(message);
        }
      });
      cancelledSegmentInfoList.push(cancelledSegmentInfo);
    }
  });
  return cancelledSegmentInfoList;
}

export function getSegmentWiseInfo(cancellationPreviewResponseJson) {
  const { previewDetail, flightDetails } = cancellationPreviewResponseJson;
  const cancelledSegmentInfoList = [];
  const segmentMap = [];
  !isEmpty(flightDetails) &&
    !isEmpty(flightDetails.segmentGroupDetailList) &&
    flightDetails.segmentGroupDetailList.forEach((segmentGroup) => {
      segmentGroup.segmentDetails.forEach((segment) => {
        segmentMap[segment.segmentLineNo] = segment;
      });
    });
  !isEmpty(previewDetail) &&
    !isEmpty(previewDetail.itineraryDetails) &&
    previewDetail.itineraryDetails.forEach((itineraryDetail) => {
      const segment = segmentMap[itineraryDetail.segId];
      cancelledSegmentInfoList.push(segment);
    });
  return cancelledSegmentInfoList;
}

export const getImageIcon = (imgName) => {
  if (imgName) {
    switch (imgName) {
      case 'REFUND':
        return GREEN_REFUND_ICON;
      case 'NO_REFUND':
        return RED_REFUND_ICON;
      case 'PART_REFUND':
        return BLACK_REFUND_ICON;
      case 'ZC':
        return ZERO_CANCEL_ICON;
      case 'ZC_BENEFIT':
        return ZERO_CANCEL_ICON;
      case 'ZC_EXPIRED':
        return ZERO_CANCEL_ICON;
      case 'DB':
        return DB_SM_ICON;
      case 'DB_EXPIRED':
        return DB_SM_ICON;
      case 'DB_BENEFIT':
        return DB_SM_ICON;
      case 'RED_BUBBLE':
        return RED_INFO_ICON;
      default:
        return undefined;
    }
  }
  return undefined;
};

export function segmentPaxInfo(cancellationPreviewResponseJson) {
  if (
    !(
      cancellationPreviewResponseJson &&
      cancellationPreviewResponseJson.previewDetail &&
      cancellationPreviewResponseJson.previewDetail.itineraryDetails
    )
  ) {
    logMessage('unable to generate itinerary info from response');
    return null;
  }

  return getCancelledSegmentInfo(cancellationPreviewResponseJson).map(
    (cancelledSegmentInfo, index) => {
      const isBorderTop =
        index + 1 === getCancelledSegmentInfo(cancellationPreviewResponseJson).length;
      return (
        <RefundPaxCancelledDtls
          key={cancelledSegmentInfo.travelInfo}
          paxNames={cancelledSegmentInfo.paxNames}
          reason={cancelledSegmentInfo.cancelReason}
          segmentWithDate={cancelledSegmentInfo.travelInfo}
          messages={cancelledSegmentInfo.messages}
          isBorderTop={isBorderTop}
        />
      );
    },
  );
}

export function getRefundAttributes(cancellationPreviewResponseJson) {
  const { previewDetail } = cancellationPreviewResponseJson;
  const cancelledSegmentInfo = {};
  cancelledSegmentInfo.messages = [];
  previewDetail.refundAddAttributes &&
    previewDetail.refundAddAttributes.forEach((attribute) => {
      if (cancellationPreviewResponseJson.previewDetail.propertiesMapping.properties[attribute]) {
        const attributeObj =
          cancellationPreviewResponseJson.previewDetail.propertiesMapping.properties[attribute];
        const message = {};
        message.attributImg = attributeObj.img;
        message.attributSubTitle = attributeObj.subtitle;
        message.attributSubTitleColor = attributeObj.subtitleColor;
        cancelledSegmentInfo.messages.push(message);
      }
    });
  return cancelledSegmentInfo.messages;
}

export function getTotalPaidAttributes(cancellationPreviewResponseJson) {
  const { previewDetail } = cancellationPreviewResponseJson;
  const cancelledSegmentInfo = {};
  cancelledSegmentInfo.messages = [];
  previewDetail.breakups.totalAmountPaid.addAttributes &&
    previewDetail.breakups.totalAmountPaid.addAttributes.forEach((attribute) => {
      if (cancellationPreviewResponseJson.previewDetail.propertiesMapping.properties[attribute]) {
        const attributeObj =
          cancellationPreviewResponseJson.previewDetail.propertiesMapping.properties[attribute];
        const message = {};
        message.attributImg = attributeObj.img;
        message.attributSubTitle = attributeObj.subtitle;
        message.attributSubTitleColor = attributeObj.subtitleColor;
        cancelledSegmentInfo.messages.push(message);
      }
    });
  return cancelledSegmentInfo.messages;
}
