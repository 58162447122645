import React, { useState } from 'react';
import FilterCardSection from '../FilterCardSection';
import FilterHeader from '../FiltersHeader';
import { View, ScrollView, Text, TouchableOpacity } from 'react-native';
import ProgressView from 'apps/post-sales/src/Common/ProgressView/ProgressView';
import BaseButton, { BUTTON_VARIANT } from 'apps/post-sales/src/Common/BaseButton';
import FilterOverlay from '../FilterOverlay';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import { FILTERS, FiltersOmnitureConstants } from '../../../../summaryConstants';
import { getStaticData } from '../../../../../../staticData/staticData';
import createStyles from "./style";
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import { SelectedFilter, Filter } from '../../../../types';
import TripSummaryOmnitureTracker from '../../../../TripSummaryOmnitureTracker';

interface FilterSectionProps {
  heading: string;
  clearAllText: string;
  data: { id: string; title: string; values: Filter[] }[];
  handleClose: () => void;
  handleFilterSelection: (selectedFilters: SelectedFilter[]) => void;
  isLoading: { status: boolean; msg: string };
  selectedFilters: SelectedFilter[];
  id: string;
}

const FilterSection: React.FC<FilterSectionProps> = (props) => {
  const {
    heading,
    clearAllText,
    data,
    handleClose,
    handleFilterSelection,
    isLoading,
    selectedFilters: filters,
    id,
  } = props || {};
  const [selectedFilters, setFilters] = useState<SelectedFilter[]>(cloneDeep(filters));
  const { viewBookingsText } = getStaticData(true);
  const fonts = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);

  const handleTab = (value: string, text: string, key: string = id) => {
    let newFilters = [...selectedFilters];
    const filterIndex = newFilters.length
      ? selectedFilters.findIndex((val) => val.key === key)
      : -1;
    if (filterIndex === -1) {
      newFilters.push({ key, values: [{ id: value, text: text }] });
      setFilters(newFilters);
    } else {
      const filterValIndex = newFilters[filterIndex].values && newFilters[filterIndex].values.findIndex((val) => val.id === value);
      if (filterValIndex === -1) {
        newFilters[filterIndex].values && newFilters[filterIndex].values.push({ id: value, text: text });
        setFilters(newFilters);
      } else {
        newFilters[filterIndex].values && newFilters[filterIndex].values.length === 1
          ? newFilters.splice(filterIndex, 1)
          : newFilters[filterIndex].values && newFilters[filterIndex].values.splice(filterValIndex, 1);
        setFilters(newFilters);
      }
    }
  };

  const handleRemoveFilter = (value: Filter, key: string) => {
    TripSummaryOmnitureTracker.trackNormalClickEvent(value.id, FiltersOmnitureConstants.FILTERS_CLEAR_ALL);
    let newFilters: SelectedFilter[] = [...selectedFilters];
    const keyIndex: number = newFilters.findIndex((filter: SelectedFilter) => filter.key === key);
    const valueIndex: number = newFilters[keyIndex].values.findIndex((val:Filter) => val.id === value.id);
    newFilters[keyIndex].values.splice(valueIndex, 1);
    if (newFilters[keyIndex].values.length === 0) {
      newFilters.splice(keyIndex, 1);
    }
    setFilters(newFilters);
  };

  const handleClick = () => {
    TripSummaryOmnitureTracker.trackNormalClickEvent(FiltersOmnitureConstants.FILTERS_TEXT, FiltersOmnitureConstants.FILTERS_VIEW_BOOKINGS);
    handleFilterSelection(selectedFilters);
  };

  const getSelectedFilterValue = (key: string) => {
    const val = !isEmpty(selectedFilters)
      ? selectedFilters.find((filter) => filter.key === key)
      : null;
    return val ? val.values.map((val) => val.id) : [];
  };

  return (
    <View
      style={
        id === FILTERS.ALL_FILTERS
          ? [styles.filterOverlayWrapper, styles.filterMainWrapper]
          : styles.filterOverlayWrapper
      }
    >
      {id === FILTERS.ALL_FILTERS ? (
        isLoading.status ? (
          <ProgressView message={isLoading.msg} />
        ) : (
          <>
            <View style={styles.filterHeaderWrapper}>
              <View style={styles.filterHeader}>
                <FilterHeader
                  heading={heading}
                  clearAllText={clearAllText}
                  handleClose={handleClose}
                  selectedFilters = {selectedFilters}
                  handleRemoveAllFilters={() => setFilters([])}
                />
              </View>
              {!isEmpty(selectedFilters) && (
                <View style={styles.selectedFilterWrapper}>
                  {selectedFilters.map((item, index) =>
                    item.values.map((value) => (
                      <View style={styles.selectedFilter} key={value.id}>
                        <Text style={styles.selectedFilterText}>{value.text}</Text>
                        <TouchableOpacity
                          onPress={() => handleRemoveFilter(value, item.key)}
                          style={styles.crossIconWrapper}
                        >
                          <Text style={[styles.crossStyle]}>+</Text>
                        </TouchableOpacity>
                      </View>
                    ))
                  )}
                </View>
              )}
            </View>
            <ScrollView>
              {data.map((item) => (
                !isEmpty(item?.values) &&
                <FilterCardSection
                  title={item.title}
                  tabList={item?.values}
                  handleTab={(val, text) => handleTab(val, text, item.id)}
                  selectedTab={getSelectedFilterValue(item.id)}
                  key={item.id}
                />
              ))}
            </ScrollView>
            <View style={styles.btnWrapper}>
              <BaseButton
                variant={BUTTON_VARIANT.PRIMARY}
                clickHandler={handleClick}
                disabled={isEqual(selectedFilters, filters)}
                text={viewBookingsText}
              />
            </View>
          </>
        )
      ) : (
        <FilterOverlay
          title={heading}
          data={data}
          handleClose={handleClose}
          isLoading={isLoading}
          handleTab={handleTab}
          handleClick={handleClick}
          buttonDisabled={isEqual(selectedFilters, filters)}
          selectedFilters={getSelectedFilterValue(id)}
        />
      )}
    </View>
  );
};

export default FilterSection;