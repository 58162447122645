import React from 'react';
import { View, Image, Text } from 'react-native';
import styles from './HotelPhotographsCardCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {isNotNullAndEmpty, getActionButton, handleClickEvent, getHotelImageURLList, isNullOrEmpty, getPropertyType} from '../../utils/HotelBookingDetailUtil';
import { PHOTOGRAPHS_ICON, HOTEL_DEFAULT_ICON } from '../../../HotelImageConstants';
import { getStaticData } from '../../../../staticData/staticData';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const MAX_PHOTOS = 4;

const HotelPhotographsCard = ({ response, card, pageName }) => {
	const { fsStyle, ...fonts } = getFont(true);
	const staticData = getStaticData();
	const hotelImageURLList = getHotelImageURLList(response);

	const handleClick = (action) => {
		handleClickEvent(card, action, response, pageName);
  	};

	const addHotelImages = (hotelImageList) => {
		const views = [];
		if (isNotNullAndEmpty(hotelImageList)) {
			hotelImageList.map((hotelImageUrl, index) => {
				if(index < MAX_PHOTOS) {
					views.push(<Image 
						style={styles.galleryImg} 
						source={hotelImageUrl == null ? HOTEL_DEFAULT_ICON : {uri: hotelImageUrl}} 
					/>);
				}
			});
		} else {
			views.push(<Image
				style={styles.galleryImg}
				source={{uri: 'https://promos.makemytrip.com/MIMA/hotelDefault.jpg'}}
			/>);
		}
		return views;
	}

	if(isNullOrEmpty(hotelImageURLList)) {
		return null;
	}

	return (
		<View style={[AtomicCss.whiteCardNew, AtomicCss.marginHz12, AtomicCss.overflow, AtomicCss.borderRadius16]}>
			<View style={[styles.headerSec, AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom15]}>
				<Image source={PHOTOGRAPHS_ICON} style={styles.headingIcon} />
				<Text style={[fonts.blackFontFamily, fsStyle.font16, AtomicCss.blackText, AtomicCss.lineHeight20]}>
					{getPropertyType(response) + ' ' + staticData.photographsText}
				</Text>
			</View>
			<View style={styles.imageGallery}>
				{addHotelImages(hotelImageURLList)}
			</View>
			<View>
				{card && isNotNullAndEmpty(card.actionList) && 
        			getActionButton(card, response, handleClick)}
			</View>
		</View>
  );
};

export default HotelPhotographsCard;
