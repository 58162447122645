import React from 'react';
import {Image, Text, View} from 'react-native';
import getStyles from '../styles/BusMamiCss';
import PropTypes from 'prop-types';
import {paymentIcon} from '../BusImageConstants';

class PriceBreakUpCard extends React.Component {
  constructor(props) {
    super(props);
  }

  static navigationOptions = {
    header: null
  };

  render() {
    const paymentSummary = this.props.response.bookingPaymentDetail;
    const busBookingInfo = this.props.response.busBookingInfo;
    const styles = getStyles();
    return (
      <View style={[styles.MamiCard, styles.mar10Bt]}>
        <View style={styles.CardContent}>
          <Image
            style={styles.CardIcon}
            source={paymentIcon}
          />
          <View style={styles.ContentPortion}>
            <View>
              <View style={[styles.Paymentrow]}>
                <View style={styles.PaymentLeft}>
                  <Text style={[styles.BlackText, styles.blackFont]}>PRICE BREAKUP</Text>
                </View>
              </View>
              <View style={[styles.Paymentrow, styles.borderbtm, styles.padVertical10]}>
                <View>
                  <Text style={[styles.PaymentLeft]}>Base price for {busBookingInfo.noOfPassengers} travellers </Text>
                </View>
                <Text
                  style={styles.BlackText}
                >{`₹ ${paymentSummary.sellingPrice - paymentSummary.insuranceAmount} `}
                </Text>
              </View>
              <View
                style={[paymentSummary.insuranceAmount > 0 ? styles.showView : styles.hideView, styles.Paymentrow, styles.borderbtm, styles.padVertical10]}
              >
                <View>
                  <Text style={[styles.PaymentLeft]}>Insurance Amount</Text>
                </View>
                <Text
                  style={styles.BlackText}
                >{paymentSummary.insuranceAmount}
                </Text>
              </View>

              <View
                style={[paymentSummary.ecouponAmount > 0 ? styles.showView : styles.hideView, styles.Paymentrow, styles.borderbtm, styles.padVertical10]}
              >
                <View>
                  <Text style={[styles.PaymentLeft, styles.greenText]}>Coupon Discount</Text>
                </View>
                <Text
                  style={[styles.greenText, {marginRight: 4}]}
                >-&nbsp;{paymentSummary.ecouponAmount}
                </Text>
              </View>
              <View style={[styles.Paymentrow, styles.pad10Top]}>
                <View style={styles.PaymentLeft}>
                  <Text style={[styles.BlackText, styles.blackFont]}>Total Amount</Text>
                </View>
                <Text
                  style={[styles.BlackText, styles.blackFont, {marginRight: 4}]}
                >{paymentSummary.paymentAmount}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  }
  addWalletInfo(paymentDetail) {
    const views = [];
    paymentDetail.forEach((paymentDetails, index) => {
      if (paymentDetails.paymentGatewayCode === 'MYWALLET') { this.addWalletCard(views, paymentDetails, index); }
    });
    return views;
  }
  addWalletCard(views, paymentDetails, index) {
    const styles = getStyles();
    views.push(<View
      key={index}
      style={[styles.Paymentrow, styles.borderbtm, styles.padVertical10]}
    >
      <View>
        <Text style={[styles.PaymentLeft, styles.greenText]}>Wallet Amount</Text>
      </View>
      <Text
        style={[styles.greenText, {marginRight: 4}]}
      >-&nbsp;{paymentDetails.amount}
      </Text>
    </View>);
  }

  getWalletPaymentAmount(paymentDetail) {
    let walletAmount = 0;
    paymentDetail.forEach((paymentDetails) => {
      if (paymentDetails.paymentGatewayCode === 'MYWALLET') { walletAmount += paymentDetails.amount; }
    });
    return walletAmount;
  }
}
PriceBreakUpCard.propTypes = {
  response: PropTypes.object.isRequired
};

export default PriceBreakUpCard;
