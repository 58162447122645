import React, { useCallback } from 'react';
import { View, Text, Image } from 'react-native';
import { isNullOrEmpty } from "core-ui-lib/helper/Object/ObjectFunctions";
import { ONE_WAY_JRNY_ICON } from '../../../../../../FlightImageConstants';
import styles from '../PassengerSelection/PassengerSelectionCss';
import { imageTransformY } from '../../../../../../../utils/PostSaleUtil';
import { getStaticData } from '../../../../../../../staticData/staticData';
import { getFont } from '../../../../../../../PostSalesStyles';
import { addSegmentInfo } from '../../../../data/dataAdapter';

export const SegmentDetailsView = React.memo(
  ({ segment, destinationCity, stopovers, showStaticContentInEnglish }) => {
    const { fsStyle, ...fonts } = getFont(true);
    
    const getStopOver = useCallback((stopovers) => {
      const stopoversList = [];
      !isNullOrEmpty(stopovers) && stopovers.forEach((stop) => {
          stopoversList.push(
            <Text style={[styles.cityText, fonts.regularFontFamily, fsStyle.font14]}>{stop}</Text>,
          );
        });
      return stopoversList;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getFlightName = useCallback(
      (segmentName) => `${segmentName.flightDesignator.flightName}`,
      [],
    );

    let {
      flightAdditionalText: { flightWithStopText },
    } = getStaticData();
    if (showStaticContentInEnglish) {
      flightWithStopText =
        'Your journey has connecting flight with stop. The airline does not allow to cancel partial segment.';
    }
    return (
      <View key={segment.segmentLineNo}>
        <View style={[styles.destRow]}>
          <View style={[styles.textRow]}>
            <Text style={[styles.cityText, fonts.boldFontFamily, fsStyle.font16]}>
              {segment.originCity}
            </Text>
            <Image style={[styles.arrowIcon, imageTransformY]} source={ONE_WAY_JRNY_ICON} />
            {getStopOver(stopovers)}
            {!isNullOrEmpty(stopovers) && (
              <Image style={styles.arrowIcon} source={ONE_WAY_JRNY_ICON} />
            )}
            <Text style={[styles.cityText, fonts.boldFontFamily, fsStyle.font16]}>
              {destinationCity}
            </Text>
          </View>
          <View style={styles.airlinesIconWrapper}>
            <Image style={styles.airlineIcon} source={{ uri: segment.airlineLogoUrl }} />
          </View>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <Text style={[styles.smallText, fonts.semiBoldFontFamily, fsStyle.font10]}>
            {segment.flightTravelDateV2}
          </Text>
          <Text style={[styles.smallText, fonts.semiBoldFontFamily, fsStyle.font10]}>, </Text>
          <Text style={[styles.smallText, fonts.semiBoldFontFamily, fsStyle.font10]}>
            {getFlightName(segment)}
          </Text>
        </View>
        {!isNullOrEmpty(stopovers) && (
          <Text style={[styles.fltInfo, fonts.regularFontFamily, fsStyle.font12]}>
            {flightWithStopText}
          </Text>
        )}
      </View>
    );
  },
);

export const getAddSegmentInfo = (segmentGroup, showStaticContentInEnglish = false) => {
  const journeyList = addSegmentInfo(segmentGroup, true);
  return (
  journeyList && journeyList.map((segmentInfoList) => {
    return (
      <SegmentDetailsView
        segment={segmentInfoList.segment}
        destinationCity={segmentInfoList.destinationCity}
        stopovers={segmentInfoList.stopovers}
        showStaticContentInEnglish={showStaticContentInEnglish}
      />
    );
  })
  );
};

export default SegmentDetailsView;
