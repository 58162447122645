import React, { useEffect, useRef, useState } from "react";
import { View, TouchableOpacity, findNodeHandle } from "react-native";
import styles from "./NewCommonOverlayCss";

const NewCommonOverlay = ({
  setVisible,
  children,
  styleContent = {},
  visible,
  customStyles,
  bottomSheetVariant,
}) => {
  const { containerStyle } = customStyles || {};
  const [overflowBehavior, setOverflowBehavior] = useState("scroll");
  const contentRef = useRef(null);

  useEffect(() => {
    const bodyEle = document.getElementsByTagName("body")[0];
    const initOverflow = bodyEle.style.overflow;
    bodyEle.style.overflow = "hidden";
    return () => {
      bodyEle.style.overflow = initOverflow;
    };
  }, []);

  useEffect(() => {
    if (contentRef.current) {
      const node = findNodeHandle(contentRef.current);
      if (node) {
        const element = node instanceof HTMLElement ? node : document.getElementById(node);
        if (element) {
          const { scrollHeight, clientHeight } = element;
          if (scrollHeight > clientHeight) {
            setOverflowBehavior("scroll");
          } else {
            setOverflowBehavior("visible");
          }
        }
      }
    }

  }, [children]);

  if (!visible) return null;

  return (
    <View style={[styles.overlayWrapper]}>
      <TouchableOpacity
        onPress={() => setVisible && setVisible(!visible)}
        activeOpacity={1}
        style={styles.overlay}
      />
      <View
        ref={contentRef}
        style={[
          styles.overlayContent,
          styles.roundBorder,
          styleContent,
          bottomSheetVariant === "default" ? containerStyle : {},
          overflowBehavior === "visible"
            ? styles.overflowVisible
            : styles.overflowScroll,
        ]}
      >
        {children}
      </View>
    </View>
  );
};

export default NewCommonOverlay;
