import React, { memo } from 'react';
import SimpleHeader from '@mmt/legacy-commons/Common/Components/Header/SimpleHeader';
import Breadcrumb from '../../Breadcrumb/index';

const AncillaryClaimHeader = ({ onBackPress, subtitle, title, activeStep, steps }) => {
  return (
    <React.Fragment>
      <SimpleHeader
        title={title}
        subtitle={{ text: subtitle ? subtitle[0] : null }}
        iconPress={onBackPress}
      />
      <Breadcrumb activeStep={activeStep} data={steps} />
    </React.Fragment>
  );
};

export default memo(AncillaryClaimHeader);
