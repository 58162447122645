import React, { Fragment } from 'react';
import _, { isEmpty } from 'lodash';
import { Image, StyleSheet, Text, View } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import Actions from '../../../navigation/postSalesNavigation';
import getStyles from '../../styles/BusMamiCss';
import {
  cancellationStyles
} from '../../../utils/CancellationPolicyCardUtils';
import {
  getActionButton,
  getButtonStyle,
  isNotNullAndEmpty
} from '../../utils/BusBookingDetailUtil';
import BusBookingDetailsConstant from '../../BusBookingDetailsConstant';
import BannerCard from "../../common/BannerCard";
import { BACK_ARROW, refundIcon } from '../../BusImageConstants';

function TripPassCard(props) {

  function handleClick() {
    Actions.openPsWebView({
      url: props.response.tripPass.url,
      headerText: BusBookingDetailsConstant.TRIP_ASSURANCE_TnC,
      headerIcon: BACK_ARROW
    });
  }

  function getFooterViewsList() {
    let viewsList = [];

    props.response.tripPass.tripPassCardFooter.map(footer => {
      viewsList.push(
        <View style={([AtomicCss.defaultTextColor, AtomicCss.lineHeight16], styles1.footer)}>
          <Text>&#9679;  </Text><Text>{footer}</Text>
        </View>
      )
    });

    return viewsList;
  }

  return (
    <Fragment>
      <View style={styles1.CardWrapper}>
        <View style={props.disableCard ? [cancellationStyles.CancellationPolicyCardWrapper, cancellationStyles.disabledCard] : [cancellationStyles.CancellationPolicyCardWrapper]}>
          <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween, AtomicCss.paddingBottom5]}>
            <Image style={styles1.imageIcon} source={refundIcon} />
            <View style={AtomicCss.flex1}>
              <Text style={[AtomicCss.blackFont, AtomicCss.blackText, AtomicCss.font16]}>
                {props.card.heading}
              </Text>
            </View>
          </View>
          <View style={[AtomicCss.paddingBottom8, AtomicCss.paddingTop5, styles1.infoDtlsSection]}>
            <Text style={[AtomicCss.defaultTextColor, AtomicCss.lineHeight18, styles1.marginLeft15]}>
              {props.card.subheading}
            </Text>
            <View style={[{marginLeft: 5}]}>
              {!isEmpty(props.response.tripPass) && <Text style={[styles1.CardHeader, AtomicCss.boldFont]}>{props.response.tripPass.tripPassCardHeader}</Text>}
              {!isEmpty(props.response.tripPass) && getFooterViewsList()}
            </View>
          </View>
          {!isEmpty(props.card.bannerMessage) && <BannerCard
            yellowBg={true}
            bannerText={props.card.bannerMessage} />
          }
        </View>
        <View style={styles1.CardContent}></View>
        {
          isNotNullAndEmpty(props.card.actionList) && 
          <View style={[getButtonStyle(props.card.actionList), styles.transperantBg]}>
            {getActionButton(props.card, props.response, handleClick)}
          </View>
        }
      </View>
    </Fragment>
  );
}
const styles1 = StyleSheet.create({
  headingIconWrapper: {
    width: 46,
    alignItems: 'flex-start',
    top: 14,
    left: 16
  },
  imageIcon: { width: 24, height: 24, marginRight: 18 },
  footer: { flexDirection: 'row', marginTop: 10 },
  infoDtlsSection: {
    paddingLeft: 30,
    marginBottom: 1
  },
  infoDtlsSectionTracker: {
    paddingLeft: 10,
    marginBottom: 0
  },
  marginLeft15: { marginLeft: 15 },
  CardContent: {
    backgroundColor: '#fff',
    borderBottomWidth: 1,
    borderBottomColor: '#e4e4e4',
  },
  CardWrapper: { marginBottom: 10, marginTop: 10 },
  CardHeader: { fontSize: 14, color: '#000', flex: 1, marginTop: 20 }
});

export default TripPassCard;
