import { StyleSheet } from 'react-native';

const createStyles = ( theme, fonts) => {
  return StyleSheet.create({
    tripGuaranteeCard: {
      backgroundColor: theme.color.cyanColor,
      padding: 10,
    },
    priceContainerStyle:{
      top:7
    },
    tripWhiteCard: {
      borderRadius: 5,
      backgroundColor: theme.color.white,
    },
    headingSection: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: 12,
      borderBottomWidth: 1,
      borderBottomColor: theme.color.grayBg,
    },
    headingText: {
      color: theme.color.black22,
      fontWeight: 'bold',
      ...fonts.boldFontFamily,
      ...fonts.fsStyle.font20
    },
    tgIconStyle: {
      width: 46,
      height: 30,
      marginRight: 10,
    },
    detailsSection: {
      padding: 12,
    },
    highlighterBar:{
      borderColor: theme.color.darkPurple,
      borderWidth: 2.3,
      height:20,
      position:'absolute',
      top:12
    },
    infoCardSection: {
      flexWrap: 'wrap',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    infoCards: {
      backgroundColor: theme.color.black2,
      padding: 12,
      borderRadius: 8,
      marginTop: 16
    },
    infoCardsWidth: {
      width: '48%',
    },
    iconStyles: {
      width: 24,
      height: 24,
    },
    lobIcon: {
      width: 22,
      height: 22,
      justifyContent: 'center',
      alignItems: 'center',
    },
    tripRefundInfo: {
      ...fonts.boldFontFamily,
      color: theme.color.black,
      ...fonts.fsStyle.font14
    },
    refundPriceText: {
     ...fonts.boldFontFamily,
      color: theme.color.lightGreen4,
    },
    btnSection: {
      flexDirection: 'row',
      paddingHorizontal: 12,
      paddingVertical: 15,
      justifyContent: 'space-between',
    },
    pricePerPersonText: {
      ...fonts.fsStyle.font12,
      textAlign: 'right',
     ...fonts.boldFontFamily,
      color: theme.color.black,
    },
  });
};


export const createHtmlStyle = ( theme, fonts) => {
    return StyleSheet.create({
      q: {
        /* fix fo Html Fonts Not working without adding blank tag */
      },
      span:{
        ...fonts.boldFontFamily,
        color: theme.color.lightGreen4,
      },
      p: {
        color: theme.color.black,
        ...fonts.fsStyle.font14
      }, 
      b:{
        color: theme.color.black,
        ...fonts.boldFontFamily,
        fontWeight:'bold'
      },
      small:{
        ...fonts.fsStyle.font12
      },
      large:{
        ...fonts.fsStyle.font20
      },
    })
  }

export default createStyles;