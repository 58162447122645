const CAB_ROUTE_CONFIG = [
    {
        key: 'cabPaymentBreakup',
        component: () => require('./components/CabPaymentDetails').default
    },
    {
        key: 'cabCancellationDetails',
        component: () => require('./components/CabCancellationDetails').default
    },
    {
        key: 'cabModifyBooking',
        component: () => require('./components/cancelAndRebook/CabModifyBooking').default
    },
    {
        key: 'carCancellationPoilcyRule',
        component: () => require('./components/CarCancellationPolicyRules').default
    },
    {
        key: 'cabTermsAndConditions',
        component: () => require('./components/CabTermsAndConditions').default
    },
    {
        key: 'cabInvoiceDetails',
        component: () => require('./components/CabInvoiceDetails').default
    },
    {
        key: 'cabChangePickup',
        component: () => require('./components/CabAmendments/CabChangePickupDetails').default
    },
    {
        key: 'cabCancellationDetailsStandAlone',
        component: () => require('./components/CabCancellationDetailsStandAlone').default
    },
    {
        key: 'carSupportIssueSelect',
        component: () => require('./components/Support/IssueSelection').default
    },
    {
        key: 'cabDriverDetails',
        component: () => require('./components/CabDriverDetails').default
    },
    {
        key: 'cabBookingDetail',
        component: () => require('./CabBookingDetailWrapper').default
    },
    {
        key: 'carSupportIssueSubmit',
        component: () => require('./components/Support/IssueSubmit').default
    },
    {
        key: 'CancellationRefundMode',
        component: () => require('./components/CancellationRefundMode').default
    },
    {
        key: 'CabCancelRequest',
        component: () => require('./components/CabCancelRequest').default
    },
    {
        key: 'carSupportIssueDetail',
        component: () => require('./components/Support/IssueDetail').default
    },
    {
        key: 'cabDetailReview',
        component: () => require('./components/cancelAndRebook/CabDetailReview').default
    },
    {
        key: 'cabReviewAmendment',
        component: () => require('./components/CabAmendments/CabReviewAmendment').default
    },
    {
        key: 'cabCommitAmendment',
        component: () => require('./components/CabAmendments/CabCommitAmendment').default
    },
    {
        key: 'searchLocation',
        component: () => require('./components/cancelAndRebook/SearchLocation').default
    },
    {
        key: 'changeDate',
        component: () => require('./components/cancelAndRebook/ChangeDate').default
    },
    {
        key: 'cabUpgrade',
        component: () => require('./components/cancelAndRebook/CabUpgrade').default
    },
    {
        key: 'cabCancelRebookPriceBreakup',
        component: () => require('./components/cancelAndRebook/CanCancelRebookPriceBreakup').default
    },
    {
        key: 'cabDateChangeDetails',
        component: () => require('./components/CabDateChangeDetails').default
    },
    {
        key: 'cabAmendFullScreen',
        component: () => require('./components/CabAmendments/CabAmendFullScreenPage').default
    },
    // {
    //     key: 'cabspecialclaim',
    //     component: () => require('src/Routes/postsales/cab/modules/specialclaim').default
    // },
    {
        key: 'cabspecialclaimoperator',
        component: () => require('./modules/specialclaim/pages/operator').default
    },
    {
        key: 'cabspecialclaimoperatorreview',
        component: () => require('./modules/specialclaim/pages/operatorreview').default
    },
    {
        key: 'cabspecialclaimthankyou',
        component: () => require('./modules/specialclaim/pages/thankyou').default
    },
    {
        key: 'openCabReschedulePage',
        component: () => require('./modules/cabReschedule').default
    },
]


export default CAB_ROUTE_CONFIG;
