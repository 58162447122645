import React from 'react';
import PropTypes from 'prop-types';
import {View, Text, Image} from 'react-native';
import styles from '../../CSS/VisaMamiCss';
import {fillDateAndTime, findDaysBetweenDates} from '@mmt/legacy-commons/Common/utils/DateUtils';
import isEmpty from 'lodash/isEmpty';


class VisaMainCard extends React.Component {
    static navigationOptions = {
      header: null
    };

    render() {
      return (
        <View style={[styles.MamiCard, styles.mar10Bt]}>
          <View style={styles.CardContentFull}>
            <View style={[styles.flexRow, styles.mar22Bt]}>
              {!isEmpty(this.props.visaCountry) && <View style={styles.VisaMainInfo}>
                <Text style={[styles.boldFont, styles.font18, styles.BlackText]} >
                  {this.props.visaCountry}
                </Text>
              </View>}
              {!isEmpty(this.props.countryURL) && <Image style={styles.FlagImg} source={{uri: this.props.countryURL}} />}
            </View>
            {!isEmpty(this.props.visaDescription) && <View style={styles.VisaRow}>
              <Text style={[styles.mar10Rt, styles.font12, styles.darkGrey, styles.regularFont]}>VISA</Text>
              <Text style={[styles.font12, styles.BlackText, styles.blackFont]}>{this.props.visaDescription}</Text>
            </View>}
            {!isEmpty(this.props.validity) && <View style={[styles.VisaRow, styles.paddingTop12]}>
              <Text style={[styles.mar10Rt, styles.font12, styles.darkGrey, styles.regularFont]}>VALIDITY</Text>
              <Text style={[styles.font12, styles.BlackText, styles.blackFont, styles.mar10Rt]}>{this.props.validity}</Text>
              <Text style={[styles.mar10Rt, styles.font12, styles.darkGrey, styles.regularFont]}>(from the date of issue)</Text>
            </View>}
            {!isEmpty(this.props.applicants) && <View style={[styles.VisaRow, styles.paddingTop12]}>
              <Text style={[styles.mar10Rt, styles.font12, styles.darkGrey, styles.regularFont]}>APPLICANTS</Text>
              <Text style={[styles.font12, styles.BlackText, styles.blackFont]}>{this.props.applicants} </Text>
            </View>}
            {!isEmpty(this.props.dateofEntry) && !isEmpty(this.props.dateofExit) && <View style={[styles.paddingTop12, styles.flexRow, styles.mar5Bt]}>
              <Text style={[styles.mar10Rt, styles.font12, styles.darkGrey, styles.regularFont]}>TRAVEL</Text>
              <Text style={[styles.font12, styles.BlackText, styles.blackFont]}> {fillDateAndTime(this.props.dateofEntry, 'DD MMM')} {"'"}{fillDateAndTime(this.props.dateofEntry, 'YYYY').substr(2, 2)} - {fillDateAndTime(this.props.dateofExit, 'DD MMM')} {"'"}{fillDateAndTime(this.props.dateofEntry, 'YYYY').substr(2, 2)} </Text>
              <Text style={[styles.mar10Rt, styles.font12, styles.darkGrey, styles.regularFont]}>({findDaysBetweenDates(this.props.dateofEntry, this.props.dateofExit)} days)</Text>
            </View>}
          </View>
        </View>
      );
    }
}


VisaMainCard.propTypes = {
  visaDescription: PropTypes.string.isRequired,
  validity: PropTypes.string.isRequired,
  applicants: PropTypes.number.isRequired,
  visaCountry: PropTypes.string.isRequired,
  countryURL: PropTypes.string.isRequired,
  dateofEntry: PropTypes.string.isRequired,
  dateofExit: PropTypes.string.isRequired
};


export default VisaMainCard;
