import { StyleSheet } from 'react-native';

export default (theme) =>
  StyleSheet.create({
    crossSellCard: {
      backgroundColor: theme.color.yellow7,
      borderRadius: 8,
      margin: 10,
    },
    innerDetails: {
      padding: 16,
    },
  });
