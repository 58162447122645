import React from 'react';
import { View, Text, Image,TouchableOpacity } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import ModifyYourBookingPopup from './ModifyYourBookingPopup'
import OtherCancellationReason from './OtherCancellationReason'
import AlternativeReasonFlowPopup  from './AlternativeReasonFlowPopup'
import { CROSS_ICON1 } from '../../../HotelImageConstants';
import { isNull } from 'lodash';
import getStyles from './bottomSheet.css'
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const CancelDateChangeReason = ({ handleAlternateFlowClick, setReason, theme,handleClick, cancellationReason , hotelDetailData, dismiss,isCorporateTheme }) => {
    const { ...fonts } = getFont(true);
    const styles = getStyles(fonts);
    const { modalData = {}, showTextBox, mandateText } = cancellationReason;
    const {details, heading, defaultCTA, ctaList} = modalData || {};
    const {heading: detailHeading, cta} = details || {}
     
    return (
       <View>
            <View style={[AtomicCss.marginBottom20]}>
                <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween]}>
                    {
                    heading && 
                    <Text style={[styles.title]}>
                        {heading}
                    </Text>
                  }
                  <TouchableOpacity style={styles.crossWrapper} onPress={()=>{dismiss && dismiss()}}>
                      <Image style={styles.closeIcon} source={CROSS_ICON1} />
                  </TouchableOpacity>
                   
                </View>
                
                {showTextBox && cta && mandateText && <OtherCancellationReason
                    mandateText={mandateText}
                    cta={cta}
                    setReason={setReason}
                    handleClick={handleClick}
                    isCorporateTheme={isCorporateTheme}
                /> }
                {!showTextBox && !isNull(ctaList) && defaultCTA && <ModifyYourBookingPopup 
                    handleAlternateFlowClick={handleAlternateFlowClick}
                    ctaList = {ctaList}
                    handleClick={handleClick}
                    defaultCTA={defaultCTA}
                /> }
                {!showTextBox && isNull(ctaList) && defaultCTA && cta && detailHeading && <AlternativeReasonFlowPopup
                    handleAlternateFlowClick={handleAlternateFlowClick}
                    handleClick={handleClick}
                    defaultCTA={defaultCTA}
                    cta={cta}
                    theme={theme}
                    detailHeading = {detailHeading}
                    hotelDetailData = {hotelDetailData}
                    isCorporateTheme={isCorporateTheme}
                />}
            </View>
        </View>

    )
};

export default CancelDateChangeReason;