import React, { useState } from 'react';
import { View, Image, Text, NativeSyntheticEvent, ImageLoadEventData, Platform } from 'react-native';
import { StyleSheet } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import { StepData } from 'apps/post-sales/src/cab/types';

interface KioskStepProps {
  item: StepData;
  isLastRow: boolean;
  id: number;
}

export const KioskStep: React.FC<KioskStepProps> = ({ item, isLastRow, id }) => {
	const { heading, subHeading, imageUrl } = item || {};
	const [aspectRatio, setAspectRatio] = useState<number | null>(null);

	const handleImageLoad = (event: NativeSyntheticEvent<ImageLoadEventData>) => {
		if(Platform.OS !== 'web') {
			const { width, height } = event.nativeEvent.source;
			setAspectRatio(width / height);
		}
  };

	return (
		<View style={[AtomicCss.flexRow, AtomicCss.marginBottom15]}>
			<View style={styles.countWpr}>
				<Text
					style={[
						AtomicCss.defaultText,
						AtomicCss.regularFont,
						AtomicCss.font14,
						AtomicCss.marginBottom5,
					]}
				>
					{id}
				</Text>
			</View>
			<View style={[styles.kioskStepSection, isLastRow ? styles.lastSectionStyle : {}]}>
				<Text
					style={[
						AtomicCss.blackText,
						AtomicCss.boldFont,
						AtomicCss.font16,
						AtomicCss.marginBottom5,
					]}
				>
					{heading}
				</Text>
				<Text
					style={[
						AtomicCss.defaultText,
						AtomicCss.regularFont,
						AtomicCss.font14,
						AtomicCss.marginBottom5,
						AtomicCss.lineHeight20
					]}
				>
					{subHeading}
				</Text>
				<View style={styles.imageContainer}>
					<Image
						style={[styles.kioskImageStyle, aspectRatio ? { aspectRatio } : null, Platform.OS === 'web' ? {height: 245} : {}]}
						source={{uri: imageUrl}}
						resizeMode="contain"
						onLoad={handleImageLoad}
					/>
				</View>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	lastSectionStyle: {
		marginBottom: 0,
		borderBottomWidth: 0,
		borderBottomColor: 'transparent',
	},
	countWpr: {
		backgroundColor: colors.grayBg,
		fontSize: 14,
		borderRadius: 8,
		alignItems: 'center',
		justifyContent: 'center',
		alignSelf: 'flex-start',
		width: 30,
		height: 29,
		marginRight: 15,
	},
	kioskImageStyle: {
		width: '100%',
		borderRadius: 20,
	},
	imageContainer: {
		flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
	},
	kioskStepSection: {
		paddingBottom: 12,
		borderBottomWidth: 1,
		borderBottomColor: colors.gray3,
		flex: 1,
	},
});
