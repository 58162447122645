import {
  ACTIONS_RAILS_PNR_LISTING_EMPTY,
  FETCH_ACTIONS_RAILS_PNR_STATUS,
  ACTIONS_RAILS_PNR_LISTING_SET_BOTTOM_LOADER,
  ACTIONS_RAILS_PNR_LISTING_SET_ERROR,
  ACTIONS_DESTORY_SESSION,
  ACTIONS_UPDATE_LATEST_TRAIN_DELAY_INFO,
} from '../RailAction/RailInfoActionService';

const initialStates = {
  showPnrStatusLodar: false,
  passengerDetails: {},
  allowRefresh: true,
};

export default (state = initialStates, action) => {
  switch (action.type) {
    case ACTIONS_DESTORY_SESSION:
      return initialStates;

    case FETCH_ACTIONS_RAILS_PNR_STATUS:
      const {
        passengerDetails,
        chartStatus,
        showPnrStatusLodar,
        lastUpdateTime,
        allowRefresh,
        lastbookingId,
      } = action.data;
      return {
        ...state,
        passengerDetails,
        chartStatus,
        showPnrStatusLodar,
        lastUpdateTime,
        allowRefresh,
        lastbookingId,
      };

    case ACTIONS_RAILS_PNR_LISTING_SET_BOTTOM_LOADER:
    case ACTIONS_RAILS_PNR_LISTING_SET_ERROR:
      return {
        ...state,
        showPnrStatusLodar,
      };
    case ACTIONS_RAILS_PNR_LISTING_EMPTY:
      return {
        ...state,
        passengerDetails: '',
        chartStatus: '',
        showPnrStatusLodar,
        lastUpdateTime: '',
      };
    case ACTIONS_UPDATE_LATEST_TRAIN_DELAY_INFO:
        const latestTrainData = action.data;
        return {
            ...state,
            latestTrainData
        }
    default:
      return state;
  }
};
