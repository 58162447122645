import React from 'react';
import { View, Text, Image, StyleSheet } from 'react-native';
import { isEmpty } from 'lodash';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import BasePage from '../../../../../../Common/PostSalesBasePage';
import FlightConst from '../../../FlightBookingDetailsConstant';
import {
  MALE_ICON,
  FEMALE_ICON,
  CHILD_ICON,
  INFANT_ICON,
  DOUBLE_SEAT_ICON,
} from '../../../../../FlightImageConstants';
import { getStaticData } from '../../../../../../staticData/staticData';
import { getFont } from '../../../../../../PostSalesStyles';
import { ThemeContext } from '../../../../../../theme/theme.context';
import { getTranslatedTextFor } from '../../../../../../utils/PostSaleUtil';
import WheelchairInfoMessages from '../../WheelChairPopup/wheelChairInfoMsg';
import { ANCILLARY_TYPE } from '../../../../AncillaryModule/constants';

class TravllerInfoCard extends BasePage {

  static contextType = ThemeContext;

  getPaxIcon(paxtype, gender, flightInfo) {
    if (
      !isEmpty(flightInfo) &&
      !isEmpty(flightInfo.tag) &&
      flightInfo.tag.type === FlightConst.CANCEL
    ) {
      switch (paxtype.toLocaleLowerCase()) {
        case 'adult':
          if (gender === 'Male') {
            return MALE_ICON;
          }
          return FEMALE_ICON;
        case 'child':
          return CHILD_ICON;
        case 'infant':
          return INFANT_ICON;
      }
    } else {
      switch (paxtype.toLocaleLowerCase()) {
        case 'adult':
          if (gender === 'Male') {
            return MALE_ICON;
          }
          return FEMALE_ICON;
        case 'child':
          return CHILD_ICON;
        case 'infant':
          return INFANT_ICON;
      }
    }
  }
  render() {
    const { fsStyle, ...fonts } = getFont(true);
    const { psTheme : theme } = this.context;
    const styles = createStyles(theme);
    const failed = this.props.isSegmentFailed;
    const failedWrapper =
    !isEmpty(this.props.flightInfo) & !isEmpty(this.props.flightInfo.tag)   &&
      this.props.flightInfo.tag.type === FlightConst.CANCEL
        ? [styles.failedWrapper]
        : '';
    const { travellersText, pnrText, eTicketNoText, pnrAndEticketNoText, seatsHeadingText, excessBaggageText, cancelledText } = getStaticData();
    return (
      <React.Fragment>
        <View
          style={[styles.tvlrInfoWrapper, failedWrapper]}
          onLayout={({ nativeEvent }) => {
            this.props.updateFlightLayout(
              nativeEvent.layout.y,
              FlightConst.FLIGHT_SEGMENT_TRAVELLER_HEIGHT,
              this.props.layoutKey,
            );
          }}
        >
          <View style={styles.topSection}>
            <Text
              style={[
                styles.headingTxt,
                fonts.boldFontFamily,
                fsStyle.font11,
                styles.trvlrNameSectionWidth,
              ]}
            >
              {travellersText}
            </Text>
            <Text
              style={[
                styles.headingTxt,
                fonts.boldFontFamily,
                fsStyle.font11,
                styles.pnrSectionWidth,
                failed && AtomicCss.alignRight
              ]}
            >
              {pnrAndEticketNoText}
            </Text>
            {!failed && (
              <Text
                style={[
                  styles.headingTxt,
                  fonts.boldFontFamily,
                  fsStyle.font11,
                  styles.seatSectionWidth,
                ]}
              >
                {seatsHeadingText}
              </Text>
            )}
            {!failed && !!this.props.purchasedBaggageInfo && (
              <Text
                style={[
                  styles.headingTxt,
                  fonts.boldFontFamily,
                  fsStyle.font11,
                  styles.excessBaggageSectionWidth,
                ]}
              >
                {excessBaggageText}
              </Text>
            )}
          </View>

          {this.props.travellerInfo &&
            this.props.travellerInfo.map((item) => (
              <View style={[ AtomicCss.flexColumn, AtomicCss.marginBottom15]}>
                <View style={styles.paxInfoWrapper}>
                  <View style={[styles.paxInfoColumnWrapper, styles.trvlrNameSectionWidth]}>
                    {!isEmpty(item?.name?.value) && (
                      <Text
                        style={[
                          fonts.boldFontFamily,
                          fsStyle.font12,
                          styles.psngItemTxt
                        ]}
                      >
                        {item.name.value}
                      </Text>
                    )}
                    {!isEmpty(item?.fareTypeId) && (
                      <Text
                        style={[
                          fsStyle.font12,
                          fonts.regularFontFamily,
                          styles.fareTypeId
                        ]}
                      >
                        {item.fareTypeId}
                      </Text>
                    )}
                    {!this.props.isAllCancelled && !failed && !isEmpty(item?.meal?.value) && item?.meal?.value !== '-' && (
                      <Text
                        style={[
                          fonts.regularFontFamily,
                          fsStyle.font12,
                          styles.cancellationTxt
                        ]}
                      >
                        {`${getTranslatedTextFor(ANCILLARY_TYPE[0])} ${item.meal.value}`}
                      </Text>
                    )}
                    {!isEmpty(item?.cancellationReasonText) && (
                      <Text
                        style={[
                          fsStyle.font14,
                          fonts.regularFontFamily,
                          styles.cancellationTxt
                        ]}
                      >
                        ({item.cancellationReasonText})
                      </Text>
                    )}
                  </View>
                  {(!isEmpty(item?.pnr?.value) || !isEmpty(item?.eTicket?.value)) && (
                    <View style={[styles.paxInfoColumnWrapper, styles.pnrSectionWidth]}>
                      {!isEmpty(item?.pnr?.value) && (
                        <Text
                          style={[
                            fsStyle.font12,
                            fonts.boldFontFamily,
                            styles.psngItemTxt,
                            failed && AtomicCss.alignRight
                          ]}
                        >
                          {item.pnr.value}
                        </Text>
                      )}
                      {!isEmpty(item?.eTicket?.value) && (item?.pnr?.value !== item.eTicket.value) && (
                        <Text
                          style={[
                            fonts.regularFontFamily,
                            fsStyle.font12,
                            styles.eTicketTxt,
                            failed && AtomicCss.alignRight
                          ]}
                        >
                          {item.eTicket.value}
                        </Text>
                      )}
                    </View>
                  )}
                  {!failed && !isEmpty(item?.seat?.value) && (
                    <View style={[styles.paxInfoColumnWrapper, styles.seatSectionWidth]}>
                      <Text
                        style={[
                          fonts.boldFontFamily,
                          fsStyle.font12,
                          styles.psngItemTxt
                        ]}
                      >
                        {item.seat.value}
                      </Text>
                    </View>
                  )}
                  {!failed && !isEmpty(item?.excessBaggage?.value) && !isEmpty(this.props.purchasedBaggageInfo) && (
                    <View style={[styles.paxInfoColumnWrapper, styles.excessBaggageSectionWidth]}>
                      <Text
                        style={[
                          fsStyle.font12,
                          fonts.boldFontFamily,
                          styles.psngItemTxt
                        ]}
                      >
                        {item.excessBaggage.value}
                      </Text>
                    </View>
                  )}
                </View>
              {!isEmpty(item?.wheelchair) && <WheelchairInfoMessages status={item.wheelchair?.status} description={item.wheelchair?.description}/>}
               </View>
            ))}
          {!!this.props.seatMessage && (
            <View style={[styles.doubleSeatWrapper, AtomicCss.marginBottom10]}>
              <View style={[styles.doubleSeatRow, AtomicCss.flexRow]}>
                <Image style={styles.dbSeatIcons} source={DOUBLE_SEAT_ICON} />
                <Text
                  style={[
                    fsStyle.font12,
                    fonts.regularFontFamily,
                    styles.seatMsgTxt
                  ]}
                >
                  {this.props.seatMessage}
                </Text>
              </View>
            </View>
          )}
        </View>
      </React.Fragment>
    );
  }
}

export default TravllerInfoCard;

const createStyles = (theme) => StyleSheet.create({

  psngItemTxt:{
    color: theme.color.black,
    marginBottom: 5,
    textAlign: 'left',
    flexWrap: 'wrap'
  },
  fareTypeId:{
    flexWrap: 'wrap',
    color: theme.color.black74
  },
  cancellationTxt: {
    flexWrap: 'wrap',
    color: theme.color.grey
  },
  eTicketTxt:{
    flexWrap: 'wrap',
    textAlign: 'left',
    color: theme.color.grey,
  },
  seatMsgTxt:{
    color: theme.color.grey,
    flex: 1
  },
  tvlrInfoWrapper: {
    backgroundColor: '#f2f2f2',
    borderRadius: 4,
    paddingHorizontal: 8,
    paddingVertical: 12,
    marginTop: 15,
    overflow: 'hidden'
  },
  lftSection: {
    flexDirection: 'column',
  },
  topSection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  headingTxt: {
    color: '#747474',
    marginBottom: 15,
    marginRight: 5,
    flexWrap: 'wrap'
  },
  paxInfoWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  paxInfoColumnWrapper: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginRight: 5,
  },
  paxIconStyle: {
    width: 16,
    height: 16,
    alignSelf: 'flex-start',
    marginRight: 12,
  },
  trvlrNameSectionWidth: {
    width: '30%'
  },
  pnrSectionWidth: {
    width: '27%',
  },
  seatSectionWidth: {
    width: '13%'
  },
  excessBaggageSectionWidth: {
    width: '27%'
  },
  pnrSectionInnerDtls: {
    paddingRight: 20,
    alignSelf: 'flex-end',
  },
  pnrHeadingWrapper: {
    width: '30%',
    paddingRight: 20,
    justifyContent: 'flex-end',
    alignSelf: 'flex-start',
  },
  failedWrapper: {
    backgroundColor: '#ffd3d4',
  },
  dbSeatIcons: {
    width: 18,
    height: 18,
    marginTop: 2,
    marginRight: 10,
  },
});
