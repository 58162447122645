import React from 'react';
import { Text, TouchableWithoutFeedback, View } from 'react-native';
import { getFont } from '../../../../../../../PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import { getStaticData } from '../../../../../../../staticData/staticData';
import { addSpaceWithAmount } from '../../../../../../../utils/PostSaleUtil';
import { getInstantRefundGradient } from '../../../../../../../Common/commonUtil';
import LinearGradient from 'react-native-linear-gradient';
import RadioButton from '../../../../../../../Common/RadioButton';
import createStyles from './styles';
import isEmpty from 'lodash/isEmpty';


const RefundModeList = ({ refundModes, handleChange, instantData, radiobtn, refundAmount }) => {
    const { instantRefundText } = getStaticData();
    const { psTheme: theme } = useTheme();
    const { fsStyle, ...fonts } = getFont(true);
    const styles = createStyles(theme, fonts);

    const addSubText = (subTitleList) => {
        const views = [];
        subTitleList.forEach((subtitle) => {
            views.push(<Text style={[fsStyle.font13, styles.AlignLeft]}>{subtitle}</Text>);
        });
        return views;
    }

    return (
        refundModes.map((refundModeObj) => {
            const modeName = refundModeObj.refundModeReason;
            return (
                <TouchableWithoutFeedback onPress={() => handleChange(refundModeObj)}>
                    <View style={styles.refundCardOuter}>
                        <View style={[styles.flexRow]}>
                            {isEmpty(refundModeObj.accountNo) && (
                                <RadioButton
                                    selected={radiobtn === modeName && !instantData}
                                    onChange={() => handleChange(refundModeObj)}
                                />
                            )}
                            {!isEmpty(refundModeObj.accountNo) && (
                                <RadioButton
                                    selected={radiobtn === modeName && instantData && instantData.accountNo === refundModeObj.accountNo}
                                    onChange={() => handleChange(refundModeObj)}
                                />
                            )}
                            <View style={{ width: '90%' }}>
                                <View style={styles.refundCardWrap}>
                                    <View style={styles.refundCardLeft}>
                                        <Text
                                            style={[
                                                fsStyle.font14,
                                                fonts.boldFontFamily,
                                                styles.BlackText,
                                                styles.AlignLeft,
                                            ]}
                                        >
                                            {refundModeObj.title}
                                        </Text>
                                        {addSubText(refundModeObj.subText)}
                                    </View>
                                    <Text
                                        style={[
                                            styles.BlackText,
                                            fonts.boldFontFamily,
                                            fsStyle.font16,
                                            styles.AlignRight,
                                        ]}
                                    >
                                        {' '}
                                        {addSpaceWithAmount(!!refundModeObj.amount ? refundModeObj.amount : refundAmount)}
                                    </Text>
                                </View>
                            </View>
                        </View>
                        {!isEmpty(refundModeObj.accountNo) && (
                            <View style={{ paddingLeft: 32, paddingTop: 10 }}>
                                <LinearGradient
                                    start={{ x: 1.0, y: 0.0 }}
                                    end={{ x: 0.0, y: 1.0 }}
                                    colors={getInstantRefundGradient()}
                                    style={styles.instantRefundTag}
                                >
                                    <Text style={styles.instantRefundTagTxxt}>{instantRefundText}</Text>
                                </LinearGradient>
                            </View>
                        )}
                    </View>
                </TouchableWithoutFeedback>
            )
        })
    )
}

export default RefundModeList;