
import React, { useCallback, useEffect, useRef } from 'react';
import { Image, StyleSheet, Text, View, ScrollView } from 'react-native';
import Button from '@mmt/legacy-commons/Common/Components/Buttons/RecGradientBtn';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import CommonBottomOverlayMessage from '../../../Common/CommonOverlayMessage/CommonBottomOverlayMessage';
import { resetToHotelDetail } from '../../details/utils/HotelBookingDetailUtil';
import { PRICE_CHANGE, SOLD_OUT } from '../../HotelImageConstants';
import { isEmpty } from 'lodash';
import { getStaticData } from '../../../staticData/staticData';
import HotelBookingTrackingHelper from '../../HotelBookingTrackingHelper';
import { PROCEED_CLICK } from '../HotelModificationConstants';
import { LANG_AWARE } from '../../HotelConstants';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const CHANGE_TYPE = {
    SOLD_OUT: 2,
    CHANGED: 1
}

const ChangeInReviewPopup = ({ bookingId, header, newMealPlan, newPolicy, type = CHANGE_TYPE.SOLD_OUT, ctaText, dismiss, handleCTAClick, availUrl, pageName, hotelDetailsResponse, overlayType }) => {
    const { fsStyle, ...fonts } = getFont(true);
    const styles = getStyles(fonts);
    useEffect(() => {
        HotelBookingTrackingHelper.trackShowEvent(pageName, `${overlayType}Shown`, hotelDetailsResponse);
    }, []);

    const iconUrl = type == CHANGE_TYPE.SOLD_OUT ? SOLD_OUT : PRICE_CHANGE;
    const buttonGradient = useRef(['#065af3', '#53b2fe']).current;

    const { mealPlanText, cancellationPolicyText } = getStaticData(LANG_AWARE.MODIFICATION);
    const handleProceedClick = useCallback(() => {
        if(type == CHANGE_TYPE.CHANGED){
            HotelBookingTrackingHelper.trackClickEvent(pageName, `${overlayType}_${PROCEED_CLICK}`);
            handleCTAClick && handleCTAClick(availUrl);
        } else {
            resetToHotelDetail(bookingId);
        }
    }, [handleCTAClick, availUrl,bookingId, type]);

    return (
        <CommonBottomOverlayMessage customStyle={AtomicCss.borderTopRadius16} isBorderRadius dismiss={dismiss} content={
            <ScrollView showsVerticalScrollIndicator={false}>
                <View style={AtomicCss.marginBottom24}>
                    <View style={styles.overlayTopline}></View>
                </View>
                <View>
                    <Image source={iconUrl} style={[styles.raiseDropImgStyle]} />
                    <Text style={[styles.title, AtomicCss.marginBottom8]}>{header}</Text>
                    {
                        (!isEmpty(newMealPlan) || !isEmpty(newPolicy)) &&
                        <View>
                            <View style={styles.strokeStyle}></View>
                            { !isEmpty(newMealPlan) && <Text style={[fonts.regularFontFamily, fsStyle.font16, AtomicCss.blackText, styles.lineHeight22, AtomicCss.marginBottom12]}>{mealPlanText} - {newMealPlan}</Text>}
                            { !isEmpty(newPolicy) && <Text style={[fonts.regularFontFamily, fsStyle.font16, AtomicCss.blackText, styles.lineHeight22, AtomicCss.marginBottom12]}>{cancellationPolicyText} - {newPolicy}</Text>}
                        </View>
                    }
                   
                </View>
                <Button label={ctaText} gradArray={buttonGradient} onPress={handleProceedClick} />
            </ScrollView>
        }>
        </CommonBottomOverlayMessage>

    )
}

const getStyles = (fonts) => {
    return StyleSheet.create({
        overlayTopline: {
            width: 40,
            height: 4,
            borderRadius: 4,
            backgroundColor: '#c2c2c2',
            alignSelf: 'center',
        },
        title: {
            color: colors.black,
            ...fonts.font22,
            fontFamily: fonts.black,
            marginBottom: 30,
            lineHeight: 32
        },
        raiseDropImgStyle: {
            width: 40,
            height: 34,
            marginBottom: 16,
            resizeMode: "contain",
        },
        strokeStyle: {
            width: 30,
            height: 4,
            backgroundColor: '#008CFF',
            marginBottom: 14,
        },
        lineHeight22: { lineHeight: 22 },
    })
}

export default React.memo(ChangeInReviewPopup);
