/* eslint-disable no-undef */
import {InteractionManager, NativeModules} from 'react-native';
import { _getLangCookie, _getMmtAuth } from '../webUtils';
import { LanguageOptions, ENGLISH } from '../const';

const _trackState = (pageName, params, isLoadEvent) => {
  // console.log('_trackState: ', pageName, params)
  // eslint-disable-next-line no-unused-vars
  s.linkTrackVars = 'channel,eVar1,eVar15,eVar24,eVar34,prop24,eVar75';
  const keys = Object.keys(params);
  keys.forEach((key) => {
    const newKey = key.replace('m_c', 'prop').replace('m_v', 'eVar').replace('m_', '');
    s.linkTrackVars += `,${newKey}`;
    s[newKey] = params[key];
  });
  s.eVar1 = 'PWA_RNW';
  s.eVar15 = pageName;
  s.pageName = pageName;
  s.eVar18 = s.getVisitNum();
  s.eVar75 = 'PWA_RNW_Direct';
  const mmtAuth = _getMmtAuth();
  s.eVar39 = (mmtAuth && mmtAuth.length) ? "Login" : "Guest";
  s.eVar37 = updateGccBookingInfo(s.eVar37);

  if (isLoadEvent) {
    const sCode = s.t(this, 'o', pageName);
    if (sCode) {
      document.write(sCode);
    }
  } else {
    const sCode = s.tl(this, 'o', pageName);
    if (sCode) {
      document.write(sCode);
    }
  }
};

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

const _trackStateSafe = (...args) => {
  try {
    InteractionManager.runAfterInteractions(()=>{
      _trackState(...args);
    })
  } catch (e) {
    // ignore
    console.log('err', e)
  }
};

const updateGccBookingInfo = (value) => {
  if(value && value.indexOf('domain_language') !== -1) {
    const langCookie = _getLangCookie();
    const language = langCookie && LanguageOptions[langCookie] ? LanguageOptions[langCookie].langCode : ENGLISH;
    const token = value.split('|domain_language:');
    return `${token[0]}|domain_language:${language}`;
  }
  return value;
}

NativeModules.OmnitureModule = {
  trackState: _trackStateSafe
};