import { StyleSheet } from "react-native";
import { Fonts, Theme } from '../../../types';

export default (fonts:Fonts,theme:Theme) => {
    const { color } = theme;
    return StyleSheet.create({
        travelContainer: {
            backgroundColor: color.white,
            paddingTop: 16,
            marginBottom: 10,
        },
        travelWrapper: {
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            paddingHorizontal: 18,
            marginBottom: 6,
        },
        contentWrapper: {
            flexDirection: 'row',
            flex: 1,
        },
        insuranceTextWrapper: {
            paddingLeft: 50,
            paddingRight: 30,
            marginBottom: 10,
        },
        redirectLink: {
            borderTopColor: color.lightGray,
            borderTopWidth: 1,
        },
        travelIconStyle: {
            width: 20,
            height: 20,
            marginRight: 12,
        },
        policyTextStyle: {
            flexDirection: 'row',
        },
        subTextStyle:{
            ...fonts.regularFontFamily, 
        fontSize: fonts.fontSize.font16, 
        color: theme.color.black,
        },
        insuranceAmountStyle:{
         ...fonts.boldFontFamily, 
         fontSize: fonts.fontSize.font16, 
         color: theme.color.green,
        },
        headingTextStyle:{
            ...fonts.boldFontFamily, 
            fontSize: fonts.fontSize.font16, 
            color: theme.color.black,
        },
    });
}


export const getHtmlStyle = (fonts:Fonts, theme:Theme) => {
    const { color } = theme;
    return StyleSheet.create({
        p: {
            fontSize: fonts.fontSize.font16,
           color:color.black,
            ...fonts.regularFontFamily,
        },
        b: {
            ...fonts.boldFontFamily,
        },
    });
};