import { StyleSheet } from "react-native";
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';

const getStyles = (fonts) => {
    return StyleSheet.create({
        title: {
            color: colors.black,
            ...fonts.font22,
            fontFamily: fonts.bold,
            marginBottom: 20,
            lineHeight: 27,
            flex: 1,
        },
        textarea: {
            borderColor: colors.lightGrey,
            borderWidth: 1,
            borderRadius: 4,
            height: 120,
            textAlignVertical: 'top',
            paddingHorizontal: 10,
            fontFamily:fonts.regular
          },
        refundIconImg: {
            width: 48,
            height: 48,
            marginBottom: 16,
            resizeMode: "contain"
        },
        nonRefundIconImg: {
            width: 48,
            height: 38,
            marginBottom: 16,
            resizeMode: "contain"
        },
    
        lineHeight24: { lineHeight: 24 },
        marginBottom45: {
            marginBottom: 45
        },
        refundTxtColor: {
            color: colors.green
        },
        nonRefundTxtColor: {
            color: colors.lightBlue17
        },
        closeIcon: {
            width: 14,
            height: 14,
            alignSelf: 'flex-start',
            marginTop: 10,
        },
        bookingDetailsWrapper: {
            borderRadius: 8,
            borderWidth: 1,
            borderColor:colors.lightBlue17,
            marginBottom:24,
        },
        bookingTopDetails: {
            paddingHorizontal: 12,
            paddingVertical: 10,
            backgroundColor:colors.lighterBlue,
            flexDirection: 'row',
            alignItems: 'center',
        },
        bookingBottomDetails: {
            paddingHorizontal: 12,
            paddingVertical: 10,
            flexDirection: 'row',
            alignItems: 'center',
        },
        bookingTitleTxt: {
            ...fonts.font14,
            color: colors.darkShade,
            fontFamily: fonts.bold,
        },
        bookingInfoTxt: {
            ...fonts.font12,
            color: colors.lightShade,
            fontFamily: fonts.regular,
            marginTop: 4,
            alignItems:'center'
        },
        linkRow: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderBottomWidth: 1,
            borderBottomColor: colors.lightGrey,
            paddingVertical: 15,
        },
        lastRow: {
            borderBottomWidth: 1,
            borderBottomColor: 'transparent',
        },
        blueArrowStyle: {
            width: 12,
            height: 12,
            right: 8
        },
        calIconStyle:{
            width:17,
            height:17,
            marginRight:5,
            tintColor:colors.azure
        },
        bulletStyle:{
            fontSize:5,
            marginHorizontal:5,
            top:3,
            color:colors.lightShade
        },
        hotelImage:{
            width:65,
            height:50,
            borderRadius:8,
        },
        crossWrapper:{
            alignSelf:'flex-start',
        },
        txtAreaFocus:{
            borderColor:colors.azure
        },
        errorTextArea:{
            borderColor:colors.red
        },
        errorLableText:{
            color:colors.red
        },
        btnWrapper:{
            flexDirection:'row',
            alignItems:'center',
            justifyContent:'space-between',
            marginTop:40,
        },
    });
}
  
export default getStyles;