import React from 'react';
import LinearGradient from 'react-native-linear-gradient';
import { Image, Text, View } from 'react-native';
import styles from './CheckingBusOverlayCss';
import { flightIcon } from '../../BusImageConstants';
import { label } from '@mmt/post-sales/src/staticData/staticData';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const CheckingBusOverlayContent = () => {
  const fonts = getFont(true);
  return (
    <View style={styles.overlayContent}>
      <View style={styles.overlayContentDtls}>
        <LinearGradient
          start={{ x: 0.1, y: 0.0 }}
          end={{ x: 0.0, y: 1.0 }}
          colors={['#a8fce7', '#c2e4e8']}
          style={[styles.fltIconWrapper]}
        >
          <Image style={styles.fltIcon} source={flightIcon} />
        </LinearGradient>
        <Text style={[styles.fltcheckInTxt, fonts.blackFontFamily, fonts.font18]}>
          {label('bus.claim.chekingWithBusOpr')}
        </Text>
      </View>
    </View>
  );
};

export default CheckingBusOverlayContent;
