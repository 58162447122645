import { connect } from 'react-redux';
import React from 'react';
import {
  listingLoader,
  fetchSearchData,
  updateSelectedItemAction,
  onCancellationContinue,
  dispatchFilterListing,
} from './FlightListingActions';
import FlightListing from '../../pages/Listing/components/FlightListing';
import { ThemeContext } from '../../../../../theme/theme.context';

const mapStateToProps = (state) => {
  const {
    response,
    searchResponse,
    selectedFlightItem,
    searchMap,
    viewState,
    headerInfo,
    bookingType,
    errorCode,
    filterInfo,
    refreshListing,
    message,
    errorPageData,
    cfarInfo,
    odcImportantInfo,
    calendarDataList,
    listingErrorResponse
  } = state.flightListing;
  return {
    response,
    searchResponse,
    selectedFlightItem,
    searchMap,
    viewState,
    headerInfo,
    bookingType,
    errorCode,
    filterInfo,
    refreshListing,
    message,
    errorPageData,
    cfarInfo,
    odcImportantInfo,
    calendarDataList,
    listingErrorResponse
  };
};

const mapDispatchToProps = (dispatch) => ({
  listingLoader: () => dispatch(listingLoader()),
  fetchSearchData: (
    searchKey,
    segmentGroup,
    bookingId,
    isCorporateBooking,
    isMyPartnerBooking,
    isCfarBooking,
  ) =>
    dispatch(
      fetchSearchData(
        searchKey,
        segmentGroup,
        bookingId,
        isCorporateBooking,
        isMyPartnerBooking,
        isCfarBooking,
      ),
    ),
  updateSelectedItemAction: (listItem) => dispatch(updateSelectedItemAction(listItem)),
  onCancellationContinue: () => dispatch(onCancellationContinue()),
  filterListing: (response, sortType, sortOrder, currFltrObj) =>
    dispatch(dispatchFilterListing(response, sortType, sortOrder, currFltrObj)),
});

const ConnectedFlightListing=(props)=>{
  return <FlightListing {...props} />
}
export default connect(mapStateToProps, mapDispatchToProps, null , {context: ThemeContext})(ConnectedFlightListing);
