

// eslint-disable-next-line import/prefer-default-export
import {isNetworkAvailable} from '@mmt/legacy-commons/Common/utils/AppUtils';
import {getCommonHeaders} from '../utils/NetworkUtils';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';

export const ACTION_BUS_LIVE_TRACKING = 'ACTION_BUS_LIVE_TRACKING';
export const ACTION_BUS_LIVE_TRACKING_ERROR = 'ACTION_BUS_LIVE_TRACKING_ERROR';
export const ACTION_BUS_LIVE_TRACKING_LOADING = 'ACTION_BUS_LIVE_TRACKING_LOADING';

export const fetchLiveTrackingData = (busDetailResponse, mmtAuth, loggingTrackInfo) => async (dispatch) => {
  try {
    const hasNetwork = await isNetworkAvailable();
    if (!hasNetwork) {
      return;
    }
    dispatch({
      type: ACTION_BUS_LIVE_TRACKING_LOADING,
      data: {
        busLiveData: null,
        viewState: ViewState.LOADING
      }
    });
    const pnr = busDetailResponse.busBookingInfo.busDetail.operatorPnr;
    const baseUrl = busDetailResponse.busLiveTrackingInfo.liveTrackingBaseUrl;
    let url = `${baseUrl}/${pnr}`;
    url = `${url}?bookingId=${busDetailResponse.bookingId}`;
    const response = await fetch(url, {headers: await getCommonHeaders(mmtAuth, loggingTrackInfo)});
    if (response.ok) {
      const body = await
        response.json();
      dispatch({
        type: ACTION_BUS_LIVE_TRACKING,
        data: {
          busLiveData: body,
          viewState: ViewState.SHOW_DETAIL,
          busDetailResponse,
          mmtAuth,
          loggingTrackInfo
        }
      });
    } else {
      dispatch({
        type: ACTION_BUS_LIVE_TRACKING_ERROR,
        data: {
          responseJson: null,
          viewState: ViewState.ERROR
        }
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION_BUS_LIVE_TRACKING_ERROR,
      data: {
        responseJson: null,
        viewState: ViewState.ERROR
      }
    });
  }
};
