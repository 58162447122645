import React, { useContext, useEffect, useState } from 'react';
import LinearGradient from 'react-native-linear-gradient';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import { Image, Text, View, Animated, Platform, Dimensions, TouchableOpacity, Easing, Linking, NativeModules  } from 'react-native';
import { ThemeContext } from '../../theme/theme.context';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import createStyle from './CustomerSupportCardCss';
import CustomerSupportCardTracker from './tracking';
import CustomerSupportCardConst from './CustomerSupportCardConst';
import { cardClickHandler } from './CustomerSupportCardHelper';
import { isEnglishLangSelected } from '@mmt/legacy-commons/AppState/AppUserConfig';
import { getFont } from '../../PostSalesStyles';
import { openDialer } from '../commonUtil';
import { getImagePath, isWifiCallingEnabled } from '../../utils/PostSaleUtil';
import isEmpty from 'lodash/isEmpty';
import GenericModule from '@mmt/core/native/GenericModule';
import PermissionSheet from './PermissionSheet';
import OpenDeepLinkModule from "@mmt/legacy-commons/Native/GenericModule";
import CommonBottomOverlayMessage from '../CommonOverlayMessage/CommonBottomOverlayMessage';
import COMMON_CONSTANT from '../commonConstant';
import PermissionsModule from 'packages/legacy-commons/Native/PermissionsModule';
import { callSupportAPI } from '../../summary/data/api';


const CustomerSupportFloatingCard = ({ dynamicTextWidth, cardWidth, deepLinkHandler, pageName, cardData, bookingId, productCode, uuid, isInternational=false }) => {
    const { psTheme : theme } = useContext(ThemeContext);
    const styles = createStyle(theme);
    const [fabAnimation, setFabAnimation] = useState(false);
    const [chromeInstallationPopup, setChromeInstallationPopup] = useState(false);
    const [permissionPopup, setPermissionPopup] = useState(false);
    const [chromeDisabledPopup, setChromeDisabledPopup] = useState(false);
    const [animation, setAnimation] = useState(new Animated.Value(0));
    const gradientColor = [theme.color.orange, theme.color.radicalRed];
    const { icon, text, url, isWebView, actions, shouldExpand, popups  } = cardData || {};
    const {chromeDisabledPopupData, chromeInstallationPopupData, permissionPopupData} = popups || {};
    const crossIcon =  getImagePath('crossIcon1.webp');
    const fonts = getFont(true);

    const omnitureTracking = new CustomerSupportCardTracker(pageName, 'floatingicon', bookingId);
    const handleOpen = () => {
        Animated.timing(cardWidth, {
          toValue: 60,
          duration: 0,
          easing: Easing.linear,
          useNativeDriver: false,
      }).start();
      Animated.timing(dynamicTextWidth, {
          toValue: 0,
          duration: 0,
          useNativeDriver: false,
      }).start();
      omnitureTracking.trackCTAClickEvent('stillneedhelp_floatingicon_expanded');
        setFabAnimation(true);
        Animated.timing(animation, {
          toValue: 1,
          duration: 600,
          useNativeDriver: true,
        }).start();
    };

    const handleClose = () => {
      Animated.timing(animation, {
        toValue: 0,
        duration: 600,
        useNativeDriver: true,
      }).start(() => {
        setFabAnimation(false);
      });
    };
    useEffect(() => {
        omnitureTracking.trackLoadEvent();
    }, []);

    useEffect(() => {
        if (!!url && Platform.OS !== 'web' && isEnglishLangSelected()) {
            omnitureTracking.trackSeenEvent();
        }
    }, [url]);

    if (!url || Platform.OS === 'web' || !isEnglishLangSelected()) {return null;}
    const screenHeight = Dimensions.get('window').height;
    const AnimatedTouchable = Animated.createAnimatedComponent(TouchableOpacity);
    const fabClickHandler = (action)=>{
        switch (action.key){
            case CustomerSupportCardConst.CHAT_WITH_US:
                omnitureTracking.trackCTAClickEvent('stillneedhelp_chatbot_clicked');
                cardClickHandler(omnitureTracking, deepLinkHandler, url, isWebView, true);
                break;
            case CustomerSupportCardConst.CALL_US_NOW:
                omnitureTracking.trackCTAClickEvent('stillneedhelp_dialler_clicked');
                if(action.shouldConnectToAgent) {
                  callSupportAPI(bookingId, productCode, isInternational, uuid, pageName);
                }
                openDialer(action.value);
                break;
            case CustomerSupportCardConst.WIFI_CALL:
                omnitureTracking.trackCTAClickEvent('stillneedhelp_wifi_clicked');
                voipCallUtil();
                break;
        }
    };

    const voipCallUtil = async () => {
      if (Platform.OS === 'android'){
        const isChromeInstalled = await GenericModule.isChromeInstalled();
        if (!isChromeInstalled){
          handleClose();
          setChromeInstallationPopup(true);
        }
        else {
          handleClose();
          if(permissionPopupData && permissionPopupData.url){
            onPermissionClickHandler(permissionPopupData.url);
          }
        }
      } else {
         // Handling for IOS will be done via sdk
        const isMicrophoneEnabled = await PermissionsModule.microphonePermissionStatus();
        if (isMicrophoneEnabled){
          OpenDeepLinkModule.openDeepLink(`mmyt://app/voip?page=voipCall&bookingId=${bookingId}`);
        } else {
          setPermissionPopup(true);
        }
      }
    };

    const onPermissionClickHandler = async (urlToOpen) => {
      let gdprCookie=null;
      const { UserSessionModule } = NativeModules;
      const userConsent = await UserSessionModule.getUserConsent();
      const consentNeeded = await UserSessionModule.needConsent();
      if(consentNeeded){
        gdprCookie =  userConsent; 
      }
      const userInfo = await HotelBookingModule.getRequestHeader(bookingId, 'customer_support');
      const result = await GenericModule.openCustomChromeTab(urlToOpen, consentNeeded ? `mmt-auth=${userInfo.mmtAuth};${COMMON_CONSTANT.GDPR_COOKIE_FOR_MYRA}=${gdprCookie}` : `mmt-auth=${userInfo.mmtAuth}`);
      setPermissionPopup(false);
      if (!result){
        setChromeDisabledPopup(true);
      }
    };

    const overlayAnimate = {
      opacity: animation.interpolate({
        inputRange: [0.01, 0.3],
        outputRange: [0, 1],
        extrapolate: 'clamp',
      }),
    };

    const triggerAnimate = {
      transform: [
        {
          translateY: animation.interpolate({
            inputRange: [0, 0.1],
            outputRange: [screenHeight, 0],
            extrapolate: 'clamp',
          }),
        },
      ],
        opacity: animation.interpolate({
          inputRange: [0.04, 0.4],
          outputRange: [0.2, 1],
          extrapolate: 'clamp',
        }),
    };


    const slideAnimate = {
      transform: [
        {
          translateY: animation.interpolate({
            inputRange: [0, 0.3],
            outputRange: [0, -1 * screenHeight],
            extrapolate: 'clamp',
          }),
        },
      ],
      opacity: animation.interpolate({
        inputRange: [0.04, 0.4],
        outputRange: [0.2, 1],
        extrapolate: 'clamp',
      }),
    };

    const getOverlayPermission = ()=>{
      if (permissionPopup && !isEmpty(permissionPopupData)) {
        return (
          <PermissionSheet
            heading={permissionPopupData.heading}
            description1={permissionPopupData.description1}
            description2={permissionPopupData.description2}
            icon ={permissionPopupData.icon}
            btnTxt={permissionPopupData.btnText}
            handleBottomOverlay={() => setPermissionPopup(false)}
            onSubmit={() => onPermissionClickHandler(permissionPopupData.url)}
          />
        );
      } else if (chromeDisabledPopup && !isEmpty(chromeDisabledPopupData)) {
        return (
          <PermissionSheet
            heading={chromeDisabledPopupData.heading}
            description1={chromeDisabledPopupData.description1}
            btnTxt={chromeDisabledPopupData.btnText}
            handleBottomOverlay={() => setChromeDisabledPopup(false)}
            onSubmit={() => setChromeDisabledPopup(false)}
          />
        );
      } else if (chromeInstallationPopup && !isEmpty(chromeInstallationPopupData)) {
        return (
          <PermissionSheet
            heading={chromeInstallationPopupData.heading}
            description1={chromeInstallationPopupData.description1}
            btnTxt={chromeInstallationPopupData.btnText}
            handleBottomOverlay={() => setChromeInstallationPopup(false)}
            onSubmit={() => setChromeInstallationPopup(false)}
          />
        );
      }
    };

    return (
        <>
        {fabAnimation &&
        (<AnimatedTouchable style={[styles.cover, overlayAnimate]} onPress={handleClose} activeOpacity={1} />)}
        <Animated.View style={[styles.floatingCardWrapper, { width: cardWidth }]}>
            <TouchableOpacity onPress={() => shouldExpand && isWifiCallingEnabled() ? handleOpen() : cardClickHandler(omnitureTracking, deepLinkHandler, url, isWebView)} activeOpacity={1}>
                <LinearGradient
                    start={{ x: 0, y: 0 }} end={{ x: 1, y: 0 }}
                    colors={gradientColor}
                    style={[styles.needHelpIconWrap, AtomicCss.borderRadius30, AtomicCss.paddingHz15, AtomicCss.justifyCenter]}
                >
                    <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
                        <Image source={{uri: icon}} style={[styles.needHelpIcon, AtomicCss.marginLeft3]} />
                        <Animated.View style={[styles.floatingIconTextWrap, AtomicCss.paddingLeft10, { opacity: dynamicTextWidth }]}>
                            <Text style={[AtomicCss.whiteText, fonts.boldFontFamily]}>{text ?? CustomerSupportCardConst.CARD_CTA_TEXT}</Text>
                        </Animated.View>
                    </View>
                </LinearGradient>
            </TouchableOpacity>
            {fabAnimation &&
                <AnimatedTouchable style={[styles.closeTrigger, triggerAnimate]} onPress={handleClose} activeOpacity={1}>
                    <LinearGradient start={{x: 0, y: 0}} end={{x: 0, y: 1}} colors={gradientColor} style={[styles.linearGradient]}>
                        <Image style={styles.closeStyle} source= {crossIcon} />
                    </LinearGradient>
                </AnimatedTouchable>
            }
        </Animated.View>
        <View style={fabAnimation ? styles.popUpouter : styles.popUpouterDissabled}>
          <Animated.View  style={[styles.sheet, slideAnimate]}>
            {!isEmpty(actions) && actions.map((action)=>
              <TouchableOpacity onPress={()=>fabClickHandler(action)} activeOpacity={1}>
                <View style={[styles.queryBtnOuter]}>
                      <Text style={styles.queryBtnText}>{action.title}</Text>
                      {action.icon.url && <View style={styles.fabIcon}>
                            <Image style={styles.chatStyle} source= {{uri: action.icon.url}} />
                      </View>}
                </View>
              </TouchableOpacity>)}
          </Animated.View >
        </View>
        {
          ((permissionPopup && !isEmpty(permissionPopupData)) ||
            (chromeInstallationPopup && !isEmpty(chromeInstallationPopupData)) ||
            (chromeDisabledPopup && !isEmpty(chromeDisabledPopupData))) && (
            <CommonBottomOverlayMessage
              isBorderRadius
              dismiss={null}
              content={getOverlayPermission()}
              isCancellable={false}
            />
          )
        }

      </>
    );
};

export default CustomerSupportFloatingCard;
