import React from 'react';
import { Text,TouchableOpacity} from 'react-native';
import { useTheme } from '../../theme/theme.context';
import createStyle from './TabsCss';

const Tabs = ({item, index, onHandleTabChange, activeTab, disable}) => {
	const {psTheme:theme} = useTheme();
	const styles = createStyle(theme)
	const tabStyle = [styles.tabStyle];
	const tabStyleTxt = [styles.tabStyleTxt];

	if (index === activeTab) {
		tabStyle.push(styles.tabActive);
		tabStyleTxt.push(styles.tabTxtActive);
	}

	const disabledState = index === disable;

	if (disabledState) {
		tabStyleTxt.push(styles.disabledTxt)
	}
	return(
		<TouchableOpacity style={tabStyle} onPress={() => onHandleTabChange(index)} disabled={disabledState ? true : false}>
			<Text style={tabStyleTxt}>{item}</Text>
		</TouchableOpacity>
	)
}

export default Tabs;