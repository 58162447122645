import {Dimensions} from 'react-native';

const windW = Dimensions.get('screen').width;

const getStyles = (fonts) => {
  return {
    pageWrapper:{
      flex:1,
      backgroundColor:'#fff',
      justifyContent:'center'
    },
  
    cardWrapper:{
      width:windW,
      paddingHorizontal:25,
  
    },
  
    marginTop3:{
      marginTop:3
    },
    marginBottom60:{
      marginBottom:60,
    },
  
    buttonWrapper:{
      backgroundColor:'#ffffff',
      alignItems:'center'
    },
  
    imageIcon:{
      width:66,
      height:60,
      alignSelf:'center',
      marginBottom:35,
    },
  
    bulletText:{
      fontSize:6,
      paddingHorizontal:5,
      paddingTop:3
    },
  
    cancellationInfoListItem:{
      paddingVertical:8,
      paddingRight:15,
      lineHeight:20
    },
  
    linkBtnWrapper:{
      marginTop:30,
    },
    InfoListItem:{
      paddingVertical:8,
      paddingRight:15,
      lineHeight:20
    }
  
  }
}

export default getStyles;
