import React from 'react';
import { View } from 'react-native';
import RefundModeSection from './RefundModeSection';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import PostSalesBookingTrackingHelper from '../../../PostSalesBookingTrackingHelper';
import { REFUND_OOT_TRACKING } from '../constants';

const SelectModeList = ({ refundModeDataList, setRefundModeList, bookingId, pageName = '' }) => {
  const onChkBoxPress = (index) => {
    refundModeDataList[index].checkBoxData.checked = !refundModeDataList[index].checkBoxData
      .checked;
    setRefundModeList(refundModeDataList);
    const clickEvent = `${refundModeDataList[index].checkBoxData.refundMode}_${refundModeDataList[index].checkBoxData.checked ? REFUND_OOT_TRACKING.CLICK.PAYMODE_CHECKED : REFUND_OOT_TRACKING.CLICK.PAYMODE_UNCHECKED}`;
    PostSalesBookingTrackingHelper.trackClickEvent(`${pageName}:${REFUND_OOT_TRACKING.PAGES.SELECT_MODE}`, clickEvent, bookingId);
  };

  return (
    <>
      {refundModeDataList.map(({ checkBoxData }, index) => {
        const { header = '', subHeader = '', checked = false } = checkBoxData || {};
        return (
          <View style={AtomicCss.marginBottom12} key={index}>
            <RefundModeSection
              title={header}
              subTitle={subHeader}
              onChkBoxPress={() => onChkBoxPress(index)}
              defaultChecked={checked}
            />
          </View>
        );
      })}
    </>
  );
};

export default SelectModeList;
