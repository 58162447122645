import React, {Component} from 'react';
import {Platform, View, BackHandler, NativeModules} from 'react-native';
import {WebView} from 'react-native-webview';
import PropTypes from 'prop-types';
import Actions from '../../navigation/postSalesNavigation';
import {isEmpty} from 'lodash';
import COMMON_CONSTANT from '../commonConstant';
import { NEED_HELP_COMMUNICATION_LINKS } from '../../PostSalesConstant';

/**
 * generic class for opening web view page in react native
 * Parameters needed : url, header text,header icon
 */
class NeedHelpWebViewWrapper extends Component {
  static navigationOptions = {
    header: null
  };

  constructor(props) {
    super(props);
    this.handleBackButtonClick = this.onBackPress.bind(this);
    this.state = {
      canGoBack: false,
      gdprConsent: {
        gdprRegion: false,
        consentType: 'LOADING'
      }
    };
    this.timer = null;
    this.navigation = props.navigation ? props.navigation : Actions;
  }

  getUserConsent = async () => {
    const { UserSessionModule } = NativeModules;
    const userConsent = await UserSessionModule.getUserConsent();
    const consentNeeded = await UserSessionModule.needConsent();

    this.setState({
      gdprConsent: {
        gdprRegion: consentNeeded, consentType: userConsent
      }
    })
  }

  componentWillMount() {
    BackHandler.addEventListener('hardwareBackPress', this.handleBackButtonClick);
    this.timer = setTimeout(() => {
      this.setState({invalidate: true});
    }, 300);
    this.getUserConsent();
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    BackHandler.removeEventListener('hardwareBackPress', this.handleBackButtonClick);
  }

  render() {
    const { gdprConsent } = this.state;
    const uri = this.props.url;
    const consentHeaders = gdprConsent.gdprRegion ? { [COMMON_CONSTANT.GDPR_COOKIE]: gdprConsent.consentType || 'IS'}  : {}
    const authCookie = 'mmt-auth=' + this.props.mmtAuth;
    return (
      <View style={{
        flex: 1,
        flexDirection: 'column'
      }}>
      {!!this.props.mmtAuth && gdprConsent.consentType !== 'LOADING' && (
        <WebView
          style={{
            flex: 1,
            flexGrow: 1
          }}
          ref={e => this._webView = e}
          source={{
            uri,
            headers: {'Cookie': authCookie, ...consentHeaders  }
          }}
          onNavigationStateChange={this.onNavigationStateChange}
          startInLoadingState
          androidHardwareAccelerationDisabled
          onShouldStartLoadWithRequest={this.onShouldStartLoadWithRequest}
          sharedCookiesEnabled={this.props.sharedCookiesEnabled}
          injectedJavaScriptBeforeContentLoaded={this.props.injectedJavaScript || null}
          onMessage={(event => this.onEventReceived(event))}
        />)}
      </View>
    );
  }

  onEventReceived = (event) => {
    const eventData = event.nativeEvent.data;
    if (!eventData) {
      return;
    }
    let action = eventData;
    try {
      const {type} = JSON.parse(eventData);
      action = type;
    } catch (e) {
      console.log('Error ', e);
    }
    if (action === 'BackPress' || action === 'close_webview') {
      this.navigation.pop();
    }
  };

  onShouldStartLoadWithRequest = (event) => {
    const {url} = event;
    if (this.props.deepLinkHandler && !isEmpty(url) && (url.includes(NEED_HELP_COMMUNICATION_LINKS.WITH_MY_ACCOUNT) || url.includes(NEED_HELP_COMMUNICATION_LINKS.WITH_MY_ACCOUNT))) {
      this.props.deepLinkHandler(url);
      return false;
    }
    return true;
  }

  onNavigationStateChange = (navState) => {
    this.setState({
      canGoBack: navState.canGoBack
    });
  };

  onBackPress = () => {
    if (this.state.canGoBack) {
      this._webView.goBack();
      return true;
    }
    this.props.backPressCb && this.props.backPressCb();
    this.navigation.pop();
    return true;
  };
}

NeedHelpWebViewWrapper.propTypes = {
  url: PropTypes.string.isRequired,
  headers: PropTypes.object,
  sharedCookiesEnabled: PropTypes.bool,
};

NeedHelpWebViewWrapper.defaultProps = {
  headers: {},
  sharedCookiesEnabled: false,
};

export default NeedHelpWebViewWrapper;
