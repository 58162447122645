import React from 'react';
import {get} from 'lodash';
import {View, Text, TouchableOpacity, Image} from 'react-native';
import PropTypes from 'prop-types';
import styles from './DynamicFormCss';
import InputFieldMaster from './InputFieldMaster';
import {isValidPattern, getHypotheticalParentNode, getIndex, setErrorField} from './DynamicFormUtils';
import {CloseIcon, CheckListIcon, CheckListIconGray, blueDropIcondown} from '../../HolidayBookingConstants';

class FormSingleSelectDropdownInput extends React.Component {
  static navigationOptions = {
    header: null
  };

  constructor(props) {
    super(props);

    this.errorField = '';
    this.hypothiticalParentNode = getHypotheticalParentNode(this.props.parent, this.props.visaForm.fields[this.props.fieldName]);
    this.state = {
      selectedVal: get(this.props, `${this.props.jsonKey}.value`, undefined) ||
       this.props.visaForm.fields[this.props.fieldName].defaultValue,
      validate: this.props.validate
    };
  }

  componentWillReceiveProps(newProps) {
    if (this.props.validate === !newProps.validate) {
      this.setState({
        validate: newProps.validate
      });
    }
  }

  componentWillMount() {
    this.props.updateChildValues(`${this.props.jsonKey}.value`, this.state.selectedVal);
    this.props.updateChildValues(`${this.props.jsonKey}.parent`, getHypotheticalParentNode(this.props.parent, this.props.visaForm.fields[this.props.fieldName]));
  }

  renderSingleValueInputView = () => (
    <View style={[this.props.visaForm.fields[this.props.fieldName].disabled ?
      styles.singleDropDownInputDisabled : styles.singleDropDownInput]}
    >
      <TouchableOpacity onPress={() => this.selectFieldClicked()}>
        <View style={[styles.flexRow, styles.spaceBetween]}>
          <Text>
            {this.props.visaForm.fields[this.props.fieldName].values.find(elem => elem.key === this.state.selectedVal) ?
        this.props.visaForm.fields[this.props.fieldName].values.find(elem => elem.key === this.state.selectedVal).value :
        this.props.visaForm.fields[this.props.fieldName].placeholder}
          </Text>
          <Image source={this.props.visaForm.fields[this.props.fieldName].disabled ? CheckListIconGray : blueDropIcondown} style={{width: 18, height: 22}} />
        </View>
      </TouchableOpacity>
    </View>
  )

  selectFieldClicked = () => {
    if (!this.props.visaForm.fields[this.props.fieldName].disabled) {
      const params = {};
      params.jsonKey = this.props.jsonKey;
      params.fieldName = this.props.fieldName;
      params.parent = getHypotheticalParentNode(this.props.parent, this.props.visaForm.fields[this.props.fieldName]);

      this.props.updateChildValues(`${this.props.jsonKey}.dependents`, {});
      if (this.props.visaForm.fields[this.props.fieldName].validations[this.hypothiticalParentNode] === undefined) {
        this.props.setField(params);
      } else {
        this.props.showDropDown(params);
      }
    }
  }

  renderDependents = () => {
    const dependents = [];
    if (this.props.visaForm.fields[this.props.fieldName].dependents &&
      this.props.visaForm.fields[this.props.fieldName].dependents[this.state.selectedVal.toLowerCase()]) {
      this.props.visaForm.fields[this.props.fieldName].dependents[this.state.selectedVal.toLowerCase()].forEach((element) => {
        dependents.push(<InputFieldMaster
          focusOnNextField={this.props.focusOnNextField}
          textInputRefs={this.props.textInputRefs}
          labelIndex={`${this.props.labelIndex}.${dependents.length + 1}`}
          key={`${this.state.selectedVal}${element}`}
          parent={this.state.selectedVal}
          jsonKey={`${this.props.jsonKey}.dependents`}
          fieldName={element}
          visaForm={this.props.visaForm}
          updateChildValues={this.props.updateChildValues}
          validate={this.state.validate}
          setField={this.props.setField}
          showDropDown={this.props.showDropDown}
        />);
      });
    }
    return dependents;
  }

  shouldShowErrorField = () => {
    if (this.props.validate) {
      this.errorField = setErrorField(this.props.visaForm.fields[this.props.fieldName].mandatory, this.state.selectedVal, this.props.visaForm.fields[this.props.fieldName].error);
      if (this.props.visaForm.fields[this.props.fieldName].mandatory) {
        if (this.state.selectedVal === '') {
          return true;
        }
        return !isValidPattern(new RegExp(this.props.visaForm.fields[this.props.fieldName].validations[getHypotheticalParentNode(this.props.parent, this.props.visaForm.fields[this.props.fieldName])]), this.state.selectedVal);
      }
      if (this.state.selectedVal === '') {
        return false;
      }
      return !isValidPattern(new RegExp(this.props.visaForm.fields[this.props.fieldName].validations[getHypotheticalParentNode(this.props.parent, this.props.visaForm.fields[this.props.fieldName])]), this.state.selectedVal);
    }
    return false;
  }

  getDependents = () => {
    const dependents = this.renderDependents();
    if (dependents.length !== 0) {
      return dependents;
    }
  }

  shouldShowCancelIcon = () => {
    if (this.props.visaForm.fields[this.props.fieldName].mandatory) {
      return this.props.visaForm.fields[this.props.fieldName] &&
      this.props.visaForm.fields[this.props.fieldName].addMore &&
      getIndex(this.props.jsonKey) !== '0';
    }
    return this.props.visaForm.fields[this.props.fieldName] &&
      this.props.visaForm.fields[this.props.fieldName].addMore;
  }

  render() {
    return (
      <View style={{flex: 1}}>
        {this.shouldShowCancelIcon() &&
        <View style={[styles.flex1, styles.flexRow, styles.mar12Bt, styles.visaFormCloseIcon]}>
          <TouchableOpacity onPress={() => this.props.deleteWRTIndex(this.props.indexInParent)}>
            <Image source={CloseIcon} style={styles.visaFormCloseIconImage} />
          </TouchableOpacity>
        </View>}
        <View style={[styles.flexRow]}>
          {this.renderSingleValueInputView()}
        </View>
        {this.shouldShowErrorField() &&
        <Text style={[styles.ErrorText, styles.font14, styles.regularFont]}>{this.errorField}</Text>}
        {this.getDependents()}
      </View>
    );
  }
}

FormSingleSelectDropdownInput.propTypes = {
  labelIndex: PropTypes.string.isRequired,
  parent: PropTypes.string.isRequired,
  jsonKey: PropTypes.string.isRequired,
  visaForm: PropTypes.object.isRequired,
  fieldName: PropTypes.string.isRequired,
  validate: PropTypes.bool.isRequired,
  updateChildValues: PropTypes.func.isRequired,
  setField: PropTypes.func.isRequired,
  showDropDown: PropTypes.func.isRequired,
  deleteWRTIndex: PropTypes.func.isRequired,
  indexInParent: PropTypes.number.isRequired,
  textInputRefs: PropTypes.object.isRequired,
  focusOnNextField: PropTypes.func.isRequired
};

export default FormSingleSelectDropdownInput;
