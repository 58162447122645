import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
import { StyleSheet } from 'react-native';

const getStyle = (theme, fonts) => {
  const {color} = theme;
  return StyleSheet.create({
    whiteCard: {
      backgroundColor: color.white,
      ...getPlatformElevation(2),
      marginBottom: 3,
      padding: 16,
    },
    tag: {
      backgroundColor: color.creamWhite,
      borderRadius: 2,
      paddingVertical: 3,
      paddingHorizontal: 6,
      alignSelf: 'flex-start',
      marginBottom: 16,
    },
    tagTxt: {
      color: color.lightYello,
      fontSize: fonts.fontSize.font10,
    },
    cardTitle: {
      color: color.black,
      fontSize: fonts.fontSize.font14,
      marginBottom: 10,
    },
    cardDesc: {
      ...fonts.regularFontFamily,
      color: color.black,
      fontSize: fonts.fontSize.font12,
      marginBottom: 10,
    },
    uploadCard: {
      backgroundColor: color.lightGrey6,
      // marginBottom: 8,
      paddingLeft: 12,
      paddingRight: 24,
      paddingVertical: 16,
      borderRadius: 4,
      marginBottom: 8,
    },
    uploadCardWhite: {
      marginBottom: 8,
      paddingRight: 5,
      paddingVertical: 8,
      borderRadius: 4,
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      alignContent: 'flex-end',
    },
    uploadCardHeading: {
      ...fonts.boldFontFamily,
      color: color.black,
      marginBottom: 2,
      lineHeight: 24,
      flex: 1, 
      flexWrap: 'wrap',
    },
    uploadCardInfo: {
      ...fonts.regularFontFamily,
      color: color.greyLight,
      lineHeight: 20,
    },
    lftDtls: {
      flex: 1,
    },
    linkWrpper: {
      top: 10,
    },
    linkTxt: {
      color: color.azure,
      fontSize: fonts.fontSize.font14,
      lineHeight: 16.8,
    },
    uploadIconWrapper: {
      width: 40,
      height: 44,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: color.lightGrey,
      borderRadius: 4,
      marginRight: 12,
    },
    failedIconWrapper: {
      width: 40,
      height: 44,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: color.lightPink,
      borderRadius: 4,
      marginRight: 12,
    },
    uploadIcon: {
      width: 24,
      height: 24,
      marginRight: 12,
      marginTop: 2,
    },
    boldFont: {
      ...fonts.boldFontFamily,
    },
    blackFont: {
      ...fonts.blackFontFamily,
    },
    blackTxt: {
      color: color.black,
    },
    defaultTxt: {
      color: color.defaultTextColor,
    },
    progressBarWrppaer: {
      marginTop: -8,
      width: '100%',
    },
    closeWrapper: {
      width: 24,
      height: 24,
      backgroundColor: '#ddd',
      borderRadius: 50,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    closeIcon: {
      width: 10,
      height: 10,
    },
    progressInfoTxt: {
      ...fonts.regularFontFamily,
      color: color.greyLight,
      fontSize: fonts.fontSize.font13,
    },
    failedTxt: {
      color: color.red,
    },
    deleteIconStyle: {
      width: 18,
      height: 18,
    },
    successIconStyle: {
      width: 14,
      height: 14,
      marginLeft: 5,
    },
    attachedProofWrapper: {
      padding: 0,
      //...getPlatformElevation(2),
      borderWidth: 1,
      borderColor: color.grey8,
      borderRadius: 4,
    },
    uploadproffImg: {
      width: '100%',
      height: 120,
    },
    attachedProof: {
      padding: 16,
    },
    errorSection: {
      paddingHorizontal: 12,
    },
    docName: {
      maxWidth: '55%',
      justifyContent: 'space-between',
    },
    errorText: {
      color: color.red,
    },
  });

}
export default getStyle;
