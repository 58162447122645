import { Platform, Linking } from 'react-native';
import {
  ACTION_ID_ADDONS_TYPE_MAPPING,
  ACTION_ID_PAGE_KEY_MAPPING,
  PAGE_REQUESTED,
} from '../commonConstants';
import Actions from 'apps/post-sales/src/navigation/postSalesNavigation';
import isEmpty from 'lodash/isEmpty';
import FlightBookingModule from '@mmt/legacy-commons/Native/FlightBookingModule';
import { fetchFlightAncillaryDetails } from '../../modules/details/data/api';

const lobName = 'flight';

export const getPageData = ({
  bookingId,
  pageRequested,
  pageName,
}: {
  bookingId: string;
  pageRequested: string;
  pageName: string;
}) => {
  const pageData = {
    affiliate: 'mmt',
    type: 'b2c',
    lob: lobName,
    pageName: pageName,
    entityType: 'BOOKING_ID',
    pageRequested: pageRequested || PAGE_REQUESTED.FLIGHT_DETAILS,
    entityId: bookingId,
  };
  return pageData;
};

export const travelInsurancePaymentHandler = (
  holdResponse: any,
) => {
  const { paymentUrl } = holdResponse;
  if (paymentUrl) {
    window.location.href = paymentUrl;
  }
};

export const commonPaymentHandler = (
  holdResponse: any,
) => {
  const { paymentUrl } = holdResponse;
  if (paymentUrl) {
    window.location.href = paymentUrl;
  }
};


export const addonsHandler = ({
  actionId,
  ...extraData
}: {
  actionId: string;
  bookingId: string;
  flightDetailResponse: object;
  source: string;
  segmentGroupId: string;
}) => {
  const actionType = ACTION_ID_ADDONS_TYPE_MAPPING[actionId];
  const pageKey = ACTION_ID_PAGE_KEY_MAPPING[actionId];
  Actions.openAncillary({
    pageKey,
    addonType: actionType,
    ...extraData,
  });
};

export const resetToSellingDetailPage = ({
  bookingId,
}: {
  bookingId: string;
  refreshPage?: boolean;
  refElment?: string;
  scrollToSection?: string;
}) => {
  FlightBookingModule.resetToFlightBookingDetail(bookingId);
};

export function resetToFlightDetailPage({
  bookingId,
}: {
  bookingId: string;
  refreshPage?: boolean;
  refElment?: string;
  scrollToSection?: string;
  shouldLoadOldFlow?: boolean;
}) {
  FlightBookingModule.resetToFlightBookingDetail(bookingId);
}

export const fetchFlightAncillary = async (bookingId: string) => {
  const ancillaryReponse = await fetchFlightAncillaryDetails(bookingId);
  return ancillaryReponse;
};

export const getDeeplinkKeyValue = (data: Record<string, string>, key: string) => {
  if (Platform.OS === 'ios') {
      return data?.[key];
  } else if (Platform.OS === 'android') {
      if (!isEmpty(data?.pageData)) {
        try {
          const pageData = JSON.parse(data.pageData);
          return pageData[key];
        } catch (err) {
          return null;
        }
      }
  }
  return null;
};

export const openExternalUrl = (link: string) => {
  try {
    Linking.canOpenURL(link)
      .then((canOpen) =>
        canOpen
          ? Linking.openURL(link)
          : console.error('Error: cannot open link via this Linking.openUrl(' + link + ')'),
      )
      .catch((e) => {
        console.error(`Error: cannot open link via this Linking.openUrl(${link})`);
        console.error('Error stack: ', e);
      });
  } catch (err) {
    console.error('Error stack: ', err);
  }
};