import React from 'react';
import { View, Text, Image } from 'react-native';
import AtomicCss from '../../commonStyles/AtomicCss';
import Actions from '../../../navigation/postSalesNavigation';
import HTMLView from 'react-native-htmlview';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { useTheme } from '../../../theme/theme.context';
import { getActionButton } from '../../utils/BusBookingDetailUtil';
import createStyle from './styleCss';
import { getHtmlStyle } from './styleCss';
import isEmpty from 'lodash/isEmpty';
interface Action {
    actionRank: number;
    actionId: string;
    actionType: string;
    actionLabeltext: string;
    actionFamily: string;
}
interface TravelInsuranceCardProps {
    response: {
        busInsuranceData: {
            heading: string;
            insuranceAmount?: string;
            subText?: string;
            policyNumberText?: string;
            knowMorePolicyText?: string;
            knowMorePolicyLink?: string;
            insuranceIconUrl?: string;
        };
    };
    card: {
        cardId: string;
        actionList: Action[];
    }
    onClickEventTracker: (arg: string) => void;
}

const TravelInsuranceCard: React.FC<TravelInsuranceCardProps> = ({ response, card, onClickEventTracker }) => {
    const { busInsuranceData } = response || {};
    const { heading, insuranceAmount, subText, policyNumberText, knowMorePolicyLink, insuranceIconUrl } = busInsuranceData || {};
    if(isEmpty(busInsuranceData)){
        return null;
    }
    const { fsStyle, ...fonts } = getFont(true);
    const { psTheme: theme } = useTheme();
    const { color } = theme;
    const styles = createStyle(fonts, theme);
    const handleKnowMoreClick = () => {
        onClickEventTracker('NonRapBusInsuranceKnowMoreClick');
            Actions.openPsWebView({
                url: knowMorePolicyLink,
            });
    }
    return (
        <View style={styles.travelContainer}>
            <View style={styles.travelWrapper}>
                <View style={styles.contentWrapper}>
                   {!!insuranceIconUrl && <Image source={{ uri: insuranceIconUrl }} style={styles.travelIconStyle} />}
                    <View style={AtomicCss.flex1}>
                        {!!heading && <Text style={[styles.headingTextStyle, AtomicCss.marginBottom3]}>{heading}</Text>}
                    </View>
                </View>
                {!!insuranceAmount && <Text style={styles.insuranceAmountStyle}>{insuranceAmount}</Text>}
            </View>
            {(!!subText || !!policyNumberText) && <View style={styles.insuranceTextWrapper}>
                {!!subText && <Text style={[styles.subTextStyle, AtomicCss.marginBottom5]}>{subText}</Text>}
                {!!policyNumberText && <HTMLView
                    style={styles.policyTextStyle}
                    value={policyNumberText}
                    stylesheet={getHtmlStyle(fonts, theme)}
                />}
            </View>}
            {!!knowMorePolicyLink && <View style={[styles.redirectLink]}>
                {getActionButton(card, response, handleKnowMoreClick)}
            </View>}
        </View>
    )
};
export default TravelInsuranceCard;