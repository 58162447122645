import isEmpty from 'lodash/isEmpty';
import { getColor, getPnrDetails } from './helper';
import {
  CardList,
  Passenger,
  Response,
  StatusData,
  StaticData,
  TopStatusData,
  DescriptionItem,
  DelayTimeData,
} from '../../types';
import { BOOKING_TYPE } from '../constants';

export const getStatusData = (passenger: Passenger[], response: Response): StatusData[] => {
  const list: StatusData[] = [];
  const { bookingState } = response;
  const { state } = bookingState || {};
  !isEmpty(passenger) &&
    passenger.forEach((item: Passenger) => {
      const obj: StatusData = {
        type: item.currentStatus,
        statusType: item.currentStatusMsg,
        PaxName: item.fullName,
        status: item.passengerCurrentStatusText,
        bookingState: state,
      };
      list.push(obj);
    });
  return list;
};

export const getTopStatusData = (
  response: Response,
  card: CardList,
  staticData: StaticData,
  color: object,
): TopStatusData => {
  const { bookingState } = response;
  const { state, subState } = bookingState || {};
  const { heading } = card ?? {};
  const { irctcPnrText, bookingIdCapsText } = staticData ?? {};
  const pnrDetails = getPnrDetails(response);
  const { pnrNo } = pnrDetails || {};
  const colorList = getColor(state, subState, color);
  const description: DescriptionItem[] = [];
  const upcomingOrCompleted =
    (state === BOOKING_TYPE.UPCOMING ||
    state == BOOKING_TYPE.ONTRIP ||
    state == BOOKING_TYPE.COMPLETED) &&
    (subState!=BOOKING_TYPE.UNCONFIRMED);

  if (!isEmpty(pnrNo) && upcomingOrCompleted) {
    description.push({
      text: `${irctcPnrText} ${pnrNo}`,
      code: pnrNo,
    });
  }
  description.push({
    text: `${bookingIdCapsText} ${response.bookingID}`,
    code: response.bookingID,
  });
  return {
    heading,
    colorList,
    description,
    upcomingOrCompleted,
  };
};

export const getTimeInfoCardData = (
  trainScheduleData: DelayTimeData,
  response: Response,
  staticData: StaticData,
) => {
  const { boardingPoint, fromStationCode } = response;
  const {
    scheduledArrivalTime,
    actualArrivalTime,
    scheduledDepartureTime,
    actualDepartureTime,
  } = trainScheduleData;
  const {
    scheduledArrivalText,
    expectedArrivalText,
    scheduledDepartureText,
    expectedDepartureText,
  } = staticData;
  if (boardingPoint == fromStationCode) {
    return {
      scheduleCardHeading: scheduledDepartureText,
      actualCardHeading: expectedDepartureText,
      scheduledTime: scheduledDepartureTime,
      actualTime: actualDepartureTime,
    };
  } else {
    return {
      scheduleCardHeading: scheduledArrivalText,
      actualCardHeading: expectedArrivalText,
      scheduledTime: scheduledArrivalTime,
      actualTime: actualArrivalTime,
    };
  }
};
