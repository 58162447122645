import React from 'react';
import { View, StyleSheet, ViewStyle } from 'react-native';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';

/**
 * Props for the Divider component.
 * @interface DividerProps
 * @property {ViewStyle} [style] - Additional styles for the Divider.
 */
interface DividerProps {
  style?: ViewStyle;
}

/**
 * Divider component renders a horizontal line to visually separate content.
 * @param {DividerProps} props - The component props.
 * @returns {JSX.Element} - The rendered JSX element.
 */
const Divider: React.FC<DividerProps> = ({ style = {}}) => (
  <View style={[styles.divider, style]} />
);

const styles = StyleSheet.create({
  divider: {
    borderTopWidth: 1,
    borderTopColor: colors.borderDividerCardStroke,
    marginTop: 10,
    marginBottom: 10,
  },
});

export default Divider;
