
import React, { useEffect, useRef, useState } from 'react';
import { ActivityIndicator, Animated, Image, Platform, Dimensions, Easing, StyleSheet, Text, View, TouchableOpacity } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import CommonBottomOverlayMessage from '../../../Common/CommonOverlayMessage/CommonBottomOverlayMessage';
import { resetToHotelDetail } from '../../details/utils/HotelBookingDetailUtil';
import { getStaticData } from '../../../staticData/staticData';
import SuccessLoaderAnim from '../../../Common/SuccessTickAnim';
import { RED_CROSS_ICON } from '../../HotelImageConstants';
import { MODIFICATION_TYPE } from '../../details/HotelBookingDetailsConstant';
import { getErrorPageDesc, getErrorPageHeading, showErrorPageCTA } from '../../hotelUtils';
import { ERROR_CTAS_ACTION_ID, LANG_AWARE } from '../../HotelConstants';
import HotelBookingTrackingHelper from '../../HotelBookingTrackingHelper';
import {
  ADD_GUEST_CONTACT_US, ADD_GUEST_GO_BACK_TO_BOOKING, CHANGE_DATE_CONTACT_US, CHANGE_DATE_GO_BACK_TO_BOOKING, CHANGE_DATE_RETRY, ADD_GUEST_RETRY, CHANGE_DATE_SEE_UPDATED_BOOKING, ADD_GUESTS_SEE_UPDATED_BOOKING
} from '../HotelModificationConstants';
import { useTheme } from '../../../theme/theme.context';
import BaseButton, { BUTTON_VARIANT } from '../../../Common/BaseButton';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import useHardwareBackpress from 'apps/post-sales/src/Common/useHardwareBackpress';
import ButtonWrapper from 'apps/post-sales/src/Common/ButtonWrapper';

const ModificationOverlay = ({ isLoading, isSuccess, isError, isForceClose, response, handleTryAgain, modificationType = MODIFICATION_TYPE.DATE_CHANGE, showHelpingHand, helpingHandClickHandler, pageName, hotelDetailsResponse, nrTrackingInfo }) => {
  const heightAnim = useRef(new Animated.Value(300)).current;
  const colorAnim = useRef(new Animated.Value(0)).current;
  const trackingPageName = useRef(pageName);
  const [showCTA, setShowCTA] = useState(false); 
  const {psTheme: theme}=useTheme();
  const { fsStyle, ...fonts } = getFont(true);
  const styles = createStyle(theme, fonts);
  const goToBookingDetailsPage = () => {
		resetToHotelDetail(bookingID);
	}
	useHardwareBackpress().addListener(goToBookingDetailsPage);

  useEffect(() => {
    if (isSuccess) {
      trackingPageName.current = `${pageName}:TYOverlay`;
      HotelBookingTrackingHelper.trackLoadEvent(trackingPageName.current, hotelDetailsResponse, { nrTrackingInfo });
    } else if (isError) {
      const { bookingID } = hotelDetailsResponse || {};
      const { errorPageData } = response || {};
      const { errorCode = '' } = errorPageData || {};
      trackingPageName.current = `${pageName}:ErrorOverlay`;
      HotelBookingTrackingHelper.trackErrorWithData(trackingPageName.current, bookingID, errorCode, hotelDetailsResponse);
    }
  }, [isSuccess, isError, response]);

  const { changingYourDates, modifyingGuestsText, datesChanged, seeUpdatedBooking, contactUsText, tryAgainText,
    dateModificationFailed, modificationFailedMsg, backToBooking, guestsModifiedText, paxModificationFailedText } = getStaticData(LANG_AWARE.MODIFICATION);

  const {
    hotelName, bookingID, corporateBooking, newDatesText, oldDatesText
  } = response;

  const successColorAnim = colorAnim.interpolate({
    inputRange: [0, 1],
    outputRange: ["#ffedd1", "#B6EBE2"]
  });
  const errorColorAnim = colorAnim.interpolate({
    inputRange: [0, 1],
    outputRange: ["#ffedd1", "#FFD3D4"]
  });

  const navigateTobookingFromTy = () => {
    if (modificationType === MODIFICATION_TYPE.DATE_CHANGE) {
      HotelBookingTrackingHelper.trackClickEvent(trackingPageName.current, CHANGE_DATE_SEE_UPDATED_BOOKING, hotelDetailsResponse);
    } else {
      HotelBookingTrackingHelper.trackClickEvent(trackingPageName.current, ADD_GUESTS_SEE_UPDATED_BOOKING, hotelDetailsResponse);
    }
    resetToHotelDetail(bookingID);
  }

  const navigateTobooking = () => {
    if (modificationType === MODIFICATION_TYPE.DATE_CHANGE) {
      HotelBookingTrackingHelper.trackClickEvent(trackingPageName.current, CHANGE_DATE_GO_BACK_TO_BOOKING, hotelDetailsResponse);
    } else {
      HotelBookingTrackingHelper.trackClickEvent(trackingPageName.current, ADD_GUEST_GO_BACK_TO_BOOKING, hotelDetailsResponse);
    }
    resetToHotelDetail(bookingID);
  }

  const onPressContactUs = () => {
    if (modificationType === MODIFICATION_TYPE.DATE_CHANGE) {
      HotelBookingTrackingHelper.trackClickEvent(trackingPageName.current, CHANGE_DATE_CONTACT_US, hotelDetailsResponse);
    } else {
      HotelBookingTrackingHelper.trackClickEvent(trackingPageName.current, ADD_GUEST_CONTACT_US, hotelDetailsResponse);
    }
    helpingHandClickHandler();
  }

  const onPressRetry = () => {
    if (modificationType === MODIFICATION_TYPE.DATE_CHANGE) {
      HotelBookingTrackingHelper.trackClickEvent(trackingPageName.current, CHANGE_DATE_RETRY, hotelDetailsResponse);
    } else {
      HotelBookingTrackingHelper.trackClickEvent(trackingPageName.current, ADD_GUEST_RETRY, hotelDetailsResponse);
    }
    handleTryAgain();
  }

  useEffect(() => {
    if (isSuccess || isError) {
      Animated.parallel([
        Animated.timing(colorAnim, {
          toValue: 1,
          duration: 700,
        }),
        Animated.timing(heightAnim, {
          toValue: Dimensions.get('window').height - (Platform.OS == 'ios' ? 100 : 0 ),
          duration: 700,
          easing: Easing.linear,
          useNativeDriver: false
        })
      ]).start(() => setShowCTA(true))
    }
  }, [isSuccess, isError]);

  return (
    <CommonBottomOverlayMessage customStyle={AtomicCss.borderTopRadius16} isBorderRadius isCancellable={false} isForceClose={isForceClose} contentMaxHeight={'100%'} content={
      <Animated.View style={{ height: heightAnim }}>
        <Animated.View style={[styles.topCircle, { backgroundColor: isSuccess ? successColorAnim : errorColorAnim }]}>
          <View style={styles.loaderWrapper}>
            {isLoading && <ActivityIndicator size="large" color={colors.yellow5} />}
            {isSuccess && <SuccessLoaderAnim />}
            {isError && <Image source={RED_CROSS_ICON} style={styles.iconStyle} />}
          </View>
        </Animated.View>
        {
          isLoading &&
          <View style={[AtomicCss.alignCenter, AtomicCss.justifyCenter]}>
            <Text style={[styles.title]}>{modificationType === MODIFICATION_TYPE.DATE_CHANGE ? changingYourDates : modifyingGuestsText}</Text>
          </View>
        }
        {
          isSuccess &&
          <View style={{ flex: 1 }}>
            <View style={[AtomicCss.alignCenter, AtomicCss.justifyCenter]}>
              <Text style={[modificationType === MODIFICATION_TYPE.DATE_CHANGE ? styles.successTitle : styles.successTitleModifyPax]}>{modificationType === MODIFICATION_TYPE.DATE_CHANGE ? datesChanged : guestsModifiedText}</Text>
              {modificationType === MODIFICATION_TYPE.DATE_CHANGE  && <Text style={[styles.newDates]}>{newDatesText}</Text>}
              {modificationType === MODIFICATION_TYPE.DATE_CHANGE && <Text style={[styles.oldDates]}>{oldDatesText}</Text>}
              {modificationType === MODIFICATION_TYPE.ADD_GUEST && <Text style={[styles.hotelNameModifyPax]}>{hotelName}</Text>}
            </View>
            {
              showCTA &&
              <View style={[AtomicCss.alignCenter, { position: 'absolute', alignSelf: 'center', width: '100%', bottom: Platform.OS == 'ios' ? 40 : 80 }]}>
                <ButtonWrapper
                  clickHandler={navigateTobookingFromTy}
                  buttonTextStyle={fsStyle.font16}
                  btnText={seeUpdatedBooking}
                />
              </View>
            }
          </View>
        }
        {
          isError &&
          <View style={{ flex: 1 }}>
            <View style={{ width: '80%', alignSelf: 'center' }}>
              <View style={[AtomicCss.alignCenter, AtomicCss.justifyCenter]}>
                <Text style={[styles.errorTitle, AtomicCss.textCenter]}>
                  {getErrorPageHeading(modificationType === MODIFICATION_TYPE.DATE_CHANGE ? dateModificationFailed : paxModificationFailedText, response)}
                </Text>
                <Text style={[styles.hotelName]}>{hotelName}</Text>
                <Text style={[styles.failedText]}>{getErrorPageDesc(modificationFailedMsg, response)}</Text>
              </View>
            </View>
            {
              showCTA && (showErrorPageCTA(ERROR_CTAS_ACTION_ID.RETRY, response) || showErrorPageCTA(ERROR_CTAS_ACTION_ID.BACK_TO_BOOKING, response)) &&
              <View style={{ position: 'absolute', alignSelf: 'center', width: '100%', bottom: Platform.OS == 'ios' ? 40 : 80 }}>
                <View style={[AtomicCss.alignCenter]}>
                {showErrorPageCTA(ERROR_CTAS_ACTION_ID.RETRY, response) && 
                  <ButtonWrapper
                    clickHandler={onPressRetry}
                    btnText={tryAgainText}
                    buttonWidth='full'
                  />
                }
                </View>
                <View style={[AtomicCss.flexRow, showErrorPageCTA(ERROR_CTAS_ACTION_ID.BACK_TO_BOOKING, response) && showErrorPageCTA(ERROR_CTAS_ACTION_ID.HELPING_HAND, response) && showHelpingHand ? AtomicCss.spaceBetween : AtomicCss.justifyCenter]}>
                  {showErrorPageCTA(ERROR_CTAS_ACTION_ID.BACK_TO_BOOKING, response) && <TouchableOpacity onPress={navigateTobooking}>
                    <Text style={styles.backToBooking }>{backToBooking}</Text>
                  </TouchableOpacity>}
                  {showErrorPageCTA(ERROR_CTAS_ACTION_ID.HELPING_HAND, response) && showHelpingHand && <TouchableOpacity onPress={onPressContactUs}>
                    <Text style={styles.backToBooking}>{contactUsText.toUpperCase()}</Text>
                  </TouchableOpacity>}
                </View>
              </View>
            }
          </View>
        }
      </Animated.View>
    } />
  )
}

const createStyle = (theme, fonts)=> StyleSheet.create({
  topCircle: {
    width: '100%',
    height: 230,
    borderBottomEndRadius: 220,
    borderBottomStartRadius: 220,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    transform: [{ scaleX: 1.5 }],
    marginTop: -20,
    marginBottom: 40
  },
  title: {
    color: colors.darkBrown,
    ...fonts.font16,
    fontFamily: fonts.black,
    marginBottom: 20,
    lineHeight: 16
  },
  successTitle: {
    color: colors.darkCyan,
    ...fonts.font18,
    fontFamily: fonts.black,
    marginBottom: 20,
    marginTop: 40,
    lineHeight: 18
  },
  successTitleModifyPax: {
    color: colors.darkCyan,
    ...fonts.font24,
    fontFamily: fonts.bold,
    fontWeight: 'bold',
    marginBottom: 10,
    marginTop: 40,
    lineHeight: 32
  },
  iconStyle: {
    width: 76,
    height: 76,
    borderRadius: 8
  },
  errorTitle: {
    color: colors.red,
    ...fonts.font24,
    fontFamily: fonts.black,
    marginBottom: 10,
    marginTop: 60,
  },
  newDates: {
    color: colors.darkCyan,
    ...fonts.font16,
    fontFamily: fonts.black,
    marginBottom: 10,
    lineHeight: 16
  },
  oldDates: {
    color: colors.darkCyan,
    ...fonts.font12,
    fontFamily: fonts.regular,
    lineHeight: 16,
    textDecorationLine: 'line-through'
  },
  hotelName: {
    color: colors.black,
    ...fonts.font14,
    fontFamily: fonts.medium,
  },
  hotelNameModifyPax: {
    color: colors.black,
    ...fonts.font18,
    fontFamily: fonts.medium,
  },
  failedText: {
    color: colors.black,
    ...fonts.font12,
    fontFamily: fonts.regular,
    marginTop: 50,
    lineHeight: 20,
    textAlign: 'center'
  },
  backToBooking: {
    color: theme.color.primary,
    ...fonts.font16,
    fontFamily: fonts.black,
    lineHeight: 20,
    textAlign: 'center',
    marginTop: 20
  },
  loaderWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    transform: [{ scaleX: 0.7 }],
  },
})

export default React.memo(ModificationOverlay);
