export const colors = {
  blueStartColor: '#5f88ff',
  blueEndColor: '#a768ff',
  blackStartColor: '#1f1c2c',
  blackEndColor: '#928dab',
  orangeStartColor: '#ff3e5e',
  orangeEndColor: '#ff7f3f',
  darkBlueStartColor: '#912086',
  darkBlueEndColor: '#3023ae',
  darkBlueCancelledStart: '#053cb2',
  darkBlueCancelledEnd: '#0c6fd9',
  confirmedStartColor: '#c2f4e9',
  confirmedEndColor: '#c2f4e9',
  cancelledStartColor: '#fbedcf',
  cancelledEndColor: '#fbedcf',
  failedStartColor: '#fedcde',
  failedEndColor: '#fedcde',
  completeStartColor: '#e7e7e7',
  completeEndColor: '#e7e7e7',
  greenColor: '#c2f4e9'
};
