import { PokusLobs } from '@mmt/legacy-commons/Native/AbConfig/AbConfigKeyMappings';
import { getPokusConfig, getAbConfigWaitingPromise } from '@mmt/legacy-commons/Native/AbConfig/AbConfigModule';

export const RailsAbConfig = {
  railsTravelersAdvisory: 'rails_travelers_advisory',
  railsTravelersComponentOrder: 'rails_travelers_segment_order',
  railsSortAndFilterRevamp: 'rails_listing_filter_revamp',
  railsSortAndFilterRevampClass : 'rails_listing_filter_revamp_class',
  railsSortAndFilterRevampAvailability : 'rails_listing_filter_revamp_availability',
  railsNuLpBanner:'Rails_NU_LP_Banner',
  railsAppTamilVernac: 'railsAppTamilVernac',
  railsRISsectionLTStileNewIconTag: 'rail_lts_rislp_new_tag',
  railsTGSrislpIconWithNewTag: 'rails_tgs_rislp_icon_witnewtag',
  railsTGSCarouselTag: 'rails_tgs_rislp_carousel_order',
  railsCalendarVersion: 'rails_calendar_version',
  railsLtsEnableOfflinePIPMode: 'rails_ris_lts_offline_pip',
  railsLtsEnabledStationAlarm: 'Rails_RIS_LTS_StationAlarm_ONOFF',
  railsLtsEnableDynamicSpeedComputation: 'rails_ris_lts_offline_speed_computation',
  railsEnableLtsImprovementChanges:'rails_enable_lts_improvement_changes',
  railsInsuranceDefaultSelected: 'rails_travelers_insurance_default',
  railsAgentTxnErrorRetry: 'rails_agent_txn_error_retry',
  railsReportGPSErrors: 'railsReportGPSErrors',
  railsReportCTErrors: 'railsReportCTErrors',
  railsTravelersIRCTCaccountToast: 'rails_travelers_irctc_account_toast',
  railsTGSAppFunnelNativeOrMweb:'Rails_TGS_Apps_funnel_payments_Native_vs_Mweb',
  showStationChangeAlert: 'rails_listing_nearbystation_bottomsheet',
  railsRISiconPaletteVersion: 'rails_rislp_risiconpallete_version',
  railsWhatsAppMessage:'rails_tgs_whatsapp',
  railsRouteExtenstionBottomsheet: 'rails_route_extension_bottomsheet',
  railsRouteExtensionV2: 'rails_route_extension_v2',
  priceBreakupCollapsed: 'rails_review_fare_breakup_collapsed',
  railsTravelerResistanceBottomsheet: 'rail_travelers_back_resistance',
  railsReviewPageResistanceBottomsheet: 'rail_review_back_resistance',
  railsListingSortingLogic: 'rails_listing_sorting_logic',
  showBusTrainCrossSell: 'rails_bus_cross_pnrpage',
  railsTravelersFCBottomSheet: 'rails_travelers_fc_prompt',
  railsOldPNRPage:'rails_old_pnr_page',
  railsFcWidgetNew: 'rails_fc_widget_new',
  railsThankyouPageCouponLounge : 'rails_thankyou_couponlounge',
  railsReportIrctcErrors:'railsReportIrctcErrors',
  railsAdfeedOnListing:'rails_listing_adfeed_onapps',
  railsIrctcNewReloadButton : 'rails_irctc_new_reload_button',
  railsNewTgWidget: 'rail_tg_widget_rac_apps',
  railsTGFCTogether:'mmt.app.rail.default.listing.default.rails_FC_TG_V1',
  railsCanLoadListingV3: 'rails_listing_load_time_improvements',
  railsTGFCTogetherPrompt:'mmt.app.rail.default.listing.default.rail_fc_and_tg_prompt',
  railsReportCustomFirebaseErrors:'mmt.app.rail.default.default.default.report_custom_firebase_errors',
  railsFcPrefCache:'mmt.app.rail.default.default.default.rails_fc_cachepref_final',
  railsCanAutoReloadIRCTC:'mmt.app.rail.default.default.default.rails_auto_reload_irctc',
  railsShowBNPPv1:'mmt.app.rail.default.default.default.rails_bnpp_v1',
  //ABCleanup:rails_ris_lts_location_choice on 4th Jan 24 defaulting to false
  tgsTandCwhatsappTag:'mmt.app.rail.default.default.default.tgs_TandC_whatsappTag',
  enableGrafanaLogger:'mmt.app.rail.default.default.default.rails_enable_grafana_logger',
  railsRisGPTConfig: 'mmt.app.rail.default.default.default.rails_ris_gpt_config',
  railsFCBNPPPrompt: 'mmt.app.rail.default.review.default.rail_fc_bnpp_prompt',
  railsConnectedTravel: 'mmt.app.rail.default.default.default.rail_connected_travel',
  railsCanEnableEnvSwitch:'mmt.app.rail.default.default.default.rail_enable_env_switch',
  railsTGPlus: 'mmt.app.rail.default.default.default.rails_tg_plus',
  railsEnableLTSErrorLogging: 'mmt.app.rail.default.default.default.lts_enable_error_logging',
  railsOfflineSwitchingLogic: 'mmt.app.rail.default.default.default.lts_offline_switching_logic',
  railsFcCallout: 'mmt.app.rail.default.default.default.rail_fc_callout',
  rails_tg_wl_green: 'mmt.app.rail.default.default.default.rails_tg_wl_green',
  railsNearbyDatesRevamp: 'mmt.app.rail.default.default.default.rail_nearbydates',
  railsTgLowCpBucket: 'mmt.app.rail.default.default.default.show_TG_low_CP_1',
}

export const getRailsPokusConfig = (configKey, defaultValue) => {
  try {
    return getPokusConfig(PokusLobs.RAIL, configKey, defaultValue);
  } catch (e) {
    return defaultValue;
  }
}

export const getTravelerComponentOrder = () => {
  return getRailsPokusConfig(RailsAbConfig.railsTravelersComponentOrder, 'bd1,irctc2,ln3,trav4,pref5,fc7,cd8,disc9');
}
export const getGreenWaitlistedComponent = () => {
  return getRailsPokusConfig(RailsAbConfig.rails_tg_wl_green, false);
}
export const getRailsTravelerAdvisoryOnOff = () => {
  return getRailsPokusConfig(RailsAbConfig.railsTravelersAdvisory, false);
}

export const getRailsSortAndFilterRevamp = () => {
  return getRailsPokusConfig(RailsAbConfig.railsSortAndFilterRevamp, true);
}

export const getRailsSortAndFilterRevampClass = () => {
  return getRailsPokusConfig(RailsAbConfig.railsSortAndFilterRevampClass, true);
}

export const getRailsSortAndFilterRevampAvailability = () => {
  return getRailsPokusConfig(RailsAbConfig.railsSortAndFilterRevampAvailability, true);
}

export const getRailsNuLpBanner = () => {
  return getRailsPokusConfig(RailsAbConfig.railsNuLpBanner, false);
}

export const getRailsAppTamilVernac = () => {
  return getRailsPokusConfig(RailsAbConfig.railsAppTamilVernac, false);
}

export const getRISsectionNewTag = () => {
  return getRailsPokusConfig(RailsAbConfig.railsRISsectionLTStileNewIconTag, false);
}

export const getTGSsectionNewTag = () => {
  return getRailsPokusConfig(RailsAbConfig.railsTGSrislpIconWithNewTag, false);
}

export const getTGSCarouselTag = () => {
  return getRailsPokusConfig(RailsAbConfig.railsTGSCarouselTag, 'TGS|LTS');
}

export const getPNRPageConfig = () => {
  return getRailsPokusConfig(RailsAbConfig.railsOldPNRPage, false);
}


export const showBusTrainCrossSell = () => {
  return getRailsPokusConfig(RailsAbConfig.showBusTrainCrossSell, false);
};

export const getTGSWhatsappTag = () => {
 return getRailsPokusConfig(RailsAbConfig.railsWhatsAppMessage, false);
}

export const getTGSTandCWhatsappTag = () => {
  return getRailsPokusConfig(RailsAbConfig.tgsTandCwhatsappTag, false);
}


export const getRailsTGSAppFunnelNativeOrMweb = () => {
  return getRailsPokusConfig(RailsAbConfig.railsTGSAppFunnelNativeOrMweb, '1');
}
export const getRailsCalendarVersion = () => {
  return getRailsPokusConfig(RailsAbConfig.railsCalendarVersion, "V1");
}

export const getClusterBottomSheetForStationChangeAlert = () => {
  return getRailsPokusConfig(RailsAbConfig.showStationChangeAlert,true);
}

export const getRisIconPaletteVersion = () => {
  return getRailsPokusConfig(RailsAbConfig.railsRISiconPaletteVersion, '1');
}

export const getRisIconGPTConfig = () => {
  return getRailsPokusConfig(RailsAbConfig.railsRisGPTConfig, 0);
}

//LTS
export const getIsLTSAlarmEnabled = () => {
  return Platform.OS === 'android' && getRailsPokusConfig(RailsAbConfig.railsLtsEnabledStationAlarm, false);
}

export const getIsOfflinePIPModeEnabled = () => {
  return Platform.OS === 'android' && getRailsPokusConfig(RailsAbConfig.railsLtsEnableOfflinePIPMode, false);
}

export const getIsDynamicSpeedComputationEnabled = () => {
  return Platform.OS === 'android' && getRailsPokusConfig(RailsAbConfig.railsLtsEnableDynamicSpeedComputation, false);
}

export const getEnableLtsImprovementChanges = () => {
  return __DEV__ ? false : Platform.OS === 'android' && getRailsPokusConfig(RailsAbConfig.railsEnableLtsImprovementChanges, false);
}

export const getCanReportError = (isGPS = true) => {
  return (
    Platform.OS === 'android' &&
    getRailsPokusConfig(
      isGPS ? RailsAbConfig.railsReportGPSErrors : RailsAbConfig.railsReportCTErrors,
      false,
    )
  );
};

//End of LTS

export const getCanReportIRCTCError = () => {
  return getRailsPokusConfig(RailsAbConfig.railsReportIrctcErrors, false);
};

export const getRailsInsuranceDefaultSelected = () => {
  return getRailsPokusConfig(RailsAbConfig.railsInsuranceDefaultSelected, 1)
}

export const getRailsAgentTxnErrorRetry = () => {
  return getRailsPokusConfig(RailsAbConfig.railsAgentTxnErrorRetry, [3, 5, 7]);
}

/* Travellers Details Pokus */
export const getRailsIrctcAccountToast = () => {
  return getRailsPokusConfig(RailsAbConfig.railsTravelersIRCTCaccountToast, true);
}

export const getTravelersBackButtonResistanceBottomsheet = () => {
  return getRailsPokusConfig(RailsAbConfig.railsTravelerResistanceBottomsheet, false);
}

export const getReviewPageBackButtonResistanceBottomsheet = () => {
  return getRailsPokusConfig(RailsAbConfig.railsReviewPageResistanceBottomsheet, false)
};

export const getRailsRouteExtenstionV2 = () => {
  return getRailsPokusConfig(RailsAbConfig.railsRouteExtensionV2, true);
}

export const getRailsRouteExtenstionBottomsheet = () => {
  return getRailsPokusConfig(RailsAbConfig.railsRouteExtenstionBottomsheet, true);
}

export const getReviewPagePriceBreakupCollapsed = () => {
  return getRailsPokusConfig(RailsAbConfig.priceBreakupCollapsed, false);
}
export const getRailsListingSortingLogic = () => {
  return getRailsPokusConfig(RailsAbConfig.railsListingSortingLogic, '3');
}

export const getTravelerFreeCancellationBottomSheet = () => {
  return getRailsPokusConfig(RailsAbConfig.railsTravelersFCBottomSheet, false);
}

export const getTravellerFcWidgetNew = () => {
  return getRailsPokusConfig(RailsAbConfig.railsFcWidgetNew, true);
}

export const getThankYouPageCouponLounge = () => {
  return getRailsPokusConfig(RailsAbConfig.railsThankyouPageCouponLounge, true);
}

export const getShowAdsOnListingPokus = () => {
  return getRailsPokusConfig(RailsAbConfig.railsAdfeedOnListing, false);
}

export const getIrctcNewReloadButton = () => {
  return getRailsPokusConfig(RailsAbConfig.railsIrctcNewReloadButton, true);
}

export const getRailsNewTgWidget = () => {
  return getRailsPokusConfig(RailsAbConfig.railsNewTgWidget, false);
}

export const getIfToShowFCTGTogether = () => {
  return getRailsPokusConfig(RailsAbConfig.railsTGFCTogether, false);
}

export const getIfToShowFCTGTogetherPrompt = () => {
  return getRailsPokusConfig(RailsAbConfig.railsTGFCTogetherPrompt, 2);
}

export const getCanLoadListingv3 = () => {
  return getRailsPokusConfig(RailsAbConfig.railsCanLoadListingV3, false);
}

export const getFcPrefCache = () => {
  return getRailsPokusConfig(RailsAbConfig.railsFcPrefCache, false);
}

export const getIfAutoReloadIRCTC = () => {
  return getRailsPokusConfig(RailsAbConfig.railsCanAutoReloadIRCTC, true);
};

export const getIfCanShowBNPP = () => {
  return getRailsPokusConfig(RailsAbConfig.railsShowBNPPv1, false);
};

export const getIfCanShowNewBNPPTagWhenFC = () => {
  return getRailsPokusConfig(RailsAbConfig.railsFCBNPPPrompt, false);
};

export const getIfCanEnableGrafanaLogger = () => {
  return !__DEV__ && getRailsPokusConfig(RailsAbConfig.enableGrafanaLogger, false);
};

export const getIfToShowRailsConnectedTravel = () => {
  return getRailsPokusConfig(RailsAbConfig.railsConnectedTravel, false);
};

export const getIfCanEnableEnvSwitch = () => {
  return getRailsPokusConfig(RailsAbConfig.railsCanEnableEnvSwitch, false);
};

export const getRailsTGVersion = () => {
  return getRailsPokusConfig(RailsAbConfig.railsTGPlus, 1);
}

export const getIfEnableLTSErrorLogging = () => {
  return getRailsPokusConfig(RailsAbConfig.railsEnableLTSErrorLogging, false);
};

export const getOfflineSwitchingLogic = () => {
  return getRailsPokusConfig(RailsAbConfig.railsOfflineSwitchingLogic, 'V1');
};

export const getRailsFcCalloutFlag = () => {
  return getRailsPokusConfig(RailsAbConfig.railsFcCallout, true);
};

export const getNearbyDatesNew = () => {
  return getRailsPokusConfig(RailsAbConfig.railsNearbyDatesRevamp, false);
}

export const getTgLowCpBucket = () => {
  return getRailsPokusConfig(RailsAbConfig.railsTgLowCpBucket, false);
}
