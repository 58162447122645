import React from 'react';
import { StyleSheet, Text, View, Image } from 'react-native';
import PropTypes from 'prop-types';
import BasePage from '../../../../../Common/PostSalesBasePage';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';
import { ZC_POLICY_ICON, DB_POLICY_ICON } from '../../../../FlightImageConstants';
import HTMLView from 'react-native-htmlview';
import { getFont } from '../../../../../PostSalesStyles';
import { ThemeContext } from 'apps/post-sales/src/theme/theme.context';
class TopCancellationPolicyMsg extends BasePage {
  static contextType = ThemeContext;
  render() {
    return <View style={[styles.whiteWrapper]}>{this.getMsgList()}</View>;
  }

  getMsgList = () => {
    const { fsStyle, ...fonts } = getFont(true);
    const {color} = this.context.psTheme;
    const views = [];
    if (this.props.zcMsg) {
      views.push(
        <View style={[AtomicCss.flexRow, AtomicCss.marginBottom10]}>
          <Image style={styles.zcIconStyle} source={ZC_POLICY_ICON} />
          <Text
            style={[
              AtomicCss.flex1,
              AtomicCss.defaultText,
              fsStyle.font14,
              fonts.regularFontFamily,
              AtomicCss.lineHeight20,
            ]}
          >
            {this.props.zcMsg}
          </Text>
        </View>,
      );
    }
    if (this.props.dbMsg) {
      views.push(
        <View style={[AtomicCss.flexRow, AtomicCss.marginBottom10]}>
          <Image style={styles.dbIconStyle} source={DB_POLICY_ICON} />
          <Text
            style={[
              AtomicCss.flex1,
              AtomicCss.defaultText,
              fsStyle.font14,
              fonts.regularFontFamily,
              AtomicCss.lineHeight20,
            ]}
          >
            {this.props.dbMsg}
          </Text>
        </View>,
      );
    }
    if (this.props.topMsg && !this.props.isTopMsgCardHtml) {
      views.push(
        <View style={[AtomicCss.flexRow, AtomicCss.marginBottom10]}>
          <Text
            style={[
              AtomicCss.flex1,
              fsStyle.font14,
              AtomicCss.lineHeight20,
              (this.props.isSubscriptionBooking ? 
                [{color: color.green}, fonts.boldFontFamily] : 
                [{color: color.defaultTextColor}, fonts.regularFontFamily]
              )
            ]}
          >
            • {this.props.topMsg}
          </Text>
        </View>
      );
    }
    if (this.props.topMsg && this.props.isTopMsgCardHtml) {
      views.push(
        <View style={[AtomicCss.flexRow, AtomicCss.marginBottom10]}>
          <HTMLView
            style={{ marginHorizontal: 5, backgroundColor: 'transparent' }}
            value={`<q>${this.props.topMsg}</q>`}
            stylesheet={htmlStyle()}
            onLinkPress={this.onLinkPress}
          />
        </View>,
      );
    }
    return views;
  };
}

const htmlStyle = () =>
  StyleSheet.create({
    q: {
      /* fix fo Html Fonts Not working without adding blank tag */
    },
    p: {
      fontSize: getFont(true).fontSize.font14,
    },
    span: {
      color: colors.green,
    },
    a: {
      color: colors.linkBlue,
    },
  });

const styles = StyleSheet.create({
  whiteWrapper: {
    padding: 16,
    backgroundColor: '#ffffff',
    marginBottom: 10,
    ...getPlatformElevation(2),
    marginTop: -2,
  },
  zcIconStyle: {
    width: 10,
    height: 13,
    marginRight: 10,
    marginTop: 3,
  },
  dbIconStyle: { width: 16, height: 15, marginRight: 5 },
});
export default TopCancellationPolicyMsg;

TopCancellationPolicyMsg.propTypes = {
  topMsg: PropTypes.string.isRequired,
  zcMsg: PropTypes.string.isRequired,
  dbMsg: PropTypes.string.isRequired,
};

TopCancellationPolicyMsg.defaultProps = {
  topMsg: undefined,
  zcMsg: undefined,
  dbMsg: undefined,
};
