import React from 'react';
import { View, Text, Image } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import createStyles from '../ChangeDateOverlay/changeDateOverlayCss';
import HTMLViewWrapper from '../../../Common/HTMLViewWrapper';
import BaseButton from '../../../Common/BaseButton';
import { getFont } from '../../../PostSalesStyles';
import { useTheme } from '../../../theme/theme.context';
import isEmpty from 'lodash/isEmpty';
import { PATH_ICON } from '../../BusImageConstants';
function BusNotFoundOverlay({ response, handleOverlay }) {
  const { responseBody, cta } = response || {};
  const { title, buttonType } = cta || {};
  const { heading, subHeading } = responseBody || {};
  const { fsStyle, ...fonts } = getFont();
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);
  return (
    <View style={styles.OverlayContentWrapper}>
      <View style={AtomicCss.marginBottom24}>
        <View style={styles.overlayTopline}></View>
        <Image source={PATH_ICON} style={styles.locationIcon} />
        <View style={styles.headingWrapper}>
          {!!heading && (
            <Text style={[[styles.headingTxt, AtomicCss.marginBottom18]]}>{heading}</Text>
          )}
          {!!subHeading && <HTMLViewWrapper htmlContent={subHeading} />}
        </View>
      </View>
      {!isEmpty(cta) && (
        <BaseButton
          text={title}
          variant={buttonType}
          clickHandler={handleOverlay}
          textStyle={AtomicCss.font16}
        />
      )}
    </View>
  );
}
export default BusNotFoundOverlay;
