import React from 'react';
import PropTypes from 'prop-types';
import {View, Text, Image, TouchableOpacity, StyleSheet} from 'react-native';
import Actions from '../../../navigation/postSalesNavigation';
import AtomicCss from '../../commonStyles/AtomicCss';
import RailBookingDetails from "../../details/RailBookingDetails";
import  {HEADER_BACK_ICON} from '../../Utils/RailImageConstant';
import { getFont } from '../../../PostSalesStyles';

class StickyHeader extends React.Component {
  constructor(props) {
    super(props);
  }
  static navigationOptions = {
    header: null
  };

  GoBackHandler = () => {
    Actions.pop();
  }

  render() {
    const { fsStyle, ...fonts } = getFont(true);
    return (
        <View style={styles.WhiteStickyHeader}>
          <View style={styles.topStatusBar}>
            <TouchableOpacity onPress={this.props.goBackToBooking ? this.props.onBackPress : this.GoBackHandler}>
              <Image style={styles.backarrow} source={HEADER_BACK_ICON} />
            </TouchableOpacity>
            <View style={AtomicCss.marginLeft12}>
              <Text style={[AtomicCss.blackText, fonts.blackFontFamily, AtomicCss.marginBottom5, fsStyle.font14]}>
                {this.props.heading}
              </Text>
              <Text style={[AtomicCss.defaultTextColor, fsStyle.font12, fonts.regularFontFamily]}>
                {this.props.subheading}
              </Text>
            </View>
          </View>
        </View>
    );
  }
}

StickyHeader.propTypes = {
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string.isRequired,
  goBackToBooking: PropTypes.bool
};

const styles = StyleSheet.create({
  WhiteStickyHeader: {
    backgroundColor: '#fff',
    paddingTop: 10,
    paddingBottom: 10,
    elevation: 3,
    shadowColor: '#000',
    shadowOffset: {width: 0, height: 4},
    shadowOpacity: 0.3,
    shadowRadius: 2
  },
  topStatusBar: {
    flexDirection: 'row',
    paddingLeft: 10,
    paddingRight: 20,
    alignItems: 'center'
  },
  backarrow: {
    width: 16,
    height: 16,
    marginHorizontal: 10,
    marginVertical: 10
  }
});

export default StickyHeader;
