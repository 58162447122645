import React, {useState, useEffect} from 'react';
import {View, Text,TouchableOpacity,Image,ScrollView, Share, AppState} from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import styles from './QRTicketCardCss';
import WarningMsgCard from './WarningMsgCard';
import PassengerTab from './PassengerTab';
import Fecha from 'fecha';
import QRCode from 'react-qr-code';
import {getTicketObject, handleClickEvent} from '../MetroBookingDetailUtil';
import MetroConstant from '../MetroConstant';
import {RIGHT_ARROW,ACTIVE_TICK_ICON } from '../MetroImageConstant';

class QRTicketCard extends React.Component{
constructor(props){
    super(props);
    this.state={
        activeTickIndex:0,
        activeTab:0,
        isAppActive: AppState.currentState === 'active'
    }
    this.activeTickHandler = this.activeTickHandler.bind(this);
    this.handleTab = this.handleTab.bind(this);
    this.ticket = {};
}

  componentDidMount() {
    AppState.addEventListener('change', this.handleAppStateChange);
  }

static navigationOptions = {
  header: null
};

activeTickHandler = (index) => {
    this.setState({activeTickIndex:index});
    let action = {
      actionFamily: MetroConstant.JOURNEY_TYPE
    }
    handleClickEvent(this.props.card, action, this.props.response, this.props.pageName);
}

handleTab(index){
    this.setState({
        activeTab: index
    });
    let action = {
      actionFamily: MetroConstant.PASSENGER_TAB
    }
    handleClickEvent(this.props.card, action, this.props.response, this.props.pageName);
}

shareTicket = (ticket) => {
    let ticketObject = getTicketObject(this.props.response, ticket);

    Share.share(ticketObject);

    let action = {
      actionFamily: MetroConstant.PASSENGER_TAB
    }
    handleClickEvent(this.props.card, action, this.props.response, this.props.pageName);
}

 handleAppStateChange = () => {
   this.setState({
     isAppActive: AppState.currentState === 'active'
   });
  };

render() {
  const oneWayTrip = this.props.response.bookingInfo.serviceType === "FORWARD";
  const ticketList = this.props.response.bookingInfo.ticketInfoList;
  const totalTicketCount = this.props.response.bookingInfo.ticketInfoList.length;
  const switchOption = oneWayTrip ? ["FORWARD"] : ["FORWARD", "RETURN"];
  const tripType = oneWayTrip ? "One Way" : "Return";
  const ticketCount = oneWayTrip ? totalTicketCount : totalTicketCount / 2;

  let passengerData = [];
  ticketList.map((item, index) => {
    if (oneWayTrip)
      passengerData.push(item)
    else {
      if (index % 2 == 0)
        passengerData.push(item)
    }
  });

  this.passengerData = passengerData;
  this.switchOption = switchOption;

  this.ticket = this.getTicket(ticketList);
  let expiryDateMsg = null;
  const expiryDate = this.ticket.expiryDateTime && Fecha.parse(this.ticket.expiryDateTime, 'DD-MMM-YYYY hh:mm A');
  if (expiryDate !== 'undefined') {
    expiryDateMsg = `${Fecha.format(expiryDate, 'DD MMM YYYY hh:mm A')}`;
  }
    return(
        <ScrollView stickyHeaderIndices={[0]} style={styles.cardWrapper}>
            <View style={{elevation:2,zIndex:5}}>
           </View>
             <View style={{elevation:2,zIndex:10}}>
            </View>

          <View style={[styles.Card,AtomicCss.marginTop5]}>
            { this.getSwitchOption(oneWayTrip, ticketCount) }
              <View style={[AtomicCss.alignCenter, AtomicCss.flexRow, ,AtomicCss.marginTop10, AtomicCss.marginBottom10, {flexDirection:'column'}]}>
                { this.state.isAppActive &&
                  <View style={AtomicCss.marginBottom10}>
                    <QRCode
                      value={this.ticket.content}
                      size={this.ticket.dimensionPixel}
                    />
                  </View>
                }
                  <View style={AtomicCss.marginBottom15}><Text style={[AtomicCss.blackText, AtomicCss.font12]}>{this.ticket.ticketId}</Text></View>

                  {/*To be done later*/}
                  {/*<TouchableOpacity activeOpacity={1} onPress={() => this.shareTicket(this.ticket)}>*/}
                  {/*  <Text style={[AtomicCss.font12, AtomicCss.boldFont, styles.linkText]}>SHARE</Text>*/}
                  {/*</TouchableOpacity>*/}
              </View>

              {this.props.response.bookingState.state !== 'Completed' && this.getMessage(expiryDateMsg)}

          </View>
        </ScrollView>
    )
}

getMessage(expiryDateMsg) {
  const tr = expiryDateMsg
  return(
    <View>
      <View style={AtomicCss.marginBottom5}><WarningMsgCard titleNormal={this.props.response.bookingInfo.validityMsg} titleBold={expiryDateMsg} /></View>

      <Text style={[AtomicCss.regularFont, AtomicCss.greyText, AtomicCss.font12, AtomicCss.textCenter]}>{this.props.response.bookingInfo.scanMessage}</Text>
    </View>
  )
}

getSwitchOption(oneWayTrip, ticketCount)
{
  return (
    <View>
      {!oneWayTrip && this.switchOption.length > 1 &&
      <View style={[styles.toggleSwitchWrapper, AtomicCss.flexRow, AtomicCss.marginBottom15]}>
        {
          this.switchOption.map((item, index) => {
            const activeTabStyle = (index === this.state.activeTickIndex) ? [styles.activeTab] : null;
            const activeTick = (index === this.state.activeTickIndex);
            const activeText = (index === this.state.activeTickIndex) ? [styles.activeTxt] : [styles.defaultTxt]
            return <TouchableOpacity key={index} style={[activeTabStyle, styles.switchButton]}
                                     onPress={() => this.activeTickHandler(index)}>{activeTick &&
            <Image style={styles.activeTickStyle} source={ACTIVE_TICK_ICON}/>}<Text
              style={activeText}>{item}</Text>
            </TouchableOpacity>
          })
        }
      </View>
      }
      { ticketCount > 1 &&
        <View style={[styles.tabWrapper, AtomicCss.marginBottom10]}>
          <ScrollView horizontal={true} showsHorizontalScrollIndicator={true}>
            {
              this.passengerData.map((item, index) => {
                return (
                  <PassengerTab
                    key={index}
                    index={index}
                    item={item}
                    tabText={this.props.response.bookingInfo.tabText}
                    count={index + 1}
                    handleTab={this.handleTab}
                    activeTab={this.state.activeTab}
                  />
                )
              })
            }
          </ScrollView>
        </View>
      }
    </View>
      )
}

  getTicket(ticketList) {
    let ticket = null;

    if(this.state.activeTickIndex===0){
      ticket = ticketList[this.state.activeTab]
    }
    else {
      let currTicket = ticketList[this.state.activeTab]
      ticketList.forEach((tick, index) => {
        if (tick.rjtId == currTicket.rjtId && tick.ticketId != currTicket.ticketId && tick.ticketEntryExitType === "RJT_RETURN") {
          ticket = tick;
        }
      });
    }
    return ticket
  }

  handleClick = (action) => {
    handleClickEvent(this.props.card, action, this.props.response, this.props.pageName);
  };
}
export default QRTicketCard;

