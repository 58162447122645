import React from 'react';
import { View, Text, TouchableOpacity, Image } from 'react-native';
import { CHECKBOX_TICK_ICON } from '../../../../FlightImageConstants';
import { isNullOrEmpty } from '../../../../../Common/commonUtil';
import styles from '../ClaimAncillaryRefundCss';
import { getFont } from '../../../../../PostSalesStyles';

const AncillaryComponent = React.memo(
  ({ info, allPaxSelected, group, card, onGroupItemSelection, selectedCard }) => {
    const { groupSelectionEnabled } = card || {};
    const { title, subtitle } = info || {};
    const { topHeader } = group || {};
    const isCurrentItemSelected = () => {
      const selectedData = selectedCard[card.id];
      let flag = false;
      selectedData &&
        selectedData.groupEntries &&
        selectedData.groupEntries.forEach((val) => {
          val.entries.forEach((data) => {
            if (val.id === group.id && data.id === info.id) {
              flag = true;
            }
          });
        });
      return flag;
    };
    const { fsStyle, ...fonts } = getFont(true);
    const selected = allPaxSelected || isCurrentItemSelected();
    const selectedStyle = selected ? 'Selected' : '';
    const isDisabled = false;
    const disableStyle = isDisabled ? [styles.paxDisabled] : '';
    const showCheckbox = groupSelectionEnabled;
    return (
      <TouchableOpacity
        activeOpacity={0.8}
        style={[styles.checkBtn, disableStyle]}
        onPress={() => showCheckbox && onGroupItemSelection(card, group, info)}
        disabled={isDisabled}
      >
        {showCheckbox && (
          <View
            style={[
              styles.checkbox,
              styles[`checkbox${selectedStyle}`],
              isDisabled ? styles.disableChkBox : '',
            ]}
          >
            {!isDisabled && selected && (
              <Image style={styles.checkedIcon} source={CHECKBOX_TICK_ICON} />
            )}
          </View>
        )}
        <View>
          <Text style={[styles.passengerName, fonts.boldFontFamily, fsStyle.font14]}>
            {topHeader} - {title}
          </Text>
          {!isNullOrEmpty(subtitle) && (
            <Text style={[styles.subtitle, { fontStyle: 'italic' }]}>{subtitle}</Text>
          )}
        </View>
      </TouchableOpacity>
    );
  },
);

export default AncillaryComponent;
