import { Platform } from "react-native";
import CabBookingDetailsConstant from "../cab/CabBookingDetailsConstant";
import {
  LAST_MINUTE_FIREBASE_CONFIG_PROD,
  LAST_MINUTE_FIREBASE_CONFIG_STAGING,
  FIREBASE_COMMON_CONFIG,
  UNDO_WEBCHECKIN_CONFIG_PROD,
  UNDO_WEBCHECKIN_CONFIG_STAGING,
  CAB_LIVE_TRACKING_CONFIG_PROD,
  CAB_LIVE_TRACKING_CONFIG_STAGING
} from './firebaseConfig';

// TODO: Get config from env variables
const isProd = process.env.NODE_ENV === 'production';

const LAST_MINUTE_FIREBASE_CONFIG = isProd ? LAST_MINUTE_FIREBASE_CONFIG_PROD : LAST_MINUTE_FIREBASE_CONFIG_STAGING;
const CAB_LIVE_TRACKING_CONFIG = isProd ? CAB_LIVE_TRACKING_CONFIG_PROD : CAB_LIVE_TRACKING_CONFIG_STAGING;
const FIREBASE_COMMON_APP_ID = Platform.OS == 'web' ? FIREBASE_COMMON_CONFIG.APP_ID_WEB : (Platform.OS == 'ios' ? FIREBASE_COMMON_CONFIG.APP_ID_IOS : FIREBASE_COMMON_CONFIG.APP_ID_ANDROID);
const FIREBASE_COMMON_API_KEY = Platform.OS == 'web' ? FIREBASE_COMMON_CONFIG.API_KEY_WEB : (Platform.OS == 'ios' ? FIREBASE_COMMON_CONFIG.API_KEY_IOS : FIREBASE_COMMON_CONFIG.API_KEY_ANDROID);

const UNDO_WEBCHECKIN_CONFIG = isProd ? UNDO_WEBCHECKIN_CONFIG_PROD : UNDO_WEBCHECKIN_CONFIG_STAGING;

const FIREBASE_CONFIG = {
  UNDO_WEBCHECKIN: {
    config: {
      apiKey: FIREBASE_COMMON_API_KEY,
      projectId: FIREBASE_COMMON_CONFIG.FLIGHT_FIREBASE_PROJECT,
      databaseURL: UNDO_WEBCHECKIN_CONFIG.FLIGHT_FIREBASE_DB_URL,
      storageBucket: FIREBASE_COMMON_CONFIG.STORAGE_BUCKET,
      messagingSenderId: FIREBASE_COMMON_CONFIG.MSG_SENDER_ID,
      appId: FIREBASE_COMMON_APP_ID
    },
    basePath: UNDO_WEBCHECKIN_CONFIG.BASE_PATH,
    corpBasePath: UNDO_WEBCHECKIN_CONFIG.CORP_BASE_PATH,
    tokenKey: UNDO_WEBCHECKIN_CONFIG.TOKEN_KEY
  },
  LAST_MINUTE_CANCELLATION: {
    config: {
      apiKey: FIREBASE_COMMON_API_KEY,
      projectId: FIREBASE_COMMON_CONFIG.FLIGHT_FIREBASE_PROJECT,
      databaseURL: LAST_MINUTE_FIREBASE_CONFIG.FLIGHT_FIREBASE_DB_URL,
      storageBucket: FIREBASE_COMMON_CONFIG.STORAGE_BUCKET,
      messagingSenderId: FIREBASE_COMMON_CONFIG.MSG_SENDER_ID,
      appId: FIREBASE_COMMON_APP_ID
    },
    basePath: LAST_MINUTE_FIREBASE_CONFIG.BASE_PATH,
    tokenKey: LAST_MINUTE_FIREBASE_CONFIG.TOKEN_KEY
  },
  CAR_TRACKING: {
    config: {
      projectId: CabBookingDetailsConstant.CAR_FIREBASE_PROJECT,
      databaseURL: CabBookingDetailsConstant.CAR_FIREBASE_DB_URL,
    },
    basePath: 'CAR_TRACKING_DATA/MMT/'
  },
  CAR_STATE_UPDATE: {
    config: {
      apiKey: FIREBASE_COMMON_API_KEY,
      projectId: FIREBASE_COMMON_CONFIG.FLIGHT_FIREBASE_PROJECT,
      databaseURL: CAB_LIVE_TRACKING_CONFIG.CAB_FIREBASE_DB_URL,
      storageBucket: FIREBASE_COMMON_CONFIG.STORAGE_BUCKET,
      messagingSenderId: FIREBASE_COMMON_CONFIG.MSG_SENDER_ID,
      appId: FIREBASE_COMMON_APP_ID
    },
    basePath: CAB_LIVE_TRACKING_CONFIG.BASE_PATH,
		tokenKey: CAB_LIVE_TRACKING_CONFIG.TOKEN_KEY
  }
};

export default FIREBASE_CONFIG;
