import React from 'react';
import {View} from 'react-native';
import HTMLView from 'react-native-htmlview';
import PropTypes from 'prop-types';
import styles from '../../CSS/VisaMamiCss';

export default class VisaFAQAnswer extends React.Component {
    static navigationOptions = {
      header: null
    };

    render() {
      return (
        <View style={[styles.CollapsedTapDtlsContainer1]}>
          <HTMLView
            style={styles.VisaFaqHTML}
            value={this.props.answer}
          />
        </View>
      );
    }
}

VisaFAQAnswer.propTypes = {
  answer: PropTypes.string.isRequired
};
