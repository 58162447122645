import React from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import LinearGradient from 'react-native-linear-gradient';
import StarRating from './Rating';
import { useTheme } from '../../../theme/theme.context';
import { getFont } from '../../../PostSalesStyles';
import createStyles from './hotelCardCss';

import { HotelCardProps } from './hotelType';

const HotelCard: React.FC<HotelCardProps> = (props) => {
  const {
    starRating,
    heading,
    imageUrl,
    displayPrice,
    nonDiscountedPrice,
    deeplink,
    omnitureTrackHandler,
  } = props;
  const { psTheme: theme } = useTheme();
  const fonts = getFont(true);
  const styles = createStyles(theme, fonts);

  const handleDeeplink = () => {
    omnitureTrackHandler('Specific_Hotel_CrossSell');
    GenericModule.openDeepLink(deeplink);
  };
  return (
    <TouchableOpacity style={styles.infoSection} onPress={handleDeeplink}>
      <View style={[styles.htlImgSection, AtomicCss.marginBottom6]}>
        <Image style={styles.htlImageStyle} source={{ uri: imageUrl }} />
        <LinearGradient
          colors={[theme.color.black, 'transparent']}
          start={{ x: 0, y: 1 }}
          end={{ x: 0.0, y: 0 }}
          style={styles.gradientBG}
        >
          <Text style={styles.slashPrice}>{nonDiscountedPrice}</Text>
          <Text style={[AtomicCss.font12, AtomicCss.whiteText, AtomicCss.boldFont]}>
            {displayPrice}
          </Text>
        </LinearGradient>
      </View>
      <Text style={[fonts.font12, AtomicCss.regularFont, AtomicCss.blackText]} numberOfLines={1}>
        {heading}
      </Text>
      <View style={[AtomicCss.marginTop5]}>
        <StarRating defaultRating={starRating} />
      </View>
    </TouchableOpacity>
  );
};

export default HotelCard;
