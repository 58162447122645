import React, { useEffect, useRef } from 'react';
import { View, Text, ScrollView, Animated, Easing } from 'react-native';
import { useTheme } from '../../../../../theme/theme.context';
import getStyle from '../../Css/RefundRequestSubmittedCss';
import { getFont } from '../../../../../PostSalesStyles';
import DetailSectionCard from '../DetailSectionCard';
import DetailsLoadingCard from '../DetailLoadingCard';
import SuccessLoaderAnim from '../../../../../Common/SuccessLoaderAnimation';
import { getStaticData } from '../../../../../staticData/staticData';
import Actions from '../../../../../navigation/postSalesNavigation';
import FlightBookingTrackingHelper from '../../../../modules/details/helper/FlightBookingTrackingHelper';
import BaseButton, { BUTTON_VARIANT } from '../../../../../Common/BaseButton';
import {getThankYouPageData} from "../../data/dataAdapter"
import { FLIGHT_THROTTLE_OBJECT } from '../../../details/FlightBookingDetailsConstant';

const RefundRequestSubmitted = ({
  cancellationProgress,
  data,
  onBackPress,
  handleBottomOverlay
}) => {
  const { psTheme } = useTheme();
  const styles = getStyle(psTheme);
  const opacity = useRef(new Animated.Value(0)).current;
  const { fsStyle, ...fonts } = getFont(true);
  const { newSpecialClaimDataCard, error } = data || {};

  useEffect(() => {
    Animated.timing(opacity, {
      toValue: 1,
      duration: 1000,
      delay: 200,
      easing: Easing.Linear,
    }).start();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (newSpecialClaimDataCard === null || !!error) {
      Actions.pop();
      Actions.specialClaimError({ goBackToBooking: onBackPress });
    }
    FlightBookingTrackingHelper.trackLoadEvent(
      'Specialclaim_fullblock_new_thankyou|personal',
      data,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, newSpecialClaimDataCard);

  const { goToYourBookingText } = getStaticData();
  const { title = null, cardsList = [], actionLists = [], imgMap = {} } = newSpecialClaimDataCard || {};
  const {backToYourBooking} = getThankYouPageData(cardsList, actionLists);

  return (
    <React.Fragment>
      <ScrollView>
        <View style={styles.fullPage}>
          <View style={styles.topSection}>
            {!cancellationProgress && <SuccessLoaderAnim />}
            <Text style={[styles.titleHeading, fonts.blackFontFamily, fsStyle.font20]}>
              {title}
            </Text>
          </View>
          {cancellationProgress && <DetailsLoadingCard />}
          {!cancellationProgress && (
            <DetailSectionCard
              delayText={opacity}
              cardsList = {cardsList} 
              actionLists = {actionLists}
              handleBottomOverlay={handleBottomOverlay}
              onBackPress={onBackPress}
              imgMap={imgMap}
            />
          )}
        </View>
      </ScrollView>
      {!cancellationProgress && (
        <View style={[styles.buttonBottomWrapper]}>
          <BaseButton
            {...FLIGHT_THROTTLE_OBJECT}
            variant={BUTTON_VARIANT.PRIMARY}
            textStyle={fsStyle.font16}
            text={backToYourBooking ? backToYourBooking : goToYourBookingText}
            clickHandler={onBackPress}
            shadow
            minWidth="98%"
          />
        </View>
      )}
    </React.Fragment>
  );
};

export default RefundRequestSubmitted;
