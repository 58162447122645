import React from 'react';
import {
  Platform,
  TouchableOpacity
} from 'react-native';

class TouchableOpacityCommon extends React.Component {
  render() {
    const {
      // eslint-disable-next-line react/prop-types
      style, activeOpacity, onPress, useWeb, ...rest
    } = this.props;
    if (useWeb || Platform.OS === 'web') {
      return (
        <TouchableOpacity
          style={style}
          activeOpacity={activeOpacity}
          onClick={event => onPress(event)}
          {
            ...rest
          }
        >
          {this.props.children}
        </TouchableOpacity>
      );
    }
    return (
      <TouchableOpacity
        style={style}
        activeOpacity={activeOpacity}
        onPress={() => onPress()}
        {
          ...rest
        }
      >
        {this.props.children}
      </TouchableOpacity>
    );
  }
}

export default TouchableOpacityCommon;
