import { StyleSheet } from 'react-native';
import {colors} from '@mmt/legacy-commons/Styles/globalStyles';

const styles = StyleSheet.create({
    overlayWrapper: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        elevation: 10,
        zIndex: 100,
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        flexDirection: 'row'
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: 'rgba(0,0,0,0.5)',
        height: '100%',
        width: '100%',
        zIndex: 1
    },
    overlayContent: {
        backgroundColor: colors.white,
        padding: 20,
        paddingBottom: Platform.OS === 'ios' ? 0 : 20,
        position: 'relative',
        zIndex: 1,
        width: '100%'
    }
});

export default styles;
