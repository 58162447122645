import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import { getStaticData } from '../../../staticData/staticData';
import { getFont} from 'apps/post-sales/src/PostSalesStyles';
import ButtonWrapper from 'apps/post-sales/src/Common/ButtonWrapper';

const ConfirmToCancel = ({ handleTab, isCorporateTheme = '' }) => {
    const { fsStyle, ...fonts } = getFont(true);
    const styles = getStyles(fonts);
    const {cancellationText: {additionalText}, confirmText} = getStaticData();
    return (
        <React.Fragment>
            <View style={[AtomicCss.marginBottom24, AtomicCss.marginTop5]}>
                <View style={styles.overlayTopline}></View>
            </View>
            <View>
                <View style={[AtomicCss.marginLeft10, AtomicCss.marginRight10]}>
                    <Text style={[styles.title, AtomicCss.marginBottom8]}>
                        {additionalText.confirmToCancelText}
                    </Text>
                    <Text style={[fonts.regularFontFamily, fsStyle.font14, AtomicCss.blackText, AtomicCss.lineHeight20, AtomicCss.marginBottom25]}>{additionalText.confirmCancelDescriptionText}</Text>
                </View>
                <View style={[AtomicCss.marginHz20, AtomicCss.marginBottom20]}>
                <ButtonWrapper
                    btnText={confirmText}
                    clickHandler={handleTab}
                    bgColors = {isCorporateTheme ? "myBiz" : null}
                />
                </View>
            </View>
        </React.Fragment>
    )
};

const getStyles = (fonts) => {
    return StyleSheet.create({
        overlayTopline: {
            width: 40,
            height: 4,
            borderRadius: 4,
            backgroundColor: colors.disabledButton,
            alignSelf: 'center', 
        },
        title: {
            color: colors.black,
            ...fonts.font22,
            fontFamily: fonts.black,
            marginBottom: 30,
            lineHeight: 32
        }
    })
}
export default ConfirmToCancel;
