import { Platform, StyleSheet, Text, View } from 'react-native';
import React, { useEffect, useState } from 'react';
// @ts-ignore Reason: Type declaration file not available
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import colors from '@mmt/ui/base/colors';
import { fontFamilies } from '@mmt/ui/base/textStyles';
import EditBookingDetails from '../../components/compounds/EditBookingDetails';
import PersuasionCard from '../../components/molecules/PersuasionsCard';
import { useGetCardDetails } from '../../hooks/useGetCardDetails';
// @ts-ignore
import { getAddress } from '../../utils';
import useCrossSellStore from '../../hooks/useCrossSellStore';
import { ACTION_UPDATE_LOCATION } from '../../reducers/crossSellReducer';
import Loader from './Loader';
import { initOmniture } from '../../omniture/constants';
import { isCorporateUser } from '@mmt/legacy-commons/Native/UserSession/UserSessionModule';
import CabsSharedModuleHolder from '@mmt/cabs-shared/src';

interface Props {
  data: {
    hotel_details: {
      latitude: number;
      longitude: number;
      name: string;
      locus_id: string;
    };
    booking_date: Date;
    checkout_date: Date;
    checkin_date: Date;
  };
}

const CrossSellCard = ({ data }: Props) => {
  const { hotel_details, booking_date, checkout_date, checkin_date } = data;
  const { isLoading, cardData } = useGetCardDetails(hotel_details);
  const [isPersonalUser, setIsPersonalUser] = useState(true);
  // @ts-ignore
  const [, dispatch] = useCrossSellStore();

  // This is to init omniture
  useEffect(() => {
    initOmniture();
    const checkProfileType = async () => {
      const isCorporateProfile = await isCorporateUser();
      setIsPersonalUser(!isCorporateProfile);
    };
    checkProfileType();
  }, []);

  // This hook is to prefill source location with hotel location
  useEffect(() => {
    const getHotelAddress = async () => {
      const CabsSharedModule = CabsSharedModuleHolder.get();
      if (CabsSharedModule == null) {
        throw 'Cabs-Shared module not bootstrapped';
      }
      const cabsLocationModule = CabsSharedModule.getCabsLocationModule();
      const { latitude, longitude, name } = hotel_details;
      const rawAddress = await cabsLocationModule.getNativeReverseGeocodes(latitude, longitude);
      const mapsAddress = getAddress(rawAddress);
      const processedAddress = {
        ...mapsAddress,
        latitude,
        longitude,
        mainText: name,
      };
      const processedAddressDestination = cardData?.dest_location || null;
      if (processedAddressDestination) {
        processedAddressDestination.mainText = processedAddressDestination?.address;
      }
      dispatch({
        type: ACTION_UPDATE_LOCATION,
        payload: {
          sourceLocation: processedAddress,
          destinationLocation: processedAddressDestination,
        },
      });
    };
    getHotelAddress();
  }, [cardData]);

  if (!isPersonalUser) {
    return null;
  }
  if (isLoading) {
    return <Loader />;
  } else if (!cardData) {
    return null;
  } else {
    const { title, cab_card = null, footer_persuasion = null } = cardData || {};
    const {
      hotel_details: { name: hotelName },
    } = data || {};
    return (
      <View
        style={[
          AtomicCss.whiteCardNew,
          AtomicCss.marginHz12,
          AtomicCss.marginBottom12,
          AtomicCss.paddingAll12,
          {borderRadius:16}
        ]}
      >
        <Text style={styles.topInfoTxt}>
          {title}
          <Text style={[AtomicCss.boldFont,{fontWeight:'700'}]}>{hotelName?.trim()}</Text>
        </Text>
        <EditBookingDetails
          cabData={cab_card}
          checkinDate={checkin_date}
          checkoutDate={checkout_date}
          bookingDate={booking_date}
        />
        <PersuasionCard data={footer_persuasion} />
      </View>
    );
  }
};

export default CrossSellCard;

const styles = StyleSheet.create({
  bookingWrapper: {
    paddingHorizontal: 10,
    paddingVertical: 12,
    backgroundColor: '#ffffff',
    borderRadius: 4,
    borderWidth: 1,
    borderColor: '#D8D8D8',
    marginHorizontal: 10,
  },
  topInfoTxt: {
    color: colors.black,
    fontSize: 15,
    fontFamily: fontFamilies.regular,
    marginBottom: 8,
    fontWeight:'400',
  },
});
