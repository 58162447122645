const getStyle = (theme, fonts) => {
  const {color} = theme;
  return {
    htmlStyle: {
      p: {
        ...fonts.regularFontFamily,
        color: color.defaultTextColor,
        lineHeight: 20,
        marginBottom: 12,
      },
    },
    OverlayContentWrapper: {
      backgroundColor: color.white,
      paddingTop: 10,
      paddingBottom: 5,
      paddingHorizontal: 5,
      position: 'relative',
      zIndex: 1,
      width: '100%',
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
    },
    overlayTopline: {
      width: 40,
      height: 4,
      borderRadius: 4,
      backgroundColor: color.disabledButton,
      alignSelf: 'center',
    },
    title: {
      color: color.black,
      marginBottom: 30,
      lineHeight: 32,
    },
    refundBigIconStyle: {
      width: 48,
      height: 48,
      marginBottom: 16,
    },
    strokeStyle: {
      width: 30,
      height: 4,
      backgroundColor: color.azure,
      marginBottom: 26,
    },
    lineHeight22: { lineHeight: 22 },
    detailSec: {
      borderBottomWidth: 1,
      borderBottomColor: color.greyBookedSeat,
      paddingBottom: 20,
      marginBottom: 20,
    },
    detailSecLast: {
      borderBottomWidth: 0,
      paddingBottom: 0,
      marginBottom: 30,
    },
    rejectDetails: {
      backgroundColor: color.whiteSmoke,
      padding: 12,
      borderRadius: 6,
      marginBottom: 60,
      flexDirection: 'row',
    },
    rejectIcon: {
      width: 20,
      height: 20,
      marginRight: 8,
    },
  };
};

export default getStyle;
