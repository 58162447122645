import React from 'react';
import {View, Text, Image, TouchableOpacity} from 'react-native';
import styles from './RefundCardCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import Tracker from '../Tracker/index'
import _ from 'lodash';
import {RUPEE_ICON,RIGHT_ARROW} from '../../../../SelfDriveImageConstant';

const RefundCard = (props) => {
    const step = props.step
    const subHeader = props.subHeader
    const TrakerRefundStatus = props.TrakerRefundStatus
    const trackerList = props.response.tripEndTrackerList

    return (
        <View style={[AtomicCss.marginBottom10]}>
            <View style={[styles.infoCardWrapper]}>
                <View style={styles.headingIconWrapper}>
                    <Image style={styles.headingIconStyle} source={RUPEE_ICON}/>
                </View>
                <View style={styles.infoCardContent}>
                    <Text
                        style={[AtomicCss.defaultText, AtomicCss.blackFont, AtomicCss.font14, AtomicCss.marginBottom0]}>{props.title} </Text>
                        <View style={[styles.refundStatusInfoTxt, AtomicCss.marginTop10, AtomicCss.marginBottom12]}>
                            <Text style={[styles.statusInfoTxt]}>
                                {subHeader}
                            </Text>
                        </View>
                    {!_.isEmpty(trackerList) && <Tracker
                        borderTopActive
                        trackerRefundStatus={TrakerRefundStatus}
                        statusStep={step}
                        trackerList={trackerList}
                    />}
                </View>
            </View>
            {!_.isEmpty(props.actionList) && <TouchableOpacity style={styles.redirectBtnWrapper} activeOpacity={.8}>
                <Text style={styles.linkBtnTxt}>View Refund Calculation</Text>
                <Image style={styles.rightArrowIcon} source={RIGHT_ARROW}/>
            </TouchableOpacity>}
        </View>
    )
}


export default RefundCard
