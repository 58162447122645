import React from 'react';
import { View } from 'react-native';

const WhiteCard = (props) => {
    const peddingZero =  props.isCardPeddingZero  ? props.styles.pedZero:null;
    return (
        <View style={[props.styles.whiteCard, peddingZero]}>
            <View> 
                {props.children}
            </View>
        </View>
    )
}


export default WhiteCard