import FlightBookingModule from '@mmt/legacy-commons/Native/FlightBookingModule';
import { getCommonHeaders } from './NetworkUtils';
import {
  fonts,
  headings,
  labels,
  color,
  customStyle,
  icons,
  components,
} from '../themeConfigurations';
import { isGCCBooking } from '../Common/commonUtil';
import { getUserProfileType } from '@mmt/legacy-commons/AppState/AppUserConfig';
import {  PROFILE_TYPE } from '@mmt/legacy-commons/Common/constants/AppConstants';
import { getStaticData } from '../staticData/staticData';

interface GetHeadersParams {
  bookingId: string;
  uniqueId: string;
  lob: string;
}
const getHeaders = async (headersData: GetHeadersParams) => {
  const { bookingId, uniqueId, lob } = headersData || {};
  const userInfo = await FlightBookingModule.getRequestHeader(bookingId ? bookingId : '', uniqueId);
  const headers = await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo, {
    psLob: lob,
  });
  return headers;
};

export const getUserProfileContextInfo = async (): Promise<UserProfileContextReturnType> => {
  // const headers = await NetworkModule.getHeaders();
  const isGccBooking = await isGCCBooking();
  let isGobalBooking = false;
  // if (headers?.['entity-name']) {
  //   isGobalBooking =  headers?.['entity-name'] === 'global';
  // } else { // todo
  isGobalBooking =  isGccBooking;
  const userProfileType = getUserProfileType();
  // }

  return {
    // isGlobalBooking: headers?.['entity-name'] === 'global',
    userProfileType: userProfileType,
    isGccBooking,
    userContextType: determineType(isGobalBooking, userProfileType),
  };
};

export const determineType = (isGobalBooking: boolean = false, userProfileType: string) : string => {
  const isB2B = getUserProfileType() === PROFILE_TYPE.BUSINESS;
  let type = 'b2c';
  if (isB2B) {
    type =  'corporate';
  } else {
      type = isGobalBooking ? 'global' : 'b2c';
  }
  return type;
};


export const getAppData = () => {
  const {travelInsuranceText = {}, errorText = {}, specialClaimText = {}, backToBookingText} = getStaticData();
  const staticText = {
    travelInsuranceText,
    specialClaimText,
    errorMsg: errorText?.somethingWentWrongTryAgainError,
    backToBookingText,
    unableToProcessRequestTechIssueText: errorText?.unableToProcessRequestTechIssueText,
  };
  const apiConfig = {
    fonts,
    headings,
    labels,
    color,
    customStyle,
    icons,
    components,
    apiConfig: {
      getHeaders,
      host: 'https://supportz.makemytrip.com/api',
    },
    getUserProfileContextInfo,
    staticText,
  };
  return apiConfig;
};
