import { View, StyleSheet, Dimensions } from 'react-native';
 
export default (theme, fonts) => {
  const { color } = theme;
  return StyleSheet.create({
    container: {
      backgroundColor: color.greyBookedSeat,
      flex: 1,
      height: 300,
    },
    PayDriverRatingCard: {},
    payToDriverTopSection: {
      paddingVertical: 20,
      borderBottomWidth: 1,
      borderBottomColor: color.lightGrey16,
      marginHorizontal: 12,
    },
    ratingWrapper: {
      paddingHorizontal: 20,
      justifyContent: 'center',
      alignSelf: 'center',
      paddingBottom: 20,
      paddingTop: 10,
    },
    rowSection: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    amountPendingSection: {
      borderRadius: 8,
      padding: 8,
      backgroundColor: color.creamWhite9,
    },
    brownText: {
      color: color.lightYello,
    },
    paymentInfoWrapper: {
      paddingHorizontal: 12,
      paddingTop: 20,
      paddingBottom: 8,
    },
 
    seperator: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginHorizontal: 0,
    },
    semiCircle: {
      height: 16,
      width: 16,
      borderRadius: 8,
      backgroundColor: color.grey24,
    },
    leftSemiCircle: {
      marginLeft: -8,
    },
    rightSemiCircle: {
      marginRight: -8,
    },
    dashedLineWrapper: {
      height: 2,
      flex: 1,
      overflow: 'hidden',
    },
    dashedLine: {
      flex: 1,
      marginHorizontal: 0,
      borderStyle: 'dashed',
      borderWidth: 1,
      borderColor: color.grey2,
      height: 3,
    },
    confirmBtnWrapper: {
      paddingHorizontal: 15,
      paddingBottom: 20,
      paddingTop: 10,
      justifyContent: 'center',
      alignItems: 'center',
    },
    btnWrapper: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 12,
    },
    buttonView: {
      flex: 1,
      margin: 5,
    },
    callHelpButtonStyle: {
      borderRadius: 10,
      paddingHorizontal: 20,
      paddingVertical: 10,
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'center'
    },
   callHelpTextStyle: {
      fontSize: fonts.fontSize.font12,
      ...fonts.boldFontFamily,
      textAlign: 'center',
      color: color.azure,
    },
    viewBreakupButtonStyle: {
      borderRadius: 10,
      paddingHorizontal: 20,
      paddingVertical: 10,
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    viewBreakupTextStyle: {
      fontSize: fonts.fontSize.font12,
      ...fonts.boldFontFamily,
      textAlign: 'center',
      color: color.white,
    },
    breakupLabel:{
      fontStyle: 'italic',
      fontSize: fonts.fontSize.font12
    }
  });
};