import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { StyleSheet, View, Text, Image } from 'react-native';
import { useTheme } from '../../../../../theme/theme.context';
import { getFont } from '../../../../../PostSalesStyles';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { Fonts, RescheduleInfoCardProps, Theme } from 'apps/post-sales/src/cab/types';

const RescheduleInfoCard: React.FC<RescheduleInfoCardProps> = ({ cardData }) => {
  const { headingData, subHeadingData, themeColor, imageUrl } = cardData || {};
  const { psTheme } = useTheme();
  const { fsStyle, ...fonts } = getFont();
  const styles = getStyle(fonts, psTheme);

  return (
    <View style={[styles.contentWrapper, { backgroundColor: themeColor }]}>
      <Image source={{ uri: imageUrl }} style={styles.calendarClockStyle} />
      <View style={[AtomicCss.flex1]}>
        {!isEmpty(headingData) && (
          <Text style={[styles.heading, { color: headingData.color }]}>{headingData.text}</Text>
        )}
        {!isEmpty(subHeadingData) && (
          <Text style={[styles.subHeading, { color: subHeadingData.color }]}>
            {subHeadingData.text}
          </Text>
        )}
      </View>
    </View>
  );
};

const getStyle = (fonts: Fonts, psTheme: Theme) => {
  return StyleSheet.create({
    contentWrapper: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 10,
      borderRadius: 10,
      marginVertical: 5,
      marginHorizontal: 10,
    },
    calendarClockStyle: {
      width: 51,
      height: 42,
      marginRight: 10,
    },
    heading: {
      fontSize: fonts.fontSize.font12,
      ...fonts.boldFontFamily,
      marginBottom: 3,
    },
    subHeading: {
      fontSize: fonts.fontSize.font16,
      ...fonts.boldFontFamily,
    },
  });
};

export default RescheduleInfoCard;
