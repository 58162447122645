import React, { useEffect } from 'react';
import Actions from '../../navigation/postSalesNavigation';
import {View, Text, Image, TouchableOpacity, ScrollView, Dimensions,Platform} from 'react-native';
import {isEmpty} from 'lodash';
import styles from './mmtBlackCss';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {getScreenWidth, initialiseIVR} from '../../utils/PostSaleUtil';
import PostSalesBookingTrackingHelper from '../../PostSalesBookingTrackingHelper';
import { openDialer } from '../commonUtil';

const callIcon = require('@mmt/legacy-assets/src/ic_mmtblack_callIcon.webp');
const backArrow = require('@mmt/legacy-assets/src/back-white.webp');
const eliteIconSm = require('@mmt/legacy-assets/src/blackEliteIconSm.webp');
const preferredIconSm = require('@mmt/legacy-assets/src/blackPreIconSm.webp');
const exclusiveIconSm = require('@mmt/legacy-assets/src/blackExcIconSm.webp');

const MMTBlackCard = ({
  tierMetaInfo, tierType, tierName, description, spendsDataList, extraInfo, benefitDetailsList,
  uncoveredDetails, bookingId, uuid, onClick, trackingPageName, isRoundedCornerAndPadding, navigation
}) => {
  useEffect(() => {
    PostSalesBookingTrackingHelper.trackLoyalityCardShown(trackingPageName, `Blackcard_${tierName}_shown`);
  }, [trackingPageName, tierName])
  const {tierIcon, tierColor, backgroundUrl} = tierMetaInfo || {};
  const onClickEvent = (ctaCata) => {
    if (ctaCata && ctaCata.actionLink) {
      if(Platform.OS === "web"){
        location.href = ctaCata.actionLink.PWA || ctaCata.actionLink.WEB;
      } else if (!isEmpty(ctaCata.actionLink.APP)) {
        GenericModule.openDeepLink(ctaCata.actionLink.APP);
      } else if (!isEmpty(ctaCata.actionLink.WEB)) {
        let navigationObj = Actions;
        if (navigation) {
          navigationObj = navigation;
        }
        navigationObj.openPsWebView({
          url: ctaCata.actionLink.WEB,
          headerText: '',
          headerIcon: backArrow
        });
      }
    }
  };

  let smallIcon;

  switch (tierType) {
    case 1:
      smallIcon = eliteIconSm;
      break;
    case 2:
      smallIcon = preferredIconSm;
      break;
    case 3:
      smallIcon = exclusiveIconSm;
      break;
    default:
      smallIcon = preferredIconSm;
  }

  const isClickable = linkObj => linkObj && linkObj.actionLink && !isEmpty(Object.keys(linkObj)) && (!isEmpty(linkObj.actionLink.APP) || !isEmpty(linkObj.actionLink.WEB));

  const blackNewCard = isRoundedCornerAndPadding ? styles.mmtBlackCardNew : null;

  if (uncoveredDetails && !isEmpty(uncoveredDetails.text)) {
    return (
      <TouchableOpacity
        disabled={!isClickable(uncoveredDetails.cardLink)}
        onPress={() => {
          onClickEvent(uncoveredDetails.cardLink);
        }}
        style={[styles.blackSpendsCard, blackNewCard]}
      >
        <Image style={styles.smallIcnStyle} source={smallIcon} />
        <Text style={styles.blackInfoTxt}>{uncoveredDetails.text}</Text>
      </TouchableOpacity>);
  }
  return (
    <View style={[AtomicCss.marginBottom10, blackNewCard]}>
      {isEmpty(backgroundUrl) ?
        <View style={[styles.BlackBG, isRoundedCornerAndPadding ? AtomicCss.borderRadius10 : null]} /> : <Image style={[styles.BlackBGImage, isRoundedCornerAndPadding ? AtomicCss.borderRadius10 : null]} source={{uri: backgroundUrl}} />
      }
      <View style={styles.mmtBlackWrapper}>
        <View style={[AtomicCss.flexRow, AtomicCss.marginBottom15]}>
          <Image style={styles.iconStyle} source={{uri: tierIcon}} />
          <View>
            {!isEmpty(tierName) &&
              <Text style={styles.title}>MMTBLACK
                <Text style={[styles.titleInfo, {color: tierColor}]}>
                  {` ${tierName}`}
                </Text>
                  {!isEmpty(extraInfo) && <Text> *</Text>}
              </Text>
              }
            {!isEmpty(description) &&
              <Text style={styles.subTitle}>
                  {description}
              </Text>
              }
          </View>
        </View>
        {!isEmpty(benefitDetailsList) &&
        <ScrollView style={styles.blackBenifitsWrapper} horizontal>
          {benefitDetailsList.map((item) => {
                        const {
heading, ctaData
} = item;
                        const benefitDescription = item.description;
                        const {type, contactNo, label} = ctaData || {};
                        const screenWidth = getScreenWidth();
                        const widthToBeSubtracted = isRoundedCornerAndPadding ? 24 : 0;
                        const singleItemStyle = benefitDetailsList.length === 1 ? {width: screenWidth - 25 - widthToBeSubtracted} : '';
                        const twoElementStyle = benefitDetailsList.length === 2 ? {width: (screenWidth / 2) - 20} : '';
                        return (
                          <TouchableOpacity
                            disabled={!isClickable(item.cardLink)}
                            onPress={() => onClickEvent(item.cardLink)}
                            style={[styles.benifitList, singleItemStyle, twoElementStyle]}
                          >
                            {!isEmpty(heading) &&
                              <Text
                                style={[AtomicCss.font12, AtomicCss.blackFont, AtomicCss.whiteText, AtomicCss.textCenter, AtomicCss.lineHeight15]}
                              >
                                  {heading}
                              </Text>
                              }
                            {!isEmpty(benefitDescription) &&
                              <Text style={styles.listMoreInfo}>
                                  {benefitDescription.join('. ')}
                              </Text>
                              }
                            {ctaData && type === 'CONTACT' &&
                            <TouchableOpacity
                              onPress={() => {
                                initialiseIVR(bookingId, uuid);
                                openDialer(contactNo);
                                onClick('black_contactus');
                              }}
                              style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginTop8]}
                            >
                              <Image style={styles.callIconStyle} source={callIcon} />
                              <Text style={styles.callIconTxtStyle}>{label}</Text>
                            </TouchableOpacity>
                                }
                          </TouchableOpacity>
                        );
                    })}

        </ScrollView>
                }
        {!isEmpty(spendsDataList) && spendsDataList.map((item) => {
          const footerText = item.text;
          const clickableTextData = item.ctaData;
          const clickableText = clickableTextData ? clickableTextData.label : '';
          return (
            <View style={styles.moreInfoWrapper}>
              {!isEmpty(footerText) &&
              <Text
                style={[AtomicCss.boldFont, AtomicCss.font12, !isEmpty(benefitDetailsList) ? {color: tierColor} : {color: '#9b9b9b'}, AtomicCss.lineHeight18]}
              >
                {footerText}
                {!isEmpty(clickableText) &&
                  <Text
                    onPress={() => {
                      onClickEvent(clickableTextData);
                      onClick('black_viewdetails');
                    }}
                    style={AtomicCss.azure}
                  >
                    {`  ${clickableText}`}
                  </Text>
              }
              </Text>
            }
              {!isEmpty(extraInfo) && <Text style={styles.bookingTxt}>*{extraInfo}</Text>}
            </View>);
        })}
      </View>
    </View>
  );
};


export default MMTBlackCard;
