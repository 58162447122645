import React from 'react';
import { getHolidayPlanningSummaryData } from './holidayPlanningCardAdapter';
import {
    ActionsContent,
    DescriptionSection,
    MainContent,
    SummaryContainer,
    DescriptionCardInfoSection,
    JourneyStartsInfoText,
    CardInfoSection,
} from '../cardElements';
import { HolidaysPlanningData, MsrTicket } from '../cardInterfaces';
import { PAGE_BOOKINGS_STATUS } from '../../../summaryConstants';

interface HolidayPlanningSummaryCardProps {
    response: HolidaysPlanningData;
    msrTicket: MsrTicket;
}
function HolidayPlanningSummaryCard({ response, msrTicket }: HolidayPlanningSummaryCardProps) {
    const cardData = getHolidayPlanningSummaryData(response, msrTicket);
    const { iconUrl,
        startingFrom,
        passengerDetails,
        packageName,
        startsOnText,
        startDate,
        endsText,
        endDate,
        openDetails,
        actions } = cardData || {};

    return (
        <SummaryContainer bookingId={response.bookingID}>
            <MainContent onPress={openDetails}>
                <CardInfoSection
                    iconUrl={iconUrl}
                    name={startingFrom}
                    passengerDetails={passengerDetails}
                    type={PAGE_BOOKINGS_STATUS.PLANNING}
                />
                <DescriptionCardInfoSection descriptionText={packageName} />
                <DescriptionSection>
                    <JourneyStartsInfoText type={PAGE_BOOKINGS_STATUS.PLANNING} startsOnText={startsOnText} startDate={startDate} endsText={endsText} endDate={endDate} />
                </DescriptionSection>
            </MainContent>
            <ActionsContent actions={actions} />
        </SummaryContainer>
    );
}

export default HolidayPlanningSummaryCard;