import { makePageData, makeV2PageData } from './bookingDetailsAdapter';
import { httpGet, httpPost } from '../../data/api';
import { ApiRequestTypes, CallNowRequestConstants, Lobs, TalkToUsConstants } from '../summaryConstants';
import {
  MSR_UPDATE_SEARCH_API,
  BOOKING_SUMMARY_URL,
  getCommonHeaders,
  TALK_TO_US_CALL_NOW_API,
} from '../../utils/NetworkUtils';
import { LOST_BOOKING_SUMMARY_URL } from '../../utils/NetworkUtils';
import { LOBNAMES } from '../../PostSalesConstant';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import { getProductType } from '../utils';

export async function fetchBookings(status, pageNumber, searchValue) {
  const data = await httpGet(
    `/v4/bookingsummary?status=${status}&pageNo=${pageNumber - 1}&searchValue=${searchValue}`,
    null,
    { uniqueIdType: ApiRequestTypes.SUMMARY, psLob: LOBNAMES.POST_SALES },
  );
  return makePageData(data);
}

export async function fetchV2Bookings() {
  const data = await httpGet(BOOKING_SUMMARY_URL, null, {
    uniqueIdType: ApiRequestTypes.SUMMARY,
    psLob: LOBNAMES.POST_SALES,
  });

  return makeV2PageData(data);
}

export async function fetchLostIDBooking() {
  const data = await httpGet(LOST_BOOKING_SUMMARY_URL, null, {
    uniqueIdType: ApiRequestTypes.SUMMARY,
    psLob: LOBNAMES.POST_SALES,
  });
  return data;
}

export async function fetchTickets() {
  const searchTicketRequest = {};
  searchTicketRequest.eventType = 'MIMA_LISTING';
  searchTicketRequest.sourceOfVisit = 'MIMA_LISTING';
  searchTicketRequest.auditorType = 'CUSTOMER';
  searchTicketRequest.tenant = 'MMT';
  const data = await httpPost(MSR_UPDATE_SEARCH_API, searchTicketRequest, {
    uniqueIdType: ApiRequestTypes.SUMMARY,
    psLob: LOBNAMES.POST_SALES,
  });
  const bookingIdtoTicketDetailmap = new Map();
  if (data) {
    const msrTickets = data.ticketsList;
    msrTickets.map((item, index) => {
      const bookingId = item.entityKey;
      if (bookingId && bookingId.length > 0 && !bookingIdtoTicketDetailmap.has(bookingId)) {
        bookingIdtoTicketDetailmap.set(bookingId, item);
      }
    });
  }
  return bookingIdtoTicketDetailmap;
}

export async function fetchHolidayPlannings() {
  const data = await httpGet('/holidayPlanningDetails', {
    uniqueIdType: ApiRequestTypes.PLANNING_DETAILS,
  });
  return data;
}

export async function cancelHolidayPlanningQuery(body) {
  const data = await httpPost('/holidayPlanning/query/close', body, {
    uniqueIdType: ApiRequestTypes.CANCEL_PLANNING_DETAILS,
  });
  return data;
}

export const callSupportAPI = async (bookingID, productCode, isInternational, uuid, pageName) => {
  try {
    const url = TALK_TO_US_CALL_NOW_API;
    const request = {
      'context': {
        'primarytype': getProductType(productCode, isInternational),
        'secondarytype': CallNowRequestConstants.SECONDARY_TYPE,
        'ternarytype': CallNowRequestConstants.TERNARY_TYPE,
        'source': CallNowRequestConstants.SOURCE,
        'pageName': pageName || '',
      },
      'bookingId': bookingID,
      'uuid': uuid,
    };

    const userInfo = await HotelBookingModule.getRequestHeader('', 'talkToAgent');
    const headers = await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo);
    const requestOptions = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(request),
    };
    await fetch(url, requestOptions);
  } catch (error) {
    console.error('API error:', error);
  }
};

export default {};
