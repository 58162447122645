import { Dimensions, StyleSheet } from 'react-native';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';

export default (theme) =>
  StyleSheet.create({
    scrollViewWrapper: {
      maxHeight: Dimensions.get('window').height - 400,
    },
    overlayContentWrapper: {
      backgroundColor: theme.color.white,
      paddingTop: 12,
      position: 'relative',
      zIndex: 1,
      width: '100%',
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      paddingBottom: 50,
      maxHeight: Dimensions.get('window').height - 200,
    },
    overlayTopline: {
      width: 40,
      height: 4,
      borderRadius: 4,
      backgroundColor: theme.color.disabledButton,
      alignSelf: 'center',
      marginBottom: 20,
    },
    title: {
      color: theme.color.black,
      marginBottom: 30,
      lineHeight: 32,
    },
    strokeStyle: {
      width: 30,
      height: 4,
      backgroundColor: theme.color.primary,
      marginBottom: 26,
    },
    lineHeight22: { lineHeight: 22 },
    lineHeight21: { lineHeight: 21 },
    detailSec: {
      borderWidth: 1,
      borderColor: theme.color.greyBookedSeat,
      paddingVertical: 20,
      paddingHorizontal: 16,
      marginBottom: 20,
      borderRadius: 4,
      backgroundColor: theme.color.white,
      elevation: 3,
      ...getPlatformElevation(2),
    },
    leftStroke: {
      width: 3,
      height: 16,
      backgroundColor: theme.color.primary,
      position: 'absolute',
      left: -16,
    },
    paxDetail: {
      width: 140,
    },
    editableTxt: {
      color: theme.color.green,
      textDecorationLine: 'underline',
      textDecorationStyle: 'dotted',
      textDecorationColor: theme.color.black,
    },
    correctionWrapper: {
      marginBottom: 60,
    },
    buttonBottomWrapper: {
      alignItems: 'center',
      position: 'absolute',
      bottom: 0,
      width: '100%',
    },
    buttonWrapper: {
      paddingBottom: 10,
      width: '100%',
      alignItems: 'center',
    },
    makeBtnWrapper: {
      paddingTop: 20,
      backgroundColor: theme.color.white,
      width: '100%',
      alignItems: 'center',
    },
    overlayButtonStyle: {
      minWidth: '96%',
    },
  });
