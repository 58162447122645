import React, { useCallback, useRef } from 'react';
import { Text, TouchableOpacity, View, ActivityIndicator } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import PropTypes from 'prop-types';
import createStyle from './ButtonCss';
import { useTheme } from '../../theme/theme.context';
// Please use varient from below object. If needed further customizations add a new vriant
export const BUTTON_VARIANT = {
  DEFAULT: 'DEFAULT',
  PRIMARY: 'PRIMARY',
  CAPSULE: 'CAPSULE',
  OUTLINED: 'OUTLINED',
  OUTLINED_CAPSULE: 'OUTLINED_CAPSULE',
  WHITE_CAPSULE: 'WHITE_CAPSULE',
  PRIMARY_WHITE:'PRIMARY_WHITE',
};
const ButtonWrapper = ({
  disabled,
  isLoading,
  clickHandler,
  buttonStyle,
  textStyle,
  subTextStyle,
  gradientColor,
  text,
  imageOnLeftToText = false,
  subText,
  spinnerSize,
  spinnerColor,
  minWidth,
  gradientStart,
  gradientEnd,
  shadow,
  children,
  numberOfLines,
  isThrottleRequired= false,
  throttleInterval = 1000,
}) => {
  const { psTheme: theme } = useTheme();
  const lastClickTimeRef = useRef(0);
  const styles = createStyle(theme);
  if (disabled) {
    gradientColor = [theme.color.disabledButton, theme.color.disabledButton];
  }
  const handleOnPress = () => {
    if (!disabled && !isLoading && typeof clickHandler === 'function') {clickHandler();}
  };

  const wrapperThrottle = () => {
    if (isThrottleRequired) {
      throttledClickHandler();
    } else {
      handleOnPress();
    }
  };

  const throttledClickHandler = useCallback(() => {
    const currentTime = Date.now();
    if (currentTime - lastClickTimeRef.current >= throttleInterval) {
      lastClickTimeRef.current = currentTime;
      handleOnPress();
    }
  }, [handleOnPress, throttleInterval]);

  return (
    <TouchableOpacity
      onPress={wrapperThrottle}
      disabled={disabled}
      style={[shadow ? styles.btn_wrapper : {}, minWidth ? {minWidth} : {}]}
      activeOpacity={0.95}
    >
      <LinearGradient
        start={gradientStart}
        end={gradientEnd}
        colors={gradientColor}
        style={[styles.btn_default, ...buttonStyle, minWidth ? { minWidth } : {}]}
      >
        <View style={{ flexDirection: 'column', alignSelf: 'center' }}>
          {isLoading ? (
            <ActivityIndicator size={spinnerSize} color={spinnerColor} />
          ) : (
            <>
              <View style={styles.textContainer}>
                {
                  imageOnLeftToText ?
                  <>
                  {children}
                  {!!text && <Text style={[styles.txt_default, ...textStyle]}>{text}</Text>}
                  </> :
                  <>
                  {!!text && !!numberOfLines ? <Text style={[styles.txt_default, ...textStyle]} numberOfLines={numberOfLines}>{text}</Text> :
                  !!text &&  <Text style={[styles.txt_default, ...textStyle]}>{text}</Text>}
                  {children}
                  </>
                }
              </View>
              {!!subText && <Text style={[styles.subTxt_deafult, ...subTextStyle]}>{subText}</Text>}
            </>
          )}
        </View>
      </LinearGradient>
    </TouchableOpacity>
  );
};
const BaseButton = ({ variant, ...rest }) => {
  const { psTheme: theme } = useTheme();
  const styles = createStyle(theme);
  let newProps = { ...rest };
  switch (variant) {
    case BUTTON_VARIANT.PRIMARY:
      newProps = {
        ...newProps,
        gradientColor: theme.color.primaryGradient,
        buttonStyle: [rest.buttonStyle],
        textStyle: [rest.textStyle],
      };
      break;
      case BUTTON_VARIANT.PRIMARY_WHITE:
        newProps = {
          ...newProps,
          gradientColor: [theme.color.white,theme.color.white],
          // buttonStyle: [rest.buttonStyle],
          textStyle: [{ color: theme.color.primary }, rest.textStyle],
        };
        break;
    case BUTTON_VARIANT.CAPSULE:
      newProps = {
        ...newProps,
        gradientColor: theme.color.primaryGradient,
        buttonStyle: [styles.capsuleBtn, rest.buttonStyle],
        textStyle: [rest.textStyle],
      };
      break;
    case BUTTON_VARIANT.OUTLINED:
      newProps = {
        ...newProps,
        gradientColor: [theme.color.transparent, theme.color.transparent],
        buttonStyle: [styles.outlined, rest.buttonStyle],
        textStyle: [{ color: theme.color.primary }, rest.textStyle],
      };
      break;
    case BUTTON_VARIANT.OUTLINED_CAPSULE:
      newProps = {
        ...newProps,
        gradientColor: [theme.color.transparent, theme.color.transparent],
        buttonStyle: [styles.outlined, styles.capsuleBtn, rest.buttonStyle],
        textStyle: [{ color: theme.color.primary }, rest.textStyle],
      };
      break;
    case BUTTON_VARIANT.WHITE_CAPSULE:
      newProps = {
        ...newProps,
        gradientColor: [theme.color.white, theme.color.white],
        buttonStyle: [styles.capsuleBtn, rest.buttonStyle],
        textStyle: [{ color: theme.color.primary }, rest.textStyle],
      };
      break;
    default:
      break;
  }
  return <ButtonWrapper {...newProps} />;
};
export default BaseButton;
BaseButton.propTypes = {
  clickHandler: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  text: PropTypes.string.isRequired,
  textStyle: PropTypes.object,
  minWidth: PropTypes.string,
  variant: PropTypes.oneOf(Object.keys(BUTTON_VARIANT)),
  buttonStyle: PropTypes.object,
  subText: PropTypes.string,
  subTextStyle: PropTypes.array,
  gradientColor: PropTypes.arrayOf(PropTypes.string),
  spinnerSize: PropTypes.oneOf(['small', 'large']),
  spinnerColor: PropTypes.string,
  shadow: PropTypes.bool,
};

// for further customizations of button, text and subtext Pass  an object of style properties .This array is used as default value
BaseButton.defaultProps = {
  variant: 'default',
  disabled: false,
  isLoading: false,
  gradientColor: ['red', 'blue'],
  gradientStart: { x: 1.0, y: 0.0 },
  gradientEnd: { x: 0.0, y: 1.0 },
  spinnerSize: 'small',
  buttonStyle: [],
  textStyle: [],
  subTextStyle: [],
  shadow: false,
};