import {
  DATE_MONTH,
  POSTSALES_DATE_TIME,
  reformatDateString,
} from '@mmt/legacy-commons/Helpers/dateTimehelpers';
import myTripsNavigation from '../../../navigation/navigation';
import { titleCase } from '@mmt/legacy-commons/Helpers/stringHelpers';
import upcomingBookingIcon from '@mmt/legacy-assets/src/postSales/summary/upcomingBusIcon.webp';
import completedBookingIcon from '@mmt/legacy-assets/src/postSales/summary/completedBusIcon.webp';
import cancelledBookingIcon from '@mmt/legacy-assets/src/postSales/summary/cancelledBusIcon.webp';
import failedBookingIcon from '@mmt/legacy-assets/src/postSales/summary/failedBusIcon.webp';
import { getStaticData } from '../../../../staticData/staticData';
import {
  getDateTimeWithPaxInfo,
  unsuccessfulBookingTextHandler,
} from '../../../data/tripListManager';
import { msrUpdateAction } from '../../../../utils/MsrUtils';
import { getNeedHelpData, getTalkToUsDetails } from '../../../utils';

function getTravelerSummary(busDetails) {
  const { passengers } = busDetails;
  const count = passengers.length;
  const firstPaxName = titleCase(passengers[0].firstName);
  if (count === 1) {
    return firstPaxName;
  }
  return `${firstPaxName} + ${count - 1}`;
}

export function getBusSummaryData(data, msrTicket) {
  const staticData = getStaticData();
  const [busDetails] = data.coachDetails;
  const { busStartDateTime } = busDetails;

  const departureDate = reformatDateString(data.minDeptDate, POSTSALES_DATE_TIME, DATE_MONTH);
  let coachVehicleInfo = `${busDetails.coachName} | ${busDetails.vehicleType}`;
  const pageData = {
    fromMyTrips: true,
  };
  const openDetails = () => {
    myTripsNavigation.openBusBookingDetail(data, pageData);
  };

  const actions = [];
  const failedBooking = data.status === 'FAILED';
  const msrAction = msrUpdateAction(msrTicket);
  if (msrAction) {
    actions.push(msrAction);
  }

  const talkToUsDetails = getTalkToUsDetails(
    data.coachDetails?.[0]?.productCode,
    data.uuid,
    false,
    true
  );
  const needHelpData = getNeedHelpData(msrTicket, data.bookingID, data.needHelpData, data.showQuickResolve, talkToUsDetails);
  const dateMonth = busStartDateTime ? busStartDateTime.split('`')[0] : departureDate;

  const travelerSummary = getTravelerSummary(busDetails);
  let dateTime = `${dateMonth} ${failedBooking ? '' : `• ${travelerSummary}`}`;
  let iconUrl = '';
  switch (data.status) {
    case 'UPCOMING':
      iconUrl = upcomingBookingIcon;
      break;
    case 'COMPLETED':
      iconUrl = completedBookingIcon;
      break;
    case 'CANCELLED':
      iconUrl = cancelledBookingIcon;
      dateTime = getDateTimeWithPaxInfo(data, staticData.cancelledText, dateTime, travelerSummary);
      break;
    case 'FAILED':
      iconUrl = failedBookingIcon;
      dateTime = getDateTimeWithPaxInfo(data) || unsuccessfulBookingTextHandler(dateTime);
      coachVehicleInfo = staticData.bookingSummaryText.additionalText.busBookingFailedText;
      break;
    default:
      iconUrl = upcomingBookingIcon;
      break;
  }
  return {
    status: data.status,
    iconUrl: iconUrl,
    from: busDetails.originCityName,
    to: busDetails.destinationCityName,
    dateTime: dateTime,
    busNameAndType: coachVehicleInfo,
    persuasions: [],
    actions,
    openDetails,
    failedBooking,
    needHelpData,
  };
}

export default {};
