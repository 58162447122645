import React from 'react';
import { Image, Text, TouchableWithoutFeedback, View } from 'react-native';
import { INFO_BLUE_ICON, ZC_IMAGE, TGS_ICON } from '../../Utils/RailImageConstant';
import TrainInfoCard from '../TrainInfoCard';
import isEmpty from 'lodash/isEmpty';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import getStyles from './RailCardCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getCardHeaderStyle, getSegmentInfo } from '../../details/data/helper';
import PopupTriggerInfoCard from '../../../Common/PopupTriggerInfoCard';
import { RAIL_OVERLAY } from '../../details/RailBookingDetailConstant';

const RailMainCard = ({ response, pageName, handleBottomSheetOverlay, card, isSubCard }) => {
  const railDetailResponse = getSegmentInfo(response);
  const { zcplusCardData } = response;
  const { freeCancellation } = railDetailResponse;
  const { psTheme } = useTheme();
  const { fsStyle, ...fonts } = getFont(true);
  const styles = getStyles(fonts, psTheme);
  const { color } = psTheme;
  const { chartStatus, tgStickyHeader, freeCancellationApplicable } = card || {};
  const { source, textColor, backgroundColor } = getCardHeaderStyle(response, color);
  return (
    <View
      style={[
        styles.tgsWhiteCardWrapper,
        isSubCard ? AtomicCss.marginVertical10 : AtomicCss.margin10,
      ]}
    >
      <View
        style={[
          styles.tgsWhiteCard,
          isSubCard ? styles.borderRadiusSubCard : styles.borderRadiusCard,
        ]}
      >
        <View style={[styles.chartInfo, { backgroundColor: backgroundColor }]}>
          <Image style={styles.greentickStyle} source={source} />
          {!isEmpty(chartStatus) && (
            <Text style={[styles.chartInfoTxt, { color: textColor }]}>{chartStatus}</Text>
          )}
        </View>
        <TrainInfoCard response={response} />
        {freeCancellationApplicable && !isEmpty(freeCancellation) && (
          <PopupTriggerInfoCard
            handleBottomSheetOverlay={handleBottomSheetOverlay}
            actionParamenter={RAIL_OVERLAY.FREE_CANCELLATION}
            imageUrl={ZC_IMAGE}
            imageStyle={styles.freeCancellation}
            infoIconImageUrl={INFO_BLUE_ICON}
            text={freeCancellation.header}
          />
        )}

        {!isEmpty(zcplusCardData) && !isEmpty(tgStickyHeader) && (
          <PopupTriggerInfoCard
            handleBottomSheetOverlay={handleBottomSheetOverlay}
            actionParamenter={RAIL_OVERLAY.TGS}
            containerStyle={styles.guaranty}
            textStyle={styles.guarantyText}
            imageUrl={TGS_ICON}
            imageStyle={styles.tripGuaranty}
            infoIconImageUrl={INFO_BLUE_ICON}
            text={tgStickyHeader}
            iconImageStyle={styles.guarantyTextIcon}
          />
        )}
      </View>
    </View>
  );
};

export default RailMainCard;
