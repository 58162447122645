import {Dimensions, Platform} from 'react-native';
import {generateGuid} from './Utils/CommonUtils';
import ErrorImg from '@mmt/legacy-assets/src/Images/Errors/error.webp';
import ManyFiltersImg from '@mmt/legacy-assets/src/Images/Errors/too-many-filters.webp';
import NoBookingImg from '@mmt/legacy-assets/src/Images/Errors/no-booking.webp';
import NoInternetImg from '@mmt/legacy-assets/src/Images/Errors/no-internet.webp';
import NoResultImg from '@mmt/legacy-assets/src/Images/Errors/no-result.webp';
import EnvConfig from "./EnvConfig";
export const STRING_SEPERATOR = '~';
export const API_BASE_URL = Platform.OS === 'web' && __DEV__ ? `${document.location.origin}/api/v1` : 'https://acme.makemytrip.com/api/v1';
export const PROMO_OFFERS_BASE_URL = 'https://hcs-promo.makemytrip.com/makemytrip/promov3';
export const ACME_IMAGE_STATIC_URL = 'https://images-acme.mmtcdn.com/prod-acme-image/system/product_media/static';
export const ACME_BRANCH_IO_BASE_URL = 'https://applinks.makemytrip.com/experiences';
export const BRANCH_IO_API_URL = 'https://api2.branch.io/v1/url';
export const BRANCH_IO_KEY = 'key_live_booIGor5qqxETHkSrWppphjmBEmfgOMb';
export const PDT_URL = 'https://pdt.makemytrip.com/dts/s/da/n';
export const APP_STORE_URL = 'https://itunes.apple.com/in/app/makemytrip-flights-hotels/id530488359?mt=8';
export const PLAY_STORE_URL = 'market://details?id=com.makemytrip';
export const MY_TRIPS_URL = 'https://pwa-supportz.makemytrip.com';
export const SUPPORTZ_COOKIE_DOMAIN = '.supportz.makemytrip.com';
export const AMENITIES_STATIC_URL = `${ACME_IMAGE_STATIC_URL}/amenities`;
export const POI_STATIC_URL = `${ACME_IMAGE_STATIC_URL}/poi`;
export const CITY_STATIC_URL = `${ACME_IMAGE_STATIC_URL}/city`;
export const DETAIL_API_ENDPOINT = '/detail';
export const SEARCH_API_ENDPOINT = '/search';
export const COLLECTION_API_ENDPOINT = '/collections';
export const UPCOMING_TRIP_RECOMMENDATION_API_ENDPOINT = '/common/upcoming/trip/recommendation';
export const PRO_BOOKING_ENDPOINT = '/booking/pro';
export const MULTI_PRO_BOOKING_ENDPOINT = '/booking/multiPro/selfBundling';
export const SCRATCH_CARD_TIME_ENDPOINT = '/scratchcard/start-end-time';
export const SCRATCH_CARD_FAQ_ENDPOINT = '/scratchcard/faq';
export const SCRATCH_CARD_CONFIG_ENDPOINT = '/scratchcard/scratch-card-campaign';
export const USER_SCRATCH_CARDS_ENDPOINT = '/scratchcard/user-scratch-cards';
export const USER_SCRATCH_CARD_WINS_ENDPOINT = '/scratchcard/user-prizes';
export const SCRATCH_CARD_RECENT_WINNERS_ENDPOINT = '/scratchcard/winners';
export const SCRATCH_CARD_PRIZE_INFO_ENDPOINT = '/scratchcard/get-prize-info';
export const SCRATCH_CARD_PRIZE_ASSIGN_ENDPOINT = '/scratchcard/scratch-a-card';
export const RATE_INVENTORY_API_ENDPOINT = '/rate/inventory';
export const BOOKING_CONFIRM_API_ENDPOINT = '/booking/details';
export const FOOD_BOOKING_CONFIRM_API_ENDPOINT = '/booking/details/multi';
export const COUPON_RECOMMEND_API_ENDPOINT = '/coupons/recommend';
export const MULTI_COUPON_RECOMMEND_API_ENDPOINT = '/coupons/recommend/multi';
export const COUPON_VALIDATE_API_ENDPOINT = '/coupons/validate';
export const DYNAMIC_FORM_GET_API_ENDPOINT = '/getFormSchema';
export const DYNAMIC_FORM_VALIDATE_API_ENDPOINT = '/validateFormData';
export const DYNAMIC_FORM_SAVE_API_ENDPOINT = '/validateAndSaveFormData';
export const ACME_PRO_BOOKING_ID = 'AcmeProBookingId'; // Key to fetch the ProBooking ID from async storage
export const LOCALITY_API_ENDPOINT = '/locality';
export const MASTER_TYPE_API_ENDPOINT = '/masterType';
export const AUTO_SEARCH_API_ENDPOINT = '/auto_search';
export const RECENT_SEARCHES_API_ENDPOINT = '/common/recentSearch/getData';
export const SAVE_RECENT_SEARCHES_API_ENDPOINT = '/common/recentSearch/postData';
export const TRENDING_SEARCHES_API_ENDPOINT = '/common/recentSearch/trending';
export const HOME_PAGE_API_ENDPOINT = '/home/page';
export const CONTINUE_BOOKING_API_ENDPOINT = '/continueBooking/getProductsToContinueBkg';
export const CITY_API_ENDPOINT = '/city';
export const BANNER_API_ENDPOINT = '/common/banners';
export const FEEDBACK_API_ENDPOINT = '/userQuery';
export const AFFILIATE_RECOM_API_ENDPOINT = '/common/affiliate/recommendation';
export const USER_REVIEW_INFO = '/user/review/info';
export const USER_REVIEW_SUBMIT = '/user/review/submit';
export const REVIEW_API_ENDPOINT = '/user/product/review';
export const COMMID_ENCRYPTION_API_ENDPOINT = '/common/userService/encodeDecode';
export const SAVE_DELIVERY_ADDRESS_API_ENDPOINT = '/common/address/save';
export const GET_DELIVERY_ADDRESS_API_ENDPOINT = '/common/address/get';
export const GET_BANNER = '/common/home/anywhere/banners';
export const COUNTRY_CODE_LIST_ENDPOINT = 'https://acme.makemytrip.com/country_code.json';

export const USER_AGREEMENT_URL = 'https://www.makemytrip.com/legal/user_agreement.html';
export const TERMS_SERVICES_URL = 'https://www.makemytrip.com/legal/user_agreement.html#tos-activities';

export const PRIVACY_POLICY_URL_GCC = 'https://www.makemytrip.com/legal/ae/privacy_policy.html';
export const USER_AGREEMENT_URL_GCC = 'https://www.makemytrip.com/legal/ae/user_agreement.html';
export const TERMS_SERVICES_URL_GCC = 'https://www.makemytrip.com/legal/ae/user_agreement.html#tos';
export const INR = 'INR';
export const PRICE = 'price';
export const GET_CITY_BY_LOCATION_URL = '/getCityByLocation';

export const PRIVACY_POLICY_URL_BASED_ON_TXN_CURRENCY = {
  AED: PRIVACY_POLICY_URL_GCC,
  INR: TERMS_SERVICES_URL
};

export const USER_AGREEMENT_URL_BASED_ON_TXN_CURRENCY = {
  AED: USER_AGREEMENT_URL_GCC,
  INR: USER_AGREEMENT_URL
};

export const TERMS_SERVICE_URL_BASED_ON_TXN_CURRENCY = {
  AED: TERMS_SERVICES_URL_GCC,
  INR: TERMS_SERVICES_URL
};

export const REGIONS_BASED_ON_TXN_CURRENCY = {
  AED: 'ae',
  INR: 'in'
};

export const COUNTRY_CODE_BASED_ON_TXN_CURRENCY = {
  AED: 'ae',
  INR: 'in'
};

export const LISTING_CARD_FIELDS = [
  'starRating',
  'cityId',
  'duration',
  'coverImageUrl',
  'displayPrice',
  'essence',
  'mrp',
  'unitType',
  'instantConfirmation'
];
// listing api
export const SEARCH_API_TYPES = {
  NEAR_ME: 'nearme',
  CITY: 'city',
  ESSENCE: 'essence',
  LOCALITY: 'l',
  GEO_LOCATION: 'GeoLocation' // send by android/iOS native.
};

export const PRODUCT_PROFILE = {
  LOCAL: '2',
  TOURIST: '3'
};

export const PRODUCT_PROFILE_TEXT = [
  'LOCAL',
  'TOURIST'
];

export const DEFAULT_CITY_TITLE = 'Anywhere';
export const DUBAI = 'Dubai';
export const LISTING_SIZE = 20;
export const RECENT_CITY_SEARCH_LIMIT = 10;
export const DETAIL_PAGE_CAROUSEL_HEIGHT = 240;
export const CATEGORY_ID = {
  MMT_PAY: 263,
  ACTIVITIES: 139,
  COUPONS: 141
};

export const MAP_PARAMS = {
  BASE_URL: 'https://maps.googleapis.com/maps/api/staticmap',
  SIZE: '320x135',
  ZOOM: 12,
  SCALE: 2,
  MAP_TYPE: 'roadmap',
  MARKER_SIZE: 'mid',
  MARKER_COLOR: 'red'
};

export const MAP_URL_SCHEME = 'http://maps.apple.com/?q=';
export const GOOGLE_MAP_API = `https://maps.googleapis.com/maps/api/geocode/json?key=&latlng=`;
export const NAME_REGEX = /^[a-zA-Z ]*$/;
export const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const MOBILE_REGEX = /^(\+\d{1,3}[- ]?)?\d{10}$/;
export const PINCODE_REGEX = /^[1-9][0-9]{5}$/;
export const API_METHOD = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT'
};

export const UNIT_TYPES = {
  UNIT: 1,
  ADULT: 2
};

export const COUNTRY_CODE_BASED_ON_TRANSACTION_CURRENCY = {
  INR: '91',
  AED: '971'
};

export const UNIT_TEXTS = {
  UNIT: {
    SINGULAR: 'Voucher',
    PLURAL: 'Vouchers'
  },
  PERSON: {
    SINGULAR: 'Person',
    PLURAL: 'Persons'
  },
  ADULT: {
    SINGULAR: 'Adult',
    PLURAL: 'Adults'
  }
};

export const DEEP_LINK_PAGE_NAMES = {
  ACTIVITY_DETAIL: 'activityDetail',
  LISTING: 'activityListing',
  LANDING: 'acmeLanding',
  SCRATCH_CARD_LANDING: 'scratchCardLanding'
};

export const NATIVE_EVENTS = {
  SCRATCH_CARD_LOGIN_EVENT: 'scratch_card_login_event',
  FEEDBACK_LOGIN_EVENT: 'feedback_login_event',
  MYRA_LOGIN_EVENT: 'myra_login_event',
  NPS_CLOSE_EVENT: 'nps_close_event',
  HARDWARE_BACK_PRESS: 'hardwareBackPress'
};

export const LOGIN_REQUEST_CODE = {
  SCRATCH_CARD_LOGIN_REQUEST: 1000,
  FEEDBACK_LOGIN_REQUEST: 1001
};

export const LANDING_SECTIONS = {
  TRENDING: 'trending',
  EXPLORE: 'explore',
  ESSENCE: 'essence',
  BANNER: 'banner',
  SCRATCH_CARD: 'scratch_card',
  UPCOMING_TRIPS: 'upcoming_trips',
  CONTINUE_BOOKING: 'continue_booking',
  PROMO_OVERYLAY: 'promo_overlay',
  POI: 'poi',
  HASH_TAG: 'hash_tag',
  INDIAN_CITIES: 'indian_cities',
  INTERNATIONAL_CITIES: 'international_cities',
  OTHER_POPULAR_CITIES: 'other_popular_cities',
  TOP_INDIAN_ACTIVITIES: 'top_indian_activities',
  TOP_GLOBAL_ACTIVITIES: 'top_global_activities',
  FEEDBACK_SECTION: 'feedback_section',
  LAST_SECTION: 'last_section',
  FOOD_DELIVERY_ENTRY_SECTION: 'foodDeliveryEntrySection',
  COLLECTIONS: 'collections',
  ADTECH:'adtech',
  BANNER_V2: 'bannerv2'
};

export const DEFAULT_QUICK_SEARCH_SECTION_NAME = 'empty';

// tyoes of section to show in global search body (in search screen)
export const QUICK_SEARCH_SECTIONS = {
  SUGGESTIONS: 'suggestions',
  EXPLORE: 'explore',
  EMPTY: 'empty'
};

export const PAGE_STATUS = {
  LOADING: 'loading',
  SUCCESS: 'success',
  DEFAULT: 'default',
  NO_RESULT: 'noResult',
  NO_INTERNET: 'noInternet',
  TOO_MANY_FILTERS: 'tooManyFilters',
  NO_BOOKING: 'noBooking',
  ERROR: 'error',
  PENDING: 'pending',
  CONFIRMING: 'confirming',
  FAILED: 'failed'
};

export const SEARCH_PLACE_HOLDER = {
  a: 'Search Activity, Category or City',
  e: 'Search Event, Category or City',
  fd: 'Search Deal, Category or City',
  od: 'Search for restaurants'
};

export const DEVICE_WINDOW = Platform.OS === 'ios' ? Dimensions.get('screen') : Dimensions.get('window');

export const AUTOCOMPLETE_TYPE = {
  CITY: 'city',
  GLOBAL: '',
  LOCALITY: 'l',
  PRODUCT: 'p',
  TAG: 'tag'
};

export const AUTOCOMPLETE_RESULT_TYPE = {
  city: 'City',
  locality: 'Locality',
  product: 'Experience',
  tag: 'Category'
};

export const ERROR_CASES = {
  error: {
    img: ErrorImg,
    title: 'Uh Oh!',
    desc: 'It looks like something went wrong. Why don’t you write to us and we\'ll get back to you.'
  },
  noResult: {
    img: NoResultImg,
    title: 'No Activities found for the selected dates!',
    desc: 'Some activities happen on particular days of the week only. Kindly choose a different date.'
  },
  tooManyFilters: {
    img: ManyFiltersImg,
    title: 'Too many filters applied',
    desc: 'Try a different combination of filters. Or try removing some filters.'
  },
  noBooking: {
    img: NoBookingImg,
    title: 'Activity not booked',
    desc: 'The activity could not be booked due to some reasons. Please try again.'
  },
  noInternet: {
    img: NoInternetImg,
    title: 'You are not connected to the Internet'
  }
};

export const PLATFORM_MAP = {
  ios: 'Ios',
  android: 'Android',
  web: 'Web'
};

export const PLATFORM_IOS = 'ios';
export const PLATFORM_ANDROID = 'android';
export const PLATFORM_WEB = 'web';

export const USER_PLATFORM = {
  IOS: Platform.OS === PLATFORM_IOS,
  ANDROID: Platform.OS === PLATFORM_ANDROID,
  WEB: Platform.OS === PLATFORM_WEB
};

export const RESPONSE_SUCCESS = 'SUCCESS';

export const GENERIC_ERROR_MSG = 'Something went wrong! Please try again!';

export const BASIC_AUTH_CREDENTIALS = {
  androidAuth: EnvConfig.BASIC_AUTH_CREDENTIALS.androidAuth,
  iosAuth: EnvConfig.BASIC_AUTH_CREDENTIALS.iosAuth,
  webAuth: EnvConfig.BASIC_AUTH_CREDENTIALS.webAuth,

  set ANDROID(androidAuth) {
    this.androidAuth = androidAuth;
  },
  set IOS(iosAuth) {
    this.iosAuth = iosAuth;
  },
  set WEB(webAuth) {
    this.webAuth = webAuth;
  },

  get ANDROID() {
    return this.androidAuth;
  },
  get IOS() {
    return this.iosAuth;
  },
  get WEB() {
    return this.webAuth;
  }
};

export const PROMO_OFFERS_API_AUTH = EnvConfig.PROMO_OFFERS_API_AUTH;

export const BASIC_AUTH_CREDENTIALS_ACME = EnvConfig.BASIC_AUTH_CREDENTIALS_ACME;

export const BASIC_AUTH_CREDENTIALS_HLD = EnvConfig.BASIC_AUTH_CREDENTIALS_HLD;
export const BASIC_AUTH_CREDENTIALS_FLIGHTS = EnvConfig.BASIC_AUTH_CREDENTIALS_FLIGHTS;
export const BASIC_AUTH_CREDENTIALS_HOTELS = EnvConfig.BASIC_AUTH_CREDENTIALS_HOTELS;

export const BASIC_AUTH_CREDENTIALS_TRIP_ASSISTANT = EnvConfig.BASIC_AUTH_CREDENTIALS_TRIP_ASSISTANT;

// TODO - Please replace this image with actual (When available).
export const IMAGE_NOT_FOUND = require('@mmt/legacy-assets/src/Images/no_image_default.webp');

// Keys to be used when saving/getting data to/from AsyncStorage
export const LOCAL_STORAGE_FIELDS = {
  ACTIVE_LOCATION_INFO: 'activeLocationInfov1', // old key name: activeLocationInfo
  DETAIL_DATA: 'detail_data',
  USER_CREDENTIALS: 'user_credentials',
  IS_LOCATION_GIVEN_BY_USER: 'false',
  HOME_PAGE_DATA: 'homepageData',
  DYNAMIC_PROMO_OVERYLAY: 'dynamicPromoOverlay',
  RECENT_CITY_SEARCH: 'recentcitysearch',
  CATEGORY: 'category',
  PDT_CONTEXT: 'pdtContext',
  OMNITURE_CONTEXT: 'omniture_context',
  GPS_LOCATION_DATA: 'GPS_LOCATION_DATA',
  COUNTRY_CODE_LIST: 'COUNTRY_CODE_LIST',
  PRIMARY_TAG: 'primaryTag',
};

// cache keys
export const CACHE_KEY = {
  MASTER_TYPES_DATA: 'masterTypesData',
  CITY_LIST: 'cityList_v1', // old keyname: cityList,
  COUNTRY_CODE_LIST: 'countryCodeList'
};

export const DEFAULT_LOCATION_INFO = {
  type: '',
  id: null,
  title: '',
  value: ''
};

export const CALENDAR_EVENT_RECEIVED = 'calendar_event_received';
export const OPEN_TY_PAGE_EVENT = 'open_thankyou_page_event';

export const COMMON_LISTING_REQUEST_PARAM = {
  fields: LISTING_CARD_FIELDS,
  isMetaDataRequired: true
};

// Keys


export const ERROR_CODES = {
  PRICE_MISMATCH: 'PBKG_01',
  COUPON_CODE_ERROR: '111', // Error code sent by CDF
  NO_INTERNET_ERROR_CODE: 'NO_INTERNET_ERROR',
  BOOKING_NOT_FOUND: 'BKG_05',
  ALREADY_USED_COUPON: 'COUP_03',
  INVALID_EMAIL: 'COM_04',
  INVALID_PHONE: 'COM_05',
  INVALID_PHONE_CODE: 'COM_06',
  INVALID_FIRST_NAME: 'COM_07',
  INVALID_LAST_NAME: 'COM_08',
  FORM_FIELD_MANDATORY: 'FORM_01',
  NOT_SERVICABLE: 'D_PBKG_12',
  HTTP_ERROR_CODE_204: '204',
};

export const DATE_SELECTOR_OPTION = {
  TODAY: 'Today',
  TOMORROW: 'Tomorrow',
  THIS_WEEK: 'This Week',
  THIS_WEEKEND: 'This Weekend',
  ALL_EXPERIENCES: 'All Days'
};

export const WEEKEND_DAYS = [5, 6, 7];

export const DEFAULT_DATE_SELECTOR_OPTION = DATE_SELECTOR_OPTION.ALL_EXPERIENCES;

export const DEFAULT_HEADER_TITLE = 'Experiences';

// constant defining the stepsize to be used for value <= maxValue in below object
export const PRICE_RANGE_FILTER_MAPPING = [
  {
    maxValue: 500,
    stepSize: 50
  },
  {
    maxValue: 1000,
    stepSize: 50
  },
  {
    maxValue: 1500,
    stepSize: 100
  },
  {
    maxValue: 2000,
    stepSize: 100
  },
  {
    maxValue: 2500,
    stepSize: 100
  },
  {
    maxValue: 3000,
    stepSize: 150
  },
  {
    maxValue: 5000,
    stepSize: 250
  },
  {
    maxValue: 7500,
    stepSize: 300
  },
  {
    maxValue: 10000,
    stepSize: 500
  },
  {
    maxValue: 50000,
    stepSize: 2000
  },
  {
    maxValue: 100000,
    stepSize: 4000
  },
  {
    maxValue: 150000,
    stepSize: 6000
  }
];

export const IMAGE_TYPE = {
  THUMBNAIL: 'THUMBNAIL',
  CAROUSEL: 'CAROUSEL',
  HOME_BANNER: 'HOME_BANNER',
  POI_LISTING: 'POI_LISTING'
};

export const ERROR_MSG = {
  NO_INTERNET: 'Please check your internet connection',
  ENTER_VALID_EMAIL: 'Please enter a valid Email address',
  ENTER_VALID_MOBILE_NUMBER: 'Please enter a valid Mobile number',
  TNC_NOT_ACCEPTED: 'Please accept Terms & Conditions before proceeding'
};

export const CUSTOMER_CARE_NO = '(0124) 462 8747';

export const DEFAULT_COUNTRY_CODE = '91';

export const MAX_PAX_COUNT_ALLOWED = 10;

// Constants to be used for maintaining participant counts
export const PARTICIPANT_TYPES = {
  UNIT: 'U',
  CHILD: 'C',
  INFANT: 'I',
  SENIOR: 'S',
  YOUTH: 'Y'
};

export const ACME_LOB_CODES = {
  a: 'acme',
  fd: 'm&d'
};

export const COSMOS_SEARCH_EVENT = 'acmeSearchEvent';

// type of suggestions - Suggestions are available in search body (in search screen)
export const SEARCH_SUGGESTION_TYPES = {
  ESSENCE: 'essence',
  TAG: 'tag',
  POI: 'poi',
  COLLECTIONS: 'collections'
};

export const PERSUASION_CONSTANTS = {
  PERSUASION_BANNER: 'persuasionBanner',
  MMT_EXCLUSIVE: 'mmtexclusive',
  MMT_BLACK: 'MMT_BLACK',
  PLACEHOLDER3: 'placeholder3'
};

export const PRODUCT_MEDIA_TAGS = {
  MENU: 'menu',
  WHAT_TO_EXPECT: 'what_to_expect',
  COVER: 'cover',
  MAIN: 'main',
  SAFETY: 'safety'
};

export const MEDIA_TYPES = {
  VIDEO: 'video',
  IMAGE: 'image'
};

export const CAROUSEL_LIST_SIZE = 10;

export const LIGHTBOX_TYPES = {
  DETAIL_CAROUSEL_IMAGES: 'image',
  DETAIL_MENU_IMAGES: 'menu_image'
};

export const FILTER_KEYS = {
  PRICE: 'price',
  DURATION: 'duration',
  TAGS: 'tags',
  LOCALITY: 'locality',
  RATING: 'rating'
};

let sessionObj = null; // Session ID value to be cached
export const getSessionObj = () => {
  if (sessionObj) {
    return sessionObj;
  }
  sessionObj = {
    sessionId: generateGuid(),
    sessionTimeStamp: new Date()
  };

  return sessionObj;
};

export const PRODUCT_CARD_TYPES = {
  CARD1: 'CARD1',
  CARD2: 'CARD2',
  CARD3: 'CARD3',
  CARD4: 'CARD4'
};

export const CONNECTION_TYPE_MAP = {
  none: 'None',
  wifi: 'WiFi',
  cellular: 'Cellular',
  unknown: 'Unknown'
};

export const ACME_CATEGORIES = {
  ACTIVITIES: 'a',
  EVENTS: 'e',
  FOODDEALS: 'fd',
  EXPERIENCES: 'exp',
  ONLINE_DELIVERY: 'od',
  HOTELS_VOUCHERS: 'hd'
};

export const CITY_ID = {
  DUBAI: 357,
  ALL: 0,
}

export const ACME_CATEGORY_IDS = {
  a: 139,
  e: 367,
  fd: 365
};

export const CATEGORY_NAMES = {
  a: 'Activities',
  e: 'Events',
  exp: 'Experiences',
  od: 'Online Delivery',
  fd: 'Meals and Deals',
  hd: 'Future Stays'
};

export const CATEGORY_DISPLAY_NAME = {
  a: 'Activities',
  e: 'Events',
  fd: 'Deals',
  od: 'Restaurants',
  hd: 'Future Stays'
};

export const DYNAMIC_FORM_THANKYOU_TEXT = {
  NON_EDITABLE: 'Filled in details will be shared with the vendor for confirmation ',
  EDITABLE: 'There might be few more additional details required from the host. Please fill in the detail now. You may find the link at My Bookings page also.'
};

export const WEBBODY_TOP_SPACING = 60;

export const API_CALL_STATUS = {
  DEFAULT: 'default',
  INPROGRESS: 'inprogress',
  SUCCESS: 'success',
  ERROR: 'error'
};

export const QUERY_FORM_BG = 'https://images-acme.mmtcdn.com/prod-acme-image/system/product_media/static/promo/query_bg.png';

export const HLD_CRITERIAS = {
  PLACES: 22,
  DESTINATION: 42
};

export const AUTH_ENV = {
  ACME: 'acme',
  HLD: 'hld',
  HOTELS: 'htl',
  FLIGHTS: 'flt',
  TRIP_ASSISTANT: 'ta'
};

export const PAGE_LAUNCH_SOURCE = {
  HUBBLE: 'hubble'
};

export const MAP_PLACEHOLDER_IMG = 'https://images-acme.mmtcdn.com/prod-acme-image/system/product_media/static/promo/map_placeholder.png';


export const DETAILS_PAGE_SECTION_ORDERING = {
  persuasions: 1,
  whyShouldIDo: 2,
  mySafety: 3,
  mmtBlack: 4,
  aboutExperience: 5,
  featuresAndAmenities: 6,
  reviewRating: 7,
  whatToExpect: 8,
  menu: 9,
  inclusionsAndExclusions: 10,
  location: 11,
  tips: 12,
  schedule: 13,
  docsToCarry: 14,
  thingsToCarry: 15,
  howToRedeem: 16,
  tnc: 17,
  faqs: 18,
  cancellationPolicy: 19,
  similarActivities: 20,
  serviceProvider: 21
};

export const RATING_LABEL = {
  ratingPoor: 'Poor',
  ratingAverage: 'Average',
  ratingGood: 'Excellent'
};

export const RATING_LABEL_MAPPING = {
  1: "TERRIBLE",
  2: "POOR",
  3: "AVERAGE",
  4: "GOOD",
  5: "EXCELLENT"
};

export const PRODUCT_CARD_DIMENSION = {
  CARD1: {width: 140, height: 210},
  CARD2: {width: 190, height: 230},
  CARD3: {width: 245, height: 115}
};

export const AFFILIATE_SOURCE = {
  HOLIDAYS: 'holidays',
  HOTELS: 'postsales_hotel',
  FLIGHTS: 'postsales_flight'
};
export const AFFILIATE_BASIC_AUTH = {
  HOLIDAYS: 'aG9saWRheXNAZ28tbW10LmNvbTptbXRAMTIz',
  HOTELS: 'bXlUcmlwc0hvdGVsOkFjbTNGdW5uM2w=',
  FLIGHTS: 'bXlUcmlwc0ZsaWdodDpBY20zRnVubjNs',
  TRIP_ASSISTANT: 'dHJpcEFzc2lzdGFudDpBY20zRnVubjNs'
};
export const FEEDBACK_FORM = {
  MAX_IMAGES: 10,
  MAX_IMAGE_DIMENTION: 1000, // 1000 x 1000 pixcels
  IMAGE_QUALITY: USER_PLATFORM.IOS ? 0.8 : 95
};
export const DEFAULT_SHOW_NEW_UGC_FORM = true;
export const STATUS_BAR_HIGHT = 20;

export const MY_SAFETY_DATA = {
	"common": {
		"description": "You have chosen an activity that ensures measures for safe sanitized environment & social distancing.",
		"safetyMeasures": ["Sanitized Environment", "Trained Staff", "Safe Practices"],
		"domesticGuidelinesLink": "https://www.makemytrip.com/promos/mysafety-state-guidelines.html",
		"internationalGuidelinesLink": "https://www.makemytrip.com/support/intl-travel/guideline.php",
		"moreInfoLink": "https://www.makemytrip.com/promos/MySafety.html?tab=activities"
	},
	"landing": {
		"description": "Destinations you travel to may have some restrictions in place. We request you to keep track of latest government mandated restrictions and plan your travel accordingly"
	},
	"listing": {
		"description": "Activities with best safety and hygiene measures for you"
	},
	"details": {
		"description": "This activity is following these safety measures"
	}
};

export const LISTING_CARD_ORDERS = {
  "safetyAssured": 2,
  "mmtBlack": 4
};
