import React from 'react';
import {Image, Text, TouchableOpacity, View} from 'react-native';
import PropTypes from 'prop-types';
import styles from './BusOperatorCss';
import {oneWayJourney} from '../../BusImageConstants'
import { getFont } from '../../../PostSalesStyles';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';

export default class BusOperator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPax: {}
    };
  }

  render() {
    const allSelectedStyle = 'Selected';
    return (
      <View style={styles.cardWrapper}>
        <View style={styles.checkAllWrapper}>
          <View style={[styles.checkAllWrapperInner]}>
            <TouchableOpacity
              style={[styles.checkBtn, styles.checkAllBtn, styles.PaxslctionHeader]}
              activeOpacity={0.9}
            >
              <View style={styles.SectorHeader}>
                <View style={{ flexDirection: 'row' }}>
                  <View>{this.addSegmentInfo()}</View>
                </View>
              </View>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    );
  }

  addSegmentInfo() {
    const fonts = getFont(true);
    return (
    <View key={this.props.response.busBookingInfo.busDetail.ticketNumber}>
      <View style={[styles.destRow]}>
        <Text style={[styles.cityText, fonts.boldFontFamily, fonts.font16, AtomicCss.lineHeight24]}>{this.props.response.busBookingInfo.busDetail.fromCity}</Text>
        <Image style={styles.arrowIcon} source={oneWayJourney} />
        <Text style={[styles.cityText, fonts.boldFontFamily, fonts.font16, AtomicCss.lineHeight24]}>{this.props.response.busBookingInfo.busDetail.toCity}</Text>
      </View>
      <Text style={[styles.smallText, fonts.semiBoldFontFamily]}>
        {this.props.response.busAdditionalInfo.departureDetail.departureTimeInTwentyFourHoursFormat}, {this.props.response.busAdditionalInfo.departureDetail.departureDay}, {this.props.response.busAdditionalInfo.departureDetail.departureDayInMonth}, {this.props.response.busAdditionalInfo.departureDetail.departureMonth}, {this.props.response.busAdditionalInfo.departureDetail.departureYear},  {this.props.response.busBookingInfo.busDetail.operator}
      </Text>
    </View>
  );
  }
}

BusOperator.propTypes = {
  passengerClickHandler: PropTypes.func.isRequired
};
