import React, { useEffect, useState } from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import styles, {getHtmlStyle} from './CabVendorInfoCardCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { isEmptyArray, isNullOrEmptyObject } from '../../../Common/commonUtil';
import { dropDownArrow } from '../../CabImageConstants';
import HTMLViewWrapper from './HTMLViewWrapper';
import { isNonEmpty } from '@mmt/legacy-commons/Common/utils/StringUtils';
import { vendorTnCHandler } from '../../utils/CabBookingDetailUtil';
import CardWrapper from '../../modules/bookingDetails/components/CardWrapper';
import CabBookingTrackingHelper from '../../CabBookingTrackingHelper';
import CabBookingDetailsConstant from '../../CabBookingDetailsConstant';

const CabVendorInfoCard = ({ data ,customStyles,  pageName}) => {
  const { vendorIcon, program, tncCta } = data || {};
  const { heading, policies, extraPolicies } = program || {};
  const { url } = vendorIcon || {};
  const { text } = tncCta || {};
  const [toggle, setToggle] = useState(false);
  const handleReadMore = () => {
    let clickEventName = CabBookingDetailsConstant.SPECIAL_PROGRAM_EXPEND_CLICK;
    if(toggle){
      clickEventName = CabBookingDetailsConstant.SPECIAL_PROGRAM_COLLAPSE_CLICK;
    }
    CabBookingTrackingHelper.trackClickEvent(pageName,clickEventName);
    setToggle(!toggle);
  };

  useEffect(()=>{
    CabBookingTrackingHelper.trackClickEvent(pageName,CabBookingDetailsConstant.SPECIAL_PROGRAM_CARD_SHOWN);
  }, []);

  return (
    <View style={[customStyles, AtomicCss.marginBottom12]}>
      <View style={styles.header}>
        {isNonEmpty(url) && <Image source={{ uri: url }} style={styles.blueSmartIcon} />}
        <View style={AtomicCss.flex1}><HTMLViewWrapper htmlContent={heading} /></View>
      </View>
      {!isEmptyArray(policies) &&
        policies.map((item) => (
          <>
            <Text style={AtomicCss.marginBottom12}>
              <HTMLViewWrapper htmlContent={item.heading} />
            </Text>
            {!isEmptyArray(item.additionalInfo) &&
              item.additionalInfo.map((itm) => (
                <View style={[AtomicCss.flexRow, AtomicCss.marginBottom12]}>
                  <View style={styles.bullet}></View>
                  <View style={AtomicCss.flex1}>
                  <HTMLViewWrapper htmlContent={`<b>${itm.heading}</b> ${itm.description}`} />
                  </View>
                </View>
              ))}
          </>
        ))}
      {toggle &&
        extraPolicies.map((item) => (
          <View style={styles.collapsedArea}>
            <Text
              style={[
                AtomicCss.font14,
                AtomicCss.boldFont,
                AtomicCss.blackText,
                AtomicCss.marginBottom12,
              ]}
            >
              <HTMLViewWrapper htmlContent={item.heading} />
            </Text>
            <HTMLViewWrapper htmlContent={item.description} />
          </View>
        ))}
      {!isEmptyArray(extraPolicies) && !toggle && (
        <TouchableOpacity
          onPress={handleReadMore}
          style={[
            AtomicCss.flexRow,
            AtomicCss.alignCenter,
            AtomicCss.spaceBetween,
            AtomicCss.marginTop10,
          ]}
        >
          <Text style={[AtomicCss.font14, AtomicCss.boldFont, AtomicCss.azure]}>{'Read More'}</Text>
          <Image
            source={dropDownArrow}
            style={[styles.downArrowStyle, toggle ? styles.activeArrow : null]}
          />
        </TouchableOpacity>
      )}
      {!isNullOrEmptyObject(tncCta) && toggle && (
        <View
          style={[
            AtomicCss.flexRow,
            AtomicCss.alignCenter,
            AtomicCss.spaceBetween,
            AtomicCss.marginTop10,
          ]}
        >
          <TouchableOpacity onPress={() => vendorTnCHandler(tncCta, pageName)}>
            <Text style={[AtomicCss.font14, AtomicCss.boldFont, AtomicCss.azure]}>{text}</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={handleReadMore}>
            <Image
              source={dropDownArrow}
              style={[styles.downArrowStyle, toggle ? styles.activeArrow : null]}
            />
          </TouchableOpacity>
        </View>
      )}
    </View>
  );
};
export default CardWrapper(CabVendorInfoCard);
