const styles = {
  greylight: { color: '#9b9b9b' },
  greenText: { color: '#1a7971' },
  yellowText: { color: '#cf8100' },
  greenInfo: {
    backgroundColor: '#c1f1dd',
    borderRadius: 4,
    padding: 10,
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 4,
    marginTop: -1,
  },
  tickGreenIcon: { width: 14, height: 14, marginRight: 7 },
  arrowIcon: { width: 11, height: 10, marginHorizontal: 8, marginTop: 4 },
  bottomOverlayWrapper: {
    height: 'auto',
    // padding: 10
  },
  darkText: { color: '#2f2f2f' },
  logoCovidIcon: { width: 35, height: 40, marginRight: 8 },
  headingText: {
    color: '#000',
    fontSize: 22,
    lineHeight: 28,
  },
  imgPlaceholder: {
    width: 24,
    height: 24,
    marginRight: 5,
  },
  payIconStyle: {
    width: 17,
    height: 21,
  },
  phoneIconStyle: {
    width: 24,
    height: 24,
  },
  rcWrapper: {
    borderWidth: 1,
    borderRadius: 4,
    borderColor: '#e7e7e7',
  },
  rcWrapperRow: {
    borderBottomWidth: 1,
    borderBottomColor: '#e7e7e7',
    padding: 16,
    flexDirection: 'row',
  },
  fltIconStyle: {
    width: 20,
    height: 20,
    marginRight: 8,
  },
  calIconStyle: {
    width: 13,
    height: 14,
  },
  userIconStyle: {
    width: 16,
    height: 12,
  },
  rcImgPlaceholder: {
    width: 16,
    height: 16,
    marginRight: 10,
  },
  infoIconStyle: {
    width: 16,
    height: 16,
    marginRight: 10,
    top: 3,
  },
  infoTxt: {
    lineHeight: 22,
    color: '#cf8100',
    fontSize: 14,
    flex: 1,
  },
  marginBottom60: { marginBottom: 30 },
  mealsTabInfo: {
    padding: 16,
    elevation: 2,
    backgroundColor: '#fff',
    borderRadius: 4,
    shadowColor: '#330000',
    shadowOpacity: 0.3,
    shadowRadius: 2,
    marginBottom: 5,
    shadowOffset: {
      width: 0,
      height: 2,
    },
  },
  flightIcon: { width: 31, height: 31, marginRight: 8 },
  redText: { color: '#e53442' },
  paddingHorizontal14: { paddingHorizontal: 14 },
};
export default styles;
