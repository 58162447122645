import React from 'react';
import {View, ScrollView, Image, Platform} from 'react-native';
import PropTypes from 'prop-types';
import Actions from '../../../navigation/postSalesNavigation';
import StickyHeader from '../../../hotel/details/components/StickyHeader';
import styles from '../../CSS/VisaMamiCss';
import VisaBookingTrackingHelper from '../../VisaBookingTrackingHelper';
import {TripHeaderIcon} from '../../Constant/VisaImageConstant';
import {downloadImage} from "../../Util/VisaImageDownloader";


export default class VisaDocumentViewer extends React.Component {
    static navigationOptions = {
      header: null
    }
    constructor(props) {
      super(props);
      this.state = {
          base64Icon: this.props.base64Icon,
      };
      this.callback = {
          onImageLoaded: (base64Icon) => {
              this.setState({ base64Icon });
          },
          onImageLoading: () => { },
          onImageLoadingFailed: () => { },
          onImageQueued: () => { }
      };
    }
    componentDidMount() {
      downloadImage(this.props.url, this.callback);
    }
    onBackPressed = () => {
      Actions.pop();
      VisaBookingTrackingHelper.trackClickEvent(this.props.headerText, this.props.response, 'back_clicked');
    };
    render() {
      return (
        <View style={[styles.VisaMainContainer, styles.MakeRelative]}>
          <StickyHeader
            headerColor={['#ffffff', '#ffffff']}
            iconSrc={TripHeaderIcon}
            title={this.props.headerText}
            subTitle={this.props.passengerName}
            titleStyle={[styles.BlackText, styles.boldFont, styles.bgTransparent, styles.font16]}
            subTitleStyle={[styles.BlackText, styles.boldFont, styles.bgTransparent, styles.font16]}
            onBackPress={this.onBackPressed}
          />
          <ScrollView>
            <View style={styles.OpenPDFWrapper}>
              {Platform.OS === "ios" ? (
              <Image style={styles.PDFImageScreen} source={{
                    uri: this.props.url,
                    method: 'GET',
                    headers: this.props.headers
                }} />
              ) : (
              <Image style={styles.PDFImageScreen} source={{ uri: this.props.base64Icon || this.state.base64Icon }} />
              )}
            </View>
          </ScrollView>
        </View>
      );
    }
}

VisaDocumentViewer.propTypes = {
  url: PropTypes.string.isRequired,
  headerText: PropTypes.string.isRequired,
  passengerName: PropTypes.string.isRequired
};

