import {colors} from '@mmt/legacy-commons/Styles/globalStyles';
import { StyleSheet } from 'react-native';

const getStyle = (fonts, color) => (
  StyleSheet.create({
    container:{
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8
    },
    MamiCardGrey: {
      backgroundColor: color.grey8,
      paddingLeft: 10,
      paddingRight: 10
    },
    CardContentFull: {
      backgroundColor: colors.white,
      padding: 15,
      paddingBottom: 0,
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8
    },
    CardContent: {
      backgroundColor: colors.white,
      flexDirection: 'row',
      alignItems: 'center',
      paddingBottom: 20
    },
    CardIcon: {
      width: 24,
      height: 24,
      marginRight: 10
    },
    marL10per: {paddingLeft: '10%'},
    maleIcon: {width: 16, height: 16, marginRight: 5},
    seatIcon: {width: 16, height: 20, marginRight: 5},
    confirmGreen: {color: color.cyan},
    waitlisted: {color: color.lightYello}
  })
)

export default getStyle;
