import React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import AtomicCss from './commonStyles/AtomicCss';
import { getFont } from '../../../PostSalesStyles';

class FaqsAnswer extends React.Component {

    render() {
      const { fsStyle, ...fonts } = getFont(true);
      const ruleList = this.props.item.messages ? this.props.item.messages : [];
      return (
        <View style={[styles.CollapsedTapDtlsContainer1]}>
          <View style={[AtomicCss.flexRow, styles.answerRow]}>
            <View style={[AtomicCss.flex1, styles.marginRight24]}>
              <Text style={[fsStyle.font12, fonts.blackFontFamily, AtomicCss.defaultTextColor, AtomicCss.marginBottom5]}>{ruleList[0]}</Text>
              <Text style={[fsStyle.font12, fonts.regularFontFamily, AtomicCss.lightGrey]}>{ruleList[1]}</Text>
            </View>
            <View style={AtomicCss.flex1}>
              <Text style={[fsStyle.font12, fonts.regularFontFamily, AtomicCss.defaultTextColor]}>{ruleList[2]}</Text>
            </View>
          </View>
          <View style={[AtomicCss.flexRow, styles.answerRow, styles.answerRowBorder0]}>
              <View style={[AtomicCss.flex1, styles.marginRight24]}>
              <Text style={[fsStyle.font12, fonts.blackFontFamily, AtomicCss.defaultTextColor, AtomicCss.marginBottom5]}>{ruleList[3]}</Text>
              <Text style={[fsStyle.font12, fonts.regularFontFamily, AtomicCss.lightGrey]}>{ruleList[4]}</Text>
            </View>
            <View style={AtomicCss.flex1}>
              <Text style={[fsStyle.font12, fonts.regularFontFamily, AtomicCss.defaultTextColor]}>{ruleList[5]}</Text>
            </View>
          </View>
            {!!ruleList[6] &&<View style={[AtomicCss.flexRow, styles.answerRow]}>
            <View style={[AtomicCss.flex1, styles.marginRight24]}>
                <Text style={[fsStyle.font12, fonts.blackFontFamily, AtomicCss.defaultTextColor, AtomicCss.marginBottom5]}>{ruleList[6]}</Text>
                <Text style={[fsStyle.font12, fonts.regularFontFamily, AtomicCss.lightGrey]}>{ruleList[7]}</Text>
            </View>
            <View style={AtomicCss.flex1}>
                <Text style={[fsStyle.font12, fonts.regularFontFamily, AtomicCss.defaultTextColor]}>{ruleList[8]}</Text>
            </View>
          </View>}
            {!!ruleList[9] &&<View style={[AtomicCss.flexRow, styles.answerRow]}>
            <View style={[AtomicCss.flex1, styles.marginRight24]}>

                <Text style={[fsStyle.font12, fonts.blackFontFamily, AtomicCss.defaultTextColor, AtomicCss.marginBottom5]}>{ruleList[9]}</Text>
                <Text style={[fsStyle.font12, fonts.regularFontFamily, AtomicCss.lightGrey]}>{ruleList[10]}</Text>
            </View>
            <View style={AtomicCss.flex1}>
                <Text style={[fsStyle.font12, fonts.regularFontFamily, AtomicCss.defaultTextColor]}>{ruleList[11]}</Text>
            </View>
          </View>}
            {!!ruleList[12] &&<View style={[AtomicCss.flexRow, styles.answerRow, styles.answerRowLast]}>
            <View style={[AtomicCss.flex1, styles.marginRight24]}>
                <Text style={[fsStyle.font12, fonts.blackFontFamily, AtomicCss.defaultTextColor, AtomicCss.marginBottom5]}>{ruleList[12]}</Text>
                <Text style={[fsStyle.font12, fonts.regularFontFamily, AtomicCss.lightGrey]}>{ruleList[13]}</Text>
            </View>
            <View style={AtomicCss.flex1}>
                <Text style={[fsStyle.font12, fonts.regularFontFamily, AtomicCss.defaultTextColor]}>{ruleList[14]}</Text>
            </View>
          </View>}
            {!!ruleList[15] &&<View style={[AtomicCss.flexRow, styles.answerRow, styles.answerRowLast]}>
            <View style={[AtomicCss.flex1, styles.marginRight24]}>
                <Text style={[fsStyle.font12, fonts.blackFontFamily, AtomicCss.defaultTextColor, AtomicCss.marginBottom5]}>{ruleList[15]}</Text>
                <Text style={[fsStyle.font12, fonts.regularFontFamily, AtomicCss.lightGrey]}>{ruleList[16]}</Text>
            </View>
            <View style={AtomicCss.flex1}>
                <Text style={[fsStyle.font12, fonts.regularFontFamily, AtomicCss.defaultTextColor]}>{ruleList[17]}</Text>
            </View>
        </View>}
            {!!ruleList[18] &&<View style={[AtomicCss.flexRow, styles.answerRow, styles.answerRowLast]}>
            <View style={[AtomicCss.flex1, styles.marginRight24]}>
                <Text style={[fsStyle.font12, fonts.blackFontFamily, AtomicCss.defaultTextColor, AtomicCss.marginBottom5]}>{ruleList[18]}</Text>
                <Text style={[fsStyle.font12, fonts.regularFontFamily, AtomicCss.lightGrey]}>{ruleList[19]}</Text>
            </View>
            <View style={AtomicCss.flex1}>
                <Text style={[fsStyle.font12, fonts.regularFontFamily, AtomicCss.defaultTextColor]}>{ruleList[20]}</Text>
            </View>
        </View>}
        </View>
      );
    }
}

const styles = StyleSheet.create({
  CollapsedTapDtlsContainer1: {
    backgroundColor: 'transparent',
    paddingHorizontal: 20,
    borderTopWidth: 1,
    borderColor: '#e4e4e4',
    paddingVertical: 20
  },
  marginRight24:{marginRight: 24},
  answerRow: {borderBottomWidth: 1, borderColor: '#e5e5e5', paddingBottom:10, marginBottom: 10},
    answerRowBorder0:{borderBottomWidth: 0},
  answerRowLast:{borderBottomWidth: 0, marginBottom: 0, paddingBottom: 0}
});

export default FaqsAnswer;
