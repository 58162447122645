import React, { useState } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Image, Dimensions } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import defaultDriverPhoto from '@mmt/legacy-assets/src/Images/CabDetails/driverphoto.webp';
import BaseButton, { BUTTON_VARIANT } from 'apps/post-sales/src/Common/BaseButton';
import { whiteStar } from '../../../../CabImageConstants';
import { DriverAssignedCardProps } from '../../../../types';
import { isEmpty } from 'lodash';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import createStyles from './styles';
import noop from 'lodash/noop';
import CabBookingDetailsConstant from 'apps/post-sales/src/cab/CabBookingDetailsConstant';
import DriverProfile from './DriverProfile';

const DriverAssignedCard: React.FC<DriverAssignedCardProps> = ({
  driverInfo,
  cabDetailInfo,
  handleButtonClick = noop,
  handleBottomSheetOverlay,
  actionList,
  pageName,
}) => {
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);

  return (
    <>
      <View style={styles.cabDetailsWrapper}>
        <View style={AtomicCss.flex1}>
          <View style={[AtomicCss.flexRow, AtomicCss.flex1]}>
            <DriverProfile
              handleBottomSheetOverlay={handleBottomSheetOverlay}
              driverInfo={driverInfo}
              pageName={pageName}
            />
          </View>
        </View>
      </View>

      {!isEmpty(actionList) && (
        <View style={styles.btnWrapper}>
          {actionList.map((item, index) => {
            const isLastRow = index === actionList.length - 1;
            return (
              <View
                key={item.actionId}
                style={[styles.buttonView, !isLastRow && styles.marginRight5]}
              >
                <BaseButton
                  variant={
                    item.actionId.toLowerCase() ===
                    CabBookingDetailsConstant.callHelpLineId.toLowerCase()
                      ? BUTTON_VARIANT.OUTLINED
                      : BUTTON_VARIANT.CAPSULE
                  }
                  buttonStyle={styles.viewBreakupButtonStyle}
                  textStyle={styles.viewBreakupTextStyle}
                  text={item.actionLabeltext}
                  clickHandler={() => handleButtonClick(item)}
                />
              </View>
            );
          })}
        </View>
      )}
    </>
  );
};

export default DriverAssignedCard;
