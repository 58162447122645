import React, { useEffect, useRef, useState } from 'react';
import { View, Animated, ScrollView, Platform } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import BusBookingDetailsConstant from '../../BusBookingDetailsConstant';
import StepBar from '../../../Common/StepBar';
import Header from '@mmt/legacy-commons/Common/Components/Header/SimpleHeader';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import { getStaticData } from '../../../staticData/staticData';
import ProgressView from '@mmt/legacy-commons/Common/Components/Progress/ProgressView';
import { BUS_CANCEL_REBOOK } from '../../../utils/NetworkUtils';
import BusCancelRebookConstant from '../CancelRebookConstants';
import {  openWebView } from '../../../utils/PostSaleUtil';
import NoInternetView from '@mmt/legacy-commons/Common/Components/Error/NoInternetView';
import { getSelectedSeats } from '../CancelRebookUtil';
import useApi from '../../../Common/useApi';
import SimpleHeader from '@mmt/legacy-commons/Common/Components/Header/SimpleHeader';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import createStyles from '../CancelRebookSelectBusPage/selectBusCss';
import useHardwareBackpress from '../../../Common/useHardwareBackpress';
import ReviewTopSection from './Components/ReviewTopSection';
import PickAndDropDetails from './Components/PickAndDropDetails';
import TravellerCard from './Components/TravellerCard';
import PayableCard from './Components/PaymentInformation';
import BlackFooter from './Components/Footer';
import Actions from '../../../navigation/postSalesNavigation';
import {dataAdaptor} from "./dataAdaptor";
import { getFont } from '../../../PostSalesStyles';
import { useTheme } from '../../../theme/theme.context';
import { dateMonth, getFormattedDateFromMillis } from '@mmt/legacy-commons/Helpers/dateHelpers';
import PaymentModule from '@mmt/legacy-commons/Native/PaymentModule';
import { getRootTag } from '@mmt/legacy-commons/AppState/RootTagHolder';
import { LOBNAMES } from 'apps/post-sales/src/PostSalesConstant';
function CancelRebookReviewBus({ bookingID, reviewPageData,prevBooking }) {
  const { fsStyle, ...fonts } = getFont();
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);
  const [viewState, setViewState] = useState(ViewState.LOADING);
  const [busCancellRebookProgress, busCancelRebookReviewData, busCancelRebookReviewApi] = useApi(
    BUS_CANCEL_REBOOK + `/${bookingID}` + `/${BusCancelRebookConstant.CANCEL_REBOOK_REVIEW}`,
  );
  const [busCancellRebookHoldProgress, busCancelRebookHoldData, busCancelRebookHoldApi] = useApi(
    BUS_CANCEL_REBOOK + `/${bookingID}` + `/${BusCancelRebookConstant.CANCEL_REBOOK_COMMIT}`,
  );
  const { title, subTitle } = busCancelRebookReviewData
    ? getReviewHeader(busCancelRebookReviewData)
    : {};
  const widthAnimation = useRef(Array.from({ length: 3 }, (_) => new Animated.Value(36))).current;

  useEffect(() => {
    dataAdaptor(busCancelRebookReviewData, setViewState);
  }, [busCancelRebookReviewData]);

  useHardwareBackpress().addListener(goBack);

  useEffect(() => {
    fetchJSONAsync();
  }, []);

  useEffect(() => {
    dataAdaptor(busCancelRebookHoldData, setViewState, handleHoldResponse);
  }, [busCancelRebookHoldData]);

  const handleHoldResponse = () => {
    const {status,redirectionUrl, responseData}= busCancelRebookHoldData || {};
    const {mojoFDCHoldRequest: request, mojoFDCHoldResponse: response, mojoFDCHoldBookingSummary: bookingSummary} = responseData || {};
    const _rootTag = getRootTag();
     if(status === BusCancelRebookConstant.HOLD_SUCCESS){
       if(redirectionUrl){
        const paymentRequest = {
          lob: LOBNAMES.BUS, // DO NOT CHANGE THIS
          request,
          response,
          bookingSummary: Platform.select({
            android: JSON.stringify(bookingSummary),
            ios: bookingSummary,
          }),
          rootTag: _rootTag,
        };
        if (Platform.OS === 'ios') {
          PaymentModule.openPaymentPage(paymentRequest, _rootTag);
        } else if(Platform.OS === 'android'){
          PaymentModule.openPaymentPage(paymentRequest);
        }
       }
     }
     else if(status === BusCancelRebookConstant.MODIFIED_SUCCESS){
         Actions.cancelRebookThankYou({response:busCancelRebookHoldData})
     }
  };
  async function fetchJSONAsync() {
    setViewState(ViewState.LOADING);
    busCancelRebookReviewApi.httpPost({body : getRequestJson() });
  }

  function getRequestJson() {
    const { tripKey, selectedSeats, BpId: boardingPointId, DpId: dropPointId, bookingId } =
      reviewPageData || {};
    const reqJson = {
      tripKey: tripKey,
      boardingPointId: boardingPointId,
      dropPointId: dropPointId,
      selectedSeats: getSelectedSeats(selectedSeats),
      bookingId: bookingId,
    };
    return reqJson;
  }

  function goBack() {
    Actions.pop();
  }

  function getReviewHeader(data) {
    const { selectedBus } = data || {};
    const { from: fromCity, to: toCity, departureTime } = selectedBus || {};
    const fromToCity = fromCity + BusCancelRebookConstant.TO_TEXT + toCity;
    return {
      title: BusBookingDetailsConstant.CONFIRM_TRAVEL_DATE_HEADER,
      subTitle: {
        text: fromToCity + ' | ' + getFormattedDateFromMillis(departureTime,dateMonth),
        size: 11,
      },
    };
  }

  function renderDetail() {
    return (
      <>
        <ScrollView style={[styles.contentWrapper, AtomicCss.flex1]}  stickyHeaderIndices={[0]}>
            <View style={styles.topStickySection}>
              <Header
                title={title}
                subtitle={subTitle}
                headerShadow={true}
                iconPress={goBack}
                alignHeader={'flex-start'}
                textSize={14}
                textColor={'black'}
              />
            </View>
            <View style={[AtomicCss.makeRelative, styles.cardWrapper, AtomicCss.paddingHz15]}>
              <StepBar count={3} isActive={1} widthAnimation={widthAnimation} />
              <ReviewTopSection reviewData={busCancelRebookReviewData} />
              <PickAndDropDetails reviewData={busCancelRebookReviewData} />
              <TravellerCard reviewData={busCancelRebookReviewData} />
            </View>
            <PayableCard reviewData={busCancelRebookReviewData} />
        </ScrollView>
        <BlackFooter reviewData={busCancelRebookReviewData} handleClick={handleCommit} />
      </>
    );
  }

  async function handleCommit() {
    setViewState(ViewState.LOADING);
    return busCancelRebookHoldApi.httpPost({body : getRequestJson() });
  }
  function renderNoNetworkView() {
    return (
      <NoInternetView
        onRetry={() => {
          setViewState(ViewState.LOADING);
          fetchJSONAsync();
        }}
      />
    );
  }
  function renderErrorView() {
    const staticData = getStaticData();
    const { backText, errorText, tryAgainText } = staticData;
    return (
      <View style={AtomicCss.flex1}>
        <SimpleHeader title={backText} iconPress={goBack} />
        <ErrorView
          showRetry
          buttonLabel={tryAgainText}
          onRetry={() => {
            setViewState(ViewState.LOADING);
            fetchJSONAsync();
          }}
          message={BusCancelRebookConstant.DEFAULT_ERROR_MSG}
        />
      </View>
    );
  }

  function renderProgressView() {
    const progreeText = busCancellRebookHoldProgress ? BusCancelRebookConstant.HOLDING_YOUR_SEAT : BusCancelRebookConstant.LOADING;
    return <ProgressView message={progreeText} />;
  }

  return (
    <View style={AtomicCss.flex1}>
      {viewState === ViewState.NO_INTERNET && renderNoNetworkView()}
      {viewState === ViewState.LOADING && renderProgressView()}
      {viewState === ViewState.ERROR && renderErrorView()}
      {viewState === ViewState.SHOW_DETAIL && renderDetail()}
    </View>
  );
}
export default CancelRebookReviewBus;
