import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';

const styles = {
  CardContentFull: {
    backgroundColor: colors.white,
    paddingRight: 0,
    paddingBottom: 0,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
  },
  cardHeadingWrapper: {
    marginBottom: 2,
    paddingVertical: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#e4e4e4',
    backgroundColor: '#fff',
  },
  cardHeading: {
    color: '#000',
    textAlign: 'center',
  },
  fareBreakupInnerWrpper: {},
  fareBreakupInnerSection: {
    ...getPlatformElevation(1),
    marginBottom: 1,
    marginTop: -1,
    backgroundColor: '#fff',
  },
  fareBreakupInnerRowSection: {
    borderBottomWidth: 1,
    borderBottomColor: '#f2f2f2',
    paddingVertical: 15,
    paddingHorizontal: 16,
  },
  fareBreakupInnerRowSection1: {
    borderBottomWidth: 1,
    borderBottomColor: colors.grayBg,
    paddingVertical: 15,
    paddingHorizontal: 16,
    zIndex: 1
  },
  rowSection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  fareBkHeadingTxt: {
    color: '#000',
    maxWidth: 200,
  },
  successTxt: {
    color: '#1a7971',
  },
  lftTxt: {
    fontSize: 12,
    color: '#747474',
    lineHeight: 14,
  },
  payTxt: {
    fontSize: 12,
    color: '#cf8100',
    lineHeight: 14,
  },
  paySection: {
    backgroundColor: '#ffedd1',
    borderRadius: 4,
    marginHorizontal: 8,
    paddingHorizontal: 8,
  },
  totalPrice: {
    padding: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 2,
  },
  totalPricetxt: {
    color: '#4a4a4a',
  },
  btnWrapper: {
    paddingHorizontal: 40,
    paddingTop: 10,
    paddingBottom: 20,
  },
  cuponCode: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#6f6f6f',
    padding: 3,
    position: 'relative',
    top: -5,
    left: 10,
  },
  cuponCodeTxt: {
    fontSize: 12,
    color: '#747474',
  },
  borderBtm0: {
    borderBottomWidth: 0,
    borderBottomColor: 'transparent',
  },
  priceInfoWrapper: {
    borderRadius: 4,
    backgroundColor: '#bfe4e0',
    padding: 10,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 15,
  },
  tcsInfoWrapper: {
    borderRadius: 4,
    backgroundColor: colors.creamWhite,
    paddingVertical: 5,
    paddingHorizontal: 8,
    marginHorizontal: 15,
    marginBottom: 10,
    alignItems: 'center',
  },
  priceInfoTxt: {
    color: '#1a7971',
    fontSize: 12,
  }
};

export default styles;
