import { StyleSheet } from 'react-native';
const createStyles = (fonts, theme) =>
  StyleSheet.create({
    buttonStyle: { paddingHorizontal: 30, paddingVertical: 16 },
    darkFooterWrapper: {
      backgroundColor: theme.color.black28,
      paddingVertical: 8,
      paddingHorizontal: 10,
      justifyContent: 'space-between',
      flexDirection: 'row',
      alignItems: 'center',
    },
    footerTotalPrice: {
      fontSize: fonts.fontSize.font18,
      ...fonts.boldFontFamily,
      color: theme.color.white,
      marginRight: 10,
    },
    footerpriceDtls: {
      fontSize: fonts.fontSize.font10,
      color: theme.color.grey23,
      marginTop: 3,
      marginBottom: 2,
    },
    infoIcon: {
      width: 18,
      height: 19,
    },
    wtRightArrow: {
      width: 16,
      height: 12,
      marginLeft: 10,
    },
    offerWrapper: {
      borderRadius: 70,
      borderWidth: 1,
      borderColor: theme.color.lightGrey15,
      width: 'auto',
      flexDirection: 'row',
      alignItems: 'center',
      paddingHorizontal: 6,
    },
    infoIconArrow: {
      height: 10,
      width: 10,
      marginRight: 5,
    },
    iconWhiteArrow: {
      height: 16,
      width: 16,
      color: theme.color.lightGrey15,
    },
    offerText: {
      color: theme.color.lightGrey15,
      fontSize: fonts.fontSize.font10,
      ...fonts.regularFontFamily,
    },
  });

export default createStyles;
