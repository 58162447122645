import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({

  cabInfoWrapper: {
    backgroundColor: '#fff',
    elevation: 3,
    shadowColor: '#330000',
    shadowOpacity: 0.3,
    shadowRadius: 2,
    shadowOffset: {width: 0, height: 2}
  },
  cabInfoContent: {padding: 8, borderBottomWidth: 1, borderColor: '#f2f2f2'},
  carImg: {width: 75, height: 39},
  priceRow: {flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: 8},
  paidPrice: {
    paddingHorizontal: 16, paddingVertical: 16, borderBottomWidth: 1, borderColor: '#e4e4e4'
  },
  driverPay: {paddingHorizontal: 16, paddingVertical: 18},
  cabInfoSection: {
    backgroundColor: '#f6f6f6', padding: 16, borderRadius: 4, marginBottom: 16
  },
  impInfoRed: {
    color: '#e53442',
    fontSize: 14
  },
  strikeThrough: {
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid'
  },

  errorIcon: {width: 68, height: 60, marginBottom: 45},
  ErrorSection: {alignItems: 'center', marginBottom: 30, marginHorizontal: 50},
  errorWrapper: {
    flexDirection: 'column', justifyContent: 'center', flex: 1, backgroundColor: '#fff'
  },
  buttonWrpr: {width: '100%', paddingHorizontal: 45},

  flex1: {
    flex: 1
  },
  cabInfoWrapperDate: {
    backgroundColor: 'grey',
    elevation: 3,
    shadowColor: '#330000',
    shadowOpacity: 0.3,
    shadowRadius: 2,
    shadowOffset: {width: 0, height: 2}
  },
  cabInfoContentDate: {
    padding: 20,
    borderBottomWidth: 1,
    borderColor: '#f2f2f2',
    backgroundColor: 'white',
    justifyContent: 'center'
  },
  banner: {
    paddingVertical: 12,
    elevation: 1,
    backgroundColor: 'white',
    marginTop: 10,
    alignItems: 'center',
    justifyContent: 'center'
  },
  GreenBanner: {
    backgroundColor: '#c1f1dd', paddingHorizontal: 16, paddingVertical: 12, elevation: 1, marginBottom: 10
  },
  whiteContainer: {
    backgroundColor: '#ffffff',
    flex: 1
  },
  InfoStrip: {
    flexDirection: 'row',
    paddingBottom: 10,
    paddingTop: 10,
    paddingLeft: 15,
    paddingRight: 15,
    alignItems: 'center',
    elevation: 1,
    shadowColor: '#000',
    shadowOffset: {width: 0, height: 2},
    shadowOpacity: 0.3,
    shadowRadius: 2,
    backgroundColor: '#fff'
  },
  mar24Rt: {
    marginRight: 24
  },
  RowCount: {
    backgroundColor: '#9b9b9b',
    width: 14,
    height: 14,
    borderRadius: 50,
    marginRight: 4,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center'
  },
  RowCountActive: {backgroundColor: '#000'},
  RowCountInActive: {backgroundColor: '#9b9b9b'},
  RowCountText: {
    color: '#fff',
    fontSize: 11
  },

  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fff',
    color: '#4a4a4a',
    paddingHorizontal: 16,
    paddingVertical: 10,
    height: 60,
    shadowColor: '#330000',
    shadowOpacity: 0.3,
    shadowRadius: 2,
    shadowOffset: {
      width: 0,
      height: 2
    },
    elevation: 4,
    zIndex: 2
  },
  backWrapper: {
    paddingRight: 15
  },
  title: {
    color: '#000000', fontFamily: 'Lato-Bold', letterSpacing: 0.3, fontSize: 16
  },
  iconBack: {
    height: 16, width: 16, paddingHorizontal: 5, paddingVertical: 5
  },
  rightIcon: {
    width: 9, height: 6, marginTop: 3, marginHorizontal: 3
  },
  fareBreakupInnerRowSection: {
    paddingTop: 4,
    borderBottomWidth: 1,
    borderBottomColor: '#f2f2f2'
  },
  rowSection: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  successTxt: {
    color: '#1a7971',
    fontFamily: 'Lato-Bold',
    fontSize: 14
  },
  cuponCode: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#6f6f6f',
    padding: 3,
    position: 'relative',
    top: -5,
    left: 10
  }
});
