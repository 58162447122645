import React from 'react';
import {View, Text, Image, TouchableOpacity} from 'react-native';
import PropTypes from 'prop-types';
import {isNotNullAndEmpty, isIntlHotelBooking} from '../utils/HotelBookingDetailUtil';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getStyles from '../../styles/ExtraChargesCss';
import HotelBookingTrackingHelper from '../../HotelBookingTrackingHelper';
import { RUPEE_ICON, INFO_ICON, PAYMENT_CARD_ICON } from '../../HotelImageConstants';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { getUserSelectedLanguage } from 'packages/legacy-commons/AppState/AppUserConfig';
import { LANGUAGE_MAP } from 'packages/legacy-commons/Common/constants/AppConstants';

class ExtraChargesCard extends React.Component {

    constructor(props) {
        super(props);
        this.fonts = getFont(true);
        this.styles = getStyles(this.fonts);
        this.fsStyle = this.fonts.fsStyle;
    }

    componentDidMount() {
        const trackPageName = isIntlHotelBooking(this.props.response?.lobCode)
        ? 'Mob:customer support:Hotel International:ExtraChargesMoreInfo'
        : 'Mob:customer support:Hotel Domestic:ExtraChargesMoreInfo';
        HotelBookingTrackingHelper.trackLoadEvent(trackPageName, this.props.response);
    }

    getPriceIcon = () => {
        let priceIcon = RUPEE_ICON;
        const selectedLang = getUserSelectedLanguage()
        if(selectedLang === LANGUAGE_MAP.ara){
            priceIcon = PAYMENT_CARD_ICON
        }
        return priceIcon
    }

    render() {
        const {extraCharges} = this.props.response;
        const extraChargesDetails = extraCharges && extraCharges.extraChargesDetails;
        const infoImage = INFO_ICON;

        if(extraCharges && extraCharges.heading && extraCharges.extraChargesDetails) {
            return (
                <React.Fragment>
                    <View style={[this.styles.payDtlsCard, AtomicCss.marginBottom12, AtomicCss.marginTop8]}>
                        <View style={[this.styles.infoCardHading, AtomicCss.marginBottom5]}>
                            <Image style={this.styles.priceIconStyle} source={this.getPriceIcon()} />
                            <View style={[AtomicCss.marginLeft8]}>
                                {extraCharges.heading && <View>
                                    <Text style={[this.styles.headingTxt]}>{extraCharges.heading.header}</Text>
                                    <Text style={[this.styles.desc]}>{extraCharges.heading.subHeader}</Text>
                                </View>}
                            </View>
                        </View>
                        {extraChargesDetails && extraChargesDetails.length > 0 && extraChargesDetails.map((extraCharge, index) => (
                            <View style={[this.styles.rowSection, index === extraChargesDetails.length - 1 ? this.styles.lastRow : undefined]}>
                                <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween, AtomicCss.marginBottom3]}>
                                    <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
                                        <Text style={[AtomicCss.blackText, this.fsStyle.font14, AtomicCss.lineHeight20, extraCharge.isChargeTypeTotal ? this.fonts.blackFontFamily : this.fonts.boldFontFamily]}>{extraCharge.name}</Text>
                                        {extraCharge.data &&
                                            <TouchableOpacity onPress={() => this.props.handleBottomOverlay(extraCharge.data)}>
                                                <Image style={this.styles.infoIcon} source={infoImage} />
                                            </TouchableOpacity>
                                        }
                                    </View>
                                    <Text style={[AtomicCss.blackText, this.fsStyle.font14, AtomicCss.lineHeight20, extraCharge.isChargeTypeTotal ? this.fonts.blackFontFamily : this.fonts.boldFontFamily]}>{`${isNotNullAndEmpty(extraCharge.priceOther) ? extraCharge.priceOther : extraCharge.priceLcy} `}</Text>
                                </View>
                                {extraCharge.priceOther && extraCharge.priceLcy && <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween]}>
                                    <Text style={[AtomicCss.defaultText, this.fonts.regularFontFamily, this.fsStyle.font12]}>{extraCharge.information}</Text>
                                    {isNotNullAndEmpty(extraCharge.priceOther) && <Text style={[AtomicCss.defaultText, this.fonts.regularFontFamily, this.fsStyle.font12]}>({`${extraCharge.priceLcy} `})</Text>}
                                </View>}
                            </View>
                        ))}
                    </View>
                </React.Fragment>
            );
        }
        return null;
    }
}

ExtraChargesCard.propTypes = {
    response: PropTypes.object.isRequired,
};

export default ExtraChargesCard;
