import { StyleSheet } from "react-native";

export default (theme, fonts) => StyleSheet.create({
  footer: {
    backgroundColor: 'rgba(0,0,0,0.9)',
    alignSelf: 'flex-end',
    justifyContent: 'space-between',
    flexDirection: 'row',
    position: 'absolute',
    width: '100%',
    bottom: 0,
    left: 0,
    zIndex: 2,
  },
  sortItem: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    padding: 10,
    borderRightColor: theme.color.lightGrey1,
    borderRightWidth: 1,
  },
  iconWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  whiteText: {
    color: theme.color.white,
  },
  sortIcon: {
    width: 24,
    height: 24,
  },
  sortLabel: {
    color: theme.color.grey31,
    fontSize: fonts.fontSize.font18,
    ...fonts.mediumFontFamily,
    textAlign: 'center',
  },
});

