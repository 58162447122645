//Postsales
import {getImagePath} from '@mmt/legacy-commons/Common/utils/postSalesCommonUtils';

export const REFUND_ICON =  getImagePath('refundIcon.webp');
export const CANCEL_ICON =  getImagePath('ic_cancelled.webp');
export const CROSS_ICON =  getImagePath('crossIcon1.webp')
export const CANCELLED_ICON =  getImagePath('ic_cancellation_policy.webp');
export const LIST_IMG =   getImagePath('listImg.webp');
export const LOCK_IMG =  getImagePath('lockIcon.webp')
export const INFO_ICON_1 =  getImagePath('info-icon1.webp');
export const PROFILE_VERI_ICON =  getImagePath('profileVeriIcon.webp');
export const RUPEE_ICON =  getImagePath('ic_claim_refund.webp');
export const INFO_ICON =  getImagePath('info-icon2.webp');
export const TIME_INFO_ICON =  getImagePath('timeInfoIcon.webp');
export const GOOGLE_MAP_ICON =  getImagePath('googlemap.webp');
export const FUEL_ICON =  getImagePath('fuelIcon.webp');
export const KM_ICON =  getImagePath('kmIcon.webp');
export const DL_ICON =  getImagePath('dlIcon.webp');
export const CANCELLED_ICON_1 =  getImagePath('calcelledIcon.webp');
export const DELAY_ICON =  getImagePath('delayIcon.webp');
export const JOURNEY_OVER_IMAGE =  getImagePath('journeyover.webp');
export const ALARM_IMAGE =  getImagePath('alarmOn.webp');
export const REFUNDICON_GROUP_115 =  getImagePath('group_115.webp');
export const SWIPE_CARD =  getImagePath('ic_payIcon_date_change.webp')
export const GUEST_ICON =  getImagePath('guest_icon.webp');
export const TIME_ICON =  getImagePath('timeIcon.webp');
export const ANDROID_ICON = getImagePath('android.png') ;
export const IOS_ICON = getImagePath('app.png') ;
export const MMT_LOGO = getImagePath('mmt_logo.webp') ;


//common
export const HEAD = require('@mmt/legacy-assets/src/trip_header_back_icon.webp')
export const HEADER_ICON = require('@mmt/legacy-assets/src/trip_header_back_icon.webp');
export const BLUE_DROP_ICON_DOWN = require('@mmt/legacy-assets/src/blueDropIcondown.webp');
export const BLUE_DROP_ICON_UP = require('@mmt/legacy-assets/src/blueDropIconUp.webp');
export const DRIVER_ICON = require('@mmt/legacy-assets/src/Images/CabDetails/driver.webp');
export const EXCLUSION_ICON =  require('@mmt/legacy-assets/src/exclusion.webp');
export const EXTRA_CHARGE_ICON = require('@mmt/legacy-assets/src/my-cash.webp');
export const GREEN_TICK = require('@mmt/legacy-assets/src/green.webp');
export const CAR_IMG = require('@mmt/legacy-assets/src/car.webp');
export const LOCATION_IMG = require('@mmt/legacy-assets/src/Images/location.webp');
export const RIGHT_ARROW = require('@mmt/legacy-assets/src/ic_arrow.webp');
// TODO:Fix this
export  const LOCATION_ICON = require('@mmt/legacy-assets/src/addon_location.webp');
export const REDIRECTION_ICON = require('@mmt/legacy-assets/src/redirect_blue.webp');
export const CALL = require('@mmt/legacy-assets/src/call-icon2.webp');
export const CANCELLED_IMAGE = require('@mmt/legacy-assets/src/ic_cancelled.webp');
export const FAILED_IMAGE = getImagePath('ic_failed.webp');
export const CONFIRMED_IMAGE = require('@mmt/legacy-assets/src/ic_confirmed.webp');
export const ANDROID_BACK_ARROW = require('@mmt/legacy-assets/src/backArrowBlack.webp');
export const IOS_BACK_ARROW = require('@mmt/legacy-assets/src/backArrowBlackIOS.webp');
export const DISOUNT_ICON = require('@mmt/legacy-assets/src/discount.webp');
