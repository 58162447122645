import React from 'react';
import PropTypes from 'prop-types';
import {Image, Text, View, StyleSheet} from 'react-native';
import {isEmpty} from 'lodash';
import AtomicCss from '../AtomicCss';
import {colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import AcmeBookingDetailsConstant from "../../utils/AcmeBookingDetailsConstant";
import BlackPriceBreakup from '../../../Common/BlackPriceBreakup';
import {paymentIcon} from '../../utils/AcmeImageConstant';


class AcmePaymentCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      responseJson: this.props.response
    };
  }

  render() {
    const { customerCurrency } = this.state.responseJson;
    return (
      <View style={[styles.MamiCard, AtomicCss.marginBottom10]}>
        <View style={styles.CardContent}>
          <Image
            style={styles.CardIcon}
            source={paymentIcon}
          />
          <View style={[styles.ContentPortion, {paddingRight: 16}]}>
            <View>
              <View style={[styles.Paymentrow, AtomicCss.marginBottom15]}>
                <View style={styles.PaymentLeft}>
                  <Text style={[styles.BlackText, styles.blackFont]}>TOTAL PRICE</Text>
                </View>
                <View style={{flex: 0.3}}>
                  <Text
                      style={[styles.BlackText, styles.blackFont, styles.AlignRight]}
                  >{customerCurrency} {this.state.responseJson.bookingPaymentDetail.totalPrice}{' '}
                  </Text>
                </View>
              </View>

              <View style={[styles.Paymentrow, AtomicCss.marginBottom15]}>
                <View style={styles.PaymentLeft}>
                  <Text style={styles.BlackText}>Amount Paid</Text>
                </View>
                <Text
                    style={styles.BlackText}
                >{customerCurrency} {this.state.responseJson.bookingPaymentDetail.amountPaid}{' '}
                </Text>
              </View>
              <View>
                <View
                    style={[styles.Paymentrow, AtomicCss.marginBottom15]}
                >
                  <View style={styles.PaymentLeft}>
                    <Text>- Paid by Card </Text>
                  </View>
                  <Text> {customerCurrency} {this.state.responseJson.bookingPaymentDetail.amountPaidByCard}{' '}
                  </Text>
                </View>
                {this.getPaidByWalletView(customerCurrency)}
                {this.state.responseJson.bookingPaymentDetail.ecouponAmount > 0 &&
                <View
                  style={[styles.Paymentrow, AtomicCss.marginBottom15]}
                >
                  <View style={styles.PaymentLeft}>
                    <Text>- ECoupon
                    ({this.state.responseJson.bookingPaymentDetail.ecouponCode})
                    </Text>
                  </View>
                  <Text> - {customerCurrency} {this.state.responseJson.bookingPaymentDetail.ecouponAmount}{' '}
                  </Text>
                </View>
              }
              {this.state.responseJson.loyaltyDetails && this.state.responseJson.loyaltyDetails.tierMetaInfo && this.state.responseJson.priceBreakupBlack &&  this.state.responseJson.priceBreakupBlack.amount > 0 && <View style={[styles.Paymentrow, AtomicCss.marginBottom15]}>
                <BlackPriceBreakup
                  priceBreakupBlackText={this.state.responseJson.priceBreakupBlack.name}
                  priceBreakupBlackValue={this.state.responseJson.priceBreakupBlack.value}
                  tierMetaInfo={this.state.responseJson.loyaltyDetails.tierMetaInfo}
                  tierType={this.state.responseJson.loyaltyDetails.tierType}
                  />
              </View>
              }
              </View>
            </View>
          </View>
        </View>
        {this.showCashbackCard() &&
        <View>
          <View style={[styles.CardContent, AtomicCss.flexRow, AtomicCss.marginTop5]}>
            <View style={styles.CardIcon} />
            <View>
              <View style={[AtomicCss.flexRow]}>
                <Text style={[styles.discountText, AtomicCss.boldFont]}>Discount Coupon</Text>
                <Text
                  style={[styles.couponTxt, AtomicCss.regularFont]}
                >{this.state.responseJson.bookingPaymentDetail.cashBackCouponCode}
                </Text>
              </View>
              <Text
                style={[styles.couponDiscription, AtomicCss.regularFont]}
              >{this.state.responseJson.bookingPaymentDetail.cashBackMessage}
              </Text>
            </View>
          </View>
        </View>
      }
      </View>);
  }

  getPaidByWalletView(customerCurrency) {
    //Hide if currency is not INR
    if (customerCurrency && AcmeBookingDetailsConstant.INDIAN_CURRENCIES.includes(customerCurrency.trim()))
    return <View
        style={[styles.Paymentrow, AtomicCss.marginBottom15]}
    >
      <View style={styles.PaymentLeft}>
        <Text>- Paid by Wallet </Text>
      </View>
      <Text> {customerCurrency} {this.state.responseJson.bookingPaymentDetail.amountPaidByWallet}{' '}
      </Text>
    </View>;
  }

  showCashbackCard() {
    return !isEmpty(this.state.responseJson.bookingPaymentDetail.cashBackMessage)
    && (this.state.responseJson.bookingState.state === 'UPCOMING' || this.state.responseJson.bookingState.state === 'COMPLETED');
  }
}

const styles = StyleSheet.create({
  MamiCard: {
    backgroundColor: '#fff'
  },
  ContentPortion: {
    flex: 1
  },
  PaymentLeft: {
    flex: 0.7
  },
  CardContent: {
    backgroundColor: '#fff',
    borderBottomWidth: 1,
    borderBottomColor: '#e4e4e4',
    flexDirection: 'row',
    padding: 15,
    elevation: 1
  },
  Paymentrow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  CardIcon: {
    width: 24,
    height: 24,
    marginRight: 15
  },
  marginLeftAuto: {
    marginLeft: 'auto'
  },
  contentTxt: {
    color: '#000',
    fontSize: 12,
    marginBottom: 10
  },
  breakupText: {
    fontSize: 12,
    color: '#000',
    textAlign: 'right'
  },
  width200: {
    width: 200
  },
  discountText: {
    marginRight: 10,
    color: '#1a7971',
    fontSize: 14
  },
  couponTxt: {
    fontSize: 12,
    color: '#747474',
    borderWidth: 1,
    borderColor: '#6f6f6f',
    borderRadius: 5,
    marginTop: 0,
    marginBottom: 10,
    padding: 2
  },
  couponTxtRight: {
    fontSize: 14,
    color: '#1a7971'
  },
  couponDiscription: {
    fontSize: 12,
    color: '#4a4a4a'
  },
  BlackText: {
    color: colors.black
  },
  blackFont: {
    fontFamily: fonts.black
  },
  AlignRight: {
    textAlign: 'right'
  }
});
AcmePaymentCard.propTypes = {
  response: PropTypes.object.isRequired
};

export default AcmePaymentCard;
