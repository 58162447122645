import React from 'react';
import { View, ScrollView, TouchableOpacity, Text, Image } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import Actions from '../../../../navigation/postSalesNavigation';
import AtomicCss from '../../../commonStyles/AtomicCss';
import IssueList from './IssueList';
import {colors} from '@mmt/legacy-commons/Styles/globalStyles';
import StickyHeader from '../StickyHeader';
import getStyles from '../../../styles/BusMamiCss';
import {rightArrow2, headerIcon} from '../../../BusImageConstants';
import { label } from '@mmt/post-sales/src/staticData/staticData';
import BusBookingTrackingHelper from './../../../BusBookingTrackingHelper';
import { PageName } from './../../../BusBookingDetailsConstant';

class IssueSelection extends React.Component {
  constructor(props) {
    super(props);
    this.pageName = 'Issue Select';
    this.pageTitle = label('bus.issue.pageTitle');
    this.state = {
      issueListObj: [],
      proceedDisabled: true,
      attachment: false
    }
  }

  onClickEventTracker = (omnitureClickEvent) => {
    BusBookingTrackingHelper.trackClickEvent(
      PageName.TRIP_DETAILS,
      undefined,
      omnitureClickEvent,
    );
  };

  pageSubTitle = () => {
    const styles = getStyles();
    return <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
      <Text style={[AtomicCss.boldFont, AtomicCss.font14, AtomicCss.defaultText]}>{this.props.response.busBookingInfo.busDetail.fromCity}</Text>
            <Image style={styles.rtArrowStyle} source={rightArrow2} />
      <Text style={[AtomicCss.boldFont, AtomicCss.font14, AtomicCss.defaultText]}>{this.props.response.busBookingInfo.busDetail.toCity}</Text>
      <Text style={[AtomicCss.boldFont, AtomicCss.font14, AtomicCss.defaultText]}> | {this.props.response.busAdditionalInfo.departureDetail.departureDayInMonth} {this.props.response.busAdditionalInfo.departureDetail.departureMonth}</Text>
    </View>
  }

  goBack = () => {
    Actions.pop();
  };

  disableProceed = () => {
    this.setState({proceedDisabled : true})
  }

  updateIssueList = (issueListObj) => {
    let proceedDisabled = true;
    if(issueListObj && issueListObj.length > 0) {
      proceedDisabled=false
    }
    let attachment = this.isAttachmentNeeded(issueListObj);
    this.setState({ issueListObj, proceedDisabled, attachment })
  }

  isAttachmentNeeded = (issueListObj) => {
    for (let issue of issueListObj) {
      let subIssue = issue.subIssue;
      if (subIssue.attachment) {
        return true;
      }
    }
    return false;
  }

  proceed = () => {
    this.onClickEventTracker('BusserviceIssue_Proceed1')
    let response = this.props.response;
    let issueList = this.state.issueListObj;
    let attachment = this.state.attachment;

    Actions.busSupportIssueDetail({
      response, issueList, attachment
    });
  }

  getIssueBreadCumb = () => {
    const styles = getStyles();
    let steps = this.props.response.busSupportData.scSteps;
    return (<View style={styles.breadCumbOuter}>
      {
        steps && steps.map((step, index) => (
          <Text style={index == 0 ? styles.activeBreadCumb : styles.nonActiveBreadcumb}>{index + 1}  {step}</Text>
        ))
      }
    </View >);
  }

  render() {
    const styles = getStyles();
    return (
      <View style={[AtomicCss.flex1, styles.bodybg]}>
        <StickyHeader
          iconSrc={headerIcon}
          title={this.pageTitle}
          headerColor={['#ffffff', '#ffffff']}
          titleStyle={[styles.DefaultText, styles.blackFont]}
          subTitle={this.pageSubTitle}
          subTitleStyle={[styles.DefaultText, styles.regularFont, styles.font12]}
          onBackPress={this.goBack}
        />
        <ScrollView>
          {this.getIssueBreadCumb()}
          <IssueList busSupportData={this.props.response.busSupportData}
            msrTicketList={this.props.response.msrTicketsForBooking.ticketsList}
            updateIssueList={this.updateIssueList}
            issueListObj={this.state.issueListObj}
            proceedDisabled={this.disableProceed}
            onClickEventTracker={this.onClickEventTracker}
          />
        </ScrollView>


        <TouchableOpacity style={AtomicCss.btn} activeOpacity={0.7} onPress={this.proceed} disabled={this.state.proceedDisabled}>
          <LinearGradient
            colors={this.state.proceedDisabled ? [colors.disabledBtnBg, colors.disabledBtnBg] : [colors.lightBlue, colors.darkBlue]}
            start={{ x: 0, y: 0 }}
            end={{ x: 1.0, y: 0 }}
            style={AtomicCss.btnGradient}
          >
            <Text style={[AtomicCss.font12, AtomicCss.whiteText, AtomicCss.boldFont]}>{this.props.response.busSupportData.proceedButton}</Text>
          </LinearGradient>
        </TouchableOpacity>
      </View>
    );
  }
}

export default IssueSelection;
