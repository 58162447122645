import React from 'react';
import {StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import AtomicCss from '../../Rails/commonStyles/AtomicCss';
import {colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import { getStaticData } from '../../staticData/staticData';

class BottomOverlay extends React.Component { 

    render() {
        const staticData = getStaticData();
        const { pnrStatusUnavailableText, closeText } = staticData;
        return (
            <View style={styles.overlayContainer}>
                <View style={styles.overlayContent}>
                    <View style={[styles.marginBottom40]}>
                        <Text style={[styles.overlayHead, styles.marginBottom26]}>{pnrStatusUnavailableText}</Text>
                        <Text style={styles.tdrText}>
                            {this.props.irctcDownMessage}
                        </Text>
                    </View>
                    <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.spaceBetween]}>
                        <TouchableOpacity onPress={() => this.props.closeOverLay()}>
                            <Text style={styles.actionBlueText}>{closeText}</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>

        );
    }
}

const styles = StyleSheet.create({
    overlayContainer:
        {
            justifyContent: 'flex-end',
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            zIndex: 1000,
            elevation: 3
        },
    overlayBg: {
        backgroundColor: 'rgba(0,0,0,.5)',
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        zIndex: 1
    },
    tdrText: {
        fontSize: 16,
        color: colors.black,
        fontFamily: fonts.regular,
        lineHeight: 22
    },
    overlayContent: {
        backgroundColor: '#fff',
        paddingTop: 26,
        paddingBottom: 30,
        paddingHorizontal: 20,
        position: 'relative',
        zIndex: 100,
        justifyContent: 'flex-end'
    },
    marginBottom26: { marginBottom: 26 },
    overlayHead: { fontSize: 28, color: '#000', fontFamily: fonts.regular },
    actionBlueText: { color: '#008cff', fontSize: 16, fontFamily: fonts.bold },
    marginBottom40: { marginBottom: 40 }
});
export default BottomOverlay;
