import { Dimensions, Platform, StyleSheet } from 'react-native';

const windowHeight =
  Platform.OS === 'ios' ? Dimensions.get('screen').height : Dimensions.get('window').height;
const windowWidth =
  Platform.OS === 'ios' ? Dimensions.get('screen').width : Dimensions.get('window').width;

  export default (theme, fonts) => StyleSheet.create({
  ResheuduleTitleMain: {
    borderBottomColor: theme.color.lightGrey,
    borderBottomWidth: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 15,
    marginHorizontal: -20,
    paddingHorizontal: 20,
  },
  ResheuduleTitle: {
    fontSize: fonts.fontSize.font21,
    color: theme.color.defaultTextColor,
    ...fonts.lightFontFamily,
  },
  CloseImg: {
    width: 14,
    height: 14,
    marginTop: 2,
  },
  OverlayDtlsContentMain: {
    borderBottomColor: theme.color.lightGrey,
    borderBottomWidth: 1,
    paddingVertical: 8,
  },
  OverlayDtls: {
    backgroundColor: theme.color.white,
    marginHorizontal: -20,
  },
  OverlayDtlsContent: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
  },
  HeadingTxt: {
    color: theme.color.defaultTextColor,
    ...fonts.boldFontFamily,
    fontSize: fonts.fontSize.font14,
    marginBottom: 8,
  },
  OverlayInnerTxt: {
    color: theme.color.defaultTextColor,
    ...fonts.regularFontFamily,
    fontSize: fonts.fontSize.font12,
    marginBottom: 5,
    paddingLeft: 5,
  },
  slashedAmountTxt: {
    color: theme.color.defaultTextColor,
    ...fonts.regularFontFamily,
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
    fontSize: fonts.fontSize.font12,
    marginBottom: 5,
    paddingLeft: 5,
  },
  CancelledTxtPrice: {
    color: theme.color.lightYellow3,
  },
  TotalAmountWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
    paddingTop: 10,
    paddingLeft:20,
  },

  TotalAmountTxt: {
    fontSize: fonts.fontSize.font18,
    ...fonts.blackFontFamily,
    color: theme.color.defaultTextColor,
  },
  TotalAmountTxtHeading:{
    fontSize: fonts.fontSize.font18,
    ...fonts.blackFontFamily,
    color: theme.color.defaultTextColor,
    maxWidth : '70%'
    
  },
  TotalAmountTxtHeading:{
    fontSize: fonts.fontSize.font18,
    ...fonts.blackFontFamily,
    color: theme.color.defaultTextColor,
    maxWidth : '70%',
    
  },
  FareCancellationOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: windowHeight,
    width: windowWidth,
    elevation: 10,
    zIndex: 100,
    backgroundColor: theme.color.grey22,
    display: 'flex',
  },
  ErrorStateWrapper: {
    paddingVertical: 15,
    paddingHorizontal: 14,
    flexDirection: 'row',
  },
  ResheduleBtnWrapper: {
    paddingVertical: 15,
    paddingHorizontal: 20,
  },
  ProceedCancWrapper: {
    borderTopColor: theme.color.lightGrey,
    borderTopWidth: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 10,
  },
  btmOverlayTxt: {
    fontSize: fonts.fontSize.font12,
    color: theme.color.greyLight,
    ...fonts.regularFontFamily,
  },
  ProceedCanTxt: {
    color: theme.color.azure,
    ...fonts.boldFontFamily,
    marginTop: 5,
    ...fonts.fsStyle.font17,
  },
  additionalInfoWrapper: {
    paddingHorizontal: 20,
    paddingBottom: 15,
    borderTopWidth: 1,
    borderTopColor: theme.color.lightGrey,
  },
  additionalInfoContainer: {
    paddingTop: 15,
  },
  additionalInfoTxt: {
    color: theme.color.defaultTextColor,
    ...fonts.regularFontFamily,
    fontSize: fonts.fontSize.font13,
    marginBottom: 5,
  },
  headerContainer: {
    borderBottomColor: theme.color.lightGrey,
    borderBottomWidth: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 15,
    marginHorizontal: -15,
    paddingHorizontal: 20,
  },
  headingIconStyle: {
    width: 19,
    height: 20,
  },
  headingText: {
    paddingHorizontal: 7,
    color: theme.color.green,
    fontWeight: '500',
    fontSize: fonts.fontSize.font14,
  },
  amountContainer: {
    flexDirection: 'row',
  },
});

