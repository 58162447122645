import {colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from '@mmt/legacy-commons/Common/Components/Card/getPlatformElevation';

const styles = {

  CardContentFull: {
    backgroundColor: colors.white,
    paddingRight: 0,
    paddingBottom: 0
  },
  cardHeadingWrapper: {
    marginBottom: 2,
    paddingVertical: 10,
    ...getPlatformElevation(2),
    backgroundColor: '#fff'
  },
  cardHeading: {
    color: '#000',
    textAlign: 'center',
    fontSize: 20,
    fontFamily: fonts.black

  },

  cardInnerSection: {
    ...getPlatformElevation(1),
    marginBottom: 1,
    backgroundColor: '#fff',
    padding: 16

  },
  rowSection: {
    padding: 16,
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderBottomColor: '#f2f2f2'
  },
  InfoWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderRadius: 4,
    backgroundColor: '#f2f2f2',
    padding: 10
  },

  infoIconStyle: {
    tintColor: '#9b9b9b',
    width: 20,
    height: 20,
    marginRight: 10,
    top: 3
  },
  refundBtnWrapper: {
    padding: 16,
    marginBottom: 2,
    backgroundColor: '#fff'

  },
  greenTxt: {
    color: '#1a7971'
  },
  orangeTxt: {
    color: '#cf8100'
  },
  buttonWrapper: {
    paddingHorizontal: 30,
    paddingVertical: 10
  }
};

export default styles;
