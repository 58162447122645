import {colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from '@mmt/legacy-commons/Common/Components/Card/getPlatformElevation';

const styles = {
  flex: {display: 'flex'},
  flexRow: {flexDirection: 'row'},
  flexColumn: {flexDirection: 'column'},
  flex1: {flex: 1},
  elevation1: {elevation: 1},
  elevation2: {elevation: 2},
  elevation3: {elevation: 3},
  makeRelative: {position: 'relative'},
  alignCenter: {alignItems: 'center'},
  textCenter: {textAlign: 'center'},
  alignRight: {textAlign: 'right'},
  alignLeft: {textAlign: 'left'},
  alignSelfCenter: {alignSelf: 'center'},
  lineThrought: {textDecorationLine: 'line-through'},
  spaceBetween: {justifyContent: 'space-between'},
  justifyStart: {justifyContent: 'flex-start'},
  justifyEnd: {justifyContent: 'flex-end'},
  justifyCenter: {justifyContent: 'center'},
  whiteBg: {backgroundColor: colors.white},
  greyBg: {backgroundColor: '#f2f2f2'},
  lineHeight18: {lineHeight: 18},
  lineHeight20: {lineHeight: 20},
  marginTop10: {marginTop: 10},
  marginTop5: {marginTop: 5},
  marginTop15: {marginTop: 15},
  marginBottom0: {marginBottom: 0},
  marginBottom3: {marginBottom: 3},
  marginBottom5: {marginBottom: 5},
  marginBottom6: {marginBottom: 6},
  marginBottom8: {marginBottom: 8},
  marginBottom10: {marginBottom: 10},
  overflow: {overflow: 'hidden'},
  marginBottom12: {marginBottom: 12},
  marginBottom14: {marginBottom: 14},
  marginBottom15: {marginBottom: 15},
  marginBottom16: {marginBottom: 16},
  marginBottom18: {marginBottom: 18},
  marginBottom20: {marginBottom: 20},
  marginBottom24: {marginBottom: 24},
  marginBottom26: {marginBottom: 26},
  marginLeft12: {marginLeft: 12},
  marginRight5: {marginRight: 5},
  marginRight10: {marginRight: 10},
  marginRight15: {marginRight: 15},
  marginRight16: {marginRight: 16},
  marginRight18: {marginRight: 18},
  paddingTop3: {paddingTop: 3},
  paddingTop10: {paddingTop: 10},
  paddingAll20: {padding: 20},
  paddingLeft5: {paddingLeft: 5},
  paddingLeft20: {paddingLeft: 20},
  paddingLeft25: {paddingLeft: 25},
  paddingLeft30: {paddingLeft: 30},
  paddingRight16: {paddingRight: 16},
  paddingRight25: {paddingRight: 25},
  paddingBottom8: {paddingBottom: 8},
  paddingBottom12: {paddingBottom: 12},
  paddingBottom15: {paddingBottom: 15},
  paddingBottom20: {paddingBottom: 20},
  paddingBottom24: {paddingBottom: 24},
  whiteCard: {
    backgroundColor: '#ffffff',
    padding: 15,
    marginVertical: 8,
    ...getPlatformElevation(2),
    borderBottomWidth: 1,
    borderBottomColor: colors.lightGrey
  },
  boldFont: {fontFamily: fonts.bold},
  blackFont: {fontFamily: fonts.black},
  regularFont: {fontFamily: fonts.regular},
  lightFont: {fontFamily: fonts.light},
  font10: {fontSize: 10},
  font11: {fontSize: 11},
  font12: {fontSize: 12},
  font13: {fontSize: 13},
  font14: {fontSize: 14},
  font15: {fontSize: 15},
  font16: {fontSize: 16},
  font17: {fontSize: 17},
  font18: {fontSize: 18},
  font20: {fontSize: 20},
  blackText: {color: colors.black},
  whiteText: {color: colors.white},
  bgTransparent: {backgroundColor: colors.transparent},
  TextTransparent: {color: colors.transparent},
  lightGreyText: {color: colors.lightGrey},
  greyText: {color: colors.grey},
  defaultText: {color: colors.defaultTextColor},
  darkGreyText: {color: colors.darkGrey},
  redText: {color: colors.red},
  errorRedText: {color: colors.errorRed},
  linkText: {color: colors.linkBlue},
  azure: {color: colors.azure},
  midGreyText: {color: '#747474'}
};

export default styles;
