import React from 'react';
import {View, Text, StyleSheet, TouchableOpacity, ScrollView} from 'react-native';
import PropTypes from 'prop-types';

import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';
import {fonts, colors} from '@mmt/legacy-commons/Styles/globalStyles';

export default function WhyDocumentsOverlayContent(props) {
  const {documentPageDetails} = props;
  if (!documentPageDetails) {
    return null;
  }
  if (!Object.keys(documentPageDetails).length) {
    return null;
  }
  const {bottomSheet} = documentPageDetails;
  if (!bottomSheet) {
    return null;
  }
  if (!Object.keys(bottomSheet).length) {
    return null;
  }
  const {DOCREQUIRED} = bottomSheet;
  if (!DOCREQUIRED) {
    return null;
  }
  if (!Object.keys(DOCREQUIRED).length) {
    return null;
  }
  const {heading, docRequiredReason, buttons} = DOCREQUIRED;
  if (
    !heading ||
    !docRequiredReason ||
    docRequiredReason.length === 0 ||
    !buttons ||
    buttons.length === 0
  ) {
    return null;
  }

  return (
    <View style={styles.overlayContent}>
      <Text style={[styles.overlayContentheading, AtomicCss.marginBottom24]}>{heading}</Text>
      <ScrollView showsVerticalScrollIndicator={false}>
        {docRequiredReason.map((text) => (
          <View style={[AtomicCss.flexRow, AtomicCss.marginBottom16]}>
            <View>
              <View style={AtomicCss.marginTop5} />
              <View style={styles.circle} />
            </View>
            <View style={{marginRight: 12}} />
            <Text style={[AtomicCss.regularFont, AtomicCss.font16, AtomicCss.defaultText]}>
              {text}
            </Text>
          </View>
        ))}
        <View style={AtomicCss.marginBottom10} />
      </ScrollView>
      <View style={styles.btnWrapper}>
        <TouchableOpacity
          style={[styles.btnLinkWrapper, styles.singleLink]}
          onPress={() => {
            props.handleButtonClick('WhyDoINeedToUploadDocs_OK');
            props.onClose();
          }}
        >
          <Text style={[styles.btnLinkTxt]}>{buttons[0].text}</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}

WhyDocumentsOverlayContent.propTypes = {
  documentPageDetails: PropTypes.shape({
    bottomSheet: PropTypes.shape({
      DOCREQUIRED: PropTypes.shape({
        heading: PropTypes.string.isRequired,
        docRequiredReason: PropTypes.shape([PropTypes.string.isRequired]).isRequired,
        buttons: PropTypes.shape([
          PropTypes.shape({
            text: PropTypes.string.isRequired
          })
        ]).isRequired
      })
    })
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  handleButtonClick: PropTypes.func.isRequired
};

const styles = StyleSheet.create({
  overlayContent: {
    maxHeight: 540
  },
  overlayContentheading: {
    fontSize: 22,
    color: colors.black,
    fontFamily: fonts.bold
  },
  rowSection: {
    borderBottomColor: '#e7e7e7',
    borderBottomWidth: 1,
    paddingBottom: 18,
    marginBottom: 18
  },
  btnWrapper: {
    marginTop: 40,
    marginBottom: 20
  },
  btnLinkWrapper: {
    paddingVertical: 10
  },
  btnLinkTxt: {
    color: colors.azure,
    fontSize: 14,
    fontFamily: fonts.black
  },
  singleLink: {
    alignSelf: 'flex-end'
  },
  lastRow: {
    marginBottom: 0,
    borderBottomWidth: 0,
    borderBottomColor: 'transparent'
  },
  submittedWrapper: {
    paddingTop: 50
  },
  pendingWrapper: {
    paddingTop: 30
  },
  successfullyTxtHeading: {
    color: '#1a7971',
    fontFamily: fonts.black,
    fontSize: 26,
    marginBottom: 16
  },
  successIconWrapper: {
    width: 90,
    height: 90,
    borderRadius: 50,
    backgroundColor: '#c1f1dd',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: -70
  },

  successIconstyle: {
    width: 66,
    height: 66
  },
  pendingIconWrapper: {
    width: 60,
    height: 60,
    borderRadius: 6,
    backgroundColor: '#fdedd2',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: -60
  },
  pendingIconstyle: {
    width: 40,
    height: 40
  },
  crossIconWrapper: {
    position: 'absolute',
    right: 0,
    width: 30,
    height: 30
  },
  crossIcon: {
    width: 24,
    height: 24
  },
  uploadModeRow: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: 25
  },
  modeIcon: {
    width: 24,
    height: 24,
    marginRight: 10
  },
  cancelUploadbtnWrapper: {
    marginTop: 60
  },
  userDocCard: {
    ...getPlatformElevation(2),
    borderRadius: 6,
    paddingHorizontal: 12,
    paddingVertical: 10,
    backgroundColor: '#fff',
    borderTopWidth: 1,
    borderTopColor: '#f2f2f2'
  },
  pendingTxt: {
    color: '#cc7f01'
  },
  circle: {width: 8, height: 8, backgroundColor: colors.defaultTextColor, borderRadius: 4},
  marginTop4: {
    marginTop: 4
  },
  marginRight12: {
    marginRight: 12
  }
});
