// @flow
import React from 'react';
import { Text } from "react-native";
import getTgsSummaryData from './tripGuaranteeCardAdapter';
import {
  CoachInfoText,
  DescriptionSection,
  MainContent,
  NewStatusText,
  RightArrow,
  StaticIcon,
  SummaryContainer,
  StatusWithIconBg
} from '../cardElements';
import styles from './triGuranteeSummaryCSS'
import { DARK_GREEN_INFO, DARK_YELLOW_INFO, TGS_ICON } from '../../../../TripGuarantee/Utils/tripGuaranteeImageConstants';
function TripGuaranteeSummaryCard({ response, msrTicket }) {
  const cardData = getTgsSummaryData(response, msrTicket);
  const { heading, tgsInfo, trainName, statusHeading, openDetails, tgsStatus, status, titleStyle,
    viewStyle,
    textStyle } = cardData || {};


  return (
    <SummaryContainer bookingId={response.bookingID}>
      <MainContent onPress={openDetails}>
        {<StaticIcon icon={TGS_ICON} iconStyle={styles.staticIcon} />}
        <DescriptionSection>
          {!!heading && <Text style={titleStyle}>{heading}</Text>}
          {!!trainName && <CoachInfoText text={trainName} />}
          {!!tgsInfo && <NewStatusText status={status} text={tgsInfo} />}
          {!!statusHeading && <StatusWithIconBg
            imageStyle={styles.infoIcon}
            src={tgsStatus ? DARK_GREEN_INFO : DARK_YELLOW_INFO}
            textStyle={textStyle}
            viewStyle={viewStyle}
            status={statusHeading}
          />}
        </DescriptionSection>
        <RightArrow />
      </MainContent>
    </SummaryContainer>
  );
}

export default TripGuaranteeSummaryCard;
