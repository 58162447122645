import {Image, Text} from 'react-native';
import React from 'react';
import Fecha from 'fecha';
import HotelBookingDetailsConstant from '../hotel/details/HotelBookingDetailsConstant';
import styles from '../Common/styles/CommonMamiCss';
import {
  appendRupeeSymbol,
  appendZero,
  fillCheckInCheckOutData,
  getTimeInMillis,
  isNotNullAndEmpty
} from '../hotel/details/utils/HotelBookingDetailUtil';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import PromiseRefundCard from '../hotel/details/components/PromiseRefundCard';
import RegularRefundCard from '../hotel/details/components/RegularRefundCard';
import Timer from '../hotel/details/components/Timer';
import { getStaticData } from '../staticData/staticData';
import { getImagePath } from './PostSaleUtil';

const cancelledIcon = getImagePath('cancelled.webp');
const failedIcon = getImagePath('ic_failed.webp');
const promiseIcon = getImagePath('mmtPromise.webp');

// eslint-disable-next-line import/no-unresolved
const tick = require('@mmt/legacy-assets/src/ic_tick.webp');
// eslint-disable-next-line import/no-unresolved
const doubleTick = require('@mmt/legacy-assets/src/double_tick.webp');

export function isRequestStateOpen(currentStatus) {
  return (currentStatus === HotelBookingDetailsConstant.REQUEST_OPEN_BREACHED_PROMISE_TAT
    || currentStatus === HotelBookingDetailsConstant.REQUEST_OPEN_BREACHED_WARNING_TAT
    || currentStatus === HotelBookingDetailsConstant.REQUEST_OPEN_BREACHED_L1_ESCALATION
    || currentStatus === HotelBookingDetailsConstant.REQUEST_OPEN_BREACHED_L2_ESCALATION
    || currentStatus === HotelBookingDetailsConstant.REQUEST_OPEN_WITHIN_WARNING_TAT
    || currentStatus === HotelBookingDetailsConstant.REQUEST_CREATED);
}


export function getTimerTextColor(promiseStatus) {
  if (isOpenPromiseBrached(promiseStatus)) {
    return styles.timerTextYellowColor;
  }
  return styles.timerTextGreenColor;
}

/**
 * method to get string representing final TAT value in hours and minutes
 * @param timeObject - the time object representing final TAT
 * @return - the representative string
 */
export function getFinalTatString(timeObject) {
  let result = '';
  if (timeObject.hours > 0) {
    result += `${timeObject.hours} hr`;
  }
  if (timeObject.minutes > 0) {
    result += ` ${timeObject.minutes} min`;
  }
  return result;
}

export function getPromiseRefundViews(response, card, pageName) {
  const promiseList = response.myPromiseResponse;
  const views = [];
  if (promiseList) {
    promiseList.forEach((promise) => {
      let refundObj = {};
      if (promise.ruldetails.showonui
        && !(promise.ruldetails.isSilent)
        && (promise.attributedetails.channel === 'MIMA'
          || promise.attributedetails.channel === 'NAV')) {
        refundObj = getRefundAmount(response.refundCommDetailList, response.cancellationDetailList, promise.uniqueid);
        if (refundObj && refundObj.refundAmount > 0) {
          views.push(<PromiseRefundCard
            key={`PromiseRefundCard${promise.uniqueid}`}
            response={response}
            promise={promise}
            card={card}
            refundObj={refundObj}
            pageName={pageName}
          />);
        }
      }
    });
  }
  return views;
}

export function isOpenPromiseBrached(promiseStatus) {
  return promiseStatus === HotelBookingDetailsConstant.REQUEST_OPEN_BREACHED_PROMISE_TAT
    || promiseStatus === HotelBookingDetailsConstant.REQUEST_OPEN_BREACHED_L1_ESCALATION
    || promiseStatus === HotelBookingDetailsConstant.REQUEST_OPEN_BREACHED_L2_ESCALATION;
}

export function getPromiseProgressStyle(promise, isVertical) {
  const promiseStyles = [];
  if (isRequestStateOpen(promise.currentstatus)) {
    promiseStyles.push(isVertical ? styles.refundHalfbarVertical : styles.refundHalfbar);
  } else {
    promiseStyles.push(isVertical ? styles.refundFullbarVertical : styles.refundFullbar);
  }
  if (isOpenPromiseBrached(promise.currentstatus)) {
    promiseStyles.push(styles.refundYellowBackground);
  } else {
    promiseStyles.push(styles.refundGreenBackground);
  }
  return promiseStyles;
}

export function getPromiseIcons(promise) {
  const image = [];
  image.push(<Image
    key="PromiseSinleTickImage"
    style={styles.aminitiesIcon}
    source={tick}
  />);

  if (!isRequestStateOpen(promise.currentstatus)) {
    image.push(<Image
      key="PromiseDoubleTickImage"
      style={styles.aminitiesIcon}
      source={doubleTick}
    />);
  }
  return image;
}

function getRefundObjFromRefundComm(refundComm) {
  const refundObj = {};
  refundObj.currentStatus = refundComm.currentRefundStatus;
  refundObj.endMsg = 'Refund Processed & Sent to Account';
  refundObj.refundAmount = Math.round(refundComm.totalRefundAmountLCY);
  refundObj.refundComm = refundComm;
  if (refundComm.refundExpectedDate) {
    refundObj.endDate = refundComm.refundExpectedDate;
    refundObj.refundExpectedDate = `Your refund will reflect in your account by ${fillCheckInCheckOutData(refundComm.refundExpectedDate, 'DD MMM YYYY')}`;
  } else {
    refundObj.refundExpectedDate = 'Your refund will reflect in your account in 3-12 days. If it still doesn\'t reflect in this time then please contact MakeMyTrip support.';
  }
  return refundObj;
}

function getRefundObjFromCancelObj(cancellationDetail) {
  const refundObj = {};
  refundObj.currentStatus = cancellationDetail.currentRefundStatus;
  refundObj.refundAmount = Math.round(cancellationDetail.refundAmountLCY);
  refundObj.refundExpectedDate = 'Your refund will reflect in your account in 3-12 days. If it still doesn\'t reflect in this time then please contact MakeMyTrip support.';
  if (cancellationDetail.requestDoneTime) {
    refundObj.endDate = cancellationDetail.requestDoneTime;
  }
  refundObj.endMsg = 'Cancellation Done';
  return refundObj;
}

export function getStyle(refundObj, refundComm) {
  const style = [];
  style.push(styles.refundGreenBackground);
  style.push(refundComm && refundObj.currentStatus !== 'Refund Processed' ?
    styles.refundHalfbarVertical : styles.refundFullbarVertical);
  return style;
}

export function getRefundObject(refundComm, cancellationDetail) {
  const refundObj = refundComm ? getRefundObjFromRefundComm(refundComm) :
    getRefundObjFromCancelObj(cancellationDetail);
  const startDate = cancellationDetail.requestDate ? cancellationDetail.requestDate : undefined;
  if (refundObj.refundAmount > 0) {
    refundObj.startMsg = 'Cancellation Initiated';
    if (startDate) {
      const startDateInMillis = getTimeInMillis(startDate);
      refundObj.startDate = Fecha.format(startDateInMillis, 'DD MMM');
      refundObj.startTime = Fecha.format(startDateInMillis, 'hh:mm A');
    }
    if (refundObj.endDate) {
      const endDateInMillis = getTimeInMillis(refundObj.endDate);
      refundObj.endDate = Fecha.format(endDateInMillis, 'DD MMM');
      refundObj.endTime = Fecha.format(endDateInMillis, 'hh:mm A');
    }
  }
  return refundObj;
}

export function createPromiseDetailsMap(promiseList) {
  const result = {};
  if (promiseList) {
    promiseList.forEach((promise) => {
      result[promise.uniqueid] = promise;
    });
  }
  return result;
}


export function createRefundCommMap(refundList) {
  const result = {};
  if (refundList) {
    refundList.forEach((refundComm) => {
      result[refundComm.requestNo] = refundComm;
    });
  }
  return result;
}


export function getPromiseTimeObject(timeInHours) {
  const totalMinutes = timeInHours * 60;
  const promiseTimeObject = {};
  promiseTimeObject.hours = Math.floor(totalMinutes / 60);
  promiseTimeObject.minutes = Math.floor(totalMinutes % 60);
  return getFinalTatString(promiseTimeObject);
}

/**
 * create a promiseTimeObject using difference in two times represented in milliseconds
 * @param difference - the time in millis
 * @return - required object representing time in hour minute and second
 */
export function getPromiseTimeObjectInMillis(timeInMillis) {
  let promiseTime = timeInMillis;
  const elapsedHours = Math.floor(promiseTime / (60 * 60 * 1000));
  promiseTime %= (60 * 60 * 1000);
  const elapsedMinutes = Math.floor(promiseTime / (60 * 1000));
  promiseTime %= 60 * 1000;
  const elapsedSeconds = Math.floor(promiseTime / 60);
  const promiseTimeObject = {};
  promiseTimeObject.hours = elapsedHours;
  promiseTimeObject.minutes = elapsedMinutes;
  promiseTimeObject.seconds = elapsedSeconds;
  return promiseTimeObject;
}

export function getTimerText(promise) {
  let elapsedTime = promise.timeelapsed;
  const isRequestOpen = isRequestStateOpen(promise.currentstatus);
  if (isRequestOpen) {
    elapsedTime = new Date().getTime() - promise.promisestarttime;
  } else {
    const promiseTimeObj = getPromiseTimeObjectInMillis(elapsedTime);
    return (<Text
      style={[styles.boldFont, getTimerTextColor(promise.currentstatus)]}
    >{getTimeWithoutSeconds(promiseTimeObj)}
    </Text>);
  }
  return <Timer promiseStatus={promise.currentstatus} seconds={(elapsedTime / 1000)} />;
}


/**
 * method to subsitute a placeholder with appropriate value from the response
 * @param placeHolder - the placeholder to be replaced
 * @param promiseDetails - the object having values to be used for replacement
 * @return - the value to replace the placeholder
 */
export function substitutePlaceHolder(placeHolder, promiseDetails, refundAmount, totalPgDays) {
  switch (placeHolder) {
    case HotelBookingDetailsConstant.PLACEHOLDER_WALLET_AMOUNT_PAID:
      return appendRupeeSymbol(promiseDetails.walletamountpaid);

    case HotelBookingDetailsConstant.PLACEHOLDER_REFUND_AMOUNT:
      return appendRupeeSymbol(refundAmount);

    case HotelBookingDetailsConstant.PLACEHOLDER_FINAL_TAT:
      return getPromiseTimeObject(promiseDetails.finaltat);

    case HotelBookingDetailsConstant.PLACEHOLDER_PENALTY_PER_HOUR:
      return appendRupeeSymbol(promiseDetails.ruldetails.penaltyperhour);

    case HotelBookingDetailsConstant.PLACEHOLDER_MAX_PENALTY:
      return appendRupeeSymbol(promiseDetails.ruldetails.maxpenalty);

    case HotelBookingDetailsConstant.PLACEHOLDER_WALLET_AMOUNT_ACCRUED:
      return appendRupeeSymbol(promiseDetails.walletamountaccrued);

    case HotelBookingDetailsConstant.PLACEHOLDER_REQUEST_ATTRIB_ONE:
      return promiseDetails.attributedetails.requestAttribute1;

    case HotelBookingDetailsConstant.PLACEHOLDER_REQUEST_ATTRIB_TWO:
      return promiseDetails.attributedetails.requestAttribute2;

    case HotelBookingDetailsConstant.PLACEHOLDER_REQUEST_ATTRIB_THREE:
      return promiseDetails.attributedetails.requestAttribute3;

    case HotelBookingDetailsConstant.PLACEHOLDER_TOTAL_PG_DAYS:
      return totalPgDays;

    case HotelBookingDetailsConstant.PLACEHOLDER_PROMISE_OVERDUE_TIME:
      return getPromiseTimeObject(promiseDetails.overduetime);


    case HotelBookingDetailsConstant.PLACEHOLDER_PROMISE_COMPLETION_TIME:
      const promiseTimeObj = getPromiseTimeObjectInMillis(promiseDetails.timeelapsed);
      return `${promiseTimeObj.hours}hr&${promiseTimeObj.minutes}min`;

    default:
      return placeHolder;
  }
}

export function getTimeWithoutSeconds(promiseTimeObj) {
  return `${appendZero(promiseTimeObj.hours)}h : ${appendZero(promiseTimeObj.minutes)}m`;
}

export function getRefundAmount(refundComList, cancellationDetailList, cancellationRequestNumber) {
  const refundObj = {};
  if (refundComList) {
    for (let count = 0; count < refundComList.length; count += 1) {
      if (refundComList[count].requestNo === cancellationRequestNumber) {
        refundObj.refundComm = refundComList[count];
        refundObj.refundAmount = Math.round(refundComList[count].totalRefundAmountLCY, 0);
        refundObj.totalPGDays = refundComList[count].totalPGDays;
        return refundObj;
      }
    }
  }

  if (cancellationDetailList) {
    for (let count = 0; count < cancellationDetailList.length; count += 1) {
      if (cancellationDetailList[count].requestNo === cancellationRequestNumber) {
        refundObj.refundAmount = Math.round(cancellationDetailList[count].refundAmountLCY, 0);
        return refundObj;
      }
    }
  }
  return refundObj;
}

export function getRefundNodes(refundComList, cancellationDetailList) {
  let refundObj = [];
  let refundObject = [];

  if (refundComList) {
    refundObj = refundComList;
  }

  if (refundObj.length > 0) {
    if (cancellationDetailList) {
      for (let count = 0; count < cancellationDetailList.length; count += 1) {
        let value = false;
        for (let counter = 0; counter < refundObj.length; counter += 1) {
          if (cancellationDetailList[count].requestNo === refundObj[counter].requestNo) {
            value = true;
            refundObj[counter].refundAmount = Math.round(cancellationDetailList[count].refundAmount, 0);
            refundObj[counter].requestDate = cancellationDetailList[count].requestDate;
            refundObj[counter].requestDoneTime = refundObj[counter].refundExpectedDate ? refundObj[counter].refundExpectedDate : '';
          }
        }
        if (value === false) {
          refundObject.push(cancellationDetailList[count]);
        }
      }
    }
  } else {
    refundObj = cancellationDetailList;
  }
  for (let i = 0; i < refundObj.length; i += 1) {
    refundObject.push(refundObj[i]);
  }

  return refundObject;
}

/**
 * method to parse string with placeholders received in MyPromiseDetails response
 * @param inputString - the string to be parsed
 * @param promiseDetails - the object to be used while replacing placeholders with values
 * @return - parsed string
 */
export function getParsedString(inputString, promiseDetails, refundAmount, totalPgDays) {
  let result = inputString;
  const regex = new RegExp('<(.*?)>', 'ig');
  const matches = inputString.match(regex);
  if (matches != null) {
    for (const placeHolderName of matches) {
      const placeHolderValue = substitutePlaceHolder(placeHolderName, promiseDetails, refundAmount, totalPgDays);
      result = result.replace(placeHolderName, placeHolderValue);
    }
  }
  return result;
}


export function getTopStatusRefundCardData(response, cardId) {
  const staticData = getStaticData();
  const promiseList = getPromiseListForRefund(response.myPromiseResponse);
  const topStatusCardObject = {};
  if (isNotNullAndEmpty(promiseList)) {
    topStatusCardObject.iconPath = promiseIcon;
    topStatusCardObject.iconStyle = styles.mmtPromiseIcon;
  } else {
    topStatusCardObject.iconPath = cancelledIcon;
    topStatusCardObject.iconStyle = styles.successIcon;
  }
  topStatusCardObject.headText = staticData.bookingSuccessfullyCancelledText;
  return topStatusCardObject;
}

function getPromiseListForRefund(promiseList) {
  const result = [];
  if (isNotNullAndEmpty(promiseList)) {
    promiseList.forEach((promise) => {
      if ((promise.ruldetails.showonui
        && !(promise.ruldetails.isSilent)
        && (promise.attributedetails.channel === 'MIMA'
          || promise.attributedetails.channel === 'NAV'))) {
        result.push(promise);
      }
    });
  }
  return result;
}

function getRefundAmountFromCanAndRefundlist(cancellationList, refundComList) {
  if (cancellationList && refundComList) {
    for (let canCount = 0; canCount < cancellationList.length; canCount += 1) {
      if (cancellationList[canCount].requestType === 'Cancellation') {
        for (let refundCount = 0; refundCount < refundComList.length; refundCount += 1) {
          if (cancellationList[canCount].requestNo === refundComList[refundCount].requestNo) {
            return refundComList[refundCount].totalRefundAmountLCY;
          }
        }
      }
    }
    for (let canCount = 0; canCount < cancellationList.length; canCount += 1) {
      if (cancellationList[canCount].requestType === 'Cancellation') {
        return cancellationList[canCount].refundAmountLCY;
      }
    }
  }
  return 0;// default case
}

export function getTopStatusSubText(response) {
  const staticData = getStaticData();
  let refundCardObject;
  refundCardObject.headText = staticData.refundProcessedSuccessfullyText;
  const {subText} = HotelBookingModule.getPromiseParsedString(JSON.stringify(response));
  refundCardObject.subText = subText;
  return refundCardObject;
}

export function getPromiseDateTimeObj(promise) {
  const isRequestOpen = isRequestStateOpen(promise.currentstatus);
  const startDate = new Date(promise.promisestarttime);
  const endDate = new Date(isRequestOpen ? promise.expectedendtime
    : promise.promiseendtime);
  const promiseStartDate = Fecha.format(startDate, 'DD MMM');
  const promiseStartTime = Fecha.format(startDate, 'hh:mm A');
  const promiseEndDate = Fecha.format(endDate, 'DD MMM');
  const promiseEndTime = Fecha.format(endDate, 'hh:mm A');

  const resultObj = {};
  resultObj.startDate = promiseStartDate;
  resultObj.startTime = promiseStartTime;
  resultObj.endDate = promiseEndDate;
  resultObj.endTime = promiseEndTime;
  return resultObj;
}
