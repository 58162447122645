import React, { Component } from 'react';
import { View, Platform } from 'react-native';
import Actions, { ActionConst } from '../../../navigation/postSalesNavigation';
import PropTypes from 'prop-types';
import SpecialClaimUtility from '../Helpers/SpecialClaimUtility';
import CheckingAirlineContent from './CheckingAirlineContent';
import styles from './CheckingAirlineOverlayCss';
import { openErrorPage } from '../../../utils/PostSaleUtil';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import { isNullOrEmpty } from '../../../hotel/details/utils/HotelBookingDetailUtil';
import { FLIGHT_CANCELLATION_PREVIEW_URL, getCommonHeaders } from '../../../utils/NetworkUtils';
import { LOBNAMES } from '../../../PostSalesConstant';

export default class CheckingAirlineOverlay extends Component {
  static navigationOptions = {
    header: null,
  };

  render() {
    return (
      <View style={styles.overlayWrapper}>
        <View style={styles.overlay} />
        <CheckingAirlineContent />
      </View>
    );
  }

  componentDidMount() {
    this.fetchJSONAsync();
  }

  checkIfRefundPresent = async (response) => {
    if (response && response.ok) {
      const body = await response.json();
      if (body.errorPageData) {
        openErrorPage(
          body.errorPageData,
          undefined,
          this.onRetryClicked,
          this.props.bookingID,
          'specialClaim',
          null,
          { psLob: LOBNAMES.FLIGHT, isCorporateBooking: this.props.isCorporateBooking },
        );
      } else if (isNullOrEmpty(body.businessErrorDescription) && body.tdcwaRefundReceived) {
        Actions.flightBookingRefundBreakup({
          headerTitle: this.props.title,
          cancellationPreviewResponseJson: body,
          cancellationRequest: SpecialClaimUtility.getCancellationPreviewRequestForAutoRefund(
            this.props.bookingID,
            this.props.specialClaimData.scCode,
            this.props.lobCode,
            this.props.paxFareSegmentIdList,
            this.props.mobileNumber,
            true,
          ),
          allPaxSelected: this.props.allPaxSelected,
          specialClaimReason: this.props.specialClaimData.scCode,
          isCorporateBooking: this.props.isCorporateBooking,
          type: ActionConst.REPLACE,
        });
      } else if (
        body.specialClaimValidationData &&
        body.specialClaimValidationData.rejectedStatusInfo
      ) {
        Actions.falseClaim({
          ...body.specialClaimValidationData.rejectedStatusInfo,
          bookingId: this.props.bookingID,
          extraProps: {
            responseJson: body,
            specialClaimData: this.props.specialClaimData,
            selectedPassenger: this.props.selectedPassenger,
            bookingState: this.props.bookingState,
            paxFareSegmentIdList: this.props.paxFareSegmentIdList,
            lobCode: this.props.lobCode,
            trackingPageName: this.props.trackingPageName,
            bookingID: this.props.bookingID,
          },
          type: ActionConst.REPLACE,
        });
      } else {
        this.openClaimReviewPage(body);
      }
    } else {
      this.openClaimReviewPage(this.props.responseJson);
    }
  };

  openClaimReviewPage(body) {
    Actions.specialClaimReviewDetails({
      responseJson: body,
      specialClaimData: this.props.specialClaimData,
      selectedPassenger: this.props.selectedPassenger,
      bookingState: this.props.bookingState,
      paxFareSegmentIdList: this.props.paxFareSegmentIdList,
      bookingID: this.props.bookingID,
      lobCode: this.props.lobCode,
      isCorporateBooking: this.props.isCorporateBooking,
      type: ActionConst.REPLACE,
    });
  }

  async fetchJSONAsync() {
    try {
      const userInfo = await HotelBookingModule.getRequestHeader(
        this.props.bookingID,
        'FLIGHT_CANCELLATION_REVIEW',
      );
      const response = await fetch(FLIGHT_CANCELLATION_PREVIEW_URL, {
        method: 'POST',
        headers: await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo, {
          psLob: LOBNAMES.FLIGHT,
        }),
        body: JSON.stringify(this.props.request),
      });
      if (Platform.OS !== 'web') Actions.pop();
      this.checkIfRefundPresent(response);
    } catch (error) {
      this.checkIfRefundPresent();
      if (Platform.OS !== 'web') Actions.pop();
      console.log(error);
    }
  }
}

CheckingAirlineOverlay.propTypes = {
  bookingID: PropTypes.string.isRequired,
  request: PropTypes.object.isRequired,
  checkIfRefundPresent: PropTypes.func.isRequired,
};
