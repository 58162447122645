import React from 'react';
import { View, StyleSheet, FlatList } from 'react-native';

import PropertiesItem from './PropertiesItem';

const SimilarPropertiesSlider = ({ hotelList, currency }) => {
  const renderItem = ({ item }) => (
    <PropertiesItem item={item} currency={currency} />
  );

  return (
    <View>
      <FlatList
        data={hotelList}
        renderItem={renderItem}
        keyExtractor={item => item.id}
        horizontal
        showsHorizontalScrollIndicator={false}
        style={styles.sliderOuter}
        contentContainerStyle={styles.containerStyle}
      />
    </View>
  );
};

export default SimilarPropertiesSlider;

const styles = StyleSheet.create({
  sliderOuter: {
    marginHorizontal: -16,
  },
  containerStyle: {
    paddingLeft: 16,
    paddingRight: 10,
  },
});
