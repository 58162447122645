import React, { memo } from 'react';
import { View } from 'react-native';
import { Shimmer, PlaceholderContainerWrapper } from './ShimmerV2';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';

//Generic loading card which can be given number of rows to show loading
//sample screenshot of 2 rows: https://gommt-my.sharepoint.com/:i:/g/personal/mmt9115_go-mmt_com/EZSWUF0_NvFNpS67YuZLHNABwvlx2x6W9R_-duzgdXiGvw?e=VFjwsy

const LoadingCard = ({rows}) => {
    let animRows = [];
    for(let iter=0;iter<rows;iter++){
        animRows.push(
        <View key={iter} style={{marginBottom:12,backgroundColor: '#fff',borderColor: '#f2f2f2',borderWidth:1, borderRadius: 4, padding:16}}>
            <View style={AtomicCss.flexRow} marginRight={12}>
            <Shimmer height={24} width={24} marginRight={12} style={{ borderRadius: 4 }} />
            <View>
                <Shimmer height={12} width={119} marginBottom={14} />
                <Shimmer height={12} width={227} marginBottom={11} />
            </View>
            </View>
        </View>);
    }

  return (
    <PlaceholderContainerWrapper>
        <Shimmer height={20} width={300} marginTop={8} marginBottom={12} />
        <Shimmer height={20} width={100} marginBottom={24} />
        {animRows}
    </PlaceholderContainerWrapper>
  )
};

export default memo(LoadingCard);