import React from 'react';
import { getFont } from '../../PostSalesStyles';
import createStyles from './styles';
import { useTheme } from '../../theme/theme.context';
import { View } from 'react-native';
import HTMLViewWrapper from '../../Common/HTMLViewWrapper';

const getContentForCFAR = (cfarText,url) => {
    const [heading = '',subheading = ''] = cfarText ? cfarText.split('[1]') : [];
    const finalHeading = `${heading}<img width=121 height=12 src=${url}></img>`;
    return {heading:finalHeading,subheading};
};

const CfarBenefitStrip = ({cfarBenefitItem,isCfarSuccess}) => {
    const fonts = getFont();
    const {psTheme: theme} = useTheme();
    const styles = createStyles(theme,fonts);
    const {heading,subheading} = getContentForCFAR(cfarBenefitItem.heading,cfarBenefitItem.url);
    const styling = isCfarSuccess ? styles.cfarContainer : styles.nonCfarContainer;
    return (
        <>
        <View style={styling}>
            {!!heading && <HTMLViewWrapper htmlContent={heading} style={[styling]}>
            </HTMLViewWrapper>}
            {!!subheading && <HTMLViewWrapper htmlContent={subheading} style={[styling]}></HTMLViewWrapper>}
        </View>
        </>
    );
};

export default CfarBenefitStrip;
