import React from 'react';
import {ScrollView, View} from 'react-native';
import PropTypes from 'prop-types';
import Actions from '../../navigation/postSalesNavigation';
import getStyles from '../styles/BusMamiCss';
import PriceBreakUpCard from './PriceBreakUpCard';
import CommonHeader from '@mmt/legacy-commons/Common/Components/Header/CommonHeader';
import {whiteCrossIcon} from '../BusImageConstants';

class BusPaymentDetails extends React.Component {
  static navigationOptions = {
    header: null
  }
  constructor(props) {
    super(props);
    this.trackPageName = 'mob:customer support:Bus Domestic:PaymentBreakup';
  }

  render() {
    const styles = getStyles();
    return (
      <View style={[styles.GreyBg, {flex: 1}]}>
        <CommonHeader
          headerText="Price Breakup"
          imgSrc={whiteCrossIcon}
          backPressHandler={this.onBackPress}
        />
        <ScrollView>
          <PriceBreakUpCard
            response={this.props.response}
            pageName={this.trackPageName}
          />
        </ScrollView>
      </View>
    );
  }
  onBackPress = () => {
    Actions.pop();
  }
}
BusPaymentDetails.propTypes = {
  response: PropTypes.object.isRequired
};
export default BusPaymentDetails;

