import { StyleSheet } from 'react-native';
export default (theme, fonts) =>
  StyleSheet.create({
    blackFooterWrapper: {
      backgroundColor: theme.color.black22,
      paddingHorizontal: 16,
      paddingVertical: 10,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    btmTextInfo: {
      color: theme.color.gray30,
      ...fonts.regularFontFamily,
      fontSize: 10,
    },
  });
