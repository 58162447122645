import { StyleSheet } from "react-native";
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
const styles = StyleSheet.create({
  ticketAdded: {
    backgroundColor: colors.limeGreen,
  },
  ticketIssued: {
    backgroundColor: colors.creamWhite,
  },
  ticketAddedText: {
    color: colors.green,
  },
  ticketIssuedText: {
    color: colors.lightYello
  },
  infoIcon: {
    width: 16,
    height: 16,
    marginRight: 10,
  },
  staticIcon:
  {
    width: 30,
    height: 20
  }
})

export default styles;
