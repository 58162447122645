import React, { useRef, useEffect, useState } from 'react';
import { Image, Text, View, TextInput, FlatList, Dimensions, TouchableOpacity, ScrollView, StatusBar, SafeAreaView } from 'react-native';
// @ts-ignore
import { colors, fonts } from '@mmt/legacy-commons/Styles/globalStyles';
import styles from './style';
import CityListItem from '../../molecules/CityListItem';
import trackEvent from 'apps/crosslob/cabs/omniture/crossSellOmniture';
import { EVENTS } from 'apps/crosslob/cabs/omniture/constants';
import FloatingInputSelector from '@RN_UI_Lib/FloatingInputSelector';
import SearchSuggestions  from '@RN_UI_Lib/SearchSuggestions';
const City = require('@mmt/legacy-assets/src/city.webp');
const Marker = require('@mmt/legacy-assets/src/cabs_marker_icon.webp');
const Airport = require('@mmt/legacy-assets/src/airportIcon.webp');
const backIcon = require('@mmt/legacy-assets/src/cabs_back_icon.webp')
const ToIcon = require('@mmt/legacy-assets/src/toIcon.webp')

const BACK_ARROW_ICON = require('@mmt/legacy-assets/src/back_icon.webp');
const HOLLOW_DOT = require('@mmt/legacy-assets/src/ic_dot_hollow_blue_border.webp');
const FILLED_DOT = require('@mmt/legacy-assets/src/ic_dot_blue_filled.webp');
const CLOSE_GRAY_ICON = require('@mmt/legacy-assets/src/ic_cross__gray.webp');
interface Props {
  isSourceFocused: boolean;
  autocompleteResults: Array<any>;
  onSourceResultSelect: (item: any) => void;
  onSourceClick: () => void;
  onDestinationClick: () => void;
  onDestinationResultSelect: (item: any) => void;
  onInputChange: (item: string) => void;
  sourceText: string;
  destinationText: string;
  onCloseCityPicker: () => void;
  onSourceClearClicked: () => void;
  onDestinationClearClicked: () => void;
  sourceData : {};
  destData:{};
}

const CityPicker = (props: Props) => {
  const {
    isSourceFocused = true,
    autocompleteResults = [],
    onSourceResultSelect = () => {},
    onSourceClick = () => {},
    onDestinationClick = () => {},
    onDestinationResultSelect = () => {},
    onInputChange = () => {},
    sourceText = '',
    destinationText = '',
    onCloseCityPicker,
    onSourceClearClicked,
    onDestinationClearClicked,
    sourceData,
    destData
  } = props;
  const [selectedField, setSelectedField] = useState('');
  const sourceRef = useRef<TextInput | null>(null);
  const destRef = useRef<TextInput | null>(null);

  useEffect(() => {
    const focusTimeout = setTimeout(() => {
      if (isSourceFocused) {
        // @ts-ignore
        sourceRef?.current?.focus();
      } else {
        // @ts-ignore
        destRef?.current?.focus();
      }
    }, 50);
    return () => {
      clearTimeout(focusTimeout);
    };
  }, [isSourceFocused]);

  const onSourceChange = async (val: string) => {
    onInputChange(val);
  };

  const onDestinationChange = async (val: string) => {
    onInputChange(val);
  };

  const onItemClicked = async (item:any) => {
    if (selectedField==='PICKUP ADDRESS') {
      trackEvent(EVENTS.CROSS_SELL.PICKUP_ADDRESS_SELECTED);
      onSourceResultSelect(item);
    } else {
      trackEvent(EVENTS.CROSS_SELL.DROP_ADDRESS_SELECTED);
      onDestinationResultSelect(item);
    }
  };

const suggestedList = autocompleteResults.map((item) => {
  const isAirport = item.mainText.toLowerCase().includes('airport') || /\bterminal\s+\d+\b/i.test(item.mainText);
  const titleWithoutSecondaryText = item.mainText.replace(item.secondaryText, '').trim().replace(/,$/, '');
  return {
    img: isAirport ? Airport : City,
    title: titleWithoutSecondaryText,
    subTitle: item.secondaryText,
  };
});

  const handleSuggestionSelection = (suggestion:any) => {
    const place = autocompleteResults.find(item => item.secondaryText === suggestion.subTitle);
    onItemClicked(place);
  };
  return (
    <View style={[styles.mainView]}>
      <SafeAreaView>
        <View style={{padding:16}}>
          <FloatingInputSelector
            leftIc={backIcon}
            leftIcCstStyle={styles.leftIconStyle}
            leftIcContainer={styles.leftIcContainerStyle}
            leftIcPress={onCloseCityPicker}
            attrName={'PICKUP ADDRESS'}
            title={'PICKUP ADDRESS'}
            updateMasterState={onSourceChange}
            titleActiveSize={12}
            titleInActiveSize={12}
            titleInactiveColor={'#008CFF'}
            textInputStyles={styles.textInputStyles}
            custTitleStyle={styles.custInputTitleStyle}
            placeHolderText={' Enter PickUp address/nearby area'}
            selectedField={selectedField}
            backgroundColorInActive={colors.white}
            selectedItemStyle={styles.textInputStyles}
            highlightedText={selectedField?.includes('DROP') ? sourceData?.mainText: ''}
            //highlightedTextCallback={onSourceClearClicked}
            otherHighlightedTextProps={{ numberOfLines: 1, normalTxtStyle: { fontWeight: "700", color: colors.black, fontFamily: fonts.bold, fontSize: 16, lineHeight: 20, } }}
            setSelectedField={()=>setSelectedField('PICKUP ADDRESS')}
            placeholderTextColor={'#9B9B9B'}
            otherTextInputProps={{ ref: sourceRef, autoCapitalize: 'sentences', selectionColor: 'black' }}           
          />
      <View style={{marginTop:10}}>
        <FloatingInputSelector
          leftIc={ToIcon}
          leftIcCstStyle={styles.leftIconStyle}
          leftIcContainer={styles.leftIcContainerStyle}
          attrName={'DROP ADDRESS'}
          title={'DROP ADDRESS'}
          titleActiveSize={12}
          titleInActiveSize={12}
          titleInactiveColor={'#008CFF'}
          updateMasterState={onDestinationChange}
          backgroundColorInActive={colors.white}
          textInputStyles={styles.textInputStyles}
          custTitleStyle={styles.custInputTitleStyle}
          placeHolderText={' Enter Drop address/nearby area'}
          selectedField={selectedField}
          selectedItemStyle={styles.textInputStyles}
          highlightedText={selectedField?.includes('PICKUP') ? destData?.mainText: ''}
          // highlightedTextCallback={onDestinationClearClicked}
          placeholderTextColor={'#9B9B9B'}
          setSelectedField={()=>setSelectedField('DROP ADDRESS')}
          otherHighlightedTextProps={{ numberOfLines: 1, normalTxtStyle: { fontWeight: "700", color: colors.black, fontFamily: fonts.bold, fontSize: 16 } }}
          otherTextInputProps={{ ref: destRef, autoCapitalize: 'sentences', selectionColor: 'black' }}            
        />
      </View>
      </View>
    </SafeAreaView>
      {autocompleteResults && autocompleteResults.length > 0 && (
        <ScrollView style={styles.suggestionView} showsVerticalScrollIndicator={true} keyboardShouldPersistTaps="always">
          <SearchSuggestions 
            suggestions={suggestedList}
            updateJourney={handleSuggestionSelection}
            custTitleStyle={styles.custTitleStyle}
            custSubtitleStyle={styles.custSubtitleStyle} heading={undefined} imgSrc={undefined} extraComponent={undefined} iconCstStyle={undefined} custCodeStyle={undefined} cstHeadingIcnStyle={undefined} cstHeadTxtStle={undefined} custContainer={undefined} custSuggestContainer={undefined} custSuggestionView={undefined}          
          />
        </ScrollView>
      )}
    </View>
  );
};

export default CityPicker;
