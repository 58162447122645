import React, { useState, useEffect } from 'react';
import { isGCCBooking } from '../commonUtil';
import { Platform } from 'react-native';
import { getAdsCard } from 'packages/legacy-commons/adsConfig/adsCard';
import { listOfAvailableCIDForGCC } from '../../PostSalesConstant';
const AdtechWrapper = ({ cid }) => {
  const [showAdCard,setShowAdCard] = useState(null);
  useEffect(() => {
    const checkForGCC = async () => {
      const isGcc = await isGCCBooking();
      const _cid = isGcc ? cid + '_GCC' : cid;
      if(!isGcc || listOfAvailableCIDForGCC.includes(_cid)) setShowAdCard(_cid);
    };
    checkForGCC();
  },[]);
  return <>{!!showAdCard ? getAdsCard(Platform.OS, showAdCard) : null}</>;
};

export default AdtechWrapper;
