import { getStaticData } from 'apps/post-sales/src/staticData/staticData';
import { ViewState } from 'apps/post-sales/src/PostSalesConstant';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import { showShortToast } from '@mmt/legacy-commons/Common/Components/Toast';
import { FLIGHT_ACTION_IDS, PAGE_REQUESTED } from 'apps/post-sales/src/flights/v2/commonConstants';
import {
  EVENT_NAME_ACTION_IDS_MAPPING,
  TrackingOmnitureLoadData,
} from 'apps/post-sales/src/flights/v2/utils/trackingUtil';
import {
  addonsHandler,
  resetToSellingDetailPage,
  resetToFlightDetailPage,
  commonPaymentHandler,
  openExternalUrl,
} from '@mmt/post-sales/src/flights/v2/utils/commonUtils';

const {
  ADD_MEAL,
  ADD_SEAT,
  ADD_BAGGAGE,
  LOAD_OLD_FLOW,
  REFRESH_PAGE,
  LOAD_TRACKING,
  CLICK_TRACKING,
  ERROR_TRACKING,
  VIEW_CAB_DETAILS,
  TRAVEL_INSURANCE_BUY,
  FAST_FORWARD_BUY,
  AIRPORT_SERVICE_BUY,
  ERROR,
  SHOW_SHORT_TOAST,
  OPEN_FLIGHT_DETAIL,
} = FLIGHT_ACTION_IDS;

const { errorText = {} } = getStaticData();

interface ActionHandlerParams {
  actionId: string;
  actionData: any;
  onLoad: (data: TrackingOmnitureLoadData) => void;
  onClick: (eventName: string, extraInfo?: string) => void;
  onError: (error: string) => void;
  bookingId: string;
  setErrorData: (data: any) => void;
  setViewState: (state: string) => void;
  handleClick: (actionIdIgnore: string, data?: any) => void;
  refreshPage: boolean;
  setApiLoading: (statusIgnore: boolean) => void;
  fetchFlightBookingDetails: () => object;
}

export const actionHandler = async ({
  actionId,
  actionData,
  onLoad,
  onClick,
  onError,
  bookingId,
  setErrorData,
  setViewState,
  refreshPage,
  setApiLoading,
  fetchFlightBookingDetails,
}: ActionHandlerParams) => {
  switch (actionId) {
    case OPEN_FLIGHT_DETAIL: {
      resetToFlightDetailPage({bookingId, refreshPage});
      break;
    }
    case LOAD_OLD_FLOW: {
      resetToFlightDetailPage({bookingId, shouldLoadOldFlow: true});
      break;
    }
    case ADD_MEAL:
    case ADD_BAGGAGE:
    case ADD_SEAT: {
        setApiLoading(true);
        const flightResponse = await fetchFlightBookingDetails();
        setApiLoading(false);
        addonsHandler({
          bookingId,
          actionId,
          flightDetailResponse: flightResponse,
          segmentGroupId: actionData?.segmentId,
          source: PAGE_REQUESTED.SELLING,
        });
      break;
    }
    case REFRESH_PAGE: {
      resetToSellingDetailPage({bookingId});
      break;
    }
    case LOAD_TRACKING: {
      onLoad(actionData);
      break;
    }
    case CLICK_TRACKING: {
      if (actionData?.trackingEventName) {
        onClick(actionData?.trackingEventName, actionData?.trackingExtraInfo);
      }
      break;
    }
    case ERROR_TRACKING: {
      if (actionData?.trackingError) {
        onError(actionData?.trackingError);
      }
      break;
    }
    case VIEW_CAB_DETAILS: {
      if (actionData?.isExternalUrl) {
        openExternalUrl(actionData?.actionUrl || actionData?.deepLink);
      } else {
        GenericModule.openDeepLink(actionData?.actionUrl || actionData?.deepLink);
      }
      break;
    }
    case ERROR: {
      setViewState(ViewState.ERROR);
      setErrorData(actionData);
      break;
    }
    case AIRPORT_SERVICE_BUY:
    case FAST_FORWARD_BUY:
    case TRAVEL_INSURANCE_BUY: {
      commonPaymentHandler(actionData);
      break;
    }
    case SHOW_SHORT_TOAST:
      showShortToast(actionData?.message || errorText?.somethingWentWrongTryAgainError);
      break;
    default:
      break;
  }
  const clickEventName = EVENT_NAME_ACTION_IDS_MAPPING[actionId];
  onClick(clickEventName || actionId, actionData?.trackingExtraInfo);
};
