import React, {createRef} from 'react';
import get from 'lodash/get'
import {DeviceEventEmitter, View, Platform, Animated, Easing, Text, TouchableOpacity, Image, Dimensions} from 'react-native';
import Actions, { ActionConst } from '../../../navigation/postSalesNavigation';
import BasePage from '../../../Common/PostSalesBasePage';
import {
  CHILD_BOOKING_INFO_URL,
  getCommonHeaders,
  HOTEL_BOOKING_DETAILS_URL,
  HOTEL_BOOKING_DETAILS_HOLIDAY_URL,
  USER_INFO_URL,
} from '../../../utils/NetworkUtils';
import HotelBookingTrackingHelper from '../../HotelBookingTrackingHelper';
import HotelBookingConstant, { DOCUMENT_NO_TO_DOCUMENT_TYPE_MAPPING, HOTEL_DETAILS_BOTTOM_OVERLAY, PAGE_NUMBER } from '../HotelBookingDetailsConstant';
import HotelStayDatesCard from './HotelStayDatesCard';
import HotelRoomDetailsCard from './HotelRoomDetailsCard';
import TopStatusCard from './TopStatusCard';
import CrossLobBenefitCard from '../cards/CrossLobBenefitCard'
import HotelLateCheckInCard from './HotelLateCheckInCard';
import HotelPaymentCard from './HotelPaymentCard';
import MakeRequestCard from './MakeRequestCard';
import SimpleHeader from '@mmt/legacy-commons/Common/Components/Header/SimpleHeader';
import HotelCancellationPolicyCard from './HotelCancellationPolicyCard';
import HotelModificationCard from './HotelModificationCard';
import NeedHelpCard from './NeedHelpCard';
import {getRegularRefundViews} from '../../../utils/MyPromiseUtil';
import ProgressView from '../../../Common/ProgressView/ProgressView';
import getStyles from './HotelMamiCss';
import MyRequestsForThisBookingCard from './MyRequestsForThisBookingCard';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import {getAcmeXSellConfig, getButtonGradientColor, isIosClient, islangValidationRequired, isLostBookingData, isScrollViewCloseToBottom, setWebViewAuth, verifyBookingLang} from '../../../utils/PostSaleUtil'
import NoInternetView from '@mmt/legacy-commons/Common/Components/Error/NoInternetView';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import { colors } from './TopStatusCardColor';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import ViewControllerModule from '@mmt/legacy-commons/Native/ViewControllerModule';
import { isNetworkAvailable } from '@mmt/legacy-commons/Common/utils/AppUtils';
import PAHXPayNowPersuasionCard from './PAHXPayNowPersuasionCard';
import OldExchangedBookingCard from './OldExchangedBookingCard';
import ImportantInformationCard from './ImportantInformationCard';
import SummaryInvoicesCard from './SummaryInvoicesCard';
import NeedMoreHelpCard from '../../../Common/NeedMoreHelpCard';
import LocationCard from '../cards/LocationCard';
import BPGMimaCard from '../cards/BPGMimaCard';
import InsuranceOfferCard from '../cards/InsuranceOfferCard';
import TravelCashCard from 'apps/post-sales/src/Common/TravelCard';
import TravelInsuranceCard from '../cards/TravelInsurance';
import ExpediaChatCard from '../cards/ExpediaChatCard';
import ExpediaChatBottomSheet from './ExpediaChatBottomSheet';
import PostsaleFirebaseApp from '../../../firebase/postsalesFirebase';
import FIREBASE_CONFIG from '../../../firebase/const';
import {
  addCardListManually,
  getSubTitleForStickyHeader,
  handleBHFFormBannerClick,
  isIntlHotelBooking,
  isNotNullAndEmpty,
  openChatWithHost,
  getActionButton,
  handleClickEvent,
  getCardAndActionFromAllCards,
  showShareIcon,
  showDownloadIcon,
  showPage,
  getCardObject,
  getActionFromCard,
  openCancellationPreview,
  isNonRefundable,
  getThrottleParams,
  openHotelReview,
  downloadFile,
  handleModificationAction
} from '../utils/HotelBookingDetailUtil';
import logAction from '@mmt/legacy-commons/Helpers/actionsLogger';
import { invalidateTrips } from '@mmt/legacy-commons/Helpers/genericHelper';
import HotelCtripBookingAwaitedCard from './HotelCtripBookingAwaitedCard';
import SpecialRequestsCard from './SpecialRequestsCard';
import PersistentToast from '../../../Common/PersistentToast';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import RefundTracker from '../../../Common/RefundTracker';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import {fetchUrlParam, getCancellationRequestNo, openDialer, getMmtSelectData,isEasyPayNativeFlowEnabled, isHelpingHandEnabled, geFixedWidthIntString} from '../../../Common/commonUtil';
import {openHostChatWeb} from '../utils/HotelBookingDetailUtil'

import { connect } from 'react-redux';
import withRouterWrapper from '../../../Common/Web/withRouterWrapper';
import createAction from '../../reducer/actionCreator';
import { HOTEL_DETAIL_ACTIONS } from '../../reducer/actions';
import PAHBottomOverlay from './PAHBottomOverlay';
import IssueAtHotelOverlay from './IssueAtHotelOverlay';
import AlertInfo from '../../../Common/CommonImpInfo';
import { removeTags } from '../../../Holiday/HolidayBookingDetailUtils';
import { MsrConstants } from '../../../Common/MSR/Constants';
import MSRMainCard from '../../../Common/MSR/MainCard';
import {AUTH_FAILED_MESSAGE, COMMON_CARD, ISSUE_AT_HOTEL_OVERLAY, LOBNAMES, MOJO_LOBNAMES} from '../../../PostSalesConstant';
import MMTBlackCard from '../../../Common/MMTBlackCard';
import BenefitsClaimedCard from '../../../Common/BenefitsClaimedCard'
import BHFCard from './BHFCard';
import NeedMoreHelpOverlay from '../../../Common/NeedMoreHelpOverlay';
import ReqstScheduleBottomInfoCard from '../../../Common/NeedMoreHelpOverlay/ReqstSchedulBottomInfoCard';
import { NEED_MORE_HELP, NEED_HELP_PAGE } from '../../../PostSalesConstant';
import hotelDetailReducer from '../../reducer/hotelDetailReducer';
import { getStaticData } from '../../../staticData/staticData';
import { getAdTechCard } from '../../../Common/commonUtil';
import { POST_SALES_HOTEL_DETAILS_PAGE, POST_SALES_WEB_HOTEL_DETAILS_PAGE } from '@mmt/legacy-commons/adsConfig/adsConfigConstants';
import CustomerSupportCard from '../../../Common/CustomerSupportCard';

import Header from '../cards/Header';
import FacilitiesCard from '../cards/FacilitiesCard';
import HotelPhotographsCard from '../cards/HotelPhotographsCard';
import ContactUs from '../cards/ContactUs';
import GetMoreOutOfBookingCard from './GetMoreOutOfBookingCard';
import ContactlessCheckinCard from '../cards/ContactlessCheckinCard';
import InsuranceCard from '../cards/InsuranceCard';
import LongStayBenefitsCard from '../cards/LongStayBenefitsCard';
import TajGiftCard from '../cards/TajGiftCard';
import HotelCreditsCard from '../cards/HotelCreditsCard';
import WhatHappensNextCard from '../cards/WhatHappensNextCard';
import PrimaryGuestBookingCard from '../cards/PrimaryGuestBookingCard';
import SimilarPropertiesCard from './SimilarPropertiesCard';
import {getPokusConfig} from '@mmt/legacy-commons/Native/AbConfig/AbConfigModule';
import abConfigKeyMappings, {PokusLobs} from '@mmt/legacy-commons/Native/AbConfig/AbConfigKeyMappings';
import { trackHelpingHandEvent } from '../utils/HelpingHandHelper';
import HotelODCNotAllowedOverlay from './hotelODCNotAllowedOverlay';
import { getFont, themeColors } from '../../../PostSalesStyles';
import CommonOverlayMessage from '../../../Common/CommonOverlayMessage';
import ProceedForCancellationOverlay from './ProceedForCancellationOverlay';
import InsuranceBenefitsOverlay from './InsuranceBenefitsOverlay';
import CancellationPolicyOverlay from './cancellationPolicy/CancellationPolicyOverlay';
import { REFUND_OOT_TRACKING } from '../../../Common/RefundOutOfTATForm/constants';
import ChoosePaymentMode from './choosePaymentMode'
import LostIDBookingDetailPage from '../../../Common/LostIdDetailPage';
import NewCommonOverlay from '../../../Common/NewCommonOverlay';
import AssistanceCard from '../cards/AssistanceCard';
import TripViewCard from '../cards/TripViewCard';
import DeepLinkScrollHandler from './DeepLinkScrollHandler';
import HomestayAwardsCard from '../cards/HomestayAwardsCard';
import BestPriceGuarenteeCard from '../cards/BestPriceGuarenteeCard';
import InstallmentsCard from '../cards/InstallmentsCard';
import StayConnectedCard from '../cards/StayConnectedCard';
import InstallmentDetails from "../../GroupBooking/BottomSheets/installmentDetails";
import { isEmpty } from "lodash";
import GBModificationOverlay from '../../GroupBooking/BottomSheets/modificationOverlay';
import ContactPropertyOverlay from '../../GroupBooking/BottomSheets/contactPropertyOverlay';
import TalkToUsdetails from '../../../Common/talkToUsCard';
import {Lobs} from '../../../summary/summaryConstants.js'

import CsatFeedbackCard from '../../../Common/CsatFeedbackCard'
import CSATFeedbackHelper from '../../../Common/CsatFeedbackCard/tracking'
import BookMyForexCard from '../../../Common/BookMyForexCard';
import CabsCrossSellCard from 'apps/crosslob/cabs/widgets/cross_sell';
import { WHITE_LEFT_ARROW_ICON } from '../../HotelImageConstants';
import CustomerSupportFloatingCard from '../../../Common/CustomerSupportCard/CustomerSupportFloatingCard';
import { scrollHelper } from '../../../Common/CustomerSupportCard/CustomerSupportCardHelper';
import { RuleTraceConst } from '../../../Common/CustomerSupportCard/CustomerSupportCardConst';
import { getCustomerSupportCardData } from '../../../Common/CustomerSupportCard/api';
import addBookingTrackingHelper, { ADD_BOOKING_TRACKING_EVENTS } from '../../../summary/components/AddBooking/tracking';
import { isMweb } from '../../hotelUtils';
import AcmeSharedModuleInstanceHolder from '@mmt/acme-shared/src';
import HolidaysSharedModuleHolder from '@mmt/holidays-shared/src';
import PanCardDetail from './PanCardDetails';
import PanDetailOverlay from './PanCardDetails/PanDetailOverlay';
import MMTSelectNewUser from '../../../Common/MmtSelectNewUserCard'
import {showTCSModificationOverlay, showTCSRulesOverlay} from '../utils/BottomOverlayHelper';
import { ThemeContext } from 'apps/post-sales/src/theme/theme.context';
import HotelBookingDetailsConstant from '../HotelBookingDetailsConstant';
import AuthErrorView from 'apps/post-sales/src/Common/AuthError/AuthErrorView';
import DownloadInvoiceBottomSheet from '../../../Common/DownloadInvoiceBottomSheet.js';
import CustomerBookingConfirm from '../../../Common/CustomerBookingConfirm';
import CommonBottomOverlayMessage from '../../../Common/CommonOverlayMessage/CommonBottomOverlayMessage';
import BottomSheetWpr from 'apps/post-sales/src/Common/BottomSheetWpr';
import { CHECK_AVAILABILITY_DATECHANGE_CLICK, CHECK_IN_CHECK_OUT_DONE_CLICK, CHECK_IN_CHECK_OUT_SELECT_CLICK } from '../../modification/HotelModificationConstants';
import { LANG_AWARE } from '../../HotelConstants';
import DateChangeOverlay from '../../modification/datechange/DateChangeOverlay';
import Calender from '../../Calender/Calender';

export const HOTEL_BOOKING_DETAILS_PAGE = 'hotelBookingDetail';

export const PAHX_NO_SHOW_PAGE = 100;

class HotelBookingDetail extends BasePage {
  static navigationOptions = {
    header: null,
  };
  static contextType = ThemeContext;

  constructor(props) {
    super(props, HOTEL_BOOKING_DETAILS_PAGE);
    this.fonts = getFont(true);
    this.styles = getStyles(this.fonts);
    this.fsStyle = this.fonts.fsStyle;
    this.bookingId = props.BOOKING_ID || props.bookingId;
    this.pageData = props.pageData ? JSON.parse(props.pageData) : undefined;
    this.isLostBooking = isLostBookingData(this.pageData);
    this.mmtAuth = '';
    this.topStatusCardColor = ['#FFFFFF', '#FFFFFF'];
    this.topStatusTextStyle = this.styles.BlackText;
    this.trackPageName = '';
    this.loggingTrackInfo = {};
    this.langValidationRequired = islangValidationRequired(this.pageData);
    this.deepLinkSource = props.DEEP_LINK_SOURCE;
    this.pnr = this.pageData?.pnr || props.pnr;
    if(!props.IS_DEEP_LINK_RESET) {
      this.deepLinkPage = props.DEEP_LINK_PAGE;
      this.documentVersion = props.documentVersion;
    } else {
      this.deepLinkPage = '';
      this.documentVersion = '';
    }

    this.deepLinkPageSection = props.DEEP_LINK_PAGE_SECTION;
    this.deepLinkPageData = props.DEEP_LINK_EXTRA_DATA
      ? JSON.parse(props.DEEP_LINK_EXTRA_DATA)
      : undefined;
    this.references = {};
    this.resetUnreadCount = this.resetUnreadCount.bind(this);
    this.userInfo = null;
    this.refundCard = null;
    // width of the card decreases on scroll. Initial value is 146
    this.customerSupportCardWidth = new Animated.Value(146);
    // width of the text decreases on scroll. Initial value is 1
    this.customerSupportTextWidth = new Animated.Value(1);
    this.tripViewCardRef = createRef();
    this.hasTripViewTracked = createRef(false);
    this.maxNights = 30;
    this.today = new Date();
    this.date = geFixedWidthIntString(this.today.getDate(),2);
    this.month = this.today.getMonth();
    this.year = this.today.getFullYear();
    this.nextDate = new Date();
    this.nextDate.setDate(this.nextDate.getDate() + 1);
    this.trackingPageName = '';
    const { monthsShortText, weekDaysShortText } = getStaticData(LANG_AWARE.MODIFICATION);
    this.MONTH_ARRAY = Object.values(monthsShortText);
    this.WEEKDAY_ARRAY = Object.values(weekDaysShortText);
    let checkInOutData = {
      checkInDate: {
        year: this.year,
        month: this.month,
        date: this.date,
        weekday: this.WEEKDAY_ARRAY[this.today.getDay()],
        monthStr: this.MONTH_ARRAY[this.month],
        dateFormat: `${this.year}/${this.month + 1}/${this.date}`
      },
      checkOutDate: {
        year: this.nextDate.getFullYear(),
        month: this.nextDate.getMonth(),
        date: geFixedWidthIntString(this.nextDate.getDate(),2),
        weekday: this.WEEKDAY_ARRAY[this.nextDate.getDay()],
        monthStr: this.MONTH_ARRAY[this.nextDate.getMonth()],
        dateFormat: `${this.nextDate.getFullYear()}/${this.nextDate.getMonth() + 1}/${this.nextDate.getDate()}`
      },
    }
    this.state = {
      responseJson: null,
      userInfoResponse: null,
      viewState: this.isLostBooking ? ViewState.SHOW_DETAIL : ViewState.LOADING,
      acmeCity: undefined,
      PAHOverlay: false,
      callbackOverlay: NEED_MORE_HELP.HELPING_HAND,
      callbackBottomSheet: true,
      helpingHandPage: NEED_HELP_PAGE.HOTEL_DETAILS,
      showModifNotAllowedoverlay: false,
      isHelpingHandEnabled: false,
      bottomOverlay: '',
      overlayPosition: new Animated.Value(-1000),
      showInstallmentOverlay: false,
      showGBModificationOverlayData: undefined,
      showGBContactOverlay: false,
      stayConnectedTitle: '',
      showDownloadInvoiceOverlay: false,
      isgreyBgVisible: false,
      vendorChatDetails: [],
      showCalendar: false,
      checkInDate: checkInOutData.checkInDate,
      checkOutDate: checkInOutData.checkOutDate,
    };
    this.callDrivers = {};

    this.headerHeight = 0;
    this.ScrollViewRef= React.createRef();

  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps && isNotNullAndEmpty(nextProps.resetBookingId)) {
      return {
        ...prevState,
        viewState: ViewState.REFRESH,
      };
    }
    return prevState;
  }

  componentWillUnmount() {
    this.props.resetHotelDetail(null);
  }

  downloadInvoiceView() {
    return ( <BottomSheetWpr
      visible={this.state.showDownloadInvoiceOverlay}
      containerStyles={this.styles.bottomSheetWprStyle}
      setVisible={this.closeDownloadInvoicePopup}
      onRequestClose={this.closeDownloadInvoicePopup}   
      children={
        <DownloadInvoiceBottomSheet
            bookingId={this.bookingId}
            closeOverlay={this.closeDownloadInvoicePopup}
            lob={'Hotel'}
            psLob={LOBNAMES.HOTEL}
            pageName={this.trackPageName}
        />
      }
    />);
  }
  closeDownloadInvoicePopup = () => {
    this.setState({showDownloadInvoiceOverlay: false});
  };

  render() {
    const staticData = getStaticData(true, true);
    return (
      <View style={this.styles.flex1}>
        {this.state.viewState === ViewState.LOADING &&
          this.renderProgressView(staticData.loadingYourTripText)}
        {this.state.viewState === ViewState.NO_INTERNET &&
          this.renderNoNetworkView()}
        {this.state.viewState === ViewState.ERROR && this.renderErrorView()}
        {this.state.viewState === ViewState.AUTH_ERROR && this.renderAuthErrorView()}
        {this.state.viewState === ViewState.SHOW_DETAIL && this.renderPage()}
        {this.state.viewState === ViewState.REFRESH && this.refreshPage()}
      </View>
    );
  }

  refreshPage() {
    this.reloadPageWithNewBookingId(this.props.resetBookingId);
    // Resetting resetBookingId to null as to prevent state change.
    this.props.resetHotelDetail(null);
  }

  handleOdcNotAlloweOverlay = () => {
    this.setState({
        showModifNotAllowedoverlay: false
    })
  }

  deepLinkScrollFinish = (result) =>{
    this.deepLinkPage = ''; // Reset the Deeplink once evaluated.
  }

  setCustomerSupportCardData = async () => {
    const data = await getCustomerSupportCardData(this.bookingId, this.pageData?.uuid, 'hotelBookingDetails');
    !!data &&
      this.setState({
        customerSupportCardData: data,
      });
  };

  componentWillMount() {
    this.setState({
      responseJson: null,
      viewState: this.isLostBooking ? ViewState.SHOW_DETAIL : ViewState.LOADING,
    });
    DeviceEventEmitter.addListener(
      'hotel_details_response_from_mypromise_service',
      this.refreshHotelDetails.bind(this),
    );
    DeviceEventEmitter.addListener(
      'hotel_modification_thankyou_page',
      this.openModificationThankYouPage.bind(this),
    );
    DeviceEventEmitter.addListener(
      'hotel_booking_deep_link_page',
      this.rerenderPageOnDeepLink.bind(this),
    );
  }

  validateBookingLang = () => {
    verifyBookingLang(this.bookingId, LOBNAMES.HOTEL).then((isValidBookingAsPerLang) => {
      if(isValidBookingAsPerLang){
        this.fetchJSONAsync(HOTEL_BOOKING_DETAILS_URL + this.bookingId);
      }
    });
  };

  componentDidMount() {
    super.componentDidMount();
    this.myEventListener = DeviceEventEmitter.addListener('hotel_booking_details_storage_permission', this.checkPermission);
    const CALENDAR_EVENT_RECEIVED = 'Check_In_Check_Out_Date_Selected';
    this.calenderEventListener = DeviceEventEmitter.addListener(CALENDAR_EVENT_RECEIVED, this.onCalendarDateReceived); 
    if (!this.isLostBooking && !this.langValidationRequired) {
      this.fetchJSONAsync(HOTEL_BOOKING_DETAILS_URL + this.bookingId);
    }
    if (this.langValidationRequired) {
      this.validateBookingLang();
    }
    this.setCustomerSupportCardData();

    if (this.pageData?.isAddBookingFlow) {
      addBookingTrackingHelper.trackLoadEvent(
        ADD_BOOKING_TRACKING_EVENTS.Addbooking_detailspage_landed,
        this.pageData?.uuid,
        this.bookingId,
      );
    }
    if(isIosClient()){
      this.deepLinkPage = parseInt(this.props?.act);
      this.documentVersion = parseInt(this.props?.version);
    } else if(this.pageData){
      this.deepLinkPage = parseInt(this.pageData?.act);
      this.documentVersion = parseInt(this.pageData?.version);
    }
  }

  onCalendarDateReceived = (dateMap)=> {
    if (dateMap != null) {
      const newCheckInDate = new Date(dateMap.checkinYear, dateMap.checkinMonth, dateMap.checkinDay);
      const newCheckOutDate = new Date(dateMap.checkoutYear, dateMap.checkoutMonth, dateMap.checkoutDay);
      this.onDateSelection(newCheckInDate, 'FROM');
      this.onDateSelection(newCheckOutDate, 'TO');
      this.trackingPageName = isIntlHotelBooking(this.state.responseJson?.lobCode)
        ? 'Mob:customer support:Hotel International:DateChange:PickNewDates'
        : 'Mob:customer support:Hotel Domestic:DateChange:PickNewDates';
      HotelBookingTrackingHelper.trackClickEvent(this.trackingPageName, CHECK_IN_CHECK_OUT_DONE_CLICK, this.state.responseJson);
    }
  }

  onDateSelection= (data, dateType)=> {
    const selectedDate = new Date(data.getFullYear(), data.getMonth(), data.getDate());
    let sDate = new Date(this.state.checkInDate.year, this.state.checkInDate.month,  this.state.checkInDate.date);
    let eDate = new Date(this.state.checkOutDate.year, this.state.checkOutDate.month,  this.state.checkOutDate.date);
    const totalNights = this.getNights(selectedDate, sDate);

    if (dateType === 'FROM' || selectedDate.getTime() === sDate.getTime()) {
      sDate = selectedDate;
      eDate = selectedDate;
      this.toastError = false;
    } else if (dateType === 'TO') {
      if (selectedDate.getTime() >= sDate.getTime() && totalNights <= this.maxNights) {
        eDate = selectedDate;
        this.toastError = false;
      } else {
        eDate = selectedDate;
        sDate = selectedDate;
        this.toastError = false;
      }
    }
    this.updateDateRangeInput(sDate, eDate);
  }

  getNights = (max, min)=> {
    const date1 = max;
    const date2 = min;
    const timeDiff = Math.abs(date2.getTime() - date1.getTime());
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return diffDays;
  }

  updateDateRangeInput = (sDate, eDate) => {
    const updatedStartDate = sDate.getDate() > 9 ? sDate.getDate() : (`0${sDate.getDate()}`).slice(-2);
    const updatedStartMonth = sDate.getMonth();
    const updatedStartYear = sDate.getFullYear();
    const updatedEndDate = sDate != eDate ? eDate.getDate() > 9 ? eDate.getDate() : (`0${eDate.getDate()}`).slice(-2) : '- -';
    const updatedEndMonth = sDate != eDate ? eDate.getMonth() : '';
    const updatedEndYear = sDate != eDate ? eDate.getFullYear() : '';
    const updatedEndWeekDay = sDate != eDate ? this.WEEKDAY_ARRAY[eDate.getDay()] : '';

    const startDate = {
      year: updatedStartYear,
      month: updatedStartMonth,
      date: updatedStartDate,
      weekday: this.WEEKDAY_ARRAY[sDate.getDay()],
      monthStr: this.MONTH_ARRAY[updatedStartMonth],
      dateFormat: `${updatedStartYear}/${updatedStartMonth + 1}/${updatedStartDate}`
    };
    const endDate = {
      year: updatedEndYear,
      month: updatedEndMonth,
      date: updatedEndDate,
      weekday: updatedEndWeekDay,
      monthStr: this.MONTH_ARRAY[updatedEndMonth],
      dateFormat: `${updatedEndYear}/${updatedEndMonth + 1}/${updatedEndDate}`
    };
    // if (sDate === eDate) { this.isCheckAvlBtn_Disabled = true; } else { this.isCheckAvlBtn_Disabled = false; }
    
    this.setState({
      checkInDate: startDate,
      checkOutDate: endDate
    });
    if(sDate != eDate){
      const oldCheckInDate = this.state.responseJson?.hotelBookingInfo?.checkInDate;
      const oldCheckOutDate = this.state.responseJson?.hotelBookingInfo?.checkOutDate;
      const {
        hotelDateChangeCardsText:{
          items:{
            buttonText,
          },
        },
      } = getStaticData(LANG_AWARE.MODIFICATION);
      let buttonGradientColor = getButtonGradientColor(this.state.responseJson.isCorporateBooking);
      this.onButtonClick({ 
        checkinDate: startDate,
        checkOutDate: endDate,
        oldCheckInDate: oldCheckInDate,
        oldCheckOutDate: oldCheckOutDate,
        bookingId: this.state.responseJson.bookingID,
        buttonText: buttonText.heading,
        buttonStyle: buttonGradientColor,
        onButtonClick: this.onButtonClick,
        hotelDetailResponse: this.state.responseJson,
      });
    }
  }

  onButtonClick = (request) => {
    const extraArgs = {
      card: this.props.card
    };
    this.setState({bottomOverlay: ''});
    handleModificationAction('DATECHANGE', request , this.state.responseJson, extraArgs, this.showHelpingHand, LANG_AWARE.MODIFICATION, this.userInfo, {actionHandler: this.handleOpenBottomOverlay});
    this.trackingPageName = isIntlHotelBooking(this.state.responseJson?.lobCode)
      ? 'Mob:customer support:Hotel International:DateChange:PickNewDates'
      : 'Mob:customer support:Hotel Domestic:DateChange:PickNewDates';
    HotelBookingTrackingHelper.trackClickEvent(this.trackingPageName, CHECK_AVAILABILITY_DATECHANGE_CLICK, this.state.responseJson);
  }

  setupFireBaseConnection = async (firebaseTokenList, vendorChatDetails) => {
    try {
      const { config, basePath, tokenKey } = FIREBASE_CONFIG.EXPEDIA_CHAT;
      this.expediaChatFirebaseApp = new PostsaleFirebaseApp(config, tokenKey, firebaseTokenList, this.bookingId);
      const { isInstanceAuthenticated, errorData } = await this.expediaChatFirebaseApp.init();
      if (isInstanceAuthenticated && vendorChatDetails) {
        this.setState({ vendorChatDetails },
          () => {
            if (this.pnr) {
              this.handleExpediaChatOverlay(HOTEL_DETAILS_BOTTOM_OVERLAY.EXPEDIA_CHAT);
            }
          }
        );
        vendorChatDetails.forEach((vendor, index) => {
          const path = basePath + `${this.bookingId}/${vendor.pnr}`;
          this.expediaChatFirebaseApp.onValue(path, (data) => {
            this.updateVendorChatDetails(index, data);
          });
        });
      }
      if (errorData) {
        this.trackError(errorData);
      }
    } catch (error) {
      this.trackError(error);
    }
  }

  updateVendorChatDetails(index, data) {
    if (!isEmpty(data)) {
      const vendorChatDetails = [...this.state.vendorChatDetails];
      vendorChatDetails[index] = {
        ...vendorChatDetails[index],
        ...{ isUserActive: data.isUserActive, unreadMessagesCount: data.unreadMessagesCount },
      };
      this.setState({ vendorChatDetails: vendorChatDetails });
    }
  }

  componentWillUnmount(){
    if (this.myEventListener) {
      this.myEventListener.remove();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.refresh !== prevProps.refresh && this.props.refresh.isRefresh === true) {
      if (this.isLostBooking) {
        this.setState({
          refreshLostDetail: {},
        });
        return;
      } else if(this.props.BOOKING_ID && this.bookingId !== this.props.BOOKING_ID) {
        this.reloadPageWithNewBookingId(this.props.BOOKING_ID);
      } else {
        this.setState({viewState: ViewState.LOADING, bottomOverlay: ''});
        this.fetchJSONAsync(HOTEL_BOOKING_DETAILS_URL + this.bookingId);
      }
    }else if(this.state.responseJson && this.pageData && prevState.responseJson !== this.state.responseJson && prevProps.pageData !== this.pageData){
        if (!this.props?.refresh?.isRefresh && this.pageData?.routeToAddRequest && this.state.responseJson) {
          Actions.makeRequestPage({ bookingId: this.bookingID, response: this.state.responseJson });
        }
        if(this.pageData?.showHotelReview && this.state.responseJson){
          openHotelReview(this.state.responseJson);
        }
      }

    if (isNotNullAndEmpty(this.props.pageNameForHelpingHand)) {
      this.props.showHelpingHand('');
      if (this.state.responseJson && this.state.responseJson.isHelpingHandEnabled) {
        this.showHelpingHand(this.props.pageNameForHelpingHand);
      }
    }
    if (this.state.responseJson && this.state.responseJson.isCorporateBooking && !this.userInfo) {
      this.fetchUserInfoJSONAsync();
    }
    if(this.state.bottomOverlay === HOTEL_DETAILS_BOTTOM_OVERLAY.CALENDAR_OVERLAY || this.props.isODC){
      this.renderCalender('FROM')
    }
  }

  onMomentumScrollEnd = ({nativeEvent}) => {
    if (nativeEvent && isScrollViewCloseToBottom(nativeEvent)) {
      if(this.state.responseJson && this.state.responseJson.layoutDetail && this.state.responseJson.layoutDetail.cardList){
        const csatCard= this.state.responseJson.layoutDetail.cardList.find(
          (card) => card.cardId === HotelBookingConstant.CSAT_FEEDBACK_CARD,
        )
      if( !!csatCard) {
        CSATFeedbackHelper.trackSeenEvent(this.trackPageName, this.bookingId);
        }
      }
    }
  }

  checkTripViewVisibility = () => {
    if (this.hasTripViewTracked.current || !this.tripViewCardRef.current) return;

    this.tripViewCardRef.current?.measureInWindow((x, y, width, height) => {
      const { height: SCREEN_HEIGHT } = Dimensions.get("window");
      const calltracking = y >= 0 && (y + height <= SCREEN_HEIGHT);
      if(calltracking) {
        this.trackingPageName = isIntlHotelBooking(this.state.responseJson?.lobCode)
          ? 'Mob:customer support:Hotel International:TripView'
          : 'Mob:customer support:Hotel Domestic:TripView';
        HotelBookingTrackingHelper.trackShowEvent(this.trackingPageName, HotelBookingDetailsConstant.TRIP_VIEW_CARD_SHOWN, this.state.responseJson);
        this.hasTripViewTracked.current = true;
      }
    });
  };

  handleScroll = (event) => {
    this.checkTripViewVisibility();
    scrollHelper(this.customerSupportCardWidth, this.customerSupportTextWidth);
  }

  handleExpediaChatOverlay = (overlay) => {
    this.setState({
      bottomOverlay: overlay,
      isgreyBgVisible: true,
    });
    Animated.timing(this.state.overlayPosition, {
      toValue: 0,
      easing: Easing.easeInOut,
      duration: 300,
      delay: 100,
    }).start();
  };

  toggleDateChangeOverlay = (calenderOverlayData) => {
    const toDate = (dateStr) => {
      const [day, month, year] = dateStr.split("/")
      return new Date(year, month - 1, day)
    }
    if (calenderOverlayData) {
      calenderOverlayData.checkinDate = toDate(calenderOverlayData.checkinDate);
      calenderOverlayData.checkoutDate = toDate(calenderOverlayData.checkoutDate);
    }
    this.setState((previousState) => {
      this.props.isODC && previousState.showCalendar && this.refreshBooking(this.props.bookingId);
      return ({
        showCalendar: !previousState.showCalendar,
        calenderOverlayData
      })
    })
  }

  onDateCloseOverLay = () => {
      const {checkinDate,checkoutDate} = this.state.calenderOverlayData;
      this.trackingPageName = isIntlHotelBooking(this.props.response?.lobCode)
            ? 'Mob:customer support:Hotel International:DateChange:PickNewDates'
            : 'Mob:customer support:Hotel Domestic:DateChange:PickNewDates';
      HotelBookingTrackingHelper.trackClickEvent(this.trackingPageName, CHECK_IN_CHECK_OUT_DONE_CLICK, this.props.response);
      this.setState({
        showCalendar: false,
        calenderOverlayData: null
      }, () => {
        this.updateDateRangeInput(checkinDate,checkoutDate)
      })
    }
  
    updateOverlayDate = (from, to) => {
      const calenderOverlayData = { ...this.state.calenderOverlayData };
      calenderOverlayData.checkinDate = from;
      calenderOverlayData.checkoutDate = to;
      this.setState({
        calenderOverlayData
      })
    }

  renderCalender = async (dateType) => {
    if(this.state.bottomOverlay === HOTEL_DETAILS_BOTTOM_OVERLAY.CALENDAR_OVERLAY || this.props.isODC){
      const { checkInDate, checkOutDate } = this.state;
      let checkinMonth = checkInDate.month + 1;
      let checkoutMonth = checkOutDate.month + 1;
      checkinMonth = checkinMonth > 9 ? checkinMonth : (`0${checkinMonth}`).slice(-2);
      checkoutMonth = checkoutMonth > 9 ? checkoutMonth : (`0${checkoutMonth}`).slice(-2);
      const newCheckinDate = `${checkInDate.date}/${checkinMonth}/${checkInDate.year}`;
      const newCheckOutDate = `${checkOutDate.date}/${checkoutMonth}/${checkOutDate.year}`;
      const params = {};
      params.checkinDate = newCheckinDate;
      params.checkoutDate = newCheckOutDate;
      if (dateType === 'FROM') { params.isCheckinSelected = true; } else { params.isCheckinSelected = false; }
      this.trackingPageName = isIntlHotelBooking(this.state.responseJson?.lobCode)
        ? 'Mob:customer support:Hotel International:DateChange:PickNewDates'
        : 'Mob:customer support:Hotel Domestic:DateChange:PickNewDates';
      HotelBookingTrackingHelper.trackClickEvent(this.trackingPageName, CHECK_IN_CHECK_OUT_SELECT_CLICK, this.state.responseJson);
      this.setState({bottomOverlay: ''});
      Platform.OS === "web" ? this.toggleDateChangeOverlay(params) : await HotelBookingModule.openHotelCalendar(params);
      return;
    }
  }

  renderPage() {
    let views = [];
    if (this.isLostBooking) {
      return (
        <LostIDBookingDetailPage
          bookingId={this.bookingId}
          pageData={this.pageData}
          lobName={LOBNAMES.HOTEL}
          refreshLostDetail={this.state.refreshLostDetail}
          getRefundCard={({ response }) => {
            this.addRefundCard(views, response, null, true);
            return views;
          }}
        />
      );
    }
    const hotelDetailResponse = this.state.responseJson?.hotelBookingInfo;
    const vendorChatDetails = this.state.vendorChatDetails || [];
    const primaryCustomerFirstName = hotelDetailResponse?.primaryCustomerDetails?.firstName || '';
    const hotelBookingInfo =
      hotelDetailResponse.hotelDetailsList[
        hotelDetailResponse.hotelDetailsList.length - 1
      ];
    const { acmeCity } = this.state;
    views = this.getMainPageViews();
    const detailResponse = this.state.responseJson;
    const toastStyle = this.isUnreadMessageFromHostPresent() ? AtomicCss.paddingBottom70 : {};
    const xSellConfig = getAcmeXSellConfig();
    const staticData = getStaticData();
    
    const holidaysSharedModule = HolidaysSharedModuleHolder.get();
    if (Platform.OS!=='web' && holidaysSharedModule === null) {
      throw new Error('Holidays module not bootstrapped');
    }
    const { ACMEHolidayGroupingView } = (holidaysSharedModule && holidaysSharedModule.getHolidaysSharedComponents()) || {};

    const acmeSharedModule = AcmeSharedModuleInstanceHolder.get();
    if ( Platform.OS!=='web' && acmeSharedModule == null) {
      throw new Error('Acme-shared module not bootstrapped');
    }

    const {
      hotelDateChangeCardsText:{
        heading,
        items:{
          buttonText,
          calenderOverlayText
        },
        additionalText:{
          newCheckInText,
          newCheckOutText
        }
      },
      hotelModificationInfoStripCardText: {
        additionalText: {
          onePickNewDatesText
        }
      }
    } = getStaticData(LANG_AWARE.MODIFICATION);
  
    const { AFFILIATE_SOURCE } = Platform.OS!=='web' ? acmeSharedModule.getSharedAcmeConstants(): {};
    const {color} = this.context.psTheme;
    return (
      <View style={[{flex: 1}, this.styles.container]}>
        <Animated.ScrollView
          ref={this.ScrollViewRef}
          scrollEventThrottle={16}
          style={this.styles.darkGreyBg}
          contentContainerStyle={[toastStyle]}
          stickyHeaderIndices={[0]}
          bounces={false}
          onMomentumScrollEnd={this.onMomentumScrollEnd}
          keyboardShouldPersistTaps="always"
          onScroll={this.handleScroll}
        >
          <View style={{elevation: 3}} onLayout={({nativeEvent})=>{
              this.headerHeight = nativeEvent.layout.height
          }}>
            <Header
              title={removeTags(hotelBookingInfo.name)}
              subTitle={getSubTitleForStickyHeader(this.state.responseJson)}
              download={showDownloadIcon(this.state.responseJson)}
              share={showShareIcon(this.state.responseJson)}
              headerRtSection={true}
              response={this.state.responseJson}
              onBackPress={this.goBack}
              bgColor={this.topStatusCardColor}
              titleStyle={[this.styles.centerTextIos, this.fonts.boldFontFamily, this.fsStyle.font16]}
              subTitleStyle={this.styles.centerTextIos}
              pageName={this.trackPageName}
            />
          </View>
          {views}
          {Platform.OS !== "web" && acmeCity && detailResponse && !detailResponse.isCorporateBooking && !isEmpty(AFFILIATE_SOURCE) && !isEmpty(acmeSharedModule) && !isEmpty(holidaysSharedModule) && ACMEHolidayGroupingView && <ACMEHolidayGroupingView
            key="ACMEAffiliateRecommendations"
            city={acmeCity}
            xSellConfig={xSellConfig?.HOTEL}
            source={AFFILIATE_SOURCE.HOTELS}
            prevPage={`${AFFILIATE_SOURCE.HOTELS}_listing`}
            pageName={this.trackPageName}
          />}
        </Animated.ScrollView>
        {this.showBnplChargeCardOverlay()}
        {this.showCallbackOverlay()}
        {Platform.OS !== "web" && this.showCallbackBottomSheet()}
        {this.showIssueAtHotelCardOverlay()}
        {this.state.showModifNotAllowedoverlay && <HotelODCNotAllowedOverlay
          title={staticData.actionNotAllowedText}
          message={staticData.blockOdcText}
          handleCloseOverlay={this.handleOdcNotAlloweOverlay}
        />}
        {this.showProceedForCancellationOverlay()}
        {this.showInsuranceBenefitsOverlay()}
        {this.showCustomerBookingConfirmation()}
        {this.showCancellationPolicyOverlay()}
        {this.showChoosePaymentMode()}
        {this.installmentDetailsOverlay()}
        {this.GBModificationOverlay()}
        {this.stayConnectedOverlay()}
        {this.showPanDetailOverLay()}
        {showTCSModificationOverlay(this.state.bottomOverlay, this.state.responseJson, this.handleOpenBottomOverlay, this.handleCloseBottomOverlay, color)}
        {showTCSRulesOverlay(this.state.bottomOverlay, this.state.responseJson, this.handleOpenBottomOverlay, this.handleCloseBottomOverlay)}
        {(this.state.showCalendar && Platform.OS === "web") && <DateChangeOverlay>
            <SimpleHeader
              title={calenderOverlayText.heading}
              iconPress={() => this.toggleDateChangeOverlay(null)}
            />
            <Calender {...calenderOverlayText.additionalText} from={this.state.calenderOverlayData.checkinDate} to={this.state.calenderOverlayData.checkoutDate} updateDate={this.updateOverlayDate} />
            {(this.state.calenderOverlayData.checkinDate && this.state.calenderOverlayData.checkoutDate) && <TouchableOpacity style={this.styles.doneBtn} onPress={this.onDateCloseOverLay}><Text style={{ color: "#fff" }}>{calenderOverlayText.additionalText.buttonText}</Text></TouchableOpacity>}
          </DateChangeOverlay>
          }
        {this.state.customerSupportCardData?.ruleTrace === RuleTraceConst.FLOATING && (
          <CustomerSupportFloatingCard
            cardWidth={this.customerSupportCardWidth}
            dynamicTextWidth={this.customerSupportTextWidth}
            deepLinkHandler={this.openDeepLinkFromNeedHelp}
            pageName={this.trackPageName}
            cardData={this.state.responseJson?.floatingNeedHelpCard}
            bookingId={this.bookingId}
            uuid={this.state.responseJson?.uuid}
            isInternational={this.state.responseJson?.isInternational}
            productCode={LOBNAMES.HOTEL}
          />
        )}
        {this.state.bottomOverlay === HOTEL_DETAILS_BOTTOM_OVERLAY.EXPEDIA_CHAT && (
          <CommonOverlayMessage
            handleBottomOverlay={this.handleCloseOverlay}
            isBorderRadius={true}
            overlayContentStyle={this.styles.overlayContentStyle}
            overlayWrapperStyle={this.styles.overlayWrapperStyle}   
            content={
              <ExpediaChatBottomSheet 
                customerName={primaryCustomerFirstName} 
                vendorChatDetails={vendorChatDetails} 
                handleCloseOverlay={this.handleCloseOverlay}
                clearDeeplinkPnr={this.clearDeeplinkPnr}
                lobName={MOJO_LOBNAMES.HOTEL}
                bookingId={this.bookingId}
                deeplinkPnr={this.pnr}
                trackingPageName={this.trackPageName}
              />
            }
          />
        )}
        {this.state.showDownloadInvoiceOverlay && this.downloadInvoiceView()}
      </View>
    );
  }

  getMainPageViews() {
    const views = [];
    this.setDeepLinkSource();
    this.setEasyPayUrl();
    this.checkForInternalDeepLinkPageFlow(this.state.responseJson, this.mmtAuth, this.loggingTrackInfo, addCardListManually(1, 'Cancel_RecPriFull', 'CapsuleFullBtnFill', 'Submit', 'Pahx'));
    let cards = this.state.responseJson.layoutDetail.cardList;
    cards = cards.filter(card => card.pageNumber === PAGE_NUMBER.ONE);    
    cards.forEach((card) => {
      this.addCard(card, views);
    });
  const talkToUsData= get(this.state.responseJson, "talkToUsData", null);
  const isInternational= get(this.state.responseJson,"isInternational",false);
  if(!isEmpty(talkToUsData))
     views.push(<TalkToUsdetails 
         key="talkToUs"
         heading={talkToUsData.action.label}  
         description={talkToUsData.action.toolTipData.text}              
         bookingId={this.bookingId}
         productCode={Lobs.Hotel}
         uuid={this.state.responseJson.uuid}
         isInternational={isInternational}
         pageName={this.trackPageName}
         />);
    return views;
  }

  getSubText(message) {
    const staticData = getStaticData();
    const type = this.state.responseJson.hotelBookingInfo.hotelDetailsList[0].propertyType;
    const mess = message.replace('{0}', type || staticData.hotelText);
    return mess;
  }

  isMyBizLateCheckInValid(){
    return this.state.responseJson?.myBizLateCheckIn?.requestedState &&
           this.state.responseJson?.myBizLateCheckIn?.nonRequestedState &&
           this.state.responseJson?.myBizLateCheckIn?.requestDetailMapping;
  }

  getTopStatusCardTitle(title) {
    const staticData = getStaticData();
    const { hotelBookingInfo } = this.state.responseJson || {};
    const { pendingCardData } = hotelBookingInfo || {};
    const { showPendingHeader } = pendingCardData || {};
    if(!isEmpty(pendingCardData) && showPendingHeader){
      return staticData.hotelBookingDetailsText.additionalText.topStatusCardText.hotelBookingPendingText
    }
    return title
  }

  onInsuranceOfferCTAClick = (deeplinkUrl) => {
    HotelBookingTrackingHelper.trackClickEvent(
      this.trackPageName,
      HotelBookingDetailsConstant.INSURANCE_OFFER_CARD_CTA_CLICKED,
      this.state.responseJson,
    );
    if (deeplinkUrl) {
      GenericModule.openDeepLink(deeplinkUrl);
    }
  };

  addCard(card, views) {
    const staticData = getStaticData();
    const showArrows = showPage(this.state.responseJson, PAGE_NUMBER.TWO);
    const { bookingDateTime, hotelBookingInfo, freeCancellation, isGccBooking, loyaltyDetails, bookingState, isCorporateBooking, longStayBenefitsDetails , tajGiftCardDetails, hotelCreditsDetails, showLosCardAsCombination = false, showSeparatorIconForTajGiftDetails = false, showSeparatorIconForHotelCreditDetails = false } = this.state.responseJson || {};
    const { hotelDetailsList, checkInDate, checkOutDate, checkInTime, checkOutTime } = hotelBookingInfo || {};
    const { latitude, longitude, name, cityCode } = !isEmpty(hotelDetailsList) ? hotelDetailsList[0] : {};
    const {hotelUIFields} = isNotNullAndEmpty(hotelDetailsList) ? hotelDetailsList[0]: {};
    const {confirmedBookingText, confirmedBookingTextSubHead, confirmedBookingDescription} = hotelUIFields || {};
    const { additionalText } = staticData.hotelBookingDetailsText || {}
    const { topStatusCardText } = additionalText;
    const vendorChatDetails = this.state.vendorChatDetails || [];
    const { 
      hotelBookingConfirmedText,
      hotelBookingReservedText,
      havingNiceStayText,
      hadNiceStayText,
      yourBookingCancelledText,
      bookingFailedText,
      confirmationAwaitingText,
      bookingNotAcceptedText,
      youModifiedThisBookingText,
      downloadOrShareText,
      shareYourExperienceText,
      amountRefundedToYouText,
      nonRefundText
    } = topStatusCardText;
    switch (card.cardId) {
      case HotelBookingConstant.TOP_STATUS_CARD_24_HRS_TO_ON_TRIP:
      case HotelBookingConstant.TOP_STATUS_CARD_48_HRS_BEFORE_TRAVEL:
      case HotelBookingConstant.TOP_STATUS_CARD_48_TO_24_HRS_BEFORE_TRAVEL:
      case HotelBookingConstant.TOP_STATUS_CARD_12_HRS_AFTER_BOOKING:
      case HotelBookingConstant.TOP_STATUS_CARD_JUST_AFTER_MODIFICATION:
      case HotelBookingConstant.TOP_STATUS_CARD_PARTIALLY_CANCELLED_ATLEAST_1_UPCOMING:
        this.topStatusCardColor = colors.lightGreen;
        views.push(
          <TopStatusCard
            key="TopStatusCard"
            response={this.state.responseJson}
            card={card}
            color={this.topStatusCardColor}
            title={this.getTopStatusCardTitle(hotelBookingConfirmedText)}
            subTitle={
              this.state.responseJson.hotelBookingInfo.hotelDetailsList[0].hotelUIFields &&
              this.state.responseJson.hotelBookingInfo.hotelDetailsList[0].hotelUIFields
                .confirmedBookingText
            }
            subTitleSec={confirmedBookingTextSubHead}
            description={confirmedBookingDescription}
            titleStyle={this.styles.darkGreenText}
            subTitleStyle={AtomicCss.greenText}
            showTime={true}
            showArrows={showArrows}
            pageName={this.trackPageName}
            handleIssueAtHotelCard={this.handleIssueAtHotelCard}
            onBackPress={this.goBack}
            handlePAHCard={this.handlePAHCard}
            actionHandler={this.showGBModificationOverlay}
            handleOpenBottomOverlay={this.handleOpenBottomOverlay}
          />,
        );
        break;
      case HotelBookingConstant.TOP_STATUS_CARD_GROUP_BOOKING_RESERVED:
        this.topStatusCardColor = colors.lightBlue;
        views.push(
          <TopStatusCard
            key="TopStatusCard"
            response={this.state.responseJson}
            card={card}
            color={this.topStatusCardColor}
            title={this.getTopStatusCardTitle(hotelBookingReservedText)}
            subTitle={confirmedBookingText}
            subTitleSec={confirmedBookingTextSubHead}
            titleStyle={this.styles.darkBlueText}
            subTitleStyle={this.styles.darkBlueText}
            showTime={true}
            showArrows={showArrows}
            pageName={this.trackPageName}
            handleIssueAtHotelCard={this.handleIssueAtHotelCard}
            onBackPress={this.goBack}
            showInstallmentOverlay={this.showInstallmentDetailsOverlay}
            handlePAHCard={this.handlePAHCard}
            actionHandler={this.showGBModificationOverlay}
            handleOpenBottomOverlay={this.handleOpenBottomOverlay}
          />,
        );
        break;
      case HotelBookingConstant.TOP_STATUS_CARD_ON_TRIP:
      case HotelBookingConstant.TOP_STATUS_CARD_MODIFIED_BOOKING_ON_TRIP:
        this.topStatusCardColor = colors.lightGreen;
        views.push(
          <TopStatusCard
            key="TopStatusCard"
            response={this.state.responseJson}
            card={card}
            color={this.topStatusCardColor}
            title={this.getTopStatusCardTitle(havingNiceStayText)}
            subTitle={downloadOrShareText}
            subTitleSec={confirmedBookingTextSubHead}
            titleStyle={this.styles.darkGreenText}
            subTitleStyle={AtomicCss.greenText}
            showTime={true}
            showArrows={showArrows}
            pageName={this.trackPageName}
            handleIssueAtHotelCard={this.handleIssueAtHotelCard}
            onBackPress={this.goBack}
            handlePAHCard={this.handlePAHCard}
            handleOpenBottomOverlay={this.handleOpenBottomOverlay}
          />,
        );
        break;
      case HotelBookingConstant.TOP_STATUS_CARD_24_HRS_AFTER_CHECKOUT_TO_FOREVER:
      case HotelBookingConstant.TOP_STATUS_CARD_AFTER_CHECKOUT_TO_24_HOURS_AFTER_CHECKOUT:
      case HotelBookingConstant.TOP_STATUS_CARD_MODIFIED_BOOKING_TRIP_COMPLETED:
        this.topStatusCardColor = colors.lightGrey;
        views.push(
          <TopStatusCard
            key="TopStatusCard"
            response={this.state.responseJson}
            card={card}
            color={this.topStatusCardColor}
            title={this.getTopStatusCardTitle(hadNiceStayText)}
            subTitle={shareYourExperienceText}
            subTitleSec={confirmedBookingTextSubHead}
            titleStyle={this.styles.defaultGrey}
            subTitleStyle={AtomicCss.midGreyText}
            pageName={this.trackPageName}
            handleIssueAtHotelCard={this.handleIssueAtHotelCard}
            bookingCardStyle={this.styles.bodybg}
            onBackPress={this.goBack}
            handlePAHCard={this.handlePAHCard}
            handleOpenBottomOverlay={this.handleOpenBottomOverlay}
          />,
        );
        break;
      case HotelBookingConstant.TOP_STATUS_CARD_CAN_INIT_TO_REFUND_DONE:
      case HotelBookingConstant.TOP_STATUS_CARD_AFTER_REFUND_DONE:
      case HotelBookingConstant.TOP_STATUS_CARD_PARTIALY_CAN_AT_REST_ALL_COMPLETED:
        this.topStatusCardColor = colors.lightYellow;
        views.push(
          <TopStatusCard
            key="TopStatusCard"
            response={this.state.responseJson}
            card={card}
            color={this.topStatusCardColor}
            title={this.getTopStatusCardTitle(yourBookingCancelledText)}
            subTitle={freeCancellation ? amountRefundedToYouText : nonRefundText}
            subTitleSec={freeCancellation && confirmedBookingTextSubHead}
            titleStyle={this.styles.brownText}
            subTitleStyle={this.styles.brownText}
            showArrows={showArrows}
            pageName={this.trackPageName}
            handleIssueAtHotelCard={this.handleIssueAtHotelCard}
            onBackPress={this.goBack}
            handlePAHCard={this.handlePAHCard}
            handleOpenBottomOverlay={this.handleOpenBottomOverlay}
          />,
        );
        break;
      case HotelBookingConstant.TOP_STATUS_CARD_LOST_FAILED_BOOKING:
      case HotelBookingConstant.TOP_STATUS_CARD_LOST_FAILED_BOOKING_CANCELLED:
        this.topStatusCardColor = colors.lightPink;
        views.push(
          <TopStatusCard
            key="TopStatusCard"
            response={this.state.responseJson}
            card={card}
            color={this.topStatusCardColor}
            title={this.getTopStatusCardTitle(bookingFailedText)}
            subTitle={amountRefundedToYouText}
            subTitleSec={confirmedBookingTextSubHead}
            titleStyle={this.styles.darkRedText}
            subTitleStyle={this.styles.darkRedText}
            showArrows={showArrows}
            pageName={this.trackPageName}
            handleIssueAtHotelCard={this.handleIssueAtHotelCard}
            onBackPress={this.goBack}
            handlePAHCard={this.handlePAHCard}
            handleOpenBottomOverlay={this.handleOpenBottomOverlay}
          />,
        );
        break;
      case HotelBookingConstant.TOP_STATUS_CARD_RTB_AWAITED:
        this.topStatusCardColor = colors.lightBlue;
        views.push(
          <TopStatusCard
            key="TopStatusCard"
            response={this.state.responseJson}
            card={card}
            color={this.topStatusCardColor}
            title={this.getTopStatusCardTitle(confirmationAwaitingText)}
            titleStyle={[this.styles.darkBlueText, AtomicCss.textCenter, this.fsStyle.font16]}
            subTitleStyle={this.styles.darkBlueText}
            bottomText={
              this.state.responseJson.hotelBookingInfo.hotelDetailsList[0].hotelUIFields &&
              this.state.responseJson.hotelBookingInfo.hotelDetailsList[0].hotelUIFields
                .rtbAwaitedBookingIdText
            }
            pageName={this.trackPageName}
            showDatesAndRoomDetails={true}
            handleIssueAtHotelCard={this.handleIssueAtHotelCard}
            onBackPress={this.goBack}
            handlePAHCard={this.handlePAHCard}
            handleMessageHost={this.handleMessageHost}
            handleOpenBottomOverlay={this.handleOpenBottomOverlay}
          />,
        );
        break;
      case HotelBookingConstant.TOP_STATUS_CARD_RTB_REJECTED:
        this.topStatusCardColor = colors.lightPink;
        views.push(
          <TopStatusCard
            key="TopStatusCard"
            response={this.state.responseJson}
            card={card}
            color={this.topStatusCardColor}
            title={this.getTopStatusCardTitle(bookingNotAcceptedText)}
            titleStyle={[this.styles.darkRedText, AtomicCss.textCenter, this.fsStyle.font16]}
            subTitleStyle={this.styles.darkRedText}
            bottomText={
              this.state.responseJson.hotelBookingInfo.hotelDetailsList[0].hotelUIFields &&
              this.state.responseJson.hotelBookingInfo.hotelDetailsList[0].hotelUIFields
                .rtbRejectedBookingIdText
            }
            pageName={this.trackPageName}
            showDatesAndRoomDetails={false}
            handleIssueAtHotelCard={this.handleIssueAtHotelCard}
            onBackPress={this.goBack}
            handlePAHCard={this.handlePAHCard}
            handleOpenBottomOverlay={this.handleOpenBottomOverlay}
          />,
        );
        break;
      case HotelBookingConstant.TOP_STATUS_CARD_OLD_EXCHANGED_CANCELLED_TRIP:
        this.topStatusCardColor = colors.lightYellow;
        views.push(
          <TopStatusCard
            key="TopStatusCard"
            response={this.state.responseJson}
            card={card}
            color={this.topStatusCardColor}
            title={this.getTopStatusCardTitle(youModifiedThisBookingText)}
            titleStyle={[this.styles.brownText, AtomicCss.textCenter, this.fsStyle.font16]}
            subTitleStyle={this.styles.brownText}
            bottomText={
              this.state.responseJson.hotelBookingInfo.hotelDetailsList[0].hotelUIFields &&
              this.state.responseJson.hotelBookingInfo.hotelDetailsList[0].hotelUIFields
                .oldExchangedBookingIdText
            }
            bookingCardStyle={this.styles.bodybg}
            pageName={this.trackPageName}
            showDatesAndRoomDetails={false}
            onBackPress={this.goBack}
            handlePAHCard={this.handlePAHCard}
            handleOpenBottomOverlay={this.handleOpenBottomOverlay}
          />,
        );
        break;
      case HotelBookingConstant.TOP_STATUS_CARD_CTRIP_CARD:
        this.topStatusCardColor = colors.lightBlue;
        views.push(
          <TopStatusCard
            key="TopStatusCard"
            response={this.state.responseJson}
            card={card}
            color={this.topStatusCardColor}
            title={this.getTopStatusCardTitle(confirmationAwaitingText)}
            titleStyle={[this.styles.darkBlueText, AtomicCss.textCenter, this.fsStyle.font16]}
            subTitleStyle={this.styles.darkBlueText}
            bottomText={
              this.state.responseJson.hotelBookingInfo.hotelDetailsList[0].hotelUIFields &&
              this.state.responseJson.hotelBookingInfo.hotelDetailsList[0].hotelUIFields
                .ctripAwaitedBookingIdText
            }
            pageName={this.trackPageName}
            showDatesAndRoomDetails={false}
            onBackPress={this.goBack}
            handleOpenBottomOverlay={this.handleOpenBottomOverlay}
          />,
        );
        break;
      case HotelBookingConstant.EXPEDIA_CHAT_CARD:
        {
          !isEmpty(vendorChatDetails) &&
            views.push(
              <ExpediaChatCard
                key="ExpediaChatCard"
                vendorChatDetails={vendorChatDetails}
                handleOpenOverlay={() => this.handleExpediaChatOverlay(HOTEL_DETAILS_BOTTOM_OVERLAY.EXPEDIA_CHAT)}
                trackingPageName={this.trackPageName}
                bookingID={this.bookingId}
              />,
            );
        }
        break;
      case HotelBookingConstant.CROSS_LOB_BENEFIT_CARD:
        if (this.state.responseJson?.crossLobForexBuy) {
          views.push(
            <CrossLobBenefitCard
              response={this.state.responseJson}
              card={card}
              pageName={this.trackPageName}
            />,
          );
        }
        break;
      case HotelBookingConstant.REGULAR_REFUND_CARD:
      case HotelBookingConstant.REFUND_CARD:
        this.addRefundCard(views, this.state.responseJson, card);
        break;
      case HotelBookingConstant.HOTEL_CTRIP_BOOKING_AWAITED_CARD:
        views.push(
          <HotelCtripBookingAwaitedCard
            key="HotelCtripBookingAwaitedCard"
            response={this.state.responseJson}
            card={card}
            pageName={this.trackPageName}
          />,
        );
        break;
      case HotelBookingConstant.WHAT_HAPPENS_NEXT_CARD:
        views.push(
          <WhatHappensNextCard
            key="HotelWHNCard"
            response={this.state.responseJson}
            rtbData={this.state.responseJson && this.state.responseJson.rtbData}
            pageName={this.trackPageName}
          />,
        );
        break;
      case HotelBookingConstant.WHAT_HAPPENS_NEXT_CARD:
        views.push(
          <WhatHappensNextCard
            key="HotelWHNCard"
            response={this.state.responseJson}
            rtbData={this.state.responseJson && this.state.responseJson.rtbData}
            pageName={this.trackPageName}
          />,
        );
        break;
      case HotelBookingConstant.REGULAR_REFUND_CARD:
        views.push(getRegularRefundViews(this.state.responseJson, card, this.trackPageName));
        break;
      // case HotelBookingConstant.CHANGE_IN_PLAN_CARD:
      //   views.push(<ChangePlans
      //     key="ChangePlans"
      //     response={this.state.responseJson}
      //     card={card}
      //     pageName={this.trackPageName}
      //   />);
      //   break;

      case HotelBookingConstant.HOTEL_LATE_CHECK_IN_CARD:
        if (this.isMyBizLateCheckInValid()) {
          const { myBizLateCheckIn } = this.state.responseJson;
          views.push(
            <HotelLateCheckInCard
              key="HotelLateCheckInCard"
              bookingID={this.bookingId}
              myBizLateCheckIn={myBizLateCheckIn}
            />,
          );
        }
        break;
      case HotelBookingConstant.TRIP_VIEW_CARD:
        if(Platform.OS !== 'web') {
          views.push(
            <TripViewCard
              key="TripViewCard"
              tripViewCardRef={this.tripViewCardRef}
              onLayout={this.checkTripViewVisibility}
              response={this.state.responseJson}
              pageName={this.trackPageName}
            />
          );
        } 
        break;
      case HotelBookingConstant.HOTEL_PAYMENT_CARD:
        views.push(
          <DeepLinkScrollHandler
            actIds={[HotelBookingConstant.HOTEL_INVOICE_SCROLL]}
            onScrollFinish={this.deepLinkScrollFinish}
            delay={200}
            scrollViewRef={this.ScrollViewRef}
            headerHeight={this.headerHeight}
            deeplinkAct={this.deepLinkPage}
          >
            <HotelPaymentCard
              bookingId={this.state.responseJson.bookingID}
              key="HotelPaymentCard"
              response={this.state.responseJson}
              card={card}
              pageName={this.trackPageName}
              handlePAHCard={this.handlePAHCard}
              actionHandlerGB={this.showInstallmentDetailsOverlay}
              setDownloadInvoice={this.setDownloadInvoiceState}
              showPayNow
            />
          </DeepLinkScrollHandler>,
        );
        break;
      case COMMON_CARD.TRAVEL_CARD:
        if (!isEmpty(this.state.responseJson.travelCardResponse)) {
          views.push(
            <TravelCashCard
              key="TravelCashCard"
              travelCardResponse={this.state.responseJson.travelCardResponse}
              uuid={this.state.responseJson.uuid}
              bookingID={this.state.responseJson.bookingID}
            />,
          );
        }
        break;
      case HotelBookingConstant.CHAT_WITH_HOST_CARD:
        views.push(
          <AssistanceCard
            response={this.state.responseJson}
            card={card}
            pageName={this.trackPageName}
            resetUnreadCount={this.resetUnreadCount}
          />,
        );
        break;
      case HotelBookingConstant.SPECIAL_REQUESTS_CARD:
        if (Platform.OS !== 'web') {
          views.push(
            <SpecialRequestsCard
              key="SpecialRequestsCard"
              response={this.state.responseJson}
              card={card}
              pageName={this.trackPageName}
            />,
          );
        }
        break;
      case HotelBookingConstant.MAKE_REQUEST_CARD:
        views.push(
          <MakeRequestCard
            key="MakeRequestCard"
            response={this.state.responseJson}
            card={card}
            pageName={this.trackPageName}
          />,
        );
        break;
      case HotelBookingConstant.HOTEL_AMENITIES_CARD:
        views.push(
          <FacilitiesCard
            key="FacilitiesCard"
            response={this.state.responseJson}
            card={card}
            pageName={this.trackPageName}
          />,
        );
        break;
      case HotelBookingConstant.LOCATION_CARD:
        views.push(
          <LocationCard
            key="LocationCard"
            response={this.state.responseJson}
            card={card}
            pageName={this.trackPageName}
          />,
        );
        break;
      case HotelBookingConstant.HOTEL_CANCELLATION_POLICY_CARD:
        views.push(
          <DeepLinkScrollHandler
            actIds={[
              HotelBookingConstant.HOTEL_CANCELCARD_SCROLL,
              HotelBookingConstant.HOTEL_DATECHANGE_SCROLL,
            ]}
            onScrollFinish={this.deepLinkScrollFinish}
            delay={200}
            scrollViewRef={this.ScrollViewRef}
            headerHeight={this.headerHeight}
            deeplinkAct={this.deepLinkPage}
          >
            <HotelCancellationPolicyCard
              key="HotelCancellationPolicyCard"
              response={this.state.responseJson}
              card={card}
              cancellationState={this.getCancellationState}
              pageName={this.trackPageName}
              odcActionHandler={this.handleODCActionClick}
              handleOpenBottomOverlay={this.handleOpenBottomOverlay}
              userInfoResponse={this.state.userInfoResponse}
            />
          </DeepLinkScrollHandler>,
        );
        break;
      case HotelBookingConstant.HOTEL_GB_MODIFICATION_CARD:
        views.push(
          <HotelModificationCard
            key="HotelModificationCard"
            response={this.state.responseJson}
            card={card}
            pageName={this.trackPageName}
            actionHandler={this.showGBModificationOverlay}
          />,
        );
        break;
      case HotelBookingConstant.BHF_ENTRY_CARD: {
        const { bhfForm } = this.state.responseJson || {};
        const { showCard } = bhfForm || {};
        if (showCard) {
          views.push(
            <BHFCard
              response={this.state.responseJson}
              card={card}
              cancellationState={this.getCancellationState}
              pageName={this.trackPageName}
              onPress={handleBHFFormBannerClick}
            />,
          );
          break;
        }
      }
      case HotelBookingConstant.OLD_EXCHANGED_BOOKING_CARD:
        views.push(
          <OldExchangedBookingCard
            key="OldExchangedBookingCard"
            response={this.state.responseJson}
            card={card}
            pageName={this.trackPageName}
            clickHandler={this.reloadPageWithNewBookingId}
          />,
        );
        break;
      case HotelBookingConstant.NEED_HELP_CARD:
        views.push(
          <NeedHelpCard
            key="NeedHelpCard"
            response={this.state.responseJson}
            card={card}
            pageName={this.trackPageName}
          />,
        );
        break;
      case HotelBookingConstant.CONTACT_US_CARD:
        views.push(
          <ContactUs
            key="ContactUsCard"
            response={this.state.responseJson}
            card={card}
            pageName={this.trackPageName}
          />,
        );
      case HotelBookingConstant.MY_REQUESTS_FOR_THIS_BOOKING_CARD:
        views.push(
          <MyRequestsForThisBookingCard
            key="MyRequestsForThisBookingCard"
            response={this.state.responseJson}
            card={card}
            pageName={this.trackPageName}
          />,
        );
        break;
      case HotelBookingConstant.PAHX_PAY_NOW_CARD:
        views.push(
          <PAHXPayNowPersuasionCard
            key="PAHXPayNowPersuasionCard"
            response={this.state.responseJson}
            card={card}
            pageName={this.trackPageName}
          />,
        );
        break;
      case HotelBookingConstant.ALERT_INFO_CARD:
        views.push(
          <AlertInfo key="AlertInfo" infoList={this.state.responseJson.importantInformationList} />,
        );
        break;
      case MsrConstants.MSR_MAIN_CARD:
        views.push(
          <View style={AtomicCss.marginBottom2}>
            <MSRMainCard
              key="MSRMainCard"
              ticketsList={(this.state.responseJson.allTicketsForBooking || {}).ticketsList}
              card={card}
              pageName={this.trackPageName}
              customStyle={this.styles.cardNewCustomStyles}
              showCardIcon={false}
            />
          </View>,
        );
        break;
      case COMMON_CARD.LOYALTY_CARD:
        if (this.state.responseJson.isGccBooking && this.state.responseJson.loyaltyDetails) {
          const mmtSelectData = getMmtSelectData(this.state.responseJson.loyaltyDetails);
          views.push(
            <View style={AtomicCss.marginBottom2}>
              <MMTSelectNewUser
                mmtSelectData={mmtSelectData}
                pageName={this.trackPageName}
                bookingState={this.state.responseJson.bookingState}
              />
            </View>,
          );
        } else if (
          this.state.responseJson.loyaltyDetails &&
          !this.state.responseJson.isCorporateBooking
        ) {
          views.push(
            <View style={AtomicCss.marginBottom2}>
              <MMTBlackCard
                {...this.state.responseJson.loyaltyDetails}
                bookingId={this.state.responseJson.bookingID}
                uuid={this.state.responseJson.uuid}
                onClick={this.onMMTBlackCardClickEvent}
                trackingPageName={this.trackPageName}
                isRoundedCornerAndPadding={true}
              />
            </View>,
          );
        }
        break;
      case COMMON_CARD.BENEFITS_CLAIMED_CARD:
        if (isGccBooking && loyaltyDetails) {
          const mmtSelectData = getMmtSelectData(loyaltyDetails);
          views.push(
            <View style={AtomicCss.marginBottom2}>
              <MMTSelectNewUser
                key="MMTSelectNewUserCard"
                mmtSelectData={mmtSelectData}
                pageName={this.trackPageName}
                bookingState={bookingState}
              />
            </View>,
          );
        } else if (loyaltyDetails && !isCorporateBooking) {
          views.push(
            <View style={AtomicCss.marginBottom2}>
              <BenefitsClaimedCard
                {...loyaltyDetails}
                key="BenefitsClaimedCard"
                bookingId={this.state.responseJson.bookingID}
                trackingPageName={this.trackPageName}
              />
            </View>,
          );
        }
        break;
      case HotelBookingConstant.LONG_STAY_BENEFITS_CARD:
        if (longStayBenefitsDetails && !isCorporateBooking && !isGccBooking) {
          views.push(
            <LongStayBenefitsCard
              {...longStayBenefitsDetails}
              key="LongStayBenefitsCard"
              trackingPageName={this.trackPageName}
              showSeperatorIcon={!!showLosCardAsCombination}
            />,
          );
        }
      case HotelBookingConstant.UPCOMING_BOOKING_SUMMARY_CARD:
      case HotelBookingConstant.COMPLETED_BOOKING_SUMMARY_CARD:
      case HotelBookingConstant.ONTRIP_BOOKING_SUMMARY_CARD:
        views.push(
          <SummaryInvoicesCard
            key="SummaryInvoicesCard"
            response={this.state.responseJson}
            card={card}
            pageName={this.trackPageName}
            handleRedirectLink={this.handleOpenBottomOverlay}
          />,
        );
        break;
      case HotelBookingConstant.TAJ_GIFT_CARD:
        if (tajGiftCardDetails && !isCorporateBooking && !isGccBooking) {
          views.push(
            <TajGiftCard
              {...tajGiftCardDetails}
              key="TajGiftCard"
              trackingPageName={this.trackPageName}
              showSeperatorIcon={!!showSeparatorIconForTajGiftDetails}
            />,
          );
        }
        break;
      case HotelBookingConstant.HOTEL_CREDITS_CARD:
        if (hotelCreditsDetails && !isCorporateBooking && !isGccBooking) {
          views.push(
            <HotelCreditsCard
              {...hotelCreditsDetails}
              key="HotelCreditsCard"
              trackingPageName={this.trackPageName}
              showSeperatorIcon={!!showSeparatorIconForHotelCreditDetails}
            />,
          );
        }
        break;
      case HotelBookingConstant.IMPORTANT_INFORMATION_CARD:
        views.push(
          <ImportantInformationCard
            key="ImportantInformationCard"
            response={this.state.responseJson}
            card={card}
            pageName={this.trackPageName}
          />,
        );
        break;
      case HotelBookingConstant.NEED_MORE_HELP_CARD:
        if (this.state.responseJson.needMoreHelp) {
          views.push(
            <NeedMoreHelpCard
              header={this.state.responseJson.needMoreHelp.header}
              subHeader={this.state.responseJson.needMoreHelp.subHeader}
              onClick={this.onCallbackClick}
            />,
          );
        }
        break;
      case HotelBookingConstant.HOTEL_ADD_TECH_CARD:
        !this.state.responseJson.isCorporateBooking && views.push(this.renderAdTechCard());
        break;
      case HotelBookingConstant.NEED_MORE_HELP_MIMA_CARD:
        if (this.state.customerSupportCardData?.ruleTrace === RuleTraceConst.INLINE) {
          views.push(
            <CustomerSupportCard
              needMoreHelpCard={this.state.responseJson?.needMoreHelpMIMACard}
              deepLinkHandler={this.openDeepLinkFromNeedHelp}
              pageName={this.trackPageName}
              bookingId={this.bookingId}
            />,
          );
        }
        break;
      case HotelBookingConstant.BPG_MIMA_CARD:
        if (this.state.responseJson?.bpgCard) {
          views.push(
            <BPGMimaCard
              key="BPGMimaCard"
              pageName={this.trackPageName}
              bpgCard={this.state.responseJson?.bpgCard}
              hotelDetailsResponse={this.state.responseJson}
            />,
          );
        }
        break;
      case HotelBookingConstant.INSURANCE_OFFER_CARD:
        const { insuranceOfferCard } = this.state.responseJson;
        if (!isEmpty(insuranceOfferCard)) {
          views.push(
            <InsuranceOfferCard
              key="insuranceOfferCard"
              handleOpenBottomOverlay={this.handleOpenBottomOverlay}
              handleClick={this.onInsuranceOfferCTAClick}
              insuranceOfferCard={insuranceOfferCard}
            />,
          );
          HotelBookingTrackingHelper.trackShowEvent(
            this.trackPageName,
            HotelBookingDetailsConstant.INSURANCE_OFFER_CARD_SHOWN,
            this.state.responseJson,
          );
        }
        break;
      case HotelBookingConstant.GET_MORE_OUT_OF_YOUR_BOOKING_CARD:
        views.push(
          <DeepLinkScrollHandler
            actIds={[
              HotelBookingConstant.HOTEL_ADDROOM_SCROLL,
              HotelBookingConstant.HOTEL_ADDGUEST_SCROLL,
            ]}
            onScrollFinish={this.deepLinkScrollFinish}
            deeplinkAct={this.deepLinkPage}
            delay={200}
            scrollViewRef={this.ScrollViewRef}
            headerHeight={this.headerHeight}
          >
            <GetMoreOutOfBookingCard
              key="GetMoreOutOfBookingCard"
              response={this.state.responseJson}
              card={card}
              pageName={this.trackPageName}
              odcActionHandler={this.handleODCActionClick}
            />
          </DeepLinkScrollHandler>,
        );
        break;
      case HotelBookingConstant.HOTEL_PHOTOGRAPHS_CARD:
        views.push(
          <HotelPhotographsCard
            key="HotelPhotographsCard"
            response={this.state.responseJson}
            card={card}
            pageName={this.trackPageName}
          />,
        );
        break;
      case HotelBookingConstant.CONTACTLESS_CHECKIN_CARD:
        views.push(
          <ContactlessCheckinCard
            key="ContactlessCheckinCard"
            response={this.state.responseJson}
            card={card}
            pageName={this.trackPageName}
          />,
        );
        break;
      case HotelBookingConstant.INSURANCE_CARD:
        views.push(
          <InsuranceCard
            key="InsuranceCard"
            response={this.state.responseJson}
            card={card}
            pageName={this.trackPageName}
          />,
        );
        break;
      case HotelBookingConstant.TRAVEL_INSURANCE_CARD:
        const { insuranceQuoteDetails } = this.state.responseJson || {};
        {
          !isEmpty(insuranceQuoteDetails) &&
            views.push(
              <View style={AtomicCss.marginHz12}>
                <TravelInsuranceCard
                  key="TravelInsuranceCard"
                  bookingId={this.bookingId}
                  response={insuranceQuoteDetails}
                  pageName={this.trackPageName}
                />
              </View>
            );
        }
        break;  
      case HotelBookingConstant.HOTEL_ROOM_DETAIL_CARD:
        views.push(
          <HotelRoomDetailsCard
            key="HotelRoomDetailsCard"
            response={this.state.responseJson}
            card={card}
            pageName={this.trackPageName}
          />,
        );
        break;
      case HotelBookingConstant.PRIMARY_GUEST_CARD:
        views.push(
          <PrimaryGuestBookingCard
            key="PrimaryGuestCard"
            response={this.state.responseJson}
            card={card}
            pageName={this.trackPageName}
            handleOpenBottomOverlay={this.handleOpenBottomOverlay}
          />,
        );
        break;
      case HotelBookingConstant.CROSS_SELL_CAB_CARD:
        views.push(
          <CabsCrossSellCard
            data={{
              hotel_details: {
                latitude,
                longitude,
                name,
                locus_id: cityCode,
              },
              booking_date: new Date(bookingDateTime),
              checkout_date: new Date(checkOutDate),
              checkin_date: new Date(checkInDate),
              checkInTime,
              checkOutTime,
            }}
          />,
        );
        break;
      case HotelBookingConstant.HOTEL_STAY_DATES_CARD:
        views.push(
          <HotelStayDatesCard
            key="HotelStayDatesCard"
            response={this.state.responseJson}
            card={card}
            pageName={this.trackPageName}
          />,
        );
        break;
      case HotelBookingConstant.SIMILAR_PROPERTIES_CARD:
        views.push(<SimilarPropertiesCard hotelDetailsResponse={this.state.responseJson} />);
        break;
      case HotelBookingConstant.HOMESTAY_AWARDS_CARD:
        if (
          this.state.responseJson.homestayAwards &&
          isNotNullAndEmpty(this.state.responseJson.homestayAwards.title)
        ) {
          views.push(
            <HomestayAwardsCard
              key="HomestayAwardsCard"
              homestayAwards={this.state.responseJson.homestayAwards}
              response={this.state.responseJson}
              pageName={this.trackPageName}
            />,
          );
        }
        break;
      case HotelBookingConstant.CSAT_FEEDBACK_CARD:
        views.push(
          <CsatFeedbackCard
            bookingDetailsResponse={this.state.responseJson}
            psLob={LOBNAMES.HOTEL}
            bookingId={this.bookingId}
            pageName={this.trackPageName}
          />,
        );
        break;
      case HotelBookingConstant.BEST_PRICE_GUARANTEE_CARD:
        views.push(
          <BestPriceGuarenteeCard
            key="BestPriceGuarenteeCard"
            hotelDetailsResponse={this.state.responseJson}
            card={card}
            pageName={this.trackPageName}
          />,
        );
        break;
      case HotelBookingConstant.INSTALLMENT_CARD:
        views.push(
          <InstallmentsCard
            key="InstallmentsCard"
            hotelDetailsResponse={this.state.responseJson}
            card={card}
            showOverlay={this.showInstallmentDetailsOverlay}
            pageName={this.trackPageName}
          />,
        );
        break;
      case HotelBookingConstant.STAY_CONNECTED_CARD:
        views.push(
          <StayConnectedCard
            key="StayConnectedCard"
            response={this.state.responseJson}
            card={card}
            handleClickEvent={this.showGBStayConnectedOverlay}
            pageName={this.trackPageName}
          />,
        );
        break;
      case COMMON_CARD.BOOK_MY_FOREX_CARD:
        views.push(
          <BookMyForexCard
            cardData={this.state.responseJson.forexDetail}
            pageName={this.trackPageName}
            customStyle={this.styles.cardNewCustomStyles}
          />,
        );
        break;
      case HotelBookingConstant.PAN_CARD_DETAILS:
        views.push(
          <PanCardDetail
            bookingResponse={this.state.responseJson}
            cardData={card}
            pageName={this.trackPageName}
            showOverlay={() => {
              this.setState({
                panDetailOverlay: true,
              });
            }}
          />,
        );
        break;
      default:
        break;
    }
  }

  onMMTBlackCardClickEvent = (omnitureClickEvent) => {
    HotelBookingTrackingHelper.trackClickEvent(
      this.trackPageName,
      omnitureClickEvent,
      this.state.responseJson,
    );
  };

  trackHelperCustomerConfirmPopUp = (markupselected) => {
    HotelBookingTrackingHelper.trackClickEvent(this.trackPageName, markupselected, this.state.responseJson);
  };

  renderAdTechCard = () => {
    return (
      <View style={[AtomicCss.alignCenter, AtomicCss.marginBottom12]}>
        {
          getAdTechCard(Platform.OS !== 'web' ? POST_SALES_HOTEL_DETAILS_PAGE : POST_SALES_WEB_HOTEL_DETAILS_PAGE)
        }
      </View>
    )
  };

  getValue(val) {
    const result = val?.split(" ");
    let res = 0;
    if (result && result.length > 0) {
      res = parseInt(result[result.length - 1].replace(',', ""));
    }
    return res;
  }


  getPriceBreakUpData() {
    const { pricingBreakup } = this.state.responseJson;
    let baseCharges = 0, totalAmt = 0, feeSurcharges = 0, discountAmount = 0, currency = "";
    if (!isEmpty(pricingBreakup)) {
      const { pricingBreakupDiscountObj, pricingBreakupMap, pricingTotalCost, feesSurcharges } = pricingBreakup;

      if (!isEmpty(pricingBreakupDiscountObj)) {
        discountAmount = this.getValue(pricingBreakupDiscountObj.discountValue);
      }

      if (!isEmpty(pricingBreakupMap)) {
        for (let componentName in pricingBreakupMap) {
          if ([HotelBookingDetailsConstant.HOTEL_RESERVATION_CHARGES, HotelBookingDetailsConstant.PROPERTY_RESERVATION_CHARGES].includes(componentName)) {
            baseCharges = this.getValue(pricingBreakupMap[componentName]);
            break;
          }
        }
      }

      if (!isEmpty(pricingTotalCost)) {
        currency = pricingTotalCost.currency;
        totalAmt = pricingTotalCost.valueLcy;
      }
      if (feesSurcharges) {
        feeSurcharges = feesSurcharges;
      }
    }
    return { baseCharges, totalAmt, feeSurcharges, discountAmount, currency };
  }

  addRefundCard(views, responseJson, card, lostBooking = false) {
    const cancellationRequestNoList = getCancellationRequestNo(responseJson.cancellationDetailList);
    if (isNotNullAndEmpty(cancellationRequestNoList)) {
      this.refundCard = card;
      const refundBreakup = responseJson.refundBreakup;
      views.push(
        <RefundTracker
          key="RefundTracker"
          cancelRequests={cancellationRequestNoList}
          bookingId={this.bookingId}
          card={card}
          renderRefundButton={refundBreakup && refundBreakup.length > 0 ? this.renderRefundButton: null}
          bookingResponse={responseJson}
          isRoundedCornerAndPadding={true}
          lobName={LOBNAMES.HOTEL}
          pageName={REFUND_OOT_TRACKING.PAGES.HOTEL}
          isLostBookingId={lostBooking}
          customStyle={this.styles.cardNewCustomStyles}
          redirectLinkCustomStyle={this.styles.refundRedirectLinkCustomStyles}
          showIconImg={false}
          allowViewMore={true}
        />
        );
    }
  }

  renderRefundButton = (card) => {
    return(
      <View>
        {getActionButton(card, this.state.responseJson, this.handleClick, getThrottleParams)}
      </View>
    );
  };

  handleClick = (action) => {
    handleClickEvent(
      this.refundCard,
      action,
      this.state.responseJson,
      this.trackPageName,
    );
  };

  handleApiError = (response, errorBody) => {
    if (response.status === 500) {
      if (errorBody.message && errorBody.message.toUpperCase() === 'AUTHENTICATION FAILED') {
        HotelBookingModule.logInUser();
        this.trackError('AUTHENTICATION FAILED');
      } else {
        this.setState({viewState: ViewState.ERROR});
      }
    } else {
      this.setState({viewState: ViewState.ERROR});
      this.trackError(response.status);
    }
  }

  async fetchUserInfoJSONAsync() {
    try {
      const hasNetwork = await isNetworkAvailable();
      if (!hasNetwork) {
        this.setState({viewState: ViewState.NO_INTERNET});
        return;
      }
      const userInfo = await HotelBookingModule.getRequestHeader(this.bookingId, 'HOTEL_BOOKING_DETAIL');
      const response = await fetch(USER_INFO_URL, {headers: await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo)});
      const body = await response.json();
      if (response && response.ok && body) {
        this.userInfo = body;
        this.setState({
          userInfoResponse: body
        })
      } else {
        this.handleApiError(response, body);
      }
    } catch (error) {
      this.setState({viewState: ViewState.ERROR});
      this.trackError(error.message);
    }
  }

  async fetchJSONAsync() {
    const { vendorNo, serviceVoucherNo } = this.props;
    try {
      const hasNetwork = await isNetworkAvailable();
      if (!hasNetwork) {
        this.setState({ viewState: ViewState.NO_INTERNET });
        return;
      }
      let response = {};
      let body = {};
      const userInfo = await HotelBookingModule.getRequestHeader(
        this.bookingId,
        'HOTEL_BOOKING_DETAIL',
      );
      this.mmtAuth = userInfo.mmtAuth;
      this.loggingTrackInfo = userInfo.loggingTrackingInfo;

      if (vendorNo) {
        const url = HOTEL_BOOKING_DETAILS_HOLIDAY_URL;
        response = await fetch(url, {
          method: 'post',
          body: JSON.stringify({
            bookingId: this.bookingId,
            refBookingId: serviceVoucherNo,
            vendorNo,
          }),
          headers: await getCommonHeaders(this.mmtAuth, this.loggingTrackInfo),
        });
        body = await response.json();
        // default values set which in not coming from holiday MIMA api
        body.bookingState = {};
        body.bookingState.state = '';
        //body.hotelBookingInfo.primaryCustomerDetails = {};
      } else {
        const url = HOTEL_BOOKING_DETAILS_URL + this.bookingId;
        // const url = "http://172.16.119.239:9006/myaccount/hotelDetails/" + this.bookingId;
        response = await fetch(url, {
          headers: await getCommonHeaders(this.mmtAuth, this.loggingTrackInfo),
        });
        body = await response.json();
      }

      if (response && response.ok && body) {
        this.props.updateBookingDetails(body)
        const acmeCity = !(body.isGccBooking || body.isKsaBooking)
          ? this.getAcmeCity(body)
          : undefined;
        if (isNotNullAndEmpty(body.crossplatformdetails)) {
          const childBookingInfoList = {};
          body.crossplatformdetails.forEach((childBookingInfo) => {
            if (childBookingInfo.category === 1) {
              childBookingInfoList[childBookingInfo.refbookingid] =
                childBookingInfo.mandatorycancelwithparent;
            }
          });
          if (Object.keys(childBookingInfoList).length > 0) {
            const childBookingInfoRequest = {};
            childBookingInfoRequest.childBookingIdList = childBookingInfoList;
            const userInfo = await HotelBookingModule.getRequestHeader(
              this.bookingId,
              'HOTEL_CHILD_BOOKING_INFO',
            );
            const childBookingInfoResponse = await fetch(
              CHILD_BOOKING_INFO_URL,
              {
                method: 'POST',
                headers: await getCommonHeaders(
                  userInfo.mmtAuth,
                  userInfo.loggingTrackingInfo,
                ),
                body: JSON.stringify(childBookingInfoRequest),
              },
            );
            if (childBookingInfoResponse && childBookingInfoResponse.ok) {
              const childInfoResponseBody = await childBookingInfoResponse.json();
              body.childBookingInfo = childInfoResponseBody;
            }
          }
        }
        this.trackPageName = this.props.holidayBooking
          ? this.props.holidayPageName
          : isIntlHotelBooking(body?.lobCode)
          ? 'mob:customer support:Hotel International:TripDetails'
          : 'mob:customer support:Hotel Domestic:TripDetails';

          // const flexiMsg = "Get 100% refund on cancelling this booking till Sun, 16 Jun 11:59 AM through FlexiCancel.";
          // const flexiInfo = "Get 100% refund if you cancel before Sun, 16 Jun 11:59 AM. through FlexiCancel Policy";
          // const flexiCta = "Learn&nbspMore";
          // const flexiCtaUrl = "https://promos.makemytrip.com/flexi-cancel/index.html";
          // body.flexiCancellationData = {
          //   "flexiTitle": "Free Cancellation through FlexiCancel Policy",
          //   "flexiInfoHtml": `<p class="font14 darkGreyText">${flexiInfo} <a class="blueText font14 latoBlack" href=${flexiCtaUrl}>${flexiCta}</a></p>`,
          //   "flexiNote": "Note: Since you have opted for Free Cancellation through FlexiCancel, you cannot modify this booking for change of Stay Dates or Guest count",
          //   "flexiIcon": "https://promos.makemytrip.com/altaccoimages/flexicancel/flexicancel_apps_mytrips_greenstrip_policy_icon_20by24.webp",
          //   "flexiCtaUrl": "https://promos.makemytrip.com/flexi-cancel/index.html",
          //   "cancellationCardFlexiMsgHtml": `<p class="font14 darkGreyText">${flexiMsg} <a class="blueText font14 latoBlack" href=${flexiCtaUrl}>${flexiCta}</a></p>`,
          // }
        this.setState({
          responseJson: body,
          viewState: ViewState.SHOW_DETAIL,
          acmeCity,
        });
        const chatDetails = body?.hotelAdditionalInfo?.vendorChatDetails || [];
        const firebaseTokenList = body?.firebaseTokenList || {};
        this.setupFireBaseConnection(firebaseTokenList, chatDetails);
        HotelBookingModule.setHotelBookingResponse(JSON.stringify(this.state.responseJson));
        this.trackLoadEvent();
      } else {
        const errorBody = body;
        if (Platform.OS !== 'web' && errorBody?.message && errorBody.message.toUpperCase() === AUTH_FAILED_MESSAGE) {
          this.setState({ viewState: ViewState.AUTH_ERROR });
        } else {
          this.setState({ viewState: ViewState.ERROR });
          this.trackError(response.status);
        }
      }
    } catch (error) {
      this.setState({ viewState: ViewState.ERROR });
      this.trackError(error.message);
    }
  }

  getAcmeCity = (response) => {
    let acmeCity;
    try {
      const { bookingState, hotelBookingInfo } = response;
      const { hotelDetailsList, checkOutDate } = hotelBookingInfo || {};
      const checkOutDateObj = new Date(checkOutDate);
      const currentDateObj = new Date();
      const state = bookingState.state.toLowerCase();
      if (
        (state === 'upcoming' ||
          state === 'confirmed' ||
          state === 'reconfirmed') &&
        currentDateObj.getTime() < checkOutDateObj.getTime()
      ) {
        if (
          hotelDetailsList &&
          hotelDetailsList.length > 0 &&
          hotelDetailsList[0].cityCode
        ) {
          // This means we can show acme products
          acmeCity = hotelDetailsList[0].cityCode;
        }
        return acmeCity;
      }
    }
    catch {
      return acmeCity;
    }
  };

  trackLoadEvent() {
    if (this.props.vendorNo) {
      HotelBookingTrackingHelper.trackLoadEvent(
        `mob:customer support:Holidays Domestic:Hotel_Detail_${this.props.holidayIndex}`,
        this.state.responseJson,
        this.deepLinkPageData,
      );
    } else {
      HotelBookingTrackingHelper.trackLoadEvent(
        this.trackPageName,
        this.state.responseJson,
        this.deepLinkPageData,
      );
    }
  }

  trackError(error) {
    if (this.props.vendorNo) {
      HotelBookingTrackingHelper.trackErrorWithData(
        `mob:customer support:Holidays Domestic:Hotel_Detail_${this.props.holidayIndex}_error`,
        this.bookingId,
        error,
        this.state.responseJson,
      );
    } else {
      HotelBookingTrackingHelper.trackErrorWithData(
        'mob:customer support: hotels:TripDetailsError',
        this.bookingId,
        error,
        this.state.responseJson,
      );
    }
  }

  reloadPageWithNewBookingId = (bookingId, noReset) => {
    this.refreshBooking(bookingId)
    this.bookingId = bookingId;
    this.deepLinkPage = '';
    !noReset && this.setState({viewState: ViewState.LOADING});
    this.fetchJSONAsync(HOTEL_BOOKING_DETAILS_URL + this.bookingId);
  };

  refreshHotelDetails(e) {
    const body = JSON.parse(e.hotel_details_bundle);
    this.setState({
      responseJson: body,
      viewState: ViewState.SHOW_DETAIL,
    });
  }

  refreshBooking = (bookingId) => {
    if (Platform.OS !== 'web') {
      return;
    }
    Actions.refresh({
      BOOKING_ID: bookingId,
      bookingId,
      refresh: true,
      type: ActionConst.REPLACE
    });
  };

  rerenderPageOnDeepLink(e) {
    const activityBundle = JSON.parse(e.hotel_deep_link_activity_bundle);
    this.deepLinkPage = activityBundle.deepLinkPage;
    this.deepLinkPageSection = activityBundle.deepLinkPageSection;
    this.openDeepLinkPage();
  }

  checkPermission = (status) => {
    if (status.success) {
      switch(this.deepLinkPage){
        case HotelBookingConstant.HOTEL_DOWNLOAD_INVOICE:
        case HotelBookingConstant.HOTEL_DOWNLOAD_VOUCHER:
        case HotelBookingConstant.HOTEL_DOWNLOAD_POLICY:
          const fileType = DOCUMENT_NO_TO_DOCUMENT_TYPE_MAPPING[this.deepLinkPage];
          downloadFile(this.state.responseJson, fileType, this.documentVersion)
          break;
        case HotelBookingConstant.HOTEL_DOWNLOAD_INVOICE_POPUP:
          this.setState({ showDownloadInvoiceOverlay: true });
          break;   
        default:
          break;
      }
      this.deepLinkPage = ''
    }
  };

  addReference(componentName, component) {
    this.references[componentName] = component;
    this.openDeepLinkPage();
  }

  openDeepLinkPage() {
    if (!this.deepLinkPage) {
      return;
    }

    // If page section is not present in URL, then set it as 0
    this.deepLinkPageSection = this.deepLinkPageSection
      ? this.deepLinkPageSection
      : 0;

    switch (this.deepLinkPage) {
      case HotelBookingConstant.HOTEL_AMENTIES_DEEP_LINK_PAGE:
        const { hotelAmentiesCardRef } = this.references;
        if (hotelAmentiesCardRef) {
          hotelAmentiesCardRef.openAmenitiesPage();
        }
        break;
      case HotelBookingConstant.ROOM_AMENTIES_DEEP_LINK_PAGE:
        const { roomDetails } = this.state.responseJson?.hotelBookingInfo;
        let roomIndex = parseInt(this.deepLinkPageSection);
        roomIndex = roomIndex && roomIndex < roomDetails.length ? roomIndex : 0;
        const roomDetail = roomDetails[roomIndex];
        const { hotelRoomDetailCardRef } = this.references;
        if (roomDetail && hotelRoomDetailCardRef) {
          hotelRoomDetailCardRef.openAmenitiesPage(roomDetail);
        }
        break;
      default:
        break;
    }
  }

  renderNoNetworkView = () => {
    const staticData = getStaticData();
    return (
      <View style={{ flex: 1 }}>
        <SimpleHeader title={staticData.backText} iconPress={this.goBack} />
        <NoInternetView
          heading={staticData.errorText.notConnectedToTheInternetError}
          subHeading={staticData.errorText.checkYourSettingsAndTryAgainError}
          buttonText={staticData.tryAgainText}
          onRetry={() => {
            this.setState({ viewState: ViewState.LOADING });
            this.fetchJSONAsync(HOTEL_BOOKING_DETAILS_URL + this.bookingId);
            HotelBookingTrackingHelper.trackClickEvent(
              'mob:customer support: hotels:TripDetailsError',
              'no_network_retry_clicked',
              this.state.responseJson,
            );
          }}
        />
      </View>
    );
  };

  openModificationThankYouPage(data) {
    if (data.Payment_Status === 'PAYMENT_SUCCESS') {
      invalidateTrips();
      const hotelDetailsResponse = this.state.responseJson;
      Actions.hotelModificationThankYouPage({
        ...data,
        buttonGradientColor: getButtonGradientColor(hotelDetailsResponse.isCorporateBooking)
      });
    } else {
      const bookingid = data.oldbookingid;
      const action = data.action;
      Actions.hotelModificationFailure({
        showBackToBookingCTA: true,
        bookingid,
        action,
        hotelDetailResponse: this.state.responseJson,
        response: this.state.responseJson
      });
    }
  }

  renderProgressView = (loadingMessage) => (
    <ProgressView message={loadingMessage ? loadingMessage : ''} />
  );

  renderErrorView = () => {
    const staticData = getStaticData();
    return (
      <View style={{ flex: 1 }}>
        <SimpleHeader title={staticData.backText} iconPress={this.goBack} />
        <ErrorView
          showRetry
          onRetry={() => {
            this.setState({ viewState: ViewState.LOADING });
            this.fetchJSONAsync(HOTEL_BOOKING_DETAILS_URL + this.bookingId);
            HotelBookingTrackingHelper.trackClickEvent(
              'mob:customer support: hotels:TripDetailsError',
              'retry_clicked',
              this.state.responseJson,
            );
          }}
          buttonGradient={themeColors.gradientBtnColor}
          message={staticData.errorText.somethingWentWrongTryAgainError}
          buttonLabel={staticData.tryAgainText}
        />
      </View>
    );
  };

  renderAuthErrorView = () => <AuthErrorView bookingId={this.bookingId} lobName={LOBNAMES.HOTEL} onGoBackPress={this.goBack} />

  goBack = () => {
    super.onBackIconPress();
  };

  onHardBackPress = () => {
    super.onBackIconPress();
    return true;
  };

  setDeepLinkSource() {
    if (this.deepLinkSource == null) {
      this.state.responseJson.deepLinkSource = '';
    } else {
      this.state.responseJson.deepLinkSource = this.deepLinkSource;
    }
  }

  handleODCActionClick = (card, action, response, trackPageName) => {
    if(this.shouldBlockOnlineModification()) {
      this.setState({
        showModifNotAllowedoverlay: true
      })
    } else {
      handleClickEvent(card, action, response, trackPageName);
    }
  }

  /**
   * url of easypay link
   */
  setEasyPayUrl() {
    this.state.responseJson.easyPayLink = `https://payments.makemytrip.com/easypay?bookingId=${this.bookingId}&mobile=${this.state.responseJson?.hotelBookingInfo?.primaryCustomerDetails?.phoneNumber}`;
  }

  openDeepLinkFromNeedHelp = (url) => {
    const params = fetchUrlParam(url);
    this.deepLinkPage = parseInt(params.act);
    const isDeepLinkHandled = this.checkForInternalDeepLinkPageFlow(this.state.responseJson, this.mmtAuth, this.loggingTrackInfo, []);
    if (!isDeepLinkHandled) {
      if (Actions.currentScene() !== 'hotelBookingDetail') {
        Actions.popTo('hotelBookingDetail');
      }
    }
  }

  /**
   * handling deep link flow
   * @param response of hotel booking
   * @param mmtAuth
   * @param loggingTrackInfo
   */
  checkForInternalDeepLinkPageFlow(response, mmtAuth, loggingTrackInfo, cardList) {
    let cardAndAction;
    let action = {};
    let card = {};
    let resetDeepLink = false;
    let isDeepLinkHandled = false;
    switch (this.deepLinkPage) {
      case HotelBookingConstant.HOTEL_DOWNLOAD_INVOICE:
      case HotelBookingConstant.HOTEL_DOWNLOAD_VOUCHER:
      case HotelBookingConstant.HOTEL_DOWNLOAD_POLICY:
        if(isIosClient() || isMweb()){
          const fileType = DOCUMENT_NO_TO_DOCUMENT_TYPE_MAPPING[this.deepLinkPage];
          downloadFile(response, fileType, this.documentVersion)
        } else {
          HotelBookingModule.checkStoragePermission();
        }
        isDeepLinkHandled = true;
        break;
      case PAHX_NO_SHOW_PAGE:
        Actions.openPahxPage({
          response,
          mmtAuth,
          loggingTrackInfo,
          cardList,
          bookingId: response.bookingID
        });
        logAction('PAHXDeepLinkPage');
        break;
      case HotelBookingConstant.HOTEL_NAMECHANGE:
          cardAndAction = getCardAndActionFromAllCards(this.state.responseJson, HotelBookingConstant.ACTION_EDIT_PRIMARY_GUEST_NAMES);
          if (cardAndAction.card && cardAndAction.action) {
            isDeepLinkHandled = true;
            handleClickEvent(cardAndAction.card, {...cardAndAction.action, actionHandler: this.handleOpenBottomOverlay}, this.state.responseJson, this.trackPageName);
          }
        break;
      case HotelBookingConstant.HOTEL_DATECHANGE:
        cardAndAction = getCardAndActionFromAllCards(this.state.responseJson, HotelBookingConstant.ACTION_CHANGE_DATES);
        if (cardAndAction.card && cardAndAction.action) {
          isDeepLinkHandled = true;
          this.handleODCActionClick(cardAndAction.card, cardAndAction.action, this.state.responseJson, this.trackPageName);
        }
        resetDeepLink = true;
        break;
      case HotelBookingConstant.HOTEL_VIEW_HOUSE_RULES:
        const houseRules =
          response.hotelBookingInfo &&
          response.hotelBookingInfo.hotelDetailsList &&
          response.hotelBookingInfo.hotelDetailsList[0].houseRules;
        if (houseRules) {
          Actions.viewHouseRules({ response, bookingId: response.bookingID });
        }
        break
      case HotelBookingConstant.HOTEL_MESSAGEHOST:
        cardAndAction = getCardAndActionFromAllCards(this.state.responseJson, HotelBookingConstant.ACTION_CHAT_WITH_HOST);
        if (cardAndAction.card && cardAndAction.action) {
          isDeepLinkHandled = true;
          handleClickEvent(cardAndAction.card, cardAndAction.action, this.state.responseJson, this.trackPageName);
        }
        resetDeepLink = true;
        break;
      case HotelBookingConstant.HOTEL_ADDGUEST:
        cardAndAction = getCardAndActionFromAllCards(this.state.responseJson, HotelBookingConstant.ACTION_ADD_GUEST);
        if (cardAndAction.card && cardAndAction.action) {
          isDeepLinkHandled = true;
          handleClickEvent(cardAndAction.card, {...cardAndAction.action, actionHandler: this.handleOpenBottomOverlay}, this.state.responseJson, this.trackPageName);
        }
        resetDeepLink = true;
        break;
      case HotelBookingConstant.HOTEL_CANCELLATION:
        cardAndAction = getCardAndActionFromAllCards(this.state.responseJson, HotelBookingConstant.ACTION_CANCEL_FULL_BOOKING);
        if (cardAndAction.card && cardAndAction.action) {
          isDeepLinkHandled = true;
          handleClickEvent(cardAndAction.card, {...cardAndAction.action, actionHandler: this.handleOpenBottomOverlay}, this.state.responseJson, this.trackPageName, true, this.userInfo);
        }
        resetDeepLink = true;
        break;
      case HotelBookingConstant.HOTEL_PICS:
        cardAndAction = getCardAndActionFromAllCards(this.state.responseJson, HotelBookingConstant.ACTION_PROPERTY_DETAILS);
        if (cardAndAction.card && cardAndAction.action) {
          isDeepLinkHandled = true;
          handleClickEvent(cardAndAction.card, cardAndAction.action, this.state.responseJson, this.trackPageName);
        }
        resetDeepLink = true;
      case HotelBookingConstant.HOTEL_ROOM_DETAILS:
        cardAndAction = getCardAndActionFromAllCards(this.state.responseJson, HotelBookingConstant.ACTION_PROPERTY_DETAILS);
        if (cardAndAction.card && cardAndAction.action) {
          isDeepLinkHandled = true;
          handleClickEvent(cardAndAction.card, cardAndAction.action, {...this.state.responseJson, deepLinkPage: this.deepLinkPage}, this.trackPageName);
        }
        resetDeepLink = true;
        break;
      case HotelBookingConstant.HOTEL_READPOLICY_SCROLL:
        cardAndAction = getCardAndActionFromAllCards(this.state.responseJson, HotelBookingConstant.ACTION_VIEW_HOUSE_RULES);
        if (cardAndAction.card && cardAndAction.action) {
          isDeepLinkHandled = true;
          handleClickEvent(cardAndAction.card, cardAndAction.action, this.state.responseJson, this.trackPageName);
        }
        resetDeepLink = true;
      break;
      case HotelBookingConstant.HOTEL_MODIFY_SCROLL:
        isDeepLinkHandled = true;
        resetDeepLink = true;
      break;
      default:
        break;
    }
    if (resetDeepLink) {
      this.deepLinkPage = ''; // Reset the Deeplink once evaluated.
    }
    return isDeepLinkHandled;
  }



  showBnplChargeCardOverlay() {
    if (this.state.PAHOverlay) {
      return (
        <PAHBottomOverlay
          handleCloseOverlay={this.handleCloseOverlay}
          navigation={this.props.navigation}
          responseJson={this.state.responseJson}
          pageName={this.trackPageName}
        />
      );
    }
    return null;
  }

  showCallbackOverlay = () => {
    if(this.state.callbackOverlay === NEED_MORE_HELP.REQUEST_CALLBACK) {
      return (<NeedMoreHelpOverlay
        bookingId={this.bookingId}
        handleBottomOverlay={this.handleRCBottomOverlay}
        handleReload={this.handleReload}
        component={NEED_MORE_HELP.REQUEST_CALLBACK}
        pageName={NEED_HELP_PAGE.HOTEL_BOOKING_DETAILS}
        trackEvent={this.trackHelpingHandEvents}
        callDrivers={this.callDrivers}
      />);
    }
  }

  showHelpingHandOverlay = () => {
    const { callbackOverlay, isHelpingHandEnabled, responseJson, helpingHandPage } = this.state;
    if (callbackOverlay === NEED_MORE_HELP.HELPING_HAND && isHelpingHandEnabled) {
      return (
        <NeedMoreHelpOverlay
          bookingId={responseJson ? responseJson.bookingID : ''}
          handleBottomOverlay={this.handleRCBottomOverlay}
          handleReload={this.handleReload}
          component={NEED_MORE_HELP.HELPING_HAND}
          pageName={helpingHandPage}
          hideLoader={true}
          trackEvent={this.trackHelpingHandEvents}
        />
      )
    }
  }

  showIssueAtHotelCardOverlay = () => {
    if (this.state.issueAtHotelOverlay === ISSUE_AT_HOTEL_OVERLAY.ISSUE_RESOLUTION) {
      this.setState({issueAtHotelOverlay: ''});
      this.onCallbackClick();
    } else if(this.state.issueAtHotelOverlay) {
      return (<IssueAtHotelOverlay
              bookingId={this.bookingId}
              pageName={this.trackPageName}
              responseJson={this.state.responseJson}
              handleIssueAtHotelCard={this.handleIssueAtHotelCard}
              updateHotelOverlayType={this.updateHotelOverlayType}
              handleCloseOverlay={this.handleCloseOverlay}
              component={this.state.issueAtHotelOverlay}
            />);
    }
    return null;
  }

  showCallbackBottomSheet = () => {
    return this.state.callbackBottomSheet && <ReqstScheduleBottomInfoCard
      handleCallbackBottomSheet={this.handleCallbackBottomSheet}
      handleTime={this.handleReload}
      bookingId={this.bookingId}
      pageName={NEED_HELP_PAGE.HOTEL_BOOKING_DETAILS}
      trackEvent={this.trackHelpingHandEvents}
      callDrivers={this.callDrivers}
    />
  }

  onCallbackClick = () => {
    this.setState({callbackOverlay: NEED_MORE_HELP.REQUEST_CALLBACK});
  }

  showHelpingHand = (pageName) => {
    this.setState({
      callbackOverlay: NEED_MORE_HELP.HELPING_HAND,
      helpingHandPage: pageName,
      isHelpingHandEnabled: true,
    });
  }

  handleRCBottomOverlay = (showOverlay) => {
    this.setState({
      callbackOverlay: showOverlay
    });
  }

  handleCallbackBottomSheet = () => {
    this.setState({
      callbackBottomSheet: false,
      callbackOverlay: ''
    })
  }

  handleReload = () => {
    this.setState({
      callbackOverlay: '',
      callbackBottomSheet: true
    });
    //this.reloadPageWithNewBookingId(this.bookingId, true);
  }

  trackHelpingHandEvents = (type, page, click, data) => {
    trackHelpingHandEvent(type, page, click, data, this.trackPageName, this.state.responseJson);
  }

  showProceedForCancellationOverlay = () => {
    if (this.state.bottomOverlay === HOTEL_DETAILS_BOTTOM_OVERLAY.PROCEED_FOR_CANCELLATION) {
      return (
        <NewCommonOverlay
          handleBottomOverlay={this.handleCloseBottomOverlay}
        >
          <View style={[AtomicCss.whiteBg, AtomicCss.borderTopRadius16]}>
            <ProceedForCancellationOverlay
              response={this.state.responseJson}
              userInfo={this.userInfo}
              handleBottomOverlay={this.handleCloseBottomOverlay}
            />
          </View>
        </NewCommonOverlay>
      )
    }
    return null;
  }

  showInsuranceBenefitsOverlay = () => {
    if (this.state.bottomOverlay === HOTEL_DETAILS_BOTTOM_OVERLAY.INSURANCE_BENEFITS) {
      const { insuranceOfferCard = {} } = this.state.responseJson;
      return (
        <NewCommonOverlay handleBottomOverlay={this.handleCloseBottomOverlay}>
            <View style={[AtomicCss.whiteBg, AtomicCss.borderTopRadius16]}>
              <InsuranceBenefitsOverlay response={insuranceOfferCard} />
            </View>
        </NewCommonOverlay>
      )
    }
    return null;
  }

  showCancellationPolicyOverlay = () => {
    if (this.state.bottomOverlay === HOTEL_DETAILS_BOTTOM_OVERLAY.CANCELLATION_POLICY) {
      return (
        <NewCommonOverlay
          handleBottomOverlay={this.handleCloseBottomOverlay}
        >
          <View style={[AtomicCss.whiteBg, AtomicCss.borderTopRadius16]}>
            <CancellationPolicyOverlay
              showDismiss
              showCancel
              dismissClickHandler={this.handleCloseBottomOverlay}
              cancelClickHandler={this.cancelClickHandler}
              cancellationPolicyCardInfo={this.state.responseJson.cancellationPolicyCardInfo}
              isRefund={!isNonRefundable(this.state.responseJson)}
              hotelDetailsResponse={this.state.responseJson}
            />
          </View>
        </NewCommonOverlay>
      )
    }
    return null;
  }

  downloadETicketWithMarkUp = (markup, isCancellationPolicySelected, markupDetails, markupselected) => {
    downloadFile(this.state.responseJson, HotelBookingDetailsConstant.MY_PARTNER_CUSTOMER_VOUCHER, '', markup, isCancellationPolicySelected, markupDetails, markupselected);
  }

  showCustomerBookingConfirmation = () => {
    const { customerConfirmPopupText } = getStaticData(false);
    if (this.state.bottomOverlay === HotelBookingConstant.ACTION_DOWNLOAD_CUSTOMER_VOUCHER) {
      const priceBreakupData = this.getPriceBreakUpData();
      return (
        <CommonBottomOverlayMessage
          overlayWrapperStyle={this.styles.overlayWrapperStyle}
          overlayContentStyle={[this.styles.OverlayContent, { bottom: this.state.overlayPosition }]}
          content={
            <CustomerBookingConfirm
              handleClick={this.handleCloseBottomOverlay}
              {...priceBreakupData}
              {...customerConfirmPopupText}
              downloadEticket={this.downloadETicketWithMarkUp}
              trackHelper={this.trackHelperCustomerConfirmPopUp}
              bookingid={this.state.responseJson ? this.state.responseJson.bookingID : ''}
            />
          }
          dismiss={this.handleCloseBottomOverlay}
        />
      );
    }
    return null;
  }

  cancelClickHandler = () => {
    this.handleCloseBottomOverlay();
    openCancellationPreview(this.state.responseJson,this.userInfo);
  }

  showChoosePaymentMode = () => {
    if (this.state.bottomOverlay === HOTEL_DETAILS_BOTTOM_OVERLAY.CHOOSE_PAYMENT_MODE_BNPL) {
      return (
        <NewCommonOverlay
          handleBottomOverlay={this.handleCloseBottomOverlay}
          customStyle={AtomicCss.borderTopRadius16}
        >
          <Animated.View style={[AtomicCss.whiteBg,{ bottom: this.state.overlayPosition }]}>
            <ChoosePaymentMode
              response={this.state.responseJson}
              pageName={this.trackPageName}
            />
          </Animated.View>
        </NewCommonOverlay>
      )
    }
    return null;
  }

  showInstallmentDetailsOverlay = () => {
    this.setState({
      showInstallmentOverlay: true
    })
  }

  hideInstallmentDetailsOverlay = () => {
    this.setState({
      showInstallmentOverlay: false
    })
  }

  installmentDetailsOverlay = () =>{
    const {showInstallmentOverlay, responseJson } = this.state;
    const { installmentDetails } = !isEmpty(responseJson) ? responseJson : {};
    const { bottomCard } = !isEmpty(installmentDetails) ? installmentDetails : {};
    if(showInstallmentOverlay && !isEmpty(bottomCard)) {
      return (
        <InstallmentDetails
          cardData={bottomCard}
          response={responseJson}
          pageName={this.trackPageName}
          dismiss={this.hideInstallmentDetailsOverlay} />
      )
    }
    return null;
  }

  showGBModificationOverlay = (actionFamily) => {
    const { modifyRoom, modifyRoomMsg, changeDates, changeDatesMsg, addMeals, addMealsMsg, callMMT } = getStaticData(true);
    let showGBModificationOverlayData;
    let trackClickName = '';
    switch (actionFamily) {
      case HotelBookingConstant.ACTION_GB_ADD_GUEST:
        trackClickName = "gb_modify_guest_click";
        showGBModificationOverlayData = {
          heading: modifyRoom,
          subHeading: modifyRoomMsg
        }
        break;
      case HotelBookingConstant.ACTION_GB_CHANGE_DATES:
        trackClickName = "gb_date_change_click";
        showGBModificationOverlayData = {
          heading: changeDates,
          subHeading: changeDatesMsg
        }
        break;
      case HotelBookingConstant.ACTION_GB_ADD_MEAL:
        trackClickName = "gb_add_meal_click"
        showGBModificationOverlayData = {
          heading: addMeals,
          subHeading: addMealsMsg
        }
        break;
    }
    if(showGBModificationOverlayData) {
      this.setState({
        showGBModificationOverlayData: {
          ...showGBModificationOverlayData,
          ctaText: callMMT,
          ctaHandler: () => {
            openDialer(HotelBookingConstant.DEFAULT_MMT_NUMBER);
          }
        }
      })
      HotelBookingTrackingHelper.trackClickEvent(this.trackPageName, trackClickName, this.state.responseJson);
    }

  }

  hideGBModificationOverlay = () => {
    this.setState({
      showGBModificationOverlayData: undefined
    })
  }

  GBModificationOverlay = () => {
    if (this.state.showGBModificationOverlayData) {
      return(<GBModificationOverlay {...this.state.showGBModificationOverlayData} dismiss={this.hideGBModificationOverlay}/>)
    }
    return null;
  }

  showGBStayConnectedOverlay = (contactStr, title) => {
    this.setState({
      showGBContactOverlay: true,
      stayConnectedTitle: title,
      contactStr
    })
  }

  hideGBStayConnectedOverlay = () => {
    this.setState({
      showGBContactOverlay: false
    })
  }

  stayConnectedOverlay = () => {
    if (this.state.showGBContactOverlay && this.state.contactStr) {
      return(<ContactPropertyOverlay dismiss={this.hideGBStayConnectedOverlay} title={this.state.stayConnectedTitle} contactStr={this.state.contactStr}/>)
    }
    return null;
  }

  showPanDetailOverLay = () => {
    if (this.state.panDetailOverlay) {
      return (<PanDetailOverlay
        dismiss={() => {
          this.setState({
            panDetailOverlay: false,
          });
        }}
        bookingResponse={this.state.responseJson}
        />);
    }
    return null;
  }

  handleOpenBottomOverlay = (overlay) => {
    this.setState({
      bottomOverlay: overlay
    });

    Animated.timing(this.state.overlayPosition, {
      toValue: 0,
      easing: Easing.easeInOut,
      duration: 300,
      delay: 100,
    }).start();
  }

  handleCloseBottomOverlay = () => {
    setTimeout(() => {
      this.setState({
        bottomOverlay: ''
      })
    }, 400);

    Animated.timing(this.state.overlayPosition, {
        toValue: -1000,
        easing: Easing.easeInOut,
        duration: 300,
        delay: 100,
    }).start();
  }

  updateHotelOverlayType = (overlayType, callDrivers) => {
    this.callDrivers = callDrivers;
    this.setState({
        issueAtHotelOverlay:overlayType
    });
  }

  resetUnreadCount() {
    this.state.responseJson.hostDetails.unreadCount = 0;
    this.setState({ responseJson: {
            ...this.state.responseJson,
            hostDetails: {
                ...this.state.responseJson.hostDetails,
                unreadCount: 0
               }
            }
          });
  }

  isUnreadMessageFromHostPresent() {
    return (
      this.state.responseJson.hostDetails &&
      this.state.responseJson.hostDetails.unreadCount > 0
    );
  }

  buildCTripHeaderTxt = () => {
    const staticData = getStaticData();
    if (
      this.state.responseJson.documentBookingStatus &&
      this.state.responseJson.documentBookingStatus === 'InstantUnconfirmed'
    ) {
      return staticData.hotelBookingDetailsText.additionalText.topStatusCardText
        .yourBookingIsConfirmedPnrToBeGeneratedText;
    }
    return staticData.hotelBookingDetailsText.additionalText.topStatusCardText.yourBookingIsAwaitingConfirmationText;
  };

  setDownloadInvoiceState = () => {
    if(Platform.OS === 'android')  {
      this.deepLinkPage = HotelBookingDetailsConstant.HOTEL_DOWNLOAD_INVOICE_POPUP
      HotelBookingModule.checkStoragePermission();

    }
    else {
    this.setState({showDownloadInvoiceOverlay: true});
    }
  }

  handlePAHCard= async () => {

    const { isBNPLBookingWithoutAutoCharge, isBNPLBookingUPIAutodebit, bookAtZero } = this.state.responseJson;

    if(isBNPLBookingWithoutAutoCharge || isBNPLBookingUPIAutodebit || bookAtZero ) {
      const staticData = getStaticData()
      const { paymentBreakup, bookingID, showBankOfferCard = false } = this.state.responseJson || {};
      const { bnplCardChargeInfo } = paymentBreakup || {}
      const { PAY_DIFFERENT_MODE_CLICKED, PAY_DIFFERENT_MODE_WITH_BANK_OFFER_CLICKED } = HotelBookingDetailsConstant
      const eventValue = showBankOfferCard ? PAY_DIFFERENT_MODE_WITH_BANK_OFFER_CLICKED : PAY_DIFFERENT_MODE_CLICKED
      HotelBookingTrackingHelper.trackClickEvent(this.trackPageName, eventValue, this.state.responseJson);
      const userInfo = await HotelBookingModule.getRequestHeader(bookingID, `BNPL_PAYMENT_${staticData.payNowText}`);
      if(isMweb()) {
        window.location.href = bnplCardChargeInfo?.otherPaymodeLink;
      } else {
       isEasyPayNativeFlowEnabled()
         ? GenericModule.openDeepLink(bnplCardChargeInfo?.otherPaymodeLink)
         : Actions.openPsWebView({
             url: bnplCardChargeInfo?.otherPaymodeLink,
             headerText: staticData.payNowText,
             headerIcon: WHITE_LEFT_ARROW_ICON,
             injectedJavaScript: setWebViewAuth(userInfo.mmtAuth),
           });
      }
    } else {
      HotelBookingTrackingHelper.trackClickEvent(this.trackPageName, 'Pay_Now_Clicked', this.state.responseJson);
      this.handleOpenBottomOverlay(HOTEL_DETAILS_BOTTOM_OVERLAY.CHOOSE_PAYMENT_MODE_BNPL)
    }
  }
  handleMessageHost = (chatURL) => {
      this.resetUnreadCount()
      openHostChatWeb(chatURL);
  }
  handleIssueAtHotelCard=(source, pageName, openWebView) => {
    const response = this.state.responseJson || {};
    const {bhfBanner} = response;
    let shouldOpenWebView =  !bhfBanner['showCheckInDeniedFlow'] || openWebView;
    if(shouldOpenWebView){
        const { formUrl, webViewText } = bhfBanner || {};
        handleBHFFormBannerClick(formUrl, source, pageName, response, webViewText)
    }else{
        HotelBookingTrackingHelper.trackClickEvent(this.trackPageName, 'IssueAtHotelsClicked', response);
        this.setState({
          PAHOverlay: false,
          issueAtHotelOverlay: ISSUE_AT_HOTEL_OVERLAY.ISSUE_SELECTION,
          viewState: ViewState.SHOW_DETAIL
        });
    }
  }
  handleCloseOverlay=() => {
    this.setState({
      PAHOverlay: false,
      issueAtHotelOverlay: '',
      bottomOverlay: ''
    });
  }

  clearDeeplinkPnr = () => {
    this.pnr = '';
  }

  shouldBlockOnlineModification = () => {
    const isInternationalBooking = this.state.responseJson.isInternational;
    return this.userInfo && this.userInfo.blockODCLobs &&
      ((!isInternationalBooking && this.userInfo.blockODCLobs.includes('HOTEL')) ||
        (isInternationalBooking && this.userInfo.blockODCLobs.includes('INTL_HOTEL'))
      );
  }

  onHardBackPress = () => {
    if (this.state.PAHOverlay) {
      this.handleCloseOverlay();
      return true;
    }
    return false;
  };
}

const mapStateToProps = (storeState) => {
  const {resetBookingId, pageNameForHelpingHand } = storeState.hotelDetailReducer;
  return {
    resetBookingId,
    pageNameForHelpingHand
  };
};

const mapDispatchToProps = (dispatch) => ({
  resetHotelDetail: (resetBookingId) =>
    dispatch(
      createAction(
        HOTEL_DETAIL_ACTIONS.RESET_HOTEL_BOOKING_DETAIL,
        resetBookingId,
      ),
    ),
  showHelpingHand: pageNameForHelpingHand =>
    dispatch(
      createAction(
        HOTEL_DETAIL_ACTIONS.SHOW_HELPING_HAND,
        pageNameForHelpingHand
      )
    ),
  updateBookingDetails: body =>
    dispatch(
      createAction(
        HOTEL_DETAIL_ACTIONS.HOTEL_DETAILS_API_SUCCESS_ENG,
        body
      )
    )
});
const ConnectedHotelBookingDetail =(props)=>{
  return <HotelBookingDetail {...props} />
}

export default withRouterWrapper(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedHotelBookingDetail),
);
