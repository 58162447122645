import React, { useState } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Image } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {
  acIcon,
  luggageIcon,
  seatIcon1,
  whiteStar,
} from '../../../../CabImageConstants';
import { CabDetailsProps } from '../../../../types';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import createStyles from './CabDetailsCss';
import isEmpty from 'lodash/isEmpty';

const CabDetails: React.FC<CabDetailsProps> = (props) => {
  const {
    carBrand,
    vendorRating,
    carType,
    fuelType,
    cabUrl,
    seatText,
    acText,
    luggageText,
    rydeBannerData,
  } = props.cabDetailInfo || {};
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);

  const { iconUrl } = rydeBannerData || {};
  return (
    <View style={styles.cabDetailsWrapper}>
      <View style={AtomicCss.flex1}>
        <View
          style={[
            AtomicCss.flexRow,
            AtomicCss.alignCenter,
            AtomicCss.marginBottom5,
          ]}
        >
          {!isEmpty(carBrand)&& (
            <Text
              style={[
                AtomicCss.blackFont,
                AtomicCss.font18,
                AtomicCss.blackText,
                AtomicCss.width73
              ]}
            >
              {carBrand}
            </Text>
          )}
          {!!vendorRating && (
            <View style={styles.ratingWrapper}>
              <Text
                style={[
                  AtomicCss.whiteText,
                  AtomicCss.blackFont,
                  AtomicCss.font12,
                ]}
              >
                {vendorRating}
              </Text>
              <Image style={styles.starRatingStyle} source={whiteStar} />
            </View>
          )}
        </View>
        {!isEmpty(carType) && (
          <Text
            style={[
              AtomicCss.defaultText,
              AtomicCss.font14,
              AtomicCss.font12,
              AtomicCss.marginBottom20,
              AtomicCss.italicFont,
            ]}
          >
            {carType}
          </Text>
        )}
        <View style={[AtomicCss.flexRow]}>
          {!isEmpty(seatText) && (
            <View
              style={[
                AtomicCss.flexRow,
                AtomicCss.alignCenter,
                AtomicCss.marginRight16,
              ]}
            >
              <View style={styles.carInnerImageWrapper}>
                <Image style={styles.iconStyle} source={seatIcon1} />
              </View>
              <Text
                style={[
                  AtomicCss.defaultText,
                  AtomicCss.font12,
                  AtomicCss.regularFont,
                ]}
              >
                {seatText}
              </Text>
            </View>
          )}
          {!isEmpty(luggageText) && (
            <View
              style={[
                AtomicCss.flexRow,
                AtomicCss.alignCenter,
                AtomicCss.marginRight16,
              ]}
            >
              <View style={styles.carInnerImageWrapper}>
                <Image style={styles.iconStyle} source={luggageIcon} />
              </View>
              <Text
                style={[
                  AtomicCss.defaultText,
                  AtomicCss.font12,
                  AtomicCss.regularFont,
                ]}
              >
                {luggageText}
              </Text>
            </View>
          )}
          {!isEmpty(acText) && (
            <View
              style={[
                AtomicCss.flexRow,
                AtomicCss.alignCenter,
                AtomicCss.marginRight16,
              ]}
            >
              <View style={styles.carInnerImageWrapper}>
                <Image style={styles.iconStyle} source={acIcon} />
              </View>
              <Text
                style={[
                  AtomicCss.defaultText,
                  AtomicCss.font12,
                  AtomicCss.regularFont,
                ]}
              >
                {acText}
              </Text>
            </View>
          )}
        </View>
      </View>
      <View style={styles.carTypeWrapper}>
        <Image style={styles.rideImageStyle} source={{ uri: iconUrl }} />
        <Image
          style={[styles.carImageStyle, AtomicCss.marginBottom10]}
          source={{ uri: cabUrl }}
        />
        {!isEmpty(fuelType) && (
          <View style={styles.carType}>
            <Text
              style={[
                AtomicCss.blackFont,
                AtomicCss.whiteText,
                AtomicCss.font11,
                AtomicCss.textCenter,
              ]}
            >
              {fuelType}
            </Text>
          </View>
        )}
      </View>
    </View>
  );
};

export default CabDetails;
