import { StyleSheet } from 'react-native';
import { colors, fonts } from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';

const styles = StyleSheet.create({
  cardWrapper: {
    backgroundColor: colors.white,
    elevation: 3,
    ...getPlatformElevation(2),
    marginBottom: 12,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: colors.lightGrey,
  },
  statusHeadText: {
    fontSize: 16,
    color: colors.black,
    fontFamily: fonts.bold,
    lineHeight: 24,
    marginBottom: 2,
  },
  smallText: {
    fontSize: 13,
    color: colors.defaultTextColor,
    fontFamily: fonts.regular,
    lineHeight: 20,
  },
  checkAllWrapperInner: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 12,
    paddingHorizontal: 16,
  },
  sectorHeader: {
    flexShrink: 1,
  },
  seperatorWrapper: {
    paddingHorizontal: 20,
    overflow: 'hidden',
    marginBottom: 6,
  },
  uploadWrapper: {
    paddingHorizontal: 8,
  },
});

export default styles;
