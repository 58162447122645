import { trackOmniture } from '@mmt/legacy-commons/Common/utils/OmnitureTrackerUtils';
import {getNoOfTravellers} from '../data/dataAdapter';

class FlightListingTrackingHelper {
  trackLoadEvent = (pageName, metaData, bookingId) => {
    try {
      let params = {};
      params = this.getTrackingOmniture(pageName, metaData, bookingId);
      trackOmniture(pageName, params);
    }catch(error){
      console.log("Error in track Load Event", error);
    }
  
  };

  getTrackingOmniture(pageName, metaData, bookingId) {
    try {
      const params = {};
    params.pageName = pageName;
    params.m_v15 = pageName;
    params.m_v39 = 'Y';
    if (bookingId) {
      params.m_v16 = bookingId;
    }
    if (metaData != null && metaData.searchCriteria != null) {
      params.m_c4 = getNoOfTravellers(metaData.searchCriteria);
      params.m_c3 = this.getRoute(metaData);
      params.m_c19 = this.getBookingType(metaData);
      params.m_c12 = this.getAirlineAndTravelClass(metaData);
    } else {
      params.m_c4 = 0;
      params.m_c3 = '';
      params.m_c19 = '';
      params.m_c12 = '';
    }
    return params;
    }catch(error){
      console.log("Error in getTrackingOmniture Event", error);
    }
  }

  trackClickEvent = (pageName, clickEvent) => {
    try {
      const params = {};
      params.pageName = pageName;
      params.m_v15 = pageName;
      params.m_c54 = clickEvent + 'clicked';
      trackOmniture(pageName, params);
    }catch(error){
      console.log("Error in trackClick Event", error);
    }
   
  };

  trackClickEventForListingPopup = (pageName, clickEvent, airlineName, isFdcApplicable) => {
    try {
        const params = {};
        params.pageName = pageName;
        params.m_v15 = pageName;
        params.m_c54 = clickEvent;
        params.m_c40 = airlineName;
        params.m_c1 = isFdcApplicable ? 'fdc_active' : '';
        trackOmniture(pageName, params);
    }catch(error){
      console.log("Error in trackClick Event", error);
    }
    
  };

  trackErrorEvent = (pageName, message, supplier) => {
    try {
      const params = {};
    params.pageName = pageName;
    params.m_v15 = pageName;
    params.m_c22 = message;
    if (supplier) {
      params.m_c40 = supplier;
    }
    trackOmniture(pageName, params);
    }catch(error){
      console.log("Error in trackClick Event", error);
    }
    
  };

  getBookingType(meta) {
    try {
    if (meta.searchCriteria.itineraryList != null) {
      if (meta.searchCriteria.itineraryList.length < 2) {
        return 'One Way';
      } else if (meta.searchCriteria.itineraryList.length === 2) {
        return 'Round Trip';
      }
      return 'Multicity';
    }
    return 'One Way';
  }catch(error){
    console.log("Error in getBookingType", error);
  }
  }

  getRoute(meta) {
    try {
      let route = '';
      if (meta.searchCriteria.itineraryList != null) {
        for (const itinerary in meta.searchCriteria.itineraryList) {
          route += `${itinerary.from}_${itinerary.to}|`;
        }
      }
      return route;
    }catch(error){
      console.log("Error in getRoute", error);
    }
    
  }

  getAirlineAndTravelClass(meta) {
    try {
    let code = '';
    if (meta.originalAirline != null) {
      code += `_${meta.originalAirline}`;
    }
    if (meta.cabinClass != null) {
      code += `_${meta.cabinClass}`;
    }
    return code;
  }catch(error){
    console.log("Error in getAirlineAndTravelClass", error);
  }
}
}

export default new FlightListingTrackingHelper();
