import COMMON_PATHS from "./Routes/Common";
import FLIGHTS_PATHS from "./Routes/Flights";
import HOTELS_PATHS from "./Routes/Hotels";
import SELF_DRIVE_PATHS from "./Routes/SelfDrive";
import RAIL_PATHS from "./Routes/Rails";
import HOLIDAYS_PATHS from "./Routes/Holidays";
import VISA_PATHS from "./Routes/Visa";
import ACME_PATHS from './Routes/Acme';
import CAB_PATHS from './Routes/Cabs';
import BUS_PATHS from './Routes/Bus';
import TRIP_GUARANTEE_PATHS from "./Routes/TripGuarantee";
import TM_INSURANCE_PATHS from './Routes/TmInsurance';
import ESCALATE_PATH from "./Routes/Escalate";
const ROUTE_PATHS = {
    ...COMMON_PATHS,
    ...FLIGHTS_PATHS,
    ...HOTELS_PATHS,
    ...SELF_DRIVE_PATHS,
    ...RAIL_PATHS,
    ...HOLIDAYS_PATHS,
    ...VISA_PATHS,
    ...ACME_PATHS,
    ...CAB_PATHS,
    ...BUS_PATHS,
    ...TRIP_GUARANTEE_PATHS,
    ...TM_INSURANCE_PATHS,
    ...ESCALATE_PATH
}

export { ROUTE_PATHS, FLIGHTS_PATHS, HOTELS_PATHS, SELF_DRIVE_PATHS, RAIL_PATHS, HOLIDAYS_PATHS, VISA_PATHS, ACME_PATHS, CAB_PATHS,BUS_PATHS, ESCALATE_PATH  };
