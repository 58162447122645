import React from 'react';
import { View } from 'react-native';
import ShareOtpSection from '../ShareOtpSection';
import { CabDriverAssignedProps } from '../../../../types';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import createStyles, { cardStyles } from './CabDriverAssignedCss';
import DriverDetailCard from './DriverDetailCard';
import * as Animatable from 'react-native-animatable';

const CabDriverAssigned: React.FC<CabDriverAssignedProps> = (props) => {
  const {
    cardData,
    showCabTrackerOverlay,
    isSubCard,
    setCardHeight,
    isTransitioning,
    isCurrentEventTripstarted,
  } = props;
  const { driverDetailsInfo, showTrackerCard } = cardData || {};
  const { startOtpText, endOtpText, showStartOtp } = driverDetailsInfo || {};

  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);

  const getOtpText = () => {
    if (showStartOtp && !!startOtpText && !!endOtpText) {
      return `${startOtpText}   |   ${endOtpText}`;
    } else if (showStartOtp && !!startOtpText) {
      return startOtpText;
    } else if (!!endOtpText) {
      return endOtpText;
    }
    return '';
  };

  const otpText = getOtpText();

  return isCurrentEventTripstarted ? (
    <Animatable.View
      animation={isTransitioning ? 'slideInDown' : ''}
      duration={1000}
      style={[
        cardStyles.card,
        showCabTrackerOverlay ? cardStyles.cardAbsolute : cardStyles.cardRelative,
      ]}
      onLayout={(e) => setCardHeight(e.nativeEvent.layout.height)}
      useNativeDriver
    >
      <ShareOtpSection
        customStyle={!isSubCard && showTrackerCard ? styles.otpCustomStyle : {}}
        otpText={otpText}
      />
      <View style={!!otpText ? styles.whiteCardNeg20 : {}}>
        <DriverDetailCard {...props} />
      </View>
    </Animatable.View>
  ) : (
    <View
      style={[
        cardStyles.card,
        showCabTrackerOverlay ? cardStyles.cardAbsolute : cardStyles.cardRelative,
      ]}
      onLayout={(e) => setCardHeight(e.nativeEvent.layout.height)}
    >
      <ShareOtpSection
        customStyle={!isSubCard && showTrackerCard ? styles.otpCustomStyle : {}}
        otpText={otpText}
      />
      <View style={!!otpText ? styles.whiteCardNeg20 : {}}>
        <DriverDetailCard {...props} />
      </View>
    </View>
  );
};

export default CabDriverAssigned;
