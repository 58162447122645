import React from 'react';
import {Text, TouchableOpacity,View,Image, NativeModules} from 'react-native';
import createStyles from './RectBookFullCSS';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss'
import {RIGHT_ARROW} from "../../../../Utils/tripGuaranteeImageConstants"
import {isEmpty} from 'lodash'
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import { getFont } from '../../../../../PostSalesStyles';
import { useTheme } from '../../../../../theme/theme.context';
import TGSBookingTrackEventHelper from '../../../../Utils/TGSBookingTrackEventHelper';
import { PAGE_NAME } from '../../../../Utils/TGSBookingConstants';
import { getClickType } from '../../../../Utils/TgsBookingDetailUtil';
import { getGdprData, shouldShowGdprBlockerScreen, isGroundLob } from '../../../../../utils/PostSaleUtil';
import { getStaticData } from '../../../../../staticData/staticData';
const RectBookFullCard = ({card}) => {
  const {heading,lobDeepLink,icon, key} = card || {};
  const {url} = icon ||{};
  const fonts = getFont();
  const {psTheme : theme} = useTheme();
  const styles = createStyles(theme,fonts);
  const handleClick = async () => {

     const { UserSessionModule } = NativeModules;
     const showGdprBlockerScreen = await shouldShowGdprBlockerScreen();
     if (showGdprBlockerScreen && isGroundLob(key)) {
      const { gdprText } = getStaticData(false);
      const { lobs: { groundTransport = 'Ground Transport'} = {} } = gdprText || {} 
      UserSessionModule.openGDPRBlockerScreen(groundTransport, null, getGdprData(gdprText));

     } else {
        const clickType = getClickType(key);
        TGSBookingTrackEventHelper.trackClickEvents(PAGE_NAME.TRIP_DETAILS, clickType);
        if(!isEmpty(lobDeepLink)){
           GenericModule.openDeepLink(lobDeepLink);
        }
     }
  }
  return(
  <TouchableOpacity style={[styles.linkBtn]} onPress={handleClick}>
  <View style={[AtomicCss.flex1,AtomicCss.flexRow,AtomicCss.alignCenter]}>
      <View style={styles.imageWrapper}>
         {!!url &&  <Image style={styles.IconStyle} source={{uri:url}} />}
      </View>
     {!!heading && <Text style={[AtomicCss.font12,fonts.regularFontFamily, AtomicCss.blackText]}>{heading}</Text> }
  </View>
  <View >
  <Image style={styles.arrowStyle} source={RIGHT_ARROW}></Image>
  </View>
</TouchableOpacity>)

}
export default RectBookFullCard;
