import _, { forEach, isEmpty } from 'lodash';
import { WEBCHECKIN, WEBCHECKIN_TRAVELLER_FORM } from '../constants';
import { Platform } from 'react-native';
import { TY_WEBCHCEKIN_STATES } from '../constants';
import {
  GREEN_TICK_ICON2,
  CLOCK_GREEN_ICON,
  CROSS_RED_CIRCLE_ICON,
} from '../../../FlightImageConstants';


export const getSelectedCountry = (uniquePassengers) => {
  return uniquePassengers.length > 0 ? uniquePassengers[0].fields.COUNTRY_NAME.fieldValue : '';
};

export const getFilteredPassengers=(passengers,selectedSegments,selectedAncillaries,fromWebCheckin)=>{
  let pax=[]
  if(fromWebCheckin){
  if(isEmpty(selectedSegments)){
    return passengers;
  }
 pax= passengers.filter((pax)=>{
    return   selectedSegments.findIndex((seg)=>seg.selected &&(seg.segmentLineNo == pax.segmentLineNo)) != -1
  })
}
else{
  if(isEmpty(selectedAncillaries)){
    return passengers;
  }
  pax= passengers.filter((pax)=>{
    let arr =  [];
    if(!isEmpty(pax) &&  !isEmpty(selectedAncillaries[pax.segmentLineNo])
     && !isEmpty(selectedAncillaries[pax.segmentLineNo][WEBCHECKIN])){
      arr = selectedAncillaries[pax.segmentLineNo][WEBCHECKIN];
    }
    return arr && arr.some((seg)=>seg.selected)
  }) 
}

  return pax;
}

export const getSelectedCountryCode = (uniquePassengers) => {
  return uniquePassengers.length > 0 ? uniquePassengers[0].fields.COUNTRY_CODE.fieldValue : '';
};

export const getSelectedNationality = (uniquePassengers) => {
  return uniquePassengers.length > 0 ? uniquePassengers[0].fields.NATIONALITY.fieldValue : '';
};

export const checkSeatSelectionTypes = (selectedAncillaries) => {
  let autoSeat = false;
  let manualSeat = false;
  let type = [];
  if (!_.isEmpty(selectedAncillaries)) {
    Object.keys(selectedAncillaries).forEach((segment) => {
      if (selectedAncillaries[segment] && !_.isEmpty(selectedAncillaries[segment][WEBCHECKIN])) {
        for (let i = 0; i < selectedAncillaries[segment][WEBCHECKIN].length; i += 1) {
          if (!autoSeat && selectedAncillaries[segment][WEBCHECKIN][i].autoSeatAssign) {
            autoSeat = true;
          }
          if (!manualSeat && selectedAncillaries[segment][WEBCHECKIN][i].sn) {
            manualSeat = true;
          }
          if (manualSeat && autoSeat) {
            break;
          }
        }
      }
    });
  }
  manualSeat && type.push('Seat');
  autoSeat && type.push('Auto Assign');
  return type.join('|');
};

export const checkAllPaxDetailsUpdated = (passengersToDisplay, uniquePassengers) => {
  if (!_.isEmpty(passengersToDisplay)) {
    let result = true;
    if (uniquePassengers && uniquePassengers.length === 1) {
      return result;
    }
    if (Array.isArray(uniquePassengers)) {
      uniquePassengers.forEach((pax) => {
        if (!pax.updated) {
          result = false;
        }
      });
    }
    return result;
  }
  return false;
};


export const getactiveIndex = ({ paxInfo, uniquePassengers }) => {
  let value = null;
  if (uniquePassengers && uniquePassengers.length) {
    for (let index in uniquePassengers) {
      if (
        uniquePassengers[index].paxLineNo === paxInfo.paxLineNo &&
        uniquePassengers[index].segmentLineNo === paxInfo.segmentLineNo
      ) {
        value = index;
        break;
      }
    }
  }
  return value;
};


export const getPaxData = (passengers, paxInfo) => {
  passengers.forEach((pax) => {
    if (pax.paxReferenceId == paxInfo.paxReferenceId) return pax;
  });
};


export const getNewDeclarationState = (pass) => {
  pass['healthConsent'] = !pass['healthConsent'];
  return pass;
};

export const getSegmentInfo = (ancillaryResponse, segNo) => {
  const { segmentAncillaryDetails } = ancillaryResponse || {};
  let info = {};
  if (segmentAncillaryDetails && segmentAncillaryDetails.length) {
    const filterList = segmentAncillaryDetails.filter((seg) => seg.segmentLineNo == segNo);
    info = filterList && filterList.length ? filterList[0] : {};
  }
  return info;
};

export const getCountryCode = (multiplePax, paxInfo, selectedCountry, selectedCountryCode) => {
  if (multiplePax || Platform.OS !== 'web') {
    return `+${paxInfo.fields.COUNTRY_CODE.fieldValue}`;
  } else {
    return `+${selectedCountryCode}`;
  }
};

export const getEventCytPageName = (thankyouObj) => {
  if (thankyouObj && TY_WEBCHCEKIN_STATES.includes(thankyouObj.status)) {
    let pageName = 'TyWebcheckin ';
    pageName += thankyouObj.addOnSuccess ? 'Success' : thankyouObj.addOnPartial ? 'Part' : 'Fail';

    return pageName;
  } else {
    let pageName = 'Thankyou ';
    pageName += thankyouObj.addOnSuccess
      ? 'Success'
      : thankyouObj.addOnPartial
      ? 'Part'
      : 'Fail';

    return pageName;
  }
};

export const getStatusIcon = (status) => {
  switch (status) {
    case 'SUCCESS':
      return GREEN_TICK_ICON2;
    case 'SCHEDULED':
      return CLOCK_GREEN_ICON;
    case 'FAILED':
      return CROSS_RED_CIRCLE_ICON;
  }
};
