import { colors} from '@mmt/legacy-commons/Styles/globalStyles';

const getStyles = (fonts) => {
    return {
        contentTitle: {
            ...fonts.font34,
            color: colors.black,
            fontFamily: fonts.light,
            marginBottom: 25,
            lineHeight:36,
        },
        bulletIcon: {
            width: 4,
            height: 4,
            marginRight: 10,
            backgroundColor: '#4a4a4a',
            borderRadius: 4,
            top: 6,
        },
        contentDesc: {
            ...fonts.font14,
            color: colors.defaultTextColor,
            fontFamily: fonts.regular,
            lineHeight: 18
        },
        crossWrapper: {
            width: 20,
            height: 20,
            alignItems: 'center',
            position: 'absolute',
            right: 10,
            top: 15,
            zIndex: 1
        },
        closeIconStyle: {
            width: 14,
            height: 14,
        },
        
    };
}

export default getStyles;
