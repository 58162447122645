import { themeColors } from '../../../PostSalesStyles';
import {colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles';

const styles = {
	pageWrapper:{
		flex: 1
	},
	whiteBg:{
		backgroundColor: colors.white
	},
	whiteCardDtls:{
		padding:16,
		marginBottom:70,
		paddingBottom:0
	},
	footerBtnWrapper:{
		paddingTop:5,
		paddingBottom:12,
		paddingHorizontal:12,
		width:'100%'
	},
  	refundCardWrap:{
    	flexDirection:'row',
    	justifyContent: 'space-between'
  	},
  	refundCardDetails:{
    	flex:1,
		flexWrap:'wrap',
  	},
	refundTopDtls:{
		flexDirection:'row',
		flex:1,
		justifyContent:'space-between',
		marginBottom:5
	},
  	radio:{
    	width:18,
    	height:18,
    	backgroundColor: "#fff",
    	borderRadius:20,
    	borderWidth:1,
    	borderColor: themeColors.radioColor,
    	marginRight:20,
    	marginTop:5
  	},
	radioInside:{
    	width:12,
    	height:12,
    	backgroundColor: themeColors.radioColor,
    	borderRadius:20,
    	marginLeft:2,
    	marginTop:2
  	},
  	refundText:{
		color: colors.greyLight,
		fontFamily:fonts.regular,
		flex:1,
		lineHeight:20,
		paddingBottom:5
  	},
	refundLeftDtls:{
		color:colors.black,
		fontFamily:fonts.bold,
		fontSize:16,
		width:'70%'
	},
	refundPriceTxt:{
		fontFamily:fonts.bold,
		color:colors.black,
		fontSize:16,
	},
	radioRow:{
		borderBottomWidth:1,
		borderBottomColor:colors.lightGrey,
		paddingVertical:15,
	},
	lastStyle:{
		borderBottomWidth:0,
		borderBottomColor:'transparent',
	},
	payMoreInfo:{
		borderRadius:4,
		paddingVertical:10,
		paddingHorizontal:13,
		backgroundColor:colors.creamWhite
	},
	payMoreInfoTxt:{
		fontFamily:fonts.regular,
		color:colors.defaultTextColor,
		fontSize:14,
	},
	refundTextWrapper:{
		width: '97%',  
	}
};

export default styles;
