import React from 'react';
import {View, Text, TouchableOpacity, Image} from 'react-native';
import PropTypes from 'prop-types';

import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';
import {fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import {InfoBlueIcon} from '../HolidayBookingConstants';
import {themeColors} from '../../PostSalesStyles';

function DocumentsRequiredCard(props) {
  const {documentPageDetails} = props;
  if (!documentPageDetails) {
    return null;
  }
  if (!Object.keys(documentPageDetails).length) {
    return null;
  }

  return (
    <View style={[styles.whiteCard, styles.innerSection, AtomicCss.marginBottom12]}>
      {documentPageDetails.customerTimeline && (
        <View style={[AtomicCss.flexRow, AtomicCss.marginBottom12]}>
          <View style={styles.cardStatus}>
            <Text style={styles.statusTxt}>{documentPageDetails.customerTimeline}</Text>
          </View>
        </View>
      )}
      <Text
        style={[
          AtomicCss.blackFont,
          AtomicCss.blackText,
          AtomicCss.marginBottom12,
          AtomicCss.font16
        ]}
      >
        {documentPageDetails.heading}
      </Text>
      <Text
        style={[
          AtomicCss.regularFont,
          AtomicCss.defaultText,
          AtomicCss.marginBottom16,
          AtomicCss.font14
        ]}
      >
        {documentPageDetails.text}
      </Text>
      {documentPageDetails.paxDocRelationNewState.map((
        docData // eslint-disable-line arrow-parens
      ) => (
        <View style={[styles.innerDtlsBox, AtomicCss.marginBottom12]}>
          <View style={[styles.headingSection, AtomicCss.marginBottom10]}>
            <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
              <Text style={[AtomicCss.boldFont, AtomicCss.blackText, AtomicCss.font14]}>
                {docData.heading}{' '}
              </Text>
              <Text style={[AtomicCss.regularFont, AtomicCss.defaultText, AtomicCss.font12]}>
                {' '}
                {docData.paxName}
              </Text>
            </View>
          </View>
          <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom5]}>
            <View style={[styles.bulletDesc]} />
            <Text style={[AtomicCss.blackText, AtomicCss.blackFont]}>{docData.docs}</Text>
          </View>
          <Text
            style={[
              AtomicCss.defaultText,
              AtomicCss.font12,
              AtomicCss.regularFont,
              AtomicCss.marginLeft12
            ]}
          >
            {docData.docsConditions}
          </Text>
        </View>
      ))}
      <TouchableOpacity
        activeOpacity={0.8}
        hitSlop={{
          top: 20,
          bottom: 20,
          left: 20,
          right: 20
        }}
        onPress={props.handleOpenWhyDocumentsOverlay}
      >
        <View style={[styles.infoSection, AtomicCss.flexRow, AtomicCss.alignCenter]}>
          <Image style={styles.infoIconStyle} source={InfoBlueIcon} />
          <Text style={[AtomicCss.azure, AtomicCss.font14, AtomicCss.boldFont]}>
            {documentPageDetails.bottomSheet.DOCREQUIRED.heading}
          </Text>
        </View>
      </TouchableOpacity>
    </View>

  );
}

DocumentsRequiredCard.propTypes = {
  documentPageDetails: PropTypes.shape({
    topHeaderText: PropTypes.string,
    topHeaderSubText: PropTypes.string,
    customerTimeline: PropTypes.string.isRequired,
    heading: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    paxDocRelationNewState: PropTypes.shape([
      PropTypes.shape({
        heading: PropTypes.string.isRequired,
        paxName: PropTypes.string.isRequired,
        docs: PropTypes.string.isRequired,
        docsConditions: PropTypes.string
      })
    ]),
    uploadMessage: PropTypes.shape({
      [PropTypes.string]: PropTypes.shape({
        text: PropTypes.string.isRequired,
        subText: PropTypes.string.isRequired,
        buttons: PropTypes.array.isRequired
      })
    }),
    bottomSheet: PropTypes.shape({
      DOCREQUIRED: PropTypes.shape({
        heading: PropTypes.string.isRequired
      }).isRequired
    }).isRequired
  }).isRequired,
  handleOpenWhyDocumentsOverlay: PropTypes.func.isRequired
};

const styles = {
  cardBorder: {
    borderTopColor: '#e4e4e4',
    borderBottomColor: '#e4e4e4',
    borderTopWidth: 1,
    borderBottomWidth: 1
  },
  infoCardWrapper: {
    backgroundColor: '#fff',
    justifyContent: 'space-between',
    flexDirection: 'row',
    ...getPlatformElevation(2),
    marginBottom: 1
  },

  infoCardContent: {
    paddingRight: 8,
    paddingVertical: 15,
    flex: 1
  },

  infoCustom: {
    fontSize: 14,
    color: '#4a4a4a',
    lineHeight: 18,
    fontFamily: fonts.regular
  },
  headingIconWrapper: {
    width: 46,
    alignItems: 'center'
  },
  headingIconStyle: {
    width: 16,
    height: 18,
    top: 15
  },

  redirectBtnWrapper: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 50,
    paddingRight: 35,
    justifyContent: 'space-between',
    flexDirection: 'row',
    backgroundColor: '#fff',
    borderBottomWidth: 1,
    borderBottomColor: '#ededed',
    alignItems: 'center'
  },

  rightArrowIcon: {
    width: 9,
    height: 13,
    tintColor: themeColors.rightArrowTintColor
  },

  linkBtnTxt: {
    fontSize: 14,
    fontFamily: fonts.bold,
    color: themeColors.linkTextColor
  },
  buttonWrapper: {
    paddingTop: 20,
    width: '90%'
  },
  tickIcon: {
    width: 14,
    height: 14,
    marginLeft: 5
  },
  missedUploadWrapper: {
    backgroundColor: '#ffd3d4',
    paddingVertical: 6,
    paddingHorizontal: 8,
    marginBottom: 12
  },
  failedText: {
    color: '#e53442',
    fontFamily: fonts.bold,
    fontSize: 14
  },
  innerDtlsBox: {
    backgroundColor: '#f2f2f2',
    padding: 12,
    borderRadius: 4,
    marginLeft: -25
  },
  headingSection: {
    borderBottomColor: '#e5e5e5',
    borderBottomWidth: 1,
    paddingBottom: 10
  },
  bulletDesc: {
    width: 5,
    height: 5,
    borderRadius: 10,
    backgroundColor: '#000',
    marginRight: 10
  },
  pink: {
    backgroundColor: 'pink'
  },
  red: {
    backgroundColor: 'red'
  },
  yellow: {
    backgroundColor: 'yellow'
  },
  green: {
    backgroundColor: 'green'
  },
  whiteCard: {
    ...getPlatformElevation(2),
    backgroundColor: '#fff',
    marginBottom: 3
  },
  innerSection: {
    padding: 16
  },
  cardStatus: {
    borderRadius: 4,
    backgroundColor: '#fdedd2',
    padding: 8,
    alignItems: 'center'
  },
  statusTxt: {
    color: '#cc7f01',
    fontFamily: fonts.bold
  },
  innerDtlsBox: {
    backgroundColor: '#f2f2f2',
    padding: 12,
    borderRadius: 4
  },
  headingSection: {
    borderBottomColor: '#e5e5e5',
    borderBottomWidth: 1,
    paddingBottom: 10
  },
  bulletDesc: {
    width: 5,
    height: 5,
    borderRadius: 10,
    backgroundColor: '#000',
    marginRight: 10
  },
  infoSection: {
    backgroundColor: '#fff',
    paddingVertical: 10
  },
  infoIconStyle: {
    width: 20,
    height: 20,
    marginRight: 10
  }
};

export default DocumentsRequiredCard;
