import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';
import { Dimensions, StyleSheet } from 'react-native';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss'

const windW = Dimensions.get('screen').width;

const styles = {
  cardWrapper: {
    width: windW,
  },

  top20Neg: {
    top: -20,
    zIndex: 10,
  },
  fltInnerDtlsWrappers: {
    paddingVertical: 16,
    paddingHorizontal: 10,
    backgroundColor: '#fff',
  },
  BottomShadow: {
    ...getPlatformElevation(1),
    backgroundColor: 'transparent',
    flex: 1,
    height: 1,
    marginBottom: 1,
  },
  btnLinkWrapper: {
    paddingVertical: 10,
    paddingHorizontal: 16,
    ...getPlatformElevation(1),
    flexDirection: 'row',
    backgroundColor: '#fff',
    borderTopWidth: 1,
    flexWrap: 'wrap',
    borderTopColor: '#ddd',
  },

  cabinBagWrapper: {
    borderTopWidth: 1,
    borderTopColor: '#ddd',
    paddingTop: 15,
  },
  chkIconStyle1: {
    width: 24,
    height: 24,
    marginRight: 8,
    top: -3,
  },
  chkIconStyle2: {
    width: 24,
    height: 24,
    marginRight: 8,
    marginTop: -5,
  },
  infoIconStyle: {
    width: 24,
    height: 24,
    marginRight: 8,
  },
  fltDtlsWrapper: {
    backgroundColor: '#fff',
  },
  topHeading: {
    paddingRight: 50,
    paddingLeft: 16,
    paddingTop: 16,
    paddingBottom: 12,
    marginBottom: 2,
    ...getPlatformElevation(3),
    backgroundColor: '#fff',
  },
  topHeadingWithoutArrow: {
    paddingHorizontal: 16,
    paddingTop: 16,
    paddingBottom: 12,
    marginBottom: 2,
    ...getPlatformElevation(3),
    backgroundColor: '#fff',
  },
  arrowStyle: {
    width: 10,
    height: 8,
    alignSelf: 'center',
    marginLeft: 5,
    marginRight: 5,
    marginTop: 3,
  },
  arrowCircleStyle: {
    width: 32,
    height: 32,
  },
  arrowCircleStyleActive: {
    transform: [{ rotate: '180deg' }],
  },
  actionBtnStyle: {
    height: 50,
    width: 50,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    right: 4,
  },
  cancelledIconStyle: {
    width: 23,
    height: 17,
    marginRight: 10,
  },
  dateChangeIconStyle: {
    width: 16,
    height: 16,
    marginRight: 10,
  },
  webChkIconStyle: {
    width: 19,
    height: 12,
    marginRight: 10,
  },
  useCaseWrapper: {
    flexDirection: 'row',
    marginVertical: 5,
    marginHorizontal: 10,
    padding: 10,
    borderRadius: 4,
  },
  cancelUseCase: {
    backgroundColor: '#ffedd1',
  },
  datechangeUseCase: {
    backgroundColor: '#e6f5ff',
  },
  webChkInUseCase: {
    backgroundColor: '#f2f2f2',
    alignItems: 'center',
  },
  cancelUseCaseTxt: {
    color: '#cf8100',
    fontSize: 12,
  },
  dateChangeUseCaseTxt: {
    color: '#0061aa',
  },
  canccelledTxt: {
    color: '#db7e00',
    alignSelf: 'flex-end',
  },
  CompletedTxt: {
    color: '#bbb',
    alignSelf: 'flex-end',
  },
  sectorStatusText: {
    alignSelf: 'flex-end',
  },
  failedTxt: {
    color: '#e53442',
    marginTop: 2,
  },
  DateChangeedTxt: {
    color: '#0061aa',
    fontSize: 12,
    alignSelf: 'flex-end',
  },
  postpondTxt: {
    color: '#cf8100',
  },
  addBagStyle: {
    width: 24,
    height: 24,
    marginRight: 8,
  },
  addGccFareStyle: {
      width: 22,
      height: 12,
      marginRight: 10,
      left: 4
  },
  addFareStyle: {
    width: 26,
    height: 26,
    marginRight: 8,
    marginTop: -5,
  },
  priotyChkInIconStyle: {
    width: 24,
    height: 24,
    marginTop: -5,
    marginRight: 8,
  },
  bgOrange: {
    backgroundColor: '#ffedd1',
  },
  airlineIcon: {
    width: 19,
    height: 19,
    marginRight: 8,
    marginLeft: 2,
  },
  bgOrangeTxt: {
    color: '#cf8100',
  },
  beltIconStyle: {
    width: 16,
    height: 17,
    marginRight: 10,
  },
  delayIcon: {
    width: 22,
    height: 22,
    marginRight: 8,
  },
  defaultIcon: {
    width: 26,
    height: 26,
    marginRight: 8,
  },
  defaultChangeIcon: {
    width: 28,
    height: 28,
    marginRight: 4,
  },
  defaultUseCaseTxt: {
    color: '#cf8100',
    flex: 1,
    marginTop: 4,
  },
  delayUseCaseTxt: {
    color: '#cf8100',
    flex: 1,
    marginTop: 2,
  },
  gateIconStyle: {
    width: 16,
    height: 12,
    marginRight: 10,
    marginTop: 2,
  },
  firstLstCityTxt: {
    color: '#000',
  },
  confirmSection: {
    backgroundColor: '#c1f1dd',
  },
  errorSection: {
    backgroundColor: '#ffd3d4',
  },
  ticketSection: {
    backgroundColor: '#ffedd1',
  },
  confirmUseCaseTxt: {
    color: '#1a7971',
    marginTop: 4,
  },
  errorUseCaseTxt: {
    color: '#eb2026',
    marginTop: 4,
  },
  ticketUseCaseTxt: {
    color: '#cf8100',
    fontSize: 12,
  },
  failedStatusColor: {
    color: '#eb2026',
  },
  awaitedStatusColor: {
    color: '#cf8100',
  },
  confirmedStatusColor: {
    color: '#1a7971',
  },
  cancelledStatusColor: {
    color: '#db7e00',
  },
  policyDesc: {
    color: '#cb9e4b',
    marginTop: 5,
  },
  policyTitle: {
    marginTop: 5,
    marginRight: 5,
  },
  baggageInfoIcon: {
    width: 30,
    height: 30,
    marginRight: 2,
  },
  btnWrapper: {
    paddingVertical: 10,
    paddingHorizontal: 20,
  },
  cityName: {
    color: colors.black,
  },
  addFareTxt:{
    maxWidth: windW * 0.5
  },
  priortiyCheckinTxt: {
    maxWidth: windW * 0.4
  }
};

export const htmlStyle = (fonts, psTheme) => {
  const {color} = psTheme;
  return StyleSheet.create({
    div: {
      color: color.darkBlue11,
      ...fonts.boldFontFamily,
      ...AtomicCss.marginTop5
    },
    span: {
      color: color.red25
    }
  });
}

export const getHtmlViewStyle = (psFonts) => {

  const { fsStyle, ...fonts } = psFonts;
  return (
    {
      p: {
        ...fsStyle.font12,
        ...fonts.regularFontFamily,
      },
      b: {
        ...fonts.boldFontFamily,
      },
    })
};



export default styles;
