import {StyleSheet} from 'react-native'
export default (theme,fonts) => 
StyleSheet.create({
  dottedList: {
    elevation: 2,
    shadowColor: theme.color.black,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 2,
    backgroundColor: theme.color.white,
    borderRadius: 4,
    padding: 18,
    paddingBottom: 8,
    marginTop: 2,
  },
  dottedListItem: {
    position: 'relative',
    paddingLeft: 13,
  },
  dottedListDot: {
    height: 4,
    width: 4,
    backgroundColor: theme.color.black,
    borderRadius: 5,
    position: 'absolute',
    top: 6,
    left: 0,
  },
  dottedListText: {
    color: theme.color.black,
    fontSize: fonts.fontSize.font14,
    ...fonts.regularFontFammily,
    marginBottom: 10,
  },
  cardTitle: {
    color: theme.color.gray,
    fontSize: fonts.fontSize.font21,
    ...fonts.lightFontFammily,
    marginBottom: 24,
  },
}
);

