import React from 'react';
import { View, StyleSheet } from 'react-native';
import Actions from '../../../navigation/postSalesNavigation';
import Header from '../cards/Header';
import SliderImages from './SliderImages';

const IndividualPhotographs = ({imageList, currImg, headerTitle, headerSubtitle}) => {
  
  const onBackPress = () => {
  	Actions.pop();
  };
	
	return (
    <View style={styles.container}>
      <Header
        title={headerTitle}
        subTitle={headerSubtitle}
        headerShadow={false}
        isHeaderBg="darkGrey"
        isCrossIcon={true}
				onBackPress={onBackPress}
      />
      <View style={styles.photosWrapper}>
        <SliderImages
					imageList={imageList}
					currImg={currImg}
          pageInfo={true}
          showFullWidth={true}
				/>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#4a4a4a',
    height: '100%',
  },
  photosWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    height: '90%',
    width: '100%',
  },
});

export default IndividualPhotographs;
