import React from 'react';
import {View, Text, Image, TouchableOpacity} from 'react-native';
import styles from './summaryCardCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {fetchBookingStatus} from '../../../utils/SelfDriveBookingDetailUtil';
import _ from 'lodash';
import ZcConstants from '../../../utils/ZoomCarConstants';
import {openGoogleMap} from '../../../../Common/commonUtil';
import {TIME_INFO_ICON, GOOGLE_MAP_ICON, FUEL_ICON,KM_ICON, DL_ICON, LOCATION_ICON} from '../../../SelfDriveImageConstant';

const SummaryCard = ({
  response, openViewPickUpLocationOverlay, openViewDropLocationOverlay, handlePress
}) => {
  const {
    model, transmissionType, fuelType, seatCapacity, licenseNo
  } = response.vehicleInfo;
  let bookingStatus = fetchBookingStatus(response);
  let knowMore = false;
  let colorBookingStatus = AtomicCss.yellowText;
  if (bookingStatus === 'Confirmed') {
    knowMore = true;
    colorBookingStatus = AtomicCss.azure;
  }
  if (response.bookingState.failedBooking) {
    colorBookingStatus = AtomicCss.redText;
    bookingStatus = 'Failed';
  }
  return (
    <View style={[AtomicCss.marginBottom10]}>
      <View style={[styles.summaryCardWrapper]}>

        <View style={[AtomicCss.spaceBetween, AtomicCss.flexRow]}>
          <View style={[AtomicCss.flexRow, AtomicCss.flex1]}>
            <View style={AtomicCss.marginRight10}>
              <Image
                source={{uri: response.vehicleInfo.vendorImageUrl}}
                style={[styles.cabIcon, AtomicCss.marginBottom5]}
              />
              { !_.isEmpty(licenseNo) && <View style={styles.cabNumber}><Text
                style={[AtomicCss.boldFont, AtomicCss.font10, AtomicCss.textCenter]}
              >{licenseNo}
                                                                        </Text>
                                         </View>}
            </View>

            <View>
              <View style={AtomicCss.marginBottom3}><Text
                style={[AtomicCss.font18, AtomicCss.blackFont, AtomicCss.blackText]}
              >{model}
                                                    </Text>
              </View>
              <View
                style={AtomicCss.marginBottom3}
              ><Text>{`${transmissionType} • ${seatCapacity} Seater • ${fuelType}`}</Text>
              </View>
              {!knowMore && <View style={[AtomicCss.flexRow]}>
                <Text
                  style={[colorBookingStatus, AtomicCss.boldFont, AtomicCss.marginRight10]}
                >{bookingStatus}
                </Text>
                            </View>}

              {knowMore && <View style={[AtomicCss.flexRow]}>
                <TouchableOpacity onPress={() => handlePress(ZcConstants.KNOW_MORE_OVERLAY)}><Text
                  style={[AtomicCss.azure, AtomicCss.boldFont, AtomicCss.marginRight10]}
                >Know
                                    More
                </Text>
                </TouchableOpacity>
                           </View>}

            </View>
          </View>
          <Image source={{uri: response.additionalInfo.vendorImageUrl}} style={styles.revIcon} />
        </View>

        <View style={styles.summaryInfo}>
          <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween, AtomicCss.marginBottom40]}>
            <View>
              <View style={AtomicCss.marginBottom10}><Text
                style={[AtomicCss.font10, AtomicCss.boldFont, AtomicCss.greyText]}
              >PICKUP
                                TIME
                                                     </Text>
              </View>
              <View style={AtomicCss.marginBottom5}><Text
                style={[AtomicCss.font20, AtomicCss.blackFont, AtomicCss.blackText]}
              >{response.tripInfo.pickup.displayTime}
                                                    </Text>
              </View>
              <Text
                style={[AtomicCss.font14, AtomicCss.boldFont, AtomicCss.defaultText]}
              >{response.tripInfo.pickup.displayDate}
              </Text>
            </View>
            <View>
              <View style={AtomicCss.marginBottom6}><Text
                style={[AtomicCss.textCenter, AtomicCss.boldFont, AtomicCss.defaultText]}
              >{response.tripInfo.durationDisplayText}
                                                    </Text>
              </View>
              <Image source={TIME_INFO_ICON} style={styles.timeInfoIcon} />
            </View>
            <View>
              <View style={AtomicCss.marginBottom10}><Text
                style={[AtomicCss.alignRight, AtomicCss.font10, AtomicCss.boldFont, AtomicCss.greyText]}
              >DROP
                                TIME
                                                     </Text>
              </View>
              <View style={AtomicCss.marginBottom5}><Text
                style={[AtomicCss.alignRight, AtomicCss.font20, AtomicCss.blackFont, AtomicCss.blackText]}
              >{response.tripInfo.drop.displayTime}
                                                    </Text>
              </View>
              <Text
                style={[AtomicCss.alignRight, AtomicCss.font14, AtomicCss.boldFont, AtomicCss.defaultText]}
              >{response.tripInfo.drop.displayDate}
              </Text>
            </View>
          </View>

          <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween]}>
            <View style={styles.tracker} />
            <View style={[AtomicCss.flexRow, AtomicCss.flex1, AtomicCss.paddingBottom24]}>
              <Image source={LOCATION_ICON} style={styles.locationIcon} />
              <View style={styles.rightSection}>
                <View style={AtomicCss.marginBottom6}><Text
                  style={[AtomicCss.font12, AtomicCss.boldFont, AtomicCss.midGreyText, AtomicCss.textUppercase]}
                >PICK-UP LOCATION
                </Text>
                </View>
                <View style={AtomicCss.marginBottom5}><Text
                  style={[AtomicCss.font14, AtomicCss.boldFont, AtomicCss.blackText]}
                >{response.tripInfo.pickup.city}
                </Text>
                </View>
                <View style={AtomicCss.marginBottom6}><Text
                  style={[AtomicCss.font12, AtomicCss.greyText]}
                >{response.tripInfo.pickup.address}
                </Text>
                </View>
                <TouchableOpacity onPress={() => openViewPickUpLocationOverlay()}><Text
                  style={[AtomicCss.azure, AtomicCss.boldFont]}
                >View Location
                                    Details
                </Text>
                </TouchableOpacity>
              </View>
            </View>
            <TouchableOpacity onPress={() => openGoogleMap(response.tripInfo.pickup.latStr, response.tripInfo.pickup.longStr, {label: null})}>
              <Image source={GOOGLE_MAP_ICON} style={styles.googlemap} />
            </TouchableOpacity>
          </View>

          <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween]}>
            <View style={[AtomicCss.flexRow, AtomicCss.flex1]}>
              <View style={styles.trackerDot} />
              <View style={styles.rightSection}>
                <View style={AtomicCss.marginBottom6}><Text
                  style={[AtomicCss.font10, AtomicCss.boldFont, AtomicCss.greyText, AtomicCss.textUppercase]}
                >Drop-off
                                    location
                </Text>
                </View>
                <View style={AtomicCss.marginBottom5}><Text
                  style={[AtomicCss.font14, AtomicCss.boldFont, AtomicCss.blackText]}
                >{response.tripInfo.drop.city}
                </Text>
                </View>
                <View style={AtomicCss.marginBottom6}><Text
                  style={[AtomicCss.font12, AtomicCss.greyText]}
                >{response.tripInfo.drop.address}
                </Text>
                </View>
                <TouchableOpacity onPress={() => openViewDropLocationOverlay()}><Text
                  style={[AtomicCss.azure, AtomicCss.boldFont]}
                >View Location
                                    Details
                </Text>
                </TouchableOpacity>
              </View>
            </View>
            <TouchableOpacity onPress={() => openGoogleMap(response.tripInfo.drop.latStr, response.tripInfo.drop.longStr, {label: null})}>
              <Image source={GOOGLE_MAP_ICON} style={styles.googlemap} />
            </TouchableOpacity>
          </View>

          <View style={styles.inclusions}>
            {response.vehicleInfo.fuelInclusive &&
            <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
              <Image source={FUEL_ICON} style={styles.locationIcon} />
              <Text style={[AtomicCss.boldFont, AtomicCss.defaultText]}>Fuel Included</Text>
            </View>}
            <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
              <Image source={KM_ICON} style={styles.locationIcon} />
              <Text style={[AtomicCss.greyText]}>
                <Text  style={[AtomicCss.boldFont, AtomicCss.defaultText]}>
                  {response.tripInfo.includedDistanceText}
                </Text>
                {response.tripInfo.extraChargeRateText}
              </Text>
            </View>
            {response.tripInfo.userDLText && <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
              <Image source={DL_ICON} style={styles.locationIcon} />
              <Text style={[AtomicCss.greyText]}>
                <Text style={[AtomicCss.boldFont, AtomicCss.defaultText]}>
                  Driving License:
                </Text>
                {' ' } {response.tripInfo.userDLText}
              </Text>
            </View>
            }
          </View>
        </View>
      </View>
    </View>
  );
};


export default SummaryCard;
