import React from 'react';
import {StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import AtomicCss from '../../Rails/commonStyles/AtomicCss';
import {colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import _ from 'lodash';
import COMMON_CONSTANT from "../commonConstant";
import { getStaticData } from '../../staticData/staticData';
import { getFont } from '../../PostSalesStyles';

const AdvisoryOverlay = ({advisoryNoticeText, closeoverlay, langAware = false}) =>{
    const { fsStyle } = getFont(langAware);
    const staticData = getStaticData();
    const { trainAdvisoryNoticeStaticText, closeText } = staticData;
    return(
        <View style={styles.overlayContainer}>
            <View style={styles.overlayContent}>
                <View style={[styles.marginBottom40]}>
                    <Text style={[styles.overlayHead, styles.marginBottom26, fsStyle.font20]}>{trainAdvisoryNoticeStaticText} </Text>
                    {
                        !_.isEmpty(advisoryNoticeText) &&
                            advisoryNoticeText.map(msg => (
                                <View style={{flexDirection: 'row'}}>
                                    <View style={styles.bulletCircle} />
                                    <Text style={[styles.tdrText, fsStyle.font14]}>{msg}</Text>
                                </View>
                            ))
                    }
                </View>
                <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.spaceBetween]}>
                    <TouchableOpacity onPress={() => closeoverlay()}>
                        <Text style={[styles.actionBlueText, fsStyle.font16]}>{closeText}</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    )
}
export default AdvisoryOverlay;
const styles = StyleSheet.create({
    overlayContainer: {
        justifyContent: 'flex-end',
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        zIndex: 1000,
        elevation: 3
    },
    overlayBg: {
        backgroundColor: 'rgba(0,0,0,.5)',
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        zIndex: 1
    },
    tdrText: {
        color: colors.black,
        fontFamily: fonts.regular,
        lineHeight: 22,
        paddingLeft: 20
    },
    overlayContent: {
        backgroundColor: '#fff',
        paddingTop: 26,
        paddingBottom: 30,
        paddingHorizontal: 20,
        position: 'relative',
        zIndex: 100,
        justifyContent: 'flex-end'
    },
    marginBottom26: {
        marginBottom: 10
    },
    overlayHead: {
        color: '#000',
        fontFamily: fonts.regular
    },
    actionBlueText: {
        color: '#008cff',
        fontFamily: fonts.bold,
        paddingTop: 10
    },
    marginBottom40: {
        marginBottom: 0
    },
    bulletCircle: {
        width: 6,
        height: 6,
        backgroundColor: '#5a5a5a',
        borderRadius: 20,
        top: 5,
        left: 5
    },
});

