import React from 'react';
import { View, Text, Image, StyleSheet } from 'react-native';
import getStyles from '../styles/BusMamiCss';
import PropTypes from 'prop-types';
import { convertStringtoTitleCase } from '../utils/BusBookingDetailUtil';
import { seatIcon, maleImage, femaleImage, guestIcon } from '../BusImageConstants';
import { label } from '@mmt/post-sales/src/staticData/staticData';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { getFont } from '../../PostSalesStyles';

class TravellerCard extends React.Component {
  constructor(props) {
    super(props);
    this.busBookingInfo = this.props.response.busBookingInfo;
    this.fonts = getFont(true);
  }

  static navigationOptions = {
    header: null,
  };

  render() {
    const travellerInfo = this.busBookingInfo.passengerDetails;
    const travellerCount = get(this, 'busBookingInfo.noOfPassengers', 0);
    const { fonts } = this;
    const styles = getStyles();
    return (
      <View style={[styles.MamiCard, styles.mar10Bt]}>
        <View style={styles.CardContent}>
          <Image style={styles.CardIcon} source={guestIcon} />
          <Text style={[fonts.blackFontFamily, styles.BlackText, styles.mar5Bt]}>
            {travellerCount} {travellerCount > 1 ? label('bus.travellers') : label('bus.traveller')}
          </Text>
        </View>
        <View style={_style.travellerContainerStyle}>{this.addTravellers(travellerInfo)}</View>
      </View>
    );
  }

  addTravellers(travellerInfo) {
    const views = [];
    travellerInfo.forEach((passengerDetail, index) => {
      this.addTravellerCard(views, passengerDetail, index);
    });
    return views;
  }

  addTravellerCard(views, passengerDetail, index) {
    const { fonts } = this;
    const styles = getStyles();
    views.push(
      <View key={index} style={[index === 0 ? '' : styles.pad15Top]}>
        <View style={[styles.flexRow, styles.JustifySpaceBetween]}>
          <Text
            style={[
              fonts.font12,
              styles.BlackText,
              fonts.blackFontFamily,
              this.getOpacityStyle(passengerDetail.status),
            ]}
          >
            {convertStringtoTitleCase(passengerDetail.name)}
          </Text>
          {!isEmpty(passengerDetail) &&
           passengerDetail.statusText &&
          <Text
            style={[this.getStatusStyle(passengerDetail.status), fonts.font12, fonts.regularFontFamily]}
          >
            {convertStringtoTitleCase(passengerDetail.statusText)}
          </Text>
          }
        </View>
        <View style={this.getOpacityStyle(passengerDetail.status)}>
          <View style={{ flexDirection: 'row', paddingBottom: 4 }}>
            <View style={{ flexDirection: 'row', marginRight: 10 }}>
              <Image
                style={[
                  passengerDetail.gender === 'Male' ? styles.showView : styles.hideView,
                  { width: 16, height: 16, marginRight: 5 },
                ]}
                source={maleImage}
              />
              <Image
                style={[
                  passengerDetail.gender === 'Female' ? styles.showView : styles.hideView,
                  { width: 16, height: 16, marginRight: 5 },
                ]}
                source={femaleImage}
              />
              <Text style={[fonts.regularFontFamily]}>
                {passengerDetail.age}, {passengerDetail.gender}
              </Text>
            </View>
            <View style={{ flexDirection: 'row' }}>
              <Image style={{ width: 16, height: 20, marginRight: 5 }} source={seatIcon} />
              <Text style={fonts.regularFontFamily}>{label('bus.seatInfo', null, { seatNumber: passengerDetail.seatNumber })}</Text>
            </View>
          </View>
          {/* Commenting this until we start getting Ladies seat from LOB // <Text style={[passengerDetail.gender === 'Female' ? styles.showView : styles.hideView, styles.regularFont, {color: '#ff40bf', fontSize: 12}]}>Reserved as a ladies seat</Text>*/}
        </View>
      </View>,
    );
  }

  getOpacityStyle(status) {
    const styles = getStyles();
    return status === 'Cancelled' ? styles.opacity3 : '';
  }
  getStatusStyle(status) {
    const styles = getStyles();
    return status === 'Cancelled' ? styles.redfont : styles.lightGreenTxt;
  }
}

const _style = StyleSheet.create({
  travellerContainerStyle: {
    marginLeft: 55,
    paddingBottom: 18,
    marginRight: 15,
    paddingTop: 18,
  },
});

TravellerCard.propTypes = {
  response: PropTypes.object.isRequired,
};
export default TravellerCard;
