import React, { useState, useEffect } from 'react';
import { ScrollView, View } from 'react-native';
import Actions from '../../../../../navigation/postSalesNavigation';
import useCancellatoinView from './hooks/useCancellationView';

import { getStaticData } from '../../../../../staticData/staticData';
import { getFont } from '../../../../../PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import { HEADER_BACK_ICON } from '../../../../FlightImageConstants';

import StickyHeader from '../../components/StickyHeader';
import Breadcrumb from '../../components/Breadcrumb';
import ErrorView from '../../components/ErrorView';

import ProgressView from '../../../../../Common/ProgressView/ProgressView';
import ConfirmationOverlay from '../../../../../Common/ConfirmationOverlay';
import NeedMoreHelpOverlay from '../../../../../Common/NeedMoreHelpOverlay';
import { ValidationMsgCom } from '../../../../cancellation/components/CancellationValidationComp';
import BaseButton, { BUTTON_VARIANT } from '../../../../../Common/BaseButton';

import CancellationFareBreak from "./components/CancellationFareBreak";
import CancellationDeductionBreakup from "./components/CancellationDeductionBreak";
import CancelledPaxDetails from './components/CancelledPaxDetails';
import GetRefundMessage from "./components/RefundMessage";

import CancellationTrackingHelper from '../../../../cancellation/Helpers/CancellationTrackingHelper';
import { setTrackingPageName, getTrackingBundle, trackHelpingHandEvents, trackViewHideBreakUpClick } from './trackinghelpers'

import { NEED_HELP_PAGE, NEED_MORE_HELP, LOBNAMES } from '../../../../../PostSalesConstant';
import { openFlightCancellationThankYouPage } from '../../../../FlightBookingUtil';
import { openErrorPage } from '../../../../../utils/PostSaleUtil';
import { BREAD_CRUMB_TEXT, ATTRIBUTE_KEYS, BREAKUP_KEYS, CANCELLATION_BREAKUP_PAGE_VIEW } from "../../constant";
import { getRefundData, getBreakupData } from "../../data/dataAdapter";
import {
  getSegmentWiseInfo,
  getButtonText,
  getCancellationConfirmationText,
  getCancellationAttributesLists,
  isSpecialClaimFlow,
  checkIfRefundModeApplicable,
  checkIfCardChargeableBooking,
  getCancellationAttributesMessageList,
  getCancelledSegmentInfo,
} from '../../helper';
import isEmpty from 'lodash/isEmpty';
import createStyles from './styles';
import InformationCard from 'apps/post-sales/src/Common/InformationCard';
import CreditShellMessageBox from '../../components/CreditShellMessageBox';

const RefundBreakup = ({
  pageData: flightDetailsResponse,
  isFull,
  selectedPassenger,
  cancellationPreviewResponseJson: cancellationReviewLandingPageData,
  headerTitle,
  cancellationRequest,
  isCorporateBooking,
  allPaxSelected,
}) => {

  const [viewState, cancellationReviewPageData, paxFareSegmentIdList, OnRetry, viewRequestBody] =
    useCancellatoinView(cancellationReviewLandingPageData, {
      isFull,
      flightDetailsResponse,
      selectedPassenger,
    });
  const [helpingHandOverlay, setHelpingHandOverlay] = useState(true);
  const [overlay, setOverlay] = useState(false);

  const { previewDetail } = cancellationReviewPageData;
  const { confirmationMessage = "" } = previewDetail || {};
  const isCardChargeableBooking = checkIfCardChargeableBooking(cancellationReviewPageData);
  const isRefundModeApplicable = checkIfRefundModeApplicable(cancellationReviewPageData, isCardChargeableBooking)
  const isSpecialClaim = isSpecialClaimFlow(cancellationRequest);


  const trackingPageName = setTrackingPageName(cancellationRequest,allPaxSelected);

  const trackingBundle = getTrackingBundle(cancellationReviewPageData, allPaxSelected, cancellationRequest);

  useEffect(() => {
    CancellationTrackingHelper.trackLoadEvent(trackingPageName, trackingBundle);
}, []);


  let { selectText, reviewText, confirmText, refundModeText, submitRequestText } = getStaticData();

  const { psTheme: theme } = useTheme();
  const { fsStyle, ...fonts } = getFont(true);
  const styles = createStyles(theme, fonts);


  const {SELECT, REVIEW, CONFIRM, REFUND_MODE } = BREAD_CRUMB_TEXT;

  const SPECIAL_CLAIM_STEPS = [SELECT, REVIEW, CONFIRM, REFUND_MODE]

  const NO_REFUND_MODE_STEPS = [selectText, reviewText, confirmText];

  const REFUND_MODE_STEPS = [selectText, reviewText, refundModeText, confirmText];

  const cancellationAddAttributes = getCancellationAttributesLists(previewDetail,ATTRIBUTE_KEYS.ADD_ATTRIBUTES);
  const { crossSellBookingMsg } = cancellationReviewPageData || {};
  const cancellationSegmentPaxInfo = getCancelledSegmentInfo(cancellationReviewPageData);
  const paid = getBreakupData(cancellationReviewPageData, BREAKUP_KEYS.TOTAL_AMOUNT_PAID)
  const deductions = getBreakupData(cancellationReviewPageData, BREAKUP_KEYS.DEDUCTIONS);
  const refundAmount = getRefundData(cancellationReviewPageData);
  const addAttributesMessageList = getCancellationAttributesMessageList(previewDetail,ATTRIBUTE_KEYS.ADD_ATTRIBUTES);
  const refundAttributesMessageList = getCancellationAttributesMessageList(previewDetail,ATTRIBUTE_KEYS.REFUND_ADD_ATTRIBUTES);

  useEffect(() => {
    if(viewState === CANCELLATION_BREAKUP_PAGE_VIEW.OPEN_ERROR_PAGE ){
      openErrorPage(
        cancellationReviewPageData ? { ...cancellationReviewPageData, type: 'replace'} : {type: 'replace'},
        undefined,
        OnRetry,
        flightDetailsResponse.bookingID,
        'cancellation',
        Actions,
        {
          psLob: LOBNAMES.FLIGHT,
          langAware: true,
          isCorporateBooking,
        },
        {
          flightCancellationRequest: viewRequestBody
        },
      );
    }else if(viewState === CANCELLATION_BREAKUP_PAGE_VIEW.OPEN_CANCELLATION_ERROR_PAGE ){
      Actions.cancellationErrorPage({
        headerTitle,
        cancellationPreviewResponseJson: cancellationReviewPageData,
        cancellationRequest,
        trackingBundle:  getTrackingBundle(cancellationReviewPageData, allPaxSelected, cancellationRequest,
          cancellationReviewPageData.businessErrorObj && cancellationReviewPageData.businessErrorObj.businessErrCode)
      });
    }
  },[viewState, cancellationReviewPageData]);

  const renderProgressView = () => {
    const { loadingText } = getStaticData();
    return <ProgressView message={loadingText ? loadingText : ''} />;
  };

  const toggleHelpingHandOverlay = (value) => {
    setHelpingHandOverlay(value);
  };

  const overlayConfirmCancelClick = (
    cancellationRequest,
    trackingPageName,
    refundModeReason,
    instantData,
  ) => {
    openFlightCancellationThankYouPage(
      cancellationRequest,
      trackingPageName,
      refundModeReason,
      instantData,
      trackingBundle
    );
  };

  const confirmationPopup = () => {
    if (overlay) {
      CancellationTrackingHelper.trackClickEvent( trackingPageName, 'confirmpopup_no',  trackingBundle);
    }
    setOverlay((prev) => !prev);
  };

  const renderConfirmationPopup = () => {
    let { backText, areYouSureText, confirmText } = getStaticData();

    if (isSpecialClaim) {
      backText = 'Back';
      areYouSureText = 'Are you sure?';
      confirmText = 'CONFIRM';
    }
    return (
      <ConfirmationOverlay
        title={areYouSureText}
        leftButtonText={backText}
        rightButtonText={confirmText}
        content={getCancellationConfirmationText()}
        leftBtnClick={confirmationPopup}
        rightBtnClick={() =>
          overlayConfirmCancelClick(cancellationRequest, trackingPageName)
        }
        outsideClick={confirmationPopup}
        additionalText={confirmationMessage}
      />
    );
  }

    const onContinueClicked = () => {
      const isCredShellError = cancellationReviewPageData && !!cancellationReviewPageData.creditShellAirlinesErrorData;
      if(isCredShellError){
        openErrorPage(
          cancellationReviewPageData ? { ...cancellationReviewPageData, type: 'replace'} : {type: 'replace'},
          undefined,
          OnRetry,
          flightDetailsResponse.bookingID,
          'cancellation',
          Actions,
          {
            psLob: LOBNAMES.FLIGHT,
            langAware: true,
            isCorporateBooking,
          },
        );
      }
      else if (isRefundModeApplicable) {
        CancellationTrackingHelper.trackClickEvent( trackingPageName, 'selectrefundmode_click', trackingBundle);
        Actions.flightCancellationRefundMode({
          refundAmount: refundAmount.totalRefund,
          emailId: '',
          cancellationRefundMode: cancellationReviewPageData.cancellationRefundMode,
          headerTitle: headerTitle,
          overlayConfirmCancelClick,
          cancellationConfirmationText: getCancellationConfirmationText(cancellationReviewPageData,isCardChargeableBooking,isSpecialClaim),
          trackingBundle,
          emiMessage: cancellationReviewPageData.emiCancellationMessage,
          bookingId: cancellationReviewPageData.bookingID,
          penaltyResponse: cancellationReviewPageData,
          cancellationRequest,
          segmentWiseInfo: getSegmentWiseInfo(cancellationReviewPageData),
          isCorporateBooking: isCorporateBooking,
          additionalText: confirmationMessage
        });
      } else {
        CancellationTrackingHelper.trackClickEvent(trackingPageName, 'confirmcancellation_click', trackingBundle);
        confirmationPopup();
      }
    };

    const renderBreakupView = () => {

      return(
        <View style={styles.pageWrapper}>
          <StickyHeader
            iconSrc={HEADER_BACK_ICON}
            title={headerTitle}
            headerColor={[theme.color.white, theme.color.white]}
            titleStyle={[styles.headerTitle, fonts.boldFontFamily, fsStyle.font16]}
            onBackPress={Actions.pop}
          />
          <Breadcrumb
            activeStep={1}
            data={isSpecialClaim ? SPECIAL_CLAIM_STEPS : (isRefundModeApplicable ? REFUND_MODE_STEPS : NO_REFUND_MODE_STEPS)}
          />
          {cancellationReviewPageData && cancellationReviewPageData.cgNudgeText && (
            <InformationCard
              description={cancellationReviewPageData.cgNudgeText}
              cardBackgroundColor={theme.color.creamWhite}
              textColor={theme.color.lightYello}
            />
          )}
          <ScrollView showsVerticalScrollIndicator={false} style={styles.pageScroll}>
            {
              !isEmpty(cancellationSegmentPaxInfo) &&
              <View style={styles.paxCancelledTopDtls}>
                {cancellationSegmentPaxInfo.map(
                  (cancelledSegmentInfo, index) => {
                    const isBorderTop = index + 1 === cancellationSegmentPaxInfo.length;
                    return (
                      <CancelledPaxDetails
                        key={cancelledSegmentInfo.travelInfo}
                        paxNames={cancelledSegmentInfo.paxNames}
                        reason={cancelledSegmentInfo.cancelReason}
                        segmentWithDate={cancelledSegmentInfo.travelInfo}
                        messages={cancelledSegmentInfo.messages}
                        isBorderTop={isBorderTop}
                      />
                    );
                  }
                )}
              </View>
            }
            <View style={styles.refundContainer}>
              <GetRefundMessage attributeList={cancellationAddAttributes} crossSellBookingMessage={crossSellBookingMsg} cancellationReviewPageData={cancellationReviewPageData}/>
              <ValidationMsgCom response={cancellationReviewPageData} />
              <CreditShellMessageBox heading={cancellationReviewPageData?.creditShellInfoObj?.heading} subheading={cancellationReviewPageData?.creditShellInfoObj?.subheading} ></CreditShellMessageBox>
              <View style={styles.autoRefundBreakupMain}>
                <CancellationFareBreak
                  amountPaidBreakup={
                    cancellationReviewPageData.previewDetail.breakups.totalAmountPaid
                  }
                  paidmessages={addAttributesMessageList}
                  showHideBreakUpClick={(clickEvent) => { trackViewHideBreakUpClick(clickEvent,cancellationReviewPageData,allPaxSelected,cancellationRequest)}}
                  showStaticContentInEnglish={false}
                  title={paid.title}
                  items={paid.list}
                  total={paid.total}
                />
                <CancellationDeductionBreakup
                  deductions={
                    cancellationReviewPageData.previewDetail.breakups.deductions
                  }
                  refundAmount={refundAmount}
                  uncancelledTicketFare={
                    cancellationReviewPageData.previewDetail.uncancelledTicketFare
                  }
                  messages={refundAttributesMessageList}
                  showHideBreakUpClick={(clickEvent) => { trackViewHideBreakUpClick(clickEvent,cancellationReviewPageData,allPaxSelected,cancellationRequest)}}
                  showStaticContentInEnglish={isSpecialClaim}
                  title={deductions.title}
                  items={deductions.list}
                  total={deductions.total}
                />
              </View>
            </View>
          </ScrollView>
          <View style={styles.footerBtnWrapper}>
            <BaseButton
              text={getButtonText(cancellationReviewPageData,isSpecialClaim,isRefundModeApplicable,submitRequestText)}
              clickHandler={onContinueClicked}
              variant={BUTTON_VARIANT.PRIMARY}
              shadow
              textStyle={fsStyle.font16}
            />
          </View>
          {overlay && renderConfirmationPopup()}
          {helpingHandOverlay === true && (
            <NeedMoreHelpOverlay
              bookingId={
                cancellationReviewPageData ? cancellationReviewPageData.bookingID : ''
              }
              handleBottomOverlay={toggleHelpingHandOverlay}
              handleReload={toggleHelpingHandOverlay}
              component={NEED_MORE_HELP.HELPING_HAND}
              pageName={NEED_HELP_PAGE.FLIGHT_CANCEL_BREAKUP}
              hideLoader={true}
              trackEvent={(type, page, click, data) => { trackHelpingHandEvents(type,page,click,data,cancellationReviewPageData,allPaxSelected,cancellationRequest) }}
            />
          )}
        </View>
      );
    }

    const renderErrorView = () => {
      const {
        errorText: { somethingWentWrongTryAgainError },
        tryAgainText,
      } = getStaticData();
      return (
        <ErrorView
          heading={somethingWentWrongTryAgainError}
          buttonLabel={tryAgainText}
          showRetry
          onRetry={OnRetry}
        />
      );
    };

    if(viewState === CANCELLATION_BREAKUP_PAGE_VIEW.LOADING){
      return renderProgressView();
    }else if( viewState === CANCELLATION_BREAKUP_PAGE_VIEW.LOADED){
      return renderBreakupView();
    }else{
      return renderErrorView();
    }

}


export default RefundBreakup;
