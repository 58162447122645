import React from 'react';
import { View, Text,Image, NativeModules } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import createStyles from "./bookReturnTicketCSS";
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import { OFFER_ICON } from '../../../../../Utils/tripGuaranteeImageConstants';
import {isEmpty} from 'lodash';
import { getFont } from '../../../../../../PostSalesStyles';
import { useTheme } from '../../../../../../theme/theme.context';
import BaseButton, { BUTTON_VARIANT } from '../../../../../../Common/BaseButton';
import TGSBookingTrackEventHelper from '../../../../../Utils/TGSBookingTrackEventHelper';
import { CLICK_TYPE, PAGE_NAME } from '../../../../../Utils/TGSBookingConstants';
import { getGdprData, shouldShowGdprBlockerScreen, isGroundLob } from '../../../../../../utils/PostSaleUtil';
import { getStaticData } from '../../../../../../staticData/staticData';

const BookReturnTicket = ({data}) => {
    const {heading,returnAction} = data || {};
    const {labelText,deepLink} = returnAction || {};
    const fonts = getFont();
    const {psTheme : theme} = useTheme();
    const styles = createStyles(theme,fonts);
    const openDeepLink = async () =>{

        const { UserSessionModule } = NativeModules;
        const showGdprBlockerScreen = await shouldShowGdprBlockerScreen();

        if (showGdprBlockerScreen) {
            const { gdprText } = getStaticData();
            const { lobs: { trains = 'Trains'} = {} } = gdprText || {} 
            UserSessionModule.openGDPRBlockerScreen(trains, null, getGdprData(gdprText));
        } else {

            TGSBookingTrackEventHelper.trackClickEvents(PAGE_NAME.TRIP_DETAILS, CLICK_TYPE.EXPLORE_TRAINS_CLICK)
            if(!isEmpty(deepLink)){
                GenericModule.openDeepLink(deepLink)
            }
        }
    }
    return (
        <View style={[AtomicCss.flexRow,AtomicCss.alignCenter]}>
            <View style={[AtomicCss.flexRow,AtomicCss.flex1]}>
                <View style={styles.iconWrapper}>
                    <Image style={styles.offerIconStyle} source={OFFER_ICON} />
                </View>
                <View style={AtomicCss.flex1}>
                    {!!heading &&<Text style={[AtomicCss.font13, fonts.blackFontFamily, AtomicCss.blackText]}>{heading}</Text>}
                </View>
            </View>
            <BaseButton
            text={labelText}
            variant={BUTTON_VARIANT.PRIMARY}
            clickHandler={openDeepLink}
            textStyle={AtomicCss.font12}
          />
        </View>
    )
}





export default BookReturnTicket