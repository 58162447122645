import { fetchFlightBookingDetails, onCancelRequest } from 'apps/post-sales/src/flights/modules/details/data/api';
import isEmpty from 'lodash/isEmpty';

type FlightData = Record<string, object>;

class FetchFlightDataService {
  private static instance: FetchFlightDataService | null = null;
  private flightData: FlightData = {};

  private constructor() {}

  static getInstance(): FetchFlightDataService {
    if (!FetchFlightDataService.instance) {
      FetchFlightDataService.instance = new FetchFlightDataService();
    }
    return FetchFlightDataService.instance;
  }

  async getData(bookingId: string, trackError: (error: string) => void): Promise<object> {
    if (!isEmpty(this.flightData[bookingId])) {
      return this.flightData[bookingId];
    }
    try {
      const flightDetailResponse = await fetchFlightBookingDetails(bookingId, false, trackError);
      if (flightDetailResponse?.isNetworkNotAvailable || flightDetailResponse?.error) {
        if (flightDetailResponse?.error?.message === 'Aborted'){
          this.deleteData(bookingId);
        }
        return {};
      }
      this.flightData[bookingId] = flightDetailResponse;
      return flightDetailResponse;
    } catch (err) {
      return {};
    }
  }

  cancelRequest(): void {
    onCancelRequest();
  }

  deleteData(bookingId: string): void {
    if (bookingId in this.flightData) {
      delete this.flightData[bookingId];
    }
  }
}

// Export the singleton instance
const FetchFlightData = FetchFlightDataService.getInstance();
export default FetchFlightData;
