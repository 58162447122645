import React from 'react';
import { View, Image, Text, StyleSheet } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getFont } from '../../../../../../../PostSalesStyles';
import { isEmptyArray } from '../../../../../../../Common/commonUtil';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
import { REFUND_ICON } from '../../../../../../FlightImageConstants';
import { SPECIAL_CLAIM_ACITONS } from '../../../../constant';
import { useTheme } from '../../../../../../../theme/theme.context';

const RefundDetailCard = ({ text, subtext, viewMoreText, handleBottomOverlay }) => {
  const { fsStyle, ...fonts } = getFont(true);
  const {psTheme} = useTheme();
  const styles = getStyle(psTheme);
  const {color} = psTheme;
  return (
    <View style={styles.refundDetails}>
      <Image source={REFUND_ICON} style={styles.refundIcon} />
      <Text
        style={[
          fsStyle.font14,
          fonts.regularFontFamily,
          AtomicCss.lineHeight20,
          AtomicCss.flex1,
          {color : color.defaultTextColor}
        ]}
      >
        <Text style={[fonts.blackFontFamily, {color : color.defaultTextColor}]}>{text + ' '}</Text>
        {!isEmptyArray(subtext) && subtext[0]}
        <Text
          style={[fonts.blackFontFamily, {color : color.azure}]}
          onPress={handleBottomOverlay.bind(null, SPECIAL_CLAIM_ACITONS.view_more)}
        >
          {viewMoreText}
        </Text>
      </Text>
    </View>
  );
};
const getStyle = (theme) => {
  const {color} = theme;
  return StyleSheet.create({
    refundDetails: {
      ...getPlatformElevation(2),
      backgroundColor: color.paleBlue,
      paddingHorizontal: 16,
      paddingVertical: 12,
      borderRadius: 8,
      overflow: 'hidden',
      marginBottom: 12,
      marginHorizontal: 12,
      flexDirection: 'row',
    },
    refundIcon: {
      width: 28,
      height: 28,
      marginRight: 12,
    },
  });
}
export default RefundDetailCard;
