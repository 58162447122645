import React from 'react';
import { View, Text, Image } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import isEmpty from 'lodash/isEmpty';
import { getFont } from '../../../../PostSalesStyles';
import { useTheme } from '../../../../theme/theme.context';
import createStyles from './style';

const VisaProgressingSteps = ({ steps }) => {
  const fonts = getFont();
  const { psTheme: theme } = useTheme();
  const styles = createStyles(fonts, theme);
  return (
    !isEmpty(steps) ? (
      <View style={styles.VisaProgressingStepsCard}>
        {steps.map((item) => (
          <View style={[styles.progessStepRow, AtomicCss.marginBottom12]}>
            {!isEmpty(item.title) && <Text style={styles.stepsText}>{item.title}</Text>}
            <View style={AtomicCss.flexRow}>
              {!isEmpty(item.imageUrl) && (
                <Image style={styles.iconStyle} source={{ uri: item.imageUrl }} />
              )}
              <View style={AtomicCss.flex1}>
                {!isEmpty(item.heading) && (
                  <Text
                    style={styles.headingText}
                  >
                    {item.heading}
                  </Text>
                )}
                {!isEmpty(item.description) && (
                  <Text style={styles.descriptionText}>
                    {item.description}
                  </Text>
                )}
              </View>
            </View>
          </View>
        ))}
      </View>
    ): null
  );
};

export default VisaProgressingSteps;
