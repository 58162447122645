import React from 'react';
import { View, Text, Image } from 'react-native';
import { styles } from "../SingleSegmentSinglePaxCom/singleSegmentSinglePaxCss"
import get from 'lodash/get';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getFont } from '../../../../../../../PostSalesStyles';
import { WARNING_ICON } from '../../../../../../FlightImageConstants';

export const getHeading = (segment, shouldShowWarning) => {
    const { fsStyle, ...fonts } = getFont(true);
    const heading = get(segment, 'heading', '');
    if (heading) {
      return (
        <View style={[AtomicCss.flex1, styles.messageContent]}>
          {shouldShowWarning && <Image source={WARNING_ICON} style={styles.warningIcon} />}
          <Text
            style={[
              AtomicCss.flex1,
              AtomicCss.alignLeft,
              ...(shouldShowWarning
                ? [styles.sclaimHeader, fonts.blackFontFamily, fsStyle.font16]
                : [styles.sclaimInfo, fonts.regularFontFamily, fsStyle.font14]),
            ]}
          >
            {heading}
          </Text>
        </View>
      );
    }
    return null;
  };