import React from 'react';
import {Image, Text, View} from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import styles from '../GiftCardDetailsCss';
import GiftCardBookingDetailsConstant from '../GiftCardBookingDetailsConstant';
import { NON_REFUNDABLE_ICON } from '../GiftCardImageConstants';

const CancellationPolicyCard = ({cancellationPolicyInfo}) => {
    
  return (
    <View style={[styles.CardContentOuter]}>
      <View style={[AtomicCss.whiteBg, AtomicCss.marginBottom0]}>
        <View style={[styles.CardContentFull]}>
          <View style={[styles.cardContent]}>
            <Image style={styles.CardIcon2} source={NON_REFUNDABLE_ICON}/>
            <Text style={[AtomicCss.blackFont, AtomicCss.blackText, AtomicCss.font14]}>
              {GiftCardBookingDetailsConstant.CANCELLATION_POLICY}
            </Text>
          </View>
          <View style={[AtomicCss.paddingRight16, {marginLeft: 35}]}>
            <View style={[styles.hostReplyInfo]}>
              <Text style={[styles.defaultText]}>
                {cancellationPolicyInfo.policyText}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}

export default CancellationPolicyCard;
