import React from 'react';
import PropTypes from 'prop-types';
import {View, Text, Image, TouchableOpacity} from 'react-native';
import isEmpty from 'lodash/isEmpty';
import getPlatformElevation from './getPlatformElevation';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import Button from './Button';
import BasePage from '../../Common/PostSalesBasePage';
import ButtonList from './ButtonList';
import {infoIcon, callBtn, chatBtn} from '../HolidayBookingConstants';

class MMTSupportCard extends BasePage {
  static navigationOptions = {
    header: null
  };

  getImageIcon = (type) => {
    if (type === 'CHAT') {
      return chatBtn;
    }
    return callBtn;
  };
  getImageIconStyle = (type) => {
    if (type === 'CHAT') {
      return styles.RbBtnWithIconImg;
    }
    return styles.RbBtnWithIconCall;
  };

  render() {
    const {buttons} = this.props;
    return (
      <View>
        {!isEmpty(buttons) && buttons.length === 2 &&
        <View style={[styles.whiteWrapper, AtomicCss.marginBottom10]}>
          <View style={styles.CardContent}>
            <View style={[AtomicCss.flex1]}>
              <Text
                style={[AtomicCss.blackFont, AtomicCss.blackText, AtomicCss.paddingTop3, AtomicCss.textCenter]}>
                STILL CAN’T FIND WHAT YOU NEED?
              </Text>
            </View>
          </View>
          <View style={[styles.ActionBtnHalf, styles.supportWrap]}>
            {buttons.map((item) => {
              return (
                <TouchableOpacity activeOpacity={0.6}
                                  style={styles.SupportTxt}
                                  onPress={() => {
                                    this.props.handleButtonClick(item, '');
                                  }}>
                  <Image style={[this.getImageIconStyle(item.type), styles.mar3Bt]}
                         source={this.getImageIcon(item.type)}/>
                  <Text style={[styles.RbBtnWithIconText]}>{item.text}</Text>
                </TouchableOpacity>
              );
            })
            }
          </View>
        </View>
        }
        {!isEmpty(buttons) && buttons.length === 1 &&
        <View style={[AtomicCss.marginBottom10, styles.cardBorder]}>
          <View style={[styles.infoCardWrapper]}>
            <View style={styles.headingIconWrapper}>
              <Image style={styles.headingIconStyle} source={infoIcon}/>
            </View>
            <View style={styles.infoCardContent}>
              <Text style={[AtomicCss.defaultText, AtomicCss.blackFont, AtomicCss.font14]}>STILL
                CAN’T
                FIND WHAT YOU NEED?</Text>
              <View style={styles.buttonWrapper}>
                <Button
                  button={buttons[0]}
                  handleClick={this.props.handleButtonClick}
                />
              </View>
            </View>
          </View>
        </View>
        }
      </View>
    );
  }
}

const styles = {
  cardBorder: {
    borderTopColor: '#e4e4e4',
    borderBottomColor: '#e4e4e4',
    borderTopWidth: 1,
    borderBottomWidth: 1
  },
  infoCardWrapper: {
    backgroundColor: '#fff',
    justifyContent: 'space-between',
    flexDirection: 'row',
    ...getPlatformElevation(2),
    marginBottom: 1
  },
  infoCardContent: {
    paddingRight: 8,
    paddingVertical: 15,
    flex: 1
  },
  infoCustom: {
    fontSize: 14,
    color: '#4a4a4a',
    lineHeight: 18,
    fontFamily: fonts.regular
  },
  headingIconWrapper: {
    width: 46,
    alignItems: 'center'
  },
  headingIconStyle: {
    width: 16,
    height: 16,
    top: 15
  },
  buttonWrapper: {
    paddingTop: 20,
    width: '90%'
  },
  btnWrapper: {
    backgroundColor: '#fff',
    paddingVertical: 16,
    paddingHorizontal: 12
  },
  actionBtnHalf: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100 %',
    backgroundColor: '#fff',
    paddingBottom: 10,
    marginTop: 15
  },
  supportWrap: {
    paddingTop: 16,
    paddingBottom: 24
  },
  SupportTxt: {
    width: 125,
    paddingLeft: 6,
    paddingRight: 6,
    backgroundColor: '#fff'
  },
  RbBtnWithIconImg: {
    width: 36,
    height: 36,
    alignSelf: 'center',
    marginLeft: -5
  },
  RbBtnWithIconCall: {
    width: 42,
    height: 42,
    alignSelf: 'center',
    marginTop: -6
  },
  mar3Bt: {
    marginBottom: 3
  },
  RbBtnWithIconText: {
    textAlign: 'center',
    color: '#008cff',
    lineHeight: 20,
    fontFamily: 'Lato-Bold'
  },
  whiteWrapper: {
    backgroundColor: '#ffffff',
    marginBottom: 10,
    ...getPlatformElevation(2),
    marginTop: -2
  },
  CardContent: {
    backgroundColor: '#fff',
    flexDirection: 'row',
    padding: 15,
    ...getPlatformElevation(1)
  },
  ActionBtnHalf: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100 %',
    backgroundColor: '#fff',
    paddingBottom: 10,
    marginTop: 15
  }

};

MMTSupportCard.propTypes = {
  buttons: PropTypes.array.isRequired,
  handleButtonClick: PropTypes.func.isRequired
};

export default MMTSupportCard;
