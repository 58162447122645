import { StyleSheet } from 'react-native';
import { Fonts, Theme } from 'apps/post-sales/src/cab/types';

const getStyle = (fonts: Fonts, theme: Theme) => {
  const { color } = theme;
  return StyleSheet.create({
    heading: {
      marginBottom: 8
    },
    nameText: {
      color: color.defaultTextColor,
      ...fonts.regularFontFamily,
      fontSize: fonts.fontSize.font14,
    },
    priceText: {
      color: color.black,
      ...fonts.boldFontFamily,
      fontSize: fonts.fontSize.font14,
    },
    specialRequestCard: {
      backgroundColor: color.white,
      borderRadius: 16,
      padding: 14,
    },
    tickImage: {
      width: 12,
      height: 12,
      marginRight: 3,
    },
  });
};

export default getStyle;
