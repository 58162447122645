import React from 'react'
import { View } from 'react-native';
import { getStaticData } from '../../staticData/staticData';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import SimpleHeader from '@mmt/legacy-commons/Common/Components/Header/SimpleHeader';
import PostSalesBookingTrackingHelper from '../../PostSalesBookingTrackingHelper';
import { AUTH_ERROR_PAGENAME, AUTH_FAILED_MESSAGE } from '../../PostSalesConstant';

const AuthErrorView = ({bookingId, lobName, onGoBackPress}) => {
  PostSalesBookingTrackingHelper.trackErrorWithData(AUTH_ERROR_PAGENAME, bookingId, AUTH_FAILED_MESSAGE, lobName);
  const staticData = getStaticData();
  return (
    <View style={{ flex: 1 }}>
      <SimpleHeader title={staticData.backText} iconPress={onGoBackPress} />
      <ErrorView
        onRetry={() => {}}
        message={staticData.errorText.authFailedErrorMessage}
      />
    </View>
  );
}

export default AuthErrorView
