import React from 'react';
import {View, Text, Image, StyleSheet} from 'react-native';
import styles from './ProfileVerificationCardCss';
import {isEmpty} from 'lodash';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import HtmlView from "react-native-htmlview";
import {getButtonStyle, handleZcClickEvent} from "../../../utils/SelfDriveBookingDetailUtil";
import {getActionButton} from "../../../../Common/commonUtil";
import {PROFILE_VERI_ICON} from '../../../SelfDriveImageConstant';

class ProfileVerificationCard extends React.Component {
    render() {
        return (
            <View style={[AtomicCss.marginBottom10, styles.cardBorder]}>
                <View style={[styles.infoCardWrapper]}>
                    <View style={styles.headingIconWrapper}>
                        <Image style={styles.headingIconStyle} source={PROFILE_VERI_ICON}/>
                    </View>
                    <View style={styles.infoCardContent}>
                        <Text
                            style={[AtomicCss.defaultText, AtomicCss.blackFont, AtomicCss.font14, AtomicCss.marginBottom15]}>{this.props.card.header}
                        </Text>
                        {this.returnDescription(this.props.card)}
                    </View>
                </View>
                {!isEmpty(this.props.card.actionList) &&
                <View
                    style={!isEmpty(this.props.card) ? !isEmpty(this.props.card.actionList) ? getButtonStyle(this.props.card.actionList) : styles.hideView : styles.hideView}>
                    {!isEmpty(this.props.card) ? getActionButton(this.props.card, this.props.response, this.handleClick) : null}
                </View>
                }
                {/*<TouchableOpacity style={styles.redirectBtnWrapper} activeOpacity={.8}>
                <Text style={styles.linkBtnTxt}>See Uploaded Documents</Text>
                <Image style={styles.rightArrowIcon} source={rightArrow} />
            </TouchableOpacity>*/}
            </View>
        )
    }

    handleClick = (action) => {
        handleZcClickEvent(this.props.card, action, this.props.response, this.props.pageName).then(r => "");
    };

    returnDescription = function (card) {
        const views = [];
        Object.keys(card.subHeaders).forEach((index) => {
            views.push(<View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom10]}>
                <HtmlView
                    value={card.subHeaders[index]}
                    stylesheet={stylesText}
                />
            </View>)
        })
        return views;
    }
}

const
    stylesText = StyleSheet.create({
        b: {
            color: colors.azure,
            fontFamily: fonts.bold
        },
        text: {
            fontFamily: fonts.regular,
            fontSize: 14,
            color: colors.darkGrey
        }
    });

export default ProfileVerificationCard
