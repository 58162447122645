import React from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import styles from './SpecialRequestCardCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { imageTransformY } from '../../../../../utils/PostSaleUtil';
import { SPECIAL_REQUEST, RIGHT_ARROW } from '../../../../FlightImageConstants';
import { getFont } from '../../../../../PostSalesStyles';

const SpecialRequestCard = ({ cardHeaderText, cardSubText, actionItemInfoList, onBtnClick }) => {
  const { fsStyle, ...fonts } = getFont(true);
  return (
    <View style={[AtomicCss.marginBottom10, styles.cardBorder]}>
      <View style={[styles.infoCardWrapper]}>
        <View style={styles.headingIconWrapper}>
          <Image style={[styles.headingIconStyle, imageTransformY]} source={SPECIAL_REQUEST} />
        </View>
        <View style={styles.infoCardContent}>
          <Text
            style={[
              AtomicCss.defaultText,
              fonts.blackFontFamily,
              fsStyle.font14,
              AtomicCss.marginBottom15,
              AtomicCss.alignLeft,
            ]}
          >
            {cardHeaderText}
          </Text>
          <Text
            style={[
              fonts.regularFontFamily,
              fsStyle.font14,
              AtomicCss.defaultText,
              AtomicCss.lineHeight20,
              AtomicCss.alignLeft,
            ]}
          >
            {cardSubText}
          </Text>
        </View>
      </View>
      {!isEmpty(actionItemInfoList) &&
        actionItemInfoList.map((item) => (
          <TouchableOpacity
            style={styles.redirectBtnWrapper}
            activeOpacity={0.8}
            onPress={() => {
              onBtnClick(item.actionId);
            }}
          >
            <Text style={[styles.linkBtnTxt, fonts.boldFontFamily, fsStyle.font14]}>
              {item.actionText}
            </Text>
            <Image style={[styles.rightArrowIcon, imageTransformY]} source={RIGHT_ARROW} />
          </TouchableOpacity>
        ))}
    </View>
  );
};

export default SpecialRequestCard;

SpecialRequestCard.propTypes = {
  actionItemInfoList: PropTypes.array.isRequired,
  cardHeaderText: PropTypes.string.isRequired,
  cardSubText: PropTypes.string.isRequired,
  onBtnClick: PropTypes.func.isRequired,
};
