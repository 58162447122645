import React, { useEffect } from 'react';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';

import Thankyou from '../../../Common/thankyou';
import FlightBookingTrackingHelper from '../details/helper/FlightBookingTrackingHelper';
import { resetToFlightBookingDetail } from '../../FlightBookingUtil';
import ProgressView from '../../../Common/ProgressView/ProgressView';
import { PART_PAYMENT_DONE_STATUS } from '../../../utils/NetworkUtils';
import useApi from '../../../Common/useApi';
import { getStaticData } from '../../../staticData/staticData';
import { LOBNAMES } from '../../../PostSalesConstant';


interface PartPaymentTYPageProps {}

const PartPaymentTYPage: React.FC<PartPaymentTYPageProps> = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const bookingId = urlParams.get('bookingId') || '';
  const txnId = urlParams.get('txnId') || '';
  const [partPayInProgress, partPayResponse, partPayApi] = useApi(PART_PAYMENT_DONE_STATUS + `${bookingId}/${txnId}`);
  const { heading , subHeading, actionList = [], theme, error } = partPayResponse || {};
  const iconUrl = theme !== 'error'
  ? 'https://imgak.mmtcdn.com/mima/images/mobile/AncillaryAddOnsSuccessfull_Icon.webp'
  : 'https://imgak.mmtcdn.com/mima/images/mobile/AncillaryBookingFailed_Icon.webp';

  useEffect(() => {
    FlightBookingTrackingHelper.trackLoadEvent('Part_Payment_thank_you', {});
  }, []);

  const clickHandler = () => {
    if (bookingId) {
      resetToFlightBookingDetail(bookingId);
    }
  };

  const partPaymentRequest = async () => {
    try {
      if (partPayApi) {
        partPayApi.httpGet({
          bookingId: bookingId,
          psLob: LOBNAMES.FLIGHT,
          uniqueId: "FLIGHT_PARTPAY_TY",
        });
      }
    } catch (error) {
      console.error("Error making API call:", error);
    }
  };

  useEffect(() => {
    if (txnId) {
      partPaymentRequest();
    }
  }, []);

  const actions = [
    {
      text: actionList?.[0]?.cta,
      clickHandler,
      variant: 'PRIMARY',
      shadow: true,
      id: actionList?.[0]?.actionId,
    },
  ];
  const onRetry = () => {
    if (txnId) {
      partPaymentRequest();
    }
  }
  const renderErrorView = () => {
    const {
      errorText: { somethingWentWrongTryAgainError },
      tryAgainText,
    } = getStaticData();

    return (
      <ErrorView
        heading={somethingWentWrongTryAgainError}
        buttonLabel={tryAgainText}
        showRetry
        onRetry={onRetry}
      />
    );
  };
  if (!!error) return renderErrorView();
  return (
    <React.Fragment>
      {partPayInProgress ? (
        <ProgressView message="loading" />
      ) : (
        <Thankyou
          icon={{ uri: iconUrl }}
          description={subHeading}
          message={heading}
          actions={actions}
        />
      )}
    </React.Fragment>
  );
};

export default PartPaymentTYPage;