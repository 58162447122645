import React from 'react';
import {themeColors } from '../../PostSalesStyles';
import styles from './ButtonWrapperCss';
import Button from '@Frontend_Ui_Lib_App/Button';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
const ButtonWrapper = ({
  clickHandler,
  btnText,
  buttonType= "fill",
  btnTxtStyle,
  btnWrpStyle,
  buttonWidth = "full",
  isThrottleRequired = false,
  throttleInterval = 1000,
  disabled = false,
  buttonSize,
  btnStyle,
  bgColors
}) => {
  const { psTheme: theme } = useTheme();
  switch (bgColors) {
    case 'myBiz':
      bgColors = theme.color.myBizGradientColor;
      break;
    default:
      bgColors = theme.color.gradientBtnColor;
      break;
  }
  return (
    <Button
      bgAngle={-90}
      buttonBgColors={bgColors}
      buttonSize= {buttonSize ? buttonSize : 'xl'}
      buttonText={btnText ? btnText : ""}
      buttonType={buttonType}
      buttonWidth={buttonWidth}
      customStyle={{
        buttonWrapperStyle: [
          btnWrpStyle ? btnWrpStyle : {},
        ],
        buttonTextStyle: [
          styles.btnInlineStyle,
          btnTxtStyle ? btnTxtStyle : {},
        ],
        buttonStyle:[
          btnStyle ? btnStyle:{}
        ]
      }}
      onButtonPress={clickHandler}
      useAngle
      throttleInterval={throttleInterval}
      isThrottleRequired={isThrottleRequired}
      disabled ={disabled}
    ></Button>
  );
};
export default ButtonWrapper;