import React from 'react';
import PropTypes from 'prop-types';
import Actions from '../../navigation/postSalesNavigation';
import { View, Text, Image } from 'react-native';
import AtomicCss from '../commonStyles/AtomicCss';
import styles from './AllErrorScreenCss';
import CapsuleBtnFill from '../../hotel/details/ActionButton/CapsuleBtnFill';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import ProgressView from '../../Common/ProgressView/ProgressView';
import NoInternetView from '@mmt/legacy-commons/Common/Components/Error/NoInternetView';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import SimpleHeader from '@mmt/legacy-commons/Common/Components/Header/SimpleHeader';
import { isNetworkAvailable } from '@mmt/legacy-commons/Common/utils/AppUtils';
import { getCommonHeaders, RAIL_BOOKING_CANCEL_URL } from '../../utils/NetworkUtils';
import RailBookingTrackingHelper from '../details/RailBookingTrackEventHelper';
import { invalidateTrips } from '@mmt/legacy-commons/Helpers/genericHelper';
import {COMMIT, DEAFULTREFUNDMODE, POST } from '../../PostSalesConstant';
import * as NpsModule from '@mmt/legacy-commons/Native/NpsModule';
import { getRatingData, isNotNullAndEmpty } from '../../Common/commonUtil';
import { ERROR_ICON, BOOKING_CANCEL_PAGE_NAME } from '../Utils/RailImageConstant';
import { BackHandler } from 'react-native';
import { getStaticData } from '../../staticData/staticData';
import { isEmpty } from 'lodash';
import BusCrossSell from '../common/BusCrossSell';
import { getFont } from '../../PostSalesStyles';
import { POST_SALES_TRAIN_CANCELLATION_SUCCESS_PAGE } from '@mmt/legacy-commons/adsConfig/adsConfigConstants';
import { getAdTechCard } from '../../Common/commonUtil';

export default class BookingCancel extends React.Component {
  static navigationOptions = {
    header: null,
  };
  constructor(props) {
    super(props);
    this.trackingPageName = 'RailBookingCancel';
    this.state = {
      viewState: ViewState.LOADING,
      responseJson: null,
    };
    this.staticData = getStaticData();
    this.errorMessage = this.staticData.errorText.somethingWentWrongTryAgainError;
    this.showRatingPrompt = true;
  }
  componentDidMount() {
    this.makeCancelBookingAPI();
    BackHandler.addEventListener('hardwareBackPress', this.clickHandler);
  }

  componentWillUnmount() {
    BackHandler.removeEventListener('hardwareBackPress', this.clickHandler);
  }
  goBack = () => {
    Actions.pop();
  };

  render() {
    return (
      <View style={{ flex: 1 }}>
        {this.state.viewState === ViewState.LOADING && this.renderProgressView()}
        {this.state.viewState === ViewState.NO_INTERNET && this.renderNoNetworkView()}
        {this.state.viewState === ViewState.ERROR && this.renderErrorView()}
        {this.state.viewState === ViewState.SHOW_DETAIL && this.renderSuccessPage()}
      </View>
    );
  }
  clickHandler = () => {
    if (this.props.bookingID) {
      const data = {
        BOOKING_ID: this.props.bookingID,
        MMT_AUTH: this.props.mmtAuth,
        LOGGING_TRACKINFO: this.props.loggingTrackInfo,
        refresh: true,
      };
      Actions.railBookingDetail({ data: data, BOOKING_ID: this.props.bookingID });
    } else {
      Actions.popTo('railBookingDetail');
    }
    RailBookingTrackingHelper.trackClickEvent(
      'cancellationCompleted',
      this.props.bookingDetails,
      'backToBookingDetailClicked',
    );
    return true;
  };
  renderProgressView = () => (
    <ProgressView message={this.staticData.loadingText ? this.staticData.loadingText : ''} />
  );

  renderErrorView = () => {
    const {errorPageData} = this.state.responseJson || {};
    const {heading,description} = errorPageData || {};
    const {ctaList} = errorPageData || {}
    const {actionText} = ctaList && ctaList.length ? ctaList[0] : {};
   return( <View style={{ flex: 1 }}>
      <SimpleHeader iconPress={this.goBack} />
      <ErrorView
        showRetry
        message={heading}
        errorDesc={description}
        buttonLabel = {actionText}
        onRetry={() => {
         this.clickHandler()
        }}
      />
    </View>)
  }

  renderNoNetworkView = () => (
    <View style={{ flex: 1 }}>
      <SimpleHeader title={this.staticData.backText} iconPress={this.goBack} />
      <NoInternetView
        buttonText={this.staticData.tryAgainText}
        onRetry={() => {
          this.setState({ viewState: ViewState.LOADING });
          this.makeCancelBookingAPI();
        }}
      />
    </View>
  );

  renderAdTechCard = () => {
    return getAdTechCard(POST_SALES_TRAIN_CANCELLATION_SUCCESS_PAGE);
  };

  renderSuccessPage = () => {
    let cancelledCount = 0;
    if (this.props.cancelledPaxLineId) {
      cancelledCount = this.props.cancelledPaxLineId.length;
    }
    RailBookingTrackingHelper.trackLoadEvent('cancellationCompleted', this.props.bookingDetails);
    const {
      somePassangersCancelledText,
      allPassangersCancelledText,
      activeTravellerText,
      noRefundApplicableText,
      refundUnderProcessText,
      successfulBookingCancelledText,
    } = this.staticData;
    let titleMessage = this.props.isFullCancellation
      ? allPassangersCancelledText
      : somePassangersCancelledText;
    titleMessage = titleMessage
      .replace('{cancelledCount}', cancelledCount)
      .replace('{totalPaxCount}', this.props.activePaxCount);
    const refundMessageText =
      this.props.refundAmount > 0 ? refundUnderProcessText : noRefundApplicableText;
    let cancellationSubTitletext = `${successfulBookingCancelledText} ${refundMessageText}`;
    const remainingPaxCount = this.props.activePaxCount - cancelledCount;
    if (!this.props.isFullCancellation && remainingPaxCount > 0) {
      cancellationSubTitletext = `${cancellationSubTitletext} ${activeTravellerText}`;
      cancellationSubTitletext = cancellationSubTitletext.replace(
        '{activeTraveller}',
        remainingPaxCount,
      );
    }
    const { busCrossSell } = this.state.responseJson || {};
    const { ratingData, npsData } = getRatingData(this.trackingPageName, this.state.responseJson);
    if (ratingData && this.showRatingPrompt) {
      NpsModule.showRatingPrompt(ratingData, npsData);
      this.showRatingPrompt = false;
    }
    const { fsStyle } = getFont(true);
    return (
      <View style={styles.FullPageErrorPage}>
        <Image style={[styles.ErrorImg1, AtomicCss.marginBottom20]} source={ERROR_ICON} />
        <Text style={[styles.ErrorHeading, AtomicCss.marginBottom20, fsStyle.font20]}>
          {titleMessage}
        </Text>
        <Text style={[styles.ErrorDisc, styles.lastDisc, fsStyle.font14]}>
          {cancellationSubTitletext}
        </Text>
        {!isEmpty(busCrossSell) && (
          <BusCrossSell cardData={busCrossSell} page={BOOKING_CANCEL_PAGE_NAME} />
        )}
        <CapsuleBtnFill
          action={{ actionLabeltext: this.staticData.viewUpdatedBookingText }}
          clickHandler={this.clickHandler}
        />
        <View style = {styles.adTechCardView}>
          {this.renderAdTechCard()}
        </View>
      </View>
    );
  };

  requestBody = () => {
    const requestObject = {
      authId: this.props.mmtAuth,
      userId: 'MMT/MIMA',
      action: COMMIT,
      refundOption: this.props.selectedRefundMode,
      functionType: 'CancellationRequest',
      bookingID: this.props.bookingID,
      isFullCancellation: this.props.isFullCancellation,
      ticketList: this.props.cancelledPaxLineId,
      instantRefundData: this.props.instantData,
      reason: this.props.reason,
      authorizationToken: this.props.authorizationToken,
    };
    return requestObject;
  };
  async makeCancelBookingAPI() {
    try {
      const hasNetwork = await isNetworkAvailable();
      if (!hasNetwork) {
        this.setState({ viewState: ViewState.NO_INTERNET });
        return;
      }
      const url = RAIL_BOOKING_CANCEL_URL;
      const commonHeaders = await getCommonHeaders(this.props.mmtAuth, this.props.loggingTrackInfo);
      const response = await fetch(url, {
        method: POST,
        headers: commonHeaders,
        body: JSON.stringify(this.requestBody()),
      });
      if (response.ok) {
        const responseBody = await response.json();
        if (responseBody && responseBody.cancelled) {
          this.setState({
            viewState: ViewState.SHOW_DETAIL,
            responseJson: responseBody,
          });
          invalidateTrips();
        } else {
          if (responseBody.response && responseBody.response.errorText) {
            this.errorMessage = responseBody.response.errorText;
          }
          this.setState({ viewState: ViewState.ERROR,responseJson: responseBody });
          this.trackError();
        }
      } else {
        this.setState({ viewState: ViewState.ERROR });
        this.trackError();
      }
    } catch (error) {
      this.setState({ viewState: ViewState.ERROR });
      this.trackError();
    }
  }

  trackError = () => {
    RailBookingTrackingHelper.trackErrorEvent('cancellationCompletedError');
  };
}

BookingCancel.propTypes = {
  isFullCancellation: PropTypes.bool.isRequired,
  totalPaxCount: PropTypes.number.isRequired,
  cancelledPaxLineId: PropTypes.array.isRequired,
  mmtAuth: PropTypes.string.isRequired,
  loggingTrackInfo: PropTypes.string.isRequired,
  bookingID: PropTypes.string.isRequired,
  bookingDetails: PropTypes.object.isRequired,
  refundAmount: PropTypes.number.isRequired,
  selectedRefundMode: PropTypes.string,
  instantData: PropTypes.object,
  reason: PropTypes.string,
  busCrossSell: PropTypes.object,
};

BookingCancel.defaultProps = {
  selectedRefundMode: DEAFULTREFUNDMODE,
  instantData: null,
};
