import React from 'react';
import { View, Text, ScrollView, DeviceEventEmitter } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import createStyles from './changeDateOverlayCss';
// import changTvlrIcon from './images/calendarIcon.png';
// import blueArrow from './images/blueArrow.png';
// import OverlayMessage from '../../HelpAndSupport/common/OverlayMessage';
// import ChangeDateOverlay from './BottomSheet/ChangeDateOverlay';
import { getFont } from '../../../PostSalesStyles';
import { useTheme } from '../../../theme/theme.context';
import HTMLViewWrapper from '../../../Common/HTMLViewWrapper';
import isEmpty from 'lodash/isEmpty';
import StatusTracker from './StatusTracker';
import FrequentlyAsked from './FrequentlyAsked';
import BaseButton from '../../../Common/BaseButton';
import BusCancelRebookConstant from '../../page/CancelRebookConstants';
function ChangeDateOverlay({ details, handleOverlay, handleDateChange }) {
  const { flexiTicketDetail } = details || {};
  const { content, heading, subHeading, cta } = flexiTicketDetail || {};
  const { title, buttonType } = cta || {};
  const { stepsToFollow, faqs } = content || {};
  const { fsStyle, ...fonts } = getFont();
  const {psTheme : theme} = useTheme();
  const styles = createStyles(theme, fonts);
  const handleButtonClick = () => {
      DeviceEventEmitter.removeAllListeners(BusCancelRebookConstant.CALENDAR_EVENT_RECEIVED);
      DeviceEventEmitter.addListener(BusCancelRebookConstant.CALENDAR_EVENT_RECEIVED,handleDateChange);
      handleOverlay();
  }
  return (
    <>
      {flexiTicketDetail && (
        <View style={styles.OverlayContentWrapper}>
          <View style={AtomicCss.marginBottom24}>
            <View style={styles.overlayTopline}></View>
            <View>
              {!!heading && (
                <Text style={[[styles.headingTxt, AtomicCss.marginBottom5]]}>{heading}</Text>
              )}
              {!!subHeading && <HTMLViewWrapper htmlContent={subHeading} />}
            </View>
          </View>
          <ScrollView>
              {!isEmpty(stepsToFollow) && <StatusTracker data={stepsToFollow} />}
              {!isEmpty(faqs) && <FrequentlyAsked faqs={faqs} />}
              <BaseButton
                text={title}
                variant={buttonType}
                clickHandler={handleButtonClick}
                textStyle={fsStyle.font16}
              />
          </ScrollView>
        </View>
      )}
    </>
  );
}
export default ChangeDateOverlay;
