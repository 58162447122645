import {WEBBODY_TOP_SPACING} from './Constants';

const webStyles = {
  pageWrapper: {
    paddingTop: WEBBODY_TOP_SPACING
  },
  webEllipsisText: {
    overflow: 'hidden',
    display: 'box'
    // lineClamp: 3,
    // boxOrient: 'vertical'
  }
};

export default webStyles;
