import { isEmpty } from 'lodash';
import React, { FC } from 'react';
import { View, StyleSheet, Text, TouchableOpacity, Image, Platform } from 'react-native';
import BaseButton, { BUTTON_VARIANT } from '../../../../../../../Common/BaseButton';
import { getFont, themeColors } from '../../../../../../../PostSalesStyles';
import { FLIGHT_THROTTLE_OBJECT } from 'apps/post-sales/src/flights/modules/details/FlightBookingDetailsConstant';
const crossIcon = require('@mmt/legacy-assets/src/iconCross.webp');

interface KnowMoreList {
    heading: string;
    subHeading: string;
}

interface RefundBottomSheetProps {
    title: string;
    icon: string;
    ctaText: string;
    knowMoreList: KnowMoreList[],
    handleBottomOverlay: (arg: string) => void;
}

const RefundBottomSheet: FC<RefundBottomSheetProps> = ({ title, icon, ctaText, knowMoreList, handleBottomOverlay }) => {
    const { fsStyle, ...fonts } = getFont(true);

    return (
        <View style={styles.wrapper}>
            <View style={styles.headingSection}>
                <Text style={[styles.headingText, fonts.font16, fonts.boldFontFamily]}>{title}</Text>
                <TouchableOpacity onPress={() => handleBottomOverlay()}>
                    <Image style={styles.crossIconStyle} source={crossIcon} />
                </TouchableOpacity>
            </View>
            <View style={[styles.payIconWrapper]}>
                <Image style={styles.payIconStyles} source={ {uri: icon}} />
            </View>

            {knowMoreList && knowMoreList.map(element => {
                return (<View style={styles.detailsSection}>
                    { !isEmpty(element) && !isEmpty(element.heading) && <Text style={[fonts.font14, fonts.boldFontFamily]}>
                        {element.heading}
                    </Text>
                    }
                   { !isEmpty(element) && !isEmpty(element.subHeading) && <Text style={[fonts.font14, fonts.regularFont, styles.marginTop5]}>
                        {element.subHeading}
                    </Text>}
                </View>)
            })
            }
            <View>
                <BaseButton
                    text={ctaText}
                    variant={BUTTON_VARIANT.PRIMARY}
                    clickHandler={handleBottomOverlay}
                    {...FLIGHT_THROTTLE_OBJECT}
                />
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    wrapper: {
        padding: 10
    },
    headingSection: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 20,
    },
    crossIconStyle: {
        width: 24,
        height: 24,
    },
    detailsSection: {
        marginBottom: 20,
    },
    marginTop5: {
        marginTop: 5
    },
    payIconWrapper: {
        marginVertical: 30,
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: 30
    },
    payIconStyles: {
        width: 160,
        height: 160,
    }
});

export default RefundBottomSheet;
