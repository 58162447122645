import { Fonts, Theme } from 'apps/post-sales/src/cab/types';
import { Dimensions, StyleSheet } from 'react-native';

const getStyle = (fonts: Fonts, theme: Theme) => {
  const { color } = theme;
  return StyleSheet.create({
    headingSection: {
      paddingHorizontal: 20,
      paddingTop: 15,
    },
    headingText: {
      color: color.black22,
      ...fonts.blackFontFamily,
      fontSize: fonts.fontSize.font18,
      marginBottom: 5,
    },

    crossIconStyle: {
      width: 15,
      height: 15,
      tintColor: color.greyText1,
    },
    moreInfo: {
      paddingVertical: 0,
      paddingHorizontal: 16,
      marginBottom: 30,
    },

    downloadInvoiceSection: {
      padding: 14,
      borderRadius: 12,
      borderWidth: 1,
      borderColor: color.lightGray,
      marginTop: 10,
      backgroundColor: color.white,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },

    downloadIcon: {
      width: 20,
      height: 20,
    },

    toastText: {
      color: color.white,
      lineHeight: 20,
      padding: 4,
      fontSize: fonts.fontSize.font14
    },

    toastDtls: {
      borderRadius: 32,
      width: 200,
      alignItems: 'center',
      elevation: 11,
      zIndex: 11,
      marginLeft: Dimensions.get('window').width / 2 - 107,
      backgroundColor: color.textGrey,
      height: 35,
    },
    OverlayContentWrapper: {
      backgroundColor: color.grayBg,
      paddingHorizontal: 12,
      position: 'relative',
      zIndex: 1,
      width: '100%',
      borderTopLeftRadius: 16,
      borderTopRightRadius: 16,
    },
    overlayTopline: {
      width: 40,
      height: 4,
      borderRadius: 4,
      backgroundColor: color.faintgrey,
      alignSelf: 'center',
      marginTop: 5,
    },

    errorScreenWrapper: {
      height: 300,
      alignItems: 'center',
      justifyContent: 'center',
    },
    errorIcon: {
      width: 52,
      height: 47,
      marginBottom: 40,
    },
    titleText : {
      color: color.black,
      ...fonts.boldFontFamily,
      fontSize: fonts.fontSize.font14
    },
    subHeaderText: {
      color: color.black74,
      ...fonts.regularFontFamily,
      fontSize: fonts.fontSize.font12
    },
    viewMoreText: {
      color: color.azure,
      ...fonts.boldFontFamily,
      fontSize: fonts.fontSize.font14
    }
  });
};

export default getStyle;
