import React, {useCallback, useRef} from 'react';
import {Text, TouchableOpacity, Image, View, Platform, ActivityIndicator} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import PropTypes from 'prop-types';
import styles from './ButtonCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { RIGHT_ARROW_BLUE_ICON } from '../../flights/FlightImageConstants';
import {themeColors} from '../../PostSalesStyles';
import { THEME } from '../../PostSalesConstant';

let gradientColor = ['#065af3', '#53b2fe'];


const Button = (props) => {
  const CapsuleBtnStyle = [styles.capsuleBtn];
  const buttonStyle = [styles.buttonStyle];
  const buttonTxtStyle = [styles.buttonTxtStyle];
  const { btnWidth, noShadowStyle, minWidth } = props;
  const lastClickTimeRef = useRef(0);

  const wrapperThrottle = () => {
    if (props.isThrottleRequired) {
      throttledClickHandler();
    } else {
      props.clickHandler(props.btnClickAction);
    }
  };

  const throttledClickHandler = useCallback(() => {
    const currentTime = Date.now();
    if (currentTime - lastClickTimeRef.current >= props.throttleInterval) {
      lastClickTimeRef.current = currentTime;
      props.clickHandler(props.btnClickAction);
    }
  }, [props.clickHandler, props.throttleInterval]);

  if (props.btnBg === 'white') {
    gradientColor = ['#fff', '#fff'];
    buttonTxtStyle.push({color:themeColors.whiteBtnTxt});
    buttonStyle.push(styles.whiteButtonStyle);
    if(props.theme === THEME.MYBIZ) {
      buttonTxtStyle.push(styles.orangeBtnTxt)
    }
  } else if (props.btnBg === 'whiteWithBorder') {
    gradientColor = ['#fff', '#fff'];
    buttonTxtStyle.push({color:themeColors.whiteBtnTxt});
    buttonStyle.push(styles.whiteButtonBorderStyle,{ borderColor:themeColors.whiteButtonBorderColor });
    CapsuleBtnStyle.push(styles.noShadowStyle);
    if(props.theme === THEME.MYBIZ) {
      buttonTxtStyle.push(styles.orangeBtnTxt)
      buttonStyle.push(styles.orangeButtonBorderStyle)
    }
  } else if (props.btnBg === 'gradientBtn') {
    gradientColor = themeColors.gradientBtnColor;
    Platform.OS === "web" && buttonStyle.push(styles.noShadowStyle);
    buttonTxtStyle.push(styles.gradientBtnTxt);
  }
  if (props.btnBg === 'gradientBtn' && props.btnType === 'flat') {
    gradientColor = themeColors.gradientBtnColor;
    buttonTxtStyle.push(styles.gradientBtnTxt);
    CapsuleBtnStyle.push(styles.flatBtn);
  }
  if(props.isCorporateBooking) {
    gradientColor = ['#f85a6e', '#f27237'];
  }
  if (props.btnBg === 'gradientBtn' && props.theme === THEME.MYBIZ && props.btnType === 'flat') {
    gradientColor = ['#F95776', '#F0772C']
    buttonTxtStyle.push(styles.gradientBtnTxt)
    CapsuleBtnStyle.push(styles.flatBtn)
  }

  if (props.btnBg === 'disableGrey' && props.btnType === 'flat') {
    gradientColor = ['#c2c2c2', '#c2c2c2'];
    buttonTxtStyle.push(styles.disableGaeytBtnTxt);
    CapsuleBtnStyle.push(styles.flatBtn);
  }

  if (props.btnBg === 'b2bGradientBtn') {
    gradientColor = ['#f85a6e', '#f27237'];
    buttonTxtStyle.push(styles.gradientBtnTxt);
  }

  if (props.disable) {
    gradientColor = ['#C2C2C2', '#C2C2C2'];
  }

  return (
    <TouchableOpacity
      style={[buttonStyle, { width: btnWidth, minWidth }]}
      activeOpacity={0.8}
      onPress={() => {
        if (!props.disable) {
          wrapperThrottle()
        }
      }
      }
    >
      <LinearGradient
        start={{x: 1.0, y: 0.0}}
        end={{x: 0.0, y: 1.0}}
        colors={gradientColor}
        style={CapsuleBtnStyle}
      >
        <View style={{ flexDirection: 'row', alignSelf: 'center' }}>
          {props.spinner ? 
            <ActivityIndicator size={props.spinnerSize} color={props.spinnerColor} /> :
            <Text style={[buttonTxtStyle, AtomicCss[props.fontSize], props.buttonTextCss]}>{props.btnTxt}</Text>
          }
          {props.isRtArrow &&
            <Image style={styles.arrowRtStyle} source={RIGHT_ARROW_BLUE_ICON} />
          }
        </View>
      </LinearGradient>
    </TouchableOpacity>

  );
};


export default Button;

Button.propTypes = {
  btnBg: PropTypes.string.isRequired,
  clickHandler: PropTypes.func.isRequired,
  btnType: PropTypes.string,
  btnTxt: PropTypes.string.isRequired,
  btnClickAction: PropTypes.string.isRequired,
  disable: PropTypes.bool,
  isRtArrow: PropTypes.bool,
  theme: PropTypes.string,
  fontSize: PropTypes.string,
};

Button.defaultProps = {
  btnType: undefined,
  disable: false,
  isRtArrow: false,
  theme: ''
};
