import React from 'react';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { View, Text, StyleSheet, TouchableOpacity, Image, Platform } from 'react-native';
import { backArrowIOS } from 'apps/post-sales/src/Holiday/HolidayBookingConstants';
import backArrow from '@mmt/legacy-assets/src/back-white.webp';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import createStyles from './style';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import { SelectedFilter } from '../../../../types';


interface FilterHeaderProps {
  heading: string;
  clearAllText: string;
  handleClose: () => void;
  handleRemoveAllFilters: () => void;
  selectedFilters: SelectedFilter[]
}

const FilterHeader: React.FC<FilterHeaderProps> = ({
  heading,
  clearAllText,
  handleClose,
  handleRemoveAllFilters,
  selectedFilters,
}) => {
  const goBack = () => {
    handleClose();
  };

  const fonts = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);

  return (
    <>
      <View style={[AtomicCss.flex1, AtomicCss.flexRow, AtomicCss.alignCenter]}>
        <TouchableOpacity
          style={styles.backArrowWrapper}
          onPress={goBack}
        >
          <Image
            style={Platform.OS === 'ios' ? [styles.backArrowIos] : [styles.backArrow]}
            source={Platform.OS === 'ios' ? backArrowIOS : backArrow}
          />
        </TouchableOpacity>
        <Text style={[AtomicCss.font16, AtomicCss.blackText, AtomicCss.boldFont]}>{heading}</Text>
      </View>
      <TouchableOpacity onPress={handleRemoveAllFilters}>
        <Text style={[styles.clearAllText, selectedFilters.length > 0 ? styles.clearAlltextBlue : {} ] }>{clearAllText}</Text>
      </TouchableOpacity>
    </>
  );
};

export default FilterHeader;