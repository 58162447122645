import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { fonts, colors } from '@mmt/legacy-commons/Styles/globalStyles';
import HTMLView from 'react-native-htmlview';
import {
    VIEW_MORE_TEXT,
    VIEW_LESS_TEXT
} from "../HolidayBookingConstants";
import { themeColors } from '../../PostSalesStyles';

const FullDescription = ({
    fullDesc
}) => {
    if (!fullDesc) {
        return null;
    }

    const [isClippedDesc, setIsClippedDesc] = useState(true);
    const CLIP_LENGTH = 200;

    useEffect(() => {
        if (fullDesc.length <= CLIP_LENGTH) {
            setIsClippedDesc(false);
        }
    }, [])

    const handleViewMore = () => {
        setIsClippedDesc(!isClippedDesc)
    }

    const description = isClippedDesc ? `${fullDesc.slice(0, CLIP_LENGTH)}...` : fullDesc;
    const viewMoreLessTxt = isClippedDesc ? VIEW_MORE_TEXT : VIEW_LESS_TEXT;

    return (
        fullDesc.length > CLIP_LENGTH ?
                (
                    <View style={styles.fullDesc}>
                        <HTMLView value={description} stylesheet={htmlStyle}></HTMLView>
                        <Text style={styles.linkTxt} onPress={handleViewMore}>{viewMoreLessTxt}</Text>
                    </View>
                ) :
                (
                    <View>
                    <HTMLView value={fullDesc} stylesheet={htmlStyle} />
                    </View>
                )
    )
}


const htmlStyle = StyleSheet.create({
    q: { /* fix fo Html Fonts Not working without adding blank tag */

    },
    p: {
        color: colors.greyLight,
        fontFamily: fonts.regular,
        fontSize: 14,
        lineHeight: 21,
        marginRight: 20,
    },
    a: {
        color: themeColors.linkTextColor,
        fontFamily: fonts.bold,
        fontSize: 14,
    }
});

const styles = StyleSheet.create({
    fullDesc: {
        color: colors.red,
        fontFamily: fonts.regular,
        fontSize: 14,
        lineHeight: 21,
        marginRight: 20,
    },
    linkTxt: {
        color: themeColors.linkTextColor,
        fontFamily: fonts.bold,
        fontSize: 14,
    },
})

export default FullDescription