import { StyleSheet } from 'react-native';

export default (theme) => StyleSheet.create({
  flexRow: {
    flexDirection: 'row',
  },
  flex1: { flex: 1 },
  alignCenter: {
    alignItems: 'center',
  },
  spaceBetween: {
    justifyContent: 'space-bwetween',
  },
  marginBottom5: {
    marginBottom: 5,
  },
  marginBottom10: {
    marginBottom: 10,
  },
  marginBottom20: {
    marginBottom: 20,
  },
  marginRight20: {
    marginRight: 20,
  },
  flexRow: {
    flexDirection: 'row',
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
  LightText: {
    color: theme.color.lightTextColor,
  },
  darkTxt: {
    color: theme.color.textGrey,
  },
  blackTxt: {
    color: theme.color.black,
  },
  Flt_Icon: {
    width: 20,
    height: 20,
    marginRight: 6,
  },
  policyCardWrapper: {
    backgroundColor: theme.color.white,
    marginVertical: 6,
    paddingBottom: 10,
    borderTopWidth: 1,
    borderTopColor: theme.color.grey8,
    borderBottomWidth: 1,
    borderBottomColor: theme.color.grey8,
    paddingHorizontal: 8,
  },
  innerSectionDetails: {
    paddingHorizontal: 6,
    paddingTop: 20,
    paddingBottom: 13,
    backgroundColor: theme.color.white,
  },
  arrowStyle: {
    width: 10,
    height: 16,
  },
  lineStyle: {
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.color.greyBrdr2,
  },
  linearGradient: {
    width: 8,
    borderRadius: 8,
  },
  gredientLine: {
    width: 8,
    marginLeft: 15,
  },
});
