import React from 'react';
import Actions from '../../../../../navigation/postSalesNavigation';
import { View, ScrollView, Alert, Text } from 'react-native';
import PropTypes from 'prop-types';
import { isEmpty, get } from 'lodash';
import SelectPassengers from './components/PassengerSelection';
import styles from './SelectPassengersCss';
import StickyHeader from '../../components/StickyHeader';
import BasePage from '../../../../../Common/PostSalesBasePage';
import Breadcrumb from '../../components/Breadcrumb';
import CancellationTrackingHelper from '../../../../cancellation/Helpers/CancellationTrackingHelper';
import { goToBookingDetails } from '../../../../cancellation/Helpers/FlightCancellationUtility';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import { isNetworkAvailable } from '@mmt/legacy-commons/Common/utils/AppUtils';
import { buildPartialPaxList, getPrimaryContactNo, getReasonForRefund, getSpecialClaimList, makePaxFareSegmentIdList } from '../../data/dataAdapter';
import BlueRectangleButton from '../../../../../Common/BlueRectangleButton';
import { showLongToast } from '@mmt/legacy-commons/Common/Components/Toast';
import ProgressView from '../../../../../Common/ProgressView/ProgressView';
import RequestValidationOverlay from '../../components/RequestValidationOverlay';
import ReasonRefundDtls from '../../components/ReasonRefundDtls';
import {
  FLIGHT_CANCELLATION_REASON,
  FLIGHT_CANCELLATION_REFUND_OPTIONS,
  LOBNAMES,
  NEED_HELP_PAGE,
  NEED_MORE_HELP,
} from '../../../../../PostSalesConstant';
import ErrorView from '../../components/ErrorView';
import { openErrorPage } from '../../../../../utils/PostSaleUtil';
import {
  isOneSegmentAndNotvalid,
  getAllPossibleScActionLists,
  checkPaxSelected
} from '../../helper';
import { SingleSegmentSinglePaxCom } from './components/SingleSegmentSinglePaxCom';
import NeedMoreHelpOverlay from '../../../../../Common/NeedMoreHelpOverlay';
import { getStaticData } from '../../../../../staticData/staticData';
import { HEADER_BACK_ICON } from '../../../../FlightImageConstants';
import { getFont } from '../../../../../PostSalesStyles';
import { isEmptyArray } from "core-ui-lib/helper/Array/isEmptyarray";
import { getFlightFullCancellationRequest } from "core-ui-lib/flight/common/Cancellation/requestAdapter";
import { fetchFlightCancellationDetails } from '../../data/api';
import { getPaxFareSegmentList, getRequestBodyTwo } from '../../../SpecialClaimNew/data/dataAdapter';
import { fetchSpecialClaimPreview } from '../../data/api';
import InformationCard from 'apps/post-sales/src/Common/InformationCard';
import { ThemeContext } from 'apps/post-sales/src/theme/theme.context';

export default class PartialBookingCancellation extends BasePage {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);
    this.paxFareSgmntIdLst = [];
    this.allPaxSelected = false;
    this.state = {
      activeStep: 0,
      FareCancDtls: false,
      responseJson: null,
      viewState: ViewState.SHOW_DETAIL,
      selectedPassenger: {},
      isPopUpOpen: false,
      activeSectorForRefund: {},
      isSingleSegmentAndNotValid: false,
      refundLevel: 'segment',
      helpingHandOverlay: true,
    };
    this.trackingPageName = 'Cancel Select';
  }

  componentDidMount() {
    super.componentDidMount();
    this.trackLoadEvent();
    if (isOneSegmentAndNotvalid(this.props.cancellationPreviewResponseJson)) {
      this.setState({
        isSingleSegmentAndNotValid: true,
        activeStep: 1,
      });
    }
  }

  trackLoadEvent() {
    CancellationTrackingHelper.trackLoadEvent(
      this.trackingPageName,
      this.getTrackingBundle(null),
      true,
    );
  }

  toggleHelpingHandOverlay = (value) => {
    this.setState({
      helpingHandOverlay: value,
    });
  };

  render() {
    return (
      <View style={{ flex: 1 }}>
        {this.state.viewState === ViewState.LOADING && this.renderProgressView()}
        {this.state.viewState === ViewState.SHOW_DETAIL && this.renderPage()}
        {this.state.viewState === ViewState.ERROR && this.renderErrorView()}
      </View>
    );
  }

  renderProgressView = () => {
    const { loadingText } = getStaticData();
    return <ProgressView message={loadingText ? loadingText : ''} />;
  };

  renderHeader = () => {
    const { fsStyle, ...fonts } = getFont(true);
    return (
      <StickyHeader
        iconSrc={HEADER_BACK_ICON}
        title={this.props.headerTitle}
        headerColor={['#ffffff', '#ffffff']}
        titleStyle={[styles.headerTitle, fonts.boldFontFamily, fsStyle.font16]}
        subTitle={this.props.headerSubTItle}
        subTitleStyle={[styles.DefaultText, styles.font12]}
        onBackPress={Actions.pop}
      />
    );
  };

  addSegments = () =>
    this.props.cancellationPreviewResponseJson.flightDetails.segmentGroupDetailList.map(
      (segmentGroup) => (
        <SelectPassengers
          key={segmentGroup.intentRefID}
          segmentGroup={segmentGroup}
          retPartialNtAllowed={this.checkRTPartialNtAllowed()}
          passengerClickHandler={this.onPaxSelectionChanged}
          segmentsGroupView={this.props.segmentsGroupView}
          specialClaimData={
            this.props.cancellationPreviewResponseJson.cancellationValidation
              ? this.props.cancellationPreviewResponseJson.cancellationValidation.specialClaimData
              : {}
          }
          setPopUpOpen={(type = 'segment') => this.setPopUpOpen(type, segmentGroup)}
          cancellationValidation={this.props.cancellationPreviewResponseJson.cancellationValidation}
          componentType="CTIP"
        />
        // null
      ),
    );

  checkRTPartialNtAllowed() {
    const retPartialNtAllowed = false;
    if (
      this.props.cancellationPreviewResponseJson.flightDetails.segmentGroupDetailList.length == 1 &&
      this.props.cancellationPreviewResponseJson.flightDetails.journeyType == 2
    ) {
      return true;
    }
    return retPartialNtAllowed;
  }

  onPaxSelectionChanged = (segmentGroup, paxSelectionMap) => {
    const slctdPssngr = this.state.selectedPassenger;
    slctdPssngr[segmentGroup.intentRefID] = paxSelectionMap;

    this.setState({
      selectedPassenger: slctdPssngr,
    });
  };

  setPopUpOpen = (type, selectedSectorForRefund) => {
    if (!this.state.isPopUpOpen) {
      const reason = getReasonForRefund(
        selectedSectorForRefund,
        type,
        this.props.cancellationPreviewResponseJson,
      );
      CancellationTrackingHelper.trackClickEvent(
        ' Validation PopUp ',
        'MI_FLT_Cancel_review',
        this.getTrackingBundle(null, reason),
      );
    }
    this.setState({
      activeSectorForRefund: selectedSectorForRefund,
      isPopUpOpen: !this.state.isPopUpOpen,
      refundLevel: type,
    });
  };

  onProceed = (activeScCode) => {

    if(this.props.pageData && this.props.pageData.specialClaimVariant === 2){
      const { flightDetails, bookingID } = this.props.pageData;
      const paxFareSegmentIdList = getPaxFareSegmentList(flightDetails, activeScCode);
      const body = getRequestBodyTwo(bookingID, activeScCode, 'RefundToCustomer', paxFareSegmentIdList);
      try{
        fetchSpecialClaimPreview(bookingID,body).then((resposneObj) => {
          const { error, response } =  resposneObj;
          if(!error){
            Actions.specialClaimNew({ detailsResponse: response, scCode: activeScCode })
          }
        })
        .catch((e) => { console.log("error in new special claim api: ", e); })
        .finally(() => {
          this.setState({viewState: ViewState.SHOW_DETAIL})
        })
      }catch(e){
        console.log("error in new special claim api: ", e);
        this.setState({ viewState: ViewState.SHOW_DETAIL })
      }
      this.setState({
        viewState: ViewState.LOADING
      })
    }else{
      const specialClaimList = getSpecialClaimList(this.props.cancellationPreviewResponseJson, activeScCode)
    Actions.specialClaimSelectPassenger({
      specialClaimData: JSON.stringify(specialClaimList),
      partialPax: JSON.stringify(buildPartialPaxList(this.props.cancellationPreviewResponseJson, specialClaimList.scCode)),
      bookingID: this.props.cancellationPreviewResponseJson.bookingID,
      bookingState: '',
      lobCode: this.props.cancellationPreviewResponseJson.lobCode,
      validationError: true,
      mobileNumber: getPrimaryContactNo(this.props.cancellationPreviewResponseJson),
      isCorporateBooking: this.props.cancellationPreviewResponseJson.isCorporateBooking,
    });
    }
    this.setState({
      isPopUpOpen: false,
    });
  };

  trackHelpingHandEvents = (type, page, click, data) => {
    const pageName = `${this.trackingPageName}_${page}`;
    const trackData = Object.assign({}, this.getTrackingBundle(null), {
      ruleTrace: data ? data.ruleTrace : '',
    });
    switch (type) {
      case 'load':
        CancellationTrackingHelper.trackLoadEvent(pageName, trackData, true);
        break;
      case 'click':
        CancellationTrackingHelper.trackClickEvent(pageName, `Flight_${click}`, trackData);
        break;
      default:
        break;
    }
  };

  renderPage() {
    const {
      selectTravellersText,
      confirmText,
      reviewText,
      proceedText,
      additionalText: { filingCliamForText, selectReasonForRefundText },
      backToBookingText,
      viewRefundCalculationText,
      backCapsText,
    } = getStaticData();
    const { fsStyle, ...fonts } = getFont(true);
    const { psTheme } = this.context;
    const STEPS = [selectTravellersText, reviewText, confirmText];

    const { cancellationMessageList, cfarCanPreviewCard } = this.props.cancellationPreviewResponseJson;

    return (
      <View style={styles.pageWrapper}>
        {this.renderHeader()}
        <Breadcrumb activeStep={this.state.activeStep} data={STEPS} />
        {this.props.cancellationPreviewResponseJson.cgNudgeText && (
          <InformationCard
            description={this.props.cancellationPreviewResponseJson.cgNudgeText}
            cardBackgroundColor={psTheme.color.creamWhite}
            textColor={psTheme.color.lightYello}
          />
        )}
        <ScrollView showsVerticalScrollIndicator={false} style={styles.pageScroll}>
          {!isEmptyArray(cancellationMessageList) &&
            cancellationMessageList.map((item) => {
              return (
                <View
                  style={[
                    styles.freeCancellationText,
                    !!item.bgColor && { backgroundColor: item.bgColor },
                  ]}
                >
                  {!!item.title && (
                    <Text
                      style={[
                        styles.titleStyle,
                        !!item.titleColor && { color: item.titleColor },
                        fonts.boldFontFamily,
                        fsStyle.font14,
                      ]}
                    >
                      {item.title}
                    </Text>
                  )}
                  {!isEmptyArray(item.subtitle) && (
                    <Text
                      style={[
                        styles.subTitleStyle,
                        !!item.subtitleColor && { color: item.subtitleColor },
                        fsStyle.font14,
                      ]}
                    >
                      {item.subtitle[0]}
                    </Text>
                  )}
                </View>
              );
            })}
          {!isEmpty(get(cfarCanPreviewCard, 'title', '')) && (
            <View>
              <Text style={[styles.cfarHeadingText, fonts.boldFontFamily, fsStyle.font16]}>
                {cfarCanPreviewCard.title}
              </Text>
            </View>
          )}
          <View style={styles.whiteCard}>
            {!this.state.isSingleSegmentAndNotValid && this.addSegments()}
          </View>
          {/*
               this screen should appear in case of sinle segment or (single segment and
                   single pax) not valid for cancellation
            */}
          {this.state.isSingleSegmentAndNotValid && (
            <View>
              <SingleSegmentSinglePaxCom
                cancelRresponse={this.props.cancellationPreviewResponseJson}
                onItemClick={(item) => {
                  this.onProceed(item.scCode);
                }}
              />
            </View>
          )}
          <View style={styles.pageScrollBtmSpace} />
        </ScrollView>
        <View>
          <View style={styles.footerBtnWrapper} />
          <View style={styles.buttonStyle}>
            {!this.state.isSingleSegmentAndNotValid && (
              <BlueRectangleButton
                buttonText={viewRefundCalculationText}
                clickHandler={this.onContinueClicked}
                disabled={checkPaxSelected(this.state.selectedPassenger)}
                buttonTextStyle={[styles.actionStyle, fonts.boldFontFamily, fsStyle.font14]}
                isCorporateBooking={this.props.cancellationPreviewResponseJson.isCorporateBooking}
              />
            )}
            {this.state.isSingleSegmentAndNotValid && (
              <BlueRectangleButton
                buttonText={backToBookingText}
                clickHandler={() =>
                  goToBookingDetails(this.props.cancellationPreviewResponseJson.bookingId)
                }
                buttonTextStyle={[styles.actionStyle, fonts.boldFontFamily, fsStyle.font14]}
              />
            )}
          </View>
        </View>
        {this.state.isPopUpOpen && (
          <RequestValidationOverlay
            closeOverlay={this.setPopUpOpen}
            content={
              <ReasonRefundDtls
                heading={selectReasonForRefundText}
                subHeading={filingCliamForText}
                rightButtonText={proceedText}
                leftButtonText={backCapsText}
                closeOverlay={this.setPopUpOpen}
                onProceed={this.onProceed}
                activeSectorForRefund={this.state.activeSectorForRefund}
                specialClaimDataList={getAllPossibleScActionLists(
                  this.props.cancellationPreviewResponseJson.cancellationValidation,
                  this.state.refundLevel,
                  this.state.activeSectorForRefund,
                )}
                refundLevel={this.state.refundLevel}
                cancellationValidation={
                  this.props.cancellationPreviewResponseJson.cancellationValidation
                }
              />
            }
          />
        )}
        {this.state.helpingHandOverlay === true && (
          <NeedMoreHelpOverlay
            bookingId={
              this.props.cancellationPreviewResponseJson
                ? this.props.cancellationPreviewResponseJson.bookingID ||
                  this.props.cancellationPreviewResponseJson.bookingId
                : ''
            }
            handleBottomOverlay={this.toggleHelpingHandOverlay}
            handleReload={this.toggleHelpingHandOverlay}
            component={NEED_MORE_HELP.HELPING_HAND}
            pageName={NEED_HELP_PAGE.FLIGHT_CANCEL_PAX}
            hideLoader={true}
            trackEvent={this.trackHelpingHandEvents}
          />
        )}
      </View>
    );
  }

  onContinueClicked = () => {
    const reason = FLIGHT_CANCELLATION_REASON.CITP;
    const [paxFareSgmntIdLst, unselectedPaxList] = makePaxFareSegmentIdList(
      this.props.cancellationPreviewResponseJson,
      this.state.selectedPassenger,
      reason,
    );
    this.paxFareSgmntIdLst = paxFareSgmntIdLst;
    if (unselectedPaxList.length !== 0 && !unselectedPaxList.includes(0)) {
      const {
        flightAdditionalText: { childrenMustAccompaniedByAdultText },
      } = getStaticData();
      showLongToast(childrenMustAccompaniedByAdultText);
      return;
    }
    if (unselectedPaxList.length === 0) {
      this.allPaxSelected = true;
    } else {
      this.allPaxSelected = false;
    }
    this.submitCancellation();
    CancellationTrackingHelper.trackClickEvent(
      this.trackingPageName,
      'MI_FLT_Cancel_review',
      this.getTrackingBundle(null),
    );
  };

  async submitCancellation() {
    try {
      const hasNetwork = await isNetworkAvailable();
      if (!hasNetwork) {
        const {
          errorText: { checkYourInternetConnectionError },
        } = getStaticData();
        Alert.alert(checkYourInternetConnectionError);
        return;
      }
      const { headerTitle } = this.props;
      const cancellationRequest = getFlightFullCancellationRequest(
        this.props.cancellationPreviewResponseJson.bookingID,
        FLIGHT_CANCELLATION_REASON.CITP,
        FLIGHT_CANCELLATION_REFUND_OPTIONS.RefundToCustomer,
        true,
        this.props.cancellationPreviewResponseJson.lobCode,
        this.paxFareSgmntIdLst,
      );
      cancellationRequest.flightCancellationType = this.props.cancellationRequest.flightCancellationType;

      // This is done in order to clear out request for fixing state bug.

      Actions.flightBookingRefundBreakup({
        headerTitle,
        cancellationPreviewResponseJson: this.props.cancellationPreviewResponseJson,
        cancellationRequest,
        allPaxSelected: this.allPaxSelected,
        selectedPassenger: this.paxFareSgmntIdLst,
        trackingBundle: this.getTrackingBundle(null),
        isCorporateBooking: this.props.cancellationPreviewResponseJson.isCorporateBooking,
        pageData: this.props.pageData,
        isFull: false
      });
    } catch (error) {
      this.setState({
        viewState: ViewState.ERROR,
      });
    }
  }

  renderErrorView = () => {
    const {
      errorText: { somethingWentWrongTryAgainError },
      tryAgainText,
    } = getStaticData();

    return (
      <ErrorView
        heading={somethingWentWrongTryAgainError}
        buttonLabel={tryAgainText}
        showRetry
        onRetry={() => {
          this.setState({ viewState: ViewState.LOADING });
          this.fetchData();
        }}
      />
    );
  };

  tryAgainClickListener = () => {
    this.setState({ viewState: ViewState.LOADING });
    this.fetchData();
  };

  fetchData() {
    this.submitCancellation();
  }

  getTrackingBundle(error, reason) {
    const trackingBundle = {};
    trackingBundle.response = this.props.cancellationPreviewResponseJson;
    trackingBundle.isInternational =
      this.props.cancellationPreviewResponseJson &&
      this.props.cancellationPreviewResponseJson.international;
    trackingBundle.flightCancellationType = this.props.cancellationRequest.flightCancellationType;
    trackingBundle.isFullSelection = this.allPaxSelected;
    trackingBundle.rfndReason = reason;
    if (error) {
      trackingBundle.error = error;
    }
    return trackingBundle;
  }
}

PartialBookingCancellation.propTypes = {
  headerTitle: PropTypes.string.isRequired,
  headerSubTItle: PropTypes.string.isRequired,
  cancellationPreviewResponseJson: PropTypes.object.isRequired,
  cancellationRequest: PropTypes.object.isRequired,
  segmentsGroupView: PropTypes.object,
};

PartialBookingCancellation.defaultProps = {
  segmentsGroupView: {},
};
