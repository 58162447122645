import {Platform} from 'react-native';

export const PLATFORM_IOS = 'ios';
export const PLATFORM_ANDROID = 'android';
export const PLATFORM_WEB = 'web';

export const USER_PLATFORM = {
  IOS: Platform.OS === PLATFORM_IOS,
  ANDROID: Platform.OS === PLATFORM_ANDROID,
  WEB: Platform.OS === PLATFORM_WEB
};

export const NO_INTERNET_CONNECTION = 'Please check your internet connection';
export const SIMILAR_HOTELS = 'https://cbdom.makemytrip.com/clientbackend/cg/static-detail';
export const SIMILAR_HOTELS_WEB = 'https://mapi.makemytrip.com/clientbackend/cg/static-detail';
export const SIMILAR_HOTELS_LOCAL = 'http://172.16.47.171/clientbackend/cg/static-detail';
