import { colors } from '@mmt/legacy-commons/Styles/globalStyles';

const getStyles = (fonts) => {
  return {
    contentTitle: {
      ...fonts.font21,
      color: colors.defaultTextColor,
      fontFamily: fonts.black,
      marginBottom: 20
    },
    contentDesc: {
      ...fonts.font16,
      color: colors.defaultTextColor,
      fontFamily: fonts.bold,
      marginBottom: 30,
      lineHeight: 22
    },
    contentFooter: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    actionBtn: {
      alignItems: 'center',
      justifyContent: 'center'
    },
    actionBtnText: {
      ...fonts.font16,
      color: colors.orange,
      fontFamily: fonts.medium
    },
    RadioWrapper: {
      flexDirection: 'row',
      marginBottom: 10
    },
    Radio: {
      width: 18,
      height: 18,
      backgroundColor: '#fff',
      borderRadius: 20,
      borderWidth: 1,
      borderColor: '#f0772c',
      marginRight: 10,
      marginTop: 5
    },
    RadioInside: {
      width: 12,
      height: 12,
      backgroundColor: '#f0772c',
      borderRadius: 20,
      marginLeft: 2,
      marginTop: 2
    },
    RadioTxt: {
      ...fonts.font14,
      color: '#4a4a4a',
      fontFamily: fonts.regular,
      lineHeight: 18,
      paddingTop: 5
    },
    textAreaContainer: {
      borderColor: '#979797',
      borderWidth: 1,
      paddingVertical: 10,
      paddingHorizontal: 15,
      borderRadius: 4,
      justifyContent: 'flex-start',
      marginBottom: 30
    },
    textArea: {
      textAlignVertical: 'top',
      height: 80,
      padding: 0,
      color: '#9b9b9b',
      fontFamily: fonts.regular
    },
    paddingBottom5: {paddingBottom: 5},
    marginBottom20: {marginBottom: 20}
  };
}

export default getStyles;
