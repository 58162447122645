import React from 'react';
import { View, Text, Image, TouchableOpacity, StyleSheet } from 'react-native';
import HTMLView from 'react-native-htmlview';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import Button from '../../../../../../Common/Button/index';
import styles from './addSeatMealBottomOverlayCss';
import FlightBookingTrackingHelper from '../../../helper/FlightBookingTrackingHelper';
import { getStaticData } from '../../../../../../staticData/staticData';
import { ADD_MEAL_ICON, ADD_SEAT_ICON } from '../../../../../FlightImageConstants';
import { getFont } from '../../../../../../PostSalesStyles';

const AddSeatMealBottomOverlay = ({
  handleBottomOverlay,
  onPressHandler,
  reponse,
  odcAncillaryPopUpMessage,
}) => {
  const { fsStyle, ...fonts } = getFont(true);
  const staticData = getStaticData();
  const htmlStyle = StyleSheet.create({
    strong: {
      color: colors.defaultTextColor,
      fontFamily: fonts.bold,
    },
    b: {
      color: colors.black,
      fontFamily: fonts.bold,
    },
    div: {
      color: colors.defaultTextColor,
    },
  });
  const { flightAddSeatMealPopupText } = staticData;
  const illAddLaterHandler = () => {
    FlightBookingTrackingHelper.trackClickEvent(
      'Details',
      'odc_seatmeal_popup_addlaterclicked',
      reponse,
    );
    handleBottomOverlay();
  };
  return (
    <View style={styles.OverlayContentWrapper}>
      <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween, styles.headerWrapper]}>
        <Text style={[styles.title, AtomicCss.flex1, fonts.blackFontFamily, fsStyle.font20]}>
          {odcAncillaryPopUpMessage.heading}
        </Text>
      </View>
      <HTMLView
        style={[
          AtomicCss.defaultText,
          fsStyle.font14,
          fonts.regularFontFamily,
          AtomicCss.marginBottom15,
          AtomicCss.lineHeight20,
        ]}
        value={odcAncillaryPopUpMessage.text}
        stylesheet={htmlStyle}
      />
      <TouchableOpacity style={styles.addSeatMealWrapper}>
        <View style={[AtomicCss.flexRow, AtomicCss.marginRight10]}>
          <Image style={styles.ancillaryIcon} source={ADD_MEAL_ICON} />
          <Image style={styles.ancillaryIcon} source={ADD_SEAT_ICON} />
        </View>
        <Text style={[fonts.blackFontFamily, fsStyle.font16, AtomicCss.blackText]}>
          {odcAncillaryPopUpMessage.imageText}
        </Text>
      </TouchableOpacity>

      <View style={styles.btnWrapper}>
        <TouchableOpacity onPress={() => illAddLaterHandler()}>
          <Text style={[styles.myBizLink, fonts.blackFontFamily, fsStyle.font14]}>
            {flightAddSeatMealPopupText.addLatertext}
          </Text>
        </TouchableOpacity>
        <Button
          btnType="flat"
          btnBg="gradientBtn"
          btnTxt={flightAddSeatMealPopupText.addNowtext}
          fontSize="font14"
          clickHandler={onPressHandler}
          buttonTextCss={fsStyle.font14}
          isCorporateBooking={true}
        />
      </View>
    </View>
  );
};

export default AddSeatMealBottomOverlay;
