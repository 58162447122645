import React, {useState} from 'react';
import {Text, TouchableOpacity, StyleSheet} from 'react-native';
import { themeColors } from '../../PostSalesStyles';

const SeeMore = (props) => {
  const {numberOfLines, text, style} = props;
  const [open, setToggle] = useState(false);
  const showLabel = text.length > 180 ? true : false;

  return (
    <React.Fragment>
      <Text style={style} numberOfLines={open || !showLabel ? null : numberOfLines}>
        {text}
      </Text>
      {showLabel ? (
        <TouchableOpacity
          onPress={() => {
            setToggle(!open);
          }}
        >
          <Text style={styles.linkBtnTxt}>
            {open ? 'show less' : 'read more'}
          </Text>
        </TouchableOpacity>
      ) : null}
    </React.Fragment>
  );
};

const styles = StyleSheet.create({
  linkBtnTxt: {
    fontSize: 14,
    color: themeColors.linkTextColor
  }
});

export default SeeMore;
