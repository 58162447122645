import React from 'react';
import { View, Image, Text } from 'react-native';
import styles from './PrimaryGuestCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { SINGLE_GUEST_ICON, DOUBLE_GUEST_ICON, GREY_MAIL_ICON, GREY_CALL_ICON } from '../../../HotelImageConstants';
import {getPrimaryCustomerEmailId, isNotNullAndEmpty, getNoOfGuestsInRoom, handleClickEvent, getActionButton, getThrottleParams} from '../../utils/HotelBookingDetailUtil';
import { getStaticData } from '../../../../staticData/staticData';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const PrimaryGuestBookingCard = ({ card, response, pageName, showHelpingHand, handleOpenBottomOverlay }) => {
  const { fsStyle, ...fonts } = getFont(true);
  const staticData = getStaticData();
  const hotelBookingInfo = response.hotelBookingInfo;

  const cardWithHelpingHand = card;
  if (cardWithHelpingHand) {
    cardWithHelpingHand.showHelpingHand = showHelpingHand;
  }

  const getPrimaryCustomerName = () => {
    const primaryCustomer = hotelBookingInfo.primaryCustomerDetails;
    return primaryCustomer &&
    `${primaryCustomer.firstName} ${primaryCustomer.middleName} ${primaryCustomer.lastName}`
      .replace(/\s+/g, ' ')
      .trim();
  };

  const getPrimaryCustomerPhoneNumber = () => {
    const primaryCustomer = hotelBookingInfo.primaryCustomerDetails;
    return primaryCustomer && primaryCustomer.phoneNumber;
  }

  const getGuestsDetails = (bookingResponse) => {
    if (!bookingResponse || !bookingResponse.passengerDetails || !isNotNullAndEmpty(bookingResponse.passengerDetails.passengers) || !bookingResponse.hotelBookingInfo) {
      return null;
    }
  
    const {passengers} = bookingResponse.passengerDetails;
  
    const paxInfoList = passengers.filter(({ isRoomLead }) => (isRoomLead)).map((paxInfo) => {
      const name = `${paxInfo.firstName} ${paxInfo.middleName} ${paxInfo.lastName}`;
      const { roomno } = paxInfo;
      return { name, roomno };
    });
    return paxInfoList;
  };

  const handleClick = (action) => {
    handleClickEvent(cardWithHelpingHand, {...action, actionHandler: handleOpenBottomOverlay}, response, pageName);
  };

  const paxInfoList = getGuestsDetails(response);
  const roomDetails = response.hotelBookingInfo && response.hotelBookingInfo.roomDetails;
  
  return (
    <View style={[AtomicCss.whiteCardNew, AtomicCss.marginHz12, AtomicCss.marginBottom12, AtomicCss.borderRadius16]}>
      <View style={[styles.headerSec, AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom15]}>
        <Text style={[fonts.blackFontFamily, fsStyle.font16, AtomicCss.blackText, AtomicCss.lineHeight20]}>
          {staticData.primaryGuestCardText.additionalText.primaryGuestBookingText}
        </Text>
      </View>
      <View style={[styles.guestWrapper, AtomicCss.marginBottom16]}>
        <View>
          <Text style={[fonts.boldFontFamily, fsStyle.font16, AtomicCss.blackText, AtomicCss.alignLeft, AtomicCss.lineHeight20]}>
            {getPrimaryCustomerName()}
          </Text>
        </View>
        {paxInfoList && paxInfoList.length > 0 && roomDetails && paxInfoList.length === roomDetails.length && paxInfoList.map((item) => {
          return (
            <View style={[AtomicCss.flexRow, AtomicCss.marginRight8, AtomicCss.marginTop10]}>
              <Image style={styles.guest} source={SINGLE_GUEST_ICON} />
              <View style={[fonts.boldFontFamily, fsStyle.font13, AtomicCss.darkGreyText, AtomicCss.flexRow, {flexShrink: 1}]}>
                <Text style={[fonts.boldFontFamily, fsStyle.font13, AtomicCss.darkGreyText, AtomicCss.alignLeft, AtomicCss.lineHeight20]}>
                  <Text style={[AtomicCss.greyText, AtomicCss.alignLeft]}>{staticData.roomText} {item.roomno}:</Text>{' '}
                  {item.name}{' '}
                  {getNoOfGuestsInRoom(hotelBookingInfo, item.roomno) && ' + ' + getNoOfGuestsInRoom(hotelBookingInfo, item.roomno)}
                </Text>
              </View>
            </View>
          );
        })}
      </View>
      <View style={[styles.dividor, AtomicCss.marginBottom16]}></View>
      <View style={[styles.guestWrapper, AtomicCss.marginRight10, AtomicCss.paddingBottom12]}>
        <View>
          <Text
            style={[
              fonts.boldFontFamily,
              fsStyle.font12,
              AtomicCss.blackText,
              AtomicCss.marginBottom6,
              AtomicCss.alignLeft,
              AtomicCss.lineHeight20
            ]}
          >
            {staticData.primaryGuestCardText.additionalText.communicationText}
          </Text>
        </View>
        <View style={[AtomicCss.flexRow, AtomicCss.marginBottom10, AtomicCss.alignCenter]}>
          <Image style={styles.guest} source={GREY_MAIL_ICON} />
          <Text
            style={[
              fonts.regularFontFamily,
              fsStyle.font13,
              AtomicCss.darkGreyText,
              AtomicCss.lineHeight20
            ]}
          >
            {getPrimaryCustomerEmailId(response)}
          </Text>
        </View>
        <View style={[AtomicCss.flexRow, AtomicCss.marginBottom8, AtomicCss.alignCenter]}>
          <Image style={styles.guest} source={GREY_CALL_ICON} />
          <Text
            style={[
              fonts.regularFontFamily,
              fsStyle.font13,
              AtomicCss.darkGreyText,
              AtomicCss.lineHeight20
            ]}
          >
            {getPrimaryCustomerPhoneNumber()}
          </Text>
        </View>
      </View>
      <View>
        {card && isNotNullAndEmpty(card.actionList) && 
          getActionButton(card, response, handleClick, getThrottleParams)}
      </View>
    </View>
  );
};

export default PrimaryGuestBookingCard;
