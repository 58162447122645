import React, { useState } from 'react';
import {
  View,
  ScrollView,
  Animated,
  Easing,
} from 'react-native';
import Tabs from './components/Tabs';
import FilterSection from './components/FilterSection';
import { fetchDataWithFilters, loadDynamicFiltersData } from '../../data/api';
import {
  getDynamicFilterIds,
  isValuesNotPresentForAnyFilter,
  setDynamicFilterValues,
} from '../../utils';
import { showLongToast } from 'packages/legacy-commons/Common/Components/Toast';
import { FILTERS_TYPE } from '../../summaryConstants';
import createStyles from './style';
import { useTheme } from '../../../../theme/theme.context';
import { getStaticData } from '../../../../staticData/staticData';
import { AllFiltersDataType, BookingData, FilterSectionType, LostIdBookingData, PlanningsData, SelectedFilter, SelectedFilterShowMore } from '../../types';
import { getFilteredPlanningsData, getFilteredLostIdData } from '../../data/dataAdapter';
import {onlyPlanningandLostIdFilterSelected} from '../../utils';
import TripSummaryOmnitureTracker from '../../TripSummaryOmnitureTracker';

interface FiltersTabProps {
  allFilterData: AllFiltersDataType; 
  data: FilterSectionType[]; 
  handleFetchedBookings: (data: {_bookings: BookingData, filters: SelectedFilter[]}) => void; 
  selectedFilters: SelectedFilter[];
  planningsData: PlanningsData,
  lostIdBookingData: LostIdBookingData 
}

const FiltersTab: React.FC<FiltersTabProps> = ({
  allFilterData,
  data,
  handleFetchedBookings,
  selectedFilters,
  planningsData,
  lostIdBookingData,
}) => {
  const [bottomOverlay, setBottomOverlay] = useState<string | null>(null);
  const [filtersData, setFiltersData] = useState<FilterSectionType[]>(data);
  const [isLoading, setIsLoading] = useState<{ msg: string; status: boolean }>({
    msg: '',
    status: false,
  });
  const [activeTab, setActiveTab] = useState<number | null>(null);

  const overlayPosition = new Animated.Value(-700);
  const allFilters = { title: allFilterData?.pageName, id: allFilterData?.pageId, type: FILTERS_TYPE.DYNAMIC };
  const tabsData = [allFilters, ...data];
  const allFilterId = allFilterData?.pageId;
  const clearAllText = allFilterData?.ctaDetails?.name;
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme);

  const handleClose = () => {
    bottomOverlay !== allFilterId &&
      Animated.timing(overlayPosition, {
        toValue: -700,
        easing: Easing.fadeInOut,
        duration: 200,
        delay: 100,
      }).start();
    setTimeout(() => {
      setBottomOverlay(null);
    }, 550);
    setBottomOverlay(null);
  };

  const handleFilterSelection = (filters: SelectedFilter[]) => {
    const { loadingDataText, errorFilteredText } = getStaticData(true);
    setIsLoading({ msg: loadingDataText, status: true });
    const newSelectedFilters: SelectedFilterShowMore[] = filters.map((item) => ({
      key: item.key,
      values: item.values.map((value: {id:string}) => value.id),
    }));
    const planningsFilteredData = getFilteredPlanningsData(planningsData, filters);
    const lostIdFilteredData = getFilteredLostIdData(lostIdBookingData, filters);
    if(onlyPlanningandLostIdFilterSelected(filters)){
        setIsLoading({ ...isLoading, status: false });
        handleClose();
        handleFetchedBookings && handleFetchedBookings({ _bookings: {}, filters, lostIdBookingData: lostIdFilteredData, planningsData: planningsFilteredData  });
        return;
    }
    fetchDataWithFilters(0, newSelectedFilters)
      .then((bookingData) => {
        if (bookingData.errorCode) {
          showLongToast(errorFilteredText);
          handleClose();
          setIsLoading({ ...isLoading, status: false });
          return;
        }
        setIsLoading({ ...isLoading, status: false });
        handleClose();
        handleFetchedBookings && handleFetchedBookings({ _bookings: bookingData, filters, lostIdBookingData: lostIdFilteredData, planningsData: planningsFilteredData  });
      })
      .catch(() => {
        showLongToast(errorFilteredText);
        handleClose();
        setIsLoading({ ...isLoading, status: false });
      });
  };


  const onHandleTabChange = async (index: number, type: string, filterType: string) => {
    setActiveTab(index);
    setBottomOverlay(type);
    const { loadingFiltersText } = getStaticData(true);
    TripSummaryOmnitureTracker.trackNormalClickEvent(filterType, type);
    if (filterType === FILTERS_TYPE.DYNAMIC) {
      // find if values of any filter section is not present
      if (isValuesNotPresentForAnyFilter(filtersData)) {
        setIsLoading({ msg: loadingFiltersText, status: true });
        const dynamicFilterIds = getDynamicFilterIds(filtersData);
        const dynamicFilterData = await loadDynamicFiltersData(dynamicFilterIds);
        if (dynamicFilterData) {
          const filterDataWithDynamicData = setDynamicFilterValues(filtersData, dynamicFilterData);
          setFiltersData(filterDataWithDynamicData);
        }
      }
    }
    setIsLoading({ ...isLoading, status: false });
    Animated.timing(overlayPosition, {
      toValue: 0,
      easing: Easing.easeInOut,
      duration: 400,
      delay: 200,
      useNativeDriver: false
    }).start();
  };

  const getFilterSectionProps = (key: string) => {
    if (key === allFilterId) {
      return {
        heading: allFilterData?.pageName,
        id : key,
        data: filtersData,
      };
    }
    const filterSelected = filtersData && filtersData.find((type: {id:string}) => type.id === key);
    return {
      id: key,
      heading: filterSelected && filterSelected.title,
      data: filterSelected?.values || [], 
    };
  };

  return (
    <>
      <View style={styles.tabsWrapper}>
        <View style={styles.hideTopShadow} />
        <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
          {tabsData.map((item, index) => {
            return (
              <Tabs
                key={index}
                item={item}
                index={index}
                onHandleTabChange={() => onHandleTabChange(index, item.id, item.type)}
                activeTab={activeTab}
                isLastItem={index === 0}
              />
            );
          })}
        </ScrollView>
      </View>
      {bottomOverlay && (
        <FilterSection
          {...getFilterSectionProps(bottomOverlay)}
          clearAllText={clearAllText}
          handleClose={handleClose}
          handleFilterSelection={handleFilterSelection}
          isLoading={isLoading}
          selectedFilters={selectedFilters}
        />
      )}
    </>
  );
};

export default FiltersTab;