const styles = {
  marginBottom80: {
    marginBottom: 80,
  },
  headerSec: {
    paddingHorizontal: 12,
    paddingTop: 16,
  },
  headingIcon: {
    width: 24,
    height: 24,
    marginRight: 10,
    backgroundColor: '#f1f1f1',
  },
  guestWrapper: {
    paddingLeft: 12,
  },
  guest: {
    width: 16,
    height: 16,
    marginRight: 5,
  },
  dividor: {
    borderBottomWidth: 1,
    borderColor: '#d4daea'
  },
};
export default styles;
