import React from 'react';
import PropTypes from 'prop-types';
import {View, Text, StyleSheet} from 'react-native';
import AtomicCss from '../../commonStyles/AtomicCss';
import { getStaticData } from '../../../staticData/staticData';
import { getFont } from '../../../PostSalesStyles';

const tickUnicode = '\u2713';
class ReviewStepHeader extends React.Component {
    static navigationOptions = {
      header: null
    };

    render() {
      const staticData = getStaticData();
      const { fsStyle, ...fonts } = getFont(true);
      const { confirmText, reviewPenaltyAndRefundText } = staticData;
      return (
        <View style={styles.infoStrip}>
          <View style={[AtomicCss.flexRow, styles.mar24Rt, styles.wrapStep]}>
            <Text style={[styles.rowCount, fonts.boldFontFamily, fsStyle.font11, this.activeStyleForCount(1)]}>{this.props.currentIndex > 1 ? tickUnicode : '1'}
            </Text>
            <Text numberOfLines={1} style={[fonts.boldFontFamily, AtomicCss.lightTextColor,
              fsStyle.font11, AtomicCss.blackText,
              (this.props.currentIndex >= 1 ? styles.activeText : {})]}
            > {reviewPenaltyAndRefundText}
            </Text>
          </View>
          <View style={AtomicCss.flexRow}>
            <Text style={[styles.rowCount, fonts.boldFontFamily, fsStyle.font11, this.activeStyleForCount(2)]}>{this.props.currentIndex > 2 ? tickUnicode : '2'}
            </Text>
            <Text style={[fonts.boldFontFamily,
              AtomicCss.lightTextColor, fsStyle.font11,
              (this.props.currentIndex >= 2 ? styles.activeText : {})]}
            >{confirmText}
            </Text>
          </View>
        </View>
      );
    }

    activeStyleForCount = (currentIndex) => {
      let style = {};
      if (this.props.currentIndex === currentIndex) {
        style = styles.rowCountActive;
      } else if (this.props.currentIndex > currentIndex) {
        style = styles.tick;
      }
      return style;
    }
}

ReviewStepHeader.propTypes = {
  currentIndex: PropTypes.number.isRequired
};
const styles = StyleSheet.create({
  infoStrip: {
    flexDirection: 'row',
    paddingBottom: 10,
    paddingTop: 10,
    paddingLeft: 15,
    paddingRight: 15,
    marginBottom: 10,
    alignItems: 'center',
    elevation: 2,
    backgroundColor: '#fff'
  },
  mar24Rt: {
    marginRight: 24,
  },
  wrapStep: {
    maxWidth: 160
  },
  rowCount: {
    backgroundColor: '#9b9b9b',
    width: 14,
    height: 14,
    borderRadius: 7,
    marginRight: 4,
    color: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    overflow: 'hidden'
  },
  rowCountActive: {backgroundColor: '#000'},
  activeText: {color: '#000'},
  tick: {
    color: '#93d51f',
    paddingTop: 1,
    backgroundColor: '#000'
  }

});

export default ReviewStepHeader;
