/* eslint-disable max-len */
import React from 'react';
import {View, Text, TouchableOpacity, TextInput} from 'react-native';
import PropTypes from 'prop-types';
import styles from './OTPBottomCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getStaticData } from '../../../staticData/staticData';

const staticData = getStaticData();

export default class OTPBottomOverlay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      counterTxt: this.props.counter,
      disabled: true
    };
  }

  componentDidMount() {
    setInterval(() => {
      if (this.state.counterTxt > 0) {
        this.setState({
          counterTxt: this.state.counterTxt - 1
        });
      }
      if (this.state.counterTxt === 0) {
        this.setState({
          disabled: false
        });
      }
    }, 1000);
  }

  render() {
    const {
      heading,
      subHeading,
      leftButtonText,
      rightButtonText,
      inputFieldTitleText,
      errorText,
      note,
      subHeadingStyle,
      headingStyle
    } = this.props
    const errorState = (this.props.errorState === true) ? [styles.errorStateStyle] : '';
    const disabledStyle = this.state.disabled === true ? [{opacity: 0.6}] : [{opacity: 1}];
    return (
      <View>
        <Text style={[styles.contentTitle,headingStyle]}>{heading}</Text>
        <View style={AtomicCss.flexRow}>
          <Text style={[styles.contentDesc,subHeadingStyle]}>{subHeading}</Text>
        </View>
        <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween, AtomicCss.marginBottom10]}>
          <Text style={[AtomicCss.defaultText, AtomicCss.font14, AtomicCss.boldFont]}>{inputFieldTitleText}</Text>
          <TouchableOpacity disabled={this.state.disabled} style={disabledStyle}
                            onPress={() => this.props.resendOtp()}>
            <Text style={[AtomicCss.azure, AtomicCss.boldFont, disabledStyle]}>{note}</Text>
          </TouchableOpacity>
        </View>
        <View style={[styles.OTPFieldWrapper, errorState]}>
          <TextInput
            underlineColorAndroid="transparent"
            style={styles.OTPInput}
            onChangeText={txt => this.props.handleOTPValue(txt)}
            value={this.props.OTPValue}
          />
          <View style={[styles.OTPDurationWrapper]}>
            <Text style={[AtomicCss.greyText, AtomicCss.regularFont, AtomicCss.font12]}>{this.state.counterTxt} sec</Text>
          </View>
        </View>
        {this.props.errorState && <Text style={[AtomicCss.redText, AtomicCss.regularFont, AtomicCss.font12]}>{errorText}</Text>}
        <View style={styles.contentFooter}>
          <TouchableOpacity style={styles.actionBtn} onPress={() => this.props.handleBottomOverlay('')}>
            <Text style={styles.actionBtnText}>{leftButtonText}</Text>
          </TouchableOpacity>
          <TouchableOpacity style={styles.actionBtn} onPress={() => this.props.handleOTPOverlay()}>
            <Text style={[styles.actionBtnText, AtomicCss.boldFont]}>{rightButtonText}</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }
}

OTPBottomOverlay.defaultProps = {
  errorState: false,
  counter: 54,
  leftButtonText: staticData.cancelText,
  rightButtonText: staticData.proceedText,
  heading: staticData.otpVerificationText,
  subHeading: staticData.additionalText.verificationInitiateRefundText,
  errorText: staticData.errorText.enterValidOtpError,
  inputFieldTitleText: staticData.enterOtpText,
  note: staticData.resendOtpText,
  headingStyle:{},
  subHeadingStyle:{}
};

OTPBottomOverlay.propTypes = {
  handleBottomOverlay: PropTypes.func.isRequired,
  handleOTPOverlay: PropTypes.func.isRequired,
  handleOTPValue: PropTypes.func.isRequired,
  OTPValue: PropTypes.string.isRequired,
  errorState: PropTypes.bool,
  counter: PropTypes.number,
  resendOtp: PropTypes.func.isRequired,
  leftButtonText:PropTypes.string,
  rightButtonText:PropTypes.string,
  heading:PropTypes.string,
  subHeading:PropTypes.string,
  errorText:PropTypes.string,
  inputFieldTitleText:PropTypes.string,
  note:PropTypes.string,
  headingStyle:PropTypes.object,
  subHeadingStyle:PropTypes.object
};
