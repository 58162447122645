import isEmpty from 'lodash/isEmpty';
import React,{useState} from 'react';
import { Image, Text, TouchableOpacity, View } from 'react-native';
import InputElement from '../../../../Common/InputElement';
import { getFont, ps_colors } from '../../../../PostSalesStyles';
import { useTheme } from '../../../../theme/theme.context';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import createStyle from './inputFieldRowCss';

const InputFieldRow = ({
  icon,
  title,
  placeHolder,
  handleBottomOverlay,
  isMandatory,
  value,
  handleOnChange,
  bottomSheetType,
  disabled,
  onFieldTouch,
  isDirtyField,
  name
}) => {
  const { psTheme: theme } = useTheme();
  const fonts = getFont(true);
  const styles = createStyle(theme, fonts);
  const showError = isMandatory && isEmpty(value) && isDirtyField ;
  
  return (
    <View style={styles.fieldBox}>
      <View style={styles.iconWrapper}>
        {!!icon && <Image source={icon} style={styles.userIcon} />}
      </View>
      <View style={styles.rightPanel}>
        <Text style={[AtomicCss.font12, AtomicCss.boldFont, AtomicCss.lightText, styles.uppertext]}>
          {title}
          {isMandatory && (
            <Text
              style={[
                AtomicCss.redText,
                AtomicCss.boldFont,
                AtomicCss.font14,
                AtomicCss.marginLeft8,
              ]}
            >
              {' '}
              *
            </Text>
          )}
        </Text>
        <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
          {!!bottomSheetType ? (
            <TouchableOpacity
              disabled={disabled}
              onPress={() => {
                handleBottomOverlay(bottomSheetType);
                onFieldTouch(name)
              }}
              style={[styles.textWrapper,showError &&styles.errorState,]}
            >
             {!!value ? <Text>{value}</Text> : <Text style={showError ?styles.errorState:{}}>{placeHolder}</Text>}
            </TouchableOpacity>
          ) : (
            <InputElement
              editable={!disabled}
              value={value || ""}
              handleChange={handleOnChange}
              inputContainerStyle={[
                styles.inputContainerStyle,
                showError && styles.errorState,
              ]}
              placeholder={placeHolder}
              placeholderTextColor={showError&& ps_colors.red}
              onFocus={()=> onFieldTouch(name)}
            />
          )}
        </View>
      </View>
    </View>
  );
};

export default InputFieldRow;
