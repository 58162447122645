import React from 'react';
import PropTypes from 'prop-types';
import {Image, StyleSheet, Text, TouchableOpacity, View, Platform} from 'react-native';
import AtomicCss from '../../commonStyles/AtomicCss';
import {
  currentBookingStatus,
  passengerImage
} from '../../details/RailBookingDetailsUtil';
import _ from 'lodash';
import {CHKBOX_ICON,CHKBOX_ICON_ACTIVE,SEAT_ICON} from '../../Utils/RailImageConstant';
import { getFont } from '../../../PostSalesStyles';

class checkboxRow extends React.Component {
  constructor(props) {
    super(props);
    this.passenger = this.props.passenger;
    this.state = {
      chkboxicon: true
    };
    this.SelectRoom = this.SelectRoom.bind(this);
  }

  SelectRoom() {
    this.setState({
      chkboxicon: !this.state.chkboxicon
    });
    this.props.onPaxSelection(this.passenger, this.state.chkboxicon);
  }

static navigationOptions = {
  header: null
};

render() {
  const { fsStyle, ...fonts } = getFont(true);
  const {bookingStatus, statusColor} = currentBookingStatus(this.passenger);
  const updatedSeatText = !_.isEmpty(this.props.updatedPassenger) ? this.props.updatedPassenger.seatCompleteText : undefined;

  return (
    <TouchableOpacity
      style={[AtomicCss.flexRow, styles.borderbtm, styles.PickAppCard]}
      onPress={this.SelectRoom}
    >
      <Image
        style={styles.chkboxicon}
        source={this.state.chkboxicon ? CHKBOX_ICON : CHKBOX_ICON_ACTIVE}
      />
      <View>
        <View style={[AtomicCss.spaceBetween, AtomicCss.flexRow,
          AtomicCss.alignCenter, AtomicCss.marginBottom10,
          AtomicCss.marginTop5, AtomicCss.marginRight10]}
        >
          <Text style={[AtomicCss.blackText, fsStyle.font14,
            fonts.boldFontFamily, styles.CheckBoxContent]}
          >
            {this.passenger.fullName}
          </Text>
          <Text style={[styles.confirmed, AtomicCss.whiteSpaceNoWrap, fsStyle.font12,
            fonts.regularFontFamily,  (Platform.OS === 'web') ?(styles.status40) :(styles.status), {color: statusColor}]}
          >{this.passenger.currentStatusMsg}
          </Text>
        </View>
        <View style={[AtomicCss.paddingBottom8, AtomicCss.flexRow]}>
          <View style={[AtomicCss.marginRight10, AtomicCss.flexRow, styles.genderYearWidth]}>
            <Image style={styles.maleIcon} source={passengerImage(this.passenger)} />
            <Text style={[fsStyle.font12, fonts.regularFontFamily, AtomicCss.defaultTextColor, AtomicCss.whiteSpaceNoWrap]}>
              {this.passenger.paxInfo}
            </Text>
          </View>
          <View style={AtomicCss.flexRow}>
            <Image style={styles.seatIcon} source={SEAT_ICON} />
            <Text style={[fsStyle.font12, fonts.regularFontFamily,
              AtomicCss.defaultTextColor, AtomicCss.marginRight10]}
            >{updatedSeatText ? updatedSeatText : this.passenger.passengerCurrentStatusText}
            </Text>
          </View>
        </View>
      </View>

    </TouchableOpacity>
  );
}
}

const styles = StyleSheet.create({
  borderbtm: {
    borderBottomWidth: 1,
    borderBottomColor: '#ddd'
  },
  PickAppCard: {
    paddingHorizontal: 15,
    paddingVertical: 15,
    backgroundColor: '#ffffff'
  },
  chkboxicon: {
    width: 28,
    height: 28,
    marginRight: 8,
    marginTop: 5,
    backgroundColor: 'transparent'
  },
  CheckBoxContent: {
    width: '60%'
  },
  confirmed: {
    color: '#00a19c',
    textAlign: 'right'
  },
  paxInfoContainer: {
    width: '50%'
  },
  status: {width: '30%'},
  status40: {width: '40%'},
  maleIcon: {width: 16, height: 16, marginRight: 5},
  seatIcon: {width: 16, height: 20, marginRight: 5},
  genderYearWidth: {width: '40%'}

});

checkboxRow.propTypes = {
  passenger: PropTypes.object.isRequired,
  onPaxSelection: PropTypes.func.isRequired
};

export default checkboxRow;
