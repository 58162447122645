import React from 'react';
import {ScrollView, Text, View} from 'react-native';
import PropTypes from 'prop-types';
import Actions from '../../../navigation/postSalesNavigation';
import getStyles from './HotelMamiCss';
import PriceBreakUpCard from './PriceBreakUpCard';
import HotelPaymentCard from './HotelPaymentCard';
import CommonHeader from '../components/CommonHeader';
import {isIntlHotelBooking} from '../utils/HotelBookingDetailUtil';
import HotelBookingTrackingHelper from '../../HotelBookingTrackingHelper';
import HotelPaymentBreakupCard from './HotelPaymentBreakupCard';
import ExtraChargesCard from './ExtraChargesCard';
import OverlayExtraCharges from './OverlayExtraCharges';
import { getStaticData } from '../../../staticData/staticData';
import { WHITE_CROSS_ICON } from '../../HotelImageConstants';
import TCSBottomOverlay from '../../../Common/TCSBottomOverlay/TCSBottomOverlay';
import CommonBottomOverlayMessage from 'apps/post-sales/src/Common/CommonOverlayMessage/CommonBottomOverlayMessage';
import isEmpty from 'lodash/isEmpty';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

class PaymentCardDetails extends React.Component {
  static navigationOptions = {
    header: null
  }
  constructor(props) {
    super(props);
    this.fonts = getFont(true);
    this.styles = getStyles(this.fonts);
    this.trackPageName = isIntlHotelBooking(this.props.response?.lobCode)
      ? 'mob:customer support:Hotel International:PaymentBreakup'
      : 'mob:customer support:Hotel Domestic:PaymentBreakup';
    this.onCloseOverlay = this.onCloseOverlay.bind(this);
    this.state = {
        bottomOverlay: false,
        points: null,
        heading: null,
        TCSOverlay: false,
    };
  }

  onCloseOverlay() {
    this.setState({
        bottomOverlay: !this.state.bottomOverlay,
    });
  }
  setTCSOverlay = () => {
    HotelBookingTrackingHelper.trackClickEvent(this.trackPageName, 'mytrips_tcs_card_learnmore_clicked', this.props.response);
    this.setState({
      TCSOverlay: !this.state.TCSOverlay,
    });
  }

  handleBottomOverlay = (data) => {
      this.setState({
          bottomOverlay: true,
          heading: data.heading,
          infoList: data.infoList
      });
      HotelBookingTrackingHelper.trackClickEvent('ExtraChargesMoreInfo', 'MI_HTL_ExtraChargesMoreInfo', this.props.response);
  }

  componentDidMount() {
    HotelBookingTrackingHelper.trackLoadEvent(this.trackPageName, this.props.response);
    const { tcsNote } = this.props.response || {};
    const { priceBreakup } = tcsNote || {};
    if (priceBreakup){
      HotelBookingTrackingHelper.trackClickEvent(this.trackPageName, 'mytrips_tcs_card_loaded', this.props.response);
    }
  }

  render() {
    const staticData = getStaticData();
    const {tcsNote} = this.props.response || {};
    const {priceBreakup} = tcsNote || {};
    return (
      <View style={[this.styles.GreyBg, {flex: 1}]}>
        <CommonHeader
          imgSrc={WHITE_CROSS_ICON}
          headerText={staticData.paymentDetailsText}
          backPressHandler={this.onBackPress}
        />
        <ScrollView>
            <ExtraChargesCard
              response={this.props.response}
              pageName={this.trackPageName}
              handleBottomOverlay={this.handleBottomOverlay}
            />
          <PriceBreakUpCard
            response={this.props.response}
            pageName={this.trackPageName}
          />
          <HotelPaymentBreakupCard
            response={this.props.response}
            pageName={this.trackPageName}
            openTCSOverlay = {this.setTCSOverlay}
          />
        </ScrollView>
        {this.state.bottomOverlay &&
          <OverlayExtraCharges onCloseOverlay={this.onCloseOverlay} infoList={this.state.infoList} heading={this.state.heading} />
        }
        {this.state.TCSOverlay && !isEmpty(priceBreakup) && 
          <CommonBottomOverlayMessage
            isBorderRadius
            dismiss={this.setTCSOverlay}
            content={<TCSBottomOverlay tcsPopupInfo={priceBreakup.popup} onClose={this.setTCSOverlay} />}
            isCancellable />}
      </View>
    );
  }
  onBackPress = () => {
    Actions.pop();
  }
}
PaymentCardDetails.propTypes = {
  response: PropTypes.object.isRequired
};
export default PaymentCardDetails;

