import React from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import { isEmpty } from 'lodash';
import Actions from '../../../../../navigation/postSalesNavigation';
import styles from './DoorToDoorPageCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { AVAN_LOGO, LUGGGAGE_ICON, BACK_WHITE_ICON } from '../../../../FlightImageConstants';
import { getFont } from '../../../../../PostSalesStyles';

const DoorToDoorPage = ({ d2DHeading, importantInfo, doorToDoorSegmentList, ctaList }) => {
  const { fsStyle, ...fonts } = getFont(true);
  return (
    <View style={styles.pageWrapper}>
      <View style={[styles.whiteCard, AtomicCss.marginBottom10]}>
        <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom15]}>
          <View style={[AtomicCss.flex1, AtomicCss.alignCenter, AtomicCss.flexRow]}>
            <Image style={styles.laggageIconStyle} source={LUGGGAGE_ICON} />
            {!isEmpty(d2DHeading) && (
              <Text style={[fsStyle.font14, fonts.boldFontFamily, AtomicCss.darkGreyText]}>
                {d2DHeading}
              </Text>
            )}
          </View>
          <Image style={styles.logoIconStyle} source={AVAN_LOGO} />
        </View>
        {!isEmpty(doorToDoorSegmentList) &&
          doorToDoorSegmentList.map((item) => {
            const suffixStyle = item.disabled ? 'disabled' : '';
            return (
              <TouchableOpacity
                activeOpacity={0.8}
                disabled
                style={[styles.cardList, styles[`cardList${suffixStyle}`]]}
              >
                <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.spaceBetween]}>
                  <View>
                    <View style={AtomicCss.flexRow}>
                      <Text
                        style={[
                          AtomicCss.blackText,
                          fonts.blackFontFamily,
                          fsStyle.font14,
                          AtomicCss.marginBottom6,
                        ]}
                      >
                        {item.fromcityname} - {item.tocityname}
                      </Text>
                      {!isEmpty(item.statusText) && (
                        <Text
                          style={[AtomicCss.headingSubTxt, AtomicCss.marginLeft5, { marginTop: 2 }]}
                        >
                          {item.statusText}
                        </Text>
                      )}
                    </View>
                    {(!isEmpty(item.mainContent1) || !isEmpty(item.mainContent2)) && (
                      <Text
                        style={[fonts.regularFontFamily, fsStyle.font12, AtomicCss.defaultText]}
                      >
                        {!isEmpty(item.mainContent1) && (
                          <Text style={AtomicCss.blackText}>{item.mainContent1} </Text>
                        )}
                        {isEmpty(item.mainContent2) ? '' : item.mainContent2}
                      </Text>
                    )}
                    {!isEmpty(item.otherInfo) && (
                      <Text
                        style={[
                          fonts.regularFontFamily,
                          fsStyle.font12,
                          AtomicCss.defaultText,
                          AtomicCss.marginTop5,
                        ]}
                      >
                        {item.otherInfo.join(' • ')}
                      </Text>
                    )}
                  </View>
                </View>
                {!isEmpty(item.ctaList) && (
                  <View style={styles.linkWrapper}>
                    {item.ctaList.map((cta, index) => (
                      <TouchableOpacity
                        disabled={item.disabled}
                        onPress={() =>
                          Actions.openPsWebView({
                            url: cta.actionUrl,
                            headerText: isEmpty(cta.header)
                              ? this.props.defaultActionButtonTitleText
                              : cta.header,
                            headerIcon: BACK_WHITE_ICON,
                          })
                        }
                      >
                        <Text
                          style={[
                            AtomicCss.azure,
                            fonts.boldFontFamily,
                            fsStyle.font14,
                            index === 0 ? AtomicCss.marginRight16 : '',
                          ]}
                        >
                          {cta.text}
                        </Text>
                      </TouchableOpacity>
                    ))}
                  </View>
                )}
              </TouchableOpacity>
            );
          })}

        {!isEmpty(importantInfo) && (
          <View style={[AtomicCss.marginBottom8, AtomicCss.marginTop10]}>
            {importantInfo.map((info) => (
              <View style={AtomicCss.flexRow}>
                <View style={styles.bullet} />
                <Text
                  style={[
                    AtomicCss.flex1,
                    fsStyle.font11,
                    fonts.regularFontFamily,
                    { color: '#777777' },
                  ]}
                >
                  {info}
                </Text>
              </View>
            ))}
          </View>
        )}
        {!isEmpty(ctaList) && (
          <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, { paddingLeft: 10 }]}>
            {ctaList.map((cta, index) => (
              <React.Fragment>
                <TouchableOpacity
                  onPress={() =>
                    Actions.openPsWebView({
                      url: cta.actionUrl,
                      headerText: isEmpty(cta.header)
                        ? this.props.defaultActionButtonTitleText
                        : cta.header,
                      headerIcon: BACK_WHITE_ICON,
                    })
                  }
                >
                  <Text style={[AtomicCss.azure, fonts.boldFontFamily, fsStyle.font14]}>
                    {cta.text}
                  </Text>
                </TouchableOpacity>
                {index !== ctaList.length - 1 && (
                  <Text style={[AtomicCss.darkGreyText, styles.dot, fsStyle.font14]}>.</Text>
                )}
              </React.Fragment>
            ))}
          </View>
        )}
      </View>
    </View>
  );
};

export default DoorToDoorPage;
