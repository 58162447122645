import React from 'react';
import {Text, TouchableHighlight, TouchableOpacity, View} from 'react-native';
import styles from './styles/SnackBarCss';
import PropTypes from 'prop-types';
import {isNonEmpty} from '@mmt/legacy-commons/Common/utils/StringUtils';

const SnackBar =
  ({topMessage, centerMessage, ctaText, ctaHandler, backCtaText, backCtaHandler}) =>
    (
      <View style={styles.overlayContainer}>
        <TouchableHighlight style={styles.overlayBg}>
          <Text>.</Text>
        </TouchableHighlight>
        <View style={styles.overlayContent}>
          <View style={[styles.marginBottom32, styles.flexRow]}>
            <Text style={styles.overlayHead}>{topMessage}</Text>
          </View>
          {
            isNonEmpty(centerMessage) &&
            <View style={[styles.marginBottom35, styles.flexRow, styles.alignCenter]}>
              <View style={styles.flex1}>
                <Text style={styles.rightContent}>
                  {centerMessage}
                </Text>
              </View>
            </View>
          }
          <View style={[styles.flexRow, styles.alignCenter, styles.JustifySpaceBetween]}>
            <TouchableOpacity onPress={backCtaHandler}>
              <Text style={styles.actionTextLightBlue}>{backCtaText}</Text>
            </TouchableOpacity>
            {
              isNonEmpty(ctaText) && <TouchableOpacity onPress={ctaHandler}>
                <Text style={styles.actionBlueText}>{ctaText}</Text>
              </TouchableOpacity>
            }
          </View>
        </View>
      </View>
    );

SnackBar.propTypes = {
  topMessage: PropTypes.string.isRequired,
  centerMessage: PropTypes.string.isRequired,
  ctaText: PropTypes.string.isRequired,
  ctaHandler: PropTypes.func.isRequired,
  backCtaText: PropTypes.string.isRequired,
  backCtaHandler: PropTypes.func.isRequired
};

export default SnackBar;
