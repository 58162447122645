import _ from 'lodash';
import { FLIGHT_CANCELLATION_TYPE } from '../../../PostSalesConstant';

class SpecialClaimUtility {
  getCancellationPreviewRequestForAutoRefund = (
    bookingId,
    cancellationReason,
    lobCode,
    paxFareSegmentIdList,
    mobileNo,
    validateClaim,
  ) => {
    const flightCancellationPreviewRequest = {};
    flightCancellationPreviewRequest.bookingId = bookingId;
    flightCancellationPreviewRequest.fullCancellationReason = cancellationReason;
    flightCancellationPreviewRequest.isFullCancellation = false;
    flightCancellationPreviewRequest.refundOption = 'RefundToCustomer';
    flightCancellationPreviewRequest.lobCode = lobCode;
    flightCancellationPreviewRequest.paxFareSegmentIdList = paxFareSegmentIdList;
    flightCancellationPreviewRequest.phoneNo = mobileNo;
    flightCancellationPreviewRequest.flightCancellationType =
      FLIGHT_CANCELLATION_TYPE.SPECIAL_CLAIM;
    flightCancellationPreviewRequest.validateClaim = validateClaim;
    return flightCancellationPreviewRequest;
  };

  getScHeaderTitle = (messageAttributes) => {
    let header = 'Claim Refund';
    if (_.isEmpty(messageAttributes) || _.isEmpty(messageAttributes['SC_CARD_HEADER']))
      return header;
    return messageAttributes['SC_CARD_HEADER'];
  };

  getRefundPolicyHeaderTitle = (messageAttributes) => {
    let header = 'Refund Policy';
    if (_.isEmpty(messageAttributes) || _.isEmpty(messageAttributes['REFUND_POLICY_CARD_HEADER']))
      return header;
    return messageAttributes['REFUND_POLICY_CARD_HEADER'];
  };
}

export default new SpecialClaimUtility();
