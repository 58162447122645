import React, { Component } from 'react';
import {View, Text, TouchableOpacity, Image} from 'react-native';
import AtomicCss from '../../../commonStyles/AtomicCss';
import CheckBox from '../CheckBox';
import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';
import _ from 'lodash';
import {rightArrow} from "../../../BusImageConstants";
import { label } from '@mmt/post-sales/src/staticData/staticData';

class IssueList extends Component {
  constructor(props) {
    super(props);
    this.pageName = 'IssueList';
    this.state = {
      showTravelledIssueList : false,
      showNotTravelledIssueList : false,
      issueListObj : this.props.issueListObj
    }
  }

  getNewIssueList() {
    const isShowTravelled = this.state.showTravelledIssueList !== false ? 'activeStyle':'';
    const isShowNotTravelled = this.state.showNotTravelledIssueList !== false ? 'activeStyle':''

    return(
        <View style={AtomicCss.paddingLeft20}>
          <View style={[styles.IssueSection]}>
            <TouchableOpacity  style={[AtomicCss.flexRow,AtomicCss.alignCenter,AtomicCss.spaceBetween,AtomicCss.marginBottom5]} onPress={() => {this.collapseTravelledIssueList()}}>
              <Text style={[AtomicCss.boldFont, AtomicCss.defaultText, AtomicCss.font16]}>{label('bus.issue.travelled')}</Text>
              <Image style={[styles.downArrow, styles[isShowTravelled]]} source={rightArrow}/>
            </TouchableOpacity>
            {this.state.showTravelledIssueList && this.getIssueList("travelled")}
          </View>
          <View style={[styles.IssueSection]}>
            <TouchableOpacity  style={[AtomicCss.flexRow,AtomicCss.alignCenter,AtomicCss.spaceBetween,AtomicCss.marginBottom5]} onPress={() => {this.collapseNotTravelledIssueList()}}>
              <Text style={[AtomicCss.boldFont, AtomicCss.defaultText, AtomicCss.font16]}>{label('bus.issue.notTravelled')}</Text>
              <Image style={[styles.downArrow, styles[isShowNotTravelled]]} source={rightArrow}/>
            </TouchableOpacity>
            {
              this.state.showNotTravelledIssueList && this.getIssueList("nottravelled")
            }
          </View>
        </View>
    )
  }

  getBusSupportDataForTravelled(busSupportData){
    let busSupportDataTmp = {};
    let issueList = busSupportData && busSupportData.issueList;
    let finalList = [];
    issueList.map(issue => {
      const subIssueList = issue && issue.subIssueList && issue.subIssueList.filter(subIssue => subIssue.category === 'Travelled');
      finalList.push({
        ...issue,
        subIssueList: subIssueList
      })
    })
    busSupportDataTmp = ({
      ...busSupportData,
      issueList : finalList
    })
    return busSupportDataTmp;
  }

  getBusSupportDataForNotTravelled(busSupportData) {
    let issueList = busSupportData && busSupportData.issueList;
    let finalList = [];
    let busSupportDataTmp = {};
    issueList.map(issue => {
      const subIssueList = issue && issue.subIssueList && issue.subIssueList.filter(subIssue => subIssue.category === 'Not Travelled');
      finalList.push({
        ...issue,
        subIssueList: subIssueList
      })
    })
    busSupportDataTmp = ({
      ...busSupportData,
      issueList : finalList
    })
    return busSupportDataTmp;
  }

  collapseTravelledIssueList() {
    if (!this.state.showTravelledIssueList) {
      this.props.onClickEventTracker('BusserviceIssue_Travelled');
    }
    this.props.updateIssueList([]);
    this.setState({
      showTravelledIssueList : !this.state.showTravelledIssueList,
    })
    if(this.state.showNotTravelledIssueList){
      this.setState({
        showNotTravelledIssueList : !this.state.showNotTravelledIssueList,
      })
    }

    if(_.isEmpty(this.props.issueListObj)){
      this.props.proceedDisabled()
    }
  }
  collapseNotTravelledIssueList() {
    if (!this.state.showNotTravelledIssueList) {
      this.props.onClickEventTracker('BusserviceIssue_NotTravelled');
    }
    this.props.updateIssueList([]);
    this.setState({
      showNotTravelledIssueList : !this.state.showNotTravelledIssueList,
    })
    if(this.state.showTravelledIssueList){
      this.setState({
        showTravelledIssueList : !this.state.showTravelledIssueList,
      })
    }

    if(_.isEmpty(this.props.issueListObj)){
      this.props.proceedDisabled()
    }
  }
  getIssueList(key) {
    let busSupportData = (key === 'travelled') ? (this.getBusSupportDataForTravelled(this.props.busSupportData)) : (this.getBusSupportDataForNotTravelled(this.props.busSupportData));
    let msrTicketList = this.props.msrTicketList;
    let issueList = busSupportData && busSupportData.issueList ?
      busSupportData.issueList.filter(issue => issue.enabled) : null;
    issueList = issueList && issueList.map(issue => {
      if (issue && issue.subIssueList && msrTicketList) {
        let totalSubIssueLength = issue.subIssueList.filter((subIssue) => subIssue.enabled).length;
        let msrSubIssueLength = msrTicketList.filter((ticket) => issue.name === ticket.secondaryType).length;
        if (totalSubIssueLength === msrSubIssueLength) issue.enabled = false;
        let subIssueList = issue.subIssueList ? issue.subIssueList.filter((subIssue) => subIssue.enabled) : null;
        subIssueList &&
          subIssueList.map((subIssue, index) => {
            msrTicketList && msrTicketList.map((ticket) => {
              if (issue.name === ticket.secondaryType && subIssue.name === ticket.ternaryType) {
                // console.log(' ticket raised for -- ', ticket.secondaryType, ' - ', ticket.ternaryType);
                subIssue.enabled = false;
              }
            });
            return subIssue;
          });
        return issue;
      }
    }
    );

    return (issueList &&
      <React.Fragment>
        {issueList.map(issue => {

          if (issue.enabled) {
            let leng = issue.subIssueList.filter((subIssue) => subIssue.enabled).length;

            let count = 0;

            let subIssueList = issue.subIssueList ? issue.subIssueList.filter((subIssue) => subIssue.enabled) : null;

            return (
              issue.enabled &&
              <View style={[styles.sectionCard]}>
                <Text style={[AtomicCss.defaultText, AtomicCss.font16, AtomicCss.boldFont]}>
                  {issue.name}
                </Text>
                <View>
                  {subIssueList &&
                    subIssueList.map((subIssue, index) => {

                      if (subIssue.enabled) {
                        let issueObject = {
                          id: `${issue.id}_${subIssue.id}`,
                          category : subIssue.category,
                          issue: { id: issue.id, name: issue.name },
                          subIssue: {
                            id: subIssue.id,
                            name: subIssue.name,
                            issueName: issue.name,
                            options: subIssue.options,
                            busSupportType: issue.id,
                            myDeskTicket: subIssue.myDeskTicket,
                            redbusTicket: subIssue.redbusTicket,
                            attachment: subIssue.attachment
                          }
                        };

                        msrTicketList && msrTicketList.map((ticket) => {
                          if (issue.name === ticket.secondaryType && subIssue.name === ticket.ternaryType) {
                            subIssue.enabled = false;
                          }
                        })

                        let subIssueStyle = [];
                        subIssueStyle.push(styles.checkBoxWrapper);

                        count++;

                        if (index == leng - 1) { subIssueStyle.push(styles.lastItemStyle); }

                        return (
                          subIssue.enabled &&
                          <View style={subIssueStyle}>
                            <CheckBox title={subIssue.name}
                              pageName={this.pageName}
                              listObj={this.props.issueListObj}
                              currentObj={issueObject}
                              updateList={this.props.updateIssueList}
                              category={subIssue.category}
                            />
                          </View>
                        );
                      }
                    }
                    )}
                </View>
              </View>
            )
          }
        }
        )
        }
      </React.Fragment>
    );
  }

  render() {
    return (
      <View>
        <View style={[AtomicCss.whiteCardSection, AtomicCss.marginBottom16]}>
          <View style={[styles.sectionCard, { borderBottomWidth: 0 }]}>
            <Text style={[AtomicCss.defaultText, AtomicCss.font22, AtomicCss.lightFont]}>
              {this.props.busSupportData.selectIssueTitle}
            </Text>
          </View>
          {this.getNewIssueList()}
        </View>
      </View>
    );
  }
}

const styles = {
  sectionCard: { padding: 16, paddingBottom: 10, borderBottomWidth: 1, borderColor: '#f2f2f2' },
  checkBoxWrapper: { borderBottomWidth: 1, borderBottomColor: '#f2f2f2' },
  lastItemStyle: { borderBottomWidth: 0, borderBottomColor: 'transparent' },
  noBorderBottom: { borderBottomWidth: 0 },
  fltDtlsWrapper: { backgroundColor: '#fff'},
  topHeading: {
    paddingHorizontal: 16,
    paddingTop: 16,
    paddingBottom: 12,
    marginBottom: 2,
    ...getPlatformElevation(3),
    backgroundColor: '#fff'
  },
  actionBtnStyle: {
    height: 50,
    width: 50,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    right: 4
  },
  arrowCircleStyle: {
    width: 32,
    height: 32
  },
  IssueSection: {
    padding: 16,
    backgroundColor: '#ffffff',
    borderBottomColor: '#f2f2f2',
    borderBottomWidth: 1,
    marginHorizontal: -15,
  },
  downArrow:{
    width:9,
    height:13,
    right:15,
    transform:[{
      rotate:'90deg'
    }]
  },
  activeStyle:{
    transform:[{
      rotate:'-90deg'
    }]
  },
};

export default IssueList;
