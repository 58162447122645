import React, { useEffect, useContext } from 'react';
import { View, Text, Image, Platform } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import createStyle from './CustomerSupportCardCss';
import CustomerSupportCardConst from './CustomerSupportCardConst';
import { ThemeContext } from '../../theme/theme.context';
import CustomerSupportCardTracker from './tracking';
import BaseButton, { BUTTON_VARIANT } from '../BaseButton';
import { cardClickHandler } from './CustomerSupportCardHelper';
import { isEnglishLangSelected } from '@mmt/legacy-commons/AppState/AppUserConfig';

const CustomerSupportCard = ({ deepLinkHandler, pageName, needMoreHelpCard, bookingId }) => {
    const { psTheme } = useContext(ThemeContext);
    const styles = createStyle(psTheme);

    const { actionList = [], header, description, customerSupportIcon, chatGradientImage } = needMoreHelpCard || {};
    const { text: ctaText, url: actionUrl, isWebView } = actionList[0] || {};
    if (!actionUrl || Platform.OS === 'web' || !isEnglishLangSelected()) {
        return null;
    };

    const omnitureTracking = new CustomerSupportCardTracker(pageName, 'bottomcard', bookingId);

    useEffect(() => {
        omnitureTracking.trackLoadEvent();
    }, []);

    useEffect(() => {
        if(!!actionUrl && Platform.OS !== 'web' && isEnglishLangSelected()) {
            omnitureTracking.trackSeenEvent();
        }
    }, [actionUrl]);

    return (
        <View style={[styles.mainWrapper, AtomicCss.marginBottom12, AtomicCss.overflow, AtomicCss.marginHz12]}>
            <View
                style={[
                    styles.cardContentTop,
                    AtomicCss.paddingLeft20,
                    AtomicCss.flexRow,
                    AtomicCss.spaceBetween,
                ]}
            >
                <View style={[AtomicCss.marginTop15]}>
                    <Text
                        style={[
                            AtomicCss.font16,
                            AtomicCss.blackFont,
                            AtomicCss.blackText,
                            AtomicCss.marginBottom4,
                        ]}
                    >
                        {header ?? CustomerSupportCardConst.INLINE_CARD_HEADER}
                    </Text>
                    <Text style={[AtomicCss.font12, AtomicCss.regularFont, AtomicCss.blackText]}>
                        {description ?? CustomerSupportCardConst.INLINE_CARD_DESC}
                    </Text>
                </View>
                <Image source={{ uri: customerSupportIcon }} style={styles.supportImgStyle} />
            </View>
            <View style={[styles.btnWrapper, AtomicCss.alignSelfFlexEnd, AtomicCss.marginRight16, AtomicCss.marginBottom12]}>
                <BaseButton
                    text={ctaText ?? CustomerSupportCardConst.CARD_CTA_TEXT}
                    clickHandler={() => cardClickHandler(omnitureTracking, deepLinkHandler, actionUrl, isWebView)}
                    variant={BUTTON_VARIANT.PRIMARY}
                    textStyle={AtomicCss.font12}
                    buttonStyle={AtomicCss.paddingVertical10}
                />
            </View>
            <View style={styles.supportBgWrapper}>
                <Image source={{ uri: chatGradientImage }} style={styles.supportBgStyle} />
            </View>
        </View>
    );
};

export default CustomerSupportCard;
