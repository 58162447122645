import React from "react";
import { View, Image, Text, StyleSheet } from "react-native";
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import icInfoYellow from '@mmt/legacy-assets/src/ic-info-yellow.webp';

const PendingPaymentMessage = ({subTitle}) => {
    return (
        <View>
            <Text style={[AtomicCss.spaceBetween, AtomicCss.marginTop12, AtomicCss.marginBottom30]}>
                <Image source={icInfoYellow} style={styles.iconStyle}/>
                <Text style={[AtomicCss.yellowText, AtomicCss.regularTextFont, AtomicCss.boldFont, AtomicCss.makeRelative, { top: "-4px"}]} >
                        {subTitle}
                </Text>
            </Text>
        </View>
    )
}

const styles = StyleSheet.create({
    iconStyle: {
        height: 18,
        width: 18,
        marginRight: 5 
    }
})

export default PendingPaymentMessage;