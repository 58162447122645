import React from 'react';
import { StyleSheet, View, Text, Image } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import HTMLView from 'react-native-htmlview';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import { openWebView } from 'apps/post-sales/src/utils/PostSaleUtil';
import HotelBookingDetailsConstant from '../../HotelBookingDetailsConstant';

interface FlexiDetailsInterface {
  flexiTitle: string;
  flexiInfoHtml: string;
  flexiIcon: string;
  flexiCtaUrl: string;
}

interface FlexiCancelPolicyProps {
  flexiDetails: FlexiDetailsInterface;
}

const getHtmlStyles = (fonts, theme) => {
  return {
    p: {
      ...fonts.font14,
      ...fonts.regularFontFamily,
      color: theme.color.defaultTextColor,
      lineHeight: 18,
      paddingRight: 20,
      textAlign: 'left',
      flex: 1
    },
    a: {
      top: 2,
      ...fonts.font14,
      ...fonts.boldFontFamily,
      color: theme.color.azure,
      textAlign: 'left',
    }
  };
}

const FlexiCancelPolicy: React.FC<FlexiCancelPolicyProps> = ({flexiDetails}) => {
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = getStyles(fonts, theme);
  const htmlStyle = getHtmlStyles(fonts, theme)
  const { flexiTitle, flexiIcon, flexiInfoHtml, flexiCtaUrl } = flexiDetails || {};
  
  const onLinkPress = () => {
    openWebView(HotelBookingDetailsConstant.FLEXI_HEADER, '', flexiCtaUrl)
  }
  return (
    !!flexiInfoHtml && (
      <View style={styles.flexiCancelWrapper}>
        <View style={styles.contentWrapper}>
          {!!flexiIcon && <Image source={{uri: flexiIcon}} style={styles.cancelImageStyle} />}
          <View style={[AtomicCss.flex1, styles.marginTop3]}>
            {!!flexiTitle && <Text style={[fsStyle.font12, fonts.blackFontFamily, styles.darkGreenText, AtomicCss.marginBottom3]}>{flexiTitle}</Text>}
            <HTMLView
              value={flexiInfoHtml}
              stylesheet={htmlStyle}
              onLinkPress={onLinkPress}
            />
          </View>
        </View>
      </View>
    )
  );
}

const getStyles = (fonts, theme) => StyleSheet.create({
  flexiCancelWrapper: {
    borderWidth:1,
    borderColor: theme.color.lightGray,
    borderRadius:4,
    padding:12,
    backgroundColor: theme.color.green11
  },
  contentWrapper: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent:'space-between',
  },
  cancelImageStyle: {
    width:20,
    height:20,
    marginRight:10,
  },
  darkGreenText: {
    color: theme.color.green12,
  },
  primaryText: {
    color: theme.color.azure,
    top: 2
  },
  defaultText: {
    ...fonts.regularFontFamily,
    color: theme.color.defaultTextColor
  },
  marginTop3: {
      marginTop:-3,
  }
})

export default FlexiCancelPolicy;