import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';

const getStyles = (fonts) => {
  return {
    headerWrapper: {
      justifyContent: 'space-between',
      padding: 16,
      flexDirection: 'row',
      alignItems: 'center',
      zIndex: 10,
    },
    headerShadow: {
      ...getPlatformElevation(2),
    },
    lightGreen: {
      backgroundColor: '#d1f8e6',
    },
    darkGrey: {
      backgroundColor: '#4a4a4a',
    },
    white: {
      backgroundColor: '#ffffff',
    },
    headingTxt: {
      ...fonts.font18,
      color: '#222',
      fontFamily: fonts.bold,
    },
    subTxt: {
      fontFamily: fonts.regular,
      ...fonts.font11,
      color: colors.defaultTextColor,
      marginTop: 3,
    },
    whiteText: {
      color: '#fff',
    },
    crossIconStyle: {
      width: 14,
      height: 14,
      marginLeft: 10,
      marginRight: 20,
    },
    whiteCrossIcon: {
      tintColor: '#fff',
    },
    downloadIcon: {
      width: 24,
      height: 24,
      marginRight: 24,
      tintColor: '#231F20',
    },
    backArrowWrapper: {
      paddingRight: 5,
    },
    backArrow: {
      width: 24,
      height: 24,
      marginRight: 5,
      tintColor: '#222',
    },
    shareIcon: {
      width: 24,
      height: 24,
      marginRight: 10,
      tintColor: '#231F20',
    },
    backArrowIos: {
      width: 11,
      height: 18,
      marginRight: 5,
      tintColor: '#231F20',
    },
  };
}
export default getStyles;
