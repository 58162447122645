import React from 'react';
import PropTypes from 'prop-types';
import {View, Text, Image, TouchableOpacity} from 'react-native';
import isEmpty from 'lodash/isEmpty';
import {fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getPlatformElevation from './getPlatformElevation';
import BasePage from '../../Common/PostSalesBasePage';
import TravellerCardItem from './TravellerCardItem';
import {GuestIcon, rightArrow} from '../HolidayBookingConstants';
import {themeColors} from '../../PostSalesStyles';

class TravellerCard extends BasePage {
  render() {
    const {travellerDetails, buttons} = this.props;
    const travellerCount = travellerDetails.noOfAdults + travellerDetails.noOfChildren + travellerDetails.noOfInfants;
    const travellerLabel = ` ${travellerCount} TRAVELLERS`;

    return (
      <View style={[AtomicCss.marginBottom10, styles.cardBorder]}>
        <View style={[styles.infoCardWrapper]}>
          <View style={styles.headingIconWrapper}>
            <Image style={styles.headingIconStyle} source={GuestIcon}/>
          </View>
          <View style={styles.infoCardContent}>
            <Text
              style={[AtomicCss.defaultText, AtomicCss.blackFont, AtomicCss.font14,
                AtomicCss.marginBottom15]}
            >{travellerLabel}
            </Text>
            {
              travellerDetails.travellerDetailList &&
              travellerDetails.travellerDetailList.map((item, index) => (
                <TravellerCardItem
                  key={item.travellerName + index}
                  item={item}
                  index={index}
                />
              ))
            }
          </View>
        </View>
        {!isEmpty(buttons) &&
        <TouchableOpacity
          style={styles.redirectBtnWrapper}
          activeOpacity={0.8}
          onPress={() => this.props.handleButtonClick(buttons[0])}>
          <Text style={styles.linkBtnTxt}>{buttons[0].text}</Text>
          <Image style={styles.rightArrowIcon} source={rightArrow}/>
        </TouchableOpacity>
        }
      </View>
    );
  }
}

const styles = {
  cardBorder: {
    borderTopColor: '#e4e4e4',
    borderBottomColor: '#e4e4e4',
    borderTopWidth: 1,
    borderBottomWidth: 1
  },
  infoCardWrapper: {
    backgroundColor: '#fff',
    justifyContent: 'space-between',
    flexDirection: 'row',
    ...getPlatformElevation(2),
    marginBottom: 1
  },

  infoCardContent: {
    paddingRight: 8,
    paddingVertical: 15,
    flex: 1
  },
  infoCustom: {
    fontSize: 14,
    color: '#4a4a4a',
    lineHeight: 18,
    fontFamily: fonts.regular
  },
  headingIconWrapper: {
    width: 46,
    alignItems: 'center'
  },
  headingIconStyle: {
    width: 24,
    height: 24,
    top: 10
  },
  redirectBtnWrapper: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 50,
    paddingRight: 35,
    justifyContent: 'space-between',
    flexDirection: 'row',
    backgroundColor: '#fff',
    borderBottomWidth: 1,
    borderBottomColor: '#ededed',
    alignItems: 'center'
  },
  rightArrowIcon: {
    width: 9,
    height: 13,
    tintColor: themeColors.rightArrowTintColor
  },

  linkBtnTxt: {
    fontSize: 14,
    fontFamily: fonts.bold,
    color: themeColors.linkTextColor
  },
  buttonWrapper: {
    paddingTop: 20,
    width: '90%'
  }
};

TravellerCard.propTypes = {
  handleBack: PropTypes.func.isRequired,
  travellerDetails: PropTypes.object.isRequired,
  buttons: PropTypes.array.isRequired
};

export default TravellerCard;
