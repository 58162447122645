import React from 'react';
import {View, Text,Image,TouchableOpacity,Platform,StyleSheet} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import {fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import {getSubTitle} from "../../../utils/SelfDriveBookingDetailUtil";
import {ANDROID_BACK_ARROW,IOS_BACK_ARROW} from '../../../SelfDriveImageConstant';

class StickyHeader extends React.Component {
  constructor(props) {
    super(props);
  }

    render() {
      let color = [this.props.card.colorBegin, this.props.card.colorEnd];
      return (
          <LinearGradient start={{x: 1.0, y: 0.0}} end={{x: 0.0, y: 1.0}} colors={color} style={[styles.TopstickyHeader,styles.bgTransparent]}>
              <View style={styles.topStatusBar}>
                <View style={styles.stickyBarWrapper}>
                 <TouchableOpacity style={styles.backArrowWrapper} onPress={this.props.onBackPress}>
                     <Image style={Platform.OS === 'ios' ? [styles.mar10Bt, styles.IOSbackarrow] : [styles.mar10Bt, styles.backarrow]} source={Platform.OS === 'ios' ? IOS_BACK_ARROW:ANDROID_BACK_ARROW} />
                  </TouchableOpacity>
                  <View style={styles.stickyInfoWrapper}>
                    <View style={styles.stickyTxtWrapper} >
                        <Text numberOfLines={1} ellipsizeMode='tail' style={styles.stickyTxt}>{this.props.response.vehicleInfo.model}</Text>
                    </View>
                    <Text style={styles.stickyTxt2}>{getSubTitle(this.props.response)}</Text>
                  </View>
                </View>
              </View>
          </LinearGradient>
      );
    }
}

const styles = StyleSheet.create({
topStatusBar:{
  flexDirection:'row',
  justifyContent: 'space-between',
 alignItems:'center'
},
backArrowWrapper:{
  width:44,
  height:44,
  alignItems:'center',
  justifyContent:'center',
},
backarrow:{
  width:16,
  height:16,
},
IOSbackarrow:{
  width:10,
  height:16,
},
stickyBarWrapper:{
  flexDirection:'row',
  width:"100%",
  alignItems:"center",
},
stickyTxtWrapper:{
  flexDirection:'row',
  alignItems:'center',
  flexWrap:'wrap'
},
oneWayIconStyle:{
  width:10,
  height:8,
  marginHorizontal:5,
},
stickyInfoWrapper:{
paddingVertical:10,
alignItems: Platform.OS === 'ios' ? 'center' : 'flex-start',
flex: 1,
},
stickyTxt:{
color:'#4a4a4a',
fontFamily:fonts.black,
backgroundColor:'transparent',
marginBottom:5,
},
stickyTxt2:{
  color:'#000',
  fontFamily:fonts.regular,
  backgroundColor:'transparent',
  fontSize:12,
},
ShareImgWrapper:{
  width:40,
  height:40,
  alignItems:"center",
  justifyContent:'center'
},
shareIcon:{
  width:19,
  height:12,
},
})
export default StickyHeader;
