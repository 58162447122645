import React from 'react';
import PropTypes from 'prop-types';
import Actions from '../../navigation/postSalesNavigation';
import CustomSafeAreaView from '@mmt/legacy-commons/Common/Components/CustomSafeAreaView';
import {View, ScrollView, Text, StyleSheet, Platform} from 'react-native';
import StickyHeaderBack from '../components/StickyHeader/StickyHeaderBack';
import ReviewStepHeader2 from '../components/ReviewStepHeader/ReviewStepHeader2';
import AtomicCss from '../commonStyles/AtomicCss';
import RecBlueBtn from '../components/buttons/RecBlueBtn';
import CheckboxRow from '../components/CheckboxRow/CheckboxRow';
import {showShortToast} from '@mmt/legacy-commons/Common/Components/Toast';
import RailBookingTrackingHelper from '../details/RailBookingTrackEventHelper';
import {connect} from "react-redux";
import {isEmpty} from 'lodash';
import { getStaticData } from '../../staticData/staticData';
import { getFont } from '../../PostSalesStyles';
export const TDR = "TDR";
export const CAN = "CAN";
class SelectTraveller extends React.Component {
  constructor(props) {
    super(props, 'SelectTraveller');
    this.bookingDetails = this.props.bookingDetails;
    this.passengers = this.props.passengers;
    this.selectedPax = [];
  }

  static navigationOptions = {
    header: null
  };

  render() {
    const { fsStyle, ...fonts } = getFont(true);
    const staticData = getStaticData();
    const { selectTravelerToCancelText, reviewRfndCalText } = staticData;
    RailBookingTrackingHelper.trackLoadEvent('PaxSelection', this.bookingDetails);
    return (
      <View style={styles.CancellationWrapper}>
        {Platform.OS === 'ios' && <CustomSafeAreaView />}
        <StickyHeaderBack
          trainDetails={this.bookingDetails ? this.bookingDetails.train : null}
        />
        <ScrollView style={AtomicCss.greyBg}>
          <ReviewStepHeader2 currentIndex={1} />
          <View style={[styles.PartCancellationHead, AtomicCss.elevation1]}>
            <Text style={[AtomicCss.blackFont, fonts.blackFontFamily, fsStyle.font16]} >
              {selectTravelerToCancelText}
            </Text>
          </View>
          {this.paxViews()}
        </ScrollView>
        <RecBlueBtn
          btntext={reviewRfndCalText}
          onPressHandler={this.onPressHandler}
        />
        {Platform.OS === 'ios' && <CustomSafeAreaView positionTop={false} />}
      </View>
    );
  }

  onPressHandler = () => {
    const staticData = getStaticData();
    const { selectMin1ToCancelText } = staticData;
    if (this.selectedPax && this.selectedPax.length > 0) {
      RailBookingTrackingHelper.trackClickEvent('PaxSelection', this.bookingDetails, 'ReviewRefundCalculationClicked');

      Actions.reviewRefundCharges({
        bookingDetails: this.props.bookingDetails,
        selectedPax: this.selectedPax,
        mmtAuth: this.props.mmtAuth,
        loggingTrackInfo: this.props.loggingTrackInfo,
        isFullCancellation: false
      });
    } else {
      showShortToast(selectMin1ToCancelText);
    }
  }


  onPaxSelection = (passenger, isSelected) => {
    const index = this.selectedPax.findIndex(x => x.paxId == passenger.paxId);
    if (isSelected) {
      if (index === -1) {
        this.selectedPax.push(passenger);
      }
    } else {
      this.selectedPax.splice(index, 1);
    }
  }

  paxViews = () => {
    const views = [];
    this.passengers.forEach((passenger, index) => {
      if( passenger.currentStatus != CAN && passenger.currentStatus != TDR ) {
        views.push(<CheckboxRow 
                      updatedPassenger = {!isEmpty(this.props.passengerDetails) ? this.props.passengerDetails.PassengerStatus[index] : ''}
                      passenger={passenger} 
                      key={`${passenger.paxId}`}
                      onPaxSelection={this.onPaxSelection}
                    />);
      }
    });
    return views;
  }
}

SelectTraveller.propTypes = {
  bookingDetails: PropTypes.object.isRequired,
  passengers: PropTypes.array.isRequired,
  mmtAuth: PropTypes.string.isRequired,
  loggingTrackInfo: PropTypes.string.isRequired
};


const styles = StyleSheet.create({
  CancellationWrapper: {
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: '#f2f2f2'
  },
  PartCancellationHead: {
    backgroundColor: '#ffffff',
    paddingVertical: 18,
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#ddd'
  },
  progressContainer: {
    flex: 1,
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
    alignItems: 'center',
    justifyContent: 'center'
  }

});

const mapStatesToProps = (state, ownProps) => {
  const {
    passengerDetails
  } = state.trainReducer;
  return {
    ...ownProps,
    passengerDetails
  };
};

export default connect(mapStatesToProps, null) (SelectTraveller);
