import { Platform } from 'react-native';
import { AbConfigKeyMappings, getPokusConfig } from '@mmt/legacy-commons/Native/AbConfig/AbConfigModule';
import { PokusLobs } from '@mmt/legacy-commons/Native/AbConfig/AbConfigKeyMappings';

export default class CabsABConfigHelper {
  // @pokus-key-check 1690178888000
  static multiCityEnabled() {
    if (Platform.OS === 'web') {
      return false;
    }
    return getPokusConfig(PokusLobs.CAB, AbConfigKeyMappings.multicityEnabled, false);
  }

  // @pokus-key-check 1690891122582
  static showNewCancellationSection() {
    //remove
    return getPokusConfig(PokusLobs.CAB, AbConfigKeyMappings.cabsNewCancellationSection, false);
  }
  // @pokus-key-check 1690178888000
  static showFAQSection() {
    return getPokusConfig(PokusLobs.CAB, AbConfigKeyMappings.faqSection, false);
  }
  // @pokus-key-check 1690178888000
  static showDefaultDateTimeAlert() {
    return getPokusConfig(PokusLobs.CAB, AbConfigKeyMappings.cabDefaultDateTimeAlert, false);
  }
  // @pokus-key-check 1690178888000
  static showBNPLOption = () => {
    return getPokusConfig(PokusLobs.CAB, AbConfigKeyMappings.cabsBNPLSwitch, false);
  };
  // @pokus-key-check 1690178888000
  static shouldShowMulticityOption = () => {
    return getPokusConfig(PokusLobs.CAB, AbConfigKeyMappings.multicitySwitch, true);
  };
  // @pokus-key-check 1690178888000
  static showPartialPayment = () => {
    return getPokusConfig(PokusLobs.CAB, AbConfigKeyMappings.cabPartPayment, false);
  };
  // @pokus-key-check 1690178888000
  static showDateTimePickerV2 = () => {
    return getPokusConfig(PokusLobs.CAB, AbConfigKeyMappings.showDateTime, false);
  };
  // @pokus-key-check 1690178888000
  static showListingV2 = () => {
    return getPokusConfig(PokusLobs.CAB, AbConfigKeyMappings.ListingV2, false)
  }
  // @pokus-key-check 1690178888000
  static showReviewV2 = () => {
    return getPokusConfig(PokusLobs.CAB, AbConfigKeyMappings.ReviewV2, false)
  }
  // @pokus-key-check 1692701198865
  static showOSZeroBooking = () => {
    return getPokusConfig(PokusLobs.CAB, AbConfigKeyMappings.OSZeroBooking, false);
  }
  // @pokus-key-check 1694060564893
  static selectLandingOSTripType = () => {
    return getPokusConfig(PokusLobs.CAB, AbConfigKeyMappings.LandingOSTripSelction, false);
  }
  // @pokus-key-check 1696419669489
  static showATListingV2 = () => {
    return getPokusConfig(PokusLobs.CAB, AbConfigKeyMappings.FunnelRevampListingAT, false)
  }
  // @pokus-key-check 1698924456194
  static showReviewAssistedWidget = () => {
    return getPokusConfig(PokusLobs.CAB, AbConfigKeyMappings.ReviewAssistedWidget, false);
  }
  // @pokus-key-check 1700683625189
  static showLandingV2 = () => {
    return getPokusConfig(PokusLobs.CAB, AbConfigKeyMappings.AirportLandingNew, false);
  }
  //@pokus-key-check  1701407781406
  static showUGCReviews = () => {
    return getPokusConfig(PokusLobs.CAB, AbConfigKeyMappings.UGC_Review, false);
  }
  // @pokus-key-check 1696419669489
  static showUpsellFlow = () => {
    return getPokusConfig(PokusLobs.CAB, AbConfigKeyMappings.UpsellFlow, false)
  }
  // @pokus-key-check 1704878673006
  static showMapIntegration = () => {
    return getPokusConfig(PokusLobs.CAB, AbConfigKeyMappings.Map_Integration,false);
  }
  // @pokus-key-check 1704878673006
  static showRealTimeStatus = () => {
    return getPokusConfig(PokusLobs.CAB, AbConfigKeyMappings.Flight_Number_Cab_A2C,false);
  }
  static showListingV2 = () => {
    return getPokusConfig(PokusLobs.CAB, AbConfigKeyMappings.ListingV2, false)
  }
  static showReviewV2 = () => {
    return getPokusConfig(PokusLobs.CAB, AbConfigKeyMappings.ReviewV2, false)
  }
}
