import React from 'react';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';

interface UpiMandateErrorViewProps {
    showRetry?: Boolean;
    onRetry?: Function;
    message?: string;
    buttonLabel?: string;
}

const UpiMandateErrorView: React.FC<UpiMandateErrorViewProps> = ({showRetry, onRetry, message, buttonLabel}) => {
    return (
       <ErrorView
         showRetry={showRetry}
         onRetry={onRetry}
         message={message}
         buttonLabel={buttonLabel}
       />
    );
}

export default UpiMandateErrorView;