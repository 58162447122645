import React from 'react';
import PropTypes from 'prop-types';
import {View, Text, Image, Platform, StyleSheet, TouchableOpacity} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import isEmpty from 'lodash/isEmpty';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import Button from './Button';
import BasePage from '../../Common/PostSalesBasePage';
import {getFontColor, getTopCardColor, getTopCardIcon} from '../HolidayBookingDetailUtils';
import {bookingState, bookingSubState, AndroidBackArrow, IOSBackArrow} from '../HolidayBookingConstants';
import {themeColors} from '../../PostSalesStyles';

class TopCard extends BasePage {
  static navigationOptions = {
    header: null
  };
  getIconStyle = (bookingStatus) => {
    if (bookingStatus.state === bookingState.UPCOMING && bookingStatus.subState === bookingSubState.UPCOMINGPAYMENTINCOMPLETE) {
      return styles.partialIcon;
    } else if (bookingStatus.state === bookingState.UPCOMING && bookingStatus.subState === bookingSubState.UPCOMINGPAYMENTCOMPLETE) {
      return styles.successIcon;
    } else if (bookingStatus.state === bookingState.ONTRIP) {
      return styles.onTripIcon;
    } else if (bookingStatus.state === bookingState.CANCELLED || bookingStatus.state === bookingState.REFUNDED) {
      return styles.cancelledIcon;
    } else if (bookingStatus.state === bookingState.COMPLETED) {
      return styles.completedIcon;
    } else {
      return styles.successIcon;
    }
  };

  render() {
    const {topCard, bookingStateValue, buttons} = this.props;
    return (
      <LinearGradient
        start={{
          x: 1.0,
          y: 0.0
        }}
        end={{
          x: 0.0,
          y: 1.0
        }}
        colors={getTopCardColor(bookingStateValue)}
        style={[styles.topStatusCard]}
      >
        <View>
          <TouchableOpacity style={[styles.backArrowWrapper, styles.topStatusBar]}
                            onPress={this.props.goBack}>
            <Image style={Platform.OS === 'ios' ? [styles.IOSbackarrow]: [styles.backarrow] }
                   source={Platform.OS === 'ios' ? IOSBackArrow : AndroidBackArrow}/>
          </TouchableOpacity>

          <View>
            <Image
              style={[AtomicCss.marginBottom10, this.getIconStyle(bookingStateValue)]}
              source={getTopCardIcon(bookingStateValue)}
            />
          </View>
        </View>
        <View style={styles.StatusCardWrapper}>
          <Text style={[styles.StatusHeadingTxt, {color: getFontColor(bookingStateValue)}]}>
            {topCard.heading1}
          </Text>
          {topCard.heading2 ? (
            <Text style={[styles.StatusSubHeadTxt, {color: getFontColor(bookingStateValue)}]}>
              {topCard.heading2}
            </Text>
          ) : null}
          <View style={[styles.BookingWrapper, AtomicCss.marginBottom8]}>
            <Text style={styles.booingDtlsTxt}>BOOKING ID</Text>
            <View>
              <Text style={styles.booingDtlsbold}>{topCard.bookingId}</Text>
            </View>
          </View>
        </View>
        {!isEmpty(buttons) &&
        <View style={styles.buttonWrapper}>
          {buttons.map(item => (
            <Button
              button={item}
              handleClick={this.props.handleButtonClick}
            />
          ))
          }
        </View>
        }
      </LinearGradient>
    );
  }
}

const styles = StyleSheet.create({
  topStatusBar: {
    paddingTop: 5,
    paddingRight: 10,
    position: 'absolute',
    top: 0
  },
  backArrowWrapper: {
    width: 44,
    height: 44,
    alignItems: 'center',
    justifyContent: 'center',
    left: -20,
    zIndex: 10
  },
  topStatusCard: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingHorizontal: 20
  },
  backarrow: {
    width: 16,
    height: 16,
    tintColor: themeColors.backArrowTintColor
  },
  IOSbackarrow: {
    width: 10,
    height: 16,
    position: 'absolute'
  },
  StatusCardWrapper: {
    paddingLeft: 20,
    paddingRight: 20
  },
  StatusSubHeadingTxt: {
    textAlign: 'center',
    fontFamily: fonts.medium,
    marginBottom: 8,
    top: -6
  },
  StatusHeadingTxt: {
    textAlign: 'center',
    fontSize: 16,
    fontFamily: fonts.black,
    lineHeight: 24,
    marginBottom: 5,
    backgroundColor: 'transparent'
  },
  StatusSubHeadTxt: {
    textAlign: 'center',
    fontSize: 16,
    fontFamily: fonts.black,
    lineHeight: 24,
    marginBottom: 10,
    backgroundColor: 'transparent'
  },
  successIcon: {
    width: 98,
    height: 56,
    alignSelf: 'center'
  },
  partialIcon: {
    width: 64,
    height: 58,
    alignSelf: 'center'
  },
  onTripIcon: {
    width: 68,
    height: 65,
    alignSelf: 'center'
  },
  completedIcon: {
    width: 90,
    height: 67,
    alignSelf: 'center'
  },
  cancelledIcon: {
    width: 104,
    height: 63,
    alignSelf: 'center'
  },
  BookingWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignSelf: 'center'
  },
  booingDtlsTxt: {
    color: themeColors.lightText,
    fontSize: 12,
    fontFamily: fonts.regular,
    lineHeight: 14,
    marginRight: 10
  },
  booingDtlsbold: {
    color: themeColors.lightText,
    fontSize: 12,
    fontFamily: fonts.bold,
    lineHeight: 14,
    textAlign: 'right'
  },
  buttonWrapper: {
    alignItems: 'center',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'center'
  },
  ShareImgWrapper: {
    width: 24,
    height: 25,
    alignItems: 'center',
    position: 'absolute',
    right: 15,
    top: 15,
    justifyContent: 'center'
  },
  shareIcon: {
    width: 19,
    height: 12
  }
});

TopCard.propTypes = {
  topCard: PropTypes.object.isRequired,
  handleBack: PropTypes.func.isRequired,
  bookingStateValue: PropTypes.number.isRequired,
  buttons: PropTypes.array.isRequired
};

export default TopCard;
