import _isEmpty from 'lodash/isEmpty';
import _isString from 'lodash/isString';
import _isObject from 'lodash/isObject';
import fecha from 'fecha';
import * as omniture from '@mmt/legacy-commons/Common/utils/OmnitureTrackerUtils';
import { diffDays, today } from '@mmt/legacy-commons/Helpers/dateHelpers';
import { CABS_DATE_FMT, CabABKeys, UNIVERSAL_SEARCH, cabsAbOmnitureKeys } from './cabsConstants';
import { getOmnitureEVars, saveOmnitureEVars } from './utils/cabsLocalStore';
import TripType from './types/TripType';
import { CabsScreen } from './config/cabsScreen';
import * as UserSessionModule from '@mmt/legacy-commons/Native/UserSession/UserSessionModule';
import CabsABConfigHelper from './utils/CabsABConfigHelper';
import { CabTypes } from '../src/cabsConstants';

const PAGE_NAME_PREFIX_LANDING = 'mob:landing:domestic cabs';
const PAGE_NAME_PREFIX_FUNNEL = 'mob:funnel:domestic cabs';

const LOB_NAME = 'mob domestic cabs';

export const PAGE_NAMES = {
  LANDING_PAGE: 'landing-New',
  LISTING_PAGE: 'listing',
  REVIEW_PAGE: 'review',
  CHECKOUT_PAGE: 'checkout',
  THANKYOU_PAGE: 'thankyou',
  PRIMARY_PAX_PAGE: 'primary_pax',
  UNIVERSAL_SEARCH_OVERLAY_PAGE: 'universal search overlay page',
  RATINGS_REVIEWS_PAGE: 'ratings & review',
  ADDON_LANDING_PAGE: 'landing airport add on',
  ADDON_REVIEW_PAGE: 'review airport add on',
  ADDON_THANKYOU_PAGE: 'thankyou airport add on',
  APPROVER_REQUESTOR: 'myBiz',
};

const cabsAbTrackingKey = 'm_v97';
const EVAR_28 = 'm_v28';
export const cabsSource = 'm_v71';
const cabsSrcDest = 'm_v13';
const cabsHydraSegment = 'm_v94';
const EVAR_82 = 'm_v82';
const EVAR_27 = 'm_v27';
const EVAR_50 = 'm_v50';
const EVAR_40 = 'm_v40';
const PROP_1 = 'm_c1;';
const EVAR_46 = 'm_v46';
let _userDetails = {};
export const CabsFeedbackPages = Object.freeze({
  OVERALL_RATING_PAGE: 'funnel:domestic cabs:rating and review pg1',
  EXTRA_FEEDBACK_PAGE: 'funnel:domestic cabs:rating and review pg2',
  THANKYOU_PAGE: 'funnel:domestic cabs:rating and review pg3',
});
// If there are multiple props/var needs to be tracked then pass a dictionary or if only prop54
// needs to be tracked pass prop54 value as string
const _prepareTrackingData = (data) => {
  let trackingData = data;
  if (_isString(trackingData)) {
    trackingData = {
      [omniture.PROP_54]: data,
    };
  }
  return trackingData;
};

export const setUserDetailsInCabsAnalytics = (userDetails) => {
  _userDetails = userDetails;
};
const _trackEvent = async (pageName, data, isLoadEvent = false) => {
  let channelName = pageName.startsWith('mob:landing:') ? 'landing' : 'mob domestic cabs funnel';
  let lobName = LOB_NAME;
  const isCorpUser = await UserSessionModule.isCorporateUser();
  if (isCorpUser) {
    if (channelName !== 'landing') {
      channelName += '|corporate';
    }
    lobName += '|corporate';
  }
  // Wrapping inside a setTimeout to prevent any omniture latency/erros to effect the main logic
  setTimeout(() => {
    omniture.trackOmniture(
      pageName,
      {
        ..._prepareTrackingData(data),
        [omniture.PROP_24]: lobName,
        [omniture.EVAR_15]: pageName,
        [omniture.VAR_CHANNEL]: channelName,
        [omniture.VAR_LOB_KEY]: lobName,
      },
      isLoadEvent,
    );
  }, 0);
};

export const getPagePrefix = (isLanding = false) => {
  return isLanding ? PAGE_NAME_PREFIX_LANDING : PAGE_NAME_PREFIX_FUNNEL;
};

export const trackLandingEvent = (data, isLoadEvent = false) => {
  const PagePrefix = getPagePrefix(true);
  _trackEvent(`${PagePrefix}:${PAGE_NAMES.LANDING_PAGE}`, data, isLoadEvent);
};

export const trackPrimaryPaxEvent = (data) => {
  const PagePrefix = getPagePrefix();
  _trackEvent(`${PagePrefix}:${PAGE_NAMES.PRIMARY_PAX_PAGE}`, data);
};

let _cabsEVars = {};
let _cabsAddonEVars = {};
let _cabsFeedbackEVars = {};
let _onHoldTrackingFunc = null;
let personalizedTripTracker = '';

export const trackLandingAbEvent = (abExperimentName) => {
  trackLandingEvent({
    [cabsAbTrackingKey]: abExperimentName,
  });
};

export const getCabsOmnitureEVars = () => _cabsEVars;
export const setCabsOmnitureEVars = (value) => {
  _cabsEVars = value;
};

export const personalizedTripTracking = (data) => {
  personalizedTripTracker = data;
};

export const setIsLoggedInTracker = (tracker) => {
  _cabsEVars = {
    ..._cabsEVars,
    [omniture.VAR_USER_LOGIN_STATUS]: tracker,
  };
};

export const myBizPrimaryPaxTracker = (bookerType, primaryPaxType) => {
  _cabsEVars = {
    ..._cabsEVars,
    [EVAR_27]: `Mob_Cab_MyBiz_${bookerType}_${primaryPaxType}`,
  };
};

export const setReceiverSourceAndCmp = (source = null, cmp = null) => {
  if (source && !_cabsEVars.cabsSource) {
    _cabsEVars = {
      ..._cabsEVars,
      [cabsSource]: source,
    };
  }
  if (cmp) {
    _cabsEVars = {
      ..._cabsEVars,
      [omniture.VAR_CAMPAIGN]: cmp,
    };
  }
  return _cabsEVars;
};

export const setHydraSegment = (hydraSegment = null) => {
  if (hydraSegment) {
    _cabsEVars = {
      ..._cabsEVars,
      [cabsHydraSegment]: hydraSegment,
    };
  }
};

// FunnelVar are variables persisted by omniture throughout the funnel, this is not const
// because it'll be updated in subsequent pages
export const createFunnelVars = (listingRequest, source = null, isMMTBlackUser = false) => {
  const {
    source_location,
    destination_location,
    departure_date,
    pickup_time,
    trip_type,
    profile_type,
    is_instant_search = false,
    source_city_name,
    destination_city_name,
  } = listingRequest;
  let srcDest = `${source_city_name || ''}`;
  let srcDestAddress = `${source_location.address}`;
  if (destination_location !== null && trip_type !== TripType.HR.value) {
    srcDest = `${source_city_name || ''} | ${destination_city_name || ''}`;
    srcDestAddress = `${source_location.address} | ${destination_location.address}`;
  }
  const deptDate = fecha.parse(departure_date, CABS_DATE_FMT);
  const deptDateFormatted = fecha.format(deptDate, 'D:M:YYYY');
  const advancePurchaseDays = parseInt(diffDays(deptDate, today()), 10);
  _cabsEVars = {
    ..._cabsEVars,
    [omniture.VAR_SRC_DEST]: srcDest,
    [cabsSrcDest]: srcDestAddress,
    [omniture.VAR_AP]: advancePurchaseDays,
    [omniture.VAR_START_DATE]: `${deptDateFormatted} ${pickup_time}`,
    [omniture.VAR_TRIP_TYPE]:
      trip_type === TripType.AT.value
        ? is_instant_search
          ? `AT_Instant`
          : `AT_Schedule`
        : trip_type,
  };
  if (isMMTBlackUser) {
    _cabsEVars = {
      ..._cabsEVars,
      [omniture.PROP_42]: 'MMT_Black_User',
    };
  }
  if (profile_type === UserSessionModule.ProfileType.BUSINESS) {
    _cabsEVars = { ..._cabsEVars, [omniture.VAR_MYBIZ_CABS]: 'MyBiz_Cabs' };
  }
  if (source === UNIVERSAL_SEARCH) {
    _cabsEVars = { ..._cabsEVars, [cabsSource]: UNIVERSAL_SEARCH };
  }
  return _cabsEVars;
};

export const trackListingLoadErrorEvent = () => {
  trackListingErrorEvent(_cabsEVars, false, true);
};

export const trackUniversalSearchLoad = (srcDest, srcDestAddress, tripType) => {
  if (srcDest) {
    const trackingData = {
      [omniture.VAR_SRC_DEST]: srcDest,
      [cabsSrcDest]: srcDestAddress,
      [omniture.VAR_TRIP_TYPE]: tripType,
      [cabsSource]: UNIVERSAL_SEARCH,
    };
    trackUniversalSearchEvent(trackingData, true);
  }
};

export const pushOnHoldTrackingListing = () => {
  if (_onHoldTrackingFunc) _onHoldTrackingFunc();
};

export const setOnHoldTrackingFunc = (trackerFunc) => {
  _onHoldTrackingFunc = trackerFunc;
};

const _capitalize = (s) => (s ? s[0].toUpperCase() + s.slice(1) : '');

export const setPokusFlag = (pokusFlags) => {
  return pokusFlags.reduce((acc, nxt) => {
    if (cabsAbOmnitureKeys[nxt.key]) {
      if (nxt.value === null) {
        return (acc = acc
          ? `${acc} | ${nxt.key}_${_capitalize(nxt.flag.toString())}`
          : `${nxt.key}_${_capitalize(nxt.flag.toString())}`);
      }
      return (acc = acc ? `${acc} | ${nxt.key}_${nxt.value}` : `${nxt.key}_${nxt.value}`);
    }
    return acc
  }, '');
};

export const ABPokusKeyTracking = (pokusFlags) => {
  let pokusOmniture = '';
  if (Boolean(pokusFlags)) {
    pokusOmniture = setPokusFlag(pokusFlags);
  }
  return pokusOmniture;
};

export const resetEvar28 = () => {
  _cabsEVars = {
    ..._cabsEVars,
    [EVAR_28]: '',
  };
};

export const resetEvar40 = () => {
  _cabsEVars = {
    ..._cabsEVars,
    [EVAR_40]: '',
  };
};

export const trackHRPackageId = (packageId) => {
  _cabsEVars = {
    ..._cabsEVars,
    [cabsAbTrackingKey]: `${_cabsEVars[cabsAbTrackingKey]}${Boolean(packageId) ? ` | Mob_Funnel_HR_Package_${packageId}` : ''
      }`,
  };
};

const calcAvgSedanPrice = (totalPrice, noOfSedans) => {
  return (totalPrice / noOfSedans / 10) % 10 > 5
    ? Math.ceil(totalPrice / noOfSedans / 100) * 100
    : Math.floor(totalPrice / noOfSedans / 100) * 100;
};

const makeOmnitureValues = (isNewListing, response) => {
  let cabList = [];
  let noOfCabs = 0;
  let noOfSedans = 0;
  let sedanSellingPrice = 0;
  let fuelAvailable = new Set();
  let SKUs = new Set();
  let vendors = new Set();
  if (isNewListing) {
    cabList = response?.response?.cab_list_v2;
    noOfCabs = cabList?.length;
    cabList?.map((cabItem) => {
      let fuelTypes = cabItem?.cab_info?.fuel_identifier?.toUpperCase().split('/');
      fuelTypes.forEach((fuel) => {
        fuelAvailable.add(fuel);
      });
      if (cabItem.cab_info.type === CabTypes.SEDAN) {
        sedanSellingPrice += cabItem?.price_info?.total_amount;
        noOfSedans += 1;
      }
      SKUs.add(cabItem.cab_info.type);
      vendors.add(cabItem?.cab_info?.tracking_details?.vendor_name);
    });
  } else {
    if (response?.response?.cab_list && response?.response?.rap_cab_list)
      cabList = response?.response?.cab_list?.concat(response?.response?.rap_cab_list);
    else if (response?.response?.cab_list) cabList = response?.response?.cab_list;
    else if (response?.response?.rap_cab_list) cabList = response?.response?.rap_cab_list;

    noOfCabs = cabList.length;
    cabList.map((cabItem) => {
      if (cabItem.fuel_type) {
        let fuelTypes = cabItem?.fuel_type.toUpperCase().split('/');
        fuelTypes.forEach((fuel) => {
          fuelAvailable.add(fuel);
        });
      }
      if (cabItem?.type === CabTypes.SEDAN) {
        sedanSellingPrice += cabItem?.cab_fare_detail?.total_amount;
        noOfSedans += 1;
      }
      SKUs.add(cabItem?.type);
      vendors.add(cabItem?.vendor_name);
    });
  }
  return {
    noOfCabs,
    sedanSellingPrice,
    noOfSedans,
    fuelAvailable,
    SKUs,
    vendors,
  }
}

export const trackListingLoad = (
  distance,
  durationFilter,
  isEPassRequired = false,
  showListingWidget = false,
  searchRequest = null,
  specificModelsList = [],
  packagesList = [],
  fuelTypePokusKey = null,
  responsePokusValues,
  cabPartnerTracker = null,
  selectedHRCabPackage = null,
  specificModelsStr = null,
  bookZeroSKUShown = false,
  response,
  isNewListing,
  beTrackingData = null,
  hourDifference = null,
) => {
  let duration =
    response?.overall_trip_duration_in_minutes > 0
      ? `${(response?.overall_trip_duration_in_minutes / 60).toFixed(2)}${hourDifference ? ` | ${hourDifference}` : ''}`
      : `0${hourDifference ? ` | ${hourDifference}` : ''}`;
  if (searchRequest?.return_date) {
    let days = searchRequest?.return_date.split('-')[0] - searchRequest?.departure_date.split('-')[0]
    duration = `${days} | ${duration}${hourDifference ? ` | ${hourDifference}` : ''}`;
  }

  const pokusOmniture = ABPokusKeyTracking(responsePokusValues);
  if (Boolean(pokusOmniture)) {
    _cabsEVars = {
      ..._cabsEVars,
      [cabsAbTrackingKey]: `${pokusOmniture}${Boolean(personalizedTripTracker) ? ` | ${personalizedTripTracker}` : ''} | ${beTrackingData ? beTrackingData?.backendPokus : ''}`,
    };
  }

  // Tracking Seleceted Package for HR in evar97
  if (searchRequest.trip_type === TripType.HR.value) {
    trackHRPackageId(selectedHRCabPackage);
  }

  if (showListingWidget) {
    _cabsEVars = { ..._cabsEVars, [cabsSource]: UNIVERSAL_SEARCH };
  }

  const getValuesForOmniture = makeOmnitureValues(isNewListing, response) //For old listing and new listing create omniture values depending upon Listing response


  let vendorsString = [...getValuesForOmniture.vendors].join('|');
  let SKUsString = [...getValuesForOmniture.SKUs].join('|');
  let fuelString = [...getValuesForOmniture.fuelAvailable].join('|');
  const avgSedanPrice = calcAvgSedanPrice(getValuesForOmniture.sedanSellingPrice, getValuesForOmniture.noOfSedans)

  _cabsEVars = {
    ..._cabsEVars,
    [omniture.VAR_DURATION]: duration,
    [EVAR_28]: `${cabPartnerTracker ? cabPartnerTracker + ` | ` : ''
      } ${getValuesForOmniture.noOfCabs} | ${bookZeroSKUShown ? 'BookZeroAvailable' : 'BookZeroNotAvailable'}`,
    ['m_v59']: `${SKUsString}||${fuelString}||${vendorsString}`,
    [omniture.VAR_PRICE]: `${avgSedanPrice ? avgSedanPrice : ''}`
  };

  if (searchRequest) {
    if (searchRequest.trip_type === TripType.AT.value) {
      if (searchRequest.source_location.is_airport) {
        _cabsEVars = {
          ..._cabsEVars,
          [EVAR_82]: 'A2C',
        };
      } else {
        _cabsEVars = {
          ..._cabsEVars,
          [EVAR_82]: 'C2A',
        };
      }
    } else {
      if (specificModelsStr) {
        _cabsEVars = {
          ..._cabsEVars,
          [EVAR_82]: specificModelsStr,
        };
      } else {
        let SKUs = [...new Set(packagesList)];
        if (specificModelsList.length > 0) {
          SKUs.push('Specific');
        }
        _cabsEVars = {
          ..._cabsEVars,
          [EVAR_82]: SKUs.length ? SKUs.join('_') : 'Not_Intercity',
        };
      }
    }
  }

  trackListingEvent(_cabsEVars, true);
};

export const trackListingEvent = (data, isLoadEvent = false) => {
  const PagePrefix = getPagePrefix();
  _trackEvent(`${PagePrefix}:${PAGE_NAMES.LISTING_PAGE}`, data, isLoadEvent);
};

export const trackCabsCorporateEvent = (page, data, isLoadEvent = false) => {
  _trackEvent(page, data, isLoadEvent);
};

export const trackUniversalSearchEvent = (data, isLoadEvent = false) => {
  const PagePrefix = getPagePrefix();
  _trackEvent(`${PagePrefix}:${PAGE_NAMES.UNIVERSAL_SEARCH_OVERLAY_PAGE}`, data, isLoadEvent);
};

export const trackPokusEvent = (data) => {
  const trackingData = {
    [omniture.BRC_ABTrackingKey]: data,
  };
  return trackingData;
};

export const trackListingErrorEvent = (
  data,
  isUniversalWidgetVisible = false,
  isLoadEvent = false,
  errorCode = null,
  isIntracity = false,
) => {
  const PagePrefix = getPagePrefix();
  // Page Nomenclature Task - Listing Page is same for Not Available and Available Cabs, prop1 is differentaitor
  let omnitureCabListingErrorPageName = `${PagePrefix}:${PAGE_NAMES.LISTING_PAGE}`;
  if (_isObject(data)) {
    if (isUniversalWidgetVisible && isLoadEvent) {
      data = {
        ...data,
        [cabsSource]: UNIVERSAL_SEARCH,
      };
    }
    if (isLoadEvent && isIntracity) {
      data = {
        ...data,
        [omniture.VAR_TRIP_TYPE]: 'Local',
      };
    }
    let errCode = errorCode;
    if (!_isEmpty(_userDetails) && !UserSessionModule.isPersonalUser(_userDetails)) {
      errCode = `Mob_Cab_MyBiz_Listing_Load_Error_${errCode}`;
    }
    _trackEvent(
      omnitureCabListingErrorPageName,
      {
        ...data,
        [omniture.VAR_ERROR_KEY]: errCode || 'Mob_Cab_Listing_Load_Error',
        [PROP_1]: 'Not Available',
      },
      isLoadEvent,
    );
  } else {
    _trackEvent(
      omnitureCabListingErrorPageName,
      { [PROP_1]: 'Not Available', [omniture.PROP_54]: data },
      isLoadEvent,
    );
  }
};

export const trackBnplEventsFromListing = (data) => {
  if (Boolean(data[EVAR_46])) {
    _cabsEVars = {
      ..._cabsEVars,
      [EVAR_46]: data[EVAR_46],
    };
  }
  const PagePrefix = getPagePrefix();
  _trackEvent(`${PagePrefix}:${PAGE_NAMES.LISTING_PAGE}`, data);
};

export const trackRTdurationDaysHrs = (data) => {
  const departDateTime = fecha.parse(
    `${data?.departureDate} ${data?.pickup_time}`,
    'DD:MM:YYYY hh:mm',
  );
  const returnDateTime = fecha.parse(`${data?.returnDate} ${data?.drop_time}`, 'DD:MM:YYYY hh:mm');
  const departDate = fecha.parse(`${data?.departureDate}`, 'DD:MM:YYYY');
  const returnDate = fecha.parse(`${data?.returnDate}`, 'DD:MM:YYYY');
  const diffHrs = (returnDateTime - departDateTime) / 3600000;
  const numOfDays = (returnDate - departDate) / 86400000;
  trackListingEvent(`Mob_Cabs_RT_Listing_${diffHrs}_${numOfDays}`);
};

const _getSafetyShieldAndKitAvailability = (mySafetyAccessories) => {
  if (!mySafetyAccessories) return '';
  let kitAccessoriesString = '';
  mySafetyAccessories.forEach((accessory) => {
    if (accessory.icon === 'PROTECTIVE_SHIELD_BLACK') {
      kitAccessoriesString += ' | SafetyShield';
    }
    if (accessory.icon === 'PERSONALISED_KIT') {
      kitAccessoriesString += ' | SafetyKit';
    }
  });
  return kitAccessoriesString;
};

const createEvar40Info = (
  cab = null,
  isSpecificModel = fasle,
  fuel_type = null,
  supplier_name = null,
  cabRating = null,
  rydeUpsellData = null,
  nonRapCabData = null,
  mySafetyAccessories,
  bookZeroSKUShown = false,
  cancellationDuration = null,
  isZeroPayment = false,
  bookAtZeroPokusValue = null
) => {
  let evar40Data = '';

  if (Boolean(rydeUpsellData)) {
    evar40Data += 'NonRAP + RAP_';
  } else {
    if (cab.cab_partner === 'RAP') {
      evar40Data += 'RAP_';
    } else {
      evar40Data += 'NonRAP_';
    }
  }
  if (isSpecificModel) {
    evar40Data += cab?.vehicle_model;
  } else {
    evar40Data += cab?.type_label;
  }
  evar40Data += ' | ';
  if (Boolean(fuel_type)) {
    evar40Data += fuel_type;
  } else {
    evar40Data += null;
  }
  evar40Data += ' | ';
  if (Boolean(supplier_name)) {
    evar40Data += supplier_name;
  } else {
    evar40Data += null;
  }
  evar40Data += ' | ';
  if (Boolean(cabRating)) {
    evar40Data += cabRating;
  } else {
    evar40Data += 'NEW';
  }
  if (nonRapCabData) {
    evar40Data += ' | RydeUpsell';
  }
  if (cancellationDuration) {
    evar40Data += ` | ${cancellationDuration}`
  }
  evar40Data = `${evar40Data}${mySafetyAccessories}`;

  if (bookAtZeroPokusValue === true) {
    evar40Data = isZeroPayment ? evar40Data += ' | Book@0' : evar40Data += ' | Non Book@0'
  }
  return evar40Data;
};

const _createCabSpecificEvar = (
  cab,
  distance,
  durationFilter,
  rydeUpsellData = null,
  nonRapCabData = null,
  isZeroPayment = false,
  bookZeroSKUShown = false,
  cancellationDuration = null,
  bookAtZeroPokusValue = null
) => {
  const {
    vendor_display_name,
    type,
    my_safety = false,
    my_safety_accessories = null,
    is_other_cab = false,
    fuel_type = null,
    vendor_name = null,
    ratings_display = {},
    supplier_name = null,
  } = cab;
  let cabRating;
  if (ratings_display?.no_of_ratings > 0 && Boolean(ratings_display?.overall_rating)) {
    const rating = ratings_display.overall_rating;
    if (rating <= 2.5) {
      cabRating = '0-2.5';
    } else if (rating <= 3.5) {
      cabRating = '2.5-3.5';
    } else if (rating <= 4) {
      cabRating = '3.5-4';
    } else if (rating <= 4.5) {
      cabRating = '4-4.5';
    } else {
      cabRating = '4.5+';
    }
  }

  const isSpecificModel = cab.vehicle_model_type !== 'GENERIC';
  const mySafetyAccessories = _getSafetyShieldAndKitAvailability(my_safety_accessories);
  _cabsEVars = {
    ..._cabsEVars,
    [omniture.VAR_PRODUCT_ID]: createEvar40Info(
      cab,
      isSpecificModel,
      fuel_type,
      supplier_name,
      cabRating,
      rydeUpsellData,
      nonRapCabData,
      mySafetyAccessories,
      bookZeroSKUShown,
      cancellationDuration,
      isZeroPayment,
      bookAtZeroPokusValue
    ),
    [EVAR_82]: cab?.type,
  };
};

export const trackRatingAndReviewLoad = () => {
  const PagePrefix = getPagePrefix();
  _trackEvent(`${PagePrefix}:${PAGE_NAMES.RATINGS_REVIEWS_PAGE}`, trackingData, true);
};

export const trackFlightAddOnEvent = (data, isLoadEvent = false) => {
  _trackEvent('funnel:domestic cabs:listing', data, isLoadEvent);
};

export const trackFlightAddOnErrorEvent = (data, isLoadEvent = false) => {
  _trackEvent('funnel:domestic cabs: error : No cabs available : Short Funnel', data, isLoadEvent);
};

export const trackRatingAndReviewEvent = (data, isLoadEvent = false) => {
  const PagePrefix = getPagePrefix();
  _trackEvent(`${PagePrefix}:${PAGE_NAMES.RATINGS_REVIEWS_PAGE}`, data, isLoadEvent);
};

export const trackFlightAddOnInstantToRegular = (data) => {
  const trackingData = {
    [omniture.VAR_REFERRER]: data,
  };
  trackFlightAddOnEvent(trackingData);
};

export const trackFlightAddOnAbEvent = (abExperimentName) => {
  trackFlightAddOnEvent({
    [cabsAbTrackingKey]: abExperimentName,
  });
};

export const trackFlightAddOnLoad = (cab, distance, durationFilter) => {
  _createCabSpecificEvar(cab, distance, durationFilter);
  trackFlightAddOnEvent(_cabsEVars, true);
};

export const trackErrorFlightAddOnLoad = (data, isInstantCab, isLoadEvent = true) => {
  const trackingData = {
    ...data,
    [omniture.BRC_ABTrackingKey]: isInstantCab ? 'AT_Instant_Cabs' : 'AT_Short_Funnel',
  };
  _trackEvent(
    'funnel:domestic cabs: error : No cabs available : Short Funnel',
    trackingData,
    isLoadEvent,
  );
};

const getFlagValue = (responsePokusValues, key) => {
  const item = responsePokusValues?.find(item => item?.key === key);
  return item ? item?.flag : null;
}

export const trackReviewLoad = (
  cab,
  distance,
  durationFilter,
  isMyBizApprovalRequired = false,
  responsePokusValues = null,
  selectedHRCabPackage = null,
  tripType = TripType.OW.value,
  rydeUpsellData = null,
  nonRapCabData = null,
  isZeroPayment = false,
  bookZeroSKUShown = false,
  cancellationDuration = null,
  beTrackingData = null
) => {

  const bookAtZeroPokusValue = getFlagValue(responsePokusValues, "Cab_OS_Zero_Payment");
  _createCabSpecificEvar(
    cab,
    distance,
    durationFilter,
    rydeUpsellData,
    nonRapCabData,
    isZeroPayment,
    bookZeroSKUShown,
    cancellationDuration,
    bookAtZeroPokusValue,
  );
  let isMyBizUser = !_isEmpty(_userDetails) && !UserSessionModule.isPersonalUser(_userDetails);

  const pokusOmniture = ABPokusKeyTracking(responsePokusValues);
  if (Boolean(pokusOmniture)) {
    _cabsEVars = {
      ..._cabsEVars,
      [cabsAbTrackingKey]: `${pokusOmniture}${Boolean(personalizedTripTracker) ? ` | ${personalizedTripTracker}` : ''
        } | ${CabsABConfigHelper.showReviewAssistedWidget() ? 'Review_Assisted_Widget_True' : 'Review_Assisted_Widget_False'}  | ${beTrackingData ? beTrackingData?.backendPokus : ''}`,
    };
  }

  // Tracking Seleceted Package for HR in evar97
  if (tripType === TripType.HR.value) {
    trackHRPackageId(selectedHRCabPackage);
  }

  if (isMyBizUser) {
    let evar50;
    if (isMyBizApprovalRequired) {
      evar50 = 'Mob_Cab_MyBiz_Approval_Needed';
    } else {
      evar50 = 'Mob_Cab_MyBiz_Approval_Not Needed';
    }
    _cabsEVars = {
      ..._cabsEVars,
      [EVAR_50]: evar50,
    };
  }
  trackReviewEvent(_cabsEVars, true);
};

export const trackReviewEvent = (data, isLoadEvent = false) => {
  const PagePrefix = getPagePrefix();
  _trackEvent(`${PagePrefix}:${PAGE_NAMES.REVIEW_PAGE}`, data, isLoadEvent);
};

export const trackReviewAbEvent = (abExperimentName) => {
  trackReviewEvent({
    [cabsAbTrackingKey]: abExperimentName,
  });
};

export const trackListingAbEvent = (trackEvent, abExperimentName) => {
  trackEvent({
    [cabsAbTrackingKey]: abExperimentName,
  });
};

export const trackPaymentCheckout = async () => {
  await saveOmnitureEVars(_cabsEVars);
  const PagePrefix = getPagePrefix();
  _trackEvent(`${PagePrefix}:${PAGE_NAMES.CHECKOUT_PAGE}`, _cabsEVars);
};

export const trackThankYouLoad = async (
  bookingId = null,
  isRNWFunnel = false,
  responsePokusValues = null,
  selectedHRCabPackage = null,
  tripType = TripType.OW.value,
  payMode = null,
  totalPayableAmount = 0,
  paymentMode = null,
  beTrackingData = null
) => {
  const eVars = await getOmnitureEVars();
  let data = { ...eVars, isRNWFunnel };

  const pokusOmniture = ABPokusKeyTracking(responsePokusValues);
  if (Boolean(pokusOmniture)) {
    data = {
      ...data,
      [cabsAbTrackingKey]: `${pokusOmniture}${Boolean(personalizedTripTracker) ? ` | ${personalizedTripTracker}` : ''
        } | ${beTrackingData ? beTrackingData?.backendPokus : ''}`,
    };
  }
  if (Boolean(payMode)) {
    if (payMode === 'Pay Later') {
      data = {
        ...data,
        [EVAR_46]: `${Boolean(_cabsEVars[EVAR_46]) ? `${_cabsEVars[EVAR_46]} | BNPL_PayMode` : 'BNPL_PayMode'
          }`,
      };
    }
  }

  if (Boolean(_cabsEVars[EVAR_28])) {
    if (Boolean(paymentMode)) {
      _cabsEVars[EVAR_28] += `| ${paymentMode.toUpperCase()}`;
    }
  }

  if (eVars[cabsAbTrackingKey]?.includes('RAP_Category_Upsell')) {
    data = {
      ...data,
      [cabsAbTrackingKey]: `${Boolean(data[cabsAbTrackingKey])
        ? `${data[cabsAbTrackingKey]} | RAP_Category_Upsell`
        : 'RAP_Category_Upsell'
        }`,
    };
  }
  // Tracking Seleceted Package for HR in evar97
  if (tripType === TripType.HR.value) {
    data = {
      ...data,
      [cabsAbTrackingKey]: `${data[cabsAbTrackingKey]}${Boolean(selectedHRCabPackage) ? ` | Mob_Funnel_HR_Package_${selectedHRCabPackage}` : ''
        }`,
    };
  }
  if (tripType === TripType.AT.value) { //funnel rewamp tracking on basis of trip type
    const funnelRewampAt = {
      key: CabABKeys?.Funnel_Revamp_Listing_AT,
      flag: CabsABConfigHelper.showATListingV2(),
    }
    data = {
      ...data,
      [cabsAbTrackingKey]: `${data[cabsAbTrackingKey]} | ${funnelRewampAt.key}_${funnelRewampAt.flag}`
    }
  } else {
    const funnelRewamp = {
      key: CabABKeys?.Funnel_Revamp,
      flag: CabsABConfigHelper.showListingV2(),
    }
    data = {
      ...data,
      [cabsAbTrackingKey]: `${data[cabsAbTrackingKey]} | ${funnelRewamp.key}_${funnelRewamp.flag}`
    }
  }

  if (bookingId) {
    data = {
      ...data,
      purchaseID: bookingId,
      [omniture.VAR_PURCHASE_ID]: `${bookingId} | ${totalPayableAmount}`,
    };
  }
  const PagePrefix = getPagePrefix();
  _trackEvent(`${PagePrefix}:${PAGE_NAMES.THANKYOU_PAGE}`, data);
};

export const trackThankYouEvent = (data) => {
  const PagePrefix = getPagePrefix();
  _trackEvent(`${PagePrefix}:${PAGE_NAMES.THANKYOU_PAGE}`, data);
};

export const trackThankYouABEvent = (abExperimentName) => {
  trackThankYouEvent({
    [cabsAbTrackingKey]: abExperimentName,
  });
};

export const trackThankYouFailed = async (isRNWFunnel = true) => {
  const eVars = await getOmnitureEVars();
  const data = { ...eVars, [omniture.VAR_ERROR_KEY]: 'Mob_Cab_TY_Booking_Failed', isRNWFunnel };
  const PagePrefix = getPagePrefix();
  _trackEvent(`${PagePrefix}:${PAGE_NAMES.THANKYOU_PAGE}`, data);
};

// ADD-ON
export const trackAddOnDetailLoad = () => {
  trackAddOnDetailEvent(_cabsAddonEVars, true);
};

export const createFeedbackFunnelEvars = (data) => {
  try {
    const { originDestination, originDestinationAddress, departDate, tripType, cabType } = data;
    _cabsFeedbackEVars = {
      [omniture.VAR_SRC_DEST]: originDestination,
      [cabsSrcDest]: originDestinationAddress,
      [omniture.VAR_START_DATE]: departDate,
      [omniture.VAR_TRIP_TYPE]: tripType,
      [omniture.VAR_PRODUCT_ID]: cabType,
    };
  } catch (e) {
    // ignore
  }
};

export const createAddonFunnelEvars = (data) => {
  try {
    const {
      originDestination,
      originDestinationAddress,
      advancePurchaseDays,
      cabDetails,
      numberOfCabs,
    } = data;
    _cabsAddonEVars = {
      [omniture.VAR_SRC_DEST]: originDestination,
      [cabsSrcDest]: originDestinationAddress,
      [omniture.VAR_TRIP_DATE]: advancePurchaseDays,
      [omniture.VAR_PRICE]: cabDetails,
      [omniture.VAR_ADDON_NUMBER_OF_CABS]: numberOfCabs,
    };
  } catch (e) {
    // ignore
  }
};

export const trackFeedbackLoad = (pageName) => {
  trackFeedbackEvent(_cabsFeedbackEVars, pageName, true);
};

export const trackFeedbackErrorLoad = (pageName, errorCode) => {
  const error = {
    [omniture.VAR_ERROR_KEY]: errorCode,
  };
  _trackEvent(pageName, error);
};

export const trackFeedbackEvent = (data, pageName, isLoadEvent = false) => {
  _trackEvent(pageName, data, isLoadEvent);
};

export const trackAddOnDetailEvent = (data, isLoadEvent = false) => {
  const PagePrefix = getPagePrefix();
  _trackEvent(`${PagePrefix}:${PAGE_NAMES.ADDON_LANDING_PAGE}`, data, isLoadEvent);
};

export const trackAddOnReviewLoad = () => {
  trackAddOnReviewEvent(_cabsAddonEVars, true);
};

export const trackAddOnReviewEvent = (data, isLoadEvent = false) => {
  const PagePrefix = getPagePrefix();
  _trackEvent(`${PagePrefix}:${PAGE_NAMES.ADDON_REVIEW_PAGE}`, _cabsAddonEVars, isLoadEvent);
};

export const trackAddOnConfirmErrorEvent = (data, isLoadEvent = false) => {
  const PagePrefix = getPagePrefix();
  _trackEvent(`${PagePrefix}:${PAGE_NAMES.ADDON_REVIEW_PAGE}`, data, isLoadEvent);
};

export const trackAddOnThankYouLoad = (bookingId = null) => {
  const trackingData = {
    ..._cabsAddonEVars,
    purchaseID: bookingId,
    [omniture.VAR_PURCHASE_ID]: bookingId,
  };
  trackAddOnThankYouEvent(trackingData, true);
};

export const trackAddOnThankYouEvent = (data, isLoadEvent = false) => {
  const PagePrefix = getPagePrefix();
  _trackEvent(`${PagePrefix}:${PAGE_NAMES.ADDON_THANKYOU_PAGE}`, data, isLoadEvent);
};

export const trackError = (data) => {
  _trackEvent('funnel:domestic cabs:error', data);
};

export const eventHeaderForTripType = (tripType) => {
  let header = '';
  switch (tripType) {
    case TripType.OW.value:
      header = 'Mob_Cabs_OW_';
      break;
    case TripType.RT.value:
      header = 'Mob_Cabs_RT_';
      break;
    case TripType.AT.value:
      header = 'Mob_Cabs_AT_';
      break;
    case TripType.HR.value:
      header = 'Mob_Cabs_HR_';
      break;
    default:
      header = '';
  }
  return header;
};

export const CabsPageTrackerFunc = Object.freeze({
  [CabsScreen.LANDING]: trackLandingEvent,
  [CabsScreen.LISTING]: trackListingEvent,
  [CabsScreen.FLIGHTADDON]: trackListingEvent,
  [CabsScreen.REVIEW]: trackReviewEvent,
  [CabsScreen.ADD_ON_BOOKING_DETAIL]: trackAddOnDetailEvent,
});

export const trackProvisionalBookingLoad = (data, isLocalNotification) => {
  try {
    if (isLocalNotification) {
      const {
        flightSegment,
        config: { airport },
      } = JSON.parse(data);
      const { destinationCityName } = flightSegment[flightSegment.length - 1];
      _trackEvent(
        'funnel:domestic cabs:local notification provisional overlay page',
        {
          [omniture.VAR_SRC_DEST]: `${airport} | ${destinationCityName}`,
          [cabsSrcDest]: `${airport} | ${destinationCityName}`,
          [omniture.VAR_REFERRER]: 'Provisional_Local_Notification',
        },
        true,
      );
    } else {
      _trackEvent(
        'funnel:domestic cabs:provisional overlay page',
        {
          [omniture.VAR_SRC_DEST]: `${data.airport_name}`,
          [cabsSrcDest]: `${data.airport_name}`,
          [omniture.VAR_REFERRER]: 'Provisional_Whatsapp',
        },
        true,
      );
    }
  } catch (e) { }
};

export const trackProvisionalBookingExit = (data) => {
  if ('fromWhatsapp' in data) {
    _trackEvent(
      'funnel:domestic cabs:provisional overlay page',
      'Mob_Cab_Provisional_Back_Clicked',
    );
  } else {
    _trackEvent(
      'funnel:domestic cabs:local notification provisional overlay page',
      'Mob_Cab_Local_Notification_Back_Clicked',
    );
  }
};
