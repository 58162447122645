import {ScrollView, View, BackHandler, Platform} from 'react-native';
import React from 'react';
import {isEmpty} from 'lodash';
import styles ,{htmlstyles} from './AcmeBookingMainCss';
import Actions from '../../navigation/postSalesNavigation';
import BasePage from '../../Common/PostSalesBasePage';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import {
  ACME_BOOKING_DETAILS_URL,
  ACME_BOOKING_DETAILS_HOLIDAY_URL,
  getCommonHeaders
} from '../../utils/NetworkUtils';
import {isNetworkAvailable} from '@mmt/legacy-commons/Common/utils/AppUtils';
import SimpleHeader from '@mmt/legacy-commons/Common/Components/Header/SimpleHeader';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import NoInternetView from '@mmt/legacy-commons/Common/Components/Error/NoInternetView';
import ProgressView from '../../Common/ProgressView/ProgressView';
import TopStatusCard from './components/TopStatusCard';
import {colors} from '../../hotel/details/components/TopStatusCardColor';
import AcmeBookingDetailsConstant from '../utils/AcmeBookingDetailsConstant';
import AcmeBookingDetailCard from './components/AcmeBookingDetailCard';
import LocationCard from './components/LocationCard';
import InclusionExclusionCard from './components/InclusionExclusionCard';
import PrimaryContact from './components/PrimaryContact';
import AcmeCancellationPolicyCard from './components/AcmeCancellationPolicyCard';
import AcmePaymentCard from './components/AcmePaymentCard';
import PickUpCard from './components/PickUpCard';
import StickyHeader from '../../hotel/details/components/StickyHeader';
import {fillDateAndTime} from '@mmt/legacy-commons/Common/utils/DateUtils';
import RefundProgressCard from './components/RefundProgressCard';
import RefundBreakUpCard from './components/RefundBreakupCard';
import AcmeBookingTrackingHelper from '../utils/AcmeBookingTrackingHelper';
import {isNotNullAndEmpty} from '../../hotel/details/utils/HotelBookingDetailUtil';
import {AUTH_FAILED_MESSAGE, CHAT_WITH_MYRA_CARD, COMMON_CARD, LOBNAMES} from '../../PostSalesConstant';
import ChatWithMyraCard from '../../Common/ChatWithMyraCard';
import {backPressHandler, isLostBookingData, isMyraEnabled} from '../../utils/PostSaleUtil';
import ViewControllerModule from '@mmt/legacy-commons/Native/ViewControllerModule';
import AlertInfo from '../../Common/CommonImpInfo';
import MMTBlackCard from '../../Common/MMTBlackCard';
import ActivityVoucherCard from "./components/ActivityVoucherCard";
import {topStatusIconUpcoming, topStatusIconCompleted, topStatusIconCancelled, topStatusIconFailed, backIcon } from '../utils/AcmeImageConstant';
import LostIDBookingDetailPage from '../../Common/LostIdDetailPage';
import { themeColors } from '../../PostSalesStyles';
import addBookingTrackingHelper, { ADD_BOOKING_TRACKING_EVENTS } from '../../summary/components/AddBooking/tracking';
import AuthErrorView from '../../Common/AuthError/AuthErrorView';
import HTMLView from 'react-native-htmlview';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';

export const ACME_BOOKING_DETAILS_PAGE = 'AcmeBookingDetails';


let _refreshListener = null;
const registerPageRefreshListener = (listener) => {
  _refreshListener = listener;
};


export const publishPageRefreshListener = () => {
  if (_refreshListener !== null) {
    _refreshListener();
  }
};

export default class AcmeBookingDetail extends BasePage {
  static navigationOptions = {
    header: null
  };

  constructor(props) {
    super(props, ACME_BOOKING_DETAILS_PAGE);
    this.bookingId = (props.data && props.data.BOOKING_ID) ? props.data.BOOKING_ID : (props.bookingId ? props.bookingId :'');
    this.pageData = props.data && props.data.pageData ? JSON.parse(props.data.pageData) : undefined;
    this.isLostBooking = isLostBookingData(this.pageData);
    this.mmtAuth = '';
    this.topStatusCardColor = [];
    this.trackPageName = '';
    this.loggingTrackInfo = {};
    this.referenceId = props.data ? props.data.referenceId : '';
    this.state = {
      responseJson: null,
      viewState: this.isLostBooking ? ViewState.SHOW_DETAIL : ViewState.LOADING,
    };
  }

  componentWillMount() {
    registerPageRefreshListener(this.reloadPage);
  }

  render() {
    return (
      <View style={styles.flex1}>
        {/* {this.state.viewState === ViewState.ERROR && this.renderErrorView()}
        {this.state.viewState === ViewState.AUTH_ERROR && this.renderAuthErrorView()}
        {this.state.viewState === ViewState.SHOW_DETAIL && this.renderPage()}
        {this.state.viewState === ViewState.NO_INTERNET && this.renderNoNetworkView()}
        {this.state.viewState === ViewState.LOADING && this.renderProgressView()} */}
         {this.renderFallbackView()}
      </View>
    );
  }

  // componentDidMount() {
  //   if (!this.isLostBooking) {
  //     this.fetchAcmeDetails();
  //   }
  // }

  fetchAcmeDetails() {
    if (this.props.holidayBookingApi) {
      this.fetchJSONAsync(ACME_BOOKING_DETAILS_HOLIDAY_URL, "POST", {
        bookingId: this.bookingId,
        sitereferenceId: this.referenceId,
      })
    } else {
      this.fetchJSONAsync(ACME_BOOKING_DETAILS_URL + this.bookingId);
    }
  }

  async fetchJSONAsync(url, method = "GET", body = {}) {
    try {
      const hasNetwork = await isNetworkAvailable();
      if (!hasNetwork) {
        this.setState({viewState: ViewState.NO_INTERNET});
        return;
      }

      const userInfo = await HotelBookingModule.getRequestHeader(this.bookingId, 'ACME_BOOKING_DETAIL');
      this.mmtAuth = userInfo.mmtAuth;
      this.loggingTrackInfo = userInfo.loggingTrackingInfo;

      const response = await
        fetch(url, {
          method: method,
          headers: await getCommonHeaders(this.mmtAuth, this.loggingTrackInfo),
          ...(method === "POST" && { body: JSON.stringify(body) }),
        });

      if (response.ok) {
        const body = await
          response.json();
        this.trackPageName = 'Mob:customer support:ACME:Tripdetailspage';
        if ((this.props.holidayBookingApi && body.bookingState.state === "UPCOMING") || !this.props.holidayBookingApi) {
          // when coming from holidays page, load only if activity is upcoming
          this.setState({
            responseJson: body,
            viewState: ViewState.SHOW_DETAIL
          });
          this.trackLoadEvent();
        } else {
          this.setState({viewState: ViewState.ERROR});
        }
      } else {
        const errorBody = await response?.json();
        if (errorBody && errorBody?.message && errorBody.message.toUpperCase() === AUTH_FAILED_MESSAGE) {
          this.setState({ viewState: ViewState.AUTH_ERROR });
        } else {
          this.setState({ viewState: ViewState.ERROR });
        }
      }
    } catch (error) {
      this.setState({viewState: ViewState.ERROR});
      this.trackError(error);
    }
  }

  componentWillUnmount() {
    registerPageRefreshListener(null);
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data && this.props.data.refresh === true) {
      if (this.isLostBooking) {
        this.setState({
          refreshLostDetail: {},
        });
        return;
      }
    }
  }

  renderErrorView = () => (
    <View style={{flex: 1}}>
      <SimpleHeader
        title="Back"
        iconPress={this.goBack}
      />
      <ErrorView
        showRetry
        onRetry={() => {
          this.setState({viewState: ViewState.LOADING});
          this.fetchAcmeDetails();
        }}
        buttonGradient={themeColors.gradientBtnColor}
      />
    </View>
  );

  getFallbackErrorMessage = () => {
    return (
      <HTMLView stylesheet={htmlstyles()} value={`<p>Download the app and check the <b>My Trips</b> section to view your <b>Tours & Attractions bookings</b></p>`}/>
    )
  }

  renderFallbackView = () => {
    return (
      <View style={{ flex: 1 }}>
        <ErrorView
          showImage={true}
          showRetry={false}
          message="Uh Oh!"
          errorDesc={this.getFallbackErrorMessage()}
          errImg={topStatusIconFailed}
          errorIconStyle={styles.errorIconStyle}
        />
      </View>
    )
  };


  renderAuthErrorView = () => <AuthErrorView bookingId={this.bookingId} lobName={LOBNAMES.ACME} onGoBackPress={this.goBack} />
  renderPage() {
    let views = [];
    if (this.isLostBooking) {
      return (
        <LostIDBookingDetailPage
          bookingId={this.bookingId}
          pageData={this.pageData}
          getRefundCard={null}
          lobName={LOBNAMES.ACME}
          refreshLostDetail={this.state.refreshLostDetail}
        />
      );
    }
    views = this.getMainPageViews();
    if (isNotNullAndEmpty(this.state.responseJson.acmeBookingInfo.acmeBookingDetails)) {
      return (
        <ScrollView style={styles.GreyBg} stickyHeaderIndices={[0]}>
          <StickyHeader
            iconSrc={backIcon}
            headerColor={this.topStatusCardColor}
            title={this.state.responseJson.acmeBookingInfo.acmeBookingDetails[0].productname}
            titleStyle={[styles.WhiteText, styles.blackFont]}
            subTitle={this.getSubTitleForStickyHeader()}
            subTitleStyle={[styles.WhiteText, styles.font12]}
            onBackPress={this.goBack}
          />
          {views}
        </ScrollView>
      );
    } else {
      return null;
    }
  }

  renderNoNetworkView = () => (
    <View style={{flex: 1}}>
      <SimpleHeader
        title="Back"
        iconPress={this.goBack}
      />
      <NoInternetView
        onRetry={() => {
          this.setState({viewState: ViewState.LOADING});
          this.fetchAcmeDetails();
        }}
      />
    </View>);

  renderProgressView = () => (<ProgressView message="Loading Your Trip..." />);

  goBack = () => {
    backPressHandler(Actions)
  };

  reloadPage = () => {
    this.setState({viewState: ViewState.LOADING});
    this.fetchAcmeDetails();
  }

  getSubTitleForStickyHeader() {
    if (this.state.responseJson.acmeBookingInfo.unitType === 1 ||
      this.state.responseJson.acmeBookingInfo.acmeBookingDetails[0].startDate === this.state.responseJson.acmeBookingInfo.acmeBookingDetails[0].endDate) {
      return fillDateAndTime(this.state.responseJson.acmeBookingInfo.acmeBookingDetails[0].endDate, 'ddd, DD MMM YY');
    }
    return `${fillDateAndTime(this.state.responseJson.acmeBookingInfo.acmeBookingDetails[0].startDate, 'ddd, DD MMM YY')}-${fillDateAndTime(this.state.responseJson.acmeBookingInfo.acmeBookingDetails[0].startDate, 'ddd, DD MMM YY')}`;
  }

  getMainPageViews() {
    const views = [];
    const cards = this.state.responseJson.layoutDetail.cardList;
    cards.forEach((card) => {
      this.addCard(card, views);
    });
    return views;
  }

  trackLoadEvent() {
    AcmeBookingTrackingHelper.trackLoadEvent(this.trackPageName, this.state.responseJson);
    if (this.pageData?.isAddBookingFlow) {
      addBookingTrackingHelper.trackLoadEvent(
        ADD_BOOKING_TRACKING_EVENTS.Addbooking_detailspage_landed,
        this.pageData?.uuid,
        this.bookingId,
        this.trackPageName
      );
    }
  }

  trackError(errorReason) {
    AcmeBookingTrackingHelper.trackErrorEvent('Mob:customer support:ACME:TripdetailspageError', errorReason);
  }

  addCard(card, views) {
    switch (card.cardId) {
      case AcmeBookingDetailsConstant.TOP_STATUS_CARD_UPCOMING:
        this.topStatusCardColor = [colors.blueStartColor, colors.blueEndColor];
        views.push(<TopStatusCard
          key="TopStatusCard_Upcoming"
          pageName={this.trackPageName}
          response={this.state.responseJson}
          iconPath={topStatusIconUpcoming}
          headText="Booking Confirmed"
          color={[colors.blueStartColor, colors.blueEndColor]}
          subText="Your booking is confirmed. Show this screen to the operator for redemption."
          card={card}
          onBackPress={this.goBack}
          refId={this.referenceId}
        />);
        break;
      case AcmeBookingDetailsConstant.TOP_STATUS_CARD_COMPLETED:
        this.topStatusCardColor = [colors.blackStartColor, colors.blackEndColor];
        views.push(<TopStatusCard
          key="TopStatusCard_Completed"
          pageName={this.trackPageName}
          response={this.state.responseJson}
          iconPath={topStatusIconCompleted}
          headText={this.getHeaderTextForCompletedCard()}
          subText=" "
          color={[colors.blackStartColor, colors.blackEndColor]}
          card={card}
          onBackPress={this.goBack}
          refId={this.referenceId}
        />);
        break;
      case AcmeBookingDetailsConstant.TOP_STATUS_CARD_CANCELLED:
        this.topStatusCardColor = [colors.pinkStartColor, colors.pinkEndColor];
        views.push(<TopStatusCard
          key="TopStatusCard_Cancelled"
          pageName={this.trackPageName}
          response={this.state.responseJson}
          iconPath={topStatusIconCancelled}
          headText="Booking Cancelled"
          subText={this.getHeaderTextForCancelledCard()}
          color={[colors.pinkStartColor, colors.pinkEndColor]}
          card={card}
          onBackPress={this.goBack}
          refId={this.referenceId}
        />);
        break;
      case AcmeBookingDetailsConstant.TOP_STATUS_CARD_FAILED:
        this.topStatusCardColor = [colors.blackStartColor, colors.blackEndColor];
        views.push(<TopStatusCard
          key="TopStatusCard_Failed"
          pageName={this.trackPageName}
          response={this.state.responseJson}
          iconPath={topStatusIconFailed}
          headText="Booking couldn’t be completed.Refund in Progress."
          subText="This was due to a technical glitch at our end. In case money was deducted, the amount will be refunded in 7 business days.You may try again or reach out to us if you’re still unable to book after multiple tries."
          color={[colors.blackStartColor, colors.blackEndColor]}
          card={card}
          onBackPress={this.goBack}
          refId={this.referenceId}
        />);
        break;
      case AcmeBookingDetailsConstant.MAIN_CARD:
        views.push(<AcmeBookingDetailCard
          key="AcmeBookingDetailCard"
          response={this.state.responseJson}
          holidayBookingApi={this.props.holidayBookingApi}
        />);
        break;
      case AcmeBookingDetailsConstant.LOCATION_CARD:
        if (isNotNullAndEmpty(this.state.responseJson.acmeBookingInfo.acmeBookingDetails[0] && this.state.responseJson.acmeBookingInfo.acmeBookingDetails[0].addressDetails.address)) {
          views.push(<LocationCard
            key="LocationCard"
            response={this.state.responseJson}
            card={card}
            pageName={this.trackPageName}
          />);
        }
        break;
      case AcmeBookingDetailsConstant.INCLUSION_EXCLUSION_CARD:
        (isNotNullAndEmpty(this.state.responseJson.acmeBookingInfo.acmeBookingDetails[0].acmeAdditionalInfo.inclusion) ||
          isNotNullAndEmpty(this.state.responseJson.acmeBookingInfo.acmeBookingDetails[0].acmeAdditionalInfo.exclusion)) &&
        views.push(<InclusionExclusionCard
          key="InclusionExclusionCard"
          response={this.state.responseJson}
          card={card}
          pageName={this.trackPageName}
        />);
        break;
      case AcmeBookingDetailsConstant.PASSENGER_DETAILS_CARD:
        views.push(<PrimaryContact
          key="PrimaryContact"
          response={this.state.responseJson}
          card={card}
          pageName={this.trackPageName}
        />);
        break;
      case AcmeBookingDetailsConstant.CANCELLATION_POLICY_CARD:
        views.push(<AcmeCancellationPolicyCard
          key="AcmeCancellationPolicyCard"
          response={this.state.responseJson}
          card={card}
          pageName={this.trackPageName}
        />);
        break;
      case AcmeBookingDetailsConstant.PAYMENT_CARD:
        views.push(<AcmePaymentCard
          key="AcmePaymentCard"
          response={this.state.responseJson}
          card={card}
          pageName={this.trackPageName}
        />);
        break;
      case AcmeBookingDetailsConstant.PICKUP_CARD:
        !isEmpty(this.state.responseJson.acmeBookingInfo.acmeBookingDetails[0].acmeAdditionalInfo.pickupAddress) &&
        views.push(<PickUpCard
          key="PickUpCard"
          response={this.state.responseJson}
          card={card}
          pageName="test"
        />);
        break;
      case AcmeBookingDetailsConstant.REFUND_PROGRESS_CARD:
        views.push(<RefundProgressCard response={this.state.responseJson} />);
        break;
      case AcmeBookingDetailsConstant.REFUND_BREAKUP_CARD:
        views.push(<RefundBreakUpCard response={this.state.responseJson} />);
        break;
      case CHAT_WITH_MYRA_CARD:
        if (isMyraEnabled(this.state.responseJson.lobCode)) {
          views.push(<ChatWithMyraCard
            key={card.cardId}
            response={this.state.responseJson}
            card={card}
            pageName={this.trackPageName}
          />);
        }
        break;
      case AcmeBookingDetailsConstant.ALERT_INFO_CARD:
        views.push(<AlertInfo
          key="AlertInfo"
          infoList={this.state.responseJson.importantInformationList}
        />);
        break;
      case COMMON_CARD.LOYALTY_CARD:
        if (this.state.responseJson.loyaltyDetails) {
          views.push(<MMTBlackCard
            {...this.state.responseJson.loyaltyDetails}
            bookingId={this.state.responseJson.bookingId}
            uuid={this.state.responseJson.uuid}
            onClick={this.onMMTBlackCardClickEvent}
            trackingPageName={this.trackPageName}
          />);
        }
        break;
      case AcmeBookingDetailsConstant.ACTIVITY_VOUCHER_CARD:
        isNotNullAndEmpty(this.state.responseJson.acmeBookingInfo.acmeBookingDetails[0].scancode)
          && this.state.responseJson.acmeBookingInfo.showQrCode &&
         views.push(<ActivityVoucherCard
            response={this.state.responseJson}
            card={card}
            key={AcmeBookingDetailsConstant.ACTIVITY_VOUCHER_CARD}
          />);
        break;
      default:
        break;
    }
  }

  onMMTBlackCardClickEvent = (omnitureClickEvent) => {
    AcmeBookingTrackingHelper.trackClickEvent(this.trackPageName, omnitureClickEvent);
  }

  getHeaderTextForCompletedCard() {
    if (this.state.responseJson.acmeBookingInfo.acmeBookingDetails[0].unitType === 1) {
      return 'Your vouchers have expired and can not be redeemed anymore';
    }
    return 'Hope you had a good experience.';
  }

  getHeaderTextForCancelledCard() {
    const {subState} = this.state.responseJson.bookingState;
    if (subState === 'FULL_CANCELLATION_VENDOR') {
      return 'Sorry, unfortunately the vendor had to cancel the activity. Your refund is processed.';
    }
    if (subState === 'FULL_CANCELLATION_AGENT') {
      return 'Your booking has been cancelled by our customer care team.';
    }
    if (subState === 'FULL_CANCELLATION_CUSTOMER') {
      return 'You have cancelled this booking.';
    }
    return 'Booking Cancelled';
  }

}
