import {
  SellingTopCard,
  GenericCard,
  SellingTravelInsuranceCard,
  SectionCard,
  StickySectionCard,
  AirportDelightCard
} from '@core_app_flight/SellingPageCards';
import CabsSellingPage from '../Components/Cabs';
import {
  TravelInsuranceCard,
} from '@core_app_flight/DetailPageCards';

export const getSectionCardListMap = () => {
  const cardListMap = {
    SellingPageHeaderCard: SellingTopCard,
    SellingPageSectionCard: SectionCard,
    TravelInsuranceCard: SellingTravelInsuranceCard,
    InFlightCard: GenericCard,
    AirportDelightCard: AirportDelightCard,
    TravelInsurancePopup: TravelInsuranceCard,
    SellingPageStickySectionCard: StickySectionCard,
    CabsCard: CabsSellingPage,
  };
  return cardListMap;
};
