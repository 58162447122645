import { useCallback, useEffect, useReducer } from 'react';
import useApi from '../../../../Common/useApi';
import {
  INVALID_PAN,
  PAN_MAX_LENGTH,
  PAN_NUMBER_REGEX,
  VALID_PAN,
} from '../../../../PostSalesConstant';
import { getStaticData } from '../../../../staticData/staticData';
import {
  HOTEL_SUBMIT_PAN_URL,
  HOTEL_VALIDATE_PAN_URL,
} from '../../../../utils/NetworkUtils';
import { removeHTMLTags, unescapeHTML } from '../../../../utils/PostSaleUtil';
import HotelBookingTrackingHelper from '../../../HotelBookingTrackingHelper';
import { LANG_AWARE } from '../../../HotelConstants';
import PanCardReducer  from './PanCardReducer';
import PanConstant, { PAN_INITIAL_STATE } from './PanConstant';
import { PanCardController } from './types';

export function usePanCardController(bookingResponse: any, pageName: string): PanCardController {
  const { isPanSubmitted, panCardDetails, bookingID } = bookingResponse || {};
  const { heading, ctaList = []} = panCardDetails || {};
  const isUserPanSaved =  ctaList.some((cta: { actionFamily: string; }) => {
    return cta.actionFamily === PanConstant.CHANGE_PAN_DETAIL;
  });

  const staticData = getStaticData(LANG_AWARE.MODIFICATION);
  const [state, dispatch] = useReducer(PanCardReducer, PAN_INITIAL_STATE, (initialState) => ({
    ...initialState,
    showPanEditor: !isPanSubmitted,
    panSubmitMsg: heading ? removeHTMLTags(unescapeHTML(heading)) : null,
    isUserPanSaved: isUserPanSaved,
  }));

  const { isPanVerified, errorText, panNumber, isChecked, panHolderUnmaskedName } = state || {};

  const [isValidating, validateResponse, validateApi]: any = useApi(HOTEL_VALIDATE_PAN_URL);
  const [isSubmitting, submitResponse, submitApi]: any = useApi(HOTEL_SUBMIT_PAN_URL);

  const toggleCheckbox = useCallback(() => {
    dispatch({ type: PanConstant.TOGGLE_CHECKBOX });
  }, [dispatch]);

  useEffect(()=>{
    let loadEventName = '';
    if (isUserPanSaved) {
      loadEventName = 'savedpan';
    } else if (isPanSubmitted) {
      loadEventName = 'confirmedpan';
    } else {
      loadEventName = 'requestedpan';
    }
    HotelBookingTrackingHelper.trackShowEvent(pageName, loadEventName, bookingResponse);
  },[]);
  useEffect(() => {
    if (validateResponse) {
      if (validateResponse.docValidStatus === VALID_PAN) {
        dispatch({
          type: PanConstant.SET_PAN_VERIFIED,
          data: {
            panHolderName: validateResponse.maskedName,
            panHolderUnmaskedName: validateResponse.name,
          },
        });
      } else {
        let errorMsg;
        if (validateResponse.docValidStatus?.toUpperCase() === INVALID_PAN) {
          errorMsg = staticData?.invalidPanText;
        } else {
          errorMsg = staticData?.errorText?.soomethingWentWrongOnlyError;
        }
        dispatch({
          type: PanConstant.SET_PAN_VERIFY_ERROR,
          data: {
            errorText: errorMsg,
          },
        });
      }
    } else if (!isValidating && panNumber) {
      validateApi.httpPost({
        body: {
          bookingId: bookingID,
          panCardNo: panNumber,
        },
      });
    }
  }, [validateResponse, bookingID, panNumber, isPanVerified]);

  useEffect(() => {
    if (submitResponse && submitResponse?.success) {
      dispatch({
        type: PanConstant.PAN_SUBMIT_SUCCESS,
        data: {
          message: removeHTMLTags(unescapeHTML(submitResponse?.message)),
        },
      });
    } else if (submitResponse != null) {
      dispatch({
        type: PanConstant.PAN_SUBMIT_ERROR,
        data: {
          errorText:
            submitResponse?.errorMsg || staticData?.errorText?.soomethingWentWrongOnlyError,
        },
      });
    }
  }, [submitResponse]);

  const onPanTextChange = useCallback(
    (value) => {
      if (value.length === PAN_MAX_LENGTH) {
        if (value.match(PAN_NUMBER_REGEX)) {
          dispatch({ type: PanConstant.VALIDATING_PAN, data: { panNumber: value } });
        } else {
          dispatch({
            type: PanConstant.SET_PAN_VERIFY_ERROR,
            data: { errorText: staticData?.invalidPanText },
          });
        }
      } else if (isPanVerified || errorText) {
        dispatch({ type: PanConstant.RESET_PAN_VERIFIED });
        validateApi.clearResponse();
      }
    },
    [isPanVerified, errorText, dispatch],
  );

  const onSubmit = useCallback((useFromSavedPan) => {
    HotelBookingTrackingHelper.trackClickEvent(pageName, useFromSavedPan ? 'confirm|savedpan' : 'confirm|new', bookingResponse);

    dispatch({ type: PanConstant.PAN_SUBMIT_LOADING });
    const body = {
      bookingId: bookingID,
      panCardNo: panNumber,
      panHolderName: panHolderUnmaskedName,
      usePANForFutureBookings: isChecked,
      useFromSavedPan: useFromSavedPan,
    };
    submitApi.httpPost({
      body,
    });
  }, [bookingID, panNumber, panHolderUnmaskedName, isChecked, dispatch]);

  const changePanNumber = useCallback(() => {
    HotelBookingTrackingHelper.trackClickEvent(pageName, 'confirm|changedpan', bookingResponse);
    dispatch({ type: PanConstant.CHANGE_PAN });
  }, [dispatch]);

  return [
    { ...state, isSubmitting, isValidating },
    { toggleCheckbox, onPanTextChange, onSubmit, changePanNumber },
  ];
}
