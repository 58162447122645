import React from 'react';
import { View, Image, Text, TouchableOpacity } from 'react-native';
import styles from './cancellationInfoCss';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { CROSS_GREY_ICON_WITHBG } from '../../../../FlightImageConstants';
import LinearGradient from 'react-native-linear-gradient';
import { imageTransformY } from '../../../../../utils/PostSaleUtil';
import { getFont } from '../../../../../PostSalesStyles';
import { useTheme } from '../../../../../theme/theme.context';
import { VOID_DGCA_CONSTANTS } from '../../FlightBookingDetailsConstant';

const CancellationInfoOverlay = ({
  cancellationJson = {},
  cancelCardActionItemClick,
  dateChangeCardActionClick,
  sourceButtonData,
  isSourceDateChange,
  closeOverlay,
  isNameChangeCorrection,
}) => {
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  const onProccedTocancellation = () => {
    if (isSourceDateChange) {
      cancelCardActionItemClick(
        {
          actionCTA: 'ACT_CANCEL_FLT_FULL',
          actionId: 'CANCEL_FLT_FULL',
        },
        'Details',
        true,
        false
      )
    } else {
      cancelCardActionItemClick(
        sourceButtonData?.actionItem,
        sourceButtonData?.pageName,
        true,
        false,
      );
    }
    closeOverlay();
  };
  const onChangeDates = () => {
    dateChangeCardActionClick(
      sourceButtonData?.actionItem?.actionCTA,
      sourceButtonData?.pageName,
      true
    );
    closeOverlay();
  };
  const id = get(cancellationJson, 'id', '');
  const title = get(cancellationJson, 'title', '');
  const subTitle = get(cancellationJson, 'subtitle[0]', '');
  const topHeader = get(cancellationJson, 'topHeader', '');
  const iconUrl = get(cancellationJson, 'iconUrl', '');
  const actionLists = get(cancellationJson, 'actionLists', []);
  const proceedBtnCta =
    actionLists &&
    actionLists.length > 0 &&
    actionLists.find((item) => item.actionId === 'process_cancellation');
  const changeDatesBtnCta =
    actionLists &&
    actionLists.length > 0 &&
    actionLists.find((item) => item.actionId === VOID_DGCA_CONSTANTS.PROCESS_DATECHANGE);
   const footerText = isNameChangeCorrection ? get(cancellationJson, 'nameCorrectionVoidText', '') : get(cancellationJson, 'footerText', '');
  return (
    <View style={styles.OverlayContentWrapper}>
      <View style={AtomicCss.marginBottom0}>
        <TouchableOpacity style={styles.closeIconWrapper} onPress={closeOverlay}>
          <Image source={CROSS_GREY_ICON_WITHBG} style={styles.closeIconStyle} />
        </TouchableOpacity>
      </View>
      <View style={styles.containerStyle}>
        {!!iconUrl && (
          <Image
            style={[id === 'VOID' ? styles.voidIconStyle : styles.iconStyle, imageTransformY]}
            source={{ uri: iconUrl }}
          />
        )}
        {!!title && (
          <Text
            style={[
              AtomicCss.fontWeightBold,
              AtomicCss.blackText,
              fsStyle.font22,
              AtomicCss.marginTop12,
              AtomicCss.marginBottom10,
            ]}
          >
            {title}
          </Text>
        )}
        {!!subTitle && (
          <Text style={[styles.titleTxt, fonts.regularFontFamily, fsStyle.font16]}>
            {subTitle}
            {!!topHeader && (
              <Text style={[fonts.boldFontFamily, AtomicCss.greenText, fsStyle.font15]}>
                {' '}
                {topHeader}
              </Text>
            )}
          </Text>
        )}
        <View style={styles.overlayMiddleline} />
        {!!footerText && (
          <Text style={[styles.desc, fonts.regularFontFamily, fsStyle.font14]}>{footerText}</Text>
        )}
      </View>
      {!isEmpty(changeDatesBtnCta) && !isEmpty(changeDatesBtnCta.actionText) && (
        <TouchableOpacity onPress={onChangeDates}>
          <LinearGradient
            style={styles.changeDateBtn}
            start={{ x: 1.0, y: 0.0 }}
            end={{ x: 0.0, y: 1.0 }}
            colors={[theme.color.white, theme.color.white]}
          >
            <Text style={[AtomicCss.blueText, fsStyle.font17, fonts.boldFontFamily]}>
              {changeDatesBtnCta.actionText}
            </Text>
          </LinearGradient>
        </TouchableOpacity>
      )}
      {!isEmpty(proceedBtnCta) && !isEmpty(proceedBtnCta.actionText) && (
        <TouchableOpacity onPress={onProccedTocancellation}>
          <LinearGradient
            style={styles.submitBtn}
            start={{ x: 1.0, y: 0.0 }}
            end={{ x: 0.0, y: 1.0 }}
            colors={theme.color.primaryGradient}
          >
            <Text style={[AtomicCss.whiteText, fsStyle.font17, AtomicCss.fontWeightBold]}>
              {proceedBtnCta.actionText}
            </Text>
          </LinearGradient>
        </TouchableOpacity>
      )}
    </View>
  );
};

export default CancellationInfoOverlay;
