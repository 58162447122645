import React from 'react';
import {View, Text, TouchableOpacity, Alert, ScrollView} from 'react-native';
import styles from './TdrSelectReasonCss';
import AtomicCss from '../../commonStyles/AtomicCss';
import {isNetworkAvailable} from '@mmt/legacy-commons/Common/utils/AppUtils';
import { getCommonHeaders, RAIL_TDR_URL} from "../../../utils/NetworkUtils";
import {showShortToast} from '@mmt/legacy-commons/Common/Components/Toast';
import Actions from '../../../navigation/postSalesNavigation';
import ProgressView from '@mmt/legacy-commons/Common/Components/Progress/ProgressView';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import { getStaticData } from '../../../staticData/staticData';
import { isEmpty } from 'lodash';
import { getFont } from '../../../PostSalesStyles';
class TdrSelectReason extends React.Component {
  static navigationOptions = {
    header: null
  }
  constructor(props) {
    super(props);
    this.state = {
      radiobtn: '',
      loadingRules: this.props.loadingRules,
      tdrRules: this.props.tdrRules,
      errorText: this.props.tdrRules.errorText,
      selectedReason: {},
      trainCancelled: this.props.trainCancelled
    };
    this.radio = this.radio.bind(this);
  }
  radio(rule) {
    if(rule.isApplicable) {
      this.props.updateSelectedReason(rule, this.state.tdrRules.tdrAddress);
      this.setState({
        radiobtn: rule.ruleID,
        selectedReason: rule
      });
    }
  }


  renderGroup = rules => {
    const { fsStyle, ...fonts } = getFont(true);
    return rules.map(rule => (
      <TouchableOpacity style={AtomicCss.marginBottom18} onPress={() => {
        this.radio(rule)
      }}>
        <View style={[AtomicCss.flexRowWithTopMargin20]}>
                <View style={this.getStyle(rule)}>
                  <View style={[this.state.radiobtn === rule.ruleID ? styles.MimaRadioInside : '']}></View>
                </View>
                {!isEmpty(rule.typeOfTdrTranslated)  && <Text style={[AtomicCss.blackText, fsStyle.font14, fonts.regularFontFamily, AtomicCss.flex1, AtomicCss.tdrText]}>
                  {rule.typeOfTdrTranslated}
                </Text>}
        </View>
      </TouchableOpacity>
    ))
  };

  getStyle(rule){
    let styleList = [];
    styleList.push(styles.MimaRadio);

    if(!rule.isApplicable){
      styleList.push(styles.radioDisabled);
    }

    return styleList;
  }

  renderGroupedRules = groupedRules =>  Object.keys(groupedRules).map((key) => {
    const tdrRuleList = groupedRules && groupedRules[key] && groupedRules[key].tdrRuleList;
    const groupHeading = tdrRuleList && tdrRuleList[0] && tdrRuleList[0].categoryTranslated;
      return <View style={[styles.borderbtm, styles.refundCardOuter]}>
        {(!this.state.trainCancelled || (this.state.trainCancelled && !key.toLowerCase().includes("expire"))) &&
          <View>
            {!isEmpty(groupHeading) && <Text style={styles.subHeadText}>{groupHeading}</Text>}
            {this.renderGroup(groupedRules[key].tdrRuleList)}
          </View>
        }
      </View>
    }
  );

  renderRules(){
    const { fsStyle, ...fonts } = getFont(true);
    const staticData = getStaticData();
    const { tdrText: { tdrSelectReasonText } } = staticData;
    return (
        <ScrollView style={AtomicCss.greyBg}>
        <View style={[AtomicCss.greyBg, AtomicCss.flex1]}>
          <View style={[AtomicCss.whiteBg, AtomicCss.marginBottom10, AtomicCss.elevation1]}>
            <View style={styles.PartCancellationHead}>
              <Text style={[fonts.blackFontFamily, AtomicCss.blackText, fsStyle.font16,AtomicCss.tdrHeadingPos]}>
               {tdrSelectReasonText.headingText}
              </Text>
            </View>
            <View style={[styles.ActionBtn]}>
              {this.renderGroupedRules(this.state.tdrRules.groupedRules)}
            </View>
          </View>
        </View>
        </ScrollView>
    );
  }


  render() {
    const staticData = getStaticData();
    const { loadingText, goBackText} = staticData;
    if(!this.state.loadingRules){
       return this.renderRules();
    }

    else if(this.state.errorText){
      return (<ErrorView buttonLabel={goBackText} message={this.state.errorText} onRetry={Actions.pop()} />);
    }

    else if(this.state.loadingRules){
      return (<ProgressView messagemessage={loadingText ? loadingText : "" }  />);
    }


  }
}

export default TdrSelectReason;
