import React, {useState, useEffect, useMemo} from 'react';
import {View, Image, Text, TouchableOpacity, StyleSheet, Platform, NativeModules} from 'react-native';
import PropTypes from 'prop-types';
import { isEmpty, noop } from 'lodash';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';
import {fonts, colors} from '@mmt/legacy-commons/Styles/globalStyles';

import ProgressBar from './ProgressBar';

import {TickSuccessIcon, CrossDocumentIcon} from '../HolidayBookingConstants';

export default function DocumentUploadPreStatusCard(props) {
  const [highLightRow, setHighLightRow] = useState('');
  let FileUploaderComp = null;
  if(Platform.OS === 'web'){
    const {FileUploaderModule:{FileUploader}} = NativeModules;
    FileUploaderComp = FileUploader;
  };

  const {
    docName,
    icon,
    docFormat,
    buttons,
    onUploadButtonPress,
    traveller,
    travellerId,
    documentType,
    uploadDocumentType,
    onRetryUpload,
    fileName,
    uploadStatus,
    error,
    docType,
    onCancelUpload,
    onDocumentPreview,
    attachmentsMap,
    setAttachmentsMap
  } = props;
  const attachmentLst= Array.from(attachmentsMap);
  const highLightStyle = highLightRow === 'active' ? 'highLight' : '';
  const suffixStyle = uploadStatus;

  const handleOnTap = () => {
    setHighLightRow('active');
    setTimeout(() => {
      setHighLightRow('');
    }, 200);
    onDocumentPreview({traveller, documentType, uploadDocumentType, travellerId, docType, fileName});
  };

  const handleRetryOrCancelUpload = (buttonText) => {
    switch (buttonText) {
      case 'RETRY':
        onRetryUpload({traveller, documentType, uploadDocumentType, travellerId, docType});
        break;
      case 'CANCEL':
        onCancelUpload({traveller, documentType, uploadDocumentType, travellerId, docType});
        break;
      default:
        break;
    }
  };

  const onFileChanged = (fileList) => {
    props.onFileSelectedFromWeb(fileList , {
      traveller,
      documentType,
      uploadDocumentType,
      travellerId,
      docType
    });
  }

  return (
    <TouchableOpacity
      style={[
        styles.whiteCard,
        styles.innerSection,
        AtomicCss.marginBottom12,
        styles[highLightStyle]
      ]}
      activeOpacity={0.7}
      onPress={handleOnTap}
    >
      {uploadStatus === 'Error' && (
        <React.Fragment>
          <View style={[AtomicCss.flexRow, AtomicCss.marginBottom12, AtomicCss.alignCenter]}>
            <Image style={styles.iconStyle} source={icon} />
            <Text style={[AtomicCss.blackFont, AtomicCss.font14, AtomicCss.defaultText]}>
              {docName}
            </Text>
          </View>
          <View style={[styles.progressWrapper, AtomicCss.spaceBetween]}>
            <View style={[{width: '70%'}]}>
              <Text
                style={[styles.docNameTxt, styles[`docNameTxt${suffixStyle}`]]}
                numberOfLines={1}
              >
                {fileName}
              </Text>
            </View>
          </View>
          {error && error.text && (
            <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween, styles.statusWrapper]}>
              <View>
                <Text style={styles.failedText}>{error.text}</Text>
              </View>
              <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween]}>
                {error.buttons && error.buttons.length > 1 && (
                  <TouchableOpacity
                    activeOpacity={0.8}
                    hitSlop={{
                      top: 20,
                      bottom: 20,
                      left: 20,
                      right: 20
                    }}
                    onPress={() => handleRetryOrCancelUpload(error.buttons[1].text)}
                  >
                    <View style={AtomicCss.flexRow}>
                      <Text style={styles.linkTxt}>{error.buttons[1].text}</Text>
                    </View>
                  </TouchableOpacity>
                )}
                {error.buttons && error.buttons.length > 0 && (
                  <TouchableOpacity
                    activeOpacity={0.8}
                    hitSlop={{
                      top: 20,
                      bottom: 20,
                      left: 20,
                      right: 20
                    }}
                    onPress={() => handleRetryOrCancelUpload(error.buttons[0].text)}
                  >
                    <View style={AtomicCss.flexRow}>
                      <Text style={styles.linkTxt}>{error.buttons[0].text}</Text>
                    </View>
                  </TouchableOpacity>
                )}
              </View>
            </View>
          )}
        </React.Fragment>
      )}
      {uploadStatus === 'Uploading' && (
        <React.Fragment>
          <View style={[AtomicCss.flexRow, AtomicCss.marginBottom12, AtomicCss.alignCenter]}>
            <Image style={styles.iconStyle} source={icon} />
            <Text style={[AtomicCss.blackFont, AtomicCss.font14, AtomicCss.defaultText]}>
              {docName}
            </Text>
          </View>
          <View style={[styles.progressWrapper, AtomicCss.spaceBetween]}>
            <View style={[{width: '70%'}]}>
              <Text
                style={[styles.docNameTxt, styles[`docNameTxt${suffixStyle}`]]}
                numberOfLines={1}
              >
                {fileName}
              </Text>
            </View>
            <TouchableOpacity
              activeOpacity={0.8}
              hitSlop={{
                top: 20,
                bottom: 20,
                left: 20,
                right: 20
              }}
              onPress={() =>
                onCancelUpload({traveller, documentType, uploadDocumentType, travellerId, docType})
              }
            >
              <View style={styles.crossImgWrapper}>
                <Image style={styles.crossIconStyle} source={CrossDocumentIcon} />
              </View>
            </TouchableOpacity>
          </View>
          <View style={[styles.progressWrapper]}>
            <ProgressBar toValue={102} duration={1200} />
          </View>
        </React.Fragment>
      )}
      {uploadStatus === 'Submitted' && (
        <React.Fragment>
          <View style={[AtomicCss.flexRow, AtomicCss.marginBottom12, AtomicCss.alignCenter]}>
            <Image style={styles.iconStyle} source={icon} />
            <Text style={[AtomicCss.blackFont, AtomicCss.font14, AtomicCss.defaultText]}>
              {docName}
            </Text>
          </View>
          <View style={[styles.progressWrapper, AtomicCss.spaceBetween]}>
            <View style={[AtomicCss.flexRow, {width: '70%'}]}>
              <Image style={[styles.tickIcon2]} source={TickSuccessIcon} />
              <Text
                style={[styles.docNameTxt, styles[`docNameTxt${suffixStyle}`]]}
                numberOfLines={1}
              >
                {fileName}
              </Text>
            </View>
            <TouchableOpacity
              activeOpacity={0.8}
              hitSlop={{
                top: 20,
                bottom: 20,
                left: 20,
                right: 20
              }}
              onPress={Platform.OS === 'web' ? noop() : () =>
                onUploadButtonPress({
                  traveller,
                  documentType,
                  uploadDocumentType,
                  travellerId,
                  docType
                })
              }
            >
              {Platform.OS === 'web' && !!FileUploaderComp &&
                <FileUploaderComp
                  onFileChanged={onFileChanged}
                  setAttachmentList={setAttachmentsMap}
                  attachmentLst={attachmentLst}
                  text={"REPLACE"}
                />
            }
            {Platform.OS !== 'web' && (
              <Text>
                <Text style={[AtomicCss.azure, AtomicCss.boldFont, AtomicCss.font12]}>REPLACE</Text>
              </Text>
            )}
            </TouchableOpacity>
          </View>
        </React.Fragment>
      )}
      {uploadStatus === 'New' && (
        <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween, AtomicCss.alignCenter]}>
          <View>
            <View style={[AtomicCss.flexRow]}>
              <Image style={styles.iconStyle} source={icon} />
              <Text style={[AtomicCss.blackFont, AtomicCss.font14, AtomicCss.defaultText]}>
                {docName}
              </Text>
            </View>
            <Text style={styles.moreInfoTxt}>{docFormat}</Text>
          </View>
          {buttons && buttons.length && (
            <TouchableOpacity
              activeOpacity={0.8}
              hitSlop={{
                top: 20,
                bottom: 20,
                left: 20,
                right: 20
              }}
              onPress={Platform.OS === 'web' ? noop():
                ()=> onUploadButtonPress({
                  traveller,
                  documentType,
                  uploadDocumentType,
                  travellerId,
                  docType
                })
              }
            >
            {Platform.OS === 'web' && !!FileUploaderComp &&
                <FileUploaderComp
                  onFileChanged={onFileChanged}
                  setAttachmentList={setAttachmentsMap}
                  attachmentLst={attachmentLst}
                  text={buttons[0].text}
                />
            }
            {Platform.OS !== 'web' && (
              <Text>
                <Text style={[AtomicCss.azure, AtomicCss.boldFont, AtomicCss.font12]}>
                  {buttons[0].text}
                </Text>
              </Text>
            )}
            </TouchableOpacity>
          )}
        </View>
      )}
    </TouchableOpacity>
  );
}

DocumentUploadPreStatusCard.defaultProps = {
  error: null,
  buttons: []
};

DocumentUploadPreStatusCard.propTypes = {
  docName: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  fileName: PropTypes.string.isRequired,
  docFormat: PropTypes.string.isRequired,
  uploadStatus: PropTypes.string.isRequired,
  buttons: PropTypes.shape([
    PropTypes.shape({
      text: PropTypes.string.isRequired
    })
  ]),
  onUploadButtonPress: PropTypes.func.isRequired,
  traveller: PropTypes.number.isRequired,
  travellerId: PropTypes.string.isRequired,
  documentType: PropTypes.string.isRequired,
  uploadDocumentType: PropTypes.string.isRequired,
  onRetryUpload: PropTypes.func.isRequired,
  onCancelUpload: PropTypes.func.isRequired,
  error: PropTypes.shape({
    text: PropTypes.string.isRequired,
    subText: PropTypes.string.isRequired,
    buttons: PropTypes.shape([
      PropTypes.shape({
        text: PropTypes.string.isRequired
      })
    ]).isRequired
  }),
  docType: PropTypes.string.isRequired
};

const styles = StyleSheet.create({
  whiteCard: {
    ...getPlatformElevation(2),
    backgroundColor: '#fff',
    marginBottom: 3,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: '#ddd'
  },
  innerSection: {
    padding: 16
  },
  iconStyle: {
    width: 16,
    height: 20,
    marginRight: 10
  },
  progressWrapper: {
    flexDirection: 'row',
    paddingLeft: 25,
    alignItems: 'center'
  },
  docNameTxt: {
    fontSize: 14,
    fontFamily: fonts.black,
    color: colors.black
  },
  docNameTxtSubmitted: {
    color: colors.azure
  },
  progressSection: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  crossImgWrapper: {
    width: 24,
    height: 24,
    alignItems: 'center',
    justifyContent: 'center'
  },
  statusWrapper: {
    paddingLeft: 25
  },
  crossIconStyle: {
    width: 24,
    height: 24
  },
  successText: {
    color: '#1a7971',
    fontFamily: fonts.regular,
    fontSize: 12
  },
  failedText: {
    color: '#e53442',
    fontFamily: fonts.regular,
    fontSize: 12
  },
  tickIcon: {
    width: 14,
    height: 14,
    marginLeft: 5
  },
  tickIcon2: {
    width: 14,
    height: 14,
    marginRight: 5,
    marginTop: 2.5
  },
  delIcon: {
    width: 16,
    height: 18,
    marginRight: 3
  },
  linkTxt: {
    color: colors.azure,
    fontSize: 12,
    fontFamily: fonts.bold,
    marginLeft: 20
  },
  moreInfoTxt: {
    color: '#747474',
    fontSize: 12,
    fontFamily: fonts.regular,
    marginTop: 5
  },
  preUploadWrapper: {
    flexDirection: 'row'
  },
  moreInfoTxt: {
    color: '#747474',
    fontSize: 12,
    fontFamily: fonts.regular,
    marginLeft: 25
  },
  highLight: {
    backgroundColor: '#eaf5ff',
    borderColor: '#008cff',
    borderRadius: 4
  }
});
