import { FLIGHT_BOOKING_ROUTE_KEYS } from './flightBookingRouteKeys';

export const FLIGHT_BOOKING_ROUTE_KEYS_ARRAY = [
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.FLIGHT_BOOKING_DETAIL,
    component: () => require('./modules/details').default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.FLIGHT_BOOKING_DETAILED_INFO,
    component: () => require('./modules/v2/DetailedInfo').default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.FLIGHT_SELLING,
    component: () => require('./modules/v2/SellingPage').default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.FLIGHT_CANCELLATION_THANKYOU,
    component: () =>
      require('./modules/Cancellation/pages/Thankyou')
        .default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.NEED_HELP_WEBVIEW,
    component: () =>
      require('../Common/NeedHelpWebView/NeedHelpWebViewWrapper').default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.OPEN_ANCILLARY,
    component: () =>
      require('./modules/AncillaryModule').default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.ANCILLARY_SIMPLE_THANK_YOU,
    component: () =>
      require('./modules/Ancillary/pages/ThankYouPage').default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.TRAVEL_INSURANCE_THANK_YOU,
    component: () =>
      require('./modules/TravelInsurance/pages/ThankyouPage').default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.PART_PAYMENT_THANK_YOU,
    component: () =>
      require('./modules/PartPaymentTYPage').default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.FLIGHT_THANK_YOU_PAGE,
    component: () =>
      require('./modules/ThankYouPage').default,
  },
  
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.CANCEL_ANYWAY_SUCCESS,
    component: () =>
      require('./cancellation/CancelAnyWayRequest/CancelAnywaySuccess')
        .default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.FLIGHT_CANCELLATION_REFUND_MODE,
    component: () =>
      require('./modules/Cancellation/pages/SelectRefundMode').default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.STATE_NAME_LIST,
    component: () =>
      require('./modules/Ancillary/pages/WebCheckin/components/StateNameList').default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.DATE_CHANGE_SUBMIT_REQUEST,
    component: () =>
      require('./modules/DateChange/components/DateChangeRequest').default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.WEB_CHECKIN_COUNTRY_CODE_LIST,
    component: () =>
      require('./modules/Ancillary/pages/WebCheckin/components/CountryCodeList').default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.PARTIAL_PAX_SELECTION,
    component: () =>
      require('./modules/Cancellation/pages/SelectPassengers').default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.CANCEL_ANYWAY_REQUEST,
    component: () =>
      require('./cancellation/CancelAnyWayRequest').default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.FLIGHT_BOOKING_REFUND_BREAKUP,
    component: () =>
      require('./modules/Cancellation/pages/RefundBreakup').default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.CANCELLATION_ERROR_PAGE,
    component: () =>
      require('./cancellation/ErrorCases/CancellationErrorComponent')
        .default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.SPECIAL_CLAIM_SELECT_PASSENGER,
    component: () =>
      require('./cancellation/components/SpecialClaimSelectPassenger')
        .default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.SPECIAL_CLAIM_REVIEW_DETAILS,
    component: () =>
      require('./cancellation/components/SpecialClaimReviewDetails')
        .default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.SELECT_REFUND_PAYMENT_MODE,
    component: () =>
      require('./cancellation/components/SelectRefundPaymentMode')
        .default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.SPECIAL_CLAIM_REQUEST_THANKYOU,
    component: () =>
      require('./cancellation/components/SpecialClaimRequestThankyou')
        .default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.FALSE_CLAIM,
    component: () =>
      require('./cancellation/components/FalseClaim').default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.AUTO_REFUND_ROUTER,
    component: () =>
      require('./cancellation/CheckingAirlineOverlay').default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.DATE_CHANGE_SUCCESS,
    component: () =>
      require('./modules/DateChange/pages/DcReqSubmit').default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.FLIGHT_LISTING,
    component: () =>
      require('./modules/DateChange/Redux/Listing/FlightListingContainer')
        .default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.COMMON_SUBMIT_REQUEST,
    component: () => require('../Common/SubmitRequest').default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.COMMON_SUBMIT_REQUEST_THANKYOU,
    component: () => require('../Common/SubmitRequestThankYou').default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.COMMON_ERROR,
    component: () => require('../Common/CommonErrorPage').default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.FLIGHT_COMMON_ERROR,
    component: () => require('./ErrorPage').default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.PS_COMMON_ERROR,
    component: () => require('@mmt/legacy-commons/Common/Components/Error/ErrorView.js').default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.FLIGHT_RESCHEDULE_FLIGHTS_PAGE,
    component: () =>
      require('./modules/DateChange/Redux/PickDates/DateChangeContainer')
        .default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.FLIGHT_RESCHEDULE_CHARGE_PAGE,
    component: () =>
      require('./modules/DateChange/pages/RescheduleCharges/DateChangeRescheduleCharges')
        .default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.AIRLINE_CONTACT_INFO,
    component: () =>
      require('./modules/details/components/AirlineContactModal').default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.NAME_CHANGE_THANKYOU_NEW,
    component: () =>
      require('./modules/NameChange/pages/NameChangeThankYou').default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.CORP_FLIGHT_RESCHEDULE_REVIEW_PAGE,
    component: () =>
      require('./modules/DateChange/pages/CorpReviewPage').default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.FLIGHT_RESCHEDULE_REVIEW_PAGE,
    component: () => require('./modules/DateChange/pages/ReviewPage').default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.FLIGHT_PAYMENT_PRICE_BREAKUP,
    component: () =>
      require('./modules/details/components/PaymentPriceBreakup').default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.FLIGHT_CANCELLATION_PAGE,
    component: () =>
      require('./modules/Cancellation/pages/CancellationLanding').default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.SPECIAL_CLAIM_REASONS,
    component: () =>
      require('./cancellation/components/ClaimReason').default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.FLIGHT_MODIFICATION_OPTION,
    component: () =>
      require('./modules/details/components/ModificationOptionStandalone')
        .default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.FLIGHT_CANCELLATION_OPTION,
    component: () =>
      require('./modules/details/components/CancellationOptionStandalone')
        .default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.FLIGHT_CANCELLATION_POLICY,
    component: () =>
      require('./modules/details/components/CancellationPolicyPage').default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.FLIGHT_BOOKING_POLICY_ERROR,
    component: () =>
      require('./modules/details/components/PolicyError/PolicyError').default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.TRIP_PROTECTION_POLICY,
    component: () =>
      require('./modules/details/components/TripProtectionPolicyPage').default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.BAGGAGE_PROTECTION_POLICY,
    component: () =>
      require('./modules/details/components/BaggageProtectionPolicyPage')
        .default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.NAME_CHANGE_CLOSE_TO_DEPARTURE,
    component: () => require('./nameChange/CloseDeparture').default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.NAME_CHANGE_SELECT_PAX_NEW,
    component: () =>
      require('./modules/NameChange/pages/NameChangeSelectPax').default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.REFUND_DELAY_INFO,
    component: () => require('../Common/RefundDelayInfoPage').default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.FLIGHT_REFUND_BREAKUP,
    component: () => require('../Common/RefundBreakupPage').default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.CLAIM_ANCILLARY_REFUND,
    component: () =>
      require('./cancellation/components/ClaimAncillaryRefund').default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.FLIGHT_THANK_YOU,
    component: () => require('./ThankyouPage').default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.SPECIAL_CLAIM_NEW,
    component: () => require('./modules/SpecialClaimNew').default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.SPECIAL_CLAIM_V2,
    component: () => require('./modules/SpecialClaimV2').default,
    options: {
      backgroundColor: '#e7e7e7',
      gestureEnabled: false,
    },
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.SPECIAL_CLAIM_ERROR,
    component: () =>
      require('./modules/SpecialClaimNew/components/RefundRequestFailed')
        .default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.INSURANCE_THANK_YOU,
    component: () =>
      require('./modules/details/components/SecureYourTripCard/InsuranceThankyouCard')
        .default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.LAST_MINUTE_CANCELLATION,
    component: () =>
      require('./modules/Cancellation/pages/lastMinuteCancellation')
        .default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.ERROR_IN_CANCELLATION,
    component: () =>
      require('./modules/Cancellation/pages/lastMinuteCancellation/components/lastMinuteFailure')
        .default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.FLIGHT_REFUND_BREAKUP_V2,
    component: () => require('../Common/RefundBreakUpPageV2').default,
  },
  {
    key: FLIGHT_BOOKING_ROUTE_KEYS.REFUND_TRACKER_FLIGHTS,
    component: () => require('./modules/v2/RefundTracker').default,
  },
];
