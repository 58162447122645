import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import React from 'react';
import { Text, View } from 'react-native';
import { DateChangeConstants } from '../../../../constants';
import createStyle from './InfoListCardCss';

const InfoListCard = (props) => {
  const fonts = getFont(true);
  const {psTheme: theme} = useTheme();
  const styles = createStyle(theme,fonts)
  const items = [];
  props.importantInfos.forEach((info) => {
    items.push(
      <View style={styles.dottedListItem}>
        <View style={styles.dottedListDot} />
        <Text style={styles.dottedListText}>{info}</Text>
      </View>,
    );
  });
  return (
    <View>
      <Text style={styles.cardTitle}>{DateChangeConstants.IMP_INFO}</Text>
      {items}
    </View>
  );
};

export default InfoListCard;
