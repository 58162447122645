import { StyleSheet } from 'react-native';

export default (theme, fonts) => {
  return StyleSheet.create({
    highLitedText: {
      color: theme.color.blue15,
    },
    tickIconStyle: {
      width: 12,
      height: 9,
      marginRight: 5,
    },
    offerImgCard: {
      borderRadius: 8,
      borderWidth: 1,
      borderColor: theme.color.yellow8,
      paddingHorizontal: 8,
      paddingVertical: 12,
      marginTop: 10,
      backgroundColor: theme.color.white,
      height: 44,
      width: 320,
    },
    addOfferIcon: {
      width: 24,
      height: 24,
      backgroundColor: theme.color.yellow9,
      alignSelf: 'center',
      borderRadius: 20,
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: -7,
      position: 'absolute',
      zIndex: 11,
    },
    plusText: {
      color: theme.color.white,
      ...fonts.font20,
      fontFamily: fonts.regular,
    },
    transparentBtn: {
      borderRadius: 20,
      padding: 10,
      marginTop: 15,
      borderWidth: 1,
      borderColor: theme.color.skyBlue6,
      justifyContent: 'center',
      alignItems: 'center',
    },
  });
};
