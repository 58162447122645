import React, {Component} from 'react';
import {Platform, View, BackHandler} from 'react-native';
import {WebView} from 'react-native-webview';
import PropTypes from 'prop-types';
import Actions from '../../navigation/postSalesNavigation';
import ViewControllerModule from '@mmt/legacy-commons/Native/ViewControllerModule';
import CommonHeader from '@mmt/legacy-commons/Common/Components/Header/CommonHeader';

const WEB_VIEW_REF = 'WEB_VIEW_REF';

/**
 * generic class for opening web view page in react native
 * Parameters needed : url, header text,header icon
 */
class BusWriteReviewWrapper extends Component {
  static navigationOptions = {
    header: null
  };

  constructor(props) {
    super(props);
    this.handleBackButtonClick = this.onBackPress.bind(this);
    this._backPressHandler = this.props.onBackPress ? this.props.onBackPress : this.onBackPress;
    this.state = {
      canGoBack: false
    };
  }

  componentWillMount() {
    BackHandler.addEventListener('hardwareBackPress', this.handleBackButtonClick);
  }

  componentWillUnmount() {
    BackHandler.removeEventListener('hardwareBackPress', this.handleBackButtonClick);
  }

  render() {
    const uri = this.props.url;
    return (
      <View style={{flex: 1}}>
        <CommonHeader
          headerText={this.props.headerText}
          imgSrc={this.props.headerIcon}
          backPressHandler={this._backPressHandler}
        />
        <WebView
          ref={e => this._webView = e}
          source={{uri}}
          ignoreSslError={true}
          onNavigationStateChange={this.onNavigationStateChange}
          startInLoadingState
        />
      </View>
    );
  }

  onNavigationStateChange = (navState) => {
    this.setState({
      canGoBack: navState.canGoBack
    });
  };

  onBackPress = () => {
    if (this.state.canGoBack) {
      this._webView.goBack();
      return true;
    }
    const somethingPoped = Actions.pop();
    if (!somethingPoped) {
      if (Platform.OS === 'ios') {
        ViewControllerModule.popViewController(this.props.rootTag);
      } else {
        BackHandler.exitApp();
      }
    }
    return true;
  };
}

BusWriteReviewWrapper.propTypes = {
  url: PropTypes.string.isRequired,
  headerText: PropTypes.string.isRequired,
  headerIcon: PropTypes.number.isRequired,
  onBackPress: PropTypes.func
};

BusWriteReviewWrapper.defaultProps = {
  onBackPress: null
};

export default BusWriteReviewWrapper;
