import React, { useEffect } from 'react';
import CardWrapper from '../CardWrapper';
import { View, Text, Image, ScrollView, TouchableOpacity } from 'react-native';
import ButtonWpr from 'apps/post-sales/src/cab/components/ButtonWpr';
import CabBookingDetailsConstant, {
  CLICK_EVENT,
} from '@mmt/post-sales/src/cab/CabBookingDetailsConstant';
import { CarPhotosData } from '@mmt/post-sales/src/cab/types';
import isEmpty from 'lodash/isEmpty';
import createStyles from './styles';
import { getFont } from '@mmt/post-sales/src/PostSalesStyles';
import { useTheme } from '@mmt/post-sales/src/theme/theme.context';
import CabBookingTrackingHelper from '@mmt/post-sales/src/cab/CabBookingTrackingHelper';

interface CarPhotosCardProps {
  data: CarPhotosData;
  handleBottomSheetOverlay: (overlayType: string) => void;
  pageName: string;
}

const CarPhotosCard = ({ data, pageName, handleBottomSheetOverlay }: CarPhotosCardProps) => {
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);

  const { cardData, imagesList } = data || {};
  const { heading, subHeading, viewAllText, shouldShowImages } = cardData || {};

  useEffect(() => {
    !!shouldShowImages &&
      CabBookingTrackingHelper.trackClickEvent(pageName, 'Mob_Cabs_MIMA_AuditedCarPhotos_Shown');
  }, []);

  if (isEmpty(data)) {
    return null;
  }

  return (
    <View style={styles.contentWrapper}>
      <View style={styles.heading}>
        {!!heading && <Text style={styles.carPhotosHeading}>{heading}</Text>}
        {!!viewAllText && (
          <View>
            <ButtonWpr
              buttonType="text"
              buttonWidth="dynamic"
              buttonText={viewAllText}
              btnTxtStyle={styles.viewAllCTA}
              onButtonPress={() =>
                handleBottomSheetOverlay(CabBookingDetailsConstant.CarPhotosOverlay)
              }
            />
          </View>
        )}
      </View>
      {!!subHeading && (
        <View>
          <Text style={styles.carPhotosSubHeading}>{subHeading}</Text>
        </View>
      )}
      {shouldShowImages && imagesList?.length > 0 && (
        <ScrollView
          style={styles.containerStyles}
          horizontal={true}
          showsHorizontalScrollIndicator={false}
        >
          {imagesList?.map((image, idx) => {
            const lastIndex = imagesList.length - 1;
            const lastRow = idx === lastIndex ? styles.lastIndex : null;
            return (
              <TouchableOpacity
                onPress={() => {
                  CabBookingTrackingHelper.trackClickEvent(
                    pageName,
                    CLICK_EVENT.AUDITED_CAR_PHOTOS_CLICKED,
                  );
                  handleBottomSheetOverlay(CabBookingDetailsConstant.CarPhotosOverlay);
                }}
                style={[styles.contentBoxWrapper, lastRow]}
                key={idx}
              >
                <Image source={{ uri: image }} style={styles.imageStyle} />
              </TouchableOpacity>
            );
          })}
        </ScrollView>
      )}
    </View>
  );
};

export default CardWrapper(CarPhotosCard);
