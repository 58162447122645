import React from 'react';
import {View, Text, Image} from 'react-native';
import AtomicCss from '../../commonStyles/AtomicCss';
import styles from './CallHelpCss';
import CapsuleBtnFill from '../../../hotel/details/ActionButton/CapsuleBtnFill';
import {HELP_ICON_2} from '../../Utils/RailImageConstant';
import { openDialer } from '../../../Common/commonUtil';
import { getStaticData } from '../../../staticData/staticData';
import { getFont } from '../../../PostSalesStyles';
import WhiteCardWrapper from '../../../Common/WhiteCardWrapper';

class RailCallHelp extends React.Component {
    static navigationOptions = {
      header: null
    };

    render() {
      const { fsStyle, ...fonts } = getFont(true);
      const staticData = getStaticData();
      const { railCallHelpText, call139Text, callText } = staticData;
      return (
        <View style={[styles.CardContentFull, AtomicCss.marginBottom10]}>
            <View style={[styles.CardContent]}>
              <Image style={styles.CardIcon} source={HELP_ICON_2} />
              <Text style={[fonts.blackFontFamily, AtomicCss.blackText, fsStyle.font14]}>
              {railCallHelpText}
              </Text>
            </View>
            <View style={[styles.marL10per, AtomicCss.paddingRight16]}>
              <Text style={[fsStyle.font14, fonts.regularFontFamily,
                  AtomicCss.defaultTextColor, AtomicCss.lineHeight20, AtomicCss.marginBottom16]}
              >{callText}
              </Text>
              <CapsuleBtnFill
                action={{actionLabeltext: call139Text}}
                clickHandler={this.callHelpLineNumber}
              />
            </View>
        </View>
      );
    }
    callHelpLineNumber = () => {
      this.props.onClickEventTracker('call_IRCTC_CTA');
      openDialer('139');
    }
}

export default WhiteCardWrapper(RailCallHelp);
