import React from 'react';
import {Image, Text, View} from 'react-native';
import styles from '../GiftCardDetailsCss';
import GiftCardBookingDetailsConstant from '../GiftCardBookingDetailsConstant';
import { PAYMENT_CARD_ICON } from '../GiftCardImageConstants';

const PriceBreakUpCard = ({response}) => {
  
  const {pricingBreakup} = response;

  const getPricingBreakupView = (pricingBreakup) => {
    const { pricingBreakupMap, pricingBreakupDiscountObj } = pricingBreakup;
    const views = [];
    if (pricingBreakupMap) {
      for (let componentName in pricingBreakupMap) {
        views.push(
          <View style={[styles.Paymentrow, styles.borderbtm, styles.padVertical10]} key={componentName}>
            <Text style={[styles.PaymentLeft]}>
              {componentName}
            </Text>
            <Text style={styles.BlackText}>
              {`${pricingBreakupMap[componentName]} `}
            </Text>
          </View>
        )
      }
      if (pricingBreakupDiscountObj) {
        views.push(
          <View style={[styles.Paymentrow, styles.font12, styles.borderbtm, styles.padVertical10]} key={pricingBreakupDiscountObj.discountText}>
            <Text style={[styles.PaymentLeft, styles.greenText2]}>
              {pricingBreakupDiscountObj.discountText} ({pricingBreakupDiscountObj.discountCode})
            </Text>
            <Text style={[styles.greenText2]}>
              {`${pricingBreakupDiscountObj.discountValue} `}
            </Text>
          </View>
        )
      }
    }
    return views;
  }

  if (!pricingBreakup) {
    return null;
  }

  return (
    <View style={[styles.MamiCard, styles.mar10Bt]}>
      <View style={styles.CardContent}>
        <Image style={styles.CardIcon}
          source={PAYMENT_CARD_ICON}/>
        <View style={styles.ContentPortion}>
          <View style={[styles.Paymentrow, styles.mar10Bt]}>
            <View style={styles.PaymentLeft}>
              <Text style={[styles.BlackText, styles.blackFont]}>
                {GiftCardBookingDetailsConstant.PRICE_BREAKUP}
              </Text>
            </View>
          </View>
          {getPricingBreakupView(pricingBreakup)}
          {pricingBreakup && pricingBreakup.pricingTotalCost && pricingBreakup.pricingTotalCost.text && (
            <View style={[styles.Paymentrow, styles.pad10Top]}>
              <View style={styles.PaymentLeft}>
                <Text style={[styles.BlackText, styles.blackFont]}>
                  {pricingBreakup.pricingTotalCost.text}
                </Text>
              </View>
              <Text style={[styles.BlackText, styles.blackFont]}>
                {`${pricingBreakup.pricingTotalCost.totalCost} `}
              </Text>
            </View>
          )}
        </View>
      </View>
    </View>
  );
}

export default PriceBreakUpCard;
