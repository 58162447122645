import React from 'react';
import {View,Image, Text, StyleSheet} from 'react-native';
import { isEmpty } from 'lodash';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';


const CancellationTimeline = ({ timeLine}) =>{
    const { fsStyle, ...fonts } = getFont(true);
    const styles = getStyles(fonts);
    return(
        <>
            {!isEmpty(timeLine) && timeLine.map((item, i) => 
                <>
                <View style={timeLine.length-1 === i ? styles.cancellationList : [styles.cancellationList, styles.trackerLine]} key={i}>
                    <View style={styles.imgTrackerWrap}>
                        {item.icon ? 
                            <Image source={{uri: item.icon}} style={styles.imgGreenArrowCircle} />
                            :<View style={styles.circleList}></View>
                        }
                    </View>
                    <View style={[styles.cancellationListCont, i==0 ? AtomicCss.marginTop5:'']}>
                        <View style={styles.cancellationLeftCont}>
                            <View>
                                <Text style={[item.state !=='EXPIRED' ? styles.cancellationTimeLineTrue : styles.cancellationTimeLineFalse,item.color ? {color:item.color} : {}]}>{item.key}
                                </Text>
                            </View>
                        </View>
                        <View style={styles.cancellationRightCont}>
                            <Text style={[item.state !== 'EXPIRED' ? styles.cancellationTimeLineTrue : styles.cancellationTimeLineFalse, item.color ? {color:item.color}: {}]}>{item.value}</Text>
                            <Text style={[fsStyle.font12, fonts.regularFontFamily, AtomicCss.defaultText, AtomicCss.paddingTop3, item.color ? {color:item.color}: {}]}>{item.subText}</Text>
                        </View>
                    </View>
                </View>
                {item.banner && item.banner.desc && 
                    <View style={styles.bannerWrapper}>
                        <View style={[styles.banner,{backgroundColor : item.banner.color}]}>
                            <Text>{item.banner.desc} </Text>
                        </View>
                    </View>}
                </>
            )}
        </>        
    )
}

const getStyles = (fonts) => {
    return StyleSheet.create({
        cancellationList: {
            flexDirection: 'row',
            paddingBottom: 24
        },
        cancellationTimeline: {
            paddingHorizontal: 24
        },
        underline: {
            textDecorationLine: 'underline'
        },
        cancellationListHeader: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 15
        },
        cancellationListCont: {
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: -5
        },
        cancellationLeftCont: {
            flex: 1
        },
        cancellationRightCont: {
            flex: 1,
            alignItems: 'flex-end'
        },
        circleList: {
            width: 6,
            height: 6,
            borderRadius: 6,
            borderWidth: 1,
            backgroundColor: 'white',
            borderColor: colors.greyLight
        },
        imgTrackerWrap: {
            width: 20,
            height: 20,
            alignItems: 'center',
            position: 'relative',
            left: -10
        },
        imgGreenArrowCircle: {
            width: 20,
            height: 20
        },
        txtRight: {
            textAlign: 'right'
        },
        trackerLine:{
            borderLeftWidth: 1,
            borderColor: colors.greyLight
        },
        cancellationTimeLineTrue: {
            ...fonts.font14,
            fontFamily: fonts.bold,
            color: colors.defaultTextColor
        },
        cancellationTimeLineFalse: {
            ...fonts.font14,
            fontFamily: fonts.regular,
            color: colors.defaultTextColor,
            textDecorationLine: 'line-through'
        },
        bannerWrapper:{
            paddingBottom: 24, 
            borderLeftWidth: 1,
            borderColor: colors.greyLight, 
            marginTop: -8
        },
        banner:{
            marginLeft:-10,
            paddingHorizontal:12,
            paddingVertical:10,
            borderRadius: 4
        }
    });
}
export default CancellationTimeline;