const MetroConstant = {
  TOP_STATUS_CARD_DEFAULT_COMPLETED: 'TopStatusCard_DEFAULT_COMPLETED',
  TOP_STATUS_CARD_DEFAULT_CONFIRMED: 'TopStatusCard_DEFAULT_CONFIRMED',
  TOP_STATUS_CARD_DEFAULT_CANCELLED: 'TopStatusCard_DEFAULT_CANCELLED',
  QR_TICKET: 'QRTicketCard',
  TOP_STATUS_CARD_LOST_FAILED_BOOKING_CANCELLED: 'TopStatusCard_LostFailedBookingCancelled',
  TOP_STATUS_CARD_LOST_FAILED_BOOKING: 'TopStatusCard_LostFailedBooking',
  TOP_STATUS_CARD_AFTER_REFUND_DONE: 'TopStatusCard_AfterRefundDone',
  TOP_STATUS_CARD_FAILED_SUBTEXT: 'This was due to a technical glitch at our end. Sorry about that. In case money was deducted, the amount will be refunded in 7 business days.',
  CLICK_BOOKAGAIN: 'Mob_Metro_MIMA_Book_Again_Click',
  ACTION_CALL_HELPLINE: 'CallHelpline',
  ACTION_CAB_CALL_US: 'CabCallUs',
  ACTION_BOOK_AGAIN: 'BookAgain',
  BOOK_AGAIN_LINK : 'mmyt://react/?page=MetroLobEntrance',
  HELPLINE_NUMBER: '0124 4628747',
  REFUND_PROGRESS_CARD: 'RefundProgressCard',
  REFUND_BREAKUP_CARD: 'RefundBreakupCard',
  JOURNEY_TYPE: 'JOURNEY_TYPE',
  PASSENGER_TAB: 'PASSENGER_TAB',
  SHARE_TICKET: 'SHARE_TICKET',
  CLICK_CALL: 'Mob_Metro_MIMA_Call_Us_Click',
  RECTANGLE_FULL_BUTTON: 'RecFullBtn',
  ROUND_BUTTON: 'RbBtnWithIcon',
  PREVIEW: 'Preview',
  COMMIT: 'Commit',
  RECTANGLE_PRIMARY_FULL_WIDTH: 'CapsuleFullBtnFill',
  RECTANGLE_PRIMARY_HALF_WIDTH: 'CapsuleHalfBtnFill',
  RECTANGLE_PRIMARY_PART_WIDTH: 'CapsulePartBtnFill',
  RECTANGLE_SECONDARY_FULL_WIDTH: 'CapsuleFullBtn',
  RECTANGLE_SECONDARY_HALF_WIDTH: 'CapsuleHalfBtn',
  WHITE_BUTTON_FULL: 'CapsuleWhiteBtn',
  WHITE_BUTTON_HALF: 'CapsuleWhiteBtnHalf',
  IN_PROGRESS: 'IN PROGRESS',
  COMPLETE: 'COMPLETE',
  CHANGE_IN_PLANS_CARD: 'ChangeInPlansCard',
  FULL_CANCEL: 'CancelFullBooking',
  PARTIAL_CANCEL: 'CancelPartBooking',
  REFUND_TO_CUSTOMER: 'RefundToCustomer',
  FORWARD_JOURNEY: 'FORWARD',
  RETURN_JOURNEY: 'RETURN',
  REFUND_BREAKUP: {
    SHOW: 'View Breakup',
    HIDE: 'Hide Breakup'
  },
  ERRORTRACK: 'MOB: Metro: mima main page:Error',
  CALL_METRO_SUPPORT: 'Call Metro Support',
  CALL_METRO_SUPPORT_FAMILY: 'CallMetro',
  CALL_MMT_SUPPORT: 'Contact Us',
  CALL_MMT_SUPPORT_FAMILY: 'ContactUs',
  CHAT_WITH_US_ACTION_BTN_TEXT: 'Chat with Myra',
  CHAT_WITH_US_ACTION_BTN_FAMILY: 'ChatWithMyra',
  MMT_SUPPORT_CONTACT_NUMBER: '01244628747',
  HYD_METRO_SUPPORT_CONTACT: '04023332555',
  ALERT_INFO_CARD: 'alertinfocard',
  ADVISORY_NOTICE : 'AdvisoryNoticeCard'
};

export default MetroConstant;
