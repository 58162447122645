const bottleIcon = require('@mmt/legacy-assets/src/AmenitiesIcons/water_bottle.webp');
const placard = require('@mmt/legacy-assets/src/AmenitiesIcons/placard.webp');
const newspaper = require('@mmt/legacy-assets/src/AmenitiesIcons/newspaper.webp');
const driver = require('@mmt/legacy-assets/src/AmenitiesIcons/driver.webp');
const car_model = require('@mmt/legacy-assets/src/AmenitiesIcons/car_model.webp');

export const CABS_DATE_FMT = 'cabsServerDate';
export const CABS_TIME_FMT = 'cabsServerTime';
export const UNIVERSAL_SEARCH = 'Universal_Search';
export const LOCAL_NOTIFICATION_TIMESTAMP = 'cabs_local_notification_timestamp';
export const LISTING_EXISTING_USER = 'cabs_listing_existing_user';
export const CAB_YEAR_MAKER = 'CAB YEAR MAKE';
export const INCLUSION_EXCLUSION = 'FARE INCLUSIONS & EXCLUSIONS';

export const CABS_OMNI_LOB = 'domestic cabs';
export const CABS_OMNI_PAGE_NAME = 'landing:domestic cabs:landing-New';
export const IDENTIFIER = '_source_cab_thankyou';

export const CABS_LISTING_PAGE = 'list';
export const CABS_REVIEW_PAGE = 'review';

export const CABS_HOLD_FAILURE_MSG =
  'Selected cab is no longer available, please' + ' try booking another cab';
export const CABS_COUPON_FAILURE_MSG = 'Failed to apply the coupon.';
export const CABS_COUPON_REMOVAL_FAILURE_MSG =
  'Sorry! There was some problem in removing the coupon. Kindly move to the previous page to remove it.';

export const MyBizTripChangeErrCode = 'MY_BIZ_TRIP_TYPE_CHANGED_ERROR_CODE'; //Err Code to do search again for myBiz triptype change.

export const noRideNowAvailableErrMsg =
  'Ride now is not available for this address. Would you like to schedule a pickup instead?';

export const CabsMultiVendorTags = {
  RECOMMENDED: 'RECOMMENDED',
  MOST_BOOKED: 'MOST_BOOKED',
  CHEAPEST: 'CHEAPEST',
  HIGHEST_RATED: 'HIGHEST_RATED',
};

export const CabsBoardingType = {
  ON_SPOT: 'ON_SPOT',
  REGULAR: 'REGULAR',
};

export const CabsATScheduleType = {
  SCHEDULE: 'Schedule',
  RIDE_NOW: 'Ride Now',
};

export const LANDING_CARD_TYPES = {
  OFFERS: 'OFFERS',
  COVID_INFO_CARD: 'COVID_INFO_CARD',
  ADS: 'ADS',
  BUS_HIRE: 'BUS_HIRE',
  MYSAFETY: 'MYSAFETY',
  WHATS_NEW: 'WHATS_NEW',
  CATEGORIES: 'CATEGORIES',
  RECENT_SEARCH: 'RECENT_SEARCH',
};

export const CabsVendorId = {
  RYDE: 4,
};

export const CabsPartners = {
  RYDE: 'RYDE',
  DEFAULT: 'DEFAULT',
};

export const DeeplinkParentScreen = Object.freeze({
  FIS_LANDING_PAGE: 'flightStatusLanding',
  UNIVERSAL_SEARCH: 'universalSearch',
  FLIGHT_TRIP_DETAILS: 'flightTripDetails',
});

export const CabTypes = {
  HATCHBACK: 'HATCHBACK',
  SEDAN: 'SEDAN',
  SUV: 'SUV',
};

export const CabsHeaderSizes = {
  SIZE_SMALL: 56,
  SIZE_LARGE: 108,
  SIZE_NORMAL: 72,
};

export const RouterPathName = {
  CABS_LISTING: '/cabsListing',
  CABS_REVIEW: '/cabsTripReview',
};

export const CAB_PAGE_STATUS = {
  LOADING: 'loading',
  SUCCESS: 'success',
  DEFAULT: 'default',
  NO_RESULT: 'noResult',
  NO_INTERNET: 'noInternet',
  ERROR: 'error',
  PENDING: 'pending',
  FAILED: 'failed',
};

export const RatingBreakupLabels = {
  on_time: 'On Time Pick Up',
  cab_condition: 'Car Rating',
  driver_behavior: 'Driver Rating',
  cab_cleanliness: 'Car Cleanliness',
};

export const RatingBucket = {
  EXCELLENT: 'EXCELLENT',
  GOOD: 'GOOD',
  POOR: 'POOR',
};

export const InfoTypes = {
  INFO: 'INFO',
  WARNING: 'WARNING',
  DANFER: 'DANGER',
};

export const ErrorCodes = Object.freeze({
  BOOKING_NOT_FOUND_ERROR_CODE: 'BOOKING_NOT_FOUND',
  BOOKING_ALREADY_UPDATED_ERROR_CODE: 'BOOKING_ALREADY_UPDATED',
  LOCKDOWN_ERROR_CODE: 'LOCK_DOWN_ERROR_CODE',
  LOB_BLOCKED_ERROR_CODE: 'LOB_BLOCKED_ERROR_CODE',
  INTRACITY_UNAVAILABILITY_ERROR_CODE: 'INTRACITY_UNAVAILABILITY_ERROR_CODE',
  MYBIZ_NO_CABS_FOUND: 'MYBIZ_NO_CABS_FOUND', //Err Code to open form for offline flow
  RIDE_NOW_DEAD_END_CODE: 'RIDE_NOW_DEAD_END_CODE',
});

export const FLIGHT_DETAILS_RELOAD_SOURCE = {
  FROM_FLIGHT_DATE_CHANGE: '111',
  FLIGHT_CANCELLATION_DONE: '113',
  FROM_CAB_CROSS_SELL_PAGE: '114',
};

export const FareType = {
  DISCOUNT: 'DISCOUNT',
  NON_DISCOUNT: 'PAYABLE',
  TOTAL: 'TOTAL',
};

export const AirportPrimeFeaturesIcon = {
  bottle: bottleIcon,
  placard: placard,
  newspaper: newspaper,
  driverUniform: driver,
  carModel: car_model,
};

//  Below enums are defined for fetchOffers Section
// ALL implies Independent of the enum
export const offerType = {
  DISCOUNT: 'DISCOUNT',
  SUBSCRIPTION: 'SUBSCRIPTION',
};

export const offerBookingType = {
  INSTANT: 'INSTANT',
  NORMAL: 'NORMAL',
  ALL: 'ALL',
};

export const offerSource = {
  HOTELS_X_SELL: 'HOTELS_X_SELL',
  PROVISIONAL: 'PROVISIONAL',
  FUNNEL: 'FUNNEL',
  SEO: 'SEO',
  ALL: 'ALL',
};

export const offerPage = {
  LANDING: 'LANDING',
  LISTING: 'LISTING',
  REVIEW: 'REVIEW',
  X_SELL: 'X_SELL',
  SEO: 'SEO',
  ALL: 'ALL',
};

// Above enums are define for fetchOffersSection

export const ClickToActionTypes = {
  NO_ACTION: 'NO_ACTION',
};

export const myBizTravellerTypes = {
  SELF: 'SELF',
  GUEST: 'GUEST',
  COLLEAGUE: 'COLLEAGUE',
};

export const LandingCardsProfieTypes = {
  PERSONAL: 'PERSONAL',
  BUSINESS: 'BUSINESS',
  ALL: 'ALL',
};

export const npsRating = {
  header: 'Rate your Booking Experience?',
  subheader: 'Tell us how was your booking experience!',
  subHeading: {
    oneStar: "Oh! Too bad! Why don't you give us some feedback?",
    twoStar: "Oh! Too bad! Why don't you give us some feedback?",
    threeStar: "Umm! Looks like we are doing just okay! Why don't you give us your feedback?",
    fourStar: 'Yay! We are always trying to do our best!',
    fiveStar: 'Awesome! We will keep improving!',
  },
  successMsg: 'Thanks for your rating!',
  actionOn4: 'TY',
  abVarient: 'IARP',
  canEdit: true,
  lowRatingCta: [
    {
      type: 'LEFT',
      title: 'SKIP',
    },
    {
      type: 'RIGHT',
      title: 'FEEDBACK',
    },
  ],
  fourRatingCTA: [
    {
      type: 'LEFT',
      title: 'SKIP',
    },
    {
      type: 'RIGHT',
      title: 'SUBMIT',
    },
  ],
  fiveRatingCTA: [
    {
      type: 'LEFT',
      title: 'SKIP',
    },
    {
      type: 'RIGHT',
      title: 'SUBMIT',
    },
  ],
};

export const cabBlockLobs = {
  OS_HIDE: 'OUTSTATION_CABS|0',
  OS_BLOCK: 'OUTSTATION_CABS|1',
  AT_HIDE: 'AIRPORT_CABS|0',
  AT_BLOCK: 'AIRPORT_CABS|1',
  HR_HIDE: 'RENTAL_CABS|0',
  HR_BLOCK: 'RENTAL_CABS|1',
};

// CabABKeys are pokus key constants
export const CabABKeys = {
  Review_Page_Luggage_Info: 'Review_Page_Luggage_Info',
  Listing_Part_Payment: 'Listing_Part_Payment',
  Funnel_Revamp: 'Funnel_Revamp',
  Funnel_Revamp_Review: 'Funnel_Revamp_Review',
  OSZeroBooking: 'Cab_OS_Zero_Payment',
  Funnel_Revamp_Listing_AT: 'Funnel_Revamp_Listing_AT',
  ReviewAssistedWidget: 'Review_Assisted_Widget',
  UGC_Review: 'UGC_Review',
  UpsellFlow: 'cab_upsell',
  Airport_Landing_New: 'Airport_Landing_New',
  Landing_OS_TripTypeSelect: 'Landing_OS_TripTypeSelect',
  Map_Integration:'map_integration',
  Flight_Number_Cab_A2C: 'mmt.app.cab.default.review.default.Flight_Number_Cab_A2C_App'
};
export const cabsAbOmnitureKeys = {
  Listing_Part_Payment: 'Listing_Part_Payment',
  Funnel_Revamp: 'Funnel_Revamp',
  Funnel_Revamp_Review: 'Funnel_Revamp_Review',
  Cab_OS_Zero_Payment: 'Cab_OS_Zero_Payment',
  Funnel_Revamp_Listing_AT: 'Funnel_Revamp_Listing_AT',
  Review_Assisted_Widget: 'Review_Assisted_Widget',
  UGC_Review: 'UGC_Review',
  Airport_Landing_New: 'Airport_Landing_New',
  Landing_OS_TripTypeSelect: 'Landing_OS_TripTypeSelect',
  cab_upsell: 'cab_upsell',
  Map_Integration:'map_integration',
  ['mmt.app.cab.default.review.default.Flight_Number_Cab_A2C_App']: 'mmt.app.cab.default.review.default.Flight_Number_Cab_A2C_App'
};

export const CONFIG_KEYS = {
  LANDING_SNACKBAR_CONTEXT: 'Q0FCI0xBTkRJTkcjU05BQ0tCQVI=',
};

export const TRAVEL_TYPE_OFFERS_KEYS = {
  OUTSTATION: 'ocabs',
  AIRPORT: 'acabs',
  HOURLY_RENTALS: 'rcabs',
};

const PROP_54 = 'm_c54';
const EVAR_46 = 'm_v46';
export const CabsBnplOmnitureData = {
  CHECK_ELIGIBILITY_CLICK: {
    key: PROP_54,
    value: 'Mob_Cab_Listing_BNPL_Check_Eligibility_Clicked',
  },
  BNPL_SUCCESS: {
    key: PROP_54,
    value: 'Mob_Cab_Listing_BNPL_Check_Eligibility_Success',
  },
  BNPL_FAILURE: {
    key: PROP_54,
    value: 'Mob_Cab_Listing_BNPL_Check_Eligibility_Failed',
  },
  BNPL_TIMEOUT: {
    key: PROP_54,
    value: 'Mob_Cab_Listing_BNPL_Check_Eligibility_Timeout',
  },
  BNPL_SHOWN: {
    key: PROP_54,
    value: 'Mob_Cab_Listing_BNPL_Check_Eligibility',
  },
  REPAYMENT_DETAILS_CLICKED: {
    key: PROP_54,
    value: 'Mob_Cab_Listing_BNPL_Check_Eligibility_Success_Repayment Details_Clicked',
  },
  BNPL_FAILED_CLOSE: {
    key: PROP_54,
    value: 'Mob_Cab_Listing_BNPL_Check_Eligibility_Close',
  },
  BNPL_TIMEOUT_CLOSE: {
    key: PROP_54,
    value: 'Mob_Cab_Listing_BNPL_Check_Eligibility_Timeout_Close',
  },
  REPAYMENT_DETAILS_CLOSED: {
    key: PROP_54,
    value: 'Mob_Cab_Listing_BNPL_Repayment_Page_Close',
  },
  ADD_MOBILE_NUMBER_CLICK: {
    key: PROP_54,
    value: 'Mob_Cab_Listing_BNPL_Add_Mobile_Clicked',
  },
  LOGIN_CLICK: {
    key: PROP_54,
    value: 'Mob_Cab_Listing_BNPL_Login_Clicked',
  },
  BNPL_SUCCESS_CONVERSION: {
    key: EVAR_46,
    value: 'BNPL_Success',
  },
  BNPL_FAILURE_CONVERSION: {
    key: EVAR_46,
    value: 'BNPL_Failure',
  },
  BNPL_TIMEOUT_CONVERSION: {
    key: EVAR_46,
    value: 'BNPL_Timeout',
  },
};

export const CAB_LIST_CATEGORIES = {
  RAP_CABS: 'RAP_CABS',
  NON_RAP_CABS: 'NON_RAP_CABS',
};

export const CAB_TYPE_PREFERENCE = {
  RAP: 'RAP',
  NON_RAP: 'Non-Rap',
};

export const cabPages = {
  LANDING: 'Landing',
  LISTING: 'Listing',
  REVIEW: 'Review',
  THANKYOU: 'ThankYou',
};

export const TRAVELLER_DETAILS = {
  MYSELF: 'myself',
  SOMEONEELSE: 'someoneElse',
  GENREAL: 'general',
  BOOKING_FOR_HANDLER: 'bookingForChange',
  ALL_BOOKING: 'all',
  TRAVELLER_TITLE: `Traveller's information`,
}


export const DATE_TIME_PICKER = {
  PICK_UP_TITILE: 'PICK UP DATE & TIME',
  DROP_TITLE: 'DROP DATE & TIME',
  DATE: 'Date',
  TIME: 'Time',
  DROP: 'Drop',
  PICK_UP: 'Pick up'
}

export const PAY_NOW_TEXTS = {
  PAY_AFTER_TRIP: 'Pay to driver',
}

export const OS_SELECT_TRIP_TYPE_ERROR = 'Please select a trip type'

export const FAQ_COMPONENT_URL = 'https://www.makemytrip.com/cabs/faqs';

export const FAQ_HEADER = 'Frequently Asked Questions';

export const LOADER_TEXT = 'Finding the best ride with our Cab Partners';

export const CALLBACK_SUCCESS_TEXT = 'Your Callback Request was raised successfully.';

export const CALLBACK_FAILURE_TEXT = 'Something went wrong. Please submit again';

export const day = ['Today', 'Tomorrow'];

export const time = [
  '8am-9am',
  '9am-10am',
  '10am-11am',
  '11am-12pm',
  '12pm-1pm',
];

export const screenTitles = {
  landing: {
    title: 'Not finding what you’re looking for?',
    subtitle: 'Let our experts help you',
  },
  timeSlot: {
    title: 'When do you want us to call you?',
    subtitle: 'Select a time slot',
  },
  arrangeCall: {
    title: 'Not finding what you’re looking for?',
    subtitle: 'We need the following details so we can connect with you',
  },
};
export const FUNNEL_SOURCES_HIDE_BOTTOMSHEET = [
  'Upsell_Thank_you_Flight_Booker',
  'Upsell_Thank_you_Non_Flight_Booker',
  'MyTrips_Flights_Details_Page'
]
