import React, { useState } from 'react';
import { View, Text, Image, ScrollView } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import isEmpty from 'lodash/isEmpty';
import styles from './CustomerStoriesCss';
import { getStaticData } from '../../../../../../../staticData/staticData';

import { QUOTE_ICON } from '../../../../../../FlightImageConstants';
import { getFont } from '../../../../../../../PostSalesStyles';

const CustomerStories = ({ data }) => {
  const { fsStyle, ...fonts } = getFont(true);
  return (
    <View style={styles.customerStoryWrapper}>
      <View style={styles.paddingHz20}>
        {!!data.title && (
          <Text
            style={[
              fonts.blackFontFamily,
              AtomicCss.blackText,
              fsStyle.font18,
              AtomicCss.marginBottom12,
            ]}
          >
            {data.title}
          </Text>
        )}
        {!!data.subtitle && (
          <Text
            style={[
              fonts.regularFontFamily,
              AtomicCss.blackText,
              fsStyle.font14,
              AtomicCss.marginBottom20,
            ]}
          >
            {!!data.insuranceCount && (
              <Text style={[fonts.regularFontFamily, AtomicCss.greenText]}>
                {data.insuranceCount}
              </Text>
            )}{' '}
            {data.subtitle}
          </Text>
        )}
      </View>
      {!isEmpty(data.insuranceReviewList) && (
        <ScrollView horizontal={true} contentContainerStyle={styles.storySlideWrapper}>
          {data.insuranceReviewList.map((item) => {
            return <ReadMoreContent item={item} />;
          })}
        </ScrollView>
      )}
    </View>
  );
};
const ReadMoreContent = ({ item }) => {
  const { fsStyle, ...fonts } = getFont(true);
  const {
    digitInsuranceText: { readMoreText, readLessText },
  } = getStaticData();
  const [truncked, setTrunked] = useState(true);
  const handleMoreTxt = () => {
    setTrunked(!truncked);
  };
  const resultSting = truncked ? item.comment.slice(0, 70) : item.comment;
  const readText = truncked ? readMoreText : readLessText;
  return (
    <View style={styles.storyWrapper}>
      <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween]}>
        {!!item.title && (
          <Text
            style={[
              fonts.boldFontFamily,
              AtomicCss.flex1,
              AtomicCss.defaultText,
              fsStyle.font14,
              AtomicCss.lineHeight18,
              AtomicCss.marginBottom8,
            ]}
          >
            {item.title}{' '}
          </Text>
        )}
        <Image source={QUOTE_ICON} style={styles.quoteIconStyle} />
      </View>
      <Text
        style={[
          fonts.boldFontFamily,
          AtomicCss.defaultText,
          fsStyle.font12,
          AtomicCss.lineHeight18,
        ]}
      >
        {resultSting}
        {resultSting.length > 69 && (
          <Text onPress={handleMoreTxt} style={AtomicCss.azure}>
            {readText}
          </Text>
        )}
      </Text>
    </View>
  );
};
export default CustomerStories;
