import React from 'react';
import Actions from '../../../../../navigation/postSalesNavigation';
import {
  Alert,
  Image,
  Platform,
  ScrollView,
  Text,
  ToastAndroid,
  TouchableOpacity,
  View,
  NativeModules,
  DeviceEventEmitter
} from 'react-native';

import Breadcrumb from '../../components/Breadcrumb';
import StickyHeader from '../../../../../hotel/details/components/StickyHeader';
import ClaimProcessList from './components/ClaimProcessList';
import SpecialClaimAddAttachmentModule from '@mmt/legacy-commons/Native/SpecialClaimAddAttachmentModule';
import OverlayMessage from './components/OverlayMessage';
import { isNetworkAvailable } from '@mmt/legacy-commons/Common/utils/AppUtils';
import { backPressHandler } from '../../../../../utils/PostSaleUtil';
import {getSpecialClaimRequest} from '../../data/requestAdapter';
import {raiseSpecialClaim} from '../../data/api';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import ProgressView from '@mmt/legacy-commons/Common/Components/Progress/ProgressView';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import ReviewButton from '../../components/ReviewButton';
import { headerIcon, tick3, deleteIcon, oneway } from '../../../../../bus/BusImageConstants';
import { getFont } from '../../../../../PostSalesStyles';
import { useTheme } from '../../../../../theme/theme.context';
import createStyles from "./operatorreviewcss";
import CabBookingDetailsConstant from '../../../../CabBookingDetailsConstant';

function OperatorReview({bookingId, response}) {
  const [attachmentLst, setAttachmentLst] = React.useState([]);
  const [viewState, setViewState] = React.useState(null);
  const [displayAgreeOverlay, setDisplayAgreeOverlay] = React.useState(false);

  const specialClaimData = response.specialClaimDataList[0];

  const fonts = getFont();
  const {psTheme : theme} = useTheme();
  const styles = createStyles(theme,fonts);

  const showAgreeOverlay = () => {
    setDisplayAgreeOverlay(true);
  }

  React.useEffect(() => {
    DeviceEventEmitter.addListener('special_claim_review_page', fetchAttachment);
    return ()=>{
      DeviceEventEmitter.removeAllListeners('special_claim_review_page', fetchAttachment);
    }
  },[])

  if (viewState === 'LOADING') {
    return <ProgressView message="Submitting the claim..." />;
  }

  function fetchAttachment(data) {
    const map = JSON.parse(JSON.stringify(attachmentLst));
    map.push(data);
    setAttachmentLst(map);
  }

  const updateAttachmentList = (map) => {
    setAttachmentLst(map);
  }

  const getAttachmentHtmlView = () => {
    const attachmentHtmlView = [];
    attachmentLst.forEach(function (obj) {
      attachmentHtmlView.push(<View style={styles.attachedItem}>
        <View style={[styles.flexRow, styles.alignCenter]}>
          <View style={styles.attachedImgWrapper}>
            <Image
              style={styles.attachedImg}
              source={{ uri: `file://${obj.filePath}` }}
            />
          </View>
          <Text style={styles.attachedImgName}>{obj.fileName}</Text>
          <Image style={styles.greenTick} source={tick3} />
        </View>
        <TouchableOpacity
          onPress={() => onDelAttachment(obj.filePath)}
          style={styles.imgDeleteBtn}
        >
          <Image style={styles.deleteIcon} source={deleteIcon} />
        </TouchableOpacity>
      </View>);
    }, this);
    return attachmentHtmlView;
  }

  const getSegmentView = () =>{
    const sgmntView = [];

    const { carBookingInfo : { carDetail : { fromCity, toCity }}} = response;
    const {carAdditionalInfo: { departureDetail : {departureTimeInTwentyFourHoursFormat,
      departureDay, departureDayInMonth, departureMonth, departureYear}}} = response;

    const obj = {};
    obj.originCityName = fromCity;
    obj.originCityCode = fromCity;
    obj.destinationCityName = toCity;
    obj.destinationCityCode = toCity;
    obj.travelDateTime = departureTimeInTwentyFourHoursFormat + " " + departureDay + " "
      + departureDayInMonth + " " + departureMonth + " " + departureYear;

    sgmntView.push(<View key={obj.originCityCode} style={[styles.segmentCard, styles.listWrapper]}>
      <View style={styles.destRow}>
        <Text style={styles.cityText}>{obj.originCityCode}</Text>
        <Image style={styles.arrowIcon} source={oneway} />
        <Text style={styles.cityText}>{obj.destinationCityCode}</Text>
        <Text style={styles.smallText}>{obj.travelDateTime}</Text>
        <Text style={styles.smallText}> | 'TRAVELLER'</Text>
      </View>
    </View>);

    return sgmntView;
  }

  let claimProcess = specialClaimData.claimProcess;
  const STEPS = [ specialClaimData.reviewDetails, specialClaimData.submitClaim];

  const fileUpload = () => {
    const { FileUploaderModule: { FileUploader } } = NativeModules;
    return (
      <FileUploader
        onFileChanged={updateAttachmentList}
        attachmentLst={attachmentLst}
        text="ADD ATTACHMENT"
      />
    )
  }

  const addSupportingInformation = () => {
    return (
      <View style={styles.whiteCard}>
        <Text style={styles.cardTitle}>Add Supporting Information</Text>
        <Text style={styles.paraText}>
          Please share the proof supporting your claim request.
        </Text>

        <View>
          {getAttachmentHtmlView()}
        </View>
        {
          (Platform.OS === 'web') ?
            (<TouchableOpacity onPress={fetchAttachment}>
              {fileUpload()}
            </TouchableOpacity>) :
            attachmentLst.length === 0 && <TouchableOpacity onPress={onAddAttachment}>
              <Text style={[styles.anchorMD, styles.marginTop20]}>
                {CabBookingDetailsConstant.ADD_ATTACHMENT}
              </Text>
            </TouchableOpacity>
        }
      </View>
    )
  }

  const closePopup = () => {
    setDisplayAgreeOverlay(false);
  };

  const getTitle = () => `Claim Refund: ${specialClaimData.scName}`;

  const goBack = () => {
    backPressHandler();
  };

  const getAttachmentBase64String = () => {
    const result = [];
    attachmentLst.forEach((obj) => {
      result.push(obj.filePath);
    }, this);

    let imagePath = result[0];

    let imageResponse = '';

    if (Platform.OS === 'ios' || Platform.OS === 'web') {
      attachmentLst.forEach((obj) => {
        if (obj.filePath === imagePath) {
          imageResponse = obj.data;
        }
      }, this);
    } else {
      imageResponse = SpecialClaimAddAttachmentModule.convertToBase64(imagePath);
    }

    return imageResponse;
  }


  const onAddAttachment = () => {
    if (attachmentLst.length >= 1) {
      ToastAndroid.show('Max 1 attachments allowed!', ToastAndroid.LONG);
    } else {
      SpecialClaimAddAttachmentModule.onButtonClicked();
    }
  };

  const onDelAttachment = (key) => {
    const map =[]
    setAttachmentLst(map);
  };

  const onHardBackPress = () => {
    if (displayAgreeOverlay) {
      closePopup();
      return true;
    }
    return false;
  };

  const submitSpecialClaim = async () => {
    try {
      const hasNetwork = isNetworkAvailable();
      if (!hasNetwork) {
        Alert.alert('Please check your internet connection');
        return;
      }
      const userInfo = await HotelBookingModule.getRequestHeader(bookingId, 'BUS_SPECIAL_CLAIM');
      let attachmentBase64Str = getAttachmentBase64String();

      let attachmentReqList = [];
      attachmentReqList.push(JSON.stringify(attachmentBase64Str));

      const request = getSpecialClaimRequest(bookingId, attachmentReqList);
      const claimResponse = await raiseSpecialClaim(request, userInfo);

      if(claimResponse && claimResponse.overallStatus) {
        Actions.cabspecialclaimthankyou({
          bookingId,
          response
        });
      }
      else {
        setViewState('ERROR');
      }
    } catch (error) {
      console.log(error);
      setViewState('ERROR');
    }
  }

  const onContinueClicked = () => {
    setDisplayAgreeOverlay(false);
    setViewState('LOADING');
    submitSpecialClaim();
  };

  if(viewState === 'ERROR') {
    return <ErrorView
    showRetry
    onRetry={() => onContinueClicked()}
  />
  }

  return (
    <View style={styles.pageWrapper}>
      <StickyHeader
        iconSrc={headerIcon}
        title={getTitle()}
        headerColor={['#ffffff', '#ffffff']}
        titleStyle={[styles.headerTitle]}
        onBackPress={goBack}
      />
      <Breadcrumb activeStep={1} data={STEPS} />
      <ScrollView showsVerticalScrollIndicator={false} style={styles.pageScroll}>
        <View style={styles.pageScrollTopSpace} />
        <View style={styles.whiteCard}>
          {getSegmentView()}
        </View>
        {addSupportingInformation()}
        {specialClaimData.refundAmtTxt ? <View style={styles.whiteCard}>
          <Text style={styles.cardTitle}>{specialClaimData.refundAmtHdr}</Text>
          <Text style={styles.paraText}>
            {specialClaimData.refundAmtTxt}
          </Text>
        </View> : null}

        <View style={styles.whiteCard}>
          <Text style={styles.cardTitle}>{claimProcess.header}</Text>
          <Text style={[styles.paraText, styles.marginBottom20]}>
            {claimProcess.description}
          </Text>
          <ClaimProcessList processList={claimProcess.processList} />
        </View>
        <View style={styles.pageScrollBtmSpace} />
      </ScrollView>
      <View style={styles.footerBtnWrapper}>
        <ReviewButton buttonText={specialClaimData.submitClaim} clickHandler={showAgreeOverlay} disabled={false} />
      </View>
      {displayAgreeOverlay &&
        <OverlayMessage
          closePopupHandler={closePopup}
          proceedClickHandler={onContinueClicked}
          specialClaimData={specialClaimData}
        />
      }
    </View>
  );
}

export default OperatorReview;
