import React from 'react';
import {View,ActivityIndicator,StyleSheet} from 'react-native';

class DetailsLoader extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <View style={[styles.container, styles.horizontal]}>
          <ActivityIndicator size="large" color="#008cff" />
      </View>
    );
  }
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems:'center',
  },
  horizontal: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    padding: 10
  }
})

export default DetailsLoader;


