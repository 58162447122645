import React from 'react';
import {View, Text, TouchableOpacity, StyleSheet} from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {fonts, colors} from '@mmt/legacy-commons/Styles/globalStyles';

const RadioBtn = ({handleRadioBtn, item, index, activeState, handleButtonClick, traveller}) => {
  const activeRadio = item === activeState;
  const activeOuterSuffix = item === activeState ? 'activeRadio' : '';
  return (
    <TouchableOpacity
      style={[AtomicCss.flexRow, AtomicCss.marginRight15, AtomicCss.alignCenter, AtomicCss.marginTop10]}
      onPress={() => {
        handleButtonClick(`Traveller${traveller}_RadioButton${index + 1}`);
        handleRadioBtn(item);
      }}
    >
      <View style={[styles.radioOuter, styles[activeOuterSuffix]]}>
        {activeRadio && <View style={styles.radioInner} />}
      </View>
      <Text style={[styles.radioTxt]}>{item}</Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  radioOuter: {
    width: 20,
    height: 20,
    borderRadius: 50,
    borderWidth: 2,
    borderColor: '#9b9b9b',
    marginRight: 10,
    justifyContent: 'center',
    alignItems: 'center'
  },
  radioInner: {
    width: 14,
    height: 14,
    borderRadius: 20,
    margin: 2,
    backgroundColor: '#008cff'
  },
  radioTxt: {
    fontSize: 12,
    color: colors.black,
    fontFamily: fonts.bold
  },
  activeRadio: {
    borderColor: '#008cff'
  }
});

export default RadioBtn;
