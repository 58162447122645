import { useState, useEffect } from "react";
import { getDurationInDaysHoursMinutesSeconds } from '@mmt/legacy-commons/Common/utils/DateUtils';


/* It takes a date as input and returns no of days left from current date 
or a countdown in hours and minutes if the days left are less than a day */
export default function useCountdown(initialDate = 0) {
  const countDownDate = new Date(initialDate).getTime();

  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown((prev) =>
        prev <= 0 ? 0 : countDownDate - new Date().getTime()
      );
    }, 1000);

    return () => clearInterval(interval);
  }, [initialDate]);

  return getDurationInDaysHoursMinutesSeconds(countDown);
};