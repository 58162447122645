import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import React from 'react';
import { Image, Text, View, TouchableOpacity, ImageSourcePropType } from 'react-native';
import { ArrowDown } from '../../CabImageConstants';
import { getStyles } from './styles';
import { useTheme } from '../../../theme/theme.context';
import { getFont } from '../../../PostSalesStyles';
import { OVERLAY_TYPE } from '../../CabBookingDetailsConstant';
import { ExtraPayableProps } from '../../types';

const ExtraPayable: React.FC<ExtraPayableProps> = ({
  paymentText,
  paymentTextColor,
  paymentValueText,
  handleOverlay,
  carDetail,
}) => {
  const { fsStyle, ...fonts } = getFont();
  const { psTheme: theme } = useTheme();
  const styles = getStyles(fonts, theme);

  return (
    <View style={AtomicCss.flexRow}>
      <View>
        {!!paymentText && (
          <Text style={[styles.extraPay, AtomicCss.alignRight, { color: paymentTextColor }]}>
            {paymentText}
          </Text>
        )}
        {!!paymentValueText && (
          <Text style={[styles.bookingAmountValue, AtomicCss.alignRight]}>{paymentValueText}</Text>
        )}
      </View>
      <TouchableOpacity
        onPress={() => {
          handleOverlay(OVERLAY_TYPE.FARE_BREAKUP, carDetail);
        }}
      >
        <Image style={styles.arrowstyle} source={ArrowDown as ImageSourcePropType} />
      </TouchableOpacity>
    </View>
  );
};

export default ExtraPayable;
