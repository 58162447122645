import React from 'react';
import { View, Text, StyleSheet, Image, TouchableOpacity } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import { confirmedTag } from '../../../CabImageConstants';
import isEmpty from 'lodash/isEmpty';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import { getStyles } from './style';
import { PaymentOptionCardProps } from '../../../types';
import RadioWpr from 'apps/post-sales/src/Common/RadioButton/RadioWpr';


const PaymentOptionCard: React.FC<PaymentOptionCardProps> = ({
  paymentOptionList,
  handlePaymentSelect,
  optionSelected,
  isOverlay = false,
  heading
}) => {
  const handlePaymentOption = (item: any) => {
    handlePaymentSelect(item);
  };

  const { fsStyle, ...fonts } = getFont();
  const { psTheme: theme } = useTheme();
  const styles = getStyles(fonts, theme);

  return (
    <View style={[styles.paymentOptionCard, !isOverlay ? AtomicCss.paddingAll16 : {}]}>
      {!!heading && <Text style={[styles.paymentOptionHeading, AtomicCss.marginBottom15]}>{heading}</Text>}
      {!isEmpty(paymentOptionList) &&
        paymentOptionList.map((item, index) => {
          const { id, heading, subHeading, priceText } = item;
          const activeOuterRadio = id === optionSelected.id ? 'radioActive' : '';
          return (
            <TouchableOpacity
              key={index}
              style={[styles.rowSection, AtomicCss.marginBottom12]}
              onPress={() => handlePaymentOption(item)}
            >
              <RadioWpr
                  isSelected={id === optionSelected.id}
                  radioSize={18}
                  customStyle={styles.radioStyle}
                  />

              <View style={[AtomicCss.flex1, AtomicCss.flexRow, AtomicCss.alignCenter]}>
                <View style={[AtomicCss.flex1]}>
                  {!!heading && (
                    <Text style={[styles.heading, AtomicCss.marginBottom3]}>{heading}</Text>
                  )}
                  {!!subHeading && <Text style={styles.subHeading}>{subHeading}</Text>}
                  {id === optionSelected.id && (
                    <Image style={styles.tagStyle} source={confirmedTag} />
                  )}
                </View>
                {!!priceText && <Text style={styles.priceText}>{priceText}</Text>}
              </View>
            </TouchableOpacity>
          );
        })}
    </View>
  );
};

export default PaymentOptionCard;
