import React from 'react';
import PropTypes from 'prop-types';
import { Image, Text, View } from 'react-native';
import { isEmpty } from 'lodash';
import createStyles from './styles';
import { REFUND_CALENDAR } from '../../../../FlightImageConstants';
import { addSpaceWithAmount } from '../../../../../utils/PostSaleUtil';
import { getStaticData } from '../../../../../staticData/staticData';
import { useTheme } from '../../../../../theme/theme.context';
import { getFont } from '../../../../../PostSalesStyles';

const FareBreakUp = (props) => {
  const { totalFare, fareBreakupItems, header } = props.fareBreakupData;
  const { fareBreakupText } = getStaticData();
  const {psTheme: theme} = useTheme();
  const fonts = getFont(true);
  const styles = createStyles(theme, fonts);
  return (
    <View style={styles.whiteCard}>
      <Text style={styles.CardHead}>{fareBreakupText}</Text>
      {!isEmpty(header) && (
        <View style={styles.cardSubHeading}>
          <Image source={REFUND_CALENDAR} style={styles.headingIconStyle} />
          <Text style={styles.headingText}>{header}</Text>
        </View>
      )}
      {!isEmpty(fareBreakupItems) &&
        fareBreakupItems.map((breakup) => (
          <View style={styles.FareDtls}>
            <View style={styles.FareDtlsTxt}>
              <Text style={styles.FareBkHeading}>{breakup.header}</Text>
              <Text
                style={[
                  styles.FareBkHeading,
                  breakup.amountColor && { color: breakup.amountColor },
                ]}
              >
                {addSpaceWithAmount(breakup.value)}
              </Text>
            </View>
            {!isEmpty(breakup.fareItems) &&
              breakup.fareItems.map((item) => (
                <View style={styles.FareDtlsTxt}>
                  <Text style={styles.font12}>{item.title}</Text>
                  <View style={styles.amountContainer}>
                    {item.slashedAmount && (
                      <Text style={[styles.font12, styles.slashedAmountStyle]}>
                        {item.slashedAmount}
                      </Text>
                    )}
                    <Text style={[styles.font12, item.amountColor && { color: item.amountColor }]}>
                      {addSpaceWithAmount(item.value)}
                    </Text>
                  </View>
                </View>
              ))}
          </View>
        ))}
      {!isEmpty(totalFare) && (
        <View style={styles.TotalFare}>
          <Text style={styles.FareBkTotalTxt}>{totalFare.header}</Text>
          <Text style={styles.FareBkTotalTxt}>{totalFare.value} </Text>
        </View>
      )}
    </View>
  );
};
FareBreakUp.propTypes = {
  fareDetail: PropTypes.object.isRequired,
};

export default FareBreakUp;
