import React, { useEffect } from 'react';
import { View, Text, TouchableOpacity, ScrollView, Platform} from 'react-native';
import { renderErrorView, renderProgressView } from '../../../../Common/NeedMoreHelpOverlay/NeedMoreHelpUtils';
import useApi from '../../../../Common/useApi';
import { getStaticData } from '../../../../staticData/staticData';
import { HOTEL_BOOKING_CANCELLATION_URL } from '../../../../utils/NetworkUtils';
import getStyles from './cancellationPolicyOverlayCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import Button from '../../../../Common/Button';
import { getHotelCancellationRequest, isBNPL, isNonRefundable, isPAH } from '../../utils/HotelBookingDetailUtil';
import Tracker from './Tracker';
import { isNotNullAndEmpty } from '../../utils/HotelBookingDetailUtil';
import { THEME } from '../../../../PostSalesConstant';
import RefundApplicableCard from '../../../Cancellation/components/RefundBreakupCard';
import HTMLView from 'react-native-htmlview';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import { getErrorPageHeading } from '../../../hotelUtils';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import ButtonWrapper from 'apps/post-sales/src/Common/ButtonWrapper';
import { useTheme } from '../../../../theme/theme.context';

const getHtmlStyleRefundableStyles = (fonts) => {
  return {
    p: {
      color: colors.black,
      ...fonts.font18,
      fontFamily: fonts.bold,
      lineHeight: 24,
      textAlign: 'left'
    },
    text: {
      color: colors.green
    }
  };
}

const getHtmlStyleNonRefundableStyles = (fonts) => {
  return {
    p: {
      color: colors.black,
      ...fonts.font18,
      fontFamily: fonts.bold,
      lineHeight: 24,
      textAlign: 'left'
    },
    text: {
      color: colors.red
    }
  };
}
const getHtmlStyleZeroRefundStyles = (fonts) => {
  return {
    p: {
      color: colors.black,
      ...fonts.font18,
      fontFamily: fonts.bold,
      lineHeight: 24,
      textAlign: 'left'
    },
    text: {
      color: colors.black
    }
  };
}

const getHtmlStyleExtraInfoStyles = (fonts) => {
  return {
    p: {
      ...fonts.font14,
      lineHeight: 24,
      fontFamily: fonts.regular,
      color: colors.defaultTextColor
    },
    b: {
      fontFamily: fonts.bold
    }
  }
}

const CancellationPolicyOverlay = ({ isRefund, showCancel, showDismiss, cancelClickHandler, dismissClickHandler, cancellationPolicyCardInfo, hotelDetailsResponse, canResponse }) => {
  const { fsStyle, ...fonts } = getFont(true);
  const styles = getStyles(fonts);
  const htmlStyleExtraInfo = getHtmlStyleExtraInfoStyles(fonts);
  const htmlStyleZeroRefund = getHtmlStyleZeroRefundStyles(fonts);
  const htmlStyleNonRefundable = getHtmlStyleNonRefundableStyles(fonts);
  const htmlStyleRefundable = getHtmlStyleRefundableStyles(fonts);
  const [cancellationProgress, cancellationResponse, cancellationApi] = useApi(HOTEL_BOOKING_CANCELLATION_URL);
  const cancellationRequest = getHotelCancellationRequest(hotelDetailsResponse, 'true', 'RefundToCustomer');
  const { policyTracker, extraInfo, showCancellationPolicy } = cancellationPolicyCardInfo;
  const hotelDetailList = hotelDetailsResponse.hotelBookingInfo && isNotNullAndEmpty(hotelDetailsResponse.hotelBookingInfo.hotelDetailsList) && hotelDetailsResponse.hotelBookingInfo.hotelDetailsList[0];
  const { cancellationRules } = hotelDetailList || {};
  const {cancellationText: {additionalText}, cancellationPolicyText, dismissText} = getStaticData();
  const { psTheme: theme } = useTheme();
  useEffect(() => {
    if (!canResponse) {
      cancellationApi.httpPost({body: cancellationRequest, bookingId: hotelDetailsResponse.bookingID, uniqueId: 'HOTEL_CANCELLATION_PREVIEW'})
    }
  }, []);

  const apiCall = () => {
    cancellationApi.httpPost({body: cancellationRequest, bookingId: hotelDetailsResponse.bookingID, uniqueId: 'HOTEL_CANCELLATION_PREVIEW'})
  }

  const renderHeader = (cancellationResp) => {
    const {totalRefundAmount} = cancellationResp;
    if (isPAH(hotelDetailsResponse)) {
      return (
        <Text style={[fsStyle.font18, fonts.boldFontFamily, AtomicCss.lineHeight24, AtomicCss.marginBottom5, AtomicCss.blackText, AtomicCss.alignLeft]}>
            {cancellationResp?.payAtHotelText || additionalText.payAtHotelText}
        </Text>
        
      )
    } else {
      return (
        <HTMLView 
          value={cancellationResp.refundAmountText}
          stylesheet={hotelDetailsResponse.bookAtZero ? htmlStyleZeroRefund : (totalRefundAmount > 0 ? htmlStyleRefundable : htmlStyleNonRefundable)}
        />
      )
    }
  }

  const renderMainContent = () => {
    const cancellationResp = cancellationResponse || canResponse;
    return (
      <View style={styles.OverlayContentWrapper}>
        <View style={Platform.OS === 'web' ? styles.stickyWrapper : null}>
          <View style={AtomicCss.marginBottom24}>
            <View style={styles.overlayTopline}></View>
          </View>
          <View>
            <Text style={[styles.title]}>{cancellationPolicyText}</Text>
          </View>
        </View>
          <ScrollView style={{paddingHorizontal:20}}>
            {renderHeader(cancellationResp)}
            <RefundApplicableCard
              isRefundable={!isNonRefundable(hotelDetailsResponse)}
              isPAH={isPAH(hotelDetailsResponse)}
              isBNPL={isBNPL(hotelDetailsResponse)}
              breakups={cancellationResp.breakups}
              cancellationResponse={cancellationResp}
              fromPolicy={true}
            />
            <View style={styles.scrollWrapper}>
              <View style={styles.trackerWrapper}>
                {isNotNullAndEmpty(policyTracker) && policyTracker.map((item, index) => {
                  return (
                    <Tracker
                      item={item}
                      index={index}
                      flexiCancellationData={hotelDetailsResponse?.flexiCancellationData}
                      isLastItem={index === policyTracker.length - 1}
                    />
                  )
                })}
                {isNotNullAndEmpty(extraInfo) && 
                  <View style={styles.extraInfoWrapper}>
                    <HTMLView
                      stylesheet={htmlStyleExtraInfo}
                      value={extraInfo}
                    />
                  </View>}
                {showCancellationPolicy && isNotNullAndEmpty(cancellationRules) && <View style={[AtomicCss.flex1, AtomicCss.marginRight18]}> 
                  {cancellationRules.split('. ').map((rule) => {
                    return (
                      <View style={[AtomicCss.marginBottom15, AtomicCss.flexRow]} key={rule}>
                        <View style={styles.itemDisc} />
                        <Text style={[fsStyle.font14, AtomicCss.defaultText, AtomicCss.lineHeight24, fonts.regularFontFamily]}>{rule}</Text>
                      </View>
                    )
                  })}
                </View>}
              </View> 
            </View>
          </ScrollView>
        {showCancel && showDismiss &&
          <View style={[styles.dismissWrapper]}>
            <View>
              <ButtonWrapper
                clickHandler={dismissClickHandler}
                btnText={dismissText}
                buttonType='text'
                buttonWidth='dynamic'
                btnTxtStyle={[fsStyle.font16,AtomicCss.lineHeight20]}
              />
            </View>
            <ButtonWrapper
              clickHandler={cancelClickHandler}
              btnText={additionalText.cancelBookingText.toUpperCase()}
              buttonType='outline'
              buttonWidth='dynamic'
              btnTxtStyle={hotelDetailsResponse.isCorporateBooking ? {color: theme.color.orange3} : {color: theme.color.azure}}
              btnStyle={hotelDetailsResponse.isCorporateBooking ? {borderColor: theme.color.orange3} : {borderColor: theme.color.azure}}
            />
          </View>
        }
        {!showCancel && showDismiss && <View style={[styles.dismissWrapper, AtomicCss.justifyEnd]}>
          <ButtonWrapper
            buttonType='text'
            btnTxtStyle={[fonts.blackFontFamily, fsStyle.font16, AtomicCss.azure, AtomicCss.lineHeight20]}
            btnText={dismissText}
            clickHandler={dismissClickHandler}
            buttonWidth='dynamic'
          />
        </View>}
      </View>
    )
  }

  if (cancellationProgress) {
    return renderProgressView(true);
  } else if (cancellationResponse && (cancellationResponse.error || cancellationResponse.errorPageData)) {
    const {errorText: { unableToProcessRequestTechIssueText }} = getStaticData();
    const desc = getErrorPageHeading(unableToProcessRequestTechIssueText, cancellationResponse);
    return renderErrorView(apiCall, true, desc);
  } else if (cancellationResponse || canResponse) {
    return renderMainContent();
  } else {
  return null;
  }
};

export default CancellationPolicyOverlay;