import React, { useEffect } from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getStyle from './styles';
import HTMLView from 'react-native-htmlview';
import LinearGradient from 'react-native-linear-gradient';
import { cabVoucherImage, copyCodeIcon } from 'apps/post-sales/src/cab/CabImageConstants';
import CardWrapper from '../CardWrapper';
import { isEmpty } from 'lodash';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import { onCopyText } from 'apps/post-sales/src/cab/utils/CabBookingDetailUtil';
import { getActionButton } from 'apps/post-sales/src/Common/commonUtil';
import CabBookingDetailsConstant, { CLICK_EVENT } from 'apps/post-sales/src/cab/CabBookingDetailsConstant';
import { RefundIssuedCardProps } from 'apps/post-sales/src/cab/types';
import CabBookingTrackingHelper from 'apps/post-sales/src/cab/CabBookingTrackingHelper';

const RefundIssuedCard: React.FC<RefundIssuedCardProps> = ({
  card,
  cardData,
  pageName,
  response,
  handleViewRefundBreaup,
}) => {
  const { refundIssuedCardData } = cardData || {};
  const { fsStyle, ...fonts } = getFont();
  const { psTheme: theme } = useTheme();
  const styles = getStyle(theme, fonts);

  useEffect(() => {
    CabBookingTrackingHelper.trackClickEvent(pageName, CLICK_EVENT.NOT_BOARDED_VOUCHER_SHOWN);
  }, []);

  if (isEmpty(refundIssuedCardData)) {
    return null;
  }

  const { heading, subHeading, desc, voucherData } = refundIssuedCardData || {};
  const { voucherCodeText, voucherCode, validText, voucherImageUrl, themeColorList } = voucherData || {};


  const copyVoucherCode = () => {
    CabBookingTrackingHelper.trackClickEvent(pageName, CLICK_EVENT.NOT_BOARDED_VOUCHER_COPY_CLICK);
    onCopyText(voucherCode);
  };

  const handleClick = () => {
    CabBookingTrackingHelper.trackClickEvent(pageName, CLICK_EVENT.VIEW_REFUND_BREAKUP_CLICK);
    handleViewRefundBreaup(CabBookingDetailsConstant.RefundIssuedOverlay);
  };

  return (
    <LinearGradient
      start={{ x: 1, y: 0 }}
      end={{ x: 0.7, y: 1 }}
      colors={!isEmpty(themeColorList) ? themeColorList : [theme.color.lightGreen21, theme.color.white]}
      style={styles.refundIssueCard}
    >
      <View style={styles.notBoardCard}>
        <View style={styles.topSection}>
          <View style={AtomicCss.flex1}>
            {!!heading && <Text style={styles.titleText}>{heading}</Text>}
            {!!subHeading && <HTMLView value={subHeading} stylesheet={styles} />}
            {!!desc && <Text style={styles.desc}>{desc}</Text>}
          </View>
          <Image style={styles.cabVoucher} source={{uri: voucherImageUrl}} />
        </View>
        {!isEmpty(voucherData) && (
          <View style={[AtomicCss.flexRow, AtomicCss.marginBottom10]}>
            {!!voucherCodeText && <Text style={styles.moreInfo}>{voucherCodeText}</Text>}
            {!!voucherCode && <Text style={styles.voucherCode}>{voucherCode}</Text>}

            <TouchableOpacity onPress={copyVoucherCode}>
              <Image style={[styles.copyIcon]} source={copyCodeIcon} />
            </TouchableOpacity>
          </View>
        )}
        {!!validText && <Text style={styles.moreInfo}>{validText}</Text>}
      </View>
      {getActionButton(card, response, handleClick, {}, true, false, styles.buttonRadiusStyle)}
    </LinearGradient>
  );
};

export default CardWrapper(RefundIssuedCard);
