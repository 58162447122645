import React, { useCallback, useEffect } from 'react';
import { View, Platform, DeviceEventEmitter } from 'react-native';
import styles from 'apps/post-sales/src/Common/downloadInvoiceBottomSheetCss.js';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import ShimmerCard from 'apps/post-sales/src/Common/ShimmerCard';
import InvoiceList from 'apps/post-sales/src/Common/InvoiceList.js';
import useApi from 'apps/post-sales/src/Common/useApi.js';
import { DOWNLOAD_INVOICE_API } from 'apps/post-sales/src/utils/NetworkUtils.js';
import DownloadInvoiceError from 'apps/post-sales/src/Common/DownloadInvoiceError.js';
import useHardwareBackpress from 'apps/post-sales/src/Common/useHardwareBackpress.js';

const DownloadInvoiceBottomSheet = ({ bookingId, closeOverlay, lob, psLob, pageName }) => {
  const [invoiceInProgress, invoiceResponse, invoiceApi] = useApi(DOWNLOAD_INVOICE_API);

  useHardwareBackpress().addListener(closeOverlay, true);

  const apiCall = useCallback(() => {
    invoiceApi.httpPost({
      body: {
        bookingid: bookingId,
        userId: Platform.OS === 'web' ? 'PWA' : Platform.OS.toUpperCase(),
        lob: lob,
      },
      bookingId: bookingId,
      uniqueId: 'DOWNLOAD_INVOICE',
      psLob: psLob,
    });
  }, [invoiceApi, bookingId]);

  useEffect(() => {
    apiCall();
  }, [apiCall]);
  return (
    <View style={styles.OverlayContentWrapper}>
      <View style={AtomicCss.marginBottom20}>
        <View style={styles.overlayTopline} />
      </View>
      {invoiceInProgress && <ShimmerCard rows={2} />}
      {!invoiceInProgress && invoiceResponse && !invoiceResponse.errorPageData && (
        <InvoiceList 
        data={invoiceResponse}
        bookingID={bookingId}
        pageName={pageName}
        />
      )}
      {!invoiceInProgress && invoiceResponse && (invoiceResponse.error || invoiceResponse.errorPageData) && (
        <DownloadInvoiceError onRetry={apiCall} />
      )}
    </View>
  );
};

export default DownloadInvoiceBottomSheet;
