import { StyleSheet } from 'react-native';

export default (theme) =>
  StyleSheet.create({
    pickUpDetailsWrapper: {
      paddingVertical: 24,
      paddingHorizontal: 16,
      backgroundColor: theme.color.white,
    },
    cardWrapper: {
      borderRadius: 4,
      borderWidth: 1,
      borderColor: theme.color.gray8,
      marginBottom: 20,
    },
    searchInptWrapper: {
      paddingHorizontal: 14,
      paddingVertical: 12,
    },
    locationTrackBorder: {
      position: 'absolute',
      left: 8,
      top: 10,
      height: '97%',
      width: 1,
      backgroundColor: theme.color.grayDark,
    },
    checkboxContainer: { marginLeft: 20, marginBottom: 12, marginTop: -10, alignItems: 'center' },
  });
