import React from 'react';
import {View, TouchableOpacity} from 'react-native';
import styles from './OverlayMessageCss';

const OverlayMessage = ({content, handleBottomOverlay}) => {
    return (
        <View style={[styles.overlayWrapper]}>
            <TouchableOpacity onPress={() => handleBottomOverlay(' ')} activeOpacity={1} style={styles.overlay} />
            <View style={styles.overlayContent}>
                {content}
            </View>
        </View>
    )
}

export default OverlayMessage;
