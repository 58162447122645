import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
import { StyleSheet } from 'react-native';

const createStyles = (fonts,theme) =>
  StyleSheet.create({
    visaDetailsCard: {
      padding: 16,
      backgroundColor: theme.color.grey22,
      ...getPlatformElevation(1),
      marginBottom: 5,
    },
    rowSection: {
      marginTop: 12,
      flexDirection: 'row',
      alignItems: 'center',
    },
    titleText: {
      fontSize: fonts.fontSize.font14,
      color: theme.color.black,
      ...fonts.boldFontFamily,
    },
    headingText: {
      color: theme.color.black, 
      ...fonts.regularFontFamily,
      fontSize: fonts.fontSize.font14,
    },
    descriptionText: {
      color: theme.color.defaultTextColor, 
      ...fonts.regularFontFamily, 
      fontSize: fonts.fontSize.font14
    },
    flagIconStyle: {
      width: 28,
      height: '100%',
      resizeMode: 'contain',
      marginRight: 10,
    },
  });
export default createStyles;
