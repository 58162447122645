import { Platform, I18nManager } from 'react-native';
import Actions from './navigation/postSalesNavigation';
import { LOBNAMES } from './PostSalesConstant';
import { FLIGHT_BOOKING_ROUTE_KEYS_ARRAY } from './flights/flightBookingRouteKeyArray';
import RAIL_ROUTE_CONFIG from './Rails/railsRouteConfig';
import SELF_DRIVE_ROUTE_CONFIG from './selfDrive/selfDriveRouteConfig';
import BUS_ROUTE_CONFIG from './bus/busRouteConfig';
import HOLIDAY_BOOKING_ROUTE_KEYS_ARRAY from './Holiday/holidayBookingRouteKeyArray';
import ACME_BOOKING_ROUTE_KEYS_ARRAY from './acme/acmeBookingRouteKeyArray';
import METRO_BOOKING_ROUTE_KEYS_ARRAY from './Metro/metroBookingRouteKeyArray';
import VISA_BOOKING_ROUTE_KEYS_ARRAY from './Visa/visaBookingRouteKeyArray';
import CAB_ROUTE_CONFIG from './cab/cabRouteConfig';
import GIFTCARD_BOOKING_ROUTE_KEYS_ARRAY from './giftcard/giftcardBookingRouteKeyArray';
import HOTEL_BOOKING_ROUTE_KEYS_ARRAY from './hotel/hotelBookingRouteKeyArray';
import SUMMARY_ROUTE_CONFIG from './summary/navigation/routeConfig';
import { LANGUAGE_MAP } from '@mmt/legacy-commons/Common/constants/AppConstants';
import TGS_ROUTE_CONFIG from './TripGuarantee/tripGuaranteeRouteConfig';
import { getUserSelectedLanguage } from 'packages/legacy-commons/AppState/AppUserConfig';

class PostsalesVernac {
  constructor() {
    this.vernacSupportLob = {};
    this.postsalesRouteLangAwareMap = {};
    this.init();
  }

  init() {
    if (Platform.OS !== 'web') {
      if(getUserSelectedLanguage() === 'ara'){
        if(!I18nManager.isRTL){
          I18nManager.forceRTL(true);
        }
      } else {
        I18nManager.forceRTL(false);
      }
      this.initVernacSupportLob();
      this.initPostsalesRouteLangAwareMap();
    }
  }

  initVernacSupportLob() {
    this.vernacSupportLob = {
      [LOBNAMES.POST_SALES]: true,
      [LOBNAMES.FLIGHT]: true,
      [LOBNAMES.HOTEL]: true,
      [LOBNAMES.RAIL]: true,
      [LOBNAMES.BUS]: true
    };
  }

  initPostsalesRouteLangAwareMap = () => {
    for (let lob in this.vernacSupportLob) {
      this.updatePostsalesRouteLangAwareMap(lob);
    }
  };

  updateVernacSupportLob(lob, value) {
    if (this.vernacSupportLob[lob] !== undefined) {
      this.vernacSupportLob[lob] = value;
    }
  }

  updatePostsalesRouteLangAwareMap(lob) {
    let routes = [];
    switch (lob) {
      case LOBNAMES.POST_SALES:
        routes = SUMMARY_ROUTE_CONFIG;
        break;
      case LOBNAMES.FLIGHT:
        routes = FLIGHT_BOOKING_ROUTE_KEYS_ARRAY;
        break;
      case LOBNAMES.HOTEL:
        routes = HOTEL_BOOKING_ROUTE_KEYS_ARRAY;
        break;
      case LOBNAMES.BUS:
        routes = BUS_ROUTE_CONFIG;
        break;
      case LOBNAMES.CAR:
        routes = CAB_ROUTE_CONFIG;
        break;
      case LOBNAMES.HOLIDAY:
        routes = HOLIDAY_BOOKING_ROUTE_KEYS_ARRAY;
        break;
      case LOBNAMES.METRO:
        routes = METRO_BOOKING_ROUTE_KEYS_ARRAY;
        break;
      case LOBNAMES.RAIL:
        routes = RAIL_ROUTE_CONFIG;
        break;
      case LOBNAMES.SELF_DRIVE:
        routes = SELF_DRIVE_ROUTE_CONFIG;
        break;
      case LOBNAMES.VISA:
        routes = VISA_BOOKING_ROUTE_KEYS_ARRAY;
        break;
      case LOBNAMES.GIFT:
        routes = GIFTCARD_BOOKING_ROUTE_KEYS_ARRAY;
        break;
      case LOBNAMES.ACME:
        routes = ACME_BOOKING_ROUTE_KEYS_ARRAY;
        break;
      case LOBNAMES.TGS:
        routes = TGS_ROUTE_CONFIG;
        break;
      default:
        return;
    }
    this.routesLobMapping(routes, lob);
  }

  routesLobMapping(routes, lob) {
    routes.forEach((route) => {
      this.postsalesRouteLangAwareMap[route.key] = lob;
    });
  }

  isCurrentLobVernacSupported(lob) {
    return this.vernacSupportLob[lob] || false;
  }

  isCurrentRouteVernacSupported() {
    const currentScene = Actions.currentScene();
    if (!currentScene) {
      return true;
    }
    const routeLob = this.postsalesRouteLangAwareMap[currentScene];
    return routeLob ? this.isCurrentLobVernacSupported(routeLob) : false;
  }

  applicableLang(appLang, lob) {
    if (Platform.OS !== 'web' && appLang !== LANGUAGE_MAP.eng) {
      if (
        (lob && !this.isCurrentLobVernacSupported(lob)) ||
        !this.isCurrentRouteVernacSupported()
      ) {
        return LANGUAGE_MAP.eng;
      }
    }
    return appLang;
  }
}

export default new PostsalesVernac();
