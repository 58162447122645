/* eslint-disable max-len */
import React from 'react';
import {View, Text, TouchableOpacity} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import PropTypes from 'prop-types';
import styles from './RefundModeCss';
import AtomicCss from '../../commonStyles/AtomicCss';
import {isNonEmpty} from '@mmt/legacy-commons/Common/utils/StringUtils';
import { label } from '@mmt/post-sales/src/staticData/staticData';
import { getFont } from '../../../PostSalesStyles';

const instantRefundGradient = ['#28beb2', '#43e1a8'];

class RefundModeList extends React.Component {
  static navigationOptions = {
    header: null,
  };
  constructor(props) {
    super(props);
    this.state = {
      radiobtn: { refundModeReason: 'RefundToCustomer' },
    };
    this.radio = this.radio.bind(this);
    this.REFUNDMODE = {
      ORIGINAL: '1',
      MYWALLET: '2',
    };

    this.refundModeList = this.props.previewObject.cancellationRefundMode.refundModeList;
    this.instantRefund = false;
    this.fonts = getFont(true);
  }
  radio(active) {
    this.setState({
      radiobtn: active,
    });
  }
  render() {
    this.refundModeList = this.props.previewObject.cancellationRefundMode.refundModeList;
    const { fonts } = this;
    return (
      <View style={[AtomicCss.greyBg, AtomicCss.flex1]}>
        <View style={[AtomicCss.whiteBg, AtomicCss.marginBottom10, AtomicCss.elevation1]}>
          <View style={styles.PartCancellationHead}>
            <Text style={[fonts.blackFontFamily, AtomicCss.blackText, fonts.font16]}>
              {label('bus.cancellation.selectRefundPaymentMode')}
            </Text>
          </View>
          <View style={[styles.ActionBtn, AtomicCss.marginTop15]}>
            {this.populateRefundModes(
              this.props.previewObject.cancellationRefundMode.refundModeList,
            )}
          </View>
        </View>
      </View>
    );
  }

  getPaymentData(refundModeCode) {
    let paymentData = [];

    if (!(this.props.previewObject && this.props.previewObject.cancellationRefundMode)) {
      return paymentData;
    }

    const refundList = this.props.previewObject.cancellationRefundMode.refundModeList;

    if (refundList && refundList.length > 0) {
      for (let i = 0; i < refundList.length; i++) {
        if (refundList[i].refundModeCode === refundModeCode) {
          paymentData = refundList[i].subText;
          return paymentData;
        }
      }
    }
    return paymentData;
  }

  addRefundModedata = (data) =>
    data.map((refundModeText, index) => (
      <View>
        <Text style={this.getTextStyle(index)}>{refundModeText}</Text>
      </View>
    ));

  getTextStyle = (index) => {
    const { fonts } = this;
    const cssStyles = [fonts.font12, styles.refundText, fonts.regularFontFamily];
    if (index % 2 == 0) cssStyles.push(AtomicCss.blackText);

    return cssStyles;
  };

  populateRefundModes = (data) => {
    const { fonts } = this;
    return data.map((refundModeData, i) => (
      <TouchableOpacity
        style={this.getStyle(refundModeData, i)}
        onPress={() => {
          this.instantRefund = isNonEmpty(refundModeData.accountNo);
          this.radio(refundModeData);
          this.props.refundMode(refundModeData);
        }}
      >
        <View style={styles.refundCardOuter}>
          <View style={[styles.refundCardWrap, AtomicCss.marginBottom10]}>
            <View style={[AtomicCss.flexRow, AtomicCss.flex1]}>
              <View style={styles.MimaRadio}>
                {!isNonEmpty(refundModeData.accountNo) && (
                  <View
                    style={[
                      this.state.radiobtn.refundModeReason === refundModeData.refundModeReason &&
                      !this.instantRefund
                        ? styles.MimaRadioInside
                        : '',
                    ]}
                  />
                )}
                {isNonEmpty(refundModeData.accountNo) && (
                  <View
                    style={[
                      this.state.radiobtn.accountNo === refundModeData.accountNo &&
                      this.instantRefund
                        ? styles.MimaRadioInside
                        : '',
                    ]}
                  />
                )}
              </View>
              <View style={styles.refundCardLeft}>
                <Text style={[AtomicCss.defaultTextColor, fonts.font14, fonts.boldFontFamily]}>
                  {refundModeData.title}
                </Text>
              </View>
            </View>
            <Text style={[AtomicCss.defaultTextColor, fonts.boldFontFamily, fonts.font16]}>
              &#8377; {this.props.previewObject.refundAmount}{' '}
            </Text>
          </View>
          {refundModeData.subText.length > 0 && this.addRefundModedata(refundModeData.subText)}
          {isNonEmpty(refundModeData.accountNo) && (
            <View style={{ paddingLeft: 32, paddingTop: 10 }}>
              <LinearGradient
                start={{ x: 1.0, y: 0.0 }}
                end={{ x: 0.0, y: 1.0 }}
                colors={instantRefundGradient}
                style={styles.instantRefundTag}
              >
                <Text style={[styles.instantRefundTagTxxt, fonts.boldFontFamily, fonts.font10]}>
                  {label('bus.cancellation.instantRefund')}
                </Text>
              </LinearGradient>
            </View>
          )}
        </View>
      </TouchableOpacity>
    ));
  };

  getStyle = (refundModeData, i) => {
    const cssStyles = [];
    if (i + 1 !== this.refundModeList.length) {
      cssStyles.push(styles.borderbtm);
      cssStyles.push(AtomicCss.marginBottom10);
    }

    if (this.isMyWalletRefundMode(refundModeData.title)) {
      cssStyles.push(styles.hideView);
    } else {
      cssStyles.push(styles.showView);
    }

    return cssStyles;
  };

  isMyWalletRefundMode = (refundModeTitle) => refundModeTitle.toLowerCase().includes('wallet');
}

RefundModeList.propTypes = {
  previewObject: PropTypes.object.isRequired,
  refundMode: PropTypes.func.isRequired
};

export default RefundModeList;
