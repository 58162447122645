import React, { useState, useMemo } from 'react';
import {
  FlatList,
  Image,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import BaseButton, { BUTTON_VARIANT } from '../BaseButton';
import { getFont } from '../../PostSalesStyles';
import { useTheme } from '../../theme/theme.context';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getStaticData } from '../../staticData/staticData';
import createStyle from './dateTimePickerCss';
import { dateDropDownOptions, DefaultDropDownData, timeDropDownOptions } from './staticData';
import { getDateFromDateObject, handleDropDownData, getIntialState, checkValidation} from './util';

const blueDropIcondown = require('@mmt/legacy-assets/src/blueDropIcondown.webp');

const DateTimePicker = ({ selectedDate = new Date(), onSelect, headerText = '', minDate, maxDate}) => {
  const { psTheme: theme } = useTheme();
  const fonts = getFont();
  const styles = createStyle(theme, fonts);
  const { doneText, selectDateText, selectTimeText, selectValidDate } = getStaticData();

  const [dateTimeObject, setDateObject] = useState(() =>
    getIntialState(selectedDate)
  );

  const [errorText, setErrorText] = useState('');
  const currentDropDownData = useMemo(
    () => handleDropDownData(DefaultDropDownData, dateTimeObject),
    [dateTimeObject.month, dateTimeObject.year],
  );

  const [currentOpenedDropDown, setCurrentOpenedDropDown] = useState('');

  const handleValidation = (dateObj) => {
    const newSelectedDate = getDateFromDateObject(dateObj);

    const isValid = checkValidation({
      selectedDate: newSelectedDate,
      minDate,
      maxDate,
    });

    if (!isValid) {
      setErrorText(selectValidDate);
    } else {
      setErrorText('');
    }
    return isValid;
  };

  const handleOnchange = (value) => {
    setDateObject({
      ...dateTimeObject,
      [currentOpenedDropDown]: value,
    });
    handleValidation({ ...dateTimeObject, [currentOpenedDropDown]: value });
      setCurrentOpenedDropDown('');
  };

  const onSubmit = () => {
    const isValid = handleValidation(dateTimeObject);
    if(isValid){
      const newSelectedDate = getDateFromDateObject(dateTimeObject);
      onSelect(newSelectedDate);
    }
  };

  const handleDropdown = (name) => {
    const isAlreadyOpen = name === currentOpenedDropDown;
    setCurrentOpenedDropDown(isAlreadyOpen ? '' : name);
  };

  const renderItem = ({ item }) => (
    <TouchableOpacity
      key={item}
      style={styles.optionItem}
      onPress={() => handleOnchange(item)}
      activeOpacity={1}
    >
      <Text style={styles.optionText}>{item}</Text>
    </TouchableOpacity>
  );

  const DropDown = ({ isOpen, data }) => (
    <View style={AtomicCss.flex1}>
      {isOpen && (
        <View>
          <FlatList data={data} renderItem={renderItem} style={styles.dropDownSelctOption} />
        </View>
      )}
    </View>
  );

  const getDropDownRowContent = (dropDownOptions) => {
    return (
      <>
        <View style={styles.dropDownWrapper}>
          {dropDownOptions.map((option, index) => (
            <TouchableOpacity
              key={option}
              activeOpacity={0.8}
              onPress={() => handleDropdown(option)}
              style={[
                styles.dropDownSelct,
                AtomicCss.flexRow,
                AtomicCss.flex1,
                AtomicCss.alignCenter,
                AtomicCss.spaceBetween,
              ]}
            >
              <Text style={styles.dropDownSelctTxt}>{dateTimeObject[option]}</Text>
              <Image style={styles.arrowStyle} source={blueDropIcondown} />
            </TouchableOpacity>
          ))}
        </View>
        <View style={AtomicCss.flexRow}>
          {dropDownOptions.map((option, i) => (
            <DropDown
              data={currentDropDownData[option]}
              isOpen={currentOpenedDropDown == option}
              name={option}
            />
          ))}
        </View>
      </>
    );
  };

  return (
    <TouchableWithoutFeedback
      onPress={() => {
        handleDropdown(currentOpenedDropDown);
      }}
    >
      <View style={styles.overlayContentWrapper}>
        <View style={styles.contentWrapper}>
          <Text style={[styles.headingTxt]}>{headerText}</Text>
          <Text style={styles.subHeadingTxt}>{selectDateText}</Text>
          {getDropDownRowContent(dateDropDownOptions)}
          <View style={AtomicCss.marginTop40} />
          <Text style={styles.subHeadingTxt}>{selectTimeText}</Text>
          {getDropDownRowContent(timeDropDownOptions)}
        </View>
        <View style={styles.btnWrapper}>
          <View style={[styles.errorContainer, !!errorText ? styles.errorActive : {}]}>
            {!!errorText && <Text style={styles.errorText}>{errorText}</Text>}
          </View>
          <BaseButton
            variant={BUTTON_VARIANT.PRIMARY}
            text={doneText}
            clickHandler={onSubmit}
            disabled={!!errorText}
          />
        </View>
      </View>
    </TouchableWithoutFeedback>
  );
};

export default DateTimePicker;
