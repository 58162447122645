import React from 'react';
import {View, Text, Image, TouchableOpacity} from 'react-native';
import {refundIcon} from '../BusImageConstants';
import PropTypes from 'prop-types';
import {appendRupeeSymbol} from '../../hotel/details/utils/HotelBookingDetailUtil';
import getStyles from '../styles/BusMamiCss';


class ChooseRefundModeCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      radiobtn: this.props.selectedRefundMode
    };
    this.penalty = this.props.penalty;
    this.busBookingInfo = this.props.response.busBookingInfo;
    this.bookingPaymentDetail = this.props.response.bookingPaymentDetail.paymentDetails[0];
    this.radio = this.radio.bind(this);
  }

  radio(active) {
    this.setState({
      radiobtn: active
    });
    this.props.updateSelectedRefundMode(active);
  }
  render() {
    const styles = getStyles();
    return (
      <View style={[styles.MamiCard, styles.mar10Bt]}>
        <View style={styles.CardContent}>
          <View style={styles.IconPortion}>
            <Image style={styles.CardIcon} source={refundIcon} />
          </View>
          <View style={styles.ContentPortion}>
            <Text style={[styles.blackFont, styles.BlackText]}>SELECT REFUND MODE</Text>
          </View>
        </View>
        <View style={[styles.ActionBtn, styles.mar15Top]}>
          <TouchableOpacity onPress={() => { this.radio('RefundToWallet'); }}>
            <View style={styles.refundCardOuter}>
              <View style={[styles.refundCardWrap, styles.mar10Bt]}>
                <View style={styles.flexRow}>
                  <View style={styles.MimaRadio}>
                    <View style={[this.state.radiobtn === 'RefundToWallet' ? styles.MimaRadioInside : '']} />
                  </View>
                  <View style={styles.refundCardLeft}>
                    <Text style={[styles.BlackText, styles.regularFont]}>MakeMyTrip Wallet Cash</Text>
                    <Text style={[styles.font13, styles.regularFont]}>{this.props.response.primaryCustomerDetails.emailId}</Text>
                  </View>
                </View>
                <Text style={[styles.BlackText, styles.boldFont, styles.font16]}>{appendRupeeSymbol(Math.round(this.penalty.refundAmount))}</Text>
              </View>
              <Text style={[styles.font13, styles.regularFont, styles.refundText]}>Instant refund, no expiry date, refundable to bank account, can be used on MakeMyTrip without restrictions</Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => { this.radio('RefundToCustomer'); }}>
            <View style={styles.refundCardOuter}>
              <View style={[styles.refundCardWrap, styles.mar10Bt]}>
                <View style={styles.flexRow}>
                  <View style={styles.MimaRadio}>
                    <View style={[this.state.radiobtn === 'RefundToCustomer' ? styles.MimaRadioInside : '']} />
                  </View>
                  <View style={styles.refundCardLeft}>
                    <Text style={[styles.BlackText, styles.regularFont]}>Original Payment Mode</Text>
                    <Text style={[styles.font13, styles.regularFont]}>{this.bookingPaymentDetail.bankName} {this.bookingPaymentDetail.cardNo} {this.bookingPaymentDetail.paymentModeName}</Text>
                  </View>
                </View>
                <Text style={[styles.BlackText, styles.boldFont, styles.font16]}>{appendRupeeSymbol(Math.round(this.penalty.refundAmount))}</Text>
              </View>
              <Text style={[styles.font13, styles.regularFont, styles.refundText]}>Refund will take upto 7 working days</Text>
            </View>
          </TouchableOpacity>
        </View>
      </View>
    );
  }
}

ChooseRefundModeCard.propTypes = {
  selectedRefundMode: PropTypes.string.isRequired,
  penalty: PropTypes.object.isRequired,
  response: PropTypes.object.isRequired,
  updateSelectedRefundMode: PropTypes.func.isRequired
};


export default ChooseRefundModeCard;
