import React, { useState, useEffect} from "react";
import { View, Text, TouchableOpacity, Image, ScrollView, ActivityIndicator } from "react-native";
import { createStyles } from "./styles";
import isEmpty from 'lodash/isEmpty';
import useApi from "../../../../../Common/useApi";
import { LOBNAMES } from "../../../../../PostSalesConstant";
import { FLIGHT_ANCILLARY_HOLD_URL } from "../../../../../utils/NetworkUtils";
import { WHEEL_CHAIR_POPUP_CLOSE_TIME } from "../../FlightBookingDetailsConstant";

import BaseButton, {BUTTON_VARIANT} from "../../../../../Common/BaseButton";
import CheckBox from '../../../../../Common/Checkbox';
import { getFont } from "../../../../../PostSalesStyles";
import FlightBookingTrackingHelper from "../../helper/FlightBookingTrackingHelper";
import usePaxSelection from "./usePaxSelection";

import WheelchairTabs from './wheelChairPopupTabs';

import { getEmailId } from "../../../../FlightBookingUtil";
import { isWheelChairApplicableForSegment } from 'core-ui-lib/flight/common/FlightBookingDetails/utils/ancillaryUtil';
import { createHoldRequestForWheelChair } from 'core-ui-lib/flight/common/Ancillary/data/requestAdapter';
import { getWheelChairPaxDetails, getWheelChairAncillarySegments, getCurrSelectedSegmentArr, getWheelChairSsrCodeForSegment } from 'core-ui-lib/flight/common/Ancillary/utils/AncillaryUtils';

import { ViewState, WHEELCHAIR_TRACKING_CONSTANTS } from "../../FlightBookingDetailsConstant";
import { useTheme } from "../../../../../theme/theme.context";
import { SUCCESS_ICON, FAILED_ICON } from "../../../../FlightImageConstants";
import { getStaticData } from "../../../../../staticData/staticData";
import { OverlayProps, SelectableWheelChairPax, WheelChairResponse, SegmentAncillaryDetail } from './wheelChairTypes';


const RequestWheelChairBottomOverlay: React.FC<OverlayProps> = ({segmentAncillaryDetail, flightDetailResponse, activeSegment, reloadFlightDetails, toggleWheelChairOverlay, wheelChairMap}) => {


const { fsStyle,...fonts }  = getFont(true);
const {psTheme : theme} = useTheme();

const { wheelChairText } = getStaticData(true);
const { requestSuccessText, requestFailedText, requestLoadingText, cancelBtnText,
  confirmBtnText, wheelChairNtAvlText, wheelChairCndText } = wheelChairText || {};

const styles = createStyles<typeof theme>(theme);

const [activeTab, setActiveTab] = useState<number>(activeSegment);
const [selectedPaxMap, onCheckBoxClicked, isPaxSelected] = usePaxSelection();

const [ inProgress, response, api] = useApi(FLIGHT_ANCILLARY_HOLD_URL);

useEffect(() => {
    let timeoutRef: ReturnType<typeof setTimeout> | null = null;
    if(response) {
        const { DETAILS_PAGE, THANK_YOU_PAGE, ERROR_MSG} = WHEELCHAIR_TRACKING_CONSTANTS;
        const { success } = response as WheelChairResponse;
        if (success) {
            FlightBookingTrackingHelper.trackWheelChairLoadEvent(DETAILS_PAGE, THANK_YOU_PAGE, flightDetailResponse);
            timeoutRef = setTimeout(() => { reloadFlightDetails() }, WHEEL_CHAIR_POPUP_CLOSE_TIME);
            setViewState(ViewState.HOLD_SUCCESS);
        } else {
            FlightBookingTrackingHelper.trackWheelChairLoadEvent(DETAILS_PAGE, THANK_YOU_PAGE, flightDetailResponse, ERROR_MSG);
            setViewState(ViewState.ERROR)
            timeoutRef = setTimeout(() => { toggleWheelChairOverlay() }, WHEEL_CHAIR_POPUP_CLOSE_TIME);
        }
    }

    return () => {
        timeoutRef && clearTimeout(timeoutRef)
    }

}, [response])

useEffect(() => {
    if(inProgress) {
        setViewState(ViewState.LOADING)
    }
}, [inProgress])

const [viewState, setViewState ] = useState<string>(ViewState.DEFAULT);

const ancillarySegments: SegmentAncillaryDetail[] = getWheelChairAncillarySegments(segmentAncillaryDetail);

const currSelectedSegmentArr = getCurrSelectedSegmentArr(ancillarySegments,activeTab);

const currSelectedSegment = !isEmpty(currSelectedSegmentArr) ? currSelectedSegmentArr[0] : null;
const isWheelChairApplicableForCurrSegment: boolean = isWheelChairApplicableForSegment(currSelectedSegment);
const isWheelChairMapAvailable: boolean = getWheelChairSsrCodeForSegment(wheelChairMap, currSelectedSegment && currSelectedSegment.segmentLineNo);
const selectableWheelChairPaxArr: SelectableWheelChairPax[] = isWheelChairApplicableForCurrSegment ? getWheelChairPaxDetails(currSelectedSegment): [];

  const onHandleTabChange = (newActiveSeg: number) => {
    setActiveTab(newActiveSeg)
  };

  const handleClick = () => {

    FlightBookingTrackingHelper.trackWheelChairClickEvent('Details', 'Add_wheelchair_confirm', flightDetailResponse);

    const { bookingID: bookingId, accountDetail: { contactDetails = [] } = { } } = flightDetailResponse;

    const emailId = getEmailId(contactDetails)
    const ancillaryRequestBody = createHoldRequestForWheelChair(bookingId,emailId,selectedPaxMap, wheelChairMap);

    if(typeof api === 'object'){
        api?.httpPost({body: ancillaryRequestBody, bookingId, uniqueId: 'FLIGHT_ANCILLARY', psLob: LOBNAMES.FLIGHT})
    }

  }

  const renderWheelChairDetails = () => {

    return (
        <View style={styles.wheelchairBSWrapper}>
            <View style={styles.tabsWrapper}>
                { !isEmpty(ancillarySegments) && <ScrollView horizontal={true} showsHorizontalScrollIndicator={false} pagingEnabled={true}>
                    {ancillarySegments.map((item, index) => {
                        return (
                            <WheelchairTabs
                                key={`${index}-${item.segmentLineNo}`}
                                arrivalCityCode={item.arrivalCityCode}
                                departureCityCode={item.departureCityCode}
                                segmentLineNo={item.segmentLineNo}
                                onHandleTabChange={onHandleTabChange}
                                activeTab={activeTab}
                            />
                        );
                    })}
                </ScrollView>}
            </View>
            <View style={styles.innerWrapper}>
                {
                    (!isWheelChairApplicableForCurrSegment || !isWheelChairMapAvailable) ?
                        <Text style={[fsStyle.font16, styles.wheelChiarDeslWrapper]}>
                            {wheelChairNtAvlText}
                        </Text> :
                        (
                            <>
                                <Text style={[fsStyle.font16, styles.wheelChiarDeslWrapper]}>
                                {wheelChairCndText}
                                </Text>
                                <ScrollView style={styles.checkBoxWrapper}>
                                    {
                                        selectableWheelChairPaxArr.map((pax, index) => {
                                            const { firstName, lastName, paxLineNo, segmentLineNo, isAlreadyPurchased } = pax;
                                            const onCheckBoxChange = () => !isAlreadyPurchased && onCheckBoxClicked({ paxLineNo, segmentLineNo, firstName, lastName });
                                            return (<View style={styles.checkBox}>
                                                <CheckBox
                                                    key={`${index}-${segmentLineNo}-${paxLineNo}`}
                                                    label={`${firstName} ${lastName}`}
                                                    disabled={isAlreadyPurchased}
                                                    containerStyle={styles.checkBoxContainer}
                                                    onChange={onCheckBoxChange}
                                                    checked={isPaxSelected({ paxLineNo, segmentLineNo }) || isAlreadyPurchased}
                                                />
                                            </View>)
                                        })
                                    }
                                </ScrollView>
                            </>
                        )
                }
                <View style={styles.buttonWrapper}>
                    <TouchableOpacity activeOpacity={0.8} style={styles.cancelBtn} onPress={toggleWheelChairOverlay}>
                        <Text style={[styles.cancelBtnText, fsStyle.font14, fonts.boldFontFamily]}>{cancelBtnText}</Text>
                    </TouchableOpacity>
                    <BaseButton
                      variant={BUTTON_VARIANT.CAPSULE}
                      clickHandler={handleClick}
                      text={confirmBtnText}
                      textStyle={fsStyle.font14}
                      disabled={isEmpty(selectedPaxMap)} />
                </View>
            </View>
        </View>
    );

  }

  const renderLoadingIndicator = () => {

      return (
          <View style={styles.holdWrapper}>
              <ActivityIndicator size="large" />
              <Text style={[fonts.boldFontFamily, fsStyle.font18, styles.loadingTxt]}>{requestLoadingText}</Text>
          </View>
      )
  }

  const renderFailureOrSuccessView = (success = false) => {
      return (
          <View style={styles.holdWrapper}>
              <Image style={styles.sucessFailImg} source={success ? SUCCESS_ICON : FAILED_ICON} />
              <Text style={[fonts.boldFontFamily, fsStyle.font18]}>{success ? requestSuccessText : requestFailedText}</Text>
          </View>
      )
  }

  if(viewState === ViewState.DEFAULT){
    return renderWheelChairDetails();
  }else if(viewState === ViewState.ERROR){
    return renderFailureOrSuccessView();
  }else if(viewState === ViewState.HOLD_SUCCESS){
    return renderFailureOrSuccessView(true);
  }else if(viewState === ViewState.LOADING){
    return renderLoadingIndicator();
  }else{
    return null;
  }
  
}


export default RequestWheelChairBottomOverlay;