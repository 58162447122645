import React from 'react';
import { BackHandler, Platform } from 'react-native';
import { Actions } from 'react-native-router-flux';
import {
  listenBackPress,
  listenPageFocusEvent,
  removeBackPressListener,
  removePageFocusListener,
} from '../../Helpers/pageNavHelpers';
import ViewControllerModule from '../../Native/ViewControllerModule';
import { getRootTag } from '../../AppState/RootTagHolder';

/** This class provides some extra lifecycle methods like:
 * onPageFocused    : Page got focused (like enter and re-enter), the data can fetched/refreshed in
 *                    this method
 * onHardBackPress  : Android Hardware button is pressed, if true is returned from this method,
 *                    the back event will be overridden
 *
 * Child classes must pass pageName property to the constructor, this name should match the
 * router screen key name
 *
 * Overriding the componentDidMount should call super.componentDidMount() else these events will not
 * be triggered
 * */
class BasePage extends React.Component {
  constructor(props, pageName) {
    super(props);
    this._pageName = pageName;
  }

  componentDidMount() {
    this._registerToPageEvents();
    if (this.onPageFocused) {
      this.onPageFocused.call(this);
    }
  }

  _registerToPageEvents() {
    if (this.onPageFocused) {
      listenPageFocusEvent(this._pageName, this);
    }
    if (this.onHardBackPress) {
      listenBackPress(this._pageName, this);
    }
  }

  componentWillUnmount() {
    this._unRegisterToPageEvents();
  }

  _unRegisterToPageEvents() {
    removePageFocusListener(this._pageName);
    removeBackPressListener(this._pageName);
  }

  onBackIconPress() {
    // simulate hardware back press
    const somethingPoped = Actions.pop();
    if (somethingPoped != null && !somethingPoped) {
      if (Platform.OS === 'ios') {
        ViewControllerModule.popViewController(getRootTag());
      } else {
        BackHandler.exitApp();
      }
    }
  }
}

export default BasePage;
