import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { Text, View } from 'react-native';
import HTMLView from 'react-native-htmlview';
import { getFont } from '../../../../PostSalesStyles';
import { useTheme } from '../../../../theme/theme.context';
import { getStyle, getHtmlStyles } from './styles';
import { IBookingPendingCardData } from './types';

const BookingPendingCard = (props: IBookingPendingCardData) => {
  const fonts = getFont(true);
  const { psTheme } = useTheme();
  const { color } = psTheme;
  const styles = getStyle(fonts, color);
  const htmlStyles = getHtmlStyles(fonts, color);
  const { pendingCardData } = props;
  const { title, descriptions } = pendingCardData || {};

  const renderText = (text: string, key: number) => {
    return (
      <View style={styles.cardText} key={key}>
        <HTMLView value={`<div>${text}</div>`} stylesheet={htmlStyles} addLineBreaks={false} />
      </View>
    );
  };
  return (
    <View style={styles.cardContainer}>
      {!isEmpty(title) && renderText(title, 0)}
      {!isEmpty(descriptions) && descriptions.map(renderText)}
    </View>
  );
};

export default BookingPendingCard;
