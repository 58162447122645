import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  headerSec: {
    paddingHorizontal: 12,
    paddingTop: 16,
  },
  headingIcon: {
    width: 24,
    height: 24,
    marginRight: 10,
    backgroundColor: '#f1f1f1',
  },
  listHeading: {
    paddingLeft: 45,
    marginBottom: 20,
    flex: 1,
  },
  facilityList: {
    paddingLeft: 45,
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  listWidth: {
    width: '45%',
    marginBottom: 20,
  },
  timerIcon: {
    width: 16,
    height: 16,
    marginRight: 10,
  },
});
export default styles;
