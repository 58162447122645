import { Actions } from "../router/routerFluxMock";
import { NativeModules } from "react-native";
const stringifyData = (data: Record<string, string>) =>
  data && JSON.stringify(data);

const openTMInsuranceBookingDetail = (
  bookingId: string,
  pageData: Record<string, string>
) => {
  Actions.tmInsuranceBookingDetail({
    data: {
      BOOKING_ID: bookingId,
      pageData: stringifyData(pageData),
    },
  });
};

NativeModules.TMInsuranceModule = {
  openTMInsuranceBookingDetail,
};

