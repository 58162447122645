import { StyleSheet } from 'react-native';
import { Fonts, Theme } from '../../../../types';

const getStyles = (fonts: Fonts, theme: Theme) => {
  const { color } = theme;
  return StyleSheet.create({
    container: {
      paddingHorizontal: 16,
      paddingTop: 16,
      backgroundColor: color.white,
    },
    headingSection: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    headingText: {
      fontSize: fonts.fontSize.font24,
      ...fonts.blackFontFamily,
      color: color.black,
    },
    crossIconStyle: {
      width: 24,
      height: 24,
    },
    breakupListWrapper: {
      paddingTop: 20,
      paddingBottom: 10,
      borderBottomWidth: 1,
      borderBottomColor: color.grey23,
    },
    section: {
      paddingVertical: 6,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    paymentLabel: {
      fontSize: fonts.fontSize.font14,
      ...fonts.regularFontFamily,
      color: color.black,
    },
    paymentValue: {
      fontSize: fonts.fontSize.font14,
      ...fonts.boldFontFamily
    },
    refundAmountLabel: {
      fontSize: fonts.fontSize.font16,
      ...fonts.boldFontFamily,
      color: color.black,
    },
    refundAmountValue: {
      fontSize: fonts.fontSize.font16,
      ...fonts.boldFontFamily,
      color: color.black,
    },
    footer: {
      paddingBottom: 16,
    },
    footerText: {
      fontSize: fonts.fontSize.font12,
      ...fonts.regularFontFamily,
      color: color.greyText1
    },
    iconImag: {
      width: 18,
      height: 18,
      marginRight: 2,
    }
  });
};

export default getStyles;


export const getHtmlStyle = (fonts: Fonts, theme: Theme, htmlTextColor?: string) => {
  return StyleSheet.create({
    p: {
      fontSize: fonts.fontSize.font14,
      ...fonts.regularFontFamily,
      color: theme.color.black,
    },
    b: {
      color: htmlTextColor || theme.color.black,
      ...fonts.boldFontFamily,
    }
  })
}
