import { Platform, StyleSheet } from 'react-native';

const getStyle = (fonts, theme) =>  {
  const {color} = theme;
  return StyleSheet.create(
    {
      pageWrapper: {
        flex: 2,
        backgroundColor: color.white,
        justifyContent: 'center',
      },
      footerBtnWrapper: {
        paddingHorizontal: 10,
        paddingVertical: 20,
        marginBottom: 20,
      },
      messageContent: {
        justifyContent: 'center',
        alignItems: 'center',
        padding: 50,
      },
      addOnContent: {
        justifyContent: 'center',
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 50,
      },
      messageIcon: {
        width: 64,
        height: 39,
        marginBottom: 40,
        marginHorizontal: 110,
      },
      messageTitle: {
        fontSize: fonts.fontSize.font20,
        ...fonts.boldFontFamily,
        color: color.black28,
        marginBottom: 10,
        textAlign: Platform.select({
          ios: 'center',
          android: 'center',
          web: 'left',
        }),
      },
      messageDesc: {
        marginTop: 5,
        fontSize: 14,
        fontFamily: fonts.regular,
        color: color.gray1,
        textAlign: Platform.select({
          ios: 'center',
          android: 'center',
          web: 'left',
        }),
      },
      ErrorPageWrapper: {
        backgroundColor: color.white,
        paddingVertical: 20,
        paddingHorizontal: 40,
      },
      ErrorRoomImage: {
        width: 107,
        height: 113,
        marginBottom: 40,
      },
      mar20Bt: {
        marginBottom: 20,
      },
      CenterText: {
        alignSelf: 'center',
      },
      ErrorPageText: {
        fontSize: fonts.fontSize.font20,
        textAlign: 'center',
        lineHeight: 25,
        color: color.darkGray,
      },
      boldFont: {
        ...fonts.boldFontFamily,
      },
      AlignCenter: {
        textAlign: 'center',
      },
      lineHt22: {
        lineHeight: 22,
      },
      mar15Bt: {
        marginBottom: 15,
      },
      mainconStyle: { width: 65, height: 38, marginBottom: 30 },
      pageSection: { alignItems: 'center', backgroundColor: color.white },
      titleHeading: {
        fontSize: fonts.fontSize.font20,
        ...fonts.blackFontFamily,
        color: color.defaultTextColor,
        marginBottom: 20,
      },
      segmentCard: {
        borderWidth: 1,
        borderColor: color.greyBookedSeat,
        borderRadius: 4,
        width: '85%',
        marginBottom: 20,
      },
      segmentHeadingWrapper: {
        padding: 16,
        borderBottomColor: color.greyBookedSeat,
        borderBottomWidth: 1,
        flexDirection: 'row',
        alignItems: 'center',
      },
      logoStyle: {
        width: 24,
        height: 24,
        marginRight: 10,
      },
      innerDtls: {
        padding: 16,
      },
      dtlsRow: {
        flexDirection: 'row',
        paddingRight: 20,
      },
      greenIconStyle: {
        width: 16,
        height: 16,
        marginRight: 10,
      },
      moreInfoWrapper: {
        padding: 16,
        paddingVertical: 10,
        borderTopColor: color.greyBookedSeat,
        borderTopWidth: 1,
      },
      webCheckInInfo: {
        flex: Platform.select({
          web: 1,
        }),
      },
    }
  )
}
export default getStyle;
