import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Image, Text, View } from 'react-native';
import createStyles from './styles';
import { ONEWAY_ARROW_ICON2 } from '../../../../../../FlightImageConstants';
import { getStaticData } from '../../../../../../../staticData/staticData';
import { ThemeContext } from '../../../../../../../theme/theme.context';
import { getFont } from '../../../../../../../PostSalesStyles';
import { getChargesTxt } from '../../../../utils';

export default class FlightInfoCard extends Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);
    this.state = {
      borderHeight: 0,
    };
  }

  getBorderHeight(layout) {
    const { height } = layout;
    this.setState({
      borderHeight: height,
    });
  }

  render() {
    const fonts = getFont(true);
    const styles = createStyles(this.context.psTheme, fonts);
    const borderHeight = this.state.borderHeight - 24;
    return <View>{this._addRescheduleChargesView(borderHeight, styles)}</View>;
  }

  _addRescheduleChargesView = (borderHeight, styles) => {
    const views = [];
    if (
      this.props.response &&
      this.props.response.fareRulesList &&
      this.props.response.fareRulesList.pnrList
    ) {
      this.props.response.fareRulesList.pnrList.forEach((pnrObj) => {
        pnrObj.segments.forEach((segment, index) => {
          views.push(this.addSegmentView(segment, index, borderHeight, styles));
        });
      });
    }
    return views;
  };

  addSegmentView(segment, index, borderHeight, styles) {
    const { timeText, adultText, childText } = getStaticData();
    return (
      <View key={index} style={styles.FICardWrapper}>
        <View style={styles.FICardHeader}>
          <Text style={styles.FICardHeaderText}>{segment.from}</Text>
          <Image style={styles.onewayIcon} source={ONEWAY_ARROW_ICON2} />
          <Text style={styles.FICardHeaderText}>{segment.to}</Text>
        </View>
        <View style={styles.FICardBody}>
          <View style={styles.FICardRow}>
            <Text style={styles.FICardRowLabel}>{timeText}</Text>
            <Text style={styles.FICardRowLabel}>
              {adultText}
              {segment.childPaxPresent ? `/${childText}` : ''}
            </Text>
          </View>
          <View
            onLayout={(event) => {
              this.getBorderHeight(event.nativeEvent.layout);
            }}
            style={styles.FICardInfoWrapper}
          >
            <View style={[styles.listLeftBrd, { height: borderHeight }]} />
            {this.addPolicyView(segment, styles)}
          </View>
        </View>
      </View>
    );
  }

  addPolicyView(segment, styles) {
    const views = [];
    segment.timeFrame.forEach((timeFrameObj, index) => {
      views.push(
        <View key={index} style={styles.FICardRow}>
          <View style={[styles.FICardDot, timeFrameObj.isActive ? styles.FICardDotActive : '']} />
          <Text
            style={[styles.FICardRowText, timeFrameObj.isActive ? styles.FICardRowTextActive : '']}
          >
            {timeFrameObj.desc}
          </Text>
          <Text
            style={[styles.FICardRowText, timeFrameObj.isActive ? styles.FICardRowTextActive : '']}
          >
            {getChargesTxt(this.props.isCancelChargesPage, timeFrameObj)}
          </Text>
        </View>,
      );
    });
    return views;
  }

}
FlightInfoCard.propTypes = {
  response: PropTypes.object.isRequired,
  isCancelChargesPage: PropTypes.bool.isRequired,
};
