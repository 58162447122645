import React from 'react';
import { Image, Text, View } from 'react-native';

import { useTheme } from '../../../../../theme/theme.context';
import { getFont } from '../../../../../PostSalesStyles';
import { getStaticData } from '../../../../../staticData/staticData';
import { BG_CIRCLED_SUCCESS_ICON } from '../../../../FlightImageConstants';
import BaseButton,{ BUTTON_VARIANT } from '../../../../../Common/BaseButton';

import { goToBookingDetails } from '../../helper';

import createStyles from "./styles";

const CancellationThankYouPage = ({ responseJson, bookingId, flightCancellationType }) => {

  const { psTheme: theme } = useTheme();
  const { fsStyle, ...fonts } = getFont(true);
  const styles = createStyles(theme, fonts);
  const { seeUpdatedBookingText, 
    cancellationThankYouText: { additionalText: { cancellationInitiatedText = 'Cancellation Initiated' } = {} } = {} } = getStaticData();

  const getCancellationCommitmentMsg = () => {
    if (responseJson.lastHourCancellationMessage) {
      return responseJson.lastHourCancellationMessage;
    }

    let commitmentMessage = 'Your cancellation request is initiated';
    if (responseJson.totalRefundAmount > 0) {
      commitmentMessage += ' Your refund amount will reflect in your account in 3-12 days.';
    } else {
      commitmentMessage += ' As per deductions you are not eligible for any refund amount.';
    }
    return commitmentMessage;
  };
  return (
    <View style={styles.thankYouWrapper}>
      <Image style={styles.successIcon} source={BG_CIRCLED_SUCCESS_ICON} />
      <Text style={[styles.messageTitle, fonts.boldFontFamily, fsStyle.font20]}>
        {responseJson.heading || cancellationInitiatedText}
      </Text>
      <Text style={[styles.messageText, fsStyle.font12]}>
        {responseJson.description || getCancellationCommitmentMsg()}
      </Text>
      <BaseButton
        variant={BUTTON_VARIANT.CAPSULE}
        text={seeUpdatedBookingText || 'SEE UPDATED BOOKING'}
        clickHandler={() => goToBookingDetails(bookingId)}
        buttonStyle={[styles.CommonFillBtnGradient, fonts.blackFontFamily]}
      />
    </View>
  );
};

export default CancellationThankYouPage;
