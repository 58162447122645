import React from 'react';
import { View, Animated } from 'react-native';

import { isNullOrEmptyObject } from '../../../../../Common/commonUtil';
import CloseToDeparture from './CloseToDeparture';
import MakeCorrection from './MakeCorrection';
import { showShortToast } from 'packages/legacy-commons/Common/Components/Toast';
import CommonOverlayMessage from '../../../../../Common/CommonOverlayMessage';
import commonOverlayMessageStyles from '../../../../../Common/CommonOverlayMessage/OverlayMessageCss';

import createSyles from './NameChangeBottomSheetCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { useTheme } from '../../../../../theme/theme.context';
import { getFont } from '../../../../../PostSalesStyles';
import { isEmpty } from 'lodash';
import { getNameCorrectionDetail } from '../../data/dataAdapter';

function NameChangeBottomSheet({ onClose, data, onContinueClicked, responseJson }) {
  const { psTheme: theme } = useTheme();
  const fonts = getFont(true);
  const styles = createSyles(theme, fonts);
  if (isEmpty(responseJson.nameCorrectionDetails?.passengerList)) {
    showShortToast('Name change not allowed for this booking');
    return null;
  }
  const {
    closeToDepartureInfo,
    firstLastNameRepeatedDetailCard,
    submitRequestData: nameCorrectionSubmitRequestCard,
    nameCorrectionRulesDetailCard,
  } = getNameCorrectionDetail(responseJson);

  const loadNameChangeScreen = () => {
    if (!isNullOrEmptyObject(closeToDepartureInfo)) {
      return (
        <CloseToDeparture
          airlineContactInfo={closeToDepartureInfo}
          handleBottomOverlay={onClose}
          responseJson={responseJson}
        />
      );
    }
    return (
      <MakeCorrection
        onClose={onClose}
        data={{
          nameCorrectionRulesDetailCard,
          firstLastNameRepeatedDetailCard,
          nameCorrectionSubmitRequestCard,
        }}
        onContinueClicked={onContinueClicked}
        responseJson={responseJson}
      />
    );
  };

  return (
    <CommonOverlayMessage
      handleBottomOverlay={onClose}
      overlayContentStyle={[commonOverlayMessageStyles.overlayContent, { paddingTop: 10 }]}
      isBorderRadius={true}
      content={
        <Animated.View>
          <View style={AtomicCss.marginBottom10}>
            <View style={styles.overlayTopline} />
          </View>
          {loadNameChangeScreen()}
        </Animated.View>
      }
    />
  );
}

export default NameChangeBottomSheet;
