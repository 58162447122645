import React from "react";
import {View} from "react-native";
import Actions from '../../../navigation/postSalesNavigation';
import _ from 'lodash';
import BasePage from '@mmt/legacy-commons/Common/navigation/BasePage';
import styles from '../../styles/SelfDriveMamiCss';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import ProgressView from '@mmt/legacy-commons/Common/Components/Progress/ProgressView';
import SimpleHeader from '@mmt/legacy-commons/Common/Components/Header/SimpleHeader';
import NoInternetView from '@mmt/legacy-commons/Common/Components/Error/NoInternetView';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import {isNetworkAvailable} from '@mmt/legacy-commons/Common/utils/AppUtils';
import StickyHeader from "../../../hotel/details/components/StickyHeader";
import CancellationInfoCard from "./CancellationInfoCard";
import ZcConstants from "../../utils/ZoomCarConstants";
import {getSubTitle} from "../../utils/SelfDriveBookingDetailUtil";
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import {getCommonHeaders, SELF_DRIVE_CANCELLATION_URL} from "../../../utils/NetworkUtils";
export const SELF_DRIVE_CANCELLATION_DETAILS_PAGE = "Self Drive Cancellation: Cancellation details";
import { HEADER_ICON } from '../../SelfDriveImageConstant'
import PostSalesBasePage from "apps/post-sales/src/Common/PostSalesBasePage";

export default class SelfDriveCancellationDetails extends PostSalesBasePage {

    constructor(props) {
        super(props, SELF_DRIVE_CANCELLATION_DETAILS_PAGE);
        this.trackingPageName = SELF_DRIVE_CANCELLATION_DETAILS_PAGE;
        this.bookingId = this.props.response.bookingId;
        this.mmtAuth = null;
        this.loggingTrackingInfo = null;
        this.penaltyResponse = null;
        this.state = {
            responseJson: null,
            viewState: ViewState.LOADING,
            errorText: "Something went wrong, Please try again!"
        };
    }

    render() {
        return (
            <View style={[styles.flex1, styles.whitebg]}>
                {this.state.viewState === ViewState.LOADING &&
                this.renderProgressView()}
                {this.state.viewState === ViewState.NO_INTERNET &&
                this.renderNoNetworkView()}
                {this.state.viewState === ViewState.ERROR && this.renderErrorView()}
                {this.state.viewState === ViewState.SHOW_DETAIL && this.renderPage()}
            </View>
        );
    }

    renderProgressView = () => <ProgressView message="Loading Details..."/>;

    renderNoNetworkView = () => (
        <View style={{flex: 1}}>
            <SimpleHeader title="Back" iconPress={this.goBack}/>
            <NoInternetView
                onRetry={() => {
                    this.setState({viewState: ViewState.LOADING});
                    this.fetchJSONAsync().then(r => "");
                }}
            />
        </View>
    );

    renderErrorView = () => (
        <View style={{flex: 1}}>
            <SimpleHeader title="Back" iconPress={this.goBack}/>
            <ErrorView
                message={this.state.errorText}
                showRetry
                onRetry={() => {
                    this.setState({viewState: ViewState.LOADING});
                    this.fetchJSONAsync().then(r => "");
                }}
            />
        </View>
    );

    renderPage = () => (
        <View style={styles.whitebg}>
            <StickyHeader
                iconSrc={HEADER_ICON}
                title="Self Drive Cancellation"
                headerColor={["#ffffff", "#ffffff"]}
                titleStyle={[styles.DefaultText, styles.blackFont]}
                subTitle={getSubTitle(this.props.response)}
                subTitleStyle={[styles.DefaultText, styles.regularFont, styles.font12]}
                onBackPress={this.goBack}
            />
            <CancellationInfoCard
                response={this.props.response}
                penalty={this.penaltyResponse}
                fareBreakUpObject={this.fareBreakUpObject}
                deductionComponent={this.getDeductions}
                paidComponent={this.getPaidBreakup}
                hideBreakup={true}
            />
        </View>
    );

    componentDidMount() {
        this.fetchJSONAsync().then(r => "");
    }

    fareBreakUpObject = () => {
        let totalRefundAmount = 0;
        let totalCancelationCharges = 0;
        let totalPaidAmount = 0;
        let refundText = "No Refund";
        if (this.cancelPreviewObj && this.cancelPreviewObj.status) {
            totalRefundAmount = parseInt(Math.round(this.cancelPreviewObj.refundBreakUp.total_refund.amount));
            totalCancelationCharges = parseInt(Math.round(this.cancelPreviewObj.cancellationBreakup.totalPenalty.amount));
            totalPaidAmount = Math.round(this.cancelPreviewObj.priceBreakup.totalPaid.amount);
            refundText = this.cancelPreviewObj.refundBreakUp.total_refund.label;
        }
        return {totalRefundAmount, totalCancelationCharges, totalPaidAmount, refundText};
    };

    getDeductions = () => {
        const components = [];

        if (
            this.cancelPreviewObj &&
            this.cancelPreviewObj.cancellationBreakup
        ) {
            const cancellationamountdetails = this.cancelPreviewObj.cancellationBreakup;

            Object.keys(cancellationamountdetails).forEach(function (key) {
                let segment = {};
                let value = cancellationamountdetails[key];
                let code = key.split(" ")[0];

                if ((value.amount > 0.0) && key.toLowerCase() !== ZcConstants.TOTAL_PENALTY) {
                    segment.label = value.label;
                    segment.value = (value.amount > 0.0) ? '- ₹ ' + value.amount : ' ₹ ' + value.amount + ' ';
                    components.push(segment);
                }
            });
        }

        return components;
    };

    getPaidBreakup = () => {
        const components = [];
        let value;

        if (this.cancelPreviewObj && this.cancelPreviewObj.priceBreakup) {
            const paidBreakup = this.cancelPreviewObj.priceBreakup;
            Object.keys(paidBreakup).forEach(key => {
                value = paidBreakup[key];
                if (key.toLowerCase() !== ZcConstants.TOTAL_PAID && Math.round(value.amount) > 0) {
                    const segment = {};
                    segment.label = key.toLowerCase() === ZcConstants.DISCOUNT ? `${value.label}` : value.label;
                    segment.value = `${value.discount ? "-" : ""} ₹ ${Math.round(value.amount)} `;
                    segment.key = key;
                    components.push(segment);
                }
            });
        }

        return components;
    };

    async fetchJSONAsync() {
        try {
            const hasNetwork = await isNetworkAvailable();
            if (!hasNetwork) {
                this.setState({viewState: ViewState.NO_INTERNET});
                return;
            }

            const {mmtAuth, loggingTrackingInfo} = await HotelBookingModule.getRequestHeader(this.props.bookingId, 'SelfDrive_Cancellation_Preview_Details');
            const request = {
                bookingId: this.bookingId,
                action: 'Preview',
                fullCancellation: {
                    refundAdjustment: 'RefundToCustomer'
                }
            };
            const response = await fetch(SELF_DRIVE_CANCELLATION_URL, {
                method: "POST",
                body: JSON.stringify(request),
                headers: await getCommonHeaders(mmtAuth, loggingTrackingInfo)
            });
            const body = await response.json()
            if (!_.isEmpty(body.errorText) || !_.isEmpty(body.errorResponse) || body.errorCode === 500) {
                this.setState({
                    errorText: body.errorText,
                    viewState: ViewState.ERROR
                });
            } else {
                this.penaltyResponse = body;
                this.cancelPreviewObj = body;
                this.errorText = this.cancelPreviewObj.errorText;
                this.fareBreakUpObject = this.fareBreakUpObject();
                this.getDeductions = this.getDeductions();
                this.getPaidBreakup = this.getPaidBreakup();
                this.cancelButtonText = this.cancelPreviewObj && this.cancelPreviewObj.refundAmount === 0 ? "INITIATE CANCELLATION" : "SELECT REFUND MODE";
                if (this.penaltyResponse !== null && this.penaltyResponse !== undefined) {
                    this.setState({
                        viewState: ViewState.SHOW_DETAIL
                    });
                }
            }
        } catch (error) {
            this.setState({viewState: ViewState.ERROR});
        }
    }

    goBack = () => {
        Actions.pop();
    };
}
