import React from 'react';
import { fligtNewTrackingOmniture } from 'apps/post-sales/src/flights/v2/utils/trackingUtil';
import RenderFlightDetailPage from '../details/Components/RenderFlightDetailPage';

interface RefundTrackerProps {
  BOOKING_ID?: string;
  bookingId?: string;
  cancelRequests?: string[];
  updateRefundReceivedStatusUrl?: string;
}

const RefundTrackerPage = (props: RefundTrackerProps) => {
  const bookingId = props.BOOKING_ID || props.bookingId || '';
  const extraData = {
    bookingId: bookingId,
    lobCode: 'LOB932463',
    lobName: 'FLIGHTS',
    updateRefundReceivedStatusUrl: props.updateRefundReceivedStatusUrl,
  }
  const { onLoad, onClick, onError } = fligtNewTrackingOmniture();
  const renderFlightPageProps = {bookingId, onLoad, onClick, onError, pageRequested: 'REFUND_TRACKER', extraPropsData: extraData};
  return (
    <RenderFlightDetailPage {...renderFlightPageProps} />
  );
};

export default RefundTrackerPage;
