/* eslint-disable max-len */
import React from 'react';
import Actions from '../../navigation/postSalesNavigation';
import PropTypes from 'prop-types';
import CustomSafeAreaView from '@mmt/legacy-commons/Common/Components/CustomSafeAreaView';
import {Alert, Platform, ScrollView, StyleSheet, Text, View} from 'react-native';
import StickyHeaderBack from '../components/StickyHeader/StickyHeaderBack';
import ReviewStepHeader2 from '../components/ReviewStepHeader/ReviewStepHeader2';
import ReviewStepHeader from '../components/ReviewStepHeader/ReviewStepHeader';
import RecBlueBtn from '../components/buttons/RecBlueBtn';
import CancellationBreakup from '../components/RefundBreakUp/CancellationBreakup';
import CancellationBreakupDtls from '../components/RefundBreakUp/CancellationBreakupDtls';
import AtomicCss from '../commonStyles/AtomicCss';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import ProgressView from '@mmt/legacy-commons/Common/Components/Progress/ProgressView';
import OverlayAlert from '../components/OverlayAlert/OverlayAlert';
import {BUS_BOOKING_DETAILS_URL, BUS_CANCELLATION_URL, getCommonHeaders} from '../../utils/NetworkUtils';
import {isNetworkAvailable} from '@mmt/legacy-commons/Common/utils/AppUtils';
import {showShortToast} from '@mmt/legacy-commons/Common/Components/Toast';
import {cancellablePaxList} from '../utils/BusBookingDetailUtil';
import SimpleHeader from '@mmt/legacy-commons/Common/Components/Header/SimpleHeader';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import BusBookingTrackingHelper from '../BusBookingTrackingHelper';
import {appendCurrencyAndRound} from '../../utils/PostSaleUtil'
import {
  CancellationPreviewRequest as CancelPreviewRequest, DEAFULTREFUNDMODE,
  FETCHCANCELLATIONPENALTY,
  POST
} from '../../PostSalesConstant';
import {
  getCancelRequestKeyName,
  removeKeyFromAsyncStorage,
  storeObjInAsyncStorage
} from '../../Common/commonUtil';
import ReasonForCancellation from "./ReasonForCancellation";
import _ from 'lodash';
import {getTheSelectedData} from '../utils/BusBookingDetailUtil'
import BasePage from '../../Common/PostSalesBasePage';
import { label } from '@mmt/post-sales/src/staticData/staticData';
import { ps_colors } from '../../PostSalesStyles';

export const BUS_CANCELLATION_DETAILS_PAGE = 'Cancellation: Cancellation details';
const fareBreakUpObject = null;
class ReviewRefundCharges extends BasePage {
    static navigationOptions = {
      header: null
    };
    constructor(props) {
      super(props, 'ReviewRefundCharges');
      this.trackingPageName = BUS_CANCELLATION_DETAILS_PAGE;
      this.state = {
        viewState: ViewState.LOADING,
        showAlert: false,
        cancellationReasonList : this.props.bookingDetails && this.props.bookingDetails.cancellationReasonList,
        cancellationReason : null,
        enableInitiateCancellationCta : (!(this.props.bookingDetails && this.props.bookingDetails.cancellationReasonList)),
        iconClicked : false
      };
      this.cancelledLineNos = null;
      this.errorText = null;
      this.cancelPreviewObj = null;
      this.cancellationAssured = this.props.bookingDetails.busBookingInfo.cancellationAssured;
      this.zeroCancellationMessage = this.props.bookingDetails.busBookingInfo.zeroCancellationMessage;
      this.cancelButtonText = label('bus.cancellation.selectRefundMode');
      this.cancelRequestKey = getCancelRequestKeyName(CancelPreviewRequest.BUS, this.props.bookingDetails.bookingId);
    }

    componentDidMount() {
      removeKeyFromAsyncStorage(this.cancelRequestKey);
      this.fetchCancelPreviewJSON();
    }

    iconClickHandler = () => {
      this.setState((prevState) => ({ iconClicked: !prevState.iconClicked }));
    }

    render() {
      if (this.state.viewState === ViewState.SHOW_DETAIL) {
        BusBookingTrackingHelper.trackLoadEvent(this.getPageName(), this.bookingDetails);
      }
      return (
        <View style={{flex: 1}}>
          {Platform.OS === 'ios' && <CustomSafeAreaView />}
          {this.state.viewState === ViewState.LOADING && this.renderProgressView()}
          {this.state.viewState === ViewState.SHOW_DETAIL && this.renderMainView()}
          {this.state.viewState === ViewState.ERROR && this.renderErrorView()}
          {Platform.OS === 'ios' && <CustomSafeAreaView positionTop={false} />}
        </View>
      );
    }

    renderErrorView = () => (
      <View style={{flex: 1}}>
        <SimpleHeader
          title={label('backText')}
          iconPress={this.goBackToDetails}
        />
        <ErrorView
          message={label('bus.cancellation.noBookingFoundForPNR')}
        />
      </View>
    );

    getPageName = () => {
      let pageName = '';
      if (this.props.isFullCancellation) {
        pageName = 'FullCancellation';
      } else {
        pageName = 'PartialCancellation';
      }
      return pageName;
    }

    renderZCMessage = (zcMessage) => (
      <View style={[styles.ZCMessage, AtomicCss.elevation1, AtomicCss.whiteBg]}>
        <Text style={[styles.impInfoGreen]}>
        {zcMessage}
        </Text>
      </View>
    )

    getTheSelectedData = (selectedReason,index,otherInfo) => {
      let detail = getTheSelectedData(selectedReason,index,otherInfo, this.state.cancellationReasonList, this.state.cancellationReason);
      this.setState({cancellationReasonList : detail.newCancellationRules, cancellationReason : detail.cancellationReason, enableInitiateCancellationCta : true})
    }

    renderMainView = () => (

      <View style={styles.CancellationWrapper}>
        <StickyHeaderBack
          busDetails={this.props.bookingDetails ? this.props.bookingDetails : null}
        />
        <ScrollView style={AtomicCss.greyBg}>
          { this.props.isFullCancellation === true && <ReviewStepHeader currentIndex={1} />}
          { this.props.isFullCancellation === false && <ReviewStepHeader2 currentIndex={2} />}
          {this.props.isFullCancellation === false && this.props.selectedPax &&
                this.props.selectedPax.length > 0 &&
                <CancellationBreakup passengers={this.props.selectedPax} />}
          {this.props.isFullCancellation === true && this.renderFullCancelHeader()}
          {this.cancellationAssured
          && this.renderZCMessage(this.zeroCancellationMessage)}
          <CancellationBreakupDtls
            fareBreakUpObject={this.fareBreakUpObject}
            selectedPax={this.props.selectedPax}
            isFullCancellation={this.props.isFullCancellation}
            isZCBooking = {this.cancellationAssured}
            deductionComponent={this.getDeductions}
            paidComponent={this.getPaidBreakup}
            hideBreakup={this.checkShowHidePriceBreakupButton}
            zcSubText={this.cancelPreviewObj.zeroCancellationWaiverText}
            cancelPreviewObj={this.cancelPreviewObj}
            iconClickHandler={this.iconClickHandler}
            iconClicked={this.state.iconClicked}
          />
          {
            this.props.bookingDetails && this.props.bookingDetails.cancellationReasonList && !_.isEmpty(this.props.bookingDetails.cancellationReasonList) &&
            <ReasonForCancellation reasons={this.state.cancellationReasonList} getTheSelectedData={this.getTheSelectedData}/>
          }
        </ScrollView>
        <View style={this.cancelPreviewObj.mmtBlackCancellationText ? styles.footer : {}}>

          {!_.isEmpty(this.cancelPreviewObj.mmtBlackCancellationText) && <Text style={styles.mmtBlackMsg}>{this.cancelPreviewObj.mmtBlackCancellationText}</Text>}
          <RecBlueBtn btntext={this.cancelButtonText} isDisabled={!this.state.cancellationReason && this.cancellationAssured ? true : false} onPressHandler={this.onClickHandler} />
        </View>
            {this.state.showAlert &&
            <OverlayAlert
              positiveBtnText={label('bus.cancellation.yesCancel')}
              negativeBtnText={label('bus.cancellation.noGoBack')}
              positiveBtnOnTap={this.cancelBookingBtnTapped}
              negativeBtnOnTap={this.backButtonTapped}
              titleText={`${label('bus.cancellation.areYouSure')}${'\n'}`}
              subTitleText={label('bus.cancellation.zeroRefundMsg')}
              bgTapAction={this.backButtonTapped}
              />
            }
      </View>

    )

    renderFullCancelHeader = () => (
      <View style={[styles.PartCancellationHead, AtomicCss.elevation1]}>
        <Text style={[AtomicCss.blackFont, AtomicCss.blackText, AtomicCss.font16]}>
                {label('bus.cancellation.refundHeading')}
        </Text>
      </View>
    )

    renderProgressView = () => (<ProgressView message={label('bus.cancellation.retrieving_charges')} style={styles.progressContainer} />);

    fareBreakUpObject = () => {
      let totalRefundAmount = 0;
      let totalCancelationCharges = 0;
      let totalPaidAmount = 0;

      if (this.cancelPreviewObj && this.cancelPreviewObj.status) {
        totalRefundAmount = parseInt(Math.round(this.cancelPreviewObj.refundAmount), 10);
        totalCancelationCharges = parseInt(Math.round(this.cancelPreviewObj.penaltyAmount), 10);
        totalPaidAmount = Math.round(this.cancelPreviewObj.responsePriceBreakup.totalpaid.amount);
      }

      return {totalRefundAmount, totalCancelationCharges, totalPaidAmount};
    }


  getDeductions = () => {
    const components = [];

    if (this.cancelPreviewObj && this.cancelPreviewObj.refundDetailPreview && this.cancelPreviewObj.status) {
      const deductions = this.cancelPreviewObj.refundDetailPreview.cancellation_penalty_break_up;
      Object.keys(deductions).forEach((key) => {
        const value = deductions[key];
        if (key.toLowerCase() !== 'total_penalty') {
          if(this.cancellationAssured && key.toLowerCase() === 'waiver_amount') {
            const segment = {};
            segment.label = value.label;
            segment.strikedValue = appendCurrencyAndRound(value.strikedAmount);
            segment.value = appendCurrencyAndRound(value.amount);
            segment.order = value.order;
            segment.labelId = key.toLowerCase();
            components.push(segment);
          }
          else if(Math.round(value.amount) !== 0) {
            const segment = {};
            segment.label = (key.toLowerCase() === 'e_coupon') ? (`${value.label} (${value.code})`) : value.label;
            segment.value = `- ${appendCurrencyAndRound(value.amount)}`;
            segment.order = value.order;
            segment.labelId = key.toLowerCase();
            components.push(segment);
          }
        }
      });
    }


    return components;
  }


  getPaidBreakup = () => {
    const components = [];
    let value;

    if (this.cancelPreviewObj && this.cancelPreviewObj.responsePriceBreakup && this.cancelPreviewObj.status) {
      const paidBreakup = this.cancelPreviewObj.responsePriceBreakup;
      Object.keys(paidBreakup).forEach((key) => {
        value = paidBreakup[key];
        if (key.toLowerCase() !== 'totalpaid' && Math.round(value.amount) !== 0) {
          const segment = {};
          segment.label = (key.toLowerCase() === 'ecoupon') ? (`${value.label} (${value.code})`) : value.label;
          segment.value = `${value.discount ? '-' : ''} ₹ ${Math.round(value.amount)} `;
          components.push(segment);
        }
      });
    }

    return components;
  }

  checkShowHidePriceBreakupButton = () => {
    if (this.props.bookingDetails && this.props.bookingDetails.busBookingInfo.busDetail.operator.toLowerCase() === 'apsrtc') {
      return false;
    }
    return true;
  }

  totalPaxCount = () => {
    let totalPaxCount = 0;
    const paxList = cancellablePaxList(this.props.bookingDetails);
    if (paxList) {
      totalPaxCount = paxList.length;
    }
    return totalPaxCount;
  }

  backButtonTapped = () => {
    this.setState({showAlert: false});
    BusBookingTrackingHelper.trackClickEvents('Cancellation Screen Shown', 'Cancellation_NotConfirmed');
  }

  pushCancelView = () => {
    Actions.busBookingCancelled({
      isFullCancellation: this.props.isFullCancellation,
      cancelledPaxLineId: this.getLinenos(),
      totalPaxCount: this.totalPaxCount(),
      bookingID: this.props.bookingDetails.bookingId,
      mmtAuth: this.props.mmtAuth,
      loggingTrackInfo: this.props.loggingTrackInfo,
      bookingDetails: this.props.bookingDetails,
      refundAmount: this.cancelPreviewObj.refundAmount,
      selectedRefundMode: DEAFULTREFUNDMODE,
      msrTicketList: this.props.bookingDetails.msrTicketsForBooking && this.props.bookingDetails.msrTicketsForBooking.ticketsList,
      reason: this.state.cancellationReason
    });
  }

  cancelBookingBtnTapped = () => {
    this.backButtonTapped();
    this.pushCancelView();
    BusBookingTrackingHelper.trackClickEvents('Cancellation Screen Shown', 'Cancellation_Confirmed');
  }

    onClickHandler = () => {
      this.setState({iconClicked: false});

    if (this.cancelPreviewObj && this.cancelPreviewObj.refundAmount === 0){
      BusBookingTrackingHelper.trackClickEvent(this.getPageName(), this.props.bookingDetails, 'IntiateCancellation_ZeroRefund');
      this.setState({showAlert: true});
      }
      else{
        BusBookingTrackingHelper.trackClickEvent(this.getPageName(), this.props.bookingDetails, 'Chooserefundmode');
        Actions.reviewBusRefundMode({
          bookingDetails: this.props.bookingDetails,
          isFullCancellation: this.props.isFullCancellation,
          previewObject: this.cancelPreviewObj,
          mmtAuth: this.props.mmtAuth,
          loggingTrackInfo: this.props.loggingTrackInfo,
          cancelledLineNos: this.getLinenos(),
          cancellationReason: this.state.cancellationReason
        });
      }
    }

    async fetchCancelPreviewJSON() {
      try {
        const hasNetwork = await isNetworkAvailable();
        if (!hasNetwork) {
          Alert.alert(label('bus.checkInternetConnnection'));
          return;
        }
        const commonHeaders = await getCommonHeaders(this.props.mmtAuth, this.props.loggingTrackInfo);

        const request = {
          bookingId: this.props.bookingDetails.bookingId,
          preview: true,
          refundOption: DEAFULTREFUNDMODE,
          lineNumbers: this.getLinenos()
        };
        await storeObjInAsyncStorage(this.cancelRequestKey, request);
        const response = await fetch(BUS_CANCELLATION_URL, {
          method: POST,
          body: JSON.stringify(request),
          headers: commonHeaders
        });

        if (response.ok) {
          const responseBody = await response.json();
          this.cancelPreviewObj = responseBody;
          this.errorText = this.cancelPreviewObj.errorText;
          this.fareBreakUpObject = this.fareBreakUpObject();
          this.getDeductions = this.getDeductions();
          this.getPaidBreakup = this.getPaidBreakup();
          this.cancelButtonText = (this.cancelPreviewObj && this.cancelPreviewObj.refundAmount === 0) ? label('bus.cancellation.initiateCancellation') : label('bus.cancellation.selectRefundMode');
          this.decideState();
          BusBookingTrackingHelper.trackLoadEvent(this.trackingPageName, this.props.bookingDetails, FETCHCANCELLATIONPENALTY);
        } else {
          BusBookingTrackingHelper.trackErrorEvent(this.trackingPageName);
          showShortToast(label('bus.somethingWentWrong'));
          Actions.pop();
        }
      } catch (error) {
        BusBookingTrackingHelper.trackErrorEvent(this.trackingPageName);
        showShortToast(label(this.errorText ? this.errorText : 'bus.somethingWentWrong'));
        Actions.pop();
      }
    }

  decideState = () => {
    if (this.cancelPreviewObj.errorText &&
        ((this.cancelPreviewObj.errorText.toString().includes('Partial cancellation is not allowed')) ||
            (this.cancelPreviewObj.errorText.toString().includes('PARTIAL-CANCELLATION-NOT-ALLOWED')) )) {
      this.errorText = label('bus.cancellation.noPartialCancellationMsg');
      this.showNoCancelScreen();
    } else if (this.cancelPreviewObj.errorText && this.cancelPreviewObj.errorText.toString().includes('Primary passenger cancellation is not allowed')) {
      const custName = `${this.props.bookingDetails.primaryCustomerDetails.firstName} ${this.props.bookingDetails.primaryCustomerDetails.lastName}`;
      this.errorText = label('bus.cancellation.primaryPassCancelNotAllowedMsg', { customerName: custName.trim() });
      this.showNoCancelScreen();
    } else if (this.cancelPreviewObj.errorText && this.cancelPreviewObj.errorText.toString().includes('Multiple partial cancellations are not allowed for Booking')) {
      this.errorText = label('bus.cancellation.multiplePartialNotAllowed');
      this.showNoCancelScreen();
    } else if (this.cancelPreviewObj.errorText && this.cancelPreviewObj.errorText.toString().includes('No booking record found for PNR')) {
      this.setState({viewState: ViewState.ERROR});
    } else if (this.cancelPreviewObj.errorText && !this.cancelPreviewObj.status && !(this.cancelPreviewObj.errorText.toString().includes('Response is null') || this.cancelPreviewObj.errorText.toString().includes('coachCancellationPenalty')) ) {
      showShortToast(this.cancelPreviewObj.errorText);
      Actions.pop();
    } else if ((this.cancelPreviewObj.errorText && (this.cancelPreviewObj.errorText.toString().includes('Response is null') || this.cancelPreviewObj.errorText.toString().includes('coachCancellationPenalty'))) ||
                !this.cancelPreviewObj.status ) {
      showShortToast(label('bus.cancellation.cantFetchRefundChrg'));
      Actions.pop();
    } else {
      this.setState({viewState: ViewState.SHOW_DETAIL});
    }
  }

  createData = () => {
    const errortexts = this.errorText;
    return {errortexts};
  }

  showNoCancelScreen = () => {
    Actions.pop();
    this.props.onError(this.createData());
  }

  getLinenos = () => {
    const linenos = [];
    this.props.selectedPax.forEach((paxDetails) => {
      linenos.push(paxDetails.lineno.toString());
    });
    return linenos;
  }

  goBack = () => {
    Actions.pop();
    // logAction(`${this.pageName}onBackPress`);
  };

  goBackToDetails = () => {
    super.onBackIconPress();
    BusBookingTrackingHelper.trackClickEvents('Cancellation Screen Shown', 'Cancellation_NotConfirmed');
  }
}

ReviewRefundCharges.propTypes = {
  bookingDetails: PropTypes.object.isRequired,
  selectedPax: PropTypes.array,
  mmtAuth: PropTypes.string.isRequired,
  loggingTrackInfo: PropTypes.string.isRequired,
  isFullCancellation: PropTypes.bool.isRequired,
  onError: PropTypes.func
};

ReviewRefundCharges.defaultProps = {
  selectedPax: null
};

const styles = StyleSheet.create({
  CancellationWrapper: {
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: ps_colors.grayBg
  },
  ZCMessage: {
    backgroundColor: ps_colors.white,
    paddingVertical: 10,
    paddingLeft: 15,
    paddingRight: 15,
    borderBottomWidth: 1,
    borderBottomColor: ps_colors.grey8
  },
  impInfoRed:{
    color: ps_colors.red,
    fontSize: 14
  },
  impInfoGreen:{
    color: ps_colors.green,
    fontSize: 14
  },
  PartCancellationHead: {
    backgroundColor: ps_colors.white,
    paddingVertical: 18,
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: ps_colors.grey8
  },
  mmtBlackMsg: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingRight: 16,
    paddingLeft: 16,
    marginBottom: 8,
    color: ps_colors.lightYello,
    backgroundColor: ps_colors.creamWhite,
    fontSize: 12,
    ...Platform.select({
      ios: {
        shadowColor: ps_colors.black,
        shadowOffset: {width: 0, height: 2},
        shadowOpacity: 0.2,
        shadowRadius: 5
      },
      web: {
        shadowColor: ps_colors.black,
        shadowOffset: {width: 0, height: 2},
        shadowOpacity: 0.2,
        shadowRadius: 5,
        borderWidth: 1,
      },
      android: {
        elevation: 6
      }
    }),
  },
  footer: {
    backgroundColor: ps_colors.white,
  }
});

export default ReviewRefundCharges;
