import React, { useEffect } from 'react';
import { View, Text, TouchableOpacity, Image, StyleSheet, TextStyle, ViewStyle, ImageStyle } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { ArrowDown, roundTrip } from '../../../../CabImageConstants';
import getStyles from './BookingInfoDetailsCss';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import CabBookingDetailsConstant, { CLICK_EVENT, PAGE_NAME } from '../../../../CabBookingDetailsConstant';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { cabAssured } from '../../../../CabImageConstants';
import CabBookingTrackingHelper from 'apps/post-sales/src/cab/CabBookingTrackingHelper';
import isEmpty from 'lodash/isEmpty';

interface Props {
  tripTypeText?: string;
  dest1Trunket?: string;
  dest2Trunket?: string;
  dateTime?: string;
  isRoundTrip?: boolean;
  returnDateTime?: string;
  internationalPickupTimeText?: string;
  bookingInfoClick: (arg: string) => void;
  isTimeUpdated: boolean;
  timeUpdatedText?: string;
  cabReassignedText?: string;
  rescheduleRequestedData?: {
    text?:string;
    color?:string;
    imageUrl?:string
  }
}

const CollapsedBookingInfo: React.FC<Props> = ({
  tripTypeText,
  dest1Trunket,
  dest2Trunket,
  dateTime,
  isRoundTrip,
  returnDateTime,
  internationalPickupTimeText,
  bookingInfoClick,
  isTimeUpdated,
  timeUpdatedText,
  cabReassignedText,
  rescheduleRequestedData
}) => {
  const { psTheme } = useTheme();
  const { fsStyle, ...fonts } = getFont();
  const { color } = psTheme;
  const styles = getStyles(color, fonts);

  useEffect(() => {
    if(cabReassignedText){
      CabBookingTrackingHelper.trackClickEvent(PAGE_NAME.TRIP_DETAILS, CLICK_EVENT.CAB_REASSIGNED_MSG_SHOWN);
    }
  }, [])

  return (
    <>
      {!!tripTypeText && (
        <View style={AtomicCss.marginVertical5}>
          <Text style={styles.triptype}>{tripTypeText}</Text>
        </View>
      )}
      <TouchableOpacity
        onPress={() => bookingInfoClick(CabBookingDetailsConstant.LOCATION_EXPEND_CLICK)}
      >
        <View style={[styles.infoRowSection, AtomicCss.marginBottom5]}>
          {!!dest1Trunket && (
            <Text style={styles.cityHeading}>
              {dest1Trunket}
            </Text>
          )}
          <View style={styles.dotWrapper}>
            <View style={styles.dot}></View>
            <View style={styles.borderLine}></View>
            <View style={styles.dotFilled}></View>
          </View>
          {!!dest2Trunket && (
            <Text style={styles.cityHeading}>
              {dest2Trunket}
            </Text>
          )}
        </View>
        <View style={[AtomicCss.flexRow, AtomicCss.marginVertical5, AtomicCss.alignCenter]}>
          {!!dateTime && <Text style={styles.dateText}>{dateTime}</Text>}
          {isRoundTrip && <Image style={styles.roundTripStyle} source={roundTrip} />}
          {!!returnDateTime && isRoundTrip && <Text style={styles.dateText}>{returnDateTime}</Text>}
          {!isEmpty(rescheduleRequestedData) && !!rescheduleRequestedData.text && (
            <View
              style={[
                styles.timeUpdatedTag,
                AtomicCss.marginLeft8,
                AtomicCss.flexRow,
                AtomicCss.alignCenter,
                {borderColor: rescheduleRequestedData.color}
              ]}
            >
              {!!rescheduleRequestedData.imageUrl && <Image style={styles.assuranceImg} source={{uri: rescheduleRequestedData.imageUrl}} />}
              {!!rescheduleRequestedData.text && <Text style={[styles.timeUpdatedText, AtomicCss.paddingHz3, {color: rescheduleRequestedData.color}]}>{rescheduleRequestedData.text}</Text>}
            </View>
          )}
        </View>
        {!!internationalPickupTimeText && (
          <Text style={styles.footerText}>{internationalPickupTimeText}</Text>
        )}

        {!!cabReassignedText && (
          <Text style={styles.footerText}>{cabReassignedText}</Text>
        )}
        <View style={styles.arrowStyleWrapper}>
          <Image style={styles.arrowstyle} source={ArrowDown} />
        </View>
      </TouchableOpacity>
    </>
  );
};

export default CollapsedBookingInfo;
