import React from 'react';
import PropTypes from 'prop-types';
import { Text, View } from 'react-native';
import createStyles from './ReasonBookingCss';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const ReasonBooking = (props) => {
  const {psTheme: theme} = useTheme();
  const fonts = getFont(true);
  const styles = createStyles(theme,fonts);
  const rd = props.rescheduleReason;
  return (
    <View>
      <Text style={styles.cardHead}>{rd.header}</Text>
      <View style={styles.tag}>
        <Text style={styles.tagTxt}>{rd.reason}</Text>
      </View>
      <Text style={styles.bookingInfo}>{rd.additional}</Text>
      <Text style={styles.bookingInfoSm}>{rd.requestText}</Text>
    </View>
  );
};

ReasonBooking.propTypes = {
  rescheduleReason: PropTypes.object.isRequired,
};

export default ReasonBooking;
