import React from 'react';
import {Text, TouchableOpacity} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import PropTypes from 'prop-types';
import styles from './ButtonsCss';

const gradients = {
  blue: ['#065af3', '#53b2fe'],
  grey: ['#D3D3D3', '#BDBDBD']
};
export default class Buttons extends React.Component {
  render() {
    const buttonType = this.props.type ? `btnType${this.props.type}` : '';
    const btnTxtStyle = this.props.btnTxtStyle ? this.props.btnTxtStyle : styles.blueBtnText;
    const buttonColor = gradients[this.props.color] ? gradients[this.props.color] : gradients.blue;
    return (
      <TouchableOpacity
        style={[styles.btn, styles[buttonType]]}
        activeOpacity={0.85}
        onPress={this.props.onPressHandler}
      >
        <LinearGradient
          start={{
                        x: 1.0,
                        y: 0.0
                    }}
          end={{
                        x: 0.0,
                        y: 1.0
                    }}
          colors={buttonColor}
          style={styles.blueBtnGradient}
        >
          <Text style={btnTxtStyle}>{this.props.text}</Text>
        </LinearGradient>
      </TouchableOpacity>
    );
  }
}
Buttons.propTypes = {
  type: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onPressHandler: PropTypes.func.isRequired
};

