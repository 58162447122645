
import React from 'react'
import { View, Text, Image } from 'react-native'
import { OFFER_ICON } from '../../../../../Utils/tripGuaranteeImageConstants';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import createStyles from "../WhyBookUs/whyBookUsCss";
import HTMLViewWrapper from '../../../../../../Common/HTMLViewWrapper';
import { getFont } from '../../../../../../PostSalesStyles';
import { useTheme } from '../../../../../../theme/theme.context';
const NextOffer = ({ data }) => {
    const { heading, offer } = data || {};
    const { heading: nextOffer, icon } = offer || {};
    const { url } = icon || {};
    const fonts = getFont();
    const {psTheme : theme} = useTheme();
    const styles = createStyles(theme,fonts);
    return (
        <>
            <View style={[AtomicCss.flexRow, AtomicCss.marginBottom10]}>
                <View style={styles.iconWrapper}>
                    <Image style={styles.offerIconStyle} source={OFFER_ICON} />
                </View>
                <View style={AtomicCss.flex1}>
                    {!!heading &&
                    <Text style={[AtomicCss.font14, fonts.blackFontFamily, AtomicCss.blackText, AtomicCss.marginBottom3]}>{heading}</Text>
                    }
                </View>
            </View>
            <View style={[AtomicCss.flexRow, styles.nextOffer, AtomicCss.marginBottom10]}>
                {!!nextOffer && <HTMLViewWrapper style={[AtomicCss.font13, styles.nextOfferText]} htmlContent={nextOffer} contentWidth={'90%'} /> }
                {!!url && <Image style={[styles.offerIconStyle]} source={{uri:url}} /> }
            </View>
        </>
    )
}

export default NextOffer;