import { StyleSheet } from 'react-native';
import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';

export default (theme, fonts) => {
	const { color } = theme;
	return StyleSheet.create({
		cabDetailsWrapper: {
			paddingTop: 16,
			paddingBottom: 5,
			flexDirection: 'row',
		},
		carTypeWrapper: {
			width: 90,
			borderRadius: 8,
			backgroundColor: color.lightBlue22,
			justifyContent: 'center',
			alignSelf: 'flex-start',
		},
		carImageStyle: {
			width: 80,
			height: 51,
			alignSelf: 'center',
		},
		rideImageStyle: {
			width: 32,
			height: 20,
			alignSelf: 'center',
			position: 'absolute',
			top: -8,
			zIndex: 11,
		},
		carType: {
			backgroundColor: color.persianGreen,
			paddingHorizontal: 4,
			paddingVertical: 2,
			borderBottomLeftRadius: 8,
			borderBottomRightRadius: 8,
		},

		starRatingStyle: {
			width: 6,
			height: 6,
			marginLeft: 3,
		},
		ratingWrapper: {
			backgroundColor: color.green,
			borderRadius: 2,
			paddingHorizontal: 4,
			paddingVertical: 2,
			marginLeft: 5,
			flexDirection: 'row',
			alignItems: 'center',
			alignSelf: 'flex-start',
		},
		cabNumber: {
			color: color.black,
			...fonts.blackFontFamily,
			fontSize: fonts.fontSize.font24,
		},
		driverImageWrapper: {
			width: 32,
			height: 32,
			marginRight: 10,
		},
		driverImageStyle: {
			width: 32,
			height: 32,
		},
		btnWrapper: {
			flexDirection: 'row',
			justifyContent: 'space-between',
			marginTop: 10,
		},
		viewBreakupButtonStyle: {
			borderRadius: 10,
			paddingHorizontal: 20,
			paddingVertical: 10,
			alignItems: 'center',
			flexDirection: 'row',
			justifyContent: 'center',
			...getPlatformElevation(0),
		},
		viewBreakupTextStyle: {
			fontSize: fonts.fontSize.font12,
			...fonts.boldFontFamily,
			textAlign: 'center',
		},
		buttonView: {
			flex: 1,
		},
		marginRight5: {
			marginRight: 5,
		},
		connectInfo: {
			paddingHorizontal: 15,
			paddingVertical: 10,
			marginHorizontal: 15,
			borderRadius: 8,
			backgroundColor: color.grayBg,
		},
		connectInfoText: {
			color: color.defaultTextColor,
			fontSize: fonts.fontSize.font14,
			...fonts.regularFontFamily,
		},
	});
};
