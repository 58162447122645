import React, { useState } from 'react';
import { Image, Text, TouchableOpacity, View } from 'react-native';
import PropTypes from 'prop-types';

import createStyles from './styles';
import { useTheme } from '../../../../../../../theme/theme.context';
import CommonStyle from '@mmt/legacy-commons/Styles/AtomicCss';
import { getImageIcon } from "../../../../helper";
import { getStaticData } from '../../../../../../../staticData/staticData';
import { getFont } from '../../../../../../../PostSalesStyles';
import { addSpaceWithAmount } from '../../../../../../../utils/PostSaleUtil';
import isEmpty from 'lodash/isEmpty';

const CancellationfareBreak = (props) => {

  const [fareBreakupDtls, setFareBreakupDtls] = useState(true);

  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);
  let { viewBreakupText, hideBreakupTest } = getStaticData();
  const { title, total, items = [] } = props;
  if (props.showStaticContentInEnglish) {
    viewBreakupText = 'View Breakup';
    hideBreakupTest = 'Hide Breakup';
  }

  const activeDtls = fareBreakupDtls ? hideBreakupTest : viewBreakupText;

  const handleFareBreakup = () => {
    let clickEvent = 'AmountPaid_View_Breakup';
    if (fareBreakupDtls) {
      clickEvent = 'AmountPaid_Hide_Breakup';
    }
    props.showHideBreakUpClick(clickEvent);
    setFareBreakupDtls((prev) => !prev);
  };

  const addPaidAmountBreakup = (components) => {
    return components.map((component) => {
      const { fsStyle, ...fonts } = getFont(true);
      return (
        <View key={component.description} style={styles.fareBrkupRow}>
          <Text style={[styles.fareBrkupTxt, fonts.regularFontFamily, fsStyle.font14]}>
            {component.description}
          </Text>
          <View style={CommonStyle.flexRow}>
            {!isEmpty(component.showSlasher) && (
              <Text
                style={[
                  styles.fareBrkupTxtPrice,
                  fonts.regularFontFamily,
                  styles.lineThrough,
                  styles.priceGap,
                  fsStyle.font14,
                ]}
              >
                {addSpaceWithAmount(component.slashedValue)}
              </Text>
            )}
            <Text style={[styles.fareBrkupTxtPrice, fonts.regularFontFamily, fsStyle.font14]}>
              {addSpaceWithAmount(component.actualValue)}
            </Text>
          </View>
        </View>
      );
    });
  }

  const addDeductionBreakup = (pricingComponent) =>
    pricingComponent.map((pricingBreakup, index) => {
      const arrayLength = pricingComponent.length;
      const { fsStyle, ...fonts } = getFont(true);
      return (
        <View
          key={index}
          style={arrayLength === index + 1 ? { marginBottom: 0 } : { marginBottom: 20 }}
        >
          {!isEmpty(pricingBreakup && pricingBreakup.subtitle) && (
            <View>
              <View style={styles.fareBrkupRowLine} />
              <View style={styles.fareBrkupRowLineWrapperTxt}>
                <Text style={[styles.fareBrkupRowLineTxt, fonts.regularFontFamily, fsStyle.font12]}>
                  {pricingBreakup.subtitle}
                </Text>
              </View>
            </View>
          )}
          {addPaidAmountBreakup(pricingBreakup.components)}
        </View>
      );
    });


  return (
    <View style={styles.fareBreakUpWrapper}>
      <View style={[styles.fareBreakupHeading]}>
        <View style={CommonStyle.flexRow}>
          <Text
            style={[
              styles.fbHeadingTxt,
              fonts.blackFontFamily,
              { color: props.amountPaidBreakup.lbl.color },
              fsStyle.font16,
            ]}
          >
            {title}
          </Text>
          {!isEmpty(items) && (
            <TouchableOpacity
              onPress={handleFareBreakup}
              activeOpacity={0.5}
              style={{ maxWidth: 100 }}
            >
              <Text
                numberOfLines={1}
                style={[styles.linkTxt, fonts.boldFontFamily, fsStyle.font12]}
              >
                {activeDtls}
              </Text>
            </TouchableOpacity>
          )}
          
        </View>
        <Text
          style={[
            styles.totalPaidPriceTxt,
            fonts.blackFontFamily,
            { color: props.amountPaidBreakup.lbl.color },
            fsStyle.font16,
          ]}
        >
          {addSpaceWithAmount(total)}
        </Text>
      </View>
      {fareBreakupDtls && (
        <View style={[styles.fareBreakupDtlsWrapper]}>
          {addDeductionBreakup(items)}
        </View>
      )}
    </View>
  );

}

CancellationfareBreak.propTypes = {
  amountPaidBreakup: PropTypes.object.isRequired,
  paidmessages: PropTypes.object.isRequired,
  showHideBreakUpClick: PropTypes.object.isRequired,
};

export default CancellationfareBreak;