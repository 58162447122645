import React from 'react';
import {
  View,
  Text,
  ScrollView,
  StyleSheet,
  FlatList,
  TouchableOpacity,
  Platform,
} from 'react-native';
import getPlatformElevation from '@mmt/legacy-commons/Common/Components/Card/getPlatformElevation';
import SimpleHeader from '@mmt/legacy-commons/Common/Components/Header/SimpleHeader';
import { getFont } from '../../../../../../../PostSalesStyles';
import { useTheme } from '../../../../../../../theme/theme.context';
import { getStaticData } from '../../../../../../../staticData/staticData';

const StateNameList = (props) => {
  const { psTheme } = useTheme();
  const { color } = psTheme;
  const { fsStyle, ...fonts } = getFont(true);
  const styles = getStyle(fonts, psTheme);

  const {stateText} = getStaticData();

  return (
    <View style={styles.container}>
      <View style={styles.selectOptionsOuter}>
        <SimpleHeader title={stateText} iconPress={() => props.onClose()} />
        <ScrollView style={styles.overlaySection}>
          <FlatList
            data={props.StateList}
            renderItem={({ item }) => (
              <TouchableOpacity
                onPress={() => {
                  props.onItemSelected(item, props.field, props.updateIndex);
                  props.onClose();
                }}
                style={styles.selectOptions}
              >
                <Text
                  style={[
                    fonts.regularFontFamily,
                    fsStyle.font18,
                    { color: color.defaultTextColor },
                  ]}
                >
                  {item.displayName}
                </Text>
              </TouchableOpacity>
            )}
            keyExtractor={(item) => item.key}
          />
        </ScrollView>
      </View>
    </View>
  );
};

const getStyle = (fonts, theme) => {
  const { color } = theme;
  return StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: color.grayBg,
    },
    selectOptionsOuter: {
      backgroundColor: color.white,
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 9999,
      ...getPlatformElevation(2),
    },
    selectOptions: {
      paddingVertical: 12,
    },
    overlaySection: {
      paddingHorizontal: 20,
    },
  });
};

export default StateNameList;
