import _ from 'lodash';
import { findDaysBetweenDates } from '@mmt/legacy-commons/Common/utils/DateUtils';

let fltTrackData = null;

export const _setFltTrackData = (data) => {
  fltTrackData = data;
};

export const _getFltTrackData = () => {
  return fltTrackData;
};

export const _trackDepartureDate = (segmentGroupDetailList) => {
  let depDate = '';
  if (segmentGroupDetailList && segmentGroupDetailList.length > 0) {
    segmentGroupDetailList.forEach(
      (segGroup) =>
        !_.isEmpty(segGroup.segmentDetails) &&
        segGroup.segmentDetails.forEach((seg) => {
          depDate += seg.travelDateTime
            ? `${_dateFormatForTracker(seg.travelDateTime.split('T')[0])}|`
            : '';
        }),
    );
  }
  return depDate;
};

export const _trackSegmentData = (segmentGroupDetailList) => {
  const info = {
    depDate: '',
    travelDateGap: '',
    adultCount: '',
    airlineCode: '',
    bookingIntent: '',
  };
  if (segmentGroupDetailList && segmentGroupDetailList.length > 0) {
    segmentGroupDetailList.forEach(
      (segGroup) =>
        !_.isEmpty(segGroup.segmentDetails) &&
        segGroup.segmentDetails.forEach((seg) => {
          const paxDetails = seg.segmentPassengerDetail || seg.paxAncillaryDetails;
          const originCity = seg.originCity;
          const destinationCity = seg.destinationCity;
          info.depDate += seg.travelDateTime
            ? `${_dateFormatForTracker(seg.travelDateTime.split('T')[0])}|`
            : '';
          info.travelDateGap += seg.travelDateTime
            ? `${findDaysBetweenDates(seg.travelDateTime, new Date())}|`
            : '';
          info.adultCount += `${!_.isEmpty(paxDetails) ? paxDetails.length : 0}|`;
          info.airlineCode +=
            seg.airlineCode || seg.airLineCode ? `${seg.airlineCode || seg.airLineCode}|` : '';
          info.bookingIntent += `${originCity}-${destinationCity}|`;
        }),
    );
  }
  return info;
};

export const _dateFormatForTracker = (val, separator = '-') => {
  if (!_.isEmpty(val) && val.indexOf('-') !== -1) {
    return val.split('-').reverse().join(separator);
  }
  return val;
};

export const _trackFltBookingDate = (val) => {
  if (!_.isEmpty(val)) {
    return `PS|${_dateFormatForTracker(val, ':')}`;
  }
  return val;
};

export const _getJourneyType = (journeyType) => {
  switch (journeyType) {
    case 1:
      return 'Multi';
    case 2:
      return 'RT';
    case 3:
      return 'OW';
    default:
      return '';
  }
};
