import React from 'react';
import {View, Text, Image} from 'react-native';
import PropTypes from 'prop-types';
import styles from './alertInfoCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getImagePath } from '../../utils/PostSaleUtil';

const infoImage = getImagePath('flight_booking_info-icon.webp');

class AlertInfo extends React.Component {

  render() {
    if(this.props.infoList){
      let image = this.props.infoList.iconUrlPath;
      if(image === undefined || image === null || image === ""){
        image = infoImage;
      }
      return (
        <View style={[styles.infoCardWrapper, {backgroundColor: this.props.infoList.headerDetail.backgroundColor}]}>
          <View style={styles.infoCardHading}>
            <Image style={styles.infoIcon} source={image} resizeMode="contain"/>
            <Text style={[styles.headingTxt, {color: this.props.infoList.headerDetail.headingcolor}]}>{this.props.infoList.headerDetail.heading}</Text>
          </View>
          {
            this.props.infoList.importantMessageList.map(item => (<View style={[AtomicCss.flexRow, AtomicCss.marginBottom10]}>
              <View style={styles.bulletCircle} />
              <Text style={[styles.infoCardDisc]}>
                <Text>{item.message}</Text>
              </Text>
            </View>))
          }
        </View>
      )
    }
    return null;
  }
}
export default AlertInfo;

AlertInfo.propTypes = {
  infoList: PropTypes.array.isRequired
};
