import React from 'react';
import {View, Text, TouchableOpacity} from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import styles from '../GiftCardDetailsCss';
import GiftCardBookingDetailsConstant from '../GiftCardBookingDetailsConstant';
import { getFont } from "../../PostSalesStyles";
import {  useTheme } from '../../theme/theme.context';

type recipientInfoType = {
	emailId: string
	name: string
	phoneNumber: string
}
type itemType = {
	amount: number
	cardNumber: string
	gcId: string
	giftcardLink: string
	recipientInfo: recipientInfoType
}


interface GiftCardDetailsProps {
	item: itemType;
	index: number;
	lastElement: boolean;
	listToShow: number;
	handleClick: Function;
	deliveryMode: number;
	lastSectionStyle: string;
}


const GiftCardDetails: React.FC<GiftCardDetailsProps> = ({ item, index, handleClick, deliveryMode, lastSectionStyle}) => {
	const {fsStyle, ...fonts} = getFont(true);
	const { psTheme: theme } = useTheme();


	return (
		<View style={[styles.gcSummaryGiftDetailsSection, styles[lastSectionStyle]]}>
			<View style={[styles.gcSummaryGiftCardTopInfo, AtomicCss.marginBottom10]}>
				<View style={[AtomicCss.flex1, AtomicCss.flexRow, AtomicCss.alignCenter]}>
					<View style={styles.gcSummaryBorderLeftHighLiter}></View>
					<View>
						<Text style={[{color:theme.color.defaultTextColor}, fsStyle.font12, fonts.regularFontFamily, AtomicCss.marginBottom3]}>{GiftCardBookingDetailsConstant.RECIPIENT} {index + 1}</Text>
						<Text style={[fonts.boldFontFamily, {color:theme.color.defaultTextColor}, fsStyle.font12]}>{deliveryMode === GiftCardBookingDetailsConstant.DELIVERY_MODES.EMAIL ? item.recipientInfo.emailId : item.recipientInfo.phoneNumber}</Text>
					</View>
				</View>
				<View>
					<Text style={[{color:theme.color.defaultTextColor}, fsStyle.font12, fonts.regularFontFamily, styles.gcSummaryTextRight, AtomicCss.marginBottom3]}>{GiftCardBookingDetailsConstant.AMOUNT}</Text>
					<Text style={[fonts.boldFontFamily, {color:theme.color.defaultTextColor}, fsStyle.font12, styles.gcSummaryTextRight]}>{item.amount}</Text>
				</View>
			</View>
			<View style={styles.gcSummaryGiftCardNumberDetails}>
				<View style={AtomicCss.flex1}>
					<Text style={[{color:theme.color.defaultTextColor}, fsStyle.font12, fonts.regularFontFamily, AtomicCss.marginBottom3]}>{GiftCardBookingDetailsConstant.GIFT_CARD_NUMBER}</Text>
					<Text style={[fonts.boldFontFamily, {color:theme.color.defaultTextColor}, fsStyle.font12]}>{item.cardNumber}</Text>
				</View>
				<View>
					<Text style={[{color:theme.color.defaultTextColor}, fsStyle.font12, fonts.regularFontFamily, styles.gcSummaryTextRight, AtomicCss.marginBottom3]}>{GiftCardBookingDetailsConstant.GIFT_CARD_DETAILS}</Text>
					<TouchableOpacity onPress={() => handleClick(item.gcId)}>
						<Text style={[fonts.boldFontFamily, {color:theme.color.azure}, fsStyle.font12, styles.gcSummaryTextRight]}>{GiftCardBookingDetailsConstant.RESEND}</Text>
					</TouchableOpacity>
				</View>
			</View>
		</View>
	)
}

export default GiftCardDetails;