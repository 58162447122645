import React from 'react';
import {Image, Text ,StyleSheet} from 'react-native';
import  LinearGradient from 'react-native-linear-gradient';
import { themeColors } from '../../PostSalesStyles';
import { DEVICE_WINDOW } from '../HolidayBookingConstants';

const AffiliateFooterCard =({affiliateFooterDetails})=>{
    if(!affiliateFooterDetails){
        return null;
    }
    return(
        <LinearGradient
                start={{x: 1.0,
                        y: 0.0
                }}
                end={{x: 0.0,
                      y: 1.0
                }}
                colors={themeColors.gradientBtnColor}
                style={styles.footerWrapper}
            >
                <Image style={styles.imageStyle} source={affiliateFooterDetails.footerImageUrl} />
            </LinearGradient>
    );
}
const styles = StyleSheet.create({
    footerWrapper:{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 88
    },
    imageStyle:{
        width: DEVICE_WINDOW.width,
        height: 88
    }
})
export default AffiliateFooterCard;