import React, { useState, useEffect } from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import createStyles from "./RailInfoCss";
import isEmpty from 'lodash/isEmpty';
import { DOWN_ARROW } from '../../../../Utils/tripGuaranteeImageConstants';
import TGSBookingConstants, { CLICK_TYPE, PAGE_NAME } from '../../../../Utils/TGSBookingConstants';
import { getFont } from '../../../../../PostSalesStyles';
import { useTheme } from '../../../../../theme/theme.context';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import TGSBookingTrackEventHelper from '../../../../Utils/TGSBookingTrackEventHelper';
import { getClickType } from '../../../../Utils/TgsBookingDetailUtil';
const RailInfoServices = ({ card, response }) => {
    const { heading } = card || {};
    const { railServices } = response || {};
    const [normalServices, setNormalServices] = useState([]);
    const [extraServices, setExtraServices] = useState([]);
    const [toggle, setToggle] = useState(false);
    const fonts = getFont();
    const { psTheme: theme } = useTheme();
    const styles = createStyles(theme, fonts);
    const handleToggle = () => {
        setToggle(!toggle);
    }
    useEffect(() => {
        if (railServices) {
            if (railServices.length > 4) {
                setNormalServices([...railServices.slice(0, 4)]);
                setExtraServices([...railServices.slice(4)]);
            }
            else {
                setNormalServices([...railServices]);
            }
        }
    }, [railServices])


    function handleRailService(url, key) {
        const clickType = getClickType(key);
        TGSBookingTrackEventHelper.trackClickEvents(PAGE_NAME.TRIP_DETAILS, clickType);
        if (!isEmpty(url)) {
            GenericModule.openDeepLink(url);
        }
    }

    const getService = (railService) => {
        const { heading, icon, serviceUrl, key } = railService || {};
        const { url } = icon || {};
        const { url: serviceLink } = serviceUrl || {};

        return (<TouchableOpacity key={`icon_${heading}`} style={styles.serviceCard} onPress={() => handleRailService(serviceLink, key)}>
            {!isEmpty(url) && <View style={styles.serviceIconWrapper}>
                <Image style={styles.pnrIconStyle} source={{ uri: url }} />
            </View>}
            {!!heading && <Text style={[styles.infoText, fonts.regularFontFamily]}>{heading}</Text>}
        </TouchableOpacity>)
    }
    return (
        <View>
            <Text style={[AtomicCss.font16, fonts.blackFontFamily, AtomicCss.blackText, AtomicCss.marginBottom14]}>{heading}</Text>
            {!isEmpty(normalServices) &&
                <View style={[styles.railInfoserviceCard, AtomicCss.paddingHz10]}>{normalServices.map((service) => getService(service))}</View>}
            {(!isEmpty(extraServices) && toggle) &&
                <View style={[styles.extraRailServices, AtomicCss.paddingHz10, AtomicCss.paddingBottom24, AtomicCss.marginTop8]}>{extraServices.map((service) => getService(service))}</View>}
            {!isEmpty(extraServices) &&
                <TouchableOpacity style={styles.showMoreBtn} activeOpacity={1} onPress={handleToggle}>
                    <Text style={[AtomicCss.azure, AtomicCss.font12, fonts.boldFontFamily]}>
                        {toggle ? TGSBookingConstants.SHOW_LESS : TGSBookingConstants.SHOW_MORE}</Text>
                    <Image style={toggle ? [styles.activeArrow, styles.downArrowStyle] : styles.downArrowStyle} source={DOWN_ARROW} />
                </TouchableOpacity>}
        </View>
    )
}

export default RailInfoServices;