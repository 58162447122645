import { StyleSheet } from 'react-native';
import { Fonts, Theme } from 'apps/post-sales/src/cab/types';

const getStyle = (fonts: Fonts, psTheme: Theme) => {
  const { color } = psTheme;
  return StyleSheet.create({
    heading: {
      color: color.black,
      fontSize: fonts.fontSize.font16,
      ...fonts.boldFontFamily,
    },
    delayedText: {
      color: color.white,
      fontSize: fonts.fontSize.font12,
      ...fonts.boldFontFamily,
    },
    flightNumberText: {
      color: color.grey,
      fontSize: fonts.fontSize.font12,
      ...fonts.boldFontFamily,
    },
    flightNumber: {
      color: color.black,
      fontSize: fonts.fontSize.font16,
      ...fonts.boldFontFamily,
    },
    flightDetailWrap: {
      paddingVertical: 12,
      paddingHorizontal: 14,
    },
    footer: {
      flexWrap: 'wrap',
      color: color.grey,
      fontSize: fonts.fontSize.font12,
      ...fonts.regularFontFamily,
    },
    cardHeading: {
      flexDirection: 'row',
    },
    flightNumberWrap: {
      borderWidth: 1,
      borderColor: color.lightGray,
      backgroundColor: color.smokeWhite,
      paddingHorizontal: 8,
      paddingVertical: 7,
      borderRadius: 8,
      marginTop: 10,
    },
    flightInfo: {
      flexDirection: 'row',
      marginTop: 10
    },
    footerTextContainer: {
      flex: 1
    },
    iconStyle: {
      width: 20,
      height: 20,
      marginRight: 2,
    },
    delayedTag: {
      backgroundColor: color.yellow13,
      paddingVertical: 3,
      paddingHorizontal: 10,
      borderRadius: 16,
    },
  });
};

export default getStyle;
