import { colors } from '@mmt/legacy-commons/Styles/globalStyles';

const styles = {
  SecureCardParentContent: {
    backgroundColor: '#d7e6f3',
    padding: 10,
  },

  CardContent: {
    backgroundColor: colors.white,
    borderRadius: 6,
  },
  CardIcon: {
    width: 24,
    height: 24,
    marginRight: 8,
  },
  digitIconStyle: {
    width: 28,
    height: 20,
    marginLeft: 14,
  },
  digitIconSm: {
    width: 35,
    height: 12,
  },
  fltCancel: {
    width: 24,
    height: 24,
    marginBottom: 4,
  },
  topSecWrapper: {
    padding: 12,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderBottomColor: colors.grayBg,
    marginBottom: 12,
  },
  insTextWrapper: {
    borderLeftWidth: 3,
    borderLeftColor: colors.azure,
    paddingLeft: 12,
    marginBottom: 12,
  },
  greenText: {
    color: colors.lightSeaGreenTextColor,
  },
  insBoxWrapper: {
    marginHorizontal: 10,
    marginBottom: 15,
  },
  insBoxDetail: {
    backgroundColor: '#f8f8f8',
    borderWidth: 1,
    borderColor: '#f4f4f4',
    borderRadius: 2,
    padding: 10,
    width: '30.65%',
    marginHorizontal: 5,
  },
  benefitsBtn: {
    backgroundColor: '#f4f4f4',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 8,
    marginHorizontal: 16,
    marginBottom: 30,
    borderRadius: 2,
  },
  insBotWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginHorizontal: 16,
    marginBottom: 16,
  },
};

export default styles;
