export const CUSTOMER_FORM_PAGE_TYPES = {
  ERRORPAGE: 'ERRORPAGE',
  ERRORPAGE_TICKET_RAISED: 'ERRORPAGE_TICKET_RAISED',
  REFUND_OUT_OF_TAT_FORM1: 'REFUND_OUT_OF_TAT_FORM1',
  REFUND_OUT_OF_TAT_FORM2: 'REFUND_OUT_OF_TAT_FORM2',
  REFUND_OUT_OF_TAT_FORM_SUBMITTED: 'REFUND_OUT_OF_TAT_FORM_SUBMITTED',
};

export const CUSTOMER_FORM_REQUEST_TYPES = {
  INTERMEDIATE_FORM: 'INTERMEDIATE-FORM',
  POST_FORM: 'POST-FORM',
};

export const UPLOADER_STATES = {
  INITAL: 'addAttachment',
  LOADING: 'loading',
  ERROR: 'error',
  SUCCESS: 'success',
};

export const UPLOADER_MAX_FILE_SIZE = 4;

export const UPLOADER_VALID_EXTENSIONS = ['jpeg', 'jpg', 'png', 'pdf'];

export const REFUND_OOT_TRACKING = {
  CLICK: {
    CHECK_REFUND_INFO: 'How_to_check_your_refund',
    REFUND_RECEIVED_FEEDBACK_YES: 'Refund_Received',
    REFUND_RECEIVED_FEEDBACK_NO: 'Refund_Not_Received',
    CREATE_TICKET: 'Create_Refund_OOT_Ticket',
    TRACK_TICKET_STATUS: 'Track_MSR_Ticket',
    PAYMODE_CHECKED: 'Paymode_Checked',
    PAYMODE_UNCHECKED: 'Paymode_Unchecked',
    PROCEED_TO_UPLOAD: 'out_tat_step1_proceed_click',
    SUBMIT_FORM: 'Submit_Ticket',
    BACK_TO_BOOKING: 'Back_To_Booking',
    TRY_AGAIN: 'Try_Again',
    ADD_ATTACHMENT: 'Add_Attachment',
    DEL_ATTACHMENT: 'Delete_Attachment',
    ABORT_UPLOAD: 'Abort_Upload',
    VIEW_REFUND_BREAKUP:'View_Refund_Breakup'
  },
  PAGES: {
    DEFAULT: 'RefundOOT',
    TY: 'RefundOOT:ThankYou',
    ERROR: 'RefundOOT:Error',
    DELAY_INFO: 'RefundOOT:DelayInfo',
    SELECT_MODE: 'RefundOOT:SelectPaymode',
    UPLOAD: 'RefundOOT:Upload',
    BUS: 'Mob:customer support:Bus',
    CAB: 'Mob:Customer Support:Cab',
    FLIGHT: 'Mob:customer support:Flight',
    GC: 'mob:customer support:giftcards',
    HOLIDAY: 'mob:cs:Holidays',
    HOTEL: 'Mob:customer support:Hotel',
    METRO: 'Mob:Customer Support:Metro',
    RAIL: 'Mob:customer support:Rail',
    SELF_DRIVE_CAR: 'Mob:customer support:ZoomCar',
    TGS: 'Mob:customer support:Tgs',
  },
  SHOW: {
    REFUND_PROCESS: 'out_tat_check_refund_shown',
    RECEIVED_REFUND: 'out_tat_yes_no_shown',
    REFUND_FORM_STEP_1: 'out_tat_step1_shown',
    REFUND_FORM_STEP_2: 'out_tat_step2_shown',
    TICKET_SUCCESS: 'out_tat_success_page',
  }
}
