import React from 'react';
import { View, Text } from 'react-native';
import createStyles from "./fareBreakupCss"
import { getFont } from '../../../../../PostSalesStyles';
import { useTheme } from '../../../../../theme/theme.context';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import TGSBookingConstants from '../../../../Utils/TGSBookingConstants';

const FareBreakup = ({ bookingDetailsResponse }) => {
    const { tgService: fareResonse } = bookingDetailsResponse || {};
    const [tgService] = fareResonse || {};
    const { totalAmountText: totalPrice, baseAmountText } = tgService || {};
    const fonts = getFont()
    const { psTheme: theme } = useTheme();
    const styles = createStyles(theme, fonts);
    return (
        <View style={[AtomicCss.whiteBg, AtomicCss.marginBottom10]}>
            <View style={[styles.CardContentFull]}>
                <View style={styles.fareBreakupInnerSection}>
                    <View style={styles.fareBreakupInnerRowSection}>
                        <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween]}>
                            <Text style={styles.fareBkHeadingTxt}>{TGSBookingConstants.BASE_PRICE}</Text>
                            {!!baseAmountText && <Text style={[styles.fareBkHeadingTxt]}>{baseAmountText}</Text>}
                        </View>
                    </View>
                </View>
                <View style={[styles.totalPrice]}>
                    <Text
                        style={[styles.totalPricetxt, AtomicCss.font16]}>{TGSBookingConstants.TOTAL_AMOUNT}</Text>
                    {!!totalPrice && <Text
                        style={[styles.totalPricetxt, AtomicCss.font18]}>{totalPrice}</Text>}
                </View>
            </View>
        </View>
    )

}

export default FareBreakup;