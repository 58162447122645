import React from 'react';
import LinearGradient from 'react-native-linear-gradient';
import { View, Text, Image } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';

import createStyles from './giftClaimStyle';
import { getFont } from '@mmt/post-sales/src/PostSalesStyles';
import { useTheme } from '@mmt/post-sales/src/theme/theme.context';

interface IGiftClaimCardProps {
  isloyaltyDetails: boolean;
  otherGCClaimedDetails: Record<string, string>;
}

const gradientColors = ['#cef1eb', '#ffffff'];

export default function GiftClaimCard({
  isloyaltyDetails,
  otherGCClaimedDetails,
}: IGiftClaimCardProps) {
  const { heading, description, icon } = otherGCClaimedDetails || {};
  const fonts = getFont();
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);
  return (
    <LinearGradient
      colors={gradientColors}
      start={{ x: 1.0, y: 1.0 }}
      end={{ x: 0.0, y: 0.0 }}
      style={[
        isloyaltyDetails
          ? styles.benefitCardGradientStyle
          : styles.benefitCardGradientLoyalityStyle,
        AtomicCss.paddingAll16,
        AtomicCss.marginHz12,
        AtomicCss.marginBottom16,
      ]}
    >
      <View>
        {!!heading && (
          <View style={AtomicCss.marginTop16}>
            <Text style={styles.headingStyle}>{heading}</Text>
          </View>
        )}
        <View style={styles.rowStyle}>
          {!!icon && <Image style={styles.icStyle} source={{ uri: icon }} />}
          {!!description && <Text style={styles.descStyle}>{description}</Text>}
        </View>
      </View>
    </LinearGradient>
  );
}
