import PropTypes from 'prop-types';
import React from 'react';
import { Text, View } from 'react-native';
import { isEmpty } from 'lodash';
import getStyles from './hotelModificationPersuationMessageCss';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const HotelModificationpersuationMessage = ({persuationdata, containerStyle}) => {
    const { ...fonts } = getFont(true);
    const styles = getStyles(fonts);
    const { text, bg_color , color } = !isEmpty(persuationdata) ? persuationdata : {};
    
    return(
        !isEmpty(text) ? <View style={[styles.roomInfoSection, {backgroundColor: bg_color}, containerStyle]}>
            <Text style={[styles.roomAvailableTxt, { color: color}]}>{text}</Text>
        </View> : null
    );
}

HotelModificationpersuationMessage.propTypes = {
    persuationdata: PropTypes.object.isRequired
}

export default HotelModificationpersuationMessage;