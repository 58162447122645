import { StyleSheet } from 'react-native';

const createStyles = (fonts, theme) =>
  StyleSheet.create({
    innerCardWrapper: {
      padding: 16,
      margin: 10,
      backgroundColor: theme.color.white,
    },
    listWrapper: {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 10,
    },
    bullet: {
      width: 5,
      height: 5,
      borderRadius: 10,
      backgroundColor: theme.color.defaultTextColor,
      marginRight: 10,
    },
    headingText: {
      ...fonts.blackFontFamily,
      color: theme.color.defaultTextColor,
      fontSize: fonts.fontSize.font16,
    },
    underLine: {
      textDecorationLine: 'underline',
    },
    checkbox: {
      height: 20,
      width: 20,
      backgroundColor: theme.color.white,
      borderColor: theme.color.lightTextColor,
      borderWidth: 1.5,
      borderRadius: 3,
      overflow: 'hidden',
      position: 'relative',
    },
    tAndCText: {
      fontSize: fonts.fontSize.font14,
      ...fonts.regularFontFamily,
      color: theme.color.defaultTextColor,
    },
    agreementText: {
      ...fonts.blackFontFamily,
      fontSize: fonts.fontSize.font14,
      color: theme.color.black,
    },
    checkboxSelected: {
      borderColor: 'transparent',
      borderWidth: 0,
    },
    checkedIcon: {
      position: 'absolute',
      height: 22,
      width: 22,
      left: -1,
      top: -1,
    },
    tncWrapper: {
      flexDirection: 'row',
      borderWidth: 1,
      borderColor: theme.color.lightGrey,
      borderRadius: 4,
      paddingVertical: 8,
      paddingHorizontal: 10,
    },
    infoIconStyle: {
      width: 16,
      height: 16,
      marginRight: 6,
    },
    errorText: {
      color: theme.color.red,
      fontSize: fonts.fontSize.font12,
      ...fonts.regularFontFamily,
    },
    errorStyle: {
      borderColor: theme.color.red,
    },
  });

export const htmlStyles = (fonts, theme)=> ({
  b: {
    ...fonts.boldFontFamily,
  },
  a: {
    textDecorationLine: 'underline',
    color: theme.color.linkBlue,
  },
});
export default createStyles;
