import React, { useEffect, useState } from 'react';
import {Platform} from 'react-native';
import FlightBookingDetailsV1 from './FlightBookingDetailsV1';
import FlightBookingDetailsV2 from '../v2/details/FlightBookingDetailsV2';
import PageLoader from 'apps/post-sales/src/Common/Cancellation/PageLoader';
import { checkIndianRegion, isPersonalBooking, isLostBookingData } from 'apps/post-sales/src/utils/PostSaleUtil';
import { getUserProfileType, isEnglishLangSelected } from 'packages/legacy-commons/AppState/AppUserConfig';
import { PROFILE_TYPE } from 'packages/legacy-commons/Common/constants/AppConstants';
import Actions from 'apps/post-sales/src/navigation/postSalesNavigation';
import { getDeeplinkKeyValue } from '../../v2/utils/commonUtils';


const FlightBookingDetails = (props) => {
  const [isLoading, setIsLoading] = useState(null);
  const isPersonal = Platform.OS === 'web' ? isPersonalBooking() : getUserProfileType() === PROFILE_TYPE.PERSONAL;
  const shouldLoadNewFlow = isPersonal && !props.shouldLoadOldFlow && isEnglishLangSelected() && checkIndianRegion();
  const [isNewFlow, setIsNewFlow] = useState(shouldLoadNewFlow);
  const isSellingPage = getDeeplinkKeyValue(props, 'isSellingPage');
  useEffect(() => {
    setIsLoading(null);
    const pageData = props.pageData ? JSON.parse(props.pageData) : undefined;
    const isLostBooking = isLostBookingData(pageData);
    if(isLostBooking) {
      setIsNewFlow(false);
    }
    setIsLoading("Done");
  }, []);
  if(!isLoading) return <PageLoader />;
  if (isSellingPage && isNewFlow) {
    Actions.flightSellingPage({
      ...props,
    });
    return null;
  }
  return isNewFlow ? (
    <FlightBookingDetailsV2 {...props} loadOldFlow={() => setIsNewFlow(false)}/>
  ) : (
    <FlightBookingDetailsV1 {...props} />
  );
};

export default FlightBookingDetails;
