import React from 'react';
import {Text, TextInput, TouchableOpacity, View} from 'react-native';
import styles from './SearchLocationCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {CancelAndRebookConstants} from './CancelAndRebookConstants';
import Loader from '../../../Common/SpinnerLoader/Loader';

class SearchInput extends React.Component {
    constructor(props) {
        super(props);
        this.textInput = React.createRef();
        this.state = {
            isActive: false,
            value: '',
        }
    }

    onRowPress = () => {
        const { isActive } = this.state;
        if (!isActive) {
            this.textInput.current.focus();
        }
    }

    onFocus = () => {
        if(this.props.type === 'pickupLocation'){
            this.props.handleFocus(true);
        }else{
            this.props.handleFocus(false);
        }
        this.setState({
            isActive: true
        });
    }

    isEditable = () => {
        if(this.props.type === 'pickupLocation'){
            this.props.handleFocus(true);
        }else{
            this.props.handleFocus(false);
        }
    }

    onBlur = () => {
        this.setState({
            isActive: false
        });
    }

    render() {
        const { isActive, value } = this.state;
        const activeSuffix = isActive ? 'Active' : '';
        return (
            <TouchableOpacity
                style={[styles.fieldBox, styles[`fieldBox${activeSuffix}`]]}
                activeOpacity={0.8}>
                <View style={AtomicCss.flex1}>
                    <View style={AtomicCss.flex1}>
                    <Text style={[AtomicCss.font12, AtomicCss.boldFont, AtomicCss.lightText, AtomicCss.marginBottom3]}>{this.props.heading}</Text>
                    <TextInput
                        style={[styles.input, AtomicCss.font16, AtomicCss.blackFont, AtomicCss.blackText]}
                        value={this.props.formValue}
                        onChangeText={input => this.props.onClickOfInputTab(input)}
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        placeholder={(this.props.showLoader) ? (CancelAndRebookConstants.FETCH_LOCATION) : (CancelAndRebookConstants.TYPE_FOR_SEARCH)}
                    />
                    </View>
                    {
                        this.props.showLoader &&
                        <View>
                            <Loader/>
                        </View>
                    }

                </View>
            </TouchableOpacity>
        )
    }
}
export default SearchInput;
