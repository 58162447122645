const getStyles = (fonts, theme) =>{
  return {
    cardWrapper: {
      backgroundColor: theme.color.white,
      borderRadius: 8,
      paddingHorizontal: 20,
      paddingVertical: 24,
    },
    topHeading: {
      flexDirection: 'row',
      paddingHorizontal: 4,
      marginBottom: 16,
    },
    refundAmountWrapper: {
      paddingHorizontal: 16,
      paddingVertical: 26,
      borderWidth: 1,
      borderColor: theme.color.lightGrey,
      borderRadius: 8,
    },
    titleText: {
      ...fonts.font20,
      color: theme.color.black,
      fontFamily: fonts.black,
      lineHeight: 24,
      flex: 1,
    },
    titleAmountText: {
      ...fonts.font20,
      color: theme.color.black,
      fontFamily: fonts.black,
      lineHeight: 24,
    },
    titleAmountTextRefundable: {
      color: theme.color.green,
    },
    titleAmountTextNonRefundable: {
      color: theme.color.red,
    },
    amountHeadingRow: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    amountRow: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 10,
    },
    detailText: {
      ...fonts.font14,
      fontFamily: fonts.regular,
      lineHeight: 20,
      marginRight: 30,
      flex: 1,
    },
    amountText: {
      ...fonts.font14,
      fontFamily: fonts.regular,
      lineHeight: 20,
    },
    totalRow: {
      marginTop: 6,
      paddingTop: 20,
      borderTopColor: theme.color.grayBg,
      borderTopWidth: 1,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    totalText: {
      ...fonts.font16,
      color: theme.color.black,
      fontFamily: fonts.black,
      lineHeight: 19.2,
    },
    totalTextRefundable: {
      color: theme.color.green,
    },
    totalTextNonRefundable: {
      color: theme.color.red,
    },
    mmtBlackWrapper: {
      marginHorizontal: -16,
      marginTop: 20,
      marginBottom: -26,
      backgroundColor: theme.color.grey14,
      padding: 16,
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
      flexDirection: 'row',
      alignItems: 'center',
      borderTopWidth: 1,
      borderTopColor: theme.color.lightGrey,
    },
    mmtBlackIcon: {
      width: 32,
      height: 32,
      marginRight: 8,
    },
    tcsPolicyInfo:{
      backgroundColor: theme.color.grayBg,
      padding: 12,
      marginHorizontal: -16,
    },
    policyCheckedSection:{
      marginHorizontal: -2,
      marginTop: 10,
      flex: 1,
      alignItems:'center',
      justifyContent:'center'
    },
    defaultText: {
      color: theme.color.defaultTextColor
    },
    midGreyText: {
      color: theme.color.black74
    }
  };
} 

export default getStyles;
 
