import myTripsNavigation from '../../../navigation/navigation';
import upcomingBookingIcon from '@mmt/legacy-assets/src/postSales/summary/upcomingVisaIcon.webp';
import completedBookingIcon from '@mmt/legacy-assets/src/postSales/summary/completedVisaIcon.webp';
import cancelledBookingIcon from '@mmt/legacy-assets/src/postSales/summary/cancelledVisaIcon.webp';
import failedBookingIcon from '@mmt/legacy-assets/src/postSales/summary/failedVisaIcon.webp';
import { getStaticData } from '../../../../staticData/staticData';
import { getDateTimeWithPaxInfo } from '../../../data/tripListManager';
import { titleCase } from '@mmt/legacy-commons/Helpers/stringHelpers';
import { msrUpdateAction } from '../../../../utils/MsrUtils';
import { getNeedHelpData } from '../../../utils';

function getVisaSummaryData(data, msrTicket) {
  const staticData = getStaticData();
  const [visaDetails] = data.visaDetails;
  const pageData = {
    fromMyTrips: true,
  };
  const openDetails = () => {
    myTripsNavigation.openVisaBookingDetail(data, pageData);
  };
  const actions = [];
  const failedBooking = data.status === 'FAILED';
  const msrAction = msrUpdateAction(msrTicket);
  if (msrAction) {
    actions.push(msrAction);
  }
  const needHelpData = getNeedHelpData(msrTicket, data.bookingID , data.needHelpData, data.showQuickResolve);
  let paxInfo =
    visaDetails.totalPax > 1
      ? `${data.leadPaxFirstName} + ${visaDetails.totalPax - 1} `
      : `${titleCase(data.leadPaxFirstName)}`;
  let description = visaDetails.visadescription;
  let iconUrl = '';
  switch (data.status) {
    case 'UPCOMING':
      iconUrl = upcomingBookingIcon;
      break;
    case 'COMPLETED':
      iconUrl = completedBookingIcon;
      break;
    case 'CANCELLED':
      iconUrl = cancelledBookingIcon;
      paxInfo = getDateTimeWithPaxInfo(data, staticData.cancelledText, paxInfo);
      break;
    case 'FAILED':
      iconUrl = failedBookingIcon;
      description = staticData.bookingSummaryText.additionalText.visaBookingFailedText;
      paxInfo = getDateTimeWithPaxInfo(data, staticData.failedText, paxInfo);
      break;
    default:
      iconUrl = upcomingBookingIcon;
      break;
  }

  return {
    iconUrl: iconUrl,
    countryName: visaDetails.visaCountryName,
    description: visaDetails.visadescription,
    paxInfo,
    persuasions: [],
    actions,
    status: data.status,
    openDetails,
    failedBooking,
    needHelpData,
  };
}

export default getVisaSummaryData;
