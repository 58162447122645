import { Platform } from 'react-native';

const MMT_COOKIE_KEY = 'com.makemytrip.com.rails.cookies';
const MMT_COOKIE_KEY_ARRAY = 'com.makemytrip.com.rails.cookies.arrays';


const getCookieKeyBy = () => {
  const cookiesParsed = decodeURIComponent(document.cookie);
  const cookieArray = cookiesParsed.split(';').filter(cook => cook);
  const prevCookieArray = JSON.parse(window?.sessionStorage?.getItem(MMT_COOKIE_KEY_ARRAY) || '[]');

  if (prevCookieArray.length === cookieArray.length && cookieArray.length >= 1) {
    const prevCookieKeyBy = JSON.parse(window?.sessionStorage?.getItem(MMT_COOKIE_KEY) || '{}');
    return prevCookieKeyBy;
  }

  const cookieKeyBy = cookieArray.reduce((acc, v) => {
    const keyValArray = v.split('=');
    const key = keyValArray[0].trim();
    if (key) {
      //@ts-ignore
      acc[key] = keyValArray.slice(1).join('');
    }
    return acc;
  }, {});

  if (window.sessionStorage) {
    window.sessionStorage.setItem(MMT_COOKIE_KEY, JSON.stringify(cookieKeyBy));
    window.sessionStorage.setItem(MMT_COOKIE_KEY_ARRAY, JSON.stringify(cookieArray));
  }
  return cookieKeyBy;
};

export const getMWebHeaders = () => {
  const cookieKeyBy = getCookieKeyBy();
  const headers: any = {
    'os': Platform.OS || null,
    'device-id': cookieKeyBy['dvid'] || null,
    'Profile-Type': JSON.parse(localStorage.getItem('rails_user_details') || '{}')?.profileType || null,
    'uuid': cookieKeyBy['mmt-uuid'] || null,
  };
  const mmtAuth = cookieKeyBy['mmt-auth'] || null;
  if (mmtAuth) {
    headers['mmt-auth'] = mmtAuth;
  }
  return headers;
};
