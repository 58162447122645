import { colors } from '@mmt/legacy-commons/Styles/globalStyles';

const styles = {
  parentContainer: {
    flex: 1,
    backgroundColor: '#ffffff',
  },
  pageWrapper: {
    flex: 1,
    backgroundColor: colors.white,
    justifyContent: 'center',
    alignItems: 'center',
  },

  messageContent: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 50,
  },
  messageIcon: {
    width: 60,
    height: 55,
    marginBottom: 40,
  },

  messageTitle: {
    color: colors.black28,
    marginBottom: 10,
    textAlign: 'center',
  },
  messageDesc: {
    color: colors.gray,
    textAlign: 'center',
    lineHeight: 20,
  },
  footerBtnWrapper: {
    paddingHorizontal: 50,
    width: '100%',
    marginTop: 30,
  },
};

export default styles;
