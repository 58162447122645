import { trackOmniture } from '@mmt/legacy-commons/Common/utils/OmnitureTrackerUtils';
import PostSalesEventLogger from '../../PostSalesEventLogger';

class LostBookingTrackingHelper {
  trackLoadEvent = (pageName, response) => {
    try {
      const trackingPageName = this.getPageName(pageName, response);
      PostSalesEventLogger.loadEvent(trackingPageName, response);
      trackOmniture(trackingPageName, this.getTrackingOmniture(trackingPageName, response));
    } catch (e) {
      console.log(e);
    }
  };

  getPageName = (pageName, response) => {
    const trackingPageName = this.isIntlBooking(response)
      ? `Mob:customer support:Intl ${response.primaryLob} : ${pageName}`
      : `Mob:customer support:Dom ${response.primaryLob} : ${pageName}`;
    return trackingPageName;
  };

  trackErrorWithData = (pageName, bookingId, data) => {
    try {
      PostSalesEventLogger.errorEvent(pageName, { bookingId, error: data });
      const params = this.getErrorParams(pageName, bookingId, data);
      trackOmniture(pageName, params);
    } catch (e) {
      console.error(e);
    }
  };

  getErrorParams = (pageName, bookingId, data) => {
    const params = {};
    params.pageName = pageName;
    params.m_v15 = pageName;
    if (bookingId) {
      params.m_c22 = `${bookingId}_${data}`;
    } else {
      params.m_c22 = data;
    }
    return params;
  };

  trackClickEvent = (pageName, clickEvent, response) => {
    try {
      PostSalesEventLogger.clickEvent(pageName, clickEvent, response);
      const params = {};
      if (response) {
        params.m_c1 = this.isIntlBooking(response)
          ? `MI_detail_Intl_${clickEvent}`
          : `MI_detail_Dom_${clickEvent}`;
        params.m_c22 = response.ruleTrace || '';
        trackOmniture(this.getPageName(pageName, response), params);
      } else {
        params.m_c1 = clickEvent;
        trackOmniture(pageName, params);
      }
    } catch (e) {
      console.error(e);
    }
  };

  getTrackingOmniture(pageName, response) {
    const { refundStatusList, lostBooking, isPushedAsLostId, refundCommStatus } = response || {};
    const { refundStatusText } = refundStatusList && refundStatusList[0] ? refundStatusList[0] : {};
    const params = {};
    params.m_v15 = pageName;
    params.m_v3 = isPushedAsLostId ? 'lost_pushed' : lostBooking ? 'lost' : 'unsuccessful';
    params.m_v16 = response.bookingID || response.bookingId;
    params.m_v24 = 'customer support';
    params.m_v5 = response.bookingDate;
    params.m_c54 = refundCommStatus
      ? refundCommStatus.toLowerCase()
      : refundStatusText
      ? refundStatusText.toLowerCase()
      : '';
    params.pageName = pageName;
    return params;
  }

  isIntlBooking = (response) => response.isInternational;
}

export default new LostBookingTrackingHelper();
