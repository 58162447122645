import React from 'react';
import {BackHandler, Platform, ScrollView, Text, TouchableHighlight, View} from 'react-native';
import BasePage from '../Common/PostSalesBasePage';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import styles from './styles/MetroMamiCss';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import {getCommonHeaders, METRO_BOOKING_DETAIL_URL} from '../utils/NetworkUtils';
import {isNetworkAvailable} from '@mmt/legacy-commons/Common/utils/AppUtils';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import ProgressView from '@mmt/legacy-commons/Common/Components/Progress/ProgressView';
import NoInternetView from '@mmt/legacy-commons/Common/Components/Error/NoInternetView';
import {colors} from '../bus/components/TopStatusCardColor';
import StickyHeader from '../hotel/details/components/StickyHeader';
import ViewControllerModule from '@mmt/legacy-commons/Native/ViewControllerModule';
import QRTicketCard from './components/QRTicketCard';
import TopStatusCardConfirmed from './components/TopStatusCardConfirmed';
import MetroConstant from './MetroConstant';
import RefundStatusCard from './components/RefundStatusCard';
import {CHAT_WITH_MYRA_CARD, LOBNAMES, NEED_HELP_CARD} from '../PostSalesConstant';
import {isLostBookingData, isMyraEnabled} from '../utils/PostSaleUtil';
import ChatWithMyraCard from '../Common/ChatWithMyraCard';
import RefundProgressCard from './components/RefundProgressCard';
import {
  getCancellationRequestNoForMetro,
  getRefundSubTitle,
  getSubTitle,
  getTitle
} from './MetroBookingDetailUtil';
import MetroBookingTrackingHelper from './MetroBookingTrackingHelper';
import ChangeInPlan from './components/ChangeInPlan';
import Support from './components/Support';
import {isNotNullAndEmpty} from '../hotel/details/utils/HotelBookingDetailUtil';
import RefundTracker from '../Common/RefundTracker';
import {isNullOrEmpty, openDialer} from '../Common/commonUtil';
import TripInfo from "./components/TripInfo";
import TopStatusCardCompleteFailed from "./components/TopStatusCardCompleteFailed";
import AlertInfo from '../Common/CommonImpInfo';
import {HEADER_ICON,TOP_STATUS_CONFIRMED_ICON,TOP_STATUS_AFTER_TRIP_ICON,TOP_STATUS_CANCELLED_ICON, TOP_STATUS_FAILED_ICON, REFUND_PROCESSED} from './MetroImageConstant';
import AdvisoryOverlay from "../Common/AdvisoryNotice/AdvisoryOverLay";
import AdvisoryNotice from "../Common/AdvisoryNotice";
import { REFUND_OOT_TRACKING } from '../Common/RefundOutOfTATForm/constants';
import LostIDBookingDetailPage from '../Common/LostIdDetailPage';
import addBookingTrackingHelper, { ADD_BOOKING_TRACKING_EVENTS } from '../summary/components/AddBooking/tracking';

export const METRO_BOOKING_DETAIL_PAGE = 'MetroBookingDetail';

export default class MetroBookingDetail extends BasePage {
    static navigationOptions = {
      header: null
    };

    constructor(props) {
      super(props, METRO_BOOKING_DETAIL_PAGE);
      this.bookingId = props.data? props.data.BOOKING_ID : (props.bookingId ? props.bookingId :'');
      this.pageData = props.data && props.data.pageData ? JSON.parse(props.data.pageData) : undefined;
      this.isLostBooking = isLostBookingData(this.pageData);
      this.mmtAuth = '';
      this.loggingTrackInfo = {};
      this.parentPage = props.data ? props.data.PARENT_PAGE : (props.PARENT_PAGE ? PARENT_PAGE :'');
      this.trackPageName = 'Mob:Customer Support:Metro:TripDetails';
      this.carOnSpotBoardingInfo = null;
      this.state = {
        responseJson: null,
        viewState: this.isLostBooking ? ViewState.SHOW_DETAIL : ViewState.LOADING,
        overlay: false,
        advisoryOverlay : false
      };
      this.toggleAdvisoryOverlay = this.toggleAdvisoryOverlay.bind(this);
      this.toggleOverlay = this.toggleOverlay.bind(this);
    }

  toggleAdvisoryOverlay(){
    this.setState({advisoryOverlay: !this.state.advisoryOverlay});
  }

    toggleOverlay(){
      this.setState({overlay: !this.state.overlay});
    }

    render() {

      return (
        <View style={styles.flex1}>
          {this.state.viewState === ViewState.ERROR && this.renderErrorView()}
          {this.state.viewState === ViewState.SHOW_DETAIL && this.renderPage()}
          {this.state.viewState === ViewState.SHOW_DETAIL && this.state.advisoryOverlay && this.renderAdvisoryOverlay()}
          {this.state.viewState === ViewState.NO_INTERNET && this.renderNoNetworkView()}
          {this.state.viewState === ViewState.LOADING && this.renderProgressView()}
        </View>
      );
    }

    componentDidMount() {
      if (!this.isLostBooking) {
        this.fetchJSONAsync(METRO_BOOKING_DETAIL_URL + this.bookingId);
      }
      if (this.pageData?.isAddBookingFlow) {
        addBookingTrackingHelper.trackLoadEvent(
          ADD_BOOKING_TRACKING_EVENTS.Addbooking_detailspage_landed,
          this.pageData?.uuid,
          this.bookingId,
        );
      }
    }

    componentWillReceiveProps(props) {
      if(props.isRefresh){
        this.setState({viewState: ViewState.LOADING});
        this.fetchJSONAsync(METRO_BOOKING_DETAIL_URL + this.bookingId);
      }
    }

    componentDidUpdate(prevProps) {
      if (this.props.data !== prevProps.data && this.props.data.refresh === true) {
        if (this.isLostBooking) {
          this.setState({
            refreshLostDetail: {},
          });
          return;
        }
      }
    }

    async fetchJSONAsync(url) {
      try {
        const hasNetwork = await isNetworkAvailable();
        if (!hasNetwork) {
          this.setState({viewState: ViewState.NO_INTERNET});
          return;
        }

        const userInfo = await HotelBookingModule.getRequestHeader(this.bookingId, 'METRO_BOOKING_DETAIL');
        this.mmtAuth = userInfo.mmtAuth;
        this.loggingTrackInfo = userInfo.loggingTrackingInfo;

        const response = await
        fetch(url, {headers: await getCommonHeaders(this.mmtAuth, this.loggingTrackInfo)});

        if (response.ok) {
          let body = await response.json();
          body.parentPage = this.parentPage;

          this.setState({
            responseJson: body,
            viewState: ViewState.SHOW_DETAIL
          });

          MetroBookingTrackingHelper.trackLoadEvent(this.trackPageName, this.state.responseJson);
        } else {
          this.setState({viewState: ViewState.ERROR});
          this.trackError();
        }
      } catch (error) {
        this.setState({viewState: ViewState.ERROR});
        this.trackError();
      }
    }

    trackError() {
      MetroBookingTrackingHelper.trackErrorEvent('Mob:Customer Support:Metro:TripDetailsError');
    }

    renderErrorView = () => (
      <ErrorView
        showRetry
        onRetry={() => {
                this.setState({viewState: ViewState.LOADING});
                this.fetchJSONAsync(METRO_BOOKING_DETAIL_URL + this.bookingId);
            }}
      />);

  renderAdvisoryOverlay(){
    return(
        <View style={styles.overlayContainer}>
          <TouchableHighlight style={styles.overlayBg}><Text>.</Text></TouchableHighlight>
          <AdvisoryOverlay
              key="RailBottomOverlay"
              pageName="RailBottomOverlay"
              advisoryNoticeText = {this.state.responseJson && this.state.responseJson.advisoryNoticeText}
              closeoverlay={this.toggleAdvisoryOverlay}
          />
        </View>
    )
  }
    renderPage() {
      if (this.isLostBooking) {
        return (
          <LostIDBookingDetailPage
            bookingId={this.bookingId}
            pageData={this.pageData}
            lobName={LOBNAMES.METRO}
            refreshLostDetail={this.state.refreshLostDetail}
            getRefundCard={({ response, canReqNoList }) => this.renderRefundCard(response, canReqNoList, true)}
          />
        );
      }
      let views = this.getMainPageViews();
      return (
        <ScrollView style={styles.GreyBg} stickyHeaderIndices={[0]}>
          <StickyHeader
            iconSrc={HEADER_ICON}
            headerColor={this.getColor(this.state.responseJson)}
            title={getTitle(this.state.responseJson)}
            titleStyle={[styles.blackColor, styles.blackFont]}
            subTitle={getSubTitle(this.state.responseJson)}
            subTitleStyle={[styles.BlackText, styles.font12]}
            onBackPress={this.goBack}
          />
          {views}
        </ScrollView>);
    }

  getColor(responseJsons) {
    if(responseJsons.bookingState.state.toUpperCase() === 'UPCOMING'){
      return [colors.confirmedStartColor, colors.confirmedEndColor];
    } else if(responseJsons.bookingState.state.toUpperCase() === 'COMPLETED'){
      return [colors.completeStartColor, colors.confirmedEndColor];
    } else if(responseJsons.bookingState.state.toUpperCase() === 'CANCELLED'){
      return [colors.cancelledStartColor, colors.cancelledEndColor];
    } else if(responseJsons.bookingState.state.toUpperCase() === 'FAILED'){
      return [colors.failedStartColor, colors.failedEndColor];
    }
    else {
      return [colors.confirmedStartColor, colors.confirmedEndColor];
    }
  }

    renderNoNetworkView = () => (
      <NoInternetView
        onRetry={() => {
                this.setState({viewState: ViewState.LOADING});
                this.fetchJSONAsync(METRO_BOOKING_DETAIL_URL + this.bookingId);
            }}
      />);

    renderProgressView = () => (<ProgressView message="Loading Your Trip..." />);

    getMainPageViews = () => {
      const views = [];
      const cards = this.state.responseJson.layoutDetail.cardList;
      cards.forEach((card) => {
        this.addCard(card, views);
      });
      return views;
    }

    renderRefundCard = (response, canRequestNoList, lostBooking = false) => {
      if (isNotNullAndEmpty(canRequestNoList)) {
        return (<RefundTracker
          key="RefundTracker"
          cancelRequests={canRequestNoList}
          bookingId={this.bookingId}
          bookingResponse={response}
          lobName={LOBNAMES.METRO}
          isLostBookingId={lostBooking}
          pageName={REFUND_OOT_TRACKING.PAGES.METRO}
        />);
      }
      return null;
    }

    addCard(card, views) {
      switch (card.cardId) {
        case MetroConstant.TOP_STATUS_CARD_DEFAULT_CONFIRMED:
          views.push(<TopStatusCardConfirmed
            key="TopStatusCardConfirmed"
            response={this.state.responseJson}
            iconPath={TOP_STATUS_CONFIRMED_ICON}
            headText={"Booking Confirmed"}
            color={[colors.confirmedStartColor, colors.confirmedEndColor]}
            bookingId={this.state.responseJson.bookingId}
            card={card}
            onBackPress={this.goBack}
            pageName={this.trackPageName}
          />);
          views.push(<TripInfo
              key="CardTripInfo"
              response={this.state.responseJson}
              card={card}
              pageName={this.trackPageName}
          />);
          break;
        case MetroConstant.QR_TICKET:
          views.push(<QRTicketCard
            key="QRCodeCard"
            response={this.state.responseJson}
            card={card}
            pageName={this.trackPageName}
          />);
          break;
        case MetroConstant.TOP_STATUS_CARD_DEFAULT_COMPLETED:
          views.push(<TopStatusCardCompleteFailed
            key="TopStatusCardCompleteFailed"
            response={this.state.responseJson}
            iconPath={TOP_STATUS_AFTER_TRIP_ICON}
            headText={"Hope you had a nice journey!"}
            color={[colors.completeStartColor, colors.completeEndColor]}
            bookingId={this.state.responseJson.bookingId}
            card={card}
            onBackPress={this.goBack}
            pageName={this.trackPageName}
          />);
          views.push(<TripInfo
              key="CardTripInfo"
              response={this.state.responseJson}
              card={card}
              pageName={this.trackPageName}
          />);
          views.push(<QRTicketCard
            key="QRCodeCard"
            response={this.state.responseJson}
            card={card}
            pageName={this.trackPageName}
          />);
          break;
        case MetroConstant.TOP_STATUS_CARD_DEFAULT_CANCELLED:
          views.push(<TopStatusCardConfirmed
            key="TopStatusCardConfirmed"
            response={this.state.responseJson}
            iconPath={TOP_STATUS_CANCELLED_ICON}
            headText={"Booking Cancelled"}
            color={[colors.cancelledStartColor, colors.cancelledEndColor]}
            bookingId={this.state.responseJson.bookingId}
            card={card}
            onBackPress={this.goBack}
            pageName={this.trackPageName}
          />);
          views.push(<TripInfo
              key="QRCodeCard"
              response={this.state.responseJson}
              card={card}
              pageName={this.trackPageName}
          />);
          break;
        case MetroConstant.TOP_STATUS_CARD_AFTER_REFUND_DONE:
          views.push(<RefundStatusCard
            key="RefundStatusCard"
            response={this.state.responseJson}
            iconPath={REFUND_PROCESSED}
            headText="Refund Processed"
            color={[colors.darkBlueStartColor, colors.darkBlueEndColor]}
            subText={getRefundSubTitle(this.state.responseJson)}
            subTextHeader={this.state.responseJson.bookingId}
            subTextDetails=""
            card={card}
            onBackPress={this.goBack}
            pageName={this.trackPageName}
          />);
          break;
        case MetroConstant.TOP_STATUS_CARD_LOST_FAILED_BOOKING:
          views.push(<TopStatusCardConfirmed
            key="TopStatusCardConfirmed"
            response={this.state.responseJson}
            iconPath={TOP_STATUS_FAILED_ICON}
            headText={"Booking couldn’t be completed. \n Refund in Progress."}
            color={[colors.failedStartColor, colors.failedEndColor]}
            bookingId={this.state.responseJson.bookingId}
            card={card}
            onBackPress={this.goBack}
            pageName={this.trackPageName}
          />);
          views.push(<TripInfo
              key="CardTripInfo"
              response={this.state.responseJson}
              card={card}
              pageName={this.trackPageName}
          />);
          break;
        case MetroConstant.TOP_STATUS_CARD_LOST_FAILED_BOOKING_CANCELLED:
          views.push(<TopStatusCardConfirmed
            key="TopStatusCardConfirmed"
            response={this.state.responseJson}
            iconPath={TOP_STATUS_CANCELLED_ICON}
            headText={"Booking couldn’t be completed. \n Refund in Progress."}
            color={[colors.failedStartColor, colors.failedEndColor]}
            bookingId={this.state.responseJson.bookingId}
            card={card}
            onBackPress={this.goBack}
            pageName={this.trackPageName}
          />);
          views.push(<TripInfo
              key="CardTripInfo"
              response={this.state.responseJson}
              card={card}
              pageName={this.trackPageName}
          />);
          break;
        case MetroConstant.REFUND_PROGRESS_CARD:
          const { cancellationDetail } = this.state.responseJson || {};
          const canRequestNoList = getCancellationRequestNoForMetro(cancellationDetail);
          views.push(this.renderRefundCard(this.state.responseJson, canRequestNoList));
          break;
        case MetroConstant.CHANGE_IN_PLANS_CARD:
          views.push(<ChangeInPlan
            key="ChangeInPlanCard"
            response={this.state.responseJson}
            pageName={this.trackPageName}
            card={card}
          />);
          break;
        case CHAT_WITH_MYRA_CARD:
          if (isMyraEnabled(this.state.responseJson.lobCode)) {
            views.push(<ChatWithMyraCard
              key={card.cardId}
              response={this.state.responseJson}
              card={card}
              pageName={this.trackPageName}
            />);
          }
          break;
        case NEED_HELP_CARD:
          views.push(<Support
            key="Support"
            supportCardClickHandler={this.supportBtnClick}
            showCallMetro={false}
            showMyraBtn={false}
            showCallus
          />);
          break;
        case MetroConstant.ALERT_INFO_CARD:
          views.push(<AlertInfo
            key="AlertInfo"
            infoList={this.state.responseJson.importantInformationList}
          />);
          break;
        case MetroConstant.ADVISORY_NOTICE:
          views.push(<AdvisoryNotice
              key="AdvisoryNoticeCard"
              heading={card.heading}
              description={card.subHeading}
              card={card}
              response={this.state.responseJson}
              togglePopUp={this.toggleAdvisoryOverlay}
          />)
          break;
      }
    }

  supportBtnClick = (actionItem) => {
    switch (actionItem.actionFamily) {
      case MetroConstant.CALL_METRO_SUPPORT_FAMILY:
        openDialer(MetroConstant.HYD_METRO_SUPPORT_CONTACT);
        MetroBookingTrackingHelper.trackClickEvent('Details', 'Call_Metro-Support', this.state.responseJson);
        break;
      case MetroConstant.CHAT_WITH_US_ACTION_BTN_FAMILY:
        GenericModule.openMyraPage();
        MetroBookingTrackingHelper.trackClickEvent('Details', 'Metro', this.state.responseJson);
        break;
      case MetroConstant.CALL_MMT_SUPPORT_FAMILY:
        openDialer(MetroConstant.MMT_SUPPORT_CONTACT_NUMBER);
        MetroBookingTrackingHelper.trackClickEvent('Details', 'Call_MMT_Support', this.state.responseJson);
        break;
      default:
        break;
    }
  };

    goBack = () => {
      if (Platform.OS === 'ios') {
        ViewControllerModule.popViewController(1);
      } else {
        BackHandler.exitApp();
      }
    };

  onHardBackPress = () => {
    if (this.state.overlay) {
      this.toggleOverlay();
      return true;
    }
    return false;
  };

  getCancelledSubTitle(response) {
    let textTitle = '';
    if (response.refundDetail) {
      const amt = response.refundDetail.amount;
      textTitle = `₹ ${amt} will be refunded to your original paymode within 7 working days`;
    }
    return textTitle;
  }
}
