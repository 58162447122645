import React from 'react';
import {View, Text, Image, TouchableOpacity} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import styles from './ChecklistOverlayCss'
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {CROSS_ICON} from '../../../../SelfDriveImageConstant';
import {handleActionClicks} from '../../../../utils/SelfDriveBookingDetailUtil';
import ZoomCarConstants from '../../../../utils/ZoomCarConstants';
import UnderTaking from '../../Undertaking';

const gradients = ["#53B2FE", "#065AF3"];

const ChecklistOverlay = (props) => {
    return (
        <React.Fragment>
            <TouchableOpacity style={styles.overlay} onPress={() => props.handlePress('')}/>
            <View style={styles.bottomOverlayWrapper}>
                <View style={[AtomicCss.flexRow, AtomicCss.marginBottom20]}>
                    <View style={AtomicCss.flex1}>
                        <Text
                            style={[AtomicCss.blackText, AtomicCss.font20, AtomicCss.blackFont, AtomicCss.marginBottom8]}>{props.header}</Text>
                        <Text style={[AtomicCss.defaultText, AtomicCss.font14, AtomicCss.marginBottom12]}>Complete the
                            checklist with the service provider (Zoomcar) to start your trip</Text>
                    </View>
                    <TouchableOpacity onPress={() => props.handlePress('')}><Image style={styles.crossIcon}
                                                                                   source={CROSS_ICON}/></TouchableOpacity>
                </View>
                <View style={AtomicCss.marginBottom35}>
                    {fetchCheckListItems(props.checkList)}
                    {
                        <UnderTaking underTaking={props.bookingDetail.underTaking}/>
                    }
                </View>
                <TouchableOpacity style={[AtomicCss.btn, AtomicCss.marginTop15]} activeOpacity={0.7} onPress={() => handleClick(props.bookingDetail)}>
                    <LinearGradient
                        colors={gradients}
                        start={{x: 0, y: 0}}
                        end={{x: 1.0, y: 0}}
                        style={[AtomicCss.btnGradient, styles.fullbtn]}
                    >
                        <Text
                            style={[AtomicCss.font16, AtomicCss.whiteText, AtomicCss.blackFont, AtomicCss.textCenter, AtomicCss.textUppercase]}>Proceed</Text>
                    </LinearGradient>
                </TouchableOpacity>
            </View>
        </React.Fragment>
    );

}

function handleClick(bookingDetail) {
    handleActionClicks(null, ZoomCarConstants.ACTION_PROCEED_CHECKLIST, bookingDetail, null);
};

function fetchCheckListItems(checkLists) {
    let views = [];
    Object.keys(checkLists).map(key => {
        views.push(
            <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom20]}>
                <View style={styles.iconsOuter}><Image style={styles.verifyIcons}
                                                       source={{uri: checkLists[key].iconUrl}}/></View>
                <View style={AtomicCss.flex1}><Text
                    style={[AtomicCss.defaultText, AtomicCss.font14]}>{checkLists[key].checkListText}</Text></View>
            </View>
        )
    })
    return views;
}

export default ChecklistOverlay;
