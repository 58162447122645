import React, { useEffect , useState } from 'react';
import { View, Text, Image , Platform } from 'react-native';
import styles from './CancellationSuccessCss';
import {showShortToast} from '@mmt/legacy-commons/Common/Components/Toast';
import {isNetworkAvailable} from '@mmt/legacy-commons/Common/utils/AppUtils';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import {getHolidayCanDoneResponse} from '../../../utils/NetworkUtils';
import NoInternetView from '@mmt/legacy-commons/Common/Components/Error/NoInternetView';
import ProgressView from '../../../Common/ProgressView/ProgressView';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import {HolidayCancellationConstant} from '../../HolidayBookingConstants';
import FullWidthButton from '../../../hotel/details/ActionButton/FullWidthButton';
import Actions from '../../../navigation/postSalesNavigation';
import HolidayBookingTrackingHelper from '../../HolidayBookingTrackingHelper';
import { themeColors } from '../../../PostSalesStyles';
import  isEmpty  from 'lodash/isEmpty';
import { resetToHolidayBookingDetail } from '../../HolidayBookingDetailUtils';
import useHardwareBackpress from "../../../Common/useHardwareBackpress";

const HolidayCancellationThankYou = (props) => {
    const [viewState, setViewState]= useState(ViewState.LOADING);
    const [responseJson, setResponseJson]= useState(null);
    useEffect(()=>{
      fetchJSONAsync();
    },[]);

  const goToBookingDetails = () => {
    const {thankyouPage} = responseJson;
    HolidayBookingTrackingHelper.trackClickEvent(
      'cancellation',
      !isEmpty(thankyouPage.buttons) && thankyouPage.buttons[0].text,
    );
    if(Platform.OS === 'web'){
      resetToHolidayBookingDetail(props.bookingId);
    }
    else {
      resetToHolidayBookingDetail(props.bookingId, true);
    }
  };

    useHardwareBackpress().addListener(goToBookingDetails);
    const fetchJSONAsync = async () => {
      try {
        const hasNetwork = await isNetworkAvailable();
        if (!hasNetwork) {
          setViewState(ViewState.NO_INTERNET);
          return;
        }
        const body = await getHolidayCanDoneResponse(props.mmtAuth, props.loggingTrackInfo, props.cancellationDoneRequest);
        if (body) {
          if (body.status) {
            setResponseJson(body);
            setViewState(ViewState.SHOW_DETAIL);
            trackLoadEvent();
          } else {
            trackError('API_STATUS_ERROR');
            showShortToast('Something went wrong');
            HolidayBookingTrackingHelper.trackViewEvent(
              'cancellation',
              'FAILED',
              'FAILED',
            );
            if(Platform.OS === 'web'){
              Actions.holidayBookingDetail({
                  data: {
                    BOOKING_ID: props.bookingId,
                    PARENT_PAGE: 'holidayBookingDetail'
                }
              });
            }else {
              Actions.popTo('holidayBookingDetail');
            }
          }
        } else {
          setViewState(ViewState.ERROR)
          trackError('API_RESPONSE_NULL');
        }
      } catch (error) {
          trackError(error);
      }
    };

  const trackLoadEvent = () => {
    HolidayBookingTrackingHelper.trackViewEvent(
      'cancellation',
      'SUCCESS',
      'SUCCESS',
    );
  };

  const trackError = (errorReason) => {
    HolidayBookingTrackingHelper.trackErrorEvent(
      'cancellation',
      props.holidayBookingResponse.bookingId,
      errorReason,
    );
  };

    const renderStateWise = () => {
      switch (viewState) {
        case ViewState.LOADING:
          return renderProgressView();
        case ViewState.NO_INTERNET:
          return renderNoNetworkView();
        case ViewState.ERROR:
          return renderErrorView();
        default:
          return renderPage();
      }
    }

    const renderNoNetworkView = () => (
      <NoInternetView
        onRetry={() => {
          setStateLoading();
        }}
      />);

    const setStateLoading = () => {
        setViewState(ViewState.LOADING);
        fetchJSONAsync();
    };

    const renderProgressView = () => (<ProgressView message={HolidayCancellationConstant.INITIALIZING_CANCELLATION}/>);

    const renderErrorView = () => (
      <ErrorView
        showImage
        showRetry
        onRetry={() => {
          setStateLoading();
        }}
        buttonGradient={themeColors.gradientBtnColor}
      />);

    const renderPage = () => {
      const {thankyouPage} = responseJson;
        return (
          <View style={styles.pageWrapper}>
              <View style={styles.messageContent}>
                  <Image style={styles.messageIcon} source={require('@mmt/legacy-assets/src/holidays/cancellation/success.webp')} />
                  <Text style={styles.messageTitle}>{thankyouPage.header}</Text>
                  <Text style={styles.messageDesc}>{thankyouPage.subHeader}</Text>
              </View>
              <View style={styles.footerBtnWrapper}>
                  <FullWidthButton buttonText={thankyouPage.buttons[0].text} clickHandler={goToBookingDetails}/>
              </View>
          </View>
        );
    }

    return (
        <View style={styles.parentContainer}>
          {renderStateWise()}
        </View>
    );
};

export default HolidayCancellationThankYou;
