import React, { useEffect } from 'react';
import TravelInsurance from '@core_app_common/TravelInsuranceOld';
import { LOBNAMES } from 'apps/post-sales/src/PostSalesConstant';
import { travelInsuranceAppData } from '../../../../Common/travelInsuranceThemeContext';
import { MIMA_BASE_URL, getCommonHeaders } from 'apps/post-sales/src/utils/NetworkUtils';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import PostSalesModule from '@mmt/legacy-commons/Native/PostSalesModule';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import { createPaymentModuleRequestAndroid, createPaymentModuleRequestIOS } from './apiHelper';
import PostSalesBookingTrackingHelper from '../../../../PostSalesBookingTrackingHelper';
import { Platform, DeviceEventEmitter } from 'react-native';
import { PAYMENT_EVENT_LISTNER } from '../../HotelBookingDetailsConstant';
import Actions from 'apps/post-sales/src/navigation/postSalesNavigation';
import { resetToHotelDetail } from '../../../details/utils/HotelBookingDetailUtil';
import { isEmpty } from 'lodash';
import { getStaticData } from 'apps/post-sales/src/staticData/staticData';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { TravelInsuranceCardProp, HoldResponseData } from './types';

const TravelInsuranceCard: React.FC<TravelInsuranceCardProp> = ({ pageName, bookingId, response }) => {
  const onClickTracking = (eventName: string) => {
    PostSalesBookingTrackingHelper.trackClickEvent(
      pageName,
      `TravelInsurance|${eventName}`,
      bookingId,
    );
  };
  const onLoadTracking = () => {
    PostSalesBookingTrackingHelper.trackShownEvent(pageName, 'TravelInsurance_card_shown', bookingId);
  };

  useEffect(() => {
    return () => {
      if (Platform.OS !== 'web') {
        const count = DeviceEventEmitter.listenerCount(PAYMENT_EVENT_LISTNER);
        if (count > 0) {
          DeviceEventEmitter.removeAllListeners(PAYMENT_EVENT_LISTNER);
        }
      }
    };
  }, []);

  const tracking = {
    onClickTracking,
    onLoadTracking,
  };
  const actionList = (actionId: string) => {
    if (actionId === 'backToBooking') {
      resetToHotelDetail(bookingId);
    }
  };

  const requestData = { bookingId: bookingId };

  const openPaymentPage = (holdResponse: HoldResponseData) => {
    if (Platform.OS === 'android') {
      const paymentRequest = createPaymentModuleRequestAndroid(holdResponse, requestData);
      PostSalesModule.openPaymentPage(JSON.stringify(paymentRequest));
    } else if (Platform.OS === 'ios') {
      const body = createPaymentModuleRequestIOS(holdResponse, requestData);
      GenericModule.openPaymentLanding(body);
    }
  };

  const paymentHandler = (holdResponse: HoldResponseData) => {
    const { paymentUrl } = holdResponse;
    if (Platform.OS === 'web') {
      window.location.href = paymentUrl;
    } else {
      DeviceEventEmitter.removeAllListeners(PAYMENT_EVENT_LISTNER);
      DeviceEventEmitter.addListener(PAYMENT_EVENT_LISTNER, (paymentRes) => {
        DeviceEventEmitter.removeAllListeners(PAYMENT_EVENT_LISTNER);
        if (!isEmpty(paymentRes?.PAYMENT_RESPONSE_VO)) {
          const paymentJson = JSON.parse(paymentRes.PAYMENT_RESPONSE_VO);
          if (!isEmpty(paymentJson.response)) {
            const { description, title, buttonText, isSuccess } =
              JSON.parse(paymentJson.response) || {};
            const imageUrl = isSuccess
              ? 'https://imgak.mmtcdn.com/mima/images/mobile/AncillaryAddOnsSuccessfull_Icon.webp'
              : 'https://imgak.mmtcdn.com/mima/images/mobile/AncillaryBookingFailed_Icon.webp';
            Actions.hotelTravelInsuranceThankYou({
              description: description,
              message: title,
              success: imageUrl,
              iconUrl: imageUrl,
              bookingId: bookingId,
              buttonText: buttonText,
            });
          } else {
            actionList('backToBooking');
          }
        } else {
          actionList('backToBooking');
        }
      });
      openPaymentPage(holdResponse);
    }
  };
  const { travelInsuranceText = {} } = getStaticData();
  const { errorHeading, errorDesc } = travelInsuranceText;
  const nativeModules = {
    backendBaseUrl: MIMA_BASE_URL,
    getTravelInsuranceHeaders: async () => {
      const userInfo = await HotelBookingModule.getRequestHeader(bookingId, 'TravelInsurance');
      const headers = await getCommonHeaders(
        userInfo.authToken,
        userInfo?.loggingTrackingInfo || '',
        {
          psLob: LOBNAMES.HOTEL,
        },
      );
      return headers;
    },
    icons: {
      closeIcon: 'https://imgak.mmtcdn.com/mima/images/mobile/crossIcon1.webp',
      rightArrowIcon: 'https://imgak.mmtcdn.com/goibibo/images/arrow_oneway.png',
      newTagImg: 'https://imgak.mmtcdn.com/mima/images/mobile/digiYatra_newTag.webp',
    },
    staticText: {
      errorHeading,
      errorDesc,
    },
  };
  return (
    <TravelInsurance
      bookingId={bookingId}
      lobName={LOBNAMES.HOTEL}
      tracking={tracking}
      themeData={travelInsuranceAppData}
      response={response}
      paymentHandler={paymentHandler}
      nativeModules={nativeModules}
      customStyles={AtomicCss.borderRadius16}
    />
  );
};
export default TravelInsuranceCard;