import React from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';
import Actions from '../../../navigation/postSalesNavigation';
import HTMLView from 'react-native-htmlview';
import {colors} from '@mmt/legacy-commons/Styles/globalStyles';
import Header from '@mmt/legacy-commons/Common/Components/Header/Header';

const AcmeFaq = response => (
    <View style={styles.container}>
      <Header
        title="Frequenty Asked Questions"
        onBackPress={() => {
          Actions.pop();
        }}
        type="Elevated"
      />
      <ScrollView style={styles.scrollViewStyle}>
        <HTMLView value={response.acmeBookingInfo.acmeBookingDetails[0].acmeAdditionalInfo.faq} />
      </ScrollView>
    </View>
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
    ...StyleSheet.absoluteFillObject
  },
  scrollViewStyle: {
    paddingHorizontal: 8,
    backgroundColor: colors.white,
    paddingVertical: 15
  }
});


export default AcmeFaq;
