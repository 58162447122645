import { useEffect, useState } from 'react';
import { getCommonHeaders, DIGIT_INSURANCE_LISTING_URL } from '../../../../../utils/NetworkUtils';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import { ViewState } from '../../../../../PostSalesConstant';

export const useTripInsuranceListingDetail = (bookingId, uniqueCrId) => {
  const [response, setResponse] = useState(null);
  const [viewState, setViewState] = useState(ViewState.LOADING);
  useEffect(() => {
    (async () => {
      try {
        setViewState(ViewState.LOADING);
        const userInfo = await HotelBookingModule.getRequestHeader(
          bookingId,
          'DIGIT_INSURANCE_DETAIL',
        );
        const request = {
          bookingId: bookingId,
          crID: uniqueCrId,
        };
        const apiResponse = await fetch(DIGIT_INSURANCE_LISTING_URL, {
          method: 'POST',
          headers: await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo),
          body: JSON.stringify(request),
        });
        if (apiResponse && apiResponse.ok) {
          const body = await apiResponse.json();
          if (body.responseFunnelSuccess) {
            setViewState(ViewState.LOADED);
            setResponse({ ...body, uniqueCrId });
          } else {
            setViewState(ViewState.ERROR);
          }
        } else {
          setViewState(ViewState.ERROR);
        }
      } catch (e) {
        setViewState(ViewState.ERROR);
      }
    })();
  }, [bookingId, uniqueCrId]);

  return [viewState, response];
};
