import {fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';

const getStyles = (fonts) => {
  return {
    payDtlsCard: {
      backgroundColor: '#ffedd1',
      ...getPlatformElevation(2),
      paddingVertical: 20,
      paddingLeft: 16,
      justifyContent: 'space-between',
    },
    infoCardHading: {
      flexDirection: 'row',
      paddingRight: 16,
    },
    headingTxt: {
      color: '#4a4a4a',
      flex: 1,
      ...fonts.font16,
      fontFamily: fonts.black,
    },
    desc: {
      color: '#4a4a4a',
      flex: 1,
      ...fonts.font12,
      fontFamily: fonts.bold,
      marginTop: 3,
    },
    infoIcon: {
      width: 14,
      height: 14,
      marginLeft: 5,
    },
    priceIconStyle: {
      width: 21,
      height: 21,
      marginRight: 10,
    },
    rowSection: {
      borderBottomColor: '#bfbfbf',
      borderBottomWidth: 1,
      paddingBottom: 8,
      paddingTop: 12,
      paddingRight: 20,
      marginLeft: 38,
    },
    lastRow: {
      borderBottomColor: 'transparent',
      borderBottomWidth: 0,
      paddingBottom: 0,
    },
  }
} 

export default getStyles;