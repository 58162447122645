import { StyleSheet } from 'react-native';
import { colors, fonts } from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';

const styles = StyleSheet.create({
  whiteCard: {
    backgroundColor: colors.white,
    ...getPlatformElevation(2),
    marginBottom: 3,
    padding: 16,
  },
  tag: {
    backgroundColor: colors.creamWhite,
    borderRadius: 2,
    paddingVertical: 3,
    paddingHorizontal: 6,
    alignSelf: 'flex-start',
    marginBottom: 16,
  },
  tagTxt: {
    color: colors.lightYello,
    fontSize: 10,
    fontFamily: fonts.black,
  },
  cardTitle: {
    color: colors.black,
    fontFamily: fonts.black,
    fontSize: 14,
    marginBottom: 10,
  },
  cardDesc: {
    color: colors.black,
    fontFamily: fonts.regular,
    fontSize: 12,
    marginBottom: 10,
  },
  uploadCard: {
    backgroundColor: colors.lightGrey6,
    marginBottom: 8,
    paddingLeft: 12,
    paddingRight: 24,
    paddingVertical: 16,
    borderRadius: 4,
  },
  uploadCardHeading: {
    color: colors.black,
    fontFamily: fonts.bold,
    fontSize: 16,
    marginBottom: 2,
    lineHeight: 24,
    marginRight: 6,
    flex: 1,
  },
  uploadCardHeadingSuccess: {
    color: colors.black,
    fontFamily: fonts.bold,
    fontSize: 16,
    marginBottom: 2,
    lineHeight: 24,
    marginRight: 6,
  },
  uploadCardInfo: {
    color: colors.greyLight,
    fontFamily: fonts.regular,
    fontSize: 13,
    lineHeight: 20,
  },
  lftDtls: {
    flex: 1,
  },
  linkWrpper: {
    top: 10,
  },
  linkTxt: {
    color: colors.azure,
    fontSize: 14,
    fontFamily: fonts.black,
    lineHeight: 16.8,
  },
  uploadIconWrapper: {
    width: 40,
    height: 44,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.lightGrey,
    borderRadius: 4,
    marginRight: 12,
  },
  failedIconWrapper: {
    width: 40,
    height: 44,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.lightPink,
    borderRadius: 4,
    marginRight: 12,
  },
  uploadIcon: {
    width: 24,
    height: 24,
    marginRight: 12,
    marginTop: 2,
  },
  boldFont: {
    fontFamily: fonts.bold,
  },
  blackFont: {
    fontFamily: fonts.black,
  },
  blackTxt: {
    color: colors.black,
  },
  defaultTxt: {
    color: colors.defaultTextColor,
  },
  progressBarWrppaer: {
    marginTop: -8,
    width: '100%',
  },
  closeWrapper: {
    width: 24,
    height: 24,
    backgroundColor: colors.grey8,
    borderRadius: 50,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeIcon: {
    width: 10,
    height: 10,
  },
  progressInfoTxt: {
    color: colors.greyLight,
    fontSize: 13,
    fontFamily: fonts.regular,
  },
  failedTxt: {
    color: colors.red,
  },
  deleteIconStyle: {
    width: 24,
    height: 28,
    marginLeft: 20,
  },
  successIconStyle: {
    width: 20,
    height: 20,
  },
  attachedProofWrapper: {
    padding: 0,
    //...getPlatformElevation(2),
    borderWidth: 1,
    borderColor: colors.grey8,
    borderRadius: 4,
  },
  uploadproffImg: {
    width: '100%',
    height: 120,
  },
  attachedProof: {
    padding: 16,
  },
  errorSection: {
    paddingHorizontal: 12,
  },
});

export default styles;
