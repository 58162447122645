const BusBookingDetailsConstant = {
  RETURN_TRIP_OFFER: 'ReturnTripOffer',
  TOP_STATUS_CARD_CAN_INIT_TO_REFUND_DONE: 'TopStatusCard_BetweenCanInitToRefundDone',
  TOP_STATUS_CARD_AFTER_REFUND_DONE: 'TopStatusCard_AfterRefundDone',
  TOP_STATUS_CARD_BOOKING_TO_BOARDING_TIME_BOOKING: 'TopStatusCard_BookingToBoardingTime',
  TOP_STATUS_CARD_BOARDING_TO_DEPARTURE_TIME: 'TopStatusCard_BoardingToDepartureTime',
  TOP_STATUS_CARD_ON_TRIP: 'TopStatusCard_OnTrip',
  TOP_STATUS_CARD_COMPLETTION_OF_TRIP_TO_FOREVER: 'TopStatusCard_CompletionOfTripToForever',
  TOP_STATUS_CARD_LOST_FAILED_BOOKING: 'TopStatusCard_LostFailedBooking',
  TOP_STATUS_CARD_LOST_FAILED_BOOKING_CANCELLED: 'TopStatusCard_LostFailedBookingCancelled',
  TOP_STATUS_CARD_PARTIALY_CAN_AT_LEAST_1_UPCOMING: 'TopStatusCard_PartiallyCancelledAtLeast1Upcoming',
  TOP_STATUS_CARD_PARTIALY_CAN_AT_REST_ALL_COMPLETED: 'TopStatusCard_PartiallyCancelledRestAllCompleted',
  TOP_STATUS_CARD_DEFAULT_COMPLETED: 'TopStatusCard_DEFAULT_COMPLETED',
  TOP_STATUS_CARD_DEFAULT_CONFIRMED: 'TopStatusCard_DEFAULT_CONFIRMED',
  TOP_STATUS_CARD_DEFAULT_CANCELLED: 'TopStatusCard_DEFAULT_CANCELLED',
  TOP_STATUS_CARD_JUST_DEFAULT_MODIFIED: 'TopStatusCard_DEFAULT_MODIFIED',
  TOP_STATUS_CARD_JUST_AFTER_MODIFICATION: 'TopStatusCard_JustAfterModification',
  TOP_STATUS_CARD_MODIFIED_BOOKING_ON_TRIP: 'TopStatusCard_ModifiedBookingOnTrip',
  TOP_STATUS_CARD_MODIFIED_BOOKING_TRIP_COMPLETED: 'ModifiedBookingTripCompleted',
  BUS_ITINERARY_CARD: 'BusItineraryCard',
  BUS_HOTEL_CROSS_SELL: 'BusHotelCrossSellCard',
  BUS_TRAVELLER_CARD: 'BusTravellerCard',
  BUS_PRICE_CARD: 'BusPriceCard',
  BUS_REFUND_PROGRESS_CARD: 'RefundProgressCard',
  BUS_CHANGE_IN_PLANS_CARD: 'ChangeInPlansCard',
  BUS_REFUND_BREAKUP_CARD: 'RefundBreakupCard',
  BUS_NEED_HELP_CARD: 'NeedHelpCard',
  SPECIAL_CLAIM_CARD: 'SpecialClaimCard',
  RECTANGLE_FULL_BUTTON: 'RecFullBtn',
  ROUND_BUTTON: 'RbBtnWithIcon',
  RECTANGLE_PRIMARY_FULL_WIDTH: 'CapsuleFullBtnFill',
  RECTANGLE_PRIMARY_HALF_WIDTH: 'CapsuleHalfBtnFill',
  RECTANGLE_SECONDARY_FULL_WIDTH: 'CapsuleFullBtn',
  RECTANGLE_SECONDARY_HALF_WIDTH: 'CapsuleHalfBtn',
  WHITE_BUTTON_FULL: 'CapsuleWhiteBtn',
  WHITE_BUTTON_HALF: 'CapsuleWhiteBtnHalf',
  BUS_MYWALLET: 'MYWALLET',
  BUS_ECOUPON: 'ECoupon',
  BUS_MyWALLET: 'MyWallet',
  CANCELLATION_POLICY_CARD: 'CancellationPolicyCard',
  AMENITIES_CARD: "AmenityCard",
  BUS_ADD_TECH_CARD: 'BusAddTechCard',
  ADVISORY_NOTICE : 'AdvisoryNoticeCard',
  PRIMO_BANNER : 'PrimoBanner',
  BUS_DOWNLOAD_VOUCHER: 'BUS_DOWNLOAD_VOUCHER',

  ACTION_CALL_OPERATOR: 'CallOperator',
  ACTION_CALL_MMT_SUPPORT: 'CallMMTSupport',
  ACTION_CANCEL_FULL_BOOKING: 'CancelFullBooking',
  ACTION_CANCEL_PARTIAL_BOOKING: 'CancelPartBooking',
  ACTION_DOWNLOAD_TICKET: 'DownloadTicket',
  ACTION_DOWNLOAD_INVOICE: 'DownloadInvoice',
  ACTION_CALL_HELPLINE: 'CallHelpline',
  ACTION_CANCEL_BOOKING: 'Cancel',
  ACTION_TRACK_BUS: 'TrackBus',
  ACTION_BUS_MAKE_REQUEST: 'BusMakeRequest',
  ACTION_BUS_WRITE_A_REVIEW: 'BusWriteAReview',
  ACTION_BUS_OPEN_OVERLAY: 'OpenBusSupportMenu',
  ACTION_BUS_OPEN_OVERLAY2: 'OpenBusSupportMenu2',
  TRIGGER_CUSTOMER_INVOICE: 'Bus_CustomerInvoice',
  TRIGGER_CUSTOMER_ETicket: "Bus_ETicket",
  ACTION_BUS_GOTO_HELP_SECTION: 'GoToBusHelpSection',
  ACTION_BUS_CALL_US: 'BusCallUs',
  ACTION_BOOK_AGAIN: 'BookAgain',
  ACTION_VIEW_PRICE_BREAKUP: 'SeePriceBreakup',
  ACTION_VIEW_MORE : 'ViewMore',
  HELPLINE_NUMBER: '0124 4628747',
  TOP_STATUS_CARD_FAILED_SUBTEXT: 'This was due to a technical glitch at our end. Sorry about that. In case money was deducted, the amount will be refunded in 7 business days.',
  // omniture for page name check
  REACT_HOTEL_ERR_PAGE: 'react_hotel_err_page',
  REACT_HOTEL_DETAILS_PAGE: 'react_hotel_details_page',
  ALERT_INFO_CARD: 'alertinfocard',
  ACTION_RAISE_SPECIAL_CLAIM: 'BusSupport',
  SUPPORT: 'SupportCard',
  REFUND_REQUEST: 'REFUND_REQUEST',
  HELP_SPECIALIST_TEAM: 'HELP_SPECIALIST_TEAM',
  CALL_SPECIALIST_TEAM: 'CALL_SPECIALIST_TEAM',
  HELP_AND_BUS_SUPPORT: 'HelpAndSupport_BusSupport',
  MSR_MAIN_CARD: 'MsrMainCard',
  AMENITY_ISSUE: 'SERVICE_PROBLEM_AMENITIES',
  AC_ISSUE: 'SERVICE_PROBLEM_AC_ISSUE',
  TRIP_ASSURANCE_CARD: 'TripAssuranceCard',
  TRIP_PASS_CARD: 'TripPassCard',
  TRIP_ASSURANCE_TnC: 'Terms and Conditions',
  TRIP_ASSURANCE_LABEL: 'FREE CANCELLATION',
  TICKET_PRIMARY_TYPE: 'Bus',
  TICKET_SECONDARY_TYPE: 'Bus Cancellation',
  TICKET_TERNARY_TYPE: 'Initiate Bus Cancellation',
  CHANGE_TRAVEL_DATE_CARD : "FlexiDateChangeCard",
  COUNT_OF_AMENITY_TO_BE_SHOWN: 4,
  CALENDAR_EVENT_RECEIVED : 'calEventReceived',
  SELECT_BUS_HEADER : 'Select Bus',
  CONFIRM_TRAVEL_DATE_HEADER : 'Confirm Travel Date',
  DATE_CHANGE_OVERLAY :'dateChangeOverlay',
  BUS_NOT_FOUND_OVERLAY:'busNotFoundOverlay',
  CONFIRM_CANCEL_OVERLAY:'confirmCancelOverlay',
  BUS_BOOKING_DETAILS_PAGE : 'BusBookingDetails',
  CANCELLATION_POLICY_OVERLAY: 'cancellationPolicyOverlay',
  BUS_INSURANCE_CARD:'BusInsuranceCard',
  BUS_RAP_CARD:'BusRapInsuranceCard',
  ClAIM_REJECTED_CARD:'TripAssuredClaimRejectCard',
  CLAIM_APPROVED_CARD:'TripAssuredClaimApprovedCard'
};

export const BusTrackingConstant = {
  RETURN_TRIP_EVENT:    'RTD_ChooseDates',
  BUS_BOOKING_DETAILS_PAGE: 'TripDetails',
  CANCEL_BOOKING: 'CancelBooking',
  BUS_OPERATOR_HELP: 'Buscancelledby_Operator_Help',
  BUS_SERVICE_HELP: 'Busserviceissue_Help',
  BUS_CALL_HELP: 'calloperator_Help',
}

export const PageName = {
  TRIP_DETAILS : 'TripDetails'
}

export const ClickEvents = {
  CANCEL_BOOKING : 'CancelBooking'
}

export default BusBookingDetailsConstant;
