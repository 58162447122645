import { StyleSheet } from 'react-native';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
import { ps_colors } from '../../../../../../../PostSalesStyles';

const createStyle = (theme) => StyleSheet.create({
  fullPage: { flex: 1, paddingBottom: 70 },
  topSection: {
    backgroundColor:  theme.color.pink3,
    flex: 1,
    paddingTop: 65,
    paddingBottom: 78,
    alignItems: 'center',
  },
  mainconStyle: { width: 64, height: 64, marginBottom: 20 },
  detailSection: { alignItems: 'center', marginTop: -78 },
  detailsTop: {
    backgroundColor: theme.color.lightGrey6,
    padding: 16,
    borderRadius: 4,
  },
  buttonBottomWrapper: {
    position: 'absolute',
    bottom: 16,
    paddingHorizontal: 8,
    width: '100%',
    alignItems: 'center',
  },
  titleHeading: {
    color:  theme.color.red17,
    marginBottom: 35,
  },
  detailCard: {
    borderRadius: 4,
    marginHorizontal: 12,
    backgroundColor: theme.color.white,
    ...getPlatformElevation(2),
    padding: 6,
  },
  contactCard: {
    borderRadius: 4,
    backgroundColor: theme.color.white,
    ...getPlatformElevation(2),
    padding: 18,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  flightIcon: {
    width: 16,
    height: 16,
    marginRight: 4,
    borderRadius: 2,
  },
  arrowStyle: {
    width: 24,
    height: 24,
    marginLeft: -2,
  },
});
export default createStyle;
