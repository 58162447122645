import { I18nManager } from "react-native";
import { ROUTE_PATHS } from "./WebRouterConst";
import { createBrowserHistory } from 'history';
import {isRTL, getRefreshURL, appendURLParams, getSource } from "../webUtils";

I18nManager.forceRTL(isRTL());

export const history = createBrowserHistory();

const replaceHistoryIfActIsPresent = () => {
  let currentURLParams = new URLSearchParams(location.search);
  if (currentURLParams.get('act')) {
    let index = 0;
    let path = window.location.pathname
    currentURLParams.forEach((value, key) => {
      if (key !== "act") {
        if (index == 0) {
          path += "?" + key + "=" + value;
        } else {
          path += "&" + key + "=" + value;
        }
        index++;
      }
    });
    history.replace(path);
  }
}

export const cleanStateForHistory = (state) => {
  const stateWithNoFunctions = {};
  for (const key of Object.keys(state)) {
    if (typeof state[key] !== "function") {
      stateWithNoFunctions[key] = state[key];
    }
  }
  return stateWithNoFunctions;
}

// if you want to pass params to different path than please add params key here for passing them
export const defaultURLParams = ["bookingId", "act", "cmp", "version"];

export const redirectUrl = (path, params, isAppendURlParams = true, urlParams = defaultURLParams) => {
  let url = isAppendURlParams ? appendURLParams(path, params, urlParams) : path;
  replaceHistoryIfActIsPresent();
  if (params.type === ActionConst.REPLACE) {
    history.replace(url, cleanStateForHistory(params));
  } else {
    history.push(url, cleanStateForHistory(params));
  }
}

const featureNotSupportAlert = () => {
  alert( "Currently, we do not support this feature here. However, you can visit the app to complete your request.");
}

let Actions = {
  pop() {
    const source = getSource();
    if (source && source.toLowerCase() === 'android') {
      mmt_android_bridge.handleGoBack();
    } else {
      history.goBack();
    }
  },
  // TODO: need to improve this
  popTo(){
    this.pop();
  },
  refresh(params) {
    const url = getRefreshURL(params);
    redirectUrl(url, cleanStateForHistory(params), false);
  },
  currentScene() {
    const currentPath = location.pathname;
    const routeObj = Object.values(ROUTE_PATHS).find(route => route.pathName === currentPath);
    if (routeObj) {
      return routeObj.pageKey;
    }
    return '';
  },
  exit(){
    history.goBack();
  },
};

Object.values(ROUTE_PATHS).map((route) => {
  Actions[route.pageKey] = function (params) {
    //if backroute key is present add it to params(route State)
    if (route.backRoute) {
      params.backRoute = appendURLParams(
        route.backRoute,
        params,
        defaultURLParams
      );
    }

    if (route.isFeatureNotSupported) {
      featureNotSupportAlert()
    } else if(route.customFunction){
      route.customFunction(redirectUrl, route.pathName, params)
    } else {
      redirectUrl(route.pathName, params);
    }
  }
})

const ActionConst = {
  REPLACE: 'REPLACE'
};

export { ActionConst, Actions };

export default Actions;
