import { Dimensions, StyleSheet } from 'react-native';

const windowHeight = Dimensions.get('window').height;
const windowWidth = Dimensions.get('window').width;

const styles = StyleSheet.create({
  overlayWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: windowHeight,
    width: windowWidth,
    elevation: 100,
    zIndex: 100,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
    height: '100%',
    width: '100%',
    zIndex: 1,
  },
  overlayContent: {
    position: 'absolute',
    zIndex: 333,
    maxHeight: 460,
    width: windowWidth - 36,
    borderRadius: 5,
    marginHorizontal: 18,
    paddingBottom: 120,
    top: 0
  },
  isBorderRadius: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  closeWrapper: {
    position: 'absolute',
    bottom: -135,
    left: 0,
    right: 0,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 30,
    elevation: 30,
  },
  closeStyle: {
    borderRadius: 40,
    paddingVertical: 17,
    paddingHorizontal: 17,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: 90,
  },
  closeIconStyle: {
    width: 14,
    height: 14,
    marginRight: 6,
  },
});

export default styles;
