import { StyleSheet, Dimensions } from 'react-native';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
const windowW = Dimensions.get('window').width;

export default (theme, fonts) => {
  const { color } = theme;
  return StyleSheet.create({
    container: {
      backgroundColor: color.greyBookedSeat,
      flex: 1,
      height: 300,
    },
    topWrapper: {
      backgroundColor: color.limeGreen,
      padding: 16,
      paddingBottom: 0,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderBottomLeftRadius: 16,
      borderBottomRightRadius: 16,
      marginBottom: 10,
    },
    scrollSection: {
      paddingBottom: 16,
    },
    driverAssignedWrapper: {
      borderBottomWidth: 1,
      borderBottomColor: color.lightGray,
      marginHorizontal: 16,
      paddingBottom: 12,
    },
    popupWrapper: {
      position: 'absolute',
      top: -45,
      left: 0,
      height: Dimensions.get('window').height - 500,
      width: Dimensions.get('window').width,
      zIndex: 100,
      flexDirection: 'row',
    },
    popupBg: {
      position: 'absolute',
      top: 0,
      left: 0,
      backgroundColor: color.grey34,
      height: '100%',
      width: '100%',
      zIndex: 1,
    },
    popupContentWrapper: {
      position: 'relative',
      zIndex: 14,
      marginTop: 14,
      width: '100%',
      paddingHorizontal: 16,
      top: 62,
    },
    blueButtonWrapper: {
      backgroundColor: color.azure,
      padding: 8,
      borderRadius: 4,
      width: 120,
      alignSelf: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      marginVertical: 15,
    },
    whiteCardNeg20: {
      marginTop: -20,
      zIndex: 5,
    },
    headerWrapper: {
      zIndex: 2,
      width: windowW,
    },
    innerWrpper: {
      zIndex: 2,
      paddingHorizontal: 16,
    },
    marginTop30: {
      marginTop: -35,
      ...getPlatformElevation(2),
    },
    marginVert18: {
      marginTop: 18,
      marginBottom: 18,
    },
    expandedStyle: {
      marginBottom: 68,
    },
    collapsdStyle: {
      marginBottom: -20,
    },
    liveTrackingCollapsed: {
      marginBottom: -34,
    },
    livetrackingStyle: {
      marginBottom: '30%',
    },
    otpCustomStyle: {
      marginHorizontal: 16,
    },
    subCardStyle: {
      marginTop: -45,
      zIndex: 10,
    },
  });
};

export const cardStyles = StyleSheet.create({
  card: {
    width: '100%',
  },
  cardAbsolute: {
    position: 'absolute',
    bottom: 0,
  },
  cardRelative: {
    position: 'relative',
    bottom: 'auto',
  },
});
