import { getStaticData } from '../../../staticData/staticData';
import { COMMIT, DEAFULTREFUNDMODE, PREVIEW } from '../../../PostSalesConstant';
import { getCancelledPaxLineIds } from '../../details/RailBookingDetailsUtil';
import { RailBookingDetails, Segment, TGCancelResponse } from '../../types';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import { addRupeeSign } from 'apps/post-sales/src/Common/commonUtil';
import isEmpty from 'lodash/isEmpty';
import { RAIL_TG_CANCEL_API } from '../../details/constants';


export const getPreviewRequest = (bookingDetails: RailBookingDetails , actionKey: string, isFullCancellation: boolean) => {
    return  {
        bookingID: bookingDetails.bookingID,
        action: PREVIEW,
        refundConfig: bookingDetails.railRacTgInfo[actionKey],
        refundOption: DEAFULTREFUNDMODE,
        isFullCancellation: isFullCancellation,
        functionType: 'CancellationRequest'
    };
}

export const getCommitRequest = (bookingDetails: RailBookingDetails , actionKey: string, isFullCancellation: boolean, refundOption: string, reason: string) => {
    return  {
        bookingID: bookingDetails.bookingID,
        action: COMMIT,
        reason: reason,
        refundConfig: bookingDetails.railRacTgInfo[actionKey],
        isFullCancellation: isFullCancellation,
        refundOption: refundOption,
        functionType: 'CancellationRequest',
      };
}

export const checkViewState = (data : any, apiType: string, setStateAction: ( state:string ) => void, dataHandler: (error: string) => void) => {
    const staticData = getStaticData();
    const { errorText } = staticData;
    if (data) {
        const errorStr = data?.response?.errorText;
        if (data.error || errorStr) {
          if (data.error?.toLowerCase &&  data.error?.toLowerCase() === ViewState.NO_INTERNET) {
            setStateAction(ViewState.NO_INTERNET);
            dataHandler(errorText.checkYourInternetConnectionError)
          } else if(errorStr){
            if ( errorStr.length > 0 ) {
                setStateAction(ViewState.ERROR);
                dataHandler(errorStr)
            }else{
                setStateAction(ViewState.ERROR);
                dataHandler(errorText.somethingWentWrongTryAgainError)
            }
          }else {
            setStateAction(ViewState.ERROR);
            dataHandler(errorText.somethingWentWrongTryAgainError)
          }
        } else {
            if( apiType === RAIL_TG_CANCEL_API.PREVIEW && (!data.ticketCancellationPreview || Object.keys(data.ticketCancellationPreview).length === 0) ) {
                setStateAction(ViewState.ERROR);
                dataHandler(errorText.somethingWentWrongTryAgainError)
            }else if( apiType === RAIL_TG_CANCEL_API.REFUND_MODE && (isEmpty(data.cancellationRefundMode) || isEmpty(data.cancellationRefundMode.refundModeList)) ){
                setStateAction(ViewState.ERROR);
                dataHandler(errorText.somethingWentWrongTryAgainError)
            }else if(  apiType === RAIL_TG_CANCEL_API.COMMIT && isEmpty(data.refundSuccessActions) ){
                setStateAction(ViewState.ERROR);
                dataHandler(errorText.somethingWentWrongTryAgainError)
            }else{
                setStateAction(ViewState.SHOW_DETAIL);
            }
        }
      }
}

const getAmountPaid = (previewResponse: TGCancelResponse) => {
    let amountPaid = 0;
    if (previewResponse && previewResponse.selectedPaxAmountPaid) {
      const fareBreakupList = previewResponse.selectedPaxAmountPaid;
      let i = 0;
      for (i = 0; i < fareBreakupList.length; i++) {
        amountPaid += fareBreakupList[i].amountInDouble;
      }
    }

    return amountPaid;
}

export const fareBreakUpObject = (previewResponse: TGCancelResponse) => {
    let totalRefundAmount = '0.0';
    let totalCancelationCharges = '0.0';
    let totalPaidAmount = '0.0';
    if(!isEmpty(previewResponse)){
        totalRefundAmount = addRupeeSign(previewResponse?.ticketCancellationPreview?.refundBreakup?.totalRefund.amount);
        totalCancelationCharges = `-${addRupeeSign(previewResponse?.ticketCancellationPreview?.cancellationFare?.totalPenalty.amount)}`;
        totalPaidAmount = addRupeeSign(getAmountPaid(previewResponse));
    }

    return {totalRefundAmount, totalCancelationCharges, totalPaidAmount};
}

export const getPaidComponent = (previewResponse: TGCancelResponse) => {
    const components = [];

    if (previewResponse && previewResponse.ticketCancellationPreview) {
        const fareBreakupList = previewResponse.selectedPaxAmountPaid;
        let i = 0;
        for (i = 0; i < fareBreakupList.length; i++) {
            const currentFareBreakup = fareBreakupList[i];
            const segment : Segment = {} ;
            if(currentFareBreakup!=null){
                segment.label = fareBreakupList[i].text;
                segment.value = fareBreakupList[i].amount;
                segment.order = i + 1;
                components.push(segment);
            }else{

            }
        }
    }
    return components;
}


export const getDeductions = (previewResponse: TGCancelResponse) => {
    const components = [];

    if (previewResponse && previewResponse.deductionsBreakup) {
        const fareBreakupList = previewResponse.deductionsBreakup;
        let i = 0;
        for (i = 0; i < fareBreakupList.length; i++) {
            const currentFareBreakup = fareBreakupList[i];
            const segment: Segment = {};
            segment.label = currentFareBreakup.text;
            segment.labelId = currentFareBreakup.labelId;
            segment.value = currentFareBreakup.amount;
            segment.order = i + 1;
            if (previewResponse.cancellationAssured && currentFareBreakup.labelId === "ticket_cancellation_charges") {
                segment.amountSaved = currentFareBreakup.amount;
            }
            components.push(segment);
        }
    }
    return components;
}

export const isRefundZero = (previewResponse: TGCancelResponse) => {
    if(previewResponse && !isEmpty(previewResponse)){
        return previewResponse?.ticketCancellationPreview?.refundBreakup?.totalRefund?.amount <= 0;
    }
    return false;
}