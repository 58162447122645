import { appendUrlPrefix } from "web/webUtils";

export const MIMA_BASE_URL = 'https://supportz.makemytrip.com/api/';
// export const MIMA_BASE_URL = 'http://172.16.216.104:9006/myaccount/'
export const PURCHASED_ANCILLARIES_URL = `${MIMA_BASE_URL}purchasedAncillaries/`;
export const FETCH_ESCALATE_INFO_URL = `${MIMA_BASE_URL}v1/escalate/info`;
export const FETCH_BOOKING_SUMMARY_URL = `${MIMA_BASE_URL}v4/bookingsummary`
export const CHECK_ESCALATE_ELIGIBILITY_URL = `${MIMA_BASE_URL}v1/escalate/eligibility`;
export const ESCALATE_SUBMIT =  `${MIMA_BASE_URL}v1/escalate/submit`; //needs to be changed

export const PWA_COOKIE_NAME = 'ver';
export const SUPPORTZ_COOKIE_DOMAIN = '.supportz.makemytrip.com';
export const BOOKING_SUMMARY_URL = appendUrlPrefix("/bookingSummary")
export const LOGIN_URL = "https://www.makemytrip.com/pwa/hlp/v3/login/"