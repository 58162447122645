import { StyleSheet } from 'react-native';
const createStyles = (fonts, theme) =>
  StyleSheet.create({
    cardWrapper: {
      backgroundColor: theme.color.grey22,
      padding: 20,
      marginBottom: 5,
    },
    header: {
      marginVertical: 20,
      marginTop: 0,
      flexDirection: 'row',
      alignItems: 'center',
    },
    headingText: {
      fontSize: fonts.fontSize.font18,
      marginLeft: 20,
      ...fonts.boldFontFamily,
    },
    subHeader: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      borderBottomWidth: StyleSheet.hairlineWidth,
      paddingBottom: 16,
      borderColor: theme.color.grey,
    },
    subHeaderKey: {
      color: theme.color.grey,
      fontSize: fonts.fontSize.font16,
    },
    subHeaderValue: {
      ...fonts.boldFontFamily,
      fontSize: fonts.fontSize.font16,
      color: theme.color.black,
    },
    fareBreakupSection: {
      marginTop: 15,
      paddingBottom: 20,
    },
    fareBreakupHeading: {
      color: theme.color.grey,
      fontSize: fonts.fontSize.font16,
    },
    fareBreakupItems: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingVertical: 4.5,
    },
    fareBreakupKey: {
      fontSize: fonts.fontSize.font14,
      color: theme.color.black,
    },
    fareBreakupValue: {
      ...fonts.boldFontFamily,
      color: theme.color.black,
    },
  });
export default createStyles;
