import React, { useMemo } from 'react';
import { View, Text } from 'react-native';
import styles from './CircularProgressBarCss';

const PercentageCircle = ({ radius, borderWidth, color, shadowColor, bgColor, children, ...props }) => {
  const circleSize = radius / 2;
  const percentToDegrees = (percent) => {
    return percent * 3.6
  }
  const computeDerivedState = () => {
    const percent = Math.max(Math.min(100, props.percent), 0)
    const needHalfCircle2 = percent > 50
    let halfCircle1Degree
    let halfCircle2Degree
    // degrees indicate the 'end' of the half circle, i.e. they span (degree - 180, degree)
    if (needHalfCircle2) {
      halfCircle1Degree = 180
      halfCircle2Degree = percentToDegrees(percent)
    } else {
      halfCircle1Degree = percentToDegrees(percent)
      halfCircle2Degree = 0
    }
    return {
      halfCircle1Degree,
      halfCircle2Degree,
      halfCircle2Styles: {
        // when the second half circle is not needed, we need it to cover
        // the negative degrees of the first circle
        backgroundColor: needHalfCircle2
          ? color
          : shadowColor,
      },
    }
  }
  const {
    halfCircle1Degree,
    halfCircle2Degree,
    halfCircle2Styles,
  } = useMemo(computeDerivedState, [props.percent])
  const renderHalfCircle = (rotateDegrees, halfCircleStyles) => {


    return (
      <View
        style={[
          styles.leftWrap,
          {
            width: circleSize,
            height: circleSize * 2,
          },
        ]}
      >
        <View
          style={[
            styles.halfCircle,
            {
              width: circleSize,
              height: circleSize * 2,
              borderRadius: circleSize,
              overflow: 'hidden',
              transform: [
                { translateX: circleSize / 2 },
                { rotate: `${rotateDegrees}deg` },
                { translateX: - circleSize / 2 },
              ],
              backgroundColor: color,
              ...halfCircleStyles,
            },
          ]}
        />
      </View>
    )
  }

  // inner circle - background white in the middle   
  const renderInnerCircle = () => {
    const radiusMinusBorder = circleSize - borderWidth;
    const { showCountdown, counter } = props;
    const percent = Math.max(Math.min(100, props.percent), 0)
    return (

      <View
        style={[
          styles.innerCircle,
          {
            width: radiusMinusBorder * 3,
            height: radiusMinusBorder * 3,
            borderRadius: radius,
            backgroundColor: bgColor,
            ...props.containerStyle,
          },
        ]}
      >
        {!showCountdown ?
          <Text style={[styles.percentText, { color: color }]}>{percent + '%'}</Text> :
          <Text style={[styles.percentText, { color: color }]}>{counter}</Text>
        }
      </View>
    )
  }

  return (
    <View
      style={[
        styles.outerCircle,
        {
          width: circleSize * 2,
          height: circleSize * 2,
          borderRadius: circleSize,
          backgroundColor: shadowColor,
          ...props.outerCircleStyle,
        },
      ]}
    >
      {renderHalfCircle(halfCircle1Degree)}
      {renderHalfCircle(halfCircle2Degree, halfCircle2Styles)}
      {renderInnerCircle()}
    </View>
  )

}


export default React.memo(PercentageCircle);