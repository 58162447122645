import React, { useMemo } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { isEmpty } from 'lodash';
import { getLostIDSummaryData } from './lostIdCardAdapter';
import {
  DescriptionSection,
  MainContent,
  NewStatusText,
  RightArrow,
  StaticIcon,
  SummaryContainer,
  TitleText,
} from '../cardElements';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import styles from './lostIDCss';
import { isEmptyArray } from '../../../../Common/commonUtil';
import { getStaticData } from '../../../../staticData/staticData';
import PaymentStatusCard from './paymentStatusCard';
import TripSummaryOmnitureTracker from '../../../tracking/TripSummaryOmnitureTracker';
import { getFont } from '../../../../PostSalesStyles';

function LostIDSummaryCard({ response, visibleItemMap }) {
  const { fsStyle, ...fonts}  = getFont(true);
  const { bookingID, lobname, refundDetails } = response || {};
  const {
    openDetails,
    iconUrl,
    title,
    subTitle,
    iconStyle,
    status,
    payStatusText,
    paymentTextLine1,
    paymentTextLine2,
    requestNo,
    isTatBreached,
    tatBreachedText,
    tatBreachedActions,
  } = getLostIDSummaryData(response);

  const getReqNos = useMemo(
    () =>
      refundDetails && refundDetails.psrRequests
        ? refundDetails.psrRequests.map((data) => data.psrNo)
        : null,
    [refundDetails],
  );

  const onRaiseRequest = (actionId) => {
    if (actionId === 'RAISE_LOST_REFUND_TICKET') {
      TripSummaryOmnitureTracker.trackBookingClickEvent(
        lobname,
        status,
        bookingID,
        'raiseLostRefundRequest',
      );
      const { raiseRefundTicketText } = getStaticData();
      openDetails &&
        openDetails(
          {
            bookingId: bookingID,
            requestNo: requestNo,
            headerTitle: raiseRefundTicketText,
            lobName: lobname,
            formType: 'RTD',
            fromSummary: true,
          },
          false,
        );
    }
  };

  const cardClickHandler = () => {
    openDetails && openDetails();
  };

  return (
    <SummaryContainer bookingId={bookingID}>
      <TouchableOpacity onPress={cardClickHandler} activeOpacity={0.7}>
        <MainContent onPress={cardClickHandler}>
          <StaticIcon icon={iconUrl} iconStyle={iconStyle} />
          <DescriptionSection>
            <TitleText text={title} />
            <NewStatusText status={status} text={subTitle} />
          </DescriptionSection>
          <RightArrow />
        </MainContent>
        {!isEmpty(payStatusText) || !isEmpty(paymentTextLine1) || !isEmpty(paymentTextLine2) ? (
          <View style={styles.refundDetailWrapper}>
            {!isEmpty(payStatusText) && (
              <View style={[styles.refundDetail, styles.refundDetailAwaited]}>
                <Text
                  style={[
                    styles.refundStatusTxt,
                    fonts.boldFontFamily,
                    styles.refundStatusTxtAwaited,
                    fsStyle.font11
                  ]}
                >
                  {payStatusText}
                </Text>
              </View>
            )}
            {!isEmpty(paymentTextLine1) && (
              <Text style={[styles.refundCardTxt, fsStyle.font14]}>{paymentTextLine1}</Text>
            )}
            {!isEmpty(paymentTextLine2) && (
              <Text style={[styles.refundDescTxt, fsStyle.font12]}>{paymentTextLine2}</Text>
            )}
          </View>
        ) : visibleItemMap[bookingID] && getReqNos ? (
          <PaymentStatusCard bookingId={bookingID} getReqNos={getReqNos} data={response} />
        ) : null}
      </TouchableOpacity>
      {isTatBreached && (
        <View style={styles.requestCardLink}>
          <Text style={[fsStyle.font12, AtomicCss.blackText, fonts.regularFontFamily]}>
            {tatBreachedText}
          </Text>
          {!isEmptyArray(tatBreachedActions) &&
            tatBreachedActions.map((item) => (
              <TouchableOpacity onPress={() => onRaiseRequest(item.type)}>
                <Text
                  style={[
                    fsStyle.font14,
                    AtomicCss.azure,
                    fonts.blackFontFamily,
                    AtomicCss.marginLeft5,
                  ]}
                >
                  {' '}
                  {item.title}
                </Text>
              </TouchableOpacity>
            ))}
        </View>
      )}
    </SummaryContainer>
  );
}

export default LostIDSummaryCard;
