import React, { useEffect, useRef, useState, useReducer } from 'react';
import { View, ScrollView, Text, DeviceEventEmitter } from 'react-native';
import useApi from '../../../../../Common/useApi';
import FAQCard from './components/FAQCard';
import ApplyForVisaCard from './components/ApplyForVisaCard';
import { HOLIDAY_ADD_VISA_URL, HOLIDAYS_HOLD_VISA_URL } from '../../../../../utils/NetworkUtils';
import ImportantInfoCard from './components/ImportantInfoCard';
import Header from '../../../../Components/Header';
import ContactCard from '../../components/ContactCard';
import DarkFooter from './components/DarkFooter';
import { INFO_ICON } from '../../../../../flights/FlightImageConstants';
import Actions from '../../../../../navigation/postSalesNavigation';
import FareBreakupCard from './components/FareBreakupCard';
import CommonBottomOverlayMessage from '../../../../../Common/CommonOverlayMessage/CommonBottomOverlayMessage';
import isEmpty from 'lodash/isEmpty';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import createStyles from './style';
import { useTheme } from '../../../../../theme/theme.context';
import ProgressView from '../../../../../Common/ProgressView/ProgressView';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import NoInternetView from '@mmt/legacy-commons/Common/Components/Error/NoInternetView';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import getAddVisaPageData, { getFareBreakup, getTotalPaidData } from './data/responseAdapter';
import { addVisaReducer, initialState } from './reducer/addVisaReducer';
import { getStaticData } from 'apps/post-sales/src/staticData/staticData';
import {
  getHoldAPIRequest,
  getPaymentRequestIOS,
  getPaymentRequestAndroid,
} from './data/requestAdapter';
import ACTION_TYPES from './reducer/actions';
import HolidayBookingTrackingHelper from '../../../../HolidayBookingTrackingHelper';
import { resetToHolidayBookingDetail } from 'apps/post-sales/src/Holiday/HolidayBookingDetailUtils';
import CenterOverlayMessage from 'apps/post-sales/src/Common/CenterOverlayMessage';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import PostSalesModule from '@mmt/legacy-commons/Native/PostSalesModule';
import { HOLIDAY_ADD_VISA_THANK_YOU_EVENT, PAYMENT_STATUS } from '../../../../HolidayBookingConstants';
import { PAYMENT_REPONSE, EMPTY_JSON_STRING } from '../../../../../PostSalesConstant';

const AddVisaPage = ({ bookingId }) => {
  const [addVisaInProgress, addVisaResponse, addVisaApi] = useApi(
    `${HOLIDAY_ADD_VISA_URL}/${bookingId}`,
  );
  useEffect(() => {
    DeviceEventEmitter.addListener(HOLIDAY_ADD_VISA_THANK_YOU_EVENT, openThankYouPage);
    return () => {
      DeviceEventEmitter.removeAllListeners(
        HOLIDAY_ADD_VISA_THANK_YOU_EVENT
      );
    };
  }, []);

  const [holdVisaApiInProgress, holdVisaResponse, holdVisaApi] = useApi(HOLIDAYS_HOLD_VISA_URL);
  const [state, dispatch] = useReducer(addVisaReducer, initialState);
  const scrollViewRef = useRef(null);
  const ImportantInfoCardLayout = useRef(null);
  const [viewState, setViewState] = useState(ViewState.LOADING);
  const [showFareBreakup, setShowFareBreakup] = useState(false);
  const [continueError, setContinueError] = useState(false);
  const [agreedState, setAgreedState] = useState(false);

  const fonts = getFont();
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);
  
  useEffect(() => {
    fetchJSONAsync();
  }, [addVisaApi]);

  const fetchJSONAsync = () => {
    addVisaApi.httpGet();
  };
  const renderThankYouPage = (commitResponse) => {
    Actions.holidaysThankYouPage({
      bookingId: bookingId,
      payId: commitResponse.payId,
    });
  };
  const openThankYouPage = (data) => {
    try {
      let success;
      let commitResponse;
      if (Platform.OS === 'android'){
        success = data.Payment_Status === PAYMENT_REPONSE.PAYMENT_SUCCESS;
        const paymentData = JSON.parse(data.PAYMENT_RESPONSE_VO);
        const { response = EMPTY_JSON_STRING } = paymentData;
        commitResponse = JSON.parse(response);
        success = success && commitResponse.status === PAYMENT_STATUS.SUCCESS;
      } else if (Platform.OS === 'ios'){
        success = data.status === PAYMENT_STATUS.SUCCESS;
        commitResponse = data;
      }
      if (success){
        renderThankYouPage(commitResponse);
      } else {
        setViewState(ViewState.ERROR);
      }
    } catch (err) {
      console.log(err);
      setViewState(ViewState.ERROR);
    }
  };
  useEffect(() => {
    if (addVisaResponse) {
      if (addVisaResponse.error || !addVisaResponse.success) {
        HolidayBookingTrackingHelper.trackErrorEvent('AddVisa', bookingId, 'error');
        if (addVisaResponse.error === ViewState.NO_INTERNET) {
          setViewState(ViewState.NO_INTERNET);
        } else {
          setViewState(ViewState.ERROR);
        }
      } else {
        HolidayBookingTrackingHelper.trackViewEvent('AddVisa', 'AddVisa', '');
        dispatch({ type: ACTION_TYPES.GET_API_SUCCESS, payload: { response: addVisaResponse } });
        setViewState(ViewState.SHOW_DETAIL);
      }
    }
  }, [addVisaResponse]);

  useEffect(() => {
    if (holdVisaResponse) {
      if (holdVisaResponse.error) {
        setViewState(ViewState.HOLD_FAILURE);
      } else if (holdVisaResponse.success) {
        renderHoldPage();
        setViewState(ViewState.HOLD_SUCCESS);
      } else if (holdVisaResponse.searchCacheExpired) {
        setViewState(ViewState.HOLD_POPUP);
      } else {
        setViewState(ViewState.HOLD_FAILURE);
      }
    }
  }, [holdVisaResponse]);

  const isContinueDisabled = () => {
    return state.totalPrice <= 0;
  };

  const makeHoldCall = () => {
    setViewState(ViewState.HOLD_LOADING);
    const request = getHoldAPIRequest(
      bookingId,
      state.countriesData,
      addVisaResponse.searchDataKey,
    );
    holdVisaApi.httpPost({ body: request });
  };

  const onHoldRetryClicked = () => {
    makeHoldCall();
  };

  const onContinueClicked = () => {
    HolidayBookingTrackingHelper.trackClickEvent('AddVisa', 'Proceed_to_payment', '');
    if (agreedState) {
      makeHoldCall();
      // Actions.holidaysThankYouPage(bookingId);
    } else {
      setContinueError(true);
      scrollViewRef.current.scrollTo({ y: ImportantInfoCardLayout.current.y, animated: true });
    }
  };
  const showFareBreakupClicked = () => {
    setShowFareBreakup(!showFareBreakup);
  };

  const renderPage = () => {
    const {
      headerData,
      countriesCardData,
      contactInformationCardData,
      faqCardData,
      importantInfoCardData,
      fareBreakupPopupData,
      footerData,
    } = getAddVisaPageData(addVisaResponse);
    const { heading: fareBreakupPopupDataHeading, totalPaidKey, itemsHeading } =
      fareBreakupPopupData || {};
    return (
      <View style={styles.pageWrapper}>
        {!isEmpty(headerData) && (
          <Header
            title={headerData.title}
            subTitle={headerData.subTitle}
            goBack={() => {
              Actions.pop();
            }}
          />
        )}
        <ScrollView style={styles.cardsWrapper} ref={scrollViewRef}>
          {!isEmpty(countriesCardData) && (
            <ApplyForVisaCard
              {...countriesCardData}
              dispatch={dispatch}
              selectedCountriesData={state.countriesData}
            />
          )}
          {!isEmpty(contactInformationCardData) && (
            <View style={AtomicCss.margin10}>
              <ContactCard {...contactInformationCardData} />
            </View>
          )}
          <View
            onLayout={(event) => {
              ImportantInfoCardLayout.current = event.nativeEvent.layout;
            }}
          >
            {!isEmpty(importantInfoCardData) && (
              <ImportantInfoCard
                {...importantInfoCardData}
                setAgreedState={setAgreedState}
                isError={continueError}
                pageName={'AddVisa'}
              />
            )}
          </View>
          {!isEmpty(faqCardData) && <FAQCard {...faqCardData} />}
        </ScrollView>
        {showFareBreakup && !isEmpty(fareBreakupPopupData) && (
          <CommonBottomOverlayMessage
            isBorderRadius
            dismiss={showFareBreakupClicked}
            content={
              <FareBreakupCard
                onDismiss={showFareBreakupClicked}
                heading={fareBreakupPopupDataHeading}
                totalPaidData={getTotalPaidData(totalPaidKey, state)}
                fareBreakup={getFareBreakup(itemsHeading, state)}
                custCurrency={state.custCurrency}
              />
            }
            isCancellable={false}
            initialBottomPosition={0}
            extraOverlayContentStyle ={styles.overlayContentExtraStyle}
          />
        )}
        {!isEmpty(footerData) && (
          <View style={styles.footerBtnWrapper}>
            <DarkFooter
              price={state.custCurrency + ' ' + state.totalPrice}
              infoIconText={footerData.infoIconKey}
              footerInfoIcon={state.totalPrice > 0 ? INFO_ICON: null}
              priceDtls={footerData.priceDtls}
              buttonLabel={footerData.btnText}
              isButtonDisabled={isContinueDisabled()}
              clickHandler={onContinueClicked}
              showFareBreakup={showFareBreakup}
              infoIconClickHandler={showFareBreakupClicked}
            />
          </View>
        )}
      </View>
    );
  };
  const renderErrorView = () => (
    <ErrorView
      showRetry
      onRetry={() => {
        setViewState(ViewState.LOADING);
        fetchJSONAsync();
      }}
    />
  );

  const renderNoNetworkView = () => (
    <NoInternetView
      onRetry={() => {
        fetchJSONAsync();
      }}
    />
  );

  const renderProgressView = (isHoldAPI) => {
    const { loadingAddVisaPageText, loadingText } = getStaticData();
    return <ProgressView message={isHoldAPI ? loadingText : loadingAddVisaPageText} />;
  };

  const renderHoldAPIFailureView = () => {
    return (
      <ErrorView
        showRetry
        onRetry={() => {
          onHoldRetryClicked();
        }}
      />
    );
  };

  const renderHoldPage = () => {
    if (Platform.OS === 'android') {
      const paymentRequest = getPaymentRequestAndroid(holdVisaResponse, bookingId);
      PostSalesModule.openPaymentPage(JSON.stringify(paymentRequest));
    } else if (Platform.OS === 'ios') {
      const body = getPaymentRequestIOS(holdVisaResponse, bookingId);
      GenericModule.openPaymentLanding(body);
    }
  };

  const renderHoldPopup = () => {
    setTimeout(() => {
      resetToHolidayBookingDetail(bookingId);
    }, 5000);
    return (
      <CenterOverlayMessage
        content={
          <View style={styles.overlayContentWrapper}>
            <Text style={styles.overlayErrorText}>{holdVisaResponse.message}</Text>
          </View>
        }
      />
    );
  };

  return (
    <View style={{ flex: 1 }}>
      {viewState === ViewState.NO_INTERNET && renderNoNetworkView()}
      {(addVisaInProgress || viewState === ViewState.LOADING) && renderProgressView()}
      {viewState === ViewState.ERROR && renderErrorView()}
      {(viewState === ViewState.SHOW_DETAIL || viewState === ViewState.HOLD_SUCCESS) && renderPage()}
      {viewState === ViewState.HOLD_LOADING && renderProgressView(true)}
      {viewState === ViewState.HOLD_POPUP && renderHoldPopup()}
      {viewState === ViewState.HOLD_FAILURE && renderHoldAPIFailureView()}
    </View>
  );
};

export default AddVisaPage;
