import React from 'react';
import { View, Text } from 'react-native';
import styles from '../indexCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { isNullOrEmpty } from '../../commonUtil';
import { getFont } from '../../../PostSalesStyles';
import { addSpaceWithAmount } from '../../../utils/PostSaleUtil';

const RefundCardHeader = ({data, status}) => {
  const fonts = getFont(true);
  return (
    <React.Fragment>
      { !isNullOrEmpty(status) &&
      <View style={[styles.statusTag, {backgroundColor : status.bgColor ? status.bgColor : "#ffffff"}]}>
        <Text style={[AtomicCss.font11, fonts.boldFontFamily, {color: status.color ? status.color : '#00000'}]} >{status.text}</Text>
      </View>
      }
      <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween, AtomicCss.alignCenter]}>
        <Text style={[AtomicCss.font18, fonts.blackFontFamily, AtomicCss.blackText, fonts.boldFontFamily]}>{data.text}</Text>
        <Text style={[AtomicCss.font18, fonts.blackFontFamily, {color: data.color ? data.color : '#00000'}]}>{addSpaceWithAmount(data.value)}</Text>
      </View>
      {!!(data.desc) && <Text style={[AtomicCss.font14, fonts.boldFontFamily, AtomicCss.midGreyText, AtomicCss.marginTop5]}>{data.desc}</Text>}
    </React.Fragment>
  );
};

export default RefundCardHeader;
