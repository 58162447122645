import React from 'react';
import {ScrollView, View} from 'react-native';
import Actions from '../../../navigation/postSalesNavigation';
import styles from '../../styles/CabMamiCss';
import FareDetails from './FareDetails';
import Header from '../../../Common/HeaderCross';

const onBackPress = () => {
    Actions.pop();
}

function CabCancelRebookPriceBreakup ({ paymentOption }) {
        return (
            <View style={[styles.GreyBg, {flex: 1}]}>
                <Header
                    title="Payment Details"
                    headerShadow
                    headingTxt="font18"
                    headingSubTxt="font12"
                    handleClose={onBackPress}
                />
                <ScrollView>
                    <FareDetails paymentBreakup={paymentOption}/>
                </ScrollView>
            </View>
        );
}

export default CabCancelRebookPriceBreakup;

