import React from 'react';
import { View, Text, TouchableOpacity, Image, ImageSourcePropType } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { CROSS_ICON as CrossIcon } from '../../hotel/HotelImageConstants';
import isEmpty from 'lodash/isEmpty';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import createStyles from './TCSBottomOverlayCss';
import { getFont } from '../../PostSalesStyles';

interface TCSInfoItem {
  icon: string;
  heading: string;
  description: string;
}

interface StepsInfo {
  heading?: string;
  stepsList?: TCSInfoItem[];
}

interface TCSPopupInfo {
  heading?: string;
  text?: string;
  steps?: StepsInfo;
}

interface TCSInfoListRowProps {
  item: TCSInfoItem;
  isLastElement: boolean;
  index: number;
}

const TCSInfoListRow: React.FC<TCSInfoListRowProps> = ({ item, isLastElement, index }) => {
  const { psTheme: theme } = useTheme();
  const fonts = getFont(true);
  const styles = createStyles(fonts, theme);
  const isLastElementStyle = isLastElement ? styles.marginBt0 : '';
  const { icon, heading: title, description } = item || {};
  return (
    <View style={[AtomicCss.flexRow, AtomicCss.marginBottom12, isLastElementStyle]} key={index}>
      {!isEmpty(icon) && (
        <Image style={styles.iconStyle} source={{ uri: icon } as ImageSourcePropType} />
      )}
      {(!isEmpty(title) || !isEmpty(description)) && (
        <View style={[AtomicCss.flex1]}>
          {!isEmpty(title) && <Text style={styles.titleText}>{title}</Text>}
          {!isEmpty(description) && <Text style={styles.descriptionText}>{description}</Text>}
        </View>
      )}
    </View>
  );
};

const TCSBottomOverlay: React.FC<{
  onClose: () => void;
  tcsPopupInfo: TCSPopupInfo;
}> = ({ onClose, tcsPopupInfo }) => {
  if (isEmpty(tcsPopupInfo)) {
    return null;
  }

  const { heading, text: description, steps } = tcsPopupInfo || {};
  const { heading: subHeading, stepsList: tcsInfoList } = steps || {};
  const { psTheme: theme } = useTheme();
  const fonts = getFont(true);
  const styles = createStyles(fonts, theme);

  return (
    <View>
      <View style={styles.topLine}></View>
      <View style={[styles.heading, AtomicCss.flexRow, AtomicCss.alignCenter]}>
        <TouchableOpacity style={styles.crossIconWrapper} onPress={onClose}>
          <Image style={styles.crossIconStyle} source={CrossIcon as ImageSourcePropType} />
        </TouchableOpacity>
        {!isEmpty(heading) && <Text style={styles.headingText}>{heading}</Text>}
      </View>
      <View style={styles.bodySection}>
        {!isEmpty(description) && <Text style={styles.overlayDescriptionText}>{description}</Text>}
        {!isEmpty(subHeading) && <Text style={styles.subHeadingText}>{subHeading}</Text>}
        {!isEmpty(tcsInfoList) &&
          tcsInfoList &&
          tcsInfoList.map((item, index) => {
            return (
              <TCSInfoListRow
                item={item}
                index={index}
                isLastElement={index === tcsInfoList.length - 1}
              />
            );
          })}
      </View>
    </View>
  );
};

export default TCSBottomOverlay;
