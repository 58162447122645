import { giftCardIcon } from 'apps/post-sales/src/giftcard/GiftCardImageConstants';
import myTripsNavigation from '../../../../navigation/navigation';
import { handleCTAClick, getMyRequestCardData } from '../../../utils';
import { GiftCardData, GiftCardSummaryDataProps, MsrTicket } from '../cardInterfaces';

function getGiftCardSummaryData(data: GiftCardData, msrTicket: MsrTicket): GiftCardSummaryDataProps {
  const { ctaInfoList: ctaDetails, infoCard } = data && data.bookingStateLayout || {};
  const [giftCardDetails] = data.giftCardDetails;
  const { giftCardName, startDateText, expiryDateText, purchasedOnText, validTillText } = giftCardDetails || {};
  const pageData = {
    fromMyTrips: true,
  };
  const openDetails = () => {
    myTripsNavigation.openGiftCardBookingDetail(data, pageData);
  };

  const actions: Array<{ label: string; onPress: () => void }> = [];
  let tripType = `${giftCardDetails.tripType}`;
  const startsIn = data.startsIn;
  const lobName = data.lobType[0];
  const bookingId = data.bookingID;
  const { text, ctaInfo } = infoCard || {};
  const infoCardStatusText = text;
  const infoCardStatusCTA = ctaInfo && ctaInfo.text;

  if (ctaDetails && ctaDetails[0]) {
    actions.push({ label: ctaDetails[0]?.text,  onPress: () => handleCTAClick(ctaDetails[0], data) })
  };
  if (ctaDetails && ctaDetails[1]) {
    actions.push({ label: ctaDetails[1]?.text,  onPress: () => handleCTAClick(ctaDetails[1], data) })
  };

  const handleInfoClick = () => {
    if (infoCard) {
      handleCTAClick(infoCard.ctaInfo, data);
    }
  };

  return {
    iconUrl: giftCardIcon,
    giftCardName,
    startDateText,
    expiryDateText,
    purchasedOnText,
    validTillText,
    tripType,
    myRequestsCard: getMyRequestCardData(msrTicket),
    actions,
    status: data.status,
    infoCardStatusText,
    startsIn,
    lobName,
    bookingId,
    infoCardStatusCTA,
    openDetails,
    handleInfoPress: handleInfoClick
  };
}
export default getGiftCardSummaryData;