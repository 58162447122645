import React from 'react';
import PropTypes from 'prop-types';
import {Image, Text, View} from 'react-native';
import getStyles from '../styles/BusMamiCss';
import {
  getActionButton, getButtonStyle, handleBusClickEvent,
  isNotNullAndEmpty
} from '../utils/BusBookingDetailUtil';
import { getFont } from '../../PostSalesStyles';

class NeedHelpCard extends React.Component {
  render() {
    const fonts = getFont(true);
    const styles = getStyles();
    return (
      <View style={[styles.MamiCard, styles.mar10Bt]}>
        <View style={styles.CardContent}>
          <View style={styles.ContentPortion}>
            <Text style={[fonts.blackFontFamily, styles.BlackText, styles.mar5Bt]}>{this.props.headText}</Text>
          </View>
        </View>
        <View style={isNotNullAndEmpty(this.props.card.actionList) ? getButtonStyle(this.props.card.actionList) : styles.hideView}>
          {getActionButton(this.props.card, this.props.response, this.handleClick)}
        </View>
      </View>
    );
  }

  handleClick = (action) => {
    handleBusClickEvent(this.props.card, action, this.props.response, this.props.pageName);
  };
}

NeedHelpCard.propTypes = {
  response: PropTypes.object.isRequired,
  card: PropTypes.object.isRequired,
  pageName: PropTypes.string.isRequired
};

export default NeedHelpCard;
