import { StyleSheet } from "react-native";
import { DEFAULT_THEME } from "apps/post-sales/src/theme/default.theme";

export default <T extends typeof DEFAULT_THEME>(theme: T) => StyleSheet.create({
    innerDetails: {
      padding: 12,
    },
    rowSection: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    animatedOuter: {
      backgroundColor: theme.color.grey36,
      position: "relative",
      overflow: "hidden",
      borderRadius: 8,
    },
    shimmerOne: {
      width: 100,
      height: 40,
    },
    shimmerTwo: {
      width: 80,
      height: 20,
    },
    shimmerThree: {
      width: 40,
      height: 20,
    },
    shimmerFour: {
      width: 180,
      height: 34,
    },
    shimmerLink: {
      width: 75,
      height: 14,
    },
  })