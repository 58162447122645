import React, { useEffect } from 'react';
import { View, Text, Image } from 'react-native';
import styles from './completeBookingCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import Button from '../../../../../../Common/Button';
import { isNullOrEmpty } from '../../../../../../Common/commonUtil';
import { isNonEmpty } from '@mmt/legacy-commons/Common/utils/StringUtils';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import { getStaticData } from '../../../../../../staticData/staticData';
import { REFUND_PAYMENT_ICON } from '../../../../../FlightImageConstants';
import { getFont } from '../../../../../../PostSalesStyles';
import FlightBookingTrackingHelper from '../../../helper/FlightBookingTrackingHelper';


const CompleteYourBookingCard = ({
  header,
  btnData: { title, paymentLink },
  footerText,
  paymentBreakup,
  nonRefundableText,
  isHoldBooking
}) => {
  useEffect(() => {
    if (isHoldBooking) {
      FlightBookingTrackingHelper.trackLoadEvent('Details', {}, 'hold_myt_complete_booking_shown');
    }
  }, []);
  const clickHandler = () => {
    if (isHoldBooking){
      FlightBookingTrackingHelper.trackClickEvent(
        'Details',
        'hold_myt_complete_booking_ctaclick',
      );
    }
    GenericModule.openDeepLink(paymentLink);
  }
  const { fsStyle, ...fonts } = getFont(true);
  const { toBePaidText } = getStaticData();
  return (
    <View style={[AtomicCss.marginBottom10, styles.cardBorder]}>
      <View style={[styles.infoCardWrapper]}>
        <View style={AtomicCss.flexRow}>
          <View style={styles.headingIconWrapper}>
            <Image style={styles.headingIconStyle} source={REFUND_PAYMENT_ICON} />
          </View>
          <View style={[styles.infoCardContent]}>
            <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween, AtomicCss.marginBottom20]}>
              <View>
                <Text
                  style={[
                    AtomicCss.defaultText,
                    fonts.blackFontFamily,
                    fsStyle.font14,
                    AtomicCss.marginBottom3,
                  ]}
                >
                  {header}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View style={[AtomicCss.marginLeft20, AtomicCss.marginRight20]}>
            {!isNullOrEmpty(paymentBreakup?.flPrice) && (
              <View
                style={[
                  AtomicCss.flexRow,
                  AtomicCss.spaceBetween,
                  styles.totalRefundprice,
                  AtomicCss.marginBottom10,
                ]}
              >
                {!isNullOrEmpty(paymentBreakup.flPrice?.text) && (
                  <Text style={[fonts.regularFontFamily, AtomicCss.defaultText, fsStyle.font14]}>
                    {paymentBreakup.flPrice.text.toUpperCase()}
                  </Text>
                )}
                {!isNullOrEmpty(paymentBreakup.flPrice?.value) && (
                  <Text style={[fonts.regularFontFamily, AtomicCss.defaultText, fsStyle.font14]}>
                    {paymentBreakup.flPrice.value.toUpperCase()}
                  </Text>
                )}
              </View>
            )}
            {!isNullOrEmpty(paymentBreakup?.flCharges) && (
              <View
                style={[
                  AtomicCss.flexRow,
                  AtomicCss.spaceBetween,
                  styles.totalRefundprice,
                  AtomicCss.marginBottom10,
                ]}
              >
                <Text style={[fonts.regularFontFamily, AtomicCss.defaultText, fsStyle.font14]}>
                  {paymentBreakup.flCharges.text}
                </Text>
                <Text style={[fonts.regularFontFamily, AtomicCss.defaultText, fsStyle.font14]}>
                  {paymentBreakup.flCharges.value}
                </Text>
              </View>
            )}
        </View>
        {!isNullOrEmpty(paymentBreakup?.flPeriod) && (
          <View style={styles.priceInfoWrapper}>
            <View
              style={[
                AtomicCss.flexRow,
                AtomicCss.spaceBetween,
                AtomicCss.alignCenter,
                AtomicCss.marginBottom8,
              ]}
            >
              <Text style={[styles.priceInfoTxt, fonts.boldFontFamily, fsStyle.font12]}>
                {toBePaidText.toUpperCase()}
              </Text>
              {!isNullOrEmpty(paymentBreakup.flPeriod?.value) && (
                <Text
                  style={[styles.priceInfoTxt, fsStyle.font14, fonts.boldFontFamily, fsStyle.font12]}
                >
                  {paymentBreakup.flPeriod.value.toUpperCase()}
                </Text>
              )}
            </View>
            {!isNullOrEmpty(paymentBreakup.flPeriod?.text) && (
                <Text style={[styles.priceInfoTxt, fonts.boldFontFamily, fsStyle.font12]}>
                  {paymentBreakup.flPeriod.text.toUpperCase()}
                </Text>
              )}
          </View>
        )}
        {isNonEmpty(title) && isNonEmpty(paymentLink) && (
          <View style={styles.btnWrapper}>
            <Button
              clickHandler={clickHandler}
              btnTxt={title}
              btnBg="gradientBtn"
              btnWidth={230}
              buttonTextCss={fsStyle.font14}
            />
          </View>
        )}
      </View>
      {(isNonEmpty(nonRefundableText) || isNonEmpty(footerText)) && (
        <View style={styles.bottomRowSection} activeOpacity={0.8}>
          {isNonEmpty(nonRefundableText) && (
            <Text
              style={[
                AtomicCss.marginBottom8,
                AtomicCss.defaultText,
                fsStyle.font12,
                fonts.regularFontFamily,
              ]}
            >
              {nonRefundableText}
            </Text>
          )}
          {isNonEmpty(footerText) && (
            <Text style={[AtomicCss.defaultText, fsStyle.font12, fonts.regularFontFamily]}>
              {footerText}
            </Text>
          )}
        </View>
      )}
    </View>
  );
};
export default CompleteYourBookingCard;
