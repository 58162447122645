import {Platform} from 'react-native';
import {colors, fonts, isIPhoneX} from '@mmt/legacy-commons/Styles/globalStyles';

const styles = {
  contentTitle: {
    fontSize: 28,
    color: colors.black,
    fontFamily: fonts.light,
    marginBottom: 18
  },
  bottomSeperation: {
    ...Platform.select({
      ios: {
            paddingBottom: isIPhoneX ? 40 : 26
      }
    })
  },
  contentDesc: {
    fontSize: 14,
    color: colors.defaultTextColor,
    fontFamily: fonts.regular,
    marginBottom: 10
  },
  contentFooter: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  actionBtn: {
    paddingVertical: 10
  },
  actionBtnText: {
    fontSize: 16,
    color: colors.azure,
    fontFamily: fonts.medium
  },
  boldFont: {
    fontFamily: fonts.bold
  }
};

export default styles;

