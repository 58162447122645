import { StyleSheet } from 'react-native';

export const createStyles = (theme, font) =>
  StyleSheet.create({
    topSection: {
      borderBottomWidth: 1,
      borderBottomColor: theme.color.lightGray,
      flexDirection: 'row',
      display: 'flex',
      gap: 8,
      justifyContent: 'flex-start',
      paddingVertical: 12,
    },
    contentStyle: {
      paddingHorizontal: 12,
    },
    listStyle: {
      display: 'flex',
      justifyContent: 'flex-start',
      marginBottom: 16,
      flexDirection: 'row',
      paddingRight: 12,
    },
    titleText: {
      ...font.boldFontFamily,
      ...font.font16,
      color: theme.color.black,
      marginBottom: 4,
    },
    bodyTitleStyle: {
      ...font.boldFontFamily,
      color: theme.color.black,
      ...font.font14,
      marginBottom: 16,
    },
    bodySection: {
      paddingVertical: 12,
    },
    rideIconStyle: {
      width: 40,
      height: 40,
      marginRight: 10,
    },
    purpleColorText: {
      color: theme.color.purpleDark,
      ...font.font12,
      ...font.boldFontFamily,
    },
    tickStyle: {
      width: 16,
      height: 16,
      marginRight: 5,
    },
    actionTitleStyle: {
      ...font.boldFontFamily,
      color: theme.color.azure,
      ...font.font14,
      paddingBottom: 12
    },
  });
