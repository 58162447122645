import {trackOmniture} from '@mmt/legacy-commons/Common/utils/OmnitureTrackerUtils';
import {findDaysBetweenDates, isReturnTripVisible, isActionAvailbleInTopCard, isCallButtonVisibleHelpSection} from './utils/BusBookingDetailUtil';
import BusEventsLogger from './BusEventsLogger';
import BusBookingDetailsConstant from './BusBookingDetailsConstant';

class BusBookingTrackingHelper {
  trackClickEvent = (pageName, busResponse, clickEvent) => {
    BusEventsLogger.clickEvent(pageName, clickEvent, busResponse);
    let params = {};
    params = this.getBasicOminitureVariables(pageName, busResponse);
    params.m_c54 = `Mob:customer support:Bus:${pageName}_${clickEvent}`;
    trackOmniture(`Mob:customer support:Bus:${pageName}`, params);
  };

  getBasicOminitureVariables(pageName, busResponse) {
    const params = {};
    try {
      if (busResponse !== undefined) {
        params.m_v16 = busResponse.bookingId;
        params.m_v9 = busResponse.bookingState.subState;
        params.m_v40 = busResponse.busBookingInfo.busDetail.type;
        params.m_v3 = `${busResponse.busBookingInfo.busDetail.fromCity}-${busResponse.busBookingInfo.busDetail.toCity}`;
        params.m_v21 = findDaysBetweenDates(
          new Date().getTime(),
          busResponse.busAdditionalInfo.departureDetail.departureDate
        );
        params.m_v30 = `${busResponse.isFreeCancellation}`;
        params.m_v40 = busResponse.busBookingInfo.busDetail.type;
        params.m_v47 = busResponse.bookingPaymentDetail.ecouponAmount > 0 ? 'Coupon status Present' : 'Coupon status Not Present';
        params.Props56 = findDaysBetweenDates(new Date().getTime(), busResponse.bookingDateTime);
        params.m_v19 = isReturnTripVisible(busResponse);
        params.m_v24 = isActionAvailbleInTopCard(busResponse, [BusBookingDetailsConstant.ACTION_BUS_OPEN_OVERLAY, BusBookingDetailsConstant.ACTION_BUS_OPEN_OVERLAY2]);
        params.m_v23 = isCallButtonVisibleHelpSection(busResponse);
        params.m_v22 = isActionAvailbleInTopCard(busResponse, [BusBookingDetailsConstant.ACTION_CALL_OPERATOR]);
      }
      params.m_v15 = `Mob:customer support:Bus:${pageName}`;
      params.m_pageName = `Mob:customer support:Bus:${pageName}`;
      return params;
    } catch (e){
      console.log('e',e)
      return params;
    }
  }

   trackLoadEvent = (pageName, busResponse) => {
     BusEventsLogger.loadEvent(pageName,busResponse);
     let params = {};
     params = this.getBasicOminitureVariables(pageName, busResponse);
     trackOmniture(`Mob:customer support:Bus:${pageName}`, params);
   };

  trackErrorEvent = (pageName) => {
    BusEventsLogger.errorEvent(pageName);
    const params = {};
    const fullPageName = pageName !== undefined ? `Mob:customer support:Bus:${pageName}_Error` : 'Mob:customer support:Bus:Error';
    params.pageName = fullPageName;
    params.m_v15 = fullPageName;
    trackOmniture(fullPageName, params);
  };

    trackClickEvents = (pageName, clickEvent) => {
      BusEventsLogger.clickEvent(pageName, clickEvent);
      const params = {};
      params.m_c54 = `Mob:customer support:Bus:${pageName}_${clickEvent}`;
      trackOmniture(`Mob:customer support:Bus:${pageName}`, params);
    };
}


export default new BusBookingTrackingHelper();
