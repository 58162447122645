import React from 'react';
import FlightSummaryCard from './flights';
import HotelSummaryCard from './hotels';
import TrainSummaryCard from './trains';
import BusSummaryCard from './bus';
import CabsSummaryCard from './cabs';
import ActivitySummaryCard from './activities';
import VisaSummaryCard from './visa';
import HolidaySummaryCard from './holidays';
import GiftCardSummaryCard from './giftcard';
import HolidayPlanningSummaryCard from './holidayPlanning';
import TripGuaranteeSummaryCard from "./TripGuarantee";
import InsuranceSummaryCard from './insurance';
import { Lobs } from '../../summaryConstants';
import { getFilteredMsrTicket } from '../../../utils/MsrUtils';
import LostIDSummaryCard from './lostID';

function SummaryCard({ response, bookingIdtoTicketDetailmap, visibleItemMap, onRefreshPlannings }) {
  const filteredTicket = getFilteredMsrTicket(response, bookingIdtoTicketDetailmap);
  const { isLostBooking, lostBooking } = response || {};
  if (isLostBooking || lostBooking) {
    return <LostIDSummaryCard response={response} visibleItemMap={visibleItemMap} />;
  }
  const [primaryLob, ...secondaryLobs] = response.lobType;
  if (response.isHolidayBooking === '1' || response.bookingID.startsWith('NL')) {
    return <HolidaySummaryCard response={response} msrTicket={filteredTicket} />;
  } else {
    switch (primaryLob) {
      case Lobs.Flight:
        return <FlightSummaryCard response={response} msrTicket={filteredTicket}  />;
      case Lobs.Hotel:
        return <HotelSummaryCard response={response} msrTicket={filteredTicket}   />;
      case Lobs.Bus:
        return <BusSummaryCard response={response} msrTicket={filteredTicket}   />;
      case Lobs.Trains:
        return <TrainSummaryCard response={response} msrTicket={filteredTicket}  />;
      case Lobs.Cars:
        return <CabsSummaryCard response={response} msrTicket={filteredTicket}  />;
      case Lobs.Acme:
        return <ActivitySummaryCard response={response} msrTicket={filteredTicket}  />;
      case Lobs.Visa:
        return <VisaSummaryCard response={response} msrTicket={filteredTicket}  />;
      case Lobs.GiftCard:
        return <GiftCardSummaryCard response={response} msrTicket={filteredTicket}  />;
      case Lobs.Planning:
        return (
          <HolidayPlanningSummaryCard
            response={response}
            onRefreshHolidayPlannings={onRefreshPlannings}
          />
        );
      case Lobs.TGS:
        return <TripGuaranteeSummaryCard response={response} msrTicket={filteredTicket} />
      case Lobs.INSURANCE:
        return <InsuranceSummaryCard response={response}/>
      default:
        return null;
    }
  }
}

export default React.memo(SummaryCard);
