import React, { useEffect, FC } from 'react';
import { SectionList, View, Text } from 'react-native';
import { isEmpty } from 'lodash';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import createStyles from '../../summaryCss';
import LobCard from '../LobCard';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { BookingData, BookingIdToTicketDetailMapType, Bookings } from '../../types';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';

interface BookingListProps {
  bookings: Bookings[]; 
  bookingIdtoTicketDetailmap: BookingIdToTicketDetailMapType; 
}

const BookingList: FC<BookingListProps> = (props) => {
  const {
    bookings,
    bookingIdtoTicketDetailmap,
  } = props || {};
  const { fsStyle, ...fonts } = getFont(true);
  const [filteredBookings, setFilteredBookings] = React.useState<Bookings[]>(bookings); 
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme);
  useEffect(() => {
    setFilteredBookings([...bookings]);
  }, [bookings]);

  const renderSectionListItem = ({ item, index }: { item: BookingData; index: number }) => {
    return (
      <>
        <LobCard
          item={item}
          bookingIdtoTicketDetailmap={bookingIdtoTicketDetailmap}
          index={index}
        />
      </>
    );
  };

  const renderHeader = ({ section }: { section: { title: string, data: BookingData[]} }) => {
      return (
        <>
          {!isEmpty(section.title) && (
            <Text style={[styles.sectionHeader, fonts.semiBoldFontFamily, fsStyle.font14]}>
              {section.title}
            </Text>
          )}
        </>
      );
  };

  return (
    <View style={AtomicCss.flex1}>
      <SectionList
        scrollEnabled={true}
        sections={filteredBookings}
        keyExtractor={(item) => item.bookingID || item.bookingId}
        renderItem={renderSectionListItem}
        onEndReachedThreshold={0.9}
        viewabilityConfig={{
          itemVisiblePercentThreshold: 50, // if 50% of the item is visible, execute onViweableItemsChanged prop
        }}
        stickySectionHeadersEnabled={false}
        renderSectionHeader={renderHeader}
      />
    </View>
  );
};

export default BookingList;