import React from 'react';
import PropTypes from 'prop-types';
import { Image, Text, View, TouchableOpacity } from 'react-native';
import getStyles from './HotelMamiCss';
import {
  getActionButton,
  handleClickEvent,
  isNotNullAndEmpty,
  isNullOrEmpty,
  showRoomAminities,
  showViewMoreText,
} from '../utils/HotelBookingDetailUtil';
import ChildBookingInfo from './ChildBookingInfo';
import HotelBookingDetailsConstant from '../HotelBookingDetailsConstant';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { TRAVELLERS_ICON, MEALPLAN_ICON, GREEN_TICK_ICON, HOTEL_DEFAULT_ICON, ROOMS_ICON } from '../../HotelImageConstants';
import { getStaticData } from '../../../staticData/staticData';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const ROOM_AMENTIES_LIMIT = 4;
class HotelRoomDetailsCard extends React.Component {

  constructor(props) {
    super(props);
    this.fonts = getFont(true);
    this.styles = getStyles(this.fonts);
    this.fsStyle = this.fonts.fsStyle;
    this.state = {
      roomAmentiesLimit: ROOM_AMENTIES_LIMIT
    };
  }

  render() {
    const staticData = getStaticData();
    const hotelDetailResponse = this.props.response.hotelBookingInfo;

    const roomInfo = hotelDetailResponse.roomDetails;
    return (
      <View style={[AtomicCss.whiteCardNew, AtomicCss.marginHz12, AtomicCss.borderRadius16]}>
        <View style={this.styles.CardContentNew}>
          <View style={this.styles.ContentPortion}>
            <Text
              style={[this.styles.blackFont, this.styles.BlackText, this.styles.mar10Bt, this.fsStyle.font16, AtomicCss.lineHeight20]}
            >{hotelDetailResponse.hotelDetailsList[0].hotelUIFields && hotelDetailResponse.hotelDetailsList[0].hotelUIFields.roomDetailsHeadingText}
            </Text>
            {this.addRooms(roomInfo)}
          </View>

        </View>
        {this.props.card && isNotNullAndEmpty(this.props.card.actionList) &&
          getActionButton(this.props.card, this.props.response, this.handleClick)}
        <ChildBookingInfo response={this.props.response} pageName={this.props.pageName} />
      </View>
    );
  }

  handleClick = (action) => {
    action = {...action, actionHandler: this.props.actionHandlerGB };
    handleClickEvent(this.props.card, action, this.props.response, this.props.pageName);
  };

  addRooms(roomInfo) {
    const views = [];
    roomInfo.forEach((roomDetail, index) => {
      this.addRoomCard(views, roomDetail, index, roomInfo.length);
    });
    return views;
  }

  onMoreClick(list) {
    this.setState({roomAmentiesLimit: list.length});
  }

  addRoomCard(views, roomDetail, index, noOfRooms) {
    const staticData = getStaticData();
    const { totalGuestBreakupText } = roomDetail;
    views.push(<View
      key={index}
      style={AtomicCss.marginBottom8}
    >
      <View style={[this.styles.roomOuter, AtomicCss.marginBottom4]}>
        <View style={this.styles.roomContent}>
          <Text
            style={[this.styles.BlackText, AtomicCss.marginBottom8, this.fsStyle.font16, AtomicCss.lineHeight20, this.fonts.boldFontFamily, AtomicCss.alignLeft]}
          >{roomDetail.roomTypeName} {this.showRoomBookingStatus(roomDetail)}
          </Text>
          {
            roomDetail?.mealPlanCode?.toUpperCase() !== 'NONE' && <View style={[this.styles.flexRow, this.styles.alignCenter, this.styles.mar4Bt]}>
              <Image
                style={this.styles.adultIcon}
                source={MEALPLAN_ICON}
              />
              <Text style={[this.fsStyle.font13, AtomicCss.lineHeight20, AtomicCss.defaultText, AtomicCss.marginLeft8]}>{this.showMealPlanText(roomDetail.mealPlan)}</Text>
            </View>
          }
          <View style={[this.styles.flexRow, this.styles.alignCenter]}>
            <Image
              style={this.styles.adultIcon}
              source={TRAVELLERS_ICON}
            />
            {totalGuestBreakupText && <Text style={[this.fsStyle.font13, AtomicCss.lineHeight20, AtomicCss.defaultText, AtomicCss.marginLeft8]}>{totalGuestBreakupText}</Text>}
          </View>
        </View>
        <TouchableOpacity style={this.styles.HotelMainImgContainer} onPress={() => this.openRoomPhotos()}>
          <Image
            style={this.styles.HotelMainImg}
            resizeMethod={'resize'}
            source={roomDetail.imageURL == null && isNullOrEmpty(roomDetail.roomImageURL) ? HOTEL_DEFAULT_ICON : {uri: this.showRoomImage(roomDetail)}}
          />
        </TouchableOpacity>
      </View>
      <Text style={[isNotNullAndEmpty(roomDetail.roomAmenties) ? this.styles.showView : this.styles.hideView, this.fsStyle.font13, AtomicCss.lineHeight20, AtomicCss.midGreyText, AtomicCss.alignLeft]} onPress={() => {this.onMoreClick(roomDetail.roomAmenties);}}>
        {showRoomAminities(roomDetail.roomAmenties, this.props.showAllAmenities ? roomDetail.roomAmenties.length :this.state.roomAmentiesLimit)} 
        {!this.props.showAllAmenities && roomDetail.roomAmenties && this.state.roomAmentiesLimit < roomDetail.roomAmenties.length && <Text style={[this.styles.showView, this.styles.BlueText]}>
        &nbsp;+ {showViewMoreText(roomDetail.roomAmenties, this.state.roomAmentiesLimit)} {staticData.moreText}
        </Text>}
      </Text>
      <View style={[this.styles.aminitiesOuter]}>
        {this.addRoomInclusion(roomDetail)}
      </View>
      {index < noOfRooms -1 && <View style={[this.styles.dividor, AtomicCss.marginBottom12, AtomicCss.marginTop16]}></View>}
    </View>);
  }

  addRoomInclusion(roomDetail) {
    const views = [];
    if(roomDetail && roomDetail.inclusions && roomDetail.inclusions !== 'null') {
      const inclusionList = roomDetail.inclusions.split('#');
      inclusionList.forEach((entry) => {
        entry && this.addRoomInclusionView(views, entry.trim());
      });
    }
    return views;
  }

  addRoomInclusionView(views, inclusionObject) {
    views.push(<View
      key={inclusionObject}
      style={[this.styles.aminitieswrap, {padding: 5}]}
    ><Image
      style={[isNotNullAndEmpty(inclusionObject) ? this.styles.showView : this.styles.hideView, this.styles.aminitiesIcon]}
      source={GREEN_TICK_ICON}
    /><Text
      style={[isNotNullAndEmpty(inclusionObject) ? this.styles.showView : this.styles.hideView, this.styles.aminities]}
    >{inclusionObject}
    </Text>
    </View>);
  }

  showRoomImage(roomInfo) {
    if (isNotNullAndEmpty(roomInfo.roomImageURL)) {
      return roomInfo.roomImageURL[0];
    } else if (roomInfo.imageURL != null) {
      return roomInfo.imageURL;
    }
  }

  showMealPlanText(mealPlanText) {
    if (mealPlanText.includes('&amp;')) {
      mealPlanText = mealPlanText.replace('&amp;', '&');
    }
    return mealPlanText;
  }

  showRoomBookingStatus(roomDetail) {
    const status = roomDetail.bookingStatus.toUpperCase();
    const staticData = getStaticData();
    if (this.getBookingSubStatusType()) {
      if (status === 'CANCELLED') {
        return '(' + staticData.cancelledCapsText + ')';
      }
      return '('+staticData.confirmedText+')';
    }
    return '';
  }

  getBookingSubStatusType() {
    const bookingSubstate = this.props.response.layoutDetail.bookingSubState;
    return (bookingSubstate === 'BetweenCanInitToRefundDone' || bookingSubstate == 'AfterRefundDone' ||
      bookingSubstate === 'PartiallyCancelledAtLeast1Upcoming' || bookingSubstate == 'PartiallyCancelledRestAllCompleted');
  }

  openRoomPhotos() {
    this.handleClick({actionFamily: HotelBookingDetailsConstant.ACTION_ROOM_PHOTOS});
  }

}


HotelRoomDetailsCard.propTypes = {
  response: PropTypes.object.isRequired,
  card: PropTypes.object.isRequired,
  pageName: PropTypes.string.isRequired
};

export default HotelRoomDetailsCard;
