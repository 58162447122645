import React from 'react';
import Actions from '../../../../../navigation/postSalesNavigation';
import { ScrollView, View, Text, Image, Linking } from 'react-native';
import FlightBookingTrackingHelper from '../../helper/FlightBookingTrackingHelper';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import BasePage from '../../../../../Common/PostSalesBasePage';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import Header from '../../../../../Common/HeaderCross';
import ReviewButton from '../../../../cancellation/SelectPassengers/ReviewButton';
import styles from './BaggageProtectPolicyPageCss';
import { MBR_ICON, IBR_IMG, TKR_IMG, GRNT_IMG } from '../../../../FlightImageConstants';
import { getFont } from '../../../../../PostSalesStyles';

class BaggageProtectionPolicyPage extends BasePage {
  constructor(props) {
    super(props, 'baggageProtectionPolicy');
  }

  static navigationOptions = {
    header: null,
  };

  getDescImg(type) {
    switch (type) {
      case 'MBR':
        return MBR_ICON;
      case 'IBRB':
        return IBR_IMG;
      case 'TRK':
        return TKR_IMG;
      case 'GRNT':
        return GRNT_IMG;
      default:
        return IBR_IMG;
    }
  }

  getDescStyle(type) {
    switch (type) {
      case 'MBR':
        return styles.mbrdescIconStyle;
      case 'IBRB':
        return styles.ibrbdescIconStyle;
      case 'TRK':
        return styles.trkdescIconStyle;
      case 'GRNT':
        return styles.grntdescIconStyle;
      default:
        return styles.ibrbdescIconStyle;
    }
  }

  onReportBaggageLossClicked = (url) => {
    Linking.openURL(url);
    FlightBookingTrackingHelper.trackClickEvent(
      'Details',
      'ReportBaggageLoss',
      this.props.responseJson,
    );
  };

  render() {
    const { fsStyle, ...fonts } = getFont(true);
    return (
      <View style={{ flex: 1, backgroundColor: '#f2f2f2' }}>
        <Header
          title="Baggage Protection"
          headerShadow
          headingTxt="font18"
          handleClose={this.onBackPress}
        />
        <ScrollView>
          <View style={[AtomicCss.marginTop10, AtomicCss.paddingBottom70]}>
            {!isEmpty(this.props.baggaeProtectionDetails.protectionDetails) && (
              <View style={styles.whiteWrapper}>
                <View style={styles.cancInfoHeading}>
                  <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
                    <Text
                      style={[
                        AtomicCss.blackText,
                        fonts.blackFontFamily,
                        AtomicCss.alignCenter,
                        fsStyle.font16,
                      ]}
                    >
                      {this.props.baggaeProtectionDetails.protectionDetails.title}
                    </Text>
                  </View>
                </View>
                <View style={styles.descriptionDetail}>
                  {this.props.baggaeProtectionDetails.protectionDetails.descriptions.map((desc) => (
                    <View
                      style={[
                        AtomicCss.flexRow,
                        AtomicCss.flex1,
                        AtomicCss.alignCenter,
                        AtomicCss.marginBottom8,
                      ]}
                    >
                      <View style={AtomicCss.flex1}>
                        <View style={{ flexDirection: 'row' }}>
                          <View style={styles.bullet} />
                          <Text style={[AtomicCss.defaultText, fsStyle.font14]}>{desc}</Text>
                        </View>
                      </View>
                    </View>
                  ))}
                </View>
              </View>
            )}
            {!isEmpty(this.props.baggaeProtectionDetails.protectionTermsnCondns) && (
              <View style={styles.whiteWrapper}>
                <View style={styles.cancInfoHeading}>
                  <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
                    <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
                      <Text
                        style={[
                          AtomicCss.blackText,
                          fonts.blackFontFamily,
                          AtomicCss.alignCenter,
                          fsStyle.font16,
                        ]}
                      >
                        {this.props.baggaeProtectionDetails.protectionTermsnCondns.title}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={styles.descriptionDetail}>
                  {this.props.baggaeProtectionDetails.protectionTermsnCondns.descriptions.map(
                    (desc) => (
                      <View style={[AtomicCss.flexRow, AtomicCss.flex1, AtomicCss.marginBottom20]}>
                        <View style={{ flexBasis: '20%', alignItems: 'flex-start' }}>
                          <View style={[styles.descIconContainer]}>
                            <Image
                              style={[this.getDescStyle(desc.code)]}
                              source={this.getDescImg(desc.code)}
                            />
                          </View>
                        </View>
                        <View style={[AtomicCss.flex1, AtomicCss.paddingLeft5]}>
                          <Text
                            style={[
                              fonts.blackFontFamily,
                              AtomicCss.blackText,
                              fsStyle.font14,
                              AtomicCss.marginBottom3,
                            ]}
                          >
                            {desc.name}
                          </Text>
                          <Text style={[AtomicCss.defaultText, fsStyle.font14]}>{desc.desc}</Text>
                        </View>
                      </View>
                    ),
                  )}
                </View>
              </View>
            )}
          </View>
        </ScrollView>
        {!isEmpty(this.props.baggaeProtectionDetails.baggageProtectCtaList) && (
          <View style={styles.footerBtnWrapper}>
            <ReviewButton
              buttonText={this.props.baggaeProtectionDetails.baggageProtectCtaList[0].text}
              clickHandler={() =>
                this.onReportBaggageLossClicked(
                  this.props.baggaeProtectionDetails.baggageProtectCtaList[0].redirectionUrl,
                )
              }
            />
          </View>
        )}
      </View>
    );
  }

  onBackPress = () => {
    Actions.pop();
  };
}

export default BaggageProtectionPolicyPage;

BaggageProtectionPolicyPage.propTypes = {
  baggaeProtectionDetails: PropTypes.object.isRequired,
  reportBaggageLossBtnClick: PropTypes.func.isRequired,
};
