import React from 'react';
import PropTypes from 'prop-types';
import {View, Text, Image, ScrollView, Platform} from 'react-native';
import  isEmpty  from 'lodash/isEmpty';
import Button from './Button';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import Header from './Header';
import {screenWidth, HolidayCancellationConstant} from '../HolidayBookingConstants';
import {fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import HeaderCross from '../../Common/HeaderCross/index';

class CancellationPolicyModal extends React.Component {
  static navigationOptions = {
    header: null
  };

  getMessages = () => {
    const views = [];
    const {cancellationPolicyPage} = this.props;
    const messages = cancellationPolicyPage.content && cancellationPolicyPage.content.newMessages;
    if (!isEmpty(messages)) {
      for (let i = 0; i < messages.length; i++) {
        views.push(
          <View style={[AtomicCss.flexRow, AtomicCss.marginBottom10]}>
            <Image style={styles.flexiDate} source={{uri: messages[i].icon_url}}/>
            <Text
              style={[AtomicCss.flex1, AtomicCss.defaultText, AtomicCss.font14, AtomicCss.regularFont, AtomicCss.lineHeight20]}>
              {messages[i].message}
            </Text>
          </View>
        );
      }
    }
    return views;
  };

  getPenaltyDataViews = penaltyDataList => penaltyDataList && penaltyDataList.map((item, index) => {
    const styleObj = this.getTextStyle(item.type);
    const parentViewStyle = [];
    parentViewStyle.push(styles.dtlsSectionRow);
    parentViewStyle.push(AtomicCss.marginBottom20);
    if (item.selected) {
      parentViewStyle.push(styleObj.boxStyle);
    }
    return (<View style={parentViewStyle}>
      {item.selected &&
      <View style={[styles.triangle, styleObj.arrowStyle]}/>
      }
      {index === penaltyDataList.length - 1 && <View style={styles.lastItem}/>}
      <View style={styles.lftSection}>
        <View style={[styles.circleOuter, styleObj.style]}>
          <View style={styles.circleInner}><Text/></View>
        </View>
        <View>
          <Text style={[styleObj.txtColor, AtomicCss.marginBottom3]}>
            {item.cancellationTimeLinerelativeToDep}
          </Text>
          <Text style={[AtomicCss.blackText, AtomicCss.font12, AtomicCss.regularFont]}>
            {item.tillDate}
          </Text>
        </View>
      </View>
      <View style={styles.rgtSection}>
        <Text style={[styleObj.txtColor, AtomicCss.alignRight]}>{item.cancellaitonCharges}{' '}</Text>
      </View>
    </View>);
  });

  getTextStyle = (type) => {
    const resultObj = {};
    resultObj.style = styles.orangeBG;
    resultObj.txtColor = styles.orangeTxt;
    if (type) {
      switch (type) {
        case 'fullRefund':
          resultObj.style = styles.greenBG;
          resultObj.txtColor = styles.greenTxt;
          resultObj.boxStyle = styles.greenBoxStyle;
          resultObj.arrowStyle = styles.greenArrowStyle;
          return resultObj;
        case 'payable':
          resultObj.style = styles.orangeBG;
          resultObj.txtColor = styles.orangeTxt;
          resultObj.boxStyle = styles.orangeBoxStyle;
          resultObj.arrowStyle = styles.orangeArrowStyle;
          return resultObj;
        case 'noRefund':
          resultObj.style = styles.redBG;
          resultObj.txtColor = styles.redTxt;
          resultObj.boxStyle = styles.redBoxStyle;
          resultObj.arrowStyle = styles.redArrowStyle;
          return resultObj;
        default:
          resultObj.style = styles.orangeBG;
          resultObj.txtColor = styles.orangeTxt;
          resultObj.boxStyle = styles.orangeBoxStyle;
          resultObj.arrowStyle = styles.orangeArrowStyle;
          return resultObj;
      }
    }
    return resultObj;
  };

  render() {
    const {cancellationPolicyPage, fromCancellation} = this.props;
    const cancellationInfoList = cancellationPolicyPage.content;
    let views = [];
    views = this.getMessages();
    return (
      <View style={styles.container}>
        {fromCancellation?
        <HeaderCross
         headerShadow
        title={HolidayCancellationConstant.CANCELLATION_HEADER}
        headingTxt="font18"
        handleClose={this.props.handleButtonClick}
      />:<Header
          title={cancellationPolicyPage.header}
          headerShadow
          pageName={this.props.pageName}
          goBack={this.props.onBackPopUp}
        />}
        <ScrollView>
          <View style={AtomicCss.whiteBg}>
            <View style={[styles.cardWrapper, AtomicCss.marginBottom10]}>
              {views}

              <View style={{paddingRight: 12}}>
                <Text
                  style={[AtomicCss.blackText, AtomicCss.blackFont, AtomicCss.font14,
                    styles.paddingLeft16, AtomicCss.marginBottom6, AtomicCss.marginTop20]}>
                  Applicable Refund</Text>
              </View>
              <View style={styles.cancellationInfoWrapper}>
                <View style={styles.line}/>
                <View style={styles.dtlsSection}>

                  {cancellationPolicyPage.content && cancellationPolicyPage.content.cancellationTimeLineCharges &&
                  this.getPenaltyDataViews(cancellationPolicyPage.content.cancellationTimeLineCharges)
                  }
                </View>
              </View>
            </View>

          </View>

        </ScrollView>
        {!isEmpty(cancellationPolicyPage.buttons) &&
        <View style={styles.buttonWrapper}>
          <Button
            pageName={this.props.pageName}
            button={cancellationPolicyPage.buttons[0]}
            handleClick={this.props.handleButtonClick}
            btnTxt={cancellationPolicyPage.buttons[0].text}
          />
        </View>
        }
      </View>
    );
  }
}

const styles = {
  container: {
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: '#f2f2f2',
    position: Platform.OS === 'web' ? 'fixed': 'absolute',
    elevation: 3,
    zIndex: 3,
    width: '100%',
    height: '100%'
  },
  cardWrapper: {
    width: screenWidth,
    backgroundColor: '#ffffff',
    paddingHorizontal: 16,
    paddingTop: 16
  },
  buttonWrapper: {
    paddingHorizontal: 10,
    paddingBottom: 20,
    backgroundColor: '#ffffff'
  },
  imageIcon: {
    width: 118,
    height: 72
  },
  cancellationInfoWrapper: {
    paddingBottom: 16,
    paddingHorizontal: 16,
    flexDirection: 'row',
    paddingTop: 10,
    justifyContent: 'space-between'
  },
  line: {
    width: 2,
    height: '98%',
    backgroundColor: '#cccccc',
    top: 10
  },
  dtlsSection: {
    flexDirection: 'column',
    flex: 1
  },
  dtlsSectionRow: {
    flexDirection: 'row',
    marginLeft: 10,
    borderRadius: 4,
    paddingLeft: 0,
    paddingVertical: 7,
    paddingRight: 7

  },
  lftSection: {
    flexDirection: 'row',
    flex: 1


  },
  circleOuter: {
    width: 8,
    height: 8,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20,
    left: -15,
    top: 2
  },
  circleInner: {
    width: 4,
    height: 4,
    alignSelf: 'center',
    backgroundColor: '#fff',
    borderRadius: 20
  },
  greenTxt: {
    fontFamily: fonts.bold,
    color: '#1a7971',
    fontSize: 12
  },
  orangeTxt: {
    fontFamily: fonts.bold,
    color: '#cf8100',
    fontSize: 12
  },
  redTxt: {
    fontFamily: fonts.bold,
    color: '#eb2026',
    fontSize: 12
  },
  flexStart: {
    alignSelf: 'flex-start'
  },
  rgtSection: {
    width: '35%',
    alignItems: 'flex-end'

  },
  greenBG: {
    backgroundColor: '#1a7971'
  },
  orangeBG: {
    backgroundColor: '#cf8100'
  },
  redBG: {
    backgroundColor: '#eb2026'
  },

  triangle: {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderLeftWidth: 5,
    borderRightWidth: 5,
    borderTopWidth: 8,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderTopColor: 'green',
    transform: [
      {rotate: '90deg'}
    ],
    margin: 0,
    marginLeft: -6,
    borderWidth: 0,
    position: 'absolute',
    top: 10,
    left: -1
  },
  greenBoxStyle: {
    backgroundColor: '#c1f1dd'
  },
  orangeBoxStyle: {
    backgroundColor: '#ffedd1'
  },
  redBoxStyle: {
    backgroundColor: '#ffd3d4'
  },
  greenArrowStyle: {
    borderTopColor: '#c1f1dd'
  },
  orangeArrowStyle: {
    borderTopColor: '#ffedd1'
  },
  redArrowStyle: {
    borderTopColor: '#ffd3d4'
  },
  lastItem: {
    width: 5,
    height: 75,
    backgroundColor: '#ffffff',
    top: 13,
    left: -14,
    position: 'absolute'
  },
  zcIconStyle: {
    width: 10,
    height: 13,
    marginRight: 12,
    marginTop: 5
  },
  flexiDate: {
    width: 16,
    height: 16,
    marginRight: 6,
    marginTop: 5
  },
  CancelIcon: {
    width: 16,
    height: 15,
    marginRight: 6,
    marginTop: 5
  }
};

CancellationPolicyModal.propTypes = {
  cancellationPolicyPage: PropTypes.object.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
  onBackPopUp: PropTypes.func.isRequired,
  pageName: PropTypes.string.isRequired
};

export default CancellationPolicyModal;
