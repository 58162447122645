import { StyleSheet } from "react-native";
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
export default (theme, fonts) =>StyleSheet.create({
    railInfoserviceCard:{
        ...getPlatformElevation(2),
        backgroundColor:theme.color.grey22,
        paddingTop:22,
        paddingBottom:30,
        flexDirection:'row',
        borderRadius:4,
        justifyContent:'space-between'
    },
    extraRailServices:{
        ...getPlatformElevation(2),
        backgroundColor:theme.color.grey22,
        flexDirection:'row',
        borderRadius:4,
        justifyContent:'space-between'
    },
    serviceIconWrapper:{
        width:48,
        height:48,
        alignItems:'center',
        justifyContent:'center',
        backgroundColor:theme.color.smokeWhite,
        borderRadius:25,
    },

    downArrowStyle: {
        width: 12,
        height: 8,
        marginLeft:15,

    },
    infoText:{
        color:theme.color.grey17,
        fontSize:10,
       ...(fonts.regularFontFamily),
        marginTop:10,
        textAlign:'center'
    },
    pnrIconStyle:{
        width:28,
        height:28,
    },
    showMoreBtn:{
        ...getPlatformElevation(2),
        backgroundColor:theme.color.grey22,
        flexDirection:'row',
        borderRadius:20,
        alignItems:'center',
        paddingVertical:10,
        paddingHorizontal:20,
        borderWidth:1,
        borderColor:theme.color.grey7,
        top:-20,
        alignSelf:'center',
    },
    activeArrow:{
        transform:[
            {
                rotate:'-180deg'
            }
        ]
    },
})