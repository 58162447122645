import { StyleSheet } from 'react-native';

export default (theme, fonts) => {
  const styles = StyleSheet.create({
    overlayWrapper: {
      position: 'absolute',
      zIndex: 2,
      backgroundColor: theme.color.white,
      height: '100%',
      width: '100%',
      top: 0,
      left: 0,
      elevation: 2,
    },
    headerGradient: {
      elevation: 8,
      shadowColor: '#000',
      shadowOffset: { width: 0, height: 0 },
      shadowOpacity: 0.3,
      shadowRadius: 2,
      paddingBottom: 1,
    },
    header: {
      padding: 18,
      flexDirection: 'row',
      alignItems: 'center',
    },
    closeIcon: {
      width: 15,
      height: 15,
      marginRight: 25,
    },
    headerTitle: {
      fontSize: fonts.fontSize.font14,
      ...fonts.regularFontFamily,
      color: theme.color.filterTextColor,
      backgroundColor: 'transparent',
    },
    FltFilterTab: {
      flexDirection: 'row',
      marginTop: 10,
    },
    FltFilterTabItem: {
      flex: 1,
      backgroundColor: 'transparent',
    },
    FilterActiveStyle: {
      borderBottomColor: theme.color.filterActiveBorderColor,
      borderBottomWidth: 3,
    },
    tabText: {
      fontSize: fonts.fontSize.font13,
      ...fonts.regularFontFamily,
      color: theme.color.filterTextColor,
      paddingBottom: 10,
      textAlign: 'center',
    },
    applyBtn: {
      height: 50,
      justifyContent: 'center',
      marginHorizontal: 12,
      marginVertical: 10,
    },
    filterItem: {
      flexDirection: 'row',
      alignItems: 'flex-start',
      paddingLeft: 15,
      paddingRight: 23,
      borderTopWidth: 2,
      borderTopColor: theme.color.lightGrey,
      paddingVertical: 20,
    },
    clearAllText: {
      fontSize: fonts.fontSize.font11,
      ...fonts.regularFontFamily,
      color: theme.color.filterTextColor,
      backgroundColor: 'transparent',
    },
  });

  return styles;
};
