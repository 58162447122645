import React, { useEffect, useState } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import createStyles from './CancelYourBookingStyle';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';

import Button from '../../../../../../Common/Button';

import { getFont } from '../../../../../../PostSalesStyles';
import { useTheme } from '../../../../../../theme/theme.context';
import FlightBookingTrackingHelper from '../../../helper/FlightBookingTrackingHelper';


interface CancellationCardDataType {
  header: string;
  subHeader: string;
  actionList: Array<{
    actionId: string;
    actionText: string;
  }>;
}
interface CancelYourBookingOverlayProps {
  handleBottomOverlay: (isConfirmBooking?: boolean) => void;
  cancellationCardData: CancellationCardDataType;
}
type handleCancelBookingType = () => void;

const CancelYourBookingOverlay = ({ handleBottomOverlay, cancellationCardData }: CancelYourBookingOverlayProps) => {

  const { header, subHeader, actionList } = cancellationCardData;

  const { fsStyle, ...fonts } = getFont();
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);
  useEffect(() => {
    FlightBookingTrackingHelper.trackLoadEvent('Details', {}, 'hold_myt_cancel_popup_shown');
  }, []);

  const handleGoBack = () => {
    handleBottomOverlay();
    FlightBookingTrackingHelper.trackClickEvent(
      'Details',
      'hold_myt_cancel_goback_cta',
    );
  };

  const handleCancelBooking: handleCancelBookingType = () => {
    FlightBookingTrackingHelper.trackClickEvent(
      'Details',
      'hold_myt_cancel_confirm_cta',
    );
     handleBottomOverlay(true);
  };
  return (
    <View style={styles.OverlayContentWrapper}>
      <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween, styles.headerWrapper]}>
        <Text style={[styles.title, AtomicCss.flex1]}>{header}</Text>
      </View>
      <Text
        style={[
          AtomicCss.defaultText,
          fsStyle.font14,
          fonts.regularFontFamily,
          AtomicCss.marginBottom26,
          AtomicCss.lineHeight20,
        ]}
      >
        {subHeader}
      </Text>
      <View style={styles.btnWrapper}>
        {actionList.map((action) => {
          if (action.actionId === 'Go_Back') {
            return (
              <TouchableOpacity onPress={handleGoBack}>
                <Text style={[fsStyle.font16, fonts.boldFontFamily, theme.color.azure]}>
                  {action.actionText}
                </Text>
              </TouchableOpacity>
            );
          } else if (action.actionId === 'Cancel_Booking') {
            return (
              <Button
                btnType="capsuleBtn"
                btnBg="gradientBtn"
                btnTxt={action.actionText}
                fontSize="font16"
                clickHandler={handleCancelBooking}
                btnClickAction={''}
              />
            );
          }
        })}
      </View>
    </View>
  );
};

export default CancelYourBookingOverlay;
