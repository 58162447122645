import Fecha from 'fecha';
import CabBookingTrackingHelper from '../../CabBookingTrackingHelper';
import { getPageName } from '../../utils/CabBookingDetailUtil';
import { nativeLog } from '../../../utils/PostSaleUtil';
export const EMPTY_STRING = '';
export const CLOSET_DATE_PICKER_DATE_FORMAT_DELIMITER = 'DD MMM YYYY,ddd -->hh : mm-->A';
export const DATE_FORMATTER_DELIMITER = '-->';
export const PICKER_DATE_FORMAT = 'DD MMM YYYY,ddd';

export function getDateAsPickerString(date, bookingId) {
    try {
        let dateString = getFormattedDateinIST(date, CLOSET_DATE_PICKER_DATE_FORMAT_DELIMITER);
        let pickerList = dateString.split(DATE_FORMATTER_DELIMITER);
        let pickerObj = {
            date: pickerList[0],
            blank: EMPTY_STRING,
            time: pickerList[1],
            blank2: EMPTY_STRING,
            meridiem: pickerList[2],
        };
        nativeLog(`dateString : ${JSON.stringify(dateString)}, bookingId : ${bookingId}`);
        return pickerObj;
    } catch (error) {
        const pageName = getPageName();
        const data = date && JSON.stringify(date);
        data && nativeLog(data);
        CabBookingTrackingHelper.trackErrorEvent(pageName);
    }
}

export function getFormattedDateinIST (date, outputFormat) {
    var d = new Date(date);
    var utc = d.getTime() + (d.getTimezoneOffset() * 60000);
    let formattedDate =  new Date(utc + (3600000 * 5.5));
    return getFormattedDateTime(formattedDate, outputFormat);
}

export function getFormattedDateTime (date, outputFormat) {
    nativeLog(`date : ${JSON.stringify(date)}, outputFormat : ${JSON.stringify(outputFormat)}`);
    var formattedDate = Fecha.format(date,outputFormat);
    return formattedDate;
}

export function getPickerData(startDate, endDate, selectedDate, bookingId){
    try {
        let dateList = [];
        let pickerData = {};
        let blank = [''];
        let time = [];
        let meridiem = ['AM', 'PM'];
        let adddays = addDays(getUtcDate(endDate), 100);
        var pickerSize =  findDiffDaysBetweenDates(startDate, adddays);
        for (var i = 0; i < pickerSize;) {
            dateList.push(getFormattedDateTime(addDays(getUtcDate(startDate), i), PICKER_DATE_FORMAT));
            i++;
        }
        let timeDiff = 15;
        let timeOptions = 24 * (60 / timeDiff);
        let hours = ['12','01','02','03','04','05','06','07','08','09','10','11'];
        let mins = ['00','15','30','45'];
        let k = 0;
        for (var j = 0; j < timeOptions;) {
            time.push(hours[k % 12] + ' : ' + mins[j % 4]);
            j++;
            if (j % 4 === 0) {
                k++;
            }
        }
        pickerData = {
            date: dateList,
            blank: blank,
            time: time,
            blank2: blank,
            meridiem: meridiem,
        };
        nativeLog(`pickerData : ${JSON.stringify(pickerData)}, bookingId: ${bookingId}`);
        return pickerData;
        } catch (error) {
        const pageName = getPageName();
        const data = JSON.stringify({startDate, endDate});
        data && nativeLog(data);
        CabBookingTrackingHelper.trackErrorEvent(pageName);
      }
}

export function getUtcDate(date){
    var d = new Date(date);
    var utc = d.getTime() + (d.getTimezoneOffset() * 60000);
    return new Date(utc + (3600000 * 5.5));
}

export function getIsoDate(newDate){
    const epoc = Fecha.parse(newDate , 'DD MMM YYYY,ddd hh : mm A').valueOf();
    const isoDateString = new Date(epoc).toISOString();
    return isoDateString;
}

export function findDiffDaysBetweenDates(firstDate, secondDate) {
    const oneDay = 24 * 60 * 60 * 1000;
    const FirstDateObj = new Date(fillDateAndTime(firstDate, 'YYYY-MM-DD'));
    const secondDateObj = new Date(fillDateAndTime(secondDate, 'YYYY-MM-DD'));
    return Math.abs((secondDateObj.getTime() - FirstDateObj.getTime()) / oneDay);
}
export function fillDateAndTime(date, outputDateFormat) {
    let resultDate = '';
    const newdate = new Date(new Date(date).valueOf());
    const formattedDate = Fecha.parse(`${newdate.getFullYear()}-${newdate.getMonth() + 1}-${newdate.getDate()}`, 'YYYY-MM-DD');
    resultDate = `${Fecha.format(new Date(formattedDate), outputDateFormat)}`;
    return resultDate;
}

export function addDays(date, noOfdays)  {
    let resultDate = new Date();
    resultDate.setDate(date.getDate() + noOfdays);
    return resultDate;
}

export function getCommitRequest (optionSelected, prevRequest){
    const commitRequest = prevRequest[0];
    commitRequest.payFullAmount = optionSelected.payFullAmount;
    commitRequest.refundMode = optionSelected.type === 'refund' ? optionSelected.id : '';
    return commitRequest;
}

export  function getPaymentOptions(carDetails, paymentOptionsData){
    const newPaymentOption = {};
    if (carDetails.minPaymentValue > 0) {
        return getPaymentOptionIfMinPaymentValueIsGreaterThanZero(newPaymentOption,carDetails,paymentOptionsData);
    }
    return getPaymentOptionIfMinPaymentValueIsZero(newPaymentOption,carDetails,paymentOptionsData);
}

export function getPaymentOptionIfMinPaymentValueIsGreaterThanZero(newPaymentOption,carDetails,paymentOptionsData) {
    const paymentOptions = [];
    const payMinimumAmount = paymentOptionsData.itemsList.filter(option => option.key === 'commitPayNow');
    paymentOptions.push({
        'heading' : payMinimumAmount[0].heading.replace('$$',carDetails.minPaymentValueText),
        'subHeading' : payMinimumAmount.subHeading,
        'key' : 'payMinimumAmount',
    });
    paymentOptions.push({
        'heading' : payMinimumAmount[0].heading.replace('$$',carDetails.paymentValueText),
        'subHeading' : payMinimumAmount.subHeading,
        'key' : 'payFullAmount',
    });
    return paymentOptions;
}

export function getPaymentOptionIfMinPaymentValueIsZero(newPaymentOption,carDetails,paymentOptionsData) {
    const paymentOptions = [];
    const payFullAmount = paymentOptionsData.itemsList.filter(option => option.key === 'commitPayNow');
    paymentOptions.push({
        'heading' : payFullAmount[0].heading.replace('$$',carDetails.paymentValueText),
        'subHeading' : payFullAmount.subHeading,
        'key' : 'payFullAmount',
    });
    paymentOptions.push({
        'heading' : carDetails.minPaymentValueText,
        'key' : 'commitPayToDriver',
    });
    return paymentOptions;
}

 function getPaymentValueForCarDetails(paymentValue, ctakey){
     if (ctakey === 'refund' && paymentValue > 0){
         return (-1 * paymentValue);
     } else {
         return paymentValue;
     }
}
export function getPaymentOptionData(carDetails,oldCarDetails, priceList) {
    const newPriceList = [];
    const breakupOfPriceDiff = [];
    const priceDifference = priceList.filter(price => price.key === 'oldPrice');
    breakupOfPriceDiff.push({ 'label':'New Price', 'amountText': carDetails.priceValueText },
        { 'label': priceDifference[0].label,'amountText': `${priceDifference[0].amountText} ` });
    const selectedCarPaymentValue = getPaymentValueForCarDetails(carDetails.paymentValue,carDetails.ctaKey);
    const priceDiffAmount = Math.round(Math.abs(( selectedCarPaymentValue + oldCarDetails.paymentValue) - (priceDifference[0].amount)));
    newPriceList.push({ 'label' : 'Price Difference', 'amountText': `₹ ${priceDiffAmount} `, 'breakup':breakupOfPriceDiff });
    const remainingPriceList = priceList.filter(price => price.key !== 'oldPrice');
    remainingPriceList.map(price => (
        newPriceList.push({ 'label' : price.label, 'amountText': price.amountText } )
    ));
    newPriceList.push({ 'label':carDetails.netPayableText, 'amountText': carDetails.paymentValueText });
    return newPriceList;
}
