import React from 'react';
import {Image, Text, View, TouchableOpacity} from 'react-native';
import PropTypes from 'prop-types';
import Actions from '../../../navigation/postSalesNavigation';
import styles from '../../CSS/VisaMamiCss';
import {tickIcon} from '../../Constant/VisaImageConstant';


class VisaOtbPolicyCard extends React.Component {
  render() {
    return (
      <View style={[styles.MamiCard, styles.mar10Bt]} >
        <View style={styles.VisaCardContent}>
          <View style={styles.flexRow}>
            <Image style={styles.CardIcon} source={tickIcon} />
            <View style={styles.flex1}>
              <Text style={[styles.cancellationPolicyCard]}>
                {this.props.response.visaBookingInfo.visaDetailList[0].otbHeading}
              </Text>
              <Text style={[styles.cancellationPolicyText]}>
                {this.props.response.visaBookingInfo.visaDetailList[0].otbDescription}
              </Text>
              {this.props.response.otbRulesUrl &&
                <TouchableOpacity onPress={() => this.openWebView(this.props.response.otbRulesUrl)}>
                  <Text style={{color: 'blue'}}>
                        Okay To Board(OTB) Rules
                  </Text>
                </TouchableOpacity>
                }
            </View>
          </View>
        </View>
      </View>
    );
  }

  openWebView = (url) => {
    Actions.openPsWebView({
      url,
      headerText: 'OKAY TO BOARD RULES'
    });
  };
}

VisaOtbPolicyCard.propTypes = {
  response: PropTypes.object.isRequired
};


export default VisaOtbPolicyCard;
