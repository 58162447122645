import { DEFAULT_THEME } from 'apps/post-sales/src/theme/default.theme';
import { ImageStyle, StyleSheet, TextStyle, ViewStyle } from 'react-native';

interface Styles {
  listRow: ViewStyle;
  listDtlsSection: ViewStyle;
  headerMessage: ViewStyle;
  iconPlaceHolder: ViewStyle;
  iconStyle: ImageStyle;
  rgtArrowStyle: ImageStyle;
  popupOpenText: TextStyle;
}

const createStyles = <Theme extends typeof DEFAULT_THEME>(theme: Theme) =>
  StyleSheet.create<Styles>({
    listRow: {
      borderTopWidth: 1,
      borderTopColor: theme.color.cabsGreyBg,
      padding: 16,
      alignItems: 'center',
      flexDirection: 'row',
    },
    listDtlsSection: {
      flexDirection: 'row',
      flex: 1,
      marginRight: 15,
    },
    headerMessage: {
        color: theme.color.defaultTextColor,
    },
    iconPlaceHolder: {
      width: 32,
      marginTop: 3,
    },
    iconStyle: {
      width: 17,
      height: 21,
      alignSelf: 'flex-start',
    },
    rgtArrowStyle: {
      width: 9,
      height: 13,
    },
    popupOpenText: {
      color: theme.color.blue10,
    },
  });

export default createStyles;
