import { Platform } from 'react-native';
import { trackOmniture } from '@mmt/legacy-commons/Common/utils/OmnitureTrackerUtils';
import PostSalesEventLogger from '../../PostSalesEventLogger';
import { getUserDetails } from '@mmt/legacy-commons/Native/UserSession/UserSessionModule';

class TripSummaryOmnitureTracker {
  getPageName = async () => {
    const userDetails = await getUserDetails();
    try {
      return `mob:cs|${userDetails?.profileType?.toLowerCase()
        }|bookingsummary`;
    }
    catch (e) {
      console.log(e);
    }
  };
  trackLoadEvent = async (allBookings, bookingState = '') => {
    const pageName = await this.getPageName();
    try {
      PostSalesEventLogger.loadEvent(
        'TripSummary',
        allBookings && allBookings.length > 0 ? allBookings[0] : {},
      );
      trackOmniture(
        pageName,
        this.getTrackingOmnitureParams(
          pageName,
          bookingState,
        ),
      );
    } catch (e) {
      console.log(e);
    }
  };

  getTrackingOmnitureParams(pageName, bookingState) {
    try {
      const params = {};
      params.m_v15 = pageName;
      params.m_v24 = 'customer support';
      //On booking summary no way to determine country and currency, so keeping those values as empty.
      if (Platform.OS === 'web') {
        params.m_v37 = 'domain_sbu:|domain_country:|domain_currency:|domain_language:English';
      }
      params.m_v108 = `${pageName}_${bookingState.toLowerCase()}`;
      params.pageName = pageName;
      params.m_v87 = 'revamp_v1';
      return params;
    }
    catch (e) {
      console.log(e);
    }
  }

  trackBookingClickEvent = (lobName, bookingStatus, bookingId, clickEvent) => {
    try {
      PostSalesEventLogger.clickEvent('TripSummary', clickEvent ? clickEvent : 'bookingClicked', {
        bookingId,
      });
      const params = {};
      const pageName = `mob:cs:${lobName}:itinerary:${bookingStatus ? bookingStatus.toLowerCase() : ''}`;
      params.m_v15 = pageName;
      params.m_v24 = 'customer support';
      params.m_v16 = bookingId;
      params.m_v87 = 'revamp_v1';
      if (clickEvent) {
        params.m_c54 = clickEvent;
      } else {
        params.m_c54 = `MI_${bookingStatus ? bookingStatus.toUpperCase(): ''}`;
      }
      params.m_c1 = params.m_c54;
      trackOmniture(pageName, params);
    } catch (e) {
      console.log(e);
    }
  };

  trackNormalClickEvent = (ctaName, clickEvent, data = {}) => {
    try {
      PostSalesEventLogger.clickEvent('TripSummary', clickEvent, {});
      const params = {};
      const pageName = `mob:cs:personal:booking summary|${ctaName}`;
      params.m_c54 = clickEvent;
      params.m_c1 = clickEvent;
      params.m_v24 = 'customer support';
      params.m_v87 = 'revamp_v1';
      trackOmniture(pageName, params);
    } catch (e) {
      console.log(e);
    }
  };

  trackErrorEvent = (pageName, error) => {
    try {
      PostSalesEventLogger.logPostSalesAction('TripSummaryErrorPage', { errorReason: error });
      const params = {};
      params.m_c22 = error;
      params.m_v87 = 'revamp_v1';
      trackOmniture(pageName, params);
    } catch (e) {
      console.log(e);
    }
  };
}

export default new TripSummaryOmnitureTracker();
