import React from 'react';
import PropTypes from 'prop-types';
import {View, Text, Image, StyleSheet} from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {blueDropIcondown, blueDropIconUp} from '../HolidayBookingConstants';

class IncExcHeader extends React.Component {
  static navigationOptions = {
    header: null
  };

  render() {
    return (
      <View style={[styles.CollapsedTapContainer1, AtomicCss.alignCenter]}>
        <View style={styles.CollapsedTapLeftSection}>
          <Text style={[AtomicCss.font16, AtomicCss.blackText, AtomicCss.blackFont]}>
            {this.props.item.heading}
          </Text>
        </View>
        <View style={[styles.CollapsedTapRightSection, AtomicCss.marginTop10]}>
          <Image
            style={[styles.AlignSelfRight, styles.blueArrow, AtomicCss.marginBottom10]}
            source={this.props.tabbing ? blueDropIconUp : blueDropIcondown}
          />
        </View>
      </View>

    );
  }
}

const styles = StyleSheet.create({
  CollapsedTapContainer1: {
    backgroundColor: 'transparent',
    paddingHorizontal: 20,
    paddingVertical: 10,
    flexDirection: 'row'
  },
  CollapsedTapLeftSection: {
    width: '70%'
  },
  CollapsedTapRightSection: {
    width: '30%'
  },
  blueArrow: {
    width: 13,
    height: 7
  },
  AlignSelfRight: {
    alignSelf: 'flex-end'
  }
});

IncExcHeader.propTypes = {
  item: PropTypes.object.isRequired,
  tabbing: PropTypes.bool.isRequired
};

export default IncExcHeader;
