import React from 'react';
import {
    View,
    Text,
    TouchableOpacity,
    ScrollView,
    StatusBar,
    BackHandler,
    DeviceEventEmitter,
    TouchableHighlight,
    Platform
} from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import TopStickyCard from './components/DetailPage/TopStatusCards/TopStickyCard';
import CancellationCard from './components/DetailPage/CancellationCard';
import TotalRefundCard from './components/DetailPage/TotalRefundCard';
import MoreLinksCard from './components/DetailPage/moreLinksCard';
import ProfileVerificationCard from './components/DetailPage/ProfileVerificationCard';
import CarDeliveryDtlsCard from './components/DetailPage/CarDeliveryDtlsCard';
import TravellerDtlsSection from './components/DetailPage/TravellerDtlsSection';
import CarAssignedDtlsCArd from './components/DetailPage/CarAssignedDtlsCArd';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import TripInfoCard from './components/DetailPage/TripInfoCard';
import PickDropProcessCard from './components/DetailPage/pickDropProcessCard';
import SummaryCard from './components/DetailPage/summaryCard/index';
import LocationOverlay from './components/DetailPage/BottomOverlay/LocationOverlay';
import ProgressView from '@mmt/legacy-commons/Common/Components/Progress/ProgressView';
import SimpleHeader from '@mmt/legacy-commons/Common/Components/Header/SimpleHeader.android';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import {getCommonHeaders, SELF_DRIVE_BOOKING_DETAILS_URL} from '../utils/NetworkUtils';
import NoInternetView from '@mmt/legacy-commons/Common/Components/Error/NoInternetView';
import {statusBarHeightForIphone} from '@mmt/legacy-commons/Styles/globalStyles';
import {isNetworkAvailable} from '@mmt/legacy-commons/Common/utils/AppUtils';
import ViewControllerModule from '@mmt/legacy-commons/Native/ViewControllerModule';
import {isEmpty} from 'lodash';
import ZoomCarConstant from './utils/ZoomCarConstants';
import RefundTracker from '../Common/RefundTracker';
import TopStatusCard from './components/DetailPage/TopStatusCards/TopStatusCard';
import ProfileVerificationPendingCard from './components/DetailPage/summaryCard/ProfileVerificationPendingCard';
import { gatherLocationInfo, setUser } from './utils/SelfDriveBookingDetailUtil';
import BasePage from '../Common/PostSalesBasePage';
import ChecklistOverlay from './components/DetailPage/BottomOverlay/ChecklistOverlay';
import Support from './components/DetailPage/Support';
import {LOBNAMES, SELF_DRIVE_DETAIL_LANDING_KEY} from '../PostSalesConstant';
import CabInfoOverlay from './components/DetailPage/CabInfoOverlay';
import SelfDriveBookingTrackingHelper from './SelfDriveBookingTrackingHelper';
import RefundEndTracker from './components/DetailPage/RefundEndTracker/index';
import AlertInfo from '../Common/CommonImpInfo';
import AdvisoryNotice from "../Common/AdvisoryNotice";
import styles from './styles/SelfDriveMamiCss';
import AdvisoryOverlay from "../Common/AdvisoryNotice/AdvisoryOverLay";
import { REFUND_OOT_TRACKING } from '../Common/RefundOutOfTATForm/constants';
import LostIDBookingDetailPage from '../Common/LostIdDetailPage';
import { isLostBookingData } from '../utils/PostSaleUtil';
import { isEmptyArray } from '../Common/commonUtil';
import addBookingTrackingHelper, { ADD_BOOKING_TRACKING_EVENTS } from '../summary/components/AddBooking/tracking';

export const ViewState = {
    LOADING: 'loading',
    NO_INTERNET: 'no_internet',
    SHOW_DETAIL: 'detail',
    ERROR: 'error',
    NO_RESULT: 'no_result',
};


export default class selfDriveBookingDetail extends BasePage {
    static navigationOptions = {
      header: null
    }

    constructor(props) {
      super(props, 'selfDriveBookingDetail');
      if (props.data != null && props.data.BOOKING_ID != null) {
        this.bookingId = props.data.BOOKING_ID;
      } else {
        this.bookingId = props.BOOKING_ID || props.bookingId;
      }
      this.pageData = props.data && props.data.pageData ? JSON.parse(props.data.pageData) : undefined;
      this.isLostBooking = isLostBookingData(this.pageData);
      this.mmtAuth = '';
      this.loggingTrackInfo = {};
      this.trackPageName = '';
      this.state = {
        openViewPickUpLocationOverlay: false,
        openViewDropLocationOverlay: false,
        responseJson: this.props.responseJson,
        viewState: this.isLostBooking ? ViewState.SHOW_DETAIL : ViewState.LOADING,
        showOverlay: false,
        errorText: '',
        errorMessage: '',
        overlay: false,
        defaultErrorMessage: 'Something went wrong, Please try again!',
        bookingDetail: {},
        advisoryOverlay : false
      };
      this.openViewPickUpLocationOverlay = this.openViewPickUpLocationOverlay.bind(this);
      this.openViewDropLocationOverlay = this.openViewDropLocationOverlay.bind(this);
      this.toggleAdvisoryOverlay = this.toggleAdvisoryOverlay.bind(this);
      this.handlePress = this.handlePress.bind(this);
    }

    toggleOverlay() {
        this.setState({overlay: !this.state.overlay});
    }

    componentDidMount() {
      super.componentDidMount();
      if (!this.isLostBooking) {
        this.callBookingDetailApi(SELF_DRIVE_BOOKING_DETAILS_URL + this.bookingId).then(r => console.log('booking detail response'));
      }
      DeviceEventEmitter.addListener('ZOOMCAR_CHECKLIST_RESPONSE', this.refreshPageData);
      DeviceEventEmitter.addListener('ZOOMCAR_PROFILE_RESPONSE', this.refreshPageData);

      if (this.pageData?.isAddBookingFlow) {
        addBookingTrackingHelper.trackLoadEvent(
          ADD_BOOKING_TRACKING_EVENTS.Addbooking_detailspage_landed,
          this.pageData?.uuid,
          this.bookingId,
          SELF_DRIVE_DETAIL_LANDING_KEY
        );
      }
    }

    componentWillReceiveProps(props){
      if (props && props.data && props.data.refresh === true) {
        if (this.isLostBooking) {
          this.setState({
            refreshLostDetail: {},
          });
          return;
        }
        this.setState({viewState: ViewState.LOADING});
        this.callBookingDetailApi(SELF_DRIVE_BOOKING_DETAILS_URL + this.bookingId).then(r => console.log('booking detail response'));
      }
    }

    componentDidUpdate() {
      if (!isEmpty(this.state.responseJson)) {
        SelfDriveBookingTrackingHelper.trackLoadEvent(SELF_DRIVE_DETAIL_LANDING_KEY, this.state.bookingDetail, this.state.responseJson.cardList);
      }
    }

    componentWillUnmount() {
        DeviceEventEmitter.removeAllListeners('ZOOMCAR_CHECKLIST_RESPONSE');
        DeviceEventEmitter.removeAllListeners('ZOOMCAR_PROFILE_RESPONSE');
    }

    refreshPageData = () => {
        this.setState({viewState: ViewState.LOADING,
            openViewPickUpLocationOverlay: false,
            openViewDropLocationOverlay: false,
            overlay: ''});
        this.callBookingDetailApi(SELF_DRIVE_BOOKING_DETAILS_URL + this.bookingId).then(r => console.log('refreshed booking detail!!'));
    }

    handlePress = (e) => {
      this.setState({overlay: e});
    }

    closeOverlay = (e) => {
      this.setState({
        openViewPickUpLocationOverlay: false,
        openViewDropLocationOverlay: false,
        overlay: ''
      });
    }
    goBack = () => {
      if (Platform.OS === 'ios') {
        ViewControllerModule.popViewController(this.props.rootTag);
      } else {
          super.onBackIconPress();
      }
    };

    renderProgressView = () => (<ProgressView message="Loading Your Trip..." />);

    toggleAdvisoryOverlay(){
        this.setState({advisoryOverlay: !this.state.advisoryOverlay});
    }

    render() {
      return (
        <View style={styles.flex1}>
          {this.state.viewState === ViewState.NO_INTERNET && this.renderNoNetworkView()}
          {this.state.viewState === ViewState.ERROR && this.renderErrorView()}
          {this.state.viewState === ViewState.SHOW_DETAIL && this.renderPage()}
          {this.state.viewState === ViewState.SHOW_DETAIL && this.state.advisoryOverlay && this.renderAdvisoryOverlay()}
          {this.state.viewState === ViewState.LOADING && this.renderProgressView()}
        </View>
      );
    }

    getMainPageViews() {
      const views = [];
      const cards = this.state.responseJson.cardList;
      cards && cards.forEach((card) => {
        this.addCard(card, views);
      });
      return views;
    }

    renderAdvisoryOverlay(){
        return(
            <View style={styles.overlayContainer}>
                <TouchableHighlight style={styles.overlayBg}><Text>.</Text></TouchableHighlight>
                <AdvisoryOverlay
                    key="SelfDriveBottomOverlay"
                    pageName="SelfDriveBottomOverlay"
                    advisoryNoticeText={this.state.responseJson && this.state.responseJson.advisoryNoticeText}
                    closeoverlay={this.toggleAdvisoryOverlay}
                />
            </View>
        )
    }

    renderPage() {
      if (this.isLostBooking) {
        return (
          <LostIDBookingDetailPage
            bookingId={this.bookingId}
            pageData={this.pageData}
            lobName={LOBNAMES.SELF_DRIVE}
            refreshLostDetail={this.state.refreshLostDetail}
            getRefundCard={({ response, canReqNoList }) => this.renderRefundCard(response, canReqNoList, true)}
          />
        );
      }
      const views = this.getMainPageViews();
      const barStyleColor = '#d6d6d6';
      return (
        <React.Fragment>
          <StatusBar backgroundColor={barStyleColor} barStyle="light-content" />
          <ScrollView stickyHeaderIndices={[0]} bounces={false} style={AtomicCss.greyBg}>
            <View style={{elevation: 2, zIndex: 5}}>
              <TopStickyCard
                response={this.state.bookingDetail}
                onBackPress={this.goBack}
                card={this.state.responseJson.cardList[0]}
              />
            </View>
            {views}
          </ScrollView>
          {this.state.openViewPickUpLocationOverlay &&
          <LocationOverlay
            handlePress={this.closeOverlay}
            tripInfo={gatherLocationInfo(this.state.bookingDetail.tripInfo.pickup)}
            subText={(this.state.bookingDetail.tripInfo.selfPickup) ? (' Collect your car from this place ') : (' Car will be delivered at :')}
            header="Pick-up Location"
          />
                }
          {this.state.openViewDropLocationOverlay &&
          <LocationOverlay
            handlePress={this.closeOverlay}
            tripInfo={gatherLocationInfo(this.state.bookingDetail.tripInfo.drop)}
            subText={this.state.bookingDetail.tripInfo.selfDrop ? (' Drop your car at this place ') : (' Car will be collected from :')}
            header="Drop Location"
          />
                }
          {this.state.overlay === ZoomCarConstant.PICKUP_OVERLAY &&
          <ChecklistOverlay
            handlePress={this.closeOverlay}
            header="Pick Up Checklist"
            checkList={this.state.bookingDetail.pickupProcess.popUpCheckList.pickUpCheckListItems}
            bookingDetail={this.state.bookingDetail}
          />
                }
          {this.state.overlay === ZoomCarConstant.DROP_OVERLAY &&
          <ChecklistOverlay
            handlePress={this.closeOverlay}
            header="Drop Up Checklist"
            checkList={this.state.bookingDetail.dropoffProcess.popUpCheckList.pickUpCheckListItems}
            bookingDetail={this.state.bookingDetail}
          />
                }
          {this.state.overlay === ZoomCarConstant.KNOW_MORE_OVERLAY &&
          <CabInfoOverlay
            handlePress={this.closeOverlay}
            response={this.state.bookingDetail}
          />
                }

        </React.Fragment>
      );
    }

    renderRefundCard = (response, cancelDetailNoList, lostBooking = false) => {
      if (cancelDetailNoList.length) {
        return (<RefundTracker
          key="RefundTracker"
          cancelRequests={cancelDetailNoList}
          bookingId={this.bookingId}
          bookingResponse={response}
          lobName={LOBNAMES.SELF_DRIVE}
          isLostBookingId={lostBooking}
          pageName={REFUND_OOT_TRACKING.PAGES.SELF_DRIVE_CAR}
        />);
      }
      return null;
    }

    addCard(card, views) {
      switch (card.cardName) {
        case ZoomCarConstant.TOP_STATUS_CARD_PROFILE_VERIFICATION_PENDING_NO_DRIVING_LICENSE:
        case ZoomCarConstant.TOP_STATUS_CARD_PROFILE_VERIFICATION_PENDING_INCOMPLETE_DOCUMENTS:
        case ZoomCarConstant.TOP_STATUS_CARD_PROFILE_VERIFICATION_IN_PROGRESS:
        case ZoomCarConstant.TOP_STATUS_CARD_PROFILE_NOT_VERIFIED:
        case ZoomCarConstant.TOP_STATUS_CARD_BOOKING_CONFIRMED:
        case ZoomCarConstant.TOP_STATUS_CARD_CAR_ASSIGNED:
        case ZoomCarConstant.TOP_STATUS_CARD_CAR_ON_THE_WAY:
        case ZoomCarConstant.TOP_STATUS_CARD_FINDING_A_CAR:
        case ZoomCarConstant.TOP_STATUS_CARD_ENROUTE:
        case ZoomCarConstant.TOP_STATUS_CARD_PROFILE_VERIFIED:
        case ZoomCarConstant.TOP_STATUS_CARD_CAR_NOT_ASSIGNED:
        case ZoomCarConstant.TOP_STATUS_CARD_CUSTOMER_CANCELLED:
        case ZoomCarConstant.TOP_STATUS_CARD_VENDOR_CANCELLED:
        case ZoomCarConstant.TOP_STATUS_CARD_PROFILE_REJECTED:
        case ZoomCarConstant.TOP_STATUS_CARD_TRIP_END_DROP_CHECKLIST_DONE:
        case ZoomCarConstant.TOP_STATUS_CARD_TRIP_END:
        case ZoomCarConstant.TOP_STATUS_CARD_TRIP_END_PAYMENT_APPLICABLE:
        case ZoomCarConstant.TOP_STATUS_CARD_BOOKING_FAILED:
        case ZoomCarConstant.TOP_STATUS_CARD_DRIVER_ASSIGNED:
          views.push(<View key={card.cardName} style={{elevation: 2, zIndex: 10}}>
            <TopStatusCard
              responseJson={this.state.bookingDetail}
              card={card}
              onBackPress={this.goBack}
            />
                     </View>);
          break;
        case ZoomCarConstant.PROFILE_VERIFICATION_CARD:
          views.push(<View key={card.cardName} style={{elevation: 1}}>
            <ProfileVerificationPendingCard
              card={card}
              response={this.state.bookingDetail}
            />
                     </View>);
          break;
        case ZoomCarConstant.PROFILE_VERIFICATION_OVERLAY:
          views.push(<View key={card.cardName} style={{elevation: 1}}>
            <TouchableOpacity onPress={() => this.handlePress('profileVerifyOverlay')}><Text>Profile
                            Verification
            </Text>
            </TouchableOpacity>
                     </View>);
          break;
        case ZoomCarConstant.CAR_CARD:
          views.push(<View key={card.cardName} style={{elevation: 1}}>
            <SummaryCard
              card={card}
              response={this.state.bookingDetail}
              openViewPickUpLocationOverlay={this.openViewPickUpLocationOverlay}
              openViewDropLocationOverlay={this.openViewDropLocationOverlay}
              handlePress={this.handlePress}
            />
                     </View>);
          break;
        case ZoomCarConstant.CAR_TRIP_INFO_CARD:
          views.push(<View key={card.cardName} style={{elevation: 1}}>
            <TripInfoCard
              card={card}
              response={this.state.bookingDetail}
              handleEndTrip={this.handlePress}
            />
                     </View>);
          break;
        case ZoomCarConstant.CAR_PICK_DROP_PROCESS_CARD:
          views.push(<View key={card.cardName} style={{elevation: 1}}>
            {!isEmpty(this.state.bookingDetail.pickupProcess.details) && <PickDropProcessCard
              card={card}
              response={this.state.bookingDetail}
              handleClick={this.handlePress}
            />}
                     </View>);
          break;
        case ZoomCarConstant.CAR_ASSIGNED_DETAIL_CARD:
          views.push(<View key={card.cardName} style={{elevation: 1}}>
            <CarAssignedDtlsCArd
              card={card}
              response={this.state.bookingDetail}
            />
                     </View>);
          break;
        case ZoomCarConstant.CAR_DELIVERY_DETAILS:
          views.push(<View key={card.cardName} style={{elevation: 1}}>
            <CarDeliveryDtlsCard
              card={card}
              response={this.state.bookingDetail}
            />
                     </View>);
          break;
        case ZoomCarConstant.CAR_CANCELLATION_CARD:
          views.push(<View key={card.cardName} style={{elevation: 1}}>
            <CancellationCard
              card={card}
              response={this.state.bookingDetail}
            />
                     </View>);
          break;
        case ZoomCarConstant.ZC_PAID_CARD:
          views.push(<View key={card.cardName} style={{elevation: 1}}>
            <TotalRefundCard
              card={card}
              response={this.state.bookingDetail}
            />
                     </View>);
          break;
        case ZoomCarConstant.TRAVEL_DETAIL_CARD:
          views.push(<View key={card.cardName} style={{elevation: 1}}>
            <TravellerDtlsSection
              card={card}
              response={this.state.bookingDetail}
            />
                     </View>);
          break;
        case ZoomCarConstant.ZC_REFUND_TRACKER_CARD:
          const { cancellationDetail } = this.state.bookingDetail || {};
          const cancelDetailNoList = [];
          if (this.state.bookingDetail && !isEmpty(cancellationDetail) && !isEmpty(cancellationDetail.requestNo)) {
            cancelDetailNoList.push(cancellationDetail.requestNo);
          }
          !isEmptyArray(cancelDetailNoList) && views.push(<View key={card.cardName} style={{elevation: 1}}>
            {this.renderRefundCard(this.state.bookingDetail, cancelDetailNoList)}
          </View>);
          break;
        case ZoomCarConstant.VALIDATION_TRIP_END_CARD:
          views.push(<View key={card.cardName} style={{elevation: 1}}>
            <RefundEndTracker
              key="RefundEndTracker"
              response={this.state.bookingDetail}
              card={card}
            />
          </View>);
          break;
        case ZoomCarConstant.PROFILE_VERIFICATION_DONE_CARD:
          views.push(<View key={card.cardName} style={{elevation: 1}}>
            <ProfileVerificationCard
              card={card}
            />
          </View>);
          break;
        case ZoomCarConstant.ACTION_CALL_MMT_SUPPORT:
          views.push(<View key={card.cardName} style={{elevation: 1}}>
            {<Support
              card={card}
              response={this.state.bookingDetail}
            />}
                     </View>);
          break;
        case ZoomCarConstant.MORE_ABOUT_BOOKING_CARD:
          views.push(<View key={card.cardName} style={{elevation: 1}}>
            <MoreLinksCard
              card={card}
              response={this.state.bookingDetail}
            />
                     </View>);
          break;
        case ZoomCarConstant.IMPORTANT_INFO_CARD:
          views.push(<AlertInfo
            key="AlertInfo"
            infoList={this.state.bookingDetail.importantInformationList}
          />);
          break;
        case ZoomCarConstant.ADVISORY_NOTICE:
            views.push(<AdvisoryNotice
                key={ZoomCarConstant.ADVISORY_NOTICE}
                heading={card.header}
                description={card.subHeaders && card.subHeaders[0]}
                card={card}
                togglePopUp={this.toggleAdvisoryOverlay}
            />)
           break;
        default:
          break;
      }
    }

    openViewPickUpLocationOverlay = () => {
      this.setState({
        openViewPickUpLocationOverlay: true
      });
    };

    openViewDropLocationOverlay = () => {
      this.setState({
        openViewDropLocationOverlay: true
      });
    };

    renderNoNetworkView = () => (
      <View style={{flex: 1, marginTop: statusBarHeightForIphone}}>
        <SimpleHeader
          title="Back"
          iconPress={this.goBack}
        />
        <NoInternetView
          onRetry={() => {
                    this.setState({viewState: ViewState.LOADING});
                    this.fetchJSONAsync(SELF_DRIVE_BOOKING_DETAILS_URL + this.bookingId);
                }}
        />
      </View>
    );

    renderErrorView = () => (
      <View style={{flex: 1, marginTop: statusBarHeightForIphone}}>
        <SimpleHeader
          title="Back"
          iconPress={this.goBack}
        />
        <ErrorView
          showRetry
          onRetry={() => {
                    this.setState({viewState: ViewState.LOADING});
                    this.callBookingDetailApi(SELF_DRIVE_BOOKING_DETAILS_URL + this.bookingId);
                }}
          message={!isEmpty(this.state.errorMessage) ? this.state.errorMessage : this.state.defaultErrorMessage}
        />
      </View>
    );

    async callBookingDetailApi(url) {
      try {
        const hasNetwork = await isNetworkAvailable();
        if (!hasNetwork) {
          this.setState({viewState: ViewState.NO_INTERNET});
          return;
        }
        const userInfo = await HotelBookingModule.getRequestHeader(this.bookingId, 'SELF_DRIVE_BOOKING_DETAIL');
        this.mmtAuth = userInfo.mmtAuth;
        this.loggingTrackInfo = userInfo.loggingTrackingInfo;

        const response = await fetch(url, {headers: await getCommonHeaders(this.mmtAuth, this.loggingTrackInfo)});
        const body = await response.json();
        if (body && (body.errorCode === 500 || body.status === 400 || body.status === 500)) {
          this.setState({
            viewState: ViewState.ERROR,
            errorMessage: body.errorMessage
          });
          this.trackError();
        } else {
          this.setState({
            responseJson: body,
            bookingDetail: body.bookingDetail,
            viewState: ViewState.SHOW_DETAIL
          });
          setUser(body.bookingDetail);
        }
      } catch (error) {
        this.setState({viewState: ViewState.ERROR});
        this.trackError();
      }
    }

    trackError = () => {
      SelfDriveBookingTrackingHelper.trackErrorEvent('Mob:Customer Support:selfdriveTripDetails_Error');
    }
}
