import React from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import styles from './BookMyForexCardCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { trackClickEvent } from '@mmt/legacy-commons/Common/utils/OmnitureTrackerUtils';
import { isEmpty } from 'lodash';
import GenericModule from "@mmt/legacy-commons/Native/GenericModule";

const BookMyForexCard = ({cardData, pageName, customStyle}) => {
    if(isEmpty(cardData)){
        return null;
    }
    const handleForexClick = () => {
        const link = cardData.deepLink;
        trackClickEvent(pageName, 'BookMyForex');
        GenericModule.openDeepLink(link);
    };
    return (
        <View style={[AtomicCss.marginBottom10, styles.cardBorder]}>
            <View style={[styles.infoCardWrapper, customStyle? customStyle:{}]}>
                {!isEmpty(cardData.heading) && <Text style={[AtomicCss.blackFont, AtomicCss.font16, AtomicCss.marginBottom12, AtomicCss.defaultText]}>{cardData.heading}</Text>}
                {!isEmpty(cardData.listOfMessage) &&
                    <View style={styles.bmfInfoTextWrapper}>
                         {cardData.listOfMessage.map((message) =>
                        <Text style={[AtomicCss.font12, AtomicCss.regularFont, AtomicCss.defaultText, AtomicCss.lineHeight15]}>{message}</Text>
                         )}
                    </View>}
                <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween]}>
                    {!isEmpty(cardData.cta) && <TouchableOpacity style={AtomicCss.marginTop5} onPress={handleForexClick}>
                        <Text style={[AtomicCss.font14, AtomicCss.blackFont, AtomicCss.azure, AtomicCss.textUppercase]}>{cardData.cta}</Text>
                    </TouchableOpacity>}
                    <View style={AtomicCss.flexColumn}>
                       <View style={[AtomicCss.flexRow, AtomicCss.alignSelfFlexEnd]}>
                           {!isEmpty(cardData.forexImageIcon) && <Image style={styles.bmfLogoStyle} source={{uri: cardData.forexImageIcon}} />}
                           {!isEmpty(cardData.textImageUrl) && <Image style={styles.bmfTextLogoStyle} source={{uri: cardData.textImageUrl}} />}
                        </View>
                        {!isEmpty(cardData.subTitle) && <Text style={[AtomicCss.font10, AtomicCss.regularFont, AtomicCss.defaultText]}>{cardData.subTitle}</Text>}
                    </View>
                </View>
            </View>
        </View>
    )
}


export default BookMyForexCard;
