import {trackOmniture} from '@mmt/legacy-commons/Common/utils/OmnitureTrackerUtils';

class SubmitRequestTrackingHelper {

  trackLoadEvent = (pageName, bookingId, errorCode) => {
    const lob = bookingId.startsWith('NN') ? 'Intl' : 'Dom';
    const trackingPageName = `Mob:Customer support:${lob} Flight:${pageName}`;
    const params = {};
    params.pageName = trackingPageName;
    params.m_c22 = errorCode;
    trackOmniture(trackingPageName, params);
  }
}

export default new SubmitRequestTrackingHelper();
