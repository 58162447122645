import React from 'react';
import PropTypes from 'prop-types';
import {View, Text, StyleSheet, Image, Platform} from 'react-native';
import AtomicCss from '../../commonStyles/AtomicCss';
import {colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import {ZC_IMAGE} from '../../Utils/RailImageConstant';
import { rupeeAmount } from '@mmt/legacy-commons/Helpers/currencyUtils';
import _ from 'lodash';
import { getStaticData } from '../../../staticData/staticData';
import { titleCase } from '@mmt/legacy-commons/Helpers/stringHelpers';
import { getFont } from '../../../PostSalesStyles';

class PartialCancellationBreakupDtls extends React.Component {
    static navigationOptions = {
      header: null
    };


    render() {
      const staticData = getStaticData();
      const { fsStyle, ...fonts } = getFont(true);
      const { noRefundText, yourRefundText, youNeedToPayText, cancellationFeeText } = staticData;
      const {totalRefundAmount, totalCancelationCharges, totalPaidAmount, remainingAmountText} = this.props.fareBreakUpObject;
      const refundText = this.props.isRefundZero ? noRefundText : yourRefundText;
      const bnplHeader = this.props.penaltyResponse && this.props.penaltyResponse.isNeedToPay;

      return (
        <View style={[AtomicCss.whiteBg, AtomicCss.elevation1]}>
        {bnplHeader && this.renderBnplHeader()}
          <View style={[AtomicCss.flexRow, styles.CancPriceBreckup, styles.borderbtm]}>
            <View style={styles.CancLeftSection}>
              <Text style={[AtomicCss.blackText, fsStyle.font14,
                fonts.boldFontFamily, AtomicCss.marginBottom8]}
              >{this.paxCountPaidString()}
              </Text>
              {this.addBreakup(this.props.paidComponent)}
            </View>
            <View>
              <Text style={[AtomicCss.blackText, fsStyle.font14,
                fonts.boldFontFamily, AtomicCss.marginBottom8]}
              > {totalPaidAmount}{' '}
              </Text>
              {this.addBreakupValue(this.props.paidComponent)}
            </View>
          </View>
          <View style={[AtomicCss.flexRow, styles.CancPriceBreckup, styles.borderbtm]}>
            <View style={styles.CancLeftSection}>
              <Text style={[AtomicCss.blackText, fsStyle.font14,
                fonts.boldFontFamily, AtomicCss.marginBottom8]}
              >{cancellationFeeText}
              </Text>

              {this.addBreakup(this.props.deductionComponent)}

            </View>
            <View>
              <Text style={[AtomicCss.blackText, fsStyle.font14,
                fonts.boldFontFamily, AtomicCss.marginBottom8]}
              > {totalCancelationCharges}{' '}
              </Text>

              {this.addBreakupValue(this.props.deductionComponent)}
            </View>
          </View>
        <View style={[AtomicCss.flexRow, styles.CancPriceBreckup]}>
           <View style={styles.CancLeftSection}>
            {!bnplHeader && <Text style={[(this.props.isRefundZero ? styles.impInfoRed : styles.impInfoGreen), fonts.boldFontFamily,
                           AtomicCss.marginBottom8]}>
                              {refundText}
                      </Text>}
            {bnplHeader && <Text style={[styles.impInfoRed, fonts.boldFontFamily, AtomicCss.marginBottom8, fsStyle.font16]}>
                  {youNeedToPayText}
                 </Text>
            }
        </View>
           <View>
        {!bnplHeader && <Text style={[(this.props.isRefundZero ? styles.impInfoRed : styles.impInfoGreen), fonts.boldFontFamily,
            AtomicCss.marginBottom8]}
        > {totalRefundAmount}{' '}
        </Text>}

            {bnplHeader && <Text style={[styles.impInfoRed, fonts.boldFontFamily, AtomicCss.marginBottom8, fsStyle.font16]}
            > {totalRefundAmount}{' '}
            </Text>}
            </View>
          </View>
          {!!remainingAmountText && <View style = {[styles.remainingAmount]}><Text style={styles.remainingAmountText}>{remainingAmountText}</Text></View>}
            { this.props.zcplusSubtext && !_.isEmpty(this.props.zcplusSubtext) &&
            <View style={[AtomicCss.flexRow,styles.zcSubText]}>
            <Text style={[AtomicCss.redText, fonts.regularFontFamily]}>{this.props.zcplusSubtext}</Text>
            </View>
            }
        </View>

      );
    }
    paxCountPaidString = () => {
      const staticData = getStaticData();
      const { totalPaidText, travellerText, trainTravellersText } = staticData;
      if (this.props.isFullCancellation) {
        return titleCase(totalPaidText);
      }
      if (this.props.selectedPax) {
        if (this.props.selectedPax.length > 1) {
          return `${titleCase(totalPaidText)} (${this.props.selectedPax.length} ${trainTravellersText})`;
        }
        return `${titleCase(totalPaidText)} (${this.props.selectedPax.length} ${travellerText})`;
      }
      return titleCase(totalPaidText);
    }

  addBreakup = components => components.map(component => {
    const { fsStyle } = getFont(true);
    if ((this.props.penaltyResponse.cancellationAssured && component.labelId === "ticket_cancellation_charges") || (this.props.penaltyResponse.isZcplus && component.labelId === "zcplus")) {
      return (
        <View>
          <View style={[AtomicCss.flexRow, styles.priceBreakups]}>
            <Image source={ZC_IMAGE} style={{ height: 20, width: 20, paddingRight:5 }} />
            <View style={styles.marginBottom0}>
              <Text style={[fsStyle.font12, AtomicCss.lightGrey]}>
                {component.label}
              </Text>
            </View>
          </View>
        </View>
      )
    }
    else {
      return (
        <View style={[AtomicCss.flexRow, styles.priceBreakups]}>
          <View>
            <Text style={[fsStyle.font12, AtomicCss.lightGrey, styles.minHeight]}>
              {component.label}
            </Text>
          </View>
        </View>
      )
    }
  });

  addBreakupValue = (components) => components.map(component => {
    const { fsStyle, ...fonts } = getFont(true);
    if ((this.props.penaltyResponse.cancellationAssured && component.labelId === "ticket_cancellation_charges") || ((this.props.penaltyResponse.isZcplus && component.labelId === "zcplus"))) {
      return (
        <View>
            <Text style={[AtomicCss.flexRow, AtomicCss.lightGrey, fsStyle.font12, fonts.regularFontFamily, styles.minHeight]}>
              <Text style={[styles.lineThrough]}>{component.value} </Text>
              <Text style={[AtomicCss.lightGrey, styles.rightComponent, styles.impInfoGreen, fonts.regularFontFamily, fsStyle.font14]}>
                {rupeeAmount(0)}{' '}
              </Text>
            </Text>

        </View>
      )
    }
    else {
      return (
        <View>

          <Text style={[AtomicCss.flexRow, AtomicCss.lightGrey, fsStyle.font12, fonts.regularFontFamily, styles.rightComponent,styles.minHeight]}>
            {component.value}{' '}
          </Text>
        </View>
      )
    }
  });


  renderBnplHeader = () => {
    const { fsStyle } = getFont(true);
    return (
      <View style={[styles.impInfoRedOuter]}>
          <Text style={[styles.impInfoBNPL, fsStyle.font13]}>{this.props.penaltyResponse.bnplmessage}
          </Text>
      </View>
    )
  }


}

PartialCancellationBreakupDtls.propTypes = {
  fareBreakUpObject: PropTypes.object.isRequired,
  selectedPax: PropTypes.array,
  isFullCancellation: PropTypes.bool.isRequired,
  deductionComponent: PropTypes.array.isRequired,
  paidComponent: PropTypes.array.isRequired,
  hideBreakup: PropTypes.bool.isRequired,
  penaltyResponse: PropTypes.object
};

PartialCancellationBreakupDtls.defaultProps = {
  selectedPax: null,
  penaltyResponse: null
};

const styles = StyleSheet.create({
  CancPriceBreckup: {
    justifyContent: 'space-between',
    padding: 16
  },
  borderbtm: {
    borderBottomWidth: 1,
    borderBottomColor: '#ddd'
  },
  CancLeftSection: {
    flexDirection: 'column'
  },
  impInfoGreen: {
    color: '#00a19c',
    fontSize: 14,
    marginBottom: 10
  },
  impInfoRed: {
    fontFamily: fonts.bold,
    color: '#d0021b',
    fontSize: 16
  },
  rightComponent: {
    textAlign: 'right'
  },
  lineThrough: {
    textDecorationLine: 'line-through'
  },

  marginBottom0:{
    marginBottom: 0
  },
  marginBottom2:{
    marginBottom: 4
  },
  minHeight: {
    minHeight: Platform.OS === 'web' ? '20px' : 0
  },
  impInfoRedOuter: {
    width: '100%',
    paddingLeft: 10,
    paddingRight:10,
    paddingTop:10
  },
  impInfoBNPL: {
    color: '#e53442',
    fontFamily: fonts.bold
  },
  zcSubText: {
    paddingTop:0,
    paddingBottom:8,
    paddingLeft:15,
    marginTop :-15
  },
  remainingAmount: {
    paddingBottom:8,
    paddingHorizontal: 15,
    marginTop :-15
  },
  remainingAmountText: {
    color: colors.defaultTextColor,
    fontFamily: fonts.regular
  }
});

export default PartialCancellationBreakupDtls;
