import { ImageStyle, StyleSheet, TextStyle, ViewStyle } from 'react-native';
import { ThemeType, FontsType } from  '../../../../../postSalesStylesType';

interface Styles {
  backWrapper: ViewStyle;
  iconBack: ImageStyle;
  icon16: TextStyle;
  btnText: TextStyle;
  input: TextStyle;
  inputContainer: ImageStyle;
  btnContainer: ViewStyle;
  rightArrow: ImageStyle;
  dropdownText: TextStyle;
  searchTypeSection: TextStyle;
  dropDown: ViewStyle;
  textWrapper: ViewStyle;
  borderTop: ViewStyle;
  psTab: ViewStyle;
  increaseArrow: ImageStyle;
  popularSearchWrapper: ViewStyle;
}

const createStyles = (theme: ThemeType, fonts: FontsType) =>
  StyleSheet.create<Styles>({
      backWrapper: {
        width: 16,
        paddingVertical: 4,
        paddingRight: 10,
      },
      iconBack: {
        height: 16,
        width: 16,
        marginRight: 16,
      },
      icon16: {
        height: 16,
        width: 16,
      },
      rightArrow: {
        width: 9,
        height: 13,
      },
      btnText: {
        ...fonts.boldFontFamily,
        fontSize: fonts.fontSize.font12,
        letterSpacing: 0.23,
        color: theme.color.azure,
        textTransform: 'uppercase',
        textAlign: 'right',
      },
      btnContainer: {
        marginRight: 8,
      },
      input: {
        backgroundColor: theme.color.white,
        color: theme.color.black,
        ...fonts.boldFontFamily,
        zIndex: -2,
        position: 'relative',
        fontSize: fonts.fontSize.font16,
        textTransform: 'capitalize',
        paddingHorizontal: 15,
        paddingVertical: 15,
        flex: 1,
      },
      inputContainer: {
        alignItems: 'center',
        zIndex: 2,
        paddingHorizontal: 20,
        position: 'relative',
        backgroundColor: theme.color.white,
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: theme.color.grey24,
      },
      dropDown: {
        backgroundColor: theme.color.white,
        zIndex: 2,
      },
      dropdownText: {
        fontSize: fonts.fontSize.font14,
        flex:1,
        color: theme.color.black,
        ...fonts.regularFontFamily,
        zIndex: 2,
        textTransform: 'capitalize',
        marginLeft: 5,
        marginRight: 6,
      },
      textWrapper: {
        backgroundColor: theme.color.white,
        paddingVertical: 12,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      searchTypeSection: {
        marginTop: 12,
        marginBottom: 6,
      },
      increaseArrow: {
        width: 17,
        height: 10,
        marginRight: 10,
      },
      popularSearchWrapper: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        marginBottom: 20,
      },
      psTab: {
        borderRadius: 8,
        borderWidth: 1,
        borderColor: theme.color.lightGray,
        paddingHorizontal: 8,
        paddingVertical: 12,
        marginVertical: 6,
        backgroundColor: theme.color.white,
        marginRight: 12,
      },
      borderTop: {
        borderTopWidth: 1,
        borderTopColor: theme.color.grey26,
      },
  });

export default createStyles;
