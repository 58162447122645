import { isEmpty } from "lodash";
import { Platform } from "react-native";
import { isNullOrEmpty } from "../Common/commonUtil";
import { CANCELLATION_REASON } from "./Cancellation/HotelCancellationConstants";
import HotelBookingDetailsConstant from "./details/HotelBookingDetailsConstant";
import { getCardAndActionFromAllCards } from "./details/utils/HotelBookingDetailUtil";
import { MODIFICATION_POPUP_TYPES, MODIFICATION_PRICE_CATEGORY } from "./HotelConstants";
import { CANCELLATION_POLICY_CHANGED_TRACKING_SUFFIX, ROOM_CHANGED_TRACKING_SUFFIX } from "./modification/HotelModificationConstants";

export const showErrorPageCTA = (actionId, resp) => {
  // Show either if errorPageData ctaList is null or if it contains that CTA 
  return (!resp || !resp.errorPageData || !resp.errorPageData.ctaList) || resp.errorPageData.ctaList.some(cta => (
     cta && cta.actionId && cta.actionId.toLowerCase() === actionId.toLowerCase()
  ));
}

export const getErrorPageHeading = (defaultHeading, resp) => {
  return resp && resp.errorPageData && !!resp.errorPageData.heading
     ? resp.errorPageData.heading
     : defaultHeading
}

export const getErrorPageDesc = (defaultDesc, resp) => {
  return resp && resp.errorPageData && resp.errorPageData.errorDesc !== null && resp.errorPageData.errorDesc !== undefined
      ? resp.errorPageData.errorDesc
      : defaultDesc;
};

export const getModificationConfirmationPopupData = (resp) => {
  if (!resp) return {};
  const popupDataList = resp.popupDataList || [];
  const popupData = popupDataList.find(popupData => popupData && popupData.key === MODIFICATION_POPUP_TYPES.CONFIRMATION) || {};
  const actionList = popupData.actionList || [];
  const action = actionList.find(action => action && action.ctaActionFamily === HotelBookingDetailsConstant.ACTION_CONFIRM_MODIFICATION) || {};
  const { heading, desc } = popupData;
  const { ctaText } = action;
  const trackingSuffix = getRoomAbsorptionDetails(resp);
  return { heading, desc, ctaText, trackingSuffix };
}

const getPriceCategoryRange = (hotelSearchPrice) => {
  const {availableRoomDetails} = hotelSearchPrice || {};
  let suffix = MODIFICATION_PRICE_CATEGORY.Pay_NA
  if( !isEmpty(availableRoomDetails) ){
    const [roomDetail] = availableRoomDetails;
    const {amountTobePaid} = roomDetail || {};
    if(amountTobePaid===0){
      suffix = MODIFICATION_PRICE_CATEGORY.NoPay;
    }else if( amountTobePaid <=200){
      suffix = MODIFICATION_PRICE_CATEGORY.PayExtra_0_200;
    }else if( amountTobePaid <=500){
      suffix = MODIFICATION_PRICE_CATEGORY.PayExtra_200_500;
    }else if( amountTobePaid <=1000){
      suffix = MODIFICATION_PRICE_CATEGORY.PayExtra_500_1000;
    }else if( amountTobePaid <=1500){
      suffix = MODIFICATION_PRICE_CATEGORY.PayExtra_1000_1500;
    }else if( amountTobePaid <=2000){
      suffix = MODIFICATION_PRICE_CATEGORY.PayExtra_1500_2000;
    }else if( amountTobePaid <=3000){
      suffix = MODIFICATION_PRICE_CATEGORY.PayExtra_2000_3000;
    }else{
      suffix = MODIFICATION_PRICE_CATEGORY.PayExtra_3000;
    }
  }
  return suffix;
}

const getRoomAbsorptionDetails = (hotelSearchPrice) => {
  const {absorptionDetails} = hotelSearchPrice || {};
  let sameRoomChangedSuffix = ROOM_CHANGED_TRACKING_SUFFIX;
  let cancellationPolicyChangedSuffix = CANCELLATION_POLICY_CHANGED_TRACKING_SUFFIX;
  if(!isEmpty(absorptionDetails)){
    const {sameRoomAvailable, cancelPolicyChanged} = absorptionDetails;
    sameRoomChangedSuffix = `${sameRoomChangedSuffix}_${!sameRoomAvailable}`;
    cancellationPolicyChangedSuffix = `${cancellationPolicyChangedSuffix}_${cancelPolicyChanged}`;
  }
  return `${sameRoomChangedSuffix}_${cancellationPolicyChangedSuffix}`;
}

export const getModificationPolicyTrackerInfo = (hotelSearchPrice) => {
  const suffixData = getPriceCategoryRange(hotelSearchPrice);
  const data =  !isEmpty(hotelSearchPrice) ?
  `isOldNr_${hotelSearchPrice.oldBkgNR}_isNewNR_${(hotelSearchPrice.newBkgNR)}_isOldBNPL_${hotelSearchPrice.oldBNPL}_isNewBNPL_${hotelSearchPrice.newBNPL}`
  : "";
  const roomAbsorptionSuffix = getRoomAbsorptionDetails(hotelSearchPrice);
  return data + " | " + suffixData + " | " + roomAbsorptionSuffix;
}

export const isMweb = () => Platform.OS === "web";

export const isModifyAllowed = (hotelBookingResponse, type, blockModification) => {
  let cardAndAction ;
  switch(type){
      case CANCELLATION_REASON.CHANGE_DATE:
          cardAndAction = getCardAndActionFromAllCards(hotelBookingResponse, HotelBookingDetailsConstant.ACTION_CHANGE_DATES);

          // discuss to handle corporate booking
          if (cardAndAction.card && cardAndAction.action && !blockModification)
              return true;
          break;
       case CANCELLATION_REASON.ADD_GUEST:
          cardAndAction = getCardAndActionFromAllCards(hotelBookingResponse, HotelBookingDetailsConstant.ACTION_ADD_GUEST);
          // discuss to avoid alerting added second check
          if (cardAndAction.card && cardAndAction.action && isNullOrEmpty(cardAndAction.actionAlertHeaderText) && isNullOrEmpty(cardAndAction.actionAlertMainText) && !blockModification )
              return true;
          break;
       case CANCELLATION_REASON.CHANGE_NAME:
          cardAndAction = getCardAndActionFromAllCards(hotelBookingResponse, HotelBookingDetailsConstant.ACTION_EDIT_PRIMARY_GUEST_NAMES);
          if (cardAndAction.card && cardAndAction.action)
              return true;
          break;
      case CANCELLATION_REASON.PROCEED_CANCEL:
          return true; 
       default: 
          return false;
  }
  return false;
}
export const isModificationEligible = (hotelBookingResponse, cancellationReason, blockModification)=>{
  const {modalData} = cancellationReason;
  if( modalData ){
      const {details, ctaList} = modalData;
      if( !isNullOrEmpty(ctaList) ){
          const newCTAList = [];
          ctaList.forEach( ctaItem => {
              if(ctaItem.actionFamily && isModifyAllowed(hotelBookingResponse, ctaItem.actionFamily, blockModification) ){
                  newCTAList.push(ctaItem);
              }
          })
          if( !isEmpty(newCTAList) ){
              modalData.ctaList = newCTAList;
              return true;
          }
      } else if ( !isNullOrEmpty(details) ) {
          const {cta} = details;
          if( ( cta && cta.actionFamily && isModifyAllowed(hotelBookingResponse, cta.actionFamily, blockModification) ) ){
              return true;
          }
      }
  }
  return false;
}
