import { StyleSheet } from 'react-native';
import { getScreenWidth } from '../../../../utils/PostSaleUtil';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';

const windW = getScreenWidth();

const styles = StyleSheet.create({
  topStickySection: {
    backgroundColor: '#fff',
    zIndex: 10,
  },
  TabsWrapper: {
    backgroundColor: '#fff',
    flexDirection: 'row',
    zIndex: 1,
    textAlign: 'center',
  },
  contentWrapper: {
    backgroundColor: '#e7e7e7',
    flex: 1,
  },
  cardWrapper: {
    width: windW,
    flex: 1,
  },
  headerSec: {
    paddingHorizontal: 12,
    paddingTop: 16,
  },
  headingIcon: {
    width: 24,
    height: 24,
    marginRight: 10,
    backgroundColor: '#f1f1f1',
  },
  docList: {
    paddingLeft: 45,
    paddingRight: 45,
  },
  bullet: {
    width: 4,
    height: 4,
    marginRight: 10,
    marginTop: 6,
    backgroundColor: '#4a4a4a',
    borderRadius: 50,
  },
  idCardWrapper: {
    width: windW,
    marginTop: 12,
  },
});

export default styles;
