import React from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';

import { colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles';

import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getPlatformElevation from './getPlatformElevation';

import { buttonTypes, NEED_HELP_CARD_ITEMS, myraIcon, callIcon, chatIcon, rightArrowBlue } from '../HolidayBookingConstants';
import { themeColors } from '../../PostSalesStyles';

const NeedSupportCard = ({
    cardData,
    handleButtonClick
}) => {

    const renderCardItem = (item, cardData, id) => {
        switch (item.type) {
            case NEED_HELP_CARD_ITEMS.AGENT_DETAILS:
                return <TouchableOpacity activeOpacity={.7}
                    style={[styles.connectHlpCard, AtomicCss.marginBottom10]}
                    onPress={() => handleButtonClick({ type: buttonTypes.CONTACT_RO })}
                >
                    <Image style={styles.iconStyle} source={callIcon} />
                    <View style={AtomicCss.flex1}>
                        <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween]}>
                            <Text style={[styles.helpCardTitle, AtomicCss.boldFont]}>
                                {item.heading}
                            </Text>
                            <Image style={styles.rtArrow} source={rightArrowBlue} />
                        </View>
                        <Text style={[AtomicCss.font14, styles.helpCardSubTitle, AtomicCss.regularFont]}>
                            {item.subHeading}
                    </Text>
                    </View>
                </TouchableOpacity>;
            case NEED_HELP_CARD_ITEMS.CUSTOMER_FORM:
                return <TouchableOpacity
                    activeOpacity={.7}
                    style={[styles.connectHlpCard]}
                    onPress={() => handleButtonClick({ type: buttonTypes.CUSTOMER_FORM })}
                >
                    <Image style={styles.iconStyle} source={chatIcon} />
                    <View style={AtomicCss.flex1}>
                        <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween]}>
                            <Text style={[styles.helpCardTitle, AtomicCss.boldFont]}>
                                {item.heading}
                    </Text>
                            <Image style={styles.rtArrow} source={rightArrowBlue} />
                        </View>
                        <Text style={[AtomicCss.font14, styles.helpCardSubTitle, AtomicCss.regularFont]}>
                            {item.subHeading}
                </Text>

                    </View>
                </TouchableOpacity>

        }
    }

    return (
        <View style={[AtomicCss.marginBottom10, styles.cardBorder]}>
            <View style={[styles.needSupportSection]}>
                <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween, AtomicCss.marginBottom5]}>
                    <Image source={myraIcon} style={styles.myraIconStyle} />
                    <View style={AtomicCss.flex1}>
                        <Text style={[styles.title, AtomicCss.flex1]}>{cardData.heading}</Text>
                        <Text style={[AtomicCss.font14, AtomicCss.regularFont, AtomicCss.defaultText, AtomicCss.marginBottom12]}>{cardData.subHeading}</Text>
                        <View style={[styles.blueLineStyle, AtomicCss.marginBottom15]}></View>
                    </View>
                </View>
                {
                    cardData.holidayHelpCardItemList &&
                    cardData.holidayHelpCardItemList.map(
                        item => {
                            if (item.isEnabled) {
                                return renderCardItem(item, cardData);
                            } else {
                                return null;
                            }
                        }
                    )
                }
            </View>

        </View>
    )
}

export default NeedSupportCard;

const styles = {
    cardBorder: {
        borderTopColor: colors.lightGrey3,
        borderBottomColor: colors.lightGrey3,
        borderTopWidth: 1,
        borderBottomWidth: 1
    },

    needSupportSection: {
        backgroundColor: colors.white,
        justifyContent: 'space-between',
        ...getPlatformElevation(2),
        marginBottom: 1,
        padding: 16,
        paddingTop: 25,
        paddingBottom: 20,
    },

    title: {
        fontFamily: fonts.black,
        fontSize: 14,
        color: colors.defaultTextColor,
        marginBottom: 10,
    },
    myraIconStyle: {
        width: 64,
        height: 64,
        marginRight: 20,
    },
    blueLineStyle: {
        width: 24,
        height: 4,
        backgroundColor: themeColors.primary,
    },
    connectHlpCard: {
        borderWidth: 1,
        borderColor: '#e3f1ff',
        backgroundColor: '#f7fbff',
        paddingVertical: 15,
        paddingHorizontal: 10,
        borderRadius: 6,
        flexDirection: 'row',
    },
    iconStyle: {
        width: 24,
        height: 24,
        marginRight: 10,
    },
    helpCardTitle: {
        fontFamily: fonts.regular,
        color: themeColors.primary,
        fontSize: 16,
        marginBottom: 4
    },
    rtArrow: {
        width: 9,
        height: 13,
        marginLeft: 10,
        top: 3,
        tintColor: themeColors.rightArrowTintColor
    },
    helpCardSubTitle: {
        color: colors.lightTextColor,
    }
}