import {
  MONTH_QUOTE_HALF_YEAR,
  POSTSALES_DATE_TIME,
  reformatDateString,
} from '@mmt/legacy-commons/Helpers/dateTimehelpers';
import { Lobs, Constant, BookingStates, MyTripsCardsVisibility } from '../summaryConstants';
import { isNonEmpty } from '@mmt/legacy-commons/Common/utils/StringUtils';
import { isNil, isEmpty, flatten, compact } from 'lodash';
import { diffDays, getFormattedDateFromMillis } from '@mmt/legacy-commons/Helpers/dateHelpers';
import { getStaticData } from '../../staticData/staticData';
import { staticTextVarHandler } from '../../utils/PostSaleUtil';
import { isEmptyArray } from '../../Common/commonUtil';

export const SortTypes = {
  TRAVEL_DATE: Constant.TRAVEL_DATE,
  BOOKING_DATE: Constant.BOOKING_DATE,
};

export function filterData(bookingSectionData, selectedLobId, sortBy) {
  const bookings = bookingSectionData.map((section) => {
    if (selectedLobId) {
      return section.data.filter(({ lobType }) => lobType[0] === selectedLobId);
    }
    return section.data;
  });
  const bookingsFlattened = [].concat(...bookings);
  return groupBookingsByDate({ bookings: bookingsFlattened, sortBy });
}

export function groupBookingsByDate({ bookings, recentData, pageTitle, sortBy }) {
  const sections = {};
  if (pageTitle === BookingStates.UNSUCCESSFUL) {
    sections[`RECENT (${recentData.length})`] = recentData;
    if (bookings.length > 0) {
      sections[
        `OLDER (${
          bookings.filter(
            (val) =>
              ![
                Lobs.AdTechUpcomingCard,
                Lobs.AdTechCancelledCard,
                Lobs.AdTechCompletedCard,
                Lobs.AdTechFailedCard,
              ].includes(val.lobType[0]),
          ).length
        })`
      ] = [];
    }
  }
  bookings.forEach((booking) => {
    if (
      booking.lobType &&
      [
        Lobs.AdTechUpcomingCard,
        Lobs.AdTechCancelledCard,
        Lobs.AdTechCompletedCard,
        Lobs.AdTechFailedCard,
      ].includes(booking.lobType[0])
    ) {
      sections.AdTechSection = [booking];
    } else if (
      booking.lobType &&
      [Lobs.Planning].includes(booking.lobType[0]) &&
      booking.status == ''
    ) {
      if (!sections['Closed Queries']) {
        sections['Closed Queries'] = [booking];
      } else {
        sections['Closed Queries'].push(booking);
      }
    } else {
      let dateYear;
      dateYear = reformatDateString(booking.maxArrDate, POSTSALES_DATE_TIME, MONTH_QUOTE_HALF_YEAR);
      if (sections[dateYear]) {
        sections[dateYear].push(booking);
      } else {
        sections[dateYear] = [booking];
      }
    }
  });
  return Object.keys(sections).map((key) => {
    const value = sections[key];
    return {
      title: key,
      data: value,
      pageTitle,
    };
  });
}

function hasBookings(allBookings, lostBookings) {
  if (allBookings && allBookings.bookingDetails && allBookings.bookingDetails.length > 0) {
    return true;
  }
  if (lostBookings && lostBookings.length > 0) {
    return true;
  }
  return false;
}

function modifyLostBookings(bookings) {
  function getDateFromSession(sessionTime) {
    const dateTime = getFormattedDateFromMillis(sessionTime, 'YYYY-MM-DD HH:MM:ss');
    return dateTime.split(' ').join('T');
  }

  return bookings.map((item) => {
    const maxArrDate =
      item && !item.maxArrDate && item.sessionTime
        ? getDateFromSession(item.sessionTime)
        : item.maxArrDate;
    return {
      ...item,
      status: item.isPending ? BookingStates.PENDING : BookingStates.FAILED,
      bookingID: item.bookingId,
      isLostBooking: true,
      lobType: [item.lobname.toUpperCase()],
      maxArrDate,
    };
  });
}

export function getBookingStatusSections(allBookings, lostIdResponse) {
  const { lostBookingList, recentLostIdDurationInDays } = lostIdResponse || {};
  if (!hasBookings(allBookings, lostBookingList)) {
    return [];
  }
  const statusSection = getStatusWiseBookingMap(allBookings);
  if (statusSection && statusSection[BookingStates.UNSUCCESSFUL]) {
    statusSection[BookingStates.UNSUCCESSFUL].forEach((item) => {
      if (item.lostBooking) {
        item.isLostBooking = true;
        item.isPushedAsLostId = true;
        item.maxArrDate = item && !item.maxArrDate ? item.bookingDateTime : item.maxArrDate;
      }
    });
  }
  if (lostBookingList && lostBookingList.length) {
    if (statusSection && statusSection[BookingStates.UNSUCCESSFUL]) {
      statusSection[BookingStates.UNSUCCESSFUL] = statusSection[BookingStates.UNSUCCESSFUL].concat(
        modifyLostBookings(lostBookingList),
      );
    } else {
      statusSection[BookingStates.UNSUCCESSFUL] = modifyLostBookings(lostBookingList);
    }
  }
  if (!allBookings.displayUnsuccessfulTab && statusSection[BookingStates.UNSUCCESSFUL]) {
    delete statusSection[BookingStates.UNSUCCESSFUL];
  }
  const bookingPages = Object.keys(statusSection).map((key) => {
    let bkgdata = statusSection[key];
    let recentLostBookings = [];
    if (key === BookingStates.UNSUCCESSFUL && bkgdata.length >= 1) {
      bkgdata.sort((a, b) => {
        const t1 = new Date(a.maxArrDate).getTime() || 0;
        const t2 = new Date(b.maxArrDate).getTime() || 0;
        return t2 - t1;
      });
      for (let i = 0; i < bkgdata.length; i++) {
        const today = new Date();
        const d = new Date(
          bkgdata[i].isLostBooking ? bkgdata[i].maxArrDate : bkgdata[i].bookingDateTime,
        );
        if (diffDays(today, d) <= recentLostIdDurationInDays) {
          const { refundDetails } = bkgdata[i] || {};
          const { psrRequests } = refundDetails || {};
          const { expectedCreditDate } = psrRequests && psrRequests.length ? psrRequests[0] : {};
          const expectedCredit = expectedCreditDate ? new Date(expectedCreditDate) : null;
          if (expectedCredit && diffDays(expectedCredit, today) < 0) {
            continue;
          }
          bkgdata[i].isRecentLostBooking = true;
          recentLostBookings.push(bkgdata[i]);
          bkgdata.splice(i, 1);
          i--;
        }
      }
    }
    return {
      pageTitle: key,
      data: groupBookingsByDate({
        bookings: bkgdata,
        recentData: recentLostBookings,
        pageTitle: key,
      }),
      recentCount: recentLostBookings.length,
    };
  });
  return [...bookingPages];
}

export function getBookingStatusSectionsV4(allBookings, lostIdResponse) {
  const { lostBookingList, recentLostIdDurationInDays } = lostIdResponse || {};
  if (!hasBookings(allBookings, lostBookingList)) {
    return [];
  }
  const statusSection = getStatusWiseBookingMap(allBookings);
  if (statusSection && statusSection[BookingStates.UNSUCCESSFUL]) {
    statusSection[BookingStates.UNSUCCESSFUL].forEach((item) => {
      if (item.lostBooking) {
        item.isLostBooking = true;
        item.isPushedAsLostId = true;
        item.maxArrDate = item && !item.maxArrDate ? item.bookingDateTime : item.maxArrDate;
      }
    });
  }
  if (lostBookingList && lostBookingList.length) {
    if (statusSection && statusSection[BookingStates.UNSUCCESSFUL]) {
      statusSection[BookingStates.UNSUCCESSFUL] = statusSection[BookingStates.UNSUCCESSFUL].concat(
        modifyLostBookings(lostBookingList),
      );
    } else {
      statusSection[BookingStates.UNSUCCESSFUL] = modifyLostBookings(lostBookingList);
    }
  }
  if (!allBookings.displayUnsuccessfulTab && statusSection[BookingStates.UNSUCCESSFUL]) {
    delete statusSection[BookingStates.UNSUCCESSFUL];
  }
  const bookingPages = Object.keys(statusSection).map((key) => {
    let bkgdata = statusSection[key];
    let recentLostBookings = [];
    if (key === BookingStates.UNSUCCESSFUL && bkgdata.length >= 1) {
      bkgdata.sort((a, b) => {
        const t1 = new Date(a.maxArrDate).getTime() || 0;
        const t2 = new Date(b.maxArrDate).getTime() || 0;
        return t2 - t1;
      });
      for (let i = 0; i < bkgdata.length; i++) {
        const today = new Date();
        const d = new Date(
          bkgdata[i].isLostBooking ? bkgdata[i].maxArrDate : bkgdata[i].bookingDateTime,
        );
        if (diffDays(today, d) <= recentLostIdDurationInDays) {
          const { refundDetails } = bkgdata[i] || {};
          const { psrRequests } = refundDetails || {};
          const { expectedCreditDate } = psrRequests && psrRequests.length ? psrRequests[0] : {};
          const expectedCredit = expectedCreditDate ? new Date(expectedCreditDate) : null;
          if (expectedCredit && diffDays(expectedCredit, today) < 0) {
            continue;
          }
          bkgdata[i].isRecentLostBooking = true;
          recentLostBookings.push(bkgdata[i]);
          bkgdata.splice(i, 1);
          i--;
        }
      }
    }
    return {
      pageTitle: key,
      data: groupBookingsByDate({
        bookings: bkgdata,
        recentData: recentLostBookings,
        pageTitle: key,
      }),
      recentCount: recentLostBookings.length,
    };
  });
  return [...bookingPages];
}

export function countBookings(allBookings) {
  return getStatusWiseBookingMap(allBookings);
}

export function prepareBookingsData(sumaryResponse, bookings, status, lostIdResponse) {
  if (
    !sumaryResponse ||
    !sumaryResponse.newBookingDetails ||
    sumaryResponse.newBookingDetails.length === 0
  ) {
    return {
      updatedBookings: [...bookings],
      hasNextPage: false,
      updatedStatusWiseMessage: sumaryResponse.statusWiseMessage,
      persuasion: sumaryResponse.persuasion
    };
  }
  const { newBookingDetails, persuasion} = sumaryResponse;
  let updatedBookings = [...bookings];
  const currentPageData = newBookingDetails.filter(statusData => statusData.status === status)[0];
  let currentPageNewBookings = currentPageData ? currentPageData.bookingDetails : [];
  if (status && status === BookingStates.FAILED) {
    const failedCancelledBookings = newBookingDetails.filter(
      (statusData) => statusData.status === BookingStates.CANCELLED,
    )[0];
    currentPageNewBookings = currentPageNewBookings.concat(
      failedCancelledBookings && failedCancelledBookings.bookingDetails
        ? failedCancelledBookings.bookingDetails
        : [],
    );
  }
  if(status && status === BookingStates.PENDING) {
    currentPageNewBookings.sort(sortPendingBookingsByPaymentChargeDate);
  }
  const hasNextPage = currentPageData ? currentPageData.hasNext : false;
  const updatedStatusWiseMessage = currentPageData
    ? { [status.toLowerCase()]: currentPageData.statusMessage }
    : null;
  updatedBookings.push(...currentPageNewBookings);

  if (status === BookingStates.FAILED && isEmpty(bookings)) {
    let recentCount = null;
    const groupUpdatedBookings = getBookingStatusSectionsV4(
      {
        bookingDetails: currentPageNewBookings,
        displayUnsuccessfulTab: sumaryResponse.displayUnsuccessfulTab,
      },
      lostIdResponse,
    );
    updatedBookings = !isEmpty(groupUpdatedBookings) ? groupUpdatedBookings[0].data : [];
    recentCount = !isEmpty(groupUpdatedBookings) ? groupUpdatedBookings[0].recentCount : null;
    return { updatedBookings, hasNextPage, updatedStatusWiseMessage, recentCount: recentCount, persuasion };
  }
  return { updatedBookings, hasNextPage, updatedStatusWiseMessage, persuasion };
}

export const modifiedBookingListResponse = (bookings, pageName) => {
  if (pageName !== BookingStates.UNSUCCESSFUL) return groupBookingsByDate({ bookings: bookings });
  return bookings;
};

function addAdTechCards(statusSection) {
  Object.keys(statusSection).forEach((key) => {
    switch (key) {
      case BookingStates.UPCOMING:
        statusSection[key].push({
          lobType: [Lobs.AdTechUpcomingCard],
          bookingID: Constant.DUMMY_ADTECH_ID,
        });
        break;
      case BookingStates.CANCELLED:
        statusSection[key].push({
          lobType: [Lobs.AdTechCancelledCard],
          bookingID: Constant.DUMMY_ADTECH_ID,
        });
        break;
      case BookingStates.COMPLETED:
        statusSection[key].push({
          lobType: [Lobs.AdTechCompletedCard],
          bookingID: Constant.DUMMY_ADTECH_ID,
        });
        break;
      case BookingStates.UNSUCCESSFUL:
        statusSection[key].push({
          lobType: [Lobs.AdTechFailedCard],
          bookingID: Constant.DUMMY_ADTECH_ID,
        });
        break;
      default:
        break;
    }
  });
}

export const showEmptyScreenActiveTab = (page) => {
  if (!isNil(page.pageTitle) && !isNil(page.data)) {
    const filteredData = page.data.filter(
      (item) => item.title !== 'AdTechSection',
    );
    return filteredData.length === 0;
  }
};

export const showEmptyScreenActiveTabV4 = (page) => {
  return page.length === 0;
};

function getStatusWiseBookingMap(allBookings) {
  if (!allBookings || !allBookings.bookingDetails || allBookings.bookingDetails.length === 0) {
    return {};
  }
  //Insert Vaccine Card in Upcoming section
  const { bookingDetails, showAdTechCard } = allBookings;
  const statusSection = {};

  bookingDetails.forEach((booking) => {
    if (booking.failedBooking && booking.status === BookingStates.CANCELLED) {
      booking.status = BookingStates.FAILED;
    }
    const status = booking.status;
    if (statusSection[status]) {
      statusSection[status].push(booking);
    } else {
      statusSection[status] = [booking];
    }
  });

  if (statusSection[BookingStates.FAILED]) {
    statusSection[BookingStates.UNSUCCESSFUL] = statusSection[BookingStates.FAILED];
    delete statusSection[BookingStates.FAILED];
  }

  if (showAdTechCard) {
    addAdTechCards(statusSection);
  }
  return statusSection;
}

export function getDateTimeWithPaxInfo(
  { refundDetails: { psrRequests } = {} } = {},
  staticText,
  dateTimeWithPaxInfo,
  summary,
) {
  if (isNonEmpty(psrRequests)) {
    const cancelledOnText = psrRequests[0].cancelledOnText;
    if (summary) {
      return `${cancelledOnText} • ${summary}`;
    } else {
      return cancelledOnText;
    }
  } else if (!dateTimeWithPaxInfo && !staticText) {
    return null;
  }
  return `${staticText} ${dateTimeWithPaxInfo}`;
}

export const swapBookingStateNames = (defaultName) =>
  defaultName === BookingStates.UNSUCCESSFUL ? BookingStates.FAILED : defaultName;

export const getStatusWiseMessage = (statusWiseMessage, pageName) => {
  const {
    statusUpcomingHeadingText,
    statusCompletedHeadingText,
    statusPendingHeadingText,
    statusCancelledHeadingText,
    statusFailedHeadingText,
    statusUpcomingMainText,
    statusPendingMainText,
    statusCompletedMainText,
    statusCancelledMainText,
    statusFailedMainText
  } = getStaticData()
  const statusWiseLocalMessage = {
    [BookingStates.UPCOMING]: {
      "heading": statusUpcomingHeadingText,
      "text": statusUpcomingMainText
    },
    [BookingStates.PENDING]: {
      "heading": statusPendingHeadingText,
      "text": statusPendingMainText
    },
    [BookingStates.COMPLETED]: {
      "heading": statusCompletedHeadingText,
			"text": statusCompletedMainText
    },
    [BookingStates.CANCELLED]: {
      "heading": statusCancelledHeadingText,
			"text": statusCancelledMainText
    },
    [BookingStates.FAILED]: {
      "heading": statusFailedHeadingText,
			"text": statusFailedMainText
    },
    [BookingStates.UNSUCCESSFUL]: {
      "heading": statusFailedHeadingText,
			"text": statusFailedMainText
    }
  }
  if(pageName && statusWiseMessage && statusWiseMessage[pageName.toLowerCase()]) {
    return statusWiseMessage[pageName.toLowerCase()];
  }
  return statusWiseLocalMessage[pageName];
};

export const unsuccessfulBookingTextHandler = (value) => {
  const { bookingUnsuccessfulOnText } = getStaticData();
  return staticTextVarHandler(bookingUnsuccessfulOnText, { date: value });
};
const mapLocusIdDetails = (details) => {
  if (details.length > 0) {
    return details.map((d) => d.locusIdDetails);
  }
  return [];
};

const getLocusIdDetails = (booking) => {
  return compact(
    flatten([
      ...mapLocusIdDetails(booking.hotelDetails),
      ...mapLocusIdDetails(booking.flightSegment),
      ...mapLocusIdDetails(booking.coachDetails),
      ...mapLocusIdDetails(booking.carDetails),
      ...mapLocusIdDetails(booking.acmeDetails),
      ...mapLocusIdDetails(booking.railDetails),
    ]),
  );
};
const mapTripIdeasData = (bookings) =>
  bookings.map((booking) => ({
    lob: booking.lobType[0],
    uuid: booking.uuid,
    locusIdDetails: getLocusIdDetails(booking),
    minDepartureDate: booking.minDeptDate,
    maxArrivalDate: booking.maxArrDate,
  }));

export const prepareTripIdeasData = (bookings) => {
  if (isEmpty(bookings) || isEmpty(bookings.bookingDetails)) {
    return [];
  }
  return mapTripIdeasData(
    bookings.bookingDetails
      .filter((booking) => booking.status === BookingStates.UPCOMING)
      .slice(0, 10),
  );
};
export const prepareTripIdeasDataV4 = (bookings) => {
  if (
    isEmpty(bookings) ||
    isEmpty(bookings.newBookingDetails) ||
    isEmpty(bookings.newBookingDetails[0].bookingDetails)
  ) {
    return [];
  }
  return mapTripIdeasData(
    bookings.newBookingDetails[0].bookingDetails
      .filter((booking) => booking.status === BookingStates.UPCOMING)
      .slice(0, 10),
  );
};
function mapPlanningCards(cards, key) {
  return cards[key].map((planning) => {
    planning.lobType = [Lobs.Planning];
    planning.bookingID = planning.ticketId;
    planning.status = key === 'In Progress' ? key : '';
    planning.maxArrDate = planning.updateAtDate;
    return planning;
  });
}

export function getPagesWithPlanningData(pages, plannings) {
  if (
    plannings &&
    plannings.cards &&
    Object.keys(plannings.cards).length > 0 &&
    plannings.cards['Closed Queries'].length == 0 &&
    plannings.cards['In Progress'].length == 0
  ) {
    return pages;
  }
  pages.unshift({
    pageTitle: Lobs.Planning,
    data: [
      {
        data: mapPlanningCards(plannings.cards, 'In Progress'),
        pageTitle: Lobs.Planning,
        title: '',
      },
      plannings.cards['Closed Queries'] && {
        data: mapPlanningCards(plannings.cards, 'Closed Queries'),
        pageTitle: Lobs.Planning,
        title: Object.keys(plannings.cards)[1],
      },
    ],
  });
  return pages;
}

export const preparePlanningData = (plannings) => {
  return {
    bookings: [
      ...mapPlanningCards(plannings.cards, 'In Progress'),
      ...mapPlanningCards(plannings.cards, 'Closed Queries'),
    ],
  };
}

export const getMyTripsCardsInfo = (cardData) => {
  let stickyCards = [];
  let nonStickyCards = [];

  const initialTripsCardCount = {
    emptyListingCount: 0,
    nonEmptyListingCount: 0,
  };
  const NON_STICKYCARD_UPCOMING = { ...initialTripsCardCount };
  const NON_STICKYCARD_PLANNING = { ...initialTripsCardCount };
  const NON_STICKYCARD_CANCELLED = { ...initialTripsCardCount };
  const NON_STICKYCARD_COMPLETED = { ...initialTripsCardCount };
  const NON_STICKYCARD_UNSUCCESSFUL = { ...initialTripsCardCount };
  const NON_STICKYCARD_PENDING = { ...initialTripsCardCount };

  let nonStickyCardCount = {
    NON_STICKYCARD_UPCOMING,
    NON_STICKYCARD_PLANNING,
    NON_STICKYCARD_CANCELLED,
    NON_STICKYCARD_COMPLETED,
    NON_STICKYCARD_UNSUCCESSFUL,
    NON_STICKYCARD_PENDING,
  };

  !!cardData &&
    cardData.forEach((data) => {
      const { isSticky } = data;
      if (isSticky) stickyCards = [...stickyCards, data];
      else {
        nonStickyCards = [...nonStickyCards, data];
        nonStickyCardCount = nonStickyCardsCountPerTab(data, nonStickyCardCount);
      }
    });

  return [stickyCards, nonStickyCards, nonStickyCardCount];
};

// Sorting Pending Bookings by Payment Charge Date in Ascending Order
function sortPendingBookingsByPaymentChargeDate(pendingBooking1, pendingBooking2) {
  return new Date(pendingBooking1.paymentInfo.paymentChargeDate).getTime() - new Date(pendingBooking2.paymentInfo.paymentChargeDate).getTime();
}

const nonStickyCardsCountPerTab = (cardInfo, nonStickyCardCount) => {
  const { visibleTabs } = cardInfo;
  if (visibleTabs) {
    for (let tab in visibleTabs) {
      const val = visibleTabs[tab];
      const cardState = `NON_STICKYCARD_${tab}`;
      /*if val = 2, the card will be added when there is no booking as well as booking.
      so card count for both empty and non empty listing is increased*/
      if (val === MyTripsCardsVisibility.ALWAYS) {
        nonStickyCardCount = {
          ...nonStickyCardCount,
          [cardState]: {
            emptyListingCount: nonStickyCardCount[cardState].emptyListingCount + 1,
            nonEmptyListingCount: nonStickyCardCount[cardState].nonEmptyListingCount + 1,
          },
        }
      }
      // card is displayed only for nonEmptyListing when val = 1
      else if (val === MyTripsCardsVisibility.WITH_BOOKING) {
        nonStickyCardCount = {
          ...nonStickyCardCount,
          [cardState]: {
            emptyListingCount: nonStickyCardCount[cardState].emptyListingCount,
            nonEmptyListingCount: nonStickyCardCount[cardState].nonEmptyListingCount + 1,
          },
        }
      }
      // card is displayed only for empty listing i.e when there are no bookings
      else {
        nonStickyCardCount = {
          ...nonStickyCardCount,
          [cardState]: {
            nonEmptyListingCount: nonStickyCardCount[cardState].nonEmptyListingCount,
            emptyListingCount: nonStickyCardCount[cardState].emptyListingCount + 1,
          },
        }
      }
    }
  }
  return nonStickyCardCount;
}
