import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import getStyles from './styles';
import { INFO_ICON, RIGHT_ARROW_WHITE_ICON  } from '../../flights/FlightImageConstants';
import BaseButton, { BUTTON_VARIANT } from '../BaseButton';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { useTheme } from '../../theme/theme.context';
import { getFont } from '../../PostSalesStyles';

const DarkFooter = (props) => {
  const { psTheme: theme } = useTheme();
  const fonts = getFont();
  const styles = getStyles(theme, fonts);
  return (
    <View style={styles.DarkFooter}>
      <View style={{ marginLeft:props.marginLeft || 15  }}>
        <View style={styles.flexRow}>
          {props.price && (<Text style={styles.FooterTotalPrice}>{props.price} </Text>)}
          {props.footerInfoIcon && (
            <TouchableOpacity onPress={props.infoIconClickHandler}>
              <Image style={styles.infoIcon} source={INFO_ICON} />
            </TouchableOpacity>
          )}
        </View>
        <Text style={styles.FooterpriceDtls}>{props.priceDtls}</Text>
      </View>
      <View style={styles.verticalSeparator} />
      <BaseButton
        variant={BUTTON_VARIANT.CAPSULE}
        clickHandler={props.clickHandler}
        text={props.buttonLabel}
        textStyle={props.textStyle || AtomicCss.font16}
        buttonStyle={props.btnStyle || styles.buttonStyle}
        shadow
        numberOfLines={props.numberOfLines}
      >
        <Image style={styles.wtRightArrow} source={RIGHT_ARROW_WHITE_ICON} />
      </BaseButton>
    </View>
  );
};
DarkFooter.defaultProps = {
  buttonLabel: 'PAY NOW',
  isCorporateBooking: false,
};
DarkFooter.propTypes = {
  price: PropTypes.string.isRequired,
  priceDtls: PropTypes.string.isRequired,
  footerInfoIcon: PropTypes.bool.isRequired,
  clickHandler: PropTypes.func.isRequired,
  infoIconClickHandler: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string,
  isCorporateBooking: PropTypes.bool,
};

export default DarkFooter;
