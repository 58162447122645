const styles = {
  cardWrapper: {
    paddingBottom: 12,
    marginHorizontal: 12,
  },
  imageGalleryScroll: {
    flexDirection: 'row',
    left: 12,
  },
  gallerySec: {
    marginRight: 12,
    flexDirection: 'row',
  },
  hotelImg: {
    width: 159,
    height: 152,
    marginRight: 10,
    borderRadius: 10,
  },
  tabWrapper: {
    paddingHorizontal: 12,
  },
  tab: {
    paddingHorizontal: 12,
    paddingVertical: 6,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#1a7971',
    marginRight: 12,
  },
  activeTab: {
    paddingHorizontal: 12,
    paddingVertical: 6,
    borderRadius: 5,
    backgroundColor: '#1a7971',
    borderWidth: 1,
    borderColor: '#1a7971',
  },
  dividor: {
    borderBottomWidth: 1,
    borderColor: '#d4daea',
    marginHorizontal: 12,
  },
  facilityHeading: {
    paddingHorizontal: 12,
    flexDirection: 'row',
  },
  facilityList: {
    paddingHorizontal: 12,
  },
  listWidth: {
    marginRight: 18,
    paddingRight: 18,
    maxWidth: 142,
    minWidth: 142,
  },
  greyTick: {
    width: 16,
    height: 16,
    marginRight: 10,
  },
  timerIcon: {
    width: 20,
    height: 20,
    marginRight: 10,
  },
  allowTextWrapper: {
    paddingHorizontal: 12,
    flexDirection: 'row',
    alignItems: 'center',
  },
  parkingWrapper: {
    paddingHorizontal: 12,
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  parkingIcon: {
    width: 20,
    height: 20,
    marginRight: 10,
  },
  blueBtn: {
    backgroundColor: '#008cff',
    borderRadius: 8,
    padding: 8,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '48%',
  },
  liteBlueBtn: {
    backgroundColor: '#eaf5ff',
    borderRadius: 8,
    padding: 8,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '48%',
  },
  btnWrapper: {
    paddingHorizontal: 12,
    marginBottom: 4,
  },
  callUsIcon: {
    width: 24,
    height: 24,
    marginRight: 10,
    tintColor: '#fff',
  },
  downloadIcon: {
    width: 24,
    height: 24,
    marginRight: 10,
  },
  ValueStaysImg: {
    height: 24,
    width: 124,
  },
  discountingCampaingnImg: {
    height: 22,
    width: 114,
  },
  mySafetyImg: {
    height: 21,
    width: 190,
  },
  luxImg: {
    height: 21,
    width: 70,
  },
};
export default styles;
