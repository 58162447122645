import React, { useCallback } from 'react';
import { View, Text, Image } from 'react-native';
import isEmpty from 'lodash/isEmpty';
import { ONE_WAY_JRNY_ICON } from '../../../../FlightImageConstants';
import createStyles from './styles';
import { imageTransformY } from '../../../../../utils/PostSaleUtil';
import { getStaticData } from '../../../../../staticData/staticData';
import { getFont } from '../../../../../PostSalesStyles';
import { getFlightName, getSegmentInfo } from '../../utils';
import { useTheme } from '../../../../../theme/theme.context';

export const SegmentDetailsView = React.memo(
  ({ segment, destinationCity, stopovers, showStaticContentInEnglish }) => {
    const fonts = getFont(true);
    const { psTheme: theme } = useTheme();
    const styles = createStyles(theme, fonts);
    const getStopOver = useCallback((stopover) => {
      const stopoversList = [];
      if (!isEmpty(stopover)) {
        stopover.forEach((stop) => {
          stopoversList.push(
            <Text style={[styles.cityText, styles.fontRegular, fonts.regularFontFamily]}>
              {stop}
            </Text>,
          );
        });
      }
      return stopoversList;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let {
      flightAdditionalText: { flightWithStopText },
      journeyConnectingText,
    } = getStaticData();
    if (showStaticContentInEnglish) {
      flightWithStopText = journeyConnectingText;
    }
    return (
      <View key={segment.segmentLineNo}>
        <View style={[styles.destRow]}>
          {!isEmpty(segment.originCity) && <Text style={[styles.cityText, fonts.boldFontFamily]}>{segment.originCity}</Text>}
          <Image style={[styles.arrowIcon, imageTransformY]} source={ONE_WAY_JRNY_ICON} />
          {getStopOver(stopovers)}
          {!isEmpty(stopovers) && <Image style={styles.arrowIcon} source={ONE_WAY_JRNY_ICON} />}
          <Text style={[styles.cityText, fonts.boldFontFamily]}>{destinationCity}</Text>
          <View style={styles.airlinesIconWrapper}>
            <Image style={styles.airlineIcon} source={{ uri: segment.airlineLogoUrl }} />
          </View>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <Text style={[styles.smallText, fonts.semiBoldFontFamily]}>
            {segment.flightTravelDateV2}
          </Text>
          <Text style={[styles.smallText, fonts.semiBoldFontFamily]}>, </Text>
          <Text style={[styles.smallText, fonts.semiBoldFontFamily]}>{getFlightName(segment)}</Text>
        </View>
        {!isEmpty(stopovers) && (
          <Text style={[styles.fltInfo, fonts.regularFontFamily]}>{flightWithStopText}</Text>
        )}
      </View>
    );
  },
);

export const addSegmentInfo = (segmentGroup, showStaticContentInEnglish = false) => {
  const {showMultiple, seg, destinationCity, stopovers } = getSegmentInfo(segmentGroup);
  if(!showMultiple){
    return (
      <SegmentDetailsView
        segment={seg}
        destinationCity={destinationCity}
        stopovers={stopovers}
        showStaticContentInEnglish={showStaticContentInEnglish}
      />
    );
  }

  return segmentGroup && !isEmpty(segmentGroup.segmentDetails) && segmentGroup.segmentDetails.map((segment) => (
    <SegmentDetailsView
      segment={segment}
      key={segment.segmentLineNo}
      destinationCity={segment.destinationCity}
      stopovers={null}
      showStaticContentInEnglish={false}
    />
  ));
};

export default SegmentDetailsView;
