import {colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles';

const styles = {
  pageWrapper: {
    flex: 2,
    backgroundColor: colors.white,
    justifyContent: 'space-between',
    paddingTop: 88,
    paddingBottom: 50
  },
  footerBtnWrapper: {
    padding: 50
  },
  messageContent: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: 50
  },
  messageIcon: {
    width: 80,
    height: 80,
    marginBottom: 8
  },
  messageTitle: {
    fontSize: 20,
    fontFamily: fonts.bold,
    color: colors.black28,
    textAlign: 'center'
  },
  extraMessage: {
    fontSize: 16,
    fontFamily: fonts.bold,
    color: colors.defaultTextColor,
    marginBottom: 24,
    textAlign: 'center'
  },
  messageDesc: {
    fontSize: 14,
    fontFamily: fonts.regular,
    color: colors.gray,
    textAlign: 'center'
  },
  boldMessageDesc: {
    fontSize: 14,
    fontFamily: fonts.bold,
    color: colors.defaultTextColor,
    textAlign: 'center'
  }
};

export default styles;

