import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import {Text, TouchableOpacity, View} from 'react-native';
import getStyles from '../components/HotelMamiCss';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';


const CapsuleBtn = ({action, clickHandler, isThrottleRequired= false, throttleInterval = 1000}) => {
  const { ...fonts } = getFont(true);
  const styles = getStyles(fonts);
  const lastClickTimeRef = useRef(0);

  const throttledClickHandler = useCallback(() => {
    const currentTime = Date.now();
    if (currentTime - lastClickTimeRef.current >= throttleInterval) {
      lastClickTimeRef.current = currentTime;
      clickHandler(action);
    }
  }, [clickHandler, throttleInterval]);

  const wrapperThrottle = () => {
    if (isThrottleRequired) {
      throttledClickHandler();
    } else {
      clickHandler(action);
    }
  }
  
return (
  <TouchableOpacity style={styles.CapsuleBtnFillOuter} onPress={wrapperThrottle}>
    <View
      style={[styles.CapsuleBtn, styles.mar10Bt]}
    >
      <Text
        style={[styles.BlueText, styles.CenterText]}
      >{action.actionLabeltext}
      </Text>
    </View>
  </TouchableOpacity>
)};

CapsuleBtn.propTypes = {
  action: PropTypes.object.isRequired,
  clickHandler: PropTypes.func.isRequired
};

export default CapsuleBtn;
