import {Platform} from 'react-native';

const styles = {
  container: {
    width: '100%',
    flexDirection: 'column',
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    paddingHorizontal: '5%',
    ...Platform.select({
      ios: {
        marginBottom: 21
      },
      android: {
        marginBottom: 21
      },
      web: {
       marginTop: 16
      }
    })
  },
  label: {
    fontSize: 14,
    fontFamily: 'Lato-Regular',
    color: '#747474',
    position: 'absolute',
    left: '5%',
    top: 0,
    ...Platform.select({
      web: {
        zIndex: -1
      }
    })
  },
  input: {

    width: '100%',
    fontSize: 14,
    fontFamily: 'Lato-Bold',
    color: '#4a4a4a',


    ...Platform.select({
      ios: {
        height: 35,
        paddingTop: 7
      },
      android: {
        height: 35
      },
      web: {
        height: 35,
        paddingTop: 7
      }
    })
  }
};

export default styles;
