import isEmpty from "lodash/isEmpty";
const getTopCardData = (response) => {
  const { cardList } = response || {};
  const { topCard } = cardList || {};
  if (isEmpty(topCard)){
    return {};
  }
  const {note, imageUrl,  buttons, heading, description, requestId} = topCard || {};
  return {
    heading: heading,
    imageUrl: imageUrl,
    description: description,
    descriptionKey: requestId && requestId.key,
    bookingId: requestId && requestId.value,
    buttons: buttons,
    note: note,
  };
};

const getVisaRequestDetailsCardData = (response) => {
  const { cardList } = response || {};
  const { visaRequestDetailsCard } = cardList || {};
  if (isEmpty(visaRequestDetailsCard)){
    return {};
  }
  const { primaryDetails} = visaRequestDetailsCard || {};
  return {
    primaryDetails: primaryDetails,
  };
};

const getContactusCardData = (response) => {
  const { cardList } = response || {};
  const { contactUsCard } = cardList || {};
  if (isEmpty(contactUsCard)){
    return {};
  }
  const {heading, description, phoneNo, email} = contactUsCard;
  const contactInformationList = [];
  contactInformationList.push(phoneNo, email);
  return {
    heading: heading,
    description: description,
    contactInformationList,
  };
};

const getThankYouVisaPageData = (response) => {
  return {
    topCardData: getTopCardData(response),
    visaRequestDetailsCardData: getVisaRequestDetailsCardData(response),
    contactUsCardData: getContactusCardData(response),
    response: response,
  };
};
export default getThankYouVisaPageData;
