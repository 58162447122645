import React, {Fragment} from 'react';
import {View, Image, Text} from 'react-native';
import PropTypes from 'prop-types';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import Header from './Header';
import Button from '../../../Common/Button';
import {GREEN_BTN_BG} from '../../../PostSalesConstant';
import BasePage from '../../../Common/PostSalesBasePage';
import CabBookingDetailsConstant from '../../CabBookingDetailsConstant';
import {styles} from './CabAmendmentStyles';
import {notAvailable} from '../../CabImageConstants';

class CabAmendFullScreenPage extends BasePage {
  constructor(props) {
    super(props, CabBookingDetailsConstant.CAB_AMEND_ERROR_SCREENS);
  }
  render() {
    return (
      <Fragment>
        <Header title={this.props.headerText} handleBack={this.props.headerBackButton} fromCity={this.props.fromCity} toCity={this.props.toCity} />
        <View style={styles.errorWrapper}>
          <View style={styles.ErrorSection}>
            <Image style={styles.errorIcon} source={this.props.sourceIcon} />
            <Text style={[AtomicCss.blackFont, AtomicCss.blackText, AtomicCss.font20, AtomicCss.marginBottom20, AtomicCss.textCenter]}>{this.props.errorText}</Text>
            <Text style={[AtomicCss.font14, AtomicCss.greyText, AtomicCss.textCenter, AtomicCss.regularFont]}>{this.props.errorSubText}</Text>
          </View>
          <View style={styles.buttonWrpr}>
            <Button btnBg={GREEN_BTN_BG} btnTxt={this.props.btnText} clickHandler={this.props.btnClickHandler} />
          </View>
        </View>
      </Fragment>
    );
  }
}

CabAmendFullScreenPage.defaultProps = {
  errorText: CabBookingDetailsConstant.DEFAULT_ERROR_MESSAGE,
  errorSubText: 'It looks like our servers took too long. Why don\'t you write to us and we will get back to you',
  sourceIcon: notAvailable
};

CabAmendFullScreenPage.propTypes = {
  errorText: PropTypes.string,
  errorSubText: PropTypes.string,
  headerText: PropTypes.string.isRequired,
  headerBackButton: PropTypes.func.isRequired,
  fromCity: PropTypes.string.isRequired,
  toCity: PropTypes.string.isRequired,
  btnText: PropTypes.string.isRequired,
  btnClickHandler: PropTypes.func.isRequired,
  sourceIcon: PropTypes.string
};
export default CabAmendFullScreenPage;
