import React from 'react';
import {View, Text} from 'react-native';

import styles from './wheelPickerCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import WheelPickerRow from './wheelPickerRow';
import * as CancelAndRebookUtill from "./CancelAndRebookUtill";

const firstColDummy = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
const secondColDummy = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const thirdColDummy = ["AM", "PM"];

class WheelPicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            valueGroups: CancelAndRebookUtill.getDateAsPickerString(this.props.date, props.bookingId),
            optionGroups: CancelAndRebookUtill.getPickerData(this.getCurrentDate(), this.getEndDate(), this.props.date, props.bookingId),
        }
    }

    getCurrentDate = () => {
        const resultDate = new Date();
        resultDate.setDate(resultDate.getDate());
        return  Date.parse(new Date(resultDate));
    }

    getEndDate = () => {
        const resultDate = new Date();
        resultDate.setDate(resultDate.getDate() + 90);
        return Date.parse(new Date(resultDate));
    }
     dateChange = (newDate) => {
         let curValGroup = this.state.valueGroups;
         curValGroup["date"] = newDate;
         this.props.onDateChange(curValGroup)
    }

    timeChange = (newTime) => {
        let curValGroup = this.state.valueGroups;
        curValGroup["time"] = newTime;
        this.props.onDateChange(curValGroup)
    }

    meridiemChange = (newMeridiem) => {
        let curValGroup = this.state.valueGroups;
        curValGroup["meridiem"] = newMeridiem;
        this.props.onDateChange(curValGroup)
    }

    activeSlideForDay = () => {
        const index = this.state.optionGroups["date"].findIndex(x => this.state.valueGroups["date"] && x === this.state.valueGroups["date"].trim());
        if(index === -1){
            return 0;
        }else{
            return index;
        }
    }

    activeSliderForTime = () => {
        const index = this.state.optionGroups["time"].findIndex(x => x === this.state.valueGroups["time"]);
        if(index === -1){
            return 0;
        }else{
            return index;
        }
    }

    activeSlideForMeridiem = () => {
        const index = this.state.optionGroups["meridiem"].findIndex(x => x ===this.state.valueGroups["meridiem"]);
        if(index === -1){
            return 0;
        }else{
            return index;
        }
    }

    render() {
    const {callback, title} = this.props;
    const firstColData = this.activeSlideForDay();
    const secondColData = this.activeSliderForTime();
    const thirdColData = this.activeSlideForMeridiem();
    return (
      <View>
        <View style={styles.wheelPickerWrapper}>
          <View style={styles.widgetTitle}>
            <Text style={[AtomicCss.font16, AtomicCss.lightText]}>{title}</Text>
          </View>
          <View style={[AtomicCss.flexRow, styles.wheelPickerBody]}>
            <WheelPickerRow
              list={this.state.optionGroups["date"]}
              onChange={this.dateChange}
              activeSlide={firstColData}
              value={this.state.valueGroups["date"]}
              callback={callback}
              type="firstCol"
              style={{
                paddingRight: 10,
                maxWidth: 200
              }}
            />
            <View style={styles.dashWrapper}>
              <Text style={styles.dashText}>-</Text>
            </View>
            <WheelPickerRow
              list={this.state.optionGroups["time"]}
              onChange={this.timeChange}
              activeSlide={secondColData}
              callback={callback}
              value={this.state.valueGroups["time"]}
              type="secondCol"
              style={{
                paddingRight: 20,
                paddingLeft: 10,
                maxWidth: 100
              }}
            />
            <WheelPickerRow
              list={this.state.optionGroups["meridiem"]}
              onChange={this.meridiemChange}
              activeSlide={thirdColData}
              callback={callback}
              type="thirdCol"
              value={this.state.valueGroups["meridiem"]}
              style={{
                paddingRight: 10,
                maxWidth: 70
              }}
            />
          </View>
        </View>
      </View>
    )
  }
}

export default WheelPicker;
