import React from 'react';
import Actions from '../../navigation/postSalesNavigation';
import PropTypes from 'prop-types';
import {Image, ScrollView, Text, View} from 'react-native';
import styles from '../styles/CabMamiCss';
import CommonHeader from '@mmt/legacy-commons/Common/Components/Header/CommonHeader';
import CabBookingTrackingHelper from '../CabBookingTrackingHelper';
import {commonheaderIcon, cancelIcon2} from "../CabImageConstants";

class CabInvoiceDetails extends React.Component {
  constructor(props) {
    super(props);
    this.invoice = this.props.response.productinclusion.invoiceDetails;
  }
  render() {
    return (
      <View style={[styles.whiteContainer, styles.transperantBgrnd]}>
        <CommonHeader
          headerText="Invoice"
          imgSrc={commonheaderIcon}
          backPressHandler={this.onBackPress}
        />
        <ScrollView>
          <View style={styles.PolicyHeading}>
            <Image style={styles.CrossIcon} source={cancelIcon2} />
            <Text style={[styles.BlackText, styles.blackFont, styles.PolicyHeadingText]}>INVOICE DETAILS</Text>
          </View>
          <View style={styles.PolicyDetailsContainer}>
            {this.showInvoice(this.invoice)}
          </View>
        </ScrollView>
      </View>
    );
  }
  componentDidMount() {
    CabBookingTrackingHelper.trackLoadEvent('Cab Details: CabInvoiceDetails', this.props.response);
  }

  showInvoice(invoice) {
    const views = [];
    invoice.forEach((invoiceObject) => {
      this.createInvoiceView(views, invoiceObject);
    });
    return views;
  }

  createInvoiceView(views, invoiceObject) {
    views.push(<View style={[styles.mar15Bt, styles.flexRow]} key={invoiceObject.lineNo}>
      <View style={styles.ItemDisc} />
      <Text>
        {invoiceObject.invoiceDetails}
      </Text>
               </View>);
  }
    onBackPress = () => {
      Actions.pop();
    }
}
CabInvoiceDetails.propTypes = {
  response: PropTypes.object.isRequired
};
export default CabInvoiceDetails;
