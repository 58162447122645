import React from 'react';
import { getInsurancesSummaryData } from './insuranceCardAdapter';
import {
  DescriptionSection,
  TitleText,
  MainContent,
  NewStatusText,
  RightArrow,
  SummaryContainer,
  StaticIcon,
  InsuranceInfoText,
} from '../cardElements';
import { styles } from './style';

function InsuranceCardSummary({ response }) {
  const cardData = getInsurancesSummaryData(response);

  return (
    <SummaryContainer bookingId={cardData.bookingID}>
      <MainContent
        onPress={() => {
          cardData.openDetails();
        }}
      >
        <StaticIcon icon={cardData.iconUrl} iconStyle={styles.staticIcon} />
        <DescriptionSection>
          <TitleText text={cardData.packageName} />
          {cardData.insuranceInfo && <InsuranceInfoText text={cardData.insuranceInfo} />}
          <NewStatusText status={cardData.status} text={cardData.dateTimeWithPaxInfo} />
        </DescriptionSection>
        <RightArrow />
      </MainContent>
    </SummaryContainer>
  );
}

export default InsuranceCardSummary;
