import Actions from '../../../../../navigation/postSalesNavigation';
import { Platform } from 'react-native';
import { getCommonHeaders } from '@mmt/legacy-commons/Helpers/genericHelper';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import FlightBookingTrackingHelper from '../../helper/FlightBookingTrackingHelper';
import { BACK_ARROW_BLACK_ICON } from '../../../../FlightImageConstants';
import { setWebViewAuth } from 'apps/post-sales/src/utils/PostSaleUtil';

const PLATFORM_SOURCE = {
  android: 'ANDROID',
  ios: 'IOS',
  web: 'PWA',
};

export const openWebView = async (msrUrl, heading) => {
  let url = `${msrUrl}&source=${PLATFORM_SOURCE[Platform.OS]}`;
  let appVersion = null;
  try {
    appVersion = await GenericModule.getAppVersion();
  } catch (e) {}
  if (appVersion) {
    url = `${url}&appVersion=${appVersion}`;
  }

  let headers = {};
  try {
    headers = await getCommonHeaders();
    delete headers['mmt-app-version-code'];
  } catch (e) {}
  headers.Cookie = `mmt-auth=${headers['mmt-auth']};`;
  Actions.openPsWebView({
    url,
    headerText: heading,
    headerIcon: BACK_ARROW_BLACK_ICON,
    headers,
    sharedCookiesEnabled: true,
    injectedJavaScript: setWebViewAuth(headers['mmt-auth']),
    whiteHeader: true,
  });
};

export const trackrRedirectToMsr = (ticket, cardRank, pageName) => {
  const linkName = ticket.actionBtnText.split(' ').join('');
  const l2Name = ticket.secondaryType.split(' ').join('');
  const issueText = ticket.statusText.split(' ').join('');
  const msrClickEvent = `MSR_clicked-link:${linkName}_issue:${issueText}_L2:${l2Name}_${cardRank}`;
  FlightBookingTrackingHelper.trackClickEvent(
    pageName,
    msrClickEvent,
    null,
    ticket.entityKey,
    ticket.id,
  );
};
