import React from 'react';
import PropTypes from 'prop-types';
import LinearGradient from 'react-native-linear-gradient';
import {Image, Text, TouchableOpacity, View} from 'react-native';
import styles from '../AcmeBookingMainCss';
import {isNotNullAndEmpty} from '../../../hotel/details/utils/HotelBookingDetailUtil';
import {getActionButton, handleClickEvent} from '../../utils/AcmeBookingDetailUtil';
import {backIcon} from '../../utils/AcmeImageConstant';


class TopStatusCard extends React.Component {
  render() {
    return (
      <LinearGradient
        start={{
          x: 1.0,
          y: 0.0
        }}
        end={{
          x: 0.0,
          y: 1.0
        }}
        colors={this.props.color}
        style={[styles.topStatusCard]}
      >
        <View style={styles.topStatusBar}>
          <TouchableOpacity style={{padding: 16}} onPress={this.props.onBackPress}>
            <Image
              style={styles.backarrow}
              source={backIcon}
            />
          </TouchableOpacity>
          <Image
            style={[styles.mar3Bt, styles.topStatusIcon]}
            source={this.props.iconPath}
          />
          <TouchableOpacity style={{padding: 16}} />
        </View>
        <View style={[styles.StatusCardWrapper, styles.transperantBgrnd]}>
          <Text
            style={[styles.WhiteText, styles.AlignCenter, styles.font18, styles.blackFont]}
          >{this.props.headText}
          </Text>
          <Text style={[styles.WhiteText, styles.AlignCenter, styles.mar15Bt]}>{this.props.subText}
          </Text>
          <View style={[styles.bookingidDetails, styles.mar10Bt, styles.transperantBgrnd]}>
            <Text style={[styles.WhiteText, styles.lightFont, styles.mar3Bt]}>Booking ID</Text>
            <Text
              style={[styles.WhiteText, styles.boldFont]}
            >
              {this.props.refId || this.props.response.bookingId}
            </Text>
          </View>
          <View style={isNotNullAndEmpty(this.props.card.actionList) ? styles.whiteBtnHalfOuter : styles.hideView}>
            {getActionButton(this.props.card, this.props.response, this.handleClick)}
          </View>
        </View>
      </LinearGradient>
    );
  }
  handleClick = (action) => {
    handleClickEvent(this.props.card, action, this.props.response, this.props.pageName);
  };
}

TopStatusCard.propTypes = {
  response: PropTypes.object.isRequired,
  card: PropTypes.object.isRequired,
  iconPath: PropTypes.number.isRequired,
  headText: PropTypes.string.isRequired,
  color: PropTypes.array.isRequired,
  subText: PropTypes.string.isRequired,
  onBackPress: PropTypes.func.isRequired,
  pageName: PropTypes.string.isRequired
};

export default TopStatusCard;
