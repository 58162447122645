import React from 'react';
import { Text, Image } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import createStyle from './styleCss';
import { getFont } from '../../../PostSalesStyles';
import { useTheme } from '../../../theme/theme.context';
import isEmpty from 'lodash/isEmpty';

interface TripAssuredTagProps {
    tripAssuredTagData: {
        text: string;
        iconUrl: string;
    };
}

const TripAssuredTag: React.FC<TripAssuredTagProps> = ({ tripAssuredTagData }) => {
    if (isEmpty(tripAssuredTagData)) {
        return null;
    }
    const { text, iconUrl } = tripAssuredTagData || {};
    const { fsStyle, ...fonts } = getFont(true);
    const { psTheme: theme } = useTheme();
    const styles = createStyle(fonts, theme);
    return (
        <LinearGradient
            start={{ x: 1.0, y: 0.0 }}
            end={{ x: 0.0, y: 1.0 }}
            colors={['#e4f6f1', '#dbedec']}
            style={styles.btnGradient}
        >
            {!!iconUrl && <Image source={{ uri: iconUrl }} style={styles.iconImgStyle} />}
            {!!text && <Text style={[styles.textStyle]}>{text}</Text>}</LinearGradient>
    )
};
export default TripAssuredTag;