const BUS_ROUTE_CONFIG = [
  {
    key: 'busPaymentBreakup',
    component: () => require('./components/BusPaymentDetails').default
  },
  {
    key: 'busCancellationDetails',
    component: () => require('./components/BusCancellationDetails').default
  },
  {
    key: 'chooseBusRefund',
    component: () => require('./components/ChooseBusRefund').default
  },
  {
    key: 'busBookingDetail',
    component: () => require('./BusBookingDetail').default
  },
  {
    key: 'selectBusPage',
    component: () => require('./page/CancelRebookSelectBusPage').default
  },
  {
    key: 'reviewDateChangePage',
    component: () => require('./page/CancelRebookReviewPage').default
  },
  {
    key: 'cancelRebookThankYou',
    component: () => require('./page/CancelRebookThankYou').default
  },
  {
    key: 'trackBus',
    component: () => require('./liveTracking/trackBus/TrackBus').default
  },
  {
    key: 'busCancellationSelectPax',
    component: () => require('./cancellation/SelectTravellers').default
  },
  {
    key: 'busreviewRefundCharges',
    component: () => require('./cancellation/ReviewRefundCharges').default
  },
  {
    key: 'reviewBusRefundMode',
    component: () => require('./cancellation/RefundMode/BusRefundMode').default
  },
  {
    key: 'busBookingCancelled',
    component: () => require('./ErrorState/BusBookingCancel').default
  },
  {
    key: 'busCancelledByOperator',
    component: () => require('./components/SpecialClaim/BusCancelledByOperator').default
  },
  {
    key: 'busCancelledByOperatorReview',
    component: () => require('./components/SpecialClaim/BusCancelledByOperatorReview').default
  },
  {
    key: 'busCancelledByOperatorThankYou',
    component: () => require('./components/SpecialClaim/BusCancelledByOperatorThankYou').default
  },
  {
    key: 'autoRefundBusRouter',
    component: () => require('./components/SpecialClaim/CheckingBusOverlay').default
  },
  {
    key: 'busSupportIssueSelect',
    component: () => require('./components/Support/IssueSelection').default
  },
  {
    key: 'busSupportIssueDetail',
    component: () => require('./components/Support/IssueDetail').default
  },
  {
    key: 'busSupportIssueSubmit',
    component: () => require('./components/Support/IssueSubmit').default
  },
  {
    key: 'busWriteReviewWrapper',
    component: () => require('./components/BusWriteReviewWrapper').default
  },
  {
    key: 'primoViewMore',
    component: () => require('./components/PrimoViewMore').default
  },
  {
    key: 'busRefundBreakup',
    component: () => require('../Common/RefundBreakupPage').default
  }
];

export default BUS_ROUTE_CONFIG;