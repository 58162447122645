import React, { useEffect, useMemo, useState } from 'react';
import {
  View,
  Text,
  Image,
  Linking,
  TouchableOpacity,
  ActivityIndicator,
  ScrollView,
  Platform,
} from 'react-native';
import{poll} from 'apps/post-sales/src/utils/PostSaleUtil.js';
import {DOWNLOAD_VENDOR_INVOICE_API, getCommonHeaders} from 'apps/post-sales/src/utils/NetworkUtils.js';
import styles from 'apps/post-sales/src/Common/downloadInvoiceBottomSheetCss.js';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { isEmptyArray } from 'apps/post-sales/src/Common/commonUtil.js';
import FlightBookingModule from '@mmt/legacy-commons/Native/FlightBookingModule';
import {
  BLUE_LIST_2_ICON,
  BLUE_TICK,
  REFRESH_BLUE,
  DOWNLOAD_WITH_LINE,
  CHEVRON_RIGHT_BLUE_ICON} from './commonImageConstants';
import { DownloadInvoiceStatus } from 'apps/post-sales/src/flights/modules/details/components/DownloadInvoice/constants.js';
import Toast, { THEME_DARK } from '@mmt/legacy-commons/Common/Components/Toast2';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import { getStaticData } from 'apps/post-sales/src/staticData/staticData.js';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import { getFont } from 'apps/post-sales/src/PostSalesStyles.js';
import BaseButton, { BUTTON_VARIANT } from 'apps/post-sales/src/Common/BaseButton/index.js';
import { imageTransformY, promiseAllSettled } from 'apps/post-sales/src/utils/PostSaleUtil.js';
import { DOWNLOAD_FILES_THROTTLE_INTERVAL } from './commonConstant';
import { isEmpty } from 'lodash';

const InvoiceList = ({ data, bookingID,pageName}) => {
    if(isEmpty(data))
        { return null;}
  const { fsStyle, ...fonts } = getFont(true);
  const [spinner, setSpinner] = useState(false);
  const [invdownloadStatus, setinvdownloadStatus] = useState({});
  let initialInvoiceStatus = {}
  useEffect(() => {
    if(!isEmptyArray(data.invoiceDetails)){
      for(let i = 0; i < data.invoiceDetails.length; i++){
        initialInvoiceStatus[i] = DownloadInvoiceStatus.NONE
      }
      setinvdownloadStatus({...initialInvoiceStatus})
    }
  },[data])

  const allInvDownloaded = useMemo(() => {
    if(!isEmptyArray(data.invoiceDetails)){
      for (let i = 0; i < data.invoiceDetails.length; i++){
        if(invdownloadStatus[i] !== DownloadInvoiceStatus.SUCCESS){
          return false;
        }
        return true;
      }
    }
  },[invdownloadStatus])

  const handleDownloadInvoice = async () => {
    const { openFileText } = getStaticData();
    !!data.downloadingText && !allInvDownloaded &&
      Toast.show(data.downloadingText, 1000, 64, THEME_DARK, styles.toastDtls, {
        ...styles.toastText,
        ...fsStyle.font14,
      });
    setSpinner(true);
    const downloadReq = [];
    const notifReq = [];
    try {
      for (let i = 0; i < data.invoiceDetails.length; i++) {
        if(invdownloadStatus[i] === DownloadInvoiceStatus.INPROGRESS || invdownloadStatus[i] === DownloadInvoiceStatus.SUCCESS){
          continue;
        }
      
        if (data.invoiceDetails[i]?.jobId!=null) {
          const response = await pollingApi(data.invoiceDetails[i],bookingID);
          if (response && response.url) {
            updateDownloadStatus(DownloadInvoiceStatus.SUCCESS, i);
            Linking.canOpenURL(response.url).then(supported => {
                if (supported) {
                  Linking.openURL(response.url);
                } });
          
          }        
            }  
        else {
        updateDownloadStatus(DownloadInvoiceStatus.INPROGRESS, i);
        const { bytes, title, invoiceno } = data.invoiceDetails[i];
        const fileName = title + '_' + invoiceno + '.pdf';
        if (Platform.OS === 'web') {
          //timeout is added because downloadjs(used in PWA) lib is not able to handle back to back multiple calls
          setTimeout(() => {
            FlightBookingModule.saveTicket(bytes, fileName);
          }, 1000 * i);
          continue;
        }
        downloadReq.push(i) //Adding Index against each promise
        downloadReq.push(FlightBookingModule.saveTicket(bytes, fileName));
        notifReq.push(
          FlightBookingModule.showTicketDownloadedNotification({
            title: fileName,
            description: openFileText,
            fileName,
          }),
        );
      }
      }
      const allInvDownloadStatus = await promiseAllSettled(downloadReq)();
      let newInvState = invdownloadStatus
      for(let j = 0; j < allInvDownloadStatus.length; j+= 2){
        if(allInvDownloadStatus[j+1]?.value?.status)
          newInvState[allInvDownloadStatus[j]?.value] = DownloadInvoiceStatus.SUCCESS
        else{
          newInvState[allInvDownloadStatus[j]?.value] = DownloadInvoiceStatus.ERROR
        }
      }
      setinvdownloadStatus({...newInvState})
      await Promise.all(notifReq);
    } catch (ex) {
      !!data.downloadFailed &&
        Toast.show(data.downloadFailed, 1000, 64, THEME_DARK, styles.toastDtls, {
          ...styles.toastText,
          ...fsStyle.font14,
        });
        let invStatus = invdownloadStatus
        for(let i = 0; i < data.invoiceDetails.length; i++){
          if(invdownloadStatus[i] === DownloadInvoiceStatus.SUCCESS){
            continue;
          }
          invStatus[i] = DownloadInvoiceStatus.ERROR
        }
        setinvdownloadStatus({...initialInvoiceStatus})
    }
    setSpinner(false);
  };

  const updateDownloadStatus = (status, index) => {
    let currentState = invdownloadStatus;
    currentState[index] = status
    setinvdownloadStatus({...currentState})
  }

  return (
    <>
        {!!data.header && (
          <Text style={[styles.title, fonts.blackFontFamily, fsStyle.font22]}>{data.header}</Text>
        )}
        <View style={styles.invoiceListWrapper}>
          <ScrollView>
            <View style={{ paddingHorizontal: 30 }}>
              {!isEmptyArray(data.invoiceDetails) &&
                data.invoiceDetails.map((item, index) => {
                  const lastItem = index === data.length - 1 ? 'lastItemStyle' : '';
                  return (
                    <InvoiceListRow
                      item={item}
                      index={index}
                      lastItem={lastItem}
                      downloadingInvoice={data.downloadingText}
                      downloadFailed={data.downloadFailed}
                      downloadStatus={invdownloadStatus[index]}
                      updateDownloadStatus={updateDownloadStatus}
                      bookingID={bookingID}
                      pageName={pageName}
                    />
                  );
                })}
            </View>
          </ScrollView>
        </View>

        {!isEmptyArray(data.actionButtonList) &&
          data?.invoiceDetails.length > 1 &&
          Platform.OS !== 'ios' && (
            <BaseButton
              throttleInterval={DOWNLOAD_FILES_THROTTLE_INTERVAL}
              isThrottleRequired={true}
              variant={BUTTON_VARIANT.PRIMARY}
              clickHandler={handleDownloadInvoice}
              textStyle={fsStyle.font16}
              isLoading={spinner}
              spinnerSize={'small'}
              spinnerColor={'white'}
              text={data.actionButtonList[0].text}
            />
          )}
    </>
  );
};

export const  pollingApi = async (item,bookingID,pageName) => {

  try {

   const {jobId} = item || {};
   const userInfo = await FlightBookingModule.getRequestHeader(
    bookingID,
    pageName,
  );

   const headers = await getCommonHeaders(userInfo.mmtAuth,userInfo.loggingTrackingInfo);
   const validate = (response) => response.jobStatus === "completed" && response.url;

   const fetchInvoiceAPI = async () => {
    const downloadInvoiceUri =DOWNLOAD_VENDOR_INVOICE_API;
    return fetch(
    `${downloadInvoiceUri}/${bookingID}/${jobId}`,{headers}
  ).then((response) => response.json());
  };

  const result = await poll({
    fn: fetchInvoiceAPI,
    validate,
    interval: item.pollingWaitTime,
    maxAttempts: item.noOfPolls + 1,
  });
   return result;
  } catch (error) {
     return{
       error: true
     }
  }
 };

const InvoiceListRow = ({ item, index, lastItem, downloadingInvoice, downloadFailed, downloadStatus, updateDownloadStatus, bookingID,pageName }) => {
  const { fsStyle, ...fonts } = getFont(true);
  const handleInvoice = async () => {
    const { openFileText } = getStaticData();
    !!downloadingInvoice &&
      Toast.show(downloadingInvoice, 1000, 64, THEME_DARK, styles.toastDtls, {
        ...styles.toastText,
        ...fsStyle.font14,
      });
    updateDownloadStatus(DownloadInvoiceStatus.INPROGRESS, index);
    try {
      const fileName = item.title + '_' + item.invoiceno + '.pdf';
      if(item.jobId!=null)
        {      
          const response = await pollingApi(item,bookingID,pageName);
          if (response && response.url) {
            updateDownloadStatus(DownloadInvoiceStatus.SUCCESS, index);
            Linking.canOpenURL(response.url).then(supported => {
                if (supported) {
                  Linking.openURL(response.url);
                } });
            }          
            

          }
    else  if (Platform.OS === 'android') {
        await FlightBookingModule.saveTicket(item.bytes, fileName);
        updateDownloadStatus(DownloadInvoiceStatus.SUCCESS, index);

        FlightBookingModule.showTicketDownloadedNotification({
          title: fileName,
          description: openFileText,
          fileName,
        });
      } else if (Platform.OS === 'ios') {
        await GenericModule.openPDFViewer(fileName, item.bytes);
        updateDownloadStatus(DownloadInvoiceStatus.NONE, index);
      } else if (Platform.OS === 'web') {
        FlightBookingModule.saveTicket(item.bytes, fileName);
        updateDownloadStatus(DownloadInvoiceStatus.NONE, index);
      }
    } catch (ex) {
        console.log(ex);
        updateDownloadStatus(DownloadInvoiceStatus.ERROR, index);
    }
  };

  return (
    <View style={[styles.listWrapper, styles[lastItem]]}>
      <View style={[AtomicCss.flex1, AtomicCss.flexRow]}>
        <Image style={styles.iconStyle} source={BLUE_LIST_2_ICON} />
        <View style={[AtomicCss.flex1]}>
          <Text style={[styles.titleTxt, fonts.blackFontFamily, fsStyle.font16]}>{item.title}</Text>
          <Text style={[styles.desc, fonts.regularFontFamily, fsStyle.font14]}>
            {item.invoiceDateFormatted}
          </Text>
          {downloadStatus == DownloadInvoiceStatus.ERROR && !!downloadFailed && (
            <Text
              style={[
                styles.failedTxt,
                AtomicCss.marginTop5,
                fonts.regularFontFamily,
                fsStyle.font14,
              ]}
            >
              {downloadFailed}
            </Text>
          )}
        </View>
      </View>
      <View>
        {downloadStatus != DownloadInvoiceStatus.SUCCESS &&
          downloadStatus != DownloadInvoiceStatus.INPROGRESS && (
            <TouchableOpacity onPress={() => handleInvoice()}>
              {downloadStatus == DownloadInvoiceStatus.NONE && (
                <Image
                  style={
                    Platform.OS === 'ios' ? [styles.downloadImgStyleIos, imageTransformY] : styles.downloadImgStyle
                  }
                  source={Platform.OS === 'ios' ? CHEVRON_RIGHT_BLUE_ICON : DOWNLOAD_WITH_LINE}
                />
              )}
              {downloadStatus == DownloadInvoiceStatus.ERROR && (
                <Image style={styles.failedIconStyle} source={REFRESH_BLUE} />
              )}
            </TouchableOpacity>
          )}
        {downloadStatus === DownloadInvoiceStatus.INPROGRESS && (
          <ActivityIndicator size="small" color={colors.primary} />
        )}
        {downloadStatus === DownloadInvoiceStatus.SUCCESS && (
          <Image style={styles.successTickStyle} source={BLUE_TICK} />
        )}
      </View>
    </View>
  );
};

export default InvoiceList;
