import React from 'react';
import {Image, View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getStaticData } from '../../../../staticData/staticData';
import { copyToClipboard } from '../../../../Common/commonUtil';
import { showLongToast } from '@mmt/core/helpers/toast';
import isEmpty from 'lodash/isEmpty';
import { NEW_COPY_ICON } from '../../../HotelImageConstants';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const BookingConfirmedHeading = ({bookingId, textStyle, bottomText}) => {  
  const staticData = getStaticData();
  const { fsStyle, ...fonts } = getFont(true);
  const onCopyBookingId = async (bookingId) => {
    const { copiedToClipboardText } = getStaticData(true);
    const copyStatus = await copyToClipboard(bookingId);
    if (copyStatus) {
      showLongToast(copiedToClipboardText);
    }
  };

  return (
    <View style={styles.bottomWrapper}>
      <Text style={[fonts.regularFontFamily, fsStyle.font14, AtomicCss.lineHeight20, AtomicCss.textCenter, AtomicCss.marginBottom2, textStyle]}>
         {bottomText ? bottomText : `${staticData.bookingIdText} : ${bookingId}`}
      </Text>
      {isEmpty(bottomText) && ( 
        <TouchableOpacity onPress={()=>onCopyBookingId(bookingId)} style={styles.copyWrapper}>
         <Image style={styles.copyIcon} source={NEW_COPY_ICON} />
      </TouchableOpacity>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  bottomWrapper: {
    paddingHorizontal: 16,
    textAlign: 'center',
    flexDirection:'row',
    alignSelf:'center'
  },
  copyWrapper:{
    flexDirection:'row',
    alignItems:'center'
  },
  copyIcon:{
    width:20,
    height:20,
    marginRight:5
  }
});

export default BookingConfirmedHeading;