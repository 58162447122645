import React from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { ONE_WAY_ICON, RIGHT_ARROW } from '../../../../FlightImageConstants';
import { getFont } from '../../../../../PostSalesStyles';
import isEmpty from 'lodash/isEmpty';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import createStyles from './CabAddonListCss';

interface CabAddonListProps {
  statusColor: string;
  status: string;
  subHeaderMessage: string;
  subHeaderMessage2: string;
  fromCity: string;
  toCity: string;
  iconForApp: string;
  addOnCardClick: () => void;
}

const CabAddonList: React.FC<CabAddonListProps> = (props) => {
  const {
    statusColor,
    status,
    subHeaderMessage,
    subHeaderMessage2,
    fromCity,
    toCity,
    iconForApp,
    addOnCardClick = () => {},
  } = props;
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme);
  const statusTxtColor = statusColor && { color: statusColor };
  const subText = `${subHeaderMessage} ${subHeaderMessage2}`;

  return (
    <TouchableOpacity
      style={styles.listRow}
      activeOpacity={0.7}
      onPress={addOnCardClick}
    >
      <View style={styles.listDtlsSection}>
        <View style={styles.iconPlaceHolder}>
          <Image style={styles.cabIconStyle} source={{ uri: iconForApp }} />
        </View>
        <View style={AtomicCss.width90}>
          <View
            style={[
              AtomicCss.flexRow,
              AtomicCss.alignCenter,
              AtomicCss.marginBottom5,
              AtomicCss.flexWrap
            ]}
          >
            {!isEmpty(fromCity) && (
              <Text
                numberOfLines={1}
                style={[
                  fsStyle.font16,
                  fonts.boldFontFamily,
                  AtomicCss.defaultText,
                  { maxWidth: 155 },
                ]}
              >
                {fromCity}
              </Text>
            )}
            <Image style={[styles.oneWayArrowStyle, styles.marHor5]} source={ONE_WAY_ICON} />
            {!isEmpty(toCity) && (
              <Text style={[fsStyle.font16, fonts.boldFontFamily, AtomicCss.defaultText]}>
                {toCity}
              </Text>
            )}
          </View>
          {!isEmpty(subText) && (
            <Text style={[fsStyle.font14, fonts.regularFontFamily]}>{subText}</Text>
          )}
          {!isEmpty(status) && (
            <Text
              style={[
                fsStyle.font14,
                fonts.regularFontFamily,
                AtomicCss.lineHeight24,
                statusTxtColor,
              ]}
            >
              {status}
            </Text>
          )}
        </View>
      </View>
      <Image style={styles.rgtArrowStyle} source={RIGHT_ARROW} />
    </TouchableOpacity>
  );
};

export default CabAddonList;
