import { StyleSheet } from 'react-native';
import getPlatformElevation from 'apps/post-sales/src/Holiday/Components/getPlatformElevation';

export const getHtmlstyles = (fonts, color) => {
  return {
    q: {
        color: color.lightGreen18,
        ...fonts.boldFontFamily,
        fontSize: fonts.fontSize.font16,
    },
    p: {
      color: color.defaultTextColor,
      fontSize: fonts.fontSize.font14,
      ...fonts.regularFontFamily,
      marginBottom: 3,
    }
  };
};
const getStyle = (fonts, color) => {
  return StyleSheet.create({
    CancellationPolicyCardWrapper: {
      backgroundColor: color.white,
      paddingLeft: 21,
      paddingRight: 16,
      paddingTop: 16,
      marginBottom: 10,
      ...getPlatformElevation(1),
    },
    CardIcon: {
      width: 24,
      height: 24,
      marginRight: 8,
    },
    ContentPortion: {
      flex: 1
    },
    heading: {
      color: color.black,
      ...fonts.blackFontFamily,
      fontSize: fonts.fontSize.font16
    },
    trakerWrapper: {
      paddingTop: 20,
      paddingBottom: 0,
      paddingLeft: 30,
    },
    cancPolicyWrapper: {
      paddingVertical: 10,
      paddingHorizontal: 12,
      marginBottom: 20,
      backgroundColor: color.white,
    },
    cancellationIcon: {
      width: 24,
      height: 24,
      marginRight: 10,
    },
    trakerWrapper: {
      paddingTop: 20,
      paddingBottom: 0,
      paddingLeft: 30,
    },
    redirectLinkWrapper: {
      paddingLeft: 30,
    },
    redirectLink: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingVertical: 12,
      borderBottomColor: color.lightGray,
      borderBottomWidth: 1,
    },
    rightArrowStyle: {
      width: 24,
      height: 24,
    },
    borBtmZero: {
      borderBottomColor: color.transparent,
      borderBottomWidth: 0,
    },
    trackerRow: {
      borderLeftWidth: 1,
      borderLeftColor: color.lightGrey16,
      paddingLeft: 20,
      paddingBottom: 20,
    },
    listContainer: {
      paddingLeft: 20,
    },
    completed: {
      opacity: 0.5,
    },
    completedDot: {
      backgroundColor: color.lightGrey16,
    },
    lastRow: {
      borderLeftWidth: 0,
      borderLeftColor: 'transparent',
      paddingBottom: 0,
    },
    rowDot: {
      width: 10,
      height: 10,
      borderRadius: 8,
      backgroundColor: color.white,
      borderWidth: 2,
      borderColor: color.lightGrey16,
      position: 'absolute',
      left: -5,
      top: 0,
    },
    successText: {
      color: color.lightGreen18,
    },
    downArrow: {
      top: -5,
      transform: [
        {
          rotate: '90deg',
        },
      ],
    },
    activeDot: {
      backgroundColor: color.azure,
      borderColor: color.azure,
    },
    deductedText: {
      color: color.lightYello,
    },
    noRefundText: {
      color: color.red17,
    },
    boardingPoint: {
      position: 'absolute',
      left: -8,
    },
    boardingBusStyle: {
      width: 20,
      height: 20,
      top: -2,
    },
    arrowDown:{
    width: 25,
      height: 25,
      top: -2,
    },
    infoIconStyle: {
      width: 24,
      height: 24,
      tintColor: color.defaultTextColor,
    },
    viewSlab:{
        fontSize: fonts.fontSize.font14,
        color:color.azure,
        ...fonts.boldFontFamily
    },
    noRefund: {
      backgroundColor: color.pink7,
      color: color.red17,
      lineHeight: 14,
      paddingVertical: 4,
      paddingHorizontal: 4,
      fontSize: fonts.fontSize.font12,
      borderRadius: 4,
      ...fonts.boldFontFamily,
    },
    cancellationPolicySubText : {
      fontSize: fonts.fontSize.font14,
    },
    currentPolicyText:{
      fontSize: fonts.fontSize.font14,
      color: color.defaultTextColor
    },
    disclaimerText:{
      color: color.defaultTextColor,
      ...fonts.regularFontFamily,
      fontSize: fonts.fontSize.font14,
      paddingLeft: 20,
      marginTop: 10
    }
  });
};

export default getStyle;
