import { StyleSheet } from 'react-native';
import { DEFAULT_THEME } from 'apps/post-sales/src/theme/default.theme';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';

export default <T extends typeof DEFAULT_THEME>(theme: T) => StyleSheet.create({
  messageProperty: {
    width: 28,
    height: 28,
  },
  rightBlueArrow: {
    width: 16,
    height: 16,
    marginRight: 10,
  },
  cardWrapper: {
    backgroundColor: theme.color.white,
    borderRadius: 10,
    marginHorizontal: 12,

    padding: 16,
    ...getPlatformElevation(2),
    marginBottom: 16,
  },
  expediaChatCard: {
    backgroundColor: theme.color.white,
    borderRadius: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  redCircle: {
    width:16,
    height:16,
    borderRadius:12,
    backgroundColor: theme.color.red31,
    justifyContent:'center',
    alignItems:'center',
    overflow:'hidden',
    position:'absolute',
    top:-4,
    right:-4,
  },
});
