import React from 'react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { View, Image, Text, StyleSheet } from 'react-native';
import noError from '@mmt/legacy-assets/src/no_cabs.webp';
import BaseButton, { BUTTON_VARIANT } from '../../../../../../Common/BaseButton';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getStaticData } from '../../../../../../staticData/staticData';
import { getFont } from '../../../../../../PostSalesStyles';
import { useTheme } from '../../../../../../theme/theme.context';
import { FLIGHT_THROTTLE_OBJECT } from '../../../../details/FlightBookingDetailsConstant';

const ErrorView = ({ retry, errorDetail }) => {
  const { errorViewMessageText, somethingWentWrongText } = getStaticData();
  const fonts = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);
  return (
    <View style={styles.noErrorContainer}>
      <Image style={styles.noErrorImage} source={noError} resizeMode="contain" />
      <Text style={styles.noErrorTitle}>
        {isEmpty(errorDetail.header) ? somethingWentWrongText : errorDetail.header}
      </Text>
      <Text style={styles.noErrorSubtitle}>
        {isEmpty(errorDetail.message) ? errorViewMessageText : errorDetail.message}
      </Text>
      <BaseButton
        text={errorDetail?.TryAgainText?.toUpperCase()}
        clickHandler={retry}
        {...FLIGHT_THROTTLE_OBJECT}
        variant={BUTTON_VARIANT.CAPSULE}
        textStyle={AtomicCss.font10}
      />
    </View>
  );
};

const createStyles = (theme, fonts) => StyleSheet.create({
  noErrorContainer: {
    flex: 1,
    backgroundColor: theme.color.white,
    paddingHorizontal: 36,
    alignItems: 'center',
  },
  noErrorImage: {
    height: 180,
    marginVertical: 16,
  },
  noErrorTitle: {
    textAlign: 'center',
    marginTop: 16,
    fontSize: fonts.fontSize.font20,
    lineHeight: 32,
    ...fonts.boldFontFamily,
  },
  noErrorSubtitle: {
    textAlign: 'center',
    marginVertical: 16,
    fontSize: fonts.fontSize.font14,
    lineHeight: 21,
    ...fonts.regularFontFamily,
  },
});

ErrorView.propTypes = {
  retry: PropTypes.func.isRequired,
  errorDetail: PropTypes.object.isRequired,
};

export default ErrorView;
