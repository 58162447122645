import React from 'react';
import  Actions  from '../../../navigation/postSalesNavigation';
import PropTypes from 'prop-types';
import {
  Image,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import getStyles from './HotelMamiCss';
import atomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import StickyHeader from './StickyHeader';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import PostSalesHotelsBaseClass from './PostSalesHotelsBaseClass';
import HotelBookingTrackingHelper from '../../HotelBookingTrackingHelper';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import * as HotelBookingDetailUtil from '../utils/HotelBookingDetailUtil';
import FullButton from '../ActionButton/FullButton';
import { CALL_HOTEL_ICON, LEFT_BLACK_ARROW_ICON, CHEVRON_RIGHT_BLUE_ICON3 } from '../../HotelImageConstants';
import { openDialer } from '../../../Common/commonUtil';
import { getStaticData } from '../../../staticData/staticData';
import { imageTransformY } from '../../../utils/PostSaleUtil';
import isEmpty  from 'lodash/isEmpty';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const headerIcon = LEFT_BLACK_ARROW_ICON;
const icon = CALL_HOTEL_ICON;
const arrowIcon = CHEVRON_RIGHT_BLUE_ICON3;

export default class SubmitRequest extends PostSalesHotelsBaseClass {
  constructor(props) {
    super(props, 'submitRequestPage', 'Persuadetocall');
    this.fonts = getFont(true);
    this.styles = getStyles(this.fonts);
    this.submitRequeststyle = getSubmitRequeststyles(this.fonts);
    this.showRequesToWrite = this.allowWriteToHotel();
  }

  onCallHotelPress = () => {
    const hotelDetailResponse = this.props.response.hotelBookingInfo;
    const hotelBookingInfo =
      hotelDetailResponse.hotelDetailsList[
        hotelDetailResponse.hotelDetailsList.length - 1
      ];
    HotelBookingTrackingHelper.trackClickEvent(
      'SubmitRequest',
      'MI_HTL_CallHotel',
      this.props.response,
    );
    if (hotelBookingInfo.phoneNumber.includes(',')) {
      Actions.openCallHotel({
        response: this.props.response,
      });
    } else {
      openDialer(hotelBookingInfo.phoneNumber);
    }
  };

  onWriteToHotel() {
    Actions.requestWriteToHotel({
      requestData: this.props.subRequestData,
      response: this.props.response,
      bookingId: this.props.response.bookingID
    });
    HotelBookingTrackingHelper.trackClickEvent(
      'SubmitRequest',
      'MI_HTL_Send request',
      this.props.response,
    );
  }

  allowWriteToHotel() {
    const checkinDate = this.props.response.hotelBookingInfo.checkInDate;
    const currentTime = new Date().getTime();
    const days = HotelBookingDetailUtil.findDaysBetweenDates(
      currentTime,
      checkinDate,
    );
    const numberofDays = this.props.subRequestData.time;
    if (days > numberofDays) {
      return true;
    }
    return false;
  }

  render() {
    const staticData = getStaticData();
    const {response} = this.props;
    const {hotelBookingInfo: hotelDetailResponse, showCallToHotel} = !isEmpty(response)? response: {};
    const hotelBookingInfo = !isEmpty(hotelDetailResponse) && !isEmpty(hotelDetailResponse.hotelDetailsList) ?
      hotelDetailResponse.hotelDetailsList[
        hotelDetailResponse.hotelDetailsList.length - 1
      ] : {};
    
    const { hotelUIFields } = hotelBookingInfo;


    return (
      <View style={this.styles.makeRequestWrapper}>
        <StickyHeader
          iconSrc={headerIcon}
          title={this.props.subRequestData.requestType}
          headerColor={['#ffffff', '#ffffff']}
          titleStyle={[this.styles.makeRequestHeader]}
          subTitle=""
          subTitleStyle={[this.styles.DefaultText, this.styles.font12]}
          onBackPress={this.onBackPress}
        />

        <ScrollView>
          <View
            style={[
              this.styles.requestInfoOuter,
              this.showRequesToWrite ? this.styles.showView : this.styles.hideView,
            ]}
          >
            <TouchableOpacity onPress={() => this.onWriteToHotel()}>
              <View style={this.styles.RecFullBtn}>
                <View>
                  <Text style={[this.submitRequeststyle.writeToHotel]}>
                    {this.props.response.hotelBookingInfo.hotelDetailsList[0].hotelUIFields && this.props.response.hotelBookingInfo.hotelDetailsList[0].hotelUIFields.writeToHotelText}
                  </Text>
                  <Text>{this.props.response.hotelBookingInfo.hotelDetailsList[0].hotelUIFields && this.props.response.hotelBookingInfo.hotelDetailsList[0].hotelUIFields.takeUpto48HrsToReplyText}</Text>
                </View>
                <Image style={[this.styles.RecFullBtnArrow, imageTransformY]} source={arrowIcon} />
              </View>
            </TouchableOpacity>
          </View>
          {showCallToHotel && <React.Fragment>
            <View
              style={[
                this.styles.OrDivider,
                this.showRequesToWrite ? this.styles.showView : this.styles.hideView,
              ]}
            >
              <View style={this.styles.OrLine}>
                <Text>.</Text>
              </View>

              <View style={this.styles.OrCircle}>
                <Text style={atomicCss.midGreyText}>
                  {staticData.hotelSubmitRequestText.additionalText.orCapsText}
                </Text>
              </View>
            </View>
            <View style={this.styles.requestInfoOuter}>
              <TouchableOpacity onPress={() => this.onCallHotelPress()}>
                <View style={[this.styles.RecFullBtn]}>
                  <View style={this.submitRequeststyle.writeHotelWrap}>
                    <Text style={[this.submitRequeststyle.writeToHotel]}>
                      {hotelUIFields && hotelUIFields.callDirectlyText}
                    </Text>
                    <Text>{this.props.subRequestData.message_call}</Text>
                  </View>
                  <Image style={[this.styles.RecFullBtnArrow, imageTransformY]} source={arrowIcon} />
                </View>
              </TouchableOpacity>
            </View>
          </React.Fragment>}
        </ScrollView>
      </View>
    );
  }
}

const getSubmitRequeststyles = (fonts) => {
  return StyleSheet.create({
    successIcon: {
      height: 105,
      width: 119,
      alignSelf: 'center',
      marginBottom: 20,
    },
    messageTitle: {
      ...fonts.font22,
      textAlign: 'center',
      lineHeight: 30,
      color: '#000000',
      fontFamily: fonts.black,
      marginBottom: 20,
      marginLeft: 50,
      marginRight: 50,
    },
    messageText: {
      textAlign: 'center',
      ...fonts.font16,
      fontFamily: fonts.regular,
      color: '#4A4A4A',
      flexDirection: 'row',
      lineHeight: 20,
      marginLeft: 35,
      marginRight: 35,
      marginBottom: 45,
    },
    writeToHotel: {
      ...fonts.font14,
      fontFamily: fonts.bold,
      color: '#000000',
    },
    writeHotelWrap: { 
      width: "100%",
      paddingLeft: 2 
    }
  });
}

SubmitRequest.propTypes = {
  subRequestData: PropTypes.object.isRequired,
  response: PropTypes.object.isRequired,
};
