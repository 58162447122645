import React from 'react';
import {View, Text} from 'react-native';
import isEmpty from 'lodash/isEmpty';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {fonts, colors} from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from './getPlatformElevation';
import { getFont } from '../../PostSalesStyles';

class FareBreakUp extends React.Component {
  static navigationOptions = {
    header: null
  };
  render() {
    const {paymentDetails} = this.props;
    const themeFonts = getFont();
    return (
      <React.Fragment>
        <View style={styles.headingTitle}>
          <Text style={[AtomicCss.blackFont, AtomicCss.font16, AtomicCss.blackText]}>Price Break
            Up</Text>
        </View>
        <View style={[AtomicCss.whiteBg, AtomicCss.marginBottom10]}>
          <View style={[styles.CardContentFull]}>
            <View style={styles.fareBreakupInnerSection}>
              {!isEmpty(paymentDetails.paymentBreakups)
              && paymentDetails.paymentBreakups.map((item) => {
                return (
                  <View style={styles.fareBreakupInnerRowSection}>
                    {item.isDiscount &&
                    <View style={[styles.rowSection]}>
                      <View style={AtomicCss.flexRow}>
                        <Text style={styles.successTxt}>{item.label}</Text>
                        {!isEmpty(item.extraInfo) &&
                        <View style={styles.cuponCode}>
                          <Text>{item.extraInfo}</Text>
                        </View>
                        }
                      </View>
                      {!isEmpty(item.value) &&
                      <Text style={[styles.successTxt]}>{item.value}{' '}</Text>
                      }

                    </View>
                    }
                    {!item.isDiscount &&
                    <View style={[styles.rowSection, AtomicCss.marginBottom8]}>
                      <Text style={styles.fareBkHeadingTxt}>{item.label} 
                      {!isEmpty(item.sideInfo) && <Text style={[AtomicCss.midGreyText, themeFonts.font12, themeFonts.regularFontFamily]}>{item.sideInfo}</Text>}</Text>
                      {!isEmpty(item.value) &&
                      <Text style={[styles.fareBkHeadingTxt]}>{item.value}{' '}</Text>
                      }
                    </View>
                    }

                    {!isEmpty(item.extraSubInfo) &&
                    <View style={[styles.rowSection, AtomicCss.marginBottom5]}>
                      <Text style={styles.lftTxt}>{item.extraSubInfo}</Text>
                    </View>
                    }
                  </View>
                );
              })
              }
            </View>
            <View style={[styles.totalPrice]}>
              <Text style={[styles.totalPricetxt, AtomicCss.font16]}>TOTAL PRICE</Text>
              <Text
                style={[styles.totalPricetxt, AtomicCss.font18]}>{paymentDetails.totalPrice}{' '}</Text>
            </View>
          </View>
        </View>
      </React.Fragment>
    );
  }
}

const styles = {

  CardContentFull: {
    backgroundColor: colors.white,
    paddingRight: 0,
    paddingBottom: 0,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc'
  },
  cardHeadingWrapper: {
    marginBottom: 2,
    paddingVertical: 10,
    ...getPlatformElevation(2),
    backgroundColor: '#fff'
  },
  cardHeading: {
    color: '#000',
    textAlign: 'center',
    fontSize: 20,
    fontFamily: fonts.black

  },
  fareBreakupInnerWrpper: {},
  fareBreakupInnerSection: {
    ...getPlatformElevation(2),
    marginBottom: 1,
    backgroundColor: '#fff'

  },
  fareBreakupInnerRowSection: {
    borderBottomWidth: 1,
    borderBottomColor: '#f2f2f2',
    paddingVertical: 15,
    paddingHorizontal: 16
  },
  rowSection: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  fareBkHeadingTxt: {
    color: '#000',
    fontFamily: fonts.bold,
    fontSize: 14
  },
  successTxt: {
    color: '#1a7971',
    fontFamily: fonts.bold,
    fontSize: 14
  },
  lftTxt: {
    fontSize: 12,
    color: '#747474',
    fontFamily: fonts.regular,
    lineHeight: 14

  },
  payTxt: {
    fontSize: 12,
    color: '#cf8100',
    fontFamily: fonts.black,
    lineHeight: 14
  },
  paySection: {
    backgroundColor: '#ffedd1',
    borderRadius: 4,
    marginHorizontal: 8,
    paddingHorizontal: 8
  },
  totalPrice: {
    padding: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 2
  },
  totalPricetxt: {
    fontSize: 16,
    fontFamily: fonts.black,
    color: '#4a4a4a'
  },
  btnWrapper: {
    paddingHorizontal: 40,
    paddingTop: 10,
    paddingBottom: 20
  },
  cuponCode: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#6f6f6f',
    padding: 3,
    position: 'relative',
    top: -5,
    left: 10
  },
  cuponCodeTxt: {
    fontFamily: fonts.regular,
    fontSize: 12,
    color: '#747474'

  },
  borderBtm0: {
    borderBottomWidth: 0,
    borderBottomColor: 'transparent'
  },

  headingTitle: {
    padding: 14,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    marginBottom: 2,
    ...getPlatformElevation(1)
  }

};

export default FareBreakUp;
