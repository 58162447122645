import React from 'react';
import PropTypes from 'prop-types';
import Fecha from 'fecha';
import LinearGradient from 'react-native-linear-gradient';
import {View, Text, Image, Platform, TouchableOpacity, StyleSheet} from 'react-native';
import {statusBarHeightForIphone} from '@mmt/legacy-commons/Styles/globalStyles';
import AtomicCss from '../../commonStyles/AtomicCss';
import {ANDROID_BACK_ARROW, IOS_BACK_ARROW} from '../../Utils/RailImageConstant';
import { getFont } from '../../../PostSalesStyles';

class StickyHeader extends React.Component {
    static navigationOptions = {
      header: null
    };

    render() {
      const { fsStyle, ...fonts } = getFont(true);
      const railDetailResponse = this.props.response.train[0].pnrDetails[0].segmentInfo[0];
      const trainStatusText = railDetailResponse.cityFromToMsg;
      const tripInfo = railDetailResponse.tripInfo;
      this.color = this.props.response.layoutDetail.cardList[0] && [this.props.response.layoutDetail.cardList[0].colorBegin, this.props.response.layoutDetail.cardList[0].colorEnd];
      return (
        <LinearGradient
          start={{x: 1.0, y: 0.0}}
          end={{x: 0.0, y: 1.0}}
          colors={this.color}
          style={[styles.stickyHeader]}
        >
          <View style={styles.topStatusBar}>
            <View style={styles.stickyBarWrapper}>
              <TouchableOpacity style={{padding: 14}} onPress={this.props.onBackPress}>
                <Image style={(Platform.OS === 'android'|| Platform.OS === 'web') ? [AtomicCss.marginBottom10, styles.backarrow] : [AtomicCss.marginBottom10, styles.IOSbackarrow]} source={Platform.OS === 'ios' ? IOS_BACK_ARROW : ANDROID_BACK_ARROW} />
              </TouchableOpacity>
              <View style={[AtomicCss.stickyTextWrapper, styles.transperantBgrnd]}>
                <Text style={[AtomicCss.blackText, fonts.blackFontFamily, AtomicCss.marginBottom5,
                  fsStyle.font14, AtomicCss.transperantBgrnd]}
                >
                  {trainStatusText}
                </Text>
                <Text style={[AtomicCss.blackText, fsStyle.font12,
                  fonts.regularFontFamily, AtomicCss.transperantBgrnd]}
                >{ tripInfo }
                </Text>
              </View>
            </View>
          </View>
        </LinearGradient>
      );
    }

}

const styles = StyleSheet.create({
  stickyTextWrapper: {
    paddingLeft: 5
  },
  stickyHeader: {
    width: '100%',
    elevation: 5,
    backgroundColor: '#000',
    marginBottom: 6,
    paddingTop: statusBarHeightForIphone
  },
  WhiteStickyHeader: {
    backgroundColor: '#fff',
    paddingTop: 10,
    paddingBottom: 10,
    elevation: 3,
    shadowColor: '#000',
    shadowOffset: {width: 0, height: 2},
    shadowOpacity: 0.3,
    shadowRadius: 2
  },
  IOSbackarrow: {
    width: 10,
    height: 16,
    marginTop: 10
  },
  TransperantStickyHeader: {
    backgroundColor: '#00FFFFFF',
    paddingTop: 10,
    paddingBottom: 10,
    elevation: 3,
    shadowColor: '#000',
    shadowOffset: {width: 0, height: 2},
    shadowOpacity: 0.3,
    shadowRadius: 2
  },

  topStatusBar: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },

  stickyBarWrapper: {
    flexDirection: 'row',
    width: '75%',
    alignItems: 'center'
  },
  backarrow: {
    width: 16,
    height: 16
  }
});

StickyHeader.propTypes = {
  response: PropTypes.object.isRequired,
  seatinfoDetails: PropTypes.object.isRequired,
  onBackPress: PropTypes.func.isRequired
};

export default StickyHeader;
