import { StyleSheet } from "react-native";

const getStyles = (fonts, color) => (
    StyleSheet.create({
      fcWrapper: {
        paddingBottom: 30,
      },
      headingSection: {
        paddingHorizontal: 18,
        paddingVertical: 15,
        backgroundColor: color.lightBlue21,
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
      },
      headingText: {
        color: color.black22,
        ...fonts.blackFontFamily,
        fontSize: fonts.fontSize.font24,
        marginBottom: 5,
      },
      subHeadingText:{
        fontSize: fonts.fontSize.font14,
        ...fonts.regularFontFamily,
        color:color.black
      },
      cancellationMsgStyle: {
        fontSize: fonts.fontSize.font14,
        ...fonts.regularFontFamily,
        color:color.white
      },
      footerTextStyle: {
        fontSize: fonts.fontSize.font14,
        ...fonts.regularFontFamily,
        color:color.azure
      },
      detailsSection: {
        paddingHorizontal: 18,
        paddingVertical: 14,
      },
      crossIconWrapper: {
        width: 30,
        height: 30,
        top: -15,
        right: -5,
        alignItems: 'center',
        justifyContent: 'center',
      },
      crossIconStyle: {
        width: 20,
        height: 20,
      },
      freeCanIconStyle: {
        width: 54,
        height: 54,
      },
      infoFC: {
        backgroundColor: color.lightGreen4,
        paddingHorizontal: 5,
        paddingVertical: 4,
        borderRadius: 4,
      },
      innerWrapper: {
        paddingHorizontal: 15,
        paddingVertical: 23,
      },
      refundTxtStyle: {
        color: color.lightGreen4,
        ...fonts.blackFontFamily,
        fontSize: fonts.fontSize.font18,
      },
      priceOfferStyle: {
        width: 27,
        height: 27,
        marginRight: 10,
      },
      greenTikStyle: {
        width: 18,
        height: 18,
        marginLeft: 5,
      },
      tickbulletStyle: {
        width: 13,
        height: 10,
        marginRight: 7,
      },
      chartPreparedWrapper: {
        backgroundColor: color.lighterBlue,
        paddingHorizontal: 5,
        paddingVertical: 4,
        borderRadius: 4,
      },
    })
);

  export default getStyles;