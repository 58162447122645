import React from 'react';
import { View, Animated, Platform} from 'react-native';
import styles from './PropetyDetailsCss';
import BasePage from '../../../../Common/PostSalesBasePage';
import Header from '../../cards/Header';
import VerifiedStaysCard from '../../cards/VerifiedStaysCard';
import PropertyMainCard from '../../cards/PropertyMainCard';
import LocationCard from '../../cards/LocationCard';
import PrimaryGuestBookingCard from '../../cards/PrimaryGuestBookingCard';
import { getPropertyType, isIntlHotelBooking, showShareIcon } from '../../utils/HotelBookingDetailUtil';
import HotelBookingConstant, {PAGE_NUMBER} from '../../HotelBookingDetailsConstant';
import HotelStayDatesCard from '../../components/HotelStayDatesCard';
import HotelRoomDetailsCard from '../../components/HotelRoomDetailsCard';
import FacilitiesCard from '../../cards/FacilitiesCard';
import PropertyLayoutCard from '../../cards/PropertyLayoutCard';
import HotelPhotographsCard from '../../cards/HotelPhotographsCard';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getStaticData } from '../../../../staticData/staticData';
import DeepLinkScrollHandler from '../../components/DeepLinkScrollHandler';
import GBModificationOverlay from '../../../GroupBooking/BottomSheets/modificationOverlay';
import { openDialer } from '../../../../Common/commonUtil';
import isEmpty from 'lodash/isEmpty';
import Actions from 'apps/post-sales/src/navigation/postSalesNavigation';

export const HOTEL_PROPERTY_DETAILS_PAGE = 'hotelPropetyDetail';

class PropertyDetails extends BasePage {
  static navigationOptions = {
    header: null
  };

  constructor(props) {
    super(props, HOTEL_PROPERTY_DETAILS_PAGE);
    this.trackPageName = isIntlHotelBooking(props.response?.lobCode)
      ? 'mob:customer support:Hotel International:PropertyDetails'
      : 'mob:customer support:Hotel Domestic:PropertyDetails';

      this.headerHeight = 0;
      this.ScrollViewRef= React.createRef();
      this.state = {
        showGBModificationOverlayData: undefined
      }
  }

  goBack = () => {
    super.onBackIconPress();
  };

  getMainPageViews() {
    const views = [];
    const cards = this.props.response.layoutDetail &&  this.props.response.layoutDetail.cardList && this.props.response.layoutDetail.cardList.filter(card => card.pageNumber === PAGE_NUMBER.TWO);
    cards && cards.forEach((card) => {
      this.addCard(card, views);
    });
    return views;
  }

  addCard(card, views) {
    switch (card.cardId) {
      case HotelBookingConstant.VERIFIED_STAYS_CARD:
        views.push(<VerifiedStaysCard
          key="VerifiedStaysCard"
          response={this.props.response}
          pageName={this.trackPageName}
        />);
        break;
      case HotelBookingConstant.HOTEL_MAIN_CARD:
        views.push(<PropertyMainCard
          key="PropertyMainCard"
          response={this.props.response} 
          card={card}
          pageName={this.trackPageName}
        />);
        break;
      case HotelBookingConstant.LOCATION_CARD:
        views.push(<LocationCard
          key="LocationCard"
          response={this.props.response}
          card={card}
          pageName={this.trackPageName}
        />);
        break;
      case HotelBookingConstant.PRIMARY_GUEST_CARD:
        views.push(<PrimaryGuestBookingCard
          key="PrimaryGuestCard"
          response={this.props.response}
          card={card}
          pageName={this.trackPageName}
        />);
        break;
      case HotelBookingConstant.HOTEL_STAY_DATES_CARD:
        views.push(<HotelStayDatesCard
          key="HotelStayDatesCard"
          response={this.props.response}
          card={card}
          pageName={this.trackPageName}
          actionHandlerGB={this.showGrpBkModificationOverlay}
        />);
        break;
      case HotelBookingConstant.HOTEL_ROOM_DETAIL_CARD:
        views.push(
        <DeepLinkScrollHandler
          actIds={[HotelBookingConstant.HOTEL_ROOM_DETAILS]}
          delay={200}
          scrollViewRef={this.ScrollViewRef}
          headerHeight={this.headerHeight}
          deeplinkAct={this.props.response.deepLinkPage}>
            <HotelRoomDetailsCard
              key="HotelRoomDetailsCard"
              response={this.props.response}
              card={card}
              pageName={this.trackPageName}
              showAllAmenities={this.props.response.deepLinkPage}
              actionHandlerGB={this.showGrpBkModificationOverlay}
            />
        </DeepLinkScrollHandler>
        );
        break;
      case HotelBookingConstant.HOTEL_PHOTOGRAPHS_CARD:
        views.push(<HotelPhotographsCard
          key="HotelPhotographsCard"
          response={this.props.response}
          card={card}
          pageName={this.trackPageName}
        />);
        break;
      case HotelBookingConstant.HOTEL_AMENITIES_CARD:
        views.push(<FacilitiesCard
          key="FacilitiesCard"
          response={this.props.response}
          card={card}
          pageName={this.trackPageName}
        />);
        break;
      case HotelBookingConstant.PROPERTY_LAYOUT_CARD: 
        views.push(<PropertyLayoutCard
          key={card.cardId}
          response={this.props.response}
          card={card}
          pageName={this.trackPageName}
        />);
        break;
      default:
        break;
    }
  }
       
  showGrpBkModificationOverlay = (actionFamily, response) => {
    const { modifyRoom, modifyRoomMsg, changeDates, changeDatesMsg, addMeals, addMealsMsg, callMMT } = getStaticData(true);

    let showGBModificationOverlayData; 
    switch (actionFamily) {
      case HotelBookingConstant.ACTION_GB_ADD_GUEST:
        showGBModificationOverlayData = {
          heading: modifyRoom,
          subHeading: modifyRoomMsg
        }
        break;
      case HotelBookingConstant.ACTION_GB_CHANGE_DATES:
        showGBModificationOverlayData = {
          heading: changeDates,
          subHeading: changeDatesMsg
        }
        break;
      case HotelBookingConstant.ACTION_GB_ADD_MEAL:
        showGBModificationOverlayData = {
          heading: addMeals,
          subHeading: addMealsMsg
        }
        break;
      default:
        if (Platform.OS === 'web') {
          Actions.hotelBookingDetail({ bookingId: response.bookingID, isODC: true});
        } else {
          this.props.actionHandler(actionFamily);
        }
        break;
    }
    if(showGBModificationOverlayData) {
      this.setState({
        showGBModificationOverlayData: {
          ...showGBModificationOverlayData,
          ctaText: callMMT,
          ctaHandler: () => {
            openDialer(HotelBookingConstant.DEFAULT_MMT_NUMBER);
          }
        }
      })
    }
  }

  hideGrpBkModificationOverlay = () => {
    this.setState({
      showGBModificationOverlayData: undefined
    })
  }

  grpBkModificationOverlay = () => {
    if (this.state.showGBModificationOverlayData) {
      return(<GBModificationOverlay {...this.state.showGBModificationOverlayData} dismiss={this.hideGrpBkModificationOverlay}/>)
    }
  }


  render() {
    const  { bookingDetailsText } = getStaticData();
    const {response} = this.props;
    const {showCallButton=false} = response || {};
    return (
      <View style={styles.container} >
        <View onLayout={({nativeEvent})=>{
            this.headerHeight = nativeEvent.layout.height
        }}>
          <Header
            title={getPropertyType(this.props.response) + ' ' + bookingDetailsText}
            headerShadow
            callUs={showCallButton}
            share={showShareIcon(this.props.response)}
            headerRtSection={true}
            bgColor='white'
            onBackPress={this.goBack}
            response={this.props.response}
            pageName={this.trackPageName}
          />
        </View>
        
        <Animated.ScrollView ref={this.ScrollViewRef} style={styles.detailsWrapper}>
          {this.getMainPageViews()}
          <View style={[AtomicCss.marginBottom12]}></View>
        </Animated.ScrollView>
        {this.grpBkModificationOverlay()}
      </View>
    );
  }
}

export default PropertyDetails;
