import React from 'react';
import { View, Text } from 'react-native';
import get from 'lodash/get';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { styles } from "../SingleSegmentSinglePaxCom/singleSegmentSinglePaxCss"
import { getFont } from '../../../../../../../PostSalesStyles';

export const getDescription = (segment, multiSeg = false) => {
    const description = get(segment, 'description', '');
    const { fsStyle, ...fonts } = getFont(true);
    if (description) {
      return (
        <View style={[AtomicCss.flex1, AtomicCss.paddingRight10]}>
          <Text
            style={[
              ...(multiSeg
                ? [styles.sclaimDescMultiSegDes]
                : [styles.sclaimDesc, fonts.regularFontFamily, fsStyle.font13]),
              AtomicCss.alignLeft,
            ]}
          >
            {description}
          </Text>
        </View>
      );
    }
    return null;
  };
  