import {NativeModules, Platform} from 'react-native';
import { getRootTag } from '../AppState/RootTagHolder';


if (NativeModules.SelfDriveReactModule) {
    const launchProfile = NativeModules.SelfDriveReactModule.launchProfileVerification;
    NativeModules.SelfDriveReactModule.launchProfileVerification = () => {
      if (Platform.OS === 'ios') {
        const reactTag = getRootTag();
        launchProfile(reactTag);
      } else {
        launchProfile();
      }
    };
  }

  if (NativeModules.SelfDriveReactModule) {
    const openCL = NativeModules.SelfDriveReactModule.openChecklist;
    NativeModules.SelfDriveReactModule.openChecklist = (bookingId, careNumber) => {
      if (Platform.OS === 'ios') {
        const reactTag = getRootTag();
        openCL(bookingId, careNumber, reactTag);
      } else {
        openCL(bookingId, careNumber);
      }
    };
  }

export default NativeModules.SelfDriveReactModule;
