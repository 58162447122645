import { StyleSheet } from 'react-native';
import { colors, fonts } from '@mmt/legacy-commons/Styles/globalStyles';

const SubmitRefundRequestScreen = StyleSheet.create({
  backArrowWrapper: {
    width: 24,
    height: 24,
    marginLeft: -4,
    marginRight: 4,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  backArrowStyle: {
    width: 12,
    height: 12,
  },
  title: {
    color: colors.black,
    marginBottom: 30,
    lineHeight: 32,
  },
  innerWrapper: {
    marginTop: 20,
    borderTopColor: '#e7e7e7',
    paddingTop: 20,
    borderTopWidth: 1,
  },
  processCardWrapper: {
    marginBottom: 30,
  },
  headingTxt: {
    color: colors.black,
    marginBottom: 25,
  },
  processRow: {
    paddingLeft: 16,
  },
  refundProcessRow: {
    borderLeftWidth: 2,
    borderLeftColor: '#FFE2B5',
    paddingLeft: 32,
    flexDirection: 'row',
    paddingBottom: 30,
  },
  borderLstItem: {
    borderLeftWidth: 0,
    borderLeftColor: 'transparent',
    paddingBottom: 0,
  },
  circle: {
    width: 32,
    height: 32,
    backgroundColor: '#fff',
    borderRadius: 50,
    position: 'absolute',
    left: 0,
    zIndex: 111,
    top: -8,
    alignItems: 'center',
    justifyContent: 'center',
  },
  circleInner: {
    width: 32,
    height: 32,
    backgroundColor: '#ffedd1',
    borderWidth: 1,
    borderColor: '#FFE2B5',
    borderRadius: 30,
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleStyle: {
    color: colors.black,
    fontFamily: fonts.bold,
    marginBottom: 3,
  },
  circleCounter: {
    color: '#CF8100',
  },
  descStyle: {
    color: colors.defaultTextColor,
    fontFamily: fonts.regular,
    fontSize: 12,
  },
  defaultText: {
    color: colors.defaultTextColor,
  },
  flex1: {
    flex: 1,
  },
  moreDtlsSection: {
    paddingHorizontal: 12,
    paddingVertical: 8,
    backgroundColor: '#ffedd1',
    borderRadius: 4,
  },
  infoTxtColor: {
    color: '#cf8100',
    fontFamily: fonts.regular,
    lineHeight: 20,
  },
  infoTxtBold: {
    fontFamily: fonts.bold,
  },
});

export default SubmitRefundRequestScreen;
