import BasePage from '../../../Common/PostSalesBasePage';
import NoInternetView from '@mmt/legacy-commons/Common/Components/Error/NoInternetView';
import ProgressView from '../../../Common/ProgressView/ProgressView';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import SpecialClaimTrackingHelper from '../Helpers/SpecialClaimTrackingHelper';
import React from 'react';

const intlLob = ['LOB02520', 'LOB02720', 'LOB01520'];
class SpecialClaimBaseClass extends BasePage {
  static navigationOptions = {
    header: null,
  };

  constructor(props, routerKeyName, pageName) {
    super(props, routerKeyName);
    this.pageName = pageName;
    this.state = {
      viewState: '',
    };
    this.trackingPageName = '';
  }
  componentDidMount() {
    super.componentDidMount();
  }

  renderProgressView = (message) => <ProgressView message={message} />;
  renderNoNetworkView = () => (
    <NoInternetView
      onRetry={() => {
        this.setState({ viewState: ViewState.LOADING });
        this.fetchData();
      }}
    />
  );

  trackPageLoad(response, specialClaimData, bookingState) {
    this.trackingPageName = this.isIntlFlightBooking(response.lobCode)
      ? `Mob:Customer support:Intl Flight:${this.pageName}`
      : `Mob:customer support:Dom Flight:${this.pageName}`;
    SpecialClaimTrackingHelper.trackLoadEvent(
      this.trackingPageName,
      response,
      specialClaimData,
      bookingState,
    );
  }
  trackError() {
    SpecialClaimTrackingHelper.trackErrorEvent(`Mob:Customer support:Flight:${this.pageName}`);
  }

  trackClickEvent(response, otherData = {}) {
    this.trackingPageName = this.isIntlFlightBooking(response.lobCode)
      ? `Mob:Customer support:Intl Flight:${this.pageName}`
      : `Mob:customer support:Dom Flight:${this.pageName}`;
    SpecialClaimTrackingHelper.trackClickEvent(this.trackingPageName, 'MI_FLT_SClaim_Yes', otherData);
  }

  isIntlFlightBooking = (lobCode) => intlLob.indexOf(lobCode) !== -1;

  fetchData() {
    // to be implemented by child classes
  }

  renderErrorView = () => (
    <ErrorView
      showRetry
      onRetry={() => {
        this.onRetryClicked();
        this.trackError();
      }}
    />
  );

  onRetryClicked = () => {
    this.setState({ viewState: ViewState.LOADING });
    this.fetchData();
  };
}
export default SpecialClaimBaseClass;
