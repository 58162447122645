import React, { useEffect } from 'react';
import { View, Text, TouchableOpacity, Image, FlatList } from 'react-native';

import { BG_BLUE_RIGHT_ARROW } from '../../../../../FlightImageConstants';
import createStyles from './reasonSelectionCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import SpellingCorrectionSection from './ReasonsExampleComponents/SpellingCorrectionSection';
import FlightBookingTrackingHelper from '../../../../../modules/details/helper/FlightBookingTrackingHelper';
import { getFont } from '../../../../../../PostSalesStyles';
import { useTheme } from '../../../../../../theme/theme.context';

const ReasonSelection = ({ data, onContinueClicked, onClose, responseJson }) => {
  const { header, subHeader, nameCorrectionRulesList } = data;

  const { psTheme: theme } = useTheme();
  const { fsStyle, ...fonts } = getFont(true);
  const styles = createStyles(theme, fonts);

  useEffect(() => {
    FlightBookingTrackingHelper.trackLoadEvent(
      'Special request: Name Change pop up reason selection',
      responseJson,
      'nameChange',
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnContinueClicked = (item) => {
    FlightBookingTrackingHelper.trackNameCorrectionBtnClick(
      'Special request: Name Change pop up reason selection',
      item.ruleNameTitle,
      responseJson,
    );
    onContinueClicked(item);
  };

  const renderNameChangeReasonsCTAs = ({ item, index }) => {
    const lastItem = index === nameCorrectionRulesList.length - 1 ? 'lastItemStyle' : '';
    return (
      <TouchableOpacity
        style={[styles.listWrapper, styles[lastItem]]}
        onPress={() => handleOnContinueClicked(item)}
      >
        <View style={styles.listTopWrapper}>
          <View style={[AtomicCss.flex1, AtomicCss.flexRow]}>
            {!!item.iconUrl && <Image style={styles.iconStyle} source={{ uri: item.iconUrl }} />}
            <View style={[AtomicCss.flex1]}>
              <Text style={styles.titleTxt}>{item.ruleNameTitle}</Text>
              <Text style={styles.desc}>{item.ruleNameSubtitle}</Text>
              <View style={{ paddingTop: 10 }} />
              <SpellingCorrectionSection ruleId={item.ruleId} />
            </View>
          </View>
          <Image style={styles.arrowStyle} source={BG_BLUE_RIGHT_ARROW} />
        </View>
      </TouchableOpacity>
    );
  };

  return (
    <View style={styles.reasonWrapper}>
      <View style={styles.fixedWrapper}>
        <Text style={styles.title}>{header}</Text>
        <Text
          style={[
            fonts.regularFontFamily,
            fsStyle.font14,
            AtomicCss.defaultText,
            AtomicCss.lineHeight20,
          ]}
        >
          {subHeader}
        </Text>
      </View>
      <FlatList
        showsVerticalScrollIndicator={false}
        data={nameCorrectionRulesList}
        renderItem={renderNameChangeReasonsCTAs}
        keyExtractor={(i) => i.ruleId}
      />
    </View>
  );
};

export default ReasonSelection;
