import { colors } from '@mmt/legacy-commons/Styles/globalStyles';

export const SLIDE_HEIGHT = 50;
const ACTIVE_BLOCK_BORDER_WIDTH = 1;

const styles = {
  wheelPickerWrapper: {
    backgroundColor: colors.white,
    elevation: 2,
    zIndex: 2,
    shadowColor: '#330000',
    shadowOpacity: 0.3,
    shadowRadius: 2,
    shadowOffset: {
      width: 0,
      height: 2
    },
    height: 200
  },
  pickerCol: {
    height: SLIDE_HEIGHT,
    alignItems: 'center',
    justifyContent: 'center'
  },
  wheelPickerBody: {
    height: 150,
    position: "relative",
    marginHorizontal: 20
  },
  pickerColTextActive: {
    color: colors.azure,
    fontSize: 18
  },
  activeBlock: {
    position: "absolute",
    width: "100%",
    height: SLIDE_HEIGHT - (ACTIVE_BLOCK_BORDER_WIDTH * 2),
    top: SLIDE_HEIGHT,
    borderTopWidth: 1,
    borderTopColor: colors.cabsGreyBg,
    borderBottomWidth: 1,
    borderBottomColor: colors.cabsGreyBg
  },
  wheelPickerFooter: {
    borderTopWidth: 1,
    borderTopColor: "#e1e7ee",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingHorizontal: 25,
    paddingVertical: 10
  },
  activeColStyle: {
    fontSize: 18,
    color: colors.black
  },
  activeSlideNumber: {
    position: 'absolute',
    left: 20,
    top: -30
  },
  widgetTitle: {
    padding: 10,
    paddingLeft: 15
  },
  dashWrapper: {
    paddingHorizontal: 10,
    justifyContent: "center",
    alignItems: 'center'
  },
  dashText: {
    color: colors.azure,
    fontSize: 18,
    textAlign: "center"
  }
};

export default styles;
