import CabBookingDetailsConstant from '../../../CabBookingDetailsConstant';

export const getTopStatusStyle = (
  topstatusCard,
  styles,
  headerShadow = true,
  themecolor,
	showCabTrackerOverlay,
) => {
  const headerStyle = [styles.headerWrapper];
  if (headerShadow) {
    headerStyle.push(styles.headerShadow);
  }
  let headerBg;
  let headerBgText;
  let crossIconColor;
  let downloadIconStyle = [styles.downloadIcon];
  let shareIconStyle = [styles.shareIcon];
  let backGroundColor;
  let statusStyleColor;
  switch (topstatusCard) {
    case CabBookingDetailsConstant.TOP_STATUS_CARD_PREJOURNEY_DETAILS_UNAVAIL:
    case CabBookingDetailsConstant.TOP_STATUS_CARD_PREJOURNEY_DETAILS_AVAIL:
    case CabBookingDetailsConstant.TOP_STATUS_CARD_DEFAULT_CONFIRMED:
    case CabBookingDetailsConstant.TOP_STATUS_CARD_POST_PICKUP_DETAILS_AVAIL:
    case CabBookingDetailsConstant.TOP_STAUTUS_LIVETRACKING_ENABLED_POST_PICKUP:
    case CabBookingDetailsConstant.TOP_STATUS_LIVE_TRACKING_AFTER_PRE_JOURNEY:
    case CabBookingDetailsConstant.TOP_STATUS_KIOSK_BOOKING:
    case CabBookingDetailsConstant.ONTRIP:
    case CabBookingDetailsConstant.TOP_STATUS_KIOSK_DRIVER_NOT_ASSIGNED:
      headerBg = showCabTrackerOverlay ? styles.mapGrey : styles.lightGreen;
      statusStyleColor = styles.upComingBgColor;
      break;
    case CabBookingDetailsConstant.TOP_STATUS_CARD_COMPLETTION_OF_TRIP_TO_FOREVER:
    case CabBookingDetailsConstant.TOP_STATUS_PRE_ALIGHT_COMPLETED_CARD:
      headerBg = styles.lightGrey;
      headerBgText = styles.whiteText;
      crossIconColor = styles.whiteCrossIcon;
      statusStyleColor = styles.completedBgColor;
      break;
    case CabBookingDetailsConstant.REFUNDED:
    case CabBookingDetailsConstant.TOP_STATUS_ADDRESS_PENDING:
    case CabBookingDetailsConstant.TOP_STATUS_CARD_AFTER_REFUND_DONE:
    case CabBookingDetailsConstant.TOP_STATUS_DEFAULT_MODIFIED:
    case CabBookingDetailsConstant.TOP_STATUS_CARD_CAN_INIT_TO_REFUND_DONE:
    case CabBookingDetailsConstant.TOP_STATUS_LONG_TAIL_NO_CAB_AVAILABLE:
      headerBg = styles.lightYellow;
      downloadIconStyle.push({ tintColor: themecolor.black22 });
      shareIconStyle.push({ tintColor: themecolor.black22 });
      break;
    case CabBookingDetailsConstant.TOP_STATUS_CARD_LOST_FAILED_BOOKING_CANCELLED:
    case CabBookingDetailsConstant.TOP_STATUS_CARD_LOST_FAILED_BOOKING:
      headerBg = styles.lightRed;
      downloadIconStyle.push({ tintColor: themecolor.black22 });
      shareIconStyle.push({ tintColor: themecolor.black22 });
      break;
    case CabBookingDetailsConstant.TOP_STATUS_LONG_TAI_VENDOR_ACCEPTED:
    case CabBookingDetailsConstant.TOP_STATUS_LONG_TAIL_USER_CONFIRMATION_PENDING:
    case CabBookingDetailsConstant.TOP_STATUS_LONG_TAIL_VENDOR_PENDING:
      headerBg = styles.darkSkyBlue;
      downloadIconStyle.push({ tintColor: themecolor.black22 });
      shareIconStyle.push({ tintColor: themecolor.black22 });
      break;
    case CabBookingDetailsConstant.TOP_STATUS_NOT_BOARDED:
      headerBg = styles.lightYellow;
      downloadIconStyle.push({ tintColor: themecolor.black22 });
      shareIconStyle.push({ tintColor: themecolor.black22 });
      statusStyleColor = styles.notBoarded;
      break;
    default:
      headerBg = styles.lightGreen;
      statusStyleColor = styles.upComingBgColor;
      break;
  }
  return {
    headerStyle,
    headerBg,
    headerBgText,
    crossIconColor,
    downloadIconStyle,
    shareIconStyle,
    statusStyleColor,
  };
};
