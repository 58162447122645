import React, { Component } from 'react';
import { Image, Text, TouchableOpacity, View } from 'react-native';
import PropTypes from 'prop-types';
import styles from './MetroReviewRefundChargeCss';
import CommonStyle from '@mmt/legacy-commons/Styles/AtomicCss';
import { getImageIcon } from '../../MetroBookingDetailUtil';
import {addRupeeSign} from '../../../Common/commonUtil';

export const REFUND_BREAKUP = {
  SHOW: 'View Breakup',
  HIDE: 'Hide Breakup'
};

export default class DeductionBreakup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fareBreakupDetail: true
    };
  }

  toggleBreakup = () => {
    this.setState({
      fareBreakupDetail: !this.state.fareBreakupDetail
    });
  };

  render() {
    const activeDtls = this.state.fareBreakupDetail ? REFUND_BREAKUP.HIDE : REFUND_BREAKUP.SHOW;
    return (<View style={styles.DeductionBreakUpWrapper}>
      <View style={[styles.fareBreakupHeading, CommonStyle.marginBottom10]}>
        <View style={CommonStyle.flexRow}>
          <Text
            style={[styles.fbHeadingTxt, { color: this.props.deductions.lbl.color }]}
          >{this.props.deductions.lbl.text}
          </Text>
          <TouchableOpacity onPress={this.toggleBreakup} activeOpacity={0.5}>
            <Text style={styles.linkTxt}>{activeDtls}</Text>
          </TouchableOpacity>
        </View>
        <Text
          style={[styles.totalPaidPriceTxt, { color: this.props.deductions.lbl.color }]}
        >{"- " +addRupeeSign(this.props.deductions.lbl.value)}
        </Text>
      </View>
      {this.state.fareBreakupDetail && this.addDeductionBreakup(this.props.deductions.pricingComponents)}
      {/*{this.getUncancelledFare()}*/}
      <View style={[styles.fareBreakupDtlsWrapper]}>
        <View style={[styles.fareBrkupRow, styles.totalRefund]}>
          <Text
            style={[styles.totalRefundTxt, { color: this.props.refundAmount.color }]}
          >{this.props.refundAmount.text}
          </Text>
          <Text
            style={[styles.totalRefundTxt, { color: this.props.refundAmount.color }]}
          >{addRupeeSign(this.props.refundAmount.value)}
          </Text>
        </View>
      </View>
      {this.getRefundAttributeMessage()}
    </View>);
  }

  addDeductionBreakup = pricingComponent => pricingComponent.map((pricingBreakup, index) => {
    const arrayLength = pricingComponent.length;
    return (
      <View
        key={index}
        style={arrayLength === index + 1 ? { marginBottom: 0 } : { marginBottom: 20 }}
      >
        {this.addDeductionComponent(pricingBreakup.components)}
      </View>
    );
  });

  addDeductionComponent = components => components.map(component => (
    <View
      key={component.desc}
      style={styles.fareBrkupRow}
    >
      <View style={[CommonStyle.flexRow, styles.descLeftWrap]}>
        <Image style={styles.dbIconSm} source={getImageIcon(component.img)} />
        <Text style={styles.fareBrkupTxt}>{component.desc}</Text>
      </View>
      <View style={[CommonStyle.flexRow, CommonStyle.alignSelfFlexEnd, styles.priceText]}>
        {
          component.showSlasher &&
          <Text
            style={[styles.fareBrkupTxtPrice, styles.lineThrough, styles.priceGap]}
          >{component.slashedValue}
          </Text>
        }
        <Text style={[styles.fareBrkupTxtPrice]}>{addRupeeSign(component.actualValue)}</Text>
      </View>
    </View>
  ));

  getRefundAttributeMessage = () => {
    const views = [];
    this.props.messages.forEach((attribute) => {
      if (attribute) {
        const attributeObj = attribute;
        const imageIcon = getImageIcon(attributeObj.attributImg);
        views.push(<View
          key={attributeObj.attributSubTitle}
          style={CommonStyle.flexRow}
        >
          {imageIcon && <Image
            style={styles.redInfoIconSm}
            source={imageIcon}
          />}
          <Text
            style={[styles.refundbottomDisc, { color: attributeObj.attributSubTitleColor }]}
          >{attributeObj.attributSubTitle}
          </Text>
        </View>);
      }
    });
    return views;
  }
}

DeductionBreakup.propTypes = {
  deductions: PropTypes.object.isRequired,
  refundAmount: PropTypes.object.isRequired,
  messages: PropTypes.array.isRequired
};

