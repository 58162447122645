import React, {useState, useEffect} from 'react';
import { View, TouchableOpacity, Keyboard } from 'react-native';
import getStyles from './IssueAtHotelOverlayCss';
import { ISSUE_AT_HOTEL_OVERLAY } from '../../../PostSalesConstant';
import IssueSelection from './IssueSelection';
import IssueL2Selection from './IssueL2Selection';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const IssueAtHotelOverlay = (props) => {
    const { ...fonts } = getFont(true);
    const styles = getStyles(fonts);
    const [bottom, setBottom] = useState(0);
    const [config, setConfig] = useState('');
    const {bhfBanner} = props.responseJson || {};
    const mydeskLobName = bhfBanner && bhfBanner.mydeskLobName;

    const getIssueL2List = () => {
      const {bhfBanner} = props.responseJson || {};
      if(bhfBanner['showCheckInDeniedFlow']){
        return bhfBanner['checkInDeniedOptions'] || [];
      }
      return [];
    }

    const showCallbackOverlayContent = () => {
        switch(props.component) {
            case ISSUE_AT_HOTEL_OVERLAY.ISSUE_SELECTION:
                return (
                    <IssueSelection
                            handleIssueAtHotelCard={props.handleIssueAtHotelCard}
                            bookingId={props.bookingId}
                            updateHotelOverlayType={props.updateHotelOverlayType}
                            setConfig={setConfig}
                            pageName={props.pageName}
                    />
                );
            case ISSUE_AT_HOTEL_OVERLAY.ISSUE_L2_SELECTION:
                return (
                    <IssueL2Selection
                        updateHotelOverlayType={props.updateHotelOverlayType}
                        issueL2List={getIssueL2List()}
                        bookingId={props.bookingId}
                        setConfig={setConfig}
                        mydeskLobName={mydeskLobName}
                    />
                );
            default:
                return null;
        }
    }

    const _keyboardDidShow = (e) => {
        setBottom(e.endCoordinates.height);
    }

    const _keyboardDidHide = (e) => {
        setBottom(0);
    }

    useEffect(() => {
        const keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', _keyboardDidShow);
        const keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', _keyboardDidHide);
        return () => {
            keyboardDidShowListener.remove();
            keyboardDidHideListener.remove();
        }
    }, []);

    return (
        <View style={[styles.overlayWrapper]}>
            <TouchableOpacity onPress={props.handleCloseOverlay} activeOpacity={1} style={styles.overlay}></TouchableOpacity>
            <View style={[styles.overlayContent, {bottom: bottom}]}>
                {showCallbackOverlayContent()}
            </View>
        </View>
    );
}

export default IssueAtHotelOverlay;
