import { themeColors } from '../../../PostSalesStyles';

export const colors = {
  Visa_blueStartColor: themeColors.Visa_blueStartColor,
  Visa_blueEndColor: themeColors.Visa_blueEndColor,

  Visa_OrangeStartColor: themeColors.Visa_OrangeStartColor,
  Visa_OrangeEndColor: themeColors.Visa_OrangeEndColor,

  Visa_GreenStartColor: themeColors.Visa_GreenStartColor,
  Visa_GreenEndColor: themeColors.Visa_GreenEndColor,


  Visa_purpleStartColor: themeColors.Visa_purpleStartColor,
  Visa_purpleEndColor: themeColors.Visa_purpleEndColor,

  Visa_blackStartColor: themeColors.Visa_blackStartColor,
  Visa_blackEndColor: themeColors.Visa_blackEndColor,

  Visa_pinkStartColor: themeColors.Visa_pinkStartColor,
  Visa_pinkEndColor: themeColors.Visa_pinkEndColor,

  Visa_YellowStartColor: themeColors.Visa_YellowStartColor,
  Visa_YellowEndColor: themeColors.Visa_YellowEndColor,

  Visa_greyPinkStartColor: themeColors.Visa_greyPinkStartColor,
  Visa_GreyPinkEndColor: themeColors.Visa_GreyPinkEndColor,
  Visa_lightblueStartColor: themeColors.Visa_lightblueStartColor,
  Visa_lightblueendColor: themeColors.Visa_lightblueendColor
};
