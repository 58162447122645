import React from 'react';
import { Text, View } from 'react-native';
import PropTypes from 'prop-types';
import getStyles from './styles';
import { getFont } from '../../../../PostSalesStyles';
import { useTheme } from '../../../../theme/theme.context';
import isEmpty from 'lodash/isEmpty';

const Breadcrumb = ({data, activeStep, isCorporateBooking}) => {
  const { psTheme: theme } = useTheme();
  const fonts = getFont();
  const styles = getStyles(theme, fonts);
  return (
    <View style={styles.steps}>
      {!isEmpty(data) && data.map((step, index) => {
        const activeStyle = index <= activeStep ? 'Active' : '';
        return (
          <View key={index} style={styles.stepItem}>
            <View
              style={[
                styles.stepNumberWrapper,
                styles['stepNumberWrapper' + activeStyle],
                isCorporateBooking && activeStyle === 'Active'
                  ? styles.stepNumberWrapperActiveCorp
                  : {},
              ]}
            >
              <Text style={styles.stepNumber}>{index + 1}</Text>
            </View>
            <Text
              style={[
                styles.stepName,
                styles['stepName' + activeStyle],
                isCorporateBooking && activeStyle === 'Active'
                  ? styles.stepNameActiveCorp
                  : {},
              ]}
            numberOfLines={1}
            >
              {step}
            </Text>
          </View>
        );
      })}
    </View>
  );
};
Breadcrumb.propTypes = {
  activeStep: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired,
};

export default Breadcrumb;
