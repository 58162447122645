import React from 'react';
import Actions from '../../navigation/postSalesNavigation';
import PropTypes from 'prop-types';
import {Image, ScrollView, Text, View} from 'react-native';
import styles from '../styles/CabMamiCss';
import CommonHeader from '@mmt/legacy-commons/Common/Components/Header/CommonHeader';
import CabBookingTrackingHelper from '../CabBookingTrackingHelper';
import {commonheaderIcon, cancelIcon2} from "../CabImageConstants";

class CabTermsAndConditions extends React.Component {
  constructor(props) {
    super(props);
    this.tnc = this.props.response.productinclusion.tnC;
  }

  render() {
    return (
      <View style={[styles.whiteContainer, styles.transperantBgrnd]}>
        <CommonHeader
          headerText="Important information"
          imgSrc={commonheaderIcon}
          backPressHandler={this.onBackPress}
        />
        <ScrollView>
          <View style={styles.PolicyDetailsContainer}>
            {this.showTnC(this.tnc)}
          </View>
        </ScrollView>
      </View>
    );
  }
  componentDidMount() {
    CabBookingTrackingHelper.trackLoadEvent('Cab Details: CabTermsAndConditions', this.props.response);
  }

  showTnC(tnc) {
    const views = [];
    tnc.forEach((tncObject) => {
      this.createTnCView(views, tncObject);
    });
    return views;
  }

  createTnCView(views, tncObject) {
    views.push(<View style={[styles.mar15Bt, styles.flexRow]} key={tncObject.lineNo}>
      <View style={styles.ItemDisc} />
      <Text>{tncObject.tnc}</Text>
               </View>);
  }
    onBackPress = () => {
      Actions.pop();
    }
}
CabTermsAndConditions.propTypes = {
  response: PropTypes.object.isRequired
};
export default CabTermsAndConditions;
