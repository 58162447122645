import { StyleSheet } from 'react-native';

export default (theme, fonts) => StyleSheet.create({
  footer: {
    backgroundColor: 'rgba(0,0,0,0.9)',
    alignSelf: 'flex-end',
    justifyContent: 'space-between',
    flexDirection: 'row',
    position: 'absolute',
    width: '100%',
    bottom: 0,
    left: 0,
    zIndex: 2,
  },
  footerLeft: {
    flex: 1,
    justifyContent: 'space-around',
    flexDirection: 'row',
  },
  sortLabel: {
    color: theme.color.grey31,
    fontSize: fonts.fontSize.font12,
    ...fonts.mediumFontFamily,
    textAlign: 'center',
  },
  whiteText: {
    color: theme.color.white,
  },
  sortItem: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    padding: 10,
    borderRightColor: theme.color.lightGrey1,
    borderRightWidth: 1,
  },
  sortIcon: {
    width: 16,
    height: 16,
  },
  footerRight: {
    backgroundColor: theme.color.yellow,
    borderRightColor: theme.color.yellow,
  },
  FooterActiveDot: {
    backgroundColor: theme.color.grey22,
    width: 4,
    height: 4,
    borderRadius: 10,
    position: 'absolute',
    top: 7,
  },
  FooterLeftFilterWrapper: {
    flexDirection: 'row',
    width: '75%',
  },
  FooterTabWidth: {
    flex: 1,
    position: 'relative',
  },
});
