import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DeviceEventEmitter, Image, Platform, Text, TouchableOpacity, View } from 'react-native';
import FlightBookingModule from '@mmt/legacy-commons/Native/FlightBookingModule';
import { getDateInFormat } from 'core-ui-lib/flight/common/ODC/helper';
import {
  getFinalSegment,
  ifNoDateChangePresent,
  getCurentMonthAndYear,
  getCurrentDate,
  getCurrentDay,
  checkFlightCalendarToOpen,
  getFlightCalendarParams,
  getTotalPax,
} from '../../../../utils';
import { validationCardTypes } from '../../../../constants';
import phoneIcon from '@mmt/legacy-assets/src/phone1.webp';
import createStyles from './styles';
import { ONEWAY_ARROW_ICON2 } from '../../../../../../FlightImageConstants';
import { openDialer } from '../../../../../../../Common/commonUtil';
import { getStaticData } from '../../../../../../../staticData/staticData';
import { isEmpty } from 'lodash';
import BaseButton, { BUTTON_VARIANT } from '../../../../../../../Common/BaseButton';
import { ThemeContext } from '../../../../../../../theme/theme.context';
import { getFont } from '../../../../../../../PostSalesStyles';
import DateChangeTrackingHelper from '../../../../analytics/DateChangeTrackingHelper';
import { FLIGHT_THROTTLE_OBJECT } from 'apps/post-sales/src/flights/modules/details/FlightBookingDetailsConstant';

const CALENDAR_EVENT_RECEIVED = 'calEventReceived';

export default class PickDatesCard extends Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);
    this.state = {
      departureDate: '',
      segmentKey: '',
    };
    this.response = this.props.response;
    this.travelCityList = [];
    if (this.props.dateChangeSegmentGroup.intentRef) {
      this.travelCityList = this.props.dateChangeSegmentGroup.intentRef.split('-');
    }
    this.calenderEventListener = null;
  }

  componentDidUpdate(prevProps) {
    if (
      Platform.OS === 'web' &&
      prevProps.webSelectedDate !== this.props.webSelectedDate &&
      this.props.webSelectedDate
    ) {
      this._refreshCalendarData({
        calDepartureDate: this.props.webSelectedDate.departureDate,
        calReferencekey: this.response.segmentLineNo,
      });
    }
  }

  render() {
    const corp = this.props.isCorporateBooking;
    const { submitRequestText, clearText, pickDateCardText ,selectDateToastText} = getStaticData();
    const styles = createStyles(this.context.psTheme, getFont(true));

    const {
      tripNotAllowed,
      tripNotAllowedMsg,
      odcValidation,
      segmentDetails,
      segmentMap,
      noChangeObj,
    } = this.props;
    const { segmentKey, departureDate } = this.state;
    let odcValidationSegment = null;
    if (
      odcValidation &&
      odcValidation.status.toLowerCase() === 'success' &&
      odcValidation.segments
    ) {
      odcValidationSegment = getFinalSegment(segmentDetails, odcValidation.segments);
    }
    const isValidationSegment = odcValidationSegment && !isEmpty(odcValidationSegment.cards);
    const isDisableSuffix = isValidationSegment ? 'disable' : '';
    if (isValidationSegment) {
      this.props.trackValidationError(this.response, odcValidationSegment);
    }
    return (
      <View
        key={this.response.segmentLineNo}
        style={[styles.cardWrapper, styles.pad10Top, styles.mar10Bt]}
      >
        <View style={styles.headerWrapper}>
          <View style={styles.destRow}>{this.renderTravelCityView(isValidationSegment, styles)}</View>
          <Text style={[styles.smallText, styles[`smallText${isDisableSuffix}`]]}>
            {getDateInFormat(this.props.dateChangeSegmentGroup.travelDateTime, 'dddd, DD MMM YYYY')}
            {getTotalPax(this.props.dateChangeSegmentGroup)}
          </Text>
          <Text style={[styles.smallText, styles[`smallText${isDisableSuffix}`]]}>
            {this.props.dateChangeSegmentGroup.flightDesc}
          </Text>
        </View>
        <View style={styles.cardBody}>
          {!tripNotAllowed && !isValidationSegment && (
            <View>
              <Text style={styles.dateLabel}>{pickDateCardText}</Text>
              <View style={styles.dtCrdWrapper}>
                <TouchableOpacity
                  style={styles.dateWrapper}
                  onPress={() => this._openFlightsCalender(this.response, selectDateToastText, corp)}
                >
                  <Text style={[styles.dateText, corp ? styles.smallBlueTextCorp : {}]}>
                    {' '}
                    {getCurrentDate(this.response, segmentKey, departureDate, segmentMap)}
                  </Text>
                  <View style={{ paddingTop: 5 }}>
                    <Text
                      style={[styles.smallBlueText, corp ? styles.smallBlueTextCorp : {}]}
                    >
                      {getCurentMonthAndYear(this.response, segmentKey, departureDate, segmentMap)}
                    </Text>
                    <Text
                      style={[styles.smallBlueText, corp ? styles.smallBlueTextCorp : {}]}
                    >
                      {getCurrentDay(this.response, segmentKey, departureDate, segmentMap)}
                    </Text>
                  </View>
                </TouchableOpacity>
                {getCurrentDate(this.response, segmentKey, departureDate, segmentMap) !== '...' && (
                  <TouchableOpacity
                    style={styles.clearWrapper}
                    onPress={() => this.clearDtCrd(this.response.segmentLineNo)}
                  >
                    <Text
                      style={[
                        styles.smallBlueText,
                        styles.clearBold,
                        corp ? styles.smallBlueTextCorp : {},
                      ]}
                    >
                      {clearText}
                    </Text>
                  </TouchableOpacity>
                )}
              </View>
            </View>
          )}
          {isValidationSegment && (
            <View>
              {odcValidationSegment.cards &&
                odcValidationSegment.cards.length > 0 &&
                odcValidationSegment.cards.map((card, index) => (
                  <View
                    style={[
                      styles.alreadyCancelledSction,
                      index === odcValidationSegment.cards.length - 1 ? styles.lastItem : '',
                    ]}
                  >
                    {index === 0 && (
                      <Text style={styles.title}>{odcValidationSegment.heading}</Text>
                    )}
                    {this.renderActions(odcValidation, card, odcValidationSegment, styles)}
                  </View>
                ))}
            </View>
          )}
          {tripNotAllowedMsg && !isValidationSegment && (
            <View>
              <Text style={styles.errorInfoTxt}>{tripNotAllowedMsg}</Text>
              {!isEmpty(odcValidation) && !isEmpty(odcValidation.submitData) && (
                <View style={styles.submitRequestWrapper}>
                  <BaseButton
                    text={submitRequestText}
                    variant={BUTTON_VARIANT.CAPSULE}
                    disabled={noChangeObj?.disabled}
                    clickHandler={() =>
                      this.props.onSubmit(
                        odcValidation.submitData.uniquePageId,
                        odcValidation.submitData.errorCode,
                      )
                    }
                    {...FLIGHT_THROTTLE_OBJECT}
                    shadow
                  />
                </View>
              )}
            </View>
          )}
        </View>
      </View>
    );
  }

  clearDtCrd = (segmentLineNo) => {
    this.setState({
      departureDate: '',
      segmentKey: '',
    });
    delete this.props.segmentMap[segmentLineNo];
    this.props.clearDate(segmentLineNo);
  };

  _refreshCalendarData = (calendarMap) => {
    if (calendarMap) {
      this.setState({
        departureDate: calendarMap.calDepartureDate,
        segmentKey: calendarMap.calReferencekey,
      });
      const segmentDetail = {};
      segmentDetail.departureDate = calendarMap.calDepartureDate;
      this.props.segmentMap[calendarMap.calReferencekey] = segmentDetail;
      this.props.selectedDates(
        this.props.segmentMap,
        calendarMap.calReferencekey,
        this.props.intentRefId,
      );
      if (this.calenderEventListener) {
        this.calenderEventListener.remove();
      }
    }
  };

  _openFlightsCalender = (flightSegment, selectDateToastText, corp = false) => {
    if (checkFlightCalendarToOpen(this.props.segmentMap, this.props.rtDiffPnr, flightSegment,selectDateToastText)) {
      DateChangeTrackingHelper.trackClickableEvent('datechangepickdate', 'selectonesector', this.props.odcPrevResponse, corp);
      DateChangeTrackingHelper.trackErrorEvent('datechangepickdate', 'selectonesector', undefined, corp);
      return;
    }
    const params = getFlightCalendarParams(
      flightSegment,
      this.state.departureDate,
      this.props.bookingDateTime,
    );
    DateChangeTrackingHelper.trackClickableEvent('datechangepickdate', 'openCalendar', this.props.odcPrevResponse, corp);
    if (Platform.OS === 'web') {
      this.props.showCalendarModel();
    } else {
      FlightBookingModule.openCalendar(params);
      this.calenderEventListener = DeviceEventEmitter.addListener(CALENDAR_EVENT_RECEIVED, this._refreshCalendarData);
    }
  };
  renderTravelCityView(isValidationSegment, styles) {
    const views = [];
    const { tripNotAllowed } = this.props;
    const notAllowedStyle = tripNotAllowed ? styles.notAllowed : {};
    const isDisableSuffix = isValidationSegment ? 'disable' : '';
    views.push(
      <Text style={[styles.cityText, notAllowedStyle, styles[`cityText${isDisableSuffix}`]]}>
        {this.travelCityList[0]}
      </Text>,
    );
    for (let i = 1; i < this.travelCityList.length; i++) {
      views.push(
        <Image
          style={[styles.arrowIcon, styles[`arrowIcon${isDisableSuffix}`]]}
          source={ONEWAY_ARROW_ICON2}
        />,
      );
      views.push(
        <Text style={[styles.cityText, notAllowedStyle, styles[`cityText${isDisableSuffix}`]]}>
          {this.travelCityList[i]}
        </Text>,
      );
    }
    return views;
  }

  renderActions(odcValidation, card, odcValidationSegment, styles) {
    const views = [];
    switch (card.cardName) {
      case validationCardTypes.SPECIAL_CLAIM:
        if (ifNoDateChangePresent(card) && odcValidationSegment) {
          views.push(<Text style={styles.desc}>{odcValidationSegment.description}</Text>);
        } else if (odcValidation.specialClaimDataList && odcValidationSegment) {
          views.push(<Text style={styles.desc}>{odcValidationSegment.description}</Text>);
          views.push(
            <TouchableOpacity
              onPress={() => {
                this.props.trackValidationClickEvent(this.props.odcPrevResponse, card.cardName);
                this.props.setPopUpOpen();
              }}
            >
              <Text style={styles.linkTxt}>{odcValidation.specialClaimData.clickableText}</Text>
            </TouchableOpacity>,
          );
        }
        break;
      case validationCardTypes.SUBMIT:
        if (odcValidation.submitData) {
          views.push(
            <Text style={styles.desc}>
              <Text style={styles.boldFont}>{odcValidation.submitData.heading} </Text>
              {odcValidation.submitData.description}
            </Text>,
          );
          views.push(
            <TouchableOpacity
              onPress={() => {
                this.props.trackValidationClickEvent(this.props.odcPrevResponse, card.cardName);
                this.props.onSubmit(
                  odcValidation.submitData.uniquePageId,
                  odcValidation.submitData.errorCode,
                );
              }}
            >
              <Text style={styles.linkTxt}>{odcValidation.submitData.clickTxt}</Text>
            </TouchableOpacity>,
          );
        }
        break;
      case validationCardTypes.CALL:
        if (odcValidation.callData) {
          views.push(
            <Text style={styles.desc}>
              <Text style={styles.boldFont}>{odcValidation.callData.heading} </Text>
              {odcValidation.callData.description}
            </Text>,
          );
          views.push(
            <View style={styles.destRow}>
              <TouchableOpacity
                style={styles.destRow}
                onPress={() => {
                  this.props.trackValidationClickEvent(this.props.odcPrevResponse, card.cardName);
                  this._openDialer(odcValidation.callData.number);
                }}
              >
                <Image style={[styles.phoneIcon]} source={phoneIcon} />
                <Text style={styles.linkTxt}>{odcValidation.callData.clickTxt}</Text>
              </TouchableOpacity>
            </View>,
          );
        }
        break;
      default:
        break;
    }
    return views;
  }

  _openDialer(contactNumber) {
    openDialer(contactNumber);
  }
}
PickDatesCard.propTypes = {
  response: PropTypes.object.isRequired,
  dateChangeSegmentGroup: PropTypes.object.isRequired,
  intentRefId: PropTypes.string.isRequired,
  bookingDateTime: PropTypes.string.isRequired,
};
