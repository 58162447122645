import { StyleSheet } from 'react-native';

export default (theme, fonts) => {
  const {color} = theme;
  return StyleSheet.create({
    container: {
      paddingHorizontal: 12,
      paddingTop: 16,
      paddingBottom: 12,
    },
    title: {
      ...fonts.regularFontFamily,
      fontSize: 14,
      color: color.lightTextColor
    },
    iconWrapper: {
      width: 26,
      height: 26,
      marginRight: 10,
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    contactIconStyle: {
      width: 24,
      height: 24,
    },
    detailsSection: {
      paddingLeft: 18,
      paddingRight: 0,
    },
    cusDetailsWrapper: {
      paddingHorizontal: 10,
      paddingVertical: 6,
      borderRadius: 4,
      borderWidth: 1,
      borderColor: color.lightGray,
      minWidth: '48%'
    },
    fullWidth: {
      minWidth: '100%',
    },
    heading: {
      ...fonts.blackFontFamily,
      color: color.black,
      fontSize: fonts.fontSize.font16
    },
  })
};
