import {getImagePath} from '../utils/PostSaleUtil';
//postsale
export const ERROR_ICON = getImagePath('ErrorIcon1.webp');
export const SELECTED_PAX_CHECKBOX = getImagePath('cancelledIcon.webp');
export const SINGLETICK = getImagePath('single-tick.webp');
export const SELECTED_CHECK_BOX = getImagePath('partialIcon.webp');
export const ONE_WAY_JOURNEY = getImagePath('oneway.webp');
export const TOP_STATUS_CONFIRMED_ICON = getImagePath('topstatusIcon1.webp');
export const TOP_STATUS_AFTER_TRIP_ICON = getImagePath('journeyover.webp');
export const REFUND_PROCESSED = getImagePath('refund_processed.webp');
export const GREEN_REFUND_ICON = getImagePath('ic_refundable_green.webp');
export const RED_REFUND_ICON = getImagePath('ic_refundable_red.webp');
export const BLACK_REFUND_ICON = getImagePath('ic_refundable_black.webp');
export const ZERO_CANCELLATION_ICON = getImagePath('zeroCancellationIcon.webp');
export const RED_INFO_ICON_SM_ICON = getImagePath('red_info.webp');

//common
export const HEADER_ICON = require('@mmt/legacy-assets/src/trip_header_back_icon.webp');
export const REFUND_ICON = require('@mmt/legacy-assets/src/refundIcon.webp');
export const DOUBLE_TICK_ICON = getImagePath('double-tick.webp');
export const SUCCESS_ICON = getImagePath('success-icon.webp');
export const CHKBOX_ICON_ACTIVE = require('@mmt/legacy-assets/src/chkboxactive.webp');
export const CHKBOX_ICON = require('@mmt/legacy-assets/src/chkboxactive.webp');
export const RIGHT_ARROW = require('@mmt/legacy-assets/src/Images/oneWayArrow.webp');
export const ACTIVE_TICK_ICON = require('@mmt/legacy-assets/src/Images/iconTick.webp');
export const DOUBLE_TICK = getImagePath('double-tick.webp');
export const WHITE_ARROW_ICON = require('@mmt/legacy-assets/src/white_backarrow.webp');
export const BLACK_ARROW_ICON = require('@mmt/legacy-assets/src/backArrowBlack.webp');
export const TOP_STATUS_CANCELLED_ICON = getImagePath('cancelled.webp');
export const TOP_STATUS_FAILED_ICON = getImagePath('ic_failed.webp');
export const IC_TICK = require('@mmt/legacy-assets/src/ic_tick.webp');
export const DOUBLE_TICK_1 = require('@mmt/legacy-assets/src/double_tick.webp');
export const DB_ICON_SM_ICON = require('@mmt/legacy-assets/src/dbIconSm.webp');







