import React, { useEffect, useState } from 'react';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import { getCommonHeaders } from '../utils/NetworkUtils';
import { ViewState } from '../PostSalesConstant';
import { getUserDetails } from '@mmt/legacy-commons/Native/UserSession/UserSessionModule';

export const usePostRequestApi = (url, request, bookingId, type, retry) => {
  const [viewState, setViewState] = useState(ViewState.LOADING);
  const [response, setResponse] = useState(undefined);
  useEffect(() => {
    (async () => {
      try {
        setViewState(ViewState.LOADING);
        const userInfo = await HotelBookingModule.getRequestHeader(bookingId, type);
        const userDetails = await getUserDetails();
        if (request && userDetails && userDetails.uuid) {
          request.userUUID = userDetails.uuid;
        }
        const apiResponse = await fetch(url, {
          method: 'POST',
          headers: await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo, {
            psLob: request && request.lobName,
          }),
          body: JSON.stringify(request),
        });
        if (apiResponse && apiResponse.ok) {
          const body = await apiResponse.json();
          if (body.success) {
            setViewState(ViewState.LOADED);
            setResponse(body);
          } else {
            setViewState(ViewState.ERROR);
          }
        } else {
          setViewState(ViewState.ERROR);
        }
      } catch (e) {
        setViewState(ViewState.ERROR);
      }
    })();
  }, [retry, request.pageName]);
  return [viewState, response];
};
