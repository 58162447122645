import React from 'react';
import { View, ScrollView, Text, TouchableOpacity, Keyboard } from 'react-native';
import _ from 'lodash';
import Actions from '../../../../../../../navigation/postSalesNavigation';
import FareChangeOverlay from '@mmt/legacy-commons/Common/Components/MIMABottomOverlay/FareChangeOverlay';
import OverlayMessage from '@mmt/legacy-commons/Common/Components/MIMABottomOverlay/OverlayMessage';
import Header from '../../../../components/Header';
import DarkFooter from '../../../../../../../Common/DarkFooter';
import styles from './customerViewCss.js';
import FlightInfoDtls from './components/FlightInfo'
import FareBreakUp from '../../../../components/FareBreakUp';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import InfoListCard from '../InfoListCard';

import FlightBookingModule from '@mmt/legacy-commons/Native/FlightBookingModule';
import {
  getCommonHeaders,
  getUserPlatform,
  FLIGHT_CORP_DATE_CHANGE_RAISE_REQUEST,
} from '../../../../../../../utils/NetworkUtils';
import { openErrorPage } from '../../../../../../../utils/PostSaleUtil';
import {DateChangeConstants, DcViewState} from '../../../../constants';
import RequestSubmit from '../../../../components/RequestSubmit';
import RescheduleOverlay from '../RescheduleOverlay';
import DateChangeBaseClass from '../../../../components/DateChangeBaseClass';
import DateChangeTrackingHelper from '../../../../analytics/DateChangeTrackingHelper';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import { isNetworkAvailable } from '@mmt/legacy-commons/Common/utils/AppUtils';
import { resetToFlightBookingDetail } from '../../../../../../FlightBookingUtil';
import { LOBNAMES } from '../../../../../../../PostSalesConstant';
import NoInternetView from '@mmt/legacy-commons/Common/Components/Error/NoInternetView';
import { getRaiseReviewRequest, getUserInfo } from '../../data/requestAdapter';
import { fetchRaiseRequest } from '../../data/api';
import UndoWebCheckInComp from '../../../../components/UndoWebCheckIn';

export default class CustomerView extends DateChangeBaseClass {
  static navigationOptions = {
    header: null,
  };
  constructor(props) {
    super(props, 'CorpReviewCustomerPage');
    this.state = {
      reasonRescheduleOverlay: false,
      holdResponse: null,
      viewState: DcViewState.SHOW_DETAIL,
      fareChangeOverlay: false,
      undoWebCheckInOverlay: false,
      action: '',
      errorMsg: undefined,
      errorNextStep: undefined,
      bottom: 0,
    };
    this.onContinueClicked = this.onContinueClicked.bind(this);
    this.onSkipClicked = this.onSkipClicked.bind(this);
    this.closeOverlay = this.closeOverlay.bind(this);
    this.closeFareChangeOverlay = this.closeFareChangeOverlay.bind(this);
    this.holdAPIErrorNextStep = undefined;
  }
  componentDidMount() {
    this.keyboardDidShowListener = Keyboard.addListener(
      'keyboardDidShow',
      this._keyboardDidShow.bind(this),
    );
    this.keyboardDidHideListener = Keyboard.addListener(
      'keyboardDidHide',
      this._keyboardDidHide.bind(this),
    );
  }

  componentWillUnmount() {
    this.keyboardDidShowListener.remove();
    this.keyboardDidHideListener.remove();
  }
  _keyboardDidShow(e) {
    this.setState({ bottom: e.endCoordinates.height });
  }
  _keyboardDidHide(e) {
    this.setState({ bottom: 0 });
  }

  closeFareChangeOverlay = () => {
    this.setState({
      fareChangeOverlay: !this.state.fareChangeOverlay,
    });
    DateChangeTrackingHelper.trackClickableEvent(`${this.props.pageName}fareupdate`, 'close', this.props.odcPreviewResponse, this.props.isCorporateBooking);
  };

  makePayment = (skipClicked, isUndoWebCheckInDone = false) => {
    const { undoWebCheckinInfo } = this.props.reviewDetail;
    const { bannerInfo } = undoWebCheckinInfo || {};

    if (!isUndoWebCheckInDone && bannerInfo) {
      this.toggleReasonRescheduleOverlay();
      this.toggleUndoWebcheckinOverlay(skipClicked);
      return;
    }
    let skipClickedPay = skipClicked;
    if (!skipClickedPay) {
      skipClickedPay = 'skip';
    }
    this.props.makeHoldCall(
      this.props.reviewDetail.bookingId,
      null,
      this.props.reviewDetail.crKey,
      this.props.reviewDetail.rKey,
      this.props.reviewDetail.corpPolicyInfo.pKey,
      skipClickedPay,
      this.setViewState,
      this.setHoldResponse,
      this.toggleReasonRescheduleOverlay,
    );
  };
  setHoldResponse = (holdResponse) => {
    this.setState({ holdResponse });
  };
  toggleReasonRescheduleOverlay = () => {
    this.setState({ reasonRescheduleOverlay: false });
  };
  setReasonRescheduleOverlay = () => {
    DateChangeTrackingHelper.trackClickableEvent(
      this.props.pageName,
      'ODCReview_Continue_Clicked',
      this.props.odcPreviewResponse,
      this.props.isCorporateBooking
    );
    this.setState({ reasonRescheduleOverlay: true });
  };

  onContinueClicked = (userComment, selectedReason) => {
    this.setState({ bottom: 0 });
    this.userComment = userComment;
    this.selectedReason = selectedReason;
    DateChangeTrackingHelper.trackClickableEvent(
      this.props.pageName,
      'ODCReview_Reason_selected',
      this.props.odcPreviewResponse,
      this.props.isCorporateBooking
    );
    DateChangeTrackingHelper.trackClickableEvent(
      this.props.pageName,
      'ODCReview_RequestApproval_Clicked',
      this.props.odcPreviewResponse,
      this.props.isCorporateBooking
    );
    this.raiseRequest(
      this.props.reviewDetail.bookingId,
      this.props.reviewDetail.crKey,
      this.props.reviewDetail.rKey,
      userComment,
      selectedReason,
      this.props.reviewDetail.corpPolicyInfo.pKey,
    );
  };

  undoWebcheckinHandler = () => {
    this.setState({
      popupName: 'undoWebCheckin',
    });
  };

  onSkipClicked = () => {
    DateChangeTrackingHelper.trackClickableEvent(
      this.props.pageName,
      'ODCReview_SkipApproval_Clicked',
      this.props.odcPreviewResponse,
      this.props.isCorporateBooking
    );
    this.makePayment();
  };

  payNow = () => {
    DateChangeTrackingHelper.trackClickableEvent(
      this.props.pageName,
      'ODCReview_PayNow_Clicked',
      this.props.odcPreviewResponse,
      this.props.isCorporateBooking,
    );
    this.makePayment('not_required');
  };

  raiseRequest = async (bookingId, crKey, rKey, userComment, selectedReason, policyKey) => {
    this.setState({
      viewState: DcViewState.RAISE_LOADING,
      reasonRescheduleOverlay: false,
    });
    try {
      const hasNetwork = await isNetworkAvailable();
      if (!hasNetwork) {
        this.setState({ viewState: DcViewState.NO_INTERNET });
        return;
      }
      const request = getRaiseReviewRequest(
        userComment,
        selectedReason,
        policyKey,
        crKey,
        rKey,
        bookingId,
        this.props,
      );
      const userInfo = await getUserInfo(bookingId, '', FLIGHT_CORP_DATE_CHANGE_RAISE_REQUEST, false);
      const body = await fetchRaiseRequest(
        userInfo,
        request,
        FLIGHT_CORP_DATE_CHANGE_RAISE_REQUEST,
      );
      if (body.errorPageData) {
        openErrorPage(
          body.errorPageData,
          this.props.onBackPressFromErrorPage,
          this.fetchData,
          body.bookingId,
          'datechange',
          Actions,
          { psLob: LOBNAMES.FLIGHT },
        );
        DateChangeTrackingHelper.trackErrorEvent(
          `${this.props.pageName}requestapproval`,
          'ODC_RequestApproval_failed',
          '',
          true,
        );
      }
      if (!body.success) {
        this.setState({
          viewState: DcViewState.RAISE_API_FAILURE_RESPONSE,
        });
        DateChangeTrackingHelper.trackErrorEvent(
          `${this.props.pageName}requestapproval`,
          'ODC_RequestApproval_failed',
          '',
          true,
        );
      } else {
        DateChangeTrackingHelper.trackLoadEvent(
          `${this.props.pageName}requestapproval`,
          body,
          true,
        );
        this.setState({
          viewState: DcViewState.RAISE_SUCCESS,
          responseMessage: body.message,
        });
      }
    } catch (e) {
      this.setState({
        viewState: DcViewState.RAISE_API_FAILURE_RESPONSE,
      });
      DateChangeTrackingHelper.trackErrorEvent(
        `${this.props.pageName}requestapproval`,
        'ODC_RequestApproval_failed',
        '',
        true,
      );
    }
  };

  closeOverlay() {
    this.setState({
      reasonRescheduleOverlay: !this.state.reasonRescheduleOverlay,
    });
  }

  toggleUndoWebcheckinOverlay = (action = '') => {
    this.setState({
      undoWebCheckInOverlay: !this.state.undoWebCheckInOverlay,
      action,
    });
  };

  getContentTitle = (header, description) => {
    const content = {};
    content.title = header;
    content.desc = description;
    return content;
  };

  renderPage() {
    const { fareBreakupData, corpPolicyInfo, undoWebCheckinInfo } = this.props.reviewDetail;
    const { totalFare } = fareBreakupData;
    const priceDtlsLabel = DateChangeConstants.DATE_CHANGE_CHARGES;
    let btnText = DateChangeConstants.PAY_NOW;
    if (corpPolicyInfo.approvalNeeded) {
      btnText = DateChangeConstants.CONTINUE;
    }
    return (
      <View style={styles.pageWrapper}>
        <Header
          title={DateChangeConstants.REVIEW_FLIGHT}
          onBackPress={() => Actions.pop()}
          type="review"
          headerData={{}}
        />
        <ScrollView ref="pageScroll" showsVerticalScrollIndicator={false} style={styles.pageScroll}>
          <FlightInfoDtls reviewDetail={this.props.reviewDetail} />
          <View style={[styles.whiteCard, styles.FareBkCard]}>
            <FareBreakUp fareBreakupData={this.props.reviewDetail.fareBreakupData} />
          </View>
          <View style={[styles.whiteCard, styles.ReviewCard, AtomicCss.marginBottom20]}>
            <InfoListCard importantInfos={this.props.reviewDetail.impInfo} />
          </View>
        </ScrollView>
        {!corpPolicyInfo.withinPolicy && (
          <View style={styles.outPolicy}>
            <Text style={styles.policyText}>{DateChangeConstants.OUT_OF_POLICY}</Text>
          </View>
        )}
        {!_.isEmpty(btnText) && !_.isEmpty(totalFare) && (
          <DarkFooter
            price={totalFare.value}
            footerInfoIcon={false}
            priceDtls={priceDtlsLabel}
            buttonLabel={btnText}
            clickHandler={
              corpPolicyInfo.approvalNeeded ? this.setReasonRescheduleOverlay : this.payNow
            }
            isCorporateBooking
          />
        )}
        {this.state.reasonRescheduleOverlay && (
          <View style={styles.overlayWrapper}>
            <TouchableOpacity
              onPress={() => this.closeOverlay()}
              activeOpacity={1}
              style={styles.overlay}
            />
            <View style={[styles.overlayContent, { bottom: this.state.bottom }]}>
              <ScrollView>
                <RescheduleOverlay
                  CloseOverlay={this.closeOverlay}
                  onContinueClicked={this.onContinueClicked}
                  onSkipClicked={this.onSkipClicked}
                  showSkipApproval={!corpPolicyInfo.blockSkipApproval}
                  RadioItem={corpPolicyInfo.rescheduleReasons}
                />
              </ScrollView>
            </View>
          </View>
        )}
        {this.state.fareChangeOverlay && (
          <OverlayMessage
            CloseOverlay={this.closeFareChangeOverlay}
            content={
              <FareChangeOverlay
                CloseOverlay={this.closeFareChangeOverlay}
                ContinuePress={this.props.openPaymentPage}
                content={this.getContentTitle(
                  this.state.holdResponse.fareChangeHeader,
                  this.state.holdResponse.fareChangeText,
                )}
                holdResponse={this.state.holdResponse}
                reviewResponse={this.props.reviewDetail}
                isCorporateBooking
              />
            }
          />
        )}
        {this.state.undoWebCheckInOverlay && (
          <UndoWebCheckInComp
            undoWebcheckinInfo={undoWebCheckinInfo}
            handleBottomOverlay={() => this.toggleUndoWebcheckinOverlay()}
            reviewInfo={this.props.reviewDetail}
            makePayment={() => this.makePayment(this.state.action, true)}
            odcPreviewResponse={this.props.odcPreviewResponse}
            odcSegmentGroup={this.props.odcSegmentGroup}
            isCorp
          />
        )}
      </View>
    );
  }

  renderRaiseAPIFailureView() {
    return (
      <ErrorView
        message={this.state.errorMsg}
        showRetry
        onRetry={() => {
          this.onRaiseRequestRetryClicked();
        }}
      />
    );
  }

  setFareChangeOverlay = () => {
    this.setState({
      fareChangeOverlay: !this.state.fareChangeOverlay,
    });
  };
  setViewState = (viewState) => {
    this.setState({
      viewState,
    });
  };

  onBacktoBooking = () => {
    Actions.pop();
  };
  onBacktoDetails = () => {
    resetToFlightBookingDetail(this.props.reviewDetail.bookingId, false);
  };
  renderRaiseRequestView = () => (
    <RequestSubmit onPressHandler={this.onBacktoDetails} message={this.state.responseMessage} />
  );
  renderNoNetworkView = () => (
    <View style={AtomicCss.flex1}>
      <NoInternetView
        onRetry={() => {
          this.setState({ viewState: DcViewState.LOADING });
          this.props.fetchData();
        }}
      />
    </View>
  );

  onRaiseRequestRetryClicked = () => {
    this.setState({
      viewState: DcViewState.RAISE_LOADING,
      reasonRescheduleOverlay: false,
    });
    this.raiseRequest(
      this.props.reviewDetail.bookingId,
      this.props.reviewDetail.crKey,
      this.props.reviewDetail.rKey,
      this.userComment,
      this.selectedReason,
      this.props.reviewDetail.corpPolicyInfo.pKey,
    );
  };

  renderErrorPage = () => {
    return (
      <ErrorView
        showRetry
        onRetry={() => {
          this.props.fetchData();
        }}
      />
    );
  };

  render() {
    return (
      <View style={AtomicCss.flex1}>
        {this.state.viewState === DcViewState.LOADING &&
          this.renderProgressView('Loading Review...')}
        {this.state.viewState === DcViewState.NO_INTERNET && this.renderNoNetworkView()}
        {this.state.viewState === DcViewState.ERROR && this.renderErrorPage()}
        {this.state.viewState === DcViewState.SHOW_DETAIL && this.renderPage()}
        {this.state.viewState === DcViewState.HOLD_SUCCESS &&
          this.props.renderPaymentPage(
            this.state.holdResponse,
            this.setFareChangeOverlay,
            this.setViewState,
          )}
        {this.state.viewState === DcViewState.HOLD_LOADING && this.renderProgressView('Loading...')}
        {this.state.viewState === DcViewState.HOLD_API_FAILURE_RESPONSE &&
          this.props.renderHoldAPIFailureView()}
        {this.state.viewState === DcViewState.RAISE_SUCCESS && this.renderRaiseRequestView()}
        {this.state.viewState === DcViewState.RAISE_LOADING &&
          this.renderProgressView('Loading...')}
        {this.state.viewState === DcViewState.RAISE_API_FAILURE_RESPONSE &&
          this.renderRaiseAPIFailureView()}
      </View>
    );
  }
}
