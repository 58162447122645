import React from 'react';
import {View, Text, Image} from 'react-native';
import styles from '../../CSS/VisaMamiCss';
import {getActionButton, getButtonStyle, isNotNullAndEmpty} from '../../../bus/utils/BusBookingDetailUtil';
import BusPriceCard from '../../../bus/components/BusPriceCard';
import PropTypes from 'prop-types';
import {handleClickEvent} from '../../Util/VisaUtil';
import {paymentIcon} from '../../Constant/VisaImageConstant';


class VisaPaymentCard extends React.Component {
    static navigationOptions = {
      header: null
    };

    render() {
      return (
        <View style={[styles.MamiCard, styles.mar10Bt]}>
          <View style={styles.CardContent}>
            <View style={styles.flexRow}>
              <Image style={styles.CardIcon} source={paymentIcon} />
              <View style={{}}>
                <Text style={[styles.visaPaymentCard]}>
                                TOTAL PAID
                </Text>
                <View style={[styles.flexRow, this.isEcouponApplied(this.props.paymentresponse.ecouponAmount) ? styles.showView : styles.hideView]}>
                  <Text style={[styles.visaTotalSaved]}>You saved &#8377; {this.props.paymentresponse.ecouponAmount}{' '}</Text>
                </View>
              </View>
            </View>
            <View style={styles.marginLeftAuto}>
              <Text style={[styles.visaReceivedAmount]}>&#8377; {this.props.paymentresponse.receivedAmount}{' '}</Text>
              <Text style={[styles.visaSellingPrice, this.isEcouponApplied(this.props.paymentresponse.ecouponAmount) ? styles.showView : styles.hideView]}>&#8377; {this.props.paymentresponse.totalCost}{' '}</Text>
            </View>
          </View>
          <View
            style={isNotNullAndEmpty(this.props.card.actionList) ? [getButtonStyle(this.props.card.actionList), styles.transperantBg] : styles.hideView}
          >
            {getActionButton(this.props.card, this.props.response, this.handleClick)}
          </View>
        </View>
      );
    }

    handleClick = (action) => {
      handleClickEvent(this.props.card, action, this.props.response, this.props.pageName);
    };


    isEcouponApplied(ecouponAmount) {
      return (ecouponAmount > 0);
    }
}

VisaPaymentCard.propTypes = {
  response: PropTypes.object.isRequired,
  card: PropTypes.object.isRequired,
  pageName: PropTypes.string.isRequired,
  paymentresponse: PropTypes.object.isRequired
};

export default VisaPaymentCard;
