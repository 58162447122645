import React from 'react';
import {View, ScrollView, TouchableOpacity, Text} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import  Actions  from '../../../../navigation/postSalesNavigation';
import AtomicCss from '../../../../bus/commonStyles/AtomicCss';
import IssueList from './IssueList';
import {colors} from '@mmt/legacy-commons/Styles/globalStyles';
import StickyHeader from '../../../../hotel/details/components/StickyHeader';
import {getCabSubTitle, getCabTitle} from '../../../utils/CabBookingDetailUtil';
import styles from '../../../styles/CabMamiCss';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import ProgressView from '@mmt/legacy-commons/Common/Components/Progress/ProgressView';
import NoNetworkView from '../../../../Common/Cancellation/NoNetworkView';
import PageLoadingError from '../../../../Common/Cancellation/PageLoadingError';
import {CAB_GET_CLAIM_REASONS_URL, getCommonHeaders} from '../../../../utils/NetworkUtils';
import {isNetworkAvailable} from '@mmt/legacy-commons/Common/utils/AppUtils';
import CabBookingTrackingHelper from '../../../CabBookingTrackingHelper';
import {CAB_CLAIM_CONST} from '../../../../PostSalesConstant';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import {headerIcon2} from "../../../CabImageConstants";

class CarIssueSelection extends React.Component {
  constructor(props) {
    super(props);
    this.pageName = CAB_CLAIM_CONST.CLAIM_ISSUE_SELECT_TEXT;
    this.pageTitle = CAB_CLAIM_CONST.CLAIM_RAISE_REQUEST_TEXT;
    this.actualRes = props.response;
    this.noResultMsg = 'Sorry, you can not raise a claim for this booking.';
    this.state = {
      issueListObj: [],
      proceedDisabled: true,
      attachment: false,
      viewState: ViewState.SHOW_DETAIL
    }
  }

  goBack = () => {
    Actions.pop();
  };

  updateIssueList = (issueListObj) => {
    let proceedDisabled = true;
    if(issueListObj && issueListObj.length > 0) {
      proceedDisabled=false
    }
    let attachment = true;
    this.setState({ issueListObj, proceedDisabled, attachment })
  }

  proceed = () => {
    let response = this.actualRes;
    let issueList = this.state.issueListObj;
    let attachment = this.state.attachment;

    CabBookingTrackingHelper.trackClickEvent('mob: cabs: mima support select issue page', 'proceed to issue detail')
    Actions.carSupportIssueDetail({
      response, issueList, attachment
    });
  }

  getIssueBreadCumb = () => {
    let steps = this.actualRes.carSupportData.scSteps;
    return (<View style={styles.breadCumbOuter}>
      {
        steps && steps.map((step, index) => (
          <Text style={index == 0 ? styles.activeBreadCumb : styles.nonActiveBreadcumb}>{index + 1}  {step}</Text>
        ))
      }
    </View >);
  }

  componentDidMount() {
    this.fetchJSONAsync(CAB_GET_CLAIM_REASONS_URL + this.actualRes.bookingId);
  }

  async fetchJSONAsync(url) {
    try {
      this.setState({viewState: ViewState.LOADING});
      const hasNetwork = await isNetworkAvailable();
      if (!hasNetwork) {
        this.setState({viewState: ViewState.NO_INTERNET});
        return;
      }

      const { mmtAuth, loggingTrackingInfo } = await HotelBookingModule.getRequestHeader(this.props.bookingid, 'CAR_GET_CLAIMS_REASONS');

      const response = await
        fetch(url, {headers: await getCommonHeaders(mmtAuth, loggingTrackingInfo)});

      if (response.ok) {
        const body = await response.json();
        if(body.issueList && body.issueList.length <= 0) {
          // this.noResultMsg = body.code;
          this.setState({viewState: ViewState.NO_RESULT});
        }
        else {
          this.actualRes.carSupportData.issueList = body.issueList;
          this.setState({
            viewState: ViewState.SHOW_DETAIL
          });
          CabBookingTrackingHelper.trackLoadEvent('mob: cabs: mima support issue selection page',  this.props.response)
        }
      } else {
        this.setState({viewState: ViewState.ERROR});
        this.trackError();
      }
    } catch (error) {
      this.setState({viewState: ViewState.ERROR});
      this.trackError();
    }
  }

  trackError() {
    CabBookingTrackingHelper.trackErrorEvent('mob: cabs: mima support issue selection page:Error');
  }

  renderProgressView = () => (<ProgressView message="Fetching the issues..." />);

  refreshDetail = () => {
    this.setState({ viewState: ViewState.LOADING });
    this.fetchJSONAsync(CAB_GET_CLAIM_REASONS_URL + this.actualRes.bookingId);
  }

  render() {
    return (
      <View style={AtomicCss.flex1}>
        {this.state.viewState === ViewState.LOADING && this.renderProgressView()}
        {this.state.viewState === ViewState.NO_INTERNET && <NoNetworkView retryFunction={this.refreshDetail} />}
        {this.state.viewState === ViewState.ERROR && <PageLoadingError retryFunction={this.refreshDetail} />}
        {this.state.viewState === ViewState.NO_RESULT && <PageLoadingError message={this.noResultMsg} buttonLabel={'Go Back'} retryFunction={this.goBack} />}
        {this.state.viewState === ViewState.SHOW_DETAIL && this.renderPage()}
      </View>
    );
  }

  renderPage() {
    return (
      <View style={[AtomicCss.flex1, styles.bodybg]}>
        <StickyHeader
          iconSrc={headerIcon2}
          title={getCabTitle(this.actualRes)}
          headerColor={['#ffffff', '#ffffff']}
          titleStyle={[styles.DefaultText, styles.blackFont]}
          subTitle={getCabSubTitle(this.actualRes)}
          subTitleStyle={[styles.DefaultText, styles.regularFont, styles.font12]}
          onBackPress={this.goBack}
        />
        <ScrollView>
          {this.getIssueBreadCumb()}
          {this.actualRes.carSupportData && this.actualRes.carSupportData.issueList && this.actualRes.carSupportData.issueList.length > 0 &&

          <IssueList carSupportData={this.actualRes.carSupportData}
                     msrTicketList={this.actualRes.msrTicketsForBooking.ticketsList}
                     updateIssueList={this.updateIssueList}
                     issueListObj={this.state.issueListObj}
          />

          }
        </ScrollView>


        <TouchableOpacity style={AtomicCss.btn} activeOpacity={0.7} onPress={this.proceed} disabled={this.state.proceedDisabled}>
          <LinearGradient
            colors={this.state.proceedDisabled ? [colors.disabledBtnBg, colors.disabledBtnBg] : [colors.lightBlue, colors.darkBlue]}
            start={{ x: 0, y: 0 }}
            end={{ x: 1.0, y: 0 }}
            style={AtomicCss.btnGradient}
          >
            <Text style={[AtomicCss.font12, AtomicCss.whiteText, AtomicCss.boldFont]}>{this.actualRes.carSupportData.proceedButton}</Text>
          </LinearGradient>
        </TouchableOpacity>
      </View>
    );
  }
}

export default CarIssueSelection;
