import React from 'react';
import { View, Text, ScrollView, TouchableOpacity } from 'react-native';
import createStyles from './ReviewCorrectionOverlayCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { useTheme } from '../../../../../../../theme/theme.context';
import { getFont } from '../../../../../../../PostSalesStyles';
import BaseButton, { BUTTON_VARIANT } from '../../../../../../../Common/BaseButton';
import { getStaticData } from '../../../../../../../staticData/staticData';
import { isNullOrEmptyObject } from '../../../../../../../Common/commonUtil';
import { NAME_CORRECTION_RULE_IDS } from '../../../../constant';
import { compareStrings } from '../../../../helper';
import { FLIGHT_THROTTLE_OBJECT } from 'apps/post-sales/src/flights/modules/details/FlightBookingDetailsConstant';

const ReviewCorrectionOverlay = ({
  onClose,
  onContinue,
  data,
  flightNameReviewCorrectionCard,
  rule,
}) => {
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme);
  const { heading, description, actionButton } = flightNameReviewCorrectionCard || {};
  const { text } = actionButton || {};

  const getStartEnd = (arr, index) => {
    if (arr && arr[index]) {
      const [start, end] = arr[index];
      return {
        start,
        end,
      };
    }
    return {
      start: -1,
      end: -1,
    };
  };

  const heighlightChangesText = (newVal, oldVal) => {
    const blocks = compareStrings(newVal, oldVal);
    const view = [];
    let index = 0;
    let { start, end } = getStartEnd(blocks, index);
    let nText = '';
    let hText = '';
    for (let i = 0; i < newVal.length; i++) {
      if (start <= i && i <= end) {
        if (nText) {
          view.push(
            <Text style={[fonts.boldFontFamily, fsStyle.font14, AtomicCss.blackText]}>
              {nText}
            </Text>,
          );
          nText = '';
        }

        if (i === start && i === end) {
          view.push(<Text style={styles.editableTxt}>{newVal.charAt(i)}</Text>);
          index++;
          ({ start, end } = getStartEnd(blocks, index));
        }
        hText += newVal.charAt(i);
        if (start < i && i === end) {
          view.push(<Text style={styles.editableTxt}>{hText}</Text>);
          hText = '';
          index++;
          ({ start, end } = getStartEnd(blocks, index));
        }
      } else {
        nText += newVal.charAt(i);
      }
      if (i === newVal.length - 1) {
        view.push(
          <Text style={[fonts.boldFontFamily, fsStyle.font14, AtomicCss.blackText]}>{nText}</Text>,
        );
      }
    }
    return view;
  };

  const renderFirstName = (fName, fnameOld) => {
    switch (rule) {
      case NAME_CORRECTION_RULE_IDS.Incorrect_Spelling:
      case NAME_CORRECTION_RULE_IDS.Change_Middle_Name:
      case NAME_CORRECTION_RULE_IDS.Change_Title:
        return heighlightChangesText(fName, fnameOld);
      case NAME_CORRECTION_RULE_IDS.Swap_Last_First_Name:
        return <Text style={styles.editableTxt}>{fName}</Text>;
      default:
        return <Text style={[fsStyle.font14, AtomicCss.blackText]}>{fName}</Text>;
    }
  };

  const renderLastName = (lName, lnameOld) => {
    switch (rule) {
      case NAME_CORRECTION_RULE_IDS.Incorrect_Spelling:
      case NAME_CORRECTION_RULE_IDS.Change_Last_Name:
        return heighlightChangesText(lName, lnameOld);
      case NAME_CORRECTION_RULE_IDS.Swap_Last_First_Name:
        return <Text style={styles.editableTxt}>{lName}</Text>;
      default:
        return <Text style={[fsStyle.font14, AtomicCss.blackText]}>{lName}</Text>;
    }
  };

  const renderGender = (val) => {
    if (rule === NAME_CORRECTION_RULE_IDS.Change_Gender) {
      return <Text style={[styles.editableTxt, fonts.boldFontFamily]}>{val}</Text>;
    }
    return val;
  };

  const renderTravellers = (info, key, count) => {
    const staticData = getStaticData();
    const { nameCorrectionTexts } = staticData || {};
    const { fName, lName, gender, fnameOld, lnameOld } = info || {};
    return (
      <View style={[styles.detailSec]} key={key}>
        <View
          style={[
            AtomicCss.flexRow,
            AtomicCss.alignCenter,
            AtomicCss.spaceBetween,
            AtomicCss.marginBottom20,
          ]}
        >
          <View style={[styles.leftStroke, { backgroundColor: theme.color.primary }]} />
          <Text style={[fonts.blackFontFamily, fsStyle.font16, AtomicCss.blackText]}>
            {' '}
            {nameCorrectionTexts.travellerText} {count}
          </Text>
          <TouchableOpacity activeBtnOpacity={0.7} onPress={onClose}>
            <Text style={[fonts.boldFontFamily, fsStyle.font13, AtomicCss.azure]}>
              {nameCorrectionTexts.editText}
            </Text>
          </TouchableOpacity>
        </View>
        <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom20]}>
          <Text
            style={[
              fonts.regularFontFamily,
              fsStyle.font14,
              AtomicCss.midGreyText,
              styles.paxDetail,
            ]}
          >
            {nameCorrectionTexts.firstAndMiddleName} :{' '}
          </Text>

          {renderFirstName(fName, fnameOld)}
        </View>
        <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom10]}>
          <Text
            style={[
              fonts.regularFontFamily,
              fsStyle.font14,
              AtomicCss.midGreyText,
              styles.paxDetail,
            ]}
          >
            {nameCorrectionTexts.surname} :
          </Text>
          {renderLastName(lName, lnameOld)}
        </View>
        <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
          <Text
            style={[
              fonts.regularFontFamily,
              fsStyle.font14,
              AtomicCss.midGreyText,
              styles.paxDetail,
            ]}
          >
            {nameCorrectionTexts.gender} :
          </Text>
          <Text style={[fonts.regularFontFamily, fsStyle.font14, AtomicCss.blackText]}>
            {renderGender(gender)}
          </Text>
        </View>
      </View>
    );
  };

  const renderContent = () => {
    const staticData = getStaticData();
    const { proceedToUploadText } = staticData || {};
    return (
      <View style={styles.overlayContentWrapper}>
        <View style={AtomicCss.marginBottom24}>
          <View style={styles.overlayTopline} />
          <View>
            <Text
              style={[styles.title, AtomicCss.marginBottom8, fonts.blackFontFamily, fsStyle.font22]}
            >
              {heading}
            </Text>
            <Text
              style={[
                fonts.regularFontFamily,
                fsStyle.font14,
                AtomicCss.defaultText,
                AtomicCss.lineHeight20,
                AtomicCss.marginBottom12,
              ]}
            >
              {description}{' '}
            </Text>
            <View style={[styles.strokeStyle, { backgroundColor: theme.color.primary }]} />
            <ScrollView style={styles.scrollViewWrapper}>
              {!isNullOrEmptyObject(data) &&
                Object.keys(data).map((key, index) => renderTravellers(data[key], key, index + 1))}
            </ScrollView>
          </View>
        </View>
        <View style={styles.buttonBottomWrapper}>
          <View style={styles.buttonWrapper}>
            <BaseButton
              {...FLIGHT_THROTTLE_OBJECT}
              text={!!text && proceedToUploadText}
              clickHandler={onContinue}
              variant={BUTTON_VARIANT.PRIMARY}
              textStyle={fsStyle.font16}
              buttonStyle={styles.overlayButtonStyle}
            />
          </View>
        </View>
      </View>
    );
  };

  return renderContent();
};

export default ReviewCorrectionOverlay;
