import React from 'react';
import PropTypes from 'prop-types';
import {Text, TouchableOpacity, StyleSheet, View, Image} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import AtomicCss from '../../../Styles/AtomicCss';

class RecBlueBtn extends React.PureComponent {
  render() {
    return (
      <TouchableOpacity onPress={() => this.props.onPress()}>
        <LinearGradient
          start={{x: 1.0, y: 0.0}}
          end={{x: 0.0, y: 1.0}}
          colors={['#065af3', '#53b2fe']}
          style={[styles.RecBlueBtn, AtomicCss.marginBottom10, AtomicCss.marginTop10]}
        >
          <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.justifyCenter]}>
            { this.props.btnImage &&
            <Image style={styles.buttonIcon} source={this.props.btnImage} />}
            <Text style={[AtomicCss.whiteText, AtomicCss.font16, AtomicCss.blackFont,
              AtomicCss.bgTransparent]}
            >
              {this.props.label}
            </Text>
          </View>
        </LinearGradient>
      </TouchableOpacity>
    );
  }
}

RecBlueBtn.propTypes = {
  label: PropTypes.string.isRequired,
  btnImage: Image.propTypes.source,
  onPress: PropTypes.func.isRequired
};

RecBlueBtn.defaultProps = {
  btnImage: null
};

const styles = StyleSheet.create({
  RecBlueBtn: {
    paddingVertical: 12,
    marginHorizontal: 10,
    borderRadius: 4,
    overflow: 'hidden'
  },
  buttonIcon: {width: 26, height: 26, marginRight: 10}
});

export default RecBlueBtn;
