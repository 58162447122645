import React from 'react';
import {Image, Platform, Text, View} from 'react-native';
import Actions from '../../../navigation/postSalesNavigation';
import PropTypes from 'prop-types';
import getStyles from './HotelMamiCss';

import {
  appendRupeeSymbol, getActionButton, getButtonStyle, handleClickEvent,
  isNotNullAndEmpty
} from '../utils/HotelBookingDetailUtil';
import HotelBookingDetailsConstant from '../HotelBookingDetailsConstant';

import {
  getParsedString, getPromiseIcons, getPromiseProgressStyle, getTimerText,
  isRequestStateOpen
} from '../../../utils/MyPromiseUtil';
import HotelBookingTrackingHelper from '../../HotelBookingTrackingHelper';
import { getStaticData } from '../../../staticData/staticData';
import { REFUND_RUPEE_ICON } from '../../HotelImageConstants';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const refundIcon = REFUND_RUPEE_ICON;

class PromiseRefundCard extends React.Component {
  constructor(props) {
    super(props);
    this.fonts = getFont(true);
    this.styles = getStyles(this.fonts);
  }
  render() {
    const staticData = getStaticData();
    return (<View style={[this.styles.MamiCard, this.styles.mar10Bt]}>
      <View style={this.styles.CardContent}>
        <Image style={this.styles.CardIcon} source={refundIcon} />
        <View style={this.styles.ContentPortion}>
          <View style={[this.styles.refundCardhead, this.styles.mar15Bt]}>
            <View style={this.styles.refundCardheadText}>
              <Text style={[this.styles.blackFont, this.styles.BlackText]}>
                {this.props.promise.reqtypeuserdata.requeststatusmsg}
              </Text>
              <Text style={[this.styles.blackFont, this.styles.BlackText]}>
                {staticData.promiseRefundCardText.additionalText.refundAmountCapsText}: {appendRupeeSymbol(this.props.refundObj.refundAmount)}
              </Text>
            </View>
            <View>
              {getTimerText(this.props.promise)}
              <Text style={this.styles.font12}>{this.getTimerSubText(this.props.promise)}</Text>
            </View>
          </View>
          <Text style={this.styles.font12}><Text style={[this.styles.boldFont, this.styles.DefaultText]}>{staticData.promiseRefundCardText.additionalText.ourPromiseText} :&nbsp;
          </Text>{getParsedString(this.props.promise.reqtypeuserdata.requestcommitmentmsg, this.props.promise, this.props.refundObj.refundAmount, this.props.refundObj.totalPgDays)}
          </Text>
          <Text style={[this.styles.font12, this.styles.mar10Bt]}><Text
            style={[this.styles.boldFont, this.styles.DefaultText]}
          >{staticData.promiseRefundCardText.additionalText.ifWeDelayWeWillPayText} :&nbsp;
          </Text>{getParsedString(this.props.promise.reqtypeuserdata.requestpenaltymsg, this.props.promise, this.props.refundObj.refundAmount, this.props.refundObj.totalPgDays)}
          </Text>

          <View style={this.props.showRefundProgress ? this.styles.mar10Bt : this.styles.hideView}>
            <View style={this.styles.refundbarInfo}>
              {getPromiseIcons(this.props.promise)}
            </View>
            <View style={[this.styles.refundStrip, this.styles.mar5Bt]}>
              <View style={getPromiseProgressStyle(this.props.promise, false)} />
            </View>
            <View style={this.styles.refundbarInfo}>
              <Text
                style={[this.styles.font12, this.styles.flex1]}
              >{this.props.promise.userviewstartstate}
              </Text>
              <Text
                style={[this.styles.font12, this.styles.AlignRight, this.styles.flex1]}
              >{this.props.promise.userviewendstate}
              </Text>
            </View>
          </View>
        </View>
      </View>
      <View
        style={this.props.showButton && isNotNullAndEmpty(this.props.card.actionList) ? getButtonStyle(this.props.card.actionList) : this.styles.hideView}
      >
        {getActionButton(this.props.card, this.props.response, this.handleClick)}
      </View>
    </View>);
  }

  getTimerSubText(promise) {
    if (isRequestStateOpen(promise.currentstatus)) {
      return staticData.promiseRefundCardText.additionalText.timeElapsedText;
    }
    return staticData.promiseRefundCardText.additionalText.timeTakenText;
  }

  handleClick = (action) => {
    if (action.actionFamily === HotelBookingDetailsConstant.ACTION_VIEW_REFUND_DETAILS) {
      const progressStyle = getPromiseProgressStyle(this.props.promise, true);
      const {
        response, promise, card, refundObj
      } = this.props;
      Actions.refundDetails({
        response,
        promise,
        card,
        refundObj,
        progressStyle
      });
      HotelBookingTrackingHelper.trackClickEvent(this.props.pageName, `MI_HTL_${action.actionFamily}`, this.props.response);
    } else {
      handleClickEvent(this.props.card, action, this.props.response, this.props.pageName);
    }
  };
}

PromiseRefundCard.propTypes = {
  response: PropTypes.object.isRequired,
  promise: PropTypes.object.isRequired,
  card: PropTypes.object.isRequired,
  refundObj: PropTypes.object.isRequired,
  pageName: PropTypes.string,
  showButton: PropTypes.bool,
  showRefundProgress: PropTypes.bool
};

PromiseRefundCard.defaultProps = {
  showButton: true,
  showRefundProgress: true,
  pageName: ''
};

export default PromiseRefundCard;
