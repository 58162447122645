import { ImageStyle, StyleSheet, TextStyle, ViewStyle } from 'react-native';

interface Theme {
  color: {
    defaultTextColor: string;
    black: string;
    grey22: string;
    grey24: string;
    grey31: string;
  };
}

interface Fonts {
  fontSize: { [key: string]: number };
  boldFontFamily: { [key: string]: object };
  regularFontFamily: { [key: string]: object };
}

interface Styles {
  heading: ViewStyle;
  topLine: ViewStyle;
  titleText: TextStyle;
  descriptionText: TextStyle;
  headingText: TextStyle;
  overlayDescriptionText: TextStyle;
  subHeadingText: TextStyle;
  crossIconStyle: ImageStyle;
  crossIconWrapper: ViewStyle;
  bodySection: ViewStyle;
  iconStyle: ImageStyle;
  marginBt0: ViewStyle;
}

const createStyles = (fonts: Fonts, theme: Theme) =>
  StyleSheet.create<Styles>({
    heading: {
      backgroundColor: theme.color.grey22,
      borderBottomWidth: 1,
      borderBottomColor: theme.color.grey24,
      paddingHorizontal: 15,
      paddingTop: 5,
      paddingBottom: 15,
      marginHorizontal: -20,
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
    },
    topLine: {
      width: 40,
      height: 4,
      borderRadius: 4,
      backgroundColor: theme.color.grey31,
      position: 'absolute',
      top: -10,
      alignSelf: 'center',
    },
    titleText: {
      fontSize: fonts.fontSize.font16,
      ...fonts.boldFontFamily,
      marginBottom: 3,
      color: theme.color.defaultTextColor,
    },
    descriptionText: {
      color: theme.color.defaultTextColor,
      fontSize: fonts.fontSize.font14,
      ...fonts.regularFontFamily,
    },
    headingText: {
      color: theme.color.black,
      fontSize: fonts.fontSize.font16,
      ...fonts.boldFontFamily,
    },
    overlayDescriptionText: {
      color: theme.color.defaultTextColor,
      fontSize: fonts.fontSize.font14,
      ...fonts.regularFontFamily,
      marginBottom: 15,
    },
    subHeadingText: {
      fontSize: fonts.fontSize.font16,
      ...fonts.boldFontFamily,
      color: theme.color.black,
      marginBottom: 16,
    },
    crossIconStyle: {
      width: 14,
      height: 14,
      marginRight: 10,
    },
    crossIconWrapper: {
      width: 32,
      height: 32,
      alignItems: 'center',
      justifyContent: 'center',
    },
    bodySection: {
      paddingVertical: 15,
    },
    iconStyle: {
      width: 38,
      height: 38,
      marginRight: 10,
    },
    marginBt0: {
      marginBottom: 0,
    },
  });

export default createStyles;
