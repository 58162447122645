import { NativeModules } from 'react-native';
import { toBase64 } from '../webUtils';

const convertToBase64WithNoWrap=(file)=>{
    new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    });
};

const convertToBase64 = (file) => toBase64(file);

NativeModules.SpecialClaimAddAttachmentModule = {
    convertToBase64WithNoWrap,
    convertToBase64
};