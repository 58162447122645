import {fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';
import {Dimensions} from 'react-native';
const windW = Dimensions.get('window').width;

const getStyles = (fonts) => {
  return {
    whiteCard: {
      backgroundColor: '#fff',
      ...getPlatformElevation(2),
      paddingVertical: 20,
      paddingHorizontal: 16,
      justifyContent: 'space-between',
      marginBottom: 10
    },
    infoCardHading: {
      flexDirection: 'row'
    },
    infoCardWrapper: {
      paddingHorizontal: 12,
      paddingTop: 20,
      paddingBottom: 12,
    },
    infoListWrapper: {
      paddingLeft: 26
    },
    tipLineGradient: {
      height: 4,
      position: 'absolute',
      width: windW - 35,
      left: 5,
      top: 0,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      overflow: 'hidden'
    },
    headingTxt: {
      color: '#000',
      flex: 1,
      ...fonts.font16,
      lineHeight: 20,
      fontFamily: fonts.black,
      marginBottom: 15,
    },
    infoCardDisc: {
      ...fonts.font14,
      color: '#4a4a4a',
      lineHeight: 18,
      paddingLeft: 20,
      paddingRight: 20,
      fontFamily: fonts.regular,
      flex: 1,
    },
    bulletCircle: {
      width: 6,
      height: 6,
      backgroundColor: '#5a5a5a',
      borderRadius: 20,
      top: 5,
      left: 5,
    },
    infoIcon: {
      width: 20,
      height: 20,
      marginRight: 10,
    },
    redirectBtnWrapper: {
      paddingHorizontal: 15,
      paddingVertical: 15,
      justifyContent: 'space-between',
      flexDirection: 'row',
      backgroundColor: '#fff',
      ...getPlatformElevation(2),
      alignItems: 'center',
      marginTop: 2,
      marginBottom: 1,
    },
    rightArrowIcon: {
      width: 9,
      height: 13,
    },
    linkBtnTxt: {
      ...fonts.font14,
      fontFamily: fonts.bold,
      color: '#008cff',
    },
    linkWrapper: {
      paddingLeft: 25,
    },
    chargesInfoWrapper: {
      borderRadius: 4,
      backgroundColor: '#ffedd1',
      paddingHorizontal: 8,
      paddingVertical: 4,
      justifyContent: 'space-between'
    },
    extraChargeTxt: {
      fontFamily: fonts.bold,
      color: '#cf8100',
      ...fonts.font14,
      lineHeight: 20
    },
    infoIconStyle: {
      width: 14,
      height: 14,
      tintColor: '#cf8100',
    },
  };
}

export default getStyles;
