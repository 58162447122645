import React from 'react';
import {View, Text, Image} from 'react-native';
import styles from './summaryCardCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import Button from '../../../../Common/Button'
import _ from 'lodash';
import ZcConstants from "../../../utils/ZoomCarConstants";
import {handleZcClickEvent} from "../../../utils/SelfDriveBookingDetailUtil";
import {REDIRECTION_ICON} from '../../../SelfDriveImageConstant';

const ProfileVerificationPendingCard = ({card, response}) => {
    let submitLDL = card.actionList.filter(b => b.actionFamily === ZcConstants.ACTION_SUBMIT_DL)[0];
    let uploadedDocuments = card.actionList.filter(b => ((b.actionFamily === ZcConstants.ACTION_SEE_UPLOADED_DOCS) || (b.actionFamily === ZcConstants.ACTION_SEE_UPLOADED_DOCUMENTS)))[0];
    return (
        <View style={[AtomicCss.marginBottom10]}>
            <View style={[styles.summaryCardWrapper]}>

                <View style={[styles.pendingVerify, AtomicCss.marginBottom15]}>
                    <View style={AtomicCss.marginBottom12}><Text
                        style={[AtomicCss.blackFont, AtomicCss.defaultText, AtomicCss.font14]}>{card.header}</Text></View>

                    {!_.isEmpty(card.subHeaders) && <View style={AtomicCss.marginBottom16}><Text
                        style={[AtomicCss.font14, AtomicCss.defaultText]}>{card.subHeaders}</Text></View>}

                    {!_.isEmpty(card.descriptions) && <View style={AtomicCss.marginBottom16}><Text
                        style={[AtomicCss.font14, AtomicCss.defaultText]}>{card.descriptions}</Text></View>}

                    {!_.isEmpty(uploadedDocuments) && <View style={styles.buttonOuter}>
                        <Button
                            btnBg="white"
                            btnWidth={300}
                            btnTxt={uploadedDocuments.actionLabeltext}
                            clickHandler={handleClick}
                            btnClickAction={uploadedDocuments}>
                        </Button>
                    </View>}
                    {!_.isEmpty(submitLDL) && <View style={styles.buttonOuter}>
                        <Button
                            btnBg="white"
                            btnTxt={submitLDL.actionLabeltext}
                            clickHandler={handleClick}
                            btnClickAction={submitLDL}
                        />
                    </View>}
                </View>
            </View>
        </View>
    )

    function handleClick(action) {
        handleZcClickEvent(card, action, response, "pending profile documents").then(r => "");
    }
}


export default ProfileVerificationPendingCard
