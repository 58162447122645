import {colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import {Platform} from 'react-native';

const styles = {
  flex1: {
    flex: 1
  },
  myrabutton: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    paddingVertical: 15
  },
  font18: {
    fontSize: 18
  },
  font16: {
    fontSize: 16
  },
  font10: {
    fontSize: 10
  },
  font11: {
    fontSize: 11
  },
  font12: {
    fontSize: 12
  },
  font13: {
    fontSize: 13
  },
  font14: {
    fontSize: 14
  },
  font20: {
    fontSize: 20
  },
  font24: {
    fontSize: 24
  },
  font15: {
    fontSize: 15
  },
  padL5: {
    PaddingLeft: 5
  },
  width145: {
    width: 145
  },
  padding15Left: {
    paddingLeft: 15
  },
  padding20Left: {
    paddingLeft: 20
  },
  marginLeftAuto: {marginLeft: 'auto'},
  borderTop: {borderTopWidth: 1, borderTopColor: '#ddd'},
  GreyBg: {
    backgroundColor: '#ddd'
  },
  defaultGrey: {color: '#4a4a4a'},
  whitebg: {
    backgroundColor: '#fff'
  },
  mar10Top: {
    marginTop: 10
  },
  mar20Top: {
    marginTop: 20
  },
  mar15Top: {
    marginTop: 15
  },
  pad10Top: {
    paddingTop: 10
  },
  pad15Top: {
    paddingTop: 15
  },
  mar25Bt: {
    marginBottom: 25
  },
  mar20Bt: {
    marginBottom: 20
  },
  mar22Bt: {
    marginBottom: 22
  },
  mar24Bt: {
    marginBottom: 24
  },
  mar15Bt: {
    marginBottom: 15
  },
  mar18Bt: {
    marginBottom: 18
  },
  mar12Bt: {
    marginBottom: 12
  },
  mar10Bt: {
    marginBottom: 10
  },
  mar8Bt: {
    marginBottom: 8
  },
  mar5Bt: {
    marginBottom: 5
  },
  mar3Bt: {
    marginBottom: 3
  },
  mar5Rt: {
    marginRight: 5
  },
  mar10Rt: {
    marginRight: 10
  },
  mar15Rt: {
    marginRight: 15
  },
  mar32Rt: {
    marginRight: 32
  },
  mar30Bt: {
    marginBottom: 30
  },
  mar35Bt: {
    marginBottom: 35
  },
  lineHeight16: {
    lineHeight: 16
  },
  lineHeight18: {
    lineHeight: 18
  },
  bgTransparent: {backgroundColor: 'transparent'},
  flexRow: {
    flexDirection: 'row'
  },
  scissorIcon: {
    width: 12, height: 12, position: 'absolute', top: -5, left: 5
  },
  promoBorder: {
    borderStyle: 'dotted',
    borderColor: '#ededed',
    padding: 8,
    borderWidth: 1
  },
  alignCenter: {
    alignItems: 'center'
  },
  borderbtm: {
    borderBottomWidth: 1,
    borderBottomColor: '#ddd'
  },
  lineHeight20: {
    lineHeight: 20
  },
  lineHeight22: {
    lineHeight: 22
  },
  lineHeight24: {
    lineHeight: 24
  },
  boldFont: {
    fontFamily: fonts.bold
  },
  blackFont: {
    fontFamily: fonts.black
  },
  lightFont: {
    fontFamily: fonts.light
  },
  regularFont: {
    fontFamily: fonts.regular
  },
  greenText: {
    color: '#3eb584'
  },
  BlueText: {
    color: '#008cff',
    fontFamily: fonts.bold
  },
  WhiteText: {
    color: colors.white
  },
  BlackText: {
    color: colors.black
  },
  DefaultText: {
    color: colors.defaultTextColor
  },
  lightYellow: {color: '#e58f13'},
  CenterText: {
    alignSelf: 'center'
  },
  AlignCenter: {
    textAlign: 'center'
  },
  AlignRight: {
    textAlign: 'right'
  },
  fontWeightB: {
    fontWeight: 'bold'
  },
  MamiCard: {
    backgroundColor: '#fff'
  },
  elevation2: {elevation: 2},
  mar20TNeg: {marginTop: -20},
  lineThought: {textDecorationLine: 'line-through'},
  cancellationBg: {backgroundColor: '#f6f6f6'},
  CardContent: {
    backgroundColor: '#fff',
    borderBottomWidth: 1,
    borderBottomColor: '#e4e4e4',
    flexDirection: 'row',
    padding: 15,
    elevation: 1
  },
  applicatCardContent: {
    backgroundColor: '#fff',
    borderBottomWidth: 1,
    borderBottomColor: '#e4e4e4',
    flexDirection: 'row',
    elevation: 1,
    paddingHorizontal: 15,
    paddingTop: 15,
    paddingBottom: 0
  },
  VisaCardContent: {
    backgroundColor: '#fff',
    padding: 15,
    elevation: 1
  },
  VisaRejectionCardContent: {
    backgroundColor: '#fff',
    padding: 15
  },
  CardContentFull: {
    backgroundColor: '#fff',
    borderBottomWidth: 1,
    borderBottomColor: '#e4e4e4',
    padding: 15,
    elevation: 1
  },
  RecFullBtn: {
    backgroundColor: '#fff',
    paddingBottom: 20,
    paddingTop: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 45,
    paddingRight: 35,
    borderBottomWidth: 1,
    borderBottomColor: '#e4e4e4'
  },
  CapsuleBtn: {
    backgroundColor: '#fff',
    paddingBottom: 12,
    paddingTop: 12,
    paddingLeft: 10,
    paddingRight: 10,
    borderWidth: 1,
    borderColor: '#53b2fe',
    borderRadius: 50,
    flex: 1,
    marginRight: 10,
    marginLeft: 10
  },
  CapsuleBtnFill: {
    paddingBottom: 12,
    paddingTop: 12,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 50,
    marginRight: 10,
    marginLeft: 10
  },
  CapsuleBtnFill1: {
    paddingBottom: 12,
    paddingTop: 12,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 50,
    marginRight: 10
  },
  CapsuleBtnFillOuter: {
    flex: 1
  },
  whiteBtnHalf: {
    backgroundColor: '#ffffff',
    borderRadius: 30,
    paddingTop: 10,
    paddingBottom: 10,
    width: '46 %'
  },
  RbBtnWithIcon: {
    borderRadius: 50,
    paddingBottom: 2,
    paddingTop: 2,
    paddingLeft: 2,
    paddingRight: 2,
    width: 36,
    height: 36,
    alignSelf: 'center'
  },
  RbBtnWithIconWrap: {
    width: 80,
    paddingLeft: 6,
    paddingRight: 6
  },
  SupportTxt: {
    width: 105,
    paddingLeft: 6,
    paddingRight: 6
  },
  RbBtnWithIconText: {
    textAlign: 'center',
    color: '#008cff',
    lineHeight: 20,
    fontFamily: fonts.semiBold
  },
  ActionBtnHalf: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100 %',
    backgroundColor: '#fff',
    paddingBottom: 10,
    marginTop: 15
  },
  ActionBtn: {
    backgroundColor: '#fff',
    paddingBottom: 10,
    marginTop: 15
  },
  ActionBtnRec: {
    backgroundColor: '#fff'
  },
  RbBtnWithIconImg: {
    width: 36,
    height: 36,
    alignSelf: 'center'
  },
  CardIcon: {
    width: 24,
    height: 24,
    marginRight: 10
  },
  infoGreen: {width: 20, height: 20, marginRight: 10},
  infoRed: {width: 16, height: 16, marginRight: 10},
  faqsIcon: {
    width: 24,
    height: 24
  },
  walletIcon: {
    width: 35,
    height: 35,
    marginRight: 10
  },
  ContentPortion: {
    flex: 1
  },
  width100per: {width: '100 %'},
  mar5Left: {marginLeft: 5},
  mar8Left: {marginLeft: 8},
  TimingBlockOuter: {
    flexDirection: 'row',
    justifyContent: 'space-around'
  },
  TimingBlock: {
    width: '50 %'
  },
  StatusCardWrapper: {
    paddingLeft: 16,
    paddingRight: 16
  },
  topStatusCard: {
    paddingTop: 20,
    paddingBottom: 15,
    zIndex: 10,
    position: 'relative',
    ...Platform.select({
      ios: {
        backgroundColor: 'transparent'
      }
    }),
    marginTop: -67
  },
  container: {
    marginTop: -67
  },
  padding12Bottom: {paddingBottom: 12},
  marginBottom100: {paddingBottom: '100%'},
  stickyHeader: {
    paddingTop: 14,
    paddingBottom: 8,
    width: '100%',
    elevation: 5,
    backgroundColor: '#fff',
    marginBottom: 6
  },
  PriceHeader: {
    paddingTop: 14,
    paddingBottom: 14,
    width: '100%',
    elevation: 5
  },
  margin0Bottom: {marginBottom: 0, elevation: 1},
  bookingidDetails: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  topStatusBar: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: 16,
    paddingRight: 16,
    alignItems: 'center'
  },
  topStatusBarRefund: {
    flexDirection: 'row',
    paddingLeft: 20,
    paddingRight: 20,
    alignItems: 'center'
  },
  whiteBtnFull: {
    backgroundColor: '#ffffff',
    borderRadius: 30,
    paddingTop: 10,
    paddingBottom: 10,
    flex: 1,
    marginRight: 10
  },
  marLR50: {marginLeft: 50, marginRight: 50},
  whiteBtnHalfOuter: {
    flexDirection: 'row',
    justifyContent: 'space-around'
  },

  callButtonHalfOuter: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginBottom: 23,
    marginTop: 10
  },
  VisaMainInfo: {
    flex: 1,
    marginRight: 10
  },


  FlagImg: {
    width: 36,
    height: 24
  },
  VisaRow: {
    flexDirection: 'row',
    paddingBottom: 12,
    borderBottomWidth: 1,
    borderBottomColor: '#ddd'
  },
  marginTop24: {marginTop: 24},
  marginTop18: {marginTop: 18},
  marginTop30: {marginTop: 30},
  marL12per: {paddingLeft: '12%'},
  lightGreenTxt: {color: '#00a19c'},
  aminitiesIcon: {
    width: 16,
    height: 16,
    marginRight: 5
  },
  aminitieswrap: {
    flexDirection: 'row',
    width: '50%',
    alignItems: 'center'
  },
  Paymentrow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  PaymentLeft: {
    flex: 0.9
  },
  PriceTotal: {
    paddingTop: 16, paddingLeft: 20, paddingRight: 20, paddingBottom: 16
  },
  padding20: {padding: 20},
  infoIcon: {
    width: 16,
    height: 16,
    marginRight: 10
  },
  infoText: {
    color: '#e53442',
    fontStyle: 'italic',
    fontSize: 12
  },
  stickyBarWrapper: {
    flexDirection: 'row',
    width: '85%',
    alignItems: 'center'
  },
  paddingL20: {paddingLeft: 20},
  stickyTextWrapper: {
    paddingLeft: 15
  },
  backarrow: {
    width: 16,
    height: 16
  },
  closeIcon: {width: 24, height: 24},
  shareicon: {
    width: 22,
    height: 17
  },
  topStatusIcon: {
    width: 70,
    height: 70
  },
  topStatusConfirmIcon: {
    width: 100,
    height: 60
  },
  RecFullBtnArrow: {
    width: 9,
    height: 12
  },
  arrowRightGray: {width: 16, height: 16},
  successText: {
    color: '#00a19c',
    fontSize: 12
  },
  refundStrip: {
    width: '100%',
    backgroundColor: '#ccc',
    height: 3
  },
  refundHalfbar: {
    width: '50%',
    height: 3
  },
  blueColor: {color: '#008cff'},
  refundFullbar: {
    width: '100%',
    height: 3
  },
  refundGreenBackground: {
    backgroundColor: '#32d18f'
  },
  refundYellowBackground: {
    backgroundColor: '#e5c055'
  },
  refundbarInfo: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  refundCardhead: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  refundCardheadText: {
    width: '65%'
  },
  refundCardOuter: {
    paddingLeft: 15,
    paddingRight: 15,
    paddingBottom: 10,
    paddingTop: 10
  },
  refundCardWrap: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  refundCardLeft: {
    width: '75%'
  },
  MimaRadio: {
    width: 18,
    height: 18,
    backgroundColor: '#fff',
    borderRadius: 20,
    borderWidth: 1,
    borderColor: '#00a19c',
    marginRight: 20,
    marginTop: 5
  },
  MimaRadioInside: {
    width: 12,
    height: 12,
    backgroundColor: '#00a19c',
    borderRadius: 20,
    marginLeft: 2,
    marginTop: 2
  },
  refundText: {
    marginLeft: 38
  },
  MamiCardGrey: {
    paddingLeft: 10,
    paddingRight: 10
  },
  impInfoGreenOuter: {
    backgroundColor: '#c8f8f6',
    width: '100%',
    padding: 10,
    borderRadius: 4
  },
  impInfoGreen: {
    color: '#00a19c',
    fontSize: 14
  },
  row: {flexDirection: 'row'},
  impInfoRedOuter: {
    backgroundColor: '#ffe3d5',
    width: '100%',
    padding: 10,
    borderRadius: 4
  },
  impInfoRed: {
    color: '#e53442',
    fontSize: 14
  },
  redText: {color: '#d0021b'},
  darkRed: {color: '#eb2026'},
  topHeader: {
    padding: 15,
    flexDirection: 'row',
    alignItems: 'center',
    height: 56
  },
  ScrolableHead: {
    paddingLeft: 15,
    paddingBottom: 14,
    paddingTop: 15,
    elevation: 1,
    backgroundColor: '#fff',
    marginBottom: 2
  },
  listOuter: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 310
  },
  listItems: {
    paddingTop: 13,
    paddingBottom: 13,
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
    fontFamily: fonts.regular
  },
  crousalOuter: {
    width: '100%'
  },
  imgg: {
    width: '100%',
    height: '100%'
  },
  headerPaddingbtm: {
    paddingBottom: 70
  },
  marginBtm: {marginBottom: 16},

  padVertical10: {
    paddingVertical: 10
  },
  whiteContainer: {
    backgroundColor: '#ffffff',
    flex: 1
  },
  PolicyHeading: {
    paddingVertical: 15,
    flexDirection: 'row',
    paddingHorizontal: 18,
    backgroundColor: '#ffffff',
    elevation: 2,
    marginBottom: 2
  },
  PolicyHeadingText: {
    paddingTop: 3
  },
  CrossIcon: {
    width: 20,
    height: 20,
    marginRight: 10
  },
  HotelIcon: {
    width: 18,
    height: 20,
    marginRight: 10
  },
  PolicyDetailsContainer: {
    paddingVertical: 18,
    paddingRight: 35,
    paddingLeft: 25,
    elevation: 0.5,
    backgroundColor: '#ffffff',
    marginBottom: 5

  },
  ItemDisc: {
    width: 6,
    height: 6,
    borderRadius: 25,
    backgroundColor: '#9b9b9b',
    marginRight: 15,
    marginTop: 6
  },
  HotelVoucherWrapper: {
    padding: 15
  },
  paddingTop18: {paddingTop: 18},
  paddingBottom8: {paddingBottom: 8},
  paddingTop10: {paddingTop: 10},
  paddingTop12: {paddingTop: 12},
  paddingBottom10: {paddingBottom: 10},
  HotelVoucherForm: {
    borderRadius: 3,
    elevation: 2,
    backgroundColor: '#ffffff',
    minHeight: 120,
    paddingVertical: 15,
    paddingHorizontal: 18
  },
  pad20Bottom: {paddingBottom: 20},
  formText: {
    width: 60,
    fontSize: 15
  },
  formField: {
    flex: 1,
    backgroundColor: '#ffffff',
    height: 30,
    fontSize: 14,
    lineHeight: 18,
    padding: 0,
    borderBottomColor: '#dddddd',
    borderBottomWidth: 1
  },

  showView: {
    display: 'flex'
  },
  hideView: {
    display: 'none'
  },
  ChooseOneRadio: {
    marginRight: 10,
    marginTop: 2
  },
  mar4Top: {marginTop: 4},
  italic: {
    fontStyle: 'italic'
  },
  PAHXInfo: {
    padding: 15
  },
  priceBreakup: {
    paddingLeft: 15, paddingRight: 15, paddingTop: 10, paddingBottom: 10
  },
  PAHXInfoHeading: {
    backgroundColor: '#fff',
    borderBottomWidth: 1,
    borderBottomColor: '#e4e4e4',
    flexDirection: 'row',
    paddingHorizontal: 15,
    paddingVertical: 10,
    elevation: 2
  },
  RefundProcessCard: {
    flexDirection: 'row'
  },
  RefundLeftSection: {
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 200,
    marginRight: 10
  },
  RefundProcessLine: {
    width: 3,
    backgroundColor: '#ccc',
    height: 200,
    borderRadius: 25
  },
  RefundProcessSuccess: {
    backgroundColor: '#00a19c',
    flex: 1
  },
  RefundProcessHalf: {
    backgroundColor: '#e5c055',
    flex: 0.5
  },
  RefundProcessSuccessHalf: {
    backgroundColor: '#00a19c',
    flex: 0.5
  },
  flexStart: {alignSelf: 'flex-start'},
  priceBreakupSection: {
    borderBottomWidth: 1,
    borderBottomColor: '#e4e4e4',
    elevation: 1
  },
  RefundRightSection: {
    marginLeft: 5,
    width: '75%'
  },
  RefundRightProcessDtls: {
    justifyContent: 'space-between',
    height: 200
  },
  RefundRefectTimeText: {
    paddingLeft: 20,
    fontSize: 12
  },

  SuccessTickIcon: {
    width: 16,
    height: 16,
    marginTop: 3,
    marginRight: 5
  },
  lightGrey: {color: '#9b9b9b'},
  RefundPaymentDtls: {
    marginLeft: 20
  },
  darkGrey: {color: '#747474'},
  ErrorStateWrapper: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    paddingHorizontal: 15
  },

  dateChangeIcon: {
    width: 70,
    height: 70
  },
  ErrorPageText: {
    fontSize: 20,
    textAlign: 'center',
    lineHeight: 25,
    color: '#3c3c3c'

  },
  width300: {width: 300},
  width250: {width: 250},
  width200: {width: 200},
  mar50Bt: {
    marginBottom: 50
  },
  formLabel: {
    width: 50, fontSize: 15, marginTop: 14, color: '#4a4a4a'
  },
  mar150Bt: {
    marginBottom: 150
  },
  lineHt22: {
    lineHeight: 22
  },
  CommonFillBtn: {
    borderRadius: 25,
    paddingVertical: 8,
    paddingHorizontal: 25
  },
  borderRadius3: {borderRadius: 3},
  spaceBetween: {justifyContent: 'space-between'},
  TextArea: {borderWidth: 1, borderColor: '#e5e5e5', marginBottom: 25},
  textInput: {textAlignVertical: 'top', padding: 10},
  FeedbackAddPhoto: {
    flexDirection: 'row', paddingLeft: 10, paddingRight: 10, paddingTop: 6, paddingBottom: 6, borderTopWidth: 1, borderTopColor: '#e5e5e5'
  },
  AddRemoveDoc: {flexDirection: 'row', justifyContent: 'space-between', flex: 1},
  bgTransparent: {
    backgroundColor: 'transparent'
  },

  TopStatusCardDetls: {
    padding: 15
  },
  CancelledIcon: {
    width: 64,
    height: 64
  },
  ProcessIcon: {
    width: 64,
    height: 64
  },

  MimaMainWrapper: {
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: '#fff'
  },
  stickyHeaderWhite: {
    paddingHorizontal: 15,
    paddingVertical: 20,
    elevation: 3,
    backgroundColor: '#fff',
    marginBottom: 1,
    shadowColor: '#000',
    shadowOffset: {width: 0, height: 2},
    shadowOpacity: 0.3,
    shadowRadius: 2

  },
  mimaInnerDtls: {
    paddingVertical: 20,
    paddingHorizontal: 25
  },
  discStyle: {
    width: 8,
    height: 8,
    backgroundColor: '#4a4a4a',
    borderRadius: 20,
    marginTop: 5,
    marginRight: 10
  },
  FAQheaderTap: {
    paddingVertical: 15,
    elevation: 2,
    backgroundColor: '#fff',
    marginBottom: 2,
    shadowColor: '#000',
    shadowOffset: {width: 0, height: 1},
    shadowOpacity: 0.3,
    shadowRadius: 2,
    justifyContent: 'space-between',
    paddingHorizontal: 15
  },
  BlueArrowTB: {
    width: 14,
    height: 12
  },
  FAQDetailsWrapper: {
    paddingHorizontal: 20,
    paddingVertical: 15
  },
  IOSbackarrow: {
    width: 10,
    height: 16
  },
  topStatusIconReceived: {
    width: 51,
    height: 50
  },
  ApprovedIcon: {
    width: 54,
    height: 57
  },
  RejectedIcon: {
    width: 54,
    height: 57
  },
  /* Add Guest Css */
  WhiteStickyHeader: {
    backgroundColor: '#fff',
    paddingTop: 10,
    paddingBottom: 10,
    elevation: 3,
    shadowColor: '#000',
    shadowOffset: {width: 0, height: 2},
    shadowOpacity: 0.3,
    shadowRadius: 2
  },
  GuestInfoStrip: {
    backgroundColor: '#fff',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 10,
    paddingTop: 10,
    paddingLeft: 15,
    paddingRight: 15,
    marginBottom: 10,
    alignItems: 'center',
    elevation: 2
  },
  VisaInfoStrip: {
    flexDirection: 'row',
    paddingBottom: 10,
    paddingTop: 10,
    paddingLeft: 15,
    paddingRight: 15,
    marginBottom: 10,
    alignItems: 'center',
    elevation: 2
  },
  AddGuestCard: {
    backgroundColor: '#fff',
    elevation: 2,
    shadowColor: '#000',
    shadowOffset: {width: 0, height: 0},
    shadowOpacity: 0.3,
    shadowRadius: 2,
    marginHorizontal: 15,
    marginBottom: 5,
    borderRadius: 4,
    overflow: 'hidden'
  },
  addGuestHead: {
    color: '#000',
    fontSize: 16,
    textAlign: 'center',
    fontFamily: fonts.bold,
    paddingVertical: 15,
    backgroundColor: '#fff'
  },
  guestDetails: {
    paddingHorizontal: 15,
    paddingVertical: 10
  },
  addGuestCounter: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    elevation: 3,
    shadowColor: '#000',
    shadowOffset: {width: 0, height: 0},
    shadowOpacity: 0.3,
    shadowRadius: 2,
    borderRadius: 4,
    overflow: 'hidden',
    width: '90%'
  },

  counterVal: {
    fontSize: 22,
    color: '#000',
    fontFamily: fonts.bold,
    paddingHorizontal: 15,
    paddingVertical: 10
  },
  minus: {marginTop: -10},
  checkAvlBtn: {
    paddingVertical: 18,
    width: '92%',
    marginHorizontal: 15,
    borderRadius: 3,
    overflow: 'hidden'
  },

  GuestDetailsRow: {
    backgroundColor: '#ffffff',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 15
  },
  ErrorSuccessWrapper: {
    paddingHorizontal: 10
  },

  ErrorIcon: {
    width: 28,
    height: 28,
    marginRight: 10,
    marginTop: 1
  },
  SuccessIcon: {
    width: 28,
    height: 28,
    marginRight: 10,
    marginTop: 1
  },
  flexWrap: {
    flexWrap: 'wrap'
  },
  ErrorSuccesstext: {
    width: '88%'

  },
  HotelRoomSection: {
    paddingHorizontal: 15,
    paddingVertical: 12,
    alignItems: 'center'
  },
  LinkText: {
    paddingTop: 2
  },
  bfIcon: {
    width: 22,
    height: 22,
    marginLeft: 5
  },
  RoomPriceInfo: {
    padding: 15
  },
  RoomPriceInfoRow: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  RoomAlert: {
    backgroundColor: '#e9f8f3'
  },
  RoomAlertText: {
    color: '#18a384',
    textAlign: 'center',
    paddingVertical: 5
  },

  RoomLeftText: {
    justifyContent: 'flex-end',
    textAlign: 'right',
    flex: 1,
    color: '#e53442',
    fontSize: 12,
    fontStyle: 'italic',
    marginTop: 2
  },
  infoIconBlue: {
    width: 18,
    height: 18,
    marginLeft: 5
  },
  BreakupTextBtm: {
    marginTop: 3
  },
  MAMIBlackFooter: {
    backgroundColor: '#4a4a4a',
    padding: 15,
    zIndex: 9,
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
  },
  FooterLeft: {
    flex: 1,
    borderRightWidth: 1,
    borderRightColor: '#ffffff',
    flexDirection: 'column'
  },
  FooterRight: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-end'

  },
  FooterBtn: {
    width: 120
  },
  PriceBookedAt: {
    backgroundColor: '#f2f2f2'
  },
  calDates: {
    fontSize: 36,
    color: '#000',
    marginRight: 5
  },
  dateInfoWrap: {
    paddingVertical: 30,
    paddingLeft: 30
  },
  dateInfoOuter: {
    backgroundColor: '#fff',
    borderColor: '#ddd',
    borderTopWidth: 1,
    borderBottomWidth: 1,
    elevation: 2,
    marginBottom: 10
  },
  /* confirmation Style */
  ConfirmationWrapper: {
    backgroundColor: '#ffffff',
    elevation: 2,
    marginBottom: 5
  },
  ConfirmationHeading: {
    padding: 15,
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#ddd'
  },
  GuestIcon: {
    width: 23,
    height: 15,
    marginRight: 5
  },
  ConfirmationDtls: {
    paddingTop: 10,
    paddingBottom: 15,
    paddingHorizontal: 45
  },

  lineHt22: {
    lineHeight: 22
  },
  lineHt24: {
    lineHeight: 24
  },
  GreenTickIcon: {
    width: 20,
    height: 20
  },
  FreeCancellation: {
    flexDirection: 'row'
  },
  ConfirmBtn: {
    marginHorizontal: 5,
    marginVertical: 5
  },

  /* Error Page */
  ErrorPageWrapper: {
    backgroundColor: '#ffffff',
    paddingVertical: 20,
    paddingHorizontal: 40

  },
  ErrorRoomImage: {
    width: 107,
    height: 113
  },
  mar40Bt: {
    marginBottom: 40
  },
  ErrorPageText: {
    fontSize: 20,
    textAlign: 'center',
    lineHeight: 28,
    color: '#3c3c3c'
  },
  ChatText: {
    color: '#008cff'
  },
  ErrorPageBtn: {
    width: 250
  },
  ErrorWrapper: {
    paddingRight: 10
  },
  RoomAvailableText: {
    color: '#18a384',
    textAlign: 'left',
    paddingVertical: 5,
    paddingHorizontal: 15
  },
  DateChangeIcon: {
    width: 18,
    height: 18,
    marginRight: 15
  },
  mar50Bt: {
    marginBottom: 50
  },
  mar80Bt: {
    marginBottom: 80
  },
  CommonFillBtn: {
    borderRadius: 25,
    paddingVertical: 14,
    paddingHorizontal: 25
  },
  justifyCenter: {
    justifyContent: 'center'
  },
  dateChangeIcon: {
    width: 70,
    height: 70
  },
  bggreyLight: {
    backgroundColor: '#f9f9f9'
  },
  CancelationDtls: {
    padding: 15,
    backgroundColor: '#ffffff'
  },
  cancIcon: {
    width: 24,
    height: 24,
    marginRight: 10,
    marginTop: 2
  },
  cancellationStatesText: {
    flex: 0.95
  },
  errorRedTxt: {
    color: '#e53442'
  },
  greenTick: {
    width: 16,
    height: 16,
    marginRight: 10,
    marginTop: 3
  },
  lineHt17: {
    lineHeight: 17
  },
  mar3Tp: {
    marginTop: 3
  },
  mediumFont: {
    fontFamily: fonts.medium
  },
  JustifySpaceBetween: {
    justifyContent: 'space-between'
  },
  CancPriceBreckup: {
    justifyContent: 'space-between',
    padding: 16
  },
  CancLeftSection: {
    flexDirection: 'column'
  },
  RoomCancelledWrapper: {
    padding: 15
  },
  RoomCancelled: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  CancellationWrapper: {
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: '#f2f2f2'
  },
  CancellationPaidWrapper: {
    borderTopWidth: 1,
    borderTopColor: '#ddd',
    paddingTop: 10,
    marginTop: 5
  },
  PartCancellationWrapper: {
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: '#000'

  },
  PartCancellationHead: {
    backgroundColor: '#ffffff',
    paddingVertical: 18,
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#ddd'

  },
  PickAppCard: {
    paddingHorizontal: 15,
    paddingVertical: 15,
    backgroundColor: '#ffffff'
  },
  chkboxicon: {
    width: 28,
    height: 28,
    marginRight: 8,
    marginTop: 5
  },
  PartCancInfoWrapper: {
    paddingHorizontal: 10,
    paddingBottom: 15
  },
  PartCancInfoDtls: {
    backgroundColor: '#c8f8f6',
    borderRadius: 4,
    paddingHorizontal: 12,
    paddingVertical: 10
  },
  PartInfoTxt: {
    color: '#249995',
    flex: 1
  },
  OrDivider: {
    height: 50,
    justifyContent: 'center',
    alignItems: 'center'
  },
  OrLine: {
    height: 1,
    backgroundColor: '#d7d7d7',
    width: '100%'

  },
  OrCircle: {
    borderWidth: 1,
    borderColor: '#d7d7d7',
    width: 32,
    height: 32,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 50,
    position: 'absolute',
    backgroundColor: '#f2f2f2'
  },
  adultIcon2: {
    width: 18,
    height: 18
  },
  mealIcon: {
    width: 22,
    height: 22
  },
  refundRupeeIcon: {
    width: 28,
    height: 28,
    marginRight: 10
  },
  RecFullBtnCard: {
    backgroundColor: '#fff',
    paddingBottom: 18,
    paddingTop: 18,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 15,
    paddingRight: 25,
    borderBottomWidth: 1,
    borderBottomColor: '#e4e4e4'
  },
  CardContentTracker: {
    backgroundColor: '#fff',
    borderBottomWidth: 1,
    borderBottomColor: '#e4e4e4',

    paddingTop: 18,
    paddingBottom: 0
  },
  particon: {
    width: 70,
    height: 55
  },

  padHor30: {
    paddingHorizontal: 30
  },
  VisaCount: {
    backgroundColor: '#9b9b9b', width: 14, height: 14, borderRadius: 50, marginRight: 4, color: '#fff', fontSize: 11, alignItems: 'center', justifyContent: 'center', textAlign: 'center'
  },
  VisaCountActive: {backgroundColor: '#000'},
  VisaActiveText: {color: '#000'},
  elevation1: {elevation: 1},
  VisaCancelPerson: {paddingVertical: 12, paddingHorizontal: 16},
  mar8LR: {marginHorizontal: 8},
  paddingBottom12: {paddingBottom: 12},


  priceBreakupMain: {
    marginLeft: 15, marginRight: 15, paddingTop: 10, paddingBottom: 15
  },

  VisaLeft: {
    flex: 0.9
  },


  VisaOffer: {width: 16, height: 16, marginLeft: 5},


  crossImg: {
    width: 24,
    height: 24
  },
  mar12Lt: {marginLeft: 12},
  femaleIcon: {
    width: 16,
    height: 16
  },
  MimaWhiteCard: {
    paddingVertical: 20,
    paddingHorizontal: 17,
    backgroundColor: '#fff',
    marginBottom: 1,
    shadowColor: '#000',
    shadowOffset: {width: 0, height: 2},
    shadowOpacity: 0.3,
    shadowRadius: 2,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    borderTopWidth: 1,
    borderTopColor: '#ccc'
  },
  coutionIcon: {
    width: 22,
    height: 21
  },
  VisaRjectedText: {
    color: '#4a4a4a',
    paddingLeft: 30,
    lineHeight: 20
  },
  whyRejectedTxt: {
    width: '90%',
    paddingLeft: 10
  },
  FaqIcon: {
    width: 19,
    height: 19
  },
  TOVMainWrapper: {
    marginTop: 20
  },
  UploadDocWrapper: {
    paddingHorizontal: 20,
    paddingVertical: 18
  },
  VisaMainContainer: {
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: '#f0f0f0'
  },
  VerifyDocWrapper: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  VerifyDocItem: {
    width: '48%',
    borderRadius: 4,
    backgroundColor: '#fff',
    elevation: 2,
    marginBottom: 5,
    marginTop: 6,
    shadowColor: '#000',
    shadowOffset: {width: 0, height: 1},
    shadowOpacity: 0.3,
    shadowRadius: 2
  },
  VerifyDocItemImage: {
    width: '100%',
    height: '100%',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4

  },
  VerifyDocItemTitle: {
    position: 'absolute',
    width: '100%',
    paddingHorizontal: 10,
    bottom: 0,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    backgroundColor: '#fff',
    elevation: 6,
    marginTop: 3,
    padding: 8
  },
  pdfIcon: {
    width: 18,
    height: 18,
    marginRight: 8
  },
  Height155: {
    height: 155
  },
  pdfIcon: {
    width: 18,
    height: 18,
    marginRight: 8
  },
  VerifyPopupTopSection: {
    paddingVertical: 30,
    paddingHorizontal: 20,
    borderBottomColor: '#ededed',
    borderBottomWidth: 1
  },
  VerifyTopSectionTxt: {
    color: '#249995',
    fontSize: 14,
    fontFamily: fonts.bold,
    textAlign: 'center',
    marginTop: 17

  },
  VerifyDocBgGradient: {
    width: '100%',
    height: '100%',
    alignItems: 'center'
  },
  PdfWhiteIcon: {
    width: 41,
    height: 54,
    position: 'relative',
    top: 35
  },
  JPGWhiteIcon: {
    width: 47,
    height: 54,
    position: 'relative',
    top: 35
  },
  OpenPDFWrapper: {
    borderWidth: 2,
    borderColor: '#4a4a4a',
    padding: 20,
    position: 'relative'

  },
  PDFImageScreen: {
    width: '100%',
    height: 535,
    resizeMode: 'contain'
  },
  trackerContainer: {
    padding: 16
  },
  trackerSubContainer: {paddingLeft: 17, position: 'relative'},
  trackingBall: {
    position: 'absolute', zIndex: 3, left: 2, width: 32, height: 32, borderWidth: 1, borderColor: '#00a19c', borderRadius: 50, backgroundColor: '#fff', alignItems: 'center', justifyContent: 'center'
  },
  visaTrackerText: {
    borderLeftWidth: 2, borderLeftColor: '#00a19c', paddingLeft: 40, position: 'relative', zIndex: 2, paddingBottom: 24
  },
  visaTrackerTextError: {borderLeftColor: '#e5e5e5'},
  visaTrackerTextLast: {
    paddingLeft: 38, position: 'relative', zIndex: 2, paddingBottom: 24
  },
  activeText: {fontSize: 14, color: '#3b385e', fontFamily: fonts.black},
  activeTextStatus: {color: '#000'},
  activeTextError: {fontSize: 14, color: '#e53442', fontFamily: fonts.black},
  errorFill: {backgroundColor: '#ffeae1', borderColor: '#e53442'},
  trackingBallGrey: {borderColor: '#e5e5e5'},
  trackingBallLightCream: {borderColor: '#f1a525', backgroundColor: '#fff2dd'},
  visaTrackerTextlightCream: {borderColor: '#f1a525'},
  marginLeft20Negative: {marginLeft: -20},
  marginLeft15Negative: {marginLeft: -20},
  trackingInfoActiveGreen: {
    backgroundColor: '#cdf6e8', padding: 12, borderRadius: 4, position: 'relative', flex: 1
  },
  trackerCheck: {width: 12, height: 10},
  tipIcon: {width: 8, height: 12, marginTop: 12},
  lightCreamActiveBg: {
    backgroundColor: '#fff4e3', padding: 12, borderRadius: 4, position: 'relative', flex: 1
  },
  trackingInfoActiveCream: {
    backgroundColor: '#ffeae1', padding: 12, borderRadius: 4, position: 'relative', flex: 1
  },
  AllDocumentItem: {
    elevation: 2,
    marginTop: 8,
    marginBottom: 8,
    shadowColor: '#000',
    shadowOffset: {width: 0, height: 1},
    shadowOpacity: 0.2,
    shadowRadius: 2,
    backgroundColor: '#fff',
    paddingHorizontal: 10,
    borderRadius: 2,
    minHeight: 34,
    alignItems: 'center'
  },
  blueArrow: {
    width: 13,
    height: 7
  },
  formIcon: {
    width: 16,
    height: 22
  },
  TicketIcon: {
    width: 21,
    height: 14
  },
  PassportIcon: {
    width: 18,
    height: 22
  },
  PhotoIcon: {
    width: 20,
    height: 20
  },
  LandingAllDocument: {
    padding: 15
  },
  blueLink: {
    color: '#008cff'
  },
  bgWhite: {
    backgroundColor: '#ffffff'
  },
  additionalCard: {
    fontFamily: fonts.bold,
    fontSize: 12,
    lineHeight: 18,
    marginBottom: 20,
    color: '#d0021b'
  },
  passengerName: {
    fontSize: 14,
    color: colors.black,
    fontFamily: fonts.bold

  },
  passengerGender: {
    fontSize: 12,
    alignSelf: 'center',
    color: '#4a4a4a',
    fontFamily: fonts.regular,
    marginLeft: 8
  },
  cancellationPolicyCard: {
    fontFamily: fonts.black,
    color: colors.black,
    fontSize: 14,
    marginTop: 4,
    marginBottom: 12
  },
  cancellationPolicyText: {
    fontSize: 14,
    color: '#4a4a4a',
    lineHeight: 22,
    marginBottom: 5,
    fontFamily: fonts.regularFont
  },
  visaPaymentCard: {
    fontFamily: fonts.black,
    color: colors.black,
    marginBottom: 8,
    fontSize: 14
  },
  visaTotalSaved: {
    color: '#00a19c',
    fontFamily: fonts.bold
  },
  visaReceivedAmount: {
    fontSize: 18,
    color: colors.black,
    fontFamily: fonts.black
  },
  visaSellingPrice: {
    fontSize: 12,
    color: '#eb2026',
    textAlign: 'right',
    textDecorationLine: 'line-through',
    fontFamily: fonts.mediumFont
  },
  CollapsedTapDtlsContainer1: {
    backgroundColor: 'transparent',
    paddingHorizontal: 20
  },
  VisaFaqHTML: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexWrap: 'wrap'
  },
  CollapsedTapContainer1: {
    backgroundColor: 'transparent',
    paddingHorizontal: 20,
    paddingTop: 20,
    paddingBottom: 15,
    flexDirection: 'row'
  },
  CollapsedTapLeftSection: {
    width: '70%'

  },
  CollapsedTapRightSection: {
    width: '30%'
  },
  BlueArrow: {
    width: 14,
    height: 12
  },
  CollapsedContainer1: {
    backgroundColor: '#fff',
    borderBottomWidth: 1,
    borderBottomColor: '#ededed'
  },
  AlignSelfRight: {
    alignSelf: 'flex-end'
  },
  visaAdditionalDocumentCard: {
    fontFamily: fonts.black,
    color: colors.black,
    fontSize: 14,
    marginTop: 4,
    marginBottom: 12
  },
  visaAdditionalDocumentCardMessage: {
    fontSize: 14,
    color: '#4a4a4a',
    lineHeight: 22,
    marginBottom: 5
  },
  topStatusHeader: {
    color: colors.white,
    textAlign: 'center',
    fontSize: 18,
    fontFamily: fonts.black,
    lineHeight: 24,
    marginBottom: 12,
    backgroundColor: 'transparent'
  },
  topStatusHeaderSubText: {
    color: colors.white,
    textAlign: 'center',
    fontSize: 18,
    fontFamily: fonts.black,
    lineHeight: 24,
    marginBottom: 12,
    backgroundColor: 'transparent'
  },
  lightGreyBg: {backgroundColor: '#f2f2f2'},
  infoSection:{
    flexDirection:'row',
    marginBottom:15
  },
  infoSectionTxt:{
    color:'#db7e00',
    fontFamily:fonts.bold,
    fontSize:12,
    flex:1,
  },
  yellowText:{
    color:'#db7e00'
  }
};

export default styles;
