import React from 'react';
import {Text, TouchableOpacity} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import PropTypes from 'prop-types';
import getStyles from '../components/HotelMamiCss';
import { getFont, themeColors } from '../../../PostSalesStyles';

const FullWidthButton = ({buttonText, clickHandler, buttonGradientColor, isCorporateBooking}) => {
  const { ...fonts } = getFont(true);
  const styles = getStyles(fonts);
  let btnGradientColor = buttonGradientColor;
  if(!buttonGradientColor){
    btnGradientColor = themeColors.FullWidthButtonGradientColor;
  }
  if(isCorporateBooking) {
    btnGradientColor = themeColors.myBizGradientColor
  }
  return (
    <TouchableOpacity onPress={() => clickHandler()} >
      <LinearGradient
        start={{
          x: 1.0,
          y: 0.0
        }}
        end={{
          x: 0.0,
          y: 1.0
        }}
        colors={btnGradientColor}
        style={[styles.fullWidthBtn]}
      >
        <Text style={[styles.WhiteText,
          styles.CenterText, styles.font14, styles.blackFont, styles.transperantBgrnd]}
        >{buttonText}
        </Text>
      </LinearGradient>
    </TouchableOpacity>
  )
};

FullWidthButton.propTypes = {
  buttonText: PropTypes.string.isRequired,
  clickHandler: PropTypes.func.isRequired
};

export default FullWidthButton;
