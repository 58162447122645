import { StyleSheet, Dimensions } from 'react-native';
import getPlatformElevation from '@mmt/legacy-commons/Common/Components/Card/getPlatformElevation';
import { DEFAULT_THEME } from '../../../../theme/default.theme';

export default <T extends typeof DEFAULT_THEME>(theme: T) =>  StyleSheet.create({
    mainWrapper: {
      flex: 1,
      backgroundColor: theme.color.grayBg,
    },
    scrollWrapper: {
      padding: 16,
    },
    tabsWrapper: {
      backgroundColor: theme.color.grey22,
      flexDirection: 'row',
      paddingVertical: 10,
      width: Dimensions.get('window').width,
      ...getPlatformElevation(2),
      borderBottomColor : theme.color.gray8,
      borderBottomWidth: 1,
    },
    hideTopShadow: {
      backgroundColor: theme.color.grey22,
      height: 10,
      position: 'absolute',
      width: '100%',
      top: -3,
    },
    topSliderCard: {
      backgroundColor: theme.color.grey22,
      padding: 16,
      borderBottomStartRadius: 16,
      borderBottomEndRadius: 16,
      margin: -16,
    },
    overlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      backgroundColor: theme.color.grey20,
      height: '100%',
      width: '100%',
      zIndex: 1,
      elevation: 3,
    },
  });
