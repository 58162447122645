import React, {Component} from 'react';
import {View, Text, Image, StyleSheet, TouchableOpacity} from 'react-native';
import {colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import AtomicCss from '../../../commonStyles/AtomicCss';
import { label } from  '@mmt/post-sales/src/staticData/staticData';

class ACIssueCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
          activeRadioIndex: 0, 
          option: ''
        };
        props.updateACOption(props.listOptions[0]);
      }
    
      onRadioSelection = (option, activeRadioIndex) => {
        this.props.updateACOption(option);
        this.setState({ option, activeRadioIndex })
      }

      renderRadioList = () => {
        return (
          <View>
            {
              this.props.listOptions.map((item, index) => {
                const isSelected = this.state.activeRadioIndex === index;
                const selectedStyle = isSelected ? 'Selected' : '';
                return (
                  <TouchableOpacity style={index=== 3 ? [styles.radioItemLast]: [styles.radioItem]} onPress={() => this.onRadioSelection(item, index)} activeOpacity={0.8}>
                    <View style={[AtomicCss.flex1, AtomicCss.marginRight10]}>
                        <Text style={[AtomicCss.font12, AtomicCss.darkGreyText, AtomicCss.regularFont]}>{item}</Text>                       
                    </View>
                    <View style={[styles.radio, styles['radio' + selectedStyle]]}>
                      {isSelected &&
                        <View style={styles.radioSelectedCircle} />
                      }
                    </View>
                  </TouchableOpacity>
                )
              })
            }
          </View>
        );
      }
    render(){
        return (
          <View style={[AtomicCss.whiteCardSection, styles.sectionCard, AtomicCss.marginBottom10]}>
            <Text style={[AtomicCss.defaultText, AtomicCss.font16, AtomicCss.boldFont, AtomicCss.marginBottom18]}>
              {label('bus.issue.howLongAcNotWorking')}</Text>
            <View>
                {this.renderRadioList()}
            </View> 
          </View>
        )
    }
}

const styles = StyleSheet.create({
      radioItem: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottomWidth: 1, 
        borderBottomColor: '#e1e7ee',
        paddingHorizontal: 0, 
        paddingVertical: 13
      },
      radioItemLast:{flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingHorizontal: 0, 
      paddingVertical: 13},
      radioItemName: {
        fontSize: 14,
        color: colors.dark,
        fontFamily: fonts.quicksandBold,
        marginBottom: 4
      },
      radio: {
        width: 18,
        height: 18,
        borderRadius: 10,
        borderColor: colors.iconGrey,
        borderWidth: 2,
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 10,
        marginTop: 5
      },
      radioSelected: {
        borderColor: '#008cff',
      },
      radioSelectedCircle: {
        width: 9,
        height: 9,
        borderRadius: 5,
        backgroundColor: '#008cff'
      },
      sectionCard:{padding: 16, paddingBottom: 4}
    });

export default ACIssueCard;