import {
  DATE_MONTH,
  POSTSALES_DATE_TIME,
  reformatDateString,
} from '@mmt/legacy-commons/Helpers/dateTimehelpers';
import { titleCase } from '@mmt/legacy-commons/Helpers/stringHelpers';
import { PAGE_BOOKINGS_STATUS } from '../../../summaryConstants';
import myTripsNavigation from '../../../navigation/navigation';
import { COMPLETED, UPCOMING } from './constants';
function getInsuranceSummary(insuranceDetails = []) {
  const { leadPaxName } = insuranceDetails[0] || {};
  const count = insuranceDetails.length;
  const firstPaxName = titleCase(leadPaxName);
  if (count <= 1) {
    return firstPaxName;
  }
  return `${firstPaxName} + ${count - 1}`;
}
export function getInsurancesSummaryData(data) {
  const insuranceDetails = (data && data.insuranceDetails) || [];
  const { insuranceProvider, productName, policyStartDateTime, policyEndDateTime } =
    insuranceDetails[0] || {};
  const checkInDate =
    reformatDateString(policyStartDateTime, POSTSALES_DATE_TIME, DATE_MONTH) || '';
  const checkoutDate = reformatDateString(policyEndDateTime, POSTSALES_DATE_TIME, DATE_MONTH) || '';
  const insurerSummary = getInsuranceSummary(insuranceDetails);
  const dateTimeWithPaxInfo = insurerSummary
    ? `${checkInDate} - ${checkoutDate} • ${insurerSummary}`
    : `${checkInDate} - ${checkoutDate}`;

  const pageData = {
    fromMyTrips: true,
  };
  const openDetails = () => {
    myTripsNavigation.openInsuranceBookingDetail(data, pageData);
  };
  return {
    status: data.status,
    iconUrl: data.status === PAGE_BOOKINGS_STATUS.UPCOMING ? UPCOMING : COMPLETED,
    packageName: productName,
    dateTimeWithPaxInfo,
    insuranceInfo: insuranceProvider || '',
    openDetails,
    bookingID: data.bookingID,
  };
}
