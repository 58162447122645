import React from 'react';
import { View, Image } from 'react-native';
import Carousel from '@Frontend_Ui_Lib_App/Carousel';
import { getFont } from '../../PostSalesStyles';
import { useTheme } from '../../theme/theme.context';
import { getStyles } from './styles';

interface CarousalItem {
  id: number;
  imgSrc: string;
}

interface CarousalWprProps {
  data: CarousalItem[];
  slideTimer?: number;
  carousalCustomStyle?: any;
  activeDotStyle?: any;
  cardWprStyle?: any;
  inactiveDotStyle?: any;
}

const CarouselWpr = ({
  data,
  slideTimer,
  carousalCustomStyle,
  activeDotStyle,
  cardWprStyle,
  inactiveDotStyle,
}: CarousalWprProps) => {
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = getStyles(theme, fonts);

  const renderBannerCard = (item: any) => (
    <View style={styles.container}>
      <Image style={[styles.imageStyle]} source={{ uri: item.imgSrc }} />
    </View>
  );

  return (
    <Carousel
      autoSlide
      data={data}
      keyIdentifier="id"
      bannerCard={renderBannerCard}
      customStyles={carousalCustomStyle || styles.carousalCustomStyle}
      customActiveDotStyle={activeDotStyle || styles.activeDot}
      customCardsWprStyle={cardWprStyle || styles.cardWpr}
      customInActiveDotStyle={inactiveDotStyle || styles.inActiveDot}
      customSlideTimer={slideTimer || 3000}
    />
  );
};

export default CarouselWpr;
