import { Platform } from 'react-native';

const styles = {
  WebCheckinOverlayWrapper: {
    margin: -20,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    backgroundColor: '#fff',
    marginBottom: Platform.OS === 'ios' ? 0 : 10,
  },
  logoMYSafety: { width: 20, height: 20, marginRight: 12 },
  checkListMain: {
    padding: 16,
    paddingRight: 0,
    paddingLeft: 30,
  },
  sectionRow: {
    borderLeftWidth: 1,
    borderLeftColor: '#e7e7e7',
    paddingLeft: 25,
  },
  lastEleBorO: {
    borderLeftWidth: 0,
    borderLeftColor: 'transparent',
    paddingLeft: 25,
  },
  dtlsSection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderBottomColor: '#e7e7e7',
    paddingBottom: 15,
    paddingRight: 15,
    marginBottom: 15,
  },
  lastItem: {
    paddingBottom: 0,
    marginBottom: 0,
    borderBottomWidth: 0,
    borderBottomColor: 'transparent',
  },
  countWrapper: {
    width: 20,
    height: 20,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: '#e7e7e7',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
    position: 'absolute',
    left: -10,
  },
  countingTxt: {
    color: '#4a4a4a',
  },
  btnWrapper: {
    padding: 15,
    borderTopWidth: 1,
    borderTopColor: '#e3e3e3',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  linkText: {
    color: '#008cff',
    fontSize: 12,
  },
  arrowIconStyle: {
    width: 16,
    height: 16,
    marginLeft: 5,
  },
  activeArrowIconStyle: {
    width: 16,
    height: 16,
    transform: [{ rotate: '180deg' }],
    marginLeft: 5,
  },
  headingDtls: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 16,
  },
  ViewAllTxt: {
    marginLeft: 30,
  },
  crossIconActionWrapper: {
    position: 'absolute',
    right: 20,
    top: -30,
  },
  crossIconStyle: {
    width: 14,
    height: 14,
    tintColor: '#fff',
  },
  MY_SAFETY_LOGO: {
    marginRight: 12,
    width: 20,
    height: 20,
  },
};

export default styles;
