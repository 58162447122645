import { Platform, StyleSheet, Dimensions, I18nManager } from 'react-native';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from '@mmt/legacy-commons/Common/Components/Card/getPlatformElevation';
import { themeColors } from '../../../PostSalesStyles';

const windowHeight = Dimensions.get('screen').height;
const windowWidth = Dimensions.get('screen').width;

const getStyles = (fonts) => {
  return StyleSheet.create({
    breadCumbOuter: {
      flexDirection: "row",
      justifyContent: "space-around",
      backgroundColor: colors.gray4,
      paddingVertical: 8,

    },
    overlayWrapper: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      height: windowHeight,
      width: windowWidth,
      elevation: 10,
      zIndex: 100,
      justifyContent: 'flex-end'
    },
    OverlayContentWrapper: {
      backgroundColor: colors.white,
      paddingHorizontal: 12,
      position: 'relative',
      zIndex: 1,
      width: '100%',
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
    },
    overlayContentStyle: {
      backgroundColor: colors.white,
      padding: 20,
      paddingTop: 15,
      position: 'relative',
      zIndex: 1,
      width: '100%',
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
    },
    overlayWrapperStyle: {
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      elevation: 10,
      zIndex: 100,
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      flexDirection: 'row',
    },
    overlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      backgroundColor: 'rgba(0,0,0,0.5)',
      height: '100%',
      width: '100%',
      zIndex: 1
    },
    overlayContent: {
      backgroundColor: colors.white,
      padding: 20,
      position: 'absolute',
      zIndex: 1,
      width: '100%'
    },
    rtArrowStyle: {
      width: 10, height: 9, marginHorizontal: 5
    },
    bodybg: {
      backgroundColor: '#f2f2f2'
    },
    nonActiveBreadcumb: {
      color: colors.greyLight,
      ...fonts.font12,
    },
    activeBreadCumb: {
      color: colors.defaultTextColor,
      ...fonts.font12,
      fontFamily: fonts.black
    },
    flex1: {
      flex: 1
    },
    font14: {
      ...fonts.font14
    },
    font18: {
      ...fonts.font18
    },
    font16: {
      ...fonts.font16
    },
    font11: {
      ...fonts.font11
    },
    font12: {
      ...fonts.font12
    },
    font13: {
      ...fonts.font13
    },
    font15: {
      ...fonts.font15
    },
    font20: {
      ...fonts.font20,
    },
    font24: {
      ...fonts.font24
    },
    cancelIcon: {
      width: 65,
      height: 40,
      marginRight: 15
    },
    GreyBg: {
      backgroundColor: '#f6f6f6'
    },
    darkGreyBg: {
      backgroundColor: '#e7e7e7'
    },
    whitebg: {
      backgroundColor: '#fff'
    },
    mar10Top: {
      marginTop: 10
    },
    mar1Bt: {
      marginBottom: 1
    },
    mar15Top: {
      marginTop: 15
    },
    pad10Top: {
      paddingTop: 10
    },
    pad15Top: {
      paddingTop: 15
    },
    mar25Bt: {
      marginBottom: 25
    },
    mar20Bt: {
      marginBottom: 20
    },
    mar15Bt: {
      marginBottom: 15
    },
    mar10Bt: {
      marginBottom: 10
    },
    mar18Bt: {
      marginBottom: 10
    },
    mar5Bt: {
      marginBottom: 5
    },
    mar4Bt: {
      marginBottom: 4
    },
    mar3Bt: {
      marginBottom: 3
    },
    mar5Lt: {
      marginLeft: 5
    },
    mar3Lt: {
      marginLeft: 5
    },
    mar13Lt: {
      marginLeft: 13
    },
    mar5Rt: {
      marginRight: 5
    },
    mar10Rt: {
      marginRight: 10
    },
    mar15Rt: {
      marginRight: 15
    },
    mar30Bt: {
      marginBottom: 30
    },
    paddingHz12: {
      paddingHorizontal: 12
    },
    lineHeight16: {
      lineHeight: 16
    },
    flexRow: {
      flexDirection: 'row'
    },
    RoomCancelled: {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between'
    },
    alignCenter: {
      alignItems: 'center'
    },
    borderbtm: {
      borderBottomWidth: 1,
      borderBottomColor: '#ddd'
    },
    lineHeight20: {
      lineHeight: 20
    },
    boldFont: {
      fontFamily: fonts.bold
    },
    blackFont: {
      fontFamily: fonts.black
    },
    lightFont: {
      fontFamily: fonts.light
    },
    regularFont: {
      fontFamily: fonts.regular
    },
    greenText: {
      color: '#3eb584'
    },
    timerTextYellowColor: {
      color: '#e5c055'
    },
    BlueText: {
      color: themeColors.primary,
      fontFamily: fonts.bold
    },
    WhiteText: {
      color: colors.white
    },
    BlackText: {
      color: colors.black
    },
    DefaultText: {
      color: colors.defaultTextColor
    },
    CenterText: {
      alignSelf: 'center'
    },
    AlignCenter: {
      textAlign: 'center'
    },
    AlignRight: {
      textAlign: 'right'
    },
    AlignLeft: {
      textAlign: 'left'
    },
    fontWeightB: {
      fontWeight: 'bold'
    },
    MamiCard: {
      backgroundColor: '#fff'
    },
    description: {
      paddingRight: 10,
      width: '90%'
    },
    whiteBtnSingle: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignSelf: 'center',
      width: 170
    },
    CardContent: {
      backgroundColor: '#fff',
      borderBottomWidth: 1,
      borderBottomColor: '#e4e4e4',
      flexDirection: 'row',
      padding: 15,
      elevation: 1,
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 1
      },
      shadowOpacity: 0.3,
      shadowRadius: 1
    },
    CardContentNew: {
      flexDirection: 'row',
      padding: 12
    },
    CardContentFull: {
      backgroundColor: '#fff',
      borderBottomWidth: 1,
      borderBottomColor: '#e4e4e4',
      padding: 15,
      elevation: 1,
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 1
      },
      shadowOpacity: 0.3,
      shadowRadius: 1
    },
    RecFullBtn: {
      backgroundColor: themeColors.rectFullButtonBgColor,
      paddingBottom: 20,
      paddingTop: 20,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingLeft: 45,
      paddingRight: 35,
      borderBottomWidth: 1,
      borderBottomColor: '#e4e4e4'
    },
    RecFullBtnRadius: {
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10
    },

    CancellationFooter: {
      paddingVertical: 16,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center'
    },

    CapsuleBtn: {
      backgroundColor: '#fff',
      paddingBottom: 12,
      paddingTop: 12,
      paddingLeft: 10,
      paddingRight: 10,
      borderWidth: 1,
      borderColor: '#53b2fe',
      borderRadius: 5,
      flex: 1,
      marginRight: 10,
      marginLeft: 10
    },

    CapsuleBtnFill: {
      paddingBottom: 12,
      paddingTop: 12,
      paddingLeft: 10,
      paddingRight: 10,
      borderRadius: 5,
      marginRight: 10,
      marginLeft: 10
    },

    FooterBtnFill: {
      paddingBottom: 11,
      paddingTop: 7,
      paddingLeft: 21,
      paddingRight: 21,
      borderRadius: 20
    },
    CapsuleBtnFillOuter: {
      flex: 1
    },
    TypeCapsule: {
      borderRadius: 34
    },
    TypePrimary: {
      borderRadius: 2
    },
    TypeRectangle: {
      borderRadius: 3
    },
    TypeFlat: {
      borderRadius: 0
    },
    btnTextBlue: {
      color: '#008cff',
      ...fonts.font14,
      fontFamily: fonts.black,
      textAlign: 'center'
    },
    btnTextWhite: {
      color: '#ffffff',
      ...fonts.font14,
      fontFamily: fonts.black,
      textAlign: 'center'
    },
    btnMainWrapper: {
      width: '100%'
    },
    wrapperShadow: {
      elevation: 3,
      backgroundColor: '#fff',
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 2
      },
      shadowOpacity: 0.3,
      shadowRadius: 2
    },
    whiteBtnHalf: {
      backgroundColor: '#ffffff',
      borderRadius: 30,
      paddingTop: 10,
      paddingBottom: 10,
      width: '46 %'
    },
    RbBtnWithIcon: {
      borderRadius: 50,
      paddingBottom: 2,
      paddingTop: 2,
      paddingLeft: 2,
      paddingRight: 2,
      width: 36,
      height: 36,
      alignSelf: 'center'
    },
    RbBtnWithIconWrap: {
      width: 80,
      paddingLeft: 6,
      paddingRight: 6
    },
    RbBtnWithIconText: {
      textAlign: 'center',
      color: themeColors.roundBtnIconText,
      lineHeight: 20,
      fontFamily: fonts.semiBold
    },
    ActionBtnHalf: {
      flexDirection: 'row',
      justifyContent: 'space-around',
      width: '100 %',
      backgroundColor: '#fff',
      paddingBottom: 10,
      marginTop: 15
    },
    ActionBtn: {
      backgroundColor: '#fff',
      paddingBottom: 10,
      marginTop: 15
    },
    ActionBtnRec: {
      backgroundColor: '#fff'
    },
    RbBtnWithIconImg: {
      width: 36,
      height: 36,
      alignSelf: 'center'
    },
    CardIcon: {
      width: 24,
      height: 24,
      marginRight: 8
    },
    primoIcon: {
      width: 70,
      height: 55,
      resizeMode: 'contain'
    },
    right: {
      marginRight: 20
    },
    RateStayIcon: {
      width: 17,
      height: 16,
      marginRight: 15
    },
    ContentPortion: {
      flex: 1
    },
    TimingBlockOuter: {
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
    TimingBlock: {
      width: '50%'
    },
    StatusCardWrapper: {
      paddingLeft: 50,
      paddingRight: 50
    },
    topStatusCard: {
      paddingTop: 10,
      paddingBottom: 20,
      zIndex: 10,
      position: 'relative',
      ...Platform.select({
        ios: {
          backgroundColor: 'transparent'
        }
      }),
      marginTop: -67
    },
    transperantBgrnd: {
      ...Platform.select({
        ios: {
          backgroundColor: 'transparent'
        }
      })
    },
    stickyHeader: {
      width: '100%',
      elevation: 5,
      backgroundColor: '#fff',
      // marginBottom: 6,
      // paddingVertical: 8
    },
    bookingidDetails: {
      flexDirection: 'row',
      justifyContent: 'space-between'
    },
    topStatusBar: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    stickyBarWrapper: {
      flexDirection: 'row',
      width: '100%',
      alignItems: 'center'
    },
    stickyTextWrapper: {
      paddingLeft: 5,
      width: "90%"
    },
    whiteBtnFull: {
      backgroundColor: '#ffffff',
      borderRadius: 30,
      paddingTop: 10,
      paddingBottom: 10,
      flex: 1,
      marginRight: 10
    },
    WhiteStickyHeader: {
      backgroundColor: '#fff',
      paddingTop: 10,
      paddingBottom: 10,
      elevation: 3,
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 2
      },
      shadowOpacity: 0.3,
      shadowRadius: 2
    },
    whiteBtnHalfOuter: {
      flexDirection: 'row',
      justifyContent: 'space-around'
    },
    HotelMainInfo: {
      flex: 1,
      marginRight: 10
    },
    HotelMainImgContainer: {
      width: '25%',
      flexDirection: 'row',
      justifyContent: 'flex-end'
    },
    HotelMainImg: {
      height: 60,
      width: 60,
      borderRadius: 8
    },
    ValueStaysImg: {
      height: 25,
      width: 120
    },
    StarRating: {
      flexDirection: 'row',
      paddingLeft: 10
    },
    HotelNightsRow: {
      flexDirection: 'row',
      paddingTop: 15,
      paddingBottom: 15,
      borderBottomWidth: 1,
      borderBottomColor: '#ddd'
    },
    HotelNightsOuter: {
      flexDirection: 'row',
      marginLeft: 25,
      width: '100%'
    },
    HotelNightsCol: {
      width: '45%'
    },
    HotelRGRow: {
      flexDirection: 'row',
      paddingTop: 15,
      paddingBottom: 15,
      alignItems: 'center'
    },
    gpsRow: {
      flexDirection: 'row',
      paddingTop: 5,
      paddingBottom: 5,
    },
    border: {
      borderBottomWidth: 1,
      borderBottomColor: '#ddd',
      paddingBottom: 10,
    },
    gpsSeatRow: {
      flexDirection: 'row',
      paddingTop: 10,
      paddingBottom: 10,
    },
    HotelRGLastRow: {
      flexDirection: 'row',
      paddingTop: 15,
      alignItems: 'center'
    },
    HotelRooms: {
      flex: 1
    },
    HotelLabel: {
      paddingRight: 25
    },
    roomOuter: {
      flexDirection: 'row',
      justifyContent: 'space-between'
    },
    roomContent: {
      width: '75%'
    },
    starspace: {
      fontSize: 6,
      color: 'transparent'
    },
    adultIcon: {
      width: 16,
      height: 11
    },
    aminitiesOuter: {
      flexDirection: 'row',
      flexWrap: 'wrap'
    },
    aminities: {
      marginRight: 15,
      color: '#00a19c',
      ...fonts.font13
    },
    aminitiesIcon: {
      width: 16,
      height: 16,
      marginRight: 5
    },
    aminitieswrap: {
      flexDirection: 'row',
      width: '100%',
      alignItems: 'center'
    },
    Paymentrow: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: "100%"
    },
    PaymentLeft: {
      flex: 0.7
    },
    hotelimg: {
      width: 60,
      height: 60
    },
    hotelimgOuter: {
      flexDirection: 'row',
      justifyContent: 'space-between'
    },
    hotelimgShadow: {
      position: 'relative',
      backgroundColor: '#333',
      zIndex: 2,
      width: 60,
      height: 60
    },
    hotelimgText: {
      position: 'absolute',
      zIndex: 2,
      width: 60,
      marginTop: 12,
      textAlign: 'center',
      color: '#fff',
      ...fonts.font16
    },
    hotelAminitiesOuter: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    amenityIcon: {
      width: 23,
      height: 18,
      marginBottom: 7,
      alignSelf: 'center'
    },
    hotelAminitiesWrap: {
      flex: 1,
      marginRight: 2
    },
    errorStrip: {
      flexDirection: 'row',
      alignItems: 'center',
      paddingRight: 20
    },
    infoIcon: {
      width: 16,
      height: 16,
      marginRight: 10
    },
    infoText: {
      color: '#eb2026',
      fontStyle: 'italic',
      ...fonts.font12
    },
    freeParkingStrip: {
      flexDirection: 'row',
      alignItems: 'center',
      paddingRight: 20
    },
    parkingIcon: {
      width: 19,
      height: 25,
      marginRight: 10
    },
    rateStaybtn: {
      width: 40,
      height: 40,
      borderRadius: 30,
      alignSelf: 'center'
    },
    rateStaybtnWhite: {
      width: 38,
      height: 38,
      borderRadius: 30,
      backgroundColor: '#fff',
      marginLeft: 1,
      marginTop: 1
    },
    IOSbackarrow: {
      width: 10,
      height: 16,
    },
    backarrow: {
      width: 16,
      height: 16
    },
    shareicon: {
      width: 22,
      height: 17
    },
    topStatusIcon: {
      width: 70,
      height: 70
    },
    RecFullBtnArrow: {
      width: 9,
      height: 12,
      tintColor: themeColors.rightArrowTintColor
    },
    starsRatings: {
      width: 14,
      height: 14
    },
    rateStaybtnText: {
      marginTop: 10,
      textAlign: 'center'
    },
    successText: {
      color: '#1a7971',
      ...fonts.font12,
    },
    failedText: {
      color: '#f15864',
      ...fonts.font12,
    },
    refundStrip: {
      width: '100%',
      backgroundColor: '#ccc',
      height: 3
    },
    refundHalfbar: {
      width: '50%',
      height: 3
    },
    refundFullbar: {
      width: '100%',
      height: 3
    },
    refundHalfbarVertical: {
      height: '50%',
      width: 3
    },
    refundFullbarVertical: {
      height: '100%',
      width: 3
    },
    refundGreenBackground: {
      backgroundColor: '#32d18f'
    },
    refundYellowBackground: {
      backgroundColor: '#e5c055'
    },
    refundbarInfo: {
      flexDirection: 'row',
      justifyContent: 'space-between'
    },
    refundCardhead: {
      flexDirection: 'row',
      justifyContent: 'space-between'
    },
    refundCardheadText: {
      width: '65%'
    },
    refundCardOuter: {
      paddingLeft: 15,
      paddingRight: 15,
      paddingBottom: 10,
      paddingTop: 10
    },
    refundCardWrap: {
      flexDirection: 'row',
      justifyContent: 'space-between'
    },
    refundCardLeft: {
      width: '75%'
    },
    MimaRadio: {
      width: 18,
      height: 18,
      backgroundColor: '#fff',
      borderRadius: 20,
      borderWidth: 1,
      marginRight: 20,
      borderColor: '#00a19c',
      marginTop: 5
    },
    MimaRadioPersonal: {
      borderColor: colors.cyan
    },
    MimaRadioCorporate: {
      borderColor: colors.yellow2
    },
    MimaRadioInside: {
      width: 12,
      height: 12,
      borderRadius: 20,
      marginLeft: 2,
      backgroundColor: '#00a19c',
      marginTop: 2
    },
    MetroRadioInside: {
      width: 12,
      height: 12,
      backgroundColor: '#00a19c',
      borderRadius: 20,
      marginLeft: 2,
      marginTop: 2,
    },
    MimaRadioInsideBackgroundPersonal: {
      backgroundColor: colors.cyan,
    },
    MimaRadioInsideBackgroundCorporate: {
      backgroundColor: colors.yellow2
    },
    refundText: {
      marginLeft: 38
    },
    MamiCardGrey: {
      backgroundColor: '#f6f6f6',
      paddingLeft: 10,
      paddingRight: 10
    },
    impInfoGreenOuter: {
      backgroundColor: '#c8f8f6',
      width: '100%',
      padding: 10
    },
    impInfoGreen: {
      color: '#00a19c',
      ...fonts.font13,
    },
    impInfoRedOuter: {
      backgroundColor: '#ffe3d5',
      width: '100%',
      padding: 10
    },
    impInfoRed: {
      color: '#e53442',
      ...fonts.font13,
    },

    topHeader: {
      flexDirection: 'row',
      alignItems: 'center',
      height: 56
    },
    ScrolableHead: {
      paddingLeft: 15,
      paddingBottom: 14,
      paddingTop: 15,
      elevation: 1,
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 1
      },
      shadowOpacity: 0.3,
      shadowRadius: 1,
      backgroundColor: '#fff',
      marginBottom: 2
    },
    listOuter: {
      paddingLeft: 16,
      paddingRight: 16
    },
    listItems: {
      paddingTop: 13,
      paddingBottom: 13,
      borderBottomWidth: 1,
      borderBottomColor: '#ddd',
      fontFamily: fonts.regular
    },
    crousalOuter: {
      width: '100%'
    },
    imgg: {
      width: '100%',
      height: '100%'
    },
    headerPaddingbtm: {
      paddingBottom: 70
    },


    padVertical10: {
      paddingVertical: 10
    },
    whiteContainer: {
      backgroundColor: '#ffffff',
      flex: 1,
      width: '100%',
      height: '100%',
    },
    PolicyHeading: {
      paddingVertical: 15,
      flexDirection: 'row',
      paddingHorizontal: 18,
      backgroundColor: '#ffffff',
      elevation: 2,
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 2
      },
      shadowOpacity: 0.3,
      shadowRadius: 2,
      marginBottom: 2
    },
    PolicyHeadingText: {
      paddingTop: 3
    },
    CrossIcon: {
      width: 20,
      height: 20,
      marginRight: 10
    },
    HotelIcon: {
      width: 20,
      height: 22,
      marginRight: 10
    },
    PolicyDetailsContainer: {
      paddingVertical: 18,
      paddingRight: 35,
      paddingLeft: 25,
      elevation: 0.5,
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 1
      },
      shadowOpacity: 0.3,
      shadowRadius: 1,
      backgroundColor: '#ffffff',
      marginBottom: 5

    },
    ItemDisc: {
      width: 6,
      height: 6,
      borderRadius: 25,
      backgroundColor: '#9b9b9b',
      marginRight: 15,
      marginTop: 6
    },
    HotelVoucherWrapper: {
      padding: 15
    },
    HotelVoucherForm: {
      borderRadius: 3,
      elevation: 2,
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 2
      },
      shadowOpacity: 0.3,
      shadowRadius: 2,
      backgroundColor: '#ffffff',
      minHeight: 120,
      paddingVertical: 15,
      paddingHorizontal: 18
    },
    formText: {
      width: 60,
      ...fonts.font15
    },
    formField: {
      flex: 1,
      backgroundColor: '#ffffff',
      height: 30,
      ...fonts.font14,
      lineHeight: 18,
      padding: 0,
      borderBottomColor: colors.grey8,
      borderBottomWidth: 1
    },

    showView: {
      display: 'flex'
    },
    hideView: {
      display: 'none'
    },


    transperantBg: {

      ...Platform.select({

        ios: {

          backgroundColor: 'transparent'

        }

      })

    },
    carouselCount: {
      position: 'absolute',
      backgroundColor: 'rgba(0,0,0,0.6)',
      color: '#fff',
      top: 70,
      right: 10,
      padding: 5,
      ...fonts.font12,
      borderRadius: 4
    },
    // standolone page
    ChooseOneRadio: {
      marginRight: 10,
      marginTop: 2
    },
    italic: {
      fontStyle: 'italic'
    },
    PAHXInfo: {
      padding: 15
    },
    PAHXInfoHeading: {
      backgroundColor: '#fff',
      borderBottomWidth: 1,
      borderBottomColor: '#e4e4e4',
      flexDirection: 'row',
      paddingHorizontal: 15,
      paddingVertical: 10,
      elevation: 2,
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 2
      },
      shadowOpacity: 0.3,
      shadowRadius: 2
    },
    RefundProcessCard: {
      flexDirection: 'row'
    },
    RefundProcessSuccess: {
      backgroundColor: '#00a19c',
      flex: 1
    },
    RefundProcessHalf: {
      backgroundColor: '#e5c055',
      flex: 0.5
    },
    RefundPaymentDtls: {
      marginLeft: 20
    },
    ErrorStateWrapper: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#ffffff',
      paddingHorizontal: 15
    },

    bggreyLight: {
      backgroundColor: '#f9f9f9'
    },
    CancelationDtls: {
      padding: 15,
      backgroundColor: '#ffffff'
    },
    cancIcon: {
      width: 24,
      height: 24,
      marginRight: 10,
      marginTop: 2
    },
    cancellationStatesText: {
      flex: 0.95
    },
    errorRedTxt: {
      color: '#e53442'
    },
    GreyText: {
      color: colors.grey
    },
    greenTick: {
      width: 16,
      height: 16,
      marginRight: 10,
      marginTop: 3
    },
    lineHt17: {
      lineHeight: 17
    },
    mar3Tp: {
      marginTop: 3
    },
    mediumFont: {
      fontFamily: fonts.medium
    },
    JustifySpaceBetween: {
      justifyContent: 'space-between'
    },
    CancPriceBreckup: {
      justifyContent: 'space-between'
    },
    CancLeftSection: {
      flexDirection: 'column'
    },
    RoomCancelledWrapper: {
      padding: 15
    },
    CancellationWrapper: {
      flex: 1,
      justifyContent: 'space-between',
      backgroundColor: '#ffffff'
    },
    CancellationPaidWrapper: {
      borderTopColor: '#ddd',
      paddingTop: 10,
      marginTop: 5
    },
    GuestInfoStrip: {
      backgroundColor: '#e3e7eb',
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingBottom: 10,
      paddingTop: 10,
      paddingLeft: 15,
      paddingRight: 15
    },
    MAMIBlackFooter: {
      backgroundColor: '#282828',
      padding: 10,
      zIndex: 9
    },
    CancellationViewFooter: {
      height: 70,
      padding: 15,
      zIndex: 9
    },
    FooterLeft: {
      flex: 1,
      flexDirection: 'column'
    },
    FooterRight: {
      flex: 1,
      width: '50%',
      justifyContent: 'center',
      alignItems: 'center'
    },
    dateChangeIcon: {
      width: 70,
      height: 70
    },
    ErrorPageText: {
      ...fonts.font20,
      textAlign: 'center',
      lineHeight: 25,
      color: '#3c3c3c'

    },
    thankYouText: {
      ...fonts.font14,
      textAlign: 'center',
      lineHeight: 25,
    },
    mar50Bt: {
      marginBottom: 50
    },
    lineHt22: {
      lineHeight: 22
    },
    CommonFillBtn: {
      borderRadius: 25,
      paddingVertical: 8,
      paddingHorizontal: 25
    },
    FooterBtn: {
      paddingHorizontal: 20
    },
    refundTick: {
      width: 14,
      height: 14
    },
    transparentBg: {
      backgroundColor: 'rgba(0,0,0,.5)',
      position: 'absolute',
      height: '100%',
      width: '100%',
      justifyContent: 'flex-end'
    },
    refundPopup: {
      backgroundColor: '#fff',
      paddingVertical: 40,
      paddingHorizontal: 20,
      zIndex: 10
    },
    refundPopupHead: {
      ...fonts.font30
    },
    fullWidthBtn: {
      width: '100%',
      paddingVertical: 18
    },
    refundVerifybtn: {
      marginTop: 25
    },
    width60: {
      width: '60%'
    },
    width40: {
      width: '40%'
    },
    blackColor: {
      color: colors.black
    },
    dBColor: {
      color: '#00a19c'
    },
    cancellationChargeColor: {
      color: '#e53442'
    },
    cancellationChargeBreakUpColor: {
      color: '#747474'
    },
    AddGuestCardWrapper: {
      paddingHorizontal: 20,
    },
    AddGuestHeadingText: {
      marginBottom: 12,
      flex: 1,
    },
    AddGuestCard: {
      backgroundColor: '#fff',
      elevation: 2,
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 2
      },
      shadowOpacity: 0.3,
      shadowRadius: 2,
      marginHorizontal: 15,
      marginBottom: 5,
      borderRadius: 4,
      ...Platform.select({
        android: {
          overflow: 'hidden'
        }
      })
    },
    addGuestHead: {
      color: '#000',
      ...fonts.font16,
      textAlign: 'center',
      fontFamily: fonts.bold,
      paddingVertical: 15,
      backgroundColor: '#fff'
    },
    guestDetails: {
      paddingHorizontal: 15,
      paddingVertical: 10
    },
    addGuestCounter: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      backgroundColor: '#fff',
      elevation: 3,
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 2
      },
      shadowOpacity: 0.3,
      shadowRadius: 2,
      borderRadius: 4,
      ...Platform.select({
        android: {
          overflow: 'hidden'
        }
      }),
      width: '95%'
    },


    counterVal: {
      ...fonts.font22,
      color: '#000',
      fontFamily: fonts.bold,
      paddingHorizontal: 15,
      paddingVertical: 10
    },
    minus: { marginTop: -10 },
    checkAvlBtn: {
      paddingVertical: 18,
      width: '92%',
      marginHorizontal: 15,
      borderRadius: 3,
      overflow: 'hidden'
    },
    addMinusWrapper: {
      ...getPlatformElevation(2),
      backgroundColor: colors.white,
      borderRadius: 4,
      margin: 2,
      height: 40
    },
    countAction: {
      width: 36,
      ...fonts.font20,
      height: 36,
      textAlign: 'center',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    minusSign: {
      width: 12,
      height: 2,
      backgroundColor: colors.gray4
    },
    activeMinusLink: {
      backgroundColor: colors.cyan3
    },
    addCount: {
      ...fonts.font18,
      color: colors.black,
      width: 40,
      textAlign: 'center',
      paddingVertical:0,
    },
    activePlusLink: {
      color: colors.cyan3
    },
    addLessSection: {
      textAlign: 'center',
      ...fonts.font24,
      color: colors.gray4,
      width: 36,
    },
    GuestDetailsRow: {
      backgroundColor: '#ffffff',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: 15
    },
    ErrorSuccessWrapper: {
      paddingHorizontal: 10,
      backgroundColor: '#e3e7eb',
      paddingTop: 10
    },

    ErrorIcon: {
      width: 28,
      height: 28,
      marginRight: 10,
      marginTop: 1
    },
    SuccessIcon: {
      width: 28,
      height: 28,
      marginRight: 10,
      marginTop: 1
    },
    flexWrap: {
      flexWrap: 'wrap'
    },
    ErrorSuccesstext: {
      width: '88%'

    },
    HotelRoomSection: {
      paddingHorizontal: 15,
      paddingVertical: 12,
      alignItems: 'center'
    },
    LinkText: {
      paddingTop: 2
    },
    bfIcon: {
      width: 18,
      height: 11
    },
    RoomPriceInfo: {
      padding: 15
    },
    RoomPriceInfoRow: {
      flexDirection: 'row',
      justifyContent: 'space-between'
    },
    RoomAlert: {
      backgroundColor: '#e9f8f3'
    },
    RoomAlertText: {
      color: '#18a384',
      textAlign: 'center',
      paddingVertical: 5
    },

    RoomLeftText: {
      justifyContent: 'flex-end',
      textAlign: 'right',
      flex: 1,
      color: '#e53442',
      ...fonts.font12,
      fontStyle: 'italic',
      marginTop: 2
    },
    RoomRightText: {
      // justifyContent: 'flex-end',
      textAlign: 'left',
      flex: 1,
      color: '#e53442',
      ...fonts.font12,
      fontStyle: 'italic',
      marginTop: 2
    },
    infoIconBlue: {
      width: 18,
      height: 18,
      marginLeft: 5
    },
    BreakupTextBtm: {
      marginTop: 3
    },
    PriceBookedAt: {
      backgroundColor: '#f2f2f2'
    },
    calDates: {
      ...fonts.font36,
      color: '#000',
      marginRight: 5
    },
    dateInfoWrap: {
      paddingVertical: 30,
      paddingLeft: 30
    },
    checkInDateInfoWrap: {
      borderRightWidth: 2,
      borderRightColor: '#e7e7e7'
    },
    dateInfoOuter: {
      backgroundColor: '#fff',
      borderColor: '#ddd',
      borderTopWidth: 1,
      borderBottomWidth: 1,
      elevation: 2,
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 2
      },
      shadowOpacity: 0.3,
      shadowRadius: 2,
      marginBottom: 10
    },
    /* confirmation Style */
    ConfirmationWrapper: {
      backgroundColor: '#ffffff',
      elevation: 2,
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 2
      },
      shadowOpacity: 0.3,
      shadowRadius: 2,
      marginBottom: 5
    },
    ConfirmationHeading: {
      padding: 15,
      flexDirection: 'row',
      borderBottomWidth: 1,
      borderBottomColor: '#ddd'
    },
    GuestIcon: {
      width: 23,
      height: 15,
      marginRight: 5
    },
    ConfirmationDtls: {
      paddingTop: 10,
      paddingBottom: 15,
      paddingHorizontal: 45
    },
    GreenTickIcon: {
      width: 20,
      height: 20
    },
    FreeCancellation: {
      flexDirection: 'row'
    },
    ConfirmBtn: {
      marginHorizontal: 5,
      marginVertical: 5
    },

    /* Error Page */
    ErrorPageWrapper: {
      backgroundColor: '#ffffff',
      paddingVertical: 20,
      paddingHorizontal: 40
    },
    ErrorRoomImage: {
      width: 107,
      height: 113
    },
    mar40Bt: {
      marginBottom: 40
    },
    ChatText: {
      color: '#008cff'
    },
    ErrorPageBtn: {
      width: 250
    },

    // Date Change Inner page
    ErrorWrapper: {
      paddingRight: 10
    },
    RoomAvailableText: {
      color: '#18a384',
      textAlign: 'left',
      paddingVertical: 5,
      paddingHorizontal: 15
    },
    DateChangeIcon: {
      width: 18,
      height: 18,
      marginRight: 15
    },
    justifyCenter: {
      justifyContent: 'center'
    },
    justifyContentspacebetween: {
      justifyContent: 'space-between',
      width: '100%',
      height: '100%'
    },
    mar1Top: {
      marginTop: 1
    },
    redfont: {
      color: '#e53442'
    },
    RoomRightxxlnText: {
      // justifyContent: 'flex-end',
      textAlign: 'left',
      flex: 1,
      ...fonts.font12,
      fontStyle: 'italic',
      marginTop: 2,
      color: "#747474"
    },
    ModifiedCardIcon: {
      width: 24,
      height: 21,
      marginRight: 15
    },
    GpsIcon: {
      height: 24,
      width: 24,
      marginRight: 5
    },
    row: { flexDirection: 'row' },
    blueColor: { color: '#008cff' },
    marginBtm: { marginBottom: 16 },
    font10: {
      ...fonts.font10
    },
    marginTop24: { marginTop: 24 },
    marginTop18: { marginTop: 18 },
    marginTop30: { marginTop: 30 },
    paddingTop18: { paddingTop: 18 },
    paddingBottom8: { paddingBottom: 8 },
    paddingTop10: { paddingTop: 10 },
    paddingBottom10: { paddingBottom: 10 },
    marL12per: { paddingLeft: '12%' },
    topStatusConfirmIcon: {
      width: 100,
      height: 60
    },
    bgTransparent: { backgroundColor: 'transparent' },
    darkRed: { color: '#eb2026' },
    lineThought: { textDecorationLine: 'line-through' },
    busOffer: {
      width: 16,
      height: 16,
      marginLeft: 5
    },
    marginLeftAuto: { marginLeft: 'auto' },
    lightGreenTxt: { color: '#00a19c' },
    mar150Bt: {
      marginBottom: 150
    },
    mar32Rt: {
      marginRight: 32
    },
    margin0Bottom: {
      marginBottom: 0,
      elevation: 1
    },
    borderTop: {
      borderTopWidth: 1,
      borderTopColor: '#ddd'
    },
    defaultGrey: { color: '#4a4a4a' },
    lineHeight18: {
      lineHeight: 18
    },
    width100per: { width: '100 %' },
    lightYellow: { color: '#e58f13' },
    flexStart: { alignSelf: 'flex-start' },
    width250: { width: 250 },
    statusCardOuter: {
      paddingLeft: 16,
      paddingRight: 16
    },
    opacity3: { opacity: 0.3 },
    cancelHeaderOuter: {
      paddingLeft: 16,
      paddingRight: 16
    },
    requestInfoOuter: {
      backgroundColor: '#fff',
      borderColor: '#ddd',
      borderTopWidth: 1,
      borderBottomWidth: 1,
      elevation: 1,
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 2
      },
      shadowOpacity: 0.3,
      shadowRadius: 2,
      marginBottom: 1
    },
    callHotelInfoOuter: {
      backgroundColor: '#fff',
      borderColor: '#ddd',
      borderTopWidth: 1,
      borderBottomWidth: 1,
      elevation: 1,
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 2
      },
      shadowOpacity: 0.3,
      shadowRadius: 2,
      paddingVertical: 45
    },

    writeToHotelOuter: {
      backgroundColor: '#ffffff',
      borderColor: '#ddd',
      borderTopWidth: 1,
      borderBottomWidth: 1,
      elevation: 2,
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 2
      },
      shadowOpacity: 0.3,
      shadowRadius: 2,
      padding: 10
    },
    makeRequestWrapper: {
      flex: 1,
      justifyContent: 'space-between',
      backgroundColor: '#f2f2f2'
    },
    specialRequestImage: {
      width: 19,
      height: 8
    },
    checkinRequestImage: {
      width: 24,
      height: 24
    },
    makeRequestSubHeader: {
      flexDirection: 'row',
      alignItems: 'center',
      paddingHorizontal: 15
    },
    makeRequestSubHeadding: {
      color: '#000',
      ...fonts.font16,
      textAlign: 'center',
      fontFamily: fonts.bold,
      paddingVertical: 15,
      paddingLeft: 15,
      backgroundColor: '#fff'
    },
    makeRequestorOuter: {
      borderColor: '#ddd',
      borderTopWidth: 1,
      borderBottomWidth: 1,
      elevation: 1,
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 2
      },
      shadowOpacity: 0.3,
      shadowRadius: 2,
      marginBottom: 1
    },
    OrDivider: {
      height: 40,
      justifyContent: 'center',
      alignItems: 'center'
    },
    OrLine: {
      height: 1,
      backgroundColor: '#d7d7d7',
      width: '100%'
    },
    OrCircle: {
      borderWidth: 1,
      borderColor: '#d7d7d7',
      width: 32,
      height: 32,
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 50,
      position: 'absolute',
      backgroundColor: '#f2f2f2'
    },
    makeRequestHeader: {
      color: colors.defaultTextColor,
      fontFamily: fonts.black,
      ...fonts.font16,
    },
    writeToHotelFormField: {
      backgroundColor: '#fff',
      ...fonts.font18,
      fontFamily: fonts.regular,
      lineHeight: 24,
      borderColor: colors.grey8,
      borderWidth: 1,
      borderRadius: 10,
      padding: 20,
      textAlign: 'left',
      minHeight: 150,
      textAlignVertical: 'top'
    },
    writeToHotelText: {
      padding: 10,
      backgroundColor: '#fff'
    },
    writeHotelGenericView: {
      flex: 1,
      paddingBottom: 15
    },
    writeHotelGenricText: {
      textAlign: 'center',
      ...fonts.font12,
      fontFamily: fonts.regular,
      color: '#747474',
      flexDirection: 'row',
      lineHeight: 20,
      padding: 10
    },
    makeRequestHeaderPaddingBottom: {
      paddingBottom: 40
    },
    refundModeMessageCard: {
      backgroundColor: '#fff',
      borderBottomWidth: 1,
      borderBottomColor: '#e4e4e4',
      flex: 1,
      padding: 15
    },
    anchorMD: {
      color: colors.azure,
      ...fonts.font14,
      letterSpacing: 0.4,
      fontFamily: fonts.bold
    },
    childBookingCancellationGrey: {
      backgroundColor: '#f6f6f6',
      padding: 10
    },
    imageBox: {
      alignSelf: 'center',
      height: 100,
      width: 350

    },
    priceText: {
      textAlign: 'right'
    },
    fareBrkupRow: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingVertical: 4
    },
    redText: {
      color: colors.red
    },
    greenRefundText: {
      color: '#1a7971'
    },
    fbHeadingTxt: {
      fontFamily: fonts.black,
      color: '#000',
      ...fonts.font16,
    },
    fareBreakUpWrapper: {
      paddingTop: 12,
      paddingHorizontal: 16,
      paddingBottom: 10
    },
    fareBreakupDtlsWrapper: {
      paddingVertical: 6
    },
    lineThrough: {
      textDecorationLine: 'line-through'
    },
    fareBrkupTxtPrice: {
      fontFamily: fonts.regular,
      color: '#747474',
      ...fonts.font14,
    },
    dbIconSm: {
      width: 16,
      height: 16,
      marginRight: 5
    },
    autoRefundBreakupMain: {
      borderTopColor: '#e7e7e7',
      borderTopWidth: 1,

      paddingTop: 10,
      paddingBottom: 10
    },
    fareBrkupTxt: {
      fontFamily: fonts.regular,
      color: '#000',
      ...fonts.font14,
    },
    descLeftWrap: {
      width: '70%'
    },
    successIcon: {
      width: 64,
      height: 38,
      alignSelf: 'center',
      marginTop: 15
    },
    failedIcon: {
      width: 61,
      height: 35,
      alignSelf: 'center'
    },
    mmtPromiseIcon: {
      width: 144,
      height: 37,
      alignSelf: 'center',
      marginTop: 15
    },
    tripCompletedIcon: {
      width: 54,
      height: 42,
      alignSelf: 'center'
    },
    BookingWrapper: {
      flexDirection: 'row',
      justifyContent: 'space-between'
    },
    booingDtlsTxt: {
      color: '#747474',
      ...fonts.font12,
      fontFamily: fonts.regular,
      lineHeight: 14,
      marginRight: 15
    },
    booingDtlsbold: {
      color: '#747474',
      ...fonts.font12,
      fontFamily: fonts.bold,
      lineHeight: 14
    },
    MoneyBackGuaranteeCard: {
      backgroundColor: '#fff',
      borderRadius: 4,
      marginBottom: 10
    },
    hundredPerImage: {
      width: 74,
      height: 71,
      borderTopLeftRadius: 4
    },
    verifiedText: {
      color: '#e6ffff',
      lineHeight: 20
    },
    guaranteeText: {
      paddingVertical: 12,
      paddingHorizontal: 12
    },
    messageIcon: {
      width: 13,
      height: 9,
      marginRight: 8,
      marginTop: 3
    },
    callIcon: {
      width: 15,
      height: 14,
      marginRight: 8
    },
    otherMemberDetails: {
      paddingTop: 12,
      borderTopWidth: 1,
      borderColor: '#e5e5e5'
    },
    bnplCardInfoTxt: {
      ...fonts.font12,
      lineHeight: 18,
      fontStyle: 'italic',
      color: '#cf8100'
    },
    instantRefundTag: {
      height: 18,
      borderRadius: 8,
      width: 115,
      alignItems: 'center',
      justifyContent: 'center'
    },
    latoBold: { fontFamily: fonts.bold },
    darkGrayText: { color: '#c2c2c2' },
    mb10: { marginBottom: 10 },
    instantRefundTagTxxt: {
      fontFamily: fonts.bold,
      ...fonts.font10,
      color: '#fff',
      alignSelf: 'center'
    },
    myTrpPricBrkupTag: {
      paddingVertical: 2,
      paddingHorizontal: 8,
      borderRadius: 10
    },
    chargesInfoWrapper: {
      borderRadius: 4,
      backgroundColor: '#ffedd1',
      paddingHorizontal: 8,
      paddingVertical: 4,
      justifyContent: 'space-between'
    },
    extraChargeTxt: {
      fontFamily: fonts.regular,
      color: '#cf8100',
      ...fonts.font12,
      lineHeight: 16
    },
    gstTxt: {
      fontFamily: fonts.black,
      color: '#cf8100',
      ...fonts.font12,
      lineHeight: 24
    },
    infoIconStyle: {
      width: 14,
      height: 14,
      tintColor: '#cf8100',
    },
    doneBtn: {
      width: 130,
      height: 40,
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      position: "absolute",
      bottom: 20,
      left: 0,
      right: 0,
      marginTop: 0,
      marginRight: 'auto',
      marginBottom: 0,
      marginLeft: 'auto',
      borderRadius: 30,
      backgroundColor: "#008cff",
      zIndex: 10,
      color: "#FFF",
      textTransform: "uppercase"
    },
    paymentSuccessIcon: {
      width: 70,
      height: 70
    },
    btn: {
      borderRadius: 8,
      padding: 8,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    bluebg: {
      backgroundColor: '#008cff',
    },
    lightBluebg: {
      backgroundColor: '#eaf5ff',
    },
    bluetext: {
      color: colors.white,
    },
    lightBluetext: {
      color: colors.azure,
    },
    iconStyle: {
      width: 20,
      height: 20,
      marginRight: 6,
    },
    whiteIconBg: {
      tintColor: 'white',
    },
    blueIconBg: {
      tintColor: '#008cff',
    },
    width48: { width: '48%' },
    container: {
      backgroundColor: '#e7e7e7',
    },
    topWrapper: {
      padding: 16,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      marginBottom: 12,
      paddingTop: 12,
      zIndex: 10,
      position: 'relative',
    },
    headerWrapper: {
      zIndex: 10,
      position: 'relative',
      marginTop: -57
    },
    topCardWrapper: {
      ...Platform.select({
        android: {
          ...getPlatformElevation(3)
        }
      }),
      zIndex: 10,
      position: 'relative',
    },
    dateChangeWrapper: {
      paddingVertical: 10,
      paddingHorizontal: 12,
      backgroundColor: '#d1f8e6',
      flexDirection: 'row',
      borderRadius: 4,
    },
    dateChangeImg: {
      width: 24,
      height: 24,
      marginRight: 8,
      marginTop: 4
    },
    msgWrapper: {
      paddingHorizontal: 16,
      paddingVertical: 8,
      backgroundColor: '#ffedd1',
      borderBottomWidth: 1,
      borderBottomColor: '#e6cfab'
    },
    msgText: {
      ...fonts.font12,
      fontFamily: fonts.bold,
      color: '#cf8100',
      lineHeight: 20
    },
    buttonBottomWrapper: {
      position: 'absolute',
      bottom: 12,
      paddingHorizontal: 12,
      width: '100%'
    },
    cardWrapper: {
      backgroundColor: '#fff',
      paddingHorizontal: 16,
      paddingTop: 16,
      paddingBottom: 20,
      ...getPlatformElevation(2),
      marginBottom: 5
    },
    textInputLabel: {
      height: 36,
      width: 170,
      borderBottomWidth: 1,
      borderBottomColor: '#c2c2c2',
      backgroundColor: '#fff',
      paddingLeft: 0,
      textAlign: I18nManager.isRTL ? 'right' : 'left'
    },
    textInputLabelActive: {
      borderBottomColor: '#008cff',
    },
    errorFiled: {
      borderColor: '#eb2026',
    },
    headerSec: {
      paddingHorizontal: 12,
      paddingTop: 16,
    },
    headingIcon: {
      width: 24,
      height: 24,
      marginRight: 10,
      backgroundColor: "#f1f1f1"
    },
    docList: {
      paddingHorizontal: 45
    },
    bullet: {
      width: 4,
      height: 4,
      marginRight: 10,
      marginTop: 6,
      backgroundColor: '#4a4a4a',
      borderRadius: 50,
    },
    darkGreenText: {
      color: '#0b4f57'
    },
    brownText: {
      color: '#955000'
    },
    darkRedText: {
      color: '#a9112f'
    },
    darkBlueText: {
      color: '#0051b7'
    },
    dividor: {
      borderBottomWidth: 1,
      borderColor: '#d4daea',
      marginHorizontal: 12,
    },
    holdIcon: {
      width: 57,
      height: 49,
      alignSelf: 'center'
    },
    whiteTint: {
      tintColor: colors.white
    },
    overlayContainer: {
      justifyContent: 'flex-end',
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      zIndex: 1000,
      elevation: 3,
    },
    overlayBg: {
      backgroundColor: 'rgba(0,0,0,.5)',
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      zIndex: 100
    },
    centerTextIos: {
      ...Platform.select({
        ios: {
          textAlign: 'center'
        }
      })
    },
    connectUsView: {
      position: 'absolute',
      bottom: 56,
      paddingVertical: 12,
      paddingHorizontal: 20
    },
    connectUsText: {
      bottom: 106
    },
    blueroundBtnbg: {
      backgroundColor: themeColors.blueRoundBtnBg,
    },
    lightBlueroundBtnbg: {
      backgroundColor: themeColors.lightBlueRoundBtnBg,
    },
    blueroundBtnText: {
      color: themeColors.roundBtnBlueText,
    },
    lightBlueroundBtnText: {
      color: themeColors.roundBtnLightBlueText,
    },
    blueroundBtnIconbg: {
      tintColor: themeColors.roundBtnBlueIconBg
    },
    whiteroundBtnIconbg: {
      tintColor: themeColors.roundBtnWhiteIconBg
    },
    lightBlueroundBtnIconbg: {
      tintColor: themeColors.roundBtnLightBlueIconBg
    },
    removeRoomImageContainer: {
      marginLeft: 'auto',
      marginRight: 15,
      marginTop: 15
    },
    deleteIcon: {
      height: 24,
      width: 24
    },
    OverlayContent: {
      paddingTop: Platform.OS === 'web' ? 0 : 12,
      position: 'relative',
      width: '100%',
      borderTopLeftRadius: 16,
      borderTopRightRadius: 16,
      bottom: 0,
      overflow: 'hidden',
      flex: 1,
      backgroundColor: colors.white,
      ...Platform.select({
        web: {
          overflowY: 'auto'
        }
      })
    },
    priceIcon: {
      width: 31,
      height: 31,
      marginRight: 12,
    },
    paymentBreakup: {
      flexDirection: 'row'
    },
    flyerExWrapper: {
      width: '100%',
      bottom: 8,
      left: 35
    },
    tagExclusiveImg: {
      width: 120,
      height: 22,
      resizeMode: 'contain'
    },
    myIcon: {
      width: 20,
      height: 27,
    },
    flyerStyleWrapper: {
      height: 22,
      borderWidth: 1,
      justifyContent: 'center',
      borderLeftWidth: 0,
      borderRightWidth: 0,
    },
    flyerStyle: {
      backgroundColor: '#F4FAFF',
    },
    tcsSection: {
      marginRight: 10,
      marginLeft: -10,
      paddingHorizontal: 12,
      paddingVertical: 10,
    },
    alignBottom: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 3
    },
    depositCard: {
      borderRadius: 4,
      backgroundColor: '#f5f5ff',
      padding: 10,
      borderWidth: 1,
      borderColor: '#d9ebff',
    },
    roomCardHeading: {
      color: '#955000',
      ...fonts.font13,
      fontFamily: fonts.black,
      lineHeight: 20
    },
    roomCardWrapper: {
      marginTop: 24,
    },
    roomCardDetailsWrapper: {
      backgroundColor: colors.lightGrey6,
      marginHorizontal: -12,
      paddingHorizontal: 24,
      paddingVertical: 20,
    },
    roomInfoWrapper: {
      borderBottomWidth: 1,
      borderBottomColor: colors.grey8,
      paddingBottom: 16
    },
    roomInfoImg: {
      width: 84,
      height: 64,
      borderRadius: 8,
    },
    addGuestPageContainer: {
      marginTop: 8,
      marginBottom: 78,
    },
    addGuestPageWrapper: {
      backgroundColor: colors.white,
      marginVertical: 8,
      borderRadius: 10,
      ...getPlatformElevation(2),
      padding: 15,
    },
    IconGrey: {
      width: 14,
      height: 14,
      tintColor: '#9B9B9B'
    },

    underline: {
      textDecorationLine: 'underline'
    },

    flexShrink: {
      flexShrink: 1
    },
    cancelDetails: {
      paddingLeft: 12,
      paddingRight: 12,
      backgroundColor: colors.white,
      marginBottom: 20,
      flex: 1
    },
    cashbackStage: {
      borderRadius: 4,
      paddingHorizontal: 8,
      paddingVertical: 10,
      flexDirection: 'row',
      ...Platform.select({
        web: {
          flex: 1,
        }
      })
    },
    priceIcon: {
      width: 31,
      height: 31,
      marginRight: 12,
    },
    paymentBreakup: {
      flexDirection: 'row'
    },
    flyerExWrapper: {
      width: '100%'
    },
    myIcon: {
      width: 20,
      height: 27,
    },
    flyerStyleWrapper: {
      height: 22,
      borderWidth: 1,
      justifyContent: 'center',
      borderLeftWidth: 0,
      borderRightWidth: 1,
    },
    flyerStyle: {
      backgroundColor: '#F4FAFF',
    },
    tcsSection: {
      marginRight: 10,
      marginLeft: -10,
      paddingHorizontal: 12,
      paddingVertical: 10,

    },
    tcsInfoSection: {
      backgroundColor: colors.grayBg,
      paddingHorizontal: 12,
      paddingVertical: 8,
      borderRadius: 8,
      marginTop: 8,
    },
    heading: {
      backgroundColor: colors.grey22,
      borderBottomWidth: 1,
      borderBottomColor: colors.grey24,
      paddingHorizontal: 15,
      paddingTop: 5,
      paddingBottom: 15,
      marginHorizontal: -20,
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
    },
    topLine: {
      width: 40,
      height: 4,
      borderRadius: 4,
      backgroundColor: colors.lightGrey17,
      position: 'absolute',
      top: -10,
      alignSelf: 'center',
    },
    crossIconStyle: {
      width: 14,
      height: 14,
      marginRight: 10,
    },
    crossIconWrapper: {
      width: 32,
      height: 32,
      alignItems: 'center',
      justifyContent: 'center',
    },
    bodySection: {
      paddingVertical: 15,
  },
  cardNewCustomStyles: {
    borderRadius: 16,
    marginHorizontal: 12
  },
  redirectLinkNewCustomStyles: {
    paddingVertical: 16,
    paddingLeft: 12,
    paddingRight: 12
  },
  refundRedirectLinkCustomStyles: {
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 12,
    paddingRight: 12
  },
  headerWrapperStyles:{
    ...getPlatformElevation(0)
  },
  disabledColor:{
    backgroundColor:colors.grey4,
  },
  counterWrapper:{
    width:130
  },
  counterWrapperStyle:{
    width:140,
    padding:0,
  },
  inputFieldBookingDtls:{
    fontFamily: fonts.regular,
    ...fonts.font12,
    lineHeight:16,
    paddingTop:Platform.OS === 'ios' ? 8:14,
    textAlignVertical: 'middle',
  },
  bottomSheetWprStyle:{
    padding:16,
  },
  });
}

export default getStyles;
