import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Image } from 'react-native';
import isEmpty from 'lodash/isEmpty';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import CabBookingDetailsConstant from '../../../../CabBookingDetailsConstant';
import CabDetails from '../CabDetails';
import CabBookedInfo from '../CabBookedInfo';
import BookingInfoDetails from '../BookingInfoDetails';
import HourlyRentalCabDetail from "../HourlyRentalCabDetail/index"
import MulticityJourneyDetail from "../../../../components/MulticityJourneyDetail"
import { ConfirmedCabDetailCardProps } from '../../../../types';
import InternationalCabTrackCard from "../InternationalCabTrackCard";
import CardWrapper from '../CardWrapper';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import createStyles from './ConfirmedCabDetailCardCss';
import get from 'lodash/get';
import ShareOtpSection from '../ShareOtpSection';

const ConfirmedCabDetailCard: React.FC<ConfirmedCabDetailCardProps> = (
	props
) => {
	const { card, cardData, isExpanded, expandCarDetail, pageName, response } = props || {};
	const { sectionList } = card;
	const { carBookingInfo, cabDetailInfo, driverETAData, oldBookingText, isInternationalBooking, internationalCabData } =
		cardData;
	if (isEmpty(sectionList)) { return null; }
	const isSingleSection = sectionList.length === 1;

	const renderSections = (id: string) => {
		const views: JSX.Element[] = [];
		switch (id.toLocaleLowerCase()) {
			case CabBookingDetailsConstant.SHOW_HR_CAR_DETAIL.toLocaleLowerCase():
				views.push(<HourlyRentalCabDetail pageName={pageName} carBookingInfo={carBookingInfo} isExpanded={isExpanded || isSingleSection} expandCarDetail={expandCarDetail} />);
				break;
			case CabBookingDetailsConstant.SHOW_JOUNEY_DETAIL.toLocaleLowerCase():
				views.push(<BookingInfoDetails pageName={pageName} carBookingInfo={carBookingInfo} isExpanded={isExpanded || isSingleSection} expandCarDetail={expandCarDetail} />);
				break;
			case CabBookingDetailsConstant.SHOW_MC_JOUNEY_DETAIL.toLocaleLowerCase():
				views.push(
					<MulticityJourneyDetail
						locationData={response?.multicityData}
						expandCarDetail={expandCarDetail}
						isExpanded={isExpanded || isSingleSection}
						carBookingInfo={carBookingInfo}
					/>);
				break;
			case CabBookingDetailsConstant.SHOW_CAR_DETAIL.toLocaleLowerCase():
				views.push(<CabDetails cabDetailInfo={cabDetailInfo} />);
				break;
			case CabBookingDetailsConstant.SHOW_DRIVER_ETA.toLocaleLowerCase():
				if (!!isInternationalBooking) {
					views.push(<InternationalCabTrackCard internationalCabData={internationalCabData} />)
				} else {
					views.push(<CabBookedInfo driverETAData={driverETAData} />);
				}
				break;
			default:
				break;
		}
		return views;
	};
	return (
		<React.Fragment>
			{sectionList.map((section) => {
				if (!section.isVisible) { return null; }
				return renderSections(section.cardId);
			})}
		</React.Fragment>
	);
};

const OldBookingComponent = (props) => {
	const { fsStyle, ...fonts } = getFont(true);
	const { psTheme: theme } = useTheme();
	const styles = createStyles(theme, fonts);
	const oldBookingText = get(props, 'cardData.oldBookingText', '');
	return (
		<React.Fragment>
			{!isEmpty(oldBookingText) && (
				<View style={styles.oldBookingSection}>
					<Text
						style={[
							AtomicCss.boldFont,
							AtomicCss.defaultText,
							AtomicCss.font13,
						]}
					>
						{oldBookingText}
					</Text>
				</View>
			)}
		</React.Fragment>
	);
};

const WrapedConfirmedDetailCard = CardWrapper(ConfirmedCabDetailCard);

const ConfirmedCard = (props) => {
	const { fsStyle, ...fonts } = getFont(true);
	const { psTheme: theme } = useTheme();
	const styles = createStyles(theme, fonts);

	const { isSubCard, cardData } = props;
	const { cabDirectionsInfo: { otpText } } = cardData || {};

	return (
		<React.Fragment>
			<ShareOtpSection
				customStyle={!isSubCard ? styles.otpCustomStyle : {}}
				otpText={otpText}
			/>
			<View style={!!otpText ? styles.whiteCardNeg20 : {}}>
				<WrapedConfirmedDetailCard {...props} />
				<OldBookingComponent {...props} />
			</View>
		</React.Fragment>
	);
};

export default ConfirmedCard;