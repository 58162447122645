import { colors, fonts } from '@mmt/legacy-commons/Styles/globalStyles';
import { Platform, StyleSheet } from 'react-native';

export const getStyle = (psTheme) => {
  const {color} = psTheme;
  return StyleSheet.create({
    mmtBlackTextWrap: {
      backgroundColor: color.yellow12
    },
    mmtBlackText : {
      color: color.lightYello
    },
  })
}

const styles = {
  flex1: {
    flex: 1,
  },
  font18: {
    fontSize: 18,
  },
  font16: {
    fontSize: 16,
  },
  font10: {
    fontSize: 10,
  },
  font11: {
    fontSize: 11,
  },
  font12: {
    fontSize: 12,
  },
  font13: {
    fontSize: 13,
  },
  font14: {
    fontSize: 14,
  },
  font20: {
    fontSize: 20,
  },
  font24: {
    fontSize: 24,
  },
  font15: {
    fontSize: 15,
  },
  padL10: { paddingLeft: 10 },
  padL5: { PaddingLeft: 5 },
  width145: { width: 145 },
  padding15Left: { paddingLeft: 15 },
  padding20Left: { paddingLeft: 20 },
  marginLeftAuto: { marginLeft: 'auto' },
  borderTop: { borderTopWidth: 1, borderTopColor: '#ddd' },
  GreyBg: {
    backgroundColor: '#ddd',
  },
  cabTime: {
    paddingTop: 8,
    paddingBottom: 8,
    textAlign: 'center',
    width: 80,
    backgroundColor: '#ffffff',
    color: '#eb2026',
    fontSize: 12,
    borderRadius: 4,
    fontFamily: fonts.bold,
    alignSelf: 'center',
  },
  defaultGrey: { color: '#4a4a4a' },
  whitebg: {
    backgroundColor: '#fff',
  },
  mar8Top: {
    marginTop: 8,
  },
  mar10Top: {
    marginTop: 10,
  },
  mar20Top: {
    marginTop: 20,
  },
  mar15Top: {
    marginTop: 15,
  },
  pad5Top: {
    paddingTop: 5,
  },
  pad10Top: {
    paddingTop: 10,
  },
  pad15Top: {
    paddingTop: 15,
  },
  mar25Bt: {
    marginBottom: 25,
  },
  mar20Bt: {
    marginBottom: 20,
  },
  mar15Bt: {
    marginBottom: 15,
  },
  mar18Bt: {
    marginBottom: 18,
  },
  mar10Bt: {
    marginBottom: 10,
  },
  mar8Bt: {
    marginBottom: 8,
  },
  mar5Bt: {
    marginBottom: 5,
  },
  mar3Bt: {
    marginBottom: 3,
  },
  mar5Rt: {
    marginRight: 5,
  },
  mar10Rt: {
    marginRight: 10,
  },
  mar15Rt: {
    marginRight: 15,
  },
  mar32Rt: {
    marginRight: 32,
  },
  mar30Bt: {
    marginBottom: 30,
  },
  lineHeight16: {
    lineHeight: 16,
  },
  lineHeight18: {
    lineHeight: 18,
  },
  bgTransparent: { backgroundColor: 'transparent' },
  transperantBgrnd: {
    ...Platform.select({
      ios: {
        backgroundColor: 'transparent',
      },
    }),
  },
  flexRow: {
    flexDirection: 'row',
  },
  scissorIcon: {
    width: 12,
    height: 12,
    position: 'absolute',
    top: -5,
    left: 5,
  },
  promoBorder: {
    borderStyle: 'dotted',
    borderColor: '#ededed',
    padding: 8,
    borderWidth: 1,
  },
  alignCenter: {
    alignItems: 'center',
  },
  borderbtm: {
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
  },
  borderbtmLight: {
    borderBottomWidth: 1,
    borderBottomColor: '#e4e4e4',
  },
  borderTBLight: {
    borderBottomWidth: 1,
    borderBottomColor: '#e4e4e4',
    borderTopWidth: 1,
    borderTopColor: '#e4e4e4',
  },
  lineHeight20: {
    lineHeight: 20,
  },
  boldFont: {
    fontFamily: fonts.bold,
  },
  blackFont: {
    fontFamily: fonts.black,
  },
  lightFont: {
    fontFamily: fonts.light,
  },
  regularFont: {
    fontFamily: fonts.regular,
  },
  greenText: {
    color: '#3eb584',
  },
  BlueText: {
    color: '#008cff',
    fontFamily: fonts.bold,
  },
  WhiteText: {
    color: colors.white,
  },
  BlackText: {
    color: colors.black,
  },
  DefaultText: {
    color: colors.defaultTextColor,
  },
  lightYellow: { color: '#e58f13' },
  CenterText: {
    alignSelf: 'center',
  },
  AlignCenter: {
    textAlign: 'center',
  },
  AlignRight: {
    textAlign: 'right',
  },
  fontWeightB: {
    fontWeight: 'bold',
  },
  MamiCard: {
    backgroundColor: '#fff',
  },
  card: {
    paddingVertical: 12,
    paddingHorizontal: 14,
  },
  lineThought: { textDecorationLine: 'line-through' },
  cancellationBg: { backgroundColor: '#f6f6f6' },
  CardContent: {
    backgroundColor: '#fff',
    borderBottomWidth: 1,
    borderBottomColor: '#e4e4e4',
    flexDirection: 'row',
    padding: 15,
    elevation: 1,
  },
  needhelpCard:{
    flexDirection: 'row',
    padding: 8,
    elevation: 1,
  },
  CardContentCancel: {
    backgroundColor: '#fff',
    padding: 15,
    elevation: 2,
  },
  CardContentFull: {
    backgroundColor: '#fff',
    borderBottomWidth: 1,
    borderBottomColor: '#e4e4e4',
    padding: 15,
    elevation: 1,
  },
  RecFullBtn: {
    backgroundColor: '#fff',
    paddingBottom: 20,
    paddingTop: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 45,
    paddingRight: 35,
    borderBottomWidth: 1,
    borderBottomColor: '#e4e4e4',
  },
  dowloadBtn: {
    backgroundColor: '#fff',
    paddingBottom: 20,
    paddingTop: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 16,
    paddingRight: 16,
    borderBottomWidth: 1,
    borderBottomColor: '#e4e4e4',
  },
  CapsuleBtn: {
    backgroundColor: '#fff',
    paddingBottom: 12,
    paddingTop: 12,
    paddingLeft: 10,
    paddingRight: 10,
    borderWidth: 1,
    borderColor: '#53b2fe',
    borderRadius: 50,
    flex: 1,
    marginRight: 10,
    marginLeft: 10,
  },
  payNowBtn: {
    backgroundColor: '#fff',
    paddingBottom: 12,
    paddingTop: 12,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 50,
    flex: 1,
    marginLeft: 20,
    borderWidth: 1,
    borderColor: '#efefef',
  },
  marL0: { marginLeft: 0 },
  CapsuleBtnFill: {
    paddingBottom: 12,
    paddingTop: 12,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 50,
    marginRight: 10,
    marginLeft: 10,
  },
  CabSubmit: {
    paddingBottom: 12,
    paddingTop: 12,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 50,
    marginRight: 10,
    marginLeft: 10,
    marginTop: -28,
    alignSelf: 'center',
  },
  btnFillPrice: {
    paddingBottom: 12,
    paddingTop: 12,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 50,
    marginRight: 10,
    marginLeft: 10,
  },
  CabLoading: {
    borderRadius: 100,
    marginTop: -40,
    alignSelf: 'center',
  },
  RatingBtn: {
    paddingBottom: 0,
    paddingTop: 2,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 3,
    marginRight: 10,
    marginLeft: 10,
  },
  payNowWrap: {
    paddingBottom: 6,
    paddingTop: 11,
    paddingLeft: 15,
    paddingRight: 15,
  },
  CapsuleBtnFillOuter: {
    flex: 1,
  },
  whiteBtnHalf: {
    backgroundColor: '#ffffff',
    borderRadius: 30,
    paddingTop: 10,
    paddingBottom: 10,
    width: '46 %',
  },
  RbBtnWithIcon: {
    borderRadius: 50,
    paddingBottom: 2,
    paddingTop: 2,
    paddingLeft: 2,
    paddingRight: 2,
    width: 36,
    height: 36,
    alignSelf: 'center',
  },
  RbBtnWithIconWrap: {
    width: 80,
    paddingLeft: 6,
    paddingRight: 6,
  },
  SupportTxt: {
    width: 105,
    paddingLeft: 6,
    paddingRight: 6,
  },
  RbBtnWithIconText: {
    textAlign: 'center',
    color: '#008cff',
    lineHeight: 20,
    fontFamily: fonts.semiBold,
  },
  ActionBtnHalf: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100 %',
    backgroundColor: '#fff',
    paddingBottom: 10,
    marginTop: 15,
  },
  ActionBtn: {
    backgroundColor: '#fff',
    paddingBottom: 10,
    marginTop: 15,
  },
  marginT0: { marginTop: 0 },
  ActionBtnRec: {
    backgroundColor: '#fff',
  },
  RbBtnWithIconImg: {
    width: 36,
    height: 36,
    alignSelf: 'center',
  },
  callIcon: {
    width: 22,
    height: 22,
    marginRight: 12,
  },
  mailIcon: {
    width: 24,
    height: 20,
    marginRight: 12,
  },
  faqsIcon: {
    width: 24,
    height: 24,
    marginRight: 10,
  },
  walletIcon: {
    width: 35,
    height: 35,
    marginRight: 10,
  },
  busOffer: { width: 16, height: 16, marginLeft: 5 },
  GpsIcon: {
    height: 24,
    width: 24,
    marginRight: 5,
  },
  ContentPortion: {
    flex: 1,
  },
  width100per: { width: '100 %' },
  mar5Left: { marginLeft: 5 },
  TimingBlockOuter: {
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  TimingBlock: {
    width: '50 %',
  },
  backIconPress: {
    position: 'absolute',
    top: 20,
    left: 10,
    padding: 10,
    ...Platform.select({
      ios: {
        elevation: 5,
      },
    }),
  },
  StatusCardWrapper: {
    paddingLeft: 50,
    paddingRight: 50,
  },
  topStatusCard: {
    paddingTop: 20,
    paddingBottom: 30,
    zIndex: 10,
    position: 'relative',
    ...Platform.select({
      ios: {
        backgroundColor: 'transparent',
      },
    }),
    marginTop: -72,
  },
  topStatusCardUpcoming: {
    zIndex: 10,
    position: 'relative',
    ...Platform.select({
      ios: {
        backgroundColor: 'transparent',
      },
    }),
    marginTop: -45,
  },
  container: {
    marginTop: -67,
  },
  padding12Bottom: { paddingBottom: 12 },
  padding16Bottom: { paddingBottom: 16 },
  marginBottom100: { paddingBottom: '100%' },
  stickyHeader: {
    paddingTop: 14,
    paddingBottom: 14,
    width: '100%',
    elevation: 5,
    backgroundColor: '#fff',
    marginBottom: 6,
  },
  PriceHeader: {
    paddingTop: 14,
    paddingBottom: 14,
    width: '100%',
    elevation: 5,
  },
  margin0Bottom: { marginBottom: 0, elevation: 1 },
  bookingidDetails: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  topStatusBar: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  statusCardOuter: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  whiteBtnFull: {
    backgroundColor: '#ffffff',
    borderRadius: 30,
    paddingTop: 10,
    paddingBottom: 10,
    flex: 1,
    marginRight: 10,
  },
  whiteBtnHalfOuter: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginRight: 15,
  },
  whiteBtnSingle: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignSelf: 'center',
    width: 170,
  },

  btnSingle: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignSelf: 'center',
    width: 170,
  },
  HotelMainInfo: {
    flex: 1,
    marginRight: 10,
  },
  HotelMainImgContainer: {
    width: '25%',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  cabImg: {
    width: 86,
    height: 48,
    resizeMode: 'contain',
  },
  HotelMainImg: {
    height: 60,
    width: 60,
  },
  StarRating: {
    flexDirection: 'row',
    paddingLeft: 10,
  },
  CabDetailsInfo: {
    flexDirection: 'row',
    paddingTop: 10,
    paddingBottom: 15,
  },
  HotelNightsRow: {
    flexDirection: 'row',
    paddingTop: 15,
    paddingBottom: 15,
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
  },
  HotelNightsOuter: {
    flexDirection: 'row',
    marginLeft: 25,
  },
  HotelNightsCol: {
    width: '45%',
  },
  HotelRGRow: {
    flexDirection: 'row',
    paddingTop: 15,
    paddingBottom: 15,
    alignItems: 'center',
  },
  HotelRooms: {
    flex: 1,
  },
  HotelLabel: {
    paddingRight: 25,
  },
  marginTop24: { marginTop: 24 },
  marginTop18: { marginTop: 18 },
  marginTop30: { marginTop: 30 },
  marginTop10: { marginTop: 10 },
  marginTop5: { marginTop: 5 },
  roomOuter: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
  roomContent: {
    width: '75%',
  },
  marL12per: { paddingLeft: '12%' },
  starspace: {
    fontSize: 6,
    color: 'transparent',
  },
  adultIcon: {
    width: 16,
    height: 11,
  },
  aminitiesOuter: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  aminities: {
    marginRight: 10,
    color: '#00a19c',
    fontSize: 13,
  },
  lightGreenTxt: { color: '#00a19c' },
  aminitiesIcon: {
    width: 16,
    height: 16,
    marginRight: 5,
  },
  aminitieswrap: {
    flexDirection: 'row',
    width: '50%',
    alignItems: 'center',
  },
  Paymentrow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  busRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  travellersIcon: { width: 24, height: 24, marginRight: 10 },
  PaymentLeft: {
    flex: 0.9,
  },
  busLeft: {
    flex: 0.9,
  },
  hotelimg: {
    width: 60,
    height: 60,
  },
  PriceTotal: {
    paddingTop: 16,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 16,
  },
  hotelimgOuter: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  hotelimgShadow: {
    position: 'relative',
    backgroundColor: '#333',
    zIndex: 2,
    width: 60,
    height: 60,
  },
  hotelimgText: {
    position: 'absolute',
    zIndex: 2,
    width: 60,
    marginTop: 12,
    textAlign: 'center',
    color: '#fff',
    fontSize: 16,
  },
  hotelAminitiesOuter: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  amenityIcon: {
    width: 23,
    height: 18,
    marginBottom: 7,
    alignSelf: 'center',
  },
  hotelAminitiesWrap: {
    flex: 1,
    marginRight: 2,
  },
  errorStrip: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 20,
  },
  padding20: { padding: 20 },
  infoIcon: {
    width: 16,
    height: 16,
    marginRight: 10,
  },
  cabparking: {
    width: 24,
    height: 24,
    marginRight: 5,
  },
  infoText: {
    color: '#e53442',
    fontStyle: 'italic',
    fontSize: 12,
  },

  freeParkingStrip: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 20,
  },
  parkingIcon: {
    width: 19,
    height: 25,
    marginRight: 10,
  },
  rateStaybtn: {
    width: 40,
    height: 40,
    borderRadius: 30,
    alignSelf: 'center',
  },
  rateStaybtnWhite: {
    width: 38,
    height: 38,
    borderRadius: 30,
    backgroundColor: '#fff',
    marginLeft: 1,
    marginTop: 1,
  },
  borderB0: { borderBottomWidth: 0 },
  stickyBarWrapper: {
    flexDirection: 'row',
    width: '85%',
    alignItems: 'center',
  },
  paddingL20: { paddingLeft: 20 },
  stickyTextWrapper: {
    paddingLeft: 15,
  },
  backarrow: {
    width: 16,
    height: 16,
  },
  closeIcon: { width: 24, height: 24 },
  shareicon: {
    width: 22,
    height: 17,
  },
  topStatusIcon: {
    width: 70,
    height: 70,
  },
  cabConfirmIcon: { width: 48, height: 48 },
  topStatusConfirmIcon: {
    width: 100,
    height: 60,
  },
  RecFullBtnArrow: {
    width: 9,
    height: 12,
  },
  arrowRightGray: {
    width: 16,
    height: 16,
  },
  starsRatings: {
    width: 14,
    height: 14,
  },
  rateStaybtnText: {
    marginTop: 10,
    textAlign: 'center',
  },
  successText: {
    color: '#00a19c',
    fontSize: 12,
  },
  refundStrip: {
    width: '100%',
    backgroundColor: '#ccc',
    height: 3,
  },
  refundHalfbar: {
    width: '50%',
    height: 3,
  },
  blueColor: { color: '#008cff' },
  refundFullbar: {
    width: '100%',
    height: 3,
  },
  refundGreenBackground: {
    backgroundColor: '#32d18f',
  },
  refundYellowBackground: {
    backgroundColor: '#e5c055',
  },
  refundbarInfo: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  refundCardhead: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  refundCardheadText: {
    width: '65%',
  },
  refundCardOuter: {
    paddingLeft: 15,
    paddingRight: 15,
    paddingBottom: 10,
    paddingTop: 10,
  },
  refundCardWrap: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  refundCardLeft: {
    width: '75%',
    marginTop: 4,
  },
  MimaRadio: {
    width: 18,
    height: 18,
    backgroundColor: '#fff',
    borderRadius: 20,
    borderWidth: 1,
    borderColor: '#00a19c',
    marginRight: 20,
    marginTop: 5,
  },
  MimaRadioInside: {
    width: 12,
    height: 12,
    backgroundColor: '#00a19c',
    borderRadius: 20,
    marginLeft: 2,
    marginTop: 2,
  },
  refundText: {
    marginLeft: 38,
  },
  leftText: {
    marginLeft: 30,
  },
  MamiCardGrey: {
    backgroundColor: '#ddd',
    paddingLeft: 10,
    paddingRight: 10,
  },
  grey: { backgroundColor: '#3b385e' },
  impInfoGreenOuter: {
    backgroundColor: '#c8f8f6',
    width: '100%',
    padding: 10,
  },
  includedGreen: {
    color: '#31a498',
  },
  impInfoGreen: {
    color: '#00a19c',
    fontSize: 13,
  },
  row: { flexDirection: 'row' },
  impInfoRedOuter: {
    backgroundColor: '#ffe3d5',
    width: '100%',
    padding: 10,
  },
  impInfoRed: {
    color: '#e53442',
    fontSize: 13,
  },
  redText: { color: '#d0021b' },
  darkRed: { color: '#eb2026' },
  lightRed: { color: '#e53442' },
  topHeader: {
    padding: 15,
    flexDirection: 'row',
    alignItems: 'center',
    height: 56,
  },
  ScrolableHead: {
    paddingLeft: 15,
    paddingBottom: 14,
    paddingTop: 15,
    elevation: 1,
    backgroundColor: '#fff',
    marginBottom: 2,
  },
  listOuter: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 310,
  },
  listItems: {
    paddingTop: 13,
    paddingBottom: 13,
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
    fontFamily: fonts.regular,
  },
  crousalOuter: {
    width: '100%',
  },
  imgg: {
    width: '100%',
    height: '100%',
  },
  headerPaddingbtm: {
    paddingBottom: 70,
  },
  marginBtm: { marginBottom: 16 },

  padVertical10: {
    paddingVertical: 10,
  },
  whiteContainer: {
    backgroundColor: '#ffffff',
    flex: 1,
  },
  PolicyHeading: {
    paddingVertical: 15,
    flexDirection: 'row',
    paddingHorizontal: 18,
    backgroundColor: '#ffffff',
    elevation: 2,
    marginBottom: 2,
  },
  PolicyHeadingText: {
    paddingTop: 3,
  },
  CrossIcon: {
    width: 20,
    height: 20,
    marginRight: 10,
  },
  HotelIcon: {
    width: 18,
    height: 20,
    marginRight: 10,
  },
  PolicyDetailsContainer: {
    paddingVertical: 18,
    paddingRight: 35,
    paddingLeft: 25,
    elevation: 0.5,
    backgroundColor: '#ffffff',
    marginBottom: 5,
  },
  ItemDisc: {
    width: 6,
    height: 6,
    borderRadius: 25,
    backgroundColor: '#9b9b9b',
    marginRight: 15,
    marginTop: 6,
  },
  HotelVoucherWrapper: {
    padding: 15,
  },
  paddingTop18: { paddingTop: 18 },
  paddingTop16: { paddingTop: 16 },
  paddingBottom18: { paddingBottom: 18 },
  paddingBottom8: { paddingBottom: 8 },
  paddingTop10: { paddingTop: 10 },
  paddingBottom10: { paddingBottom: 10 },
  HotelVoucherForm: {
    borderRadius: 3,
    elevation: 2,
    backgroundColor: '#ffffff',
    minHeight: 120,
    paddingVertical: 15,
    paddingHorizontal: 18,
  },
  pad20Bottom: { paddingBottom: 20 },
  formText: {
    width: 60,
    fontSize: 15,
  },
  formField: {
    flex: 1,
    backgroundColor: '#ffffff',
    height: 30,
    fontSize: 14,
    lineHeight: 18,
    padding: 0,
    borderBottomColor: '#dddddd',
    borderBottomWidth: 1,
  },

  showView: {
    display: 'flex',
  },
  hideView: {
    display: 'none',
  },
  // standolone page
  ChooseOneRadio: {
    marginRight: 10,
    marginTop: 2,
  },
  mar4Top: { marginTop: 4 },
  italic: {
    fontStyle: 'italic',
  },
  PAHXInfo: {
    padding: 15,
  },
  priceBreakup: {
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 10,
    paddingBottom: 10,
  },
  PAHXInfoHeading: {
    backgroundColor: '#fff',
    borderBottomWidth: 1,
    borderBottomColor: '#e4e4e4',
    flexDirection: 'row',
    paddingHorizontal: 15,
    paddingVertical: 10,
    elevation: 2,
  },
  RefundProcessCard: {
    flexDirection: 'row',
  },
  RefundLeftSection: {
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 200,
    marginRight: 10,
  },
  driverDetails: {
    backgroundColor: 'white',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 16,
    borderRadius: 4,
  },
  detailsInfo: {
    color: '#000',
    fontSize: 14,
    fontFamily: fonts.bold,
    marginBottom: 6,
  },
  lockIcon: { width: 40, height: 40 },
  contactDetails: {
    fontSize: 12,
    color: '#000',
    fontFamily: fonts.regular,
    marginBottom: 4,
  },
  departTime: { fontSize: 12, color: '#000', fontFamily: fonts.bold },
  RefundProcessLine: {
    width: 3,
    backgroundColor: '#ccc',
    height: 200,
    borderRadius: 25,
  },
  RefundProcessSuccess: {
    backgroundColor: '#00a19c',
    flex: 1,
  },
  RefundProcessHalf: {
    backgroundColor: '#e5c055',
    flex: 0.5,
  },
  RefundProcessSuccessHalf: {
    backgroundColor: '#00a19c',
    flex: 0.5,
  },
  flexStart: { alignSelf: 'flex-start' },
  priceBreakupSection: {
    borderBottomWidth: 1,
    borderBottomColor: '#e4e4e4',
    elevation: 1,
  },
  RefundRightSection: {
    marginLeft: 5,
    width: '75%',
  },
  RefundRightProcessDtls: {
    justifyContent: 'space-between',
    height: 200,
  },
  RefundRefectTimeText: {
    paddingLeft: 20,
    fontSize: 12,
  },

  SuccessTickIcon: {
    width: 16,
    height: 16,
    marginTop: 3,
    marginRight: 5,
  },
  lightGrey: { color: '#9b9b9b' },
  instantRefundColor: { color: '#2a9c98' },
  RefundPaymentDtls: {
    marginLeft: 20,
  },
  darkGrey: { color: '#747474' },
  ErrorStateWrapper: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    paddingHorizontal: 15,
  },

  dateChangeIcon: {
    width: 70,
    height: 70,
  },
  ErrorPageText: {
    fontSize: 20,
    textAlign: 'center',
    lineHeight: 25,
    color: '#3c3c3c',
  },
  width300: { width: 300 },
  width250: { width: 250 },
  width100: { width: 100 },
  width160: { width: 160 },
  width170: { width: 170 },
  mar50Bt: {
    marginBottom: 50,
  },
  mar150Bt: {
    marginBottom: 150,
  },
  lineHt22: {
    lineHeight: 22,
  },
  CommonFillBtn: {
    borderRadius: 25,
    paddingVertical: 8,
    paddingHorizontal: 25,
  },
  bgTransparent: { backgroundColor: 'transparent' },
  TextArea: {
    borderWidth: 1,
    borderColor: '#e5e5e5',
    marginBottom: 25,
    marginLeft: 35,
  },
  textInput: { textAlignVertical: 'top', padding: 10 },
  TickIcon: { width: 20, height: 20, marginRight: 10 },
  refundProcessContainer: {
    width: 300,
    elevation: 2,
    borderRadius: 4,
    alignSelf: 'center',
    paddingTop: 20,
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 20,
  },
  refundProcessCancelled: {
    width: 300,
    elevation: 2,
    borderRadius: 4,
    alignSelf: 'center',
  },
  cancelLoader: {
    flex: 1,
    justifyContent: 'center',
  },
  horizontalLoader: {
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  width40: { width: 50, height: 50 },
  searchCab: { position: 'absolute', top: 16 },
  colorBlue: { color: '#46fbf0' },
  cabAddressWidth: { width: 220 },
  mar12Lt: { marginLeft: 12 },
  WhiteStickyHeader: {
    backgroundColor: '#fff',
    paddingTop: 10,
    paddingBottom: 10,
    elevation: 3,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 2,
  },
  mediumFont: {
    fontFamily: fonts.medium,
  },
  cancelBreadcrumb: {
    paddingVertical: 7,
    paddingHorizontal: 15,
    justifyContent: 'space-between',
    backgroundColor: '#e3e7eb',
  },
  padding16: { padding: 16 },
  refundInfoLeft: { width: '80%' },
  refundIcon: {
    width: 24,
    height: 24,
  },
  activeBreadCrumb: {
    color: colors.defaultTextColor,
    fontFamily: fonts.medium,
  },
  coTravellerIcon: {
    width: 16,
    height: 11,
  },
  bookedCabType: {
    fontSize: 14,
    color: colors.defaultTextColor,
    marginBottom: 5,
    fontFamily: fonts.bold,
  },
  cancelInfoTitle: {
    fontSize: 14,
    color: colors.black,
    fontFamily: fonts.medium,
    marginBottom: 5,
  },
  cancelInfoTPrice: {
    fontSize: 14,
    color: colors.black,
    fontFamily: fonts.medium,
    textAlign: 'right',
  },
  cancelInfoSubPrice: {
    color: '#9b9b9b',
    fontFamily: fonts.medium,
    textAlign: 'right',
  },
  cancelInfoSubCtg: {
    color: '#9b9b9b',
    fontFamily: fonts.medium,
    marginBottom: 3,
  },
  cancelRefundTxt: {
    fontSize: 14,
    color: '#00a19c',
    fontFamily: fonts.black,
    marginBottom: 3,
  },
  overlayContainer: {
    justifyContent: 'flex-end',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    zIndex: 1000,
    elevation: 3,
    backgroundColor: '#fff'
  },
  overlayBg: {
    backgroundColor: 'rgba(0,0,0,.5)',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    zIndex: 1,
  },
  overlayContent: {
    backgroundColor: '#fff',
    paddingVertical: 20,
    paddingHorizontal: 20,
    position: 'relative',
    zIndex: 100,
    justifyContent: 'flex-end',
  },
  cabOverlayContent: {
    backgroundColor: '#fff',
    paddingVertical: 20,
    paddingHorizontal: 20,
    position: 'relative',
    zIndex: 100,
  },
  actionBlueText: { color: '#008cff', fontSize: 16, fontFamily: fonts.bold },
  cancelTxt: {
    fontSize: 16,
    color: '#4a4a4a',
    fontFamily: fonts.regular,
    lineHeight: 22,
  },
  overlayHead: { fontSize: 24, color: '#000' },
  CancelledIcon: {
    width: 85,
    height: 80,
    marginRight: 15,
    marginTop: -16,
  },
  cabRefundZeroScreen: {
    height: 250,
  },
  CancPriceBreckup: {
    justifyContent: 'space-between',
    paddingTop: 16,
    paddingBottom: 2,
    paddingRight: 16,
    paddingLeft: 16,
  },
  borderbtm: {
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
  },
  CancLeftSection: {
    flexDirection: 'row',
  },
  impInfoGreen: {
    fontFamily: fonts.bold,
    color: '#00a19c',
    fontSize: 16,
  },
  priceBreakups: {
    justifyContent: 'space-between',
    paddingRight: 16,
    paddingLeft: 16,
    paddingBottom: 5,
  },
  rightComponent: {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 30,
  },
  impInfoRed: {
    fontFamily: fonts.bold,
    color: '#d0021b',
    fontSize: 16,
  },
  linkTxt: {
    fontFamily: fonts.bold,
    color: '#008cff',
    fontSize: 12,
    marginLeft: 10,
    marginTop: 2,
  },
  fbHeadingTxt: {
    fontFamily: fonts.black,
    color: '#000',
    fontSize: 16,
  },
  fareBrkupRowLineTxt: {
    fontSize: 14,
    fontFamily: fonts.regular,
  },
  instantRefundTag: {
    height: 18,
    borderRadius: 8,
    width: 115,
    alignItems: 'center',
    justifyContent: 'center',
  },
  instantRefundTagTxxt: {
    fontFamily: fonts.bold,
    fontSize: 10,
    color: '#fff',
    alignSelf: 'center',
  },
  refundModeMessageCard: {
    backgroundColor: '#fff',
    borderBottomWidth: 1,
    borderBottomColor: '#e4e4e4',
    flex: 1,
    padding: 15,
  },
  rowSection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  totalAmountSection: {
    borderTopWidth: 1,
    borderTopColor: '#CCC',
    paddingTop: 15,
  },
  trackingIcon: {
    width: 24,
    height: 24,
    marginRight: 8,
  },
  subtextSection: {
    top: -4,
  },
  depositCard: {
    borderRadius: 4,
    backgroundColor: '#f5f5ff',
    padding: 10,
    borderWidth: 1,
    borderColor: '#d9ebff',
  },
  imgPlaceHolder: {
    width: 24,
    height: 24,
    borderRadius: 25,
    marginRight: 10,
  },
  driverImgStyle: {
    width: 20,
    height: 21,
    alignSelf: 'center',
    top: 2,
  },
  fuelTypeGreen: {
    backgroundColor: '#28beb2',
    borderRadius: 4,
    paddingHorizontal: 4,
    paddingVertical: 4,
  },
  fuelTypeOrange: {
    backgroundColor: '#f09819',
    borderRadius: 4,
    paddingHorizontal: 4,
    paddingVertical: 4,
  },
  bulletCircle: {
    width: 6,
    height: 6,
    backgroundColor: '#5a5a5a',
    borderRadius: 20,
    top: 5,
    left: 5,
  },
  infoCardDisc: {
    fontSize: 14,
    lineHeight: 18,
    paddingLeft: 20,
    fontFamily: fonts.regular,
  },
  imgWrapper: {
    width: 30,
    height: 30,
    top: 2,
  },
  flexOne: {
    flex: 1,
  },
  marginBottom8: { marginBottom: 8 },
  //        blackText:{color:'#000'},
  fontRegular: { fontFamily: fonts.regular },
  fontBold: { fontFamily: fonts.bold },
  rowCenter: { flexDirection: 'row' },
  rideFranchiseCard: {
    borderTopColor: '#E7E7E7',
    borderTopWidth: 1,
    paddingTop: 12,
    paddingBottom: 12,
    marginTop: 12,
  },
  rideImgStyle: {
    width: 38,
    height: 20,
    marginHorizontal: 5,
    resizeMode: 'contain',
  },
  amenitiesDetailsWrapper: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    display: 'flex',
  },
  dot: {
    width: 4,
    height: 4,
    backgroundColor: '#777777',
    marginRight: 5,
    borderRadius: 5,
  },
  amenListWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '45%',
    marginBottom: 5,
  },
  downArrowStyle: {
    marginTop: 4,
    width: 10,
    height: 6,
  },
  upArrowStyle: {
    marginTop: 4,
    width: 10,
    height: 6,
    transform: [
      {
        rotate: '180deg',
      },
    ],
  },
  carCategoryDesc: {
    flexDirection: 'row',
    fontSize: 14,
    marginTop: 10,
    fontWeight: fonts.bold,
    alignItems: 'center',
  },
  leafIcon: {
    width: 8,
    height: 14,
    top: -3
  },
  cardHeading: {
    paddingVertical: 12,
    paddingHorizontal: 14,
  },
  flex:{
    flexDirection: 'row',
  },
  buttonRadiusStyle: {
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
  },
  customStyle: {
    paddingLeft: 14,
    paddingRight: 14,
  },
  square: {
    height: 18,
    width:18,
    backgroundColor: '#EEEEEE',
    borderRadius:3,
    marginRight: 5
  },
  oldBookingSection: {
    backgroundColor: 'red',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 8,
    width: '90%',
    left: '5%',
    marginTop:20,
    zIndex:10,
  }
};

export default styles;
