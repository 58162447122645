import React from 'react';
import { View, TouchableOpacity } from 'react-native';
import styles from './RequestValidationOverlay';

const OverlayMessage = React.memo(({ closeOverlay, content }) => (
  <View style={styles.overlayWrapper}>
    <TouchableOpacity onPress={() => closeOverlay()} activeOpacity={1} style={styles.overlay} />
    <View style={styles.overlayContent}>{content}</View>
  </View>
));

export default OverlayMessage;
