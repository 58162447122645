import Fecha from 'fecha';
import {Platform} from'react-native';

import {AIRLINE_IMG_URL} from '../FlightsConfig';
import {isNotNullAndEmpty} from '../../../hotel/details/utils/HotelBookingDetailUtil';
import {FILTERS} from './ListingConstant';
import {getScreenDensityName} from '@mmt/legacy-commons/Helpers/displayHelper';

export const AIRLINE_IMAGE_BASEURL = `https://imgak.mmtcdn.com/flights/assets/media/mobile/common/${Platform.OS === 'ios' ? getScreenDensityName().toUpperCase() : getScreenDensityName()}/{CODE}.png?v=2`;


export function getTechStoppagesNames(tchStp, index) {
  const stops = [];
  tchStp.map((tchStop) => stops.push(tchStop.locInfo.cityCd));
  return stops;
}

export function getStoppagesNames(itinerary, index) {
  const stops = [];
  itinerary.flts.map((arrival) => stops.push(arrival.arrInfo.cityCd));
  return stops;
}

export function getNoOfStopsText(stops) {
  return stops > 0 ? (`${stops} Stop`) : 'Non Stop';
}
export function getDurationText(duration) {
  const hr = Math.floor(duration / 60);
  const min = duration % 60;

  let durText = '';
  if (hr > 0) {
    durText = `${durText + hr}h `;
  }
  if (min > 0) {
    durText = `${durText + min}m`;
  }

  return durText;
}


export function getFlightItemListMap(response) {
  const listItemMap = [];
  response.rcomGrps.forEach((rcomGrp) => {
    rcomGrp.recoms.forEach(recommendation =>
      getMappedListItem(recommendation, listItemMap, response));
  });
  return listItemMap;
}

export function getMappedListItem(recomm, listItemMap, searchResponse) {
  const itinerary = getItinerary(recomm.itJrnyIdx[0], searchResponse, 0);
  if (itinerary == null) { return; }
  const listItem = {};
  listItem.odc = recomm.odc;
  listItem.pay = recomm.odc.pay;
  if (recomm.cData) {
    listItem.outOfPolicy = !recomm.cData.wPolicy;
  }

  if (recomm.fareBreakup) {
    listItem.fareBreakup = recomm.fareBreakup;
    listItem.additionalInfoList = recomm.additionalInfoList;
  } else {
    listItem.priceBreakup = recomm.priceBreakup;
  }
  listItem.legs = [];
  if (searchResponse.odcSummary.pnrList[0].requestedItineraries['0']) { listItem.legs.push(getListItemLeg(itinerary, searchResponse, 0)); }
  if (recomm.itJrnyIdx[1] != null) {
    const retItinerary = getItinerary(recomm.itJrnyIdx[1], searchResponse, 1);
    if (retItinerary && searchResponse.odcSummary.pnrList[0].requestedItineraries['1']) {
      listItem.legs.push(getListItemLeg(retItinerary, searchResponse, 1));
    }
  }
  listItemMap.push(listItem);
}

function getListItemLeg(itinerary, searchResponse, index) {
  const leg = {};
  leg.dur = itinerary.dur;
  if (itinerary.flts.length === 1 && itinerary.flts[0].tchStp && itinerary.flts[0].tchStp.length > 0) {
    leg.noOfStop = itinerary.flts[0].tchStp.length;
    leg.stoppages = getTechStoppagesNames(itinerary.flts[0].tchStp, index);
    leg.tchStop = true;
  } else {
    leg.noOfStop = itinerary.flts.length - 1;
    leg.stoppages = getStoppagesNames(itinerary, index);
    leg.tchStop = false;
  }
  leg.airline = itinerary.flts[0].mrkAl;
  leg.airlineName = getAirlineName(leg.airline, searchResponse);

  if (itinerary.flts[0].oprAl && itinerary.flts[0].oprAl !== itinerary.flts[0].mrkAl) {
    let operatedByAirlineName = getAirlineName(itinerary.flts[0].oprAl, searchResponse);
    if (!operatedByAirlineName) {
      operatedByAirlineName = itinerary.flts[0].oprAl;
    }
    leg.opAirline = `*Operated by ${operatedByAirlineName}`;
  }

  const fromTo = getFromToCity(searchResponse.meta.searchCriteria.itineraryList, index);
  leg.from = fromTo.from;
  leg.to = fromTo.to;

  leg.depTime = getTime(itinerary.depDate);
  leg.arrTime = getTime(itinerary.arrDate);
  return leg;
}

function getFromToCity(itList, index) {
  const fromTo = {};
  if (isNotNullAndEmpty(itList) && itList[index] != null) {
    fromTo.from = itList[index].from;
    fromTo.to = itList[index].to;
  }
  return fromTo;
}

function getItinerary(itineraryIdx, searchResponse, index) {
  return searchResponse.itJrnyLst[index][itineraryIdx];
}

function getAirlineName(airlineCode, searchResponse) {
  if (searchResponse.alCdeMap != null && searchResponse.alCdeMap[airlineCode] != null) {
    return searchResponse.alCdeMap[airlineCode].nm;
  }
  return '';
}


export function getTime(date) {
  if (date == null) {
    return date;
  }
  const dateObject = Fecha.parse(date.split('T')[0], 'YYYY-MM-DD HH:mm');
  return Fecha.format(new Date(dateObject), 'HH:mm');
}


export function getFareComponent(listItem, odcSummary) {
  const pnr = odcSummary.pnrList[0];
  const selectedItem = {};
  selectedItem.crKey = odcSummary.crKey;
  selectedItem.rKey = listItem.odc.rKey;
  selectedItem.old = pnr.old;
  selectedItem.anc = pnr.anc;
  selectedItem.dcMarkup = pnr.dcMarkup;
  selectedItem.nf = listItem.odc.nf;
  selectedItem.dcf = listItem.odc.dcf;
  selectedItem.oldTotal = selectedItem.old + selectedItem.anc;
  selectedItem.dct = listItem.odc.dcf + pnr.dcMarkup;
  const fareDiff = selectedItem.nf - selectedItem.oldTotal;
  selectedItem.farediff = fareDiff.toFixed(2);
  selectedItem.pay = listItem.odc.pay;
  return selectedItem;
}

export function getAirlineImage(airlineCode) {
  return `${AIRLINE_IMG_URL}/${airlineCode}.png`;
}

export const getAirlineImageUrl = (airlineCode) => {
  if (airlineCode == null || airlineCode.isEmpty) { return null; }
  return AIRLINE_IMAGE_BASEURL.replace('{CODE}', airlineCode);
};

export function getBookingType(meta) {
  if (meta != null && meta.searchCriteria != null) {
    return meta.searchCriteria.funnelType;
  }
  return 'DOMESTIC';
}

export function getListingHeader(response) {
  const headerInfo = {};
  const meta = response.meta;
  const alCdeMap = response.alCdeMap;
  headerInfo.origAirline = '';
  headerInfo.journeyType = '';
  if (meta != null && meta.searchCriteria != null) {
    headerInfo.origAirline = getOrigAirline(meta, alCdeMap);
    headerInfo.journeyType = getJourneyType(response);
    headerInfo.title = getTitle(meta.searchCriteria);
    headerInfo.subTitle = getSubtitle(meta.searchCriteria);
  }
  return headerInfo;
}


function getTitle(searchCriteria) {
  const titleArr = [];
  let title = '';
  if (searchCriteria.itineraryList != null && searchCriteria.itineraryList.length > 0) {
    searchCriteria.itineraryList.forEach((itinerary, index) => {
      title = `${itinerary.from} - ${itinerary.to}`;
      if (itinerary.depDate != null) {
        title = `${title} | ${getDateFormat(itinerary.depDate, 'ddd, DD MMM YYYY')}`;
      }
      titleArr.push(title);
    });
  }
  return titleArr;
}

function getDateFormat(date, outputFormat) {
  const dateObject = Fecha.parse(date.split('T')[0], 'YYYY-MM-DD');
  return Fecha.format(new Date(dateObject), outputFormat);
}

export function getNoOfTravellers(searchCriteria) {
  let noOfTravellers = 0;
  if (searchCriteria.numAdults != null) {
    noOfTravellers += searchCriteria.numAdults;
  }
  if (searchCriteria.numChildren != null) {
    noOfTravellers += searchCriteria.numChildren;
  }
  if (searchCriteria.numInfants != null) {
    noOfTravellers += searchCriteria.numInfants;
  }
  return noOfTravellers;
}

function getSubtitle(searchCriteria) {
  const noOfTravellers = getNoOfTravellers(searchCriteria);

  let subtitle = '';
  if (noOfTravellers > 0) {
    subtitle = noOfTravellers + (noOfTravellers > 1 ? ' Travellers ' : ' Traveller ');
  }
  if (searchCriteria.cabinClass != null) {
    subtitle = `${subtitle} | ${searchCriteria.cabinClass}`;
  }
  return subtitle;
}

export function getFilterInfo(response) {
  const {itinFltrDta} = response;
  const filterInfo = {};
  const itinFilterInfoLst = [];
  itinFltrDta.forEach((fltrDta, index) => {
    if (Object.keys(fltrDta).length > 0) {
      const itinFilterInfo = {itinFltr: fltrDta};
      const requestedItinerariesKeyArr =  Object.keys(response.odcSummary.pnrList[0].requestedItineraries);
      if (requestedItinerariesKeyArr.length === 1) {
        itinFilterInfo.fcn = getCityNameFromCode(response, response.odcSummary.pnrList[0].requestedItineraries[`${requestedItinerariesKeyArr[0]}`].split('-')[0]);
      } else {
        itinFilterInfo.fcn = getCityNameFromCode(response, response.odcSummary.pnrList[0].requestedItineraries[`${index}`].split('-')[0]);
      }
      itinFilterInfoLst.push(itinFilterInfo);
    }
  });
  filterInfo.itinFltrLst = itinFilterInfoLst;
  if (response.cmnFltrDta.alLst) { filterInfo.alFltrLst = response.cmnFltrDta.alLst; } else { filterInfo.alFltrLst = []; }
  return filterInfo;
}

//on module unficiation this method is already present in core-ui-lib odc
export const getCalendarDataList = (response) => {
  const { calDataLst } = response;
  return calDataLst;
};


function getCityNameFromCode(response, cityCd) {
  if (response.ctyCdeMap && response.ctyCdeMap[cityCd] != null) {
    return response.ctyCdeMap[cityCd].nm;
  }
  return '';
}

export function getInitFilters(filterInfo) {
  const currFltrObj = {};
  const alFltrLst = [];
  const itinFltrLst = [];
  filterInfo.alFltrLst.forEach((al) => {
    const alCdObj = {};
    alCdObj.ac = al.cd;
    alCdObj.selected = false;
    alFltrLst.push(alCdObj);
  });
  currFltrObj.alFltrLst = alFltrLst;
  filterInfo.itinFltrLst.forEach((item) => {
    const obj = {};
    const stpsLst = [];
    const depLst = [];
    item.itinFltr.stpsLst.forEach((stp) => {
      stpsLst.push(false);
    });
    item.itinFltr.depTmSlot.forEach((dep) => {
      depLst.push(false);
    });
    obj.currStpLst = stpsLst;
    obj.currDepTmLst = depLst;
    itinFltrLst.push(obj);
    currFltrObj.itinFltrLst = itinFltrLst;
  });
  return currFltrObj;
}

export function filterSearchMap(searchMap, currFltrObj) {
  if (isEmptyFltr(currFltrObj)) return searchMap;
  if (!isOpAlFltrEmpty(currFltrObj.alFltrLst)) { searchMap = filterSearchMapByOpAl(searchMap, currFltrObj); }
  if (!isStpFltrEmpty(currFltrObj.itinFltrLst)) { searchMap = filterSearchMapByStp(searchMap, currFltrObj); }
  if (!isDepTmFltrEmpty(currFltrObj.itinFltrLst)) { searchMap = filterSearchMapByDepTm(searchMap, currFltrObj); }
  return searchMap;
}

function filterSearchMapByOpAl(searchMap, currFltrObj) {
  const newSearchMap = [];
  searchMap.forEach((listItem) => {
    listItem.legs.forEach((leg) => {
      if (isAlFltr(leg.airline, currFltrObj.alFltrLst)) {
        newSearchMap.push(listItem);
      }
    });
  });
  return newSearchMap;
}

function filterSearchMapByStp(searchMap, currFltrObj) {
  const newSearchMap = [];
  searchMap.forEach((listItem) => {
    if (listItem.legs.length === 2 && !isStpFltrEmptyFrLeg(currFltrObj.itinFltrLst[0]) && !isStpFltrEmptyFrLeg(currFltrObj.itinFltrLst[1])) {
      if (currFltrObj.itinFltrLst[0].currStpLst[listItem.legs[0].noOfStop] && currFltrObj.itinFltrLst[1].currStpLst[listItem.legs[1].noOfStop]) {
        newSearchMap.push(listItem);
      }
    } else {
      listItem.legs.forEach((leg, index) => {
        if (currFltrObj.itinFltrLst[index].currStpLst[leg.noOfStop]) {
          newSearchMap.push(listItem);
        }
      });
    }
  });
  return newSearchMap;
}

function filterSearchMapByDepTm(searchMap, currFltrObj) {
  const newSearchMap = [];
  searchMap.forEach((listItem) => {
    if (listItem.legs.length === 2 && !isDepTmFltrEmptyFrLeg(currFltrObj.itinFltrLst[0]) && !isDepTmFltrEmptyFrLeg(currFltrObj.itinFltrLst[1])) {
      if (currFltrObj.itinFltrLst[0].currDepTmLst[getDepFltrIndx(listItem.legs[0].depTime)] && currFltrObj.itinFltrLst[1].currDepTmLst[getDepFltrIndx(listItem.legs[1].depTime)]) {
        newSearchMap.push(listItem);
      }
    } else {
      listItem.legs.forEach((leg, index) => {
        if (currFltrObj.itinFltrLst[index].currDepTmLst[getDepFltrIndx(leg.depTime)]) {
          newSearchMap.push(listItem);
        }
      });
    }
  });
  return newSearchMap;
}

function isAlFltr(ac, alFltrLst) {
  for (let i = 0; i < alFltrLst.length; i++) {
    if (alFltrLst[i].ac === ac) return alFltrLst[i].selected;
  }
  return false;
}

export function isEmptyFltr(currFltrObj) {
  if (!currFltrObj || currFltrObj.alFltrLst.length === 0 || currFltrObj.itinFltrLst.length === 0) return true;
  if (isOpAlFltrEmpty(currFltrObj.alFltrLst) && isStpFltrEmpty(currFltrObj.itinFltrLst) && isDepTmFltrEmpty(currFltrObj.itinFltrLst)) { return true; } return false;
}

function isOpAlFltrEmpty(alFltrLst) {
  for (let i = 0; i < alFltrLst.length; i++) {
    if (alFltrLst[i].selected) return false;
  }
  return true;
}

function isStpFltrEmpty(itinFltrLst) {
  for (let i = 0; i < itinFltrLst.length; i++) {
    for (let j = 0; j < itinFltrLst[i].currStpLst.length; j++) {
      if (itinFltrLst[i].currStpLst[j] === true) return false;
    }
  }
  return true;
}

function isDepTmFltrEmpty(itinFltrLst) {
  for (let i = 0; i < itinFltrLst.length; i++) {
    for (let j = 0; j < itinFltrLst[i].currDepTmLst.length; j++) {
      if (itinFltrLst[i].currDepTmLst[j] === true) return false;
    }
  }
  return true;
}

function getDepFltrIndx(depTm) {
  const depTmObj = Fecha.parse(depTm, 'HH:mm');
  const mTm = Fecha.parse('06:00', 'HH:mm');
  const nTm = Fecha.parse('12:00', 'HH:mm');
  const eTm = Fecha.parse('18:00', 'HH:mm');
  const niTm = Fecha.parse('00:00', 'HH:mm');
  const midNtTm = Fecha.parse('23:59', 'HH:mm');
  if (depTmObj >= niTm && depTmObj < mTm) return 0;
  if (depTmObj >= mTm && depTmObj < nTm) return 1;
  if (depTmObj >= nTm && depTmObj < eTm) return 2;
  if (depTmObj >= eTm && depTmObj <= midNtTm) return 3;
  return -1;
}

function isStpFltrEmptyFrLeg(itinFltrLstItm) {
  for (let j = 0; j < itinFltrLstItm.currStpLst.length; j++) {
    if (itinFltrLstItm.currStpLst[j] === true) return false;
  }
  return true;
}

function isDepTmFltrEmptyFrLeg(itinFltrLstItm) {
  for (let j = 0; j < itinFltrLstItm.currDepTmLst.length; j++) {
    if (itinFltrLstItm.currDepTmLst[j] === true) return false;
  }
  return true;
}

export function getQuckFltrLst(filterInfo) {
  const arr = [];
  arr.push('Non-stop only');
  arr.push('Morning Departures');
  if (isNotNullAndEmpty(filterInfo.alFltrLst)) {
    filterInfo.alFltrLst.forEach((alFltr) => {
      arr.push(alFltr.nm);
    });
  }
  return arr;
}

export function isFilterDisabled(fltrMtDta) {
  if (fltrMtDta === undefined) return true;
  if (fltrMtDta.numFlt === 0) return true;
  return false;
}

export function getActiveFilters(sortType, sortOrder, currFltrObj) {
  let str = '';
  str += `sorter: ${sortType} , `;
  str += `order: ${sortOrder} | `;
  str += 'airlines: ';
  currFltrObj.alFltrLst.forEach((obj) => {
    if (obj.selected) str += `${obj.ac}_`;
  });
  str += ' | ';
  currFltrObj.itinFltrLst.forEach((itin, index) => {
    if (index === 0) str += 'onward- '; else str += 'return- ';
    str += 'stops: ';
    itin.currStpLst.forEach((stp, i) => {
      if (stp) str += `${i}_`;
    });
    str += ';dep: ';
    itin.currDepTmLst.forEach((dep, i) => {
      if (dep) str += `${FILTERS.departure.list[i].DepTime}_`;
    });
    str += ' | ';
  });
  return str;
}

function getOrigAirline(meta, alCdeMap) {
  if (meta.searchCriteria.originalAirline) {
    if (alCdeMap[meta.searchCriteria.originalAirline]) { return alCdeMap[meta.searchCriteria.originalAirline].nm; }
    return meta.searchCriteria.originalAirline;
  }
  return '';
}

function getJourneyType(response) {
  let str = '';
  if (response.itJrnyLst.length === 1) str = 'One way';
  if (response.itJrnyLst.length === 2) {
    str = 'Round Trip | ';
    if (response.meta.searchCriteria.itineraryList.length === 2) { str += 'Full'; } else { str += 'Part'; }
  }
  return str;
}
