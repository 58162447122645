import React from 'react';
import { isEmpty } from 'lodash';
import { View, Text } from 'react-native';
import styles from './SpecialClaimRaiseInfoCss';
import { getFont } from '../../../../PostSalesStyles';

const SpecialClaimRaiseInfo = React.memo(({ specialClaimData, cancellationButtonClick, type }) => {
  const getSpecialClaimMessage = () => {
    if (specialClaimData && !isEmpty(specialClaimData.message)) {
      const { message, clickableText } = specialClaimData;
      const splitMsg1 = message.split('<');
      const splitMsg2 = message.split('>');
      const result = [];
      result.push(splitMsg1[0]);
      result.push(clickableText);
      if (splitMsg2.length > 1) {
        result.push(splitMsg2[1]);
      }
      return result;
    }
    return undefined;
  };
  const { fsStyle, ...fonts } = getFont(true);
  const claimMessage = getSpecialClaimMessage();
  if (!claimMessage || (claimMessage && claimMessage.length < 2)) {
    return null;
  }

  if (type === 'partialPax') {
    return (
      <React.Fragment>
        <View>
          <Text
            style={[styles.raiseClaimPartial, fonts.boldFontFamily, fsStyle.font14]}
            onPress={cancellationButtonClick}
          >
            {claimMessage[1]}
          </Text>
        </View>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {claimMessage && (
        <View style={styles.raiseClaimTextContainer}>
          <Text style={[styles.raiseClaimText, fonts.regularFontFamily, fsStyle.font14]}>
            {claimMessage[0]}
            <Text
              style={[styles.raiseClaimClickableText, fonts.boldFontFamily, fsStyle.font14]}
              onPress={cancellationButtonClick}
            >
              {claimMessage[1]}
            </Text>
            {claimMessage.length === 3 ? claimMessage[2] : ''}
          </Text>
        </View>
      )}
    </React.Fragment>
  );
});

export default SpecialClaimRaiseInfo;
