import { StyleSheet } from "react-native";
export default (theme, fonts) => StyleSheet.create({
    newBoookingWrapper: {
        backgroundColor: theme.color.white,
        padding: 16,
        marginBottom: 10
    },
    seatsLeft: {
        paddingHorizontal: 6,
        paddingVertical: 3,
        backgroundColor: theme.color.pinkRed,
        borderRadius: 2,
        alignSelf: 'flex-start',
        marginBottom: 5
    },
    borderLine: {
        width: 12,
        height: 1,
        backgroundColor: theme.color.lightTextColor
    },
    ratingBg: {
        borderRadius: 4,
        paddingVertical: 3,
        paddingHorizontal: 5,
        marginRight: 4
    },
    ratingText: {
        color: theme.color.lightSeaGreenTextColor
    }
})