import React, { useEffect, useState } from 'react';
import {View, Easing, Animated, TouchableOpacity} from 'react-native';
import PropTypes from 'prop-types';
import getStyles from './OverlayCss';
import HotelODCNotAllowedOverlayContent from './hotelODCNotAllowedOverlayContent';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const  HotelODCNotAllowedOverlay = ({title, message, handleCloseOverlay}) => {
  const { ...fonts } = getFont(true);
  const styles = getStyles(fonts);

  const [toastPostion, setToastPostion] = useState(new Animated.Value(-400));
  const startAnimate = (bottom) => {
    Animated.timing(toastPostion, {
      toValue: bottom,
      easing: Easing.easeInOut
    }).start();
  }

  useEffect(() => {
    const bottom = 30
    startAnimate(bottom);
  }, []);

  return (
    <View style={styles.overlayWrapper}>
      <TouchableOpacity onPress={() => handleCloseOverlay()} activeOpacity={.9} style={styles.overlay}></TouchableOpacity>
      <Animated.View style={[styles.overlayContent, {bottom: toastPostion}]}>
        <HotelODCNotAllowedOverlayContent
          title={title}
          message={message}
          handleCloseOverlay={handleCloseOverlay}
        />
      </Animated.View>
    </View>

  );
}

HotelODCNotAllowedOverlay.propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    handleCloseOverlay: PropTypes.object.isRequired
}

export default HotelODCNotAllowedOverlay;