import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Image, Platform } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {openGoogleMap, openGoogleMapAlways} from '../../Common/commonUtil';
import { DOWN_ARROW_LG, DOWN_ARROW_SM, LOCATION , LOC } from '../CabImageConstants';
import styles from './MoreInfoForOnSpotCss';

const MoreInfoForOnSportCab = ({carOnSpotBoardingInfo, closePopup, response}) => {
  const showFirstStep = () => (
    <React.Fragment>
      <View style={styles.titleWrapper}>
        <Image style={styles.locIconStyle} source={LOC} />
        {
          response && response.carSupportData && response.carSupportData.onSpot && response.carSupportData.onSpot.onSpotCardHeader && 
          <Text style={[AtomicCss.blackFont, AtomicCss.blackText, AtomicCss.font11]}>{response.carSupportData.onSpot.onSpotCardHeader}</Text>
        }
      </View>
      <View style={[AtomicCss.paddingLeft25, AtomicCss.marginBottom15]}>
        <View style={[AtomicCss.flexRow]}>
          {
            response && response.carSupportData && response.carSupportData.onSpot && response.carSupportData.onSpot.stepOne && 
            <Text style={[AtomicCss.regularFont, AtomicCss.defaultText, AtomicCss.font10, AtomicCss.marginRight10]}>{response.carSupportData.onSpot.stepOne}</Text>
          }
          {
            carOnSpotBoardingInfo.mapWay && 
            <Text style={[AtomicCss.boldFont, AtomicCss.blackText, AtomicCss.font10]}>{carOnSpotBoardingInfo.mapWay}</Text>
          }
          
        </View>
        <View style={styles.innerDetails}>
          {
            (carOnSpotBoardingInfo.kioskImage) ? 
            ( <View style={styles.arrowWrapper}>
              <Image style={styles.arrowLgStyle} source={DOWN_ARROW_LG} />
            </View>) : 
            ( <View style={styles.arrowWrapper}>
              <Image style={styles.arrowXsStyle} source={DOWN_ARROW_SM} />
            </View>) 
           
          }
          <View style={styles.rightDetails}>
            <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween, AtomicCss.marginBottom8]}>
              {
                carOnSpotBoardingInfo.landmark && 
                <Text style={[AtomicCss.font12, AtomicCss.regularFont, AtomicCss.defaultText, styles.locationInfo]}>{carOnSpotBoardingInfo.landmark}</Text>
              }
              <TouchableOpacity style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.alignItemsStart]} 
              onPress={ openMap }>
                <Image style={styles.locationImgStyle} source={LOCATION} />
                {
                  response && response.carSupportData && response.carSupportData.onSpot && response.carSupportData.onSpot.direction && 
                  <Text style={styles.locationTxt}>{response.carSupportData.onSpot.direction}</Text>
                }
              </TouchableOpacity>
            </View>
            {
              carOnSpotBoardingInfo.kioskImage && 
              <Image style={styles.airportImgStyle} source={{uri: carOnSpotBoardingInfo.kioskImage}} />
            }
          </View>
        </View>
      </View>
    </React.Fragment>
  );

  const openMap = () => {
    if(Platform.OS === 'web'){
      openGoogleMapAlways(carOnSpotBoardingInfo.latitude,carOnSpotBoardingInfo.longitude)
    }else{
      openGoogleMap(carOnSpotBoardingInfo.latitude,carOnSpotBoardingInfo.longitude)
    }
  }

  const showSecondStep = () =>(
  <View style={[AtomicCss.paddingLeft25]}>
        <View style={[AtomicCss.flexRow]}>
          {
            response && response.carSupportData && response.carSupportData.onSpot && response.carSupportData.onSpot.stepTwo && 
            <Text style={[AtomicCss.regularFont, AtomicCss.defaultText, AtomicCss.font10, AtomicCss.marginRight10]}>{response.carSupportData.onSpot.stepTwo}</Text>
          }
          {
            response && response.carSupportData && response.carSupportData.onSpot && response.carSupportData.onSpot.stepTwoText && 
            <Text style={[AtomicCss.boldFont, AtomicCss.blackText, AtomicCss.font10]}>{response.carSupportData.onSpot.stepTwoText}</Text>
          } 
        </View>
        <View style={styles.innerDetails}>
          <View style={styles.arrowWrapper}>
            <Image style={styles.arrowSmStyle} source={DOWN_ARROW_SM} />
          </View>
          <View style={[styles.rightDetails, styles.stepTwoDetails]}>
            <TouchableOpacity onPress={ closePopup }>
            <Image style={styles.barCodeStyle} source={{uri: carOnSpotBoardingInfo.qrCodeLink}} />
            </TouchableOpacity>
            <Text style={[AtomicCss.blackFont, AtomicCss.blackText, AtomicCss.font11, AtomicCss.marginHz20]}>OR</Text>
            <View style={styles.barCodeTextWrapper}>
              {
                carOnSpotBoardingInfo.qrCode &&
                <Text style={[AtomicCss.boldFont, AtomicCss.blackText, AtomicCss.font14]}>{carOnSpotBoardingInfo.qrCode}</Text>
              }
            </View>
          </View>
        </View>
  </View>
  );
  
const showThirdStep = () => (
<View style={[AtomicCss.paddingLeft25,AtomicCss.flexRow]}>
  {
    response && response.carSupportData && response.carSupportData.onSpot && response.carSupportData.onSpot.stepThree &&
    <Text style={[AtomicCss.regularFont, AtomicCss.defaultText, AtomicCss.font10, AtomicCss.marginRight10]}>{response.carSupportData.onSpot.stepThree}</Text>
  }
  {
    response && response.carSupportData && response.carSupportData.onSpot && response.carSupportData.onSpot.stepThreeText &&
    <Text style={[AtomicCss.boldFont, AtomicCss.blackText, AtomicCss.font10]}>{response.carSupportData.onSpot.stepThreeText}</Text>
  }
</View>
);

  return (
    <View style={[styles.getAirportCabCard, AtomicCss.marginBottom10]}>
      { showFirstStep() }
      {showSecondStep() }
      { showThirdStep() } 
    </View>
  );
}

export default MoreInfoForOnSportCab;