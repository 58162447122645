import React from 'react';
import { View, Text, Image, StyleSheet } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';
import { MY_ICON } from '../../../../FlightImageConstants';
import { getFont } from '../../../../../PostSalesStyles';

const CardCTA = ({ ctaInfo }) => {
  const fonts = getFont(true);
  return (
    <View style={[AtomicCss.marginBottom10, styles.cardBorder]}>
      <View style={[styles.infoCardWrapper]}>
        <Image style={styles.myIconStyle} source={MY_ICON} />
        <View style={styles.infoDtls}>
          <Text style={[AtomicCss.defaultText, fonts.boldFontFamily]}>{ctaInfo}</Text>
        </View>
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  cardBorder: {
    borderTopColor: '#ccc',
    borderTopWidth: 0.5,
  },
  infoCardWrapper: {
    backgroundColor: '#fff',
    ...getPlatformElevation(1),
    marginBottom: 1,
    paddingHorizontal: 16,
    paddingVertical: 16,
    flexDirection: 'row',
  },
  infoDtls: {
    flex: 1,
  },

  myIconStyle: {
    width: 24,
    height: 31,
    marginRight: 15,
  },
});

export default CardCTA;
