import React from "react";
import { StyleSheet, Text, Image } from "react-native";
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import iconClock from '@mmt/legacy-assets/src/iconClock.webp';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import DaysTimeCountDown from "../DaysTimeCountDown";
import LinearGradient from "react-native-linear-gradient";

const PendingPaymentTag = ({ tagTextStart, tagTextEnd, paymentChargeDate }) => (
  <LinearGradient
    start={{ x: 1.0, y: 0.0 }}
    end={{ x: 0.0, y: 1.0 }}
    colors={[colors.yellow4, colors.yellow2]}
    style={[AtomicCss.marginTop8, AtomicCss.paddingAll10, AtomicCss.alignSelfStart, AtomicCss.borderRadius15, AtomicCss.spaceBetween,
    ]}
  >
    <Text style={[AtomicCss.boldFont, AtomicCss.font10, AtomicCss.whiteText, AtomicCss.spaceBetween, AtomicCss.flex1]}>
      <Image source={iconClock} style={styles.iconStyle} />
      <Text style={[AtomicCss.alignCenter, AtomicCss.makeRelative, { top: "-4px"}]}>
        <Text style={[AtomicCss.boldFont, AtomicCss.font10, AtomicCss.whiteText]}> {tagTextStart} {" "}</Text>
        <DaysTimeCountDown initialDate={paymentChargeDate} />
        <Text style={[AtomicCss.boldFont, AtomicCss.font10, AtomicCss.whiteText]}> {tagTextEnd}</Text>
      </Text>
    </Text>
  </LinearGradient>
);

const styles = StyleSheet.create({
  iconStyle: {
    width: 15,
    height: 15,
    marginRight: 5,
  }
});

export default PendingPaymentTag;