import React from 'react';
import PropTypes from 'prop-types';
import {View, Text, StyleSheet} from 'react-native';
import {fonts, colors} from '../../../Styles/globalStyles';

// Please don't modify default styles, pass your own if you need a
// different style but ideally it should be consistent
export default function Label(props) {
  const {label, labelStyle, containerStyle} = props;
  return (
    <View style={containerStyle}>
      <Text style={labelStyle}> {label} </Text>
    </View>
  );
}

const styles = ({
  labelStyle: {
    fontSize: 14,
    color: colors.black,
    fontFamily: fonts.bold
  },
  containerStyle: {
    marginBottom: 10,
    marginLeft: -3
  }
});

Label.propTypes = {
  label: PropTypes.string.isRequired,
  labelStyle: PropTypes.object,
  containerStyle: PropTypes.object
};

Label.defaultProps = {
  labelStyle: styles.labelStyle,
  containerStyle: styles.containerStyle
};
