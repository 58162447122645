import React, { useEffect, useState, useImperativeHandle , useRef,  FC, forwardRef } from 'react';
import {
  SectionList,
  Text,
  View,
  TouchableOpacity,
  Image,
} from 'react-native';
import BookingList from '../BookingList';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import NoTopBookings from '../../pages/Wheretogopage/components/NoTopBookings';
import MSRTripsCard from '../cards/MSRTripsCard';
import { getBookingCount } from '../../utils';
import ListingCardShimmer from '../ListingCardShimmer';
import { getStaticData } from 'apps/post-sales/src/staticData/staticData';
import { getTranslatedTextForNew } from 'apps/post-sales/src/utils/PostSaleUtil';
import { OmnitureConstants, PAGE_BOOKINGS_STATUS } from '../../summaryConstants';
import { BLUE_DOWN_ARROW } from 'apps/post-sales/src/flights/FlightImageConstants';
import {SHOW_MORE_SHIMMER_COUNT} from '../../summaryConstants';
import createStyles from "./style";
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { BookingIdToTicketDetailMapType, SelectedFilter, SummaryStateArrayType, MyRequestsCardDataInterface, summaryStateObjectType } from '../../types';
import TripSummaryOmnitureTracker from '../../TripSummaryOmnitureTracker';

interface BookingSummarySectionListProps {
  summaryStateArray: SummaryStateArrayType[];
  summaryState: summaryStateObjectType;
  bookingIdtoTicketDetailmap: BookingIdToTicketDetailMapType;
  myRequestsCardData: MyRequestsCardDataInterface; 
  loadShowMoreData: (bookingStatus: string, page?: number) => void; 
  showLoadMoreLoader: boolean;
  selectedFilters: SelectedFilter[];
}

const BookingSummarySectionList: FC<BookingSummarySectionListProps> = forwardRef((props, ref) => {
  const {
    summaryStateArray: summaryArray,
    summaryState,
    bookingIdtoTicketDetailmap,
    myRequestsCardData,
    loadShowMoreData,
    showLoadMoreLoader,
    selectedFilters,
    scrollToTop,
  } = props || {};

  const { showMoreText } = getStaticData(true);
  const [summaryStateArray, setSummaryArray] = useState<SummaryStateArrayType[]>(summaryArray); 
  const [showMoreBookingStatus, setShowMoreBookingStatus] = useState('');
  const sectionListRef = useRef(null);
  const { psTheme: theme } = useTheme();
  const fonts = getFont();
  const styles = createStyles(theme, fonts);

  useImperativeHandle(ref, () => ({
    scrollToTop: () => {
      if (sectionListRef.current) {
        sectionListRef.current.scrollToLocation({ sectionIndex: 0, itemIndex: 0, viewOffset: 0 });
      }
    },
  }));



  useEffect(() => {
    setSummaryArray(summaryArray);
  }, [summaryArray]);

  const handleSectionToggle = (index: number) => {
    let newArray = [...summaryStateArray];
    newArray[index].isExpanded = !newArray[index].isExpanded;
    setSummaryArray(newArray);
  };

  const getNoTripsCarousal = () => {
    return <NoTopBookings/>;
  };

  const handleShowMoreBookings = (bookingStatus: string) => {
    TripSummaryOmnitureTracker.trackNormalClickEvent(OmnitureConstants.SHOW_MORE_TEXT, OmnitureConstants.SHOW_MORE_BOOKINGS);
    setShowMoreBookingStatus(bookingStatus);
    loadShowMoreData(bookingStatus);
  };

  const renderListFooter = () => {
    if (showLoadMoreLoader) {
      return <ListingCardShimmer count={SHOW_MORE_SHIMMER_COUNT} />;
    }
    return null;
  };

  return (
    <View style={[AtomicCss.flex1, AtomicCss.marginLeft4, AtomicCss.marginRight4]}>
      <SectionList
        ref={sectionListRef}
        contentContainerStyle={{ paddingBottom: 50 }}
        sections={summaryStateArray}
        keyExtractor={(item, index) => item.title + index.toString()} 
        renderItem={({ item, index, section }) => {
          const { title } = section || {};
          const { currentPageResponse = [], hasNextPage, statusWiseMessage } =
            summaryState && summaryState[title] || {};

          return (
            <>
              {section.isExpanded && (
                <BookingList
                  key={index}
                  bookings={[item]}
                  summaryResponse={currentPageResponse}
                  statusWiseMessage={statusWiseMessage}
                  bookingIdtoTicketDetailmap={bookingIdtoTicketDetailmap}
                  hasNextPage={hasNextPage}
                />
              )}
            </>
          );
        }}
        renderSectionHeader={({ section: { title, index } }) => {
          if (summaryState &&  summaryState[title] && summaryState[title]?.bookings?.length > 0) {
            return (
              <TouchableOpacity style={[AtomicCss.marginRight10]} onPress={() => handleSectionToggle(index)} key={index}>
                <View style={[styles.headingSection, AtomicCss.marginTop15]}>
                  <Text
                    style={[
                      styles.headingText,
                      AtomicCss.flex1,
                      AtomicCss.marginLeft10,
                      AtomicCss.marginRight10,
                    ]}
                  >
                    {getTranslatedTextForNew(title)}
                  </Text>
                  <Image
                    style={[
                      { width: 16, height: 9 },
                      summaryStateArray.length &&
                        summaryStateArray[index] &&
                        summaryStateArray[index].isExpanded
                        ? styles.activeStyle
                        : {},
                    ]}
                    source={BLUE_DOWN_ARROW}
                  />
                </View>
              </TouchableOpacity>
            );
          }
        }}
        renderSectionFooter={({ section }) => (
          <View style={AtomicCss.flex1}>
            {!showLoadMoreLoader &&  summaryState && summaryState[section.title] && summaryState[section.title]?.bookings?.length > 0 && summaryState[section.title]?.hasNextPage && section.isExpanded ? (
              <TouchableOpacity
                style={[AtomicCss.flexRow, styles.showMoreLink]}
                onPress={() => handleShowMoreBookings(section.title)}
              >
                <Text style={styles.showMoreText}>
                  {showMoreText + ' '}
                </Text>
                <Image
                  style={[styles.blueArrowSmStyle, styles.activeArrow]}
                  source={BLUE_DOWN_ARROW}
                />
              </TouchableOpacity>
            ) : null}
            {showLoadMoreLoader && showMoreBookingStatus === section.title ? renderListFooter() : null}
          </View>
        )}
        ListHeaderComponent={
          <>
            {myRequestsCardData && getBookingCount(summaryState) !== 0 && (
              <View style={[AtomicCss.marginTop10, AtomicCss.marginBottom6]}>
                <MSRTripsCard myRequestsCardData={myRequestsCardData} />
              </View>
            )}
            {getBookingCount(summaryState, [PAGE_BOOKINGS_STATUS.UPCOMING, PAGE_BOOKINGS_STATUS.ONTRIP]) == 0  && !selectedFilters && getNoTripsCarousal()}
          </>
        }
        stickySectionHeadersEnabled={false}
        bounces={false}
      />
    </View>
  );
});



export default BookingSummarySectionList;