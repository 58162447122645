import React from 'react';
import PropTypes from 'prop-types';
import {View, Text, Image} from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getPlatformElevation from './getPlatformElevation';
import BasePage from '../../Common/PostSalesBasePage';
import {VisaIcon} from '../HolidayBookingConstants';

class VisaCard extends BasePage {
  static navigationOptions = {
    header: null
  };

  render() {
    const {visaCard} = this.props;
    return (
      <View style={[AtomicCss.marginBottom10, styles.cardBorder]}>
        <View style={[styles.infoCardWrapper]}>
          {visaCard && visaCard.heading1 &&
          <View style={styles.headingIconWrapper}>
            <Image style={styles.headingIconStyle} source={VisaIcon}/>
          </View>
          }
          <View style={styles.infoCardContent}>
            {visaCard && visaCard.heading1 &&
            <Text
              style={[AtomicCss.defaultText, AtomicCss.blackFont,
                AtomicCss.font14, AtomicCss.marginBottom15]}
            >{visaCard.heading1}
            </Text>
            }
            {visaCard && visaCard.content &&
            <Text
              style={[AtomicCss.regularFont, AtomicCss.font14,
                AtomicCss.defaultText, AtomicCss.lineHeight20]}
            >{visaCard.content}
            </Text>
            }
          </View>
        </View>
      </View>
    );
  }
}

const styles = {
  cardBorder: {
    borderTopColor: '#e4e4e4',
    borderBottomColor: '#e4e4e4',
    borderTopWidth: 1,
    borderBottomWidth: 1
  },
  infoCardWrapper: {
    backgroundColor: '#fff',
    justifyContent: 'space-between',
    flexDirection: 'row',
    ...getPlatformElevation(2),
    marginBottom: 1
  },
  infoCardContent: {
    paddingRight: 8,
    paddingVertical: 15,
    flex: 1
  },
  headingIconWrapper: {
    width: 46,
    alignItems: 'center'
  },
  headingIconStyle: {
    width: 16,
    height: 18,
    top: 15
  },
  redirectBtnWrapper: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 50,
    paddingRight: 35,
    justifyContent: 'space-between',
    flexDirection: 'row',
    backgroundColor: '#fff',
    borderBottomWidth: 1,
    borderBottomColor: '#ededed',
    alignItems: 'center'
  },
  rightArrowIcon: {
    width: 9,
    height: 13
  },
  buttonWrapper: {
    paddingTop: 20,
    width: '90%'
  }
};

VisaCard.propTypes = {
  visaCard: PropTypes.object.isRequired
};


export default VisaCard;
