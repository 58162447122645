import {StyleSheet } from "react-native";
export default (theme, fonts) =>StyleSheet.create({ 
    destCard:{
        borderBottomColor:theme.color.gray1,
        borderBottomWidth:1,
        marginBottom:8,
        paddingBottom:10
    },
    busIcon:{
        height:28,
        width:28,
        marginRight:20
    }
})