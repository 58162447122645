import { trackOmniture } from '@mmt/legacy-commons/Common/utils/OmnitureTrackerUtils';
export const BOOKING_SUMMARY_PAGE_NAME = 'mob:customer support:all:mytrips';
class addBookingTrackingHelper {
  trackLoadEvent(eventKey, uuid, bookingId = null, pageName = BOOKING_SUMMARY_PAGE_NAME) {
    const params = {
      pageName,
      m_c54: uuid,
      m_c1: eventKey,
    };

    if (bookingId) {
      params['m_v16'] = bookingId;
    }
    trackOmniture(pageName, params);
  }
  trackClickEvent(actionName, uuid, pageName = BOOKING_SUMMARY_PAGE_NAME) {
    const params = {
      pageName,
      m_c54: uuid,
      m_c1: actionName,
    };
    trackOmniture(pageName, params);
  }
}
export default new addBookingTrackingHelper();

export const ADD_BOOKING_TRACKING_EVENTS = {
  Mytrips_topbar_addbooking_clicked: 'Mytrips_topbar_addbooking_clicked',
  mytrips_addbooking_sheet_opened: 'mytrips_addbooking_sheet_opened',
  Addbooking_sheet_info_clicked: 'Addbooking_sheet_info_clicked',
  Addbooking_sheet_searchbox_clicked: 'Addbooking_sheet_searchbox_clicked',
  Addbooking_sheet_sendotp_clicked: 'Addbooking_sheet_sendotp_clicked',
  Addbooking_sheet_verifyotp_clicked: 'Addbooking_sheet_verifyotp_clicked ',
  Addbooking_detailspage_landed: 'Addbooking_detailspage_landed',
};
