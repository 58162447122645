import React, { memo, useEffect } from 'react';
import { SERVER_ERROR_ICON } from '../../../FlightImageConstants';
import ViewStateHandler from '../../../../Common/ViewStateHandler';
import { isNullOrEmptyObject } from '../../../../Common/commonUtil';
import SpecialClaimTrackingHelper from '../../Helpers/SpecialClaimTrackingHelper';

const AncillaryClaimProcess = ({ loading, loaderMsg, children, error, onRetry, pageName }) => {
  useEffect(() => {
    if (!isNullOrEmptyObject(error)) {
      SpecialClaimTrackingHelper.trackAncillaryClaimErrorEvent(pageName);
    }
  }, [error, pageName]);

  return (
    <ViewStateHandler
      {...{
        loading,
        loaderMsg,
        children,
        error,
        onRetry,
        errImg: SERVER_ERROR_ICON,
        showErrImage: true,
      }}
    />
  );
};

export default memo(AncillaryClaimProcess);
