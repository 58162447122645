import React from "react";
import {appendUrlPrefix} from "../../webUtils";
import loadable from "@loadable/component";
import ProgressView from "../../components/ProgressView";

const CAB_PATHS = [

    {
        pathName: appendUrlPrefix('/cabTnC'),
        pageKey: "cabTermsAndConditions",
        Component: loadable(() => import("@rn/apps/post-sales/src/cab/components/CabTermsAndConditions"), { fallback: <ProgressView /> })
    },
    {
        pathName: appendUrlPrefix('/cabCancellation'),
        pageKey: "cabCancellationDetails",
        Component: loadable(() => import("@rn/apps/post-sales/src/cab/components/CabCancellationDetails"), { fallback: <ProgressView /> })
    },
    {
        pathName: appendUrlPrefix('/cabPaymentDetail'),
        pageKey: "cabPaymentBreakup",
        Component: loadable(() => import("@rn/apps/post-sales/src/cab/components/CabPaymentDetails"), { fallback: <ProgressView /> })
    },
    {
        pathName: appendUrlPrefix('/cabModify'),
        pageKey: "cabModifyBooking",
        Component: loadable(() => import("@rn/apps/post-sales/src/cab/components/cancelAndRebook/CabModifyBooking"), { fallback: <ProgressView /> })
    },
    {
        pathName: appendUrlPrefix('/carCancellationPoilcy'),
        pageKey: "carCancellationPoilcyRule",
        Component: loadable(() => import("@rn/apps/post-sales/src/cab/components/CarCancellationPolicyRules"), { fallback: <ProgressView /> })
    },
    {
        pathName: appendUrlPrefix('/cabInvoice'),
        pageKey: "cabInvoiceDetails",
        Component: loadable(() => import("@rn/apps/post-sales/src/cab/components/CabInvoiceDetails"), { fallback: <ProgressView /> })
    },
    {
        pathName: appendUrlPrefix('/cabChangePickupDetails'),
        pageKey: "cabChangePickup",
        Component: loadable(() => import("@rn/apps/post-sales/src/cab/components/CabAmendments/CabChangePickupDetails"), { fallback: <ProgressView /> })
    },
    {
        pathName: appendUrlPrefix('/cabCancellationDetails'),
        pageKey: "cabCancellationDetailsStandAlone",
        Component: loadable(() => import("@rn/apps/post-sales/src/cab/components/CabCancellationDetailsStandAlone"), { fallback: <ProgressView /> })
    },
    {
        pathName: appendUrlPrefix('/carSupportIssue'),
        pageKey: "carSupportIssueSelect",
        Component: loadable(() => import("@rn/apps/post-sales/src/cab/components/Support/IssueSelection"), { fallback: <ProgressView /> })
    },
    {
        pathName: appendUrlPrefix('/cabDriverDetail'),
        pageKey: "cabDriverDetails",
        Component: loadable(() => import("@rn/apps/post-sales/src/cab/components/CabDriverDetails"), { fallback: <ProgressView /> })
    },
    {
        pathName: appendUrlPrefix('/carSupportSubmitIssue'),
        pageKey: "carSupportIssueSubmit",
        Component: loadable(() => import("@rn/apps/post-sales/src/cab/components/Support/IssueSubmit"), { fallback: <ProgressView /> })
    },
    {
        pathName: appendUrlPrefix('/CancellationRefundOptions'),
        pageKey: "CancellationRefundMode",
        Component: loadable(() => import("@rn/apps/post-sales/src/cab/components/CancellationRefundMode"), { fallback: <ProgressView /> })
    },
    {
        pathName: appendUrlPrefix('/CabCancel'),
        pageKey: "CabCancelRequest",
        Component: loadable(() => import("@rn/apps/post-sales/src/cab/components/CabCancelRequest"), { fallback: <ProgressView /> })
    },
    {
        pathName: appendUrlPrefix('/carSupportIssueDetails'),
        pageKey: "carSupportIssueDetail",
        Component: loadable(() => import("@rn/apps/post-sales/src/cab/components/Support/IssueDetail"), { fallback: <ProgressView /> })
    },
    {
        pathName: appendUrlPrefix('/cabDetailsReview'),
        pageKey: "cabDetailReview",
        Component: loadable(() => import("@rn/apps/post-sales/src/cab/components/cancelAndRebook/CabDetailReview"), { fallback: <ProgressView /> })
    },
    {
        pathName: appendUrlPrefix('/cabAmendmentReview'),
        pageKey: "cabReviewAmendment",
        Component: loadable(() => import("@rn/apps/post-sales/src/cab/components/CabAmendments/CabReviewAmendment"), { fallback: <ProgressView /> })
    },
    {
        pathName: appendUrlPrefix('/cabAmendmentCommit'),
        pageKey: "cabCommitAmendment",
        Component: loadable(() => import("@rn/apps/post-sales/src/cab/components/CabAmendments/CabCommitAmendment"), { fallback: <ProgressView /> })
    },
    {
        pathName: appendUrlPrefix('/searchLocations'),
        pageKey: "searchLocation",
        Component: loadable(() => import("@rn/apps/post-sales/src/cab/components/cancelAndRebook/SearchLocation"), { fallback: <ProgressView /> })
    },
    {
        pathName: appendUrlPrefix('/changeDateTime'),
        pageKey: "changeDate",
        Component: loadable(() => import("@rn/apps/post-sales/src/cab/components/cancelAndRebook/ChangeDate"), { fallback: <ProgressView /> })
    },
    {
        pathName: appendUrlPrefix('/changeCab'),
        pageKey: "cabUpgrade",
        Component: loadable(() => import("@rn/apps/post-sales/src/cab/components/cancelAndRebook/CabUpgrade"), { fallback: <ProgressView /> })
    },
    {
        pathName: appendUrlPrefix('/cabCancelRebookDetails'),
        pageKey: "cabCancelRebookPriceBreakup",
        Component: loadable(() => import("@rn/apps/post-sales/src/cab/components/cancelAndRebook/CanCancelRebookPriceBreakup"), { fallback: <ProgressView /> })
    },
    {
        pathName: appendUrlPrefix('/cabDateChangeDetail'),
        pageKey: "cabDateChangeDetails",
        Component: loadable(() => import("@rn/apps/post-sales/src/cab/components/CabDateChangeDetails"), { fallback: <ProgressView /> })
    },
    {
        pathName: appendUrlPrefix('/cabAmendScreen'),
        pageKey: "cabAmendFullScreen",
        Component: loadable(() => import("@rn/apps/post-sales/src/cab/components/CabAmendments/CabAmendFullScreenPage"), { fallback: <ProgressView /> })
    },
    {
        pathName: appendUrlPrefix('/cabBooking'),
        pageKey: "cabBookingDetail",
        Component: loadable(() => import("@rn/apps/post-sales/src/cab/CabBookingDetailWrapper"), { fallback: <ProgressView /> })
    }

]


export default CAB_PATHS;
