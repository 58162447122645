import React, { useEffect, useCallback } from 'react';
import { Platform, DeviceEventEmitter, Alert } from 'react-native';
import SpecialClaim from '@core_app_flight/SpecialClaim';
import SpecialClaimAddAttachmentModule from '@mmt/legacy-commons/Native/SpecialClaimAddAttachmentModule';
import { resetToFlightBookingDetail } from '../../FlightBookingUtil';
import { getImageByteCharacters } from '../../../utils/PostSaleUtil';
import { TrackingPageNames } from './const';
import SpecialClaimTracking from './utils/trackUtils';

const SpecialClaimV2 = (props: {
  bookingID: string,
  actionId: string,
  bookingDetailsResponse: any
}) => {
  const { bookingID, actionId, bookingDetailsResponse } = props;
  const { onLoad, onClick, onError } = SpecialClaimTracking();

  const handleAddAttachment = () => {
    const eventKey = 'special_claim_review_page';
    DeviceEventEmitter.addListener(eventKey, async (attachment) => {
      DeviceEventEmitter.removeAllListeners(eventKey);
      if (Platform.OS !== 'ios') {
        attachment.data = await getImageByteCharacters(attachment.filePath);
      }
      DeviceEventEmitter.emit('flight_special_claim_file_upload', attachment);
    });
    SpecialClaimAddAttachmentModule.onButtonClicked();
  };
  const onBackToBooking = (bookingId, reset = false) => {
    resetToFlightBookingDetail(bookingId, reset);
  };


  const trackOminuture = (event: 'load' | 'click' | 'error', trackData: Record<string, any>) => {
    if (event === 'load') {
      const { pageName, scDetection } = trackData;
      onLoad({
        pageName: pageName ? TrackingPageNames[pageName] : TrackingPageNames.PaxSelection,
        bookingDetailsResponse: bookingDetailsResponse,
        reason: actionId,
        scDetection,
      });
    } else if (event === 'click') {
      const { eventName } = trackData;
      onClick(eventName, '');
    } else if (event === 'error') {
      const { error } = trackData;
      onError(error);
    }
  };

  return (
    <SpecialClaim
      bookingId={bookingID}
      reason={actionId}
      trackOminuture={trackOminuture}
      onBackToBooking={onBackToBooking}
      onAttachFile={handleAddAttachment}
    />
  );
};

export default SpecialClaimV2;
