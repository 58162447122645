import { StyleSheet } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { DEFAULT_THEME } from '@mmt/post-sales/src/theme/default.theme';

const createStyles = <Theme extends typeof DEFAULT_THEME>(theme: Theme, fonts: any) => StyleSheet.create({
  tripViewWrapper: {
    ...AtomicCss.whiteCardNew,
    ...AtomicCss.marginHz12,
    ...AtomicCss.borderRadius16
  },
});

export default createStyles;