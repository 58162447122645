import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';

const styles = {
  cfarImage: {
    height: 27,
    width: 150,
    marginBottom: 10
  },
  cfarImageGCC: {
    height: 27,
    width: 150,
    resizeMode:'contain'
  },
  infoCardWrapper: {
    backgroundColor: '#fff',
    justifyContent: 'space-between',
    flexDirection: 'row',
    ...getPlatformElevation(2),
    marginBottom: 1,
  },

  infoCardContent: {
    paddingHorizontal: 8,
    paddingVertical: 15,
    flex: 1,
  },

  infoCustom: {
    color: '#4a4a4a',
    lineHeight: 18,
  },
  headingIconWrapper: {
    width: 46,
    alignItems: 'flex-start',
    top: 14,
    left: 16,
  },
  headingIconStyle: {
    width: 24,
    height: 19,
  },

  redirectBtnWrapper: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 60,
    paddingRight: 35,
    justifyContent: 'space-between',
    flexDirection: 'row',
  },

  rightArrowIcon: {
    width: 9,
    height: 13,
  },

  linkBtnTxt: {
    color: colors.azure,
  },
  buttonWrapper: {
    paddingTop: 20,
    width: '90%',
  },
  ZCIconStyle: {
    width: 18,
    height: 24,
  },
  DBIconStyle: {
    width: 24,
    height: 24,
  },
  greenTxt: {
    color: colors.green,
    lineHeight: 18,
  },
  redirectBtnSection: {
    backgroundColor: '#fff',
    borderBottomWidth: 1,
    borderBottomColor: '#ededed',
  },
  linkTagWrapper: {
    backgroundColor: '#dde8ff',
    borderRadius: 20,
    paddingHorizontal: 10,
    paddingVertical: 6,
    marginLeft: 60,
    marginBottom: 10,
    maxWidth: 170,
    top: -10,
  },

  linkTagtxt: {
    color: colors.lightPurple,
  },
  linkinfoTxt: {
    color: colors.lightTextColor,
    marginTop: 3,
  },
  disableLinkItemTxt: {
    color: colors.lightTextColor,
  },
  errorStyle: {
    color: colors.red6,
    fontSize: 14,
    marginBottom: 8,
  },
  customerSupportWrapper: {
    backgroundColor: colors.white,
    justifyContent: 'space-around',
    flexDirection: 'row',
    paddingVertical: 15,
    borderBottomWidth: 1,
    borderBottomColor: colors.dividerGrey,
  },
  btnWrapper: {
    alignItems: 'center',
  },
  btnStyle: {
    width: 36,
    height: 36,
    marginBottom: 5,
  },
  btnTxtStyle: {
    color: colors.azure,
    fontSize: 14,
  },
  raiseClaimTextContainer: {
    flexDirection: 'row',
    backgroundColor: '#ffefee',
    padding: 10,
    marginTop: 16,
    marginRight: 16,
  },
  raiseClaimIcon: {
    width: 22,
    height: 19,
    top: 2,
  },
  raiseClaimText: {
    color: colors.defaultTextColor,
    fontSize: 12,
    lineHeight: 17,
    flex: 1,
    marginLeft: 10,
  },
  raiseClaimClickableText: {
    color: colors.azure,
    fontSize: 12,
    lineHeight: 17,
  },
  actionBtnSubText: {
    color: colors.azure,
    lineHeight: 18,
    marginTop: 4,
  },
  cancSpecialSection: {
    backgroundColor: '#fff',
    ...getPlatformElevation(1),
  },
  dividerSection: {
    borderTopWidth: 1,
    borderTopColor: '#e7e7e7',
    justifyContent: 'space-between',
    flexDirection: 'row',
    borderTopStyle: 'dashed',
  },
  semiCircleStyle: {
    width: 16,
    height: 16,
    backgroundColor: '#f2f2f2',
    borderRadius: 16,
    borderWidth: 2,
    borderColor: '#e7e7e7',
  },
  leftSemiCircle: {
    left: -8,
    top: -8,
  },
  rightSemiCircle: {
    right: -8,
    top: -8,
  },
  headingfltIconStyle: {
    width: 22,
    height: 19,
  },
  canInfoTxt: {
    color: '#b10067',
  },
  raiseRqstBtn: {
    paddingTop: 10,
  },
  cfarInfoIcon: {
    width: 27,
    height: 27,
  },
};

export default styles;
