import { LANGUAGE_MAP } from '@mmt/legacy-commons/Common/constants/AppConstants';
import IndigoTheme from './IndigoTheme';
import GITheme from './GITheme';
import PostSalesEventLogger from './PostSalesEventLogger'
import BusEventsLogger from './bus/BusEventsLogger';
import CabEventsLogger from './cab/CabEventsLogger';
import RailBookingEventsLogger from './Rails/details/RailBookingEventsLogger';
import FlightEventsLogger from './flights/modules/details/helper/FlightEventsLogger';
import HotelEventsLogger from './hotel/HotelEventsLogger';
import VisaBookingEventsLogger from './Visa/VisaBookingEventsLogger';
import TGSBookingEventsLogger from './TripGuarantee/Utils/TGSBookingEventsLogger';
import GiftCardEventLogger from './giftcard/GiftCardEventLogger';
import HolidayBookingEventsLogger from './Holiday/HolidayBookingEventsLogger';

export const USERID = 'WEBLIVE';
export const CHANNEL = 'Native';
export const IDCONTEXT = 'MOB';
export const DOMESTICLOBCODE = 'LOB02730,LOB02530,LOB01530';
export const CORPORATE_LOB_CODES = [
  'LOB03810',
  'LOB03820',
  'LOB03830',
  'LOB03855',
  'LOB03850',
  'LOB03865',
];
export const EMAIL_VALIDATION_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const PHONE_NUMBER_VALIDATION_REGEX = /^[0-9]{10}$/;
export const WRITE_TO_REQUEST_RAISED_BY = 'CUSTOMER';
export const AIRC = 1;
export const YEAR = '53';
export const CAB_TRIP_TYPE_OUTSTATION = 'Outstation_Return';
export const VENDOR_RATING = '/5';
export const E_COUPON = 'ECoupon';
export const ON_TRIP_STATE = 'OnTrip';
export const UPCOMING_STATE = 'Upcoming';
export const E_COUPON_TYPE = 'ECOUPON REVERSAL';
export const LINE_OF_BUSINESS = 'domestic cabs';
export const LINE_OF_BUSINESS_SD = 'domestic self drive';
export const MY_WALLET = 'MYWALLET';
export const FREE_CANCELLATION_HOUR_WINDOW = 3 * 60 * 60 * 1000;
export const HOUR_IN_MILLIS = 3600;
export const SECOND_IN_MILLIS = 1000;
export const DEFAULT_DATE_TIME = '1753-01-01T00:00:00';
export const INDIAN_PHONE_NUMBERS = /((\+*)((0[ -]+)*|(91 )*)(\d{10,12}))|\d{3,6}([- ]*)\d{5,8}/g;
export const ROUND_TRIP = 2;
export const PWA_COOKIE_NAME = 'ver';
export const METRO_LOB = 'Metro';
export const DIGIT_REGEXP = new RegExp('^[0-9]+$');
export const NAME_REGEX = new RegExp(/^[a-zA-Z ]*$/);
export const OTHERS = 'Others';
export const ENTER_YOUR_REASON = 'Enter Your Reason';
export const REASON_FOR_CANCELLATION = 'Reason For Cancellation';
export const BASE_CDN_IMAGE_PATH = 'https://imgak.mmtcdn.com/mima/images/mobile/';
export const HOSTS_ID = 'hosts';
//TODO: SHould move this to backend
export const SUCCESS = 'SUCCESS'
export const HOTEL_SOLD_OUT = "All rooms are reserved at your hotel from {{checkin}} - {{checkout}}"

export const AUTH_ERROR_PAGENAME = 'mob:cs:personal:booking summary | auth fail'
export const AUTH_FAILED_MESSAGE = 'AUTHENTICATION FAILED'

export const FLOAT_REGEX = /^([0-9]{1,})?(\.)?([0-9]{1,})?$/;
export const ABSOLUTE_ACTIVE_STATE = 0;
export const PERCENTAGE_ACTIVE_STATE = 1;
export const MARKUP_SELECTED = "markUp_selected";
export const MARKUP_DESELECTED = "markUp_deselected";
export const MARKUP_PERCENTAGE = "markUpType_Percentage";
export const MARKUP_ABSOLUTE = "markUpType_Absolute";
export const CUSTOMERVOUCHER_MARKUP = "CustomerBookingVoucher_withMarkUp";
export const CUSTOMERVOUCHER_WITHOUTMARKUP = "CustomerBookingVoucher_withOutMarkUp";
export const PROCEED = "PROCEED";
// Const for Myra.
export const MYRA_BASE_URL = 'https://myra.makemytrip.com/chat?';

export const ENTITY_TYPES = {
  POST_SALES_BOOKING_HOTEL: 'PostSalesBooking_Hotel',
  POST_SALES_BOOKING_HOLIDAY: 'PostSalesBooking_Holiday',
  POST_SALES_BOOKING_DRIVERCHAT: 'PostSalesBooking_DriverChat',
};

export const PAGE_IDENTIFIER = {
  HOTEL_DETAILS_PAGE: 'hotels_details_page',
  HOLIDAYS_DETAILS_PAGE: 'holidays_details_page',
  CAB_DETAILS_PAGE: 'cab_details_page',
};

export const MYRA_INTENTS = {
  CHAT_WITH_HOST_INTENT: 'MMT.Hotelier.Start',
  CHAT_WITH_DRIVER_INTENT: 'MMT.Driver.Start',
};

export const MYTA_CHAT_OWNER = {
  HOST: 'HOST',
  DRIVER: 'DRIVER',
};

export const CHAT_WITH_MYRA_CARD = 'ChatWithMyraCard';
export const NEED_HELP_CARD = 'NeedHelpCard';

export const CORPORATE_HELPLINE_NUMBER = '1800-103-9695';

export const FLIGHT_CANCELLATION_REASON = {
  CITP: 'CITP',
};

export const NEED_HELP_COMMUNICATION_LINKS = {
  WITH_MY_ACCOUNT: "https://supportz.makemytrip.com/MyAccount/Communication/param",
  WITH_MIMA: "https://supportz.makemytrip.com/Mima/Communication/"
}

export const FLIGHT_CANCELLATION_REFUND_OPTIONS = {
  RefundToCustomer: 'RefundToCustomer',
};

export const FLIGHT_CANCELLATION_TYPE = {
  FULL_CANCELLATION: 'FULL_CANCELLATION',
  PARTIAL_CANCELLATION: 'PARTIAL_CANCELLATION',
  SPECIAL_CLAIM: 'SPECIAL_CLAIM',
};

export const FLIGHT_DETAILS_RELOAD_SOURCE = {
  FROM_FLIGHT_DATE_CHANGE: '111',
  FLIGHT_CANCELLATION_DONE: '113',
  FROM_CAB_CROSS_SELL_PAGE: '114',
};

export const CANCELLATION_REQUEST_TYPE = 'Cancellation';
export const CLAIM_REQUEST_TYPE = 'Claim';

export const HELPLINE_NO = '0124 4628747';


export const TGPrremiumRequest = 'TGPrremiumRequest';

export const CancellationPreviewRequest = {
  BUS: 'busCancel',
  RAIL: 'railCancel',
  CAR: 'carCancel',
  FLIGHT: 'flightCancel',
  HOTEL: 'hotelCancel',
  ACME: 'acmeCancel',
  VISA: 'visaCancel',
};

export const PAYMENT_REPONSE = {
  PAYMENT_SUCCESS: "PAYMENT_SUCCESS"
}

export const InstantRefundReloadEvent = {
  BUS: 'bus_cancellation_reload',
  CAR: 'car_cancellation_reload',
  RAIL: 'rail_cancellation_reload',
  FLIGHT: 'flight_cancellation_reload',
  HOTEL: 'hotel_cancellation_reload',
  ACME: 'acme_cancellation_reload',
  VISA: 'visa_cancellation_reload',
};

export const LOBNAMES = {
  BUS: 'BUS',
  CAR: 'CAR',
  RAIL: 'RAIL',
  FLIGHT: 'FLIGHT',
  HOTEL: 'HOTEL',
  ACME: 'ACME',
  TGS : 'TGS',
  VISA: 'VISA',
  GIFT: 'GIFT',
  HOLIDAY: 'HOLIDAY',
  METRO: 'METRO',
  SELF_DRIVE: 'SELF_DRIVE',
  POST_SALES: 'POST_SALES',
  INSURANCE: 'INSURANCE'
};

export const MOJO_LOBNAMES = {
  HOTEL: 'MOJO_HOTEL',
};

export const LOGGER_MAP = {
  BUS: BusEventsLogger,
  CAR: CabEventsLogger,
  RAIL: RailBookingEventsLogger,
  FLIGHT: FlightEventsLogger,
  HOTEL: HotelEventsLogger,
  TGS : TGSBookingEventsLogger,
  VISA: VisaBookingEventsLogger,
  GIFT: GiftCardEventLogger,
  HOLIDAY: HolidayBookingEventsLogger,
  POST_SALES: PostSalesEventLogger
}

export const PAGELOADERMESSAGEDEFAULT = 'Loading Details...';
export const FETCHCANCELLATIONPENALTY = 'Fetch_Cancellation_Penalty';
export const CANCELLATIONSELECTREFUND = 'Cancellation: Select Refund Mode';
export const POST = 'POST';
export const PREVIEW = 'Preview';
export const COMMIT = 'Commit';
export const DEAFULTREFUNDMODE = 'RefundToCustomer';
export const RefundToWallet = 'RefundToWallet';
export const TOTALAMOUNT = 'totalamount';
export const EXTRACHARGES = 'extracharges';
export const OR = 'OR';
export const INSTANT_REFUND_BANNER_TEXT = 'Get Instant refund in your account';
export const OVERLAY = 'overlay';
export const OTP_OVERLAY = 'OTPOverlay';
export const OTP_LOADER = 'OTPLoader';
export const EMPTY_STRING = '';
export const EMPTY_JSON_STRING = "{}";
export const KEYBOARD_SHOW_EVENT = 'keyboardDidShow';
export const KEYBOARD_HIDE_EVENT = 'keyboardDidHide';
export const HOTEL_CANCEL_DETAILS = 'Hotel_Cancellation_Details';
export const FLIGHT_CANCEL_DETAILS = 'Flight_Cancellation_Details';
export const CAB_CANCEL_DETAILS = 'Cab_Cancellation_Details';
export const SELF_DRIVE_CANCEL_DETAILS = 'Self_Drive_Cancellation_Details';
export const OTP_FAILED_ALERT = 'Sending OTP failed. Please try again';
export const INSTANT_REFUND_SUBTEXT =
  'Link accounts tagged with your phone \n for almost instant refunds';
export const INSTANT_REFUND = 'INSTANT REFUND';
export const CONFIRM_CANCEL_BUTTON_TEXT = 'CONFIRM & INITIATE REFUND';
export const ALERT_MESSAGE_SPECIAL_CLIAM = 'Please accept to submit your claim';
export const REFUNDMODE_PAGE_NAMES = {
  BUS: 'Mob:customer support:Bus:SelectRefundMode',
  CAR: 'Mob:customer support:Car:SelectRefundMode',
  RAIL: 'Mob:customer support:Rail:SelectRefundMode',
  HOTEL: 'Mob:customer support:Hotel:SelectRefundMode',
  FLIGHT: 'Mob:customer support:Flight:SelectRefundMode',
  VISA: 'Mob:customer support:Visa:SelectRefundMode',
  ACME: 'Mob:customer support:Acme:SelectRefundMode',
  SELF_DRIVE_CAR: 'Mob:customer support:ZoomCar:SelectRefundMode',
};

// Instant refund Omniture Data
export const REFUND_MODE_LIST_NOT_AVAILABLE = 'refundModeList is null or empty';
export const REGISTER_INSTANT_REFUND_SHOWN = 'Register For Instant Refund Banner Shown';
export const INSTANT_REFUND_NOT_ELIGBLE = 'Instant Refund is not Applicable';
export const INSTANT_REFUND_AVAILABLE = 'Instant Refund Account Available';
export const INSTANT_REFUND_OTP_SUCCESS = 'OTP sent on Requesting Instant Refund Successful';
export const INSTANT_REFUND_OTP_FAILED = 'OTP sent on Requesting Instant Refund Failed';
export const WRONG_OTP_ENTERED = 'Wrong OTP Entered on Instant Refund';
export const INSTANT_REFUND_REQUESTED = 'Cancellation Request with Instant Refund';
export const NO_INSTANT_REFUND_REQUESTED = 'Cancellation Request without Instant Refund'; // to know the skeptical level of customer on Instant refund
export const REGISTER_INSTANT_REFUND_CLICKED = 'Register for Instant Refund Clicked';
export const INSTANT_REFUND_OTP_REQUESTED_AGAIN = 'OTP resent on Requesting Instant Refund';
export const CONFIRM_DATE_CHANGE = 'CONFIRM DATE CHANGE';
export const SAME_CAR_MESSAGE = 'Same car is available at a no extra cost.';
export const CHANGE_PICKUP = 'Change Pick Up Date/Time';
export const PICK_DATE = 'PICK DATE';
export const VIEW_AVAILABLITY = 'VIEW AVAILABILITY';
export const ONE = '1';
export const TWO = '2';
export const THREE = '3';
export const GREEN_BTN_BG = 'gradientBtn';
export const BACK_TO_BOOKING = 'BACK TO BOOKING';
export const UAE_COMPANY_CODE = 'MMTUAE';
export const US_COMPANY_CODE = 'MMTUS';
export const KSA_COMPANY_CODE = 'KSA';
export const GCC_UAE_COMPANY_CODE = 'GCCUAE';
export const OMR_COMPANY_CODE = 'GCCOMAN';
export const KWD_COMPANY_CODE = 'GCCKWT';

export const COMMON_CARD = {
  LOYALTY_CARD: 'LoyaltyCard',
  BOOK_MY_FOREX_CARD:'BookMyForexCard',
  BENEFITS_CLAIMED_CARD: 'BenefitsClaimedCard',
  TRAVEL_CARD: 'TravelCard'
};

export const DEEP_LINK_PAGE = {
  FLIGHT_SPECIAL_CLAIM_SELECTED: 204,
  FLIGHT_ADD_ANCILLARY: 209,
  EXTERNAL_LINK: 210,
  FLIGHT_TICKET_BOOKED: 211,

  // new codes
  FLIGHT_BAGGAGE_BENEFITS_POLICY: 213,

  //working codes
  FLIGHT_DATE_CHANGE: 201,
  FLIGHT_SPECIAL_CLAIM: 202,
  FLIGHT_CANCELLATION: 203,
  FLIGHT_WEB_CHECK_IN: 205,
  FLIGHT_ADD_SEAT: 206,
  FLIGHT_ADD_MEAL: 207,
  FLIGHT_ADD_BAGGAGE: 208,
  FLIGHT_DATE_CHANGE_POLICY: 212,
  FLIGHT_NAME_CORRECTION: 214,
  FLIGHT_PRICE_BREAKUP_CARD: 215,
  FLIGHT_CANCELLATION_POLICY: 216,

  FLIGHT_DOWNLOAD_INVOICE: 217,
  FLIGHT_DOWNLOAD_ETICKET: 218,

  FLIGHT_SPECIAL_CANCELLED_DIRECTLY_WITH_AIRLINE_REASON: 219,
  FLIGHT_SPECIAL_CANCELLED_BY_AIRLINE_REASON: 220,
  FLIGHT_SPECIAL_CLAIM_MEDICIAL_REASON: 221,
};
export const ANCILLARY_DEPENDENT_DEEPLINK = [205, 206, 207, 208, 209];
export const DEEP_LINK_CODE_TRACK = {
  201: 'DateChange',
  202: 'SpecialClaim',
  203: 'Cancellation',
  204: 'SpecialClaimSelect',
  205: 'Webcheckin',
  206: 'AddSeat',
  207: 'AddMeal',
  208: 'AddBaggage',
  209: 'AddAncillary',
  210: 'ExternalLink',
  211: 'FlightBooked',
};
export const SELF_DRIVE_DETAIL_LANDING_KEY =
  'Mob:Customer Support:selfdrive:selfDriveBookingDetail';

export const ViewState = {
  LOADING: 'loading',
  LOADED: 'loaded',
  ERROR: 'error',
};

export const CALLBACK = {
  ERROR_MESSAGE:
    'We are not able to process your request due to some technical issue. Please try again.',
  ERROR_BUTTON: 'TRY AGAIN',
  LOADING: 'Loading...',
};

export const NEED_MORE_HELP = {
  LOADING: 'Loading...',
  REQUEST_SCHEDULE: 'RqstSchedule',
  REQUEST_CONFIRM: 'ReqstComfirm',
  REQUEST_WAITING: 'ReqstWaiting',
  REQUEST_CALLBACK: 'RqstCallBack',
  REQUEST_ERROR: 'RqstError',
  CONNECT_HELPDESK: 'ConnectHelpdesk',
  HELPING_HAND: 'Helping_Hand',
  CALL_US: 'Call_Us',
  CHAT_US: 'Chat_us',
};

export const NEED_HELP_PAGE = {
  FLIGHT_BOOKING_DETAILS: 'flightBookingDetails',
  HOTEL_BOOKING_DETAILS: 'hotelBookingDetails',
  FLIGHT_DATE_CHANGE: 'flightDateChange',
  FLIGHT_CANCEL: 'flightCancel',
  REFUND_BREAKUP: 'refundBreakup',
  FLIGHT_WEBCHECKIN: 'flightWebcheckin',
  FLIGHT_SEAT_MAP: 'flightSeatMap',
  FLIGHT_SPECIAL_CLAIM: 'flightSpecialClaim',
  FLIGHT_SPECIAL_CLAIM_REASON: 'flightSpecialClaimReason',
  FLIGHT_SPECIAL_CLAIM_PAX: 'flightSpecialClaimPax',
  FLIGHT_SPECIAL_CLAIM_REVIEW: 'flightSpecialClaimReview',
  FLIGHT_FALSE_CLAIM: 'flightFalseClaim',
  FLIGHT_CANCEL_PAX: 'flightCancelPax',
  FLIGHT_CANCEL_BREAKUP: 'flightCancelBreakup',
  FLIGHT_ANCILLARY_REVIEW: 'flightAncillaryReview',
  REFUND_TRACKER_CARD: 'refundTrackerCard',
  HOTEL_DETAILS: 'hotelDetails',
  HOTEL_CANCELLATION: 'hotelCancellation',
  HOTEL_DATE_CHANGE: 'hotelDateChange',
  HOTEL_ADD_GUEST: 'hotelAddGuest',
  HOTEL_NAME_CHANGE: 'hotelNameChange',
};

export const refundDelayedText = 'Refund is Delayed';

export const ISSUE_AT_HOTEL_L1 = {
  BAD_SERVICE_TITLE: 'Bad service at the hotel',
  BAD_SERVICE_SUBTEXT:
    'If the hotel is not providing amenities, or the room is dirty please let us know.',
  CHECKIN_DENIED_TITLE: 'I am not allowed check-in',
  CHECKIN_DENIED_SUBTEXT:
    'If the hotel is not providing check in for any reason please contact us.',
};

export const ISSUE_AT_HOTEL_OVERLAY = {
  ISSUE_SELECTION: 'IssueSelection',
  ISSUE_L2_SELECTION: 'IssueL2Selection',
  ISSUE_RESOLUTION: 'IssueResolution',
};

export const CAB_CLAIM_CONST = {
  CLAIM_ISSUE_SELECT_TEXT: 'Issue Select',
  CLAIM_RAISE_REQUEST_TEXT: 'Raise a Request',
  CLAIM_FILL_DESCRIPTION_TEXT: 'Please fill the description atleast 30 characters.',
};

export const OMNITURE_COMPANY_DETAILS = {
  [UAE_COMPANY_CODE]: {
    sbuValue: 'gcc',
    countryValue: 'uae',
  },
  [US_COMPANY_CODE]: {
    sbuValue: 'us',
    countryValue: 'us',
  },
  [KSA_COMPANY_CODE]: {
    sbuValue: 'sa',
    countryValue: 'sa',
  },
  [OMR_COMPANY_CODE]: {
    sbuValue: 'om',
    countryValue: 'om',
  },
  [KWD_COMPANY_CODE]: {
    sbuValue: 'kw',
    countryValue: 'kw',
  },
};

export const CURRENCY_DETAILS = {
  AED: 'aed',
  $: 'usd',
  '₹': 'inr',
  INR: 'inr',
  SAR: 'sar',
  OMR: 'omr',
  KWD: 'kwd',
};

export const ENG_CONSTANT = {
  LOADING: 'Loading...',
  BACK: 'Back',
  NO_INTERNET: 'You are not connected to the Internet',
  CHECK_SETTINGS: 'Please check your Settings and try again.',
  TRY_AGAIN_TEXT: 'TRY AGAIN',
  SOME_THING_WENT_WRING: 'Something went wrong',
  NAME_CHANGE_ERROR_DESCRIPTION:
    'Sorry we are not able to raise a name change request because of some technical issue',
  SOME_THING_WENT_WRONG_TRY_AGAIN: 'Something went wrong, Please try again!',
  RETRY: 'RETRY',
  UPDATED_SUCCESSFULLY:'The details are updated successfully'
};

export const AffiliateThemes = {
  '6eholidays': IndigoTheme,
  'giholidays': GITheme
}
export const THEME = {
  MYBIZ: 'MYBIZ',
};

export const NO_INTERNET = 'NO_INTERNET';

export const REFUND_STATUS = {
  RECEIVED: 1,
  NOT_RECEIVED: 2,
};

export const DONWLOAD_FILE_EXT = {
    INVOICE: '_Invoice',
    ETICKET: '_ETicket'
}

export const LOB_SUPPORT_LANG = {
  [LOBNAMES.FLIGHT]: [LANGUAGE_MAP.eng, LANGUAGE_MAP.hin],
  [LOBNAMES.POST_SALES]: [LANGUAGE_MAP.eng, LANGUAGE_MAP.hin],
  [LOBNAMES.HOTEL]: [LANGUAGE_MAP.eng],
  [LOBNAMES.RAIL]: [LANGUAGE_MAP.eng],
  [LOBNAMES.HOLIDAY]: [LANGUAGE_MAP.eng],
  [LOBNAMES.BUS]: [LANGUAGE_MAP.eng],
  [LOBNAMES.SELF_DRIVE]: [LANGUAGE_MAP.eng],
  [LOBNAMES.ACME]: [LANGUAGE_MAP.eng],
  [LOBNAMES.VISA]: [LANGUAGE_MAP.eng],
  [LOBNAMES.METRO]: [LANGUAGE_MAP.eng],
  [LOBNAMES.GIFT]: [LANGUAGE_MAP.eng],
  [LOBNAMES.CAR]: [LANGUAGE_MAP.eng],
  [LOBNAMES.TGS]: [LANGUAGE_MAP.eng],
};

export const LOCALE_MAP = {
  [LANGUAGE_MAP.hin]: 'hin',
  [LANGUAGE_MAP.hi]: 'hin',
  [LANGUAGE_MAP.eng]: 'eng',
  [LANGUAGE_MAP.en]: 'eng',
}

export const PAN_NUMBER_REGEX = '[A-Z]{5}[0-9]{4}[A-Z]{1}';
export const PAN_MAX_LENGTH = 10;
export const INVALID_PAN = 'INVALID_PAN';
export const VALID_PAN = 'VALID_PAN';
//maintaing a list for available cid's for gcc, once cids are avaliable for all pages remove it.
export const listOfAvailableCIDForGCC = ['POST_SALES_BOOKING_SUMMARY_PAGE_GCC','POST_SALES_WEB_BOOKING_SUMMARY_PAGE_GCC'];

