import React from 'react';
import {Image, ScrollView, Text, View} from 'react-native';
import PropTypes from 'prop-types';
import Actions from '../../navigation/postSalesNavigation';
import styles from './CancellationThankyouCss';
import AtomicCss from '../details/AtomicCss';
import logAction from '@mmt/legacy-commons/Helpers/actionsLogger';
import {showShortToast} from '@mmt/legacy-commons/Common/Components/Toast';
import {isNetworkAvailable} from '@mmt/legacy-commons/Common/utils/AppUtils';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import BasePage from '../../Common/PostSalesBasePage';
import {getAcmeCanDoneResponse} from '../../utils/NetworkUtils';
import NoInternetView from '@mmt/legacy-commons/Common/Components/Error/NoInternetView';
import ProgressView from '@mmt/legacy-commons/Common/Components/Progress/ProgressView';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import FullWidthButton from '../../hotel/details/ActionButton/FullWidthButton';
import AcmeBookingTrackingHelper from '../utils/AcmeBookingTrackingHelper';
import {publishPageRefreshListener} from '../details/AcmeBookingDetail';
import {invalidateTrips} from '@mmt/legacy-commons/Helpers/genericHelper';
import AcmeBookingDetailsConstant from '../utils/AcmeBookingDetailsConstant';
import * as NpsModule from '@mmt/legacy-commons/Native/NpsModule';
import {
  getRatingData, isNotNullAndEmpty
} from '../../Common/commonUtil';
import {cancelIcon} from '../utils/AcmeImageConstant';

const pageName = 'AcmeCancellationThankYou';

export default class AcmeCancellationThankyou extends BasePage {
  static navigationOptions = {
    header: null
  };

  constructor(props) {
    super(props, pageName);
    this.trackingPageName = pageName;
    this.refundAmount = 0;
    this.bookingId = '';
    this.showRatingPrompt = true;
    this.state = {
      viewState: ViewState.LOADING,
      responseJson: null
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.fetchJSONAsync();
  }

  render() {
    return (
      <View style={styles.parentContainer}>
        {this.renderStateWise()}
      </View>
    );
  }

  onHardBackPress = () => {
    this.goToBookingDetails(true);
    logAction('HardwareBackPressed');
    return true;
  };

  async fetchJSONAsync() {
    try {
      const hasNetwork = await isNetworkAvailable();
      if (!hasNetwork) {
        this.setState({viewState: ViewState.NO_INTERNET});
        return;
      }
      const body = await getAcmeCanDoneResponse(this.props.url, this.props.mmtAuth, this.props.loggingTrackInfo, this.props.cancellationDoneRequest);
      if (body) {
        this.bookingId = body.bookingId;
        if (body.status) {
          this.refundAmount = body.refundAmount;
          this.setState({
            responseJson: body,
            viewState: ViewState.SHOW_DETAIL
          });
          this.trackLoadEvent();
        } else {
          this.trackError('API_STATUS_ERROR');
          showShortToast('Something went wrong');
          Actions.popTo('acmeBookingDetail');
        }
      } else {
        this.setState({viewState: ViewState.ERROR});
        this.trackError('API_RESPONSE_NULL');
      }
    } catch (error) {
      this.trackError(error);
    }
  }

  trackLoadEvent() {
    AcmeBookingTrackingHelper.trackLoadEvent('Mob:customer support:ACME:fullcancellationthankyou', this.props.acmeBookingResponse);
  }

  trackError(errorReason) {
    AcmeBookingTrackingHelper.trackErrorEvent('Mob:customer support:< ACME>:fullcancellationthankyouerror', errorReason);
  }


  renderStateWise() {
    switch (this.state.viewState) {
      case ViewState.LOADING:
        return this.renderProgressView();
      case ViewState.NO_INTERNET:
        return this.renderProgressView();
      case ViewState.ERROR:
        return this.renderErrorView();
      default:
        return this.renderPage();
    }
  }

  renderNoNetworkView = () => (
    <NoInternetView
      onRetry={() => {
        this.setStateLoading();
      }}
    />);

  renderProgressView = () => (<ProgressView message="Initializing cancellation..." />);

  renderErrorView = () => (
    <ErrorView
      showRetry
      onRetry={() => {
        this.setStateLoading();
      }}
    />);


  setStateLoading = () => {
    this.setState({viewState: ViewState.LOADING});
    this.fetchJSONAsync();
  };

  renderPage() {
    const { ratingData, npsData } = getRatingData(this.trackingPageName, this.state.responseJson);
    if (ratingData && this.showRatingPrompt) {
      NpsModule.showRatingPrompt(ratingData, npsData);
      this.showRatingPrompt = false;
    }

    return (
      <View style={[styles.paddingTop60, AtomicCss.flex1, AtomicCss.whiteBg]}>
        <ScrollView>
          <View style={[styles.FullPageErrorPage]}>
            <Image style={[styles.ErrorImg1, AtomicCss.marginBottom20]} source={cancelIcon} />
            <Text style={[styles.ErrorHeading, AtomicCss.marginBottom24]}>{this.props.acmeBookingResponse.acmeBookingInfo.acmeBookingDetails[0].productname} successfully {'\n'} cancelled</Text>
            <Text style={[styles.ErrorDisc, AtomicCss.marginBottom20]}>{this.getCancellationTextForRefundAmount()}{' '}</Text>
            <Text style={[styles.ErrorDisc, styles.lastDisc]}>{this.getCancellationTextForConfirmation()}</Text>
          </View>
        </ScrollView>
        <FullWidthButton buttonText={this.getButtonCta()} clickHandler={this.goToBookingDetails} />
      </View>
    );
  }

  getButtonCta = () => {
    if (this.refundAmount > 0) {
      return 'TRACK REFUND';
    }
    return 'VIEW UPDATED BOOKING';
  };

  goToBookingDetails = () => {
    invalidateTrips();
    Actions.popTo('acmeBookingDetail');
    publishPageRefreshListener();
    AcmeBookingTrackingHelper.trackClickEvent('Mob:customer support:ACME:fullcancellationthankyou', 'MI_ACME_VIEW_UPDATED_BOOKING');
  };

  getCancellationTextForRefundAmount() {
    const {customerCurrency} = this.props.acmeBookingResponse;
    if (this.refundAmount > 0) {
      if (AcmeBookingDetailsConstant.INDIAN_CURRENCIES.includes(customerCurrency)) {
        return `You have successfully cancelled this booking. The refund amount of ${customerCurrency} ${this.refundAmount}  will reflect in your account as per following timelines.( Private bank: 3-7 days, Government bank: 7-14 days , UPI:0-8 hours ,MyWallet:Instantly , Gift Card:0-24 hours)`;
      }
      return `You have successfully cancelled this booking. The refund amount of ${customerCurrency} ${this.refundAmount} will reflect in your account in 3-7 days`;
    }
    return 'You have successfully cancelled this booking. There is no refund applicable.';
  }

  getCancellationTextForConfirmation() {
    return 'The cancellation confirmation also has been sent to your register phone number.';
  }
}
AcmeCancellationThankyou.propTypes = {
  url: PropTypes.string.isRequired,
  mmtAuth: PropTypes.string.isRequired,
  loggingTrackInfo: PropTypes.string.isRequired,
  cancellationDoneRequest: PropTypes.object.isRequired,
  acmeBookingResponse: PropTypes.object.isRequired
};
