import { NativeModules } from 'react-native';
import { DOWNLOAD_BUS_INVOICE_PDF, BUS_ERROR_MSG } from "../const";
import ToastWeb from "../components/ToastWeb";
import {getRequestHeader} from "./NetworkModule";
import { getCommonHeaders } from "@rn/apps/post-sales/src/utils/NetworkUtils";
import { convertStringToBlob } from '../webUtils'

const  downloadTicketInvoice = async(busresponse, triggerName,bookingId) => {
    let booking;
    let email;
    if (busresponse) {
        booking = busresponse.bookingId;
        email = busresponse.primaryCustomerDetails.emailId;
    }
    else if (bookingId)
    {
        email = '';
        booking = bookingId;
    }

    var ticketInvoice = "Invoice";
    if(triggerName === "Bus_ETicket"){
        ticketInvoice = "E-Ticket";
    }

    try {
        let busVoucherPdfUri = DOWNLOAD_BUS_INVOICE_PDF;
        let downloadFile = ((triggerName === "Bus_ETicket") ? "Bus_ETicket_" : "Bus_Invoice_") + booking + ".pdf";
        let busVoucherBody = {
            userId: email,
            triggerName: triggerName,
            bookingId: booking
        }

        let loggingTrackInfo = {
            userId: email,
            uniqueId: booking,
            uniqueIdType: triggerName === "Bus_ETicket" ? "BUSTICKET" : "BUSINVOICE",
            loginSource: "WEB"
        }
        const userInfo = await getRequestHeader(busresponse.bookingId, triggerName);
        const headers = await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo);
        const voucherResponse = await fetch(busVoucherPdfUri, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(busVoucherBody)
        })
        if(voucherResponse.ok){
            const responseBody = await voucherResponse.json();
            if (responseBody && responseBody.success && responseBody.pdf) {
                let blob = convertStringToBlob(
                    responseBody.pdf.body,
                  "application/pdf"
                );
                const download = require("downloadjs");
                download(blob, "Trips_Cab_DownloadETicket.pdf", "application/pdf");
                ToastWeb.show("Downloaded successfully");
            }else{
                ToastWeb.show(responseBody.responseText);
            }   
        }
    }
    catch (error) {
        ToastWeb.show(BUS_ERROR_MSG);
        console.log('FAILURE with error code as' + error);
    }
}

NativeModules.BusBookingModule = {
    downloadTicketInvoice,
};