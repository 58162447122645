import React from 'react';
import { View, Text, StyleSheet, Image, TouchableOpacity } from 'react-native';
import Actions from '../../../../../navigation/postSalesNavigation';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import FlightBookingTrackingHelper from '../../helper/FlightBookingTrackingHelper';
import { showAirlineContact } from '../../../../FlightBookingUtil';
import FlightConst from '../../FlightBookingDetailsConstant';
import {
  PHONE_CALL_ICON,
  BLUE_RUPEES_ICON,
  SM_BLUE_RT_ARROW,
  INFO_BLUE_ICON,
} from '../../../../FlightImageConstants';
import { DEEP_LINK_CODE_TRACK, DEEP_LINK_PAGE } from '../../../../../PostSalesConstant';
import { openDialer } from '../../../../../Common/commonUtil';
import { getFont } from '../../../../../PostSalesStyles';
import { getImageTransformStyling } from 'apps/post-sales/src/utils/PostSaleUtil';

class WhatNextCard extends React.Component {
  onAirlineContactClicked = (contacts) => {
    if (contacts.length === 1 && contacts[0].airLineContactNo.split(',').length === 1) {
      openDialer(contacts[0].airLineContactNo.split(',')[0]);
    } else {
      Actions.airlineContactInfo({ airlineNameContacts: contacts });
    }
  };

  onSpecialClaimClicked = (specialClaimData) => {
    Actions.specialClaimSelectPassenger({
      specialClaimData: JSON.stringify(specialClaimData),
      partialPax: JSON.stringify(this.getPaxFareSegmentList(specialClaimData)),
      bookingID: this.props.bookingId,
      bookingState: this.props.response.bookingState.state,
      lobCode: this.props.response.lobCode,
      mobileNumber: this.props.mobileNumber,
      isCorporateBooking: this.props.response.isCorporateBooking,
    });
  };

  getPaxFareSegmentList = (specialClaimData) => {
    const paxFareSegmentList = [];
    this.props.response.newFlightDetails.segmentGroupDetailList.forEach((segmentGroupDetail) => {
      segmentGroupDetail.segmentDetails.forEach((segmentDetail) => {
        segmentDetail.segmentPassengerDetail.forEach((segmentPassengerDetail) => {
          if (!segmentPassengerDetail.isPaxCancelled) {
            const paxFareSegment = {};
            paxFareSegment.reason = specialClaimData.scCode;
            paxFareSegment.paxFareSegmentId = segmentPassengerDetail.paxFareSegmentId;
            paxFareSegmentList.push(paxFareSegment);
          }
        });
      });
    });
    return paxFareSegmentList;
  };

  ButtonClick = (data) => {
    const { actionId, message, reason, url, header, webViewHeader, openInWebView } = data;
    switch (actionId) {
      case FlightConst.CALL_AIRLINE:
        if (showAirlineContact(this.props.response)) {
          this.onAirlineContactClicked(this.props.response.airLineNameContacts);
          FlightBookingTrackingHelper.trackClickEvent(
            'WhatNext',
            'FlightCancelledByAirline_Call',
            this.props.response,
          );
        }
        break;
      case FlightConst.FILE_SPECIAL_CLAIM:
        FlightBookingTrackingHelper.trackClickEvent(
          'WhatNext',
          'FlightCancelledByAirline_SpecialClaim',
          this.props.response,
        );
        const deeplink = reason
          ? DEEP_LINK_PAGE.FLIGHT_SPECIAL_CLAIM_SELECTED
          : DEEP_LINK_PAGE.FLIGHT_SPECIAL_CLAIM;
        this.props.openDeepLink &&
          this.props.openDeepLink(this.props.response, deeplink, { reason });
        break;
      case FlightConst.CONTACT_US:
        openDialer(message);
        break;
      default:
        if (!isNaN(actionId)) {
          FlightBookingTrackingHelper.trackClickEvent(
            'WhatNext',
            this.getEventName(+actionId),
            this.props.response,
          );
          this.props.openDeepLink &&
            this.props.openDeepLink(this.props.response, +actionId, {
              reason,
              header,
              url,
              webViewHeader,
              openInWebView,
            });
        }
        break;
    }
  };

  getEventName = (code) => {
    let event = 'FlightCancelledByAirline';
    if (code && DEEP_LINK_CODE_TRACK[code]) {
      event += `_${DEEP_LINK_CODE_TRACK[code]}`;
    }
    return event;
  };

  getSpecialClaimData = (reason) => {
    const specialClaimList = this.props.response.specialClaimDataList;
    if (specialClaimList && specialClaimList.length > 0) {
      for (let i = 0; i < specialClaimList.length; i += 1) {
        if (specialClaimList[i].scCode === reason) {
          return specialClaimList[i];
        }
      }
    }
    return null;
  };

  getIcons = (item) => {
    const imageData = {
      style: styles.defaultIconStyle,
      icon: INFO_BLUE_ICON,
    };
    if (['CALL_AIRLINE', 'CONTACT_US'].includes(item.actionId)) {
      imageData.icon = PHONE_CALL_ICON;
    } else if (['FILE_SPECIAL_CLAIM', '202', '204'].includes(item.actionId)) {
      imageData.style = styles.priceIconStyle;
      imageData.icon = BLUE_RUPEES_ICON;
    } else if (item.iconUrl) {
      imageData.icon = { uri: item.iconUrl };
    }
    return imageData;
  };

  showWhatNextDetails = () => {
    const views = [];
    const { fsStyle, ...fonts } = getFont(true);
    this.props.scActionList.map((item, index) => {
      const imageData = this.getIcons(item);
      views.push(
        <TouchableOpacity
          activeOpacity={0.8}
          style={[
            styles.rowSection,
            AtomicCss.flexRow,
            index === 0 ? styles.borBottom : '',
            styles.borBottomSpace,
          ]}
          onPress={() => this.ButtonClick(item)}
        >
          <View style={styles.imagePlacehoder}>
            <Image style={imageData.style} source={imageData.icon} />
          </View>
          <View style={styles.rowDtlSection}>
            <Text
              style={[
                fonts.boldFontFamily,
                AtomicCss.azure,
                fsStyle.font16,
                AtomicCss.marginBottom5,
              ]}
            >
              {item.header}
            </Text>
            <Text style={[styles.rowDtlsdisc, fonts.regularFontFamily, fsStyle.font12]}>
              {item.subText}
            </Text>
          </View>
          <Image style={[styles.rightArrowStyle, getImageTransformStyling()]} source={SM_BLUE_RT_ARROW} />
        </TouchableOpacity>,
      );
    });

    return views;
  };
  render() {
    const { fsStyle, ...fonts } = getFont(true);
    return (
      <View style={[styles.whiteWrapper]}>
        <Text
          style={[
            fsStyle.font20,
            fonts.boldFontFamily,
            AtomicCss.blackText,
            AtomicCss.marginBottom15,
          ]}
        >
          {this.props.headerTitle || 'What you can do Next?'}
        </Text>

        {this.showWhatNextDetails()}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  whiteWrapper: {
    backgroundColor: '#ffffff',
    borderTopColor: '#ddd',
    marginTop: 15,
    borderTopWidth: 1,
    paddingHorizontal: 16,
    paddingTop: 16,
    paddingBottom: 10,
  },
  imagePlacehoder: {
    width: 32,
    height: 32,
    backgroundColor: '#edf7ff',
    marginRight: 12,
    borderRadius: 30,
    justifyContent: 'center',
  },
  rowDtlSection: {
    width: '80%',
  },
  defaultIconStyle: {
    width: 20,
    height: 20,
    alignSelf: 'center',
  },
  priceIconStyle: {
    width: 24,
    height: 20,
    alignSelf: 'center',
  },
  rowDtlsdisc: {
    color: '#747474',
  },
  borBottom: {
    borderBottomColor: '#f2f2f2',
    borderBottomWidth: 1,
  },
  borBottomSpace: {
    marginBottom: 15,
    paddingBottom: 15,
  },
  rightArrowStyle: {
    width: 9,
    height: 13,
    position: 'absolute',
    right: 10,
    top: 5,
  },
});

export default WhatNextCard;
