import fecha from "fecha";
import { LocaleConfig } from "react-native-calendars";
import { getRailsLanguageByKey } from './AppLanguage';

const hinConfig = {
  availabilityIntensities : {
    high: 'उच्च उपलब्धता',
    medium: 'मध्यम उपलब्धता',
    low: 'कम उपलब्धता',
  },
  dayNamesFromMonday : [ 'सोम', 'मंगल', 'बुध', 'गुरु', 'शुक्र', 'शनि', 'रवि'],
  dayNamesShort: ['रवि', 'सोम', 'मंगल', 'बुध', 'गुरु', 'शुक्र', 'शनि'],
  dayNames: ['रविवार', 'सोमवार', 'मंगलवार', 'बुधवार', 'गुरुवार', 'शुक्रवार', 'शनिवार'],
  monthNamesShort: ['जनवरी', 'फरवरी', 'मार्च', 'अप्रैल', 'मई', 'जून', 'जुलाई', 'अगस्त', 'सितम्बर', 'अक्टूबर', 'नवम्बर', 'दिसम्बर'],
  monthNames: ['जनवरी', 'फरवरी', 'मार्च', 'अप्रैल', 'मई', 'जून', 'जुलाई', 'अगस्त', 'सितम्बर', 'अक्टूबर', 'नवम्बर', 'दिसम्बर'],
  amPm: ['AM', 'PM'],
};

const enConfig = {
  availabilityIntensities : {
    high: 'High availability',
    medium: 'Medium availability',
    low: 'Low availability',
  },
  dayNamesFromMonday : [ 'Mon', 'Tue', 'Wed', 'Thu', 'Fri','Sat', 'Sun'],
  dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  amPm: ['AM', 'PM'],
}

const tamConfig = {
  availabilityIntensities : {
    high: 'High availability',
    medium: 'Medium availability',
    low: 'Low availability',
  },
  dayNamesFromMonday : [ 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
  dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  dayNames: ["திங்கட்கிழமை", "செவ்வாய்", "புதன்", "வியாழன்", "வெள்ளி", "சனிக்கிழமை", "ஞாயிற்றுக்கிழமை"],
  monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  monthNames: ["ஜனவரி", "பிப்ரவரி", "மார்ச்", "ஏப்ரல்", "மே", "ஜூன்", "ஜூலை", "ஆகஸ்ட்", "செப்டம்பர்", "அக்டோபர்", "நவம்பர்", "டிசம்பர்"],
  amPm: ['AM', 'PM'],
}

const langConfig = {
  eng: enConfig,
  hin: hinConfig,
  tam: tamConfig,
}

export const updateFechaWithLang = (lang: string) => {
  if (lang === "hin") {
    fecha.i18n = hinConfig;
    LocaleConfig.locales['hin'] = hinConfig;
    LocaleConfig.defaultLocale = "hin";
  } else if (lang === "tam") {
    fecha.i18n = tamConfig;
    LocaleConfig.locales['tam'] = tamConfig;
    LocaleConfig.defaultLocale = "tam";
  }else {
    LocaleConfig.defaultLocale = "";
    fecha.i18n = enConfig;
  }
};

export const getMonthNamesShort = () => {
  const currentLanguage = getRailsLanguageByKey("id");
  if (currentLanguage === "hi") {
    return hinConfig.monthNamesShort;
  }
  return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
}

export const getDaysNamesShort = () => {
  const currentLanguage = getRailsLanguageByKey("id");
  if (currentLanguage === "hi") {
    return hinConfig.dayNamesShort;
  }
  return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
}

export const getCalendarDayNames = () => {
  const currentLanguage = getRailsLanguageByKey("cookieCode");
  return langConfig[currentLanguage].dayNamesShort;
}

export const getCalendarDayNamesFromMonday = () => {
  const currentLanguage = getRailsLanguageByKey("cookieCode");
  return langConfig[currentLanguage].dayNamesFromMonday;
}

export const getAvailabilityIntensities = () => {
  const currentLanguage = getRailsLanguageByKey("cookieCode");
  return langConfig[currentLanguage].availabilityIntensities;
}
