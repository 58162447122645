import React from 'react';
import PropTypes from 'prop-types';
import {Image, Text, TouchableOpacity, View} from 'react-native';
import getStyles from './HotelMamiCss';
import {isNotNullAndEmpty} from '../utils/HotelBookingDetailUtil';
import {resetToAcmeDetail} from '../../../acme/utils/AcmeBookingDetailUtil';
import HotelBookingDetailsConstant from '../HotelBookingDetailsConstant';
import {openCabBookingDetail} from '../../../cab/utils/CabBookingDetailUtil';
import HotelBookingTrackingHelper from '../../HotelBookingTrackingHelper';
import { getStaticData } from '../../../staticData/staticData';
import { DOOR_ICON } from '../../HotelImageConstants';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const icon = DOOR_ICON;

class ChildBookingInfo extends React.Component {
  constructor(props) {
    super(props);
    this.fonts = getFont(true);
    this.styles = getStyles(this.fonts);
  }
  render() {
    const staticData = getStaticData();
    const hotelDetailResponse = this.props.response;
    if (!hotelDetailResponse.childBookingInfo || !hotelDetailResponse.childBookingInfo.childBookingDetailInfoList) {
      return null;
    }
    const childBookingDataList = this.getCrossPlatformDetails(hotelDetailResponse);
    return (
      <View style={[this.styles.MamiCard, this.styles.mar10Bt]}>
        <View style={this.styles.CardContent}>
          <Image style={this.styles.CardIcon} source={icon} />
          <View style={this.styles.ContentPortion}>
            <View>
              <Text style={[this.styles.BlackText, this.styles.blackFont, this.styles.mar15Bt]}>
                {staticData.childBookingInfoText.heading}
              </Text>
              <View>
                {this.getAddOnViews(childBookingDataList)}
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  }

  getAddOnViews = (childBookingDataList) => {
    const views = [];
    childBookingDataList.forEach((childBookingData) => {
      views.push(<View style={this.styles.mar20Bt}>
        <Text style={[this.styles.BlackText, this.styles.mar3Bt]}>{childBookingData.title}
        </Text>
        <Text style={[this.styles.font12, this.styles.mar10Bt]}>{childBookingData.subText}
        </Text>
        {childBookingData.callToAction && childBookingData.lobName === HotelBookingDetailsConstant.ACME_LOB_NAME &&
        <TouchableOpacity onPress={() => this.handleClick(childBookingData)}>
          <Text style={[this.styles.BlueText, this.styles.font12]}>{childBookingData.callToAction}</Text>
        </TouchableOpacity>
        }
      </View>);
    });
    return views;
  };

  getCrossPlatformDetails = (response) => {
    const dataToShowList = [];
    const childBookingDetailList = response.childBookingInfo.childBookingDetailInfoList;
    const crossPlatformList = response.crossplatformdetails;
    for (let j = 0; j < childBookingDetailList.length; j += 1) {
      for (let i = 0; i < crossPlatformList.length; i += 1) {
        const crossPlatformInfo = crossPlatformList[i];
        if (crossPlatformInfo.category === 1
          && crossPlatformInfo.refbookingid === childBookingDetailList[j].bookingId) {
          const dataToShow = {};
          dataToShow.bookingId = childBookingDetailList[j].bookingId;
          dataToShow.title = crossPlatformInfo.description;
          dataToShow.mandatorycancelwithparent = childBookingDetailList[j].mandatorycancelwithparent;
          dataToShow.amountreceived = crossPlatformInfo.amountreceived;
          dataToShow.subText = this.getSubText(childBookingDetailList[j], dataToShow);
          dataToShow.lobName = childBookingDetailList[j].lobName;
          if (isNotNullAndEmpty(childBookingDetailList[j].crossplatformLayoutDetail.callToActionCardDetailsList)) {
            const callToAction = childBookingDetailList[j].crossplatformLayoutDetail.callToActionCardDetailsList[0];
            dataToShow.callToAction = callToAction.actionLabel;
            dataToShow.actionType = callToAction.actionType;
          }
          dataToShowList.push(dataToShow);
          break;
        }
      }
    }
    return dataToShowList;
  };

  getSubText = (booking, dataToShow) => {
    const msg = booking.crossplatformLayoutDetail.bookingMessages[booking.bookingStatus];
    const mapObj = {
      '{activity_name}': dataToShow.title,
      '{refund_amount}': booking.refundDetails.refundAmount,
      '{booking_id}': booking.bookingId,
      '{amount_paid}': dataToShow.amountreceived
    };
    return this.replaceAll(msg, mapObj);
  };

  handleClick = (data) => {
    if (data.actionType === 'VIEW_DETAILS') {
      this.openAcmeBookingDetails(data.bookingId);
      HotelBookingTrackingHelper.trackClickEvent(this.props.pageName, 'MI_HTL_ADD_ON_CLICKED', this.props.response);
    }
  };

  openAcmeBookingDetails(bookingId) {
    resetToAcmeDetail(bookingId);
  }

  replaceAll = (str, mapObj) => {
    const re = new RegExp(Object.keys(mapObj)
      .join('|'), 'gi');

    return str.replace(re, matched => mapObj[matched.toLowerCase()]);
  };

  openCabBookingDetails(bookingId) {
    openCabBookingDetail(bookingId);
  }
}

ChildBookingInfo.propTypes = {
  response: PropTypes.object.isRequired,
  pageName: PropTypes.string.isRequired
};

export default ChildBookingInfo;
