import {colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';

const styles = {
  BlackBG: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    backgroundColor: colors.black
  },
  BlackBGImage: {
    width: '100%',
    height: '100%',
    position: 'absolute'
  },
  mmtBlackWrapper: {
    paddingLeft: 15,
    paddingVertical: 19
  },
  iconStyle: {
    width: 40,
    height: 38,
    marginRight: 8
  },
  title: {
    fontSize: 15,
    color: '#fff',
    fontFamily: fonts.black
  },
  titleInfo: {
    fontSize: 15,
    fontFamily: fonts.bold
  },
  titleInfoPreferred: {
    color: '#da956d'
  },
  titleInfoElite: {
    color: '#9b9b9b'
  },
  titleInfoExclusive: {
    color: '#eed5a2'
  },
  subTitle: {
    fontSize: 12,
    fontFamily: fonts.bold,
    color: 'rgba(255,255,255,.6)',
    lineHeight: 14
  },
  moreInfoWrapper: {
    paddingRight: 20

  },
  blackBenifitsWrapper: {
    marginBottom: 15,
    flexDirection: 'row'
  },
  benifitList: {
    backgroundColor: '#000',
    borderWidth: 1,
    borderColor: '#494949',
    borderRadius: 4,
    marginRight: 8,
    flex: 1,
    width: 150,
    paddingVertical: 17,
    alignItems: 'center',
    paddingHorizontal: 15
    // width:windW-20,
  },
  listMoreInfo: {
    color: '#cdcdcd',
    fontSize: 11,
    fontFamily: fonts.regular,
    lineHeight: 15,
    textAlign: 'center',
    marginTop: 4
  },
  callIconStyle: {
    width: 24,
    height: 24,
    marginRight: 7
  },
  callIconTxtStyle: {
    fontFamily: fonts.bold,
    color: '#eed5a2',
    fontSize: 12
  },
  bookingTxt: {
    fontSize: 10,
    color: 'rgba(255,255,255,.5)',
    fontFamily: fonts.regular,
    right: 0,
    alignSelf: 'flex-end',
    top: -12

  },
  blackSpendsCard: {
    ...getPlatformElevation(2),
    backgroundColor: '#f9f9f9',
    padding: 16,
    borderRadius: 6,
    marginBottom: 8,
    marginHorizontal: 8,
    flexDirection: 'row'
  },
  smallIcnStyle: {
    width: 36,
    height: 33,
    top: 3,
    marginRight: 5

  },
  blackInfoTxt: {
    fontSize: 12,
    color: '#9b9b9b',
    fontFamily: fonts.bold,
    flex: 1
  },
  mmtBlackCardNew: {
    borderRadius: 10,
    ...getPlatformElevation(2),
    marginHorizontal: 12,
  },
};


export default styles;
