/**
 * Helper Util file for making loading screens with Shimmer effect
 */

 import React from 'react';
 import { View, Platform } from 'react-native';
 import { Placeholder, PlaceholderContainer } from 'react-native-loading-placeholder';
 import LinearGradient from 'react-native-linear-gradient';
 
 const placeholderBg = '#f1f1f1';
 const DELAY = 200;
 const DURATION = 800;
 const dummyLoaderPromise = new Promise(() => { });
 const gradientToAnimate = (<LinearGradient
   colors={['#eeeeee', '#dddddd', '#eeeeee']}
   start={{ x: 1.0, y: 0.0 }}
   end={{ x: 0.0, y: 0.0 }}
   style={{ flex: 1 }}
 />);
 
 export const Shimmer = (props) => {
   // TODO: Test on Web and make appropriate changes
   const { style, height, width, marginLeft, marginRight, marginBottom, marginTop } = props;
   const otherStyles = { height, width, marginLeft, marginRight, marginBottom, marginTop };
  //  if (Platform.OS === 'web') {
  //    return (
  //      <View
  //        style={{ ...styles.placeHolder, ...style }}
  //        replace
  //        loader={dummyLoaderPromise}
  //      />);
  //  }
   return (
     <Placeholder
       style={{ ...styles.placeHolder, ...style, ...otherStyles }}
       replace
       loader={dummyLoaderPromise}
     />);
 };
 
 const PlaceHolderContainerControl = PlaceholderContainer;
 
 // eslint-disable-next-line react/prop-types
 export const PlaceholderContainerWrapper = ({ children }) => (
   <PlaceHolderContainerControl
     animatedComponent={gradientToAnimate}
     duration={DURATION}
     delay={DELAY}
   >
     {children}
   </PlaceHolderContainerControl>
 );
 
 
 const styles = {
   placeHolder: {
     flexDirection: 'column',
     width: '100%',
     backgroundColor: placeholderBg
   }
 };
 