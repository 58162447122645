import { StyleSheet } from 'react-native';

export default ( theme, fonts) => StyleSheet.create({
  steps: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.color.white,
    paddingVertical: 9,
    paddingHorizontal: 15,
    elevation: 2,
    shadowColor: theme.color.black,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 2,
    position: 'relative',
    zIndex: 2,
    marginTop: -2,
  },
  stepItem: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink:1
  },
  stepNumberWrapper: {
    backgroundColor: theme.color.disabledBtnBg,
    width: 13,
    height: 13,
    borderRadius: 13,
    marginRight: 5,
  },
  stepNumberWrapperActive: {
    backgroundColor: theme.color.black,
  },
  stepNumberWrapperDone: {
    backgroundColor: theme.color.black,
  },
  stepNumber: {
    fontSize: fonts.fontSize.font10,
    color: theme.color.white,
    lineHeight: 13,
    textAlign: 'center',
  },
  stepName: {
    color: theme.color.disabledBtnBg,
    fontSize: fonts.fontSize.font10,
    
  },
  stepNameActive: {
    color: theme.color.black,
  },
  GreenTick: {
    width: 7,
    height: 5,
    position: 'relative',
    top: 4,
    left: 3,
  },
  stepNumberWrapperActiveCorp:{
    color: theme.color.orange,
  },
  stepNameActiveCorp:{
    color: theme.color.orange,
  }

});
