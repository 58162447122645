import React from 'react';
import {View, Text, Image, TouchableOpacity} from 'react-native';
import isEmpty from 'lodash/isEmpty';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import { themeColors } from '../../PostSalesStyles';
import BasePage from '../../Common/PostSalesBasePage';
import ContactItem from './ContactItem';
import {infoIcon, rightArrow} from '../HolidayBookingConstants';
class ContactCard extends BasePage {
  render() {
    const {contactCard, contactPageDetails, buttons} = this.props;

    return (
      <View style={[AtomicCss.marginBottom10, styles.cardBorder]}>
        <View style={[styles.infoCardWrapper]}>
          <View style={styles.headingIconWrapper}>
            <Image style={styles.headingIconStyle} source={infoIcon}/>
          </View>
          <View style={styles.infoCardContent}>
            <Text style={[AtomicCss.defaultText, AtomicCss.blackFont, AtomicCss.font14]}>CONTACT
              NUMBERS
            </Text>
          </View>
        </View>
        {!isEmpty(contactCard.agentInfo) && contactCard.agentInfo.map((item, index) => (
          <ContactItem
            key={index}
            index={index}
            count={contactCard.agentInfo.length}
            agentInfo={item}/>
        ))
        }
        {!isEmpty(buttons) && contactPageDetails && !isEmpty(contactPageDetails.holidayContactList) &&
        <TouchableOpacity
          style={styles.redirectBtnWrapper}
          activeOpacity={0.8}
          onPress={() => this.props.handleButtonClick(buttons[0])}>
          <Text style={styles.linkBtnTxt}>{buttons[0].text}</Text>
          <Image style={styles.rightArrowIcon} source={rightArrow}/>
        </TouchableOpacity>
        }
      </View>
    );
  }
}

const styles = {
  cardBorder: {
    borderTopColor: '#e4e4e4',
    borderBottomColor: '#e4e4e4',
    borderTopWidth: 1
  },
  infoCardWrapper: {
    backgroundColor: '#fff',
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingTop: 15,
  },
  infoCardContent: {
    paddingRight: 8,
    flex: 1
  },
  infoCustom: {
    fontSize: 14,
    color: '#4a4a4a',
    lineHeight: 18,
    fontFamily: fonts.regular
  },
  headingIconWrapper: {
    width: 46,
    alignItems: 'center'
  },
  headingIconStyle: {
    width: 20,
    height: 20
  },
  redirectBtnWrapper: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 50,
    paddingRight: 35,
    justifyContent: 'space-between',
    flexDirection: 'row',
    backgroundColor: '#fff',
    borderTopWidth: 2,
    borderTopColor: '#ededed',
    borderBottomWidth: 2,
    borderBottomColor: '#ededed',
    alignItems: 'center'
  },
  rightArrowIcon: {
    width: 9,
    height: 13,
    tintColor: themeColors.rightArrowTintColor,
  },
  linkBtnTxt: {
    fontSize: 14,
    fontFamily: fonts.bold,
    color: themeColors.linkTextColor
  },
  buttonWrapper: {
    paddingTop: 20,
    width: '90%'
  }
};


export default ContactCard;
