import React from 'react';
import { View, Text } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getFont } from '../../../../../../../PostSalesStyles';
import { isEmptyArray } from '../../../../../../../Common/commonUtil';
import { useTheme } from '../../../../../../../theme/theme.context';

const AncillaryRow = ({ data, type }) => {
  const { fsStyle, ...fonts } = getFont(true);
  const {psTheme} = useTheme();
  const {color} = psTheme;
  const { title, subtitle } = data;
  return (
    <View style={AtomicCss.flexColumn}>
      <Text
        style={[color.black, fsStyle.font14, fonts.boldFontFamily, AtomicCss.marginBottom3]}
      >
        {type + ' - ' + title}{' '}
      </Text>
      {!isEmptyArray(subtitle) &&
        subtitle.map((st) => (
          <Text style={[color.greyLight, fsStyle.font13, fonts.regularFontFamily]}>{st}</Text>
        ))}
    </View>
  );
};
export default AncillaryRow;
