import React from 'react';
import PropTypes from 'prop-types';
import SingleRequestTicketCard from './SingleRequestTicketCard';
import MultipleRequestsTicketCards from './MultipleRequestsTicketCards';

export default function MSRMainCard(props) {
  const {ticketsList, pageName, customStyle, showCardIcon} = props;
  if (!ticketsList) {
    return null;
  }
  const ticketsCount = ticketsList.length;
  if (ticketsCount === 0) {
    return null;
  }
  if (ticketsCount === 1) {
    return <SingleRequestTicketCard ticket={ticketsList[0]} pageName={pageName} customStyle={customStyle} showCardIcon={showCardIcon}/>;
  }
  return <MultipleRequestsTicketCards ticketsList={ticketsList} pageName={pageName} customStyle = {customStyle}/>;
}

MSRMainCard.defaultProps = {
  ticketsList: []
};

MSRMainCard.propTypes = {
  ticketsList: PropTypes.arrayOf(PropTypes.shape({
    iconUrl: PropTypes.string.isRequired,
    secondaryType: PropTypes.string.isRequired,
    createdAt: PropTypes.number.isRequired,
    updatedAt: PropTypes.number.isRequired,
    expectedClosureDate: PropTypes.number,
    assignee: PropTypes.string,
    msrUrl: PropTypes.string.isRequired,
    actionBtnText: PropTypes.string.isRequired
  }))
};
