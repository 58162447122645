import { StyleSheet } from 'react-native';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';

const styles = StyleSheet.create({
  requestWrapper: {
    borderTopWidth: 1,
    borderTopColor: colors.lightGrey,
    paddingTop: 8,
    marginRight: 16
  },
  reciveRefundWrapper: {
    borderTopWidth: 1,
    borderTopColor: colors.lightGrey,
    paddingTop: 15,
    marginTop: 15,
  },
  refundReceivedCard: {
    marginBottom: 6,
    marginRight: 12,
  },
  btnWrapper: {
    ...getPlatformElevation(2),
    backgroundColor: colors.white,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: colors.grey2,
    paddingVertical: 5,
    paddingHorizontal: 10,
    flexDirection: 'row',
    alignItems: 'center',
    width: '47%',
    justifyContent: 'center',
  },
  thumsIconStyle: {
    width: 22,
    height: 22,
    marginRight: 10,
  },
  span: {
    ...AtomicCss.boldFont,
    ...AtomicCss.azure,
  },
  p: {
    ...AtomicCss.defaultText, 
    ...AtomicCss.regularFont,
    ...AtomicCss.font12, 
    ...AtomicCss.lineHeight16
  }
});

export default styles;
