import isEmpty from "lodash/isEmpty";
import React, { useMemo } from "react";
import { Image, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { openDialer } from '../../../Common/commonUtil';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import CommonBottomOverlayMessage from '../../../Common/CommonOverlayMessage/CommonBottomOverlayMessage';
import { PHONE_ICON } from "../../HotelImageConstants";
import { getFont } from "apps/post-sales/src/PostSalesStyles";

const ContactPropertyCardContent = React.memo(({ dismiss, contactStr, title }) => {
  const { fsStyle, ...fonts } = getFont(true);
  const contactList = useMemo(() => !isEmpty(contactStr) ? contactStr.split(",") : []);

  return (
    <>
      <TouchableOpacity onPress={dismiss} >
        <View style={styles.overlayTopline}></View>
      </TouchableOpacity>
      <View style={styles.contactListWrap}>
        <View style={AtomicCss.marginBottom20}>
          <Text style={[fsStyle.font20, fonts.blackFontFamily, AtomicCss.blackText]}>{title}</Text>
        </View>
        <View>
          {
            contactList.map((contact) => (
              <TouchableOpacity onPress={() => { openDialer(contact) }}>
                <View style={styles.contactList}>
                  <View style={AtomicCss.marginRight15}><Image source={PHONE_ICON} style={styles.imgPhoneIcon} /></View>
                  <View>
                    <Text style={[fsStyle.font18, AtomicCss.azure, fonts.boldFontFamily]}>{contact}</Text>
                  </View>
                </View>
              </TouchableOpacity>
            ))
          }
        </View>
      </View>
    </>
  )
});



const ContactPropertyOverlay = (props) => {
  return (
    <CommonBottomOverlayMessage customStyle={AtomicCss.borderTopRadius16} dismiss={props.dismiss} content={
      <ContactPropertyCardContent {...props} />
    } />
  )
};

export default React.memo(ContactPropertyOverlay);


const styles = StyleSheet.create({
  overlayTopline: {
    width: 40,
    height: 4,
    borderRadius: 4,
    backgroundColor: '#dddddd',
    alignSelf: 'center',
  },
  contactList: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#f7fbff',
    borderWidth: 1,
    borderColor: '#e3f1ff',
    borderRadius: 4,
    paddingVertical: 15,
    paddingHorizontal: 20,
    marginBottom: 10
  },
  imgPhoneIcon: {
    width: 20,
    height: 20
  },
  contactListWrap: {
    padding: 20,
    paddingTop: 20
  },
})