import React from 'react';
import { Image, Text, TouchableOpacity, StyleSheet, Platform, View } from 'react-native';
import {colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import { useTheme } from '../theme/theme.context';

interface StyleObj {
  [proName: string]: any
}

interface CheckboxProps {
  onChange: () => any;
  label: string;
  labelStyle?: StyleObj,
  containerStyle?: StyleObj;
  checked?: boolean;
  disabled?:boolean;
  tintColor?: string | null;
  errorTextStyle?: StyleObj,
  errorText?: string | null;
  showErrorText?: boolean | null;
  size?:number | undefined;
}

const CB_CHECKED = require('@mmt/legacy-assets/src/ic-check-box-active.webp');
const CB_UNCHECKED= require('@mmt/legacy-assets/src/ic-check-box-inactive.webp');

export default function Checkbox(props: CheckboxProps) {
  const {psTheme:theme}=useTheme();
  const {
    label,
    labelStyle = {},
    containerStyle = {},
    checked = false,
    onChange,
    disabled,
    tintColor,
    errorTextStyle,
    errorText,
    showErrorText,
    size=25
  } = props;

  const imageSrc = checked ? CB_CHECKED : CB_UNCHECKED;
  const imageStyle = [
    styles.imageStyle,
    disabled ? styles.disabled : {},
    {
      tintColor:  checked ? tintColor ? tintColor : theme.color.primary  : theme.color.lightTextColor ,
      height: size,
      width: size,
    },
  ];
  const finalLabelStyle = [styles.label, disabled ? styles.disabled : {}, labelStyle];
  // @ts-ignore
  return (
    <TouchableOpacity
      style={[styles.container, containerStyle]}
      onPress={onChange}
      activeOpacity={0.8}
      disabled={disabled}
    >
      <Image source={imageSrc} style={imageStyle} />
      {!!label && (
        <View style={styles.labelContainer}>
          <Text style={finalLabelStyle}>{label}</Text>
          {showErrorText && <Text style={errorTextStyle}>{errorText}</Text>}
        </View>
      )}
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  label: {
    marginTop: Platform.OS === 'ios' ? 0 : 1,
    flexShrink: 1,
    fontSize: 14,
    color: colors.black,
    fontFamily: fonts.regular,
  },
  imageStyle: {
    height: 25,
    width: 25,
    marginRight: 10
  },
  disabled: {
    opacity: 0.4
  },
  labelContainer:{
    width: "90%"
  }
});
