import {StyleSheet} from 'react-native';

export default (theme, fonts) => 
StyleSheet.create({
    flex1: {
        flex: 1
      },
      whiteContainer: {
        backgroundColor: theme.color.white,
        flex: 1,
        width:'100%',
        height:'100%',
      },
      DefaultText: {
        color: theme.color.defaultTextColor
      },
      MamiCard: {
        backgroundColor: theme.color.white
      },
      CardContent: {
        backgroundColor: theme.color.white,
        borderBottomWidth: 1,
        borderBottomColor: theme.color.lightGrey,
        flexDirection: 'row',
        padding: 15,
        elevation: 1,
        shadowColor: theme.color.black,
        shadowOffset: {
          width: 0,
          height: 1
        },
        shadowOpacity: 0.3,
        shadowRadius: 1
      },
      CardIcon: {
        width: 24,
        height: 24,
        marginRight: 8
      },
      BlackText: {
        color: theme.color.black
      },
      AlignLeft: {
        textAlign: 'left'
      },
      ActionBtn: {
        backgroundColor: theme.color.white,
        paddingBottom: 10,
        marginTop: 15
      },
      mar15Top: {
        marginTop: 15
      },
      paddingAll: {
        paddingTop: 20,
        paddingLeft: 20
      },
      refundModeMessageCard: {
        backgroundColor: theme.color.white,
        borderBottomWidth: 1,
        borderBottomColor: theme.color.lightGrey,
        flex: 1,
        padding: 15
      },
      alignCenter: {
        alignItems: 'center'
      },
});