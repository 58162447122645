import React from 'react';
import PropTypes from 'prop-types';
import {View, ScrollView, StyleSheet, Animated, Platform} from 'react-native';
import isEmpty from 'lodash/isEmpty';
import TravellerInfoItem from './TravellerInfoItem';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import Header from './Header';
import {getPaxConfigLabel} from '../HolidayBookingDetailUtils';
import {screenHeight} from '../HolidayBookingConstants';

class TravellerInfo extends React.Component {

  static navigationOptions = {
    header: null
  };

  constructor(props) {
    super(props);
    this.state = {
      slideUp: new Animated.Value(screenHeight)
    };
  }

  handleOpen = () => {
    Animated.timing(this.state.slideUp, {
      duration: 700,
      toValue: 0
    })
      .start();
  };


  render() {
    const {travellerPageDetails} = this.props;
    const travellerDetailList = travellerPageDetails && travellerPageDetails.travellerDetailList;
    return (
      travellerPageDetails ?
      <View style={styles.container}>
        <Header
          title="Traveller Details"
          subTitle={this.props.travellerHeaderLabel}
          headerShadow
          headingTxt="font18"
          pageName={this.props.pageName}
          goBack={this.props.onBackPopUp}
          navigation={this.props.navigation}
        />

        <ScrollView>
          <View style={AtomicCss.marginTop10}>
            {!isEmpty(travellerDetailList)
            && travellerDetailList.map((item, index) => {
              return (
                <TravellerInfoItem
                  item={item}
                  key={item.travellerName}
                  index={index}
                />
              );
            })
            }
          </View>
        </ScrollView>
      </View>
        : null
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: '#f2f2f2',
    position: Platform.OS === 'web'? 'fixed': 'absolute',
    elevation: 3,
    zIndex: 3,
    width: '100%',
    height: '100%'
  },
  margin15: {
    margin: 15
  },
  modalWrapper: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#fff',
    zIndex: 100,
    elevation: 10
  },
  activeModal: {
    top: 0
  }
});

TravellerInfo.propTypes = {
  travellerPageDetails: PropTypes.object.isRequired,
  travellerHeaderLabel: PropTypes.string.isRequired,
  pageName: PropTypes.string.isRequired,
  onBackPopUp: PropTypes.func.isRequired
};
export default TravellerInfo;
