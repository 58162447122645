import {trackOmniture} from '@mmt/legacy-commons/Common/utils/OmnitureTrackerUtils';
import {findDaysBetweenDates, getIsRailRACCardVisible, getRailAncillary, getseatinfoText} from './RailBookingDetailsUtil';
import RailBookingEventsLogger from './RailBookingEventsLogger';
import isEmpty from 'lodash/isEmpty';
import { getSegmentInfo } from './data/helper';

class RailBookingTrackEventHelper {
  trackClickEvent = (pageName, railResponse, clickEvent) => {
    RailBookingEventsLogger.clickEvent(pageName, clickEvent, railResponse);
    let params = {};
    params = this.getBasicOminitureVariables(pageName, railResponse);
    params.m_c54 = `Mob:customer support:Rail:${pageName}_${clickEvent}`;
    trackOmniture(`Mob:customer support:Rail:${pageName}`, params);
  };

  trackClickEvents = (pageName, clickEvent) => {
    RailBookingEventsLogger.clickEvent(pageName,clickEvent);
    const params = {};
    params.m_c54 = `MI_Rail_${pageName}_${clickEvent}`;
    trackOmniture(`Mob:customer support:Rail:${pageName}`, params);
  };

  getBasicOminitureVariables = (pageName, railResponse) => {
    const params = {};
    const {freeCancellation} = getSegmentInfo(railResponse);
    if (railResponse !== undefined) {
      const seatinfoDetails = getseatinfoText(railResponse);
      // params.m_v35 = railResponse.primaryContactNumber;
      params.m_v16 = railResponse.bookingID;
      // params.m_v34 = railResponse.primaryEmailID;
      params.m_v9 = railResponse.bookingStatus;
      params.m_v82 = !isEmpty(railResponse.bnppDetail) ? "True" : "False";
      params.m_v40 = !isEmpty(railResponse.bnppDetail) && railResponse.bnppDetail.pendingTime || "";
      params.m_v28 = !isEmpty(railResponse.tgsUpgradeCardData) || !isEmpty(freeCancellation) ? "Yes" : "No";
      params.Prop19 = seatinfoDetails.tripType;
      const isRailRACCardVisible = getIsRailRACCardVisible(railResponse);
      params.m_v71 = isRailRACCardVisible
      params.m_v28 = getRailAncillary(railResponse);
      if (railResponse && railResponse.train && railResponse.train[0] &&
        railResponse.train[0].pnrDetails && railResponse.train[0].pnrDetails[0] &&
        railResponse.train[0].pnrDetails[0].segmentInfo &&
        railResponse.train[0].pnrDetails[0].segmentInfo[0]) {
        const segmentInfo = railResponse.train[0].pnrDetails[0].segmentInfo[0];
        const departureTime = `${segmentInfo.boardingDate}T${segmentInfo.departureTime}`;
        const bookingDate = railResponse.bookingDateTime.replace(' ', 'T');

        params.m_v21 = findDaysBetweenDates(new Date().getTime(), departureTime);
        params.m_v7 = findDaysBetweenDates(new Date().getTime(), bookingDate);
        params.Props56 = findDaysBetweenDates(new Date().getTime(), bookingDate);
      }
    }

    params.m_v15 = `Mob:customer support:Rail:${pageName}`;
    params.m_pageName = `Mob:customer support:Rail:${pageName}`;
    return params;
  }

  trackLoadEvent = (pageName, railResponse) => {
    RailBookingEventsLogger.loadEvent(pageName, railResponse);
    let params = {};
    params = this.getBasicOminitureVariables(pageName, railResponse);
    trackOmniture(`Mob:customer support:Rail:${pageName}`, params);
  };

  trackErrorEvent = (pageName) => {
    RailBookingEventsLogger.errorEvent(pageName);
    const params = {};
    const fullPageName = pageName !== undefined ? `Mob:customer support:Rail:${pageName}_Error` : 'Mob:customer support:Rail:Error';
    params.pageName = fullPageName;
    params.m_v15 = fullPageName;
    trackOmniture(fullPageName, params);
  };

  trackBannerShow = (bannerName, pageName) => {
    RailBookingEventsLogger.loadEvent(pageName,bannerName);
    const params = {};
    params.prop54 = bannerName;
    trackOmniture(pageName, params);
  }
}


export default new RailBookingTrackEventHelper();
