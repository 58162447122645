import React, { useEffect, useMemo } from 'react';
import { View, Text, TouchableOpacity, Image, Platform } from 'react-native';
import createStyles from './BookingWhiteCardCss';
import _ from 'lodash';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import HotelTopCard from './HotelTopCard';
import HotelBookingDetailsConstant from '../../HotelBookingDetailsConstant';
import {fillCheckInCheckOutData, getPrimaryCustomerName, isNotNullAndEmpty, getActionButton, handleClickEvent, isNullOrEmpty, openPropertyDetails, isUpcomingOrOnTripBooking, showHotelImage, getThrottleParams} from '../../utils/HotelBookingDetailUtil';
import { getImageTransformStyling } from '../../../../utils/PostSaleUtil';
import { RIGHT_ARROW_ICON, HOTEL_DEFAULT_ICON, EDIT_ICON } from '../../../HotelImageConstants';
import { getStaticData } from '../../../../staticData/staticData';
import { isEmpty } from 'lodash';
import HotelBookingTrackingHelper from '../../../HotelBookingTrackingHelper';
import { joinStrings } from '@mmt/legacy-commons/Common/utils/StringUtils';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import HotelBookingConstant from '../../HotelBookingDetailsConstant'; 
import Persuasion from '@Frontend_Ui_Lib_App/Persuasion';

const BookingWhiteCard = ({response, card, showTime, showArrows, pageName, handleIssueAtHotelCard, topStatusColorStyle, bookingCardStyle, showDatesAndRoomDetails, showInstallmentOverlay, handlePAHCard, handleMessageHost, actionHandler, handleOpenBottomOverlay}) => {
  const suffixStyle = response.bookingStatus;
  const staticData = getStaticData(true);
  const {hotelBookingInfo, bhfBanner, flexibleCheckInFlag, flexibleCheckIn} = response || {};
  const { altAccoRoomInfo } = hotelBookingInfo || {}
  const showWriteReviewBanner = response.showWriteReviewBanner && Platform.OS != "web";
  const hotelDetailList = hotelBookingInfo.hotelDetailsList && hotelBookingInfo.hotelDetailsList.length > 0 && hotelBookingInfo.hotelDetailsList[0];
  const totalGuestCount = hotelBookingInfo.totalNumberOfAdults + hotelBookingInfo.totalNumberOfChilds;
  const {showCard: showBHFBanner} = bhfBanner || {};
  const { checkinDetails } = !isEmpty(hotelBookingInfo) ? hotelBookingInfo : {}
  const { instructions, showBanner, title, dayUseTimeText, dayUseDurationText } = !isEmpty(checkinDetails) ? checkinDetails : {}
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme)
  const filterActionList = [HotelBookingDetailsConstant.ACTION_CHANGE_DATES, HotelBookingDetailsConstant.ACTION_GB_CHANGE_DATES, HotelBookingDetailsConstant.ACTION_ADD_GUEST, HotelBookingDetailsConstant.ACTION_GB_ADD_GUEST, HotelBookingDetailsConstant.ACTION_EDIT_PRIMARY_GUEST_NAMES]
  const bottomActionCard = useMemo(()=>{
    const bottomActionList =  card?.actionList?.filter((action) => !filterActionList.includes(action.actionFamily)) || []
    return  {...card, actionList: bottomActionList}
  },[card])

  useEffect(() => {
    if (showBHFBanner) {
      HotelBookingTrackingHelper.trackShowEvent(pageName, 'bhf_mytrips_card_shown', response);
    }
  }, [showBHFBanner])

  const addRoomTypeName = (roomMealData) => {
    let views = [];
    for (let roomName in roomMealData) {
      views.push(
          <Text style={[AtomicCss.midGreyText, fsStyle.font12, AtomicCss.alignLeft, AtomicCss.lineHeight16, fonts.boldFontFamily]}>
           {roomName}
           {!isNullOrEmpty(roomMealData[roomName]) && (roomMealData[roomName] !== "EP") ? <Text style={[AtomicCss.midGreyText, AtomicCss.font12, AtomicCss.regularFont]}>{' (' + roomMealData[roomName] + ')'}</Text> : null}
         </Text>
      );
    }
    return views;
  };

  const addAltAccoRoomTypeName = (title) => {
    return (
      <Text
        style={[
          { color: theme.color.greyLight },
          fsStyle.font12,
          AtomicCss.alignLeft,
          AtomicCss.lineHeight22,
          fonts.boldFontFamily,
        ]}
      >
        {title}
      </Text>
    );
  };

  const renderModificationBanner = (modificationMsg) => {
    return (
      <Persuasion
        bgColor={[theme.color.grayBg]}
        content={modificationMsg}
        customStyle={{
          contentStyle: [styles.editText, fsStyle.font12, AtomicCss.lineHeight20, fonts.boldFontFamily],
          startIconStyle: [styles.editIcon],
          wrapperStyle: [styles.editWrapper, AtomicCss.marginBottom20, AtomicCss.alignCenter]
        }}
        startIcon={EDIT_ICON}
      />
    )
  };

  const renderBottomInfoBanner = (text) => {
    return (
      <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.justifyCenter, AtomicCss.marginBottom20, AtomicCss.textCenter]}>
        <View style={styles.bottomInfo}>
          <Text style={[AtomicCss.textCenter, fsStyle.font13, fonts.boldFontFamily, AtomicCss.midGreyText, AtomicCss.lineHeight20]}>{text}</Text>
        </View>
      </View>
    )
  }

  const handleClick = (action) => {
    handleClickEvent(card, {...action, actionHandler: showInstallmentOverlay }, response, pageName);
  };

  const handleTopCardAction = (card,action,actionHandler) => {
    handleClickEvent(card, actionHandler ? {...action, actionHandler: handleOpenBottomOverlay}: action, response, pageName);
  }

  const modifyActionType = (card) => {
    card.actionList && card.actionList.forEach((action, index) => {
      if (index > 1 && action.actionType === HotelBookingDetailsConstant.ROUND_BUTTON_BLUE) {
        action.actionType = HotelBookingDetailsConstant.ROUND_BUTTON_LIGHT_BLUE;
      }
    })
    return true;
  }

  const onWriteAReviewClick = () => {
		handleClickEvent(card, {actionFamily: HotelBookingDetailsConstant.ACTION_WRITE_HOTEL_REVIEW}, response, pageName);
	}

  const onBHFBannerClick = () => {
    handleIssueAtHotelCard('BHF_banner', pageName);
  }

  const renderCheckinCheckoutDetails = () => {
    const changeDateActionsList = [HotelBookingDetailsConstant.ACTION_CHANGE_DATES, HotelBookingDetailsConstant.ACTION_GB_CHANGE_DATES]
    const changeDateAction = card?.actionList?.find((action) => changeDateActionsList.includes(action.actionFamily)) || null
    const { isDayUseBookingNew } = response;
    return (
      <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.spaceBetween, AtomicCss.marginBottom12, styles.borderBottom, styles.paddingLR12]}>
        <View style={[AtomicCss.flexRow, AtomicCss.flex1]}>
          <View style={isDayUseBookingNew? AtomicCss.width100 : styles.width50}>
            <Text style={[AtomicCss.midGreyText, fsStyle.font12, AtomicCss.lineHeight20, fonts.boldFontFamily, AtomicCss.marginBottom2, AtomicCss.alignLeft]}>
              {isDayUseBookingNew ? staticData.hotelMainCardText.additionalText.stayTimingText : staticData.hotelMainCardText.additionalText.checkInText}
            </Text>
            <Text style={[AtomicCss.midGreyText, fsStyle.font13, fonts.blackFontFamily, AtomicCss.blackText, AtomicCss.lineHeight16, AtomicCss.alignLeft]}>
              {_.has(hotelBookingInfo, 'checkinDetails.dayDateText') ? hotelBookingInfo.checkinDetails.dayDateText : fillCheckInCheckOutData(hotelBookingInfo.checkInDate, 'ddd, DD MMM')}
              <Text style={[fsStyle.font10, AtomicCss.blackText, AtomicCss.lineHeight14, fonts.regularFontFamily]}>
                &nbsp;&nbsp;{_.has(hotelBookingInfo, 'checkinDetails.year') ? hotelBookingInfo.checkinDetails.year : fillCheckInCheckOutData(hotelBookingInfo.checkInDate, 'YYYY')}
              </Text>
            </Text>
            {
              isDayUseBookingNew ? (
                <Text style={[AtomicCss.blackText, fsStyle.font16, fonts.blackFontFamily]}>
                  {dayUseTimeText || ''}
                  <View style={[AtomicCss.paddingLeft5]}>
                    <Text style={[fsStyle.font14, AtomicCss.defaultText, fonts.regularFontFamily]}>{dayUseDurationText || ''}</Text>
                  </View>
                </Text>
              ) : (
                  showTime ? (
                  <Text style={[AtomicCss.midGreyText, fsStyle.font12, AtomicCss.lineHeight16, AtomicCss.alignLeft]}>
                  {_.has(hotelBookingInfo, 'checkinDetails.hourText2') ? hotelBookingInfo.checkinDetails.hourText2 : `${staticData.hotelMainCardText.additionalText.afterText} ${hotelBookingInfo.checkInTime}`}
                  </Text>
                ) : null
              )
            }

          </View>
         {!isEmpty(hotelBookingInfo.checkoutDetails) &&  !hotelBookingInfo.checkoutDetails.hide &&
           <View style={styles.width50}>
            <Text style={[AtomicCss.midGreyText, fsStyle.font12, AtomicCss.lineHeight20, fonts.boldFontFamily, AtomicCss.marginBottom2, AtomicCss.alignLeft]}>
              {staticData.hotelMainCardText.additionalText.checkOutText}
            </Text>
            <Text style={[AtomicCss.midGreyText, fsStyle.font13, fonts.blackFontFamily, AtomicCss.blackText, AtomicCss.lineHeight16, AtomicCss.alignLeft]}>
              {_.has(hotelBookingInfo, 'checkoutDetails.dayDateText') ? hotelBookingInfo.checkoutDetails.dayDateText : fillCheckInCheckOutData(hotelBookingInfo.checkOutDate, 'ddd, DD MMM')}
              <Text style={[fsStyle.font10, AtomicCss.blackText, AtomicCss.lineHeight14, fonts.regularFontFamily]}>
                &nbsp;&nbsp;{_.has(hotelBookingInfo, 'checkoutDetails.year') ? hotelBookingInfo.checkoutDetails.year : fillCheckInCheckOutData(hotelBookingInfo.checkOutDate, 'YYYY')}
              </Text>
            </Text>
            {showTime && <Text style={[AtomicCss.midGreyText, fsStyle.font12, AtomicCss.lineHeight16, AtomicCss.alignLeft]}>
              {_.has(hotelBookingInfo, 'checkoutDetails.hourText2') ? hotelBookingInfo.checkoutDetails.hourText2:`${staticData.hotelMainCardText.additionalText.beforeText} ${hotelBookingInfo.checkOutTime}`}
            </Text>}
          </View>}
        </View>
        {(showArrows && changeDateAction) && <TouchableOpacity onPress={() => handleTopCardAction(card,changeDateAction,actionHandler)}>
            <Text style={[AtomicCss.azure,AtomicCss.font11,fonts.blackFontFamily]}>{changeDateAction.actionLabeltext}</Text>
          </TouchableOpacity>
        }
      </View>
    )
  }

  const renderFlexiCheckInDetails = (flexibleCheckIn) => {
    return (
      <View
        style={[
          AtomicCss.flexRow,
          AtomicCss.alignCenter,
          AtomicCss.spaceBetween,
          AtomicCss.marginBottom12,
          styles.paddingLR12,
          styles.borderBottom
        ]}
      >
        <View style={AtomicCss.flex1}>
          {!!flexibleCheckIn.title &&
            <Text
              style={[
                {color: theme.color.greyLight},
                fsStyle.font14,
                fonts.blackFontFamily,
                {color: theme.color.black},
                AtomicCss.lineHeight20,
                AtomicCss.marginTop2,
                AtomicCss.alignLeft,
              ]}
            >
              {flexibleCheckIn.title}
              {!!flexibleCheckIn.slot && `${':'}`}
              {!!flexibleCheckIn.slot &&
                <Text style={[
                  {color: theme.color.greyLight},
                  fsStyle.font12,
                  fonts.regularFontFamily,
                  AtomicCss.alignLeft,
                  AtomicCss.lineHeight20,
                ]}
                > {flexibleCheckIn.slot}
                </Text>
              }
            </Text>
          }

          {!!flexibleCheckIn.subTitle &&
            <Text
              style={[
                {color: theme.color.greyLight},
                fsStyle.font12,
                fonts.regularFontFamily,
                AtomicCss.alignLeft,
                AtomicCss.lineHeight20,
              ]}
            >
              {flexibleCheckIn.subTitle}
            </Text>
          }
        </View>
      </View>
    )
  }

  const renderRoomDetails = () => {
    const changeGuestActionsList = [HotelBookingDetailsConstant.ACTION_ADD_GUEST, HotelBookingDetailsConstant.ACTION_GB_ADD_GUEST]
    const changeGuestAction = card?.actionList?.find((action) => changeGuestActionsList.includes(action.actionFamily)) || null
    const changePrimaryGuestNameAction = card?.actionList?.find((action) => action.actionFamily === HotelBookingDetailsConstant.ACTION_EDIT_PRIMARY_GUEST_NAMES) || null
    const { title, heading, subHeading, isEntire } = altAccoRoomInfo || {}
    const { packageTypeIcon = "" } = response;
    return (
      <>
        <View style={[styles.borderBottom, styles.paddingLR12, AtomicCss.marginBottom12]}>
          <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.spaceBetween]}>
            <View style={AtomicCss.flex1}>
              {!!packageTypeIcon && (
                <View style={[AtomicCss.marginBottom5, styles.superPackageImgContainer]}>
                  <Image source={{ uri: packageTypeIcon }} style={styles.superPackageImg} />
                </View>
              )}
              <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
                <Text style={[AtomicCss.flexRow, styles.maxWidth70]}>
                  {!!title && isEntire
                    ? addAltAccoRoomTypeName(title)
                    : addRoomTypeName(hotelBookingInfo.roomMealData)}
                </Text>
                {!isEmpty(hotelBookingInfo.benefitClaimDetails) &&
                  !isEmpty(hotelBookingInfo.benefitClaimDetails.title) && (
                    <View style={[AtomicCss.alignSelfStart]}>
                      <Text
                        style={[fsStyle.font12, fonts.boldFontFamily, styles.benefitClaimedLabel]}
                      >
                        {hotelBookingInfo.benefitClaimDetails.title}
                      </Text>
                    </View>
                  )}
              </View>

              {isEntire && !isEmpty(heading) && (
                <Text
                  style={[
                    AtomicCss.midGreyText,
                    fsStyle.font12,
                    AtomicCss.alignLeft,
                    AtomicCss.lineHeight22,
                    fonts.regularFontFamily,
                  ]}
                >
                  {heading} {!isEmpty(subHeading) && `| ${subHeading.join(', ')}`}
                </Text>
              )}

               {!!hotelBookingInfo?.totalGuestBreakupText && (
                <Text
                  style={[
                    {color: theme.color.black},
                    fsStyle.font16,
                    fonts.blackFontFamily,
                    AtomicCss.lineHeight20,
                    AtomicCss.marginTop2,
                    AtomicCss.alignLeft,
                  ]}
                >
                  {hotelBookingInfo.totalGuestBreakupText}
                </Text>
              )}
            </View>
            {showArrows && changeGuestAction && (
              <TouchableOpacity
                onPress={() => handleTopCardAction(card, changeGuestAction, actionHandler)}
              >
                <Text style={[AtomicCss.azure, AtomicCss.font11, fonts.blackFontFamily]}>
                  {changeGuestAction.actionLabeltext}
                </Text>
              </TouchableOpacity>
            )}
          </View>
          {!isEmpty(hotelBookingInfo.benefitClaimDetails) &&
            !isEmpty(hotelBookingInfo.benefitClaimDetails.description) && (
              <View style={[AtomicCss.flexRow, styles.benefitClaimWrapper]}>
                {!!hotelBookingInfo.benefitClaimDetails.iconUrl && (
                  <Image
                    source={{ uri: hotelBookingInfo.benefitClaimDetails.iconUrl }}
                    style={styles.benefitClaimIconStyle}
                  />
                )}
                <View style={AtomicCss.flex1}>
                  <Text
                    style={[
                      styles.benefitClaimText,
                      fsStyle.font14,
                      AtomicCss.lineHeight14,
                      fonts.regularFontFamily,
                    ]}
                  >
                    {hotelBookingInfo.benefitClaimDetails.description}
                  </Text>
                </View>
              </View>
            )}
        </View>

        {getPrimaryCustomerName(response) && (
          <View
            style={[
              AtomicCss.flexRow,
              AtomicCss.alignCenter,
              AtomicCss.spaceBetween,
              AtomicCss.marginBottom12,
              styles.paddingLR12,
            ]}
          >
            <View style={AtomicCss.flex1}>
              <Text
                style={[
                  AtomicCss.midGreyText,
                  AtomicCss.font12,
                  AtomicCss.alignLeft,
                  AtomicCss.lineHeight20,
                  AtomicCss.boldFont,
                ]}
              >
                {staticData.hotelMainCardText.additionalText.primaryGuest}
              </Text>
              <Text
                style={[
                  AtomicCss.midGreyText,
                  AtomicCss.font16,
                  AtomicCss.blackFont,
                  AtomicCss.blackText,
                  AtomicCss.lineHeight20,
                  AtomicCss.marginTop2,
                  AtomicCss.alignLeft,
                ]}
              >
                {getPrimaryCustomerName(response)}
              </Text>
            </View>
            {showArrows && changePrimaryGuestNameAction && (
              <TouchableOpacity
                onPress={() =>
                  handleTopCardAction(card, changePrimaryGuestNameAction, actionHandler)
                }
              >
                <Text style={[AtomicCss.azure, AtomicCss.font11, fonts.blackFontFamily]}>
                  {changePrimaryGuestNameAction.actionLabeltext}
                </Text>
              </TouchableOpacity>
            )}
          </View>
        )}
      </>
    );
  }

  return (
    <View> 
    <View style={[styles.topWrapper, styles[`topWrapper${suffixStyle}`]]}>
      <View style={styles.hotelInfoCard}>
      {response.isModifiedNew &&
        !_.isEmpty(response.modifiedBookingMsg) &&
        isUpcomingOrOnTripBooking(response) &&
        renderModificationBanner(response.modifiedBookingMsg)}

      <HotelTopCard
        rating={hotelDetailList.hotelCategoryCode}
        category={hotelDetailList.hotelCategory}
        showAltAccoRating={hotelDetailList.showAltAccoRating}
        propertyName={hotelDetailList.name}
        hotelDestinationText={hotelDetailList.hotelDestinationText}
        imgSrc={
          isNullOrEmpty(hotelDetailList.hotelImageURLList) &&
          isNullOrEmpty(hotelDetailList.imageURL)
            ? HOTEL_DEFAULT_ICON
            : { uri: showHotelImage(hotelDetailList) }
        }
        mmtvalueStays={response.mmtvalueStays}
        mmtValueStayImgUrl={response.mmtValueStayImgUrl}
        corporateMMTValueStayImgUrl={response.corporateMMTValueStayImgUrl}
        mySafety={hotelDetailList.mySafety}
        response={response}
        showHotelDetails={showDatesAndRoomDetails}
        card={card}
        pageName={pageName}
        bookingStatus={response.bookingStatus}
        handlePAHCard={handlePAHCard}
        handleMessageHost={handleMessageHost}
        showBHFBanner={showBHFBanner}
        showWriteReviewBanner={showWriteReviewBanner}
        showBanner={showBanner}
        bhfBanner={bhfBanner}
        topStatusColorStyle={topStatusColorStyle}
        instructions={instructions}
        title={title}
        onBHFBannerClick={onBHFBannerClick}
        onWriteAReviewClick={onWriteAReviewClick}
      />
      </View>
    </View>

        <View style={[styles.BookingWhiteCard, bookingCardStyle]}>
          <View style={AtomicCss.alignItemsStart}><Text style={[AtomicCss.blackText,AtomicCss.font16,AtomicCss.lineHeight16,AtomicCss.blackFont,styles.headingText]}>{staticData.hotelMainCardText.additionalText.bookingDetails}</Text></View>
          {showDatesAndRoomDetails && renderCheckinCheckoutDetails()}
          {flexibleCheckInFlag && !isEmpty(flexibleCheckIn) && renderFlexiCheckInDetails(flexibleCheckIn)}
          {showDatesAndRoomDetails && renderRoomDetails()}

          {bottomActionCard &&
              isNotNullAndEmpty(bottomActionCard.actionList) &&
              modifyActionType(bottomActionCard) && (
          <View
            style={[
              AtomicCss.flexRow,
              AtomicCss.spaceBetween,
              AtomicCss.flex1,
              AtomicCss.flexWrap,
              AtomicCss.marginBottom12,
              styles.paddingLR12,
              styles.borderTop
            ]}
          >

            {getActionButton(bottomActionCard, response, handleClick)}
          </View>
        )}
      </View>

      {response.isExchangedOld &&
        renderBottomInfoBanner(staticData.hotelMainCardText.additionalText.oldBookingText)}
    </View>
  );
};

export default BookingWhiteCard;
