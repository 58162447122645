import {NativeModules} from 'react-native';
import {_getLangCookie, openURL, _getMyBusinessSubscriptionCookie, _getCCDECookie} from "../webUtils";
import {Font, LanguageOptions} from "../const";

const openDialer = (number) => {
    window.location = 'tel:' + number;
}

const openDeepLink = (deeplink, target) => {
    openURL(deeplink, target);
}

const getFont = () => {
    const cookie = _getLangCookie();
    return LanguageOptions[cookie] ? LanguageOptions[cookie].font : Font.LATO;
}

const checkMyPartner = () => {
    const cookieVal = _getMyBusinessSubscriptionCookie();
    return (cookieVal !== null) && (cookieVal === 'myPartner');
}

const checkIndianRegion = () => {
    const cookieVal = _getCCDECookie();
    return (cookieVal !== null) && (cookieVal?.toLowerCase() === 'in');
}

const checkPersonalBooking = () => {
    const cookieVal = _getMyBusinessSubscriptionCookie();
    return !cookieVal || cookieVal === 'b2c';
}

NativeModules.GenericModule = {
    openDialer,
    openDeepLink,
    getFont,
    checkMyPartner,
    checkIndianRegion,
    checkPersonalBooking
};
