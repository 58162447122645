import { StyleSheet } from "react-native";

export default (theme, fonts) => StyleSheet.create( {
  FlightInfoTopSection: {
    paddingHorizontal: 15,
    paddingTop: 12,
    paddingBottom: 5,
  },
  FltDestTxt: {
    fontSize: fonts.fontSize.font18,
    color: '#000',
    ...fonts.mediumFontFamily,
  },
  FltDate: {
    fontSize: fonts.fontSize.font12,
    color: theme.color.defaultTextColor,
  },
  BlackArrow: {
    width: 13,
    height: 12,
    marginTop: 5,
    marginHorizontal: 7,
  },
  FltTime: {
    fontSize: fonts.fontSize.font12,
    color: theme.color.black2f,
  },
  FltStop: {
    fontSize: fonts.fontSize.font12,
    color: theme.color.lightTextColor,
    ...fonts.boldFontFamily,
    alignSelf: 'flex-end',
  },
  FltDateInfo: {
    fontSize: fonts.fontSize.font12,
    color: theme.color.lightTextColor,
  },
  fltInfoInnerDtls: {
    borderLeftColor: theme.color.gainsBoro,
    borderLeftWidth: 2,
    paddingLeft: 10,
    marginLeft: 10,
    position: 'relative',
  },
  fltAmentiesWrapper: {
    borderWidth: 1,
    borderColor: theme.color.gainsBoro,
    borderRadius: 2,
    minHeight: 50,
    marginBottom: 10,
  },
  FltAmentiesTopSection: {
    padding: 8,
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  FltAmentiesCollapsedSection: {
    padding: 8,
    borderTopColor: theme.color.gainsBoro,
    borderTopWidth: 1,
    flexDirection: 'row',
  },
  Flt_Icon: {
    width: 39,
    height: 39,
    marginRight: 6,
  },
  fltName: {
    color: theme.color.darkGrey3,
    fontSize: fonts.fontSize.font12,
  },
  fltCode: {
    color: theme.color.darkGrey3,
    fontSize: fonts.fontSize.font12,
  },
  CollapsedArrow: {
    width: 11,
    height: 7,
  },
  MealIcon: {
    width: 16,
    height: 13,
    marginRight: 5,
    marginTop: 2,
  },
  WifiIcon: {
    width: 14,
    height: 10,
    marginRight: 5,
    marginTop: 3,
  },
  fltInfoCircleTop: {
    width: 6,
    height: 6,
    backgroundColor: theme.color.black2f,
    borderRadius: 10,
    position: 'absolute',
    left: 8,
    zIndex: 100,
    top: 1,
  },
  fltInfoCircleCenter: {
    left: -14,
    backgroundColor: '#c8c8c8',
  },
  fltInfoCircleBottom: {
    width: 6,
    height: 6,
    backgroundColor: theme.color.black2f,
    borderRadius: 10,
    position: 'absolute',
    left: 8,
    zIndex: 100,
    bottom: 0,
  },
  flexRow: {
    flexDirection: 'row',
  },
  marginBottom10: {
    marginBottom: 10,
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
  marginBottom20: {
    marginBottom: 20,
  },
  boldText: {
    ...fonts.boldFontFamily,
  },
  font11: {
    fontSize: fonts.fontSize.font11,
  },
  LightText: {
    color: theme.color.lightTextColor,
  },
  marginRight20: {
    marginRight: 20,
  },
  whiteCard: {
    backgroundColor: theme.color.white,
    padding: 15,
    marginVertical: 8,
    elevation: 2,
    shadowColor: theme.color.black,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.3,
    shadowRadius: 1,
  },
  hidden: {
    display: 'none',
  },
  font12: {
    fontSize: fonts.fontSize.font12,
  },
  flex1: {
    flex: 1,
  },
  operatedByText: {
    color: theme.color.gray35,
    fontSize: fonts.fontSize.font10,
    marginLeft: 5,
    marginRight: 150,
    alignSelf: 'center',
  },
});
