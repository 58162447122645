import {StyleSheet} from 'react-native';
export default (theme, fonts) => 
StyleSheet.create({
    cardHead: {
      fontSize: fonts.fontSize.font21,
      color: theme.color.defaultTextColor,
      ...fonts.lightFontFamily,
      marginBottom: 16,
    },
    gsttxtheading: {
      fontSize: fonts.fontSize.font11,
      color: theme.color.defaultTextColor,
      ...fonts.mediumFontFamily,
      marginBottom: 5,
    },
    gsttxtinfo: {
      fontSize: fonts.fontSize.font14,
      color: theme.color.black,
      ...fonts.boldFontFamily,
    },
  }
);

