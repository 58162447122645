import React from 'react';
import {View, Text, StyleSheet} from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import LinearGradient from 'react-native-linear-gradient';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';

const OverNightTag = ({tagText}) => (
     (
    <View style={[AtomicCss.alignItemsStart]}>
      <LinearGradient style={styles.overnightWrapper} start={{ x: 1.0, y: 0.0 }} end={{ x: 0.0, y: 1.0 }} colors={[colors.blackStartColor, colors.blackEndColor]}>
        <Text style={[AtomicCss.whiteText, AtomicCss.boldFont, AtomicCss.font10]}>{tagText}</Text>
      </LinearGradient>
    </View>
  ));

const styles = StyleSheet.create({
    overnightWrapper:{
        paddingHorizontal: 8,
        paddingVertical: 2,
        borderRadius: 8,
        marginTop: 3,
        marginBottom: -7,
      }
});

export default OverNightTag;