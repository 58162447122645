import React, { useState, useEffect, useRef } from 'react';
import {
  BackHandler,
  Image,
  Platform,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
  Animated,
} from 'react-native';
import Header from '../../../Common/HeaderCross';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import CabInfo from './CabInfo';
import ExclusionTab from './Exclusions';
import InclusionsTab from './Inclusions';
import { getStyles } from './CabUpgradeStyle';
import LinearGradient from 'react-native-linear-gradient';
import BlackFooter from './BlackFooter';
import CabBreadCumb from './CabBreadCumb';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import { CAB_BOOKING_DETAILS_URL, getCommonHeaders } from '../../../utils/NetworkUtils';
import ProgressView from '@mmt/legacy-commons/Common/Components/Progress/ProgressView';
import { getPaymentOptionData, getCommitRequest } from './CancelAndRebookUtill';
import { isNetworkAvailable } from '@mmt/legacy-commons/Common/utils/AppUtils';
import * as NetworkUtils from '../../../utils/NetworkUtils';
import Actions from '../../../navigation/postSalesNavigation';
import { CancelAndRebookConstants } from './CancelAndRebookConstants';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import PaymentModule from '@mmt/legacy-commons/Native/PaymentModule';
import _ from 'lodash';
import { openCabBookingDetail } from '../../utils/CabBookingDetailUtil';
import RoundTripInfo from './RoundTripInfo';
import { getRootTag } from '@mmt/legacy-commons/AppState/RootTagHolder';
import { cancelIcon } from '../../CabImageConstants';
import NoInternetView from '@mmt/legacy-commons/Common/Components/Error/NoInternetView';
import CabBookingTrackingHelper from '../../CabBookingTrackingHelper';
import CabBookingDetailsConstant, { CLICK_EVENT, OVERLAY_TYPE, PAGE_NAME } from '../../CabBookingDetailsConstant';
import PaymentOptionCard from './PaymentOptions';
import { getFont } from '../../../PostSalesStyles';
import { useTheme } from '../../../theme/theme.context';
import CommonBottomOverlayMessage from '../../../Common/CommonOverlayMessage/CommonBottomOverlayMessage';
import { getStaticData } from 'apps/post-sales/src/staticData/staticData';
import HeaderWrapper from 'apps/post-sales/src/Common/HeaderWrapper';


const CabDetailReview = (props) => {
  const [viewState, setViewState] = useState(ViewState.DEFAULT);
  const [commitRequest, setCommitRequest] = useState(null);
  const [globalbody, setGlobalBody] = useState(null);
  const [footerData, setFooterData] = useState(
    props.carDetail?.paymentOptionList?.[0]?.paymentStickyFooterData,
  );
  const [optionSelected, setOptionSelected] = useState(props.carDetail?.paymentOptionList?.[0]);
  const [overlay, setOverlay] = useState(null);
  const isPaymentSelected = useRef(false);

  const { fsStyle, ...fonts } = getFont();
  const { psTheme: theme } = useTheme();
  const styles = getStyles(fonts, theme);
  const { color } = theme;
  const newNavigation = props.newNavigation;

  useEffect(() => {
    BackHandler.addEventListener('hardwareBackPress', onBackPress);
    if(Platform.OS === 'ios'){
      newNavigation?.addListener('beforeRemove', preventSwipeBack);
    }
    return () => {
      BackHandler.removeEventListener('hardwareBackPress', onBackPress);
      if(Platform.OS === 'ios'){
        newNavigation?.removeListener('beforeRemove');
      }
    };
  }, [overlay]);

  const preventSwipeBack = (e) => {
    e.preventDefault();
    newNavigation?.removeListener('beforeRemove');
    return onBackPress()
  }

  const handlePaymentSelect = (data) => {
    const {id="", paymentStickyFooterData={}} = data || {};
    let paymentOption = CLICK_EVENT.PAYMENT_OPTION_SELECTED_CLICK;
    switch(id){
      case "commitPay_Partial":
      paymentOption = CLICK_EVENT.PARTIAL_PAYMENT_OPTION_SELECTED_CLICK;
      break;

      case "commitPay_Full":
      paymentOption = CLICK_EVENT.FULL_PAYMENT_OPTION_SELECTED_CLICK;
      break;

      case "Refund_To_Customer":
      paymentOption = CLICK_EVENT.REFUND_TO_ORIGINAL_MODE_CLICK;
      break;

      case "Refund_To_Wallet":
      paymentOption = CLICK_EVENT.REFUND_TO_WALLET_CLICK;
      break;
    }

    CabBookingTrackingHelper.trackClickEvent(
      PAGE_NAME.MODIFY_BOOKING,
      paymentOption,
    )
    isPaymentSelected.current = true;
    setOptionSelected(data);
    setFooterData(paymentStickyFooterData);
  };

  const handleOverlay = (overlay = null) => {
    setOverlay(overlay);
  };

  const enrichCommitRequest = (prevRequest, carDetails, searchId) => {
    return [{
      ...prevRequest,
      cabChange: {
        key: carDetails.id,
        searchId: searchId,
      },
    }]
  };

  const handleClick = () => {
    if (!isPaymentSelected.current && overlay !== OVERLAY_TYPE.PAYMENT_OPTION) {
      handleOverlay(OVERLAY_TYPE.PAYMENT_OPTION);
    } else {
      CabBookingTrackingHelper.trackClickEvent(
        PAGE_NAME.MODIFY_BOOKING,
        CLICK_EVENT.PAYMENT_CTA_CLICK,
      );
      const enrichedRequest = enrichCommitRequest(
        props.prevRequest,
        props.carDetail,
        props.rawData.newCarData.searchId,
      );
      setCommitRequest(getCommitRequest(optionSelected, enrichedRequest));
      setViewState(ViewState.COMMIT);
    }
  };

  const openPaymentPage = (request, response, headerProps, cab , tag, url) => {
    const cabData = cab || {};
    const paymentRequest = {
      lob: 'CAB',
      request: request,
      response: response,
      tripHeaderData: headerProps,
      cab: cabData,
      rootTag: getRootTag(),
      omnitureData: {},
    };
    if (Platform.OS === 'ios') {
      PaymentModule.openPaymentPage(paymentRequest, getRootTag());
    } else if (Platform.OS === 'android') {
      PaymentModule.openPaymentPage(paymentRequest);
    } else {
      GenericModule.openDeepLink(url);
    }
  };

  const fetchJSONAsync = async () => {
    try {
      const hasNetwork = await isNetworkAvailable();
      if (!hasNetwork) {
        setViewState(ViewState.NO_INTERNET);
        return;
      }
      const url = NetworkUtils.CANCEL_AND_REBOOK_COMMIT_URL;
      const { mmtAuth, loggingTrackingInfo } = await HotelBookingModule.getRequestHeader(
        props.bookingId,
        'Cab_cancelAndRebook',
      );
      setViewState(ViewState.LOADING);
      const response = await fetch(url, {
        method: CancelAndRebookConstants.POST,
        headers: await getCommonHeaders(mmtAuth, loggingTrackingInfo),
        body: JSON.stringify(commitRequest),
      });
      if (response.ok) {
        const body = await response.json();
        if (body.success === true) {
          if (!_.isEmpty(body.paymentCheckoutUrl) && body.commitHold) {
            const request = body?.paymentinfo?.request;
            const response = body?.paymentinfo?.response;
            const headerProps = body?.paymentinfo?.header;
            const tag = body?.paymentinfo?.tag;
            const cab = body?.paymentinfo?.cab;
            const url = body?.paymentCheckoutUrl;
            openPaymentPage(request, response, headerProps, cab, tag, url);
            setViewState(ViewState.DEFAULT);
            setOverlay(null);
          } else {
            setGlobalBody(body);
            setViewState(ViewState.COMMIT_SUCCESS);
          }
        } else {
          setGlobalBody(body);
          setViewState(ViewState.COMMIT_FAIL);
        }
      } else {
        setViewState(ViewState.ERROR);
      }
    } catch (error) {
      setViewState(ViewState.ERROR);
    }
  };

  const renderThankYou = () => {
    const { viewUpdatedBookingText } = getStaticData(false);
    return !globalbody.commitDone && !globalbody.commitHold ? (
      <View style={styles.thankYouWrapper}>
        <Image style={styles.successIcon} source={cancelIcon} />
        <Text style={styles.messageTitle}>{globalbody.errorText}</Text>
        <Text style={styles.messageSubtitle}>{globalbody.errorSubtext}</Text>
        <TouchableOpacity
          style={[styles.CommonFillBtn, styles.transparantBgrnd]}
          onPress={() => openBooking(globalbody.ctaRedirectionUrl, globalbody.newBookingId)}
        >
          <LinearGradient
            start={{
              x: 1.0,
              y: 0.0,
            }}
            end={{
              x: 0.0,
              y: 1.0,
            }}
            colors={[color.darkBlue, color.lightBlue]}
            style={styles.CommonFillBtnGradient}
          >
            {!_.isEmpty(globalbody.ctaText) ? (
              <Text style={[styles.CommonFillBtnText, styles.transparantBgrnd]}>
                {globalbody.ctaText}
              </Text>
            ) : (
              <Text style={[styles.CommonFillBtnText, styles.transparantBgrnd]}>
                {viewUpdatedBookingText}
              </Text>
            )}
          </LinearGradient>
        </TouchableOpacity>
      </View>
    ) : (
      <View style={styles.thankYouWrapper}>
        <Image style={styles.successIcon} source={cancelIcon} />
        <Text style={styles.messageTitle}>{globalbody.thankyouText}</Text>
        <Text style={styles.messageSubtitle}>{globalbody.thankyouSubtext}</Text>
        <TouchableOpacity
          style={[styles.CommonFillBtn, styles.transparantBgrnd]}
          onPress={() => openBooking(globalbody.ctaRedirectionUrl, globalbody.newBookingId)}
        >
          <LinearGradient
            start={{
              x: 1.0,
              y: 0.0,
            }}
            end={{
              x: 0.0,
              y: 1.0,
            }}
            colors={[color.darkBlue, color.lightBlue]}
            style={styles.CommonFillBtnGradient}
          >
            {!_.isEmpty(globalbody.ctaText) ? (
              <Text style={[styles.CommonFillBtnText, styles.transparantBgrnd]}>
                {globalbody.ctaText}
              </Text>
            ) : (
              <Text style={[styles.CommonFillBtnText, styles.transparantBgrnd]}>
                {viewUpdatedBookingText}
              </Text>
            )}
          </LinearGradient>
        </TouchableOpacity>
      </View>
    );
  };

  const openBooking = (url, newBookingId) => {
    CabBookingTrackingHelper.trackClickEvent(PAGE_NAME.MODIFY_BOOKING_COMMIT, CLICK_EVENT.VIEW_UPDATED_BOOKING);
    if (!_.isEmpty(url) && Platform.OS === 'ios') {
      GenericModule.openDeepLink(url);
    } else {
      openCabBookingDetail(newBookingId).then((r) => {});
    }
  };

  const renderCommit = () => {
    fetchJSONAsync();
  };

  const renderNoNetworkView = () => (
    <NoInternetView
      onRetry={() => {
        setViewState(ViewState.LOADING);
        fetchJSONAsync(CAB_BOOKING_DETAILS_URL + props.bookingId);
      }}
    />
  );

  const renderProgressView = () => {
    const { loadingText = 'Loading...' } = getStaticData(false);
    return <ProgressView message={loadingText} />;
  };

  const renderErrorView = () => (
    <ErrorView
      showRetry
      onRetry={() => {
        setViewState(ViewState.LOADING);
        fetchJSONAsync(CAB_BOOKING_DETAILS_URL + props.bookingId);
      }}
    />
  );

  const onBackPress = () => {
    if (!!overlay) {
      setOverlay(null);
    } else {
      Actions.pop();
    }
    return true;
  };

  const openPaymentinfoOverlay = () => {
    const paymentOption = getPaymentOptionData(
      props.carDetail,
      props.rawData.oldCarData,
      props.rawData.priceList,
    );
    Actions.cabCancelRebookPriceBreakup({ paymentOption });
  };

  const isRoundTrip = (inclusionList) => {
    const inclusion =
      !_.isEmpty(inclusionList) &&
      inclusionList.filter(
        (inclusion) => inclusion === CancelAndRebookConstants.ONLY_ONE_PICKUP_AND_DROP,
      );
    if (_.isEmpty(inclusion)) {
      return false;
    } else {
      return true;
    }
  };

  const renderPreview = () => {
    const { paymentOptionList, freeCancellationText,  ctaKey} = props.carDetail;
    return (
      <View style={[AtomicCss.greyBg, AtomicCss.flex1]}>
      <HeaderWrapper
          titleText={CancelAndRebookConstants.MODIFY_BOOKING_HEADING}
          clickHandler={onBackPress}
      />


        <CabBreadCumb state={CancelAndRebookConstants.REVIEW_TEXT} />
        <ScrollView>
          {isRoundTrip(props.carDetail.inclusions) ? (
            <CabInfo
              isTripDistanceCalculated={false}
              modifiedTripDetails={props.rawData.modifiedTripData}
              cabDetails={props.carDetail}
            />
          ) : (
            <RoundTripInfo
              modifiedTripDetails={props.rawData.modifiedTripData}
              cabDetails={props.carDetail}
            />
          )}
          <View style={[styles.collapsableWrap, AtomicCss.whiteCard]}>
            <Text style={[AtomicCss.blackText, AtomicCss.font16, AtomicCss.blackFont]}>
              {props.rawData.newCarData.reviewHeading}
            </Text>
            <Text style={[AtomicCss.yellowText, AtomicCss.font12, AtomicCss.blackFont]}>
              {props.rawData.newCarData.reviewSubheading}
            </Text>
            {props.carDetail.cancellationPolicy &&
              !_.isEmpty(props.carDetail.cancellationPolicy) && (
                <InclusionsTab
                  heading={props.rawData.newCarData.cancellationHeading}
                  inclusionData={props.carDetail.cancellationPolicy}
                  type={'cancellationPolicy'}
                />
              )}
            {props.carDetail.inclusions && !_.isEmpty(props.carDetail.inclusions) && (
              <InclusionsTab
                heading={props.rawData.newCarData.inclusionHeading}
                inclusionData={props.carDetail.inclusions}
                type={'inclusion'}
              />
            )}
            {props.carDetail.exclusions && !_.isEmpty(props.carDetail.exclusions) && (
              <ExclusionTab
                heading={props.rawData.newCarData.exclusionHeading}
                exclusionData={props.carDetail.exclusions}
                isLast={true}
              />
            )}
            {props.carDetail.extraCharges && !_.isEmpty(props.carDetail.extraCharges) && (
              <InclusionsTab
                heading={props.rawData.newCarData.extraChargesHeading}
                inclusionData={props.carDetail.extraCharges}
                isLast={true}
                type={'extraCharges'}
              />
            )}
          </View>
          <PaymentOptionCard
            paymentOptionList={paymentOptionList}
            handlePaymentSelect={handlePaymentSelect}
            optionSelected={optionSelected}
            isOverlay={false}
            heading={
              ctaKey === 'refund'
                ? CabBookingDetailsConstant.refundOptionsText
                : CabBookingDetailsConstant.paymentOptionsText
            }
          />
        </ScrollView>
        {
          <BlackFooter
            onConfirmation={handleClick}
            carDetail={props.carDetail}
            openPriceBreakup={openPaymentinfoOverlay}
            paymentStickyFooterData={footerData}
            freeCancellationText={freeCancellationText}
            handleOverlay={handleOverlay}
            overlay={overlay}
          />
        }
         {overlay === OVERLAY_TYPE.PAYMENT_OPTION && (
          <CommonBottomOverlayMessage
            dismiss={handleOverlay}
            isBorderRadius={false}
            overlayWrapperStyle={styles.overlayWrapperStyle}
            content={
              <PaymentOptionCard
                paymentOptionList={paymentOptionList}
                handlePaymentSelect={handlePaymentSelect}
                optionSelected={optionSelected}
                isOverlay={true}
                heading={
                  ctaKey === 'refund'
                    ? CabBookingDetailsConstant.refundOptionsText
                    : CabBookingDetailsConstant.paymentOptionsText
                }
              />
            }
          ></CommonBottomOverlayMessage>
        )}
      </View>
    );
  };

  return (
    <View style={[AtomicCss.flex1]}>
      {viewState === ViewState.ERROR && renderErrorView()}
      {viewState === ViewState.NO_INTERNET && renderNoNetworkView()}
      {viewState === ViewState.LOADING && renderProgressView()}
      {viewState === ViewState.DEFAULT && renderPreview()}
      {viewState === ViewState.COMMIT && renderCommit()}
      {viewState === ViewState.COMMIT_SUCCESS && renderThankYou()}
      {viewState === ViewState.COMMIT_FAIL && renderErrorView()}
    </View>
  );
};

export default CabDetailReview;
