import { StyleSheet } from 'react-native';

export default (theme, fonts) => StyleSheet.create({
  cardWrapper: {
    backgroundColor: theme.color.white,
    elevation: 2,
    shadowColor: theme.color.black,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 2,
    borderRadius: 4,
    marginBottom: 10,
  },
  headerWrapper: {
    elevation: 1,
    shadowColor: theme.color.black,
    shadowOffset: { width: 0, height: 0.5 },
    shadowOpacity: 0.3,
    shadowRadius: 2,
    backgroundColor: theme.color.white,
    marginBottom: 2,
    padding: 15,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  destRow: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap:'wrap',
  },
  cityText: {
    fontSize: fonts.fontSize.font16,
    color: theme.color.black28,
    ...fonts.boldFontFamily,
  },
  arrowIcon: {
    width: 10,
    height: 7,
    marginHorizontal: 5,
  },
  smallText: {
    ...fonts.fsStyle.font10,
    color: theme.color.defaultTextColor,
    ...fonts.semiBoldFontFamily,
    marginTop: 5,
    marginBottom: 5,
  },
  cardBody: {
    padding: 15,
  },
  dtCrdWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 1,
  },
  dateWrapper: {
    flexDirection: 'row',
    flex: 0.8,
  },
  dateText: {
    color: theme.color.azure,
    fontSize:  fonts.fontSize.font36,
    ...fonts.boldFontFamily,
    marginRight: 10,
  },
  smallBlueText: {
    color: theme.color.azure,
    ...fonts.fsStyle.font12,
    ...fonts.regularFontFamily,
    marginBottom: 2,
  },
  dateLabel: {
    color: theme.color.disabledBtnBg,
    fontSize:  fonts.fontSize.font12,
    ...fonts.regularFontFamily,
    marginBottom: 3,
  },
  clearBold: {
    ...fonts.boldFontFamily
  },
  clearWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 5,
  },
  errorInfoTxt: {
    fontSize:  fonts.fontSize.font12,
    color: theme.color.red,
    ...fonts.italicFontFamily,
  },
  tripComInfoTxt: {
    fontSize:  fonts.fontSize.font12,
    color: theme.color.greyLight,
    ...fonts.regularFontFamily,
  },
  notAllowed: {
    color: theme.color.greyLight,
  },
  cityTextdisable: {
    opacity: 0.3,
  },
  arrowIcondisable: {
    opacity: 0.3,
  },
  smallTextdisable: {
    opacity: 0.3,
  },
  alreadyCancelledSction: {
    borderBottomWidth: 1,
    borderBottomColor: theme.color.lightGrey,
    paddingBottom: 16,
    marginBottom: 15,
  },
  title: {
    ...fonts.blackFontFamily,
    color: theme.color.greyLight,
    fontSize: fonts.fontSize.font12,
    marginBottom: 15,
  },
  desc: {
    ...fonts.regularFontFamily,
    color: theme.color.greyLight,
    fontSize:  fonts.fontSize.font12,
    marginBottom: 8,
  },
  linkTxt: {
    color: theme.color.azure,
    ...fonts.boldFontFamily,
    fontSize:  fonts.fontSize.font12,
  },
  boldFont: {
    ...fonts.boldFontFamily,
  },
  lastItem: {
    paddingBottom: 0,
    borderBottomWidth: 0,
    borderBottomColor: 'transparent',
    marginBottom: 0,
  },
  phoneIcon: {
    width: 12,
    height: 12,
    marginHorizontal: 5,
  },
  smallBlueTextCorp:{
    color: theme.color.yellow2,
  },
  submitRequestWrapper: {
    padding: 10,
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },
});
