import { HOTEL_DOWNLOAD_VOUCHER, HOTEL_MYPARTNER_VOUCHER, HOTEL_DOWNLOAD_CUSTOMER_VOUCHER, HOTEL_DOWNLOAD_BILL_MEMO,HOTEL_DOWNLOAD_GST_INVOICE } from "../../utils/NetworkUtils";

const HotelBookingDetailsConstant = {
  TOP_STATUS_CARD_12_HRS_AFTER_BOOKING: 'TopStatusCard_BookingTo12HrsAfterBooking',
  TOP_STATUS_CARD_48_HRS_BEFORE_TRAVEL: 'TopStatusCard_AfterBookingTo48HrsBeforeTravel',
  TOP_STATUS_CARD_48_TO_24_HRS_BEFORE_TRAVEL: 'TopStatusCard_48HrsBeforeTravelTo24HrsBeforeTravel',
  TOP_STATUS_CARD_24_HRS_TO_ON_TRIP: 'TopStatusCard_24HrsBeforeTravelToOnTrip',
  TOP_STATUS_CARD_ON_TRIP: 'TopStatusCard_OnTrip',
  TOP_STATUS_CARD_AFTER_CHECKOUT_TO_24_HOURS_AFTER_CHECKOUT: 'TopStatusCard_AfterCheckOutTo24HrsAfterCheckout',
  TOP_STATUS_CARD_PARTIALLY_CANCELLED_ATLEAST_1_UPCOMING: 'TopStatusCard_PartiallyCancelledAtLeast1Upcoming',
  TOP_STATUS_CARD_24_HRS_AFTER_CHECKOUT_TO_FOREVER: 'TopStatusCard_24HrsAfterCheckoutToForever',
  TOP_STATUS_CARD_CAN_INIT_TO_REFUND_DONE: 'TopStatusCard_BetweenCanInitToRefundDone',
  TOP_STATUS_CARD_AFTER_REFUND_DONE: 'TopStatusCard_AfterRefundDone',
  TOP_STATUS_CARD_PARTIALY_CAN_AT_LEAST_1_UPCOMING: 'TopStatusCard_PartiallyCancelledAtLeast1Upcoming',
  TOP_STATUS_CARD_PARTIALY_CAN_AT_REST_ALL_COMPLETED: 'TopStatusCard_PartiallyCancelledRestAllCompleted',
  TOP_STATUS_CARD_LOST_FAILED_BOOKING: 'TopStatusCard_LostFailedBooking',
  TOP_STATUS_CARD_LOST_FAILED_BOOKING_CANCELLED: 'TopStatusCard_LostFailedBookingCancelled',
  TOP_STATUS_CARD_JUST_AFTER_MODIFICATION: 'TopStatusCard_JustAfterModification',
  TOP_STATUS_CARD_MODIFIED_BOOKING_ON_TRIP: 'TopStatusCard_ModifiedBookingOnTrip',
  TOP_STATUS_CARD_MODIFIED_BOOKING_TRIP_COMPLETED: 'TopStatusCard_ModifiedBookingTripCompleted',
  TOP_STATUS_CARD_OLD_EXCHANGED_CANCELLED_TRIP: 'TopStatusCard_OldExchangedCancelledTrip',
  TOP_STATUS_CARD_CTRIP_CARD: 'Top_Status_Card_Ctrip_Card',
  TOP_STATUS_CARD_RTB_AWAITED: 'TopStatusCard_RTBAwaited',
  TOP_STATUS_CARD_GROUP_BOOKING_RESERVED: 'TopStatusCard_GroupBooking_Reserved',
  TOP_STATUS_CARD_RTB_REJECTED: 'TopStatusCard_RTBRejected',
  HOTEL_MAIN_CARD: 'HotelMainCard',
  CROSS_LOB_BENEFIT_CARD: 'CrossLobBenefitCard',
  HOTEL_STAY_DATES_CARD: 'HotelStayDatesCard',
  HOTEL_GUEST_NAMES_CARD: 'HotelGuestNamesCard',
  HOTEL_ROOM_DETAIL_CARD: 'HotelRoomDetailsCard',
  CHANGE_IN_PLAN_CARD: 'ChangeInPlansCard',
  IMPORTANT_INFO_CARD: 'ImportantInfoCard',
  PENDING_PAYMENT_CARD: 'PendingPaymentCard',
  BHF_ENTRY_CARD: 'BHFEntryCard',
  HOTEL_CANCELLATION_POLICY_CARD: 'HotelCancellationPolicyCard',
  HOTEL_GB_MODIFICATION_CARD: 'GroupBookingModificationCard',
  HOTEL_LATE_CHECK_IN_CARD: 'HotelLateCheckInCard',
  TRIP_VIEW_CARD: 'TripViewCard',
  TRIP_VIEW_CARD_SHOWN: 'tripView_card_shown',
  HOTEL_PAYMENT_CARD: 'HotelPaymentCard',
  CHAT_WITH_HOST_CARD: 'ChatWithHostCard',
  SPECIAL_REQUESTS_CARD: 'SpecialRequestsCard',
  HOTEL_AMENITIES_CARD: 'HotelAmenitiesCard',
  GETTING_THERE_CARD: 'GettingThereCard',
  RATE_YOUR_STAY_CARD: 'RateYourStayCard',
  PROMISE_REFUND_CARD: 'PromiseRefundCard',
  REGULAR_REFUND_CARD: 'RegularRefundCard',
  PROPERTY_LAYOUT_CARD: 'PropertyLayoutCard',
  EXPEDIA_CHAT_CARD: 'ExpediaChatCard',
  REFUND_CARD: 'RefundCard',
  MODIFICATION_AMOUNT_CARD: 'ModificationAmountCard',
  MODIFICATION_DETAIL_CARD: 'ModificationDetailsCard',
  CHOOSE_REFUND_MODE_CARD: 'ChooseRefundModeCard',
  NEED_HELP_CARD: 'NeedHelpCard',
  MAKE_REQUEST_CARD: 'MakeaRequestCard',
  IMPORTANT_INFORMATION_CARD: 'ImportantInformationCard',
  NEED_MORE_HELP_CARD: 'NeedMoreHelpCard',
  HOTEL_ADD_TECH_CARD: 'HotelAddTechCard',
  CONTACT_US_CARD: 'ContactUsCard',
  CROSS_SELL_CARD: 'CrossSellCard',
  ADD_ONS_CARD: 'AddOnsCard',
  TRIP_SUMMARY_CARD: 'TripSummaryCard',
  OLD_EXCHANGED_BOOKING_CARD: 'OldExchangedBookingCard',
  NEED_MORE_HELP_MIMA_CARD: 'NeedMoreHelpMIMACard',
  SIMILAR_PROPERTIES_CARD: 'SimilarPropertiesCard',
  HOMESTAY_AWARDS_CARD: 'HomestayAwardsCard',
  CSAT_FEEDBACK_CARD: "CsatFeedbackCard",
  BEST_PRICE_GUARANTEE_CARD: 'BestPriceGuaranteeCard',
  INSTALLMENT_CARD: 'InstallmentCard',
  STAY_CONNECTED_CARD: 'StayConnectedCard',
  RECTANGLE_FULL_BUTTON: 'RecFullBtn',
  ROUND_BUTTON: 'RbBtnWithIcon',
  RECTANGLE_PRIMARY_FULL_WIDTH: 'CapsuleFullBtnFill',
  RECTANGLE_PRIMARY_HALF_WIDTH: 'CapsuleHalfBtnFill',
  RECTANGLE_SECONDARY_FULL_WIDTH: 'CapsuleFullBtn',
  RECTANGLE_SECONDARY_HALF_WIDTH: 'CapsuleHalfBtn',
  WHITE_BUTTON_FULL: 'CapsuleWhiteBtn',
  WHITE_BUTTON_HALF: 'CapsuleWhiteBtnHalf',
  ROUND_BUTTON_BLUE: 'RoundButton',
  ROUND_BUTTON_LIGHT_BLUE: 'RoundButtonLightBlue',
  ROUND_BUTTON_WHITE_WITH_BORDER: 'RoundButtonWhiteWithBorder',
  FULL_ROUND_BUTTON_WHITE_WITH_BORDER: 'FullRoundButtonWhiteWithBorder',
  RECTANGLE_BLUE_BUTTON: 'RecBlueBtn',
  FULL_ROUND_BUTTON_BLUE: 'FullRoundButton',
  RECTANGLE_FULL_BUTTON2: 'RectFullBtn',
  REDIRECT_LINK_ICON: 'RectsFullBtn',
  PERSISTENT_TOAST: 'PersistentToast',
  PAHX_PAY_NOW_CARD: 'PAHXPayNowPersuasionCard',
  HOTEL_CTRIP_BOOKING_AWAITED_CARD: 'HotelCtripBookingAwaitedCard',
  WHAT_HAPPENS_NEXT_CARD: 'WhatHappensNextCard',
  MONEY_BACK_GUARANTEE_CARD: 'MoneyBackGuaranteeCard',
  LOCATION_CARD: 'LocationCard',
  PAY_NOW_SHOWN: 'Pay_Now_Shown',
  PAY_NOW_WITH_BANK_OFFER_SHOWN: 'Pay_Now_Shown | Bank_Offer_Displayed',
  PAY_DIFFERENT_MODE_CLICKED: 'Pay_Different_Mode_Clicked',
  PAY_DIFFERENT_MODE_WITH_BANK_OFFER_CLICKED: 'Pay_Different_Mode_Clicked | Bank_Offer_Displayed',
  BANK_COUPON_COPIED: 'BANK_COUPON_COPIED',
  BANK_OFFER_DISPLAYED: 'Bank_Offer_Displayed',
  BANK_OFFER_POPUP_DISPLAYED: 'Bank_Offer_Popup_Displayed',
  BANK_OFFER_POPUP_CLOSED: 'Bank_Offer_Popup_Closed',
  FREE_CANCELLATION_POPUP_DISPLAYED: 'Free_Cancellation_Popup_Displayed',
  FREE_CANCELLATION_POPUP_CLOSED: 'Free_Cancellation_Popup_Closed',
  FREE_CANCELLATION_CANCEL_BOOKING_CLICKED: 'Free_Cancellation_Cancel_Booking_Clicked',
  PAY_DIFFERENT_MODE_WITH_BANK_OFFER_POPUP_CLICKED: 'Pay_Different_Mode_Clicked | Bank_Offer_Popup_Displayed',
  BPG_MIMA_CARD: 'BPGMimaCard',
  INSURANCE_OFFER_CARD: 'insuranceOfferCard',
  INSURANCE_OFFER_CARD_SHOWN: "Insurance_IH_mytrips_shown",
  INSURANCE_OFFER_CARD_CTA_CLICKED: "insurance_IH_mytrips_click",
  PRIMARY_GUEST_CARD: 'PrimaryGuestCard',
  VERIFIED_STAYS_CARD: 'VerifiedStaysCard',
  HOTEL_PHOTOGRAPHS_CARD: 'HotelPhotographsCard',
  GET_MORE_OUT_OF_YOUR_BOOKING_CARD: 'GetMoreOutOfYourBookingCard',
  CONTACTLESS_CHECKIN_CARD: 'ContactlessCheckInCard',
  INSURANCE_CARD: 'InsuranceCard',
  TRAVEL_INSURANCE_CARD: 'TravelInsuranceCard',
  CROSS_SELL_CAB_CARD: 'CrossSellCabCard',
  PAN_CARD_DETAILS: 'PanCardDetails',
  PLACEHOLDER_WALLET_AMOUNT_PAID: '<walletamountpaid>',
  PLACEHOLDER_PROMISE_END_TIME: '<promiseendtime>',
  PLACEHOLDER_EXPECTED_END_TIME: '<expectedendtime>',
  PLACEHOLDER_FINAL_TAT: '<finaltat>',
  PLACEHOLDER_WALLET_AMOUNT_ACCRUED: '<walletamountaccrued>',
  PLACEHOLDER_REQUEST_ATTRIB_ONE: '<requestAttribute1>',
  PLACEHOLDER_REQUEST_ATTRIB_TWO: '<requestAttribute2>',
  PLACEHOLDER_REQUEST_ATTRIB_THREE: '<requestAttribute3>',
  PLACEHOLDER_TOTAL_PG_DAYS: '<totalpgdays>',
  PLACEHOLDER_REFUND_AMOUNT: '<totalRefundAmount>',
  PLACEHOLDER_PROMISE_OVERDUE_TIME: '<promiseoverduetime>',
  PLACEHOLDER_PROMISE_COMPLETION_TIME: '<promisecompletiontime>',
  PLACEHOLDER_PENALTY_PER_HOUR: '<penaltyperhour>',
  PLACEHOLDER_MAX_PENALTY: '<maxpenalty>',
  UPCOMING_BOOKING_SUMMARY_CARD:'Upcoming_BookingDetailsCard',
  COMPLETED_BOOKING_SUMMARY_CARD: 'Completed_BookingDetailsCard',
  ONTRIP_BOOKING_SUMMARY_CARD: 'OnTrip_BookingDetailsCard',
  PERCENTAGE_ACTIVE_STATE : 1,
  MY_PARTNER_CUSTOMER_VOUCHER: "MY_PARTNER_CUSTOMER_VOUCHER",
  BILL_MEMO: "BILL_MEMO",
  GST_INVOICE: "GST_INVOICE",
  PROMOS_BASE_URL: "https://promos.makemytrip.com/MIMA/",

  REQUEST_CREATED: 0,
  REQUEST_OPEN_WITHIN_WARNING_TAT: 1,
  REQUEST_CLOSED_WITHIN_WARNING_TAT: 2,
  REQUEST_OPEN_BREACHED_WARNING_TAT: 3,
  REQUEST_CLOSED_BREACHED_WARNING_TAT: 4,
  REQUEST_OPEN_BREACHED_PROMISE_TAT: 5,
  REQUEST_CLOSED_BREACHED_PROMISE_TAT: 6,
  REQUEST_OPEN_BREACHED_L1_ESCALATION: 7,
  REQUEST_CLOSED_BREACHED_L1_ESCALATION: 8,
  REQUEST_OPEN_BREACHED_L2_ESCALATION: 9,
  REQUEST_CLOSED_BREACHED_L2_ESCALATION: 10,
  REQUEST_REOPENED: 11,
  REQUEST_REOPENED_CLOSED: 12,

  ACTION_ADD_GUEST: 'AddGuest',
  ACTION_DOWNLOAD_POLICY: 'DownloadPolicy',
  ACTION_GB_ADD_GUEST: 'GroupBookingAddGuests',
  ACTION_GB_ADD_MEAL: 'GroupBookingAddMeal',
  ACTION_GB_CHANGE_DATES: 'GroupBookingChangeDates',
  ACTION_GB_PAY_REMAINING_NOW: 'PayRemainingAmountNow',
  ACTION_GB_PAY_INSTALLMENT: 'PayInstallment',
  ACTION_ADD_MEAL: 'AddMeal',
  ACTION_ADD_OTHER_GUEST_NAMES: 'AddOtherGuestNames',
  ACTION_SUBMIT_GUEST_NAMES: 'SubmitGuestNames',
  ACTION_CALL_HOTEL: 'CallHotel',
  ACTION_CALL_MMT_SUPPORT: 'CallMMTSupport',
  ACTION_CANCEL_FULL_BOOKING: 'CancelFullBooking',
  ACTION_CANCEL_PARTIAL_BOOKING: 'CancelPartBooking',
  ACTION_CHANGE_DATES: 'ChangeDates',
  ACTION_CHAT_WITH_US: 'ChatWithUs',
  ACTION_CHECK_IN_AT_HOTEL: 'CheckInAtHotel',
  ACTION_DOWNLOAD_INVOICE: 'DownloadInvoice',
  ACTION_DOWNLOAD_IHINVOICE: 'DownloadIHInvoice',
  ACTION_AUTO_DEBIT_MANDATE: 'AutoDebitMandate',
  DOWNLOAD_INVOICE_HORLIST:'DownloadInvoice_HorList',
  ACTION_DOWNLOAD_NEW_VOUCHER: 'DownloadNewVoucher',
  ACTION_DOWNLOAD_VOUCHER: 'DownloadVoucher',
  ACTION_EDIT_PRIMARY_GUEST_NAMES: 'EditPrimaryGuestName',
  ACTION_EMAIL_NEW_VOUCHER: 'EmailNewVoucher',
  ACTION_EMAIL_VOUCHER: 'EmailVoucher',
  ACTION_MAKE_REQUEST: 'MakeRequest',
  ACTION_MAP_AND_DIRECTION: 'MapsAndDirections',
  ACTION_MORE_AMENITIES: 'RoomAmenities',
  ACTION_RATE_YOUR_STAY: 'RateYourStay',
  ACTION_REQUEST_EARLY_CHECK_IN: 'RequestEarlyCheckin',
  ACTION_REQUEST_LATE_CHECK_OUT: 'RequestLateCheckout',
  ACTION_SEE_PAYMENT_BREAK_UP: 'SeePaymentBreakup',
  ACTION_VIEW_HOUSE_RULES: 'ViewHouseRules',
  ACTION_CHAT_WITH_HOST: 'ChatWithHost',
  ACTION_CHAT_WITH_HOST_RTB: 'ChatWithHostRTB',
  ACTION_GROUP_BOOKNG_PAY_NOW: 'GroupBookingPayNow',
  ACTION_STAFF_DETAILS: 'StaffDetails',
  ACTION_UPGRADE_ROOM: 'UpgradeRoom',
  ACTION_VALID_ID_PROOF: 'ValidIDProofs',
  ACTION_VIEW_ALL: 'ViewAll',
  ACTION_VIEW_CANCELLATION_CHARGES: 'ViewCancellationCharges',
  ACTION_VIEW_DATE_CHANGE_CHARGES: 'ViewDateChangeCharges',
  ACTION_VIEW_HOTEL_DETAILS: 'ViewHotelAmenities',
  ACTION_VIEW_LOCATION: 'ViewLocation',
  ACTION_OPEN_LOCATION: 'OpenLocationInMaps',
  ACTION_OPEN_LOCATION_GOOGLE_MAPS: 'OpenLocationInGoogleMaps',
  ACTION_COPY_HOTEL_ADDRESS: 'CopyAddressToClipboard',
  ACTION_COPY_HOTEL_COORDINATES: 'CopyCoordinatesToClipboard',
  ACTION_VIEW_REFUND_DETAILS: 'ViewRefundPromiseDetails',
  ACTION_VIEW_ROOM_DETAILS: 'ViewRoomDetails',
  ACTION_VIEW_MODIFICATION_DETAILS: 'ViewModificationDetails',
  ACTION_RESEND_CONFIRMATION: 'ResendConfirmation',
  ACTION_CHAT_WITH_HOTEL: 'ChatWithHotel',
  ACTION_MODIFY: 'Modify',
  ACTION_CANCEL: 'Cancel',
  //ACTION_REFUND_CALCULATION: 'ViewRefundCalculation',
  ACTION_GO_TO_HELP_SELECTION: 'GoToHelpSection',
  ACTION_INITIATE_REFUND: 'InitiateRefund',
  ACTION_VIEW_POLICY: 'ViewHotelPolicy',
  ACTION_VIEW_REGULAR_REFUND_DETAILS: 'ViewRefundRegularDetails',
  ACTION_VIEW_REFUND_CALCULATION: 'ViewRefundCalculation',
  ACTION_PAY_NOW: 'PayNow',
  ACTION_CROSS_LOB_BUY: 'CrossLobBuy',
  ACTION_PAHX_DEEP_LINK: 'Pahx',
  ACTION_ROOM_AMENITIES: 'ViewRoomAmenities',
  ACTION_HOTEL_AMENITIES: 'ViewHotelAmenities',
  ACTION_NAME_CHANGE_SUBMIT: 'NameChangeSubmit',
  ACTION_MODIFY_NAME_CHANGE: 'ModifyName',
  ACTION_MODIFY_NAME_CHANGE_NOT: 'Modifyname_Not',
  ACTION_MODIFY_NAME_LESS_TIME_WINDOW: 'ModifyName_Lesstimewindow',
  ACTION_MODIFY_NAME_ALREADY_SUBMITTED: 'ModifyName_alreadyformsubmitted',
  ACTION_MODIFY_NAME_ALREADY_DONE: 'Modifyname_alreadydone',
  ACTION_CREATE_NEW_REQUEST: 'CreateNewRequest',
  ACTION_CANCEL_BOOKING_FOR_FREE: 'CancelbookingForFree',
  MY_REQUESTS_FOR_THIS_BOOKING_CARD: 'MyRequestsForThisBookingCard',
  LONG_STAY_BENEFITS_CARD: 'LongStayBenefitsCard',
  TAJ_GIFT_CARD: 'TajGiftCard',
  HOTEL_CREDITS_CARD: 'HotelCreditsCard',
  ACTION_VIEW_SEE_UPDATED_BOOKING: 'SeeUpdatedBooking',
  ACTION_WRITE_HOTEL_REVIEW: 'WriteAReview',
  ACTION_SEE_PAYMENT_BREAK_UP_EXTRA_CHARGES: 'SeePaymentBreakupExtraCharges',
  ACTION_VIEW_HOUSE_RULES_IMPORTANT_INFO: 'ViewHouseRulesImportantInformation',
  ACTION_SHARE_HOTEL_LOCATION: 'ShareHotelLocation',
  ACTION_CALL_PROPERTY: 'CallProperty',
  ACTION_PROPERTY_DETAILS: 'PropertyDetails',
  ACTION_HOTEL_PHOTOS: 'HotelPhotos',
  ACTION_ROOM_PHOTOS: 'RoomPhotos',
  ACTION_WRITE_TO_PROPERTY: 'WriteToProperty',
  ACTION_PARTIAL_CANCELLATION: 'PartialCancellation',
  ACTION_DOWNLOAD_CUSTOMER_VOUCHER: 'DownloadCustomerVoucher',
  ACTION_DOWNLOAD_BILL_MEMO: 'DownloadBillMemo',
  ACTION_DOWNLOAD_GST_INVOICE: 'DownloadGstInvoice',
  ACTION_CONFIRM_MODIFICATION: 'ConfirmModification',
  HOTEL_UNIQUEID_TYPE: 'HOTEL_BOOKING_DETAIL',
  HOTEL_RESERVATION_CHARGES: 'Hotel Reservation Charges',
  PROPERTY_RESERVATION_CHARGES: "Property Reservation Charges",
  // omniture for page name check
  // omniture constant starts
  REACT_HOTEL_ERR_PAGE: 'react_hotel_err_page',
  REACT_HOTEL_POLICY_PAGE: 'react_hotel_policy_page',
  REACT_HOTEL_CANCELLATION_POLICY_PAGE: 'react_hotel_cancellation_policy_page',
  REACT_HOTEL_AMENITIES_PAGE: 'react_hotel_amenities_page',
  REACT_HOTEL_ROOM_AMENITIES_PAGE: 'react_hotel_room_amenities_page',
  REACT_HOTEL_CANCELLATION_STANDALONE_PAGE: 'react_hotel_cancellation_standalone_page',
  REACT_HOTEL_MODIFICATION_STANDALONE_PAGE: 'react_hotel_modification_standalone_page',
  REACT_HOTEL_DETAILS_PAGE: 'react_hotel_details_page',
  REACT_INVOICE_MESSAGE: 'InvoiceMessage',

  REACT_HOTEL_PAYMENT_DETAILS_PAGE: 'react_hotel_payment_details_page',
  REACT_HOTEL_EASY_PAY_WEB_VIEW_PAGE: 'react_hotel_easy_pay_web_view_page',
  REACT_HOTEL_NAME_CHANGE_FORM_PAGE: 'react_hotel_name_change_form_page',
  REACT_HOTEL_NAME_CHANGE_SUCCESS_PAGE: 'react_hotel_name_change_success_page',
  REACT_HOTEL_PAHX_NO_SHOW_PAGE: 'react_hotel_pahx_no_show_page',
  REACT_HOTEL_PAHX_SUCCESS_PAGE: 'react_hotel_pahx_success_page',
  // Omniture constant ends
  BOOKING_CANCELLATION_REFUND_PREVIEW_PAGE: 0,
  BOOKING_CANCELLATION_REFUND_MODE_PAGE: 1,

  // Refund Details Screen Constant
  REQUEST_ATTRIBUTE_CANCELLATION: 'Cancellation',
  REFUND_MODE_ORIGINAL: 'Original mode',
  REFUND_SOURCE_MY_WALLET: 'MyWallet',

  // Deep Link Pages
  HOTEL_AMENTIES_DEEP_LINK_PAGE: 101,
  ROOM_AMENTIES_DEEP_LINK_PAGE: 102,
  HOTEL_VIEW_HOUSE_RULES: 103,
  HOTEL_NAMECHANGE: 105,
  HOTEL_DATECHANGE: 106,
  HOTEL_MESSAGEHOST: 107,
  HOTEL_ADDGUEST: 108,
  HOTEL_CANCELLATION: 109,
  HOTEL_ADDROOM_SCROLL: 110,
  HOTEL_DATECHANGE_SCROLL: 111,
  HOTEL_ADDGUEST_SCROLL: 112,
  HOTEL_CANCELCARD_SCROLL: 113,
  HOTEL_MODIFY_SCROLL: 114,
  HOTEL_READPOLICY_SCROLL: 115,
  HOTEL_PICS: 116,
  HOTEL_ROOM_DETAILS: 117,
  HOTEL_INVOICE_SCROLL: 118,
  HOTEL_DOWNLOAD_INVOICE: 121,
  HOTEL_DOWNLOAD_VOUCHER: 122,
  HOTEL_DOWNLOAD_POLICY: 123,
  HOTEL_DOWNLOAD_INVOICE_POPUP: 124,
  HOTEL_OPEN_CALENDAR: 125,

  ACME_LOB_NAME: 'Acme',
  CAB_LOB_NAME: 'Cab',

  ACTION_LABEL_IOS_ADD_TO_PASSBOOK: "Add To Passbook",
  ACTION_LABEL_IOS_OPEN_MAPS: "Open in Maps",

  RESEND_HOTEL_VOUCHER_TEXT: 'Share Booking Details',

  ALERT_INFO_CARD: 'alertinfocard',

  MMT_ASSURED_DEFAULT_HOT_LINE_NUMBER: "01244628747, 01245045105",
  DEFAULT_CORPORATE_TOLL_FREE_NUMBER: "+911244628720",
  DEFAULT_MMT_NUMBER: "01244628747",
  CORPORATE_LOBS: ['LOB03810', 'LOB03820', 'LOB03830', 'LOB03855', 'LOB03850'],

  EARLY_CHECKIN_REQUEST_KEY: 'EarlyCheckin',
  LATE_CHECKOUT_REQUEST_KEY: 'LateCheckout',
  OTHERS_KEY: 'Others',

  CHAT_STAFF: 'CHAT_STAFF',
  CALL_STAFF: 'CALL_STAFF',
  AWAITED: 'AWAITED',
  MESSAGE_HOST: 'MESSAGE HOST',
  DEFAULT_MAP_LABEL_TEXT: "Your Booked Hotel",
  CROSSLOB_VIEW_TERMS_CLICKED: 'crosslob_view_terms_clicked',
  ACTION_ADD_TO_APPLE_WALLET: 'AddToAppleWallet',
  HOTEL_UNIQUEID_TYPE: 'HOTEL_BOOKING_DETAIL',
  FLEXI_HEADER: 'Flexi Cancel',
  POLICY: 'POLICY',
  VOUCHER: 'VOUCHER',
  INVOICE: 'INVOICE',
  TRIP_VIEW_SRC: 'htlmytdetails',
  HOTEL_FUNNEL_STEP: 'detail'
};

export const PAX_TYPE = {
  ADULT: 'Adult',
  CHILD: 'Child'
}

export const cashBackStageConstant = {
  CASHBACK_IN_PROCESS: "Cashback_In_Process",
  CASHBACK_PROCESSED: "Cashback_Processed",
  BC_CASHBACK_NOT_PROCESSED: "Booking_Cancelled_Cashback_Not_Processed",
  BC_CASHBACK_PROCESSED: "Booking_Cancelled_Cashback_Processed"
}

export const PAX_GENDER = {
  MALE: 'Male',
  FEMALE: 'Female'
}

export const PAX_TITLE = {
  MR: 'Mr.',
  MRS: 'Mrs.',
  MS: 'Ms.'
}

export const ContactLessCheckinConstants = {
  DOC_STATUS: {
    PENDING: 'PENDING',
    DOC_UPLOADED: 'DOC_UPLOADED',
    APPROVED: 'APPROVED',
    REJECTED: 'REJECTED'
  }
};

export const RTBConstants = {
  rtbAwaitedStatusText: 'Awaiting Host Confirmation',
  rtbAwaitedDesc: 'Hold on dear traveller, your booking status will be conveyed within 24 hrs.',
  rtbRejectedDesc2: 'Don’t worry, you’ll get a full refund credited to the payment method used to make the payment. We’ve lots of other options for you',
  bookingNotAcceptedText: 'Booking Not Accepted',
  messageHostText: 'Message Host for a quicker response.',
  ACCEPTED: 'Accepted',
  REJECTED: 'Rejected'
}

export const PAGE_NUMBER = {
  ONE: 1,
  TWO: 2
}

export const MODIFICATION_TYPE = {
  NAME_CHANGE: "NameChange",
  ADD_GUEST: "AddGuest",
  DATE_CHANGE: "DateChange"
};

export const API_REQUEST_TYPE = {
  HOTEL_CUSTOMER_SUBMIT_NAME_CHANGE: "HOTEL_CUSTOMER_SUBMIT_NAME_CHANGE",
  HOTEL_CUSTOMER_NAME_CHANGE_MODIFICATION: "HOTEL_CUSTOMER_NAME_CHANGE_MODIFICATION"
};

export const HOTEL_DETAILS_BOTTOM_OVERLAY = {
  CANCELLATION_POLICY: 'CANCELLATION_POLICY',
  PROCEED_FOR_CANCELLATION: 'PROCEED_FOR_CANCELLATION',
  CHOOSE_PAYMENT_MODE_BNPL: 'CHOOSE_PAYMENT_MODE_BNPL',
  TCS_MODIFICATION_OVERLAY: 'TCS_MODIFICATION_OVERLAY',
  TCS_RULES_OVERLAY: 'TCS_RULES_OVERLAY',
  INSURANCE_BENEFITS: 'INSURANCE_BENEFITS',
  ACTION_DOWNLOAD_CUSTOMER_VOUCHER: 'DownloadCustomerVoucher',
  EXPEDIA_CHAT: 'EXPEDIA_CHAT',
  CALENDAR_OVERLAY: 'CALENDAR_OVERLAY',
}

export const CANCELLATION_POLICY_TRACKER_TYPES = {
  PROCESSED: 'Processed',
  COMPLETED: 'Completed',
  PROGRESS: 'Progress',
  EXPIRED: 'Expired',
  ACTIVE: 'Active'
}

export const ERROR_TYPES = {
  NO_INTERNET: 'NO_INTERNET',
  AUTH_FAILURE: 'AUTH_FAILURE',
}

export const DOWNLOAD_FILE_TYPE = {
  POLICY: 'PolicyDoc',
  INVOICE: 'HotelInvoice',
  VOUCHER: 'BookingVoucher',
  BOOKING_INVOICE : 'Booking Invoice',
  BILL_MEMO : 'Bill Memo',
  GST_INVOICE : 'Gst Invoice',
  MY_PARTNER_CUSTOMER_VOUCHER: 'Customer Booking Confirmation'
}

export const DOWNLOAD_FILE_TRIGGER_NAME = {
  POLICY: 'Hotel_Booking_Voucher_PDF2',
  INVOICE: 'Hotel_CustomerInvoice',
  VOUCHER: 'Hotel_CustomerVoucher',
  MY_PARTNER_CUSTOMER_VOUCHER: 'HotelVoucher_MyPartner_New',
  BILL_MEMO:'MMTHOTEL_BILL_MEMO',
  GST_INVOICE:'GST_INVOICE'
}

export const TRIGGER_NAME_BASED_URL = () => {
  return {
  'Hotel_Booking_Voucher_PDF2': HOTEL_DOWNLOAD_VOUCHER,
  'Hotel_CustomerInvoice': HOTEL_DOWNLOAD_VOUCHER,
  'Hotel_CustomerVoucher': HOTEL_DOWNLOAD_VOUCHER,
  'HotelVoucher_MyPartner_New': HOTEL_MYPARTNER_VOUCHER,
  'MMTHOTEL_BILL_MEMO' : HOTEL_DOWNLOAD_BILL_MEMO,
  'GST_INVOICE' : HOTEL_DOWNLOAD_GST_INVOICE
  // Add more mappings as needed
  }
};

export const DOCUMENT_NO_TO_DOCUMENT_TYPE_MAPPING = {
  121: 'INVOICE',
  122: 'VOUCHER',
  123: 'POLICY'
}

export const APP_STATE_CONSTANTS = {
  ACTIVE: 'active',
  BACKGROUND: 'background',
}

export const PAYMENT_EVENT_LISTNER = 'hotel_booking_details_thankyou_page';

export default HotelBookingDetailsConstant;
