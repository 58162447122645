import React from 'react';
import { View, Text } from 'react-native';
import CountryVisaCard from '../CountryVisaCard';
import isEmpty from 'lodash/isEmpty';
import { useTheme } from '../../../../../../../theme/theme.context';
import createStyles from './style';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const ApplyForVisaCard = ({ label, countries, dispatch, selectedCountriesData }) => {
  const { psTheme: theme } = useTheme();
  const fonts = getFont();
  const styles = createStyles(fonts, theme);
  return (
    <View style={styles.countryVisa}>
      {!isEmpty(label) && (
        <View style={styles.headingWrapper}>
          <Text style={styles.labelText}>{label}</Text>
        </View>
      )}
      {!isEmpty(countries) && (
        <View style={styles.innerCardDetails}>
          {countries.map((country, index) => (
            <View style={styles.visaSection}>
              <CountryVisaCard
                country={country}
                countryIndex={index}
                dispatch={dispatch}
                selectedCountryData={selectedCountriesData[index]}
                countryPrice={
                  selectedCountriesData[index] && selectedCountriesData[index].countryPrice
                }
              />
            </View>
          ))}
        </View>
      )}
    </View>
  );
};

export default ApplyForVisaCard;
