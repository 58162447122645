import { StyleSheet } from "react-native";
export default (theme, fonts) =>StyleSheet.create({
    headingStatusTxt: {
        color: theme.color.lightTextColor,
        fontSize: 14,
        flex: 1,
    },
    paxName: {
        color: theme.color.black,
        fontSize: 13,
        ...(fonts.regularFontFamily),
        width:'46%'
    },
    paxStatus:{
        flexDirection:'row',
        flex:1
    },
    
    status: {
        borderRadius: 10,
        marginRight: 10,
        paddingVertical: 2,
        width: 70,
        justifyContent: 'center',
        alignItems: 'center'
    },
    statusConfirm:{
        backgroundColor:theme.color.green3,
        borderWidth: 1,
        borderColor: theme.color.green3,
    },
    statusRAC:{
        backgroundColor:theme.color.grey22,
        borderWidth: 1,
        borderColor: theme.color.green3,
    },
    statusWaiting:{
        backgroundColor:theme.color.grey22,
        borderWidth: 1,
        borderColor: theme.color.yello,
    },
    statusTDR:{
        backgroundColor:theme.color.grey22,
        borderWidth: 1,
        borderColor: theme.color.azureBlue3,
    },
    statusCancelled:{
        backgroundColor:theme.color.grey22,
        borderWidth: 1,
        borderColor: theme.color.red7,
    },
    statusFailed:{
        backgroundColor:theme.color.grey22,
        borderWidth: 1,
        borderColor: theme.color.red,
    },
    statusText: {
        ...(fonts.boldFontFamily),
        fontSize: 8,
        color: theme.color.black,
        textTransform: 'uppercase'
    },
    statusConfirmText:{
        color:theme.color.white, 
    },
    statusRACText:{
        color:theme.color.green3,
    },
    statusWaitingText:{
        color:theme.color.yello,
    },
    statusTdrText:{
        color:theme.color.azureBlue3,
    },
    statusFailedText:{
        color:theme.color.red7,
    },
    statusCancelledText:{
        color:theme.color.red,
    },
    statusReport: {
        ...(fonts.regularFontFamily),
        fontSize: 13,
        color: theme.color.black,
        textTransform: 'uppercase',
    },
    lastEleStyle:{
        marginBottom:0
    },
})