
import React from 'react';
import {TouchableOpacity} from 'react-native';
import {USER_PLATFORM} from '../../HolidayBookingConstants';
const AcmeTouchableOpacity = ({
  style, activeOpacity, onPress, disabled, children
}) => {
  if (USER_PLATFORM.WEB) {
    return (
      <TouchableOpacity
        disabled={disabled}
        style={style}
        activeOpacity={activeOpacity}
        onClick={event => onPress(event)}
      >
        {children}
      </TouchableOpacity>
    );
  }
  return (
    <TouchableOpacity
      disabled={disabled}
      style={style}
      activeOpacity={activeOpacity}
      onPress={() => onPress()}
    >
      {children}
    </TouchableOpacity>
  );
};
export default AcmeTouchableOpacity;
