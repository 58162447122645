import { StyleSheet } from 'react-native';
import { themeColors } from '../../../../../../../PostSalesStyles';

export default (theme, fonts) =>
  StyleSheet.create({
    alignLeft: {
      textAlign: 'left',
    },
    paddingLeft10:{
      paddingLeft: 10
    },
    refundCardOuter: {
      paddingLeft: 15,
      paddingRight: 15,
      paddingBottom: 10,
      paddingTop: 10
    },
    flexRow: {
      flexDirection: 'row'
    },
    marginTop10: {
      marginTop: 10
    },
    marginTop3: {
      marginTop: 3
    },
    refundCardWrap: {
      flexDirection: 'row',
      justifyContent: 'space-between'
    },
    blackText: {
      color: theme.color.black
    },
    refundCardLeft: {
      width: '75%'
    },
    instantRefundTag: {
      height: 24,
      borderRadius: 5,
      width: 115,
      alignItems: 'center',
      justifyContent: 'center'
    },
    instantRefundTagTxxt: {
      fontFamily: fonts.bold,
      fontSize: 10,
      color: theme.color.white,
      alignSelf: 'center'
    },
    walletIcon: {
      height: 22,
      width: 20,
      padding: 10,
      marginRight: 10,
      alignSelf: 'center'
    },
    listIcon: {
      width: 18,
      height: 18,
      marginRight: 10,
      marginTop: 2
    },
    knowMore:{
        color: themeColors.primary,
        fontFamily: fonts.bold,
        marginTop: 20
    }
  });