import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import {
  View,
  Text,
  Image,
  ScrollView,
  StyleSheet,
  Dimensions,
  TouchableOpacity,
  Animated,
  Easing
} from 'react-native';
import getPlatformElevation from './getPlatformElevation';
import Header from './Header';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import {
  ActivityIcon, BusIcon,
  CarIcon, CruiseIcon,
  flightIcon,
  HotelIcon,
  itineraryType, RailIcon,
  rightArrow,
  rightArrowBlack
} from '../HolidayBookingConstants';
import { getItinerarySubHeading, removeTags, getActivityListIconData } from '../HolidayBookingDetailUtils';
import FullDescription from "./FullDescription";
import { themeColors } from '../../PostSalesStyles';
import OverNightTag from "./OverNightTag";

const windW = Dimensions.get('screen').width;

class ItineraryModal extends React.Component {
  static navigationOptions = {
    header: null
  };

  constructor(props) {
    super(props);
    this.state = {
      toastPostion: new Animated.Value(0)
    };
  }

  componentDidMount() {
    if (this.props.daywiseItineraryDetails && this.props.daywiseItineraryDetails.length > 3) {
      const bottom = this.props.bottom ? this.props.bottom + 50 : 80;
      const delay = this.props.delay ? this.props.delay : 0;
      const duration = this.props.duration ? this.props.duration : 800;
      this.startAnimate(bottom, duration, delay);
    }
  }

  startAnimate(bottom, duration, delay) {
    const current = this;
    Animated.timing(this.state.toastPostion, {
      toValue: bottom,
      easing: Easing.easeInOut,
      duration,
      delay
    })
      .start();
  }

  handleScroll = () => {
    const current = this;
    current.hideToast(-150);
  };

  hideToast(top) {
    Animated.timing(this.state.toastPostion, {
      toValue: 0,
      easing: Easing.easeInOut,
      duration: 500,
      delay: 0
    })
      .start();
  }

  handleToast = (nativeEvent) => {
    if (nativeEvent
      && nativeEvent.layout
      && nativeEvent.layout.height
      && nativeEvent.layout.height > this.scrollviewHeight) {
      const bottom = this.props.bottom ? this.props.bottom + 50 : 100;
      const delay = this.props.delay ? this.props.delay : 0;
      const duration = this.props.duration ? this.props.duration : 800;
      this.startAnimate(bottom, duration, delay);
    }
  };

  render() {
    const { daywiseItineraryDetails, isBundlePackage } = this.props;
    return (
      <View style={styles.modalWrapper}>
        <Header
          title="Itinerary"
          subTitle={this.props.travellerHeaderLabel}
          headerShadow
          headingTxt="font18"
          pageName={this.props.pageName}
          goBack={this.props.onBackPopUp}
          navigation={this.props.navigation}
        />
        <ScrollView
          onLayout={({nativeEvent}) => this.scrollviewHeight = nativeEvent.layout.height}
          onScroll={this.handleScroll}
        >
          <View style={[AtomicCss.marginTop10]}>
            {!isEmpty(daywiseItineraryDetails) &&
            daywiseItineraryDetails.map((item, index) => {
              let dayLabel = `Day ${item.day}`;
              if (!isEmpty(item.date)) {
                dayLabel = `${dayLabel} (${item.date})`;
              }
              const id = index + 1;
              return (
                <View style={[styles.cardWrapper, AtomicCss.marginBottom10]} key={item.id}>
                  <View
                    style={[AtomicCss.flexRow, AtomicCss.marginBottom5, AtomicCss.alignCenter]}
                  >
                    <View
                      style={[AtomicCss.justifyCenter, AtomicCss.alignCenter, styles.activityDayNumber]}
                    >
                      <Text
                        style={[AtomicCss.blackFont, AtomicCss.font10, AtomicCss.defaultText]}
                      >{id}
                      </Text>
                    </View>
                    <Text
                      style={[AtomicCss.boldFont, AtomicCss.defaultText]}
                    >{dayLabel}
                    </Text>
                  </View>

                  <View style={styles.activityInnerDtls}>
                    {
                      isBundlePackage ?
                        (
                          <View>
                            {!!item.daywiseData[0].itineraryData.serviceText &&
                            <Text style={[AtomicCss.defaultText, AtomicCss.font14, AtomicCss.regularFont, AtomicCss.marginBottom15]}>
                              {item.daywiseData[0].itineraryData.serviceText}
                              </Text>}
                            <View style={styles.dayActivityWrapper}>
                              {!isEmpty(item.daywiseData) &&
                                getActivityListIconData(item.daywiseData[0].itineraryData).map((act, index) => {
                                  const iconStyle = `${act.type.toLowerCase()}Style`;
                                  return (
                                    <View style={[styles.dayActInnerSection]}>
                                      <View style={styles.iconWrapper}>
                                        <Image style={styles[iconStyle]} source={act.iconSrc} />
                                      </View>
                                      <Text style={styles.dayActTxt}>{act.text}</Text>
                                    </View>
                                  )
                                })}
                              <View>
                                <FullDescription
                                  fullDesc={item.daywiseData[0].itineraryData.serviceDescription}
                                />
                              </View>
                            </View>
                          </View>
                        ) : null
                    }
                    {!isEmpty(item.daywiseData) &&
                    item.daywiseData.map((dayWiseDataItem, dataIndex) => {
                      const iLen = dataIndex === (item.daywiseData.length - 1) ? [styles.lastItemstyle] : '';
                      const {subHeadingPrefix, subHeadingSuffix} = getItinerarySubHeading(dayWiseDataItem);
                      const activityType = dayWiseDataItem.itinerary === itineraryType.Activity && dayWiseDataItem.transferType === itineraryType.Transfers ? dayWiseDataItem.transferType : dayWiseDataItem.itinerary;
                      return (
                        <TouchableOpacity
                          activeOpacity={0.6}
                          onPress={() => {
                            if (dayWiseDataItem.itinerary !== itineraryType.Free) {
                              this.props.handleItineraryClick(activityType, index, dataIndex);
                            }
                          }}
                        >
                          { dayWiseDataItem.itinerary === itineraryType.Flight && dayWiseDataItem.isOvernight &&
                          <OverNightTag tagText = {dayWiseDataItem.overnightTag}/>
                          }
                          <View
                            style={[styles.dayItemRow, AtomicCss.flexRow,
                              AtomicCss.alignCenter, iLen]}
                          >
                            <View
                              style={[styles.width40IconWrapper, AtomicCss.flexRow,
                                AtomicCss.alignCenter]}
                            >
                              {dayWiseDataItem.itinerary === itineraryType.Flight &&
                                <View>
                                  <Image style={styles.flightImg} source={flightIcon} />
                                </View>
                              }
                              {dayWiseDataItem.itinerary === itineraryType.Hotel &&
                              <View>
                                <Image style={styles.hotelImg} source={HotelIcon} />
                              </View>
                              }
                              {dayWiseDataItem.itinerary === itineraryType.Rail &&
                              <View>
                                <Image style={styles.carImg} source={RailIcon} />
                              </View>
                              }
                              {dayWiseDataItem.itinerary === itineraryType.Car &&
                              <View>
                                <Image style={styles.carImg} source={CarIcon} />
                              </View>
                              }
                              {dayWiseDataItem.itinerary === itineraryType.Bus &&
                              <View>
                                <Image style={styles.carImg} source={BusIcon} />
                              </View>
                              }
                              {(activityType === itineraryType.Activity
                                || dayWiseDataItem.itinerary === itineraryType.Free) &&
                                <View>
                                  <Image style={styles.activityImg} source={ActivityIcon} />
                                </View>
                              }
                              {activityType === itineraryType.Transfers &&
                              <View>
                                <Image style={styles.carImg} source={CarIcon} />
                              </View>
                              }
                              {dayWiseDataItem.itinerary === itineraryType.Cruise &&
                              <View>
                                <Image style={styles.activityImg} source={CruiseIcon} />
                              </View>
                              }
                            </View>

                            <View style={AtomicCss.flex1}>
                              <View>
                                {dayWiseDataItem.itinerary === itineraryType.Flight &&
                                <View style={[AtomicCss.flexRow, styles.marginBottom4]}>
                                  <Text
                                    style={[AtomicCss.boldFont, AtomicCss.font16, AtomicCss.blackText]}
                                  >{dayWiseDataItem.itineraryData.flightOriginCity}
                                  </Text>
                                  <View>
                                    <Image style={styles.rightArrowStyle} source={rightArrowBlack} />
                                  </View>
                                  <Text
                                    style={[AtomicCss.boldFont, AtomicCss.font16, AtomicCss.blackText]}
                                  >{dayWiseDataItem.itineraryData.flightDestinationCity}
                                  </Text>
                                </View>
                                }

                                {dayWiseDataItem.itinerary !== itineraryType.Flight &&
                                <View style={[AtomicCss.flexRow, styles.marginBottom4]}>
                                  <Text
                                    style={[AtomicCss.boldFont, AtomicCss.font16, AtomicCss.blackText]}
                                  >{removeTags(dayWiseDataItem.itineraryData.serviceName)}
                                  </Text>
                                </View>
                                }

                                {(!isEmpty(subHeadingPrefix) || !isEmpty(subHeadingSuffix)) &&
                                <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
                                  <Text
                                    style={[AtomicCss.regularFont, AtomicCss.greyText, AtomicCss.font14]}
                                  >{subHeadingPrefix}
                                  </Text>
                                  {!isEmpty(subHeadingPrefix) && !isEmpty(subHeadingSuffix) &&
                                  <Text
                                    style={[AtomicCss.defaultText, AtomicCss.boldFont, styles.bulletText]}
                                  >{'\u2B24'}
                                  </Text>
                                  }
                                  <Text
                                    style={[AtomicCss.regularFont, AtomicCss.greyText, AtomicCss.font14]}
                                  >{subHeadingSuffix}
                                  </Text>
                                </View>
                                }
                              </View>
                            </View>
                            {dayWiseDataItem.itinerary != itineraryType.Free
                             && !dayWiseDataItem.dummyFlight ? (
                              <View style={styles.rightArrowIcon}>
                                <Image style={styles.rightArrowIcon} source={rightArrow} />
                              </View>
                            ) : null}
                          </View>
                        </TouchableOpacity>
                      );
                    })
                    }
                  </View>
                </View>
              );
            })
            }
          </View>
        </ScrollView>

        <Animated.View style={[styles.toastWrapper, {bottom: this.state.toastPostion}]}>
          <View style={styles.toastDtls}>
            <View style={styles.toastTextWrapper}>
              <Text style={styles.toastText}>Scroll down to view day wise itinerary</Text>
            </View>
          </View>
        </Animated.View>

      </View>
    );
  }
}

const styles = StyleSheet.create({
  modalWrapper: {
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: '#f2f2f2',
    position: 'absolute',
    elevation: 3,
    zIndex: 3,
    width: '100%',
    height: '100%'
  },
  cardWrapper: {
    width: windW,
    backgroundColor: '#fff',
    ...getPlatformElevation(2),
    paddingLeft: 16,
    paddingTop: 16,
    marginBottom: 3
  },
  activityDayNumber: {
    width: 20,
    height: 20,
    backgroundColor: '#e5e5e5',
    borderRadius: 50,
    marginRight: 10
  },
  marginBottom4: {
    marginBottom: 4
  },
  bulletText: {
    fontSize: 6,
    paddingHorizontal: 5,
    paddingTop: 3
  },
  iconWrapper: {
    width: 20,
    height: 16,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10
  },
  width40IconWrapper: {
    width: 40,
    height: 40,
    marginRight: 12,
    alignItems: 'center'
  },
  flightImg: {
    width: 21,
    height: 13
  },
  hotelImg: {
    width: 24,
    height: 24
  },
  carImg: {
    width: 20,
    height: 19
  },
  activityImg: {
    width: 15,
    height: 23
  },
  rightArrowStyle: {
    width: 8,
    height: 7,
    top: 7,
    marginHorizontal: 5
  },
  rightArrowIcon: {
    width: 9,
    height: 13,
    tintColor: themeColors.rightArrowTintColor
  },
  activityInnerDtls: {
    paddingLeft: 30,
    paddingRight: 16,
    marginBottom: 20
  },
  dayItemRow: {
    borderBottomWidth: 1,
    borderBottomColor: '#e5e5e5',
    paddingVertical: 15
  },
  pendingInfo: {
    color: '#cf8100'
  },
  lastItemstyle: {
    borderBottomWidth: 0,
    borderBottomColor: 'transparent',
    paddingBottom: 0
  },
  toastWrapper: {
    zIndex: 1,
    marginBottom: -150,
    position: 'absolute',
    bottom: 0,
    width: '90%',
    left: '5%'
  },
  toastTextWrapper: {
    borderRadius: 6
  },
  toastText: {
    color: colors.white,
    fontSize: 12,
    textAlign: 'center'
  },
  toastViewText: {
    color: '#33d18f',
    fontFamily: fonts.bold,
    fontSize: 10
  },
  hostMessageView: {
    paddingVertical: 20,
    paddingHorizontal: 16
  },
  toastDtls: {
    backgroundColor: '#4a4a4a',
    borderRadius: 6,
    paddingVertical: 16,
    paddingHorizontal: 16
  },
  dayActivityWrapper: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  dayActTxt: {
    color: '#747474',
    fontFamily: fonts.bold,
    fontSize: 11,
  },
  dayActInnerSection: {
    width: '49%',
    marginBottom: 12,
    flexDirection: 'row'
  },
  transfersStyle: {
    width: 18,
    height: 13
  },
  airport_cabStyle: {
    width: 18,
    height: 13
  },
  hotelStyle: {
    width: 13,
    height: 16
  },
  activityStyle: {
    width: 11,
    height: 14
  },
  mealStyle: {
    width: 20,
    height: 11
  },
  breakfastStyle: {
    width: 20,
    height: 11
  },
  lunchStyle: {
    width: 20,
    height: 11
  },
  dinnerStyle: {
    width: 20,
    height: 11
  },
});

ItineraryModal.propTypes = {
  daywiseItineraryDetails: PropTypes.object.isRequired,
  travellerHeaderLabel: PropTypes.string.isRequired,
  pageName: PropTypes.string.isRequired,
  onBackPopUp: PropTypes.func.isRequired,
  handleItineraryClick: PropTypes.func.isRequired
};

export default ItineraryModal;
