import React from 'react';
import { ScrollView, StyleSheet, View, BackHandler, Linking, Platform } from 'react-native';
import Actions from '../navigation/postSalesNavigation';
import getPlatformElevation from './Components/getPlatformElevation';
import Header from './Components/Header';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import Tabs from './Components/Tab';
import VisasList from './Components/VisaDetailTab';
import ActivitiesList from './Components/ActivityDetailTab';
import FlightDetail from './Components/FlightDetailTab';
import HotelList from './Components/HotelDetailTab';
import TransferList from './Components/TransferDetailTab';
import ContactInfo from './Components/ContactInfo';
import BasePage from '../Common/PostSalesBasePage';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import {
  screenWidth,
  screenHeight,
  tabsType,
  HARDWARE_BACK_PRESS,
  buttonTypes,
} from './HolidayBookingConstants';
import { IOS_AAROGYA_SETU_LINK } from '../utils/NetworkUtils';
import CheckListOverlay from './Components/CheckListSafeTravel/CheckListOverlay';
import SelectFlights from './Components/SelectFlights';
import TravelPurpose from './Components/TravelPurpose';
import { BackWhiteIcon, CrossIcon } from './HolidayBookingConstants';
import { backPressHandler, openFlightAncillary } from '../utils/PostSaleUtil';
import { downloadTicket } from './HolidayBookingDetailUtils';
import HolidayBookingTrackingHelper from './HolidayBookingTrackingHelper';
import { getRootTag } from '@mmt/legacy-commons/AppState/RootTagHolder';
import HolidaysSharedModuleHolder from '@mmt/holidays-shared/src';
import InsuranceDetailList from './Components/InsuranceDetailTab';


const PAGE_NAME = 'TripDetails';

class HolidayBookingTab extends BasePage {
  constructor(props) {
    super(props);
    const {
      visaPageDetails,
      flightDetailsType,
      hotelDetailsMyTrips,
      activityPageDetails,
      insurancePageDetails,
      transfers,
      defaultTab,
    } = this.props;
    const { hotelDetails } = hotelDetailsMyTrips || {};
    const { transferDetails } = transfers || {};
    const { holidayActivityList } = activityPageDetails || {};
    const { holidayVisaDetailsList } = visaPageDetails || {};
    const {insuranceList} = insurancePageDetails || {};
    const tabsItem = [];
    holidayVisaDetailsList && holidayVisaDetailsList.length > 0
      ? tabsItem.push(tabsType.VISA)
      : null;
    insuranceList && insuranceList.length > 0 ? tabsItem.push(tabsType.INSURANCE) : null;
    flightDetailsType ? tabsItem.push(tabsType.FLIGHTS) : null;
    hotelDetails && hotelDetails.length > 0 ? tabsItem.push(tabsType.HOTELS) : null;
    transferDetails && transferDetails.length > 0 ? tabsItem.push(tabsType.TRANSFERS) : null;
    holidayActivityList && holidayActivityList.length > 0
      ? tabsItem.push(tabsType.ACTIVITIES)
      : null;
    this.state = {
      loader: false,
      tabsItem,
      activeTab: tabsItem.indexOf(defaultTab) === -1 ? 0 : tabsItem.indexOf(defaultTab),
      contactInfo: null,
      popUp: '',
    };
    this.tabContent = React.createRef();
  }

  omnitureTracking = () => {
    const { activeTab, tabsItem } = this.state;
    const selectedTabsType = tabsItem[activeTab];
    if (selectedTabsType === tabsType.VISA) {
      HolidayBookingTrackingHelper.trackClickEvent(PAGE_NAME, 'Visas_Tab', 'componentList');
    } else if (selectedTabsType === tabsType.FLIGHTS) {
      HolidayBookingTrackingHelper.trackClickEvent(PAGE_NAME, 'Flights_Tab', 'componentList');
    } else if (selectedTabsType === tabsType.HOTELS) {
      HolidayBookingTrackingHelper.trackClickEvent(PAGE_NAME, 'Hotels_Tab', 'componentList');
    } else if (selectedTabsType === tabsType.TRANSFERS) {
      HolidayBookingTrackingHelper.trackClickEvent(PAGE_NAME, 'Transfers_Tab', 'componentList');
    } else if (selectedTabsType === tabsType.ACTIVITIES) {
      HolidayBookingTrackingHelper.trackClickEvent(PAGE_NAME, 'Activities_Tab', 'componentList');
    } else if (selectedTabsType === tabsType.INSURANCE) {
      HolidayBookingTrackingHelper.trackClickEvent(PAGE_NAME, 'Insurance_Tab', 'componentList');
    }
  };

  checklistActionHandler = (action) => {
    switch (action.type) {
      case 'READ_GUIDELINES':
        HolidayBookingTrackingHelper.trackClickEvent(
          PAGE_NAME,
          'Checklist_Read_Guidelines',
          'componentList_Flights',
        );
        this.externalLinkHandler(action, 'Guidelines');
        break;
      case 'AAROGYA_SETU':
        HolidayBookingTrackingHelper.trackClickEvent(
          PAGE_NAME,
          'Checklist_Download_AarogyaSetu',
          'componentList_Flights',
        );
        const iosURL = action.iosURL || IOS_AAROGYA_SETU_LINK;
        this.externalLinkHandler(action, 'Aarogya Setu', iosURL);
        break;
      case 'WEB_CHECKIN':
        HolidayBookingTrackingHelper.trackClickEvent(
          PAGE_NAME,
          'Checklist_Web_Checkin',
          'componentList_Flights',
        );
        Actions.openAncillary({
          pageKey: 'webcheckin',
          holidayBooking:true,
          bookingId: this.props.BOOKING_ID,
          flightDetailResponse: this.props.flightBookingDetail,
          holidayData: this.props.holidayData,
        });
        break;
      case 'BAGGAGE_TAG':
        HolidayBookingTrackingHelper.trackClickEvent(
          PAGE_NAME,
          'Checklist_Baggage_Tag',
          'componentList_Flights',
        );
        if (Platform.OS === 'ios') {
          this.state.responseJson.reactTag = getRootTag();
        } else {
          downloadTicket('Flight_BaggageTag');
        }
        break;
      default:
        this.externalLinkHandler(action, '');
        break;
    }
  };

  externalLinkHandler = (action, title, iosURL) => {
    let externalUrl = action.url;
    if (Platform.OS === 'ios' && iosURL) {
      externalUrl = iosURL;
    }
    if (externalUrl) {
      if (action.openInWebView) {
        Actions.openPsWebView({
          headerText: action.webViewTitle || title,
          headerIcon: CrossIcon,
          url: externalUrl,
          whiteHeader: true,
        });
      } else {
        Linking.openURL(externalUrl);
      }
    }
  };

  onBackPopUp = () => {
    HolidayBookingTrackingHelper.trackClickEvent(PAGE_NAME, 'Holidays_Back', 'componentList');
    if (this.state.popUp !== '') {
      this.setState({
        popUp: '',
      });
    }
  };

  openVisaTab = () => {
    this.onBackPopUp();
    this.setActiveTab(tabsType.VISA);
  };

  toggleTravelProfile = () => {
    this.showPopUp(buttonTypes.TRAVELPROFILE);
  };
  toggleChecklistOverlay = () => {
    this.showPopUp(buttonTypes.WEBCHECKIN);
    HolidayBookingTrackingHelper.trackClickEvent(
      PAGE_NAME,
      'View_Flights_Checklist',
      'componentList_Flights',
    );
  };

  toggleSelectFlightOverlay = () => {
    this.showPopUp(buttonTypes.SELECT_FLIGHT);
  };

  showPopUp = (popUpOverlay) => {
    this.setState({
      popUp: popUpOverlay,
    });
  };

  navigationOptions = {
    header: null,
  };

  setActiveTab = (activeTab) => {
    this.setState({ activeTab }, () => {
      this.omnitureTracking();
    });
  };

  componentWillUnmount() {
    BackHandler.removeEventListener(HARDWARE_BACK_PRESS, this.goBack);
  }

  componentDidMount() {
    super.componentDidMount();
    const { tabsItem, activeTab } = this.state;
    if (tabsItem.length > 0) {
      const contentScrollAmount = screenWidth * activeTab;
      setTimeout(() => {
        (function (contentScrollAmount, tabContent) {
          tabContent &&
            tabContent.scrollTo({
              x: Platform.OS === 'web' ? contentScrollAmount - 1 : contentScrollAmount,
              animated: false,
            });
        })(contentScrollAmount, this.tabContent.current);
      }, 0);
    }
    HolidayBookingTrackingHelper.trackViewEvent(
      this.props.trackPageName,
      `${tabsItem[activeTab]}_List`,
      `componentList_${tabsItem[activeTab]}`,
    );
  }

  onTabClick = (tab) => {
    this.setActiveTab(tab, () => {
      this.omnitureTracking();
    });
    const contentScrollAmount = screenWidth * tab;
    this.tabContent.current.scrollTo({
      x: Platform.OS === 'web' ? contentScrollAmount - 1 : contentScrollAmount,
      animated: true,
    });
  };

  onHandleContentScroll = (event) => {
    const contentScrollWidth = screenWidth;
    const activeTab = Math.round(event.nativeEvent.contentOffset.x / contentScrollWidth);
    this.setActiveTab(activeTab);
  };

  goBack = () => {
    backPressHandler(Actions);
  };

  onHardBackPress = () => {
    const { contactInfo } = this.state;
    if (contactInfo) {
      this.setState({ contactInfo: null });
      return true;
    }
    if (this.state.popUp !== '') {
      this.setState({
        popUp: '',
      });
      return true;
    }
    backPressHandler(Actions);
    return true;
  };

  setContactInfo = (contactInfo) => {
    this.setState({ contactInfo });
  };

  callMmt = (phone = '') => {
    Linking.openURL(`tel:${phone}`);
  };

  sendMail = (email = '') => {
    const params = {};
    params.email = email;
    GenericModule.sendEmail(params);
  };

  showLocation = (url = '') => {};

  render() {
    const {
      visaPageDetails,
      flightDetailsType,
      hotelDetailsMyTrips,
      activityPageDetails,
      transfers,
      contactPageDetails,
      travellerPageDetails,
      BOOKING_ID: bookingId,
      insurancePageDetails
    } = this.props;
    const { tabsItem, loader, activeTab, contactInfo } = this.state;
    const { hotelDetails, hotelBookingMessage = null } = hotelDetailsMyTrips || {};
    const { transferDetails, transferBookingMessage = null, bannerText = 0 } = transfers;
    const holidaysSharedModule = HolidaysSharedModuleHolder.get();
    if (Platform.OS !== 'web' && holidaysSharedModule === null) {
      throw new Error('Holidays module not bootstrapped');
    }
    const { Loader: HorizontalLoader } =
      (holidaysSharedModule && holidaysSharedModule.getHolidaysSharedComponents()) || {};
    return (
      <View style={AtomicCss.flex1}>
        {contactInfo != null ? (
          <ContactInfo
            holidayContactList={contactInfo}
            callMmt={this.callMmt}
            sendMail={this.sendMail}
            showLocation={this.showLocation}
            onBackPopUp={() => {
              this.setContactInfo(null);
            }}
          />
        ) : null}
        <View style={[styles.contentWrapper, AtomicCss.flex1]}>
          {loader && HorizontalLoader && (
            <View
              style={[
                AtomicCss.flexRow,
                AtomicCss.marginTop10,
                {
                  width: screenWidth,
                  height: screenHeight,
                },
              ]}
            >
              <HorizontalLoader />
            </View>
          )}
          {tabsItem.length > 0 && (
            <React.Fragment>
              <View style={styles.topStickySection}>
                <Header title="Package" goBack={this.goBack} />
                <ScrollView horizontal showsHorizontalScrollIndicator={false}>
                  <View style={styles.TabsWrapper}>
                    {tabsItem.map((item, index) => (
                      <Tabs
                        item={item}
                        index={index}
                        key={item}
                        onHandleTabChange={this.onTabClick}
                        activeTab={activeTab}
                      />
                    ))}
                  </View>
                </ScrollView>
              </View>
              <ScrollView
                onMomentumScrollEnd={this.onHandleContentScroll}
                horizontal
                showsHorizontalScrollIndicator
                pagingEnabled
                ref={this.tabContent}
                style={styles.mainScroll}
              >
                <View style={[AtomicCss.flexRow, AtomicCss.flex1, AtomicCss.marginTop10]}>
                  {tabsItem.indexOf(tabsType.VISA) >= 0 && (
                    <VisasList
                      visaDetails={visaPageDetails}
                      bookingId={bookingId}
                      travellerDetails={travellerPageDetails}
                      pageName={PAGE_NAME}
                      toggleTravelProfile={this.toggleTravelProfile}
                      viewVisaDetail={this.props.viewVisaDetail}
                      isTravelProfileRequired={this.props.isTravelProfileRequired}
                    />
                  )}
                  {tabsItem.indexOf(tabsType.INSURANCE) >= 0 && (
                    <InsuranceDetailList
                      insuranceDetails={insurancePageDetails}
                      bookingId={bookingId}
                      pageName={PAGE_NAME}
                    />
                  )}
                  {tabsItem.indexOf(tabsType.FLIGHTS) >= 0 && (
                    <FlightDetail
                      flightData={flightDetailsType.segmentGroupDetailList}
                      bookingId={bookingId}
                      pageName={PAGE_NAME}
                      checklist={this.props.checklist}
                      toggleChecklistOverlay={this.toggleChecklistOverlay}
                      checklistActionHandler={this.checklistActionHandler}
                      holidayData={this.props.holidayData}
                    />
                  )}
                  {tabsItem.indexOf(tabsType.HOTELS) >= 0 && (
                    <ScrollView>
                      <HotelList
                        hotelData={hotelDetails}
                        topMessage={hotelBookingMessage}
                        bookingId={bookingId}
                        pageName={PAGE_NAME}
                        isBundlePackage={this.props.isBundlePackage}
                      />
                    </ScrollView>
                  )}
                  {tabsItem.indexOf(tabsType.TRANSFERS) >= 0 && (
                    <ScrollView>
                      <TransferList
                        transferDetail={transferDetails}
                        topMessage={transferBookingMessage}
                        bannerText={bannerText}
                        contactList={contactPageDetails}
                        pageName={PAGE_NAME}
                        setContactInfo={this.setContactInfo}
                        bookingId={bookingId}
                      />
                    </ScrollView>
                  )}
                  {tabsItem.indexOf(tabsType.ACTIVITIES) >= 0 && (
                    <ScrollView>
                      <ActivitiesList
                        bookingId={bookingId}
                        activityDetail={activityPageDetails}
                        contactList={contactPageDetails}
                        pageName={PAGE_NAME}
                        setContactInfo={this.setContactInfo}
                      />
                    </ScrollView>
                  )}
                </View>
              </ScrollView>
            </React.Fragment>
          )}
        </View>

        {this.state.popUp === buttonTypes.WEBCHECKIN && (
          <CheckListOverlay
            pageName="CheckListOverlay"
            checkList={this.props.checklist}
            checklistActionHandler={this.checklistActionHandler}
            onBackPopUp={this.onBackPopUp}
          />
        )}
        {this.state.popUp === buttonTypes.SELECT_FLIGHT && (
          <SelectFlights
            pageName="SelectFlightOverlay"
            flightDetailsType={flightDetailsType}
            handleButtonClick={this.handleButtonClick}
            externalLinkHandler={this.externalLinkHandler}
            holidayResponse={this.props.holidayResponse}
            onBackPopUp={this.onBackPopUp}
          />
        )}
        {this.state.popUp === buttonTypes.TRAVELPROFILE && (
          <TravelPurpose
            mmtAuth={this.props.mmtAuth}
            loggingtrackInfo={this.props.loggingTrackInfo}
            visaPageDetails={visaPageDetails}
            bookingId={bookingId}
            onBackPopUp={this.onBackPopUp}
            onSubmitSuccess={this.openVisaTab}
          />
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  topStickySection: {
    ...getPlatformElevation(2),
    backgroundColor: '#fff',
    zIndex: 10,
  },
  TabsWrapper: {
    backgroundColor: '#fff',
    flexDirection: 'row',
    justifyContent: 'space-between',
    zIndex: 1,
  },
  loaderOuter: {
    width: '100%',
  },
  mainScroll: {
    backgroundColor: '#f2f2f2',
  },
});

export default HolidayBookingTab;
