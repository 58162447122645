import React from 'react';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import PropTypes from 'prop-types';
import BaseButton, { BUTTON_VARIANT } from './BaseButton';

class RectFullBlueButton extends React.Component {
  render() {
    return (
      <BaseButton
        text={this.props.action.actionLabeltext}
        variant={BUTTON_VARIANT.PRIMARY}
        clickHandler={() => this.props.clickHandler(this.props.action)}
        buttonStyle={[AtomicCss.marginRight10, AtomicCss.marginLeft10, AtomicCss.marginBottom16]}
        textStyle={[ AtomicCss.font16, AtomicCss.lineHeight20]}
        isThrottleRequired={this.props.isThrottleRequired}
        throttleInterval={this.props.throttleInterval}
      />
    );
  }
}

RectFullBlueButton.propTypes = {
  action: PropTypes.object.isRequired,
  clickHandler: PropTypes.func.isRequired
};

export default RectFullBlueButton;
