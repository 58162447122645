import React from "react";
import { Image, ScrollView, Text, View } from "react-native";
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getStyles from './PropertyLayoutCSS';
import { isEmpty } from 'lodash';
import { FLAG_BLACK_BG } from "../../../HotelImageConstants";
import { getFont } from "apps/post-sales/src/PostSalesStyles";

const PropertyLayoutCard = ({ data }) => {
  const { fsStyle, ...fonts } = getFont(true);
  const styles = getStyles(fonts);
  if(isEmpty(data)){
    return null;
  }
  return (
    <ScrollView
      horizontal={true}
      showsHorizontalScrollIndicator={false}
      contentContainerStyle={styles.scrollWrapper}
    >
      <View style={[AtomicCss.flexRow, styles.guestWrapper]}>
        {data.map((item, index) => {
          const { title, subTitle, imageLabels, imageURIs, additionalText, desc } = !isEmpty(item) ? item : {};
          return (
            <View key={index} style={[styles.cardWrapper]}>
              <Image source={FLAG_BLACK_BG} style={styles.propertyTag} />
              {
                !isEmpty(imageLabels) && imageLabels.map(label => (
                  <Text
                    style={styles.label}
                  >
                    {label}
                  </Text>))
              }

              {!isEmpty(imageURIs) && <Image style={styles.hotelImg} source={{ uri: imageURIs[0] }} />}
              <View style={[styles.textWrapper]}>
                <Text
                  style={[
                    fsStyle.font14,
                    fonts.regularFontFamily,
                    AtomicCss.blackText,
                    AtomicCss.lineHeight20,
                    styles.marginBottom4,
                  ]}
                >
                  {!isEmpty(title) && <Text style={[fonts.blackFontFamily]}>{title} </Text>}
                  {!isEmpty(additionalText) && (
                    <Text style={[AtomicCss.midGreyText, fsStyle.font10]}>
                      {additionalText}
                    </Text>
                  )}
                </Text>
                {!isEmpty(subTitle) && <Text
                  style={[
                    fsStyle.font14,
                    AtomicCss.greyText,
                    AtomicCss.lineHeight20,
                    styles.marginBottom4,
                  ]}
                >
                  {subTitle}
                </Text>}
                {!isEmpty(desc) && <Text
                  style={[
                    fsStyle.font13,
                    fonts.regularFontFamily,
                    AtomicCss.defaultText,
                    AtomicCss.lineHeight20,
                  ]}
                >
                  {desc}
                </Text>}
              </View>
            </View>
          );
        })}
      </View>
    </ScrollView>
  );
};

export default React.memo(PropertyLayoutCard);
