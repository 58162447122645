export const initialState = {
  autocompleteResults: [],
  isSourceFocused: false,
  sourceText: '',
  destinationText: '',
};

export const ACTION_UPDATE_SOURCE_FOCUSED = '@CROSS_SELL_CABS/ACTION_UPDATE_SOURCE_FOCUSED';
export const ACTION_UPDATE_AUTOCOMPLETE_RESULTS =
  '@CROSS_SELL_CABS/ACTION_UPDATE_AUTOCOMPLETE_RESULTS';
export const ACTION_UPDATE_SOURCE_TEXT = '@CROSS_SELL_CABS/ACTION_UPDATE_SOURCE_TEXT';
export const ACTION_UPDATE_DESTINATION_TEXT = '@CROSS_SELL_CABS/ACTION_UPDATE_DESTINATION_TEXT';
export const ACTION_INIT_CITY_PICKER = '@CROSS_SELL_CABS/ACTION_INIT_CITY_PICKER';

export default function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ACTION_UPDATE_SOURCE_FOCUSED:
      return {
        ...state,
        isSourceFocused: payload,
      };
    case ACTION_UPDATE_AUTOCOMPLETE_RESULTS:
      return {
        ...state,
        autocompleteResults: payload,
      };
    case ACTION_UPDATE_SOURCE_TEXT:
      return {
        ...state,
        sourceText: payload,
      };
    case ACTION_UPDATE_DESTINATION_TEXT:
      return {
        ...state,
        destinationText: payload,
      };
    case ACTION_INIT_CITY_PICKER:
      const { sourceText, destinationText, isSourceFocused } = payload;
      return {
        ...state,
        isSourceFocused,
        sourceText,
        destinationText,
      };
    default:
      return state;
  }
}
