import React, {useState, useCallback, useEffect} from 'react';
import {View, StyleSheet} from 'react-native';
import {fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import HeaderCross from '../../../Common/HeaderCross/index';
import DynamicForm from '../DynamicForm';
import {
  HOLIDAYS_GET_VISA_PROFILE_QUESTIONS_URL,
  HOLIDAYS_SAVE_VISA_PROFILE_QUESTIONS_URL,
  getCommonHeaders
} from '../../../utils/NetworkUtils';
import NoInternetView from '@mmt/legacy-commons/Common/Components/Error/NoInternetView';
import ProgressView from '../../../Common/ProgressView/ProgressView';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import {isNetworkAvailable} from '@mmt/legacy-commons/Common/utils/AppUtils';
import {DEVICE_WINDOW} from '../../HolidayBookingConstants';
import { themeColors } from '../../../PostSalesStyles';

const ViewState = {
  LOADING: 'loading',
  SHOW_DETAIL: 'detail',
  NO_INTERNET: 'no_internet',
  DEFAULT: 'default',
  ERROR: 'error'
};


const TravePurpose = (props) => {
  const [travelPurposeData, setTravelPurposeData] = useState([]);
  const [viewState, setViewState] = useState(ViewState.LOADING);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const {
    mmtAuth, loggingTrackInfo, visaPageDetails, onBackPopUp, bookingId
  } = props;
  const [profileAnswers, setProfileAnswers] = useState([]);
  useEffect(() => {
    getTravellerPurposeData();
  },[]);

  const getTravellerPurposeData = useCallback(() => {
    fetchJSONAsync(HOLIDAYS_GET_VISA_PROFILE_QUESTIONS_URL);
  });
  const fetchJSONAsync = async (url) => {
    try {
      const hasNetwork = await isNetworkAvailable();
      if (!hasNetwork) {
        setViewState(ViewState.NO_INTERNET);
        return;
      }
      const visaProfileQuestionBody = {
        externalRefId: visaPageDetails.holidayVisaDetailsList.map(visaDetail => visaDetail.externalrefNo)
      };
      const response = await fetch(url, {
        method: 'POST',
        headers: await getCommonHeaders(mmtAuth, loggingTrackInfo),
        body: JSON.stringify(visaProfileQuestionBody)
      });
      if (response.ok) {
        const body = await response.json();
        setTravelPurposeData(body.profileQuestions);
        setViewState(ViewState.SHOW_DETAIL);
      } else {
        setTravelPurposeData({});
        setViewState(ViewState.ERROR);
      }
    } catch (error) {
      console.log(error);
      setViewState(ViewState.ERROR);
    }
  };
  const handleNextClick = (index, fieldValues) => {
    setSelectedIndex(selectedIndex + 1);
    setProfileAnswers(...profileAnswers, [{
      payRefId: travelPurposeData[selectedIndex].payRefId,
      formResponse: fieldValues
    }]);
  };
  const handleSubmitClick = (index, fieldValues) => {
    setProfileAnswers(...profileAnswers, [{
      payRefId: travelPurposeData[selectedIndex].payRefId,
      formResponse: fieldValues
    }]);
    submitTravelPurposeData([...profileAnswers, {
      payRefId: travelPurposeData[selectedIndex].payRefId,
      formResponse: fieldValues
    }]);
  };
  const submitTravelPurposeData = async (formResponse) => {
    try {
      const hasNetwork = await isNetworkAvailable();
      if (!hasNetwork) {
        setViewState(ViewState.NO_INTERNET);
        return;
      }

      const request = {
        externalRefId: bookingId,
        profileAnswers: formResponse
      };

      const response = await fetch(HOLIDAYS_SAVE_VISA_PROFILE_QUESTIONS_URL, {
        method: 'POST',
        headers: await getCommonHeaders(mmtAuth, loggingTrackInfo),
        body: JSON.stringify(request)
      });
      
      if (response.ok) {
        const body = await response.json();
        if(body.message==="Success") {
          props.onSubmitSuccess();
        }
        else {
          setViewState(ViewState.ERROR);
        }
      } else {
        setViewState(ViewState.ERROR);
      }
    } catch (error) {
      console.log(error);
      setViewState(ViewState.ERROR);
    }
  };

  const renderProgressView = () => (<ProgressView message="Loading Travel Purpose..." />);

  const renderErrorView = () => (
    <View style={{flex: 1}}>
      <ErrorView
        showRetry
        onRetry={() => {
          setViewState(ViewState.LOADING);
          fetchJSONAsync(HOLIDAYS_GET_VISA_PROFILE_QUESTIONS_URL);
        }}
        buttonGradient={themeColors.gradientBtnColor}
      />
    </View>
  );

  const renderPage = (travelPurposeData) => {
    const countryNames = visaPageDetails.holidayVisaDetailsList.map(visaDetail => visaDetail.visaCountryName);
    return (
      <View style={{flex: 1}}>
        <HeaderCross
          title="Travel Profile"
          headerShadow
          headingTxt="font18"
          handleClose={onBackPopUp}
        />
        {travelPurposeData && travelPurposeData.length>0 &&  travelPurposeData.map((item, index) =>
           index === selectedIndex && (
           <DynamicForm
             countryName={countryNames[selectedIndex]}
             showFooter
             travelPurposeData={item}
             index={index}
             onProceed={selectedIndex !== travelPurposeData.length - 1 ? handleNextClick : handleSubmitClick}
             buttonText={selectedIndex !== travelPurposeData.length - 1 ? 'CONTINUE' : 'SUBMIT'}
           />))}
      </View>
    );
  };
  const renderNoNetworkView = () => (
    <NoInternetView
      onRetry={() => {
        setViewState(ViewState.NO_INTERNET);
        fetchJSONAsync(HOLIDAYS_VISA_PROFILE_QUESTIONS_URL);
      }}
    />
  );
  return (
    <View style={styles.travelPurposeContainer}>
      {viewState === ViewState.LOADING && renderProgressView()}
      {viewState === ViewState.NO_INTERNET && renderNoNetworkView()}
      {viewState === ViewState.ERROR && renderErrorView()}
      {viewState === ViewState.SHOW_DETAIL && renderPage(travelPurposeData)}
    </View>
  );
};

const styles = StyleSheet.create({
  travelPurposeContainer: {
    flex: 1,
    position: 'absolute',
    height: DEVICE_WINDOW.height,
    top: 0,
    left: 0,
    width: DEVICE_WINDOW.width,
    zIndex: 200,
    backgroundColor: 'white',
    elevation: 10
  },
  fullPage: {backgroundColor: '#f2f2f2', flex: 1},
  desc: {
    color: '#4a4a4a',
    fontFamily: fonts.regular,
    fontSize: 12,
    lineHeight: 18
  },
  innerDetailSec: {
    paddingHorizontal: 16,
    paddingVertical: 30,
    backgroundColor: '#ffffff',
    paddingBottom: 50
  },
  btnWrapper: {
    paddingVertical: 14,
    paddingHorizontal: 15
  }
});
export default TravePurpose;

