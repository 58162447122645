import React, { useCallback, useState } from 'react';

import NameChangeReason from './ReasonSelection';
import LastNameIsRepeated from './LastNameIsRepeated';
import SubmitRequest from './SubmitRequestCard/SubmitRequest';
import { isNullOrEmptyObject } from '../../../../../Common/commonUtil';

const BOTTOM_SHEET_SCREENS = {
  lastNameRepeated: 'lastNameRepeated',
  reasonSelected: 'reasonSelected',
  submitRequest: 'submitRequest',
};

const MakeCorrection = ({ onContinueClicked, data, onClose, responseJson }) => {
  const {
    nameCorrectionRulesDetailCard,
    firstLastNameRepeatedDetailCard,
    nameCorrectionSubmitRequestCard,
  } = data || {};

  const [screen, setScreen] = useState(
    isNullOrEmptyObject(firstLastNameRepeatedDetailCard)
      ? BOTTOM_SHEET_SCREENS.reasonSelected
      : BOTTOM_SHEET_SCREENS.lastNameRepeated,
  );

  const [reasonSelected, setReasonSelected] = useState(null);

  const goToNext = useCallback(
    (item = null) => {
      if (screen === BOTTOM_SHEET_SCREENS.lastNameRepeated) {
        setScreen(BOTTOM_SHEET_SCREENS.reasonSelected);
      } else if (screen === BOTTOM_SHEET_SCREENS.reasonSelected) {
        setReasonSelected(item);
        setScreen(BOTTOM_SHEET_SCREENS.submitRequest);
      } else if (screen === BOTTOM_SHEET_SCREENS.submitRequest) {
        onContinueClicked(reasonSelected);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [screen, reasonSelected],
  );

  const onBackPress = useCallback(() => {
    if (screen === BOTTOM_SHEET_SCREENS.submitRequest) {
      setScreen(BOTTOM_SHEET_SCREENS.reasonSelected);
    } else if (screen === BOTTOM_SHEET_SCREENS.reasonSelected) {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screen, reasonSelected]);

  return (
    <>
      {screen === BOTTOM_SHEET_SCREENS.reasonSelected && (
        <NameChangeReason
          onClose={onClose}
          onContinueClicked={goToNext}
          data={{ ...nameCorrectionRulesDetailCard }}
          responseJson={responseJson}
        />
      )}
      {screen === BOTTOM_SHEET_SCREENS.lastNameRepeated && (
        <LastNameIsRepeated
          data={{ ...firstLastNameRepeatedDetailCard }}
          onClose={onClose}
          onContinueClicked={goToNext}
          responseJson={responseJson}
        />
      )}
      {screen === BOTTOM_SHEET_SCREENS.submitRequest && (
        <SubmitRequest
          reasonSelected={reasonSelected}
          goToNext={goToNext}
          handleBack={onBackPress}
          data={nameCorrectionSubmitRequestCard}
          responseJson={responseJson}
        />
      )}
    </>
  );
};

export default MakeCorrection;
