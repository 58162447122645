import React from 'react';
import { View } from 'react-native';

import createStyles from '../NameCrectRqstSbmtedCss';
import Seperator from '../../../../../../Common/CardLayoutSeperator';
import { Shimmer, PlaceholderContainerWrapper } from '../../../../../../Common/ShimmerV2';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { useTheme } from '../../../../../../theme/theme.context';

const DetailsLoadingCard = ({ data }) => {
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme);
  return (
    <View style={styles.detailSection}>
      <View style={[styles.detailCard, AtomicCss.marginBottom15]}>
        <PlaceholderContainerWrapper>
          <View>
            <View style={styles.detailsTop}>
              <Shimmer height={20} width={300} marginBottom={8} style={{ borderRadius: 2 }} />
              <Shimmer height={20} width={256} marginBottom={40} style={{ borderRadius: 2 }} />
              <Shimmer height={20} width={153} />
            </View>
            <View style={styles.segmentCard}>
              <Shimmer height={20} width={98} marginBottom={20} style={{ borderRadius: 2 }} />
              <View
                style={[styles.segmentHeadingWrapper, AtomicCss.marginBottom12, styles.lastItem]}
              >
                <Shimmer height={32} width={32} marginRight={13} style={{ borderRadius: 4 }} />
                <View>
                  <Shimmer height={20} width={145} marginBottom={8} style={{ borderRadius: 2 }} />
                  <Shimmer height={20} width={171} style={{ borderRadius: 2 }} />
                </View>
              </View>
            </View>
            <View style={styles.seperatorWrapper}>
              <Seperator />
            </View>
            <View style={styles.innerDtls}>
              <View style={[styles.dtlsRow, AtomicCss.marginBottom20]}>
                <Shimmer height={20} width={300} marginBottom={12} />
              </View>
              <View style={[styles.dtlsRow, AtomicCss.marginBottom8]}>
                <View
                  style={[
                    AtomicCss.flexRow,
                    AtomicCss.alignCenter,
                    AtomicCss.spaceBetween,
                    AtomicCss.flex1,
                  ]}
                >
                  <Shimmer height={20} width={67} marginBottom={12} />
                  <Shimmer height={20} width={67} marginBottom={12} />
                </View>
              </View>
              <View style={[styles.dtlsRow, AtomicCss.marginBottom26]}>
                <View
                  style={[
                    AtomicCss.flexRow,
                    AtomicCss.alignCenter,
                    AtomicCss.spaceBetween,
                    AtomicCss.flex1,
                  ]}
                >
                  <Shimmer height={20} width={67} marginBottom={12} />
                  <Shimmer height={20} width={67} marginBottom={12} />
                </View>
              </View>
            </View>
          </View>
        </PlaceholderContainerWrapper>
      </View>
    </View>
  );
};

export default DetailsLoadingCard;
