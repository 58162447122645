import { Animated, Image, StyleSheet, TouchableOpacity, View } from 'react-native';
import React, { useCallback, useState } from 'react';
import InputView from '../../atoms/CityInput';
// @ts-ignore
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { LocationProps } from '../../../types/globalTypes';
import SearchWidget from '@RN_UI_Lib/SearchWidget';
import SearchInfo from '@RN_UI_Lib/SearchInfo';
import { colors, fonts } from 'packages/legacy-commons/Styles/globalStyles';

const FromIcon = require('@mmt/legacy-assets/src/ic_from.webp');
const ToIcon = require('@mmt/legacy-assets/src/ic_to.webp');
const SWAP_ICON = require('@mmt/legacy-assets/src/swap_v2.webp');

interface Props {
  from: LocationProps | null;
  to: LocationProps | null;
  onClick: (isSourceClicked: boolean) => void;
  onSwap: () => void;
}

const LocationInputCard = ({ from, to, onClick, onSwap }: Props) => {
  return (
    <View style={[AtomicCss.marginTop10, AtomicCss.marginBottom10]}>
      <TouchableOpacity onPress={()=>onClick(true)}>
        <SearchWidget
            iconType={FromIcon}
            // customImgStyle={showClear && newDesignStyles.stopIcon}
            imgUrl={FromIcon}
            custContainerStyle={styles.contentContainer}
            component={
              <View style={{}}>
                <SearchInfo
                  title={'PICKUP ADDRESS'}
                  subTitle={from?.mainText ? `*${from?.mainText}*` : null}  
                  containerStyle={styles.titleContainer}
                  titleStyle={styles.titleStyle}
                  subTitleStyle={styles.subTitle}
                />
              </View>
            }
          />
        </TouchableOpacity>
      <View style={[AtomicCss.marginTop8]}>
        <TouchableOpacity onPress={()=>onClick(false)}>
            <SearchWidget
            iconType={ToIcon}
            customImgStyle={{}}
            imgUrl={ToIcon}
            custContainerStyle={styles.contentContainer}
            component={
              <View style={{}}>
                <SearchInfo
                  title={'DROP ADDRESS'}
                  subTitle={to?.mainText ? `*${to?.mainText}*`: null}
                  containerStyle={styles.titleContainer}
                  titleStyle={styles.titleStyle}
                  subTitleStyle={styles.subTitle}
                />
              </View>
            }
          />
          </TouchableOpacity>
      </View>
      {Boolean(from) && Boolean(to) && (
        <TouchableOpacity onPress={onSwap} style={styles.swapBtnContainer} activeOpacity={0.8}>
          <View>
            <Image source={SWAP_ICON} style={styles.swapIcon} />
          </View>
        </TouchableOpacity>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  swapBtnContainer: {
    position: 'absolute',
    overflow: 'hidden',
    height: 35,
    width: 35,
    top: 41,
    right: 8,
    zIndex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  swapIcon: {
    width: 35,
    height: 35,
  },
  subTitle: { 
    fontSize: 16, 
    fontFamily: fonts.black ,
    fontWeight:'900',
    lineHeight: 16
  },
  titleContainer: {
    flexGrow: 1,
  },
  contentContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    paddingRight: 35,
  },
  titleStyle: {
    fontFamily: fonts.bold,
    fontSize: 12,
    lineHeight: 14,
    color: colors.greyText1,
    fontWeight:'700',
  },
});

export default LocationInputCard;
