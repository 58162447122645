
import React from "react"
import { Image, View, Text, TouchableOpacity } from "react-native";
import createStyles from './voucherCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { COPY_ICON, VOUCHER_OFFER } from "../../../../Utils/tripGuaranteeImageConstants";
import { copyToClipboard } from '../../../../../Common/commonUtil';
import { getStaticData } from '../../../../../staticData/staticData';
import { showLongToast } from '@mmt/legacy-commons/Common/Components/Toast';
import { useTheme } from '../../../../../theme/theme.context';
import { getFont } from '../../../../../PostSalesStyles';
import HTMLViewWrapper from '../../../../../Common/HTMLViewWrapper';
import TGSBookingTrackEventHelper from "../../../../Utils/TGSBookingTrackEventHelper";
import { CLICK_TYPE, PAGE_NAME } from "../../../../Utils/TGSBookingConstants";
const VoucherInfo = ({ voucherInfo:voucherInformation }) => {
    const { heading, code, voucherInfo,backgroundColor,textColor,generated } = voucherInformation || {};
    const fonts = getFont();
    const { psTheme: theme } = useTheme();
    const styles = createStyles(theme, fonts);
    const onCopyVoucherCode = async () => {
        TGSBookingTrackEventHelper.trackClickEvents(PAGE_NAME.TRIP_DETAILS, CLICK_TYPE.COPY_VOUCHERICON_CLICK);
        const { copiedToClipboardText } = getStaticData(false);
        const copyStatus = await copyToClipboard(code);
        if (copyStatus) {
            showLongToast(copiedToClipboardText);
        }
    };
    return (
        <>
            <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, styles.voucherCodeWrapper, AtomicCss.marginTop12]}>
                <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
                    <Image style={styles.offerIconStyle} source={VOUCHER_OFFER} />
                    {!!heading && <Text style={[fonts.boldFontFamily, AtomicCss.defaultText, AtomicCss.font14]}>{heading}</Text> }
                </View>
                <TouchableOpacity disabled={!generated} onPress={onCopyVoucherCode} style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
                    <Text style={[styles.offerCodeText,fonts.boldFontFamily]}>{code}</Text>
                    {generated  &&<Image style={styles.copyIconStyle} source={COPY_ICON} />}
                </TouchableOpacity>
            </View>
            <View style={[styles.voucherCodeInfo, AtomicCss.marginTop12, AtomicCss.marginBottom10, AtomicCss.paddingLeft5,{backgroundColor : backgroundColor}]}>
                {!!voucherInfo && <HTMLViewWrapper style={[AtomicCss.font11,{color : textColor}]} htmlContent={voucherInfo} /> }
            </View>
        </>
    )
}

export default VoucherInfo;