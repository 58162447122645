import { isUserLoggedIn } from '@mmt/legacy-commons/Native/UserSession/UserSessionModule';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import OmnitureModule from '@mmt/legacy-commons/Native/OmnitureModule';
import { USER_PLATFORM } from './Constants/SimilarPropConstants';
import { getWebVisitorId } from './utils';

const getExperimentData = () => {
   return `{APE:10,PAH:5,PAH5:T,WPAH:F,BNPL:T,MRS:T,PDO:PN,MCUR:T,ADDON:T,CHPC:T,AARI:T,NLP:Y,RCPN:T,PLRS:T,MMRVER:V3,BLACK:T,EMIDT:1}`;
};

const getVisitorId = async () => {
   if (USER_PLATFORM.WEB) {
      return getWebVisitorId();
   } else if (USER_PLATFORM.IOS) {
      const deviceInfo = await OmnitureModule.deviceInfo();
      return deviceInfo.dvc_did;
   }

   const deviceInfo = await GenericModule.getDeviceInfo();
   return deviceInfo.deviceId;
}

const getAppVersion = async () => {
   if (USER_PLATFORM.WEB) {
      return "7.2.5";
   } else if (USER_PLATFORM.IOS) {
      const deviceInfo = await OmnitureModule.deviceInfo();
      return deviceInfo.dvc_app_ver;
   }

   const deviceInfo = await GenericModule.getDeviceInfo();
   return deviceInfo.appVersion;
}

const getRequestDetails = async (requestObj, isLoggedIn) => {
   const { trafficSrc = null, countryCode, cityCode } = requestObj;
   const visitorId = await getVisitorId();

   return {
      visitorId: visitorId,
      visitNumber: 1,
      trafficSource: trafficSrc,
      loggedIn: isLoggedIn,
      srCon: countryCode,
      srCty: cityCode,
      couponCount: 3,
      funnelSource: 'HOTELS',
      idContext: 'B2C',
      notifCoupon: null,
      pageContext: 'DETAIL',
      channel: 'B2Cweb',
   };
};

const getSearchCriteria = (requestObj) => {
   const { hotelId, checkIn, checkOut, roomStayCandidates, countryCode, cityCode, tripType } = requestObj;
   return {
      hotelId,
      checkIn,
      checkOut,
      roomStayCandidates,
      comparatorHotelIds: [],
      countryCode,
      cityCode,
      limit: 10,
      tripType,
   };
};

export const prepareStaticDetailRequest = async (requestObj, uuids) => {
   const imageFormat = 'webp';
   const isLoggedIn = await isUserLoggedIn();
   const requestDetail = await getRequestDetails(requestObj, isLoggedIn);
   const appVersion = await getAppVersion();
   let defailtDeviceDetails = { "appVersion": appVersion, "deviceId": "PWA", "deviceType": "Mobile", "bookingDevice": "PWA" };
   if (USER_PLATFORM.ANDROID) {
      defailtDeviceDetails = { ...defailtDeviceDetails, "deviceId": "ANDROID", "bookingDevice": "android" };
   } else if (USER_PLATFORM.iOS) {
      defailtDeviceDetails = { ...defailtDeviceDetails, "deviceId": "iOS", "bookingDevice": "ios" };
   }
   const deviceDetails = requestObj.deviceInfo ? { ...defailtDeviceDetails, ...requestObj.deviceInfo } : defailtDeviceDetails;

   return {
      deviceDetails,
      expData: getExperimentData(),
      featureFlags: {
         noOfAddons: 2,
         noOfCoupons: 3,
         noOfPersuasions: 2,
         noOfSoldouts: 1,
         bestOffersLimit: 3,
         reviewSummaryRequired: true,
         shortlistingRequired: true,
         staticData: true,
         walletRequired: true,
         unmodifiedAmenities: true
      },
      imageDetails: {
         categories: [
            {
               count: 100,
               imageFormat,
               type: 'H',  //
               height: 218,
               width: 375,
            },
            {
               count: 100,
               height: 218,
               imageFormat,
               type: 'R',  //
               width: 375,
            },
         ],
         types: [
            'professional',
            'traveller',
            'panoramic',
         ],
      },
      requestDetails: requestDetail,
      requiredApis: {
         comparatorRequired: true
      },
      reviewDetails: {
         otas: [
            'MMT',
            'TA',
            'MANUAL',
         ],
         tagTypes: [
            'BASE',
            'WHAT_GUESTS_SAY',
         ],
      },
      searchCriteria: getSearchCriteria(requestObj),
      uuids: uuids,
   };
};
