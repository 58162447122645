import React from 'react';
import { View } from 'react-native';
import { getActionButton, handleClickEvent } from '../utils/HotelBookingDetailUtil';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import isEmpty from 'lodash/isEmpty';

interface SummaryInvoicesCardProps {
  response: any;
  card: any;
  pageName: string;
  handleRedirectLink: (link: string) => void;
}

const SummaryInvoicesCard: React.FC<SummaryInvoicesCardProps> = ({ response, card, pageName, handleRedirectLink }) => {

  const handleClick = (action: any) => {
    handleClickEvent(card, { ...action, actionHandler: handleRedirectLink }, response, pageName);
  };

  return (
    <View style={[AtomicCss.whiteCardNew, AtomicCss.marginHz12]}>
      {card && !isEmpty(card.actionList) &&
        getActionButton(card, response, handleClick)
      }
    </View>
  );
}

export default SummaryInvoicesCard;
