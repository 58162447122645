import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';

const styles = {
  whiteWrapper: {
    backgroundColor: '#ffffff',
    marginBottom: 10,
    ...getPlatformElevation(2),
  },
  cancInfoHeading: {
    ...getPlatformElevation(2),
    padding: 16,
    backgroundColor: '#fff',
    marginBottom: 2,
  },
  arrowBlkStyle: {
    width: 9,
    height: 8,
    marginHorizontal: 6,
  },
  blueArrowStyle: {
    width: 14,
    height: 9,
  },
  arrowWrapper: {
    width: 50,
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    right: 0,
  },
  arrowActiveStyle: {
    transform: [
      {
        rotate: '180deg',
      },
    ],
  },
  tripInfoWrapper: {
    padding: 10,
  },
  hideshadow: {
    elevation: 0,
    backgroundColor: 'transparent',
  },
  collapsedDetails: {
    ...getPlatformElevation(2),
    borderTopColor: '#d2d2d2',
    borderTopWidth: 1,
    paddingVertical: 12,
    paddingHorizontal: 16,
    backgroundColor: '#fff',
    marginTop: -2,
  },
  digitIconStyle: {
    width: 56,
    height: 56,
    marginRight: 10,
  },
  digitImg: {
    width: 30,
    height: 15,
    marginRight: 5,
  },
};

export default styles;
