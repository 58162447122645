import isEmpty from 'lodash/isEmpty';
import React, { useCallback } from 'react';
import { Image, Text, View } from 'react-native';
import HTMLView from 'react-native-htmlview';
import { getStaticData } from '../../../staticData/staticData';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { DOOR_WITH_BG_ICON } from '../../HotelImageConstants';
import {
    getActionButton, handleClickEvent,
    isNotNullAndEmpty, getThrottleParams
} from '../utils/HotelBookingDetailUtil';
import getStyles from './HotelMamiCss';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const HotelModificationCard = ({card, response, actionHandler, pageName }) => {
    const { fsStyle, ...fonts } = getFont(true);
    const styles = getStyles(fonts);
   const { lblChangeBooking } = getStaticData(true);
   const handleClick = useCallback((action) => {
        handleClickEvent(card, {...action, actionHandler}, response, pageName);
    },[card, actionHandler, response, pageName]);

    return(
        <View style={[AtomicCss.whiteCardNew, AtomicCss.marginHz12, AtomicCss.borderRadius16]}>
        <View style={[styles.CardContentNew, {paddingBottom: -10}]}>
          <View style={styles.ContentPortion}>
            <Text style={[styles.blackFont, styles.BlackText, styles.mar10Bt, fsStyle.font16, AtomicCss.lineHeight20]}>
              {lblChangeBooking}
            </Text>
            
              <View style={AtomicCss.marginBottom20}>
                <HTMLView
                  value={card.instruction}
                />
              </View>
          </View>
        </View>
     {
        !isEmpty(card) && isNotNullAndEmpty(card.actionList) && 
            getActionButton(card, response, handleClick, getThrottleParams)
     }
    </View>
    )
}

export default React.memo(HotelModificationCard);
