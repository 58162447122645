import React from 'react';
import {View, Text, Image, TouchableOpacity} from 'react-native';
import _ from 'lodash';
import styles from './CarAssignedDtlsCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import Button from '../../../../Common/Button';
import {handleActionClicks} from '../../../utils/SelfDriveBookingDetailUtil';
import ZoomCarConstants from '../../../utils/ZoomCarConstants';
import {DRIVER_ICON} from '../../../SelfDriveImageConstant';

const CarAssignedDtlsCArd = ({card, response}) => {
  const {model, licenseNo} = response.vehicleInfo;
  const ctaEnabled = response.pickupProcess && response.pickupProcess.filledCheckListCTAEnabled;
  return (
    <View style={[styles.cardBorder]}>
      <View style={styles.carDtlsCardWrapper}>
        <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween, AtomicCss.marginBottom10]}>
          <View style={AtomicCss.flex1}>
            <Text
              style={[AtomicCss.midGreyText, AtomicCss.boldFont, AtomicCss.font10, AtomicCss.marginBottom8]}
            >{card.header}
            </Text>
            <View style={[AtomicCss.flexRow, AtomicCss.flex1, AtomicCss.alignCenter]}>
              <Text
                style={[AtomicCss.blackFont, AtomicCss.blackText, AtomicCss.font18]}
              >{licenseNo}
              </Text>
              <Text numberOfLines={1} ellipsizeMode="tail" style={[styles.carNameTxt]}>{model}</Text>
            </View>
          </View>
          {!_.isEmpty(response.additionalInfo.OTP) && <View>
            <Text
              style={[AtomicCss.midGreyText, AtomicCss.boldFont, AtomicCss.font10, AtomicCss.marginBottom8, AtomicCss.alignRight]}
            >OTP
            </Text>
            <View style={styles.otpWrapper}>
              <Text
                style={[AtomicCss.blackFont, AtomicCss.whiteText, AtomicCss.font18]}
              >{response.additionalInfo.OTP}
              </Text>
            </View>
          </View>}
        </View>
        <View style={[styles.driverDtlsWrapper, AtomicCss.marginBottom18, AtomicCss.flexRow, AtomicCss.spaceBetween]}>
          {
                  !_.isEmpty(response.driverInfo) && !_.isEmpty(response.driverInfo.name) &&
                  <View style={[AtomicCss.flexRow, AtomicCss.flex1]}>
                    <Image
                      style={styles.driverImgStyle2}
                      source={DRIVER_ICON}
                    />
                    <Text style={[AtomicCss.midGreyText, AtomicCss.boldFont, AtomicCss.font10, AtomicCss.marginBottom5]}>
                    CAR ASSIGNED
                    </Text>
                    <Text style={[AtomicCss.blackFont, AtomicCss.blackText, AtomicCss.font14]}>
                      {response.driverInfo.name}
                    </Text>
                  </View>
              }
          {!_.isEmpty(response.driverInfo) && !_.isEmpty(response.driverInfo.contactNo) &&
            <TouchableOpacity
              style={[AtomicCss.flexRow, AtomicCss.alignCenter]}
              onPress={() => {
                        handleClick(ZoomCarConstants.ACTION_CALL_TO_AGENT);
                    }}
            >
              <Image style={styles.callIconStyle} source={require('@mmt/legacy-assets/src/call-icon2.webp')} />
              <Text style={[AtomicCss.azure, AtomicCss.boldFont, AtomicCss.font14]}>Call Agent</Text>
            </TouchableOpacity>}
        </View>
        <View style={styles.depositeCard}>
          <View style={styles.imgPlaceHolder}>
            <Image
              style={styles.driverImgStyle}
              source={require('@mmt/legacy-assets/src/driverImgWhite.webp')}
            />
          </View>
          {fetchSubHeader(card.subHeaders)}
        </View>
        { !_.isEmpty(ctaEnabled) && ctaEnabled && <View style={styles.btnWrapper}>
          <Button
            btnTxt="FILL CHECKLIST TO START TRIP"
            btnBg="gradientBtn"
            btnType="Capsule"
            clickHandler={handleClick}
            btnClickAction={ZoomCarConstants.ACTION_FILL_CHECK_LIST}
          />
        </View>}
      </View>

    </View>
  );

  function handleClick(action) {
    handleActionClicks(card, action, response, 'CAR ASSIGNED ACTION CLICK').then(r => '');
  }

  function fetchSubHeader(subHeader) {
    const views = [];
    Object.keys(subHeader).map((key) => {
      views.push(<Text style={[AtomicCss.font12, AtomicCss.defaultText, AtomicCss.regularFont, AtomicCss.flex1]}>
        {subHeader[key]}
                 </Text>);
    });
    return views;
  }
};


export default CarAssignedDtlsCArd;
