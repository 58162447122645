import { NativeModules } from 'react-native';
import { getCommonHeaders } from "@rn/apps/post-sales/src/utils/NetworkUtils";
import { getRequestHeader } from './NetworkModule';
import { defaultURLParams } from "../router/routerFluxMock";
import {
    DOWNLOAD_HOLIDAY_VOUCHERS_PDF,
    DOWNLOAD_HOLIDAY_INVOICE_PDF,
    DOWNLOAD_VOUCHER_STATES
} from "../const";
import { downloadTicket } from './FlightBookingModule';
import { convertStringToBlob, appendURLParams } from '../webUtils';
import { HOLIDAY_ROUTE_URLS } from '../router/Routes/Holidays';
import  customRouter  from '../router/RouterCustomStack';
import { getStaticData } from '@rn/apps/post-sales/src/staticData/staticData';

if (!Promise.allSettled) {
    Promise.allSettled = function (promises) {
      return Promise.all(
        promises.map((p) =>
          Promise.resolve(p).then(
            (value) => ({
              status: "fulfilled",
              value,
            }),
            (reason) => ({
              status: "rejected",
              reason,
            })
          )
        )
      );
    };
  }

const downloadTickets = async (
    vouchers, 
    bookingId, 
    voucherStatus,
    setVoucherStatus,
    defaultErrorMessage,
    voucherIndex = 0,
  )=>{
    const voucherDetails = JSON.parse(vouchers);
    await Promise.allSettled(
        voucherDetails.map(async(voucherDetail, index) => {
          if (voucherDetails.length === 1) {
            index = voucherIndex;
          }
          if(voucherDetail.type === "HOLIDAY"){
            await downloadHolidayVoucher(
                bookingId, 
                index, 
                voucherStatus,
                setVoucherStatus,
                defaultErrorMessage,
            );
          } else if (voucherDetail.type === "ADDON") {
              await downloadAddonVoucher(
                  voucherDetail, 
                  bookingId, 
                  index, 
                  voucherStatus,
                  setVoucherStatus,
                  defaultErrorMessage,
              );
          } else if ( voucherDetail.type === "ADDON_VIATOR"){
              downloadViatorVoucher(
                  voucherDetail, 
                  index,
                  voucherStatus,
                  setVoucherStatus,
                  defaultErrorMessage,
              );
          } else if(voucherDetail.triggerName == 'Holiday_CustomerInvoice') {
            await downloadInvoice(voucherDetail, bookingId);
          } else if (voucherDetail.triggerName == 'Flight_BaggageTag'){
            await downloadTicket(voucherDetail.triggerName, bookingId);
          }
        })
      );
}
const downloadHolidayVoucher = async ( 
    bookingId,
    index, 
    voucherStatus,  
    setVoucherStatus,
    defaultErrorMessage, 
    lang
  ) => {
  voucherStatus[index] = { state: DOWNLOAD_VOUCHER_STATES.PROGRESS, message: '' };
  setVoucherStatus(voucherStatus);
  let holidayVoucherBody = {
      triggerName: "Holiday_BookingCard",
      pdf: true,
      bookingId: bookingId,
    };
  const result = await downloadFile(DOWNLOAD_HOLIDAY_VOUCHERS_PDF, "POST", bookingId, holidayVoucherBody, "Holidays_BookingCard", lang);
  if (result) {
    const { downloadSuccessText } = getStaticData();
    voucherStatus[index] = {
      state: DOWNLOAD_VOUCHER_STATES.SUCCESS,
      message: downloadSuccessText,
    };
  } else {
    voucherStatus[index] = {
      state: DOWNLOAD_VOUCHER_STATES.RETRY,
      message: defaultErrorMessage,
    };
  }
  setVoucherStatus(voucherStatus);
};
const downloadAddonVoucher = async (
    voucherDetail, 
    bookingId,
    index,
    voucherStatus,  
    setVoucherStatus,
    defaultErrorMessage,  
    lang
  ) => {
  voucherStatus[index] = { state: DOWNLOAD_VOUCHER_STATES.PROGRESS, message: '' };
  setVoucherStatus(voucherStatus);
    let addonVoucherBody = {
        triggerName: "Holiday_OfflineVoucher",
        pdf: true,
        bookingId: bookingId,
        voucherId: voucherDetail.voucherId
      };
    const result = await downloadFile(DOWNLOAD_HOLIDAY_VOUCHERS_PDF, "POST", bookingId, addonVoucherBody, "Holidays_Addon_Voucher", lang);
    if (result) {
      const { downloadSuccessText } = getStaticData();
      voucherStatus[index] = {
        state: DOWNLOAD_VOUCHER_STATES.SUCCESS,
        message: downloadSuccessText,
      };
    } else {
      voucherStatus[index] = {
        state: DOWNLOAD_VOUCHER_STATES.RETRY,
        message: defaultErrorMessage,
      };
    }
    setVoucherStatus(voucherStatus);
  };

const downloadViatorVoucher = (
      voucherDetail, 
      index,
      voucherStatus,
      setVoucherStatus,
      defaultErrorMessage
  ) => {
  const { downloadSuccessText } = getStaticData();
  voucherStatus[index] = { state: DOWNLOAD_VOUCHER_STATES.PROGRESS, message: '' };
  setVoucherStatus(voucherStatus);
  if(voucherDetail.voucherUrl) {
    window.open(voucherDetail.voucherUrl);
    voucherStatus[index] = { state: DOWNLOAD_VOUCHER_STATES.SUCCESS, message: downloadSuccessText };
    setVoucherStatus(voucherStatus);
  } else {
    voucherStatus[index] = { state: DOWNLOAD_VOUCHER_STATES.RETRY, message: defaultErrorMessage };
    setVoucherStatus(voucherStatus);
  }
}
const downloadInvoice = async ( voucherDetail, bookingId, lang) => {
  let invoiceVoucherBody = {
    userId: "PWA",
    pdf: true,
    bookingId: bookingId,
  };
  downloadFile(DOWNLOAD_HOLIDAY_INVOICE_PDF, "POST", bookingId, invoiceVoucherBody, 'Holidays_Invoice', lang);
}

const handleDownloadResponseError=(response)=> {
    if (!response.ok) {
      throw Error(response.status + " " + response.statusText);
    }
    const responseBody = response.json();
    return responseBody;
  };

const downloadFile = async (url, method, bookingId, body, fileName, lang, index=0) => {
  const userInfo = await getRequestHeader(bookingId, fileName);
  const headers = await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo);
  headers.language = lang || headers.language;
  return fetch(url, {
    method: method,
    headers: headers,
    body: JSON.stringify(body),
  })
    .then((result) => handleDownloadResponseError(result))
    .then((responseBody) => {
      if (responseBody && responseBody.success && (responseBody.pdf || responseBody.mailHtml)){
        const download = require("downloadjs");
        if (responseBody.mailHtml && !responseBody.pdf) {
          download(
            new Blob([responseBody.mailHtml]),
            fileName + '.html',
            "text/html"
          );
          return true;
        }
        let blob = convertStringToBlob(
          responseBody.pdf,
          "application/pdf"
        );
        download(
          blob,
          `${fileName}_${index}.pdf`,
          "application/pdf"
        );
        return true;
      } else {
        return false;
      }
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
};

function resetToHolidayBookingDetail(bookingId) {
  const url = appendURLParams(HOLIDAY_ROUTE_URLS.DETAIL_PAGE, { bookingId }, defaultURLParams);
  customRouter.popToRoute(url);
}

NativeModules.HolidayBookingModule = {
    downloadTickets,
    resetToHolidayBookingDetail
};