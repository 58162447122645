import React, {useEffect} from 'react';
import { View, Image, Text, ScrollView, Linking, Dimensions } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import isEmpty from 'lodash/isEmpty';
import { getFont } from '../../PostSalesStyles';
import { useTheme } from '../../theme/theme.context';
import { getStyle } from './MmtSelectNewUserCss';
import { getStaticData } from '../../staticData/staticData';
import { getMMTSTrackingEventName, openLink } from '../commonUtil';
import MMTSelectTrackingHelper from './MMTSelectTrackingHelper'
import GenericModule from 'packages/core/native/GenericModule';

const BenefitsCards = ({ item, firstEle, lastEle, state, pageName, tierName }) => {
  const { ...fonts } = getFont(true);
  const { psTheme } = useTheme();
  const styles = getStyle(psTheme, fonts);
  const firstCardStyle = firstEle ? 'firstCard' : '';
  const lastCardStyle = lastEle ? 'lastCard' : '';
  const lastTextStyle = lastEle ? 'clickableText' : '';
  const { iconUrl, heading, actionLink, contactNo } = item;
  const telephoneURL = `tel:${contactNo}`;
  const {contactUsText} = getStaticData();
  const onClickHandler = (actionLink = '',type) => {
    Linking.canOpenURL(actionLink).then(
      (supported) => {
        if (supported){ 
          Linking.openURL(actionLink)};
          MMTSelectTrackingHelper.trackMMTSClickEvent(pageName,getMMTSTrackingEventName(tierName, state, 'click',type));
      },
      (err) => console.log(err),
    );
  }
  return (
    <View style={[styles.benefitsCards, styles[firstCardStyle], styles[lastCardStyle]]}>
      {!isEmpty(iconUrl) && (
        <View style={styles.iconWrapper}>
          <Image style={styles.benefitsIcon} source={{ uri: iconUrl }} />
        </View>
      )}
      <View style={styles.textDiv}>
        <Text
          style={[styles.benefitsText, styles[lastTextStyle]]}
          onPress={() => {
            if (actionLink) {
              GenericModule.openDeepLink(actionLink);
              MMTSelectTrackingHelper.trackMMTSClickEvent(pageName,getMMTSTrackingEventName(tierName, state, 'click','explore'));
            }
          }}
        >
          {heading}
        </Text>
        {item.code === 'CONTACT_US' && <Text style={styles.clickableText} 
        onPress = { () => {
            onClickHandler(telephoneURL,'contactus');
          }
        }>{contactUsText}</Text>}
      </View>
    </View>
  );
};

const MMTSelectNewUserCard = (props) => {
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme } = useTheme();
  const {color} = psTheme;
  const styles = getStyle(psTheme, fonts);
  const { heading, description, list, backgroundGradient, mmtSelectLogo, tierName } =
    props.mmtSelectData || {};
  let color1=color.white, color2=color.white;
  if(!isEmpty(backgroundGradient) && backgroundGradient.length>1){
    color1=backgroundGradient[0];
    color2=backgroundGradient[1];
  }

  const { state = '' } = props.bookingState || {};

  const defaultDeviceWidth = 360;
  const defaultImageHeight = 28;
  const width340 = 340 / defaultDeviceWidth * Dimensions.get('window').width;
  const height35 = width340 / Dimensions.get('window').width * defaultImageHeight;


  useEffect(() => {
    MMTSelectTrackingHelper.trackMMTSDisplayEvent(props.pageName,getMMTSTrackingEventName(tierName,state,'display'));
  },[]);
  return (
    <View style={styles.mmtSelectWrapper}>
      <View style={styles.cardHeadingWrapper}>
        {!isEmpty(mmtSelectLogo) && <Image
          style={[styles.cardTitleStyle,{height: height35}, {width: width340 - 14}]}
          source={{uri : mmtSelectLogo}}
        />}
      </View>
      <LinearGradient
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 0 }}
        colors={[color1, color2]}
        style={styles.cardDetailsWrapper}
      >
        <View style={styles.cardTopSection}>
          <View style={styles.cardTopDetails}>
            {!isEmpty(heading) && <Text style={styles.detailHeading}>{heading}</Text>}
            {!isEmpty(description) && <Text style={styles.detailInfo}>{description}</Text>}
          </View>
        </View>
        <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
          {!isEmpty(list) && list.map((item, index) => {
            return (
              <BenefitsCards
                item={item}
                index={index}
                firstEle={index === 0}
                lastEle={index === list.length - 1}
                state = {state}
                pageName = {props.pageName}
                tierName = {tierName}
              />
            );
          })}
        </ScrollView>
      </LinearGradient>
    </View>
  );
};

export default MMTSelectNewUserCard;
