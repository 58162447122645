import { dayTime, Months } from "./staticData";

export const getIntialState = (selectedDate) => {
    const date =  new Date(selectedDate);
    const day = date.getDate();
    const month = Months[date.getMonth()];
    const year = date.getFullYear();
    let hour = date.getHours();
    const minute = date.getMinutes();
    const ampm = hour >= 12 ? dayTime.PM:dayTime.AM;
    hour = hour % 12;
    hour = hour ? hour : 12;
  
    return {
      day,
      month,
      year,
      hour: hour < 10 ? `0${hour}` : hour,
      minute: minute < 10 ? `0${minute}` : minute,
      ampm,
    };
  };


export function isLeapYear(year) {
  return (year % 4 == 0 && year % 100 != 0) || year % 400 == 0;
}


export const getDateFromDateObject = (dateTimeObject) => {
  let { hour, ampm } = dateTimeObject;
  if (hour == '12') {
    hour = '00';
  }
  if (ampm === 'PM') {
    hour = parseInt(hour, 10) + 12;
  }

  return new Date(
    dateTimeObject.year,
    Months.indexOf(dateTimeObject.month),
    dateTimeObject.day,
    hour,
    dateTimeObject.minute,
  );
};


export const getDays = (days) => {
  return Array(days)
    .fill(0)
    .map((_, index) => index + 1);
};

export const handleDropDownData = (currentDropDownData, dateTimeObject) => {
  let days = currentDropDownData.day;

  switch (dateTimeObject.month) {
    case 'Apr':
    case 'Jun':
    case 'Sep':
    case 'Nov':
      days = getDays(30);
      break;

    case 'Feb':
      if (isLeapYear(dateTimeObject.year)) {
        days = getDays(29);
      } else {
        days = getDays(28);
      }
      break;

    default:
      days = getDays(31);
  }

  return {
    ...currentDropDownData,
    day: days,
  };
};

export const checkValidation = ({ minDate, maxDate, selectedDate }) => {

  if (
    (minDate && selectedDate.getTime() < new Date(minDate).getTime()) ||
    (maxDate && selectedDate.getTime() > new Date(maxDate).getTime())
  ) {
    return false;
  } else {
    return true;
  }
};
