import { Dimensions, StyleSheet, Platform } from 'react-native';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';

const windowHeight = (Platform.OS === 'android' ||  Platform.OS === 'web') ? Dimensions.get('window').height : Dimensions.get('screen').height;

const getStyles = (fonts) => {
  return StyleSheet.create({
    OverlayContentWrapper: {
      maxHeight: Platform.OS === 'web' ? null : 0.70 * windowHeight,
      backgroundColor: colors.white,
      borderTopLeftRadius: 16,
      borderTopRightRadius: 16
    },
    overlayTopline: {
      width: 40,
      height: 4,
      borderRadius: 4,
      marginTop:5,
      backgroundColor: colors.disabledButton,
      alignSelf: 'center',
    },
    title: {
      color: colors.black,
      ...fonts.font22,
      fontFamily: fonts.black,
      marginBottom: 32,
      paddingHorizontal:10,
      lineHeight: 32,
    },
    refundTxtColor: {
      color: colors.green,
    },
    nonRefundTxtColor: {
      color: colors.red,
    },
    trackerWrapper: {
      borderTopWidth: 1,
      borderTopColor: colors.lightGrey,
      marginLeft: -30,
      flex: 1,
      paddingVertical: 34,
    },
    extraInfoWrapper: {
      marginTop: -20,
      marginBottom: 16
    },
    scrollWrapper: {
      paddingLeft: 30,
      paddingRight: 30,
    },
    dismissWrapper: {
      borderTopWidth: 1,
      borderTopColor: colors.lightGrey,
      paddingHorizontal:20,
      paddingVertical:10,
      flexDirection: 'row',
      justifyContent: 'space-between',
      zIndex: 2,
      alignItems:"center"
    },
    dismissBtn: {
      paddingHorizontal: 20,
      paddingVertical: 12,
    },
    itemDisc: {
      width: 6,
      height: 6,
      borderRadius: 25,
      backgroundColor: colors.defaultTextColor,
      marginRight: 15,
      marginTop: 10
    },
    stickyWrapper: {
      ...Platform.select({
        web: {
          position: 'sticky',
        },
      }),
      top: 0,
      zIndex: 10,
      backgroundColor: 'white',
      paddingTop: 12,
    },
  });
}

export default getStyles;
