import React from 'react';
import useCrossSellStore from '../../../hooks/useCrossSellStore';
import { ACTION_UPDATE_LOCATION } from '../../../reducers/crossSellReducer';
import LocationInputCard from './LocationInputCard';

interface Props {
  onClick: () => void;
}

const Container = ({ onClick }: Props) => {
  const [state, dispatch] = useCrossSellStore();
  const { sourceLocation, destinationLocation } = state;
  const onSwap = () => {
    dispatch({
      type: ACTION_UPDATE_LOCATION,
      payload: { sourceLocation: destinationLocation, destinationLocation: sourceLocation },
    });
  };
  return (
    <LocationInputCard
      from={sourceLocation}
      to={destinationLocation}
      onClick={onClick}
      onSwap={onSwap}
    />
  );
};

export default Container;
