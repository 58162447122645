import React from 'react';
import { StyleSheet, Text } from 'react-native';
import NewCommonOverlay from '../NewCommonOverlay';
import DrumRollCalendar from '@RN_UI_Lib/DrumRollCalendar';
import { Platform } from 'react-native';
import { useTheme } from '../../theme/theme.context';
import { getFont } from '../../PostSalesStyles';

const monthMap = {
  Jan: 0,
  Feb: 1,
  Mar: 2,
  Apr: 3,
  May: 4,
  Jun: 5,
  Jul: 6,
  Aug: 7,
  Sep: 8,
  Oct: 9,
  Nov: 10,
  Dec: 11,
} as const;

const monthArr = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
] as const;

interface DobCalendarProps {
  handleClose: () => void;
  confirmPress: (date: string) => void;
  initDOB?: string;
  popupTitle?: string;
  popupTitleStyle?: Record<string, number | string>;
  confirmBtnTitle?: string;
  confirmBtnStyle?: Record<string, number | string>;
  title?:string;
  customTitleStyle?:Record<string,string>;
}

const DobCalendar = ({
  handleClose,
  confirmPress,
  initDOB = '',
  popupTitle = '',
  confirmBtnTitle = 'SAVE DOB',
  popupTitleStyle = {},
  confirmBtnStyle = {},
  customTitleStyle={},
  title,
}: DobCalendarProps) => {
  const { psTheme: theme } = useTheme();
  const { fsStyle, ...fonts } = getFont(true);

  const onConfirm = (day: number, month: string, year: number) => {
    const monthIndex = monthMap[month] + 1;
    const formattedDate = `${year}-${(monthIndex < 10 ? '0' : '') + monthIndex}-${
      (day < 10 ? '0' : '') + day
    }`;
    confirmPress(formattedDate);
  };

  const getInitDOB = (initDOB?: string) => {
    if (!!initDOB) {
      const splitDateString = initDOB.split('-');
      const parsedYear = Number(splitDateString[0]);
      const parsedMonth = Number(splitDateString[1]);
      const monthAsString = monthArr[parsedMonth - 1];
      const parsedDate = Number(splitDateString[2]);

      return { initialDate: parsedDate, initialMonth: monthAsString, initialYear: parsedYear };
    } else {
      const currDate = new Date();
      const parsedDate = currDate.getDate();
      const parsedMonth = currDate.getMonth();
      const monthAsString = monthArr[parsedMonth];
      const parsedYear = currDate.getFullYear();

      return { initialDate: parsedDate, initialMonth: monthAsString, initialYear: parsedYear };
    }
  };

  const { initialDate = 1, initialMonth = 'Jan', initialYear = 1970 } = getInitDOB(initDOB) || {};
  const styles = getStyle(theme, fonts);
  return (
    <NewCommonOverlay
      roundBorder={true}
      handleBottomOverlay={handleClose}
      styleContent={styles.overlayContent}
    >
      {!!title && <Text style={[customTitleStyle]}>{title}</Text>}
      <DrumRollCalendar
        minYear={new Date().getFullYear() - 120}
        maxYear={new Date().getFullYear()}
        initialDate={initialDate}
        initialMonth={initialMonth}
        initialYear={initialYear}
        showWrapStyle={false}
        txtStyleHeading={{ ...styles.customTxtStyleHeading, ...popupTitleStyle }}
        calendarTitle={popupTitle}
        onSave={onConfirm}
        scrlContainer={styles.columnWidth}
        selectedDateClr={theme.color.darkBlue}
        unselectedDateClr={theme.color.black}
        btnContainer={styles.hline}
        initialScroll={Platform.OS === 'web'}
        showInfiniteScroll={false}
        testIds={{
          saveBtnId: 'calendarSaveBtn',
          calendarTitleId: 'calendarTitleText',
        }}
      >
        <Text style={{ ...styles.cofirmBtnStyle, ...confirmBtnStyle }}>{confirmBtnTitle}</Text>
      </DrumRollCalendar>
    </NewCommonOverlay>
  );
};

const getStyle = (theme, fonts) => {
  return StyleSheet.create({
    container: {
      flex: 1,
    },
    customTxtStyleHeading: {
      alignSelf: 'center',
      marginTop: 10,
      ...fonts.regularFontFamily,
      fontSize: fonts.fontSize.font16,
    },
    cofirmBtnStyle: {
      color: theme.color.darkBlue,
      fontWeight: 'bold',
    },
    overlayContent: {
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.color.white,
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
    },
    columnWidth: { width: 100 },
    libBtnContainer: {
      marginTop: 10,
      marginBottom: 10,
    },
    hline: {
      width: 100,
      marginTop: 10,
      marginBottom: 10,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignSelf: 'center',
    },
  });
};

export default DobCalendar;
