import React, {useState, useEffect} from 'react';
import {View, Text, StyleSheet, TouchableOpacity, Image} from 'react-native';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';
import {fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import RadioBtn from './RadioBtn';
import DocumentUploadPreStatusCard from './DocumentUploadPreStatusCard.js';
import {DocIconPending, DocIcon, CollapseArrow} from '../HolidayBookingConstants';

const getActiveIndex = (docsList) => {
  let activeIndex = -1;
  let submittedActiveIndex = -1;
  let docsLength = 1000;
  Object.keys(docsList)
    .forEach((docType, index) => {
      const docData = docsList[docType];
      const submittedList = docData.filter(doc => doc.uploadStatus === 'Submitted');
      if (!isEmpty(submittedList) && docData.length < docsLength) {
        docsLength = docData.length;
        submittedActiveIndex = index;
      } else if (docData.length < docsLength) {
        docsLength = docData.length;
        activeIndex = index;
      }
    });
  return submittedActiveIndex > -1 ? submittedActiveIndex : activeIndex;
};

const getDocStatusHeading = (docsList, activeState) => {
  let uploaded = 0;
  let required = 0;
  let activeDocList = '';
  Object.keys(docsList)
    .forEach((docType) => {
      if (docType === activeState) {
        activeDocList = docsList[docType];
      }
    });
  activeDocList.forEach((doc) => {
    if (doc.fileName !== null && doc.uploadStatus === 'Submitted') {
      uploaded += 1;
    }
  });
  required = activeDocList.length;
  return uploaded === required ? 'Document Uploaded' : `${uploaded}/${required} Uploaded`;
};

const getDocStatusHeadingOnClick = (docsList, activeState, traveller) => {
  let uploaded = 0;
  let required = 0;
  let activeDocList = docsList[traveller][activeState].docs;
  Object.keys(activeDocList)
    .forEach((docType) => {
      const docData = activeDocList[docType];
      if (docData.uploadStatus === 'Submitted') {
        uploaded += 1;
      }
    });
  required = Object.keys(activeDocList).length;
  return uploaded === required ? 'Document Uploaded' : `${uploaded}/${required} Uploaded`;
};

export default function DocumentUploadCard(props) {
  const [state, setState] = useState({
    activeState: Object.keys(props.docsList)[getActiveIndex(props.docsList)],
    isExpanded: props.isExpanded,
    overlay: '',
    requiredDocsUploaded: false,
    overallUploadStatus: props.overallUploadStatus,
    uploaded: props.noOfUploadedDocPax,
    docStatusHeading: getDocStatusHeading(props.docsList, Object.keys(props.docsList)[getActiveIndex(props.docsList)])
  });

  const updateRequiredAndUploadCount = (activeState) => {
    if (props.travellerDocumentsData && Object.keys(props.travellerDocumentsData).length) {
      if (props.travellerDocumentsData[props.traveller]) {
        const {required, uploaded} =
        props.travellerDocumentsData[props.traveller][activeState] || {};
        setState((state) => {
          if (state.requiredDocsUploaded) {
            return {
              ...state,
              activeState,
              docStatusHeading: 'Document Uploaded'
            };
          }
          return {
            ...state,
            requiredDocsUploaded: uploaded === required,
            uploaded,
            activeState,
            docStatusHeading: getDocStatusHeadingOnClick(props.travellerDocumentsData, activeState, props.traveller)
          };
        });
      }
    }
  };

  useEffect(() => {
    updateRequiredAndUploadCount(state.activeState);
  }, [props.travellerDocumentsData]);

  const handleRadioBtn = (active) => {
    updateRequiredAndUploadCount(active);
  };

  const handleToggleTraveller = () => {
    props.handleButtonClick(
      `Traveller${props.traveller}_ClickTo${state.isExpanded ? 'Collapse' : 'Open'}`
    );
    setState((state) => ({
      ...state,
      isExpanded: !state.isExpanded
    }));
  };

  const renderRadioButtonAndTravellerDocuments = () => {
    if (!state.isExpanded) {
      return null;
    }
    const filtered = Object.keys(props.docsList)
      .filter((docName) => docName === state.activeState);
    if (!filtered) {
      return null;
    }
    const [docName] = filtered;
    const docDetails = props.docsList[docName];
    if (!docDetails) {
      return null;
    }
    return (
      <React.Fragment>
        {props.radioButton && (
          <View style={[styles.radioWrapper, AtomicCss.marginBottom24]}>
            <Text
              style={[
                AtomicCss.blackText,
                AtomicCss.boldFont,
                AtomicCss.font14
              ]}
            >
              {text}
            </Text>
            <View style={styles.RadioBtnWrapper}>
              {Object.keys(docsList)
                .map((item, index) => {
                  return (
                    <RadioBtn
                      item={item}
                      traveller={props.traveller}
                      index={index}
                      handleRadioBtn={handleRadioBtn}
                      activeState={state.activeState}
                      handleButtonClick={props.handleButtonClick}
                    />
                  );
                })}
            </View>
          </View>
        )}
        {state.requiredDocsUploaded && (
          <Text style={styles.completedTxt}>Sufficient documents have already been submitted</Text>
        )}
        <React.Fragment>
          {docDetails.map((docDetail, _index) => {
            const {docName: uploadDocumentType} = docDetail;
            const {travellerDocumentsData, traveller} = props;
            let {fileName, uploadStatus} = docDetail;
            let errorCode = null;
            try {
              const documentData =
                travellerDocumentsData[traveller][state.activeState].docs[uploadDocumentType];
              if (Object.keys(documentData.attachments || {}).length) {
                // eslint-disable-next-line prefer-destructuring
                fileName = Object.keys(documentData.attachments)[0];
              }
              if (documentData.uploadStatus !== undefined) {
                // eslint-disable-next-line prefer-destructuring
                uploadStatus = documentData.uploadStatus;
              }
              if (documentData.errorCode !== null) {
                // eslint-disable-next-line prefer-destructuring
                errorCode = documentData.errorCode;
              }
            } catch (e) {
              console.log(e);
            }

            if (fileName === null) {
              fileName = '';
            }

            return (
              <DocumentUploadPreStatusCard
                key={_index + props.travellerId}
                uploadStatus={uploadStatus}
                fileName={fileName}
                docName={docDetail.docName}
                docType={docDetail.docType}
                icon={props.isPending && !fileName ? DocIconPending : DocIcon}
                docFormat={docDetail.docFormat}
                buttons={docDetail.buttons}
                traveller={props.traveller}
                travellerId={props.travellerId}
                documentType={state.activeState}
                uploadDocumentType={docDetail.docName}
                onUploadButtonPress={props.onUploadButtonPress}
                travellerDocumentsData={props.travellerDocumentsData}
                onRetryUpload={props.onRetryUpload}
                error={props.uploadMessage[errorCode] || {}}
                onCancelUpload={props.onCancelUpload}
                onDocumentPreview={props.onDocumentPreview}
                attachmentsMap={props.attachmentsMap}
                setAttachmentsMap={props.setAttachmentsMap}
                onFileSelectedFromWeb={props.onFileSelectedFromWeb}
              />
            );
          })}
        </React.Fragment>
      </React.Fragment>
    );
  };

  const {heading, paxName, text, docsList, noOfTotalDocPax} = props;
  let suffixStatus = '';
  let noOfUploadedDoc = `${state.uploaded}/${noOfTotalDocPax} Uploaded`;
  if (state.uploaded === noOfTotalDocPax) {
    suffixStatus = '2';
    noOfUploadedDoc = 'Documents uploaded';
  } else if (state.uploaded > 0) {
    suffixStatus = '1';
  } else {
    suffixStatus = '0';
  }
  const supSuffix = state.isExpanded ? 'Active' : '';

  return (
    <View style={[styles.whiteCard, styles.innerSection, AtomicCss.marginBottom12]}>
      <TouchableOpacity
        style={[AtomicCss.flexRow, styles.headingWrapper, AtomicCss.alignCenter]}
        activeOpacity={0.6}
        onPress={handleToggleTraveller}
      >
        <View style={AtomicCss.flex1}>
          <Text style={styles.headingTxt}>{heading}</Text>
          <Text
            style={[
              AtomicCss.font20,
              AtomicCss.blackText,
              AtomicCss.blackFont,
              AtomicCss.marginBottom6
            ]}
          >
            {paxName}
          </Text>
          <Text style={[styles.docStatusTxt, styles[`docStatusTxt${suffixStatus}`]]}>
            {state.docStatusHeading}
          </Text>
        </View>
        <Image
          style={[styles.collapseArrow, styles[`collapseArrow${supSuffix}`]]}
          source={CollapseArrow}
        />
      </TouchableOpacity>
      {renderRadioButtonAndTravellerDocuments()}
    </View>
  );
}

DocumentUploadCard.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  docsList: PropTypes.shape([PropTypes.shape({}).isRequired]).isRequired,
  heading: PropTypes.string.isRequired,
  paxName: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  noOfUploadedDocPax: PropTypes.string.isRequired,
  overallUploadStatus: PropTypes.string.isRequired,
  radioButton: PropTypes.bool.isRequired,
  onUploadButtonPress: PropTypes.func.isRequired,
  onRetryUpload: PropTypes.func.isRequired,
  onCancelUpload: PropTypes.func.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
  traveller: PropTypes.number.isRequired,
  travellerDocumentsData: PropTypes.object.isRequired,
  uploadMessage: PropTypes.object.isRequired,
  isPending: PropTypes.bool.isRequired,
  onDocumentPreview: PropTypes.func.isRequired
};

const styles = StyleSheet.create({
  whiteCard: {
    ...getPlatformElevation(2),
    backgroundColor: '#fff',
    marginBottom: 3
  },
  innerSection: {
    padding: 16,
    paddingBottom: 0
  },
  headingWrapper: {
    paddingBottom: 12,
    marginBottom: 15,
    ...getPlatformElevation(1),
    backgroundColor: '#fff',
    margin: -16,
    paddingHorizontal: 16,
    paddingVertical: 12
  },
  headingTxt: {
    fontFamily: fonts.bold,
    fontSize: 12,
    marginBottom: 8,
    color: '#747474'
  },
  collapseDtls: {
    paddingVertical: 20
  },
  docStatusTxt: {
    fontFamily: fonts.bold,
    fontSize: 14,

    alignSelf: 'flex-start'
  },
  docStatusTxt2: {
    color: '#1a7971'
  },
  docStatusTxt0: {
    color: '#cc7f01'
  },
  RadioBtnWrapper: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  completedTxt: {
    color: '#1a7971',
    fontSize: 14,
    fontFamily: fonts.bold,
    marginBottom: 16
  },
  pendingTxt: {
    color: '#cc7f01',
    fontSize: 14,
    fontFamily: fonts.regular,
    marginBottom: 16
  },
  collapseArrow: {
    width: 26,
    height: 26
  },
  collapseArrowActive: {
    transform: [{rotate: '180deg'}]
  }
});
