import { StyleSheet } from "react-native";

export default (theme, fonts) => StyleSheet.create({
  dottedList: {
    backgroundColor: theme.color.white,
    paddingTop: 5,
    paddingBottom: 3,
    paddingLeft: 10,
    paddingRight: 3,
  },
  dottedListItem: {
    position: 'relative',
    paddingLeft: 13,
  },
  dottedListDot: {
    height: 4,
    width: 4,
    backgroundColor: theme.color.greyLight,
    borderRadius: 5,
    position: 'absolute',
    top: 6,
    left: 0,
  },
  dottedListText: {
    color: theme.color.gray,
    fontSize: fonts.fontSize.font12,
    fontFamily: fonts.regular,
    marginBottom: 10,
  },
  whiteCard: {
    backgroundColor:theme.color.white,
    padding: 15,
    marginVertical: 8,
    elevation: 2,
    shadowColor: theme.color.black,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 2,
  },
  cardTitle: {
    color: theme.color.gray,
    fontSize: fonts.fontSize.font16,
    ...fonts.regularFontFamily,
    marginBottom: 10,
  },
});
