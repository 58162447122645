// @flow
import React from 'react';
import { getHolidaySummaryData } from './holidayCardAdapter';
import {
  ActionsContent,
  DescriptionSection,
  HolidayDescriptionText,
  MainContent,
  NewStatusText,
  RightArrow,
  StaticIcon,
  SummaryContainer,
  TitleText,
  NeedHelpStrip
} from '../cardElements';
import { isEmpty } from 'lodash';

function HolidaySummaryCard({ response, msrTicket }) {
  const cardData = getHolidaySummaryData(response, msrTicket);
  const {needHelpData} = cardData;
  return (
    <SummaryContainer bookingId={response.bookingID}>
      <MainContent onPress={cardData.openDetails}>
        <StaticIcon icon={cardData.iconUrl} iconStyle={{ height: 25, width: 17 }} />
        <DescriptionSection>
          <TitleText text={cardData.packageName} />
          {!cardData.failedBooking && !isEmpty(cardData.descriptionText) && (
            <HolidayDescriptionText text={cardData.descriptionText} />
          )}
          <NewStatusText status={cardData.status} text={cardData.dateTime} />
        </DescriptionSection>
        <RightArrow />
      </MainContent>
      {!isEmpty(needHelpData) &&  needHelpData.map((needHelp)=><NeedHelpStrip {...needHelp}/>)}
      <ActionsContent actions={cardData.actions} />
    </SummaryContainer>
  );
}

export default HolidaySummaryCard;
