import React from 'react';
import {FlatList, Image, ScrollView, Text, View, Dimensions} from 'react-native';
import Actions from '../../../navigation/postSalesNavigation';
import Carousel from '../../../Common/Carousel/Carousel';
import PropTypes from 'prop-types';
import getStyles from './HotelMamiCss';
import {isIntlHotelBooking, isNotNullAndEmpty} from '../utils/HotelBookingDetailUtil';
import CommonHeader from '../components/CommonHeader';
import HotelBookingTrackingHelper from '../../HotelBookingTrackingHelper';
import { getStaticData } from '../../../staticData/staticData';
import { HOTEL_DEFAULT_ICON, WHITE_CROSS_ICON } from '../../HotelImageConstants';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

export const DEVICE_WINDOW = Dimensions.get('screen');
const windowWidth = DEVICE_WINDOW.width;

class ViewRoomAmenities extends React.Component {
  static navigationOptions = {
    header: null
  };

  constructor(props) {
    super(props);
    this.fonts = getFont(true);
    this.styles = getStyles(this.fonts);
    this.carouselSize = {
      width: windowWidth,
      height: 210
    };
    this.state = {
      currentIndex: 1
    };
  }


  render() {
    const roomDetailObject = this.props.roomDetailObj;
    const staticData = getStaticData();
    return (
      <View style={this.styles.whitebg}>
        <CommonHeader
          headerText={roomDetailObject.roomTypeName}
          imgSrc={WHITE_CROSS_ICON}
          backPressHandler={this.onBackPress}
        />
        <Carousel
          delay={3000}
          style={this.carouselSize}
          autoplay
          currentPage={0}
          onAnimateNextPage={page => this.assignIndex(page)}
        >
          {this.addRoomImages(roomDetailObject.roomImageURL)}
        </Carousel>
        <Text
          style={[isNotNullAndEmpty(roomDetailObject.roomImageURL) ? this.styles.showView : this.styles.hideView, this.styles.carouselCount]}
        >{isNotNullAndEmpty(roomDetailObject.roomImageURL) ? this.state.currentIndex : 0}/{isNotNullAndEmpty(roomDetailObject.roomImageURL) ? roomDetailObject.roomImageURL.length : 0}
        </Text>
        <Text style={[this.styles.blackFont, this.styles.DefaultText, this.styles.ScrolableHead, this.styles.transperantBgrnd,this.styles.AlignLeft]}>{staticData.viewRoomAmenitiesText.additionalText.roomAmenitiesCapsText}
        </Text>
        <ScrollView showsVerticalScrollIndicator={false}>
          <View style={this.styles.listOuter}>
            {this.checkForAmenitiesView(roomDetailObject.roomAmenties)}
          </View>
        </ScrollView>
      </View>

    );
  }
  onBackPress = () => {
    Actions.pop();
  };
  componentDidMount() {
    const trackPageName = isIntlHotelBooking(this.props.response?.lobCode)
      ? 'mob:customer support:Hotel International:RoomAmenities'
      : 'mob:customer support:Hotel Domestic:RoomAmenities';
    HotelBookingTrackingHelper.trackLoadEvent(trackPageName, this.props.response);
  }

  addRoomImages(roomImageList) {
    const views = [];
    if (isNotNullAndEmpty(roomImageList)) {
      roomImageList.forEach((roomImageUrl) => {
        views.push(<View style={this.carouselSize}><Image
          style={this.styles.imgg}
          resizeMethod="resize"
          source={roomImageUrl == null ? HOTEL_DEFAULT_ICON : {uri: roomImageUrl}}
        />
        </View>);
      });
    } else {
      views.push(<View style={this.carouselSize}>
        <Image
          style={this.styles.imgg}
          resizeMethod="resize"
          source={{uri: 'https://promos.makemytrip.com/MIMA/hotelDefault.jpg'}}
        />
      </View>);
    }
    return views;
  }
  renderFlatList(views, roomAmenitiesList) {
    views.push(<FlatList
      data={roomAmenitiesList}
      ItemSeparatorComponent={() => <View style={{ borderBottomColor: '#ddd', borderBottomWidth: 1 }}></View>}
      renderItem={
        ({item: amenityItem}) => <Text style={this.styles.listItems}>{amenityItem}</Text>
      }
    />);
  }
  renderNoAmenitiesView(views) {
    const staticData = getStaticData();
    views.push(<Text style={this.styles.listItems}>{staticData.viewRoomAmenitiesText.additionalText.noAmenitiesAvailableText}</Text>);
  }

  checkForAmenitiesView(roomAmenities) {
    const views = [];
    if (isNotNullAndEmpty(roomAmenities)) {
      this.renderFlatList(views, roomAmenities);
    } else {
      this.renderNoAmenitiesView(views);
    }
    return views;
  }

  assignIndex(page) {
    if (isNotNullAndEmpty(this.props.roomDetailObj.roomImageURL)) {
      this.setState({currentIndex: page + 1});
    } else {
      this.setState({currentIndex: 0});
    }
  }
}

ViewRoomAmenities.propTypes = {
  response: PropTypes.object.isRequired
};

export default ViewRoomAmenities;
