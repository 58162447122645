import React, {useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import Actions from '../../../../../navigation/postSalesNavigation';
import useCancellationDone from "./hooks/useCancellationDone";
import { getStaticData } from '../../../../../staticData/staticData';
import { LOBNAMES } from '../../../../../PostSalesConstant';

import { getRatingData } from '../../../../../Common/commonUtil';
import ProgressView from '../../../../../Common/ProgressView/ProgressView';

import { invalidateTrips } from '@mmt/legacy-commons/Helpers/genericHelper';
import * as NpsModule from '@mmt/legacy-commons/Native/NpsModule';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import logAction from '@mmt/legacy-commons/Helpers/actionsLogger';
import NoInternetView from '@mmt/legacy-commons/Common/Components/Error/NoInternetView';

import { openErrorPage } from '../../../../../utils/PostSaleUtil';
import CancellationTrackingHelper from '../../../../cancellation/Helpers/CancellationTrackingHelper';
import { CANCELLATION_THANKYOU_PAGE_VIEW, THANKYOU_PAGE_ERROR } from '../../constant';
import { goToBookingDetails } from "../../helper";

import CancellationThankYouPage from '../../components/Success';
import isEmpty from "lodash/isEmpty";
import { BackHandler } from 'react-native';
import { resetToFlightBookingDetail } from '../../../../FlightBookingUtil';
import useHardwareBackpress from '../../../../../Common/useHardwareBackpress';


const ThankYouPage = (props) => {

    const { cancellationRequest, trackingBundle, segmentWiseInfo, isCorporateBooking, flightCancellationType } = props;
    const { bookingId, isHoldBooking } = cancellationRequest || {};

    const { initializingCancellationText,
        errorText: { somethingWentWrongTryAgainError, notConnectedToTheInternetError, checkYourSettingsAndTryAgainError },
        tryAgainText, backText } = getStaticData();

    const trackingPageName = 'ThankYou';
    const ratingTrackingPageName = 'FlightCancellationThankYou';
    const showRatingPrompt = useRef(true);


    const [viewState, cancellationDonePageData, errorType, onRetry] = useCancellationDone(cancellationRequest, { bookingId });

    const handleBackPress = () => {
        goToBookingDetails(bookingId);
        logAction('HardwareBackPressed');
        return true;
    }

    useHardwareBackpress().addListener(handleBackPress, true);
    
    const onBackPressFromError = () => {
        setTimeout(() => goToBookingDetails(bookingId));
    };

    const redirectToLastMinuteCancellation = (response) => {
        Actions.lastMinuteCancellation({
            updateStatusInterval: !isEmpty(response) && response.tentativeWindowCancellationTimeout,
            awaitingStatusInterval: !isEmpty(response) && response.tentativeAwaitingWindowTimeout,
            cancellationDoneResponseJson: response,
            bookingId: bookingId,
            segmentWiseInfo: segmentWiseInfo,
            isCorporateBooking: isCorporateBooking,
        });
    };

    useEffect(() => {
        if (viewState === CANCELLATION_THANKYOU_PAGE_VIEW.LOADED) {
            if (cancellationDonePageData && cancellationDonePageData.tentativeWindowCancellationApplicable) {
                redirectToLastMinuteCancellation(cancellationDonePageData);
            }
            CancellationTrackingHelper.trackLoadEvent(
                trackingPageName,
                trackingBundle,
            );
        } else if (viewState === CANCELLATION_THANKYOU_PAGE_VIEW.NO_INTERNET) {
            CancellationTrackingHelper.trackErrorEvent(
                trackingPageName,
                { error: 'no_internet', ...trackingBundle },
                undefined,
            );
        }
    }, [viewState]);

    useEffect(() => {
        if (errorType != null) {
            if (errorType === THANKYOU_PAGE_ERROR.OPEN_ERROR_PAGE) {
                const { errorPageData } = cancellationDonePageData || {};
                const { errorCode = '', meta } = errorPageData || {};

                if (errorPageData) {
                    openErrorPage(
                        errorPageData,
                        onBackPressFromError,
                        onRetry,
                        bookingId,
                        'cancellation',
                        Actions,
                        { psLob: LOBNAMES.FLIGHT },
                    );
                }
                CancellationTrackingHelper.trackErrorEvent(
                    trackingPageName,
                    { error: errorCode, ...trackingBundle },
                    meta ? meta.supplier : undefined,
                );
            } else if (errorType === THANKYOU_PAGE_ERROR.BUSSINESS_ERROR) {
                const { businessErrorDescription } = cancellationDonePageData || {};
                const errorName = businessErrorDescription ? businessErrorDescription : 'cancellation_status_0';
                CancellationTrackingHelper.trackErrorEvent(
                    trackingPageName,
                    { error: errorName, ...trackingBundle },
                    undefined,
                );
            } else if (errorType === THANKYOU_PAGE_ERROR.API_ERROR) {
                CancellationTrackingHelper.trackErrorEvent(
                    trackingPageName,
                    { error: 'response_error', ...trackingBundle },
                    undefined,
                );
            }
        }
    }, [errorType]);


    const renderPage = () => {

        const { ratingData, npsData } = getRatingData(ratingTrackingPageName, cancellationDonePageData);
        if (ratingData && showRatingPrompt.current) {
            NpsModule.showRatingPrompt(ratingData, npsData);
            showRatingPrompt.current = false;
        }

        return (
            <CancellationThankYouPage
                responseJson={cancellationDonePageData}
                bookingId={bookingId}
                flightCancellationType={flightCancellationType}
                isCorporateBooking={isCorporateBooking}
            />
        )
    }


    if (viewState === CANCELLATION_THANKYOU_PAGE_VIEW.LOADING || (cancellationDonePageData && cancellationDonePageData.tentativeWindowCancellationApplicable)) {
        return <ProgressView message={initializingCancellationText} />;
    } else if (viewState === CANCELLATION_THANKYOU_PAGE_VIEW.ERROR) {
        return <ErrorView
            message={somethingWentWrongTryAgainError}
            buttonLabel={tryAgainText}
            showRetry
            onRetry={() => {
                onRetry();
            }}
        />
    } else if (viewState === CANCELLATION_THANKYOU_PAGE_VIEW.NO_INTERNET) {
        return (<NoInternetView
            title={backText}
            heading={notConnectedToTheInternetError}
            subHeading={checkYourSettingsAndTryAgainError}
            buttonText={tryAgainText}
            onRetry={() => {
                onRetry();
            }}
        />);
    } else {
        invalidateTrips();
        return renderPage();
    }

}

ThankYouPage.propTypes = {
    cancellationRequest: PropTypes.object.isRequired,
    trackingBundle: PropTypes.object.isRequired,
};

export default ThankYouPage;