import { StyleSheet } from "react-native";
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';

const getStyles = (psTheme, fonts) => {
  const {color} = psTheme;
  return StyleSheet.create({
    header: {
      fontSize: fonts.fontSize.font16,
      color: color.black,
      ...fonts.blackFontFamily
    },
    subHeader: {
      fontSize: fonts.fontSize.font14,
      color: color.defaultTextColor,
      ...fonts.regularFontFamily
    },
    updatedMsgStyles: {
      fontSize: fonts.fontSize.font12,
      color: color.grey,
      ...fonts.regularFontFamily
    },
    headingWrapper: {
      flexDirection: 'row',
      ...getPlatformElevation(1),
      paddingHorizontal: 16,
      paddingTop: 16,
      paddingBottom: 12,
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8
    },
    iconWrapper: {
      width: 26,
      height: 26,
      marginRight: 10,
      alignItems: 'center',
      justifyContent: 'flex-start',
    },

    paxIconStyle: {
      width: 24,
      height: 24,
    },
    detailsSection: {
      paddingHorizontal:16,
      paddingVertical:20
    },
    refreshIconStyle: {
      width: 20,
      height: 24,
      marginBottom:5,
      marginRight:5
    },
    bullet: {
      width: 6,
      height: 6,
      backgroundColor: color.grey33,
      borderRadius: 6,
      marginHorizontal:6,
    },
  });
}
export default getStyles;
