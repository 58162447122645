import {fonts} from '@mmt/legacy-commons/Styles/globalStyles';

const styles ={
    trackerContainer:{
        flexDirection:'row',
        alignItems:'center',
        backgroundColor:'#fff',

    },
    trackerDtls:{
        position:'relative',
        marginLeft:10,
    },
    trackerInnerDtls:{
        position:'relative',
        marginBottom:20,
        flexDirection:'row',
    },
    progressLine:{
        width:4,
        backgroundColor:'#e7e7e7',
        position:'absolute',
        height:'80%',
        left:10,
        top:0,
    },
    countWrapper:{
        width:24,
        height:24,
        backgroundColor:'#e7e7e7',
        alignItems:'center',
        justifyContent:'center',
        borderRadius:50,
        left:-10,
    },
    countTxt:{
        fontFamily:fonts.bold,
        color:'#747474',
    },
    trackerStatus:{
        fontFamily:fonts.bold,
        color:'#4a4a4a',
        fontSize:13,
        marginBottom:5,
    },
    trackerStatusTime:{
        fontFamily:fonts.regular,
        color:'#747474',
        fontSize:12,
    },
    trackerPriceTxt:{
        color:'#cf8100',
        fontSize:13,
        fontFamily:fonts.regular,
    },
    tickIcon:{
        width:24,
        height:24,
        position:'absolute',
        left:0,
        top:0,
        zIndex:11,
    },
    defaultActiveStyle:{
        backgroundColor:'#43e1a8',
        width:4,
        position:'absolute',
        top:0,
    },
    stepOneLineActive:{
        height:'35%',
    },
    stepTwoLineActive:{
        height:'78%',
    },
    stepThreeLineActive:{
        height:'100%',
    },
    lastItem:{
        marginBottom:0,
    },
    borderTopActive:{
        borderTopColor:'#e5e5e5',
        paddingTop:15,
        borderTopWidth:1,
    }

}
export default styles;
