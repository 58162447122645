import React, { useState, useContext } from 'react';
import { Text, View, TouchableOpacity } from 'react-native';
import createStyles from '../styles';
import { isFilterDisabled } from '../../../../utils/FlightListingUtil';
import { useTheme } from '../../../../../../../../../theme/theme.context';
import { getFont } from '../../../../../../../../../PostSalesStyles';

const FlightStop = (props) => {
  const { psTheme } = useTheme();
  const fonts = getFont(true);
  const styles = createStyles(psTheme, fonts);
  const [filterActiveItem, setFilterActiveItem] = useState(props.selected);
  const fltrDisabled = isFilterDisabled(props.fltrMtDta);

  const handleFilterActiveItem = () => {
    setFilterActiveItem(!filterActiveItem);
    props.toggleStopFilter(!filterActiveItem, props.index);
  };

  const { data, fltrMtDta, currency } = props;
  return (
    <View>
      <TouchableOpacity
        style={
          !filterActiveItem
            ? [styles.filterStopWrapper]
            : [styles.filterStopWrapper, { backgroundColor: psTheme.color.primary }]
        }
        activeOpacity={1}
        onPress={() => handleFilterActiveItem()}
        disabled={fltrDisabled}
      >
        <Text
          style={
            fltrDisabled
              ? [styles.stopCountTxt, styles.textGray]
              : !filterActiveItem
              ? [styles.stopCountTxt]
              : [styles.stopCountTxt, styles.TextWhite]
          }
        >
          {data.stopCount}
        </Text>
        <Text
          style={
            fltrDisabled
              ? [styles.stopTxt, styles.textGray]
              : !filterActiveItem
              ? [styles.stopTxt]
              : [styles.stopTxt, styles.TextWhite]
          }
        >
          {data.stopTxt}
        </Text>
        <Text
          style={
            !filterActiveItem ? [styles.stopPriceTxt] : [styles.stopPriceTxt, styles.TextWhite]
          }
        >
          {fltrDisabled ? '' : `${currency || '₹'} ${fltrMtDta.chPay}`}
        </Text>
      </TouchableOpacity>
    </View>
  );
};
export default FlightStop;
