import { StyleSheet } from 'react-native';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';

const styles = StyleSheet.create({
  backIconStyle: {
    width: 15,
    height: 15,
    marginBottom: 5,
  },
  closeIconWrapper: {
    alignSelf: 'flex-end',
    borderRadius: 50,
    marginBottom: -50,
  },
  closeIconStyle: {
    width: 21,
    height: 21,
  },
  containerStyle: {
    margin: 10,
    marginTop: -5,
  },
  changeDateBtn: {
    borderRadius: 6,
    paddingVertical: 14,
    paddingHorizontal: 28,
    marginTop: 12,
    borderWidth: 2,
    borderColor: colors.azure,
    alignItems: 'center',
  },
  submitBtn: {
    borderRadius: 6,
    paddingVertical: 14,
    paddingHorizontal: 28,
    marginTop: 12,
    alignItems: 'center',
  },
  OverlayContentWrapper: {
    backgroundColor: colors.white,
    paddingRight: 12,
    paddingLeft: 10,
    position: 'relative',
    zIndex: 1,
    width: '100%',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  submitReqWrapper: {
    minHeight: 490,
  },
  overlayTopline: {
    width: 40,
    height: 4,
    borderRadius: 4,
    backgroundColor: colors.faintgrey,
    alignSelf: 'center',
  },
  voidIconStyle: {
    width: 90,
    height: 90,
    marginTop: -55,
    marginBottom: 24,
  },
  iconStyle: {
    width: 44,
    height: 44,
    marginRight: 12,
  },
  overlayMiddleline: {
    width: 30,
    height: 4,
    borderRadius: 4,
    backgroundColor: colors.primary,
    marginTop: 10,
    marginBottom: 25,
  },
  arrowStyle: {
    width: 8,
    height: 13,
    marginLeft: 10,
    marginTop: 5,
  },
  titleTxt: {
    color: '#4a4a4a',
    marginBottom: 14,
  },
  desc: {
    color: colors.textGrey,
    fontStyle: 'italic',
  },
});
export default styles;
