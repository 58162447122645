import React from 'react';
import {BackHandler} from 'react-native'
import {
  onBackPressed,
  listenPageFocusEvent,
  removePageFocusListener,
} from '@mmt/legacy-commons/Helpers/pageNavHelpers';
import Actions from '../navigation/postSalesNavigation';
import { getCurrentRouterInfo } from '@mmt/legacy-commons/Common/navigation/routerTypeRef';
import { logException } from '@mmt/legacy-commons/Helpers/actionsLogger';
import { backPressHandler } from '../utils/PostSaleUtil';

/** This class provides some extra lifecycle methods like:
 * onPageFocused    : Page got focused (like enter and re-enter), the data can fetched/refreshed in
 *                    this method
 * onHardBackPress  : Android Hardware button is pressed, if true is returned from this method,
 *                    the back event will be overridden
 *
 * Child classes must pass pageName property to the constructor, this name should match the
 * router screen key name
 *
 * Overriding the componentDidMount should call super.componentDidMount() else these events will not
 * be triggered
 * */

const _backHandlers = {};
class PostSalesBasePage extends React.Component {
  constructor(props, pageName) {
    super(props);
    this._pageName = pageName;
  }

  componentDidMount() {
    this._registerToPageEvents();
    if (this.onPageFocused) {
      this.onPageFocused.call(this);
    }
  }


  componentWillUnmount() {
    this._unRegisterToPageEvents();
  }

  _registerToPageEvents() {
    if (this.onPageFocused) {
      listenPageFocusEvent(this._pageName, this);
    }
    if (this.onHardBackPress) {
      _backHandlers[this._pageName] = this;
      BackHandler.addEventListener('hardwareBackPress', () => {
        let currentPageKey;
        let currentRouterInfo;
        try {
          currentRouterInfo = getCurrentRouterInfo();
        } catch (e) {
          logException(e.message);
        }
        if (currentRouterInfo && currentRouterInfo.type === 'REACT_NAV') {
          currentPageKey = currentRouterInfo.ref.getCurrentPageKey()
        } else {
          currentPageKey = Actions.currentScene;
        }
        return this.onBackPressed(currentPageKey);
      });
    }
  }

  onBackPressed = (currPage) => {
    const handler = _backHandlers[currPage];
    if (handler) {
      return handler.onHardBackPress();
    }
    return false;
  };


  removeBackPressListener(pageName) {
    delete _backHandlers[pageName];
  }

  _unRegisterToPageEvents() {
    removePageFocusListener(this._pageName);
    this.removeBackPressListener(this._pageName);
  }

  onBackIconPress() {
    backPressHandler()
  }
}

export default PostSalesBasePage;
