export const ApiRequestTypes = {
    SUMMARY: 'SUMMARY',
    LOSTID_SUMMARY: 'LOSTID_SUMMARY',
    PLANNING_DETAILS: 'PLANNING_DETAILS',
    CANCEL_PLANNING_DETAILS: 'CANCEL_PLANNING_DETAILS',
    STATIC_TEXT_JSON: 'STATIC_TEXT_JSON',
  };
  
  export const ViewState = {
    LOADING: 'loading',
    LOADED: 'loaded',
    EMPTY: 'empty',
    ERROR: 'error',
    SHOW_MORE_LOADING:'show_more_loading',
    SHOW_MORE_LOADED: 'show_more_loaded'
  };

  export const BookingState = {
    UPCOMING: 'upcoming',
    ONTRIP: 'ontrip',
  };
  
  export const PAGE_BOOKINGS_STATUS = {
    ALL: 'ALL',
    ONTRIP:'ONTRIP',
    UPCOMING: 'UPCOMING',
    PENDING: 'PENDING',
    CANCELLED: 'CANCELLED',
    FAILED: 'FAILED',
    COMPLETED: 'COMPLETED',
    AWAITING: 'AWAITING',
    UNSUCCESSFUL: 'UNSUCCESSFUL',
    LOSTID_SUMMARY: 'LOSTID_SUMMARY',
    PLANNING: 'PLANNING',
  } as const;
  
  export const Lobs = {
    Flight: 'FLIGHT',
    Hotel: 'HOTEL',
    Bus: 'BUS',
    Trains: 'TRAIN',
    Cars: 'CAR',
    Rail: 'RAIL',
    Cabs: 'CAB',
    Metro: 'METRO',
    Acme: 'ACME',
    Activity: 'ACTIVITY',
    SelfDrive: 'SELFDRIVE',
    TGS : 'TGS',
    Visa: 'VISA',
    GiftCard: 'GIFTCARD',
    Holiday:'Holiday',
    HOLIDAYS: 'HOLIDAY',
    Planning: 'PLANNING',
    VaccineCertificateCard: 'VaccineCertificateCard',
    AdTechUpcomingCard: 'AdTechUpcomingCard',
    AdTechPendingCard: 'AdTechPendingCard',
    AdTechCancelledCard: 'AdTechCancelledCard',
    AdTechCompletedCard: 'AdTechCompletedCard',
    AdTechFailedCard: 'AdTechFailedCard',
    Insurance:'INSURANCE'
  };

  export const FlightConstants = {
    RoundTrip: 'R',
    MultiCity: 'M',
  }
  
  export const Constant = {
    POWERED_BY: 'Powered by', //These should move to backend in respected Summary API card data
    EXPERIENCE_IN: 'Experience in', //These should move to backend in respected Summary API card data
    IN: 'in', //These should move to backend in respected Summary API card data
    PURCHASED_ON: 'Purchased on', //These should move to backend in respected Summary API card data
    TRAVEL_DATE: 'Travel Date', //No need to move this to backend as this is not shown on UI
    BOOKING_DATE: 'Booking Date', //No need to move this to backend as this is not shown on UI,
    DUMMY_ADTECH_ID: 'DummyAdTechId',
  };
  
  export const BookingStates = {
    UPCOMING: 'UPCOMING',
    CANCELLED: 'CANCELLED',
    COMPLETED: 'COMPLETED',
    FAILED: 'FAILED',
    LOST: 'LOST',
    UNSUCCESSFUL: 'UNSUCCESSFUL',
    LOSTID_SUMMARY: 'LOSTID_SUMMARY',
    PENDING: 'PENDING',
  };
  
  export const BookingStatusCode = {
    FAILED: '2',
    INSTANT_UNCONFIRMED: '9',
  };
  
  export const MyTripsCards = {
    VACCINATION_CARD : "Covid_Certificate",
    TRIP_IDEAS_CARD: "Trip_ideas",
    AD_TECH_CARD: "Adtech_card",
    ADD_BOOKING:"Add_Booking"
  };
  
  export const MyTripsCardsTitle = {
    VACCINATION_CARD_TITLE: 'VaccinationSection',
    AD_TECH_CARD_TITLE: 'AdTechSection'
  };
  
  export const MyTripsCardsVisibility = {
    ALWAYS: 2,
    WITH_BOOKING: 1,
    WITHOUT_BOOKING: 0
  }
  
  export const MSR_TYPE = {
    TALK_TO_US: 'TALK_TO_US',
    ESCALATE: 'ESCALATE'
  };
  
  export const TalkToUsConstants = {
    INTERNATIONAL_HOTEL: 'International Hotel',
    DOMESTIC_HOTEL: 'Domestic Hotel',
    INTERNATIONAL_FLIGHT: 'International Air',
    DOMESTIC_FLIGHT: 'Domestic Air',
  };
  
  export const CallNowRequestConstants = {
    SECONDARY_TYPE: 'Booking Related Issues',
    TERNARY_TYPE: 'Talk to us',
    SOURCE: 'mytrips',
  };

  export const FILTERS = {
    BOOKING_STATUS: 'BOOKING_STATUS',
    CATEGORY: 'CATEGORY',
    LOCATION:'LOCATION',
    DATE:'DATE',
    ALL_FILTERS:'All Filters'
  };

  export const FILTERS_TYPE = {
    STATIC: 'static',
    DYNAMIC: 'dynamic'
  };

  export const MAX_SWITCHER_BOOKING_COUNT_TO_SHOW = 10;

  export const MY_TRIPS_CORPORATE_DEEPLINK = 'mmyt://mytrips?isCorporate=true';

  export const LOAD_MORE_THRESHOLD = 200;

  export const SHOW_MORE_SHIMMER_COUNT = 2;

  export const PROPERTIES_ACTION = {
    ROUTE: 'ROUTE',
    DEEP_LINK: 'DEEP_LINK',
    OPEN_DIALER: 'OPEN_DIALER',
    CONTACT_PROPERTY: 'CONTACT_PROPERTY',
    VIEW_DOC: 'VIEW_DOC',
    LINK: 'LINK',
    DOWNLOAD: 'DOWNLOAD',
    DERIVE_LINK: 'DERIVE_LINK',
  };

  export const PROPERTIES_ID = {
    NEED_HELP: "NeedHelp"
  }

  export const PROPERTIES_PAGE_TYPE = {
    REVIEWUS: 'REVIEWUS',
    DETAILS: 'DETAILS',
    ITINERARY:'ITINERARY',
    ADD_REQUEST: 'ADD_REQUEST',
  };

  export const INITIAL_PAGE_NUMBER = 1;

  export const FILTERS_MAX_VALUES_SHOWN = 10;

  export const PROMISE_STATUS={
    FULFILLED: 'fulfilled'
  }

  export const PLANNINGS_STATUS={
    IN_PROGRESS: 'In Progress',
    CLOSED_QUERIES: 'Closed Queries'
  }

  export const FiltersOmnitureConstants = {
     FILTERS_TEXT: 'filters', 
     FILTERS_VIEW_BOOKINGS: 'view_bookings_in_filter',
     FILTERS_CLEAR_ALL: 'clear_all_in_filter',
  }

  export const OmnitureConstants = {
     SHOW_MORE_TEXT: 'show more',
     SHOW_MORE_BOOKINGS: 'show_more_bookings',
     NO_BOOKINGS_ERROR_TEXT: 'MY_ININERARY_NO_BOOKINGS_ERROR',
  }

  export default {};
