import React from 'react';
import { View, Text } from 'react-native';
import createStyles from './RequestProcessCardCss';
import { isEmptyArray } from '../../../../../../Common/commonUtil';
import { getFont } from '../../../../../../PostSalesStyles';
import { useTheme } from '../../../../../../theme/theme.context';

const RequestProcessCard = ({ data }) => {
  const { footerTitle, footerDescList } = data;
  const { psTheme: theme } = useTheme();
  const fonts = getFont(true);
  const styles = createStyles(theme, fonts);
  return (
    <View style={[styles.innerWrapper]}>
      <View style={[styles.processCardWrapper]}>
        {!!footerTitle && <Text style={styles.headingTxt}>{footerTitle}</Text>}
        {!isEmptyArray(footerDescList) &&
          footerDescList.map((desc, index) => (
            <View style={styles.processRow}>
              <View style={styles.circle}>
                <View style={styles.circleInner}>
                  <Text style={styles.circleCounter}>{index + 1}</Text>
                </View>
              </View>
              <View
                style={[
                  styles.refundProcessRow,
                  index === footerDescList.length - 1 ? styles.borderLstItem : {},
                ]}
              >
                <Text style={styles.titleStyle}>{desc}</Text>
               </View>
            </View>
          ))}
      </View>
    </View>
  );
};

export default RequestProcessCard;
