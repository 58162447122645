import { StyleSheet } from 'react-native';

const getStyles = (fonts, color) =>
  StyleSheet.create({
    statusHeading: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    hedingStatusTxt: {
      color: color.lightTextColor,
      fontSize: fonts.fontSize.font16,
      ...fonts.boldFontFamily,
      flex: 1,
    },
    textCenter: {
      textAlign: 'right',
    },
    paxName: {
      color: color.black,
      fontSize: fonts.fontSize.font16,
      ...fonts.semiBoldFontFamily,
      flex: 1,
      marginRight: 10,
    },
    paxNameCancelled: {
      color: color.lightTextColor,
      ...fonts.boldFontFamily,
      textDecorationLine: 'line-through'
    },
    status: {
      borderRadius: 4,
      marginTop: 5,
      justifyContent: 'center',
      alignItems: 'center',
      paddingHorizontal: 6,
      paddingVertical: 2,
    },
    statusConfirm: {
      backgroundColor: color.green10,
      borderWidth: 1,
      borderColor: color.green10,
    },
    statusRAC: {
      backgroundColor: color.white,
      borderWidth: 1,
      borderColor: color.green10,
    },
    statusWaiting: {
      backgroundColor: color.white,
      borderWidth: 1,
      borderColor: color.yello,
    },
    statusCANText: {
      color: color.lightTextColor,
      ...fonts.boldFontFamily
    },
    statusConfirmComplete: {
      backgroundColor: color.white,
      borderWidth: 1,
      borderColor: color.green10,
    },
    statusText: {
      ...fonts.boldFontFamily,
      fontSize: fonts.fontSize.font12,
      color: color.black,
      textTransform: 'uppercase',
    },
    statusConfirmText: {
      color: color.white,
    },
    statusConfirmCompleteText: {
      color: color.green10
    },
    statusRACText: {
      color: color.green10,
    },
    statusWaitingText: {
      color: color.yello,
    },
    statusReport: {
      ...fonts.boldFontFamily,
      fontSize: fonts.fontSize.font14,
      color: color.black,
      textTransform: 'uppercase',
    },
    lastEleStyle: {
      marginBottom: 0,
    },
  });

export default getStyles;
