import React, { useEffect, useState, useRef } from 'react';
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  Dimensions,
  DeviceEventEmitter,
  Platform,
} from 'react-native';
import styles from './uploadProffCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {
  SUCCESS_DARK_GREEN_TICK_ICON_SM,
  DELETE_ICON,
  BLUE_LIST_ICON_ERROR,
  BLUE_LIST_2_ICON,
  CROSS_GREY_ICON,
} from '../../../../flights/FlightImageConstants';
import ProgressBar from './ProgressBar';
import SpecialClaimAddAttachmentModule from '@mmt/legacy-commons/Native/SpecialClaimAddAttachmentModule';
import useApi from '../../../useApi';
import { UPLOAD_ATTACHMENT_URL } from '../../../../utils/NetworkUtils';
import { CreateGuid, isInvalidUploadDocSize } from '../../../../utils/PostSaleUtil';
import {
  UPLOADER_STATES,
  UPLOADER_MAX_FILE_SIZE,
  UPLOADER_VALID_EXTENSIONS,
  REFUND_OOT_TRACKING,
} from '../../constants';
import { getStaticData } from '../../../../staticData/staticData';
import PostSalesBookingTrackingHelper from '../../../../PostSalesBookingTrackingHelper';

const windW = Dimensions.get('window').width;
const progressBarWidth = windW - 100;

const UploadProofCard = ({ setAttachmentSectionData, bookingId, pageName = '', formType = '' }) => {
  const [uploadAttachmentInProgress, uploadAttachmentResponse, uploadAttachmentApi] = useApi(
    UPLOAD_ATTACHMENT_URL,
  );
  const [fileData, setFileData] = useState({});
  const fileInput = useRef();
  const [uploaderState, setUploaderState] = useState(UPLOADER_STATES.INITAL);

  const {
    attachSupportingDocText,
    formatsText,
    uploadText,
    retryText,
    uploadFailedText,
    plzUploadDocUnderText,
    plzUploadDocWithMimeText,
    plzTryAgainText,
  } = getStaticData(false);

  useEffect(() => {
    if (uploadAttachmentInProgress) {
      setUploaderState(UPLOADER_STATES.LOADING);
    } else if (
      uploadAttachmentResponse &&
      uploadAttachmentResponse.responseStatus &&
      uploadAttachmentResponse.statusCode == 200
    ) {
      const { documentID: documentId, url, fileName } = uploadAttachmentResponse;
      setAttachmentSectionData({
        documentId,
        url,
        fileName,
      });
      setUploaderState(UPLOADER_STATES.SUCCESS);
    } else if (uploadAttachmentResponse) {
      // show error in uploading
      setUploaderState(UPLOADER_STATES.ERROR);
      setFileData((fileData) => ({
        ...fileData,
        errorMsg: plzTryAgainText,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadAttachmentResponse, uploadAttachmentInProgress]);

  const onAddAttachment = () => {
    PostSalesBookingTrackingHelper.trackClickEvent(`${pageName}:${REFUND_OOT_TRACKING.PAGES.UPLOAD}`, REFUND_OOT_TRACKING.CLICK.ADD_ATTACHMENT, bookingId);
    if(Platform.OS === 'web') {
      fileInput && fileInput.current.click();
      return;
    }
    const eventKey = 'special_claim_review_page';
    DeviceEventEmitter.addListener(eventKey, (data) => {
      DeviceEventEmitter.removeAllListeners(eventKey);
      uploadAttachment(data);
    });
    SpecialClaimAddAttachmentModule.onButtonClicked();
  };

  const onDeleteAttachment = () => {
    PostSalesBookingTrackingHelper.trackClickEvent(`${pageName}:${REFUND_OOT_TRACKING.PAGES.UPLOAD}`, REFUND_OOT_TRACKING.CLICK.DEL_ATTACHMENT, bookingId);
    setUploaderState(UPLOADER_STATES.INITAL);
    setAttachmentSectionData(undefined);
  };

  const onUploadAbort = () => {
    PostSalesBookingTrackingHelper.trackClickEvent(`${pageName}:${REFUND_OOT_TRACKING.PAGES.UPLOAD}`, REFUND_OOT_TRACKING.CLICK.ABORT_UPLOAD, bookingId);
    uploadAttachmentApi.abortApiCall();
    setUploaderState(UPLOADER_STATES.INITAL);
    setAttachmentSectionData(undefined);
    setFileData({});
  };

  const getFileContent = async (attachmentData) => {
    const { filePath } = attachmentData;
    const imgInfo = await SpecialClaimAddAttachmentModule.convertToBase64(Platform.OS === 'web' ? attachmentData : filePath);
    if(Platform.OS === 'web') {
      return imgInfo ? imgInfo.split(';')[1].replace('base64,', '') : imgInfo;
    }
    return imgInfo.imageResponse;
  };

  const isValidAttachment = (fileSize, fileExtension) => {
    let errorMsg = '';
    if (isInvalidUploadDocSize(fileSize, UPLOADER_MAX_FILE_SIZE)) {
      errorMsg = plzUploadDocUnderText;
    } else if (!fileExtension || !UPLOADER_VALID_EXTENSIONS.includes(fileExtension.toLowerCase())) {
      errorMsg = plzUploadDocWithMimeText;
    } else {
      return true;
    }
    setUploaderState(UPLOADER_STATES.ERROR);
    setFileData((fileData) => ({
      ...fileData,
      errorMsg,
    }));
    return false;
  };

  const uploadAttachment = async (attachmentData) => {
    const { fileName, filePath, data, name } = attachmentData || {};
    const file_name = fileName || name;
    let fileSize;
    if (Platform.OS === 'web') {
      fileSize = attachmentData.size;
    } else {
      const RNFS = await import ('react-native-fs');
      const size = await RNFS.stat(filePath);
      fileSize = size.size;
    }
    const token = file_name ? file_name.split('.') : [];
    const fileExtension = token[token.length - 1];
    if(Platform.OS === 'web') {
      setFileData(attachmentData);
    } else {
      setFileData((fileData) => ({
        ...fileData,
        fileName: file_name,
        filePath,
        fileSize,
      }));
    }
    if (!isValidAttachment(fileSize, fileExtension)) {
      return;
    }
    const fileContent = Platform.OS === 'ios' ? data : await getFileContent(attachmentData);
    const body = {
      authId: 'AUTOMATION',
      userId: 'AUTOMATION',
      source: 'MIMA',
      bookingID: bookingId,
      documentID: CreateGuid(),
      applicationName: 'mmtmidofficein',
      fileName: file_name,
      fileContent,
      fileExtension,
      createdDate: new Date(),
      formType,
    };
    uploadAttachmentApi.httpPost({
      bookingId,
      uniqueId: 'CUSTOMER_FORM_UPLOAD_ATTACHMENT',
      body,
    });
  };

  const onChangeFile = (e) => {
    uploadAttachment(e.target.files[0]);
  };

  switch (uploaderState) {
    case UPLOADER_STATES.INITAL:
      if(Platform.OS === 'web') {
        return (
          <>
            <TouchableOpacity
              key={'addAttachment'}
              activeOpacity={0.8}
              style={[styles.uploadCard, AtomicCss.flexRow, AtomicCss.spaceBetween]}
              onPress={onAddAttachment}
            >
              <View style={[AtomicCss.flexRow, AtomicCss.flex1, AtomicCss.spaceBetween]}>
                <Image style={styles.uploadIcon} source={BLUE_LIST_2_ICON} />
                <View style={styles.lftDtls}>
                  <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.spaceBetween]}>
                    <View style={[AtomicCss.flex1]}>
                      <Text style={styles.uploadCardHeading}>{attachSupportingDocText}</Text>
                      <Text style={styles.uploadCardInfo}>{formatsText}</Text>
                    </View>
                    <TouchableOpacity onPress={onAddAttachment}>
                      <Text style={styles.linkTxt}>{uploadText}</Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            </TouchableOpacity>
            <input style={{ display: 'none' }} ref={fileInput} type="file" onChange={e => onChangeFile(e)} accept=".jpg,.jpeg,.png" />
          </>
        );
      }
      return (
        <TouchableOpacity
          key={'addAttachment'}
          activeOpacity={0.8}
          style={[styles.uploadCard, AtomicCss.flexRow, AtomicCss.spaceBetween]}
          onPress={onAddAttachment}
        >
          <View style={[AtomicCss.flexRow, AtomicCss.flex1, AtomicCss.spaceBetween]}>
            <Image style={styles.uploadIcon} source={BLUE_LIST_2_ICON} />
            <View style={styles.lftDtls}>
              <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.spaceBetween]}>
                <View style={[AtomicCss.flex1]}>
                  <Text style={styles.uploadCardHeading}>{attachSupportingDocText}</Text>
                  <Text style={styles.uploadCardInfo}>{formatsText}</Text>
                </View>
                <TouchableOpacity onPress={onAddAttachment}>
                  <Text style={styles.linkTxt}>{uploadText}</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </TouchableOpacity>
      );
    case UPLOADER_STATES.LOADING:
      return (
        <View key={'progress'} style={AtomicCss.marginBottom20}>
          <View style={[styles.uploadCard, AtomicCss.makeRelative]}>
            <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween]}>
              <Image style={styles.uploadIcon} source={BLUE_LIST_2_ICON} />
              <View style={styles.lftDtls}>
                <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.spaceBetween]}>
                  <View style={[AtomicCss.flex1]}>
                    <Text numberOfLines={1} style={styles.uploadCardHeading}>
                      {fileData.fileName || fileData.name}
                    </Text>
                  </View>
                  <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
                    <TouchableOpacity
                      activeOpacity={0.8}
                      style={styles.closeWrapper}
                      onPress={onUploadAbort}
                    >
                      <Image style={styles.closeIcon} source={CROSS_GREY_ICON} />
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.progressBarWrppaer}>
            <ProgressBar status="inProgress" toValue={90} containerWidth={progressBarWidth} />
          </View>
        </View>
      );
    case UPLOADER_STATES.ERROR:
      return (
        <>
        <TouchableOpacity
          activeOpacity={0.8}
          style={AtomicCss.marginBottom20}
          onPress={onAddAttachment}
        >
          <View style={[styles.uploadCard]}>
            <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween]}>
              <Image style={styles.uploadIcon} source={BLUE_LIST_ICON_ERROR} />
              <View style={styles.lftDtls}>
                <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.spaceBetween]}>
                  <View style={[AtomicCss.flex1]}>
                    <Text numberOfLines={1} style={styles.uploadCardHeading}>
                      {fileData.fileName || fileData.name}
                    </Text>
                    <Text style={styles.uploadCardInfo}>
                      <Text style={[styles.progressInfoTxt, styles.failedTxt]}>
                        {uploadFailedText}
                      </Text>
                    </Text>
                  </View>
                  <TouchableOpacity onPress={onAddAttachment}>
                    <Text style={styles.linkTxt}>{retryText}</Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </View>
          {fileData.errorMsg && (
            <View
              style={[
                AtomicCss.flexRow,
                AtomicCss.alignCenter,
                AtomicCss.spaceBetween,
                styles.errorSection,
              ]}
            >
              <Text style={styles.failedTxt}>{fileData.errorMsg}</Text>
            </View>
          )}
        </TouchableOpacity>
        {
          Platform.OS === 'web' && <input style={{ display: 'none' }} ref={fileInput} type="file" onChange={e => onChangeFile(e)} accept=".jpg,.jpeg,.png" />
        }
     </>
      );
    case UPLOADER_STATES.SUCCESS:
      return (
        <View style={[styles.attachedProofWrapper, AtomicCss.marginBottom10]}>
          <View>
            <Image style={styles.uploadproffImg} source={{ uri: Platform.OS ==='web' ? URL.createObjectURL(fileData) : `file://${fileData.filePath}` }} />
          </View>

          <View
            style={[
              AtomicCss.flexRow,
              AtomicCss.alignCenter,
              AtomicCss.spaceBetween,
              styles.attachedProof,
            ]}
          >
            <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.flex1]}>
              <Text numberOfLines={1} style={styles.uploadCardHeadingSuccess}>
                {fileData.fileName || fileData.name}
              </Text>
              <Image style={[styles.successIconStyle]} source={SUCCESS_DARK_GREEN_TICK_ICON_SM} />
            </View>
            <TouchableOpacity onPress={onDeleteAttachment}>
              <Image style={styles.deleteIconStyle} source={DELETE_ICON} />
            </TouchableOpacity>
          </View>
        </View>
      );
  }
};

export default UploadProofCard;
