import {Platform} from 'react-native';
import {colors, fonts } from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from '@mmt/legacy-commons/Common/Components/Card/getPlatformElevation';
import {zIndexes, fontSizes, DEVICE_WINDOW} from '../../HolidayBookingConstants';

const styles = {
  cardVisaForm: {
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(151,151,151,.2)',
    marginBottom: 10,
    marginTop: 10,
    marginLeft: -16,
    marginRight: -16
  },
  cardBgForm: {
    borderRadius: 4,
    elevation: 4,
    backgroundColor: 'white',
    shadowOffset: {width: 0, height: 2},
    shadowOpacity: 0.3,
    shadowRadius: 2,
    padding: 16,
    marginBottom: 10
  },
  flex1: {
    flex: 1
  },
  flexRow: {
    flexDirection: 'row'
  },
  spaceBetween: {
    justifyContent: 'space-between'
  },
  regularFont: {
    fontFamily: fonts.regular
  },
  blueColor: {
    color: colors.azure
  },
  font14: {
    fontSize: 14
  },
  mar15Top: {
    marginTop: 15
  },
  BlackText: {
    color: colors.black
  },
  redText: {
    color: colors.red
  },
  boldFont: {
    fontFamily: fonts.bold
  },
  mar12Bt: {
    marginBottom: 12
  },
  checkAvlBtnDisable: {
    paddingVertical: 18,
    width: '92%',
    marginHorizontal: 15,
    borderRadius: 3,
    overflow: 'hidden',
    backgroundColor: colors.lightTextColor
  },
  mar10Bt: {
    marginBottom: 10
  },
  pad15Top: {
    paddingTop: 15
  },
  pad20Bt: {
    paddingBottom: 20
  },
  WhiteTextLight: {
    color: colors.lightGray
  },
  CenterText: {
    alignSelf: 'center'
  },
  font12: {
    fontSize: 12
  },
  bgTransparent: {
    backgroundColor: 'transparent'
  },
  checkAvlBtn: {
    paddingVertical: 18,
    width: '92%',
    marginHorizontal: 15,
    borderRadius: 3,
    overflow: 'hidden'
  },
  WhiteText: {
    color: colors.white
  },
  whitebg: {
    backgroundColor: colors.white
  },
  lightYellow: {
    backgroundColor: colors.creamWhite
  },
  marL15: {
    marginLeft: 15
  },
  mar10Top: {
    marginTop: 10
  },
  mar20Lt: {
    marginLeft: 20
  },
  mar20Rt: {
    marginRight: 20
  },
  darkYellowText: {
    color: colors.lightYello
  },
  progressContainer: {
    width: '100%',
    height: '100%',
    backgroundColor: colors.white,
    alignItems: 'center',
    justifyContent: 'center'
  },
  FormSection: {
    paddingLeft: 20, paddingRight: 20, paddingTop: 0, paddingBottom: 15
  },
  destinationContentContainer: {
    flex: 1,
    flexDirection: 'column'
  },
  destinationText: {
    fontFamily: fonts.regular,
    fontSize: 22,
    color: colors.defaultTextColor
  },
  destinationRowContainer: {
    flex: 1,
    paddingTop: 12,
    paddingBottom: 12,
    marginBottom: 8,
    paddingHorizontal: 14,
    flexDirection: 'row'
  },
  destinationFormContainer: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: colors.white,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  visaFormCloseIcon: {
    alignSelf: 'flex-end',
    width: 24,
    height: 24
  },
  visaFormCloseIconImage: {
    resizeMode: 'contain',
    width: 24,
    height: 24
  },
  stickyHeaderMain: {
    paddingTop: 14,
    paddingBottom: 14,
    width: '100%',
    elevation: 5,
    backgroundColor: colors.white,
    marginBottom: 6
  },
  mar0Bt: {
    marginBottom: 0
  },
  topStatusBar: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: 20,
    paddingRight: 20,
    alignItems: 'center'
  },
  stickyBarWrapperDocument: {
    flexDirection: 'row',
    width: '75%',
    alignItems: 'center'
  },
  padding5: {
    padding: Platform.OS === 'ios' ? 5 : 0
  },
  mar3Bt: {
    marginBottom: 3
  },
  backarrow: {
    width: 16,
    height: 16,
    marginTop: 5,
    marginRight: 10
  },
  stickyTextWrapper: {
    paddingLeft: 15
  },
  font18: {
    fontSize: 18
  },
  lightFont: {
    fontFamily: fonts.light
  },
  defaultGrey: {color: colors.defaultTextColor},
  font11: {
    fontSize: 11
  },
  ValueTab: {
    alignSelf: 'flex-start',
    borderRadius: 4,
    elevation: 1,
    padding: 12,
    marginBottom: 15,
    marginRight: 15,
    ...getPlatformElevation(3),
    ...Platform.select({
      ios: {
        shadowColor: colors.black,
        shadowOpacity: 0.2,
        shadowRadius: 2,
        shadowOffset: {
          height: 2,
          width: 0
        }
      }
    })
  },
  tabActiveTxt: {color: colors.white},
  errorText: {
    fontSize: 14,
    fontFamily: fonts.regular,
    color: colors.errorRed,
    marginHorizontal: 8
  },
  ErrorText: {
    color: colors.red6
  },
  tabActiveDisabled: {backgroundColor: colors.lightTextColor},
  tabActive: {backgroundColor: colors.azure},
  overlayContainer:
  {
    justifyContent: 'flex-end',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    zIndex: 1000,
    elevation: 3
  },
  overlayBg: {
    backgroundColor: 'rgba(0,0,0,.5)',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    zIndex: 1
  },
  overlayContent: {
    backgroundColor: colors.white,
    paddingVertical: 40,
    paddingHorizontal: 20,
    position: 'relative',
    zIndex: 100,
    justifyContent: 'flex-end'
  },
  marginBottom32: {marginBottom: 32},
  overlayHead: {fontSize: 34, color: colors.black, fontFamily: fonts.light},
  marginBottom35: {marginBottom: 35},
  alignCenter: {
    alignItems: 'center'
  },
  rightContent:
    {
      fontSize: 14,
      color: colors.defaultTextColor,
      fontFamily: fonts.regular,
      lineHeight: 22
    },
  JustifySpaceBetween: {justifyContent: 'space-between'},
  actionBlueText: {
    color: colors.azure,
    fontSize: 16,
    fontFamily: fonts.bold
  },
  blueLink: {
    color: colors.azure
  },
  VisaRadioInsideDisabled: {
    width: 11,
    height: 11,
    backgroundColor: colors.lightTextColor,
    borderRadius: 20,
    marginLeft: 4,
    marginTop: 4
  },
  VisaRadioInside: {
    width: 11,
    height: 11,
    backgroundColor: colors.azure,
    borderRadius: 20,
    marginLeft: 4,
    marginTop: 4
  },
  VisaRadio: {
    width: 21,
    height: 21,
    backgroundColor: colors.white,
    borderRadius: 20,
    borderWidth: 1,
    borderColor: colors.azure,
    marginRight: 8
  },
  singleDropDownInputDisabled: {
    flex: 1, width: '100%', borderColor: colors.lightTextColor, borderWidth: 1, padding: 14, borderRadius: 4
  },
  singleDropDownInput: {
    flex: 1, width: '100%', borderColor: colors.azure, borderWidth: 1, padding: 14, borderRadius: 4
  },
  textInputGray: {
    padding: 10,
    borderWidth: 1,
    borderColor: colors.lightTextColor,
    borderRadius: 4,
    color: colors.defaultTextColor,
    fontFamily: fonts.regular
  },
  textInput: {
    padding: 10,
    borderWidth: 1,
    borderColor: colors.azure,
    borderRadius: 4,
    color: colors.defaultTextColor,
    fontFamily: fonts.regular
  },
  borderbtm: {
    borderBottomWidth: 1,
    borderBottomColor: colors.grey8
  },
  PickAppCard: {
    paddingHorizontal: 10,
    paddingVertical: 15,
    backgroundColor: colors.white
  },
  chkboxicon: {
    width: 28,
    height: 28,
    marginRight: 8
  },
  VisaContent: {
    width: '75%',
    justifyContent: 'center'
  },
  font13: {
    fontSize: 13
  },
  lineHeight20: {
    lineHeight: 20
  },
  mar20Bt: {
    marginBottom: 20
  },
  mar5Rt: {
    marginRight: 5
  },
  grey: {color: colors.greyLight},
  mar10Rt: {
    marginRight: 10
  },
  width62Percent: {
    width: '62%'
  },
  lightGreyBg: {backgroundColor: colors.grayBg},
  iewForm: {margin: 12, borderRadius: 4},
  elevation3: {elevation: 3},
  DarkBlue: {
    color: colors.darkBlue2
  },
  Italic: {
    fontStyle: 'italic'
  },
  loaderWrapper: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    position: 'absolute',
    zIndex: zIndexes.z5,
    height: DEVICE_WINDOW.height,
    width: DEVICE_WINDOW.width,
    top: 0,
    left: 0,
    ...getPlatformElevation(Platform.select({ios: 4, android: 5}))
  },
  actionButtonContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 10
  },
  topInfoSection: {
    paddingHorizontal: 14,
    paddingTop: 7,
    paddingBottom: 15,
    backgroundColor: colors.creamWhite2
  },
  captionText: {
    fontFamily: fonts.bold,
    fontSize: fontSizes.lg2,
    color: colors.black,
    marginBottom: 3
  },
  activityName: {
    fontFamily: fonts.bold,
    fontSize: fontSizes.md,
    color: colors.black
  },
  infoBlock: {
    flex: 1
  },
  valueText: {
    fontFamily: fonts.bold,
    fontSize: fontSizes.reg,
    color: colors.black
  },
  totalPriceText: {
    color: colors.lightTextColor,
    fontFamily: fonts.regular,
    fontSize: fontSizes.sm,
    marginBottom: 1
  },
  priceText: {
    color: colors.white,
    fontSize: fontSizes.lg,
    fontFamily: fonts.bold,
    paddingRight: 10
  }
};

export default styles;
