const styles = {
  facingAnyIssueCard: {
    backgroundColor: '#fff',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  rightBlueArrow: {
    width: 16,
    height: 16,
    marginRight: 5,
  },
};

export default styles;
