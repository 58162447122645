import React, { Component } from 'react';
import LinearGradient from 'react-native-linear-gradient';
import { Image, Text, View } from 'react-native';
import styles from './CheckingAirlineOverlayCss';

import { FLIGHT_IMG } from '../../FlightImageConstants';
import { getFont } from '../../../PostSalesStyles';
import { getStaticData } from "../../../staticData/staticData";

export default class CheckingAirlineContent extends Component {
  static navigationOptions = {
    header: null,
  };

  render() {
    const staticData = getStaticData();
    const { fsStyle, ...fonts } = getFont(true);
    return (
      <View style={styles.overlayContent}>
        <View style={styles.overlayContentDtls}>
          <LinearGradient
            start={{
              x: 0.1,
              y: 0.0,
            }}
            end={{
              x: 0.0,
              y: 1.0,
            }}
            colors={['#a8fce7', '#c2e4e8']}
            style={[styles.fltIconWrapper]}
          >
            <Image style={styles.fltIcon} source={FLIGHT_IMG} />
          </LinearGradient>
          <Text style={[styles.fltcheckInTxt, fonts.blackFontFamily, fsStyle.font18]}>
            {staticData.checkingWithAirlineText}
          </Text>
        </View>
      </View>
    );
  }
}
