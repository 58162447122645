/* eslint-disable max-len */
import React from 'react';
import {Linking, NativeModules, Platform} from 'react-native';
import Actions from '../../navigation/postSalesNavigation';
import Fecha from 'fecha';
import _ from 'lodash';
import WhiteFullCapsuleButton from '../../hotel/details/ActionButton/WhiteFullCapsuleButton';
import CabBookingDetailsConstant from './ZoomCarConstants';
import styles from '../styles/SelfDriveMamiCss';
import CapsuleBtn from '../../hotel/details/ActionButton/CapsuleBtn';
import CapsuleBtnFill from '../../hotel/details/ActionButton/CapsuleBtnFill';
import RectFullButton from '../../hotel/details/ActionButton/RectFullButton';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import CabBookingModule from '@mmt/legacy-commons/Native/CabBookingModule';
import RoundButtonWithIcon from '../../hotel/details/ActionButton/RoundButtonWithIcon';
import ZoomCarConstant from "./ZoomCarConstants";
import ZoomCarConstants from "./ZoomCarConstants";
import COMMON_CONSTANT from "../../Common/commonConstant";
import ZcConstants from './ZoomCarConstants';
import isEmpty from 'lodash/isEmpty';
import SelfDriveReactModule from '@mmt/legacy-commons/Native/SelfDriveReactModule';
import {ANDROID_BACK_ARROW} from "../SelfDriveImageConstant"; 
import { openDialer } from '../../Common/commonUtil';

export function getUpperCase(str) {
    return str.toUpperCase();
}

export function fillDepartureArrivalDate(date, outputDateFormat) {
    return Fecha.format(new Date(date), outputDateFormat);
}

export function isNotNullAndEmpty(object) {
    return (object != null && object.length > 0);
}

export function getActionButton(card, response, clickHandler) {
    const actionButtons = [];
    for (const action of card.actionList) {
        addActionButton(card, action, actionButtons, response, clickHandler);
    }
    return actionButtons;
}

export function showPriceBreakupCard(response, card) {
    if (response && (!_.isEmpty(response.clientDetails.priceList))) {
        Actions.commonPaymentPriceBreakUp({bookingDetailsResponse: response});
    } else {
        Actions.cabPaymentBreakup({
            response,
            card
        });
    }
}

export function downloadVoucher(triggerName, response) {
    if (Platform.OS === 'ios') {
        CabBookingModule.downloadTicket(JSON.stringify(response), CabBookingDetailsConstant.ACTION_TRIGGER_VOUCHER);
    } else {
        CabBookingModule.downloadTicket(triggerName, response.bookingId);
    }
}

function addActionButton(card, action, actionButtons, response, clickHandler) {
    switch (action.actionType) {
        case CabBookingDetailsConstant.WHITE_BUTTON_FULL:
            actionButtons.push(<WhiteFullCapsuleButton
                key={`WhiteFullCapsuleButton${action.actionLabeltext}`}
                card={card}
                action={action}
                response={response}
                clickHandler={clickHandler}
            />);
            break;
        case CabBookingDetailsConstant.RECTANGLE_PRIMARY_FULL_WIDTH:
        case CabBookingDetailsConstant.RECTANGLE_PRIMARY_HALF_WIDTH:
            actionButtons.push(<CapsuleBtnFill
                key={`CapsuleBtnFill${action.actionLabeltext}`}
                card={card}
                action={action}
                response={response}
                clickHandler={clickHandler}
            />);
            break;
        case CabBookingDetailsConstant.RECTANGLE_SECONDARY_FULL_WIDTH:
        case CabBookingDetailsConstant.RECTANGLE_SECONDARY_HALF_WIDTH:
            actionButtons.push(<CapsuleBtn
                key={`CapsuleBtn${action.actionLabeltext}`}
                card={card}
                action={action}
                response={response}
                clickHandler={clickHandler}
            />);
            break;
        case CabBookingDetailsConstant.RECTANGLE_FULL_BUTTON:
            actionButtons.push(<RectFullButton
                key={`RectFullButton${action.actionLabeltext}`}
                card={card}
                action={action}
                response={response}
                clickHandler={clickHandler}
            />);
            break;
        case CabBookingDetailsConstant.ROUND_BUTTON:
            actionButtons.push(<RoundButtonWithIcon
                key={`RoundButtonWithIcon${action.actionLabeltext}`}
                card={card}
                action={action}
                response={response}
                clickHandler={clickHandler}
            />);
            break;
        default:
            break;
    }
}

export const getImageIconForZCTopStatusCard = (card) => {
    switch (card.cardName) {
        case ZoomCarConstant.TOP_STATUS_CARD_PROFILE_VERIFICATION_PENDING_NO_DRIVING_LICENSE:
        case ZoomCarConstant.TOP_STATUS_CARD_PROFILE_VERIFICATION_IN_PROGRESS:
        case ZoomCarConstant.TOP_STATUS_CARD_PROFILE_VERIFICATION_PENDING_INCOMPLETE_DOCUMENTS:
            return ZoomCarConstants.ALARM_IMAGE
        case ZoomCarConstant.TOP_STATUS_CARD_BOOKING_CONFIRMED:
        case ZoomCarConstant.TOP_STATUS_CARD_CAR_ASSIGNED:
        case ZoomCarConstant.TOP_STATUS_CARD_CAR_ON_THE_WAY:
        case ZoomCarConstant.TOP_STATUS_CARD_FINDING_A_CAR:
        case ZoomCarConstant.TOP_STATUS_CARD_ENROUTE:
        case ZoomCarConstant.TOP_STATUS_CARD_PROFILE_VERIFIED:
            return ZoomCarConstants.CONFIRMED_IMAGE;
        case ZoomCarConstant.TOP_STATUS_CARD_CAR_NOT_ASSIGNED:
        case ZoomCarConstant.TOP_STATUS_CARD_CUSTOMER_CANCELLED:
        case ZoomCarConstant.TOP_STATUS_CARD_VENDOR_CANCELLED:
        case ZoomCarConstant.TOP_STATUS_CARD_PROFILE_REJECTED:
        case ZoomCarConstant.TOP_STATUS_CARD_PROFILE_NOT_VERIFIED:
            return ZoomCarConstants.CANCELED_IMAGE;
        case ZoomCarConstant.TOP_STATUS_CARD_TRIP_END_DROP_CHECKLIST_DONE:
        case ZoomCarConstant.TOP_STATUS_CARD_TRIP_END:
        case ZoomCarConstant.TOP_STATUS_CARD_TRIP_END_PAYMENT_APPLICABLE:
            return ZoomCarConstants.JOURNEY_OVER_IMAGE;
        case ZoomCarConstant.TOP_STATUS_CARD_BOOKING_FAILED:
            return ZoomCarConstants.FAILED_IMAGE;
        default:
            return ZoomCarConstants.CANCELED_IMAGE;
    }
};
export const fetchBookingStatus = (response) => {
    switch (response.bookingState.state.toLowerCase()) {
        case ZoomCarConstant.UPCOMING:
            return "Confirmed"
        case ZoomCarConstant.CANCELLED:
            return "Cancelled"
        case ZoomCarConstant.COMPLETED:
            return "Completed"
        case ZoomCarConstant.CONFIRMED:
            return "Confirmed"
    }
}

export function getButtonStyle(actionList) {
    const {actionType} = actionList[0];
    switch (actionType) {
        case COMMON_CONSTANT.RECTANGLE_PRIMARY_HALF_WIDTH:
        case COMMON_CONSTANT.RECTANGLE_SECONDARY_HALF_WIDTH:
        case COMMON_CONSTANT.ROUND_BUTTON:
            return [styles.ActionBtnHalf, styles.transperantBgrnd];
        case COMMON_CONSTANT.RECTANGLE_FULL_BUTTON:
            return [styles.ActionBtnRec, styles.transperantBgrnd];
        default:
            return [styles.ActionBtn, styles.transperantBgrnd];
    }
}

export async function handleZcClickEvent(card, action, response, pageName, actionClick) {
    switch (action.actionFamily) {
        case ZoomCarConstants.ACTION_MORE_INFO_POLICIES:
            Actions.openPsWebView({
                headerText: 'Policies',
                url: response.otherInfo.policyUrl,
                headerIcon : ANDROID_BACK_ARROW
            });
            break;
        case ZoomCarConstants.ACTION_MORE_INFO_GET_REFUND:
            Actions.moreInfoWindow({
                bookingDetailsResponse: response,
                title: 'How will I get my refund ?',
                frequentQuestions: false,
                inclusions: false,
                refundDetails: true
            });
            break;
        case ZoomCarConstants.ACTION_MORE_INFO_ASKED_QUESTION:
            Actions.moreInfoWindow({
                title: 'Frequently Asked Questions',
                frequentQuestions: true,
                inclusions: false,
                refundDetails: false,
                bookingDetailsResponse: response
            });
            break;
        case ZoomCarConstants.ACTION_MORE_INFO_INCLUSION_EXCLUSION:
            Actions.moreInfoWindow({
                title: 'Inclusions & Exclusions',
                frequentQuestions: false,
                inclusions: true,
                refundDetails: false,
                bookingDetailsResponse: response
            });
            break;
        case ZoomCarConstants.ACTION_PAYMENT_DETAILS_PAGE:
            Actions.selfDrivePriceBreakUp({
                bookingDetailsResponse: response
            });
            break;
        case ZoomCarConstants.ACTION_FULL_CANCEL:
            Actions.selfDriveCancellationDetails({
                response: response
            });
            break;
        case ZoomCarConstants.ACTION_EXTEND_TRIP_NOW:
            openDialer(response.additionalInfo.vendorContactNo);
            break;
        case ZoomCarConstants.ACTION_END_TRIP_NOW:
            actionClick(ZcConstants.PICKUP_OVERLAY);
            break;
        case ZoomCarConstants.ACTION_SUBMIT_DL:
            break;
        case ZoomCarConstants.ACTION_CONTACT_MMT:
            openDialer(response.otherInfo.mmtHelpLineNumber);
            break;
        case ZoomCarConstants.ACTION_CONTACT_VENDOR:
            openDialer(response.additionalInfo.vendorContactNo);
            break;
        case ZoomCarConstants.ACTION_PROFILE_VERIFICATION:
        case ZoomCarConstants.ACTION_UPLOAD_DOCUMENTS:
            profileVerification();
            break;
        default:
            break;
    }
}

export async function handleActionClicks(card, action, response, pageName) {
    switch (action) {
        case ZoomCarConstants.ACTION_CANCELLATION_POLICY:
            Actions.cancellationPolicy({
                policyResponse: response.otherInfo
            });
            break;
        case ZoomCarConstants.ACTION_CALL_TO_AGENT:
            openDialer(response.driverInfo.contactNo);
            break;
        case ZoomCarConstants.ACTION_FILL_CHECK_LIST:
            break;
        case ZoomCarConstants.ACTION_PAY_NOW_CTA:
            Actions.openPsWebView({
                headerText: 'Easy Pay',
                url: response.otherInfo.easyPayUrl,
                headerIcon : ANDROID_BACK_ARROW
            });
            break;
        case ZoomCarConstants.ACTION_PROCEED_CHECKLIST:
            openChecklist(response);
            break;
        default:
            break;
    }
}

export function openMapsWithImage(lng, lat) {
    const url = Platform.select({
        ios: `maps:${lat},${lng}`,
        android: `geo:${lat},${lng}`,
    });
    Linking.openURL(url);
}

export function openChecklist(response) {
    console.log('inside open checklist');
    const vendorBookingId = response.vendorBookingId;
    const vendorContact = !isEmpty(response.additionalInfo.vendorContactNo)
        ? response.additionalInfo.vendorContactNo : '01246280407';
    if(!isEmpty(vendorBookingId)) {
        console.log(`Start: openChecklist with bookingid : ${vendorBookingId}`);
        SelfDriveReactModule.openChecklist(vendorBookingId, vendorContact);
        console.log('End: openChecklist with bookingid');
    }
}

export function gatherLocationInfo(tripInfo) {
    return {
        latStr: tripInfo.latStr,
        longStr: tripInfo.longStr,
        address1: tripInfo.city,
        address2: tripInfo.address
    }
}

export function getSubTitle(response) {
    return `${response.tripInfo.pickup.displayDate} - ${response.tripInfo.drop.displayDate}`;
}

export function setUser(bookingDetailJson) {
    console.log('setting user now ************>');
    if(bookingDetailJson && bookingDetailJson.additionalInfo && bookingDetailJson.additionalInfo.vendorName && bookingDetailJson.additionalInfo.vendorName.toLowerCase() === 'zoomcar') {
        if(!isEmpty(bookingDetailJson)) {
            console.log('start: bookingDetailJson');
            const { profileStatus } = bookingDetailJson.additionalInfo;
            const isProfileUploadDone = !isEmpty(profileStatus)
                && ((profileStatus.toUpperCase() === 'UPLOADED') || (profileStatus.toUpperCase() === 'APPROVED')
                    || (profileStatus.toUpperCase() === 'DECLINED'));

            const inputJson = {
                'USERNAME': bookingDetailJson.paxInfo.name,
                'PHONE' : bookingDetailJson.paxInfo.contactNo,
                'EMAIL': bookingDetailJson.paxInfo.emailId,
                'AUTH_TOKEN': bookingDetailJson.additionalInfo.vendorUuid,
                'PROFILE_UPLOAD_COMPLETE' : isProfileUploadDone
            };
            console.log(`input string ==>${JSON.stringify(inputJson)}`);
            console.log('Start: setUser');
            SelfDriveReactModule.setUser(JSON.stringify(inputJson));
            console.log('End: setUser');
        }
    }
}

export function profileVerification() {
    console.log('Start: Launching profile verification');
    SelfDriveReactModule.launchProfileVerification();
    console.log('End: Launching profile verification');
}

export const ViewState = {
    LOADING: 'loading',
    LOADING_PAY:'loading_pay',
    SHOW_DETAIL: 'detail',
    REFRESH: 'refresh',
    NO_INTERNET: 'no_internet',
    DEFAULT: 'default',
    ERROR: 'error',
    NO_RESULT: 'no_result',
    HOLD_SUCCESS: 'HOLD_SUCCESS',
    NOT_LOGGED_IN: 'not_logged_in',
    HOLD_LOADING: 'HOLD_LOADING',
    COMMIT :'commit',
    COMMIT_SUCCESS : 'commit_success',
    COMMIT_FAIL : 'commit_fail',
    NO_CABS : 'no_cabs',
    RESEND_VOUCHER: 'resend_voucher'
  };
  