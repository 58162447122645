import React from 'react';
import { View, ScrollView, Text, StyleSheet } from 'react-native';
import { isEmpty } from 'lodash';
import Actions from '../../../navigation/postSalesNavigation';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import Header from '@mmt/legacy-commons/Common/Components/Header/Header';
import CallAirlineCard from './CallAirlineCard';
import SpellingCorrectionSection from '../../modules/details/components/SpellingCorrectionSection';
import BasePage from '../../../Common/PostSalesBasePage';
import FlightBookingTrackingHelper from '../../modules/details/helper/FlightBookingTrackingHelper';
import { openDialer } from '../../../Common/commonUtil';
import { getStaticData } from '../../../staticData/staticData';

class CloseDeparture extends BasePage {
  constructor(props) {
    super(props, 'nameChangeCloseToDeparture');
    FlightBookingTrackingHelper.trackLoadEvent(
      'Special request : Name Change Pop up near to dep',
      this.props.response,
    );
  }

  static navigationOptions = {
    header: null,
  };
  render() {
    const {
      nameChangeCloseToDepartureText: {
        heading,
        subHeading,
        items: { spellingCorrectionSectionText },
      },
    } = getStaticData();
    return (
      <View style={[AtomicCss.flex1, AtomicCss.greyBg]}>
        <Header
          type="Details"
          title={this.props.flightDetailHeader.heading}
          subTitle={this.getSubtitle()}
          onBackPress={this.goBack}
        />
        <ScrollView>
          <View style={styles.infoSection}>
            <Text
              style={[
                AtomicCss.font16,
                AtomicCss.blackText,
                AtomicCss.blackFont,
                AtomicCss.marginBottom4,
              ]}
            >
              {heading}
            </Text>
            <Text
              style={[
                AtomicCss.font14,
                AtomicCss.defaultText,
                AtomicCss.regularFont,
                AtomicCss.marginBottom16,
              ]}
            >
              {subHeading}
            </Text>
            <SpellingCorrectionSection content={spellingCorrectionSectionText} />
          </View>
          <CallAirlineCard
            onContactBtnClick={this.onContactBtnClick}
            {...this.props.closeToDepartureInfo}
          />
        </ScrollView>
      </View>
    );
  }

  goBack = () => {
    Actions.pop();
  };

  getSubtitle() {
    const {
      originCity,
      destinationCity,
      travelStartDate,
      travelEndDate,
      journeyType,
    } = this.props.flightDetailHeader;
    if (journeyType === 3) {
      return `${originCity} - ${destinationCity} | ${travelStartDate}`;
    }
    return `${originCity} - ${destinationCity} | ${travelStartDate}-${travelEndDate}`;
  }

  onContactBtnClick = (actionId, contactNoList) => {
    if (!isEmpty(contactNoList)) {
      switch (actionId) {
        case 'MAKEMYTRIP':
          openDialer(contactNoList[0].contactNo[0]);
          break;
        case 'AIRLINE':
          if (contactNoList.length === 1 && contactNoList[0].contactNo.length === 1) {
            openDialer(contactNoList[0].contactNo[0]);
          } else {
            const contacts = contactNoList.map((contact) => {
              const contactToShow = {};
              contactToShow.airLineName = contact.airlineName;
              contactToShow.airlineLogoUrl = contact.mobileAirlineIconUrl;
              contactToShow.airLineContactNo = contact.contactNo.join(',');
              return contactToShow;
            });
            Actions.airlineContactInfo({ airlineNameContacts: contacts });
          }
          break;
        default:
          break;
      }
    }
  };
}

const styles = StyleSheet.create({
  buttonWrapper: {
    marginHorizontal: 8,
    marginVertical: 8,
  },
  infoSection: {
    marginBottom: 8,
    backgroundColor: '#fff',
    paddingHorizontal: 16,
    paddingVertical: 12,
    shadowColor: '#330000',
    shadowOpacity: 0.3,
    shadowRadius: 2,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    elevation: 3,
    zIndex: 1,
  },
  ORSection: {
    marginHorizontal: 12,
    marginBottom: 24,
    marginTop: 24,
  },
  ORBorder: {
    borderBottomWidth: 1,
    borderColor: '#d9d9d9',
  },
  ORTextWrapper: {
    borderWidth: 1,
    borderColor: '#d9d9d9',
    backgroundColor: '#f2f2f2',
    borderRadius: 25,
    width: 32,
    height: 32,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: -18,
    left: '46%',
    zIndex: 10,
    elevation: 1,
  },
  greyColor: { color: '#959595' },
});
export default CloseDeparture;
