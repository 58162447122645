import React from 'react';
import {View, Text} from 'react-native';
import { getFont } from '../../../../PostSalesStyles';
import { useTheme } from '../../../../theme/theme.context';
import createStyles from "./BreadcrumbCss";

const Breadcrumb = (props) => {
  
  if(!props.data)
    return null; 

    const fonts = getFont()
    const {psTheme : theme} = useTheme();
    const styles = createStyles(theme,fonts);

return (
  <View style={styles.steps}>
    {
              props.data.map((step, index) => {
                  const activeStyle = index <= props.activeStep ? 'Active' : '';
                  return (
                    <View key={index} style={styles.stepItem}>
                      <View style={[styles.stepNumberWrapper, styles[`stepNumberWrapper${activeStyle}`]]}>
                        <Text style={styles.stepNumber}>{index + 1}</Text>
                      </View>
                      <Text style={[styles.stepName, styles[`stepName${activeStyle}`]]}>{step}</Text>
                    </View>
                  );
              })
          }
  </View>
);
        }

export default Breadcrumb;
