import React from 'react';
import {Alert, Platform, ScrollView, Text, TextInput, View} from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getStyles from './HotelMamiCss';
import Actions from '../../../navigation/postSalesNavigation';
import {
  addActionManually, handleClickEvent, getHotelAndDatesSubtitle, isIntlHotelBooking, getModificationRequest, createModificationReviewRequest, validationCheckForNameChange, fillCheckInCheckOutData
} from '../utils/HotelBookingDetailUtil';
import {isNetworkAvailable} from '@mmt/legacy-commons/Common/utils/AppUtils';
import {
  getCommonHeaders, HOTEL_CUSTOMER_NAME_CHANGE_MODIFICATION_URL, HOTEL_CUSTOMER_SUBMIT_NAME_CHANGE_API
} from '../../../utils/NetworkUtils';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import HotelBookingDetailsConstant, { API_REQUEST_TYPE, MODIFICATION_TYPE } from '../HotelBookingDetailsConstant';
import PropTypes from 'prop-types';
import ViewStates from '../utils/ViewStates';
import PostSalesHotelsBaseClass from './PostSalesHotelsBaseClass';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import Header from '../cards/Header';
import Button from '../../../Common/Button';
import { getStaticData } from '../../../staticData/staticData';
import { NEED_HELP_PAGE } from '../../../PostSalesConstant';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import { getErrorPageHeading, showErrorPageCTA } from '../../hotelUtils';
import { ERROR_CTAS_ACTION_ID } from '../../HotelConstants';
import HotelBookingTrackingHelper from '../../HotelBookingTrackingHelper';
import { HOTEL_DETAIL_ACTIONS } from '../../reducer/actions';
import createAction from '../../reducer/actionCreator';
import withRouterWrapper from '../../../Common/Web/withRouterWrapper';
import { connect } from 'react-redux';
import BaseButton, { BUTTON_VARIANT } from '../../../Common/BaseButton';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import ButtonWrapper from 'apps/post-sales/src/Common/ButtonWrapper';
import FloatingInputWpr from 'apps/post-sales/src/Common/FloatingInputWpr';
import DropdownComponent from '../../../Common/Dropdown';
import isEmpty from 'lodash/isEmpty';

class HotelDetailsNameChange extends PostSalesHotelsBaseClass {
  constructor(props) {
    super(props, 'openNameChangePage', 'NameChangeForm');
    this.fonts = getFont(true);
    this.styles = getStyles(this.fonts);
    this.fsStyle = this.fonts.fsStyle;
    const primaryCustomer = this.getPrimaryCustomer(this.props.response);
    this.salutation = primaryCustomer.salutation;
    this.salutationOptions = primaryCustomer.salutationOptions;
    this.staticData = getStaticData();
    const { hotelPaymentCardDetailsText } = this.staticData;
    this.state = {
      firstName: primaryCustomer && primaryCustomer.firstName,
      lastName: primaryCustomer && primaryCustomer.lastName,
      salutation: primaryCustomer && primaryCustomer.salutation,
      viewState: ViewStates.LOADING,
      active: false,
      loaderMessage: hotelPaymentCardDetailsText.additionalText.checkingNameChangeApplicability,
      checkNameChangeApplicableResponse: {},
      hotelReviewResponse: null,
      errorMsgFirstName: '',
      errorMsgLastName: '',
    };
    this.action = {};
  }
  componentWillMount() {
    this.action = addActionManually('NameChangeSubmit').actionList[0];
  }

  componentDidMount() {
    super.componentDidMount();
    this.checkForNameChangeApplicable();
  }

	onFocusSearch = () => {
    this.setState({
      active: true
    });
	}

  onBlurSearchFirstName = () => {
    const { hotelDetailsNameChangeCardText } = this.staticData;
    if (!this.validateInput(this.state.firstName)) {
      this.setState({
        active: false,
        errorMsgFirstName: hotelDetailsNameChangeCardText.additionalText.nameInEnglishOnlyText,
      })
    } else {
      this.setState({
        active: false,
        errorMsgFirstName: '',
      })
    }
  }

  onBlurSearchLastName = () => {
    const { hotelDetailsNameChangeCardText } = this.staticData;
    if (!this.validateInput(this.state.lastName)) {
      this.setState({
        active: false,
        errorMsgLastName: hotelDetailsNameChangeCardText.additionalText.nameInEnglishOnlyText,
      })
    } else {
      this.setState({
        active: false,
        errorMsgLastName: '',
      })
    }
  }

  validateInput = (txt) => {
    const regex = /^[A-Za-z\s]+$/;
    if (txt && !txt.match(regex)) {
        return false;
    }
    return true;
  }

  render() {
    const { response}  = this.props;
    const { hotelDetailsNameChangeCardText } = this.staticData;
    return (
      <View style={AtomicCss.flex1}>
        <View>
          <Header
            title={hotelDetailsNameChangeCardText.additionalText.changePrimaryGuestNameText}
            subTitle={getHotelAndDatesSubtitle(response)}
            headerShadow
            onBackPress={this.onHardBackPress}
          />
        </View>
        {this.state.viewState === ViewStates.DEFAULT && this.mainView()}
        {this.state.viewState === ViewStates.LOADING && this.renderProgressView(this.state.loaderMessage)}
        {this.state.viewState === ViewStates.ERROR && this.renderErrorView()}
        {this.state.viewState === ViewStates.NO_INTERNET && this.renderNoNetworkView()}
      </View>
    );
  }

  renderErrorView = () => {
    const { errorText: { somethingWentWrongTryAgainError } } = this.staticData;
    const { bookingID } = this.props.response || {};
    const { hotelReviewResponse } = this.state;
    const { errorPageData } = hotelReviewResponse || {};
    const { errorCode = '', errorDesc } = errorPageData || {};
    HotelBookingTrackingHelper.trackErrorWithData(this.trackingPageName, bookingID, errorCode, this.props.response)
    return (
      <ErrorView
        showRetry={showErrorPageCTA(ERROR_CTAS_ACTION_ID.RETRY, hotelReviewResponse)}
        onRetry={() => {
          this.setState({viewState: ViewStates.LOADING});
          this.fetchData();
          this.trackError();
        }}
        errorDesc={errorDesc}
        message={getErrorPageHeading(somethingWentWrongTryAgainError, hotelReviewResponse)}
      />
    )
  };

  onHardBackPress = (isHelpingHandEnabled = false) => {
    if (isHelpingHandEnabled && this.props.showHelpingHand) {
      this.props.showHelpingHand(NEED_HELP_PAGE.HOTEL_NAME_CHANGE);
    }
    super.onBackIconPress();
    return true;
  };

  onChangeSalutation = (salutation) => {
    this.setState({salutation});
  }

  mainView() {
    const { response } = this.props;
    const { hotelDetailsNameChangeCardText } = this.staticData;
    const { errorMsgFirstName, errorMsgLastName } = this.state;
    const {isInternational} = response || isIntlHotelBooking(response?.lobCode);
    const isActive = this.state.active != false ? "Active" : "";

    return (
      <View style={AtomicCss.flex1}>
        <ScrollView keyboardShouldPersistTaps="always" keyboardDismissMode="on-drag">
          <View style={this.styles.msgWrapper}>
            <Text style={this.styles.msgText}>{hotelDetailsNameChangeCardText.additionalText.changeNameWarningText}</Text>
          </View>
          <View>
            <View style={this.styles.cardWrapper}>
              <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom16]}>
                <Text style={[this.fonts.blackFontFamily, this.fsStyle.font14, AtomicCss.blackText, AtomicCss.lineHeight20]}>{hotelDetailsNameChangeCardText.additionalText.enterRevisedNamePrimaryGuestText}</Text>
              </View>
              {!!this.salutation && !isEmpty(this.salutationOptions) && <View style={AtomicCss.marginBottom15}>
                <DropdownComponent  selected={this.state.salutation} allOptions={this.salutationOptions} onSelect={this.onChangeSalutation}/>
              </View>}
              <View style={[AtomicCss.marginBottom15]}>
                  <FloatingInputWpr
                    label={isInternational ? hotelDetailsNameChangeCardText.additionalText.givenNameText : hotelDetailsNameChangeCardText.additionalText.firstNameText}
                    value={this.state.firstName}
                    errorMessage={errorMsgFirstName}
                    onChangeText={firstName => this.setState({firstName})}
                    isError={!!errorMsgFirstName}
                    onBlur={this.onBlurSearchFirstName}
                  />
                </View>
                <View>
                <FloatingInputWpr
                    label={isInternational ? hotelDetailsNameChangeCardText.additionalText.surnameText : hotelDetailsNameChangeCardText.additionalText.lastNameText}
                    value={this.state.lastName}
                    errorMessage={errorMsgLastName}
                    onChangeText={lastName => this.setState({lastName})}
                    isError={!!errorMsgLastName}
                    onBlur={this.onBlurSearchLastName}
                  />
                </View>
            </View>
          </View>
        </ScrollView>
        <View style={[this.styles.buttonBottomWrapper]}>
          <ButtonWrapper
            btnText={hotelDetailsNameChangeCardText.additionalText.confirmNameChangeCapsText}
            clickHandler={this.handleClick}
            isThrottleRequired={true}
            throttleInterval={1000}
          />
        </View>
      </View>);
  }

  getPrimaryCustomer(response) {
    const primaryCustomer = response.hotelBookingInfo?.primaryCustomerDetails || {};
    return primaryCustomer;
  }

  onBackPress = () => {
    Actions.pop();
  };
  handleClick = () => {
    const {
      hotelDetailsNameChangeCardText: {
        additionalText: {
          pleaseEnterFirstNameText, pleaseEnterLastNameText, nameInEnglishOnlyText, useDifferentNameText
        }
      }
    } = getStaticData();
    const { firstName, lastName, salutation } = this.state;
    if (firstName === '') {
      alert(pleaseEnterFirstNameText);
      return;
    }
    if (lastName === '') {
      alert(pleaseEnterLastNameText);
      return;
    }
    if (!this.validateInput(firstName) || !this.validateInput(lastName)) {
      alert(nameInEnglishOnlyText);
      return;
    }

    const primaryCustomer = this.getPrimaryCustomer(this.props.response);
    if(primaryCustomer && primaryCustomer?.firstName && primaryCustomer?.lastName && primaryCustomer.firstName === firstName && primaryCustomer.lastName === lastName  && primaryCustomer.salutation === salutation) {
      alert(useDifferentNameText);
      return;
    }

    this.fetchData();
  };

  fetchData() {
    this.setState({
      viewState: ViewStates.LOADING
    });
    const { checkNameChangeApplicableResponse } = this.state;
    const { checkNameChangeUserResponse } = checkNameChangeApplicableResponse || {};
    if (checkNameChangeUserResponse && checkNameChangeUserResponse.success) {
      this.submitNameChange();
    } else {
      this.checkForNameChangeApplicable();
    }
  }

  getAlertCtaList = (isHelpingHandEnabled) => {
    const { goBackCapsText, connectWithUsCapsText } = this.staticData;
    let ctaList = [
      {
        text: goBackCapsText,
        onPress: () => {
          this.onHardBackPress(false);
        }
      }
    ];
    return Platform.OS === "web" ? [] : ctaList;
  }

  async checkForNameChangeApplicable() {
    try {
      const hasNetwork = await isNetworkAvailable();
      if (!hasNetwork) {
        this.setState({viewState: ViewState.NO_INTERNET});
        return;
      }
      const { hotelPaymentCardDetailsText } = this.staticData;
      const { hotelBookingInfo, bookingID, uuid: userUUID, lobCode, isCorporateBooking } = this.props.response;
      const { checkInDate, checkOutDate, primaryCustomerDetails } = hotelBookingInfo || {};
      const formattedCheckInDate = fillCheckInCheckOutData(checkInDate, 'YYYY-MM-DD');
      const formattedCheckOutDate = fillCheckInCheckOutData(checkOutDate, 'YYYY-MM-DD');
      const { eMailID } = primaryCustomerDetails || {};
      const {
        appVersion, exprimentData, deviceId, visitorId
      } = Platform.OS === 'web' ? HotelBookingModule.getHotelDateChangeStaticData(bookingID) : await HotelBookingModule.getHotelDateChangeStaticData();
      const url = HOTEL_CUSTOMER_NAME_CHANGE_MODIFICATION_URL;
      const userInfo = await HotelBookingModule.getRequestHeader(bookingID, API_REQUEST_TYPE.HOTEL_CUSTOMER_NAME_CHANGE_MODIFICATION);
      const headers = {
        ...await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo),
        emailid: userInfo.emailId
      }
      const requestBody = {
        hotelAvailRoomCodeRequest: getModificationRequest(appVersion, exprimentData, deviceId, visitorId,
          formattedCheckInDate, formattedCheckOutDate, bookingID, false, isCorporateBooking),
        checkNameChangeUserRequest: {
            bookingId: bookingID,
            emailID: eMailID
        },
        bookingId: bookingID,
        userUUID,
        lobCode
      }

      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers
      });

      if (response.ok) {
        const checkNameChangeApplicableResponse = await response.json();
        const { checkNameChangeUserResponse, enableHelpingHand = false } = checkNameChangeApplicableResponse || {};
        const { isNameChangeAllowed = false, alertMainText = '' } = validationCheckForNameChange(this.props.response, checkNameChangeUserResponse, this.props.pageName);
        if (isNameChangeAllowed) {
          this.setState({
            viewState: ViewState.DEFAULT,
            checkNameChangeApplicableResponse,
            loaderMessage: hotelPaymentCardDetailsText.additionalText.confirmingNameChangeText,
          });
        } else {
          Alert.alert(
            '',
            alertMainText,
            this.getAlertCtaList(enableHelpingHand)
          );
          if (Platform.OS === "web") {
            this.onHardBackPress(false);
          }
        }
      } else {
        this.setState({
          viewState: ViewStates.ERROR,
          hotelReviewResponse: null,
        });
      }
    } catch (error) {
      this.setState({
        viewState: ViewStates.ERROR,
        hotelReviewResponse: null,
      });
    }
  }

  async submitNameChange() {
    try {
      const action = this.action;
      const hasNetwork = await isNetworkAvailable();
      if (!hasNetwork) {
        this.setState({viewState: ViewState.NO_INTERNET});
        return;
      }
      let requestRaised = '';
      if (Platform.OS === 'android') {
        requestRaised = 'ANDROID';
      } else {
        requestRaised = 'IOS';
      }

      const { hotelBookingInfo, bookingID, uuid: userUUID, lobCode } = this.props.response;
      const { checkNameChangeApplicableResponse } = this.state;
      const { availRespMetadata = {}, chkCanNRebookFlow } = checkNameChangeApplicableResponse || {};
      const { checkInDate, checkOutDate, primaryCustomerDetails } = hotelBookingInfo || {};
      const { eMailID: emailID } = primaryCustomerDetails || {};
      const formattedCheckInDate = fillCheckInCheckOutData(checkInDate, 'YYYY-MM-DD');
      const formattedCheckOutDate = fillCheckInCheckOutData(checkOutDate, 'YYYY-MM-DD');
      const newSalutation = this.state.salutation;
      const newFirstName = this.state.firstName.trim();
      const newLastName = this.state.lastName.trim();

      const submitNameChangeUserRequest = {
        newSalutation,
        newFirstName,
        newLastName,
        bookingId: bookingID,
        requestRaisedBy: requestRaised,
        emailID
      };

      const availResponse = { ...availRespMetadata, bookingID };
      const {
        appVersion, exprimentData, deviceId, visitorId, thankYouURL
      } = Platform.OS === 'web' ? HotelBookingModule.getHotelDateChangeStaticData(availResponse.bookingID) : await HotelBookingModule.getHotelDateChangeStaticData();
      const { hashKey } = availResponse;
      const modificationReviewRequest = chkCanNRebookFlow ? createModificationReviewRequest(appVersion, exprimentData, deviceId, visitorId, formattedCheckInDate, formattedCheckOutDate, availResponse, thankYouURL, hashKey) : null;
      const hotelAvailRoomCodeRequest = {
        ...modificationReviewRequest,
        modificationType: MODIFICATION_TYPE.NAME_CHANGE,
        firstName: newFirstName,
        lastName: newLastName,
      }

      const userInfo = await HotelBookingModule.getRequestHeader(this.props.response.bookingID, API_REQUEST_TYPE.HOTEL_CUSTOMER_SUBMIT_NAME_CHANGE);
      const headers = {
        ...await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo),
        emailid: userInfo.emailId
      }

      const request = {
        submitNameChangeUserRequest,
        hotelAvailRoomCodeRequest,
        bookingId: bookingID,
        userUUID,
        lobCode,
        chkCanNRebookFlow,
      }

      const response = await fetch(HOTEL_CUSTOMER_SUBMIT_NAME_CHANGE_API, {
        method: 'POST',
        body: JSON.stringify(request),
        headers,
      });

      if (response.ok) {
        const body = await response.json();
        const { submitNameChangeUserResponse, hotelReviewResponse } = body || {};
        if (!chkCanNRebookFlow && submitNameChangeUserResponse) {
          if (submitNameChangeUserResponse.status) {
            this.props.response.submitNameChange = submitNameChangeUserResponse;
            handleClickEvent(this.props.card, action, this.props.response, HotelBookingDetailsConstant.REACT_HOTEL_NAME_CHANGE_FORM_PAGE);
          } else {
            alert(submitNameChangeUserResponse.description);
          }
          this.setState({
            viewState: ViewStates.DEFAULT
          });
        } else if (chkCanNRebookFlow && hotelReviewResponse && hotelReviewResponse.success) {
          let newBookingID = hotelReviewResponse.bookingID;
          if (hotelReviewResponse.paymentDetail) {
            newBookingID = hotelReviewResponse.paymentDetail.bookingID;
          }
          Platform.OS !== "web" && await HotelBookingModule.invalidateTripDetails();
          Actions.hotelModificationThankYouPage({
            newBookingID,
            thankYouText: hotelReviewResponse.successText,
            heading: hotelReviewResponse.successHeading,
            newCheckinDate: formattedCheckInDate,
            newCheckoutDate: formattedCheckOutDate,
            emailID,
            action: MODIFICATION_TYPE.NAME_CHANGE,
            hotelDetailResponse: this.props.response,
            modificationResponse: hotelReviewResponse
          })
          this.setState({
            viewState: ViewState.DEFAULT,
          })
        } else {
          this.setState({
            viewState: ViewStates.ERROR,
            hotelReviewResponse,
          });
        }
      } else {
        this.setState({
          viewState: ViewStates.ERROR,
          hotelReviewResponse: null,
        });
      }
    } catch (error) {
      this.setState({
        viewState: ViewStates.ERROR,
        hotelReviewResponse: null,
      });
    }
  }

}

const mapDispatchToProps = dispatch => ({
  showHelpingHand: pageNameForHelpingHand =>
    dispatch(createAction(HOTEL_DETAIL_ACTIONS.SHOW_HELPING_HAND, pageNameForHelpingHand))
});

HotelDetailsNameChange.propTypes = {
  response: PropTypes.object.isRequired,
  pageName: PropTypes.string.isRequired,
  card: PropTypes.object.isRequired
};

export default withRouterWrapper(connect(null, mapDispatchToProps)(HotelDetailsNameChange));
