import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import {Image, Text, TouchableOpacity, View} from 'react-native';
import getStyles from '../ActionBtnCss';
import {getRoundIcon} from '../commonUtil';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';

const RoundButtonWithIcon = ({action, clickHandler, isThrottleRequired= false, throttleInterval = 1000}) => {
  
  const lastClickTimeRef = useRef(0);
  const styles = getStyles();
  const throttledClickHandler = useCallback(() => {
    const currentTime = Date.now();
    if (currentTime - lastClickTimeRef.current >= throttleInterval) {
      lastClickTimeRef.current = currentTime;
      clickHandler(action);
    }
  }, [clickHandler, throttleInterval]);

  const wrapperThrottle = () => {
    if (isThrottleRequired) {
      throttledClickHandler();
    } else {
      clickHandler(action);
    }
  };
  return (<TouchableOpacity onPress={ wrapperThrottle }>
    <View style={styles.RbBtnWithIconWrap}>
      <Image
        style={[styles.RbBtnWithIconImg, AtomicCss.marginBottom3]}
        resizeMethod={'resize'}
        source={getRoundIcon(action.actionFamily)}
      />
      <Text style={styles.RbBtnWithIconText}>{action.actionLabeltext}</Text>
    </View>
  </TouchableOpacity>)
};

RoundButtonWithIcon.propTypes = {
  action: PropTypes.object.isRequired,
  clickHandler: PropTypes.func.isRequired
};

export default RoundButtonWithIcon;
