import { AbConfigKeyMappings } from 'packages/legacy-commons/Native/AbConfig/AbConfigModule';

export const CabBookingDetailsConstant = {
  TRIP_GUARANTEE_CARD: 'TripGuaranteeCard',
  TOLL_REFUND_CARD: 'tollRefundCard',
  CAB_CANCEL_REQUEST: 'CancelRequest_Click',
  CAB_CONFIRM_REQUEST: 'ConfirmRequest_Click',
  LOCATION_DROPDOWN_CLICK: 'DropdownLocation_Click',
  LOCATION_EXPEND_CLICK: 'Mob_Cabs_MIMA_Location_Expand_Click',
  LOCATION_COLLAPS_CLICK: 'Mob_Cabs_MIMA_Location_Collapse_Click',
  SPECIAL_PROGRAM_EXPEND_CLICK: 'Mob_Cabs_MIMA_Special_Program_Expand_Click',
  SPECIAL_PROGRAM_TNC_CLICK: 'Mob_Cabs_MIMA_Special_Program_Read_Terms_Conditions_Click',
  SPECIAL_PROGRAM_COLLAPSE_CLICK: 'Mob_Cabs_MIMA_Special_Program_Collapse_Click',
  SPECIAL_PROGRAM_CARD_SHOWN: 'Mob_Cabs_MIMA_Special_Program_Shown',
  CLICK_PAY_NOW: 'Mob_Cabs_MIMA_Pay_Now_Click',
  BACK_ARROW_CLICK: 'Mob_Cabs_MIMA_Back_Click',
  CLICK_CALL_DRIVE: 'Mob_Cabs_MIMA_Call_Driver_Click',
  CLICK_MODIFY_BOOKING: 'BookAnotherRide_Click',
  CLICK_CALL_HELPLINE: 'Mob_Cabs_MIMA_Call_Helpline_Click',
  TOP_STATUS_CARD_LOST_FAILED_BOOKING_CANCELLED: 'TopStatusCard_LostFailedBookingCancelled',
  TOP_STATUS_CARD_DEFAULT_CONFIRMED: 'TopStatusCard_DEFAULT_CONFIRMED',
  TOP_STATUS_CARD_PREJOURNEY_DETAILS_UNAVAIL: 'TopStatusCard_PreJourneyDetailsUnAvail',
  TOP_STATUS_CARD_POST_PICKUP_DETAILS_UNAVAIL: 'TopStatusCard_PostPickUpDetailsUnAvail',
  TOP_STATUS_CARD_AFTER_REFUND_DONE: 'TopStatusCard_AfterRefundDone',
  TOP_STATUS_CARD_POST_PICKUP_DETAILS_AVAIL: 'TopStatusCard_PostPickUpDetailsAvail',
  TOP_STATUS_CARD_DEFAULT_CANCELLED: 'TopStatusCard_DEFAULT_CANCELLED',
  TOP_STATUS_CARD_COMPLETTION_OF_TRIP_TO_FOREVER: 'TopStatusCard_CompletionOfTripToForever',
  TOP_STATUS_DEFAULT_COMPLETED: 'TopStatusCard_DEFAULT_COMPLETED',
  TOP_STATUS_CARD_PREJOURNEY_DETAILS_AVAIL: 'TopStatusCard_PreJourneyDetailsAvail',
  TOP_STATUS_BETWEEN_CARD_INIT_REFUND_DONE: 'TopStatusCard_BetweenCanInitToRefundDone',
  TOP_STATUS_ADDRESS_PENDING: 'TopStatusCard_DEFAULT_UNCONFIRMED',
  TOP_STATUS_DEFAULT_MODIFIED: 'TopStatusCard_DEFAULT_MODIFIED',
  TOP_STATUS_KIOSK_BOOKING: 'TopStatusCard_CarKioskBooking',
  TOP_STAUTUS_LIVETRACKING_ENABLED_POST_PICKUP: 'TopStatusCard_PostPickUpDetailsAvailTracking',
  TOP_STATUS_LIVE_TRACKING_AFTER_PRE_JOURNEY: 'TopStatusCard_PreJourneyDetailsAvailTracking',
  TOP_STATUS_PRE_ALIGHT_COMPLETED_CARD: 'TopStatusCard_PreAlightToAlight',
  TOP_STATUS_LONG_TAIL_NO_CAB_AVAILABLE: 'TopStatusCard_LongtailTailNoCabsBooking',
  TOP_STATUS_NOT_BOARDED: 'TopStatusCard_Not_Boarded',
  TOP_STATUS_KIOSK_DRIVER_NOT_ASSIGNED: 'TopStatusCard_KioskDriverUnassinged',
  TOP_STATUS_DRIVER_UNASSIGNED: 'TopStatusCard_DriverUnassigned',
  TOP_STATUS_LONG_TAIL_VENDOR_PENDING: 'TopStatusCard_LongtailVendorPending',
  TOP_STATUS_LONG_TAI_VENDOR_ACCEPTED: 'TopStatusCard_LongtailDriverAccepted',
  TOP_STATUS_LONG_TAIL_USER_CONFIRMATION_PENDING: 'TopStatusCard_LongtailUserConfirmationPending',
  SUBCARD_CAB_DETAIL_CARD: 'CabDetailcard',
  SUBACARD_MODIFIED_CAB_DETAIL_CARD: 'Modifiedbookingdetailcard',
  SUBCARD_FETCHING_DRIVER_DETAILS_ANIMATION: 'FetchingDriverDetails',
  SUBCARD_COMPLETED_PAYMENT_FOREVER: 'CompletedPaymentcard',
  SUBCARD_CANCELLED_CAB_DETAIL_CARD: 'Cancelledbookingdetailcard',
  SUBACRD_FAILED_CAB_DETAIL_CARD: 'FailedIdbookingdetailcard',
  CONFIRMED_KIOSK_BOOKING: 'Confirmed_CarKioskcard',
  FLIGHT_DETAILS: 'FlightDetails',
  DRIVER_NOT_ASSIGNED_KIOSK: 'DriverNotAssignedCard_CarKioskBooking',
  TRIP_MODIFICATION_CARD: 'TRIP_MODIFICATION_CARD',
  SUBACRD_CAR_TRACKER_PRE_PICKUP: 'TrackYourCabCardPreJourney',
  SUBACRD_CAR_TRACKER_POST_PICKUP: 'TrackYourCabCardPostPickUp',
  SUBACRD_UNCONFIRMED_ADDRESS: 'unconfirmedCabDetailcard',
  // LONG_TAIL_PENDING: 'Pending_CabDetailcard',
  SHOW_JOUNEY_DETAIL: 'showJourneyDetail',
  SHOW_MC_JOUNEY_DETAIL: 'showMulticityJourneyDetail',
  SHOW_CAR_DETAIL: 'showCarDetail',
  SHOW_DRIVER_ETA: 'showDriverETA',
  SHOW_HR_CAR_DETAIL: 'showHourlyRentalCarDetail',
  SHOW_DRIVER_DETAIL: 'showDriverdetails',
  // SUBCARD_CANCELLED_CAB_DETAIL:'CancelledCabDetailCard',
  // SUBCARD_CONFIRMED_CAB_DETAILED_CARD: 'Confirmed_CabDetailcard',
  DOWNLOAD_INVOICE_POPUP: 'DOWNLOAD_INVOICE_POPUP',
  LONG_TAIL_USER_CONFIRMATION_CARD: 'UserConfirmationCard',
  SUBCARD_CONFIRM_YOUR_LADNING_CARD: 'ConfirmYourLanding',
  CAB_UPSELL_CARD: 'CabUpSell',
  SUBCARD_DRIVER_ASSIGNED_CARD: 'DriverAssignedCard',
  TOP_STATUS_CARD_CAN_INIT_TO_REFUND_DONE: 'TopStatusCard_BetweenCanInitToRefundDone',
  TOP_STATUS_CARD_LOST_FAILED_BOOKING: 'TopStatusCard_LostFailedBooking',
  TOP_STATUS_CARD_DEFAULT_COMPLETED: 'TopStatusCard_DEFAULT_COMPLETED',
  TRIP_MODIFICATION: 'ModificationbookingCard',
  CAB_ITINERARY_CARD: 'CabItineraryCard',
  CAB_TRAVELLER_CARD: 'CabTravellerCard',
  CAB_PRICE_BREAK_UP_CARD: 'CabPriceBreakUpCard',
  CAB_REFUND_PROGRESS_CARD: 'RefundProgressCard',
  CAB_REFUND_BREAKUP_CARD: 'RefundBreakupCard',
  CAB_NEED_HELP_CARD: 'NeedHelpCard',
  CAB_CANCEL_BOOKING_CARD: 'CancelBooking',
  INCLUSION_EXCLUSION_CARD: 'InclusionExculsionCard',
  CAB_MY_SAFETY_CARD: 'MySafetyCard',
  CAB_MAIN_CARD: 'CabMainCard',
  CAB_CHANGEPLANS_CARD: 'CabChangePlansCard',
  CAR_TRACKER_CARD: 'CarTrackerCard',
  CAR_AND_DRIVER_CARD: 'CarAndDriverDetailsCard',
  CAB_VENDOR_INFO_CARD: 'VendorCard',
  CAB_VOUCHER_CARD: 'VoucherCard',
  CAB_REFUND_ISSUED_CARD: 'RefundIssuedCard',
  RESCHEDULE_AWAITING_CARD: 'rescheduleAwaitingCard',
  RESCHEDULE_INFO_CARD: 'rescheduleInfoCard',
  RESCHEDULE_DATE_PICKER_CARD: 'datePickerCard',
  RESCHEDULE_JOURNEY_DETAILS_CARD: 'journeyDetailCard',
  RESCHEDULE_GO_TO_MYTRIPS_CARD: 'goToMyTripsCard',
  RECTANGLE_FULL_BUTTON: 'RecFullBtn',
  ROUND_BUTTON: 'RbBtnWithIcon',
  RECTANGLE_PRIMARY_FULL_WIDTH: 'CapsuleFullBtnFill',
  RECTANGLE_PRIMARY_HALF_WIDTH: 'CapsuleHalfBtnFill',
  RECTANGLE_SECONDARY_FULL_WIDTH: 'CapsuleFullBtn',
  RECTANGLE_SECONDARY_HALF_WIDTH: 'CapsuleHalfBtn',
  WHITE_BUTTON_FULL: 'CapsuleWhiteBtn',
  WHITE_BUTTON_HALF: 'CapsuleWhiteBtnHalf',
  WRITE_TO_US_PAGE: 'mmt.intent.action.WRITE_TO_US',
  TOP_STATUS_CARD_FAILED_SUBTEXT:
    'This was due to a technical glitch at our end. Sorry about that. In case money was deducted, the amount will be refunded in 7 business days.',
  CAN_INIT_TO_REFUND_DONE: 'BetweenCanInitToRefundDone',
  AFTER_REFUND_DONE: 'AfterRefundDone',
  PREJOURNEY_DETAILS_AVAIL: 'PreJourneyDetailsAvail',
  PREJOURNEY_DETAILS_UNAVAIL: 'PreJourneyDetailsUnAvail',
  POST_DETAILS_AVAIL: 'PostPickUpDetailsAvail',
  POST_DETAILS_UNAVAIL: 'PostPickUpDetailsUnAvail',
  COMPLETTION_TO_FOREVER: 'CompletionOfTripToForever',
  LOST_FAILED: 'LostFailedBooking',
  LOST_FAILED_BOOKING_CANCELLED: 'LostFailedBookingCancelled',
  DEFAULT_COMPLETED: 'DEFAULT_COMPLETED',
  DEFAULT_CONFIRMED: 'DEFAULT_CONFIRMED',
  DEFAULT_CANCELLED: 'DEFAULT_CANCELLED',
  ACTION_CALL_DRIVER: 'CallDriver',
  ACTION_CALL_MMT_SUPPORT: 'CallMMTSupport',
  ACTION_CANCEL_FULL_BOOKING: 'CancelFullBooking',
  ACTION_PROCEED_WITH_CANCEL: 'ProceedWithCancellation',
  ACTION_MODIFY_BOOKING: 'ChangeDateLocation',
  ACTION_VIEW_CANCELLATION_POLICY: 'CancellationPolicy',
  ACTION_DOWNLOAD_VOUCHER: 'DownloadVoucher',
  ACTION_DOWNLOAD_INVOICE: 'DownloadInvoice',
  ACTION_TRIGGER_VOUCHER: 'Car_Eticket',
  ACTION_TRIGGER_INVOICE: 'Car_CustomerInvoice',
  ACTION_DRIVER_DETAILS: 'DriverDetails',
  ACTION_TERMS_AND_CONDITIONS: 'TermsAndConditions',
  ACTION_INVOICE_DETAILS: 'CabInvoice',
  ACTION_DATECHANGE_DETAILS: 'CabDateChange',
  ACTION_CANCELBOOKING_DETAILS: 'CancelBooking',
  ACTION_CALL_HELPLINE: 'CallSOSHotline',
  ACTION_TRIP_MODIFICATION: 'tripModification',
  ACTION_DRIVER_CHAT: 'ChatWithDriver',
  ACTION_BOOK_AGAIN: 'BookAgain',
  ACTION_CANCEL_BOOKING: 'Cancel',
  ACTION_CAB_WTU: 'CabWriteToUs',
  ACTION_CAB_GOTO_HELP_SECTION: 'GoToCabHelpSection',
  ACTION_CAB_CALL_US: 'CabCallUs',
  ACTION_CHANGEPICKUP_DETAILS: 'ChangePickup',
  ACTION_CAB_REVIEW: 'CabReview',
  HELPLINE_NUMBER: '0124 4628747',
  ACTION_VIEW_PRICE_BREAKUP: 'SeePriceBreakup',
  CLICK_CALL: 'Mob_Cabs_MIMA_Call_Us_Click',
  CLICK_CALLDRIVER: 'Mob_Cabs_MIMA_Call_Driver_Click',
  CLICK_WTU: 'Mob_Cabs_MIMA_Write_To_Us_Click',
  CLICK_BOOKAGAIN: 'Mob_Cabs_MIMA_Book_Again_Click',
  CLICK_FARE_BREAKUP: 'Mob_Cabs_MIMA_View_Breakup_Click',
  CLICK_CANCEL_BOOKING: 'Mob_Cabs_MIMA_Cancel_Booking_Click',
  MODIFY_BOOKING: 'Mob_Cabs_MIMA_Modify_Booking_Click',
  CLICK_CANCEL_BOOKING_FOR_NOAMENDMENT: 'Mob_Cabs_MIMA_Cancel_Booking_NOAMENDMENT_Click',
  CLICK_CANCELLATION_POLICY: 'Mob_Cabs_MIMA_Cancellation_Policy_Click',
  CLICK_MODIFICATION_POLICY: 'Mob_Cabs_MIMA_View_Modification_Policy',
  CLICK_DOWNLOAD_VOUCHER: 'Mob_Cabs_MIMA_Download_Eticket_Click',
  CLICK_DOWNLOAD_INVOICE: 'Mob_Cabs_MIMA_Download_Invoice_Click',
  CLICK_DRIVER_DETAILS: 'Mob_Cabs_MIMA_Driver_Details_Click',
  CLICK_TERMS_AND_CONDITIONS: 'Mob_Cabs_MIMA_Terms_Conditions_Click',
  CLICK_INVOICE_DETAILS: 'Mob_Cabs_MIMA_Invoice_Details_Click',
  CLICK_CABDATECHANGE_DETAILS: 'Mob_Cabs_MIMA_DateChange_Details_Click',
  CLICK_CANCEL_CONTINUE: 'Mob_Cabs_MIMA_Continue_to_Cancel_Click',
  CLICK_CONFIRM_CANCEL: 'Mob_Cabs_MIMA_Confirm_Cancellation_Click',
  CLICK_CANCELBOOKING_DETAILS: 'Mob_Cabs_MIMA_CancelBookingDetails_Click',
  CLICK_CAB_CROSS_SELL_UPDATE_DEEPLINK: 'MI_Cab_Cross_Sell_Update_Deeplink',
  CLICK_CHANGEPICKUP_DETAILS: 'Mob_Cabs_MIMA_ChangePickupDetails_Click',
  CLICK_CHAT_WITH_DRIVER: 'Mob_Cabs_MIMA_ChatWithDriver_Click',
  CLICK_SELECT_PICKUPDATES: 'Mob_Cabs_MIMA_SELECTDATE_Click',
  CLICK_SELECT_PICKUPDATES_UNAVAILABLE: 'Mob_Cabs_MIMA_SELECTDATE_UNAVAILABLE_Click',
  CLICK_REVIEW_AMENDMENT: 'Mob_Cabs_MIMA_REVIEW_AMENDMENT_CLick',
  CLICK_UPDATED_BOOKING_DETAILS: 'Mob_Cabs_MIMA_UpdatedBooking_Details_Click',
  CLICK_CAB_REVIEW: 'Mob_Cabs_MIMA_CabReview_Click',
  CAB_REVIEW_UNAVAILABLE: 'Mob_Cabs_MIMA_CabReviewUnavailable_Click',
  CHECK_CAB_AMEND_SERVICE: 'CHECK_CAB_AMEND_SERVICE',
  COMPLETE_AMENDMENT_SERVICE: 'COMPLETE_AMENDMENT_SERVICE',
  CAB_DOWNLOAD_VOUCHER: 'CAB_DOWNLOAD_VOUCHER',
  CAB_AMEND_DETAILS_PAGE: 'cabChangePickup',
  CAB_AMEND_PREVIEW_PAGE: 'cabReviewAmendment',
  CAB_AMEND_THANKYOU_PAGE: 'cabCommitAmendment',
  CAB_AMEND_ERROR_SCREENS: 'cabAmendFullScreen',
  CAB_AMEND_HOLD_PAYMENT_PAGE: 'cabAmendmentHoldPayment',
  DEFAULT_ERROR_MESSAGE: 'Something went wrong. Please try Again',
  CHANGE_PICKUP_HEADER: 'Change Pick Up Date/Time',
  TRY_AGAIN: 'Try Again',
  CANCEL_CAB: 'CANCEL CAB',
  BACK_TO_DETAILS: 'Back to Booking Details',
  DATE_CHANGE_NOT_ALLOWED: 'Date change is not available for selected Dates',
  CAB_AMENDMENT_THANKYOU_EVENT: 'cab_booking_details_amendment_thankyou_page',
  CAR_AMENDMENT_DATE_DISPLAY: 'DD-MM-YYYY',
  INCLUSIONEXCLUSIONDELIMITER: '-->',
  ALERT_INFO_CARD: 'alertinfocard',
  CANCELLATION_POLICY_CARD: 'CancellationPolicyCard',
  MODIFICATION_POLICY_CARD: 'ModificationPolicyCard',
  CLICK_REFRESH_MAP: 'Mob_Cabs_MIMA_Refresh_Map_Click',
  CLICK_ZOOM_OUT_MAP: 'Mob_Cabs_MIMA_Zoom_Out_Map_Click',
  CLICK_ZOOM_IN_MAP: 'Mob_Cabs_MIMA_Zoom_In_Map_Click',
  CLICK_RECENTER_MAP: 'Mob_Cabs_MIMA_Recenter_Map_Click',
  TRACK_YOUR_CAR_CARD: 'TrackYourCabCard',
  CAR_TRACKER_COMPLETED_CARD: 'CarTrackerCompletedCard',
  CAR_FIREBASE_API_KEY: 'AIzaSyAn9T2tUBEO6CuX1IpK5meWDxX3AAMIKHs',
  CAR_FIREBASE_PROJECT: 'makemytrip-grounds',
  CAR_FIREBASE_DB_URL: 'https://makemytrip-grounds.firebaseio.com',
  CAR_TRACKING_DATA: 'CAR_TRACKING_DATA',
  LATITUDE_DELTA: 0.1522,
  LONGITUDE_DELTA: 0.0821,
  PICKUP_OR_DROP: {
    PICKUP: 'Pick Up',
    DROP: 'Drop Point',
  },
  DEFAULT_DRIVER_DELAY_MSG:
    'Request you to wait a little for your pickup details. If you’re getting late, you can cancel booking for free.',
  CAB_DRIVER_CARD_HEADING: 'CAR AND DRIVER DETAILS',
  DRIVER_RATING_DELIMITER: ' /',
  DRIVER_DEFAULT_RATING: '4.3',
  DRIVER_MAX_RATING: '5',
  DISTANCE_UNIT: 'kms',
  APPROX_TEXT: '(approx.)',
  DISTANCE_TRAVELLED: 'DISTANCE',
  TRAVEL_DURATION: 'DURATION',
  ODOMETER_READING: 'ODOMETER READING',
  ODOMETER_SUBTEXT: ' (complete trip) ',
  SEATS: 'Seats',
  BAGS: 'Bags',
  AC: 'AC',
  AC_AVAILABLE: 1,
  TRIP_DETAILS_CARD_HEADING: 'TRIP DETAILS',
  DEFAULT_TRIP_DETAILS_PAGE: 'Mob:Customer Support:Cab:TripDetails',
  BOOKING_ID_TEXT: 'BOOKING ID',
  UNCONFIRMED_BOOKINGSTATUS: 'Unconfirmed',
  ONSPOT_BOOKING_TYPE: 'ONSPOT',
  COMPLETED_BOOKING_STATE: 'completed',
  ONTRIP_BOOKING_STATE: 'ontrip',
  REVIEW_LINK_UNAVAILABLE: 'Something went wrong. Please try after some time',
  DEFAULT_DRIVER_PHOTO:
    'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcS7MhL6k4igEUDMq_B771fDx-zph9YbWplocng5N_QxrbuM9Ay2&usqp=CAU',
  ClaimCard: 'ClaimCard',
  ACTION_RAISE_SPECIAL_CLAIM: 'CarClaim',
  ACTION_GO_TO_BOOKING_DETAILS: 'GoToCabBookingDetail',
  MSR_MAIN_CARD: 'MsrMainCard',
  PAY_ONLINE: 'Pay Online',
  ADVISORY_NOTICE: 'AdvisoryNoticeCard',
  OTHER_INFORMATION_CARD: 'CabOtherInfoCard',
  CAB_DRIVER_CHAT_CARD: 'CabChatWithDriverCard',
  CAB_ADD_TECH_CARD: 'CabAddTechCard',
  ADDON_SERVICES_CARD: 'AddonServices',
  NEED_MORE_HELP_MIMA_CARD: 'NeedMoreHelpMIMACard',
  CAB_AMENITIES_CARD: 'PrimeSkuAmenities',
  HOURLY_RENTALS: 'HOURLY_RENTALS',
  FRANCHISE_TYPE: 'Ryde',
  RYDE_BANNER_CARD: 'CarRydeBannerCard',
  LOCATION_TO: 'To',
  LOCATION_FROM: 'From',
  LOCATION_RETURN: 'Return',
  MULTICITY_FARE_INFO: ' included in the fare',
  SPECIAL_CLAIM_CARD: 'SpecialClaimCard',
  ADD_ATTACHMENT: 'ADD ATTACHMENT',
  CancellationPolicy: 'CancelPolicies',
  DownloadInvoice: 'DownloadInvoice',
  CallDriverOverlay: 'CallDriverOverlay',
  RefundIssuedOverlay: 'RefundIssuedOverlay',
  KioskDirectionsOverlay: 'KioskDirectionsOverlay',
  CarPhotosOverlay: 'CarPhotosOverlay',
  DriverProfileOverlay: 'DriverProfileOverlay',
  ConfirmYourLandingOverlay: 'ConfirmYourLandingOverlay',
  UpdateTripDetail: 'UpdateTripDetail',
  kioskPhoto: 'kioskPhoto',
  viewMapText: 'View Map',
  viewPhotoText: 'VIEW PHOTO',
  payTotheDriverText: 'Or, Pay to the driver after the trip',
  amountDueText: 'Amount Due',
  callDriverId: 'CallDriver_VerList',
  callHelpLineId: 'CallHelpline_VerList',
  amountPaidText: 'Amount Paid',
  fareBreakupText: 'Fare Breakup',
  paymentOptionsText: 'Payment Options',
  refundOptionsText: 'Refund Options',
  bookingAmountText: 'Booking Amount',
  currentBookingText: 'Current Booking',
  hideText: 'HIDE',
  loadingText: 'Loading...',
  viewAllText: 'VIEW ALL',
  timeText: 'Time',
  chargesText: 'Charges',
  viewPolicyText: 'View Policy',
  viewDetailText: 'View Details',
  bookingIdText: 'Booking ID',
  cancellationPolicyText: 'Cancellation Policy',
  modificationPolicyText: 'Modification Policy',
  eTicketText: 'E - Ticket',
  invoiceAvailableText: 'Invoice will be available for download after trip completion',
  downloadInvoiceText: 'Download Invoice',
  viewMoreText: 'View More',
  pickUpText: 'Pickup',
  extraChargeText: 'Extra charge after that',
  CopyNumberActionId: 'CopyNumber',
  CAB_DIRECTION_CARD: 'CabDirectionCard',
  CAR_PHOTOS_CARD: 'CarPhotosCard',
};

export const PAGE_NAME = {
  TRIP_DETAILS: 'Mob:Customer Support:Cab:TripDetails',
  MODIFY_BOOKING: 'Mob:Customer Support:Cab:TripDetails:ModifyBooking',
  MODIFY_BOOKING_EDIT: 'Mob:Customer Support:Cab:TripDetails:ModifyBooking:Edit',
  MODIFY_BOOKING_SELECT: 'Mob:Customer Support:Cab:TripDetails:ModifyBooking:Select',
  MODIFY_BOOKING_REVIEW: 'Mob:Customer Support:Cab:TripDetails:ModifyBooking:Review',
  MODIFY_BOOKING_COMMIT: 'Mob:Customer Support:Cab:TripDetails:ModifyBooking:Confirm',
  MAP_DETAIL: 'Mob:Customer Support:Cab:MapDetails',
  CAB_RESCHEDULE_PAGE: 'Mob:Customer Support:Cab:Reschedule',
  CAB_RESCHEDULE_REQUESTED_PAGE: 'Mob:Customer Support:Cab:RescheduleRequested',
  CAB_RESCHEDULE_ERROR_PAGE: 'Mob:Customer Support:Cab:RescheduleRequestedError',
  CHANGE_PICKUP_TIME: 'Mob:Customer Support:Cab:Modify:ChangePickupTime',
};

export const CLICK_EVENT = {
  AT_RETURN_UPSELL_CLICK: 'Mob_Cabs_MIMA_AT_Return_Upsell_Click',
  AT_RETURN_UPSELL_CARD_SHOWN: 'Mob_Cabs_MIMA_AT_Return_Upsell_Shown',
  OS_RETURN_UPSELL_CARD_SHOWN: 'Mob_Cabs_MIMA_OS_Return_Upsell_Shown',
  MODIFY_BOOKING_CLICK: 'Mob_Cabs_MIMA_Modify_Booking_Click',
  CLOSE_CLICK: 'Mob_Cabs_ModifyBooking_Close_Click',
  PICKUP_POINT_CLICK: 'Mob_Cabs_ModifyBooking_Pickup_Click',
  DESTINATION_POINT_CLICK: 'Mob_Cabs_ModifyBooking_Destination_Click',
  SWAP_LOCATION_CLICK: 'Mob_Cabs_ModifyBooking_Swap_Locations_Click',
  PICKUP_DATA_CLICK: 'Mob_Cabs_ModifyBooking_Pickup_DateTime_Click',
  RETURN_DATE_CLICK: 'Mob_Cabs_ModifyBooking_Return_DateTime_Click',
  ROUND_TRIP_CONFIRM_CLICK: 'Mob_Cabs_ModifyBooking_RT_Confirm_Click',
  CLOSE_ROUND_TRIP_CONFIRM_CLICK: 'Mob_Cabs_ModifyBooking_RT_Confirmation_Close_Click',
  DATE_TIME_CHANGE_DONE_CLICK: 'Mob_Cabs_ModifyBooking_DateTime_Change_Confirm_Click',
  CHECK_AVAILABILITY_CLICK: 'Mob_Cabs_ModifyBooking_Check_Availability_Click',
  CAB_SELECTED_CLICK: 'Mob_Cabs_ModifyBooking_Modified_Cab_Selected',
  VIEW_ALL_CABS_CLICK: 'Mob_Cabs_ModifyBooking_ViewAll_CabOptions_Click',
  HIDE_CAB_OPTION_CLICK: 'Mob_Cabs_ModifyBooking_Hide_CabOptions_Click',
  COLLAPSE_CANCELLATION_POLICY_CLICK: 'Mob_Cabs_ModifyBooking_Collapse_CancellationPolicy_Click',
  EXPAND_CANCELLATION_POLICY_CLICK: 'Mob_Cabs_ModifyBooking_Expand_CancellationPolicy_Click',
  COLLAPSE_NEW_INCLUCSION_CLICK: 'Mob_Cabs_ModifyBooking_Collapse_Inclusions_Click',
  EXPAND_NEW_INCLUCSION_CLICK: 'Mob_Cabs_ModifyBooking_Expand_Inclusions_Click',
  COLLAPSE_NEW_EXCLUSION_CLICK: 'Mob_Cabs_ModifyBooking_Collapse_Exclusions_Click',
  EXPAND_NEW_EXCLUSION_CLICK: 'Mob_Cabs_ModifyBooking_Expand_Exclusions_Click',
  PAYMENT_INFO_ICON_CLICK: 'Mob_Cabs_ModifyBooking_Payment_Information_Click',

  PAYMENT_CTA_CLICK: 'Mob_Cabs_ModifyBooking_Payment_Click',
  PARTIAL_PAY_CLICK: 'Mob_Cabs_ModifyBooking_Partial_Payment_Option_Click',
  FULL_PAY_CLICK: 'Mob_Cabs_ModifyBooking_Full_Payment_Option_Click',
  FARE_BREAKUP_CLICK: 'Mob_Cabs_ModifyBooking_Fare_Breakup_Click',
  PAYMENT_OPTION_SELECTED_CLICK: 'Mob_Cabs_ModifyBooking_Payment_Option_Click',
  PARTIAL_PAYMENT_OPTION_SELECTED_CLICK: 'Mob_Cabs_ModifyBooking_Partial_Payment_Option_Click',
  FULL_PAYMENT_OPTION_SELECTED_CLICK: 'Mob_Cabs_ModifyBooking_Full_Payment_Option_Click',
  FARE_BREAKUP_CLICK: 'Mob_Cabs_ModifyBooking_Fare_Breakup_Click',
  PAYMENT_OPTION_EXPEND_CLICK: 'Mob_Cabs_ModifyBooking_Payment_Option_Expand_Click',
  REFUND_TO_WALLET_CLICK: 'Mob_Cabs_ModifyBooking_Refund_Wallet_Click',
  REFUND_TO_ORIGINAL_MODE_CLICK: 'Mob_Cabs_ModifyBooking_Refund_OriginalSource_Click',
  TRACKER_CARD_SHOWN: 'Mob_Cabs_MIMA_Tracking_Shown',
  TRACKER_ERROR_CARD_SHOW: 'Mob_Cabs_MIMA_Error_Tracking_Shown',
  DOCUMENT_VIEW_MORE_CLICK: 'Mob_Cabs_MIMA_Download_Documents_ViewMore_Click',
  KIOSK_MAP_CLICK: 'Mob_Cabs_MIMA_Kiosk_Map_Click',
  KIOSK_IMAGE_CLICK: 'Mob_Cabs_MIMA_Kiosk_Image_Click',
  VIEW_ON_MAPS_CLICK: 'Mob_Cabs_MIMA_Kiosk_View_On_Maps_Click',
  FLIGHT_DETAIL_CARD_SHOWN: 'Mob_Cabs_MIMA_Flight_Details_Shown',
  FLIGHT_DELAY_TAG_SHOWN: 'Mob_Cabs_MIMA_Flight_Details_Delayed_Shown',
  CAB_REASSIGNED_MSG_SHOWN: 'Mob_Cabs_MIMA_Rescheduled_Shown',
  FLIGHT_DETAIL_CARD_INT_BOOKING_SHOWN: 'Mob_Cabs_MIMA_Flight_Details_IntlBooking_Shown',
  VENDOR_TRANSFER_URL_SHOW: 'Mob_Cabs_MIMA_Vendor_TripDetailsURL_Shown',
  VENDOR_TRANSFER_URL_CLICK: 'Mob_Cabs_MIMA_Vendor_TripDetailsURL_Clicked',
  ADDON_SERVICES_CARD_SHOW: 'Mob_Cabs_MIMA_Addon_Shown',
  VIEW_UPDATED_BOOKING: 'Mob_Cabs_ModifyBooking_View_Updated_Booking_Click',
  SELFHELP_INT_BOOKING_SHOWN: 'Mob_Cabs_MIMA_SelfHelp_IntlBooking_Shown',
  SELFHELP_DOM_BOOKING_SHOWN: 'Mob_Cabs_MIMA_SelfHelp_Shown',
  NEED_HELP_CARD_SHOWN: 'Mob_Cabs_MIMA_NeedHelp_Shown',
  MAP_COLLAPS_CLICK: 'Mob_Cabs_MIMA_MapCollapse_Click',
  MAP_EXPEND_CLICK: 'Mob_Cabs_MIMA_MapExpand_Click',
  MAP_RECENTER_CLICK: 'Mob_Cabs_MIMA_Recenter_Map_Click',
  CONFIRM_RESCHEDULE_CLICK: 'Mob_Cabs_Reschedule_ConfirmReschedule_Click',
  RESCHEDULE_DATE_SELECTED: 'Mob_Cabs_Reschedule_Date_Selected',
  CAB_RESCHEDULE_VIEW_DETAILS_CLICK: 'Mob_Cabs_Reschedule_ViewDetails_Click',
  CAB_RESCHEDULE_GO_TO_MYTRIPS_CLICK: 'Mob_Cabs_Reschedule_MyTripsNavigation_Click',
  CAB_RESCHEDULE_VIEW_BOOKING_CLICK: 'Mob_Cabs_Reschedule_ViewBooking_Click',
  CAB_RESCHEDULE_TRY_AGAIN_CLICK: 'Mob_Cabs_Reschedule_TryAgain_Click',
  DATE_PICKER_OVERLAY_CLOSE_CLICK: 'Mob_Cabs_Modify_Close_Click',
  DATE_SELCTED: 'Mob_Cabs_Modify_Date_Selected',
  DATE_SELECTED_CONTINUE_CLICK: 'Mob_Cabs_Modify_ContinuePickupTimeChange_Click',
  NOT_BOARDED_VOUCHER_SHOWN: 'Mob_Cabs_MIMA_NotBoardedVoucher_Shown',
  NOT_BOARDED_VOUCHER_COPY_CLICK: 'Mob_Cabs_MIMA_NotBoardedVoucher_Copied',
  VIEW_REFUND_BREAKUP_CLICK: 'Mob_Cabs_MIMA_ViewRefundBreakup_Click',
  MODIFY_PROMPT_SHOWN: 'Mob_Cabs_Cancel_ModifyPrompt_Shown',
  MODIFICATION_CARD_SHOW: 'Mob_Cabs_MIMA_ModifyBooking_Shown',
  CHANGE_PICKUP_TIME_CLICK: 'Mob_Cabs_MIMA_ChangePickupTime_Clicked',
  CONFIRM_YOUR_LANDING_OVERLAY_SHOWN: 'Mob_Cabs_MIMA_LandingStatusBottomSheet_Shown',
  CONFIRM_YOUR_LANDING_CARD_SHOWN: 'Mob_Cabs_MIMA_LandingStatusCard_Shown',
  ASSIGNING_DRIVER_CARD_SHOWN: 'Mob_Cabs_MIMA_AssigningDriver_Shown',
  AUDITED_CAR_PHOTOS_CLICKED: 'Mob_Cabs_MIMA_AuditedCarPhotos_Clicked',
};

export const MAX_CITY_LENGTH = 13;

export const SOURCE = {
  MYGATE : "MYGATE"
}

export const OVERLAY_TYPE = {
  PAYMENT_OPTION: 'paymentOption',
  FARE_BREAKUP: 'fare_breakup',
};

export default CabBookingDetailsConstant;

export const CardsToShowDuringTrackingOverlay = [
  'TopStatusCard_PostPickUpDetailsAvailTracking',
  'DriverAssignedCard',
  'TrackYourCabCardPostPickUp',
  'TrackYourCabCardPreJourney',
  'TopStatusCard_PreJourneyDetailsAvailTracking',
];

export const CabTrackerStatuses = {
  BOARDED: 'BOARDED',
  DRIVER_ASSIGNED: 'DRIVER_ASSIGNED',
  ARRIVED: 'ARRIVED',
  STARTED: 'STARTED',
};

export const WayPointStates = {
  NEXT: 'NEXT',
  CROSSED: 'CROSSED',
  UPCOMING: 'UPCOMING',
};

export const PokusesToTrackOnLoad = [
  AbConfigKeyMappings.realTimeCabMyTripsStatus,
  AbConfigKeyMappings.modificationChangePickupTime,
];

// Shortened names for pokus keys
export const CabBookingABKeysShortened = {
  [AbConfigKeyMappings.realTimeCabMyTripsStatus]: 'rlTmCbSt',
  [AbConfigKeyMappings.modificationChangePickupTime]: 'mdChPpTm',
};

export const DeepLinkType = {
  CAB_RESCHEDULE: 'CAB_RESCHEDULE',
};

export const DAY_DATE_TIME_FORMAT = 'ddd, DD MMM, hh:mm A';
export const CabRescheduleErrorData = {
  heading: 'Oops!',
  subHeading: 'An error occured while processing your request!',
  actionList: [
    {
      actionRank: 1,
      actionId: 'TRY_AGAIN',
      actionType: 'fill',
      actionLabelText: 'TRY AGAIN',
      actionFamily: 'OpenModificationBottomSheet',
    },
    {
      actionRank: 2,
      actionId: 'BACK_TO_BOOKING',
      actionType: 'outline',
      actionLabelText: 'Back To Booking',
      actionFamily: 'GoToCabBookingDetail',
    },
  ],
};

export const EVENT_EMITTER_CONTS = {
  SHOW_DATE_TIME_PICKER: 'showDateTimePicker',
  SHOW_MODIFICATION_WRAPPER: 'showModificationWrapper',
  HIDE_DATE_TIME_PICKER: 'hideDateTimePicker',
  FETCH_MODIFICATION_JSON: 'fetchModificationJSON',
};

export const DRIVER_PROFILE_OVERLAY_SECTIONS = {
  PERSONAL_INFO: 'personalInfo',
  PERSONAL_INFO_WITH_LANGUAGES: 'personalInfoWithLanguages',
  LANGUAGE_INFO: 'languageInfo',
  CUSTOMER_REVIEWS: 'customerReviews',
  TRIP_COMPLETED: 'tripsCompleted',
  XP_DATA: 'xpData',
};
