import { Platform } from 'react-native';
import { isNetworkAvailable } from '@mmt/legacy-commons/Common/utils/AppUtils';
import {DcViewState} from '../constants';
import FlightBookingModule from '@mmt/legacy-commons/Native/FlightBookingModule';
import {
  getCommonHeaders,
  FLIGHT_NO_TRUE_DATE_CHANGE_HOLD,
  FLIGHTS_LISTING_DATE_CHANGE_REQUESTV2,
  getUserPlatform
} from '../../../../utils/NetworkUtils';
import { LOBNAMES } from '../../../../PostSalesConstant';
import NetworkModule from '@mmt/legacy-commons/Native/NetworkModule';

const getHeaders = async () => {
  const headers = await NetworkModule.getHeaders();
  return headers;
};

export const dateChangePreviewAPI = async (bookingID, segmentGroup, url, isCfarBooking)=>{

  const userInfo = await FlightBookingModule.getRequestHeader(
    bookingID,
    'FLIGHT_DATE_CHANGE_PREVIEW',
  );
  url += `/${bookingID}?isCfarBooking=${!!isCfarBooking}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo, {
      psLob: LOBNAMES.FLIGHT,
    }),
  });
  return response;
};
export const makeSubmitRequestAPI = async ({ bookingId, commonInfo, recommendation, reqKey }) => {
  try {
    const hasNetwork = await isNetworkAvailable();
    if (!hasNetwork) {
      return { viewState: DcViewState.NO_INTERNET };
    }
    const request = {
      bookingId,
      commonInfo,
      recommendation,
      reqKey,
      platformSource: Platform.OS,
    };
    const userInfo = await FlightBookingModule.getRequestHeader('', 'FLIGHT_ODC_SUBMIT_REQUEST');
    const response = await fetch(FLIGHT_NO_TRUE_DATE_CHANGE_HOLD, {
      method: 'POST',
      headers: await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo, {
        psLob: LOBNAMES.FLIGHT,
      }),
      body: JSON.stringify(request),
    });
    if (response.ok) {
      const body = await response.json();
      if (body.status) {
        return {
          holdResponse: body,
          viewState: DcViewState.HOLD_SUCCESS,
        };
      } else {
        return {
          holdResponse: body,
          errorReason: body.failureData.failureReason,
          viewState: DcViewState.HOLD_API_FAILURE_RESPONSE,
        };
      }
    } else {
      let errorReason;
      if (response.status === 500) {
        const errorBody = await response.json();
        if (errorBody.message) {
          errorReason = errorBody.message;
        } else {
          errorReason = response.status;
        }
      }
      return {
        errorReason,
        viewState: DcViewState.ERROR,
      };
    }
  } catch (error) {
    console.log(error.message);
    return {
      errorReason: error.message,
      viewState: DcViewState.ERROR,
    };
  }
};


export const getListingAPI = async (bookingId, segmentGroup, isCfarBooking)=>{
  const userInfo = await FlightBookingModule.getRequestHeader(
    bookingId,
    'FLIGHT_DATE_CHANGE_LISTING',
  );
  const request = {
    authId: userInfo.mmtAuth,
    userId: getUserPlatform().toUpperCase(),
    bookingId: bookingId,
    segmentGroup,
    isCfarBooking: !!isCfarBooking,
    version: 'v2',
  };
  const headers = {
    ...(await getHeaders()),
    ...(await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo)),
  };
  const url = `${FLIGHTS_LISTING_DATE_CHANGE_REQUESTV2}`;
  const response = await fetch(url, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(request),
  });
  return response;
}