import { colors, fonts } from '@mmt/legacy-commons/Styles/globalStyles';
import { Platform, StyleSheet } from 'react-native';

export default (theme) => {
  const styles= StyleSheet.create({
    overlayContainer: {
      justifyContent: 'flex-end',
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      zIndex: 1000,
      elevation: 3,
    },
    overlayBg: {
      backgroundColor: 'rgba(0,0,0,.5)',
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      zIndex: 1,
    },
    overlayContent: {
      backgroundColor: '#fff',
      paddingVertical: 20,
      paddingHorizontal: 20,
      position: 'relative',
      zIndex: 100,
      justifyContent: 'flex-end',
    },
    flexRow: {
      flexDirection: 'row',
    },
    CancelledIcon: {
      width: 65,
      height: 40,
      marginRight: 15,
    },
    mar15Bt: {
      marginBottom: 15,
    },
    mar25Bt: {
      marginBottom: 25,
    },
    alignCenter: {
      alignItems: 'center',
    },
    WhiteText: {
      color: colors.white,
    },
    regularFont: {
      fontFamily: fonts.regular,
    },
    CenterText: {
      alignSelf: 'center',
    },
    overlayHead: {
      fontSize: 24,
      color: '#000',
    },
    cancelTxt: {
      fontSize: 16,
      color: '#4a4a4a',
      fontFamily: fonts.regular,
      lineHeight: 22,
    },
    spaceBetween: {
      justifyContent: 'space-between',
    },
    actionBlueText: {
      color: theme.color.primary,
      fontSize: 16,
      fontFamily: fonts.bold,
    },
    CapsuleBtnFill: {
      paddingBottom: 12,
      paddingTop: 12,
      paddingLeft: 10,
      paddingRight: 10,
      borderRadius: 50,
      marginRight: 10,
      marginLeft: 10,
    },
    transperantBgrnd: {
      ...Platform.select({
        ios: {
          backgroundColor: 'transparent',
        },
      }),
    },
  });

  return styles;
};
