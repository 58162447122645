import React, { useState } from 'react';
import { View, Image, TouchableOpacity, Text, FlatList } from 'react-native';
import createStyle from './HotelPhotosCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import Header from '../../cards/Header';
import {
  openIndividualPhotograph,
  getHotelImageURLList,
  isNullOrEmpty,
} from '../../utils/HotelBookingDetailUtil';
import Actions from '../../../../navigation/postSalesNavigation';
import { HOTEL_DEFAULT_ICON } from '../../../HotelImageConstants';
import { getStaticData } from '../../../../staticData/staticData';
import { useTheme } from '../../../../theme/theme.context';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const HotelPhotos = ({ response }) => {
  const { ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyle(theme, fonts);
  if (isNullOrEmpty(getHotelImageURLList(response))) {
    return null;
  }

  const staticData = getStaticData();
  const allMedia = staticData.allMediaText;
  const hotelDetailList = response.hotelBookingInfo.hotelDetailsList[0];
  const hotelImgCategoryMap = {
    [allMedia]: hotelDetailList.hotelImageURLList,
    ...hotelDetailList.hotelImgCategoryMap,
  };
  const tabList = Object.keys(hotelImgCategoryMap);

  const [activeTab, setActiveTab] = useState(0);
  const [imageCategory, setImageCategory] = useState(allMedia);
  const [imageList, setImageList] = useState(hotelImgCategoryMap[allMedia]);

  const handleBtn = (item, index) => {
    setActiveTab(index);
    setImageCategory(item);
    setImageList(hotelImgCategoryMap[item]);
  };

  const onBackPress = () => {
    Actions.pop();
  };

  const modifyImageList = (imageList) => {
    let modifiedImageList = [];
    let rowSize = 1;
    for (let i = 0, len = imageList.length; i < len; i += rowSize) {
      if (i % 5 === 0) {
        rowSize = 1;
      } else {
        rowSize = 2;
      }
      modifiedImageList.push([imageList.slice(i, i + rowSize), i]);
    }
    return modifiedImageList;
  };

  const renderImageRow = ({ item }) => {
    const hotelImageUrlList = item[0];
    const index = item[1];
    return (
      <View
        style={[
          AtomicCss.flexRow,
          AtomicCss.flex1,
          AtomicCss.paddingLeft12,
          AtomicCss.paddingRight12,
        ]}
      >
        <TouchableOpacity
          style={[AtomicCss.flex1]}
          onPress={() =>
            openIndividualPhotograph(
              imageList,
              index,
              hotelDetailList.name,
              staticData.hotelPhotosText + ' • ' + imageCategory,
            )
          }
        >
          <Image
            style={[hotelImageUrlList.length > 1 ? styles.halfSize : styles.fullSize]}
            source={
              hotelImageUrlList[0] == null ? HOTEL_DEFAULT_ICON : { uri: hotelImageUrlList[0] }
            }
          />
        </TouchableOpacity>
        {hotelImageUrlList.length > 1 && (
          <TouchableOpacity
            style={[AtomicCss.flex1]}
            onPress={() =>
              openIndividualPhotograph(
                imageList,
                index + 1,
                hotelDetailList.name,
                staticData.hotelPhotosText + ' • ' + imageCategory,
              )
            }
          >
            <Image
              style={[styles.halfSize, AtomicCss.marginLeft8]}
              source={
                hotelImageUrlList[1] == null ? HOTEL_DEFAULT_ICON : { uri: hotelImageUrlList[1] }
              }
            />
          </TouchableOpacity>
        )}
      </View>
    );
  };

  const renderImageCategorySection = () => {
    return (
      <View style={[styles.cardWrapper]}>
        <View style={[AtomicCss.flexRow, AtomicCss.flexWrap, AtomicCss.paddingAll12]}>
          {tabList.map((item, index) => {
            const activeSuffix = activeTab === index ? 'Active' : '';
            return (
              <TouchableOpacity
                style={[styles.btnWrapper, styles[`btnWrapper${activeSuffix}`]]}
                activeOpacity={0.6}
                onPress={() => handleBtn(item, index)}
              >
                <Text style={[styles.btnText, styles[`btnText${activeSuffix}`]]}>{item}</Text>
              </TouchableOpacity>
            );
          })}
        </View>
      </View>
    );
  };

  return (
    <View style={[styles.contentWrapper, AtomicCss.flex1]}>
      <View style={styles.topStickySection}>
        <Header title={staticData.hotelPhotosText} headerShadow onBackPress={onBackPress} />
      </View>
      <FlatList
        data={modifyImageList(imageList)}
        renderItem={renderImageRow}
        ListHeaderComponent={renderImageCategorySection()}
        keyExtractor={(item, index) => index}
        removeClippedSubviews={true}
        initialNumToRender={5}
        updateCellsBatchingPeriod={100}
        windowSize={11}
      />
    </View>
  );
};

export default HotelPhotos;
