import AsyncStorage from "@react-native-async-storage/async-storage";
import { updateFechaWithLang } from "./calendarUtil";
import defaultBusLangJson from "./default-lang/bus.json";

const labels = {
    ...defaultBusLangJson,
};

let Vernacular = {
    lobName: '',
    labels: {},
    lang: null,
    defaultLang: 'eng',
    previouslySelectedLanguage: 'eng',
    init: function () {
        return this;
    },
    label: function (str, options, params) {
        try {
            let label = this.labels[str] || labels[str] || str;
            if (options) {
                const keys = Object.keys(options);
                keys.forEach(k => {
                    label = options[k] ? transformers[k](label, str) : label;
                });
            }
            if (params) {
                label = transformers.parameterize(label, params);
            }
            return typeof label === 'object' ? label : label.trim();
        } catch (e) {
            return str;
        }
    }
};

const transformers = {
    capitalize: (str) => {
        try {
            return str
                .toLowerCase()
                .split(" ")
                .map((s) => (s[0].toUpperCase() + s.slice(1)))
                .join(" ");
        } catch (e) {
            return str;
        }
    },
    sentenceCase: (str) => {
        try {
            return str[0].toUpperCase() + str.slice(1);
        } catch (e) {
            return str;
        }
    },
    lowercase: (str) => {
        try {
            return str.toLowerCase();
        } catch (e) {
            return str;
        }
    },
    uppercase: (str) => {
        try {
            return str.toUpperCase();
        } catch (e) {
            return str;
        }
    },
    defaultLang: (str, key) => {
        return langLabels[key];
    },
    parameterize: (str, params) => {
        return str.replace(/{{\w*}}/ig, (match) => {
            const key = match.replace(/[{}]/ig, '') || "";
            return params[key];
        });
    }
}

const label = (key, options, params) => {
    return Vernacular.label(key,options, params);
};

const getCurrentLangKey = () => {
    return Vernacular.lang || Vernacular.defaultLang;
}

const getVernacCachedLabels = async (lobName, lang) => {
    const vernac_labels = await AsyncStorage.getItem(`${lobName}_vernac_lang_${lang}`);
    if(vernac_labels){
        const parsedCachedLabels = JSON.parse(vernac_labels);
        return parsedCachedLabels;
    }else{
        return null;
    }
}

export default function initializeLanguage(config) {
    if(config.lobName === undefined){
        throw new Error("lobName key is missing");
    }
    if (config.lang === undefined) {
        throw new Error("lang key is missing");
    }
    if (config.labels === undefined) {
        throw new Error("labels key is missing");
    }

    AsyncStorage.setItem(`${config.lobName}_vernac_lang_${config.lang}`, JSON.stringify({
        lastUpdatedOn: new Date().getDate(),
        cachedLabels: config.labels
    }));
    const init = Vernacular.init.bind({ ...Vernacular, ...config });
    Vernacular = init();
    updateFechaWithLang(Vernacular.lang);    

    return Vernacular;
}

export { label, getCurrentLangKey, getVernacCachedLabels };

export const HIN = "hin";
export const EN = "eng";
export const TAM = "tam";
export const VERNAC_DEFAULT_LANG = EN;

export const languageConfig = {
  [HIN]: {
    id: 'hi',
    beCode: "hi",
    value: "Hindi",
    label: "हिंदी",
    altLang: "eng",
    cookieCode: "hin",
    altLabel: "ENG",
  },
  [EN]: {
    id: 'en',
    beCode: "en",
    value: "English",
    altLang: "hin",
    cookieCode: "eng",
    label: "ENG",
    altLabel: "हिंदी",
  },
  [TAM]: {
    id: 'tam',
    beCode: "tam",
    value: "Tamil",
    label: "Tamil",
    altLang: "eng",
    cookieCode: "tam",
    altLabel: "ENG",
  },
};

export const LANGUAGE_STATUS = {
    IN_PROGRESS: 'IN_PROGRESS',
    DONE: 'DONE',
    NOT_DONE: 'NOT_DONE'
};
