import React from 'react';
import {Image, Text, View} from 'react-native';
import PropTypes from 'prop-types';
import styles from '../../CSS/VisaMamiCss';
import {scissorIcon, offerGreenSmallIcon} from '../../Constant/VisaImageConstant';


class VisaPaymentDetail extends React.Component {
  render() {
    const pricingBreakup = this.props.response.pricingSummary.countrypricingbreakups[0];
    const pricingDtl = this.props.response.bookingPaymentDetail;
    const paymentresponse = this.props.response.pricingSummary.pricingSummaryDetail;
    return (
      <View style={[styles.VisaMainContainer, styles.MakeRelative]} >
        <View style={[styles.MamiCard, styles.mar10Bt, styles.paddingBottom10]}>
          <View style={styles.priceBreakupSection}>
            <View style={[styles.flexRow, styles.priceBreakup, styles.mar5Left]}>
              <View style={[styles.ContentPortion]}>
                <View style={[styles.VisaRow,
                    styles.borderbtm,
                    styles.paddingTop10,
                    styles.paddingBottom10,
                    styles.mar10Bt]}
                >
                  <View style={styles.VisaLeft}>
                    <Text style={[styles.BlackText,
                        styles.boldFont,
                        styles.font14,
                        styles.mar10Bt]}
                    >
                                      Visa Embassy Fee
                    </Text>
                  </View>
                  <View>
                    <Text style={[styles.BlackText,
                        styles.boldFont,
                        styles.font14,
                        styles.mar10Bt]}
                    >
                            &#8377; {pricingBreakup.costAmountLCY}{' '}
                    </Text>
                  </View>
                </View>
                <View style={[styles.flexRow,
                    styles.paddingBottom12,
                    styles.mar10Bt]}
                >
                  <View style={styles.ContentPortion} >
                    <View style={styles.VisaRow}>
                      <View style={styles.VisaLeft}>
                        <Text style={[styles.BlackText, styles.boldFont, styles.font14]}>
                                  MMT Convinience Fee
                        </Text>
                      </View>
                      <Text style={[styles.BlackText, styles.boldFont, styles.font14]}>
                              &#8377; {pricingBreakup.serviceChargeLCY}{' '}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={[styles.flexRow, styles.paddingBottom12]}>
                  <View style={styles.ContentPortion}>
                    <View style={styles.VisaRow}>
                      <View style={styles.VisaLeft}>
                        <Text style={[styles.BlackText,
                            styles.boldFont,
                            styles.font14]}
                        >
                            GST
                        </Text>
                      </View>
                      <Text style={[styles.BlackText, styles.boldFont, styles.font14]}>
                              &#8377; {pricingBreakup.customerIGSTAmountLCY + pricingBreakup.customerCGSTAmountLCY + pricingBreakup.customerSGSTAmountLCY}{' '}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>


          </View>
          <View style={[styles.priceBreakupSection, styles.bggreyLight, this.showCouponDetail(pricingDtl) ? styles.showView : styles.hideView]}>
            <View style={[styles.flexRow, styles.priceBreakup]}>
              <Image style={styles.walletIcon} source={offerGreenSmallIcon} />
              <View style={styles.ContentPortion}>
                <View style={styles.VisaRow}>
                  <View style={styles.VisaLeft}>
                    <Text style={[styles.BlackText, styles.regularFont, styles.font14, styles.mar10Bt]}>Coupon code applied</Text>
                    <View style={{position: 'relative'}}>
                      <Text style={[styles.font14,
                                        styles.lightGreenTxt,
                                        styles.boldFont,
                                        styles.promoBorder,
                                        styles.flexStart]}
                      >
                        {pricingDtl.ecouponCode}
                      </Text>
                      <Image style={styles.scissorIcon} source={scissorIcon} />
                    </View>
                  </View>
                  <Text style={[styles.lightGreenTxt,
                                styles.blackFont,
                                styles.font14,
                                styles.regularFont]}
                  >- &#8377; {pricingDtl.ecouponAmount}{' '}
                  </Text>
                </View>
              </View>

            </View>
          </View>

          <View style={[styles.PriceTotal, styles.elevation1]}>
            <View style={[styles.flexRow, styles.mar10Bt]}>
              <View style={styles.flexRow}>
                <View style={{}}>
                  <Text style={[styles.blackFont, styles.BlackText, styles.mar8Bt, styles.font14]}>
                                TOTAL PAID
                  </Text>
                  <View style={styles.flexRow}>
                    <Text style={[styles.lightGreenTxt, styles.font12, styles.boldFont, this.showCouponDetail(pricingDtl) ? styles.showView : styles.hideView]}>You saved &#8377; {paymentresponse.ecouponAmount}{' '}</Text>

                  </View>
                </View>
              </View>
              <View style={styles.marginLeftAuto}>
                <Text style={[styles.font18, styles.BlackText, styles.blackFont, styles.mar5Bt]}>&#8377; {paymentresponse.receivedAmount}{' '}</Text>
                <Text style={[styles.font12, styles.darkRed, styles.AlignRight, styles.lineThought, this.showCouponDetail(pricingDtl) ? styles.showView : styles.hideView]}>&#8377; {paymentresponse.totalCost}{' '}</Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  }

  showCouponDetail(pricingDtl) {
    return (pricingDtl && pricingDtl.ecouponCode != null && pricingDtl.ecouponAmount > 0);
  }
}

VisaPaymentDetail.propTypes = {
  response: PropTypes.object.isRequired
};

export default VisaPaymentDetail;
