import React from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import  isEmpty  from 'lodash/isEmpty';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import styles from './RefundIssueCardCss.js';
import { HELP_ICON, RIGHT_ARROW, BACK_WHITE_ICON } from '../../../../FlightImageConstants';
import FlightBookingModule from '@mmt/legacy-commons/Native/FlightBookingModule';
import {
  appendPlatformInfo,
  setWebViewAuth,
  openWebView,
} from '../../../../../utils/PostSaleUtil';
import FlightBookingTrackingHelper from '../../helper/FlightBookingTrackingHelper';
import { getFont } from '../../../../../PostSalesStyles';

function RefundIssueCard({ text, desc, url, bookingId }) {
  const { fsStyle, ...fonts } = getFont(true);
  const onNeedHelpPress = async () => {
    const userInfo = await FlightBookingModule.getRequestHeader(
      bookingId,
      'NEED_HELP_REFUND_BREAKUP',
    );
    const uri = appendPlatformInfo(url, userInfo);
    if (!isEmpty(uri)) {
      openWebView(
        'Need Help',
        BACK_WHITE_ICON,
        uri,
        null,
        bookingId,
        'NEED_HELP_REFUND_BREAKUP',
        setWebViewAuth(userInfo.mmtAuth),
        true,
      );
      FlightBookingTrackingHelper.trackClickEvent(
        'refundBreakupV2',
        'MI_RefundBreakup_MSR_Submit',
        { bookingId },
      );
    }
  };

  if (!!isEmpty(url)) {
    return null;
  }

  return (
    <View style={styles.issueCardWrapper}>
      <TouchableOpacity style={styles.innerSectionDetails} onPress={onNeedHelpPress}>
        <View
          style={[
            AtomicCss.flex1,
            AtomicCss.spaceBetween,
            AtomicCss.flexRow,
            AtomicCss.alignCenter,
          ]}
        >
          <Image style={styles.iconStyle} source={HELP_ICON} />
          <View style={AtomicCss.flex1}>
            {!isEmpty(text) && (
              <Text
                style={[
                  fonts.blackFontFamily,
                  fsStyle.font14,
                  AtomicCss.blackText,
                  AtomicCss.marginBottom5,
                ]}
              >
                {text}
              </Text>
            )}
            {!isEmpty(desc) && (
              <Text style={[fonts.regularFontFamily, fsStyle.font13, AtomicCss.greyText]}>
                {desc}
              </Text>
            )}
          </View>
          <Image className={styles} style={styles.arrowStyle} source={RIGHT_ARROW} />
        </View>
      </TouchableOpacity>
    </View>
  );
}

export default RefundIssueCard;
