import React, { useState } from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import TripAssuredTag from '../TripAssuredTag/index';
import CardInfo from './cardInfo';
import isEmpty from 'lodash/isEmpty';
import { getFont } from '../../../PostSalesStyles';
import { useTheme } from '../../../theme/theme.context';
import { dropDownArrow } from '../../../Rails/Utils/RailImageConstant';
import createStyle from './styleCss';


interface Data {
    id: number;
    iconImage?: string;
    cardTitle: string;
    cardDescription?: string;
    poweredByImg?: string;
    url?: string;
    actionTracker: string;
};

interface TripAssuredTagData {
    text: string;
    iconUrl: string;
};
interface SpecialClaimDataList {
    popupHeader: string;
    popupNo: string;
    popupYes: string;
    popupTxt: string;
};
interface TripAssuredCardProps {
    rapInsuranceData: {
        cardData: Data[];
        tripAssuredTagData?: TripAssuredTagData;
        heading: string;
        subHeading: string;
        hideCardText: string;
        showCardText: string;
        insuranceAmount?: string;
    };
    specialClaimDataList?: SpecialClaimDataList;
    onClickEventTracker: (arg: string) => void;
    raiseClaimClicked: (arg: SpecialClaimDataList) => void;
}
const TripAssuredCard: React.FC<TripAssuredCardProps> = ({ rapInsuranceData, specialClaimDataList, onClickEventTracker, raiseClaimClicked }) => {
    if (isEmpty(rapInsuranceData)) {
        return null;
    }
    const { cardData, tripAssuredTagData, heading, subHeading, showCardText, hideCardText, insuranceAmount } = rapInsuranceData || {};
    const [showAllCards, setShowAllCards] = useState(false);
    const { fsStyle, ...fonts } = getFont(true);
    const { psTheme: theme } = useTheme();
    const styles = createStyle(fonts, theme);

    const toggleCardsVisibility = () => {
        setShowAllCards(!showAllCards);
    };
    const renderCardInfo = (data: Data, index: number) => (
        <View key={index}>
            <CardInfo data={data} specialClaimDataList={specialClaimDataList} raiseClaimClicked={raiseClaimClicked} onClickEventTracker={onClickEventTracker} />
        </View>
    );
    return (
        <View style={styles.tripAssuredWrapper}>
            <View style={styles.tripWrapper}>
                {!isEmpty(tripAssuredTagData) && <TripAssuredTag tripAssuredTagData={tripAssuredTagData} />}
                {!!insuranceAmount && <Text style={styles.insuranceAmountStyle}>{insuranceAmount}</Text>}
            </View>
            {!!heading && <Text style={styles.headingStyle}>{heading}</Text>}
            {!!subHeading && <Text style={styles.subHeadingStyle}>{subHeading}</Text>}
            <View>
                {!isEmpty(cardData) && (showAllCards ? (
                    cardData.map(renderCardInfo)
                ) : (
                    cardData.length > 0 && cardData.slice(0, 1).map(renderCardInfo)
                ))}
            </View>
            {!isEmpty(cardData) && cardData.length > 1 && hideCardText && showCardText && (<TouchableOpacity onPress={toggleCardsVisibility} style={styles.linkWrapper}>
                <Text style={styles.showCardTextStyle}>
                    {(showAllCards ? hideCardText : showCardText)}
                </Text>
                <Image source={dropDownArrow} style={showAllCards ? styles.upArrowIconStyle : styles.arrowIconStyle} />
            </TouchableOpacity>)}
        </View>
    )
};

export default TripAssuredCard;