import React from 'react';
import { Animated, Platform, StyleSheet, ViewProps, ViewStyle } from 'react-native';
import shadow from './shadow';

interface CardProps extends ViewProps {
  children: React.ReactNode | React.ReactNode[];
}

function Card(props: CardProps) {
  const flattenedStyles = StyleSheet.flatten(props.style) || {};
  const { elevation = Platform.OS === 'ios' ? 0 : 4 }: ViewStyle = flattenedStyles;
  return (
    <Animated.View style={[props.style, !!elevation && shadow(elevation)]}>
      {props.children}
    </Animated.View>
  );
}

export default Card;
