import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Text, TouchableOpacity, View} from 'react-native';
import styles from './DynamicFormCss';


class FormDropDownRow extends Component {
  render() {
    return (
      <TouchableOpacity onPress={() => this.props.onDestinationSelected(this.props.destination)}>
        <View style={this.props.style}>
          <View style={styles.destinationContentContainer}>
            <Text style={styles.destinationText}>
              {this.props.destination.value}
            </Text>
          </View>
        </View>
      </TouchableOpacity>
    );
  }
}

FormDropDownRow.propTypes = {
  style: PropTypes.object,
  onDestinationSelected: PropTypes.func.isRequired,
  destination: PropTypes.object.isRequired
};

FormDropDownRow.defaultProps = {
  style: styles.destinationRowContainer
};

export default FormDropDownRow;
