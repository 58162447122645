import { StyleSheet } from "react-native";
import { Fonts, Theme } from '../../../types';

export default (fonts: Fonts, theme: Theme) => {
    const { color } = theme;
    return StyleSheet.create({
        claimRejectedWrapper: {
            backgroundColor: color.white,
            paddingTop: 16,
            marginBottom: 10,
        },
        claimRejectedContainer: {
            paddingHorizontal: 18,
        },
        tripTagContainer: {
            marginBottom: 12,
        },
        redirectLink: {
            borderTopColor: color.lightGray,
            borderTopWidth: 1,
            marginLeft: -27,
        },
        rightArrowStyle: {
            width: 24,
            height: 24
        },
        subHeadingStyle: {
            marginBottom: 12,
            ...fonts.regularFontFamily,
            fontSize: fonts.fontSize.font14,
            color: color.grey,
        },
        headingStyle: {
            ...fonts.boldFontFamily,
            fontSize: fonts.fontSize.font16,
            color: color.black,
            marginBottom: 10,
        },
    });
}


export const getHtmlStyle = (fonts: Fonts, theme: Theme) => {
    const { color } = theme;
    return StyleSheet.create({
        p: {
            fontSize: fonts.fontSize.font16,
            ...fonts.regularFontFamily,
        },
        b: {
            ...fonts.boldFontFamily,
        },
    });
};