import { StyleSheet } from "react-native";

export default (theme, fonts) => StyleSheet.create({
  CardHead: {
    fontSize: fonts.fontSize.font21,
    color: theme.color.textGrey,
    ...fonts.regularFontFamily,
    marginBottom: 10,
    paddingHorizontal: 15,
  },
  cardSubHeading: {
    borderBottomColor: theme.color.lightGrey,
    borderBottomWidth: 1,
    borderTopColor: theme.color.lightGrey,
    borderTopWidth: 1,
    paddingBottom: 10,
    paddingTop: 10,
    marginBottom: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: 5,
  },
  FareBkHeading: {
    fontSize: fonts.fontSize.font14,
    color: theme.color.textGrey,
    ...fonts.boldFontFamily,
  },
  FareDtls: {
    marginBottom: 10,
    borderBottomColor: theme.color.lightGrey3,
    borderBottomWidth: 1,
    paddingBottom: 5,
    paddingHorizontal: 15,
  },
  FareDtlsTxt: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 3,
  },
  FareDiffernce: {
    paddingVertical: 10,
    marginBottom: 10,
  },
  TotalFare: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 15,
  },
  FareBkTotalTxt: {
    fontSize: fonts.fontSize.font16,
    color: theme.color.textGrey,
    ...fonts.boldFontFamily,
    maxWidth:'80%',
  },
  font12: {
    fontSize: fonts.fontSize.font12,
  },
  font16: {
    fontSize: fonts.fontSize.font16,
  },
  whiteCard: {
    backgroundColor: theme.color.white,
    padding: 15,
    marginVertical: 8,
    elevation: 2,
    shadowColor: theme.color.black,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.3,
    shadowRadius: 1,
  },
  slashedAmountStyle: {
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
    fontSize: fonts.fontSize.font12,
    marginRight: 5,
  },
  amountContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  headingIconStyle: {
    width: 19,
    height: 20,
  },
  headingText: {
    paddingHorizontal: 7,
    color: theme.color.green,
    fontWeight: '500',
    fontSize: fonts.fontSize.font14,
  },
});
