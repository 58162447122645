import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';
import { StyleSheet } from 'react-native';

const styles =(theme, fonts)=> StyleSheet.create({
  dateChangeBoxContainer: {
    backgroundColor: theme.color.white,
    marginTop: -1,
    ...getPlatformElevation(2),
  },

  dateChangeBoxStyle: {
    backgroundColor: theme.color.creamWhite,
    width: '90%',
    marginHorizontal: '5%',
    marginTop: -1,
    paddingLeft: 10,
    paddingVertical: 10,
    borderWidth: 0.5,
    borderColor: theme.color.lightYello,
    marginBottom: 15,
    borderRadius: 3,
  },

  crossIconActionWrapper: {
    position: 'absolute',
    right: 5,
    top: -20,
  },

  crossIconStyle: {
    width: 16,
    height: 16,
    tintColor: theme.color.white,
  },

  dateChangeHeading: {
    marginBottom: 12,
    ...getPlatformElevation(3),
    backgroundColor: theme.color.creamWhite,
  },

  dateChangeBorderLeftHighLiter: {
    paddingHorizontal: 16,
    paddingTop: 16,
    paddingBottom: 12,
    borderLeftColor: theme.color.lightYello,
    borderLeftWidth: 6,
  },

  ctripODCHeading: {
    width: '85%',
    fontWeight: 'bold',
    color: theme.color.black,
  },
  ctripODCSubHeading: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    color: theme.color.black,
  },
  marginBetweenText: {
    marginLeft: 5,
  },
  infoCardWrapper: {
    backgroundColor: '#fff',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginBottom: 1,
  },

  infoCardContent: {
    paddingHorizontal: 8,
    paddingVertical: 15,
    flex: 1,
  },

  infoCustom: {
    fontSize: 14,
    color: '#4a4a4a',
    lineHeight: 18,
  },
  headingIconWrapper: {
    width: 46,
    alignItems: 'flex-start',
    top: 14,
    left: 16,
  },
  headingIconStyle: {
    width: 19,
    height: 20,
  },

  redirectBtnWrapper: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 60,
    paddingRight: 35,
    justifyContent: 'space-between',
    flexDirection: 'row',
    backgroundColor: '#fff',
    borderBottomWidth: 1,
    borderBottomColor: '#ededed',
    alignItems: 'center',
  },

  rightArrowIcon: {
    width: 9,
    height: 13,
  },

  linkBtnTxt: {
    color: '#008cff',
  },
  buttonWrapper: {
    paddingTop: 20,
    width: '90%',
  },
});


export default styles;
