import React, { useState, useEffect } from 'react';
import { Text, View, Image, ScrollView, TouchableOpacity } from 'react-native';
import Actions from '../../../navigation/postSalesNavigation';
import Header from '../../Components/Header';
import styles from './CancelledDtlsCss';
import Breadcrumb from '../Breadcrumb';
import FareBreak from './FareBreak';
import DeductionBreakup from './DeductionBreakup';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import Button from '../../Components/Button';
import NeedSupportCard from '../../Components/NeedHelpCard';
import PayInfoCard from './PayInfoCard';
import OverlayMessage from '../../../Common/InstantRefund/Overlays/OverlayMessage';
import TCSBottomSheet from './TCSBottomSheet';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import {isNetworkAvailable} from '@mmt/legacy-commons/Common/utils/AppUtils';
import SimpleHeader from '@mmt/legacy-commons/Common/Components/Header/SimpleHeader';
import NoInternetView from '@mmt/legacy-commons/Common/Components/Error/NoInternetView';
import ProgressView from '../../../Common/ProgressView/ProgressView';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import {getCommonHeaders} from '../../../utils/NetworkUtils';
import CancellationPolicyModal from '../../Components/CancellationPolicyModal';
import { openCustomerFormInWebView } from '../../../utils/PostSaleUtil';
import {HolidayCancellationConstant, CONNECT_WITH_HELPDESK} from '../../HolidayBookingConstants';
import {colors} from '@mmt/legacy-commons/Styles/globalStyles';
import HolidayBookingTrackingHelper from '../../HolidayBookingTrackingHelper';
import ConfirmCancelledOverlay from '../CancellationRefundMode/ConfirmCancelledOverlay';
import {openHolidayCancellationThankYou} from "../../HolidayBookingDetailUtils";
import {ZeroCancelIcon} from '../../HolidayBookingConstants';
import { themeColors } from '../../../PostSalesStyles';

const CancelledHolidayPackage = (props) => {

    const [activeStep, setActiveStep]= useState(0);
    const [isOverlay, setIsOverlay]= useState('');
    const [responseJson, setResponseJson]= useState(null);
    const [viewState, setViewState]= useState(ViewState.LOADING);
    useEffect(()=>{
        fetchJSONAsync();
    },[]);

  const handleBottomOverlay = (showOverly) => {
    setIsOverlay(showOverly);
  };


  const handlePolicyOverlay = () => {
    setIsOverlay((prevState) => {
      if (prevState === 'policyOverlay') {
        return '';
      } else {
        HolidayBookingTrackingHelper.trackClickEvent(
          props.pageName,
          'View Policy',
        );
        return 'policyOverlay';
      }
    });
  };

  const openNeedHelpPage = () => {
    const action = {
      header: 'HEADER',
      webViewHeader:  CONNECT_WITH_HELPDESK,
      url: responseJson.holidayHelpCard.customerFormUrl,
    };
    HolidayBookingTrackingHelper.trackClickEvent(
      props.pageName,
      'MSR_Entry',
    );
    openCustomerFormInWebView(action, responseJson.bookingId, 'CONNECT_HELPDESK');
  };
  const handleButtonClick = () => {
    if (responseJson.refundAmount > 0) {
      HolidayBookingTrackingHelper.trackClickEvent(
        props.pageName,
        'Select Refund Mode',
      );
      Actions.holidayCancellationRefundMode({
        pageName: 'SelectRefundMode',
        headerSubTitle: props.packageName + '   ' + props.packageDuration,
        holidayResponse: props.holidayResponse,
        cancellationResponse: responseJson,
      });
    } else {
      HolidayBookingTrackingHelper.trackClickEvent(
        props.pageName,
        'Confirm Cancellation',
      );
      refundpopup();
    }
  }

    const handleConfirmOverlay = () => {
      setIsOverlay('');
    }

    const handleConfirmClick=()=>{
      openHolidayCancellationThankYou(props.holidayResponse, 'RefundToCustomer');
    }

    const refundpopup = () => {
      setIsOverlay('ConfirmCancel');
    };

    const goBack = () => {
        Actions.pop();
    };

   const fetchJSONAsync = async () => {
        try {
            const hasNetwork = await isNetworkAvailable();
            if (!hasNetwork) {
                setViewState(ViewState.NO_INTERNET);
                return;
            }
            const response = await
                fetch(props.url, {
                  method: 'POST',
                  headers: await getCommonHeaders(props.mmtAuth, props.loggingTrackInfo),
                  body: JSON.stringify(props.cancellationPreviewRequest)
                });
            if (response && response.ok) {
                const body = await response.json();
                if (body && body.status) {
                  setResponseJson(body);
                  setViewState(ViewState.SHOW_DETAIL);
                  trackLoadEvent();
                } else {
                  setViewState(ViewState.ERROR);
                }
            } else {
                    setViewState(ViewState.ERROR);
            }
        } catch (error) {
            setViewState(ViewState.ERROR);
            console.log(error);
        }
  }

  const trackLoadEvent = () => {
    HolidayBookingTrackingHelper.trackViewEvent(
      props.pageName,
      'Cancellation Review Page',
    );
  };

  const renderStateWise = () => {
    switch (viewState) {
      case ViewState.LOADING:
        return renderProgressView();
      case ViewState.NO_INTERNET:
        return renderNoNetworkView();
      case ViewState.ERROR:
        return renderErrorView();
      default:
        return renderPage();
    }
  }


  const renderPage = () => {
    const { cancelTopCard, steps, cancelPackageName, zcCancellationMessage, paymentDetails,
      refundBreakup, cancellationBreakup, totalCost, holidayHelpCard,
      tcsMessagePopup, dynamicCancellationPolicy, confirmCancellationOverlay, cancellationHeader, myCashMessage } = responseJson;
       return (
        <View style={styles.pageWrapper}>
            <Header
                title={cancelTopCard.heading}
                subTitle={`${cancelTopCard.packageName} - ${cancelTopCard.travelDates}`}
                navigation={props.navigation}
                goBack={goBack}
            />
            {steps && steps.length>0 && (<Breadcrumb activeStep={activeStep} data={steps} />)}
            <ScrollView>
                <View style={styles.whiteCard}>
                    <View style={styles.packageHeading}>
                        <Text numberOfLines={1} style={[AtomicCss.font16, AtomicCss.blackText, AtomicCss.boldFont]}>{cancelPackageName}</Text>
                    </View>
                    <View style={styles.statusInfoWrapper}>
                        <View style={[AtomicCss.marginBottom5, AtomicCss.flexRow, AtomicCss.justifyCenter, AtomicCss.alignCenter]}>
                              {zcCancellationMessage.type == "FullRefund" && (<Image style={styles.zcIconMd} source={ZeroCancelIcon} />)}
                            <Text style={[styles.zcInfoTxt, getStyledColor(zcCancellationMessage)]}>{zcCancellationMessage.zcMessage}</Text>
                        </View>
                         <TouchableOpacity style={styles.viewWrapper} onPress={handlePolicyOverlay}>
                            <Text style={[{color:themeColors.linkTextColor}, AtomicCss.boldFont, AtomicCss.font14]}>{zcCancellationMessage.button.text.toUpperCase()}</Text>
                        </TouchableOpacity>
                    </View>
                    <View style={styles.autoRefundBreakupMain}>
                        <FareBreak totalCostNode={totalCost} farebreakupData={paymentDetails.paymentBreakups}/>
                        <PayInfoCard pastPayments={paymentDetails.pastPayments}/>
                        <DeductionBreakup
                            handleBottomOverlay={handleBottomOverlay}
                            deductions={cancellationBreakup}
                            myCashMessage={myCashMessage}
                            deductionHeader={cancellationHeader}
                            refundBreakup={refundBreakup}
                            customerCurrency={props.currency}
                        />
                    </View>
                    <View style={styles.footerBtnWrapper}>
                        <Button
                            pageName="holidayCancellationReview"
                            btnTxt={refundBreakup.buttons[0].text}
                            button={refundBreakup.buttons[0]}
                            handleClick={handleButtonClick}
                        />
                    </View>
                </View>
               {holidayHelpCard && <NeedSupportCard cardData={holidayHelpCard} handleButtonClick={openNeedHelpPage}/>}

            </ScrollView>

            {isOverlay === 'TCSInfo' &&
                <OverlayMessage
                    content={
                        <TCSBottomSheet
                            isOverlayVisble={isOverlay}
                            handleBottomOverlay={handleBottomOverlay}
                            tcsMessagePopup={tcsMessagePopup}
                        />
                    }
                    handleBottomOverlay={handleBottomOverlay}
                />
            }
             {isOverlay === 'policyOverlay' &&
                <CancellationPolicyModal
                  pageName="holidayCancellationReview"
                  fromCancellation={false}
                  cancellationPolicyPage={dynamicCancellationPolicy}
                  handleButtonClick={handlePolicyOverlay}
                  onBackPopUp={handlePolicyOverlay}
              />
            }
            {isOverlay === "ConfirmCancel" &&
                <OverlayMessage
                    isBorderRadius={true}
                    handleBottomOverlay={handleConfirmOverlay}
                    content={
                        <ConfirmCancelledOverlay
                            confirmCancellationData={confirmCancellationOverlay}
                            handleBackClick={handleConfirmOverlay}
                            handleConfirmClick={handleConfirmClick}
                        />
                    }
                />
            }
        </View>
    );
  }
  const reloadHandler=()=>{
    setViewState(ViewState.LOADING);
    fetchJSONAsync(props.url);
  }

  const renderNoNetworkView = () => (
    <View style={{flex: 1}}>
      <SimpleHeader
        title="Back"
        iconPress={goBack}
      />
      <NoInternetView
        onRetry={reloadHandler}
      />
    </View>
  );

  const renderProgressView = () => (
    <View style={{flex: 1}}>
      <SimpleHeader
        title={HolidayCancellationConstant.CANCEL_HOLIDAY_PACKAGE}
        subTitle={props.packageName + props.packageDuration}
        iconPress={goBack}
      />
      <ProgressView message= {HolidayCancellationConstant.FETCHING_CANCELLATION_DETAILS}/>
    </View>
  );

  const renderErrorView = () => (
    <View style={{flex: 1}}>
      <SimpleHeader
        title={HolidayCancellationConstant.CANCEL_HOLIDAY_PACKAGE}
        subTitle={props.packageName + props.packageDuration}
        iconPress={goBack}
      />
      <ErrorView
        showImage
        showRetry
        onRetry={reloadHandler}
        buttonGradient={themeColors.gradientBtnColor}
      />
    </View>
  );

  const getStyledColor = (message) => {
    if(message.type == "FullRefund") {
      return {
        color: colors.green,
      }
    }
    else if(message.type === "NoRefund") {
      return {
        color: colors.red
      }
    }
    else {
      return {
        color: colors.greyLight
      }
    }
  }

  return (
    <View style={{flex: 1}}>
      {renderStateWise()}
    </View>
  );

}

export default CancelledHolidayPackage;
