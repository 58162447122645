import { StyleSheet } from 'react-native';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';

export default (theme) =>
  StyleSheet.create({
    contentTitle: {
      color: colors.black,
      marginBottom: 24,
    },
    contentFooter: {
      flexDirection: 'row',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
    },
    actionBtn: {
      paddingVertical: 10,
    },

    fltStatusCard: {
      borderWidth: 1,
      borderColor: '#e7e7e7',
      borderRadius: 4,
      marginBottom: 20,
    },
    cityInfoWrapper: {
      backgroundColor: '#f9f9f9',
      padding: 12,
    },
    flightDtlsRow: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },

    titleTxt: {
      color: '#747474',
      marginBottom: 5,
    },
    destName: {
      fontSize: 14,
      color: '#000',
      marginBottom: 4,
    },

    fltDisc: {
      fontSize: 12,
      color: '#4a4a4a',
    },

    arrowRight: {
      width: 10,
      height: 6,
      marginTop: 5,
      marginHorizontal: 5,
    },
    infoTxt: {
      color: '#4a4a4a',
    },
    imgWrapper: {
      width: 32,
      height: 32,
      marginRight: 10,
    },
    submitBtn: {
      alignItems: 'center',
      justifyContent: 'center',
      paddingHorizontal: 36,
      paddingVertical: 14,
      borderRadius: 40,
      overflow: 'hidden',
    },
    actionBtnText: {
      color: theme.color.primary,
    },
  });
