import React from 'react';
import { Text, View } from 'react-native';
import { getFont } from '../../../../../PostSalesStyles';
import { useTheme } from '../../../../../theme/theme.context';
import { getStyle, getHtmlStyles } from '../StatusCard/ContactUsPopup/ContactUsPopupCss';
import HTMLView from 'react-native-htmlview';
import {openDialer} from '../../../../../Common/commonUtil'

type ctripODCdetailItemType = {
  popupInfo: popupInfoType;
};

type popupInfoType = {
  heading: string;
  desc: string;
  ctaText: string;
};

interface OverlayProps {
  ctripODCdetail: ctripODCdetailItemType;
  handleBottomOverlay: void;
}

const RequestCtripODCViewMoreBottomOverlay: React.FC<OverlayProps> = ({
  ctripODCdetail,
  handleBottomOverlay,
}) => {

  const { popupInfo } = ctripODCdetail;
  const { heading, desc, ctaText } = popupInfo || {};
  const fonts = getFont(true);
  const { psTheme } = useTheme();
  const styles = getStyle(fonts, psTheme);
  const htmlStyle = getHtmlStyles(fonts, psTheme);
  return (
    <View style={styles.container}>
      {!!heading && <Text style={styles.title}>{heading}</Text>}
      <View style={styles.barStyles}></View>
      {!!desc && (
          <HTMLView
            value={desc}
            onLinkPress={openDialer}
            stylesheet={htmlStyle}
          />
        )}
      <View style={styles.ctaTextWrap}>
        {!!ctaText && (
          <Text onPress={handleBottomOverlay} style={styles.ctaText}>
            {ctaText}
          </Text>
        )}
      </View>
    </View>
  );
};

export default RequestCtripODCViewMoreBottomOverlay;
