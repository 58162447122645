import React from 'react';
import { View, Text, TouchableOpacity, Image, ImageSourcePropType } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { ArrowDown } from '../../../../CabImageConstants';
import getStyles from './BookingInfoDetailsCss';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import CabBookingDetailsConstant from '../../../../CabBookingDetailsConstant';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

interface Props {
  tripTypeText: string;
  fromCity: string;
  fromCityDesc: string;
  toCity: string;
  toCityDesc: string;
  dateTime: string;
  isRoundTrip: boolean;
  returnDateTime: string;
  bookingInfoClick: (arg: string) => void;
}
interface TripDetailProps {
  fromCity : string,
  fromCityDesc : string ,
  dateTime : string,
  toCity : string,
  toCityDesc : string,
  returnLeg : boolean
}

const ExpendedBookingInfo: React.FC<Props> = ({
  tripTypeText,
  fromCity,
  fromCityDesc,
  toCity,
  toCityDesc,
  dateTime,
  isRoundTrip,
  returnDateTime,
  bookingInfoClick,
}) => {
  const { psTheme } = useTheme();
  const { fsStyle, ...fonts } = getFont();
  const { color } = psTheme;
  const styles = getStyles(color, fonts);

  const tripDetail = ({fromCity , fromCityDesc , dateTime , toCity , toCityDesc, returnLeg} : TripDetailProps) => {
    const { psTheme } = useTheme();
    const { fsStyle, ...fonts } = getFont();
    const { color } = psTheme;
    const styles = getStyles(color, fonts);
    return (
      <>
        <View style={styles.detailsRowSection}>
          <View style={[styles.dotPositionWrapper]}>
            <View style={[styles.dot]}></View>
          </View>
          {!!fromCity && (
            <Text
              style={[
                styles.cityHeading,
                AtomicCss.marginBottom3,
              ]}
            >
              {fromCity}
            </Text>
          )}
          {!!fromCityDesc && (
            <Text
              style={[
                [
                  styles.cityDesc,
                  AtomicCss.marginBottom3,
                ],
              ]}
            >
              {fromCityDesc}
            </Text>
          )}
          {!returnLeg &&  !!dateTime && (
            <Text style={styles.dateText}>
              {dateTime}
            </Text>
          )}
        </View>
        <View style={[styles.detailsRowSection, styles.lastRow]}>
          <View style={[styles.dotFilled, styles.dotFilledPosition]}></View>
          {!!toCity && (
            <Text
              style={[
                styles.cityHeading,
                AtomicCss.marginBottom3,
              ]}
            >
              {toCity}
            </Text>
          )}
          {!!toCityDesc && (
            <Text style={[styles.cityDesc,  AtomicCss.marginBottom3,]}>
              {toCityDesc}
            </Text>
          )}
          {returnLeg &&  !!dateTime && (
            <Text style={styles.dateText}>
              {dateTime}
            </Text>
          )}
        </View>
      </>
    );
  };

  const roundTripDetails = {
    fromCity : toCity,
    fromCityDesc : toCityDesc,
    dateTime : returnDateTime,
    toCity : fromCity,
    toCityDesc : fromCityDesc,
    returnLeg : true
  }
  return (
    <>
      {!!tripTypeText && (
        <View style={AtomicCss.marginVertical5}>
          <Text style={styles.triptype}>{tripTypeText}</Text>
        </View>
      )}
      <View>
      {tripDetail({fromCity, fromCityDesc, dateTime, toCity, toCityDesc, returnLeg : false})}
        {isRoundTrip && (
          <View style={AtomicCss.marginTop20}>{tripDetail(roundTripDetails)}</View>
        )}
        <TouchableOpacity
          style={[styles.arrowStyleWrapper, styles.activeArrow]}
          onPress={() => bookingInfoClick(CabBookingDetailsConstant.LOCATION_COLLAPS_CLICK)}
        >
          <Image style={styles.arrowstyle} source={ArrowDown as ImageSourcePropType} />
        </TouchableOpacity>
      </View>
    </>
  );
};

export default ExpendedBookingInfo;
