import { StyleSheet } from "react-native";

export default (theme) =>
  StyleSheet.create({
    person: {
      width: 56,
      height: 56,
      marginRight: 10,
      borderRadius: 50,
    },
    host: {
      width: 68,
      height: 18,
      marginTop: 5,
    },
    call: {
      width: 40,
      height: 40,
    },
    message: {
      width: 40,
      height: 40,
    },
    docList: {
      paddingLeft: 15,
      paddingRight: 15,
    },
    bullet: {
      width: 4,
      height: 4,
      marginRight: 10,
      marginTop: 6,
      backgroundColor: theme.color.defaultTextColor,
      borderRadius: 50,
    },
    tag: {
      backgroundColor: theme.color.grayBg,
      borderRadius: 2,
      paddingHorizontal: 8,
      paddingVertical: 4,
      marginRight: 5,
    },
    borderBlock: {
      borderTopWidth: 1,
      borderColor: theme.color.lightGrey,
    },
    freeCancelCardWrapper: {
      borderBottomWidth: 1,
      borderBottomColor: theme.color.lightGrey18,
      borderRadius: 16
    },
    headerSec: {
      paddingHorizontal: 12,
      paddingTop: 16,
    },
    headingIcon: {
      width: 24,
      height: 24,
      marginRight: 10,
      backgroundColor: theme.color.grey2,
    },
    cancelDetails: {
      backgroundColor: theme.color.white,
      flex: 1,
      borderRadius: 16
    },
    refundDetails: {
      backgroundColor: theme.color.grayBg,
      borderRadius: 3,
      paddingHorizontal: 8,
      paddingVertical: 6,
      marginLeft: -8,
      flexDirection: "row",
      alignItems: "center",
    },
    greenText: {
      color: theme.color.green,
    },
    notification: {backgroundColor:'red', width:15, height: 15,borderRadius:15, right:-2, top: -4, position:'absolute',zIndex:1},
    redirectLinkNewCustomStyles: {
      paddingVertical: 16,
      paddingLeft: 12,
      paddingRight: 12
    },
    hostImgWrapper:{
      width:70,
      height:70,
      marginLeft:10,
    },
    hostImg:{
      width:70,
      height:70,
      borderRadius:50
    },
    cottageIcon:{
      width:16,
      height:16,
      marginRight:5,
    },
    careTackerSection:{
      padding:12,
    },
    careTakerIcon:{
      width:16,
      height:16,
      marginRight:10,
    },
    hostedByDetails:{
      padding:12,
      borderBottomWidth: 1,
      borderBottomColor: theme.color.lightGrey18,
    },
    iconStyle:{
      width:20,
      height:20,
      marginRight:10,
    },
  });
  