import { StyleSheet } from 'react-native';


export default () => {
  return StyleSheet.create({
    customRatingStyle: {
      flexDirection: 'row',
    },
    starImageStyle: {
      width: 10,
      height: 10,
      resizeMode: 'contain',
      marginRight: 2,
      opacity: 0.8
    },
    defaultStarImageStyle: {
      width: 10,
      height: 10,
      resizeMode: 'contain',
      marginRight: 2,
      opacity: 0.8
    },
  });
}