const VisaDetailConstant = {
  /* Top Status Cards */
  TOP_STATUS_CARD_BOOKING_INIT_BEFORE_APPLICATION_FILED: 'TopStatusCard_Booking_Init_BeforeApplicationFiledState',
  TOP_STATUS_CARD_BOOKING_CANCELLED: 'TopStatusCard_Booking_Cancelled',
  TOPS_STATUS_CARD_APPLCATION_FILED_TODAY_STATE: 'TopStatusCard_Application_filed_TodayState',
  TOP_STATUS_CARD_UNDERPROCESS_STATE: 'TopStatusCard_Application_UnderProcess_State',
  TOP_STATUS_CARD_OTB_REQUIRED_STATE: 'TopStatusCard_Application_OTB_Required_State',
  TOP_STATUS_CARD_TRAVEL_COMPLETED_STATE: 'TopStatusCard_Application_Travel_Completed_State',
  TOP_STATUS_CARD_ALL_VISA_REJECTED_STATE: 'TopStatusCard_Application_All_Visa_Rejected_State',
  TOP_STATUS_CARD_ADDITIONAL_DOCUMENT_STATE: 'TopStatusCard_Application_Additional_Document_Required_State',
  TOP_STATUS_CARD_APPROVED_STATE: 'TopStatusCard_Application_Approved_State',
  TOP_STATUS_CARD_OTB_PENDING_STATE: 'TopStatusCard_Application_OTB_Pending_State',
  TOP_STATUS_CARD_PARTIAL_VISA_REJECTED_STATE: 'TopStatusCard_Application_Partial_Visa_Rejected_State',
  /* Other Cards */
  VISA_DETAIL_CARD: 'VisaDetailCard',
  VISA_TRACKER_CARD: 'VisaTrackerCard',
  VISA_APPLICANT_CARD: 'VisaApplicantCard',
  VISA_CANCELLATIONPOLICY_CARD: 'VisaCancellationPolicyCard',
  VISA_OTBPOLICY_CARD: 'VisaOtbPolicyCard',
  VISA_PAYMENT_CARD: 'TotalPaidCard',
  VISA_NEED_HELPUS_CARD: 'NeedHelpCard',
  /* Visa Action Family */
  TRACK_VISA_STATUS: 'TrackVisaStatus',
  VIEW_VISA_DOCUMENT: 'ViewVisaDocument',
  VIEW_PAYMENT_BREAKUP: 'SeePaymentBreakup',
  VISA_REJECTION_Message: 'VisaRejectedMessageCard',
  VISA_REJECTION_REASON: 'VisaRejectionMessageCard',
  VISA_ADDITIONAL_DOCUMENT: 'VisaAddtionalDocumentCard',
  VISA_CALL_CUSTOMERCARE: 'callHelpLine',
  VISA_FAQ: 'FAQ',
  VISA_DOWNLOAD_VISA: 'DownloadVisa',
  VIEW_DOCUMENT_CHECKLIST:'ViewDocumentChecklist',
  FILL_FORM:'FillForm',
  UPLOAD_DOCUMENTS:'UploadDocuments',
  VIEW_DOCUMENTS:'ViewDocument',
  VISA_VIEW_CANCELLATION_CHARGES: 'ViewCancellationCharges',
  VISA_CANCEL_ALL_APPLICATIONS: 'CancelAllApplications',
  VISA_CANCEL_SOME_APPLICATIONS: 'CancelSomeApplications',
  VISA_DOWNLOAD_INVOICE: 'DownloadInvoice',
  /**/
  UNDER_PROCESS: 'under process',
  VISA_DENIED: 'visa denied',
  DOCUMENTS_COMPLETE: 'documents complete',
  DOCUMENTS_INCOMPLETE: 'documents incomplete',
  DOCUMENTS_UPLOADED:'documents uploaded',
  VISA_DELAYED: 'delayed',
  VISA_EXPECTED: 'visa expected',
  VISA_APPROVED: 'visa approved',
  /* RECORD Type Enum */
  PASSPORT_FRONT: 'Passport_Front',
  PASSPORT_BACK: 'Passport_Back',
  PASSPORT_IMAGE: 'Passport_Image',
  HOTEL_VOUCHER: 'HOTEL_VOUCHER',
  E_TICKETS: 'E_tickets',
  LETTER_OF_INVITATION: 'Letter_of_Invitation',
  ITR: 'ITR',
  SPONSORSHIP_LETTER: 'Sponsorship_Letter',
  VISA: 'Visa',
  VISA_MESSAGE_REJECTION: 'immigration authorities did not give specific reasons for visa rejection. However, our agent will get in touch with you within the next 24 hours, to see if re-application is possible.',
  DEFAULT_DOC_EXTENSION: 'jpg',
  APPLICATION_PDF: 'application/',
  DOWNLOAD_VISA: 'DownloadVisa',
  ALERT_INFO_CARD: 'alertinfocard'
};

export default VisaDetailConstant;

