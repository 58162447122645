import React from 'react';
import {Image, Text, View, TouchableOpacity} from 'react-native';
import styles from '../../../acme/details/components/InclusionExclusionCss';
import PropTypes from 'prop-types';
import AtomicCss from '../../../acme/details/AtomicCss';
import {isNotNullAndEmpty} from '../../utils/BusBookingDetailUtil';
import BusBookingDetailsConstant from '../../BusBookingDetailsConstant';
import {mainCardIcon} from '../../../cab/CabImageConstants';
import { label } from '@mmt/post-sales/src/staticData/staticData';
import { getFont } from '../../../PostSalesStyles';

const inclusionIcon = require('@mmt/legacy-assets/src/green.webp');

class AmenityDetails extends React.Component {
    static navigationOptions = {
      header: null
    };

    constructor(props) {
      super(props);
      this.state = {
        viewMore: false
      }
      this.fonts = getFont(true);
    }

    render() {
      const { fonts } = this;
      return (
        <View style={[AtomicCss.whiteBg, AtomicCss.marginBottom5]}>
          <View style={[styles.CardContentFull, AtomicCss.paddingBottom8]}>
            <View style={[styles.CardContent]}>
              <Image style={styles.CardIcon} source={mainCardIcon} />
              <View style={AtomicCss.flex1}>
                <Text
                  style={[fonts.blackFontFamily, AtomicCss.blackText, fonts.font14, AtomicCss.marginBottom24]}
                >
                  {this.props.card.heading}
                </Text>
                {this.getAmenities()}
              </View>
            </View>
          </View>
        </View>
      );
    }

    viewMoreClick() {
      this.setState({viewMore: !this.state.viewMore});
    }

    getAmenities() {
      const views = [];
      const amenities = this.props.response.busBookingInfo.coachadditionaldetails[0].aminities;
      const amenitiesList = amenities && amenities.split(',');
      const { fonts } = this;

      if (isNotNullAndEmpty(amenitiesList)) {
        for (let i = 0; i < amenitiesList.length; i++) {
          if(i>=BusBookingDetailsConstant.COUNT_OF_AMENITY_TO_BE_SHOWN && !this.state.viewMore) break;
            views.push(<View
                            key={i}
                            style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom18]}
                          >
                            <Image style={styles.activityTick} source={inclusionIcon} />
                            <Text style={[styles.tickText, fonts.regularFontFamily, fonts.font14, AtomicCss.lineHeight18]}>{amenitiesList[i]}</Text>
                        </View>);
        }
        views.push(<View
                      style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom18]}
                    >
                      {
                        !this.state.viewMore && amenitiesList.length>BusBookingDetailsConstant.COUNT_OF_AMENITY_TO_BE_SHOWN &&
                        <View>
                          <TouchableOpacity onPress={() => this.viewMoreClick()}>
                            <Text style={[styles.viewMoreText, fonts.regularFontFamily, fonts.font14]}>
                              {label('bus.amenities.viewMore')} &nbsp;
                            </Text>
                          </TouchableOpacity>
                        </View>
                      }
                      {
                        this.state.viewMore && <View>
                          <TouchableOpacity onPress={() => this.viewMoreClick()}>
                            <Text style={[styles.viewMoreText, fonts.regularFontFamily, fonts.font14]}>
                              {label('bus.amenities.viewLess')} &nbsp;
                            </Text>
                          </TouchableOpacity>
                        </View>
                      }
                  </View>);
      }
      return views;
    }
}

AmenityDetails.propTypes = {
  response: PropTypes.object.isRequired,
  card: PropTypes.object.isRequired,
  pageName: PropTypes.string.isRequired
};

export default AmenityDetails;
