import {
  DATE_MONTH,
  POSTSALES_DATE_TIME,
  reformatDateString,
} from '@mmt/legacy-commons/Helpers/dateTimehelpers';
import myTripsNavigation from '../../../navigation/navigation';

import upcomingBookingIcon from '@mmt/legacy-assets/src/postSales/summary/upcomingFlightIcon.webp';
import completedBookingIcon from '@mmt/legacy-assets/src/postSales/summary/completedFlightIcon.webp';
import cancelledBookingIcon from '@mmt/legacy-assets/src/postSales/summary/cancelledFlightIcon.webp';
import failedBookingIcon from '@mmt/legacy-assets/src/postSales/summary/failedFlightIcon.webp';
import { titleCase } from '@mmt/legacy-commons/Helpers/stringHelpers';
import TripSummaryOmnitureTracker from '../../../tracking/TripSummaryOmnitureTracker';
import { getStaticData } from '../../../../staticData/staticData';
import {
  getDateTimeWithPaxInfo,
  unsuccessfulBookingTextHandler,
} from '../../../data/tripListManager';
import { isEmpty } from 'lodash';
import { msrUpdateAction } from '../../../../utils/MsrUtils';
import { getNeedHelpData, getTalkToUsDetails } from '../../../utils';
import FlightBookingDetailsConstant from '../../../../flights/modules/details/FlightBookingDetailsConstant';

export const getTicketsActionList = (
  flightDetailResponse
) => {
  const staticData = getStaticData(false);
  const bookingState = flightDetailResponse?.bookingState || {};
  const ticketsListData = [];
  const isMyPartnerBooking = flightDetailResponse?.isMyPartnerBooking;

  if (isMyPartnerBooking &&
    (bookingState.state.toUpperCase() === FlightBookingDetailsConstant.UPCOMING ||
      bookingState.state.toUpperCase() === FlightBookingDetailsConstant.COMPLETED)
  ) {
    const ticketsDataListItem = {};
    ticketsDataListItem.actionLabeltext = staticData.customerBookingConfirmationText;
    ticketsDataListItem.actionFamily = FlightBookingDetailsConstant.ACTION_DOWNLOAD_VOUCHER;
    ticketsDataListItem.lob = FlightBookingDetailsConstant.FLIGHT;
    ticketsDataListItem.triggername = FlightBookingDetailsConstant.FLIGHT_CUSTOMER_BOOKING;
    ticketsListData.push(ticketsDataListItem);
  }
  return ticketsListData;
};

const getTravelerSummary = (flightDetails) => {
  const { passengers } = flightDetails;
  const count = passengers.length;
  const firstPaxName = titleCase(passengers[0].firstName);
  if (count === 1) {
    return firstPaxName;
  }
  return `${firstPaxName} + ${count - 1}`;
};

export function getFlightsSummaryData(data, msrTicket) {
  const staticData = getStaticData();
  const departDate =
    data.minDeptDateMobile || reformatDateString(data.minDeptDate, POSTSALES_DATE_TIME, DATE_MONTH);
  const arrivalDate =
    data.maxArrDateMobile || reformatDateString(data.maxArrDate, POSTSALES_DATE_TIME, DATE_MONTH);
  let from,
    to,
    dateTimeWithPaxInfo = `${departDate}`,
    travelerSummary;
  let intentDetails = [];
  let journeyTypeText;
  const actions = [];
  const pageData = {
    fromMyTrips: true,
  };
  
  const talkToUsDetails = getTalkToUsDetails(
    data.flightSegment?.[0]?.productCode,
    data.uuid,
    data.flightCommonFields?.isInternationalFlight,
    true
  );
  const openDetails = (trackClickEvent = true) => {
    myTripsNavigation.openFlightBookingDetail(data, trackClickEvent, pageData);
  };
  const failedBooking = data.status === 'FAILED';
  if (!isEmpty(data.flightSegment)) {
    const [flightSegment] = data.flightSegment;
    const citiesCode = data.intent && data.intent.split('-');
    from = data.flightSegment.some((seg) => seg.origin === citiesCode[0])
      ? data.flightSegment.find((seg) => seg.origin === citiesCode[0]).originCityName
      : flightSegment.originCityName;
    to = data.flightSegment.some((seg) => seg.destination === citiesCode[1])
      ? data.flightSegment.find((seg) => seg.destination === citiesCode[1]).destinationCityName
      : flightSegment.destinationCityName;
    intentDetails = data.intentDetails;
    if (data.status === 'UPCOMING' && !data.farelockdata) {
      if (data.webCheckInApplicable) {
        actions.push({
          label: staticData.bookingSummaryText.additionalText.completeWebCheckinText,
          onPress: () => {
            openDetails(false);
            TripSummaryOmnitureTracker.trackBookingClickEvent(
              'flight',
              data.status,
              data.bookingID,
              'Trips_flight_dom_Web Check In',
            );
          },
        });
      } else {
        const msrAction = msrUpdateAction(msrTicket);
        if (msrAction) {
          actions.push(msrAction);
        }
      }
    } else {
      const msrAction = msrUpdateAction(msrTicket);
      if (msrAction) {
        actions.push(msrAction);
      }
    }

    if (data.farelockdata && !isEmpty(data.farelockdata.fareLockDescription)) {
      actions.push({
        label: data.farelockdata.fareLockDescription,
        onPress: () => {
          openDetails();
        },
      });
    }

    journeyTypeText = staticData.flightBookingText;
    travelerSummary =
      !failedBooking && flightSegment.passengers ? getTravelerSummary(flightSegment) : '';
    if (data.journeyType === 'O') {
      journeyTypeText = staticData.oneWayFlightText;
      dateTimeWithPaxInfo = travelerSummary
        ? `${departDate} • ${travelerSummary}`
        : `${departDate}`;
    } else if (data.journeyType === 'R') {
      journeyTypeText = staticData.roundTripFlightText;
      dateTimeWithPaxInfo = travelerSummary
        ? `${departDate} - ${arrivalDate} • ${travelerSummary}`
        : `${departDate}`;
    } else if (data.journeyType === 'M') {
      journeyTypeText = staticData.multiCityFlightText;
      dateTimeWithPaxInfo = travelerSummary
        ? `${departDate} - ${arrivalDate} • ${travelerSummary}`
        : `${departDate}`;
    }
  }

  let iconUrl = '';
  let { paymentInfo } = data || {};
  let pendingPaymentChargeDate = '';
  let tagStartText = '';
  let tagEndText = '';
  let paymentDueText = '';
  switch (data.status) {
    case 'UPCOMING':
      iconUrl = upcomingBookingIcon;
      break;
    case 'PENDING':
      if(!isEmpty(paymentInfo)) {
        pendingPaymentChargeDate = paymentInfo.paymentChargeDate;
        tagStartText = paymentInfo.tagStartText;
        tagEndText = paymentInfo.tagEndText;
        paymentDueText = paymentInfo.paymentDueText;
        iconUrl = upcomingBookingIcon;
      }
      break;
    case 'COMPLETED':
      iconUrl = completedBookingIcon;
      break;
    case 'CANCELLED':
      iconUrl = cancelledBookingIcon;
      dateTimeWithPaxInfo = getDateTimeWithPaxInfo(
        data,
        staticData.cancelledText,
        dateTimeWithPaxInfo,
        travelerSummary,
      );
      break;
    case 'FAILED':
      iconUrl = failedBookingIcon;
      dateTimeWithPaxInfo =
        getDateTimeWithPaxInfo(data) || unsuccessfulBookingTextHandler(dateTimeWithPaxInfo);
      journeyTypeText = staticData.bookingSummaryText.additionalText.flightBookingFailedText;
      break;
    default:
      iconUrl = upcomingBookingIcon;
      break;
  }
  const needHelpData = getNeedHelpData(msrTicket, data.bookingID, data.needHelpData, data.showQuickResolve, talkToUsDetails);

  return {
    status: data.status,
    journeyType: data.journeyType,
    iconUrl,
    from,
    to,
    dateTimeWithPaxInfo,
    journeyTypeText,
    persuasions: [],
    actions,
    intentDetails,
    openDetails,
    bookingID: data.bookingID,
    fareLockBooking: !!data.farelockdata,
    bkgSchdMsg: data.bookingSchedChangeMessage,
    bkgSchdMsgColor: data.bookingSchedChangeMsgColour
      ? data.bookingSchedChangeMsgColour.toLowerCase()
      : undefined,
    failedBooking,
    pendingPaymentChargeDate,
    tagStartText,
    tagEndText,
    paymentDueText,
    needHelpData,
    paymentInfo: data.paymentInfo,
    holdBookingState: data.holdBookingState,
    isHoldBooking: data.holdBooking,
  };
}

export default {};
