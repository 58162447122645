import { Dimensions, Platform } from 'react-native';
import {fonts, isIPhoneX, statusBarHeightForIphone} from '@mmt/legacy-commons/Styles/globalStyles';

const windowHeight = Dimensions.get('screen').height;
const windowWidth = Dimensions.get('screen').width;

const styles = {
    overlayWrapper:{
        position: 'absolute',
        top: 0,
        left: 0,
        elevation: 10,
        zIndex: 100,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        width: windowWidth,
        ...Platform.select({
          ios: {
            paddingBottom: isIPhoneX ? 60 : 20, height: windowHeight,
          },
          android: {
           paddingBottom: 26, height: windowHeight-30,
          },
          web: {
            paddingBottom: 26, height: windowHeight-30,
          }
        })
    },
    overlay:{
        position: 'absolute',
        ...Platform.select({
          ios: {
            top: -statusBarHeightForIphone,
          },
          android: {
           top: 0
          },
          web: {
            top: 0
          }
        }),
        left: 0,
        backgroundColor: 'rgba(0,0,0,0.5)',
        height: windowHeight,
        width: '100%',
        zIndex: 1
    },
    overlayContent:{
        position: 'relative',
        zIndex: 1,
        width:'100%'
    },
  overlayContentDtls:{
    marginHorizontal:40,
    borderRadius:4,
    backgroundColor:'#fff',
    padding:24,
    justifyContent:'center',
    alignItems:'center',
    elevation:4,
    margin:3,
  },
fltIconWrapper:{
  width:64,
  height:64,
  alignItems:'center',
  justifyContent:'center',
  borderRadius:50,
  marginBottom:20,
},
fltIcon:{
  width:46,
  height:28,
},
fltcheckInTxt:{
  fontFamily:fonts.black,
  color:'#000',
  fontSize:18,
},
};

export default styles;
