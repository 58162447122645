import { Dimensions, StyleSheet } from 'react-native';
import { Fonts, Theme } from '../../cab/types';

export const getStyles = (theme: Theme, fonts: Fonts) => {
	const { color } = theme;

	return StyleSheet.create({
		carousalCustomStyle: {
			width: '100%',
		},
		container: {
			width: Dimensions.get('window').width - 40,
			height: 440,
			borderRadius: 6,
			overflow: 'hidden',
		},
		imageStyle: {
			width: '100%',
			height: '100%',
			borderRadius: 20,
		},
		activeDot: {
			backgroundColor: color.lightBlue17,
			borderRadius: 5,
		},
		cardWpr: {
			marginBottom: 30,
		},
		inActiveDot: {
			backgroundColor: color.lightGrey8,
			borderRadius: 5,
		},
	});
};
