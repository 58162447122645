const ModulesConstants = {
  AFFILIATE: "aff",
  MMT: "MMT",
  ANCILLARY_STORAGE_KEY: "ANCILLARY_STORAGE_KEY",
  BOOKING_ID: "bookingId",
};

const CookiesConstants = {
  LANGUAGE: "lang",
  WC_REQ: "wc_req",
  AUTH: "mmt-auth",
  DEVICE_ID: "dvid",
};

const REQUEST_METHOD = {
  GET : "GET",
  POST : "POST"
}

export { ModulesConstants, CookiesConstants, REQUEST_METHOD };
