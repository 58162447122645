import React from 'react';
import { View, Text } from 'react-native';
import { DriverProfileLanguageData } from '@mmt/post-sales/src/cab/types';
import { getFont } from '@mmt/post-sales/src/PostSalesStyles';
import { useTheme } from '@mmt/post-sales/src/theme/theme.context';
import createStyles from '../styles';

const LanguageSpeak = ({ data }: { data: DriverProfileLanguageData }) => {
  const { heading, list } = data || {};
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);

  return (
    <View style={styles.overlaySection}>
      {!!heading && <Text style={styles.languageSectionHeading}>{heading}</Text>}
      <View style={styles.languageSectionList}>
        {list?.map((language, index) => {
          return (
            <View style={styles.languageWrapper} key={index}>
              <Text style={styles.languageSectionText}>{language}</Text>
            </View>
          );
        })}
      </View>
    </View>
  );
};

export default LanguageSpeak;
