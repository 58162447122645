import React from 'react';
import PropTypes from 'prop-types';
import {Image, Text, View} from 'react-native';
import _ from 'lodash';
import styles from '../Visa/CSS/VisaMamiCss';

import handleClickEvent, {getButtonStyle, getActionButton} from './commonUtil';


const cantFindIcon = require('@mmt/legacy-assets/src/cantFind.webp');

class NeedHelpCard extends React.Component {
  render() {
    return (
      <View style={[styles.MamiCard, styles.mar10Bt]}>
        <View style={styles.CardContent}>
          <Image style={styles.CardIcon} source={cantFindIcon} />
          <View style={styles.ContentPortion}>
            <Text style={[
                styles.blackFont,
                styles.BlackText,
                styles.mar5Bt]}
            >NEED SUPPORT FROM MAKEMYTRIP?
            </Text>
          </View>
        </View>
        <View style={!_.isEmpty(this.props.card.actionList) ? getButtonStyle(this.props.card.actionList) : styles.hideView}>
          {getActionButton(this.props.card, this.props.response, this.handleClick)}
        </View>
      </View>
    );
  }

    handleClick = (action) => {
      handleClickEvent(this.props.card, action, this.props.response, this.props.pageName, this.props.phoneNumber);
    };
}


NeedHelpCard.propTypes = {
  response: PropTypes.object.isRequired,
  card: PropTypes.object.isRequired,
  pageName: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired
};

export default NeedHelpCard;
