import { StyleSheet } from 'react-native';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
import { fonts, colors } from '@mmt/legacy-commons/Styles/globalStyles';

const styles = StyleSheet.create({
  fullPage: { flex: 1, paddingBottom: 70 },
  topSection: {
    backgroundColor: colors.softCyan,
    flex: 1,
    paddingTop: 65,
    paddingBottom: 78,
    alignItems: 'center',
  },
  mainconStyle: { width: 64, height: 64, marginBottom: 20 },
  detailSection: { marginTop: -78 },
  detailsTop: {
    backgroundColor: colors.lightGrey6,
    padding: 16,
    borderRadius: 4,
    borderColor: colors.grayBg,
    borderWidth: 1,
  },
  buttonBottomWrapper: {
    position: 'absolute',
    bottom: 16,
    paddingHorizontal: 8,
    width: '100%',
    alignItems: 'center',
  },
  titleHeading: {
    fontSize: 20,
    fontFamily: fonts.black,
    color: colors.darkCyan,
    marginBottom: 35,
  },
  detailCard: {
    borderRadius: 8,
    marginHorizontal: 12,
    backgroundColor: colors.white,
    ...getPlatformElevation(2),
    padding: 6,
  },
});

export default styles;
