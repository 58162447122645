import React from 'react';
import {View,ActivityIndicator,StyleSheet} from 'react-native';

const  Loader = () => {
        return (
            <View style={[styles.container]}>
        <ActivityIndicator size="small" color="#008cff" />
            </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'flex-end',
        alignItems:'flex-end',
        marginBottom : 20
    }
})

export default Loader;
