import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';
import { Platform } from 'react-native';

const styles = {
  whiteWrapper: {
    backgroundColor: '#ffffff',
    marginBottom: 10,
    ...getPlatformElevation(2),
  },
  cancInfoHeading: {
    ...getPlatformElevation(2),
    padding: 16,
    backgroundColor: '#fff',
    marginBottom: 2,
  },
  arrowBlkStyle: {
    width: 9,
    height: 8,
    marginHorizontal: 6,
  },
  blueArrowStyle: {
    width: 14,
    height: 9,
  },
  arrowWrapper: {
    width: 50,
    height: Platform.OS == 'web' ? 20 : 50,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    right: 0,
  },
  arrowActiveStyle: {
    transform: [
      {
        rotate: '180deg',
      },
    ],
  },
  tripInfoWrapper: {
    padding: 10,
  },
  hideshadow: {
    elevation: 0,
    backgroundColor: 'transparent',
  },
  p: {
    color: '#4a4a4a',
    fontSize: 14,
  },
  errorFltInfo: {
    color: '#e53442',
    fontFamily: 'Lato-Italic',
    fontStyle: 'italic',
    marginTop: 5,
  },
  boxDisabled: {
    color: '#747474',
  },
};

export default styles;
