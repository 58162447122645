import {
    ActionsContent,
    DescriptionSection,
    MainContent,
    SummaryContainer,
    DescriptionCardInfoSection,
    JourneyStartsInfoText,
    CardInfoSection,
  } from '../cardElements';
  import { getInsuranceSummaryData } from './insuranceCardAdapter';
  import { PAGE_BOOKINGS_STATUS } from '../../../summaryConstants';
  import React from 'react';
  import { InsuranceDataProps } from '../cardInterfaces';
  interface InsuranceCardProps {
    response: InsuranceDataProps;
  }
  function InsuranceCard({ response }: InsuranceCardProps) {
    const cardData = getInsuranceSummaryData(response);
  
    const {
      iconUrl,
      heading,
      passengerDetails,
      insuranceProvider,
      startsOnText,
      startDate,
      endsText,
      endDate,
      actions,
      openDetails,
    } = cardData || {};
    return (
      <SummaryContainer bookingId={response.bookingID}>
        <MainContent onPress={openDetails}>
          <CardInfoSection
            //@ts-ignore
            iconUrl={iconUrl}
            name={heading}
            passengerDetails={passengerDetails}
          />
          <DescriptionCardInfoSection descriptionText={insuranceProvider} />
          <DescriptionSection>
            <JourneyStartsInfoText
              type={PAGE_BOOKINGS_STATUS.PLANNING}
              startsOnText={startsOnText}
              startDate={startDate}
              endsText={endsText}
              endDate={endDate}
            />
          </DescriptionSection>
        </MainContent>
        <ActionsContent actions={actions} />
      </SummaryContainer>
    );
  }
  export default InsuranceCard;
  