import React, { useEffect, useState } from 'react';
import { View, Text, TouchableOpacity, Image } from 'react-native';
import createStyles from './style';
import { CHECKBOX_ACTIVE_BLUE_ICON } from '../../../../../../../flights/FlightImageConstants';
import { useTheme } from '../../../../../../../theme/theme.context';
import isEmpty from 'lodash/isEmpty';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const VisaSelectTravellerRow = ({ item, onSelectPassenger, travellerIndex, selected }) => {
  const [checkboxState, setChecknboxState] = useState(selected);

  useEffect(()=>{
    setChecknboxState(selected);
  }, [selected]);

  const fonts = getFont();
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme);

  const handleSelectTravellar = () => {
    setChecknboxState(!checkboxState);
    onSelectPassenger(!checkboxState, travellerIndex);
  };
  const isActiveOuter = checkboxState === true ? 'checkboxSelected' : '';
  return (
    <TouchableOpacity
      style={styles.visaSelectCard}
      onPress={handleSelectTravellar}
      activeOpacity={0.8}
    >
      <View style={[styles.checkbox, styles[isActiveOuter]]}>
        {checkboxState && <Image style={styles.checkedIcon} source={CHECKBOX_ACTIVE_BLUE_ICON} />}
      </View>
      {!isEmpty(item.name) && <View style={styles.travllerNameWrapper}>
        <Text style={[fonts.blackFontFamily, {fontSize: fonts.fontSize.font14, color: theme.color.black}]}>
          {item.name}
        </Text>
      </View>}
    </TouchableOpacity>
  );
};

export default VisaSelectTravellerRow;
