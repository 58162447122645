import React from 'react';
import { View, Text, Image, TouchableOpacity, Platform, StyleSheet, DeviceEventEmitter } from 'react-native';
import HTMLView from 'react-native-htmlview';
import isEmpty  from 'lodash/isEmpty';
import LinearGradient from 'react-native-linear-gradient';
import Actions from '../../../../../navigation/postSalesNavigation';
import FlightBookingModule from '@mmt/legacy-commons/Native/FlightBookingModule';
import { showShortToast } from '@mmt/legacy-commons/Common/Components/Toast';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import * as flightBookingUtil from '../../../../FlightBookingUtil';
import FlightBookingTrackingHelper from '../../helper/FlightBookingTrackingHelper';
import {
  AbConfigKeyMappings,
  getPokusConfig,
} from '@mmt/legacy-commons/Native/AbConfig/AbConfigModule';
import { PokusLobs } from '@mmt/legacy-commons/Native/AbConfig/AbConfigKeyMappings';
import {
  EventEmitterConst,
  TopCardType,
  TOP_CARD_BOOKING_STATUS,
  FLIGHT_THROTTLE_OBJECT,
} from '../../FlightBookingDetailsConstant';
import { BAGGAGE, MEALS, SEATS } from '../../../AncillaryModule/constants';
import AddSeatMealStripCard from '../addSeatMealStripCard/index';
import {
  SUCCESS_ICON,
  ON_TRIP_LOGO,
  CANCELLED_LOGO,
  FAILED_LOGO,
  COVID_IMAPACTED_ICON,
  MMT_PROMISE_LOGO,
  FULL_AWAITED_ICON,
  PARTAILLY_AWAITED_ICON,
  BLUE_ALARM_ICON,
  FLT_AWAITED_ICON,
  CIRCULAR_GREEN,
  FAILED_CROSS_ICON,
  SPCLIAM_RAISED_ICON,
  FLT_ON_HOLD_ICON,
  GREY_CANCELLED_ICON,
  RELEASE_HOLD,
} from '../../../../FlightImageConstants';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import FlightBookingDetailsConstant from '../../FlightBookingDetailsConstant';
import { getStaticData } from '../../../../../staticData/staticData';
import { getFont } from '../../../../../PostSalesStyles';
import MMTValueCard from './MMTValueCard';
import { isEmptyArray } from '../../../../../Common/commonUtil';
import BaseButton, { BUTTON_VARIANT } from '../../../../../Common/BaseButton';
import { getRootTag } from '@mmt/legacy-commons/AppState/RootTagHolder';
import SearchBar from '../SearchBar';
class TopStatusCard extends React.Component {
  static navigationOptions = {
    header: null,
  };

  constructor() {
    super();
    this.triggerName = null;
  }

  getTopStatusCardInfo = (bookingstate, promiseRes, topStatusCard, cardInfo) => {
    const {
      topCardText: {
        additionalText: {
          holdBookingSuccessText,
          bookingConfirmedText,
          onTripText,
          completedBookingText,
          refundedBookingText,
          cancelledBookingText,
          failedBookingText,
          temporaryBookingText,
        },
      },
    } = getStaticData();
    const { state, subState } = bookingstate;
    const topStatusCardProps = {};
    if (topStatusCard) {
      const dataFromAPI = this.getTopCardInfoFromAPI(topStatusCard);
      if (dataFromAPI) {
        return dataFromAPI;
      }
    }
    switch (state.toUpperCase()) {
      case 'UPCOMING':
        if (subState && subState.toUpperCase() === 'PENDING') {
          topStatusCardProps.icon = FULL_AWAITED_ICON;
          topStatusCardProps.style = styles.fullAwaitedIcon;
          topStatusCardProps.headerText = holdBookingSuccessText;
          topStatusCardProps.defaultTxtcolor = [styles.blackcolor];
        } else {
          topStatusCardProps.icon = SUCCESS_ICON;
          topStatusCardProps.style = styles.successIcon;
          topStatusCardProps.headerText = bookingConfirmedText;
          topStatusCardProps.defaultTxtcolor = [styles.lightcolor];
        }
        break;
      case 'ONTRIP':
        topStatusCardProps.icon = ON_TRIP_LOGO;
        topStatusCardProps.style = styles.tripIcon;
        topStatusCardProps.headerText = onTripText;
        topStatusCardProps.defaultTxtcolor = [styles.lightcolor];
        break;
      case 'COMPLETED':
        topStatusCardProps.icon = COVID_IMAPACTED_ICON;
        topStatusCardProps.style = styles.covidImpactedIcon;
        topStatusCardProps.headerText = completedBookingText;
        topStatusCardProps.defaultTxtcolor = [styles.darkcolor];
        break;
      case 'CANCELLED':
      case 'REFUNDED':
        if (promiseRes && promiseRes.length > 0) {
          topStatusCardProps.icon = MMT_PROMISE_LOGO;
          topStatusCardProps.style = styles.mmtPromiseIcon;
          topStatusCardProps.headerText = refundedBookingText;
          topStatusCardProps.defaultTxtcolor = [styles.darkcolor];
        } else {
          topStatusCardProps.icon = CANCELLED_LOGO;
          topStatusCardProps.style = styles.successIcon;
          topStatusCardProps.headerText = cancelledBookingText;
          topStatusCardProps.defaultTxtcolor = [styles.darkcolor];
        }
        break;
      case 'FAILED':
      case 'LOSTFAILED':
        topStatusCardProps.icon = FAILED_LOGO;
        topStatusCardProps.style = styles.failedIcon;
        topStatusCardProps.headerText = failedBookingText;
        topStatusCardProps.defaultTxtcolor = [styles.darkcolor];
        break;
      default:
        break;
    }
    if (cardInfo && cardInfo.cardHeaderText) {
      topStatusCardProps.headerText = cardInfo.cardHeaderText;
    }
    return topStatusCardProps;
  };

  getTopCardInfoFromAPI = (topStatusCard) => {
    const topStatusCardProps = {};
    topStatusCardProps.headerText = topStatusCard.header;
    if (!isEmpty(topStatusCard.descriptionsList)) {
      const descList = [];
      topStatusCard.descriptionsList.forEach((topCardDesc) => {
        const desc = {};
        desc.text = topCardDesc.text;
        const descStatus = topCardDesc.status.toUpperCase();
        if (descStatus === 'AWAITED') {
          desc.icon = FLT_AWAITED_ICON;
          desc.iconStyle = styles.alarmOnIcon;
        } else if (descStatus === 'BLANK') {
          desc.icon = CIRCULAR_GREEN;
          desc.iconStyle = styles.confirmedDescIcon;
        } else if (descStatus === 'FAILED' || descStatus === 'LOSTFAILED') {
          desc.icon = FAILED_CROSS_ICON;
          desc.iconStyle = styles.confirmedDescIcon;
        }
        descList.push(desc);
      });
      topStatusCardProps.descList = descList;
    }
    switch (topStatusCard.topCardType) {
      case 1:
        topStatusCardProps.icon = BLUE_ALARM_ICON;
        topStatusCardProps.style = styles.holdIcon;
        topStatusCardProps.defaultTxtcolor = [styles.bluecolor];
        break;
      case 2:
        topStatusCardProps.icon = FULL_AWAITED_ICON;
        topStatusCardProps.style = styles.fullAwaitedIcon;
        topStatusCardProps.defaultTxtcolor = [styles.darkcolor];
        break;
      case 3:
      case 5:
        topStatusCardProps.icon = PARTAILLY_AWAITED_ICON;
        topStatusCardProps.style = styles.partiallyAwaitedIcon;
        topStatusCardProps.defaultTxtcolor = [styles.darkcolor];
        break;
      case 4:
        topStatusCardProps.icon = SUCCESS_ICON;
        topStatusCardProps.style = styles.successIcon;
        topStatusCardProps.defaultTxtcolor = [styles.lightcolor];
        break;
      case 7:
        const { isKsaBooking } = this.props.response;
        topStatusCardProps.icon = isKsaBooking
          ? require('@mmt/legacy-assets/src/postSales/ic_ksa_currency.webp')
          : SPCLIAM_RAISED_ICON;
        topStatusCardProps.style = isKsaBooking ? styles.currencyIcon : styles.specialClaimIcon;
        topStatusCardProps.defaultTxtcolor = [styles.darkcolor];
        break;
      case TopCardType.FARE_LOCK_HOLD:
      case TopCardType.TEMPORARY_BOOKING:
        topStatusCardProps.icon = FLT_ON_HOLD_ICON;
        topStatusCardProps.style = styles.successIcon;
        topStatusCardProps.defaultTxtcolor = [styles.darkcolor];
        break;
      case TopCardType.FARE_LOCK_EXPIRED:
      case TopCardType.TEMPORARY_BOOKING_EXPIRED:
        topStatusCardProps.icon = GREY_CANCELLED_ICON;
        topStatusCardProps.style = styles.successIcon;
        topStatusCardProps.defaultTxtcolor = [styles.darkcolor];
        break;
      case TopCardType.FARE_LOCK_FAILED:
      case TopCardType.FARE_LOCK_CANCELLED_BY_AIRLINE:
      case TopCardType.TEMPORARY_BOOKING_FAILED:
      case TopCardType.SEAT_NOT_AVAILABLE:
        topStatusCardProps.icon = FAILED_LOGO;
        topStatusCardProps.style = styles.failedIcon;
        topStatusCardProps.defaultTxtcolor = [styles.darkcolor];
        break;
      case TopCardType.TEMPORARY_BOOKING_CANCELLED:
        topStatusCardProps.icon = CANCELLED_LOGO;
        topStatusCardProps.style = styles.successIcon;
        topStatusCardProps.defaultTxtcolor = [styles.darkcolor];
        break;
      default:
        return undefined;
    }
    return topStatusCardProps;
  };

  onButtonClicked = (action, ancillary) => {
    if (Platform.OS === 'ios') {
      this.props.response.reactTag = getRootTag();
    }
    const {
      topCardText: {
        additionalText: {
          downloadInvoiceText,
          addSeatText,
          addMealText,
          addBaggageText,
          addAncillaryText,
          bookingConfirmationText,
        },
      },
    } = getStaticData();
    switch (action) {
      case FlightBookingDetailsConstant.ACTION_DOWNLOAD_ETICKET:
        this.props.onDownloadEticketClicked();
        break;
      case FlightBookingDetailsConstant.ACTION_ADD_TO_PASSBOOK:
        if (Platform.OS === 'ios') {
          FlightBookingModule.downloadTicket(
            JSON.stringify(this.props.response),
            'Flight_CustomerETicket',
          );
        }
        FlightBookingTrackingHelper.trackClickEvent(
          'Details',
          'Flight_E-ticket',
          this.props.response,
        );
        break;
      case addAncillaryText:
        if (ancillary.seatApplicable) {
          if (Platform.OS === 'ios') {
            FlightBookingModule.openAncillaryActivity(JSON.stringify(this.props.response), 'Seats');
          } else {
            FlightBookingModule.openAncillaryActivity(this.props.bookingId, 'Seats');
          }
          FlightBookingTrackingHelper.trackClickEvent(
            'Details',
            'Flight_Card_seat',
            this.props.response,
          );
        } else if (ancillary.mealApplicale) {
          if (Platform.OS === 'ios') {
            FlightBookingModule.openAncillaryActivity(JSON.stringify(this.props.response), 'Meals');
          } else {
            FlightBookingModule.openAncillaryActivity(this.props.bookingId, 'Meals');
          }
          FlightBookingTrackingHelper.trackClickEvent(
            'Details',
            'Flight_Card_meal',
            this.props.response,
          );
        } else if (ancillary.baggageApplicable) {
          if (Platform.OS === 'ios') {
            FlightBookingModule.openAncillaryActivity(
              JSON.stringify(this.props.response),
              'Baggage',
            );
          } else {
            FlightBookingModule.openAncillaryActivity(this.props.bookingId, 'Baggage');
          }
          FlightBookingTrackingHelper.trackClickEvent(
            'Details',
            'Flight_Card_baggage',
            this.props.response,
          );
        }
        break;
      case downloadInvoiceText:
        if (Platform.OS === 'web') {
          FlightBookingModule.downloadTicket('Flight_Customer_Invoice', this.props.bookingId);
        } else {
          this.props.onPaymentCardBtnClick(FlightBookingDetailsConstant.ACTION_DOWNLOAD_INVOICE);
        }
        FlightBookingTrackingHelper.trackClickEvent(
          'Details',
          'Flight_invoice',
          this.props.response,
        );
        break;
      case addSeatText:
        const isNewAncillaryFlow = getPokusConfig(
          PokusLobs.POST_SALES,
          AbConfigKeyMappings.newAncillaryModule,
          false,
        );
        Actions.openAncillary({
          pageKey: isNewAncillaryFlow ? 'selectAddons': 'selectSeats',
          addonType: SEATS,
          bookingId: this.props.bookingId,
          activeSegment: this.props.response.newFlightDetails.segmentGroupDetailList[0]
            .segmentDetails[0].segmentLineNo,
          holidayBooking: this.props.holidayBooking,
          holidayData: this.props.holidayData,
        });
        FlightBookingTrackingHelper.trackClickEvent(
          'Details',
          'Flight_Card_seat',
          this.props.response,
        );
        break;
      case addMealText:
        Actions.openAncillary({
          pageKey:'selectAddons',
          bookingId: this.props.bookingId,
          addonType: MEALS,
          holidayBooking: this.props.holidayBooking,
          holidayData: this.props.holidayData,
        });
        FlightBookingTrackingHelper.trackClickEvent(
          'Details',
          'Flight_Card_meal',
          this.props.response,
        );
        break;
      case addBaggageText:
        Actions.openAncillary({
          pageKey:'selectAddons',
          bookingId: this.props.bookingId,
          addonType: BAGGAGE,
          holidayBooking: this.props.holidayBooking,
          holidayData: this.props.holidayData,
        });
        FlightBookingTrackingHelper.trackClickEvent(
          'Details',
          'Flight_Card_baggage',
          this.props.response,
        );
        break;
      case bookingConfirmationText:
        if (Platform.OS === 'ios') {
          FlightBookingModule.downloadTicket(
            JSON.stringify(this.props.response),
            'Flight_Eticket_MyPartner',
          );
        } else if (Platform.OS === 'web') {
          FlightBookingModule.downloadTicket('Flight_Eticket_MyPartner', this.props.bookingId);
        } else {
          FlightBookingModule.downloadTicket('Flight_Eticket_MyPartner');
        }
        FlightBookingTrackingHelper.trackClickEvent(
          'Details',
          'Flight_CustomerBookingConfirmation',
          this.props.response,
        );
        break;
      default:
        break;
    }
  };

  checkAncillaryApplicable = (response) => {
    const ancilary = {};
    ancilary.seatApplicable = false;
    ancilary.mealApplicale = false;
    ancilary.baggageApplicable = false;

    for (let i = 0; i < response.newFlightDetails.segmentGroupDetailList.length; i++) {
      for (
        let j = 0;
        j < response.newFlightDetails.segmentGroupDetailList[i].segmentDetails.length;
        j++
      ) {
        const segmentLineNo =
          response.newFlightDetails.segmentGroupDetailList[i].segmentDetails[j].segmentLineNo;
        if (flightBookingUtil.isSeatApplicable(this.props.segmentAncillaryDetails, segmentLineNo)) {
          ancilary.seatApplicable = true;
        }
        if (
          flightBookingUtil.isBaggageApplicable(this.props.segmentAncillaryDetails, segmentLineNo)
        ) {
          ancilary.baggageApplicable = true;
        }
        if (flightBookingUtil.isMealApplicable(this.props.segmentAncillaryDetails, segmentLineNo)) {
          ancilary.mealApplicale = true;
        }
      }
    }

    return ancilary;
  };

  isFareLockCardType = (type) => {
    switch (type) {
      case TopCardType.FARE_LOCK_HOLD:
      case TopCardType.FARE_LOCK_EXPIRED:
      case TopCardType.FARE_LOCK_FAILED:
      case TopCardType.FARE_LOCK_CANCELLED_BY_AIRLINE:
      case TopCardType.SEAT_NOT_AVAILABLE:
        return true;
      default:
        return false;
    }
  };

  render() {
    const { fsStyle, ...fonts } = getFont(true);
    const htmlStyle = StyleSheet.create({
      span: {},
      b: {
        fontSize: 14,
        fontFamily: fonts.bold,
      },
    });
    const {
      bookingIDText,
      errorText: { technicalGlitchError, tryAgainOrReachOutError },
    } = getStaticData();
    const state = this.props.bookingState.state.toUpperCase();
    const subState = this.props.bookingState.subState.toUpperCase();
    const isAncillaryApplicable = this.checkAncillaryApplicable(this.props.response);
    const buttonText = flightBookingUtil.getButtonText(
      state.toUpperCase(),
      isAncillaryApplicable,
      this.props.response,
    );
    const { styleColours, showAddSeatMealStrip, odcAncillaryPopUpMessage } = this.props;
    const topStatusCardInfo = this.getTopStatusCardInfo(
      this.props.bookingState,
      this.props.response.myPromiseResponse,
      this.props.topStatusCard,
      this.props.card,
    );
    const { mmtValue, contactUs } = this.props.topStatusCard || {};
    const searchMoreFlight = this.props.response.searchMoreFlightsCTA;
    const { toggleAutoSearchPopup, searchBarDetailResponse } = this.props;
    return (
      <LinearGradient
        start={{ x: 1.0, y: 0.0 }}
        end={{ x: 0.0, y: 1.0 }}
        colors={styleColours}
        style={[styles.topStatusCard]}
      >
        <View style={{elevation:4, zIndex:50 }}>
          {this.props.getStickyHeader(false)}
        </View>
        <View style={[styles.topCardImageWrapper,{backgroundColor:styleColours[0]}]}>
          <Image
            style={[AtomicCss.marginBottom10, topStatusCardInfo.style]}
            source={topStatusCardInfo.icon}
          />
        </View>
        <View>
          <Text
            style={[
              styles.StatusHeadingTxt,
              topStatusCardInfo.defaultTxtcolor,
              fonts.blackFontFamily,
              fsStyle.font16,
            ]}
          >
            {topStatusCardInfo.headerText}
          </Text>
          {!isEmpty(topStatusCardInfo.descList) &&
            topStatusCardInfo.descList.map((desc, index) => (
              <View
                style={[
                  styles.BookingWrapper,
                  AtomicCss.justifyCenter,
                  index === topStatusCardInfo.descList.length - 1
                    ? AtomicCss.marginBottom12
                    : AtomicCss.marginBottom6,
                ]}
              >
                <Image style={desc.iconStyle} source={desc.icon} />
                {!isEmpty(desc.text) && (
                  <Text style={[fsStyle.font14, AtomicCss.defaultText, fonts.boldFontFamily]}>
                    {desc.text}
                  </Text>
                )}
              </View>
            ))}
          {this.props.topStatusCard && !isEmpty(this.props.topStatusCard.subText) && (
            <View style={[styles.BookingWrapper, AtomicCss.marginBottom6]}>
              <HTMLView
                value={`<span>${this.props.topStatusCard.subText}</span>`}
                style={[styles.cardSubText, fonts.mediumFontFamily, fsStyle.font14]}
                stylesheet={htmlStyle}
              />
            </View>
          )}
          {state !== 'FAILED' && (
            <View style={[styles.BookingWrapper, AtomicCss.marginBottom15]}>
              <Text style={[styles.booingDtlsTxt, fonts.regularFontFamily, fsStyle.font12]}>
                {bookingIDText}
              </Text>
              <Text style={[styles.booingDtlsbold, fonts.boldFontFamily, fsStyle.font12]}>
                {this.props.bookingId}
              </Text>
            </View>
          )}

          {state === 'FAILED' && (
            <View style={[styles.BookingWrapper, AtomicCss.marginBottom15]}>
              <Text
                style={[
                  fsStyle.font14,
                  fonts.regularFontFamily,
                  AtomicCss.defaultText,
                  AtomicCss.textCenter,
                  AtomicCss.marginBottom10,
                ]}
              >
                {technicalGlitchError}
              </Text>
              <Text
                style={[
                  fsStyle.font14,
                  fonts.regularFontFamily,
                  AtomicCss.defaultText,
                  AtomicCss.textCenter,
                ]}
              >
                {tryAgainOrReachOutError}
              </Text>
            </View>
          )}
        </View>
        {state !== 'CANCELLED' &&
          state !== 'REFUNDED' &&
          state !== 'FAILED' &&
          subState !== 'CTRIP_UNCONFIRMED' &&
          !(
            this.props.response.topStatusCard &&
            (this.props.response.topStatusCard.topCardType ===
              TOP_CARD_BOOKING_STATUS.FULLY_AWAITED ||
              this.isFareLockCardType(this.props.response.topStatusCard.topCardType) ||
              flightBookingUtil.isHoldBooking(this.props.response.topStatusCard.topCardType))
          ) && (
            <View style={styles.buttonsColumnWrapper}>
              <View style={styles.buttonWrapper}>
                <BaseButton
                  text={buttonText.text1}
                  variant={BUTTON_VARIANT.WHITE_CAPSULE}
                  clickHandler={() => {
                    this.onButtonClicked(buttonText.action1, isAncillaryApplicable);
                  }}
                  isThrottleRequired={true}
                  throttleInterval={buttonText.throttleInterval1}
                  shadow
                  buttonStyle={AtomicCss.margin5}
                  textStyle={{ maxWidth: 190, ...fsStyle.font14 }}
                />
                {!!buttonText.text4 &&
                  <BaseButton
                    text={buttonText.text4}
                    isThrottleRequired={true}
                    throttleInterval={buttonText.throttleInterval4}
                    variant={BUTTON_VARIANT.WHITE_CAPSULE}
                    clickHandler={() => {
                      this.onButtonClicked(buttonText.action4, isAncillaryApplicable);
                    }}
                    shadow
                    buttonStyle={AtomicCss.margin5}
                    textStyle={{ maxWidth: 190, ...fsStyle.font14}}
                  />
                }

                {!!buttonText.text2 && (
                  <BaseButton
                    text={buttonText.text2}
                    isThrottleRequired={true}
                    throttleInterval={buttonText.throttleInterval2}
                    variant={BUTTON_VARIANT.WHITE_CAPSULE}
                    clickHandler={() => {
                      DeviceEventEmitter.emit(EventEmitterConst.CTA_CLICKED_EVENT);
                      this.onButtonClicked(buttonText.action2, isAncillaryApplicable);
                    }}
                    shadow
                    buttonStyle={AtomicCss.margin5}
                    textStyle={{ maxWidth: 90, ...fsStyle.font14 }}
                  />
                )}
              </View>
              {!!buttonText.text3 && (
                <BaseButton
                  isThrottleRequired={true}
                  throttleInterval={buttonText.throttleInterval3}
                  text={buttonText.text3}
                  variant={BUTTON_VARIANT.WHITE_CAPSULE}
                  clickHandler={() => {
                    this.onButtonClicked(buttonText.action3, isAncillaryApplicable);
                  }}
                  shadow
                />
              )}
            </View>
          )}
        {!isEmpty(searchMoreFlight?.btnData?.link) && (
          <View style={styles.buttonsColumnWrapper}>
            <View style={styles.buttonWrapper}>
              <BaseButton
                {...FLIGHT_THROTTLE_OBJECT}
                text={searchMoreFlight.btnData.title}
                variant={BUTTON_VARIANT.WHITE_CAPSULE}
                clickHandler={() => {
                  FlightBookingTrackingHelper.trackClickEvent(
                    'Details',
                    'hold_myt_cancelorexpire_searchflight',
                  );
                  GenericModule.openDeepLink(searchMoreFlight.btnData.link);
                }}
                shadow
                buttonStyle={AtomicCss.margin5}
                textStyle={{ maxWidth: 190, ...fsStyle.font14}}
              />
            </View>
          </View>
        )}
        {showAddSeatMealStrip && !isEmpty(odcAncillaryPopUpMessage) && (
          <AddSeatMealStripCard
            odcAncillaryPopUpMessage={odcAncillaryPopUpMessage}
            response={this.props.response}
          />
        )}
        {!isEmptyArray(mmtValue) && (
          <MMTValueCard
            mmtValue={mmtValue}
            ctripContact={contactUs}
            toggleServiceGuaranteeOverlay={this.props.toggleServiceGuaranteeOverlay}
          />
        )}
        {(state && state.toUpperCase() === 'UPCOMING') &&
          (subState && subState.toUpperCase() === 'PENDING') &&
          this.props.releaseHold && (
            <View style={[styles.successfulContainer]}>
              <TouchableOpacity onPress={this.props.togglereleaseholdOverlay} style={styles.releaseContainer}>
                <Image source={RELEASE_HOLD} style={styles.releaseHold} />
                {!!this.props.releaseHold.title && (
                  <Text style={[styles.releaseText, fonts.boldFontFamily, fsStyle.font12]}>{this.props.releaseHold.title}</Text>
                )}
              </TouchableOpacity>
            </View>
          )
        }
        {!isEmpty(searchBarDetailResponse?.dataSet) && this.props.response?.showSearchBar && (
            <View  style={styles.searchBarWrapper}>
              <SearchBar
                toggleAutoSearchPopup={toggleAutoSearchPopup}
                placeholderList={searchBarDetailResponse?.placeholder}
              />
            </View>
          )}
      </LinearGradient>
    );
  }
}
const styles = StyleSheet.create({
  topCardImageWrapper: {
    width:'100%',
    alignItems: 'center',
    marginTop: 20,
    zIndex:60,
  },
  topButtonWrap: {
    display:'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginHorizontal: 12,
  },
  downloadIcon: {
    width: 24,
    height: 24,
    tintColor: '#231F20',
  },
  topStatusCard: {
    paddingTop: 10,
    paddingBottom: 10,
    position: 'relative',
    marginTop: -5,
  },
  topBackArrowWrapper: {
    width: 44,
    height: 44,
    alignItems: 'center',
    justifyContent: 'center',
    left: -10,
  },
  backarrow: {
    width: 16,
    height: 16,
    position: 'absolute',
  },
  IOSbackarrow: {
    width: 10,
    height: 16,
    position: 'absolute',
  },
  StatusHeadingTxt: {
    textAlign: 'center',
    lineHeight: 24,
    marginBottom: 10,
    backgroundColor: 'transparent',
  },
  successIcon: {
    width: 64,
    height: 38,
    alignSelf: 'center',
  },
  holdIcon: {
    width: 40,
    height: 40,
    alignSelf: 'center',
  },
  failedIcon: {
    width: 61,
    height: 35,
    alignSelf: 'center',
  },
  tripIcon: {
    width: 49,
    height: 46,
    alignSelf: 'center',
  },
  journeyOverIcon: {
    width: 42,
    height: 46,
    alignSelf: 'center',
  },
  covidImpactedIcon: {
    width: 61,
    height: 35,
    alignSelf: 'center',
  },
  mmtPromiseIcon: {
    width: 144,
    height: 37,
    alignSelf: 'center',
  },
  fullAwaitedIcon: {
    width: 57,
    height: 49,
    alignSelf: 'center',
  },
  partiallyAwaitedIcon: {
    width: 62,
    height: 38,
    alignSelf: 'center',
  },
  specialClaimIcon: {
    width: 60,
    height: 35,
    alignSelf: 'center',
  },
  currencyIcon: {
    width: 40,
    height: 40,
    alignSelf: 'center',
  },
  BookingWrapper: {
    flexDirection: 'row',
    alignSelf: 'center',
  },
  booingDtlsTxt: {
    color: '#747474',
    lineHeight: 18,
    marginRight: 10,
  },
  booingDtlsbold: {
    color: '#747474',
    lineHeight: 18,
  },
  cardSubText: {
    color: '#4a4a4a',
    lineHeight: 20,
    textAlign: 'center',
  },
  buttonWrapper: {
    alignItems: 'center',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'center',
  },
  ShareImgWrapper: {
    width: 24,
    height: 25,
    alignItems: 'center',
    position: 'absolute',
    right: 15,
    top: 15,
    justifyContent: 'center',
  },
  shareIcon: {
    width: 19,
    height: 12,
  },
  lightcolor: {
    color: '#1a7971',
  },
  blackcolor: {
    color: '#000000',
  },
  darkcolor: {
    color: '#4a4a4a',
  },
  bluecolor: {
    color: '#053cb2',
  },
  alarmOnIcon: {
    width: 16,
    height: 16,
    marginRight: 4,
  },
  confirmedDescIcon: {
    width: 14,
    height: 14,
    marginRight: 5,
    marginTop: 2,
  },
  buttonsColumnWrapper: {
    alignItems: 'center',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
  },
  releaseText: {
    lineHeight: 20,
    marginLeft: 4,
    alignItems: 'center',
    color: "#008cff",
  },
  releaseContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
  },
  releaseHold: {
    height: 15,
    width: 15,
  },
  successfulContainer: {
    alignItems: 'center',
    marginTop: 8,
  },
  searchBarWrapper: {
    paddingHorizontal: 12,
    paddingVertical: 10,
    elevation: 2,
  },
});

export default TopStatusCard;
