import React, { useEffect } from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import styles from './MSRComponentsCss';
import { GREEN_THUMBS_UP_UP_ICON, RED_THUMBS_DOWN_ICON } from '../../hotel/HotelImageConstants';
import HTMLView from 'react-native-htmlview';
import { openWebView } from './Utils';
import { getStaticData } from '../../staticData/staticData';
import PostSalesBookingTrackingHelper from '../../PostSalesBookingTrackingHelper';
import { isNonEmpty } from '@mmt/legacy-commons/Common/utils/StringUtils';

export const EscalateMessage = ({ escalationDetails, pageName, ticket }) => {
    
    const handleEscalateClick = () => {
        PostSalesBookingTrackingHelper.trackClickEvent(pageName, 'escalate_click', ticket && ticket.entityKey, ticket && ticket.id);
        openWebView(escalationDetails.escalateUrl);
    }

    useEffect(() => {
       PostSalesBookingTrackingHelper.trackShownEvent(pageName, 'escalate_shown', ticket && ticket.entityKey);
    }, []);
    
    return (
        <TouchableOpacity onPress={(handleEscalateClick)}>    
            <View style={[styles.requestWrapper, AtomicCss.marginTop15]}>
            {isNonEmpty(escalationDetails.escalateText) && <HTMLView 
                value={escalationDetails.escalateText}
                stylesheet={styles} 
            />}
            </View>
        </TouchableOpacity> 
    )
}

export const FeedbackCard = ({ feedbackDetails, pageName, ticket }) => {
    
    const {yesText, noText} = getStaticData(false);
    
    const handleFeedbackClick = (clickedYes) => {
        PostSalesBookingTrackingHelper.trackClickEvent(pageName, clickedYes ? 'csat_escalate_yes_click' : 'csat_escalate_notyet_click', ticket && ticket.entityKey, ticket && ticket.id);
        openWebView(feedbackDetails.feedbackUrl);
    }

    useEffect(() => {
        PostSalesBookingTrackingHelper.trackShownEvent(pageName, 'csat_escalation_shown', ticket && ticket.entityKey);
    }, []);
    
    return (
        <View style={[styles.reciveRefundWrapper]}>
            <View style={styles.refundReceivedCard}>
            {isNonEmpty(feedbackDetails.feedbackText) && <Text style={[AtomicCss.font14, AtomicCss.blackText, AtomicCss.boldFont, AtomicCss.lineHeight18, AtomicCss.marginBottom12]}>
                {feedbackDetails.feedbackText}
            </Text>}
            <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween, AtomicCss.marginRight8]}>
                <TouchableOpacity style={[styles.btnWrapper]} activeOpacity={0.7} onPress={() => handleFeedbackClick(true)}>
                <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
                    <Image style={styles.thumsIconStyle} source={GREEN_THUMBS_UP_UP_ICON} />
                    <Text style={[AtomicCss.font14, AtomicCss.blackText, AtomicCss.boldFont]}>{yesText.toUpperCase()}</Text>
                </View>
                </TouchableOpacity>
                <TouchableOpacity style={[styles.btnWrapper]} onPress={() => handleFeedbackClick(false)}>
                <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
                    <Image style={styles.thumsIconStyle} source={RED_THUMBS_DOWN_ICON} />
                    <Text style={[AtomicCss.font14, AtomicCss.blackText, AtomicCss.boldFont]}>{noText.toUpperCase()}</Text>
                </View>
                </TouchableOpacity>
            </View>
            </View>
        </View>
    )
}