import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  SafeAreaView,
  ScrollView,
  StyleSheet
} from 'react-native';

import SimilarProperties from './components/SimilarProperties';
import { getStaticDetail, hotelCompareAdaptor } from '../hotelApi';

const SimilarPropertiesListView = (props) => {
  const [propertiesData, setPropertiesData] = useState(null);

  const callStaticDetail = (uuids = []) => {
    return getStaticDetail(props, uuids).then((data) => {
      if (data && data.response) {
        if (data.response.uuids && data.response.uuids.length) {
          return callStaticDetail(data.response.uuids);
        } else {
          const hotelCompareData = hotelCompareAdaptor(data.response);
          setPropertiesData(hotelCompareData);
        }
      }
    });
  }

  useEffect(() => {
    callStaticDetail();
  }, []);

  if (!(propertiesData && propertiesData.hotelList && propertiesData.hotelList.length)) {
    return null;
  }

  const cityName = propertiesData.hotelList[0].cityName;

  return (
    <React.Fragment>
      {/* <SafeAreaView style={styles.topSafeArea} /> */}
      <SafeAreaView style={{ flex: 1 }}>
        <ScrollView style={{ flex: 1 }}>
          <SimilarProperties
            heading="Similar Properties"
            description={`Meanwhile, we’ve got some amazing property recommendations for your stay in ${cityName}. Give them a shot!`}
            cta="View All Properties"
            propertiesData={propertiesData}
            currency={props.currency}
          />
        </ScrollView>
      </SafeAreaView>
    </React.Fragment>
  );
}

const styles = StyleSheet.create({
  topSafeArea: {
    backgroundColor: '#ff3e5e',
    height: 160,
  },
});

SimilarPropertiesListView.propTypes = {
  checkIn: PropTypes.string.isRequired,
  checkOut: PropTypes.string.isRequired,
  cityCode: PropTypes.string.isRequired,
  countryCode: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  hotelId: PropTypes.string.isRequired,
  locationId: PropTypes.string,
  locationType: PropTypes.string,
  roomStayCandidates: PropTypes.array.isRequired,
  pageContext: PropTypes.string.isRequired,
  deviceInfo: PropTypes.object,
  idContext: PropTypes.string,
  debug: PropTypes.bool
};

export default SimilarPropertiesListView;
