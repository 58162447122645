import {StyleSheet} from 'react-native'
export default (theme,fonts) => 
StyleSheet.create({
  whiteCard: {
    backgroundColor: theme.color.white,
    elevation: 2,
    shadowColor: theme.color.black,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.3,
    shadowRadius: 1,
  },

  flightTopDtls: {
    borderBottomWidth: 1,
    borderBottomColor: theme.color.grayBg,
    paddingBottom: 10,
    alignItems: 'center',
    height: 50,
    marginTop: 8,
  },
  flightDetailsSection: {
    flexDirection: 'row',
    paddingHorizontal: 16,
    paddingVertical: 10,
    alignItems: 'center',
    elevation: 3,
    marginTop: 10,
  },
  FareSection: {
    paddingHorizontal: 8,
    paddingVertical: 2,
    borderRadius: 10,
    marginLeft: 5,
  },
  fareType: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: theme.color.grayBg,
    paddingVertical: 8,
    marginHorizontal: 16,
    paddingRight: 60,
  },
  BaggageInfo: {
    borderBottomWidth: 1,
    borderColor: theme.color.grayBg,
    paddingVertical: 8,
    marginHorizontal: 16,
  },
  Amenities: { paddingTop: 10, marginHorizontal: 16, paddingBottom: 16 },
  baggageIcon: { width: 16, height: 16, marginRight: 16 },
  VideoIcon: { marginLeft: 10, width: 16, height: 16 },
  wifiIcon: { marginLeft: 8, width: 16, height: 16 },
  flightStatus: {
    paddingLeft: 16,
    height: 37,
    paddingRight: 10,
    alignItems: 'center',
    justifyContent: 'center',
    borderTopRightRadius: 2,
    borderBottomRightRadius: 2,
  },
  fltDtlInfo: {
    padding: 16,
  },
  rightArrowIcon: { width: 20, height: 20 },
  topArrow: { width: 15, height: 9 },
  CardHead: {
    fontSize: fonts.fontSize.font21,
    color: theme.color.defaultTextColor,
    ...fonts.lightFontFamily,
    marginBottom: 10,
  },
  fltDtls: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  durationWrapper: {
    borderBottomWidth: 2,
    borderBottomColor: theme.color.grey8,
    alignSelf: 'flex-start',
    paddingBottom: 4,
    width: 80,
    alignItems: 'center',
  },
  fltIcon: {
    width: 32,
    height: 32,
    marginRight: 10,
  },
});

