import React from 'react';
import { StyleSheet, Text, View, Image, TouchableOpacity, Platform } from 'react-native';
import HtmlView from 'react-native-htmlview';
import { isEmpty } from 'lodash';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { joinStrings } from '@mmt/legacy-commons/Common/utils/StringUtils';
import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';
import { ONE_WAY_JRNY_ICON, SM_USER_ICON, CREDIT_SHELL_ICON } from '../../FlightImageConstants';
import { getFont } from '../../../PostSalesStyles';

const { fsStyle, ...fonts } = getFont(true);

export default class SegmentPaxInfo extends React.Component {
  render() {
    const { heading, subHeading, passengerList } = this.props.segmentApprovedInfo;
    const { creditShellInfo } = this.props;
    return (
      <View style={styles.paxCancelledinnderDtls}>
        {!isEmpty(heading) && (
          <View style={styles.destRow}>
            <Text
              style={[
                styles.fltDtlsTxt,
                AtomicCss.marginBottom5,
                AtomicCss.marginRight2,
                fsStyle.font14,
              ]}
            >
              {heading.fromCity}
            </Text>
            <Image style={styles.arrowIcon} source={ONE_WAY_JRNY_ICON} />
            <Text
              style={[
                styles.fltDtlsTxt,
                AtomicCss.marginBottom5,
                AtomicCss.marginRight2,
                fsStyle.font14,
              ]}
            >
              {heading.toCity}
            </Text>
            <Text
              style={[
                styles.fltDtlsDateTxt,
                AtomicCss.marginBottom5,
                AtomicCss.marginLeft3,
                AtomicCss.marginRight2,
                fsStyle.font12,
              ]}
            >
              {heading.travelDate}
            </Text>
            <Text style={[styles.fltDtlsDateTxt, AtomicCss.marginBottom5, fsStyle.font12]}>
              , {heading.airline}
            </Text>
          </View>
        )}
        {!isEmpty(subHeading) && (
          <Text
            style={[
              styles.fltDtlsTxt,
              AtomicCss.marginBottom10,
              styles.defaultText,
              fonts.boldFontFamily,
              fsStyle.font14,
            ]}
          >
            {subHeading}
          </Text>
        )}
        {!isEmpty(passengerList) && (
          <View style={[AtomicCss.flexRow]}>
            <Image style={styles.UserIcon} source={SM_USER_ICON} />
            <Text style={[styles.paxName, fsStyle.font12]}>{joinStrings(passengerList, ', ')}</Text>
          </View>
        )}
        {creditShellInfo && (
          <View style={[AtomicCss.flexColumn, AtomicCss.marginTop12]}>
            <View style={[AtomicCss.flexRow, AtomicCss.paddingRight16]}>
              <Image style={styles.creditShellIcon} source={CREDIT_SHELL_ICON} />
              <HtmlView value={creditShellInfo.metaInfo} stylesheet={htmlStyle()} />
            </View>
            <TouchableOpacity
              style={[AtomicCss.marginTop5]}
              onPress={() => this.props.showCreditShellPopup(creditShellInfo, true)}
            >
              <Text style={[styles.knowMoreText, fsStyle.font11]}>KNOW MORE</Text>
            </TouchableOpacity>
          </View>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  paxCancelledinnderDtls: {
    paddingTop: 15,
    paddingBottom: 16,
    paddingLeft: 16,
    paddingRight: 25,
    ...getPlatformElevation(2),
    backgroundColor: '#fff',
  },
  arrowIcon: {
    width: 10,
    height: 7,
    marginHorizontal: 5,
  },
  destRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  fltDtlsTxt: {
    fontFamily: fonts.black,
    color: '#000',
    lineHeight: 20,
  },
  fltDtlsDateTxt: {
    fontFamily: fonts.regular,
    color: '#000',
    lineHeight: 20,
  },
  defaultText: {
    color: colors.defaultTextColor,
  },
  UserIcon: {
    width: 16,
    height: 12,
    marginRight: 8,
    marginTop: 2,
  },
  creditShellIcon: {
    width: 24,
    height: 24,
    marginRight: 8,
  },
  paxName: {
    color: '#000000',
    lineHeight: 16,
    fontFamily: fonts.regular,
    marginRight: 16,
  },
  knowMoreText: {
    color: '#008cff',
    fontFamily: fonts.bold,
  },
});

const htmlStyle = () => ({
  p: {
    color: colors.black,
    fontSize: fonts.fontSize.font12,
    fontFamily: fonts.regular,
  },
  transperantBgrnd: {
    ...Platform.select({
      ios: {
        backgroundColor: 'transparent',
      },
    }),
  },
  b: {
    color: colors.black,
    fontSize: fonts.fontSize.font12,
    fontFamily: fonts.bold,
  },
});
