import React from 'react';
import {ScrollView, Text, View} from 'react-native';
import Actions from '../../../../../navigation/postSalesNavigation';
import Breadcrumb from '../../components/Breadcrumb';
import StickyHeader from '../../../../../hotel/details/components/StickyHeader';
import CabOperator from './components/CabOperator';
import ReviewButton from '../../components/ReviewButton';
import {headerIcon} from '../../../../../bus/BusImageConstants';
import { backPressHandler } from '../../../../../utils/PostSaleUtil';
import { getFont } from '../../../../../PostSalesStyles';
import { useTheme } from '../../../../../theme/theme.context';
import createStyles from "./operatorcss";

function Operator({bookingId, specialClaimData, response}) {

  const fonts = getFont()
  const {psTheme : theme} = useTheme();
  const styles = createStyles(theme,fonts);

  const goBack = () => {
    backPressHandler();
  };

  const onContinueClicked = () => {
    Actions.cabspecialclaimoperatorreview({
      response, bookingId
    });
  };

  const getTitle = () => `Claim Refund: ${specialClaimData.scName}`;

  const addSegments = () => {
    return(
      <CabOperator
        response={response}
      />
    )
  }

  const STEPS = [ specialClaimData.reviewDetails, specialClaimData.submitClaim];

  return (
    <View style={styles.pageWrapper}>
       <StickyHeader
        iconSrc={headerIcon}
        title={getTitle()}
        headerColor={['#ffffff', '#ffffff']}
        titleStyle={[styles.headerTitle]}
        onBackPress={goBack}
      />
      <Breadcrumb data={STEPS} activeStep={0} />
      <ScrollView showsVerticalScrollIndicator={false} style={styles.pageScroll}>
        <View style={styles.pageScrollTopSpace} />

        <View style={styles.whiteCard}>
          <Text style={styles.cardTitle}>
            {specialClaimData.explTxt}
          </Text>
          <Text style={styles.cardSubTitle}>
            {specialClaimData.explSubTxt}
          </Text>
          {addSegments()}
        </View>
        <View style={styles.pageScrollBtmSpace} />
      </ScrollView>
      <View style={styles.footerBtnWrapper}>
        <ReviewButton
          buttonText={specialClaimData.reviewDetails}
          clickHandler={onContinueClicked}
          disabled={false}
        />
      </View>
    </View>
  );
}

export default Operator;
