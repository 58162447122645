import React from 'react';
import {View, ScrollView, Linking, Clipboard} from 'react-native';
import * as Fecha from 'fecha';
import isEmpty from 'lodash/isEmpty';
import styles from './slctFlightsCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import HeaderCross from '../../../Common/HeaderCross';
import SectorCards from './SectorCards';
import Button from '../../../Common/Button';
import Toast from '@mmt/legacy-commons/Common/Components/Toast2';
import {MONTHS} from '../../HolidayBookingConstants';
import {PlaneReturnIcon} from '../../HolidayBookingConstants';
import {getStaticData} from "../../../staticData/staticData";
import HolidayBookingTrackingHelper from '../../HolidayBookingTrackingHelper';

class SelectFlights extends React.Component {
  static navigationOptions = {
    header: null
  };

  constructor() {
    super();
    this.state = {
      activeIndex: -1,
      webCheckInUrl: ''
    };
  }

  handleSectorFlight = (index, webCheckInUrl, pnr) => {
    const {
      selectFlightsText:{
        additionalText:{
          pnrSelectedText,
        }
      }
    } = getStaticData();
    HolidayBookingTrackingHelper.trackClickEvent(
      this.props.pageName,
      `Checklist_flight_${index}`
    );
    Toast.show(pnrSelectedText);
    Clipboard.setString(pnr);
    this.setState({
      activeIndex: index,
      webCheckInUrl: webCheckInUrl
    });
  };

  clickHandler = () => {
    HolidayBookingTrackingHelper.trackClickEvent(
      this.props.pageName,
      "Checklist_PNR_proceed"
    );
    if (this.state.webCheckInUrl) {
      Linking.openURL(this.state.webCheckInUrl);
    }
  };

  getDate = (trvlDateTime, format = 'DD MMM') => {
    try {
      const travelDate = trvlDateTime.split('T')[0];
      const mIndex = parseInt(travelDate.split('-')[1], 10);
      const day = parseInt(travelDate.split('-')[2]);
      const year = parseInt(travelDate.split('-')[0]) % 100;
      return `${day} ${MONTHS[mIndex - 1]} '${year}`;
    } catch (e) {
      return Fecha.format(new Date(trvlDateTime), format);
    }
  };

  getError = (segment) => {
    if (!segment.webCheckAllowed) {
      return 'progress';
    }
    if (!segment.webCheckInUrl) {
      return 'progress';
    }
    return null;
  };

  getErrorMessage = (segment) => {
    const {
      flightErrorText:{
        webCheckInIsNotAvailableError,
        webCheckInHasFailedText,
      }
    } = getStaticData();
    if (!segment.webCheckAllowed) {
      return webCheckInIsNotAvailableError;
    }
    if (!segment.webCheckInUrl) {
      return webCheckInHasFailedText ;
    }
    return '';
  };

  isSelectable = (segment) => {
    return !(!segment.webCheckAllowed || !segment.webCheckInUrl);
  };

  getSectorData = (segmentGroupDetailList) => {
    if (isEmpty(segmentGroupDetailList)) {
      return [];
    }
    const sectorDataList = [];

    segmentGroupDetailList.forEach((segmentGroupDetail) => {
      segmentGroupDetail.segmentDetails.forEach((segment) => {
        const sectorData = {
          sectorHeading: false,
          icon: PlaneReturnIcon,
          heading: `${segment.originCity} to ${segment.destinationCity}`,
          status: segment.noOfStops > 0 ? 'Stop,' : 'nonStop',
          city: [segment.originCity, segment.destinationCity],
          moreInfo:  segment.moreInfoText ||`${segment.flightDesignator.flightName} • ${this.getDate(segment.travelDateTime, 'DD MMM')}`,
          error: this.getError(segment),
          errorTxt: this.getErrorMessage(segment),
          pnr: segment.pnrNo,
          showPnr: this.isSelectable(segment),
          selectable: this.isSelectable(segment),
          webCheckInUrl: segment.webCheckInUrl
        };
        sectorDataList.push(sectorData);
      });
    });

    return sectorDataList;
  };

  render() {
    const {
      selectFlightsText:{
        additionalText:{
          copyPnrAndProceedText,
        },
        heading,
      }
    } = getStaticData();
    const {flightDetailsType} = this.props;
    const sectorData = this.getSectorData(flightDetailsType.segmentGroupDetailList);
    return (
      <React.Fragment>
        <View style={[styles.WebCheckinOverlayWrapper, AtomicCss.flex1]}>
          <HeaderCross
            title={heading}
            headerShadow
            headingTxt="font18"
            handleClose={this.props.onBackPopUp}

          />
          <ScrollView contentContainerStyle={styles.scrollViewStyle}>
            <View style={styles.wrapperSector}>
              {sectorData.map((item, index) => (
                <SectorCards
                  item={item}
                  index={index}
                  handleSectorFlight={this.handleSectorFlight}
                  activeState={this.state.activeIndex}
                />
              ))}
            </View>
          </ScrollView>
          <View style={styles.btnWrapper}>
            <Button
              btnTxt={copyPnrAndProceedText}
              btnType="flat"
              btnBg={this.state.activeIndex !== -1 ? 'gradientBtn' : 'disableGrey'}
              clickHandler={this.clickHandler}
              isRtArrow
            />
          </View>
        </View>
      </React.Fragment>
    );
  }
}


export default SelectFlights;
