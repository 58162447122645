import { isNetworkAvailable } from '@mmt/legacy-commons/Common/utils/AppUtils';
import FlightBookingModule from '@mmt/legacy-commons/Native/FlightBookingModule';
import { DcViewState } from '../../../constants';
import DateChangeTrackingHelper from '../../../analytics/DateChangeTrackingHelper';
import { LOBNAMES } from '../../../../../../PostSalesConstant';
import {
  getCommonHeaders,
  FLIGHT_DATE_CHANGE_HOLD,
  FLIGHT_DATE_CHANGE_HOLDV2,
} from '../../../../../../utils/NetworkUtils';
import { getReviewAPIRequest, getHoldAPIRequest } from './requestAdapter';
import { isCorporateBookingId } from '../../../../../../utils/PostSaleUtil';
import FlightEventsLogger,{FLIGHT_LOG_ACTIONS} from '../../../../details/helper/FlightEventsLogger';

export const makeReviewAPI = async (
  url,
  { crKey, rKey, recomId, bookingId, sessionId, origAirline, journeyType },
) => {
  const datechangereview = 'datechangereview';
  try {
    const hasNetwork = await isNetworkAvailable();
    if (!hasNetwork) {
      return { viewState: DcViewState.NO_INTERNET };
    }
    const userInfo = await FlightBookingModule.getRequestHeader('', 'FLIGHT_DATE_CHANGE_REVIEW');
    const request = getReviewAPIRequest(bookingId, crKey, rKey, recomId, sessionId);
    const response = await fetch(url, {
      method: 'POST',
      headers: await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo, {
        psLob: LOBNAMES.FLIGHT,
      }),
      body: JSON.stringify(request),
    });
    if (response.ok) {
      const body = await response.json();
      if (body.errorPageData) {
        DateChangeTrackingHelper.trackErrorEvent(
          datechangereview,
          body.errorPageData.errorCode,
          body.errorPageData.meta ? body.errorPageData.meta.supplier : undefined,
        );
        return {
          openErrorPage: true,
          errorPageData: body.errorPageData,
          bookingId: body.bookingId,
        };
      } else {
        body.origAirline = origAirline;
        body.journeyType = journeyType;
        if (body.dateChangeType === 'SPECIAL_DATE_CHANGE') {
          DateChangeTrackingHelper.trackLoadEvent('datechangereview_request', body);
        } else {
          DateChangeTrackingHelper.trackLoadEvent(datechangereview, body);
        }
        return {
          responseJson: body,
          viewState: DcViewState.SHOW_DETAIL,
        };
      }
    } else {
      DateChangeTrackingHelper.trackErrorEvent(datechangereview, 'NO_RESPONSE', undefined);
      return { error: true, viewState: DcViewState.ERROR };
    }
  } catch (error) {
    DateChangeTrackingHelper.trackErrorEvent(datechangereview, '', undefined);
    console.log(error);
    return { error: true, viewState: DcViewState.ERROR };
  }
};

export const makeHoldAPI = async ({ bookingId, crKey, rKey, recomId, sessionId }) => {
  try {
    const hasNetwork = await isNetworkAvailable();
    if (!hasNetwork) {
      return { viewState: DcViewState.NO_INTERNET };
    }
    const userInfo = await FlightBookingModule.getRequestHeader('', 'FLIGHT_MODIFICATION');

    const request = getHoldAPIRequest(userInfo,bookingId, crKey, rKey, recomId, sessionId);
    const response = await fetch(
      isCorporateBookingId(bookingId)
        ? FLIGHT_DATE_CHANGE_HOLD
        : FLIGHT_DATE_CHANGE_HOLDV2,
      {
        method: 'POST',
        headers: await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo, {
          psLob: LOBNAMES.FLIGHT,
        }),
        body: JSON.stringify(request),
      },
    );
    if (response.ok) {
      const body = await response.json();
      if (body && body.errorPageData) {
        DateChangeTrackingHelper.trackErrorEvent(
          'datechangereview',
          body.errorPageData.errorCode,
          body.errorPageData.meta ? body.errorPageData.meta.supplier : undefined,
        );
        return {
          holdResponse: body,
        };
      } else if (body && body.success) {
        FlightEventsLogger.logAction(FLIGHT_LOG_ACTIONS.ODC_HOLD_API, body);
        return {
          holdResponse: body,
          viewState: DcViewState.HOLD_SUCCESS,
        };
      } else {
        DateChangeTrackingHelper.trackErrorEvent('datechangereview', body.failureReason, undefined);
        return {
          viewState: DcViewState.HOLD_API_FAILURE_RESPONSE,
          errorMsg: body.failureMessage,
          errorNextStep: body.failureNextStep,
        };
      }
    } else {
      DateChangeTrackingHelper.trackErrorEvent('datechangereview', response.status, undefined);
      return ({ viewState: DcViewState.ERROR });
    }
  } catch (error) {
    DateChangeTrackingHelper.trackErrorEvent('datechangereview', error.message, undefined);
    console.log(error.message);
    return { viewState: DcViewState.ERROR };
  }
};
