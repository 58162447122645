import Actions from '../../../../../navigation/postSalesNavigation';
import FlightListingTrackingHelper from '../../analytics/FlightListingTrackingHelper';
import { getBookingType } from '../../data/dataAdapter';

export const onReviewAndRebook = (corporateBooking, bookingId, info = {}) => (
    dispatch,
    getState,
  ) => {
    const selectedFlight = getState().flightListing.selectedFlightItem;
    const origAirline = getState().flightListing.headerInfo.origAirline;
    const journeyType = getState().flightListing.headerInfo.journeyType;
    const sessionId = getState().flightListing.sessionId;
    trackClickEvent('Review_And_Book', getState().flightListing.bookingType);
    const encryptedData = {
      crKey: selectedFlight.crKey,
      rKey: selectedFlight.rKey,
      sessionId: sessionId,
      recomId: selectedFlight.recomId,
      origAirline,
      journeyType,
      bookingId,
      isCorporateBooking: corporateBooking,
      ...info,
    };
    if (corporateBooking) {
      Actions.corpFlightRescheduleReviewPage(encryptedData);
    } else {
      Actions.flightRescheduleReviewPage(encryptedData);   
    }
  };
  
  export function trackPageLoad(responseBody, bookingId) {
    const trackingPageName = getPageName(getBookingType(responseBody.meta));
    FlightListingTrackingHelper.trackLoadEvent(trackingPageName, responseBody.meta, bookingId);
  }
  
  export function getPageName(bookingType) {
    return isIntlFlightBooking(bookingType)
      ? 'Mob:Customer support:Intl flight:datechangelisting'
      : 'Mob:customer support:Dom flight:datechangelisting';
  }
  export function getClickEvent(eventname, bookingType) {
    return isIntlFlightBooking(bookingType)
      ? `MI_Intl_datechangelisting:${eventname}`
      : `MI_dom_datechangelisting:${eventname}`;
  }
  
  export function trackClickEvent(eventname, bookingType) {
    FlightListingTrackingHelper.trackClickEvent(
      getPageName(bookingType),
      getClickEvent(eventname, bookingType),
    );
  }
  
  export function trackClickEventListingPopup(eventname, bookingType, airlineName, isFdcApplicable) {
    FlightListingTrackingHelper.trackClickEventForListingPopup(
      getPageName(bookingType),
      getClickEvent(eventname, bookingType),
      airlineName,
      isFdcApplicable,
    );
  }
  
  export function trackError(message, bookingId, supplier) {
    let pageName = 'Mob:Customer support: Dom datechangelisting:Error';
    if (bookingId && bookingId.startsWith('NN')) {
      pageName = 'Mob:Customer support:Intl datechangelisting:Error';
    }
    FlightListingTrackingHelper.trackErrorEvent(pageName, message, supplier);
  }
  
  export function isIntlFlightBooking(bookingType) {
    return bookingType !== 'DOMESTIC';
  }

  