import React from 'react';
import {View, TouchableOpacity} from 'react-native';
import PropTypes from 'prop-types';
import styles from './OverlayMessageCss';

export default class OverlayMessage extends React.Component {
  render() {
    return (
      <View style={styles.overlayWrapper}>
        <TouchableOpacity onPress={() => this.props.CloseOverlay ? this.props.CloseOverlay() : ()=>{}} activeOpacity={1} style={styles.overlay} />
        <View style={styles.overlayContent}>
          {this.props.content}
        </View>
      </View>
    );
  }
}

OverlayMessage.propTypes = {
  content: PropTypes.object.isRequired,
  CloseOverlay: PropTypes.func.isRequired
};
