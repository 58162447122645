import React, { useRef } from 'react';
import { View, Image, Text, TouchableOpacity } from 'react-native';
import LinkSection from './LinkSection';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { CROSS_ICON } from 'apps/post-sales/src/Rails/Utils/RailImageConstant';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import CommonBottomOverlayMessage from '../../../Common/CommonOverlayMessage/CommonBottomOverlayMessage';
import createStyle from './styleCss';

interface HelpSupportProps {
  handleBottomOverlay: (arg: string) => void;
  data: {
    heading: string;
    actionList: {
      iconUrl: string;
      subHeading: string;
      heading: string;
      actionId: string;
    }[];
  };
  handleAction: (arg: string) => void;
}

const HelpSupport: React.FC<HelpSupportProps> = (props) => {
  const overlayRef = useRef(null);
  const { handleBottomOverlay, data, handleAction } = props;
  const { heading, actionList } = data || {};

  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme } = useTheme();
  const styles = createStyle(fonts, psTheme);

  const handleClose = () => {
    handleBottomOverlay('');
  };

  const setCloseOverlay = () => {
    const { closeOverlay } = overlayRef.current;
    if (closeOverlay) {
      closeOverlay();
    } else {
      handleClose();
    }
  };

  return (
    <CommonBottomOverlayMessage
      dismiss={handleClose}
      customStyle={styles.overlayStyle}
      ref={overlayRef}
      content={
        <View style={styles.container}>
          <View style={[styles.headingSection]}>
            <View
              style={[
                AtomicCss.flexRow,
                AtomicCss.alignCenter,
                AtomicCss.spaceBetween,
                AtomicCss.marginBottom10,
              ]}
            >
              <View style={[AtomicCss.flexRow, AtomicCss.flex1, AtomicCss.alignCenter]}>
                <Text style={styles.headingText}>{heading || ''}</Text>
              </View>
              <TouchableOpacity style={styles.crossIconWrapper} onPress={setCloseOverlay}>
                <Image style={styles.crossIconStyle} source={CROSS_ICON} />
              </TouchableOpacity>
            </View>
          </View>
          <View style={styles.bodySection}>
            {Array.isArray(actionList) &&
              actionList.map((list) => {
                const { iconUrl, subHeading, heading: listHeading, actionId } = list || {};
                return (
                  <LinkSection
                    iconUrl={iconUrl}
                    title={listHeading}
                    info={subHeading}
                    actionId={actionId}
                    key={actionId}
                    onPress={handleAction}
                  />
                );
              })}
          </View>
        </View>
      }
    />
  );
};

export default HelpSupport;
