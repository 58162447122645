import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { getStaticData } from '../../../../../staticData/staticData';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import HTMLView from 'react-native-htmlview';
import { getFont } from '../../../../../PostSalesStyles';

const FareServices = ({ handleBottomOverlay, title, description }) => {
  const { okayGotItText } = getStaticData();
  const { fsStyle, ...fonts } = getFont(true);

  const htmlStyle = {
    b: {
      color: '#4a4a4a',
      fontSize: fonts.fontSize.font14,
      fontFamily: fonts.bold,
    },
  };

  return (
    <View style={styles.bottomOverlayWrapper}>
      <Text style={[styles.headingText, fonts.blackFontFamily, fsStyle.font22]}>{title}</Text>

      <View style={styles.marginBottom40}>
        {description.map((item, index) => {
          if (index === 0) return null;
          return (
            <View style={styles.goFlexiList}>
              <Text style={styles.bullet}></Text>
              <HTMLView
                style={[styles.goFlexiListTxt, fonts.regularFontFamily, fsStyle.font16]}
                value={item}
                stylesheet={htmlStyle}
              />
            </View>
          );
        })}
      </View>

      <TouchableOpacity activeOpacity={0.4} onPress={() => handleBottomOverlay()}>
        <Text
          style={[fonts.blackFontFamily, fsStyle.font16, AtomicCss.azure, AtomicCss.alignRight]}
        >
          {okayGotItText}
        </Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  bottomOverlayWrapper: {
    height: 'auto',
    padding: 10,
  },
  darkText: { color: '#2f2f2f' },
  logoCovidIcon: { width: 35, height: 40, marginRight: 8 },
  headingText: {
    color: '#000',
    lineHeight: 28,
    marginBottom: 30,
  },

  marginBottom40: { marginBottom: 40 },
  goFlexiList: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  bullet: {
    width: 5,
    height: 5,
    borderRadius: 10,
    backgroundColor: colors.defaultTextColor,
    marginRight: 10,
  },
  goFlexiListTxt: {
    color: colors.defaultTextColor,
    lineHeight: 27,
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
});

export default FareServices;
