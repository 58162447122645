import React from 'react';
import PropTypes from 'prop-types';
import LinearGradient from 'react-native-linear-gradient';
import {Image, Text, TouchableOpacity, View} from 'react-native';
import styles from './AcmeRefundOverlayCss';
import {cancellingIcon} from '../utils/AcmeImageConstant';

class AcmeRefundOverlay extends React.Component {
  render() {
    return (
      <View style={styles.overlayContainer}>
        <TouchableOpacity style={styles.overlayBg} onPress={() => this.props.backPress()} />
        <View style={styles.overlayContent}>
          <View style={styles.flexRow}>
            <Image source={cancellingIcon} style={styles.CancelledIcon} />
            <View style={styles.mar15Bt}>
              <Text style={styles.overlayHead}>Are you sure?</Text>
            </View>
          </View>
          <View style={[styles.mar25Bt]}>
            <Text style={styles.cancelTxt}>
              Once confirmed, your booking will be cancelled and refund will be initiated.
            </Text>
          </View>
          <View style={[styles.flexRow, styles.alignCenter, styles.spaceBetween]}>
            <TouchableOpacity onPress={() => this.props.backPress()}>
              <Text style={styles.actionBlueText}>BACK</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => this.props.confirmClicklistener()}>
              <LinearGradient
                start={{x: 1.0, y: 0.0}}
                end={{x: 0.0, y: 1.0}}
                colors={['#065af3', '#53b2fe']}
                style={styles.CapsuleBtnFill}
              >
                <Text style={[styles.WhiteText, styles.regularFont, styles.CenterText, styles.transperantBgrnd]}>OKAY, CANCEL</Text>
              </LinearGradient>
            </TouchableOpacity>
          </View>
        </View>
      </View>

    );
  }
}
export default AcmeRefundOverlay;

AcmeRefundOverlay.propTypes = {
  backPress: PropTypes.func.isRequired,
  confirmClicklistener: PropTypes.func.isRequired
};

