import React, { useEffect, useRef, useState } from 'react';
import { View, Animated, BackHandler, Platform, DeviceEventEmitter } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import StepBar from '../../../Common/StepBar';
import Header from '@mmt/legacy-commons/Common/Components/Header/SimpleHeader.android';
import BusBookingDetailsConstant from '../../BusBookingDetailsConstant';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import { getStaticData } from '../../../staticData/staticData';
import ProgressView from '@mmt/legacy-commons/Common/Components/Progress/ProgressView';
import { BUS_CANCEL_REBOOK } from '../../../utils/NetworkUtils';
import BusCancelRebookConstant from '../CancelRebookConstants';
import NoInternetView from '@mmt/legacy-commons/Common/Components/Error/NoInternetView';
import isEmpty from 'lodash/isEmpty';
import { handleCalenderDate } from '../CancelRebookUtil';
import useApi from '../../../Common/useApi';
import SimpleHeader from '@mmt/legacy-commons/Common/Components/Header/SimpleHeader';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import BusList from './Components/BusList/index';
import createStyles from './selectBusCss';
import TopTravelCard from './Components/TopTravelCard';
import { getFont } from '../../../PostSalesStyles';
import useHardwareBackpress from '../../../Common/useHardwareBackpress';
import Actions from '../../../navigation/postSalesNavigation';
import { useTheme } from '../../../theme/theme.context';
import { dataAdaptor } from './dataAdaptor';

function CancelRebookSelectBus({ newDate, prevBookingDetails, bookingID, notFoundCb, navigation }) {
  const { fsStyle, ...fonts } = getFont();
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);
  const [viewState, setViewState] = useState(ViewState.LOADING);
  const [newBookingDate, setDate] = useState(newDate);
  const [busCancellRebookProgress, busCancelRebookData, busCancelRebookApi] = useApi(getApiLink());
  const { title, subTitle } = busCancelRebookData ? getSearchHeaderInfo(busCancelRebookData) : {};
  const CALENDAR_EVENT_RECEIVED = 'calEventReceived';
  const widthAnimation = useRef(Array.from({ length: 3 }, (_) => new Animated.Value(36))).current;

  useEffect(() => {
    dataAdaptor(busCancelRebookData, setViewState);
  }, [busCancelRebookData]);

  useHardwareBackpress().addListener(goBack);

  useEffect(() => {
    fetchJSONAsync();
  }, [newBookingDate]);

  function getApiLink() {
    return (
      BUS_CANCEL_REBOOK +
      `/${bookingID}` +
      `/${BusCancelRebookConstant.CANCEL_REBOOK_SEARCH}/${handleCalenderDate(newBookingDate)}`
    );
  }

  function fetchJSONAsync() {
    setViewState(ViewState.LOADING)
    busCancelRebookApi.httpGet();
  }

  function goBack() {
    Actions.popTo('busBookingDetail');
  }

  function getSearchHeaderInfo(busCancelRebookData) {
    const { metadata } = busCancelRebookData || {};
    const { fromCity, toCity, operatorName } = metadata || {};
    const fromToCity = fromCity + BusCancelRebookConstant.TO_TEXT + toCity;
    return {
      title: BusBookingDetailsConstant.SELECT_BUS_HEADER,
      subTitle: { text: fromToCity, size: 11 },
    };
  }
  function handleChange(date) {
    setViewState(ViewState.LOADING);
    setDate(date.calDepartureDate);
  }
  function renderDetail() {
    const { buses } = busCancelRebookData || {};
    if (isEmpty(buses)) {
      Actions.popTo('busBookingDetail');
      notFoundCb(busCancelRebookData);
      return null;
    }
    return (
      <View style={[styles.contentWrapper, AtomicCss.flex1]}>
        <View style={styles.topStickySection}>
          <Header
            title={title}
            subtitle={subTitle}
            headerShadow={true}
            iconPress={goBack}
            alignHeader={'flex-start'}
            textSize={14}
            textColor={'black'}
          />
        </View>
        <View style={[AtomicCss.makeRelative, styles.cardWrapper]}>
          <StepBar count={3} isActive={0} widthAnimation={widthAnimation} />
          <TopTravelCard
            handleDateChange={handleChange}
            previousBokingData={prevBookingDetails}
            date={newBookingDate}
            tab={BusCancelRebookConstant.BUS_SEARCH_STATE}
          />
        </View>
        <BusList response={busCancelRebookData} bookingId={bookingID} navigation={navigation} />
      </View>
    );
  }
  function renderNoNetworkView() {
    return (
      <NoInternetView
        onRetry={() => {
          setViewState(ViewState.LOADING);
          fetchJSONAsync();
        }}
      />
    );
  }
  function renderErrorView() {
    const staticData = getStaticData();
    const { backText, errorText, tryAgainText } = staticData;
    return (
      <View style={{ flex: 1 }}>
        <SimpleHeader title={backText} iconPress={goBack} />
        <ErrorView
          showRetry
          buttonLabel={tryAgainText}
          onRetry={() => {
            setViewState(ViewState.LOADING);
            fetchJSONAsync();
          }}
          message={BusCancelRebookConstant.DEFAULT_ERROR_MSG}
        />
      </View>
    );
  }
  function renderProgressView() {
    return <ProgressView message={BusCancelRebookConstant.SEARCHING_BUSES} />;
  }
  return (
    <View style={AtomicCss.flex1}>
      {viewState === ViewState.NO_INTERNET && renderNoNetworkView()}
      {(busCancellRebookProgress || viewState === ViewState.LOADING) && renderProgressView()}
      {viewState === ViewState.ERROR && renderErrorView()}
      {viewState === ViewState.SHOW_DETAIL && renderDetail()}
    </View>
  );
}
export default CancelRebookSelectBus;
