import { Platform } from 'react-native';
import {colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles';

const styles = {
    whiteCard: {
        borderRadius: 8,
        backgroundColor: colors.white,
    },
    cardBorder:{
        borderTopColor: colors.lightGrey3,
        borderBottomColor:colors.lightGrey3,
        borderTopWidth:1,
        borderBottomWidth:1
    },
    headingStyle:{
        paddingHorizontal: 16,
        flexDirection: "row",
        paddingTop: 12
    },
    processDtlsWrapper: {
        paddingVertical: 20,
        paddingLeft:26,
        paddingRight:12,
        paddingBottom: 0
    },
    iconStyle:{
        width:20,
        height:20,
    },
    cardIcon: {
        width: 45,
        height: 24,
        marginRight: 10
    },
    processRowDtls:{
        paddingLeft:25,
        paddingBottom:25,
    },
    imgHolder:{
        width:32,
        height:32,
        position:'absolute',
        left:-17,
        justifyContent:'center',
        alignItems:'center',
    },
    processTitle:{
        color:colors.defaultTextColor,
        fontFamily:fonts.bold,
        fontSize:14,
        marginBottom:5,
    },
    processDesc:{
        color:'#747474',
        fontFamily:fonts.regular,
        fontSize:12,
    },
    transperantBg: {
    backgroundColor: 'transparent'
    },
    actionBtnRec: {
        backgroundColor: '#fff'
    },
    cardContent: {
        borderBottomWidth: 1,
        borderBottomColor: '#e4e4e4',
        padding: 15,
    },
    cardSubtext : {
        width :'95%'
    }
}

export  default styles;
