import { StyleSheet } from 'react-native';

export default (theme, fonts) => {
  return StyleSheet.create({
    offerCard: {
      borderRadius: 8,
      borderWidth: 1,
      borderColor: theme.color.yellow8,
      paddingHorizontal: 8,
      paddingVertical: 12,
      backgroundColor: theme.color.white,
    },
    tickIconStyle: {
      width: 12,
      height: 9,
      marginRight: 5,
      alignSelf:'flex-start',
      marginTop: 5,
    },
    htlInfoSection: {
      flexDirection: 'row',
      marginTop: 12,
      justifyContent: 'space-between',
      paddingRight: 20,
    },
  });
};

export const htmlStyle = (theme,fonts) => {
  return StyleSheet.create({
    span: {
      color: theme.color.blue15,
      ...fonts.font14,
      ...fonts.boldFontFamily,
    },
    p: {
      ...fonts.font14,
      ...fonts.boldFontFamily,
    }
  });
};
