import { Fonts, Theme } from 'apps/post-sales/src/cab/types';
import { StyleSheet } from 'react-native';

const getStyle = (theme: Theme, fonts: Fonts) => {
  const { color } = theme;
  return StyleSheet.create({
    refundIssueCard: {
      borderRadius: 16,
    },
    notBoardCard: {
      paddingVertical: 20,
      paddingHorizontal: 16,
      borderBottomWidth: 1,

      borderBottomColor: color.lightGrey3,
    },
    RefundIssueTop: {
      paddingVertical: 20,
      paddingHorizontal: 8,
    },
    cabVoucher: {
      width: 90,
      height: 73,
      marginLeft: 12,
    },
    topSection: {
      borderBottomWidth: 1,
      borderBottomColor: color.lightGray,
      paddingBottom: 12,
      marginBottom: 12,
      flexDirection: 'row',
    },
    titleText: {
      ...fonts.boldFontFamily,
      fontSize: fonts.fontSize.font16,
      color: color.black,
      marginBottom: 12,
    },
    desc: {
      fontSize: fonts.fontSize.font12,
      color: color.defaultTextColor,
    },
    p: {
      fontSize: fonts.fontSize.font12,
      color: color.defaultTextColor,
      marginBottom: 12,
    },
    b: {
      color: color.lightGreen18,
      ...fonts.boldFontFamily,
    },
    moreInfo: {
      color: color.defaultTextColor,
      ...fonts.boldFontFamily,
      fontSize: fonts.fontSize.font12,
    },
    voucherCode: {
      color: color.azure,
      ...fonts.boldFontFamily,
      fontSize: fonts.fontSize.font12,
      marginLeft: 5,
    },
    copyIcon: {
      width: 16,
      height: 16,
      marginLeft: 5,
    },
    buttonRadiusStyle: {
      borderBottomLeftRadius: 16,
      borderBottomRightRadius: 16,
      paddingLeft: 14,
      paddingRight: 14,
    },
  });
};

export default getStyle;
