import React, { useCallback, useEffect, useRef, useState } from 'react';
import { View, Animated, ScrollView, Keyboard } from 'react-native';
import { KeyboardAwareView } from 'react-native-keyboard-aware-view';
import cloneDeep from 'lodash/cloneDeep';

import Actions from '../../../../../navigation/postSalesNavigation';
import createStyles from './RequestCallBackRefundCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import Header from '@mmt/legacy-commons/Common/Components/Header/SimpleHeader';
import StepBar from '../../../../../Common/StepBar';
import UploadDocuments from '../UploadDocuments';
import SelectMode from './components/SelectMode';
import CallBackOverlayMessage from '../../../../../Common/CommonOverlayMessage';
import ReviewCorrectionOverlay from './components/ReviewCorrectionOverlay';
import useHardwareBackpress from '../../../../../Common/useHardwareBackpress';
import FlightBookingTrackingHelper from '../../../../modules/details/helper/FlightBookingTrackingHelper';
import BaseButton, { BUTTON_VARIANT } from '../../../../../Common/BaseButton';
import { getStaticData } from '../../../../../staticData/staticData';
import { getFont } from '../../../../../PostSalesStyles';
import { useTheme } from '../../../../../theme/theme.context';
import { NAME_CORRECTION_RULE_IDS } from '../../constant';
import { NAME_CORRECTION_SUBMIT, REVIEW_NAME_CORRECTION } from '../../../../../utils/NetworkUtils';

import useApi from '../../../../../Common/useApi';

import { isEmptyArray, isNullOrEmptyObject } from '../../../../../Common/commonUtil';
import {
  getPaxSelectionPageDetails,
  getFlightSubHeading,
  getFormattedPassengerList,
} from '../../data/dataAdapter';
import {
  getFormattedConfirmedPassengerList,
  getFinalPassengerList,
  requestAdapterForReview,
} from '../../data/requestAdapter';
import { validateInput, isValidChange, initSelectedPax, isAttachmentDone } from '../../helper';
import { FLIGHT_THROTTLE_OBJECT } from '../../../details/FlightBookingDetailsConstant';

let reviewApiCount = 0;

const NameChangeRequest = ({
  nameCorrectionDetails,
  bookingId,
  reasonSelected,
  reviewApiCallLimit = 3,
  response: responseJson,
}) => {
  const staticData = getStaticData();
  const { nameCorrectionTexts } = staticData || {};
  const { psTheme: theme } = useTheme();
  const { fsStyle } = getFont(true);
  const styles = createStyles(theme);
  const {addListener,removeListener} = useHardwareBackpress()
  const {
    flightDetailHeader,
    flightActionHeader,
    passengerList,
    importantCard,
    noOfCharactersChangeAllowed,
    nameRvwCorrectionValidationText,
    nameCorrectionRulesDetailCard,
    flightNameCorrectionUpload,
    flightNameReviewCorrectionCard,
    submitRequestActionButton,
    actionButton,
  } = getPaxSelectionPageDetails(nameCorrectionDetails);

  const clonePassengerList = cloneDeep(passengerList);

  const [activeTab, setActiveTab] = useState(0);
  const [editInfo, setEditInfo] = useState({});
  const [attachmentList, setAttachmentList] = useState({});
  const [selectedPax, setSelectedPax] = useState(initSelectedPax(clonePassengerList));
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmPaxData, setConfirmPaxData] = useState(null);

  const widthAnimation = useRef(Array.from({ length: 2 }, (_) => new Animated.Value(36))).current;

  const [activeState, setActiveState] = useState(0);

  const [, ncResponse, ncAPI] = useApi(NAME_CORRECTION_SUBMIT);
  const [, rncResponse, rncAPI] = useApi(REVIEW_NAME_CORRECTION);
  const { nameChangeValidationResponseList, error: rncErr } = rncResponse || {};
  const [globalInputError, setGlobalInputError] = useState(nameChangeValidationResponseList);
  useEffect(() => {
    FlightBookingTrackingHelper.trackLoadEvent(
      'Special request: Name Change Select passenger',
      responseJson,
      'nameChange',
    );
    const finalPassengerList = getFinalPassengerList(passengerList);
    ncAPI.httpPost({
      bookingId,
      uniqueId: 'FLIGHT_NAME_CORRECTION_SUBMIT',
      body: {
        bookingId,
        submitNameChangeRequest: false,
        passengerList: finalPassengerList,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ncAPI, bookingId, passengerList]);

  useEffect(() => {
    if (!isEmptyArray(nameChangeValidationResponseList)) {
      setGlobalInputError(nameChangeValidationResponseList);
      const nameCorrectionNotApplicable = nameChangeValidationResponseList.find(
        (item) => item.nameCorrectionApplicable === false,
      );
      if (!nameCorrectionNotApplicable) {
        setShowConfirmation(true);
      }
    }
  }, [nameChangeValidationResponseList]);

  const backHandler = () => {
    if (activeTab > 0) {
      setActiveTab(activeTab - 1);
      setActiveState(0);
      Animated.timing(widthAnimation[0], {
        toValue: 36,
        duration: 500,
      }).start();
    } else {
      Actions.pop();
    }
  };

  addListener(backHandler);

  const onProceedClicked = (data) => {
    FlightBookingTrackingHelper.trackNameCorrectionBtnClick(
      'Special Request: Name Change select passenger',
      'Review Correction',
      responseJson,
    );
    setConfirmPaxData(data);
    if (reasonSelected.ruleId === NAME_CORRECTION_RULE_IDS.Incorrect_Spelling) {
      reviewApiCount = 1;
      reviewCorretionRequest(data);
    } else {
      setShowConfirmation(true);
    }
  };

  const onContinueConfirmation = () => {
    FlightBookingTrackingHelper.trackNameCorrectionBtnClick(
      'Special Request: Name Change select passenger',
      'Proceed to upload proof',
      responseJson,
    );

    setActiveTab(1);
    setActiveState(1);
    Animated.timing(widthAnimation[0], {
      toValue: 12,
      duration: 500,
    }).start();
    setShowConfirmation(false);
  };

  const checkIfNoPaxSelected = () => {
    let flag = false;
    if (isNullOrEmptyObject(selectedPax)) {
      flag = true;
    } else if (ncResponse && ncResponse.passengerList && !isEmptyArray(clonePassengerList)) {
      flag = ncResponse.passengerList.filter((item) => !item.nameChangeRaised).length <= 0;
    }

    return flag;
  };

  const updateAttachmentList = (attachment) => {
    setAttachmentList((preState) => ({ ...preState, ...attachment }));
  };
  const onDeleteAttachment = (id) => {
    const newAttachment = cloneDeep(attachmentList);
    delete newAttachment[id];
    setAttachmentList(newAttachment);
  };

  const updateSelectedPax = (pax) => {
    const { paxReferenceNo, paxLineNo } = pax || {};
    const key = paxReferenceNo || paxLineNo;
    if (key && selectedPax[key]) {
      delete pax.updatedData;
      delete selectedPax[key];
      delete editInfo[key];
      const data =
        !isEmptyArray(globalInputError) && globalInputError.filter((item) => item.paxId != key);
      if (data) {
        setGlobalInputError(data);
      }
      setEditInfo({ ...editInfo });
    } else {
      selectedPax[key] = pax;
    }
    setSelectedPax({ ...selectedPax });
  };

  const onReviewCorrection = () => {
    try {
      Keyboard.dismiss();
      const validationInfo = {};
      selectedPax &&
        Object.keys(selectedPax).forEach((pax) => {
          const { paxReferenceNo, paxLineNo } = selectedPax[pax];
          const key = paxReferenceNo || paxLineNo;
          validationInfo[key] = validateInput(
            selectedPax[pax],
            reasonSelected,
            nameCorrectionTexts,
          );
        });

      setEditInfo(validationInfo);
      if (isValidChange(validationInfo)) {
        onProceedClicked && onProceedClicked(validationInfo);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getFilesUrls = () => {
    const result = [];
    Object.keys(attachmentList).forEach((key) => {
      result.push(attachmentList[key]);
    });
    return result.map((item) => item.fileUrls);
  };

  const onSubmitRequest = () => {
    FlightBookingTrackingHelper.trackNameCorrectionBtnClick(
      'Special Request: Name Change select passenger',
      'Submit Request',
      responseJson,
    );

    const fileUrls = getFilesUrls(attachmentList);
    const finalPassengerList = getFormattedConfirmedPassengerList(clonePassengerList, confirmPaxData);
    removeListener();
    Actions.nameChangeThankYouNew({
      uniqueId: 'FLIGHT_NAME_CORRECTION_SUBMIT',
      bookingId,
      passengerList: clonePassengerList,
      reasonSelected,
      finalPassengerList,
      attachmentList: fileUrls,
      responseJson,
    });
  };
  const reviewCorretionRequest = useCallback(
    (data) => {
      const reqBody = requestAdapterForReview(data, bookingId, noOfCharactersChangeAllowed);
      rncAPI.httpPost({
        bookingId,
        uniqueId: 'FLIGHT_REVIEW_NAME_CORRECTION',
        body: reqBody,
      });
    },
    [bookingId, noOfCharactersChangeAllowed, rncAPI],
  );

  useEffect(() => {
    if (!isNullOrEmptyObject(rncErr) && reviewApiCount < reviewApiCallLimit) {
      reviewApiCount++;
      reviewCorretionRequest(confirmPaxData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewCorretionRequest, rncErr, reviewApiCallLimit]);

  const flightSubHeading = getFlightSubHeading(nameCorrectionRulesDetailCard, reasonSelected);

  const [passengerRequestCheckListData, setPassengerRequestCheckListData] = useState(null);

  useEffect(() => {
    if (!isNullOrEmptyObject(ncResponse) && ncResponse.passengerList) {
      setPassengerRequestCheckListData({
        passengerCheckList: ncResponse.passengerList,
        alreadyCorrectionReqRaisedString: ncResponse.alreadyCorrectionReqRaisedString,
      });
    }
  }, [ncResponse]);

  const formattedPassengerList = getFormattedPassengerList(
    clonePassengerList,
    passengerRequestCheckListData,
  );

  return (
    <KeyboardAwareView style={[styles.contentWrapper, AtomicCss.flex1]}>
      <View style={styles.topSection}>
        <View style={styles.topStickySection}>
          <Header
            title={flightDetailHeader.heading || nameCorrectionTexts.heading}
            subtitle={{ text: flightSubHeading || '' }}
            iconPress={backHandler}
            headerShadow={true}
            alignHeader={'flex-start'}
          />
        </View>

        <ScrollView keyboardShouldPersistTaps={'handled'}>
          <View style={[AtomicCss.makeRelative, styles.cardWrapper]}>
            {activeTab === 0 && (
              <>
                <View style={[AtomicCss.whiteCard, styles.pageWrapper]}>
                  <Animated.View style={[styles.selectModeWrapper]}>
                    <View style={styles.stepBarWrapper}>
                      <StepBar count={2} widthAnimation={widthAnimation} isActive={activeState} />
                    </View>
                    <SelectMode
                      passengerList={formattedPassengerList}
                      flightActionHeader={flightActionHeader}
                      selectedPax={selectedPax}
                      rule={reasonSelected}
                      updateSelectedPax={updateSelectedPax}
                      importantCard={importantCard}
                      actionButton={actionButton}
                      ncSubmitResponse={ncResponse}
                      nameRvwCorrectionValidationText={nameRvwCorrectionValidationText}
                      editInfo={editInfo}
                      globalInputError={globalInputError}
                      // formattedPassengerList= {formattedPassengerList}
                    />
                  </Animated.View>
                </View>
              </>
            )}
            {activeTab === 1 && (
              <View
                style={[
                  AtomicCss.whiteCard,
                  AtomicCss.marginHorizontal12,
                  AtomicCss.flex1,
                  styles.pageWrapper,
                ]}
              >
                <View style={[styles.uploadDocWrapper]}>
                  <View style={styles.stepBarWrapper}>
                    <StepBar count={2} widthAnimation={widthAnimation} isActive={activeState} />
                  </View>
                  <UploadDocuments
                    bookingId={bookingId}
                    flightNameCorrectionUpload={flightNameCorrectionUpload}
                    data={confirmPaxData}
                    attachmentList={attachmentList}
                    setAttachmentList={updateAttachmentList}
                    onDeleteAttachment={onDeleteAttachment}
                    rule={reasonSelected.ruleId}
                  />
                </View>
              </View>
            )}
          </View>
        </ScrollView>
      </View>
      <View style={styles.bottomSection}>
        <View style={[styles.buttonBottomWrapper]}>
          {activeTab === 0 && (
            <>
              <BaseButton
                {...FLIGHT_THROTTLE_OBJECT}
                variant={BUTTON_VARIANT.PRIMARY}
                clickHandler={onReviewCorrection}
                testStyle={fsStyle.font16}
                text={actionButton.text || nameCorrectionTexts.reviewCorrection}
                disabled={checkIfNoPaxSelected()}
                buttonStyle={styles.pageButtonStyle}
              />
            </>
          )}
          {activeTab === 1 && (
            <BaseButton
              {...FLIGHT_THROTTLE_OBJECT}
              variant={BUTTON_VARIANT.PRIMARY}
              clickHandler={onSubmitRequest}
              testStyle={fsStyle.font16}
              buttonStyle={styles.pageButtonStyle}
              text={submitRequestActionButton.text || nameCorrectionTexts.submitRequest}
              disabled={!isAttachmentDone(attachmentList, selectedPax)}
            />
          )}
        </View>
      </View>
      {showConfirmation && (
        <CallBackOverlayMessage
          handleBottomOverlay={() => setShowConfirmation(false)}
          content={
            <ReviewCorrectionOverlay
              onClose={() => setShowConfirmation(false)}
              onContinue={onContinueConfirmation}
              data={confirmPaxData}
              flightNameReviewCorrectionCard={flightNameReviewCorrectionCard}
              rule={reasonSelected.ruleId}
              handleBottomOverlay={() => setShowConfirmation(false)}
            />
          }
        />
      )}
    </KeyboardAwareView>
  );
};

export default NameChangeRequest;
