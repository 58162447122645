import React, { useContext } from 'react';
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  UIManager,
  LayoutAnimation,
  Platform,
} from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getStyle from './SelectPassengersCss';
import { getFont } from '../../../../../../../PostSalesStyles';
import AncillaryRow from '../AncillaryRow';
import Seperator from '../../../../../../../Common/CardLayoutSeperator';
import { isEmptyArray } from '../../../../../../../Common/commonUtil';
import SpecialClaimNewContext from '../../../../SpecialClaimNewContext';
import { RadioButton } from '@mmt/legacy-commons/Common/Components/Radio';
import specialClaimActionTypes from '../../../../data/specialClaimActionTypes';
import {getAncillarySecotorSelectionData} from "../../../../data/dataAdapter"
import { useTheme } from '../../../../../../../theme/theme.context';

const SelectCancelledFlight = ({
  ancillaryData,
  isLastItem,
  selectedAnc,
  dispatchCancellationState,
}) => {
  if (Platform.OS === 'android' && UIManager.setLayoutAnimationEnabledExperimental) {
    UIManager.setLayoutAnimationEnabledExperimental(true);
  }

  const handleFlightSegment = () => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.linear);
    dispatchCancellationState({
      type: specialClaimActionTypes.LOAD_ANCILLARY,
      data: ancillaryData,
    });
  };

  const { fsStyle, ...fonts } = getFont(true);
  const {psTheme} = useTheme();
  const styles = getStyle(psTheme, fonts);
  const {color} = psTheme;
  const context = useContext(SpecialClaimNewContext);
  const {
    title,
    subtitle,
    groupEntries,
    isActive,
    imgUri
  } = getAncillarySecotorSelectionData(ancillaryData, selectedAnc, context);
  
  return (
    <View style={[styles.cardWrapper, isLastItem]}>
      <View style={styles.checkAllWrapper}>
        <View style={[styles.checkAllWrapperInner]}>
          <TouchableOpacity
            style={[styles.checkBtn, styles.checkAllBtn, styles.paxslctionHeader]}
            activeOpacity={0.9}
            onPress={handleFlightSegment}
          >
            <View style={[styles.sectorHeader]}>
              <View style={AtomicCss.flexRow}>
                <View style={[styles.checkbox, styles.checkboxAll]}>
                  <RadioButton checked={isActive} />
                </View>
                <View>
                  <View style={styles.destRow}>
                    <Text style={[styles.cityText, fsStyle.font18]}>{title}</Text>
                  </View>
                  {!isEmptyArray(subtitle) &&
                    subtitle.map((st) => (
                      <Text style={[styles.smallText, fsStyle.font13]}>{st}</Text>
                    ))}
                </View>
              </View>
              <View style={styles.airlinesIconWrapper}>
                <Image style={styles.airlineIcon} source={{ uri: imgUri }} />
              </View>
            </View>
          </TouchableOpacity>
        </View>
      </View>
      <View style={styles.seperatorWrapper}>
        <Seperator />
      </View>
      <View style={styles.passengersList}>
        {isActive && (
          <>
            {groupEntries.map((groupEntry, index) =>
              groupEntry.entries.map((entry) => (
                <View style={styles.paxListWrapper}>
                  <AncillaryRow
                    key={index}
                    index={index}
                    data={entry}
                    type={groupEntry.topHeader}
                  />
                </View>
              )),
            )}
          </>
        )}
        {!isActive && (
          <View style={styles.collapsedSection}>
            <Text
              style={[fsStyle.font14, color.defaultTextColor, styles.lineHeight22, AtomicCss.flex1]}
            >
              {groupEntries.map((groupEntry, index) => (
                <React.Fragment>
                  <Text style={fonts.boldFontFamily}>{groupEntry.topHeader}</Text> (
                  {groupEntry.entries.map((entry, entryIndex) => (
                    <Text style={fonts.italicFontFamily}>
                      {entry.title}
                      {entryIndex != groupEntry.entries.length - 1 && ', '}
                    </Text>
                  ))}
                  ){index != groupEntries.length - 1 && ', '}
                </React.Fragment>
              ))}
            </Text>
          </View>
        )}
      </View>
    </View>
  );
};
export default SelectCancelledFlight;
