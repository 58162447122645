import React from "react";
import { Text, TouchableOpacity } from "react-native";
import {fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import { themeColors } from "../../PostSalesStyles";

const Tabs = props => {
  const index = props.index;
  const tabStyle = [styles.tabStyle];
  const tabStyleTxt = [styles.tabStyleTxt];

  if (index === props.activeTab) {
    tabStyle.push(styles.tabActive);
    tabStyleTxt.push(styles.tabTxtActive);
  }
  const disabledState = index === props.disable;
  if (disabledState) {
    tabStyleTxt.push(styles.disabledTxt);
  }
  return (
    <TouchableOpacity
      style={tabStyle}
      onPress={() => props.onHandleTabChange(index)}
      disabled={disabledState ? true : false}
    >
      <Text style={tabStyleTxt}>{props.item}</Text>
    </TouchableOpacity>
  );
};

const styles ={
    tabStyle:{
        paddingVertical:10,
        marginHorizontal:15,
    },
    tabStyleTxt:{
        color:'#4a4a4a',
        fontFamily:fonts.regular,
        fontSize:12,
    },
    tabActive:{
        borderBottomColor: themeColors.activeTabColor,
        borderBottomWidth: 3,

    },
    tabTxtActive:{
        color:'#000',
        fontFamily:fonts.bold,  
    },
    disabledTxt:{
        color:'rgba(74,74,74,.3)'
    },
}

export default Tabs;
