import React from 'react';
import { View, Image, Text, Dimensions, TouchableOpacity, Linking } from 'react-native';
import Carousel from 'react-native-snap-carousel';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import LinearGradient from 'react-native-linear-gradient';
import RightArrow from '@mmt/legacy-assets/src/Images/rightArrow.webp';
import GenericModule from 'packages/core/native/GenericModule';
import createStyle from './style';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import { WhereToGOImage } from '../../../../types';

const gradientColor = ['rgba(255, 255, 255, 0.1)', 'rgba(0, 0, 0, 0.7)'];
const { width: screenWidth } = Dimensions.get('window');
const ITEM_WIDTH = screenWidth - 150;

interface WhereToGoSliderProps {
  data: WhereToGOImage[] | undefined;
}
const WhereToGoSlider = ({ data }: WhereToGoSliderProps) => {
  const { psTheme: theme } = useTheme();
  const fonts = getFont();
  const styles = createStyle(theme, fonts);
  const handleWhere2GoDeepLink = (link: string) => {
    GenericModule.openDeepLink(link);
  };

  const redirectSocialMedia = (link: string) => {
    Linking.openURL(link);
  };
  const renderSocialMediaCard = (item: WhereToGOImage) => {
    return (
      <View style={styles.socialCardContainer}>
        {!!item.heading && <Text style={styles.socialCardHeading}>{item.heading}</Text>}
        {item.subHeading?.map((subHeading, index) => {
            return (
              <Text key={index} style={styles.socialCardSubHeading}>
                {subHeading}
              </Text>
            );
          })}
        <View style={[styles.socialContainer, AtomicCss.marginBottom12]}>
          { item.socialMediaDto?.map((socialMedia, index) => {
              return (
                <TouchableOpacity
                  style={[styles.socialWrapper, { backgroundColor: socialMedia.bgColor }]}
                  key={index}
                  onPress={() => redirectSocialMedia(socialMedia.redirectUrl)}
                >
                  <Image source={{ uri: socialMedia.iconUrl }} style={styles.iconStyle} />
                  {!!socialMedia.text && (
                    <Text style={styles.socialMediaText}>{socialMedia.text}</Text>
                  )}
                </TouchableOpacity>
              );
            })}
        </View>
      </View>
    );
  };

  const renderWhereToGoItem = (item: WhereToGOImage) => {
    return (
      <TouchableOpacity
        style={styles.itemContainer}
        activeOpacity={0.7}
        onPress={() => handleWhere2GoDeepLink(item.deeplink)}
      >
        <Image source={{ uri: item.thumbnail }} style={styles.imageStyle} />
        <LinearGradient
          start={{ x: 0.0, y: 0.5 }}
          end={{ x: 0.0, y: 0.8 }}
          colors={gradientColor}
          style={[styles.textContainer]}
        >
          <View style={[AtomicCss.flex1, AtomicCss.justifyEnd]}>
            <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.spaceBetween]}>
              <Text style={[styles.titleText, AtomicCss.marginBottom5]}>{item.title}</Text>
              <Image style={styles.rightArrowStyle} source={RightArrow}></Image>
            </View>
          </View>
        </LinearGradient>
      </TouchableOpacity>
    );
  };
  const renderItem = ({ item }: { item: WhereToGOImage }) => {
    return item.isSocialMediaData ? renderSocialMediaCard(item) : renderWhereToGoItem(item);
  };
  return (
    <View style={styles.container}>
      <Carousel
        data={data}
        renderItem={renderItem}
        sliderWidth={screenWidth}
        itemWidth={ITEM_WIDTH}
        layout="default"
        layoutCardOffset={`20`}
        loop={true}
        autoplay={true}
        autoplayInterval={3000}
      />
    </View>
  );
};

export default WhereToGoSlider;