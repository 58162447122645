import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import {View, Text, ScrollView, StyleSheet} from 'react-native';
import VoucherList from './VoucherList';
import {fonts, colors} from '@mmt/legacy-commons/Styles/globalStyles';
import Header from './Header';
import Button from './Button';
import {getButtonsForType} from '../HolidayBookingDetailUtils';
import {buttonDisplayTypes} from '../HolidayBookingConstants';
import TopInfo from './TopInfo';
class DownloadVoucher extends React.Component {
  static navigationOptions = {
    header: null
  };

  render() {
    const {downloadVoucherPage, status} = this.props;
    const roundButtons = getButtonsForType(downloadVoucherPage.buttons, buttonDisplayTypes.LINK_RECTANGLE_FULL, false);
    const topMargin = isEmpty(downloadVoucherPage.downloadBookingMessage) ? 10 : 0;
    return (
      <View style={styles.modalWrapper}>
        <Header
          title="Download Vouchers"
          headerShadow
          headingTxt="font18"
          pageName={this.props.pageName}
          goBack={this.props.onBackPopUp}
          navigation={this.props.navigation}
        />
        <ScrollView>
          {!isEmpty(downloadVoucherPage.downloadBookingMessage) &&
          <TopInfo
            info={downloadVoucherPage.downloadBookingMessage}/>
          }
          <View style={{marginTop: topMargin}}>
            {!isEmpty(downloadVoucherPage.downloadVoucherSegmentList) &&
            downloadVoucherPage.downloadVoucherSegmentList.map((item, index) => {
              return (
                <View>
                  <VoucherList
                    item={item}
                    index={index}
                    status={!isEmpty(status) && status[index]}
                    downloadVoucher={this.props.downloadVoucher}
                    lstItem={index === downloadVoucherPage.downloadVoucherSegmentList.length - 1}/>
                  {item.type === 'holiday' && downloadVoucherPage.downloadVoucherSegmentList.length > 1 &&
                  <View style={styles.dividerWrapper}>
                    <View style={styles.dividerLine}/>
                    <View style={styles.orDivider}>
                      <Text style={styles.orDividerText}>OR</Text>
                    </View>
                  </View>
                  }
                </View>
              );
            })
            }
          </View>
        </ScrollView>
        <View style={styles.btnWrapper}>
          {!isEmpty(roundButtons)
          && roundButtons.map((item, index) => {
            return (
              <Button
                pageName={this.props.pageName}
                btnTxt={item.text}
                btnBg={item.displayType}
                button={item}
                handleClick={this.props.handleButtonClick}/>
            );
          })
          }
        </View>
      </View>
    );
  }

}

const styles = StyleSheet.create({
  modalWrapper: {
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: '#f2f2f2',
    position: 'absolute',
    elevation: 3,
    zIndex: 3,
    width: '100%',
    height: '100%'
  },
  btnWrapper: {padding: 15},
  dividerWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 50,
    position: 'relative',
    // marginTop:0,
    marginBottom: 5
  },
  dividerLine: {
    width: '100%',
    height: 1,
    backgroundColor: '#ccc',
    position: 'absolute'
  },
  orDivider: {
    width: 32,
    height: 32,
    borderRadius: 50,
    borderColor: '#ccc',
    borderWidth: 1,
    backgroundColor: '#f2f2f2',
    justifyContent: 'center',
    alignSelf: 'center'

  },
  orDividerText: {
    fontSize: 12,
    fontFamily: fonts.regular,
    color: colors.defaultTextColor,
    alignSelf: 'center'
  }
});

DownloadVoucher.propTypes = {
  downloadVoucherPage: PropTypes.object.isRequired,
  onBackPopUp: PropTypes.func.isRequired,
  downloadVoucher: PropTypes.func.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
  pageName: PropTypes.func.isRequired
};

export default DownloadVoucher;
