import React from "react";
import { appendUrlPrefix } from "../../webUtils";
import loadable from "@loadable/component";
import ProgressView from "../../components/ProgressView";

import { getPokusConfig } from "web/WebModules/AbConfigModule";
export const FLIGHT_ROUTE_URLS = {
  DETAIL_PAGE: appendUrlPrefix("/flight"),
};
const FLIGHTS_PATHS = {
  FLIGHT_BOOKING_DETAIL: {
    pathName: FLIGHT_ROUTE_URLS.DETAIL_PAGE,
    pageKey: "flightBookingDetail",
    Component: loadable(
      () => import("@rn/apps/post-sales/src/flights/modules/details"),
      { fallback: <ProgressView /> }
    ),
    isRtl: true,
  },
  FLIGHT_BOOKING_DETAILED_INFO: {
    pathName: appendUrlPrefix("/flightBookingDetailedInfo"),
    pageKey: "flightBookingDetailedInfo",
    Component: loadable(
      () => import("@rn/apps/post-sales/src/flights/modules/v2/DetailedInfo"),
      { fallback: <ProgressView /> }
    )
  },
  FLIGHT_SELLING : {
    pathName: appendUrlPrefix("/flightSellingPage"),
    pageKey: "flightSellingPage",
    Component: loadable(
      () => import("@rn/apps/post-sales/src/flights/modules/v2/SellingPage"),
      { fallback: <ProgressView /> }
    )
  },
  REFUND_TRACKER_FLIGHTS : {
    pathName: appendUrlPrefix("/refundTrackerFlights"),
    pageKey: "refundTrackerFlights",
    Component: loadable(
      () => import("@rn/apps/post-sales/src/flights/modules/v2/RefundTracker"),
      { fallback: <ProgressView /> }
    )
  },
  FLIGHT_PAYMENT_BREAKUP: {
    pathName: appendUrlPrefix("/flightPaymentBreakup"),
    pageKey: "flightPaymentPriceBreakUp",
    Component: loadable(
      () =>
        import(
          "@rn/apps/post-sales/src/flights/modules/details/components/PaymentPriceBreakup"
        ),
      { fallback: <ProgressView /> }
    ),
    isRtl: false,
  },
  FLIGHT_SPECIAL_CLAIM_REASON: {
    pathName: appendUrlPrefix("/flightSpecialClaimReason"),
    pageKey: "specialClaimReasons",
    Component: loadable(
      () =>
        import(
          "@rn/apps/post-sales/src/flights/cancellation/components/ClaimReason/index"
        ),
      { fallback: <ProgressView /> }
    ),
    isRtl: true,
  },
  FLIGHT_CANCELLATION: {
    pathName: appendUrlPrefix("/flightCancel"),
    pageKey: "flightCancellationPage",
    Component: loadable(
      () =>
        import(
          "@rn/apps/post-sales/src/flights/modules/Cancellation/pages/CancellationLanding"
        ),
      { fallback: <ProgressView /> }
    ),
    isRtl: true,
  },
  FLIGHT_CANCELLATION_REFUND_BREAKUP: {
    pathName: appendUrlPrefix("/flightCancellationBreakup"),
    pageKey: "flightBookingRefundBreakup",
    Component: loadable(
      () =>
        import(
          "@rn/apps/post-sales/src/flights/modules/Cancellation/pages/RefundBreakup"
        ),
      { fallback: <ProgressView /> }
    ),
    isRtl: true,
    customFunction: (redirectUrl, pathName, params) => {
      if (params.cancellationPreviewResponseJson) {
        params.bookingId = params.cancellationPreviewResponseJson.bookingId;
      }
      redirectUrl(pathName, params);
    },
  },
  FLIGHT_REFUND_BREAKUP: {
    pathName: appendUrlPrefix("/refundBreakup"),
    pageKey: "fltRefundBreakup",
    Component: loadable(
      () => import("@rn/apps/post-sales/src/Common/RefundBreakupPage"),
      { fallback: <ProgressView /> }
    ),
    isRtl: true,
  },
  FLIGHT_REFUND_BREAKUP_V2: {
    pathName: appendUrlPrefix("/refundBreakupV2"),
    pageKey: "fltRefundBreakupV2",
    Component: loadable(
      () => import("@rn/apps/post-sales/src/Common/RefundBreakUpPageV2"),
      { fallback: <ProgressView /> }
    ),
    isRtl: true,
  },
  FLIGHT_CANCELLATION_PAX_SELECTION: {
    pathName: appendUrlPrefix("/partialPaxSelection"),
    pageKey: "partialPaxSelection",
    Component: loadable(
      () =>
        import(
          "@rn/apps/post-sales/src/flights/modules/Cancellation/pages/SelectPassengers"
        ),
      { fallback: <ProgressView /> }
    ),
    isRtl: true,
    customFunction: (redirectUrl, pathName, params) => {
      if (params.cancellationPreviewResponseJson) {
        params.bookingId = params.cancellationPreviewResponseJson.bookingId;
      }
      redirectUrl(pathName, params);
    },
  },
  FLIGHT_DATE_CHANGE_THANK_YOU_WEB: {
    pathName: appendUrlPrefix("/DateChangeThankYouCard"),
    pageKey: "dateChangeThankYouCard",
    Component: loadable(
      () =>
        import(
          "@rn/apps/post-sales/src/flights/modules/DateChange/pages/DcReqSubmit"
        ),
      { fallback: <ProgressView /> }
    ),
    isRtl: false,
  },
  FLIGHT_COMMON_THANK_YOU: {
    pathName: appendUrlPrefix("/flightCommonThankYou"),
    pageKey: "flightCommonThankYou",
    Component: loadable(
      () => import("@rn/apps/post-sales/src/flights/ThankyouPage"),
      { fallback: <ProgressView /> }
    ),
    isRtl: false,
  },
  FLIGHT_COMMON_ERROR: {
    pathName: appendUrlPrefix("/flightCommonError"),
    pageKey: "flightCommonError",
    Component: loadable(
      () => import("@rn/apps/post-sales/src/flights/ErrorPage"),
      { fallback: <ProgressView /> }
    ),
    isRtl: false,
  },
  FLIGHTS_DATE_CHANGE_SUCCESS: {
    pathName: appendUrlPrefix("/dateChangeSucess"),
    pageKey: "dateChangeSucess",
    Component: loadable(
      () =>
        import(
          "@rn/apps/post-sales/src/flights/modules/DateChange/pages/DcReqSubmit"
        ),
      { fallback: <ProgressView /> }
    ),
    isRtl: false,
  },
  FLIGHT_SPECIAL_CLAIM: {
    pathName: appendUrlPrefix("/specialClaimNew"),
    pageKey: "specialClaimNew",
    Component: loadable(
      () =>
        import(
          "@rn/apps/post-sales/src/flights/modules/SpecialClaimNew"
        ),
      { fallback: <ProgressView /> }
    ),
  },
  FLIGHT_SPECIAL_CLAIM_V2: {
    pathName: appendUrlPrefix("/specialClaimV2"),
    pageKey: "specialClaimV2",
    Component: loadable(
      () =>
        import(
          "@rn/apps/post-sales/src/flights/modules/SpecialClaimV2"
        ),
      { fallback: <ProgressView /> }
    ),
  },
  FLIGHT_CANCELLATION_THANK_YOU: {
    pathName: appendUrlPrefix("/flightCancellationThankyou"),
    pageKey: "flightCancellationThankyou",
    Component: loadable(
      () =>
        import(
          "@rn/apps/post-sales/src/flights/modules/Cancellation/pages/Thankyou"
        ),
      { fallback: <ProgressView /> }
    ),
    isRtl: true,
  },
  FLIGHT_CANCELLATION_ERROR: {
    pathName: appendUrlPrefix("/flightCancellationError"),
    pageKey: "cancellationErrorPage",
    Component: loadable(
      () =>
        import(
          "@rn/apps/post-sales/src/flights/cancellation/ErrorCases/CancellationErrorComponent"
        ),
      { fallback: <ProgressView /> }
    ),
    isRtl: true,
  },
  FLIGHT_CANCELLATION_ANYWAY: {
    pathName: appendUrlPrefix("/flightCancellationAnyWay"),
    pageKey: "cancelAnyWayRequest",
    Component: loadable(
      () =>
        import(
          "@rn/apps/post-sales/src/flights/cancellation/CancelAnyWayRequest"
        ),
      { fallback: <ProgressView /> }
    ),
    isRtl: true,
  },
  FLIGHT_CANCELLATION_ANYWAY_THANK_YOU: {
    pathName: appendUrlPrefix("/flightCancellationAnyWayThankyou"),
    pageKey: "cancelAnywaySuccess",
    Component: loadable(
      () =>
        import(
          "@rn/apps/post-sales/src/flights/cancellation/CancelAnyWayRequest/CancelAnywaySuccess"
        ),
      { fallback: <ProgressView /> }
    ),
    isRtl: true,
  },
  FLIGHT_CANCELLATION_POLICY: {
    pathName: appendUrlPrefix("/flightCancellationPolicy"),
    pageKey: "flightCancellationPolicy",
    Component: loadable(
      () =>
        import(
          "@rn/apps/post-sales/src/flights/modules/details/components/CancellationPolicyPage"
        ),
      { fallback: <ProgressView /> }
    ),
    isRtl: true,
  },
  NAME_CHANGE_SELECT_PAX_NEW: {
    pathName: appendUrlPrefix("/flightNameChangePaxSelection"),
    pageKey: "nameChangeSelectPaxNew",
    Component: loadable(
      () =>
        import(
          "@rn/apps/post-sales/src/flights/modules/NameChange/pages/NameChangeSelectPax"
        ),
      { fallback: <ProgressView /> }
    ),
    isRtl: true,
  },
  FLIGHTS_RESCHEDULE_DATE_TIME: {
    pathName: appendUrlPrefix("/flightRescheduleFlightsPage"),
    pageKey: "flightRescheduleFlightsPage",
    Component: loadable(
      () =>
        import(
          "@rn/apps/post-sales/src/flights/modules/DateChange/Redux/PickDates/DateChangeContainer"
        ),
      { fallback: <ProgressView /> }
    ),
    isRtl: false,
  },
  FLIGHT_LISTING: {
    pathName: appendUrlPrefix("/flightListing"),
    pageKey: "flightListing",
    Component: loadable(
      () =>
        import(
          "@rn/apps/post-sales/src/flights/modules/DateChange/Redux/Listing/FlightListingContainer"
        ),
      { fallback: <ProgressView /> }
    ),
    isRtl: false,
  },
  FLIGHTS_DATE_CHANGE_REVIEW_PAGE: {
    pathName: appendUrlPrefix("/flightRescheduleReviewPage"),
    pageKey: "flightRescheduleReviewPage",
    Component: loadable(
      () =>
        import(
          "@rn/apps/post-sales/src/flights/modules/DateChange/pages/ReviewPage"
        ),
      { fallback: <ProgressView /> }
    ),
    isRtl: false,
  },
  FLIGHTS_CORP_DATE_CHANGE_REVIEW_PAGE: {
    pathName: appendUrlPrefix("/corpFlightRescheduleReviewPage"),
    pageKey: "corpFlightRescheduleReviewPage",
    Component: loadable(
      () =>
        import(
          "@rn/apps/post-sales/src/flights/modules/DateChange/pages/CorpReviewPage"
        ),
      { fallback: <ProgressView /> }
    ),
    isRtl: false,
  },
  FLIGHT_SPECIAL_CLAIM_PAX_SELECTION: {
    pathName: appendUrlPrefix("/fltClaimPax"),
    pageKey: "specialClaimSelectPassenger",
    Component: loadable(
      () =>
        import(
          "@rn/apps/post-sales/src/flights/cancellation/components/SpecialClaimSelectPassenger"
        ),
      { fallback: <ProgressView /> }
    ),
    isRtl: true,
  },
  FLIGHT_CLAIM_ANCILLARY_REFUND: {
    pathName: appendUrlPrefix("/claimAncillaryRefund"),
    pageKey: "claimAncillaryRefund",
    Component: loadable(
      () =>
        import(
          "@rn/apps/post-sales/src/flights/cancellation/components/ClaimAncillaryRefund"
        ),
      { fallback: <ProgressView /> }
    ),
    isRtl: true,
  },
  FLIGHT_BOOKING_POLICY_ERROR: {
    pathName: appendUrlPrefix("/flightBookingPolicyError"),
    pageKey: "flightBookingPolicyError",
    Component: loadable(
      () =>
        import(
          "@rn/apps/post-sales/src/flights/modules/details/components/PolicyError/PolicyError"
        ),
      { fallback: <ProgressView /> }
    ),
    isRtl: false,
  },
  FLIGHT_PAX_NAME_CORRECTION_CLOSE_TO_DEPARTURE: {
    pathName: appendUrlPrefix("/flight/nameCorrectionCloseToDeparture"),
    pageKey: "nameChangeCloseToDeparture",
    Component: loadable(
      () => import("@rn/apps/post-sales/src/flights/nameChange/CloseDeparture"),
      { fallback: <ProgressView /> }
    ),
    isRtl: false,
  },
  FLIGHT_PAX_NAME_CORRECTION_THANK_YOU: {
    pathName: appendUrlPrefix("/flight/nameCorrectionThankyou"),
    pageKey: "nameChangeThankYouNew",
    Component: loadable(
      () =>
        import("@rn/apps/post-sales/src/flights/modules/NameChange/pages/NameChangeThankYou"),
      { fallback: <ProgressView /> }
    ),
    isRtl: false,
  },
  AIRLINE_CONTACT_INFO: {
    pathName: appendUrlPrefix("/airlineContact"),
    pageKey: "airlineContactInfo",
    Component: loadable(
      () =>
        import(
          "@rn/apps/post-sales/src/flights/modules/details/components/AirlineContactModal"
        ),
      { fallback: <ProgressView /> }
    ),
    isRtl: false,
  },
  TRIP_PROTECTION_POLICY: {
    pathName: appendUrlPrefix("/tripProtectionPolicy"),
    pageKey: "tripProtectionPolicy",
    Component: loadable(
      () =>
        import(
          "@rn/apps/post-sales/src/flights/modules/details/components/TripProtectionPolicyPage"
        ),
      { fallback: <ProgressView /> }
    ),
    isRtl: false,
  },
  CHECKING_AIRLINE_OVERLAY: {
    pathName: appendUrlPrefix("/checkAirline"),
    pageKey: "autoRefundRouter",
    Component: loadable(
      () =>
        import(
          "@rn/apps/post-sales/src/flights/cancellation/CheckingAirlineOverlay"
        ),
      { fallback: <ProgressView /> }
    ),
    isRtl: true,
  },
  SPECIALCLAIM_REVIEW_DETAILS: {
    pathName: appendUrlPrefix("/claimReview"),
    pageKey: "specialClaimReviewDetails",
    Component: loadable(
      () =>
        import(
          "@rn/apps/post-sales/src/flights/cancellation/components/SpecialClaimReviewDetails"
        ),
      { fallback: <ProgressView /> }
    ),
    isRtl: true,
  },
  SPECIAL_CLAIM_THANKYOU: {
    pathName: appendUrlPrefix("/splClmThanku"),
    pageKey: "specialClaimRequestThankYou",
    Component: loadable(
      () =>
        import(
          "@rn/apps/post-sales/src/flights/cancellation/components/SpecialClaimRequestThankyou"
        ),
      { fallback: <ProgressView /> }
    ),
    isRtl: true,
  },

  FLIGHT_FALSE_SPECIAL_CLAIM: {
    pathName: appendUrlPrefix("/flight/falseSpecialClaim"),
    pageKey: "falseClaim",
    Component: loadable(
      () =>
        import(
          "@rn/apps/post-sales/src/flights/cancellation/components/FalseClaim"
        ),
      { fallback: <ProgressView /> }
    ),
    isRtl: true,
    backRoute: FLIGHT_ROUTE_URLS.DETAIL_PAGE,
  },
  BAGGAGE_PROTECTION_POLICY: {
    pathName: appendUrlPrefix("/baggageProtectionPolicy"),
    pageKey: "baggageProtectionPolicy",
    Component: loadable(
      () =>
        import(
          "@rn/apps/post-sales/src/flights/modules/details/components/BaggageProtectionPolicyPage"
        ),
      { fallback: <ProgressView /> }
    ),
    isRtl: false,
  },
  FLIGHT_CANCELLATION_REFUND_MODE: {
    pathName: appendUrlPrefix("/flightCancellationRefundMode"),
    pageKey: "flightCancellationRefundMode",
    Component: loadable(
      () =>
        import(
          "@rn/apps/post-sales/src/flights/modules/Cancellation/pages/SelectRefundMode"
        ),
      { fallback: <ProgressView /> }
    ),
    isRtl: true,
  },
  SEATSELECT: {
    pathName: appendUrlPrefix("/seatSelect"),
    pageKey: "",
    isFeatureNotSupported: true,
    isRtl: false,
  },
  CANCELLATION_REFUND_DETAILS: {
    pathName: appendUrlPrefix("/viewBreakup"),
    pageKey: "",
    Component: loadable(
      () =>
        import(
          "@rn/apps/post-sales/src/flights/cancellation/CancellationRefundDtls"
        ),
      { fallback: <ProgressView /> }
    ),
    isRtl: true,
  },
  SPECIAL_CLAIM_REFUND_MODE: {
    pathName: appendUrlPrefix("/flight/specialClaim/RefundMode"),
    pageKey: "selectRefundPaymentMode",
    Component: loadable(
      () =>
        import(
          "@rn/apps/post-sales/src/flights/cancellation/components/SelectRefundPaymentMode"
        ),
      { fallback: <ProgressView /> }
    ),
    isRtl: true,
  },
  FLIGHTS_RESCHEDULE_CHARGE: {
    pathName: appendUrlPrefix("/flight/dateChange/rules"),
    pageKey: "flightRescheduleChargePage",
    Component: loadable(
      () =>
        import(
          "@rn/apps/post-sales/src/flights/modules/DateChange/pages/RescheduleCharges/DateChangeRescheduleCharges"
        ),
      { fallback: <ProgressView /> }
    ),
    isRtl: false,
  },
  CORP_FLIGHT_ODC_APPROVAL: {
    pathName: appendUrlPrefix("/CorpFlightDateChangeStatus"),
    pageKey: "corpFlightApproval",
    Component: loadable(
      () =>
        import(
          "@rn/apps/post-sales/src/flights/modules/DateChange/pages/CorpReviewPage"
        ),
      { fallback: <ProgressView /> }
    ),
    isRtl: false,
  },

  LAST_MINUTE_CANCELLATION: {
    pathName: appendUrlPrefix("/lastMinuteCancellation"),
    pageKey: "lastMinuteCancellation",
    Component: loadable(
      () =>
        require("@rn/apps/post-sales/src/flights/modules/Cancellation/pages/lastMinuteCancellation"),
      { fallback: <ProgressView /> }
    ),
    isRtl: false,
  },
  ERROR_IN_CANCELLATION: {
    pathName: appendUrlPrefix("/errorInLastMinuteCancellation"),
    pageKey: "errorInLastMinuteCancellation",
    Component: loadable(
      () =>
        require("@rn/apps/post-sales/src/flights/modules/Cancellation/pages/lastMinuteCancellation/components/lastMinuteFailure"),
      { fallback: <ProgressView /> }
    ),
    isRtl: false,
  },
  OPEN_ANCILLARIES: {
    pathName: appendUrlPrefix("/openAncillary"),
    pageKey: "openAncillary",
    Component: loadable(() => import("../../Modules/AncillaryModule"), {
      fallback: <ProgressView />,
    }),
    isRtl: false,
  },
  ANCILLARY_THANK_YOUA: {
    pathName: appendUrlPrefix("/ancillaryThankyou"),
    pageKey: "ancillarySimpleThankyou",
    Component: loadable(
      () =>
        import("../../Modules/AncillaryModule/thankYouPage"),
      {
        fallback: <ProgressView />,
      }
    ),
    isRtl: false,
  },
  TRAVEL_ISURANCE_THANK_YOU: {
    pathName: appendUrlPrefix("/travelInsuranceThankYou"),
    pageKey: "travelInsuranceThankYou",
    Component: loadable(
      () =>
        import("@rn/apps/post-sales/src/flights/modules/TravelInsurance/pages/ThankyouPage"),
      {
        fallback: <ProgressView />,
      }
    ),
    isRtl: false,
  },
  PART_PAYMENT_THANK_YOU: {
    pathName: appendUrlPrefix("/PartPaymentStatus"),
    pageKey: "partPaymentThankYou",
    Component: loadable(
      () =>
        import("@rn/apps/post-sales/src/flights/modules/PartPaymentTYPage"),
      {
        fallback: <ProgressView />,
      }
    ),
    isRtl: false,
  },
  FLIGHT_THANK_YOU_PAGE: {
    pathName: appendUrlPrefix("/flightThankYou"),
    pageKey: "flightThankYou",
    Component: loadable(
      () =>
        import("@rn/apps/post-sales/src/flights/modules/ThankYouPage"),
      {
        fallback: <ProgressView />,
      }
    ),
    isRtl: false,
  },
};

export default FLIGHTS_PATHS;