import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { colors, fonts } from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';


const TCSInfoBottomSheet = ({handleBottomOverlay, overlayContent}) => {

    const renderTCSList = () => {
        return(
            overlayContent && overlayContent.messages.map((list,index) => {
                const isLastItem = index === overlayContent.messages.length-1 ? "lastItemStyle":''
                return (
                    <View style={[styles.listSectionRow,styles[isLastItem]]} key={index}>
                        <View style={styles.countWrapper}>
                            <Text style={styles.countTxt}>{index}</Text>
                        </View>
                        <Text style={[styles.font14, AtomicCss.defaultText, AtomicCss.regularFont, AtomicCss.flex1]}>{list}</Text>
                    </View>
                )
            })
        )
    }


    return (
        <View style={styles.contentWrpper}>
            <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom15]}>
                <Text style={styles.title}>{overlayContent.heading}</Text>
            </View>
            <Text style={[AtomicCss.font14, AtomicCss.boldFont, AtomicCss.defaultText, AtomicCss.marginBottom26]}>{overlayContent.detailText}</Text>
            <View style={styles.listSection}>
                {renderTCSList()}
            </View>
            <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween, AtomicCss.alignSelfFlexEnd]}>
                <TouchableOpacity onPress={()=>handleBottomOverlay('')}>
                    <Text style={[AtomicCss.azure, AtomicCss.boldFont, AtomicCss.font16, AtomicCss.alignRight]} >{overlayContent.actionText}</Text>
                </TouchableOpacity>

            </View>
        </View>

    );
}

const styles = StyleSheet.create({
    contentWrpper: {
        paddingVertical: 10,
    },
    failedImgStyle: {
        width: 60,
        height: 35,
        marginRight: 10,
    },
    title: {
        fontFamily: fonts.black,
        color: colors.black,
        fontSize: 20,
    },
    listSection: {
        marginBottom: 30,
        paddingLeft: 20,
    },
    listSectionRow: {
        borderLeftColor: colors.grey8,
        paddingLeft: 28,
        borderLeftWidth: 2,
        flexDirection: 'row',
        paddingBottom: 20,
    },
    lastItemStyle:{
        paddingBottom: 10,
        borderLeftColor: 'transparent',
        borderLeftWidth:0,
    },
    countWrapper: {
        backgroundColor: colors.white,
        width: 32,
        height: 32,
        borderRadius: 6,
        alignItems: 'center',
        justifyContent: 'center',
        ...getPlatformElevation(2),
        position: 'absolute',
        marginLeft: -17,

    },
    countTxt: {
        color: colors.greyLight,
        fontSize: 14,
        fontFamily: fonts.regular
    },
})
export default TCSInfoBottomSheet;