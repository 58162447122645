import React from 'react';
import { StyleSheet, StyleProp, TextStyle, ViewStyle } from 'react-native';
import Button from '@Frontend_Ui_Lib_App/Button';
import Spinner from '@Frontend_Ui_Lib_App/Spinner';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { Fonts, Theme } from 'apps/post-sales/src/cab/types';

interface ButtonWprProps {
  buttonText: string;
  buttonSize?: string;
  buttonType?: string;
  buttonWidth?: string;
  endIcon?: string;
  buttonBgColors?: string[];
  btnWrpStyle?: StyleProp<ViewStyle>;
  onButtonPress: () => void;
  btnTxtStyle?: StyleProp<TextStyle>;
  btnStyle?: StyleProp<ViewStyle>;
	showBtnLoader?: boolean;
  loaderSize?: number;
}

const ButtonWpr: React.FC<ButtonWprProps> = ({
  buttonText,
  buttonSize,
  buttonType,
  buttonWidth,
  endIcon,
  buttonBgColors,
  btnWrpStyle,
  onButtonPress,
  btnTxtStyle,
  btnStyle,
	showBtnLoader,
  loaderSize=20
}) => {
  const { psTheme: theme } = useTheme();
  const fonts = getFont();
  const styles = getStyle(fonts, theme);
  return (
    <Button
      buttonBgColors={
        buttonBgColors ? buttonBgColors : [theme.color.lightBlue, theme.color.darkBlue]
      }
      buttonSize={buttonSize ? buttonSize : 'md'}
      buttonText={buttonText}
      buttonType={buttonType ? buttonType : 'fill'}
      buttonWidth={buttonWidth ? buttonWidth : '100%'}
      customStyle={{
        buttonWrapperStyle: [btnWrpStyle ? btnWrpStyle : {}],
        buttonTextStyle: [styles.btnInlineStyle, btnTxtStyle ? btnTxtStyle : {}],
        buttonStyle: [btnStyle ? btnStyle : {}],
      }}
      // endIcon={endIcon ? endIcon : ''}    // not working on mWeb
      onButtonPress={onButtonPress}
      useAngle
			disabled={showBtnLoader}
			disabledButtonBgColors={[theme.color.lightBlue, theme.color.darkBlue]}
    >
			{showBtnLoader && <Spinner
				size={loaderSize}
				strokeWidth={2}
				progressPercent={90}
				speed={1.5}
				color="#FFFFFF"
			/>}
		</Button>
  );
};

const getStyle = (fonts: Fonts, theme: Theme) =>
  StyleSheet.create({
    btnInlineStyle: {
      ...fonts.boldFontFamily,
      fontSize: fonts.fontSize.font16,
    },
  });

export default ButtonWpr;