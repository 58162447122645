import React from 'react';
import { View, StyleSheet } from 'react-native';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';

const CustomCardImage = () => {
  const { psTheme } = useTheme();
  const { color } = psTheme;
  const styles = getStyles(color);
  return (
    <View style={styles.square} />
  );
};

interface Styles {
  square: {};
}

const getStyles = (color: string): Styles => {
  return StyleSheet.create<Styles>({
    square: {
      height: 18,
      width: 18,
      backgroundColor: '#EEEEEE',
      borderRadius: 3,
      marginRight: 5
    }
  });
};

export default CustomCardImage;