import { Platform } from 'react-native';
import { isEmpty, isNil } from 'lodash';
import { fillDateAndTime } from '@mmt/legacy-commons/Common/utils/DateUtils';
import {
  PROP_1,
  PROP_24,
  EVAR_24,
  trackOmniture,
} from '@mmt/legacy-commons/Common/utils/OmnitureTrackerUtils';
import {
  isBaggageApplicable,
  isMealApplicable,
  isSeatApplicable,
  shouldShowChangeInPlanCard,
  showAirlineContact,
} from '../../../FlightBookingUtil';
import { isCorporateBooking, isMyraEnabled } from '../../../../utils/PostSaleUtil';
import FlightBookingDetailsConstant from '../FlightBookingDetailsConstant';
import FlightEventsLogger from './FlightEventsLogger';
import {
  _trackSegmentData,
  _getJourneyType,
  _trackFltBookingDate,
} from '../../../FlightOmnitureTrackerUtils';
import { getStaticData } from '../../../../staticData/staticData';
import { CURRENCY_DETAILS, OMNITURE_COMPANY_DETAILS } from '../../../../PostSalesConstant';

class FlightBookingTrackingHelper {
  trackFlightBookingDetailLoadEvent = (
    pageName,
    response,
    segmentAncillaryDetails,
    holidayBookingApi,
    cmp,
  ) => {
    try {
      FlightEventsLogger.loadEvent(pageName, response);
      const trackingPageName = this.getPageName(pageName, response, holidayBookingApi);
      const params = this.getTrackingOmniture(trackingPageName, response);
      if (!holidayBookingApi) {
        params.m_c74 = this.getCtaShownToUser(response, segmentAncillaryDetails);
        params.m_c1 = this.getInsuranceCardShown(response);
      }
      if (cmp) {
        params.m_v81 = cmp;
      }
      trackOmniture(trackingPageName, params, true);
    } catch (e) {
      console.log(e);
    }
  };

  trackLoadEvent = (pageName, response, m_v87 = undefined) => {
    try {
      FlightEventsLogger.loadEvent(pageName, response);
      const trackingPageName = this.getPageName(pageName, response);
      trackOmniture(
        trackingPageName,
        this.getTrackingOmniture(trackingPageName, response, m_v87),
        true,
      );
    } catch (e) {
      console.log(e);
    }
  };

  getPageName = (pageName, response, holidayBookingApi = false) => {
    try {
      let lobName = holidayBookingApi ? 'Holidays' : 'Flight';
      let trackingPageName = this.isIntlFlightBooking(response)
        ? `Mob:customer support:Intl ${lobName} : ${pageName}`
        : `Mob:customer support:Dom ${lobName} : ${pageName}`;
      if (isCorporateBooking(response.lobCode)) {
        trackingPageName += '_Corporate';
      } else if (response.myPartnerBooking) {
        trackingPageName += '_myPartner';
      } else if (response.bookingID && response.bookingID.startsWith('NF3')) {
        trackingPageName += '_Personal_PhonePe';
      } else if (response.bookingID && response.bookingID.startsWith('NF6')) {
        trackingPageName += '_Personal_Flipkart';
      } else if (response.ctaInfo) {
        trackingPageName += '_CTA';
      } else {
        trackingPageName += '_Personal';
      }
      return trackingPageName;
    } catch (error) {}
  };

  trackErrorWithData = (pageName, bookingId, data, supplierName) => {
    try {
      FlightEventsLogger.errorEvent(pageName, { bookingId, error: data });
      const params = this.getErrorParams(pageName, bookingId, data, supplierName);
      trackOmniture(pageName, params);
    } catch (e) {
      console.error(e);
    }
  };

  getErrorParams = (pageName, bookingId, data, supplierName) => {
    try {
      const params = {};
      params.pageName = pageName;
      params.m_v15 = pageName;
      if (bookingId) {
        params.m_c22 = `${bookingId}_${data}`;
      } else {
        params.m_c22 = data;
      }
      if (supplierName) {
        params.m_c40 = supplierName;
      }
      return params;
    } catch (error) {}
  };

  trackErrorForDateChangeNotSupported = (response, pageName, errorReason, supplierName) => {
    try {
      FlightEventsLogger.errorEvent(pageName, { error: errorReason });
      const params = this.getErrorParams(pageName, undefined, errorReason, supplierName);
      params.m_v40 = this._getAirlineName(response);
      trackOmniture(pageName, params);
    } catch (e) {
      console.error(e);
    }
  };

  trackAddOnShownCard = (pageName, eventName, bookingDetailRes) => {
    try {
      const params = {};
      params.pageName = this.getPageName(pageName, bookingDetailRes);
      params.m_c54 = eventName;
      trackOmniture(pageName, params);
    } catch (e) {
      console.error(e);
    }
  };

  trackCabShownCard = (pageName) => {
    try {
      const params = {};
      params.pageName = pageName;
      params.m_c54 = 'Cab_Cross_Sell_Card_Shown';
      trackOmniture(pageName, params);
    } catch (e) {
      console.error(e);
    }
  };

  trackCabClickedCard = (pageName) => {
    try {
      const params = {};
      params.pageName = pageName;
      params.m_c54 = 'Cab_Cross_Sell_Clicked';
      trackOmniture(pageName, params);
    } catch (e) {
      console.error(e);
    }
  };

  trackClickEvent = (pageName, clickEvent, response, bookingId, ticketId, evar20 = null, extraParams = {}) => {
    try {
      FlightEventsLogger.clickEvent(pageName, clickEvent, response);
      const params = {...extraParams};
      if (ticketId) {
        params.m_v81 = Platform.select({ android: 'ANDROID', ios: 'IOS', web: 'PWA' });
        params.m_c39 = ticketId;
        params.m_v16 = bookingId;
        params.m_c40 = 'MIMA_DETAIL';
        params.m_c20 = 'MIMA_DETAIL';
        params.m_c42 = 'CUSTOMER';
        params.m_c43 = 'Success';
        if (pageName === 'mytrips') {
          params.m_c40 = 'MIMA_LISTING';
          params.m_c20 = 'MIMA_LISTING';
        }
      }
      if (evar20){
        params.m_c20 = evar20;
      }
      if (response) {
        params.m_c54 = this.isIntlFlightBooking(response)
          ? `MI_detail_Intl_${clickEvent}`
          : `MI_detail_Dom_${clickEvent}`;
        params.m_c22 = response.ruleTrace || '';
        trackOmniture(this.getPageName(pageName, response), params);
      } else {
        params.m_c54 = clickEvent;
        trackOmniture(pageName, params);
      }
    } catch (e) {
      console.error(e);
    }
  };

  trackClickEventForCYT = (pageName, clickEvent, response, cytExperimentValue = null) => {
    try {
      FlightEventsLogger.clickEvent(pageName, clickEvent, response);
      const params = {};
      if (!isNil(cytExperimentValue)) {
        params.m_c1 = `CYT_experiment_flag_${cytExperimentValue}`;
      }
      if (response) {
        params.m_c54 = this.isIntlFlightBooking(response)
          ? `MI_detail_Intl_${clickEvent}`
          : `MI_detail_Dom_${clickEvent}`;
        params.m_c22 = response.ruleTrace || '';
        trackOmniture(this.getPageName(pageName, response), params);
      } else {
        params.m_c54 = clickEvent;
        trackOmniture(pageName, params);
      }
    } catch (e) {
      console.error(e);
    }
  };

  /* As all new click events or particular card wil be captured in prop1 now */
  trackClickEventNew = (pageName, clickEvent, response) => {
    try {
      FlightEventsLogger.clickEvent(pageName, clickEvent, response);
      const params = {};
      if (response) {
        params.m_c1 = this.isIntlFlightBooking(response)
          ? `MI_detail_Intl_${clickEvent}`
          : `MI_detail_Dom_${clickEvent}`;
        params.m_c22 = response.ruleTrace || '';
        trackOmniture(this.getPageName(pageName, response), params);
      } else {
        params.m_c1 = clickEvent;
        trackOmniture(pageName, params);
      }
    } catch (e) {
      console.error(e);
    }
  };

  trackNameCorrectionBtnClick = (pageName, clickEvent, response) => {
    try {
      const params = {};
      if (response) {
        params.m_c54 = this.isIntlFlightBooking(response)
          ? `MI_Special Request_Intl_${clickEvent}`
          : `MI_Special Request_Domestic_${clickEvent}`;
        trackOmniture(this.getPageName(pageName, response), params);
      } else {
        params.m_c54 = clickEvent;
        trackOmniture(pageName, params);
      }
    } catch (e) {
      console.error(e);
    }
  };


  trackWheelChairClickEvent = (pageName, clickEvent, response) => {
    try{
      FlightEventsLogger.clickEvent(pageName, clickEvent, response);
      let trackingPageName = this.getPageName(pageName,response);
      trackingPageName += `_${clickEvent}`
      const params = this.getTrackingOmniture(trackingPageName,response);
      params.m_v81 = Platform.select({ android: 'ANDROID', ios: 'IOS', web: 'PWA' });
      params.m_c54 = `${clickEvent}_Clicked`;
      trackOmniture(trackingPageName,params);
    }catch(e){
      console.log("error in omniture wheelchair tracking event: ", e);
    }
  }

  trackWheelChairLoadEvent = (pageName, eventName, response, errorMsg = '') => {
    try{
      FlightEventsLogger.loadEvent(eventName, response);
      let trackingPageName = this.getPageName(pageName,response);
      trackingPageName += `_${eventName}`
      const params = this.getTrackingOmniture(trackingPageName,response);
      params.m_v81 = Platform.select({ android: 'ANDROID', ios: 'IOS', web: 'PWA' });
      params.m_c22 = errorMsg;
      trackOmniture(trackingPageName,params);
    }catch(e){
      console.log("error in omniture wheelchair tracking event: ", e);
    }
  }

  trackingNewClickEvent = (pageName, clickEvent, response, extraParams = {}) => {
    try{
      FlightEventsLogger.clickEvent(pageName, clickEvent, response);
      let trackingPageName = `${this.getPageName(pageName,response)}_${clickEvent}`;
      const params = this.getTrackingOmniture(trackingPageName,response);
      params.m_v81 = Platform.select({ android: 'ANDROID', ios: 'IOS', web: 'PWA' });
      params.m_c54 = `${clickEvent}_Clicked`;
      let newParams = { ...params, ...extraParams }
      trackOmniture(trackingPageName,newParams);
    }catch(e){
      console.log("error in omniture wheelchair tracking event: ", e);
    }
  }

  getTrackingOmniture(pageName, response, m_87 = undefined) {
    const params = {};
    params.m_v15 = pageName;
    params.pageName = pageName;
    if (m_87) {
      params.m_v87 = m_87;
    }
    if (isEmpty(response)) {
      return params;
    }
    const segInfo =
      response && response.newFlightDetails
        ? _trackSegmentData(response.newFlightDetails.segmentGroupDetailList)
        : {};
    params.m_v3 = segInfo.bookingIntent;
    params.m_v7 = FlightBookingTrackingHelper.getDateDiff(response.bookingDateTime, new Date());
    params.m_v6 = segInfo.travelDateGap;
    params.m_v16 = response.bookingID;
    params.m_v9 =
      response && response.newFlightDetails
        ? _getJourneyType(response.newFlightDetails.journeyType)
        : '';
    params.m_v14 = this._getBookingState(response);
    params.m_v40 = segInfo.airlineCode;
    params.m_v21 = segInfo.adultCount;
    params.m_v24 = this.isIntlFlightBooking(response) ? 'mob intl flights' : 'mob domestic flights';
    params.m_v11 = this._getBookingAmount(response);
    params.m_v87 = m_87
      ? m_87
      : shouldShowChangeInPlanCard(response)
      ? 'Changeplan'
      : 'canceldateclaim';
    params.m_v36 = FlightBookingTrackingHelper.getPaymentType(response);
    params.m_v13 = FlightBookingTrackingHelper.getCancellationRequestNo(response)
      ? 'Refund Card_Yes'
      : 'Refund Card_No';
    if (Platform.OS === 'web') {
      params.m_v37 = FlightBookingTrackingHelper.getGccBookingInfo(response);
    }
    params.m_c22 = response && response.ruleTrace ? response.ruleTrace : '';
    params.m_v5 =
      response && response.bookingDateTime
        ? _trackFltBookingDate(response.bookingDateTime.split('T')[0])
        : '';
    params.m_v4 = segInfo.depDate;
    params.m_v67 = response && response.myPartnerBooking ? response.myPartnerAgentId || '' : '';
    return params;
  }

  getPaxInfo = (passengerList) => {
    let adultCount = 0;
    let childCount = 0;
    let infantCount = 0;
    if (!isEmpty(passengerList)) {
      passengerList.forEach((passenger) => {
        switch (passenger.paxType) {
          case 0:
            adultCount += 1;
            break;
          case 1:
            childCount += 1;
            break;
          case 2:
            infantCount += 1;
            break;
          default:
            break;
        }
      });
    }
    let result = '';
    if (adultCount > 0) {
      result += adultCount;
    }
    if (childCount > 0) {
      result += `|${childCount}`;
    }
    if (infantCount > 0) {
      result += `|${infantCount}`;
    }
    return result;
  };

  _getBookingState = (response) => {
    let tmp = response.bookingState ? response.bookingState.state : '';
    if (response.isMMTPrivilegeEligible) {
      tmp = `${tmp} |DB`;
    }
    if (response.isEligibleForZeroCancellation) {
      tmp = `${tmp} |ZC`;
    }
    return tmp;
  };

  _getJourneyType = (response) => {
    const { newFlightDetails } = response;
    if (newFlightDetails && newFlightDetails.journeyType) {
      switch (newFlightDetails.journeyType) {
        case 1:
          return 'Multi';
        case 2:
          return 'RT';
        case 3:
          return 'OW';
        default:
          return '';
      }
    }

    return '';
  };

  isIntlFlightBooking = (response) => response && response.isInternational;

  _getBookingIntentDetail = (response) => {
    if (response.newFlightDetails) {
      return response.newFlightDetails.bookingIntentDetail;
    }
    return '';
  };

  _getBookingAmount = (response) => {
    const {
      flightPaymentPriceBreakUpText: {
        items: {
          fareBreakUpText: { totalAmountText },
        },
      },
    } = getStaticData();
    const amtItem = response.fareBreakupNodeList.find((item) => item.name === totalAmountText);
    return amtItem.amt;
  };

  _getAirlineName = (response) => {
    const { newFlightDetails } = response;
    let airlineName = '';
    if (newFlightDetails && !isEmpty(newFlightDetails.segmentGroupDetailList)) {
      newFlightDetails.segmentGroupDetailList.forEach((segmentGroup) => {
        segmentGroup.segmentDetails.forEach((segment) => {
          if (segment.flightDesignator) {
            airlineName += `${segment.flightDesignator.flightName} | `;
          }
        });
      });
    }
    return airlineName;
  };

  getDateTimeToTravel = (response) => {
    let result = '';
    if (
      response.newFlightDetails &&
      !isEmpty(response.newFlightDetails.segmentGroupDetailList)
    ) {
      response.newFlightDetails.segmentGroupDetailList.forEach((segmentGroup) => {
        if (!isEmpty(segmentGroup.segmentDetails)) {
          segmentGroup.segmentDetails.forEach((segment) => {
            result += `${FlightBookingTrackingHelper.findDiffDaysBetweenDates(
              segment.travelDateTime,
              new Date(),
            )}|`;
          });
        }
      });
    }
    return result;
  };

  static getDateDiff(date1, date2) {
    return `${FlightBookingTrackingHelper.findDiffDaysBetweenDates(date1, date2)} Days`;
  }

  static findDiffDaysBetweenDates(firstDate, secondDate) {
    const oneDay = 24 * 60 * 60 * 1000;
    const FirstDateObj = new Date(fillDateAndTime(firstDate, 'YYYY-MM-DD'));
    const secondDateObj = new Date(fillDateAndTime(secondDate, 'YYYY-MM-DD'));
    return Math.abs((secondDateObj.getTime() - FirstDateObj.getTime()) / oneDay);
  }

  static getPaymentType(response) {
    if (response.paymentDetails && response.paymentDetails.bnplbooking) {
      return 'BNPL';
    }
    if (
      response.bookingState &&
      response.bookingState.subState &&
      response.bookingState.subState.toUpperCase() === 'CTRIP_UNCONFIRMED'
    ) {
      return 'CTRIP';
    }
    return 'NORMAL';
  }

  static getCancellationRequestNo(response) {
    let result;
    if (!isEmpty(response.cancellationDetailList)) {
      result = response.cancellationDetailList.find(
        (cancellationDetail) =>
          cancellationDetail.requestType === 'Cancellation' ||
          cancellationDetail.requestType === 'Claim',
      );
    }
    return result;
  }

  static getGccBookingInfo(response) {
    let sbuValue = '';
    let countryValue = '';
    let currencyValue = '';
    const { currency } = response;
    const companyOmitureDetail = OMNITURE_COMPANY_DETAILS[response.companyName];
    sbuValue += (companyOmitureDetail && companyOmitureDetail.countryValue) || 'india';
    countryValue = (companyOmitureDetail && companyOmitureDetail.countryValue) || 'in';
    currencyValue = CURRENCY_DETAILS[currency] || currency;
    return `domain_sbu:${sbuValue}|domain_country:${countryValue}|domain_currency:${currencyValue}|domain_language:English`;
  }

  getCtaShownToUser = (response, segmentAncillaryDetails) => {
    const { cardList } = response.layoutDetail;
    let result = '';
    if (response.exchangeFlightCardInfo) {
      result += this.getODCExchangeBtn(response);
    }

    result += this.getTopCardButtonData(response.bookingState.state.toUpperCase());

    if (segmentAncillaryDetails) {
      result += this.getAncillaryBtnData(
        response.newFlightDetails.segmentGroupDetailList,
        segmentAncillaryDetails,
      );
    }

    cardList.forEach((card) => {
      // eslint-disable-next-line default-case
      switch (card.cardId) {
        case FlightBookingDetailsConstant.CANCEL_DETAIL_INFO_CARD_OR_CHANGE_IN_PLAN:
          if (!shouldShowChangeInPlanCard(response)) {
            if (
              response.cancelCardDetailInfo &&
              !isEmpty(response.cancelCardDetailInfo.actionItemInfoList)
            ) {
              result += this.getCancellationCardCta(
                response.cancelCardDetailInfo.actionItemInfoList,
              );
            }
          }
          break;
        case FlightBookingDetailsConstant.CANCEL_DETAIL_INFO_CARD:
          if (response.cancelCardDetailInfo) {
            result += this.getCancellationCardCta(response.cancelCardDetailInfo.actionItemList);
          }
          break;
        case FlightBookingDetailsConstant.DATE_CHANGE_DETAIL_INFO_CARD:
          result += this.getDateChangeBtnInfo(response);
          break;
        case FlightBookingDetailsConstant.DATE_CHANGE_DETAIL_INFO_CARD_OR_CHANGE_IN_PLAN:
          if (!shouldShowChangeInPlanCard(response)) {
            result += this.getDateChangeBtnInfo(response);
          }
          break;
        case FlightBookingDetailsConstant.IMP_INFORMATION_CARD:
          if (!isEmpty(response.impInfo)) {
            if (response.impInfo.some((impInfoObj) => impInfoObj.clickableTextId === 'WEB_FORM')) {
              result += 'WF|';
            }
          }
          break;
        case FlightBookingDetailsConstant.SPECIAL_REQUEST_CARD:
          if (response.specialRequestCardDetailInfo) {
            const { actionItemInfoList } = response.specialRequestCardDetailInfo;
            if (
              !isEmpty(actionItemInfoList) &&
              actionItemInfoList.some((item) => item.actionId === 'NAME_CHANGE')
            ) {
              result += 'NC|';
            }
          }
          break;
        case FlightBookingDetailsConstant.SUPPORT_CARD:
          {
            const { isGccBooking, showMyraBtn } = response;
            const showMyra = showMyraBtn && !isGccBooking && isMyraEnabled(response.lobCode);
            if (showMyra || showAirlineContact(response) || isCorporateBooking(response.lobCode)) {
              result += 'CI|';
            }
          }
          break;
      }
    });
    result = result + this.getStatusValue(response);
    return result;
  };

  getInsuranceCardShown = (response) => {
    const insuranceDetails = response ? response.insuranceDetails : null;
    let result=''
    if (
      insuranceDetails &&
      insuranceDetails.provider === 'DIGIT' &&
      insuranceDetails.tripProtectCtaList &&
      insuranceDetails.tripProtectCtaList.length > 0 &&
      insuranceDetails.tripProtectCtaList.filter((item) => item.text === 'File Insurance Claim')
        .length > 0
    ) {
      result = 'Insurance_Claim_Shown';
    } else if (response && response.isInsuranceApplicable) {
      result = 'Trvl_insurance_displayed';
    }

    if(!!response && response.cfarApplicable){
      result+= !!result ? ' | CFAR_displayed' : 'CFAR_displayed';
    }
    return result;
  };

  getODCExchangeBtn = (response) => {
    let result = '';
    const { eligiblePnrExchangeInfoList } = response.exchangeFlightCardInfo;
    if (!isEmpty(eligiblePnrExchangeInfoList)) {
      let buttonNumber = 1;
      eligiblePnrExchangeInfoList.forEach((item) => {
        if (!isEmpty(item.ctaList)) {
          if (item.ctaList.some((cta) => cta.actionType === 'VIEW_NEW_FLIGHTS')) {
            result += `ODE${buttonNumber}|`;
            buttonNumber += 1;
          }
        }
      });
    }
    return result;
  };

  getDateChangeBtnInfo = (response) => {
    if (response.datechangeCacheDetail && response.datechangeCacheDetail.dateChangeCards) {
      const { datechangeCacheDetail } = response;
      const addDateChangeBtn = Object.keys(datechangeCacheDetail.dateChangeCards).some(
        (dateChangeActionItemKey) => {
          const value = datechangeCacheDetail.dateChangeCards[dateChangeActionItemKey];
          return !!(value && dateChangeActionItemKey === 'ACT_RES_FLIGHT');
        },
      );
      if (addDateChangeBtn) {
        return 'CTD|';
      }
    }
    return '';
  };

  getTopCardButtonData = (bookingState, showDownloadInvoice) => {
    // eslint-disable-next-line default-case
    switch (bookingState) {
      case FlightBookingDetailsConstant.UPCOMING:
      case FlightBookingDetailsConstant.ONTRIP:
        return 'DT|';
      case FlightBookingDetailsConstant.COMPLETED:
        if (showDownloadInvoice) {
          return 'DI|';
        }
    }
    return '';
  };

  getAncillaryBtnData = (segmentGroupDetailList, segmentAncillaryDetails) => {
    let result = '';
    let seatBtn = '';
    let seatBtnNo = 1;
    let mealBtn = '';
    let mealBtnNo = 1;
    let baggageBtn = '';
    let baggageBtnNo = 1;
    let webCheckInBtn = '';
    let webCheckInBtnNo = 1;
    segmentGroupDetailList.forEach((segmentFlightDetails) => {
      if (!isEmpty(segmentFlightDetails.segmentDetails)) {
        const { segmentLineNo } = segmentFlightDetails.segmentDetails[0];
        if (isSeatApplicable(segmentAncillaryDetails, segmentLineNo)) {
          seatBtn += `AS${seatBtnNo}|`;
          seatBtnNo += 1;
        }
        if (isMealApplicable(segmentAncillaryDetails, segmentLineNo)) {
          mealBtn += `AM${mealBtnNo}|`;
          mealBtnNo += 1;
        }
        if (isBaggageApplicable(segmentAncillaryDetails, segmentLineNo)) {
          baggageBtn += `AB${baggageBtnNo}|`;
          baggageBtnNo += 1;
        }
        segmentFlightDetails.segmentDetails.forEach((segmentDetail) => {
          if (
            !isEmpty(segmentDetail.webCheckinInfo) &&
            segmentDetail.webCheckinInfo.action &&
            segmentDetail.webCheckinInfo.action.text &&
            segmentDetail.webCheckinInfo.action.type === 'WEBCHECKIN'
          ) {
            webCheckInBtn += `WC${webCheckInBtnNo}|`;
            webCheckInBtnNo += 1;
          }
        });
      }
    });
    if (!isEmpty(seatBtn)) {
      result += seatBtn;
    }
    if (!isEmpty(mealBtn)) {
      result += mealBtn;
    }
    if (!isEmpty(baggageBtn)) {
      result += baggageBtn;
    }
    if (!isEmpty(webCheckInBtn)) {
      result += webCheckInBtn;
    }
    return result;
  };

  getCancellationCardCta = (actionItemList) => {
    let cancellatonCardCta = '';
    let partialSegmentNo = 1;
    actionItemList.forEach((actionItem) => {
      switch (actionItem.actionId) {
        case 'CANCEL_FLT_FULL':
          cancellatonCardCta += 'FC|';
          break;
        case 'CANCEL_FLT_PARTIAL':
          cancellatonCardCta += 'CST|';
          break;
        case 'CANCEL_FLT_PARTIAL_SEGGRP':
          cancellatonCardCta += `C${partialSegmentNo}|`;
          partialSegmentNo += 1;
          break;
        case 'CANCEL_CHRGS':
          cancellatonCardCta += 'CC|';
          break;
        case 'SPECIAL_CLAIM':
          cancellatonCardCta += 'SC|';
          break;
        default:
          break;
      }
    });
    return cancellatonCardCta;
  };

  getStatusValue = (response) => {
    let status = '';
    if (response.newFlightDetails && response.newFlightDetails.segmentGroupDetailList) {
      const { segmentGroupDetailList } = response.newFlightDetails;
      segmentGroupDetailList.forEach((segDet) => {
        segDet.segmentDetails.forEach((seg) => {
          switch (seg.bookingSegmentStatus) {
            case 'CANCELLED':
              status += 'DSC|';
              break;
            case 'SEAT_NOT_ATTACHED':
              status += 'DSC|';
              break;
            case 'DEFAULT_CHANGE':
              status += 'DSC|';
              break;
            case 'SCHEDULE_CHANGE':
              status += 'DSM|';
              break;
            case 'AWAITED':
              status += 'AW|';
              break;
            case 'FAILED':
              status += 'FD|';
              break;
            case 'BLANK':
              status += 'CD|';
              break;
            default:
              break;
          }
          if (seg.scheduleChangeInfo) {
            switch (seg.scheduleChangeInfo.messageType) {
              case 'CANCEL':
                status += 'TSC|';
                break;
              case 'OTHERS':
                status += 'TSM|';
                break;
              default:
                break;
            }
          }
        });
      });
    }
    return status;
  };

  trackDeepLinkClickEvent = ({
    pageName = 'cs:brand:mima_help',
    lineOfBusiness = 'Customer Support',
    eventName,
  }) => {
    if (!eventName) return;
    const params = {
      pageName,
      [PROP_24]: lineOfBusiness,
      [EVAR_24]: lineOfBusiness,
      [PROP_1]: `${eventName}_clicked`,
    };
    trackOmniture(pageName, params);
  };
}

export default new FlightBookingTrackingHelper();
