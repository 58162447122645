import { StyleSheet, View, Image } from 'react-native';
import React from 'react';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import STAR_IMAGE from '@mmt/legacy-assets/src/star.webp';

const StarRating = ({maxCount = 5,activeCount=null }) => {
  if(!activeCount)return null;
  let star = [];
  for (let i = 0; i < maxCount; i++) {
    star.push(
      <View>
        <Image
          style={[styles.starStyle, i < activeCount ? styles.activeStyle : styles.disabledStyle]}
          source={STAR_IMAGE}
        />
      </View>,
    );
  }
  return  star
};

export default StarRating;

const styles = StyleSheet.create({
  starStyle: {
    width: 8,
    height: 8,
    paddingRight: 1,
    marginLeft: 1,
  },
  activeStyle: {
    tintColor: colors.black,
  },
  disabledStyle: {
    tintColor: colors.gray5,
  },
});
