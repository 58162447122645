import React from 'react';
import PropTypes from 'prop-types';
import SingleRequestTicketCard from './SingleRequestTicketCard';
import MultipleRequestsTicketCards from './MultipleRequestsTicketCards';

export default function FlightMSRCard(props) {
  const { ticketsList, allTicketsForBooking, pageName, trackingPageName } = props;
  if (!ticketsList) {
    return null;
  }
  const ticketsCount = ticketsList.length;
  if (ticketsCount === 0) {
    return null;
  }
  if (ticketsCount === 1) {
    return (
      <SingleRequestTicketCard
        allTicketsForBooking={allTicketsForBooking}
        ticket={ticketsList[0]}
        pageName={pageName}
        trackingPageName={trackingPageName}
      />
    );
  }
  return (
    <MultipleRequestsTicketCards
      allTicketsForBooking={allTicketsForBooking}
      ticketsList={ticketsList}
      pageName={pageName}
      trackingPageName={trackingPageName}
    />
  );
}

FlightMSRCard.defaultProps = {
  ticketsList: [],
};

FlightMSRCard.propTypes = {
  ticketsList: PropTypes.arrayOf(
    PropTypes.shape({
      iconUrl: PropTypes.string.isRequired,
      secondaryType: PropTypes.string.isRequired,
      createdAt: PropTypes.number.isRequired,
      updatedAt: PropTypes.number.isRequired,
      expectedClosureDate: PropTypes.number,
      assignee: PropTypes.string,
      msrUrl: PropTypes.string.isRequired,
      actionBtnText: PropTypes.string.isRequired,
    }),
  ),
};
