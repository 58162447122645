import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import React from 'react';
import { Text, View } from 'react-native';
import createStyles from './ManagerApprovalCss';
import { useTheme } from '../../../../../../../theme/theme.context';

const ManagerApprovalCard = (props) => {
  const rd = props.requesterDetails;
  const {psTheme: theme} = useTheme();
  const fonts = getFont(true);
  const styles = createStyles(theme,fonts);
  return (
    <View>
      <Text style={styles.cardHead}>{rd.header}</Text>
      <View style={styles.paxDtlsRow}>
        <Text style={styles.paxName}>{rd.name}</Text>
        <Text style={styles.mngId}>{rd.email}</Text>
        <Text style={styles.mngId}>{rd.mobile}</Text>
      </View>
    </View>
  );
};
export default ManagerApprovalCard;
