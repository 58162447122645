import React from 'react';
import {StyleSheet, View, Image, Text} from 'react-native';
import PropTypes from 'prop-types';

import {TickSuccessIcon} from '../../HolidayBookingConstants';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';
import {fonts, colors} from '@mmt/legacy-commons/Styles/globalStyles';

export default function DocumentsUploadedHeader(props) {
  const {documentPageDetails} = props;
  if (!documentPageDetails) {
    return null;
  }
  if (!Object.keys(documentPageDetails).length) {
    return null;
  }

  return (
    <View style={[styles.whiteCard, styles.innerSection, AtomicCss.marginBottom12]}>
      <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
        <Image style={styles.successIconStyle} source={TickSuccessIcon} />
        <Text style={[styles.succesTxt, AtomicCss.marginBottom10]}>
          {documentPageDetails.topHeaderText}
        </Text>
      </View>
      <Text style={styles.moreInfo}>
        {documentPageDetails.topHeaderSubText.split(documentPageDetails.dueDate).map((text, index) => {
          if (index === 0) {
            return text;
          }
          return (
            <React.Fragment>
              <Text style={AtomicCss.boldFont}>{documentPageDetails.dueDate}</Text>
              {text}
            </React.Fragment>
          );
        })}
      </Text>
    </View>
  );
}

DocumentsUploadedHeader.propTypes = {
  documentPageDetails: PropTypes.shape({
    topHeaderText: PropTypes.string,
    topHeaderSubText: PropTypes.string
  }).isRequired
};

const styles = StyleSheet.create({
  whiteCard: {
    ...getPlatformElevation(2),
    backgroundColor: '#fff',
    marginBottom: 3
  },
  innerSection: {
    padding: 16
  },
  iconStyle: {
    width: 16,
    height: 16,
    marginRight: 10,
    alignSelf: 'flex-start'
  },
  successIconStyle: {
    width: 14,
    height: 14,
    marginRight: 10,
    marginTop: 2,
    alignSelf: 'flex-start'
  },
  moreInfo: {
    paddingLeft: 25,
    color: colors.black,
    fontSize: 14,
    fontFamily: fonts.regular
  },
  missDeadLineTextInfo: {
    color: '#e53442',
    fontSize: 16,
    fontFamily: fonts.bold
  },
  succesTxt: {
    color: '#1a7971',
    fontSize: 16,
    fontFamily: fonts.bold
  }
});
