import React from 'react';
import {View, TouchableOpacity} from 'react-native';
import styles from './OverlayExtraChargesCss';
import BottomOverlayDetails from './BottomOverlayDetails';

const OverlayExtraCharges = ({onCloseOverlay, infoList, heading}) => {
    return (
        <View style={styles.overlayWrapper}>
            <TouchableOpacity onPress={()=>onCloseOverlay()} activeOpacity={1} style={styles.overlay}></TouchableOpacity>
            <View style={styles.overlayContent}>
                <BottomOverlayDetails onCloseOverlay={onCloseOverlay} infoList={infoList} heading={heading} />
            </View>
        </View>
    );
}

export default OverlayExtraCharges;