import React from "react";
import { Text } from "react-native";
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { MONTHS } from '../HolidayBookingConstants';
import * as Fecha from 'fecha';

const DateComponent = props => {
  const getDate = (trvlDateTime) => {
    try {
      const travelDate = trvlDateTime.split('T')[0];
      const mIndex = parseInt(travelDate.split('-')[1], 10);
      const day = parseInt(travelDate.split('-')[2]);
      return `${day} ${MONTHS[mIndex - 1]}`;
    } catch (e) {
      return Fecha.format(new Date(trvlDateTime), "DD MMM");
    }
  }

  const getTime = (trvlTime) => {
    try {
      const travelDate = trvlTime.split('T')[1];
      return travelDate.substr(0, travelDate.lastIndexOf(':'));
    } catch (e) {
      return Fecha.format(new Date(trvlTime), 'HH:mm');
    }
  }

  return (
    <Text style={[AtomicCss[props.font], AtomicCss[props.color], AtomicCss[props.fontWeight]]}>
      {props.isDummyFlight ? '' : `${getTime(props.time)},`} {getDate(props.time)}
    </Text>
  );
};

export default DateComponent;
