export const WEB_VIEW_EVENTS = {
  OPEN_HOTEL_BOOKING_DETAIL: 'OPEN_HOTEL_BOOKING_DETAIL',
  OPEN_FLIGHT_BOOKING_DETAIL: 'OPEN_FLIGHT_BOOKING_DETAIL',
  OPEN_HOLIDAY_BOOKING_DETAIL: 'OPEN_HOLIDAY_BOOKING_DETAIL',
  OPEN_WALLET_LANDING: 'OPEN_WALLET_LANDING',
  CALL_DISCONNECTED: 'CALL_DISCONNECTED',
};

type EventIds = keyof typeof WEB_VIEW_EVENTS;

const registry: Partial<Record<EventIds, (data: any) => void>> = {};

export function subscribeToWebViewEvents(eventId: EventIds, listener: (data: any) => void) {
  registry[eventId] = listener;
}

export function publishWebViewEvent(eventId: EventIds, data: any){
  let listener = registry[eventId];
  if (listener) {
    listener(data)
  }
}
