import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, Text, View, Image } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getFont } from '../../../../../PostSalesStyles';
import isEmpty from 'lodash/isEmpty';
import { ThemeContext } from 'apps/post-sales/src/theme/theme.context';

class CancellationInfoList extends React.Component {
  static contextType = ThemeContext;
  render() {
    const { penaltyNodes } = this.props;
    return (
      <View style={styles.cancellationInfoWrapper}>
        <View style={styles.line} />
        <View style={styles.dtlsSection}>{this.getPenaltyDataViews(penaltyNodes)}</View>
      </View>
    );
  }

  getPenaltyDataViews = (penaltyDataList) =>
    penaltyDataList.map((item, index) => {
      const {color} = this.context.psTheme;
      const { fsStyle, ...fonts } = getFont(true);
      const styleObj = this.getTextStyle(item.type);
      const parentViewStyle = [];
      parentViewStyle.push(styles.dtlsSectionRow);
      parentViewStyle.push(AtomicCss.marginBottom20);
      if (item.selected) {
        parentViewStyle.push(styleObj.boxStyle);
      }
      return (
        <View style={parentViewStyle}>
          {item.selected && <View style={[styles.triangle, styleObj.arrowStyle]} />}
          {index === penaltyDataList.length - 1 && <View style={styles.lastItem} />}
          <View style={styles.lftSection}>
            <View style={[styles.circleOuter, styleObj.style]}>
              <View style={styles.circleInner}>
                <Text />
              </View>
            </View>
            <View style={{width:'100%'}}>
              <Text
                style={[
                  styleObj.txtColor,
                  AtomicCss.marginBottom3,
                  fonts.boldFontFamily,
                  fsStyle.font14,
                  {paddingRight: 10}
                ]}
              >
                {item.timeApplicable.heading}
              </Text>
              <Text style={[AtomicCss.blackText, fsStyle.font12, fonts.regularFontFamily]}>
                {item.timeApplicable.subHeading}
              </Text>
              {!isEmpty(item.subheading) && (
                <View style={[AtomicCss.displayFlex, AtomicCss.marginTop8, AtomicCss.flexRow]}>
                  {!isEmpty(item.iconURL) && (
                    <Image source={{uri: item.iconURL}} style={styles.myBizSmeIcon} />
                  )}
                  <Text
                    style={[
                      {color: color.black},
                      fsStyle.font12,
                      fonts.boldFontFamily
                    ]}
                  >
                    {item.subheading}
                  </Text>
                </View>
              
              )}
            </View>
          </View>
          <View style={styles.rgtSection}>
            <Text
              style={[
                styleObj.txtColor,
                AtomicCss.alignRight,
                fonts.boldFontFamily,
                fsStyle.font14,
              ]}
            >
              {item.heading}
            </Text>
          </View>
        </View>
      );
    });

  getTextStyle = (type) => {
    const resultObj = {};
    resultObj.style = styles.orangeBG;
    resultObj.txtColor = styles.orangeTxt;
    if (type) {
      switch (type) {
        case 'free':
          resultObj.style = styles.greenBG;
          resultObj.txtColor = styles.greenTxt;
          resultObj.boxStyle = styles.greenBoxStyle;
          resultObj.arrowStyle = styles.greenArrowStyle;
          return resultObj;
        case 'payable':
          resultObj.style = styles.orangeBG;
          resultObj.txtColor = styles.orangeTxt;
          resultObj.boxStyle = styles.orangeBoxStyle;
          resultObj.arrowStyle = styles.orangeArrowStyle;
          return resultObj;
        case 'noRefund':
          resultObj.style = styles.redBG;
          resultObj.txtColor = styles.redTxt;
          resultObj.boxStyle = styles.redBoxStyle;
          resultObj.arrowStyle = styles.redArrowStyle;
          return resultObj;
        default:
          resultObj.style = styles.orangeBG;
          resultObj.txtColor = styles.orangeTxt;
          resultObj.boxStyle = styles.orangeBoxStyle;
          resultObj.arrowStyle = styles.orangeArrowStyle;
          return resultObj;
      }
    }
    return resultObj;
  };
}

const styles = StyleSheet.create({
  cancellationInfoWrapper: {
    paddingBottom: 3,
    paddingHorizontal: 16,
    flexDirection: 'row',
    paddingTop: 15,
    justifyContent: 'space-between',
  },
  line: {
    width: 2,
    height: '96%',
    backgroundColor: '#cccccc',
    top: 10,
  },
  dtlsSection: {
    flexDirection: 'column',
    flex: 1,
  },
  dtlsSectionRow: {
    flexDirection: 'row',
    marginLeft: 10,
    borderRadius: 4,
    paddingLeft: 0,
    paddingVertical: 7,
    paddingRight: 7,
  },
  lftSection: {
    flexDirection: 'row',
    flex: 1,
  },
  circleOuter: {
    width: 8,
    height: 8,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20,
    left: -15,
    top: 2,
  },
  circleInner: {
    width: 4,
    height: 4,
    alignSelf: 'center',
    backgroundColor: '#fff',
    borderRadius: 20,
  },
  greenTxt: {
    color: '#1a7971',
    fontSize: 12,
  },
  orangeTxt: {
    color: '#cf8100',
    fontSize: 12,
  },
  redTxt: {
    color: '#eb2026',
    fontSize: 12,
  },
  flexStart: {
    alignSelf: 'flex-start',
  },
  rgtSection: {
    width: '35%',
    alignItems: 'flex-end',
  },
  greenBG: {
    backgroundColor: '#1a7971',
  },
  orangeBG: {
    backgroundColor: '#cf8100',
  },
  redBG: {
    backgroundColor: '#eb2026',
  },
  myBizSmeIcon: {
    width: 12,
    height: 12,
    marginRight: 4
  },

  triangle: {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderLeftWidth: 5,
    borderRightWidth: 5,
    borderTopWidth: 8,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderTopColor: 'green',
    transform: [{ rotate: '90deg' }],
    margin: 0,
    marginLeft: -6,
    borderWidth: 0,
    position: 'absolute',
    top: 10,
    left: -1,
  },
  greenBoxStyle: {
    backgroundColor: '#c1f1dd',
  },
  orangeBoxStyle: {
    backgroundColor: '#ffedd1',
  },
  redBoxStyle: {
    backgroundColor: '#ffd3d4',
  },
  greenArrowStyle: {
    borderTopColor: '#c1f1dd',
  },
  orangeArrowStyle: {
    borderTopColor: '#ffedd1',
  },
  redArrowStyle: {
    borderTopColor: '#ffd3d4',
  },
  lastItem: {
    width: 5,
    height: 75,
    backgroundColor: '#ffffff',
    top: 8,
    left: -14,
    position: 'absolute',
  },
});
export default CancellationInfoList;

CancellationInfoList.propTypes = {
  penaltyNodes: PropTypes.array.isRequired,
};
