import React from 'react';
import {View, Text, TouchableOpacity} from 'react-native';
import PropTypes from 'prop-types';
import styles from './MimaBottomOverlayDtlsCss';


export default class PartialCancellation extends React.Component {
  render() {
      const corp = this.props.isCorporateBooking;
    return (
      <View>
        <Text style={styles.contentTitle}>{this.props.content.title}</Text>
        <Text style={styles.contentDesc}>{this.props.content.desc}
        </Text>
        <View style={styles.contentFooter}>
          <TouchableOpacity style={styles.actionBtn} onPress={() => this.props.CloseOverlay()}>
            <Text style={[styles.actionBtnText, corp ? styles.corpColor : {}]}>GO BACK</Text>
          </TouchableOpacity>
          <TouchableOpacity style={styles.actionBtn} onPress={() => this.props.ContinuePress()}>
            <Text style={[styles.actionBtnText, corp ? styles.corpColor : {}]}>CONTINUE TO CANCEL</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }
}

PartialCancellation.propTypes = {
  CloseOverlay: PropTypes.func.isRequired,
  ContinuePress: PropTypes.func.isRequired,
  content: PropTypes.object.isRequired
};

