import React from 'react';
import { View, Image, Text, TouchableOpacity } from 'react-native';
import createStyles from './styles';

import { getFont } from '@mmt/post-sales/src/PostSalesStyles';
import { useTheme } from '@mmt/post-sales/src/theme/theme.context';
import { CROSS_ICON } from '@mmt/post-sales/src/cab/CabImageConstants';
import { DriverProfileOverlayData } from '@mmt/post-sales/src/cab/types';
import { renderSections } from './sectionRenderer';
import CabBookingTrackingHelper from '@mmt/post-sales/src/cab/CabBookingTrackingHelper';

interface DriverProfileOverlayProps {
  overlayData: DriverProfileOverlayData;
  handleBottomOverlay: (actionId: string, heading?: string) => void;
  pageName: string;
}

const DriverProfileOverlay = ({
  overlayData,
  handleBottomOverlay,
  pageName,
}: DriverProfileOverlayProps) => {
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);

  const { heading, sectionList } = overlayData || {};

  return (
    <View style={styles.container}>
      {!!heading && (
        <View style={[styles.headingSection]}>
          <Text style={[styles.headingText]}>{heading}</Text>
          <TouchableOpacity
            style={styles.crossIconWrapper}
            onPress={() => {
              CabBookingTrackingHelper.trackClickEvent(
                pageName,
                'Mob_Cabs_MIMA_DriverProfileOverlay_Close_Clicked',
              );
              handleBottomOverlay('');
            }}
          >
            <Image style={styles.crossIconStyle} source={CROSS_ICON} />
          </TouchableOpacity>
        </View>
      )}
      <View style={styles.driverProfileWrapper}>{renderSections(sectionList)}</View>
    </View>
  );
};
export default DriverProfileOverlay;
