import React from 'react';
import PropTypes from 'prop-types';
import LinearGradient from 'react-native-linear-gradient';
import Actions from '../../../navigation/postSalesNavigation';
import {Image, Text, TouchableOpacity, View} from 'react-native';
import styles from '../../../cab/components/CabMamiCss';
import _ from 'lodash';
import {CANCEL_ICON} from '../../SelfDriveImageConstant';

class SelfDriverRefundOverlay extends React.Component {
  render() {
    return (
      <View style={styles.overlayContainer}>
        <TouchableOpacity style={styles.overlayBg} onPress={() => this.props.backPress()} />
        <View style={styles.overlayContent}>
          <View style={styles.flexRow}>
            <Image source={CANCEL_ICON} style={styles.CancelledIcon} />
            <View style={styles.mar15Bt}>
              <Text style={styles.overlayHead}>Are you sure?</Text>
            </View>
          </View>
          <View style={[styles.mar25Bt]}>
            <Text style={styles.cancelTxt}>
              {this.getCancellationConfirmationText()}
            </Text>
          </View>
          <View style={[styles.flexRow, styles.alignCenter, styles.spaceBetween]}>
            <TouchableOpacity onPress={() => this.props.backPress()}>
              <Text style={styles.actionBlueText}>BACK</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => this.onConfirmClickHandler()}>
              <LinearGradient
                start={{x: 1.0, y: 0.0}}
                end={{x: 0.0, y: 1.0}}
                colors={['#065af3', '#53b2fe']}
                style={styles.CapsuleBtnFill}
              >
                <Text style={[styles.WhiteText, styles.regularFont, styles.CenterText, styles.transperantBgrnd]}>OKAY, CANCEL</Text>
              </LinearGradient>
            </TouchableOpacity>
          </View>
        </View>
      </View>

    );
  }

  getCancellationConfirmationText = () => {
    return 'Once confirmed, your booking will be cancelled and refund will be initiated.';
  };

  onConfirmClickHandler() {
    Actions.SelfDriveCancelRequest({
      bookingId: this.props.bookingId,
      response: this.props.response,
      selectedRefundMode: _.isEmpty(this.props.selectedRefundMode) ? 'RefundToCustomer' : this.props.selectedRefundMode,
    });
  }
}
export default SelfDriverRefundOverlay;

SelfDriverRefundOverlay.propTypes = {
  backPress: PropTypes.func.isRequired,
  selectedRefundMode: PropTypes.string.isRequired,
  bookingId: PropTypes.string.isRequired,
  pageName: PropTypes.string.isRequired,
};
