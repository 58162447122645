import {colors} from '@mmt/legacy-commons/Styles/globalStyles';

const getStyles = (fonts) => {
  return {
    loaderWrapper: {
      flex: 1,
      backgroundColor: '#fff',
      alignItems: 'center',
      paddingHorizontal: 30,
      paddingVertical: 50
    },
  
    headingTitle: {
      ...fonts.font22,
      fontFamily: fonts.black,
      color: '#000',
      textAlign: 'center',
      marginTop: 70,
      marginBottom: 30,
      backgroundColor: 'transparent'
    },
    bankInfoTxt: {
      color: '#249995',
      ...fonts.font16,
      fontFamily: fonts.regular,
      textAlign: 'center',
      marginTop: 10,
      marginBottom: 20,
      backgroundColor: 'transparent'
    },
    bankChargeCard: {
      backgroundColor: 'transparent',
      color: colors.black,
      fontFamily: fonts.bold,
      ...fonts.font16
    },
    imageIcon: {
      width: 24,
      height: 24,
      alignSelf: 'center',
      marginBottom: 35
    },
    chargingCardWrapper: {
      borderTopColor: '#f2f2f2',
      borderTopWidth: 1,
      paddingTop: 50,
      width: '100%',
      alignItems: 'center'
    }
  };
}

export default getStyles;
