export {
  getFlightItemListMap,
  getListingHeader,
  getBookingType,
  getFareComponent,
  getFilterInfo,
  getDurationText,
  getNoOfTravellers,
} from 'core-ui-lib/flight/common/ODC/dataAdapter';
import { getStaticData } from '../../../../staticData/staticData';
import isEmpty from 'lodash/isEmpty';
export const getTerminalData = (tNo, terminalText) => {
  if (tNo) {
    return terminalText && terminalText.concat(` ${tNo}`);
  }
  return '';
};

export const getBaggageInfo = (leg) => {
  const { cabinBaggageText, checkInBaggageText } = getStaticData();
  let bagInfo = '';
  if (leg?.checkinbaginfo?.unitAllowance) {
    bagInfo = `${leg.checkinbaginfo.unitAllowance} ${checkInBaggageText}`;
  }
  if (bagInfo !== '' && leg?.cabinbaginfo?.unitAllowance ) {
    bagInfo += ' | ';
  }
  if (leg?.cabinbaginfo?.unitAllowance ) {
    bagInfo += `${leg.cabinbaginfo.unitAllowance} ${cabinBaggageText}`;
  }
  return bagInfo;
};

export const getOperatedByAirlineText = (originalAirline, operatedByAirline) => {
  const {operatedByAirlineText} = getStaticData();
  if (!isEmpty(operatedByAirline) && originalAirline !== operatedByAirline) {
    return operatedByAirlineText.replace('${operatedByAirline}', operatedByAirline);
  }
  return undefined;
};
