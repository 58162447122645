import React from 'react';
import { StyleSheet, View, Text, ActivityIndicator } from 'react-native';
import { colors, fonts } from '../../../Styles/globalStyles';

export default class ProgressView extends React.Component {

  render() {
    const styleByProps = this.props.style ? this.props.style : {};
    return (
      <View style={[styles.progressContainer, styleByProps]}>
        <ActivityIndicator
          styleAttr="Inverse"
          style={styles.indicator}
          color="#008b8b"
          size="large"
        />
        <Text style={[styles.textDark, { marginTop: 8 }]}>
          {this.props.message ? this.props.message : 'Loading...'}
        </Text>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  progressContainer: {
    flex: 1,
    width: '100%',
    height: '100%',
    backgroundColor: '#ffffffd9',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textDark: {
    color: colors.black,
    fontSize: 16,
    fontFamily: fonts.bold,
    textAlign: 'center',
  },
});
