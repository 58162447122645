import { StyleSheet } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';

export const getStyle = (fonts, color) => {
    return StyleSheet.create({
      viWrapper: {
        backgroundColor: color.gray6,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 10,
        marginVertical: 5
      },
      detailWrapper: {
        marginVertical: 5,
      },
      title: {
        ...fonts.boldFontFamily,
        color: color.lightYello,
        fontSize: fonts.fontSize.font12,
      },
      subtitle: {
        color: color.defaultTextColor,
        flexDirection: 'row',
      },
      imageWrap: {
          height: 16, 
          width: 12,
          ...AtomicCss.marginRight8,
          ...AtomicCss.marginVertical5,
          top:4,
      }
    });
  };