import React, { useEffect, useState } from 'react';
import { View, Animated, Text, StyleSheet, TouchableOpacity } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getStaticData } from '../../staticData/staticData';
import { useTheme } from '../../theme/theme.context';

const StepBar = ({count, isActive, widthAnimation, skipText, skipHandler, langAware = false}) => {
    const { stepOfText } = getStaticData(langAware);
    const [steps, setSteps] = useState([]);
    const { psTheme: theme } = useTheme();
    const styles = createStyle(theme);

    useEffect(() => {
        let updatedSteps = [];
        for (let iter = 0; iter < count; iter++) {
            const completeStep = iter < isActive ? {width: widthAnimation[iter], backgroundColor: theme.color.primary} : '';
            updatedSteps.push(
                <Animated.View style={[styles.disabledLine, iter ===isActive? styles.activeLine:null, completeStep]}>
                </Animated.View>
            );
        }
        setSteps(updatedSteps)
    }, [count, isActive, widthAnimation, theme]);

    return (
        <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween, AtomicCss.alignCenter]}>
            <View>
                <Text style={[AtomicCss.font12, AtomicCss.boldFont, AtomicCss.midGreyText, AtomicCss.lineHeight20]}>{stepOfText.replace('{currentStep}', isActive + 1).replace('{totalSteps}', count)}</Text>
                <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginTop5]}>
                    {steps}
                </View>
            </View>
            {!!skipText &&
                <TouchableOpacity activeOpacity={0.7} onPress={skipHandler}>
                    <Text style={[styles.skipText, AtomicCss.font14, AtomicCss.blackFont, AtomicCss.azure, AtomicCss.lineHeight18]}>{skipText}</Text>
                </TouchableOpacity>
            }
        </View>
    )
}

const createStyle = (theme)=> StyleSheet.create({   
    disabledLine: {
        backgroundColor: theme.color.gray3,
        width: 36,
        height: 6,
        borderRadius: 2,
        marginRight: 4
    },
    activeLine: {
        backgroundColor: theme.color.primary,
    },
    skipText:{
        color:theme.color.primary
    }
});

export default StepBar;
