
import myTripsNavigation from '../../../navigation/navigation';
import { msrUpdateAction } from '../../../../utils/MsrUtils'
import styles from './triGuranteeSummaryCSS'
import baseCardStyle from '../baseCardStyles';
import { getFont } from '../../../../PostSalesStyles';
import TGSBookingTrackEventHelper from '../../../../TripGuarantee/Utils/TGSBookingTrackEventHelper';
import { CLICK_TYPE, PAGE_NAME } from '../../../../TripGuarantee/Utils/TGSBookingConstants';
function getTgsSummaryData(data, msrTicket) {
  const {tgsDetails : tgsData} = data || {};
  const [tgsDetails] = tgsData || {};
  const { pnrNo, paxCount, trainName, heading, statusHeading, status: tgsStatus } = tgsDetails || {}
  const tgsInfo = `${pnrNo} • ${paxCount}`;
  const pageData = {
    fromMyTrips: true,
  };

  const openDetails = () => {
    TGSBookingTrackEventHelper.trackClickEvents(PAGE_NAME.SUMMARY, CLICK_TYPE.SUMMARY_CLICK)
    myTripsNavigation.openTripGuaranteeBookingDetail(data, pageData);
  };
  const actions = [];
  const msrAction = msrUpdateAction(msrTicket)
  if (msrAction) {
    actions.push(msrAction)
  }

  const fonts = getFont(true);
  const titleStyle = [baseCardStyle.title, fonts.blackFontFamily];
  const viewStyle = tgsStatus ? styles.ticketAdded : styles.ticketIssued;
  const textStyle = tgsStatus ? styles.ticketAddedText : styles.ticketIssuedText;

  return {
    heading,
    tgsInfo,
    trainName,
    openDetails,
    status: data.status,
    tgsStatus,
    statusHeading,
    titleStyle,
    viewStyle,
    textStyle
  };
}

export default getTgsSummaryData;
