const colors = {
  white: '#fff',
  black: '#000',
  transparent: '#ffffff00',
  //Text
  defaultTextColor: '#4a4a4a',
  lightTextColor: '#9b9b9b',
  informationTextColor: '#0a223d',
  warningTextColor: '#cf8100',
  inputErrorTextColor: '#e62120',
  // blacks
  black3c: '#3c3c3c',
  //Blues
  darkBlue: '#065af3',
  lightBlue: '#53b2fe',
  veryLightBlue: '#f4faff',
  softBlue: '#53b2fe',
  electricBlue: '#065af3',
  azure: '#008cff',
  //Greys
  greyE7: '#e7e7e7',
  lightGrey: '#9b9b9b',
  veryLightGrey: '#d8d8d8',
  grey10: '#afafaf',
  grayBg: '#f2f2f2',
  disabledButton: '#c2c2c2',
  rippleColor: '#d7d7d7',
  grey13: '#fafafa',
  // yellows
  InformationYellowBg: '#ffedd1',
  // border
  lightBorder: '#e7e7e7',
};

export const standardGradients = {
  blueCta: [colors.softBlue, colors.electricBlue],
  disabled: [colors.veryLightGrey, colors.veryLightGrey],
};

export default colors;
