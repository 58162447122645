import React, { useMemo, useState } from 'react';
import { View, Text, TouchableOpacity, Image } from 'react-native';
import {
  ONE_WAY_JRNY_ICON,
  CHECKBOX_TICK_ICON,
  RADIO_BLUE_ICON,
  RADIO_INACTIVE_ICON,
  BLUE_DOWN_ARROW,
} from '../../../../FlightImageConstants';
import { getImageUrlFromMap } from '../../../../../utils/PostSaleUtil';
import { isEmpty } from 'lodash';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import AncillaryComponent from './AncillaryComponent';
import styles from '../ClaimAncillaryRefundCss';
import { isEmptyArray } from '../../../../../Common/commonUtil';
import { getFont } from '../../../../../PostSalesStyles';

const CardComponent = ({
  card,
  imgMap,
  selectedCard,
  onCardSelection,
  multiCardSelection,
  onGroupItemSelection,
  cardIndex,
}) => {
  const { fsStyle, ...fonts } = getFont(true);
  const { id, title, subtitle, groupEntries, img, separator } = card || {};
  const [collapse, setCollapse] = useState({});
  const intentList = title ? title.split('-') : [];
  const isAllItemSelected = (selectedData, currentCard) => {
    if (selectedData.groupEntries.length === currentCard.groupEntries.length) {
      let actualCount = 0;
      let selectCount = 0;
      selectedData.groupEntries.forEach((val) => {
        selectCount += val.entries.length;
      });
      currentCard.groupEntries.forEach((val) => {
        actualCount += val.entries.length;
      });
      return actualCount === selectCount;
    }
    return false;
  };
  const allPaxSelected =
    selectedCard[id] && isAllItemSelected(selectedCard[id], card) ? true : false;
  const allSelectedStyle = allPaxSelected ? 'Selected' : '';
  const isCheckBoxDisabled = false;

  const imgUrl = useMemo(() => getImageUrlFromMap(imgMap, img), [imgMap, img]);

  const toggleCollapse = (i) => {
    setCollapse((state) => ({ ...state, [i]: !state[i] }));
  };

  const getAncillaryInfo = (id, group) => {
    const data = !isEmptyArray(group) ? group.find((item) => item.topHeader === id) : null;
    return data && !isEmptyArray(data.entries) ? data.entries.map((a) => a.title) : null;
  };

  const renderAncillaryInfo = useMemo(() => {
    const ancillaryInfo = {
      seat: getAncillaryInfo('Seat', groupEntries),
      meal: getAncillaryInfo('Meal', groupEntries),
      bag: getAncillaryInfo('Baggage', groupEntries),
    };
    return (
      <Text
        style={[
          AtomicCss.defaultText,
          fonts.regularFontFamily,
          fsStyle.font14,
          AtomicCss.lineHeight20,
        ]}
      >
        {ancillaryInfo.seat && (
          <React.Fragment>
            <Text style={fonts.boldFontFamily}>Seat </Text>({ancillaryInfo.seat.join(', ')})
            {ancillaryInfo.meal || ancillaryInfo.bag ? ', ' : ''}
          </React.Fragment>
        )}
        {ancillaryInfo.meal && (
          <React.Fragment>
            <Text style={fonts.boldFontFamily}>Meal </Text>({ancillaryInfo.meal.join(', ')})
            {ancillaryInfo.bag ? ', ' : ''}
          </React.Fragment>
        )}
        {ancillaryInfo.bag && (
          <React.Fragment>
            <Text style={fonts.boldFontFamily}>Baggage </Text>({ancillaryInfo.bag.join(', ')})
          </React.Fragment>
        )}
      </Text>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupEntries]);

  return (
    <View style={[styles.cardWrapper, cardIndex === 0 || separator ? null : AtomicCss.marginTop20]}>
      {separator && (
        <View style={styles.seperator}>
          <View style={[styles.semiCircle, styles.leftSemiCircle]} />
          <View style={styles.dashedLineWrapper}>
            <View style={styles.dashedLine} />
          </View>
          <View style={[styles.semiCircle, styles.rightSemiCircle]} />
        </View>
      )}
      <View style={styles.checkAllWrapper}>
        <View style={[styles.checkAllWrapperInner]}>
          <TouchableOpacity
            style={[styles.checkBtn, styles.checkAllBtn, styles.PaxslctionHeader]}
            activeOpacity={0.8}
            onPress={() => onCardSelection(card)}
          >
            <View style={styles.SectorHeader}>
              <View style={{ flexDirection: 'row' }}>
                {multiCardSelection ? (
                  <View
                    style={[
                      styles.checkbox,
                      styles.checkboxAll,
                      styles[`checkbox${allSelectedStyle}`],
                      isCheckBoxDisabled ? styles.disableChkBox : '',
                    ]}
                  >
                    {allPaxSelected && (
                      <Image style={styles.checkedIcon} source={CHECKBOX_TICK_ICON} />
                    )}
                  </View>
                ) : (
                  <View style={[styles.radio, styles.checkboxAll]}>
                    <Image
                      style={styles.radioIcon}
                      source={allPaxSelected ? RADIO_BLUE_ICON : RADIO_INACTIVE_ICON}
                    />
                  </View>
                )}
                <View style={[styles.destRow, AtomicCss.flex1]}>
                  <View style={AtomicCss.flex2}>
                    <View style={styles.destRow}>
                      {!isEmptyArray(intentList) &&
                        intentList.map((intent, index) => (
                          <React.Fragment key={index}>
                            <Text style={[styles.cityText, fonts.boldFontFamily, fsStyle.font16]}>
                              {intent}
                            </Text>
                            {index < intentList.length - 1 && (
                              <Image style={styles.arrowIcon} source={ONE_WAY_JRNY_ICON} />
                            )}
                          </React.Fragment>
                        ))}
                    </View>
                    <Text style={styles.subtitle}>{subtitle}</Text>
                  </View>
                  {!isEmpty(imgUrl) && (
                    <View style={styles.airlinesIconWrapper}>
                      <Image style={styles.airlineIcon} source={{ uri: imgUrl }} />
                    </View>
                  )}
                </View>
              </View>
            </View>
          </TouchableOpacity>
        </View>
      </View>
      {!isEmptyArray(groupEntries) && (
        <View style={styles.collapseMainWrapper}>
          {!collapse[cardIndex] && (
            <View style={styles.collapseHeadingSection}>
              <TouchableOpacity
                style={styles.actionLink}
                activeOpacity={0.8}
                onPress={() => toggleCollapse(cardIndex)}
              >
                <View style={styles.leftDetails}>{renderAncillaryInfo}</View>
                <View style={styles.arrowWrapper}>
                  <Image style={styles.arrowStyle} source={BLUE_DOWN_ARROW} />
                </View>
              </TouchableOpacity>
            </View>
          )}
        </View>
      )}
      {collapse[cardIndex] && (
        <View>
          <TouchableOpacity
            style={[styles.actionBtnWrapper]}
            activeOpacity={0.8}
            onPress={() => toggleCollapse(cardIndex)}
          >
            <View style={[styles.arrowWrapper, { top: 20 }]}>
              <Image style={[styles.arrowStyle, styles.activeArrow]} source={BLUE_DOWN_ARROW} />
            </View>
          </TouchableOpacity>
          {!isEmptyArray(groupEntries) &&
            groupEntries.map((item, index) => (
              <View
                key={index}
                style={[
                  styles.sectorContainer,
                  index < groupEntries.length - 1 ? styles.sectorBorderStyle : null,
                ]}
              >
                {!isEmptyArray(item.entries) &&
                  item.entries.map((info, i) => (
                    <React.Fragment key={i}>
                      <AncillaryComponent
                        {...{
                          card,
                          group: item,
                          info,
                          allPaxSelected,
                          onGroupItemSelection,
                          selectedCard,
                        }}
                      />
                    </React.Fragment>
                  ))}
              </View>
            ))}
        </View>
      )}
    </View>
  );
};

export default CardComponent;
