import React from 'react';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import { StyleSheet, Text, View, ViewStyle } from 'react-native';
import CustomCardImage from '../CustomCardImage';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { Fonts, Theme } from 'apps/post-sales/src/cab/types';

interface HeadingComponentProps {
  heading: string;
  showIcon?: boolean;
  containerStyle?: ViewStyle;
}

const HeadingComponent: React.FC<HeadingComponentProps> = ({ heading, showIcon = false, containerStyle = {} }) => {
  const { fsStyle, ...fonts } = getFont();
  const { psTheme } = useTheme();
  const styles = getStyles(fonts, psTheme);

  return (
    <View style={[AtomicCss.flexRow, containerStyle]}>
      {showIcon && <CustomCardImage />}
      {!!heading && <Text style={styles.headingText}>{heading}</Text>}
    </View>
  );
};

export default HeadingComponent;

const getStyles = (fonts: Fonts, theme: Theme) => {
  const { color } = theme;
  return StyleSheet.create({
    headingText: {
      color: color.black,
      fontSize: fonts.fontSize.font16,
      ...fonts.boldFontFamily,
    },
    cardHeading: {
      paddingVertical: 12,
      paddingHorizontal: 14,
    },
  });
};
