import React from 'react';
import PropTypes from 'prop-types';
import {View, ScrollView, StyleSheet} from 'react-native';
import isEmpty from 'lodash/isEmpty';
import IncExcCollapsed from './IncExcCollapsed';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import Header from './Header';

class InclusionExclusionModal extends React.Component {
  constructor(props) {
    super(props);
    this.tabbingFn = this.tabbingFn.bind(this);
    this.state = {
      activeCard: 0,
      collapseActiveCard: true
    };
  }

  tabbingFn(index, nextState) {
    this.setState({
      activeCard: index,
      collapseActiveCard: nextState
    });
  }

  static navigationOptions = {
    header: null
  };

  render() {
    const {usefulLinks} = this.props;
    let headerLabel = '';
    const inclusionList = [];
    if (!isEmpty(usefulLinks.inclusions)) {
      headerLabel = 'Inclusions';
      inclusionList.push({
        heading: 'Inclusions',
        data: usefulLinks.inclusions
      });
    }
    if (!isEmpty(usefulLinks.exclusions)) {
      if (headerLabel.length > 0) {
        headerLabel += ' & ';
      }
      headerLabel += 'Exclusions';
      inclusionList.push({
        heading: 'Exclusions',
        data: usefulLinks.exclusions
      });
    }
    return (
      <View style={styles.modalWrapper}>
        <View style={AtomicCss.marginBottom10}>
          <Header
            title={headerLabel}
            headerShadow
            pageName={this.props.pageName}
            goBack={this.props.onBackPopUp}
            navigation={this.props.navigation}/>
        </View>
        <ScrollView>
          {
            inclusionList.map((item, index) => {
              const isActive = index === this.state.activeCard ? this.state.collapseActiveCard : false;
              return (
                <IncExcCollapsed
                  item={item}
                  key={index}
                  index={index}
                  isActive={isActive}
                  tabbingFn={this.tabbingFn}
                />
              );
            })
          }
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  modalWrapper: {
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: '#f2f2f2',
    position: 'absolute',
    elevation: 3,
    zIndex: 3,
    width: '100%',
    height: '100%'
  }
});

InclusionExclusionModal.propTypes = {
  usefulLinks: PropTypes.object.isRequired,
  onBackPopUp: PropTypes.func.isRequired,
  pageName: PropTypes.func.isRequired
};

export default InclusionExclusionModal;
