import { botmanSensorHeader, fetchJson, fetchPostJson } from '@mmt/core/network';
import { Platform } from 'react-native';
import { CardDetailResponse, FareAPIRequest, FareAPIResponse } from '../types/APITypes';

const API_ENDPOINT = 'https://cabs.makemytrip.com';

export async function getCardDetails(hotel_details): Promise<CardDetailResponse> {
  const sensorHeader = await botmanSensorHeader();
  const header = {
    os: Platform.OS,
    ...sensorHeader,
  };
  return fetchJson(`${API_ENDPOINT}/crosssell/hotel/v3/widget?lat=${hotel_details?.latitude}&lng=${hotel_details?.longitude}`, {
    headers: header,
  });
}

export async function getFareDetails(request: FareAPIRequest): Promise<FareAPIResponse> {
  const sensorHeader = await botmanSensorHeader();
  const header = {
    os: Platform.OS,
    ...sensorHeader,
  };
  return fetchPostJson(`${API_ENDPOINT}/crosssell/hotel/v2/widget/checkFare`, request, header);
}
