import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, Image } from 'react-native';
import styles from './RaiseRefundCardCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { RUPEES_ICON } from '../../../../FlightImageConstants';
import { getFont } from '../../../../../PostSalesStyles';

const ClaimRaised = ({ cardText, countView, title }) => {
  const { fsStyle, ...fonts } = getFont(true);
  return (
    <View style={[countView ? null : AtomicCss.marginBottom10]}>
      <View style={[styles.infoCardWrapper]}>
        <View style={styles.headingIconWrapper}>
          {countView ? countView : <Image style={styles.headingIconStyle} source={RUPEES_ICON} />}
        </View>
        <View style={styles.infoCardContent}>
          <Text
            style={[
              AtomicCss.defaultText,
              fonts.blackFontFamily,
              fsStyle.font14,
              AtomicCss.marginBottom15,
            ]}
          >
            {title}
          </Text>
          <Text
            style={[
              fonts.regularFontFamily,
              fsStyle.font14,
              AtomicCss.defaultText,
              AtomicCss.lineHeight20,
            ]}
          >
            {cardText}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default ClaimRaised;

ClaimRaised.propTypes = {
  cardText: PropTypes.string.isRequired,
};

ClaimRaised.defaultProps = {
  title: 'REFUND CLAIM RAISED',
};
