import {NativeModules, Platform} from 'react-native';
import { getRootTag } from '../AppState/RootTagHolder';

const visaDownload = NativeModules.VisaBookingModule.downloadVisa;
NativeModules.VisaBookingModule.downloadVisa = (data) => {
  if (Platform.OS === 'ios') {
    const reactTag = getRootTag();
    visaDownload(data, reactTag);
  } else {
    visaDownload(data);
  }
};

export default NativeModules.VisaBookingModule;
