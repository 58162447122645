import React from 'react';
import {Text, TouchableOpacity, Image} from 'react-native';
import styles from './ghostButtonCss';

const GhostButton = ({
  title, icon, containerStyle, onPresshandler
}) => {
  const onPress = () => {
    if (onPresshandler) {
      onPresshandler();
    }
  };

  return (
    <TouchableOpacity style={[styles.btn, containerStyle]} onPress={onPress}>
      {icon ? <Image source={icon} style={styles.btnIcon} /> : null}
      <Text style={styles.btnText}>{title}</Text>
    </TouchableOpacity>
  );
};


export default GhostButton;
