import React from 'react';
import { Image } from 'react-native';
import { RNCardContainer, RNLabel, RNView, RNRowContainer, RNTouchableOpacity, RNIcon, RNInfoChip, RNHyperLink, RNDivider } from 'core-rn-ui-factory';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { useTheme } from '../../../../theme/theme.context';
import createStyles from './dateChangeStyle';
import isEmpty from 'lodash/isEmpty';
import { View } from 'react-native-animatable';

interface RoomDetailsCardProps {
  roomDetailsCard: {
    notificationStrip: {
      theme: string;
      iconUrl: string;
      message: string;
    };
    heading: string;
    roomDetailsHeader: {
      roomImgUrl: string;
      imgCount: string;
      title: string;
      guestDetails: {
        iconUrl: string;
        description: string;
      };
      roomDimension: {
        iconUrl: string;
        description: string;
      };
      bedDetails: {
        iconUrl: string;
        description: string;
      };
    };
    roomInclusionList: {
      iconUrl?: string;
      description: string;
    }[];
    actionList: {
      actionLabelText: string;
    }[];
  };
  openPropertyDetails: () => void;
  openRoomPhotos: () => void;
}

const RoomDetailsCard: React.FC<RoomDetailsCardProps> = ({ roomDetailsCard, openPropertyDetails, openRoomPhotos }) => {
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme);

  if(isEmpty(roomDetailsCard)){
    return null;
  }

  return (
    <RNCardContainer style={[styles.cardContainer, AtomicCss.whiteBg]}>
      {!isEmpty(roomDetailsCard.notificationStrip) &&
        <RNInfoChip
          style={styles.infoChip}
          type={roomDetailsCard.notificationStrip.theme}
          icon={roomDetailsCard.notificationStrip.iconUrl}
          text={roomDetailsCard.notificationStrip.message}
        />
      }

      <RNView>
        {!!roomDetailsCard.heading && <RNLabel size="mediumW2" shade="highEmphasis">{roomDetailsCard.heading}</RNLabel>}
        {!isEmpty(roomDetailsCard.roomDetailsHeader) &&
          <RNRowContainer style={[AtomicCss.marginTop12, AtomicCss.justifyStart]}>
            {!!roomDetailsCard.roomDetailsHeader.roomImgUrl &&
              <RNTouchableOpacity testID="dc_review_image" onPress={openRoomPhotos}>
                <Image
                  source={{
                    uri: roomDetailsCard.roomDetailsHeader.roomImgUrl,
                  }}
                  style={{ borderRadius: 16, width: 94, height: 94 }}
                  alt="hotel-image"
                />
                { !!roomDetailsCard.roomDetailsHeader.imgCount &&
                  <RNView style={styles.imageCount}>
                    <RNLabel testID="room_image_count" size="baseW1" shade="white">{roomDetailsCard.roomDetailsHeader.imgCount}</RNLabel>
                  </RNView>
                }
              </RNTouchableOpacity>
            }

            <RNView style={[AtomicCss.marginLeft12, AtomicCss.flex1]}>
              {!!roomDetailsCard.roomDetailsHeader.title && <RNLabel size="mediumW2" shade="medium">{roomDetailsCard.roomDetailsHeader.title}</RNLabel>}

              {!isEmpty(roomDetailsCard.roomDetailsHeader.guestDetails) &&
                <RNRowContainer style={[AtomicCss.marginTop8, AtomicCss.justifyStart]}>
                  {!!roomDetailsCard.roomDetailsHeader.guestDetails.iconUrl &&
                    <RNIcon
                      source={{ uri: roomDetailsCard.roomDetailsHeader.guestDetails.iconUrl }}
                      size="sm"
                    />
                  }
                  {!!roomDetailsCard.roomDetailsHeader.guestDetails.description &&
                    <RNLabel style={AtomicCss.marginLeft8} size="baseW1" shade="medium">{roomDetailsCard.roomDetailsHeader.guestDetails.description}</RNLabel>}
                </RNRowContainer>
              }

              {!isEmpty(roomDetailsCard.roomDetailsHeader.roomDimension) &&
                <RNRowContainer style={[AtomicCss.marginTop8, AtomicCss.justifyStart]}>
                  {!!roomDetailsCard.roomDetailsHeader.roomDimension.iconUrl &&
                    <RNIcon
                      source={{ uri: roomDetailsCard.roomDetailsHeader.roomDimension.iconUrl }}
                      size="sm"
                    />
                  }
                  {!!roomDetailsCard.roomDetailsHeader.roomDimension.description &&
                    <RNLabel style={AtomicCss.marginLeft8} size="baseW1" shade="medium">{roomDetailsCard.roomDetailsHeader.roomDimension.description}</RNLabel>}
                </RNRowContainer>
              }

              {!isEmpty(roomDetailsCard.roomDetailsHeader.bedDetails) &&
                <RNRowContainer style={[AtomicCss.marginTop4, AtomicCss.justifyStart]}>
                  {!!roomDetailsCard.roomDetailsHeader.bedDetails.iconUrl &&
                    <RNIcon
                      source={{ uri: roomDetailsCard.roomDetailsHeader.bedDetails.iconUrl }}
                      size="sm"
                    />
                  }
                  {!!roomDetailsCard.roomDetailsHeader.bedDetails.description &&
                    <RNLabel style={AtomicCss.marginLeft8} size="baseW1" shade="medium">{roomDetailsCard.roomDetailsHeader.bedDetails.description}</RNLabel>
                  }
                </RNRowContainer>
              }

            </RNView>
          </RNRowContainer>
        }
      </RNView>

      <RNDivider
        marginTop={16}
        marginBottom={0}
        height={1}
      />

      <RNView style={AtomicCss.marginTop10}>
        {roomDetailsCard.roomInclusionList?.map((inclusion, index) => {
          return (
            <RNView
              key={index}
            >
              {!!inclusion.iconUrl &&
                <RNIcon
                  size="sm"
                  source={{ uri: inclusion.iconUrl }}
                />
              }
              {!inclusion.iconUrl && !!inclusion.description &&
                <RNView style={styles.bulletCircle}></RNView>
              }
              {!!inclusion.description &&
                <RNLabel
                  style={AtomicCss.marginLeft20}
                  size="baseW1"
                  shade="medium">
                  {inclusion.description}
                </RNLabel>
              }
            </RNView>
          );
        })}
      </RNView>

      {roomDetailsCard.actionList?.map((action, index) => {
        return (
          <RNHyperLink
            key={index}
            style={styles.roomDetailsCardAction}
            text={action.actionLabelText}
            onPress={openPropertyDetails}
          />
        );
      })}

    </RNCardContainer>
  );
};

export default RoomDetailsCard;