export const defaultMinOutstationSearchTatHrs = 3;
export const defaultMinLocalSearchTatHrs = 3;

const TripType = Object.freeze({
  RT: {
    value: 'RT',
    name: 'Round Trip',
    message: 'Retain cab till return & include multiple stops',
    defaultAP: defaultMinOutstationSearchTatHrs,
  },
  OW: {
    value: 'OW',
    name: 'One Way',
    message: 'Includes one pick up & drop',
    defaultAP: defaultMinOutstationSearchTatHrs,
  },
  AT: {
    value: 'AT',
    name: 'Airport Cabs',
    message: 'Pre-book airport pick ups & drops',
    defaultAP: defaultMinLocalSearchTatHrs,
  },
  HR: {
    value: 'HR',
    name: 'Hourly Rentals',
    message: '',
    defaultAP: defaultMinLocalSearchTatHrs,
  },
});

export const AirportPickupType = Object.freeze({
  FROM: {
    value: 1,
    name: 'from',
    type: 'FROM_AIRPORT',
    displayName: 'Travel From Airport',
  },
  TO: {
    value: 0,
    name: 'to',
    type: 'TO_AIRPORT',
    displayName: 'Travel To Airport',
  },
});

export const TravelType = Object.freeze({
  OUTSTATION: {
    name: 'Outstation Cabs',
    defaultTripType: TripType.OW.value,
    allTripTypes: [TripType.OW.value, TripType.RT.value],
  },
  AIRPORT: {
    name: 'Airport Cabs',
    defaultTripType: TripType.AT.value,
    allTripTypes: [TripType.AT.value],
  },
  RENTALS: {
    name: 'Hourly Rentals',
    defaultTripType: TripType.HR.value,
    allTripTypes: [TripType.HR.value],
  },
  //TODO @rajeshbatth Remove this
  SELF_DRIVE: {
    name: 'Self Drive',
    defaultTripType: 'SD',
    allTripTypes: ['SD'],
  },
});

export default TripType;
