import React from 'react';
import PropTypes from 'prop-types';
import { Text, View } from 'react-native';
import CommonStyle from '@mmt/legacy-commons/Styles/AtomicCss';
import createStyles from './GSTDetailCardCss';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { DateChangeConstants } from '../../../../constants';

const GSTDetailCard = (props) => {
  const { gst } = props;
  const {psTheme : theme} = useTheme();
  const fonts = getFont(true)
  const styles = createStyles(theme,fonts);
  return (
    <View>
      <Text style={styles.cardHead}>{DateChangeConstants.GST_DETAILS}</Text>
      <View style={CommonStyle.marginBottom25}>
        <Text style={styles.gsttxtheading}>{gst.gstNo.title}</Text>
        <Text style={styles.gsttxtinfo}>{gst.gstNo.value}</Text>
      </View>
      <View style={CommonStyle.marginBottom25}>
        <Text style={styles.gsttxtheading}>{gst.cname.title}</Text>
        <Text style={styles.gsttxtinfo}>{gst.cname.value}</Text>
      </View>
    </View>
  );
};

GSTDetailCard.propTypes = {
  gst: PropTypes.object.isRequired,
};

export default GSTDetailCard;
