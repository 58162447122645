import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Image, Text, TouchableOpacity, View } from 'react-native';
import createStyles from './styles';
import CommonStyle from '@mmt/legacy-commons/Styles/AtomicCss';
import {getImageIcon } from "../../../../helper";
import { GROPU_3_ICON } from '../../../../../../FlightImageConstants';
import { getStaticData } from '../../../../../../../staticData/staticData';
import { getFont } from '../../../../../../../PostSalesStyles';
import { useTheme } from '../../../../../../../theme/theme.context';

const CancelledPaxDetails = (props) => {

  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);
  const [moreTraveller, setMoreTraveller] = useState(false);

  const handleMoreTravler = () => {
    setMoreTraveller(prev => !prev);
  };

  const showAllPax = () => {
    const { fsStyle, ...fonts } = getFont(true);
    const views = [];
    for (let i = 1; i < props.paxNames.length; i += 1) {
      views.push(
        <View style={[CommonStyle.flexRow]}>
          <Image style={styles.UserIcon} source={GROPU_3_ICON} />
          <Text style={[styles.refundtopDisc, fonts.regularFontFamily, fsStyle.font12]}>
            {props.paxNames[i]}
          </Text>
        </View>,
      );
    }
    return views;
  }

  const getMorePaxText = () => {
    const { showLessText, travellerText } = getStaticData();
    if (moreTraveller) {
      return `- ${showLessText}`;
    }
    const remainingNoOfPax = props.paxNames.length - 1;
    return ` +${remainingNoOfPax} ${travellerText}`;
  };

  const getSegmentWiseRefundMessage = () => {
    const { fsStyle, ...fonts } = getFont(true);
    const views = [];
    props.messages.forEach((attribute) => {
      if (attribute) {
        const attributeObj = attribute;
        const imageIcon = getImageIcon(attributeObj.attributImg);
        views.push(
          <View key={attributeObj.attributSubTitle} style={CommonStyle.flexRow}>
            {imageIcon && <Image style={styles.zcIconSm} source={imageIcon} />}
            <Text
              style={[
                styles.refundtopDisc,
                fonts.regularFontFamily,
                { color: attributeObj.attributSubTitleColor },
                fsStyle.font12,
              ]}
            >
              {attributeObj.attributSubTitle}
            </Text>
          </View>,
        );
      }
    });
    return views;
  };


  return (
    <View
      style={[styles.paxCancelledinnderDtls, props.isBorderTop ? styles.topGreyBorder : '']}
    >
      <Text
        style={[
          styles.fltDtlsTxt,
          fonts.blackFontFamily,
          CommonStyle.marginBottom10,
          CommonStyle.alignLeft,
          fsStyle.font14,
        ]}
      >
        {props.segmentWithDate}
      </Text>
      <View style={[CommonStyle.flexRow]}>
        <Image style={styles.UserIcon} source={GROPU_3_ICON} />
        <Text style={[styles.refundtopDiscPax, fonts.regularFontFamily, fsStyle.font12]}>
          {props.paxNames[0]}
        </Text>
        {props.paxNames.length > 1 && (
          <TouchableOpacity activeOpacity={0.9} onPress={() => handleMoreTravler()}>
            <Text style={[styles.moreTraveller, fonts.boldFontFamily, fsStyle.font12]}>
              {getMorePaxText()}
            </Text>
          </TouchableOpacity>
        )}
      </View>
      {moreTraveller && showAllPax()}
      {getSegmentWiseRefundMessage()}
    </View>
  );
}

CancelledPaxDetails.propTypes = {
  segmentWithDate: PropTypes.string.isRequired,
  paxNames: PropTypes.array.isRequired,
  isBorderTop: PropTypes.bool.isRequired,
};

export default CancelledPaxDetails;
