const ACME_BOOKING_ROUTE_KEYS_ARRAY = [
  {
    key: 'acmeCancellationPolicy',
    component: () => require('./details/components/AcmeCancellationPolicyStandAlone').default
  },
  {
    key: 'acmeBookingDetail',
    component: () => require('./details/AcmeBookingDetail').default
  },
  {
    key: 'acmeSchedule',
    component: () => require('./details/components/AcmeSchedule').default
  },
  {
    key: 'acmeThingsToCarry',
    component: () => require('./details/components/AcmeThingsToCarry').default
  },
  {
    key: 'acmeCancellationReview',
    component: () => require('./cancellation/AcmeCancellationReview').default
  },
  {
    key: 'acmeCancellationThankyou',
    component: () => require('./cancellation/AcmeCancellationThankyou').default
  },
  {
    key: 'acmeTermsConditions',
    component: () => require('./details/components/AcmeTermsAndConditions').default
  },
  {
    key: 'acmeFaq',
    component: () => require('./details/components/AcmeFaq').default
  },
  {
    key: 'acmeRedemptionSteps',
    component: () => require('./details/components/AcmeRedemptionSteps').default
  },
  {
    key: 'acmeCancellationRefundMode',
    component: () => require('./cancellation/AcmeCancellationRefundMode').default
  },
]

export default ACME_BOOKING_ROUTE_KEYS_ARRAY;