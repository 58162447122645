import { ViewStyle, TextStyle, ImageStyle, StyleSheet } from 'react-native';
import { DEFAULT_THEME } from 'apps/post-sales/src/theme/default.theme';

export interface Styles {
  imageContainer: ViewStyle;
  imageIcon: ImageStyle;
  rightBlueArrow: ImageStyle;
  bottomLink: ViewStyle;
  textStyle: TextStyle;
}

const createStyles = <Theme extends typeof DEFAULT_THEME>(theme: Theme) =>
  StyleSheet.create<Styles>({
    imageContainer: {
      marginRight: 10,
    },
    imageIcon: {
      width: 32,
      height: 32
    },
    rightBlueArrow: {
      width: 16,
      height: 16,
      tintColor: theme.color.azure,
    },
    bottomLink: {
      paddingLeft: 20,
      paddingRight: 24,
      paddingTop: 20,
      paddingBottom: 15,
      borderTopWidth: 1,
      borderColor: theme.color.greyBookedSeat,
    },
    textStyle: {
      width: '90%',
      color: theme.color.redirectLinkTextColor,
    }
  });

export default createStyles;