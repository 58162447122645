import React from 'react';
import {View, Text, TouchableOpacity, Image} from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from './getPlatformElevation';
import {crossIcon1} from '../HolidayBookingConstants';

class HeaderCross extends React.Component {
  render() {
    const headerStyle = [styles.headerWrapper];
    if (this.props.headerShadow) {
      headerStyle.push(styles.headerShadow);
    }

    return (
      <View style={headerStyle}>
        <TouchableOpacity onPress={() => this.props.goBack(this.props.pageName)}>
          <Image style={[styles.crossIconStyle]} source={crossIcon1}/>
        </TouchableOpacity>
        <View style={[AtomicCss.flex1]}>
          <Text
            style={[styles.headingTxt, AtomicCss[this.props.headingTxt]]}>{this.props.title}</Text>
          {this.props.headingSubTxt &&
          <Text style={[styles.headingSubTxt]}>{this.props.subTitle}</Text>
          }
        </View>

      </View>
    );
  }
}

const styles = {
  headerWrapper: {
    backgroundColor: '#fff',
    justifyContent: 'space-between',
    padding: 16,
    flexDirection: 'row',
    alignItems: 'center',
    zIndex: 10

  },
  headerShadow: {
    ...getPlatformElevation(2)
  },
  headingTxt: {
    fontSize: 14,
    color: '#4a4a4a',
    fontFamily: fonts.bold
  },
  headingSubTxt: {
    fontSize: 12,
    color: '#4a4a4a',
    fontFamily: fonts.regular
  },
  crossIconStyle: {
    width: 14,
    height: 14,
    marginRight: 15
  }
};

export default HeaderCross;
