import {
  DATE_MONTH,
  POSTSALES_DATE_TIME,
  reformatDateString,
} from '@mmt/legacy-commons/Helpers/dateTimehelpers';
import myTripsNavigation from '../../../navigation/navigation';
import upcomingBookingIcon from '@mmt/legacy-assets/src/postSales/summary/upcomingHolidaysIocn.webp';
import completedBookingIcon from '@mmt/legacy-assets/src/postSales/summary/completedHolidaysIcon.webp';
import cancelledBookingIcon from '@mmt/legacy-assets/src/postSales/summary/cancelledHolidaysIcon.webp';
import failedBookingIcon from '@mmt/legacy-assets/src/postSales/summary/failedHolidaysIcon.webp';
import { getStaticData } from '../../../../staticData/staticData';
import TripSummaryOmnitureTracker from '../../../tracking/TripSummaryOmnitureTracker';
import {
  getDateTimeWithPaxInfo,
  unsuccessfulBookingTextHandler,
} from '../../../data/tripListManager';
import { HOLIDAY_MINIMAL_BOOKING_TYPE } from '../../../../Holiday/HolidayBookingConstants';
import { getNeedHelpData } from '../../../utils';

export function getHolidaySummaryData(data, msrTicket) {
  const staticData = getStaticData();
  let fromDate = reformatDateString(data.minDeptDate, POSTSALES_DATE_TIME, DATE_MONTH);
  let toDate = reformatDateString(data.maxArrDate, POSTSALES_DATE_TIME, DATE_MONTH);
  const pageData = {
    fromMyTrips: true,
  };
  const openDetails = () => {
    myTripsNavigation.openHolidayBookingDetail(data, pageData);
  };
  const failedBooking = data.status === 'FAILED';
  let descriptionText = '';
  let iconUrl = '';
  let summary =
    data.totalPax > 1
      ? `${data.leadPaxFirstName} + ${data.totalPax - 1} `
      : `${data.leadPaxFirstName}`;
  let dateTime = `${fromDate} - ${toDate} • ${summary}`;
  if (data.isMinimalMima) {
    dateTime = data.holidayType === HOLIDAY_MINIMAL_BOOKING_TYPE.FD ? summary : '';
  }
  const needHelpData = getNeedHelpData(msrTicket, data.bookingID, data.needHelpData, data.showQuickResolve);
  
  switch (data.status) {
    case 'UPCOMING':
      iconUrl = upcomingBookingIcon;
      break;
    case 'COMPLETED':
      iconUrl = completedBookingIcon;
      break;
    case 'CANCELLED':
      iconUrl = cancelledBookingIcon;
      dateTime = getDateTimeWithPaxInfo(data, staticData.cancelledText, dateTime, summary);
      break;
    case 'FAILED':
      iconUrl = failedBookingIcon;
      descriptionText = staticData.bookingSummaryText.additionalText.holidayBookingFailedText;
      dateTime = getDateTimeWithPaxInfo(data) || unsuccessfulBookingTextHandler(dateTime);
      break;
    default:
      iconUrl = upcomingBookingIcon;
      break;
  }
  const actions = [];
  if (data.status === 'UPCOMING' && !data.farelockdata && data.webCheckInApplicable) {
    actions.push({
      label: staticData.bookingSummaryText.additionalText.completeWebCheckinText,
      onPress: () => {
        openDetails();
        TripSummaryOmnitureTracker.trackBookingClickEvent(
          'holiday',
          data.status,
          data.bookingID,
          'Complete_Web_Checkin',
        );
      },
    });
  }
  return {
    status: data.status,
    iconUrl: iconUrl,
    descriptionText,
    dateTime,
    packageName: data.packageName,
    persuasions: [],
    actions,
    openDetails,
    failedBooking,
    needHelpData,
  };
}

export default {};
