import React from 'react';
import {Image, Text, View} from 'react-native';
import PropTypes from 'prop-types';
import styles from '../../CSS/VisaMamiCss';
import {getActionButton, getButtonStyle, isNotNullAndEmpty} from '../../../hotel/details/utils/HotelBookingDetailUtil';
import {refundIcon} from '../../Constant/VisaImageConstant';
import isEmpty from 'lodash/isEmpty';

class VisaCancellationPolicyCard extends React.Component {
  render() {
    return (
      <View style={[styles.MamiCard, styles.mar10Bt]} >
        <View style={styles.VisaCardContent}>
          <View style={styles.flexRow}>
            <Image style={styles.CardIcon} source={refundIcon} />
            <View style={styles.flex1}>
              <Text style={[styles.cancellationPolicyCard]}>
                  CANCELLATION POLICY
              </Text>
              {!isEmpty(this.props.response.visaCancellationPolicyV2) && this.props.response.visaCancellationPolicyV2.map((item)=> 
              <Text style={[styles.cancellationPolicyText]}>
                {`\u2022 ${item}`}
              </Text>)}
              
            </View>
          </View>
        </View>
      </View>
    );
  }
}

VisaCancellationPolicyCard.propTypes = {
  response: PropTypes.object.isRequired,
  card: PropTypes.object.isRequired,
  pageName: PropTypes.string.isRequired
};


export default VisaCancellationPolicyCard;
