import React from 'react';
import { Image, Text, TouchableOpacity, View } from 'react-native';
import PropTypes from 'prop-types';
import createStyles from './styles';
import { BACK_ARROW_BLACK_ICON } from '../../../../FlightImageConstants';
import { getFont } from '../../../../../PostSalesStyles';
import { useTheme } from '../../../../../theme/theme.context';
import HeaderWrapper from 'apps/post-sales/src/Common/HeaderWrapper';
const Header = (props) => {
  const headerType = props.type ? `headerType${props.type}` : '';
  const { headerData } = props;
  const {tripType, journeyDetails, ownwardDate, returnDate } = headerData || {};
  const { psTheme: theme } = useTheme();
  const fonts = getFont(true);
  const styles = createStyles(theme, fonts);
  return (
    <HeaderWrapper
      titleText={props.title}
      closeIcon={BACK_ARROW_BLACK_ICON}
      wrapperStyle= {[styles.headerWrapper, styles[headerType]]}
      titleStyle={{...fonts.boldFontFamily }}
      iconStyle={styles.backArrow}
      titleNoOfLines={1}
      descText={
        <>
        {props.type === 'Details' && (
          <Text style={styles.headerDescText}>
            <Text>{tripType} </Text> 
            <Text>{journeyDetails}{' '}</Text>
            <Text>{ownwardDate}{' '}</Text>
            <Text>{returnDate}</Text>
          </Text>
        )}
        </>
    }
      clickHandler={props.onBackPress}
  />


  );
};
Header.propTypes = {
  onBackPress: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  headerData: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default Header;
