import { Platform } from 'react-native';
import { AIRLINE_IMG_URL } from '../../../constants';
import { getScreenDensityName } from '@mmt/legacy-commons/Helpers/displayHelper';
import { getStaticData } from '../../../../../../staticData/staticData';
import isEmpty from 'lodash/isEmpty';

export const AIRLINE_IMAGE_BASEURL = `https://imgak.mmtcdn.com/flights/assets/media/mobile/common/${
  Platform.OS === 'ios' ? getScreenDensityName().toUpperCase() : getScreenDensityName()
}/{CODE}.png?v=2`;

export function getNoOfStopsText(stops) {
  const { stopText, nonStopText } = getStaticData();
  return stops > 0 ? `${stops} ${stopText}` : `${nonStopText}`;
}

export function getAirlineImage(airlineCode) {
  return `${AIRLINE_IMG_URL}/${airlineCode}.png`;
}

export function getQuckFltrLst(filterInfo) {
  const { quickFilterText1, quickFilterText2 } = getStaticData();
  const arr = [];
  arr.push(quickFilterText1);
  arr.push(quickFilterText2);
  if (!isEmpty(filterInfo.alFltrLst)) {
    filterInfo.alFltrLst.forEach((alFltr) => {
      arr.push(alFltr.nm);
    });
  }
  return arr;
}

export function isFilterDisabled(fltrMtDta) {
  if (fltrMtDta === undefined) return true;
  if (fltrMtDta.numFlt === 0) return true;
  return false;
}

export const setQuickFltrs = (currFltrObj) => {
  const arr = [];
  arr.push(currFltrObj.itinFltrLst[0].currStpLst[0]);
  arr.push(currFltrObj.itinFltrLst[0].currDepTmLst[1]);
  currFltrObj.alFltrLst.forEach((alFltr) => {
    arr.push(alFltr.selected);
  });
  return arr;
};
