import React from 'react';
import PropTypes from 'prop-types';
import {Image, Text, View} from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getStyles from './HotelMamiCss';
import {
  getActionButton, getButtonStyle, handleClickEvent,
  isNotNullAndEmpty
} from '../utils/HotelBookingDetailUtil';
import { BLACK_INFO_ICON, WHITE_BG_INFO_ICON } from '../../HotelImageConstants';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const infoIcon = WHITE_BG_INFO_ICON;

class HotelCtripBookingAwaitedCard extends React.Component {
  constructor(props) {
    super(props);
    this.fonts = getFont(true);
    this.styles = getStyles(this.fonts);
    this.fsStyle = this.fonts.fsStyle;
  }
  render() {
    if (!this.props.response.cTripWHNHeaderTxt || !this.props.response.cTripWHNMainTxt) {
      return null;
    }
    return (
      <View style={[AtomicCss.whiteCardNew, this.styles.mar10Bt, AtomicCss.marginHz12, AtomicCss.borderRadius16]}>

        <View style={this.styles.CardContentNew}>
          <Image
            style={this.styles.CardIcon}
            source={infoIcon}
          />
          <View style={this.styles.ContentPortion}>
            <Text style={[this.styles.blackFont, this.styles.BlackText, this.styles.mar10Bt, this.fsStyle.font16, AtomicCss.lineHeight20]}>
              {this.props.response.cTripWHNHeaderTxt}
            </Text>
            <Text style={[this.fsStyle.font14, AtomicCss.lineHeight20, this.styles.defaultGrey]}>{this.props.response.cTripWHNMainTxt}</Text>
          </View>
        </View>

        <View>
				  {this.props.card && isNotNullAndEmpty(this.props.card.actionList) &&
					  getActionButton(this.props.card, this.props.response, this.handleClick)}
			  </View>
      </View>
    );
  }

  handleClick = (action) => {
    handleClickEvent(this.props.card, action, this.props.response, this.props.pageName);
  };
}

HotelCtripBookingAwaitedCard.propTypes = {
  response: PropTypes.object.isRequired,
  card: PropTypes.object.isRequired,
  pageName: PropTypes.string.isRequired
};

export default HotelCtripBookingAwaitedCard;
