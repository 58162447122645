import React from 'react';
import Actions from '../../../navigation/postSalesNavigation';
import PropTypes from 'prop-types';
import {Image, ScrollView, Text, View} from 'react-native';
import getStyles from './HotelMamiCss';
import CommonHeader from '../../details/components/CommonHeader';
import {isIntlHotelBooking} from '../utils/HotelBookingDetailUtil';
import HotelBookingTrackingHelper from '../../HotelBookingTrackingHelper';
import { getStaticData } from '../../../staticData/staticData';
import { CIRCLE_CROSS_ICON, WHITE_CROSS_ICON } from '../../HotelImageConstants';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

class HotelCancellationPolicyRules extends React.Component {
  static navigationOptions = {
    header: null
  }
  constructor(props) {
    super(props);
    this.fonts = getFont(true);
    this.styles = getStyles(this.fonts);
  }

  render() {
    const staticData = getStaticData();
    const hotelDetailList = this.props.response.hotelBookingInfo.hotelDetailsList[this.props.response.hotelBookingInfo.hotelDetailsList.length - 1];
    const cancelIcon = CIRCLE_CROSS_ICON;
    return (
      <View style={[this.styles.whiteContainer, this.styles.transperantBgrnd]}>
        <CommonHeader
          headerText={staticData.cancellationPolicyText}
          imgSrc={WHITE_CROSS_ICON}
          backPressHandler={this.onBackPress}
        />
        <ScrollView>
          <View style={this.styles.PolicyHeading}>
            <Image style={this.styles.CrossIcon} source={cancelIcon} />
            <Text style={[this.styles.BlackText, this.styles.blackFont, this.styles.PolicyHeadingText, this.styles.AlignLeft]}>{staticData.cancellationPolicyCapsText}</Text>
          </View>
          <View style={this.styles.PolicyDetailsContainer}>
            {this.showHotelPolicy(hotelDetailList.cancellationRules)}
          </View>
        </ScrollView>
      </View>
    );
  }
  componentDidMount() {
    const trackPageName = isIntlHotelBooking(this.props.response?.lobCode)
      ? 'mob:customer support:Hotel International:CancellationPolicy'
      : 'mob:customer support:Hotel Domestic:CancellationPolicy';
    HotelBookingTrackingHelper.trackLoadEvent(trackPageName, this.props.response);
  }

  onBackPress = () => {
    Actions.pop();
  }

  showHotelPolicy(hotelPolicy) {
    const views = [];
    const hotelPolicyList = hotelPolicy.split(';');
    hotelPolicyList.forEach((hotelPolicyObject) => {
      this.createHotelPolicyView(views, hotelPolicyObject);
    });
    return views;
  }

  createHotelPolicyView(views, hotelPolicyObject) {
    views.push(<View style={[this.styles.mar15Bt, this.styles.flexRow]} key={hotelPolicyObject}>
      <View style={this.styles.ItemDisc} />
      <Text style={this.styles.AlignLeft}>{hotelPolicyObject}</Text>
    </View>);
  }
  onBackPressed = () => {
    Actions.pop();
  }
}
HotelCancellationPolicyRules.propTypes = {
  response: PropTypes.object.isRequired
};
export default HotelCancellationPolicyRules;
