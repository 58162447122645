import { LANGUAGE_MAP, PROFILE_TYPE } from '../Common/constants/AppConstants';

let userSelectedLanguage = LANGUAGE_MAP.eng;

export const setUserSelectedLanguage = (val) => {
  userSelectedLanguage = LANGUAGE_MAP[val] || LANGUAGE_MAP.eng;
};

export const getUserSelectedLanguage = () => {
  return userSelectedLanguage;
};

export const isEnglishLangSelected = () => {
  const appLang = getUserSelectedLanguage();
  return appLang === LANGUAGE_MAP.eng;
};


let profileType = PROFILE_TYPE.PERSONAL

export const setUserProfileType = (val) => {
  profileType = val;
}

export const getUserProfileType = () => {
  return profileType;
}

let region = "in";

export const setUserCountryCode = (val) => {
  region = val;
}

export const getUserCountryCode = () => {
  return region;
}
