import {NativeModules} from 'react-native';
import cookie from 'react-cookies';
import {getCookie as gdprGetCookie, setCookie as gdprSetCookie, removeCookie as gdprRemoveCookie, itemType } from 'MMT-UI/storages/client/cookieStorage';
import _ from 'lodash';
import { MY_BUSINESS_SUBSCRIPTION, GDPR_REGION_COOKIE } from '../const';
import { _getMmtAuth as getMmtAuth } from '../webUtils';

const _isUserLoggedIn = () => {
  const mmtAuth = getMmtAuth();
  return Promise.resolve(!_.isEmpty(mmtAuth));
};

const _getUserDetails = ()=>{
  throw new Error("Method not implemented in Web")
}

const _isCorporateUser = () => {
  const isCorporateUser = gdprGetCookie(MY_BUSINESS_SUBSCRIPTION, { isPiiData: true}, itemType.ES)
  if(_.isEmpty(isCorporateUser) || isCorporateUser === "b2c") {
    return false;
  }
  return true;
}

const _isGdprRegion = () => {
  const isGdprRegion = gdprGetCookie(GDPR_REGION_COOKIE, { isPiiData: true}, itemType.ES);
  if(isGdprRegion && isGdprRegion === '1'){
    return true;
  }
  return false;
}

export const _getMmtAuth = async (act = '') => {
  if (act === '301') {
    return Promise.resolve('');
  }
  const mmtAuth = getMmtAuth();
  if (_.isEmpty(mmtAuth)) {
    return Promise.reject(new Error('User not logged in'));
  }
  return Promise.resolve(mmtAuth);
};

export const getUserConsent = ()=> {
  return;
}

NativeModules.UserSessionModule = {
  isUserLoggedIn: _isUserLoggedIn,
  getUserDetails: _getUserDetails,
  getMmtAuth: _getMmtAuth,
  isCorporateUser: _isCorporateUser,
  needConsent: _isGdprRegion,
  getUserConsent,
  gdprGetCookie,
  gdprSetCookie,
  gdprRemoveCookie,
  cookieType: itemType
};
