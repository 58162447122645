//postsales - rails
import {getImagePath} from '../../utils/PostSaleUtil';
export const CANCEL_ICON = getImagePath('ic_cross.webp');
export const CROSS_ICON2 = getImagePath('crossIcon2.webp');
export const HELP_ICON_1 = getImagePath('ic_gotohelpsection.webp');
export const WRITE_TO_US_ICON = getImagePath('write_to_us.webp');
export const CONTACT_ICON = getImagePath('contactIconV2.webp')
export const HELP_ICON_2 = getImagePath('helpIcon.webp');
export const CANCEL_NOW = getImagePath('cancellation-now.webp');
export const SEAT_ICON = getImagePath('seater.webp');
export const TOP_STATUS_ICON = getImagePath('topstatusIcon8.webp');
export const ZC_IMAGE = getImagePath('freeCancellation.webp');
export const REFUND = getImagePath('refund.webp');
export const SINGLE_TICK = getImagePath('single-tick.webp');
export const CANCELLED_IMAGE = getImagePath('topstatusIcon8.webp');
export const GUEST_ICON = getImagePath('guest_icon.webp');
export const RELOAD_ICON = getImagePath('reloadIcon.webp');
export const INFO_IMAGE = getImagePath('info-icon2.webp');
export const CLOCK_ICON = getImagePath('clockIcon.webp');
export const CANCELLATION = getImagePath('cancellation.webp');
export const MALE_IMAGE = getImagePath('male.webp');
export const FEMALE_IMAGE = getImagePath('female.webp');
export const INFANT_IMAGE = getImagePath('infant.webp');
export const TOP_STATUS_ICON_1 = getImagePath('topstatusIcon1.webp');
export const TOP_STATUS_ICON_4 = getImagePath('topstatusIcon4.webp');
export const TOP_STATUS_ICON_8 = getImagePath('topstatusIcon8.webp');
export const ONTRIP_IMAGE = getImagePath('ontrip.webp');
export const READY_TO_BOARD_IMAGE = getImagePath('getReadyToBoard.webp');
export const ERROR_ICON = getImagePath('ErrorIcon1.webp');
export const RED_INFO = getImagePath('red_info.webp');
export const CALENDAR_ICON = getImagePath('calendar.webp');
export const VOUCHER_IMG = getImagePath('voucherImg.webp');
export const REFUND_ICON_IMG = getImagePath('trainRefundIcon.webp');
export const VOUCHER_2X_IMG = getImagePath('voucher2x.webp');
export const MAP_LOCATION_IMG = getImagePath('mapLocation.webp');
export const dropDownArrow = getImagePath('blueArrow.webp');
export const COPY_ICON = require('@mmt/legacy-assets/src/copyIcon.webp');
export const NO_CANCELLATION_ICON = getImagePath('noCancellationIcon.webp');
export const CROSS_ICON = getImagePath('crossIcon3.png');
export const GREEN_CIRCLE_TICK = getImagePath('greenCircleTick.png');
export const PRICE_OFFER_ICON = getImagePath('priceOffer.png');
export const TICK_BULLET_ICON = getImagePath('tickbullet.png');
export const LIVE_TRAIN_ICON = getImagePath('liveTrain.png');
export const LINE_ARROW_ICON = getImagePath('lineArrow.png');
export const INFO_BLUE_ICON = getImagePath('infoBlueIcon.webp');
export const INFO_ORANGE_ICON = getImagePath('infoIconOrange.png');
export const GREEN_CIRCLE_TICK2 = getImagePath('GreenCircular.webp');
export const DARK_GREEN_CIRCLE_TICK = getImagePath('darkGreenTick.webp');
export const TGS_ICON = getImagePath('tgsIcon.webp');
export const COPY_ICON_GREEN = getImagePath('copyIcon2.png');
export const CORY_ICON = getImagePath('copyIcon3.png');
export const TRAIN_MATE = getImagePath('trainMate.png');
export const REFRESH_ICON_BLUE = getImagePath('refreshBlue.webp');
export const trainIcon = getImagePath('v5trainIcon.webp');
//Common
export const DOUBLE_TICK = require('@mmt/legacy-assets/src/double_tick.webp');
export const REFUND_ICON = require('@mmt/legacy-assets/src/refundIcon.webp');
export const WHITE_CROSS = require('@mmt/legacy-assets/src/whiteCross.webp');
export const BLUE_DROP_ICON_DOWN = require('@mmt/legacy-assets/src/blueDropIcondown.webp');
export const BLUE_DROP_ICON_UP = require('@mmt/legacy-assets/src/blueDropIconUp.webp');
export const GREEN_TICK = require('@mmt/legacy-assets/src/green_tick.webp');
export const CALL_ICON = require('@mmt/legacy-assets/src/call-icon2.webp');
export const MYRA_HELP_ICON = require('@mmt/legacy-assets/src/MyraImg.webp');
export const CHKBOX_ICON = require('@mmt/legacy-assets/src/chkboxinactive.webp');
export const CHKBOX_ICON_ACTIVE = require('@mmt/legacy-assets/src/chkboxactive.webp');
export const ANDROID_BACK_ARROW = require('@mmt/legacy-assets/src/backArrowBlack.webp');
export const IOS_BACK_ARROW = require('@mmt/legacy-assets/src/backArrowBlackIOS.webp');
export const HEADER_BACK_ICON = require('@mmt/legacy-assets/src/trip_header_back_icon.webp');
export const PAYMENT_ICON = require('@mmt/legacy-assets/src/payment_icon.webp');
export const BACK_ARROW = require('@mmt/legacy-assets/src/back-white.webp');
export const TOP_STATUS_ICON_9 = require('@mmt/legacy-assets/src/topstatusIcon9.webp');
export const IC_FAILED_IMAGE = getImagePath('ic_failed.webp');
export const SUCCESS_ICON = getImagePath('success-icon.webp');
export const REFUND_TRACKER_TICKICON = getImagePath('refund_tracker_tickIcon.webp');
export const BOOKING_CANCEL_PAGE_NAME = 'bookingCancel';
export const TDR_FILED_PAGE_NAME = 'tdrFiledThanks';
export const TG_ICON= getImagePath('tgsIcon.webp');
export const PAY_PENDING_ICON= getImagePath('payPending.webp');
export const SUCCESS_GREEN_TICK= getImagePath('success-green.webp');
