import { StyleSheet } from "react-native";
export default (theme, fonts) =>
  StyleSheet.create({
    span: {
        fontSize: 11,
        color: theme.color.black
    },
    refundVoucherWrapper: {
        paddingHorizontal: 20,
        paddingVertical: 10,
        backgroundColor: theme.color.grey22,
        marginTop: 10,
        borderRadius: 16
    },
    topInfoWrapper: {
        backgroundColor: theme.color.lightYellow2,
        paddingVertical: 4,
        paddingHorizontal: 15,
        borderRadius: 5,
    },
    topInfoText: {
        fontSize: 12,
        color: theme.color.darkYellow,
        ...fonts.regularFontFamily
    },
    refundTrainImg: {
        width: 38,
        height: 25,
        marginRight: 10,
    },
    bullet: {
        width: 6,
        height: 6,
        borderRadius: 3,
        backgroundColor: theme.color.black,
        marginRight: 8,
        marginTop: 8,
    },
    voucherImgStyle: {
        width: 16,
        height: 9,
        marginRight: 10,
        top: 3,
    },
    mmtGuranteeText: {
        color: theme.color.governorBay,
        textTransform: 'uppercase',
        fontSize: 11,
        ...fonts.boldFontFamily
    },
    copyImgStyle: {
        width: 12,
        height: 13,
        marginLeft: 10,
    },
    voucherWrapper: {
        paddingHorizontal: 20,
    },
    downArrow: {
        width: 10,
        height: 4,
        marginLeft: 10,
        marginTop : 5
    },
    activeArrow: {
        transform: [
            {
                rotate: "-180deg"
            }
        ],
    },
    copyIcon: {
        width: 12,
        height: 12,
        marginLeft: 8,
      }
});
