import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';
import { StyleSheet} from 'react-native';

export default (theme, fonts) =>
StyleSheet.create({
  listWrapper: {
    borderRadius: 4,
    overflow: 'hidden',
    borderBottomColor: 'transparent',
    paddingTop: 0,
    paddingBottom: 20,
    ...getPlatformElevation(2)
  },
  listItem: {
    position: 'relative',
    paddingLeft: 40,
    paddingTop: 3,
    marginTop: 15
  },
  listItemTitle: {
    fontSize: 14,
    color: theme.color.defaultTextColor,
    fontFamily: fonts.bold,
    marginBottom: 3
  },
  listItemDesc: {
    fontSize: 14,
    color: theme.color.disabledBtnBg,
    fontFamily: fonts.regular
  },
  listItemIcon: {
    width: 31,
    height: 31,
    position: 'absolute',
    top: 0,
    left: 0
  },
  Email_Icon: {
    width: 19,
    height: 14,
    marginTop: 5
  },
  Call_Icon: {
    width: 19,
    height: 15,
    marginTop: 5
  },
  Refund_Icon: {
    width: 20,
    height: 16,
    marginTop: 5
  },
  whiteCard: {
    backgroundColor: '#ffffff',
    padding: 15,
    marginVertical: -10,
    marginBottom: 8,
    elevation: 2,
    shadowColor: '#000000',
    shadowOffset: {width: 0, height: 0},
    shadowOpacity: 0.3,
    shadowRadius: 1
  }
});
