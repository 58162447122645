import React from 'react';
import {View, Text, Image, Dimensions} from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import {WebCheckInImg, WebCheckIconImgRaised, WEB_CHECKIN_REQUEST_RAISED} from '../HolidayBookingConstants';

export const webChkInStatus = ({status}) => {
  if(!status){
    return null;
  }

  let webCheckinIcon = WebCheckInImg;
  if(status === WEB_CHECKIN_REQUEST_RAISED){
    webCheckinIcon = WebCheckIconImgRaised;
  }
  return (
    <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween, styles.webChecinSection]}>
      <View style={[AtomicCss.flex1, AtomicCss.flexRow]}>
        <View style={styles.webCheckinWrapper}>
          <Image
            style={styles.WebCheckinIcon}
            source={webCheckinIcon}/>
        </View>
        <View style={AtomicCss.flex1}>
          <Text
            style={[AtomicCss.font12, AtomicCss.defaultText,
              AtomicCss.boldFont, AtomicCss.marginBottom3, AtomicCss.marginTop10]}>
            {status}
            </Text>
        </View>
      </View>
    </View>
  );
};

const styles = {
  cardWrapper: {
    width: Dimensions.get('screen').width
  },

  top20Neg: {
    top: -20,
    zIndex: 10
  },
  fltInnerDtlsWrappers: {
    paddingVertical: 16,
    paddingHorizontal: 10,
    backgroundColor: '#fff'
  },
  chkIconStyle1: {
    width: 12,
    height: 20,
    marginRight: 15
  },
  arrowStyle: {
    width: 10,
    height: 8,
    alignSelf: 'center',
    marginLeft: 5,
    marginRight: 5,
    top: 3

  },
  arrowCircleStyle: {
    width: 32,
    height: 32
  },
  arrowCircleStyleActive: {
    transform: [{rotate: '180deg'}]
  },
  actionBtnStyle: {
    height: 50,
    width: 50,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    right: 4,
    top: 8
  },
  cancelledIconStyle: {
    width: 23,
    height: 17,
    marginRight: 10
  },
  dateChangeIconStyle: {
    width: 16,
    height: 16,
    marginRight: 10
  },
  beltIconStyle: {
    width: 16,
    height: 17,
    marginRight: 10
  },
  gateIconStyle: {
    width: 16,
    height: 12,
    marginRight: 10,
    marginTop: 2
  },
  webChkIconStyle: {
    width: 19,
    height: 12,
    marginRight: 10
  },
  useCaseWrapper: {
    flexDirection: 'row',
    marginHorizontal: 10,
    marginVertical: 5,
    padding: 10,
    borderRadius: 4
  },
  cancelUseCase: {
    backgroundColor: '#ffedd1'
  },
  datechangeUseCase: {
    backgroundColor: '#e6f5ff'
  },
  webChkInUseCase: {
    backgroundColor: '#f2f2f2',
    alignItems: 'center'
  },
  cancelUseCaseTxt: {
    color: '#cf8100',
    fontSize: 12,
    fontFamily: fonts.bold
  },
  dateChangeUseCaseTxt: {
    color: '#0061aa',
    fontSize: 12,
    fontFamily: fonts.bold
  },
  canccelledTxt: {
    color: '#db7e00',
    fontFamily: fonts.bold,
    fontSize: 12,
    alignSelf: 'flex-end'
  },
  failedTxt: {
    color: '#e53442',
    fontSize: 12,
    fontFamily: fonts.bold,
    marginTop: 2
  },
  CompletedTxt: {
    color: '#bbb',
    fontFamily: fonts.bold,
    fontSize: 12,
    alignSelf: 'flex-end'
  },
  DateChangeedTxt: {
    color: '#0061aa',
    fontFamily: fonts.bold,
    fontSize: 12,
    alignSelf: 'flex-end'
  },
  postpondTxt: {
    color: '#cf8100'
  },
  bgOrange: {
    backgroundColor: '#ffedd1'
  },
  bgOrangeTxt: {
    color: '#cf8100',
    fontFamily: fonts.bold,
    fontSize: 12
  },
  dCTopInfoWrapper: {
    paddingHorizontal: 10,
    paddingVertical: 8,
    backgroundColor: '#ffedd1',
    borderRadius: 4,
    flexDirection: 'row',
    margin: 8
  },
  calIconStyle: {
    width: 16,
    height: 16,
    marginRight: 10
  },
  webChecinSection: {
    backgroundColor: '#f5f5ff',
    borderRadius: 4,
    height: 50,
    paddingHorizontal: 8,
    paddingVertical: 6,
    marginRight: 8,
    marginBottom: 10
  },
  WebCheckinIcon: {
    width: 18,
    height: 12
  },
  webCheckinWrapper: {
    width: 26,
    height: 26,
    marginRight: 10,
    backgroundColor: '#fff',
    borderRadius: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    top: 3
  },
  borBottomSpace: {
    borderBottomColor: '#f2f2f2',
    borderBottomWidth: 1

  },
  linkBtnWrapper: {
    borderTopWidth: 1,
    borderTopColor: '#d2d2d2',
    paddingVertical: 10,
    flexDirection: 'row',
    marginTop: 10
  },
  forwordIconStyle: {
    width: 12,
    marginLeft: 5,
    height: 12
  },
  imagePlacehoder: {
    width: 32,
    height: 32,
    backgroundColor: '#edf7ff',
    marginRight: 12,
    borderRadius: 30,
    justifyContent: 'center'
  },
  webCheckIcon: {
    width: 44,
    height: 44,
    alignSelf: 'center'
  },
  rowDtlSection: {
    width: '80%'
  },
  rowDtlsdisc: {
    color: '#747474',
    fontSize: 12,
    fontFamily: fonts.regular
  },
  rightArrowStyle: {
    width: 9,
    height: 13,
    position: 'absolute',
    right: 10,
    top: 5
  },
  rightArrowRedirectIcon: {
    width: 8,
    height: 13
  },
  CompleteWebCheckin: {
    paddingHorizontal: 8,
    paddingTop: 16
  },
  checkIncontent: {
    borderBottomWidth: 1,
    borderBottomColor: '#e7e7e7',
    paddingBottom: 20
  },
  covidRow: {
    backgroundColor: '#eef7ff',
    borderRadius: 4,
    padding: 4,
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 8,
    marginBottom: 20
  },
  covidIcon: {
    width: 20,
    height: 20,
    marginRight: 4
  },
  lightGreyText: {color: '#747474'},

  blackIconStyle: {
    width: 25,
    height: 23,
    marginRight: 5,
    top: 3
  }

};
export default webChkInStatus;
