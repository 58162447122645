import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { MMTCONNECT_ICON } from '../../../../FlightImageConstants';
import { getStaticData } from '../../../../../staticData/staticData'
import { useTheme } from '../../../../../theme/theme.context';
import { getFont } from '../../../../../PostSalesStyles';
import { htmlStyle } from './styles';
import HTMLView from 'react-native-htmlview';

const getHeadingText = (heading) => {
    let headingTxt = !isEmpty(heading) ? heading.replace(
        '{mmtConnect}',
        `<img src='${MMTCONNECT_ICON.uri}' width="12" height="10"><span>MMT</span>CONNECT`,
    ) : "";
    headingTxt = "<div>" + headingTxt + "</div>";
    return headingTxt;
}

const MMTConnectCard = ({ flightDetailResponse }) => {

    const { fsStyle, ...fonts } = getFont(true);
    const { psTheme: theme } = useTheme();
    const { mmtConnectFlightText } = getStaticData();
    const { heading } = mmtConnectFlightText || {}

    const { mmtConnect } = flightDetailResponse || {};

    return (mmtConnect && <HTMLView value={getHeadingText(heading)} stylesheet={htmlStyle(fonts, theme)} />)
}

export default MMTConnectCard;