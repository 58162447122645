import React from 'react';
import {Text, View} from 'react-native';
import PropTypes from 'prop-types';
import styles from '../../../../flights/modules/details/components/PaymentPriceBreakup/FareBreakUp/FareBreakUpCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import ZoomCarConstant from "../../../utils/ZoomCarConstants";
import {isEmpty} from 'lodash';

class FareBreakUp extends React.Component {
    render() {
        let paidAmountDetail = this.props.fareBreakUpDetail.filter(k => k.key === ZoomCarConstant.TOTAL_PRICE_KEY)[0];
        return (<View style={[AtomicCss.whiteBg, AtomicCss.marginBottom10]}>
            <View style={[styles.CardContentFull]}>
                <View style={styles.cardHeadingWrapper}>
                    <Text style={styles.cardHeading}>Price Breakup</Text>
                </View>
                <View style={styles.fareBreakupInnerSection}>
                    {this.getFareBreakUpViews()}
                    {this.getDiscountViews()}
                </View>
                <View style={[styles.totalPrice]}>
                    <Text
                        style={[styles.totalPricetxt, AtomicCss.font16]}>{paidAmountDetail.label}</Text>
                    <Text
                        style={[styles.totalPricetxt, AtomicCss.font18]}>{`₹ ${paidAmountDetail.amount} `}</Text>
                </View>
            </View>
        </View>);
    }

    getFareBreakUpViews = () => {
        const views = [];
        const fareBreakupArr = this.props.fareBreakUpDetail;
        fareBreakupArr && fareBreakupArr.map((breakup, key) => {
            if (breakup.key === ZoomCarConstant.TOTAL_PRICE_KEY || breakup.key === ZoomCarConstant.E_COUPON_KEY) {
                return;
            }
            views.push(<View key={key} style={styles.fareBreakupInnerRowSection}>
                <View style={[styles.rowSection]}>
                    <Text style={styles.fareBkHeadingTxt}>{breakup.label}</Text>
                    <Text style={[styles.fareBkHeadingTxt]}>
                        {`₹ ${breakup.amount} `}
                    </Text>
                </View>
            </View>);
        })
        return views;
    };

    getDiscountViews = () => {
        let discountDetail = this.props.fareBreakUpDetail.filter(k => k.key === ZoomCarConstant.E_COUPON_KEY)[0];
        console.log('discounts ==> ', JSON.stringify(discountDetail))
        if(isEmpty(discountDetail)){
           return;
        }
        if(!isEmpty(discountDetail))
        return (<View style={styles.fareBreakupInnerRowSection}>
            <View style={[styles.rowSection]}>
                <View style={AtomicCss.flexRow}>
                    <Text style={styles.successTxt}>{discountDetail.label}</Text>
                </View>
                <Text style={[styles.successTxt]}>{`-₹ ${discountDetail.amount} `}</Text>
            </View>
        </View>);
    };
}

export default FareBreakUp;

FareBreakUp.propTypes = {
    fareBreakUpDetail: PropTypes.array.isRequired,
};

FareBreakUp.defaultProps = {
    cashbackCouponText: undefined
};
