import isEmpty from 'lodash/isEmpty';
import { epocTimeToFormattedTime } from 'packages/legacy-commons/Common/utils/DateUtils';
import { Dimensions, Platform } from 'react-native';
import { CabTrackerStatuses, WayPointStates } from '../../../CabBookingDetailsConstant';
import pointToLineDistance from '@turf/point-to-line-distance';
const EARTH_RADIUS = 6371;
const { width, height } = Dimensions.get('screen');
const ASPECT_RATIO = width / height;

export const getDecrypedWayPoints = (response) => {
  const encodedWayPoints = response?.routes?.[0]?.overview_polyline?.points;
  if (isEmpty(encodedWayPoints)) return '';
  const len = encodedWayPoints.length;
  const path = [];
  let index = 0;
  let lat = 0;
  let lng = 0;
  let listIndex = 0;

  while (index < len) {
    let result = 1;
    let shift = 0;
    let b;
    do {
      b = encodedWayPoints.charCodeAt(index++) - 63 - 1;
      result += b << shift;
      shift += 5;
    } while (b >= 0x1f);
    lat += (result & 1) !== 0 ? ~(result >> 1) : result >> 1;

    result = 1;
    shift = 0;
    do {
      b = encodedWayPoints.charCodeAt(index++) - 63 - 1;
      result += b << shift;
      shift += 5;
    } while (b >= 0x1f);
    lng += (result & 1) !== 0 ? ~(result >> 1) : result >> 1;

    path.push({
      index: listIndex++,
      latitude: lat * 1e-5,
      longitude: lng * 1e-5,
      state: listIndex === 0 ? WayPointStates.NEXT : WayPointStates.UPCOMING,
    });
  }

  return path;
};

export const bearingBetweenLocations = (startLat, startLng, endLat, endLng) => {
  const toRadian = (n) => n * (Math.PI / 180);
  const toDegree = (n) => n * (180 / Math.PI);
  const dLon = toRadian(endLng - startLng);
  const y = Math.sin(dLon) * Math.cos(toRadian(endLat));
  const x =
    Math.cos(toRadian(startLat)) * Math.sin(toRadian(endLat)) -
    Math.sin(toRadian(startLat)) * Math.cos(toRadian(endLat)) * Math.cos(dLon);
  const brng = Math.atan2(y, x);
  return (toDegree(brng) + 360) % 360;
};

function computeDistanceBetween(from, to) {
  const EARTH_RADIUS = 6371; // Approximate Earth radius in KM

  const lat1 = from.latitude;
  const lon1 = from.longitude;
  const lat2 = to.latitude;
  const lon2 = to.longitude;

  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = EARTH_RADIUS * c; // Distance in KM
  return distance;
}

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

export const updateWaypointStates = (routeToDistination, currentLocation) => {
  if (isEmpty(routeToDistination)) return;

  let minDistance = EARTH_RADIUS;

  let distanceMap = new Map();
  let wayPoints = routeToDistination;
  for (let location of wayPoints) {
    let distance = computeDistanceBetween(currentLocation, location);
    distanceMap.set(location.index, distance);
  }

  let nextLocationIndex = -1;
  for (let [key, value] of distanceMap.entries()) {
    if (minDistance > value) {
      minDistance = value;
      nextLocationIndex = key;
    }
  }

  for (let i = 0; i < wayPoints.length; i++) {
    if (i < nextLocationIndex) {
      wayPoints[i].state = WayPointStates.CROSSED;
    } else if (i > nextLocationIndex) {
      wayPoints[i].state = WayPointStates.UPCOMING;
    } else {
      wayPoints[i].state = WayPointStates.NEXT;
    }
  }

  return wayPoints;
};

export const getCoordinates = (location) => {
  return {
    latitude: location?.lat,
    longitude: location?.lng,
  };
};

export const getFooterData = (eventName, distanceLeft, liveTrackingCardData) => {
  const { footerData } = liveTrackingCardData || {};
  const { started_data, arrived_data, boarded_data } = footerData || {};
  switch (eventName) {
    case CabTrackerStatuses.STARTED:
      return (!!started_data && started_data.replace('{distanceLeft}', distanceLeft)) || '';
    case CabTrackerStatuses.ARRIVED:
      return arrived_data || '';
    case CabTrackerStatuses.BOARDED:
      return (!!boarded_data && boarded_data.replace('{distanceLeft}', distanceLeft)) || '';
  }
  return '';
};

export const getHeaderData = (eventName, distanceLeft, liveTrackingCardData, heading) => {
  let result = {};
  const { subHeading } = liveTrackingCardData || {};
  const { started_subHeading, arrived_subHeading, boarded_subHeading } = subHeading || {};
  switch (eventName) {
    case CabTrackerStatuses.STARTED:
      result.heading = heading;
      result.subHeading =
        (!!started_subHeading && started_subHeading.replace('{distanceLeft}', distanceLeft)) || '';
      break;
    case CabTrackerStatuses.ARRIVED:
      result.heading = heading;
      result.subHeading = arrived_subHeading || '';
      break;
    case CabTrackerStatuses.BOARDED:
      result.heading = heading;
      result.subHeading =
        (!!boarded_subHeading && boarded_subHeading.replace('{distanceLeft}', distanceLeft)) || '';
  }
  return result;
};

export const getLastUpdatedText = (timestamp, liveTrackingCardData) => {
  if (!timestamp) return '';

  const { lastUpdatedText } = liveTrackingCardData || {};
  const date = epocTimeToFormattedTime(timestamp, 'DD MMM YYYY, hh:mm A');
  return !!lastUpdatedText && lastUpdatedText.replace('{date}', date);
};

export const findDistanceLeft = (decryptedWayPoints, currentLocation) => {
  let distanceLeft = 0;
  let prevCoordinate = currentLocation;
  !isEmpty(decryptedWayPoints) &&
    decryptedWayPoints.forEach((location, index) => {
      if (location.state === WayPointStates.UPCOMING) {
        distanceLeft += computeDistanceBetween(prevCoordinate, location);
        prevCoordinate = location;
      }
    });
  return distanceLeft.toFixed(1);
};

export const onSameRoute = (wayPoints, location) => {
  const wayPointList = [];
  const point = [location.latitude, location.longitude];
  !isEmpty(wayPoints) &&
    wayPoints.forEach((point) => {
      wayPointList.push([point.latitude, point.longitude]);
    });
  const distance = pointToLineDistance(point, wayPointList); // in kms
  return distance < 0.05;
};

export const getTooltipText = (eventName, duration, liveTrackingCardData) => {
  const { toolTipData } = liveTrackingCardData || {};
  const { started_data, arrived_data, boarded_data } = toolTipData || {};
  let durationNoWrap = duration.replace(/ /g, '&nbsp;');
  switch (eventName) {
    case CabTrackerStatuses.STARTED:
      return (!!started_data && started_data.replace('{duration}', durationNoWrap)) || '';
    case CabTrackerStatuses.ARRIVED:
      return arrived_data || '';
    case CabTrackerStatuses.BOARDED:
      return (!!boarded_data && boarded_data.replace('{duration}', durationNoWrap)) || '';
    default:
      return '';
  }
};

export const getRegion = (location) => {
	const lat_delta = Platform.OS === 'ios' ? 0.0222 : 0.0922;
	const long_delta = lat_delta * ASPECT_RATIO;
  return {
    latitude: location?.latitude,
    longitude: location?.longitude,
    latitudeDelta: lat_delta,
    longitudeDelta: long_delta,
  };
};

export const calculateDelta = (distance) => {
  let delta;
  if (distance <= 1) {
    delta = 0.005;
  } else if (distance <= 3) {
    delta = 0.01;
  } else if (distance <= 8) {
    delta = 0.03;
  } else if (distance <= 15) {
    delta = 0.07;
  } else if (distance <= 50) {
    delta = 0.15;
  } else {
    delta = 0.3;
  }

  const lat_delta = 0.0222;
	const long_delta = lat_delta * ASPECT_RATIO;

  return Platform.OS === 'ios' ? {
    latitudeDelta: lat_delta,
    longitudeDelta: long_delta,
  } : {
    latitudeDelta: delta,
    longitudeDelta: delta,
  };
};

export const convertSecondsToHoursAndMinutes = (seconds) => {
  if (!seconds) return '';
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  let result = '';
  if (hours > 0) {
    result += `${hours} hrs `;
  }
  if (minutes > 0) {
    result += `${minutes} mins `;
  }
  return result.trim();
};

export const isCarOutOfView = (currentCarLocation, region) => {
  if (!currentCarLocation || !region.current) return false;
  // Check if the car's coordinates are outside the map's current region
  return (
    currentCarLocation.latitude < region.current.latitude - region.current.latitudeDelta / 3 ||
    currentCarLocation.latitude > region.current.latitude + region.current.latitudeDelta / 3 ||
    currentCarLocation.longitude < region.current.longitude - region.current.longitudeDelta / 3 ||
    currentCarLocation.longitude > region.current.longitude + region.current.longitudeDelta / 3
  );
};
