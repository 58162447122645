import React, { Fragment, useCallback, useState } from 'react';
import { Image, Text, TouchableOpacity, View } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import LinearGradient from 'react-native-linear-gradient';
import createStyles from './FlightDtlsInfoCss';
import { getAirlineImage } from '../../../../../../../../../flights-funnel/Listing/utils/FlightListingUtil';
import { CHEVRON_DOWN_BLUE_ICON2, CHEVRON_UP_BLUE_ICON2 } from '../../../../../../../../FlightImageConstants';
import { getFont } from '../../../../../../../../../PostSalesStyles';
import { DEPART, TERMINAL } from 'apps/post-sales/src/flights/modules/DateChange/constants';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';

const FlightDtlsInfo = (props) => {
  const [toggleSections, setToggleSections] = useState(props.flightDetails[0].leg.map(() => true));
  const { psTheme: theme } = useTheme();

  const { fsStyle, ...fonts } = getFont(true);
  const styles = createStyles(theme, fonts);
  const handleTab = useCallback((index) => {
    setToggleSections((prevToggleSections) => {
      const prevSections = prevToggleSections;
      prevSections[index] = !prevSections[index];
      return { ...prevSections };
    });
  }, []);
  const legs = [];
  props.flightDetails.forEach((journey) => {
    journey.leg.forEach((leg, index) => {
      legs.push(
        <Fragment key={index}>
          <TouchableOpacity activeOpacity={0.6} onPress={() => handleTab(index)}>
            <View style={[styles.whiteCard, styles.flightDetailsSection]}>
              <View style={AtomicCss.flex1}>
                <Text
                  style={[
                    fonts.boldFontFamily,
                    fsStyle.font14,
                    AtomicCss.blackTxt,
                    AtomicCss.marginBottom3,
                  ]}
                >
                  {leg.departInfo.cityName}-{leg.arrivalInfo.cityName}
                </Text>
                <Text style={[fonts.regularFontFamily, AtomicCss.darkTxt, fsStyle.font11]}>
                  {' '}
                  {leg.departInfo.date}{' '}
                </Text>
              </View>
              <Image
                source={toggleSections[index] ? CHEVRON_UP_BLUE_ICON2 : CHEVRON_DOWN_BLUE_ICON2}
                style={styles.topArrow}
              />
            </View>
          </TouchableOpacity>
          {toggleSections[index] && (
            <View style={[styles.whiteCard, AtomicCss.marginBottom10]}>
              <View style={[AtomicCss.flexRow, styles.flightTopDtls]}>
                <LinearGradient
                  start={{ x: 0.0, y: 0.0 }}
                  end={{ x: 1.0, y: 0.0 }}
                  colors={['#1e1e1e', '#4f4f4f']}
                  style={styles.flightStatus}
                >
                  <Text
                    style={[
                      AtomicCss.lightFont,
                      fsStyle.font14,
                      AtomicCss.whiteText,
                      AtomicCss.marginBottom3,
                    ]}
                  >
                    {DEPART}
                  </Text>
                  <Text style={[fsStyle.font10, AtomicCss.whiteText, fonts.boldFontFamily]}>
                    {leg.departInfo.date}
                  </Text>
                </LinearGradient>
                <View style={AtomicCss.marginLeft8}>
                  <Text style={[fsStyle.font16, AtomicCss.darkTxt, fonts.boldFontFamily]}>
                    {' '}
                    {leg.departInfo.cityCode}-{leg.arrivalInfo.cityCode}
                  </Text>
                  <Text style={[fsStyle.font12, AtomicCss.darkTxt, fonts.regularFontFamily]}>
                    {leg.departInfo.date} | {leg.duration}
                  </Text>
                </View>
              </View>
              <View style={styles.fltDtlInfo}>
                <View
                  style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom10]}
                >
                  <Image style={styles.fltIcon} source={{ uri: getAirlineImage(leg.airlineCd) }} />
                  <Text style={[fonts.boldFontFamily, AtomicCss.blackTxt]}>
                    {leg.airlineName}{' '}
                  </Text>
                  <Text style={[fonts.regularFontFamily, AtomicCss.darkTxt]}>
                    {leg.opAirlineCd} {leg.flightNo}
                  </Text>
                </View>
                <View style={[styles.fltDtls]}>
                  <View style={AtomicCss.flex1}>
                    <Text style={[fsStyle.font22, fonts.boldFontFamily, AtomicCss.blackTxt]}>
                      {leg.departInfo.time}
                    </Text>
                    <Text
                      style={[
                        fonts.regularFontFamily,
                        AtomicCss.LightText,
                        fsStyle.font12,
                        AtomicCss.marginBottom3,
                      ]}
                    >
                      {leg.departInfo.date}
                    </Text>
                    <Text
                      style={[
                        fonts.boldFontFamily,
                        AtomicCss.darkTxt,
                        fsStyle.font12,
                        AtomicCss.marginBottom3,
                      ]}
                    >
                      {leg.departInfo.cityName}
                    </Text>
                    <Text
                      style={[
                        fonts.regularFontFamily,
                        AtomicCss.darkTxt,
                        fsStyle.font12,
                        AtomicCss.marginBottom3,
                      ]}
                    >
                      {leg.departInfo.terminalName}
                    </Text>
                    <Text
                      style={[fonts.regularFontFamily, AtomicCss.LightText, fsStyle.font12]}
                    >
                      {TERMINAL} {leg.departInfo.terminalNo}
                    </Text>
                  </View>
                  <View style={styles.durationWrapper}>
                    <Text style={[AtomicCss.LightText, fonts.boldFontFamily]}>
                      {leg.duration}
                    </Text>
                  </View>
                  <View style={AtomicCss.flex1}>
                    <Text
                      style={[
                        fsStyle.font22,
                        fonts.boldFontFamily,
                        AtomicCss.blackTxt,
                        AtomicCss.alignRight,
                      ]}
                    >
                      {leg.arrivalInfo.time}
                    </Text>
                    <Text
                      style={[
                        fonts.regularFontFamily,
                        AtomicCss.LightText,
                        fsStyle.font12,
                        AtomicCss.alignRight,
                        AtomicCss.marginBottom3,
                      ]}
                    >
                      {leg.arrivalInfo.date}
                    </Text>
                    <Text
                      style={[
                        fonts.boldFontFamily,
                        AtomicCss.darkTxt,
                        fsStyle.font12,
                        AtomicCss.alignRight,
                        AtomicCss.marginBottom3,
                      ]}
                    >
                      {leg.arrivalInfo.cityName}
                    </Text>
                    <Text
                      style={[
                        fonts.regularFontFamily,
                        AtomicCss.darkTxt,
                        fsStyle.font12,
                        AtomicCss.alignRight,
                        AtomicCss.marginBottom3,
                      ]}
                    >
                      {leg.arrivalInfo.terminalName}
                    </Text>
                    <Text
                      style={[
                        fonts.regularFontFamily,
                        AtomicCss.LightText,
                        fsStyle.font12,
                        AtomicCss.alignRight,
                        AtomicCss.marginBottom3,
                      ]}
                    >
                      {TERMINAL} {leg.arrivalInfo.terminalNo}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          )}
        </Fragment>,
      );
    });
  });
  return legs;
};

export default FlightDtlsInfo;
