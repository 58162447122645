import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Text, TextInput, TouchableOpacity, View } from 'react-native';
import createStyles from './RejectionOverlayDtlsCss';
import Buttons from '../../../../../components/Button';
import RadioList from '../RadioList';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { CANCEL, DateChangeConstants, SUBMIT } from '../../../../constants';

const RejectionOverlay = (props) => {
  const {psTheme: theme} = useTheme();
  const fonts = getFont(true);
  const styles = createStyles(theme,fonts);
  const [activeState, setActiveState] = useState(0);
  const [activeStateText, setActiveStateText] = useState(props.reasons[0]);
  const [comment, setComment] = useState('');
  const handleRadio = useCallback(
    (index) => {
      setActiveState(index);
      setActiveStateText(props.reasons[index]);
    },
    [props.reasons],
  );
  const handleComment = useCallback((text) => {
    setComment(text);
  }, []);

  return (
    <View style={styles.paddingBottom25}>
      <Text style={styles.contentTitle}>{DateChangeConstants.HEADING_REJECT}</Text>
      {props.reasons.map((item, index) => (
        <RadioList item={item} index={index} handleRadio={handleRadio} activeState={activeState} />
      ))}
      <View style={styles.textAreaContainer}>
        <TextInput
          style={styles.textArea}
          underlineColorAndroid={theme.color.transparent}
          placeholder={DateChangeConstants.DESC_REJECT}
          placeholderTextColor={theme.color.grey}
          numberOfLines={10}
          multiline
          onChangeText={(text) => handleComment(text)}
        />
      </View>
      <View style={styles.contentFooter}>
        <TouchableOpacity style={styles.actionBtn} onPress={() => props.closeOverlay()}>
          <Text style={styles.actionBtnText}>{CANCEL}</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.actionBtn}>
          <Buttons
            isCorporateBooking
            text={SUBMIT}
            type="Rounded"
            color="blue"
            onPressHandler={() => {
              props.onRejectSubmitClicked(comment, activeStateText);
            }}
          />
        </TouchableOpacity>
      </View>
    </View>
  );
};

RejectionOverlay.propTypes = {
  onRejectSubmitClicked: PropTypes.func.isRequired,
  closeOverlay: PropTypes.func.isRequired,
  reasons: PropTypes.array.isRequired,
};
export default RejectionOverlay;
