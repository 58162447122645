import { View, Image, Text } from 'react-native';
import React, { useEffect } from 'react';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import createStyles from './style';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import isEmpty from 'lodash/isEmpty';
import PostSalesBookingTrackingHelper from '../../../../PostSalesBookingTrackingHelper';
import RowCardContainer from '../../../../Common/Components/RowCardContainer';
import CardContainer from '../../../../Common/Components/CardContainer';
import HTMLView from 'react-native-htmlview';

interface IHotelCreditsCardProps {
  heading: string;
  imageUrl: string;
  description: string;
  seperatorIconUrl: ISeperatorIconUrl,
  trackingPageName: string;
  showSeperatorIcon: boolean;
}

interface ISeperatorIconUrl {
  WEB : string;
}

const HotelCreditsCard = ({
  heading,
  imageUrl,
  description,
  seperatorIconUrl,
  trackingPageName,
  showSeperatorIcon,
}: IHotelCreditsCardProps) => {
  useEffect(() => {
    PostSalesBookingTrackingHelper.trackShownEvent(
      trackingPageName,
      'HotelCreditsCard_shown',
    );
  }, [trackingPageName]);

  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme);
  const getHtmlStyles = (fonts) => {
    return {
        p: {
            color: theme.color.defaultTextColor,
            fontSize: fonts.fontSize.font12,
            lineHeight: 14,
            ...fonts.regularFontFamily,
        },
        b: {
          color: theme.color.defaultTextColor,
          fontSize: fonts.fontSize.font12,
          lineHeight: 14,
          ...fonts.boldFontFamily,
        },
    };
};

  return (
    <CardContainer containerStyle={styles.benefitWrapper}>
    {showSeperatorIcon && !isEmpty(seperatorIconUrl?.WEB) && (
      <View style={styles.seperatorIcon}>
        <Image source={{uri : seperatorIconUrl.WEB}} style={styles.plusIcon} />
      </View>
    )}
      <View style={styles.benefitWhiteBg}>
        <RowCardContainer>
          <View style={AtomicCss.flex1}>
            {!!heading && (
              <Text
                style={[
                  {color: theme.color.black},
                  fsStyle.font14,
                  fonts.blackFontFamily,
                  AtomicCss.marginBottom8,
                  AtomicCss.marginTop12,
                ]}
              >
                {heading}
              </Text>
            )}
            {!!description && (
                <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween, AtomicCss.alignCenter, AtomicCss.marginBottom4]}>
                  <View style={styles.descriptionTxt}>
                    <HTMLView
                      stylesheet={getHtmlStyles(
                        fonts
                      )}
                      value={description}
                    />
                  </View>
                  {!!imageUrl && <Image source={{ uri: imageUrl }} style={styles.mainImg} />}
                </View>
            )}
          </View>
        </RowCardContainer>
      </View>
  </CardContainer>
  );
};

export default HotelCreditsCard;
