import {PixelRatio, Dimensions, Platform} from 'react-native';

export const fonts = {
  black: 'Lato-Black',
  bold: 'Lato-Bold',
  regular: 'Lato-Regular',
  medium: 'Lato-Regular',
  light: 'Lato-Light',
  semiBold: 'Lato-Regular'
};


export const colors = {
  black: '#000000',
  white: '#ffffff',
  gray: '#727272',
  grayBg: '#f2f2f2',
  transparent: 'transparent',
  azure: '#008cff',
  azureSemiTrans: 'rgba(0,140,255, 0.3)',
  lightGrey: '#e7e7e7',
  rippleColor: '#d7d7d7',
  grey: '#7a7a7a',
  defaultTextColor: '#4a4a4a',
  darkGrey: '#292828',
  lightTextColor: '#9b9b9b',
  lighterTextColor: '#a5a5a5',
  lightestTextColor: '#c9c9c9',
  lightSeaGreenTextColor: '#249995',
  hummingBird: '#cdf6e8',
  redDiscount: '#d3000d',
  red: '#e53442',
  errorRed: '#d0011b',
  disabledButton: '#c2c2c2',
  linkBlue: '#004cde',
  disabledBtnBg: '#9b9b9b',
  black28: '#282828'

};

const pixelRatio = PixelRatio.get();
const deviceWidth = Dimensions.get('screen').width;
const deviceHeight = Dimensions.get('screen').height;

/* Normalise 3x/XXHDPI font and return appropriate size of other DPI phones */
export const normalisePx = (pixels) => {
  const newValue = (pixels / 3) * pixelRatio;
  // Samsung S6
  if (pixelRatio >= 4 && deviceWidth <= 640) {
    return newValue * 0.66;
  }
  return newValue;
};

export const isIPhoneX = Platform.OS === 'ios' && deviceHeight === 812;
