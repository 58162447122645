import { StyleSheet, Dimensions } from "react-native";
import { DEFAULT_THEME } from "apps/post-sales/src/theme/default.theme";

const { width: screenWidth } = Dimensions.get('window');
const ITEM_WIDTH = screenWidth - 150;

export default <T extends typeof DEFAULT_THEME>(theme: T, fonts: any) => StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  itemContainer: {
    width: ITEM_WIDTH,
    height: 325,
  },
  imageStyle: {
    flex: 1,
    borderRadius: 16,
  },
  textContainer: {
    position: 'absolute',
    bottom: 0,
    textAlign: 'center',
    paddingHorizontal: 20,
    height: '100%',
    borderRadius: 16,
    justifyContent: 'flex-end',
    paddingBottom: 20,
  },
  rightArrowStyle: {
    width: 8,
    height: 12,
    tintColor: theme.color.white
  },
  titleText: {
    fontSize: fonts.fontSize.font18,
    ...fonts.blackFontFamily,
    color: theme.color.white
  },
  socialCardContainer: {
    backgroundColor: theme.color.white,
    borderRadius: 16,
    padding:16,
    minHeight: 325,
    marginBottom:5,
  },
  socialContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',  
    flexWrap : 'wrap',
    width : '100%'
  },
  iconStyle: {
    width:29,
    height:29,
    marginBottom:8,
  },
  socialWrapper: {
    padding:12, 
    borderRadius: 8,
    width:'48%', 
    height:72,
    marginTop:12,
  },
  socialCardHeading : {
    fontSize: fonts.fontSize.font18,
    ...fonts.blackFontFamily,
    marginBottom : 6
  },
  socialCardSubHeading : {
    fontSize: fonts.fontSize.font12,
    ...fonts.regularFontFamily,
  },
  socialMediaText: {
    fontSize: fonts.fontSize.font12,
    ...fonts.blackFontFamily,
    color: theme.color.white
  }

});
