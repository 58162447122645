import { InsuranceData, InsuranceDataProps, InsuranceDetailsProps } from '../cardInterfaces';
import { handleCTAClick } from '../../../utils';
import { titleCase } from '@mmt/legacy-commons/Helpers/stringHelpers';
import myTripsNavigation from '../../../../navigation/navigation';
import {
  DATE_MONTH,
  POSTSALES_DATE_TIME,
  reformatDateString,
} from '@mmt/legacy-commons/Helpers/dateTimehelpers';
import { PAGE_BOOKINGS_STATUS } from '../../../summaryConstants';
import {
  COMPLETED,
  UPCOMING,
} from 'apps/post-sales/src/summary/components/cards/insurance/constants';
import { externalLinkPressHandler } from 'apps/post-sales/src/utils/PostSaleUtil';
function getInsurerSummary(insuranceDetails: InsuranceDetailsProps[]) {
  const { leadPaxName } = insuranceDetails[0] || {};
  const count = insuranceDetails.length;
  const firstPaxName = titleCase(leadPaxName);
  if (count <= 1) {
    return firstPaxName;
  }
  return `${firstPaxName} + ${count - 1}`;
}

export function getInsuranceSummaryData(data: InsuranceDataProps): InsuranceData {
  const { ctaInfoList: ctaDetails } = (data && data.bookingStateLayout) || {};
  const insuranceDetails = (data && data.insuranceDetails) || [];
  const actions: Array<{ label: string; onPress: () => void }> = [];
  const lobName = (data && data.lobType && data.lobType[0]) || '';
  if (ctaDetails && ctaDetails[0]) {
    actions.push({
      label: ctaDetails[0]?.text,
      onPress: () => externalLinkPressHandler(ctaDetails[0]?.properties?.downloadPolicyUri),
    });
  }
  if (ctaDetails && ctaDetails[1]) {
    actions.push({
      label: ctaDetails[1]?.text,
      onPress: () => handleCTAClick(ctaDetails[1], data),
    });
  }
  const insurerSummary = getInsurerSummary(insuranceDetails);
  const { policyStartDateTime, policyEndDateTime, productName, insuranceProvider, travelCountry,startsOnText,endsText } =
    insuranceDetails[0] || {};
  const checkInDate =
    reformatDateString(policyStartDateTime, POSTSALES_DATE_TIME, DATE_MONTH) || '';
  const checkoutDate = reformatDateString(policyEndDateTime, POSTSALES_DATE_TIME, DATE_MONTH) || '';
  const pageData = {
    fromMyTrips: true,
  };
  const openDetails = () => {
    myTripsNavigation.openInsuranceBookingDetail(data, pageData);
  };
  return {
    status: data.status,
    iconUrl: data.status === PAGE_BOOKINGS_STATUS.COMPLETED ? COMPLETED : UPCOMING,
    lobName,
    insuranceProvider: `${productName} - ${insuranceProvider}`,
    actions,
    passengerDetails: insurerSummary,
    startDate: checkInDate,
    openDetails: openDetails,
    endDate: checkoutDate,
    endsText: endsText,
    startsOnText: startsOnText,
    heading: travelCountry,
  };
}
