import React from 'react';
import PropTypes from 'prop-types';
import {Image, Text, View, StyleSheet, TouchableOpacity, Platform} from 'react-native';
import Actions from '../../../navigation/postSalesNavigation';
import {getCommonHeaders} from '@mmt/legacy-commons/Helpers/genericHelper';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import getStyles from './HotelMamiCss';
import {
  getActionButton,
  getPreviousDateInMilliseconds,
  getTimeInMillis,
  handleClickEvent,
  isNotNullAndEmpty,
  getThrottleParams
} from '../utils/HotelBookingDetailUtil';
import {DEFAULT_DATE_TIME} from '../../../PostSalesConstant';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import HotelBookingTrackingHelper from '../../HotelBookingTrackingHelper';
import { getStaticData } from '../../../staticData/staticData';
import {
  CANCELLATION_ICON, NO_CANCELLATION_ICON, BLACK_CANCELLATION_ICON,
  MEDICAL_WAIVER_ICON, BLACK_LEFT_ARROW_ICON2
} from '../../HotelImageConstants';
import HotelBookingDetailsConstant from '../HotelBookingDetailsConstant';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import HTMLView from 'react-native-htmlview';
import CancellationTimeline from "./CancellationTimeline";
import { isEmpty } from 'lodash';
import { isGCCBooking } from 'apps/post-sales/src/Common/commonUtil';
import { setRegionCookie, setWebViewAuth } from 'apps/post-sales/src/utils/PostSaleUtil';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { ThemeContext } from '../../../theme/theme.context';
import ButtonWrapper from 'apps/post-sales/src/Common/ButtonWrapper';

const medicalWaiverIcon = MEDICAL_WAIVER_ICON;

const getHtmlStyles = (fonts, theme) => {
  return {
    p: {
      ...fonts.font14,
      ...fonts.regularFontFamily,
      color: theme.color.defaultTextColor,
      lineHeight: 18,
      textAlign: 'left',
      flex: 1
    },
    a: {
      top: 2,
      ...fonts.font14,
      ...fonts.boldFontFamily,
      color: theme.color.azure,
      textAlign: 'left',
    }
  };
}
const PLATFORM_SOURCE = Platform.OS === 'android' ? 'ANDROID' : 'IOS';
const openWebView = async (_url, options = {}) => {
  let url = _url;
  if(url.includes('?')) {
    url += `&source=${PLATFORM_SOURCE}`;
  } else {
    url += `?source=${PLATFORM_SOURCE}`;
  }
  let appVersion = null;
  try {
    appVersion = await GenericModule.getAppVersion();
  } catch (e) {}
  if (appVersion) {
    url = `${url}&appVersion=${appVersion}`;
  }

  let headers = {};
  try {
    headers = await getCommonHeaders();
    delete headers['mmt-app-version-code'];
  } catch (e) {}
  const isGcc = await isGCCBooking();
  const authValue = headers['mmt-auth'];
  const javaScript = setWebViewAuth(authValue, isGcc);
  Actions.openPsWebView({
    url,
    headers,
    headerText: options.headerText,
    headerIcon: options.headerIcon,
    sharedCookiesEnabled: options.sharedCookiesEnabled,
    whiteHeader: options.whiteHeader,
    injectedJavaScript: javaScript,
  });
};

class HotelCancellationPolicyCard extends React.Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);

    this.state = {
      responseJson: this.props.response
    };
  }

  componentDidMount() {
    const {waiverForm} = this.state.responseJson;
    if (waiverForm && Object.keys(waiverForm).length && waiverForm.showCard) {
      HotelBookingTrackingHelper.trackShowEvent(this.props.pageName, 'NR_waiver_CTA_shown', this.state.responseJson);
    }
  
    const { response = {}, pageName } = this.props;
    if(this.props.response.isGroupBooking) {
      HotelBookingTrackingHelper.trackShowEvent(pageName, "gb_part_payment_cancellation_card_shown", response);
    }
  }

  onLinkPress = (url) => {
    openWebView(url, {headerText: HotelBookingDetailsConstant.FLEXI_HEADER})
  }

  render() {
    const {hotelBookingInfo, bookingStatus, cancellationCardData, flexiCancellationData } = this.state.responseJson;
    const { cancellationCardFlexiMsgHtml = '', flexiNote = '', flexiIcon = '', flexiCtaUrl = ''} = flexiCancellationData || {};
    const currentTime = new Date().getTime();
    const freeCancellationEligibiltyDate = this.state.responseJson.freeCancellationEligibityDate;

    const { fsStyle, ...fonts } = getFont(true);
    const styles = getStyles(fonts);
    const localStyles = getLocalStyles(fonts, this.context.psTheme);
    const htmlStyle = getHtmlStyles(fonts, this.context.psTheme)
  
    return (
      <View style={[AtomicCss.whiteCardNew, AtomicCss.marginHz12, AtomicCss.borderRadius16]}>
        {!(bookingStatus === 'Cancelled') && (
          <>
          <View style={[styles.CardContentNew, {paddingBottom: -10}]}>
         
            <View style={styles.ContentPortion}>
              <Text style={[styles.blackFont, styles.BlackText, styles.mar10Bt, AtomicCss.font16]}>
                {cancellationCardData && cancellationCardData.heading}
              </Text>
              {!isEmpty(cancellationCardFlexiMsgHtml) && 
                <View style={[AtomicCss.flexRow, AtomicCss.marginBottom10, AtomicCss.marginRight10]}>
                  <HTMLView
                    value={cancellationCardFlexiMsgHtml}
                    style={flexiIcon ? AtomicCss.paddingRight12 : ''}
                    onLinkPress={() => this.onLinkPress(flexiCtaUrl)}
                    stylesheet={htmlStyle}
                  />
                  {!!flexiIcon && <Image source={{uri: flexiIcon}} style={localStyles.flexiIcon} />}
                </View>
              }
              {isEmpty(cancellationCardFlexiMsgHtml) && cancellationCardData && !isEmpty(cancellationCardData.subTextList) &&
                <View style={AtomicCss.marginBottom10}>
                      {cancellationCardData.subTextList.map(info => (
                        <View style={[AtomicCss.flexRow, AtomicCss.marginBottom10]}>
                          <View style={localStyles.bulletCircle}></View>
                          <Text style={[localStyles.infoCardDisc, AtomicCss.alignLeft]}>{info}</Text>
                        </View>
                      ))}
                </View>
              }
              {this.getCancelationWaiverCard(fsStyle,fonts,styles,localStyles)}  
            </View>
          </View>
          {!isEmpty(cancellationCardData.timeLineObj) && <View style={localStyles.cancellationTimeline}>
              <View style={[localStyles.cancellationListHeader, AtomicCss.paddingLeft20]}>
                  <Text style={[fsStyle.font14, fonts.boldFontFamily, AtomicCss.blackText, styles.underline]}>{cancellationCardData.timeLineObj.leftHeading}</Text>
                  <Text style={[fsStyle.font14, fonts.boldFontFamily, AtomicCss.blackText, styles.underline]}>{cancellationCardData.timeLineObj.rightHeading}</Text>
              </View>
              {!isEmpty(cancellationCardData.timeLineObj.timeline) && <CancellationTimeline timeLine={cancellationCardData.timeLineObj.timeline} />}
          </View>}
          </>
        )}
       {this.props.card && isNotNullAndEmpty(this.props.card.actionList) &&
          getActionButton(this.props.card, this.props.response, this.handleClick, getThrottleParams)}
        
        {!!flexiNote && 
          <View style={localStyles.borderTop}>
            <View style={localStyles.flexiNoteWrapper}>
              <Text style={[localStyles.flexiNoteText]}>{flexiNote}</Text>
            </View>
          </View>
        }
      </View>
    );
  }

  handleClick = (action) => {
    if(action.actionFamily === HotelBookingDetailsConstant.ACTION_CHANGE_DATES && this.props.response && this.props.response.isCorporateBooking) {
      this.props.odcActionHandler(this.props.card, action, this.props.response, this.props.pageName);
    } else {
      handleClickEvent(this.props.card, {...action, actionHandler: this.props.handleOpenBottomOverlay}, this.props.response, this.props.pageName, true, this.props.userInfoResponse);
    }
  };

  showCancellationImage(
    freeCancellationEligibiltyDate,
    currentTime
  ) {
    const {isMMTPrivilegeEligible, isMyPartnerBooking} = this.state.responseJson || {}
    if (isMMTPrivilegeEligible && !isMyPartnerBooking) {
      return BLACK_CANCELLATION_ICON;
    }

    if (this.checkForFreeCancellation(freeCancellationEligibiltyDate)) {
      if (getTimeInMillis(freeCancellationEligibiltyDate) < currentTime) {
        return NO_CANCELLATION_ICON;
      }

      // Fully refundable
      return CANCELLATION_ICON;
    }
    return NO_CANCELLATION_ICON;
  }

  getCancelationWaiverCard = (fsStyle,fonts,styles,localStyles) => {
    const {waiverForm} = this.state.responseJson;

    if (!waiverForm) {
      return null;
    }
    if (!Object.keys(waiverForm).length) {
      return null;
    }
    if (!waiverForm.showCard) {
      return null;
    }

    return (
      <View style={[localStyles.refundWaiverContainer, AtomicCss.flexRow]}>
        <Image source={medicalWaiverIcon} style={localStyles.refundWaiverIcon} />
        <View style={[AtomicCss.flex1]}>
          <Text
            style={[
              AtomicCss.defaultText,
              fsStyle.font12,
              fonts.regularFontFamily,
              AtomicCss.lineHeight18,
              AtomicCss.alignLeft
            ]}
          >
            {waiverForm.cardText.split(waiverForm.cardBoldText).map((text, index) => {
              if (index === 0) {
                return text;
              }
              return (
                <React.Fragment>
                  <Text
                    style={[
                      AtomicCss.defaultText,
                      fsStyle.font12,
                      fonts.boldFontFamily,
                      AtomicCss.lineHeight18,
                      AtomicCss.alignLeft
                    ]}
                  >
                    {waiverForm.cardBoldText}
                  </Text>
                  {text}
                </React.Fragment>
              );
            })}
          </Text>
          <ButtonWrapper
            clickHandler={() => {
              HotelBookingTrackingHelper.trackClickEvent(this.props.pageName, 'NR_waiver_CTA_click', this.state.responseJson);
              openWebView(waiverForm.formUrl, {
                headerText: waiverForm.webViewText,
                headerIcon: BLACK_LEFT_ARROW_ICON2,
                whiteHeader: true,
                sharedCookiesEnabled: true
              });
            }}
            btnText={waiverForm.cardLinkText}
            btnTxtStyle={[fsStyle.font12,fonts.boldFontFamily]}
            buttonType='text'
            buttonWidth='dynamic'
          />
        </View>
      </View>
    );
  };

  checkForFreeCancellation(freeCancellationEligibiltyDate) {
    return (
      freeCancellationEligibiltyDate &&
      freeCancellationEligibiltyDate !== DEFAULT_DATE_TIME &&
      getTimeInMillis(freeCancellationEligibiltyDate) >
        getTimeInMillis(this.props.response.bookingDateTime)
    );
  }
}

const getLocalStyles = (fonts, theme) => {
  return StyleSheet.create({
    text: {
      fontStyle: 'normal',
      ...fonts.font14,
      lineHeight: 20,
    },
    refundWaiverContainer: {
      backgroundColor: theme.color.lightRed9,
      borderRadius: 4,
      padding: 10,
      marginTop: -10,
      marginBottom: 20
    },
    refundWaiverIcon: {
      width: 24,
      height: 22,
      marginRight: 10,
      marginTop: 5
    },
    cancellationTimeline: {
      paddingHorizontal: 25
    },
    cancellationListHeader: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 15
    },
    bulletCircle: {
      width: 6,
      height: 6,
      backgroundColor: theme.color.darkGrey5,
      borderRadius: 20,
      top: 5,
      left: 5,
    },
    infoCardDisc: {
      ...fonts.font14,
      color: theme.color.defaultTextColor,
      lineHeight: 18,
      paddingLeft: 20,
      paddingRight: 20,
      fontFamily: fonts.regular,
      flex: 1,
    },
    flexiIcon: {
      width: 27,
      height: 32,
      marginRight: 10
    },
    borderTop: {
      borderTopWidth:1,
      borderTopColor: theme.color.lightGrey,
    },
    flexiNoteWrapper: {
      backgroundColor: theme.color.yellowOrange2, 
      margin:12,
      borderRadius:12,
      padding:16
    },
    flexiNoteText: {
      ...fonts.font14,
      color: theme.color.defaultTextColor,
      fontFamily: fonts.regular,
      lineHeight: 18
    },
  });
}

HotelCancellationPolicyCard.propTypes = {
  response: PropTypes.object.isRequired,
  card: PropTypes.object.isRequired,
  pageName: PropTypes.string.isRequired,
  odcActionHandler: PropTypes.func
};

HotelCancellationPolicyCard.defaultProps = {
  odcActionHandler: () => {}
}

export default HotelCancellationPolicyCard;