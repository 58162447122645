export function makePageData(bookingSummaryResponse) {
  const { newBookingDetails, pageNumber, userBookingSummary, bookingSummaryLayout } = bookingSummaryResponse
  const { showAdTechCard, statusWiseMessage, emptyBookingSummaryMessage, errorMessage, responseCode, displayUnsuccessfulTab, otherLanguageBookingInfo, bookingsInOtherLanguage, cardDetail, uuid , addBookingCTA, persuasion} = userBookingSummary || {};
  const { bookingStateCards } = bookingSummaryLayout || {};
  if (newBookingDetails == null || bookingStateCards == null) {
    throw new Error('Mytrips: Invalid response');
  }
  return {
    newBookingDetails,
    showAdTechCard,
    statusWiseMessage,
    emptyBookingSummaryMessage,
    errorMessage,
    responseCode,
    pageNumber,
    displayUnsuccessfulTab,
    otherLanguageBookingInfo,
    bookingsInOtherLanguage,
    cardDetail,
    uuid,
    addBookingCTA,
    bookingStateCards,
    persuasion
  }
}

export function makeV2PageData(bookingSummaryResponse) {
  const {
    bookingDetails,
    showAdTechCard,
    statusWiseMessage,
    emptyBookingSummaryMessage,
    errorMessage,
    responseCode,
    displayUnsuccessfulTab,
    otherLanguageBookingInfo,
    bookingsInOtherLanguage,
    cardDetail,
    bookingSummaryLayout
  } = bookingSummaryResponse;
  const { bookingStateCards } = bookingSummaryLayout || {};
  if (bookingDetails == null || bookingStateCards == null) {
    throw new Error('Mytrips: Invalid response');
  }
  return {
    bookingDetails,
    showAdTechCard,
    statusWiseMessage,
    emptyBookingSummaryMessage,
    errorMessage,
    responseCode,
    displayUnsuccessfulTab,
    otherLanguageBookingInfo,
    bookingsInOtherLanguage,
    cardDetail,
    bookingStateCards,
  };
}
export default {};
