import React from 'react';
import {
  View,
  Text,
  Image,
  TouchableHighlight,
  StyleSheet,
  Animated,
  Easing,
  TouchableOpacity,
} from 'react-native';
import { isEmpty } from 'lodash';
import HTMLView from 'react-native-htmlview';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import Button from '../../../../Common/Button';
import Actions from '../../../../navigation/postSalesNavigation';
import { BACK_ARROW_WHITE_ICON, CREDIT_SHELL_ICON } from '../../../FlightImageConstants';
import { getFont } from '../../../../PostSalesStyles';

const { fsStyle, ...fonts } = getFont(true);

const htmlStyle = {
  q: {
    /* fix fo Html Fonts Not working without adding blank tag */
  },
  p: {
    color: colors.defaultTextColor,
    fontSize: fonts.fontSize.font16,
    fontFamily: fonts.regular,
  },
  b: {
    color: colors.black,
    fontSize: fonts.fontSize.font16,
    fontFamily: fonts.black,
  },
};

class CreditShellOverlay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      overlayPosition: new Animated.Value(0),
    };
  }

  static navigationOptions = { header: null };

  componentDidMount() {
    const bottom = 400;
    const delay = 200;
    const duration = 400;
    this.startAnimate(bottom, duration, delay);
  }

  startAnimate(bottom, duration, delay) {
    Animated.timing(this.state.overlayPosition, {
      toValue: bottom,
      easing: Easing.easeInOut,
      duration,
      delay,
    }).start();
  }
  render() {
    const { header, description, ctaList } = this.props;
    return (
      <View style={styles.overlayContainer}>
        <TouchableHighlight style={styles.overlayBg}>
          <Text>.</Text>
        </TouchableHighlight>
        <Animated.View style={[styles.overlayContent, { bottom: this.state.overlayPosition }]}>
          <View style={[AtomicCss.flexRow, AtomicCss.flexWrap, AtomicCss.marginBottom20]}>
            {!isEmpty(header) && (
              <Text style={[styles.heading, fsStyle.font28]}>
                {header} <Image style={styles.creditShellIcon} source={CREDIT_SHELL_ICON} />
              </Text>
            )}
          </View>
          {!isEmpty(description) &&
            description.map((desc, index) => (
              <HTMLView
                value={desc}
                stylesheet={htmlStyle}
                style={
                  index === description.length - 1
                    ? styles.marginBottom60
                    : AtomicCss.marginBottom24
                }
                onLinkPress={(url) =>
                  Actions.openPsWebView({
                    headerText: 'Information',
                    headerIcon: BACK_ARROW_WHITE_ICON,
                    url,
                  })
                }
              />
            ))}
          {!isEmpty(ctaList) && (
            <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween, AtomicCss.alignCenter]}>
              {ctaList.map((cta) => this.getBtnView(cta))}
            </View>
          )}
        </Animated.View>
      </View>
    );
  }

  getBtnView = (cta) => {
    if (cta.buttonType === 'BLUE_NORMAL_TEXT_BTN') {
      return (
        <TouchableOpacity
          activeOpacity={0.6}
          onPress={() => {
            this.props.btnClick(cta.actionType);
          }}
        >
          <Text style={[fsStyle.font16, AtomicCss.azure, fonts.regularFontFamily]}>
            {cta.title}
          </Text>
        </TouchableOpacity>
      );
    }
    return (
      <View style={styles.width153}>
        <Button
          btnTxt={cta.title}
          clickHandler={() => this.props.btnClick(cta.actionType)}
          buttonTextCss={fsStyle.font14}
          btnBg="gradientBtn"
        />
      </View>
    );
  };
}

const styles = StyleSheet.create({
  overlayContainer: {
    justifyContent: 'flex-end',
    position: 'absolute',
    bottom: 0,
    left: 0,
    height: '100%',
    width: '100%',
    zIndex: 1000,
    elevation: 3,
  },
  overlayBg: {
    backgroundColor: 'rgba(0,0,0,.5)',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    zIndex: 3,
    elevation: 3,
  },
  heading: {
    lineHeight: 32,
    marginBottom: 2,
    color: '#000000',
    fontFamily: fonts.black,
  },
  subHeading: {
    fontSize: 28,
    marginBottom: 20,
    color: '#26b5a9',
    fontFamily: fonts.black,
  },
  overlayContent: {
    backgroundColor: '#fff',
    paddingVertical: 30,
    paddingHorizontal: 20,
    shadowColor: '#330000',
    shadowOpacity: 0.3,
    shadowRadius: 2,
    elevation: 4,
    zIndex: 4,
    position: 'absolute',
    bottom: 0,
    marginBottom: -400,
    width: '100%',
    shadowOffset: {
      width: 1,
      height: 0,
    },
  },
  width153: {
    width: 153,
  },
  marginBottom60: {
    marginBottom: 60,
  },

  creditShellIcon: {
    width: 24,
    height: 24,
    marginLeft: 5,
    marginTop: 5,
  },
});
export default CreditShellOverlay;
