import React from 'react';
import {View,Image, Text, TouchableOpacity} from 'react-native';
import createStyle from './ContactUsCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { BLUE_RIGHT_ARROW_ICON, CONTACT_US_ICON } from '../../../HotelImageConstants';
import HotelBookingDetailsConstant from '../../HotelBookingDetailsConstant';
import { getImageTransformStyling } from '../../../../utils/PostSaleUtil';
import {handleClickEvent} from '../../utils/HotelBookingDetailUtil';
import { getStaticData } from '../../../../staticData/staticData';
import { useTheme } from '../../../../theme/theme.context';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const ContactUs = ({response, card, pageName, header, info, disable = false}) => {
	const { fsStyle, ...fonts } = getFont(true);
	const staticData = getStaticData();
	const {psTheme: theme}=useTheme();
	const styles = createStyle(theme);
	
	const onClick = () => {
		handleClickEvent(card, {actionFamily: HotelBookingDetailsConstant.ACTION_CALL_MMT_SUPPORT}, response, pageName);
	}
	const headerText = header ? header: staticData.contactUsText;
	const infoText = info ? info : staticData.contactUsCardText.additionalText.needMoreHelpText;

	const renderCardItem = () => {
    return (
      <TouchableOpacity style={styles.contactCard} onPress={() => {!disable && onClick()}}>
        <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.flex1]}>
          <Image style={styles.contactUs} source={CONTACT_US_ICON} />
          <View style={[AtomicCss.flex1]}>
           <Text style={[fonts.blackFontFamily, fsStyle.font14, AtomicCss.blackText, AtomicCss.marginBottom3]}>{headerText}</Text>  
		   <Text style={[fonts.regularFontFamily, fsStyle.font14, AtomicCss.defaultText]}>{infoText}</Text>
          </View>
        </View>
        <View>
	        {!disable && <Image style={[styles.rightBlueArrow, getImageTransformStyling()]} source={BLUE_RIGHT_ARROW_ICON} />}
        </View>
      </TouchableOpacity>
    );
  };
	
	const extraContactUsInfo = response?.hotelBookingInfo?.commonFields?.contactUsText;
	return (
    <View style={[styles.cardWrapper]}>
      {renderCardItem()}
      {!!extraContactUsInfo && (
        <View style={AtomicCss.marginTop10}>
	     <Text style={[fsStyle.font14, fonts.regularFontFamily, AtomicCss.defaultText, AtomicCss.textCenter]}>{extraContactUsInfo}</Text> 
		</View>
      )}
    </View>
  ) 
}
export default ContactUs