import React from 'react';
import {Text} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import PropTypes from 'prop-types';
import styles from './ActionButtonCss';
import {gradient} from '../../HolidayBookingConstants';
import TouchableOpacity from '../TouchableOpacity';

const ActionButton = (props) => {
  const gradientStyle = {...styles.actionButton, ...props.btnStyle};
  return (
    <TouchableOpacity onPress={props.onPress} style={styles.actionBtnContainer} disabled={!props.enabled} activeOpacity={0.7}>
      <LinearGradient
        colors={props.enabled ? gradient.lightBlue : gradient.grey}
        start={{x: 0.0, y: 0.0}}
        end={{x: 1.0, y: 0.0}}
        style={gradientStyle}
      >
        <Text style={[styles.actionText, styles.basicTextStyle]}> {props.btnText} </Text>
      </LinearGradient>
    </TouchableOpacity>
  );
};

ActionButton.propTypes = {
  onPress: PropTypes.func.isRequired,
  btnText: PropTypes.string.isRequired,
  enabled: PropTypes.bool,
  btnStyle: PropTypes.object
};

ActionButton.defaultProps = {
  enabled: true,
  btnStyle: {}
};

export default ActionButton;
