import { StyleSheet } from 'react-native';

export default (theme) => {
  return StyleSheet.create({
    semiCircle: {
      width: 16,
      height: 8,
      backgroundColor: theme.color.gray2,
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      marginLeft: 4,
      marginRight: 2,
    },
  });
}
