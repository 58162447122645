import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
import { StyleSheet } from 'react-native';

const getStyles = (fonts, color) =>
  StyleSheet.create({
    headingStyle: {
      fontSize: fonts.fontSize.font14,
      ...fonts.regularFontFamily,
      color: color.lightTextColor,
    },
    subHeadingStyle: {
      fontSize: fonts.fontSize.font14,
      ...fonts.boldFontFamily,
      color: color.black,
    },
    trainScheduleTextStyle: {
        fontSize: fonts.fontSize.font16,
      ...fonts.blackFontFamily,
      color: color.black,
    },
    checkScheduleTextStyle: {
        fontSize: fonts.fontSize.font14,
      ...fonts.boldFontFamily,
      color: color.azure,
    },
    lastUpdatedTextStyle: {
        fontSize: fonts.fontSize.font14,
      ...fonts.regularFontFamily,
      color: color.defaultTextColor,
    },
    pedZero: {
      paddingHorizontal: 0,
      paddingVertical: 0,
    },
    headingWrapper: {
      flexDirection: 'row',
      ...getPlatformElevation(1),
      paddingHorizontal: 16,
      paddingTop: 16,
      paddingBottom: 12,
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
    },
    iconWrapper: {
      width: 26,
      height: 26,
      marginRight: 10,
      alignItems: 'center',
      justifyContent: 'flex-start',
    },

    paxIconStyle: {
      width: 24,
      height: 24,
    },
    detailsSection: {
      paddingHorizontal: 16,
      paddingVertical: 20,
    },
    refreshIconStyle: {
      width: 20,
      height: 24,
      marginBottom:5,
      marginRight:5
    },
    bullet: {
      width: 6,
      height: 6,
      backgroundColor: color.grey33,
      borderRadius: 6,
      marginHorizontal: 6,
    },
    scheduleContainer: {
      padding: 12,
      borderRadius: 4,
    },
    platformWrap: {
      ...fonts.boldFontFamily,
      fontSize: fonts.fontSize.font14,
      backgroundColor: color.lighterBlue,
      borderRadius: 4,
      lineHeight: 17,
      paddingHorizontal: 4,
      marginLeft: 5,
      color: color.darkBlue12,
    },
    scheduleArrv: {
      width: '49%',
      borderColor: color.greyBookedSeat,
      borderWidth: 1,
      paddingHorizontal: 8,
      paddingVertical: 8,
      borderRadius: 4,
    },
    TimeTag: {
      backgroundColor: color.lightGreen17,
      color: color.green9,
      lineHeight: 14,
      paddingVertical: 2,
      paddingHorizontal: 4,
      fontSize: fonts.fontSize.font12,
      marginLeft: 3,
      borderRadius: 4,
      ...fonts.boldFontFamily,
    },
    delayTag: {
      backgroundColor: color.amour,
      color: color.red
    },
    liveTrain: {
      width: 32,
      height: 32,
    },
    lineArrow: {
      height: 14,
      width: 27,
    },
    updatedMsgStyles: {
      fontSize: fonts.fontSize.font12,
      color: color.grey,
      ...fonts.regularFontFamily,
      marginRight: 5
    },
  });

  export const getWhiteCardStyles = (color) => {
    return StyleSheet.create({
      whiteCard: {
        ...getPlatformElevation(2),
        backgroundColor: color.white,
        borderRadius: 8,
      }
    })
  }

export default getStyles;
