import React, { useState } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Image } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { greenTick2, userIcon, triangle } from '../../../../CabImageConstants';
import { CabBookedInfoProps } from '../../../../types';
import createStyles from './CabBookedInfoCss';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import { isEmpty } from 'lodash';

const CabBookedInfo: React.FC<CabBookedInfoProps> = ({ driverETAData }) => {
  const {
    driverDetailsExpectedTime,
    driverDetailsMsg,
    bookingCreatedTime,
    cabBookedText,
    driverETAText,
    driverEtaPercentage = '40%',
  } = driverETAData || {};
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);
  return (
    <View style={styles.cabBookedInfoSection}>
      <View
        style={[
          AtomicCss.flexRow,
          AtomicCss.alignCenter,
          AtomicCss.spaceBetween,
          AtomicCss.marginBottom12,
        ]}
      >
        <View style={AtomicCss.flex1}>
          {!isEmpty(cabBookedText) && (
            <Text
              style={[
                AtomicCss.boldFont,
                AtomicCss.blackText,
                AtomicCss.font12,
                AtomicCss.marginBottom3,
              ]}
            >
              {cabBookedText}
            </Text>
          )}
          {!isEmpty(bookingCreatedTime) && (
            <Text
              style={[
                AtomicCss.regularFont,
                AtomicCss.defaultText,
                AtomicCss.font11,
              ]}
            >
              {bookingCreatedTime}
            </Text>
          )}
        </View>
        <View style={AtomicCss.flex1}>
          {!isEmpty(driverETAText) && (
            <Text
              style={[
                AtomicCss.boldFont,
                AtomicCss.blackText,
                AtomicCss.font12,
                styles.textRight,
                AtomicCss.marginBottom3,
              ]}
            >
              {driverETAText}
            </Text>
          )}
          {!isEmpty(driverDetailsExpectedTime) && (
            <Text
              style={[
                AtomicCss.regularFont,
                AtomicCss.defaultText,
                AtomicCss.font11,
                styles.textRight,
              ]}
            >
              {driverDetailsExpectedTime}
            </Text>
          )}
        </View>
      </View>
      <View style={styles.bookingStatusTrackerInfo}>
        <View style={styles.iconWrapper}>
          <Image style={styles.iconStyle} source={greenTick2} />
        </View>
        <View style={[AtomicCss.flex1]}>
          <View style={styles.progessStatusWrapper}>
            <View style={[{ width: driverEtaPercentage }, styles.progressStatus]}></View>
          </View>
        </View>
        <View style={styles.iconWrapper}>
          <Image style={styles.iconStyle} source={userIcon} />
        </View>
      </View>
      {!isEmpty(driverDetailsMsg) && (
        <>
         <Image style={[styles.triangle, {marginLeft: '88%'}]} source={triangle} />
           <View style={styles.TootlTipInnerInfo}>
            <Text style={[AtomicCss.whiteText, AtomicCss.regularFont, AtomicCss.font12]}>
              {driverDetailsMsg}
            </Text>
          </View>
        </>
      )}
    </View>
  );
};

export default CabBookedInfo;
