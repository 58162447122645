import React from 'react';
import { View, Text, Image, TouchableOpacity, Animated } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import styles from './OverlayCss';
import { getStaticData } from '../../../../../../staticData/staticData';
import { CYT_CLOSE_ICON } from '../../../../../FlightImageConstants';
import { getFont } from '../../../../../../PostSalesStyles';

const CompleteTripOverlay = (props) => {
  const { closeText } = getStaticData();
  const { fsStyle, ...fonts } = getFont(true);
  return (
    <View style={[styles.overlayWrapper]}>
      <TouchableOpacity
        onPress={props.handleBottomOverlay}
        activeOpacity={1}
        style={[styles.overlay]}
      ></TouchableOpacity>
      <View style={[styles.overlayContent]}>
        <Animated.View style={{ bottom: props.overlayPosition }}>
          {props.content}
          <TouchableOpacity style={styles.closeWrapper} onPress={props.handleBottomOverlay}>
            <LinearGradient
              start={{ x: 0.0, y: 1.0 }}
              end={{ x: 1.0, y: 0.0 }}
              colors={['#001037', '#000000']}
              style={styles.closeStyle}
            >
              <Image source={CYT_CLOSE_ICON} style={styles.closeIconStyle} />
              <Text style={[AtomicCss.whiteText, fsStyle.font11, fonts.blackFontFamily]}>
                {closeText}
              </Text>
            </LinearGradient>
          </TouchableOpacity>
        </Animated.View>
      </View>
    </View>
  );
};
export default CompleteTripOverlay;
