import React, { useEffect } from 'react';
import { View, Image, Text, TouchableOpacity } from 'react-native';
import styles from './StayConnectedCardCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getStaticData } from '../../../../staticData/staticData';
import { isEmpty } from 'lodash';
import { PHONE_CLOCK_ICON, BLUE_BG_CALL_ICON } from '../../../HotelImageConstants';
import { STAY_CONNECTED_CARD_CTA_TYPES } from '../../../HotelConstants';
import HotelBookingTrackingHelper from '../../../HotelBookingTrackingHelper';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const StayConnectedCard = ({ card, response, handleClickEvent, pageName }) => {
  const { fsStyle, ...fonts } = getFont(true);
  const { stayConnectedText } = getStaticData();
  const { stayConnectedCardData } = response || {};
  const { contacts } = stayConnectedCardData || {};

  useEffect(()=>{
    HotelBookingTrackingHelper.trackShowEvent(pageName, "gb_stay_connected_card_shown", response);
  },[pageName, response]);

  const onClick = (ctaType, contactData) => {
    const { contactList, title } = contactData;
    switch (ctaType) {
      case STAY_CONNECTED_CARD_CTA_TYPES.CALL_MMT:
        HotelBookingTrackingHelper.trackClickEvent(pageName, "gb_contact_mmt_click", response);
        break;
      case STAY_CONNECTED_CARD_CTA_TYPES.CALL_PROPERTY:
        HotelBookingTrackingHelper.trackClickEvent(pageName, "gb_contact_property_click", response);
        break;
      default:
    }
    // Open bottomsheet of contact nos.
    handleClickEvent && handleClickEvent(contactList,title);
  };

  const getIcon = (ctaType) => {
    switch (ctaType) {
      case STAY_CONNECTED_CARD_CTA_TYPES.CALL_MMT:
      case STAY_CONNECTED_CARD_CTA_TYPES.CALL_PROPERTY:
        return BLUE_BG_CALL_ICON;
      default:
        return null;
    }
  };

  return (
    <View style={styles.myBizStayConnectedCard}>
      <View style={styles.stayConnectedInnerCard}>
       
        <View style={styles.stayConnectedContent}>
          <View>
            <Text style={[fsStyle.font16, fonts.blackFontFamily, AtomicCss.blackText]}>
              {stayConnectedText}
            </Text>
          </View>
          {card && !isEmpty(card.instruction) && (
            <View style={[AtomicCss.marginTop10]}>
              <Text
                style={[
                  fsStyle.font14,
                  AtomicCss.defaultText,
                  fonts.regularFontFamily,
                  AtomicCss.lineHeight18,
                  AtomicCss.width83,
                ]}
              >
                {card.instruction}
              </Text>
            </View>
          )}
          <View style={[AtomicCss.marginTop10]}>
            {!isEmpty(contacts) &&
              contacts.map((contactData, index) => {
                return (
                  <View
                    style={[styles.contactListWrap, index > 0 ? styles.contactListDivider : '']}
                  >
                    <View style={AtomicCss.flexRow}>
                      <View style={styles.contactImgWrap}>
                        <Image source={{ uri: contactData.icon }} style={styles.imgMyTripIcon} />
                      </View>
                      <View style={styles.contactListContent}>
                        {contactData && contactData.title && (
                          <Text style={[fsStyle.font13, fonts.boldFontFamily, AtomicCss.blackText]}>
                            {contactData.title}
                          </Text>
                        )}
                        {contactData && contactData.subtitle && (
                          <Text
                            style={[
                              fsStyle.font13,
                              AtomicCss.greyText,
                              fonts.regularFontFamily,
                              AtomicCss.marginTop5,
                              AtomicCss.width83
                            ]}
                          >
                            {contactData.subtitle}
                          </Text>
                        )}
                      </View>
                    </View>
                    <View style={[AtomicCss.flexRow]}>
                      {contactData && !isEmpty(contactData.ctaList) && contactData.ctaList.map((cta) => {
                        const iconUri = getIcon(cta);
                        return iconUri && (
                          <TouchableOpacity onPress={() => onClick(cta, contactData)}>
                            <Image source={iconUri} style={styles.imgContactIcon} />
                          </TouchableOpacity>
                        );
                      })}
                    </View>
                  </View>
                );
              })}
          </View>
        </View>
      </View>
    </View>
  );
};

export default StayConnectedCard;
