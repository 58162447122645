import {colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import {fontSizes} from '../../HolidayBookingConstants';

const styles = {
  actionBtnContainer: {
    alignItems: 'flex-end'
  },
  actionButton: {
    height: 40,
    borderRadius: 22,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 7,
    marginBottom: 7
  },
  actionText: {
    color: colors.white,
    fontSize: fontSizes.reg,
    fontFamily: fonts.bold,
    padding: 8
  },
  basicTextStyle: {
    backgroundColor: 'transparent'
  },
};

export default styles;
