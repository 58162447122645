import React from 'react';
import { View, Text, Image } from 'react-native';
import QRCode from 'react-qr-code'
import { getActionButton, handleClickEvent } from '../../utils/AcmeBookingDetailUtil';

import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss'
import { fonts } from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import { isNotNullAndEmpty } from '../../../hotel/details/utils/HotelBookingDetailUtil';
import {voucherIcon} from '../../utils/AcmeImageConstant';

const ActivityVoucherCard = (props) => {

    const { scancode, pnrNo } = props.response.acmeBookingInfo.acmeBookingDetails[0];

    const getActionListToDisplay = () => {
        /*
        - take actions present in card info
        - filter them based on criterias
        - return subset of actions present in card info
        */

        const actionListToShow = [];

        props.card.actionList &&
            props.card.actionList.forEach((action) => {
                switch (action.actionFamily.toUpperCase()) {
                    case 'HOWTOREDEEM':
                        isNotNullAndEmpty(props.response.acmeBookingInfo.acmeBookingDetails[0].acmeAdditionalInfo.howToRedeem) &&
                            actionListToShow.push(action)
                        break;
                    default:
                        break;
                }
            });
        return {
            ...props.card,
            actionList: actionListToShow,
        }
    };

    const handleClick = (action) => {
        handleClickEvent(props.card, action, props.response, props.pageName);
    };

    // format PNR if it is very long
    // in some cases PNR is different from booking id / ref. number
    const PNR_CLIP_LENGTH = 20;
    const pnrFormatted = pnrNo.length > PNR_CLIP_LENGTH ?
        `${pnrNo.substring(0, PNR_CLIP_LENGTH)}...` :
        pnrNo;

    return (
        <View style={[AtomicCss.marginBottom10, styles.cardBorder]}>
            <View style={[styles.infoCardWrapper]}>
                <View style={AtomicCss.flexRow}>
                    <View style={styles.headingIconWrapper}>
                        <Image style={styles.headingIconStyle} source={voucherIcon} />
                    </View>
                    {props.response.voucherCard && <View style={[styles.infoCardContent, AtomicCss.marginBottom10]}>
                        <Text style={[AtomicCss.defaultText, AtomicCss.blackFont, AtomicCss.font14, AtomicCss.marginBottom15]}>{props.response.voucherCard.heading}</Text>
                        <Text style={[AtomicCss.regularFont, AtomicCss.font14, AtomicCss.defaultText, AtomicCss.lineHeight20]}>{props.response.voucherCard.description.join()}</Text>
                    </View>}
                </View>
                <View style={styles.dtlsSection}>
                    <View style={styles.qrCodeWrapper}>
                        <QRCode
                            value={scancode}
                            size={164}
                            fgColor={colors.black}
                            bgColor={colors.white}
                        />
                    </View>
                </View>
                <Text style={styles.qrCodeInfoTxt}>
                    {`PNR - ${pnrFormatted}`}
                </Text>
            </View>
            {getActionButton(getActionListToDisplay(), props.response, handleClick)}
        </View >
    )
}


const styles = {
    cardBorder: {
        borderTopColor: '#e4e4e4',
        borderBottomColor: '#e4e4e4',
        borderTopWidth: 1,
        borderBottomWidth: 1
    },
    infoCardWrapper: {
        backgroundColor: '#fff',
        justifyContent: 'space-between',
        ...getPlatformElevation(1),
        paddingBottom: 20,
        marginBottom: 1,
    },

    infoCardContent: {
        paddingRight: 8,
        paddingVertical: 15,
        flex: 1
    },

    infoCustom: {
        fontSize: 14,
        color: '#4a4a4a',
        lineHeight: 18,
        fontFamily: fonts.regular,
    },
    headingIconWrapper: {
        width: 46,
        alignItems: 'center',
    },
    headingIconStyle: {
        width: 20,
        height: 13,
        top: 15,
    },

    redirectBtnWrapper: {
        paddingTop: 20,
        paddingBottom: 20,
        paddingLeft: 50,
        paddingRight: 35,
        justifyContent: 'space-between',
        flexDirection: 'row',
        backgroundColor: '#fff',
        borderBottomWidth: 1,
        borderBottomColor: '#ededed',
        alignItems: 'center',

    },

    rightArrowIcon: {
        width: 9,
        height: 13
    },

    linkBtnTxt: {
        fontSize: 14,
        fontFamily: fonts.bold,
        color: "#008cff"
    },
    buttonWrapper: {
        paddingTop: 20,
        width: '90%'
    },
    qrCodeWrapper: {
        width: 172,
        height: 172,
    },
    dtlsSection: {
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: 10,
    },
    qrCodeInfoTxt: {
        color: '#000',
        fontFamily: fonts.bold,
        fontSize: 14,
        textAlign: 'center'
    },
}

export default ActivityVoucherCard
