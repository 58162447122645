import isEmpty from 'lodash/isEmpty';
import { ACTION_ID, BOOKING_TYPE } from '../constants';
import { getImagePath } from '../../../utils/PostSaleUtil';
import { LayoutDetail, CardList, SegmentInfo, Train, PnrDetails, Action, ACTION_TYPE, DelayTimeData, Image, StaticData} from '../../types';
import {DARK_GREEN_CIRCLE_TICK, INFO_ORANGE_ICON} from "../../Utils/RailImageConstant"
export const getCardbyCardId = (cardId: string, response: { layoutDetail?: LayoutDetail } = {}): CardList => {
  const cards = response.layoutDetail?.cardList;
  return cards?.find(card => card.cardId === cardId) || {};
};

export const getColor = (state: string, subState: string, color: any) => {
  if (!state) {
    return color.white;
  }
  switch (state) {
    case BOOKING_TYPE.UPCOMING:
    case BOOKING_TYPE.COMPLETED:
    case BOOKING_TYPE.ONTRIP:
      if(subState ==BOOKING_TYPE.UNCONFIRMED){
        return [color.textGrey, color.textGrey]
        }
      return [color.green8, color.green];
    case BOOKING_TYPE.PENDING:
    case BOOKING_TYPE.CANCELLED:
    case BOOKING_TYPE.REFUNDED:
      return [color.textGrey, color.textGrey];
  }
};


export const getSegmentInfo = (response?: { train?: Train[] }): SegmentInfo => {
  if (
    response?.train?.[0]?.pnrDetails?.[0]?.segmentInfo?.length
  ) {
    return response.train[0].pnrDetails[0].segmentInfo[0];
  }
  return {};
};

export const getPnrDetails = (response?: { train?: Train[] }): PnrDetails => {
  if (response?.train?.[0]?.pnrDetails?.length) {
    return response.train[0].pnrDetails[0];
  }
  return {};
};

export const getCancellationPolicyActionList = (actionList?: Action[]): Action[] => {
  if (!actionList || actionList.length === 0) {
    return {};
  }
  const cancelPolicyActionList = actionList.filter(item => item.actionId === ACTION_ID.CANCEL_POLICY);
  const { innerActionList } = cancelPolicyActionList.length ? cancelPolicyActionList[0] : {};
  return innerActionList || [];
};

export const getRoundIcon = (actionFamily : ACTION_TYPE): Image => {
  switch(actionFamily){
    case ACTION_TYPE.DOWNLOAD_TICKET:
      return getImagePath('downloadEticket.png')
    case ACTION_TYPE.DOWNLOAD_INVOICE:
      return getImagePath('downloadIconV2.webp')
  }
  return {};
}


export const getTimeStatus = (trainDelayed: boolean, delayInMins: string, staticData: StaticData) : string => {
  let {minDelayText, onTimeText} = staticData;
  if(trainDelayed){
    minDelayText = minDelayText.replace("{time}", delayInMins);
    return minDelayText;
  }else{
    return onTimeText;
  }
}

export const getCardHeaderStyle = (response : Response, color : object) => {
  const {chartPreapred} = getPnrDetails(response);
  if(chartPreapred === 1){
    return {
      source : DARK_GREEN_CIRCLE_TICK,
      textColor : color.green,
      backgroundColor : color.gray6
    }
  }else{
    return {
      source : INFO_ORANGE_ICON,
      textColor : color.lightYello,
      backgroundColor : color.yellow3
    }
  }
}
