import React from 'react';
import {Text, View} from 'react-native';
import BasePage from '../../Common/PostSalesBasePage';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';

class TravellerCardItem extends BasePage {
  render() {
    const {item} = this.props;
    const ageLabel = `  ${item.age} ${item.age > 1 ? 'yrs' : 'yr'}, ${item.gender}`;
    let nameLabel = item.travellerName;
    if (item.travellerName.length > 26) {
      nameLabel = `${item.travellerName.substr(0, 25)}...`;
    }
    return (
      <View style={[AtomicCss.flexRow, AtomicCss.marginBottom10]}>
        <Text
          style={[AtomicCss.boldFont, AtomicCss.font14, AtomicCss.defaultText,
            AtomicCss.lineHeight20]}
        >{nameLabel}
        </Text>
        <Text
          style={[AtomicCss.regularFont, AtomicCss.font14, AtomicCss.defaultText,
            AtomicCss.lineHeight20]}
        >{ageLabel}
        </Text>
      </View>
    );
  }
}

export default TravellerCardItem;
