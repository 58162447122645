import React from "react";
import { View, Text, Image, TouchableOpacity, NativeModules } from "react-native";
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss'
import createStyles from "../whyBookUsCss";
import { RIGHT_ARROW } from "../../../../../../Utils/tripGuaranteeImageConstants";
import { getFont } from '../../../../../../../PostSalesStyles';
import { useTheme } from '../../../../../../../theme/theme.context';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import { getClickType } from "../../../../../../Utils/TgsBookingDetailUtil";
import TGSBookingTrackEventHelper from "../../../../../../Utils/TGSBookingTrackEventHelper";
import { PAGE_NAME } from "../../../../../../Utils/TGSBookingConstants";
import { getGdprData, shouldShowGdprBlockerScreen, isGroundLob } from "../../../../../../../utils/PostSaleUtil";
import { getStaticData } from "../../../../../../../staticData/staticData";
const ListCard = ({ item, index }) => {
    const { heading, subHeading, deepLink, icon, backgroundColor, key } = item || {};
    const { url } = icon || {};
    const fonts = getFont();
    const {psTheme : theme} = useTheme();
    const styles = createStyles(theme,fonts);
    const handleClick = async () => {

        const { UserSessionModule } = NativeModules;
        const showGdprBlockerScreen = await shouldShowGdprBlockerScreen();

        if (showGdprBlockerScreen && isGroundLob(key)) {
            const { gdprText } = getStaticData(false);
            const { lobs: { groundTransport = 'Ground Transport'} = {} } = gdprText || {} 
            UserSessionModule.openGDPRBlockerScreen(groundTransport, null, getGdprData(gdprText));
        } else {

            const clickType = getClickType(key);
            TGSBookingTrackEventHelper.trackClickEvents(PAGE_NAME.TRIP_DETAILS, clickType);
            if(!!deepLink){
                GenericModule.openDeepLink(deepLink);
            }
        }
    }
    return (
        <TouchableOpacity style={[styles.listCard, { borderColor: backgroundColor }]} onPress={handleClick}>
            <View style={[AtomicCss.flexRow]}>
                <View style={[AtomicCss.flexRow, AtomicCss.flex1]}>
                    <View style={[styles.heigLighter, { backgroundColor: backgroundColor }]}></View>
                    <View style={styles.listInnerDtls}>
                        {!!url && <Image style={styles.iconStyle} source={{uri:url}} /> }
                        <View style={styles.content}>
                            {!!heading && <Text style={[styles.title,fonts.boldFontFamily]}>{heading}</Text> }
                            {!!subHeading && <Text style={[styles.desc,fonts.regularFontFamily]}>{subHeading} </Text> }
                        </View>
                    </View>
                </View>
                <Image style={styles.rightArrowStyle} source={RIGHT_ARROW} />
            </View>

        </TouchableOpacity>
    )
}

export default ListCard;