import React from 'react';
import PropTypes from 'prop-types';
import {View, Text, Image, TouchableOpacity} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import styles from './MetroCss';
import {isNotNullAndEmpty, getActionButton, handleClickEvent} from '../MetroBookingDetailUtil';
import {BLACK_ARROW_ICON} from '../MetroImageConstant';

class TopStatusCardConfirmed extends React.Component {
  render() {
    return (
      <LinearGradient start={{x: 1.0, y: 0.0}} end={{x: 0.0, y: 1.0}} colors={this.props.color} style={[styles.topStatusCard]} >
        <View style={[styles.topStatusBar, styles.statusCardOuter]}>
          <TouchableOpacity style={styles.backIconPress} onPress={this.props.onBackPress}>
            <Image style={[styles.mar10Bt, styles.backarrow]} source={BLACK_ARROW_ICON} />
          </TouchableOpacity>
          <Image style={[styles.mar3Bt, styles.successIcon]} source={this.props.iconPath} />
        </View>
        <View style={styles.StatusCardWrapper}>
          <Text style={[styles.greenText, styles.AlignCenter, styles.font18, styles.mar10Bt, styles.blackFont]}>
            {this.props.headText}
          </Text>

          <View/>

          <View>
            <Text style={[styles.GreyColor, styles.AlignCenter, styles.mar15Bt, styles.font14, styles.regularFont]}>
              BOOKING ID {this.props.bookingId}
            </Text>
          </View>

          <View style={isNotNullAndEmpty(this.props.subText) ? styles.showView : styles.hideView}>
            <Text style={[styles.WhiteText, styles.AlignCenter, styles.mar20Bt, styles.font14, styles.regularFont]}>
              {this.props.subText}
            </Text>
          </View>

          <View style={isNotNullAndEmpty(this.props.card.actionList) ? styles.whiteBtnHalfOuter : styles.hideView}>
            {getActionButton(this.props.card, this.props.response, this.handleClick)}
          </View>
        </View>
      </LinearGradient>
    );
  }
    handleClick = (action) => {
      handleClickEvent(this.props.card, action, this.props.response, this.props.pageName);
    };
}
TopStatusCardConfirmed.propTypes = {
  response: PropTypes.object.isRequired,
  iconPath: PropTypes.number.isRequired,
  headText: PropTypes.string.isRequired,
  color: PropTypes.array.isRequired,
  subText: PropTypes.string,
  card: PropTypes.object.isRequired,
  pageName: PropTypes.string.isRequired,
  subTextHeader: PropTypes.string,
  subTextDetails: PropTypes.string
};
TopStatusCardConfirmed.defaultProps = {
  subTextDetails: '',
  subText: '',
  subTextHeader: ''
};
export default TopStatusCardConfirmed;
