import { ModificationRequestData, HoldResponseData} from './types';
import { PAYMENT_EVENT_LISTNER } from '../../HotelBookingDetailsConstant';

export const createPaymentModuleRequestIOS = (holdResponse: HoldResponseData, requestData: ModificationRequestData) => ({
	bookingInfo: getBookingInfo(holdResponse, requestData?.bookingId),
	userVO: getUserInfo(holdResponse),
	eventName: PAYMENT_EVENT_LISTNER,
});

export const createPaymentModuleRequestAndroid = (holdResponse: HoldResponseData, requestData: ModificationRequestData) => ({
	paymentDetails: getPaymentDetails(holdResponse, requestData?.bookingId),
	eventName: PAYMENT_EVENT_LISTNER,
});

const getBookingInfo = (holdResponse: HoldResponseData, bookingId: string) => {
  const { paymentDetails } = holdResponse || {};
  const { checkoutId} = paymentDetails || {};
  return {
    bookingId: bookingId,
    product: 'post_sales',
    checkoutId: '' + checkoutId,
  };
};

const getUserInfo = (holdResponse: HoldResponseData) => {
  const { paymentDetails } = holdResponse || {};
  const { emailid, mobile } = paymentDetails || {};
  return {
    mobile: mobile,
    travellerEmail: emailid,
  };
};


export const getPaymentDetails = (holdResponse: HoldResponseData, bookingId: string) => {
  const { paymentDetails } = holdResponse || {};
  const { checkoutId, emailid, mobile } = paymentDetails || {};
  return {
    checkoutId: checkoutId,
    travellerEmail: emailid,
    channel: 'post_sales',
    mobile: mobile,
    bookingId: bookingId,
  };
};
