import { Linking, Platform, BackHandler, Clipboard, View, NativeModules } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import React from 'react';
import Fecha from 'fecha';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import COMMON_CONSTANT from './commonConstant';
import getStyles from './ActionBtnCss';
import WhiteFullCapsuleButton from './ActionButton/WhiteFullCapsuleButton';
import RoundButtonWithIcon from './ActionButton/RoundButtonWithIcon';
import CapsuleBtn from './ActionButton/CapsuleBtn';
import CapsuleBtnFill from './ActionButton/CapsuleBtnFill';
import RectFullButton from './ActionButton/RectFullButton';
import BusBookingModule from '@mmt/legacy-commons/Native/BusBookingModule';
import PostSalesBookingTrackingHelper from '../PostSalesBookingTrackingHelper';
import { CANCELLATION_REQUEST_TYPE, CLAIM_REQUEST_TYPE } from '../PostSalesConstant';
import ViewControllerModule from '@mmt/legacy-commons/Native/ViewControllerModule';
import { isNetworkAvailable } from '@mmt/legacy-commons/Common/utils/AppUtils';
import * as Sentry from '@sentry/react-native';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import { getCommonHeaders, SEND_OTP_URL, VALIDATE_OTP_URL } from '../utils/NetworkUtils';
import { POST } from '../PostSalesConstant';
import { isNotNullAndEmpty } from '../bus/utils/BusBookingDetailUtil';
import { isNonEmpty } from '@mmt/legacy-commons/Common/utils/StringUtils';
// import { getAdsCard } from '@mmt/legacy-commons/adsConfig/adsCard';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import Toast from '@mmt/legacy-commons/Common/Components/Toast2';
import { getStaticData } from '../staticData/staticData';
import { isEmpty } from 'lodash';
import { getRootTag } from '@mmt/legacy-commons/AppState/RootTagHolder';
import { AbConfigKeyMappings, getPokusConfig } from '@mmt/legacy-commons/Native/AbConfig/AbConfigModule';
import { PokusLobs } from '@mmt/legacy-commons/Native/AbConfig/AbConfigKeyMappings';
import { getUserDetails } from '@mmt/legacy-commons/Native/UserSession/UserSessionModule';
import NetworkModule from 'packages/legacy-commons/Native/NetworkModule';
import AdtechWrapper from './AdTechWrapper'
import { getDataFromStorage, removeDataFromStorage, setDataInStorage } from "../utils/localstorage";
import HotelBookingDetailsConstant from '../hotel/details/HotelBookingDetailsConstant';
import BusBookingDetailsConstant from '../bus/BusBookingDetailsConstant';
import CabBookingDetailsConstant from '../cab/CabBookingDetailsConstant';
import { BLUE_PHONE_ICON, CALL_BLUE_BG_ICON, CHAT_BLUE_BG_ICON, CHECKIN_BLUE_BG_ICON, HEADSET_BLUE_BG_ICON, HELP_BLUE_BG_ICON, HOSTEL_ICON, ID_BLUE_BG_ICON, LETTER_INSTRUCTIONS_BLUE_BG_ICON, LIVE_TRACKING_ICON, RESEND_BLUE_BG_ICON, ROUNDED_CHAT_BLUE_BG_ICON, RUPEE_SYMBOL_WHITE, TICKET_SUCCESS_BLUE_BG_ICON, TRAIN_COACH_ICON, WHITE_DOWNLOAD_ICON, WHITE_MAP_ICON, WISH_WAND_ICON } from './commonImageConstants';
import FlightBookingDetailsConstant from '../flights/modules/details/FlightBookingDetailsConstant';
import MetroConstant from '../Metro/MetroConstant';
import RailBookingDetailConstant from '../Rails/details/RailBookingDetailConstant';

export default async function handleClickEvent(card, action, response, pageName, phoneNumber) {
  switch (action.actionFamily) {
    case COMMON_CONSTANT.ACTION_CALL_OPERATOR:
      openDialer(phoneNumber);
      PostSalesBookingTrackingHelper.trackClickEvent(pageName, 'call_support');
      break;
    case COMMON_CONSTANT.ACTION_HELP_SECTION:
      GenericModule.openActivity('mmt.intent.action.CUSTOMER_SUPPORT');
      PostSalesBookingTrackingHelper.trackClickEvent(pageName, 'go_to_help');
      break;
    case COMMON_CONSTANT.ACTION_MAKE_REQUEST:
      BusBookingModule.startWriteToUsActivity();
      PostSalesBookingTrackingHelper.trackClickEvent(pageName, 'email_support');
      break;
    case COMMON_CONSTANT.ACTION_CHAT_WITH_US:
      GenericModule.openMyraPage();
      PostSalesBookingTrackingHelper.trackClickEvent(pageName, 'chat_with_us');
      break;
    case COMMON_CONSTANT.ACTION_CALL_SOS:
      openDialer(COMMON_CONSTANT.HELPLINE_NUMBER);
      PostSalesBookingTrackingHelper.trackClickEvent(pageName, 'call_support');
      break;
    default:
      break;
  }
}

export function getButtonStyle(actionList) {
  const { actionType } = actionList[0];
  const styles = getStyles();
  switch (actionType) {
    case COMMON_CONSTANT.RECTANGLE_PRIMARY_HALF_WIDTH:
    case COMMON_CONSTANT.RECTANGLE_SECONDARY_HALF_WIDTH:
    case COMMON_CONSTANT.ROUND_BUTTON:
      return [styles.ActionBtnHalf, styles.transperantBgrnd];
    case COMMON_CONSTANT.RECTANGLE_FULL_BUTTON:
      return [styles.ActionBtnRec, styles.transperantBgrnd];
    default:
      return [styles.ActionBtn, styles.transperantBgrnd];
  }
}

export function isNullOrEmpty(object) {
  return object == null || object.length === 0;
}

export function isNullOrEmptyObject(obj) {
  return obj === undefined || obj === null || Object.keys(obj).length === 0;
}

export function isEmptyArray(arr) {
  return arr === undefined || arr === null || arr.length === 0;
}

export function fillCheckInCheckOutData(date, outputDateFormat) {
  if (isNotNullAndEmpty(date)) {
    const dateObject = Fecha.parse(date, 'DD-MMM-YYYY');
    return Fecha.format(new Date(dateObject), outputDateFormat);
  }
  return date;
}

export function getActionButton(card, response, clickHandler, fontFamily, lowerRadiusRound=false, upperRadiusRound=false, buttonRadiusStyle={}, getThrottleParams) {
  const actionButtons = [];
  const actionListLen = card.actionList.length;
  !isEmpty(card) && !isEmpty(card.actionList) && card.actionList.forEach((action, index) => {
    const lowerRound = lowerRadiusRound ? index === actionListLen-1 : false;
    const upperRound = upperRadiusRound ? index === 0 : false;
    addActionButton(card, action, actionButtons, response, clickHandler, fontFamily, lowerRound, upperRound, buttonRadiusStyle,getThrottleParams);
  })
  return actionButtons;
}

function addActionButton(card, action, actionButtons, response, clickHandler, fontFamily, lowerRound, upperRound, buttonRadiusStyle, getThrottleParams) {
  let throttleParam={
    throttleInterval: 0, isThrottleRequired: false
  }
  if(getThrottleParams && ( typeof getThrottleParams === 'function' ) ){
    throttleParam = getThrottleParams(action);
  }
  const {throttleInterval, isThrottleRequired} = throttleParam;

  switch (action.actionType) {
    case COMMON_CONSTANT.RECTANGLE_FULL_BUTTON:
      actionButtons.push(
        <RectFullButton
          key={`RectFullButton${action.actionLabeltext}`}
          card={card}
          action={action}
          response={response}
          clickHandler={clickHandler}
          fontFamily={fontFamily}
          lowerRound={lowerRound}
          upperRound={upperRound}
          throttleInterval={throttleInterval}
          isThrottleRequired={isThrottleRequired}
          buttonRadiusStyle={buttonRadiusStyle}
        />,
      );
      break;
    case COMMON_CONSTANT.RECTANGLE_PRIMARY_FULL_WIDTH:
    case COMMON_CONSTANT.RECTANGLE_PRIMARY_HALF_WIDTH:
      actionButtons.push(
        <CapsuleBtnFill
          key={`CapsuleBtnFill${action.actionLabeltext}`}
          card={card}
          action={action}
          response={response}
          throttleInterval={throttleInterval}
          isThrottleRequired={isThrottleRequired}
          clickHandler={clickHandler}
        />,
      );
      break;
    case COMMON_CONSTANT.RECTANGLE_SECONDARY_FULL_WIDTH:
    case COMMON_CONSTANT.RECTANGLE_SECONDARY_HALF_WIDTH:
      actionButtons.push(
        <CapsuleBtn
          key={`CapsuleBtn${action.actionLabeltext}`}
          card={card}
          action={action}
          response={response}
          throttleInterval={throttleInterval}
          isThrottleRequired={isThrottleRequired}
          clickHandler={clickHandler}
        />,
      );
      break;
    case COMMON_CONSTANT.ROUND_BUTTON:
      actionButtons.push(
        <RoundButtonWithIcon
          key={`RoundButtonWithIcon${action.actionLabeltext}`}
          card={card}
          action={action}
          response={response}
          throttleInterval={throttleInterval}
          isThrottleRequired={isThrottleRequired}
          clickHandler={clickHandler}
        />,
      );
      break;
    case COMMON_CONSTANT.WHITE_BUTTON_FULL:
    case COMMON_CONSTANT.WHITE_BUTTON_HALF:
      actionButtons.push(
        <WhiteFullCapsuleButton
          key={`WhiteFullCapsuleButton${action.actionLabeltext}`}
          card={card}
          action={action}
          response={response}
          throttleInterval={throttleInterval}
          isThrottleRequired={isThrottleRequired}
          clickHandler={clickHandler}
        />,
      );
      break;
    default:
      break;
  }
}


export function getRoundIcon(actionFamily) {
  if (actionFamily) {
    switch (actionFamily) {
      case HotelBookingDetailsConstant.ACTION_VIEW_LOCATION:
        return WHITE_MAP_ICON;
      case BusBookingDetailsConstant.ACTION_BUS_CALL_US:
      case CabBookingDetailsConstant.ACTION_CALL_DRIVER:
      case COMMON_CONSTANT.ACTION_CALL_OPERATOR:
      case FlightBookingDetailsConstant.CALL_AIRLINE_ACTION_BTN_FAMILY:
      case FlightBookingDetailsConstant.ACTION_CORPORATE_CALL:
      case MetroConstant.CALL_METRO_SUPPORT_FAMILY:
      case MetroConstant.CALL_MMT_SUPPORT_FAMILY:
        return CALL_BLUE_BG_ICON;
      case HotelBookingDetailsConstant.ACTION_CHAT_WITH_HOTEL:
        return ROUNDED_CHAT_BLUE_BG_ICON;
      case HotelBookingDetailsConstant.ACTION_RESEND_CONFIRMATION:
        return RESEND_BLUE_BG_ICON;
      case HotelBookingDetailsConstant.ACTION_VALID_ID_PROOF:
      case CabBookingDetailsConstant.ACTION_DRIVER_DETAILS:
        return ID_BLUE_BG_ICON;
      case HotelBookingDetailsConstant.ACTION_CHECK_IN_AT_HOTEL:
        return CHECKIN_BLUE_BG_ICON;
      case HotelBookingDetailsConstant.ACTION_CALL_MMT_SUPPORT:
      case FlightBookingDetailsConstant.ACTION_CALL_MMT_SUPPORT_GCC:
        return HEADSET_BLUE_BG_ICON;
      case HotelBookingDetailsConstant.ACTION_GO_TO_HELP_SELECTION:
        return LETTER_INSTRUCTIONS_BLUE_BG_ICON;
      case HotelBookingDetailsConstant.ACTION_CHAT_WITH_US:
      case BusBookingDetailsConstant.ACTION_BUS_MAKE_REQUEST:
      case FlightBookingDetailsConstant.CHAT_WITH_US_ACTION_BTN_FAMILY:
        return CHAT_BLUE_BG_ICON;
      case HotelBookingDetailsConstant.ACTION_MAKE_REQUEST:
      case COMMON_CONSTANT.ACTION_MAKE_REQUEST:
        return WISH_WAND_ICON;
      case BusBookingDetailsConstant.ACTION_BUS_GOTO_HELP_SECTION:
      case COMMON_CONSTANT.ACTION_HELP_SECTION:
        return HELP_BLUE_BG_ICON;
      case RailBookingDetailConstant.LIVE_TRAIN_STATION:
        return LIVE_TRACKING_ICON;
      case RailBookingDetailConstant.RAIL_LIVE_PNR_CHECK:
        return TICKET_SUCCESS_BLUE_BG_ICON;
      case RailBookingDetailConstant.TRAIN_COACH_POSITION:
        return TRAIN_COACH_ICON;
      case HotelBookingDetailsConstant.ACTION_CALL_HOTEL:
        return BLUE_PHONE_ICON;
      case HotelBookingDetailsConstant.ACTION_DOWNLOAD_VOUCHER:
      case HotelBookingDetailsConstant.ACTION_DOWNLOAD_CUSTOMER_VOUCHER:
        return WHITE_DOWNLOAD_ICON;
      case HotelBookingDetailsConstant.ACTION_PROPERTY_DETAILS:
        return HOSTEL_ICON;
      case HotelBookingDetailsConstant.ACTION_GB_PAY_INSTALLMENT:
        return RUPEE_SYMBOL_WHITE;
      case HotelBookingDetailsConstant.ACTION_CHAT_WITH_HOST_RTB:
      case HotelBookingDetailsConstant.ACTION_GROUP_BOOKNG_PAY_NOW:
        return null;
      default:
        return {
          uri: `https://promos.makemytrip.com/MIMA/${actionFamily}.png`,
        };
    }
  }
}

export function addRupeeSign(amount) {
  if (amount >= 0) {
    return `₹ ${Math.abs(amount.toFixed(2))} `;
  }
  return `- ₹ ${Math.abs(amount.toFixed(2))} `;
}

/*
  Function to get a particular length integer string
  input ( integer, length of integer string required )
  input   output
  30,2     30
  5,2      05
  30,1     30
  30,3     030

*/
export function geFixedWidthIntString(value, length) {
  length = length && length >= 1 && length <= 21 ? length : 1;
  return value.toLocaleString('en-US', {
    minimumIntegerDigits: length,
  });
}

export function openGoogleMapAlways(lat, lng, options, url) {
  const defaultLabel = 'Your Map';
  let label = defaultLabel;
  if (typeof options === 'object' && options) {
    if (typeof options.label === 'string' && options.label && options.label.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      label = options.label;
    }
  }
  let urlToOpen = null;
  if (lat != null && lng != null) {
    urlToOpen = `https:\/\/maps.google.com/maps?q=${lat},${lng}`;
  } else if (url) {
    urlToOpen = url;
  }
  if (urlToOpen) {
    Linking.canOpenURL(urlToOpen).then(supported=> {
      if (supported) {
        Linking.openURL(urlToOpen);
      }
    }).catch(e=>console.log('error while opening url ', urlToOpen));
  }
}

export function openGoogleMap(lat, lng, options = {}) {
  const scheme = Platform.select({ ios: 'maps:0,0?q=', android: 'geo:0,0?q=', web: 'https://www.google.com/maps/search/?api=1&query=' });
  const defaultLabel = 'Your Map';
  let label = defaultLabel;
  if (typeof options === 'object' && options) {
    if (typeof options.label === 'string' && options.label && options.label.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      label = options.label;
    }
  }
  let iosLabel = label.replace(/ /g, "%20");
  iosLabel = iosLabel.replace("|", "%7C");
  const latLng = `${lat},${lng}`;
  const url = Platform.select({
    ios: `${scheme}${iosLabel}@${latLng}`,
    android: `${scheme}${latLng}(${label})`,
    web: `${scheme}${latLng}`,
  });
  Linking.canOpenURL(url).then((supported) => {
    if (supported) {
      Linking.openURL(url);
    } else {
      const { featureNotSupportedText } = getStaticData();
      Toast.show(featureNotSupportedText);
    }
  }).catch(e=>console.log('error while opening url ', url));
}

export async function copyToClipboard(textString) {
  if (isNullOrEmpty(textString) || isEmpty(Clipboard)) {
    return false;
  }
  return (async () => Clipboard.setString(textString))()
    .then(() => true)
    .catch((err) => false);
}

export function getRatingData(pageName, response) {
  if (isNullOrEmpty(response) || isNullOrEmpty(response.ratingDetail)) {
    return { ratingData: null, npsData: null };
  }
  const ratingDetail = response.ratingDetail;
  const ratingData = {
    ...ratingDetail,
    lowRatingCta: [
      {
        title: ratingDetail.leftCTATexts[0],
        type: 'LEFT',
      },
      {
        title: ratingDetail.rightCTATexts[0],
        type: 'RIGHT',
      },
    ],
    fourRatingCTA: [
      {
        title: ratingDetail.leftCTATexts[3],
        type: 'LEFT',
      },
      {
        title: ratingDetail.rightCTATexts[3],
        type: 'RIGHT',
      },
    ],
    fiveRatingCTA: [
      {
        title: ratingDetail.leftCTATexts[4],
        type: 'LEFT',
      },
      {
        title: ratingDetail.rightCTATexts[4],
        type: 'RIGHT',
      },
    ],
    subheader: ratingDetail.subHeader,
    subHeading: {
      oneStar: ratingDetail.subHeadings[0],
      twoStar: ratingDetail.subHeadings[1],
      threeStar: ratingDetail.subHeadings[2],
      fourStar: ratingDetail.subHeadings[3],
      fiveStar: ratingDetail.subHeadings[4],
    },
  };

  const npsDetail = response.npsInfo;
  const npsData = {
    ...npsDetail,
    reactTag: getRootTag(),
    pageName: pageName,
    page: pageName,
  };
  return { ratingData, npsData };
}

export function fetchUrlParam(url) {
  const regex = /[?&]([^=#]+)=([^&#]*)/g;
  const params = {};
  let match;
  while ((match = regex.exec(url))) {
    params[match[1]] = match[2];
  }
  return params;
}

export function getCancellationRequestNo(cancellationDetailList) {
  const cancellationRequestNoList = [];
  if (isNotNullAndEmpty(cancellationDetailList)) {
    cancellationDetailList.forEach((cancellationDetail) => {
      if (
        cancellationDetail.requestType === CANCELLATION_REQUEST_TYPE ||
        cancellationDetail.requestType === CLAIM_REQUEST_TYPE
      ) {
        cancellationRequestNoList.push(cancellationDetail.requestNo);
      }
    });
  }
  return cancellationRequestNoList;
}

// Function to retrieve data from AsyncStorage
export const retrieveObjFromAsyncStorage = async (keyName) => {
  try {
    const value = await getDataFromStorage(keyName);
    if (value) {
      return JSON.parse(value);
    }
    return value;
  } catch (error) {
    return null;
  }
};

// Function to store data in AsyncStorage
export const storeObjInAsyncStorage = async (keyName, value) => {
  try {
    await setDataInStorage(keyName, JSON.stringify(value), (err) => {
      if (err) {
        return false;
      }
      return true;
    });
    return true;
  } catch (error) {
    return false;
  }
};

/**
 * Function to remove data from Async storage
 * @param keyName
 * @returns {Promise<boolean>}
 */
export const removeKeyFromAsyncStorage = async (keyName) => {
  try {
    await removeDataFromStorage(keyName);
    return true;
  } catch (error) {
    return false;
  }
};

/**
 * returns the key name for saving cancel request in async storage
 * which will be further used for recalling the
 * cancel preview api after instant refund registration
 * @param lobname
 * @param bookingId
 * @returns {string}
 */

export function getCancelRequestKeyName(lobname, bookingId) {
  return `${lobname}_${bookingId}`;
}

export function goBack() {
  if (Platform.OS === 'ios') {
    ViewControllerModule.popViewController(1);
  } else {
    BackHandler.exitApp();
  }
}

export function registerForInstantRefund(lobname) {
  if (Platform.OS === 'ios') {
    GenericModule.startUPIRegistration(lobname);
  } else {
    GenericModule.startUPIRegistration();
  }
}

export async function verifyOtp(bookingId, otp, otpData) {
  try {
    const hasNetwork = await isNetworkAvailable();
    if (!hasNetwork) {
      return null;
    }
    const userInfo = await HotelBookingModule.getRequestHeader(bookingId, 'VERIFY_OTP_SERVICE');
    const commonHeaders = await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo);
    const request = {
      token: otp,
      clientTransactionId: otpData,
      bookingId: bookingId,
    };
    const response = await fetch(VALIDATE_OTP_URL, {
      method: POST,
      body: JSON.stringify(request),
      headers: commonHeaders,
    });
    if (response.ok) {
      const body = await response.json();
      if (body.success && body.data.verified) {
        return body?.authorizationToken;
      }
    }
  } catch (error) {
    // do nothing
  }
  return null;
}

export async function sendOtp(bookingId) {
  let otpData = null;
  try {
    const hasNetwork = await isNetworkAvailable();
    if (!hasNetwork) {
      return otpData;
    }
    const userInfo = await HotelBookingModule.getRequestHeader(bookingId, 'SEND_OTP_SERVICE');
    const commonHeaders = await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo);
    const request = {
      channel: ['MOBILE'],
      message: 'to verify for Instant Refund',
    };
    const response = await fetch(SEND_OTP_URL, {
      method: POST,
      body: JSON.stringify(request),
      headers: commonHeaders,
    });
    if (response.ok) {
      const body = await response.json();
      if (body.success && isNonEmpty(body.data.clientTransactionId)) {
        otpData = body.data.clientTransactionId;
      }
    }
  } catch (error) {
    // do nothing
  }
  return otpData;
}

export function getInstantRefundGradient() {
  return ['#28beb2', '#43e1a8'];
}

export const getSMSDivider = () => {
  return Platform.OS === 'ios' ? '&' : '?';
};

export const getSmsUrl = (message) => {
  return `sms:${''}${getSMSDivider()}body=${message}`;
};

export const getWhatsappUrl = (message) => {
  return `whatsapp://send/?text=${message}`;
};

export const getMmtAuth = async () => {
  if (Platform.OS === 'web') {
    const { UserSessionModule } = NativeModules;

    return Promise.resolve(UserSessionModule.gdprGetCookie('mmt-auth',{ isPiiData: true}, UserSessionModule.cookieType && UserSessionModule.cookieType.ES));
  }
  const { mmtAuth } = await getUserDetails();
  return mmtAuth;
};

export function setCookie(name, value, daysToLive,isPiiData=true) {

  const { UserSessionModule} = NativeModules;
  // Encode value in order to escape semicolons, commas, and whitespace
  // let cookie =  gdprSetCookie(name, value)
  let options={isPiiData};
  if(typeof daysToLive === "number") {
      /* Sets the max-age attribute so that the cookie expires
      after the specified number of days */
      // cookie += "; max-age=" + (daysToLive*24*60*60);
      options.maxAge = (daysToLive*24*60*60)
  }
  const host = location.host;
  if (host.split('.').length === 1) {
       // no "." in a domain - it's localhost or something similar
       UserSessionModule.gdprSetCookie(name,value,options, UserSessionModule.cookieType && UserSessionModule.cookieType.ES)
  } else {
      const domainParts = host.split('.');
      domainParts.shift();
      let domain = '.' + domainParts.join('.');

        options.path = `/${domain}`;
        UserSessionModule.gdprSetCookie(name,value,options, UserSessionModule.cookieType && UserSessionModule.cookieType.ES)
       // check if cookie was successfuly set to the given domain
       // (otherwise it was a Top-Level Domain)
       let savedCookie = UserSessionModule.gdprGetCookie(name,options, UserSessionModule.cookieType && UserSessionModule.cookieType.ES);
      if (savedCookie == null || savedCookie != value) {
          // append "." to current domain
          domain = '.' + host;
          options.path = `/${domain}`
          UserSessionModule.gdprSetCookie(name,value,options, UserSessionModule.cookieType && UserSessionModule.cookieType.ES)
      }
  }
}

export function getCookie(cname) {

  const { UserSessionModule} = NativeModules;

  var decodedCookie = UserSessionModule.gdprGetCookie(cname,{isPiiData:true}, UserSessionModule.cookieType && UserSessionModule.cookieType.ES)

  return decodedCookie || "";
}

export const getAdTechCard = (cid) => {
  return <AdtechWrapper cid = {cid}/>
};

export const openDialer = (number) => {
  if (!isEmpty(number)) {
    if (Platform.OS === 'ios') {
      GenericModule.openDialer(number);
    } else {
      Linking.openURL(`tel:${number}`);
    }
  }
};

export const getParamsForBookingDetail = (bookingID, mmtAuth, loggingtrackInfo) => {
  const params = {};
  params.BOOKING_ID = bookingID;
  params.MMT_AUTH = mmtAuth;
  params.LOGGING_TRACKINFO = loggingtrackInfo;
  return params;
};

export const openLink = (link = '') => {
  Linking.canOpenURL(link).then(
    (supported) => {
      supported && Linking.openURL(link);
    },
    (err) => console.log(err, link),
  );
};

export const isEasyPayNativeFlowEnabled = () => {
  return getPokusConfig(PokusLobs.POST_SALES, AbConfigKeyMappings.easyPayNative, false);
};

export const isHelpingHandEnabled = () => {
  return getPokusConfig(PokusLobs.POST_SALES, AbConfigKeyMappings.helpingHandEnable, false);
};

export const getMmtSelectData = (loyaltyDetails) => {
  const {
    description,
    benefitDetailsList,
    backGroundGradient,
    mmtSelectLogo,
    tierName
  } = loyaltyDetails || {};
  let list = [];
  const tier = !!tierName ? tierName.toLowerCase() : '';
  if(!isEmpty(benefitDetailsList)){
    benefitDetailsList.forEach((item)=>{
      const { code = '', heading='', description = [], iconUrl : { APP } = {} } = item || {};
      const obj = {
        code,
        heading,
        description,
        iconUrl: APP,
      }
      if(!isEmpty(item.ctaData)){
        !isEmpty(item.ctaData.actionLink)?obj.actionLink = item.ctaData.actionLink.APP:"";
        !isEmpty(item.ctaData.contactNo)?obj.contactNo  = item.ctaData.contactNo:"";
      }
      list.push(obj);
    });
  }

    return {
      heading : description,
      list,
      backgroundGradient : backGroundGradient,
      mmtSelectLogo,
      tierName : tier
    }
}

export const getMMTSTrackingEventName = (tierName,bookingState,event,type) => {
  const state = !!bookingState && bookingState.toLowerCase();
  const middleText = {
    upcoming : 'earn',
    cancelled : 'lose',
    completed : 'earned',
  };
  const subText = `${state}_${tierName}`;
  const eventKeys = {
        display : `MMTS_${subText}_${middleText[state]}_coins_card_displayed`,
        click : `MMTS_${subText}_${type}_clicked`,
    };
    return eventKeys[event];
};

export const isGCCBooking = async () => {
  const header = await NetworkModule.getHeaders();
  return header.hasOwnProperty('region') && header.region === 'ae';
};

export const catchError = (error, errorInfo) => {
  try {
      Sentry.withScope(scope => {
        errorInfo && Object.keys(errorInfo).forEach(key => {
          scope.setExtra(key, errorInfo[key]);
        });
        Sentry.captureException(error);
      });
  } catch (err) {
    // console.error(err);
  }
}

export const getDeeplinkKeyValue = (data, key) => {
  if (Platform.OS === 'ios') {
      return data?.[key];
  } else if (Platform.OS === 'android') {
      if(!isEmpty(data?.pageData)) {
        try {
          const pageData = JSON.parse(data.pageData);
          return pageData[key];
        } catch(err) {
          return null;
        }
      }
  }
  return null;
}