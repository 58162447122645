export const FLIGHT_IMAGE_URL = 'https://imgak.mmtcdn.com/flights/assets/media/mobile/common/hdpi';
export const BASE_HOLIDAY_SERVICE_URL_NODE = 'https://holidayservice.makemytrip.com/';
export const BASE_HOLIDAY_SERVICE_URL = `${BASE_HOLIDAY_SERVICE_URL_NODE}HolidayServices/service`;
export const FETCH_SHORTLISTED_URL = 'https://shortlisting.makemytrip.com/rest/v1/getShortlists?lob=HLD';
export const FETCH_SIMILAR_PACKAGES_URL = '/package/similar/v2';
export const HOL_SHORTLIST_LOB = 'HLD';
export const ADD_SHORTLISTED_URL = 'https://shortlisting.makemytrip.com/rest/v1/updateShortlists';
export const REMOVE_SHORTLISTED_URL = 'https://shortlisting.makemytrip.com/rest/v1/removeShortlists';
export const HOLIDAY_PACKAGE_IMAGE_BASE_URL = 'https://imgak.mmtcdn.com/hp-images/new';
export const HOLIDAY_LISTING_META_URL = '/listing/packages/metadata';
export const HOLIDAY_LISTING_META_URL_NEW = '/listing/packages/metadata/v2';
export const HOLIDAY_USER_PACKAGES_URL = '/personalization/userPackages/fetch';
export const HOLIDAY_QUERY_URL = 'https://holidayz.makemytrip.com/holidays/submitProductQuery!submitAppQuery';
export const ABCONFIG_URL = 'https://ab.makemytrip.com/ab/services/v2/allocateVariants';
export const USER_DETAILS_URL = 'https://mapi.makemytrip.com/ext/web/pwa/user/details';
export const INIT_CHAT_URL = 'https://gia.makemytrip.com/api/init/';
export const REFERRAL_SERVICE_URL = "https://referral-service.makemytrip.com/referral-webservice/holidays/checkContactValidity"
export const OPEN_CHAT_URL = 'https://gia.makemytrip.com/chat/conversation/';
export const HOLIDAY_GENERIC_DURATION = 'DURATION';
export const HOLIDAY_GENERIC_NUMBER_OF_PACKAGES = 'NUMBER_OF_PACKAGES';
export const HOLIDAY_GENERIC_PRICE_PER_PERSON = 'PRICE_PER_PERSON';
export const FLIGHT_EARLY_DEPART_TIME = 9;
export const HH_FORMAT = 'HH';
export const DD_MM_YY_FORMAT = 'DD MMM YY';
export const ddd_DD_MMM_YY = "ddd DD MMM'YY";
export const HH_MM_FORMAT = 'HH:mm';
export const USER_DEFAULT_CITY = 'New Delhi';
export const USER_DESTINATION_DEFAULT_CITY = 'Goa';
export const infoIcon = 'https://hldak.mmtcdn.com/prod-s3-hld-hpcmsadmin/holidays/images/phonixImages/Alert-Orange.png';
export const RECENTLY_SEEN_ACTION_DETAILS = 'details';
export const USER_PACKAGE_TYPE = 'USER_PACKAGE';
export const LISTING_IMAGE_PREFIX = '_360x203';
export const SPACE = ' ';
export const ADULTS = 'Adults';
export const ADULT = 'Adult';
export const CHILDREN = 'Children';
export const CHILD = 'Child';
export const INFANTS = 'Infants';
export const READ = 'READ';
export const NEW = 'NEW';
export const CLOSED = 'CLOSED';
export const QUOTE_SHARED = 'QUOTE_SHARED';
export const AGENT_ASSIGNED = 'AGENT_ASSIGNED';
export const COMMA = ',';
export const INFANT = 'Infant';
export const LANDING_DEPARTURE_DATE_FORMAT = 'DD MMM';
export const DOT = '.';
export const NIGHT = 'N';
export const DAY = 'D';
export const MAX_DESTINATION_CITIES = 3;
export const MAX_DESTINATIONS_COUNT = 2;
export const IPHONE = 'iphone';
export const NO_COST_EMI = 'No Cost Emi';
export const REQUEST_WEBSITE = 'IN';
export const REQUEST_LOB = 'Holidays';
export const BASE_IMAGE_PATH = 'https://imgak.mmtcdn.com/hp-images/new';
export const PDT_PUSH_URL = 'https://pdt.makemytrip.com/dts/s/da/n';
export const PDT_LOB = 'HLD';
export const DEEPLINK_LAST_PAGE = 'landing';
export const DEEPLINK_LISTING_GROUPING_PARAM = '/search';
export const DEEPLINK_SME_PAGE = '/sme';
export const DEEPLINK_DETAIL_PAGE = '/package';
export const DEEPLINK_QUOTES_PAGE = '/quotes';
export const DEEPLINK_QUOTES_DETAIL_PAGE = '/quotes/detail'
export const DEEPLINK_SEND_QUERY = '/sendQuery30';
export const DEEPLINK_MMYT_DETAIL_PAGE = '/details';
export const DEEPLINK_DETAIL_PAGE_CUSTOMIZED = '/savePackage';
export const DEEPLINK_REVIEW_PAGE = '/reArchBookingReviewAndPaymentAction'
export const DEEPLINK_MAP_PAGE = 'map';
export const DEEPLINK_LISTING_PARAM = 'redirectionPage=listing';
export const DEEPLINK_WG_PARAM = 'pt=wg';
export const DEEPLINK_OPENOUTSIDE_PARAM = 'open=outside';
export const DEEPLINK_OPENWEB_PARAM = 'openweb=true';
export const WEEKEND_GETAWAY_PAGE_TYPE = 'wg';
export const WG_OMNI_SUFFIX = ' tgif';
export const PARAM_CMP = 'cmp';
export const PARAM_PAGE_TYPE = 'pt';
export const PARAM_AFFILIATE = 'aff';
export const PARAM_DEP_CITY = 'depCity';
export const PARAM_REFERRAL_LINK = 'referralCode';
export const MAX_STORY_COUNT = 5;
export const FILTER_WEEKEND_GETAWAYS = 'Weekend Getaways';
export const STORY_IMAGE_SIZE_ATTR = 'crop=0.56xh:h;*,*&resize=400:720';
export const GI_AFFILIATE_SUFFIX = ' GI';
export const TP_AFFILIATE_SUFFIX = ' TP';
export const GI_AFFILIATE_NAME = 'GoIbibo';
export const TP_AFFILIATE_NAME = 'Tripoto';
export const CARD_IMAGE_SIZE = 'resize=360:203';
export const CAMPAIGN = 'campaign';
export const FROM_LISTING_GROUPING_DEEPLINK = 'from_listing_grouping_deeplink';
export const JOURNEY_TYPE = 'OW';
export const DEFAULT_COUNTRY_CODE = 'IN';
export const DEFAULT_REGION = 'in';
export const DEFAULT_LOB_COUNTRY = 'India';
export const PARAM_BANNER = 'banner';
export const BULLET_POINT_CONTENT = '\u2B24';
export const HOLIDAYS_SOURCE = 'holidays_homepage';
export const FLIGHT_SORTERS = [
  {
    label: 'Price',
    type: 'price',
    filterTypes: [
      'Lowest first',
    ],
  },
  {
    label: 'Departure Time',
    type: 'departure_time',
    filterTypes: [
      'Earliest first',
      'Latest first',
    ],
  },
  {
    label: 'Arrival Time',
    type: 'arrival_time',
    filterTypes: [
      'Earliest first',
      'Latest first',
    ],
  },
  {
    label: 'Duration',
    type: 'duration',
    filterTypes: [
      'Earliest first',
      'Latest first',
    ],
  },
];

export const FLIGHT_FILTERS = [
  {
    label: 'Non-stop',
    type: 'non_stop',
  },
  {
    label: 'Early depart',
    type: 'early_depart',
  },
  {
    label: 'Same Airline',
    type: 'same_airline',
  },
];

export const FLIGHT_FILTERS_UPGRADE = [
  {
    label: 'Non-stop',
    type: 'non_stop',
  },
  {
    label: 'Early depart',
    type: 'early_depart',
  },
  {
    label: 'Upgrade Airline',
    type: 'upgrade_flight',
  },
];

export const INVERVENTION_RULE_SHOW_CTA = 'show_cta';
export const INVERVENTION_RULE_HIDE_CTA = 'hide_cta';
export const FAB_CALL_CTA = 'call';
export const FAB_QUERY_CTA = 'query';
export const FAB_CHAT_CTA = 'chat';

export const DETAILS_DEPARTUTE_DATE_FORMAT = 'YYYY-MM-DD';

export const pdtErrorSev = {
  LANDING: 0,
  LISTING: 1,
  DETAIL: 2,
  OTHER: 3,
};

export const DOM_BRANCH = 'DOM';
export const OBT_BRANCH = 'OBT';
export const SERVICELINE_PHONE_DELHI = '0124 4859621';
export const SERVICELINE_PHONE_NONDELHI = '01244859657';
export const MAX_HASHTAGS_TO_SHOW = 2;
export const MAX_HASHTAGS_TO_SHOW_LISTING = 3;

export const PAGE_NAME_QUERY = 'query';

export const PDT_RAW_EVENT = 'rawEvent';
export const PDT_PAGE_EXIT_EVENT = 'page_exit';
export const PDT_PAGE_ENTRY_EVENT = 'page_entry';
export const PDT_ERROR_API = 'API';
export const PDT_ERROR_TIMEOUT = 'timeout';
export const PDT_ERROR_DATA = 'data';
export const PDT_PAGE_VIEW = 'pageview';
export const PDT_PAGE_LOAD = 'page_load';
export const PDT_CLICK_EVENT = 'click';
export const PDT_NO_PKG_FOUND = 'no pkg found';
export const TNC_SEPERATOR = '~';
export const MONTH_ARR_CAPS = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
export const MONTH_ARR_CAMEL = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export const PACKAGE_TYPE_FIT = 'FIT';
export const PDT_CHANGE_CLICK = 'change';
export const PDT_CHOOSE_DESTINATION = 'choose_destination';
export const PDT_CHOOSE_DATE = 'choose_date';
export const PDT_SUBMIT_CLICK = 'submit';
export const PDT_DONE_CLICK = 'done';
export const PDT_BACK_CLICK = 'back';
export const PDT_OTP_SUBMIT_CLICK = 'otp_submit';
export const NO_DEPARTURE_CITY = 'NODEPT';
export const SEO_MOBILE_QUERY_ID = 'SEO_MOBILE';

export const EVENTS = {
  REVIEW: {
    PAYMENT_EVENT_REVIEW: 'payment_event_review',
  },
};

export const PAYMENT_STATUS = {
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILED',
  UNAVAILABLE: 'UNAVAILABLE',
};

export const THANKYOU_VIEW_STATE = {
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
};

export const CONTACT_US_EMAIL_ID = 'onlinedomquery@makemytrip.com';
export const CONTACT_US_PHONE = '0124-478-1881';
export const RAW_CHANNEL_CODE = 'raw_mobile';
export const RAW_PLATFORM_NAME = 'web';

export const ABCONFIG_WEB_OSNAME = 'MSITE';


export const WG_FROM_CITY_LIST = ['New Delhi',
  'Bangalore',
  'Mumbai',
  'Chennai',
  'Hyderabad',
  'Kolkata',
  'Indore',
  'Ahmedabad',
  'Pune'];

export const FUNNELS = {
  HOLIDAY: 'HLD',
  WEEKEND_GETAWAY: 'WG',
};

export const AFFILIATES = {
  MMT: 'MMT',
  GI: 'GI',
  TP: 'TP',
  INDIGO: 'IGO',
  INDIGO6E: 'IGO6E',
};

export const MAP_URL_SCHEME = 'http://maps.apple.com/?q=';

export const MAP_PARAMS = {
  BASE_URL: 'https://maps.googleapis.com/maps/api/staticmap',
  SIZE: '320x135',
  ZOOM: 12,
  SCALE: 2,
  MAP_TYPE: 'roadmap',
  MARKER_SIZE: 'mid',
  MARKER_COLOR: 'blue',
};

export const MAP_PARAMS_FULL_SCREEN = {
  BASE_URL: 'https://maps.googleapis.com/maps/api/staticmap',
  SIZE: '320x480',
  ZOOM: 12,
  SCALE: 2,
  MAP_TYPE: 'roadmap',
  MARKER_ICON: 'https://jsak.mmtcdn.com/holidays/images/app/branchLocatorPin.png',
};

export const HOLIDAY_CORRELATION_KEY = 'holiday_correlation_key';
export const HOLIDAY_CORRELATION_GROUPING_KEY = 'holiday_correlation_grouping_key';
export const HOLIDAY_CORRELATION_LISTING_KEY = 'holiday_correlation_listing_key';
export const GOOD_RATING = 4.25;
export const AVERAGE_RATING = 3.75;
export const BELOW_AVERAGE_RATING = 3;
export const SORTING_FILTER = 'SORTING_FILTER';
export const PRICE_FILTER = 'PRICE_FILTER';
export const AMENITY_FILTER = 'AMENITY_FILTER';
export const HOTELS_AND_MORE_FILTER = 'HOTELS_AND_MORE_FILTER';
export const POPULAR_FILTER = 'POPULAR_FILTER';
export const USER_RATING_FILTER = 'USER_RATING_FILTER';
export const LOCATION_FILTER = 'LOCATION_FILTER';
export const STAR_RATING_FILTER = 'STAR_RATING_FILTER';
export const PACKAGE_STORY_DESCRIPTION = 'It\'s a specially curated Package!';
export const HLD_PAGE_NAME = {
  LANDING: 'LANDING',
  LISTING: 'LISTING',
  GROUPING: 'GROUPING',
  MAP: 'MAP',
  DETAILS: 'DETAILS',
  REVIEW:'REVIEW',
};
export const WEB_CHECKIN_REQUEST_RAISED = 'Web Check In Request Raised';
export const HLD_CUES_POKUS_KEYS = {
  LANDING: 'landing',
  LISTING: 'listing',
  GROUPING: 'grouping',
  GROUPING_V2: 'groupingV2',
  MAP: 'map',
  DETAIL: 'detailV2',
  FPH: 'fph',
  PSM: 'psm',
  PSM_MODIFY: 'psmModify',
};
export const FAB_PULSE_ANIMATION_DEFAULT_CONFIG = {
  interval: 10 * 1000, // 10 secs
  timeout: 60 * 60 * 1000, // 1 hour
};
export const GUIDED = 'GUIDED';
export const USER_AGREEMENT_URL = 'https://www.makemytrip.com/legal/user_agreement.html';
export const PRIVACY_POLICY_URL = 'https://www.makemytrip.com/legal/privacy_policy.html';
export const MONTH_INTEGER_MAP = {'JAN':'01','FEB':'02','MAR':'03','APR':'04','MAY':'05','JUN':'06','JUL':'07','AUG':'08','SEP':'09','OCT':'10','NOV':'11','DEC':'12'};
export const ERROR_TEXT = 'This field is required';
export const STEP_COUNT = 1;
export const AD = 'AD';
export const VERTICAL_NUDGE_DEFAULT_CONFIG = {
  enabled: false,
  hideAfterSeconds: 5, // After these seconds nudge will disappear
  showAfterSeconds: 2, // After these seconds nudge will appear
  showAfterDays: 3, // After 3 days nudge will appear again
};
export const COLOR_CODES = {
  lightGrey: '#928DAB',
  darkBlack: '#1F1C2C',
};
export const DATES_FORMAT = {
  ABR_DAY_NAME:'ddd',
  FULL_DAY_NAME: 'dddd',
  ABR_MONTH_NAME:'MMM',
  FULL_MONTH_NAME:'MMMM',
  FULL_YEAR:'YYYY',
  DATE_OF_MONTH:'DD',
  MONTH_NUMBER:'MM',
  DEFAULT_FORMAT:'YYYY-MM-DD',
};
export const TYPE_OF_URLS = {
  USER_AGREEMENT_URL: {
    title: 'User Agreement',
    url: 'https://www.makemytrip.com/legal/user_agreement.html',
  },
  PRIVACY_POLICY_URL: {
    title: 'Privacy Policy',
    url: 'https://www.makemytrip.com/legal/privacy_policy.html',
  },
  TERMS_OF_SERIVCE_URL: {
    title: 'Terms of Service',
    url: 'https://www.makemytrip.com/legal/user_agreement.html#tos-obt',
  },
};

export const variantEnum = {
  HOTELS:'hotels',
  OTHERS:'others',
  NULL:'NULL',
};
export const IMAGE_STORY_TYPE = { type: 'image', label: 'Photos' };
export const VIDEO_STORY_TYPE = { type: 'video', label: 'Videos' };
export const VIEW_ALL_STORY_TYPE = { type: 'viewAll', label: 'All Media' };
export const KeyboardEvents = {
  SHOW :'keyboardDidShow',
  HIDE: 'keyboardDidHide',
};

export const FLIGHT_MESSAGE_TYPES = {
  OVERNIGHT_MESSAGE: 'overnightMessage',
  TERMINAL_MESSAGE: 'terminalMessage',
  BAGGAGE_MESSAGE: 'baggageMessage',
  UPGRADE_MESSAGE: 'upgradeMessage',
};

export const CANCELLATION_MESSAGES = {
  ONLY_NON_REFUNDABLE_TEXT:
    'Cancellation is not allowed for this package. You will get no refund after booking.',
  ONLY_NO_DATE_CHANGE_TEXT: 'Date cannot be changed for this package after booking',
  NON_REFUNDABLE_TEXT: `Non-Refundable. 
Cancellation will not be allowed.`,
  NO_DATE_CHANGE_TEXT: 'Date change will not be allowed.',
};

export const PDTConstants = {
  FAB_STRIP: 'fab',
  CONTACT_ICON: 'contact_icon',
  CALL_SUFFIX: '_call',
  QUERY_SUFFIX: '_query',
  CHAT_SUFFIX: '_chat',
  BRANCH_LOCATOR_SUFFIX: '_branchLocator',
  PAGE_VIEW: 'pageview',
  PAGE_LOAD: 'page_load',
};

export const ERROR_CODES = {
  LISTING002 : 'LISTING002',
};

export const HTML_CODES = {
  BULLET : '\u2022',
};


export const SEARCH_FILTER_SECTIONS = {
  TRAVELLING_TO: 'TRAVELLING TO',
  STARTING_FROM: 'STARTING FROM',
  GOING_TO: 'GOING TO',
  PACKAGES_FOR: 'PACKAGES FOR',
  ROOM_GUESTS: 'ROOM & GUESTS',
  STARTING_DATE: 'STARTING DATE',
  TRAVELLING_DATE: 'TRAVELLING DATE',
};


export const TRIP_IDEAS_GALLERY_KEY  = 'gallery';

export const RESIZE_MODE_IMAGE = {
  CONTAIN: 'contain',
  COVER: 'cover',
  STRETCH: 'stretch',
};

export const HOL_REQUEST_TYPE = {
  DROP_OFF: 'DROP_OFF',
}

export const LOGIN_EVENTS =  {
  REFER_AND_EARN: 'login_event_hol_refer_and_earn'
}
