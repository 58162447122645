import React, {Fragment} from 'react';
import {View, Text, ScrollView, Platform, BackHandler} from 'react-native';
import Header from '../../../Common/HeaderCross';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import CabBreadCumb from './CabBreadCumb';
import EditBookingDetailsForm from './EditBookingDetailsFormCard';
import Actions from '../../../navigation/postSalesNavigation';
import _ from 'lodash';
import {getFormattedDateinIST} from "./CancelAndRebookUtill";
import RecBlueBtn from '@mmt/legacy-commons/Common/Components/Buttons/RecBlueBtn';
import {CancelAndRebookConstants} from './CancelAndRebookConstants';
import BottomOverlay from './BottomOverlay';
import CabBookingTrackingHelper from '../../CabBookingTrackingHelper';
import { CLICK_EVENT, PAGE_NAME } from '../../CabBookingDetailsConstant';
import ButtonWrapper from 'apps/post-sales/src/Common/ButtonWrapper';

class CabModifyBooking extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showOverlay : false,
            changeForRoundTripOverlay : false,
            tripStatusChanged : props.tripStatusChanged || false,
            dateChangeOverlay : false,
            validateLocation : false,
            isPickUp : false,
            subtitle : `${this.props.response.carBookingInfo.carDetail.carType} | ${this.props.response.carAdditionalInfo.departureDetail.departureDayInMonth} ${this.props.response.carAdditionalInfo.departureDetail.departureMonth} | ${this.props.response.carAdditionalInfo.departureDetail.pickupLocation}`,
            formData : {
                from :{
                    name : this.props.response.carAdditionalInfo.departureDetail.address,
                    locationId: this.props.response.carAdditionalInfo.additionalLOBInfo && this.props.response.carAdditionalInfo.additionalLOBInfo.locationInfo.sourcePlaceId
                },
                to : {
                    name: this.props.response.carAdditionalInfo.arrivalDetail.address,
                    locationId: this.props.response.carAdditionalInfo.additionalLOBInfo && this.props.response.carAdditionalInfo.additionalLOBInfo.locationInfo.destinationPlaceId
                },
                fromDate:{
                    date : this.props.response.carAdditionalInfo.departureDetail.departureTime,
                    month: this.props.response.carAdditionalInfo.departureDetail.departureMonth,
                    day:this.props.response.carAdditionalInfo.departureDetail.departureDayInMonth,
                    week:this.props.response.carAdditionalInfo.departureDetail.departureDay,
                    time :getFormattedDateinIST(this.props.response.carAdditionalInfo.departureDetail.departureDate, 'hh:mm A'),
                    year: this.props.response.carAdditionalInfo.departureDetail.departureYear,
                },
                toDate : {
                    date : this.props.response.carBookingInfo.carDetail.tripType === 'Outstation_Return' ?
                            this.props.response.carAdditionalInfo.arrivalDetail.arrivalTime : '',
                    month: this.props.response.carAdditionalInfo.arrivalDetail.arrivalMonth,
                    day:this.props.response.carAdditionalInfo.arrivalDetail.arrivalDayInMonth,
                    week:this.props.response.carAdditionalInfo.arrivalDetail.arrivalDay,
                    time :getFormattedDateinIST(this.props.response.carAdditionalInfo.arrivalDetail.arrivalDate, 'hh:mm A'),
                    year: this.props.response.carAdditionalInfo.arrivalDetail.arrivalYear,
                }
            }
        }
        const { carType } = this.props.response.carBookingInfo.carDetail;
        const pickupTime = `${this.props.response.carAdditionalInfo.departureDetail.departureDayInMonth} ${this.props.response.carAdditionalInfo.departureDetail.departureMonth}`;
        const pickupLocation = this.props.response.carAdditionalInfo.departureDetail.pickupLocation;
        const subtitle = carType + " | " + pickupTime + " | " + pickupLocation;
        if(props.keyValue && props.keyValue === 'date'){
            this.state.formData = props.formData;
        }
    }

    componentWillReceiveProps(props) {
        this.setState({ formData: props.formData });
      }

    componentDidMount() {
        CabBookingTrackingHelper.trackLoadEvent(PAGE_NAME.MODIFY_BOOKING, this.props.response);
    }

    openLocationOverlay = () => {
        Actions.searchLocation({
            bookingDetailResponse:this.props.response,
            formData:this.state.formData,
            subtitle: this.state.subtitle,
            response : this.props.response,
            onSelect: this.setNewlySelectedLocation
        })
    }

    setNewlySelectedLocation = (fromLocation, toLocation) => {
        if(fromLocation != null || !_.isEmpty(fromLocation)){
            this.setState(prevFormData => ({
                formData :{
                    ...prevFormData.formData,
                    from :{
                        ...prevFormData.formData.from,
                        name:fromLocation.description,
                        locationId: fromLocation.Place_id

                    }
                }
            }))
        }

        if(toLocation != null || !_.isEmpty(toLocation)){
            this.setState(prevFormData => ({
                formData :{
                    ...prevFormData.formData,
                    to :{
                        ...prevFormData.formData.to,
                        name:toLocation.description,
                        locationId: toLocation.Place_id
                    }
                }
            }))
        }
        Actions.pop();
    }

    openPickupDateOverlay = () => {
           Actions.changeDate({
              formData : this.state.formData,
              isPickUp : true,
              methodToCall : "getTheUpdatedDateCheck",
              subtitle :  this.state.subtitle,
              response : this.props.response,
              changeDateCallback : this.getTheUpdatedDateCheck,
              isRoundTrip : (this.props.response.carBookingInfo.carDetail.tripType === 'Outstation_Return'),
              showReturnDate : true   });
    }


    getTheUpdatedDateCheck = (updateDateData) => {
        this.setState({formData:updateDateData})
         Actions.pop();
        let data = updateDateData;
        if(_.isEmpty((data["toDate"]["date"]).toString())){
            data["toDate"]["date"] = this.props.response.carAdditionalInfo.arrivalDetail.arrivalDate;
        }
        Actions.changeDate({
            formData : data,
            isPickUp : false,
            methodToCall : "closeReturnDateOverlay",
            subtitle : this.state.subtitle,
            response : this.props.response,
            showReturnDate : true,
            isRoundTrip : (this.props.response.carBookingInfo.carDetail.tripType === 'Outstation_Return')
        });
    }


    changeTripToRoundTrip = () => {
        this.setState({ changeForRoundTripOverlay : true})
    }
    openDateChangeOverlay = (isPickUp) => {
        if(this.props.response.carBookingInfo.carDetail.tripType === 'Outstation_Return'){
            this.openPickupDateOverlay();
        }else{
            if(isPickUp){
                Actions.changeDate({
                    formData : this.state.formData,
                    isPickUp : true,
                    methodToCall : "getTheUpdatedDate",
                    subtitle :  this.state.subtitle,
                    response : this.props.response,
                    isRoundTrip : (this.props.response.carBookingInfo.carDetail.tripType === 'Outstation_Return'),
                    showReturnDate : ((this.props.response.carBookingInfo.carDetail.tripType === 'Outstation_Return') || this.state.tripStatusChanged),
                });
            }else{
                let data = this.state.formData
                if(_.isEmpty((data["toDate"]["date"]).toString())){
                    data["toDate"]["date"] = this.props.response.carAdditionalInfo.arrivalDetail.arrivalDate;
                }
                Actions.changeDate({
                    formData : data,
                    isPickUp : false,
                    methodToCall : "getTheUpdatedDate",
                    subtitle : this.state.subtitle,
                    response : this.props.response,
                    tripStatusChanged: this.state.tripStatusChanged || false,
                    isRoundTrip : (this.props.response.carBookingInfo.carDetail.tripType === 'Outstation_Return'),
                    showReturnDate : ((this.props.response.carBookingInfo.carDetail.tripType === 'Outstation_Return') || this.state.tripStatusChanged)
                });
            }
        }
    }

    cabUpgrade(formData){
        const now = Date.now();
        if(formData.from.locationId === formData.to.locationId){
            this.setState({validateLocation : true})
        }
        else if((formData.fromDate.date) && (formData.toDate.date)){
            if(now > formData.fromDate.date || formData.fromDate.date > formData.toDate.date || formData.fromDate.date === formData.toDate.date){
                this.setState({showOverlay:true})
            }else{
                Actions.cabUpgrade({modifiedData : formData, bookingDetailResponse : this.props.response, subtitle : this.state.subtitle});
            }
        }else if (_.isEmpty(formData.toDate.date)){
            if(now > formData.fromDate.date){
                this.setState({showOverlay:true})
            }else {
                Actions.cabUpgrade({modifiedData : formData, bookingDetailResponse : this.props.response, subtitle : this.state.subtitle});
            }
        }else{
            Actions.cabUpgrade({modifiedData : formData, bookingDetailResponse : this.props.response, subtitle : this.state.subtitle});
        }
    }

    closeOverlay = () => {
        this.setState({showOverlay : false})
    }
    closeLocationValidation = () => {
        this.setState({validateLocation : false})
    }

    oncloseChangeForRoundTripOverlay = () => {
        CabBookingTrackingHelper.trackClickEvent(PAGE_NAME.MODIFY_BOOKING, CLICK_EVENT.CLOSE_ROUND_TRIP_CONFIRM_CLICK);
        this.setState({changeForRoundTripOverlay : false})
    }

    closeChangeForRoundTripOverlay = () => {
        CabBookingTrackingHelper.trackClickEvent(PAGE_NAME.MODIFY_BOOKING, CLICK_EVENT.ROUND_TRIP_CONFIRM_CLICK);
        this.setState({changeForRoundTripOverlay : false})
        this.setState({tripStatusChanged : true})
        this.openDateChangeOverlay();
    }

    onBackPress = () => {
        CabBookingTrackingHelper.trackClickEvent(PAGE_NAME.MODIFY_BOOKING, CLICK_EVENT.CLOSE_CLICK);
        Actions.pop();
    };

    swapTheLocation = () => {
        let formDataCopy = this.state.formData;
        this.setState(prevFormData => ({
            formData :{
                ...prevFormData.formData,
                from :{
                    name:formDataCopy.to.name,
                    locationId: formDataCopy.to.locationId
                },
                to :{
                    name:formDataCopy.from.name,
                    locationId: formDataCopy.from.locationId
                }
            }
        }))
    }

  render() {
      return (
      <View style={[AtomicCss.flex1, AtomicCss.greyBg]}>
        <Header
          title={CancelAndRebookConstants.MODIFY_BOOKING_HEADING}
          headerShadow
          headingTxt="font18"
          headingSubTxt="font12"
          handleClose={this.onBackPress}
        />
        <CabBreadCumb state={CancelAndRebookConstants.EDIT_TEXT}/>
        <ScrollView>
          <EditBookingDetailsForm openpopUp = {this.openLocationOverlay}
                                  openPopUpForDateChange={this.openDateChangeOverlay}
                                  bookingDetailResponse={this.props.response}
                                  formData = {this.state.formData}
                                  changeTripToRoundTrip={this.changeTripToRoundTrip}
                                  tripStatusChanged = {this.state.tripStatusChanged}
                                  onSwap={this.swapTheLocation}
          />
        </ScrollView>
            <View style={{padding:15}}>
                <ButtonWrapper
                    clickHandler = {() => {
                        CabBookingTrackingHelper.trackClickEvent(PAGE_NAME.MODIFY_BOOKING_EDIT, CLICK_EVENT.CHECK_AVAILABILITY_CLICK);
                        this.cabUpgrade(this.state.formData)
                        }}
                    btnText = {CancelAndRebookConstants.CHECK_AVAILABILITY}
                    btnTxtStyle = {[AtomicCss.font16]}
                />
            </View>
          {
              this.state.validateLocation && <BottomOverlay onClickOfOk={this.closeLocationValidation} text={CancelAndRebookConstants.INVALID_Location} closeOverlay={this.closeLocationValidation}/>
          }
          {
            this.state.showOverlay && <BottomOverlay onClickOfOk={this.closeOverlay} text={CancelAndRebookConstants.INVALID_DATES} closeOverlay={this.closeOverlay}/>
          }
          {
              this.state.changeForRoundTripOverlay && <BottomOverlay onClickOfOk={this.closeChangeForRoundTripOverlay} text={CancelAndRebookConstants.ALERT_MSG} closeOverlay={this.oncloseChangeForRoundTripOverlay}/>
          }
      </View>
    );
  }
}

export default CabModifyBooking;
