import React from 'react';
import {View, Text, Image, TouchableOpacity} from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import styles from './VisaDetailCardCss';
import {fillCheckInCheckOutData} from '../../../hotel/details/utils/HotelBookingDetailUtil';
import {ErrorVisaIcon, RightArrowIcon} from '../../HolidayBookingConstants';
import  isEmpty  from 'lodash/isEmpty';


const VisaDetailCard = ({visaDetail, travellerLabel, buttonText, index, viewDetails, openTravelProfile}) => {
  if(isEmpty(visaDetail)){
    return null;
  }
  return (<View style={[AtomicCss.marginBottom10, styles.cardBorder]}>
    <View style={[styles.infoCardWrapper]}>
      {(!isEmpty(visaDetail.visaCountryName) ||  !isEmpty(visaDetail.visaDescription)) && <View style={styles.headingSection}>
        <Text style={[AtomicCss.defaultText, AtomicCss.lineHeight20, AtomicCss.blackFont, AtomicCss.font16]}>
          {visaDetail.visaCountryName} - {visaDetail.visaDescription}
        </Text>
      </View>}
      {!isEmpty(visaDetail.tagText) && <View style={styles.infoSection}>
        <Image style={styles.errorIconStyle} source={ErrorVisaIcon} />
        <Text style={styles.infoSectionTxt}>
          {visaDetail.tagText}
        </Text>
      </View>}
      <View style={styles.innerDtlsWrapper}>
        {!isEmpty(visaDetail.dateOfEntry) &&  !isEmpty(visaDetail.dateOfExit) && <View style={[styles.applicantRowSection]}>
          <View style={styles.rowLeft}>
            <Text style={styles.rowLeftText}>YOUR TRAVEL</Text>
          </View>
          <View>
            <Text style={styles.title}>{fillCheckInCheckOutData(visaDetail.dateOfEntry,'ddd, DD MMM YY')} -{fillCheckInCheckOutData(visaDetail.dateOfExit, 'ddd, DD MMM YY')} </Text>
            <Text style={[styles.moreInfo]}>({visaDetail.durationOfStay})</Text>
          </View>
        </View>}
        {!isEmpty(travellerLabel) && <View style={[styles.applicantRowSection, styles.cardBorder]}>
          <View style={styles.rowLeft}>
            <Text style={styles.rowLeftText}>APPLICANTS</Text>
          </View>
          <View>
            <Text style={styles.title}>{travellerLabel}</Text>
          </View>
        </View>}
        {!isEmpty(visaDetail.validity)  &&  <View style={[styles.applicantRowSection]}>
          <View style={styles.rowLeft}>
            <Text style={styles.rowLeftText}>VALIDITY</Text>
          </View>
          <View>
            <Text style={styles.title}>{visaDetail.validity} from date of issue</Text>
            {!isEmpty(visaDetail.receivingDate) && <Text style={[styles.moreInfo]}>(expected on {fillCheckInCheckOutData(visaDetail.receivingDate ,'DD MMM ')})</Text>}
          </View>
        </View>}
      </View>

    </View>
    {!isEmpty(visaDetail.buttons) && 
    <TouchableOpacity style={styles.redirectBtnWrapper} 
        activeOpacity={0.8} onPress={buttonText === 'View Details' ? viewDetails.bind(null, visaDetail.externalrefNo) : openTravelProfile}>
      <Text style={styles.linkBtnTxt}>{buttonText}</Text>
      <Image style={styles.rightArrowIcon} source={RightArrowIcon} />
    </TouchableOpacity>}
  </View>
  )
};


export default VisaDetailCard;
