import React from 'react';
import {View, Text, StyleSheet, Image, TouchableOpacity} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';
import {logoMYSafety} from '../../HolidayBookingConstants';
import { themeColors } from '../../../PostSalesStyles';

const CheckListSafeTravel = ({checkList, onViewAllClick, checklistActionHandler}) => {
  const viewAllHandler = () => {
    onViewAllClick(true);
  };

  return (
    <View style={styles.WebCheckin}>
      <LinearGradient
        start={{
          x: 1.0,
          y: 0.0
        }}
        end={{
          x: 0.0,
          y: 1.0
        }}
        colors={['#e8fde9', '#ffffff']}
        style={[styles.headingDtls]}
      >
        <Text
          style={[AtomicCss.font16, AtomicCss.blackText, AtomicCss.blackFont, AtomicCss.marginBottom5]}>{checkList.header}</Text>
        {checkList.showIcon && <Image style={styles.logoMYSafety} source={logoMYSafety}/>}
      </LinearGradient>
      <View style={styles.checkListMain}>
        {checkList.itemlist && checkList.itemlist.map((item, index) => {
            if (index < checkList.displayLimit) {
              return (
                <View style={[styles.checkListWrapper, AtomicCss.marginBottom16]}>
                  <View style={styles.listLftDetls}>
                    <View style={styles.countWrapper}>
                      <Text style={styles.countingTxt}>{index + 1}</Text>
                    </View>
                    <View style={styles.dtls}>
                      <View style={styles.textDetails}>
                        <Text
                          style={[AtomicCss.boldFont, AtomicCss.blackText, AtomicCss.font12, AtomicCss.marginBottom5]}>{item.title}
                          </Text>
                          {item.action.type==="WEB_CHECKIN" && 
                            <View>
                              <Text style={styles.mandatoryTag}>Mandatory</Text>
                            </View>
                          }
                      </View>
                      {item.desc && item.desc.map(text =>
                        <Text
                          style={[AtomicCss.regularFont, AtomicCss.defaultText, AtomicCss.font12]}>{text}</Text>
                      )}
                    </View>
                  </View>
                  {item.action && item.action.title &&
                  <TouchableOpacity onPress={() => checklistActionHandler(item.action)}>
                    <Text
                      style={[{color:themeColors.linkTextColor}, AtomicCss.font14, AtomicCss.boldFont]}>{item.action.title}</Text>
                  </TouchableOpacity>
                  }
                </View>
              );
            }
            return null;
          }
        )}
        {checkList.itemlist && checkList.itemlist.length > 1 &&
        <TouchableOpacity style={[styles.ViewAllTxt]} onPress={viewAllHandler}><Text
          style={[{color:themeColors.linkTextColor}, AtomicCss.font14, AtomicCss.boldFont]}>VIEW
          ALL</Text></TouchableOpacity>}
      </View>

    </View>
  );
};
const styles = StyleSheet.create({
  WebCheckin: {
    ...getPlatformElevation(1),
    marginBottom: 8,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    backgroundColor: '#fff'
  },
  logoMYSafety: {
    width: 20,
    height: 20,
    marginRight: 12
  },
  checkListMain: {
    padding: 16
  },
  checkListWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  listLftDetls: {
    flex: 1,
    marginRight: 10,
    borderLeftWidth: 1,
    borderLeftColor: '#ddd',
    paddingLeft: 20,
    marginLeft: 10,
    paddingBottom: 10
  },
  countWrapper: {
    width: 20,
    height: 20,
    borderWidth: 1,
    borderColor: '#e7e7e7',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
    position: 'absolute',
    left: -10
  },
  countingTxt: {
    fontSize: 12,
    fontFamily: fonts.bold,
    color: '#4a4a4a'
  },
  btnWrapper: {
    padding: 15,
    borderTopWidth: 1,
    borderTopColor: '#e3e3e3',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row'
  },
  linkText: {
    color: '#008cff',
    fontSize: 12,
    fontFamily: fonts.bold
  },
  arrowIconStyle: {
    width: 16,
    height: 16,
    marginLeft: 5
  },
  activeArrowIconStyle: {
    width: 16,
    height: 16,
    transform: [
      {rotate: '180deg'}
    ],
    marginLeft: 5
  },
  headingDtls: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 16
  },
  ViewAllTxt: {
    marginLeft: 30
  },
  textDetails:{
    flexDirection:'row',
  },
  mandatoryTag: {
    fontSize: 12,
    fontWeight: '700',
    color: '#cf8100',
    textAlign: 'center',
    paddingTop: 3,
    paddingLeft: 9,
    paddingRight: 9,
    paddingBottom: 3,
    borderRadius: 100,
    backgroundColor: '#ffedd1',
    marginLeft: 8,
    marginTop:-5
  }

});

export default CheckListSafeTravel;
