import {fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from 'apps/post-sales/src/Holiday/Components/getPlatformElevation';
import { StyleSheet } from 'react-native';


const styles = StyleSheet.create({
    infoCardWrapper:{
        backgroundColor:'#fff',
        paddingVertical:20,
        paddingHorizontal:16,
        justifyContent:'space-between',
        marginTop:10,
        borderRadius: 8,
        ...getPlatformElevation(2),
    },
    infoCardHading:{
        flexDirection:'row',
    },
    infoCardDisc:{
        color:'#4a4a4a',
        lineHeight:18,
        paddingLeft:30,
    },
    infoIcon:{
        width:20,
        height:20,
        marginRight:10,
    },
    defaultText: {color: '#4a4a4a'},
    marginBottom12: {marginBottom: 12},
    boldFont: {fontFamily: fonts.bold},
    marginBottom15: {marginBottom: 15},
    flex1: { flex: 1 },
});

export default styles;
