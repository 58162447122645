import React from 'react';
import {Text, TouchableOpacity, StyleSheet, Image} from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {BlueArrow} from '../HolidayBookingConstants';
import { themeColors } from '../../PostSalesStyles';

const RedirectBtn = (props) => {
  return (
    <TouchableOpacity style={styles.redirectBtnWrapper} activeOpacity={0.8} onPress={props.onPress}>
      <Text style={[styles.linkBtnTxt, AtomicCss.boldFont]}>
        {props.btnName}
      </Text>
      <Image style={styles.rightArrowIconTransform} source={BlueArrow}/>
    </TouchableOpacity>
  );
};
const styles = StyleSheet.create({
  redirectBtnWrapper: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 55,
    paddingRight: 35,
    justifyContent: 'space-between',
    flexDirection: 'row',
    backgroundColor: '#fff',
    alignItems: 'center',
    marginLeft: -16,
    borderTopWidth: 1,
    borderTopColor: '#d5d5d5'
  },

  rightArrowIconTransform: {
    width: 22,
    height: 15,
    transform: [{rotate: '-90deg'}, {scaleX: 0.6}, {scaleY: 0.6}],
    tintColor: themeColors.rightArrowTintColor
  },
  linkBtnTxt: {
    fontSize: 14,
    color: themeColors.linkTextColor
  }
});

export default RedirectBtn;
