import React from 'react';
import PropTypes from 'prop-types';
import { Image, Text, TouchableOpacity, View } from 'react-native';
import styles from './SelectPassengersCss';
import { CANCELLED_PAX_ICON } from '../../FlightImageConstants';
import { getStaticData } from '../../../staticData/staticData';
import { getFont } from '../../../PostSalesStyles';

export default class CheckboxRow extends React.Component {
  checkboxHandler() {
    const paxSelected = !this.props.selected;
    this.props.clickHandler(this.props.passengerData, paxSelected);
  }

  render() {
    const { fsStyle, ...fonts } = getFont(true);
    const { isParentDisabled, isPaxDisabled, selected } = this.props;
    const selectedStyle = selected ? 'Selected' : '';
    const isDisabled = isParentDisabled || isPaxDisabled;
    const disableStyle = isDisabled ? [styles.paxDisabled] : '';
    return (
      <View style={[styles.sectorBorderStyle]}>
        <TouchableOpacity
          activeOpacity={0.9}
          style={[styles.checkBtn, styles.sectorPaxList, disableStyle]}
          onPress={() => this.checkboxHandler()}
          disabled={isDisabled}
        >
          <View
            style={[
              styles.checkbox,
              styles[`checkbox${selectedStyle}`],
              isDisabled ? styles.disableChkBox : '',
            ]}
          >
            {!isDisabled && selected && (
              <Image style={styles.checkedIcon} source={CANCELLED_PAX_ICON} />
            )}
          </View>
          <View style={{ width: '100%' }}>
            <Text style={[styles.passengerName, fonts.boldFontFamily, fsStyle.font14]}>
              {this.getPaxName()}
            </Text>
            {this.getPaxSubTitle()}
          </View>
        </TouchableOpacity>
      </View>
    );
  }

  getPaxSubTitle = () => {
    const { fsStyle, ...fonts } = getFont(true);
    let {
      flightAdditionalText: { childrenAloneText },
    } = getStaticData();
    if (this.props.showStaticContentInEnglish === true) {
      childrenAloneText = 'Children/Infant cannot travel alone, an adult must accompany them.';
    }
    if (this.props.passengerData.paxType != '0') {
      return (
        <Text style={[styles.childInfoAlert, fonts.regularFontFamily, fsStyle.font12]}>
          {childrenAloneText}
        </Text>
      );
    }
  };

  getPaxName = () => {
    const { passengerName } = this.props.passengerData;
    return `${passengerName.firstName} ${passengerName.lastName}`;
  };
}

CheckboxRow.propTypes = {
  passengerData: PropTypes.object.isRequired,
  clickHandler: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
};
