import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { View, Text, Image, TouchableOpacity, Platform, DeviceEventEmitter } from 'react-native';
import styles from './WebCheckInCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import MySafetyBanner from '../../../AncillaryModule/component/MySafetyBanner';
import {
  getSegmentApplicableAncillary,
  connectingFlightInfo,
  getDateAndAirlineName,
} from '../../../AncillaryModule/utils/AncillaryUtils';
import { getStaticData } from 'apps/post-sales/src/staticData/staticData';
import { useTheme } from '../../../../../theme/theme.context';
import Actions from '../../../../../navigation/postSalesNavigation';
import { getAirlineImageUrl } from '../../../../../flights-funnel/Listing/utils/FlightListingUtil';
import FlightBookingTrackingHelper from '../../helper/FlightBookingTrackingHelper';

import { BLACK_RIGHT_ARROW } from '../../../../FlightImageConstants';
import { getFont } from '../../../../../PostSalesStyles';
import { EventEmitterConst } from '../../FlightBookingDetailsConstant';

const WebCheckInOverlay = ({
  flightDetailResponse,
  handleBottomOverlay,
  overlayDesc,
  overlayActionList,
  holidayBooking,
  holidayData,
}) => {
  const staticTexts = getStaticData(true);
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  FlightBookingTrackingHelper.trackLoadEvent('Details_WC_Overlay', flightDetailResponse);

  const onWebcheckinClick = () => {
    DeviceEventEmitter.emit(EventEmitterConst.CTA_CLICKED_EVENT);
    handleBottomOverlay(false);
    Actions.openAncillary({
      pageKey: 'webcheckin',
      bookingId: flightDetailResponse.bookingID,
      flightDetailResponse: flightDetailResponse,
      holidayBooking: holidayBooking,
      holidayData: holidayData,
    });
  };

  const actionHandler = (type) => {
    FlightBookingTrackingHelper.trackClickEvent(
      'Details_WC_Overlay',
      `WC_Overlay_${type}`,
      flightDetailResponse,
    );
    switch (type) {
      case 'CANCEL':
        handleBottomOverlay(false);
        break;
      case 'WEB_CHECKIN':
        onWebcheckinClick();
        break;
    }
  };

  const renderIntent = (intentList) => {
    if (!intentList || intentList.length === 0) {
      return null;
    }
    const lgth = intentList.length;
    return intentList.map((item, index) => (
      <React.Fragment>
        <Text style={[AtomicCss.blackText, fonts.blackFontFamily, fsStyle.font14]}>{item}</Text>
        {index < lgth - 1 && <Image style={styles.arrowIcon} source={BLACK_RIGHT_ARROW} />}
      </React.Fragment>
    ));
  };

  return (
    <View style={styles.bottomOverlayWrapper}>
      {flightDetailResponse && flightDetailResponse.webcheckInBanner && (
        <MySafetyBanner {...flightDetailResponse.webcheckInBanner} />
      )}

      {overlayDesc &&
        overlayDesc.map((text) => (
          <Text
            style={[
              AtomicCss.darkText,
              fsStyle.font14,
              fonts.regularFontFamily,
              AtomicCss.marginBottom15,
              AtomicCss.marginTop8,
              AtomicCss.alignLeft,
            ]}
          >
            {text}
          </Text>
        ))}

      <View style={styles.marginBottom60}>
        {flightDetailResponse.newFlightDetails.segmentGroupDetailList.map((item) =>
          item.segmentDetails.map((segmentDetail) => {
            const { hasConnectingFlight, firstFlight, intentList } = connectingFlightInfo(
              segmentDetail.segmentLineNo,
              flightDetailResponse.connectingSegGroup,
              item.segmentDetails,
            );
            const hideSector = hasConnectingFlight && firstFlight === false;
            if (hideSector) {
              return null;
            }
            const webCheckInDescObj = getSegmentApplicableAncillary(
              flightDetailResponse,
              segmentDetail,
              true,
              staticTexts,
              theme.color,
            );
            if (!isEmpty(webCheckInDescObj)) {
              return (
                <View style={[styles.mealsTabInfo, AtomicCss.flexRow]}>
                  <Image
                    style={styles.flightIcon}
                    source={{ uri: getAirlineImageUrl(segmentDetail.airLineCode) }}
                  />
                  <View style={AtomicCss.flex1}>
                    <View
                      style={[AtomicCss.flexRow, AtomicCss.marginBottom3, { flexWrap: 'wrap' }]}
                    >
                      {intentList && intentList.length > 0 ? (
                        renderIntent(intentList)
                      ) : (
                        <React.Fragment>
                          <Text style={[styles.font14, AtomicCss.blackText, fonts.blackFontFamily]}>
                            {segmentDetail.originCity}
                          </Text>
                          <Image style={styles.arrowIcon} source={BLACK_RIGHT_ARROW} />
                          <Text style={[styles.font14, AtomicCss.blackText, fonts.blackFontFamily]}>
                            {segmentDetail.destinationCity}
                          </Text>
                        </React.Fragment>
                      )}
                    </View>
                    <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, { flexWrap: 'wrap' }]}>
                      <Text
                        style={[
                          styles.font12,
                          styles.greylight,
                          fonts.boldFontFamily,
                          AtomicCss.marginRight5,
                        ]}
                      >
                        {getDateAndAirlineName(segmentDetail)}
                      </Text>
                      <Text
                        style={[
                          styles.font12,
                          styles.greenText,
                          fonts.boldFontFamily,
                          { color: webCheckInDescObj.color },
                        ]}
                      >
                        • {webCheckInDescObj.webCheckinStatus} {webCheckInDescObj.webCheckinTime}
                      </Text>
                    </View>
                  </View>
                </View>
              );
            }
          }),
        )}
      </View>

      <View
        style={[
          AtomicCss.flexRow,
          AtomicCss.spaceBetween,
          Platform.OS === 'ios' ? AtomicCss.marginBottom30 : '',
        ]}
      >
        {overlayActionList &&
          overlayActionList.map((action) => {
            return (
              <TouchableOpacity activeOpacity={0.4} onPress={() => actionHandler(action.type)}>
                <Text style={[fonts.regularFontFamily, fsStyle.font16, AtomicCss.azure]}>
                  {action.title}
                </Text>
              </TouchableOpacity>
            );
          })}
      </View>
    </View>
  );
};
export default WebCheckInOverlay;
