import { StyleSheet } from 'react-native';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';

const getStyles = (fonts) => {
  return StyleSheet.create({
    cardWrapper: {
      paddingHorizontal: 24,
      marginTop: 24,
      marginBottom: 20,
    },
    headingText: {
      marginBottom: 20,
      flex: 1,
    },
    infoWrapper: {
      backgroundColor: colors.limeGreen,
      paddingHorizontal: 8,
      paddingVertical: 4,
      borderRadius: 4,
    },
    infoText: {
      color: colors.green,
      ...fonts.font13,
      fontFamily: fonts.black,
      lineHeight: 15.6,
    },
    refundTopDtls: {
      flexDirection: 'row',
      marginBottom: 5,
    },
    Radio: {
      width: 18,
      height: 18,
      backgroundColor: colors.white,
      borderRadius: 20,
      borderWidth: 1,
      borderColor: colors.purpleyGrey,
      marginRight: 10,
      marginTop: 2,
    },
    activeBorder: {
      borderColor: colors.azure,
    },
    myBizActiveBorder: {
      borderColor: colors.orange3,
    },
    RadioInside: {
      width: 12,
      height: 12,
      backgroundColor: colors.azure,
      borderRadius: 20,
      marginLeft: 2,
      marginTop: 2,
    },
    myBizRadioInside:{
      width: 12,
      height: 12,
      backgroundColor: colors.orange3,
      borderRadius: 20,
      marginLeft: 2,
      marginTop: 2
    },
    refundTag: {
      paddingHorizontal: 8,
      paddingVertical: 4,
      borderRadius: 12,
    },
    refundTagText: {
      color: colors.white,
      fontFamily: fonts.bold,
      ...fonts.font10
    },
    refundText: {
      color: colors.greyLight,
      fontFamily: fonts.regular,
      flex: 1,
      lineHeight: 18,
    },
    refundLeftDtls: {
      color: colors.black,
      fontFamily: fonts.bold,
      ...fonts.font16,
      lineHeight: 20,
      marginRight: 8,
    },
    selectWrapperUp: {
      backgroundColor: colors.lightGrey6,
      borderRadius: 4,
      padding: 16,
      marginBottom: 8,
    },
    activeBg: {
      backgroundColor: colors.lighterBlue,
    },
    
    radioWprStyle: {marginRight:10, marginTop:4,alignSelf:'flex-start'}
  });
}

export default getStyles;
