import React from 'react';
import { View, ScrollView, StyleSheet } from 'react-native';
import Actions from '../../../../navigation/postSalesNavigation';
import ReasonRefundRqstCard from './ReasonRefundRqstCard';
import RaiseRequestProcessCardV2 from './RaiseRequestProcessCardV2';
import RefundExpectedCard from './RefundExpectedCard';
import SimpleHeader from '@mmt/legacy-commons/Common/Components/Header/SimpleHeader';
import { getPaxFareSegmentList } from '../../../FlightBookingUtil';
import FlightBookingTrackingHelper from '../../../modules/details/helper/FlightBookingTrackingHelper';
import { getStaticData } from '../../../../staticData/staticData';
import { isEmpty } from 'lodash';

const RefundRequestPage = ({
  specialClaimVariant,
  specialClaimDataList,
  specialClaimMetaData,
  bookingResponse,
  mobileNumber,
  bookingId,
}) => {
  const onReasonSelected = (item) => {
    FlightBookingTrackingHelper.trackClickEvent(
      'Special claim options',
      `Specialclaim_${item.scCode}`,
      bookingResponse,
    );
    if (item && item.scCode === 'ancclaim') {
      Actions.claimAncillaryRefund({
        bookingId: bookingId,
        lobCode: bookingResponse.lobCode,
        scCode: item.scCode,
        flightResponse: bookingResponse,
        isCorporateBooking: !isEmpty(bookingResponse) ? bookingResponse.isCorporateBooking : false,
      });
    } else {
      Actions.specialClaimSelectPassenger({
        specialClaimData: JSON.stringify(item),
        partialPax: JSON.stringify(getPaxFareSegmentList(bookingResponse, item)),
        bookingID: bookingId,
        bookingState: bookingResponse.bookingState.state,
        lobCode: bookingResponse.lobCode,
        mobileNumber,
        isCorporateBooking: !isEmpty(bookingResponse) ? bookingResponse.isCorporateBooking : false,
      });
    }
  };

  const {
    additionalText: { raiseRefundRequestText },
    informationText,
  } = getStaticData();

  const SimpleHeaderContent = SimpleHeader;
  return (
    <View style={{ flex: 1 }}>
      <View style={styles.pageWrapper}>
        <SimpleHeaderContent title={raiseRefundRequestText} iconPress={() => Actions.pop()} />
        <ScrollView>
          <ReasonRefundRqstCard
            claimReasonHeader={specialClaimMetaData.claimReason}
            specialClaimDataList={specialClaimDataList}
            onItemClick={onReasonSelected}
            webViewHeaderText={informationText}
            specialClaimVariant={bookingResponse.specialClaimVariant}
          />
          {specialClaimMetaData.claimProcess && (
            <RaiseRequestProcessCardV2
              {...specialClaimMetaData.claimProcess}
              webViewHeaderText={informationText}
            />
          )}
          {specialClaimMetaData.refundRules && (
            <RefundExpectedCard
              {...specialClaimMetaData.refundRules}
              webViewHeaderText={informationText}
            />
          )}
        </ScrollView>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  pageWrapper: {
    flex: 1,
    backgroundColor: '#f2f2f2',
  },
});

export default RefundRequestPage;
