import React from 'react';
import { View, Text } from "react-native";
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import createStyles from '../contactDetailsCss';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { Detail } from '../../../types';
const CustomerDetails = ({ detail, isLast }: Detail) => {
    const { text:title, subText:info } = detail || {};
    const fonts = getFont();
    const {psTheme : theme} = useTheme();
    const {color} = theme;
    const styles = createStyles(theme,fonts);
    return (
        <View style={AtomicCss.margin5}>
            {!!title && <Text style={[styles.title, AtomicCss.marginBottom3, {color: color.lightTextColor}]}>{title}</Text>}
            <View style={[styles.cusDetailsWrapper, isLast ? styles.fullWidth : {}]}>
                {!!info && <Text style={[styles.title, {color: color.black}]}>{info}</Text>}
            </View>
        </View>
    )
}

export default CustomerDetails;