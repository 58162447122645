import {Platform} from 'react-native';
import RNFetchBlob from 'rn-fetch-blob';
import {isNonEmpty} from './StringUtils';
import {showShortToast} from '../Components/Toast';


export function isNullOrEmpty(object) {
  return object == null || object.length === 0;
}

/**
 * Download file from the network.
 * Files are downloaded in Download folder.
 *
 * @param url file resource url
 * @param fileName name of the file.
 * @param fileExt Extension of the file ex: pdf, html
 */
export const downloadFile = (url, fileName, fileExt) => {
  if (!isNonEmpty(url) || !isNonEmpty(fileName)) {
    console.log('Download params are empty.');
    return;
  }

  let ext = '';
  if (isNullOrEmpty(fileExt)) {
    ext = `.${extension(url)[0]}`;
  } else {
    ext = `.${fileExt}`;
  }

  const {fs} = RNFetchBlob;
  const downloadDir = fs.dirs.DownloadDir;
  const path = `${downloadDir}/${fileName}${ext}`;
  download(url, path, () => {
  });
};
/**
 * Download file from the network.
 * Files are downloaded in Download folder.
 * @param url file resource url
 * @param fileName name of the file.
 * @param {*} headers Headers for GET request to download a secure file
 */
export const downloadFileWithHeaders = (url, fileName, headers , callback  = filePath => {}) => {
  if (!isNonEmpty(url) || !isNonEmpty(fileName)) {
    console.log('Download params are empty.');
    return;
  }
  const {fs} = RNFetchBlob;
  const downloadDir = fs.dirs.DownloadDir;
  const path = `${downloadDir}/${fileName}`;
  download(url, path, callback, headers);
};

function download(src, filePath, cb, headers) {
  const configOptions = {
    fileCache: true,
    path: filePath,
    addAndroidDownloads: {
      useDownloadManager: true,
      notification: true,
      description: 'File downloaded by download manager.',
      path: filePath
    }
  };
  console.log(`Downloading file from URL ${src}`);
  RNFetchBlob
    .config(configOptions)
    .fetch('GET', src, headers)
    .then((res) => {
      showShortToast(`File downloaded at ${res.path()}`);
      if (Platform.OS === 'ios') {
        RNFetchBlob.ios.previewDocument(res.path());
      }
      cb(res.path());
    })
    .catch((err) => {
      showShortToast(`Unable to download file ${err}`);
      cb(undefined);
    });
}

function extension(filename) {
  return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename) : undefined;
}
