import React from 'react';
import {Image, ScrollView, StyleSheet, Text, View} from 'react-native';
import Actions from '../../navigation/postSalesNavigation';
import TopMessage from './TopMessage';
import RedirectBtn from './RedirectBtn';
import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import DateComponent from './DateComponent';
import {
  flightIcon,
  rightArrow,
  screenWidth
} from '../HolidayBookingConstants';
import CheckListSafeTravel from './CheckListSafeTravel';
import WebCheckInStatus from './webCheckInStatus';
import HolidayBookingTrackingHelper from '../HolidayBookingTrackingHelper';

const FlightList = (props) => {
  const {
    flightData, notificaion, bookingId, holidayResponse,
    pageName, checklist, toggleChecklistOverlay, checklistActionHandler, holidayData
  } = props;

  const getWebCheckinStatus = (segment) => {
    const segmentDetails=segment.segmentDetails[0];
    let webCheckInText;
    if(segmentDetails.webCheckInDescription === 'OPEN' || segmentDetails.webCheckInDescription === 'OPEN_MMT_SUPPORTED') {
      webCheckInText = 'Web Check In Available Now!';
    } else if(segmentDetails.webCheckInDescription === 'MMT_NOT_SUPPORTED' && segmentDetails.webCheckAllowed) {
      webCheckInText = 'Web Check In Available Now!';
    } else if(segmentDetails.webCheckInDescription === 'ALL_CHECKED_IN') {
      webCheckInText = 'Web Check In Completed';
    } else if(segmentDetails.webCheckInDescription === 'WEBCHECKIN_SCHEDULED' && !segmentDetails.webCheckInApplicable) {
      webCheckInText = 'Web Check In Request Raised';
    } else if(segmentDetails.webCheckInDescription === 'SCHEDULED_OPEN' && segmentDetails.webCheckInApplicable) {
      webCheckInText = 'Web Check In Available Now!';
    }
    else {
      webCheckInText = '';
    }
    return webCheckInText;
  };

  const getFlightHeading = (segment) => {
    const heading = [];
    heading.push(segment[0].originCity);
    heading.push(segment[segment.length - 1].destinationCity);
    return `${heading.join(' to ')} Flight`;
  };
  const getFlightNameDetails = (item) => {
    return item.dummyFlight
      ? item.dummyFlightInfo?.dummyFlightName
      : `${item.flightDesignator.flightName} ${item.airLineCode} ${item.flightDesignator.flightNumber}`;
  };

  const viewDetail = (collapseIndex) => {
    HolidayBookingTrackingHelper.trackClickEvent(pageName, 'View_Flight_Details','componentList_Flights' );
    HolidayBookingTrackingHelper.trackViewEvent(
      pageName + '_Flights',
      'Flight Booking Details',
    );
    Actions.flightBookingDetail({
      BOOKING_ID: bookingId,
      holidayPageName: pageName + '_Flights',
      collapseIndex,
      stickyHeader: false,
      type: 'push',
      holidayBookingApi: true,
      holidayData: holidayData,
      showMyraBtn: false
    });
  };
  if (!flightData || flightData.length === 0) {
    return (
      <View style={{
        width: screenWidth,
        alignItems: 'center'
      }}
      >
        <Text
          style={[
            AtomicCss.blackFont,
            AtomicCss.font16,
            AtomicCss.blackText
          ]}
        >
          No Flight Found
        </Text>
      </View>
    );
  }
  return (
    <ScrollView style={{width: screenWidth}} stickyHeaderIndices={[0]}>
      {notificaion && (
        <TopMessage img="voucherIcon" info={notificaion} bgcolor="#cf8100"/>
      )}
      {checklist &&
      <CheckListSafeTravel
        onViewAllClick={toggleChecklistOverlay}
        checkList={checklist}
        checklistActionHandler={checklistActionHandler}
      />
      }
      {flightData &&
      flightData.map((segment, fligthIndex) => (
        <React.Fragment key={fligthIndex}>
          <View style={[styles.cardWrapper, AtomicCss.marginBottom10]}>
              <WebCheckInStatus status={getWebCheckinStatus(segment)}/>
            <View
              style={[
                AtomicCss.flexRow,
                AtomicCss.marginBottom18,
                AtomicCss.alignCenter
              ]}
            >
              <Image style={styles.fltIconStyle} source={flightIcon}/>
              <Text
                style={[
                  AtomicCss.blackFont,
                  AtomicCss.defaultTextColor,
                  AtomicCss.font14,
                  styles.flightHeader
                ]}
              >
                {getFlightHeading(segment.segmentDetails)}
              </Text>
            </View>
            {segment.segmentDetails.map((item, index, totalSegments) => (
              <React.Fragment key={`${index}${item.airLinePNRNo}`}>
                <View style={styles.fltInnerDtls}>
                  <View
                    style={[
                      AtomicCss.flexRow,
                      AtomicCss.spaceBetween,
                      AtomicCss.marginBottom10
                    ]}
                  >
                    <Text
                      style={[
                        AtomicCss.blackFont,
                        AtomicCss.font16,
                        AtomicCss.blackText,
                        styles.airlineHeader
                      ]}
                    >
                     { getFlightNameDetails(item)}
                    </Text>
                    <View>
                      <Image
                        style={styles.airlineIconStyle}
                        source={{uri: item.dummyFlight? item.dummyFlightInfo?.dummyFlightLogo: item.airlineLogoUrl}}
                      />
                    </View>
                  </View>
                  <View style={[AtomicCss.flexRow]}>
                    <View style={styles.fltDtlsSection}>
                      <View style={[styles.flightsTimeInfo, AtomicCss.marginBottom5]}>
                        <DateComponent
                          isDummyFlight={item.dummyFlight}
                          time={item.travelDateTime}
                          color="blackText"
                          font="font14"
                          fontWeight="boldFont"
                        />
                      </View>
                      <Text
                        style={[
                          AtomicCss.regularFont,
                          AtomicCss.font14,
                          AtomicCss.defaultText
                        ]}
                      >
                        <Text style={AtomicCss.boldFont}>
                          {item.originCityCode}
                        </Text>
                        , {item.originCity}
                      </Text>
                    </View>
                    <View>
                      <Image
                        style={styles.rightArrowStyle}
                        source={rightArrow}
                      />
                    </View>
                    <View
                      style={[
                        styles.fltDtlsSection,
                        AtomicCss.marginLeft12
                      ]}
                    >
                      <View style={[styles.flightsTimeInfo, AtomicCss.marginBottom5]}>
                        <DateComponent
                          isDummyFlight={item.dummyFlight}
                          time={item.arrivalDateTime}
                          color="blackText"
                          font="font14"
                          fontWeight="boldFont"
                        />
                      </View>
                      <Text
                        style={[
                          AtomicCss.regularFont,
                          AtomicCss.font14,
                          AtomicCss.defaultText
                        ]}
                      >
                        <Text style={[AtomicCss.boldFont]}>
                          {item.destinationCityCode}
                        </Text>
                        , {item.destinationCity}
                      </Text>
                    </View>
                  </View>
                  {item.info && (
                    <View style={AtomicCss.marginTop10}>
                      <Text
                        style={[
                          AtomicCss.font14,
                          AtomicCss.regularFont,
                          styles.pendingInfo
                        ]}
                      >
                        {item.info}
                      </Text>
                    </View>
                  )}
                </View>
                {index < totalSegments.length - 1 && (
                  <View style={styles.layOverWrapper}>
                    <View style={styles.horizontalLine}/>
                    <View style={styles.layOverDtls}>
                      <Text
                        style={[
                          AtomicCss.greyText,
                          AtomicCss.font12,
                          AtomicCss.regularFont
                        ]}
                      >
                        Stop in {item.destinationCity}
                      </Text>
                    </View>
                  </View>
                )}
              </React.Fragment>
            ))}
           {!segment.dummyFlight &&
            <RedirectBtn btnName="View Flight Details" onPress={() => viewDetail(fligthIndex)}/>}
          </View>
        </React.Fragment>
      ))}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  cardWrapper: {
    width: screenWidth,
    backgroundColor: '#fff',
    ...getPlatformElevation(2),
    paddingLeft: 16,
    paddingTop: 16,
    marginBottom: 3
  },
  flightHeader: {
    width: '90%'
  },
  fltIconStyle: {
    width: 22,
    height: 12,
    marginRight: 20
  },
  fltInnerDtls: {
    paddingLeft: 40,
    paddingRight: 16,
    marginBottom: 20
  },
  airlineHeader: {
    maxWidth: '90%'
  },
  airlineIconDefaultStyle: {
    width: 30,
    height: 25,
    zIndex: 1,
    position: 'absolute'
  },
  airlineIconStyle: {
    width: 30,
    zIndex: 2,
    height: 25
  },
  fltDtlsSection: {
    width: '35%',
    marginRight: '5%'
  },
  rightArrowStyle: {
    width: 8,
    height: 7,
    top: 7,
    marginRight: 15
  },
  layOverWrapper: {
    marginTop: 30,
    justifyContent: 'center',
    alignItems: 'center'
  },
  horizontalLine: {
    height: 1,
    backgroundColor: '#f2f2f2',
    width: '100%'
  },
  layOverDtls: {
    backgroundColor: '#fff',
    borderWidth: 1,
    borderColor: '#f2f2f2',
    borderRadius: 30,
    paddingVertical: 7,
    paddingHorizontal: 20,
    top: -16
  },
  redirectBtnWrapper: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 40,
    paddingRight: 35,
    justifyContent: 'space-between',
    flexDirection: 'row',
    backgroundColor: '#fff',
    alignItems: 'center',
    marginLeft: -16,
    borderTopWidth: 1,
    borderTopColor: '#d5d5d5',
    marginTop: 15
  },
  rightArrowIcon: {
    width: 9,
    height: 13
  },
  linkBtnTxt: {
    fontSize: 14,
    fontFamily: fonts.bold,
    color: '#008cff'
  },
  flightsTimeInfo: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  pendingInfo: {
    color: '#cf8100',
    fontFamily: fonts.regular,
    fontSize: 14,
    lineHeight: 20,
    fontStyle: 'italic'
  }
});

export default FlightList;
