import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from './GetPlatformElevationCss';

const styles = {
  pageWrapper: {
    flex: 1,
    backgroundColor: '#f2f2f2',
  },
  pageScroll: {
    flex: 1,
  },
  pageScrollBtmSpace: {
    height: 58,
  },
  pageScrollTopSpace: {
    height: 0,
    marginTop: -5,
  },
  footerBtnWrapper: {
    padding: 12,
    position: 'absolute',
    zIndex: 2,
    bottom: 0,
    left: 0,
    width: '100%',
  },
  reqMessageWrapper: {
    padding: 15,
    paddingTop: 8,
  },
  reqMessage: {
    color: colors.defaultTextColor,
    marginBottom: 6,
  },
  submitReqBtnText: {
    fontSize: 12,
    color: colors.azure,
    paddingVertical: 6,
  },
  headerTitle: {
    color: colors.black,
    width: '90%',
  },
  headerSubTitle: {
    fontSize: 12,
    color: colors.disabledBtnBg,
    marginTop: 3,
  },
  whiteCard: {
    backgroundColor: '#ffffff',
    padding: 15,
    marginVertical: 8,
    marginBottom: 20,
    elevation: 2,
    shadowColor: '#000000',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.3,
    shadowRadius: 1,
  },
  refundCard: {
    backgroundColor: '#ffffff',
    padding: 15,
    marginVertical: 8,
    marginBottom: 10,
    elevation: 2,
    shadowColor: '#000000',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.3,
    shadowRadius: 1,
  },
  cardTitle: {
    color: colors.black,
    marginBottom: 10,
  },
  cardSubTitle: {
    fontSize: 12,
    color: colors.gray,
    marginBottom: 20,
  },
  anchorMD: {
    color: colors.azure,
    fontSize: 14,
    letterSpacing: 0.4,
  },
  marginTop20: {
    marginTop: 20,
  },
  marginBottom20: {
    marginBottom: 20,
  },
  segmentCard: {
    backgroundColor: '#ffffff',
    padding: 15,
    marginVertical: 0,
    marginBottom: 1,
    elevation: 2,
    shadowColor: '#000000',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.3,
    shadowRadius: 1,
  },
  listWrapper: {
    borderRadius: 4,
    overflow: 'hidden',
    borderBottomColor: 'transparent',
    paddingTop: 10,
    paddingBottom: 10,
    ...getPlatformElevation(2),
  },
  paraText: {
    color: colors.black,
  },
  destRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  cityText: {
    marginTop: 5,
    fontSize: 16,
    color: colors.black28,
  },
  arrowIcon: {
    marginTop: 5,
    width: 10,
    height: 7,
    marginHorizontal: 5,
  },
  smallText: {
    fontSize: 10,
    color: colors.defaultTextColor,
    marginLeft: 5,
    marginTop: 7,
    marginBottom: 0,
    lineHeight: 10,
  },
  importantInofTag: {
    borderRadius: 10,
    width: 180,
    height: 15,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 10,
    left: 30,
    elevation: 2,
    zIndex: 111,
  },
  tagStyle: {
    color: '#fff',
  },
};

export default styles;
