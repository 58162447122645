import { isEmptyArray, isNullOrEmptyObject } from '../Common/commonUtil';
import isEmpty from 'lodash/isEmpty';
import FlightBookingModule from '@mmt/legacy-commons/Native/FlightBookingModule';
import { LOBNAMES } from '../PostSalesConstant';
import { getCommonHeaders, RENEW_FIREBASE_AUTH_URL } from '../utils/NetworkUtils';
import { initializeApp, getApps } from 'firebase/app';
import { getDatabase, ref, onValue } from 'firebase/database';
import { getAuth, signInWithCustomToken, setPersistence, browserSessionPersistence } from "firebase/auth";
import { SessionStorage } from '../utils/localstorage';
class PostsaleFirebaseApp {
  constructor(config, tokenKey, firebaseTokenList, bookingId) {
    this.instance = null;
    this.config = config;
    this.bookingId = bookingId
    this.tokenKey = tokenKey;
    this.totalRenewRetriesAllowed = 1;
    this.currentRenewRetry = 0;
    this.storageKey = bookingId + '_' + tokenKey;
    this.authToken = this.getAuthToken(tokenKey, firebaseTokenList);
    this.renewTokenUrl = RENEW_FIREBASE_AUTH_URL + bookingId;
    this.onValueListeners = [];
  }

  init = async () => {
    const app = this.getFirebaseInstance(this.config);
    this.instance = app;
    if (!isEmpty(this.authToken)){
      SessionStorage.setItem(this.storageKey, this.authToken);
    } else {
      this.authToken = SessionStorage.getItem(this.storageKey);
    }
    return !isEmpty(this.authToken) ? await this.authenticateInstance(this.authToken) : await this.renewAuthToken();
  }
  
  getFirebaseInstance = (config) => {
    const apps = getApps();
    if (!isEmptyArray(apps)) {
      let firebaseApp = apps.find((app) => app.name === config.projectId);
      if (firebaseApp) {
        return firebaseApp;
      }
    }
    return this.createFirebaseInstance(config);
  };

  createFirebaseInstance = (config) => {
    if (isNullOrEmptyObject(config)) {
      return null;
    }
    return initializeApp(config, config.projectId);
  };

  getDatabase = () => {
    return (this.instance && this.config.databaseURL) ? getDatabase(this.instance, this.config.databaseURL) : null;
  };

  getAuthToken = (tokenKey, firebaseTokenList) => {
    const tokenValue = !isEmpty(firebaseTokenList) ? firebaseTokenList[tokenKey] : null;
    return tokenValue;
  }
  
  renewAuthToken = async (errorData = null) => {
    let result = {
      isInstanceAuthenticated: false,
      errorData: errorData
    }
    try {
      const userInfo = await FlightBookingModule.getRequestHeader(
        this.bookingId,
        'FIREBASE_TOKEN_RENEW',
       );
      const headers = await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo, {
        psLob: LOBNAMES.FLIGHT,
      })
      const response = await fetch(this.renewTokenUrl, {
        method: 'POST',
        headers: headers
      })
      if (response.ok){
        const renewApiResponse = await response.json();
        const { firebaseTokenList = {} } = renewApiResponse || {};
        this.authToken = this.getAuthToken(this.tokenKey, firebaseTokenList);
        SessionStorage.setItem(this.storageKey, this.authToken);
        !isEmpty(this.authToken)
          ? result = this.authenticateInstance(this.authToken, result.errorData)
          : (result.errorData = 'Auth Token Empty');
      }
      else {
        result.errorData = 'Renew Api Failed';
        console.log("@ Renew Failed: ", response.status)
      }
      return result;
    }
    catch (error) {
      result.errorData = error.message;
      console.log("@ FireBase Auth Retry Error: ", error)
      return result;
    }
  }

  authenticateInstance = async (authToken, errorData = null) => {
    let result = {
      isInstanceAuthenticated: false,
      errorData: errorData
    }
    try{
      const auth = getAuth(this.instance);
      try {
        await setPersistence(auth, browserSessionPersistence);
      } catch (error) {
          result.errorData = 'Persistence Error: ' + error;
          console.log("@Persistence set Failed!!")
      }
      try {
        const userCredential = await signInWithCustomToken(auth, authToken);
        const user = userCredential.user;
        result.isInstanceAuthenticated = true;
        result.errorData = null;
        this.currentRenewRetry = 0;
      } catch (error) {
        const errorCode = error.code;
        const errorMessage = error.message;
        result.errorData = errorCode + ' ' + errorMessage; 
        if (errorCode == 'auth/invalid-custom-token') {
          if (this.totalRenewRetriesAllowed > this.currentRenewRetry){
            this.currentRenewRetry = this.currentRenewRetry + 1;
            result = await this.renewAuthToken(result.errorData)
          }
        }
      };
    } catch (error) {
        result.errorData = error;
        console.log("Exception: ", error)
    }
    return result;
  }


  getRef = (path) => {
    const db = this.getDatabase();
    return db ? ref(db, path) : null;
  };

  onValue = (path, callback) => {
    const fbRef = path ? this.getRef(path) : null;
    const onValueListener =
      fbRef &&
      onValue(fbRef, (snapshot) => {
        callback && callback(snapshot && snapshot.val());
      });
    onValueListener && this.onValueListeners.push(onValueListener);
  };
  
  removeOnValueListeners = () => {
    !isEmptyArray(this.onValueListeners) &&
      this.onValueListeners.forEach((unsubscribe) => {
        if (typeof unsubscribe === 'function') {
          unsubscribe();
        }
      });
  };
}
export default PostsaleFirebaseApp;