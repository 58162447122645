import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import getStyle from '../SectorSelection/SelectPassengersCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { isEmptyArray } from '../../../../../../../Common/commonUtil';
import CheckBox from '../../../../../../../Common/Checkbox';
import { getFont } from '../../../../../../../PostSalesStyles';
import { useTheme } from '../../../../../../../theme/theme.context';

const CheckboxRow = ({ data, onPressHandler }) => {
  const { fsStyle, ...fonts } = getFont(true);
  const {psTheme} = useTheme();
  const styles = getStyle(psTheme, fonts);
  const {color} = psTheme;
  const ismoreInfoVisible = data.footerText ? 'isMoreInfoWrapper' : '';
  const showChildPaxWarning = data.showChildPaxMsg && !isEmptyArray(data.subtitle);
  
  return (
    <TouchableOpacity
      activeOpacity={0.9}
      style={[styles.checkBtn, styles.sectorPaxList, styles[ismoreInfoVisible]]}
      onPress={onPressHandler}
    >
      <CheckBox checked={data.selected} onChange={onPressHandler} size={20} />
      <View>
        <View style={styles.selectNameRow}>
          <Text
            style={[
              styles.passengerName,
              showChildPaxWarning ? AtomicCss.paddingBottom10 : '',
              fsStyle.font14,
            ]}
          >
            {data.title}
          </Text>
          {showChildPaxWarning && !data.selected && (
            <Text style={[fsStyle.font13, fonts.regularFontFamily, color.lightYello]}>
              {data.subtitle[0]}
            </Text>
          )}
        </View>
      </View>
    </TouchableOpacity>
  );
};
export default CheckboxRow;
