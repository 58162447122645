import React from 'react';
import {View,ScrollView,Text,TouchableOpacity,Image} from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import styles from './PassengerTabCss'

export default class PassengerTab extends React.Component {
  constructor(props) {
    super(props);

  }
  static navigationOptions = {
    header: null
  };

  render() {
    const activeStyle = this.props.index === this.props.activeTab;
    const item = this.props.item;

    return (

            <TouchableOpacity style={styles.tabTouchable} activeOpacity={1} onPress={()=> this.props.handleTab(this.props.index)}>
                <View style={[AtomicCss.flexRow, activeStyle?[styles.TabItemActive]:[styles.TabItem]]} >
                     <Text style={activeStyle?[styles.TabItemTxtActive]:[styles.TabItemTxt]}>{this.props.tabText}</Text>
                     <View style={activeStyle?[styles.tabBadgeCountActive]:[styles.tabBadgeCount]}><Text style={styles.tabBadgeText}>{this.props.count}</Text></View>
                </View>

              {activeStyle && <View style={styles.tabBottomBorder}></View>}
            </TouchableOpacity>

    );
  }
}
