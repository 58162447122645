import React,{useState,useEffect} from 'react';
import { Text, View, StyleSheet, TouchableOpacity,Keyboard,Platform } from 'react-native';
import BottomSheet from '@Frontend_Ui_Lib_App/BottomSheet';

const BottomSheetWpr = ({ onDismiss, children, setVisible, containerStyles, visible,noScrollPopupOnKeyboardOpen, onRequestClose = ()=> {} }) => {
  const [bottom, setBottom] = useState(0);
  // useEffect to handle componentDidMount and componentWillUnmount
  useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', _keyboardDidShow);
    const keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', _keyboardDidHide);

    // Cleanup listeners on component unmount
    return () => {
      keyboardDidShowListener.remove();
      keyboardDidHideListener.remove();
    };
  }, []);  // Empty dependency array to mimic componentDidMount

  // Handler for keyboardDidShow event
  const _keyboardDidShow = (e) => {
    if (noScrollPopupOnKeyboardOpen) {
      setBottom(0);
    } else {
      setBottom(e.endCoordinates.height);
    }
  };

  // Handler for keyboardDidHide event
  const _keyboardDidHide = () => {
    setBottom(0);
  };
  return (
    <BottomSheet
      visible={visible}
      customStyles={{
        containerStyle: [styles.wrapperStyle, containerStyles, (Platform.OS === 'ios' ? {bottom} : null)],
      }}
      onDismiss={onDismiss}
      setVisible={setVisible}
      onRequestClose={onRequestClose}
      {...(Platform.OS === 'web' ? { bottomSheetVariant: "default" } : {})}
    >
      {children}
    </BottomSheet>
  );
};

const styles = StyleSheet.create({
  // OVERLAY STYLES
  wrapperStyle: {
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    paddingBottom: 15,
  },
});

export default BottomSheetWpr;
