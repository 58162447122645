import React from 'react';
import { View, Text } from 'react-native';
import PropTypes from 'prop-types';
import styles from './BreadcrumbCss';

const Breadcrumb = ({ data, activeStep }) => {

  if (!data) {
    return null;
  }

  return (<View style={styles.steps}>
    {
      data.map((step, index) => {
        const activeStyle = index === activeStep ? 'Active' : '';
        return (
          <View key={index} style={styles.stepItem}>
            <View style={[styles.stepNumberWrapper, styles[`stepNumberWrapper${activeStyle}`]]}>
              <Text style={styles.stepNumber}>{index + 1}</Text>
            </View>
            <Text numberOfLines={1} style={[styles.stepName, styles[`stepName${activeStyle}`]]}>{step}</Text>
          </View>
        );
      })
    }
  </View>)
};

Breadcrumb.propTypes = {
  activeStep: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired
};

export default Breadcrumb;

