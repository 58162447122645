/* eslint-disable max-len */
import React from 'react';
import { View, Text, Image } from 'react-native';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import styles from './CabBookingDetailStyles';
import { locationGhostIcon, seatIcon, aCIcon, bagIcon } from "../../CabImageConstants";
import {
  getCabFeatures,
  fillDepartureArrivalDate,
  getButtonStyle, getActionButton, handleClickEvent
} from '../../utils/CabBookingDetailUtil';
import { dateFormat, timeFormat } from '@mmt/legacy-commons/Helpers/dateHelpers';
import CabBookingDetailsConstant from '../../CabBookingDetailsConstant';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
class TripDetailsCard extends React.Component {

  render() {
    return (
      <View style={AtomicCss.marginBottom10}>
        <View style={[styles.card, AtomicCss.marginBottom0]}>
          <View style={styles.cardPrimaryDetails}>
            <Image source={locationGhostIcon} style={styles.cardIcon} />
            <View style={styles.content}>
              <Text style={styles.cardTitle}>{CabBookingDetailsConstant.TRIP_DETAILS_CARD_HEADING}</Text>
   
                <>
                {!isEmpty(this.props.response) &&
                  !isEmpty(this.props.response.multicitytransport) ? 
                  <View style={styles.multiCityBody}>{this.MultiCityStop(this.props.response.multicitytransport)}</View> :
                  <View style={styles.body}>
                    {this.renderLocationDetails(true)}
                    {this.props.response.clientDetails.isRoundTrip ?
                      <React.Fragment>
                        {this.renderSeperator()}
                        {this.renderLocationDetails(false)}
                      </React.Fragment> : null}
                  </View>}
                  </>
            </View>
          </View>
          {!isEmpty(this.props.response) && isEmpty(this.props.response.multicitytransport) &&
            <>
              {this.renderTripDetails() ? this.renderMapDetails() : null}
              {this.renderCabFeatures()}
            </>
          }
          {(this.props.response.clientDetails.isBoarded &&
            !(CabBookingDetailsConstant.COMPLETED_BOOKING_STATE === this.props.response.bookingState.state.toLowerCase()) &&
            !isEmpty(this.props.response.clientDetails.odometerText)) ? this.renderOdometerRow() : null}

        </View>
        <View
          style={!isEmpty(this.props.card.actionList) ? [getButtonStyle(this.props.card.actionList), styles.transperantBg] : styles.hideView}
        >
          {getActionButton(this.props.card, this.props.response, this.handleClick)}
        </View>
      </View>
    );
  }

  handleClick = (action) => {
    handleClickEvent(this.props.card, action, this.props.response, this.props.pageName);
  };

  fillTheTime = (firstValue, secondValue) => {
    if (!isEmpty(firstValue)) {
      return firstValue;
    } else if (((typeof secondValue === 'object' || typeof secondValue === 'string') && !isEmpty(secondValue)) || secondValue) {
      return fillDepartureArrivalDate(secondValue, timeFormat)
    }

    return '';
  }

  getDepartureTime = () => {
    const { carAdditionalInfo } = this.props.response || {};
    const { departureDetail } = carAdditionalInfo || {}
    const { departureTimeTwelveHoursFormat, departureTime } = departureDetail || {}
    return this.fillTheTime(departureTimeTwelveHoursFormat, departureTime);
  }

  getArrivalTime = () => {
    const { carAdditionalInfo } = this.props.response || {};
    const { arrivalDetail } = carAdditionalInfo || {};
    const { arrivalTimeInTwelveFourHoursFormat, arrivalTime } = arrivalDetail || {};
    return this.fillTheTime(arrivalTimeInTwelveFourHoursFormat, arrivalTime);
  }

  renderTripDetails = () => this.props.response.clientDetails.isBoarded &&
    (this.props.response.liveTrackingEnabled ||
      this.props.response.clientDetails.actualDistance > 0 ||
      !isEmpty(this.props.response.clientDetails.actualDuration) ||
      !isEmpty(this.props.response.clientDetails.odometerText))

  renderLocationDetails = (pickUp) => {
    const departureTime = pickUp ? this.getDepartureTime() : this.getArrivalTime();
    const pickupDate = pickUp ? fillDepartureArrivalDate(this.props.response.carAdditionalInfo.departureDetail.departureDate, dateFormat) :
      fillDepartureArrivalDate(this.props.response.carAdditionalInfo.arrivalDetail.arrivalDate, dateFormat);
    const leftCity = pickUp ? this.props.response.carBookingInfo.carDetail.fromCity : this.props.response.carBookingInfo.carDetail.toCity;
    const leftAddress = pickUp ? this.props.response.carAdditionalInfo.departureDetail.address : this.props.response.carAdditionalInfo.arrivalDetail.location;
    const rightCity = pickUp ? this.props.response.carBookingInfo.carDetail.toCity : this.props.response.carBookingInfo.carDetail.fromCity;
    const rightAddress = pickUp ? this.props.response.carAdditionalInfo.arrivalDetail.location : this.props.response.carAdditionalInfo.departureDetail.address;
    const pickupDropText = pickUp ? 'Pickup by' : 'Drop by';


    return (
      <View style={{ flex: 1 }}>
        <Text style={styles.timeText}>{pickupDropText}</Text>
        {<View style={styles.tripDateRow}>
          <Text style={styles.pickUpTime}>{departureTime}</Text>
          <Text style={styles.dropTime}>{pickupDate}</Text>
        </View>}
        <View style={styles.locationDetails}>
          <View style={styles.pickUpPointDetails}>
            <Text style={styles.locationName} numberOfLines={1}>{leftCity}</Text>
            <Text style={styles.smallText}>{leftAddress}</Text>
          </View>

          {!this.props.response.clientDetails.isRoundTrip ?
            <View style={styles.tripDurationDetails}>
              <Text style={styles.tripDistance}>{this.props.response.carAdditionalInfo.kmLimit} {CabBookingDetailsConstant.DISTANCE_UNIT}</Text>
              <Text style={styles.smallText}>{this.props.response.carAdditionalInfo.duration}</Text>
              <Text style={styles.smallText}>{CabBookingDetailsConstant.APPROX_TEXT}</Text>
            </View> :
            <View style={styles.tripDurationDetails} />
          }
          <View style={styles.dropPointDetails}>
            <Text style={styles.locationName} numberOfLines={1}>{rightCity}</Text>
            <Text style={[styles.smallText]}>{rightAddress}</Text>
          </View>
        </View>
      </View>
    );
  };


  MultiCityStop = (multicitytransport) => {
    const departureTime = this.getDepartureTime();
    const arrivalTime = this.getArrivalTime();
    const pickupDepartureDate = fillDepartureArrivalDate(this.props.response.carAdditionalInfo.departureDetail.departureDate, dateFormat);
    const pickupArrivalDate = fillDepartureArrivalDate(this.props.response.carAdditionalInfo.arrivalDetail.arrivalDate, dateFormat);
    const departureCity = this.props.response.carBookingInfo.carDetail.fromCity;
    const departureAddress = this.props.response.carAdditionalInfo.departureDetail.address;
    const destination = this.props.response.carBookingInfo.carDetail.toCity;
    const destinationAddress = this.props.response.carAdditionalInfo.arrivalDetail.location;
    return (
      <View style={{ flex: 1 }}>
        <View style={[AtomicCss.flexColumn, AtomicCss.marginBottom5]}>
          <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween, AtomicCss.marginBottom5]}>
            <Text style={[AtomicCss.regularFont, AtomicCss.font11]}>{CabBookingDetailsConstant.LOCATION_FROM}</Text>
            <Text style={[AtomicCss.blackText, AtomicCss.boldFont, AtomicCss.font11]}>{departureTime}</Text>
          </View>
          <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween]}>
            <Text style={[AtomicCss.blackText, AtomicCss.boldFont, AtomicCss.font14]} numberOfLines={1}>{departureCity}</Text>
            <Text style={[AtomicCss.regularFont, AtomicCss.font11]}> {pickupDepartureDate}</Text>
          </View>

        </View>
        <View>
          <Text style={styles.smallText}>{departureAddress}</Text>
        </View>
        <View style={styles.multiCityWrapper} >
          {multicitytransport.map((location, index) =>
            <View style={[styles.multiCityRow, AtomicCss.flexColumn, AtomicCss.alignLeft]}>
              <Text style={[styles.stopTitleTxt, AtomicCss.marginBottom4]}>{'Stop ' + (Number(index) + 1)}</Text>
              <Text style={[AtomicCss.boldFont, AtomicCss.blackText, AtomicCss.font13]}>{location.locationname}</Text>
            </View>
          )}
        </View>
        <View style={[AtomicCss.flexColumn, styles.multiCityRow]}>
          <Text style={[AtomicCss.marginBottom5, AtomicCss.regularFont, AtomicCss.font11]}>{CabBookingDetailsConstant.LOCATION_TO}</Text>
          <Text style={[styles.locationName, AtomicCss.blackText]} numberOfLines={1}>{destination}</Text>
          <Text style={[styles.smallText]}>{destinationAddress}</Text>
        </View>
        {this.props.response.clientDetails.isRoundTrip &&
          <View style={[styles.multiCityRow, AtomicCss.flexRow, AtomicCss.spaceBetween]}>
            <View style={[{ flexDirection: 'column' }]}>
              <Text style={[AtomicCss.regularFont, AtomicCss.font11, AtomicCss.marginBottom5]}>{CabBookingDetailsConstant.LOCATION_RETURN}</Text>
              <Text style={[AtomicCss.blackText, AtomicCss.boldFont, AtomicCss.font14]} >{departureCity}</Text>
            </View>
            <View style={[{ flexDirection: 'column' }]}>
              <Text style={[AtomicCss.blackText, AtomicCss.boldFont, AtomicCss.font11, AtomicCss.marginBottom5]}>{arrivalTime}</Text>
              <Text style={[AtomicCss.regularFont, AtomicCss.font11]}> {pickupArrivalDate}</Text>
            </View>
          </View>}
        {this.renderMulticitySeperator()}
        {this.renderTripDetails() ? this.renderMapDetails() : null}
        {this.renderCabFeatures()}
      </View>
    )
  }

  renderMapDetails = () => (
    <View style={styles.mapCard}>
      <View style={styles.mapInfo}>
        {this.props.response.liveTrackingEnabled &&
          <View style={styles.mapInfoRow}>
            <Text style={styles.mapInfoLabel}>BOOKING ID</Text>
            <Text style={styles.mapInfoValue}>{this.props.response.bookingId}</Text>
          </View>}

        {this.props.response.clientDetails.actualDistance > 0 &&
          <View style={styles.mapInfoRow}>
            <Text style={styles.mapInfoLabel}>{CabBookingDetailsConstant.DISTANCE_TRAVELLED}</Text>
            <Text style={styles.mapInfoValue}>{this.props.response.clientDetails.actualDistance}</Text>
          </View>}
        {!isEmpty(this.props.response.clientDetails.actualDuration) && <View style={styles.mapInfoRow}>
          <Text style={styles.mapInfoLabel}>{CabBookingDetailsConstant.TRAVEL_DURATION}</Text>
          <Text style={styles.mapInfoValue}>{this.props.response.clientDetails.actualDuration}</Text>
        </View>}
        {!isEmpty(this.props.response.clientDetails.odometerText) &&
          <View style={styles.mapInfoRow}>
            <Text style={styles.mapInfoLabel}>{CabBookingDetailsConstant.ODOMETER_READING}</Text>
            <Text style={styles.mapInfoValue}>{this.props.response.clientDetails.odometerText}
              <Text style={styles.mapInfoSmallText}>{CabBookingDetailsConstant.ODOMETER_SUBTEXT}</Text>
            </Text>
          </View>}
      </View>
      {/*  todo code to show the map snapshot Post completion of trip
         <View style={styles.mapView}>
        <Image
          source={{ uri: 'https://miro.medium.com/max/750/1*UzUJiSs0VrMEM_ZoiFbJBQ.jpeg' }}
          style={styles.mapImg}
        />
      </View> */}
    </View>
  )

  renderMulticitySeperator = () => {
    const { duration, kmLimit } = this.props.response.carAdditionalInfo;
    return (
      <>
        {!!kmLimit  && !isEmpty(duration) &&
          <Text style={[styles.multiCityRow]}>
            <Text style={[styles.blackText, AtomicCss.font14]}>{duration}</Text>
            <Text style={styles.seperator}> | </Text>
            <Text style={[styles.blackText, AtomicCss.font14]}>{kmLimit} {CabBookingDetailsConstant.DISTANCE_UNIT}</Text>
            <Text style={AtomicCss.regularFont}>{CabBookingDetailsConstant.MULTICITY_FARE_INFO}</Text>
          </Text>
        }
      </>
    )
  }

  renderSeperator = () => (
    <View style={styles.roundTripSeperator}>
      <View style={styles.seperatorLine} />
      <View style={styles.seperatorDaysGhost}>
        <View style={styles.tripDurationDetails}>
          <Text style={styles.tripDistance}>{this.props.response.carAdditionalInfo.kmLimit} {CabBookingDetailsConstant.DISTANCE_UNIT}</Text>
          <Text style={styles.smallText1}>{this.props.response.carAdditionalInfo.duration}</Text>
        </View>
      </View>
    </View>
  )

  renderCabFeatures = () => {
    const CAB_FEATURES = getCabFeatures(this.props.response);
    return (
      <View style={styles.cabFeatureList}>
        {
          CAB_FEATURES.map((feature, index) => {
            const firstFeatureStyle = index === 0 ? styles.cabFeatureFirst : {};
            const lastFeatureStyle = index === CAB_FEATURES.length - 1 ? styles.cabFeatureLast : {};
            const icon = this.getIcon(feature);
            return (
              <View style={[styles.cabFeature, firstFeatureStyle, lastFeatureStyle]} key={index}>
                <Image source={icon} style={styles.cabFeatureIcon} />
                <Text style={styles.cabFeatureName}>{feature}</Text>
              </View>
            );
          })
        }
      </View>
    );
  }

  getIcon = (featureName) => {
    if (featureName.includes(CabBookingDetailsConstant.SEATS)) {
      return seatIcon;
    } else if (featureName.includes(CabBookingDetailsConstant.AC)) {
      return aCIcon;
    }
    return bagIcon;
  };

  renderOdometerRow = () => (
    <View style={styles.odometerRow}>
      <Text style={styles.odometerRowLabel}>{CabBookingDetailsConstant.ODOMETER_READING}</Text>
      {
        this.props.response.carAdditionalInfo && this.props.response.carAdditionalInfo.additionalLOBInfo &&
        this.props.response.carAdditionalInfo.additionalLOBInfo.tripInfo &&
        <Text style={styles.odometerRowValue}>{this.props.response.carAdditionalInfo.additionalLOBInfo.tripInfo.odometerStart}</Text>
      }
    </View>
  )
}

TripDetailsCard.propTypes = {
  response: PropTypes.object.isRequired,
  card: PropTypes.object.isRequired
};

export default TripDetailsCard;
