import {colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import { Dimensions } from 'react-native';

const windowHeight = Dimensions.get('screen').height;
const windowWidth = Dimensions.get('screen').width;

const styles = {
    overlayWrapper: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: windowHeight,
        width: windowWidth,
        elevation: 10,
        zIndex: 100,
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        flexDirection: 'row',
        paddingBottom: 70,
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: 'rgba(0,0,0,0.5)',
        height: '100%',
        width: '100%',
        zIndex: 1
    },
    overlayContent: {
        backgroundColor: colors.white,
        paddingHorizontal: 20,
        position: 'relative',
        zIndex: 1,
        width: '100%',
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        paddingTop: 20,
        paddingBottom: 40,
        top: 20
    },
    textDark: {
        color: colors.black,
        fontSize: 16,
        fontFamily: fonts.bold,
        textAlign: 'center'
    }
};

export default styles;