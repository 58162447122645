import React, { useEffect } from 'react';
import { View, Image, Text, ImageBackground } from 'react-native';
import styles from './BestPriceGuarenteeCSS';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getStaticData } from '../../../../staticData/staticData';
import { BEST_PRICE_BG, PINK_TIMER_ICON, WHITE_LEFT_ARROW_ICON } from '../../../HotelImageConstants';
import { isNotNullAndEmpty } from '../../utils/HotelBookingDetailUtil';
import Actions from '../../../../navigation/postSalesNavigation';
import HotelBookingTrackingHelper from '../../../HotelBookingTrackingHelper';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const BestPriceGuarenteeCard = ({ card, hotelDetailsResponse, pageName }) => {
  const { fsStyle, ...fonts } = getFont(true);
  const { bestPriceGuaranteeText, submitClaimTxt } = getStaticData();

  useEffect(()=>{
    HotelBookingTrackingHelper.trackShowEvent(pageName, "gb_bpg_card_shown", hotelDetailsResponse);
  },[hotelDetailsResponse]);

  const onLearnMoreClick = () => {
    HotelBookingTrackingHelper.trackClickEvent(this.props.pageName, "gb_bpg_learn_more_click", hotelDetailsResponse)
    Actions.openPsWebView({
      url: hotelDetailsResponse.bestPriceGuaranteeCardData.learnMoreURL,
      headerIcon: WHITE_LEFT_ARROW_ICON,
    });
  };

  return (
    <ImageBackground source={BEST_PRICE_BG} imageStyle={AtomicCss.borderRadius10} style={styles.bestPriceQuarenteeCard}>
      <Image source={PINK_TIMER_ICON} style={styles.imgTimerIcon} />
      <View style={styles.cardInfo}>
        <Text style={[fsStyle.font14, fonts.blackFontFamily, AtomicCss.defaultText]}>
          {bestPriceGuaranteeText}
        </Text>
        {card && isNotNullAndEmpty(card.instruction) && (
          <Text
            style={[
              fsStyle.font13,
              AtomicCss.defaultText,
              fonts.regularFontFamily,
              AtomicCss.paddingTop10,
              AtomicCss.lineHeight18,
            ]}
          >
            {card.instruction}
            {hotelDetailsResponse &&
              hotelDetailsResponse.bestPriceGuaranteeCardData &&
              isNotNullAndEmpty(hotelDetailsResponse.bestPriceGuaranteeCardData.learnMoreURL) && (
                <Text
                  onPress={onLearnMoreClick}
                  style={[AtomicCss.azure, fonts.boldFontFamily]}
                >{` ${submitClaimTxt}`}</Text>
              )}
          </Text>
        )}
      </View>
    </ImageBackground>
  );
};

export default BestPriceGuarenteeCard;
