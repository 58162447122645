import {colors} from '@mmt/legacy-commons/Styles/globalStyles';

const styles = {
  CardContent: {
    backgroundColor: colors.white,
    borderBottomWidth: 1,
    borderBottomColor: '#e4e4e4',
    flexDirection: 'row',
    padding: 15,
    elevation: 1
  },
  CardIcon: {
    width: 24,
    height: 24,
    marginRight: 10
  },
  mar34Bt: {marginBottom: 34},
  refundbarInfo: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  aminitiesIcon: {
    width: 16,
    height: 16,
    marginRight: 5
  },
  refundStrip: {
    width: '100%',
    backgroundColor: '#ccc',
    height: 3
  },
  refundHalfbar: {
    width: '50%',
    height: 3
  },
  refundGreenBackground: {
    backgroundColor: '#32d18f'
  },
  doubleTick: {
    width: 14, height: 14, marginRight: 5, marginBottom: 5
  },
  refundFullbar: {
    width: '100%',
    height: 3
  },
  borderBottomContent: {borderBottomWidth: 1, borderBottomColor: '#e7e7e7', paddingBottom: 20},
  borderBottomContentSeperator: {
    borderBottomWidth: 5,
    borderBottomColor: '#e7e7e7',
    paddingBottom: 20
  }

};

export default styles;
