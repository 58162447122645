import React, { useState } from 'react';
import { View, Text, Image } from 'react-native';
import getStyles from './RefundBreakupCardCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import HTMLView from 'react-native-htmlview';
import { getStaticData } from '../../../../staticData/staticData';
import isEmpty from 'lodash/isEmpty';
import { isNotNullAndEmpty } from '../../../details/utils/HotelBookingDetailUtil';
import { MMT_BLACK_ICON } from '../../../HotelImageConstants';
import { isNonEmpty } from '@mmt/legacy-commons/Common/utils/StringUtils';
import Checkbox from 'apps/post-sales/src/Common/Checkbox';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';

const getHtmlStyles = (fonts, theme) => {
    return {
      p: {
        color: theme.color.black74,
          ...fonts.font14,
        fontFamily: fonts.regular,
        lineHeight: 20
      },
      span: {
        fontFamily: fonts.bold,
        color: theme.color.lightGreen18
      }
    };
  }

const RefundApplicableCard = ({ isRefundable, isPAH, isBNPL, breakups, cancellationResponse, fromPolicy = false, handleBottomOverlay, tcsChecked, setTCSChecked }) => {
	
    const [viewAmountPaidBreakup, setViewAmountPaidBreakup] = useState(false);
    const [viewDeductionsBreakup, setViewDeductionsBreakup] = useState(false);
    const {cancellationText: {additionalText}, viewBreakupText, hideBreakupTest} = getStaticData();
    const suffixStyle = (isPAH || isBNPL) ? '' : !isRefundable  || cancellationResponse.totalRefundAmount <= 0 ? 'NonRefundable' : 'Refundable';
    const { totalAmountPaid, deductions, refundAmount } = breakups;
    const { tcsNote } = breakups || {};
    const { priceBreakup } = tcsNote || {};
    const {fsStyle, ...fonts} = getFont();
    const {psTheme: theme} = useTheme();
    const styles = getStyles(fonts, theme);
    const htmlStyle = getHtmlStyles(fonts, theme);
    const showDoubleBlackMessage = () => {
        return cancellationResponse && (cancellationResponse.doubleBlackUser || cancellationResponse.bookingCoveredUnderDB) && isNonEmpty( cancellationResponse.doubleBlackMessage) && !fromPolicy;
    }

    const handleTCSCheckBox = () => {
        setTCSChecked(!tcsChecked);
    };

    const getBreakups = (breakupComponent, viewBreakup, setViewBreakup) => {
        const { lbl, pricingComponents, info } = breakupComponent;
        return (
            <React.Fragment>
                {lbl && <View style={AtomicCss.marginBottom18}>
                    <View style={[styles.amountHeadingRow]}>
                        {!isEmpty(lbl.text) && <Text style={[styles.detailText, fonts.boldFontFamily, AtomicCss.blackText]}>
                            {lbl.text}
                            {isNotNullAndEmpty(pricingComponents) && pricingComponents[0].components && <Text onPress={() => setViewBreakup(!viewBreakup)} style={[fonts.boldFontFamily, AtomicCss.azure, fsStyle.font12]}>{`  ${getBreakupText(viewBreakup)} `}</Text>}
                        </Text>}
                        {!isEmpty(lbl.value) && <Text style={[styles.amountText, fonts.boldFontFamily, AtomicCss.blackText, AtomicCss.alignLeft]}>{`${lbl.value} `}</Text>}
                    </View>
                    {!isEmpty(info) && <Text style={[fsStyle.font14, AtomicCss.lineHeight20, AtomicCss.midGreyText, fonts.regularFontFamily, AtomicCss.alignLeft]}>{info}</Text>}
                </View>}
                {viewBreakup && isNotNullAndEmpty(pricingComponents) && pricingComponents[0].components && pricingComponents[0].components.map((item) => {
                    return (
                        <View style={styles.amountRow}>
                            <View style={AtomicCss.flex1}>
                                {!isEmpty(item.desc) && <Text style={[styles.detailText, AtomicCss.defaultText, AtomicCss.alignLeft]}>{item.desc}</Text>}
                                {!isEmpty(item.subDesc) && <HTMLView 
                                    value={item.subDesc}
                                    stylesheet={htmlStyle}
                                />}
                            </View>
                            <View>
                                {(item.showSlasher && !isEmpty(item.slashedValue)) ? <Text style={[styles.amountText, styles.midGreyText, AtomicCss.lineThrought]}>{`${item.slashedValue} `}</Text>
                                    : !isEmpty(item.actualValue) && <Text style={[styles.amountText, styles.defaultText]}>{`${item.actualValue} `}</Text>
                                }
                                {!isEmpty(item.subDesc) && item.showSlasher && !isEmpty(item.actualValue) && <Text style={[styles.amountText, styles.defaultText, AtomicCss.alignRight, AtomicCss.marginRight5]}>{`${item.actualValue} `}</Text>}
                            </View>
                        </View>
                    )
                })}
            </React.Fragment>
        )
    }
 
    const getBreakupText = (viewBreakup) => {
        return viewBreakup ? hideBreakupTest : viewBreakupText;
    }
    
	return (
        <View style={[styles.cardWrapper]}>
            {!fromPolicy && <View style={styles.topHeading}>
                <Text style={styles.titleText}>{additionalText.refundCalculatedText}</Text>
                <Text style={[styles.titleAmountText, styles[`titleAmountText${suffixStyle}`]]}>{`${refundAmount.lbl.value}    `}</Text>
            </View>}
            <View style={styles.refundAmountWrapper}>
                <View style={AtomicCss.marginBottom24}>
                   {getBreakups(totalAmountPaid, viewAmountPaidBreakup, setViewAmountPaidBreakup)}
                </View>
                {getBreakups(deductions, viewDeductionsBreakup, setViewDeductionsBreakup)}
                {!isEmpty(priceBreakup) && <View style={styles.tcsPolicyInfo}>
                    {!isEmpty(priceBreakup.text) && <Text style={[fsStyle.font12, {color: theme.color.defaultTextColor}, fonts.regularFontFamily, AtomicCss.lineHeight18]}>{priceBreakup.text}</Text>}
                    {!isEmpty(priceBreakup.ctaText) && <Text style={[{color: theme.color.azure}, fonts.boldFontFamily, fsStyle.font12]} onPress={() =>handleBottomOverlay('TCS_OVERLAY')}>{priceBreakup.ctaText}</Text>}
                    <View style={[styles.policyCheckedSection]}>
                        <Checkbox
                            checked={tcsChecked}
                            label={priceBreakup.checkboxText}
                            labelStyle={fsStyle.font12}
                            onChange={handleTCSCheckBox}
                            containerStyle= {AtomicCss.alignCenter}
                        />
                    </View>
                </View>}
                {refundAmount && refundAmount.lbl && <View style={styles.totalRow}>
                    {!isEmpty(refundAmount.lbl.text) && <Text style={[styles.totalText, styles[`totalText${suffixStyle}`], AtomicCss.flex1]}>{refundAmount.lbl.text}</Text>}
                    {!isEmpty(refundAmount.lbl.value) && <Text style={[styles.totalText, styles[`totalText${suffixStyle}`]]}>{`${refundAmount.lbl.value} `}</Text>}
                </View>}
                {showDoubleBlackMessage() && <View style={styles.mmtBlackWrapper}>
                    <Image source={MMT_BLACK_ICON} style={styles.mmtBlackIcon} />
                    <Text style={[AtomicCss.flex1, fonts.regularFontFamily, fsStyle.font14, AtomicCss.defaultText, AtomicCss.lineHeight20]}>{cancellationResponse.doubleBlackMessage}
                    </Text>
                </View>}
            </View>
        </View>
	);
}

export default RefundApplicableCard;