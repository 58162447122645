import React from 'react';
import { View, Image, Text, TouchableOpacity } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import BaseButton, { BUTTON_VARIANT } from '../../BaseButton';
import { getFont } from '../../../PostSalesStyles';
import { useTheme } from '../../../theme/theme.context';
import createStyles from './createStyles';
import  isEmpty from 'lodash/isEmpty';
import { CROSS_GREY_ICON_WITHBG } from '../../../flights/FlightImageConstants';


const PermissionSheet = ({ handleBottomOverlay, heading, description1, description2, btnTxt, onSubmit, icon }) => {
    const fonts = getFont();
    const {psTheme: theme} = useTheme();
    const styles = createStyles(theme, fonts);

  return (
    <View>
      <View>
        <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.spaceBetween, AtomicCss.marginBottom10]}>
          {!isEmpty(heading) && <View style={[AtomicCss.flexRow, AtomicCss.flex1, AtomicCss.alignCenter]}>
            <Text style={styles.headingText}>{heading}</Text>
          </View>}
          <TouchableOpacity onPress={() => handleBottomOverlay('')}>
            <Image style={styles.crossIconStyle} source={CROSS_GREY_ICON_WITHBG} />
          </TouchableOpacity>
        </View>
        {!isEmpty(description1) && <Text style={[{fontSize:fonts.fontSize.font16}, fonts.regularFontFamily, AtomicCss.midGreyText]}>{description1}</Text>}
      </View>

      <View style={styles.detailsSection}>
        {!isEmpty(description2) &&
          <View style={styles.rowSection}>
            {!isEmpty(icon) && <View style={styles.iconWrapper}>
              <Image style={[styles.iconStyles]} source={{uri: icon.url}} />
            </View>}
            <View style={AtomicCss.flex1}>
              <Text style={[AtomicCss.defaultText, {fontSize: fonts.fontSize.font16}, fonts.regularFontFamily]}>{description2} </Text>
            </View>
          </View>
        }
        {!isEmpty(btnTxt) && <View style={styles.btnWrapper}>
          <BaseButton
            variant={BUTTON_VARIANT.PRIMARY}
            text={btnTxt}
            clickHandler={onSubmit}
            textStyle={{fontSize: fonts.fontSize.font16}}
          />
        </View>}
      </View>
    </View>
  );
};

export default PermissionSheet;
