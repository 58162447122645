import React from "react";
import { appendUrlPrefix } from "../../webUtils";
import loadable from "@loadable/component";
import ProgressView from "../../components/ProgressView";

export const COMMON_URLS={
    BOOKING_SUMMARY: appendUrlPrefix('/bookingSummary'),
    COMMUNICATION: appendUrlPrefix('/Communication/param'),
}

const COMMON_PATHS = {
    LOGIN_CALL_BACK: {
        pathName: appendUrlPrefix('/loginSuccess'),
        pageKey: "",
        Component: loadable(() => import("../../components/LoginCallBack"), { fallback: <ProgressView /> }),
    },
    BOOKING_SUMMARY: {
        pathName: COMMON_URLS.BOOKING_SUMMARY,
        pageKey: "openBookingSummary",
        Component: loadable(() => import("@rn/apps/post-sales/src/summary/BaseBookingSummary"), { fallback: <ProgressView /> }),
        isRtl : true
    },
    COMMON_ERROR: {
        pathName: appendUrlPrefix('/commonError'),
        pageKey: "commonError",
        Component: loadable(() => import("@rn/apps/post-sales/src/Common/CommonErrorPage/index"), { fallback: <ProgressView /> }),
    },
    REFUND_DELAY_INFO: {
        pathName: appendUrlPrefix('/refundDelayInfo'),
        pageKey: "refundDelayInfo",
        Component: loadable(() => import("@rn/apps/post-sales/src/Common/RefundDelayInfoPage"), { fallback: <ProgressView /> }),
    },
    COMMON_SUBMIT_REQUEST: {
        pathName: appendUrlPrefix('/submitRequest'),
        pageKey: "commonSubmitRequest",
        Component: loadable(() => import("@rn/apps/post-sales/src/Common/SubmitRequest"), { fallback: <ProgressView /> }),
    },
    SUBMIT_REQUEST_THANKYOU: {
        pathName: appendUrlPrefix('/submitRequestThankyou'),
        pageKey: "commonSubmitRequestThankYou",
        Component: loadable(() => import("@rn/apps/post-sales/src/Common/SubmitRequestThankYou"), { fallback: <ProgressView /> }),
    },
    POSTSALES_WEBVIEW: {
        pathName: appendUrlPrefix('/mpostsales/rnw/openWebView'),
        pageKey: "openWebView",
        Component: loadable(() => import("@rn/apps/post-sales/src/Common/WebViewWrapper"), { fallback: <ProgressView /> }),
    },
    REFUND_BREAKUP: {
        pathName: appendUrlPrefix('/refundBreakup'),
        pageKey: "refundBreakup",
        Component: loadable(() => import("@rn/apps/post-sales/src/Common/RefundBreakupPage"), { fallback: <ProgressView /> }),
    },
    REFUND_BREAKUP_V2: {
        pathName: appendUrlPrefix('/refundBreakupV2'),
        pageKey: "refundBreakupV2",
        Component: loadable(() => import("@rn/apps/post-sales/src/Common/RefundBreakUpPageV2"), { fallback: <ProgressView /> }),
    },
    COMMON_PRICE_BREAKUP : {
        pathName: appendUrlPrefix('/commonPaymentPriceBreakUp'),
        pageKey: "commonPaymentPriceBreakUp",
        Component: loadable(() => import("@rn/apps/post-sales/src/Common/PaymentPriceBreakup"), { fallback: <ProgressView /> }),
      },
    COMMON_WEB_VIEW : {
        pathName: appendUrlPrefix('/openPsWebView'),
        pageKey: "openPsWebView",
        Component: loadable(() => import("@rn/apps/post-sales/src/Common/WebViewWrapper"), { fallback: <ProgressView /> }),
    },
    CUSTOMER_HELP_AND_SUPPORT : {
        pathName: appendUrlPrefix('/customerHelpAndSupport'),
        pageKey: "customerHelpAndSupport",
        Component: loadable(() => import("@rn/apps/post-sales/src/contactus/index"), { fallback: <ProgressView /> }),
    },
    COMMON_REFUND_OUT_OF_TAT_FORM: {
        pathName: appendUrlPrefix('/refundOutOfTATForm'),
        pageKey: "refundOutOfTATForm",
        Component: loadable(() => import("@rn/apps/post-sales/src/Common/RefundOutOfTATForm"), { fallback: <ProgressView /> }),
    },
    COMMON_COMMUNICATION: {
        pathName: COMMON_URLS.COMMUNICATION,
        pageKey: 'communication',
        Component: loadable(() => import("@web/components/Communication"), { fallback: <ProgressView /> }),
    },
}

export default COMMON_PATHS;

