import React from 'react';
import {View, Text, StyleSheet} from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { useTheme } from '../../../theme/theme.context';
import { getFont } from '../../../PostSalesStyles';
import { getStatusStyle } from '../../details/RailBookingDetailsUtil';
import { getStaticData } from 'apps/post-sales/src/staticData/staticData';
import { PnrPaxStatusCardProps } from '../../types';
import isEmpty from 'lodash/isEmpty';
import getStyles from './PnrPaxStatusCardCss';
import { BOOKING_STATUS } from '../../details/constants';

const PnrPaxStatusCard = ({data} : PnrPaxStatusCardProps) => {
  const { psTheme } = useTheme();
  const { color } = psTheme;
  const { fsStyle, ...fonts } = getFont(true);
  const staticData = getStaticData();
  const styles = getStyles(fonts, color);
  const {passengerNameText} = staticData;
  return (
    <View>
      <View style={[styles.statusHeading, AtomicCss.marginBottom16]}>
        <Text style={styles.hedingStatusTxt}>{passengerNameText}</Text>
      </View>
      {!isEmpty(data) && data.map((item, index) => {
        return (
          <StatusList
            item={item}
            index={index}
            key={index}
            isLastElement={index === data.length - 1}
          />
        );
      })}
    </View>
  );
};

const StatusList = ({item, index, isLastElement}) => {
  const { psTheme } = useTheme();
  const { color } = psTheme;
  const { fsStyle, ...fonts } = getFont(true);
  const styles = getStyles(fonts, color);
  const {statusStyle, statusTextStyle, lastItemStyle} = getStatusStyle(styles, item, isLastElement);
  const {PaxName, status, statusType} = item || {};
  return (
    <View
      style={[
        AtomicCss.flexRow,
        AtomicCss.marginBottom20,
        styles[lastItemStyle],
      ]}>
      {!isEmpty(PaxName) && <Text style={[styles.paxName, status==BOOKING_STATUS.CAN ? styles.paxNameCancelled : {}]}>{PaxName}</Text>}
      <View style={[AtomicCss.flex1, {alignItems: 'flex-end'}]}>
        {!isEmpty(status) && status!=BOOKING_STATUS.CAN && <Text style={styles.statusReport}>{status}</Text>}
        <View style={statusStyle}>
          {!isEmpty(statusType) && <Text style={statusTextStyle}>{statusType}</Text>}
        </View>
      </View>
    </View>
  );
};



export default PnrPaxStatusCard;
