import { StyleSheet } from 'react-native';

export default (theme, fonts) => StyleSheet.create({
  title: {
    color: theme.color.black,
    fontSize: fonts.fontSize.font22,
    marginBottom: 30,
  },
  bullet: {
    width: 6,
    height: 6,
    marginRight: 6,
    backgroundColor: theme.color.black,
    borderRadius: 50,
  },
  noteWrapper: {
    borderRadius: 4,
    backgroundColor: theme.color.creamWhite,
    padding: 12,
    marginTop: 30,
    marginBottom: 60,
  },
  bottomWrapper: {
    marginHorizontal: -10,
  },
});
