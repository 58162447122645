import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useRef } from 'react';
import { Image, StyleSheet, Text, TextInput, TouchableOpacity, View, Animated } from 'react-native';
import Loader from '../../../../Common/SpinnerLoader/Loader';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { getStaticData } from '../../../../staticData/staticData';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import Checkbox from '../../../../Common/Checkbox';
import { LANG_AWARE } from '../../../HotelConstants';
import { BLACK_INFO_ICON, PAN_CARD_ICON } from '../../../HotelImageConstants';
import { usePanCardController } from './PanCardController';
import { useTheme } from '../../../../theme/theme.context';
import { PanCardProps, PanMessageProps } from './types';
import useEffectAfterMount from '../../../../Common/useEffectAfterMount';
import PanConstant from './PanConstant';

const PanMessage = (props: PanMessageProps) => {
  const fontStyle = getFont(true);
  const { fsStyle } = fontStyle;
  const isPanSavedMsg =
    props?.panSubmitMsg && props.panSubmitMsg.includes('(') && props.panSubmitMsg.includes(')');
  return (
    <Text
      style={[
        AtomicCss.flex1,
        fsStyle.font14,
        fontStyle.regularFontFamily,
        AtomicCss.lineHeight18,
        AtomicCss.marginBottom16,
        props.isPanSubmit ? {} : props.styles.infoColor,
        AtomicCss.lineHeight20,
      ]}
    >
      {isPanSavedMsg ? (
        <>
          <Text>{props.panSubmitMsg.slice(0, props.panSubmitMsg.indexOf('('))}</Text>
          <Text style={[fonts.boldFontFamily]}>
            {props.panSubmitMsg.slice(
              props.panSubmitMsg.indexOf('('),
              props.panSubmitMsg.indexOf(')'),
            ) + ')'}
          </Text>
          <Text>{props.panSubmitMsg.slice(props.panSubmitMsg.indexOf(')') + 1 )}</Text>
          {'  '}
        </>
      ) : (
        `${props.panSubmitMsg || props.instruction}   `
      )}
      <TouchableOpacity onPress={props.showOverlay}>
        <Image source={BLACK_INFO_ICON} style={props.styles.infoIconStyle} resizeMode={'cover'} />
      </TouchableOpacity>
    </Text>
  );
};

const PanCardDetail = (props: PanCardProps) => {
  const { panCardDetails } = props.bookingResponse || {};
  const { ctaList } = panCardDetails || {};
  const { actionList, instruction } = props.cardData || {};
  const [state, callbacks] = usePanCardController(props.bookingResponse, props.pageName);
  const {
    isPanVerified,
    isPanVerifing,
    showPanEditor,
    isChecked,
    panHolderName,
    errorText,
    panSubmitMsg,
    isSubmitting,
    isPanSubmit,
    isUserPanSaved,
  } = state || {};
  const { toggleCheckbox, onPanTextChange, onSubmit, changePanNumber } = callbacks;
  const fontStyle = getFont(true);
  const { fsStyle } = fontStyle;
  const { psTheme } = useTheme();
  const styles = createStyle(psTheme);

  const { panCardHeadingText, enterPanText, detectingPanText } = getStaticData(
    LANG_AWARE.MODIFICATION,
  );

  const inputLabelPositionAnim = useRef(new Animated.Value(20)).current;
  const inputLabelSizeAnim = useRef(new Animated.Value(16)).current;
  const inputLabelColorAnim = useRef(new Animated.Value(0)).current;

  const colorInterpolate = inputLabelColorAnim.interpolate({
    inputRange:[0, 1, 2],
    outputRange: [colors.textGrey , colors.azure, colors.red],
  });

  const onInpputFocus = useCallback(() => Animated.parallel([
    Animated.timing(
      inputLabelPositionAnim, {
        toValue: 0,
        duration: 300,
        useNativeDriver: false,
      }
    ),
    Animated.timing(
      inputLabelSizeAnim, {
        toValue: 14,
        duration: 300,
        useNativeDriver: false,
      }
    ),
    Animated.timing(
      inputLabelColorAnim, {
        toValue: 1,
        duration: 300,
        useNativeDriver: false,
      }
    ),
  ]).start(), []);

  useEffectAfterMount(() => {
    if (errorText) {
      inputLabelColorAnim.setValue(2);
    } else {
       inputLabelColorAnim.setValue(inputLabelColorAnim ? 1 : 0);
    }
  }, [errorText]);

  return (
    <View style={[AtomicCss.whiteCardNew, AtomicCss.marginHz12, AtomicCss.borderRadius16]}>
      <View style={[styles.headerSec, AtomicCss.flexRow, AtomicCss.alignCenter]}>
        <Image source={PAN_CARD_ICON} style={styles.headingIcon} />
        <Text
          style={[
            fontStyle.blackFontFamily,
            fsStyle.font16,
            AtomicCss.blackText,
            AtomicCss.lineHeight20,
          ]}
        >
          {panCardHeadingText}
        </Text>
      </View>
      <View style={styles.infoTopWrapper}>
        <PanMessage
          panSubmitMsg={panSubmitMsg}
          isPanSubmit={isPanSubmit}
          showOverlay={props.showOverlay}
          styles={styles}
          instruction={instruction}
        />
        {!isUserPanSaved && showPanEditor && (
          <View>
            <View style={[styles.inputContainer]}>
              <View style={[AtomicCss.flex1]}>
                <Animated.Text style={[errorText ? AtomicCss.redText : AtomicCss.blueText, {top: inputLabelPositionAnim, fontSize: inputLabelSizeAnim, color: colorInterpolate}]}>{enterPanText}</Animated.Text>
                <View style={styles.inputTextWrapper}>
                  <TextInput
                    maxLength={10}
                    onFocus={onInpputFocus}
                    autoCapitalize={'characters'}
                    onChangeText={onPanTextChange}
                    editable={!isPanVerifing}
                    style={[styles.inputText, fsStyle.font16, fontStyle.blackFontFamily]}
                  />
                  <Text style={[fsStyle.font10, AtomicCss.greyText, AtomicCss.paddingHz8]}>
                    {isPanVerifing ? detectingPanText : panHolderName}
                  </Text>
                </View>
                {<Text style={[AtomicCss.redText, fsStyle.font14]}>{errorText}</Text>}
              </View>
              {!isEmpty(ctaList) &&
                ctaList.map((cta: any) => {
                  if (cta.actionFamily === 'SubmitPanDetail') {
                    return (
                      <View style={[AtomicCss.paddingLeft10]}>
                        {isSubmitting ? (
                          <Loader />
                        ) : (
                          <TouchableOpacity
                            activeOpacity={0.7}
                            disabled={!isPanVerified}
                            onPress={() => { onSubmit(false); }}
                          >
                            <Text
                              style={[
                                fontStyle.boldFontFamily,
                                fsStyle.font16,
                                AtomicCss.marginBottom3,
                                isPanVerified ? AtomicCss.blueText : AtomicCss.greyText,
                              ]}
                            >
                              {cta.actionLabeltext}
                            </Text>
                          </TouchableOpacity>
                        )}
                      </View>
                    );
                  }
                })}
            </View>
            {isPanVerified &&
              !isEmpty(ctaList) &&
              ctaList
                .filter((cta: any) => cta.actionType === 'checkbox')
                .map((action: any) => (
                  <Checkbox
                    label={action.actionLabeltext}
                    labelStyle={[
                      AtomicCss.greyText,
                      styles.checkboxText,
                      fsStyle.font14,
                      fontStyle.mediumFontFamily,
                    ]}
                    checked={isChecked}
                    onChange={toggleCheckbox}
                  />
                ))}
          </View>
        )}
       {!isPanSubmit && <View style={[AtomicCss.flex1, AtomicCss.flexRow, AtomicCss.alignSelfFlexEnd]}>
          {
            isUserPanSaved && (
              <View style={AtomicCss.marginRight10}>
                {
                  !isSubmitting ?
                  (!isEmpty(ctaList) &&
                  ctaList.map((action: any) => {
                    if (action.actionFamily === PanConstant.SUBMIT_PAN_DETAIL) {
                      return (
                        <TouchableOpacity
                          onPress={() => {
                            onSubmit(true);
                          }}
                        >
                          <Text style={[AtomicCss.blueText, fsStyle.font16, fontStyle.boldFontFamily]}>
                            {action.actionLabeltext}
                          </Text>
                        </TouchableOpacity>
                      );
                    }
                  }))
                  :
                  <Loader  />
                }
              </View>
            )
          }
          {isUserPanSaved &&
            !isEmpty(ctaList) &&
            ctaList.map((action: any) => {
              if (action.actionFamily === PanConstant.CHANGE_PAN_DETAIL) {
                return (
                  <TouchableOpacity
                    onPress={changePanNumber}
                  >
                    <Text style={[AtomicCss.blueText, fsStyle.font16, fontStyle.boldFontFamily]}>
                      {action.actionLabeltext}
                    </Text>
                  </TouchableOpacity>
                );
              }
            })}
        </View>}
        {isUserPanSaved && errorText && <Text style={[AtomicCss.redText, fsStyle.font14]}>{errorText}</Text>}
      </View>
    </View>
  );
};

export default React.memo(PanCardDetail);

const createStyle = (theme: any) =>
  StyleSheet.create({
    headerSec: {
      paddingLeft: 12,
      paddingRight: 20,
      paddingTop: 20,
      paddingBottom: 8,
    },
    headingIcon: {
      width: 24,
      height: 24,
      marginRight: 10,
      backgroundColor: theme.color.grey2,
      borderRadius: 4,
    },
    infoTopWrapper: {
      paddingLeft: 48,
      paddingRight: 16,
      paddingBottom: 16,
    },
    infoIconStyle: {
      width: 14,
      height: 14,
      tintColor: theme.color.grey1,
    },
    infoColor: {
      color: theme.color.lightYello,
    },
    inputContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    checkboxText: {
      color: theme.color.textGrey,
      paddingTop: 3,
    },
    inputTextWrapper: {
      flexDirection: 'row',
      borderBottomColor: theme.color.grey10,
      marginTop: 2,
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottomWidth: 1,
      paddingBottom: 3,
    },
    inputText: {
      flex: 1,
      padding:0,
    },
  });
