import React from 'react';
import {ScrollView, Text, View} from 'react-native';
import getStyles from './HotelMamiCss';
import ChoosePAHX from './ChoosePAHX';
import {
  fillCheckInCheckOutData, getActionButton, getButtonStyle, getPaymentDueText,
  handleClickEvent, isIntlHotelBooking, isNotNullAndEmpty
} from '../utils/HotelBookingDetailUtil';
import Actions from '../../../navigation/postSalesNavigation';
import {getCommonHeaders} from '../../../utils/NetworkUtils';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import PropTypes from 'prop-types';
import HotelBookingDetailsConstant from '../HotelBookingDetailsConstant';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import CommonHeader from '../../details/components/CommonHeader'
import HotelBookingTrackingHelper from '../../HotelBookingTrackingHelper';
import { getStaticData } from '../../../staticData/staticData';
import { WHITE_LEFT_ARROW_ICON } from '../../HotelImageConstants';
import { getFont, themeColors } from '../../../PostSalesStyles';

const PAHX_SUBMIT_URL = 'https://supportz.makemytrip.com/api/payAtHotelNoShow';

class DetailsPAHX extends React.Component {
  static navigationOptions = {
    header: null
  };

  constructor(props) {
    super(props);
    this.fonts = getFont(true);
    this.styles = getStyles(this.fonts);
    this.radioSelectedText = true;
    this.trackPageName = isIntlHotelBooking(this.props.response?.lobCode)
      ? 'mob:customer support:Hotel International:PAHXNoShow'
      : 'mob:customer support:Hotel Domestic:PAHXNoShow';
  }

  componentDidMount() {
    HotelBookingTrackingHelper.trackLoadEvent(this.trackPageName, this.props.response);
  }


  render() {
    const staticData = getStaticData();
    const {hotelBookingInfo, paymentSummary} = this.props.response;
    return (
      <View style={{flex: 1}}>
        <CommonHeader
          headerText={staticData.detailsPAHXText.heading}
          imgSrc={WHITE_LEFT_ARROW_ICON}
          backPressHandler={this.onBackPress}
        />
        <ScrollView>
          <View>
            <View style={[this.styles.PAHXInfo, this.styles.MamiCard, this.styles.mar10Bt]}>
              <View style={[this.styles.impInfoRedOuter]}>
                <Text
                  style={[this.styles.impInfoRed, this.styles.lineHeight20]}
                >
                  {paymentSummary.confirmBySubmittingFormText || `Dear ${this.getPrimaryCustomerName(hotelBookingInfo)},${hotelBookingInfo.name} has
                  mentioned that you DID NOT SHOW UP at the hotel
                  on ${fillCheckInCheckOutData(hotelBookingInfo.checkInDate, 'ddd, DD MMM YYYY')}.
                  Please confirm by submitting the form below, otherwise your card will be
                  automatically charged
                  for ${getPaymentDueText(paymentSummary, this.props.response.paymentDetails)} penalty
                  in next 24 hours`}
                </Text>
              </View>
            </View>
            <View style={[this.styles.MamiCard, this.styles.mar10Bt]}>
              <ChoosePAHX
                response={this.props.response}
                radioSelector={this.getRadioButtonSelectionText}
              />
              <View
                style={isNotNullAndEmpty(this.props.cardList[0].actionList) ? getButtonStyle(this.props.cardList[0].actionList) : this.styles.hideView}
              >
                {getActionButton(this.props.cardList[0], this.props.response, this.handleClick)}
              </View>
            </View>
          </View>
        </ScrollView>
      </View>
    );
  }

  getPrimaryCustomerName(hotelBookingInfo) {
    const primaryCustomer = hotelBookingInfo.primaryCustomerDetails;
    return `${primaryCustomer.firstName} ${primaryCustomer.lastName}`;
  }

  onBackPress = () => {
    Actions.pop();
  };
  getRadioButtonSelectionText = (selectedText) => {
    if (selectedText === 'Yes') {
      this.radioSelectedText = true;
    } else {
      this.radioSelectedText = false;
    }
  };
  handleClick = (action) => {
    this.submitPAHXData(action);
  };


  async submitPAHXData(action) {
    const request = {
      bookingId: this.props.response.bookingID,
      customerCheckedIn: this.radioSelectedText
    };
    const response = await
      fetch(PAHX_SUBMIT_URL, {
        method: 'POST',
        body: JSON.stringify(request),
        headers: await getCommonHeaders(this.props.mmtAuth, this.props.loggingTrackInfo)
      });
    if (response.ok) {
      const body = await
        response.json();
      this.props.response.pahxResponse = body;
      handleClickEvent(this.props.cardList[0], action, this.props.response, HotelBookingDetailsConstant.REACT_HOTEL_PAHX_NO_SHOW_PAGE);
    } else {
      this.renderErrorView(action);
    }
  }

  renderErrorView = action => (
    <ErrorView
      showRetry
      onRetry={() => {
        this.submitPAHXData(action);
      }}
      buttonGradient={themeColors.gradientBtnColor}
    />);
}

DetailsPAHX.propTypes = {
  response: PropTypes.object.isRequired,
  mmtAuth: PropTypes.string.isRequired,
  cardList: PropTypes.array.isRequired,
  loggingTrackInfo: PropTypes.string.isRequired
};
export default DetailsPAHX;
