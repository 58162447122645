import React from 'react';
import {View, ScrollView, StyleSheet, Alert, Platform, BackHandler} from 'react-native';
import ViewControllerModule from '@mmt/legacy-commons/Native/ViewControllerModule';
import RecBlueBtn from '@mmt/legacy-commons/Common/Components/Buttons/RecBlueBtn';
import TdrSelectReason from '../TdrSelectReason/TdrSelectReason';
import AtomicCss from '../../commonStyles/AtomicCss';
import Actions from '../../../navigation/postSalesNavigation';
import StickyHeader from '../../components/StickyHeaderBack/StickyHeader';
import {createTdrCommitRequest, trainFromToSubTitleWithDate} from '../../details/RailBookingDetailsUtil';
import Breadcrumb from '../../../Common/Breadcrumb'
import OverlayAlert from '../../components/OverlayAlert/OverlayAlert';
import {getCommonHeaders, RAIL_TDR_URL} from '../../../utils/NetworkUtils';
import {isNetworkAvailable} from '@mmt/legacy-commons/Common/utils/AppUtils';
import {showShortToast} from '@mmt/legacy-commons/Common/Components/Toast';
import ProgressView from '@mmt/legacy-commons/Common/Components/Progress/ProgressView';
import RailBookingTrackingHelper from '../../details/RailBookingTrackEventHelper';
import {isEmpty} from 'lodash';
import { getStaticData } from '../../../staticData/staticData';

class FileTdrOption extends React.Component {
  static navigationOptions = {
    header: null
  };

  constructor(props) {
    super(props);
    this.state = {
      showOverlay: false,
      error: false,
      loadingRules: true,
      tdrRules: {},
      errorText: '',
      selectedReason: {}
    };
    this.tdrAddress = '';
    this.STEPS = ' ';
    this.TDR_OVERLAY_TITLE = ' ';
    this.HEADING = ' ';
    this.BOTTOM_BUTTON = ' ';
    this.SUB_TITLE_TEXT = ' ';
    this.POSITIVE_BTN_TEXT = ' ';
    this.NEGATIVE_BTN_TEXT = ' ';
    this.INT_CONN_ISSUE_MSG = ' ';
    this.SHOW_SHORT_TOAST = ' ';
  }

  componentWillMount() {
    BackHandler.addEventListener('hardwareBackPress', this.onHardBackPress);
    this.fetchTdrRules();
  }

  onHardBackPress = () => {
    if (Platform.OS === 'ios') {
      ViewControllerModule.popViewController(this.props.rootTag);
    } else {
      BackHandler.exitApp();
    }
    return false;
  };


  componentWillUnmount() {
    BackHandler.removeEventListener('hardwareBackPress', this.onHardBackPress);
  }


  buildRequest() {
    const segments = [];
    const segment = {};
    segment.partialPax = false;
    segment.segmentId = 1;
    segment.pnrNo = this.props.bookingDetails.train[0].pnrDetails[0].pnrNo;
    segments.push(segment);
    const request = {
      userId: 'MMT/MIMA',
      action: 'getRules',
      bookingId: this.props.bookingDetails.bookingID,
      segment: segments
    };
    return request;
  }

  async fetchTdrRules() {
    const staticData = getStaticData();
    const noIntMessage = staticData.errorText.notConnectedToTheInternetError;
    try {
      const hasNetwork = await isNetworkAvailable();
      if (!hasNetwork) {
        Alert.alert({noIntMessage});
        return;
      }
      const commonHeaders = await getCommonHeaders(this.props.mmtAuth, this.props.loggingTrackInfo);

      const response = await fetch(RAIL_TDR_URL, {
        method: 'POST',
        body: JSON.stringify(this.buildRequest()),
        headers: commonHeaders
      });

      if (response.ok) {
        const responseBody = await response.json();

        this.STEPS = responseBody.tdrLayoutResponse.tdrSelectReasonLayout.steps;
        this.TDR_OVERLAY_TITLE = responseBody.tdrLayoutResponse.tdrSelectReasonLayout.overlayTitle;
        this.HEADING = responseBody.tdrLayoutResponse.tdrSelectReasonLayout.headerText;
        this.BOTTOM_BUTTON = responseBody.tdrLayoutResponse.tdrSelectReasonLayout.bottomButton;
        this.SUB_TITLE_TEXT = responseBody.tdrLayoutResponse.tdrSelectReasonLayout.overlayAlert.subTitleText;
        this.POSITIVE_BTN_TEXT = responseBody.tdrLayoutResponse.tdrSelectReasonLayout.overlayAlert.positiveBtnText;
        this.NEGATIVE_BTN_TEXT = responseBody.tdrLayoutResponse.tdrSelectReasonLayout.overlayAlert.negativeBtnText;
        this.INT_CONN_ISSUE_MSG = responseBody.tdrLayoutResponse.tdrSelectReasonLayout.intConnIssueMsg;
        this.SHOW_SHORT_TOAST = responseBody.tdrLayoutResponse.tdrSelectReasonLayout.showShortToast;

        this.setState({
          loadingRules: false,
          tdrRules: responseBody,
          errorText: responseBody.errorMessages
        });
      } else {
        showShortToast(this.SHOW_SHORT_TOAST);
        Actions.pop();
      }
    } catch (error) {
      RailBookingTrackingHelper.trackErrorEvent('TdrOption');
      showShortToast(this.SHOW_SHORT_TOAST);
      Actions.pop();
    }
  }


  render() {
    const staticData = getStaticData();
    if (this.state.loadingRules) {
      return (<ProgressView message={staticData.loadingText ? staticData.loadingText : "" } />);
    }

    return (
      <View style={styles.CancellationWrapper}>
        <StickyHeader heading={this.HEADING} subheading={trainFromToSubTitleWithDate(this.props.bookingDetails.train)} />
        <Breadcrumb activeStep={0} data={this.STEPS} />
        <ScrollView style={AtomicCss.greyBg}>
          <View style={[AtomicCss.marginBottom10, AtomicCss.marginTop10]}>
            <TdrSelectReason bookingDetails={this.props.bookingDetails} updateSelectedReason={this.updateSelectedReason} trainCancelled={this.props.trainCancelled} tdrRules={this.state.tdrRules} loadingRules={this.state.loadingRules} />
          </View>
        </ScrollView>
        {!isEmpty(this.state.selectedReason) && <RecBlueBtn label={this.BOTTOM_BUTTON} onPress={this.handleClick} />}
        {this.state.showOverlay &&
        <OverlayAlert
          positiveBtnText={this.POSITIVE_BTN_TEXT}
          negativeBtnText={this.NEGATIVE_BTN_TEXT}
          positiveBtnOnTap={this.fileTdr}
          negativeBtnOnTap={this.bgTapped}
          titleText={this.TDR_OVERLAY_TITLE}
          subTitleText={this.SUB_TITLE_TEXT}
          bgTapAction={this.bgTapped}
        />}

      </View>
    );
  }

  bgTapped = () => {
    this.setState({
      showOverlay: false
    });
  };

  createTdrCommitRequest = () => createTdrCommitRequest(this.props.bookingDetails, this.state.selectedReason, this.selectedPax);


  handleClick= () => {
    if (this.state.selectedReason && this.state.selectedReason.partialPossible) {
      RailBookingTrackingHelper.trackClickEvent('TdrOption', this.props.bookingDetails, 'SelectPassenger');
      Actions.tdrSelectPassengers({
        bookingDetails: this.props.bookingDetails,
        selectedReason: this.state.selectedReason,
        tdrAddress: this.tdrAddress,
        mmtAuth: this.props.mmtAuth,
        loggingTrackInfo: this.props.loggingTrackInfo,
        tdrRules: this.state.tdrRules
      });
    } else if (this.state.selectedReason.certificateRequired) {
      RailBookingTrackingHelper.trackClickEvent('TdrOption', this.props.bookingDetails, 'CertificateRequired');
      Actions.tdrForm({
        request: this.createTdrCommitRequest(),
        selectedReason: this.state.selectedReason,
        bookingDetails: this.props.bookingDetails,
        tdrAddress: this.tdrAddress,
        mmtAuth: this.props.mmtAuth,
        loggingTrackInfo: this.props.loggingTrackInfo,
        tdrRules: this.state.tdrRules

      });
    } else {
      this.setState({
        showOverlay: true
      });
    }
  }

  fileTdr = () => {
    RailBookingTrackingHelper.trackClickEvent('TdrOption', this.props.bookingDetails, 'FilingTdrAndMoveToThxPage');
    Actions.tdrThankYou({
      request: this.createTdrCommitRequest(),
      selectedReason: this.state.selectedReason,
      bookingDetails: this.props.bookingDetails,
      tdrAddress: this.tdrAddress,
      tdrRules: this.state.tdrRules
    });
  }


  updateSelectedReason= (rule, tdrAddress) => {
    this.setState({
      selectedReason: rule
    });
    this.tdrAddress = tdrAddress;
  }
}


const styles = StyleSheet.create({
  CancellationWrapper: {
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: '#f2f2f2'
  },
  PartCancellationHead: {
    backgroundColor: '#ffffff',
    paddingVertical: 18,
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#ddd'
  }
});

export default FileTdrOption;
