import React from 'react';
import Actions from '../../../navigation/postSalesNavigation';
import PropTypes from 'prop-types';
import {Image, ScrollView, Text, View} from 'react-native';
import Header from '@mmt/legacy-commons/Common/Components/Header/Header';
import styles from '../../styles/AcmeMamiCss';


class AcmeSchedule extends React.Component {
  static navigationOptions = {
    header: null
  }

  render() {
    return (
      <View style={[styles.whiteContainer, styles.transperantBgrnd]}>
        <Header
          title = "Schedule"
          onBackPress = {this.onBackPress}
          type = "Elevated"
        />
        <ScrollView>
          <View style={styles.PolicyDetailsContainer}>
            {this.showSchedule()}
          </View>
        </ScrollView>
      </View>
    );
  }

  onBackPress = () => {
    Actions.pop();
  }

  showSchedule() {
    const views = [];
    this.props.response.acmeBookingInfo.acmeBookingDetails[0].acmeAdditionalInfo.schedule.forEach((scheduleObject) => {
      this.createScheduleView(views, scheduleObject);
    });
    return views;
  }

  createScheduleView(views, scheduleObject) {
    views.push(<View style={[styles.mar15Bt, styles.flexRow]} key={scheduleObject}>
      <View style={styles.ItemDisc} />
      <Text>{scheduleObject}</Text>
    </View>);
  }
  onBackPressed = () => {
    Actions.pop();
  }
}
AcmeSchedule.propTypes = {
  response: PropTypes.object.isRequired
};
export default AcmeSchedule;
