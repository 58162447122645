import { BookingIdPrefixes, EnumInterfce } from "./types";

export const baseUrl = "https://imgak.mmtcdn.com/mima/images/";
const desktopBaseUrl = `${baseUrl}Desktop/`
export const DESKTOP_ESCALATION_LANDING_URL = "https://supportz.makemytrip.com/Mima/Escalate";
export const DESKTOP_ESCALATION_TO_TEAM = "https://supportz.makemytrip.com/Mima/EscalationToTeam";

export const ESCALATE_IMAGES: EnumInterfce = {
    CLOSE_ICON: `${baseUrl}mobile/crossIcon1.webp`,
    BACK_ARROW: `${baseUrl}mobile/backArrowBlack.webp`,
    SOMETHING_WENT_WRONG: `${baseUrl}Bus/notExpectedError.png`,
    FLIGHT_ICON: `${desktopBaseUrl}escalation_flights.webp`,
    HOTEL_ICON: `${desktopBaseUrl}escalation_hotels.webp`,
    CAR_ICON: `${desktopBaseUrl}escalation_cab.webp`,
    BUS_ICON: `${desktopBaseUrl}escalation_bus.webp`,
    HOLIDAY_ICON: `${desktopBaseUrl}escalation_holidays.webp`,
    TRAIN_ICON: `${desktopBaseUrl}escalation_trains.webp`,
    VISA_ICON: `${desktopBaseUrl}escalation_visa.png`,
    PLUS_ICON: `${desktopBaseUrl}escalation_plus.png`,
    MINUS_ICON: `${desktopBaseUrl}escalation_minus.png`,
  
    SELFDRIVE_ICON: `${desktopBaseUrl}escalation_selfdrive.webp`,
    TGS_ICON: `${desktopBaseUrl}escalation_trains.webp`,
    GIFTCARD_ICON: `${desktopBaseUrl}escalation_giftCard.webp`,
    ACME_ICON: `${desktopBaseUrl}escalation_holidays.webp`,
};


export const LOBTYPE = {
  FLIGHT : 2,
  HOTEL: 1,
  BUS: 3,
  RAIL: 4,
  ACME: 5,
  CAB: 6,
  VISA: 7,
  DEFAULT : 0,
}

export const LOB_TYPE : EnumInterfce= {
  HOTEL: "HOTEL",
  BUS: "BUS",
  CAR: "CAR",
  TRAIN: "TRAIN",
  VISA: "VISA",
  FLIGHT: "FLIGHT",
  HOLIDAY: "HOLIDAY",
  CRUISE: "CRUISE",
  ACME: "ACME",
  GIFTCARD: "GIFTCARD",
  SELFDRIVE: "SELFDRIVE",
  HOLIDAY_PLANNING:"HOLIDAYPLANNING",
  TRIP_GUARANTEE:"TGS",
  INSURANCE:"INSURANCE"
};

export const BOOKING_STATUS : EnumInterfce = {
  UPCOMING: "UPCOMING",
  COMPLETED: "COMPLETED",
  CANCELLED: "CANCELLED",
  FAILED: "FAILED",
  INPROGRESS: "INPROGRESS",
  CLOSED: "CLOSED",
  PENDING: "PENDING" 
};

export const BOOKING_ID_PREFIXES : BookingIdPrefixes = {
  HOTEL: ["MH", "NM", "NH"],
  BUS: ["NU"],
  CAR: ["NC"],
  TRAIN: ["NR"],
  VISA: ["NV"],
  FLIGHT: ["NF", "NN"],
  HOLIDAY: ["NL"],
  CRUISE: ["CR"],
  ACME: [],
  SELFDRIVE : ["SD"]
};


export const BOTTOM_SHEET_TYPE = {
  UNABLE_TO_ESCALATE: "unableToConnect",
  CREATE_SUPPORT_REQUEST: "createSupportRequest",
  SUCCESS : "success",
  ERROR : "error"
}

export const BUTTON_ACTION_TYPE = {
  BUTTON_FILLED : "button_filled", 
  BUTTON_HIGHLIGHT : "button_highlight" , 
  BUTTON_SUBMIT : "submit", 
  BUTTON_CANCEL : "cancel",
  CREATE_SUPPORT_REQUEST : "create_request",
  CALL_NOW : "call_now",
};

export const BUTTON_ACTION_ID = {
  PRIMARY : "primary",
  SECONDARY : "secondary", 
}

export const PAGE_SOURCE = {
  ABOUT_US: 0,
  SEO: 1
}

export const ESCALATE_STATIC_TEXT = {
  SOMETHING_WENT_WRONG: "Something Went Wrong",
  ERROR_MESSAGE_SUBHEADING: "We apologize for the inconvenience caused to you. Please close this window and try again.",
  YOUR_TRIPS : "Your Trips",
  YOUR_TRIPS_SUBHEADING : "Select Bookings to escalate",
  SELECT : "Select"
};

export const ESCALATE_ROUTES = {
  ESCALATE : "/Escalate",
  ESCALATE_MY_TRIPS : `/EscalateMyTrips`,
  ESCALATE_TO_TEAM_PAGE : `/EscalationToTeam`
}

export const ESCALATE_PAGE_CARD_TYPE = {
  MY_TRIPS_CARD : "mytrips",
  CUSTOMER_CARE_CARD : "customerCare",
  ESCALATE_CARD : "escalate"
}