import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
import { Platform, StyleSheet } from 'react-native';
import {colors} from '@mmt/legacy-commons/Styles/globalStyles';

const getStyles = (fonts) => {
  return StyleSheet.create({
    myBizPaymentPendingCard: {
      backgroundColor: '#fff',
      borderRadius: 10,
      marginHorizontal: 12,
      marginBottom: 12,
      ...getPlatformElevation(2),
    },
    cardHeader: {
      padding: 20,
      borderBottomWidth: 1,
      borderColor: '#E7E7E7',
    },
    cardContent: {
      padding: 20,
    },
    iconDueCalendar: {
      width: 32,
      height: 40,
      resizeMode: 'cover',
    },
    innerContentWrap: {
      flexDirection: 'row',
      paddingLeft: 15,
    },
    innerContent: {
      marginLeft: -17,
    },
    dashedLines: {
      borderColor: '#f0d3a5',
      borderWidth:1,
      borderStyle: 'dashed',
      position: 'relative',
      left: 16,
      borderRadius: Platform.OS == 'android' ? 1 : 0,
    },
  
    paymentStepContent: {
      width: '87%',
      paddingBottom: 25,
    },
  });
}

export default getStyles;

export const getHtmlStyles = (fonts) => {
  return {
    span: {
      color: colors.defaultTextColor,
      ...fonts.font14,
      fontFamily: fonts.regular,
      lineHeight: 20,
    },
    b: {
      fontFamily: fonts.bold,
      color: 'red'
    }
  };
}