import _ from 'lodash';
import React from 'react';
import { Image, Text, TouchableOpacity, View } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getStaticData } from '../../../../../staticData/staticData';
import { GREY_STAR_ICON, MYSAFETY_ICON, REWARDS_ICON, SUPPORT_ICON } from '../../../../HotelImageConstants';
import HotelBookingDetailsConstant from '../../../HotelBookingDetailsConstant';
import { getNumberOfGuest, getStayDatesText, handleClickEvent } from '../../../utils/HotelBookingDetailUtil';
import createStyles from './HotelTopCardCss';
import BankOfferCard from '../../BankOfferCard/BankOfferCard'
import BottomClickableCard from '../../BottomClickableCard';
import isEmpty from 'lodash/isEmpty'
import BaseButton, { BUTTON_VARIANT } from 'apps/post-sales/src/Common/BaseButton';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import HotelBookingTrackingHelper from 'apps/post-sales/src/hotel/HotelBookingTrackingHelper';
import { copyToClipboard } from 'apps/post-sales/src/Common/commonUtil';
import { showLongToast } from '@mmt/legacy-commons/Common/Components/Toast';
import AltAccoRatingComponent from '../../../../Common/altAccoRatingComponent/altAccoRatingComponent';
import ButtonWrapper from 'apps/post-sales/src/Common/ButtonWrapper';

const HotelTopCard = ({ rating, category, showAltAccoRating, propertyName, hotelDestinationText, imgSrc, mmtValueStayImgUrl, corporateMMTValueStayImgUrl, mmtvalueStays, mySafety, response, showHotelDetails, card, pageName, handlePAHCard, handleMessageHost, showBHFBanner, showWriteReviewBanner, showBanner, bhfBanner, topStatusColorStyle, instructions, title, onBHFBannerClick, onWriteAReviewClick }) => {
  const { fsStyle, ...fonts } = getFont(true);
  const staticData = getStaticData();
  const mmtValueStaysImgURI = mmtValueStayImgUrl || 'https://imgak.mmtcdn.com/mima/images/value-stays.png';
  const myBizAssuredImgURI = corporateMMTValueStayImgUrl || 'https://imgak.mmtcdn.com/Midoffice/Corporate/myBizAssured_logo_m.png';
  const { isDiscountingCampaign = false, discountingCampaignIconUrl, isLuxuryHotel, luxTagImg, isMyBizAssured, hostDetails, hotelBookingInfo, isRTBBooking, rtbStatus } = response || {}
  const discountingCampaignImageUrl = discountingCampaignIconUrl || `https://promos.makemytrip.com/sale_images/limited_time_sale_2x.png`;
  const { staffDetails } = !isEmpty(hotelBookingInfo) ? hotelBookingInfo : {}
  const { hosts, sections } = !isEmpty(staffDetails) ? staffDetails : {}
  const { hosts: hostsSection } = sections || {};
  let enableHostChat = null, imageURI = null, description = null, chatURL = null;
  if (hosts) {
    ({ enableHostChat, imageURI, description, chatURL } = {...hosts[0], ...hostDetails });
  }
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme)

  const renderStar = () => {
    let stars = [];
    const activeState = rating;
    for (let i = 0; i < 5; i++) {
      if (i < activeState) {
        stars.push(
          <Image style={[styles.starStyle, styles.activeStyle]} source={GREY_STAR_ICON} />
        );
      } else {
        stars.push(
          <Image style={[styles.starStyle, styles.disabledStyle]} source={GREY_STAR_ICON} />
        );
      }
    }
    return <View style={[AtomicCss.flexRow, AtomicCss.marginTop0]}>{stars}</View>;
  };

  const getGuestsText = (totalGuest) => {
    return totalGuest > 1 ? staticData.hotelMainCardText.additionalText.guestsText : staticData.hotelMainCardText.additionalText.guestText;
  }

  const handleClick = (action) => {
    handleClickEvent(card, action, response, pageName);
  };

  const openHotelPhotos = () => {
    handleClick({ actionFamily: HotelBookingDetailsConstant.ACTION_HOTEL_PHOTOS });
  }

  const onCopyOfferCode = async (offerCode) => {
    const { copiedToClipboardText } = getStaticData(true);
    HotelBookingTrackingHelper.trackClickEvent(pageName, HotelBookingDetailsConstant.BANK_COUPON_COPIED, response);
    const copyStatus = await copyToClipboard(offerCode);
    if (copyStatus) {
      showLongToast(copiedToClipboardText);
    }
  };

  const renderCheckinDetails = (instruction) => {
    if (!isEmpty(instruction)) {
      return (<View>
        <Text style={[
          AtomicCss.blackFont,
          AtomicCss.font16,
          AtomicCss.blackText,
          AtomicCss.lineHeight20,
        ]}>{title}</Text>
        {
          instruction.map((text, index) => (
            <View key={index} style={[AtomicCss.flexRow, AtomicCss.marginTop10r]}>
              <Text style={styles.bullet}></Text>
              <Text style={[
                AtomicCss.font14,
                AtomicCss.defaultText,
              ]}>{text}</Text>
            </View>
          ))
        }
      </View>)
    }
    return null
  }

  const renderWithoutHotelDetails = () => {
    return (
      <View style={[AtomicCss.flexRow, AtomicCss.marginBottom8]}>
        <View style={[AtomicCss.marginRight18, AtomicCss.flex1]}>
          <View style={[AtomicCss.flexRow, AtomicCss.flex1, AtomicCss.flexWrap, AtomicCss.marginBottom4]}>
            <Text style={[fsStyle.font20, fonts.blackFontFamily, AtomicCss.blackText, AtomicCss.lineHeight28]}>
              {propertyName}
            </Text>
          </View>
          <Text style={[fsStyle.font14, AtomicCss.midGreyText, AtomicCss.lineHeight16]}>
            {hotelDestinationText}
          </Text>
          <View style={[AtomicCss.marginTop16]}>
            <Text style={[fsStyle.font14, fonts.boldFontFamily, AtomicCss.defaultText, AtomicCss.lineHeight16]}>{getStayDatesText(response)}
              {!_.isEmpty(response.hotelBookingInfo.totalGuestBreakupText) && <Text style={[fonts.regularFontFamily, AtomicCss.midGreyText]}>{` • ${getNumberOfGuest(response)} ${getGuestsText(getNumberOfGuest(response))}`}</Text>}
            </Text>
          </View>
        </View>
        <TouchableOpacity onPress={() => openHotelPhotos()}>
          <Image style={styles.hotelImg} source={imgSrc} />
        </TouchableOpacity>
      </View>
    )
  }

  const renderWithHotelDetails = () => {
    const { paymentBreakup, installmentDueTime, showBankOfferCard = false } = response || {};
    const { bankOfferCard } = paymentBreakup || {}
    return (
      <>
        <View style={[AtomicCss.flexRow]}>
          <View style={[AtomicCss.marginRight18, AtomicCss.flex1]}>
            <View>
              {(mmtvalueStays || mySafety || isLuxuryHotel || isMyBizAssured || isDiscountingCampaign) && (
                <View style={[AtomicCss.flexRow, AtomicCss.marginBottom4, AtomicCss.flexWrap]}>
                  {(mmtvalueStays || isMyBizAssured) && (
                    <View style={[AtomicCss.marginRight8, AtomicCss.marginBottom4]}>
                      <Image
                        source={{
                          uri: isMyBizAssured ? myBizAssuredImgURI : mmtValueStaysImgURI,
                        }}
                        style={styles.ValueStaysImg}
                      />
                    </View>
                  )}
                  {isDiscountingCampaign && discountingCampaignImageUrl && (
                    <View style={[AtomicCss.marginRight8, AtomicCss.marginBottom4]}>
                      <Image source={{uri: discountingCampaignImageUrl}} style={styles.discountingCampaingnImg} />
                    </View>
                  )}
                  {isLuxuryHotel && luxTagImg && (
                    <View style={[AtomicCss.marginRight8, AtomicCss.marginBottom4]}>
                      <Image
                        source={{
                          uri: luxTagImg,
                        }}
                        style={styles.luxImg}
                        resizeMode="contain"
                      />
                    </View>
                  )}
                  {mySafety && (
                    <View style={AtomicCss.marginRight8}>
                      <Image source={MYSAFETY_ICON} style={styles.mySafetyImg} />
                    </View>
                  )}
                </View>
              )}
              <View style={AtomicCss.marginBottom12}>
                <Text
                  style={[
                    AtomicCss.font24,
                    AtomicCss.blackFont,
                    AtomicCss.blackText,
                    AtomicCss.lineHeight28,
                  ]}
                >
                  {propertyName}
                </Text>
              </View>
              {rating > 0 && !showAltAccoRating && <View style={AtomicCss.marginBottom12}>
                {renderStar()}
              </View>}
              {showAltAccoRating && !!category &&
                <AltAccoRatingComponent
                  category={category}
                  customStyles={AtomicCss.marginBottom8}
                />
              }
            </View>
            <Text style={[AtomicCss.font14, AtomicCss.midGreyText, AtomicCss.lineHeight16, AtomicCss.regularTextFont]}>
              {hotelDestinationText}
            </Text>
          </View>
          <TouchableOpacity onPress={openHotelPhotos}>
            <Image style={styles.hotelImg} source={imgSrc} />
          </TouchableOpacity>
        </View>

        {isRTBBooking && rtbStatus === HotelBookingDetailsConstant.AWAITED && enableHostChat && hostsSection && !isEmpty(hostsSection.title) &&
          <React.Fragment>
            <View style={[styles.topCardActionWrapper, styles.messageHostWrapper]}>
              <View style={[AtomicCss.flex1]}>
                <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
                  <Image source={{ uri: imageURI }} style={styles.careTakerImg} />
                  <View style={[AtomicCss.flex1]}>
                    <Text style={[styles.topCardInfoText, styles.messageHostInfoText]}>
                      {hostsSection.title} {hostsSection.subTitle}
                    </Text>
                  </View>
                </View>

                {!isEmpty(description) &&
                  <Text style={[AtomicCss.font14, AtomicCss.defaultText, AtomicCss.regularFont, AtomicCss.marginTop5, AtomicCss.lineHeight15]}>
                    {description}
                  </Text>
                }
              </View>
            </View>
            <View style={AtomicCss.marginTop10}>
              {!isEmpty(chatURL) &&
                <ButtonWrapper 
                  btnText={HotelBookingDetailsConstant.MESSAGE_HOST}
                  clickHandler={() => handleMessageHost(chatURL)}
                />
              }
            </View>
          </React.Fragment>
        }

        {(paymentBreakup?.bnplCardChargeInfo && rtbStatus !== HotelBookingDetailsConstant.AWAITED) && (
          <>
            <View style={[styles.topCardActionWrapper, styles.paymentDueWrapper]}>
              <View style={[AtomicCss.flex1, AtomicCss.marginRight8]}>
                <Text style={[styles.topCardInfoText, styles.paymentDueInfoText]}>{installmentDueTime}</Text>
                <Text style={[AtomicCss.font14, AtomicCss.defaultText, AtomicCss.regularFont, AtomicCss.lineHeight15]}>
                  {paymentBreakup.paymentBreakupHeading}
                </Text>
              </View>
              {
                !showBankOfferCard &&
                  <ButtonWrapper 
                    btnText={`${staticData.hotelMainCardText.additionalText.pay}  ${paymentBreakup.paymentBreakupTotalPrice.value} `}
                    clickHandler={handlePAHCard}
                    buttonWidth='dynamic'
                  />
              }
            </View>
            { showBankOfferCard && <BankOfferCard bankOfferCard={bankOfferCard} clickPayNow={handlePAHCard} copyOfferCode={onCopyOfferCode}/> }
          </>
        )}

        {(showBHFBanner || showWriteReviewBanner || showBanner) && (
          <React.Fragment>
            <View style={styles.seperator}>
              <View style={[styles.semiCircle, styles.leftSemiCircle, topStatusColorStyle]} />
              <View style={styles.dashedLineWrapper}>
                <View style={styles.dashedLine} />
              </View>
              <View style={[styles.semiCircle, styles.rightSemiCircle, topStatusColorStyle]} />
            </View>
            <View>
              {showBHFBanner && (
                <BottomClickableCard
                  onPress={onBHFBannerClick}
                  pageName={pageName}
                  title={bhfBanner.cardText}
                  subTitle={bhfBanner.cardSubText}
                  icon={SUPPORT_ICON}
                  iconStyle={styles.supportIcon}
                />
              )}
              {showBHFBanner && showWriteReviewBanner && (
                <View style={[styles.dividor, AtomicCss.marginTop10, AtomicCss.marginBottom10]} />
              )}
              {showWriteReviewBanner && (
                <BottomClickableCard
                  onPress={onWriteAReviewClick}
                  pageName={pageName}
                  title={
                    response.isCorporateBooking
                      ? staticData.hotelMainCardText.additionalText.reviewHeadingCorporateText
                      : staticData.hotelMainCardText.additionalText.reviewHeadingText
                  }
                  subTitle={
                    response.isCorporateBooking
                      ? staticData.hotelMainCardText.additionalText.reviewSubHeadingCorporateText
                      : staticData.hotelMainCardText.additionalText.reviewSubHeadingText
                  }
                  icon={REWARDS_ICON}
                  iconStyle={styles.rewardsIcon}
                />
              )}
            </View>
            {showBanner && (showWriteReviewBanner || showBHFBanner) && (
              <View style={[styles.dividor, AtomicCss.marginTop10, AtomicCss.marginBottom10]} />
            )}
            {showBanner && renderCheckinDetails(instructions)}
          </React.Fragment>
        )}
      </>
    );
  }

  return (
    showHotelDetails ? renderWithHotelDetails() : renderWithoutHotelDetails()
  );
}
export default HotelTopCard;