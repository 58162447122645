import React from 'react';
import {View, Text, Image} from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import styles from './SecurityCardCss';
import Button from '../../../../Common/Button';
import ZoomCarConstant from "../../../utils/ZoomCarConstants";
import {INFO_ICON} from '../../../SelfDriveImageConstant';
import {isEmpty} from 'lodash';
import BasePage from '../../../../Common/PostSalesBasePage';
import ZoomCarConstants from "../../../utils/ZoomCarConstants";
import {handleActionClicks} from "../../../utils/SelfDriveBookingDetailUtil";
import { backPressHandler } from '../../../../utils/PostSaleUtil';
class SecurityCard extends BasePage {
    constructor(props) {
        super(props);
        if(!isEmpty(this.props.depositDetails.ctaInfo) && this.props.depositDetails.ctaInfo.key === ZoomCarConstant.CTA_INFO){
            this.state = {
                activeState: ZoomCarConstant.ACTION_PAY_NOW_CTA
            }
        } else {
            this.state = {
                activeState: ''
            }
        }
    }
    render() {
        let securityAmount = this.props.depositDetails.securityPriceBreakup && this.props.depositDetails.securityPriceBreakup.filter(pay => pay.key === ZoomCarConstant.SECURITY_DEPOSIT_KEY)[0]
        if (isEmpty(securityAmount)) {
            return;
        }
        return (
            <View style={[AtomicCss.whiteBg, AtomicCss.marginBottom10]}>
                <View style={[styles.CardContentFull]}>
                    <View style={styles.cardHeadingWrapper}>
                        <Text style={styles.cardHeading}>{this.props.depositDetails.depositHeader}</Text>
                    </View>
                    <View style={styles.cardInnerSection}>
                        <View style={[[styles.rowSection, AtomicCss.flexRow]]}>
                            <Text
                                style={[AtomicCss.flex1, AtomicCss.blackText, AtomicCss.boldFont, AtomicCss.font14]}>{securityAmount.label}</Text>
                            <Text
                                style={[AtomicCss.flex1, AtomicCss.blackText, AtomicCss.boldFont, AtomicCss.font14, AtomicCss.alignRight]}> &#8377;  {securityAmount.amount}</Text>
                        </View>
                        { this.props.depositDetails.depositDescription && <View style={styles.InfoWrapper}>
                            <Image style={styles.infoIconStyle} source={INFO_ICON}/>
                            <Text
                                style={[AtomicCss.defaultText, AtomicCss.font14, AtomicCss.regularFont, AtomicCss.flex1]}>
                                {this.props.depositDetails.depositDescription}
                            </Text>
                        </View>}
                    </View>
                    <View style={styles.refundBtnWrapper}>

                        {this.props.depositDetails.securityPriceBreakup && this.props.depositDetails.securityPriceBreakup.map((pay, key) => {
                            if (pay.key === ZoomCarConstant.SECURITY_DEPOSIT_KEY) {
                                return;
                            }
                            let color = AtomicCss.darkGreyText;
                            if (pay.key === ZoomCarConstant.OUTSTANDING_AMOUNT) {
                                color = styles.orangeTxt;
                            } else if (pay.key === ZoomCarConstant.GET_REFUND_AMOUNT) {
                                color = styles.greenTxt;
                            }
                            return (<View key={key}
                                          style={[AtomicCss.flexRow, AtomicCss.spaceBetween, AtomicCss.marginBottom10]}>
                                <Text style={[AtomicCss.font16, AtomicCss.blackFont, color]}>{pay.label}</Text>
                                <Text
                                    style={[AtomicCss.font16, AtomicCss.blackFont, color]}>&#8377;  {pay.amount}</Text>
                            </View>)
                        })
                        }

                        {this.props.depositDetails.ctaInfo && <View style={styles.buttonWrapper}>
                            <Button btnTxt={this.props.depositDetails.ctaInfo.label}
                                    btnBg="gradientBtn"
                                    clickHandler={this.handleClick}
                                    btnClickAction={this.state.activeState}
                            />
                        </View>}

                    </View>
                </View>
            </View>
        );
    }
    handleClick = (e) => {
        if(this.state.activeState === ZoomCarConstant.ACTION_PAY_NOW_CTA){
            handleActionClicks('', ZoomCarConstants.ACTION_PAY_NOW_CTA, this.props.response, 'total paid card').then(r => console.log('clicked event'));
        } else {
            backPressHandler();
        }
    }
}

export default SecurityCard;
