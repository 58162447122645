import React from 'react';
import { View, Text, StyleSheet, Image, TouchableOpacity, NativeModules } from 'react-native';
import AtomicCss from '../../commonStyles/AtomicCss';
import { LIVE_TRAIN_ICON, LINE_ARROW_ICON } from '../../Utils/RailImageConstant';
import { handleRailClickEvent, openLiveTrainStation } from '../../details/RailBookingDetailsUtil';
import { LiveTrackingInfoProps } from '../../types';
import { getStaticData } from '../../../staticData/staticData';
import isEmpty from 'lodash/isEmpty';
import { getFont } from '../../../PostSalesStyles';
import { useTheme } from '../../../theme/theme.context';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import { getGdprData, shouldShowGdprBlockerScreen } from '../../../utils/PostSaleUtil';

const LiveTrackingInfo = ({ response }: LiveTrackingInfoProps) => {
  const staticData = getStaticData();
  let {liveTrainUrl} = response || {};
  const { trackLiveStatusText, gdprText } = staticData;
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme } = useTheme();
  const { color } = psTheme;
  const styles = getStyles(fonts, color);
  return (
    <>
      <TouchableOpacity
        onPress={async () => {
          const { UserSessionModule } = NativeModules;
          const showGdprBlockerScreen = await shouldShowGdprBlockerScreen();
          if (showGdprBlockerScreen) {
            const { lobs: { trains = 'Trains'} = {} } = gdprText || {}
            UserSessionModule.openGDPRBlockerScreen(trains, null, getGdprData(gdprText));
          }else{
            if (!isEmpty(liveTrainUrl)) {
              GenericModule.openDeepLink(liveTrainUrl);
            } else {
              openLiveTrainStation(response);
            }
          }
        }}
        style={[AtomicCss.flexRow, AtomicCss.alignCenter]}
      >
        <View style={[AtomicCss.flex1, AtomicCss.flexRow, AtomicCss.alignCenter]}>
          <Image style={styles.liveTrain} source={LIVE_TRAIN_ICON} />
          {!isEmpty(trackLiveStatusText) && (
            <Text style={styles.textStyle}>{trackLiveStatusText}</Text>
          )}
        </View>
        <Image style={[styles.lineArrow]} source={LINE_ARROW_ICON} />
      </TouchableOpacity>
    </>
  );
};

const getStyles = (fonts, color) =>
  StyleSheet.create({
    liveTrain: {
      width: 32,
      height: 32,
    },
    lineArrow: {
      height: 14,
      width: 27,
    },
    textStyle: {
      fontSize: fonts.fontSize.font14,
      color: color.black,
      ...fonts.boldFontFamily,
    },
  });

export default LiveTrackingInfo;
