import React,{useState} from 'react';
import { View, Text, TextInput, TouchableOpacity } from 'react-native';
import styles from './ResendGiftCardCss'
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import Button from '../../Common/Button';
import GiftCardBookingDetailsConstant from '../GiftCardBookingDetailsConstant';

const ResendGiftCardOverlay = ({ handleBottomOverlay, handleResendVoucher, handleEmailIdInput, defaultWaterMark, overlayMessage }) => {

    if(!overlayMessage) {
        return null;
    }

    const [inputValue, setInputValue] = useState(defaultWaterMark);
    const [editBtn, setEditBtn] = useState(true);

    const handleInput =(emailAddress)=>{
        setInputValue (emailAddress);
        setEditBtn(false)
        handleEmailIdInput(emailAddress);
    }
    return (
        <View style={styles.giftCardBottomOverlay}>
            <Text style={styles.title}>{overlayMessage.heading}</Text>
            <Text style={styles.subTitle}>{overlayMessage.subheading}</Text>
            <View style={styles.textInputWrapper}>
                <TextInput
                    placeholderTextColor="#747474"
                    style={styles.textInputLabel}
                    onChangeText={(emailId)=> handleInput(emailId)}
                    placeholder={defaultWaterMark}
                />
                {editBtn &&
                    <Text style={[AtomicCss.font12, AtomicCss.azure, AtomicCss.regularFont, styles.editTextLabel]}>
                        {GiftCardBookingDetailsConstant.RESEND_GIFT_CARD_EDIT_CTA_TEXT}
                    </Text>
                }
            </View>
            <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.spaceBetween, AtomicCss.paddingBottom30]}>

                <TouchableOpacity style={AtomicCss.flex1} onPress={() => handleBottomOverlay(' ')}>
                    <Text style={[AtomicCss.font16, AtomicCss.azure, AtomicCss.regularFont]}>
                        {GiftCardBookingDetailsConstant.RESEND_GIFT_CARD_DISMISS_CTA_TEXT}
                    </Text>
                </TouchableOpacity>
                <View style={styles.buttonWrapper}>
                    <Button
                        btnTxt={GiftCardBookingDetailsConstant.RESEND_GIFT_CARD_CTA_TEXT}
                        btnBg = "gradientBtn"
                        fontSize={16}
                        clickHandler={handleResendVoucher}
                    />
                </View>
            </View>
        </View>
    );
}


export default ResendGiftCardOverlay;
