import React from 'react';
import isEmpty from 'lodash/isEmpty';
import {View, Text, Image} from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';

import getPlatformElevation from './getPlatformElevation';
import {fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import BasePage from '../../Common/PostSalesBasePage';
import {getTravellerIcon} from '../HolidayBookingDetailUtils';

class TravellerInfoItem extends BasePage {
  static navigationOptions = {
    header: null
  };


  render() {
    const iconStyle = [styles.tvlrIconStyle];
    const {item} = this.props;
    const travellerIcon = getTravellerIcon(item.gender);
    const genderAge = `${item.gender} / ${item.age} ${item.age > 1 ? 'yrs': 'yr'}`;
    const showPassport = !isEmpty(item.passportNo) || !isEmpty(item.passportIssuanceCountry)
      || !isEmpty(item.passportIssuanceDate) || !isEmpty(item.passportExpiryDate);

    return (
      <View style={[styles.tvlrCardWrapper]}>
        <View style={styles.topSection}>
          <Image style={iconStyle} source={travellerIcon}/>
          <Text style={styles.headingTxt}>{item.travellerName}</Text>
        </View>
        <View style={styles.tvlrDtlSection}>
          <View
            style={[styles.tvlrRowList, showPassport ? AtomicCss.marginBottom6 : AtomicCss.marginBottom0]}>
            <View style={styles.tvlrSection}>
              <View style={styles.tvlrSectionList}>
                <Text style={[styles.tvlrInfoTxt, AtomicCss.marginBottom5]}>
                  GENDER / AGE
                </Text>
                <Text style={styles.tvlrInfoTxtDtls}>
                  {genderAge}
                </Text>
              </View>
              <View style={styles.tvlrSectionList}>
                <Text style={[styles.tvlrInfoTxt, AtomicCss.marginBottom5]}>
                  DATE OF BIRTH
                </Text>
                <Text style={styles.tvlrInfoTxtDtls}>
                  {item.dateOfBirth}
                </Text>
              </View>
              {!isEmpty(item.nationality) &&
              <View style={styles.tvlrSectionList}>
                <Text style={[styles.tvlrInfoTxt, AtomicCss.marginBottom5]}>
                  NATIONALITY
                </Text>
                <Text style={styles.tvlrInfoTxtDtls}>
                  {item.nationality}
                </Text>
              </View>
              }
              {!isEmpty(item.mealPreference) &&
              <View style={styles.tvlrSectionList}>
                <Text style={[styles.tvlrInfoTxt, AtomicCss.marginBottom5]}>
                  MEAL PREFERENCE
                </Text>
                <Text style={styles.tvlrInfoTxtDtls}>
                  {item.mealPreference}
                </Text>
              </View>
              }
            </View>
          </View>

          {showPassport &&
          <View style={[styles.tvlrRowListLast, AtomicCss.marginBottom0]}>
            <View style={styles.tvlrSection}>
              {!isEmpty(item.passportNo) &&
              <View style={styles.tvlrSectionList}>
                <Text style={[styles.tvlrInfoTxt, AtomicCss.marginBottom5]}>
                  Passport Number
                </Text>
                <Text style={styles.tvlrInfoTxtDtls}>
                  {item.passportNo}
                </Text>
              </View>
              }
              {!isEmpty(item.passportIssuanceCountry) &&
              <View style={styles.tvlrSectionList}>
                <Text style={[styles.tvlrInfoTxt, AtomicCss.marginBottom5]}>
                  Issuing Country
                </Text>
                <Text style={styles.tvlrInfoTxtDtls}>
                  {item.passportIssuanceCountry}
                </Text>
              </View>
              }
              {!isEmpty(item.passportIssuanceDate) &&
              <View style={styles.tvlrSectionList}>
                <Text style={[styles.tvlrInfoTxt, AtomicCss.marginBottom5]}>
                  Passport Issue
                </Text>
                <Text style={styles.tvlrInfoTxtDtls}>
                  {item.passportIssuanceDate}
                </Text>
              </View>
              }
              {!isEmpty(item.passportExpiryDate) &&
              <View style={styles.tvlrSectionList}>
                <Text style={[styles.tvlrInfoTxt, AtomicCss.marginBottom5]}>
                  Passport Expiry
                </Text>
                <Text style={styles.tvlrInfoTxtDtls}>
                  {item.passportExpiryDate}
                </Text>
              </View>
              }
            </View>
          </View>
          }
        </View>
      </View>


    );
  }
}

const styles = {
  tvlrCardWrapper: {
    ...getPlatformElevation(2),
    backgroundColor: '#fff',
    paddingVertical: 16,
    paddingLeft: 16,
    marginBottom: 10
  },
  tvlrIconStyle: {
    width: 20,
    height: 21,
    marginRight: 10
  },
  topSection: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20
  },
  headingTxt: {
    color: '#2f2f2f',
    fontFamily: fonts.black,
    fontSize: 14
  },
  tvlrDtlSection: {
    paddingLeft: 32
  },
  tvlrRowList: {
    borderBottomColor: '#e7e7e7'
  },
  tvlrRowListLast: {
    borderTopColor: '#e7e7e7',
    borderTopWidth: 1,
    paddingBottom: 5,
    paddingTop: 5
  },
  tvlrSection: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  tvlrSectionList: {
    width: 155,
    marginBottom: 10,
    marginTop: 0
  },
  tvlrInfoTxt: {
    color: '#747474',
    fontSize: 12,
    fontFamily: fonts.regular,
    width: '100%',
    top: 1
  },
  tvlrInfoTxtDtls: {
    color: '#4a4a4a',
    fontSize: 13,
    fontFamily: fonts.bold,
    width: '63%'
  },
  borderBottom0: {
    borderBottomWidth: 0,
    borderBottomColor: 'transparent',
    paddingBottom: 0
  },
  marBtm0: {
    marginBottom: 0
  }
};

export default TravellerInfoItem;
