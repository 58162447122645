import React from 'react';
import PropTypes from 'prop-types';
import LinearGradient from 'react-native-linear-gradient';
import Actions from '../../navigation/postSalesNavigation';
import {Image, Text, TouchableOpacity, View} from 'react-native';
import styles from './CabMamiCss';
import CabBookingDetailsConstant from '../CabBookingDetailsConstant';
import CabBookingTrackingHelper from '../CabBookingTrackingHelper';
import {cancelIcon} from "../CabImageConstants";
import { ThemeContext } from '../../theme/theme.context';
import BaseButton, { BUTTON_VARIANT } from '../../Common/BaseButton';

class CabRefundOverlay extends React.Component {
  static contextType = ThemeContext;
  render() {
    const {color} = this.context.psTheme;
    return (
      <View style={styles.overlayContainer}>
        <TouchableOpacity style={styles.overlayBg} onPress={() => this.props.backPress()} />
        <View style={this.props.isRefundPage ? (styles.overlayContent) : (styles.cabOverlayContent)}>
          <View style={styles.flexRow}>
            <Image source={cancelIcon} style={styles.CancelledIcon} />
            <View style={styles.mar15Bt}>
              <Text style={styles.overlayHead}>Are you sure?</Text>
            </View>
          </View>
          <View style={[styles.mar25Bt]}>
            <Text style={styles.cancelTxt}>
              {this.getCancellationConfirmationText()}
            </Text>
          </View>
          <View style={[styles.flexRow, styles.alignCenter, styles.spaceBetween]}>
            <TouchableOpacity onPress={() => this.props.backPress()}>
              <Text style={[styles.actionBlueText,{color:color.primary}]}>BACK</Text>
            </TouchableOpacity>
            <BaseButton
              variant={BUTTON_VARIANT.CAPSULE}
              clickHandler={()=>this.onConfirmClickHandler()}
              text="OKAY, CANCEL"
              buttonStyle={styles.CapsuleBtnFill}
              textStyle={styles.regularFont}
            />
          </View>
        </View>
      </View>

    );
  }

  getCancellationConfirmationText = () => {
    if (this.props.emiBooking) {
      return 'This is the final step. Cancellation of this booking will not impact your EMI. You need to continue to pay EMI. To adjust EMI, please call your bank post cancellation. Once cancelled, your booking can not be reinstated.';
    }
    return 'Once confirmed, your booking will be cancelled and refund will be initiated.';
  };

  onConfirmClickHandler() {
    const {toggleOverlay} = this.props;
    toggleOverlay && toggleOverlay();
    Actions.CabCancelRequest({
      bookingId: this.props.bookingId,
      selectedRefundMode: this.props.selectedRefundMode,
      cabCrossSellBooking: this.props.cabCrossSellBooking,
      parentBookingId: this.props.parentBookingId,
      email: this.props.email,
      reason: this.props.cancellationReason,
      isMyGate: this.props.isMyGate
    });
    CabBookingTrackingHelper.trackClickEvent(this.props.pageName, CabBookingDetailsConstant.CLICK_CONFIRM_CANCEL);
  }
}
export default CabRefundOverlay;

CabRefundOverlay.propTypes = {
  backPress: PropTypes.func.isRequired,
  selectedRefundMode: PropTypes.string.isRequired,
  bookingId: PropTypes.string.isRequired,
  pageName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  cabCrossSellBooking: PropTypes.bool.isRequired,
  parentBookingId: PropTypes.string,
  emiBooking: PropTypes.bool.isRequired
};

CabRefundOverlay.defaultProps = {
  parentBookingId: undefined
};
