import React from 'react';
import { View, ScrollView, Text, ActivityIndicator, Keyboard, findNodeHandle } from 'react-native';
import { set, isEmpty, filter } from 'lodash';
import Actions from '../../../navigation/postSalesNavigation';
import PropTypes from 'prop-types';
import styles from './DynamicFormCss';
import BasePage from '../../../Common/PostSalesBasePage';
import FormContainer from './FormContainer';
import NoInternetView from '@mmt/legacy-commons/Common/Components/Error/NoInternetView';
import { isNetworkAvailable } from '@mmt/legacy-commons/Common/utils/AppUtils';
import { showShortToast, showLongToast } from '@mmt/legacy-commons/Common/Components/Toast';
import { PAGENAME, FORM_DATA_TYPE_NEW, INT_MAX_VAL, ViewState } from './DynamicFormConstants';
import FormDropdownSelector from './FormDropdownSelector';
import { validateDynamicForm, getHypotheticalParentNode, isNextKeyGood } from './DynamicFormUtils';

import { GENERIC_ERROR_MSG, USER_PLATFORM, DEVICE_WINDOW } from '../../HolidayBookingConstants';
import Footer from '../Footer';
import ProgressView from '../../../Common/ProgressView/ProgressView';
import ActionButton from '../ActionButton';
import { loadCollectionsForDynamicForm } from '../../../utils/NetworkUtils';

class DynamicForm extends BasePage {
  static navigationOptions = {
    header: null,
  };

  constructor(props) {
    super(props, 'DynamicForm');
    this.state = {
      viewState: ViewState.LOADING,
      loaderText: 'Loading...',
      validateForm: false,
      isPageBusy: false,
      bookedOn: null,
      forDate: null,
      bookingId: null,
      activityName: null,
      isFormEditable: true,
      formIndex: 0,
      visaFormChanged: false,
    };

    this.scrollViewWindowOffset = 0;
    this.textInputRefs = {};
    this.scrollIndex = 0;
    this.scrollViewRef = null;
    this.scrollViewContentHeight = 0;

    this.requestsCount = 0;
    this.dropDownSelectedValue = '';
    this.fields = [];
    this.visaParams = { ...this.props.visaParams, page: PAGENAME.APPLICATION_FORM_PREFILLED };
    this.visaFormResponse = {};
    this.previousPage = 'fromDocVerification';
    this.visaForm = {};
    this.profileQuestions = [];
    this.params = {};
    this.profileAnswers = [];
  }

  onBackPressed = () => {
    Actions.pop();
  };

  componentDidMount() {
    super.componentDidMount();
    this.fetchDynamicForm();
  }

  componentDidUpdate() {
    if (this.scrollViewRef) {
      setTimeout(() => {
        if (this.scrollViewRef) {
          this.scrollViewRef.scrollTo({ x: 0, y: this.scrollIndex, animated: false });
        }
      }, 1);
    }
  }

  async fetchPreProcessData(key, parent) {
    try {
      const { url } = this.visaForm.fields[key];
      this.requestsCount += 1;
      const response = await loadCollectionsForDynamicForm(url);
      if (!response || response.error_code) {
        showShortToast('Cannot fetch field values');
        return;
      }

      response.values.forEach((element) => {
        this.visaForm.fields[key].values.push(element);
      });
      Object.keys(response.dependents).forEach((_key) => {
        this.visaForm.fields[key].dependents[_key] = response.dependents[_key];
      });
      this.visaForm.fields[key].validations[parent] = response.validation;
      this.requestsCount -= 1;
      if (this.requestsCount === 0) {
        this.setState({
          viewState: ViewState.SHOW_DETAIL,
        });
      }
    } catch (e) {
      showShortToast('Unable to fetch data');
      this.props.onFormLoadError();
    }
  }

  recursivelySetValue(parent, values) {
    if (!values) return;
    Object.keys(values).forEach((key) => {
      if (
        this.visaForm.fields[key] &&
        this.visaForm.fields[key].validations[
          getHypotheticalParentNode(parent, this.visaForm.fields[key])
        ] === undefined &&
        this.visaForm.fields[key].url !== ''
      ) {
        this.fetchPreProcessData(key, getHypotheticalParentNode(parent, this.visaForm.fields[key]));
      }
      values[key] &&
        values[key].forEach((element) => {
          this.recursivelySetValue(element.value, element.dependents);
        });
    });
  }

  fetchDefaultValues = () => {
    Object.keys(this.visaForm.fields).forEach((key) => {
      const field = this.visaForm.fields[key];
      if (
        field.defaultValue !== '' &&
        field.url !== '' &&
        filter(field.values, (row) => row.key === field.defaultValue).length === 0
      ) {
        this.fetchPreProcessData(key, getHypotheticalParentNode('', field));
      }
    });
  };

  preProcessJson = () => {
    this.fetchDefaultValues();
    Object.keys(this.visaForm.fields).forEach((elem) => {
      this.visaForm.fields[elem].validations = {};
      if (isEmpty(this.visaForm.fields[elem].values) && this.visaForm.fields[elem].url === '') {
        this.visaForm.fields[elem].validations[''] = this.visaForm.fields[elem].validation;
      }
      this.visaForm.fields[elem].values.forEach((element) => {
        this.visaForm.fields[elem].validations[element.parent] = this.visaForm.fields[
          elem
        ].validation;
      });
    });
    this.recursivelySetValue('', this.visaForm.fieldValues);
  };

  getFormSchemaUrl = (productId, bookingId, noOfPaxes, packageId) => {
    if (bookingId) {
      return `${API_BASE_URL}${DYNAMIC_FORM_GET_API_ENDPOINT}?bId=${bookingId}&rId=${packageId}`;
    }
    return `${API_BASE_URL}${DYNAMIC_FORM_GET_API_ENDPOINT}?pId=${productId}&paxes=${noOfPaxes}&rId=${packageId}`;
  };

  async fetchDynamicForm() {
    const { travelPurposeData } = this.props;
    this.profileQuestions = travelPurposeData;
    const response = travelPurposeData.formSchema;
    if (!response || response.error_code) {
      this.props.onFormLoadError();
    } else if (response) {
      this.visaForm = response;
      this.setMetaDataFields(response);
      this.preProcessJson();
      if (this.requestsCount === 0) {
        this.setState({ viewState: ViewState.SHOW_DETAIL });
      }
    }
  }

  setMetaDataFields = (response) => {
    const bookedOn = this.props.bookedOn || response.bookedOnDate;
    const forDate = this.props.forDate || response.bookedForDate;
    const { bookingId } = this.props;
    const activityName = this.props.activityName || response.activityName;
    const isFormEditable = true;
    this.setState({
      bookedOn,
      forDate,
      bookingId,
      activityName,
      isFormEditable,
    });
  };

  render() {
    const iosStyle = USER_PLATFORM.IOS && DEVICE_WINDOW.height >= 812 ? { paddingTop: 35 } : {};
    return (
      <View style={[{ flex: 1 }, iosStyle]}>
        {this.state.viewState === ViewState.LOADING_DROPDOWN_DATA && this.renderNewFieldLoader()}
        {this.state.viewState === ViewState.NO_INTERNET && this.renderNoNetworkView()}
        {this.state.viewState === ViewState.NO_INTERNET_WHILE_SELECTING_DROPDOWN &&
          this.renderNoNetworkViewDropDown()}
        {this.state.viewState === ViewState.SHOW_SELECTOR && this.renderSelector()}
        {this.state.viewState === ViewState.SHOW_DETAIL && this.renderContent()}
      </View>
    );
  }

  renderNoNetworkViewDropDown = () => (
    <NoInternetView
      onRetry={() => {
        this.setState({
          viewState: ViewState.LOADING_DROPDOWN_DATA,
        });
        this.fetchNewData();
      }}
    />
  );

  renderNoNetworkView = () => (
    <NoInternetView
      onRetry={() => {
        this.setState({ viewState: ViewState.LOADING });
        this.fetchDynamicForm();
      }}
    />
  );

  renderNewFieldLoader = () => (
    <View style={styles.progressContainer}>
      <ActivityIndicator
        styleAttr="Inverse"
        style={styles.indicator}
        size="large"
        color="#008b8b"
      />
      <Text style={styles.darkText}> {'Loading Information'}</Text>
    </View>
  );

  renderProgressView = () => (
    <View style={styles.progressContainer}>
      <ActivityIndicator
        styleAttr="Inverse"
        style={styles.indicator}
        size="large"
        color="#008b8b"
      />
      <Text style={styles.darkText}> {this.state.loaderText}</Text>
    </View>
  );

  updateChildValues = (key, val) => {
    set(this, key, val);
  };

  getNextFieldLabel = (label) => {
    let closestElemLabel = `${INT_MAX_VAL}`;
    Object.keys(this.textInputRefs).forEach((key) => {
      if (this.textInputRefs[key] && isNextKeyGood(key, closestElemLabel, label)) {
        closestElemLabel = key;
      }
    });
    if (closestElemLabel === `${INT_MAX_VAL}`) {
      return null;
    }
    return closestElemLabel;
  };

  focusOnNextField = (dataPacket) => {
    Object.keys(this.textInputRefs).forEach((key) => {
      if (this.textInputRefs[key] === null) {
        delete this.textInputRefs[key];
      }
    });
    const nextFieldLabel = this.getNextFieldLabel(dataPacket.labelIndex);
    if (nextFieldLabel === null) {
      Keyboard.dismiss();
    } else {
      this.textInputRefs[nextFieldLabel].focus();
      this.textInputRefs[nextFieldLabel].measureLayout(
        findNodeHandle(this.scrollViewRef),
        (ox, oy, width, heigth, px, py) => {
          if (this.scrollViewRef) {
            this.scrollViewRef.scrollTo({ x: 0, y: oy - heigth, animated: true });
          }
        },
      );
    }
  };

  getTopInfoSection = () => (
    <View style={styles.topInfoSection}>
      <Text style={styles.captionText}>{this.props.countryName}</Text>
      <View style={{ flexDirection: 'row', marginTop: 10 }}>
        <Text>
          Fill Traveller Profile for Primary Traveller only, who is the lead traveller for this trip
        </Text>
      </View>
    </View>
  );

  getBottomSection = () => (
    <View>
      <View style={styles.actionButtonContainer}>
        <ActionButton
          onPress={this.onFormSubmitButtonPress}
          btnText="SUBMIT"
          enabled
          btnStyle={{ paddingHorizontal: 46 }}
        />
      </View>
    </View>
  );

  renderContent = () => {
    return (
      <View style={[styles.whitebg, styles.flex1, { height: DEVICE_WINDOW.height }]}>
        <ScrollView
          onScroll={this.handleScroll}
          ref={(ref) => {
            this.scrollViewRef = ref;
          }}
          keyboardShouldPersistTaps="handled"
        >
          {this.getTopInfoSection()}
          <FormContainer
            focusOnNextField={this.focusOnNextField}
            textInputRefs={this.textInputRefs}
            visaForm={this.visaForm}
            formIndex={this.state.formIndex}
            validateForm={this.state.validateForm}
            updateChildValues={this.updateChildValues}
            setField={this.setField}
            showDropDown={this.showDropDown}
          />
          {this.state.isFormEditable && !this.props.showFooter && this.getBottomSection()}
        </ScrollView>

        {this.state.isPageBusy ? <ProgressView style={styles.loaderWrapper} message=" " /> : null}
        {this.state.isFormEditable && this.props.showFooter && (
          <Footer
            actionText={this.props.buttonText}
            onActionPress={this.onFormSubmitButtonPress}
            footerLeftContent={this.getFooterLeftContent()}
          />
        )}
      </View>
    );
  };

  handleScroll = (event) => {
    this.scrollIndex = event.nativeEvent.contentOffset.y;
    this.scrollViewContentHeight = event.nativeEvent.contentSize.height;
  };

  /**
   * Function to JSX code for showing price on left of footer.
   */
  getFooterLeftContent = () => (
    <View>
      <Text style={styles.totalPriceText} />
      <View style={styles.priceView}>
        <Text style={styles.priceText} />
      </View>
    </View>
  );

  onFormSubmitButtonPress = async () => {
    Keyboard.dismiss();
    const isFormValid = validateDynamicForm(this.visaForm.fields, this.visaForm.fieldValues);
    if (isFormValid) {
      this.props.onProceed(this.props.index, this.visaForm.fieldValues);
    } else {
      this.setState({ validateForm: true });
    }
  };

  async fetchNewData() {
    const hasNetwork = await isNetworkAvailable();
    if (!hasNetwork) {
      this.setState({
        viewState: ViewState.NO_INTERNET_WHILE_SELECTING_DROPDOWN,
      });
      return;
    }

    const url = this.visaForm.fields[this.params.fieldName].url;

    const response = await loadCollectionsForDynamicForm(url);
    if (!response || response.error_code) {
      showShortToast('Cannot fetch field values');
      return;
    }
    response.values.forEach((element) => {
      this.visaForm.fields[this.params.fieldName].values.push(element);
    });

    Object.keys(response.dependents).forEach((key) => {
      this.visaForm.fields[this.params.fieldName].dependents[key] = response.dependents[key];
    });

    this.visaForm.fields[this.params.fieldName].validations[this.params.parent] =
      response.validation;

    if (this.visaForm.fields[this.params.fieldName].type === FORM_DATA_TYPE_NEW.DROPDOWN) {
      this.fields = [];
      response.values.forEach((value) => {
        this.fields.push({
          key: value.key,
          value: value.value,
        });
      });
    }

    this.setState({
      viewState:
        this.visaForm.fields[this.params.fieldName].type === FORM_DATA_TYPE_NEW.DROPDOWN
          ? ViewState.SHOW_SELECTOR
          : ViewState.SHOW_DETAIL,
    });
  }

  renderSelector = () => (
    <FormDropdownSelector
      onSelected={this.onSelected}
      fields={this.fields}
      onBackPressed={this.onSelectorBackPressed}
    />
  );

  onSelectorBackPressed = () => {
    this.setState({
      viewState: ViewState.SHOW_DETAIL,
    });
  };

  setField = (params) => {
    this.params = params;
    this.setState({
      viewState: ViewState.LOADING_DROPDOWN_DATA,
    });
    this.fetchNewData();
  };

  showDropDown = (params) => {
    this.params = params;
    if (this.visaForm.fields[params.fieldName].type === FORM_DATA_TYPE_NEW.DROPDOWN) {
      this.fields = [];
      this.visaForm.fields[params.fieldName].values.forEach((value) => {
        if (this.params.parent === value.parent) {
          this.fields.push({
            key: value.key,
            value: value.value,
            parent: value.parent,
          });
        }
      });
    }

    this.setState({
      viewState:
        this.visaForm.fields[params.fieldName].type === FORM_DATA_TYPE_NEW.DROPDOWN
          ? ViewState.SHOW_SELECTOR
          : ViewState.SHOW_DETAIL,
    });
  };

  onSelected = (field) => {
    this.dropDownSelectedValue = field.key;
    this.updateChildValues(`${this.params.jsonKey}.value`, field.key);
    this.updateChildValues(`${this.params.jsonKey}.parent`, field.parent);
    this.updateChildValues(this.params.jsonKey.dependents, {});
    this.setState({
      viewState: ViewState.SHOW_DETAIL,
    });
  };
}

DynamicForm.propTypes = {
  productId: PropTypes.string,
  bookingId: PropTypes.string,
  activityName: PropTypes.string,
  bookedOn: PropTypes.object,
  forDate: PropTypes.object,
  onBackPress: PropTypes.func.isRequired,
  onFormLoadError: PropTypes.func.isRequired,
  noOfPaxes: PropTypes.number,
};

DynamicForm.defaultProps = {
  productId: undefined,
  bookingId: undefined,
  bookedOn: null,
  forDate: null,
  noOfPaxes: 0,
  activityName: '',
};

export default DynamicForm;
