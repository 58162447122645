import React from 'react';
import PropTypes from 'prop-types';
import { Text, TouchableOpacity, View } from 'react-native';
import Buttons from '../../../../../components/Button';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import createStyles from './ApprovedOverlayCss'
import { OK } from '../../../../constants';
const ApprovedOverlayDtls = (props) => {
  const fonts = getFont(true);
  const {psTheme: theme} = useTheme();
  const styles = createStyles(theme,fonts);
  return  ( 
    <View style={styles.paddingBottom25}>
      <Text style={styles.contentTitle}>{props.approvalText}</Text>
      <Text style={styles.contentDesc}> {props.approvalSubText}</Text>

      <View style={styles.contentFooter}>
        <TouchableOpacity style={styles.actionBtn}>
          <Buttons
            isCorporateBooking
            text={OK}
            type="Rounded"
            color='blue'
            width={150}
            onPressHandler={() => {
              props.onOkPress();
            }}
          />
        </TouchableOpacity>
      </View>
    </View>
  )
};

ApprovedOverlayDtls.propTypes = {
  approvalText: PropTypes.string.isRequired,
  approvalSubText: PropTypes.string.isRequired,
  onOkPress: PropTypes.func.isRequired,
};

export default ApprovedOverlayDtls;
