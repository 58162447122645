import React, { useEffect } from 'react';
import { View, Text, Image } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { inclusionIcon } from 'apps/post-sales/src/cab/CabImageConstants';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import CardWrapper from '../CardWrapper';
import isEmpty from 'lodash/isEmpty';
import getStyle from './styles';
import HeadingComponent from '../HeadingComponent';
import CabBookingTrackingHelper from 'apps/post-sales/src/cab/CabBookingTrackingHelper';
import { CLICK_EVENT, PAGE_NAME } from 'apps/post-sales/src/cab/CabBookingDetailsConstant';

interface AddonServices {
  cardData: {
    addonServicesData: {
      serviceList: Array<{
        id: string;
        name: string;
        priceText: string;
      }>;
    };
  };
  card: {
    heading: string;
  };
  pageName: string;
}

const AddonServices = ({ card, cardData, pageName }: AddonServices) => {
  const { addonServicesData } = cardData || {};
  const { heading } = card || {};
  const { serviceList } = addonServicesData || {};
  const { fsStyle, ...fonts } = getFont();
  const { psTheme } = useTheme();
  const styles = getStyle(fonts, psTheme);

  if (isEmpty(addonServicesData) || isEmpty(serviceList)) return null;

  useEffect(()=>{
    CabBookingTrackingHelper.trackClickEvent(pageName, CLICK_EVENT.ADDON_SERVICES_CARD_SHOW);
  }, []);

  return (
    <View style={styles.specialRequestCard}>
      <HeadingComponent heading={heading} containerStyle={styles.heading} />
      {!isEmpty(serviceList) &&
        serviceList.map((service) => {
          const { id, name, priceText } = service || {};
          return (
            <View
              key={id}
              style={[AtomicCss.flexRow, AtomicCss.spaceBetween, AtomicCss.marginBottom5]}
            >
              <View style={[AtomicCss.flex1, AtomicCss.flexRow, AtomicCss.alignCenter]}>
                <Image style={[styles.tickImage]} source={inclusionIcon} />
                {!!name && <Text style={styles.nameText}>{name}</Text>}
              </View>
              {!!priceText && <Text style={styles.priceText}>{priceText}</Text>}
            </View>
          );
        })}
    </View>
  );
};

export default CardWrapper(AddonServices);
