import React from 'react';
import {ScrollView, View} from 'react-native';
import Actions from '../../navigation/postSalesNavigation';
import styles from '../GiftCardDetailsCss';
import PriceBreakUpCard from './PriceBreakupCard';
import CommonHeader from '@mmt/legacy-commons/Common/Components/Header/CommonHeader';
import PaymentBreakupCard from './PaymentBreakupCard';
import { WHITE_CROSS_ICON } from '../GiftCardImageConstants';

const GiftCardPaymentCardDetails = ({response}) => {
  
	const onBackPress = () => {
		Actions.pop();
	}
	
	return (
		<View style={[styles.GreyBg, {flex: 1}]}>
			<CommonHeader
				headerText="Payment Details"
				imgSrc={WHITE_CROSS_ICON}
				backPressHandler={onBackPress}
			/>
			<ScrollView>
				<PriceBreakUpCard
					response={response}
				/>
				<PaymentBreakupCard 
					response={response}
				/>
			</ScrollView>
		</View>
	);
}

export default GiftCardPaymentCardDetails;

