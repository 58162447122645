import { StyleSheet, TextStyle, ViewStyle } from 'react-native';
import getPlatformElevation from '@mmt/legacy-commons/Common/Components/Card/getPlatformElevation';
import { DEFAULT_THEME } from 'apps/post-sales/src/theme/default.theme';

interface Styles {
  whiteWrapper: ViewStyle;
  cardContent: ViewStyle;
  title: TextStyle;
  cabNotPresentStyle: TextStyle;
  fltInnerDtlsWrappers: ViewStyle
  bottomLine: ViewStyle
}

const createStyles = <Theme extends typeof DEFAULT_THEME>(theme: Theme) =>
  StyleSheet.create<Styles>({
    whiteWrapper: {
      backgroundColor: theme.color.white,
      marginBottom: 10,
      ...getPlatformElevation(2),
      marginTop: -2,
    },
    cardContent: {
      backgroundColor: theme.color.white,
      borderBottomWidth: 1,
      borderBottomColor: theme.color.lightGrey3,
      flexDirection: 'row',
      padding: 15,
      elevation: 1,
    },
    title: {
      color: theme.color.black,
    },
    cabNotPresentStyle: {
      fontSize: 14,
      color: theme.color.defaultTextColor,
      marginLeft: 48,
      marginRight: 16,
      marginTop: 16,
      marginBottom: 24,
      lineHeight: 18,
    },
    fltInnerDtlsWrappers: {
      paddingVertical: 10,
      paddingHorizontal: 10,
    },
    bottomLine: {
      borderBottomWidth: 1,
      borderBottomColor: theme.color.lightGrey3,
    }
  });

export default createStyles;
