import React from 'react';
import {View} from 'react-native';
import Actions from '../../navigation/postSalesNavigation';
import BasePage from '../../Common/PostSalesBasePage';
import getStyles from '../styles/BusMamiCss';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import ProgressView from '@mmt/legacy-commons/Common/Components/Progress/ProgressView';
import SimpleHeader from '@mmt/legacy-commons/Common/Components/Header/SimpleHeader';
import NoInternetView from '@mmt/legacy-commons/Common/Components/Error/NoInternetView';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import {getCommonHeaders, BUS_CANCELLATION_URL} from '../../utils/NetworkUtils';
import {isNetworkAvailable} from '@mmt/legacy-commons/Common/utils/AppUtils';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import BusCancellationInfoStrip from './BusCancellationInfoStrip';
import BusPenaltyDetails from './BusPenaltyDetails';
import logAction from '@mmt/legacy-commons/Helpers/actionsLogger';
import {getSubTitleForCancellationHeader} from '../utils/BusBookingDetailUtil';
import StickyHeader from '../../hotel/details/components/StickyHeader';
import BusBookingTrackingHelper from '../BusBookingTrackingHelper';
import {headerIcon} from '../BusImageConstants';

export const BUS_CANCELLATION_DETAILS_PAGE = 'Cancellation: Cancellation details';
export default class BusCancellationDetails extends BasePage {
  static navigationOptions = {
    header: null
  }

  constructor(props) {
    super(props, BUS_CANCELLATION_DETAILS_PAGE);
    this.trackingPageName = BUS_CANCELLATION_DETAILS_PAGE;
    this.bookingId = this.props.response.bookingId;
    this.mmtAuth = null;
    this.loggingTrackingInfo = null;
    this.penaltyResponse = null;
    this.state = {
      responseJson: null,
      viewState: ViewState.LOADING
    };
    this.busBookingInfo = this.props.response.busBookingInfo;
    this.styles = getStyles();
  }

  render() {
    return (
      <View style={[this.styles.flex1, this.styles.whitebg]}>
        {this.state.viewState === ViewState.LOADING && this.renderProgressView()}
        {this.state.viewState === ViewState.NO_INTERNET && this.renderNoNetworkView()}
        {this.state.viewState === ViewState.ERROR && this.renderErrorView()}
        {this.state.viewState === ViewState.SHOW_DETAIL && this.renderPage()}
      </View>
    );
  }

    renderProgressView = () => (<ProgressView message="Loading Details..." />);


    renderNoNetworkView = () => (
      <View style={{flex: 1}}>
        <SimpleHeader
          title="Back"
          iconPress={this.goBack}
        />
        <NoInternetView
          onRetry={() => {
                    this.setState({viewState: ViewState.LOADING});
                    this.fetchJSONAsync(true);
                }}
        />
      </View>
    );

    renderErrorView = () => (
      <View style={{flex: 1}}>
        <SimpleHeader
          title="Back"
          iconPress={this.goBack}
        />
        <ErrorView
          showRetry
          onRetry={() => {
                    this.setState({viewState: ViewState.LOADING});
                    this.fetchJSONAsync(true);
                }}
        />
      </View>
    );

    renderPage = () => (
      <View style={this.styles.whitebg}>
        <StickyHeader
          iconSrc={headerIcon}
          title="Full Cancellation"
          headerColor={['#ffffff', '#ffffff']}
          titleStyle={[this.styles.DefaultText, this.styles.blackFont]}
          subTitle={getSubTitleForCancellationHeader(this.props.response)}
          subTitleStyle={[this.styles.DefaultText, this.styles.regularFont, this.styles.font12]}
          onBackPress={this.goBack}
        />
        <BusCancellationInfoStrip
          response={this.props.response}
          penalty={this.penaltyResponse}
          modificationState="ViewRefund"
        />
        <BusPenaltyDetails
          response={this.props.response}
          penalty={this.penaltyResponse}
          onConfirmClick={this.onConfirmClickHandler}
        />
      </View>
    );

      onConfirmClickHandler = () => {
        Actions.chooseBusRefund({
          response: this.props.response,
          penaltyResponse: this.penaltyResponse,
          mmtAuth: this.mmtAuth,
          loggingTrackingInfo: this.loggingTrackingInfo
        });
      };

      componentDidMount() {
        this.fetchJSONAsync(true);
      }

      async fetchJSONAsync(prev) {
        try {
          const hasNetwork = await isNetworkAvailable();
          if (!hasNetwork) {
            this.setState({viewState: ViewState.NO_INTERNET});
            return;
          }
          const request = {
            bookingId: this.bookingId,
            preview: prev,
            refundOption: 'RefundToCustomer'
          };
          const {mmtAuth, loggingTrackingInfo} = await HotelBookingModule.getRequestHeader(this.props.bookingid, 'Bus_Cancellation_Details');
          this.mmtAuth = mmtAuth;
          this.loggingTrackingInfo = loggingTrackingInfo;
          const response = await
            fetch(BUS_CANCELLATION_URL, {
              method: 'POST',
              body: JSON.stringify(request),
              headers: await getCommonHeaders(mmtAuth, loggingTrackingInfo)
            });

          if (response.ok) {
            const body = await
              response.json();
            this.penaltyResponse = body;
            this.setState({
              viewState: ViewState.SHOW_DETAIL
            });
            BusBookingTrackingHelper.trackLoadEvent(this.trackingPageName, this.props.response, 'Fetch_Cancellation_Penalty');
          } else {
            BusBookingTrackingHelper.trackErrorEvent(this.trackingPageName);
            this.setState({viewState: ViewState.ERROR});
          }
        } catch (error) {
          BusBookingTrackingHelper.trackErrorEvent(this.trackingPageName);
          this.setState({viewState: ViewState.ERROR});
        }
      }

    goBack = () => {
      Actions.pop();
      logAction(`${this.pageName}onBackPress`);
    };
}
