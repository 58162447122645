import { colors, fonts } from '@mmt/legacy-commons/Styles/globalStyles';

const styles = {
  pageWrapper: {
    flex: 1,
    backgroundColor: '#f2f2f2',
  },
  pageScroll: {
    flex: 1,
  },

  footerBtnWrapper: {
    padding: 12,
    position: 'absolute',
    zIndex: 2,
    bottom: 0,
    left: 0,
    width: '100%',
  },
  whiteCard: {
    backgroundColor: '#ffffff',
    padding: 15,
    marginVertical: 8,
    elevation: 2,
    shadowColor: '#000000',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.3,
    shadowRadius: 1,
  },
  DarkFooter: {
    backgroundColor: '#282828',
    paddingVertical: 4,
    paddingHorizontal: 10,
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
  },
  ReviewCard: {
    marginVertical: 5,
  },
  FareBkCard: {
    paddingVertical: 15,
    paddingHorizontal: 0,
    marginVertical: 5,
  },
  cardTitle: {
    color: colors.gray,
    fontSize: 16,
    fontFamily: fonts.light,
    marginBottom: 10,
  },
  expenceDropDown: {
    borderRadius: 4,
    backgroundColor: '#fff',
    padding: 5,
    borderColor: '#979797',
    borderWidth: 1,
    position: 'absolute',
    width: '92%',
    marginHorizontal: 15,
    zIndex: 1000,
    elevation: 10,
    top: 123,
  },
  DropoptionTxt: {
    fontFamily: fonts.regular,
    color: '#4a4a4a',
    padding: 3,
  },

  tabWrapper: {
    marginTop: -25,
    position: 'relative',
    zIndex: 2,
    elevation: 2,
    shadowColor: '#000000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 2,
    flex: 1,
  },
  tabsHeader: {
    paddingHorizontal: 10,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 3,
  },
  tabs: {
    backgroundColor: '#ffffff',
    borderRadius: 4,
    elevation: 2,
    shadowColor: '#000000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 2,
    marginVertical: 2,
    flexDirection: 'row',
  },
  tab: {
    flex: 1,
    alignItems: 'center',
    paddingTop: 9,
    overflow: 'hidden',
    paddingBottom: 5,
    borderBottomColor: '#ffffff',
    borderBottomWidth: 3,
    position: 'relative',
    zIndex: 2,
  },
  line: {
    width: 2,
    height: 50,
    backgroundColor: '#f2f2f2',
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 100,
  },
  tabActive: {
    borderBottomColor: '#f17531',
  },
  tabFirst: {
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
  },
  tabLast: {
    borderRightWidth: 0,
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 0,
  },
  tabTitleSm: {
    fontFamily: fonts.semiBold,
    color: colors.black28,
    fontSize: 10,
  },
  tabTitle: {
    fontFamily: fonts.bold,
    color: colors.black28,
    fontSize: 16,
  },
  tabContent: {
    flex: 1,
    marginTop: 25,
  },
  tabContentBtmSpace: {
    height: 30,
  },
  tabContentTopSpace: {
    height: 65,
  },
  infoIcon: {
    width: 16,
    height: 16,
    marginRight: 10,
  },
  info_redTxt: {
    color: '#eb2026',
    fontSize: 12,
    fontFamily: fonts.bold,
  },
  infoIconWrapper: {
    flexDirection: 'row',
    paddingHorizontal: 20,
    paddingVertical: 8,
    alignItems: 'center',
    backgroundColor: '#ffd3d4',
    justifyContent: 'center',
  },
};

export default styles;
