import React from 'react';
import {View, Text, Image, TouchableOpacity} from 'react-native';
import {ArrowIcon} from '../../HolidayBookingConstants';
import styles from './slctFlightsCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';


class SectorFlights extends React.Component {
  static navigationOptions = {
    header: null
  };

  render() {
    const {item, index, handleSectorFlight, activeState} = this.props;
    const oneSectorTwoPnr = (item.type === 'OneSecTwoPnr') ? 'oneSectorTwoPnrStyle' : '';
    const errorSuffix = item.error;
    return (

      <View style={[styles.sectorWrapper, styles[oneSectorTwoPnr]]}>
        {item.sectorHeading &&
        <View style={[styles.sectorHeading, AtomicCss.flexRow, AtomicCss.alignCenter]}>
          <Image style={[styles.planeImgStyle]} source={item.icon}/>
          <Text
            style={[AtomicCss.font14, AtomicCss.defaultText, AtomicCss.regularFont]}>{item.heading}</Text>
        </View>
        }

        <TouchableOpacity
          style={[styles.sectorinnerDtls]}
          onPress={() => item.selectable ? handleSectorFlight(index, item.webCheckInUrl, item.pnr) : {}}
          activeOpacity={0.9}
        >
          <View style={AtomicCss.flexRow}>
            <View style={styles.Radio}>
              {index === activeState &&
              <View style={styles.RadioInside}></View>
              }
            </View>
            <View>
              <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom5]}>
                {item.city && item.city.map((city, index) => {
                  const lastItem = index === item.city.length - 1;
                  const middleCityTxt = (index > 0 && index < item.city.length - 1) ? 'midTxtStyle' : '';
                  return (
                    <React.Fragment>
                      <Text style={[styles.cityTxt, styles[middleCityTxt]]}>{city}</Text>
                      {!lastItem &&
                      <Image style={styles.arrowStyle} source={ArrowIcon}/>
                      }
                    </React.Fragment>
                  );
                })}
              </View>
              <Text
                style={[AtomicCss.font12, AtomicCss.darkGreyText, AtomicCss.regularFont]}>{item.moreInfo}</Text>
              {item.showPnr &&
              <View style={styles.copyCodeWrapper}>
                <Text
                  style={[AtomicCss.boldFont, AtomicCss.font16, AtomicCss.blackText]}>{item.pnr}</Text>
              </View>
              }
            </View>
          </View>
          {item.error &&
          <View style={[styles.errorState, styles[`errorState${errorSuffix}`]]}>
            <Text
              style={[styles.errorStateTxt, styles[`errorStateTxt${errorSuffix}`]]}>{item.errorTxt}</Text>
          </View>
          }
        </TouchableOpacity>
      </View>

    );
  }
}


export default SectorFlights;
