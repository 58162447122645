import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import {Image, Text, TouchableOpacity, View} from 'react-native';
import getStyles from '../components/HotelMamiCss';
import {getRoundIcon} from '../utils/HotelBookingDetailUtil';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const RoundButtonWithIcon = ({action, clickHandler, isThrottleRequired= false, throttleInterval = 1000}) => {
  const { ...fonts } = getFont(true);
  const styles = getStyles(fonts);
  const lastClickTimeRef = useRef(0);

  const throttledClickHandler = useCallback(() => {
    const currentTime = Date.now();
    if (currentTime - lastClickTimeRef.current >= throttleInterval) {
      lastClickTimeRef.current = currentTime;
      clickHandler(action);
    }
  }, [clickHandler, throttleInterval]);

  const wrapperThrottle = () => {
    if (isThrottleRequired) {
      throttledClickHandler();
    } else {
      clickHandler(action);
    }
  }

  return (<TouchableOpacity onPress={wrapperThrottle}>
    <View style={styles.RbBtnWithIconWrap}>
      <Image
        style={[styles.RbBtnWithIconImg, styles.mar3Bt]}
        resizeMethod={'resize'}
        source={getRoundIcon(action.actionFamily)}
      />
      <Text style={styles.RbBtnWithIconText}>{action.actionLabeltext}</Text>
    </View>
  </TouchableOpacity>)};

RoundButtonWithIcon.propTypes = {
  action: PropTypes.object.isRequired,
  clickHandler: PropTypes.func.isRequired
};

export default RoundButtonWithIcon;
