import { StyleSheet } from "react-native";

export default (theme, fonts) =>StyleSheet.create({
    refundVoucherInfoTxt: {
        textAlign: 'center',
        fontSize: 11,
         ...(fonts.regularFontFamily),
    },
    priceTag: {
        paddingVertical: 2,
        paddingHorizontal: 6,
        borderRadius: 15,
        flexDirection: 'row',
        alignSelf: 'flex-start',
        position: 'absolute',
        left: 8,
        top: -10,
    },
    voucherPriceTxt: {
        color: theme.color.goldenYellow,
        fontSize: 11,
         ...(fonts.boldFontFamily),
    },
    refundVoucherInfo: {
        paddingHorizontal: 12,
        paddingVertical: 10,
        borderWidth: 1,
        borderColor: theme.color.goldenYellow,
        backgroundColor: theme.color.floralWhite,
        flex: 1,
        borderRadius: 4,
    },
  })

  