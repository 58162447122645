import { STATUS, validationCardTypes } from './constants';
import { getTimeInMillis, isNotNullAndEmpty } from '../../../hotel/details/utils/HotelBookingDetailUtil';
import {
  getAdultCount,
  chkSgmntGrpSlctd,
  chkSgmntGrpEmpty,
  getDateInFormat,
  getFormattedDate
} from 'core-ui-lib/flight/common/ODC/helper';
import { ROUND_TRIP } from '../../../PostSalesConstant';
import { getStaticData } from '../../../staticData/staticData';
import { diffDays, addMonths } from '@mmt/legacy-commons/Helpers/dateHelpers';
import DateChangeTrackingHelper from './analytics/DateChangeTrackingHelper';
import { showShortToast } from '@mmt/legacy-commons/Common/Components/Toast';
import { Platform } from 'react-native';
import PostsaleFirebaseApp from '../../../firebase/postsalesFirebase';
import FIREBASE_CONFIG from '../../../firebase/const';
import { CANCELLATION_ICON, CALL_BLUE_ICON, DEP_IMG, DUR_IMG, NOON_GREY_ACTIVE_IMG, NOON_GREY_IMG, MORNING_BLUE_IMG, MORNING_BLUE_ACTIVE_IMG, EVENING_GREY_ACTIVE_IMG, EVENING_GREY_IMG, NIGHT_GREY_IMG, NIGHT_GREY_ACTIVE_IMG } from '../../FlightImageConstants';
import isEmpty from 'lodash/isEmpty';
import { fillCheckInCheckOutData, isEmptyArray } from '../../../Common/commonUtil';
import { isUndoWCApplicable, isUndoWCDone, getTripCardInfo, getOdcInfo } from './helper';
import { addSpaceWithAmount } from '../../../utils/PostSaleUtil';

export const parseGetURLParams = (url, paramsArray) => {
  const urlParams = {};
  paramsArray.forEach((value) => {
    for (let i = 0; i < url.length; i++) {
      if (url[i].indexOf(value) != -1) {
        urlParams[value] = url[i].split(/[=](.+)/)[1];
      }
    }
  });
  return urlParams;
};

export const getFinalSegment = (segmentDetails, segments) => {
  const activeSegment = segments.filter((segment) =>
    segmentDetails.some((segmentDetail) => segment.segLineNo === segmentDetail.segmentLineNo),
  );
  if (activeSegment && activeSegment.length > 0) {
    return activeSegment[0];
  }
  return null;
};

export const ifNoDateChangePresent = (card) => {
  return card.actionList.includes(validationCardTypes.NO_DATE_CHANGE);
};

export const checkSegmentDetailsListLength = (response)=>{
  const {flightDetails} = response || {};
  const {segmentGroupDetailList} = flightDetails || {};
  return segmentGroupDetailList ? segmentGroupDetailList.length : 0;

}

export const getHeaderText = (response) => {
  const { flightDetails } = response;
  const { roundTripText, onewayTripText } = getStaticData();
  const headerMapper = {};
  if (flightDetails) {
    headerMapper.journeyDetails = flightDetails.bookingIntentDetail;
    headerMapper.ownwardDate = getDateInFormat(flightDetails.travelDateTime, 'DD MMM');
    if (flightDetails.journeyType === 2) {
      headerMapper.returnDate = `-${getDateInFormat(flightDetails.returnTravelDateTime, 'DD MMM')}`;
    }
    headerMapper.tripType =
      flightDetails.journeyType === ROUND_TRIP ? roundTripText : onewayTripText;
    headerMapper.adultCount = getAdultCount(response);
  }
  return headerMapper;
};

export const areAllSegmentsDisabled = (odcValidation) => {
  let res = false;
  if (odcValidation && odcValidation.status.toLowerCase() === 'success') {
    res = odcValidation.segments.every((segment) => {
      if (isNotNullAndEmpty(segment.cards)) {
        return true;
      }
    });
  }
  return res;
};

export const getTotalPax = (dateChangeSegmentGroup) => {
  const { passengerList } = dateChangeSegmentGroup;
  const { travellersText, travellersText2 } = getStaticData();
  if (!isEmpty(passengerList)) {
    if (passengerList.length > 1) {
      return ` | ${passengerList.length} ${travellersText}`;
    }
    return ` | ${passengerList.length} ${travellersText2}`;
  }
  return '';
};

export const getDaysToShowCalendar = (date) => {
  const bookingDate = new Date(date);
  const dateAfterElevenMonth = addMonths(bookingDate, 11);
  const today = new Date();
  const diffInDays = diffDays(dateAfterElevenMonth, today);

  return diffInDays;
};

export const getCurrentDate = (segment, segmentKey, departureDate, segmentMap) => {
  let currentDate = '';
  if (segment.segmentLineNo.toString() === segmentKey) {
    currentDate = departureDate;
  } else if (segmentMap && segmentMap[segment.segmentLineNo]) {
    currentDate = segmentMap[segment.segmentLineNo].departureDate;
  }
  currentDate = getDateInFormat(currentDate, 'DD');
  if (currentDate === '') {
    return '...';
  }
  return currentDate;
};

export const getCurentMonthAndYear = (segment, segmentKey, departureDate, segmentMap) => {
  let currMonthYear = '';
  if (segment.segmentLineNo.toString() === segmentKey) {
    currMonthYear = departureDate;
  } else if (segmentMap && segmentMap[segment.segmentLineNo]) {
    currMonthYear = segmentMap[segment.segmentLineNo].departureDate;
  }
  return getDateInFormat(currMonthYear, 'MMM YYYY');
};

export const getCurrentDay = (segment, segmentKey, departureDate, segmentMap) => {
  let currentDay = '';
  if (segment.segmentLineNo.toString() === segmentKey) {
    currentDay = departureDate;
  } else if (segmentMap && segmentMap[segment.segmentLineNo]) {
    currentDay = segmentMap[segment.segmentLineNo].departureDate;
  }
  return getDateInFormat(currentDay, 'dddd');
};

export const getPaxFareSegmentList = (response, specialClaimData = {}) => {
  const paxFareSegmentList = [];
  response.flightDetails.segmentGroupDetailList.forEach((segmentGroupDetail) => {
    segmentGroupDetail.segmentDetails.forEach((segmentDetail) => {
      segmentDetail.segmentPassengerDetail.forEach((segmentPassengerDetail) => {
        if (!segmentPassengerDetail.isPaxCancelled) {
          const paxFareSegment = {};
          paxFareSegment.reason = specialClaimData.scCode;
          paxFareSegment.paxFareSegmentId = segmentPassengerDetail.paxFareSegmentId;
          paxFareSegmentList.push(paxFareSegment);
        }
      });
    });
  });
  return paxFareSegmentList;
};

export const getDateRangeInputCalendar = (sDate, eDate) => {
  const { weekDaysShortText, monthsShortText } = getStaticData();
  const { sun, mon, tue, wed, thu, fri, sat } = weekDaysShortText;
  const { jan, feb, mar, apr, may, jun, jul, aug, sep, oct, nov, dec } = monthsShortText;
  const WEEKDAY_ARRAY = [sun, mon, tue, wed, thu, fri, sat];
  const MONTH_ARRAY = [jan, feb, mar, apr, may, jun, jul, aug, sep, oct, nov, dec];
  const isEndData = eDate;
  const updatedStartDate = sDate.getDate() > 9 ? sDate.getDate() : `0${sDate.getDate()}`.slice(-2);
  const updatedStartMonth = sDate.getMonth() + 1;
  const updatedStartYear = sDate.getFullYear();
  const updatedEndDate = isEndData
    ? sDate !== eDate
      ? eDate.getDate() > 9
        ? eDate.getDate()
        : `0${eDate.getDate()}`.slice(-2)
      : '- -'
    : null;
  const updatedEndMonth = isEndData ? (sDate != eDate ? eDate.getMonth() + 1 : '') : null;
  const updatedEndYear = isEndData ? (sDate != eDate ? eDate.getFullYear() : '') : null;
  const updatedEndWeekDay = isEndData
    ? sDate !== eDate
      ? WEEKDAY_ARRAY[eDate.getDay()]
      : ''
    : null;

  const startDate = {
    year: updatedStartYear,
    month: updatedStartMonth,
    date: updatedStartDate,
    weekday: WEEKDAY_ARRAY[sDate.getDay()],
    monthStr: MONTH_ARRAY[updatedStartMonth - 1]?.toUpperCase(),
    dateFormat: `${updatedStartYear}/${updatedStartMonth}/${updatedStartDate}`,
  };
  const endDate = {
    year: updatedEndYear,
    month: updatedEndMonth,
    date: updatedEndDate,
    weekday: updatedEndWeekDay,
    monthStr: MONTH_ARRAY[updatedEndMonth - 1]?.toUpperCase(),
    dateFormat: `${updatedEndYear}/${updatedEndMonth}/${updatedEndDate}`,
  };
  return { startDate, endDate };
};

export const selectFlights = (
  staticData,
  segmentGroup,
  response,
  rtDateErrorPopupCallBack,
  rtDiffPnr,
  callListing,
) => {
  const { selectDateToastText } = staticData;
  if (segmentGroup.length === 0) {
    showShortToast(selectDateToastText);
    return;
  }
  const {
    journeyType,
    segmentGroupDetailList,
    returnTravelDateTime,
    travelDateTime,
  } = response.flightDetails;
  if (
    journeyType === 2 &&
    segmentGroupDetailList.length === 2 &&
    segmentGroup.length > 1 &&
    segmentGroup[1].newDate < segmentGroup[0].newDate
  ) {
    rtDateErrorPopupCallBack(response);
  } else if (
    journeyType === 2 &&
    segmentGroupDetailList.length === 2 &&
    segmentGroup.length === 1 &&
    !rtDiffPnr
  ) {
    if (
      (segmentGroup[0].intentRefID === 1 && returnTravelDateTime && 
        new Date(segmentGroup[0].newDate) >
        new Date(returnTravelDateTime.split('T')[0]).setHours(0, 0, 0)) ||
      (segmentGroup[0].intentRefID === 2 && travelDateTime &&
        new Date(segmentGroup[0].newDate) <
        new Date(travelDateTime.split('T')[0]).setHours(0, 0, 0))
    ) {
      rtDateErrorPopupCallBack(response);
    } else {
      callListing();
    }
  } else {
    callListing();
  }
};

export const isTripNotAllowed = (cancellation, response) => {
  const isCancellationDisabled = cancellation && cancellation.disabled;
  if (isCancellationDisabled) {
    DateChangeTrackingHelper.getDateChangeDisabledOmniture(
      'datechangepickdate',
      response,
      cancellation,
    );
  }
  return isCancellationDisabled;
};

export const checkFlightCalendarToOpen = (segmentMap, rtDiffPnr, flightSegment,selectDateToastText) => {
  if (
    Object.keys(segmentMap).length > 0 &&
    rtDiffPnr &&
    segmentMap[flightSegment.segmentLineNo] === undefined
  ) {
    showShortToast(selectDateToastText);
    return true;
  }
};

export const getFlightCalendarParams = (flightSegment, departureDate, bookingDateTime) => {
  const params = {};
  params.segmentKey = flightSegment.segmentLineNo.toString();
  if (Platform.OS === 'ios') {
    if (departureDate) {
      params.departDate = departureDate;
    } else {
      params.departDate = bookingDateTime;
    }
    params.maxDaysAllowed = getDaysToShowCalendar(params.departDate);
    params.departDate = new Date(); // Calendar will show the dates from today.
  } else {
    params.departDate = bookingDateTime;
  }
  return params;
};

export const getListingContentTitle = (differentSupplier) => {
  const content = {};
  const {
    diffAirlineTextTitle,
    diffAirlineTextDesc,
    airIndiaTextTitle,
    airIndiaTextDesc,
  } = getStaticData();
  content.title = diffAirlineTextTitle;
  content.desc = diffAirlineTextDesc;
  if (differentSupplier) {
    content.title = airIndiaTextTitle;
    content.desc = airIndiaTextDesc;
  }
  return content;
};

export const  initFirebaseDatabase = async(
  undoWCList,
  undoWCFirebaseApp,
  updateStatus,
  bookingId,
  pnr,
  isCorp,
  data
) => {
  try {
    if (!isEmptyArray(undoWCList)) {
      const { config, basePath, tokenKey, corpBasePath } = FIREBASE_CONFIG.UNDO_WEBCHECKIN;
      const firebasePath = isCorp ? corpBasePath : basePath;
      const { firebaseTokenList = {} } = data || {};
      undoWCFirebaseApp.current = new PostsaleFirebaseApp(config, tokenKey, firebaseTokenList, bookingId);
      const {isInstanceAuthenticated, errorData} = await undoWCFirebaseApp.current.init();
      isInstanceAuthenticated && undoWCList.forEach((item, index) => {
        if (item.status === STATUS.PENDING) {
          const path = firebasePath + `${bookingId}/${pnr}/${item['request-id']}`;
          undoWCFirebaseApp.current.onValue(path, (data) => {
            const { status } = data || {};
            if (status === STATUS.SUCCESS || status === STATUS.FAILURE) {
              updateStatus(index, data);
            }
          });
        }
      });
      if(errorData){
        DateChangeTrackingHelper.trackErrorEvent(
          'datechangereviewUndoWCOverlay',
          `authenticate_firebase_${errorData}`,
        );
        console.log("@Firebase Error: ", errorData);
      }
    }
  } catch (err) {
    DateChangeTrackingHelper.trackErrorEvent(
      'datechangereviewUndoWCOverlay',
      `init_firebase_${err ? err.message : ''}`,
    );
    console.log(err);
  }
};

export const getFlightName = (segmentName) => `${segmentName.flightDesignator.flightName}`;

export const createPassengerList = (passenger) => {
  const resultantList = [];
  let index = 0;
  passenger.forEach((obj) => {
    if (obj.selected) {
      resultantList[index] = obj;
      index++;
    }
  });
  return resultantList;
};

export const getContactNoBtnList = (contactNoList, btnClick) => {
  const contactNoBtnListKeys = Object.keys(contactNoList);
  const linkBtnList = [];
  if (!isEmpty(contactNoBtnListKeys)) {
    contactNoBtnListKeys.forEach((key) => {
      const contactData = contactNoList[key];
      const linkBtnData = { ...contactData };
      linkBtnData.btnIcon = CALL_BLUE_ICON;
      linkBtnData.btnStyle = {
        height: 36,
        width: 36,
      };
      linkBtnData.actionType = key;
      linkBtnData.clickListener = btnClick;
      linkBtnList.push(linkBtnData);
    });
  }
  return linkBtnList;
};

export const getErrorPageDataFromReviewAPI = (
  contactNoList,
  mobileHeaderData,
  onBackPressFromError,
  btnClick,
  errorReason,
) => {
  const { errorText, raiseDateChangeErrorText, tryAgainText } = getStaticData();
  return {
    headerData: {
      title: mobileHeaderData.header,
    },
    contentTitle: errorText.soomethingWentWrongOnlyError,
    contentDescriptionList: [raiseDateChangeErrorText],
    errorImage: CANCELLATION_ICON,
    errorImageStyle: {
      width: 60,
      height: 55,
      marginBottom: 25,
    },
    btnList: [
      {
        title: tryAgainText,
        buttonType: 'BLUE_RECTANGULAR_BTN',
        actionType: 'TRY_AGAIN',
        clickListener: btnClick,
      },
    ],
    linkBtnList: getContactNoBtnList(contactNoList),
    onBackPressHandler: onBackPressFromError,
    omniturePageName: 'Mob:customer support:Dom flight:datechange_requesterror',
    failureReason: errorReason,
  };
};

export const getErrorPageDataFromAPIResponse = (
  response,
  omniturePageName,
  btnClick,
  onBackPressFromError,
  errorReason,
) => {
  const { heading, descriptionList, actionBtn, contactNoList } = response.failureData;
  const headerData = response.mobileHeaderData.header;
  const btnList = [];
  const linkBtnList = getContactNoBtnList(contactNoList, btnClick);
  if (!isEmpty(actionBtn)) {
    actionBtn.forEach((btn) => {
      const btnData = { ...btn };
      btnData.clickListener = btnClick;
      btnList.push(btnData);
    });
  }
  return {
    headerData: {
      title: headerData,
    },
    contentTitle: heading,
    contentDescriptionList: descriptionList,
    errorImage: CANCELLATION_ICON,
    errorImageStyle: {
      width: 60,
      height: 55,
      marginBottom: 25,
    },
    btnList,
    linkBtnList,
    onBackPressHandler: onBackPressFromError,
    omniturePageName,
    failureReason: errorReason,
  };
};

export const getCommitMsgDescription = (urlObj) => {
  const {
    dateChangeThankYouCard: { successBookingDescription, failureBookingDescription },
  } = getStaticData();
  let msgDesc = '';
  if (urlObj.viewState) {
    msgDesc = successBookingDescription;
  } else {
    msgDesc = failureBookingDescription;
  }
  return msgDesc;
};

export const getCommitMsgTitle = (urlObj) => {
  const {
    dateChangeThankYouCard: { successTitle, failureTitle },
  } = getStaticData();
  let title = '';
  if (urlObj.viewState) {
    title = successTitle;
  } else {
    title = failureTitle;
  }
  return title;
};

export const getDCSubmitCommitMsgDescription = (commitMsg, paymentSuccess) => {
  let msgDesc = '';
  const { dateChangeCommitMsgSuccessText, dateChangeCommitMsgUnsuccessText } = getStaticData(false) || {};
  if (commitMsg) {
    msgDesc = commitMsg;
  } else if (paymentSuccess) {
    msgDesc = dateChangeCommitMsgSuccessText;
  } else {
    msgDesc = dateChangeCommitMsgUnsuccessText;
  }
  return msgDesc;
};

export const getDCSubmitCommitMsgTitle = (commitTitle, paymentSuccess) => {
  let title = '';
  const { dateChangeSuccessText, dateChangeUnSuccessText } = getStaticData(false) || {};
  if (commitTitle) {
    title = commitTitle;
  } else if (paymentSuccess) {
    title = dateChangeSuccessText;
  } else {
    title = dateChangeUnSuccessText;
  }
  return title;
};

export const isAlFltrOn = (currOpAlFltrLst) => {
  for (let i = 0; i < currOpAlFltrLst.length; i++) {
    if (currOpAlFltrLst[i].selected) {
      return false;
    }
  }
  return true;
};

export const getErrorDetails = (errorCode) => {
  const errorDetail = {};
  const {
    tryAgainText,
    errorNoFlightsFoundHeader,
    errorNoFlightsFoundMessage,
    errorNoFlightsFoundText,
  } = getStaticData();
  if (errorCode === '1011') {
    errorDetail.header = errorNoFlightsFoundHeader;
    errorDetail.message = errorNoFlightsFoundMessage;
    errorDetail.TryAgainText = errorNoFlightsFoundText;
  } else {
    errorDetail.header = '';
    errorDetail.message = '';
    errorDetail.TryAgainText = tryAgainText;
  }
  return errorDetail;
};

export const isSubmitApplicable = (sgmtGrpLst) => {
  if (sgmtGrpLst === undefined || sgmtGrpLst.length === 0) {
    return false;
  }
  if (sgmtGrpLst.length === 1) {
    return sgmtGrpLst[0].paxFareSegmentId.length !== 0 && sgmtGrpLst[0].newDate !== undefined;
  }
  if (sgmtGrpLst.length === 2) {
    if (chkSgmntGrpSlctd(sgmtGrpLst[0]) && chkSgmntGrpSlctd(sgmtGrpLst[1])) {
      return true;
    } else if (chkSgmntGrpSlctd(sgmtGrpLst[0]) && chkSgmntGrpEmpty(sgmtGrpLst[1])) {
      return true;
    } else if (chkSgmntGrpEmpty(sgmtGrpLst[0]) && chkSgmntGrpSlctd(sgmtGrpLst[1])) {
      return true;
    } else {
      return false;
    }
  }
};

export const createPassengerMapUtil = (
  passengerStatus,
  segmentLineNo,
  intentRefId,
  dcPaxList,
  selectedDateMap,
) => {
  const mapper = {};
  const passengerList = [];
  for (let i = 0; i < passengerStatus.length; i++) {
    const passengerObj = passengerStatus[i];
    if (passengerObj.selected) {
      const paxLineNo = passengerObj.paxLineNo;
      const paxFSIdLst = getPaxFareSgmntIdList(paxLineNo, intentRefId, dcPaxList);
      paxFSIdLst.forEach((paxFSId) => {
        passengerList.push(paxFSId);
      });
    }
  }
  mapper.paxFareSegmentId = passengerList;
  mapper.intentRefID = intentRefId;
  if (selectedDateMap && selectedDateMap[segmentLineNo]) {
    mapper.newDate = getTimeInMillis(selectedDateMap[segmentLineNo].departureDate);
  }
  return mapper;
};

export const getPaxFareSgmntIdList = (paxLineNo, intentRefId, dcPaxList) => {
  for (let i = 0; i < dcPaxList[intentRefId].length; i++) {
    if (paxLineNo === dcPaxList[intentRefId][i].paxLineNo) {
      return dcPaxList[intentRefId][i].paxFareSegmentIdList;
    }
  }
  return [];
};

export const selectPassengers = (selectedPassengers, passengerStatus) => {
  const passengerData = passengerStatus;
  const selected =
    selectedPassengers < passengerData.length ? passengerData.length : 0;
  const isSelected = selectedPassengers < passengerData.length;
  for (let i = 0; i < passengerData.length; i++) {
    passengerData[i].selected = isSelected;
  }
  return {
    selected,
    passengerData
  };
}

export const undoWebCheckInApplicable = (item, undoWebCheckinApplicableSegs) =>
  item &&
  item.segmentLineNo &&
  !isEmptyArray(undoWebCheckinApplicableSegs) &&
  undoWebCheckinApplicableSegs.includes(`${item.segmentLineNo}`);

export const getSteps = () => {
  const { dateChangeStep1Text, dateChangeStep2Text, dateChangeStep3Text } = getStaticData();
  return [dateChangeStep1Text, dateChangeStep2Text, dateChangeStep3Text];
};

export const getTotalPriceText = (listItem, currency) => {
  const text = listItem.fareBreakup
    ? listItem.fareBreakup?.totalPayable.amount
    : listItem.priceBreakup
      ? listItem.priceBreakup.totalPayable
      : listItem.pay;

  if (text || typeof text === 'number') {
    return ` ${listItem.fareBreakup ? '' : currency} ${text} `;
  }
  return null;
};

export const toggleFilter = (currFltrObj, qckFltrOnLst, index) => {
  qckFltrOnLst[index] = !qckFltrOnLst[index];
  if (index === 0) {
    currFltrObj.itinFltrLst[0].currStpLst[0] = !currFltrObj.itinFltrLst[0]
      .currStpLst[0];
  } else if (index === 1) {
    currFltrObj.itinFltrLst[0].currDepTmLst[1] = !currFltrObj.itinFltrLst[0]
      .currDepTmLst[1];
  } else if (index >= 2) {
    currFltrObj.alFltrLst[index - 2].selected = !currFltrObj.alFltrLst[index - 2]
      .selected;
  }
}

export const getSegmentInfo = (segmentGroup) => {
  if (
    segmentGroup &&
    segmentGroup.segmentGroupIntent &&
    segmentGroup.segmentGroupIntent.split('-').length > 2
  ) {
    let stop = [];
    let stopovers = [];
    stop = segmentGroup.segmentGroupIntent.split('-');
    const stopLen = stop.length;
    stopovers = segmentGroup.segmentGroupIntent.split('-').slice(1, stopLen - 1);
    const seg = segmentGroup.segmentDetails[0];
    return {
      showSingle: true,
      seg,
      destinationCity,
      stopovers,
    }
  }
  return {
    showMultiple: true
  }
}

export const getJourneyInfo = (data, undoWebCheckinApplicableSegs) => {
  const { segmentDetails } = data || {};
  const result = [];
  segmentDetails &&
    segmentDetails.forEach((item) => {
      if (undoWebCheckInApplicable(item, undoWebCheckinApplicableSegs)) {
        const { originCity, destinationCity, travelDateTime } = item || {};
        const traveldate = getFormattedDate(travelDateTime, 'DD MMM');
        result.push({
          text1: `${originCity}-${destinationCity}`,
          text2: `, ${traveldate}`
        });
      }
    });
  return result;
};

export const getOverlayJourneyInfo = (data, undoWebCheckinApplicableSegs, odcSegmentGroup, undoWCList) => {
  const { segmentDetails, intentRefID } = data || {};
  const odcInfo = getOdcInfo(odcSegmentGroup, intentRefID);
  const tripCardInfoArray = [];
  segmentDetails &&
    segmentDetails.forEach((item) => {
      const isUndoWCapplicable = isUndoWCApplicable(undoWebCheckinApplicableSegs, item);
      if (odcInfo && (isUndoWCDone(undoWCList) || isUndoWCapplicable)) {
        const tripCardInfo = getTripCardInfo(isUndoWCapplicable, undoWCList, odcInfo, item);
        tripCardInfoArray.push({ tripCardInfo });
      }
    });
  return tripCardInfoArray;
}

export const getChargesTxt = (isCancelChargesPage, timeFrameObj) => {
  let txt = '';
  if (isCancelChargesPage) {
    txt = addSpaceWithAmount(timeFrameObj.cancelChargesAd);
  } else {
    txt = addSpaceWithAmount(timeFrameObj.dcChargesAd);
  }
  return txt;
}

export const getSorters = () => {
  const { priceText, departureText, durationText, lowestFirstText, earliestFirstText, latestFirstText, shortestFirstText } = getStaticData();
  return [
    {
      sortIcon: null,
      label: priceText,
      type: 'price',
      filterTypes: [lowestFirstText],
    },
    {
      sortIcon: DEP_IMG,
      label: departureText,
      type: 'departure',
      filterTypes: [earliestFirstText, latestFirstText],
    },
    {
      sortIcon: DUR_IMG,
      label: durationText,
      type: 'duration',
      filterTypes: [shortestFirstText],
    },
  ]
};

export const getFilters = () => {
  const { stopLowerCaseText, nonStopText, stopsText, beforeSixAM, afterSixPM } = getStaticData();
  return {
    stops: {
      list: [
        {
          stopCount: '0',
          stopTxt: nonStopText,
        },
        {
          stopCount: '1',
          stopTxt: stopLowerCaseText,
        },
        {
          stopCount: '2+',
          stopTxt: stopsText,
        },
      ],
    },
    departure: {
      list: [
        {
          DepImg: MORNING_BLUE_IMG,
          DepTime: beforeSixAM,
          iconStyle: 'DepmorningIcon',
          DepImgActive: MORNING_BLUE_ACTIVE_IMG,
        },
        {
          DepImg: NOON_GREY_IMG,
          DepTime: '6 AM - 12 AM',
          iconStyle: '',
          DepImgActive: NOON_GREY_ACTIVE_IMG,
        },
        {
          DepImg: EVENING_GREY_IMG,
          DepTime: '12 PM - 6 PM',
          iconStyle: '',
          DepImgActive: EVENING_GREY_ACTIVE_IMG,
        },
        {
          DepImg: NIGHT_GREY_IMG,
          DepTime: afterSixPM,
          iconStyle: '',
          DepImgActive: NIGHT_GREY_ACTIVE_IMG,
        },
      ],
    },
  }
};

export const getFlightSortingTab = () => {
  const { sortByText, filtersText } = getStaticData();
  return [sortByText, filtersText];
}

export function getCalendarDataList(response){
  const { calDataLst } = response;
  return calDataLst;
};
