export const createSubmitRequestBody = (pickupDetails, response, bookingId, showDropOffDetails) => {
  return {
    pickupDetails: pickupDetails.map((item, index) => ({
      ...item,
      dropoff: showDropOffDetails.includes(index) ? item.dropoff : null,
    })),
    fromDate: new Date(),
    toDate: new Date(),
    bookingId,
  };
};
