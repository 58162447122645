import React from 'react';
import {View, Text, Image, StyleSheet} from 'react-native';
import {colors} from '@mmt/legacy-commons/Styles/globalStyles';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {getImagePath} from "./PostSaleUtil";
import getPlatformElevation from '../Holiday/Components/getPlatformElevation';
import { getFont } from '../PostSalesStyles';

export function getImgSource(cancellationPolicyStartTime, cancellationPolicyEndTime) {
  let currentDate = new Date()
  let startDate = new Date(cancellationPolicyStartTime);
  let endDate = new Date(cancellationPolicyEndTime);
  if (currentDate >= startDate && currentDate < endDate) {
    return [cancellationNowImage, true];
  }
  return [dotHollowGrayImage, false];
}

export function rendorForEachElement(imgSource, cancellationTimelineTitle, totalCancellationPenalty, lastRow, currentTimeline) {
  const fonts = getFont(true);

  return (
    <View style={!lastRow && cancellationStyles.trackerRow}>
    <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween]}>
      <View style={[AtomicCss.paddingLeft20, AtomicCss.flex1]}>
        <Image style={imgSource.style} source={imgSource.source} />
        <Text style={[currentTimeline && (fonts.boldFontFamily, AtomicCss.blackText), fonts.font12]}>
          {cancellationTimelineTitle}
        </Text>
      </View>
      <Text style={[currentTimeline && (fonts.boldFontFamily, AtomicCss.blackText), AtomicCss.paddingRight10, fonts.font12]}>
        {totalCancellationPenalty}{' '}
      </Text>
    </View>
  </View>
  )
}

export const cancellationNowImage = {style: {height: 16,
  width: 16,
  position: 'absolute',
  left: -8,
  top: 0,
  backgroundColor: colors.white}, source: getImagePath('cancellation-now.webp')}

export const dotHollowGrayImage = { style: {height: 8,
  width: 8,
  position: 'absolute',
  left: -5,
  top: 4,
  backgroundColor: colors.white} ,source: require('@mmt/legacy-assets/src/dot-hollow-gray.webp')}

export const cancellationStyles = StyleSheet.create({
  CancellationPolicyCardWrapper :{backgroundColor: '#fff', paddingLeft: 21, paddingRight: 16, paddingTop: 16, borderTopLeftRadius: 8, borderTopRightRadius: 8, ...getPlatformElevation(1)},
  disabledCard:{opacity: 0.4},
  CabCancellationPolicyCardWrapper :{backgroundColor: '#fff', paddingLeft: 21, paddingRight: 16, paddingTop: 16, paddingBottom: 12},
  trackerHeadingRow:{flexDirection: 'row', paddingBottom: 12, justifyContent: 'space-between'},
  trackerHeading: {fontSize: 14, color: '#000', flex: 1, paddingLeft: 20},
  trackerRow: {borderLeftWidth: 1, borderLeftColor: '#ddd', paddingBottom: 12},
  trackerActive: {
    height: 16,
    width: 16,
    position: 'absolute',
    left: -8,
    top: 0,
    backgroundColor: colors.white
  },
  trackerBall: {
    height: 8,
    width: 8,
    position: 'absolute',
    left: -5,
    top: 4,
    backgroundColor: colors.white,
  },
  trackerBallLast: {
    height: 8,
    width: 8,
    position: 'absolute',
    left: 1,
    bottom: 1,
    backgroundColor: colors.white
  },
  marginTopNeg6:{marginTop: -6},
  paddingBottom16: {paddingBottom: 16}
});
