import React from 'react';
import PropTypes from 'prop-types';
import { ScrollView, View, StyleSheet, Platform } from 'react-native';
import Actions from '../../../../../navigation/postSalesNavigation';
import BasePage from '../../../../../Common/PostSalesBasePage';
import CancellationCard from '../CancellationCard';
import Header from '../../../../../Common/HeaderCross';
import RaiseRefundCard from '../RaiseRefundCard';
import OverlayMessage from '../../../../../Common/OverlayMessage';
import FlightBookingTrackingHelper from '../../helper/FlightBookingTrackingHelper';
import { getScCardHeader } from '../../../../FlightBookingUtil';

class CancellationOptionStandalone extends BasePage {
  static navigationOptions = {
    header: null,
  };

  constructor(props) {
    super(props, 'flightCancellationOption');
    this.state = {
      specialClaimSelectedItem: undefined,
    };
  }

  render() {
    return (
      <View style={[styles.container, styles.transparentBgrnd]}>
        <Header title="Cancel" handleClose={this.onBackPress} headerShadow headingTxt="font18" />
        <ScrollView>
          <View style={{ paddingBottom: 70, marginTop: 10 }}>
            {this.props.response.cancelCardDetailInfo && (
              <CancellationCard
                pageName="Details_ChangePlan_Cancellation"
                cancelCardDetailInfo={this.props.response.cancelCardDetailInfo}
                dbMessage={this.props.response.dbMessage}
                normalMsgObj={this.props.normalMsgObj}
                zcGlobalMsg={this.props.response.zcGlobalMsg}
                cancellationButtonClick={this.props.cancelCardActionItemClick}
              />
            )}
            {this.props.response.specialClaimDataList && (
              <RaiseRefundCard
                specialClaimDataList={this.props.response.specialClaimDataList}
                specialClaimActionClick={this.raiseClaimClicked}
                scTopMessage={this.props.response.scTopMessage}
                scTopHeader={getScCardHeader(this.props.response.messageAttributes)}
              />
            )}
          </View>
        </ScrollView>
        {this.state.specialClaimSelectedItem && (
          <OverlayMessage
            popUpOutsideClick={() => {
              this.dismissRaiseClaimPopup(false);
            }}
            leftBtnClick={() => {
              this.dismissRaiseClaimPopup(true);
            }}
            rightBtnClick={this.raiseClaimContinueClicked}
            title={this.state.specialClaimSelectedItem.popupHeaderTxt}
            leftBtnTxt="No, Not now"
            rightBtnTxt="Yes, Proceed"
            content={this.state.specialClaimSelectedItem.popupTxt}
          />
        )}
      </View>
    );
  }

  raiseClaimClicked = (item) => {
    this.setState({
      specialClaimSelectedItem: item,
    });
    FlightBookingTrackingHelper.trackClickEvent(
      'Details_ChangePlan_Cancellation',
      `Flight_Sclaim_${item.scCode}`,
      this.props.response,
    );
  };

  dismissRaiseClaimPopup = (track) => {
    this.setState({
      specialClaimSelectedItem: undefined,
    });
    if (track) {
      FlightBookingTrackingHelper.trackClickEvent(
        'Sclaim_Popup',
        'Sclaim_Popup_Not_Now',
        this.props.response,
      );
    }
  };

  raiseClaimContinueClicked = () => {
    this.props.onContinueClicked(this.state.specialClaimSelectedItem);
    this.dismissRaiseClaimPopup(false);
    FlightBookingTrackingHelper.trackClickEvent(
      'Sclaim_Popup',
      'Sclaim_Popup_Proceed',
      this.props.response,
    );
  };

  onBackPress = () => {
    Actions.pop();
  };

  componentDidMount() {
    FlightBookingTrackingHelper.trackLoadEvent(
      'Details_ChangePlan_Cancellation',
      this.props.response,
    );
  }
}

CancellationOptionStandalone.propTypes = {
  response: PropTypes.object.isRequired,
  normalMsgObj: PropTypes.object,
  cancelCardActionItemClick: PropTypes.func.isRequired,
  onContinueClicked: PropTypes.func.isRequired,
};

CancellationCard.defaultProps = {
  normalMsgObj: undefined,
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#f2f2f2',
    flex: 1,
  },
  transparentBgrnd: {
    ...Platform.select({
      ios: {
        backgroundColor: 'transparent',
      },
    }),
  },
});
export default CancellationOptionStandalone;
