import { StyleSheet } from 'react-native';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';

const getStyle = (theme) => {
  const {color} = theme;
  return StyleSheet.create({
    fullPage: { flex: 1, paddingBottom: 70 },
    topSection: {
      backgroundColor: color.pink3,
      flex: 1,
      paddingTop: 65,
      paddingBottom: 78,
      alignItems: 'center',
    },
    mainconStyle: { width: 64, height: 64, marginBottom: 20 },
    detailSection: { alignItems: 'center', marginTop: -78 },
    detailsTop: {
      backgroundColor: color.lightGrey6,
      padding: 16,
      borderRadius: 4,
    },
  
    buttonBottomWrapper: {
      position: 'absolute',
      bottom: 16,
      paddingHorizontal: 8,
      width: '100%',
      alignItems: 'center',
    },
    titleHeading: {
      color: colors.red17,
      marginBottom: 35,
    },
    detailCard: {
      borderRadius: 4,
      marginHorizontal: 12,
      backgroundColor: color.white,
      ...getPlatformElevation(2),
      padding: 6,
    },
  });
} 
export default getStyle;
