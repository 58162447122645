export const DOWNLOAD_FILES_THROTTLE_INTERVAL = 3000;

const COMMON_CONSTANT = {
  ACTION_CALL_OPERATOR: 'CallUs',
  ACTION_HELP_SECTION: 'GoToHelpSection',
  ACTION_MAKE_REQUEST: 'MakeRequest',
  ACTION_CHAT_WITH_US: 'ChatWithUs',
  ACTION_CALL_SOS: 'CallSOSHotline',
  RECTANGLE_FULL_BUTTON: 'RecFullBtn',
  ROUND_BUTTON: 'RbBtnWithIcon',
  RECTANGLE_PRIMARY_FULL_WIDTH: 'CapsuleFullBtnFill',
  RECTANGLE_PRIMARY_HALF_WIDTH: 'CapsuleHalfBtnFill',
  RECTANGLE_SECONDARY_FULL_WIDTH: 'CapsuleFullBtn',
  RECTANGLE_SECONDARY_HALF_WIDTH: 'CapsuleHalfBtn',
  WHITE_BUTTON_FULL: 'CapsuleWhiteBtn',
  WHITE_BUTTON_HALF: 'CapsuleWhiteBtnHalf',
  HELPLINE_NUMBER: '0124 4628747',
  ADVISORY_NOTICE: 'Advisory Notice',
  CLOSE : 'CLOSE',
  GDPR_COOKIE: 'GDPR_C',
  GDPR_COOKIE_FOR_MYRA: 'GDPR-C'
};
export const FILTER_LOCATION_CENTER = 'center';
export const FILTER_LOCATION_TOP = 'flex-start';
export const FILTER_LOCATION_BOTTOM = 'flex-end';
export const FILTER_LOADING_COLOR = '#008cff';
export const FILTER_WIDTH = 0.2;
export const HORIZONTAL = 'Horizontal'; 

export default COMMON_CONSTANT;
