import {colors} from '@mmt/legacy-commons/Styles/globalStyles';
import {Dimensions} from 'react-native';

const windowHeight = Dimensions.get('screen').height;
const windowWidth = Dimensions.get('screen').width;

const styles = {
    overlayWrapper: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: windowHeight,
        width: windowWidth,
        elevation: 10,
        zIndex: 1000,
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        flexDirection: 'row',
        paddingBottom: 26,
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: 'rgba(0,0,0,0.5)',
        height: '100%',
        width: '100%',
        zIndex: 1,
    },
    overlayContent: {
        backgroundColor: colors.white,
        padding: 20,
        position: 'relative',
        zIndex: 1,
        width:'100%',
        bottom: -3,
    }
};

export default styles;