import { isEmpty } from 'lodash';
import React from 'react';
import { Image, Text, View } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getStyles from './bottomSheet.css';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import ButtonWrapper from 'apps/post-sales/src/Common/ButtonWrapper';


const AlternativeReasonFlowPopup = ({ handleAlternateFlowClick, theme,handleClick, hotelDetailData, detailHeading, cta, defaultCTA,isCorporateTheme}) => {    
    const { fsStyle, ...fonts } = getFont(true);
    const styles = getStyles(fonts);
    const { hotelNameHeading, checkinCheckoutText, hotelImg, roomText, icon } = hotelDetailData || {};
    const { title, subtitle } = roomText || {};
    return (
        <React.Fragment>
            {!isEmpty(detailHeading) && <Text style={[fonts.blackFontFamily, fsStyle.font14, AtomicCss.defaultText, AtomicCss.lineHeight20, AtomicCss.marginBottom10]}>{detailHeading.toUpperCase()}</Text>}
            <View style={styles.bookingDetailsWrapper}>
                <View style={styles.bookingTopDetails}>
                    <View style={AtomicCss.flex1}>
                        {!isEmpty(hotelNameHeading) && <Text style={styles.bookingTitleTxt}>{hotelNameHeading}</Text>}
                        {!isEmpty(roomText) && <View style={[AtomicCss.alignCenter,AtomicCss.flexRow]}>
                            {!isEmpty(title) && <Text style={styles.bookingInfoTxt}>{title}</Text>} 
                            <Text style={styles.bulletStyle}>{'\u2B24'}</Text>
                            {!isEmpty(subtitle) &&<Text style={styles.bookingInfoTxt}>{subtitle}</Text>} 
                        </View>}
                    </View>
                    {!isEmpty(hotelImg) && <View>
                        <Image style={styles.hotelImage} source={hotelImg}/>
                    </View>}
                </View>
                <View style={styles.bookingBottomDetails}>
                    {!isEmpty(icon) && <Image style={styles.calIconStyle} source={icon}/>}
                    {!isEmpty(checkinCheckoutText) && <Text style={[AtomicCss.defaultText,fsStyle.font14,fonts.boldFontFamily,AtomicCss.flex1]}>{checkinCheckoutText}</Text>}
                </View>
            </View>
            {cta &&
            <View style={[AtomicCss.marginBottom20,AtomicCss.marginHz20]}>
                <ButtonWrapper
                    bgColors = {isCorporateTheme ? "myBiz" : null}
                    btnText={cta.actionLabelText}
                    clickHandler={
                        ()=>{
                            handleAlternateFlowClick && handleAlternateFlowClick(cta.actionFamily);
                        }
                    }
                />
            </View>}
            {!isEmpty(defaultCTA) && 
                <ButtonWrapper
                    btnText={defaultCTA}
                    clickHandler={ () => {
                        handleClick && handleClick(true)
                     }}
                     buttonType='text'
                     btnTxtStyle={[fsStyle.font16,fonts.boldFontFamily]}
                />
            }
        </React.Fragment>

    )
};

export default AlternativeReasonFlowPopup;