import { StyleSheet } from 'react-native';

const createStyles = (fonts, theme) =>
  StyleSheet.create({
    visaCardWrapper: {
      backgroundColor: theme.color.lighterBlue,
      borderRadius: 4,
    },
    activeVisaCardWrapper: {
      backgroundColor: theme.color.grayBg,
      borderRadius: 4,
    },
    collapseHeading: {
      padding: 12,
    },
    flagIconStyle: {
      width: 28,
      height: 20,
      marginRight: 12,
    },
    selectTravelerText: {
      color: theme.color.azure,
      ...fonts.boldFontFamily,
      fontSize: 14,
    },
    arrowDownStyle: {
      width: 12,
      height: 12,
      top: 10,
      right: 10,
      paddingRight: 18,
    },
    innerMainDetails: {
      paddingHorizontal: 12,
    },
    processingTimeWrapper: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    clockGreenStyle: {
      width: 14,
      height: 14,
      marginRight: 5,
    },
    activeArrow: {
      transform: [
        {
          rotate: '-180deg',
        },
      ],
    },
    countryNameText: {
      ...fonts.boldFontFamily,
      color: theme.color.black,
      fontSize: fonts.fontSize.font16,
    },
    visaKeyText: {
      ...fonts.boldFontFamily,
      color:theme.color.defaultTextColor,
      fontSize: fonts.fontSize.font14,
    },
    textClass: {
      color: theme.color.defaultTextColor,
      fontSize: fonts.fontSize.font14,
    },
    passengerText:{
      color: theme.color.defaultTextColor,
      fontSize: fonts.fontSize.font14,
      width: '70%'
    }
  });

export default createStyles;
