import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import Actions from '../../navigation/postSalesNavigation';
import {View, Text, ScrollView, TouchableOpacity, StyleSheet, Platform} from 'react-native';
import styles, { getStyle } from './CabMamiCss';
import CabBookingDetailsConstant from '../CabBookingDetailsConstant';
import CabBookingTrackingHelper from '../CabBookingTrackingHelper';
import {E_COUPON, MY_WALLET} from '../../PostSalesConstant';
import {getECouponAmt, handleClickEvent} from '../utils/CabBookingDetailUtil';
import CabRefundOverlay from './CabRefundOverlay';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import BlackPriceBreakup from '../../Common/BlackPriceBreakup';
import ReasonForCancellation from './ReasonForCancellation';
import BaseButton, { BUTTON_VARIANT } from '../../Common/BaseButton';
import { getStaticData } from '../../staticData/staticData';
import { ThemeContext } from '../../theme/theme.context';
import HTMLView from 'react-native-htmlview';
import { getFont } from '../../PostSalesStyles';
import ButtonWrapper from '../../Common/ButtonWrapper';
// import BottomSheetWpr from '../../Common/BottomSheetWpr';
import ModificationPrompt from './ModificationPrompt'
import NewCommonOverlay from '../../Common/NewCommonOverlay';
export const pageName = 'Mob:Customer Support:Cab:TripDetails:CancelBooking:RefundAmt';

class CancellationInfoCard extends React.Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);
    this.state = {
      isOverlayVisible: false,
      showPromptOverlay: false,
      promptData: {},
      radiobtn: 'Refund_To_Customer',
      cancellationRules : this.props.response && this.props.response.cancellationReasonList,
      enableContinueCta : (!(this.props.response && this.props.response.cancellationReasonList))
    };
    this.toggleOverlay = this.toggleOverlay.bind(this);
    this.onConfirmClickHandler = this.onConfirmClickHandler.bind(this);
    this.response = this.props.response;
    this.carDetailInfo = this.response.carBookingInfo.carDetail;
    this.paymentDetails = this.response.bookingPaymentDetail;
    this.penalty = this.props.penalty;
    this.cardInfo = this.getCardDetails(this.paymentDetails);
    this.paidByCard = this.getAmtPaidByCard(this.paymentDetails);
    this.walletBonus = this.getWalletBonus(
      this.paymentDetails,
      this.paidByCard
    );
    this.cancellationCharges = this.getCancellationCharges(this.penalty);
    this.cancellationReason = this.props.response && this.props.response.cancellationReasonList && ((isEmpty(this.props.response.cancellationReasonList)) ? ('') : (this.props.response.cancellationReasonList[0].reason));

  }

   getTheSelectedData = (selectedReason,index,otherInfo) => {
     if(!isEmpty(otherInfo)){
       this.cancellationReason = `${selectedReason?.reason}:${otherInfo}`;
     }else{
       this.cancellationReason = `${selectedReason?.reason}`;
     }

     const newCancellationRules = cloneDeep(this.props.response.cancellationReasonList);
     for(let i = 0; i < this.props.response.cancellationReasonList.length; i++) {
       newCancellationRules[i].selected = false;
     }
     newCancellationRules[index].selected = true;
     this.setState({
      cancellationRules:newCancellationRules,
      enableContinueCta: true,
      promptData : selectedReason?.prompt
    })

     this.props.response?.modificationAllowed &&
       !isEmpty(selectedReason?.prompt) &&
       this.setState({ showPromptOverlay: true });
  };

  render() {
    const {
      totalRefundAmount,
      totalCancelationCharges,
      totalPaidAmount,
      refundText
    } = this.props.fareBreakUpObject;
    const { continueText } = getStaticData(false);
    const activeDtls = this.state.fareBreakupDtls
      ? 'Hide Breakup'
      : 'Show Breakup';
    const {psTheme} = this.context;
    const styleCss = getStyle(psTheme);

    return (
      <View>
        <ScrollView>
          <View style={[AtomicCss.greyBg, styles.mar10Bt]}>
            <View style={[styles.flexRow, styles.cancelBreadcrumb]}>
              <View style={styles.flexRow}>
                <Text style={[styles.mar5Rt, styles.activeBreadCrumb]}>1</Text>
                <Text style={styles.activeBreadCrumb}>View Refund Amount</Text>
              </View>
              <View style={styles.flexRow}>
                <Text style={[styles.mar5Rt, styles.darkGrey]}>2</Text>
                <Text style={styles.darkGrey}>Confirm Cancellation</Text>
              </View>
            </View>
            <View style={[styles.padding16, styles.borderbtm, styles.MamiCard]}>
              <Text style={styles.bookedCabType}>
                {this.carDetailInfo.fromCity} - {this.carDetailInfo.toCity}
              </Text>
            </View>

            <ScrollView>
              <View style={[AtomicCss.whiteBg, AtomicCss.elevation1]}>
                <View
                  style={[
                    styles.borderbtm,
                    {
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between'
                    }
                  ]}
                >
                  <View style={[AtomicCss.flexRow, styles.CancPriceBreckup]}>
                    <View style={styles.CancLeftSection}>
                      <Text style={[styles.fbHeadingTxt]}>Total Paid</Text>
                      {!this.props.hideBreakup && (
                        <TouchableOpacity
                          onPress={this.handleFareBreakup}
                          activeOpacity={0.5}
                        >
                          <Text style={styles.linkTxt}>{activeDtls}</Text>
                        </TouchableOpacity>
                      )}
                      {}
                    </View>
                    <View>
                      <Text
                        style={[
                          AtomicCss.blackText,
                          AtomicCss.font14,
                          AtomicCss.boldFont,
                          AtomicCss.marginBottom8
                        ]}
                      >
                        &#8377; {totalPaidAmount}{' '}
                      </Text>
                      {}
                    </View>
                  </View>
                  {(this.props.hideBreakup ||
                    (this.props.paidComponent && this.state.fareBreakupDtls)) &&
                    this.addBreakup(this.props.paidComponent)}
                </View>

                <View style={[styles.borderbtm]}>
                  <View style={[AtomicCss.flexRow, styles.CancPriceBreckup]}>
                    <View style={styles.CancLeftSection}>
                      <Text style={[styles.fbHeadingTxt]}>Deductions</Text>
                      {}
                    </View>
                    <View>
                      <Text
                        style={[
                          AtomicCss.blackText,
                          AtomicCss.font14,
                          AtomicCss.boldFont,
                          AtomicCss.marginBottom8
                        ]}
                      >
                        {totalCancelationCharges && totalCancelationCharges > 0 ?
                          `- ₹ ${totalCancelationCharges} ` : `₹ ${totalCancelationCharges} `}
                      </Text>
                      {}
                    </View>
                  </View>

                  {this.props.deductionComponent &&
                    this.addBreakup(this.props.deductionComponent)}
                </View>

                <View style={[AtomicCss.flexRow, styles.CancPriceBreckup]}>
                  <View style={styles.CancLeftSection}>
                    <Text
                      style={[
                        totalRefundAmount <= 0
                          ? styles.impInfoRed
                          : styles.impInfoGreen
                      ]}
                    >
                      {refundText}
                    </Text>
                  </View>
                  {totalRefundAmount > 0 && (
                    <View>
                      <Text
                        style={[
                          styles.impInfoGreen,
                          AtomicCss.boldFont,
                          AtomicCss.marginBottom8
                        ]}
                      >
                        &#8377; {totalRefundAmount}
                      </Text>
                    </View>
                  )}
                  {totalRefundAmount <= 0 && (
                    <View>
                      <Text
                        style={[
                          styles.impInfoRed,
                          AtomicCss.boldFont,
                          AtomicCss.marginBottom8
                        ]}
                      >
                        &#8377; {totalRefundAmount}{' '}
                      </Text>
                    </View>
                  )}
                </View>
              </View>
              {!!this.props.mmtBlackCancellationText &&
              <View style={[AtomicCss.paddingHz16, AtomicCss.paddingVertical10, styleCss.mmtBlackTextWrap]}>
                <Text style={styleCss.mmtBlackText}>{this.props.mmtBlackCancellationText}</Text>
                </View>
              }
            </ScrollView>
            {this.props.response &&
              this.props.response.cancellationReasonList &&
              !isEmpty(this.props.response.cancellationReasonList) && (
                <ReasonForCancellation
                  reasons={this.state.cancellationRules}
                  getTheSelectedData={this.getTheSelectedData}
                ></ReasonForCancellation>
              )}
            {this.state.enableContinueCta && (
              <>
                <View style={{ padding: 16 }}>
                  <ButtonWrapper
                    clickHandler={this.onConfirmClickHandler}
                    btnText={continueText}
                    // btnWrpStyle = {{marginLeft: 16}}
                    btnTxtStyle={[
                      styles.WhiteText,
                      styles.regularFont,
                      styles.CenterText,
                      styles.transperantBgrnd,
                      styles.font14,
                    ]}
                  />
                </View>
              </>
            )}
          </View>
         {Platform.OS !== 'web' && <View style={styles.cabRefundZeroScreen} />}
        </ScrollView>
        {this.state.isOverlayVisible && (
          <CabRefundOverlay
            backPress={this.onOverlayBackPress}
            selectedRefundMode="Refund_To_Customer"
            bookingId={this.props.response.bookingId}
            pageName={pageName}
            email={this.props.response.primaryCustomerDetails.emailId}
            cancellationReason={this.cancellationReason}
            isRefundPage = {false}
          />
        )}
        {this.state.showPromptOverlay && (
        <NewCommonOverlay
          handleBottomOverlay={this.modificationPromptToggle}
          roundBorder={true}
          >
          <ModificationPrompt
            promptData={this.state.promptData}
            promptActionHandler={this.promptActionHandler}
            closePrompt={this.modificationPromptToggle}
            pageName={pageName}
          />
        </NewCommonOverlay>
        )}
      </View>
    );
  }
  getPaymentAmount(paymentDetails) {
    return paymentDetails ? paymentDetails.paymentAmount : 0;
  }

  handleFareBreakup = () => {
    this.setState({
      fareBreakupDtls: !this.state.fareBreakupDtls
    });
  };

  addBreakup = components => components.map((component) => {
    const fonts = getFont(true);
    const {psTheme} = this.context;
    const {color} = psTheme;
    const htmlStyle = getHtmlStyle(fonts, color, component.htmlTextColor);
    if (!isEmpty(component.key) && component.key === 'loyaltyDiscount' && this.penalty.loyaltyDetails) {
      return (<View style={[AtomicCss.flexRow, styles.priceBreakups]}>
        <BlackPriceBreakup
          priceBreakupBlackText={component.label}
          priceBreakupBlackValue={component.value}
          tierMetaInfo={this.penalty.loyaltyDetails.tierMetaInfo}
          tierType={this.penalty.loyaltyDetails.tierType}
        />
      </View>);
    }
    return (<View style={[AtomicCss.flexRow, styles.priceBreakups]}>
      <View style={styles.CancLeftSection}>
        {component.isHtmlText ?
        <HTMLView value={component.label} stylesheet={htmlStyle} /> :
        <Text style={[styles.fareBrkupRowLineTxt]}>{component.label}</Text>
        }
        {}
      </View>
      <View>
        <Text style={[styles.fareBrkupRowLineTxt]}>{component.value}{' '}</Text>
        {}
      </View>
    </View>);
  })

  getCardDetails(payment) {
    let details = '';
    if (payment.paymentDetails) {
      payment.paymentDetails.forEach((modeDetails) => {
        if (
          modeDetails.paymentModeName !== E_COUPON &&
          modeDetails.paymentGatewayCode !== MY_WALLET
        ) {
          details = `${modeDetails.bankName} ${
            modeDetails.newModeOfPaymentName
          } ${modeDetails.cardNo}`;
        }
      });
    }
    return details;
  }

  getAmtPaidByCard(payment) {
    let amt = 0;
    if (payment.paymentDetails) {
      payment.paymentDetails.forEach((modeDetails) => {
        if (
          modeDetails.paymentModeName !== E_COUPON &&
          modeDetails.paymentGatewayCode !== MY_WALLET
        ) {
          amt += modeDetails.amount;
        }
      });
    }
    return amt;
  }

  getWalletBonus(paymentDetails, paidByCard) {
    const totalPaid =
      paymentDetails.paymentAmount + getECouponAmt(paymentDetails);
    return totalPaid - paidByCard;
  }

  getCancellationCharges(penaltyAmt) {
    let amt = 0;
    if (penaltyAmt) {
      amt = penaltyAmt.penaltyAmount + penaltyAmt.markupAmount;
      amt -= penaltyAmt.waiverAmount;
    }
    return amt;
  }

  onConfirmClickHandler() {
    if (!(this.penalty.refundAmount > 0)) {
      this.toggleOverlay();
    } else {
      Actions.CancellationRefundMode({
        response: this.response,
        penaltyResponse: this.penalty,
        cancellationReason : this.cancellationReason,
        isMyGate: this.props.isMyGate
      });
    }
    CabBookingTrackingHelper.trackClickEvent(
      pageName,
      CabBookingDetailsConstant.CLICK_CANCEL_CONTINUE
    );
  }

  promptActionHandler = (action) => {
    const {actionFamily} = action || {};
    if (actionFamily === CabBookingDetailsConstant.ACTION_PROCEED_WITH_CANCEL) {
      this.onConfirmClickHandler();
    }else{
      handleClickEvent({} , action, this.props.response, pageName);
    }
    this.modificationPromptToggle();
  }

  toggleOverlay() {
    this.setState({
      isOverlayVisible: !this.state.isOverlayVisible
    });
  }

  onOverlayBackPress = () => {
    this.toggleOverlay();
  };

  modificationPromptToggle = () => {
    this.setState({
      showPromptOverlay: !this.state.showPromptOverlay
    });
  }

  onHardBackPress = () => {
    if (this.state.isOverlayVisible) {
      this.toggleOverlay();
      return true;
    }
    return false;
  };
}
CancellationInfoCard.propTypes = {
  response: PropTypes.object.isRequired,
  penalty: PropTypes.object.isRequired
};

const getHtmlStyle = (fonts, color, textColor) => StyleSheet.create({
  p : {
    fontSize: fonts.fontSize.font14,
    ...fonts.regularFontFamily
  },
  b : {
    color: textColor || color.lightYello
  }
})

export default CancellationInfoCard;
