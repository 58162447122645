import { StyleSheet } from "react-native";

export default (theme) => StyleSheet.create({
    topImageStyle: {
      width: '100%',
      height: 86,
      resizeMode: 'cover',
      borderTopRightRadius: 16,
      borderTopLeftRadius: 16,
      backgroundColor: theme.color.grey35,
    },
    tripTagInfo: {
      borderRadius: 4,
      paddingHorizontal: 4,
      paddingVertical: 2,
      position: 'absolute',
      left: 12,
      top: 10,
      zIndex: 11,
      width: 70,
      height: 25,
    },
    innerDetails: {
      padding: 12,
    },
    linkWrapper: {
      paddingTop: 20,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: 12,
    },
    rowSection: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    leftInfo: {
      width: '45%',
    },
    rightInfo: {
      alignSelf: 'flex-end',
      justifyContent: 'flex-end',
    },
    imageWrapper: {
      marginRight: 10,
      width: 36,
      height: 36,
    },
    animatedOuter: {
      backgroundColor: theme.color.grey36,
      position: "relative",
      overflow: "hidden",
      borderRadius: 8,
    },
    shimmerOne: {
      width: 70,
      height: 20,
    },
    shimmerTwo: {
      width: 80,
      height: 20,
    },
    shimmerThree: {
      width: 40,
      height: 20,
    },
    shimmerFour: {
      width: 180,
      height: 34,
    },
    shimmerLink: {
      width: 75,
      height: 14,
    },
  });