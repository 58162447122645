import React from 'react';
import PropTypes from 'prop-types';
import {Image, Text, View} from 'react-native';
import {Actions} from 'react-native-router-flux';
import Card from '../Card';
import {colors, fonts} from '../../../Styles/globalStyles';
import {backIcon} from '../../../Helpers/displayHelper';
import TouchableRipple from '../TouchableRipple';
import { getImageTransformStyling } from 'apps/post-sales/src/utils/PostSaleUtil';

const SimpleHeader = ({
  icon,
  title,
  iconPress,
  elevation,
  textFont,
  textColor,
  textSize,
  rightIcon,
  rightIconPress,
  extraBottom,
  subtitle,
  alignHeader = 'center',
  rightChild,
  childLeftToRightIcon,
}) => {
  const subtitleProp = {...defaultSubtitle, ...subtitle};
  return (
    <Card
      style={{
        marginHorizontal: 0, marginVertical: 0, zIndex: 10, paddingBottom: extraBottom
      }}
      elevation={elevation}
    >
      <View style={{
        flexDirection: 'row',
        alignItems: 'center',
        height: 54
      }}
      >
        <TouchableRipple onPress={iconPress} feedbackColor={colors.transparent}>
          <View style={{
            width: 44, height: 44, flex: 0, justifyContent: 'center', alignItems: 'center'
          }}
          >
            <Image source={icon} style={[getImageTransformStyling(), {resizeMode: 'contain', height:17, width:17}]} />
          </View>
        </TouchableRipple>
        <View style={{
          flex: 1, flexDirection: 'column', justifyContent:'center', alignItems:alignHeader, marginRight: rightIcon ? 0 : 48
        }}
        >
          <Text style={{
            textAlign: 'center', fontSize: textSize, fontFamily: textFont, color: textColor
          }}
          >
            {title}
          </Text>
          {!!subtitleProp.text &&
            <Text style={{
              textAlign: 'center', fontSize: subtitleProp.size, fontFamily: subtitleProp.font, color: subtitleProp.color
            }}
            >
              {subtitleProp.text}
            </Text>
          }
        </View>
        { childLeftToRightIcon }
        {
          rightIcon &&
          <TouchableRipple onPress={rightIconPress} feedbackColor={colors.transparent}>
            <View style={{
              width: 48, height: 44, flex: 0, justifyContent: 'center', alignItems: 'center'
            }}
            >
              <Image style={{width: 24, height: 24, resizeMode: 'contain'}} source={rightIcon} />
            </View>
          </TouchableRipple>
      }
      {  rightChild}
      </View>
    </Card>);
};

SimpleHeader.propTypes = {
  icon: Image.propTypes.source,
  rightIcon: Image.propTypes.source,
  elevation: PropTypes.number,
  title: PropTypes.string.isRequired,
  iconPress: PropTypes.func,
  rightIconPress: PropTypes.func,
  textFont: PropTypes.string,
  textColor: PropTypes.string,
  textSize: PropTypes.number,
  extraBottom: PropTypes.number,
  subtitle: PropTypes.object,
  rightChild:PropTypes.elementType
};

SimpleHeader.defaultProps = {
  icon: backIcon,
  rightIcon: null,
  elevation: 4,
  iconPress: () => Actions.pop(),
  rightIconPress: () => {},
  textFont: fonts.medium,
  textColor: colors.defaultTextColor,
  textSize: 18,
  extraBottom: 0,
  subtitle: defaultSubtitle
};

const defaultSubtitle = {
  text: null,
  font: fonts.regular,
  color: colors.lightTextColor,
  size: 12
};

export default SimpleHeader;
