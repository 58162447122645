
import React, { useEffect } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import BaseButton, { BUTTON_VARIANT } from '../../../Common/BaseButton';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import { MODIFICATION_TYPE } from '../../details/HotelBookingDetailsConstant';
import HotelBookingTrackingHelper from '../../HotelBookingTrackingHelper';
import { ADD_GUEST_CONFIRM_OVERLAY_SHOWN, CHANGE_DATE_CONFIRM_OVERLAY_SHOWN } from '../HotelModificationConstants';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import ButtonWrapper from 'apps/post-sales/src/Common/ButtonWrapper';

const ConfirmationPopup = ({ dismiss, handleCTAClick, corporateBooking, heading = '', desc = '', modificationType = MODIFICATION_TYPE.DATE_CHANGE, pageName, hotelDetailsResponse, ctaText = '', trackingSuffix = '' }) => {
  const { fsStyle, ...fonts } = getFont(true);
  const styles = getStyles(fonts);
  useEffect(() => {
    const showEvent = modificationType === MODIFICATION_TYPE.DATE_CHANGE ? CHANGE_DATE_CONFIRM_OVERLAY_SHOWN : ADD_GUEST_CONFIRM_OVERLAY_SHOWN;
    const trackingData = !!trackingSuffix ? `${showEvent}_${trackingSuffix}` : showEvent;
    HotelBookingTrackingHelper.trackShowEvent(pageName, trackingData, hotelDetailsResponse);
  }, []);

  return (
      <>
        <View style={AtomicCss.marginBottom10}>
          <View style={styles.overlayTopline} />
        </View>
        <View style={[AtomicCss.marginBottom24]}>
          <Text style={[styles.title, AtomicCss.marginBottom8]}>{heading}</Text>
          <View>
            <Text style={[fonts.regularFontFamily, fsStyle.font16, AtomicCss.blackText, styles.lineHeight22, AtomicCss.marginBottom12]}>{desc}</Text>
          </View>
        </View>
         <View style={styles.btnWrapper}>
          <ButtonWrapper
            clickHandler={handleCTAClick}
            btnText={ctaText}
            buttonTextStyle={fsStyle.font16}
          />
          </View>
      </>
  )
}

const getStyles = (fonts) => {
  return StyleSheet.create({
    overlayTopline: {
      width: 40,
      height: 4,
      borderRadius: 4,
      backgroundColor: '#c2c2c2',
      alignSelf: 'center',
    },
    title: {
      color: colors.black,
      ...fonts.font22,
      fontFamily: fonts.black,
      marginBottom: 30,
      lineHeight: 32
    },
    btnWrapper:{
      paddingBottom:10,
    },
    lineHeight22: { lineHeight: 22 },
  })
}

export default React.memo(ConfirmationPopup);
