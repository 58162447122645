import React, { useEffect } from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import createStyles from '../NameChangeBottomSheetCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import RequestProcessCard from '../RequestProcessCard';
import { ANDROID_BACK_ARROW } from '../../../../../FlightImageConstants';
import FlightBookingTrackingHelper from '../../../../../modules/details/helper/FlightBookingTrackingHelper';
import BaseButton, { BUTTON_VARIANT } from '../../../../../../Common/BaseButton';
import { getStaticData } from '../../../../../../staticData/staticData';
import { getFont } from '../../../../../../PostSalesStyles';
import { useTheme } from '../../../../../../theme/theme.context';
import { FLIGHT_THROTTLE_OBJECT } from '../../../../details/FlightBookingDetailsConstant';

const SubmitRequest = ({ reasonSelected, data, goToNext, handleBack, responseJson }) => {
  const {
    heading: nameCorrectionSubmitRequestHeader,
    subHeading: nameCorrectionSubmitRequestSubHeader,
    actionButton,
    footerTitle,
    footerDescList,
  } = data;

  const staticData = getStaticData();
  const { submiRequestText } = staticData;
  const { psTheme: theme } = useTheme();
  const fonts = getFont(true);
  const styles = createStyles(theme, fonts);

  useEffect(() => {
    FlightBookingTrackingHelper.trackLoadEvent(
      'Special request: Name Change pop up submit a request',
      responseJson,
      'nameChange',
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <View style={styles.submitReqWrapper}>
      <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom20]}>
        <TouchableOpacity style={styles.backArrowWrapper} onPress={handleBack}>
          <Image source={ANDROID_BACK_ARROW} style={styles.backArrowStyle} />
        </TouchableOpacity>
        {!!reasonSelected.ruleNameTitle && (
          <Text
            style={[
              AtomicCss.blackFont,
              AtomicCss.font12,
              AtomicCss.midGreyText,
              AtomicCss.lineHeight20,
            ]}
          >
            {reasonSelected.ruleNameTitle}
          </Text>
        )}
      </View>
      {!!nameCorrectionSubmitRequestHeader && (
        <Text style={[styles.title, AtomicCss.marginBottom8]}>
          {nameCorrectionSubmitRequestHeader}
        </Text>
      )}
      {!!nameCorrectionSubmitRequestSubHeader && (
        <Text
          style={[
            AtomicCss.regularFont,
            AtomicCss.font14,
            AtomicCss.defaultText,
            AtomicCss.lineHeight20,
            AtomicCss.marginBottom24,
          ]}
        >
          {nameCorrectionSubmitRequestSubHeader}
        </Text>
      )}
      <BaseButton
        text={actionButton.text || submiRequestText}
        clickHandler={goToNext}
        variant={BUTTON_VARIANT.PRIMARY}
        disable={actionButton && actionButton.disable}
        textStyle={AtomicCss.font16}
        shadow
        {...FLIGHT_THROTTLE_OBJECT}
      />
      <RequestProcessCard data={{ footerTitle, footerDescList }} />
    </View>
  );
};

export default SubmitRequest;
