import React, { useCallback, useEffect } from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import Actions from '../../../navigation/postSalesNavigation';
import CommonHeader from '../../details/components/CommonHeader'
import getStyles from '../../details/components/HotelMamiCss';
import { WHITE_CROSS_ICON } from '../../HotelImageConstants';
import { getActionButton, getButtonStyle, handleClickEvent, isIntlHotelBooking, isNotNullAndEmpty } from '../../details/utils/HotelBookingDetailUtil';
import HotelBookingTrackingHelper from '../../HotelBookingTrackingHelper';
import useHardwareBackpress from '../../../Common/useHardwareBackpress';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const PartialCancellation = ({ response, actionCard = {}, pageName, headerText }) => {
  const { ...fonts } = getFont(true);
  const styles = getStyles(fonts);
  useEffect(() => {
    const trackPageName = isIntlHotelBooking(response?.lobCode)
    ? 'Mob:customer support:Hotel International:HotelPartialCancellation'
    : 'Mob:customer support:Hotel Domestic:HotelPartialCancellation';
    HotelBookingTrackingHelper.trackLoadEvent(trackPageName, response);
  }, [])

  const onBackPress = useCallback(() => {
    Actions.pop();
  }, []);

  useHardwareBackpress().addListener(onBackPress);

  const handleClick = (action) => {
    handleClickEvent(actionCard, action, response, pageName);
  };  

  return (
    <View style={[styles.whiteContainer, styles.transperantBgrnd]}>
      <CommonHeader headerText={headerText} imgSrc={WHITE_CROSS_ICON} backPressHandler={onBackPress} />
      {isNotNullAndEmpty(actionCard.actionList) &&
        <View style={getButtonStyle(actionCard.actionList)}>
          {getActionButton(actionCard, response, handleClick)}
        </View>
      }
    </View>
  )
}

PartialCancellation.propTypes = {
  response: PropTypes.object.isRequired
}

export default PartialCancellation;
