import React from 'react'
import {View, Text, StyleSheet, Image, TouchableOpacity} from 'react-native';
import AtomicCss from '../commonStyles/AtomicCss';
import { createStyles } from './previewTGChargesCss';
import { getFont } from '../../PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import isEmpty from 'lodash/isEmpty';
import { RailTGCardData } from '../types';

interface Props {
  cardData: RailTGCardData
  isPreview: boolean
}
const TGRefundInfoCard : React.FC<Props> = ({cardData, isPreview}) => {
  const fonts = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles =  createStyles(theme,fonts);
  const {refundHeading, refundInfoText, refundText, refundAmountText, refundAmountTextList } = cardData || { };
  return (
    !isEmpty(cardData) ?
      <View style={styles.tgRefundCard}>
        { !isEmpty(refundHeading) && <View style={[styles.headingSection, styles.borderBottom]}>
             <Text style={[fonts.font16,fonts.blackFontFamily,AtomicCss.blackText]}>{refundHeading}</Text>
        </View>}
        {!isPreview ? 
          (<React.Fragment>
             { !isEmpty(refundInfoText) && <View style={[styles.descWrapper, styles.borderBottom ]}>
                <Text style={[fonts.font13,fonts.blackFontFamily]}>{refundInfoText}</Text>
            </View>}
            {!isEmpty(refundText) && !isEmpty(refundAmountText) && <View style={styles.refundSection}>
                <Text style={[fonts.font14,fonts.blackFontFamily,styles.successText]}>{refundText}</Text>
                <Text style={[fonts.font14,fonts.blackFontFamily,styles.successText]}>{refundAmountText}</Text>
            </View>}
          </React.Fragment>) :  
          (<React.Fragment>
             { !isEmpty(refundInfoText) && <View style={[styles.refundDescWrapper, isEmpty(refundAmountTextList) ? AtomicCss.paddingBottom12:{} ]}>
                <Text style={[fonts.font13,fonts.blackFontFamily]}>{refundInfoText}</Text>
            </View>}
            {  !isEmpty(refundAmountTextList) && <View style={[ styles.refundTextList]}>
              {refundAmountTextList.map( (text, index) => {
                return (<View style={[ styles.refundText]} key={index}>
                    <Text style={[fonts.font13,fonts.semiBoldFontFamily]}>{index+1}{". "}</Text>
                    <Text style={[fonts.font13,fonts.semiBoldFontFamily, {flex: 4}]}>{text}</Text>
                </View>)
              })}
              </View>
            }
          </React.Fragment>)
        }
    </View>: null
    
  )
}

export default TGRefundInfoCard