import React, {useEffect} from 'react';
import { View, TouchableOpacity, Text, Image } from 'react-native';
import {
  isNotNullAndEmpty,
  isIntlHotelBooking
} from '../utils/HotelBookingDetailUtil';
import getStyles from './HotelMamiCss';
import AtomicCss from '../../../acme/details/AtomicCss';
import HotelBookingTrackingHelper from '../../HotelBookingTrackingHelper';
import { BLACK_INFO_ICON } from '../../HotelImageConstants';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const  InfoIcon = BLACK_INFO_ICON;

const PaymentCardExtraCharges = ({response, extraChargeTotal, handleClickIcon}) => {
  const { ...fonts } = getFont(true);
  const styles = getStyles(fonts);
  useEffect(() => {
    const trackingPageName = isIntlHotelBooking(response?.lobCode)
      ? 'Mob:customer support:Hotel International:ExtraCharges'
      : 'Mob:customer support:Hotel Domestic:ExtraCharges';
    HotelBookingTrackingHelper.trackLoadEvent(trackingPageName, response);
  }, []);

  return (
    <View style={styles.chargesInfoWrapper}>  
      <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween]}>
        <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
          <Text style={[styles.gstTxt, AtomicCss.spaceBetween]}>{extraChargeTotal.heading}  </Text>
          <TouchableOpacity onPress={() => handleClickIcon(extraChargeTotal.heading)}>
            <Image style={styles.infoIconStyle} source={InfoIcon}/>
          </TouchableOpacity>
        </View>
        <Text style={styles.gstTxt}>{`${isNotNullAndEmpty(extraChargeTotal.extraChargeOther) ? extraChargeTotal.extraChargeOther : extraChargeTotal.extraChargeLcy} `}</Text>
      </View>
      <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween]}>
        <View style= {{flex:0.9}}>
          <Text style={[styles.extraChargeTxt]}>{extraChargeTotal.subHeading}</Text>
        </View>
        {
          isNotNullAndEmpty(extraChargeTotal.extraChargeOther) &&
          <Text style={[styles.extraChargeTxt]}>({`${extraChargeTotal.extraChargeLcy}`})</Text>
        }
      </View>
    </View> 
  );
}

export default PaymentCardExtraCharges;