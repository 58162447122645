import React, { useCallback, useState } from 'react';
import { View, Text, TouchableOpacity, Image, StyleSheet } from 'react-native';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import Checkbox from '../../Checkbox';

const RefundModeSection = ({ onChkBoxPress, title, subTitle, defaultChecked }) => {
  const [activeCheckBox, isActiveCheckBox] = useState(defaultChecked);

  const handleChkBox = useCallback(() => {
    isActiveCheckBox(!activeCheckBox);
    onChkBoxPress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onChkBoxPress]);

  return (
    <>
      <TouchableOpacity
        onPress={handleChkBox}
        style={styles.multiChkBoxWrapper}
        activeOpacity={0.7}
      >
        <Checkbox checked={activeCheckBox} containerStyle={styles.activeChkBoxStyle} onChange={handleChkBox} size={23}/>
        <View style={AtomicCss.flex1}>
          <Text
            style={[
              AtomicCss.blackText,
              AtomicCss.font16,
              AtomicCss.boldFont,
              AtomicCss.marginBottom5,
              AtomicCss.lineHeight20,
            ]}
          >
            {title}
          </Text>
          {!!subTitle && (
            <Text
              style={[
                AtomicCss.defaultText,
                AtomicCss.font13,
                AtomicCss.regularFont,
                AtomicCss.lineHeight20,
              ]}
            >
              {subTitle}
            </Text>
          )}
        </View>
      </TouchableOpacity>
    </>
  );
};

const styles = StyleSheet.create({
  chkBoxWrapper: {
    flexDirection: 'row',
    paddingHorizontal: 16,
  },
  multiChkBoxWrapper: {
    flexDirection: 'row',
    padding: 16,
    backgroundColor: colors.lightGrey6,
    borderRadius: 4,
    alignItems: 'center',
  },
  chkBoxPlaceHolder: {
    width: 18,
    height: 18,
    backgroundColor: colors.white,
    borderColor: colors.lightTextColor,
    marginRight: 15,
  },
  activeChkBoxStyle: {
    top: -3,
    left: -4,
  },
});

export default React.memo(RefundModeSection);
