import React from 'react';
import {Image, Text, View} from 'react-native';
import PropTypes from 'prop-types';
import styles from '../styles/CabMamiCss';
import {getECouponAmt} from '../utils/CabBookingDetailUtil';
import {appendRupeeSymbol} from '../../hotel/details/utils/HotelBookingDetailUtil';
import {priceBreakUpImage} from "../CabImageConstants";

class FareDetails extends React.Component {
  constructor(props) {
    super(props);
    this.paymentDetails = this.props.response.bookingPaymentDetail;
    this.eCouponAmt = getECouponAmt(this.paymentDetails);
    this.pricing = this.props.response.pricingSummary;
    this.GST = this.getGST(this.pricing);
  }
  render() {
    return (
      <View style={[styles.MamiCard, styles.mar10Bt]}>
        <View style={styles.CardContent}>
          <Image
            style={styles.CardIcon}
            source={priceBreakUpImage}
          />
          <View style={styles.ContentPortion}>
            <View>
              <View style={styles.Paymentrow}>
                <View style={styles.PaymentLeft}>
                  <Text style={[styles.BlackText, styles.blackFont]}>PRICE BREAKUP</Text>
                </View>
              </View>
              <View style={[styles.Paymentrow, styles.borderbtm, styles.padVertical10]}>
                <View>
                  <Text style={styles.PaymentLeft}>Base Price</Text>
                </View>
                <Text
                  style={styles.BlackText}
                >{`₹ ${ this.paymentDetails.sellingPrice - this.GST} `}
                </Text>
              </View>
              <View
                style={[styles.Paymentrow, styles.borderbtm, styles.padVertical10]}
              >
                <View>
                  <Text style={styles.PaymentLeft}>GST</Text>
                </View>
                <Text
                  style={styles.BlackText}
                >{this.GST}
                </Text>
              </View>

              <View
                style={[this.eCouponAmt > 0 ? styles.showView : styles.hideView, styles.Paymentrow, styles.borderbtm, styles.padVertical10]}
              >
                <View>
                  <Text style={[styles.PaymentLeft, styles.greenText]}>Coupon Discount</Text>
                </View>
                <Text
                  style={[styles.greenText, {marginRight: 4}]}
                >-{ this.eCouponAmt}
                </Text>
              </View>
              <View style={[styles.Paymentrow, styles.pad10Top]}>
                <View style={styles.PaymentLeft}>
                  <Text style={[styles.BlackText, styles.blackFont]}>Total Amount</Text>
                </View>
                <Text
                  style={[styles.BlackText, styles.blackFont, {marginRight: 4}]}
                > { appendRupeeSymbol(this.paymentDetails.sellingPrice - this.eCouponAmt) }
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  }

  getBaseFare(pricing) {
    let amt = 0;
    pricing.pricingbreakups.forEach((pricingbreakups) => {
      if (pricingbreakups.pricingType === 'Booking') { amt = pricingbreakups.baseAmount + pricingbreakups.commissionAmount; }
    });
    return amt;
  }
  getGST(pricing) {
    let amt = 0;
    pricing.pricingbreakups.forEach((pricingbreakups) => {
      if (pricingbreakups.pricingType === 'Booking') { amt = pricingbreakups.taxAmount; }
    });
    return amt;
  }
}
FareDetails.propTypes = {
  response: PropTypes.object.isRequired
};

export default FareDetails;
