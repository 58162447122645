import React, { useEffect } from 'react';
import { View, Text, TouchableOpacity, Image, StyleSheet } from 'react-native';
import getStyles from './styles';
import { useTheme } from '../../../theme/theme.context';
import { getFont } from '../../../PostSalesStyles';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { CROSS_ICON } from '../../CabImageConstants';
import ButtonWrapper from 'apps/post-sales/src/Common/ButtonWrapper';
import isEmpty from 'lodash/isEmpty';
import CabBookingTrackingHelper from '../../CabBookingTrackingHelper';
import { CLICK_EVENT } from '../../CabBookingDetailsConstant';

interface ActionItem {
  actionType: string;
  actionId: string;
  actionLabeltext: string;
  actionFamily: string;
}

interface PromptData {
  heading: string;
  subHeading: string;
  description: string;
  actionList: ActionItem[];
}

interface ModificationPromptProps {
  promptData: PromptData;
  promptActionHandler: (item: ActionItem) => void;
  closePrompt: () => void;
  pageName: string
}

const ModificationPrompt: React.FC<ModificationPromptProps> = ({ promptData, promptActionHandler, closePrompt, pageName }) => {
  const { heading, subHeading, description, actionList } = promptData || {};
  const { fsStyle, ...fonts } = getFont();
  const { psTheme: theme } = useTheme();
  const styles = getStyles(fonts, theme);

  useEffect(() => {
    CabBookingTrackingHelper.trackClickEvent(
      pageName,
      CLICK_EVENT.MODIFY_PROMPT_SHOWN
    );
  }, []);

  return (
    <View style={{backgroundColor: theme.color.white}}>
      <View style={[styles.headingSection]}>
        {!!heading && <Text style={[styles.headingText]}>{heading}</Text>}
        <TouchableOpacity style={styles.crossIconWrapper} onPress={closePrompt}>
          <Image style={styles.crossIconStyle} source={CROSS_ICON} />
        </TouchableOpacity>
      </View>

      <View style={styles.modifyInfoBox}>
        {!!subHeading && <Text style={[styles.titleText]}>{subHeading}</Text>}
        {!!description && (
          <Text style={styles.description}>
            {description}
          </Text>
        )}
      </View>
      {!isEmpty(actionList) && (
        <View style={styles.btnWrapper}>
          {actionList.map((item) => {
            const { actionType, actionId, actionLabeltext } = item || {};
            return (
              <View key={actionId} style={styles.buttonView}>
                <ButtonWrapper
                  clickHandler={() => promptActionHandler(item)}
                  btnText={actionLabeltext}
                  btnTxtStyle={styles.viewBreakupTextStyle}
                  buttonType={actionType}
                  buttonWidth="full"
                />
              </View>
            );
          })}
        </View>
      )}
    </View>
  );
};

export default ModificationPrompt;