import React from 'react';
import { View, Text, TouchableOpacity, Image, StyleSheet } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { WC_SUCCESS_ICON, WC_DELAY_ICON, WC_FAILED_ICON } from '../../../../FlightImageConstants';
import { getFont } from '../../../../../PostSalesStyles';
import isEmpty from 'lodash/isEmpty';

const WebCheckInStatus = ({
  header,
  desc,
  status,
  action,
  wcStatusCardActionHandler,
  segIndex,
}) => {
  const { fsStyle, ...fonts } = getFont(true);
  let iconImg;
  switch (status) {
    case 'SUCCESS':
      iconImg = WC_SUCCESS_ICON;
      break;
    case 'PENDING':
      iconImg = WC_DELAY_ICON;
      break;
    case 'FAILED':
      iconImg = WC_FAILED_ICON;
      break;
    default:
      iconImg = WC_SUCCESS_ICON;
      break;
  }
  return (
    <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween, styles.webChecinSection]}>
      <View style={[AtomicCss.flex1, AtomicCss.flexRow]}>
        <View style={styles.webCheckinWrapper}>
          <Image style={styles.WebCheckinIcon} source={iconImg} />
        </View>
        <View style={[AtomicCss.justifyCenter, AtomicCss.flex1]}>
          {!isEmpty(header) && (
            <Text
              style={[
                fsStyle.font13,
                AtomicCss.defaultText,
                fonts.boldFontFamily,
                AtomicCss.marginBottom3,
                AtomicCss.alignLeft,
              ]}
            >
              {header}
            </Text>
          )}
          {!isEmpty(desc) && (
            <Text
              style={[
                fsStyle.font12,
                AtomicCss.defaultText,
                fonts.regularFontFamily,
                AtomicCss.alignLeft,
              ]}
            >
              {desc}
            </Text>
          )}
        </View>
      </View>

      {action && action.text && (
        <TouchableOpacity
          style={AtomicCss.marginTop5}
          onPress={() => wcStatusCardActionHandler(action, segIndex)}
        >
          <Text style={[fsStyle.font12, fonts.blackFontFamily, AtomicCss.azure]}>
            {action.text}
          </Text>
        </TouchableOpacity>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  webChecinSection: {
    backgroundColor: '#f5f5ff',
    borderRadius: 4,
    paddingHorizontal: 8,
    paddingVertical: 6,
    marginHorizontal: 8,
    marginTop: 8,
  },
  WebCheckinIcon: {
    width: 18,
    height: 12,
  },
  webCheckinWrapper: {
    width: 26,
    height: 26,
    marginRight: 10,
    backgroundColor: '#fff',
    borderRadius: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    top: 3,
  },
  linkBtnWrapper: {
    borderTopWidth: 1,
    borderTopColor: '#d2d2d2',
    paddingVertical: 10,
    flexDirection: 'row',
    marginTop: 10,
  },
  forwordIconStyle: {
    width: 12,
    marginLeft: 5,
    height: 12,
  },
});

export default WebCheckInStatus;
