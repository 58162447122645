import React, { useState } from "react"
import { View, Text } from 'react-native'
import DayPicker, { DateUtils } from 'react-day-picker';
import '../../../../../../web/Assets/css/style.css';
import '../../../../../../web/Assets/css/dayPickerReset.css';
import styles from "./OverlayCalendarCss";
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss'

const Calender = (props) => {
    
    const getInitialState = () => {
        var today = new Date();

        var tomorrow = new Date(today.getTime() + (1000 * 60 * 60 * 24));
        return {
            from: props.from,
            to: props.to,
            enteredTo: null, // Keep track of the last day for mouseEnter.
        };
    }

    const [calendarState, updateCalendarState] = useState(getInitialState());

    const getResetState = () => {
        return {
            from: null,
            to: null,
            enteredTo: null, // Keep track of the last day for mouseEnter.
        };
    }

    const handleResetClick = () => {
        updateCalendarState((prevState) => ({
            ...prevState,
            ...getResetState()
        }))
    }

    const isSelectingFirstDay = (from, to, day) => {
        const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
        const isRangeSelected = from && to;
        return !from || isBeforeFirstDay || isRangeSelected;
    }

    const handleDayClick = (day, { disabled, selected }) => {
        const { from, to } = calendarState;
        const { updateDate } = props;

        if (disabled) {
            return;
        }

        if (from && to && day >= from && day <= to) {
            handleResetClick();
            updateCalendarState((prevState) => ({
                ...prevState,
                from: day,
                to: null,
                enteredTo: null,
            }))
        }

        if (this.isSelectingFirstDay(from, to, day)) {
            updateCalendarState((prevState) =>({
                ...prevState,
                from: day,
                to: null,
                enteredTo: null,
            }))
            updateDate(day, null);
        } else {
            if (from !== day) {
                var dayDiff = Math.ceil((day - from) / (1000 * 60 * 60 * 24)) + 1;
                if (dayDiff > 30) {
                    updateCalendarState((prevState) =>({
                        ...prevState,
                        dayDiff
                    }))
                } else {
                    updateCalendarState((prevState) =>({
                        ...prevState,
                        to: day,
                        enteredTo: day,
                        dayDiff
                    }))
                    updateDate(from, day);
                }
            }
        }
    }

    const handleSingleDayClick = (day, { selected }) => {
        const { updateDate } = props;
        updateCalendarState((prevState) =>({
            ...prevState,
            from: day,
            to: null
        }))
        updateDate(day, null);
    }

    const setScroll = () => {
        let startDate = calendarState.from ? calendarState.from : props.from;
        if (startDate && startDate != null) {
            let month;
            if (new Date(startDate).getMonth() >= new Date().getMonth())
                month = new Date(startDate).getMonth() - new Date().getMonth();
            else
                month = 12 + new Date(startDate).getMonth() - new Date().getMonth()

            setTimeout(() => {
                try {

                    let offset = document.getElementsByClassName("DayPicker-Month")[month].offsetTop - 20;
                    document.getElementsByClassName("DayPicker-Months")[0].scrollTop = offset;
                } catch (e) {
                }
            }, 10);
        }
    }

    setScroll();
    const { from, to, dayDiff } = calendarState;
    const modifiers = { start: from, end: to };
    const options = { year: "numeric", month: "short", day: "numeric" };
    const today = new Date();
    const { fromText, toText, canNotBeGreaterText } = props;
    return (
        <View style={styles.container}>
            <View style={styles.header}>
                <View style={styles.headerContent}>
                    {!!fromText && <View style={(from && !to) ? [styles.dateFiled, styles.active] : styles.dateFiled}>
                        <Text style={[styles.subHeading, AtomicCss.alignLeft]}> {fromText} </Text>
                        <Text style={[styles.dateContainer, AtomicCss.alignLeft]}>  {!from && '--'} {from && `${from.toLocaleDateString('en-GB', options)}`} </Text>
                    </View>
                    }
                    {to &&
                        <View style={(to) ? [styles.dateFiled, styles.active] : styles.dateFiled}>
                            <Text style={[styles.subHeading, AtomicCss.alignLeft]}> {toText} </Text>
                            <Text style={[styles.dateContainer, AtomicCss.alignLeft]}> {!to && '--'} {to && `${to.toLocaleDateString('en-GB', options)}`}</Text>
                        </View>
                    }
                </View>
            </View>
            <View style={{ flex: 1 }}>
                {dayDiff && dayDiff > 30 && <Text style={[styles.errorText, AtomicCss.alignLeft]} id="range_error">{canNotBeGreaterText} </Text>}
            </View>
            {!props.singleDate ? <DayPicker
                numberOfMonths={13}
                selectedDays={[from, { from, to }]}
                modifiers={modifiers}
                onDayClick={handleDayClick}
                disabledDays={{ before: today }}
            /> : <DayPicker
                numberOfMonths={13}
                selectedDays={calendarState.from}
                modifiers={modifiers}
                onDayClick={handleSingleDayClick}
                disabledDays={{ before: today }}
            />}
        </View>
    );
}
export default Calender;