import {colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles';

const styles = {
  btnTypeRound: {
    borderRadius: 4,
    overflow: 'hidden'
  },
  btnTypeRounded: {
    borderRadius: 40,
    overflow: 'hidden'
  },
  blueBtnText: {
    paddingVertical: 16,
    fontSize: 16,
    fontFamily: fonts.bold,
    color: '#ffffff',
    textAlign: 'center'
  },
  blueBtnGradient: {
    paddingHorizontal: 30
  },
  ghostBtn: {
    borderRadius: 4,
    overflow: 'hidden',
    backgroundColor: 'transparent'
  },
  ghostBtnText: {
    paddingVertical: 12,
    fontSize: 12,
    fontFamily: fonts.bold,
    color: colors.azure,
    textAlign: 'center'
  },
  WtRightArrow: {
    width: 16,
    height: 12,
    marginLeft: 10
  },
  BtnCenter: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  }
};

export default styles;
