import { StyleSheet } from 'react-native';

export default (theme, fonts) =>
StyleSheet.create({
  pageWrapper: {
    flex: 1,
    backgroundColor: '#f2f2f2'
  },
  pageScroll: {
    flex: 1
  },
  pageScrollBtmSpace: {
    height: 58
  },
  pageScrollTopSpace: {
    height: 0,
    marginTop: -5
  },
  footerBtnWrapper: {
    padding: 12,
    position: 'absolute',
    zIndex: 2,
    bottom: 0,
    left: 0,
    width: '100%'
  },
  whiteCard: {
    backgroundColor: '#ffffff',
    padding: 15,
    marginVertical: 8,
    marginBottom: 8,
    elevation: 2,
    shadowColor: '#000000',
    shadowOffset: {width: 0, height: 0},
    shadowOpacity: 0.3,
    shadowRadius: 1
  },
  reqMessageWrapper: {
    padding: 15,
    paddingTop: 8
  },
  reqMessage: {
    fontSize: 14,
    color: theme.color.defaultTextColor,
    fontFamily: fonts.regular,
    marginBottom: 6
  },
  submitReqBtnText: {
    fontSize: 12,
    color: theme.color.azure,
    fontFamily: fonts.black,
    paddingVertical: 6
  },
  headerTitle: {
    fontSize: 16,
    color: theme.color.black,
    fontFamily: fonts.bold
  },
  headerSubTitle: {
    fontSize: 12,
    fontFamily: fonts.regular,
    color: theme.color.disabledBtnBg,
    marginTop: 3
  },
  cardTitle: {
    color: theme.color.gray,
    fontSize: 21,
    fontFamily: fonts.light,
    marginBottom: 10
  },
  cardSubTitle: {
    fontSize: 14,
    color: theme.color.gray,
    fontFamily: fonts.regular,
    marginBottom: 20
  }
});

