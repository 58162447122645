import {fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from '@mmt/legacy-commons/Common/Components/Card/getPlatformElevation';


const styles ={
  cardBorder:{
    borderTopColor:'#e4e4e4',
    borderBottomColor:'#e4e4e4',
    borderTopWidth:1,
    borderBottomWidth:1,
  },
  infoCardWrapper:{
    backgroundColor:'#fff',
    justifyContent:'space-between',
    ...getPlatformElevation(1.3),
    marginBottom:1,
  },

  infoCardContent:{
      paddingHorizontal:8,
      paddingVertical:15,
      flex:1
  },

  infoCustom:{
    fontSize:14,
    color:'#4a4a4a',
    lineHeight:18,
    fontFamily:fonts.regular,
  },
  headingIconWrapper:{
    width:46,
    alignItems:'flex-start',
    top:14,
    left:16,
  },
  headingIconStyle:{
    width:17,
    height:21,
  },

  redirectBtnWrapper:{
    paddingTop:20,
    paddingBottom:20,
    paddingLeft:60,
    paddingRight:35,
    justifyContent:'space-between',
    flexDirection:'row',
    backgroundColor:'#fff',
    borderBottomWidth:1,
    borderBottomColor:'#ededed',
    alignItems:'center',

  },

  rightArrowIcon:{
    width:9,
    height:13
  },

  linkBtnTxt:{
    fontSize:14,
    fontFamily:fonts.bold,
    color:"#008cff"
  },

  disIcon:{
    width:16,
    height:16,
    marginRight:5,
  },
  greenTxt:{
    color:'#1a7971',
    fontSize:12,
  },
  totalRefundprice:{
    paddingRight:15,
  },
  priceInfoWrapper:{
    borderRadius:4,

    backgroundColor:'#dde8ff',
    padding:10,
    marginLeft:50,
    marginRight:10,
    marginBottom:15,

  },
  depositeCard:{
    borderRadius:4,
    backgroundColor:'#f5f5ff',
    padding:10,
    marginLeft:40,
    marginRight:10,
    marginBottom:15,
    flexDirection:'row',
    borderWidth:1,
    borderColor:'#d9ebff',
  },
  priceImgStyle:{
    width:20,
    height:21,
    alignSelf:'center',
    top:2,
  },
  imgPlaceHolder:{
    width:20,
    height:20,
    borderRadius:25,
    marginRight:10,
  },
  priceInfoTxt:{
    color:'#053cb2',
    fontSize:12,
    fontFamily:fonts.bold
  },
  btnWrapper:{
    paddingLeft:60,
    marginBottom:15
  },
  preferredTxt:{
    color:'#da956d'
  },
  eliteTxt:{
    color:'#9b9b9b'
  },
  exclusiveTxt:{
    color:'#c5953d'
  },
  blackIconSm:{
    width:25,
    height:23,
    marginRight:5,
  },
  blackWrpper:{
    paddingVertical:6,
    paddingLeft:50,
    paddingRight:12,
  },
}


export default styles;
