import React from 'react';
import Actions from '../../../navigation/postSalesNavigation';
import {ScrollView, View} from 'react-native';
import PropTypes from 'prop-types';
import Ticket from '../../components/Ticket';
import styles from './MetroSelectTicketCss';
import StickyHeader from '../../../hotel/details/components/StickyHeader';
import BasePage from '../../../Common/PostSalesBasePage';
import Breadcrumb from '../../components/Breadcrumb';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import BlueRectangleButton from '../../../Common/BlueRectangleButton';
import ProgressView from '@mmt/legacy-commons/Common/Components/Progress/ProgressView';

import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import MetroConstant from '../../MetroConstant';
import {HEADER_ICON} from '../../MetroImageConstant';

export const STEPS = [
  'SELECT TICKET(s)',
  'REVIEW',
  'CONFIRM'
];

export default class MetroSelectTicket extends BasePage {
  constructor(props) {
    super(props, 'metroCancelSelectTicket');

    this.allPaxSelected = false;
    this.state = {
      activeStep: 0,
      FareCancDtls: false,
      responseJson: null,
      viewState: ViewState.SHOW_DETAIL,
      selectedTicketList: []
    };

    this.trackingPageName = 'Cancel Select';
    this.lineNoList = [];
    this.ticketIdList = [];
    this.rjtIdList = [];
    this.purchaseId = props.detailResponse.bookingInfo.purchaseId;
    this.bookingId = props.detailResponse.bookingId;
  }

  componentDidMount() {
    super.componentDidMount();
  }

  render() {
    return (
      <View style={{flex: 1}}>
        {this.state.viewState === ViewState.LOADING && this.renderProgressView('Loading...')}
        {this.state.viewState === ViewState.SHOW_DETAIL && this.renderPage()}
        {this.state.viewState === ViewState.ERROR && this.renderErrorView()}
      </View>
    );
  }

    renderProgressView = message => (<ProgressView message={message} />);

    renderHeader = () => (
      <StickyHeader
        iconSrc={HEADER_ICON}
        title={this.props.headerTitle}
        headerColor={['#ffffff', '#ffffff']}
        titleStyle={[styles.headerTitle]}
        subTitle={this.props.headerSubTitle}
        subTitleStyle={[styles.DefaultText, styles.font12]}
        onBackPress={super.onBackIconPress}
      />
    );

    addTicketSegment = () => {
      const source = this.props.detailResponse.bookingInfo ? this.props.detailResponse.bookingInfo.source : "";
      const destination = this.props.detailResponse.bookingInfo ? this.props.detailResponse.bookingInfo.destination : "";
      const travelDateTime = this.props.detailResponse.bookingInfo.travelDateTime;
      const segments = [];

      if(this.props.detailResponse.bookingInfo.serviceType === MetroConstant.FORWARD_JOURNEY) {
        return (
          <Ticket
            key={this.props.detailResponse.bookingInfo.serviceType}
            retPartialNtAllowed={true}
            ticketClickHandler={this.onTicketSelectionChanged}
            source={source}
            destination={destination}
            travelDateTime={travelDateTime}
            journeyType={MetroConstant.FORWARD_JOURNEY}
            bookingInfo={this.props.detailResponse.bookingInfo}
          />
        );
      }
      else {
       return (
        <View>
          <Ticket
            key={this.props.detailResponse.bookingInfo.serviceType}
            retPartialNtAllowed={true}
            ticketClickHandler={this.onTicketSelectionChanged}
            source={source}
            destination={destination}
            travelDateTime={travelDateTime}
            journeyType={MetroConstant.RETURN_JOURNEY}
            bookingInfo={this.props.detailResponse.bookingInfo}
          />
        </View>
        )
      }

      return segments;
    }

  onTicketSelectionChanged = (ticket, selected) => {
        let ticketList = this.state.selectedTicketList;
        let bFlag = false;
    if (selected) {
      ticketList.forEach(t => {
        if (t.ticketId === ticket.ticketId) {
          bFlag = true;
          return;
        }
      });
      if (!bFlag) {
        ticketList.push(ticket);
      }
        }
        else {
          ticketList.splice( ticketList.indexOf(ticket), 1 );
        }

      this.setState({
          selectedTicketList: ticketList
        });
    }

    renderPage() {
      return (
        <View style={styles.pageWrapper}>
          {this.renderHeader()}
          <Breadcrumb
            activeStep={this.state.activeStep}
            data={STEPS}
          />
          <ScrollView showsVerticalScrollIndicator={false} style={styles.pageScroll}>
            <View style={styles.whiteCard}>
              {this.addTicketSegment()}
            </View>
            <View style={styles.pageScrollBtmSpace} />
          </ScrollView>
          <View>
            <View style={styles.footerBtnWrapper} />
            <View style={styles.buttonStyle}>
              <BlueRectangleButton
                buttonText="VIEW REFUND CALCULATION"
                clickHandler={this.onContinueClicked}
                disabled={this.checkPasSelected()}
              />
            </View>
          </View>
        </View>
      );
    }

    onContinueClicked = () => {
      let ticketIdList = this.state.selectedTicketList && this.state.selectedTicketList.map(ticket => ticket.ticketId);
      let rjtIdList = this.state.selectedTicketList && this.state.selectedTicketList.map(ticket => ticket.rjtId);
      rjtIdList = rjtIdList && rjtIdList.filter(
        (ticket, index) => rjtIdList.indexOf(ticket) === index
      );

      this.ticketIdList = ticketIdList;
      this.rjtIdList = rjtIdList;
      this.ticketLineNoList = this.state.selectedTicketList && this.state.selectedTicketList.map(ticket => ticket.lineNo);

      Actions.metroCancelReviewRefundCharge({
        headerTitle: this.props.headerTitle,
        headerSubTitle: this.props.headerSubTitle,
        ticketLineNoList: this.ticketLineNoList,
        ticketIdList: this.ticketIdList,
        rjtIdList: this.rjtIdList,
        purchaseId: this.purchaseId,
        detailResponse: this.props.detailResponse,
        isFullCancel: false
      });

    };

    renderErrorView = () => (
      <ErrorView
        showRetry
        onRetry={() => {
                this.setState({viewState: ViewState.LOADING});
            }}
      />);

    checkPasSelected = () => {
      const ticketCount = this.state.selectedTicketList.length;
      return (ticketCount==0);
    };
}

MetroSelectTicket.propTypes = {
  detailResponse: PropTypes.object.isRequired
};
