import {colors} from '@mmt/legacy-commons/Styles/globalStyles';

const styles = {
  cabImage: {
    width: 80,
    height: 40
  },
  fieldBox: {
    borderColor: colors.grey12,
    borderWidth: 1,
    marginTop: 10,
    borderRadius: 2,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 8,
    paddingVertical: 5,
    backgroundColor: colors.grey13
  },
  addressBoxIcon: {
    height: 24,
    width: 24,
    marginRight: 8
  },
  swapBtn: {
    position: 'absolute',
    overflow: 'hidden',
    height: 35,
    width: 35,
    borderRadius: 5,
    top: 41,
    right: 8,
    zIndex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.white,
    borderColor: colors.lightGrey,
    borderWidth: 1
  },
  swapIcon: {
    width: 15,
    height: 18
  },
  calendarIcon: {
    width: 24,
    height: 24,
    marginRight: 8,
    marginTop: 3
  }
};

export default styles;
