import { isEmpty } from 'lodash';
import React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import Button from '../../../../Common/Button';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import ButtonWrapper from 'apps/post-sales/src/Common/ButtonWrapper';

const ModifyYourBookingPopup = ({ handleClick, handleAlternateFlowClick, defaultCTA, ctaList }) => {
  const { fsStyle, ...fonts } = getFont(true);
  return (
    <React.Fragment>
      {!isEmpty(ctaList) &&
        ctaList.map((ctaItem) => (
          <View style={[AtomicCss.marginHz20, AtomicCss.marginBottom20]}>
            <ButtonWrapper
              clickHandler={() => {
                handleAlternateFlowClick && handleAlternateFlowClick(ctaItem.actionFamily);
              }}
              btnText={ctaItem.actionLabelText}
            />
          </View>
        ))}
      <ButtonWrapper
        clickHandler={() => {
          handleClick(true);
        }}
        btnText={defaultCTA}
        buttonType='text'
        btnTxtStyle={[fonts.boldFontFamily, fsStyle.font16]}
      />
    </React.Fragment>
  );
};

export default ModifyYourBookingPopup;
