import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Image } from 'react-native';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import { Actions } from 'react-native-router-flux';
import _isEmpty from 'lodash/isEmpty';

import SimilarPropertiesSlider from './SimilarPropertiesSlider';
import { USER_PLATFORM } from '../../Constants/SimilarPropConstants';

const SimilarProperties = ({
  heading,
  description,
  propertiesData,
  cta,
  currency
}) => {

  const clickHandler = () => {
    if (USER_PLATFORM.WEB) {
      window.location.href = propertiesData.deepLink;
    } else {
      GenericModule.openDeepLink(propertiesData.deepLink);
    }
  }

  return (
    <View style={styles.container}>
      {heading && <Text style={styles.heading}>{heading}</Text>}
      {description && <Text style={styles.desc}>{description}</Text>}
      <SimilarPropertiesSlider hotelList={propertiesData.hotelList} currency={currency} />
      {
        !_isEmpty(cta) &&
        <TouchableOpacity style={styles.ctaBtn} onPress={() => clickHandler()}>
          <Text style={styles.ctaText}>{cta}</Text>
          <Image
            source={require('../images/ic_arrow_dropdown.png')}
            style={styles.arrowIcon}
          />
        </TouchableOpacity>
      }
    </View>
  );
};

export default SimilarProperties;

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    padding: 16,
  },
  heading: {
    fontFamily: 'Lato-Bold',
    fontWeight: '700',
    color: '#000',
    fontSize: 14,
    lineHeight: 16,
    marginBottom: 8,
  },
  desc: {
    fontFamily: 'Lato-Regular',
    fontWeight: '400',
    color: '#4a4a4a',
    fontSize: 14,
    lineHeight: 18,
    marginBottom: 16,
  },
  ctaBtn: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 16,
  },
  ctaText: {
    fontFamily: 'Lato-Bold',
    fontSize: 14,
    lineHeight: 17,
    fontWeight: '700',
    color: '#008cff',
  },
  arrowIcon: {
    width: 9,
    height: 13,
    resizeMode: 'contain',
  },
});
