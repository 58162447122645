import React from 'react';
import getTgsSummaryData from './tripGuaranteeCardAdapter';
import {
  ActionsContent,
  DescriptionSection,
  MainContent,
  SummaryContainer,
  CardInfoSection,
  HeaderSection,
  JourneyStartsInfoText,
  StatusInfoSection,
  WhereToGoTopImage
} from '../cardElements';
import { MsrTicket, TgsData } from '../cardInterfaces';
import MSRRefundInfoCard from '../MSRRefundInfoCard';

interface TgsSummaryCardProps {
  response: TgsData;
  msrTicket: MsrTicket;
  isUpcomingOrOnTripFirstBooking: boolean,
  topImage: string
}

function TripGuaranteeSummaryCard({ response, msrTicket, isUpcomingOrOnTripFirstBooking, topImage }: TgsSummaryCardProps) {
  const cardData = getTgsSummaryData(response, msrTicket);
  const {
    heading,
    openDetails,
    name,
    trainNumber,
    iconUrl,
    pnrText,
    actions,
    pnr,
    startsIn,
    myRequestsCard,
    infoCardStatusText,
    infoCardStatusCTA,
    lobName,
    bookingId,
    status,
    handleInfoPress } = cardData || {};
  return (
    <SummaryContainer bookingId={response.bookingID}>
      {topImage && isUpcomingOrOnTripFirstBooking &&
        <WhereToGoTopImage topImageText={startsIn} topImage={topImage} />
      }
      <MainContent onPress={openDetails}>
        <DescriptionSection>
          <CardInfoSection
            name={name}
            passengerDetails={trainNumber}
          />
          <HeaderSection iconUrl={iconUrl} name={heading} />
          <JourneyStartsInfoText startsOnText={pnrText} startDate={pnr} />
          {infoCardStatusText && <StatusInfoSection statusText={infoCardStatusText} statusCta={infoCardStatusCTA} handleInfoPress={handleInfoPress} />}
        </DescriptionSection>
      </MainContent>
      <ActionsContent actions={actions} isInfoCardShown={!!infoCardStatusText}/>
      {myRequestsCard && <MSRRefundInfoCard data={myRequestsCard} lobName={lobName} status={status} bookingId={bookingId} />}
    </SummaryContainer>
  );
}

export default TripGuaranteeSummaryCard;