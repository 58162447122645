import React, { useEffect, useRef, useState } from 'react';
import { Text } from 'react-native';

const Timer = ({ duration, timerTextStyle, callback }) => {
  const [state, setState] = useState({
    overlay: false,
    start: Date.now(),
    diff: 0,
    minutes: '00',
    seconds: '00',
    duration: duration, // in seconds,
  });
  const timerRef = useRef();

  useEffect(() => {
    timerRef.current = setInterval(() => {
      timer();
    }, 1000);
    return () => {
      clearInterval(timerRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const timer = () => {
    let { diff, minutes, seconds } = state;
    const { duration, start } = state;

    diff = duration - (((Date.now() - start) / 1000) | 0);
    minutes = (diff / 60) | 0;
    seconds = diff % 60 | 0;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;
    if (diff != 0) {
      setState((prev) => ({ ...prev, minutes: minutes, seconds: seconds }));
    }
    if (diff == 0 || diff < 0) {
      setState((prev) => ({ ...prev, minutes: '00', seconds: '00' }));
      clearInterval(timerRef.current);
      callback && callback();
    }
  };

  return (
    <Text style={timerTextStyle}>
      {state.minutes} m : {state.seconds} s{' '}
    </Text>
  );
};

export default Timer;
