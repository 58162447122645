import * as Omniture from '@mmt/legacy-commons/Common/utils/OmnitureTrackerUtils';
import { TrackerDataProps } from './type';
import { PAGE_NAME_PREFIX, CHANNEL_NAME, LOB_NAME, VARIABLE_FACTORS, PAGE_NAMES } from './constants';

const _prepareTrackingData = (data: TrackerDataProps): Record<string, string> => {
  let trackingData = data;
  if (typeof trackingData === 'string') {
    trackingData = {
      // @ts-ignore
      [Omniture.PROP_54]: data,
    };
  }
  // @ts-ignore
  return trackingData;
};

export const trackEvent = (
  page: string,
  data: TrackerDataProps,
  isLoadEvent: boolean = false,
): void => {
  try {
    const IS_LANDING_PAGE = page === PAGE_NAMES.CROSS_SELL_CABS ? true : false;
    const PAGE_NAME_PREFIX_VALUE = IS_LANDING_PAGE
      ? PAGE_NAME_PREFIX.LANDING
      : PAGE_NAME_PREFIX.DEFAULT;

    const PAGE_NAME_VALUE = `${PAGE_NAME_PREFIX_VALUE}:${page}`;
    const CHANNEL_NAME_VALUE = IS_LANDING_PAGE ? CHANNEL_NAME.LANDING : CHANNEL_NAME.DEFAULT;

    // Wrapping inside a setTimeout to prevent any omniture latency/erros to effect the main logic
    setTimeout(() => {
      Omniture.trackOmniture(
        PAGE_NAME_VALUE,
        {
          ..._prepareTrackingData(data),
          [Omniture.PROP_24]: `${LOB_NAME}${VARIABLE_FACTORS.CORPORATE_SUFFIX}`,
          [Omniture.EVAR_15]: PAGE_NAME_VALUE,
          [Omniture.VAR_CHANNEL]: `${CHANNEL_NAME_VALUE}${VARIABLE_FACTORS.CORPORATE_SUFFIX}`,
          [Omniture.VAR_LOB_KEY]: `${LOB_NAME}${VARIABLE_FACTORS.CORPORATE_SUFFIX}`,
        },
        isLoadEvent,
      );
    }, 0);
  } catch (error) {
    console.error(`Failed to track event of ${page} page`);
  }
};
