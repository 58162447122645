import { trackOmniture } from '@mmt/legacy-commons/Common/utils/OmnitureTrackerUtils';
import isEmpty from 'lodash/isEmpty';
import FlightBookingTrackingHelper from '../../../modules/details/helper/FlightBookingTrackingHelper';

type TrackingOmnitureData = Record<string, any>;
interface TrackingOmniture {
  onLoad: (data: SpecialClaimOmnitureData) => void;
  onClick: (clickEventIgnore: string, extraInfoIgnore: string) => void;
  onError: (errorDescIgnore: string) => void;
}
export interface SpecialClaimOmnitureData {
  pageName?: string;
  bookingType?: string;
  tripType?: string;
  itenary?: string;
  departureInfo?: string;
  uuid?: string;
  reason?: string;
  bookingDetailsResponse?: any;
  scDetection?: string;
}

const SpecialClaimTracking = (): TrackingOmniture => {
  let dataSet: TrackingOmnitureData = {
    pageName: 'mob:customer support:all',
    m_v15: 'mob:customer support:all',
  };

  const getSpecialClaimParams = (data: SpecialClaimOmnitureData) => {
    const { pageName, reason, bookingDetailsResponse, scDetection } = data;
    const { bookingType } = bookingDetailsResponse;
    const fullPageName = FlightBookingTrackingHelper.getPageName(pageName, bookingDetailsResponse);
    return {
      ...FlightBookingTrackingHelper.getTrackingOmniture(pageName, bookingDetailsResponse),
      m_v13: reason,
      m_v15: fullPageName,
      m_v60: bookingType,
      m_c1: scDetection,
    };
  };

  const onLoad = (data: SpecialClaimOmnitureData) => {
    try {
      const { uuid, pageName } = data;
      const params: TrackingOmnitureData = getSpecialClaimParams(data);
      params.pageName = isEmpty(pageName) ? 'mob:customer support:all' : pageName;
      params.m_v15 = isEmpty(pageName) ? 'mob:customer support:all' : pageName;
      if (!isEmpty(uuid)) {
        params.m_v54 = uuid;
      }
      dataSet = { ...dataSet, ...params };
      trackOmniture(dataSet?.pageName, dataSet);
    } catch (err) {
      console.log('Error: ', err);
    }
  };

  const onClick = (eventName: string, extraInfo: string) => {
    try {
      const clickEventDataSet = { ...dataSet };
      clickEventDataSet.m_c54 = eventName;
      if (!isEmpty(extraInfo)) {
        clickEventDataSet.m_v13 = extraInfo;
      }
      trackOmniture(clickEventDataSet?.pageName, clickEventDataSet);
    } catch (err) {
      console.log('Error: ', err);
    }
  };

  const onError = (error: string) => {
    try {
      const errorEventDataSet = { ...dataSet };
      if (!isEmpty(error)) {
        errorEventDataSet.m_c22 = error;
      }
      trackOmniture(errorEventDataSet?.pageName, errorEventDataSet);
    } catch (err) {
      console.log('Error: ', err);
    }
  };

  return {
    onLoad,
    onClick,
    onError,
  };
};

export default SpecialClaimTracking;
