import React, { useState } from 'react';
import { View } from 'react-native';
import BookingInfoDetails from '../BookingInfoDetails';
import DriverAssignedCard from '../DriverAssignedCardV2';
import { CabDriverAssignedProps } from '../../../../types';
import { handleClickEvent } from '../../../../utils/CabBookingDetailUtil';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import createStyles from './CabDriverAssignedCss';
import CardWrapper from '../CardWrapper';
import CabBookingDetailsConstant from 'apps/post-sales/src/cab/CabBookingDetailsConstant';
import * as Animatable from 'react-native-animatable';
import HourlyRentalCabDetail from '../HourlyRentalCabDetail';
import MulticityJourneyDetail from 'apps/post-sales/src/cab/components/MulticityJourneyDetail';

const CabDriverAssigned: React.FC<CabDriverAssignedProps> = (props) => {
  const {
    card,
    response,
    cardData,
    expandCarDetail,
    isExpanded,
    pageName,
    handleBottomSheetOverlay,
    isCurrentEventTripstarted,
    isTransitioning,
  } = props;
  const {
    driverDetailsInfo,
    cabDetailInfo,
    carBookingInfo,
    isInternationalBooking = false,
  } = cardData || {};
  const { driverInfo } = driverDetailsInfo || {};
  const { sectionList } = card || {};

  const { actionList } = card || {};
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);
  const handleButtonClick = (item) => {
    if (isInternationalBooking && item.actionId === CabBookingDetailsConstant.callDriverId) {
      handleBottomSheetOverlay(CabBookingDetailsConstant.CallDriverOverlay);
    } else {
      handleClickEvent(card, item, response, pageName);
    }
  };

  const renderSections = (id: string) => {
    const views: JSX.Element[] = [];
    switch (id.toLocaleLowerCase()) {
      case CabBookingDetailsConstant.SHOW_HR_CAR_DETAIL.toLocaleLowerCase():
        views.push(
          <HourlyRentalCabDetail
            pageName={pageName}
            carBookingInfo={carBookingInfo}
            isExpanded={isExpanded}
            expandCarDetail={expandCarDetail}
          />,
        );
        break;
      case CabBookingDetailsConstant.SHOW_JOUNEY_DETAIL.toLocaleLowerCase():
        views.push(
          <BookingInfoDetails
            pageName={pageName}
            carBookingInfo={carBookingInfo}
            isExpanded={isExpanded}
            expandCarDetail={expandCarDetail}
          />,
        );
        break;
      case CabBookingDetailsConstant.SHOW_MC_JOUNEY_DETAIL.toLocaleLowerCase():
        views.push(
          <MulticityJourneyDetail
            locationData={response?.multicityData}
            expandCarDetail={expandCarDetail}
            isExpanded={isExpanded}
            carBookingInfo={carBookingInfo}
          />,
        );
        break;
      case CabBookingDetailsConstant.SHOW_DRIVER_DETAIL.toLocaleLowerCase():
        views.push(
          <View style={[styles.driverAssignedWrapper]} key={id}>
            <DriverAssignedCard
              driverInfo={driverInfo}
              cabDetailInfo={cabDetailInfo}
              actionList={actionList}
              handleButtonClick={handleButtonClick}
              handleBottomSheetOverlay={handleBottomSheetOverlay}
              pageName={pageName}
            />
          </View>,
        );
        break;
      default:
        break;
    }
    return views;
  };

  return isCurrentEventTripstarted ? (
    <React.Fragment>
      {sectionList.map((section) => {
        if (!section.isVisible) {
          return null;
        }
        return renderSections(section.cardId);
      })}
    </React.Fragment>
  ) : (
    <Animatable.View animation={isTransitioning ? 'fadeIn' : ''} duration={1000} useNativeDriver>
      {sectionList.map((section) => {
        if (!section.isVisible) {
          return null;
        }
        return renderSections(section.cardId);
      })}
    </Animatable.View>
  );
};

export default CardWrapper(CabDriverAssigned);
