import { StyleSheet } from "react-native";
import { DEFAULT_THEME } from "apps/post-sales/src/theme/default.theme";

export default <T extends typeof DEFAULT_THEME>(theme: T, fonts: any) => StyleSheet.create({
    filterRowSection: {
      borderBottomWidth: 1,
      borderBottomColor: theme.color.grey37,
      padding: 16,
    },
    filterTab: {
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    tab: {
      paddingHorizontal: 8,
      paddingVertical: 12,
      borderWidth: 1,
      borderColor: theme.color.grey37,
      borderRadius: 8,
      marginBottom: 8,
      marginRight: 8,
    },
    tabText: {
      color: theme.color.defaultTextColor,
      fontSize: fonts.fontSize.font14,
      ...fonts.regularFontFamily,
    },
    linkText: {
      color: theme.color.azure,
      fontSize: fonts.fontSize.font14,
      ...fonts.regularFontFamily,
    },
    activeTabStyle: {
      borderColor: theme.color.azure,
      backgroundColor: theme.color.lighterBlue,
    },
    activeTabTextStyle: {
      color: theme.color.azure,
    },
    titleText: {
      fontSize: fonts.fontSize.font16,
      ...fonts.blackFontFamily,
      color: theme.color.black,
    },
  });