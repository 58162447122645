import React, { useRef } from 'react';
import { View, Image, Text, TouchableOpacity } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { CROSS_ICON } from '../../Utils/RailImageConstant';
import { NO_CANCELLATION_ICON } from '../../Utils/RailImageConstant';
import Button from 'apps/post-sales/src/Common/Button';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { getStaticData } from 'apps/post-sales/src/staticData/staticData';
import {
  cancellationNowImage,
  cancellationStyles,
  dotHollowGrayImage,
} from 'apps/post-sales/src/utils/CancellationPolicyCardUtils';
import {
  getCancellationPolicyView,
  onFullCancelTapped,
  trainCancelledClickHandler,
} from '../../details/RailBookingDetailsUtil';
import { getCardbyCardId, getCancellationPolicyActionList } from '../../details/data/helper';
import HTMLView from 'react-native-htmlview';
import { useTheme } from '../../../theme/theme.context';
import { getStyles, getHtmlStyle } from './CancellationPolicySheetCss';
import { CancellationPolicyBtmSheetProps } from '../../types';
import { CARD_ID } from '../../details/constants';
import isEmpty from 'lodash/isEmpty';
import { getActionButton } from '../../../Common/commonUtil';

const CancellationPolicyBtmSheet = ({
  handleBottomOverlay,
  response,
  disableCard,
  mmtAuth,
  loggingTrackInfo,
  tripStatus,
  tdrRules,
  tdrApiError,
  handleViewTdrClick,
}: CancellationPolicyBtmSheetProps) => {
  const cancellationNowImageUsed = useRef(false);
  const { showPolicy } = getCardbyCardId(CARD_ID.RAIL_CHANGE_IN_PLANS_CARD, response);
  const { psTheme } = useTheme();
  const { color } = psTheme;
  const { fsStyle, ...fonts } = getFont(true);
  const styles = getStyles(fonts, color);
  const htmlStyle = getHtmlStyle(fonts, color);
  const staticData = getStaticData();
  const {
    cancellationPolicyCapsText,
    cancellationTimeText,
    chargesText,
    ifMissTrainText,
    fileTdrText,
    cancellationPolicyText,
    cancelYourBookingText,
  } = staticData;
  const { clientDetails } = response || {};
  const {
    RailCancellationPolicyFooter,
    RailCancellationPolicyFooter2,
    cancellationPolicyMessage,
    actionList = [],
  } = clientDetails || {};
  const card = {
    actionList: actionList,
  };
  const getImgSourceForTdr = () => {
    if (cancellationNowImageUsed.current) {
      return <Image style={dotHollowGrayImage.style} source={dotHollowGrayImage.source} />;
    }
    return <Image style={cancellationNowImage.style} source={cancellationNowImage.source} />;
  };

  const handleClick = (action: any) => {
    {
      !disableCard &&
        trainCancelledClickHandler(
          response,
          action,
          tdrRules,
          mmtAuth,
          loggingTrackInfo,
          handleViewTdrClick,
        );
    }
  };

  return (
    <View style={styles.fcWrapper}>
      <View style={[styles.headingSection, AtomicCss.flexRow, AtomicCss.alignCenter]}>
        <View style={[AtomicCss.flex1, AtomicCss.flexRow, AtomicCss.alignCenter]}>
          <Image style={styles.canIconStyle} source={NO_CANCELLATION_ICON} />
          <Text style={styles.headingText}>{cancellationPolicyText}</Text>
        </View>
        <TouchableOpacity style={styles.crossIconWrapper} onPress={handleBottomOverlay}>
          <Image style={styles.crossIconStyle} source={CROSS_ICON} />
        </TouchableOpacity>
      </View>

      {showPolicy && (
        <View style={[styles.infoDtlsSectionTracker]}>
          <View style={AtomicCss.paddingTop10}>
            <View style={cancellationStyles.trackerHeadingRow}>
              {!isEmpty(cancellationTimeText) && (
                <Text
                  style={[cancellationStyles.trackerHeading, fonts.boldFontFamily, fsStyle.font14]}
                >
                  {cancellationTimeText}
                </Text>
              )}
              {!isEmpty(chargesText) && (
                <Text style={[fsStyle.font14, fonts.boldFontFamily, AtomicCss.blackText]}>
                  {chargesText}
                </Text>
              )}
            </View>
            {getCancellationPolicyView(response, cancellationNowImageUsed)}
          </View>
          <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween, AtomicCss.marginBottom12]}>
            <View style={[AtomicCss.makeRelative, AtomicCss.paddingLeft20]}>
              {getImgSourceForTdr()}
              {!isEmpty(ifMissTrainText) && (
                <Text
                  style={[
                    AtomicCss.paddingRight25,
                    fonts.boldFontFamily,
                    fsStyle.font12,
                    { color: color.lightTextColor },
                    cancellationStyles.marginTopNeg6,
                    AtomicCss.paddingTop7,
                  ]}
                >
                  {ifMissTrainText}
                </Text>
              )}
            </View>
            {!isEmpty(fileTdrText) && (
              <Text
                style={[
                  fsStyle.font12,
                  { color: color.lightTextColor },
                  fonts.regularFontFamily,
                  cancellationStyles.marginTopNeg6,
                  AtomicCss.paddingRight10,
                  AtomicCss.paddingTop7,
                ]}
              >
                {fileTdrText}
              </Text>
            )}
          </View>
          <View style={styles.detailsSection}>
            <View style={AtomicCss.marginBottom20}>
              {!isEmpty(RailCancellationPolicyFooter) && (
                <Text
                  style={[
                    { color: color.defaultTextColor },
                    AtomicCss.lineHeight18,
                    AtomicCss.regularFont,
                  ]}
                >
                  {RailCancellationPolicyFooter}
                </Text>
              )}
            </View>
            {!isEmpty(cancellationPolicyMessage) &&
              cancellationPolicyMessage.map((item) => {
                const { text, textColor, backgroundColor } = item;
                return (
                  <View style={[styles.canFeeInfo, { backgroundColor: backgroundColor }]}>
                    {!isEmpty(text) && (
                      <Text style={[styles.canFeeInfoText, { color: textColor }]}>{text}</Text>
                    )}
                  </View>
                );
              })}
          </View>
          <View style={[AtomicCss.paddingLeft15, AtomicCss.marginTop10, AtomicCss.paddingBottom8]}>
            {!isEmpty(RailCancellationPolicyFooter2) && (
              <View
                style={[
                  { color: color.defaultTextColor },
                  AtomicCss.lineHeight18,
                  { flexDirection: 'row', justifyContent: 'space-between' },
                ]}
              >
                <Text>&#9679; </Text>
                <Text>{RailCancellationPolicyFooter2}</Text>
              </View>
            )}
          </View>
        </View>
      )}
      <View>{getActionButton(card, response, handleClick, fonts.boldFontFamily, true)}</View>
      <View style={styles.btnWrapper}>
        <Button
          btnWidth={'100%'}
          btnBg="gradientBtn"
          btnType="flat"
          fontSize={fonts.fontSize.font16}
          btnTxt={cancelYourBookingText.toUpperCase()}
          clickHandler={() => {
            onFullCancelTapped(response, tdrRules, mmtAuth, loggingTrackInfo);
          }}
        />
      </View>
    </View>
  );
};

export default CancellationPolicyBtmSheet;
