import { StyleSheet } from 'react-native';
import { fonts } from '@mmt/legacy-commons/Styles/globalStyles';

export default (theme) =>
  StyleSheet.create({
    tabStyle: {
      paddingVertical: 10,
      marginHorizontal: 15,
      flex: 1,
    },
    tabStyleTxt: {
      color: '#4a4a4a',
      fontFamily: fonts.regular,
      fontSize: 14,
      textAlign: 'center',
    },
    tabActive: {
      borderBottomColor: theme.color.primary,
      borderBottomWidth: 3,
    },
    tabTxtActive: {
      color: '#000',
      fontFamily: fonts.black,
    },
    disabledTxt: {
      color: 'rgba(74,74,74,.3)',
    },
  });
