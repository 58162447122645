import React, {Component} from 'react';
import {get} from 'lodash';
import {View, Text, TextInput, TouchableOpacity, Image, Platform} from 'react-native';
import PropTypes from 'prop-types';
import styles from './DynamicFormCss';
import InputFieldMaster from './InputFieldMaster';
import {FORM_DATA_TYPE_NEW, DAY_SLASH_INDEX, MONTH_SLASH_INDEX, INT_MAX_VAL, DATE_LENGTH} from './DynamicFormConstants';
import {isValidPattern, getHypotheticalParentNode, getIndex, setErrorField} from './DynamicFormUtils';
import {CloseIcon} from '../../HolidayBookingConstants';


class FormTextInput extends Component {
  constructor(props) {
    super(props, 'DynamicForm');
    this.textInputRefernce = {};
    this.errorField = '';
    this.text = '';
    this.previousText = '';
    this.formattedText = '';
    this.maxLengthIfTextInput = INT_MAX_VAL;
    this.state = {
      updateInput: false,
      validate: this.props.validate
    };
  }

  componentWillReceiveProps(newProps) {
    if (this.props.validate === !newProps.validate) {
      this.setState({
        validate: newProps.validate
      });
    }
  }

  componentWillMount() {
    this.text = get(this.props, `${this.props.jsonKey}.value`, undefined) || this.props.visaForm.fields[this.props.fieldName].defaultValue;
    this.props.updateChildValues(`${this.props.jsonKey}.value`, this.text);
    this.props.updateChildValues(`${this.props.jsonKey}.parent`, getHypotheticalParentNode(this.props.parent, this.props.visaForm.fields[this.props.fieldName]));

    if (this.props.visaForm.fields[this.props.fieldName].type === FORM_DATA_TYPE_NEW.DATE) {
      this.text = this.text.split('-').join('/');
    }
  }

  formatDate = (text) => {
    this.formattedText = text;
    if (this.props.visaForm.fields[this.props.fieldName].type === FORM_DATA_TYPE_NEW.DATE
         && this.previousText.length < this.formattedText.length
         && (this.formattedText.length === DAY_SLASH_INDEX
          || this.formattedText.length === MONTH_SLASH_INDEX)) {
      this.formattedText += '/';
    }
  }

  renderDependents = () => {
    const dependents = [];
    if (this.props.visaForm.fields[this.props.fieldName].dependents &&
      this.props.visaForm.fields[this.props.fieldName].dependents[this.value]) {
      this.props.visaForm.fields[this.props.fieldName].dependents[this.value].forEach((element) => {
        dependents.push(<InputFieldMaster
          focusOnNextField={this.props.focusOnNextField}
          textInputRefs={this.props.textInputRefs}
          labelIndex={`${this.props.labelIndex}.${this.dependents.length + 1}`}
          key={element}
          parent=""
          jsonKey={`${this.props.jsonKey}.dependents`}
          fieldName={element}
          visaForm={this.props.visaForm}
          updateChildValues={this.props.updateChildValues}
          validate={this.state.validate}
          setField={this.props.setField}
          showDropDown={this.props.showDropDown}
        />);
      });
    }
    return dependents;
  }

  getParentJsonKey = (jsonKey) => {
    const keySteps = jsonKey.split('.');
    keySteps.pop();
    return keySteps.join('.');
  }

  shouldShowErrorField = () => {
    if (this.props.validate) {
      this.errorField = setErrorField(this.props.visaForm.fields[this.props.fieldName].mandatory, this.text, this.props.visaForm.fields[this.props.fieldName].error);
      if (this.props.visaForm.fields[this.props.fieldName].mandatory) {
        if (this.text === '') {
          return true;
        }
        return !isValidPattern(new RegExp(this.props.visaForm.fields[this.props.fieldName].validations[getHypotheticalParentNode(this.props.parent, this.props.visaForm.fields[this.props.fieldName])]), this.props.visaForm.fields[this.props.fieldName].type === FORM_DATA_TYPE_NEW.DATE ? this.text.split('/').join('-') : this.text);
      }
      if (this.text === '') {
        return false;
      }
      return !isValidPattern(new RegExp(this.props.visaForm.fields[this.props.fieldName].validations[getHypotheticalParentNode(this.props.parent, this.props.visaForm.fields[this.props.fieldName])]), this.props.visaForm.fields[this.props.fieldName].type === FORM_DATA_TYPE_NEW.DATE ? this.text.split('/').join('-') : this.text);
    }
    return false;
  }

  getDependents = () => {
    const dependents = this.renderDependents();
    if (dependents.length !== 0) {
      return dependents;
    }
  }

  shouldShowCancelIcon = () => {
    if (this.props.visaForm.fields[this.props.fieldName].mandatory) {
      return this.props.visaForm.fields[this.props.fieldName] &&
      this.props.visaForm.fields[this.props.fieldName].addMore &&
      getIndex(this.props.jsonKey) !== '0';
    }
    return this.props.visaForm.fields[this.props.fieldName] &&
      this.props.visaForm.fields[this.props.fieldName].addMore;
  }

  getKeyBoardType = () => {
    if (this.props.visaForm.fields[this.props.fieldName].type === FORM_DATA_TYPE_NEW.DATE) {
      if (Platform.OS === 'ios') {
        return 'numbers-and-punctuation';
      }
      return 'phone-pad';
    }
    return 'default';
  }

  render() {
    if (this.text !== null && this.text !== undefined) {
      this.formatDate(this.text);
      this.previousText = this.formattedText;
      this.text = this.formattedText;
    }
    if (this.props.visaForm.fields[this.props.fieldName].maxLength) {
      this.maxLengthIfTextInput = this.props.visaForm.fields[this.props.fieldName].maxLength;
    }
    return (
      <View>
        {this.shouldShowCancelIcon() &&
        <View style={[styles.flex1, styles.flexRow, styles.mar12Bt, styles.visaFormCloseIcon]}>
          <TouchableOpacity onPress={() => this.props.deleteWRTIndex(this.props.indexInParent)}>
            <Image source={CloseIcon} style={styles.visaFormCloseIconImage} />
          </TouchableOpacity>
        </View>}
        <TextInput
          ref={(ref) => {
            if (!this.props.visaForm.fields[this.props.fieldName].disabled) {
              this.props.textInputRefs[`${this.props.labelIndex}`] = ref;
              this.textInputRefernce = ref;
            }
          }}
          editable={!this.props.visaForm.fields[this.props.fieldName].disabled}
          style={[this.props.visaForm.fields[this.props.fieldName].disabled ?
            styles.textInputGray : styles.textInput, styles.mar10Bt]}
          value={this.text}
          placeholder={this.props.visaForm.fields[this.props.fieldName].placeholder}
          onChangeText={text => this.setText(text)}
          blurOnSubmit={false}
          underlineColorAndroid="transparent"
          multiline={this.props.textArea}
          numberOfLines={this.props.textArea ? 4 : 1}
          returnKeyType="next"
          onSubmitEditing={() => {
            this.handleSubmit();
        }}
          keyboardType={this.getKeyBoardType()}
          maxLength={(this.props.visaForm.fields[this.props.fieldName].type === FORM_DATA_TYPE_NEW.DATE) ? (DATE_LENGTH) : this.maxLengthIfTextInput}
        />
        {this.shouldShowErrorField() &&
        <Text style={[styles.ErrorText, styles.font14, styles.regularFont]}>{this.errorField}</Text>}
        {this.getDependents()}
      </View >
    );
  }

  handleSubmit = () => {
    if (!this.props.textArea) {
      this.props.focusOnNextField({
        labelIndex: this.props.labelIndex,
        reference: this.textInputRefernce
      });
    }
  }

setText = (val) => {
  this.text = val;
  if (this.props.visaForm.fields[this.props.fieldName].type === FORM_DATA_TYPE_NEW.DATE) {
    this.props.updateChildValues(`${this.props.jsonKey}.value`, this.text.split('/').join('-'));
  } else {
    this.props.updateChildValues(`${this.props.jsonKey}.value`, this.text);
  }
  this.props.updateChildValues(`${this.props.jsonKey}.parent`, getHypotheticalParentNode(this.props.parent, this.props.visaForm.fields[this.props.fieldName]));
  this.props.updateChildValues(`${this.props.jsonKey}.dependents`, {});
  this.setState({
    updateInput: true
  });
}
}

FormTextInput.propTypes = {
  labelIndex: PropTypes.string.isRequired,
  parent: PropTypes.string.isRequired,
  jsonKey: PropTypes.string.isRequired,
  visaForm: PropTypes.object.isRequired,
  fieldName: PropTypes.string.isRequired,
  validate: PropTypes.bool.isRequired,
  updateChildValues: PropTypes.func.isRequired,
  textArea: PropTypes.bool.isRequired,
  setField: PropTypes.func.isRequired,
  showDropDown: PropTypes.func.isRequired,
  deleteWRTIndex: PropTypes.func.isRequired,
  indexInParent: PropTypes.number.isRequired,
  textInputRefs: PropTypes.object.isRequired,
  focusOnNextField: PropTypes.func.isRequired
};


export default FormTextInput;
