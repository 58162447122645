import React from 'react';
import {Image, Text, View} from 'react-native';
import getStyles from './HotelMamiCss';
import PropTypes from 'prop-types';
import {
  getActionButton,
  getButtonStyle,
  getNonAddedPassengerCountStr,
  getNumberOfGuest,
  getPassengerNameList, getPrimaryCustomerEmailId,
  getTotalNoOfGuestPresent,
  handleClickEvent,
  isNotNullAndEmpty
} from '../utils/HotelBookingDetailUtil';
import { getStaticData } from '../../../staticData/staticData';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { GUEST_ICON, ENVELOPE_ICON, CALL_ICON } from '../../HotelImageConstants';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const messageIcon = ENVELOPE_ICON;
const guestIcon = GUEST_ICON;
const contactIcon = CALL_ICON;

class GuestCard extends React.Component {
  constructor(props) {
    super(props);
    this.fonts = getFont(true);
    this.styles = getStyles(this.fonts);
    this.fsStyle = this.fonts.fsStyle;
    this.hotelBookingInfo = this.props.response.hotelBookingInfo;
  }

  render() {
    const staticData = getStaticData(true);
    const {hotelBookingInfo} = this.props.response;
    const totalGuestCount = hotelBookingInfo.totalNumberOfAdults + hotelBookingInfo.totalNumberOfChilds
    return (
      <View style={[this.styles.MamiCard, this.styles.mar10Bt]}>
        <View style={this.styles.CardContent}>
          <Image
            style={this.styles.CardIcon}
            source={guestIcon}
          />
          <View style={this.styles.ContentPortion}>
            <Text style={[this.styles.blackFont, this.styles.BlackText, this.styles.mar10Bt, AtomicCss.alignLeft]}>
              {totalGuestCount} {totalGuestCount > 1 ? staticData.guestCardText.additionalText.guestsCapsText : staticData.guestCardText.additionalText.guestCapsText}
            </Text>
            <Text style={[AtomicCss.marginBottom5, AtomicCss.alignLeft]}>
              <Text style={[this.styles.BlackText, AtomicCss.alignLeft, this.styles.mar5Lt]}>{this.getPrimaryCustomerName()}</Text>
              <Text style={AtomicCss.alignLeft}> ({staticData.guestCardText.additionalText.primaryText})
                <Text style={AtomicCss.alignLeft}>{getTotalNoOfGuestPresent(this.props.response) === 0 ? getNonAddedPassengerCountStr(this.props.response) : ''}</Text>
                
              </Text>
            </Text>
            <View style={[AtomicCss.flexRow, AtomicCss.marginBottom5]}>
              <Image style={this.styles.messageIcon} source={messageIcon}/>
              <Text
                style={[AtomicCss.flex1, this.fsStyle.font12, AtomicCss.defaultText, this.fonts.regularFontFamily, AtomicCss.alignLeft]}>
                {getPrimaryCustomerEmailId(this.props.response)}
              </Text>
            </View>
            <View style={[AtomicCss.flexRow, AtomicCss.marginBottom12]}>
              <Image style={this.styles.callIcon} source={contactIcon}/>
              <Text
                style={[AtomicCss.flex1, this.fsStyle.font12, AtomicCss.defaultText, this.fonts.regularFontFamily, AtomicCss.alignLeft]}>{this.getPrimaryCustomerPhoneNumber()}</Text>
            </View>
            {
              getTotalNoOfGuestPresent(this.props.response) > 0 &&
              <View style={[AtomicCss.flexRow, this.styles.otherMemberDetails]}>
                <Image style={this.styles.messageIcon} source={guestIcon}/>
                <Text
                  style={[AtomicCss.flex1, this.fsStyle.font12, AtomicCss.defaultText, this.fonts.regularFontFamily, AtomicCss.alignLeft]}>
                  {getPassengerNameList(this.props.response)}
                  {getNonAddedPassengerCountStr(this.props.response)}
                </Text>
              </View>
            }
          </View>
        </View>
        <View
          style={isNotNullAndEmpty(this.props.card.actionList) ? getButtonStyle(this.props.card.actionList) : this.styles.hideView}>
          {getActionButton(this.props.card, this.props.response, this.handleClick)}
        </View>
      </View>
    );
  }

  handleClick = (action) => {
    handleClickEvent(this.props.card, action, this.props.response, this.props.pageName);
  };

  getPrimaryCustomerName() {
    const primaryCustomer = this.hotelBookingInfo.primaryCustomerDetails;
    return `${primaryCustomer.firstName} ${primaryCustomer.middleName} ${primaryCustomer.lastName}`;
  }

  getPrimaryCustomerPhoneNumber() {
    const primaryCustomer = this.hotelBookingInfo.primaryCustomerDetails;
    return primaryCustomer.phoneNumber;
  }
}

GuestCard.propTypes = {
  response: PropTypes.object.isRequired,
  card: PropTypes.object.isRequired,
  pageName: PropTypes.string.isRequired
};

export default GuestCard;
