import { StyleSheet } from "react-native";
export default (theme, fonts) => StyleSheet.create({
    editDateWrapper: {
        backgroundColor: theme.color.lightGrey6,
        borderRadius: 8,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 16
    },
    linkText: {
        color: theme.color.cyanBlue
    },
    calendarIcon: {
        width: 17,
        height: 16,
        marginRight: 14
    },
    editIcon: {
        width: 16,
        height: 20,
    }
})