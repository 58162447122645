import React from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import HTMLView from 'react-native-htmlview';
import getStyle from './RefundDetailsOverlayCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getFont } from '../../../../../PostSalesStyles';
import { REFUND_BIG_ICON, REJECT_ICON } from '../../../../FlightImageConstants';
import { isEmptyArray } from '../../../../../Common/commonUtil';
import { externalLinkPressHandler } from '../../../../../utils/PostSaleUtil';
import {getRefundDetailsData} from "../../data/dataAdapter"
import { useTheme } from '../../../../../theme/theme.context';

const RefundDetailsOverlay = ({ refundDetails, okayGotItText, handleBottomOverlay }) => {
  const { fsStyle, ...fonts } = getFont(true);
  const {psTheme} = useTheme();
  const styles = getStyle(psTheme, fonts);
  const {color} = psTheme;
  const { title, subtitle, entries, footerText } = getRefundDetailsData(refundDetails);
  return (
    <View style={styles.OverlayContentWrapper}>
      <View style={AtomicCss.marginBottom24}>
        <View style={styles.overlayTopline}></View>
      </View>
      <React.Fragment>
        <Image source={REFUND_BIG_ICON} style={styles.refundBigIconStyle} />
        {!!title && (
          <Text
            style={[styles.title, fonts.blackFontFamily, AtomicCss.marginBottom8, fsStyle.font22]}
          >
            {title}
          </Text>
        )}
        {!isEmptyArray(subtitle) &&
          subtitle.map((subs) => (
            <HTMLView
              stylesheet={{ ...styles.htmlStyle, p: { ...styles.htmlStyle.p, ...fsStyle.font14 } }}
              value={'<p>' + subs + '</p>'}
              onLinkPress={externalLinkPressHandler}
            />
          ))}
        <View style={styles.strokeStyle}></View>
        {!isEmptyArray(entries) &&
          entries.map((entry, index) => {
            return (
              <View
                key={entry.id}
                style={[
                  styles.detailSec,
                  index === entries.length - 1 ? styles.detailSecLast : styles.detailSec,
                ]}
              >
                <View
                  style={[
                    AtomicCss.flexRow,
                    AtomicCss.alignCenter,
                    AtomicCss.spaceBetween,
                    AtomicCss.marginBottom8,
                  ]}
                >
                  <Text
                    style={[
                      fonts.blackFontFamily,
                      fsStyle.font14,
                      {color : color.black},
                      styles.lineHeight22,
                    ]}
                  >
                    {entry.title}
                  </Text>
                  <Text
                    style={[
                      fonts.blackFontFamily,
                      fsStyle.font14,
                      {color : color.black},
                      styles.lineHeight22,
                    ]}
                  >
                    {entry.topHeader}
                  </Text>
                </View>
                {!isEmptyArray(entry.subtitle) &&
                  entry.subtitle.map((subs) => (
                    <Text
                      key={subs}
                      style={[
                        fonts.regularFontFamily,
                        fsStyle.font14,
                        { color : color.defaultTextColor},
                        styles.lineHeight22,
                      ]}
                    >
                      {subs}
                    </Text>
                  ))}
              </View>
            );
          })}
        {!!footerText && (
          <View style={styles.rejectDetails}>
            <Image source={REJECT_ICON} style={styles.rejectIcon} />
            <Text
              style={[
                fsStyle.font13,
                fonts.regularFontFamily,
                {color : color.defaultTextColor},
                AtomicCss.lineHeight20,
                AtomicCss.flex1,
              ]}
            >
              {footerText}
            </Text>
          </View>
        )}
        <TouchableOpacity style={[AtomicCss.flexRow]} onPress={handleBottomOverlay.bind(null, '')}>
          <Text
            style={[
              fsStyle.font16,
              fonts.blackFontFamily,
              {color : color.azure},
              AtomicCss.alignRight,
              AtomicCss.lineHeight22,
              AtomicCss.flex1,
            ]}
          >
            {okayGotItText}
          </Text>
        </TouchableOpacity>
      </React.Fragment>
    </View>
  );
};

export default RefundDetailsOverlay;
