import React from 'react';
import FetchFlightData from 'apps/post-sales/src/flights/v2/utils/fetchFlightData';
import { fligtNewTrackingOmniture } from 'apps/post-sales/src/flights/v2/utils/trackingUtil';
import RenderFlightDetailPage from './Components/RenderFlightDetailPage';
import usePageRefresh from 'apps/post-sales/src/Common/Hooks/usePageRefresh';
interface FlightBookingDetailsV2Props {
  BOOKING_ID: string;
  bookingId: string;
  refresh: {
    isRefresh: boolean;
  };
  loadOldFlow: () => void;
}

const FlightBookingDetailsV2: React.FC<FlightBookingDetailsV2Props> = (props) => {
  const { refresh, loadOldFlow } = props;
  const bookingId = props.BOOKING_ID || props.bookingId;
  const { onLoad, onClick, onError } = fligtNewTrackingOmniture();
  const uniqueKey = usePageRefresh({
    refresh,
    refreshDependency: [bookingId],
    onRefresh: () => {
      FetchFlightData.deleteData(bookingId);
    },
  });
  const renderFlightPageProps = {
    bookingId,
    onLoad,
    onClick,
    onError,
    loadOldFlow,
    pageRequested: 'FLIGHT_DETAILS',
    extraPropsData: {
      bookingId,
    },
  };
  return (
    <RenderFlightDetailPage {...renderFlightPageProps} key={uniqueKey}/>
  );
};

export default FlightBookingDetailsV2;
