import { StyleSheet } from "react-native";
import {Fonts, Theme} from "../../../../types"

export const getStyle = (fonts : Fonts, psTheme : Theme) => {
    const { color } = psTheme;
    return StyleSheet.create({
      triptype: {
        color: color.black74,
        fontSize: fonts.fontSize.font14,
        ...fonts.boldFontFamily,
      },
      hrHours: {
        color: color.black,
        fontSize: fonts.fontSize.font16,
        ...fonts.boldFontFamily,
      },
      hrDistance: {
        color: color.black,
        fontSize: fonts.fontSize.font16,
        ...fonts.regularFontFamily,
      },
      extraCharge: {
        color: color.defaultTextColor,
        fontSize: fonts.fontSize.font12,
        ...fonts.regularFontFamily,
      },
      pickUpText: {
        color: color.black74,
        fontSize: fonts.fontSize.font14,
        ...fonts.boldFontFamily,
      },
      fromCityDesc: {
        color: color.black,
        fontSize: fonts.fontSize.font16,
        ...fonts.boldFontFamily,
      },
      fromCityDesc2: {
        color: color.black74,
        fontSize: fonts.fontSize.font14,
        ...fonts.regularFontFamily,
      },
      dateTime: {
        color: color.defaultTextColor,
        fontSize: fonts.fontSize.font12,
        ...fonts.boldFontFamily,
      },
      hourlyRentalWrapper: {
        paddingVertical: 13,
        paddingHorizontal: 14,
        paddingBottom: 16,
      },
      rentalTopWrapper: {
        borderBottomWidth: 1,
        borderBottomColor: color.lightGray,
        marginBottom: 12,
        paddingBottom: 12,
      },
      arrowStyleWrapper: {
        position: 'absolute',
        top: 0,
        right: 0,
      },
      arrowstyle: {
        width: 24,
        height: 24,
      },
      activeArrow: {
        transform: [
          {
            rotate: '180deg',
          },
        ],
      },
    });
  };
