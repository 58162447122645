import React from 'react';
import { View, Text, Image, Platform, StyleSheet, TouchableOpacity } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { getStaticData } from '../../../staticData/staticData';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import { ANDROID_BACK_ARROW, IOS_BACK_ARROW } from '../../../flights/FlightImageConstants';
import { backPressHandler } from '../../../utils/PostSaleUtil';
import {getFont} from "../../../PostSalesStyles";

const TopStatusCard = ({ data }) => {
  const fonts = getFont(true);
  const { topStatusCard, bookingId, bookingDate, isPending = false } = data || {};
  const { topStatusText, bookingDesc } = topStatusCard || {};
  const { bookingIDText } = getStaticData();
  const bkgTextStyle = [styles.booingDtlsTxt, fonts.regularFontFamily, isPending ? styles.pendingTxtColor : styles.failedTxtColor];

  return (
    <LinearGradient
      start={{ x: 1.0, y: 0.0 }}
      end={{ x: 0.0, y: 1.0 }}
      colors={isPending ? [colors.creamWhite, colors.creamWhite] : ['#FFD3D4', '#FFD3D4']}
      style={[styles.topStatusCard]}
    >
      <View style={styles.backArrowWrapper}>
        <TouchableOpacity
          style={styles.imgContainer}
          activeOpacity={0.8}
          onPress={() => backPressHandler()}
        >
          <Image
            style={
              Platform.OS === 'ios'
                ? [AtomicCss.marginBottom10, styles.IOSbackarrow]
                : [AtomicCss.marginBottom10, styles.backarrow]
            }
            source={Platform.OS === 'ios' ? IOS_BACK_ARROW : ANDROID_BACK_ARROW}
          />
        </TouchableOpacity>
        <Text style={[fonts.regularFontFamily, AtomicCss.font13, isPending ? styles.pendingTxtColor : styles.failedTxtColor]}>
          {bookingDate}
        </Text>
      </View>
      <View style={styles.StatusCardWrapper}>
        {!!topStatusText && (
          <Text style={[styles.StatusHeadingTxt, fonts.blackFontFamily, isPending ? styles.pendingTxtColor : styles.failedTxtColor]}>{topStatusText}</Text>
        )}
        {!!bookingDesc && (
          <Text
            style={[
              AtomicCss.font14,
              AtomicCss.defaultText,
              AtomicCss.marginBottom15,
              AtomicCss.textCenter,
              isPending ? styles.pendingTxtColor : styles.failedTxtColor,
            ]}
          >
            {bookingDesc}
          </Text>
        )}
        <View style={[styles.BookingWrapper, AtomicCss.marginBottom15]}>
          <Text style={bkgTextStyle}>{bookingIDText}</Text>
          <Text style={bkgTextStyle}>:</Text>
          <Text style={bkgTextStyle}>{bookingId}</Text>
        </View>
      </View>
    </LinearGradient>
  );
};
const styles = StyleSheet.create({
  topStatusCard: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingHorizontal: 20,
    position: 'relative',
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
  },
  backArrowWrapper: {
    height: 50,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  backarrow: {
    width: 16,
    height: 16,
  },
  IOSbackarrow: {
    width: 10,
    height: 16,
    marginLeft: 10,
    position: 'absolute',
  },
  StatusCardWrapper: {},
  StatusHeadingTxt: {
    color: colors.defaultTextColor,
    textAlign: 'center',
    fontSize: 13,
    marginBottom: 10,
    textTransform: 'uppercase',
    backgroundColor: 'transparent',
  },
  BookingWrapper: {
    flexDirection: 'row',
    alignSelf: 'center',
  },
  booingDtlsTxt: {
    color: colors.black,
    fontSize: 12,
    marginRight: 10,
  },
  failedTxtColor: {
    color: colors.deepRed,
  },
  pendingTxtColor: {
    color: colors.darkBrown
  },
  imgContainer: { top: -5, height: 20, padding: 15, justifyContent: 'center' },
});
export default TopStatusCard;
