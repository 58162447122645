import React from 'react';
import isEmpty from 'lodash/isEmpty';
import getGiftCardSummaryData from './giftcardCardAdapter';
import {
  ActionsContent,
  GiftCardInfoText,
  DescriptionSection,
  MainContent,
  NewStatusText,
  RightArrow,
  StaticIcon,
  SummaryContainer,
  TitleText,
  NeedHelpStrip
} from '../cardElements';

function GiftCardSummaryCard({ response, msrTicket }) {
  const cardData = getGiftCardSummaryData(response, msrTicket);
  const {needHelpData} =  cardData;
  return (
    <SummaryContainer bookingId={response.bookingID}>
      <MainContent onPress={cardData.openDetails}>
        <StaticIcon icon={cardData.iconUrl} iconStyle={{ width: 32, height: 32 }} />
        <DescriptionSection>
          <TitleText text={cardData.giftCardName} />
          {!cardData.failedBooking && <GiftCardInfoText text={cardData.tripType} />}
          <NewStatusText status={cardData.status} text={cardData.dateTime} />
        </DescriptionSection>
        <RightArrow />
      </MainContent>
      {!isEmpty(needHelpData) &&  needHelpData.map((needHelp)=><NeedHelpStrip {...needHelp}/>)}
      <ActionsContent actions={cardData.actions} />
    </SummaryContainer>
  );
}
export default GiftCardSummaryCard;
