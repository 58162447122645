import { StyleSheet } from 'react-native';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';

const styles = StyleSheet.create({
  cardWrapper: {
    backgroundColor: colors.grey22,
    justifyContent: 'space-between',
    paddingVertical: 18,
    paddingBottom: 8,
    paddingLeft: 16,
  },
  tagInfo: {
    borderRadius: 20,
    paddingHorizontal: 4,
    paddingVertical: 2,
    alignSelf: 'flex-start',
    left: 15,
    top: 10,
    zIndex: 1,
  },
  tagStyle: {
    color: colors.white,
  },
  hotelIconStyle: {
    width: 24,
    height: 24,
    marginRight: 8,
  },
  topSectionInfo: {
    flexDirection: 'row',
    paddingRight: 16,
  },
  recommondedHotelWrapper: {
    flexDirection: 'row',
    marginTop: 12,
    backgroundColor: colors.white,
  },
  recommondedHotel: {
    borderWidth: 1,
    borderColor: colors.lightGrey,
    borderRadius: 4,
    marginRight: 10,
    width: 230,
  },
  hotelImgStyle: {
    width: '100%',
    height: 90,
    borderRadius: 4,
  },
  midDetailWrapper: {
    paddingHorizontal: 10,
    paddingVertical: 6,
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'space-between',
  },
  detailsSection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  moreInfoSection: {
    paddingRight: 10,
    backgroundColor: colors.limeGreen,
    borderRadius: 4,
    flexDirection: 'row',
  },
  effectsStyle: {
    width: 3,
    backgroundColor: colors.lightGreen13,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
  },
  info: {
    paddingHorizontal: 10,
    paddingVertical: 4,
  },
  ratingHotel: {
    borderRadius: 4,
    backgroundColor: colors.lightGreen4,
    paddingVertical: 2,
    paddingHorizontal: 6,
    marginRight: 5,
  },
  starStyle: {
    width: 8,
    height: 8,
    paddingRight: 1,
    marginLeft: 1,
  },
  activeStyle: {
    tintColor: colors.black,
  },
  disabledStyle: {
    tintColor: colors.gray7,
  },
  locationStyle: {
    width: 6,
    height: 8,
    marginRight: 5,
  },
  priceSection: {
    paddingTop: 10,
    width: '40%',
  },
  slacedPrice: {
    color: colors.lightTextColor,
    textDecorationLine: 'line-through',
  },
  actionWrapper: {
    marginLeft: 24,
    justifyContent: 'center',
  },
  blueArrowStyle: {
    width: 18,
    height: 14,
    marginLeft: 8,
    resizeMode: 'cover',
  },
  actionLink: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 15,
    paddingHorizontal: 6,
  },
});
export default styles;
