import React, { useState, useCallback } from 'react';
import { Text, View, Image, TouchableOpacity } from 'react-native';
import createStyles from './FlightInfoDtlsCss';
import {isEmpty} from 'lodash'
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getAirlineImage } from '../../../../../../../../../flights-funnel/Listing/utils/FlightListingUtil';
import {
  CHEVRON_UP_BLUE_ICON2,
  CHEVRON_DOWN_BLUE_ICON2,
} from '../../../../../../../../FlightImageConstants';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';

const FltAmenitiesDtls = (props) => {
  const { fsStyle, ...fonts } = getFont(true);
  const {psTheme: theme} = useTheme()
  const styles = createStyles(theme,fonts)
  const [CollapsedDtls, setCollapsedDtls] = useState(true);
  const collpasedCon = useCallback(() => {
    setCollapsedDtls((prevState) => ({ CollapsedDtls: !prevState.CollapsedDtls }));
  }, []);
  return (
    <View>
      <TouchableOpacity activeOpacity={0.85} onPress={() => collpasedCon()}>
        <View style={styles.FltAmentiesTopSection}>
          <View style={AtomicCss.flexRow}>
            <Image
              style={styles.Flt_Icon}
              source={{
                uri: getAirlineImage(props.legDetails.airlineCd),
              }}
            />
            <View>
              <Text style={styles.fltName}>{props.legDetails.airlineName}</Text>
              <Text style={styles.fltCode}>{props.legDetails.airlineSubText}</Text>
            </View>
          </View>
          {!isEmpty(props.legDetails.amenities) && (
            <Image
              style={styles.CollapsedArrow}
              source={CollapsedDtls ? CHEVRON_UP_BLUE_ICON2 : CHEVRON_DOWN_BLUE_ICON2}
            />
          )}
        </View>
      </TouchableOpacity>
      {CollapsedDtls && props.legDetails.amenities && (
        <View style={[styles.FltAmentiesCollapsedSection]}>
          {props.legDetails.amenities && !isEmpty(props.legDetails.amenities[0]) && (
            <View style={[AtomicCss.flexRow, AtomicCss.marginRight20]}>
              <Image
                style={styles.MealIcon}
                source={{ uri: props.legDetails.amenities[0].image }}
              />
              <Text style={[fsStyle.font12, styles.LightText]}>
                {props.legDetails.amenities[0].type}
              </Text>
            </View>
          )}
          {props.legDetails.amenities && !isEmpty(props.legDetails.amenities[1]) && (
            <View style={AtomicCss.flexRow}>
              <Image
                style={styles.WifiIcon}
                source={{ uri: props.legDetails.amenities[1].image }}
              />
              <Text style={[fsStyle.font12, styles.LightText]}>
                {props.legDetails.amenities[1].type}
              </Text>
            </View>
          )}
        </View>
      )}
    </View>
  );
};

export default FltAmenitiesDtls;
