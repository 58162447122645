import {NativeModules, Platform} from 'react-native';
import { getRootTag } from '../AppState/RootTagHolder';

if (NativeModules.BusBookingModule) {
    const openWriteToUs = NativeModules.BusBookingModule.startWriteToUsActivity;
    NativeModules.BusBookingModule.startWriteToUsActivity = () => {
        if (Platform.OS === 'ios') {
            const reactTag = getRootTag();
            const genericWriteToUs = NativeModules.GenericModule.startWriteToUsActivity;
            genericWriteToUs(reactTag);
        } else {
            openWriteToUs();
        }
    };
};

export default NativeModules.BusBookingModule;
