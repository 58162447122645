import React from 'react';
import { View, Text, StyleSheet, Image, TouchableOpacity } from 'react-native';
import { getImageTransformStyling } from '../../../utils/PostSaleUtil';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { BLUE_RIGHT_ARROW_ICON } from '../../HotelImageConstants';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import FlexiCancelPolicy from '../../details/components/cancellationPolicy/FlexiCancelPolicy';
import isEmpty from 'lodash/isEmpty';

const PolicyDetailCard = ({ title, desc, handleClick, isRefundable, flexiCancellationData}) => {
    const { fsStyle, ...fonts } = getFont(true);
    const suffixStyle = isRefundable ? "Refundable" : "NonRefundable";

    if(!isEmpty(flexiCancellationData)) {
        return (
            <View style={styles.flexiWrapper}>
              <FlexiCancelPolicy flexiDetails={flexiCancellationData} />
            </View>
        )
    }
    return (
        <TouchableOpacity activeOpacity={0.7} style={[styles.policyCardWrapper, styles[`policyCardWrapper${suffixStyle}`]]} onPress={handleClick}>
            <Text style={[fsStyle.font14, fonts.blackFontFamily, styles.headText, styles[`headText${suffixStyle}`]]}>{title}</Text>
            <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween]}>
                <Text style={[AtomicCss.flex1, fsStyle.font14, fonts.regularFontFamily, AtomicCss.defaultText, AtomicCss.marginRight10, AtomicCss.lineHeight22]}>{desc}</Text>
                <Image source={BLUE_RIGHT_ARROW_ICON} style={[styles.rightBlueArrow, getImageTransformStyling()]} />
            </View>
        </TouchableOpacity>
    );
};
    
const styles = StyleSheet.create({
    flexiWrapper: {
        marginHorizontal:16,
        marginBottom:16,
    },
    policyCardWrapper: {
        marginHorizontal: 16,
        marginBottom: 16,
        borderRadius: 8,
        padding: 12
    },
    policyCardWrapperRefundable: {
        backgroundColor: colors.limeGreen,
    },
    policyCardWrapperNonRefundable: {
        backgroundColor: colors.lightPink,
    },
    headText: {
        lineHeight: 22,
        marginBottom: 4
    },
    headTextRefundable: {
        color: colors.green,
    },
    headTextNonRefundable: {
        color: colors.deepRed,
    },
    rightBlueArrow: {
        width: 16,
        height: 16,
        marginRight: 10,
        marginTop: 5
    }
});

export default PolicyDetailCard;