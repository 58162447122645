import {colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles';


const styles = {
  CardContentFull: {
    backgroundColor: colors.white,
    borderBottomWidth: 1,
    borderBottomColor: '#e4e4e4',
    padding: 16,
    elevation: 1
  },
  CardInfoRow: {
    flexDirection: 'row',
    paddingTop: 11,
    paddingBottom: 11,
    borderBottomWidth: 1,
    borderBottomColor: '#ddd'
  },
  bulletPoints: {
    width: 5,
    height: 5,
    borderRadius: 50,
    backgroundColor: '#bababa',
    marginLeft: 5,
    marginRight: 5,
    fontFamily: fonts.regular,
    marginTop: 3
  },
  rowLeft: {fontSize: 12, color: '#747474', width: 80},
  rowRight: {fontSize: 12, color: '#000'},
  borderBottom0: {borderBottomColor: 'transparent'},
  width110: {width: 110},
  width25: { width: 25 },
  coverImg: {
    width: 80,
    height: 80,
    resizeMode: 'contain',
  }
};

export default styles;
