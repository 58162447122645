import FlightEventsLogger from '../helper/FlightEventsLogger';

import FlightBookingTrackingHelper from '../helper/FlightBookingTrackingHelper';
import { CTA_SEARCH_TYPE, universalSearchTrackingType } from '../components/SearchBar/autoCompleteSearchTypes';
import isEmpty from 'lodash/isEmpty';

export const getSearchResultActIds = (data: CTA_SEARCH_TYPE[]): string => {
    if (isEmpty(data)){
        return '';
    }
  const allActIds = [...data.map((entry) => entry.actId)];
  return `|${allActIds.join('|')}`;
};

export const universalSearchBarTrackCickEvent = ({
  clickEvent,
  searchKey,
  searchResult,
}: universalSearchTrackingType) => {
  try {
    const pageName: string = 'Search Page';
    FlightEventsLogger.clickEvent(pageName, clickEvent, {});
    FlightBookingTrackingHelper.trackClickEvent(pageName, clickEvent, null, null, null, null, {
      m_c1: `${searchKey}${getSearchResultActIds(searchResult)}`,
    });
  } catch (e) {
    console.log(e);
  }
};
