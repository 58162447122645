const styles = {
  headerSec: {
    paddingHorizontal: 12,
    paddingTop: 16,
  },
  headingIcon: {
    width: 24,
    height: 24,
    marginRight: 10,
    backgroundColor: '#f1f1f1',
  },
  addressWrapper: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  mapStyle: {
    width: 60,
    height: 60,
  },
  copyWrapper: {
    marginTop: 8,
    marginBottom: 8
  },
  copyIcon: {
    width: 18,
    height: 22,
    marginRight: 5
  },
  marginBottom80: {
    marginBottom: 80,
  },
};
export default styles;
