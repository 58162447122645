import React from 'react';
import { View, Text, Image } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import CustomerDetails from './CustomerDetails';
import isEmpty from 'lodash/isEmpty';
import { CONTACT_ICON } from '../../../../Utils/tripGuaranteeImageConstants';
import { getFont } from '../../../../../PostSalesStyles';
import createStyles from "./contactDetailsCss";
import { useTheme } from '../../../../../theme/theme.context';
const ContactDetailsCard = ({ card, response }) => {
    const { heading } = card || {};
    const { contactDetailsInfo } = response || {};
    const fonts = getFont();
    const {psTheme : theme} = useTheme();
    const styles = createStyles(theme,fonts);
    return (
        <>
            <View style={[AtomicCss.flexRow, AtomicCss.marginBottom10, AtomicCss.alignCenter]}>
                <View style={[styles.iconWrapper, AtomicCss.justifyCenter]}>
                    <Image style={styles.contactIconStyle} source={CONTACT_ICON} />
                </View>
                {!!heading && <Text style={[AtomicCss.font16, fonts.boldFontFamily, AtomicCss.blackText, AtomicCss.flex1]}>{heading}</Text> }
            </View>
            {!isEmpty(contactDetailsInfo) ? <View style={styles.detSection}>
                <View style={[AtomicCss.flexRow, AtomicCss.flexWrap, AtomicCss.margin5,AtomicCss.paddingLeft30]}>
                    {contactDetailsInfo.map((detail, index) => <CustomerDetails detail={detail}/>)}

                </View>
            </View> : null}
        </>
    )
}

export default ContactDetailsCard
