import { trainIcon } from 'apps/post-sales/src/Rails/Utils/RailImageConstant';
import myTripsNavigation from '../../../../navigation/navigation';
import { getMyRequestCardData, handleCTAClick } from '../../../utils';
import { MsrTicket, TgsData, TgsSummaryDataProps } from '../cardInterfaces';

const getTgsSummaryData = (data: TgsData, msrTicket: MsrTicket): TgsSummaryDataProps => {
  const tgsData = data && data.tgsDetails && data.tgsDetails[0];
  const { ctaInfoList: ctaDetails, infoCard } = data && data.bookingStateLayout || {};
  const { heading, status: tgsStatus, name, trainNumber, pnrText, pnr } = tgsData || {}
  const pageData = {
    fromMyTrips: true,
  };
  const openDetails = () => {
    myTripsNavigation.openTripGuaranteeBookingDetail(data, pageData);
  };
  const actions: Array<{ label: string; onPress: () => void }> = [];
  const startsIn = data.startsIn;
  const lobName = data.lobType[0];
  const bookingId = data.bookingID;
  const { text, ctaInfo } = infoCard || {};
  const infoCardStatusText = text;
  const infoCardStatusCTA = ctaInfo && ctaInfo.text;

  if (ctaDetails && ctaDetails[0]) {
    actions.push({ label: ctaDetails[0]?.text, onPress: () => handleCTAClick(ctaDetails[0], data) })
  };
  if (ctaDetails && ctaDetails[1]) {
    actions.push({ label: ctaDetails[1]?.text, onPress: () => handleCTAClick(ctaDetails[1], data) })
  };

  const handleInfoClick = () => {
    if (infoCard) {
      handleCTAClick(infoCard.ctaInfo, data);
    }
  };

  return {
    heading,
    openDetails,
    status: data.status,
    name,
    trainNumber,
    iconUrl: trainIcon,
    pnrText,
    actions,
    pnr,
    startsIn,
    lobName,
    bookingId,
    myRequestsCard: getMyRequestCardData(msrTicket),
    infoCardStatusText,
    infoCardStatusCTA,
    handleInfoPress: handleInfoClick
  };
}

export default getTgsSummaryData;