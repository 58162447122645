import {colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';


const styles = {
  capsuleBtn: {
    borderRadius: 25,
    paddingHorizontal: 20,
    paddingVertical: 14,
    ...getPlatformElevation(2)

  },
  flatBtn: {
    borderRadius: 4,
    paddingHorizontal: 20,
    paddingVertical: 14,
    ...getPlatformElevation(2)

  },
  buttonStyle: {
    backgroundColor: 'transparent',
    ...getPlatformElevation(2)

  },
  buttonTxtStyle: {
    fontSize: 14,
    fontFamily: fonts.black,
    textAlign: 'center',
    color: '#fff'
  },
  whiteButtonStyle: {
    backgroundColor: 'transparent',
    borderRadius: 25,
    margin: 5
  },
  whiteButtonBorderStyle: {
    backgroundColor: '#fff',
    borderRadius: 25,
    borderWidth: 1,
    borderColor: '#008cff',
    ...getPlatformElevation(0)
  },
  whiteBtnTxt: {
    color: '#008cff'
  },
  gradientBtnTxt: {
    color: '#fff'
  },
  disableGaeytBtnTxt: {
    color: '#fff',
    opacity: 0.4
  },
  arrowRtStyle: {
    width: 18,
    height: 14,
    tintColor: '#fff',
    marginLeft: 10
  },
  noShadowStyle: {
    ...getPlatformElevation(0),
    paddingVertical: 12
  },
  orangeButtonBorderStyle: {
    borderColor: colors.orange3,
  },
  orangeBtnTxt: {
    color: colors.orange3,
  },
};
export default styles;
