import React from 'react';
import { View, Image, Text, TouchableOpacity, StyleSheet } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { CROSS_ICON } from 'apps/post-sales/src/cab/CabImageConstants';
import CabBookingDetailsConstant from 'apps/post-sales/src/cab/CabBookingDetailsConstant';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import { ActionList, Response } from 'apps/post-sales/src/cab/types';
import isEmpty from 'lodash/isEmpty';
import BaseButton from 'apps/post-sales/src/Common/BaseButton';
import { handleClickEvent } from 'apps/post-sales/src/cab/utils/CabBookingDetailUtil';
import HTMLView from 'react-native-htmlview';
import { onCopyText } from '../../../../utils/CabBookingDetailUtil';
import getStyle, { htmlstyles } from './styles';

interface CallDriverOverlayProps {
  handleBottomOverlay: () => void;
  response: Response;
  pageName: string;
}

const CallDriverOverlay = ({ handleBottomOverlay, response, pageName }: CallDriverOverlayProps) => {
  const { callDriverData, isInternationalBooking } = response || {};
  if (!isInternationalBooking || isEmpty(callDriverData)) return null;

  const {
    heading,
    connectViaPhoneText,
    copyDriverNumberText,
    actionList,
    driverWhatsappNumber,
  } = callDriverData || {};
  const { fsStyle, ...fonts } = getFont();
  const { psTheme } = useTheme();
  const styles = getStyle(fonts, psTheme);

  const handleClick = (action: ActionList) => {
    if (action.actionId === CabBookingDetailsConstant.CopyNumberActionId) {
      onCopyText(driverWhatsappNumber);
    } else {
      handleClickEvent({}, action, response, pageName);
    }
  };

  return (
    <View style={[styles.callDriverWrap]}>
      <View style={[styles.headingSection]}>
        {!!heading && <Text style={styles.heading}>{heading}</Text>}
        <TouchableOpacity style={styles.crossIconWrapper} onPress={handleBottomOverlay}>
          <Image style={styles.crossIconStyle} source={CROSS_ICON} />
        </TouchableOpacity>
      </View>
      <View style={AtomicCss.marginVertical10}>
        {!!copyDriverNumberText && (
          <HTMLView value={copyDriverNumberText} stylesheet={htmlstyles(fonts, psTheme)} />
        )}
        <View style={[AtomicCss.flexRow, AtomicCss.justifyCenter, AtomicCss.marginBottom15]}>
          <View style={[styles.orTextWrap]}>
            <Text style={[styles.orText]}>Or</Text>
          </View>
        </View>
        {!!connectViaPhoneText && (
          <Text style={styles.connectViaPhoneText}>{connectViaPhoneText}</Text>
        )}
      </View>
      {!isEmpty(actionList) &&
        actionList.map((action) => {
          const { actionLabeltext, actionType, actionId } = action;
          return (
            <BaseButton
              key={actionId}
              variant={actionType}
              buttonStyle={styles.buttonStyle}
              textStyle={styles.textStyle}
              text={actionLabeltext}
              clickHandler={() => handleClick(action)}
              shadow={false}
            />
          );
        })}
    </View>
  );
};

export default CallDriverOverlay;
