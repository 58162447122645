import { getCommonHeaders, GET_ERROR_DATA } from '../../../../../../utils/NetworkUtils';
import { STATUS } from '../../../constants';
import DateChangeTrackingHelper from '../../../analytics/DateChangeTrackingHelper';
import FlightBookingModule from '@mmt/legacy-commons/Native/FlightBookingModule';

export const fetchUndoWCFailure = async (undoWCList, bookingId, allFailed, isCorp) => {
  const failedList = [];
  const userInfo = await FlightBookingModule.getRequestHeader(bookingId, 'UNDO_WEBCHECKIN_FAILURE');
  const headers = await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo);
  undoWCList.forEach((item) => {
    if (item.status === STATUS.FAILURE) {
      const { error } = item;
      const { errorCode, errorDescription, errorMessage } = error || {};
      const reqBody = {
        bookingId,
        pageId: isCorp ? 'ODC_UNDO_WEB_CHECKIN_CORP' : 'ODC_UNDO_WEB_CHECKIN',
        errorDetail: {
          ec: errorCode,
          em: errorMessage,
          ed: errorDescription,
        },
      };
      failedList.push(
        fetch(GET_ERROR_DATA, {
          method: 'POST',
          headers,
          body: JSON.stringify(reqBody),
        }),
      );
    }
  });
  const response = Promise.all(failedList)
    .then(async (result) => {
      if (result && result.length) {
        if (result[0].ok) {
          const res = await result[0].json();
          const { errorPageData } = res || {};
          if (errorPageData) {
            return {
              errorPageData,
              allFailedText: allFailed ? 'datechangeUndoWCFail' : 'datechangeUndoWCPartialFail',
            };
          }
        }
      }
    })
    .catch((err) => {
      DateChangeTrackingHelper.trackErrorEvent(
        'datechangereviewUndoWCOverlay',
        `errorDataAPI_${err ? err.message : ''}`,
      );
      console.log(err);
    });

  return response;
};
