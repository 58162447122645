import React from 'react';
import {ScrollView, Text, View, StyleSheet} from 'react-native';
import InfoTDR from './InfoTDR';
import AtomicCss from './commonStyles/AtomicCss';
import Header from '../../../Common/HeaderCross';
import RailBookingTrackEventHelper from "../../details/RailBookingTrackEventHelper";
import BasePage from '../../../Common/PostSalesBasePage';
import { backPressHandler } from '../../../utils/PostSaleUtil';
import { getStaticData } from '../../../staticData/staticData';
import { getFont } from '../../../PostSalesStyles';
import isEmpty from 'lodash/isEmpty';

class TDRProcedure extends BasePage {
  constructor(props) {
      super(props, "TdrProcedure");
  }
  render() {
    const staticData = getStaticData(true);
    const { tdrProcedureText } = staticData;
    const { tdrRules } = this.props.bookingDetailsResponse;
    const { fsStyle, ...fonts } = getFont(true);
    const {additionalText} = tdrProcedureText || {};
    const {infoMessageText, zonalRefundInfoText} = additionalText || {};
    return (
      <View style={[AtomicCss.greyBg, AtomicCss.flex1, AtomicCss.spaceBetween]}>
          <Header
              title={tdrProcedureText.headingText}
              headerShadow
              headingTxt="font18"
              handleClose={this.onBackPress}
          />
        <ScrollView>
          <View style={[AtomicCss.makeRelative, AtomicCss.marginBottom20, AtomicCss.marginTop2]}>
              <View style={styles.topAccordianSection}>
                  <Text style={[fsStyle.font18, AtomicCss.boldFont, AtomicCss.blackText, AtomicCss.marginBottom10]}>{tdrProcedureText.subheadingText}</Text>
                  {!isEmpty(infoMessageText) && <Text style={[fsStyle.font13, fonts.regularFontFamily, AtomicCss.lightGrey]}>
                      {infoMessageText}
                  </Text>}
                  {!isEmpty(zonalRefundInfoText) && <Text>
                      {"\n"}{zonalRefundInfoText}
                  </Text>}
              </View>
              <InfoTDR tdrRulesAccordianList={tdrRules} />
          </View>
        </ScrollView>
      </View>
    );
  }

    onBackPress = () => {
      backPressHandler();
    };

    componentDidMount() {
        RailBookingTrackEventHelper.trackLoadEvent('Details_TdrProcedure', this.props.bookingDetailsResponse);
    }

}
const styles = StyleSheet.create({
    topAccordianSection:{padding: 16, backgroundColor: '#fff', borderBottomWidth: 1, borderBottomColor: '#e5e5e5'},
    marginTop2:{marginTop: 2}
});

export default TDRProcedure;
