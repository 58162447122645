import React from 'react';
import {Image} from 'react-native';
import PropTypes from 'prop-types';
import radioChecked from '@mmt/legacy-assets/src/ic-radiobutton-active.webp';
import radioUnchecked from '@mmt/legacy-assets/src/ic-radiobutton-inactive.webp';

const RadioButton = (props) => {
  const { checked, dimension, style } = props;
  const imageStyle = [
    {
      width: dimension,
      height: dimension
    },
    style
  ];
  return (
    <Image
      style={imageStyle}
      source={checked ? radioChecked : radioUnchecked}
    />
  );
}

RadioButton.propTypes = {
  checked: PropTypes.bool.isRequired,
  dimension: PropTypes.number,
  style: PropTypes.object
};

RadioButton.defaultProps = {
  dimension: 24,
  style: {}
};

export default RadioButton;
