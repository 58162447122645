import React from 'react';
import {Animated, Easing, TextInput, View} from 'react-native';
import styles from './styles/InputElementCss';

class InputElement extends React.Component {
  constructor(props) {
    super(props);
    let top = 10;
    let fontSize = 14;
    if (this.props.value.length > 0) {
      top = -10;
      fontSize = 12;
    }

    this.state = {
      isFocused: false,
      top: new Animated.Value(top),
      fontSize: new Animated.Value(fontSize)
    };
  }

  animate() {
    Animated.parallel([
      Animated.timing(this.state.top, {
        toValue: -20,
        duration: 200,
        easing: Easing.linear
      }),
      Animated.timing(this.state.fontSize, {
        toValue: 12,
        duration: 200,
        easing: Easing.linear
      })

    ])
      .start();
  }

  reverse() {
    Animated.parallel([
      Animated.timing(this.state.top, {
        toValue: 9,
        duration: 200,
        easing: Easing.linear
      }),
      Animated.timing(this.state.fontSize, {
        toValue: 14,
        duration: 200,
        easing: Easing.linear
      })

    ])
      .start();
  }


  handleInputFocus = () => {
    this.setState({isFocused: true});
    this.animate();
    this.props.onFocus && this.props.onFocus();
  };

  handleInputBlur = () => {
    this.setState({isFocused: false});
    if (this.props.value.length == 0) {
      this.reverse();
    }
  };

  render() {
    const {
      label,
      value,
      handleChange,
      inputContainerStyle,
      editable,
      placeholder,
      placeholderTextColor,
    } = this.props;

    const {isFocused} = this.state;
    const labelStyle = [styles.label];
    const inputStyle = [styles.input];
    const containerStyle =[styles.container];


    if (isFocused) {
      inputStyle.push({borderColor: '#008cff'});
    }
    if(inputContainerStyle){
      containerStyle.push(inputContainerStyle);

    }
    const {top, fontSize} = this.state;


    return (
      <View style={containerStyle}>
        <Animated.Text style={[labelStyle, {
          fontSize,
          top
        }]}>{label}</Animated.Text>
        <TextInput
          style={inputStyle}
          value={value}
          onChangeText={handleChange}
          onFocus={this.handleInputFocus}
          onBlur={this.handleInputBlur}
          editable={editable}
          placeholder={placeholder}
          placeholderTextColor={placeholderTextColor}
        />
      </View>
    );
  }
}

export default InputElement;

InputElement.defaultProps = {
  editable: true,
  placeholder: '',
};
