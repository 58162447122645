import React from 'react';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { DATE_CHANGE_ICON, EDIT_BLUE_ICON, GUEST_MODIFICATION } from '../../HotelImageConstants';
import { isEmpty } from 'lodash';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { MODIFICATION_TYPE } from '../../details/HotelBookingDetailsConstant';
import HotelModificationpersuationMessage from './hotelModificationPersuationMessage';
import { getStaticData } from '../../../staticData/staticData';
import { LANG_AWARE } from '../../HotelConstants';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const ModificationCard = ({ response, modificationType = MODIFICATION_TYPE.DATE_CHANGE, editBtnHandler }) => {
  const { fsStyle, ...fonts } = getFont(true);
  const {
    hotelName, availableRoomDetails, paxCountText, newDatesText
  } = response;
  if (isEmpty(availableRoomDetails)){
    return null;
  }
  const { newDatesText: newDatesSubHeadingText } = getStaticData(LANG_AWARE.MODIFICATION);
  const { imageURL, roomTypeAndCount, mealPlanText, cancelPenaltyList } = availableRoomDetails[0];
  const { penaltyDescription, textColor } = !isEmpty(cancelPenaltyList) ? cancelPenaltyList[0] :{};
  const  splittedPolicy  = !isEmpty(penaltyDescription) ? penaltyDescription.split('.') : [];
  const  policyMsg  = !isEmpty(splittedPolicy) ? splittedPolicy[0].concat('.') : '';
  const policyData= {text: policyMsg, bg_color:'#00000000', color: textColor || '#955000'}

  return (<View style={styles.hotelCardWrapper}>
    <View style={[styles.topSection, AtomicCss.marginBottom20]}>
      <View style={[AtomicCss.flex1]}>
        {!isEmpty(hotelName) && <Text style={[fsStyle.font20, fonts.blackFontFamily, styles.headText]}>{hotelName}</Text>}
        {!isEmpty(roomTypeAndCount) && <Text style={[fsStyle.font14, fonts.regularFontFamily, AtomicCss.defaultText, AtomicCss.marginBottom5, fonts.boldFontFamily]}>{roomTypeAndCount}</Text>}
        {!isEmpty(mealPlanText) && <Text style={[fsStyle.font14, fonts.regularFontFamily, AtomicCss.greyText, styles.lineHeight16]}>{mealPlanText}</Text>}
      </View>
      {!isEmpty(imageURL) && <Image source={{ uri: imageURL }} style={styles.hotelImgStyle} />}
    </View>
    {!isEmpty(policyMsg) && <HotelModificationpersuationMessage persuationdata={policyData} containerStyle={styles.policyContainer}/>}
    <View style={styles.paxDetails}>
      <View style={styles.detailsRow}>
        <View style={[AtomicCss.flex1, AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom2]}>
          <View style={styles.iconWrapper}>
            <Image style={styles.userIconStyle} resizeMode={'contain'} source={GUEST_MODIFICATION} />
          </View>
          {!isEmpty(paxCountText) && <Text style={[fsStyle.font15, AtomicCss.lineHeight20, AtomicCss.defaultText, fonts.boldFontFamily, styles.textShrink]}>{paxCountText}</Text>}
        </View>
        {modificationType === MODIFICATION_TYPE.ADD_GUEST && <TouchableOpacity onPress={editBtnHandler}>
          <Image style={styles.editImgStyle} source={EDIT_BLUE_ICON} />
        </TouchableOpacity>}
      </View>
      <View style={styles.detailsRow}>
        <View style={[AtomicCss.flex1, AtomicCss.flexRow, AtomicCss.alignCenter]}>
          <View style={styles.iconWrapper}>
            <Image style={styles.userIconStyle} source={DATE_CHANGE_ICON} />
          </View>
          <Text style={[fsStyle.font15, fonts.regularFontFamily, AtomicCss.marginBottom5, AtomicCss.greyText, styles.textShrink]}>
            <Text style={[fonts.boldFontFamily, AtomicCss.defaultText]}>{newDatesText}</Text>
            {modificationType === MODIFICATION_TYPE.DATE_CHANGE && ` (${newDatesSubHeadingText})`}
          </Text>
        </View>
        {modificationType === MODIFICATION_TYPE.DATE_CHANGE && <TouchableOpacity onPress={editBtnHandler}>
          <Image style={styles.editImgStyle} source={EDIT_BLUE_ICON} />
        </TouchableOpacity>}
      </View>
    </View>
  </View>)

}


const styles = StyleSheet.create({
  hotelCardWrapper: {
    padding: 20,
    backgroundColor: '#fff'
  },
  topSection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex:1,
  },
  headText: {
    color: '#222',
    lineHeight: 24,
    marginBottom: 20
  },
  hotelImgStyle: {
    width: 76,
    height: 76,
    borderRadius: 8
  },
  lineHeight16: {
    lineHeight: 16
  },
  paxDetails: {
    backgroundColor: '#F8F8F8',
    borderRadius: 8,
    paddingVertical: 10,
  },
  detailsRow: {
    paddingHorizontal: 20,
    paddingVertical: 6,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  iconWrapper: {
    marginRight: 14,
    marginTop: 2
  },
  userIconStyle: {
    width: 25,
    height: 25,
  },
  editImgStyle: {
    width: 12,
    height: 12,
  },
  textShrink: {
    flexShrink: 1,
  },
  policyContainer: {paddingHorizontal: 0, paddingVertical: 10}
});

export default ModificationCard;
