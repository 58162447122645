import React from 'react';
import {View, Text, Image, TouchableOpacity} from 'react-native';
import styles from './SpecialClaimCardCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {refundIcon, rightArrow} from '../../../bus/BusImageConstants';
import {isEmpty} from 'lodash';

function SpecialClaimCard({ specialClaimDataList, specialClaimActionClick }) {

  const specialClaimData = !isEmpty(specialClaimDataList) ? specialClaimDataList[0] : null;
  
  const onItemClick = (specialClaimItem) => {
    specialClaimActionClick(specialClaimItem);
  }

  const specialClaimReasonView = (specialClaimDataList) => (specialClaimDataList.map(specialClaimItem =>
    (<TouchableOpacity
      key={specialClaimItem.scName}
      style={styles.redirectBtnWrapper}
      activeOpacity={0.8}
      onPress={() => { onItemClick(specialClaimItem); }}
    >
      <Text style={styles.linkBtnTxt}>{specialClaimItem.scName}</Text>
      <Image style={styles.rightArrowIcon} source={rightArrow} />
     </TouchableOpacity>)));

  return (<React.Fragment>
    <View style={[AtomicCss.marginBottom10]}>
      <View style={[styles.infoCardWrapper]}>
        <View style={styles.headingIconWrapper}>
          <Image style={styles.headingIconStyle} source={refundIcon} />
        </View>
        <View style={styles.infoCardContent}>
          <Text
            style={[AtomicCss.defaultText, AtomicCss.blackFont, AtomicCss.font14, AtomicCss.marginBottom15]}
          >{specialClaimData.claimHeader}
          </Text>
          <Text style={[AtomicCss.regularFont, AtomicCss.font14, AtomicCss.defaultText, AtomicCss.lineHeight20]}>
            {specialClaimData.claimMessage}
          </Text>

        </View>
      </View>
      {specialClaimReasonView(specialClaimDataList)}
    </View>
  </React.Fragment>
  );
}

export default SpecialClaimCard;

