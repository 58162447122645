import {colors} from '@mmt/legacy-commons/Styles/globalStyles';

const styles = {
  steps: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    paddingVertical: 9,
    paddingHorizontal: 15,
    elevation: 2,
    shadowColor: '#000000',
    shadowOffset: {width: 0, height: 2},
    shadowOpacity: 0.2,
    shadowRadius: 2,
    position: 'relative',
    zIndex: 2
  },
  stepItem: {
    flexDirection: 'row',
    marginRight: 10,
    justifyContent: 'center',
    alignItems: 'center'
  },
  stepNumberWrapper: {
    backgroundColor: colors.disabledBtnBg,
    width: 13,
    height: 13,
    borderRadius: 13,
    marginRight: 3
  },
  stepNumberWrapperActive: {
    backgroundColor: colors.black
  },
  stepNumber: {
    fontSize: 10,
    color: '#ffffff',
    lineHeight: 13,
    textAlign: 'center'
  },
  stepName: {
    color: colors.disabledBtnBg,
    fontSize: 10
  },
  stepNameActive: {
    color: colors.black
  }
};

export default styles;

