import { StyleSheet } from "react-native";
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation'
export default (theme, fonts) =>StyleSheet.create({
    topSection:{
        ...getPlatformElevation(2),
        backgroundColor:theme.color.grey22,
        paddingHorizontal:12,
        paddingTop:16,
        paddingBottom:12,
        borderRadius:8,
        marginBottom:10,
        marginLeft :10,
        marginRight : 10
    },
    iconWrapper: {
        width: 26,
        height: 26,
        marginRight: 10,
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    cardIconStyle: {
        width: 24,
        height: 24,
    },
    detailsSection: {
        paddingLeft: 35,
        paddingRight: 0,
    },
    rightArrowStyle: {
        transform: [
            {
                rotate: "-90deg"
            },
        ],
        width: 15,
        height: 10,

    },

})