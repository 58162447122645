import React, { useMemo, useEffect } from 'react';
import { BackHandler, View, Text, Image, TouchableOpacity, ScrollView } from 'react-native';
import styles from './SpecialClaimBottomSheetScreensCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { ANDROID_BACK_ARROW } from '../../../FlightImageConstants';
import RequestProcessCard from './RequestProcessCard';
import ListToMapUtil from '../../../../utils/ListToMapUtil';
import { isEmptyArray } from '../../../../Common/commonUtil';
import { getFont } from '../../../../PostSalesStyles';
import BaseButton, { BUTTON_VARIANT } from '../../../../Common/BaseButton';
import { getImageTransformStyling } from 'apps/post-sales/src/utils/PostSaleUtil';

interface props {
  submitRefundRequest: () => void;
  handleBack: () => void;
  previewResponse: any;
}

const SubmitRefundRequestScreen: React.FC<props> = ({ submitRefundRequest, handleBack, previewResponse }) => {
  const { fsStyle, ...fonts } = getFont(true);
  const { newSpecialClaimDataCard = {}} =
    previewResponse || {};
  const { cardsList = [], actionLists = [] } = newSpecialClaimDataCard || {}; 
  //create maps
  const refundRequestMap = useMemo(() => new ListToMapUtil(cardsList, 'id'), [cardsList]);
  const refundRequestActionsMap = useMemo(() => new ListToMapUtil(actionLists, 'actionId'), [
    actionLists,
  ]);

  useEffect(() => {
    BackHandler.addEventListener('hardwareBackPress', onHardWareBackPress);
    return () => {
      BackHandler.removeEventListener('hardwareBackPress', onHardWareBackPress);
    };
  }, []);

  const onHardWareBackPress = () => {
    handleBack();
    return true;
  }

  //fetch values
  const {
    title = null,
    subtitle = null,
    footerText = null,
    footerSubText = null,
  } = refundRequestMap.getObject('refund_information');
  const trackerInfo = refundRequestMap.getObject('process_tracker');

  if (!previewResponse?.newSpecialClaimDataCard) {
    return null;
  }
  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom20]}>
        <TouchableOpacity style={styles.backArrowWrapper} onPress={handleBack}>
          <Image source={ANDROID_BACK_ARROW} style={[styles.backArrowStyle, getImageTransformStyling()]} />
        </TouchableOpacity>
        <Text
          style={[
            fonts.blackFontFamily,
            fsStyle.font12,
            AtomicCss.midGreyText,
            AtomicCss.lineHeight20,
          ]}
        >
          {refundRequestActionsMap.getValue('sc_reason_text', 'actionText')}
        </Text>
      </View>
      {!!title && (
        <Text
          style={[styles.title, fonts.blackFontFamily, AtomicCss.marginBottom8, fsStyle.font22]}
        >
          {title}
        </Text>
      )}
      {!isEmptyArray(subtitle) &&
        subtitle.map((sub) => (
          <Text
            key={sub}
            style={[
              fonts.regularFontFamily,
              fsStyle.font14,
              AtomicCss.defaultText,
              AtomicCss.lineHeight20,
              AtomicCss.marginBottom24,
            ]}
          >
            {sub}
          </Text>
        ))}
      <BaseButton
        text={refundRequestActionsMap.getValue('submit_a_request_text', 'actionText')}
        variant={BUTTON_VARIANT.PRIMARY}
        clickHandler={submitRefundRequest}
        textStyle={fsStyle.font16}
      />
      {!!trackerInfo && (
        <RequestProcessCard
          trackerInfo={trackerInfo}
          footerText={footerText}
          footerSubText={footerSubText}
        />
      )}
    </ScrollView>
  );
};

export default SubmitRefundRequestScreen;
