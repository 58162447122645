import React from 'react';
import { StyleSheet, View, Text, Platform } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';

const TrackerRow = ({ name, address, dateAtLocation, isLastRow }) => {
	const { fsStyle, ...fonts } = getFont();
  const { psTheme } = useTheme();
  const styles = getStyle(fonts, psTheme);

	return (
		<View style={[styles.detailsRowSection, isLastRow && styles.noBorder]}>
			<>
				<View style={[styles.dotPositionWrapper]}>
					<View style={[isLastRow ? styles.dotFilled : styles.dot]}></View>
				</View>
				{!!name && <Text style={[AtomicCss.font16, AtomicCss.blackFont, AtomicCss.blackText, AtomicCss.marginBottom5]}>{name}</Text>}
				{!!address && <Text style={[[AtomicCss.midGreyText, AtomicCss.font12, AtomicCss.regularFont, AtomicCss.lineHeight16]]}>{address}</Text>}
				{!!dateAtLocation && <Text style={[[AtomicCss.defaultText, AtomicCss.font12, AtomicCss.boldFont, AtomicCss.lineHeight16, AtomicCss.marginTop5]]}>{dateAtLocation}</Text>}
			</>
		</View>
	);
}
const getStyle = (fonts, theme) => {
	const { color } = theme;
	return StyleSheet.create({
		detailsRowSection: {
			paddingBottom: 15,
			paddingLeft: 14,
			borderLeftWidth: 1,
			borderLeftColor: color.textGrey,
			borderStyle: Platform.OS === 'ios' ? 'solid' : 'dashed',
		},
		noBorder: {
			borderLeftWidth: 0,
		},
		dotPositionWrapper: {
			width: 20,
			height: 20,
			justifyContent: 'center',
			alignItems: 'center',
			backgroundColor: '#fff',
			position: 'absolute',
			left: -8,
			top: 0,
		},
		dot: {
			width: 8,
			height: 8,
			borderRadius: 10,
			borderWidth: 1,
			borderColor: color.textGrey,
			marginRight: 5,
		},
		dotFilled: {
			width: 8,
			height: 8,
			borderRadius: 10,
			borderWidth: 1,
			borderColor: color.textGrey,
			backgroundColor: color.textGrey,
			marginRight: 3,
		},
	})
}

export default TrackerRow;
