import React from 'react';
import { View, Text, StyleSheet, Platform } from 'react-native';
import isEmpty from 'lodash/isEmpty';
import Button from '../../../Common/Button';
import BaseButton, { BUTTON_VARIANT } from '../../../Common/BaseButton';
import { getFont } from '../../../PostSalesStyles';

const SwitchCard = ({ clickHandler, title, heading, type }) => {
  const { fsStyle, ...fonts}  = getFont(true);
  return (
    <View style={styles.switchSection}>
      <View style={styles.switchDtls}>
        {!isEmpty(heading) && (
          <Text style={[styles.switchTxt, fonts.boldFontFamily, fsStyle.font12]}>{heading}</Text>
        )}
        {!isEmpty(title) && (
          <BaseButton
          variant={BUTTON_VARIANT.CAPSULE}
          text={title}
          clickHandler={() => clickHandler(type)}
          buttonStyle={{
            paddingVertical: 8,
            paddingHorizontal: 8,
            borderRadius: 40,
            marginRight: 10,
            marginLeft: 10,
          }}
          textStyle={fsStyle.font14}
        />
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  switchSection: {
    backgroundColor: '#ffedd1',
    paddingHorizontal: 7,
    paddingVertical: 5,
    elevation: 2,
  },
  switchDtls: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  switchTxt: {
    color: '#000',
    ...Platform.select({
      android: {
        flex: 1,
      },
      ios: {
        flex: 1,
      },
    }),
  },
});

export default SwitchCard;
