import React from 'react';
import { View, Text, Image, StyleSheet, TouchableOpacity } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import FailedImg from '@mmt/legacy-assets/src/holidays/cancellation/failedImg.webp';
import Button from '../../../Components/Button';
import {isNotNullAndEmpty} from '../../../../hotel/details/utils/HotelBookingDetailUtil';
import styles from './CancelledOverlayStyle';

const ConfirmCancelledOverlay = (props) => {
    return (
        <View style={styles.contentWrpper}>
            <View style={[AtomicCss.flexRow,AtomicCss.alignCenter,AtomicCss.marginBottom15]}>
                <Image style={styles.failedImgStyle} source={FailedImg}/>
                {isNotNullAndEmpty(props.confirmCancellationData.header) && <Text style={styles.title}>{props.confirmCancellationData.header}</Text>}
            </View>
            {isNotNullAndEmpty(props.confirmCancellationData.subHeader) && <Text style={[AtomicCss.font14, AtomicCss.boldFont, AtomicCss.redText, AtomicCss.marginBottom5]}>{props.confirmCancellationData.subHeader}</Text>}
            {isNotNullAndEmpty(props.confirmCancellationData.message) && <Text style={[AtomicCss.font14, AtomicCss.boldFont, AtomicCss.defaultText, AtomicCss.marginBottom30]}>{props.confirmCancellationData.message}</Text>}
            <View style={[AtomicCss.flexRow,AtomicCss.spaceBetween,AtomicCss.alignCenter]}>
                <TouchableOpacity onPress={props.handleBackClick}>
                   {isNotNullAndEmpty(props.confirmCancellationData.buttons[0].text) && <Text style={[AtomicCss.azure,AtomicCss.boldFont,AtomicCss.font14]}>{props.confirmCancellationData.buttons[0].text}</Text>}
                </TouchableOpacity>
                <Button
                    btnTxt={props.confirmCancellationData.buttons[1].text}
                    button={props.confirmCancellationData.buttons[1]}
                    btnWidth={120}
                    handleClick={props.handleConfirmClick}
                />
            </View>
        </View>

    );
}
export default ConfirmCancelledOverlay;
