import React from 'react';
import { getTrainSummaryData } from './trainCardAdapter';
import {
    ActionsContent,
    DescriptionSection,
    MainContent,
    SummaryContainer,
    CardInfoSection,
    JourneyInfoText,
    StatusInfoSection,
    WhereToGoTopImage
} from '../cardElements';
import MSRRefundInfoCard from '../MSRRefundInfoCard';
import { MsrTicket, TrainData } from '../cardInterfaces';

interface TrainSummaryCardProps {
    response: TrainData;
    msrTicket: MsrTicket;
    isUpcomingOrOnTripFirstBooking: boolean,
    topImage: string
}

function TrainSummaryCard({ response, msrTicket, isUpcomingOrOnTripFirstBooking, topImage }: TrainSummaryCardProps) {
    const cardData = getTrainSummaryData(response, msrTicket);
    const {
        iconUrl,
        from,
        to,
        actions,
        myRequestsCard,
        passengerDetails,
        railNo,
        railPass,
        railStartDateText,
        startsIn,
        railEndDateText,
        railStartDateTime,
        railEndDateTime,
        infoCardStatusText,
        infoCardStatusCTA,
        lobName,
        bookingId,
        status,
        openDetails,
        handleInfoPress } = cardData || {};

    return (
        <SummaryContainer bookingId={response.bookingID}>
            {topImage && isUpcomingOrOnTripFirstBooking &&
                <WhereToGoTopImage topImageText={startsIn} topImage={topImage} />
            }
            <MainContent onPress={openDetails}>
                <DescriptionSection>
                    <CardInfoSection
                        iconUrl={iconUrl}
                        name={railNo}
                        startingPoint={railPass}
                        passengerDetails={passengerDetails}
                    />
                    <JourneyInfoText
                        from={from}
                        startDateTime={railStartDateTime}
                        startDateText={railStartDateText}
                        to={to}
                        endDateTime={railEndDateTime}
                        endDateText={railEndDateText}
                    />
                    {infoCardStatusText && <StatusInfoSection statusText={infoCardStatusText} statusCta={infoCardStatusCTA} handleInfoPress={handleInfoPress} />}
                </DescriptionSection>
            </MainContent>
            <ActionsContent actions={actions} isInfoCardShown={!!infoCardStatusText}/>
            {myRequestsCard && <MSRRefundInfoCard data={myRequestsCard} lobName={lobName} status={status} bookingId={bookingId} />}
        </SummaryContainer>
    );
}

export default TrainSummaryCard;