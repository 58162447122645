import { holidaysIcon } from 'apps/post-sales/src/Holiday/HolidayBookingConstants';
import myTripsNavigation from '../../../../navigation/navigation';
import { getMyRequestCardData, handleCTAClick } from '../../../utils';
import { HolidayData, HolidaySummaryDataProps, MsrTicket } from '../cardInterfaces';

export function getHolidaySummaryData(data: HolidayData, msrTicket: MsrTicket): HolidaySummaryDataProps {
  const pageData = {
    fromMyTrips: true,
  };
  const openDetails = () => {
    myTripsNavigation.openHolidayBookingDetail(data, pageData);
  };
  let summary =
    data.totalPax > 1
      ? `${data.leadPaxFirstName} + ${data.totalPax - 1} `
      : `${data.leadPaxFirstName}`;
  let passengerDetails = summary;
  const startsIn = data.startsIn;
  const lobName = data.lobType[0];
  const bookingId = data.bookingID;
  const destinationHolidayDetailsIndex = data.holidayDetails && data.holidayDetails.length - 1;
  const { startingFrom, startsOnText, startDateText: startDate } = data && data.holidayDetails && data.holidayDetails[0] || {};
  const { endsText, endDateText: endDate } = data && data.holidayDetails && data.holidayDetails[destinationHolidayDetailsIndex] || {};
  const { ctaInfoList: ctaDetails, infoCard } = data && data.bookingStateLayout || {};
  const { text, ctaInfo } = infoCard || {};
  const infoCardStatusText = text;
  const infoCardStatusCTA = ctaInfo && ctaInfo.text;

  const actions: Array<{ label: string; onPress: () => void }> = [];

  if (ctaDetails && ctaDetails[0]) {
    actions.push({ label: ctaDetails[0]?.text, onPress: () => handleCTAClick(ctaDetails[0], data) })
  };
  if (ctaDetails && ctaDetails[1]) {
    actions.push({ label: ctaDetails[1]?.text, onPress: () => handleCTAClick(ctaDetails[1], data) })
  };
  const handleInfoClick = () => {
    if (infoCard) {
      handleCTAClick(infoCard.ctaInfo, data);
    }
  };

  return {
    status: data.status,
    iconUrl: holidaysIcon,
    packageName: data.packageName,
    actions,
    passengerDetails,
    myRequestsCard: getMyRequestCardData(msrTicket),
    startsIn,
    lobName,
    bookingId,
    startingFrom,
    startsOnText,
    endsText,
    startDate,
    endDate,
    infoCardStatusText,
    infoCardStatusCTA,
    openDetails,
    handleInfoPress: handleInfoClick
  };
}

export default {};