import React from 'react';
import {PropTypes} from 'prop-types';
import { View, Text, Image , TouchableOpacity, StyleSheet } from 'react-native';
import isEmpty from 'lodash/isEmpty';

import ButtonList from './ButtonList';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getPlatformElevation from './getPlatformElevation';
import genericCardDefaultImage from '@mmt/legacy-assets/src/no_dest_default.webp';
import {
  getPackageDestNightList,
  getPaxConfigLabel,
  showCtaTabView,
  showPackageItinerary
} from '../HolidayBookingDetailUtils';
import {tabsType, PENDING_TEXT , rightArrow, buttonTypes } from '../HolidayBookingConstants';
import HolidayBookingTrackingHelper from '../HolidayBookingTrackingHelper';
import { getFont } from '../../PostSalesStyles';
import { ThemeContext } from '../../theme/theme.context';
class PackageDetails extends React.Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);
    this.state = {
      imageLoadFailed: false
    };
    this.handleButtonClick = this.handleButtonClick.bind(this);
  }

  _onImageLoadError = () => {
    this.setState({imageLoadFailed: true});
  };

  static navigationOptions = {
    header: null
  };

  handleButtonClick = (packageType) => {
    HolidayBookingTrackingHelper.trackClickEvent(
      this.props.trackPageName,
      `View_${packageType.toLowerCase()}_List`,
    );
    this.props.openDetailTabPage(packageType);
  }

  render() {
    const { bookingSummary, packageInclusions = {}, isVisaIncluded, isTransferDetailsPending } = this.props;
    const departureLabel = `From ${bookingSummary.departureCity}`;
    const packCityList = getPackageDestNightList(bookingSummary.citiesIncluded);
    const paxConfigLabel = getPaxConfigLabel(bookingSummary.noOfAdult, bookingSummary.noOfChild, bookingSummary.noOfInfant);
    const durationInDays = parseInt(bookingSummary.duration) + 1;
    const durationLabel = `${bookingSummary.duration}N/${durationInDays}D`;
    const showCtaTab = showCtaTabView(packageInclusions);
    const { fsStyle,...fonts } = getFont();
    const styles = createStyles( fonts, this.context.psTheme );

    return (
      <View style={[AtomicCss.marginBottom10, styles.cardBorder]}>
        <View style={[styles.infoCardWrapper, styles.packageDtlsWrapper]}>
          <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween, AtomicCss.marginBottom5]}>
            <View style={[AtomicCss.marginRight15, AtomicCss.flex1]}>
              <Text
                style={[AtomicCss.boldFont, AtomicCss.blackText, AtomicCss.font14, AtomicCss.marginBottom5]}
                numberOfLines={2}
                ellipsizeMode="tail"
              >{bookingSummary.packageName}
              </Text>
              <View style={styles.stickyTxtWrapper}>
                <Text style={styles.stickyTxt2}>{departureLabel}</Text>
              </View>
            </View>
            <Image
              style={[styles.hotelIconStyle]}
              source={!this.state.imageLoadFailed ?
                {uri: bookingSummary.holidayPackageImage}
                : ''}
              onError={this._onImageLoadError}
            />
          </View>
          <View style={[styles.dtlsRow, AtomicCss.flexRow, {marginTop: -17}]}>
            <Text style={styles.lftDtls}>{durationLabel}</Text>
            <View
              style={[AtomicCss.flexRow, AtomicCss.flex1, AtomicCss.alignCenter, styles.flexWrap]}
            >
              <Text
                style={[AtomicCss.boldFont, AtomicCss.blackText, AtomicCss.font12]}
              >{bookingSummary.departureDate}
              </Text>
              <Text> - </Text>
              <Text
                style={[AtomicCss.boldFont, AtomicCss.blackText, AtomicCss.font12]}
              >{bookingSummary.arrivalDate}
              </Text>
            </View>
          </View>
          {!isEmpty(packCityList) &&
          <View style={[styles.dtlsRow, AtomicCss.flexRow]}>
            <Text style={styles.lftDtls}>CITIES</Text>
            <View style={[AtomicCss.flex1, AtomicCss.flexRow, styles.flexWrap]}>
              {packCityList.map((city, index) => (
                <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
                  <Text
                    style={[AtomicCss.boldFont, AtomicCss.blackText, AtomicCss.font12, AtomicCss.lineHeight20]}
                  >{city.cityName}
                    <Text style={AtomicCss.greyText}>{' '}{city.numOfnight}N</Text>
                  </Text>
                  {index !== packCityList.length - 1 &&
                  <View style={styles.triangle}/>
                  }
                </View>
              ))}
            </View>
          </View>
          }
          <View style={[styles.dtlsRow, AtomicCss.flexRow, this.props.response?.inclusions? '' :styles.lstItem]}>
            <Text style={styles.lftDtls}>TRAVELLER(S)</Text>
            <View
              style={[AtomicCss.flexRow, AtomicCss.flex1, AtomicCss.alignCenter, styles.flexWrap]}>
              <Text
                style={[AtomicCss.boldFont, AtomicCss.blackText, AtomicCss.font12]}>{paxConfigLabel}</Text>
            </View>
          </View>
         { 
          this.props.response?.inclusions && 
           (
            <View style={ [AtomicCss.paddingTop12, styles.lstItem] }> 
             <TouchableOpacity style={[styles.redirectBtnWrapper, styles.lstItem]} activeOpacity={.8} onPress={() => this.props.handleButtonClick({ type: buttonTypes.INCLUSIONS_POPUP })}>
                <Text style={styles.linkBtnTxt}>
                   { this.props.response?.inclusionsHeading.inclusionButton } 
                  </Text>
                <Image style={styles.rightArrowIcon} source={ rightArrow }/>
              </TouchableOpacity>
              </View>
            )
        }
        </View>
        {showCtaTab && (
          <View style={[styles.btnWrapper, AtomicCss.flexRow, AtomicCss.spaceBetween]}>
            {isVisaIncluded && (
              <ButtonList
                btnIcon="visaIcon"
                btnListTxt={'View\nVisa'}
                onPress={() => this.handleButtonClick(tabsType.VISA)}
              />
            )}
            {packageInclusions.insurance && (
              <ButtonList
                btnIcon="insuranceIcon"
                btnListTxt={'View\nInsurance'}
                onPress={() => this.handleButtonClick(tabsType.INSURANCE)}
              />
            )}
            {packageInclusions.flights && (
              <ButtonList
                btnIcon="flightsIcon"
                btnListTxt={'View\nFlights'}
                onPress={() => this.handleButtonClick(tabsType.FLIGHTS)}
              />
            )}
            {packageInclusions.hotels && (
              <ButtonList
                btnIcon="hotelsIcon"
                btnListTxt={'View\nHotels'}
                onPress={() => this.handleButtonClick(tabsType.HOTELS)}
              />
            )}
            {packageInclusions.transfers && (
              <ButtonList
                btnIcon="transfersIcon"
                btnListTxt={'View\nTransfers'}
                onPress={() => this.handleButtonClick(tabsType.TRANSFERS)}
                statusText={isTransferDetailsPending ? PENDING_TEXT : null}
              />
            )}
            {packageInclusions.activities && (
              <ButtonList
                btnIcon="activitiesIcon"
                btnListTxt={'View\nActivities'}
                onPress={() => this.handleButtonClick(tabsType.ACTIVITIES)}
              />
            )}
          </View>
        )}
      </View>
    );
  }
}

const createStyles = (fonts, theme)=>StyleSheet.create({
  cardBorder: {
    borderTopColor: '#e4e4e4',
    borderBottomColor: '#e4e4e4',
    borderTopWidth: 1,
    borderBottomWidth: 1
  },
  infoCardWrapper: {
    backgroundColor: '#fff',
    justifyContent: 'space-between',
    ...getPlatformElevation(2),
    marginBottom: 1
  },
  packageDtlsWrapper: {
    padding: 16
  },
  hotelIconStyle: {
    width: 58,
    height: 58,
    alignSelf: 'flex-end'
  },
  triangle: {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderLeftWidth: 4,
    borderRightWidth: 4,
    borderBottomWidth: 7,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: '#cccccc',
    transform: [
      {rotate: '90deg'}
    ],
    marginTop: 2,
    marginLeft: 5,
    marginRight: 5,
    borderWidth: 0,
    borderColor: '#fff'
  },
  dtlsRow: {
    paddingVertical: 11,
    borderBottomColor: '#e5e5e5',
    borderBottomWidth: 1,
    alignItems: 'center'
  },
  lftDtls: {
    width: '20%',
    marginRight: '5%',
    fontSize: 12,
    ...fonts.regularFontFamily,
    color: '#747474'
  },
  flexWrap: {
    flexWrap: 'wrap'
  },
  btnWrapper: {
    backgroundColor: '#fff',
    paddingVertical: 16,
    paddingHorizontal: 12
  },
  bulletsPoints: {
    color: '#aaa',
    paddingLeft: 5,
    marginLeft: 5,
    marginRight: 5
  },
  roundDiscGrey: {
    height: 4,
    width: 4,
    backgroundColor: '#9b9b9b',
    marginHorizontal: 1,
    borderRadius: 3
  },
  stickyTxtWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    paddingLeft: 1,
  },
  stickyTxt2: {
    color: '#000',
    ...fonts.regularFontFamily,
    backgroundColor: 'transparent',
    fontSize: 12
  },
  lstItem: {
    borderBottomColor: 'transparent',
    borderTopWidth: 0,
    paddingBottom: 0
  },
  redirectBtnWrapper: {
    paddingTop:0,
    paddingBottom:20,
    paddingRight:35, 
    justifyContent:'space-between',
    flexDirection:'row',
    backgroundColor: theme.color.grey22,
    borderBottomWidth:1,
    borderBottomColor: theme.color.gray1,
    alignItems:'center',
  },

  rightArrowIcon: {
    width:9,
    height:13,
  },

  linkBtnTxt: {
    fontSize:fonts.fontSize.font14,
    ...fonts.boldFontFamily,
    color:theme.color.azure,
  }
});

PackageDetails.propTypes = {
  bookingSummary: PropTypes.object.isRequired,
  packageInclusions: PropTypes.object.isRequired
};


export default PackageDetails;
