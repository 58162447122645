
export const labelsName = {
  autoSuggest: 'destination',
  flightSearch: 'flightSearch',
  tfromCity: 'fromCity',
  tdestinationCity: 'destinationCity',
  tDepartureDate: 'departureDate',
  tReturnDate: 'returnDate',
  tEnterCity: 'enterCityText',
  tTextBoxToSearch: 'textBoxToSearch',
  tCrossIconMultiSearch: 'crossIconMultiSearch',
  tMultiCityFromRow: 'multiCityFromRow',
  tMultiCityToRow: 'multiCityToRow',
  tAddAdultButton: 'addAdultButton',
  tRemoveAdultButton: 'removeAdultButton',
  tAddChildButton: 'addChildButton',
  tRemoveChildButton: 'removeChildButton',
  tAddInfantButton: 'addInfantButton',
  tRemoveInfantButton: 'removeInfantButton',
  tDoneButtonTraveller: 'doneButtonTraveller',
  tSearchFlight: 'searchFlight'

};
