import React from 'react';
import {View, ScrollView, StyleSheet} from 'react-native';
import {fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import VisaDetailCard from './VisaDetailCard';
import {
  screenWidth
} from '../HolidayBookingConstants';
import Actions from '../../navigation/postSalesNavigation';
import {getPaxConfigLabel} from '../HolidayBookingDetailUtils';
import HolidayBookingTrackingHelper from '../HolidayBookingTrackingHelper';

const VisasList = (props) => {
  const viewVisaDetail = (refId, collapseIndex = 0) => {
    HolidayBookingTrackingHelper.trackClickEvent(
      props.pageName,
      'View_Visa_Details',
      'componentList_Visa'
    );
    HolidayBookingTrackingHelper.trackViewEvent(
      props.pageName + '_Visa',
      'Visa Booking Details',
    );
    const prePageName = HolidayBookingTrackingHelper.getPrePageName();
    Actions.visaBookingDetail({
      holidayPageName: prePageName + props.pageName + '_Visa',
      BOOKING_ID: props.bookingId,
      refBookingId: refId,
      stickyHeader: false,
      type: 'push',
      holidayBookingApi: true,
      showMyraBtn: false
    });
  }
  const travellerLabel = getPaxConfigLabel(
    props.travellerDetails.noOfAdults,
    props.travellerDetails.noOfChildren,
    props.travellerDetails.noOfInfants
  );
  return (
    <ScrollView style={styles.fullPage}>
      {props.visaDetails.holidayVisaDetailsList.map((item, index) => (
          <VisaDetailCard visaDetail={item} travellerLabel={travellerLabel} buttonText={props.isTravelProfileRequired ? 'Fill Travel Profile' : 'View Details'} index={index} viewDetails={viewVisaDetail} openTravelProfile={props.toggleTravelProfile} />
      ))}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  fullPage: {backgroundColor: '#f2f2f2', width: screenWidth},
  visaProcessInfoCard: {
    marginVertical: 8,
    flexDirection: 'row',
    marginHorizontal: 8,
    backgroundColor: '#cdf6e8',
    borderRadius: 4,
    padding: 10
  },
  succssTickStyle: {
    width: 20,
    height: 20,
    marginRight: 10
  },
  successTxt: {
    fontFamily: fonts.bold,
    color: '#00a19c',
    fontSize: 14,
    flex: 1
  }
});
export default VisasList;
