import React from 'react';
import { View, Text, Image } from 'react-native';
import styles from './CabBookingDetail/CabBookingDetailStyles';
import CabBookingDetailsConstant from '../CabBookingDetailsConstant';
import { locationGhostIcon, seatIcon } from '../CabImageConstants';
import { dateFormat, timeFormat } from '@mmt/legacy-commons/Helpers/dateHelpers';
import {
  getCabFeatures,
  fillDepartureArrivalDate
} from '../utils/CabBookingDetailUtil';

const HourlyTripDetails = (props) => {
  const { response } = props || {};
  const { carBookingInfo, carAdditionalInfo } = response || {};
  const { carDetail } = carBookingInfo || {};
  const { departureDetail, duration, kmLimit } = carAdditionalInfo || {};
  const { fromCity } = carDetail || {};
  const { address, departureTime, departureDate } = departureDetail || {};

  const renderLocationDetails = () => {
    const pickupTime = fillDepartureArrivalDate(departureTime, timeFormat);
    const pickupDate = fillDepartureArrivalDate(departureDate, dateFormat);

    return (
      <View style={{ flex: 1 }}>
        <Text style={styles.timeText}>Pickup by</Text>
        <View style={[styles.locationDetails]}>
          <View style={[styles.renterHourly]}>
            <Text style={styles.locationName} numberOfLines={1}>{fromCity} City</Text>
            <Text style={styles.smallText}>{address}</Text>
          </View>
          <View style={[styles.dropPointDetails, { alignSelf: "flex-start" }]}>
            <Text style={styles.locationName} numberOfLines={1}>{pickupTime}</Text>
            <Text style={[styles.smallText, styles.textRight]}>{pickupDate}</Text>
          </View>
        </View>
      </View>
    );
  }

  const totalTime = () => {
    return (duration && kmLimit) ? (
      <View style={styles.totalTimeSection}>
        <Text style={styles.totalTimeText}>{duration} | {kmLimit} {CabBookingDetailsConstant.DISTANCE_UNIT}
          <Text style={styles.smallText}> included in the fare</Text></Text>
      </View>
    ) : null;
  }

  const renderCabFeatures = () => {
    const cabFeatureList = getCabFeatures(response);

    return cabFeatureList && cabFeatureList.length > 0 ? (
      <View style={styles.cabFeatureList}>
        {
          cabFeatureList.map((feature, index) => {
            const firstFeatureStyle = index === 0 ? styles.cabFeatureFirst : {};
            const lastFeatureStyle = index === cabFeatureList.length - 1 ? styles.cabFeatureLast : {};
            return (
              <View style={[styles.cabFeature, firstFeatureStyle, lastFeatureStyle]} key={index}>
                <Image source={seatIcon} style={styles.cabFeatureIcon} />
                <Text style={styles.cabFeatureName}>{feature}</Text>
              </View>
            )
          })
        }
      </View>
    ) : null;
  }

  return (
    <View style={styles.card}>
      <View style={styles.cardPrimaryDetails}>
        <Image source={locationGhostIcon} style={styles.cardIcon} />
        <View style={styles.content}>
          <Text style={styles.cardTitle}>{CabBookingDetailsConstant.TRIP_DETAILS_CARD_HEADING}</Text>
          <View style={styles.body}>
            {renderLocationDetails()}
          </View>
        </View>
      </View>
      {totalTime()}
      {renderCabFeatures()}
    </View>
  );
}

export default HourlyTripDetails;