import React from 'react';
import {get} from 'lodash';
import {View, Text, TouchableOpacity, Image} from 'react-native';
import PropTypes from 'prop-types';
import styles from './DynamicFormCss';
import InputFieldMaster from './InputFieldMaster';
import {isValidPattern, getHypotheticalParentNode, getIndex, setErrorField, getSingleMultiInputStyle} from './DynamicFormUtils';
import {CloseIcon} from '../../HolidayBookingConstants';


class FormSingleSelectInput extends React.Component {
  static navigationOptions = {
    header: null
  };

  constructor(props) {
    super(props);

    this.errorField = '';

    if (this.props.visaForm.fields[this.props.fieldName].validations[getHypotheticalParentNode(this.props.parent, this.props.visaForm.fields[this.props.fieldName])] === undefined &&
      this.props.visaForm.fields[this.props.fieldName].url !== '') {
      const params = {};
      params.jsonKey = this.props.jsonKey;
      params.fieldName = this.props.fieldName;
      params.parent = getHypotheticalParentNode(this.props.parent, this.props.visaForm.fields[this.props.fieldName]);
      this.props.setField(params);
    }
    this.state = {
      selectedVal: get(this.props, `${this.props.jsonKey}.value`, undefined) ||
       this.props.visaForm.fields[this.props.fieldName].defaultValue,
      validate: this.props.validate
    };
  }

  componentWillReceiveProps(newProps) {
    if (this.props.validate === !newProps.validate) {
      this.setState({
        validate: newProps.validate
      });
    }
  }

  componentWillMount() {
    this.props.updateChildValues(`${this.props.jsonKey}.value`, this.state.selectedVal);
    this.props.updateChildValues(`${this.props.jsonKey}.parent`, getHypotheticalParentNode(this.props.parent, this.props.visaForm.fields[this.props.fieldName]));
  }

  selection = (elem) => {
    if (!this.props.visaForm.fields[this.props.fieldName].disabled) {
      this.props.updateChildValues(`${this.props.jsonKey}.value`, elem.key);
      this.props.updateChildValues(`${this.props.jsonKey}.parent`, getHypotheticalParentNode(this.props.parent, this.props.visaForm.fields[this.props.fieldName]));
      this.props.updateChildValues(`${this.props.jsonKey}.dependents`, {});
      this.setState({
        selectedVal: elem.key
      });
    }
  }

  renderSingleValueInputView = () => {
    const list = [];
    this.props.visaForm.fields[this.props.fieldName].values.forEach((elem) => {
      if (elem.parent.toLowerCase() === getHypotheticalParentNode(this.props.parent.toLowerCase(), this.props.visaForm.fields[this.props.fieldName])) { list.push(this.view(elem)); }
    });
    return list;
  }

  view = (elem) => {
    const isActive = this.state.selectedVal.toLowerCase() === elem.key.toLowerCase();
    return (
      <TouchableOpacity onPress={() => { this.selection(elem); }} key={elem.key}>
        <View style={[styles.ValueTab, isActive ? getSingleMultiInputStyle(this.props.visaForm.fields[this.props.fieldName].disabled) : '']}>
          <Text style={[styles.defaultGrey, styles.font12, styles.regularFont,
            isActive ? styles.tabActiveTxt : styles.defaultGrey]}
          >
            {elem.value}
          </Text>
        </View>
      </TouchableOpacity>
    );
  }

  renderDependents = () => {
    const dependents = [];
    if (this.props.visaForm.fields[this.props.fieldName].dependents &&
      this.props.visaForm.fields[this.props.fieldName].dependents[this.state.selectedVal.toLowerCase()]) {
      this.props.visaForm.fields[this.props.fieldName].dependents[this.state.selectedVal.toLowerCase()].forEach((element) => {
        dependents.push(<InputFieldMaster
          focusOnNextField={this.props.focusOnNextField}
          textInputRefs={this.props.textInputRefs}
          labelIndex={`${this.props.labelIndex}.${dependents.length + 1}`}
          key={`${this.state.selectedVal}${element}`}
          parent={this.state.selectedVal}
          jsonKey={`${this.props.jsonKey}.dependents`}
          fieldName={element}
          visaForm={this.props.visaForm}
          updateChildValues={this.props.updateChildValues}
          validate={this.state.validate}
          setField={this.props.setField}
          showDropDown={this.props.showDropDown}
        />);
      });
    }
    return dependents;
  }

  shouldShowErrorField = () => {
    if (this.props.validate) {
      this.errorField = setErrorField(this.props.visaForm.fields[this.props.fieldName].mandatory, this.state.selectedVal, this.props.visaForm.fields[this.props.fieldName].error);
      if (this.props.visaForm.fields[this.props.fieldName].mandatory) {
        if (this.state.selectedVal === '') {
          return true;
        }
        return !isValidPattern(new RegExp(this.props.visaForm.fields[this.props.fieldName].validations[getHypotheticalParentNode(this.props.parent, this.props.visaForm.fields[this.props.fieldName])]), this.state.selectedVal);
      }
      if (this.state.selectedVal === '') {
        return false;
      }
      return !isValidPattern(new RegExp(this.props.visaForm.fields[this.props.fieldName].validations[getHypotheticalParentNode(this.props.parent, this.props.visaForm.fields[this.props.fieldName])]), this.state.selectedVal);
    }
    return false;
  }

  getDependents = () => {
    const dependents = this.renderDependents();
    if (dependents.length !== 0) {
      return dependents;
    }
  }

  shouldShowCancelIcon = () => {
    if (this.props.visaForm.fields[this.props.fieldName].mandatory) {
      return this.props.visaForm.fields[this.props.fieldName] &&
      this.props.visaForm.fields[this.props.fieldName].addMore &&
      getIndex(this.props.jsonKey) !== '0';
    }
    return this.props.visaForm.fields[this.props.fieldName] &&
      this.props.visaForm.fields[this.props.fieldName].addMore;
  }
  render() {
    return (
      <View>
        {this.shouldShowCancelIcon() &&
        <View style={[styles.flex1, styles.flexRow, styles.mar12Bt, styles.visaFormCloseIcon]}>
          <TouchableOpacity onPress={() => this.props.deleteWRTIndex(this.props.indexInParent)}>
            <Image source={CloseIcon} style={styles.visaFormCloseIconImage} />
          </TouchableOpacity>
        </View>}
        <View style={[styles.flexRow, {flexWrap: 'wrap'}]}>
          {this.renderSingleValueInputView()}
        </View>
        {this.shouldShowErrorField() &&
        <Text style={[styles.ErrorText, styles.font14, styles.regularFont]}>{this.errorField}</Text>}
        {this.getDependents()}
      </View>
    );
  }
}

FormSingleSelectInput.propTypes = {
  labelIndex: PropTypes.string.isRequired,
  parent: PropTypes.string.isRequired,
  jsonKey: PropTypes.string.isRequired,
  visaForm: PropTypes.object.isRequired,
  fieldName: PropTypes.string.isRequired,
  validate: PropTypes.bool.isRequired,
  updateChildValues: PropTypes.func.isRequired,
  setField: PropTypes.func.isRequired,
  showDropDown: PropTypes.func.isRequired,
  deleteWRTIndex: PropTypes.func.isRequired,
  indexInParent: PropTypes.number.isRequired,
  textInputRefs: PropTypes.object.isRequired,
  focusOnNextField: PropTypes.func.isRequired
};

export default FormSingleSelectInput;
