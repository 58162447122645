import { StyleSheet } from 'react-native';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';

const getStyles = (fonts) => {
  return StyleSheet.create({
    trackerDtls: {
      position: 'relative',
      marginLeft: 10,
      borderLeftWidth: 4,
      borderLeftColor: colors.gray3,
      paddingBottom: 24,
    },
    trackerDtlsCompleted: {
      borderLeftColor: colors.green,
    },
    trackerDtlsActive: {
      borderLeftColor: colors.azure,
    },
    trackerDtlsActive: {
      borderLeftColor: colors.azure,
    },
    trackerDtlsCredit: {
      borderLeftColor: colors.green,
    },
    trackerInnerDtls: {
      position: 'relative',
      flexDirection: 'row',
    },
    countWrapper: {
      width: 16,
      height: 16,
      backgroundColor: colors.gray3,
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 50,
      left: -10,
      top: -1,
      marginRight: 8,
    },
    countWrapperCredit: {
      width: 16,
      height: 8,
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 2,
      left: -10,
      top: 4,
    },
    countWrapperCompleted: {
      backgroundColor: colors.green,
    },
    countWrapperProgress: {
      backgroundColor: colors.green,
    },
    trackerStatus: {
      fontFamily: fonts.bold,
      color: colors.black,
      ...fonts.font14,
      lineHeight: 20,
    },
    trackerStatusExpired: {
      color: colors.lightTextColor,
    },
    trackerStatusTime: {
      fontFamily: fonts.regular,
      color: colors.greyLight,
      ...fonts.font13,
      lineHeight: 20,
    },
    trackerPriceTxt: {
      color: colors.lightYello,
      ...fonts.font13,
      fontFamily: fonts.regular,
    },
    iconWrapperProcessed: {
      width: 24,
      height: 24,
      position: 'absolute',
      left: -3,
      top: -4,
      zIndex: 11,
      borderRadius: 50,
      backgroundColor: colors.greyLight,
      alignItems: 'center',
      justifyContent: 'center',
    },
    iconWrapperCompleted: {
      backgroundColor: colors.green,
      width: 24,
      height: 24,
      position: 'absolute',
      left: -3,
      top: -4,
      zIndex: 11,
      borderRadius: 50,
      alignItems: 'center',
      justifyContent: 'center',
    },
    iconWrapperActive: {
      backgroundColor: colors.azure,
      width: 24,
      height: 24,
      position: 'absolute',
      left: -3,
      top: -4,
      zIndex: 11,
      borderRadius: 50,
      alignItems: 'center',
      justifyContent: 'center',
    },
    tickIcon: {
      width: 14,
      height: 14,
    },
    circle: {
      width: 16,
      height: 16,
      position: 'absolute',
      left: 0,
      top: 0,
      zIndex: 11,
      backgroundColor: colors.gray3,
      borderRadius: 50,
    },
    circleCompleted: {
      backgroundColor: colors.green,
    },
    circleProgress: {
      backgroundColor: colors.green,
    },
    circleCredit: {
      width: 16,
      height: 8,
      borderRadius: 2,
      backgroundColor: colors.green,
    },
    lastItem: {
      marginBottom: 0,
    },
    borderTopActive: {
      borderTopColor: colors.cabsGreyBg,
      paddingTop: 15,
      borderTopWidth: 1,
    },
    lastItemStyle: {
      borderLeftWidth: 3,
      borderLeftColor: 'transparent',
      paddingBottom: 0,
    },
    progressTxt: {
      fontFamily: fonts.bold,
      color: colors.lightYello,
    },
    infoWrapper: {
      backgroundColor: colors.grayBg,
      padding: 8,
      top: -8,
      borderRadius: 4,
    },
    statusWrapper: {
      backgroundColor: colors.grayBg,
      paddingVertical: 8,
      paddingHorizontal: 12,
      marginTop: 16,
      borderRadius: 4,
    },
    statusWrapperExpired: {
      backgroundColor: colors.grayBg,
      opacity: 0.5,
    },
    statusWrapperCompleted: {
      backgroundColor: colors.limeGreen,
    },
    statusWrapperCredit: {
      backgroundColor: colors.limeGreen,
    },
    trackerStatusHead: {
      ...fonts.font14,
      lineHeight: 22,
      fontFamily: fonts.black,
      color: colors.defaultTextColor,
      marginBottom: 4,
    },
    trackerStatusHeadCompleted: {
      color: colors.green,
    },
    trackerStatusHeadCredit: {
      color: colors.green,
    },
    expiredWrapper: {
      backgroundColor: colors.grayBg,
      paddingVertical: 8,
      paddingHorizontal: 12,
      marginTop: -12,
      borderRadius: 4,
      flexDirection: 'row',
      marginLeft: -48,
      marginBottom: -28,
    },
    calIconStyle: {
      width: 20,
      height: 20,
      marginRight: 10,
    },
    dateInfo: {
      ...fonts.font13,
      lineHeight: 20,
      fontFamily: fonts.bold,
      color: colors.black,
      flex: 1,
    },
  });
}

export default getStyles;
