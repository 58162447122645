import React, { memo } from 'react';
import { Text, View, Image, TouchableOpacity } from 'react-native';

import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import styles from './hotelCrossSellCss';
import { LOCATION_ICON } from '../../../../FlightImageConstants';
import { get } from 'lodash';
import { isEmptyArray, isNullOrEmptyObject } from '../../../../../Common/commonUtil';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import FlightHotelCrossSellTrackingHelper from './tracking';
import { getPerNightText } from '../../../../FlightBookingUtil';

import StarRating from '../../../../../Common/StarRating';
import { getFont } from '../../../../../PostSalesStyles';
const MAX_RATING = 5;

const HotelCard = ({ data, index }) => {
  const {
    name,
    starRating,
    flyfishReviewSummary,
    cityName,
    displayFare,
    appDeepLink,
    mainImages,
  } = data;
  const perNightText = getPerNightText();

  const { fsStyle, ...fonts } = getFont(true);

  const handleHotelCardClick = () => {
    FlightHotelCrossSellTrackingHelper.trackClickEvent('details', 'reco');
    if (appDeepLink) GenericModule.openDeepLink(appDeepLink);
  };

  const cumulativeRating = get(flyfishReviewSummary, 'MMT.cumulativeRating', null);
  return (
    <TouchableOpacity style={styles.recommondedHotel} onPress={handleHotelCardClick}>
      {!isEmptyArray(mainImages) && !!mainImages[0] && (
        <View>
          <Image style={styles.hotelImgStyle} source={{ uri: mainImages[0] }} />
        </View>
      )}
      <View style={styles.midDetailWrapper}>
        <View style={[styles.detailsSection, AtomicCss.spaceBetween]}>
          <View style={AtomicCss.flex1}>
            <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom3]}>
              <View style={styles.ratingHotel}>
                {!!cumulativeRating && (
                  <Text style={[AtomicCss.whiteText, fonts.regularFontFamily, fsStyle.font12]}>
                    {cumulativeRating}/{MAX_RATING}
                  </Text>
                )}
              </View>
              <StarRating maxCount={MAX_RATING} activeCount={starRating} />
            </View>
            {!!name && (
              <Text
                style={[fonts.regularFontFamily, AtomicCss.blackText, fsStyle.font14]}
                numberOfLines={2}
              >
                {name}
              </Text>
            )}
            <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
              <Image style={styles.locationStyle} source={LOCATION_ICON} />
              {!!cityName && (
                <Text style={[AtomicCss.defaultText, fsStyle.font11, fonts.regularFontFamily]}>
                  {cityName}
                </Text>
              )}
            </View>
          </View>

          {!isNullOrEmptyObject(displayFare) && (
            <View style={styles.priceSection}>
              {!!displayFare?.displayPriceBreakDown?.nonDiscountedPrice && (
                <Text style={[styles.slacedPrice, AtomicCss.alignRight, fsStyle.font11]}>
                  {displayFare.displayPriceBreakDown.nonDiscountedPrice}
                </Text>
              )}
              {!!displayFare.displayPriceBreakDown.displayPrice && (
                <Text
                  style={[
                    fsStyle.font14,
                    fonts.boldFontFamily,
                    AtomicCss.blackText,
                    AtomicCss.alignRight,
                  ]}
                >
                  {displayFare.displayPriceBreakDown.displayPrice}
                </Text>
              )}
              {!!perNightText && (
                <Text
                  style={[
                    AtomicCss.lightTextColor,
                    fsStyle.font11,
                    fonts.regularFontFamily,
                    AtomicCss.alignRight,
                  ]}
                >
                  {perNightText}
                </Text>
              )}
            </View>
          )}
        </View>
        {!!displayFare?.displayPriceBreakDown?.couponInfo?.description && (
          <View style={styles.moreInfoSection}>
            <View style={styles.effectsStyle}></View>
            <View style={styles.info}>
              <Text style={[AtomicCss.defaultText, fsStyle.font12, fonts.regularFontFamily]}>
                {displayFare.displayPriceBreakDown.couponInfo.description}
              </Text>
            </View>
          </View>
        )}
      </View>
    </TouchableOpacity>
  );
};

export default memo(HotelCard);
