import React from 'react';
import {
  View,
  TouchableOpacity,
  Image,
  Linking,
  Platform,
  DeviceEventEmitter,
} from 'react-native';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import {
  AbConfigKeyMappings,
  getPokusConfig,
} from '@mmt/legacy-commons/Native/AbConfig/AbConfigModule';
import { PokusLobs } from '@mmt/legacy-commons/Native/AbConfig/AbConfigKeyMappings';
import Actions from '../../../../../navigation/postSalesNavigation';
import styles from './FlightDtlsCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import FlightConst, {
  EventEmitterConst,
} from '../../FlightBookingDetailsConstant';
import {setFlightAddonItem} from '../../../../FlightBookingUtil';
import FlightBookingTrackingHelper from '../../helper/FlightBookingTrackingHelper';
import MMTConnectCard from '../MMTConnectCard';
import {
  BAGGAGE,
  MEALS,
  SEATS
} from '../../../AncillaryModule/constants';
import AddonCard from './AddonCard';
import Button from '../../../../../Common/Button';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import { CIRCLED_ARROW } from '../../../../FlightImageConstants';
import { getStaticData } from '../../../../../staticData/staticData';
import HolidayBookingTrackingHelper from '../../../../../Holiday/HolidayBookingTrackingHelper';
import HeaderComponent from './HeaderComponent';
import SegmentGroupContent from './HeaderComponent/SegmentGroupContent';
import FlightItinaryDetail from './FlightItinaryDetail';
import {
  isFareLockExpired,
  getItinaryDetails,
  isAllPaxCancelledForSegment,
  getFailedStatus,
  getSegmentStatusStyle,
} from '../../utils/flightItinaryUtil';
import { ThemeContext } from '../../../../../theme/theme.context';
import { getRootTag } from '@mmt/legacy-commons/AppState/RootTagHolder';
import { getMealBaggageAvailableSegment } from 'core-ui-lib/flight/common/FlightBookingDetails/utils/ancillaryUtil';

class FlightDtlsCardMain extends React.Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);
    this.isFailed = getFailedStatus(this.props.segmentFlightDetails);
    this.isCompleted = this.props.segmentFlightDetails.completed;
    this.isAllCancelled = isAllPaxCancelledForSegment(
      get(this.props.segmentFlightDetails, 'segmentDetails[0]', {})
    );
    let collapsedFltDtls;
    if (this.props.collapsed === null) {
      this.isFailed = getFailedStatus(this.props.segmentFlightDetails);
      this.isCompleted = this.props.segmentFlightDetails.completed;
      this.isAllCancelled = isAllPaxCancelledForSegment(
        get(this.props.segmentFlightDetails, 'segmentDetails[0]', {})
      );
      collapsedFltDtls =
        !this.isAllCancelled && !this.isCompleted && !this.isFailed;
    } else {
      collapsedFltDtls = !this.props.collapsed;
    }
    for (
      let i = 0;
      i < this.props.segmentFlightDetails.segmentDetails.length;
      i += 1
    ) {
      if (
        this.props.segmentFlightDetails.segmentDetails[i].segmentRefundDetail
      ) {
        collapsedFltDtls = true;
        break;
      }
    }
    if (isFareLockExpired(this.props.flightDetailResponse)) {
      collapsedFltDtls = true;
    }
    this.state = {
      collapsedFltDtls,
    };
    this.handleFlightDtls = this.handleFlightDtls.bind(this);
  }

  handleFlightDtls = () => {
    this.setState({
      collapsedFltDtls: !this.state.collapsedFltDtls,
    });
  };

  onBtnClick = (action) => {
    if (Platform.OS === 'ios') {
      this.props.flightDetailResponse.reactTag = getRootTag();
    } else if (Platform.OS === 'web') {
      //remove this check once web verified
      // this.props.destroySession();
    }
    DeviceEventEmitter.emit(EventEmitterConst.CTA_CLICKED_EVENT);
    switch (action.actionFamily) {
      case FlightConst.ACTION_MEAL:
        setFlightAddonItem(FlightConst.ACTION_MEAL);
        Actions.openAncillary({
          pageKey:'selectAddons',
          bookingId: this.props.flightDetailResponse.bookingID,
          activeSegment: getMealBaggageAvailableSegment(this.props.segmentFlightDetails, this.props.segmentAncillaryDetails, MEALS),
          addonType: MEALS,
          flightDetailResponse : this.props.flightDetailResponse,
          holidayBooking: this.props.holidayBooking,
          holidayData: this.props.holidayData,
          isCorporateBooking: this.props.isCorporateBooking,
        });
        this.props.holidayBooking
          ? HolidayBookingTrackingHelper.trackClickEvent(
              this.props.holidayPageName,
              'Add Meal'
            )
          : FlightBookingTrackingHelper.trackClickEvent(
              'Details',
              'Flight_Card_meal',
              this.props.flightDetailResponse
            );
        break;
      case FlightConst.ACTION_SEAT:
        setFlightAddonItem(FlightConst.ACTION_SEAT);
        const isNewAncillaryFlow = getPokusConfig(
          PokusLobs.POST_SALES,
          AbConfigKeyMappings.newAncillaryModule,
          false,
        );
        Actions.openAncillary({
          pageKey: isNewAncillaryFlow ? 'selectAddons': 'selectSeats',
          addonType: SEATS,
          bookingId: this.props.flightDetailResponse.bookingID,
          flightDetailResponse : this.props.flightDetailResponse,
          activeSegment:
            this.props.segmentFlightDetails.segmentDetails[0].segmentLineNo,
          holidayBooking: this.props.holidayBooking,
          holidayData: this.props.holidayData,
          isCorporateBooking: this.props.isCorporateBooking,
        });
        this.props.holidayBooking
          ? HolidayBookingTrackingHelper.trackClickEvent(
              this.props.holidayPageName,
              'Add Seat'
            )
          : FlightBookingTrackingHelper.trackClickEvent(
              'Details',
              'Flight_Card_seat',
              this.props.flightDetailResponse
            );
        break;
      case FlightConst.ACTION_BAGGAGE:
        Actions.openAncillary({
          pageKey:'selectAddons',
          bookingId: this.props.flightDetailResponse.bookingID,
          flightDetailResponse : this.props.flightDetailResponse,
          activeSegment: getMealBaggageAvailableSegment(this.props.segmentFlightDetails, this.props.segmentAncillaryDetails, BAGGAGE),
          addonType: BAGGAGE,
          holidayBooking: this.props.holidayBooking,
          holidayData: this.props.holidayData,
          isCorporateBooking: this.props.isCorporateBooking,
        });
        this.props.holidayBooking
          ? HolidayBookingTrackingHelper.trackClickEvent(
              this.props.holidayPageName,
              'Add Baggage'
            )
          : FlightBookingTrackingHelper.trackClickEvent(
              'Details',
              'Flight_Card_baggage',
              this.props.flightDetailResponse
            );
        break;
      case FlightConst.ACTION_WEBCHECKIN:
        if (action.url) {
          if (action.openInWebView) {
            const { flightWebCheckInText } = getStaticData();
            Actions.openPsWebView({
              headerText: action.webViewTitle || flightWebCheckInText,
              headerIcon: require('@mmt/legacy-assets/src/back-white.webp'),
              url: action.url,
            });
          } else {
            Linking.openURL(action.url);
          }
          this.props.holidayBooking
            ? HolidayBookingTrackingHelper.trackClickEvent(
                this.props.holidayPageName,
                'Web Checkin'
              )
            : FlightBookingTrackingHelper.trackClickEvent(
                'Details',
                'Flight_Card_webcheckin_outside',
                this.props.flightDetailResponse
              );
        } else {
          Actions.openAncillary({
            pageKey: 'webcheckin',
            bookingId: this.props.flightDetailResponse.bookingID,
            flightDetailResponse: this.props.flightDetailResponse,
            holidayBooking: this.props.holidayBooking,
            holidayData: this.props.holidayData,
            isCorporateBooking: this.props.isCorporateBooking,
          });
          this.props.holidayBooking
            ? HolidayBookingTrackingHelper.trackClickEvent(
                this.props.holidayPageName,
                'Web Checkin'
              )
            : FlightBookingTrackingHelper.trackClickEvent(
                'Details',
                'Flight_Card_webcheckin_inside_2',
                this.props.flightDetailResponse
              );
        }
        break;
        case FlightConst.ACTION_WHEELCHAIR:
          FlightBookingTrackingHelper.trackWheelChairClickEvent('Details','Add_wheelchair', this.props.flightDetailResponse);
          this.props.toggleWheelChairOverlay(this.props.segmentFlightDetails?.segmentDetails[0]?.segmentLineNo);
        break;
      default:
        break;
    }
  };

  getStatusTextColor(segmentGroupStatus) {
    return getSegmentStatusStyle(segmentGroupStatus, styles);
  }

  render() {
    const staticText = getStaticData();
    const itinaryDetails = {
      ...getItinaryDetails(
        this.props.segmentFlightDetails,
        staticText,
        this.props.segmentAncillaryDetails,
        this.props.flightDetailResponse,
        this.props.tentativeWindowBookingStatus,
        this.getStatusTextColor
      ),
    };
    const { segmentDetails, stopOvers, segmentInformation } = itinaryDetails;
    const activeArrowStyle = this.state.collapsedFltDtls
      ? [styles.arrowCircleStyle]
      : [styles.arrowCircleStyle, styles.arrowCircleStyleActive];

    const { farelockInfo, isAllCancelled, isCompleted, isFailed } =
      itinaryDetails || {};
    const isArrowVisiable =
      !isFareLockExpired(this.props.flightDetailResponse) &&
      (this.props.collapsed !== null ||
        isAllCancelled ||
        isCompleted ||
        isFailed);
        const { segmentAncillaryGenericMessageObj } = this.props;
    return (
      <View
        style={[AtomicCss.marginBottom10, styles.fltDtlsWrapper]}
        onLayout={({ nativeEvent }) => {
          this.props.updateFlightLayout(
            nativeEvent.layout.y,
            FlightConst.FLIGHT_SEGMENT_PARENT_HEIGHT,
            this.props.layoutKey
          );
        }}
      >
        <View
          style={
            isArrowVisiable ? styles.topHeading : styles.topHeadingWithoutArrow
          }
        >
          <HeaderComponent itinaryDetails={itinaryDetails} />
          <SegmentGroupContent itinaryDetails={itinaryDetails} />
          {isArrowVisiable && (
            <TouchableOpacity
              activeOpacity={0.8}
              style={styles.actionBtnStyle}
              onPress={(event) => this.handleFlightDtls(event)}
            >
              <Image style={activeArrowStyle} source={CIRCLED_ARROW} />
            </TouchableOpacity>
          )}
          <MMTConnectCard flightDetailResponse={this.props.flightDetailResponse} />
        </View>
        {this.state.collapsedFltDtls &&
          segmentDetails &&
          segmentDetails.map((segmentDetail, index) => {
            const isTechnicalStopOver =
              !isEmpty(stopOvers) && !stopOvers[0].flightChange;
            const showExtraInfo =
              !(isTechnicalStopOver && index !== segmentDetails.length - 1) &&
              !this.props.isFareLockBooking;
              const segmentAncillaryGenericMessage = segmentAncillaryGenericMessageObj && segmentAncillaryGenericMessageObj[segmentDetail && segmentDetail.segmentLineNo]
            return (
              <FlightItinaryDetail
                itinaryDetails={itinaryDetails}
                segmentDetail={segmentDetail}
                index={index}
                flightDetailResponse={this.props.flightDetailResponse}
                segmentAncillaryDetails={this.props.segmentAncillaryDetails}
                holidayBooking={this.props.holidayBooking}
                holidayPageName={this.props.holidayPageName}
                holidayData={this.props.holidayData}
                isCorporateBooking={this.props.isCorporateBooking}
                updateFlightLayout={this.props.updateFlightLayout}
                layoutKey={this.props.layoutKey}
                showExtraInfo={showExtraInfo}
                setFareServiceOverlayData={this.props.setFareServiceOverlayData}
                openDeepLink={this.props.openDeepLink}
                tentativeWindowBookingStatus={
                  this.props.tentativeWindowBookingStatus
                }
                errorPageData={this.props.errorPageData}
                segmentAncillaryGenericMessage={segmentAncillaryGenericMessage}
              />
            );
          })}
        <AddonCard
          itinaryDetails={itinaryDetails}
          segmentDetails={segmentDetails}
          isCorporateBooking={this.props.isCorporateBooking}
          onBtnClick={this.onBtnClick}
          ancillaryResponseReceived={this.props.ancillaryResponseReceived}
        />
        {farelockInfo && farelockInfo.btnData && (
          <View style={styles.btnWrapper}>
            <Button
              btnTxt={farelockInfo.btnData.title}
              btnBg="gradientBtn"
              btnWidth="100%"
              clickHandler={() =>
                GenericModule.openDeepLink(farelockInfo.btnData.link)
              }
            />
          </View>
        )}
      </View>
    );
  }
}

FlightDtlsCardMain.defaultProps = {
  collapsed: null,
};

//remove once verified on web
// export default connect(null, addOnsDispatchToProps)(FlightDtlsCardMain);
export default FlightDtlsCardMain;
