import {Platform, StyleSheet} from 'react-native';
import {colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import { themeColors } from '../PostSalesStyles';

const getStyles = ()=> StyleSheet.create({
    ActionBtnHalf: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        width: '100%',
        backgroundColor: colors.grey22,
        paddingBottom: 10,
        marginTop: 15
    },
    transperantBgrnd: {
        ...Platform.select({
          ios: {
            backgroundColor: 'transparent'
          }
        })
    },
    ActionBtnRec: {
        backgroundColor: colors.grey22,
    },
    ActionBtn: {
        backgroundColor: colors.grey22,
        paddingBottom: 10,
        marginTop: 15
    },
    CapsuleBtn: {
        backgroundColor: colors.grey22,
        paddingBottom: 12,
        paddingTop: 12,
        paddingLeft: 10,
        paddingRight: 10,
        borderWidth: 1,
        borderColor: colors.lightBlue,
        borderRadius: 50,
        flex: 1,
        marginRight: 10,
        marginLeft: 10
    },
    BlueText: {
        color: themeColors.primary,
        fontFamily: fonts.bold
    },
    CapsuleBtnFill: {
        paddingBottom: 12,
        paddingTop: 12,
        paddingLeft: 10,
        paddingRight: 10,
        borderRadius: 8,
        marginRight: 10,
        marginLeft: 10
    },
    RecFullBtn: {
        backgroundColor: themeColors.rectFullButtonBgColor,
        paddingBottom: 20,
        paddingTop: 20,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: 45,
        paddingRight: 35,
        borderBottomWidth: 1,
        borderBottomColor: colors.lightGrey3
    },
    RecFullBtnArrow: {
        width: 9,
        height: 12,
        tintColor: themeColors.rightArrowTintColor
    },
    RbBtnWithIconWrap: {
        width: 80,
        paddingLeft: 6,
        paddingRight: 6
    },
    RbBtnWithIconImg: {
        width: 36,
        height: 36,
        alignSelf: 'center'
    },
    RbBtnWithIconText: {
        textAlign: 'center',
        color: themeColors.roundBtnIconText,
        lineHeight: 20,
        fontFamily: fonts.semiBold
    },
});

export default getStyles;
