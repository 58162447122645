import React from 'react';
import { ScrollView, View, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import Actions from '../../../../../navigation/postSalesNavigation';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import BasePage from '../../../../../Common/PostSalesBasePage';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import CancelPolicyList from './CancelPolicyList';
import FlightCancInfo from '../FlightCancInfo';
import Header from '../../../../../Common/HeaderCross';
import TopCancellationPolicyMsg from './TopCancellationPolicyMsg';
import FlightBookingTrackingHelper from '../../helper/FlightBookingTrackingHelper';
import { cancellationPolicyBtnClick } from '../../../../FlightBookingUtil';
import BaseButton, { BUTTON_VARIANT } from '../../../../../Common/BaseButton';
import { FLIGHT_THROTTLE_OBJECT } from '../../FlightBookingDetailsConstant';
import isEmpty from 'lodash/isEmpty';
import HeaderWrapper from 'apps/post-sales/src/Common/HeaderWrapper';
class CancellationPolicyPage extends BasePage {
  constructor(props) {
    super(props, 'flightCancellationPolicy');
  }

  static navigationOptions = {
    header: null,
  };

  render() {
    const { pageName, penaltyData, response, isTopMsgCardHtml } = this.props;
    const { infoList, segmentGroupPenaltyList } = penaltyData;
    const topMsg = penaltyData.descObj.descObjText;
    return (
      <View style={{ flex: 1, backgroundColor: '#f2f2f2' }}>
        <HeaderWrapper
          titleText={pageName}
          clickHandler={this.onBackPress}
      />
        <ScrollView>
          <View style={[AtomicCss.flex1]}>
            {this.props.showTopMsgCard && (
              <TopCancellationPolicyMsg
                dbMsg={this.getDBMsg()}
                topMsg={topMsg}
                zcMsg={this.props.response.zcGlobalMsg}
                isTopMsgCardHtml={isTopMsgCardHtml}
                isSubscriptionBooking={response.isSubscriptionBooking}
              />
            )}
            {this.getSegmentWisePenalty(segmentGroupPenaltyList)}
            {infoList && <CancelPolicyList cancellationPolicyInfo={infoList} />}
          </View>
        </ScrollView>
        {isEmpty(response.delayedODCState) && this.props.actionBtn && (
          <BaseButton
            variant={BUTTON_VARIANT.PRIMARY}
            text={this.props.actionBtn.text}
            buttonStyle={styles.CapsuleBtnFill}
            shadow
            clickHandler={() =>
              cancellationPolicyBtnClick(this.props.actionBtn.btnAction, response)
            }
            {...FLIGHT_THROTTLE_OBJECT}
          />
        )}
      </View>
    );
  }

  getDBMsg = () => {
    if (this.props.response.dbMessage) {
      return this.props.response.dbMessage.globalMessage;
    }
    return undefined;
  };

  onBackPress = () => {
    Actions.pop();
  };

  getSegmentWisePenalty = (segmentGroupPenaltyList) =>
    segmentGroupPenaltyList.map((segmentPenaltyData) => (
      <FlightCancInfo segmentPenaltyData={segmentPenaltyData} />
    ));

  componentDidMount() {
    if (this.props.pageName === 'Date Change Policy') {
      FlightBookingTrackingHelper.trackLoadEvent('Details_DateChangePolicy', this.props.response);
    } else {
      FlightBookingTrackingHelper.trackLoadEvent('Details_CancelPolicy', this.props.response);
    }
  }
}

export default CancellationPolicyPage;

CancellationPolicyPage.propTypes = {
  penaltyData: PropTypes.object.isRequired,
  pageName: PropTypes.string.isRequired,
  showTopMsgCard: PropTypes.bool.isRequired,
  actionBtn: PropTypes.object,
  onBtnClick: PropTypes.func.isRequired,
  response: PropTypes.object.isRequired,
};

CancellationPolicyPage.defaultProps = {
  btnAction: undefined,
};

const styles = StyleSheet.create({
  CapsuleBtnFill: {
    paddingVertical: 16,
    marginHorizontal: 8,
    marginBottom: 8,
  },
  BtnTouch: {
    marginTop: 20,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 2,
    borderRadius: 4,
  },
  btnStyle: {
    color: colors.white,
    fontSize: 14,
    backgroundColor: 'transparent',
    alignSelf: 'center',
  },
});
