import GITheme from '../GITheme';
import { ps_colors } from '../PostSalesStyles';

// TODO: we will remove GITheme once we add unique colors in this file and migrate all lobs to use colors from theme

const GI_COLOR_THEME = {
  ...GITheme,
  ...ps_colors,
  //   Add unique colors here
  primary: '#2274E0',
  primaryGradient:['#2274E0',"#2274E0"],
  filterTextColor:"#fff",
  filterActiveBorderColor:"#fff",
  lightPrimaryColor:"#eaf5ff",
  linkButtonTextColor:"#2274E0",
  primaryBorderLight:"#b5dcff",
};

export const GI_THEME = {
  id:'giholidays',
  color: GI_COLOR_THEME,
};
