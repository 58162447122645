import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import getMapStyle, { htmlstyles, lastUpdateMsgStyle } from '../../style';
import HTMLView from 'react-native-htmlview';
import { Platform, TouchableOpacity, View } from 'react-native';
import { Image } from 'react-native';
import CabBookingTrackingHelper from '../../../../CabBookingTrackingHelper';
import MapViewWrapper from '../MapViewWrapper';
import {
  zoomInIcon,
  zoomOutIcon,
  currentLocationIcon,
  mapDirection,
} from '../../../../CabImageConstants';
import {
  bearingBetweenLocations,
  calculateDelta,
  getLastUpdatedText,
  isCarOutOfView,
} from '../../data/helper';
import {
  CLICK_EVENT,
  PAGE_NAME,
  WayPointStates,
} from 'apps/post-sales/src/cab/CabBookingDetailsConstant';
import { AnimatedRegion } from 'react-native-maps';
import Header from '../../../../../Common/HeaderCross';
import isEmpty from 'lodash/isEmpty';

const CarTrackerDetail = ({
  carCurrentCoordinates,
  pickupLocation,
  destinationLocation,
  wayPoints,
  currentCarLocation,
  toCarLocation,
  toolTipText,
  toggleCabTrackerOverlay,
  timestamp,
  durationMsg,
  headerData,
  carPreviousLocation,
  showCabTrackerOverlay,
  appHeight,
  driverCardHeight,
  liveTrackingCardData,
  distanceLeft,
}) => {
  const { psTheme: theme } = useTheme();
  const { fsStyle, ...fonts } = getFont(true);
  const mapStyles = getMapStyle(fonts, theme);
  const mapView = useRef(null);
  const lastUpdatedText = getLastUpdatedText(timestamp, liveTrackingCardData);
  const carMarkerRef = useRef(null);
  const tooltipMarkerRef = useRef(null);
  const rotationAngle = useRef(0);
  const [markerCoordinate, setMarkerCoordinate] = useState(currentCarLocation);
  const region = useRef(carCurrentCoordinates.current);

	const coords = useRef(
    new AnimatedRegion({
      latitude: currentCarLocation.latitude,
      longitude: currentCarLocation.longitude,
      latitudeDelta: 0,
      longitudeDelta: 0,
    }),
  );

  const handleRegionChange = (newRegion) => {
    region.current = newRegion;
  };

  useEffect(() => {
    if (isCarOutOfView(currentCarLocation, region)) {
      mapView.current &&
        mapView.current.animateToRegion(
          {
            latitude: currentCarLocation.latitude,
            longitude: currentCarLocation.longitude,
            latitudeDelta: region.current.latitudeDelta,
            longitudeDelta: region.current.longitudeDelta,
          },
          2000,
        );
    }
  }, [region.current, currentCarLocation]);

  useEffect(() => {
    CabBookingTrackingHelper.trackClickEvent(PAGE_NAME.TRIP_DETAILS, CLICK_EVENT.TRACKER_CARD_SHOWN);
  }, []);

  useEffect(() => {
    if (Platform.OS === 'android') {
      if (carMarkerRef.current) {
        carMarkerRef.current.animateMarkerToCoordinate(currentCarLocation, 2000);
      }
      if (tooltipMarkerRef.current) {
        tooltipMarkerRef.current.animateMarkerToCoordinate(currentCarLocation, 2000);
      }
      const timer = setTimeout(() => {
        // updating car current coordinates with 2000 delay is important to avoid flickering of car marker.
        setMarkerCoordinate(currentCarLocation);
      }, 2000);
      return () => clearTimeout(timer);
    } else {
			coords.current && coords.current
			.timing({
				latitude: currentCarLocation.latitude,
				longitude: currentCarLocation.longitude,
				duration: 2000,
				useNativeDriver: false,
			})
			.start();
    }
  }, [currentCarLocation]);

  useEffect(() => {
    if (
      carPreviousLocation.latitude != currentCarLocation.latitude ||
      carPreviousLocation.longitude != currentCarLocation.longitude
    ) {
      rotationAngle.current = bearingBetweenLocations(
        carPreviousLocation.latitude,
        carPreviousLocation.longitude,
        currentCarLocation.latitude,
        currentCarLocation.longitude,
      );
    }
  }, [currentCarLocation, carPreviousLocation]);

  const currentLocationHandler = () => {
    CabBookingTrackingHelper.trackClickEvent(PAGE_NAME.TRIP_DETAILS, CLICK_EVENT.MAP_RECENTER_CLICK);
    const { latitudeDelta, longitudeDelta } = calculateDelta(distanceLeft);
    mapView.current &&
      mapView.current.animateToRegion(
        {
          latitude: currentCarLocation.latitude,
          longitude: currentCarLocation.longitude,
          latitudeDelta,
          longitudeDelta,
        },
        500,
      );
  };

  const zoomToggle = () => {
    if (showCabTrackerOverlay) {
      CabBookingTrackingHelper.trackClickEvent(PAGE_NAME.TRIP_DETAILS, CLICK_EVENT.MAP_COLLAPS_CLICK);
    } else {
      CabBookingTrackingHelper.trackClickEvent(PAGE_NAME.TRIP_DETAILS, CLICK_EVENT.MAP_EXPEND_CLICK);
    }
    toggleCabTrackerOverlay();
  };

  const renderMap = () => {
    const mapRef = (ref) => {
      mapView.current = ref;
    };

    const toolTipMarkerRefFunc = (marker) => {
      tooltipMarkerRef.current = marker;
    };

    const carMarkerRefFunc = (marker) => {
      carMarkerRef.current = marker;
    };

    const wayPointsInMap = [];
    wayPoints.current.forEach((point) => {
      if (point.state === WayPointStates.NEXT || point.state === WayPointStates.UPCOMING) {
        wayPointsInMap.push({ latitude: point.latitude, longitude: point.longitude });
      }
    });

    return (
      <MapViewWrapper
        mapRef={mapRef}
        handleRegionChange={handleRegionChange}
        carMarkerRefFunc={carMarkerRefFunc}
        toolTipMarkerRefFunc={toolTipMarkerRefFunc}
        initialRegion={carCurrentCoordinates.current}
        pickupLocation={pickupLocation}
        destinationLocation={destinationLocation}
        wayPointsList={wayPointsInMap}
        strokeWidth={4}
        strokeColor={theme.color.azure}
        currentCarLocation={markerCoordinate}
        rotationAngle={rotationAngle.current}
        toolTipText={toolTipText}
        showCabTrackerOverlay={showCabTrackerOverlay}
        appHeight={appHeight}
				coords={coords.current}
      />
    );
  };

  const onBackPress = () => {
    toggleCabTrackerOverlay();
  };

  return (
    <View style={mapStyles.MamiCard}>
      {showCabTrackerOverlay && (
        <View style={mapStyles.headerWrap}>
          <Header
            title={headerData.heading}
            subTitle={headerData.subHeading}
            handleClose={onBackPress}
          />
        </View>
      )}
      {!showCabTrackerOverlay && !!lastUpdatedText && (
        <View style={mapStyles.lastUpdateMsg}>
          <HTMLView value={lastUpdatedText} stylesheet={lastUpdateMsgStyle(theme, fonts)} />
        </View>
      )}
      <View
        style={[
          showCabTrackerOverlay ? mapStyles.mapContainerExpanded : mapStyles.mapContainer,
          { height: showCabTrackerOverlay ? appHeight - (driverCardHeight ?? 320) + 50 : 300 },
        ]}
      >
        {renderMap()}
        <React.Fragment>
          <View
            style={[
              mapStyles.zoomOut,
              {
                bottom: showCabTrackerOverlay ? 120 : 15,
                right: showCabTrackerOverlay ? 21 : 10,
              },
            ]}
          >
            <TouchableOpacity
              onPress={zoomToggle}
              style={mapStyles.iconWrapper}
              activeOpacity={0.9}
            >
              <View>
                <Image
                  source={showCabTrackerOverlay ? zoomOutIcon : zoomInIcon}
                  style={mapStyles.icon}
                />
              </View>
            </TouchableOpacity>
          </View>

          <View
            style={[
              mapStyles.recenter,
              {
                bottom: showCabTrackerOverlay ? 160 : 55,
                right: showCabTrackerOverlay ? 21 : 10,
              },
            ]}
          >
            {Platform.OS !== 'web' &&<TouchableOpacity
              onPress={currentLocationHandler}
              style={mapStyles.iconWrapper}
              activeOpacity={0.9}
            >
              <View>
                <Image source={currentLocationIcon} style={mapStyles.icon} />
              </View>
            </TouchableOpacity>}
          </View>
        </React.Fragment>
      </View>
      {!showCabTrackerOverlay && !isEmpty(durationMsg.current) && (
        <View style={[mapStyles.infoMessage]}>
          <Image source={mapDirection} style={mapStyles.mapDirection} />
          <HTMLView value={durationMsg.current} stylesheet={htmlstyles(theme, fonts)} />
        </View>
      )}
    </View>
  );
};

export default CarTrackerDetail;
