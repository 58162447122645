import React, { useState, memo, useMemo } from 'react';
import { Text, View, ScrollView } from 'react-native';
import Actions from '../../../navigation/postSalesNavigation';
import StickyHeader from '../../../hotel/details/components/StickyHeader';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import Breadcrumb from '../Breadcrumb/index';
import styles from '../css/SelectRefundModeCss';
import SpecialClaimUtility from '../Helpers/SpecialClaimUtility';
import useSubmitSpecialClaim from '../hooks/submitSpecialClaimHooks';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import ProgressView from '../../../Common/ProgressView/ProgressView';
import CheckboxRow from '../../../Common/Checkbox';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import OptionsList from '../../../Common/OptionsListWithRadio/OptionsListWithRadio';
import { ALERT_MESSAGE_SPECIAL_CLIAM, ENG_CONSTANT } from '../../../PostSalesConstant';
import { getFont } from '../../../PostSalesStyles';
import BaseButton, { BUTTON_VARIANT } from '../../../Common/BaseButton';
import { getStaticData } from "../../../staticData/staticData";
import { FLIGHT_THROTTLE_OBJECT } from '../../modules/details/FlightBookingDetailsConstant';

const headerIcon = require('@mmt/legacy-assets/src/trip_header_back_icon.webp');

const STEPS = ['SELECT TRAVELLERS', 'REVIEW', 'CONFIRM'];

const SelectRefundPaymentMode = ({
  responseJson: {
    scRefundMode: { refundModeList, declaration, ctaList, header, subHeader },
  },
  responseJson,
  specialClaimRequest,
  specialClaimData,
  bookingState,
  isCorporateBooking,
}) => {
  const [selectedMode, setSelectedMode] = useState(refundModeList[0].refundModeReason);
  const [isDeclared, setIsDeclared] = useState(false);
  const [status, submitRequest] = useSubmitSpecialClaim(
    responseJson,
    bookingState,
    specialClaimData,
  );

  const onModeChangeHandler = (mode) => {
    setSelectedMode(mode);
  };

  const onDeclarationChangeHandler = () => {
    setIsDeclared(!isDeclared);
  };

  const goBack = () => {
    Actions.pop();
  };

  const submitClickHandler = () => {
    let payload = { ...specialClaimRequest };
    payload.refundOption = selectedMode;
    submitRequest(payload, submitClickHandler);
  };

  const title = useMemo(
    () =>
      `${SpecialClaimUtility.getScHeaderTitle(responseJson.messageAttributes)}: ${
        specialClaimData.explTxt
      }`,
    [responseJson.messageAttributes, specialClaimData.explTxt],
  );
  const raiseCalmCta = useMemo(
    () => ctaList.find((cta) => cta.actionId === 'sc_refund_mode_raise'),
    [ctaList],
  );

  const getHeader = () => {
    const { fsStyle, ...fonts } = getFont(true);
    return (
      <StickyHeader
        iconSrc={headerIcon}
        title={title}
        headerColor={['#ffffff', '#ffffff']}
        titleStyle={[styles.headerTitle, fonts.boldFontFamily, fsStyle.font16]}
        onBackPress={goBack}
      />
    );
  };

  const getBreadcrumb = () => {
    return <Breadcrumb activeStep={2} data={STEPS} />;
  };

  const getContent = () => {
    const { fsStyle, ...fonts } = getFont(true);
    const staticData = getStaticData();
    return (
      <>
        <ScrollView showsVerticalScrollIndicator={false} style={styles.pageScroll}>
          <View style={AtomicCss.whiteCard}>
            <Text style={[AtomicCss.heading1Text, AtomicCss.marginBottom10]}>{header}</Text>
            <Text
              style={[
                styles.subHeading,
                AtomicCss.marginBottom20,
                fonts.regularFontFamily,
                fsStyle.font12,
              ]}
            >
              {subHeader}
            </Text>
            <OptionsList
              list={refundModeList}
              isCorporateBooking={isCorporateBooking}
              selectedOptions={selectedMode}
              keyName="refundModeReason"
              onChangeHandler={onModeChangeHandler}
            />
          </View>
          <View style={AtomicCss.whiteCard}>
            <CheckboxRow
              label={declaration}
              errorText={staticData.specialClaimAlertMessageText || "Please accept to submit your claim"}
              errorTextStyle={[styles.errorText, fonts.regularFontFamily, fsStyle.font14]}
              showErrorText
              containerStyle={styles.checkboxRow}
              checked={isDeclared}
              onChange={onDeclarationChangeHandler}
            />
          </View>
        </ScrollView>
        {raiseCalmCta && (
          <View style={styles.footerBtnWrapper}>
            <BaseButton
              variant={BUTTON_VARIANT.PRIMARY}
              text={raiseCalmCta.actionText}
              clickHandler={submitClickHandler}
              disabled={!isDeclared || selectedMode.length === 0}
              {...FLIGHT_THROTTLE_OBJECT}
            />
          </View>
        )}
      </>
    );
  };

  if (status === ViewState.LOADING) {
    return <ProgressView message={ENG_CONSTANT.LOADING} />;
  }

  if (status === ViewState.ERROR) {
    return (
      <View style={{ flex: 1 }}>
        {getHeader()}
        <ErrorView
          message={ENG_CONSTANT.SOME_THING_WENT_WRONG_TRY_AGAIN}
          showRetry={true}
          onRetry={submitClickHandler}
          buttonLabel={ENG_CONSTANT.RETRY}
        />
      </View>
    );
  }

  return (
    <View style={styles.pageWrapper}>
      {getHeader()}
      {getBreadcrumb()}
      {getContent()}
    </View>
  );
};

export default memo(SelectRefundPaymentMode);
