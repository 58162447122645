import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
import { useTheme } from '../../../../../theme/theme.context';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';

interface CardWrapperProps {
  Component: React.ComponentType,
  customStyle: object,
	ignoreWrapperStyles: boolean,
}

const CardWrapper = <P extends object>(Component: React.ComponentType<P>) => {
  return function WrappedComponent(props: P & CardWrapperProps) {
    const { psTheme } = useTheme();
    const { color } = psTheme;
    const whiteCardStyle = getWhiteCardStyles(color);
    return (
      <View
      style={[
        !props.ignoreWrapperStyles && AtomicCss.marginBottom10,
				!props.ignoreWrapperStyles && AtomicCss.paddingLeft16,
				!props.ignoreWrapperStyles && AtomicCss.paddingRight16,
        { ...props.customStyle },
      ]}
    >
      <View style={[whiteCardStyle.whiteCard, !props.ignoreWrapperStyles && whiteCardStyle.platforElevation]}>
        <Component {...props} />
      </View>
    </View>
    );
  };
};

export default CardWrapper;

export const getWhiteCardStyles = (color: any) => {
  return StyleSheet.create({
    whiteCard: {
      borderRadius: 16,
      backgroundColor: color.white,
    },
		platforElevation: {
			...getPlatformElevation(2),
		}
  });
};