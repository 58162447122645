import {colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';

const styles = {
  attachedItem: {
    ...getPlatformElevation(2),
    backgroundColor: colors.white,
    padding: 4,
    borderRadius: 4,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 5
  },
  attachedImgWrapper: {
    overflow: 'hidden',
    borderRadius: 2,
    marginRight: 8
  },
  attachedImg: {
    height: 16,
    width: 16
  },
  attachedImgName: {
    fontSize: 12,
    fontFamily: fonts.regular,
    color: colors.grey,
    marginRight: 4
  },
  greenTick: {
    width: 12,
    height: 9,
    marginTop: 3
  },
  deleteIcon: {
    height: 24,
    width: 24
  },
  paraText: {
    fontSize: 14,
    color: colors.black28,
    fontFamily: fonts.regular,
    marginBottom: 5
  },
  flexRow: {
    flexDirection: 'row'
  },
  spaceBetween: {
    justifyContent: 'space-between'
  }
};

export default styles;
