import {Platform, StyleSheet} from 'react-native';
import {colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles';

const styles = StyleSheet.create({
  flex1: {
    flex: 1
  },
  GreyBg: {
    backgroundColor: '#f6f6f6'
  },
  topStatusCard: {
    paddingTop: 10,
    paddingBottom: 20,
    zIndex: 10,
    position: 'relative',
    ...Platform.select({
      ios: {
        backgroundColor: 'transparent'
      }
    }),
    marginTop: -67
  },
  topStatusBar: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  mar3Bt: {
    marginBottom: 3
  },
  topStatusIcon: {
    width: 70,
    height: 70
  },
  backarrow: {
    width: 16,
    height: 16
  },
  StatusCardWrapper: {
    paddingLeft: 50,
    paddingRight: 50
  },
  transperantBgrnd: {
    ...Platform.select({
      ios: {
        backgroundColor: 'transparent'
      }
    })
  },
  WhiteText: {
    color: colors.white
  },
  AlignCenter: {
    textAlign: 'center'
  },
  font18: {
    fontSize: 18
  },
  boldFont: {
    fontFamily: fonts.bold
  },
  blackFont: {
    fontFamily: fonts.black
  },
  mar15Bt: {
    marginBottom: 15
  },
  bookingidDetails: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  mar10Bt: {
    marginBottom: 10
  },
  lightFont: {
    fontFamily: fonts.light
  },
  errorIconStyle: {
    width: 100,
    height: 100
  },
});

export default styles;


export const htmlstyles = () => {
  return StyleSheet.create({
    p: {
      color: colors.grey,
      fontFamily: fonts.regular
    },
    b: {
      color: colors.black,
      fontFamily: fonts.bold
    },
  });
};
