import React from 'react';
import {View, ScrollView, Text, StyleSheet, Alert, Image} from 'react-native';
import StickyHeader from '../../components/StickyHeaderBack/StickyHeader';
import AtomicCss from '../../commonStyles/AtomicCss';
import RecBlueBtn from '@mmt/legacy-commons/Common/Components/Buttons/RecBlueBtn';
import CheckboxRow from '../../components/CheckboxRow/CheckboxRow';
import Breadcrumb from '../../../Common/Breadcrumb';
import {createTdrCommitRequest, trainFromToSubTitleWithDate} from '../../details/RailBookingDetailsUtil';
import Actions from '../../../navigation/postSalesNavigation';
import OverlayAlert from '../../components/OverlayAlert/OverlayAlert';
import {REFUND} from '../../Utils/RailImageConstant';
import RailBookingTrackingHelper from '../../details/RailBookingTrackEventHelper';
import { getStaticData } from '../../../staticData/staticData';
import { getFont } from '../../../PostSalesStyles';

export const STEP = [
  'SELECT REASON',
  'SELECT TRAVELLERS AND CONFIRM'
];


class TdrSelectTraveller extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAlert: false,
      showOverlay: false
    };
    this.selectedPax = [];
    this.HEADER_TEXT = this.props.tdrRules.tdrLayoutResponse.passengerSelectLayout.headerText;
    this.PASSENGER_SELECT_HEADER_TEXT = this.props.tdrRules.tdrLayoutResponse.passengerSelectLayout.passengerSelectHeaderText;
    this.POSITIVE_BTN_TEXT = this.props.tdrRules.tdrLayoutResponse.passengerSelectLayout.overlayAlert.positiveBtnText;
    this.NEGATIVE_BTN_TEXT = this.props.tdrRules.tdrLayoutResponse.passengerSelectLayout.overlayAlert.negativeBtnText;
    this.TDR_OVERLAY_TITLE = this.props.tdrRules.tdrLayoutResponse.passengerSelectLayout.overlayAlert.tdrOverlayTitle;
    this.SUB_TITLE_TEXT = this.props.tdrRules.tdrLayoutResponse.passengerSelectLayout.overlayAlert.subTitleText;
    this.BOTTOM_BUTTON = this.props.tdrRules.tdrLayoutResponse.passengerSelectLayout.bottomButton;
    this.IMPORTANT_INFO_FOR_USER = this.props.tdrRules.tdrLayoutResponse.passengerSelectLayout.importantInfoForUser;
    this.SHOW_SHORT_TOAST = this.props.tdrRules.tdrLayoutResponse.passengerSelectLayout.showShortToast;
    this.INT_CONN_ISSUE_MSG = this.props.tdrRules.tdrLayoutResponse.passengerSelectLayout.intConnIssueMsg;
    this.STEP = this.props.tdrRules.tdrLayoutResponse.passengerSelectLayout.steps;
    this.PASS_HANDLE_ALERT_TITLE = this.props.tdrRules.tdrLayoutResponse.passengerSelectLayout.passHandleAlertTitle;
    this.PASS_HANDLE_ALERT_MSG = this.props.tdrRules.tdrLayoutResponse.passengerSelectLayout.passHandleAlertMsg;
    this.PASS_HANDLE_ALERT_BTN_TEXT = this.props.tdrRules.tdrLayoutResponse.passengerSelectLayout.passHandleAlertBtnText;
    this.PASS_HANDLE_ALERT_BTN_STYLE = this.props.tdrRules.tdrLayoutResponse.passengerSelectLayout.passHandleAlertBtnStyle;
    this.PASS_HANDLE_ALERT_BTN_SKIP = this.props.tdrRules.tdrLayoutResponse.passengerSelectLayout.passHandleAlertBtnSkip;
  }

  static navigationOptions = {
    header: null
  };
  render() {
    const { fsStyle, ...fonts } = getFont(true);
    const staticData = getStaticData();
    const { tdrText: {tdrSelectTravellerText} } = staticData;
    const subheader = trainFromToSubTitleWithDate(this.props.bookingDetails.train);
    const handleClick = this.handleClick;
    return (

      <View style={styles.CancellationWrapper}>
        <StickyHeader heading={this.HEADER_TEXT} subheading={subheader} />
        <Breadcrumb activeStep={1} data={this.STEP} />
        <ScrollView style={AtomicCss.greyBg}>
          <View style={[styles.PartCancellationHead, AtomicCss.elevation1]}>
            <Text style={[fonts.blackFontFamily, AtomicCss.blackText, fsStyle.font18]}>
              {tdrSelectTravellerText.headingText}
            </Text>
          </View>
          {this.paxViews()}
          {/*{this.tdrMsgViews()}*/}
        </ScrollView>
        <RecBlueBtn label={this.BOTTOM_BUTTON} onPress={handleClick} />
        {this.state.showOverlay &&
        <OverlayAlert
          positiveBtnText={this.POSITIVE_BTN_TEXT}
          negativeBtnText={this.NEGATIVE_BTN_TEXT}
          positiveBtnOnTap={this.goToTdrForm}
          negativeBtnOnTap={this.showAlert}
          titleText={this.TDR_OVERLAY_TITLE}
          subTitleText={this.SUB_TITLE_TEXT}
          bgTapAction={this.bgTapped}
        />}
        {this.state.showAlert && this.handleAlert()}
      </View>
    );
  }

  bgTapped = () => {
    this.setState({
      showAlert: false,
      showOverlay: false
    });
  };

  showAlert = () => {
    this.setState({
      showAlert: true
    });
  };

  handleAlert = () => {
    Alert.alert(
      this.PASS_HANDLE_ALERT_TITLE,
      this.PASS_HANDLE_ALERT_MSG,
      [
        {
          text: this.PASS_HANDLE_ALERT_BTN_TEXT,
          onPress: () => this.goToTdrForm(),
          style: this.PASS_HANDLE_ALERT_BTN_STYLE
        },
        {text: this.PASS_HANDLE_ALERT_BTN_SKIP, onPress: () => this.commitTdrRequest()}
      ],
      {cancelable: true}
    );
  };

  goToTdrForm =() => {
    Actions.tdrForm({
      request: this.createTdrCommitRequest(),
      bookingDetails: this.props.bookingDetails,
      tdrAddress: this.props.tdrAddress,
      tdrRules: this.props.tdrRules
    });
  };


  handleClick = () => {
    if (this.props.selectedReason.certificateRequired) {
      this.setState({
        showOverlay: true,
        mmtAuth: this.props.mmtAuth,
        loggingTrackInfo: this.props.loggingTrackInfo
      });
    } else {
      this.commitTdrRequest();
    }
    RailBookingTrackingHelper.trackClickEvent('SelectTraverller', this.props.bookingDetails, 'FiledTdrFromPsngrPage');
  };

  commitTdrRequest = () => {
    console.log('bookingID:', this.props.bookingId);
    Actions.tdrThankYou({
      request: this.createTdrCommitRequest(),
      selectedReason: this.props.selectedReason,
      bookingDetails: this.props.bookingDetails,
      tdrAddress: this.props.tdrAddress,
      mmtAuth: this.props.mmtAuth,
      loggingTrackInfo: this.props.loggingTrackInfo,
      tdrRules: this.props.tdrRules
    });
  }

  createTdrCommitRequest = () => {
    const selectedPax = this.getPaxList();
    return createTdrCommitRequest(this.props.bookingDetails, this.props.selectedReason, selectedPax);
  };

  getPaxList = () => {
    const paxList = [];
    this.selectedPax.forEach((pax) => {
      paxList.push(pax.ticketLineNo);
    });

    return paxList;
  };

  paxViews = () => {
    const views = [];
    this.props.bookingDetails.train[0].pnrDetails[0].segmentInfo[0].passenger.forEach((passenger) => {
      if (passenger.currentStatus !== 'CAN' && passenger.showFileTdr) {
        views.push(<CheckboxRow passenger={passenger} key={`${passenger.paxId}`} onPaxSelection={this.onPaxSelection} vernacEnabled={false} />);
      }
    });
    return views;
  }

  tdrMsgViews = () => {
    const { fsStyle } = getFont(true);
    const views = [];
    views.push(<View style={styles.tdrMsg}>
      <View style={styles.rupeeIconPos}>
        <Image
          style={[styles.rupeeIconSizePos]}
          source={REFUND}
        />
      </View>
      <View style={styles.textMsgViewPos}>
        <Text style={[styles.textMsgPos, fsStyle.font14]}>{this.IMPORTANT_INFO_FOR_USER}</Text>
      </View>
    </View>);
    return views;
  }

  onPaxSelection = (passenger, isSelected) => {
    const index = this.selectedPax.findIndex(x => x.paxId == passenger.paxId);
    if (isSelected) {
      if (index === -1) {
        this.selectedPax.push(passenger);
      }
    } else {
      this.selectedPax.splice(index, 1);
    }
  }
}

const styles = StyleSheet.create({
  CancellationWrapper: {
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: '#f2f2f2'
  },
  tdrMsg: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#b3ffff',
    height: 90,
    margin: 7
  },
  PartCancellationHead: {
    backgroundColor: '#ffffff',
    paddingVertical: 18,
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#ddd'
  },
  rupeeIconPos: {
    marginTop: 25,
    marginLeft: 10
  },
  textMsgViewPos: {
    marginTop: 25,
    marginLeft: 10,
    marginRight: 10
  },
  textMsgPos: {

  },
  rupeeIconSizePos: {
    width: 40,
    height: 40
  }
});

export default TdrSelectTraveller;
