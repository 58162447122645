import { StyleSheet } from 'react-native';
import { DEFAULT_THEME } from 'apps/post-sales/src/theme/default.theme';

export default <T extends typeof DEFAULT_THEME>(theme: T) => StyleSheet.create({
    cardContainer: {
      marginHorizontal: 12,
      marginBottom: 12,
    },

    selectedDateContainer: {
      marginTop: 8,
      display: 'flex',
      justifyContent: 'space-between',
    },

    roomDetailsCardAction: {
      marginLeft: 11,
      marginTop: 4,
    },
    w70:{
      width: '70%',
    },
    w30:{
      width: '30%',
    },
    bulletCircle: {
      width: 6,
      height: 6,
      backgroundColor: theme.color.darkGrey5,
      borderRadius: 20,
      top: 12,
      left: 5,
      marginRight: 10,
    },

   flexStyle: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    gap: 8,
   },

   infoStripContainer: {
    padding: 16,
    lineHeight: 12,
   },

   priceDateContainer: {
    borderWidth: 1,
    borderColor: theme.color.lightGrey3,
    borderRadius: 12,
    padding: 12,
   },

   infoChip: {
      display: 'flex',
      flexDirection: 'row',
      gap: 4,
      marginBottom: 12,
      borderRadius: 12,
      padding: 12,
      paddingRight: 20,
   },
   imageCount: {
      position: 'absolute',
      bottom: 8,
      left: 8,
      backgroundColor: theme.color.black,
      borderRadius: 4,
      padding: 4,
   }
});
