import React, { useRef, useEffect } from 'react';
import { View, Text, ScrollView, ActivityIndicator, Animated, Easing, Image } from 'react-native';
import useApi from '../../../../../Common/useApi';

import createStyles from './NameCrectRqstSbmtedCss';
import DetailSectionCard from './component/DetailSectionCard';
import DetailsLoadingCard from './component/DetailsLoadingCard';
import SuccessLoaderAnim from '../../../../../Common/SuccessLoaderAnimation';
import { FAILED_CROSS_ICON } from '../../../../FlightImageConstants';
import { resetToFlightBookingDetail } from '../../../../FlightBookingUtil';
import FlightBookingTrackingHelper from '../../../../modules/details/helper/FlightBookingTrackingHelper';
import BaseButton, { BUTTON_VARIANT } from '../../../../../Common/BaseButton';
import { getStaticData } from '../../../../../staticData/staticData';
import { getFont } from '../../../../../PostSalesStyles';
import { useTheme } from '../../../../../theme/theme.context';

import { NAME_CORRECTION_SUBMIT } from '../../../../../utils/NetworkUtils';
import useHardwareBackpress from 'apps/post-sales/src/Common/useHardwareBackpress';
import { FLIGHT_THROTTLE_OBJECT } from '../../../details/FlightBookingDetailsConstant';

const NameChangeThankYou = ({
  uniqueId,
  bookingId,
  passengerList,
  reasonSelected,
  finalPassengerList,
  attachmentList,
  responseJson,
}) => {
  const staticData = getStaticData();
  const { nameCorrectionTexts, goToYourBookingText } = staticData || {};

  const { psTheme: theme } = useTheme();
  const { fsStyle, ...fonts } = getFont(true);
  const styles = createStyles(theme);

  const [ncfInprogress, ncfResponse, ncfAPI] = useApi(NAME_CORRECTION_SUBMIT);
  useHardwareBackpress().addListener(() => resetToFlightBookingDetail(bookingId), true)

  const opacity = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.timing(opacity, {
      toValue: 1,
      duration: 1000,
      delay: 200,
      easing: Easing.Linear,
    }).start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    ncfAPI.httpPost({
      bookingId,
      uniqueId: 'FLIGHT_NAME_CORRECTION_SUBMIT',
      body: {
        bookingId,
        submitNameChangeRequest: true,
        passengerList: finalPassengerList,
        attachmentList,
        reasonSelectedForNameCorrection: reasonSelected.ruleId,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (ncfResponse && ncfResponse.success) {
      FlightBookingTrackingHelper.trackLoadEvent(
        'Special request : Name Change Thank You success',
        responseJson,
        'nameChange',
      );
    } else if (ncfResponse && !ncfResponse.success) {
      FlightBookingTrackingHelper.trackLoadEvent(
        'Special request : Name Change Thank You Failure',
        responseJson,
        'nameChange',
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ncfResponse]);

  return (
    <>
      <ScrollView>
        <View style={styles.fullPage}>
          <View
            style={[
              styles.topSection,
              !ncfInprogress && ncfResponse && !ncfResponse.success && styles.failureBackground,
            ]}
          >
            {ncfInprogress && (
              <View style={styles.loaderContainer}>
                <ActivityIndicator
                  size="large"
                  color={theme.color.green2}
                  style={styles.activityIndicator}
                />
              </View>
            )}
            {!ncfInprogress && ncfResponse && ncfResponse.success && <SuccessLoaderAnim />}
            {!ncfInprogress && ncfResponse && !ncfResponse.success && (
              <Image style={styles.mainconStyle} source={FAILED_CROSS_ICON} />
            )}
            {(ncfInprogress || ncfResponse) && (
              <Text style={[styles.titleHeading, fsStyle.font20, fonts.blackFontFamily]}>
                {ncfInprogress ? nameCorrectionTexts.submitLoadingText : ncfResponse.heading}
              </Text>
            )}
          </View>

          {ncfInprogress && <DetailsLoadingCard />}
          {!ncfInprogress && ncfResponse && (
            <DetailSectionCard
              delayText={opacity}
              passengerList={passengerList}
              reasonSelected={reasonSelected}
              data={ncfResponse}
              bookingId={bookingId}
            />
          )}
        </View>
      </ScrollView>
      {!ncfInprogress && (
        <View style={[styles.buttonBottomWrapper]}>
          <BaseButton
            {...FLIGHT_THROTTLE_OBJECT}
            clickHandler={() => resetToFlightBookingDetail(bookingId)}
            minWidth="98%"
            variant={BUTTON_VARIANT.PRIMARY}
            textStyle={fsStyle.font16}
            text={goToYourBookingText}
          />
        </View>
      )}
    </>
  );
};
export default NameChangeThankYou;
