/* eslint-disable max-len */
import React from 'react';
import { Image, Platform, Text, View } from 'react-native';
import styles from '../../acme/details/components/InclusionExclusionCss';
import PropTypes from 'prop-types';
import AtomicCss from '../../acme/details/AtomicCss';
import { getButtonStyle, handleClickEvent, isNotNullAndEmpty } from '../utils/CabBookingDetailUtil';
import CabBookingDetailsConstant from '../CabBookingDetailsConstant';
import { mainCardIcon, inclusionIcon, exclusionIcon } from "../CabImageConstants";
import isEmpty from 'lodash/isEmpty';
import CabsSharedModuleHolder from '@mmt/cabs-shared/src';
import CardWrapper from '../modules/bookingDetails/components/CardWrapper';
import { getActionButton } from 'apps/post-sales/src/Common/commonUtil';
import HeadingComponent from '../modules/bookingDetails/components/HeadingComponent';

class InclusionExclusionCard extends React.Component {
  static navigationOptions = {
    header: null
  };

  render() {
    const cabsSharedModule = CabsSharedModuleHolder.get();
    if(Platform.OS !== 'web') {
      if (cabsSharedModule === null) {
        throw new Error('Cabs module not bootstrapped');
      }
    }
    const {heading} = this.props.card;
    this.getActionListToDisplay();
    return (
      <React.Fragment>
         <HeadingComponent heading={heading} showIcon={true} containerStyle={styles.cardHeading}/>
        <View style={styles.cardHeading}>
          {this.props.card.subheading &&
            !isEmpty(this.props.card.subheading) && (
              <Text
                style={[
                  AtomicCss.regularFont,
                  AtomicCss.blackText,
                  AtomicCss.font14,
                  AtomicCss.marginBottom24,
                ]}
              >
                {this.props.card.subheading}
              </Text>
            )}
          {this.getInclusions()}
          {this.getExclusions()}
        </View>
        <View
            style={
              isNotNullAndEmpty(this.props.card.actionList)
                ? [
                    getButtonStyle(this.props.card.actionList),
                    styles.borderTop,
                    styles.buttonRadiusStyle,
                  ]
                : styles.hideView
            }
          >
            {getActionButton(
              this.props.card,
              this.props.response,
              this.handleClick,
              {},
              true,
              false,
              styles.buttonRadiusStyle,
            )}
          </View>
      </React.Fragment>
    );
  }

  getActionListToDisplay() {
    const actionListToShow = [];
    this.props.card.actionList.forEach((action) => {
      if ((action.actionFamily === 'TermsAndConditions' && isNotNullAndEmpty(this.props.response.productinclusion.tnC))
        || (action.actionFamily === 'CabInvoice')) {
        actionListToShow.push(action);
      }
    });
    this.props.card.actionList = actionListToShow;
  }

  getInclusions() {
    const views = [];
    const inclusionList =
      this.props.response.productinclusion.inclusion;
    if (isNotNullAndEmpty(inclusionList)) {
      inclusionList.forEach((inclusion) => {
        inclusion.inclusion = inclusion.inclusion.replace(CabBookingDetailsConstant.INCLUSIONEXCLUSIONDELIMITER, '  ');
        views.push(<View
          key={inclusion.lineNo}
          style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom18]}
        >
          <Image style={styles.activityTick} source={inclusionIcon} />
          <Text style={styles.tickText}>{inclusion.inclusion}</Text>
        </View>);
      });
    }
    return views;
  }

  getExclusions() {
    const views = [];
    const exclusionList =
      this.props.response.productinclusion.exclusion;
    if (isNotNullAndEmpty(exclusionList)) {
      exclusionList.forEach((exclusion) => {
        exclusion.exclusion = exclusion.exclusion.replace(CabBookingDetailsConstant.INCLUSIONEXCLUSIONDELIMITER, '  ');
        views.push(<View
          key={exclusion.lineNo}
          style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom18]}
        >
          <Image
            style={styles.activityCross}
            source={exclusionIcon}
          />
          <Text style={styles.CrossText}>{exclusion.exclusion}</Text>
        </View>);
      });
    }
    return views;
  }

  handleClick = (action) => {
    handleClickEvent(this.props.card, action, this.props.response, this.props.pageName);
  };
}

InclusionExclusionCard.propTypes = {
  response: PropTypes.object.isRequired,
  card: PropTypes.object.isRequired,
  pageName: PropTypes.string.isRequired
};

export default CardWrapper(InclusionExclusionCard);
