import React from 'react';
import { View, Text, StyleSheet, Dimensions } from 'react-native';

import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import TravellerProofCard from './components/TravellerProofCard';
import { getFont } from '../../../../../PostSalesStyles';
const windW = Dimensions.get('window').width;

const UploadDocuments = ({
  bookingId,
  flightNameCorrectionUpload,
  data,
  setAttachmentList,
  onDeleteAttachment,
}) => {
  const { fsStyle, ...fonts } = getFont(true);

  const { heading, description, mandatoryText } = flightNameCorrectionUpload || {};

  // eslint-disable-next-line no-shadow
  const updateAttachments = (id, data) => {
    setAttachmentList({ [id]: data });
  };

  return (
    <View style={styles.mainWrapper}>
      <View style={styles.cardWrapper}>
        <View style={styles.headingText}>
          <Text
            style={[
              fsStyle.font20,
              fonts.blackFontFamily,
              AtomicCss.blackText,
              AtomicCss.marginBottom8,
              styles.lineHeight28,
              AtomicCss.flex1,
            ]}
          >
            {heading}
          </Text>
          <Text
            style={[
              fsStyle.font14,
              fonts.regularFontFamily,
              AtomicCss.defaultText,
              AtomicCss.lineHeight20,
              AtomicCss.flex1,
            ]}
          >
            {description}
          </Text>
        </View>

        {data &&
          Object.keys(data).map((key, index) => (
            <View style={styles.sectorWrapper}>
              <TravellerProofCard
                info={data[key]}
                id={key}
                key={key}
                count={index + 1}
                bookingId={bookingId}
                updateAttachments={updateAttachments}
                mandatoryText={mandatoryText}
                onDeleteAttachment={onDeleteAttachment}
              />
            </View>
          ))}
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  cardWrapper: {
    width: windW - 55,
    paddingHorizontal: 5,
    marginBottom: 8,
  },
  headingText: {
    marginBottom: 24,
    flex: 1,
  },

  lineHeight24: {
    lineHeight: 24,
  },
  sectorWrapper: {
    paddingVertical: 12,
  },
});
export default UploadDocuments;
