import React, { useEffect, useState } from 'react';
import { View, Text, Image } from 'react-native';
import styles from './ReqstConfirmOverlayCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import agentIcon from '@mmt/legacy-assets/src/agentIcon.webp';
import Button from '../../../Button';
import { SCHEDULE_CALLBACK_URL } from '../../../../utils/NetworkUtils';
import { renderProgressView, renderErrorView } from '../../NeedMoreHelpUtils';
import { ViewState } from '../../../../PostSalesConstant';
import { usePostRequestApi } from '../../../PostRequestApi';
import { getImagePath } from '../../../../utils/PostSaleUtil';

const succesTick = getImagePath('circular_green.webp');

const ReqstCallBackBottomSheet = (props) => {
  const trackPage = 'helpinghand_submit_callbacklater';
  const [retry, setRetry] = useState(0);

  const request = {
    bookingId: props.bookingId,
    tenant: 0,
    primarytype: props.callDrivers && props.callDrivers.primarytype,
    secondarytype: props.callDrivers && props.callDrivers.secondarytype,
    ternarytype: props.callDrivers && props.callDrivers.ternarytype,
    timeZone: 'IST',
    configId: props.configId,
    timeSlot: props.timeSlot,
    pageName: !props.callDrivers ? props.pageName : '',
    lobName: props.lobName,
  };

  const [viewState, response] = usePostRequestApi(
    SCHEDULE_CALLBACK_URL,
    request,
    props.bookingId,
    'SCHEDULE_CALLBACK',
    retry,
  );

  useEffect(() => {
    if (response) {
      props.trackEvent && props.trackEvent('load', trackPage);
    }
  }, [response]);

  const handleRetry = () => {
    setRetry(retry + 1);
  };

  if (viewState === ViewState.LOADING) {
    return renderProgressView();
  }

  if (viewState === ViewState.ERROR) {
    return renderErrorView(handleRetry);
  }

  if (viewState === ViewState.LOADED && response && response.layoutData) {
    props.callScheduled();
    return (
      <View style={styles.OverlayContentWrapper}>
        <View style={AtomicCss.marginBottom16}>
          <View style={styles.overlayTopline} />
        </View>
        <View style={styles.agentImgWrapper}>
          <Image style={styles.agentIconStyle} source={agentIcon} />
        </View>
        <View style={styles.titleWrapper}>
          <Text style={styles.title}>
            {response.layoutData.headerText}
            <Image style={styles.successTickStyle} source={succesTick} />
          </Text>
        </View>
        <Text
          style={[
            AtomicCss.defaultText,
            AtomicCss.font14,
            AtomicCss.regularFont,
            AtomicCss.marginBottom26,
          ]}
        >
          {response.layoutData.subHeaderText1}
        </Text>
        <View style={AtomicCss.marginBottom20}>
          <Button
            btnType="flat"
            btnBg="gradientBtn"
            btnTxt={response.layoutData.ctaText}
            fontSize="font14"
            clickHandler={(action) => {
              props.trackEvent && props.trackEvent('click', trackPage, response.layoutData.ctaText);
              props.handleTime(action);
            }}
          />
        </View>
      </View>
    );
  }

  return null;
};

export default ReqstCallBackBottomSheet;
