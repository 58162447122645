const initialState = {
  paxDetails: [],
  isAnySelected: false,
  proofSegments: [],
  isAnyNonProofSegment: false,
  documentList: {},
  refundMode: null,
  isConfirmed: false,
  fileUrls: [],
  areOnlyChildPaxSelected: false,
  ancillaryCancel: {},
};

export default initialState;
