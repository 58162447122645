import React from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import getStyle from '../../Css/UploadProofToSupportCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getFont } from '../../../../../PostSalesStyles';
import Button from '../../../../../Common/Button';
import { ANDROID_BACK_ARROW, REJECT_ICON } from '../../../../FlightImageConstants';
import { isEmptyArray, isNullOrEmpty } from '../../../../../Common/commonUtil';
import { useTheme } from '../../../../../theme/theme.context';

const SubmitPartialRequest = ({
  handleBack,
  docNotUploadedPageTwo,
  requiredSegmentData,
  notRequiredSegmentData,
  dontHaveComm,
  partialRequest,
  onUploadProof,
}) => {
  const { fsStyle, ...fonts } = getFont(true);
  const {psTheme} = useTheme();
  const styles = getStyle(psTheme);
  const {color} = psTheme;
  const { title, subtitle, footerText } = docNotUploadedPageTwo;

  const SegmentIncludedSubtitle =
    !isEmptyArray(requiredSegmentData) &&
    !isEmptyArray(subtitle) &&
    subtitle.map((sub) =>
      sub.includes('$$%%') ? (
        <Text>
          <Text>{sub.split('$$%%')[0]}</Text>
          <Text key={sub} style={[fonts.boldFontFamily]}>
            {requiredSegmentData.toString()}
          </Text>
          <Text>{sub.split('$$%%')[1]}</Text>
        </Text>
      ) : (
        sub
      ),
    );
  const segmentIncludedFooterText =
    !isEmptyArray(notRequiredSegmentData) &&
    !isNullOrEmpty(footerText) &&
    footerText.includes('$$%%') ? (
      <Text>
        <Text>{footerText.split('$$%%')[0]}</Text>
        <Text key={footerText} style={[fonts.boldFontFamily]}>
          {notRequiredSegmentData.toString()}
        </Text>
        <Text>{footerText.split('$$%%')[1]}</Text>
      </Text>
    ) : (
      footerText
    );

  return (
    <React.Fragment>
      <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom20]}>
        <TouchableOpacity style={styles.backArrowWrapper} onPress={handleBack}>
          <Image source={ANDROID_BACK_ARROW} style={styles.backArrowStyle} />
        </TouchableOpacity>
        <Text
          style={[
            fonts.blackFontFamily,
            fsStyle.font12,
            color.greyLight,
            AtomicCss.lineHeight20,
          ]}
        >
          {dontHaveComm}
        </Text>
      </View>
      {!!title && (
        <Text
          style={[styles.title, fonts.blackFontFamily, AtomicCss.marginBottom8, fsStyle.font22]}
        >
          {title}
        </Text>
      )}
      <View style={styles.strokeStyle}></View>
      <View style={[AtomicCss.flexRow, styles.marginBottom30]}>
        <Image source={REJECT_ICON} style={[styles.rejectIconStyle, AtomicCss.marginRight10]} />
        {!isEmptyArray(SegmentIncludedSubtitle) &&
          SegmentIncludedSubtitle.map((Sub, index) => (
            <Text
              key={index}
              style={[
                fonts.regularFontFamily,
                fsStyle.font14,
                color.red,
                AtomicCss.flex1,
                AtomicCss.lineHeight20,
              ]}
            >
              {Sub}
            </Text>
          ))}
      </View>
      {!isNullOrEmpty(segmentIncludedFooterText) && (
        <Text
          key={segmentIncludedFooterText}
          style={[
            fonts.regularFontFamily,
            fsStyle.font14,
            color.defaultTextColor,
            styles.lineHeight22,
            styles.marginBottom60,
          ]}
        >
          {segmentIncludedFooterText}
        </Text>
      )}
      <Button
        btnTxt={partialRequest}
        disable={isEmptyArray(notRequiredSegmentData)}
        clickHandler={onUploadProof.bind(null, true)}
        btnBg="gradientBtn"
        btnType="flat"
        fontSize={fsStyle.font16}
        buttonTextCss={fsStyle.font16}
      />
    </React.Fragment>
  );
};

export default SubmitPartialRequest;
