import React, {Component} from 'react';
import {View} from 'react-native';
import Actions from '../../../navigation/postSalesNavigation';
import PropTypes from 'prop-types';
import CheckingBusOverlayContent from './CheckingBusOverlayContent';
import styles from './CheckingBusOverlayCss';

export default class CheckingBusOverlay extends Component {
  render() {
    return (
      <View style={styles.overlayWrapper}>
        <View style={styles.overlay} />
        <CheckingBusOverlayContent />
      </View>
    );
  }

  getLineNumbers() {
    const linenos = [];
    this.props.response && this.props.response.busBookingInfo && this.props.response.busBookingInfo.passengerDetails &&  this.props.response.busBookingInfo.passengerDetails.forEach((paxDetails) => {
      linenos.push(paxDetails.lineno && paxDetails.status && paxDetails.status === 'Confirmed' ? paxDetails.lineno.toString() : "");
    });
    return linenos;
  }

  checkIfRefundPresent = () => {
    Actions.pop();
    setTimeout(() => {
      Actions.busCancelledByOperatorReview({
        response: this.props.response,
        specialClaimData: this.props.specialClaimData,
        selectedPassenger: this.props.selectedPassenger,
        bookingState: this.props.bookingState,
        bookingId: this.props.bookingId,
        lobCode: this.props.lobCode,
        linenos: this.getLineNumbers()
      });
    }, 0);
};
  componentDidMount() {
    this.checkIfRefundPresent();
  }
}

CheckingBusOverlay.propTypes = {
  bookingID: PropTypes.string.isRequired,
  request: PropTypes.object.isRequired
};
