import { ViewStyle, TextStyle, ImageStyle, StyleSheet } from 'react-native';
import { DEFAULT_THEME } from 'apps/post-sales/src/theme/default.theme';

interface Styles {
  container: ViewStyle;
  bottomSheet: ViewStyle;
  crossImage: ImageStyle;
  heading: TextStyle;
  subHeading: TextStyle;
  flightContainer: ViewStyle;
  flightImage: ImageStyle;
  flightContent: ViewStyle;
  flightCodeWrapper: ViewStyle;
  flightCodeText: TextStyle;
  economy: TextStyle;
  flightInfo: TextStyle;
  font900: TextStyle;
  font400: TextStyle;
  checkboxWrapper: ViewStyle;
  button: ViewStyle;
  confirmText: TextStyle;
}

const createStyles = <Theme extends typeof DEFAULT_THEME>(theme: Theme) =>
  StyleSheet.create<Styles>({
    container: {
      flex: 1,
      justifyContent: 'flex-end',
    },
    bottomSheet: {
      backgroundColor: theme.color.white,
      padding: 20,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
    crossImage: {
      height: 14,
      width: 14,
      position: 'absolute',
      right: 0,
    },
    heading: {
      lineHeight: 28,
      color: theme.color.black,
    },
    subHeading: {
      color: theme.color.textGrey,
      lineHeight: 16,
      marginTop: 4,
    },
    flightContainer: {
      padding: 16,
      paddingBottom: 13,
      borderColor: theme.color.lightGrey,
      borderWidth: 1,
      backgroundColor: theme.color.grey11,
      marginTop: 20,
      marginBottom: 12,
      flexDirection: 'row',
      borderRadius: 8,
    },
    flightImage: {
      height: 24,
      width: 24,
    },
    flightContent: {
      marginLeft: 8,
    },
    flightCodeWrapper: {
      flexDirection: 'row',
    },
    flightCodeText: {
      lineHeight: 24,
      color: theme.color.black,
    },
    economy: {
      color: theme.color.textGrey,
      lineHeight: 24,
      marginLeft: 8,
    },
    flightInfo: {
      color: theme.color.textGrey,
      lineHeight: 18,
    },
    checkboxWrapper: {
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: 24,
      marginBottom: 32,
    },
    button: {
      padding: 16,
      marginLeft: -10,
      marginRight: -10,
      alignItems: 'center',
      borderRadius: 4,
    },
  });

export default createStyles;
