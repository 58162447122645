import React from 'react';
import { View, StyleSheet, Dimensions, Text } from 'react-native';
const windW = Dimensions.get('window').width;
import getPlatFormElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import {getFont} from "../../../PostSalesStyles";

const LostIdRefundCard = ({
  refundTrackerStatus = 'Initiated',
  refundStatusText,
  trackerData,
  refundAmount,
  refundModeBreakupList,
  heading,
  headingv2,
  refundText,
}) => {
  return (
    <View style={styles.PaymentStatusCard}>
      <TopInfoSection
        refundType={refundTrackerStatus}
        tagTitle={refundStatusText}
        refundAmount={refundAmount}
        refundModeBreakupList={refundModeBreakupList}
        heading={heading}
        headingv2={headingv2}
      />
      <Divider />
      <TrackerSection trackerData={trackerData} heading={refundText} />
    </View>
  );
};
const TopInfoSection = ({ refundType, tagTitle, refundAmount, heading, headingv2 }) => {
  const tagStyleSuffix = refundType;
  const fonts = getFont(true);
  return (
    <View style={styles.refundStatusTopDetails}>
      <View
        style={[
          styles.refundInfoStatus,
          AtomicCss.marginBottom16,
          styles[`refundInfoStatus${tagStyleSuffix}`],
        ]}
      >
        <Text style={[styles[`${tagStyleSuffix}Text`], fonts.boldFontFamily, AtomicCss.font11]}>
          {tagTitle}
        </Text>
      </View>
      <View style={styles.rowSection}>
        <View style={[AtomicCss.flex1, AtomicCss.marginRight15]}>
          <Text
            style={[
              AtomicCss.font18,
              fonts.blackFontFamily,
              AtomicCss.blackText,
              AtomicCss.marginBottom3,
            ]}
          >
            {heading}
          </Text>
          {!!headingv2 && (
            <Text style={[AtomicCss.font14, fonts.regularFontFamily, AtomicCss.midGreyText]}>
              {headingv2}
            </Text>
          )}
        </View>
        <Text style={[styles.fontSize24, fonts.blackFontFamily, AtomicCss.blackText,AtomicCss.flex1,AtomicCss.alignRight]}>
          {refundAmount}
        </Text>
      </View>
    </View>
  );
};
const Divider = () => {
  return (
    <View style={styles.dividerSection}>
      <View style={[styles.semiCircleStyle, styles.leftSemiCircle]} />
      <View style={[styles.semiCircleStyle, styles.rightSemiCircle]} />
    </View>
  );
};
const TrackerSection = ({ trackerData, heading }) => {

  const fonts = getFont(true);
  return (
    <View style={styles.trackerCard}>
      {!!heading && (
        <Text
          style={[
            AtomicCss.defaultText,
            AtomicCss.font14,
            fonts.regularFontFamily,
            AtomicCss.lineHeight20,
          ]}
        >
          {heading}
        </Text>
      )}
      <View style={styles.trackerWrapper}>{trackerData}</View>
    </View>
  );
};
const styles = StyleSheet.create({
  pageWrapper: {
    width: windW,
    backgroundColor: colors.grayBg,
    flex: 1,
  },
  bodyDetails: {
    padding: 12,
  },
  PaymentStatusCard: {
    backgroundColor: colors.white,
    borderRadius: 8,
    ...getPlatFormElevation(2),
    width: '100%',
  },
  refundStatusTopDetails: {
    padding: 20,
  },
  refundInfoStatus: {
    borderRadius: 4,
    paddingVertical: 4,
    paddingHorizontal: 6,
    flexDirection: 'row',
    alignSelf: 'flex-start',
  },
  refundInfoStatusInitiated: {
    backgroundColor: colors.creamWhite,
  },
  InitiatedText: {
    color: colors.darkBrown,
    textTransform: 'uppercase',
  },
  refundInfoStatusAwaited: {
    backgroundColor: colors.grayBg,
  },
  AwaitedText: {
    color: colors.textGrey,
    textTransform: 'uppercase',
  },
  refundInfoStatusRefunded: {
    backgroundColor: colors.limeGreen,
  },
  RefundedText: {
    color: colors.green,
    textTransform: 'uppercase',
  },
  rowSection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  fontSize24: {
    fontSize: 24,
  },
  dividerSection: {
    borderTopWidth: 2,
    borderTopColor: colors.greyBookedSeat,
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  semiCircleStyle: {
    width: 16,
    height: 16,
    backgroundColor: colors.grayBg,
    borderRadius: 16,
  },
  leftSemiCircle: {
    left: -8,
    top: -8,
  },
  rightSemiCircle: {
    right: -8,
    top: -8,
  },
  trackerCard: {
    paddingHorizontal: 20,
    paddingTop: 0,
    paddingBottom: 20,
  },
  trackerWrapper: {
    paddingTop: 15,
  },
});

export default LostIdRefundCard;
