import React, { useEffect, useRef, useState } from 'react';
import { ScrollView, Modal, Text, View } from 'react-native';
import Actions from '../../../navigation/postSalesNavigation';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import Header from '../../../Common/HeaderCross';
import CabBreadcrumb from './CabBreadCumb';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import NoInternetView from '@mmt/legacy-commons/Common/Components/Error/NoInternetView';
import ProgressView from '@mmt/legacy-commons/Common/Components/Progress/ProgressView';
import { isNetworkAvailable } from '@mmt/legacy-commons/Common/utils/AppUtils';
import * as NetworkUtils from '../../../utils/NetworkUtils';
import CabListing from './CabListing';
import { CancelAndRebookConstants } from './CancelAndRebookConstants';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import { getCommonHeaders } from '../../../utils/NetworkUtils';
import isEmpty from 'lodash/isEmpty';
import CapsuleBtnFill from '../../../Common/CapsuleBlueFullButton';
import { getFont } from '../../../PostSalesStyles';
import { getStyles } from './CabUpgradeStyle';
import NewCommonOverlay from '../../../Common/NewCommonOverlay';
import { getPaymentOptionData } from './CancelAndRebookUtill';
import FareBreakupOverlay from './FareBreakupOverlay';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import CabBookingDetailsConstant, { CLICK_EVENT, OVERLAY_TYPE, PAGE_NAME } from '../../CabBookingDetailsConstant';
import CabBookingTrackingHelper from '../../CabBookingTrackingHelper';

const CabUpgrade = (props) => {
  const [viewState, setViewState] = useState(ViewState.LOADING);
  const [responseJson, setResponseJson] = useState(null);
  const [previewRequest, setPreviewRequest] = useState(null);
  const [showModal, setShowModal] = useState(true);
  const [cancelAndRebookPreviewResponse, setCancelAndRebookPreviewResponse] = useState({});
  const [showOverlay, setShowOverlay] = useState(null);
  const scrollViewRef = useRef(null);
  const [currentCabDetail, setCurrentCabDetail] = useState(null);

  const { fsStyle, ...fonts } = getFont();
  const { psTheme: theme } = useTheme();
  const styles = getStyles(fonts, theme);

  useEffect(() => {
    fetchAvailableCar();
  }, []);

  const createRequest = () => {
    const request = {
      bookingId: props.bookingDetailResponse.bookingId,
      dateChange: {
        newPickupDate: props.modifiedData.fromDate && props.modifiedData.fromDate.date,
        newDropDate: props.modifiedData.toDate && props.modifiedData.toDate.date,
      },
      locationChange: {
        newPickupLocation: props.modifiedData.from && props.modifiedData.from.name,
        newPickupLocationId: props.modifiedData.from && props.modifiedData.from.locationId,
        newDropLocation: props.modifiedData.to && props.modifiedData.to.name,
        newDropLocationId: props.modifiedData.to && props.modifiedData.to.locationId,
      },
    };
    setPreviewRequest(request);
    return request;
  };

  const fetchAvailableCar = async () => {
    try {
      const hasNetwork = await isNetworkAvailable();
      if (!hasNetwork) {
        setViewState(ViewState.NO_INTERNET);
        return;
      }
      const url = NetworkUtils.CANCEL_AND_REBOOK_PREVIEW_URL;
      const { mmtAuth, loggingTrackingInfo } = await HotelBookingModule.getRequestHeader(
        props.bookingId,
        'Cab_canelAndRebook',
      );
      const request = createRequest();
      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(request),
        headers: await getCommonHeaders(mmtAuth, loggingTrackingInfo),
      });
      if (response && response.ok) {
        const body = await response.json();
        setCancelAndRebookPreviewResponse(body);
        if (body.success) {
          setResponseJson(body);
          setViewState(ViewState.SHOW_DETAIL);
        } else {
          if (!isEmpty(body.responseText)) {
            setViewState(ViewState.NO_CABS);
          } else {
            setViewState(ViewState.ERROR);
          }
        }
      } else {
        setViewState(ViewState.ERROR);
      }
    } catch (error) {
      setViewState(ViewState.ERROR);
    }
  };

  const renderProgressView = () => {
    const { loadingText } = CabBookingDetailsConstant
    return <ProgressView message={loadingText} />;
  };
  const renderNoNetworkView = () => (
    <NoInternetView
      onRetry={() => {
        setViewState(ViewState.LOADING);
        fetchAvailableCar();
      }}
    />
  );
  const renderNoCabsView = () => (
    <ErrorView
      message={cancelAndRebookPreviewResponse.responseText}
      showRetry
      onRetry={() => {
        setViewState(ViewState.LOADING);
        onBackPress();
      }}
    />
  );
  const renderErrorView = () => (
    <ErrorView
      showRetry
      onRetry={() => {
        setViewState(ViewState.LOADING);
        fetchAvailableCar();
      }}
    />
  );

  const onBackPress = () => {
    Actions.pop();
  };

  const renderCabList = (carDetail) => {
    if (!isEmpty(carDetail)) {
      return (
        <View style={styles.cabRow}>
          <CabListing
            scrollViewRef={scrollViewRef}
            currentBooking={false}
            cabDetail={carDetail}
            rawData={responseJson}
            prevRequest={previewRequest}
            subtitle={props.subtitle}
            handleOverlay={handleOverlay}
          />
        </View>
      );
    }
    return null;
  };

  const getCurrentBookingData = (responseJson) => {
    const { bookingAmountText } = CabBookingDetailsConstant
    return {
      bookingAmountText: bookingAmountText,
      bookingAmountValue: responseJson?.priceList?.filter((item) => item.key === 'oldPrice')?.[0]
        ?.amountText
    };
  };

  const handleOverlay = (overlay = null, cabDetail = {}) => {
    if(!!overlay){
      CabBookingTrackingHelper.trackClickEvent(
        PAGE_NAME.MODIFY_BOOKING,
        CLICK_EVENT.FARE_BREAKUP_CLICK,
      )
    }
    setShowOverlay(overlay);
    setCurrentCabDetail(cabDetail);
  };

  const fareBreakupOverlay = () => {
    const paymentOption = getPaymentOptionData(
      currentCabDetail,
      responseJson.oldCarData,
      responseJson.priceList,
    );
    return (
      <NewCommonOverlay handleBottomOverlay={handleOverlay} roundBorder={true}>
        <View style={styles.OverlayContentWrapper}>
          <FareBreakupOverlay paymentBreakup={paymentOption} handleBottomOverlay={handleOverlay} />
        </View>
      </NewCommonOverlay>
    );
  };

  const renderPage = () => {
    const { carTimeNotSameAsOldPickupTime, ctaNoCabAvailableText } = responseJson.newCarData;
    const closeModal = () => {
      setShowModal(false);
  };

  const {currentBookingText} = CabBookingDetailsConstant

    return (
      <>
        <React.Fragment>
          {carTimeNotSameAsOldPickupTime && !isEmpty(ctaNoCabAvailableText) && (
            <Modal
              animationType="slide"
              transparent={true}
              visible={showModal}
              onRequestClose={closeModal}
            >
              <View style={styles.centeredView}>
                <View style={styles.modalView}>
                  <Text style={styles.modalText}>
                    {responseJson.newCarData.ctaNoCabAvailableText}
                  </Text>
                  <View style={[AtomicCss.alignCenter, AtomicCss.flexRow, styles.positiveButton]}>
                    <CapsuleBtnFill
                      action={{ actionLabeltext: CancelAndRebookConstants.USER_ALERT_BTN_TEXT }}
                      clickHandler={closeModal}
                      style={AtomicCss.alignCenter}
                    />
                  </View>
                </View>
              </View>
            </Modal>
          )}
          <View style={[AtomicCss.flex1, AtomicCss.greyBg]}>
            <Header
              title={CancelAndRebookConstants.MODIFY_BOOKING_HEADING}
              headerShadow
              headingTxt="font18"
              headingSubTxt="font12"
              handleClose={onBackPress}
            />
            <CabBreadcrumb state={CancelAndRebookConstants.SELECT_TEXT} />
            <ScrollView
              ref={(ref) => {
                scrollViewRef.current = ref;
              }}
            >
              <View style={[AtomicCss.greyBg]}>
                {!isEmpty(responseJson.oldCarData) && (
                  <View style={styles.cabRow}>
                    <View style={styles.tagInfo}>
                      {!!currentBookingText && <Text style={styles.currentBookingTxt}>{currentBookingText.toUpperCase()}</Text>}
                      <View style={styles.borderArrow} />
                    </View>
                    <CabListing
                      currentBooking={true}
                      cabDetail={responseJson.oldCarData}
                      currentBookingData={getCurrentBookingData(responseJson)}
                      handleOverlay={handleOverlay}
                    />
                  </View>
                )}
              </View>
              <View style={[AtomicCss.greyBg, AtomicCss.marginTop5]}>
                <Text
                  style={[
                    styles.textBold,
                    AtomicCss.marginTop5,
                    AtomicCss.marginBottom10,
                    AtomicCss.font14,
                  ]}
                >
                  {' '}
                  &nbsp; &nbsp; {responseJson.newCarData.heading}
                </Text>

                <View style={styles.section}>
                  {responseJson.newCarData &&
                    !isEmpty(responseJson.newCarData.itemList) &&
                    responseJson.newCarData.itemList.map((carDetail) => renderCabList(carDetail))}
                </View>
              </View>
            </ScrollView>
          </View>
        </React.Fragment>
        {showOverlay === OVERLAY_TYPE.FARE_BREAKUP && fareBreakupOverlay()}
      </>
    );
  };

  return (
    <View style={styles.flex1}>
      {viewState === ViewState.LOADING && renderProgressView()}
      {viewState === ViewState.NO_INTERNET && renderNoNetworkView()}
      {viewState === ViewState.ERROR && renderErrorView()}
      {viewState === ViewState.NO_CABS && renderNoCabsView()}
      {viewState === ViewState.SHOW_DETAIL && renderPage()}
    </View>
  );
};

export default CabUpgrade;
