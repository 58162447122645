import React from 'react';
import { View, Text, ScrollView } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import { getStaticData } from '../../../../../../staticData/staticData';
import SwipeCard from '../SwipeCard';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import styles from './CYTModalCss';
import PercentageCircle from '../../../../../../Common/CircularProgressBar';
import { getFont } from '../../../../../../PostSalesStyles';

const HeadingText = ({ title }) => {
  const { fsStyle, ...fonts } = getFont(true);
  return (
    <View style={styles.headingStyle}>
      <View style={styles.leftStroke}></View>
      <Text
        style={[
          AtomicCss.blackText,
          fsStyle.font11,
          fonts.blackFontFamily,
          AtomicCss.lineHeight18,
          AtomicCss.marginLeft12,
          AtomicCss.textUppercase,
        ]}
      >
        {title}
      </Text>
    </View>
  );
};
const CYTModal = ({ data, bookingID, ...props }) => {
  const { fsStyle, ...fonts } = getFont(true);
  const fdata = data ? data : {};
  const { completeYourTripText } = getStaticData();
  const {
    header: {
      journeyIntent = [],
      heading = completeYourTripText,
      journeyDate,
      completedPercentage,
      journeyType,
    } = {},
    cardInfo,
  } = fdata;
  return (
    <>
      <View style={styles.tripBgWrapper}>
        <LinearGradient
          start={{ x: 1.5, y: 1.5 }}
          end={{ x: 0.0, y: 1.0 }}
          colors={['#008BDE', '#001037']}
          style={[styles.topStatusCard]}
        >
          <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween]}>
            <View>
              <Text
                style={[
                  AtomicCss.whiteText,
                  fsStyle.font16,
                  fonts.blackFontFamily,
                  styles.lineHeight22,
                  AtomicCss.marginBottom5,
                ]}
              >
                {heading}
              </Text>
              <View style={styles.journeyTypeWrapper}>
                {journeyIntent.map((item, index, arr) => (
                  <>
                    <Text
                      style={[
                        AtomicCss.whiteText,
                        fonts.boldFontFamily,
                        fsStyle.font10,
                        AtomicCss.lineHeight16,
                      ]}
                    >
                      {item}
                    </Text>
                    {index !== arr.length - 1 && (
                      <View
                        style={[
                          styles.arrowWrapper,
                          AtomicCss.paddingRight5,
                          AtomicCss.paddingLeft5,
                        ]}
                      >
                        <Text
                          style={[
                            AtomicCss.whiteText,
                            fonts.boldFontFamily,
                            fsStyle.font10,
                            AtomicCss.lineHeight8,
                          ]}
                        >
                          &#8594;
                        </Text>
                        {!isEmpty(journeyType) &&
                        journeyType.toLowerCase() !== 'o' &&
                        journeyType.toLowerCase() !== 'm' ? (
                          <Text
                            style={[
                              AtomicCss.whiteText,
                              fsStyle.font10,
                              fonts.boldFontFamily,
                              styles.lineHeight8,
                            ]}
                          >
                            &#8592;
                          </Text>
                        ) : (
                          <></>
                        )}
                      </View>
                    )}
                  </>
                ))}
                <Text
                  style={[
                    AtomicCss.whiteText,
                    fonts.boldFontFamily,
                    fsStyle.font10,
                    AtomicCss.lineHeight16,
                  ]}
                >
                  ・{journeyDate}
                </Text>
              </View>
            </View>
            {!isNil(completedPercentage) && (
              <View>
                <PercentageCircle
                  percent={completedPercentage}
                  radius={50}
                  borderWidth={14}
                  color={colors.white}
                  shadowColor={colors.darkBlue9}
                  bgColor={colors.darkBlue8}
                />
              </View>
            )}
          </View>
        </LinearGradient>
      </View>
      <ScrollView style={styles.scrollViewContainer} showsVerticalScrollIndicator={false}>
        <View style={styles.scrollWrapper}>
          {cardInfo &&
            cardInfo.map((item, index) => (
              <View style={styles.segmentCard} key={index}>
                <HeadingText title={item.heading} />
                <SwipeCard
                  listData={item.itemList}
                  scrollToView={props.scrollToView}
                  bookingID={bookingID}
                  callDeepLinkCYT={props.callDeepLinkCYT}
                />
              </View>
            ))}
        </View>
      </ScrollView>
    </>
  );
};
export default CYTModal;
