import React from 'react';
import {
  View,
  Image
} from 'react-native';
import HTMLView from 'react-native-htmlview';
import {blueDropIcondown, blueDropIconUp} from '../../Constant/VisaImageConstant';
import PropTypes from 'prop-types';
import styles from '../../CSS/VisaMamiCss';


export default class VisaFAQText extends React.Component {
    static navigationOptions = {
      header: null
    };

    render() {
      return (
        <View style={[styles.CollapsedTapContainer1]}>
          <View style={styles.CollapsedTapLeftSection}>
            <HTMLView
              style={styles.VisaFaqHTML}
              addLineBreaks={false}
              value={this.props.question}
            />
          </View>
          <View style={[styles.CollapsedTapRightSection]}>
            <Image
              style={[styles.AlignSelfRight, styles.mar10Bt, styles.BlueArrow]}
              source={this.props.tabbing ? blueDropIconUp : blueDropIcondown}
            />
          </View>
        </View>

      );
    }
}

VisaFAQText.propTypes = {
  question: PropTypes.string.isRequired,
  tabbing: PropTypes.bool.isRequired
};
