import {colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import {fontSizes} from '../../HolidayBookingConstants';

const styles = {
  footerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    height:80,
    backgroundColor: colors.black28,
    paddingLeft: 14,
    paddingRight: 14,
    paddingTop:10,
    marginBottom:10,
  },
  actionBtnContainer: {
    alignItems: 'flex-end'
  },
  actionButton: {
    height: 40,
    borderRadius: 22,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 7,
    marginBottom: 7
  },
  actionText: {
    color: colors.white,
    fontSize: fontSizes.reg,
    fontFamily: fonts.bold,
    padding: 8
  }
};

export default styles;
