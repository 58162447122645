import React from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getFont } from '../../../../../PostSalesStyles';
import { useTheme } from '../../../../../theme/theme.context';

const AddBookingCard = ({ response, handleAddBookingOverlay }) => {
  const { text, addBooking } = response;
  const { psTheme: theme } = useTheme();
  const fonts = getFont(true);

  const addBookingOnPress = () => {
    handleAddBookingOverlay && handleAddBookingOverlay(true, 'bottom');
  };

  const styles = createStyles(theme, fonts);
  return (
    <View style={[AtomicCss.alignCenter, AtomicCss.paddingAll10]}>
      {!!text && <Text style={styles.addBookingTitle}>{text}</Text>}
      <TouchableOpacity onPress={addBookingOnPress}>
        {!!addBooking && <Text style={styles.addBookingText}>{addBooking}</Text>}
      </TouchableOpacity>
    </View>
  );
};

export default AddBookingCard;

const createStyles = (theme, fonts) => {
  return StyleSheet.create({
    addBookingTitle: {
      ...fonts.regularFontFamily,
      fontSize: fonts.fontSize.font16,
      color:theme.color.black
    },
    addBookingText: {
      color: theme.color.primary,
      padding: 8,
      ...fonts.boldFontFamily,
      fontSize: fonts.fontSize.font16,
    },
  });
};
