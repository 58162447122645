import { StyleSheet } from 'react-native';
import { Fonts, Theme } from '../../types';

export const getStyles = (fonts : Fonts, theme : Theme) => {
  const { color } = theme;
  return StyleSheet.create({
    extraPay: {
      ...fonts.boldFontFamily,
      fontSize: fonts.fontSize.font10,
    },
    bookingAmountValue: {
      ...fonts.boldFontFamily,
      color: color.black,
      fontSize: 18,
    },
    arrowstyle: {
      width: 20,
      height: 20,
      top: 12
    },
  });
};
