import React, { useContext } from 'react';
import { View, Text, Image, DeviceEventEmitter } from 'react-native';
import getStyle from './SectorCardCss';
import { getFont } from '../../../../../../../PostSalesStyles';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import CardLayoutSeperator from '../../../../../../../Common/CardLayoutSeperator';
import { SUCCESS_TICK_ICON_SM } from '../../../../../../FlightImageConstants';
import UploadProofCard from '../UploadProofCard';
import SpecialClaimNewContext from '../../../../SpecialClaimNewContext';
import SpecialClaimAddAttachmentModule from '@mmt/legacy-commons/Native/SpecialClaimAddAttachmentModule';
import SPECIAL_CLAIM_ACITON_TYPES from '../../../../data/specialClaimActionTypes';
import specialClaimActionTypes from '../../../../data/specialClaimActionTypes';
import { UPLOAD_STATES } from '../../../../constant';
import { uploadFileApi } from '../../../../data/api';
import { fileUploadAPI } from '../../../../../../../utils/PostSaleUtil';
import { getUploadSectorCardData } from '../../../../data/dataAdapter';
import { useTheme } from '../../../../../../../theme/theme.context';
import isEmpty from 'lodash/isEmpty';

const SectorCard = ({
  item,
  isLastItem,
  entries,
  uploadText,
  documents,
  dispatchCancellationState,
  retryText,
  addMoreText,
  bookingId,
}) => {
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme } = useTheme();
  const styles = getStyle(psTheme, fonts);
  const context = useContext(SpecialClaimNewContext);
  const { title, subtitle, footerText, selected, topHeader, id, imgUri, uploadProofData } =
    getUploadSectorCardData(item, context, entries);
  const { titleList, subtitleList } = item;
  //upload files begin
  const uploadFile = async (doc) => {
    const responseObj = await uploadFileApi(doc, bookingId);
    if (responseObj == false) {
      return false;
    } else {
      const { fileName, documentID, url } = responseObj;
      dispatchCancellationState({
        type: specialClaimActionTypes.UPLOAD_DOC_CLOUD,
        data: { fileName, documentId: documentID, url },
      });
      return documentID;
    }
  };

  //upload files end
  const onAddAttachment = async () => {
    const eventKey = 'special_claim_review_page';
    DeviceEventEmitter.addListener(eventKey, async (data) => {
      DeviceEventEmitter.removeAllListeners(eventKey);
      data.status = UPLOAD_STATES.INPROGRESS;
      dispatchCancellationState({
        type: SPECIAL_CLAIM_ACITON_TYPES.UPLOAD_DOC,
        segmentId: id,
        data,
      });
      const status = await uploadFile(data);
      dispatchCancellationState({
        type: SPECIAL_CLAIM_ACITON_TYPES.UPLOAD_DOC_STATUS,
        segmentId: id,
        status: status ? UPLOAD_STATES.SUCCESS : UPLOAD_STATES.ERROR,
        filePath: data.filePath,
      });
    });
    SpecialClaimAddAttachmentModule.onButtonClicked();
  };

 const onAddAttachmentWeb = async (fileArr) => {
    const data = fileArr[0] || {};
    const updatedData = {...data, data: data?.base64String, contentType: data?.mimeType};
    updatedData.status = UPLOAD_STATES.INPROGRESS;
    dispatchCancellationState({
      type: SPECIAL_CLAIM_ACITON_TYPES.UPLOAD_DOC,
      segmentId: id,
      data: updatedData,
    });
    const status = await uploadFile(updatedData);
    dispatchCancellationState({
      type: SPECIAL_CLAIM_ACITON_TYPES.UPLOAD_DOC_STATUS,
      segmentId: id,
      status: status ? UPLOAD_STATES.SUCCESS : UPLOAD_STATES.ERROR,
      filePath: updatedData.filePath,
    });
  };

  const onDeleteAttachment = async (document) => {
    if (document.status == UPLOAD_STATES.ERROR) {
      dispatchCancellationState({
        type: SPECIAL_CLAIM_ACITON_TYPES.UPLOAD_DOC_STATUS,
        segmentId: id,
        status: UPLOAD_STATES.INPROGRESS,
        filePath: document.filePath,
      });
      const status = await uploadFile(document);
      dispatchCancellationState({
        type: SPECIAL_CLAIM_ACITON_TYPES.UPLOAD_DOC_STATUS,
        segmentId: id,
        status: status ? UPLOAD_STATES.SUCCESS : UPLOAD_STATES.ERROR,
        filePath: document.filePath,
      });
    } else {
      dispatchCancellationState({
        type: SPECIAL_CLAIM_ACITON_TYPES.REMOVE_DOC,
        segmentId: id,
        filePath: document.filePath,
      });
    }
  };

  return (
    <View style={[styles.cardWrapper, isLastItem ? styles.cardWrapperLastItem : null]}>
      <View style={styles.checkAllWrapper}>
        <View style={[styles.checkAllWrapperInner]}>
          <View style={styles.sectorHeader}>
            <View style={{ flexDirection: 'column' }}>
              {!isEmpty(titleList) ? (
                titleList.map((title, index) => (
                  <>
                    <View style={styles.destRow}>
                      <Text style={[styles.cityText, fonts.boldFontFamily, fsStyle.font18]}>
                        {title}
                      </Text>
                    </View>
                    {!isEmpty(subtitleList) && !isEmpty(subtitleList[index]) && (
                      <Text
                        style={[
                          styles.smallText,
                          fonts.regularFontFamily,
                          fsStyle.font13,
                          AtomicCss.marginBottom5,
                        ]}
                      >
                        {subtitleList[index]}
                      </Text>
                    )}
                  </>
                ))
              ) : (
                <>
                  <View style={styles.destRow}>
                    <Text style={[styles.cityText, fonts.boldFontFamily, fsStyle.font18]}>
                      {title}
                    </Text>
                  </View>
                  <Text style={[styles.smallText, fonts.regularFontFamily, fsStyle.font13]}>
                    {subtitle}
                  </Text>
                </>
              )}
            </View>
            <View style={styles.airlinesIconWrapper}>
              <Image style={styles.airlineIcon} source={{ uri: imgUri }} />
            </View>
          </View>
        </View>
        {!selected && !!topHeader && (
          <View style={styles.infoTag}>
            <View style={styles.infoWrapper}>
              <Text style={[styles.infoText, fonts.blackFontFamily, fsStyle.font10]}>
                {topHeader}
              </Text>
            </View>
          </View>
        )}
      </View>
      {selected ? (
        <View style={styles.proofWrapper}>
          <Image style={styles.greenTick} source={SUCCESS_TICK_ICON_SM} />
          <Text style={[styles.proofText, fonts.boldFontFamily, fsStyle.font13]}>{footerText}</Text>
        </View>
      ) : (
        <React.Fragment>
          <View style={styles.seperatorWrapper}>
            <CardLayoutSeperator />
          </View>
          <View style={styles.uploadWrapper}>
            <UploadProofCard
              uploadProofData={uploadProofData}
              uploadText={uploadText}
              documents={documents}
              onAddAttachment={onAddAttachment}
              onAddAttachmentWeb={onAddAttachmentWeb}
              onDeleteAttachment={onDeleteAttachment}
              addMoreText={addMoreText}
              retryText={retryText}
            />
          </View>
        </React.Fragment>
      )}
    </View>
  );
};

export default SectorCard;
