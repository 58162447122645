import { Platform } from 'react-native';
import _intersection from 'lodash/intersection';
import _includes from 'lodash/includes';
import _isFunction from 'lodash/isFunction';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import { format } from 'fecha';
import { addDays } from '@mmt/legacy-commons/Helpers/dateTimehelpers';
import { now } from '@mmt/legacy-commons/Helpers/dateHelpers';
import CabsSharedModuleHolder from '@mmt/cabs-shared/src';

const globalSupportedTypes = [
  'locality',
  'administrative_area_level_2',
  'administrative_area_level_4',
  'administrative_area_level_5',
  'street_address',
  'route',
  'intersection',
  'sublocality_level_1',
  'sublocality_level_2',
  'sublocality_level_3',
  'sublocality_level_4',
  'sublocality_level_5',
  'neighborhood',
  'premise',
  'subpremise',
  'natural_feature',
  'airport',
  'park',
  'point_of_interest',
  'floor',
  'parking',
  'post_box',
  'room',
  'bus_station',
  'train_station',
  'transit_station',
  'establishment',
];

const localSupportedTypes = [
  'administrative_area_level_4',
  'administrative_area_level_5',
  'street_address',
  'route',
  'intersection',
  'sublocality_level_1',
  'sublocality_level_2',
  'sublocality_level_3',
  'sublocality_level_4',
  'sublocality_level_5',
  'neighborhood',
  'premise',
  'subpremise',
  'natural_feature',
  'airport',
  'park',
  'point_of_interest',
  'floor',
  'parking',
  'post_box',
  'room',
  'bus_station',
  'train_station',
  'transit_station',
  'establishment',
];

const _filterComponentByType = (addressComponents, types) => {
  if (!addressComponents || !types) return null;
  // TODO Replace with lodash
  for (const type of types) {
    for (const addressComponent of addressComponents) {
      if (addressComponent.types.includes(type)) {
        return addressComponent;
      }
    }
  }
  return null;
};

export const getPlaceDetails = async (placeId) => {
  let CabsSharedModule = CabsSharedModuleHolder.get();
  if (CabsSharedModule == null) {
    throw 'Cabs-Shared module not bootstrapped';
  }
  const { getNativeGeocodes } = CabsSharedModule.getCabsLocationModule();
  const result = await getNativeGeocodes(placeId);
  return getAddress(result);
};

export const getAddress = (result) => {
  const cityTypes = ['administrative_area_level_2', 'locality', 'administrative_area_level_1'];
  const airportTypes = ['airport'];
  const addressComponents = result?.address_components;
  const cityName = _filterComponentByType(addressComponents, cityTypes);
  const city = cityName && cityName.long_name;
  const isCity = _intersection(cityTypes, result.types).length > 0;
  const isAirport =
    _intersection(airportTypes, result.types).length > 0 ||
    _includes(result?.formatted_address?.toLowerCase(), 'airport');
  const postalCode = _filterComponentByType(addressComponents, ['postal_code']);
  const pincode = postalCode && postalCode.short_name;
  let { lat = null, lng = null } = result?.geometry?.location;
  if (_isFunction(lat)) {
    lat = lat();
    lng = lng();
  }
  return {
    address: result.formatted_address,
    place_id: result.place_id,
    latitude: lat,
    longitude: lng,
    pincode,
    city,
    google_city: city,
    is_city: isCity,
    is_airport: isAirport,
  };
};

export const fetchAutoCompleteResults = async (query: string) => {
  let CabsSharedModule = CabsSharedModuleHolder.get();
  if (CabsSharedModule == null) {
    throw 'Cabs-Shared module not bootstrapped';
  }
  const { getNativePredictions } = CabsSharedModule.getCabsLocationModule();
  const predictions = await getNativePredictions(query);
  const results = _filterPredictions(predictions, query, false);
  return results;
};

const _filterPredictions = (predictions, query, isNearBy) => {
  const supportedTypes = isNearBy ? localSupportedTypes : globalSupportedTypes;
  return predictions
    .filter((prediction) => {
      for (const predictionType in prediction.types) {
        if (supportedTypes.indexOf(prediction.types[predictionType]) > -1) {
          return true;
        }
      }
      return false;
    })
    .map((prediction) => {
      const mainText = prediction.structured_formatting.main_text;
      const secondaryText = prediction.structured_formatting.secondary_text
        ? prediction.structured_formatting.secondary_text
        : mainText;
      const name = `${mainText}, ${secondaryText}`;
      const matches = matchMainTextWithQuery(mainText, query);
      return {
        name,
        matches,
        mainText: name,
        secondaryText,
        placeId: prediction.place_id,
      };
    });
};

const matchMainTextWithQuery = (mainText, query) => {
  const matchStart = mainText.toLowerCase().indexOf(query.toLowerCase());
  const matches = [];
  if (matchStart < 0) {
    matches.push({
      text: mainText,
      matched: false,
    });
  } else {
    if (matchStart > 0) {
      matches.push({
        text: mainText.substring(0, matchStart),
        matched: false,
      });
    }
    matches.push({
      text: mainText.substring(matchStart, matchStart + query.length),
      matched: true,
    });
    if (matchStart + query.length < mainText.length) {
      matches.push({
        text: mainText.substring(matchStart + query.length, mainText.length + 1),
        matched: false,
      });
    }
  }
  return matches;
};

export const openListingUrl = (source, destination, originSource = '') => {
  const src = encodeURIComponent(JSON.stringify(source));
  const dest = encodeURIComponent(JSON.stringify(destination));
  const departDate = addDays(now(), 1);
  departDate.setHours(10);
  departDate.setMinutes(0);
  const dateStr = format(departDate, 'DD-MM-YYYY');
  const timeStr = format(departDate, 'HH:mm');
  GenericModule.openDeepLink(
    `mmyt://react/?page=cabsListing&deeplink=true&tripType=OW&from=${src}&to=${dest}&startDate=${dateStr}&pickupTime=${timeStr}&source=${originSource}&marketingHotelId=Hotel_My_Trips`,
  );
};

export const PlatformConfig = {
  ...Platform.select({
    android: {
      bookMode: 'A',
      bookingDevice: 'Android',
      appVersion: '7.2.5',
    },
    ios: {
      bookMode: 'I',
      bookingDevice: 'iOS',
      appVersion: '6.0.2',
    },
    web: {
      bookMode: 'M',
      bookingDevice: 'msite',
      appVersion: '1.0.0',
    },
  }),
};