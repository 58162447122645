import React from 'react';
import {View, Text} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import PropTypes from 'prop-types';
import CommonStyles from '@mmt/legacy-commons/Styles/AtomicCss';
import styles from './FooterCss';
import TouchableOpacity from '../TouchableOpacity';

const enabledColors = ['#53B2FE', '#065AF3'];
const disabledColors = ['#989898', '#989898'];

const Footer = props => (
  <View style={[styles.footerContainer, props.footerContainerStyle]}>
    {props.footerLeftContent ? props.footerLeftContent : <View />}
    {props.enabled ?
      <TouchableOpacity onPress={props.onActionPress} style={styles.actionBtnContainer}>
        <LinearGradient
          colors={enabledColors}
          start={{x: 0.0, y: 0.0}}
          end={{x: 1.0, y: 0.0}}
          style={[styles.actionButton, props.footerActionStyle]}
        >
          <Text style={[styles.actionText, CommonStyles.basicTextStyle]}> {props.actionText} </Text>
        </LinearGradient>
      </TouchableOpacity> :
      <View style={styles.actionBtnContainer}>
        <LinearGradient
          colors={disabledColors}
          start={{x: 0.0, y: 0.0}}
          end={{x: 1.0, y: 0.0}}
          style={[styles.actionButton, props.footerActionStyle]}
        >
          <Text style={[styles.actionText, CommonStyles.basicTextStyle]}> {props.actionText} </Text>
        </LinearGradient>
      </View>}
  </View>
);

Footer.propTypes = {
  footerLeftContent: PropTypes.element,
  footerContainerStyle: PropTypes.object,
  footerActionStyle: PropTypes.object,
  onActionPress: PropTypes.func.isRequired,
  actionText: PropTypes.string.isRequired,
  enabled: PropTypes.bool
};

Footer.defaultProps = {
  footerLeftContent: null,
  footerContainerStyle: {},
  footerActionStyle: {},
  enabled: true
};

export default Footer;
