import React from 'react';
import LinearGradient from 'react-native-linear-gradient';
import {Image, Text, TouchableOpacity, View} from 'react-native';
import styles from '../GiftCardDetailsCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { BACK_ARROW_BLACK_ICON } from '../GiftCardImageConstants';
import Button from '../../Common/Button/index';
import GiftCardBookingDetailsConstant from '../GiftCardBookingDetailsConstant';


const TopStatusCard = (props) => {

  const getIconStyle = () => {
    return props.iconStyle || styles.topStatusIcon;
  }

  const getResendVoucher = (ctas) => {
    for(let obj of ctas) {
      if(obj.actionFamily === GiftCardBookingDetailsConstant.RESEND_VOUCHER) {
        return {
          actionFamily: obj.actionFamily,
          text: obj.text
        };
      }
    }
    return undefined;
  }

  return (
    <LinearGradient
      start={{
        x: 1.0,
        y: 0.0
      }}
      end={{
        x: 0.0,
        y: 1.0
      }}
      colors={props.color}
      style={[styles.topStatusCard]}
    >
      <View style={styles.topStatusBar}>
        <TouchableOpacity style={{padding: 16}} onPress={props.onBackPress}>
          <Image style={styles.backarrow} source={BACK_ARROW_BLACK_ICON}/>
        </TouchableOpacity>
        <Image style={[styles.mar10Bt, getIconStyle()]} source={props.iconPath}/>
        <TouchableOpacity style={{padding: 16}}/>
      </View>
      <View style={[styles.StatusCardWrapper, styles.transperantBgrnd]}>
        <Text style={[styles.BlackText, styles.AlignCenter, styles.font18, styles.blackFont, AtomicCss.paddingBottom10]}>
          {props.headText}
        </Text>
        <View style={[styles.flexRow]}>
          <View style={[AtomicCss.flex1]}>
            <Text style={[styles.booingDtlsTxt, styles.AlignRight]}>BOOKING ID</Text>
          </View>
          <View style={[AtomicCss.flex1]}>
            <Text style={styles.booingDtlsbold}>{props.bookingID}</Text>
          </View>
        </View>
      </View>
    </LinearGradient>
  );
}

export default TopStatusCard;
