import React from 'react';
import { View, Text, Image } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import createStyle from './PolicyCardCss';
import { useTheme } from '../../../../../theme/theme.context';
import { getFont } from '../../../../../PostSalesStyles';

const structedTimePenalityObject = (timelist, penalityList) => {

  let result = [];
  for (let i = 0; i < timelist?.length - 1; i++) {
    result.push({
      timeFrame: `${timelist[i].date}${timelist[i].time ? `, ${timelist[i].time}` : ''} - ${
        timelist[i + 1].date
      }${timelist[i + 1].time ? `, ${timelist[i + 1].time}` : ''}`,
      penality: penalityList[i].text,
    });
  }
  return result;
};

const PolicyCard = ({ cardData }) => {
  const { title, data } = cardData;
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyle(theme);

  return (
    <View style={styles.policyCardWrapper}>
      <View style={[styles.innerSectionDetails]}>
        <View
          style={[
            styles.flex1,
            styles.spaceBetween,
            styles.flexRow,
            styles.alignCenter,
            styles.marginBottom20,
          ]}
        >
          <View style={[styles.flex1, styles.marginBottom10]}>
            {!!title && (
              <Text style={[fonts.regularFontFamily, fsStyle.font18, styles.darkTxt]}>
                {title}
              </Text>
            )}
          </View>
        </View>
        {data &&
          data.map((item) => {
            const {
              penaltyTitle,
              header,
              timePeriodHeading,
              timelineList,
              penaltyList,
              bgColors = ['#01953c', '#83b430', '#d69e15', '#fa5d5d'],
            } = item;
            const { airlineIcon, sectorHeading } = header;
            return (
              <View style={[styles.marginBottom20]}>
                <View
                  style={[
                    styles.flex1,
                    styles.spaceBetween,
                    styles.flexRow,
                    styles.marginBottom20,
                  ]}
                >
                  <View style={[styles.flex1, styles.flexRow]}>
                    <Image style={styles.Flt_Icon} source={{ uri: airlineIcon }} />
                    <Text style={[styles.darkTxt, fsStyle.font16]}>{sectorHeading}</Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.flex1,
                    styles.spaceBetween,
                    styles.flexRow,
                    styles.marginBottom20,
                  ]}
                >
                  {!!timePeriodHeading && (
                    <Text style={[fonts.boldFontFamily, styles.darkTxt, fsStyle.font12, styles.flex1]}>
                      {timePeriodHeading}
                    </Text>
                  )}
                  {!!penaltyTitle && (
                    <Text style={[fonts.boldFontFamily, styles.darkTxt, fsStyle.font12, styles.flex1, {textAlign: "right"}]}>
                      {penaltyTitle}
                    </Text>
                  )}
                </View>

                <View
                  style={[styles.flexRow, styles.spaceBetween, styles.alignCenter]}
                >
                  <View style={[styles.flex1]}>
                    {structedTimePenalityObject(timelineList, penaltyList)?.map((frame) => {
                      return (
                        <View
                          style={[
                            styles.flexRow,
                            styles.spaceBetween,
                            styles.marginBottom10,
                            styles.lineStyle,
                          ]}
                          key={frame?.timeFrame}
                        >
                          {!!frame?.timeFrame && (
                            <Text
                              style={[
                                fonts.blackFontFamily,
                                fsStyle.font14,
                                styles.blackTxt,
                                styles.marginBottom5,
                              ]}
                            >
                              {frame.timeFrame}
                            </Text>
                          )}
                          {!!frame?.penality && (
                            <Text
                              style={[
                                fonts.blackFontFamily,
                                fsStyle.font16,
                                styles.blackTxt,
                                styles.marginBottom5,
                              ]}
                            >
                              {frame.penality}
                            </Text>
                          )}
                        </View>
                      );
                    })}
                  </View>
                  <View style={styles.gredientLine}>
                    <LinearGradient
                      colors={bgColors}
                      style={[styles.linearGradient, styles.flex1]}
                    />
                  </View>
                </View>
              </View>
            );
          })}
      </View>
    </View>
  );
};

export default PolicyCard;
