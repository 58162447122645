import React from 'react';
import { StyleSheet } from 'react-native'
const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  header: {
    borderBottomColor: '#e7e7e7',
    borderBottomStyle: 'solid',
    borderBottomWidth: 2,
    position: "relative",
    flexDirection: 'row',
    flexWrap: 'wrap',
    flex: 1
  },
  headerContent: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 10,
    flex: 1
  },
  dateFiled: {
    flex: 1,
    paddingLeft: 16,
    marginBottom: -2,
    position: "relative",
  },
  active: {
    borderBottomColor: '#008cff',
    borderBottomStyle: 'solid',
    borderBottomWidth: 3
  },
  subHeading: {
    fontSize: 12,
    color: "#9b9b9b",
    marginTop: 10,
    whiteSpace: 'nowrap'
  },
  dateContainer: {
    fontSize: 20,
    marginVertical: 8,
    fontWeight: "600",
    whiteSpace: 'nowrap'
  },
  errorText: {
    backgroundColor: "#ffd3d4",
    color: "#eb2026",
    width: "100%",
    zindex: 1,
    position: "relative",
    top: 0,
    margin: 0,
    textAlign: "center",
    fontSize: 12,
    fontFamily: "Lato-Bold",
    paddingHorizontal: 16,
    paddingVertical: 4
  }
});

export default styles;