import {fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from '@mmt/legacy-commons/Common/Components/Card/getPlatformElevation';


const styles ={
  cardBorder:{
    borderTopColor:'#e4e4e4',
    borderBottomColor:'#e4e4e4',
    borderTopWidth:1,
    borderBottomWidth:1
  },
  infoCardWrapper:{
    backgroundColor:'#fff',
    justifyContent:'space-between',
    flexDirection:'row',
    ...getPlatformElevation(2),
    marginBottom:1,
  },

  infoCardContent:{
      paddingHorizontal:8,
      paddingVertical:15,
      flex:1
  },

  infoCustom:{
    fontSize:14,
    color:'#4a4a4a',
    lineHeight:18,
    fontFamily:fonts.regular,
  },
  headingIconWrapper:{
    width:46,
    alignItems:'flex-start',
    top:14,
    left:16,
  },
  headingIconStyle:{
    width:24,
    height:24,
  },

  redirectBtnWrapper:{
    paddingTop:20,
    paddingBottom:20,
    paddingLeft:60,
    paddingRight:35,
    justifyContent:'space-between',
    flexDirection:'row',
    backgroundColor:'#fff',
    borderBottomWidth:1,
    borderBottomColor:'#ededed',
    alignItems:'center',

  },

  rightArrowIcon:{
    width:9,
    height:13
  },

  linkBtnTxt:{
    fontSize:14,
    fontFamily:fonts.bold,
    color:"#008cff"
  },
  buttonWrapper:{
    paddingTop:20,
    width:'90%'
  },

}


export default styles;
