import { Dimensions, StyleSheet, I18nManager } from 'react-native';

export default (theme, fonts) =>
  StyleSheet.create({
    reasonWrapper: {
      maxHeight: Dimensions.get('window').height - 160,
    },
    fixedWrapper: {
      marginBottom: 30,
    },
    scrollWrapper: {
      paddingHorizontal: 30,
      paddingBottom: 30,
    },
    title: {
      color: theme.color.black,
      fontSize: fonts.fontSize.font22,
      ...fonts.blackFontFamily,
      marginBottom: 8,
      lineHeight: 32,
    },
    arrowStyle: {
      width: 8,
      height: 13,
      marginLeft: 10,
      marginTop: 5,
      transform: [{scaleX: I18nManager.isRTL ? -1 : 1}]
    },
    iconStyle: {
      width: 24,
      height: 24,
      marginRight: 12,
      marginTop: 3,
    },
    listWrapper: {
      marginBottom: 12,
      backgroundColor: theme.color.paleBlue,
      borderColor: theme.color.faintBlue,
      borderWidth: 1,
      borderRadius: 4,
      padding: 16,
    },
    listTopWrapper: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    lastItemStyle: {
      marginBottom: 0,
    },
    titleTxt: {
      ...fonts.blackFontFamily,
      fontSize: fonts.fontSize.font16,
      color: theme.color.azure,
      marginBottom: 4,
      lineHeight: 24,
    },
    desc: {
      ...fonts.regularFontFamily,
      fontSize: fonts.fontSize.font14,
      color: theme.color.textGrey,
      lineHeight: 20,
    },
    innerBottomWrapper: {
      borderTopWidth: 1,
      borderTopColor: theme.color.faintBlue,
      marginHorizontal: -16,
      paddingHorizontal: 16,
      marginTop: 16,
      paddingTop: 12,
      marginBottom: -4,
    },
    innerWrapper: {
      backgroundColor: theme.color.white,
      borderWidth: 1,
      borderColor: theme.color.grayBg,
      borderRadius: 4,
      padding: 12,
    },
    greenTickIcon: {
      height: 16,
      width: 16,
      marginRight: 4,
    },
    arrowIcon: {
      width: 8,
      height: 6,
      marginRight: 12,
    },
    nameCorrectionRow: {
      flexDirection: 'row',
      alignItems: 'center',
      paddingHorizontal: 20,
      borderRadius: 4,
    },
    underline: {
      textDecorationLine: 'underline',
    },
    lineThorugh: {
      textDecorationLine: 'line-through',
    },
    width100: {
      width: 100,
    },
    greenText: {
      color: theme.color.green,
    },
  });
