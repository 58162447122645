import { StyleSheet, ViewStyle } from 'react-native';

interface Theme {
    color: {
        grayBg: string;
        white: string;
        lightGray: string;
        lightGreen18: string;
    };
}

interface Fonts {
    fontSize: { [key: string]: number };
    boldFontFamily: { [key: string]: object };
    regularFontFamily: { [key: string]: object };
}

interface Styles {
    CancellationWrapper: ViewStyle;
    tgRefundCard: ViewStyle;
    descWrapper: ViewStyle;
    refundTextList: ViewStyle;
    refundText: ViewStyle;
    borderTop: ViewStyle;
    refundDescWrapper: ViewStyle;
    headingSection: ViewStyle;
    refundSection: ViewStyle;
    successText: ViewStyle;
    borderBottom: ViewStyle;
}

  
export const createStyles = (theme: Theme, fonts: Fonts) => {
    return StyleSheet.create<Styles>({
        CancellationWrapper: {
            flex: 1,
            justifyContent: 'space-between',
            backgroundColor: theme.color.grayBg,
        },
        tgRefundCard:{
            backgroundColor:theme.color.white,
            marginVertical: 10
        },
        refundTextList: {
            paddingBottom : 16
        },
        refundText: {
            paddingHorizontal: 16,
            paddingVertical: 2,
            flexDirection: 'row'
        },
        refundDescWrapper: {
            paddingHorizontal:16,
            paddingTop: 8
        },
        descWrapper:{
            paddingHorizontal:16,
            paddingVertical:8
        },
        borderTop: {
            borderTopWidth:1,
            borderTopColor: theme.color.lightGray,
        },
        borderBottom: {
            borderBottomColor: theme.color.lightGray,
            borderBottomWidth:1
        },
        headingSection:{
            padding:16,
        },
        refundSection:{
            padding:16,
            flexDirection:'row',
            alignItems:'center',
            justifyContent:'space-between'
        },
        successText:{
            color: theme.color.lightGreen18
        },
    })
}