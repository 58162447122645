import React from "react";
import { View, Image, StyleSheet, Text, Dimensions } from "react-native";
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';;
import { fonts } from '@mmt/legacy-commons/Styles/globalStyles';
import {VoucherIcon, MailIcon, LockIcon, greenVoucherIcon, alertIcon} from '../HolidayBookingConstants';
import { ps_colors } from "../../PostSalesStyles";

const windW = Dimensions.get("screen").width;

const TopMessage = props => {
  const { info, bgcolor, img } = props;
  const backgroundStyle = bgcolor ? { backgroundColor: bgcolor } : "";

  let imgIcon;
  let iconStyle;
  let infotextStyle={}
  switch (img) {
    case 'voucherIcon':
      imgIcon = VoucherIcon;
      iconStyle = [styles.voucherIconStyle];
      break;
    case 'mailIcon':
      imgIcon = MailIcon;
      iconStyle = [styles.mailIconStyle];
      break;
    case 'lockIcon':
      imgIcon = LockIcon;
      iconStyle = [styles.lockIconStyle];
      break;
    case 'greenVoucherIcon':
      imgIcon = greenVoucherIcon;
      iconStyle = [styles.voucherIconStyle];
      infotextStyle = { color: ps_colors.defaultTextColor };
      break;
    case 'redAlertIcon':
      imgIcon = alertIcon;
      iconStyle = [styles.redVoucherIconStyle];
      infotextStyle = { color: ps_colors.defaultTextColor };
      break;
    default:
  }
  return (
    <View style={{ width: windW }}>
      <View style={[styles.infoWrapper, backgroundStyle]}>
        <View style={[AtomicCss.flex1, AtomicCss.marginRight18]}>
          <Text style={[styles.infoTxtStyle,infotextStyle]}>{info}</Text>
        </View>
        <View style={styles.iconWrapper}>
         
          <Image style={iconStyle} source={imgIcon} />  
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  infoWrapper: {
    marginHorizontal: 10,
    marginTop: 5,
    marginBottom: 15,
    padding: 16,
    borderRadius: 4,
    flexDirection: "row",
    justifyContent: "space-between"
  },
  iconWrapper: {
    width: 40,
    height: 40,
    backgroundColor: "#fff",
    borderRadius: 50,
    alignItems: "center",
    justifyContent: "center"
  },
  voucherIconStyle: {
    width: 24,
    height: 14,
  },
  redVoucherIconStyle: {
    width: 24,
    height: 24,
    resizeMode:'contain'
  },
  infoTxtStyle: {
    color: "#fff",
    fontSize: 14,
    lineHeight: 24,
    fontFamily: fonts.bold
  },
  mailIconStyle: {
    width: 22,
    height: 15
  },
  lockIconStyle: {
    width: 17,
    height: 21
  }
});

export default TopMessage;
