import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { isEmpty } from 'lodash';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const PaymentDetailCard = ({ paymentBreakup }) => {
	const { fsStyle, ...fonts } = getFont(true);
  const styles = getStyles(fonts);
	if (isEmpty(paymentBreakup)) {
		return null
	}

	const { key, keyValue, keyValueColor, subText, breakupItemList = [] } = paymentBreakup;

	return (<View style={[AtomicCss.whiteCard, AtomicCss.marginHz12, AtomicCss.marginTop15, AtomicCss.flex1, AtomicCss.borderRadius16]}>
		<View style={[styles.topHeading, AtomicCss.marginTop15, AtomicCss.marginBottom20]}>
			<View style={[AtomicCss.flex1, AtomicCss.marginRight15]}>
				<Text style={[styles.titleText, AtomicCss.marginBottom3]}>{key}</Text>
				<Text style={[AtomicCss.defaultText, fsStyle.font14, fonts.regularFontFamily]}>{subText}</Text>
			</View>
			<Text style={[styles.titleAmountText, !isEmpty(keyValueColor) ? {color: keyValueColor} :{}]}>{`${keyValue} `}</Text>
		</View>

		<View style={styles.refundAmountWrapper}>
			{
				!isEmpty(breakupItemList) && breakupItemList.map(item => {
					return (
						<View style={AtomicCss.marginBottom10}>
							<View style={styles.amountRow}>
								<View style={[AtomicCss.flex1]}>
									<Text style={[fonts.boldFontFamily, AtomicCss.blackText, fsStyle.font14, AtomicCss.flex1]}>{item.key}</Text>
									{!isEmpty(item.subText) && <Text style={[AtomicCss.defaultText, fsStyle.font14, fonts.regularFontFamily, AtomicCss.marginTop3]}>{item.subText}</Text>}
								</View>
								<Text style={[fonts.boldFontFamily, fsStyle.font14, AtomicCss.blackText]}>{`${item.keyValue} `}</Text>
							</View>
							{
								!isEmpty(item.breakupItemList) && item.breakupItemList.map((childItem, index) => {
									return (
										<View style={[styles.amountRow, index == 0 ? styles.firstElementStyle : {}]}>
											<Text style={[styles.detailText, AtomicCss.defaultText]}>{childItem.key}</Text>
											<Text style={[styles.amountText, AtomicCss.defaultText]}>{`${childItem.keyValue} `}</Text>
										</View>
									)
								})
							}
						</View>
					)
				})
			}
			<View style={styles.totalRow}>
				<Text style={[styles.totalText, AtomicCss.flex1, !isEmpty(keyValueColor) ? {color: keyValueColor} :{}]}>{key}</Text>
				<Text style={[styles.totalText, !isEmpty(keyValueColor) ? {color: keyValueColor} :{}]}>{`${keyValue} `}</Text>
			</View>
		</View>
	</View>)

}


const getStyles = (fonts) => {
	return StyleSheet.create({
		topHeading: {
			flexDirection: 'row',
			paddingHorizontal: 4,
			marginBottom: 16
		},
		titleText: {
			...fonts.font20,
			color: colors.black,
			fontFamily: fonts.black,
			lineHeight: 24,
			flex: 1
		},
		titleAmountText: {
			...fonts.font20,
			color: '#000',
			fontFamily: fonts.black,
			lineHeight: 24,
		},
		refundAmountWrapper: {
			paddingHorizontal: 16,
			paddingVertical: 16,
			marginBottom: 5,
			borderWidth: 1,
			borderColor: '#e7e7e7',
			borderRadius: 8
		},
		amountRow: {
			flexDirection: 'row',
			justifyContent: 'space-between',
			marginBottom: 10,
		},
		totalRow: {
			paddingTop: 15,
			paddingBottom: 10,
			borderTopColor: '#f2f2f2',
			borderTopWidth: 1,
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'space-between',
		},
		totalText: {
			...fonts.font16,
			color: '#000000',
			fontFamily: fonts.black,
			lineHeight: 19.2,
		},
		amountHeadingRow: {
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'space-between',
		},
		detailText: {
			...fonts.font14,
			fontFamily: fonts.regular,
			lineHeight: 20,
			marginRight: 30,
		},
		amountText: {
			...fonts.font14,
			fontFamily: fonts.regular,
			lineHeight: 20,
		},
		firstElementStyle: {
			paddingTop: 15,
		},
	});
}

export default PaymentDetailCard;
