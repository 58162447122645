import { StyleSheet } from 'react-native';
import { DEFAULT_THEME } from 'apps/post-sales/src/theme/default.theme';

export default <T extends typeof DEFAULT_THEME>(theme: T) =>
  StyleSheet.create({
    benefitWrapper: {
      backgroundColor: theme.color.transparent,
      marginHorizontal: 12,
      marginBottom: 12,
      paddingVertical: 0,
    },
    benefitWhiteBg: {
      backgroundColor: theme.color.white,
      borderRadius: 16,
      paddingHorizontal: 16,
      borderWidth: 1,
      borderColor: theme.color.lightGray,
    },
    seperatorIcon: {
      paddingTop: 10,
      paddingBottom: 12,
      marginTop: -35,
      left: '45%',
      position: 'absolute',
      zIndex: 1,
    },
    plusIcon: {
      width: 35,
      height: 35,
    },
    mainImg: {
      width: 41,
      height: 37,
      marginLeft: 8,
    },
    descriptionTxt: {
      width: '80%',
    },
  });
