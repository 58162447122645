import React from 'react';
import {Image, Text, View} from 'react-native';
import Actions from '../../../navigation/postSalesNavigation';
import logAction from '@mmt/legacy-commons/Helpers/actionsLogger';
import styles from '../../CSS/VisaMamiCss';
import StickyHeader from '../../../hotel/details/components/StickyHeader';
import VisaPaymentDetail from './VisaPaymentDetail';
import PropTypes from 'prop-types';
import {TripHeaderIcon} from '../../Constant/VisaImageConstant';


export default class VisaPaymentBreakUp extends React.Component {
    static navigationOptions = {
      header: null
    }
    onBackPress = () => {
      Actions.pop();
      logAction(`${this.pageName}onBackPress`);
    };

    render() {
      return (
        <View style={styles.MimaMainWrapper}>
          <StickyHeader headerColor={['#ffffff', '#ffffff']} iconSrc={TripHeaderIcon} title="Price Breakup" subTitle="" titleStyle={[styles.BlackText, styles.boldFont, styles.bgTransparent, styles.font16]} subTitleStyle={[styles.BlackText, styles.boldFont, styles.bgTransparent, styles.font16]} onBackPress={this.onBackPress} />
          <VisaPaymentDetail response={this.props.response} />
        </View>
      );
    }
}

VisaPaymentBreakUp.propTypes = {
  response: PropTypes.object.isRequired
};
