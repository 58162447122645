import React from 'react'
import { View, Image, Text, StyleSheet } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import { REFUND_BLUE_ICON } from '../../../HotelImageConstants';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const EmiDetailCard = ({emiMessage}) => {
    const { fsStyle, ...fonts } = getFont(true);
    return (
        <View style={styles.refundDetails}>
            <Image source={REFUND_BLUE_ICON} style={styles.refundIcon} />
            <Text style={[fsStyle.font14, fonts.regularFontFamily, AtomicCss.lineHeight18, AtomicCss.flex1, AtomicCss.defaultText]}>
                {emiMessage}
            </Text>
        </View>
    )
}

const styles = StyleSheet.create ({   
    refundDetails: {
        ...getPlatformElevation(2),
        backgroundColor: colors.paleBlue,
        paddingHorizontal: 16,
        paddingVertical: 12,
        borderRadius: 8,
        overflow: 'hidden',
        marginTop: 4,
        marginBottom: 12,
        marginHorizontal: 12,
        flexDirection: 'row',
    },
    refundIcon: {
        width: 28,
        height: 28,
        marginRight: 12
    }
})

export default EmiDetailCard;