const TGSBookingConstants = {
TOP_STATUS_CARD : 'TopStatusCard',
TRIP_VOUCHER_USAGE_CARD : 'TgVoucherUse',
IMP_INSTRUCTIONS_CARD : 'ImpInstructions',
RAIL_SERVICES_CARD : 'RailServices',
TGS_FEATURES_CARD: "TripGuaranteeFeatures",
PAX_DETAILS_CARD : "PaxDetails",
CONTACT_DETAILS_CARD : "ContactDetails",
PAYMENT_INFO : 'PaymentCard',
CONTACT_US : 'ContactUs',
//TOP STATUS CARD CONSTANTS

NOT_CONFIRMED : 'CHART_PREPARED_NOT_CONFIRMED',
NOT_PREPARED : 'CHART_NOT_PREPARED',
PREPARED_CONFIRMED : 'CHART_PREPARED_CONFIRMED',
PARTIALLY_CONFIRMED : 'CHART_PREPARED_PARTIALLY_CONFIRMED',
NOT_PREPARED_CANCELLED : 'CHART_NOT_PREPARED_CANCELLED',


//Status CARD CONSTANTS
WAITING_LIST : 'WAITING LIST',
RAC : 'RAC',
CONFIRMED : 'CONFIRMED',
TDR : 'TDR',
FAILED : 'FAILED',
CANCELLED : 'CANCELLED',


//RefundVouhcers Constants
RUPEE_SIGN : '₹',



//ActionList Constants
RECT_FULL_BOOK_BUTTON : "RecBookFullBtn",


//Rail info services

SHOW_MORE :'Show more information services',
SHOW_LESS : 'Show Less',



//actions

RECTANGLE_FULL_BUTTON: 'RecFullBtn',
RECTANGLE_PRIMARY_FULL_WIDTH: 'CapsuleFullBtnFill',
RECTANGLE_PRIMARY_HALF_WIDTH: 'CapsuleHalfBtnFill',
RECTANGLE_SECONDARY_FULL_WIDTH: 'CapsuleFullBtn',
RECTANGLE_SECONDARY_HALF_WIDTH: 'CapsuleHalfBtn',
DOWNLOAD_RECT_FULL_BUTTON: 'RecFullDownloadBtn',

ACTION_PAYMENT_DETAILS_PAGE : "SeePriceBreakup",

//ContactUs diff action
CALL_US : "CALL",


//Price Breakup Constants
TOTAL_AMOUNT : 'Total Amount',
BASE_PRICE : 'BASE PRICE',

DOWNLOAD_INVOICE : 'Download Invoice',
TGS_INVOICE_TRIGGER: 'TGS_CustomerInvoice',


//Detail Page States
LOADING: 'loading',
SHOW_DETAIL: 'detail',
NO_INTERNET: 'NO_INTERNET',
ERROR: 'error',

DOWNLOAD_FAILED: 'DOWNLOAD_FAILED',
TGS_STORAGE_PERMISSION: 'tgs_booking_details_storage_permission',
UNIQUE_ID_TYPE: 'TGS_INVOICE',



//service
PNR_STATUS_PAGE : 'pnrStatusPage',
RAIL_LISTING :'railsListing',
COACH_POSITION :'coachPositionList',
RAIL_SCHEDULE:'railsTrainSchedule',
}

export default TGSBookingConstants;


export const PAGE_NAME = {
    TRIP_DETAILS : "TripDetails",
    SUMMARY : "Summary"
}

export const CLICK_TYPE = {
    TERMS_AND_CONDITION_CLICK : 'TermsAndCondition_Click',
    DOWNLOAD_INVOICE_CLICK: 'DownloadInvoice_Click',
    PNR_STATUS_CLICK: 'PNRStatus_Click',
    TRAIN_SCHEDULE_CLICK: 'TrainSchedule_Click',
    LIVE_TRAIN_STATUS_CLICK: 'LiveTrainStatus_Click',
    FOOD_IN_TRAIN_CLICK: 'FoodInTrain_Click',
    LIVE_STATION_CLICK: 'LiveStation_Click',
    COACH_POSITION_CLICK: 'CoachPosition_Click',
    TRAIN_AVAILABLILITY_CLICK: 'TrainAvailablility_Click',
    VACANTCHART_CLICK: 'VacantChart_Click',
    EXPLORE_TRAINS_CLICK: 'ExploreTrains_Click',
    CALL_US_CLICK: 'CallUs_Click',
    FREE_CANCELLATION_CLICK: 'FreeCancellation_Click',
    TRIP_GUARANTEE_CLICK: 'TripGuarantee_Click',
    BUY_NOW_PAY_LATER_CLICK: 'BNPL_Click',
    COPY_VOUCHERICON_CLICK: 'CopyVoucherIcon_Click',
    REFERESH_PNR_ICON_CLICK: 'RefereshPNRIcon_Click',
    SUMMARY_CLICK: 'Summary_Click',
    BOOKTRAIN_CLICK: 'BookTrain_Click',
    BOOKBUS_CLICK: 'BookBus_Click',
    BOOKCAB_CLICK: 'BookCab_Click',
    BOOKFLIGHT_CLICK: 'BookFlight_Click'
}
