import React from 'react';
import getStyles from '../../details/components/HotelMamiCss';
import { View, ScrollView, DeviceEventEmitter, BackHandler } from 'react-native';
import Actions from '../../../navigation/postSalesNavigation';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import { getCommonHeaders, HOTEL_ADD_GUEST_URL } from '../../../utils/NetworkUtils';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import MAMIBlackFooter from '../common/MAMIBlackFooter';
import NoInternetView from '@mmt/legacy-commons/Common/Components/Error/NoInternetView';
import {
  fillCheckInCheckOutData,
  getModificationRequest,
  isIntlHotelBooking,
  handleClickEvent,
} from '../../details/utils/HotelBookingDetailUtil';
import BasePage from '../../../Common/PostSalesBasePage';
import HotelBookingTrackingHelper from '../../HotelBookingTrackingHelper';
import SimpleHeader from '@mmt/legacy-commons/Common/Components/Header/SimpleHeader';
import { invalidateTrips } from '@mmt/legacy-commons/Helpers/genericHelper';
import { NEED_HELP_PAGE } from '../../../PostSalesConstant';
import ErrorPage from '../../details/pages/ErrorPage';
import {
  getHelpingHandData,
  renderNeedHelpOverlay,
  trackHelpingHandEvent,
} from '../../details/utils/HelpingHandHelper';
import HotelModificationpersuationMessage from '../common/hotelModificationPersuationMessage';
import LoadingView from '../common/LoadingView';
import ModificationFailureOverlay from '../common/ModificaionFailureOverlay';
import { getButtonGradientColor } from '../../../utils/PostSaleUtil';
import ChangeInReviewPopup from '../common/ChangeInReviewPopup';
import { HOTEL_ADD_GUEST_AVAIL_URL } from '../../../utils/NetworkUtils';
import { getStaticData } from '../../../staticData/staticData';
import ModificationCard from '../common/ModificationCard';
import { MODIFICATION_TYPE } from '../../details/HotelBookingDetailsConstant';
import PaymentDetailCard from '../common/PaymentDetailCard';
import ConfirmationPopup from '../common/ConfirmationPopup';
import ModificationOverlay from '../common/ModificationOverlay';
import { CHK_DIFF_PAX, ADD_GUEST_CONTINUE_CONFIRM, RETRY, ROOM_CARD_EDIT_PAX, SMTHNG_WRONG_OVERLAY_PAX, SOLD_OUT_AVAIL_OVERLAY_PAX, SOLD_OUT_OVERLAY_PAX, CONNECT_WITH_US, ADD_GUEST_REVIEW_PRICE_OVERLAY } from '../HotelModificationConstants';
import { LANG_AWARE } from '../../HotelConstants';
import { getModificationConfirmationPopupData, getModificationPolicyTrackerInfo } from '../../hotelUtils';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import BottomSheetWpr from '../../../Common/BottomSheetWpr';
import isEmpty from 'lodash/isEmpty';
export const HOTEL_ADD_GUEST_VIEWROOM = 'HotelAddGuestViewPrice';
class AddGuestReview extends BasePage {
  gradientColor = ['#065af3', '#53b2fe'];
  constructor(props) {
    super(props, HOTEL_ADD_GUEST_VIEWROOM);
    this.fonts = getFont(true);
    this.styles = getStyles(this.fonts);
    this.state = {
      responseJson: null,
      viewState: ViewState.LOADING,
      roomDetails: this.props.hotelBookingDetail.roomReqDetails,
      helpingHandOverlay: false,
      showChangeInReviewPopup: false,
      showConfirmationPopup: false,
      showModificationOverlay: false,
      mamiViewState: '',
      modificationFailureOverlayProps: {},
      confirmationPopupProps: {},
    };
    this.fetchJsonAsync = this.fetchJsonAsync.bind(this);
    this.mimaBlackFooterRef = React.createRef();
    this.showHelpingHand =
      this.props.hotelBookingDetail && this.props.hotelBookingDetail.isHelpingHandEnabled;
    this.helpingHandData = getHelpingHandData(
      this.props.hotelBookingDetail,
      NEED_HELP_PAGE.HOTEL_ADD_GUEST,
      this.trackHelpingHandEvents,
      LANG_AWARE.MODIFICATION
    );
    this.nrTrackingInfo = null;
    this.modificationThankyouPageListener = null;
    this.paymentBackPressListener = null;
  }
  static navigationOptions = {
    header: null,
  };

  componentDidMount() {
    const hotelDetail = this.props.hotelBookingDetail;
    this.fetchJsonAsync(HOTEL_ADD_GUEST_URL);
    this.gradientcolor = getButtonGradientColor(hotelDetail.isCorporateBooking);
    this.trackingPageName = isIntlHotelBooking(hotelDetail?.lobCode)
      ? 'Mob:customer support:Hotel International:AddGuest:ResultsPage_Available'
      : 'Mob:customer support:Hotel Domestic:AddGuest:ResultsPage_Available';
  }

  onBackPress = () => {
    if (
      this.state.viewState !== ViewState.ERROR &&
      this.props.hotelBookingDetail.isHelpingHandEnabled &&
      this.props.showHelpingHand
    ) {
      this.props.showHelpingHand();
    }
    Actions.pop();
    return true;
  };

  editBtnHandler = () => {
    HotelBookingTrackingHelper.trackClickEvent(
      this.trackingPageName,
      ROOM_CARD_EDIT_PAX,
      this.props.hotelBookingDetail
    );
    Actions.pop();
  }

  hideConfirmationPopup = () => {
    this.setState({ showConfirmationPopup: false });
  };

  showConfirmationPopup = () => {
    this.setState({ showConfirmationPopup: true });
  };

  handleReviewConfirm = () => {
    HotelBookingTrackingHelper.trackClickEvent(
      this.trackingPageName,
      ADD_GUEST_CONTINUE_CONFIRM,
      this.props.hotelBookingDetail
    );
    this.showConfirmationPopup();
  }

  onConfirmPopClick = () => {
    const { corporateBooking } = this.state.responseJson;
    this.mimaBlackFooterRef.current && this.mimaBlackFooterRef.current.countinueHandler();
    if (corporateBooking) {
      this.setState({
       showConfirmationPopup: false
     });
   } else {
     this.setState({
       showConfirmationPopup: false
     });
   }
  };

  setMamiViewState = (viewState) => {
    if (viewState === ViewState.NO_INTERNET) {
      this.setState({
        viewState: ViewState.NO_INTERNET,
      });
      return;
    }
    this.setState({
      mamiViewState: viewState,
      showModificationOverlay: viewState != ViewState.DEFAULT,
    });
  };

  setErrorPageData = (resp) => {
    const { errorPageData } = resp || {};
    this.setState({
      responseJson: {
        ...this.state.responseJson,
        errorPageData,
      }
    })
  }

  trackError() {
    this.trackingPageName = isIntlHotelBooking(this.props.hotelBookingDetail?.lobCode)
      ? 'Mob:customer support:Hotel International:AddGuest:ResultsPage_Available:Error'
      : 'Mob:customer support:Hotel Domestic:AddGuest:ResultsPage_Available:Error';
    HotelBookingTrackingHelper.trackErrorEvent(
      this.trackingPageName,
      this.props.hotelBookingDetail,
    );
  }

  handleAddGuestFailure = (unavailabilityInstructions, reviewPriceResponse) => {
    if (!unavailabilityInstructions || unavailabilityInstructions.type == 0) {
      this.setMamiViewState(ViewState.ERROR);
      return;
    }
    let modificationFailureOverlayProps;
    if (unavailabilityInstructions.type == 2) {
      const { noRoomAvailable, checkForDiffPaxSelection } = getStaticData(LANG_AWARE.MODIFICATION);
      const { paxCountText } = this.state.responseJson || {};
      modificationFailureOverlayProps = {
        overlayTitle: noRoomAvailable,
        overlayType: SOLD_OUT_OVERLAY_PAX,
        overlayPrimaryBtnProps: {
          btnTxt: checkForDiffPaxSelection,
          onTap: this.onBackPress,
          clickEvent: CHK_DIFF_PAX,
        },
        overlayEditProps: {
          title: paxCountText,
        }
      }
    }

    this.setState({
      unavailabilityInstructions,
      responseJson: {
        ...this.state.responseJson,
        unavailabilityInstructions,
        success: reviewPriceResponse.success,
        errorPageData: null,
      },
      showChangeInReviewPopup: unavailabilityInstructions.type == 1,
      showModificationFailurePopup: unavailabilityInstructions.type == 2,
      showModificationOverlay: false,
      modificationFailureOverlayProps,
    });
  };

  handleChangeInReviewProceed = () => {
    this.setState({
      showChangeInReviewPopup: false,
      unavailabilityInstructions: null,
    });
    this.mimaBlackFooterRef.current &&
      this.mimaBlackFooterRef.current.fetchJSONAsync(HOTEL_ADD_GUEST_AVAIL_URL);
  };

  hideChangeInReviewPopup = () => {
    this.setState({ showChangeInReviewPopup: false });
  };

  handleTryAgain = () => {
    this.setState({
      viewState: ViewState.LOADING,
      showModificationOverlay: false,
      showModificationFailurePopup: false
    });
    this.fetchJsonAsync(HOTEL_ADD_GUEST_URL);
  }
  handleCloseBottomSheet = () => {
    this.setState({
      showModificationFailurePopup: false
    })
  }
  trackSuccessLoadEvent(hotelSearchPrice, hotelDetailResponse) {
    this.trackingPageName = isIntlHotelBooking(hotelDetailResponse?.lobCode)
    ? 'Mob:customer support:Hotel International:AddGuest:ResultsPage_Available'
    : 'Mob:customer support:Hotel Domestic:AddGuest:ResultsPage_Available';
    this.nrTrackingInfo = getModificationPolicyTrackerInfo(hotelSearchPrice)
    HotelBookingTrackingHelper.trackHotelAddGuestLoadEvent(
      this.trackingPageName,
      hotelDetailResponse,
      hotelSearchPrice,
      true,
      this.nrTrackingInfo
    );
  }

  renderPage() {
    const searchResponse = this.state.responseJson;
    return this.getSuccessView(searchResponse);
  }

  getSuccessView(searchResponse) {
    const { paymentBreakup, corporateBooking, persuationMessage } = searchResponse;
    const { reviewChanges } = getStaticData(true);
    const { autoOpenOverlay } = this.props;
    return (
      <View style={{ width: '100%', flex: 1 }}>
        <SimpleHeader title={reviewChanges} iconPress={this.onBackPress} />
        <ScrollView style={this.styles.container}>
          <ModificationCard
            response={searchResponse}
            modificationType={MODIFICATION_TYPE.ADD_GUEST}
            editBtnHandler={this.editBtnHandler}
          />
          {persuationMessage && (
            <HotelModificationpersuationMessage persuationdata={persuationMessage} />
          )}
          <PaymentDetailCard paymentBreakup={paymentBreakup} />
        </ScrollView>
        <MAMIBlackFooter
          ref={this.mimaBlackFooterRef}
          response={searchResponse}
          roomDetails={this.state.roomDetails}
          hotelDetailResponse={this.props.hotelBookingDetail}
          action="ADDGUEST"
          showHelpingHand={this.showHelpingHand}
          helpingHandLabel={this.helpingHandData && this.helpingHandData.connectWithUsText}
          helpingHandClickHandler={() => this.setHelpingHandOverlay(true)}
          cnacelHandler={this.handlecancelClick}
          handleDateChangeFailure={this.handleAddGuestFailure}
          handleClick={this.handleReviewConfirm}
          paymentBreakup={paymentBreakup}
          handleViewState={this.setMamiViewState}
          setErrorPageData={this.setErrorPageData}
          closeModificationOverlay={this.handleModificationOverlay}
        />
        {this.showHelpingHand &&
          this.state.helpingHandOverlay === true &&
          renderNeedHelpOverlay(this.helpingHandData, this.setHelpingHandOverlay)}
        {this.state.showChangeInReviewPopup && this.state.unavailabilityInstructions && (
          <ChangeInReviewPopup
            {...this.state.unavailabilityInstructions}
            bookingId={this.props.bookingid}
            dismiss={this.hideChangeInReviewPopup}
            handleCTAClick={this.handleChangeInReviewProceed}
            overlayType={ADD_GUEST_REVIEW_PRICE_OVERLAY}
            hotelDetailsResponse={this.props.hotelBookingDetail}
            pageName={this.trackingPageName}
          />
        )}
        {this.state.showConfirmationPopup && (
          <BottomSheetWpr
              visible={this.state.showConfirmationPopup}
              containerStyles={this.styles.bottomSheetWprStyle}
              setVisible={this.hideConfirmationPopup}
              children={
                <ConfirmationPopup
                  pageName={this.trackingPageName}
                  dismiss={this.hideConfirmationPopup}
                  corporateBooking={corporateBooking}
                  modificationType={MODIFICATION_TYPE.ADD_GUEST}
                  hotelDetailsResponse={this.props.hotelBookingDetail}
                  handleCTAClick={this.onConfirmPopClick}
                  {...this.state.confirmationPopupProps}
              />
                }
            />
        )}
        {this.state.showModificationOverlay && (
          <ModificationOverlay
            isLoading={this.state.mamiViewState === ViewState.LOADING}
            isSuccess={this.state.mamiViewState === ViewState.COMMIT_SUCCESS}
            isError={this.state.mamiViewState === ViewState.ERROR}
            isForceClose={this.state.mamiViewState === ViewState.FORCE_CLOSE}
            modificationType={MODIFICATION_TYPE.ADD_GUEST}
            response={this.state.responseJson}
            handleTryAgain={this.handleTryAgain}
            showHelpingHand={this.showHelpingHand}
            helpingHandClickHandler={this.showHelpingHandInModificationOverlay}
            helpingHandData={this.helpingHandData}
            hotelDetailsResponse={this.props.hotelBookingDetail}
            pageName={this.trackingPageName}
            nrTrackingInfo={this.nrTrackingInfo}
          />
        )}
        { this.state.showModificationFailurePopup && (
          <BottomSheetWpr
            visible={this.state.showModificationFailurePopup}
            containerStyles={this.styles.bottomSheetWprStyle}
            setVisible={()=>{}}
            children={
              <ModificationFailureOverlay
              {...this.state.unavailabilityInstructions}
              response={this.state.responseJson}
              {...this.state.modificationFailureOverlayProps}
              hotelDetailsResponse={this.props.hotelBookingDetail}
              pageName={this.trackingPageName}
              handleTryAgain={this.handleTryAgain}
              dismiss={this.handleCloseBottomSheet}
            />
          }
          />
        )}
      </View>
    );
  }

  handlecancelClick = () => {
    const action = {
      actionFamily: 'CancelFullBooking',
    };
    handleClickEvent(this.props.card, action, this.props, '', LANG_AWARE.MODIFICATION);
  };

  componentWillMount() {
    if (DeviceEventEmitter) {
      DeviceEventEmitter.removeAllListeners('hotel_modification_thankyou_page');
      this.modificationThankyouPageListener = DeviceEventEmitter.addListener(
        'hotel_modification_thankyou_page',
        this.openModificationThankYouPage,
      );
      this.paymentBackPressListener = DeviceEventEmitter.addListener('hotel_modification_back_press', this.handlePaymentBackPress);
    }
    BackHandler.addEventListener('hardwareBackPress', this.onBackPress);
  }

  handlePaymentBackPress = () => {
    this.setMamiViewState(ViewState.FORCE_CLOSE)
    setTimeout(()=>{
      this.setMamiViewState(ViewState.DEFAULT)
    }, 1000);
  }

  render() {
    return (
      <View
        style={{
          width: '100%',
          height: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {this.renderStateWise()}
      </View>
    );
  }

  componentWillUnmount() {
    if (this.modificationThankyouPageListener) {
      this.modificationThankyouPageListener.remove();
    }
    if (this.paymentBackPressListener) {
      this.modificationThankyouPageListener.remove();
    }
    BackHandler.removeEventListener('hardwareBackPress', this.onBackPress);
  }

  openModificationThankYouPage = (data) => {
    if (data && data.Payment_Status === 'PAYMENT_SUCCESS') {
      invalidateTrips();
      this.setMamiViewState(ViewState.COMMIT_SUCCESS);
    } else {
      this.setMamiViewState(ViewState.ERROR);
    }
  };

  trackHelpingHandEvents = (type, page, click, data) => {
    trackHelpingHandEvent(
      type,
      page,
      click,
      data,
      this.trackingPageName,
      this.props.hotelBookingDetail,
    );
  };

  setHelpingHandOverlay = (value) => {
    this.setState({
      helpingHandOverlay: value,
    });
  };

  showHelpingHandInModificationOverlay = () => {
    this.setState({
      showModificationOverlay: false,
    })
    this.setHelpingHandOverlay(true);
  }

  renderStateWise() {
    if (this.state.viewState === ViewState.LOADING) {
      return this.renderProgressView();
    } else if (this.state.viewState === ViewState.ERROR) {
      this.trackError();
      return this.renderErrorView();
    } else if (this.state.viewState === ViewState.NO_INTERNET) {
      return this.renderNoNetworkView();
    }
    return this.renderPage();
  }

  renderProgressView = () => <LoadingView />;
  renderNoNetworkView = () => {
    const { backText } = getStaticData(LANG_AWARE.MODIFICATION);
    return (
      <View style={[{ width: '100%' }, { flex: 1 }]}>
        <SimpleHeader title={backText} iconPress={this.onBackPress} />
        <NoInternetView
          gradientColor={this.gradientcolor}
          onRetry={() => this.fetchJsonAsync(HOTEL_ADD_GUEST_URL)}
        />
      </View>
    )
  };

  onRetryErrorPage = () => {
    HotelBookingTrackingHelper.trackClickEvent(this.trackingPageName, RETRY, this.props.hotelBookingDetail);
    this.setState({ viewState: ViewState.LOADING });
    this.fetchJsonAsync(HOTEL_ADD_GUEST_URL);
  }

  onHelpingHandClickErrorPage = () => {
    HotelBookingTrackingHelper.trackClickEvent(this.trackingPageName, CONNECT_WITH_US, this.props.hotelBookingDetail);
    this.setHelpingHandOverlay(true);
  }

  renderErrorView = () => {
    const { backText } = getStaticData(LANG_AWARE.MODIFICATION);
    return (
      <View style={[{ width: '100%' }, { flex: 1 }]}>
        <SimpleHeader title={backText} iconPress={this.onBackPress} />
        <ErrorPage
          showRetry
          buttonGradient={this.gradientcolor}
          onRetry={this.onRetryErrorPage}
          showHelpingHand={this.showHelpingHand}
          helpingHandLabel={this.helpingHandData && this.helpingHandData.connectWithUsText}
          helpingHandClickHandler={this.onHelpingHandClickErrorPage}
          isCorporateBooking={this.props.hotelBookingDetail.isCorporateBooking}
          langAware={LANG_AWARE.MODIFICATION}
        />
        {this.showHelpingHand &&
          this.state.helpingHandOverlay === true &&
          renderNeedHelpOverlay(this.helpingHandData, this.setHelpingHandOverlay)}
      </View>
    )
  };

  async fetchJsonAsync(url, newRoomReqDetails = []) {
    const hotelDetail = this.props.hotelBookingDetail;
    const {
      appVersion,
      exprimentData,
      deviceId,
      visitorId,
    } = await HotelBookingModule.getHotelDateChangeStaticData();
    const { mmtAuth, loggingTrackingInfo } = await HotelBookingModule.getRequestHeader(
      hotelDetail.bookingID,
      'Hotel_AddGuest_ViewRoom',
    );
    try {
      const request = getModificationRequest(
        appVersion,
        exprimentData,
        deviceId,
        visitorId,
        fillCheckInCheckOutData(hotelDetail.hotelBookingInfo.checkInDate, 'YYYY-MM-DD'),
        fillCheckInCheckOutData(hotelDetail.hotelBookingInfo.checkOutDate, 'YYYY-MM-DD'),
        hotelDetail.bookingID,
        true,
        hotelDetail.isCorporateBooking,
      );
      if(!isEmpty(newRoomReqDetails)){
        request.roomDetails = newRoomReqDetails;
        this.setState({ roomDetails: newRoomReqDetails });
      } else {
        request.roomDetails = this.state.roomDetails;
      }
      const { tryAgainText, noRoomAvailable, errorText: { soomethingWentWrongOnlyError }, checkForDiffPaxSelection } = getStaticData(LANG_AWARE.MODIFICATION);

      const response = await fetch(url, {
        headers: await getCommonHeaders(mmtAuth, loggingTrackingInfo),
        body: JSON.stringify(request),
        method: 'POST',
      });
      if (response.ok) {
        const body = await response.json();
        if (body.unavailabilityInstructions) {
          let modificationFailureOverlayProps;
          const confirmationPopupProps = {
            ...getModificationConfirmationPopupData(body),
          }
          if (body.unavailabilityInstructions.type == 2) {
            modificationFailureOverlayProps = {
              overlayTitle: noRoomAvailable,
              overlayType: SOLD_OUT_AVAIL_OVERLAY_PAX,
              overlayPrimaryBtnProps: {
                btnTxt: checkForDiffPaxSelection,
                onTap: this.onBackPress,
                clickEvent: CHK_DIFF_PAX,
              },
              overlayEditProps: {
                title: body.paxCountText,
              }
            }
          } else {
            modificationFailureOverlayProps = {
              overlayTitle: soomethingWentWrongOnlyError,
              overlayType: SMTHNG_WRONG_OVERLAY_PAX,
              overlayPrimaryBtnProps: {
                btnTxt: tryAgainText,
                onTap: this.handleTryAgain,
                clickEvent: RETRY,
              },
              overlayEditProps: null,
            }
          }

          this.setState({
            unavailabilityInstructions: body.unavailabilityInstructions,
            showModificationFailurePopup: true,
            responseJson: body,
            viewState: ViewState.SHOW_DETAIL,
            modificationFailureOverlayProps,
            confirmationPopupProps,
          });
        } else if (body.success) {
          const confirmationPopupProps = {
            ...getModificationConfirmationPopupData(body),
          }
          this.setState({
            responseJson: body,
            viewState: ViewState.SHOW_DETAIL,
            confirmationPopupProps,
          });
          this.trackSuccessLoadEvent(body, this.props.hotelBookingDetail);
        } else {
          this.setState({ viewState: ViewState.ERROR });
        }
      } else {
        this.setState({ viewState: ViewState.ERROR });
      }
    } catch (error) {
      this.setState({ viewState: ViewState.ERROR });
    }
  }
}

export default AddGuestReview;
