
const ViewStates = {
  LOADING: 'loading',
  DEFAULT: 'default',
  FETCH_DATA: 'fetchData',
  ERROR: 'error',
  NO_INTERNET: 'noInternet',
  FAILURE: 'failure'
};
export default ViewStates;
