import { isEmpty } from "lodash";
import { isEmptyArray, isNullOrEmpty } from "../../Common/commonUtil";
import { getStaticData } from "../../staticData/staticData";
import { showHotelImage } from "../details/utils/HotelBookingDetailUtil";
import { CANCELLATION_REASON_TYPES } from "../HotelConstants";
import { CALENDAR_BLUE, HOTEL_DEFAULT_ICON, USER_ICON } from "../HotelImageConstants";
import { CANCELLATION_STEPS } from './HotelCancellationConstants';

export const getHotelDetailCardData = (response) => {
  const hotelDetailList = response.hotelBookingInfo && !isEmptyArray(response.hotelBookingInfo.hotelDetailsList) && response.hotelBookingInfo.hotelDetailsList[0];
  const { commonFields } = response.hotelBookingInfo;
  if (hotelDetailList) {
      return ({
          hotelName: hotelDetailList.name,
          destination: hotelDetailList.city,
          checkinCheckoutRoomsText: commonFields && commonFields.checkinCheckoutRoomsText,
          hotelImg: isNullOrEmpty(hotelDetailList.hotelImageURLList) &&
          isNullOrEmpty(hotelDetailList.imageURL) ? HOTEL_DEFAULT_ICON : {uri: showHotelImage(hotelDetailList)}
      })
  }
}

export const getHotelModalCardData = (response, cancellationReasonItem) => {
  const { hotelBookingInfo } = response || {};
  const { hotelDetailsList, commonFields, totalNumberOfRooms, totalGuestBreakupText, totalNumberOfNights, checkinDetails, checkoutDetails } = hotelBookingInfo || {};
  const hotelDetailList = !isEmpty(hotelDetailsList) ? hotelDetailsList[0] : undefined;
  const { name, city, hotelImageURLList, imageURL } = hotelDetailList || {}
  const { checkinCheckoutText: checkInOutText, stayText }= commonFields;
  const { dateText: checkInDateText }= checkinDetails || {};
  const { dateText: checkoutDateText }= checkoutDetails || {};

  const {
    hotelMainCardText: {
      additionalText: {
        roomText,
        roomsText,
        nightText,
        nightsText
      }
    }
  } = getStaticData();

  let roomTextData;
  let checkinCheckoutText;
  let icon;
  const totalRoomsText = `${totalNumberOfRooms} ${totalNumberOfRooms > 1 ? roomsText : roomText}`;
  
  switch(cancellationReasonItem.id) {
    case CANCELLATION_REASON_TYPES.DATE_CHANGE:
      roomTextData = {
        title: totalRoomsText,
        subtitle: totalGuestBreakupText
      }
      checkinCheckoutText = `${checkInOutText} (${totalNumberOfNights} ${totalNumberOfNights > 1 ? nightsText : nightText})`;
      icon= CALENDAR_BLUE;
      break;
    case CANCELLATION_REASON_TYPES.ADD_GUEST:
      roomTextData = {
        title: `${checkInDateText} - ${checkoutDateText}`,
        subtitle: totalRoomsText
      }
      checkinCheckoutText= totalGuestBreakupText;
      icon= USER_ICON;
      break;
    default:
      break;
  }
  if (hotelDetailList) {
      return ({
          hotelNameHeading: name + " - "+ city,
          checkinCheckoutText,
          hotelImg: !isEmpty(hotelImageURLList) && !isEmpty(imageURL) ? {uri: showHotelImage(hotelDetailList)} : HOTEL_DEFAULT_ICON,
          roomText: roomTextData,
          icon
      })
  }
}

export const getCancellationSteps = (hotelBookingResponse, cancellationResponse) => {
  let cancellationStepsList = [CANCELLATION_STEPS.VIEW_REFUND];
  if (Math.round(cancellationResponse.totalRefundAmount, 0) > 0) {
    cancellationStepsList = [...cancellationStepsList, CANCELLATION_STEPS.REFUND_MODE];
  }
  if (cancellationResponse && cancellationResponse.showCancellationReason && cancellationResponse.cancellationReasonData) {
    cancellationStepsList = [CANCELLATION_STEPS.CANCELLATION_REASON,...cancellationStepsList];
  }
  return cancellationStepsList;
}

export const getTCSPriceBreakup = (cancellationResponse)=> {
  const {breakups} = cancellationResponse || {};
  const {tcsNote} = breakups || {};
  const {priceBreakup} = tcsNote || {};
  return priceBreakup
}