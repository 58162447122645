import React from 'react';
import {AppState, Image, ScrollView, Text, View} from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import styles from './QRTicketCardCss';
import Fecha from 'fecha';
import {handleClickEvent} from '../MetroBookingDetailUtil';
import MetroConstant from '../MetroConstant';
import {addRupeeSign} from '../../Common/commonUtil';
import {RIGHT_ARROW} from '../MetroImageConstant';

class TripInfo extends React.Component{
    constructor(props){
        super(props);
        this.state={
            activeTickIndex:0,
            activeTab:0,
            isAppActive: AppState.currentState === 'active'
        }
        this.activeTickHandler = this.activeTickHandler.bind(this);
        this.handleTab = this.handleTab.bind(this);
        this.ticket = {};
    }

    componentDidMount() {
        AppState.addEventListener('change', this.handleAppStateChange);
    }

    activeTickHandler = (index) => {
        this.setState({activeTickIndex:index});
        let action = {
            actionFamily: MetroConstant.JOURNEY_TYPE
        }
        handleClickEvent(this.props.card, action, this.props.response, this.props.pageName);
    }

    handleTab(index){
        this.setState({
            activeTab: index
        });
        let action = {
            actionFamily: MetroConstant.PASSENGER_TAB
        }
        handleClickEvent(this.props.card, action, this.props.response, this.props.pageName);
    }
    handleAppStateChange = () => {
        this.setState({
            isAppActive: AppState.currentState === 'active'
        });
    };

    render(){
        const oneWayTrip = this.props.response.bookingInfo.serviceType === "FORWARD";
        const ticketList = this.props.response.bookingInfo.ticketInfoList;
        const totalTicketCount = this.props.response.bookingInfo.ticketInfoList.length;
        const cancelledMsg = this.props.response.cancelledPaxInfo === undefined ? '' : this.props.response.cancelledPaxInfo;
        const switchOption = oneWayTrip ? ["FORWARD"] : ["FORWARD", "RETURN"];
        const tripType = oneWayTrip ? "One Way" : "Return";
        const ticketCount = oneWayTrip ? totalTicketCount : totalTicketCount / 2;

        let passengerData = [];
        ticketList.map((item,index)=> {
            if(oneWayTrip)
                passengerData.push(item)
            else {
                if (index % 2 === 0)
                    passengerData.push(item)
            }
        });

        this.passengerData = passengerData;
        this.ticket = this.getTicket(ticketList);
        const expiryDate = Fecha.parse(this.ticket.expiryDateTime, 'DD-MMM-YYYY');
        const expiryDateString = `${Fecha.format(expiryDate, 'DD MMM')}`

        return(
            <ScrollView stickyHeaderIndices={[0]} style={styles.cardWrapper}>
                <View style={{elevation:2,zIndex:5}}>
                </View>
                <View style={{elevation:2,zIndex:10}}>
                </View>

                <View style={[styles.Card,AtomicCss.marginTop2]}>
                    <View style={[AtomicCss.flexRow,AtomicCss.spaceBetween]}>
                        <View>
                            <View style={AtomicCss.flexRow}>
                                <Text style={[AtomicCss.lightFont,AtomicCss.blackText,AtomicCss.font22,AtomicCss.marginBottom5]}>{this.props.response.bookingInfo.source}</Text>
                                <Image style={styles.arrowStyle} source={RIGHT_ARROW}/>
                                <Text style={[AtomicCss.lightFont,AtomicCss.blackText,AtomicCss.font22]}>{this.props.response.bookingInfo.destination}</Text>
                            </View>
                            <View>
                                <Text style={[AtomicCss.font12,AtomicCss.greyText]}> <Text style={AtomicCss.regularFont}>{expiryDateString} • {tripType} • </Text> <Text style={AtomicCss.boldFont}>{ticketCount} {ticketCount===1 ? 'Passenger': 'Passengers'}</Text></Text>
                            </View>
                            <View>
                            {cancelledMsg.length>0 && <View>
                                <Text style={[AtomicCss.font12,AtomicCss.orangeText]}> <Text style={AtomicCss.regularFont}>• {cancelledMsg}</Text></Text>
                            </View>
                            }
                            </View>
                        </View>

                        <View style={AtomicCss.justifyCenter}>
                            <Text style={[AtomicCss.blackFont, AtomicCss.font22, AtomicCss.marginBottom5, styles.textRight]}>{addRupeeSign(this.props.response.paymentSummary.totalAmountPaid)}</Text>
                        </View>
                    </View>
                </View>
            </ScrollView>
        )
    }
    getTicket(ticketList) {
        let ticket = null;

        if(this.state.activeTickIndex===0){
            ticket = ticketList[this.state.activeTab]
        }
        else {
            let currTicket = ticketList[this.state.activeTab]
            ticketList.forEach((tick, index) => {
                if (tick.rjtId === currTicket.rjtId && tick.ticketId != currTicket.ticketId && tick.ticketEntryExitType === "RJT_RETURN") {
                    ticket = tick;
                }
            });
        }
        return ticket
    }

    handleClick = (action) => {
        handleClickEvent(this.props.card, action, this.props.response, this.props.pageName);
    };
}
export default TripInfo;

