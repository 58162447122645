import { isNullOrEmpty } from "core-ui-lib/helper/Object/ObjectFunctions";
import FlightBookingModule from '@mmt/legacy-commons/Native/FlightBookingModule';
import {
  LOBNAMES,
} from '../../../../PostSalesConstant';
import {
  FLIGHT_CANCELLATION_PREVIEW_URL,
  FLIGHT_CANCELLATION_DONE_V2_URL,
  getCommonHeaders,
  MIMA_BASE_URL, GET_ERROR_DATA_V2,
  FLIGHT_RELEASE_HOLD_URL,
} from "../../../../utils/NetworkUtils";
import { THANKYOU_PAGE_ERROR } from "../constant";

import { fetchErrorDataAdapter } from './dataAdapter';
import isEmpty from 'lodash/isEmpty';

export const fetchFlightCancellationDetails = async (bookingId, requestBody) => {

  try {
    const userInfo = await FlightBookingModule.getRequestHeader(
      bookingId,
      'FLIGHT_CANCELLATION_REVIEW',
    );
    const response = await fetch(FLIGHT_CANCELLATION_PREVIEW_URL, {
      method: 'POST',
      headers: await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo, {
        psLob: LOBNAMES.FLIGHT,
      }),
      body: JSON.stringify(requestBody),
    });
    if (response && response.ok){
      const body = await response.json();
      if (!isNullOrEmpty(body.errorPageData) || body.flightDetails === null){
        return {
          error: true,
          exception: false,
          cancellationResponse: body,
          isLoaded: true,
          hasErrorData: true
        }
      }
      else if (isNullOrEmpty(body.businessErrorObj)){
        return {
          error: false,
          exception: false,
          cancellationResponse: body,
          isLoaded: true,
          success: true
        }
      }
      else {
        return {
          error: true,
          exception: false,
          cancellationResponse: body,
          isLoaded: true,
          hasErrorData: false
        }
      }
    }
    else {
      return {
        error: true,
        exception: true,
        isLoaded: false,
        success: false
      }
    }
  } catch (e) {
    return {
      error: true,
      errorMsg: e.message,
      exception: true
    };
  }
};

export const fetchFlightCancellationDone = async (bookingId, requestBody) => {
  try {
    const userInfo = await FlightBookingModule.getRequestHeader(
      bookingId,
      'FLIGHT_CANCELLATION_DONE',
    );
    const isHoldBooking = requestBody.isHoldBooking;
    const cancellationDoneUrl = isHoldBooking ? FLIGHT_RELEASE_HOLD_URL : FLIGHT_CANCELLATION_DONE_V2_URL ;
    const response = await fetch(cancellationDoneUrl, {
      method: 'POST',
      headers: await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo, {
        psLob: LOBNAMES.FLIGHT,
      }),
      body: JSON.stringify(requestBody),
    });

    if (response && response.ok) {
      const body = await response.json();
      if (body && body.errorPageData) {
        return { rawResponse: body, errorType: THANKYOU_PAGE_ERROR.OPEN_ERROR_PAGE, error: true }
      } else if ((isNullOrEmpty(body.businessErrorDescription) && body.cancellationStatus === 0) || body.lastHourCancellationMessage) {
        return { rawResponse: body, error: false }
      } else {
        return { rawResponse: body, errorType: THANKYOU_PAGE_ERROR.BUSSINESS_ERROR, error: true }
      }
    } else {
      return { rawResponse: null, errorType: THANKYOU_PAGE_ERROR.API_ERROR, error: true }
    }

  } catch (e) {
    console.log("catch error in fetchCancellationDoneAPI: ", e);
    return { rawResponse: null, errorType: THANKYOU_PAGE_ERROR.API_ERROR, error: true }

  }

}

const getUrl = (reqUrl) => {
  let _url = reqUrl;
  if (_url.startsWith('/')) {
    _url = `${MIMA_BASE_URL}${_url.substr(1)}`;
  }
  return _url;
}

const getHeaders = async (bookingId, uniqueId, psLob) => {
  let headers = {};
  const userInfo = await FlightBookingModule.getRequestHeader(
    bookingId ? bookingId : '',
    uniqueId,
  );
  headers = await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo, { psLob });
  return headers;
};

export const fetchLastMinuteErrorData = async (bookingId, data=[], successPnr=[], isCorporateBooking = false) => {

  const modifiedErrorData =
    !isEmpty(data) &&
    data.map(({ errorCode, errorMessage, errorDescription, pnrNo }) => ({
        ec: errorCode,
        em: errorMessage,
        ed: errorDescription,
        pnrNo,
    }));

  const url = getUrl(GET_ERROR_DATA_V2);
  const headers = await getHeaders(bookingId, 'FLIGHT_LAST_MINUTE_CANCELLATION', null)

  const requestOptions = {
    method: "POST",
    body: JSON.stringify({
      bookingId: bookingId,
      errorDetails: modifiedErrorData || [],
      pageId: isCorporateBooking
        ? "TENTATIVE_CANCELLATION_CORP"
        : "TENTATIVE_CANCELLATION",
        successPnr:successPnr.join(',')
    }),
    headers,
  };
  const response = await fetch(url,requestOptions);
  return await fetchErrorDataAdapter(response);
}

export const fetchSpecialClaimPreview = async (bookingId, requestBody) => {

  try {
    const userInfo = await FlightBookingModule.getRequestHeader(
      bookingId,
      'CANCELLATION_DETAIL',
    );
    const response = await fetch(FLIGHT_CANCELLATION_PREVIEW_URL, {
      method: 'POST',
      headers: await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo, {
        psLob: LOBNAMES.FLIGHT,
      }),
      body: JSON.stringify(requestBody),
    });
    if (response && response.ok){
      const body = await response.json();
      if (!isNullOrEmpty(body.errorPageData) || body.flightDetails === null){
        return {
          error: true,
          response: body,
        }
      }
      else if (isNullOrEmpty(body.businessErrorObj)){
        return {
          error: false,
          response: body
        }
      }
      else {
        return {
          error: true,
          response: body
        }
      }
    }
    else {
      return {
        error: true,
      }
    }
  } catch (e) {
    return {
      error: true
    };
  }

}
