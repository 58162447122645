import React, {Component} from 'react';
import { View, Text, TouchableOpacity, Image, StyleSheet,
  DeviceEventEmitter,NativeModules, Platform} from 'react-native';
import AtomicCss from '../../../../bus/commonStyles/AtomicCss';
import SpecialClaimAddAttachmentModule from '@mmt/legacy-commons/Native/SpecialClaimAddAttachmentModule';
import {colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';
import {tick, deleteIcon} from "../../../CabImageConstants";

class UploadCard extends Component {

  constructor(props) {
    super(props);
    this.data = new FormData();
  }

  componentWillMount() {
    DeviceEventEmitter.addListener('special_claim_review_page', this.fetchAttachment.bind(this));
  }

  fetchAttachment(data) {
    const map = this.props.attachmentList;
    map.set(data.filePath, data);
    this.props.updateAttachmentList(map);
  }

  onAddAttachment = () => {
    SpecialClaimAddAttachmentModule.onButtonClicked();
  };

  onDelAttachment = (key) => {
    const map = this.props.attachmentList;
    map.delete(key);
    this.props.updateAttachmentList(map);
  };

  getAttachmentHtmlView() {
    const attachmentHtmlView = [];

    this.props.attachmentList.forEach(function (obj) {
      attachmentHtmlView.push(<View style={styles.attachedItem}>
        <View style={[styles.flexRow, styles.alignCenter]}>
          <View style={styles.attachedImgWrapper}>
            <Image
              style={styles.attachedImg}
              source={{uri: `file://${obj.filePath}`}}
            />
          </View>
          <Text style={styles.attachedImgName}>{obj.fileName}</Text>
          <Image style={styles.greenTick} source={tick} />
        </View>
        <TouchableOpacity
          onPress={() => this.onDelAttachment(obj.filePath)}
          style={styles.imgDeleteBtn}
        >
          <Image style={styles.deleteIcon} source={deleteIcon} />
        </TouchableOpacity>
      </View>);
    }, this);
    return attachmentHtmlView;
  }

  render () {
    return (
            <View style={[AtomicCss.whiteCardSection, styles.sectionCard, AtomicCss.marginBottom10]}>
            <Text style={[AtomicCss.defaultText, AtomicCss.font16, AtomicCss.boldFont, AtomicCss.marginBottom15]}>
              {this.props.uploadTitle}
            </Text>
              <View>
                {this.getAttachmentHtmlView()}
              </View>
              {
                (Platform.OS === 'web') ? 
                ( <TouchableOpacity onPress={this.fetchAttachment.bind(this)}>
                <NativeModules.FileUploaderModule.FileUploader
                   onFileChanged={this.props.updateAttachmentList}   
                   attachmentLst={this.props.attachmentList}
                   text={this.props.uploadDescription}
                   />
                </TouchableOpacity>) : 
                ( <TouchableOpacity onPress={this.onAddAttachment}>
                  <View style={styles.uploadSection}>
                    <Text style={[AtomicCss.font26, AtomicCss.lightText]}>+</Text>
                    <Text style={[AtomicCss.font12, AtomicCss.lightText, AtomicCss.boldFont, AtomicCss.marginBottom15]}>
                      {this.props.uploadDescription}
                    </Text>
                  </View>
                </TouchableOpacity>)
              }
            </View>
          );
  };
}

const styles = StyleSheet.create({
  sectionCard:{padding: 16},
  uploadSection: {borderWidth: 1, borderColor: '#e7e7e7', borderRadius: 4, alignItems: 'center', justifyContent: 'center', height: 66},
  attachedItem: {
    ...getPlatformElevation(2),
    backgroundColor: colors.white,
    padding: 4,
    borderRadius: 4,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 5
  },
  attachedImgWrapper: {
    overflow: 'hidden',
    borderRadius: 2,
    marginRight: 8
  },
  attachedImg: {
    height: 16,
    width: 16
  },
  attachedImgName: {
    fontSize: 12,
    fontFamily: fonts.regular,
    color: colors.grey,
    marginRight: 4
  },
  greenTick: {
    width: 12,
    height: 9,
    marginTop: 3
  },
  deleteIcon: {
    height: 24,
    width: 24
  },
  paraText: {
    fontSize: 14,
    color: colors.black28,
    fontFamily: fonts.regular,
    marginBottom: 5
  },
  flexRow: {
    flexDirection: 'row'
  },
  spaceBetween: {
    justifyContent: 'space-between'
  }
});

export default UploadCard;
