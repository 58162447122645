import { StyleSheet } from "react-native";
import {Fonts, Theme} from "apps/post-sales/src/cab/types";

const getStyle = (fonts : Fonts, psTheme : Theme) => {
    return StyleSheet.create({
      container: {
        marginHorizontal: 10,
        borderRadius: 16,
        backgroundColor: psTheme.color.white,
        marginVertical: 5,
      },
      tagWrapper: {
        borderWidth: 1,
        borderRadius: 16,
        paddingHorizontal: 8,
        paddingTop: 2,
        paddingBottom: 4,
        flexDirection: 'row',
        alignItems: 'center',
      },
      pickupTimeWrap: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 14,
        borderBottomWidth: 1,
        borderBottomColor: psTheme.color.lightGray,
        paddingBottom: 12,
      },
      pickupTimeText: {
        fontSize: fonts.fontSize.font12,
        color: psTheme.color.black74,
        ...fonts.boldFontFamily,
        marginBottom: 3,
      },
      dateTime: {
        fontSize: fonts.fontSize.font16,
        color: psTheme.color.defaultTextColor,
        ...fonts.boldFontFamily,
      },
      assuranceImg: {
        width: 15,
        height: 15,
        marginRight: 5,
      },
      rescheduleText: {
        fontSize: fonts.fontSize.font12,
        ...fonts.boldFontFamily,
      },
      bookingIdText: {
        fontSize: fonts.fontSize.font12,
        color: psTheme.color.defaultTextColor,
        ...fonts.regularFontFamily,
      },
      bookingId: {
        fontSize: fonts.fontSize.font12,
        color: psTheme.color.black,
        ...fonts.boldFontFamily,
      },
      middleSection: {
        flexDirection: 'row',
        paddingVertical: 15,
        paddingHorizontal: 15,
        justifyContent: 'space-between',
      },
      viewDetailsText: {
        color: psTheme.color.azure,
        fontSize: fonts.fontSize.font12,
        ...fonts.boldFontFamily,
      },
    });
  };

  export default getStyle;