import React from 'react';
import { Text, View, StyleSheet } from 'react-native';
import { useTheme } from '../../../../../theme/theme.context';
import { getFont } from '../../../../../PostSalesStyles';
import { handleClickEvent } from '../../../../utils/CabBookingDetailUtil';
import { PAGE_NAME } from '../../../../CabBookingDetailsConstant';
import { getActionButton } from '../../../../../Common/commonUtil';
import { Fonts, BookingDetailNavigationProps, Theme } from 'apps/post-sales/src/cab/types';

const BookingDetailNavigation: React.FC<BookingDetailNavigationProps> = ({ cardData, response }) => {
  const { subHeading } = cardData || {};
  const { psTheme } = useTheme();
  const { fsStyle, ...fonts } = getFont();
  const styles = getStyle(fonts, psTheme);
  const handleClick = (action: any) => {
    handleClickEvent(cardData, action, response, PAGE_NAME.CAB_RESCHEDULE_PAGE);
  };
  return (
    <View style={styles.contentWrapper}>
      <View style={styles.subHeadingWrap}>
        {!!subHeading && <Text style={styles.subHeading}>{subHeading}</Text>}
      </View>
      {getActionButton(cardData, response, handleClick, {}, true, false, styles.customStyle)}
    </View>
  );
};

const getStyle = (fonts: Fonts, psTheme: Theme) => {
  return StyleSheet.create({
    contentWrapper: {
      marginHorizontal: 10,
      marginVertical: 5,
      borderRadius: 16,
      paddingHorizontal: 14,
      backgroundColor: psTheme.color.white,
    },
    subHeadingWrap: {
      borderBottomWidth: 1,
      paddingVertical: 10,
      borderBottomColor: psTheme.color.lightGrey3,
    },
    subHeading: {
      ...fonts.regularFontFamily,
      fontSize: fonts.fontSize.font12,
      color: psTheme.color.defaultTextColor,
    },
    customStyle: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  });
};

export default BookingDetailNavigation;