import React, { useState, useEffect, useRef } from 'react';
import { View, Text } from 'react-native';
import { openErrorPage } from '../../../../../../utils/PostSaleUtil';
import useApi from '../../../../../../Common/useApi';
import createStyles from './styles';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import Timer from './Timer';
import TripCard from './TripCard';
import { getFont } from '../../../../../../PostSalesStyles';
import { isEmptyArray } from '../../../../../../Common/commonUtil';
import { GET_ERROR_DATA } from '../../../../../../utils/NetworkUtils';
import DateChangeTrackingHelper from '../../../analytics/DateChangeTrackingHelper';
import { getOverlayJourneyInfo, initFirebaseDatabase } from '../../../utils';
import {
  getTimeOutReqBody,
  isUndoWCDone,
  isUndoWCPending,
  getUndoWCValidationFailureForAll,
} from '../../../helper';
import { STATUS } from '../../../constants';
import { fetchUndoWCFailure } from '../data/api';
import { useTheme } from '../../../../../../theme/theme.context';
import BaseButton, { BUTTON_VARIANT } from 'apps/post-sales/src/Common/BaseButton';
import { getStaticData } from 'apps/post-sales/src/staticData/staticData';
import Actions from 'apps/post-sales/src/navigation/postSalesNavigation';
import { LOBNAMES } from 'apps/post-sales/src/PostSalesConstant';
import { FLIGHT_THROTTLE_OBJECT } from '../../../../details/FlightBookingDetailsConstant';

const UndoWebCheckInOverlay = ({
  undoWebcheckinInfo,
  reviewInfo,
  onContinue,
  data,
  odcPreviewResponse,
  odcSegmentGroup,
  handleBottomOverlay,
  isCorp
}) => {
  const { fsStyle, ...fonts } = getFont();
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);
  const [, timeoutReqData, api] = useApi(GET_ERROR_DATA);
  const { flightDetails } = odcPreviewResponse || {};
  const { segmentGroupDetailList } = flightDetails || {};
  const { undoWebCheckinApplicableSegs } = undoWebcheckinInfo || {};
  const { flightsRaiseUndoWebCheckinResponseList = [], flightUndoWebCheckinProgressInfo } =
    data || {};
  const isUndoWCValidationFailureForAll = getUndoWCValidationFailureForAll(
    flightsRaiseUndoWebCheckinResponseList,
  );
  const {
    heading,
    description,
    durationInMins = 5,
    delayedText,
    waitingDurationText,
    confirmDateChange,
    confirmODCTitle,
    confirmODCSubtitle,
    dateChangeRequestedText,
    verifyWebcheckinText,
    delayTimer = 30,
  } = flightUndoWebCheckinProgressInfo || {};
  const delayTimerMS = delayTimer * 1000;
  const { fareBreakup, fareDetail, pnr, bookingId } = reviewInfo || {};
  const {amtpayable, continueToPayText: continueToPayTextMyBiz } = fareBreakup || {};
  let { continueToPayText: continueToPayValueText } = fareDetail || {};
  const [delayTime, setDelayTime] = useState(null);
  const [undoWCList, setUndoWCList] = useState(() =>
    JSON.parse(JSON.stringify(flightsRaiseUndoWebCheckinResponseList)),
  );
  const [title, setTitle] = useState(heading);
  const [subTitle, setSubTitle] = useState(description);
  continueToPayValueText = isCorp ? continueToPayTextMyBiz.toUpperCase() : continueToPayValueText.toUpperCase();
  const continueBtnText = amtpayable >= 0 ? continueToPayValueText : confirmDateChange.toUpperCase();
  const waitTextToken = waitingDurationText.split('{timer}');
  const delayedUndoECRef = useRef();
  const undoWCFirebaseApp = useRef();

  useEffect(() => {
    return () => {
      const { current } = undoWCFirebaseApp || {};
      const { removeOnValueListeners } = current || {};
      removeOnValueListeners && removeOnValueListeners();
    };
  }, []);

  useEffect(() => {
    if (timeoutReqData) {
      const { errorPageData } = timeoutReqData || {};
      errorPageData && undoWCFailedHandler(errorPageData, 'datechangeUndoWCTimeout', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeoutReqData]);

  useEffect(() => {
    if (isUndoWCValidationFailureForAll && flightsRaiseUndoWebCheckinResponseList.length) {
      const errorPageData = flightsRaiseUndoWebCheckinResponseList[0].errorPageData;
      undoWCFailedHandler(errorPageData, 'datechangeUndoWCValidationFail');
    } else {
      initFirebaseDatabase(undoWCList, undoWCFirebaseApp, updateStatus, bookingId, pnr, isCorp, data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flightsRaiseUndoWebCheckinResponseList, isUndoWCValidationFailureForAll]);

  const undoWCFailedHandler = (errorPageData, trackPageName, displayRenderComp = true) => {
    DateChangeTrackingHelper.trackLoadEvent(trackPageName, odcPreviewResponse);
    errorPageData &&
      openErrorPage(
        errorPageData,
        undefined,
        undefined,
        bookingId,
        'odc_undowebcheckin',
        undefined,
        {
          renderComponent: displayRenderComp ? renderUndoWCSegs() : null,
        },
      );
    handleBottomOverlay && handleBottomOverlay();
  };

  const openFailurePageWithDefaultData = () => {
    const {backToBookingText, errorText: errorTexts} = getStaticData();
    const {
      errorText,
      soomethingWentWrongOnlyError,
      unableToProcessRequestTechIssueText,
    } = errorTexts;
    const errorData = {};
    errorData.heading = soomethingWentWrongOnlyError;
    errorData.description = unableToProcessRequestTechIssueText;
    errorData.mobileHeaderData = {};
    errorData.mobileHeaderData.title = errorText;
    const ctaList =
      ' [{\n' +
      '            "actionId": "BACK_TO_BOOKING",\n' +
      '            "actionText": "' + backToBookingText + '",\n' +
      '            "actionUrl": null,\n' +
      '            "type": 2\n' +
      '        }]';
    errorData.ctaList = JSON.parse(ctaList);
    openErrorPage(
      errorData,
      undefined,
      undefined,
      bookingId,
      'odc_undowebcheckin',
      Actions,
      { psLob: LOBNAMES.FLIGHT },
    );
  };

  const renderUndoWCSegs = () => {
    const isAnyUndoWCSuccess =
      !isEmptyArray(undoWCList) && undoWCList.some((item) => item.status === STATUS.SUCCESS);
    return (
      <View style={[styles.detailCard]}>
        <Text
          style={[
            AtomicCss.font14,
            AtomicCss.blackFont,
            AtomicCss.blackText,
            AtomicCss.marginBottom12,
          ]}
        >
          {dateChangeRequestedText}
        </Text>

        {!isEmptyArray(segmentGroupDetailList) &&
          segmentGroupDetailList.map((item) => renderJourney(item, true))}
        {isAnyUndoWCSuccess && !!verifyWebcheckinText && (
          <Text
            style={[
              AtomicCss.font12,
              AtomicCss.regularFont,
              AtomicCss.midGreyText,
              AtomicCss.lineHeight18,
            ]}
          >
            *{verifyWebcheckinText}
          </Text>
        )}
      </View>
    );
  };

  const renderUndoWCAndODCSegs = () => {
    return (
      <View style={styles.tripCardWrapper}>
        {!isEmptyArray(segmentGroupDetailList) &&
          segmentGroupDetailList.map((item) => renderJourney(item))}
      </View>
    );
  };

  useEffect(() => {
    delayedUndoECRef.current = setTimeout(() => {
      const durationInSecond = durationInMins * 60;
      setDelayTime(durationInSecond - delayTimer);
    }, delayTimerMS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [durationInMins]);

  useEffect(() => {
    if (!isUndoWCValidationFailureForAll && !isEmptyArray(undoWCList)) {
      const isAnyUndoWCFailed = undoWCList.some((item) => item.status === STATUS.FAILURE);
      if (!isUndoWCPending(undoWCList) && isAnyUndoWCFailed) {
        const allFailed = undoWCList.every((item) => item.status === STATUS.FAILURE);
        onUndoWCFailure(allFailed);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUndoWCPending(undoWCList), undoWCList, isUndoWCValidationFailureForAll]);

  const onUndoWCFailure = async (allFailed) => {
    const response = await fetchUndoWCFailure(undoWCList, bookingId, allFailed, isCorp);
    if(response){
      undoWCFailedHandler(response.errorPageData, response.allFailedText);
    } else {
      openFailurePageWithDefaultData()
    }
   
  };

  useEffect(() => {
    if (isUndoWCDone(undoWCList)) {
      setTitle(confirmODCTitle);
      setSubTitle(confirmODCSubtitle);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [undoWCList]);

  useEffect(() => {
    if (!isUndoWCPending(undoWCList)) {
      clearTimeout(delayedUndoECRef.current);
    }
  }, [undoWCList]);

  const updateStatus = (index, data) => {
    undoWCList[index] = {
      ...undoWCList[index],
      ...data,
    };
    setUndoWCList([...undoWCList]);
  };

  const renderJourney = (data, isErrorScreen) => {
    const journeyInfo = getOverlayJourneyInfo(data, undoWebCheckinApplicableSegs, odcSegmentGroup,undoWCList );
    const cards = [];
    journeyInfo && journeyInfo.map((item)=>{
      cards.push(
        <View style={AtomicCss.marginBottom12}>
          <TripCard
            journeyInfo={item.tripCardInfo.journeyInfo}
            tripCardStatus={
              isUndoWCValidationFailureForAll
                ? STATUS.FAILURE
                : item.tripCardInfo.tripCardStatusInfo && item.tripCardInfo.tripCardStatusInfo.status
            }
            flightUndoWebCheckinProgressInfo={flightUndoWebCheckinProgressInfo}
            isErrorScreen={isErrorScreen}
          />
        </View>
      );
    })
    return cards;
  };

  const onTimeout = () => {
    setDelayTime(null);
    api.httpPost({
      bookingId,
      uniqueId: 'FLIGHT_TIMEOUT_UNDO_WEBCHECKIN',
      body: getTimeOutReqBody(bookingId, isCorp),
    });
  };

  return (
    <>
      <View>
        <Text style={[styles.title, AtomicCss.marginBottom20, fonts.blackFontFamily]}>{title}</Text>
        {delayTime && isUndoWCPending(undoWCList) ? (
          <>
            <Text
              style={[
                AtomicCss.boldFont,
                AtomicCss.font14,
                AtomicCss.yellowText,
                styles.lineHeight21,
                AtomicCss.marginBottom5,
              ]}
            >
              {delayedText}
            </Text>
            <Text
              style={[
                AtomicCss.regularFont,
                AtomicCss.font14,
                AtomicCss.defaultText,
                styles.lineHeight21,
                AtomicCss.marginBottom30,
              ]}
            >
              <Text style={AtomicCss.boldFont}>{waitTextToken[0]}</Text>
              <Timer
                duration={delayTime}
                timerTextStyle={[styles.timerText, fonts.blackFontFamily]}
                callback={onTimeout}
              />{' '}
              {waitTextToken[1]}
            </Text>
          </>
        ) : (
          <Text
            style={[
              AtomicCss.boldFont,
              AtomicCss.font14,
              styles.lineHeight21,
              AtomicCss.marginBottom30,
            ]}
          >
            {subTitle}
          </Text>
        )}
        {renderUndoWCAndODCSegs()}
      </View>
      <View style={styles.bottomWrapper}>
        <BaseButton
          text={continueBtnText}
          clickHandler={onContinue}
          variant={BUTTON_VARIANT.PRIMARY}
          disabled={!isUndoWCDone(undoWCList)}
          textStyle={fsStyle.font16}
          {...FLIGHT_THROTTLE_OBJECT}
        />
      </View>
    </>
  );
};

export default UndoWebCheckInOverlay;
