import React, { useState } from 'react';
import { View, Text, TouchableOpacity, Image, Linking } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { CHECKBOX_ACTIVE_BLUE_ICON } from '../../../../../../../flights/FlightImageConstants';
import { InfoBlueIcon } from '../../../../../../HolidayBookingConstants';
import HTMLView from 'react-native-htmlview';
import isEmpty from 'lodash/isEmpty';
import createStyles, { htmlStyles } from './style';
import { getFont } from '../../../../../../../PostSalesStyles';
import { useTheme } from '../../../../../../../theme/theme.context';
import HolidayBookingTrackingHelper from 'apps/post-sales/src/Holiday/HolidayBookingTrackingHelper';

const ImportantInfoCard = ({
  heading,
  agreementNotCheckedText,
  termsAndConditions,
  tncUrl,
  aggrementText,
  isError,
  setAgreedState,
  pageName,
}) => {
  const fonts = getFont();
  const { psTheme: theme } = useTheme();
  const styles = createStyles(fonts, theme);
  const htmlStyle = htmlStyles(fonts, theme);
  const [checkboxState, setChecknboxState] = useState(false);

  const handleTnC = () => {
    HolidayBookingTrackingHelper.trackClickEvent(
      pageName,
      checkboxState ? 'T&C_agreement_uncheck' : 'T&C_agreement_check',
    );
    setChecknboxState(!checkboxState);
    setAgreedState(!checkboxState);
  };
  const openTnC = () => {
    Linking.openURL(tncUrl);
  };

  const isActiveOuter = checkboxState === true ? 'checkboxSelected' : '';
  return (
    <View style={styles.innerCardWrapper}>
      {!isEmpty(heading) && (
        <Text style={[styles.headingText, AtomicCss.marginBottom6]}>{heading}</Text>
      )}
      {isError && !isEmpty(agreementNotCheckedText) && !checkboxState && (
        <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
          <Image style={styles.infoIconStyle} source={InfoBlueIcon} />
          <Text style={styles.errorText}>{agreementNotCheckedText}</Text>
        </View>
      )}
      {!isEmpty(termsAndConditions) && (
        <View style={AtomicCss.marginTop15}>
          {termsAndConditions.map((item, index) => (
            <View
              style={[
                styles.listWrapper,
                index === termsAndConditions.length - 1 ? AtomicCss.marginBottom12 : '',
              ]}
            >
              <View style={styles.bullet} />
              <HTMLView
                value={`<p>${item}</p>`}
                stylesheet={htmlStyle}
                onLinkPress={(url) => {
                  openTnC();
                }}
              />
            </View>
          ))}
        </View>
      )}
      <TouchableOpacity
        style={isError ? [styles.tncWrapper, styles.errorStyle] : [styles.tncWrapper]}
        onPress={handleTnC}
        activeOpacity={0.8}
      >
        <View style={[styles.checkbox, styles[isActiveOuter], AtomicCss.marginRight10]}>
          {checkboxState && <Image style={styles.checkedIcon} source={CHECKBOX_ACTIVE_BLUE_ICON} />}
        </View>
        {!isEmpty(aggrementText) && (
          <View style={AtomicCss.flex1}>
            <Text style={styles.agreementText}>{aggrementText}</Text>
          </View>
        )}
      </TouchableOpacity>
    </View>
  );
};

export default ImportantInfoCard;
