import React from 'react';
import { Text, View } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getStaticData } from 'apps/post-sales/src/staticData/staticData';
import createStyle from './style';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

interface NoUpcomingTextProps {
    noTopBookings?: boolean;
    isFilterSelected?: boolean;
}

const NoUpcomingText = ({ noTopBookings, isFilterSelected }: NoUpcomingTextProps) => {
    const staticData = getStaticData();
    const { psTheme: theme } = useTheme();
    const fonts = getFont();
    const styles = createStyle(theme, fonts);
    return (
        <View style={[styles.container, AtomicCss.alignCenter]}>
            {!!isFilterSelected ? <Text style={[styles.noFilteredBookingsText, AtomicCss.marginBottom10]}>{staticData.noFilteredBookingsText}</Text> : (
                <>
                    <Text style={[styles.headerText, AtomicCss.marginBottom10]}>{!!noTopBookings ? staticData.noUpcomingBookingText : staticData.noBookingsText}</Text>
                    <Text style={[styles.subHeadingText, AtomicCss.marginBottom24]}>{staticData.startExploringText}</Text>
                </>
            )}
        </View>
    )
}
export default NoUpcomingText;

