import { View, Image, TouchableOpacity, Text, Platform } from 'react-native';
import React, { useEffect } from 'react';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import LinearGradient from 'react-native-linear-gradient';
import createStyles from './benefitsClaimedStyle';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import isEmpty from 'lodash/isEmpty';
import PostSalesBookingTrackingHelper from '../../PostSalesBookingTrackingHelper';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import HTMLView from 'react-native-htmlview';
import RowCardContainer from '../Components/RowCardContainer';
import CardContainer from '../Components/CardContainer';
interface IBenefitsClaimedCardProps {
    tierMetaInfo: {
        tierIcon: string
    },
    tierName: string,
    spendsDataList: Array<ISpendsDataList>,
    benefitDetailsList: Array<IBenefitDetailsList>,
    trackingPageName: string,
    heading: string,
    messageBgColor: string
}
interface IBenefitDetailsList {
    iconUrl: {
      WEB: string
    },
    heading: string,
    description: string[]
}
interface ISpendsDataList {
    iconUrl: string,
    text: string,
    messageTextColor: string,
    ctaData: {
        label: string,
        actionLink: {
          PWA: string
        }
    }
}

const getHtmlStyles = (fonts, color) => {
    return {
        span: {
            color: color,
            fontSize: fonts.fontSize.font14,
            lineHeight: 14,
            ...fonts.regularFontFamily
        }
    }
}

const BenefitsClaimedCard = ({ tierMetaInfo, tierName, spendsDataList, benefitDetailsList, trackingPageName, heading, messageBgColor }: IBenefitsClaimedCardProps) => {
    useEffect(() => {
        PostSalesBookingTrackingHelper.trackLoyalityCardShown(trackingPageName, `BenefitsClaimedCard_${tierName}_shown`);
    }, [trackingPageName, tierName])

    const { fsStyle, ...fonts } = getFont(true);
    const { psTheme: theme } = useTheme();
    const styles = createStyles(theme)
    const gradientColor = theme.color[`${tierName?.toLowerCase()}GradientColor`] || theme.color.platinumGradientColor

    const viewBenefits = (link: string) => {
        if (link) {
           if(Platform.OS === 'web'){
             window.open(link, '_blank')
           }else{
            GenericModule.openDeepLink(link);
           }
        }
    }

    return (
      <CardContainer containerStyle={styles.benefitWrapper}>
        {!isEmpty(tierMetaInfo?.tierIcon) && (
          <View style={styles.benefitHead}>
            <Image source={{ uri: tierMetaInfo.tierIcon }} style={styles.membershipIcon} />
          </View>
        )}
        <LinearGradient
          colors={gradientColor}
          start={{ x: 0.0, y: 0.0 }}
          end={{ x: 0.0, y: 1.0 }}
          style={[styles.cardDetailsWrapper]}
        >
          <View style={styles.benefitWhiteBg}>
            <RowCardContainer>
              <View style={AtomicCss.flex1}>
                {!isEmpty(heading) && (
                  <Text
                    style={[
                      AtomicCss.blackText,
                      fsStyle.font18,
                      fonts.blackFontFamily,
                      AtomicCss.marginBottom12,
                      AtomicCss.marginTop8
                    ]}
                  >
                    {heading}
                  </Text>
                )}
                {!isEmpty(benefitDetailsList) &&
                  benefitDetailsList.map((item, index) => {
                    return (
                      <View key={index} style={[AtomicCss.flexRow, AtomicCss.marginVertical8]}>
                        {!isEmpty(item?.iconUrl?.WEB) && !!tierName && (
                          <Image
                            source={{ uri: item.iconUrl.WEB }}
                            style={styles.listIconStyle}
                          />
                        )}
                        {!isEmpty(item.heading) && !isEmpty(item.description) && (
                          <View style={[AtomicCss.displayFlex, AtomicCss.width90]}>
                            <Text
                              style={[
                                AtomicCss.blackText,
                                fsStyle.font14,
                                AtomicCss.lineHeight14,
                                fonts.boldFontFamily,
                              ]}
                            >
                              {item.heading}
                            </Text>
                            <Text
                              style={[
                                AtomicCss.defaultText,
                                fsStyle.font12,
                                AtomicCss.lineHeight14,
                                fonts.regularFontFamily,
                                AtomicCss.marginTop3,
                              ]}
                            >
                              {item.description.join('. ')}
                            </Text>
                          </View>
                        )}
                      </View>
                    );
                  })}
              </View>
            </RowCardContainer>

            {!isEmpty(spendsDataList) && (
              <RowCardContainer>
                <View style={[AtomicCss.flex1, styles.flashWrapper, {backgroundColor: messageBgColor || theme.color.grayBg} ]}>
                  {spendsDataList.map((item, index) => {
                    return (
                      <View key={index}>
                        {!isEmpty(item.text) && (
                          <View style={[AtomicCss.flexRow, AtomicCss.marginVertical5]}>
                            {!isEmpty(item.iconUrl) && (
                              <Image source={{uri: item.iconUrl}} style={styles.flashIconStyle} />
                            )}

                            <View style={AtomicCss.flex1}>
                              <HTMLView
                                stylesheet={getHtmlStyles(
                                  fonts,
                                  item?.messageTextColor || theme.color.defaultTextColor,
                                )}
                                value={item.text}
                              />
                              {!isEmpty(item?.ctaData?.label) && (
                                <TouchableOpacity
                                  style={AtomicCss.marginTop5}
                                  onPress={() => {
                                    viewBenefits(item.ctaData?.actionLink?.PWA);
                                  }}
                                >
                                  <Text
                                    style={[
                                      AtomicCss.azure,
                                      fsStyle.font14,
                                      fonts.regularFontFamily,
                                    ]}
                                  >
                                    {item.ctaData.label}
                                  </Text>
                                </TouchableOpacity>
                              )}
                            </View>
                          </View>
                        )}
                      </View>
                    );
                  })}
                </View>
              </RowCardContainer>
            )}
          </View>
        </LinearGradient>
      </CardContainer>
    );
}

export default BenefitsClaimedCard