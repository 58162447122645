import React from 'react';
import { View, Text, Image, StyleSheet } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { BLACK_RIGHT_ARROW, GREEN_TICK_ICON2 } from '../../../../../FlightImageConstants';
import { getAirlineImage } from '../../../../../../flights-funnel/Listing/utils/FlightListingUtil';
import ProgressView from '../../../../../../Common/ProgressView/ProgressView';
import { getStaticData } from '../../../../../../staticData/staticData';
import { getFont } from '../../../../../../PostSalesStyles';
import { useTheme } from '../../../../../../theme/theme.context';

const TripCard = ({
  isErrorScreen,
  tripCardStatus,
  journeyInfo,
  flightUndoWebCheckinProgressInfo,
}) => {
  const fonts = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = getStyles(theme, fonts);
  const { airlineCd, originCity, departureCity, date } = journeyInfo || {};
  const isNotApplicable = tripCardStatus === 'none';
  const isSuccess = tripCardStatus === 'SUCCESS';
  const isFailed = tripCardStatus === 'FAILURE';
  const statusBgCard = isSuccess && !isFailed ? 'SuccessCard' : 'FailedCard';
  const { undoWCText } = getStaticData(true);
  const { changingDateToText, undoingWCText } = undoWCText.additionalText;
  const { unableToUndoText, wcUndoneText } = flightUndoWebCheckinProgressInfo;
  return (
    <View style={isErrorScreen ? null : [styles.uploadCard, styles[statusBgCard]]}>
      <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween]}>
        <Image style={styles.flightIconStyle} source={{ uri: getAirlineImage(airlineCd) }} />
        <View style={AtomicCss.flex1}>
          <View>
            <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom5]}>
              {!!originCity && <Text
                style={[
                  {fontSize:fonts.fontSize.font14},
                  fonts.boldFontFamily,
                  AtomicCss.blackText,
                  AtomicCss.lineHeight20,
                ]}
              >
                {originCity}
              </Text>}
              <Image style={styles.rtArrowStyle} source={BLACK_RIGHT_ARROW} />
              {!!departureCity && <Text
                style={[
                  {fontSize:fonts.fontSize.font14},
                  fonts.boldFontFamily,
                  AtomicCss.blackText,
                  AtomicCss.lineHeight20,
                ]}
              >
                {departureCity}
              </Text>}
            </View>
            {isSuccess && (
              <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom5]}>
                <Image style={styles.tickIconStyle} source={GREEN_TICK_ICON2} />
                <Text
                  style={[
                    {fontSize:fonts.fontSize.font14},
                    fonts.boldFontFamily,
                    AtomicCss.greenText,
                    AtomicCss.lineHeight20,
                  ]}
                >
                  {wcUndoneText}
                </Text>
              </View>
            )}
            {!isSuccess && !isFailed && !isNotApplicable && (
              <Text
                style={[
                  {fontSize:fonts.fontSize.font14},
                  fonts.regularFontFamily,
                  AtomicCss.defaultText,
                  AtomicCss.lineHeight20,
                ]}
              >
                {undoingWCText}..
              </Text>
            )}
            {isFailed && (
              <Text
                style={[
                  {fontSize:fonts.fontSize.font14},
                  fonts.regularFontFamily,
                  AtomicCss.redText,
                  AtomicCss.lineHeight20,
                ]}
              >
                {unableToUndoText}
              </Text>
            )}
            {!isErrorScreen && (isSuccess || isNotApplicable) && (
              <Text
                style={[
                  {fontSize:fonts.fontSize.font14},
                  fonts.regularFontFamily,
                  AtomicCss.midGreyText,
                  AtomicCss.lineHeight20,
                ]}
              >
                {changingDateToText.replace('{date}', date)}
              </Text>
            )}
          </View>
        </View>
        {!isSuccess && !isFailed && !isNotApplicable && (
          <View>
            <ProgressView style={{ backgroundColor: 'none' }} />
          </View>
        )}
      </View>
    </View>
  );
};
const getStyles = (theme, fonts) => StyleSheet.create({
  uploadCard: {
    backgroundColor: theme.color.lighGrey6,
    marginBottom: 8,
    padding: 16,
    borderRadius: 4,
  },
  SuccessCard: {
    backgroundColor: theme.color.limeGreen,
  },

  FailedCard: {
    backgroundColor: theme.color.lightGrey6,
  },
  flightIconStyle: {
    width: 32,
    height: 32,
    marginRight: 12,
    marginTop: 4,
  },
  progressBarWrppaer: {
    marginTop: -8,
    width: '100%',
  },
  rtArrowStyle: {
    width: 12,
    height: 9,
    marginHorizontal: 4,
  },
  tickIconStyle: {
    width: 16,
    height: 16,
    marginRight: 4,
  },
});
export default TripCard;
