import { StyleSheet } from 'react-native';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';

const createStyles = (theme) =>
  StyleSheet.create({
    visaTypeRowCard: {
      ...getPlatformElevation(2),
      borderRadius: 4,
      backgroundColor: theme.color.grey22,
      padding: 15,
      marginBottom: 10,
    },
    radio: {
      borderColor: theme.color.lightTextColor,
      borderWidth: 2,
      height: 18,
      width: 18,
      backgroundColor: theme.color.white,
      borderRadius: 10,
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: 5,
    },
    radioActive: {
      borderColor: theme.color.azure,
    },
    radioCircle: {
      height: 11,
      width: 11,
      backgroundColor: theme.color.azure,
      borderRadius: 10,
    },
    visaTypeMoreInfo: {
      paddingLeft: 20,
      marginTop: 5,
    },
  });
export default createStyles;
