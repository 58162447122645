import {fonts} from '@mmt/legacy-commons/Styles/globalStyles'
import getPlatformElevation from '@mmt/legacy-commons/Common/Components/Card/getPlatformElevation'

const styles = {

  TabItem:{
    borderRadius:4,
    ...getPlatformElevation(2),
    backgroundColor:'#fff',
    paddingVertical:10,
    paddingHorizontal:15,
    borderColor:'#ddd',
    borderWidth:1,
    margin:5,
    flexDirection:'row',
},
TabItemActive:{
  borderRadius:4,
  backgroundColor:'#eaf5ff',
  paddingVertical:10,
  paddingHorizontal:15,
  margin:5,
  borderColor:'#eaf5ff',
  borderWidth:1,
  flexDirection:'row',
  ...getPlatformElevation(2),
},

TabItemTxt:{
  fontFamily:fonts.regular,
  fontSize:16,
  color:'#000000',
  paddingTop:3,
},

TabItemTxtActive:{
  fontFamily:fonts.bold,
  fontSize:16,
  color:'#008cff',
  paddingTop:3,
},

tabIcon:{
  width:20,
  height:20,
  marginRight:6,
},
tabIconReward:{
  width:19,
  height:21,
  marginRight:6,
},

tabTouchable:{
    height:60
},

tabBottomBorder:{
    width:'96%',
    height:2,
    backgroundColor:'#008cff',
    position:'absolute',
    bottom:0,
    left:'2%'
},

tabBadgeCount:{
    borderRadius:8,
    width:16,
    height:16,
    fontSize:12,
    fontFamily:fonts.bold,
    marginLeft:5,
    backgroundColor:'#000000',
    overflow:'hidden',
    alignSelf:'center',
    justifyContent:'center',
    alignItems:'center',
    flex:1
},

tabBadgeCountActive:{
    borderRadius:8,
    width:16,
    height:16,
    marginLeft:5,
    backgroundColor:'#008cff',
    overflow:'hidden',
    alignSelf:'center',
    justifyContent:'center',
    alignItems:'center',
    flex:1
},

tabBadgeText:{
    fontSize:12,
    fontFamily:fonts.bold,
    color:'#ffffff',
}
};

export default styles;
