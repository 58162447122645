import { Fonts, Theme } from 'apps/post-sales/src/cab/types';
import { StyleSheet } from 'react-native';

const getStyles = (fonts: Fonts, theme: Theme) => {
  const { color } = theme;
  return StyleSheet.create({
    container: {
      marginBottom: 10,
      paddingHorizontal: 16,
    },
    offerText: {
      color: color.defaultTextColor,
      fontSize: fonts.fontSize.font14,
      ...fonts.regularFontFamily,
    },
    AirportUpselCards: {
      borderRadius: 16,
      paddingVertical: 12,
      paddingRight: 15,
      paddingLeft: 12,
    },
    flatOfferSection: {
      borderRadius: 8,
      backgroundColor: color.blue12,
      borderColor: color.blue13,
      borderWidth: 1,
      alignItems: 'center',
      justifyContent: 'center',
      width: 44,
      height: 48,
      marginTop: 10,
      marginRight: 12,
    },
    flatTag: {
      backgroundColor: color.blue15,
      borderRadius: 4,
      padding: 4,
      marginTop: -15,
    },
    offerListSection: {
      flexDirection: 'row',
      alignItems: 'center',
      paddingHorizontal: 10,
      paddingVertical: 5,
      flexWrap: 'wrap',
    },
    doneIconStyle: {
      width: 12,
      height: 12,
      marginRight: 5,
    },
    listText: {
      color: color.lightGreen18,
      fontSize: fonts.fontSize.font12,
      ...fonts.regularFontFamily,
    },
    rightArrowStyle: {
      width: 12,
      height: 12,
    },
    copyCode: {
      borderWidth: 1,
      borderColor: color.lightGray,
      paddingVertical: 4,
      paddingHorizontal: 10,
      borderRadius: 10,
      borderStyle: 'dashed'
    },
    bookNowSection: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      borderTopColor: color.grayBg,
      borderTopWidth: 1,
      paddingVertical: 10,
      marginHorizontal: -12,
      paddingHorizontal: 16,
      marginBottom: -10
    },
    flatText: {
      fontSize: fonts.fontSize.font10,
      color: color.white,
      ...fonts.regularFontFamily,
    },
    offerText1: {
      fontSize: fonts.fontSize.font14,
      color: color.blue14,
      ...fonts.blackFontFamily,
    },
    offerText2: {
      fontSize: fonts.fontSize.font12,
      color: color.blue14,
      ...fonts.boldFontFamily,
    },
    heading: {
      ...fonts.blackFontFamily,
      color: color.black,
      fontSize: fonts.fontSize.font16,
    },
    subHeading: {
      ...fonts.regularFontFamily,
      color: color.defaultTextColor,
      fontSize: fonts.fontSize.font14,
    },
    startingPriceInfo: {
      ...fonts.regularFontFamily,
      color: color.black74,
      fontSize: fonts.fontSize.font12,
    },
    voucherCode: {
      ...fonts.boldFontFamily,
      color: color.black74,
      fontSize: fonts.fontSize.font10,
    },
    copyAndBookNowText: {
      ...fonts.boldFontFamily,
      color: color.azure,
      fontSize: fonts.fontSize.font12,
    },
  });
};

export default getStyles;
