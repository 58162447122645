import { StyleSheet } from 'react-native';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';

const getStyle = (theme) => {
  const {color} = theme;
  return StyleSheet.create({
    fullPage: { flex: 1, paddingBottom: 70 },
    topSection: {
      backgroundColor: color.softCyan,
      flex: 1,
      paddingTop: 65,
      paddingBottom: 78,
      alignItems: 'center',
    },
    mainconStyle: { width: 64, height: 64, marginBottom: 20 },
    detailSection: { marginTop: -78 },
    detailsTop: {
      backgroundColor: color.lightGrey6,
      padding: 16,
      borderRadius: 4,
      marginBottom: 20,
      borderColor: color.grayBg,
      borderWidth: 1,
    },
    arrowStyle: {
      width: 10,
      height: 15,
      marginLeft: 4,
      marginTop: 4,
    },
    buttonBottomWrapper: {
      position: 'absolute',
      bottom: 16,
      paddingHorizontal: 8,
      width: '100%',
      alignItems: 'center',
    },
    titleHeading: {
      color: color.darkCyan,
      marginBottom: 35,
    },
    detailCard: {
      borderRadius: 8,
      marginHorizontal: 12,
      backgroundColor: color.white,
      ...getPlatformElevation(2),
      padding: 6,
    },
    segmentCard: {
      paddingHorizontal: 10,
    },
    segmentHeadingWrapper: {
      borderBottomColor: color.lightGrey,
      borderBottomWidth: 1,
      flexDirection: 'row',
      alignItems: 'center',
      paddingBottom: 12,
    },
    lastItem: {
      borderBottomWidth: 0,
      paddingBottom: 6,
    },
    arrowIcon: {
      width: 10,
      height: 7,
      marginHorizontal: 5,
    },
    logoStyle: {
      width: 32,
      height: 32,
      marginRight: 10,
    },
    innerDtls: {
      padding: 10,
    },
    dtlsRow: {
      flexDirection: 'row',
    },
    greenIconStyle: {
      width: 16,
      height: 16,
      marginRight: 10,
    },
    moreInfoWrapper: {
      padding: 16,
      paddingVertical: 10,
      borderTopColor: color.lightGrey,
      borderTopWidth: 1,
    },
    errorIcon: {
      width: 18,
      height: 16,
      marginRight: 10,
    },
    seperatorWrapper: {
      paddingHorizontal: 20,
      overflow: 'hidden',
      marginTop: 12,
      marginBottom: 4,
      marginHorizontal: -6,
    },
    loaderContainer: {
      textAlign: 'center',
      justifyContent: 'center',
      marginTop: 16,
      marginBottom: 28,
    },
    activityIndicator: {
      transform: [{ scale: 1.5 }],
    },
    lineHeight24: {
      lineHeight: 24,
    },
    lineHeight16: {
      lineHeight: 16,
    },
  });
}

export default getStyle;
