import { Fonts, Theme } from '@mmt/post-sales/src/cab/types';
import { StyleSheet } from 'react-native';

export default (theme: Theme, fonts: Fonts) => {
	const { color } = theme;
	return StyleSheet.create({
		container: {
			padding: 20,
			backgroundColor: color.grayBg,
			borderTopLeftRadius: 20,
			borderTopRightRadius: 20,
		},
		headingSection: {
			flexDirection: 'row',
			justifyContent: 'space-between',
			marginBottom: 10,
		},
		headingText: {
			color: color.black22,
			...fonts.blackFontFamily,
			fontSize: fonts.fontSize.font20,
		},
		titleText: {
			...fonts.blackFontFamily,
			fontSize: fonts.fontSize.font14,
			color: color.lightGreen18,
			marginBottom: 5,
		},
		crossIconStyle: {
			width: 24,
			height: 24,
		},
		crossIconWrapper: {
			width: 30,
			height: 30,
			right: -10,
		},
		btnWrapper: {
			paddingHorizontal: 18,
			paddingBottom: 12,
		},
		overlaySection: {
			marginBottom: 10,
		},
		marginBottom10: {
			marginBottom: 10,
		},
		driverProfileWrapper: {
			backgroundColor: color.white,
			borderRadius: 16,
			borderWidth: 1,
			borderColor: color.lightGray,
			padding: 16,
		},
		driverImageStyle: {
			width: 128,
			height: 128,
			borderRadius: 12,
			marginLeft: 15,
		},
		profileRow: {
			flexDirection: 'row',
			alignItems: 'center',
		},
		iconImageStyle: {
			width: 20,
			height: 21,
			marginRight: 10,
		},
		borderBottom: {
			borderBottomWidth: 1,
			borderBottomColor: color.grayBg,
		},
		lastRow: {
			borderBottomWidth: 0,
			marginBottom: 0,
		},
		whatCustomerWrapper: {
			backgroundColor: color.green11,
			borderRadius: 18,
			paddingHorizontal: 12,
			paddingVertical: 8,
			marginRight: 4,
			marginBottom: 4,
		},
		customerReviewHeading: {
			...fonts.regularFontFamily,
			fontSize: fonts.fontSize.font14,
			lineHeight: 16,
			color: color.black,
			marginBottom: 10,
		},
		customerReviewList: {
			flexDirection: 'row',
			flexWrap: 'wrap',
		},
		customerReviewText: {
			...fonts.boldFontFamily,
			fontSize: fonts.fontSize.font13,
			lineHeight: 15,
			color: color.lightGreen18,
		},
		profileContainer: {
			flexDirection: 'row',
			justifyContent: 'space-between',
			marginBottom: 10,
		},
		driverName: {
			flex: 1,
		},
		driverNameText: {
			...fonts.blackFontFamily,
			fontSize: fonts.fontSize.font18,
			color: color.black,
			marginBottom: 14,
		},
		driverProfileLeftSection: {
			flexDirection: 'row',
			alignItems: 'center',
			paddingBottom: 10,
		},
		leftSectionMainText: {
			...fonts.blackFontFamily,
			fontSize: fonts.fontSize.font14,
			color: color.black,
			marginBottom: 3,
		},
		leftSectionSubText: {
			...fonts.regularFontFamily,
			fontSize: fonts.fontSize.font12,
			color: color.defaultTextColor,
		},
		languageWrapper: {
			backgroundColor: color.grayBg,
			borderRadius: 18,
			paddingHorizontal: 12,
			paddingVertical: 4,
			marginRight: 4,
			marginBottom: 4,
		},
		languageSectionContainer: {
			marginBottom: 10,
		},
		languageSectionHeading: {
			...fonts.regularFontFamily,
			fontSize: fonts.fontSize.font14,
			lineHeight: 16,
			color: color.black,
			marginBottom: 10,
		},
		languageSectionList: {
			flexDirection: 'row',
			flexWrap: 'wrap',
		},
		languageSectionText: {
			...fonts.boldFontFamily,
			fontSize: fonts.fontSize.font13,
			lineHeight: 16,
			color: color.black,
		},
	});
};
