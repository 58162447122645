import React, { Component } from 'react';
import { View, Platform, ActivityIndicator, StyleSheet } from 'react-native';

import PropTypes from 'prop-types';
import {
  FILTER_LOCATION_CENTER,
  FILTER_LOADING_COLOR,
  FILTER_LOCATION_BOTTOM,
  FILTER_LOCATION_TOP,
} from '../commonConstant';

export default class Loader extends Component {
  constructor(props) {
    super(props);
    this.startProgress = this.startProgress.bind(this);
    this.stopProgress = this.stopProgress.bind(this);
    this.clearProgress = this.clearProgress.bind(this);
    this.state = {
      Progress_Value: 0.0,
    };
  }

  componentDidMount() {
    if (Platform.OS === 'ios') {
      this.startProgress();
    }
  }

  componentDidUpdate() {
    if (this.state.Progress_Value >= 1) {
      this.clearProgress();
    }
  }

  componentWillUnmount() {
    this.stopProgress();
  }

  startProgress() {
    this.value = setInterval(() => {
      if (this.state.Progress_Value <= 1) {
        this.setState({ Progress_Value: this.state.Progress_Value + 0.01 });
      }
    }, 10);
  }

  stopProgress() {
    clearInterval(this.value);
  }

  clearProgress() {
    this.setState({
      Progress_Value: 0.0,
    });
  }

  render() {
    const alignment = {
      top: FILTER_LOCATION_TOP,
      center: FILTER_LOCATION_CENTER,
      bottom: FILTER_LOCATION_BOTTOM,
    };
    const position = this.props.position
      ? alignment[this.props.position]
      : alignment[FILTER_LOCATION_CENTER];
    const loaderWidth = this.props.width ? this.props.width * 100 + '%' : '10%';
    return (
      <View
        style={{
          flex: 1,
          alignItems: 'center',
          justifyContent: position,
        }}
      >
        <View
          style={{
            width: loaderWidth,
          }}
        >
          {Platform.OS === 'web' && (
            <ActivityIndicator
              styleAttr="Inverse"
              style={styles.indicator}
              size="large"
              color="#008cff"
            />
          )}
        </View>
      </View>
    );
  }
}

Loader.propTypes = {
  position: PropTypes.string,
  loadingColor: PropTypes.string,
  width: PropTypes.number,
};

const styles = StyleSheet.create({
  indicator: {
    backgroundColor: 'transparent',
    width: '100%',
    height: 3,
  },
});
