import { StyleSheet } from 'react-native';

const getStyles = (theme, fonts) => {
  const { color } = theme;
  return StyleSheet.create({
    specialOfferCard: {
      paddingRight: 16,
      marginBottom: 10,
      marginTop: 10,
    },
    unlockInfoSection: {
      paddingVertical: 10,
      paddingHorizontal: 20,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 16,
    },
    offerText: {
      color: color.red23,
      fontSize: fonts.fontSize.font12,
    },
    specialOfferDtls: {
      borderBottomWidth: 1,
      borderBottomColor: color.lightGray,
      flexDirection: 'row',
      alignItems: 'center',
      marginLeft: 16,
      paddingVertical: 12,
    },
    imageWrapper: {
      width: 40,
      height: 40,
      marginRight: 10,
    },
    offerIcon: {
      width: 40,
      height: 40,
    },
    arrowStyle: {
      marginHorizontal: 5,
      width: 15,
      height: 12,
    },
    moreInfoWrapper: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingHorizontal: 16,
      paddingVertical: 10,
    },
  });
};

export default getStyles;
