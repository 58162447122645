import React, { useState } from 'react';
import { Image, Text, TouchableOpacity, View } from 'react-native';
import { getScreenWidth } from '../../../../utils/PostSaleUtil';
import { isEmpty } from 'lodash';
import { getFont, ps_colors } from '../../../../PostSalesStyles';
import createStyles from './baseCardStyles';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import { flightRoundTripIcon, rightArrowGreyIcon } from 'apps/post-sales/src/flights/FlightImageConstants';
import { SummaryActionProps } from './cardInterfaces';
import { PAGE_BOOKINGS_STATUS } from '../../summaryConstants';



export function SummaryContainer({ children, bookingId }: { children: React.ReactNode, bookingId: string }) {
  return (
    <View style={baseCardStyle.container} testID={bookingId} >
      {children}
    </View>
  )
}

export function MainContent({ children, onPress }: { children: React.ReactNode, onPress: () => void }) {
  return (
    <TouchableOpacity onPress={onPress} activeOpacity={0.7}>
      <View>
        {children}
      </View>
    </TouchableOpacity>
  )
}

export function DescriptionSection({ children }: { children: React.ReactNode }) {
  return (
    <View style={baseCardStyle.descriptionSection}>
      {children}
    </View>
  )
}

export function ActionsContent({ actions,isInfoCardShown }: { actions: Array<{ onPress: () => void, label: string }>, isInfoCardShown?: boolean }) {
  if (!actions || actions.length === 0) {
    return null;
  }
  return (
    <View style={isInfoCardShown ? baseCardStyle.linkWrapper : [baseCardStyle.linkWrapper, AtomicCss.marginTop0]}>
      {actions.map((action, index) => (
        <React.Fragment key={index}>
          {!isEmpty(action.label) && <SummaryAction label={action.label} actionHandler={action.onPress} />}
        </React.Fragment>
      ))}
    </View>
  )
}

export function SummaryAction({ label, actionHandler }: SummaryActionProps) {
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleActionPress = async () => {
    setButtonDisabled(true);
    await actionHandler();
    setButtonDisabled(false);
  }

  return (
    <TouchableOpacity disabled={buttonDisabled} onPress={handleActionPress}>
      <Text style={[{ color: theme.color.azure }, fsStyle.font14, fonts.blackFontFamily, buttonDisabled ? { opacity: 0.4 } : {}]}>
        {label}
      </Text>
    </TouchableOpacity>);
}
interface WhereToGoTopImageProps {
  topImageText?: string;
  topImage?: string;
}

export function WhereToGoTopImage({ topImageText, topImage }: WhereToGoTopImageProps) {
  const { fsStyle, ...fonts } = getFont(true);

  return (
    <View style={baseCardStyle.topImageWrapper}>
      {!isEmpty(topImageText) && <View style={baseCardStyle.tripTagInfo}>
        <Text style={[fonts.boldFontFamily]}>{topImageText}</Text>
      </View>}
      <Image style={baseCardStyle.topImageStyle} source={{ uri: topImage }} />
    </View>
  );
}

interface FlightRoundTripSectionTextProps {
  from?: string;
  to?: string;
  descriptionText?: string;
  showRoundTripIcon?: string;
}

export function FlightRoundTripSectionText({
  from = '',
  to = '',
  descriptionText = '',
  showRoundTripIcon = '',
}: FlightRoundTripSectionTextProps) {
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  return (
    <>
      <View style={[baseCardStyle.tripInfoSection, AtomicCss.marginBottom12]}>
        <>
          <View style={baseCardStyle.leftInfo}>
            {!isEmpty(from) && <Text style={[fsStyle.font14, { color: theme.color.black }, fonts.blackFontFamily, AtomicCss.marginBottom3]}>{from}</Text>}
          </View>
          <View style={baseCardStyle.arrowRightWrapper}>
            {!!showRoundTripIcon ?
              <Image style={baseCardStyle.arrowRightStyle} source={flightRoundTripIcon} /> :
              <Image style={baseCardStyle.arrowRightStyle} source={rightArrowGreyIcon} />}
          </View>
          <View style={baseCardStyle.rightInfo}>
            {!isEmpty(to) && <Text style={[baseCardStyle.textRight, fsStyle.font14, { color: theme.color.black }, fonts.blackFontFamily, AtomicCss.marginBottom3]}>{to}</Text>}
          </View>
        </>
      </View>
      <Text style={[fsStyle.font14, { color: theme.color.black74 }, fonts.regularFontFamily, AtomicCss.marginBottom12]}>{descriptionText} </Text>
    </>
  )

}

interface JourneyInfoTextProps {
  from?: string;
  startDateTime?: string;
  startDateText?: string;
  to?: string;
  endDateTime?: string;
  endDateText?: string;
}

export function JourneyInfoText({
  from = '',
  startDateTime = '',
  startDateText = '',
  to = '',
  endDateTime = '',
  endDateText = '',
}: JourneyInfoTextProps) {
  const width = getScreenWidth();
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();

  return (
    <View style={[baseCardStyle.tripInfoSection, AtomicCss.marginBottom12]}>
      <>
        <View style={baseCardStyle.leftInfo}>
          {!isEmpty(from) && <Text style={[fsStyle.font14, { color: theme.color.black }, fonts.blackFontFamily, AtomicCss.marginBottom3]}>{from}</Text>}
          {!isEmpty(startDateTime) && <Text style={[fsStyle.font14, { color: theme.color.defaultTextColor }, fonts.boldFontFamily, AtomicCss.marginBottom3]}>{startDateTime}</Text>}
          {!isEmpty(startDateText) && <Text style={[fsStyle.font12, { color: theme.color.black74 }, fonts.regularFontFamily]}>{startDateText}</Text>}
        </View>
        <View style={baseCardStyle.arrowRightWrapper}>
          <Image style={baseCardStyle.arrowRightStyle} source={rightArrowGreyIcon} />
        </View>
        <View style={baseCardStyle.rightInfo}>
          {!isEmpty(to) && <Text style={[baseCardStyle.textRight, fsStyle.font14, { color: theme.color.black }, fonts.blackFontFamily, AtomicCss.marginBottom3]}>{to}</Text>}
          {!isEmpty(endDateTime) && <Text style={[baseCardStyle.textRight, fsStyle.font14, { color: theme.color.defaultTextColor }, fonts.boldFontFamily, AtomicCss.marginBottom3]}>{endDateTime}</Text>}
          {!isEmpty(endDateText) && <Text style={[baseCardStyle.textRight, fsStyle.font12, { color: theme.color.black74 }, fonts.regularFontFamily]}>{endDateText}</Text>}
        </View>
      </>
    </View>
  );
}

interface JourneyHotelInfoTextProps {
  checkInText?: string;
  startDateText?: string;
  startDateTime?: string;
  checkOutText?: string;
  endDateText?: string;
  endDateTime?: string;
}

export function JourneyHotelInfoText({
  checkInText = '',
  startDateText = '',
  startDateTime = '',
  checkOutText = '',
  endDateText = '',
  endDateTime = '',
}: JourneyHotelInfoTextProps) {
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();

  return (
    <View style={[baseCardStyle.tripInfoSection, AtomicCss.marginBottom12]}>
      <View style={baseCardStyle.leftInfo}>
        {!isEmpty(checkInText) && <Text style={[fsStyle.font12, { color: theme.color.black }, fonts.boldFontFamily, AtomicCss.marginBottom6]}>{checkInText}</Text>}
        {!isEmpty(startDateText) && <Text style={[fsStyle.font14, { color: theme.color.defaultTextColor }, fonts.regularFontFamily, AtomicCss.marginBottom3]}>{startDateText}</Text>}
        {!isEmpty(startDateTime) && <Text style={[fsStyle.font14, { color: theme.color.defaultTextColor }, fonts.regularFontFamily]}>{startDateTime}</Text>}
      </View>
      <View style={baseCardStyle.rightInfo}>
        {!isEmpty(checkOutText) && <Text style={[baseCardStyle.textRight, fsStyle.font12, { color: theme.color.black }, fonts.boldFontFamily, AtomicCss.marginBottom6]}>{checkOutText}</Text>}
        {!isEmpty(endDateText) && <Text style={[baseCardStyle.textRight, fsStyle.font14, { color: theme.color.defaultTextColor }, fonts.regularFontFamily, AtomicCss.marginBottom3]}>{endDateText}</Text>}
        {!isEmpty(endDateTime) && <Text style={[baseCardStyle.textRight, fsStyle.font14, { color: theme.color.defaultTextColor }, fonts.regularFontFamily]}>{endDateTime}</Text>}
      </View>
    </View>
  );
}

interface JourneyStartsInfoTextProps {
  startsOnText?: string;
  startDate?: string;
  endsText?: string;
  endDate?: string;
  type?: string
}

export function JourneyStartsInfoText({
  startsOnText = '',
  startDate = '',
  endsText = '',
  endDate = '',
  type = 'known'
}: JourneyStartsInfoTextProps) {
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  const planningTab = (type == PAGE_BOOKINGS_STATUS.PLANNING);
  return (
    <View style={[baseCardStyle.tripInfoSection, planningTab ? AtomicCss.marginBottom3 : AtomicCss.marginBottom12]}>
      <View style={planningTab ? {} : baseCardStyle.leftInfo}>
        {!isEmpty(startsOnText) && <Text style={[fsStyle.font14, { color: theme.color.black }, fonts.boldFontFamily, AtomicCss.marginBottom6]}>{startsOnText}</Text>}
        {/* {!isEmpty(startsOnText) ? (!startDate && <Text style={[fsStyle.font14, theme.color.black, fonts.boldFontFamily, AtomicCss.marginBottom6]}>{startsOnText}</Text>) : <Text style={[fsStyle.font12, theme.color.black, fonts.boldFontFamily, AtomicCss.marginBottom6]}>{startsOnText}</Text>} */}
        {!isEmpty(startDate) && <Text style={[fsStyle.font14, { color: theme.color.defaultTextColor }, fonts.regularFontFamily, AtomicCss.marginBottom3]}>{startDate}</Text>}
      </View>
      <View style={planningTab ? {} : baseCardStyle.leftInfo}>
        {!isEmpty(endsText) && <Text style={[baseCardStyle.textRight, fsStyle.font12, { color: theme.color.black }, fonts.boldFontFamily, AtomicCss.marginBottom6]}>{endsText}</Text>}
        {!isEmpty(endDate) && <Text style={[baseCardStyle.textRight, fsStyle.font14, { color: theme.color.defaultTextColor }, fonts.regularFontFamily, AtomicCss.marginBottom3]}>{endDate}</Text>}
      </View>
    </View>
  );
}


interface CardInfoSectionProps {
  iconUrl?: string;
  icon?: string;
  flightIcon?: string;
  name?: string;
  startingPoint?: string;
  passengerDetails?: string;
  type?: string
}

export function CardInfoSection({
  flightIcon = '',
  iconUrl = '',
  icon = '',
  name = '',
  startingPoint = '',
  passengerDetails = '',
  type = "known"
}: CardInfoSectionProps) {
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  const planningTab = (type == PAGE_BOOKINGS_STATUS.PLANNING);

  return (
    <View style={[planningTab ? baseCardStyle.planningTopSection : baseCardStyle.topSection, AtomicCss.marginBottom10]}>
      <View style={baseCardStyle.infoSection}>
        <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
          { !isEmpty(flightIcon) && Array.isArray(flightIcon) && flightIcon.length > 1 ? 
          flightIcon.map((flightImage, index) => {
            const ismultiIcon = index !== 0 ? baseCardStyle.multiAirlinelogo : '';
            return (
              <View key={index} style={[baseCardStyle.airlineLogoWrapper, ismultiIcon]}>
                <Image style={baseCardStyle.airlineLogoStyle} source={{ uri: flightImage }} />
              </View>
            )
          })
         : (!isEmpty(flightIcon) && 
            <View style={baseCardStyle.airlineLogoWrapper}>
              <Image style={baseCardStyle.airlineLogoStyle} source={{ uri: flightIcon }} />
            </View>
          )}
          {!isEmpty(iconUrl) ? <View style={baseCardStyle.iconWrapper}>
            <Image style={baseCardStyle.iconStyle} source={iconUrl} />
          </View> :
            (!isEmpty(icon) && <View style={baseCardStyle.imageWrapper}>
              <Image style={{ height: 24, width: 24, marginRight: 4 }} source={icon} />
            </View>)}
          <View style={{ flex: 1 }}>
            {!isEmpty(name) && <Text style={flightIcon ? [{ color: theme.color.defaultTextColor }, fsStyle.font12, fonts.regularFontFamily, AtomicCss.marginLeft5] : [{ color: theme.color.defaultTextColor }, fsStyle.font12, fonts.regularFontFamily]}>{name}</Text>}
            {!isEmpty(startingPoint) && <Text style={flightIcon ? [{ color: theme.color.defaultTextColor }, fsStyle.font12, fonts.regularFontFamily,  AtomicCss.marginLeft12] : [{ color: theme.color.defaultTextColor }, fsStyle.font12, fonts.regularFontFamily]}>{startingPoint}</Text>}
          </View>
          {!isEmpty(passengerDetails) && <Text style={[{ color: theme.color.defaultTextColor }, fsStyle.font12, fonts.regularFontFamily, planningTab ? baseCardStyle.planningPassengerWrapper : baseCardStyle.passengerWrapper]}>{passengerDetails}</Text>}
        </View>
      </View>
    </View>
  );
}

interface VisaCardHeaderProps {
  iconUrl?: string,
  status?: string,
  paxInfo?: string,
}

export const VisaCardHeader = ({
  iconUrl = '',
  status = '',
  paxInfo = ''
}: VisaCardHeaderProps) => {
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  return (
    <View style={[baseCardStyle.visaTopSection, AtomicCss.marginBottom10]}>
      {!isEmpty(status) && <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
        <View style={baseCardStyle.visaTopInfoSection}>
          <Image style={baseCardStyle.visaTimerIconStyle} source={iconUrl} />
          <Text style={[baseCardStyle.visaStatusText, fsStyle.font12]}>{status}</Text>
        </View>
      </View>}
      {!isEmpty(paxInfo) && <Text style={[{ color: theme.color.defaultTextColor }, fsStyle.font12, fonts.regularFontFamily]}>{paxInfo}</Text>}
    </View>
  )
}

interface VisaCardSectionProps {
  iconUrl?: string,
  name?: string,
  visaType?: string,
  validityText?: string,
  validity?: string
}

export const VisaCardSection = ({
  iconUrl = '',
  name = '',
  visaType = '',
  validityText = '',
  validity = ''
}: VisaCardSectionProps) => {
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  return (
    <View>
      <View style={[AtomicCss.flexRow, AtomicCss.marginBottom12]}>
        <View style={baseCardStyle.visaIconWrapper}>
          {!isEmpty(iconUrl) && <Image style={baseCardStyle.visaIconStyle} source={{ uri: iconUrl }} />}
        </View>
        <View style={[AtomicCss.flex1]}>
          {!isEmpty(name) && <Text style={[fonts.blackFontFamily, { color: theme.color.black }, fsStyle.font16, AtomicCss.marginBottom5]}>{name}</Text>}
          {!isEmpty(visaType) && <Text style={[fonts.regularFontFamily, { color: theme.color.defaultTextColor }, fsStyle.font12]}>{visaType}</Text>}
        </View>
      </View>
      <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom12]}>
        {!isEmpty(validityText) && <Text style={[fonts.boldFontFamily, { color: theme.color.black }, fsStyle.font12, AtomicCss.marginRight4]}>{validityText}</Text>}
        {!isEmpty(validity) && <Text style={[fonts.regularFontFamily, { color: theme.color.defaultTextColor }, fsStyle.font12]} >{validity} </Text>}
      </View>
    </View>
  )
}

interface HeaderSectionProps {
  iconUrl?: string;
  name?: string;
}

export function HeaderSection({
  iconUrl = '',
  name = ''
}: HeaderSectionProps) {
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  return (
    <View style={[baseCardStyle.headingInfoSection, AtomicCss.marginBottom12]}>
      {!isEmpty(iconUrl) && <View style={baseCardStyle.iconWrapper}>
        <Image style={baseCardStyle.iconStyle} source={iconUrl} />
      </View>}
      {!isEmpty(name) && <Text style={[{ color: theme.color.black }, baseCardStyle.headingInfoText, fonts.blackFontFamily, fsStyle.font14]}>{name}</Text>}
    </View>
  )
}

interface HotelCardInfoSectionProps {
  destCityName?: string;
  passengerDetails?: string;
}

export function HotelCardInfoSection({
  destCityName = '',
  passengerDetails = ''
}: HotelCardInfoSectionProps) {
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();

  return (
    <View style={[baseCardStyle.rowSection, AtomicCss.marginBottom10]}>
      {!isEmpty(destCityName) && <Text style={[{ color: theme.color.defaultTextColor }, fsStyle.font12, fonts.boldFontFamily]}>{destCityName}</Text>}
      {!isEmpty(passengerDetails) && <Text style={[{ color: theme.color.defaultTextColor }, fsStyle.font12, fonts.regularFontFamily]}>{passengerDetails}</Text>}
    </View>
  );
}

interface DescriptionCardInfoSectionProps {
  icon?: string;
  imageUrl?: string;
  trainIcon?: string;
  descriptionText?: string;
}

export function DescriptionCardInfoSection({
  icon = '',
  imageUrl = '',
  trainIcon = '',
  descriptionText = ''
}: DescriptionCardInfoSectionProps) {
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();

  return (
    <View style={[baseCardStyle.htlInfoSection, AtomicCss.marginBottom8]}>
      {!isEmpty(imageUrl) ? (
        <View style={baseCardStyle.imageWrapper}>
          <Image style={{ height: 36, width: 36, borderRadius: 4, marginRight: 4 }} source={{ uri: icon }} />
        </View>
      ) : (!isEmpty(icon) && <View style={baseCardStyle.imageWrapper}>
        <Image style={{ height: 28, width: 28, marginRight: 4 }} source={icon} />
      </View>)}
      {!isEmpty(trainIcon) && (<View style={baseCardStyle.imageWrapper}>
        <Image style={{ height: 18, width: 15, marginRight: 4 }} source={trainIcon} />
      </View>)}
      {!isEmpty(descriptionText) && <Text style={[{ color: theme.color.black }, fonts.blackFontFamily, fsStyle.font14]}>{descriptionText}</Text>}
    </View>
  );
}

interface StatusInfoSectionProps {
  statusText?: string;
  statusCta?: string;
  handleInfoPress?: () => void;
}

export function StatusInfoSection({
  statusText = '',
  statusCta = '',
  handleInfoPress
}: StatusInfoSectionProps) {
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();

  return (
     <View style={[baseCardStyle.statusSection, AtomicCss.flexRow, AtomicCss.spaceBetween]}>
      {!isEmpty(statusText) && <Text style={[{ color: theme.color.black }, fonts.regularFontFamily, fsStyle.font12, AtomicCss.flex1]}>{statusText}</Text>}
      {!isEmpty(statusCta) && (
        <TouchableOpacity onPress={handleInfoPress}>
          <Text style={[{ color: theme.color.azure }, fsStyle.font12, fonts.boldFontFamily, AtomicCss.marginLeft4]}>{statusCta}</Text>
        </TouchableOpacity>
      )}
    </View>
  );
}

interface LostBookingPaymentStatusProps {
  paymentTextLine1?: string,
  paymentTextLine2?: string,
}

export function LostBookingPaymentStatus({
  paymentTextLine1 = '',
  paymentTextLine2 = ''
}: LostBookingPaymentStatusProps) {
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  return (
    <View style={[baseCardStyle.pendingtripInfoSection]}>
      <Text style={[fsStyle.font12, { color: theme.color.black }, fonts.regularFontFamily, AtomicCss.lineHeight18]}>
        {!isEmpty(paymentTextLine1) && <Text style={baseCardStyle.highLightedText}>
          {paymentTextLine1}
        </Text>}
        {!isEmpty(paymentTextLine2) && paymentTextLine2}
      </Text>
    </View>
  )
}