import React from 'react';
import {View, Text,StyleSheet} from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
import {
    findNumberOfDaysBetweenDates
} from '@mmt/legacy-commons/Common/utils/DateUtils';
import { styles } from './CancelAndRebookStyles';
import {CancelAndRebookConstants} from './CancelAndRebookConstants';

class CabDatePicker extends React.Component{
    render(){
        const activePicker = [styles.activePickerStyles]
        const activePickerHeading = [styles.activePickerheadingStyles]
        return(
            <View style={styles.pickerWrapper}>
                <View style={ this.props.isPickUp ? ([styles.pickerBox, activePicker]) : ([styles.pickerBox])}>
                    {
                        this.props.isPickUp ?
                            ( <Text style={[AtomicCss.boldFont,AtomicCss.greyText,AtomicCss.font11,AtomicCss.marginBottom5, activePickerHeading]}>{CancelAndRebookConstants.TRIP_START}</Text>) :
                            ( <Text style={[AtomicCss.boldFont,AtomicCss.greyText,AtomicCss.font11,AtomicCss.marginBottom5]}>{CancelAndRebookConstants.TRIP_START}</Text>)
                    }

                    <View style={[AtomicCss.flexRow,AtomicCss.marginBottom5]}>
                        <Text style={[AtomicCss.blackFont,AtomicCss.blackText,AtomicCss.font14]}>{this.props.formData.fromDate.day} { ' '} {this.props.formData.fromDate.month}</Text>
                        <Text style={[AtomicCss.regularFont,AtomicCss.defaultText,AtomicCss.font12]}>  {this.props.formData.fromDate.week},  {this.props.formData.fromDate.year}</Text>
                    </View>
                    <Text style={[AtomicCss.boldFont,AtomicCss.blackText,AtomicCss.font12]}>{this.props.formData.fromDate.time}</Text>
                    <View style={styles.borderDaysLeftBox}></View>
                </View>
                <View style={styles.daysCountWrapper}>
                    <View style={styles.daysCount}>
                        <Text style={[AtomicCss.greyText,AtomicCss.font10,AtomicCss.boldFont]}>{findNumberOfDaysBetweenDates(this.props.formData.fromDate.date,this.props.formData.toDate.date)} DAYS</Text>
                    </View>
                </View>
                <View style={!this.props.isPickUp ? ([styles.pickerBox,styles.tripEndBox, activePicker]):([styles.pickerBox,styles.tripEndBox])}>
                    <View style={[styles.borderDaysRightBox,styles.activeBorder]}></View>
                    {
                        !this.props.isPickUp ?
                            (<Text style={[AtomicCss.boldFont,AtomicCss.greyText,AtomicCss.font11,AtomicCss.marginBottom5, activePickerHeading]}>Trip End</Text>):
                            (<Text style={[AtomicCss.boldFont,AtomicCss.greyText,AtomicCss.font11,AtomicCss.marginBottom5]}>Trip End</Text>)
                    }

                    {
                        this.props.showReturnDate ? (<React.Fragment><View style={[AtomicCss.flexRow,AtomicCss.marginBottom5]}>
                                <Text style={[AtomicCss.blackFont,AtomicCss.blackText,AtomicCss.font14]}>{this.props.formData.toDate.day} { ' '} {this.props.formData.toDate.month}</Text>
                                <Text style={[AtomicCss.regularFont,AtomicCss.defaultText,AtomicCss.font12]}> {this.props.formData.toDate.week},  {this.props.formData.toDate.year}</Text>
                            </View>
                            <Text style={[AtomicCss.boldFont,AtomicCss.blackText,AtomicCss.font12]}>{this.props.formData.toDate.time}</Text></React.Fragment>) :
                            (<View style={[AtomicCss.flexRow,AtomicCss.marginBottom5]}>
                                <Text style={[AtomicCss.regularFont,AtomicCss.defaultText,AtomicCss.font12]}> Add date and time to change to return trip</Text>
                            </View>)
                    }

                </View>
            </View>


        )
    }

}
export default CabDatePicker
