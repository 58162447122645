import React from 'react';
import { View, Text, TouchableOpacity, Image, FlatList } from 'react-native';
import { BG_BLUE_RIGHT_ARROW } from '../../../FlightImageConstants';
import styles from './SpecialClaimBottomSheetCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { CANCELLATION_REASONS } from '../../Constant';
import { getFont } from '../../../../PostSalesStyles';
import { getImageTransformStyling } from 'apps/post-sales/src/utils/PostSaleUtil';

const DONT_SHOW_FULL_CANCELLATION = 1;

const ReasonScreen = ({ reasons, header, handleTab, specialClaimVariant, src }) => {
  const { fsStyle, ...fonts } = getFont(true);
  const renderSpecialClaimCTAs = ({ item, index }) => {
    const lastItem = index === reasons.length - 1 ? 'lastItemStyle' : '';
    if (
      (src === 'EXPECTING_REFUND' || specialClaimVariant === DONT_SHOW_FULL_CANCELLATION) &&
      item.scCode === CANCELLATION_REASONS.PLANS_CHANGED
    )
      return null;
    return (
      <TouchableOpacity
        style={[styles.listWrapper, styles[lastItem]]}
        onPress={handleTab.bind(null, item.scCode)}
      >
        <View style={[AtomicCss.flex1, AtomicCss.flexRow]}>
          {!!item.iconUrl && <Image style={styles.iconStyle} source={{ uri: item.iconUrl }} />}
          <View style={[AtomicCss.flex1]}>
            <Text style={[styles.titleTxt, fonts.blackFontFamily, fsStyle.font16]}>
              {item.explTxt}
            </Text>
            <Text style={[styles.desc, fsStyle.font14]}>{item.explSubTxt}</Text>
          </View>
        </View>
        <Image style={[styles.arrowStyle, getImageTransformStyling()]} source={BG_BLUE_RIGHT_ARROW} />
      </TouchableOpacity>
    );
  };

  return (
    <React.Fragment>
      <Text style={[styles.title, fonts.blackFontFamily, fsStyle.font22]}>{header}</Text>
      <FlatList
        showsVerticalScrollIndicator={false}
        data={reasons}
        renderItem={renderSpecialClaimCTAs}
        keyExtractor={(i) => i.scCode}
      />
    </React.Fragment>
  );
};

export default ReasonScreen;
