import React from 'react';
import PropTypes from 'prop-types';
import {StyleSheet, Text, View} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import {colors, fonts} from '../../../Styles/globalStyles';
import getPlatformElevation from '../Card/getPlatformElevation';
import TouchableRipple from '../TouchableRipple';
import {normalisePx} from '../../../Styles/globalStyles';


const CtaButton = ({
  height, label, onPress, customTextStyle, horizontalPadding, buttonGradient = ['#53B2FE', '#065AF3']
}) => (

  <TouchableRipple
    feedbackColor={colors.transparent}
    onPress={() => onPress()}
  >
    <View>
      <LinearGradient
        colors={buttonGradient}
        start={{x: 0.0, y: 0.0}}
        end={{x: 1.0, y: 0.0}}
        style={[styles.cta, {height, borderRadius: (height / 2), paddingHorizontal: horizontalPadding}]}
      >
        <Text style={customTextStyle || styles.text}>{label.toUpperCase()}</Text>
      </LinearGradient>
    </View>
  </TouchableRipple>
);

CtaButton.propTypes = {
  height: PropTypes.number,
  horizontalPadding: PropTypes.number,
  label: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
  customTextStyle: PropTypes.object,
  buttonGradient: PropTypes.array
};

CtaButton.defaultProps = {
  height: normalisePx(40),
  horizontalPadding: normalisePx(20),
  customTextStyle: null,
  buttonGradient: ['#53B2FE', '#065AF3']
};

const styles = ({
  cta: {
    overflow: 'hidden',
    backgroundColor: colors.azure,
    alignItems: 'center',
    justifyContent: 'center',
    ...getPlatformElevation(4)
  },
  text: {
    color: colors.white,
    fontSize: 10,
    fontFamily: fonts.black,
    backgroundColor: colors.transparent
  }
});

export default CtaButton;
