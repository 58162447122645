import React, {useEffect, useState} from 'react';
import { View, Platform } from 'react-native';
import Header from '@mmt/legacy-commons/Common/Components/Header/SimpleHeader';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import LoadingCard from './components/LoadingCard';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import { getCommonHeaders, REFUND_CALCULATION_URL_V2 } from '../../utils/NetworkUtils';
import ErrorCard from './components/ErrorCard';
import RefundDetailCard from './components/RefundDetailCard';
import FlightBookingTrackingHelper from '../../flights/modules/details/helper/FlightBookingTrackingHelper';
import Actions from '../../navigation/postSalesNavigation';
import ProgressView from '../ProgressView/ProgressView';
import { getFont } from '../../PostSalesStyles';

const ViewState = {
  LOADING: 'loading',
  LOADED: 'loaded',
  ERROR: 'error'
};

const RefundBreakUpV2 = ({ bookingId, apiPSLob }) => {
  const fonts = getFont(true);
  const [viewState, setViewState] = useState(ViewState.LOADING);
  const [response, setResponse] = useState(undefined);

  const onApiError = (bookingId, apiResponse) => {
    setViewState(ViewState.ERROR);
    FlightBookingTrackingHelper.trackErrorWithData("mob:customer support: flight:refundBreakupV2Error", bookingId, apiResponse, undefined);
  }

  useEffect(() => {
    fetchPageData();
  }, [bookingId]);

  const fetchPageData = async () => {
    try{
      setViewState(ViewState.LOADING);
      const userInfo = await HotelBookingModule.getRequestHeader(bookingId, 'REFUND_BREAKUP_DETAILS_V2');
      const apiResponse = await fetch(
        `${REFUND_CALCULATION_URL_V2 + bookingId}`,
        {headers: await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo, { psLob : apiPSLob })}
      );
      if (apiResponse && apiResponse.ok) {
        const body = await apiResponse.json();
        if (body.success) {
          setViewState(ViewState.LOADED);
          setResponse(body);
        }
        else {
          onApiError(bookingId, apiResponse);
        }
      }
      else {
        onApiError(bookingId, apiResponse);
      }
    }
    catch(e){
      onApiError(bookingId, e);
    }
  }

  const onBackPress = () => {
    if(response)
      FlightBookingTrackingHelper.trackClickEvent("refundBreakupV2", "MI_RefundBreakup_Back_Click", response);
      Actions.pop();
  }

  const renderContent = () => {
    switch(viewState){
      case ViewState.LOADING: return Platform.OS === 'web' ? <ProgressView /> : <LoadingCard />;
      case ViewState.ERROR: return <ErrorCard fetchData={fetchPageData}/>;
      case ViewState.LOADED: return <RefundDetailCard refundDetails={response} fetchData={fetchPageData} />;
      default: return <ErrorCard fetchData={fetchPageData}/>;
    }
  }

  return(
    <View style={[AtomicCss.flex1, AtomicCss.greyBg]}>
      <Header
        title={response && response.heading ? response.heading : ""}
        textFont={fonts.bold}
        elevation={2}
        textSize={18}
        iconPress={onBackPress}
      />
      {renderContent()}
    </View>
  );
}

export default RefundBreakUpV2;
