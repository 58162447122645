import { StyleSheet } from 'react-native';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
import { DEFAULT_THEME } from 'apps/post-sales/src/theme/default.theme';

export default <T extends typeof DEFAULT_THEME>(theme: T, fonts: any) =>
  StyleSheet.create({
    viewRequest: {
      backgroundColor: theme.color.creamWhite,
      paddingVertical: 12,
      paddingHorizontal: 8,
      borderBottomLeftRadius: 2,
      borderBottomRightRadius: 2,
      ...getPlatformElevation(2),
    },
    refundRequestCard: {
      backgroundColor: theme.color.creamWhite,
      marginHorizontal: -12,
      marginBottom: -12,
      paddingVertical: 10,
      paddingHorizontal: 12,
      borderBottomLeftRadius: 16,
      borderBottomRightRadius: 16,
      marginTop: 15,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    refundRequestStyle: {
      width: 22,
      height: 23,
      marginRight: 5,
    },
    headingStyle: {
      color: theme.color.black, 
      ...fonts.blackFontFamily, 
      fontSize: fonts.fontSize.font14
    },
    subHeadingStyle:{
      color: theme.color.black, 
      ...fonts.regularFontFamily, 
      fontSize: fonts.fontSize.font14
    },
    ctaStyle: {
      color: theme.color.azure, 
      ...fonts.blackFontFamily,
      fontSize:fonts.fontSize.font14
    },
    headingWrapper:{
      width: '90%'
    }
  });
