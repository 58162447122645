import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import { Dimensions } from 'react-native';

const windowWidth = Dimensions.get('screen').width;

const styles = {
  cardWrapper: {
    backgroundColor: colors.white,
    elevation: 3,
    shadowColor: '#000000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 2,
    borderRadius: 4,
  },
  checkAllWrapper: {
    borderBottomWidth: 1,
    borderBottomColor: colors.lightGrey,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    backgroundColor: colors.white,
    marginBottom: 2,
  },
  sectorPaxList: {
    paddingVertical: 19,
    paddingHorizontal: 45,
  },
  sectorBorderStyle: {
    borderBottomColor: '#e7e7e7',
    borderBottomWidth: 1,
  },
  checkBtn: {
    flexDirection: 'row',
    paddingVertical: 10,
    paddingHorizontal: 15,
    alignItems: 'center',
  },
  checkAllBtn: {
    alignItems: 'flex-start',
  },
  checkbox: {
    height: 18,
    width: 18,
    backgroundColor: colors.white,
    borderColor: colors.disabledBtnBg,
    borderWidth: 1.5,
    borderRadius: 3,
    overflow: 'hidden',
    position: 'relative',
    marginRight: 12,
  },
  checkboxSelected: {
    borderColor: 'transparent',
    borderWidth: 0,
  },
  checkboxAll: {
    marginTop: 10,
  },
  checkedIcon: {
    position: 'absolute',
    height: 29,
    width: 29,
    left: -5,
    top: -4,
  },
  destRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  textRow: {
    maxWidth: windowWidth - 120,
    flexWrap: 'wrap', 
    flexDirection: 'row', 
    alignItems: 'center'
  },
  cityText: {
    color: colors.black28,
  },
  arrowIcon: {
    width: 10,
    height: 7,
    marginHorizontal: 5,
  },
  rtInfo: {
    color: '#cf8100',
    textAlign: 'left',
    marginBottom: 10,
    paddingTop: 10,
    paddingRight: 10,
    width: '40%',
  },
  smallText: {
    color: colors.defaultTextColor,
    marginTop: 5,
    marginBottom: 0,
    paddingBottom: 2,
  },
  passengerName: {
    fontSize: 14,
    color: colors.black,
  },
  passengersList: {
    paddingHorizontal: 0,
    marginBottom: 18,
  },
  redInfoIcon: {
    width: 16,
    height: 16,
    marginRight: 10,
  },
  redItalicText: {
    color: colors.red,
    fontSize: 12,
    fontStyle: 'italic',
  },
  redInfoWrappe: {
    padding: 16,
    paddingTop: 6,
    flexDirection: 'row',
  },
  segmentRow: {
    paddingBottom: 5,
  },
  PaxslctionHeader: {
    flex: 1,
    justifyContent: 'space-between',
  },
  SectorHeader: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
  },
  checkAllWrapperInner: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  airlinesIconWrapper: {
    width: 33,
    height: 33,
    marginHorizontal: 12,
  },
  airlineIcon: {
    width: 32,
    height: 32,
  },
  fltInfo: {
    color: '#cf8100',
    textAlign: 'left',
    flexWrap: 'wrap',
    marginBottom: 10,
    paddingRight: 20,
    paddingTop: 15,
    width: '40%',
  },
  childInfoAlert: {
    fontSize: 12,
    color: '#cf8100',
    paddingTop: 5,
  },
  destCityWrapper: {
    alignItems: 'center',
    flex: 1,
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  flexOne: {
    flex: 1,
  },
  paxDisabled: {
    backgroundColor: '#f9f9f9',
    opacity: 0.7,
  },
  margin_bottom10: {
    marginBottom: 10,
  },
  passengerCancelled: {
    fontSize: 14,
    color: colors.black,
    textDecorationLine: 'line-through',
  },
  boxDisabled: {
    backgroundColor: '#f9f9f9',
    opacity: 0.7,
  },
  cancelledTxt: {
    color: '#eb2026',
    fontSize: 12,
  },
  disableChkBox: {
    backgroundColor: '#e7e7e7',
    borderColor: '#cccccc',
  },
  disableTxt: {
    color: '#747474',
    fontSize: 14,
  },
  errorInfoTxt: {
    color: '#e53442',
    paddingLeft: 45,
    paddingRight: 20,
    paddingBottom: 10,
  },
  specialclaim: {
    paddingLeft: 40,
    paddingRight: 20,
  },
  sclaimInfo: {
    fontSize: 12,
    color: '#cf8100',
    paddingLeft: 45,
    paddingRight: 20,
    paddingBottom: 5,
  },
  paxDescrp: {
    fontSize: 12,
    paddingVertical: 10,
    paddingHorizontal: '18%',
  },
  paxHeading: {
    fontSize: 12,
    color: '#cf8100',
    paddingHorizontal: '18%',
  },
  warningIcon: {
    width: 27,
    height: 27,
    marginBottom: 7,
    marginLeft: 16,
  },
  specialClaimCont: {
    paddingVertical: 10,
    backgroundColor: colors.grayBg,
    borderBottomColor: colors.lightGray,
    borderBottomWidth: 1,
  },
  sclaimHeader: {
    color: '#282828',
    paddingLeft: 8,
    paddingRight: 20,
    paddingBottom: 8,
    paddingTop: 6,
  },
  scHeading: {
    flexDirection: 'row',
  },
  descBorder: {
    borderTopColor: '#e7e7e7',
    borderTopWidth: 1,
  },
  description: {
    marginRight: '5%',
    marginLeft: '13%',
    paddingVertical: 8,
  },
};

export default styles;
