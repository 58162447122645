import React from 'react';
import { Image, Text, View, StyleSheet, Platform } from 'react-native';
import PropTypes from 'prop-types';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import CapsuleBtnFill from '../../../../../hotel/details/ActionButton/CapsuleBtnFill';
import CapsuleBtn from '../../../../../hotel/details/ActionButton/CapsuleBtn';
import FlightBookingDetailsConstant from '../../FlightBookingDetailsConstant';
import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';
import { CHANGE_IN_PLAN_ICON } from '../../../../FlightImageConstants';
import { getStaticData } from '../../../../../staticData/staticData';
import { getFont } from '../../../../../PostSalesStyles';

class ChangePlan extends React.Component {
  render() {
    const { fsStyle, ...fonts } = getFont(true);
    const {
      changePlanCardText: {
        heading,
        additionalText: { cancellationOptionsText, modificationOptionsText },
      },
    } = getStaticData();
    return (
      <View style={[styles.cardWrapper, styles.elevation1]}>
        <View style={styles.CardContent}>
          <View style={styles.headingIconWrapper}>
            <Image style={styles.CardIcon} source={CHANGE_IN_PLAN_ICON} />
          </View>
          <View style={[styles.ContentPortion, AtomicCss.flexRow, AtomicCss.spaceBetween]}>
            <Text style={[AtomicCss.defaultText, fonts.blackFontFamily, fsStyle.font14]}>
              {heading}
            </Text>
          </View>
        </View>
        <View style={[styles.ActionBtn, styles.transparentBgrnd]}>
          {(this.props.response.cancelCardDetailInfo ||
            this.props.response.specialClaimDataList) && (
            <CapsuleBtnFill
              action={this.getActionObj(
                cancellationOptionsText,
                FlightBookingDetailsConstant.ACTION_CANCELLATION_OPTIONS,
              )}
              clickHandler={this.props.clickHandler}
            />
          )}
          {this.props.response.datechangeCacheDetail && (
            <CapsuleBtn
              action={this.getActionObj(
                modificationOptionsText,
                FlightBookingDetailsConstant.ACTION_MODIFICATION_OPTIONS,
              )}
              clickHandler={this.props.clickHandler}
            />
          )}
        </View>
      </View>
    );
  }

  getActionObj = (btnText, btnActionFamily) => {
    const action = {};
    action.actionLabeltext = btnText;
    action.btnActionFamily = btnActionFamily;
    return action;
  };
}

const styles = StyleSheet.create({
  cardWrapper: {
    marginBottom: 10,
  },
  CardContent: {
    flexDirection: 'row',
    backgroundColor: '#fff',
  },
  CardIcon: {
    width: 24,
    height: 24,
    marginRight: 15,
  },
  ContentPortion: {
    paddingHorizontal: 8,
    paddingVertical: 15,
    flex: 1,
  },
  ActionBtn: {
    backgroundColor: '#fff',
    paddingBottom: 10,
    marginTop: 15,
    paddingLeft: 48,
    paddingRight: 48,
  },
  transparentBgrnd: {
    ...Platform.select({
      ios: {
        backgroundColor: 'transparent',
      },
    }),
  },
  elevation1: {
    backgroundColor: '#ffffff',
    ...getPlatformElevation(1),
  },
  headingIconWrapper: {
    width: 46,
    alignItems: 'flex-start',
    top: 14,
    left: 16,
  },
});

export default ChangePlan;

ChangePlan.propTypes = {
  clickHandler: PropTypes.func.isRequired,
  response: PropTypes.func.isRequired,
};
