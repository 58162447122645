import React from 'react';
import { View, Text, Image, TouchableOpacity, StyleSheet } from 'react-native';
import HTMLView from 'react-native-htmlview';
import PropTypes from 'prop-types';
import styles from './paymentCardCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import FlightBookingDetailsConstant from '../../FlightBookingDetailsConstant';
import { imageTransformY } from '../../../../../utils/PostSaleUtil';
import { isEmpty, get } from 'lodash';
import { getStaticData } from '../../../../../staticData/staticData';
import { REFUND_PAYMENT_ICON, RIGHT_ARROW } from '../../../../FlightImageConstants';
import { getFont } from '../../../../../PostSalesStyles';
import { externalLinkHandler } from '../../../../FlightBookingUtil';
import CashbackBanner from '../../../../../Common/CashbackBanner';
import {getPriceBreakCardDetails} from '../../utils/priceBreakupUtil';
import { getTicketsActionList } from '../../../../../summary/components/cards/flights/flightsCardAdapter';
import RedirectLinkImage from 'apps/post-sales/src/Common/RedirectLinkImage';

class PaymentCard extends React.Component {
  static navigationOptions = {
    header: null,
  };
  
  redirectToBnplPay = () => {
    const action = get(
      this.props.paymentDetails,
      'payLaterDetails.payLaterDescription.action',
      null,
    );
    if (!isEmpty(action)) externalLinkHandler(action);
  };

  render() {
    const fonts= getFont(true);
    const {fsStyle} = fonts;
    const htmlStyle = StyleSheet.create({
      q: {
        fontFamily: fonts.regular,
      },
      p: {
        color: '#1a7971',
        ...fonts.fsStyle.font14,
      },
    });
    const bnplHtmlStyle = StyleSheet.create({
      q: {
        fontFamily: fonts.regular,
      },
      p: {
        ...fonts.fsStyle.font14,
      },
      b: {
        fontFamily: fonts.bold,
        ...fonts.fsStyle.font14,
      },
    });
    const staticData = getStaticData();
    const {
      flightPaymentPriceBreakUpText: {
        additionalText: { downloadInvoiceText },
      },
    } = staticData

    const {
      ispayLater:isBnplBooking,
      paymentModeHeader: bnplsHeader,
      payLaterSubHeader: bnplSubHeader,
      payLaterActionUrlText: actionUrlText,
      payLateraActionUrl: actionUrl,
      iconUrl: bnplPartnerIcon,
      cashbackInformationList,
      failedAncillaryText:failedAncillaryRefundText,
      cashbackMsg,
      amtPaid,
      bnplPaidText,
      priceBreakUpList
    } = getPriceBreakCardDetails(this.props.flightDetailResponse, staticData) || {};

    const ticketsActionList = getTicketsActionList(
      this.props.flightDetailResponse
    );
    const ticketsListData = ticketsActionList || [];
    return (
      <View style={[AtomicCss.marginBottom10, styles.elevation1]}>
        <View style={[styles.infoCardWrapper]}>
          <View style={AtomicCss.flexRow}>
            <View style={styles.headingIconWrapper}>
              <Image
                style={[styles.headingIconStyle, imageTransformY]}
                source={REFUND_PAYMENT_ICON}
              />
            </View>
            <View style={[styles.infoCardContent, AtomicCss.flexRow, AtomicCss.spaceBetween]}>
              <Text
                style={[
                  AtomicCss.defaultText,
                  fonts.blackFontFamily,
                  fsStyle.font14,
                  AtomicCss.marginBottom3,
                ]}
              >
                {this.props.title}
              </Text>
              <View style={styles.totalRefundprice}>
                <Text style={[AtomicCss.defaultText, fonts.blackFontFamily, fsStyle.font14]}>
                  {amtPaid}{' '}
                </Text>
              </View>
            </View>
          </View>
          {!isEmpty(cashbackInformationList) &&
            cashbackInformationList.map((item) => (
              <View style={AtomicCss.flexRow}>
                <CashbackBanner
                  iconUrl={item.iconUrl}
                  cashbackText={item.cashbackText}
                  backgroundColor={item.backgroundColor}
                  color={item.color}
                />
              </View>
            ))}
          {!isEmpty(bnplPaidText) && !isBnplBooking && (
            <View style={[styles.gstInfoWrapper, { backgroundColor: '#ffedd1' }]}>
              <Text
                style={[
                  { color: '#cf8100' },
                  AtomicCss.alignLeft,
                  fonts.regularFontFamily,
                  fsStyle.font14,
                ]}
              >
                {bnplPaidText}
              </Text>
            </View>
          )}
          {isBnplBooking && (
            <View style={[styles.paylaterComp, AtomicCss.marginBottom12]}>
              {!!bnplPartnerIcon && (
                <Image style={styles.paylaterIcon} source={{ uri: bnplPartnerIcon }} />
              )}
              <View style={styles.paylaterRight}>
                {!isEmpty(bnplsHeader) && (
                  <Text
                    style={[
                      fsStyle.font17,
                      fonts.blackFontFamily,
                      AtomicCss.defaultText,
                      AtomicCss.lineHeight28,
                      AtomicCss.marginBottom4,
                    ]}
                  >
                    {bnplsHeader}
                  </Text>
                )}
                {!isEmpty(bnplSubHeader) && (
                  <HTMLView
                    value={`<q><p>${bnplSubHeader} ${
                      actionUrl && actionUrlText
                        ? `<a href=${actionUrl}><b>${actionUrlText}</b></a>`
                        : ''
                    }</p></q>`}
                    stylesheet={bnplHtmlStyle}
                    onLinkPress={(url) => {
                      this.redirectToBnplPay(url);
                    }}
                  />
                )}
              </View>
            </View>
          )}
          {!isEmpty(cashbackMsg) && (
            <View style={[styles.gstInfoWrapper, { backgroundColor: '#bfe4e0' }]}>
              <HTMLView value={`<q><p>${cashbackMsg}</p></q>`} stylesheet={htmlStyle} />
            </View>
          )}
          {!isEmpty(failedAncillaryRefundText) && (
            <View style={[styles.gstInfoWrapper, { backgroundColor: '#dde8ff' }]}>
              <Text
                style={[
                  { color: '#053cb2' },
                  AtomicCss.alignLeft,
                  fonts.regularFontFamily,
                  fsStyle.font14,
                ]}
              >
                {failedAncillaryRefundText}
              </Text>
            </View>
          )}
          {!isEmpty(this.props.ctripMsg) && (
            <View style={[styles.gstInfoWrapper, { backgroundColor: '#dde8ff' }]}>
              <Text
                style={[
                  { color: '#053cb2' },
                  AtomicCss.alignLeft,
                  fonts.regularFontFamily,
                  fsStyle.font14,
                ]}
              >
                {this.props.ctripMsg}
              </Text>
            </View>
          )}
        </View>
        {!isEmpty(priceBreakUpList) && (
        <TouchableOpacity
          style={styles.redirectBtnWrapper}
          activeOpacity={0.8}
          onPress={() => {
            this.onBtnClicked(FlightBookingDetailsConstant.ACTION_PAYMENT_DETAILS_PAGE);
          }}
        >
          <Text style={[styles.linkBtnTxt, fonts.boldFontFamily, fsStyle.font14]}>
            {this.props.btnTxt}
          </Text>
          <Image style={[styles.rightArrowIcon, imageTransformY]} source={RIGHT_ARROW} />
        </TouchableOpacity>
        )}
        {this.props.downloadInvoiceEnable && (
          <TouchableOpacity
            style={styles.redirectBtnWrapper}
            activeOpacity={0.8}
            onPress={() => {
              this.onBtnClicked(FlightBookingDetailsConstant.ACTION_DOWNLOAD_INVOICE);
            }}
          >
            <Text style={[styles.linkBtnTxt, fonts.boldFontFamily, fsStyle.font14]}>
              {downloadInvoiceText}
            </Text>
            <Image style={[styles.rightArrowIcon, imageTransformY]} source={RIGHT_ARROW} />
          </TouchableOpacity>
        )}
        {ticketsListData.length > 0 && (
          <View style={styles.redirectListWrapper}>
            {ticketsListData.map((ticketData, index) => (
              <RedirectLinkImage
                key={`RedirectLink${ticketData.actionFamily}`}
                action={ticketData}
                clickHandler={() => this.onBtnClicked(FlightBookingDetailsConstant.ACTION_DOWNLOAD_VOUCHER)}
              />
            ))}
          </View>
        )}
      </View>
    );
  }

  onBtnClicked = (action) => {
    this.props.onBtnClick(action);
  };
}

export default PaymentCard;

PaymentCard.propTypes = {
  paymentDetails: PropTypes.object.isRequired,
  bnplMsg: PropTypes.string.isRequired,
  ctripMsg: PropTypes.string.isRequired,
  onBtnClick: PropTypes.func.isRequired,
};
