import React from 'react';
import PropTypes from 'prop-types';
import { DeviceEventEmitter, Image, Platform, Text, TouchableOpacity, View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import AtomicCss from '../../commonStyles/AtomicCss';
import {
  getThrottleParams,
  handleRailClickEvent,
} from '../../details/RailBookingDetailsUtil';
import {
  getCardbyCardId,
  getRoundIcon,
  getSegmentInfo,
  getTopStatusCardColor,
} from '../../details/data/helper';
import { getTopStatusData } from '../../details/data/dataAdapter';
import isEmpty from 'lodash/isEmpty';
import RailBookingDetailConstant from '../../details/RailBookingDetailConstant';
import {
  ANDROID_BACK_ARROW,
  IOS_BACK_ARROW,
  CORY_ICON,
} from '../../Utils/RailImageConstant';
import { getStaticData } from '../../../staticData/staticData';
import { getFont } from '../../../PostSalesStyles';
import { COPY_ICON_GREEN } from '../../Utils/RailImageConstant';
import { copyToClipboard } from 'apps/post-sales/src/Common/commonUtil';
import { showLongToast } from 'packages/core/helpers/toast';
import { ThemeContext } from 'apps/post-sales/src/theme/theme.context';
import getStyles from './style';
import BaseButton from '../../../Common/BaseButton';
import { ACTION_ID } from '../../details/constants';

class TopStatusCard extends React.Component {
  static contextType = ThemeContext;
  static navigationOptions = {
    header: null,
  };

  constructor(props, context) {
    super(props);
    const { bookingState } = this.props.response;
    const { state, subState } = bookingState || {};
    this.isOnBoard =
      this.props.card.cardId === RailBookingDetailConstant.TOP_STATUS_CARD_24_HRS_TO_ON_TRIP;
  }

  onCopyVoucherCode = async (code, index) => {
    this.props.onClickEventTracker(`CopyID_${index}`)
    const { copiedToClipboardText } = getStaticData(false);
    const copyStatus = await copyToClipboard(code);
    if (copyStatus) {
      showLongToast(copiedToClipboardText);
    }
  };

  isSomePsgrFiledTdr = () => {
    const passengerList = this.props.response.train[0].pnrDetails[0].segmentInfo[0].passenger;
    let filedTDR = 0;

    passengerList.forEach((passenger) => {
      if (passenger.currentStatus === 'TDR') {
        filedTDR = filedTDR + 1;
      }
    });

    return filedTDR > 0;
  };
  componentWillUnmount() {
    DeviceEventEmitter.removeAllListeners(RailBookingDetailConstant.RAIL_STORAGE_PERMISSION);
  }

  getCardView() {
    const views = [];
    const { actionList, subCardList } = this.props.card;
    !isEmpty(subCardList) && subCardList.forEach((card) => {
      this.props.addCard(card, views, true);
    });
    return views;
  }


  render() {
    const staticData = getStaticData();
    const { fsStyle, ...fonts } = getFont(true);
    const { psTheme } = this.context;
    const { color } = psTheme;
    const styles = getStyles(color, fonts);
    const { bookingIdCapsText, irctcPnrText } = staticData;
    const { actionList, subCardList, imageUrl } = this.props.card;
    const { heading, colorList, description, upcomingOrCompleted } = getTopStatusData(
      this.props.response,
      this.props.card,
      staticData,
      color,
    );
    const {colorBegin, colorEnd} = this.props.card;
    return (
      <LinearGradient
        start={{ x: 1.0, y: 0.0 }}
        end={{ x: 0.0, y: 1.0 }}
        colors={[colorBegin, colorEnd]}
        style={styles.topStatusCard}
      >
        <View style={[styles.topStatusBar, styles.statusCardOuter]}>
          <TouchableOpacity style={styles.backIconPress} onPress={this.props.onBackPress}>
            <Image
              style={
                Platform.OS === 'android' || Platform.OS === 'web'
                  ? [AtomicCss.marginBottom10, styles.backarrow]
                  : [AtomicCss.marginBottom10, styles.IOSbackarrow]
              }
              source={Platform.OS === 'ios' ? IOS_BACK_ARROW : ANDROID_BACK_ARROW}
            />
          </TouchableOpacity>
        </View>
        <View style={styles.StatusCardWrapper}>
          <View style={[AtomicCss.alignCenter, AtomicCss.marginBottom5]}>
            <Image style={[styles.headerImage]} source={{uri : imageUrl}} />
          </View>
          <Text
            style={[
              styles.StatusHeadingTxt,
              styles.transperantBgrnd,
              fonts.blackFontFamily,
              fsStyle.font18,
              { color: colorList[0] },
            ]}
          >
            {heading}
          </Text>
          {!isEmpty(description) &&
            description.map((item, index) => {
              return (
                <TouchableOpacity
                  onPress={() => {
                    this.onCopyVoucherCode(item.code, index);
                  }}
                  style={[styles.BookingWrapper, AtomicCss.alignCenter]}
                >
                  {!isEmpty(item) && !isEmpty(item.text) && (
                    <Text
                      style={[styles.transperantBgrnd, fsStyle.font14, { color: colorList[1] }]}
                    >
                      {item.text}
                    </Text>
                  )}
                  <Image style={[styles.copyIcon, upcomingOrCompleted ? styles.copyIconColorGreen : styles.copyIconColor]} source={CORY_ICON} />
                </TouchableOpacity>
              );
            })}

            {
              !isEmpty(subCardList) && this.getCardView()
            }

          {!isEmpty(actionList) && !this.isSomePsgrFiledTdr() && (
            <View style={[actionList.length === 1 ? styles.singleBtnWrapper : styles.btnWrapper]}>
              {actionList.map((action) => {
                const { actionType: variant, actionLabeltext, actionId } = action || {};
                const isDownloadInvoice = actionId === ACTION_ID.DOWNLOAD_INVOICE;
                const { isThrottleRequired, throttleInterval} = getThrottleParams(action);
                return (
                  <View style={styles.buttonView}>
                  <BaseButton
                    variant={variant}
                    buttonStyle={styles.buttonStyle}
                    textStyle={styles.buttonTxtStyle}
                    clickHandler={() => {
                      this.handleClick(action);
                    }}
                    throttleInterval={throttleInterval}
                    isThrottleRequired={isThrottleRequired}
                    text={actionLabeltext}
                    imageOnLeftToText={isDownloadInvoice}
                  >
                    <Image style={styles.icon} source={getRoundIcon(action.actionFamily)} />
                  </BaseButton>
                  </View>
                );
              })}
            </View>
          )}
        </View>
      </LinearGradient>
    );
  }

  handleClick = (action) => {
    handleRailClickEvent(this.props.card, action, this.props.response, this.props.pageName);
  };
}

TopStatusCard.propTypes = {
  response: PropTypes.object.isRequired,
  onBackPress: PropTypes.func.isRequired,
};

export default TopStatusCard;
