import { isArray, isObject } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { View, ScrollView, StyleSheet, DeviceEventEmitter } from 'react-native';
import Actions from '../../navigation/postSalesNavigation';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import { LOST_BOOKING_DETAIL_URL } from '../../utils/NetworkUtils';
import RefundTrackerWithClaim from '../RefundTrackerNew';
import useApi from '../useApi';
import ViewStateHandler from '../ViewStateHandler';
import TopStatusCard from './TopStatusCard';
import LostBookingTrackingHelper from './LostBookingTrackingHelper';
import { getCancellationRequestNo, isNullOrEmptyObject } from '../commonUtil';
import { getStaticData } from '../../staticData/staticData';
import { LOBNAMES } from '../../PostSalesConstant';
import RefundOutOfTATForm from '../RefundOutOfTATForm';
import MSRMainCard from '../MSR/MainCard';
import { EventEmitterConst } from '../../flights/modules/details/FlightBookingDetailsConstant';

const LostIDBookingDetailPage = ({
  getRefundCard,
  pageData,
  bookingId,
  lobName,
  refreshLostDetail,
  isBookingConfirmed,
}) => {
  const pageName = 'unsuccessfulBookingDetail';
  const { loadingYourTripText } = getStaticData(true, true);
  const { lostRaiseReqInfo } = pageData || {};
  const appendParams = (url, paramInfo) => {
    const { isPushedAsLostId = false, profileType = '', requestNo = '', isBookingConfirmed = false } = paramInfo || {};
    return `${url}?profileType=${profileType}&bookingId=${bookingId}&isPushedAsLostId=${isPushedAsLostId}&reqNo=${requestNo}&isBookingConfirmed=${isBookingConfirmed}`;
  };

  const [inProgress, response, api] = useApi(appendParams(LOST_BOOKING_DETAIL_URL, pageData));

  const fullPageName = useMemo(
    () =>
      LostBookingTrackingHelper.getPageName(pageName, {
        ...response,
        ...pageData,
      }),
    [response, pageData],
  );

  useEffect(() => {
    isNullOrEmptyObject(lostRaiseReqInfo) && loadData();
  }, [loadData, refreshLostDetail, lostRaiseReqInfo]);

  useEffect(() => {
    if (response) {
      if (response.error) {
        LostBookingTrackingHelper.trackErrorWithData(pageName, bookingId, response.error);
      } else {
        LostBookingTrackingHelper.trackLoadEvent(pageName, {
          ...response,
          ...pageData,
        });
      }
    }
  }, [response, pageData, bookingId]);

  const loadData = useCallback(() => {
    api.httpGet({
      bookingId,
      uniqueId: 'LOST_BOOKING_DETAIL',
      psLob: LOBNAMES.POST_SALES,
    });
  }, [api, bookingId]);

  const getCancellationDetailList = (info) => {
    const cancellationDetailList = [];
    if (isObject(info.refundDetails) && isArray(info.refundDetails.psrRequests)) {
      const psrRequests = info.refundDetails.psrRequests;
      for (let i = 0; i < psrRequests.length; i++) {
        cancellationDetailList.push({
          ...psrRequests[i],
          requestNo: psrRequests[i].psrNo,
          blockCallRefundApi: info.blockCallRefundApi,
          requestType: psrRequests[i].type,
        });
      }
    }
    info.cancellationDetailList = cancellationDetailList;
    return cancellationDetailList;
  };

  const onRefundBreakupBtnClicked = (requestNo, version) => {
    if (version && version == 2) {
      Actions.refundBreakupV2({
        bookingId,
        apiPSLob: LOBNAMES.POST_SALES,
      });
    } else {
      Actions.refundBreakup({
        bookingId,
        requestNo,
        apiPSLob: LOBNAMES.POST_SALES
      });
    }
    DeviceEventEmitter.emit(EventEmitterConst.CTA_CLICKED_EVENT);
    LostBookingTrackingHelper.trackClickEvent(pageName, `${lobName}_refund_Details`, response);
  };

  const renderView = (info) => {
    const { layoutDetail, refundStatusList, allTicketsForBooking } = info || {};
    const { ticketsList } = allTicketsForBooking || [];
    const { cardList } = layoutDetail || {};
    const { requestNo = '' } = pageData || {};
    const view = [];
    cardList &&
      cardList.forEach((card) => {
        const { cardId } = card || {};
        switch (cardId) {
          case 'topStatusCard':
            view.push(<TopStatusCard data={info} />);
            break;
          case 'msrCard':
            ticketsList &&
              ticketsList.length > 0 &&
              view.push(<MSRMainCard ticketsList={ticketsList} pageName={fullPageName} />);
            break;
          case 'refundTrackerCard':
            if (refundStatusList && refundStatusList.length) {
              view.push(
                <RefundTrackerWithClaim
                  refundTrackerData={info}
                  isLostBookingId={true}
                  isRoundedCornerAndPadding={true}
                  bookingId={bookingId}
                  lostRequestNo={requestNo}
                  pageName={fullPageName}
                  lobName={lobName}
                />,
              );
            } else {
              const cancellationDetailList = getCancellationDetailList(info);
              const refundCard =
                getRefundCard && cancellationDetailList.length
                  ? getRefundCard({
                      response: info,
                      cancellationDetailList,
                      canReqNoList: getCancellationRequestNo(cancellationDetailList),
                      onRefundBreakupBtnClicked,
                    })
                  : null;
              refundCard && view.push(refundCard);
            }
            break;
        }
      });
    return view;
  };

  if (!isNullOrEmptyObject(lostRaiseReqInfo)) {
    return <RefundOutOfTATForm {...pageData.lostRaiseReqInfo} isLostBookingId={true} />;
  }

  return (
    <ViewStateHandler
      {...{
        loading: inProgress,
        loaderMsg: loadingYourTripText,
        error: response ? (response.error || !response.layoutDetail) : null,
        onRetry: loadData,
      }}
    >
      <View style={styles.pageContainer}>
        <ScrollView>{renderView(response)}</ScrollView>
      </View>
    </ViewStateHandler>
  );
};

const styles = StyleSheet.create({
  pageContainer: { backgroundColor: colors.grayBg, flex: 1 },
  refundCardWrapper: {
    marginHorizontal: 15,
    borderRadius: 10,
    marginTop: 10,
  },
});

export default LostIDBookingDetailPage;
