import React from 'react';
import PropTypes from 'prop-types';
import {Image, Text, View} from 'react-native';
import getStyles from './HotelMamiCss';
import {
  getActionButton, getButtonStyle, getDateFromMilliseconds, handleClickEvent,
  isNotNullAndEmpty, isNullOrEmpty
} from '../utils/HotelBookingDetailUtil';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import {getParsedString, isRequestStateOpen} from '../../../utils/MyPromiseUtil';
import { CHAT_ICON } from '../../HotelImageConstants';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';


class MyRequestsForThisBookingCard extends React.Component {
  constructor(props) {
    super(props);
    this.fonts = getFont(true);
    this.styles = getStyles(this.fonts);
    this.allRequest = [];
  }

  render() {
    const promiseDetailList = this.props.response.myPromiseResponse;
    const mySupportOpenRequest = this.getOpenRequestList(promiseDetailList);
    if (this.allRequest.length > 0) {
      return (
        <View style={[this.styles.MamiCard, this.styles.mar10Bt]}>
          <View style={this.styles.CardContent}>
            <Image
              style={this.styles.RateStayIcon}
              source={CHAT_ICON}
            />
            <View style={this.styles.ContentPortion}>
              <Text style={[this.styles.blackFont, this.styles.BlackText, this.styles.mar10Bt]}>MY SUPPORT REQUESTS
                ({isNotNullAndEmpty(mySupportOpenRequest) ? mySupportOpenRequest.length : 0}&nbsp;
                Open)
              </Text>
              {this.showOpenRequestContent(mySupportOpenRequest)}
            </View>
          </View>
          <View
            style={isNotNullAndEmpty(this.props.card.actionList) ? getButtonStyle(this.props.card.actionList) : this.styles.hideView}
          >
            {getActionButton(this.props.card, this.props.response, this.handleClick)}
          </View>
        </View>
      );
    }
    return null;
  }

  handleClick = (action) => {
    handleClickEvent(this.props.card, action, this.props.response,this.props.pageName);
  };

  showOpenRequestContent(mySupportRequestList) {
    if (isNullOrEmpty(mySupportRequestList)) {
      return;
    }
    const views = [];
    mySupportRequestList.forEach((mySupportRequestObject) => {
      this.fillOpenRequestContent(mySupportRequestObject, views);
    });
    return views;
  }

  fillOpenRequestContent(mySupportRequestObject, views) {
    views.push(<View>
      <Text>Raised
          on {getDateFromMilliseconds(mySupportRequestObject.promisestarttime, 'Do,MMM')}
      </Text>
      <Text>{mySupportRequestObject.reqtypeuserdata.requeststatusmsg}</Text>
      <Text>{getParsedString(mySupportRequestObject.reqtypeuserdata.requestcommitmentmsg, mySupportRequestObject, 0, 0)}</Text>
      <Text
        style={this.styles.BlueText}
        onPress={() => {
          this.showRequestDetailsPage(mySupportRequestObject);
        }}
      >Know More
      </Text>
    </View>);
  }

  showRequestDetailsPage(mySupportRequestObject) {
    HotelBookingModule.openRequestDetailsScreen(JSON.stringify(mySupportRequestObject));
  }

  getOpenRequestList(promiseList) {
    const openRequestList = [];
    if (isNullOrEmpty(promiseList)) {
      return;
    }
    promiseList.forEach((promiseObject, index) => {
      this.processPromiseObject(promiseObject, openRequestList);
    });
    return openRequestList;
  }

  processPromiseObject(promiseObject, openRequestList) {
    const channel = promiseObject.attributedetails.channel.toUpperCase();
    if (promiseObject.ruldetails.showonui &&
      (channel === 'WRITETOUS' || channel.toUpperCase() === 'VOICE')) {
      this.allRequest.push(promiseObject);
      if (isRequestStateOpen(promiseObject.currentstatus)) {
        openRequestList.push(promiseObject);
      }
    }
  }
}
MyRequestsForThisBookingCard.propTypes = {
  response: PropTypes.object.isRequired,
  card: PropTypes.object.isRequired,
  pageName:PropTypes.string.isRequired
};
export default MyRequestsForThisBookingCard;
