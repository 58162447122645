import { Fonts, Theme } from 'apps/post-sales/src/cab/types';
import { StyleSheet } from 'react-native';

const getStyles = (fonts: Fonts, theme: Theme) =>
  StyleSheet.create({
    card: {
      padding: 16,
    },
    description: {
      fontSize: fonts.fontSize.font16,
      color: theme.color.textGrey,
    },
    landingIcon: {
      width: 48,
      height: 47,
      marginRight: 15,
    },
    btnTxtStyle: {
      fontSize: fonts.fontSize.font12,
    },
    btnStyle: {
      paddingHorizontal: 48,
      paddingVertical: 10,
    },
  });

export default getStyles;
