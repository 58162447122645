import PostSalesEventLogger from '../../../../PostSalesEventLogger';

export default class FlightEventsLogger {
  static loadEvent(pageName, eventData) {
    pageName = this.updateAction(pageName);
    PostSalesEventLogger.loadEvent(pageName, eventData);
  }

  static clickEvent(pageName, clickEvent, eventData) {
    pageName = this.updateAction(pageName);
    PostSalesEventLogger.clickEvent(pageName, clickEvent, eventData);
  }

  static errorEvent(pageName, eventData) {
    pageName = this.updateAction(pageName);
    PostSalesEventLogger.errorEvent(pageName, eventData);
  }

  static successEvent(eventName, eventData) {
    eventName = this.updateAction(eventName);
    PostSalesEventLogger.successEvent(eventName, eventData);
  }

  static failureEvent(eventName, eventData) {
    eventName = this.updateAction(eventName);
    PostSalesEventLogger.failureEvent(eventName, eventData);
  }

  static logAction(action, actionData) {
    action = this.updateAction(action);
    PostSalesEventLogger.logPostSalesAction(action, actionData);
  }

  static updateAction(action) {
    return `Flt_${action}`;
  }
}

export const FLIGHT_LOG_ACTIONS = {
  ANCILLARY_PAYMENT_PAGE: 'Ancillary_Payment_Page',
  ANCILLARY_THANKYOU_PAGE: 'Ancillary_Thankyou_Page',
  ANCILLARY_ERROR_PAGE: 'Ancillary_Error_Page',
  ODC_THANKYOU_PAGE: 'ODC_Thankyou_Page',
  OPEN_ODC_PAYMENT_PAGE: 'Open_ODC_Payment_Page',
  //APIs
  ANCILLARY_HOLD_API: 'Ancillary_Hold_API',
  WEBCHECKIN_API: 'Webcheckin_API',
  ODC_HOLD_API: 'ODC_Hold_API',
  DATE_CHANGE_PAYMENT_PAGE: 'DATE_CHANGE_PAYMENT_PAGE',
  PURCHASED_ANCILLARIES_API: 'PURCHASED_ANCILLARIES_API',
  WHEELCHAIR_HOLD_API_FAILED: 'WHEELCHAIR_HOLD_API_FAILED',
  WHEELCHAIR_HOLD_API_SUCCESS: 'WHEELCHAIR_HOLD_API_SUCCESS'
};
