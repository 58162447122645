import React from 'react';
import { View } from 'react-native';

import createStyles from './styles';
import ProfileDetails from './components/ProfileDetails';
import LanguageSpeak from './components/LanguageSpeak';
import WhatCustomersSay from './components/WhatCustomersSay';
import LeftSectionCommon from './components/LeftSectionCommon';
import { DRIVER_PROFILE_OVERLAY_SECTIONS } from '../../../../CabBookingDetailsConstant';
import { DriverProfileSection } from '../../../../types';

import { getFont } from '@mmt/post-sales/src/PostSalesStyles';
import { useTheme } from '@mmt/post-sales/src/theme/theme.context';

export const getSectionRenderer = (section: DriverProfileSection, isLastRow: boolean) => {
  const { cardId, data } = section || {};
  if (!data) {
    return null;
  }

  const componentMap: Record<string, { component: React.FC<any>; wrapComponent: boolean }> = {
    [DRIVER_PROFILE_OVERLAY_SECTIONS.PERSONAL_INFO]: {
      component: ProfileDetails,
      wrapComponent: true,
    },
    [DRIVER_PROFILE_OVERLAY_SECTIONS.LANGUAGE_INFO]: {
      component: LanguageSpeak,
      wrapComponent: true,
    },
    [DRIVER_PROFILE_OVERLAY_SECTIONS.CUSTOMER_REVIEWS]: {
      component: WhatCustomersSay,
      wrapComponent: true,
    },
    [DRIVER_PROFILE_OVERLAY_SECTIONS.TRIP_COMPLETED]: {
      component: LeftSectionCommon,
      wrapComponent: true,
    },
    [DRIVER_PROFILE_OVERLAY_SECTIONS.XP_DATA]: {
      component: LeftSectionCommon,
      wrapComponent: true,
    },
  };

  const Component = componentMap?.[cardId]?.component;
  return Component ? <Component data={data} isLastRow={isLastRow} /> : null;
};

export const renderSections = (sectionList: DriverProfileSection[]) => {
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);

  if (sectionList) {
    return sectionList.map((section, index) => {
      const isLastRow = index === sectionList.length - 1;
      return (
        <View key={section.cardId} style={styles.marginBottom10}>
          {getSectionRenderer(section, isLastRow)}
        </View>
      );
    });
  }
  return null;
};
