import React from 'react';
import PropTypes from 'prop-types';
import ProgressView from '../../Common/ProgressView/ProgressView';
import { getStaticData } from '../../staticData/staticData';


export default class PageLoader extends React.Component {
  componentDidMount() {
    if (this.props.updateAPIData) { this.props.updateAPIData(); }
  }

  render() {
    const staticData = getStaticData();
    const loadingMessage = this.props.loaderMessage ? this.props.loaderMessage : (staticData.loadingDetailsText ? staticData.loadingDetailsText : "");
    return (
      <ProgressView message={loadingMessage} />
    );
  }
}

PageLoader.defaultProps = {
  loaderMessage: "",
  updateAPIData: null
};

PageLoader.propTypes = {
  loaderMessage: PropTypes.string,
  updateAPIData: PropTypes.func
};
