import React from 'react';
import getFlightsSummaryData from './flightsCardAdapter';
import {
  ActionsContent,
  DescriptionSection,
  MainContent,
  SummaryContainer,
  CardInfoSection,
  JourneyInfoText,
  StatusInfoSection,
  WhereToGoTopImage,
  FlightRoundTripSectionText
} from '../cardElements';
import MSRRefundInfoCard from '../MSRRefundInfoCard';
import { WhereToGOImage } from '../../../types';
import { MsrTicket, FlightData } from '../cardInterfaces';

interface FlightSummaryCardProps {
  response: FlightData,
  msrTicket: MsrTicket,
  isUpcomingOrOnTripFirstBooking: boolean,
  topImage: WhereToGOImage,
}

function FlightSummaryCard({ response, msrTicket, isUpcomingOrOnTripFirstBooking, topImage }: FlightSummaryCardProps) {
  const cardData = getFlightsSummaryData(response, msrTicket);
  const {
    airLineDetails,
    passengerDetails,
    logoUrl,
    flightStartDate,
    flightEndDate,
    flightStartTime,
    flightEndTime,
    startsIn,
    from,
    to,
    actions,
    infoCardStatusText,
    infoCardStatusCTA,
    lobName,
    bookingId,
    status,
    openDetails,
    myRequestsCard,
    handleInfoPress,
    journeyTypeText,
    showRoundTripIcon,
   } = cardData || {};

  return (
    <SummaryContainer bookingId={response.bookingID}>
      {topImage && isUpcomingOrOnTripFirstBooking &&
        <WhereToGoTopImage topImageText={startsIn} topImage={topImage} />
      }
      <MainContent
        onPress={() => {
          openDetails();
        }}
      >
        <DescriptionSection>
          <CardInfoSection
            flightIcon={logoUrl}
            name={airLineDetails}
            passengerDetails={passengerDetails}
            startingPoint=""
            iconUrl=""
          />
          {journeyTypeText ?
            <FlightRoundTripSectionText
              from={from}
              to={to}
              descriptionText={journeyTypeText}
              showRoundTripIcon={showRoundTripIcon} /> :
            <JourneyInfoText
              from={from}
              startDateTime={flightStartTime}
              startDateText={flightStartDate}
              to={to}
              endDateTime={flightEndTime}
              endDateText={flightEndDate}
            />} 
          {infoCardStatusText && <StatusInfoSection statusText={infoCardStatusText} statusCta={infoCardStatusCTA} handleInfoPress={handleInfoPress} />}
        </DescriptionSection>
      </MainContent>
      <ActionsContent actions={actions} isInfoCardShown={!!infoCardStatusText} />
      {myRequestsCard && <MSRRefundInfoCard data={myRequestsCard} lobName={lobName} status={status} bookingId={bookingId} />}
    </SummaryContainer>
  );
}

export default FlightSummaryCard;