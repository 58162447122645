export interface GrafanaTracking {
  organization: string;
  lobName: string;
  platform: string;
  latencies: Latency[];
  errors: ErrorTrack[];
}

export interface Latency {
  pageName: string;
  entityType: string;
  entityName: string;
  latencyInMilli: number;
  latencyType: string;
}

export interface ErrorTrack {
  pageName: string;
  entityType: string;
  entityName: string;
  errorMessage: string;
  errorCode: number;
}

//PageType should be in sync with BE
export const ENTITY_TYPE = {
  PAGE: 'page',
  API: 'api',
  COMPONENT: 'component',
};
export type EntityType = typeof ENTITY_TYPE[keyof typeof ENTITY_TYPE];

export const PAGE_TYPES = {
  LANDING: 'LANDING',
  COMMON_LANDING: 'COMMON_LANDING',
  LISTING: 'LISTING',
  TRAVELLER: 'TRAVELLER',
};
export type PageType = typeof PAGE_TYPES[keyof typeof PAGE_TYPES];

//PageNames can be anything
export const PAGE = {
  LISTING_V2: 'rails_listing_v2',
  LISTING_V3: 'rails_listing_v3',
  LANDING: 'rails_landing',
  COMMON_LANDING: 'rails_bus_common_landing',
  TRAVELLER: 'rails_traveller',
  REVIEW: 'rails_review',
  THANK_YOU: 'rails_thankyou',
  LOB_GENERIC: 'rails_generic',
  RIS: 'rails_ris',
};
export type PageNames = typeof PAGE[keyof typeof PAGE];
