import { StyleSheet } from 'react-native';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';

export default (theme, fonts) =>
  StyleSheet.create({
    cardWrapper: {
      backgroundColor: theme.color.white,
      elevation: 3,
      ...getPlatformElevation(2),
      marginBottom: 16,
      borderRadius: 8,
      borderWidth: 1,
      borderColor: theme.color.greyBookedSeat,
    },
    lastItemStyle: {
      marginBottom: 900,
    },
    checkAllWrapper: {
      borderRadius: 8,
    },
    checkBtn: {
      flexDirection: 'row',
      paddingVertical: 16,
      paddingHorizontal: 15,
      alignItems: 'center',
    },
    checkAllBtn: {
      alignItems: 'flex-start',
    },
    checkbox: {
      height: 18,
      width: 18,
      backgroundColor: theme.color.white,
      borderColor: theme.color.disabledBtnBg,
      borderWidth: 1,
      borderRadius: 3,
      overflow: 'hidden',
      position: 'relative',
      marginRight: 12,
    },
    checkboxSelected: {
      borderColor: theme.color.transparent,
      borderWidth: 0,
    },
    checkboxAll: {
      marginTop: 3,
    },
    checkedIcon: {
      width: 19,
      height: 19,
      position: 'absolute',
      left: 0,
      top: -1,
      borderRadius: 3,
    },
    destRow: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    cityText: {
      fontSize: 18,
      color: theme.color.black,
      ...fonts.boldFontFamily,
      lineHeight: 24,
    },
    arrowIcon: {
      width: 10,
      height: 7,
      marginHorizontal: 5,
    },
    smallText: {
      fontSize: 13,
      color: theme.color.defaultTextColor,
      ...fonts.RegularFontFamily,
      marginBottom: 0,
      lineHeight: 16,
    },
    passengerName: {
      fontSize: 14,
      color: theme.color.black,
      ...fonts.boldFontFamily,
    },
    passengersWrapper: {
      margin: 8,
      backgroundColor: theme.color.paleBlue,
      paddingHorizontal: 12,
      paddingTop: 20,
      borderRadius: 4,
    },
    tabWrapper: {
      marginBottom: 20,
    },
    tabBox: {
      ...getPlatformElevation(2),
      backgroundColor: theme.color.grey22,
      paddingHorizontal: 12,
      paddingVertical: 12,
      borderRadius: 4,
      marginRight: 12,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      flex: 1,
    },
    lastTabItem: {
      marginRight: 0,
    },
    tabBoxActive: {
      backgroundColor: theme.color.primary,
    },
    tabText: {
      fontSize: 16,
      color: theme.color.primary,
      textAlign: 'center',
      ...fonts.RegularFontFamily,
    },
    tabTextActive: {
      color: theme.color.white,
      ...fonts.boldFontFamily,
    },
    redInfoIcon: {
      width: 16,
      height: 16,
      marginRight: 10,
    },
    redItalicText: {
      color: theme.color.red,
      ...fonts.RegularFontFamily,
      fontSize: 12,
      fontStyle: 'italic',
    },
    redInfoWrappe: {
      padding: 16,
      paddingTop: 6,
      flexDirection: 'row',
    },
    checkAllWrapperInner: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    ViewPolicyTxt: {
      fontSize: 14,
      color: theme.color.primary,
      ...fonts.boldFontFamily,
      paddingHorizontal: 20,
      marginTop: 30,
    },
    SelectNameRow: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      flex: 1,
    },
    paxListWrapper: {
      backgroundColor: theme.color.lightGrey6,
      borderRadius: 4,
      paddingLeft: 40,
      marginBottom: 8,
    },
    sectorPaxList: {
      paddingVertical: 19,
      paddingHorizontal: 35,
    },
    isMoreInfoWrapper: {
      borderBottomColor: 'transparent',
      borderBottomWidth: 0,
    },
    passengerRfundTxt: {
      fontSize: 12,
      color: theme.color.greyLight,
      ...fonts.RegularFontFamily,
      marginTop: 5,
    },
    paxslctionHeader: {
      flex: 1,
      justifyContent: 'space-between',
    },
    sectorHeader: {
      flexDirection: 'row',
      flex: 1,
    },
    sectorHeaderOpacity: {
      opacity: 0.5,
    },
    airlinesIconWrapper: {
      width: 33,
      height: 33,
      alignSelf: 'center',
    },
    airlineIcon: {
      width: 32,
      height: 32,
    },
    paxDisabled: {
      backgroundColor: theme.color.grey7,
      opacity: 0.7,
    },
    margin_bottom10: {
      marginBottom: 10,
    },
    passengerCancelled: {
      fontSize: 14,
      color: theme.color.black,
      ...fonts.boldFontFamily,
      textDecorationLine: 'line-through',
    },
    boxDisabled: {
      opacity: 0.4,
    },
    cancelledTxt: {
      color: theme.color.red,
      fontSize: 12,
      ...fonts.RegularFontFamily,
    },
    disableChkBox: {
      backgroundColor: theme.color.greyBookedSeat,
      borderColor: theme.color.gray3,
    },
    disableTxt: {
      color: theme.color.greyLight,
      fontSize: 14,
      ...fonts.RegularFontFamily,
    },
    errorInfoTxt: {
      fontSize: 14,
      color: theme.color.red6,
    },
    infoTxtColor: {
      color: theme.color.lightYello,
    },
    collapsedSection: {
      flexDirection: 'row',
      flex: 1,
      borderRadius: 4,
      paddingLeft: 40,
      paddingVertical: 15,
      backgroundColor: theme.color.lightGrey6,
      marginBottom: 8,
    },
    textBlue: {
      color: theme.color.primary,
    },
    txtInfo: {
      ...fonts.RegularFontFamily,
      color: theme.color.textGrey,
      fontSize: 12,
    },
    moreInfoWrapper: {
      backgroundColor: theme.color.borderColor,
      borderRadius: 4,
      padding: 16,
      margin: 10,
    },
    infoIcon: {
      width: 20,
      height: 20,
      marginRight: 8,
      tintColor: theme.color.lightYello,
    },
    seperatorWrapper: {
      paddingHorizontal: 20,
      overflow: 'hidden',
      marginBottom: 4,
    },
    lineHeight22: {
      lineHeight: 22,
    },
    refundInfoCard: {
      flexDirection: 'row',
      flex: 1,
      borderRadius: 4,
      padding: 12,
      marginBottom: 8,
    },
    refundInfoCardRequested: {
      backgroundColor: theme.color.creamWhite,
    },
    refundInfoCardCancelled: {
      backgroundColor: theme.color.borderColor,
    },
    refundInfoTextRequested: {
      color: theme.color.lightYello,
      fontSize: 13,
      ...fonts.boldFontFamily,
    },
    refundInfoTextCancelled: {
      color: theme.color.greyLight,
      fontSize: 13,
      ...fonts.boldFontFamily,
    },
    upperText: {
      textTransform: 'uppercase',
    },
    allErrorWrapper: {
      borderTopWidth: 1,
      borderTopColor: theme.color.faintBlue,
      paddingTop: 12,
      marginTop: -4,
      marginBottom: 20,
    },
    msgStyle: {
      color: theme.color.red,
    },
    boldFont: {
      ...fonts.boldFontFamily,
    },
    moreDtlsSection: {
      padding: 12,
      flexDirection: 'row',
      flex: 1,
      backgroundColor: theme.color.creamWhite,
      borderRadius: 4,
      marginTop: 4,
      marginBottom: 8,
      marginHorizontal: 8,
    },
  });
