import React from 'react';
import { Text, View } from 'react-native';
import HTMLView from 'react-native-htmlview';
import { getFont } from '../../../../../../PostSalesStyles';
import { useTheme } from '../../../../../../theme/theme.context';
import {getStyle, getHtmlStyles} from "./MMTValueCardCss"

const MMTValueCard = ({ mmtValue, ctripContact, toggleServiceGuaranteeOverlay }) => {
  const fonts = getFont(true);
  const {psTheme} = useTheme();
  const {color} = psTheme;
  const styles = getStyle(fonts, color);
  const htmlStyles = getHtmlStyles(fonts, color);
  const mmtValueLen = mmtValue.length;
  return (
    <View style={styles.cardContainer}>
      {mmtValue.map((text, index) => {
        return (
          <View style={styles.cardText}>
            <HTMLView value={`<div>${text}</div>`} stylesheet={htmlStyles} addLineBreaks={false}/>
            {index === mmtValueLen-1 && !!ctripContact &&
                <Text style={styles.contactUsText}
                  onPress={toggleServiceGuaranteeOverlay}>
                  {ctripContact.title}
                </Text>
            }
          </View>
        )
      })}
    </View>
  );
};

export default MMTValueCard;
