import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import createStyles from './style';
import {
  RIGHT_ARROW_WHITE_ICON,
} from '../../../../../../../flights/FlightImageConstants';
import BaseButton, { BUTTON_VARIANT } from '../../../../../../../Common/BaseButton';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { useTheme } from '../../../../../../../theme/theme.context';
const whiteArrowImg = require('@mmt/legacy-assets/src/iconWhiteArrow.webp');

const DarkFooter = ({priceDtls, price, footerInfoIcon, buttonLabel, isButtonDisabled, clickHandler, infoIconText, infoIconClickHandler, showFareBreakup}) => {
  const fonts = getFont();
  const {psTheme: theme } = useTheme();
  const styles = createStyles(fonts, theme);

  return (
    <View style={styles.darkFooterWrapper}>
      <View style={AtomicCss.marginLeft15}>
        <View style={AtomicCss.flexRow}>
          <Text style={styles.footerpriceDtls}>{priceDtls}</Text>
        </View>
        <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
          <Text style={[styles.footerTotalPrice]}>{price}</Text>
          {footerInfoIcon && <TouchableOpacity
            style={styles.offerWrapper}
            onPress={() => {
              infoIconClickHandler && infoIconClickHandler();
            }}
            activeOpacity={0.7}
          >
            <Image style={styles.infoIconArrow} source={footerInfoIcon} />
            <Text style={styles.offerText}>{infoIconText}</Text>
            <Image
              style={[
                styles.iconWhiteArrow,
                showFareBreakup ? { transform: [{ rotate: '180deg' }] } : {},
              ]}
              source={whiteArrowImg}
            />
          </TouchableOpacity>}
        </View>
      </View>
      <BaseButton
        variant={BUTTON_VARIANT.CAPSULE}
        clickHandler={clickHandler}
        text={buttonLabel}
        textStyle={{fontSize: fonts.fontSize.font16}}
        buttonStyle={styles.buttonStyle}
        disabled={isButtonDisabled}
        shadow
      >
        <Image style={styles.wtRightArrow} source={RIGHT_ARROW_WHITE_ICON} />
      </BaseButton>
    </View>
  );
};
DarkFooter.defaultProps = {
  buttonLabel: 'PAY NOW',
};
DarkFooter.propTypes = {
  price: PropTypes.string.isRequired,
  priceDtls: PropTypes.string.isRequired,
  clickHandler: PropTypes.func.isRequired,
  infoIconClickHandler: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string,
};

export default DarkFooter;
