import React from 'react';
import {BackHandler, Image, Text, View} from 'react-native';
import Actions from '../../../navigation/postSalesNavigation';
import styles from './MetroThankYouCss';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import {getCommonHeaders, METRO_CANCEL_URL} from '../../../utils/NetworkUtils';
import NoInternetView from '@mmt/legacy-commons/Common/Components/Error/NoInternetView';
import ProgressView from '@mmt/legacy-commons/Common/Components/Progress/ProgressView';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import {isNetworkAvailable} from '@mmt/legacy-commons/Common/utils/AppUtils';
import BasePage from '../../../Common/PostSalesBasePage';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import {invalidateTrips} from '@mmt/legacy-commons/Helpers/genericHelper';
import CapsuleBtnFill from '../../../hotel/details/ActionButton/CapsuleBtnFill';
import logAction from '@mmt/legacy-commons/Helpers/actionsLogger';
import * as NpsModule from '@mmt/legacy-commons/Native/NpsModule';
import {
  getRatingData, isNotNullAndEmpty
} from '../../../Common/commonUtil';
import {DOUBLE_TICK_ICON,SUCCESS_ICON, ERROR_ICON} from '../../MetroImageConstant';

export default class MetroThankYou extends BasePage {
  static navigationOptions = {
    header: null
  };

  constructor(props) {
    super(props, 'metroCancelThankYou');
    this.state = {
      viewState: ViewState.LOADING,
      mmtAuth: '',
      cancelResponse: null
    };
    this.trackingPageName = 'MetroCancelThankYou';
    this.showRatingPrompt = true;
  }

  componentDidMount() {
    super.componentDidMount();
    this.cancelMetroBooking();
  }

  componentWillMount() {
    BackHandler.addEventListener('hardwareBackPress', this.clickHandler);
  }

  componentWillUnmount() {
    BackHandler.removeEventListener('hardwareBackPress', this.clickHandler);
  }

  render() {
    return (
      <View style={{flex: 1}}>
        {this.state.viewState === ViewState.LOADING && this.renderProgressView()}
        {this.state.viewState === ViewState.NO_INTERNET && this.renderNoNetworkView()}
        {this.state.viewState === ViewState.ERROR && this.renderErrorView()}
        {this.state.viewState === ViewState.SHOW_DETAIL && this.renderSuccessPage()}
      </View>
    );
  }

  async cancelMetroBooking() {
    try {
      const hasNetwork = await isNetworkAvailable();
      if (!hasNetwork) {
        this.setState({viewState: ViewState.NO_INTERNET});
        return;
      }
      const userInfo = await HotelBookingModule.getRequestHeader(this.props.bookingId, 'METRO_CANCEL_CONFIRM');
      const cancelRequest = this.props.cancelRequest;

      const response = await
        fetch(METRO_CANCEL_URL, {
          method: 'POST',
          headers: await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo),
          body: JSON.stringify(cancelRequest)
        });
      if (response && response.ok) {
        const body = await
          response.json();

        if (body.status) {
          invalidateTrips();
          this.setState({
            cancelResponse: body,
            viewState: ViewState.SHOW_DETAIL,
            mmtAuth: userInfo.mmtAuth
          });
        } else {
          this.setState({
            viewState: ViewState.ERROR
          });
        }
      } else {
        this.setState({viewState: ViewState.ERROR});
      }
    } catch (error) {
      this.setState({viewState: ViewState.ERROR});
    }
  }

  renderNoNetworkView = () => (
    <NoInternetView
      onRetry={() => {
        this.setStateLoading();
      }}
    />);

  renderProgressView = () => (<ProgressView message="Initializing cancellation..." />);

  renderErrorView = () => (
    <ErrorView
      showRetry
      onRetry={() => {
        this.setStateLoading();
      }}
    />);

  onHardBackPress = () => {
    if (this.state.viewState === ViewState.SHOW_DETAIL) {
      this.clickHandler();
      logAction('HardwareBackPressed');
      return true;
    }
    return false;
  };

  setStateLoading = () => {
    this.setState({viewState: ViewState.LOADING});
    this.cancelMetroBooking();
  };

  clickHandler = () => {
    const params = {};
    params.BOOKING_ID = this.props.bookingId;
    params.MMT_AUTH = this.state.mmtAuth;

    Actions.metroBookingDetail({data: params, isRefresh:true});
  }

  renderSuccessPage = () => {
    const cancelledCount = 1;

    const titleMessage = this.props.isFullCancel ? 'Successfully cancelled all ticket(s) ' :
      `Successfully cancelled ${cancelledCount} out of ${this.props.ticketCount} ${this.props.ticketCount > 1 ? 'ticket(s)' : 'ticket'}`;
    const fullCancelSubTitle = `Your booking has been successfully cancelled. ${this.props.refundAmount > 0 ? 'Your refund is under process.' : 'There is no refund applicable.'}`;
    let partialCancelSubTitle = `Your booking has been successfully cancelled. ${this.props.refundAmount > 0 ? 'Your refund is under process.' : 'There is no refund applicable.'}`;
    const remainingTicketCount = this.props.totalPaxCount - 1;
    if (remainingTicketCount > 0) {
      partialCancelSubTitle = `${partialCancelSubTitle} ${remainingTicketCount} ${remainingTicketCount > 1 ? 'tickets are' : 'ticket is'} still active on the booking.`;
    }

    const { ratingData, npsData } = getRatingData(this.trackingPageName, this.state.cancelResponse);
    if (ratingData && this.showRatingPrompt) {
      NpsModule.showRatingPrompt(ratingData, npsData);
      this.showRatingPrompt = false;
    }

    return (
      <View style={styles.FullPageErrorPage}>
        <Image
          style={[styles.ErrorImg1, styles.marginBottom20]}
          source={ERROR_ICON}
        />
        <Text style={[styles.ErrorHeading, styles.marginBottom20]}>{titleMessage}</Text>
        <Text style={[styles.ErrorDisc, styles.lastDisc]}>
          {this.props.isFullCancel ? fullCancelSubTitle : partialCancelSubTitle}
        </Text>
        <CapsuleBtnFill
          action={{actionLabeltext: '  VIEW UPDATED BOOKING  '}}
          clickHandler={this.clickHandler}
        />
      </View>
    );
  }

  getErrorTrackingBundle(error) {
    const {trackingBundle} = this.props;
    trackingBundle.error = error;
    return trackingBundle;
  }
}

MetroThankYou.propTypes = {

};
