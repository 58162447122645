import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import styles from './overlayContentCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {isNotNullAndEmpty} from '../../../hotel/details/utils/HotelBookingDetailUtil';

const TCSBottomSheet = ({handleBottomOverlay, tcsMessagePopup}) => {
    return (
        <View style={styles.overlayContent}>
            {isNotNullAndEmpty(tcsMessagePopup.tcsMessages[1].header) && <Text style={[styles.overlayContentheading, AtomicCss.marginBottom24]}>{tcsMessagePopup.tcsMessages[1].header}</Text>}
            <Text style={[AtomicCss.defaultText, AtomicCss.font14, AtomicCss.boldFont, AtomicCss.marginBottom20]}>{isNotNullAndEmpty(tcsMessagePopup.tcsMessages[1].subHeader) && tcsMessagePopup.tcsMessages[1].subHeader}</Text>
            {isNotNullAndEmpty(tcsMessagePopup.tcsMessages[1].messages) && tcsMessagePopup.tcsMessages[1].messages.map((item, index, array)=>{
                return (
                    <View style={[styles.rowSection,index===array.length-1 ?styles.lastItem:'']}>
                        <View style={styles.countWrapper}>
                            <Text style={styles.countTxt}>{index+1}</Text>
                        </View>
                        <Text style={[AtomicCss.defaultText, AtomicCss.font14, AtomicCss.regularFont, AtomicCss.marginBottom10]}>{item}</Text>
                    </View>
                )
            })}
            <View style={styles.btnWrapper}>
                <TouchableOpacity style={[styles.btnLinkWrapper, styles.singleLink]} onPress={() =>handleBottomOverlay(" ")}>
                {isNotNullAndEmpty(tcsMessagePopup.buttons) && tcsMessagePopup.buttons[0] && <Text style={[styles.btnLinkTxt]}>{tcsMessagePopup.buttons[0].text}</Text>}
                </TouchableOpacity>
            </View>
        </View>

    );
}
export default TCSBottomSheet;