import React from 'react';
import { StyleSheet, View, Image, Text, TouchableOpacity } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {
  CROSS_ICON,
  ZC_IMAGE,
  GREEN_CIRCLE_TICK,
  PRICE_OFFER_ICON,
  TICK_BULLET_ICON,
} from '../../Utils/RailImageConstant';
import { getSegmentInfo } from '../../details/data/helper';
import { useTheme } from '../../../theme/theme.context';
import { getFont } from '../../../PostSalesStyles';
import { FreeCancellationBtmSheetProps, FreeCancellation, AdditionalInfo } from '../../types';
import isEmpty from 'lodash/isEmpty';
import getStyles from './FreeCancelationBtmSheetCss';

const FreeCancellationBtmSheet = ({
  response,
  handleBottomOverlay,
}: FreeCancellationBtmSheetProps) => {
  const { freeCancellation } = getSegmentInfo(response);
  const { additionalInfo }: { additionalInfo?: AdditionalInfo } = freeCancellation || {};
  const { headerData, freeCancellationMsg, refundData, infoList, footerData } =
    additionalInfo || {};
  const { psTheme } = useTheme();
  const { color } = psTheme;
  const { fsStyle, ...fonts } = getFont(true);
  const styles = getStyles(fonts, color);
  return (
    <View style={styles.fcWrapper}>
      <View style={[styles.headingSection, AtomicCss.flexRow, AtomicCss.alignCenter]}>
        <View style={[AtomicCss.flex1, AtomicCss.flexRow, AtomicCss.alignCenter]}>
          <Image style={styles.freeCanIconStyle} source={ZC_IMAGE} />
          <View>
            {!isEmpty(headerData) && !isEmpty(headerData.text) && (
              <Text style={styles.headingText}>{headerData.text}</Text>
            )}
            {!isEmpty(headerData) && !isEmpty(headerData.subText) && (
              <Text style={styles.subHeadingText}>
                {headerData.subText}
              </Text>
            )}
          </View>
        </View>
        <TouchableOpacity style={styles.crossIconWrapper} onPress={() => handleBottomOverlay()}>
          <Image style={styles.crossIconStyle} source={CROSS_ICON} />
        </TouchableOpacity>
      </View>
      <View style={styles.detailsSection}>
        <View style={styles.infoFC}>
          {!isEmpty(freeCancellationMsg) && (
            <Text style={styles.cancellationMsgStyle}>
              {freeCancellationMsg}
            </Text>
          )}
        </View>

        <View style={styles.innerWrapper}>
          <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom20]}>
            <Image style={styles.priceOfferStyle} source={PRICE_OFFER_ICON} />
            {!isEmpty(refundData) && <Text style={styles.refundTxtStyle}>{refundData.text}</Text>}
            <Image style={styles.greenTikStyle} source={GREEN_CIRCLE_TICK} />
          </View>
          {!isEmpty(infoList) &&
            infoList.map((item, index) => {
              return (
                <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom20]}>
                  <Image style={styles.tickbulletStyle} source={TICK_BULLET_ICON} />
                  {!isEmpty(item) && !isEmpty(item.text) && (
                    <Text style={[AtomicCss.defaultText, AtomicCss.font15, AtomicCss.regularFont]}>
                      {item.text}
                    </Text>
                  )}
                </View>
              );
            })}
        </View>
        <View style={styles.chartPreparedWrapper}>
          {!isEmpty(footerData) && !isEmpty(footerData.text) && (
            <Text style={styles.footerTextStyle}>
              {footerData.text}
            </Text>
          )}
        </View>
      </View>
    </View>
  );
};

export default FreeCancellationBtmSheet;
