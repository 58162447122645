import React, { useMemo, useState, useEffect } from 'react';
import { getPassengers, getPaxFareSegmentIdListFromPassengers } from '../../../data/dataAdapter';
import { CANCELLATION_BREAKUP_PAGE_VIEW } from '../../../constant';
import { FLIGHT_CANCELLATION_REASON, FLIGHT_CANCELLATION_REFUND_OPTIONS } from '../../../../../../PostSalesConstant';
import { getFlightFullCancellationRequest } from "core-ui-lib/flight/common/Cancellation/requestAdapter";
import { fetchFlightCancellationDetails } from '../../../data/api';

const useCancellatoinView = (cancellationDataFromLanding, { isFull = false, flightDetailsResponse, selectedPassenger }) => {

    const [viewState, setViewState] = useState(CANCELLATION_BREAKUP_PAGE_VIEW.LOADING);
    const [cancellationReviewPageData, setCancellationReviewPageData] = useState(cancellationDataFromLanding);
    const paxFareSegmentIdList = useMemo(() => {

        const allPassengers = getPassengers(flightDetailsResponse);

        const paxFareSegmentIdList = getPaxFareSegmentIdListFromPassengers(selectedPassenger, allPassengers);
        return paxFareSegmentIdList


    }, [selectedPassenger, flightDetailsResponse]);
   
    const cancellationRequest = getFlightFullCancellationRequest(
        flightDetailsResponse?.bookingID,
        FLIGHT_CANCELLATION_REASON.CITP,
        FLIGHT_CANCELLATION_REFUND_OPTIONS.RefundToCustomer,
        true,
        '',
        paxFareSegmentIdList,
    );
    const callCancellationAPI = async () => {
        try {
            const responseObj = await fetchFlightCancellationDetails(flightDetailsResponse.bookingID, cancellationRequest);
            if (responseObj && responseObj.isLoaded && !responseObj.exception) {
                const { cancellationResponse } = responseObj;
                if (responseObj.error && responseObj.hasErrorData) {
                    setViewState(CANCELLATION_BREAKUP_PAGE_VIEW.OPEN_ERROR_PAGE);
                    setCancellationReviewPageData(cancellationResponse);
                } else if (!responseObj.error && responseObj.success) {
                    setViewState(CANCELLATION_BREAKUP_PAGE_VIEW.LOADED);
                    setCancellationReviewPageData(cancellationResponse);
                } else {
                    setViewState(CANCELLATION_BREAKUP_PAGE_VIEW.OPEN_CANCELLATION_ERROR_PAGE);
                    setCancellationReviewPageData(cancellationResponse);
                }
            } else {
                setViewState(CANCELLATION_BREAKUP_PAGE_VIEW.ERROR);
            }
        } catch (e) {
            setViewState(CANCELLATION_BREAKUP_PAGE_VIEW.ERROR);
        }
    }

    useEffect(() => {
        if (!isFull) {
            callCancellationAPI();
        } else {
            setViewState(CANCELLATION_BREAKUP_PAGE_VIEW.LOADED);
        }
    }, [isFull])

    const OnRetry = () => {
        setViewState(CANCELLATION_BREAKUP_PAGE_VIEW.LOADING);
        callCancellationAPI();
    }

    return [viewState, cancellationReviewPageData, paxFareSegmentIdList, OnRetry, cancellationRequest];
}

export default useCancellatoinView;