import isEmpty from 'lodash/isEmpty';
import myTripsNavigation from '../../../../navigation/navigation';
import { titleCase } from '@mmt/legacy-commons/Helpers/stringHelpers';
import { getMyRequestCardData, handleCTAClick } from '../../../utils';
import { ActivityData, ActivitySummaryDataProps, MsrTicket } from '../cardInterfaces';
import { activityIcon } from 'apps/post-sales/src/acme/ActivityImageConstants';
import TripSummaryOmnitureTracker from '../../../TripSummaryOmnitureTracker';

export function getActivitySummaryData(data: ActivityData, msrTicket: MsrTicket): ActivitySummaryDataProps {
  const { status } = data;
  const [acmeDetails] = data.acmeDetails;
  const { ctaInfoList: ctaDetails, infoCard } = data && data.bookingStateLayout || {};
  const actions: Array<{ label: string; onPress: () => void }> = [];
  const { text, ctaInfo } = infoCard || {};
  const infoCardStatusText = text;
  const infoCardStatusCTA = ctaInfo && ctaInfo.text;
  const pageData = {
    fromMyTrips: true,
  };
  const openDetails = () => {
    myTripsNavigation.openActivityBookingDetails(data, pageData);
  };

  let cityName = '';
  let dateTimeWithPaxInfo = '';
  const startsIn = data.startsIn;
  const lobName = data.lobType[0];
  const bookingId = data.bookingID;
  if (!isEmpty(acmeDetails) && !isEmpty(acmeDetails.cityName)) {
    cityName = acmeDetails.cityName;
  }

  if (!isEmpty(acmeDetails) && !isEmpty(acmeDetails.startDateTime) && !isEmpty(data.leadPaxFirstName)) {
    dateTimeWithPaxInfo = `${titleCase(data.leadPaxFirstName)}`;
  }
  const handleInfoClick = () => {
    if (infoCard) {
      handleCTAClick(infoCard.ctaInfo, data);
    }
  };

  if (ctaDetails && ctaDetails[0]) {
    actions.push({ label: ctaDetails[0]?.text, onPress: () => handleCTAClick(ctaDetails[0], data) })
  };
  if (ctaDetails && ctaDetails[1]) {
    actions.push({ label: ctaDetails[1]?.text, onPress: () => handleCTAClick(ctaDetails[1], data) })
  };

  return {
    status,
    iconUrl: activityIcon,
    cityName,
    myRequestsCard: getMyRequestCardData(msrTicket),
    productName: !isEmpty(acmeDetails) ? acmeDetails.productName : '',
    startDateText: acmeDetails.startDateText,
    dateText: acmeDetails.dateText,
    startsIn,
    lobName,
    bookingId,
    actions,
    openDetails,
    ctaDetails,
    dateTimeWithPaxInfo,
    infoCardStatusText,
    infoCardStatusCTA,
    dateAndDurationText: acmeDetails.dateAndDurationText,
    handleInfoPress: handleInfoClick
  };
}

export default {};