import React from 'react';
import {
  View,
  TouchableOpacity
} from 'react-native';
import PropTypes from 'prop-types';
import styles from '../../CSS/VisaMamiCss';
import VisaFAQText from './VisaFAQText';
import VisaFAQAnswer from './VisaFAQAnswer';

const VisaFAQCollapsed = ({
  isActive, index, faq, tabbingFn
}) =>
  (
    <View style={[styles.CollapsedContainer1, styles.boldFont]}>
      <TouchableOpacity
        onPress={() => tabbingFn(index, !isActive)}
        activeOpacity={0.9}
      >
        <VisaFAQText question={faq.question} tabbing={isActive} />
      </TouchableOpacity>
      {isActive &&
        <VisaFAQAnswer answer={faq.answer} />
            }
    </View>
  );

export default VisaFAQCollapsed;

VisaFAQCollapsed.propTypes = {
  isActive: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  faq: PropTypes.object.isRequired,
  tabbingFn: PropTypes.func.isRequired
};
