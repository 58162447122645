import React from 'react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import styles from './RaiseRefundCardCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import BasePage from '../../../../../Common/PostSalesBasePage';
import { imageTransformY } from '../../../../../utils/PostSaleUtil';
import { RUPEES_ICON, RIGHT_ARROW } from '../../../../FlightImageConstants';
import { getFont } from '../../../../../PostSalesStyles';

class RaiseRefundCard extends BasePage {
  render() {
    const { fsStyle, ...fonts } = getFont(true);
    return (
      <View>
        <View style={[AtomicCss.marginBottom10]}>
          <View style={[styles.infoCardWrapper]}>
            <View style={styles.headingIconWrapper}>
              <Image style={styles.headingIconStyle} source={RUPEES_ICON} />
            </View>
            <View style={styles.infoCardContent}>
              <Text
                style={[
                  AtomicCss.defaultText,
                  fonts.blackFontFamily,
                  fsStyle.font14,
                  AtomicCss.marginBottom15,
                  AtomicCss.alignLeft,
                ]}
              >
                {this.props.scTopHeader.toUpperCase()}
              </Text>
              {!isEmpty(this.props.scTopMessage) && (
                <Text
                  style={[
                    fonts.regularFontFamily,
                    fsStyle.font14,
                    AtomicCss.defaultText,
                    AtomicCss.lineHeight20,
                    AtomicCss.marginRight10,
                    AtomicCss.alignLeft,
                  ]}
                >
                  {this.props.scTopMessage}
                </Text>
              )}
            </View>
          </View>
          {this.specialClaimReasonView(this.props.specialClaimDataList, this.onItemClick, fonts)}
        </View>
      </View>
    );
  }

  specialClaimReasonView = (specialClaimDataList, onItemClick, fonts) => {
    const { fsStyle } = getFont(true);
    return specialClaimDataList.map((specialClaimItem) => (
      <TouchableOpacity
        key={specialClaimItem.scName}
        style={styles.redirectBtnWrapper}
        activeOpacity={0.8}
        onPress={() => {
          onItemClick(specialClaimItem);
        }}
      >
        <Text style={[styles.linkBtnTxt, fonts.boldFontFamily, fsStyle.font14]}>
          {specialClaimItem.scName}
        </Text>
        <Image style={[styles.rightArrowIcon, imageTransformY]} source={RIGHT_ARROW} />
      </TouchableOpacity>
    ));
  };

  onItemClick = (specialClaimItem) => {
    this.props.specialClaimActionClick(specialClaimItem);
  };
}

RaiseRefundCard.propTypes = {
  specialClaimDataList: PropTypes.array.isRequired,
  specialClaimActionClick: PropTypes.func.isRequired,
  scTopMessage: PropTypes.string.isRequired,
  scTopHeader: PropTypes.string.isRequired,
};

export default RaiseRefundCard;
