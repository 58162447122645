import React, { useEffect } from 'react';
import { View, Text, StyleSheet, Image, TouchableOpacity, Platform } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getFont } from '../../../../PostSalesStyles';
import { useTheme } from '../../../../theme/theme.context';
import BaseButton from '../../../../Common/BaseButton';

interface bankOfferCardDetails {
  headerText: string;
  actualAmount: string;
  discountedAmount: string;
  offerDescription: string;
  bankIcon: string;
  offerCode: string;
  offerCodeCta: string;
  offerConditions: string;
  payCta: string;
}

interface BankOfferCardInterface {
  bankOfferCard: bankOfferCardDetails;
  clickPayNow: () => void;
  copyOfferCode: (code: string) => void;
}

const BankOfferCard = ({ bankOfferCard, clickPayNow, copyOfferCode } : BankOfferCardInterface) => {
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } : any = useTheme();
  const styles = createStyles(theme, fonts);

  const { headerText, actualAmount, discountedAmount, offerDescription, bankIcon, offerCode, offerCodeCta, offerConditions, payCta } = bankOfferCard;
  
  return (
    <View style={styles.offerWrapper}>
      <View style={[AtomicCss.marginBottom12, fsStyle.font16]}>
        <Text style={[styles.blackText, fonts.blackFontFamily]}>
          {headerText} {' '}
          <Text style={[styles.blackText, styles.slacedPrice]}>{actualAmount}</Text>
          <Text style={[fonts.blackFontFamily]}>{`  ${discountedAmount}`}</Text>
        </Text>
      </View>
      <Text
        style={[
          fsStyle.font14,
          styles.defaultText,
          fonts.regularFontFamily,
          AtomicCss.marginBottom12,
        ]}
      >
        {offerDescription}
      </Text>
      {!!offerCode && (
        <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom12]}>
          {!!bankIcon && (
            <View style={styles.bnkLogoWrapper}>
              <Image style={styles.logoStyle} source={{ uri: bankIcon }} />
            </View>
          )}
          <View style={styles.offerCodeSection}>
            <Text
              style={[
                styles.blackText,
                fsStyle.font12,
                fonts.boldFontFamily,
                AtomicCss.paddingLeft5,
              ]}
            >
              {offerCode}
            </Text>
            <TouchableOpacity style={styles.copyCodeBtn} onPress={() => copyOfferCode(offerCode)}>
              <Text style={[fonts.boldFontFamily, fsStyle.font12, styles.primaryText]}>
                {offerCodeCta}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      )}
      <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.flex1]}>
        <Text
          style={[
            styles.defaultText,
            fsStyle.font12,
            fonts.regularFontFamily,
            AtomicCss.marginRight15,
            Platform.select({
              web: {
                maxWidth: 145,
              },
            }),
          ]}
        >
          {offerConditions}
        </Text>
        <View style={[AtomicCss.flex1]}>
          <BaseButton
            text={payCta}
            buttonStyle={styles.topCardBtn}
            clickHandler={clickPayNow}
            variant={'PRIMARY'}
            numberOfLines={Platform.select({ web: 1 })}
          />
        </View>
      </View>
      <View style={styles.curveEffects}></View>
    </View>
  );
};

const createStyles = (theme: any, fonts: any)=>{
  return StyleSheet.create({
    defaultText: {
      color: theme.color.defaultTextColor,
    },
    blackText: {
      color: theme.color.black,
    },
    primaryText: {
      color: theme.color.azure,
    },
    offerWrapper: {
      borderRadius: 16,
      backgroundColor: theme.color.white,
      padding: 12,
      borderWidth: 1,
      borderColor: theme.color.skyBlue5,
      marginTop: 16,
      overflow: 'hidden',
    },
    slacedPrice: {
      textDecorationLine: 'line-through',
      fontFamily: fonts.regular,
    },
    bnkLogoWrapper: {
      width: 30,
      height: 30,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.color.lightRed8,
      borderRadius: 30,
      marginRight: 8,
      borderWidth: 1,
      borderColor: theme.color.smokeWhite,
    },
    logoStyle: {
      width: 17,
      height: 17,
    },
    curveEffects: {
      width: 200,
      height: 200,
      backgroundColor: theme.color.lightBlue23,
      borderRadius: 200,
      position: 'absolute',
      right: -50,
      bottom: -50,
      zIndex: -1,
    },
    offerCodeSection: {
      borderWidth: 1,
      borderColor: theme.color.grey40,
      borderStyle: 'dashed',
      borderRadius: 4,
      padding: 4,
      flexDirection: 'row',
      alignItems: 'center',
    },
    copyCodeBtn: {
      padding: 8,
      backgroundColor: theme.color.lighterBlue,
      borderRadius: 4,
      marginLeft: 25,
      borderWidth: 1,
      borderColor: theme.color.primary,
    },
    topCardBtn: {
      borderRadius: 12,
      paddingHorizontal: 10,
      paddingVertical: 10,
      ...fonts.font12
    }
  });

}
export default BankOfferCard;
