import React, { useState } from 'react'
import { View ,Text, Image, TouchableOpacity } from 'react-native';
import AtomicCss from '../../../commonStyles/AtomicCss';
import Actions from '../../../../navigation/postSalesNavigation';
import { VOUCHER_IMG, REFUND_ICON_IMG, dropDownArrow ,COPY_ICON} from '../../../Utils/RailImageConstant';
import { getFont } from '../../../../PostSalesStyles';
import HTMLViewWrapper from '../../../../cab/components/CabVendorInfoCard/HTMLViewWrapper';
import { getStaticData } from '../../../../staticData/staticData';
import { copyToClipboard } from '../../../../Common/commonUtil';
import backArrow from '@mmt/legacy-assets/src/back-white.webp';
import { showLongToast } from '@mmt/core/helpers/toast';
import TnC from '../TermsAndConditions';
import { useTheme } from '../../../../theme/theme.context';
import createStyles  from "./refundVoucherCss";
const RefundVoucher = ({ response, onClickEventTracker }) => {
    const { psTheme: theme } = useTheme();
    const fonts = getFont();
    const styles =  createStyles(theme,fonts);
    const [toggle, setToggle] = useState(true)
    const { tgVoucherData } = response || {};
    const { refundMsg, cancelMsg, refundModeMsg, voucherCodeLabel, voucherCode,
        validitySubText, knowMoreLabel, termsAndConditionLabel, termsAndCondition } = tgVoucherData ||{};
    const handleTNC = () => {
        setToggle(!toggle)
    }
    const onCopyVoucherCode = async () => {
        const { copiedToClipboardText } = getStaticData(false);
        const copyStatus = await copyToClipboard(voucherCode);
        if (copyStatus) {
          onClickEventTracker('TGvoucher_Copy')
          showLongToast(copiedToClipboardText);
        }
      };
         const handleKnowMoreClick = () => {
         if(response && response.train && response.train[0]){
            onClickEventTracker('TGvoucher_Viewtnc')
            Actions.openPsWebView({
              url: response.train[0] && response.train[0].zcplusLink,
              headerText: response.zcplusCardData && response.zcplusCardData.zcplusHeadingApp,
              headerIcon: backArrow
            });
            }
          }
    return (
        <View style={styles.refundVoucherWrapper}>
            {!!cancelMsg &&
                <View style={[styles.topInfoWrapper, AtomicCss.marginBottom10]}>
                    <Text style={[styles.topInfoText]}>{cancelMsg}</Text>
                </View>
            }
            <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom20]}>
                <Image style={styles.refundTrainImg} source={REFUND_ICON_IMG} />
                {!!refundMsg &&
                    <Text style={[AtomicCss.defaultText, AtomicCss.font12,fonts.regularFontFamily, AtomicCss.flex1]}>
                        {refundMsg}
                    </Text>
                }
            </View>
            {!!refundModeMsg &&
                        <View style={[AtomicCss.flexRow, AtomicCss.paddingBottom12]}>
                            <View style={styles.bullet}/>
                            <HTMLViewWrapper style={[AtomicCss.blackText, AtomicCss.font12,AtomicCss.marginRight10,AtomicCss.marginBottom10]} contentWidth ={'99%'} htmlContent={refundModeMsg} />
                        </View>
            }
          <View style={[AtomicCss.flexRow, styles.voucherWrapper, AtomicCss.marginBottom15,AtomicCss.marginTop10, AtomicCss.spaceBetween]}>
                          <View style={[AtomicCss.flexRow]}>
                              <Image style={styles.voucherImgStyle} source={VOUCHER_IMG} />
                              {!!voucherCodeLabel && <Text style={[AtomicCss.defaultText, fonts.boldFontFamily, AtomicCss.font11]}>{voucherCodeLabel}</Text>}
                          </View>
                              <View style={[AtomicCss.flexColumn, AtomicCss.marginBottom3]}>
                                  <TouchableOpacity
                                      activeOpacity={0.7}
                                      style={[
                                          AtomicCss.flexRow,
                                          AtomicCss.flex1,
                                         AtomicCss.marginLeft12,
                                      ]}
                                      onPress={onCopyVoucherCode}
                                  >
                                  {!!voucherCode && <Text style={[styles.mmtGuranteeText]}>{voucherCode}</Text>}
                                      <Image source={COPY_ICON} style={styles.copyIcon} />
                                  </TouchableOpacity>
                        {!!validitySubText && <Text style={[AtomicCss.greyText,AtomicCss.marginLeft12, AtomicCss.font10, fonts.regularFontFamily, AtomicCss.marginTop5]}> {validitySubText} </Text>}
                           <TouchableOpacity onPress={handleKnowMoreClick}>
                                  {!!knowMoreLabel && <Text style={[AtomicCss.azure,AtomicCss.marginLeft12, AtomicCss.boldFont, AtomicCss.font10,AtomicCss.marginTop5]}>{knowMoreLabel}</Text>}
                              </TouchableOpacity>
                              </View>
                      </View>
            <View style={AtomicCss.paddingLeft20}>
                <TouchableOpacity style={[AtomicCss.flexRow, AtomicCss.marginBottom12]} onPress={handleTNC}>
                    {!!termsAndConditionLabel && <Text style={[AtomicCss.azure, AtomicCss.font12, AtomicCss.boldFont]}> {termsAndConditionLabel} </Text>}
                    <Image
                        source={dropDownArrow}
                        style={[styles.downArrow, toggle ? styles.activeArrow : null]}
                    />
                </TouchableOpacity>
                {toggle &&
                    <TnC termsAndCondition={termsAndCondition} />
                }
            </View>
        </View>
    )
}


export default RefundVoucher;
