import {colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';

const styles = {
  contentTitle: {
    fontSize: 21,
    color: colors.defaultTextColor,
    fontFamily: fonts.light,
    marginBottom: 18
  },
  contentDesc: {
    fontSize: 14,
    paddingTop: 3,
    color: colors.defaultTextColor,
    fontFamily: fonts.regular
  },
  contentFooter: {
    marginBottom: 30,
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  actionBtn: {
    paddingVertical: 10
  },
  actionBtnEnabledText: {
    fontSize: 16,
    color: colors.azure,
    fontFamily: fonts.medium
  },
  actionBtnDisabledText: {
    fontSize: 16,
    color: colors.grey,
    fontFamily: fonts.medium
  },
  checkboxRow: {
    flexWrap: 'wrap',
    marginBottom: 5
  },
  agreementList: {
    ...getPlatformElevation(2),
    backgroundColor: colors.white,
    padding: 15,
    paddingBottom: 5,
    marginBottom: 10,
    borderRadius: 4
  },
  checkbox: {
    height: 18,
    width: 18,
    backgroundColor: colors.white,
    borderColor: colors.disabledBtnBg,
    borderWidth: 1.5,
    borderRadius: 3,
    overflow: 'hidden',
    position: 'relative',
    marginRight: 12,
    marginTop: 5
  },
  checkboxSelected: {
    borderColor: 'transparent',
    borderWidth: 0
  },
  checkBtn: {
    flexDirection: 'row',
    paddingVertical: 10,
    paddingHorizontal: 3,
    alignItems: 'center'
  },
  checkedIcon: {
    position: 'absolute',
    height: 29,
    width: 29,
    left: -5,
    top: -4
  },
  flex1: {
    flex: 1
  }
};

export default styles;
