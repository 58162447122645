const HOTEL_BOOKING_ROUTE_KEYS_ARRAY = [
    {
        key: "hotelBookingDetail",
        component: () => require('./details/components/HotelBookingDetails').default,
    },
    {
        key: "viewHotelAmenities",
        component: () => require('./details/components/ViewHotelAmenities').default,
    },
    {
        key: "viewPaymentBreakup",
        component: () => require('./details/components/PaymentCardDetails').default,
    },
    {
        key: "cancellationPreview",
        component: () => require('./Cancellation').default,
    },
    {
        key: "hotelChangeDate",
        component: () => require('./modification/datechange/HotelChangeDate').default,
    },
    {
        key: "addGuest",
        component: () => require('./modification/addguest/AddGuest').default,
    },
    {
        key: "addGuestReview",
        component: () => require('./modification/addguest/AddGuestReview').default,
    },
    {
        key: "viewHouseRules",
        component: () => require('./details/pages/HouseRules').default,
    },
    {
        key: "hotelCancellationPoilcyRule",
        component: () => require('./details/components/HotelCancellationPolicyRules').default,
    },
    {
        key: "viewHotelPolicy",
        component: () => require('./details/components/ViewHotelPolicy').default,
    },
    {
        key: "hotelChangeDateViewPrice",
        component: () => require('./modification/datechange/HotelChangeDateViewPrice').default,
    },
    {
        key: "hotelModificationThankYouPage",
        component: () => require('./modification/common/HotelModificationThankYouPage').default,
    },
    {
        key: "hotelModificationFailure",
        component: () => require('./modification/common/HotelModificationFailure').default,
    },
    {
        key: "openResendVoucherPage",
        component: () => require('./details/components/ResendHotelVoucher').default,
    },
    {
        key: "openCallHotel",
        component: () => require('./details/components/CallHotel').default,
    },
    {
        key: "viewGetDirections",
        component: () => require('./details/components/ViewGetDirections').default,
    },
    {
        key: "makeRequestPage",
        component: () => require('./details/components/MakeRequest').default,
    },
    {
        key: "viewRefundCalculation",
        component: () => require('./details/components/RefundBreakUpCard').default,
    },
    {
        key: "submitRequestPage",
        component: () => require('./details/components/SubmitRequest').default,
    },
    {
        key: "openSubmitPage",
        component: () => require('./details/components/HotelSuccessfulSubmissionPage').default,
    },
    {
        key: "viewRoomAmenities",
        component: () => require('./details/components/ViewRoomAmenities').default,
    },
    {
        key: "openNameChangePage",
        component: () => require('./details/components/HotelDetailsNameChange').default,
    },
    {
        key: "requestWriteToHotel",
        component: () => require('./details/components/RequestWriteToHotel').default,
    },
    {
        key: "psThankYouPage",
        component: () => require('./details/components/PSThankYouPage').default,
    },
    {
        key: "hotelPropertyDetails",
        component: () => require('./details/pages/PropertyDetails').default,
    },
    {
        key: "viewHotelPhotos",
        component: () => require('./details/pages/HotelPhotos').default,
    },
    {
        key: "viewRoomPhotos",
        component: () => require('./details/pages/RoomPhotos').default,
    },
    {
        key: "viewIndividualPhotograph",
        component: () => require('./details/components/IndividualPhotographs').default,
    },
    {
        key: "addGuestNamesResult",
        component: () => require('./details/components/AddGuestNamesResult').default,
    },
    {
        key: "openPahxPage",
        component: () => require('./details/components/DetailsPAHX').default,
    },
    {
        key: "chargeSuccessfullPage",
        component: () => require('./details/components/ChargeSuccessfullPage').default,
    },
    {
        key: "chargeFailedPage",
        component: () => require('./details/components/ChargeFailedPage').default,
    },
    {
        key: "addGuestNames",
        component: () => require('./details/components/AddGuestName').default,
    },
    {
        key: "hotelCancellationStandAlone",
        component: () => require('./details/components/HotelCancellationStandalone').default,
    },
    {
        key: "hotelModificationStandAlone",
        component: () => require('./details/components/HotelModificationStandalone').default,
    },
    {
        key: "hotelPayNow",
        component: () => require('./details/components/HotelPayNow').default,
    },
    {
        key: "refundDetails",
        component: () => require('./details/components/RefundDetails').default,
    },
    {
        key: "detailsRefundProcess",
        component: () => require('./details/components/DetailsRefundProcessCard').default,
    },
    {
        key: "pahxChargeCard",
        component: () => require('./details/components/PAHXChargeCard').default,
    },
    {
        key: "hotelPartialCancellation",
        component: () => require('./Cancellation/PartialCancellation/PartialCancellation').default,
    },
    {
        key: "staffDetails",
        component: () => require('./details/pages/StaffDetails').default,
    },
    {
       key: "upiMandateErrorView",
       component: () => require('./details/pages/UpiMandateErrorView').default
    },
    {
        key: "hotelTravelInsuranceThankYou",
        component: () => require('./details/pages/ThankyouPage').default,
    },
];

export default HOTEL_BOOKING_ROUTE_KEYS_ARRAY;