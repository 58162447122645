import FlightBookingModule from "@mmt/legacy-commons/Native/FlightBookingModule"
import { getUserPlatform } from "../../../../../../utils/NetworkUtils"
export const getUserInfo = async (bookingId,action,requestType, checkPlat = true) => {
    let userInfo;
    if (getUserPlatform() === 'web' && checkPlat){
        userInfo = await FlightBookingModule.getRequestHeader(
                            bookingId,
                            requestType,
                            action,
                        );
    } else {
        userInfo = await FlightBookingModule.getRequestHeader(
            bookingId,
            requestType,
          );
    }

    return userInfo;
};

export const getManagerViewRequest = (bookingId,workflowId) => {
    return {
        bookingId
        ,workflowId,
    };
};

export const getCustomerViewRequest = (data) => {
    return {
        sessionId: data.sessionId,
        bookingId: data.BOOKING_ID,
        commonInfo: data.crKey,
        recomId: data.recomId,
      };
};


export const getHoldRequest = (workflowId,policyKey,crKey,sessionId,recomId,bookingId,skipApproval) => {
    const varyingRequest = workflowId
        ? {
            workflowId,
          }
        : {
            pKey: policyKey,
            commonInfo: crKey,
            sessionId: sessionId,
            recomId: recomId,
          };

      return {
        bookingId,
        skipApproval,
        platformSource: getUserPlatform().toUpperCase(),
        ...varyingRequest,
      };
};

export const getRaiseReviewRequest = (userComment,selectedReason,policyKey,crKey,rKey,bookingId,props) => {
    return  {
        userComment,
        reason: selectedReason,
        pKey: policyKey,
        commonInfo: crKey,
        recommendation: rKey,
        bookingId,
        sessionId: props.sessionId,
        recomId: props.recomId,
        platformSource: getUserPlatform().toUpperCase(),
      };
}

export const getApproverDetailRequest = (bookingId,workflowId,action,reason,comment) => {
    return {
        odcBookingId: bookingId,
        workflowId,
        action,
        reason,
        comment,
    }
}