import { StyleSheet } from "react-native";
import { fontSizes } from '@mmt/legacy-commons/Styles/globalStyles';

export default (theme) => {
    const styles = StyleSheet.create({
        queryBotWrapper:{
            backgroundColor: theme.color.black
        },
        queryBotResWrapper: {
            borderTopLeftRadius: 40
        },
        bottomStickyWrapper:{
            position:'relative'
        },
        topHeadWithCrossIcon:{
            width: '90%',
            backgroundColor: theme.color.black,
            borderTopRightRadius:20,
            position:'absolute',
            top:-25,
            minHeight:50
        },
        closeIcon:{
            width:12,
            height:12,
            tintColor: theme.color.white
        },
        queryBtn:{
            backgroundColor: theme.color.gray5,
            borderRadius: 4,
            width:'48%',
            minHeight:52
        },
        queryBtnActive:{
            backgroundColor: theme.color.purpleyGrey2
        },
        midSection:{
            width:16
        },
        iconStyle:{
            width: 28,
            height: 28
        },
        resolveTxt:{
            fontSize: fontSizes.md,
            color: theme.color.black
        },
        resolveTxtActive:{
            color: theme.color.white
        } 
    });

    return styles;
};