import {StyleSheet} from 'react-native'
export default (theme,fonts) => 
  StyleSheet.create({
    cardHead: {
      fontSize: fonts.fontSize.font21,
      color: theme.color.defaultTextColor,
      ...fonts.lightFontFamily,
      marginBottom: 10,
    },
    linkTxt: {
      color: theme.color.submitDateChangeReqBtnText,
      fontSize: fonts.fontSize.font14,
      ...fonts.regularFontFamily,
    },
    tag: {
      borderRadius: 4,
      backgroundColor: theme.color.lightGray,
      padding: 7,
      width: 140,
      alignItems: 'center',
      marginBottom: 10,
    },
    tagTxt: {
      color: theme.color.defaultTextColor,
      fontSize: fonts.fontSize.font14,
      ...fonts.boldFontFamily,
    },
    bookingInfo: {
      ...fonts.regularFontFamily,
      fontSize: fonts.fontSize.font14,
      color: theme.color.defaultTextColor,
      marginBottom: 5,
    },
    bookingInfoSm: {
      ...fonts.regularFontFamily,
      fontSize: fonts.fontSize.font11,
      color: theme.color.lightTextColor,
    },
  }
);
