import getPlatformElevation from "@mmt/legacy-commons/Styles/getPlatformElevation";
import { StyleSheet } from "react-native";

const getStyles = (fonts, color) => (
    StyleSheet.create({
      topSection: {
        ...getPlatformElevation(2),
        backgroundColor: color.white,
        paddingHorizontal: 12,
        paddingTop: 16,
        paddingBottom: 12,
        borderTopRightRadius: 8,
        borderTopLeftRadius: 8,
      },
      headingWrapper: {
        flexDirection: 'row',
      },
      iconWrapper: {
        marginRight: 8,
        alignItems: 'center',
        justifyContent: 'flex-start',
      },
      cardIconStyle: {
        width: 24,
        height: 24,
      },
      detailsSection: {
        paddingLeft: 32,
        paddingRight: 0,
      },
      rightArrowStyle: {
        transform: [
          {
            rotate: '-90deg',
          },
        ],
        width: 15,
        height: 10,
      },
      redirectLink: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingVertical: 20,
        alignItems: 'center',
        paddingLeft: 44,
        paddingRight: 20,
        borderBottomWidth: 1,
        borderBottomColor: color.lightGrey3,
      },
      CardContent: {
        backgroundColor: color.white,
        flexDirection: 'row',
        marginTop: 10,
      },
      discountText: {
        marginRight: 10,
        color: color.green,
      },
      couponTxt: {
        color: color.greyLight,
        borderWidth: 1,
        borderColor: color.darkGrey3,
        borderRadius: 5,
        marginTop: 0,
        marginBottom: 10,
        padding: 2,
      },
      couponDiscription: {
        color: color.defaultTextColor,
      },
      hideView: {
        display: 'none',
      },
      headerStyle: {
        fontSize: fonts.fontSize.font16,
        color: color.black,
        ...fonts.blackFontFamily
      },
      subHeaderStyle: {
        fontSize: fonts.fontSize.font14,
        color: color.defaultTextColor,
        ...fonts.regularFontFamily
      },
      buttonRadiusStyle:{
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8
      }
    })
)

export default getStyles;