import React from 'react';
import PropTypes from 'prop-types';
import {Image, Text, View} from 'react-native';
import styles from '../../CSS/VisaMamiCss';
import {handleClickEvent, handleEvent} from '../../Util/VisaUtil';
import {getActionButton, isNotNullAndEmpty} from '../../../hotel/details/utils/HotelBookingDetailUtil';
import VisaDetailConstant from "../../Constant/VisaDetailConstant";
import {visaRejectionCardIcon} from '../../Constant/VisaImageConstant';


class VisaRejectionMessage extends React.Component {
    static navigationOptions = {
      header: null
    };
    render() {
      return (<View style={[styles.MamiCard, styles.mar10Bt]} >
        <View style={styles.VisaRejectionCardContent}>
          <View style={styles.flexRow}>
            <Image style={styles.CardIcon} source={visaRejectionCardIcon} />
            <View style={styles.flex1}>
              <Text style={[
                          styles.blackFont,
                          styles.BlackText,
                          styles.font14,
                          styles.mar4Top,
                          styles.mar12Bt]}
              >
                          Visa Rejected, What next?

              </Text>
              <Text style={[styles.font14, styles.defaultGrey, styles.lineHeight22, styles.mar5Bt]}>
                {this.getRejectionMessage()}
              </Text>
            </View>
          </View>
        </View>
        <View style={isNotNullAndEmpty(this.props.card.actionList) ? styles.callButtonHalfOuter : styles.hideView}>
          {getActionButton(this.props.card, this.props.response, this.handleClick)}
        </View>
      </View>);
    }

    handleClick = (action) => {

      handleEvent(action, this.props.response.customerCareNumber, this.props.response, this.props.pageName);
    };

    getRejectionMessage() {
      if (this.props.visaRejectionString !== '' && this.props.visaRejectionString !== null)
      { return this.prop.visaRejectionString; }
      return `The  ${this.props.visaCountry.toLowerCase()} ${VisaDetailConstant.VISA_MESSAGE_REJECTION}`;
    }
}

VisaRejectionMessage.propTypes = {
  visaCountry: PropTypes.string.isRequired,
  visaRejectionString: PropTypes.string,
  card: PropTypes.object.isRequired,
  response: PropTypes.object.isRequired,
  pageName: PropTypes.string.isRequired
};

VisaRejectionMessage.defaultProps = {
  visaRejectionString: ''
};
export default VisaRejectionMessage;
