import { FILTERS } from '../summaryConstants';
import { SearchTicketRequestType, SelectedFilterShowMore, summaryStateObjectType } from "../types";
import { isEmpty } from 'lodash';

export const getShowMoreFilterRequestData = (summaryState: summaryStateObjectType, selectedFilters: { current: SelectedFilterShowMore[] }, pageNo: number, bookingStatus: string) => {
  const prevState = { ...summaryState };
  let updatedSelectedFilters = [];
  if(!isEmpty(selectedFilters.current)){
    updatedSelectedFilters = [...selectedFilters.current];
  }
  updatedSelectedFilters = updatedSelectedFilters.map((item) => ({
    key: item.key,
    values: !isEmpty(item.values) && item.values.map((value: {id:string}) => value.id),
  }));
  const bookingStatusIndex = updatedSelectedFilters.findIndex((filter) => filter.key === FILTERS.BOOKING_STATUS);

  if (bookingStatusIndex < 0) {
    // This change is done as the backend does not support filters for Show More API
    updatedSelectedFilters.push({
      key: FILTERS.BOOKING_STATUS,
      values: [bookingStatus],
    });
  } else {
    updatedSelectedFilters[bookingStatusIndex].values = [bookingStatus];
  }

  let filteredPageNumber;
  if (pageNo) {
    filteredPageNumber = pageNo;
  } else {
    if (prevState[bookingStatus]) {
      filteredPageNumber = prevState[bookingStatus]?.currentFilteredPage + 1;
    } else {
      filteredPageNumber = 1;
    }
  }
  return {
    filteredPageNumber,
    updatedSelectedFilters,
  };
};


export const getTicketRequestData = ()=> {
  const searchTicketRequest:SearchTicketRequestType= {};
  searchTicketRequest.eventType = 'MIMA_LISTING';
  searchTicketRequest.sourceOfVisit = 'MIMA_LISTING';
  searchTicketRequest.auditorType = 'CUSTOMER';
  searchTicketRequest.tenant = 'MMT';
  return searchTicketRequest;

}