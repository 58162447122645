import isEmpty from 'lodash/isEmpty';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { BackHandler, Image, Text, TouchableOpacity, View } from 'react-native';
import { showShortToast } from '@mmt/legacy-commons/Common/Components/Toast';
import {  isAlphaNumericStringWithoutSpace } from '@mmt/legacy-commons/Common/utils/StringUtils';
import CommonBottomOverlayMessage from '../../../Common/CommonOverlayMessage/CommonBottomOverlayMessage';
import OTPBottomOverlay from '../../../Common/InstantRefund/Overlays/OTPBottomOverlay';
import useApi from '../../../Common/useApi';
import { CUSTOMER_SUPPORT } from '../../../contactus/constants';
import { getStaticData } from '../../../staticData/staticData';
import { ThemeContext } from '../../../theme/theme.context';
import {
  ADD_BOOKING_SEND_OTP,
  ADD_BOOKING_VALIDATE_OTP
} from '../../../utils/NetworkUtils';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import BaseButton, { BUTTON_VARIANT } from '../../../Common/BaseButton';
import { getFont } from '../../../PostSalesStyles';
import { openDetailsPageByLob } from '../../utils';
import BookingIdCard from './BookingIdCard';
import createStyle from './styles';
import AddBookingTrackingHelper, { ADD_BOOKING_TRACKING_EVENTS } from './tracking';

const AddBookingBottomOverlay = ({ handleOverlay:handleBottomSheet, uuid, response, source=null }) => {
  const [sendingOtp, sendOtpResponse, sendOtpApi] = useApi(ADD_BOOKING_SEND_OTP);
  const [verifingOtp, verifyOtpResponse, verifyOtpApi] = useApi(ADD_BOOKING_VALIDATE_OTP);
  const [bookingValue, setBookingValue] = useState('');
  const [showOtpScreen, setShowOtpScreen] = useState(false);
  const [errorText, setErrorText] = useState('');
  const otpValue = useRef({ value: '' });

  const { psTheme: theme } = useContext(ThemeContext);
  const { errorText: staticErrorText } = getStaticData();
  const { somethingWentWrongTryAgainError } = staticErrorText;
  const isCustomerSupportFlow = source === CUSTOMER_SUPPORT;

  const { header, description, sendOTPButton, sendOTPCloseImageURL, invalidBookingIdText } =
    response?.addBookingOTPCTA || {};
  const fonts = getFont(true);
  const styles = createStyle(theme, fonts);

  const handleOverlay = (value) => {
    if (handleBottomSheet) {
      handleBottomSheet(value);
    }
  };

  const onHardBackPress = (showOtpScreen) => {
    if (showOtpScreen) {
      setShowOtpScreen(false);
    } else {
      handleOverlay(false);
    }
    return true;
  };


  useEffect(() => {
    AddBookingTrackingHelper.trackLoadEvent(
      ADD_BOOKING_TRACKING_EVENTS.mytrips_addbooking_sheet_opened,
      uuid,
    );
  }, []);

  useEffect(() => {
   if (!isCustomerSupportFlow){
    BackHandler.addEventListener('hardwareBackPress', () => onHardBackPress(showOtpScreen));
    return () =>
      BackHandler.removeEventListener('hardwareBackPress', () => onHardBackPress(showOtpScreen));
   }
  }, [showOtpScreen]);

  useEffect(() => {
    if (sendOtpResponse && sendOtpResponse?.success) {
      setShowOtpScreen(true);
      setErrorText("")
    } else if (sendOtpResponse && sendOtpResponse?.error) {
      setErrorText(somethingWentWrongTryAgainError);
    } else {
      setErrorText(sendOtpResponse?.message);
    }
  }, [sendOtpResponse]);

  useEffect(() => {
    if (verifyOtpResponse && verifyOtpResponse?.error) {
      showShortToast(sendOtpResponse?.validateOTPCTA?.addBookingFailureToastMsg);
      handleOverlay(false);
    } else if (
      verifyOtpResponse?.response?.status === 'SUCCESS' &&
      Array.isArray(sendOtpResponse?.lobType)
    ) {
      showShortToast(sendOtpResponse?.validateOTPCTA?.addBookingSuccessToastMsg);
      const data = {
        bookingID: bookingValue,
        uuid: sendOtpResponse.uuid,
        isAddBookingFlow:true
      }
      setShowOtpScreen(false);
      setBookingValue('');
      openDetailsPageByLob(sendOtpResponse?.lobType[0], data, data);
      handleOverlay(false);
    } else if (verifyOtpResponse?.validateOTPStatus) {
      showShortToast(sendOtpResponse?.validateOTPCTA?.addBookingFailureToastMsg);
      handleOverlay(false);
    } else {
      setErrorText(sendOtpResponse?.validateOTPCTA?.validateOTPfailureMsg);
    }
  }, [verifyOtpResponse]);


  const handleBookingValue = (bookingValue) => {
    const bookingValueInUpperCase = bookingValue &&  bookingValue.toUpperCase();
    setBookingValue(bookingValueInUpperCase);
    if (bookingValue === '') setErrorText('');
    else if (!isAlphaNumericStringWithoutSpace(bookingValue)) {
      setErrorText(invalidBookingIdText);
    } else {
      setErrorText('');
    }
  };

  const sendOtp = () => {
    AddBookingTrackingHelper.trackClickEvent(
      ADD_BOOKING_TRACKING_EVENTS.Addbooking_sheet_sendotp_clicked,
      uuid,
    );
    const body = {
      bookingId: bookingValue,
    };

    sendOtpApi.httpPost({
      uniqueId: 'ADD_BOOKING_SEND_OTP',
      body,
    });
  };

  const handleOTPValue = (e) => {
    setErrorText("")
    otpValue.current.value = e;
  };

  const handleOTPScreen = () => {
    setShowOtpScreen(false);
  };

  const verifyOtp = () => {
    const OTPvalue = otpValue.current.value.trim();
    if (OTPvalue) {
      AddBookingTrackingHelper.trackClickEvent(
        ADD_BOOKING_TRACKING_EVENTS.Addbooking_sheet_verifyotp_clicked,
        uuid,
      );
      const body = {
        bookingId: bookingValue,
        token: OTPvalue,
        clientTransactionId: sendOtpResponse.data.clientTransactionId,
        bookerUUID: sendOtpResponse.uuid,
        purpose: sendOtpResponse.purpose,
      };
      verifyOtpApi.httpPost({
        uniqueId: 'ADD_BOOKING_VERIFY_OTP',
        body,
      });
    }
  };

  const handleOTPResend = () => {
    setErrorText('');
    sendOtp();
  };

  const renderOverlayScreen = () => {
    if (showOtpScreen) {
      return (
        <View>
          <OTPBottomOverlay
            handleOTPValue={handleOTPValue}
            handleBottomOverlay={handleOTPScreen}
            handleOTPOverlay={verifyOtp}
            resendOtp={handleOTPResend}
            errorText={errorText}
            errorState={!!errorText}
            heading={sendOtpResponse?.validateOTPCTA?.header || ''}
            subHeading={sendOtpResponse?.validateOTPCTA?.description || ''}
            inputFieldTitleText={sendOtpResponse?.validateOTPCTA?.enterOTP || ''}
            leftButtonText={sendOtpResponse?.validateOTPCTA?.cancel || ''}
            rightButtonText={sendOtpResponse?.validateOTPCTA?.proceed || ''}
            note={sendOtpResponse?.validateOTPCTA?.resendOTP || ''}
            counter={
              sendOtpResponse?.validateOTPCTA?.resendTimeLimit
                ? Number(sendOtpResponse?.validateOTPCTA?.resendTimeLimit)
                : 0
            }
            key={sendOtpResponse?.data?.clientTransactionId}
            headingStyle={styles.otpHeading}
            subHeadingStyle={styles.otpSubHeading}
          />
        </View>
      );
    } else {
      return (
        <>
          <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween, AtomicCss.marginBottom8]}>
            {!!header && <Text style={styles.heading}>{header}</Text>}
            {!isCustomerSupportFlow && (
              <TouchableOpacity
                style={[styles.closeIconWrapper, AtomicCss.alignCenter, AtomicCss.justifyStart]}
                onPress={() => handleOverlay(false)}
              >
                <View style={[AtomicCss.alignCenter, AtomicCss.justifyCenter]}>
                  {sendOTPCloseImageURL && (
                    <Image style={styles.closeIcon} source={{ uri: sendOTPCloseImageURL }} />
                  )}
                </View>
              </TouchableOpacity>
            )}
          </View>
          {!!description && (
            <Text style={[styles.subHeading, AtomicCss.defaultText, AtomicCss.marginBottom24]}>
              {description}
            </Text>
          )}
          <View style={AtomicCss.marginBottom48}>
            <BookingIdCard
              bookingValue={bookingValue}
              handleBookingValue={handleBookingValue}
              errorText={errorText}
              textData={response?.addBookingOTPCTA || {}}
            />
          </View>
          <BaseButton
            variant={BUTTON_VARIANT.PRIMARY}
            text={sendOTPButton || ''}
            textStyle={styles.subHeading}
            clickHandler={sendOtp}
            disabled={isEmpty(bookingValue) || !!errorText || !!sendingOtp}
            buttonStyle={AtomicCss.marginBottom30}
          />
        </>
      );
    }
  };
  if (isCustomerSupportFlow) {
    return renderOverlayScreen();
  }
return (
  <CommonBottomOverlayMessage
    isBorderRadius
    dismiss={null}
    content={renderOverlayScreen()}
    isCancellable={false}
  />
);
};

export default AddBookingBottomOverlay;
