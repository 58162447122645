import React from 'react';
import get from 'lodash/get';
import { Image, Text, View } from 'react-native';
import styles from '../PassengerSelection/PassengerSelectionCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { WARNING_ICON } from '../../../../../../FlightImageConstants';
import SpecialClaimRaiseInfo from '../../../../../../cancellation/components/SpecialClaimRaiseInfo';
import { getFont } from '../../../../../../../PostSalesStyles';

const InvalidPaxCard = React.memo(
  ({ segmentInfo, shouldShowSpClmText, cancellationButtonClick, specialClaimData }) => {
    const { fsStyle, ...fonts } = getFont(true);
    const paxHeading = get(segmentInfo, 'paxHeaderList', []);
    const paxDesc = get(segmentInfo, 'paxDescriptionList', []);
    return (
      <View style={[styles.specialClaimCont]}>
        {paxHeading &&
          paxHeading.length > 0 &&
          paxHeading.map((heading) => {
            return (
              <View style={[AtomicCss.flex1, styles.scHeading]}>
                <Image source={WARNING_ICON} style={styles.warningIcon} />
                <Text style={[styles.sclaimHeader, fonts.blackFontFamily, fsStyle.font14]}>
                  {heading}
                </Text>
              </View>
            );
          })}
        {paxDesc &&
          paxDesc.length > 0 &&
          paxDesc.map((desc, index) => {
            return (
              <View style={[styles.description, index === 0 ? styles.descBorder : '']}>
                <Text>{desc} </Text>
              </View>
            );
          })}
        {shouldShowSpClmText && (
          <SpecialClaimRaiseInfo
            cancellationButtonClick={cancellationButtonClick}
            type="partialPax"
            specialClaimData={specialClaimData}
          />
        )}
      </View>
    );
  },
);

export default InvalidPaxCard;
