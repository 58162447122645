import React from 'react';
import { View, StyleSheet } from 'react-native';
import { PlaceholderContainerWrapper } from '../ShimmerV2';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { useTheme } from '../../theme/theme.context';
import Shimmer from '@Frontend_Ui_Lib_App/Shimmer';


interface ShimmerCardProps {
    rows: number;
}

interface Theme {
  color: {
    white: string;
  };
}

const ShimmerCard: React.FC<ShimmerCardProps> = ({ rows }) => {
  const {psTheme} = useTheme();
  const styles = getStyle(psTheme);
  const getAnimRows = () => {
    let animRows: JSX.Element[] = [];
    for (let iter = 0; iter < rows; iter++) {
      animRows.push(
        <View style={styles.shimmerWrapper} key={iter}>
          <View style={[AtomicCss.flexRow, AtomicCss.marginRight12]}>
            <Shimmer height={24} width={24} marginRight={12} style={{ borderRadius: 4 }} />
            <View>
              <Shimmer height={12} width={119} marginBottom={14} />
              <Shimmer height={12} width={227} marginBottom={11} />
            </View>
          </View>
        </View>,
      );
    }
    return animRows;
  };

  return (
    <PlaceholderContainerWrapper>
      <View>
        <Shimmer height={20} width={230} marginTop={8} marginBottom={12} />
        {getAnimRows()}
        <Shimmer height={44} width={'100%'} marginTop={8} marginBottom={12} />
      </View>
    </PlaceholderContainerWrapper>
  );
};


const getStyle = (theme : Theme) => {
  const {color} = theme;
  return StyleSheet.create({
    shimmerWrapper : {
      marginBottom: 12,
      backgroundColor: color.white,
      padding: 16
    }
  })
}
export default ShimmerCard;
