import React from 'react';
import {View, TouchableOpacity} from 'react-native';
import PropTypes from 'prop-types';
import styles from './OverlayMessageCss';

/**
 * this Overlay can be used to wrap an element. This Overlay shall occupy standard(1/4) or depending on content size from the base of the screen
 */

export default class OverlayMessage extends React.Component {
  
  render() {
    const isRadius = this.props.isBorderRadius === true ? 'isBorderRadius':'';
    return (
      <View style={this.props.keyboard ? styles.overlayWrapperKeyboard : styles.overlayWrapper}>
        <TouchableOpacity onPress={() => this.props.handleBottomOverlay(' ')} activeOpacity={1} style={styles.overlay} />
        <View style={styles.overlayContent}>
          {this.props.content}
        </View>
      </View>
    );
  }
}

OverlayMessage.propTypes = {
  content: PropTypes.element,
  keyboard: PropTypes.bool,
  handleBottomOverlay: PropTypes.func.isRequired,
  isBorderRadius:PropTypes.bool
};

OverlayMessage.defaultProps = {
  content: null,
  keyboard: false,
  isBorderRadius:false
};

