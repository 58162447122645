import { useEffect, useState } from 'react';

const useEffectAfterMount = (cb, deps) => {
    const [isMounted, setMounted] = useState(false);
    useEffect(() => {
        if (isMounted) {
            return cb();
        }
        setMounted(true);
    }, deps);
};

export default useEffectAfterMount;
