import { StyleSheet } from 'react-native';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
export const createStyles = (theme, font) =>
  StyleSheet.create({
    travelCardCardStyle: {
      borderRadius: 8,
      borderColor: theme.color.lavender,
      borderWidth: 1,
      padding: 16,
      flexDirection: 'row',
      alignItems: 'center',
      flexWrap: 'wrap',
      ...getPlatformElevation(3),
      marginHorizontal: 16,
      marginBottom: 12,
    },
    infoIconStyle: {
      width: 40,
      height: 40,
      marginRight: 6,
    },
    titleStyle: {
      color: theme.color.black,
      marginBottom: 5,
      ...font.font16,
      ...font.blackFontFamily,
    },
    subTitleStyle: {
      color: theme.color.greyText1,
      ...font.font12,
    },
    descStyle: {
      marginTop: 8,
      color: theme.color.textGrey,
      ...font.font12,
    },
  });
