import { StyleSheet } from "react-native";
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
export default (theme, fonts) =>StyleSheet.create({
        contentWrapper:{
            backgroundColor:theme.color.lightGrey
        },
        whiteColor:{
            backgroundColor:theme.color.white,
        },
        topStickySection:{
            ...getPlatformElevation(2),
            backgroundColor:theme.color.white,
            zIndex:10,
        },
        cardWrapper:{
            padding : 10,
            backgroundColor: theme.color.white,
            marginBottom:20,
        },
        pageWrapper:{
            padding:16,
            marginBottom:12,
            backgroundColor:theme.color.white,
        },
        buttonBottomWrapper: { 
            position: 'absolute', 
            bottom: 0, 
            textAlign:'center',
            width:'100%'
        },
        
        flatBtnWrapper:{
            borderRadius:4
        },
        flatBtn:{
            borderRadius:8,
            paddingHorizontal:20,
            paddingVertical:14,
    
        },
        btnText:{
            fontSize:16,
            fontFamily:fonts.black,
            lineHeight:20,
            color:theme.color.white,
        },
        selectModeWrapper:{
            position:'relative',
            zIndex:2,
        },
        uploadDocWrapper:{
            position:'relative',
            zIndex:4,
            top:0
        },
        UploadDocumentsOldWrapper:{
            position:'relative',
            zIndex:6,
        },
        stepBarWrapper:{
            marginBottom:12
      },
      disabledLine:{
        backgroundColor:theme.color.grey24,
        width:36,
        height:6,
        borderRadius:2,
        marginRight:4
    },
    activeLine:{
        backgroundColor:theme.color.lightBlue17,
    },
    activeDot:{
        backgroundColor:theme.color.lightBlue17,
    },

});