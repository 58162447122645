import { Dimensions, StyleSheet } from 'react-native';
import { Fonts, Theme } from '../../../../types';

const getStyles = (fonts: Fonts, theme: Theme) => {
	const { color } = theme;
	return StyleSheet.create({
		headingSection: {
			flexDirection: 'row',
			alignItems: 'center',
			borderBottomWidth: 1,
			borderBottomColor: color.gray3,
			paddingTop: 24,
			paddingBottom: 16,
			paddingHorizontal: 16,
		},
		headingText: {
			color: color.black,
			...fonts.boldFontFamily,
			fontSize: 16,
			marginBottom: 5,
			flex: 1,
		},
		detailsSection: {
			maxHeight: Dimensions.get('window').height - 300,
			paddingHorizontal: 16,
		},
		bodySection: {
			paddingVertical: 12,
		},

		crossIconStyle: {
			width: 15,
			height: 15,
			tintColor: color.greyText1,
			marginRight: 12,
		},
		headingDetails: {
			borderBottomWidth: 1,
			borderBottomColor: color.gray3,
			paddingBottom: 12,
			marginBottom: 16,
		},
		btnWrpper: {
			backgroundColor: color.white,
			paddingHorizontal: 20,
			paddingTop: 10,
			borderTopWidth: 1,
			borderTopColor: color.gray3,
		},
		btnTxtStyle: { fontSize: 14, fontWeight: '700' }
	});
};

export default getStyles;
