import { StyleSheet } from 'react-native';

export default (theme, fonts) => {
  const { color } = theme;
  return StyleSheet.create({
    tollRefund: {
      borderRadius: 8,
      paddingVertical: 12,
      paddingRight: 30,
      paddingLeft: 12,
      marginTop: 12,
      marginBottom: 12,
    },
    refundBgImage: {
      width: 65,
      height: 73,
      position: 'absolute',
      right: 0,
      top: 0,
    },
    myCaseIconStyle: {
      width: 20,
      height: 20,
      marginRight: 6,
    },
    cardHeading: {
      color: color.lightGreen18,
      fontSize: fonts.fontSize.font16,
      ...fonts.boldFontFamily
    },
    arrowStyle: {
      width: 14,
      height: 14,
      tintColor: color.lightText,
      marginLeft: 5,
      marginTop:4
    },
    discriptionStyle: {
      color: color.defaultTextColor,
      fontSize: fonts.fontSize.font14,
      ...fonts.regularFontFamily,
      marginBottom:10
    },
    actionLableStyle: {
      color: color.black,
      fontSize: fonts.fontSize.font12,
      ...fonts.boldFontFamily
     
    }
  });
};
