import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import createStyle from './DateChangeCardCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { isNull , isEmpty } from 'lodash';
import { imageTransformY } from '../../../../../utils/PostSaleUtil';
import { CHANGE_TRV_DT_ICON, RIGHT_ARROW, REFUND_CALENDAR } from '../../../../FlightImageConstants';
import { getFont } from '../../../../../PostSalesStyles';
import { useTheme } from '../../../../../theme/theme.context';
import { RefNameConst } from '../../FlightBookingDetailsConstant';

const DateChangeCard = ({
  delayedOdcDetails,
  dateChangeCacheDetail,
  differentialPenaltyList,
  dateChangeButtonClick,
  pageName,
  isFreeDateChangeAssured,
  refElement,
  toggleCtripODCViewMoreOverlay,
}) => {
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyle(theme,fonts);
  const actionItemViews = () => {
    if (!dateChangeCacheDetail.dateChangeCards) {
      return null;
    }

    return Object.keys(dateChangeCacheDetail.dateChangeCards).map((dateChangeActionItemKey) => {
      const value = dateChangeCacheDetail.dateChangeCards[dateChangeActionItemKey];
      if (value) {
        return actionItemView(dateChangeActionItemKey, value);
      }
      return null;
    });
  };

  const actionItemView = (key, value) => (
    <TouchableOpacity
      style={styles.redirectBtnWrapper}
      activeOpacity={0.8}
      onPress={() => dateChangeButtonClick(key, pageName)}
    >
      <Text style={[styles.linkBtnTxt, fonts.boldFontFamily, fsStyle.font14]}>{value}</Text>
      <Image style={[styles.rightArrowIcon, imageTransformY]} source={RIGHT_ARROW} />
    </TouchableOpacity>
  );
  const dcHeaderColorClass = !isNull(dateChangeCacheDetail.dcHeaderColorClass)
    ? AtomicCss[dateChangeCacheDetail.dcHeaderColorClass]
    : {};
    const {heading , subheading , popupInfo} = delayedOdcDetails || {};
    const { clickableText } = popupInfo || {};
  return (
    <View
      style={[AtomicCss.marginBottom10]}
      ref={(e) => refElement(e, RefNameConst.FLIGHT_DATE_CHANGE_REF)}
    >
      <View style={styles.wrapperStyle}>
        <View style={[styles.infoCardWrapper]}>
          <View style={styles.headingIconWrapper}>
          {isFreeDateChangeAssured ? (
            <Image style={styles.headingIconStyle} source={REFUND_CALENDAR} />
          ) : (
            <Image style={styles.headingIconStyle} source={CHANGE_TRV_DT_ICON} />
          )}
          </View>
          <View style={styles.infoCardContent}>
            <Text
              style={[
                AtomicCss.defaultText,
                fonts.blackFontFamily,
                fsStyle.font14,
                AtomicCss.marginBottom15,
                AtomicCss.alignLeft,
              ]}
            >
              {dateChangeCacheDetail.dcMainText}
            </Text>
            {dateChangeCacheDetail.dcHeaderText && (
              <Text
                style={[
                  fonts.regularFontFamily,
                  fsStyle.font14,
                  AtomicCss.defaultText,
                  AtomicCss.lineHeight20,
                  dcHeaderColorClass,
                  AtomicCss.alignLeft,
                ]}
              >
                {dateChangeCacheDetail.dcHeaderText}
              </Text>
            )}
            {dateChangeCacheDetail.dcSubText && (
              <Text
                style={[
                  fonts.regularFontFamily,
                  fsStyle.font14,
                  AtomicCss.paddingTop16,
                  AtomicCss.defaultText,
                  AtomicCss.lineHeight20,
                ]}
              >
                {dateChangeCacheDetail.dcSubText}
              </Text>
            )}
          </View>
        </View>
        {delayedOdcDetails && (
          <View style={styles.dateChangeBoxContainer}>
            <View style={styles.dateChangeBoxStyle}>
              <Text style={[styles.ctripODCHeading, fsStyle.font14, AtomicCss.defaultText, fonts.regularFontFamily]}>
                {heading}
              </Text>
              <View>
                <Text styles={[fsStyle.font14, AtomicCss.defaultText, fonts.regularFontFamily]}>
                  {subheading}
                </Text>
                {!isEmpty(clickableText) && (
                  <TouchableOpacity activeOpacity={0.8} onPress={toggleCtripODCViewMoreOverlay}>
                    <Text style={[fonts.regularFontFamily, styles.linkBtnTxt, fsStyle.font14]}>
                      {clickableText}
                    </Text>
                  </TouchableOpacity>
                )}
              </View>
            </View>
          </View>
        )}
      </View>
      {actionItemViews()}
    </View>
  );
};

DateChangeCard.propTypes = {
  dateChangeCacheDetail: PropTypes.object.isRequired,
  dateChangeButtonClick: PropTypes.func.isRequired,
  differentialPenaltyList: PropTypes.array.isRequired,
  pageName: PropTypes.string.isRequired,
};
export default DateChangeCard;
