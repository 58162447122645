import React from 'react';
import { View, Text, Image, StyleSheet } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getFont } from '../../../../../PostSalesStyles';
import { useTheme } from '../../../../../theme/theme.context';
import { BUS_YELLOW_ICON, RIGHT_ARROW } from '../../../../BusImageConstants';
import BusCancelRebookConstant from '../../../CancelRebookConstants';
import ChangeDateCard from '../ChangeDateCard';
import createStyles from './TopTravelCardCSS';
const TopTravelCard = ({ handleDateChange, previousBokingData, date, tab }) => {

  const { primaryCustomerDetails, busAdditionalInfo, busBookingInfo } = previousBokingData || {};
  const { departureDetail, arrivalDetail } = busAdditionalInfo || {};
  const { pickupLocation: boardingCity, address: boardingAddress } = departureDetail || {};
  const { dropLocation: dropCity, address: dropAddress } = arrivalDetail || {};
  const { firstName, middleName, lastName } = primaryCustomerDetails || {};
  const { busDetail } = busBookingInfo || {};
  const { operator } = busDetail || {};
  const name = (firstName || '') + ' ' + (middleName || '') + ' ' + (lastName || '');

  const { fsStyle, ...fonts } = getFont();
  const {psTheme : theme} = useTheme();
  const styles = createStyles(theme, fonts);

  function getBoardingDropLocation(address, city,styleProps={}) {
    return (
      <View style={[AtomicCss.flexColumn,AtomicCss.flex2,styleProps]}>
        {!!address && (
          <Text
            style={[
              fsStyle.font14,
              AtomicCss.defaultText,
              fonts.regularFontFamily,
              AtomicCss.lineHeight20,
            ]}
          >
            {address},
          </Text>
        )}
        {!!city && <Text style={fonts.boldFontFamily}>{city}</Text>}
      </View>
    );
  }
  return (
    <View style={AtomicCss.marginTop10}>
      <View style={[AtomicCss.flexRow,AtomicCss.spaceBetween]}>
      {!!operator && (
        <Text
          style={[
            fsStyle.font19,
            AtomicCss.blackText,
            fonts.boldFontFamily,
            AtomicCss.marginBottom14,
          ]}
        >
          {operator}
        </Text>
      )}
      <Image source={BUS_YELLOW_ICON} style={styles.busIcon} />
      </View>
      <View style={styles.destCard}>
        <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom10]}>
          {getBoardingDropLocation(boardingAddress, boardingCity,AtomicCss.alignItemsStart)}
          <View style={[styles.imageContainer]}>
            <Image source={RIGHT_ARROW} style={[styles.rightArrow]} />
          </View>
          {getBoardingDropLocation(dropAddress, dropCity,AtomicCss.alignItemsEnd)}
        </View>
        {!!name && (
          <Text
            style={[
              fsStyle.font13,
              AtomicCss.midGreyText,
              fonts.regularFontFamily,
              AtomicCss.lineHeight18,
            ]}
          >
            {BusCancelRebookConstant.FOR_TEXT} {name}
          </Text>
        )}
      </View>
      {tab === BusCancelRebookConstant.BUS_SEARCH_STATE && (
        <ChangeDateCard handleDateChange={handleDateChange} date={date} />
      )}
    </View>
  );
};

export default TopTravelCard;
