import React, { useEffect } from 'react';
import { View, Image, Text, ScrollView } from 'react-native';
import getStyle from './RefundRequestFailedCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getFont } from '../../../../../PostSalesStyles';
import Button from '../../../../../Common/Button';
import { FAILED_CROSS_ICON } from '../../../../FlightImageConstants';
import { getStaticData } from '../../../../../staticData/staticData';
import FlightBookingTrackingHelper from '../../../../modules/details/helper/FlightBookingTrackingHelper';
import { useTheme } from '../../../../../theme/theme.context';

const RefundRequestFailed = ({ goBackToBooking }) => {
  const { fsStyle, ...fonts } = getFont(true);
  const {psTheme} = useTheme();
  const {color} = psTheme;
  const styles = getStyle(psTheme);
  useEffect(() => {
    FlightBookingTrackingHelper.trackLoadEvent('Specialclaim_fullblock_new_error|personal', {});
  }, []);
  const {
    refundRequestFailed,
    pleaseTryAgain,
    technicalIssue,
    goBackToBookingText,
  } = getStaticData();
  return (
    <React.Fragment>
      <ScrollView>
        <View style={styles.fullPage}>
          <View style={styles.topSection}>
            <Image style={styles.mainconStyle} source={FAILED_CROSS_ICON} />
            <Text style={[styles.titleHeading, fonts.blackFontFamily, fsStyle.font20]}>
              {refundRequestFailed}
            </Text>
          </View>
          <View style={styles.detailSection}>
            <View style={[styles.detailCard]}>
              <View style={styles.detailsTop}>
                <Text
                  style={[
                    color.black,
                    fonts.blackFontFamily,
                    fsStyle.font14,
                    AtomicCss.lineHeight20,
                    AtomicCss.marginBottom6,
                  ]}
                >
                  {pleaseTryAgain}
                </Text>
                <Text
                  style={[
                    color.defaultTextColor,
                    fonts.regularFontFamily,
                    fsStyle.font14,
                    AtomicCss.lineHeight20,
                  ]}
                >
                  {technicalIssue}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </ScrollView>

      <View style={[styles.buttonBottomWrapper]}>
        <Button
          btnWidth={'98%'}
          btnBg="gradientBtn"
          btnType="flat"
          fontSize={fsStyle.font16}
          btnTxt={goBackToBookingText}
          buttonTextCss={fsStyle.font16}
          clickHandler={goBackToBooking}
        />
      </View>
    </React.Fragment>
  );
};

export default RefundRequestFailed;
