import React from 'react';
import { View, TouchableOpacity, Image, Text, Dimensions } from 'react-native';
import Carousel from 'react-native-snap-carousel';
import createStyle from './style';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import { WhereToGOImage } from '../../../../types';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const sliderWidth = Dimensions.get('window').width;
const ITEM_WIDTH = sliderWidth - 50;

interface WhereToGoSliderProps{
  data: WhereToGOImage[]
}
const SliderImages = ({ data }: WhereToGoSliderProps) => {
  const { psTheme: theme } = useTheme();
  const fonts = getFont();
  const styles = createStyle(theme, fonts);
  const renderItem = ({ item }:{item: WhereToGOImage}) => {
    return (
      <TouchableOpacity style={styles.imgContainer} activeOpacity={.8}>
        <Image style={styles.image} source={{ uri: item.thumbnail }} resizeMode={'cover'} />
        <View style={styles.slideBg}></View>
        <Text style={styles.textStyle} numberOfLines={2}>{item.title}</Text>
      </TouchableOpacity>
    );
  };
  return (
    <View style={styles.container}>
      <Carousel
        data={data}
        renderItem={renderItem}
        sliderWidth={sliderWidth}
        itemWidth={ITEM_WIDTH}
        layout="default"
        loop={true}
        autoplay={true}
        autoplayInterval={2000}
      />
    </View>
  );
};

export default SliderImages;
