import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
import { Dimensions } from 'react-native';

export default (theme) => {
    const styles = {
        mainWrapper: {
            ...getPlatformElevation(2),
            backgroundColor: theme.color.white,
            borderRadius: 8,
        },
        cardContentTop: {
            position: 'relative',
            zIndex: 1,
        },
        supportImgStyle: {
            width: 88,
            height: 64,
        },
        btnWrapper: {
            width: 120,
        },
        supportBgWrapper: {
            position: 'absolute',
            left: 0,
            bottom: 0,
            zIndex: 0,
        },
        supportBgStyle: {
            width: 71,
            height: 114,
        },
        floatingCardWrapper: {
            position: 'absolute',
            right: 20,
            bottom: 20,
        },
        floatingIconTextWrap: {
            position: 'absolute',
            left: 25,
        },
        needHelpIcon: {
            width: 24,
            height: 20,
            position: 'relative',
            top: 2,
            tintColor: theme.color.white
        },
        needHelpIconWrap: {
            width: '100%',
            height: 60,
            elevation: 3,
        },
        linearGradient:{
            width: 60,
            height: 60,
            borderRadius: 50,
            alignItems:'center',
            justifyContent:'center',
        },
        cover: {
            backgroundColor: 'rgba(0,0,0,.8)',
            position:'absolute',
            width:'100%',
            height:'100%',
            left:0,
            bottom:0,
        },
        sheet: {
            position: 'absolute',
            top: Dimensions.get('window').height,
            right: 14,
            bottom: 0,
            height: '100%',
            justifyContent: 'flex-end',
            zIndex: 5,
        },
        closeTrigger:{
            position: 'absolute',
        },
        fabIcon: {
            backgroundColor: theme.color.grey22,
            marginHorizontal: 10,
            borderRadius: 50,
            alignItems: 'center',
            justifyContent: 'center',
            width:40,
            height:40,
            elevation: 5,
        },
        queryBtnOuter:{
            flexDirection:'row',
            alignItems:'center',
            width:180,
            justifyContent:'space-between',
            height:40,
            marginTop:15,
        },
        closeStyle:{
            width:14,
            height:14,
            tintColor: theme.color.white,
        },
        chatStyle:{
            width: 20,
            height: 20,
        },
        queryBtnText:{
            color: theme.color.grey22,
            width: 110,
            textAlign: 'right',
        },
        popUpouter:{
            height:230,
            position: 'absolute',
            overflow: 'hidden',
            width: 180,
            bottom: 80,
            right: 0,
            zIndex: 1,
            paddingBottom: 20,
        },
        popUpouterDissabled:{
            right: 20,
            position:'absolute',
            bottom: 80,
            paddingBottom: 20,
        },
    };
    return styles;
};
