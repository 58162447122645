const getHeaderData = (response) => {
  const { header } = response || {};
  const { pageHeading, packageName } = header || {};
  return {
    title: pageHeading,
    subTitle: packageName,
  };
};

const getCountriesCardData = (response) => {
  const { cards } = response || {};
  const { countriesCard } = cards || {};
  const {label, countries} = countriesCard || {};
  return {
    label: label,
    countries: countries.map((item) => ({
      flagImg: item.flagImageUrl,
      title: item.heading,
      visaKey: item.visaKey,
      countryCode: item.countryCode,
      travellersKey: item.travellersKey,
      description: item.description,
      disable: item.disable,
      disableText: item.disableText,
      visaType: item.visaType,
    })),
  };
};
const getFAQCardData = (response) => {
  const {cards} = response || {};
  const {faqCard} = cards || {};
  const {title, faqList, categoriesList} = faqCard || {};
  return {
    title,
    list: faqList,
    categoriesList,
    maxItem: 3,
  };
};

const getContactInformationCardData = (response) => {
  const {cards} = response || {};
  const {contactInformationCard} = cards || {};
  const {heading, description, contactInformationList} = contactInformationCard || {};
  return {
    heading,
    description,
    contactInformationList,
  };
};

const getImportantInfoCardData = (response) => {
  const {cards} = response || {};
  const {importantInformationCard} = cards || {};
  const {heading, agreementNotCheckedText, termsAndConditions, aggrementText} = importantInformationCard || {};
  const {url, tncStaticList} = termsAndConditions || {};
  return {
    heading,
    agreementNotCheckedText,
    termsAndConditions: tncStaticList,
    tncUrl: url,
    aggrementText,
  };
};

const getFareBreakupPopupData = (response) => {
  const {cards} = response || {};
  const {fareBreakupPopup} = cards || {};
  const {heading, totalPaidKey, items} = fareBreakupPopup || {};
  return {
    heading: heading,
    totalPaidKey: totalPaidKey,
    itemsHeading: items && items.heading,
  };
};

const getFooterData = (response) => {
  const {cards} = response || {};
  const {footerData} = cards || {};
  const {totalPaidKey, button, infoIconKey} = footerData || {};
  return {
    priceDtls: totalPaidKey,
    infoIconKey,
    btnText: button.text,
  };
};

export const getFareBreakup = (itemsHeading, state) => {
  const fareBreakup = {
    heading: itemsHeading,
    items: state.countriesBreakup,
  };
  return fareBreakup;
};
export const getTotalPaidData = (totalPaidKey, state) => {
  return {
    key: totalPaidKey,
    value: state.totalPrice,
  };
};

export default getAddVisaPageData = (response) => {
  return {
    headerData: getHeaderData(response),
    countriesCardData: getCountriesCardData(response),
    faqCardData: getFAQCardData(response),
    contactInformationCardData: getContactInformationCardData(response),
    importantInfoCardData: getImportantInfoCardData(response),
    fareBreakupPopupData: getFareBreakupPopupData(response),
    footerData: getFooterData(response),
    response: response,
  };
};
