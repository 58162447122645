import React from 'react';
import {
  View,
  Animated,
  Platform,
  ToastAndroid,
  Text,
  TouchableOpacity,
  NativeModules,
} from 'react-native';
import { Actions } from '../Navigation';
import _find from 'lodash/find';
import _indexOf from 'lodash/indexOf';
import _isEmpty from 'lodash/isEmpty';
import { colors, fonts } from '@mmt/legacy-commons/Styles/globalStyles';
import Toast from '@mmt/legacy-commons/Common/Components/Toast2';
import { rupeeFormatter } from '@mmt/legacy-commons/Helpers/currencyUtils';
import { getPokusConfig } from '@mmt/legacy-commons/Native/AbConfig/AbConfigModule';
import { PokusLobs } from '@mmt/legacy-commons/Native/AbConfig/AbConfigKeyMappings';
import AbConfigKeyMappings from '@mmt/legacy-commons/Native/AbConfig/AbConfigKeyMappings';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import OmnitureModule from '@mmt/legacy-commons/Native/OmnitureModule';
import { CabABKeys, cabBlockLobs } from '../cabsConstants';
import CabsABConfigHelper from './CabsABConfigHelper';
import { TravelType } from '../types/TripType';
import { getUserDetails } from '@mmt/legacy-commons/Native/UserSession/UserSessionModule';

const WHITE_BACK_ARROW = require('@mmt/legacy-assets/src/white_backarrow.webp');

export const inr = (rupees) => `\u20B9 ${rupeeFormatter(rupees)}`;

export const isRawClient = () => Platform.OS === 'web';
let commonHeadersObj = null;

export const createDeviceDetails = async () => {
  let deviceDetails = {};
  if (Platform.OS === 'ios') {
    const deviceInfo = await OmnitureModule.deviceInfo();
    if (!_isEmpty(deviceInfo)) {
      deviceDetails.os = 'ios';
      deviceDetails.osVersion = deviceInfo.dvc_os_ver;
      deviceDetails.deviceType = deviceInfo.dvc_type;
      deviceDetails.appVersion = deviceInfo.dvc_app_ver;
      deviceDetails.deviceId = deviceInfo.dvc_did;
      deviceDetails.networkType = deviceInfo.dvc_conn_type;
      deviceDetails.deviceName = `${deviceInfo.dvc_manuf} ${deviceInfo.dvc_mdl}`;
    }
  } else if (Platform.OS === 'android') {
    const deviceInfo = await GenericModule.getDeviceInfo();
    if (!_isEmpty(deviceInfo)) {
      const { androidVersion, phoneNumber, accountEmailId, ...rest } = deviceInfo;
      deviceDetails = {
        ...rest,
        os: 'android',
        osVersion: deviceInfo.androidVersion,
      };
    }
  }
  return deviceDetails;
};

export const fetchAppVersion = async () => {
  try {
    if (isRawClient()) return '';
    if (GenericModule) {
      const appVersion = await GenericModule.getAppVersion();
      return appVersion;
    } else {
      return '';
    }
  } catch (e) {
    return '';
  }
};

export const getTripDataFromState = (state) => {
  const { travelType, tripType } = state.cabsTripInfo;
  const {
    departDate,
    returnDate = null,
    sourceData = null,
    selectedAirportTypeIndex = 0,
    stops = [],
    destinationData = null,
    packageKey,
    hrPackages = [],
  } = state.cabsTripInfo[travelType];

  return {
    travelType,
    tripType,
    departDate,
    returnDate,
    sourceData,
    selectedAirportTypeIndex,
    stops,
    destinationData,
    packageKey,
    hrPackages
  };
};

export const getTravelTypeFromTripType = (tripType) => {
  const travelTypeObj = _find(
    TravelType,
    ({ allTripTypes }) => _indexOf(allTripTypes, tripType) >= 0,
  );
  const travelType = travelTypeObj.name;
  return travelType;
};

export const getDefaultTripTypeFromTravelType = (travelType) => {
  const allTravelTypes = [
    TravelType.OUTSTATION,
    TravelType.AIRPORT,
    TravelType.SELF_DRIVE,
    TravelType.RENTALS,
  ];
  const newTravelType = allTravelTypes.filter((item) => item.name === travelType);
  const { defaultTripType: tripType } = newTravelType[0];
  return tripType;
};

let SESSION_ID = null;
export const getSessionId = () => {
  if (Boolean(SESSION_ID)) {
    return SESSION_ID;
  } else {
    SESSION_ID = `sessionId_${new Date().getTime().toString(16)}_${Math.floor(
      (1 + Math.random()) * 0x10000,
    )
      .toString(16)
      .substring(1)}`;
  }
  return SESSION_ID;
};

export const modifyPartnerBannerData = (data) => {
  if (!data) return null;
  let finalAmenitiesStruc = [];
  let modifiedAmenities = [];
  const currentData = data?.amenities || [];
  for (let count = 0; count < data?.amenities?.length; count = count + 2) {
    modifiedAmenities.push(currentData[count]);
    if (currentData[count + 1]) {
      modifiedAmenities.push(currentData[count + 1]);
    }
    finalAmenitiesStruc.push([...modifiedAmenities]);
    modifiedAmenities = [];
  }
  data.amenities = finalAmenitiesStruc;
  return data;
};

export const getTravelTypeVisibleStatus = (corpBlockLobs) => {
  const hideTravelTypes = [];
  const blockTravelTypes = [];
  if (corpBlockLobs?.includes(cabBlockLobs.OS_BLOCK)) {
    blockTravelTypes.push('OS');
  }
  if (corpBlockLobs?.includes(cabBlockLobs.AT_BLOCK)) {
    blockTravelTypes.push('AT');
  }
  if (corpBlockLobs?.includes(cabBlockLobs.HR_BLOCK)) {
    blockTravelTypes.push('HR');
  }
  if (corpBlockLobs?.includes(cabBlockLobs.OS_HIDE)) {
    hideTravelTypes.push('OS');
  }
  if (corpBlockLobs?.includes(cabBlockLobs.AT_HIDE)) {
    hideTravelTypes.push('AT');
  }
  if (corpBlockLobs?.includes(cabBlockLobs.HR_HIDE)) {
    hideTravelTypes.push('HR');
  }
  return [hideTravelTypes, blockTravelTypes];
};

// @pokus-key-check 1690178888000
export const showReviews = () => {
  return getPokusConfig(PokusLobs.CAB, AbConfigKeyMappings.showReviews, false);
};

// @pokus-key-check 1690178888000
export const backReviewPopUpShowAB = () => {
  return getPokusConfig(PokusLobs.CAB, AbConfigKeyMappings.backReviewPopupShow, false);
};

// @pokus-key-check 1690178888000
export const showMoreOptions = () => {
  return getPokusConfig(PokusLobs.CAB, AbConfigKeyMappings.showMoreOptionsCabs, false);
};

export const getPokusFlagsForBE = () => {
  const pokus_flags = [
    {
      key: CabABKeys?.Listing_Part_Payment,
      flag: CabsABConfigHelper.showPartialPayment()
    },
    {
      key: CabABKeys?.Funnel_Revamp_Review,
      flag: CabsABConfigHelper.showReviewV2(),
    },
    {
      key: CabABKeys?.OSZeroBooking,
      flag: CabsABConfigHelper.showOSZeroBooking(),
    },
    {
      key: CabABKeys.ReviewAssistedWidget,
      flag: CabsABConfigHelper.showReviewAssistedWidget()
    },
    {
      key: CabABKeys?.UGC_Review,
      flag: CabsABConfigHelper.showUGCReviews(),
    },
    {
      key: CabABKeys?.UpsellFlow,
      flag: CabsABConfigHelper.showUpsellFlow(),
    },
    {
      key: CabABKeys?.Landing_OS_TripTypeSelect,
      flag: CabsABConfigHelper.selectLandingOSTripType()
    },
    {
      key: CabABKeys?.Airport_Landing_New,
      flag: CabsABConfigHelper.showLandingV2()
    },
    {
      key:CabABKeys?.Map_Integration,
      flag:CabsABConfigHelper.showMapIntegration(),
    },
    {
      key:CabABKeys?.Flight_Number_Cab_A2C,
      flag:CabsABConfigHelper.showRealTimeStatus(),
    }
  ];
  return pokus_flags;
};

export const seggregateContent = (
  str,
  highlighted = { fontFamily: fonts.bold },
  normal = {},
  separator = '*',
) => {
  let components = [];
  if (!str) {
    return components;
  }
  let isRupeeSymbolInString = false;
  let isInBetweenSpecialChars = false,
    sectionText = '',
    highlightedText = '',
    normalText = '';
  for (let i = 0; i < str.length; i++) {
    if (str[i] === '\u20B9' || str[i] === '₹') {
      isRupeeSymbolInString = true;
    }
    if (str[i] !== separator) {
      if (isInBetweenSpecialChars) {
        highlightedText += str[i];
      } else {
        normalText += str[i];
      }
    } else {
      if (isInBetweenSpecialChars) {
        sectionText = highlightedText;
        components.push(
          <Text style={highlighted} key={i}>
            {sectionText}
          </Text>,
        );
      } else {
        sectionText = normalText;
        components.push(
          <Text style={normal} key={i}>
            {sectionText}
          </Text>,
        );
      }
      isInBetweenSpecialChars = !isInBetweenSpecialChars;
      (highlightedText = ''), (normalText = '');
    }
  }
  if (normalText) {
    components.push(
      <Text style={normal} key={'remaining_content'}>
        {normalText}
      </Text>,
    );
  }

  if (isRupeeSymbolInString) {
    components.push(<Text style={normal}> </Text>);
  }

  return components;
};

export const openWebView = (link, icon = WHITE_BACK_ARROW, title = 'Know More') => {
  if (!link) return;
  Actions.openWebView({
    url: link,
    headerIcon: icon,
    headerText: title,
  });
};

export const clickableContentGenerator = (
  str,
  highlightedContentStyle = {},
  clickableStyle = {},
  contentStyle = {},
) => {
  try {
    const link = str.match(/http\S+/g);
    if (link) {
      const strComponents = str.split(link[0]);
      return (
        <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
          <Text>
            {seggregateContent(strComponents[0], highlightedContentStyle, contentStyle)}
            {!!strComponents[1] &&
              seggregateContent(strComponents[1], highlightedContentStyle, contentStyle)}
          </Text>
          <TouchableOpacity onPress={() => openWebView(link[0])}>
            <Text style={clickableStyle}>{link[0]}</Text>
          </TouchableOpacity>
        </View>
      );
    }
    return <Text>{seggregateContent(str, highlightedContentStyle, contentStyle)}</Text>;
  } catch (e) {
    return null;
  }
};

export const cabErrorLongToast = (message, onErrorDismiss) => {
  if (Platform.OS === 'android') {
    ToastAndroid.show(message, ToastAndroid.LONG);
    onErrorDismiss();
  } else if (Platform.OS === 'ios') {
    const errorViewTranslateY = new Animated.Value(150);
    const translateAnim = Animated.timing(errorViewTranslateY, {
      toValue: 0,
      fromValue: 150,
      duration: 300,
    });
    translateAnim.start();
    setTimeout(() => {
      const translateReverseAnim = Animated.timing(errorViewTranslateY, {
        fromValue: 0,
        toValue: 150,
        duration: 300,
      });
      translateReverseAnim.start();
      onErrorDismiss();
    }, 3000);

    return (
      <Animated.View
        style={{
          backgroundColor: colors.darkGrey,
          left: 0,
          right: 0,
          bottom: 0,
          position: 'absolute',
          padding: 16,
          transform: [
            {
              translateY: errorViewTranslateY,
            },
          ],
        }}
      >
        <Text style={{ color: colors.lightestTextColor }}>{message}</Text>
      </Animated.View>
    );
  } else {
    Toast.show(message, 3000);
    onErrorDismiss();
  }
};

export const extractPokusValueFromList = (key, list, defaultValue = null) => {
  let pokusValue = defaultValue;
  list?.forEach((pokusData) => {
    if (pokusData.key === key) {
      pokusValue = pokusData.flag;
    }
  });
  return pokusValue;
};

const getCommonHeaders = async () => {
  // If common Headers object already exists, use it
  if (commonHeadersObj) {
    return commonHeadersObj;
  }

  const { NetworkModule } = NativeModules;
  const commonHeaders = await NetworkModule.getHeaders();
  commonHeadersObj = {
    deviceId: Platform.OS === 'android' ? commonHeaders.deviceid : commonHeaders.deviceId,
    mcId: Platform.OS === 'ios' ? commonHeaders['usr-mcid'] : commonHeaders.mcid,
    appVersion: commonHeaders.ver,
  };
  return commonHeadersObj;
};

export const getPdtInfoForBE = async () => {
  const deviceDetails = await createDeviceDetails();
  const userDetails = await getUserDetails();
  const commonHeaders = await getCommonHeaders();
  const pdtDeviceInfo = {
    deviceType: deviceDetails?.deviceType,
    model: deviceDetails?.deviceName,
    pdtDeviceId: Platform.OS === 'android' ? deviceDetails.pdtDeviceId : deviceDetails.deviceId,
    osVersion: deviceDetails?.osVersion,
    os: deviceDetails?.os,
    appVersion: deviceDetails?.appVersion,
    bookMode: isRawClient() ? 'M' : Platform.OS === 'android' ? 'A' : 'I',
    networkType: deviceDetails?.networkType,
    deviceName: deviceDetails?.deviceName,
    mcId: commonHeaders?.mcId,
  };
  const pdtUserDetails = {
    mmtAuth: userDetails?.mmtAuth,
    uuid: userDetails?.uuid,
    loggedIn: userDetails?.loggedIn,
    loginType: userDetails?.loginType,
    profileType: userDetails?.profileType,
    mcId: commonHeaders?.mcId,
    email: userDetails?.email,
    mobile: userDetails?.mobile,
    mobileVerified: userDetails?.mobileVerified,
    emailVerified: userDetails?.emailVerified,
  };
  return {
    pdtDeviceInfo,
    pdtUserDetails,
  };
};

export const seggregateLinkContent = (
  str,
  handleOnPressLink,
  highlighted = { fontFamily: fonts.bold },
  normal = {},
  separator = '$',
) => {
  let components = [];
  if (!str) {
    return components;
  }
  let isRupeeSymbolInString = false;
  let isInBetweenSpecialChars = false,
    sectionText = '',
    highlightedText = '',
    normalText = '';
  for (let i = 0; i < str.length; i++) {
    if (str[i] === '\u20B9' || str[i] === '₹') {
      isRupeeSymbolInString = true;
    }
    if (str[i] !== separator) {
      if (isInBetweenSpecialChars) {
        highlightedText += str[i];
      } else {
        normalText += str[i];
      }
    } else {
      if (isInBetweenSpecialChars) {
        sectionText = highlightedText;
        components.push(
          <TouchableOpacity onPress={() => handleOnPressLink()} >
            <Text style={highlighted} key={i}>
              {sectionText}
            </Text>
          </TouchableOpacity>
        );
      } else {
        sectionText = normalText;
        components.push(
          <Text style={normal} key={i}>
            {sectionText}
          </Text>,
        );
      }
      isInBetweenSpecialChars = !isInBetweenSpecialChars;
      (highlightedText = ''), (normalText = '');
    }
  }
  if (normalText) {
    components.push(
      <Text style={normal} key={'remaining_content'}>
        {normalText}
      </Text>,
    );
  }

  if (isRupeeSymbolInString) {
    components.push(<Text style={normal}> </Text>);
  }

  return components;
};

export const getSEODeepLinkData = (seoPageResponse) => {
  const seoDeepLinkData = {
    from: seoPageResponse?.sourceCityCode,
    to: seoPageResponse?.destinationCityCode,
    screen: "Listing",
    startDate: seoPageResponse?.departDate,
    pickupTime: seoPageResponse?.pickupTime,
    source: seoPageResponse?.source,
    cmp: ''
  };
  return seoDeepLinkData;
};

export const openSEOWebView = (seoUrl) => {
  Actions.openWebView({
    url: seoUrl,
    headerText: 'MakeMyTrip Cabs',
    headerIcon: require('@mmt/legacy-assets/src/white_backarrow.webp'),
    seoPath: true,
  })
}

export const getSessionDetails = async () => {
  try {
    if (GenericModule?.getSessionInfo) {
      const deepLinkTrackingData = await GenericModule.getSessionInfo()
      return deepLinkTrackingData
    }
    return null
  } catch (err) {
    return null
  }
}
