import getPlatformElevation from 'packages/legacy-commons/Styles/getPlatformElevation';
import { Fonts, Theme } from 'apps/post-sales/src/cab/types';
import { StyleSheet } from 'react-native';

const getStyle = (fonts: Fonts, theme: Theme) => {
  const { color } = theme;
  return StyleSheet.create({
    callDriverWrap: {
      paddingHorizontal: 20,
      paddingTop: 20,
      paddingBottom: 10,
    },
    headingSection: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    heading: {
      color: color.black,
      fontSize: fonts.fontSize.font16,
      ...fonts.boldFontFamily,
    },

    subHeading: {
      color: color.black,
      fontSize: fonts.fontSize.font14,
      ...fonts.boldFontFamily,
    },
    connectViaPhoneText: {
      color: color.black,
      fontSize: fonts.fontSize.font14,
      ...fonts.regularFontFamily,
    },

    crossIconStyle: {
      width: 22,
      height: 22,
    },
    crossIconWrapper: {
      width: 30,
      height: 30,
      right: -10,
    },
    textStyle: {
      fontSize: fonts.fontSize.font14,
    },
    buttonStyle: {
      borderRadius: 10,
      paddingVertical: 13,
      ...getPlatformElevation(0),
      marginVertical: 6,
    },
    orTextWrap:{
      paddingHorizontal:40,
      borderBottomColor: color.grey37,
      borderBottomWidth:1,
    },
    orText:{
      bottom:"-50%",
      backgroundColor: color.white,
      color: color.grey,
      paddingHorizontal:10,
    }
  });
};

export const htmlstyles = (fonts: Fonts, theme: Theme) => {
  const { color } = theme;
  return StyleSheet.create({
    p: {
      color: color.black,
      fontSize: fonts.fontSize.font14,
      ...fonts.regularFontFamily,
    },
    b: {
      ...fonts.boldFontFamily,
    },
  });
};

export default getStyle;
