import React from 'react';
import { View, Text, Image, TouchableOpacity, Platform } from 'react-native';
import Actions from '../../../navigation/postSalesNavigation';
import getStyles from '../../details/components/HotelMamiCss';
import LinearGradient from 'react-native-linear-gradient';
import PropTypes from 'prop-types';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import BasePage from '../../../Common/PostSalesBasePage';
import {CHANGE_DATE_GO_BACK_TO_BOOKING, ADD_GUEST_GO_BACK_TO_BOOKING} from '../HotelModificationConstants';
import HotelBookingTrackingHelper from '../../HotelBookingTrackingHelper';
import { isIntlHotelBooking } from '../../details/utils/HotelBookingDetailUtil';
import { TICKET_FAIL_GREY_ICON } from '../../HotelImageConstants';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { renderNeedHelpOverlay } from '../../details/utils/HelpingHandHelper';
import { getErrorPageHeading } from '../../hotelUtils';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

class HotelModificationFailure extends BasePage {
    
    constructor(props) {
      super(props, 'hotelModificationFailure');
      this.fonts = getFont(true);
      this.styles = getStyles(this.fonts);
      this.fsStyle = this.fonts.fsStyle;
      this.state = {
        helpingHandOverlay: false
      };
      if (props.action === 'DATECHANGE') {
        this.trackingPageName = isIntlHotelBooking(this.props.response && this.props.response?.lobCode)
          ? 'Mob:customer support:Hotel International:DateChange:ResultsPage_Available:Error'
          : 'Mob:customer support:Hotel Domestic:DateChange:ResultsPage_Available:Error';
      } else if (props.action === 'ADDGUEST') {
        this.trackingPageName = isIntlHotelBooking(this.props.response && this.props.response?.lobCode)
          ? 'Mob:customer support:Hotel International:AddGuest:ResultsPage_Available:Error'
          : 'Mob:customer support:Hotel Domestic:AddGuest:ResultsPage_Available:Error';
      }
    }

    componentDidMount() {
      const { errorCode = '' } = this.props.errorPageData || {};
      HotelBookingTrackingHelper.trackErrorWithData(this.trackingPageName, this.props.bookingid, errorCode, this.props.response)
    }

    toggleHelpingHandOverlay = (value) => {
      this.setState({
        helpingHandOverlay: value
      });
    }

    setHelpingHandOverlay = () => {
      this.toggleHelpingHandOverlay(true);
    }
  
    static navigationOptions = {
      header: null
    };
    async resettoDetail(bookingId) {
      const {mmtAuth, loggingTrackingInfo} = await HotelBookingModule.getRequestHeader(this.props.bookingId == null ? " " : this.props.bookingId, 'Hotel_Modification_ViewRoom');
      const BOOKING_ID = bookingId;
      const MMT_AUTH = mmtAuth;
      const LOGGING_TRACKINFO = loggingTrackingInfo;

      try {
        let hotelDetailResponse = Platform.OS === "web" ? this.props.hotelDetailResponse : await HotelBookingModule.getHotelBookingDetailsResponse();
        this.trackLoadEvent(hotelDetailResponse);
      } catch (error) {
        console.error('error: ', error);
      }
      Actions.hotelBookingDetail({ BOOKING_ID, MMT_AUTH, LOGGING_TRACKINFO });
    }

    trackLoadEvent = (hotelDetailResponse) =>{
      const action = this.props.action;
      if (action === 'DATECHANGE') {
        HotelBookingTrackingHelper.trackClickEvent(this.trackingPageName, CHANGE_DATE_GO_BACK_TO_BOOKING, hotelDetailResponse);
      } else if (action === 'ADDGUEST') {
        HotelBookingTrackingHelper.trackClickEvent(this.trackingPageName, ADD_GUEST_GO_BACK_TO_BOOKING, hotelDetailResponse);
      }
    }

    onHardBackPress = () => {
      this.resettoDetail(this.props.bookingid == null ? " " : this.props.bookingid);
    };

    render() {
      const failedIcon = TICKET_FAIL_GREY_ICON;
      const action = this.props.action;
      const buttonGradientColor = this.props.buttonGradientColor;
      const isCorp = this.props.isCorporateBooking;
      return (
        <View style={this.styles.ErrorStateWrapper}>
          <Image
            style={[
                    this.styles.dateChangeIcon,
                    this.styles.mar50Bt,
                    this.styles.CenterText]}
            source={failedIcon}
          />
          <Text style={[this.styles.ErrorPageText, this.styles.boldFont, this.styles.transperantBgrnd]}>Something Went Wrong</Text>
          <View style={[action === 'DATECHANGE' ? this.styles.showView : this.styles.hideView]}>
            <Text style={[this.styles.AlignCenter, this.styles.lineHt22, this.styles.mar50Bt, this.styles.transperantBgrnd]}>{!!this.props.errorMsg ? this.props.errorMsg : 'We were not able to update dates.'}
            </Text>
          </View>
          <View style={[action === 'ADDGUEST' ? this.styles.showView : this.styles.hideView]}>
            <Text style={[this.styles.AlignCenter, this.styles.lineHt22, this.styles.mar50Bt, this.styles.transperantBgrnd]}>{!!this.props.errorMsg ? this.props.errorMsg : 'We were not able to modify guests.'}
            </Text>
          </View>
          {this.props.showBackToBookingCTA &&
            <TouchableOpacity onPress={() => {
                this.resettoDetail(this.props.bookingid == null ? " " : this.props.bookingid, action);
                  }}
            >
              <LinearGradient
                start={{x: 1.0, y: 0.0}}
                end={{x: 0.0, y: 1.0}}
                colors={buttonGradientColor}
                style={[this.styles.CommonFillBtn]}
              >
                <Text style={[this.styles.WhiteText, this.styles.CenterText, this.styles.blackFont, this.styles.transperantBgrnd]}>
                              GO BACK TO BOOKING
                </Text>
              </LinearGradient>
            </TouchableOpacity>
          }
          {this.props.showHelpingHand && this.props.helpingHandData && <TouchableOpacity style={[this.styles.connectUsView]} onPress={this.setHelpingHandOverlay}>
          <Text style={[this.fsStyle.font16, AtomicCss.lineHeight20, isCorp ? AtomicCss.lightBlueMyBizText : AtomicCss.blueText, this.fonts.blackFontFamily]}>{this.props.helpingHandData.connectWithUsText}</Text>
        </TouchableOpacity>}
        {this.props.showHelpingHand && this.state.helpingHandOverlay === true && this.props.helpingHandData && 
          renderNeedHelpOverlay(this.props.helpingHandData, this.toggleHelpingHandOverlay)
        }
        </View>


      );
    }
}


HotelModificationFailure.propTypes =
    {
      bookingid: PropTypes.string.isRequired,
      action: PropTypes.string.isRequired,
      buttonGradientColor: PropTypes.array
    };

HotelModificationFailure.defaultProps = {
  buttonGradientColor: ['#53B2FE', '#065AF3']
};


export default HotelModificationFailure;
