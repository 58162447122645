import React from 'react';
import PropTypes from 'prop-types';
import { statusBarHeightForIphone } from '@mmt/legacy-commons/Styles/globalStyles';
import StickyHeader from '../components/StickyHeader/StickyHeader';
import {
  Animated,
  BackHandler,
  Easing,
  Dimensions,
  Platform,
  ScrollView,
  StatusBar,
  Text,
  TouchableHighlight,
  View,
  DeviceEventEmitter
} from 'react-native';
import TotalPrice from '../components/PaymentModeCard';
import Traveller from '../components/Traveller';
import RailBookingModule from '@mmt/legacy-commons/Native/RailBookingModule';
import RailCallHelp from '../components/CallHelp/RailCallHelp';
import CallHelpUs from '../components/CallHelpUs';
import TopStatusCard from '../components/topStatusCard';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import ProgressView from '../../Common/ProgressView/ProgressView';
import NoInternetView from '@mmt/legacy-commons/Common/Components/Error/NoInternetView';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import ViewControllerModule from '@mmt/legacy-commons/Native/ViewControllerModule';
import { isNetworkAvailable } from '@mmt/legacy-commons/Common/utils/AppUtils';
import SimpleHeader from '@mmt/legacy-commons/Common/Components/Header/SimpleHeader';
import {getCommonHeaders, RAIL_BOOKING_DETAILS_URL, RAIL_PAYMENT, RAIL_TDR_URL} from '../../utils/NetworkUtils';
import {checkForAndroidPaymentSuccess, checkForIosPaymentSuccess, getCancellationDetailInfo, getPaymentRequest , getseatinfoText, isTdrFiled} from './RailBookingDetailsUtil';
import RailBookingTrackingHelper from './RailBookingTrackEventHelper';
import { colors } from '../../bus/components/TopStatusCardColor';
import AtomicCss from '../commonStyles/AtomicCss';
import {isLostBookingData, isMyraEnabled, isTGSUpgradeEnabled, setWebViewAuth} from '../../utils/PostSaleUtil';
import RailUnConfirmedTopStatusCard from '../components/UnconfirmedBookings/RailUnConfirmedTopStatusCard';
import RailUnConfirmedInformationCard from '../components/UnconfirmedBookings/RailUnConfirmedInformationCard';
import RefundTracker from '../../Common/RefundTracker';
import RailOperationOverlayAlert from '../../bus/components/OverlayAlert/RailOperationOverlayAlert';
import {AUTH_FAILED_MESSAGE, CANCELLATION_REQUEST_TYPE, CHAT_WITH_MYRA_CARD, CLAIM_REQUEST_TYPE, COMMON_CARD, EMPTY_JSON_STRING, LOBNAMES, PAYMENT_REPONSE} from '../../PostSalesConstant';
import RailBookingDetailConstant, { LOADING, RAIL_OVERLAY, RAIL_TGS, RAIL_TRACKING_CONSTANT } from './RailBookingDetailConstant';
import AlertInfo from '../../Common/CommonImpInfo';
import RailCancellationPolicyCard from '../components/CancellationCard';
import ChatMyra from '../components/ChatMyra/ChatMyra';
import BottomOverlay from '../../Common/OverlayMessage/BottomOverLay';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { RAIL_DETAILS, RAIL_TDR_REQUEST, RAIL_TDR_RULES_LAYOUT } from './RailPostSalesReducer';
import MMTBlackCard from '../../Common/MMTBlackCard';
import RailQrCodeOverlay from '../components/QrCode/RailQrCodeOverlay';
import { getAdTechCard, isEasyPayNativeFlowEnabled } from '../../Common/commonUtil';
import { POST_SALES_RAIL_DETAILS_PAGE } from '@mmt/legacy-commons/adsConfig/adsConfigConstants';
import AdvisoryNotice from '../../Common/AdvisoryNotice';
import AdvisoryOverlay from '../../Common/AdvisoryNotice/AdvisoryOverLay';
import { REFUND_OOT_TRACKING } from '../../Common/RefundOutOfTATForm/constants';
import LostIDBookingDetailPage from '../../Common/LostIdDetailPage';
import BasePage from '../../Common/PostSalesBasePage';
import { getStaticData } from '../../staticData/staticData';
import CustomerSupportCard from '../../Common/CustomerSupportCard';
import { scrollHelper } from '../../Common/CustomerSupportCard/CustomerSupportCardHelper';
import CustomerSupportFloatingCard from '../../Common/CustomerSupportCard/CustomerSupportFloatingCard';
import { RuleTraceConst } from '../../Common/CustomerSupportCard/CustomerSupportCardConst';
import { getCustomerSupportCardData } from '../../Common/CustomerSupportCard/api';
import RefundVoucher from '../components/TGVoucher/RefundVoucher';
import MSRMainCard from '../../Common/MSR/MainCard';
import { getSegmentInfo } from '../details/data/helper';
import RailSchedule from '../components/RailSchedule';
import NewCommonOverlay from '../../Common/NewCommonOverlay';
import FreeCancellationOverlay from '../components/FreeCancellationBtmSheet';
import CancellationPolicyOverlay from '../components/CancellationPolicyBtmSheet';
import TripGuaranteeCard from './components/TripGuaranteeCard';
import TGSOverlay from './components/Overlay/TGSOverlay';
import Actions from '../../navigation/postSalesNavigation';
import { BACK_ARROW, TG_ICON } from '../Utils/RailImageConstant';

export const RAIL_BOOKING_DETAILS_PAGE = 'railBookingDetail';
import { ThemeContext } from '../../theme/theme.context';
import getStyle from './RailBookingDetailsCss';
import ContactDetailsCard from '../components/ContactDetailsCard';
import RailMainCard from '../components/RailCard';
import LiveTrakingInfoCard from '../components/LiveTrakingInfoCard';
import { BOOKING_TYPE, COMPONENT_ID, PAGE_NAME } from './constants';
import WhiteCard from '../../Common/WhiteCard';
import PaymentPendingCard from "../components/PaymentPendingCard"
import PaymentPendingOverlay from "../components/PaymentPendingOverlay"
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import RailTGSCard from './components/RailTGSCard';
import { getDataFromStorage } from '../../utils/localstorage';
import PostSalesModule from '@mmt/legacy-commons/Native/PostSalesModule';
import HotelCrossSell from '../../Common/CrossSell/Hotel';
import { getPokusConfig } from '@mmt/legacy-commons/Native/AbConfig/AbConfigModule';
import abConfigKeyMappings, { PokusLobs } from '@mmt/legacy-commons/Native/AbConfig/AbConfigKeyMappings';
import AuthErrorView from '../../Common/AuthError/AuthErrorView';
import TravelCashCard from '../../Common/TravelCard';

class RailBookingDetails extends BasePage {
    static navigationOptions = {
      header: null
    }
    static contextType = ThemeContext;

    constructor(props) {
      super(props, RAIL_BOOKING_DETAILS_PAGE);
      if (props.data != null && props.data.BOOKING_ID != null) {
        this.bookingId = props.data.BOOKING_ID;
      } else {
        this.bookingId = props.BOOKING_ID || props.bookingId;
      }
      this.pageData = props.pageData ? JSON.parse(props.pageData) : undefined;
      this.isLostBooking = isLostBookingData(this.pageData);
      this.mmtAuth = '';
      this.loggingTrackInfo = {};
      this.trackPageName = '';
      this.topStatusCardColor = [colors.blueStartColor, colors.blueEndColor];
      // width of the card decreases on scroll. Initial value is 146
      this.customerSupportCardWidth = new Animated.Value(146);
      // width of the text decreases on scroll. Initial value is 1
      this.customerSupportTextWidth = new Animated.Value(1);
      this.state = {
        responseJson: null,
        viewState: this.isLostBooking ? ViewState.SHOW_DETAIL : ViewState.LOADING,
        showOverlay: false,
        tdrRules: {},
        errorText: '',
        tdrApiError: false,
        closeOverLay: true,
        bottomOverlay: '',
        overlayPosition: new Animated.Value(0),
        errorMessage: '',
        overlay: false,
        advisoryOverlay : false,
        showTgUpdatedText: false
      };
      this.retFn = '';
      this.NAGATIVE_OVERLAY_BUTTON = '';
      this.POSITIVE_OVERLAY_BUTTON = '';
      this.TITLE_TEXT = '';
      this.SUB_TITLE_TEXT = '';
      this.SUB_TITLE_HIGHLIGHTED_TEXT = '';
      this.fetchTGSHoldData = this.fetchTGSHoldData.bind(this);
      this.toggleAdvisoryOverlay = this.toggleAdvisoryOverlay.bind(this);
      this.toggleOverlay = this.toggleOverlay.bind(this);
      this.closeQROverLay = this.closeQROverLay.bind(this);
      this.handlePendingPayClick = this.handlePendingPayClick.bind(this);
    };

  toggleAdvisoryOverlay() {
    this.setState({ advisoryOverlay: !this.state.advisoryOverlay });
  }

  getWishingToTravelWithRAC =  async () => {
    const travellingWithRACKey = `travellingTravelWithRAC${this.bookingId}`;
    const travellingWithRAC = await getDataFromStorage(
      travellingWithRACKey,
    );
    return travellingWithRAC;
  };
  getMainPageViews() {
    const views = [];
    const cards = this.state.responseJson.layoutDetail.cardList;
    cards.forEach((card) => {
      this.addCard(card, views);
    });
    return views;
  }

  showOverlay = (
    fn,
    negBtnMsg,
    posBtnMsg,
    titleText,
    subTitleHighLightedText,
    subTitleText,
    closeOverlay,
  ) => {
    if (!closeOverlay) {
      this.setState({ showOverlay: true });
    }
    this.POSITIVE_OVERLAY_BUTTON = posBtnMsg;
    this.NAGATIVE_OVERLAY_BUTTON = negBtnMsg;
    this.TITLE_TEXT = titleText;
    this.SUB_TITLE_HIGHLIGHTED_TEXT = subTitleHighLightedText;
    this.SUB_TITLE_TEXT = subTitleText;
    this.retFn = fn;
  };

  showOverlayNegative = () => {
    this.setState({ showOverlay: false });
  };

  showOverlayPositive = () => {
    if (this.retFn && typeof this.retFn === 'function') {
      this.retFn();
    }
    this.setState({ showOverlay: false });
  };
  closeOverLay = () => {
    this.setState({ closeOverLay: false });
  };
    render() {
      if (this.state.viewState === ViewState.SHOW_DETAIL) {
        this.props.railDetailsDispatcher(this.state.responseJson);
      }
      if (!isEmpty(this.state.tdrRules)) {
        this.props.railTdrLayoutDispatcher(this.state.tdrRules);
      }
      const { psTheme } = this.context;
      const { color } = psTheme;
      const styles = getStyle(color);
      return (
        <View style={this.isLostBooking ? styles.lostBookingContainer : styles.mainView}>
          {this.state.viewState === ViewState.LOADING && this.renderProgressView()}
          {this.state.viewState === ViewState.LOADING_TGS && this.renderProgressView(LOADING.TGS_HOLD)}
          {this.state.viewState === ViewState.NO_INTERNET && this.renderNoNetworkView()}
          {this.state.viewState === ViewState.ERROR && this.renderErrorView()}
          {this.state.viewState === ViewState.AUTH_ERROR && this.renderAuthErrorView()}
          {this.state.viewState === ViewState.SHOW_DETAIL && this.state.overlay && this.renderQRCode()}
          {this.state.viewState === ViewState.SHOW_DETAIL && this.state.advisoryOverlay && this.renderAdvisoryOverlay()}
          {this.state.viewState === ViewState.SHOW_DETAIL && this.renderPage()}
          {this.state.customerSupportCardData?.ruleTrace === RuleTraceConst.FLOATING && (
            <CustomerSupportFloatingCard
              cardWidth={this.customerSupportCardWidth}
              dynamicTextWidth={this.customerSupportTextWidth}
              cardData={this.state.responseJson?.floatingNeedHelpCard}
              bookingId={this.bookingId}
              pageName='Mob:customer support:Rail:TripDetails'
              uuid={this.state.responseJson?.uuid}
              isInternational={this.state.responseJson?.isInternational}
              productCode={LOBNAMES.RAIL}
            />
          )}
        </View>
      );
    }

  setCustomerSupportCardData = async () => {
    const data = await getCustomerSupportCardData(
      this.bookingId,
      this.pageData?.uuid,
      'railBookingDetails',
    );
    !!data &&
      this.setState({
        customerSupportCardData: data,
      });
  };

  componentDidMount() {
    super.componentDidMount()
    if (!this.isLostBooking) {
      this.fetchJSONAsync(RAIL_BOOKING_DETAILS_URL + this.bookingId);
    }
    this.setCustomerSupportCardData();
    this.checkAndSetTravelWithRACSelected();
    DeviceEventEmitter.addListener(RailBookingDetailConstant.RAIL_TGS_THANKYOU_EVENT, this.openThankYouPage);
    BackHandler.addEventListener('hardwareBackPress', this.closeQROverLay);
  }

  componentWillUnmount(){
    DeviceEventEmitter.removeAllListeners(RailBookingDetailConstant.RAIL_TGS_THANKYOU_EVENT,this.openThankYouPage);
    BackHandler.removeEventListener('hardwareBackPress', this.closeQROverLay);
  }

  componentWillReceiveProps(props) {
    if (props && props.data && props.data.refresh === true && !this.isLostBooking) {
      this.setState({ viewState: ViewState.LOADING });
      this.fetchJSONAsync(RAIL_BOOKING_DETAILS_URL + this.bookingId);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data && this.props.data.refresh === true) {
      if (this.isLostBooking) {
        this.setState({
          refreshLostDetail: {},
        });
        return;
      }
    }
  }

  getFreeCancellationOverlay() {
    const { responseJson = {} } = this.state;
    const { psTheme } = this.context;
    const { color } = psTheme;
    const styles = getStyle(color);
    return (
      <NewCommonOverlay handleBottomOverlay={this.handleCloseBottomOverlay} roundBorder={true}>
        <Animated.View style={{ bottom: this.state.overlayPosition }}>
          <View style={styles.OverlayContentWrapper}>
            <FreeCancellationOverlay
              handleBottomOverlay={this.handleCloseBottomOverlay}
              response={responseJson}
            />
          </View>
        </Animated.View>
      </NewCommonOverlay>
    );
  }

  getCancellationPolicyOverlay() {
    const { responseJson = {} } = this.state;
    const { psTheme } = this.context;
    const { color } = psTheme;
    const styles = getStyle(color);
    return (
      <NewCommonOverlay
        handleBottomOverlay={() => {
          this.handleCloseBottomOverlay();
        }}
        roundBorder={true}
      >
        <Animated.View style={{ bottom: this.state.overlayPosition }}>
          <View style={styles.OverlayContentWrapper}>
            <CancellationPolicyOverlay
              handleBottomOverlay={this.handleCloseBottomOverlay}
              response={responseJson}
              disableCard={this.state.responseJson.irtctcDown}
              key="CancellationPolicy"
              mmtAuth={this.mmtAuth}
              loggingTrackInfo={this.loggingTrackInfo}
              tripStatus={this.state.responseJson.bookingStatus}
              tdrRules={this.state.tdrRules}
              tdrApiError={this.state.tdrApiError}
              handleViewTdrClick={this.showOverlay}
            />
          </View>
        </Animated.View>
      </NewCommonOverlay>
    );
  }

  getPendingPaymentOverlay = () => {
    const { responseJson = {} } = this.state;
    const { psTheme } = this.context;
    const { color } = psTheme;
    const styles = getStyle(color);
    return (
      <NewCommonOverlay
        handleBottomOverlay={() => {
          this.handleCloseBottomOverlay();
        }}
        roundBorder={true}
      >
        <Animated.View style={{ bottom: this.state.overlayPosition }}>
          <View style={styles.OverlayContentWrapper}>
            <PaymentPendingOverlay
              handleBottomOverlay={this.handleCloseBottomOverlay}
              response={responseJson}
              pageName={PAGE_NAME.TRIP_DETAILS}
              key={COMPONENT_ID.PAYMENT_PENDING}
              handlePendingPayClick={this.handlePendingPayClick}
            />
          </View>
        </Animated.View>
      </NewCommonOverlay>
    );
  }

  isSomePsgrFiledTdr = () => {
    const passengerList = this.state.responseJson.train[0].pnrDetails[0].segmentInfo[0].passenger;
    let filedTDR = 0;
    passengerList.forEach((passenger) => {
      if (passenger.currentStatus === 'TDR') {
        filedTDR += 1;
      }
    });

    if (filedTDR > 0) {
      return true;
    }
    return false;
  };

  areAllPsgrFiledTdrOrCan = () => {
    const passengerList = this.state.responseJson.train[0].pnrDetails[0].segmentInfo[0].passenger;
    let countCancellOrTdr = 0;
    passengerList.forEach((passenger) => {
      if (passenger.currentStatus === 'TDR' || passenger.currentStatus === 'CAN') {
        countCancellOrTdr += 1;
      }
    });

    if (countCancellOrTdr === passengerList.length) {
      return true;
    }
    return false;
  };
    handleOpenBottomOverlay = (overlay) => {
      this.setState({
        bottomOverlay: overlay
      });

      Animated.timing(this.state.overlayPosition, {
        toValue: 0,
        easing: Easing.easeInOut,
        duration: 200,
        delay: 100,
      }).start();
    }

    handleCloseBottomOverlay = () => {
      this.setState({
        bottomOverlay: ''
      })
    }

    closeOverlay = () => {
      this.setState({
        bottomOverlay: ''
      })
    }
    getTGSKnowMoreOverlay () {
      const {responseJson={}} = this.state;
      const {tgsUpgradeCardData={}} = responseJson || {};
      const { psTheme } = this.context;
      const { color } = psTheme;
      const styles = getStyle(color);
      return (
        !isEmpty(tgsUpgradeCardData) ? <NewCommonOverlay
          handleBottomOverlay={this.handleCloseBottomOverlay}
          styleContent={{maxHeight:"90%"}}
          roundBorder={true}
        >
          <Animated.View style={[styles.tgsOverlayAnimation, { bottom: this.state.overlayPosition }]}>
            <TGSOverlay
              tgsUpgradeCardData={tgsUpgradeCardData}
              proceedHandler={this.fetchTGSHoldData}
              handleBottomOverlay={this.closeOverlay}
              data={{
                bookingId:this.bookingId,
                authId: this.mmtAuth
              }}
              response={this.state.responseJson}
            />
          </Animated.View>
        </NewCommonOverlay>:null
      )
    }

    checkAndSetTravelWithRACSelected = async () => {
      const showAlternativeDesc = await this.getWishingToTravelWithRAC();
      if(showAlternativeDesc){
        this.setState({
          showTGUpdatedText: true
        })
      }
    }
    renderPage() {
      let views = [];
      const {responseJson={}, closeOverLay, bottomOverlay} = this.state;
      const {irtctcDown, irctcDownMessage, train, bookingState}  = responseJson || {};
      const { state } = bookingState || {};
      const { trainScheduleData } = !isEmpty(train) && train[0] || {};
      if (this.isLostBooking) {
        return (
          <LostIDBookingDetailPage
            bookingId={this.bookingId}
            pageData={this.pageData}
            lobName={LOBNAMES.RAIL}
            refreshLostDetail={this.state.refreshLostDetail}
            getRefundCard={({ response }) => this.renderRefundCard(response, null, true)}
          />
        );
      }
      const seatinfoDetails = getseatinfoText(this.state.responseJson);
      views = this.getMainPageViews();
      RailBookingTrackingHelper.trackLoadEvent('TripDetails', this.state.responseJson);
      const { psTheme } = this.context;
      const { color } = psTheme;
      const styles = getStyle(color);
      const barStyleColor = color.nero;
      const showLiveTrackingFooter = !isEmpty(trainScheduleData) && (state == BOOKING_TYPE.UPCOMING || state == BOOKING_TYPE.ONTRIP);
      return (
        <React.Fragment>
          <StatusBar backgroundColor={barStyleColor} barStyle="light-content" />
          <ScrollView
          stickyHeaderIndices={[0]}
          bounces={false}
          style={[
            Platform.OS === 'web' ? [AtomicCss.greyBg, { top: -70 }] : [AtomicCss.greyBg],
            showLiveTrackingFooter ? {marginBottom: 50} : {}
          ]}
          onScroll={() =>
            scrollHelper(this.customerSupportCardWidth, this.customerSupportTextWidth)
          }
        >
          <View style={[Platform.OS === 'web' ? { elevation: 2, zIndex: 5, top: 70 } : {}]}>
            <View style={{ zIndex: 6 }}>
              <StickyHeader
                key="StickyHeader"
                response={this.state.responseJson}
                seatinfoDetails={seatinfoDetails}
                onBackPress={this.goBack}
              />
            </View>
          </View>
            {views}
          </ScrollView>
          {showLiveTrackingFooter && (
            <View style={styles.footer}>
              <LiveTrakingInfoCard response={this.state.responseJson} />
            </View>
          )}
          {this.state.showOverlay &&
              <RailOperationOverlayAlert
                positiveBtnText={this.POSITIVE_OVERLAY_BUTTON}
                negativeBtnText={this.NAGATIVE_OVERLAY_BUTTON}
                positiveBtnOnTap={this.showOverlayPositive}
                negativeBtnOnTap={this.showOverlayNegative}
                titleText={this.TITLE_TEXT}
                subTitleText={this.SUB_TITLE_TEXT}
                subTitleHighLightedText={this.SUB_TITLE_HIGHLIGHTED_TEXT}
              />}
          {closeOverLay && irtctcDown &&
            <React.Fragment>
              <TouchableHighlight style={AtomicCss.overlayBg}><Text>.</Text></TouchableHighlight>
              <BottomOverlay
                key="RailBottomOverlay"
                pageName="RailBottomOverlay"
                closeOverLay={this.closeOverLay}
                irctcDownMessage={irctcDownMessage}
              />
            </React.Fragment>
          }

          {bottomOverlay === RAIL_OVERLAY.FREE_CANCELLATION &&
          this.getFreeCancellationOverlay()}
         {bottomOverlay === RAIL_OVERLAY.CANCELLATION_POLICY &&
          this.getCancellationPolicyOverlay()}
          {bottomOverlay === RAIL_OVERLAY.TGS && this.getTGSKnowMoreOverlay()}
          {bottomOverlay === RAIL_OVERLAY.PENDING_PAYMENT && this.getPendingPaymentOverlay()}
        </React.Fragment>
      );
    }
  getTGSShowRefundCard = (response) => {
    const {cancelDetailNoList} = getCancellationDetailInfo(response);
    return cancelDetailNoList.length > 0;
  } 
  renderRefundCard = (response, card, lostBooking = false) => {
    const segmentInfo = getSegmentInfo(response) || {};
    const refundCommList = segmentInfo.refundComList;
    const cancelDetailList = segmentInfo.cancellationDetailList || response.cancellationDetailList;
    const cancelDetailNoList = [];
    const { psTheme } = this.context;
    const { color } = psTheme;
    const styles = getStyle(color);
    if (refundCommList || cancelDetailList) {
      if (!isEmpty(cancelDetailList)) {
        cancelDetailList.forEach((cancellationDetail) => {
          if (
            cancellationDetail.requestType === 'Cancellation' ||
            cancellationDetail.requestType === 'Claim'
          ) {
            cancelDetailNoList.push(cancellationDetail.requestNo);
          }
        });
      }
    }
    return (
      <RefundTracker
        key="RefundTracker"
        cancelRequests={cancelDetailNoList}
        bookingId={this.bookingId}
        card={card}
        bookingResponse={response}
        lobName={LOBNAMES.RAIL}
        isLostBookingId={lostBooking}
        pageName={REFUND_OOT_TRACKING.PAGES.RAIL}
        isRoundedCornerAndPadding={true}
        cardStyle={styles.refundTrackerCardStyle}
      />
    );
  };

  addCard(card, views, isSubCard = false) {
    const bookingStatus = this.state.responseJson.bookingStatus;
    const { train, tgVoucherData } = this.state.responseJson;
    const { trainScheduleData } = !isEmpty(train) && train[0];
    const { isVisible } = card;
    if (!isVisible) {
      return null;
    }

    switch (card.cardId) {
      case RailBookingDetailConstant.TOP_STATUS_CARD_BOOKING_TO_BOARDING_TIME_BOOKING:
      case RailBookingDetailConstant.TOP_STATUS_CARD_DEFAULT_CONFIRMED:
      case RailBookingDetailConstant.TOP_STATUS_CARD_ON_TRIP:
      case RailBookingDetailConstant.TOP_STATUS_CARD_CAN_INIT_TO_REFUND_DONE:
      case RailBookingDetailConstant.TOP_STATUS_CARD_DEFAULT_CANCELLED:
      case RailBookingDetailConstant.TOP_STATUS_CARD_AFTER_REFUND_DONE:
      case RailBookingDetailConstant.TOP_STATUS_CARD_DEFAULT_COMPLETED:
      case RailBookingDetailConstant.TOP_STATUS_CARD_PARTIALY_CAN_AT_REST_ALL_COMPLETED:
      case RailBookingDetailConstant.TOP_STATUS_CARD_PARTIALY_CAN_AT_LEAST_1_UPCOMING:
      case RailBookingDetailConstant.TOP_STATUS_CARD_LOST_FAILED_BOOKING:
      case RailBookingDetailConstant.TOP_STATUS_CARD_LOST_FAILED_BOOKING_CANCELLED:
      case RailBookingDetailConstant.TOP_STATUS_CARD_12_HRS_AFTER_BOOKING:
      case RailBookingDetailConstant.TOP_STATUS_CARD_24_HRS_TO_ON_TRIP:
      case RailBookingDetailConstant.TOP_STATUS_CARD_ON_TRIP_PART_CANCEL:
      case RailBookingDetailConstant.TOP_STATUS_CARD_UNCONFIRMED:
        views.push(
          <TopStatusCard
            key="TopStatusCard"
            response={this.state.responseJson}
            onBackPress={this.goBack}
            pageName="TripDetails"
            card={card}
            tdrRules={this.state.tdrRules}
            addCard={this.addCard.bind(this)}
            onClickEventTracker={this.onClickEventTracker}
          />,
        );
        break;
      case RailBookingDetailConstant.RAIL_UNCONFIRMED_INFORMATION_CARD:
        views.push(
          <RailUnConfirmedInformationCard
            key="RailUnConfirmedInformationCard"
            response={this.state.responseJson}
            card={card}
          />,
        );
        break;
      case RailBookingDetailConstant.RAIL_ITINERARY_CARD:
        views.push(
          <RailMainCard
            key="RailMainCard"
            response={this.state.responseJson}
            pageName="TripDetails"
            handleBottomSheetOverlay={this.handleOpenBottomOverlay}
            card={card}
            isSubCard={isSubCard}
          />,
        );
        break;
      case RailBookingDetailConstant.PAYMENT_PENDING_CARD:
        views.push(
          <PaymentPendingCard
            key="PaymentPendingCard"
            response={this.state.responseJson}
            pageName="TripDetails"
            handleOpenBottomOverlay={this.handleOpenBottomOverlay}
            card={card}
            isSubCard={isSubCard}
            handlePendingPayClick={this.handlePendingPayClick}
          />,
        );
      break;
      case RailBookingDetailConstant.RAIL_TRAVELLER_CARD:
        views.push(
          <Traveller
            key="TravellerDetails"
            response={this.state.responseJson}
            mmtAuth={this.mmtAuth}
            loggingTrackInfo={this.loggingTrackInfo}
            card={card}
            onClickEventTracker={this.onClickEventTracker}
          />,
        );
        break;
      case RailBookingDetailConstant.RAIL_HOTEL_CROSS_SELL:
        if (getPokusConfig(PokusLobs, abConfigKeyMappings.HotelCrossSell, false)) {
          views.push(
            <HotelCrossSell
              key="HotelCrossSell"
              bookingId={this.bookingId}
              omnitureTrackHandler={this.onClickEventTracker}
            />,
          );
        }
        break;
        case RailBookingDetailConstant.RAIL_TGS_Card:
          let {heading, subHeading, description, altervativeDesc, altervativeSubDesc, bannerMessage } = card;
          const {showTGRefundTracker = false} = this.state.responseJson;
          const showAlternativeDesc = this.state.showTGUpdatedText;
          const showRefundCard = !showAlternativeDesc && showTGRefundTracker && this.getTGSShowRefundCard(this.state.responseJson);
          if( showAlternativeDesc){
            subHeading = altervativeDesc;
            description = altervativeSubDesc;
          }
          views.push(
            <RailTGSCard
              key="RailTgsDetails"
              bookingId={this.bookingId}
              cardData={{
                title: heading,
                desc: subHeading,
                cardInfoText: bannerMessage,
                subdesc: description,
                showRefundCard,
                altervativeDesc,
                altervativeSubDesc
              }}
              omnitureTrackHandler={this.onTGCardClickEventTracker}
              travellingWithRAC={showAlternativeDesc}
              card={card}
              response={this.state.responseJson}
              travelWithRACClickHandler={this.checkAndSetTravelWithRACSelected}
            />
          );
          break;
      case RailBookingDetailConstant.RAIL_SCHEDULE_CARD:
        if (!isEmpty(trainScheduleData)) {
          views.push(
            <RailSchedule
              key="RailScheduleCard"
              response={this.state.responseJson}
              card={card}
              trainScheduleData={trainScheduleData}
            />,
          );
        }
        break;
      case RailBookingDetailConstant.RAIL_PRICE_CARD:
        views.push(<TotalPrice key="TotalPrice" response={this.state.responseJson} card={card} />);
        break;
      case COMMON_CARD.TRAVEL_CARD:
        if (!isEmpty(this.state.responseJson.travelCardResponse)) {
          views.push(
            <TravelCashCard
              key="TravelCashCard"
              travelCardResponse={this.state.responseJson.travelCardResponse}
              uuid={this.state.responseJson.uuid}
              bookingID={this.state.responseJson.bookingID}
            />,
          );
        }
        break;
      case RailBookingDetailConstant.RAIL_REFUND_VOUCHER_CARD:
        if (tgVoucherData) {
          views.push(
            <RefundVoucher
              key="refundVoucher"
              pageName="refundVoucher"
              card={card}
              response={this.state.responseJson}
              onClickEventTracker={this.onClickEventTracker}
            />,
          );
        }
        break;

      case RailBookingDetailConstant.CONTACT_DETAILS_CARD:
        views.push(<ContactDetailsCard card={card} response={this.state.responseJson} />);
        break;

      case RailBookingDetailConstant.RAIL_REFUND_TRACKER_CARD:
        views.push(this.renderRefundCard(this.state.responseJson, card));
        break;
      case RailBookingDetailConstant.RAIL_CHANGE_IN_PLANS_CARD:
        views.push(
          <RailCancellationPolicyCard
            disableCard={this.state.responseJson.irtctcDown}
            key="CancellationPolicy"
            response={this.state.responseJson}
            mmtAuth={this.mmtAuth}
            loggingTrackInfo={this.loggingTrackInfo}
            tripStatus={bookingStatus}
            tdrRules={this.state.tdrRules}
            tdrApiError={this.state.tdrApiError}
            cancellationButton
            card={card}
            handleViewTdrClick={this.showOverlay}
            handleBottomSheetOverlay={this.handleOpenBottomOverlay}
            onClickEventTracker={this.onClickEventTracker}
          />,
        );
        break;
        case RailBookingDetailConstant.RAIL_TGS_UPGRADE_CARD:
          const {responseJson={}} = this.state;
          const {tgsUpgradeCardData = {}} = responseJson || {}
          isTGSUpgradeEnabled() && !isEmpty(tgsUpgradeCardData) && views.push(<TripGuaranteeCard
            key={card.cardId}
            knowMoreClickHandler={() => {
              this.handleOpenBottomOverlay(RAIL_OVERLAY.TGS)
            }}
            proceedClickHandler={ this.fetchTGSHoldData }
            tgsUpgradeCardData={tgsUpgradeCardData}
            data={{
              bookingId: this.bookingId,
              authId: this.mmtAuth
            }}
            card={card}
          />);
          break;

        case RailBookingDetailConstant.ALERT_INFO_CARD:
          views.push(<AlertInfo
            key="AlertInfo"
            infoList={this.state.responseJson.importantInformationList}
          />);
          break;

        case CHAT_WITH_MYRA_CARD:
          if (isMyraEnabled(this.state.responseJson.lobCode)) {
            views.push(<ChatMyra
              key="ChatMyra"
              response={this.state.responseJson}
              pageName="TripDetails"
              card={card}
            />,
          );
        }
        break;
      case RailBookingDetailConstant.RAIL_NEED_HELP_CARD_MMT:
        views.push(
          <CallHelpUs
            key="CallHelpUs"
            card={card}
            phoneNumber={this.state.responseJson.tollFreeNumber}
            pageName={PAGE_NAME.TRIP_DETAILS}
            onClickEventTracker={this.onClickEventTracker}
          />
        );
        break;
      case RailBookingDetailConstant.RAIL_NEED_HELP_CARD:
        views.push(
          <RailCallHelp
            key="RailCallHelp"
            response={this.state.responseJson}
            card={card}
            pageName="RailHelpPage"
            onClickEventTracker={this.onClickEventTracker}
          />,
        );
        break;
      case COMMON_CARD.LOYALTY_CARD:
        if (this.state.responseJson.loyaltyDetails) {
          views.push(
            <MMTBlackCard
              {...this.state.responseJson.loyaltyDetails}
              bookingId={this.state.responseJson.bookingId}
              uuid={this.state.responseJson.uuid}
              onClick={this.onMMTBlackCardClickEvent}
              trackingPageName={this.trackPageName}
            />,
          );
        }
        break;
      case RailBookingDetailConstant.RAIL_ADD_TECH_CARD:
        !this.state.responseJson.isCorporateBooking && views.push(this.renderAdTechCard());
        break;
      case RailBookingDetailConstant.NEED_MORE_HELP_CARD:
        if (this.state.customerSupportCardData?.ruleTrace === RuleTraceConst.INLINE) {
          views.push(
            <CustomerSupportCard
              needMoreHelpCard={this.state.responseJson?.needMoreHelpMIMACard}
              pageName="Mob:customer support:Rail:TripDetails"
              bookingId={this.bookingId}
            />,
          );
        }
        break;
      case RailBookingDetailConstant.MSR_MAIN_CARD:
        this.state.responseJson.msrTicketsForBooking &&
          views.push(
            <MSRMainCard
              key="MSRMainCard"
              ticketsList={(this.state.responseJson.msrTicketsForBooking || {}).ticketsList}
              card={card}
              pageName={this.trackPageName}
            />,
          );
        break;
      default:
        break;
    }
  }

  onMMTBlackCardClickEvent = (omnitureClickEvent) => {
    RailBookingTrackingHelper.trackClickEvent(
      this.trackPageName,
      this.state.responseJson,
      omnitureClickEvent,
    );
  };

  onTGCardClickEventTracker = (omnitureClickEvent) => {
    RailBookingTrackingHelper.trackClickEvent(
      PAGE_NAME.TRIP_DETAILS,
      this.state.responseJson,
      omnitureClickEvent,
    );
  };

  onClickEventTracker = (omnitureClickEvent) => {
    RailBookingTrackingHelper.trackClickEvent(
      RAIL_TRACKING_CONSTANT.RAIL_BOOKING_DETAILS_PAGE,
      this.state.responseJson,
      omnitureClickEvent,
    );
  };

  renderAdTechCard = () => {
    const { psTheme } = this.context;
    const { color } = psTheme;
    const styles = getStyle(color);
    return <WhiteCard styles={styles}>
      {getAdTechCard(POST_SALES_RAIL_DETAILS_PAGE)}
    </WhiteCard>
  };

  renderProgressView = (status) => {
    const staticData = getStaticData();
    const {loadingYourTripText, loadingText} = staticData;
    let message = '';
    switch(status){
      case LOADING.TGS_HOLD:
        message = loadingText
        break;
      default:
        message = loadingYourTripText
    }
    return (<ProgressView message={message} />)
  };

  goBack = () => {
    if (Platform.OS === 'ios') {
      ViewControllerModule.popViewController(1);
    } else {
      super.onBackIconPress();
    }
  };
  renderErrorView = () => {
    const staticData = getStaticData();
    const { backText, errorText, tryAgainText } = staticData;
    return (
      <View style={{ flex: 1, marginTop: statusBarHeightForIphone }}>
        <SimpleHeader title={backText} iconPress={this.goBack} />
        <ErrorView
          showRetry
          buttonLabel={tryAgainText}
          onRetry={() => {
            this.setState({ viewState: ViewState.LOADING });
            this.fetchJSONAsync(RAIL_BOOKING_DETAILS_URL + this.bookingId);
          }}
          message={
            !isEmpty(this.state.errorMessage)
              ? this.state.errorMessage
              : errorText.somethingWentWrongTryAgainError
          }
        />
      </View>
    );
  };

  renderAuthErrorView = () => <AuthErrorView bookingId={this.bookingId} lobName={LOBNAMES.RAIL} onGoBackPress={this.goBack} />

  renderNoNetworkView = () => {
    const staticData = getStaticData();
    return (
      <View style={{ flex: 1, marginTop: statusBarHeightForIphone }}>
        <SimpleHeader title={staticData.backText} iconPress={this.goBack} />
        <NoInternetView
          buttonText={staticData.tryAgainText}
          onRetry={() => {
            this.setState({ viewState: ViewState.LOADING });
            this.fetchJSONAsync(RAIL_BOOKING_DETAILS_URL + this.bookingId);
          }}
        />
      </View>
    );
  };

  toggleOverlay() {
    this.setState({ overlay: !this.state.overlay });
  }

  renderQRCode() {
    return (
      <View style={{ height: Dimensions.get('screen').height }}>
        <RailQrCodeOverlay closePopup={this.toggleOverlay} response={this.state.responseJson} />
      </View>
    );
  }

  renderAdvisoryOverlay() {
    const { psTheme } = this.context;
    const { color } = psTheme;
    const styles = getStyle(color);
    return (
      <View style={styles.overlayContainer}>
        <TouchableHighlight style={styles.overlayBg}>
          <Text>.</Text>
        </TouchableHighlight>
        <AdvisoryOverlay
          key="RailBottomOverlay"
          pageName="RailBottomOverlay"
          advisoryNoticeText={this.state.responseJson && this.state.responseJson.advisoryNoticeText}
          closeoverlay={this.toggleAdvisoryOverlay}
          langAware={true}
        />
      </View>
    );
  }

  closeQROverLay(){
    if(this.state.overlay){
      this.setState({overlay: false});
      return true;
    }
  }

  buildRequest() {
    const segments = [];
    const segment = {};
    segment.partialPax = false;
    segment.segmentId = 1;
    segments.push(segment);
    const request = {
      userId: 'MMT/MIMA',
      action: 'getRules',
      bookingId: this.bookingId,
      segment: segments,
    };
    return request;
  }

  async fetchLayout() {
    try {
      const hasNetwork = await isNetworkAvailable();
      if (!hasNetwork) {
        this.setState({ viewState: ViewState.NO_INTERNET });
        return;
      }
      const commonHeaders = await getCommonHeaders(this.mmtAuth, this.loggingTrackInfo);

      const response = await fetch(RAIL_TDR_URL, {
        method: 'POST',
        body: JSON.stringify(this.buildRequest()),
        headers: commonHeaders,
      });

      if (response.ok) {
        const responseBody = await response.json();
        if (responseBody.tdrLayoutResponse == null) {
          this.setState({
            tdrApiError: true,
          });
        }
        this.setState({
          tdrRules: responseBody,
          errorText: responseBody.errorMessages,
        });
      } else {
        this.setState({ tdrApiError: true });
        this.trackError();
      }
    } catch (error) {
      this.setState({ tdrApiError: true });
      this.trackError();
    }
  }

    renderThankYouPage = () => {
      const staticData = getStaticData();
      const { seeUpdatedBookingCapitalizeText, tgsAddedSuccessfully } = staticData;
      Actions.commonSubmitRequestThankYou({
          icon: TG_ICON,
          iconStyle: {
              width: 80,
              height:50,
              resizeMode: 'contain',
              marginBottom: 8
          },
          title: tgsAddedSuccessfully,
          descriptionList: [],
          btnTitle: seeUpdatedBookingCapitalizeText,
          bookingId: this.bookingId,
          lob: LOBNAMES.RAIL
      });

    }

    openThankYouPage = (data) => {
      let success = false;
      if(Platform.OS === 'android'){
        success = checkForAndroidPaymentSuccess(data)
      }else if(Platform.OS === 'ios'){
        success = checkForIosPaymentSuccess(data)
      }
      if(success){
        this.renderThankYouPage();
      }else{
        this.setState({
          viewState: ViewState.ERROR
        });
      }
  }

  async handlePendingPayClick(paymentUrl){
    const userInfo = await HotelBookingModule.getRequestHeader(this.bookingId, 'RAIL_BOOKING_DETAIL');
    const {payNowText} = getStaticData(true);
    if(Platform.OS === "web") {
      window.location.href = paymentUrl;
    } else {
     isEasyPayNativeFlowEnabled()
       ? GenericModule.openDeepLink(paymentUrl)
       : Actions.openPsWebView({
           url: paymentUrl,
           headerText: payNowText,
           headerIcon: BACK_ARROW,
           injectedJavaScript: setWebViewAuth(userInfo.mmtAuth),
         });
    }
  }

    async fetchTGSHoldData() {
      try {
        const hasNetwork = await isNetworkAvailable();
        if (!hasNetwork) {
          this.setState({viewState: ViewState.NO_INTERNET});
          return;
        }
        const commonHeaders = await getCommonHeaders(this.mmtAuth, this.loggingTrackInfo);
        this.setState && this.setState({
          viewState: ViewState.LOADING_TGS
        })
        const response = await fetch(RAIL_PAYMENT+`/${RAIL_TGS.HOLD}`, {
          method: 'POST',
          body: JSON.stringify({
            bookingId:this.bookingId
          }),
          headers: commonHeaders
        });

        if (response.ok) {
          const responseBody = await response.json();
          if (!responseBody.success) {
            this.setState({viewState: ViewState.ERROR});
          }else{
            const paymentRequest = getPaymentRequest(responseBody, this.bookingId);
            if( Platform.OS === "android" ){
              PostSalesModule.openPaymentPage(JSON.stringify(paymentRequest));
            }else if( Platform.OS === "ios" ){
              GenericModule.openPaymentLanding(paymentRequest);
            }
            this.setState({
              viewState: ViewState.SHOW_DETAIL
            })
          }
        } else {
          this.setState({viewState: ViewState.ERROR});
        }
      } catch (error) {
        console.log(error);
        this.setState({viewState: ViewState.ERROR});
      }
    }

    async fetchJSONAsync(url) {
      try {
        const hasNetwork = await isNetworkAvailable();
        if (!hasNetwork) {
          this.setState({viewState: ViewState.NO_INTERNET});
          return;
        }
        const userInfo = await HotelBookingModule.getRequestHeader(this.bookingId, 'RAIL_BOOKING_DETAIL');
        this.mmtAuth = userInfo.mmtAuth;
        this.loggingTrackInfo = userInfo.loggingTrackingInfo;

        const response = await
          fetch(url, {headers: await getCommonHeaders(this.mmtAuth, this.loggingTrackInfo)});
        const body = await response.json();
        if (body.status === true || body.isLostBookingId) {
          this.setState({
            responseJson: body,
            viewState: ViewState.SHOW_DETAIL
          });
          if (!isEmpty(body)) {
            this.fetchLayout();

        }
      } else {
        if (body && body?.message && body.message.toUpperCase() === AUTH_FAILED_MESSAGE) {
          this.setState({ viewState: ViewState.AUTH_ERROR });
        } else {
          this.setState({
            viewState: ViewState.ERROR,
            errorMessage: body.errorMessage,
          });
          this.trackError();
        }
      }
    } catch (error) {
      this.setState({ viewState: ViewState.ERROR });
      this.trackError();
    }
  }

  trackError = () => {
    RailBookingTrackingHelper.trackErrorEvent('TripDetailsError');
  };
}

RailBookingDetails.propTypes = {
  data: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  railDetailsDispatcher: (railAPIResponse) => {
    dispatch({ type: RAIL_DETAILS, payload: railAPIResponse });
  },
  tdrRequestDispatcher: (tdrRequestForFiling) => {
    dispatch({ type: RAIL_TDR_REQUEST, payload: tdrRequestForFiling });
  },
  railTdrLayoutDispatcher: (railTdrLayoutResponse) => {
    dispatch({ type: RAIL_TDR_RULES_LAYOUT, payload: railTdrLayoutResponse });
  },
});

const ConnectedRailBookingDetail =(props)=>{
  return <RailBookingDetails {...props} />
}

export default connect(null, mapDispatchToProps)(ConnectedRailBookingDetail);
