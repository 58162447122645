import React from 'react';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { View, Dimensions } from 'react-native';
import { useTheme } from '../../../theme/theme.context';
import createStyles from './BottomSemiCircleCss';

const BottomSemiCircle: React.FC<{}> = () => {
  const width = Dimensions.get('window').width;
  const cardBtmEffect = Array(parseInt(`${(width - 64) / 16}`, 10)).fill(null);

  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme);

  return (
    <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween]}>
      {cardBtmEffect.map((_, i) => (
        <View key={i} style={styles.semiCircle} />
      ))}
    </View>
  );
};

export default BottomSemiCircle;
