import React from "react";
import { View, Text } from "react-native";
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss'
import createStyles from "./refundVoucherCss";
import TGSBookingConstants from '../../../../Utils/TGSBookingConstants';
import HTMLViewWrapper from "../../../../../Common/HTMLViewWrapper"
import { getFont } from '../../../../../PostSalesStyles';
import { useTheme } from '../../../../../theme/theme.context';
import LinearGradient from "react-native-linear-gradient";
const RefundVoucherCard = ({ tripGuaranteeInfo }) => {
    const { refundInPaymnode, travelVoucher } = tripGuaranteeInfo || {};
    const fonts = getFont();
    const {psTheme : theme} = useTheme();
    const styles = createStyles(theme,fonts);
    return (
        <>
               {!!refundInPaymnode &&<View style={[styles.refundVoucherInfo, AtomicCss.marginRight10]}>
                <LinearGradient
                    colors={[theme.color.yellow6, theme.color.azureLight2]}
                    start={{ x: 0.0, y: 0.0 }}
                    end={{ x: 1.0, y: 0.0 }}
                    style={styles.priceTag}
                >
                    <Text style={[styles.voucherPriceTxt]}>{TGSBookingConstants.RUPEE_SIGN}</Text>
                </LinearGradient>
              <HTMLViewWrapper style={[AtomicCss.font10]} htmlContent={refundInPaymnode} />
            </View>}
            {!!travelVoucher &&  <View style={styles.refundVoucherInfo}>
                <LinearGradient
                    colors={[theme.color.yellow6, theme.color.azureLight2]}
                    start={{ x: 0.0, y: 0.0 }}
                    end={{ x: 1.0, y: 0.0 }}
                    style={styles.priceTag}
                >
                    <Text style={styles.voucherPriceTxt}>{TGSBookingConstants.RUPEE_SIGN} {TGSBookingConstants.RUPEE_SIGN}</Text>
                </LinearGradient>
                <HTMLViewWrapper style={[AtomicCss.font10]} htmlContent={travelVoucher} />
            </View>}
        </>
    )
}
export default RefundVoucherCard;