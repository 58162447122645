import React, {Component} from 'react';
import {Image, PixelRatio, StyleSheet, Text, View} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import noInternetImage from '@mmt/legacy-assets/src/error_no_internet.webp';
import PropTypes from 'prop-types';
import getPlatformElevation from '../Card/getPlatformElevation';
import {colors, fonts} from '../../../Styles/globalStyles';
import TouchableRipple from '../TouchableRipple';
import ButtonWrapper from 'apps/post-sales/src/Common/ButtonWrapper';

class NoInternetView extends Component {
  render() {
    const {
      heading,
      subHeading,
      buttonText
    } = this.props
    return (
      <View style={[this.props.style]}>
        <View style={styles.noInternet}>
          <Image source={noInternetImage} style={styles.noInternetImage} />
          <Text style={styles.noInternetTitle}>{heading}</Text>
          <Text style={styles.noInternetSubtitle}>{subHeading}</Text>
        </View>
        <View style={styles.noInternetCta}>
          <ButtonWrapper
            btnText={buttonText}
            clickHandler={this.props.onRetry}
            buttonWidth='dynamic'
          />
        </View>
      </View>
    );
  }
}

NoInternetView.propTypes = {
  onRetry: PropTypes.func.isRequired,
  style: PropTypes.object,
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  buttonText: PropTypes.string,
  gradientColor: PropTypes.arrayOf(PropTypes.string)
};

NoInternetView.defaultProps = {
  style: {},
  heading:"You are not connected to the Internet",
  subHeading:"Please check your Settings and try again.",
  buttonText:"TRY AGAIN",
  gradientColor: ['#53B2FE', '#065AF3']
};

const styles = StyleSheet.create({
  noInternet: {
    alignItems: 'center'
  },
  noInternetImage: {
    height: PixelRatio.getPixelSizeForLayoutSize(60),
    marginHorizontal: PixelRatio.getPixelSizeForLayoutSize(24),
    marginVertical: PixelRatio.getPixelSizeForLayoutSize(8),
    resizeMode: 'contain'
  },
  noInternetTitle: {
    marginTop: 24,
    marginHorizontal: 24,
    color: colors.darkGrey,
    fontSize: 20,
    fontFamily: fonts.bold,
    lineHeight: 28,
    textAlign: 'center'
  },
  noInternetSubtitle: {
    marginTop: 8,
    marginHorizontal: 24,
    color: colors.lightTextColor,
    fontSize: 14,
    fontFamily: fonts.regular,
    lineHeight: 21,
    textAlign: 'center'
  },
  noInternetCta: {
    marginTop: 16,
    flexDirection:'row',
    justifyContent:"center"
  },
  cta: {
    backgroundColor: colors.azure,
    height: PixelRatio.getPixelSizeForLayoutSize(16),
    paddingHorizontal:  PixelRatio.getPixelSizeForLayoutSize(5),
    borderRadius:  PixelRatio.getPixelSizeForLayoutSize(8),
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    ...getPlatformElevation(4)
  },
  text: {
    color: colors.white,
    fontSize: PixelRatio.getFontScale() * 12,
    paddingHorizontal: 8,
    fontFamily: fonts.bold,
    backgroundColor: colors.transparent
  }
});

export default NoInternetView;
