import { BackHandler, NativeModules, Platform } from 'react-native';
import { Actions } from 'react-native-router-flux';
import { getCurrentRouterInfo } from '../Common/navigation/routerTypeRef';
import { logException } from './actionsLogger';

const _listeners = {};

export const listenPageFocusEvent = (pageName, listener) => {
  if (_listeners[pageName] && _listeners[pageName].length) {
    _listeners[pageName].push(listener);
  } else {
    _listeners[pageName] = [listener];
  }
};

export const removePageFocusListener = (pageName) => {
  if (_listeners && _listeners[pageName] && _listeners[pageName].length) {
    _listeners[pageName].pop();
  }
};

const notifyNativePageChange = (pageName) => {
  const { GenericModule } = NativeModules;
  if (GenericModule && GenericModule.onPageChanged) {
    GenericModule.onPageChanged(pageName);
  }
};

export const onPageFocused = (pageName) => {
  let listener = null;
  if (_listeners[pageName] && _listeners[pageName].length) {
    listener = _listeners[pageName][_listeners[pageName].length - 1];
  }
  if (listener) {
    listener.onPageFocused();
  }
  notifyNativePageChange(pageName);
};

const getActiveRouteName = (newRouteState) => {
  const route = newRouteState.routes[newRouteState.index];
  if (route.state) {
    // Dive into nested navigators
    return getActiveRouteName(route.state);
  }
  return route.name;
};

const _backHandlers = {};
export const listenBackPress = (pageName, listener) => {
  _backHandlers[pageName] = listener;
  BackHandler.addEventListener('hardwareBackPress', () => {
    let currentPageKey;
    let currentRouterInfo;
    try {
      currentRouterInfo = getCurrentRouterInfo();
    } catch (e) {
      logException(e.message);
    }
    if (currentRouterInfo && currentRouterInfo.type === 'REACT_NAV' && currentRouterInfo?.ref?.current?.getRootState) {
      currentPageKey = getActiveRouteName(
        currentRouterInfo?.ref?.current?.getRootState(),
      );
    } else {
      currentPageKey = Actions.currentScene;
    }
    return onBackPressed(currentPageKey);
  });
};
export const removeBackPressListener = (pageName) => {
  delete _backHandlers[pageName];
};

export const onBackPressed = (currPage) => {
  const handler = _backHandlers[currPage];
  if (handler) {
    return handler.onHardBackPress();
  }
  return false;
};

export const allActiveReactPages = (action) => {
  if (
    Platform.OS === 'ios' &&
    action &&
    action.scene &&
    action.scene.sceneKey &&
    action.scene.rootTag
  ) {
    const {
      scene: { sceneKey, rootTag },
    } = action;
    // send the pages to iOS Native client
    const { GenericModule } = NativeModules;
    GenericModule && GenericModule.reactPages(sceneKey, rootTag);
  }
};
