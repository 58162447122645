import React, {useState, useEffect, useRef} from 'react';
import {View, ScrollView, Text, Image, FlatList} from 'react-native';
import styles from './HouseRulesCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import Header from '../../cards/Header';
import Tabs from '../../../../Common/Tabs';
import {getHouseRules, getIdProofRules} from '../../utils/HotelBookingDetailUtil';
import {getScreenWidth} from '../../../../utils/PostSaleUtil';
import Actions from '../../../../navigation/postSalesNavigation';
import { WHITE_BG_INFO_ICON } from '../../../HotelImageConstants';
import { getStaticData } from '../../../../staticData/staticData';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import SlideTabPages from '@Frontend_Ui_Lib_App/SlideTabPages/lib/SlideTabPages';

const HouseRules = ({response, activeIndex = 0}) => {
	const { fsStyle, ...fonts } = getFont(true);
	const staticData = getStaticData();
	const houseRules = getHouseRules(response);
	const idProofRules = getIdProofRules(response);
	const [activeTab, setActiveTab] = useState(activeIndex);
	let TabsItem = [];
	if (houseRules) {
		TabsItem = [ ...TabsItem, response?.hotelPolicyText || staticData.houseRulesText.additionalText.hotelPolicyText];
	}

	if (idProofRules) {
		TabsItem = [ ...TabsItem, staticData.houseRulesText.additionalText.idProofText];
	}

	const onBackPress = () => {
		Actions.pop();
	};

	const getHouseRulesView = () => {
		return(
			<View style={styles.cardWrapper}>
				<FlatList
					data={Object.keys(houseRules)}
					keyExtractor={({ item }) => item}
					renderItem={({ item: category, index }) =>
						<View style={[AtomicCss.whiteCardNew, AtomicCss.marginHz12, AtomicCss.borderRadius16, index === 0 ? AtomicCss.marginTop12 : null]}>
							<View style={[styles.headerSec, AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom15]}>
								<Text style={[fonts.blackFontFamily, fsStyle.font16, AtomicCss.blackText, AtomicCss.lineHeight20]}>{category}</Text>
							</View>
							<View style={[styles.docList, AtomicCss.marginBottom15, AtomicCss.paddingLeft12, AtomicCss.paddingRight12]}>
								{houseRules[category] && houseRules[category].length > 0 && houseRules[category].map((item) =>{
									return(
										<View style={[AtomicCss.flexRow, AtomicCss.marginBottom6]}>
											<Text style={styles.bullet}></Text>
											<Text style={[fonts.regularFontFamily, fsStyle.font14, AtomicCss.defaultText, AtomicCss.lineHeight18]}>{item}</Text>
										</View>
									)
								})}    
							</View>
						</View>
					}
				/>
			</View>
		)
	}

	const getIdProof = () => {
		return (
			<View style={styles.idCardWrapper}>
				<View style={[AtomicCss.whiteCardNew, AtomicCss.marginHz12, AtomicCss.borderRadius16]}>
					<View style={[styles.headerSec, AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom15]}>
						<Text style={[fonts.blackFontFamily, fsStyle.font16, AtomicCss.blackText, AtomicCss.lineHeight20]}>{staticData.houseRulesText.additionalText.idProofCheckInText}</Text>
					</View>
					<View style={[styles.docList, AtomicCss.marginBottom15, AtomicCss.paddingLeft12, AtomicCss.paddingRight12]}>
						{idProofRules && idProofRules.length > 0 && idProofRules.map((item) =>{
							return(
								<View style={[AtomicCss.flexRow, AtomicCss.marginBottom6]}>
									<View style={styles.bullet}></View>
									<Text style={[fonts.regularFontFamily, fsStyle.font14, AtomicCss.defaultText, AtomicCss.lineHeight18]}>{item}</Text>
								</View>
							)
						})}
					</View>  
				</View>
			</View>
		)
		
	}

	if(!houseRules && !idProofRules) {
		return null;
	}

	const houseRulesData = houseRules && getHouseRulesView();
	const getIdProofData = idProofRules && getHouseRulesView();
	return(
		<View style={[styles.contentWrapper, AtomicCss.flex1]}>
			<View style={styles.topStickySection}>
				<Header
					title={response?.hotelPolicyCheckInText || staticData.houseRulesText.additionalText.hotelPolicyCheckInText}
					headerShadow={false}
					callUs={true}
					headerRtSection={true} 
					onBackPress={onBackPress}
					response={response}
					pageName={"Hotel Policy & Check in"}
				/>
			</View>

			<SlideTabPages
				currentActiveTab={activeTab}
				customActiveTabTextStyles={fonts.boldFontFamily}
				pages={[
					houseRulesData,
					getIdProofData
				]}
				scrollEnabled
				tabsData={TabsItem}
			/>

		</View>
	)
};

export default HouseRules;