import { StyleSheet } from 'react-native';

const getStyles = (color, fonts) => {
  return StyleSheet.create({
    triptype: {
      color: color.black74,
      fontSize: fonts.fontSize.font14,
      ...fonts.boldFontFamily,
    },
    bookingInfoDetails: {
      paddingHorizontal: 14,
      paddingBottom: 10,
      marginTop: 6
    },
    dotWrapper: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    dot: {
      width: 8,
      height: 8,
      borderRadius: 10,
      borderWidth: 1,
      borderColor: color.textGrey,
      marginRight: 5,
    },
    dotFilled: {
      width: 8,
      height: 8,
      borderRadius: 10,
      borderWidth: 1,
      borderColor: color.textGrey,
      backgroundColor: color.textGrey,
      marginLeft: 5,
    },
    borderLine: {
      width: 50,
      height: 2,
      backgroundColor: color.textGrey,
      borderRadius: 4,
    },
    arrowStyleWrapper: {
      position: 'absolute',
      top: 0,
      right: 0,
    },
    arrowstyle: {
      width: 24,
      height: 24,
    },
    roundTripStyle: {
      width: 15,
      height: 15,
    },
    infoRowSection: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '90%',
    },
    detailsRowSection: {
      paddingBottom: 15,
      paddingHorizontal: 14,
      borderLeftWidth: 1,
      borderLeftColor: color.textGrey,
    },
    lastRow: {
      borderLeftWidth: 0,
      borderLeftColor: 'transparent',
      paddingBottom: 0,
    },
    dotFilledPosition: {
      position: 'absolute',
      left: -8,
      top: 5,
    },
    dotPositionWrapper: {
      width: 20,
      height: 20,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: color.white,
      position: 'absolute',
      left: -8,
      top: 0,
    },
    activeArrow: {
      transform: [
        {
          rotate: '180deg',
        },
      ],
    },

    cityHeading: {
      color: color.black,
      fontSize: fonts.fontSize.font16,
      ...fonts.boldFontFamily,
    },
    cityDesc: {
      color: color.grey,
      fontSize: fonts.fontSize.font14,
      ...fonts.regularFontFamily,
    },
    dateText: {
      color: color.defaultTextColor,
      fontSize: fonts.fontSize.font12,
      ...fonts.boldFontFamily,
    },
    footerText: {
      color: color.textGrey,
      fontSize: fonts.fontSize.font12,
      ...fonts.italicFontFamily,
    },
    timeUpdatedTag: {
      borderWidth: 1,
      borderRadius: 16,
      paddingHorizontal: 5,
      paddingVertical: 2
    },
    timeUpdatedText: {
      ...fonts.boldFontFamily,
    },
    assuranceImg: {
      width: 15,
      height: 15,
    },
    flexRow: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  });
};

export default getStyles;
