import React from 'react';
import {
  View, ScrollView, Text, TouchableOpacity,
  DeviceEventEmitter, Platform,
} from 'react-native';
import BasePage from '../../../Common/PostSalesBasePage';
import StickyHeader from '../common/StickyHeader';
import getStyles from '../../details/components/HotelMamiCss';
import {isNotNullAndEmpty, handleModificationAction, isIntlHotelBooking, fetchHotelDetailsResponse} from '../../details/utils/HotelBookingDetailUtil';
import HotelModificationInfoStrip from '../common/HotelModificationInfoStrip';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import CheckAvlBtn from '../common/CheckAvlBtn';
import HotelBookingTrackingHelper from '../../HotelBookingTrackingHelper';
import { connect } from 'react-redux';
import {
  CHECK_IN_CHECK_OUT_SELECT_CLICK, CHECK_IN_CHECK_OUT_DONE_CLICK,
  CHECK_AVAILABILITY_DATECHANGE_CLICK
} from '../HotelModificationConstants';
import Calender from "../../Calender/Calender";
import DateChangeOverlay from "./DateChangeOverlay"
import { NEED_HELP_PAGE } from '../../../PostSalesConstant';
import { getHelpingHandData, renderNeedHelpOverlay, trackHelpingHandEvent } from '../../details/utils/HelpingHandHelper';
import { getButtonGradientColor } from '../../../utils/PostSaleUtil';

export const CALENDAR_EVENT_RECEIVED = 'Check_In_Check_Out_Date_Selected';
import SimpleHeader from '@mmt/legacy-commons/Common/Components/Header/SimpleHeader';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getStaticData } from '../../../staticData/staticData';
import { LANG_AWARE } from '../../HotelConstants';
import withRouterWrapper from '../../../Common/Web/withRouterWrapper';
import NoInternetView from '@mmt/legacy-commons/Common/Components/Error/NoInternetView';
import ProgressView from '../../../Common/ProgressView/ProgressView';
import { ERROR_TYPES } from '../../details/HotelBookingDetailsConstant';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import { getFont, themeColors } from '../../../PostSalesStyles';
import { has } from 'lodash';
import { geFixedWidthIntString } from '../../../Common/commonUtil';
import Actions from '../../../navigation/postSalesNavigation';

const MONTH_ARRAY = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC'
];

const WEEKDAY_ARRAY = [
  'Sun',
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat'
];

export const Hotel_DATECHANGE_CALENDER = 'HotelChangeDate';
class HotelChangeDate extends BasePage {
    static navigationOptions = {
      header: null
    }

    constructor(props) {
      super(props, Hotel_DATECHANGE_CALENDER);
      this.fonts = getFont(true);
      this.styles = getStyles(this.fonts);
      this.onDateSelection = this.onDateSelection.bind(this);
      this.getNights = this.getNights.bind(this);
      this.updateDateRangeInput = this.updateDateRangeInput.bind(this);
      this.onButtonClick = this.onButtonClick.bind(this);
      this.getCheckInOutDetails = this.getCheckInOutDetails.bind(this);
      this.isCheckAvlBtn_Disabled = false;
      this.maxNights = 30;
      this.today = new Date();
      this.date = geFixedWidthIntString(this.today.getDate(),2);
      this.month = this.today.getMonth();
      this.year = this.today.getFullYear();
      this.nextDate = new Date();
      this.nextDate.setDate(this.nextDate.getDate() + 1);
      this.trackingPageName = '';
      const { monthsShortText, weekDaysShortText } = getStaticData(LANG_AWARE.MODIFICATION);
      this.MONTH_ARRAY = Object.values(monthsShortText);
      this.WEEKDAY_ARRAY = Object.values(weekDaysShortText);
      let checkInOutData = {
        checkInDate: {
          year: this.year,
          month: this.month,
          date: this.date,
          weekday: this.WEEKDAY_ARRAY[this.today.getDay()],
          monthStr: this.MONTH_ARRAY[this.month],
          dateFormat: `${this.year}/${this.month + 1}/${this.date}`
        },
        checkOutDate: {
          year: this.nextDate.getFullYear(),
          month: this.nextDate.getMonth(),
          date: geFixedWidthIntString(this.nextDate.getDate(),2),
          weekday: this.WEEKDAY_ARRAY[this.nextDate.getDay()],
          monthStr: this.MONTH_ARRAY[this.nextDate.getMonth()],
          dateFormat: `${this.nextDate.getFullYear()}/${this.nextDate.getMonth() + 1}/${this.nextDate.getDate()}`
        },
      }
      if(this.props.response && isIntlHotelBooking(this.props.response?.lobCode)){
        checkInOutData = this.getCheckInOutDetails(this.props.response);
      }
      this.state = {
        showCalendar: false,
        checkInDate: checkInOutData.checkInDate,
        checkOutDate: checkInOutData.checkOutDate,
        defaultActiveTab: 'FROM',
        helpingHandOverlay: false
      };

      this.calendarDateReceived = this.onCalendarDateReceived.bind(this);
      this.calenderEventListener = null;
    }

    onButtonClick(request) {
      const extraArgs = {
        card: this.props.card
      };
      handleModificationAction('DATECHANGE', request,this.props.response, extraArgs, this.showHelpingHand, LANG_AWARE.MODIFICATION);
      this.trackingPageName = isIntlHotelBooking(this.props.response?.lobCode)
        ? 'Mob:customer support:Hotel International:DateChange:PickNewDates'
        : 'Mob:customer support:Hotel Domestic:DateChange:PickNewDates';
      HotelBookingTrackingHelper.trackClickEvent(this.trackingPageName, CHECK_AVAILABILITY_DATECHANGE_CLICK, this.props.response);
    }

    onDateSelection(data, dateType) {
      const selectedDate = new Date(data.getFullYear(), data.getMonth(), data.getDate());
      let sDate = new Date(this.state.checkInDate.year, this.state.checkInDate.month,  this.state.checkInDate.date);
      let eDate = new Date(this.state.checkOutDate.year, this.state.checkOutDate.month,  this.state.checkOutDate.date);
      const totalNights = this.getNights(selectedDate, sDate);

      if (dateType === 'FROM' || selectedDate.getTime() === sDate.getTime()) {
        sDate = selectedDate;
        eDate = selectedDate;
        this.toastError = false;
      } else if (dateType === 'TO') {
        if (selectedDate.getTime() >= sDate.getTime() && totalNights <= this.maxNights) {
          eDate = selectedDate;
          this.toastError = false;
        } else {
          eDate = selectedDate;
          sDate = selectedDate;
          this.toastError = false;
        }
      }
      this.updateDateRangeInput(sDate, eDate);
    }

    getNights(max, min) {
      const date1 = max;
      const date2 = min;
      const timeDiff = Math.abs(date2.getTime() - date1.getTime());
      const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
      return diffDays;
    }

    updateDateRangeInput(sDate, eDate) {
      const updatedStartDate = sDate.getDate() > 9 ? sDate.getDate() : (`0${sDate.getDate()}`).slice(-2);
      const updatedStartMonth = sDate.getMonth();
      const updatedStartYear = sDate.getFullYear();
      const updatedEndDate = sDate != eDate ? eDate.getDate() > 9 ? eDate.getDate() : (`0${eDate.getDate()}`).slice(-2) : '- -';
      const updatedEndMonth = sDate != eDate ? eDate.getMonth() : '';
      const updatedEndYear = sDate != eDate ? eDate.getFullYear() : '';
      const updatedEndWeekDay = sDate != eDate ? this.WEEKDAY_ARRAY[eDate.getDay()] : '';

      const startDate = {
        year: updatedStartYear,
        month: updatedStartMonth,
        date: updatedStartDate,
        weekday: this.WEEKDAY_ARRAY[sDate.getDay()],
        monthStr: this.MONTH_ARRAY[updatedStartMonth],
        dateFormat: `${updatedStartYear}/${updatedStartMonth + 1}/${updatedStartDate}`
      };
      const endDate = {
        year: updatedEndYear,
        month: updatedEndMonth,
        date: updatedEndDate,
        weekday: updatedEndWeekDay,
        monthStr: this.MONTH_ARRAY[updatedEndMonth],
        dateFormat: `${updatedEndYear}/${updatedEndMonth + 1}/${updatedEndDate}`
      };
      if (sDate === eDate) { this.isCheckAvlBtn_Disabled = true; } else { this.isCheckAvlBtn_Disabled = false; }

      this.setState({
        checkInDate: startDate,
        checkOutDate: endDate
      });
    }

    onCalendarDateReceived(dateMap) {
      if (dateMap != null) {
        const newCheckInDate = new Date(dateMap.checkinYear, dateMap.checkinMonth, dateMap.checkinDay);
        const newCheckOutDate = new Date(dateMap.checkoutYear, dateMap.checkoutMonth, dateMap.checkoutDay);
        this.onDateSelection(newCheckInDate, 'FROM');
        this.onDateSelection(newCheckOutDate, 'TO');
        this.trackingPageName = isIntlHotelBooking(this.props.response?.lobCode)
          ? 'Mob:customer support:Hotel International:DateChange:PickNewDates'
          : 'Mob:customer support:Hotel Domestic:DateChange:PickNewDates';
        HotelBookingTrackingHelper.trackClickEvent(this.trackingPageName, CHECK_IN_CHECK_OUT_DONE_CLICK, this.props.response);
      }
    }

  async renderCalender(dateType) {
    const { checkInDate, checkOutDate } = this.state;
    let checkinMonth = checkInDate.month + 1;
    let checkoutMonth = checkOutDate.month + 1;
    checkinMonth = checkinMonth > 9 ? checkinMonth : (`0${checkinMonth}`).slice(-2);
    checkoutMonth = checkoutMonth > 9 ? checkoutMonth : (`0${checkoutMonth}`).slice(-2);
    const newCheckinDate = `${checkInDate.date}/${checkinMonth}/${checkInDate.year}`;
    const newCheckOutDate = `${checkOutDate.date}/${checkoutMonth}/${checkOutDate.year}`;
    const params = {};
    params.checkinDate = newCheckinDate;
    params.checkoutDate = newCheckOutDate;
    if (dateType === 'FROM') { params.isCheckinSelected = true; } else { params.isCheckinSelected = false; }
    this.trackingPageName = isIntlHotelBooking(this.props.response?.lobCode)
      ? 'Mob:customer support:Hotel International:DateChange:PickNewDates'
      : 'Mob:customer support:Hotel Domestic:DateChange:PickNewDates';
    HotelBookingTrackingHelper.trackClickEvent(this.trackingPageName, CHECK_IN_CHECK_OUT_SELECT_CLICK, this.props.response);
    if (Platform.OS === "web") {
      this.toggleDateChangeOverlay(params);
    } else {
      await HotelBookingModule.openHotelCalendar(params);
    }
  }

  toggleDateChangeOverlay = (calenderOverlayData) => {
    const toDate = (dateStr) => {
      const [day, month, year] = dateStr.split("/")
      return new Date(year, month - 1, day)
    }
    if (calenderOverlayData) {
      calenderOverlayData.checkinDate = toDate(calenderOverlayData.checkinDate);
      calenderOverlayData.checkoutDate = toDate(calenderOverlayData.checkoutDate);
    }
    this.setState((previousState) => ({
      showCalendar: !previousState.showCalendar,
      calenderOverlayData
    }))
  }

  onDateCloseOverLay = () => {
    const {checkinDate,checkoutDate} = this.state.calenderOverlayData;
    this.trackingPageName = isIntlHotelBooking(this.props.response?.lobCode)
          ? 'Mob:customer support:Hotel International:DateChange:PickNewDates'
          : 'Mob:customer support:Hotel Domestic:DateChange:PickNewDates';
    HotelBookingTrackingHelper.trackClickEvent(this.trackingPageName, CHECK_IN_CHECK_OUT_DONE_CLICK, this.props.response);
    this.setState({
      showCalendar: false,
      calenderOverlayData: null
    }, () => {
      this.updateDateRangeInput(checkinDate,checkoutDate)
    })
  }

  updateOverlayDate = (from, to) => {
    const calenderOverlayData = { ...this.state.calenderOverlayData };
    calenderOverlayData.checkinDate = from;
    calenderOverlayData.checkoutDate = to;
    this.setState({
      calenderOverlayData
    })
  }

    componentDidMount() {
      this.calenderEventListener = DeviceEventEmitter.addListener(CALENDAR_EVENT_RECEIVED, this.calendarDateReceived);
      const { response, bookingId } = this.props;
      if (response) {
        this.trackHotelCalenderLoadEvent(response);
      } else {
        this.props.fetchBookingDetails(bookingId);
      }
    }

    onBackPress = () => {
      Actions.pop();
    }

    componentDidUpdate(prevProps) {
      const { showLoader, showError, response } = this.props;
      if (!showLoader && !showError && !prevProps.response && response) {
        this.trackHotelCalenderLoadEvent(response);
        if(isIntlHotelBooking(response?.lobCode))
          this.setState(this.getCheckInOutDetails(response))
      }
    }
    getCheckInOutDetails (response){
      const {hotelBookingInfo} = response;
      const {checkInDate, checkOutDate} = hotelBookingInfo;
      const checkindate = new Date(checkInDate);
      const checkoutdate = new Date(checkOutDate);
      const datePart = geFixedWidthIntString(checkindate.getDate(),2);
      const newCheckInDate = {
        year: checkindate.getFullYear(),
        month: checkindate.getMonth(),
        date: datePart,
        weekday: this.WEEKDAY_ARRAY[checkindate.getDay()],
        monthStr: this.MONTH_ARRAY[checkindate.getMonth()],
        dateFormat: `${checkindate.getFullYear()}/${checkindate.getMonth()+ 1}/${datePart}`
      }
      const newCheckOutDate = {
        year: checkoutdate.getFullYear(),
        month: checkoutdate.getMonth(),
        date: geFixedWidthIntString(checkoutdate.getDate(),2),
        weekday: this.WEEKDAY_ARRAY[checkoutdate.getDay()],
        monthStr: this.MONTH_ARRAY[checkoutdate.getMonth()],
        dateFormat: `${checkoutdate.getFullYear()}/${checkoutdate.getMonth() + 1}/${checkoutdate.getDate()}`
      }
      return {
        checkInDate: newCheckInDate,
        checkOutDate: newCheckOutDate
      }

    }

    componentWillUnmount() {
      if (this.calenderEventListener) {
        this.calenderEventListener.remove();
      }
    }

    trackHotelCalenderLoadEvent(hotelbookingDetailResponse) {
      this.trackingPageName = isIntlHotelBooking(hotelbookingDetailResponse?.lobCode)
        ? 'Mob:customer support:Hotel International:DateChange:PickNewDates'
        : 'Mob:customer support:Hotel Domestic:DateChange:PickNewDates';
      HotelBookingTrackingHelper.trackLoadEvent(this.trackingPageName, hotelbookingDetailResponse);
    }

    setHelpingHandOverlay = (value) => {
      this.setState({
        helpingHandOverlay: value
      });
    }

    trackHelpingHandEvents = (type, page, click, data) => {
      trackHelpingHandEvent(type, page, click, data, this.trackingPageName, this.props.response);
    }

    showHelpingHand = () => {
      this.setState({
        helpingHandOverlay: true
      })
    }

    trackRetryClickEvent = (clickEvent) => {
      this.trackingPageName = isIntlHotelBooking(this.props.response?.lobCode)
        ? 'Mob:customer support:Hotel International:DateChange:Preview:Error'
        : 'Mob:customer support:Hotel Domestic:DateChange:Preview:Error';
      HotelBookingTrackingHelper.trackClickEvent(
        this.trackingPageName,
        clickEvent,
        this.props.response,
      );
    }

    renderProgressView = () => (
      <ProgressView />
    );

    renderNoNetworkView = () => {
      const staticData = getStaticData(LANG_AWARE.MODIFICATION);
      const { bookingId } = this.props;
      return (
        <View style={AtomicCss.flex1}>
          <SimpleHeader title={staticData.backText} iconPress={this.onBackPress} />
          <NoInternetView
              onRetry={() => {
                this.props.fetchBookingDetails(bookingId);
                trackRetryClickEvent('no_internet_retry_clicked');
              }}
          />
        </View>
      );
    };

    renderErrorView = () => {
      const staticData = getStaticData(LANG_AWARE.MODIFICATION);
      const { bookingId, hotelDetailsErrorData } = this.props;
      const { errorType } = hotelDetailsErrorData || {};
      if (errorType === ERROR_TYPES.NO_INTERNET) {
        return this.renderNoNetworkView();
      }
      return (
        <View style={AtomicCss.flex1}>
          <SimpleHeader title={staticData.backText} iconPress={this.onBackPress} />
          <ErrorView
            showRetry
            onRetry={() => {
              this.props.fetchBookingDetails(bookingId);
              this.trackRetryClickEvent('retry_clicked');
            }}
            buttonGradient={themeColors.gradientBtnColor}
            message={staticData.errorText.somethingWentWrongTryAgainError}
            buttonLabel={staticData.tryAgainText}
          />
        </View>
      );
    };

    render() {
      const { showLoader, showError, response } = this.props;
      if (showError) {
        return this.renderErrorView();
      } else if (showLoader || !response) {
        return this.renderProgressView();
      }
      const {checkInDate, checkOutDate} = this.state;
      const hotelbookingDetailResponse = this.props.response;
      const hotelDetailResponse = hotelbookingDetailResponse.hotelBookingInfo;
      const hotelBookingInfo =
            hotelDetailResponse.hotelDetailsList[hotelDetailResponse.hotelDetailsList.length - 1];
      const hotelName = hotelBookingInfo.name;
      const oldCheckInDate = hotelDetailResponse.checkInDate;
      const oldCheckOutDate = hotelDetailResponse.checkOutDate;
      const checkinCheckoutText = has(hotelDetailResponse, 'commonFields.checkinCheckoutText2')? hotelDetailResponse.commonFields.checkinCheckoutText2 : '';
      const roomDetails =
      isNotNullAndEmpty(hotelDetailResponse.roomDetails) ?
      hotelDetailResponse.roomDetails.length : 0;
      const totalGuest =
      (hotelDetailResponse.totalNumberOfAdults + hotelDetailResponse.totalNumberOfChilds);
      let buttonGradientColor = getButtonGradientColor(hotelbookingDetailResponse.isCorporateBooking);
      if (this.isCheckAvlBtn_Disabled) {
        buttonGradientColor = ['#9B9B9B', '#9B9B9B'];
      }
      if(this.props.response.isCorporateBooking){
        buttonGradientColor = ['#f85a6e', '#f27237'];
      }
      const {
        hotelDateChangeCardsText:{
          heading,
          items:{
            buttonText,
            calenderOverlayText
          },
          additionalText:{
            newCheckInText,
            newCheckOutText
          }
        },
        hotelModificationInfoStripCardText: {
          additionalText: {
            onePickNewDatesText
          }
        }
      } = getStaticData(LANG_AWARE.MODIFICATION);
      return (
        <View style={{width: '100%', height: '100%', justifyContent: 'space-between'}}>
          <StickyHeader
            hotelName={hotelName}
            checkinCheckoutText={checkinCheckoutText}
            roomDetails={roomDetails}
            guestCount={totalGuest}
          />
          <ScrollView>
            <HotelModificationInfoStrip
              modificationState="Modify"
              action={onePickNewDatesText}
            />
            <View>
              <Text style={this.styles.addGuestHead}>{heading}</Text>
              <View style={[this.styles.flexRow, this.styles.dateInfoOuter]}>
                <TouchableOpacity style={[this.styles.flex1, this.styles.borderRt, this.styles.dateInfoWrap, this.styles.checkInDateInfoWrap]} onPress={() => this.renderCalender('FROM')}>
                  <Text style={[this.styles.mar3Bt, AtomicCss.alignLeft]}>{newCheckInText}</Text>
                  <View style={[this.styles.flexRow, this.styles.alignCenter]}>
                    <Text style={[this.styles.calDates, this.styles.boldFont, AtomicCss.alignLeft]}>{checkInDate.date}</Text>
                    <View>
                      <Text style={[this.styles.DefaultText, AtomicCss.alignLeft]}>{checkInDate.monthStr} {checkInDate.year}</Text>
                      <Text style={AtomicCss.alignLeft}>{checkInDate.weekday}</Text>
                    </View>
                  </View>
                </TouchableOpacity>
                <TouchableOpacity style={[this.styles.flex1, this.styles.dateInfoWrap]} onPress={() => this.renderCalender('TO')}>
                  <Text style={[this.styles.mar3Bt, AtomicCss.alignLeft]}>{newCheckOutText}</Text>
                  <View style={[this.styles.flexRow, this.styles.alignCenter]}>
                    <Text style={[this.styles.calDates, this.styles.boldFont, AtomicCss.alignLeft]}>{checkOutDate.date}</Text>
                    <View>
                      <Text style={[this.styles.DefaultText, AtomicCss.alignLeft]}>{checkOutDate.monthStr} {checkOutDate.year}</Text>
                      <Text style={AtomicCss.alignLeft}>{checkOutDate.weekday}</Text>
                    </View>
                  </View>
                </TouchableOpacity>
              </View>
            </View>
          </ScrollView>
          <View style={{paddingHorizontal:15}}>
            <CheckAvlBtn
              checkinDate={this.state.checkInDate}
              checkOutDate={this.state.checkOutDate}
              oldCheckInDate={oldCheckInDate}
              oldCheckOutDate={oldCheckOutDate}
              bookingId={hotelbookingDetailResponse.bookingID}
              buttonText={buttonText.heading}
              buttonStyle={buttonGradientColor}
              onButtonClick={this.onButtonClick}
              hotelDetailResponse={hotelbookingDetailResponse}
            />
          </View>
          {(this.state.showCalendar && Platform.OS === "web") && <DateChangeOverlay>
            <SimpleHeader
              title={calenderOverlayText.heading}
              iconPress={() => this.toggleDateChangeOverlay(null)}
            />
            <Calender {...calenderOverlayText.additionalText} from={this.state.calenderOverlayData.checkinDate} to={this.state.calenderOverlayData.checkoutDate} updateDate={this.updateOverlayDate} />
            {(this.state.calenderOverlayData.checkinDate && this.state.calenderOverlayData.checkoutDate) && <TouchableOpacity style={this.styles.doneBtn} onPress={this.onDateCloseOverLay}><Text style={{ color: "#fff" }}>{calenderOverlayText.additionalText.buttonText}</Text></TouchableOpacity>}
          </DateChangeOverlay>
          }
          {this.state.helpingHandOverlay === true &&
            renderNeedHelpOverlay(getHelpingHandData(this.props.response, NEED_HELP_PAGE.HOTEL_DATE_CHANGE, this.trackHelpingHandEvents, LANG_AWARE.MODIFICATION), this.setHelpingHandOverlay, true)
          }
        </View>
      );
    }
}

const mapStateToProps = state => {
  const { hotelDetailsResponseEng, isHotelBookingAPIErrorEng, isHotelBookingDetailsFetching, hotelDetailsErrorData } = state.hotelDetailReducer;
  return {
    response: hotelDetailsResponseEng,
    showError: isHotelBookingAPIErrorEng,
    showLoader: isHotelBookingDetailsFetching,
    hotelDetailsErrorData: hotelDetailsErrorData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchBookingDetails: (bookingId, langAware = LANG_AWARE.MODIFICATION) => {
    dispatch(fetchHotelDetailsResponse(bookingId, langAware));
  },
});

export default withRouterWrapper(connect(mapStateToProps, mapDispatchToProps)(HotelChangeDate));
