import { StyleSheet } from 'react-native';
import { colors, fonts } from '@mmt/legacy-commons/Styles/globalStyles';

export default (theme)=>StyleSheet.create({
	radioButtonContainer: {
		width: 18,
		height: 18,
		backgroundColor: colors.white,
		borderRadius: 20,
		borderWidth: 1,
		borderColor: theme.color.primary,
		marginRight: 10,
	},
	itemContainer: {
		borderWidth: 1,
		borderColor: theme.color.lightGrey,
		borderTopLeftRadius: 4,
		borderTopRightRadius: 4,
		paddingHorizontal: 12,
		paddingVertical: 16
	},
	radioButton: {
		width: 12,
		height: 12,
		borderRadius: 20,
		marginLeft: 2,
		backgroundColor: colors.azure,
		marginTop: 2
	},
	radioBackgroundPersonal: {
		backgroundColor: colors.azure,
	},
	radioBackgroundCorporate: {
		backgroundColor: colors.yellow2,
	},
	selectedOption: {
		backgroundColor: theme.color.lightPrimaryColor
	},
	contentContaienr: {
		flex: 1,
		flexWrap: 'wrap',
	},
	titleContainer: {
		flexDirection: 'row',
		paddingBottom: 5
	},
	titleContent: {
		color: colors.defaultTextColor,
		fontFamily: fonts.bold,
		fontSize: 14,
		marginRight: 20
	},
	tag: {
		paddingHorizontal: 16,
		paddingVertical: 2,
		borderRadius: 9
	},
	tagText: {
		color: colors.white,
		fontFamily: fonts.bold,
		fontSize: 10
	},
	subText: {
		color: colors.greyLight,
		fontFamily: fonts.regular,
		flex: 1,
		lineHeight: 16,
	}
});

