import { getFont } from '../../../../../../PostSalesStyles';
import { useTheme } from '../../../../../../theme/theme.context';
import React from 'react';
import { View, Text } from "react-native";
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import createStyles from '../contactDetailsCss';
const CustomerDetails = ({ detail }) => {
    const { label:title, value:info } = detail || {};
    const fonts = getFont();
    const {psTheme : theme} = useTheme();
    const styles = createStyles(theme,fonts);
    return (
        <View style={AtomicCss.margin5}>
            {!!title && <Text style={[styles.title, AtomicCss.lightText, AtomicCss.marginBottom3]}>{title}</Text>}
            <View style={[styles.cusDetailsWrapper]}>
                {!!info && <Text style={[styles.title, AtomicCss.blackText]}>{info}</Text>}
            </View>
        </View>
    )
}

export default CustomerDetails;