import React from 'react';
import { View, Text, StyleSheet, ActivityIndicator } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { fonts, colors } from '@mmt/legacy-commons/Styles/globalStyles';

// Screenshot: https://gommt-my.sharepoint.com/:i:/g/personal/mmt8626_go-mmt_com/EX-ORw_O9QlEorsXGT0Np2IB6xy525ffgMggvUUR78o5tg?e=lI1iHT
const LoaderOverlay = ({loaderText}) => {
    return (
        <View style={styles.overlayContentWrapper}>
            <View style={styles.topCircle}>
                <View style={styles.loaderWrapper}>
                    <ActivityIndicator size="large" color={colors.yellow5} />
                </View>
            </View>
            <View style={[AtomicCss.alignCenter, AtomicCss.justifyCenter]}>
                <Text style={[styles.title]}>{loaderText}</Text>
            </View>
        </View>
    )
};

const styles = StyleSheet.create({
    overlayContentWrapper: {
       minHeight: 200
    },
    topCircle: {
        width: 300,
        height: 300,
        borderRadius: 280,
        backgroundColor: colors.creamWhite,
        alignSelf: 'center',
        position:'relative',
        marginTop: -150,
        transform: [{ scaleX: 2 }],
        marginBottom: 40
    },
    loaderWrapper: {
        height: 150,
        marginTop: 140,
        alignItems: 'center',
        justifyContent: 'center',
        transform: [{ scaleX: .5 }],
    },
    title: {
        color: colors.darkBrown,
        fontSize: 16,
        fontFamily: fonts.black,
        marginBottom: 20,
        lineHeight: 16
    }
})

export default React.memo(LoaderOverlay);
