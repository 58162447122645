import { LocationProps } from '../types/globalTypes';
export const ACTION_UPDATE_LOCATION = '@CROSS_SELL_CABS/ACTION_UPDATE_LOCATION';
export const ACTION_UPDATE_FARE_DATA = '@CROSS_SELL_CABS/ACTION_UPDATE_FARE_DATA';
export interface CrossSellStateProps {
  sourceLocation: LocationProps | null;
  destinationLocation: LocationProps | null;
  fareData: {
    totalFareLabel: string;
    deeplink: string;
  } | null;
}
interface ActionUpdateLocationProps {
  type: typeof ACTION_UPDATE_LOCATION;
  payload: {
    sourceLocation: LocationProps | null;
    destinationLocation: LocationProps | null;
  };
}
interface ActionUpdateFareProps {
  type: typeof ACTION_UPDATE_FARE_DATA;
  payload: {
    totalFareLabel: string;
    deeplink: string;
  };
}

export type CrossSellActionProps = ActionUpdateLocationProps | ActionUpdateFareProps;

export const initialState = {
  sourceLocation: null,
  destinationLocation: null,
  fareData: null,
};

export default function reducer(
  state: CrossSellStateProps = initialState,
  action: CrossSellActionProps,
): CrossSellStateProps {
  const { type, payload } = action;
  switch (type) {
    case ACTION_UPDATE_LOCATION:
      const { sourceLocation, destinationLocation } = payload;
      return {
        ...state,
        sourceLocation,
        destinationLocation,
        fareData: null,
      };
    case ACTION_UPDATE_FARE_DATA:
      return {
        ...state,
        fareData: payload,
      };
    default:
      return state;
  }
}
