import React, { useEffect } from 'react';
import { View, Image, Text, ScrollView, TouchableOpacity } from 'react-native';
import styles from './RefundRequestFailedCss';
import Button from '../../Button';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { RED_CROSS_BG_ICON } from '../../../flights/FlightImageConstants';
import { getStaticData } from '../../../staticData/staticData';
import PostSalesBookingTrackingHelper from '../../../PostSalesBookingTrackingHelper';
import { REFUND_OOT_TRACKING } from '../constants';
import useHardwareBackpress from "../../useHardwareBackpress";

const RefundRequestFailed = ({ onGoBack, onTryAgain, bookingId, pageName = '' }) => {
  const {
    tryAgainText,
    backToBookingText,
    pleaseTryAgain,
    technicalIssue,
    ticketRaiseFailedText,
  } = getStaticData(false);

  useEffect(() => {
    PostSalesBookingTrackingHelper.trackLoadEvent(`${pageName}:${REFUND_OOT_TRACKING.PAGES.ERROR}`, '', bookingId);
  }, []);

  useHardwareBackpress().addListener(onGoBack);

  return (
    <>
      <ScrollView>
        <View style={styles.fullPage}>
          <View style={styles.topSection}>
            <Image style={styles.mainconStyle} source={RED_CROSS_BG_ICON} />
            <Text style={styles.titleHeading}>{ticketRaiseFailedText}</Text>
          </View>
          <View style={styles.detailSection}>
            <View style={[styles.detailCard]}>
              <View style={styles.detailsTop}>
                <Text
                  style={[
                    AtomicCss.blackText,
                    AtomicCss.blackFont,
                    AtomicCss.font14,
                    AtomicCss.lineHeight20,
                    AtomicCss.marginBottom6,
                  ]}
                >
                  {pleaseTryAgain}
                </Text>
                <Text
                  style={[
                    AtomicCss.defaultText,
                    AtomicCss.regularFont,
                    AtomicCss.font14,
                    AtomicCss.lineHeight20,
                  ]}
                >
                  {technicalIssue}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </ScrollView>

      <View style={[styles.buttonBottomWrapper]}>
        {onTryAgain && (
          <Button
            clickHandler={onTryAgain}
            btnWidth={'98%'}
            btnBg="gradientBtn"
            btnType="flat"
            fontSize="font16"
            btnTxt={tryAgainText}
          />
        )}
        {onGoBack && (
          <TouchableOpacity style={styles.linkTextWrapper} onPress={onGoBack}>
            <Text
              style={[
                AtomicCss.azure,
                AtomicCss.blackFont,
                AtomicCss.font14,
                AtomicCss.lineHeight18,
              ]}
            >
              {backToBookingText}
            </Text>
          </TouchableOpacity>
        )}
      </View>
    </>
  );
};

export default RefundRequestFailed;
