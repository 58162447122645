import React, { useState } from 'react';
import { View, Text, TouchableOpacity, FlatList } from 'react-native';
import styles from '../indexCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getFont } from '../../../PostSalesStyles';

const NavHeader = ({ onPressHandler, data }) => {
  const fonts = getFont(true);
  const [activeNavIndex, handleNavIndex] = useState(0);
  const onNavPress = (index) => {
    handleNavIndex(index);
    onPressHandler(index);
  }
  const renderFlatListItem = ({item, index}) => (<TouchableOpacity
      activeOpacity={0.8}
      style={[
        styles.navBtn,
        (index === activeNavIndex ? styles.navBtnActive : {})
      ]}
      onPress={() => onNavPress(index)}
    >
      <Text
        style={[
          AtomicCss.font13,
          fonts.boldFontFamily,
          AtomicCss.defaultText,
          AtomicCss.marginBottom5,
          index === activeNavIndex ? styles.navBtnTitle : {}
        ]}
      >
        {item.tabHeader}
      </Text>
      <Text
        numberOfLines={2}
        style={[
          AtomicCss.font12,
          fonts.regularFontFamily,
          AtomicCss.defaultText,
          index === activeNavIndex ? styles.navBtnDesc : {}
        ]}
      >
        {item.subHeading}
      </Text>
    </TouchableOpacity>);

  if(!data.refundBreakup.length || data.refundBreakup.length <2) return null;
  return (
    <View style={styles.navHeader}>
      <View style={styles.navContainer}>
        <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween, AtomicCss.marginBottom8]}>
          <Text style={[AtomicCss.font18, fonts.blackFontFamily, AtomicCss.flex1, AtomicCss.blackText]}>{data.totalRefund.text} </Text>
          <Text style={[AtomicCss.font18, fonts.blackFontFamily, AtomicCss.blackText]}>{data.totalRefund.value}</Text>
        </View>
        <Text style={[AtomicCss.font13, fonts.regularFontFamily, AtomicCss.gray2Text, AtomicCss.marginBottom16]}>{data.bookingIdText}{data.bookingId}</Text>
      </View>
      <View style={styles.navList}>
        <FlatList
          horizontal
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={{paddingHorizontal: 16}}
          data={data.refundBreakup}
          renderItem={renderFlatListItem}
          keyExtractor={(item) => item.requestNo}
        />
      </View>
    </View>
  );
}

export default NavHeader;
