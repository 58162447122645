import {trackOmniture} from '@mmt/legacy-commons/Common/utils/OmnitureTrackerUtils';

class MMTSelectTrackingHelper {
    trackMMTSDisplayEvent = (pageName, eventName) => {
        const params = {};
        params.pageName = pageName;
        params.m_v15 = pageName;
        params.m_c1 = eventName;
        params.m_c50 = eventName;
        trackOmniture(pageName,params);
      }

      trackMMTSClickEvent = (pageName, eventName) => {
        const params = {};
        params.pageName = pageName;
        params.m_v15 = pageName;
        params.m_c54 = eventName;
        trackOmniture(pageName,params);
      }
}

export default new MMTSelectTrackingHelper();
