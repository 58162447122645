import getPlatformElevation from '@mmt/legacy-commons/Common/Components/Card/getPlatformElevation';
const styles = {

    bottomOverlayWrapper:{
        height:"auto",
        padding: 30,
        backgroundColor : "#fff",
        width:"100%",
        ...getPlatformElevation(2),
        borderTopLeftRadius : 8,
        borderTopRightRadius : 8
    },
    overlay:{
        height:"100%",
        width:"100%",
        position:"absolute",
        top:0,
        backgroundColor:"#000",
        opacity:0.6,
        ...getPlatformElevation(2),
    },
    crossIcon:{
        width:28,
        height:28
    },
    iconsOuter:{
        width:40,
        height:40,
        backgroundColor:"#f2f2f2",
        borderRadius:40,
        alignItems:"center",
        justifyContent:"center",
        marginRight:10
    },
    verifyIcons:{
        width:24,
        height:24
    },
    fullbtn:{
        paddingVertical: 15,
        borderRadius:4
    }

};

export default styles;

