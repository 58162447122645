import React from 'react';
import {Image, Text, View} from 'react-native';
import styles from '../GiftCardDetailsCss';
import {
  getActionButton,
  getButtonStyle,
  handleClickEvent,
  isNotNullAndEmpty,
} from '../GiftCardBookingDetailUtil';
import { PAYMENT_CARD_ICON } from '../GiftCardImageConstants';

const GiftCardPaymentCard = ({response, card, pageName}) => {

  const {paymentBreakup} = response;

  const handleClick = (action) => {
    handleClickEvent(card, action, response, pageName);
  };

  const invokeAmountPaidUI = (paymentBreakupMap) => {
    const views = [];
    if(paymentBreakupMap){
      for (let componentName in paymentBreakupMap) {
        views.push
        (
          <View key={componentName} style={[styles.Paymentrow, styles.mar15Bt]}>
            <Text style={styles.PaymentLeft}>- {componentName}</Text>
            <Text>{`${paymentBreakupMap[componentName]} `}</Text>
          </View>
        )
      }
    }
    return views;
  }

  if (!paymentBreakup) {
    return null;
  }

  return (
    <View style={[styles.MamiCard, styles.mar10Bt]}>
      <View style={[styles.CardContent, {paddingBottom: 0}]}>
        <Image
          style={styles.CardIcon}
          source={PAYMENT_CARD_ICON}
        />
        <View style={[styles.ContentPortion, {paddingRight: 16}]}>
          <View>
            <View style={[styles.Paymentrow, styles.mar10Bt]}>
              <View style={[styles.Paymentrow]}>
                <View style={styles.PaymentLeft}>
                  <Text style={[styles.BlackText, styles.blackFont]}>{paymentBreakup.paymentBreakupTotalPrice.text}</Text>
                </View>
                <View style={{flex: 0.3}}>
                  <Text style={[styles.BlackText, styles.blackFont, styles.AlignRight]}>
                    {`${paymentBreakup.paymentBreakupTotalPrice.value} `}
                  </Text>
                </View>
              </View>
            </View>
            <View style={[styles.Paymentrow, styles.mar10Bt]}>
              <View style={styles.PaymentLeft}>
                <Text style={styles.BlackText}>{paymentBreakup.paymentBreakupAmountPaid.text}</Text>
              </View>
              <Text style={styles.BlackText}>{`${paymentBreakup.paymentBreakupAmountPaid.value} `}</Text>
            </View>
            <View>
              {invokeAmountPaidUI(paymentBreakup.paymentBreakupMap)}
            </View>
          </View>
        </View>
      </View>
      <View style={card != null ? isNotNullAndEmpty(card.actionList) ? getButtonStyle(card.actionList) : styles.hideView : styles.hideView}>
        {card != null ? getActionButton(card, handleClick) : null}
      </View>
    </View>
  );
}

export default GiftCardPaymentCard;
