import React, {useState} from 'react';
import {View, Text, Image, TouchableOpacity} from 'react-native';
import PropTypes from 'prop-types';

import Button from '../../../Common/Button';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';
import {fonts} from '@mmt/legacy-commons/Styles/globalStyles';

import {UPLOAD_STATUS_CONSTANTS} from './Util';
import {DocIcon} from '../../HolidayBookingConstants';


function DocumentsRequiredCard(props) {
  const [isDocVisible, setDocVisible] = useState(false);
  const {response} = props;
  if (!response) {
    return null;
  }
  if (!Object.keys(response).length) {
    return null;
  }

  const {holidayDocumentsCard} = response;
  if (!holidayDocumentsCard) {
    return null;
  }
  if (!Object.keys(holidayDocumentsCard).length) {
    return null;
  }

  if (holidayDocumentsCard.buttons === null || holidayDocumentsCard.buttons.length === 0) {
    return null;
  }
  if (
    holidayDocumentsCard.uploadStatus === UPLOAD_STATUS_CONSTANTS.REQUIRED &&
    holidayDocumentsCard.buttons.length < 2
  ) {
    return null;
  }

  const renderDocData = () => {
    if (holidayDocumentsCard.uploadStatus !== UPLOAD_STATUS_CONSTANTS.REQUIRED) {
      return null;
    }
    if (isDocVisible) {
      return (
        <View>
          {holidayDocumentsCard.paxDocRelationNewState &&
            holidayDocumentsCard.paxDocRelationNewState.length &&
            holidayDocumentsCard.paxDocRelationNewState.map((
              docData // eslint-disable-line arrow-parens
            ) => (
              <View style={[styles.innerDtlsBox, AtomicCss.marginBottom12]}>
                <View style={[styles.headingSection, AtomicCss.marginBottom10]}>
                  <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
                    <Text style={[AtomicCss.boldFont, AtomicCss.blackText, AtomicCss.font14]}>
                      {docData.heading}{' '}
                    </Text>
                    <Text style={[AtomicCss.regularFont, AtomicCss.defaultText, AtomicCss.font12]}>
                      {' '}
                      {docData.paxName}
                    </Text>
                  </View>
                </View>
                <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom5]}>
                  <View style={[styles.bulletDesc]} />
                  <Text style={[AtomicCss.blackText, AtomicCss.blackFont]}>{docData.docs}</Text>
                </View>
                <Text
                  style={[
                    AtomicCss.defaultText,
                    AtomicCss.font12,
                    AtomicCss.regularFont,
                    AtomicCss.marginLeft12
                  ]}
                >
                  {docData.docsConditions}
                </Text>
              </View>
            ))}
        </View>
      );
    }
    return (
      <TouchableOpacity
        style={AtomicCss.marginBottom20}
        onPress={() => {
          setDocVisible(true);
          props.handleButtonClick(holidayDocumentsCard.buttons[0]);
        }}
      >
        <Text style={[AtomicCss.azure, AtomicCss.boldFont, AtomicCss.font14]}>
          {holidayDocumentsCard.buttons[0].text}
        </Text>
      </TouchableOpacity>
    );
  };

  const renderSubTextWithBoldText = () => {
    if (!holidayDocumentsCard.dueDate) {
      return (
        <Text
          style={[
            AtomicCss.regularFont,
            AtomicCss.font14,
            AtomicCss.defaultText,
            AtomicCss.lineHeight20,
            AtomicCss.marginBottom15
          ]}
        >
          {holidayDocumentsCard.subText}
        </Text>
      );
    }

    return (
      <Text
        style={[
          AtomicCss.regularFont,
          AtomicCss.font14,
          AtomicCss.defaultText,
          AtomicCss.lineHeight20,
          AtomicCss.marginBottom15
        ]}
      >
        {holidayDocumentsCard.subText.split(holidayDocumentsCard.dueDate).map((text, index) => {
          if (index === 0) {
            return text;
          }
          return (
            <React.Fragment>
              <Text style={AtomicCss.boldFont}>{holidayDocumentsCard.dueDate}</Text>
              {text}
            </React.Fragment>
          );
        })}
      </Text>
    );
  };

  const renderButton = () => {
    const buttonIndex =
      holidayDocumentsCard.uploadStatus === UPLOAD_STATUS_CONSTANTS.REQUIRED ? 1 : 0;
    return (
      <View style={styles.btnWrapper}>
        <Button
          btnTxt={holidayDocumentsCard.buttons[buttonIndex].text}
          btnBg="gradientBtn"
          clickHandler={() => props.handleButtonClick(holidayDocumentsCard.buttons[buttonIndex])}
        />
      </View>
    );
  };

  return (
    <View style={[AtomicCss.marginBottom10, styles.cardBorder]}>
      <View style={[styles.infoCardWrapper]}>
        <View style={styles.headingIconWrapper}>
          <Image style={styles.headingIconStyle} source={DocIcon} />
        </View>
        <View style={AtomicCss.flex1}>
          <View style={styles.infoCardContent}>
            <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom15]}>
              <Text style={[AtomicCss.defaultText, AtomicCss.blackFont, AtomicCss.font14]}>
                {holidayDocumentsCard.heading}
              </Text>
            </View>
            {holidayDocumentsCard.text && (
              <View style={[styles.missedUploadWrapper]}>
                <Text style={styles.failedText}>{holidayDocumentsCard.text}</Text>
              </View>
            )}
            {renderSubTextWithBoldText()}
            {renderDocData()}
            {renderButton()}
          </View>
        </View>
      </View>
    </View>
  );
}

DocumentsRequiredCard.propTypes = {
  response: PropTypes.shape({
    holidayDocumentsCard: PropTypes.shape({
      heading: PropTypes.string.isRequired,
      text: PropTypes.string,
      subText: PropTypes.string.isRequired,
      paxDocRelationNewState: PropTypes.shape([
        PropTypes.shape({
          heading: PropTypes.string.isRequired,
          paxName: PropTypes.string.isRequired,
          docs: PropTypes.string.isRequired,
          docsConditions: PropTypes.string
        })
      ]),
      buttons: PropTypes.shape([
        PropTypes.shape({
          text: PropTypes.string.isRequired
        })
      ])
    }).isRequired
  }).isRequired,
  handleButtonClick: PropTypes.func.isRequired
};

const styles = {
  cardBorder: {
    borderTopColor: '#e4e4e4',
    borderBottomColor: '#e4e4e4',
    borderTopWidth: 1,
    borderBottomWidth: 1
  },
  infoCardWrapper: {
    backgroundColor: '#fff',
    justifyContent: 'space-between',
    flexDirection: 'row',
    ...getPlatformElevation(2),
    marginBottom: 1
  },

  infoCardContent: {
    paddingRight: 8,
    paddingVertical: 15,
    flex: 1
  },

  infoCustom: {
    fontSize: 14,
    color: '#4a4a4a',
    lineHeight: 18,
    fontFamily: fonts.regular
  },
  headingIconWrapper: {
    width: 46,
    alignItems: 'center'
  },
  headingIconStyle: {
    width: 16,
    height: 18,
    top: 15
  },

  redirectBtnWrapper: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 50,
    paddingRight: 35,
    justifyContent: 'space-between',
    flexDirection: 'row',
    backgroundColor: '#fff',
    borderBottomWidth: 1,
    borderBottomColor: '#ededed',
    alignItems: 'center'
  },

  rightArrowIcon: {
    width: 9,
    height: 13
  },

  linkBtnTxt: {
    fontSize: 14,
    fontFamily: fonts.bold,
    color: '#008cff'
  },
  buttonWrapper: {
    paddingTop: 20,
    width: '90%'
  },
  tickIcon: {
    width: 14,
    height: 14,
    marginLeft: 5
  },
  missedUploadWrapper: {
    backgroundColor: '#ffd3d4',
    paddingVertical: 6,
    paddingHorizontal: 8,
    marginBottom: 12
  },
  failedText: {
    color: '#e53442',
    fontFamily: fonts.bold,
    fontSize: 14
  },
  innerDtlsBox: {
    backgroundColor: '#f2f2f2',
    padding: 12,
    borderRadius: 4,
    marginLeft: -25
  },
  headingSection: {
    borderBottomColor: '#e5e5e5',
    borderBottomWidth: 1,
    paddingBottom: 10
  },
  bulletDesc: {
    width: 5,
    height: 5,
    borderRadius: 10,
    backgroundColor: '#000',
    marginRight: 10
  },
  btnWrapper: {
    width: '92%'
  },
  pink: {
    backgroundColor: 'pink'
  },
  red: {
    backgroundColor: 'red'
  },
  yellow: {
    backgroundColor: 'yellow'
  },
  green: {
    backgroundColor: 'green'
  }
};

export default DocumentsRequiredCard;
