interface DataHolder<T> {
  set(value: T): void;

  get(): T | null;
}

export class DataHolderImpl<T> implements DataHolder<T>{
  _value: T | null = null
  get(): T | null {
    return this._value;
  }

  set(value: T): void {
    this._value = value;
  }

}

export default DataHolder;
