import {colors} from '@mmt/legacy-commons/Styles/globalStyles';

const styles = {
    cabImage: {
        width: 80,
        height: 40
    },
    fieldBox: {
        height: 48,
        borderColor: colors.grey12,
        borderWidth: 1,
        marginTop: 10,
        borderRadius: 2,
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 8,
        paddingVertical: 5,
        backgroundColor: colors.grey13
    },
    fieldBoxActive: {
        borderColor: colors.activeBorder,
        backgroundColor: colors.activeBg
    },
    addressBoxIcon: {
        height: 24,
        width: 24,
        marginRight: 8
    },
    addressBoxIconActive: {
        tintColor: colors.activeBorder
    },
    input: {
        height: 20,
        padding: 0,
        margin: 0
    },
    gpsIcon: {
        height: 21,
        width: 21,
        marginRight: 8
    }
};

export default styles;
