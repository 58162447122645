import React from "react";
import { View, Text, TouchableOpacity, TextInput } from "react-native";
import RadioButton from '../../RadioButton';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getFont } from '../../../PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import createStyles from '../styles';
import PriceDetails from "../PriceDetails";
import { getStaticData } from '../../../staticData/staticData';

interface PaymentDetailsSectionProps {
  activeState: number;
  handleAmount: (active: number, type: string) => void;
  amountType: string;
  handleAmountInput: (e: string) => void;
  amount: string;
  isMarkupEnabled: boolean;
  totalAmountCalculated: number;
  baseChargeCalculated: number;
  feesSurcharges: number;
  currency: string;
}

const PaymentDetailsSection: React.FC<PaymentDetailsSectionProps> = ({
  activeState,
  handleAmount,
  amountType,
  handleAmountInput,
  amount,
  isMarkupEnabled,
  totalAmountCalculated,
  baseChargeCalculated,
  feesSurcharges,
  currency,
}) => {
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme);
  const { customerConfirmPopupText } = getStaticData(false);
  const { additionalText, showEnterPercentage, showEnterAbsolute, items } = customerConfirmPopupText || {};
  const { markupOptions } = items || {};
  const handleOptionPress = (optionId: number, optionSymbol: string) => {
    handleAmount(optionId, optionSymbol || currency || '');
  };
  return (
    <View style={styles.paymentDetailsWrapper}>
      <View
        pointerEvents={!isMarkupEnabled ? "none" : "auto"}
        style={!isMarkupEnabled ? styles.disabledContainer : null}
      >
        <View style={styles.borderBottom}>
          <View style={styles.container}>
            {markupOptions.map((option, index) => (
              <View key={option?.id} style={[styles.radioContainer, AtomicCss.marginBottom10]}>
                <RadioButton
                  selected={option?.id === activeState}
                  onChange={() => handleOptionPress(option?.id, option?.symbol)}
                  radioOuterStyle={option?.id === activeState ? styles.radioBgActive : styles.radioBg}
                  radioInnerStyle={option?.id === activeState ? styles.radioActive : null}
                />
                <TouchableOpacity onPress={() => handleOptionPress(option?.id, option?.symbol)}>
                  <Text style={[fsStyle.font12, styles.optionValue, option.id === activeState ? [fonts.boldFontFamily, styles.textBlack,] : fonts.regularFontFamily]}>
                    {option?.value}
                  </Text>
                </TouchableOpacity>
              </View>
            ))}
          </View>
          <View style={styles.vCodeSection}>
            <View style={styles.copyCodeSection}>
              {amountType && (
                <Text style={[styles.textGrey, fsStyle.font16, fonts.regularFontFamily]}>
                  {amountType}
                </Text>
              )}
            </View>
            <TextInput
              style={[styles.textarea, fsStyle.font12, fonts.regularFontFamily]}
              placeholder={activeState ? showEnterPercentage : showEnterAbsolute}
              onChangeText={handleAmountInput}
              value={amount || ''}
            />
          </View>
          <Text style={[styles.defaultText, fsStyle.font11, fonts.regularFontFamily]}>
            {additionalText?.markupInputInfo}
          </Text>
        </View>
        <PriceDetails
          totalAmountCalculated={totalAmountCalculated}
          baseChargeCalculated={baseChargeCalculated}
          feesSurcharges={feesSurcharges}
          currency={currency}
        />
      </View>
    </View>
  );
};

export default PaymentDetailsSection;
