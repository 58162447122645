import Header from "../../Common/HeaderCross";
import {ScrollView, StyleSheet, View} from "react-native";
import React from "react";
import PrimoBanner from "./PrimoBanner";
import {colors} from '@mmt/legacy-commons/Styles/globalStyles';

const PrimoViewMore = ({response}) => {
    return(
        <View  style={styles.priceBreakupWrapper}>
            <Header
                title=""
                headerShadow
                headingTxt="font18"
                handleClose={this.onBackPress}
            />
            <ScrollView style={{marginTop: 10}}>
                <PrimoBanner response={response}
                             header={response.busAdditionalInfo.reviewDetail.extraData.primoFeatures.heading}
                    subheader={response.busAdditionalInfo.reviewDetail.extraData.primoFeatures.description}
                             showAllFeatures = {true}
                />
            </ScrollView>
        </View>

    )
}
const styles = StyleSheet.create({
    priceBreakupWrapper: {
        flex: 1,
        justifyContent: 'space-between',
        backgroundColor: colors.grayBg
    },
})
export  default PrimoViewMore;
