export const LOGIN_EVENT = 'login_event';
export const MOBILE_VERIFIED_EVENT = 'mobile_verified_event';

export const LANGUAGE_MAP = {
  en: 'eng',
  eng: 'eng',
  hi: 'hin',
  hin: 'hin',
  ara: 'ara',
  tam: 'tam', // Tamil
};

export const COMMON_ACTIONS = {
  RESET_ALL_REDUX_STATE:'@MMT/RESET_ALL_REDUX_STATE'
}

export const COMMON_KEYS = {
  REDUX_STATE_RESET_DONE:'mmtReduxStateResetDone'
}
export const PROFILE_TYPE = {
  PERSONAL: "PERSONAL",
  BUSINESS: "BUSINESS",
  CORPORATE:"CORPORATE"
}

export const LOBS = {
  BUS: 'Bus',
  CAB: 'Cab',
  TRAIN: 'Train',
  BUS_TRAIN:'Bus/Train'
}

export const LOBS_WITH_MYBIZ = [LOBS.BUS, LOBS.CAB];

export const gdprBlockScreenPayload = {
  title: 'bookings are temporarily unavailable for users in your region',
  header:
    'We apologize for the inconvenience. Currently, MakeMyTrip offers Flight, Hotel and Holiday Package bookings in your region. Please visit the website homepage.<br><br><br><b>In case you have any queries about your existing or ongoing bookings made on MakeMyTrip, kindly manage your booking here.</b>',
  logo: 'https://imgak.mmtcdn.com/pwa_v3/pwa_hotel_assets/header/logo@2x.png',
  cta: [
    {
      text: 'GO BACK TO HOMEPAGE',
      link: 'mmyt://app/home/?isCorporate=false&region=in',
      trackingKey: 'home',
    },
    {
      text: 'MANAGE BOOKINGS',
      link: 'mmyt://mytrips?isCorporate=false',
      trackingKey: 'manage_booking',
    },
    {
      text: 'CONTACT US',
      link: 'mmyt://app/callus/?01244628747',
      trackingKey: 'contactus',
    },
  ],
};

export const gdprBlockScreenPayloadForB2B = {
  title: 'bookings are temporarily unavailable for users in your region',
  header:
    'We apologize for the inconvenience. Currently, MakeMyTrip offers Flight, Hotel and Holiday Package bookings in your region. Please visit the website homepage.<br><br><br><b>In case you have any queries about your existing or ongoing bookings made on MakeMyTrip, kindly manage your booking here.</b>',
  logo: 'https://imgak.mmtcdn.com/pwa_v3/pwa_hotel_assets/header/logo@2x.png',
  cta: [
    {
      text: 'GO BACK TO HOMEPAGE',
      link: 'mmyt://app/home/?isCorporate=true&region=in',
      trackingKey: 'home',
    },
    {
      text: 'MANAGE BOOKINGS',
      link: 'mmyt://mytrips?isCorporate=true',
      trackingKey: 'manage_booking',
    },
    {
      text: 'CONTACT US',
      link: 'mmyt://app/callus/?01244628747',
      trackingKey: 'contactus',
    },
  ],
};
