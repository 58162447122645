import React from 'react';
import { View, Text, Image } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import isEmpty from 'lodash/isEmpty';
import { useTheme } from '../../../../../theme/theme.context';
import createStyles from './style';
import { getFont } from '../../../../../PostSalesStyles';

const ContactCard = ({ heading, description, contactInformationList }) => {
  const fonts = getFont();
  const { psTheme: theme } = useTheme();
  const styles = createStyles(fonts, theme);

  return (
    <View style={styles.innerCardWrapper}>
      {!isEmpty(heading) && <Text style={styles.headingText}>{heading}</Text>}
      {!isEmpty(description) && <Text style={styles.descriptionText}>{description}</Text>}
      {!isEmpty(contactInformationList) &&
        contactInformationList.map((item) => (
          <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom8]}>
            {!isEmpty(item.iconUrl) && (
              <Image style={styles.contactIconStyle} source={{ uri: item.iconUrl }} />
            )}
            {!isEmpty(item.value) && <Text style={styles.contactValue}>{item.value}</Text>}
          </View>
        ))}
    </View>
  );
};

export default ContactCard;
