import React from 'react';
import {View, Text, Image, TouchableOpacity, ScrollView, FlatList} from 'react-native';
import styles from './CabInfoOverlayCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {CROSS_ICON} from '../../../SelfDriveImageConstant';

const CabInfoOverlay = (props) => {
    const {model} = props.response.vehicleInfo;
    return (
        <React.Fragment>
            <View style={styles.overlay} onPress={() => this.props.handlePress('')}/>
            <View style={styles.bottomOverlayWrapper}>
                <View style={[AtomicCss.flexRow, AtomicCss.marginBottom30]}>
                    <View style={AtomicCss.flex1}>
                        <Text
                            style={[AtomicCss.greyText, AtomicCss.font14, AtomicCss.marginBottom6, AtomicCss.textUppercase]}>All
                            about your car</Text>
                        <Text
                            style={[AtomicCss.blackText, AtomicCss.font20, AtomicCss.blackFont, AtomicCss.marginBottom12]}>{model}</Text>
                        <View style={styles.underline}/>
                    </View>
                    <TouchableOpacity onPress={() => props.handlePress('')}><Image style={styles.crossIcon}
                                                                                   source={CROSS_ICON}/></TouchableOpacity>
                </View>
                <View>
                    <FlatList
                        numColumns={2}
                        style={AtomicCss.marginBottom20}
                        data={props.response.vehicleInfo.knowMore}
                        renderItem={({item}) =>
                            <View style={[styles.cabInfolist, AtomicCss.flexRow]}>
                                <View style={styles.listDot}/>
                                <Text>{item}</Text>
                            </View>
                        }
                    />
                </View>
                <ScrollView style={[styles.locationImgOuter, AtomicCss.marginBottom35]} horizontal={true}>
                    <Image style={styles.locationImg} source={{uri: props.response.vehicleInfo.vendorImageUrl}}/>
                </ScrollView>

                <TouchableOpacity onPress={() => props.handlePress('')}>
                    <View style={AtomicCss.marginTop15}><Text
                        style={[AtomicCss.alignRight, AtomicCss.azure, AtomicCss.blackFont, AtomicCss.font16, AtomicCss.textUppercase]}>OKAY,
                        GOT IT</Text></View>
                </TouchableOpacity>
            </View>
        </React.Fragment>
    );
}


export default CabInfoOverlay;
