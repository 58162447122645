import { StyleSheet } from 'react-native';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';

export default (theme, fonts) =>
  StyleSheet.create({
    bookingNotificationWrapper: {
      ...getPlatformElevation(2),
      zIndex: 11,
      marginRight: 20,
      marginLeft: 5,
    },
    myBizNotification: {
      borderRadius: 17,
      backgroundColor: theme.color.red12,
      paddingVertical: 4,
      paddingHorizontal: 10,
    },
    myBizLogo: {
      width: 30,
      height: 13,
    },
    notification: {
      width: 18,
      height: 18,
      backgroundColor: theme.color.red,
      borderRadius: 20,
      alignItems: 'center',
      position: 'absolute',
      justifyContent: 'center',
      right: -5,
      top: -10,
      zIndex: 999,
    },
    notificationText: {
      color: theme.color.white,
      fontSize: 8,
   ...(fonts.regularFontFamily),
    },

    tooltipWrapper: {
      borderRadius: 4,
      padding: 8,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      position: 'absolute',
      backgroundColor: theme.color.green,
      width: 195,
      right: 15,
      top: 45,
      ...getPlatformElevation(4),
      zIndex: 11,
      },
    notificationInfoTxt: {
      color: theme.color.white,
      fontSize: 10,
      ...(fonts.regularFontFamily),
      maxWidth: 175,
    },
    crossIconWrapper: {
      width: 20, 
      height: 20,
      alignItems: 'center',
    },
    crossIconWhite: {
      tintColor: theme.color.white,
      width: 14,
      height: 14,
    },
    arrowWrapper: {
      position: 'absolute',
      top: -6,
      right: 10,
    },
    arrowStyle: {
      width: 19,
      height: 8,
    },
  });
