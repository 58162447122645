import React from 'react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import {View, Text, StyleSheet} from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {removeTags} from '../HolidayBookingDetailUtils';


class IncExcItem extends React.Component {
  static navigationOptions = {
    header: null
  };

  render() {
    const {item} = this.props;
    return (
      <View style={[styles.CollapsedTapDtlsContainer1]}>
        {item.data.map((itemLabel, index) => {
          const label = removeTags(itemLabel);
          if (isEmpty(label)) {
            return (
              <View/>
            );
          } else {
            return (
              <View style={[styles.answerRow, AtomicCss.marginBottom10]}>
                <Text style={styles.bulletsPoints}>{'\u2B24'}</Text>
                <View style={AtomicCss.flex1}>
                  <Text
                    style={[AtomicCss.lineHeight20, AtomicCss.font14,
                      AtomicCss.regularFont, AtomicCss.defaultTextColor]}>
                    {label}
                  </Text>
                </View>
              </View>
            );
          }
        })
        }
      </View>
    );
  }
}

const styles = StyleSheet.create({
  CollapsedTapDtlsContainer1: {
    backgroundColor: 'transparent',
    paddingHorizontal: 10,
    borderTopWidth: 1,
    borderColor: '#e4e4e4',
    paddingVertical: 10
  },
  answerRow: {
    flexDirection: 'row',
    marginLeft: 10
  },
  bulletsPoints: {
    fontSize: 6,
    paddingHorizontal: 10,
    marginTop: 5,
    color: '#4a4a4a'
  }
});

IncExcItem.propTypes = {
  item: PropTypes.object.isRequired
};

export default IncExcItem;
