/* eslint-disable max-len */
import {Alert, Linking, Platform, Share} from 'react-native';
import Actions from '../../../navigation/postSalesNavigation';
import Fecha from 'fecha';
import React from 'react';
import _, { isEmpty } from 'lodash';
import {
  CHANNEL,
  ENTITY_TYPES,
  HOUR_IN_MILLIS,
  MYRA_BASE_URL,
  MYRA_INTENTS,
  MYTA_CHAT_OWNER,
  PAGE_IDENTIFIER,
  SECOND_IN_MILLIS,
  USERID,
  UPCOMING_STATE,
  ON_TRIP_STATE,
} from '../../../PostSalesConstant';
import HotelBookingDetailsConstant, { DOWNLOAD_FILE_TYPE, ERROR_TYPES, DOWNLOAD_FILE_TRIGGER_NAME, HOTEL_DETAILS_BOTTOM_OVERLAY, PAGE_NUMBER, PAX_TYPE, TRIGGER_NAME_BASED_URL } from '../HotelBookingDetailsConstant';
import HotelBookingTrackingHelper from '../../HotelBookingTrackingHelper';
import RectFullButton from '../../../Common/ActionButton/RectFullButton';
import RoundButtonWithIcon from '../../../Common/ActionButton/RoundButtonWithIcon';
import getStyles from '../components/HotelMamiCss';
import CapsuleBtnFill from '../../../Common/ActionButton/CapsuleBtnFill';
import CapsuleBtn from '../../../Common/ActionButton/CapsuleBtn';
import WhiteFullCapsuleButton from '../../../Common/ActionButton/WhiteFullCapsuleButton';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import FlightBookingModule from '@mmt/legacy-commons/Native/FlightBookingModule';
import { getCommonHeaders, HOTEL_BOOKING_CANCELLATION_URL, HOTEL_BOOKING_DETAILS_URL, HOTEL_GET_INVOICE_URL } from '../../../utils/NetworkUtils';
import {
  AbConfigKeyMappings,
  getPokusConfig,
} from '@mmt/legacy-commons/Native/AbConfig/AbConfigModule';
import logAction from '@mmt/legacy-commons/Helpers/actionsLogger';
import BusBookingDetailsConstant from '../../../bus/BusBookingDetailsConstant';
import COMMON_CONSTANT from '../../../Common/commonConstant';
import {isCorporateBooking, isIosClient, setWebViewAuth} from '../../../utils/PostSaleUtil';
import CabBookingDetailsConstant from '../../../cab/CabBookingDetailsConstant';
import FlightBookingDetailsConstant from '../../../flights/modules/details/FlightBookingDetailsConstant';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import { showLongToast } from '@mmt/legacy-commons/Common/Components/Toast';
import { joinStrings } from '@mmt/legacy-commons/Common/utils/StringUtils';
import MetroConstant from '../../../Metro/MetroConstant';
import {
  fetchUrlParam,
  openGoogleMap,
  openGoogleMapAlways,
  copyToClipboard,
  openDialer,
  isEasyPayNativeFlowEnabled,
} from '../../../Common/commonUtil';
import { PokusLobs } from '@mmt/legacy-commons/Native/AbConfig/AbConfigKeyMappings';
import RailBookingDetailConstant from "../../../Rails/details/RailBookingDetailConstant";
import {
  CALL_BLUE_BG_ICON, ROUNDED_CHAT_BLUE_BG_ICON, RESEND_BLUE_BG_ICON,
  ID_BLUE_BG_ICON, CHECKIN_BLUE_BG_ICON, HEADSET_BLUE_BG_ICON, LETTER_INSTRUCTIONS_BLUE_BG_ICON,
  CHAT_BLUE_BG_ICON, WISH_WAND_ICON, HELP_BLUE_BG_ICON, LIVE_TRACKING_ICON,
  TICKET_SUCCESS_BLUE_BG_ICON, TRAIN_COACH_ICON, WHITE_LEFT_ARROW_ICON, WHITE_MAP_ICON,
  BLUE_PHONE_ICON, WHITE_DOWNLOAD_ICON, HOSTEL_ICON, RUPEE_SYMBOL_WHITE, BOOKING_INVOICE, BILL_MEMO
} from '../../HotelImageConstants';
import { ENGLISH, getStaticData } from '../../../staticData/staticData';
import { getQueryParamsFromUrl } from '@mmt/legacy-commons/Helpers/misc';
import RoundButton, { BUTTON_VARIANT } from '../../../Common/RoundButton/RoundButton';
import RedirectLink from '../../../Common/RedirectLink';
import RedirectLinkImage from '../../../Common/RedirectLinkImage';
import RectFullBlueButton from '../../../Common/RectFullBlueButton';
import { MOD_ERROR_TYPES } from '../../modification/HotelModificationConstants';
import Toast from '@mmt/legacy-commons/Common/Components/Toast2';
import { isNetworkAvailable } from '@mmt/legacy-commons/Common/utils/AppUtils';
import createAction from '../../reducer/actionCreator';
import { HOTEL_DETAIL_ACTIONS } from '../../reducer/actions';
import { getRootTag } from '@mmt/legacy-commons/AppState/RootTagHolder';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { LANGUAGE_MAP } from 'packages/legacy-commons/Common/constants/AppConstants';
import { getUserSelectedLanguage } from 'packages/legacy-commons/AppState/AppUserConfig';
import { showShortToast } from '@mmt/legacy-commons/Common/Components/Toast';

const CARD_LAST_FOUR_DIGITS = 4;
const backIcon = WHITE_LEFT_ARROW_ICON;

export function getButtonStyle(actionList) {
  const { ...fonts } = getFont(true);
  const styles = getStyles(fonts);
  const { actionType } = actionList[0];
  if (
    actionType === HotelBookingDetailsConstant.RECTANGLE_PRIMARY_HALF_WIDTH ||
    actionType === HotelBookingDetailsConstant.RECTANGLE_SECONDARY_HALF_WIDTH ||
    actionType === HotelBookingDetailsConstant.ROUND_BUTTON
  ) {
    return [styles.ActionBtnHalf, styles.transperantBgrnd];
  } else if (actionType === HotelBookingDetailsConstant.RECTANGLE_FULL_BUTTON) {
    return [styles.ActionBtnRec, styles.transperantBgrnd];
  }
  return [styles.ActionBtn, styles.transperantBgrnd];
}


export async function resetToHotelDetail(bookingId, isRefresh = true) {
  const {mmtAuth, loggingTrackingInfo} = await HotelBookingModule.getRequestHeader(bookingId, 'HOTEL_DETAIL');
  const BOOKING_ID = bookingId;
  const MMT_AUTH = mmtAuth;
  const LOGGING_TRACKINFO = loggingTrackingInfo;
  Actions.hotelBookingDetail({BOOKING_ID, MMT_AUTH, LOGGING_TRACKINFO, refresh: { isRefresh }});
}

export function getDateFromMilliseconds(dateInMillis, outputDateFormat) {
  const date = new Date();
  date.setTime(dateInMillis);
  return Fecha.format(new Date(date), outputDateFormat);
}

function hideActionButtons(response) {
  return (!response.isRTBBooking && !response.isNegotiatedRatesBooking && response.documentBookingStatus && response.documentBookingStatus === 'InstantUnconfirmed');
}

export const getThrottleParams = (action) => {
  switch (action.actionFamily) {
    case HotelBookingDetailsConstant.ACTION_GROUP_BOOKNG_PAY_NOW:
    case HotelBookingDetailsConstant.ACTION_CANCEL_BOOKING_FOR_FREE:
    case HotelBookingDetailsConstant.ACTION_ADD_GUEST:
    case HotelBookingDetailsConstant.ACTION_EDIT_PRIMARY_GUEST_NAMES:
    case HotelBookingDetailsConstant.ACTION_GB_ADD_GUEST:
    case HotelBookingDetailsConstant.ACTION_GB_CHANGE_DATES:
    case HotelBookingDetailsConstant.ACTION_VIEW_CANCELLATION_CHARGES:
    case HotelBookingDetailsConstant.ACTION_CHANGE_DATES:
    case HotelBookingDetailsConstant.ACTION_CANCEL_FULL_BOOKING:
    case HotelBookingDetailsConstant.ACTION_AUTO_DEBIT_MANDATE:
    case HotelBookingDetailsConstant.ACTION_DOWNLOAD_VOUCHER:
    case HotelBookingDetailsConstant.ACTION_PROPERTY_DETAILS:
    case HotelBookingDetailsConstant.ACTION_OPEN_LOCATION:
      return {
        isThrottleRequired: true,
        throttleInterval: 1000
      }
    default:
      return {
        isThrottleRequired: false,
        throttleInterval: 0
      }
  }
}

export function getActionButton(card, response, clickHandler, getThrottleParams) {
  const actionButtons = [];
  const staticData = getStaticData();
  let actionBtnStyle = {};
  if (!hideActionButtons(response)) {
    for (const action of card.actionList) {
      if (Platform.OS === 'ios') {
        switch (action.actionFamily) {
          case HotelBookingDetailsConstant.ACTION_VIEW_LOCATION:
            action.actionLabeltext = staticData.openInMaps;
            break;
          case HotelBookingDetailsConstant.ACTION_ADD_TO_APPLE_WALLET:
            actionBtnStyle = { btnWidth: { width: '100%' } };
            break;
          default:
            break;
        }
      }
      const isSingleAction = !_.isEmpty(card.actionList) && card.actionList.length === 1
      if (showActionButton(card, action, response)) {
        addActionButton(card, action, actionButtons, response, clickHandler, isSingleAction, getThrottleParams, actionBtnStyle);
      }
    }
    return actionButtons;
  }
  return null;
}

function showActionButton(card, action, response) {
  switch (action.actionFamily) {
    case HotelBookingDetailsConstant.ACTION_PAY_NOW:
      return (
        (response.isBNPLBooking ||
          response.hotelBookingInfo.ispah ||
          response.ispahx) &&
        response.paymentDetails.amountDue > 0
      );
    case HotelBookingDetailsConstant.ACTION_CHAT_WITH_US:
      return !isCorporateBooking(response?.lobCode);
    case HotelBookingDetailsConstant.ACTION_CALL_HOTEL:
    case HotelBookingDetailsConstant.ACTION_VIEW_POLICY:
      const hotelDetailResponse = response.hotelBookingInfo;
      const hotelBookingInfo =
        hotelDetailResponse.hotelDetailsList[
        hotelDetailResponse.hotelDetailsList.length - 1
        ];
      const type = hotelBookingInfo.propertyType;

      if (action.actionLabeltext.includes('Hotel') && type) {
        action.actionLabeltext = action.actionLabeltext.replace('Hotel', type);
      }
      return true;
    case HotelBookingDetailsConstant.ACTION_VIEW_HOUSE_RULES:
      return getHouseRules(response) && Object.keys(getHouseRules(response)).length > 0;
    case HotelBookingDetailsConstant.ACTION_WRITE_HOTEL_REVIEW:
      return Platform.OS !== "web"
    case HotelBookingDetailsConstant.ACTION_OPEN_LOCATION_GOOGLE_MAPS:
      // iOS -> show open in maps (generic) + open in google maps
      // android -> show open in maps (generic)
      return Platform.OS === 'ios';
    case HotelBookingDetailsConstant.ACTION_HOTEL_PHOTOS:
      return getHotelImageURLList(response) != null;
    case HotelBookingDetailsConstant.ACTION_VALID_ID_PROOF:
      return getIdProofRules(response) != null;
    case HotelBookingDetailsConstant.ACTION_CHAT_WITH_HOST_RTB:
      return response.rtbData && response.rtbData.showMessageHostBtn;
    case HotelBookingDetailsConstant.ACTION_ROOM_PHOTOS:
      return getRoomImages(response);
    case HotelBookingDetailsConstant.ACTION_PROPERTY_DETAILS:
      return showPage(response, PAGE_NUMBER.TWO);
    default:
      return true;
  }
}

function addActionButton(card, action, actionButtons, response, clickHandler, isSingleAction, getThrottleParams, actionBtnStyle) {
  const { ...fonts } = getFont(true);
  const styles = getStyles(fonts);
  let throttleParam = {
    throttleInterval: 0, isThrottleRequired: false
  }
  if (getThrottleParams && (typeof getThrottleParams === 'function')) {
    throttleParam = getThrottleParams(action);
  }
  const { throttleInterval, isThrottleRequired } = throttleParam;

  switch (action.actionType) {
    case HotelBookingDetailsConstant.RECTANGLE_FULL_BUTTON:
      actionButtons.push(<RectFullButton
        key={`RectFullButton${action.actionLabeltext}`}
        card={card}
        action={action}
        response={response}
        clickHandler={clickHandler}
        throttleInterval={throttleInterval}
        isThrottleRequired={isThrottleRequired}
      />);
      break;
    case HotelBookingDetailsConstant.RECTANGLE_PRIMARY_FULL_WIDTH:
    case HotelBookingDetailsConstant.RECTANGLE_PRIMARY_HALF_WIDTH:
      actionButtons.push(<CapsuleBtnFill
        key={`CapsuleBtnFill${action.actionLabeltext}`}
        card={card}
        action={action}
        response={response}
        isCorporateBooking={response.isCorporateBooking}
        clickHandler={clickHandler}
        throttleInterval={throttleInterval}
        isThrottleRequired={isThrottleRequired}
      />);
      break;
    case HotelBookingDetailsConstant.RECTANGLE_SECONDARY_FULL_WIDTH:
    case HotelBookingDetailsConstant.RECTANGLE_SECONDARY_HALF_WIDTH:
      actionButtons.push(
        <CapsuleBtn
          key={`CapsuleBtn${action.actionLabeltext}`}
          card={card}
          action={action}
          response={response}
          clickHandler={clickHandler}
          throttleInterval={throttleInterval}
          isThrottleRequired={isThrottleRequired}
        />,
      );
      break;
    case HotelBookingDetailsConstant.ROUND_BUTTON:
      actionButtons.push(
        <RoundButtonWithIcon
          key={`RoundButtonWithIcon${action.actionLabeltext}`}
          card={card}
          action={action}
          response={response}
          clickHandler={clickHandler}
          throttleInterval={throttleInterval}
          isThrottleRequired={isThrottleRequired}
        />,
      );
      break;
    case HotelBookingDetailsConstant.WHITE_BUTTON_FULL:
    case HotelBookingDetailsConstant.WHITE_BUTTON_HALF:
      actionButtons.push(<WhiteFullCapsuleButton
        key={`WhiteFullCapsuleButton${action.actionLabeltext}`}
        card={card}
        action={action}
        response={response}
        clickHandler={clickHandler}
        throttleInterval={throttleInterval}
        isThrottleRequired={isThrottleRequired}
      />);
      break;
    case HotelBookingDetailsConstant.ROUND_BUTTON_BLUE:
      actionButtons.push(<RoundButton
        key={`RoundButton${action.actionLabeltext}`}
        card={card}
        action={action}
        response={response}
        isCorporateBooking={response.isCorporateBooking}
        clickHandler={clickHandler}
        varient={BUTTON_VARIANT.BLUE}
        btnWidth={isSingleAction ? styles.btnMainWrapper : styles.width48}
        iconColor="white"
        throttleInterval={throttleInterval}
        isThrottleRequired={isThrottleRequired}
      />);
      break;
    case HotelBookingDetailsConstant.RECTANGLE_FULL_BUTTON2:
      actionButtons.push(<RedirectLink
        key={`RedirectLink${action.actionLabeltext}`}
        card={card}
        action={action}
        response={response}
        clickHandler={clickHandler}
        throttleInterval={throttleInterval}
        isThrottleRequired={isThrottleRequired}
        customStyle={styles.redirectLinkNewCustomStyles}
      />);
      break;
    case HotelBookingDetailsConstant.REDIRECT_LINK_ICON:
      actionButtons.push(<RedirectLinkImage
        key={`RedirectLink${action.actionFamily}`}
        card={card}
        action={action}
        response={response}
        clickHandler={clickHandler}
      />);
      break;
    case HotelBookingDetailsConstant.ROUND_BUTTON_LIGHT_BLUE:
      actionButtons.push(<RoundButton
        key={`RoundButton${action.actionLabeltext}`}
        card={card}
        action={action}
        response={response}
        isCorporateBooking={response.isCorporateBooking}
        clickHandler={clickHandler}
        varient={BUTTON_VARIANT.LIGHT_BLUE}
        btnWidth={actionBtnStyle?.btnWidth || styles.width48}
        iconColor="blue"
        throttleInterval={throttleInterval}
        isThrottleRequired={isThrottleRequired}
      />);
      break;
    case HotelBookingDetailsConstant.ROUND_BUTTON_WHITE_WITH_BORDER:
      actionButtons.push(<RoundButton
        key={`RoundButton${action.actionLabeltext}`}
        card={card}
        action={action}
        response={response}
        isCorporateBooking={response.isCorporateBooking}
        clickHandler={clickHandler}
        varient={BUTTON_VARIANT.WHITE_WITH_BORDER}
        btnWidth={styles.width48}
        iconColor="blue"
        throttleInterval={throttleInterval}
        isThrottleRequired={isThrottleRequired}
      />);
      break;
    case HotelBookingDetailsConstant.FULL_ROUND_BUTTON_WHITE_WITH_BORDER:
      actionButtons.push(<RoundButton
        key={`RoundButton${action.actionLabeltext}`}
        card={card}
        action={action}
        response={response}
        isCorporateBooking={response.isCorporateBooking}
        clickHandler={clickHandler}
        varient={BUTTON_VARIANT.WHITE_WITH_BORDER_WITHOUT_ICON}
        btnWidth={styles.width100per}
      />);
      break;
    case HotelBookingDetailsConstant.RECTANGLE_BLUE_BUTTON:
      actionButtons.push(<RectFullBlueButton
        key={`RectBlueButton${action.actionLabeltext}`}
        card={card}
        action={action}
        response={response}
        clickHandler={clickHandler}
        throttleInterval={throttleInterval}
        isThrottleRequired={isThrottleRequired}
      />);
      break;
    case HotelBookingDetailsConstant.FULL_ROUND_BUTTON_BLUE:
      actionButtons.push(<RoundButton
        key={`RoundButton${action.actionLabeltext}`}
        card={card}
        action={action}
        response={response}
        isCorporateBooking={response.isCorporateBooking}
        clickHandler={clickHandler}
        varient={BUTTON_VARIANT.BLUE}
        btnWidth={styles.btnMainWrapper}
        iconColor="white"
        throttleInterval={throttleInterval}
        isThrottleRequired={isThrottleRequired}
      />);
      break;
    default:
      break;
  }
}

export function fillCheckInCheckOutData(date, outputDateFormat) {
  if (isNotNullAndEmpty(date)) {
    const dateObject = Fecha.parse(date.split('T')[0], 'YYYY-MM-DD');
    return Fecha.format(new Date(dateObject), outputDateFormat);
  }
  return date;
}

export function getPreviousDate(date, outputDateFormat) {
  const dateObj = Fecha.parse(date.split('T')[0], 'YYYY-MM-DD');
  const result = dateObj.getTime() - 86400000;
  dateObj.setTime(result);
  return Fecha.format(dateObj, outputDateFormat);
}

export const appendRupeeSymbol = (amount) => {
  try {
    return `₹ ${amount}`;
  } catch (e) {
    console.log('err', e);
  }
};
export const appendRupeeSymbolAndRound = (amount) => {
  try {
    return `₹ ${Math.round(amount)}`;
  } catch (e) {
    console.log('err', e);
  }
};

export function appendZero(number) {
  if (number < 10) {
    return `0${number}`;
  }
  return number;
}

export function getPreviousDateInMilliseconds(date) {
  const dateObj = Fecha.parse(date.split('T')[0], 'YYYY-MM-DD');
  return dateObj.getTime();
}

export function showRoomAminities(objectList, limit) {
  let resultString = '';
  if (objectList) {
    for (let index = 0; index < objectList.length; index++) {
      if (index > limit - 1) {
        break;
      } else if (index === limit - 1) {
        resultString += `${objectList[index]}`;
      } else {
        resultString += `${objectList[index]}, `;
      }
    }
  }

  return resultString;
}

export function showViewMoreText(objectList, limit) {
  if (objectList && objectList.length > limit) {
    return objectList.length - limit;
  }
}

export function fillDateAndTime(date, outputDateFormat) {
  let resultDate = '';
  const dateObject = Fecha.parse(date.split('T')[0], 'YYYY-MM-DD');
  resultDate = `${Fecha.format(new Date(dateObject), outputDateFormat)} ${
    date.split('T')[1]
  }`;
  return resultDate;
}

export function findDaysBetweenDates(firstDate, secondDate) {
  const oneDay = 24 * 60 * 60 * 1000;
  const secondDateObj = Fecha.parse(secondDate.split('T')[0], 'YYYY-MM-DD');
  return Math.round(Math.abs((secondDateObj.getTime() - firstDate) / oneDay));
}

export function findHoursBetweenDates(firstDate, secondDate) {
  const secondDateObj = Fecha.parse(
    secondDate.toString().replace('T', ' '),
    'YYYY-MM-DD HH:mm:ss',
  );
  const secs = (secondDateObj.getTime() - firstDate) / SECOND_IN_MILLIS;
  const hours = secs / HOUR_IN_MILLIS;
  return Math.round(Math.abs(hours));
}

export function getTimeInMillisecond(date) {
  const dateObj = Fecha.parse(date.split('T')[0], 'YYYY-MM-DD');
  return dateObj.getTime();
}

export function isNotNullAndEmpty(object) {
  return object != null && object.length > 0;
}

export const csvToListHelper = (csv) =>
  isNotNullAndEmpty(csv) ? csv.split(',') : [];
export const listToCSVHelper = (list) =>
  isNotNullAndEmpty(list) ? list.join(',') : '';

export function isNullOrEmpty(object) {
  return object == null || object.length === 0;
}

const getPreBookChatParams = (hostDetails) => {
  const { preChatWithHostUrl: url } = hostDetails;
  if (isNullOrEmpty(url)) return {};
  const params = getQueryParamsFromUrl(url);
  return params;
}

export const openMyraWeb = (paramMap) => {
  let myraUrl = MYRA_BASE_URL;
  const allParamMap = { channel: 'PWA', ...paramMap };
  Object.entries(allParamMap).forEach(([key, value]) => {
      console.log(key, value);
      myraUrl += `${key}=${value}&`
  });
  window.open(myraUrl, '_self');
}

export function openChatWithHost(response) {
  const bookingId = response.bookingID;
  const isSessionPresent =
    response.hostDetails && response.hostDetails.isSessionPresent;
  const paramMap = {
    entityType: ENTITY_TYPES.POST_SALES_BOOKING_HOTEL,
    entityKey: bookingId,
    pageIdentifier: PAGE_IDENTIFIER.HOTEL_DETAILS_PAGE,
    openChat: true,
    chatOwner: MYTA_CHAT_OWNER.HOST,
    intent: MYRA_INTENTS.CHAT_WITH_HOST_INTENT
  };
  const preChatParams = getPreBookChatParams(response.hostDetails);
  const params = {...paramMap, ...preChatParams};
  if (Platform.OS === 'web') {
    openMyraWeb(params);
  } else {
    GenericModule.openMyraFromParams(params);
  }
}

export function openHostChatWeb(url) {
  const params = !isNullOrEmpty(url) ? getQueryParamsFromUrl(url) : {};
  if (Platform.OS === 'web') {
    openMyraWeb(params);
  } else {
    GenericModule.openMyraFromParams(params);
  }
}

export const handleBHFFormBannerClick = async (url, source, pageName, response, webViewHeader) => {
  const userInfo = await HotelBookingModule.getRequestHeader(response.bookingID, `BHF_FORM_${webViewHeader}`);
  Actions.openPsWebView({
    url,
    headerText: webViewHeader,
    headerIcon: backIcon,
    injectedJavaScript: setWebViewAuth(userInfo.mmtAuth)
  });
  HotelBookingTrackingHelper.trackClickEvent(
    pageName,
    `bhf_mytrips_card_click`,
    response,
  );
};

export const handleContactLessCheckinClick = (
  url,
  status,
  pageName,
  response,
) => {
  const staticData = getStaticData();
  Actions.openPsWebView({
    url,
    headerText: staticData.contactlessCheckInText,
    headerIcon: backIcon,
  });
  HotelBookingTrackingHelper.trackClickEvent(
    pageName,
    `MI_HTL_Contactless_${status}_clicked`,
    response,
  );
};

export const  getFeedbackReviewModel = (response) => {
  const { hotelBookingInfo, bookingID: transaction_id, paymentDetails } = response || {};
  const { hotelDetailsList = [], primaryCustomerDetails, checkInDate, checkOutDate } = hotelBookingInfo || {};
  const hotelDetails = hotelDetailsList[0];

  const { name: hotel_name, code: hotel_id, cityCode, country: countryCode, city } = hotelDetails || {};
  const { eMailID: user_email, firstName = '', lastName = '' } = primaryCustomerDetails || {};
  const { paymentAmount: room_tariff }  = paymentDetails || {};

  const user_name = `${firstName} ${lastName}`.trim();
  const action = '25';
  const check_in_date = fillCheckInCheckOutData(checkInDate, 'DD MMM YYYY');
  const check_out_date =  fillCheckInCheckOutData(checkOutDate, 'DD MMM YYYY');
  const hotel_address = `${city} ${countryCode}`;

  return {
    hotel_name, hotel_id, user_email, user_name,
    transaction_id, action, cityCode, countryCode,
    check_in_date, check_out_date, room_tariff, hotel_address
  };
}

export const shouldBlockOnlineModification = (responseJson, userInfoResponse) => {
  const isInternationalBooking = responseJson.isInternational;
  return userInfoResponse && userInfoResponse.blockODCLobs &&
    ((!isInternationalBooking ) ||
      (isInternationalBooking )
    );
}

const checkForChargeBack = (isChargeBackApplied, chargeBackNote) => {
  if (isChargeBackApplied && !_.isEmpty(chargeBackNote) && !_.isEmpty(chargeBackNote.text)) {
    showLongToast(chargeBackNote.text)
    return true;
  }
}

export async function handleClickEvent(card, action, response, pageName, langAware = true, userInfoResponse) {
  const staticData = getStaticData(langAware);
  const {isChargeBackApplied, chargeBackNote} = response;
  logAction(`${pageName} ${card.cardId} ${action.actionFamily}clicked`);
  const clickEvent = `MI_HTL_${action.actionFamily}`;
  switch (action.actionFamily) {
    case HotelBookingDetailsConstant.ACTION_ADD_GUEST:
      if(checkForChargeBack(isChargeBackApplied, chargeBackNote)){
         return
      }
      if (!response.isModificationAllowed) {
        action.actionHandler && action.actionHandler(HOTEL_DETAILS_BOTTOM_OVERLAY.TCS_MODIFICATION_OVERLAY);
        return;
      }
      if (action.actionAlertHeaderText && action.actionAlertMainText) {
        Alert.alert(action.actionAlertHeaderText, action.actionAlertMainText);
      } else {
        Actions.addGuest({
          bookingId: response.bookingID,
          card: card,
          userInfo: userInfoResponse,
        });
        HotelBookingTrackingHelper.trackClickEvent(
          pageName,
          clickEvent,
          response,
        );
      }
      break;

    case HotelBookingDetailsConstant.ACTION_CANCEL_PARTIAL_BOOKING:
    case HotelBookingDetailsConstant.ACTION_CHECK_IN_AT_HOTEL:
    case HotelBookingDetailsConstant.ACTION_RATE_YOUR_STAY:
    case HotelBookingDetailsConstant.ACTION_VIEW_ALL:
    case HotelBookingDetailsConstant.ACTION_VIEW_DATE_CHANGE_CHARGES:
      Alert.alert(
        '',
        staticData.thisFunctionalityIsCommingSoonText +
        '\n' +
        staticData.forTheTimeBeingCallTheHotelDirectlyText,
      );
      HotelBookingTrackingHelper.trackClickEvent(
        pageName,
        clickEvent,
        response,
      );
      break;
    case HotelBookingDetailsConstant.ACTION_VIEW_MODIFICATION_DETAILS:
    case HotelBookingDetailsConstant.ACTION_REFUND_CALCULATION:
    case HotelBookingDetailsConstant.ACTION_VIEW_REGULAR_REFUND_DETAILS:
      Alert.alert('', staticData.thisFunctionalityIsCommingSoonText);
      HotelBookingTrackingHelper.trackClickEvent(
        pageName,
        clickEvent,
        response,
      );
      break;
    case HotelBookingDetailsConstant.ACTION_ADD_MEAL:
    case HotelBookingDetailsConstant.ACTION_UPGRADE_ROOM:
      if (action.actionAlertHeaderText && action.actionAlertMainText) {
        const hotelDetailResponse = response.hotelBookingInfo;
        const hotelBookingInfo =
          hotelDetailResponse.hotelDetailsList[
          hotelDetailResponse.hotelDetailsList.length - 1
          ];
        const { propertyType = staticData.propertyCapsText } = hotelBookingInfo;
        Alert.alert(action.actionAlertHeaderText, action.actionAlertMainText, [
          {
            text: `${staticData.callCapsText} ${propertyType}`.toUpperCase(),
            onPress: () => {
              openCallHotel(
                card,
                { ...action, actionFamily: 'CallHotel' },
                response,
                pageName,
              );
              HotelBookingTrackingHelper.trackClickEvent(
                pageName,
                `${clickEvent}_callhotel`,
                response,
              );
            },
            style: 'cancel',
          },
          {
            text: staticData.goBackCapsText,
            onPress: () => {
              HotelBookingTrackingHelper.trackClickEvent(
                pageName,
                `${clickEvent}_goback`,
                response,
              );
            },
          },
        ]);
      } else {
        Alert.alert('', staticData.thisFunctionalityIsCommingSoonText);
      }
      HotelBookingTrackingHelper.trackClickEvent(
        pageName,
        clickEvent,
        response,
      );
      break;
    case HotelBookingDetailsConstant.ACTION_REQUEST_EARLY_CHECK_IN:
      openSpecialRequest(HotelBookingDetailsConstant.EARLY_CHECKIN_REQUEST_KEY, response, langAware);
      HotelBookingTrackingHelper.trackClickEvent(pageName, clickEvent, response);
      break;
    case HotelBookingDetailsConstant.ACTION_ADD_OTHER_GUEST_NAMES:
      Actions.addGuestNames({
        response,
        bookingId: response.bookingID,
        card: card,
        userInfo: userInfoResponse,
      });
      HotelBookingTrackingHelper.trackClickEvent(
        pageName,
        clickEvent,
        response,
      );
      break;
    case HotelBookingDetailsConstant.ACTION_CHANGE_DATES:
      const cardName = card.cardId?.split('_')?.[0];
      HotelBookingTrackingHelper.trackClickEvent(pageName, `${clickEvent}_${cardName}`, response);
      if (checkForChargeBack(isChargeBackApplied, chargeBackNote)) {
        return
      }
      if(action && typeof action.actionHandler === 'function'){
        if (!response.isModificationAllowed) {
          action.actionHandler(HOTEL_DETAILS_BOTTOM_OVERLAY.TCS_MODIFICATION_OVERLAY);
          return;
        }
        action.actionHandler(HOTEL_DETAILS_BOTTOM_OVERLAY.CALENDAR_OVERLAY, response);
        return;
      } else {
        handleDateChangeClick(action, response, card, userInfoResponse);
      }
      break;
    case HotelBookingDetailsConstant.ACTION_DOWNLOAD_CUSTOMER_VOUCHER:
      action.actionHandler(HOTEL_DETAILS_BOTTOM_OVERLAY.ACTION_DOWNLOAD_CUSTOMER_VOUCHER)
      HotelBookingTrackingHelper.trackClickEvent(
        pageName,
        clickEvent,
        response,
      );
      break
    case HotelBookingDetailsConstant.ACTION_MODIFY_NAME_CHANGE:
    case HotelBookingDetailsConstant.ACTION_MODIFY_NAME_CHANGE_NOT:
    case HotelBookingDetailsConstant.ACTION_MODIFY_NAME_LESS_TIME_WINDOW:
    case HotelBookingDetailsConstant.ACTION_MODIFY_NAME_ALREADY_SUBMITTED:
    case HotelBookingDetailsConstant.ACTION_MODIFY_NAME_ALREADY_DONE:
    case HotelBookingDetailsConstant.ACTION_EDIT_PRIMARY_GUEST_NAMES:
      if (checkForChargeBack(isChargeBackApplied, chargeBackNote)) {
        return
      }
      if (!response.isModificationAllowed) {
        action.actionHandler(HOTEL_DETAILS_BOTTOM_OVERLAY.TCS_MODIFICATION_OVERLAY);
        return;
      }
      Actions.openNameChangePage({
        response,
        card,
        pageName,
        userInfo: userInfoResponse,
      });
      break;
    case HotelBookingDetailsConstant.ACTION_CANCEL:
      Actions.hotelCancellationStandAlone({
        response,
        card,
      });
      HotelBookingTrackingHelper.trackClickEvent(
        pageName,
        clickEvent,
        response,
      )
      break;
    case HotelBookingDetailsConstant.ACTION_MODIFY:
      if (!response.isModificationAllowed) {
        action.actionHandler(HOTEL_DETAILS_BOTTOM_OVERLAY.TCS_MODIFICATION_OVERLAY);
        return;
      }
      Actions.hotelModificationStandAlone({
        response,
        card,
      });
      HotelBookingTrackingHelper.trackClickEvent(
        pageName,
        clickEvent,
        response,
      );
      break;
    case HotelBookingDetailsConstant.ACTION_VIEW_CANCELLATION_CHARGES:
      if (checkForChargeBack(isChargeBackApplied, chargeBackNote)) {
        return
      }
      action.actionHandler(HOTEL_DETAILS_BOTTOM_OVERLAY.CANCELLATION_POLICY);
      HotelBookingTrackingHelper.trackClickEvent(pageName, clickEvent, response);
      break;
    case HotelBookingDetailsConstant.ACTION_VIEW_POLICY:
      Actions.viewHotelPolicy({
        response,
      });
      HotelBookingTrackingHelper.trackClickEvent(
        pageName,
        clickEvent,
        response,
      );
      break;
    case HotelBookingDetailsConstant.ACTION_VALID_ID_PROOF:
      if (getIdProofRules(response)) {
        Actions.viewHouseRules({
          response, activeIndex: 1
        });
      } else {
        showLongToast(
          staticData.hotelBookingDetailErrorText.additionalText
            .sorryWeCouldNotFetchRequiredInformationText,
        );
      }
      HotelBookingTrackingHelper.trackClickEvent(
        pageName,
        clickEvent,
        response,
      );
      break;
    case HotelBookingDetailsConstant.ACTION_SEE_PAYMENT_BREAK_UP_EXTRA_CHARGES:
    case HotelBookingDetailsConstant.ACTION_SEE_PAYMENT_BREAK_UP:
      Actions.viewPaymentBreakup({
        response,
      });
      HotelBookingTrackingHelper.trackClickEvent(
        pageName,
        clickEvent,
        response,
      );
      break;
    case HotelBookingDetailsConstant.ACTION_PAY_NOW:
      if (Platform.OS === 'ios') {
        Actions.openPsWebView({
          url: response.easyPayLink,
          headerText: staticData.payNowText,
          headerIcon: WHITE_LEFT_ARROW_ICON,
        });
        const trackPageName = isIntlHotelBooking(response?.lobCode)
          ? 'mob:customer support:Hotel International:EasyPayInWebView'
          : 'mob:customer support:Hotel Domestic:EasyPayInWebView';
        HotelBookingTrackingHelper.trackLoadEvent(trackPageName, response); // explicitly done here to make the web view page generic
      } else {
        Actions.hotelPayNow({ response });
      }
      HotelBookingTrackingHelper.trackClickEvent(
        pageName,
        clickEvent,
        response,
      );
      break;
    case HotelBookingDetailsConstant.ACTION_CROSS_LOB_BUY:
      if (response.crossLobForexBuy && !_.isEmpty(response.crossLobForexBuy.offerLink)) {
        GenericModule.openDeepLink(response.crossLobForexBuy.offerLink);
      }
      HotelBookingTrackingHelper.trackClickEvent(
        pageName,
        clickEvent,
        response
      );
      break;
    case HotelBookingDetailsConstant.ACTION_CHAT_WITH_US:
      var isMyraAbEnabled = getPokusConfig(
        PokusLobs.POST_SALES,
        AbConfigKeyMappings.myraChatEnabled,
        false,
      );
      if (!isMyraAbEnabled) {
        Alert.alert('', staticData.thisFunctionalityIsCommingSoonText);
      } else {
        response.reactTag = getRootTag();
        HotelBookingModule.onButtonClicked(
          JSON.stringify(card),
          JSON.stringify(action),
          JSON.stringify(response),
          pageName,
        );
      }
      HotelBookingTrackingHelper.trackClickEvent(
        pageName,
        clickEvent,
        response,
      );
      break;
    case HotelBookingDetailsConstant.ACTION_PAHX_DEEP_LINK:
      Actions.openSubmitPage({
        response,
        isFromNameChangeFlow: false,
      });
      HotelBookingTrackingHelper.trackClickEvent(
        pageName,
        clickEvent,
        response,
      );
      break;
    case HotelBookingDetailsConstant.ACTION_HOTEL_AMENITIES:
      Actions.viewHotelAmenities({
        response,
        bookingId: response.bookingID,
      });
      HotelBookingTrackingHelper.trackClickEvent(
        pageName,
        clickEvent,
        response,
      );
      break;
    case HotelBookingDetailsConstant.ACTION_NAME_CHANGE_SUBMIT:
      Actions.openSubmitPage({
        response,
        isFromNameChangeFlow: true,
      });
      HotelBookingTrackingHelper.trackClickEvent(
        pageName,
        clickEvent,
        response,
      );
      break;
    case HotelBookingDetailsConstant.ACTION_CANCEL_BOOKING_FOR_FREE:
    case HotelBookingDetailsConstant.ACTION_CANCEL_FULL_BOOKING: {
      if (checkForChargeBack(isChargeBackApplied, chargeBackNote)) {
        return
      }
      if (showProceedForCancellationOverlay(response)) {
        action.actionHandler(HOTEL_DETAILS_BOTTOM_OVERLAY.PROCEED_FOR_CANCELLATION);
      } else {
        openCancellationPreview(response, userInfoResponse);
      }
      HotelBookingTrackingHelper.trackClickEvent(
        pageName,
        clickEvent,
        response,
      );
      break;
    }
    case HotelBookingDetailsConstant.ACTION_RESEND_CONFIRMATION:
      Actions.openResendVoucherPage({
        response,
        card,
        pageName,
      });
      HotelBookingTrackingHelper.trackClickEvent(pageName, clickEvent, response);
      break;
    case HotelBookingDetailsConstant.ACTION_CALL_HOTEL:
    case HotelBookingDetailsConstant.ACTION_CALL_MMT_SUPPORT:
      openCallHotel(card, action, response, pageName);
      break;
    case HotelBookingDetailsConstant.ACTION_DOWNLOAD_INVOICE:
      if (action.actionId === HotelBookingDetailsConstant.DOWNLOAD_INVOICE_HORLIST) {
        downloadFile(response, HotelBookingDetailsConstant.INVOICE)
      } else if (response.isCBR) {
        sendInvoiceMail(response);
      }
      HotelBookingTrackingHelper.trackClickEvent(
        pageName,
        clickEvent,
        response,
      );
      break;
    case HotelBookingDetailsConstant.ACTION_DOWNLOAD_BILL_MEMO:
      downloadFile(response, HotelBookingDetailsConstant.BILL_MEMO)
      HotelBookingTrackingHelper.trackClickEvent(
        pageName,
        clickEvent,
        response,
      );
      break
    case HotelBookingDetailsConstant.ACTION_DOWNLOAD_GST_INVOICE:
      downloadGSTInvoice(response, HotelBookingDetailsConstant.GST_INVOICE)
      HotelBookingTrackingHelper.trackClickEvent(
        pageName,
        clickEvent,
        response,
      );
      break
    case HotelBookingDetailsConstant.ACTION_CREATE_NEW_REQUEST:
      Actions.makeRequestPage({ bookingId: response.bookingID, response });
      HotelBookingTrackingHelper.trackClickEvent(
        pageName,
        clickEvent,
        response,
      );
      break;
    case HotelBookingDetailsConstant.ACTION_WRITE_HOTEL_REVIEW:
      if (Platform.OS === 'web') {
        break;
      }

      if (Platform.OS === 'ios') {
        response.reactTag = getRootTag();
        HotelBookingModule.onButtonClicked(
          JSON.stringify(card),
          JSON.stringify(action),
          JSON.stringify(response),
          pageName,
        );
      } else {
        const hotelFeedbackData = await HotelBookingModule.getHotelFeedbackDate();
        const reviewModelData = hotelFeedbackData.REVIEW_MODEL || JSON.stringify(getFeedbackReviewModel(response));
        console.log('reviewModelData is: ', reviewModelData);
        if (reviewModelData) {
          HotelBookingModule.openWriteReviewActivity(reviewModelData);
        } else {
          showLongToast(staticData.errorText.soomethingWentWrongOnlyError);
        }
        HotelBookingTrackingHelper.trackClickEvent(
          pageName,
          clickEvent,
          response,
        );
      }
      break;
    case HotelBookingDetailsConstant.ACTION_VIEW_HOUSE_RULES_IMPORTANT_INFO:
    case HotelBookingDetailsConstant.ACTION_VIEW_HOUSE_RULES: {
      Actions.viewHouseRules({
        response,
        bookingId: response.bookingID
      });
      HotelBookingTrackingHelper.trackClickEvent(
        pageName,
        clickEvent,
        response,
      );
      break;
    }
    case HotelBookingDetailsConstant.ACTION_CHAT_WITH_HOST_RTB:
    case HotelBookingDetailsConstant.ACTION_CHAT_WITH_HOST: {
      openChatWithHost(response);
      const unreadCount = getHostMsgUnreadCount(response)
        ? getHostMsgUnreadCount(response)
        : '0';
      const newclickEvent = clickEvent.concat('_'.concat(unreadCount));
      HotelBookingTrackingHelper.trackClickEvent(
        pageName,
        newclickEvent,
        response,
      );
      break;
    }
    case HotelBookingDetailsConstant.ACTION_MAP_AND_DIRECTION: {
      const params = fetchUrlParam(response);
      if (params && params.q) {
        const latLong = params.q.split(',');
        if (latLong.length > 1) {
          openGoogleMap(latLong[0], latLong[1]);
        }
      }
      break;
    }
    case HotelBookingDetailsConstant.ACTION_VIEW_LOCATION: {
      let { subCardId, subActionList, subActionListMainText } = action;
      HotelBookingTrackingHelper.trackClickEvent(
        pageName,
        clickEvent,
        response,
      );
      if (isNotNullAndEmpty(subActionList)) {
        Actions.viewGetDirections({
          response,
          pageName: pageName,
          headerText: subActionListMainText,
          actionCard: {
            ...action,
            actionList: subActionList,
            cardId: subCardId,
          }, //generate pseudo card for subactions
        });
        break;
      }
      //else {no break --> if subActions not present, move to ACTION_OPEN_LOCATION usecase}
    }
    case HotelBookingDetailsConstant.ACTION_OPEN_LOCATION: {
      const { hotelBookingInfo } = response;
      let locationFound = false;
      if (!_.isEmpty(hotelBookingInfo)) {
        const { hotelDetailsList } = hotelBookingInfo;
        if (!_.isEmpty(hotelDetailsList)) {
          const options = { label: null };
          let [locationURL, latitude, longitude] = [null, null, null];
          if (Platform.OS === 'web') {
            if (hotelDetailsList[0] && hotelDetailsList[0].locationURL) {
              locationFound = true;
              Linking.openURL(hotelDetailsList[0].locationURL);
            } else {
              locationFound = false;
            }
          } else {
            hotelDetailsList.every((item) => {
              if (item.longitude && item.latitude) {
                // eslint-disable-next-line prefer-destructuring
                longitude = item.longitude;
                // eslint-disable-next-line prefer-destructuring
                latitude = item.latitude;
                options.label = item.name;
                return false;
              }
              if (item.locationURL) {
                // eslint-disable-next-line prefer-destructuring
                locationURL = item.locationURL;
                options.label = item.name;
                return false;
              }
              return true;
            });
            const selectedLang = getUserSelectedLanguage();
            if (selectedLang === LANGUAGE_MAP.ara && isIosClient()) {
              options.label = response?.defaultMapText || HotelBookingDetailsConstant.DEFAULT_MAP_LABEL_TEXT
            }
            if (latitude && longitude) {
              locationFound = true;
              openGoogleMap(latitude, longitude, options);
            } else if (locationURL) {
              const params = fetchUrlParam(locationURL);
              if (params && params.q) {
                const latLong = params.q.split(',');
                if (latLong.length > 1) {
                  locationFound = true;
                  openGoogleMap(latLong[0], latLong[1], options);
                }
              }
            }
          }
        }
      }
      if (!locationFound) {
        showLongToast(staticData.unableToFetchAddressErrorText);
      }
      HotelBookingTrackingHelper.trackClickEvent(
        pageName,
        clickEvent,
        response,
      );
      break;
    }
    case HotelBookingDetailsConstant.ACTION_OPEN_LOCATION_GOOGLE_MAPS: {
      const { hotelBookingInfo } = response;
      let locationFound = false;
      if (!_.isEmpty(hotelBookingInfo)) {
        const { hotelDetailsList } = hotelBookingInfo;
        if (!_.isEmpty(hotelDetailsList)) {
          const options = { label: null };
          let [locationURL, latitude, longitude] = [null, null, null];
          hotelDetailsList.every((item) => {
            if (item.longitude && item.latitude) {
              // eslint-disable-next-line prefer-destructuring
              longitude = item.longitude;
              // eslint-disable-next-line prefer-destructuring
              latitude = item.latitude;
              options.label = item.name;
              return false;
            }
            if (item.locationURL) {
              // eslint-disable-next-line prefer-destructuring
              locationURL = item.locationURL;
              options.label = item.name;
              return false;
            }
            return true;
          });
          if (latitude && longitude) {
            locationFound = true;
            openGoogleMapAlways(latitude, longitude, options);
          } else if (locationURL) {
            const params = fetchUrlParam(locationURL);
            if (params && params.q) {
              const latLong = params.q.split(',');
              if (latLong.length > 1) {
                locationFound = true;
                openGoogleMapAlways(latLong[0], latLong[1], options);
              }
            }
            if (!locationFound) {
              openGoogleMapAlways(null, null, options, locationURL)
            }
          }
        }
      }
      if (!locationFound) {
        showLongToast(staticData.unableToFetchAddressErrorText);
      }
      HotelBookingTrackingHelper.trackClickEvent(pageName, clickEvent, response);
      break;
    }
    case HotelBookingDetailsConstant.ACTION_COPY_HOTEL_ADDRESS: {
      const { hotelBookingInfo } = response;
      copyAddress(hotelBookingInfo, langAware);
      HotelBookingTrackingHelper.trackClickEvent(pageName, clickEvent, response);
      break;
    }
    case HotelBookingDetailsConstant.ACTION_COPY_HOTEL_COORDINATES: {
      const { hotelBookingInfo } = response;
      let copyStatus = false;
      let locationFound = false;
      if (!_.isEmpty(hotelBookingInfo)) {
        const { hotelDetailsList } = hotelBookingInfo;
        if (!_.isEmpty(hotelDetailsList)) {
          let [locationURL, latitude, longitude] = [null, null, null];
          hotelDetailsList.every((item) => {
            if (item.longitude && item.latitude) {
              // eslint-disable-next-line prefer-destructuring
              longitude = item.longitude;
              // eslint-disable-next-line prefer-destructuring
              latitude = item.latitude;
              return false;
            }
            if (item.locationURL) {
              // eslint-disable-next-line prefer-destructuring
              locationURL = item.locationURL;
              return false;
            }
            return true;
          });
          if (latitude && longitude) {
            locationFound = true;
            copyStatus = await copyToClipboard([latitude, longitude].join(','));
          } else if (locationURL) {
            const params = fetchUrlParam(locationURL);
            if (params && params.q) {
              const latLong = params.q;
              if (latLong.length > 1) {
                locationFound = true;
                copyStatus = await copyToClipboard(latLong);
              }
            }
          }
        }
      }
      const { unableToFetchAddressErrorText, copiedToClipboardText, errorText: { soomethingWentWrongOnlyError } } = staticData;
      if (!locationFound) {
        showLongToast(unableToFetchAddressErrorText);
      } else if (copyStatus) {
        showLongToast(copiedToClipboardText);
      } else {
        showLongToast(soomethingWentWrongOnlyError);
      }
      HotelBookingTrackingHelper.trackClickEvent(
        pageName,
        clickEvent,
        response,
      );
      break;
    }
    case HotelBookingDetailsConstant.ACTION_VIEW_REFUND_CALCULATION:
      Actions.viewRefundCalculation({
        response,
      });
      HotelBookingTrackingHelper.trackClickEvent(pageName, clickEvent, response);
      break;
    case HotelBookingDetailsConstant.ACTION_CALL_MMT_SUPPORT:
      if (Platform.OS === "web") {
        openCallSupport(card, action, response, pageName)
      } else {
        HotelBookingModule.onButtonClicked(
          JSON.stringify(card),
          JSON.stringify(action),
          JSON.stringify(response),
          pageName,
        );
      }
      break;
    case HotelBookingDetailsConstant.ACTION_SHARE_HOTEL_LOCATION:
      shareLocation(response);
      HotelBookingTrackingHelper.trackClickEvent(pageName, clickEvent, response);
      break;
    case HotelBookingDetailsConstant.ACTION_PROPERTY_DETAILS:
      openPropertyDetails(response);
      HotelBookingTrackingHelper.trackClickEvent(pageName, clickEvent, response);
      break;
    case HotelBookingDetailsConstant.ACTION_HOTEL_PHOTOS:
      if (getHotelImageURLList(response) != null) {
        Actions.viewHotelPhotos({
          response
        });
      }
      HotelBookingTrackingHelper.trackClickEvent(pageName, clickEvent, response);
      break;
    case HotelBookingDetailsConstant.ACTION_ROOM_PHOTOS:
      if (getRoomImages(response)) {
        Actions.viewRoomPhotos({
          response
        });
      }
      HotelBookingTrackingHelper.trackClickEvent(pageName, clickEvent, response);
      break;
    case HotelBookingDetailsConstant.ACTION_REQUEST_LATE_CHECK_OUT:
      openSpecialRequest(HotelBookingDetailsConstant.LATE_CHECKOUT_REQUEST_KEY, response);
      HotelBookingTrackingHelper.trackClickEvent(pageName, clickEvent, response);
      break;
    case HotelBookingDetailsConstant.ACTION_WRITE_TO_PROPERTY:
      openWriteToProperty(HotelBookingDetailsConstant.OTHERS_KEY, response, langAware);
      HotelBookingTrackingHelper.trackClickEvent(pageName, clickEvent, response);
      break;
    case HotelBookingDetailsConstant.ACTION_PARTIAL_CANCELLATION:
      if (!response.isModificationAllowed) {
        action.actionHandler(HOTEL_DETAILS_BOTTOM_OVERLAY.TCS_MODIFICATION_OVERLAY);
        return;
      }
      let { subCardId, subActionList, subActionListMainText } = action;
      HotelBookingTrackingHelper.trackClickEvent(pageName, clickEvent, response);
      if (isNotNullAndEmpty(subActionList)) {
        Actions.hotelPartialCancellation({
          response,
          pageName,
          headerText: subActionListMainText,
          actionCard: { actionList: subActionList, cardId: subCardId }
        });
      } else {
        showLongToast(staticData.errorText.smthWentWrongTryLater);
      }
      break;
    case HotelBookingDetailsConstant.ACTION_STAFF_DETAILS:
      Actions.staffDetails({ staffDetails: response.hotelBookingInfo.staffDetails });
      break;
    case HotelBookingDetailsConstant.ACTION_GROUP_BOOKNG_PAY_NOW:
      const { installmentDetails } = response || {};
      const { paymentUrl: url } = installmentDetails || {};
      isEasyPayNativeFlowEnabled() ?
        GenericModule.openDeepLink(url) :
        Actions.openPsWebView({
          url,
          headerText: staticData.payNowText,
          headerIcon: WHITE_LEFT_ARROW_ICON,
        });
      break;
    case HotelBookingDetailsConstant.ACTION_GB_ADD_GUEST:
    case HotelBookingDetailsConstant.ACTION_GB_ADD_MEAL:
    case HotelBookingDetailsConstant.ACTION_GB_CHANGE_DATES:
    case HotelBookingDetailsConstant.ACTION_GB_PAY_REMAINING_NOW:
    case HotelBookingDetailsConstant.ACTION_GB_PAY_INSTALLMENT:
      action.actionHandler && action.actionHandler(action.actionFamily);
      break;
    case HotelBookingDetailsConstant.ACTION_DOWNLOAD_POLICY:
      downloadFile(response, HotelBookingDetailsConstant.POLICY)
      break;
    case HotelBookingDetailsConstant.ACTION_DOWNLOAD_VOUCHER:
      downloadFile(response, HotelBookingDetailsConstant.VOUCHER);
      break;
    case HotelBookingDetailsConstant.ACTION_DOWNLOAD_IHINVOICE:
      downloadFile(response, HotelBookingDetailsConstant.INVOICE)
      break;
    case HotelBookingDetailsConstant.ACTION_ADD_TO_APPLE_WALLET:
      if (Platform.OS !== 'ios') break;
      addToAppleWallet({ card, action, response, pageName });
      break;
    default:
      nativeClickHandler({ card, action, response, pageName });
      break;
  }
}

function nativeClickHandler({card, action, response, pageName}) {
  response.reactTag = getRootTag();
  if (Platform.OS === "web") {
    FlightBookingModule.onButtonClicked(
      JSON.stringify(card),
      JSON.stringify(action),
      JSON.stringify(response),
      pageName,
    );
  } else {
    HotelBookingModule.onButtonClicked(
      JSON.stringify(card),
      JSON.stringify(action),
      JSON.stringify(response),
      pageName,
    );
  }
}

export async function downloadGSTInvoice(response, triggerName) {
  const bookingID = response.bookingID;
  const requestUrl = triggerName ? TRIGGER_NAME_BASED_URL()[triggerName] : '';
  const userInfo = await HotelBookingModule.getRequestHeader(
    bookingID,
    HotelBookingDetailsConstant?.HOTEL_UNIQUEID_TYPE
  );
  const { mmtAuth, loggingTrackingInfo } = userInfo || {};
  let headers = await getCommonHeaders(mmtAuth, loggingTrackingInfo);

  const requestBody = {
    userId: Platform.select({ android: 'ANDROID', ios: 'IOS', web: 'PWA' }),
    triggerName: triggerName,
    bookingId: bookingID,
  };

  try {
    const response = await fetch(requestUrl, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(requestBody),
    });

    const data = await response.json();

    if (data.success && data.pdf) {
      const zipResponse = await fetch(data.pdf);
      const blob = await zipResponse.blob();

      if (FlightBookingModule && FlightBookingModule.saveFile) {
        FlightBookingModule.saveFile(blob, `${bookingID}_VendorInvoices`, "application/zip");
      }
    } else {
      alert("Failed to download the invoice.");
    }
  } catch (error) {
    alert("Error while downloading the invoice.");
  }
}

export async function downloadFile(response, fileType='', docVersion='', markupAmount = 0, isCancellationPolicyAdded = false, markupDetails, markupselected) {
  const { downloadFileText, downloadFailedText } = getStaticData();
  const triggerName = fileType ? DOWNLOAD_FILE_TRIGGER_NAME[fileType] : '';
  const fileName = fileType ? DOWNLOAD_FILE_TYPE[fileType] : '';
  const reqUrl = triggerName ? TRIGGER_NAME_BASED_URL()[triggerName] : '';
  try {
    showLongToast(downloadFileText);
    const bookingID = response.bookingID;
    const userInfo = await HotelBookingModule.getRequestHeader(bookingID, HotelBookingDetailsConstant?.HOTEL_UNIQUEID_TYPE);
    const { mmtAuth, loggingTrackingInfo } = userInfo || {};
    const reqBody = {
      bookingId: bookingID,
      triggerName: triggerName,
      userId: Platform.select({android:'ANDROID', ios:'IOS', web:'PWA'}),
      markupAmount,
      isCancellationPolicyAdded,
      markupInfo: markupDetails ? markupDetails : {},
      isServiceFeesSelected: markupselected
    }
    if(docVersion){
      reqBody['version'] = docVersion;
    }
    let headers = await getCommonHeaders(mmtAuth, loggingTrackingInfo);
    const docName = fileName ? `${fileName}-${bookingID}.pdf` : `${bookingID}.pdf`;
    downloadDocument(reqUrl, reqBody, headers, docName);

    let { language } = headers;
    if (language && language !== ENGLISH) {
      // Download English File as well.
      headers = await getCommonHeaders(mmtAuth, loggingTrackingInfo, { langAware: false });
      downloadDocument(reqUrl, reqBody, headers, docName);
    }
  } catch (error) {
    showLongToast(downloadFailedText);
  }
}

async function downloadDocument(reqUrl, reqBody, reqHeaders, docName) {
  const { downloadFailedText, openFileText, errorText, downloadCustomerBookingInvoiceSuccess } = getStaticData();
  try {
    const response = await fetch(reqUrl, {
      method: 'post',
      body: JSON.stringify(reqBody),
      headers: reqHeaders,
    });
    const resBody = await response.json();
    if (!resBody.success) {
      showLongToast(downloadFailedText);
    }
    
    if(reqBody?.triggerName === DOWNLOAD_FILE_TRIGGER_NAME[HotelBookingDetailsConstant.MY_PARTNER_CUSTOMER_VOUCHER] && resBody.success){
      showShortToast(downloadCustomerBookingInvoiceSuccess);
    }

    if (resBody?.pdf) {
      if (Platform.OS === 'ios') {
        await GenericModule.openPDFViewer(docName, resBody.pdf);
      } else {
        await FlightBookingModule.saveTicket(resBody.pdf, docName);
        if (Platform.OS === 'android') {
          FlightBookingModule.showTicketDownloadedNotification({
            title: docName,
            fileName: docName,
            description: openFileText,
          });
        }
      }
    } else {
      showLongToast(errorText?.somethingWentWrongError);
    }
  } catch (error) {
    showLongToast(downloadFailedText);
  }
}

function openCallSupport(card, action, response, pageName) {
  let numberToCall = '';
  if (response.isGccBooking) {
    numberToCall = response.contactNo || '';
  } else {
    numberToCall = HotelBookingDetailsConstant.MMT_ASSURED_DEFAULT_HOT_LINE_NUMBER;
    if (HotelBookingDetailsConstant.CORPORATE_LOBS.includes(response?.lobCode)) {
      numberToCall = HotelBookingDetailsConstant.DEFAULT_CORPORATE_TOLL_FREE_NUMBER;
    }
  }
  if (numberToCall && numberToCall.includes(',')) {
    const hotelDetailResponse = response.hotelBookingInfo;
    response.hotelBookingInfo.hotelDetailsList[hotelDetailResponse.hotelDetailsList.length - 1].phoneNumber = numberToCall;
    const staticData = getStaticData();
    Actions.openCallHotel({
      response,
      headerText: staticData.callHotelCardText.additionalText.callSupportText
    });
  } else if (numberToCall) {
    window.location = 'tel:' + numberToCall;
  }
}

function openCallHotel(card = {}, action = {}, response, pageName) {
  const hotelDetailResponse = response.hotelBookingInfo;
  const hotelBookingInfo =
    hotelDetailResponse.hotelDetailsList[
      hotelDetailResponse.hotelDetailsList.length - 1
    ];
  if (hotelBookingInfo.phoneNumber.includes(',')) {
    Actions.openCallHotel({
      response,
    });
    HotelBookingTrackingHelper.trackClickEvent(
      pageName,
      'MI_HTL_CallHotel',
      response,
    );
  } else {
    openDialer(hotelBookingInfo.phoneNumber)
  }
}

export function getActionFromCard(card, actionFamily) {
  return (
    card &&
    card.actionList.find((action) => action.actionFamily === actionFamily)
  );
}

export function getRoundIcon(actionFamily) {
  if (actionFamily) {
    switch (actionFamily) {
      case HotelBookingDetailsConstant.ACTION_VIEW_LOCATION:
        return WHITE_MAP_ICON;
      case BusBookingDetailsConstant.ACTION_BUS_CALL_US:
      case CabBookingDetailsConstant.ACTION_CALL_DRIVER:
      case COMMON_CONSTANT.ACTION_CALL_OPERATOR:
      case FlightBookingDetailsConstant.CALL_AIRLINE_ACTION_BTN_FAMILY:
      case FlightBookingDetailsConstant.ACTION_CORPORATE_CALL:
      case MetroConstant.CALL_METRO_SUPPORT_FAMILY:
      case MetroConstant.CALL_MMT_SUPPORT_FAMILY:
        return CALL_BLUE_BG_ICON;
      case HotelBookingDetailsConstant.ACTION_CHAT_WITH_HOTEL:
        return ROUNDED_CHAT_BLUE_BG_ICON;
      case HotelBookingDetailsConstant.ACTION_RESEND_CONFIRMATION:
        return RESEND_BLUE_BG_ICON;
      case HotelBookingDetailsConstant.ACTION_VALID_ID_PROOF:
      case CabBookingDetailsConstant.ACTION_DRIVER_DETAILS:
        return ID_BLUE_BG_ICON;
      case HotelBookingDetailsConstant.ACTION_CHECK_IN_AT_HOTEL:
        return CHECKIN_BLUE_BG_ICON;
      case HotelBookingDetailsConstant.ACTION_CALL_MMT_SUPPORT:
      case FlightBookingDetailsConstant.ACTION_CALL_MMT_SUPPORT_GCC:
        return HEADSET_BLUE_BG_ICON;
      case HotelBookingDetailsConstant.ACTION_GO_TO_HELP_SELECTION:
        return LETTER_INSTRUCTIONS_BLUE_BG_ICON;
      case HotelBookingDetailsConstant.ACTION_CHAT_WITH_US:
      case BusBookingDetailsConstant.ACTION_BUS_MAKE_REQUEST:
      case FlightBookingDetailsConstant.CHAT_WITH_US_ACTION_BTN_FAMILY:
        return CHAT_BLUE_BG_ICON;
      case HotelBookingDetailsConstant.ACTION_MAKE_REQUEST:
      case COMMON_CONSTANT.ACTION_MAKE_REQUEST:
        return WISH_WAND_ICON;
      case BusBookingDetailsConstant.ACTION_BUS_GOTO_HELP_SECTION:
      case COMMON_CONSTANT.ACTION_HELP_SECTION:
        return HELP_BLUE_BG_ICON;
      case RailBookingDetailConstant.LIVE_TRAIN_STATION:
        return LIVE_TRACKING_ICON;
      case RailBookingDetailConstant.RAIL_LIVE_PNR_CHECK:
        return TICKET_SUCCESS_BLUE_BG_ICON;
      case RailBookingDetailConstant.TRAIN_COACH_POSITION:
        return TRAIN_COACH_ICON;
      case HotelBookingDetailsConstant.ACTION_CALL_HOTEL:
        return BLUE_PHONE_ICON;
      case HotelBookingDetailsConstant.ACTION_DOWNLOAD_VOUCHER:
      case HotelBookingDetailsConstant.ACTION_DOWNLOAD_CUSTOMER_VOUCHER:
        return WHITE_DOWNLOAD_ICON;
      case HotelBookingDetailsConstant.ACTION_PROPERTY_DETAILS:
        return HOSTEL_ICON;
      case HotelBookingDetailsConstant.ACTION_GB_PAY_INSTALLMENT:
        return RUPEE_SYMBOL_WHITE;
      case HotelBookingDetailsConstant.ACTION_CHAT_WITH_HOST_RTB:
      case HotelBookingDetailsConstant.ACTION_GROUP_BOOKNG_PAY_NOW:
        return null;
      default:
        return {
          uri: `https://promos.makemytrip.com/MIMA/${actionFamily}.png`,
        };
    }
  }
}


export function getRedirectLinkImage(actionFamily) {
  if (actionFamily) {
    switch (actionFamily) {
      case HotelBookingDetailsConstant.ACTION_DOWNLOAD_INVOICE:
      case HotelBookingDetailsConstant.ACTION_DOWNLOAD_CUSTOMER_VOUCHER:
      case FlightBookingDetailsConstant.ACTION_DOWNLOAD_VOUCHER:
      case HotelBookingDetailsConstant.ACTION_DOWNLOAD_GST_INVOICE:
        return BOOKING_INVOICE;
      case HotelBookingDetailsConstant.ACTION_DOWNLOAD_BILL_MEMO:
        return BILL_MEMO;
      default:
        return {
          uri: `${HotelBookingDetailsConstant.PROMOS_BASE_URL}${actionFamily}.png`,
        };
    }
  }
}

export function getCardObject(response, cardName) {
  const { cardList } = response.layoutDetail;
  if (isNotNullAndEmpty(cardList)) {
    for (let count = 0; count < cardList.length; count += 1) {
      if (cardList[count].cardId === cardName) {
        return cardList[count];
      }
    }
  }
  return null;
}

export function isIntlHotelBooking(lobCode) {
  if (
    lobCode === 'LOB02530' ||
    lobCode === 'LOB02730' ||
    lobCode === 'LOB01530'
  ) {
    return false;
  }
  return true;
}

export async function openCancellationPreview(response, userInfoResponse) {
  const userInfo = await HotelBookingModule.getRequestHeader(
    response.bookingID,
    'HOTEL_CANCELLATION_PREVIEW',
  );
  const cancellationRequest = getHotelCancellationRequest(
    response,
    'true',
    'RefundToCustomer',
  );
  cancellationRequest.emailId = userInfo.emailId;
  Actions.cancellationPreview({
    bookingId: response.bookingID,
    url: HOTEL_BOOKING_CANCELLATION_URL,
    mmtAuth: userInfo.mmtAuth,
    emailId: userInfo.emailId,
    loggingTrackInfo: userInfo.loggingTrackingInfo,
    cancelaltionPreviewRequest: cancellationRequest,
    hotelBookingResponse: response,
    userInfoResponse: userInfoResponse
  });
}

export async function openHotelCancellationThankYou(response, refundMode, instantData, refundAmount, cancellationReason) {
  const userInfo = await HotelBookingModule.getRequestHeader(response.bookingID, 'HOTEL_CANCELLATION_DONE');
  const cancellationRequest = getHotelCancellationRequest(response, 'false', refundMode, instantData);
  cancellationRequest.emailId = userInfo.emailId;
  Actions.cancellationThankYou({
    url: HOTEL_BOOKING_CANCELLATION_URL,
    mmtAuth: userInfo.mmtAuth,
    loggingTrackInfo: userInfo.loggingTrackingInfo,
    cancellationDoneRequest: cancellationRequest,
    hotelBookingResponse: response,
    refundAmount: refundAmount,
    bookingId: response.bookingID,
  });
}

function getChildBookingListToCancel(hotelBookingResponse) {
  const childCancelReq = {};
  const childBookingIdList = [];
  childCancelReq.parentBookingId = hotelBookingResponse.bookingID;
  hotelBookingResponse.childBookingInfo.childBookingDetailInfoList.forEach(
    (childBooking) => {
      if (childBooking.mandatorycancelwithparent) {
        childBookingIdList.push(childBooking.bookingId);
      }
    },
  );
  childCancelReq.childBookingIds = childBookingIdList;
  return childCancelReq;
}

export function getHotelCancellationRequest(hotelBookingResponse, isCancellationPreview, refundOption, instantData, cancellationReason) {
  const hotelCancellationRequest = {};
  hotelCancellationRequest.bookingID = hotelBookingResponse.bookingID;
  hotelCancellationRequest.channel = 'B2CWeb';
  hotelCancellationRequest.idContext = 'MMT';
  hotelCancellationRequest.isPreview = isCancellationPreview;
  hotelCancellationRequest.currencyCode = hotelBookingResponse.paymentSummary
    .currencyCode
    ? hotelBookingResponse.paymentSummary.currencyCode
    : 'INR';
  if (
    hotelBookingResponse.hotelBookingInfo.primaryCustomerDetails &&
    hotelBookingResponse.hotelBookingInfo.primaryCustomerDetails.mobileNumber
  ) {
    hotelCancellationRequest.phoneNo =
      hotelBookingResponse.hotelBookingInfo.primaryCustomerDetails.mobileNumber;
  }
  hotelCancellationRequest.refundOption = refundOption;
  hotelCancellationRequest.requestType = 'B2BAgent';
  if (hotelBookingResponse.childBookingInfo) {
    hotelCancellationRequest.childCancellationReq = getChildBookingListToCancel(
      hotelBookingResponse,
    );
  }
  if (instantData) {
    hotelCancellationRequest.instantData = instantData;
  }
  if (cancellationReason) {
    hotelCancellationRequest.cancellationReason = cancellationReason;
  }
  return hotelCancellationRequest;
}

export function setPaymentAmount(
  paymentSummary,
  paymentDetails,
  couponInformation,
) {
  let amount = 0;
  paymentDetails.forEach((paymentObj, index) => {
    amount += paymentObj.amountLCY;
    if (paymentObj.paymentMode === 100) {
      amount -= paymentObj.amountLCY;
    }
  });

  if (paymentSummary.eCouponList != null) {
    amount -= paymentSummary.eCouponList.totalECouponAmountLcy;
  }
  amount = Math.round(amount, 0);
  if (amount < 0) {
    amount = 0;
  }
  return amount;
}

export function appendCurrencySymbol(currencyCode, value) {
  const resId = getResourceIdForCurrency(currencyCode);
  let result;
  if (resId === -1) {
    result = value;
  } else if (resId === 0) {
    result = `${currencyCode} ${value}`;
  } else {
    result = resId + value;
  }
  return result;
}

export function getResourceIdForCurrency(currency) {
  if (isNullOrEmpty(currency)) {
    return -1;
  }
  if (isNullOrEmpty(currency) || currency.toLowerCase().includes('inr')) {
    return '\u20B9 ';
  }
  if (currency.toLowerCase().includes('us')) {
    return '\u0024 ';
  }
  if (currency.toLowerCase().includes('eur')) {
    return '\u20AC ';
  }
  return 0;
}

export function getPaymentDueText(paymentSummary, paymentDetails) {
  const amountDue =
    paymentDetails.amountDueLcy >= 0
      ? appendCurrencySymbol(
          paymentSummary.currencyCode,
          Math.round(paymentDetails.amountDueLcy),
          0,
        )
      : 0;
  return amountDue;
}

export function getSubTitleForStickyHeader(hotelDetailResponse, langAware = true) {
  const { checkInDate, checkOutDate, roomDetails, totalNumberOfAdults, totalNumberOfChilds, commonFields } = hotelDetailResponse.hotelBookingInfo;
  const totalGuest = totalNumberOfAdults + totalNumberOfChilds;
  const {
    hotelMainCardText: {
      additionalText: {
        guestText,
        guestsText,
        roomText,
        roomsText
      }
    }
  } = getStaticData(langAware);
  return `${(commonFields && !!commonFields.checkinCheckoutText2) ? commonFields.checkinCheckoutText2 : `${fillCheckInCheckOutData(checkInDate, 'DD MMM')}
  -${fillCheckInCheckOutData(checkOutDate, 'DD MMM')}`} • ${isNotNullAndEmpty(roomDetails) ? roomDetails.length : 0} ${roomDetails && roomDetails.length > 1 ? roomsText: roomText} • ${totalGuest} ${totalGuest > 1 ? guestsText: guestText}`;
}

/**
 * manually adding card data for any internal flow when its information is not coming
 * in layout details node in hotel details api
 * @returns {Array} cardList array
 */
export function addCardListManually(
  actionRank,
  actionId,
  actionType,
  actionLabelText,
  actionFamily,
) {
  const cardList = [];
  const card = {};
  const actionList = [];
  const action = {};
  action.actionRank = actionRank;
  action.actionId = actionId;
  action.actionType = actionType;
  action.actionLabeltext = actionLabelText;
  action.actionFamily = actionFamily;
  actionList.push(action);
  card.actionList = actionList;
  cardList.push(card);
  return cardList;
}

export function getReviewStripMessage(searchResponse, langAware = true) {
  const staticData = getStaticData(langAware);
  if (isNullOrEmpty(searchResponse.availableRoomDetails)) {
    return staticData.hotelBookingDetailUtilText.additionalText.reviewText;
  } else if (
    searchResponse.availableRoomDetails[0].paymentMode === 'PAH_WITHOUT_CC' ||
    searchResponse.availableRoomDetails[0].applicableRefund > 0
  ) {
    return staticData.hotelBookingDetailUtilText.additionalText
      .reviewConfirmText;
  }
  return staticData.hotelBookingDetailUtilText.additionalText.reviewText;
}

export const showRoundToast = (message) => {
  if (isNullOrEmpty(message)) return;
  Toast.show(message, undefined, 80, 'dark', {
    flexDirection:'row',
    alignItems:'center',
  }, {
    fontSize: 14,
    lineHeight:20,
    borderRadius: 32,
    paddingHorizontal: 32,
    overflow: 'hidden',
  });
}

export function handleModificationAction(modificationAction, modificationResponse, hotelDetailResponse, extraArgs = {}, showHelpingHand, langAware = true, userInfoResponse, action = {}) {
  const {card} = extraArgs;
  const staticData = getStaticData(langAware);
  const isCorporateBooking = hotelDetailResponse.isCorporateBooking;
  if (modificationAction === 'ADDGUEST') {
    const isSameRoomDetails = _.isEqual(
      modificationResponse.roomListDetails.map(({childAgeList, childAges, ...roomDetail}) => roomDetail),
      modificationResponse.origRoomDetails.map(({childAgeList, childAges, ...roomDetail}) => roomDetail));
    let areSameAgesSelected = false;
    if (isSameRoomDetails) {
      areSameAgesSelected = _.zip(modificationResponse.roomListDetails, modificationResponse.origRoomDetails)
        .every(([{ childAgeList: modifiedChildAgeList = [] }, { childAgeList:  origChildAgeList = [] }]) => {
          return _.isEqual(modifiedChildAgeList.map(age => `${age}`), origChildAgeList.map(age => `${age}`));
        });
    }
    const isAgeNotSelected = modificationResponse.roomListDetails.some(({childAgeList}) => {
      return childAgeList.some(age => !extraArgs.childAgeCandidates.includes(`${age}`));
    });
    const roomListDetails = modificationResponse.roomListDetails.map(({childAgeList, ...roomDetail}) => {
      return {
        childAgeList,
        ...roomDetail,
        childAges: listToCSVHelper(childAgeList)
      }
    })
    if (isSameRoomDetails && areSameAgesSelected) {
      showLongToast(staticData.pleaseAddSomeAdultsOrChildrenToCheckAvailabilityText);
      return { errorType: MOD_ERROR_TYPES.PAX_COUNT_UNCHANGED };
    } else if (isAgeNotSelected) {
      const { addGuestCardText: { additionalText:{ provideAgeText, pleaseProvideAgeAllChildrenText } } } = staticData;
      const totalChildCount = roomListDetails.reduce((total, {noofchild}) => (
        total + noofchild
      ), 0);
      showLongToast(
        `${totalChildCount > 1 ? pleaseProvideAgeAllChildrenText : provideAgeText}`
      );
      return { errorType: MOD_ERROR_TYPES.CHILD_AGE_MISSING };
    } else {
      const hotelBookingDetail = modificationResponse.response;
      hotelBookingDetail.roomReqDetails = roomListDetails;
      const hotelBookingDetailResponse = hotelDetailResponse;
      const prop = {
        hotelBookingDetail,
        hotelBookingDetailResponse,
        card: card,
        showHelpingHand,
        userInfo: userInfoResponse,
      };
      Actions.addGuestReview(prop);
      return { errorType: MOD_ERROR_TYPES.NONE };
    }
  } else if (modificationAction === 'DATECHANGE') {
    const checkinMonth = ("0" + (modificationResponse.checkinDate.month + 1)).slice(-2)
    const checkoutMonth = ("0" + (modificationResponse.checkOutDate.month + 1)).slice(-2)
    const newCheckinDate = `${modificationResponse.checkinDate.year}-${checkinMonth}-${modificationResponse.checkinDate.date}`;
    const newCheckOutDate = `${modificationResponse.checkOutDate.year}-${checkoutMonth}-${modificationResponse.checkOutDate.date}`;
    const bookingid = modificationResponse.bookingId;

    if (modificationResponse.isDisabled) {
      showLongToast(staticData.pleaseSelectCheckOutDateText);
    } else if (
      checkSameTravelDate(
        newCheckinDate,
        newCheckOutDate,
        modificationResponse.oldCheckInDate,
        modificationResponse.oldCheckOutDate,
      )
    ) {
      showLongToast(staticData.newDatesAreSameAsOldDatesText);
    } else {
      Actions.hotelChangeDateViewPrice({
        newCheckinDate,
        newCheckOutDate,
        bookingid,
        hotelDetailResponse,
        showHelpingHand,
        card,
        isCorporateBooking,
        userInfo: userInfoResponse,
      });
    }
  }
}

function checkSameTravelDate(
  newCheckinDate,
  newcheckoutdate,
  oldCheckinDate,
  oldCheckoutdate,
) {
  const formattedcheckInDate = fillCheckInCheckOutData(
    oldCheckinDate,
    'YYYY-MM-DD',
  );
  const formattedcheckOutDate = fillCheckInCheckOutData(
    oldCheckoutdate,
    'YYYY-MM-DD',
  );
  if (
    formattedcheckInDate === newCheckinDate &&
    formattedcheckOutDate === newcheckoutdate
  ) {
    return true;
  }
  return false;
}

export function getHotelAddressString(hotelDetails) {
  let addressList = [];
  if (hotelDetails.addressLine1) {
    addressList.push(
      hotelDetails.addressLine1.replace(/\r|\t|\n/g, ' ').trim(),
    );
  }
  if (hotelDetails.addressLine2) {
    addressList.push(
      hotelDetails.addressLine2.replace(/\r|\t|\n/g, ' ').trim(),
    );
  }
  if (hotelDetails.city) {
    addressList.push(hotelDetails.city.replace(/\r|\t|\n/g, ' ').trim());
  }
  return addressList.filter((n) => n.trim().length > 0).join(', ');
}

export function getModificationRequest(
  appVersion,
  exprimentData,
  deviceId,
  visitorId,
  checkinDate,
  checkOutDate,
  bookingid,
  addVersion,
  isCorpbooking
) {
  const request = {};
  request.userId = USERID;
  request.platformSource = Platform.OS === 'web' ? 'msite' : Platform.OS;
  request.platformVersion = appVersion;
  request.bookingId = bookingid;
  request.channel = Platform.OS === 'web' ? 'PWA' : CHANNEL;
  request.checkin = checkinDate;
  request.checkout = checkOutDate;
  request.idContext = isCorpbooking ? "CORP" : "B2C";
  request.visitorId = visitorId;
  request.bookingDevice = Platform.OS === 'web' ? 'msite' : Platform.OS;
  request.deviceId = deviceId;
  request.appVersion = appVersion;
  request.experimentData = exprimentData;
  if (addVersion && Platform.OS === 'android') {
    request.ver = 'v2';
  }
  return request;
}

export function createModificationReviewRequest(
  appVersion,
  exprimentData,
  deviceId,
  visitorId,
  checkinDate,
  checkOutDate,
  searchHotelResponse,
  thankYouURL,
  hashKey,
  isCorpbooking,
  availValidateReferenceKey
) {
  const reviewrequest = {};
  reviewrequest.userId = USERID;
  reviewrequest.platformSource = Platform.OS === 'web' ? 'msite' : Platform.OS;
  reviewrequest.platformVersion = appVersion;
  reviewrequest.bookingId = searchHotelResponse.bookingID;
  reviewrequest.mtKey = searchHotelResponse.availableRoomDetails[0].mtKey;
  reviewrequest.roomCode = searchHotelResponse.availableRoomDetails[0].roomCode;
  reviewrequest.ratePlanCode =
    searchHotelResponse.availableRoomDetails[0].ratePlanCode;
  reviewrequest.newModeOfPayment =
    searchHotelResponse.availableRoomDetails[0].paymentMode;
  reviewrequest.couponCode =
    searchHotelResponse.availableRoomDetails[0].couponCode;
  reviewrequest.channel = Platform.OS === 'web' ? 'PWA' : CHANNEL;
  reviewrequest.checkin = checkinDate;
  reviewrequest.checkout = checkOutDate;
  reviewrequest.idContext = isCorpbooking ? "CORP" : "B2C";
  reviewrequest.visitorId = visitorId;
  reviewrequest.bookingDevice = Platform.OS === 'web' ? 'msite' : Platform.OS;
  reviewrequest.deviceId = deviceId;
  reviewrequest.appVersion = appVersion;
  reviewrequest.experimentData = exprimentData;
  reviewrequest.supplierCode =
    searchHotelResponse.availableRoomDetails[0].supplierCode;
  reviewrequest.additionalParamaters = searchHotelResponse.additionalParamaters;
  reviewrequest.source = searchHotelResponse.source;
  reviewrequest.sessionKey = searchHotelResponse.sessionKey;
  reviewrequest.thankYouURL = thankYouURL;
  reviewrequest.hashKey = hashKey;
  reviewrequest.availValidateReferenceKey = availValidateReferenceKey;
  return reviewrequest;
}


export function validationCheckForNameChange(response, checkNameChangeUserResponse, pageName, langAware = true) {
  let isNameChangeAllowed = false;
  let alertMainText = '';
  const {
    hotelBookingDetailErrorText: {
      additionalText: {
        sorryWeCouldNotFetchRequiredInformationText,
        oopsSomethingWentWrongPleaseTryAfterSometimeText,
      }
    }
  } = getStaticData(langAware);
  if (checkNameChangeUserResponse && checkNameChangeUserResponse.success) {
    if (checkNameChangeUserResponse.allowedDescription) {
      switch (checkNameChangeUserResponse.allowedDescription.toUpperCase()) {
        case 'YES':
        case 'UNKNOWN':
          HotelBookingTrackingHelper.trackClickEvent(pageName, 'MI_HTL_ModifyName', response);
          isNameChangeAllowed = true;
          break;
        case 'NO':
          alertMainText = checkNameChangeUserResponse.message;
          HotelBookingTrackingHelper.trackClickEvent(pageName, 'MI_HTL_Modifyname_Not', response);
          break;
        case 'NAMECHANGELIMITCROSSED':
          alertMainText = checkNameChangeUserResponse.message;
          HotelBookingTrackingHelper.trackClickEvent(pageName, 'MI_HTL_ModifyName_Lesstimewindow', response);
          break;
        case 'UNKNOWNLIMITBREACHED':
          alertMainText = checkNameChangeUserResponse.message;
          HotelBookingTrackingHelper.trackClickEvent(pageName, 'MI_HTL_ModifyName_alreadyformsubmitted', response);
          break;
        case 'UNKNOWNNOTALLOWED':
          alertMainText = checkNameChangeUserResponse.message;
          HotelBookingTrackingHelper.trackClickEvent(pageName, 'MI_HTL_Modifyname_alreadydone', response);
          break;
        default:
          break;
      }
    } else {
      alertMainText = sorryWeCouldNotFetchRequiredInformationText;
    }
  } else {
    alertMainText = oopsSomethingWentWrongPleaseTryAfterSometimeText;
  }
  return {
    isNameChangeAllowed, alertMainText
  }
}

export function getTimeInMillis(inputDate) {
  const date = Fecha.parse(
    inputDate.toString().replace('T', ' '),
    'YYYY-MM-DD HH:mm:ss',
  );
  return date.getTime();
}

export function getCardNoFromPayment(paymentDetails) {
  let cardNo = '';
  if (paymentDetails != null && paymentDetails.paymentDetails != null) {
    paymentDetails.paymentDetails.forEach((paymentObj) => {
      if (paymentObj != null) {
        if (paymentObj.bankName) {
          if (
            !paymentObj.bankName.includes('MyWallet') &&
            !paymentObj.bankName.includes('My Wallet')
          ) {
            cardNo = `(**** ${paymentObj.cardNo.substr(
              paymentObj.cardNo.length - CARD_LAST_FOUR_DIGITS,
            )})`;
          }
        }
      }
    });
  }
  return cardNo;
}

export function getAmountDue(paymentSummary) {
  if (paymentSummary.paymentDetails.amountDueLcy > 0) {
    return appendCurrencySymbol(
      paymentSummary.paymentDetails.currencyCode,
      Math.round(paymentSummary.paymentDetails.amountDueLcy),
      0,
    );
  }
  return 0;
}

export function getHouseRules(hotelDetailResponse) {
  return (
    hotelDetailResponse &&
    hotelDetailResponse.hotelBookingInfo &&
    hotelDetailResponse.hotelBookingInfo.hotelDetailsList &&
    hotelDetailResponse.hotelBookingInfo.hotelDetailsList[0].houseRules
  );
}

export function getHostMsgUnreadCount(hotelDetailResponse) {
  return (
    hotelDetailResponse &&
    hotelDetailResponse.hostDetails &&
    hotelDetailResponse.hostDetails.unreadCount
  );
}

export function getNonAddedPassengerCountStr(hotelDetailResponse) {
  return getNoOfNonAddedPax(hotelDetailResponse) > 0
    ? ' + ' + getNoOfNonAddedPax(hotelDetailResponse)
    : '';
}

export function getNoOfNonAddedPax(hotelDetailResponse) {
  return (
    getNumberOfGuest(hotelDetailResponse) -
    getTotalNoOfGuestPresent(hotelDetailResponse)
  );
}

export function getNoOfNonAddedPaxType(hotelDetailResponse, paxType) {
  return (
    getTotalNumberOfPaxType(hotelDetailResponse, paxType) -
    getTotalNoOfPaxTypePresent(hotelDetailResponse, paxType)
  );
}

export function getTotalNumberOfPaxType(hotelDetailResponse, paxType) {
  const { hotelBookingInfo } = hotelDetailResponse;
  return PAX_TYPE.ADULT === paxType
    ? hotelBookingInfo.totalNumberOfAdults
    : hotelBookingInfo.totalNumberOfChilds;
}

export function getTotalNoOfGuestPresent(hotelDetailResponse) {
  const passengerDetails =
    hotelDetailResponse && hotelDetailResponse.passengerDetails;
  const passengers = (passengerDetails && passengerDetails.passengers) || [];
  return parseInt(passengers.length);
}

export function getTotalNoOfPaxTypePresent(hotelDetailResponse, paxType) {
  const passengerDetails =
    hotelDetailResponse && hotelDetailResponse.passengerDetails;
  const passengers = (passengerDetails && passengerDetails.passengers) || [];
  return parseInt(
    passengers.filter((passenger) => passenger.paxType == paxType).length,
  );
}

export function getNumberOfGuest(hotelDetailResponse) {
  const { hotelBookingInfo } = hotelDetailResponse;
  return (
    hotelBookingInfo.totalNumberOfAdults + hotelBookingInfo.totalNumberOfChilds
  );
}

export function getPassengerNameList(hotelDetailResponse) {
  const nameList =
    hotelDetailResponse &&
    hotelDetailResponse.passengerDetails &&
    hotelDetailResponse.passengerDetails.passengers &&
    hotelDetailResponse.passengerDetails.passengers.length > 0 &&
    hotelDetailResponse.passengerDetails.passengers.map((passenger) =>
      joinStrings(
        [passenger.firstName, passenger.middleName, passenger.lastName],
        ' ',
      ),
    );
  return joinStrings(nameList, ',  ');
}

export function getHotelName(hotelDetailResponse) {
  return (
    hotelDetailResponse &&
    hotelDetailResponse.hotelBookingInfo &&
    hotelDetailResponse.hotelBookingInfo.hotelDetailsList &&
    hotelDetailResponse.hotelBookingInfo.hotelDetailsList.length > 0 &&
    hotelDetailResponse.hotelBookingInfo.hotelDetailsList[0].name
  );
}

export function getHotelCheckInDate(hotelDetailResponse) {
  return (
    hotelDetailResponse &&
    hotelDetailResponse.hotelBookingInfo &&
    hotelDetailResponse.hotelBookingInfo.checkInDate
  );
}

export function getHotelCheckOutDate(hotelDetailResponse) {
  return (
    hotelDetailResponse &&
    hotelDetailResponse.hotelBookingInfo &&
    hotelDetailResponse.hotelBookingInfo.checkOutDate
  );
}

export function getPrimaryCustomerEmailId(hotelDetailResponse) {
  const { hotelBookingInfo } = hotelDetailResponse;
  return (
    hotelBookingInfo.primaryCustomerDetails &&
    hotelBookingInfo.primaryCustomerDetails.eMailID
  );
}

export function getPaxType(passenger, adultCount, childCount) {
  let paxTypeString = passenger.paxType + ' ';
  paxTypeString +=
    passenger.paxType === PAX_TYPE.ADULT ? adultCount : childCount;
  return paxTypeString;
}

export function getPaxName(passenger) {
  let paxName = joinStrings(
    [passenger.firstName, passenger.middleName, passenger.lastName],
    ' ',
  );
  paxName += passenger.isLeadPax ? ' (Primary Guest)' : '';
  return paxName;
}

export function getPageName(hotelDetailResponse, pageName) {
  return isIntlHotelBooking(hotelDetailResponse?.lobCode)
    ? 'Mob:customer support:Hotel International:'.concat(pageName)
    : 'Mob:customer support:Hotel Domestic:'.concat(pageName);
}

export function getCityName(hotelDetailResponse) {
  return (
    hotelDetailResponse &&
    hotelDetailResponse.hotelBookingInfo &&
    hotelDetailResponse.hotelBookingInfo.hotelDetailsList &&
    hotelDetailResponse.hotelBookingInfo.hotelDetailsList.length > 0 &&
    hotelDetailResponse.hotelBookingInfo.hotelDetailsList[0].city
  );
}

export function getTotalRefundableAmount(data) {
  let amount = '';
  if (
    data &&
    data.breakups &&
    data.breakups.refundAmount &&
    data.breakups.refundAmount.lbl &&
    data.breakups.refundAmount.lbl.value
  ) {
    amount = data.breakups.refundAmount.lbl.value;
  }
  return amount;
}

export function getPropertyType(hotelDetailResponse) {
  return hotelDetailResponse && hotelDetailResponse.hotelBookingInfo
    && hotelDetailResponse.hotelBookingInfo.hotelDetailsList
    && hotelDetailResponse.hotelBookingInfo.hotelDetailsList.length > 0
    && hotelDetailResponse.hotelBookingInfo.hotelDetailsList[0].propertyType;
}

export async function copyAddress(hotelBookingInfo, langAware = true) {
  let copyStatus = false;
  let locationFound = false;
  const { unableToFetchAddressErrorText, copiedToClipboardText, errorText: { soomethingWentWrongOnlyError } } = getStaticData(langAware);
  if (!_.isEmpty(hotelBookingInfo)){
    const {hotelDetailsList} = hotelBookingInfo;
    if (!_.isEmpty(hotelDetailsList)) {
      let addressString = null;
      hotelDetailsList.every((item) => {
        let str = getHotelAddressString(item);
        if(!isNullOrEmpty(str)){ addressString = str; return false;}
      });
      if(addressString){
        locationFound = true;
        copyStatus = await copyToClipboard(addressString);
      }
    }
  }
  if(!locationFound){
    showLongToast(unableToFetchAddressErrorText);
  }else if(copyStatus) {
    showLongToast(copiedToClipboardText);
  } else {
    showLongToast(soomethingWentWrongOnlyError);
  }
}

export function shareLocation(response) {
  const {hotelBookingInfo} = response;
  const {hotelDetailsList} = hotelBookingInfo;
  const message = hotelDetailsList[0].shareLocationText;
  if (message) {
    Share.share({
      message: message
    });
  }
}

export function getNoOfGuestsInRoom(hotelBookingInfo, roomno) {
  const {roomDetails} = hotelBookingInfo;
  const roomList = roomDetails && roomDetails.filter(room => room.roomNumber == roomno);
  const noOfAdult = roomList && roomList.length > 0 && parseInt(roomList[0].totalNumberOfAdults) || 0;
  const noOfChild = roomList && roomList.length > 0 && parseInt(roomList[0].totalNumberOfChilds) || 0;
  const extraAdult = noOfAdult === 2 ? `${noOfAdult-1} Adult` : (noOfAdult > 2 ? `${noOfAdult-1} Adults` : '')
  const extraChild = noOfChild === 1 ? `${noOfChild} Child` : (noOfChild > 1 ? `${noOfChild} Children` : '')
  const extraGuests = extraChild && extraAdult ? `${extraAdult}, ${extraChild}` : (extraAdult || extraChild);
  return extraGuests;
}

export function getPrimaryCustomerName(response) {
  const primaryCustomer = response.hotelBookingInfo.primaryCustomerDetails;
  return `${primaryCustomer.firstName} ${primaryCustomer.middleName} ${primaryCustomer.lastName}`;
}

export function addActionManually(actionFamily) {
  return addCardListManually('','','','',actionFamily)[0];
}

export function openPropertyDetails(response) {
  Actions.hotelPropertyDetails({
    response
  });
}

export function openIndividualPhotograph(imageList, currImg = 0, headerTitle, headerSubtitle) {
  Actions.viewIndividualPhotograph({
    imageList, currImg, headerTitle, headerSubtitle
  });
}

export function getHotelAndDatesSubtitle(hotelDetailResponse) {
  const { checkInDate, checkOutDate, commonFields } = hotelDetailResponse.hotelBookingInfo;
  return `${getHotelName(hotelDetailResponse)} • ${(commonFields && !!commonFields.checkinCheckoutText2) ? commonFields.checkinCheckoutText2 : `${fillCheckInCheckOutData(checkInDate, 'DD MMM')}-${fillCheckInCheckOutData(checkOutDate, 'DD MMM')}`}`;
}

export function getCardAndActionFromAllCards(hotelDetailResponse, actionFamily) {
  const {cardList} = hotelDetailResponse.layoutDetail;
  let cardFound, actionFound;
  if (isNotNullAndEmpty(cardList)) {
    cardList.find(card => {
      if (isNotNullAndEmpty(card.actionList)) {
        cardFound = card;
        actionFound = card.actionList.find(action => action.actionFamily === actionFamily);
        return actionFound;
      }
    })
  }
  return {card: cardFound, action: actionFound};
}

export function getHotelImageURLList(hotelDetailResponse) {
  return hotelDetailResponse && hotelDetailResponse.hotelBookingInfo
    && hotelDetailResponse.hotelBookingInfo.hotelDetailsList
    && hotelDetailResponse.hotelBookingInfo.hotelDetailsList.length > 0
    && hotelDetailResponse.hotelBookingInfo.hotelDetailsList[0].hotelImageURLList;
}

export function openSpecialRequest(requestTypeKey, response, langAware = true) {
  const { errorText: { smthWentWrongTryLater } } = getStaticData(langAware);
  if (response.requestDetailMapping) {
    const requestDetailMappingList = _.flatten(Object.values(response.requestDetailMapping));
    const subRequestData = requestDetailMappingList && requestDetailMappingList.find(requestDetail => requestDetail.requestTypeKey === requestTypeKey);
    if (subRequestData) {
      Actions.submitRequestPage({subRequestData, response});
    } else {
      showLongToast(smthWentWrongTryLater);
    }
  }
}

const openWriteToProperty = (requestTypeKey, response, langAware = true) => {
  const { errorText: { smthWentWrongTryLater } } = getStaticData(langAware);
  if (response.requestDetailMapping) {
    const requestDetailMappingList = _.flatten(Object.values(response.requestDetailMapping));
    const subRequestData = requestDetailMappingList && requestDetailMappingList.find(requestDetail => requestDetail.requestTypeKey === requestTypeKey);
    if (subRequestData) {
      Actions.requestWriteToHotel({ requestData: subRequestData, response });
    } else {
      showLongToast(smthWentWrongTryLater);
    }
  }
}

export function isUpcomingBooking(response) {
  const state = response.bookingState && response.bookingState.state.toLowerCase();
  if (state === 'upcoming') {
    return true;
  }
  return false;
}

export function getIdProofRules(hotelDetailResponse) {
  return hotelDetailResponse && hotelDetailResponse.hotelBookingInfo
    && hotelDetailResponse.hotelBookingInfo.hotelDetailsList
    && hotelDetailResponse.hotelBookingInfo.hotelDetailsList.length > 0
    && hotelDetailResponse.hotelBookingInfo.hotelDetailsList[0].idProofRules;
}

export function getStayDatesText(hotelDetailResponse) {
  const { checkInDate, checkOutDate, commonFields } = hotelDetailResponse.hotelBookingInfo;
  return commonFields && commonFields.checkinCheckoutText2 ? commonFields.checkinCheckoutText2 : `${fillCheckInCheckOutData(checkInDate, 'DD MMM')} - ${fillCheckInCheckOutData(checkOutDate, 'DD MMM')}`;
}

export function getRoomImages(hotelDetailResponse) {
  return hotelDetailResponse && hotelDetailResponse.hotelBookingInfo
    && hotelDetailResponse.hotelBookingInfo.roomDetails
    && hotelDetailResponse.hotelBookingInfo.roomDetails.length > 0
    && hotelDetailResponse.hotelBookingInfo.roomDetails.some(room => isNotNullAndEmpty(room.roomImageURL)) ;
}

export function showShareIcon(response) {
  if (isUpcomingOrOnTripBooking(response)) {
    const cardAndAction = getCardAndActionFromAllCards(response, HotelBookingDetailsConstant.ACTION_RESEND_CONFIRMATION);
    if (cardAndAction.card && cardAndAction.action) {
      return true;
    }
  }
  return false;
}

export function showDownloadIcon(response) {
  if (isUpcomingOrOnTripBooking(response)) {
    const cardAndAction = getCardAndActionFromAllCards(response, HotelBookingDetailsConstant.ACTION_DOWNLOAD_VOUCHER);
    if (cardAndAction.card && cardAndAction.action) {
      return true;
    }
  }
  return false;
}

export function isUpcomingOrOnTripBooking(response) {
  const state = response.bookingState && response.bookingState.state && response.bookingState.state.toLowerCase();
  if (state === ON_TRIP_STATE.toLowerCase() || state === UPCOMING_STATE.toLowerCase()) {
    return true;
  }
  return false;
}

export const getSimilarHotelsProps = (hotelDetailsResponse) => {
  const { hotelBookingInfo } = hotelDetailsResponse || {};
  if (_.isEmpty(hotelBookingInfo)) return {};
  const {
    checkInDate: checkIn, checkOutDate: checkOut, hotelDetailsList, roomDetails = []
  } = hotelBookingInfo;
  const {
    city: cityName, cityCode, code: hotelId, country: countryCode
  } = (!_.isEmpty(hotelDetailsList) && hotelDetailsList[0]) || {};
  const roomStayCandidates = roomDetails.map(({totalNumberOfAdults: adultCount, childAgeList: childAges = []}) => {
      return  { adultCount, childAges };
  });
  const { currencyCode: currency } = (!_.isEmpty(roomDetails) && roomDetails[0]) || {};
  const limit = 10;
  const pageContext = 'HotelBookingDetails';
  const props = {
      checkIn, checkOut, cityName, cityCode,
      hotelId, limit, countryCode, pageContext,
      roomStayCandidates, currency
  };
  return props;
}

export const showPage = (response, pageNumber) => {
  const cards = response.layoutDetail.cardList;
  if (isNotNullAndEmpty(cards.filter(card => card.pageNumber === pageNumber))) {
    return true;
  }
  return false;
}

export const getRoomInfo = (roomDetail) => {
  const {
    totalNumberOfAdults: noofadult, totalNumberOfChilds: noofchild, roomNumber: roomno, childAges,
    childAgeList
  } = roomDetail;
  return {
    noofadult,
    noofchild,
    roomno,
    childAges,
    childAgeList: childAgeList.slice(),
  };
}

export const getRoomInfoIterable = (roomDetailList) => {
  return roomDetailList.map((roomDetail) => getRoomInfo(roomDetail));
}

export const showHotelImage = (hotelBookingInfo) => {
  if (isNotNullAndEmpty(hotelBookingInfo.imageURL)) {
    return hotelBookingInfo.imageURL;
  }
  return hotelBookingInfo.hotelImageURLList && hotelBookingInfo.hotelImageURLList[0];
}

export const isNonRefundable = (hotelBookingDetailResponse) => {
  return hotelBookingDetailResponse && hotelBookingDetailResponse.isNonRefundable;
}

export const isPAH = (hotelBookingDetailResponse) => {
  return hotelBookingDetailResponse && hotelBookingDetailResponse.hotelBookingInfo && hotelBookingDetailResponse.hotelBookingInfo.ispah;
}

export const handleDateChangeClick = (action, response, card) => {
  if (action.actionAlertHeaderText && action.actionAlertMainText) {
    Alert.alert(action.actionAlertHeaderText, action.actionAlertMainText);
  } else {
    Actions.hotelChangeDate({bookingId: response.bookingID , card});
  }
}

const showProceedForCancellationOverlay = (hotelBookingDetailResponse) => {
  const cardAndAction = getCardAndActionFromAllCards(hotelBookingDetailResponse, HotelBookingDetailsConstant.ACTION_CHANGE_DATES);
  return cardAndAction.card && cardAndAction.action && hotelBookingDetailResponse.isNonRefundable;
}

export const isBNPL = (hotelBookingDetailResponse) => {
  return hotelBookingDetailResponse && (hotelBookingDetailResponse.isBNPLBooking || hotelBookingDetailResponse.bookAtZero);
}

export const fetchHotelDetailsResponse = (bookingId, langAware = false) => {
  return async dispatch => {
    try {
      dispatch(createAction(HOTEL_DETAIL_ACTIONS.FETCH_HOTEL_BOOKING_DETAILS_ENG));
      const hasNetwork = await isNetworkAvailable();
      if (!hasNetwork) {
        this.setState({ viewState: ViewState.NO_INTERNET });
        dispatch(createAction(HOTEL_DETAIL_ACTIONS.FETCH_HOTEL_BOOKING_DETAILS_ENG, { errorType: ERROR_TYPES.NO_INTERNET, errorDesc: '' }));
        return;
      }
      let response = {};
      let body = {};
      const userInfo = await HotelBookingModule.getRequestHeader(
        bookingId,
        'HOTEL_BOOKING_DETAIL',
      );
      const mmtAuth = userInfo.mmtAuth;
      const loggingTrackInfo = userInfo.loggingTrackingInfo;

      const url = HOTEL_BOOKING_DETAILS_URL + bookingId;
      response = await fetch(url, {
        headers: await getCommonHeaders(mmtAuth, loggingTrackInfo, { langAware }),
      });
      body = await response.json();

      if (response && response.ok && body) {
        dispatch(createAction(HOTEL_DETAIL_ACTIONS.HOTEL_DETAILS_API_SUCCESS_ENG, body));
      } else if (response.status === 500) {
        const errorBody = body;
        if (Platform.OS !== 'web' && errorBody.message && errorBody.message.toUpperCase() === 'AUTHENTICATION FAILED') {
          HotelBookingModule.logInUser();
          dispatch(createAction(HOTEL_DETAIL_ACTIONS.HOTEL_BOOKING_DETAILS_API_ERROR_ENG, { errorType: ERROR_TYPES.AUTH_FAILURE }));
        } else {
          dispatch(createAction(HOTEL_DETAIL_ACTIONS.HOTEL_BOOKING_DETAILS_API_ERROR_ENG, {}));
        }
      } else {
        dispatch(createAction(HOTEL_DETAIL_ACTIONS.HOTEL_BOOKING_DETAILS_API_ERROR_ENG, {}));
      }
    } catch (error) {
      dispatch(createAction(HOTEL_DETAIL_ACTIONS.HOTEL_BOOKING_DETAILS_API_ERROR_ENG, {}));
    }
  };
}

export const getUnreadMsgCount = (vendorChatDetails) => {
  return vendorChatDetails.reduce((totalUnreadMsgCount, chatDetail) => {
    return totalUnreadMsgCount + (parseInt(chatDetail.unreadMessagesCount) || 0);
  }, 0);
};

const sendInvoiceMail = async (bookingResponse) => {
  const { invoiceSentSuccessMsg, invoiceSentFailedMsg } = getStaticData(true);
  try {
    const hasNetwork = await isNetworkAvailable();
    if (!hasNetwork) {
      Alert.alert('Please check your internet connection');
      return;
    }
    

    const { bookingID } = bookingResponse || {};
    const request = {
      userId: Platform.OS.toUpperCase(),
      triggerName: 'Hotel_CustomerInvoice',
      bookingId: bookingID,
    };
    const userInfo = await HotelBookingModule.getRequestHeader(bookingID, 'HOTEL_GET_INVOICE');
    const response = await fetch(HOTEL_GET_INVOICE_URL, {
      method: 'POST',
      body: JSON.stringify(request),
      headers: await getCommonHeaders(
        userInfo.mmtAuth,
        userInfo.loggingTrackingInfo,
      ),
    });
    if (response.ok) {
      const body = await response.json();
      if (body.success) {
        showLongToast(invoiceSentSuccessMsg);
      } else {
        showLongToast(invoiceSentFailedMsg);
      }
    } else {
      showLongToast(invoiceSentFailedMsg);
    }
  } catch (error) {
    showLongToast(invoiceSentFailedMsg);
  }
};

export const openHotelReview = async (response) => {
  if (Platform.OS === 'ios') {
    response.reactTag = getRootTag();
    const pageName = 'mob:customer support:Hotel Domestic:TripDetails'
    HotelBookingModule.onButtonClicked(
      JSON.stringify({
        actionList: [
          { actionRank: 2, actionId: 'CallHotel_Rb', actionType: 'RoundButton', actionLabeltext: 'Contact Property', actionFamily: 'CallHotel' },
          { actionRank: 2, actionId: 'PropertyDetails_Rb', actionType: 'RoundButton', actionLabeltext: 'Property Details', actionFamily: 'PropertyDetails' }],
        bookingType: "DEFAULT",
        cardId: "TopStatusCard_ModifiedBookingTripCompleted",
        cardRank: 1,
        pageNumber: 1,
      }),
      JSON.stringify({ actionFamily: HotelBookingDetailsConstant.ACTION_WRITE_HOTEL_REVIEW }),
      JSON.stringify(response),
      pageName,
    );
  } else {
    const staticData = getStaticData();
    const hotelFeedbackData = await HotelBookingModule.getHotelFeedbackDate();
    const reviewModelData = hotelFeedbackData.REVIEW_MODEL || JSON.stringify(getFeedbackReviewModel(response));
    if (reviewModelData) {
      HotelBookingModule.openWriteReviewActivity(reviewModelData);
    } else {
      showLongToast(staticData.errorText.soomethingWentWrongOnlyError);
    }
  }
}
