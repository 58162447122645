import { colors, fonts } from '@mmt/legacy-commons/Styles/globalStyles';

const styles = {
  PartCancellationHead: {
    backgroundColor: colors.white,
    paddingVertical: 18,
    flexDirection: 'row',
    marginLeft: 10,
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#ddd'
  },
  ActionBtn: {
    backgroundColor: colors.white,
  },
  borderbtm: {
    borderBottomWidth: 1,
    borderBottomColor: '#ddd'
  },
  refundCardOuter: {
    paddingHorizontal: 16,
    paddingTop: 16,
    paddingBottom: 10
  },
  refundCardWrap: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  MimaRadio: {
    width: 18,
    height: 18,
    backgroundColor: '#fff',
    borderRadius: 20,
    overflow: 'hidden',
    borderWidth: 1,
    borderColor: '#008cff',
    marginRight: 10
  },
  MimaRadioInside: {
    width: 12,
    height: 12,
    backgroundColor: '#008cff',
    borderRadius: 20,
    overflow: 'hidden',
    marginLeft: 2,
    marginTop: 2
  },
  radioDisabled: { borderColor: '#e1e1e1' },
  refundCardLeft: {
    width: '75%'
  },
  refundText: {
    marginLeft: 38
  },
  subHeadText: {marginBottom: 18, color: '#4a4a4a', fontSize: 12, fontFamily: fonts.regular}
};

export default styles;
