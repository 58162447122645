import React from 'react';
import { View, Text } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import createStyles from '../changeDateOverlayCss';
import FAQList from './FaqList';
import BusCancelRebookConstant from '../../../page/CancelRebookConstants';
import { getFont } from '../../../../PostSalesStyles';
import { useTheme } from '../../../../theme/theme.context';
function FrequentlyAsked({ faqs }) {
  const { fsStyle, ...fonts } = getFont();
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);
  return (
    <View>
      <Text
        style={[
          AtomicCss.blackText,
          fonts.boldFontFamily,
          fsStyle.font16,
          AtomicCss.marginBottom20,
        ]}
      >
        {BusCancelRebookConstant.FAQ_HEADING}
      </Text>
      {faqs.map((item, index) => {
        return (
          <View style={styles.faqListWrapper}>
            <FAQList index={index} faq={item} lastElemet={index === faqs.length - 1} />
          </View>
        );
      })}
    </View>
  );
}

export default FrequentlyAsked;
