import React, {useState, useEffect} from 'react';
import { View } from 'react-native';
import SliderImages from '../SliderImages';
import NoUpcomingText from '../NoUpcomingText';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import createStyle from './style';
import { loadWhereToGoNoTripsData } from '../../../../data/api';
import { WhereToGoNoTripsObject } from '../../../../types';

const NoTopBookings = () => {
    const [whereToGoData, setWhereToGoData] = useState<WhereToGoNoTripsObject>();
    const loadData = async ()=> {
        const data = await loadWhereToGoNoTripsData();
        setWhereToGoData(data);
    }
    useEffect(()=> {
        loadData();
    }, []);
    const whereToGoList = whereToGoData?.data?.collection?.list;
    if(!whereToGoList){
        return null;
    }
    const { psTheme: theme } = useTheme();
    const styles = createStyle(theme);
    return (
        <View style={styles.scrollWrapper}>
            <View style={styles.topSliderCard}>
                <NoUpcomingText noTopBookings={true} />
                {whereToGoList && <SliderImages data={whereToGoList} />}
            </View>
            <View style={[AtomicCss.marginBottom24]}>
            </View>
        </View>
    )
}



export default NoTopBookings;