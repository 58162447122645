import { isNetworkAvailable } from '@mmt/legacy-commons/Common/utils/AppUtils';
import FlightBookingModule from '@mmt/legacy-commons/Native/FlightBookingModule';
import { LOBNAMES } from '../../../../PostSalesConstant';
import FlightEventsLogger, { FLIGHT_LOG_ACTIONS } from '../../details/helper/FlightEventsLogger';
import { showShortToast } from '@mmt/legacy-commons/Common/Components/Toast';
import { createHoldRequest, createWebCheckinRequest } from './requestAdapter';
import {
  FLIGHT_ANCILLARY,
  NO_INTERNET_CONNECTIVITY_TEXT,
} from '../constants';
import {
  DO_WEBCHECKIN_URL,
  getCommonHeaders,
  FLIGHT_ANCILLARY_HOLD_URL,
  FLIGHT_GET_ADDRESS_BY_PINCODE
} from '../../../../utils/NetworkUtils';
import { WEBCHECKIN_TRAVELLER_FORM } from '../constants';
// import data from "./dummy.json"
export const holdAncillariesApi = async (selectedAncillaryDetail, ancillaryResponse) => {
  try {
    const hasNetwork = await isNetworkAvailable();
    if (!hasNetwork) {
      showShortToast(NO_INTERNET_CONNECTIVITY_TEXT);
      return {
        hasNetwork: false,
      };
    }
    const holdAncillaryRequest = createHoldRequest(selectedAncillaryDetail, ancillaryResponse);
    const userInfo = await FlightBookingModule.getRequestHeader(
      holdAncillaryRequest.bookingId,
      FLIGHT_ANCILLARY,
    );
    const response = await fetch(FLIGHT_ANCILLARY_HOLD_URL, {
      method: 'POST',
      headers: await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo, {
        psLob: LOBNAMES.FLIGHT,
      }),
      body: JSON.stringify(holdAncillaryRequest),
    });
    if (response.ok) {
      const body = await response.json();
      FlightEventsLogger.successEvent(FLIGHT_LOG_ACTIONS.ANCILLARY_HOLD_API, body);
      if (body && !body?.success){
        FlightEventsLogger.failureEvent(FLIGHT_LOG_ACTIONS.ANCILLARY_HOLD_API, response);
        return {
          error: body,
        }
      }
      return body;
    } else {
      FlightEventsLogger.failureEvent(FLIGHT_LOG_ACTIONS.ANCILLARY_HOLD_API, response);
      return {
        error: response,
      };
    }
  } catch (error) {
    FlightEventsLogger.failureEvent(FLIGHT_LOG_ACTIONS.ANCILLARY_HOLD_API, error);
    return {
      error,
    };
  }
};

export const getAddressByPincode = async (bookingID, pincode) => {
  const userInfo = await FlightBookingModule.getRequestHeader(bookingID, WEBCHECKIN_TRAVELLER_FORM);
  let headers = await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo, {});
  try {
    const response = await fetch(FLIGHT_GET_ADDRESS_BY_PINCODE + pincode, {
      headers: headers,
    });
    if (response.ok) {
      const body = await response.json();
      return body;
    } else {
      return null;
    }
  } catch (error) {
    console.log('error', error);
    return null;
  }
};

export const webCheckinApi = async (ancillaryData, selectedAncillaryDetail) => {
  try {
    const hasNetwork = await isNetworkAvailable();
    if (!hasNetwork) {
      showShortToast(NO_INTERNET_CONNECTIVITY_TEXT);
      return;
    }
    const webcheckinRequest = createWebCheckinRequest(ancillaryData, selectedAncillaryDetail);
    const userInfo = await FlightBookingModule.getRequestHeader(
      webcheckinRequest.bookingId,
      FLIGHT_ANCILLARY,
    );
    let response = await fetch(DO_WEBCHECKIN_URL, {
      method: 'POST',
      headers: await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo),
      body: JSON.stringify(webcheckinRequest),
    });

    if (response.ok) {
      const body = await response.json();
      if (body && !body?.success){
        return {
          error: true,
          response: body,
        };
      }
      return body;
    } else {
      return {
        error: true,
        response: response,
      };
    }
  } catch (error) {
    console.log(error);
    return error;
  }
};
