import { StyleSheet } from 'react-native';
import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';

const styles = StyleSheet.create({
  infoCardWrapper: {
    backgroundColor: '#fff',
    ...getPlatformElevation(1),
    marginBottom: 1,
  },

  infoCardContent: {
    paddingHorizontal: 8,
    paddingVertical: 15,
    flex: 1,
  },
  redirectListWrapper: {
    paddingRight: 11,
  },
  infoCustom: {
    fontSize: 14,
    color: '#4a4a4a',
    lineHeight: 18,
  },
  headingIconWrapper: {
    width: 46,
    alignItems: 'flex-start',
    top: 14,
    left: 16,
  },
  headingIconStyle: {
    width: 17,
    height: 21,
  },

  redirectBtnWrapper: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 60,
    paddingRight: 35,
    justifyContent: 'space-between',
    flexDirection: 'row',
    backgroundColor: '#fff',
    borderBottomWidth: 1,
    borderBottomColor: '#ededed',
    alignItems: 'center',
  },

  rightArrowIcon: {
    width: 9,
    height: 13,
  },

  linkBtnTxt: {
    color: '#008cff',
  },

  disIcon: {
    width: 16,
    height: 16,
    marginRight: 5,
  },
  greenTxt: {
    color: '#1a7971',
  },
  totalRefundprice: {
    paddingRight: 15,
  },
  paymentDtlsSection: {
    paddingLeft: 65,
    paddingVertical: 15,
    paddingRight: 8,
  },
  paymentInnerDtls: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  gstInfoWrapper: {
    borderRadius: 4,
    padding: 10,
    marginLeft: 40,
    marginRight: 10,
    marginBottom: 10,
  },
  elevation1: {
    backgroundColor: '#ffffff',
    ...getPlatformElevation(1),
  },
  paylaterComp: {
    backgroundColor: colors.grayBg,
    paddingHorizontal: 12,
    paddingVertical: 14,
    borderRadius: 4,
    flexDirection: 'row',
    marginLeft: 40,
    marginRight: 10,
    marginBottom: 10,
  },
  paylaterIcon: {
    width: 16,
    height: 16,
  },
  paylaterRight: {
    flex: 1,
    paddingLeft: 10,
    flexDirection: 'column',
  },
});

export default styles;
