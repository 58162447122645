import { DEFAULT_THEME } from 'apps/post-sales/src/theme/default.theme';
import { ImageStyle, StyleSheet, TextStyle, ViewStyle } from 'react-native';

interface Styles {
    listRow: ViewStyle;
    listDtlsSection: ViewStyle;
    iconPlaceHolder: TextStyle;
    cabIconStyle: ImageStyle;
    rgtArrowStyle: ImageStyle;
    fltIconStyle: ImageStyle;
    oneWayArrowStyle: ImageStyle;
    marHor5: ImageStyle;
    cancelledTxt: ViewStyle | TextStyle;
    upcomingTxt: ViewStyle | TextStyle;
    completedTxt: ViewStyle | TextStyle;
}

const createStyles = <Theme extends typeof DEFAULT_THEME>(theme: Theme) =>
  StyleSheet.create<Styles>({
    listRow: {
        borderTopWidth: 1,
        borderTopColor: theme.color.cabsGreyBg,
        padding: 16,
        alignItems: 'center',
        flexDirection: 'row',
      },
      listDtlsSection: {
        flexDirection: 'row',
        flex: 1,
        marginRight: 15,
      },
      iconPlaceHolder: {
        width: 32,
        marginTop: 3,
      },
      cabIconStyle: {
        width: 18,
        height: 15,
        alignSelf: 'flex-start',
      },
      fltIconStyle: {
        width: 22,
        height: 12,
        alignSelf: 'flex-start',
      },
      rgtArrowStyle: {
        width: 9,
        height: 13,
      },
      oneWayArrowStyle: {
        width: 10,
        height: 8,
      },
      marHor5: {
        marginHorizontal: 5,
      },
      cancelledTxt: {
        color: theme.color.lightYello,
      },
      upcomingTxt: {
        color: theme.color.green,
      },
      completedTxt: {
        color: theme.color.greyLight,
      },
  });

export default createStyles;
