import React from 'react';
import Spinner from '@Frontend_Ui_Lib_App/Spinner/lib/Spinner';
import {themeColors } from 'apps/post-sales/src/PostSalesStyles';

interface SpinnerLoaderWprProps{
  size?: number;
  strokeWidth?: number;
  progressPercent?: number;
  speed?: number;
  wrapperTestId?: string;
  color?: string;
}

const SpinnerLoaderWpr: React.FC<SpinnerLoaderWprProps> = ({

  size = 30,
  strokeWidth = 3,
  progressPercent = 80,
  speed = 1.5,
  color = themeColors.postSalesLoaderColor,
  wrapperTestId = '',

}) => {

  return (
          <Spinner
              size={size}
              strokeWidth={strokeWidth}
              progressPercent={progressPercent}
              speed={speed}
              color={color}
              wrapperTestId={wrapperTestId}
          />
  );
};


export default SpinnerLoaderWpr;
