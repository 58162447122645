import React from 'react';
import { View, Text } from 'react-native';
import getStyles from '../../details/components/HotelMamiCss';
import PropTypes from 'prop-types';
import { getStaticData } from '../../../staticData/staticData';
import { LANG_AWARE } from '../../HotelConstants';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

class HotelModificationInfoStrip extends React.Component {
  static navigationOptions = {
    header: null,
  };

  constructor(props) {
    super(props);
    this.fonts = getFont(true);
    this.styles = getStyles(this.fonts);
  }

  render() {
    const staticData = getStaticData(LANG_AWARE.MODIFICATION);
    const stage = this.props.modificationState;

    return (
      <View style={this.styles.GuestInfoStrip}>
        <Text
          style={
            stage === 'Modify' ? [this.styles.boldFont, this.styles.DefaultText] : ''
          }
        >
          {this.props.action}
        </Text>
        <Text
          style={[
            stage === 'Review' ? this.styles.boldFont : this.styles.regularFont,
            this.styles.DefaultText,
            stage === 'ReviewConfirm' ? this.styles.hideView : this.styles.showView,
          ]}
        >
          {staticData.hotelModificationInfoStripCardText.additionalText.twoSeeAvailablityText}
        </Text>
        <Text
          style={[
            stage === 'ReviewConfirm' ? this.styles.boldFont : this.styles.regularFont,
            this.styles.DefaultText,
            stage === 'ReviewConfirm' ? this.styles.showView : this.styles.hideView,
          ]}
        >
          {
            staticData.hotelModificationInfoStripCardText
              .additionalText.twoSeeAvailablityAndConfirmText
          }
        </Text>
        <Text
          style={[
            stage === 'PAY' ? this.styles.boldFont : this.styles.regularFont,
            this.styles.DefaultText,
            stage === 'ReviewConfirm' ? this.styles.hideView : this.styles.showView,
          ]}
        >
          {staticData.hotelModificationInfoStripCardText.additionalText.threePayText}
        </Text>
      </View>
    );
  }
}

HotelModificationInfoStrip.propTypes = {
  modificationState: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
};
export default HotelModificationInfoStrip;
