import React from 'react';
import {View, Text, Image, StyleSheet, TouchableOpacity} from 'react-native';
import {handleZcClickEvent} from "../../../utils/SelfDriveBookingDetailUtil";
import {CALL} from '../../../SelfDriveImageConstant';

class Call extends React.Component {

    render() {
        return (
            <View style={styles.SupportTxt}>
                <TouchableOpacity onPress={() => this.handleClick(this.props.action)}>
                <Image style={[styles.RbBtnWithIconImg, styles.mar3Bt]}
                       source={CALL}/>
                    <Text style={[styles.RbBtnWithIconText]}>{this.props.action.actionLabeltext}</Text>
                </TouchableOpacity>
            </View>
        );
    }

    handleClick = (action) => {
        handleZcClickEvent("", action, this.props.response, this.props.pageName).then(r => console.log(''));
    };
}

const styles = StyleSheet.create({
    SupportTxt: {
        width: 105,
        paddingLeft: 6,
        paddingRight: 6
    },
    RbBtnWithIconImg: {
        width: 36,
        height: 36,
        alignSelf: 'center'
    },
    mar3Bt: {
        marginBottom: 3
    },
    RbBtnWithIconText: {
        textAlign: 'center',
        color: '#008cff',
        lineHeight: 20,
        fontFamily: 'Lato-Bold'
    }
});

export default Call;
