import React from 'react';
import { View, Text, Image } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { insuranceIcon } from '../../HolidayBookingConstants';
import isEmpty from 'lodash/isEmpty';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import getStyles from './style';

interface InsuranceDetailCardProps {
  insuranceDetail: {
    heading: string,
    companyName: string,
    insuranceIconUrl: string,
    tagText: string,
    tagTextColor: string,
    data: {
      key: string,
      value: string
    }[]
  }
}
const InsuranceDetailCard = ({ insuranceDetail }: InsuranceDetailCardProps) => {
  if (isEmpty(insuranceDetail)) {
    return null;
  }
  const fonts = getFont();
  const {psTheme: theme} = useTheme();
  const styles = getStyles(theme, fonts);
  const {heading, companyName, insuranceIconUrl, tagText, tagTextColor, data} = insuranceDetail || {};
  return (<View style={[AtomicCss.marginBottom10, styles.cardBorder]}>
    <View style={styles.infoCardWrapper}>
      {(!isEmpty(heading)) && <View style={styles.headingSection}>
        <Image style={styles.insuranceIconStyle} source={insuranceIcon} />
        <Text style={styles.headingText}>
          {heading}
        </Text>
      </View>}
      {!isEmpty(companyName) && <View style={styles.infoSection}>
        <Image style={styles.companyIconStyle} source={{uri: insuranceIconUrl}} />
        <Text style={styles.infoSectionTxt}>
          {companyName}
        </Text>
      </View>}

      <View style={styles.innerDtlsWrapper}>
          {!isEmpty(tagText) && <Text style={[styles.infoSectionTxt, { color: tagTextColor }]}>
            {tagText}
          </Text>}
        {!isEmpty(data) && data.map((item) =>(
          <View style={styles.itemDetail}>
            <View style={styles.itemRow}>
              <Text style={styles.rowLeftText}>{item.key}</Text>
            </View>
            <View style={styles.itemRow}>
              <Text style={styles.title}>{item.value} </Text>
            </View>
          </View>
        ))}
      </View>
    </View>
  </View>
  );
};


export default InsuranceDetailCard;