import { StyleSheet } from 'react-native';
import { Fonts, Theme } from 'apps/post-sales/src/cab/types';

const getStyle = (fonts: Fonts, theme: Theme) => {
  return StyleSheet.create({
    paddingAll30: {
      padding: 30,
    },
    carIconStyle: {
      width: 100,
      height: 100,
      marginBottom: 20,
    },
    calendarIconStyle: {
      width: 62,
      height: 55,
      marginBottom: 20,
    },
    headingText: {
      fontSize: fonts.fontSize.font20,
      ...fonts.boldFontFamily,
      color: theme.color.black,
    },
    p: {
      marginBottom: 12,
      textAlign: 'center',
      fontSize: fonts.fontSize.font13,
      color: theme.color.defaultTextColor,
    },
    buttonWrapStyle: {
      paddingVertical: 15,
      backgroundColor: theme.color.skyBlue9,
    },
    btnTextStyle: {
      fontSize: fonts.fontSize.font16,
      ...fonts.boldFontFamily,
    },
  });
};

export default getStyle;