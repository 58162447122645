import React from 'react';
import { View, Text, Image, TouchableOpacity, StyleSheet } from 'react-native';
import { isEmpty } from 'lodash';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';
import {
  ALERT_RED_TRIANGLE_ICON,
  AIRLINE_PHONE_ICON,
  ONE_WAY_JRNY_ICON,
  GREY_INFO_ICON,
} from '../../../FlightImageConstants';
import { openDialer } from '../../../../Common/commonUtil';
import { getFont } from '../../../../PostSalesStyles';

const { fsStyle, ...fonts } = getFont(true);

class FalseClaimSegmentCard extends React.Component {
  render() {
    const { heading, subHeading, description, airlineContacts, extraInfo } = this.props;
    return (
      <View style={[AtomicCss.flexColumn, styles.whiteCard]}>
        {!isEmpty(heading) && (
          <View style={styles.destRow}>
            <Text
              style={[
                styles.fltDtlsTxt,
                AtomicCss.marginBottom5,
                AtomicCss.marginRight2,
                fsStyle.font14,
              ]}
            >
              {heading.fromCity}
            </Text>
            <Image style={styles.arrowIcon} source={ONE_WAY_JRNY_ICON} />
            <Text
              style={[
                styles.fltDtlsTxt,
                AtomicCss.marginBottom5,
                AtomicCss.marginRight2,
                fsStyle.font14,
              ]}
            >
              {heading.toCity}
            </Text>
            <Text
              style={[
                styles.fltDtlsDateTxt,
                AtomicCss.marginBottom5,
                AtomicCss.marginLeft3,
                AtomicCss.marginRight2,
                fsStyle.font12,
              ]}
            >
              {heading.travelDate}
            </Text>
            <Text style={[styles.fltDtlsDateTxt, AtomicCss.marginBottom5, fsStyle.font12]}>
              , {heading.airline}
            </Text>
          </View>
        )}
        <View style={[styles.detlsWrapper]}>
          <View style={[styles.imgWrapper, { flexBasis: '10%' }]}>
            <Image style={styles.errorStyle} source={ALERT_RED_TRIANGLE_ICON} />
          </View>
          <View style={{ flexBasis: '90%' }}>
            {!isEmpty(subHeading) && (
              <Text style={[styles.infoTxt, AtomicCss.marginBottom10, fsStyle.font12]}>
                {subHeading}
              </Text>
            )}
          </View>
        </View>
        <View style={[styles.detlsWrapper]}>
          <View style={[styles.imgWrapper, { flexBasis: '10%' }]}>
            {!isEmpty(description) && (
              <Image style={styles.infoIconStyle} source={GREY_INFO_ICON} />
            )}
          </View>
          <View style={{ flexBasis: '90%' }}>
            {!isEmpty(description) &&
              description.map((desc) => (
                <Text style={[styles.descTxt, AtomicCss.marginBottom10, fsStyle.font12]}>
                  {desc}
                </Text>
              ))}
            {!isEmpty(airlineContacts) &&
              airlineContacts.map((airline) => (
                <View style={[styles.contactRow, AtomicCss.marginBottom10]}>
                  <Text
                    style={[
                      AtomicCss.defaultText,
                      fonts.blackFontFamily,
                      fsStyle.font12,
                      AtomicCss.marginBottom12,
                    ]}
                  >
                    {airline.airlineName}
                  </Text>
                  {!isEmpty(airline.contactNo) &&
                    airline.contactNo.map((contactNo, index) => (
                      <TouchableOpacity
                        onPress={() => {
                          openDialer(contactNo);
                        }}
                        style={[
                          AtomicCss.flexRow,
                          AtomicCss.spaceBetween,
                          AtomicCss.marginRight10,
                          index !== airlineContacts.length - 1
                            ? AtomicCss.marginBottom14
                            : AtomicCss.marginBottom5,
                        ]}
                      >
                        <Text style={[AtomicCss.defaultText, fonts.boldFontFamily, fsStyle.font12]}>
                          {contactNo}
                        </Text>
                        <Image style={styles.callIconStyle} source={AIRLINE_PHONE_ICON} />
                      </TouchableOpacity>
                    ))}
                </View>
              ))}
            {!isEmpty(extraInfo) && (
              <View style={styles.extraInfo}>
                <Text style={[styles.infoTxt, fsStyle.font12]}>{extraInfo}</Text>
              </View>
            )}
          </View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  whiteCard: {
    ...getPlatformElevation(2),
    backgroundColor: '#f6f6f6',
    paddingTop: 20,
    paddingHorizontal: 16,
    marginBottom: 8,
  },
  imgWrapper: {
    width: 32,
    height: 32,
    marginRight: 11,
  },
  errorStyle: {
    width: 32,
    height: 32,
  },
  infoIconStyle: {
    width: 27,
    height: 27,
    marginTop: 10,
  },
  detlsWrapper: {
    flex: 1,
    marginTop: 7,
    flexDirection: 'row',
    marginRight: 10,
  },
  arrowIcon: {
    width: 10,
    height: 7,
    marginHorizontal: 5,
  },
  destRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  fltDtlsTxt: {
    fontFamily: fonts.black,
    color: '#000',
    lineHeight: 20,
  },
  fltDtlsDateTxt: {
    fontFamily: fonts.regular,
    color: '#000',
    lineHeight: 20,
  },
  headingTxt: {
    color: '#3c3c3c',
    fontFamily: fonts.black,
    fontSize: 14,
  },
  infoTxt: {
    color: '#4a4a4a',
    fontFamily: fonts.bold,
  },
  descTxt: {
    borderTopWidth: 1,
    borderTopColor: '#e7e7e7',
    paddingTop: 12,
    color: '#cf8100',
  },

  contactRow: {
    // paddingBottom: 12
  },
  callIconStyle: {
    width: 18,
    height: 18,
  },
  blackTxt: {
    color: '#000',
  },
  extraInfo: {
    borderTopWidth: 1,
    borderTopColor: '#e7e7e7',
    paddingTop: 12,
    paddingBottom: 16,
    marginTop: 10,
  },
});

export default FalseClaimSegmentCard;
