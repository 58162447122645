import {fonts} from '@mmt/legacy-commons/Styles/globalStyles';

const styles = {
  warningAddmsgCard: {
    backgroundColor: '#ffedd1',
    borderBottomWidth: 1,
    borderBottomColor: '#ffce80',
    paddingHorizontal: 15,
    paddingVertical: 10
  },
  warningAddmsg: {
    color: '#cf8100',
    fontFamily: fonts.bold
  }
};

export default styles;
