import React from 'react';
import { ActivityIndicator, Image, Text, View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

import {
  failedBgCarPickUpDetails,
  successBgCarPickUpDetails
} from '../../../HolidayBookingConstants';
import createStyle from './submitPopupCss';

import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getFont } from '../../../../PostSalesStyles';
import { useTheme } from '../../../../theme/theme.context';
import { carPickupDetailsText } from '../../constants';

const SubmitPopUp = ({ response, inProgress }) => {
  const { psTheme: theme } = useTheme();
  const fonts = getFont(true);


  const { seagull, turquoise, sweetCorn, lightCoral } = theme.color;
  const gradientSuccessColor = [seagull, turquoise];
  const gradientFailedColor = [sweetCorn, lightCoral];


  const styles = createStyle(theme, fonts);

  if (inProgress)
    return (
      <View style={[styles.overlayContentWrapper, AtomicCss.justifyCenter, AtomicCss.alignCenter]}>
        <ActivityIndicator />
      </View>
    );
  if (!response) return null;

  const bgImage = response.success ? successBgCarPickUpDetails : failedBgCarPickUpDetails;
  const title = response.responseText || '';
  const subTitle = carPickupDetailsText.redirectingBackToDetails;
  const gradientColor = response.success ? gradientSuccessColor : gradientFailedColor;
  const type = response.success ? 'success' : 'failed';

  const suffixStyle = type === 'failed' ? 'Failed' : '';
  return (
    <LinearGradient
      start={{ x: 1.0, y: 0.0 }}
      end={{ x: 0.0, y: 1.0 }}
      colors={gradientColor}
      style={[
        styles.overlayContentWithBgWrapper,
        styles[`overlayContentWithBgWrapper${suffixStyle}`],
      ]}
    >
      <Image source={bgImage} style={[styles.withBgStyle, styles[`withBgStyle${suffixStyle}`]]} />
      <View style={[styles.headingWrapper, styles[`headingWrapper${suffixStyle}`]]}>
        <Text style={[styles.headingWithBgTxt]}>{title}</Text>
        <Text style={[styles.subHeadingWithBgTxt]}>{subTitle}</Text>
      </View>
    </LinearGradient>
  );
};

export default SubmitPopUp;
