import React, { ReactNode } from 'react';
import { View, StyleSheet, ViewStyle } from 'react-native';

/**
 * Props for the RowCardContainer component.
 * @interface RowCardContainerProps
 * @property {ReactNode} children - The child components to be rendered inside the CardContainer.
 * @property {ViewStyle} [containerStyle] - Additional styles for the RowCardContainer.
 */
interface RowCardContainerProps {
  children: ReactNode;
  containerStyle?: ViewStyle;
}

/**
 * RowCardContainer component renders a contain in flex row with custom styles.
 * @param {RowCardContainerProps} props - The component props.
 * @returns {JSX.Element} - The rendered JSX element.
 */
const RowCardContainer: React.FC<RowCardContainerProps> = ({
  children,
  containerStyle = {},
}) => <View style={[styles.rowCard, containerStyle]}>{children}</View>;

const styles = StyleSheet.create({
  rowCard: {
    flexDirection: 'row',
    marginBottom: 10,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

export default RowCardContainer;
