import React, { useEffect, useState } from 'react';
import Thankyou from '../../../../../Common/thankyou';
import { NativeModules } from 'react-native';
import FlightBookingTrackingHelper from '../../../details/helper/FlightBookingTrackingHelper';
import { getCommonHeaders, PURCHASED_TRAVELINS_URL } from 'apps/post-sales/src/utils/NetworkUtils';
import ProgressView from 'apps/post-sales/src/Common/ProgressView/ProgressView';
import { resetToFlightBookingDetail } from 'apps/post-sales/src/flights/FlightBookingUtil';

interface UserInfo {
  mmtAuth: string;
  loggingTrackingInfo: string;
}

interface ResponseData {
  status: string;
  heading?: string;
  subHeading?: string;
  theme?: string;
  actionList?: { cta: string; actionId: string }[];
}

export const insurancePurchasedApi = async (
  bookingId: string,
  txnId: string,
): Promise<ResponseData> => {
  const { FlightBookingModule } = NativeModules;
  try {
    const userInfo: UserInfo = await FlightBookingModule.getRequestHeader(
      bookingId,
      'FLIGHT_ANCILLARY',
    );
    const response = await fetch(PURCHASED_TRAVELINS_URL + `${bookingId}/${txnId}`, {
      method: 'GET',
      headers: await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo),
    });

    if (response && response.ok) {
      const body = await response.json();
      return body ? body : { status: 'PAYMENT_FAILED' };
    } else {
      return { status: 'COMMIT_FAILED' };
    }
  } catch (error) {
    console.log({ errorPurchase: error });
    return { status: 'PAYMENT_FAILED' };
  }
};

const TravelInsuranceThankyouPage: React.FC = () => {
  const [paymentResponse, setPaymentResponse] = useState<{ status: string } | null>(null);
  const [responseData, setResponseData] = useState<ResponseData | null>(null);
  const urlParams = new URLSearchParams(window.location.search);
  const bookingId = urlParams.get('bookingId') || '';
  const txnId = urlParams.get('txnId') || '';

  useEffect(() => {
    purchasedTravelInsMethod();
  }, [txnId]);

  useEffect(() => {
    try {
      FlightBookingTrackingHelper.trackLoadEvent('Travel_Insurance_thank_you', {});
    } catch (err) {}
  }, []);

  const purchasedTravelInsMethod = async () => {
    const result = await insurancePurchasedApi(bookingId, txnId);
    setPaymentResponse({ status: result.status });
    setResponseData(result);
  };

  const { heading, subHeading, theme, actionList = [] } = responseData || {};
  const { cta, actionId } = actionList[0] || {};
  const actions = [
    {
      text: cta || 'Back to Booking',
      clickHandler: () => {
        resetToFlightBookingDetail(bookingId);
      },
      variant: 'PRIMARY',
      shadow: true,
      id: actionId || 'backToBooking',
    },
  ];

  return (
    <React.Fragment>
      {!responseData ? (
        <ProgressView message="loading" />
      ) : (
        <Thankyou description={subHeading} message={heading} actions={actions} />
      )}
    </React.Fragment>
  );
};

export default TravelInsuranceThankyouPage;
