import { colors, fonts } from '@mmt/legacy-commons/Styles/globalStyles';

const styles = {
  card: {
    backgroundColor: colors.white,
    marginBottom: 10,
    padding: 15,
  },
  cardPrimaryDetails: {
    flexDirection: 'row',
    paddingBottom: 5
  },
  cardIcon: {
    height: 25,
    width: 24,
    marginRight: 8
  },
  cardTitle: {
    fontSize: 14,
    fontFamily: fonts.black,
    color: colors.black2f,
    marginBottom: 10
  },
  content: {
    flex: 1
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  cabStatusGradient: {
    paddingVertical: 2,
    paddingHorizontal: 8,
    borderRadius: 10,
    overflow: 'hidden'
  },
  cabStatusText: {
    fontSize: 10,
    fontFamily: fonts.bold,
    color: colors.white
  },
  body: {
    paddingTop: 5,
    marginBottom: 15
  },
  multiCityBody:{
    paddingTop: 5,
  },
  cabDesc: {
    fontSize: 12,
    fontFamily: fonts.regular,
    color: colors.black,
    lineHeight: 16
  },
  boardingDetails: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },
  carDetails: {
    flex: 1
  },
  driverDetails: {
    flex: 1,
    justifyContent: 'flex-end',
    flexDirection: 'row',
    marginLeft: 50
  },
  carName: {
    fontSize: 14,
    fontFamily: fonts.black,
    color: colors.black,
    width: 150
  },
  carNumber: {
    fontSize: 14,
    fontFamily: fonts.regular,
    color: colors.black,
    marginTop: 3
  },
  carOtp: {
    fontSize: 16,
    fontFamily: fonts.black,
    color: '#257af8'
  },
  driverNameWrapper: {
    alignItems: 'flex-end'
  },
  driverName: {
    fontSize: 14,
    fontFamily: fonts.black,
    color: colors.black
  },
  ratingGradient: {
    paddingHorizontal: 4,
    height: 18,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 3,
    overflow: 'hidden',
    marginTop: 3
  },
  driverRatingText: {
    fontSize: 14,
    fontFamily: fonts.bold,
    color: colors.white
  },
  totalRatingText: {
    fontSize: 10
  },
  driverThumbWrapper: {
    height: 41,
    width: 41,
    borderRadius: 21,
    overflow: 'hidden',
    marginLeft: 8
  },
  driverThumb: {
    height: '100%',
    resizeMode: 'cover'
  },
  aarogyaSetuIconStyle: {
    width: 20,
    height: 20,
    marginRight: 10,
    marginTop: 3
  },
  coronaWrapper: {
    flexDirection: 'row',
    paddingRight: 20,
    marginTop: 20
  },
  tripDateRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10
  },
  pickUpTime: {
    fontSize: 12,
    fontFamily: fonts.bold,
    color: colors.black
  },
  timeText: {
    fontSize: 14,
    fontFamily: fonts.bold,
    color: colors.green,
    marginBottom: 10
  },
  dropTime: {
    fontSize: 12,
    fontFamily: fonts.regular,
    color: colors.disabledBtnBg
  },
  locationName: {
    fontSize: 14,
    fontFamily: fonts.bold,
    color: colors.defaultTextColor,
    marginBottom: 5
  },
  tripDistance: {
    fontSize: 12,
    fontFamily: fonts.bold,
    color: colors.greyLight,
    borderBottomColor: colors.gray3,
    borderBottomWidth: 1,
    paddingHorizontal: 7,
    paddingBottom: 3,
    marginBottom: 3
  },
  smallText: {
    fontSize: 12,
    fontFamily: fonts.regular,
    color: colors.greyLight
  },
  smallText1: {
    fontSize: 12,
    fontFamily: fonts.black,
    color: colors.greyLight
  },
  blackText: {
    fontFamily: fonts.black,
    color: colors.black,
    fontSize: 12
  },
  textRight: {
    textAlign: 'right'
  },
  tripDurationDetails: {
    marginHorizontal: 10,
    alignItems: 'center'
  },
  locationDetails: {
    flexDirection: 'row'
  },
  pickUpPointDetails: {
    flex: 1
  },
  dropPointDetails: {
    flex: 1,
    alignItems: 'flex-end'
  },
  roundTripSeperator: {
    position: 'relative',
    alignItems: 'center'
  },
  seperatorLine: {
    position: 'absolute',
    height: 2,
    width: '100%',
    left: 0,
    top: 32,
    backgroundColor: colors.cabsGreyBg,
    zIndex: 1
  },
  seperatorDaysGhost: {
    height: 51,
    paddingHorizontal: 40,
    borderColor: colors.cabsGreyBg,
    borderWidth: 1,
    justifyContent: 'center',
    borderRadius: 20,
    marginVertical: 10,
    position: 'relative',
    zIndex: 2,
    backgroundColor: colors.white
  },
  seperatorDaysGhostText: {
    fontSize: 14,
    fontFamily: fonts.bold,
    color: colors.black
  },
  mapCard: {
    paddingTop: 15,
    borderTopColor: colors.lightGrey3,
    borderTopWidth: 1,
    marginTop: 10
  },
  mapInfoRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10
  },
  mapInfoLabel: {
    color: colors.lightTextColor,
    fontSize: 12,
    fontFamily: fonts.regular,
    minWidth: 140
  },
  mapInfoValue: {
    color: colors.black,
    fontSize: 12,
    fontFamily: fonts.bold
  },
  mapInfoSmallText: {
    color: colors.lightTextColor,
    fontSize: 12,
    fontFamily: fonts.regular
  },
  mapView: {
    overflow: 'hidden',
    height: 218,
    borderRadius: 8,
    marginTop: 10
  },
  mapImg: {
    height: '100%'
  },
  tripDurationTrackWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 6
  },
  tripDurationTrackCircle: {
    width: 6,
    height: 6,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: colors.darkGrey2,
    backgroundColor: colors.white
  },
  tripDurationTrack: {
    width: 60,
    backgroundColor: colors.darkGrey2,
    height: 1
  },
  tripDurationTrackCircleGreen: {
    borderColor: colors.cyan,
    backgroundColor: colors.cyan
  },
  cabFeatureList: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderTopColor: colors.lightGrey3,
    borderTopWidth: 1,
    paddingTop: 10,
  },
  cabFeature: {
    minWidth: '25%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  cabFeatureLast: {
    justifyContent: 'flex-end'
  },
  cabFeatureFirst: {
    justifyContent: 'flex-start'

  },
  cabFeatureIcon: {
    height: 20,
    width: 20,
    marginRight: 5
  },
  cabFeatureName: {
    color: colors.defaultTextColor,
    fontSize: 14,
    fontFamily: fonts.bold
  },
  odometerRow: {
    flexDirection: 'row',
    alignItems: 'center',
    borderTopColor: colors.lightGrey3,
    borderTopWidth: 1,
    paddingTop: 15,
    marginTop: 15
  },
  odometerRowLabel: {
    color: colors.lightTextColor,
    fontSize: 12,
    fontFamily: fonts.regular,
    marginRight: 15
  },
  odometerRowValue: {
    color: colors.black,
    fontSize: 12,
    fontFamily: fonts.black
  },
  pickUpBy: {
    color: '#3A7771',
    fontFamily: fonts.bold,
    marginBottom: 10,
    fontSize: 14,
  },
  renterHourly: {
    width: '70%'
  },
  pickuptime: {
    width: '25%',
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  },
  totalTimeSection: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderTopColor: colors.lightGrey3,
    borderTopWidth: 1,
    paddingTop: 10,
    paddingLeft: 33
  },
  totalTimeText: {
    color: colors.defaultTextColor,
    fontSize: 14,

  },
  lightgreyTxt: {
    color: colors.defaultTextColor,
    fontFamily: fonts.italic
  },
  multiCityWrapper: {
    paddingTop: 15,
  },
  multiCityRow: {
    borderStyle: 'dashed',
    borderTopWidth: 1,
    borderTopColor: colors.lightGrey3,
    paddingVertical: 13,
    borderRadius: 5,
  },
  seperator: {
    color: colors.lightGrey3,
  },
  stopTitleTxt: {
    fontSize: 11,
    fontFamily: fonts.regular
  },
  stopdetailsTxt: {
    color: colors.black,
    fontFamily: fonts.regular,
    fontSize: 10,
  },
};

export default styles;
