import isEmpty from 'lodash/isEmpty';
import AncillaryTrackingUtil from './AncillaryTrackingUtil';
import {
  getSelectedAncillariesTextForTracking,
  checkforPartialPax,
  checkForMoreThanTwoConnectingSegments,
  checkforPartialPaxAndPartialSeg,
  getGroupWebCheckinMandatoryAlert,
  isAnyAncillarySelected,
  checkIfWebCheckinSelected,
} from './AncillaryUtils';
import {
  showLongToast,
  showShortToast,
} from '@mmt/legacy-commons/Common/Components/Toast';
import { REVIEW_BLOCK_ALERT, TERMS_N_CONDNS_ALERT } from '../constants';

export const trackHelpingHandEventHandler = (type, pageName, click, data,ancillaryResponse) => {
    const trackData = Object.assign({}, ancillaryResponse, {
      ruleTrace: data ? data.ruleTrace : '',
    });
    switch (type) {
      case 'load':
        AncillaryTrackingUtil._trackLoadEvent(pageName, trackData);
        break;
      case 'click':
        AncillaryTrackingUtil._trackClickEvent(
          pageName,
          `Flight_${click}`,
          trackData
        );
        break;
      default:
        break;
    }
  };

  export const trackOnReviewContinueClick = (
    selectedAncillaries,
    ancillaryResponse
  ) => {
    AncillaryTrackingUtil._trackClickEvent(
      'Review',
      `Flight_Review_Submit_${getSelectedAncillariesTextForTracking(
        selectedAncillaries
      )}`,
      ancillaryResponse
    );
  };


  export const getWebCheckinOverLayMesage = (
    selectedAncillaries,
    ancillaryResponse
  ) => {
    const { partialWebCheckinInfo = {}, connectingSegment = null } =
      ancillaryResponse;
    const {
      partialPaxMessage = null,
      partialSectorMessage = null,
      partialPaxSectorMessage = null,
    } = partialWebCheckinInfo || {};
    if (checkforPartialPax(selectedAncillaries, ancillaryResponse)) {
      if (
        !isEmpty(connectingSegment) &&
        checkForMoreThanTwoConnectingSegments(connectingSegment)
      ) {
        return {show:true,message: partialPaxSectorMessage};
      } else {
        return {show:true,message: partialPaxMessage};
      }
    } else if (
      checkforPartialPaxAndPartialSeg(selectedAncillaries, ancillaryResponse)
    ) {
      return {show:true,message: partialSectorMessage};
    }
  };

  export const validateBeforeHoldApi = (
    selectedAncillaries,
    ancillaryResponse,
    termsCnds
  ) => {
    const groupWebCheckinAlert = getGroupWebCheckinMandatoryAlert(
      ancillaryResponse,
      selectedAncillaries
    );
    if (!isAnyAncillarySelected(selectedAncillaries, ancillaryResponse)) {
      showShortToast(REVIEW_BLOCK_ALERT);
      return false;
    } else if (!termsCnds) {
      showShortToast(TERMS_N_CONDNS_ALERT);
      return false;
    } else if (
      checkIfWebCheckinSelected(selectedAncillaries) &&
      !isEmpty(groupWebCheckinAlert)
    ) {
      showLongToast(groupWebCheckinAlert);
      return false;
    }
    return true;
  };

  export const trackLoadCYTOmniture = (propsThankyouObj, thankyouObj, value, ancillaryResponse, FltTrackData) => {
    const pageStatus = propsThankyouObj.addOnSuccess
          ? 'Success'
          : thankyouObj.addOnPartial
          ? 'Part'
          : 'Fail';
        AncillaryTrackingUtil._trackLoadEventCyt(
          `addon_purchased_${pageStatus}_${value}`,
          ancillaryResponse,
          !isEmpty(FltTrackData) && !isNil(FltTrackData.ominatureValue)
            ? FltTrackData.ominatureValue
            : '',
        );
  }
