import React from 'react';
import { StyleSheet, Text, Image, View, TextInput, Animated, Easing, Platform } from 'react-native';
import EditIcon from '@mmt/legacy-assets/src/postSales/edit_icon.webp';
import { getFont } from '../../../../../../../PostSalesStyles';
import { ThemeContext } from '../../../../../../../theme/theme.context';

class InputElement extends React.Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);
    let top = 12;
    let fontSize = 12;

    if (props.value.length > 0) {
      top = -10;
      fontSize = 12;
    }
    if (props.editable === false || props.error === true || !props.error === true) {
      top = 5;
      fontSize = 12;
    }

    this.state = {
      isFocused: false,
      top: new Animated.Value(top),
      fontSize: new Animated.Value(fontSize),
      editBtn: true,
      value: this.props.value,
    };
  }
  animate() {
    Animated.parallel([
      Animated.timing(this.state.top, {
        toValue: 5,
        duration: 200,
        easing: Easing.linear,
      }),
      Animated.timing(this.state.fontSize, {
        toValue: 12,
        duration: 200,
        easing: Easing.linear,
      }),
    ]).start();
  }
  reverse() {
    Animated.parallel([
      Animated.timing(this.state.top, {
        toValue: 12,
        duration: 200,
        easing: Easing.linear,
      }),
      Animated.timing(this.state.fontSize, {
        toValue: 12,
        duration: 200,
        easing: Easing.linear,
      }),
    ]).start();
  }

  handleInputFocus = () => {
    this.setState({ isFocused: true, editBtn: false });
    this.animate();
  };

  handleInputBlur = () => {
    this.setState({ isFocused: false, editBtn: true });
    if (this.state.value.length == 0) {
      this.reverse();
    }
  };
  nameEnter = (value) => {
    if (value) {
      value = value.toUpperCase();
    }

    if (this.props.validate) {
      if (this.props.validate(value)) {
        this.setState({ value });
        this.props.onChange && this.props.onChange(value);
      }
    } else {
      this.setState({ value });
      this.props.onChange && this.props.onChange(value);
    }
  };

  render() {
    const { psTheme: theme } = this.context;
    const { ...fonts } = getFont(true);
    const styles = createStyles(theme, fonts);

    const { label, error, editable, msg } = this.props;
    const { isFocused } = this.state;
    const labelStyle = [styles.label];
    const inputStyle = [styles.input];
    const inputContainer = [styles.container];

    if (error) {
      labelStyle.push({ color: theme.color.red });
      inputContainer.push({ borderColor: theme.color.red });
    }
    if (editable === false) {
      labelStyle.push({ color: theme.color.disabledBtnBg });
      inputContainer.push({
        borderColor: theme.color.greyBookedSeat,
        backgroundColor: theme.color.grey14,
      });
    }
    if (isFocused) {
      labelStyle.push({ color: theme.color.primary });
      inputContainer.push({ borderColor: theme.color.primary });
    }
    const { top, fontSize } = this.state;
    return (
      <View style={styles.wrapper}>
        <View style={[inputContainer]}>
          <Animated.Text
            pointerEvents="none"
            style={[labelStyle, { fontSize: fontSize, top: top }]}
            numberOfLines={1}
          >
            {label}
          </Animated.Text>
          <TextInput
            numberOfLines={1}
            underlineColorAndroid="transparent"
            editable={editable}
            maxLength={80}
            style={inputStyle}
            value={this.state.value}
            onChangeText={this.nameEnter}
            onFocus={this.handleInputFocus}
            onBlur={this.handleInputBlur}
            keyboardType={Platform.OS === 'ios' ? null : 'visible-password'}
          />
          {this.state.editBtn && !error && editable === true && (
            <View pointerEvents="none" style={styles.editWrapper} activeOpacity={0.7}>
              <Image source={EditIcon} style={styles.editIconStyle} />
            </View>
          )}
        </View>
        {error && <Text style={styles.msgStyle}>{msg}</Text>}
      </View>
    );
  }
}

const createStyles = (theme, fonts) =>
  StyleSheet.create({
    wrapper: {
      marginBottom: 20,
    },
    container: {
      width: '100%',
      flexDirection: 'column',
      backgroundColor: theme.color.white,
      height: 50,
      borderWidth: 1,
      borderColor: theme.color.greyBookedSeat,
      paddingHorizontal: 10,
      borderRadius: 2,
    },
    containerAcceptedBorder: {
      borderColor: theme.color.greyBookedSeat,
    },
    containerDisable: {
      borderColor: theme.color.greyBookedSeat,
      backgroundColor: theme.color.grey14,
    },
    label: {
      fontSize: fonts.fontSize.font14,
      color: theme.color.greyLight,
      position: 'absolute',
      paddingHorizontal: 10,
      textTransform: 'uppercase',
    },
    labelAcceptedText: {
      color: theme.color.greyLight,
    },
    labelDisableText: {
      color: theme.color.disabledBtnBg,
    },
    input: {
      width: '100%',
      fontSize: fonts.fontSize.font16,
      fontFamily: fonts.black,
      color: theme.color.black,
      position: 'relative',
      top: Platform.OS === 'ios' ? 20 : 8,
    },
    msgStyle: {
      fontSize: fonts.fontSize.font12,
      color: theme.color.red,
      fontFamily: fonts.regular,
      marginTop: 5,
    },
    editWrapper: {
      position: 'absolute',
      right: 1,
      top: 1,
      backgroundColor: theme.color.white,
      paddingVertical: 14,
      paddingHorizontal: 14,
    },
    editIconStyle: {
      width: 18,
      height: 18,
    },
  });

export default InputElement;
