import { StyleSheet } from 'react-native';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
export default (theme, fonts) =>
  StyleSheet.create({
    thankYouWrapper: {
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.color.white,
      paddingHorizontal: '10%',
    },
    successIcon: {
      width: 40,
      height: 40,
      marginRight: 15,
      marginBottom: 10,
      alignSelf: 'center',
    },
    messageTitle: {
      fontSize: 20,
      textAlign: 'center',
      lineHeight: 25,
      color: theme.color.black,
      ...fonts.boldFontFamily,
      marginBottom: 27,
    },
    messageSubtitle: {
      fontSize: 15,
      textAlign: 'center',
      lineHeight: 25,
      color: theme.color.black,
      ...fonts.regularFontFamily,
      marginBottom: 27,
    },
    CommonFillBtn: {
      width: '100%',
    },
    transparantBgrnd: {
      ...Platform.select({
        ios: {
          backgroundColor: 'transparent',
        },
      }),
    },
    CommonFillBtnGradient: {
      borderRadius: 25,
      paddingVertical: 11,
      paddingHorizontal: 25,
      marginTop: 24,
    },
    CommonFillBtnText: {
      color: theme.color.white,
      alignSelf: 'center',
      ...fonts.boldFontFamily,
    },
  });
