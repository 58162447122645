import AtomicCss from "../../Rails/commonStyles/AtomicCss";
import {StyleSheet, Text, TextInput, TouchableOpacity, View} from "react-native";
import React from "react";
import {ENTER_YOUR_REASON, OTHERS, REASON_FOR_CANCELLATION} from "../../PostSalesConstant";
import { getFont } from "../../PostSalesStyles";
import { label } from '@mmt/post-sales/src/staticData/staticData';


const ReasonForCancellation = ({reasons, getTheSelectedData}) => {
    const fonts = getFont(true);
return(
<View style={[AtomicCss.whiteBg, AtomicCss.elevation1, AtomicCss.marginTop10, AtomicCss.paddingBottom8]}>
    <View style={[reasonForCancellationStyles.PartCancellationHead, AtomicCss.elevation1]}>
        <Text style={[fonts.blackFontFamily, AtomicCss.blackText, fonts.font16]}>
            {label('bus.cancellation.reasonForCancellation')}
        </Text>
    </View>
    {getReasonList(reasons, getTheSelectedData)}
</View>
)
}

const getReasonList = (reasons, getTheSelectedData) => {
    const fonts = getFont(true);
    return reasons && reasons.map((reason, index) => (
        <React.Fragment>
        <TouchableOpacity style={[AtomicCss.marginBottom18,AtomicCss.paddingLeft20]} onPress={() => getTheSelectedData(reason,index)}>
            <View style={[AtomicCss.flexRowWithTopMargin20]}>
                <View style={reasonForCancellationStyles.MimaRadio}>
                    <View style={[reason.selected ? reasonForCancellationStyles.MimaRadioInside : '']}></View>
                </View>
                <Text style={[AtomicCss.blackText, fonts.font14, fonts.regularFontFamily, AtomicCss.flex1, AtomicCss.tdrText]}>
                    {reason.reason}
                </Text>
            </View>
        </TouchableOpacity>
    {
        (reason.reason === OTHERS && reason.selected === true) && (
                <TextInput
                    multiline
                    placeholder= {ENTER_YOUR_REASON}
                    style={[reasonForCancellationStyles.textarea, fonts.regularFontFamily, fonts.font14]}
                    onChangeText={text => getTheSelectedData(reason, index,text)}
                />
            )
    }
        </React.Fragment>
    ));
}

const reasonForCancellationStyles = StyleSheet.create({
    textarea: {
        borderColor: '#e1e7ee',
        borderWidth: 1,
        borderRadius: 6,
        marginTop: 16,
        minHeight: 112,
        padding: 12,
        lineHeight: 17,
        fontSize: 14,
        color: '#647a97',
        textAlignVertical: 'top',
        marginLeft: 16,
        marginRight: 16,
        marginBottom: 16
    },
    MimaRadioInside: {
        width: 12,
        height: 12,
        backgroundColor: '#008cff',
        borderRadius: 20,
        overflow: 'hidden',
        marginLeft: 2,
        marginTop: 2
      },
      MimaRadio: {
        width: 18,
        height: 18,
        backgroundColor: '#fff',
        borderRadius: 20,
        overflow: 'hidden',
        borderWidth: 1,
        borderColor: '#008cff',
        marginRight: 10
      },
    CancellationWrapper: {
        flex: 1,
        justifyContent: 'space-between',
        backgroundColor: '#f2f2f2'
    },
    PartCancellationHead: {
        backgroundColor: '#ffffff',
        paddingVertical: 18,
        paddingLeft: 10,
        borderBottomWidth: 1,
        borderBottomColor: '#ddd'
    },
})
export  default ReasonForCancellation;
