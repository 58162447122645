import Fecha from 'fecha';
import { mandatoryFields, othersTravelMode } from './constants';

export const updateForAllCases = (pickupDetails, showDropOffDetails, activeTab, isDropOff) => {
  const { travellerName, contactNumber } = isDropOff
    ? pickupDetails[activeTab].dropoff
    : pickupDetails[activeTab].pickup;

  return pickupDetails.map((item, index) => ({
    ...item,
    pickup: {
      ...item.pickup,
      travellerName,
      contactNumber,
    },
    dropoff: showDropOffDetails.includes(index)
      ? {
          ...item.dropoff,
          travellerName,
          contactNumber,
        }
      : item.dropoff,
  }));
};

export const checkValidation = (pickupDetails, showDropOffDetails) => {
  let isValid = true;
  pickupDetails.forEach((pickup, index) => {
    if (!isValid) return;
    const isPickUpValid = checkFields(pickup.pickup);
    const isDropOffValid = showDropOffDetails.includes(index) ? checkFields(pickup.dropoff) : true;
    isValid = isPickUpValid && isDropOffValid;
  });

  return isValid;
};

export const checkFields = (object) => {
  return mandatoryFields.every((field) => {
    if (object[field] || object[field] === 0) {
      return true;
    } else if (field === 'number' && object.travelMode == othersTravelMode) {
      return true;
    } else {
      return false;
    }
  });
};

export const getIntialState = (state, response) => {
  const showDropOffDetails = [];
  const pickupDetails = response.pickupDetails;
  const dirtyFields = []

  pickupDetails.forEach((item, index) => {
    if (item.dropoff && item.dropoff.pickupPoint) {
      showDropOffDetails.push(index);
    }
    dirtyFields.push({
      pickup: Object.keys(item.pickup).map((key) => ({ [key]: false })),
      dropoff: Object.keys(item.dropoff).map((key) => ({ [key]: false })),
    });

  }, []);

  return {
    ...state,
    pickupDetails,
    showDropOffDetails,
    dirtyFields
  };
};


export const onFieldTouch = ({dirtyFields, activeTab, isDropOff, fieldName}) => {
  dirtyFields[activeTab][isDropOff ? 'dropoff' : 'pickup'][fieldName] = true;
  return dirtyFields;
};