import { flightIcon2, RailIcon, BusIcon, otherTransportIcon } from '../HolidayBookingConstants';

export const BottomOverlayTypes = {
  SELECT_TRANSFER_MODE: 'SELECT_TRANSFER_MODE',
  SELECT_ARRIVAL_TIME: 'SELECT_ARRIVAL_TIME',
  SELECT_DEPARTURE_TIME: 'SELECT_DEPARTURE_TIME',
};

export const transferMode = {
  0: { label: 'Flight', icon: flightIcon2 },
  1: { label: 'Train', icon: RailIcon },
  2: { label: 'Bus', icon: BusIcon },
  3: { label: 'Others', icon: otherTransportIcon },
};

export const othersTravelMode = 3;

export const pageName = 'holidayCarpickupDetails';

export const REDIRECT_TO_DETAIL_TIMEOUT = 3000;

export const mandatoryFields = [
  'travellerName',
  'contactNumber',
  'pickupPoint',
  'dropOffPoint',
  'number',
  'arrivalDate',
  'departureDate',
];

// This feature does not support vernac. Once we support just move these to polyglot static file
export const carPickupDetailsText = {
  pickupDetails: 'pickup details',
  dropOffDetails: 'Drop-off details',
  travellerName: 'Traveller Name',
  contactNumber: 'Contact Number',
  arrivalDate: 'Arrival Date & Time',
  departureDate: 'Departure Date & Time',
  additionalInfo: 'If any requirement comment below',
  dropOffPoint: 'Enter Drop-off Point',
  pickupPoint: 'Enter Pickup Point',
  travelMode: 'Transport Mode',
  number: 'Number',
  updateAllCheckbox: 'Update these details for all other cases',
  selectDepartureDate: 'Select Departure Date',
  selectArrivalDate: 'Select Arrival Date',
  selectTransportMode: 'Select Transport Mode',
  headerText: 'Pickup Details',
  addMore: 'ADD MORE',
  submit: 'SUBMIT',
  next: 'NEXT',
  redirectingBackToDetails: 'Redirecting back to your booking',
  addMoreSubtitle: 'If you have booked any transport service for your departure, we will require drop-off details of the same to ensure your smooth journey',
  enterTravellerName: 'Enter Traveller Name',
  contactNo: 'Enter Contact No',
  arrivalDateAndTime: 'Select Arrival Date & Time',
  departureDateAndTime: 'Select Departure Date & Time',
  commentHere: 'Enter Your Comments',
  toHotelIn:'To Hotel In',
  fromHotelIn: 'From Hotel In',
  remove:'REMOVE',
  enter:"Enter",
  tryAgain:"Try Again",
  somethingWentWrongError: 'Something went wrong',
  errorPageSubText:"We are not able to process your request due to some technical issue. Please try again.",
  cantEditErrorText:"You can’t edit now, Please reach out to Relationship Officer(RO) for further help."
};