import React from 'react';
import { View, Text, Image, TouchableOpacity, ScrollView } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { isEmpty } from 'lodash';
import styles from './refundRqstSuccessCss.js';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import BlueRectangleButton from '../../../../Common/BlueRectangleButton';
import { resetToFlightBookingDetail } from '../../../FlightBookingUtil';
import {
  AIRLINE_PHONE_ICON,
  QUESTION_MARK_ICON,
  BG_SUCCESS_TICK_ICON,
} from '../../../FlightImageConstants';
import { openDialer } from '../../../../Common/commonUtil';
import { getFont } from '../../../../PostSalesStyles';
import useHardwareBackpress from '../../../../Common/useHardwareBackpress';

const SpecialClaimRequestThankYou = ({
  bookingId,
  heading,
  description,
  whatNextCard,
  fasterRefundCard,
}) => {
  const { fsStyle, ...fonts } = getFont(true);
  const onBackPressHandler = () => {
    resetToFlightBookingDetail(bookingId);
  };
  useHardwareBackpress().addListener(onBackPressHandler);
  return (
    <View style={styles.pageWrapper}>
      <ScrollView>
        <View style={[styles.whiteCard, AtomicCss.marginBottom10]}>
          <View style={styles.messageContent}>
            <Image style={styles.messageIcon} source={BG_SUCCESS_TICK_ICON} />
            {!isEmpty(heading) && (
              <Text style={[styles.messageTitle, fonts.boldFontFamily, fsStyle.font20]}>
                {heading}
              </Text>
            )}
            {!isEmpty(description) && (
              <Text style={[styles.messageDesc, fonts.regularFontFamily, fsStyle.font14]}>
                {description}
              </Text>
            )}
          </View>
        </View>
        <View style={[styles.whiteCard, styles.innerSection]}>
          {whatNextCard && (
            <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom10]}>
              <Image style={styles.questionIcon} source={QUESTION_MARK_ICON} />
              <Text style={[styles.headingTxt, fonts.boldFontFamily, fsStyle.font18]}>
                {whatNextCard.title || 'What next?'}
              </Text>
            </View>
          )}
          <View style={styles.moreInfoWrapper}>
            {whatNextCard && (
              <View>
                {!isEmpty(whatNextCard.mainText) && (
                  <Text
                    style={[
                      styles.successTxt,
                      fsStyle.font14,
                      AtomicCss.marginBottom10,
                      fonts.blackFontFamily,
                    ]}
                  >
                    {whatNextCard.mainText}
                  </Text>
                )}
                {!isEmpty(whatNextCard.subText) && (
                  <Text style={[styles.desc, fonts.regularFontFamily, fsStyle.font14]}>
                    {whatNextCard.subText}
                  </Text>
                )}
                {!isEmpty(whatNextCard.subtitle) && (
                  <Text style={[styles.desc, fonts.regularFontFamily, fsStyle.font14]}>
                    {whatNextCard.subtitle}
                  </Text>
                )}
              </View>
            )}
            {fasterRefundCard && (
              <View style={styles.fasterRefundSection}>
                <LinearGradient
                  start={{
                    x: 0.0,
                    y: 1.0,
                  }}
                  end={{
                    x: 1.0,
                    y: 0.0,
                  }}
                  colors={['#43e1a8', '#219393']}
                  style={styles.refundTag}
                >
                  <Text style={[styles.refundTxt, fonts.boldFontFamily, fsStyle.font10]}>
                    FOR FASTER REFUND
                  </Text>
                </LinearGradient>
                <Text style={[styles.desc, fonts.regularFontFamily, AtomicCss.marginBottom5]}>
                  {fasterRefundCard.description}
                </Text>
                {!isEmpty(fasterRefundCard.airlineContacts) &&
                  fasterRefundCard.airlineContacts.map((airline, index) => (
                    <View
                      style={[
                        styles.contactRow,
                        index === fasterRefundCard.airlineContacts.length - 1
                          ? styles.borderBottom0
                          : '',
                      ]}
                    >
                      <Text
                        style={[
                          styles.defaultTxt,
                          fonts.blackFontFamily,
                          fsStyle.font14,
                          AtomicCss.marginBottom10,
                        ]}
                      >
                        {airline.airlineName}
                      </Text>
                      {!isEmpty(airline.contactNo) &&
                        airline.contactNo.map((airlineContactNo) => (
                          <TouchableOpacity
                            onPress={() => openDialer(airlineContactNo)}
                            style={[
                              AtomicCss.flexRow,
                              AtomicCss.spaceBetween,
                              AtomicCss.marginBottom18,
                            ]}
                          >
                            <Text style={[styles.defaultTxt, fonts.boldFontFamily, fsStyle.font14]}>
                              {airlineContactNo}
                            </Text>
                            <Image style={styles.callIconStyle} source={AIRLINE_PHONE_ICON} />
                          </TouchableOpacity>
                        ))}
                    </View>
                  ))}
              </View>
            )}
          </View>
        </View>
      </ScrollView>
      <View style={styles.footerBtnWrapper}>
        <BlueRectangleButton
          buttonText="BACK TO BOOKING"
          clickHandler={() => {
            onBackPressHandler();
          }}
        />
      </View>
    </View>
  );
};

export default SpecialClaimRequestThankYou;
