import React from 'react';
import { View, StyleSheet, Text } from 'react-native';
import AtomicCss from '../../../../bus/commonStyles/AtomicCss';


const IssueInfoCard = (props) => {
  const issueList = props.issueList;
  const issueMap = props.groupByIssue(issueList);

  return (
    <View style={[AtomicCss.whiteCardSection, styles.busInfoWrapper, AtomicCss.marginBottom10]}>
      {Object.entries(issueMap).map((item, index) => {
        return (
          <View style={[AtomicCss.flexRow, AtomicCss.marginBottom20]}>
            <Text style={[styles.serviceInfo, AtomicCss.boldFont]}>{item[0]}</Text>
            <Text style={[AtomicCss.regularFont, AtomicCss.font12, AtomicCss.lightText, AtomicCss.flex1]}>{item[1]}</Text>
          </View>
        );
      })}
    </View>
  );
}

const styles = StyleSheet.create({
  busInfoWrapper: { paddingHorizontal: 16, paddingVertical: 10 },
  serviceInfo: { fontSize: 12, color: '#4a4a4a', width: 80, marginRight: 5 }
});

export default IssueInfoCard;
