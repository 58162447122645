import { colors, fonts } from '@mmt/legacy-commons/Styles/globalStyles';

const styles = {
    OverlayContentWrapper: {
        padding: 10
    },
    overlayTopline: {
        width: 40,
        height: 4,
        borderRadius: 4,
        backgroundColor: '#c2c2c2',
        alignSelf: 'center',
    },
    title: {
        color: colors.black,
        fontSize: 20,
        fontFamily: fonts.black,
        lineHeight:30,
        marginBottom: 10
    },
    successTickStyle:{
        width:20,
        height:20,
    },
    titleWrapper:{
        flexDirection:'row',
        flexWrap:'wrap',
        marginBottom:10,
    },
    agentIconStyle:{
        width:126,
        height:84,
    },
    agentImgWrapper:{
        alignItems:'center',
        justifyContent:'center',
        marginBottom:30,
    },
    linkTxt:{
        fontFamily:fonts.bold,
        fontSize:12,
        color:'#008cff',
        textAlign:'center'
    },
};

export default styles;