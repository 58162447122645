import {
  DATE_MONTH,
  POSTSALES_DATE_TIME,
  reformatDateString,
} from '@mmt/legacy-commons/Helpers/dateTimehelpers';
import myTripsNavigation from '../../../navigation/navigation';
import upcomingBookingIcon from '@mmt/legacy-assets/src/postSales/summary/upcomingHolidaysIocn.webp';

function getHolidayPlanningCardSummaryData({
  title,
  status,
  updateAtDate,
  ticketId,
  optionCTAs,
  buttonCTAs,
  closeQueryCTAs,
  tagDestination,
  noOfTravellers,
}) {
  const travelDate = reformatDateString(updateAtDate, POSTSALES_DATE_TIME, DATE_MONTH);
  const openDetails = () => {
    myTripsNavigation.openHolidayPlanningCardBookingDetail(ticketId, tagDestination);
  };
  const editQuery = () => {
    myTripsNavigation.openHolidayEditPlannningDetail(ticketId);
  };
  let iconUrl = upcomingBookingIcon;
  const travelText = `${noOfTravellers != '' ? noOfTravellers + ' • ' : ''}${travelDate}`;
  return {
    iconUrl,
    ticketId,
    optionCTAs,
    buttonCTAs,
    closeQueryCTAs,
    travelText,
    title,
    status,
    openDetails,
    editQuery,
  };
}
export default getHolidayPlanningCardSummaryData;
