import {trackOmniture} from '@mmt/legacy-commons/Common/utils/OmnitureTrackerUtils';
import Fecha from 'fecha';
import {findDaysBetweenDates} from './AcmeBookingDetailUtil';
import {getTimeInMillis} from '../../hotel/details/utils/HotelBookingDetailUtil';

class AcmeBookingTrackingHelper {
  trackLoadEvent = (pageName, acmeBookingResponse) => {
    let params = {};
    params = this.getBasicOminitureVariables(pageName, acmeBookingResponse);
    trackOmniture(pageName, params);
  };

  getBasicOminitureVariables(pageName, acmeBookingResponse) {
    const params = {};
    try {
      const acmeDetails = acmeBookingResponse.acmeBookingInfo.acmeBookingDetails[0];
      params.pageName = pageName;
      params.m_v15 = pageName;
      params.m_v9 = acmeBookingResponse.layoutDetail.bookingSubState;
      params.m_c19 = this.getAcmeBookingType(acmeBookingResponse);
      params.m_v3 = acmeDetails.addressDetails.cityName;
      params.m_v7 = findDaysBetweenDates(new Date().getTime(), acmeBookingResponse.bookingDateTime);
      params.m_v6 = this.findTripDuration(acmeDetails.startDate, acmeDetails.endDate);
      params.m_v14 = this.isBookingRefundable(acmeDetails.freeCancellationEligibityDate);
      params.m_v16 = acmeBookingResponse.bookingId;
      params.m_v4 = acmeDetails.startDate;
      // params.m_c13 = acmeBookingResponse.hotelBookingInfo.hotelDetailsList[0].hotelCategory;
      // params.m_v35 = acmeBookingResponse.primaryCustomerDetails.phoneNumber;
      params.m_v39 = 'Y';
      params.m_v21 = this.getDifferenceOfBookingDateToStartDate(acmeDetails.startDate, acmeBookingResponse.bookingDateTime);
      params.m_v95 = acmeBookingResponse.bookingPaymentDetail.totalPrice;
      params.m_v81 = acmeBookingResponse.cmp;
      params.m_v7 = findDaysBetweenDates(new Date().getTime(), acmeBookingResponse.bookingDateTime);
      return params;
    } catch {
      return params;
    }
  }

  findTripDuration(firstDate, secondDate) {
    const firstDateObj = Fecha.parse(firstDate, 'YYYY-MM-DD');
    const secondDateObj = Fecha.parse(secondDate, 'YYYY-MM-DD');
    return findDaysBetweenDates(firstDateObj.getTime(), secondDateObj.getTime());
  }

  getDifferenceOfBookingDateToStartDate(startDate, bookingDate) {
    const startDateObj = Fecha.parse(startDate, 'YYYY-MM-DD');
    return findDaysBetweenDates(startDateObj.getTime(), bookingDate);
  }

  isBookingRefundable(freeCancellationDate) {
    const currentTime = new Date().getTime();
    if (getTimeInMillis(freeCancellationDate) < currentTime) {
      return 'Refundable';
    }
    return 'Non Refundable';
  }

  getAcmeBookingType(acmeBookingResponse) {
    if (acmeBookingResponse.acmeBookingInfo.unitType === 1) {
      return 'vouchers';
    } else if (acmeBookingResponse.acmeBookingInfo.unitType === 2) {
      return 'Activitiy';
    }
    return 'NOT_DEFINED';
  }

  trackClickEvent = (pageName, clickEvent) => {
    const params = {};
    params.pageName = pageName;
    params.m_v15 = pageName;
    params.m_c54 = clickEvent;
    trackOmniture(pageName, params);
  };

  trackErrorEvent = (pageName, reason) => {
    const params = {};
    params.pageName = pageName;
    params.m_v15 = pageName;
    params.m_c22 = reason;
    trackOmniture(pageName, params);
  }
}


export default new AcmeBookingTrackingHelper();
