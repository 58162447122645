import { colors } from '@mmt/legacy-commons/Styles/globalStyles';

const styles = {
  pageWrapper: {
    flex: 1,
    backgroundColor: '#f2f2f2',
  },
  whiteBg: {
    backgroundColor: colors.white,
  },
  headerTitle: {
    color: colors.black,
  },
  whiteCard: {
    backgroundColor: '#ffffff',
    elevation: 2,
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.2,
    shadowRadius: 2,
    marginVertical: 6,
    padding: 16,
  },
  errorInfoIcon: {
    width: 32,
    height: 32,
    marginRight: 10,
  },
  cancelTopHeading: {
    color: '#000',
    marginBottom: 10,
  },
  cancelDiscription: {
    lineHeight: 20,
    color: colors.defaultTextColor,
    textAlign: 'center',
  },
  cancelErrorWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  btnWrapper: {
    padding: 16,
    paddingBottom: 0,
  },
  dividerWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 50,
    position: 'relative',
    marginHorizontal: 16,
  },
  dividerLine: {
    width: '100%',
    height: 1,
    backgroundColor: '#ccc',
    position: 'absolute',
  },
  orDivider: {
    width: 32,
    height: 32,
    borderRadius: 50,
    borderColor: '#ccc',
    borderWidth: 1,
    backgroundColor: '#f2f2f2',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  orDividerText: {
    fontSize: 12,
    color: colors.defaultTextColor,
    alignSelf: 'center',
  },
  rightArrow: {
    width: 14,
    height: 18,
  },
  callUsTxt: {
    color: '#000',
    fontSize: 14,
    marginBottom: 5,
  },
  rtArrowPosition: {
    top: 12,
  },
  errorRequestIcon: {
    width: 60,
    height: 55,
    marginBottom: 25,
  },
};

export default styles;
