import React from 'react';
import PropTypes from 'prop-types';
import { Image, ScrollView, Text, View } from 'react-native';
import Actions from '../../../navigation/postSalesNavigation';
import styles from './CancelErrorChatCss';
import StickyHeader from '../../../hotel/details/components/StickyHeader';
import BlueRectangleButton from '../../../Common/BlueRectangleButton';
import { HELPLINE_NO, NEED_HELP_PAGE, NEED_MORE_HELP } from '../../../PostSalesConstant';
import BasePage from '../../../Common/PostSalesBasePage';
import CancellationTrackingHelper from '../Helpers/CancellationTrackingHelper';
import NeedMoreHelpOverlay from '../../../Common/NeedMoreHelpOverlay';
import { ERROR_REQUEST_ICON, HEADER_BACK_ICON } from '../../FlightImageConstants';
import { openDialer } from '../../../Common/commonUtil';
import { getFont } from '../../../PostSalesStyles';
import { getErrorPageImage } from '../../../utils/PostSaleUtil';

export default class CancellationErrorComponent extends BasePage {
  constructor(props) {
    super(props);
    this.state = {
      helpingHandOverlay: true,
    };
    this.businessErrorObj =
      this.props.cancellationPreviewResponseJson &&
      this.props.cancellationPreviewResponseJson.businessErrorObj;
    this.trackingPageName = 'CancellationBusinessError';
    CancellationTrackingHelper.trackErrorEvent(
      this.trackingPageName,
      this.props.trackingBundle,
      undefined,
    );
  }

  toggleHelpingHandOverlay = (value) => {
    this.setState({
      helpingHandOverlay: value,
    });
  };

  trackHelpingHandEvents = (type, page, click, data) => {
    const pageName = `${this.trackingPageName}_${page}`;
    const trackData = Object.assign({}, this.props.trackingBundle, {
      ruleTrace: data ? data.ruleTrace : '',
    });
    switch (type) {
      case 'load':
        CancellationTrackingHelper.trackLoadEvent(pageName, trackData, undefined);
        break;
      case 'click':
        CancellationTrackingHelper.trackClickEvent(pageName, `Flight_${click}`, trackData);
        break;
      default:
        break;
    }
  };

  render() {
    const { fsStyle, ...fonts } = getFont(true);
    const bookingId = this.props.cancellationPreviewResponseJson ? this.props.cancellationPreviewResponseJson.bookingID || this.props.cancellationPreviewResponseJson.bookingId : '';
    return (
      <View style={styles.pageWrapper}>
        <StickyHeader
          iconSrc={HEADER_BACK_ICON}
          title={this.props.headerTitle}
          headerColor={['#ffffff', '#ffffff']}
          titleStyle={[styles.headerTitle, fonts.boldFontFamily, fsStyle.font16]}
          onBackPress={Actions.pop}
        />
        <ScrollView ref="pageScroll" showsVerticalScrollIndicator={false} style={styles.pageScroll}>
          <View style={[styles.whiteCard, { marginTop: -2 }]}>
            <View style={styles.cancelErrorWrapper}>
              <Image style={styles.errorRequestIcon} source={getErrorPageImage(bookingId)} />
              <Text style={[styles.cancelTopHeading, fonts.boldFontFamily, fsStyle.font20]}>
                {this.businessErrorObj.heading}
              </Text>
              <Text style={[styles.cancelDiscription, fonts.regularFontFamily, fsStyle.font14]}>
                {this.businessErrorObj.subHeading}
              </Text>
            </View>
            <View style={styles.btnWrapper}>
              <BlueRectangleButton
                buttonText={this.getButtonText()}
                clickHandler={this.onCTAClicked}
              />
            </View>
          </View>
          {/*//TODO: Need constructor open myra from the page, when developed*/}
          {/*<View style={styles.dividerWrapper}>
            <View style={styles.dividerLine}></View>
            <View style={styles.orDivider}>
              <Text style={[styles.orDividerText, fonts.regularFontFamily]}>OR</Text>
            </View>
          </View>*/}
          {/*<TouchableOpacity
            style={[styles.whiteCard, styles.flexRow, styles.spaceBetween]}
            activeOpacity={.8}>
            <View style={styles.flex1}>
              <Text style={[styles.callUsTxt, fonts.boldFontFamily]}>Call Us for Cancellation</Text>
              <Text>Talk to our agent now to cancel your ticket</Text>
            </View>
            <Image style={[styles.rightArrow, styles.rtArrowPosition]}
                   source={rightArrowBlue}/>
          </TouchableOpacity>*/}
        </ScrollView>
        {this.state.helpingHandOverlay === true && (
          <NeedMoreHelpOverlay
            bookingId={bookingId}
            handleBottomOverlay={this.toggleHelpingHandOverlay}
            handleReload={this.toggleHelpingHandOverlay}
            component={NEED_MORE_HELP.HELPING_HAND}
            pageName={NEED_HELP_PAGE.FLIGHT_CANCEL_ERROR}
            hideLoader={true}
            trackEvent={this.trackHelpingHandEvents}
          />
        )}
      </View>
    );
  }

  getButtonText = () => 'CALL MAKEMYTRIP';

  onCTAClicked = () => {
    openDialer(HELPLINE_NO);
    CancellationTrackingHelper.trackClickEvent(
      this.trackingPageName,
      'callmmt_click',
      this.props.trackingBundle,
    );
  };
}

CancellationErrorComponent.propTypes = {
  headerTitle: PropTypes.string.isRequired,
  cancellationPreviewResponseJson: PropTypes.object.isRequired,
  cancellationRequest: PropTypes.object.isRequired,
  trackingBundle: PropTypes.object.isRequired,
};
