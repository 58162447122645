import React, { useState } from 'react';
import {
  View,
  Text,
  Image,
  TouchableWithoutFeedback,
  TouchableOpacity,
  Modal,
} from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { mapImage, commonheaderIcon, fullScreen } from '../../../../CabImageConstants';
import HTMLViewWrapper from '../../../../components/CabVendorInfoCard/HTMLViewWrapper';
import CardWrapper from '../CardWrapper';
import { KioskBookingConfirmedProps } from '../../../../types';
import isEmpty from 'lodash/isEmpty';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import createStyles from './styles';
import CabBookingTrackingHelper from 'apps/post-sales/src/cab/CabBookingTrackingHelper';
import { openGoogleMap } from '../../../../../Common/commonUtil';
import CabBookingDetailsConstant, {
  CLICK_EVENT,
} from 'apps/post-sales/src/cab/CabBookingDetailsConstant';
import HeadingComponent from '../HeadingComponent';
import LinearGradient from 'react-native-linear-gradient';
import BaseButton from 'apps/post-sales/src/Common/BaseButton';

const KioskBookingConfirmed: React.FC<KioskBookingConfirmedProps> = ({ cardData, pageName }) => {
  const { kioskInfo } = cardData || {};

  if (isEmpty(kioskInfo)) {
    return null;
  }
  const { heading, boardingSteps, kioskImage, QRCodeIcon, qrCodeMessage, longitude, latitude, kioskPathImage } =
    kioskInfo || {};
  const fonts = getFont();
  const { psTheme: theme } = useTheme();
  const { color } = theme;
  const styles = createStyles(theme, fonts);
  const [modalVisible, setModalVisible] = useState(false);
  const [imageUri, setImageUri] = useState({});

  const openImage = (uriObj, clickEventName) => {
    CabBookingTrackingHelper.trackClickEvent(pageName, clickEventName);
    setImageUri(uriObj);
    setModalVisible(true);
  };

  const navigateToGoogleMap = (lat, lng) => {
    CabBookingTrackingHelper.trackClickEvent(pageName, CLICK_EVENT.VIEW_ON_MAPS_CLICK);
    return openGoogleMap(lat, lng);
  };

  const renderKioskImages = (actionList) => {
    return (
      <View style={AtomicCss.marginBottom24}>
        <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween, AtomicCss.marginBottom20]}>
          {!!kioskPathImage && <View style={[styles.imageWrapper, (!kioskImage) && { flex: 1 }]}>
            <Image style={[styles.kioskImageStle, (!kioskImage) && { width: '100%' }]} source={{ uri: kioskPathImage }} />

            <LinearGradient
              colors={[color.black, 'rgba(0,0,0,0.2)']}
              start={{ x: 0, y: 1 }}
              end={{ x: 0.0, y: 0 }}
              style={[styles.textInfoWrapper, , (!kioskImage) && { width: '100%' }]}
            >
              <TouchableOpacity
                onPress={() => {
                  openImage({ uri: kioskPathImage }, CLICK_EVENT.KIOSK_MAP_CLICK);
                }}
                style={[AtomicCss.flexRow, AtomicCss.alignSelfCenter]}
              >
                <Text
                  style={[
                    AtomicCss.whiteText,
                    AtomicCss.blackFont,
                    AtomicCss.font12,
                    AtomicCss.marginRight2,
                  ]}
                >
                  {CabBookingDetailsConstant.viewPhotoText}
                </Text>
                <Image source={fullScreen} style={styles.fullScreen} />
              </TouchableOpacity>
            </LinearGradient>
          </View>}
          {!!kioskImage && <View style={[styles.imageWrapper, (!kioskPathImage) && { flex: 1 }]}>
            <Image style={[styles.kioskImageStle, (!kioskPathImage) && { width: '100%' }]} source={{ uri: kioskImage }} />
            <LinearGradient
              colors={[color.black, 'rgba(0,0,0,0.2)']}
              start={{ x: 0, y: 1 }}
              end={{ x: 0.0, y: 0 }}
              style={[styles.textInfoWrapper,  (!kioskPathImage) && { width: '100%' }]}
            >
              <TouchableOpacity
                onPress={() => {
                  openImage({ uri: kioskImage }, CLICK_EVENT.KIOSK_IMAGE_CLICK);
                }}
                style={[AtomicCss.flexRow, AtomicCss.alignSelfCenter]}
              >
                <Text
                  style={[
                    AtomicCss.whiteText,
                    AtomicCss.blackFont,
                    AtomicCss.font12,
                    AtomicCss.marginRight2,
                  ]}
                >
                  {CabBookingDetailsConstant.viewPhotoText}
                </Text>
                <Image source={fullScreen} style={styles.fullScreen} />
              </TouchableOpacity>
            </LinearGradient>
          </View>}
        </View>
        {!!latitude &&
          !!longitude &&
          !isEmpty(actionList) &&
          actionList.map((action) => {
            const { actionLabeltext, actionType } = action;
            return (
              <BaseButton
                variant={actionType}
                buttonStyle={styles.buttonStyle}
                textStyle={styles.textStyle}
                text={actionLabeltext}
                clickHandler={() => navigateToGoogleMap(latitude, longitude)}
                shadow={false}
              />
            );
          })}
      </View>
    );
  };

  const renderQrCode = () => {
    return (
      <View style={styles.qrCodeWrapper}>
        {!!QRCodeIcon && (
          <TouchableWithoutFeedback>
            <Image style={styles.qrCodeImageStyle} source={{ uri: QRCodeIcon }} />
          </TouchableWithoutFeedback>
        )}
        {!!qrCodeMessage && (
          <View style={styles.qrCodeTextWrapper}>
            <HTMLViewWrapper htmlContent={qrCodeMessage} />
          </View>
        )}
      </View>
    );
  };

  return (
    <>
      <View style={styles.howToBoardWrapper}>
        {!!heading && <HeadingComponent heading={heading} containerStyle={styles.heading} />}
        {!isEmpty(boardingSteps) &&
          boardingSteps.map((item) => {
            const { actionList = [] } = item || {};
            return (
              <View key={item?.key}>
                <Text style={[styles.stepsHeading, AtomicCss.marginBottom3]}>{item?.title}</Text>
                <Text style={[styles.stespSubHeading, AtomicCss.marginBottom12]}>{item?.desc}</Text>
                {item?.key === 'boardingCab' && renderKioskImages(actionList)}
                {item?.key === 'checkingin' && renderQrCode()}
              </View>
            );
          })}
      </View>
      <Modal
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => setModalVisible(false)}
      >
        <View style={styles.modalContainer}>
          <TouchableOpacity onPress={() => setModalVisible(false)} style={styles.closeButton}>
            <Image source={commonheaderIcon} style={styles.closeIcon} />
          </TouchableOpacity>
          <Image source={imageUri} style={styles.fullImage} resizeMode="contain" />
        </View>
      </Modal>
    </>
  );
};

export default CardWrapper(KioskBookingConfirmed);
