import React from 'react';
import LinearGradient from 'react-native-linear-gradient';
import {Image, Text, TouchableOpacity, View} from 'react-native';
import PropTypes from 'prop-types';
import getStyles from '../styles/BusMamiCss';
import {fillDepartureArrivalDate} from '../utils/BusBookingDetailUtil';
import {whiteBackIcon} from '../BusImageConstants';
import { label } from '@mmt/post-sales/src/staticData/staticData';
import { get } from "lodash";
import { getFont } from '../../PostSalesStyles';

class StickyHeader extends React.Component {
  render() {
    const busDetailResponse = this.props.response.busBookingInfo;
    const busAdditionalBookingInfo = this.props.response.busAdditionalInfo;
    const fromCity = get(this.props, 'response.busBookingInfo.busDetail.fromCity', '');
    const toCity = get(this.props, 'response.busBookingInfo.busDetail.toCity', '');
    const fonts = getFont(true); 
    const styles = getStyles();
    const getSubHeading = () => {
      const date = get(busAdditionalBookingInfo, 'departureDetail.departureDayInMonth', '');
      const month = get(busAdditionalBookingInfo, 'departureDetail.departureMonth', '');
      const passengerCount = get(busDetailResponse, 'noOfPassengers', '');
      const operator = get(busDetailResponse, 'busDetail.operator', '');

      let subheading = '';
      date && (subheading = date + ' ');
      month && (subheading += month);
      passengerCount && (subheading += ` | ${passengerCount} ${label('bus.travellers', { capitalize: true })}`);
      operator && (subheading += ` | ${operator}`);

      return subheading;
    }

    return (
      <LinearGradient
        start={{
          x: 1.0,
          y: 0.0,
        }}
        end={{
          x: 0.0,
          y: 1.0,
        }}
        colors={this.props.color}
        style={styles.stickyHeader}
      >
        <View style={styles.topStatusBar}>
          <View style={styles.stickyBarWrapper}>
            <TouchableOpacity style={{ padding: 14 }} onPress={this.props.onBackPress}>
              <Image style={styles.backarrow} source={whiteBackIcon} />
            </TouchableOpacity>
            <View style={[styles.stickyTextWrapper, styles.transperantBgrnd]}>
              <Text
                numberOfLines={1}
                ellipsizeMode="tail"
                style={[styles.WhiteText, styles.blackFont]}
              >
                {label('bus.itinerary.busFromTo', null, { fromCity, toCity })}
              </Text>
              <Text style={[styles.WhiteText, styles.regularFont, fonts.font12, fonts.boldFontFamily]}>
                {getSubHeading()}
              </Text>
            </View>
          </View>
          <TouchableOpacity style={{ padding: 14 }} />
        </View>
      </LinearGradient>
    );
  }
}

StickyHeader.propTypes = {
  response: PropTypes.object.isRequired
};
export default StickyHeader;
