import React from 'react';
import { View, Text, Image, StyleSheet, TouchableOpacity } from 'react-native';
import { getStaticData } from '../../../staticData/staticData';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import Button from '../../../Common/Button';
import { NON_REFUNDABLE_ICON } from '../../HotelImageConstants';
import HotelBookingDetailsConstant from '../HotelBookingDetailsConstant';
import { getCardAndActionFromAllCards, handleDateChangeClick, openCancellationPreview } from '../utils/HotelBookingDetailUtil';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import ButtonWrapper from 'apps/post-sales/src/Common/ButtonWrapper';

const ProceedForCancellationOverlay = ({ response, handleBottomOverlay,userInfo }) => {
  const { fsStyle, ...fonts } = getFont(true);
  const styles = getStyles(fonts);
  const {cancellationText: {additionalText}} = getStaticData();
  const {persuadeModifyBookingText} = response;
  
  const handleModificationOptionsClick = () => {
    const cardAndAction = getCardAndActionFromAllCards(response, HotelBookingDetailsConstant.ACTION_CHANGE_DATES);
    if (cardAndAction.card && cardAndAction.action) {
      handleBottomOverlay();
      handleDateChangeClick(cardAndAction.action, response);
    }
  } 

  const handleCancellationClick = () => {
    handleBottomOverlay();
    openCancellationPreview(response, userInfo);
  }
  
  return (
    <View style={AtomicCss.marginBottom10}>
      <View style={[AtomicCss.marginBottom24, AtomicCss.marginTop5]}>
        <View style={styles.overlayTopline}></View>
      </View>
      <View style={[AtomicCss.paddingHz10]}>
        <Image source={NON_REFUNDABLE_ICON} style={[styles.nonRefundIconImg]} />
        <Text style={[styles.title, AtomicCss.marginBottom24]}>
          {additionalText.noRefundOnCancellationText}
        </Text>
        <Text style={[fonts.regularFontFamily, fsStyle.font14, AtomicCss.blackText, AtomicCss.lineHeight24, AtomicCss.marginBottom25]}>
          {persuadeModifyBookingText}
        </Text>
          <View style={AtomicCss.marginBottom24}>
            <ButtonWrapper
              btnText={additionalText.viewModificationOptionsText}
              clickHandler={handleModificationOptionsClick}
              btnTxtStyle={fsStyle.font16}
            />
          </View>
          <ButtonWrapper
              btnText={additionalText.proceedForCancellationText}
              clickHandler={handleCancellationClick}
              btnTxtStyle={fsStyle.font16}
              buttonType='text'
            />
      </View>
    </View>
  )
};

const getStyles = (fonts) => {
  return StyleSheet.create({
    overlayTopline: {
      width: 40,
      height: 4,
      borderRadius: 4,
      backgroundColor: colors.disabledButton,
      alignSelf: 'center',
    },
    title: {
      color: colors.black,
      ...fonts.font24,
      fontFamily: fonts.black,
      marginBottom: 30,
      lineHeight: 32
    },
    nonRefundIconImg: {
      width: 48,
      height: 48,
      marginBottom: 16,
      resizeMode: "contain"
    }
  });
}

export default ProceedForCancellationOverlay;
