import React from 'react';
import { View, Text, StyleSheet, Image, TouchableOpacity } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import LinearGradient from 'react-native-linear-gradient';
import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';
import FlightBookingTrackingHelper from '../../helper/FlightBookingTrackingHelper';
import { MY_SAFETY_LOGO } from '../../../../FlightImageConstants';
import { getFont, themeColors } from '../../../../../PostSalesStyles';
import HolidayBookingTrackingHelper from '../../../../../Holiday/HolidayBookingTrackingHelper';
import { useTheme } from '../../../../../theme/theme.context';


const CheckListSafeTravel = ({
  checkList,
  onViewAllClick,
  checklistActionHandler,
  flightResponse,
  btnText,
  inHolidayFlow,
  holidayPageName,
}) => {
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);
  const viewAllHandler = () => {
    inHolidayFlow
      ? HolidayBookingTrackingHelper.trackClickEvent(holidayPageName, 'View_Flights_CheckList')
      : FlightBookingTrackingHelper.trackClickEvent(
          'Details_Checklist',
          'Flight_List_View_All',
          flightResponse,
        );
    onViewAllClick(true);
  };
  return (
    <View style={styles.WebCheckin}>
      <LinearGradient
        start={{ x: 1.0, y: 0.0 }}
        end={{ x: 0.0, y: 1.0 }}
        colors={[theme.color.lightGreen15, theme.color.white]}
      >
        <View style={[styles.headingDtls]}>
        <Text
          style={[
            fsStyle.font16,
            AtomicCss.blackText,
            fonts.blackFontFamily,
            AtomicCss.marginBottom5,
            { maxWidth: 350 },
          ]}
        >
          {checkList.header}
        </Text>
        {checkList.showIcon && <Image style={styles.logoMYSafety} source={MY_SAFETY_LOGO} />}
        </View>
      </LinearGradient>
      <View style={styles.checkListMain}>
        {checkList.itemlist &&
          checkList.itemlist.map((item, index) => {
            if (index < checkList.displayLimit) {
              return (
                <View style={[styles.checkListWrapper, AtomicCss.marginBottom16]}>
                  <View style={styles.listLftDetls}>
                    <View style={styles.countWrapper}>
                      <Text style={[styles.countingTxt, fonts.boldFontFamily, fsStyle.font12]}>
                        {index + 1}
                      </Text>
                    </View>
                    <View style={styles.dtls}>
                      <Text
                        style={[
                          fonts.boldFontFamily,
                          AtomicCss.blackText,
                          fsStyle.font12,
                          AtomicCss.marginBottom5,
                          AtomicCss.alignLeft,
                        ]}
                      >
                        {item.title}
                      </Text>
                      {item.desc &&
                        item.desc.map((text) => (
                          <Text
                            style={[
                              fonts.regularFontFamily,
                              AtomicCss.defaultText,
                              fsStyle.font12,
                              AtomicCss.alignLeft,
                            ]}
                          >
                            {text}
                          </Text>
                        ))}
                    </View>
                  </View>
                  {item.action && item.action.title && (
                    <TouchableOpacity onPress={() => checklistActionHandler(item.action)}>
                      <Text
                        style={[
                          { color: themeColors.actionTitleColor },
                          fsStyle.font14,
                          fonts.boldFontFamily,
                        ]}
                      >
                        {item.action.title}
                      </Text>
                    </TouchableOpacity>
                  )}
                </View>
              );
            }
            return null;
          })}
        {checkList.itemlist && checkList.itemlist.length > 1 && (
          <TouchableOpacity style={[styles.ViewAllTxt]} onPress={viewAllHandler}>
            <Text
              style={[
                { color: themeColors.actionTitleColor },
                fsStyle.font14,
                fonts.boldFontFamily,
                AtomicCss.alignLeft,
              ]}
            >
              {btnText}
            </Text>
          </TouchableOpacity>
        )}
      </View>
    </View>
  );
};
const createStyles = (theme) => StyleSheet.create({
  WebCheckin: {
    ...getPlatformElevation(1),
    marginBottom: 8,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    backgroundColor: theme.color.white,
  },
  logoMYSafety: { width: 20, height: 20, marginRight: 12 },
  checkListMain: {
    padding: 16,
  },
  checkListWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  listLftDetls: {
    flex: 1,
    marginRight: 10,
    borderLeftWidth: 1,
    borderLeftColor: theme.color.grey8,
    paddingLeft: 20,
    marginLeft: 10,
    paddingBottom: 10,
  },
  countWrapper: {
    width: 20,
    height: 20,
    borderWidth: 1,
    borderColor: theme.color.greyBookedSeat,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.color.white,
    position: 'absolute',
    left: -10,
  },
  countingTxt: {
    color: theme.color.defaultTextColor,
  },
  btnWrapper: {
    padding: 15,
    borderTopWidth: 1,
    borderTopColor: '#e3e3e3',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  arrowIconStyle: {
    width: 16,
    height: 16,
    marginLeft: 5,
  },
  activeArrowIconStyle: {
    width: 16,
    height: 16,
    transform: [{ rotate: '180deg' }],
    marginLeft: 5,
  },
  headingDtls: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 16,
  },
  ViewAllTxt: {
    marginLeft: 30,
  },
});

export default CheckListSafeTravel;
