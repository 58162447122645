import { StyleSheet } from 'react-native';

const getStyles = (fonts, psTheme) => {
  const {color} = psTheme;
  return StyleSheet.create({
    secureInfo: {
        backgroundColor: color.lighterBlue,
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
        paddingVertical: 8,
        paddingHorizontal: 8,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      secureInfoTxt: {
        color: color.vividBlue,
        fontSize: fonts.fontSize.font12,
        ...fonts.regularFontFamily,
      },
      infoImg: {
        width: 14,
        height: 14,
      },
  });
}


export default getStyles;