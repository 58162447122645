import { NativeModules, Platform } from 'react-native';
import startsWith from 'lodash/startsWith';
import isEmpty from 'lodash/isEmpty';

const LOGIN_GENERIC_HEADING = 'Login for the Best Travel Offers';

export const isUserLoggedIn = async () => {
  const { UserSessionModule } = NativeModules;
  return UserSessionModule && UserSessionModule.isUserLoggedIn();
};

export const performLogin = async (
  showAsBottomSheet = false,
  heading = LOGIN_GENERIC_HEADING,
  verifyMobile = false,
  verifyHeader = null,
  verifySubText = null,
) => {
  const { UserSessionModule } = NativeModules;
  return (
    UserSessionModule &&
    UserSessionModule.performLogin(
      showAsBottomSheet,
      heading,
      verifyMobile,
      verifyHeader,
      verifySubText,
    )
  );
};

export const performEditProfile = () => {
  const { UserSessionModule } = NativeModules;
  return UserSessionModule && UserSessionModule.performEditProfile();
};

export const ProfileType = {
  BUSINESS: 'BUSINESS',
  PERSONAL: 'PERSONAL',
};

export const isCorporateUser = async () => {
  const { UserSessionModule } = NativeModules;
  return UserSessionModule.isCorporateUser();
};

export const getMmtAuth = async () => {
  if (Platform.OS === 'web') {
    // eslint-disable-next-line global-require
    const { default: cookies } = require('react-cookies');
    return Promise.resolve(cookies.load('mmt-auth'));
  }
  const { mmtAuth } = await getUserDetails();
  return mmtAuth;
};

export const getDeviceId = async () => {
  if (Platform.OS === 'web') {
    // eslint-disable-next-line global-require
    const { default: cookies } = require('react-cookies');
    return Promise.resolve(cookies.load('dvid'));
  }
  const { deviceId } = await getDeviceDetails();
  return deviceId;
};

export const isPersonalUser = (userDetails) => {
  const { profileType } = userDetails;
  return profileType !== ProfileType.BUSINESS;
};

export const openGDPRBlockerScreen = async (lob, source, data) => {
  const { UserSessionModule } = NativeModules;
  try {
    await UserSessionModule.openGDPRBlockerScreen(lob, source, data);
  } catch (e) {
    //
  }
};

export const shouldShowGSTNWidget = async () => {
  if (Platform.OS === 'web') {
    const { UserSessionModule } = NativeModules;
    return UserSessionModule && UserSessionModule.showGSTNWidget();
  }

  let userLoggedIn = await isUserLoggedIn();
  if (!userLoggedIn) {
    return true;
  }

  let userDetails = await getUserDetails();
  if (userDetails?.state) {
    return false;
  }

  return true;
};

export const _getUserDetails = async () => {
  const { UserSessionModule } = NativeModules;
  try {
    const userDetails = await UserSessionModule.getUserDetails();
    return _parseUserDetails(userDetails)();
  } catch (error) {
    return {};
  }
};

const shouldRefetchUserDataAndroid = async () => {
  const { UserSessionModule } = NativeModules;
  const { isUserLoadedCompletely } = UserSessionModule;
  const isUserDetailsLoaded = await isUserLoadedCompletely();
  return !isUserDetailsLoaded;
};

const shouldRefetchUserDataIos = (userDetails) => {
  const {
    email,
    Email,
    mobile,
    Mobile,
    Phone,
    phoneContacts = null,
    verifiedNumbers = null,
  } = userDetails ?? {};

  const isValidMobileNumber = () => {
    try {
      const mobileContactNumberList = JSON.parse(phoneContacts);
      const verifiedMobileNumber = JSON.parse(verifiedNumbers);
      return verifiedMobileNumber?.length > 0 || mobileContactNumberList?.length > 0;
    } catch (e) {
      return false;
    }
  };

  return (!email && !Email) || (!mobile && !Mobile && !Phone && !isValidMobileNumber());
};

const shouldRefetchUserDetails = (userDetails) =>
  Platform.select({
    android: async () => await shouldRefetchUserDataAndroid(),
    ios: async () => shouldRefetchUserDataIos(userDetails),
    web: async () => userDetails, // TODO: Need to implement this logic for m-web
  });

export const getUserDetails = async () => {
  const { UserSessionModule } = NativeModules;
  const { isUserLoggedIn, getUserDetails, fetchUserData } = UserSessionModule;
  let userDetails = {};
  try {
    const isLoggedIn = await isUserLoggedIn();
    if (!isLoggedIn) {
      return userDetails;
    }

    userDetails = await getUserDetails();
    const getIsUserDetailsMissing = shouldRefetchUserDetails(userDetails);
    const isUserDetailsMissing = await getIsUserDetailsMissing();

    if (isUserDetailsMissing) {
      userDetails = await fetchUserData();
    }

    return _parseUserDetails(userDetails)();
  } catch (e) {
    return {};
  }
};

const _parseUserDetails = (userDetails) =>
  Platform.select({
    android: () => _parseUserDetailsAndroid(userDetails),
    ios: () => _parseUserDetailsIos(userDetails),
    web: () => userDetails,
  });

const _parseUserDetailsAndroid = (userDetails) => {
  const userDetailsObj = JSON.parse(userDetails);
  const {
    cupAge,
    cupCrdt,
    cupEmailid,
    cupFname,
    cupGender,
    cupLandline,
    cupLname,
    cupPrCty,
    cupProfileType,
    cupUpdt,
    emailVerified,
    uuid,
    imageUrl,
    loggedIn,
    loginType,
    mmtAuth,
    corpData = null,
    mmtAuthInHeaderFormat,
    mobileContactNumberList,
    verifiedMobileNumber,
    state,
    pincode,
    address,
  } = userDetailsObj;
  let mobile;
  let mobileVerified = false;
  if (verifiedMobileNumber && verifiedMobileNumber.length > 0) {
    mobile = verifiedMobileNumber[0];
    mobileVerified = true;
  } else if (mobileContactNumberList && mobileContactNumberList.length > 0) {
    mobile = mobileContactNumberList[0];
  }

  let organizationId = null;
  if (!isEmpty(corpData)) {
    try {
      organizationId = corpData.employee.organizationId;
    } catch (e) {
      // Ignore exception
    }
  }

  return {
    firstName: cupFname,
    lastName: cupLname,
    email: cupEmailid,
    profileType: cupProfileType,
    uuid,
    imageUrl,
    gender: cupGender,
    mmtAuth,
    mmtAuthInHeaderFormat,
    loggedIn,
    loginType,
    mobile,
    verifiedMobileNumber,
    emailVerified,
    mobileVerified,
    organizationId,
    corpData,
    createdAt: new Date(cupCrdt),
    state,
    pincode,
    address,
  };
};

const _parseUserDetailsIos = (userDetails) => {
  const {
    FirstName,
    LastName,
    Email,
    dob,
    uuid,
    profileType,
    gender,
    imageUrl,
    loggedIn = true,
    loginChannel,
    mmtAuth,
    phoneContacts,
    verifiedNumbers,
    countryCode,
    Phone,
    isEmailVerified,
    isMobileVerified,
    organizationId = null,
    state,
    pincode,
    address,
  } = userDetails;

  let { corpData = null } = userDetails;

  //Following Change in CorpData to make consistent for Both Android & iOS
  const roles = Boolean(corpData?.roles) ? corpData?.roles.split(',') : null;
  const blockLobs = Boolean(corpData?.blockLobs) ? corpData?.blockLobs.split('~') : null;
  corpData = {
    employee: { ...corpData, roles, blockLobs },
  };

  let mobile = null;
  let verifiedMobileNumber;
  try {
    const mobileContactNumberList = JSON.parse(phoneContacts);
    verifiedMobileNumber = JSON.parse(verifiedNumbers);
    if (verifiedMobileNumber && verifiedMobileNumber.length > 0) {
      mobile = verifiedMobileNumber[0];
    } else if (mobileContactNumberList && mobileContactNumberList.length > 0) {
      mobile = mobileContactNumberList[0];
    }
  } catch (e) {
    // Ignore
  }

  if (mobile) {
    let { loginId: mobileNumber } = mobile;
    const code = mobile.countryCode;
    if (startsWith(mobileNumber, code)) {
      mobileNumber = mobileNumber.substr(code.length, mobileNumber.length - 1);
    }
    mobile = { countryCode: code, mobileNumber };
  } else {
    mobile = { countryCode, mobileNumber: Phone };
  }
  const mobileVerified = isMobileVerified === 'true';
  const emailVerified = isEmailVerified === 'true';
  return {
    firstName: FirstName,
    lastName: LastName,
    email: Email,
    imageUrl,
    gender,
    mmtAuth,
    loggedIn,
    loginType: loginChannel,
    mobile,
    verifiedMobileNumber,
    profileType,
    uuid,
    corpData,
    emailVerified,
    mobileVerified,
    organizationId,
    state,
    pincode,
    address,
  };
};
