import {trackOmniture} from '@mmt/legacy-commons/Common/utils/OmnitureTrackerUtils';
import GiftCardEventLogger from './GiftCardEventLogger';

class GiftCardBookingTrackingHelper {
	
	trackLoadEvent = (pageName, response) => {
    GiftCardEventLogger.loadEvent(pageName, response);
    let params = {};
    params = this.getBasicOminitureVariables(pageName, response);
    trackOmniture(pageName, params);
	};

	trackClickEvent = (pageName, clickEvent) => {
    GiftCardEventLogger.clickEvent(pageName, clickEvent);
    const params = {};
    params.pageName = pageName;
    params.m_v15 = pageName;
    params.m_c54 = clickEvent;
    trackOmniture(pageName, params);
	};
	
	trackErrorEvent = (pageName) => {
    GiftCardEventLogger.errorEvent(pageName);
    const params = {};
    params.pageName = pageName;
    params.m_v22 = pageName;
    trackOmniture(pageName, params);
  };
    
	getBasicOminitureVariables(pageName, response) {
    const params = {};
    params.pageName = pageName;
    params.m_v15 = pageName;
	  params.m_v16 = response.bookingID;
    return params;
  }
}

export default new GiftCardBookingTrackingHelper();
