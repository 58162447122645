import { Platform } from 'react-native';
import { trackOmniture } from '@mmt/legacy-commons/Common/utils/OmnitureTrackerUtils';
import { countBookings } from '../data/tripListManager';
import PostSalesEventLogger from '../../PostSalesEventLogger';
import { isEmpty } from 'lodash';
import { getUserDetails } from '@mmt/legacy-commons/Native/UserSession/UserSessionModule';
import { Lobs } from '../summaryConstants';

class TripSummaryOmnitureTracker {
  getPageName = async () => {
    const userDetails = await getUserDetails();
    return `mob:cs|${
      userDetails.profileType && userDetails.profileType.toLowerCase()
    }|bookingsummary`;
  };
  trackLoadEvent = async (allBookings, bookingState) => {
    const pageName = await this.getPageName();
    try {
      PostSalesEventLogger.loadEvent(
        'TripSummary',
        allBookings && allBookings.length > 0 ? allBookings[0] : {},
      );
      const bookingMap = countBookings(allBookings);
      let upcomingTripCount = 0;
      let cancelledTripCount = 0;
      if (bookingMap.UPCOMING) {
        upcomingTripCount = bookingMap.UPCOMING.length;
      }
      if (bookingMap.CANCELLED) {
        cancelledTripCount = bookingMap.CANCELLED.length;
      }
      trackOmniture(
        pageName,
        this.getTrackingOmnitureParams(
          pageName,
          upcomingTripCount,
          cancelledTripCount,
          bookingState,
        ),
      );
    } catch (e) {
      console.log(e);
    }
  };

  getTrackingOmnitureParams(pageName, upcomingTripCount, cancelledTripCount, bookingState) {
    const params = {};
    params.m_v15 = pageName;
    params.m_v24 = 'customer support';
    params.m_v11 = upcomingTripCount;
    params.m_c54 = cancelledTripCount;
    //On booking summary no way to determine country and currency, so keeping those values as empty.
    if (Platform.OS === 'web') {
      params.m_v37 = 'domain_sbu:|domain_country:|domain_currency:|domain_language:English';
    }
    params.m_v108 = `${pageName}_${bookingState.toLowerCase()}`;
    params.pageName = pageName;
    return params;
  }

  async trackTripIdeasLoadEvent(data) {
    try {
      const params = {};
      const pageName = await this.getPageName();
      params.m_v44 = `TICARD_load_${data.contentType}_${data.id}_${data.extraData}_T_${
        !isEmpty(data.title) && data.title.join(' ')
      }_D_${data.text}`;
      trackOmniture(pageName, params);
    } catch (e) {
      console.error(e);
    }
  }

  async trackTripIdeasSeenEvent(data) {
    try {
      const params = {};
      const pageName = await this.getPageName();
      params.m_v44 = `TICARD_seen_${data.contentType}_${data.id}_${data.extraData}_T_${
        !isEmpty(data.title) && data.title.join(' ')
      }_D_${data.text}`;
      trackOmniture(pageName, params);
    } catch (e) {
      console.error(e);
    }
  }

  async getTripIdeasClickedTrackedEvent(data) {
    try {
      const params = {};
      const pageName = await this.getPageName();
      params.m_c50 = `TICARD_click_${data.contentType}_${data.id}_${data.extraData}_T_${
        !isEmpty(data.title) && data.title.join(' ')
      }_D_${data.text}`;
      trackOmniture(pageName, params);
    } catch (e) {
      console.error(e);
    }
  }

  async trackTripIdeasErrorEvent(data) {
    try {
      const params = {};
      const pageName = await this.getPageName();
      params.m_c50 = `TICARD_Error_${data.contentType}_${data.id}_${data.extraData}`;
      trackOmniture(pageName, params);
    } catch (e) {
      console.error(e);
    }
  }

  trackBookingClickEvent = (lobName, bookingStatus, bookingId, clickEvent = undefined, paramsObj={}) => {
    try {
      PostSalesEventLogger.clickEvent('TripSummary', clickEvent ? clickEvent : 'bookingClicked', {
        bookingId,
      });
      const params = {};
      const pageName = `mob:cs:${lobName}:itinerary:${bookingStatus.toLowerCase()}`;
      params.m_v15 = pageName;
      params.m_v24 = 'customer support';
      params.m_v16 = bookingId;
      for(let key in paramsObj) {
        params[key] = paramsObj[key]
      }
      if (clickEvent) {
        params.m_c54 = clickEvent;
      } else {
        params.m_c54 = `MI_${bookingStatus.toUpperCase()}`;
      }
      params.m_c1 = params.m_c54;
      trackOmniture(pageName, params);
    } catch (e) {
      console.error(e);
    }
  };

  trackNormalClickEvent = (pageName, clickEvent, data = {}) => {
    try {
      PostSalesEventLogger.clickEvent('TripSummary', clickEvent, {});
      const params = {};
      params.m_c54 = clickEvent;
      params.m_c1 = clickEvent;
      trackOmniture(pageName, params);
    } catch (e) {
      console.error(e);
    }
  };

  trackErrorEvent = (pageName, error) => {
    try {
      PostSalesEventLogger.logPostSalesAction('TripSummaryErrorPage', { errorReason: error });
      const params = {};
      params.m_c22 = error;
      trackOmniture(pageName, params);
    } catch (e) {
      console.log(e);
    }
  };
}

export default new TripSummaryOmnitureTracker();
