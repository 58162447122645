import React from 'react';
import {View, Text, Image} from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import styles from './BlackPriceBreakupCss';

const eliteIconSm = require('@mmt/legacy-assets/src/blackEliteIconSm.webp');
const exclusiveIconSm = require('@mmt/legacy-assets/src/blackExcIconSm.webp');
const preferredIconSm = require('@mmt/legacy-assets/src/blackPreIconSm.webp');

const BlackPriceBreakup = ({
  priceBreakupBlackText, priceBreakupBlackValue, tierMetaInfo, tierType
}) => {
  const {tierColor} = tierMetaInfo;
  let tierIcon;
  switch (tierType) {
    case 1:
      tierIcon = eliteIconSm;
      break;
    case 2:
      tierIcon = preferredIconSm;
      break;
    case 3:
      tierIcon = exclusiveIconSm;
      break;
    default:
      tierIcon = preferredIconSm;
  }
  return (
    <View style={[AtomicCss.flexRow, AtomicCss.flex1]}>
      <View style={[AtomicCss.flexRow, AtomicCss.flex1]}>
        <Image style={styles.blackIconStyle} source={tierIcon} />
        <Text style={[styles.blackInfoTxt, { color: tierColor }, AtomicCss.alignLeft]}>{priceBreakupBlackText}</Text>
      </View>
      <Text style={[{ color: tierColor }, AtomicCss.font14, AtomicCss.boldFont, AtomicCss.alignLeft]}>{priceBreakupBlackValue}{' '}</Text>
    </View>
  );
};

export default BlackPriceBreakup;
