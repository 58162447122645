import React from 'react';
import {Alert, BackHandler, Dimensions, Image, Platform, Text, View} from 'react-native';
import ViewControllerModule from '@mmt/legacy-commons/Native/ViewControllerModule';
import AtomicCss from '../../commonStyles/AtomicCss';
import styles from './TdrFiledDetailsCss';
import {isNetworkAvailable} from '@mmt/legacy-commons/Common/utils/AppUtils';
import {getCommonHeaders, RAIL_TDR_URL} from "../../../utils/NetworkUtils";
import {showShortToast} from '@mmt/legacy-commons/Common/Components/Toast';
import Actions from '../../../navigation/postSalesNavigation';
import ProgressView from '@mmt/legacy-commons/Common/Components/Progress/ProgressView';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import StickyHeader from "../../components/StickyHeaderBack/StickyHeader";
import RecBlueBtn from '@mmt/legacy-commons/Common/Components/Buttons/RecBlueBtn';
import {trainFromToSubTitleWithDate} from "../../details/RailBookingDetailsUtil";
import RailBookingTrackingHelper from '../../details/RailBookingTrackEventHelper';
import ViewState from '../../../flights-funnel/Listing/utils/ViewState';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import * as NetworkUtils from "../../../utils/NetworkUtils";
import {SUCCESS_ICON,TDR_FILED_PAGE_NAME} from '../../Utils/RailImageConstant';
import RailBookingEventsLogger from '../../details/RailBookingEventsLogger';
import {getParamsForBookingDetail} from '../../../Common/commonUtil';
import { getStaticData } from '../../../staticData/staticData';
import BusCrossSell from '../../common/BusCrossSell';
import  isEmpty  from 'lodash/isEmpty';
import { getFont } from '../../../PostSalesStyles';
import { POST_SALES_TRAIN_TDR_SUCCESS_PAGE } from '@mmt/legacy-commons/adsConfig/adsConfigConstants';
import { getAdTechCard } from '../../../Common/commonUtil';

export const TDR_FILED_THANKS = 'TdrFiledThanks';
class TdrFiledThanks extends React.Component {
  constructor(props) {
    super(props);
    RailBookingEventsLogger.logAction('TdrFiledThanks constructor start');
    this.state = {
      loading: true,
      responseJson: null
    };
    this.mmtAuth = "";
    this.loggingtrackInfo = {};
    this.HEADER_TEXT = this.props.tdrRules.tdrLayoutResponse.tdrThanks.headerText;
    this.TDR_THANKS_MSG_HEADING = this.props.tdrRules.tdrLayoutResponse.tdrThanks.tdrThanksMsgHeading;
    this.TDR_THANKS_TOP_MSG_WITH_PREFIX_TRAVELLER = this.props.tdrRules.tdrLayoutResponse.tdrThanks.tdrThanksTopMsgWithPrefixTraveller;
    this.TDR_THANKS_TOP_MSG_WITH_SINGLE_TRAVELLER = this.props.tdrRules.tdrLayoutResponse.tdrThanks.tdrThanksTopMsgWithSingleTraveller;
    this.TDR_THANKS_TOP_MSG_WITH_MULTI_TRAVELLER = this.props.tdrRules.tdrLayoutResponse.tdrThanks.tdrThanksTopMsgWithMultiTraveller;
    this.TDR_THANKS_TOP_MSG_WITH_TRAVELLER_INFO = this.props.tdrRules.tdrLayoutResponse.tdrThanks.tdrThanksTopMsgWithTravellerInfo;
    this.TDR_THANKS_BOTTOM_MSG = this.props.tdrRules.tdrLayoutResponse.tdrThanks.tdrThanksBottomMsg;
    this.BOTTOM_BUTTON = this.props.tdrRules.tdrLayoutResponse.tdrThanks.bottomButton;
    this.INT_CONN_ISSUE_MSG = this.props.tdrRules.tdrLayoutResponse.tdrThanks.intConnIssueMsg;
    this.SHOW_SHORT_TOAST = this.props.tdrRules.tdrLayoutResponse.tdrThanks.showShortToast;
    RailBookingEventsLogger.logAction('TdrFiledThanks constructor end');
  }
  static navigationOptions = {
    header: null
  };
  componentDidMount() {
    this.fileTdr();
  }
  componentWillMount() {
    BackHandler.addEventListener('hardwareBackPress', this.onHardBackPress);
  }
  onHardBackPress = () => {
    RailBookingEventsLogger.logAction('TdrFiledThanks onHardBackPress');
    if (Platform.OS === 'ios') {
      ViewControllerModule.popViewController(this.props.rootTag);
    } else {
      this.clickHandler();
      return true;
    }
    return false;
  };
  componentWillUnmount() {
    BackHandler.removeEventListener('hardwareBackPress', this.onHardBackPress);
  }
  async fileTdr(){
    const staticData = getStaticData();
    const { errorText } = staticData;
    try {
      const hasNetwork = await isNetworkAvailable();
      if (!hasNetwork) {
        Alert.alert(this.INT_CONN_ISSUE_MSG ? this.INT_CONN_ISSUE_MSG : errorText.notConnectedToTheInternetError);
        return;
      }
      const userInfo = await HotelBookingModule.getRequestHeader(this.props.bookingDetails.bookingID, "RailTdrThankYou");
      const commonHeaders = await NetworkUtils.getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo);
      this.mmtAuth = userInfo.mmtAuth;
      this.loggingtrackInfo = userInfo.loggingTrackingInfo;
      const response = await fetch(RAIL_TDR_URL, {
        method: 'POST',
        body: JSON.stringify(this.props.request),
        headers: commonHeaders
      });
      if (response.ok) {
        const responseBody = await response.json();
        this.setState({
          loading: false,
          errorText: responseBody.errorMessage,
          responseJson : responseBody
        });
        RailBookingTrackingHelper.trackLoadEvent(TDR_FILED_THANKS, this.state.responseBody);
      } else {
        showShortToast(this.SHOW_SHORT_TOAST);
        Actions.pop();
      }
    } catch (error) {
      RailBookingTrackingHelper.trackErrorEvent(TDR_FILED_THANKS);
      showShortToast(this.SHOW_SHORT_TOAST);
      Actions.pop();
    }
  }

  clickHandler = () => {
    const params = {};
    params.BOOKING_ID = this.props.bookingDetails.bookingID;
    params.MMT_AUTH = this.mmtAuth;
    params.LOGGING_TRACKINFO = this.loggingtrackInfo;
    params.refresh = true;
    Actions.railBookingDetail({data: params, BOOKING_ID: this.props.bookingDetails.bookingID});
    RailBookingTrackingHelper.trackClickEvent(
      TDR_FILED_THANKS, this.props.bookingDetails, 'RetrnFromThxToBooking');
      return true;
  }
  isTdrFiledForGivenPax=(tdrfiledPaxList, paxid)=>{
    let counter = 0;
    if( tdrfiledPaxList != null){
      for( counter = 0; counter < tdrfiledPaxList.length; counter++){
        if( tdrfiledPaxList[counter] == paxid ){
          return true;
        }
      }
    }
    return false;
  };

  renderAdTechCard = () => {
    return getAdTechCard(POST_SALES_TRAIN_TDR_SUCCESS_PAGE);
  };

  renderPage = () => {
    const { fsStyle, ...fonts } = getFont(true);
    const staticData = getStaticData();
    const tdrFiledPaxList = this.props.request.segment[0].paxList;
    const passengers = this.props.bookingDetails.train[0].pnrDetails[0].segmentInfo[0].passenger;
    const primaryEmailID = this.props.bookingDetails.primaryEmailID;
    let totalCancelledPassenger = 0;
    let totalUncanceledPassenger = 0;
    let nameOfUncancelledPassenger = [];
    let nameOfTravellingPassenger = "";
    let totalTdrFiledPassenger = 0;
    let counter = 0;
    if( passengers != null ){
        for( counter = 0; counter< passengers.length; counter++){
        if( this.isTdrFiledForGivenPax(tdrFiledPaxList, passengers[counter].ticketLineNo) || passengers[counter].currentStatus == "CAN"   ){
              totalCancelledPassenger += 1;
              if(this.isTdrFiledForGivenPax(tdrFiledPaxList, passengers[counter].ticketLineNo) ){
                totalTdrFiledPassenger +=1;
              }
            }else{
              nameOfUncancelledPassenger[totalUncanceledPassenger]={ firstName: passengers[counter].paxFirstName, lastName: passengers[counter].paxLastName};
              if(totalUncanceledPassenger == 0 ) {
                nameOfTravellingPassenger = nameOfTravellingPassenger;
              }else{
                nameOfTravellingPassenger = nameOfTravellingPassenger + ' ' + passengers[counter].paxFirstName;
              }
              totalUncanceledPassenger += 1;
            }
        }
    }
    const totalTravellingPassenger = nameOfUncancelledPassenger.length;
    const nameOfTravellingPassengers = nameOfTravellingPassenger;
    const {busCrossSell} = this.state.responseJson || {};
    return (
      <View style={{flex: 1,ustifyContent: 'flex-end'}}>
      <View style={[AtomicCss.whiteBg, AtomicCss.marginBottom10]}>
        <View style={[styles.CardContentFull, AtomicCss.marginBottom20]}>
          <View style={[styles.tdrHeadingContent]}>
            <View style={[styles.EmptyContent]}>
            </View>
            <View style={[styles.CardContent]}>
              <Image
                style={[styles.tdrHeadingIcon]}
                source={SUCCESS_ICON}
              />
            </View>
            <View style={[styles.EmptyContentBottom]}>
            </View>
            <View>
              <Text style={[fonts.blackFontFamily, AtomicCss.blackText, fsStyle.font22]}>
                {this.TDR_THANKS_MSG_HEADING}
              </Text>
            </View>
          </View>
          <View style={[styles.marL10per, AtomicCss.paddingRight16]}>
            <Text style={[fsStyle.font16, fonts.regularFontFamily, AtomicCss.defaultTextColor, AtomicCss.lineHeight20, AtomicCss.marginBottom26, AtomicCss.spaceBetween, AtomicCss.textCenter]}>
              {
                totalTdrFiledPassenger === 0 ?
                    (this.TDR_THANKS_TOP_MSG_WITH_PREFIX_TRAVELLER+' '+ staticData.allText +' '+this.TDR_THANKS_TOP_MSG_WITH_MULTI_TRAVELLER) :
                    (!totalTdrFiledPassenger === 1 ?
                this.TDR_THANKS_TOP_MSG_WITH_PREFIX_TRAVELLER+' '+totalTdrFiledPassenger+' '+this.TDR_THANKS_TOP_MSG_WITH_MULTI_TRAVELLER:
                this.TDR_THANKS_TOP_MSG_WITH_PREFIX_TRAVELLER+' '+totalTdrFiledPassenger+' '+this.TDR_THANKS_TOP_MSG_WITH_SINGLE_TRAVELLER)
              }
              { nameOfTravellingPassengers.length > 0 ? nameOfTravellingPassengers+' '+this.TDR_THANKS_TOP_MSG_WITH_TRAVELLER_INFO: '' }
          </Text>
            {
            <View>
              <Text style={[fsStyle.font16, fonts.regularFontFamily, AtomicCss.defaultTextColor, AtomicCss.lineHeight20, AtomicCss.marginBottom26, AtomicCss.spaceBetween, AtomicCss.textCenter]}>
               {this.TDR_THANKS_BOTTOM_MSG}
              </Text>
            </View>
            }
          </View>
        </View>
      </View>
      {!isEmpty(busCrossSell)  &&<BusCrossSell cardData={busCrossSell} summaryCard={false} page={TDR_FILED_PAGE_NAME}/>}
      <View style={{flex: 1,
          justifyContent: 'flex-end',
          position: 'absolute',
          bottom: 0,
          width: Dimensions.get('screen').width,
          marginBottom: 0}}>
          <RecBlueBtn label={this.BOTTOM_BUTTON} onPress={this.clickHandler} />
      </View>
      <View style = {styles.adTechCardView}>
            {this.renderAdTechCard()}
      </View>
    </View>
    );
  };
  goToBookingDetails = () => {
    if(Platform.OS === 'web'){
      Actions.railBookingDetail({data: getParamsForBookingDetail(this.props.bookingDetails.bookingID,this.mmtAuth,this.loggingtrackInfo), BOOKING_ID: this.props.bookingDetails.bookingID});
    }else{
      Actions.popTo('railBookingDetail');
    }
  };
  render() {
    const staticData = getStaticData();
    if(this.state.loading){
      return (<ProgressView message={staticData.loadingText ? staticData.loadingText : ""} />);
    }
    else if(this.state.errorText){
      return (<ErrorView buttonLabel={staticData.goBackText} message={this.state.errorText} showRetry onRetry={() => Actions.pop()}/>);
    }
    else {
      return this.renderPage();
    }
  }
}
export default TdrFiledThanks;