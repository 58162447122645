import React from 'react';
import { Image, Text, TouchableOpacity, View } from 'react-native';
import { openWebView } from '../../../../../Common/MSR/Utils';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { useTheme } from '../../../../../theme/theme.context';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import createStyle from './style';
import TripSummaryOmnitureTracker from '../../../TripSummaryOmnitureTracker';

interface MsrRefundInfoCardProps{
  data: {
    icon: string,
    heading: string,
    subHeading: string,
    cta: {
      text: string,
      url: string
    }
  },
  lobName: string,
  bookingId: string,
  status: string,
}
const MSRRefundInfoCard = ({ data, lobName, status, bookingId }: MsrRefundInfoCardProps) => {
  if (!data) return null;
  const { icon, heading, subHeading, cta } = data || {};
  const { text, url } = cta || {};
  const { psTheme: theme } = useTheme();
  const fonts = getFont();
  const styles = createStyle(theme, fonts);

  const handleViewStatus = () => {
    TripSummaryOmnitureTracker.trackBookingClickEvent(lobName, status, bookingId , 'view_requests')
    openWebView(url);
  };

  return (
    <View style={styles.refundRequestCard}>
      <View style={[AtomicCss.flexRow, AtomicCss.flex1, AtomicCss.alignCenter, AtomicCss.paddingRight10]}>
        <Image style={styles.refundRequestStyle} source={{ uri: icon }} />
        <View style={styles.headingWrapper}>
          <Text style={styles.headingStyle}>{heading}</Text>
          <Text style={styles.subHeadingStyle}>{subHeading}</Text>
        </View>
      </View>
      <TouchableOpacity activeOpacity={0.9} onPress={handleViewStatus}>
        <Text style={styles.ctaStyle}>{text}</Text>
      </TouchableOpacity>
    </View>
  )
};

export default MSRRefundInfoCard;
