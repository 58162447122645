import { Fonts, Theme } from '@mmt/post-sales/src/cab/types';
import { StyleSheet } from 'react-native';

export default (theme: Theme, fonts: Fonts) => {
	const { color } = theme;
	return StyleSheet.create({
		heading: {
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'space-between',
			marginTop: 10,
		},
		contentWrapper: {
			paddingBottom: 12,
			paddingHorizontal: 14,
		},
		containerStyles: {
			paddingTop: 12,
			marginRight: -14,
		},
		contentBoxWrapper: {
			borderRadius: 20,
			position: 'relative',
			width: 115,
			alignItems: 'flex-start',
			marginRight: 10,
		},
		imageStyle: {
			width: 115,
			height: 115,
			borderRadius: 20,
		},
		lastIndex: {
			marginRight: 14,
		},
		carPhotosHeading: {
			...fonts.blackFontFamily,
			fontSize: fonts.fontSize.font16,
			color: color.black,
		},
		carPhotosSubHeading: {
			...fonts.regularFontFamily,
			fontSize: fonts.fontSize.font14,
			lineHeight: 16,
			color: color.defaultTextColor,
			marginTop: 10,
		},
		viewAllCTA: {
			...fonts.regularFontFamily,
			fontSize: fonts.fontSize.font12,
		}
	});
};
