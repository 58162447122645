import {
    addSegmentInfo,
    getReasonForRefund,
    getBreakupData,
    getRefundData,
    getPassengers,
    getPaxFareSegmentIdListFromPassengers
  } from "core-ui-lib/flight/common/Cancellation/data/dataAdapter";
  
  import {
    buildPartialPaxList,
    getPaxSubTitle,
    addPassengerData,
  } from 'core-ui-lib/flight/common/Cancellation/data/appDataAdapter';

  import { isNullOrEmpty } from "core-ui-lib/helper/Object/ObjectFunctions";
  import { fillCheckInCheckOutData } from "../helper";

  import { ViewState } from "core-ui-lib/flight/common/Cancellation/constant";
  
  export {
    buildPartialPaxList,
    getPaxSubTitle,
    addSegmentInfo,
    addPassengerData,
    getReasonForRefund,
    getRefundData,
    getBreakupData,
    getPassengers,
    getPaxFareSegmentIdListFromPassengers
  };
  

export const getJourneyIntentDtl = (response) => {
  let headerSubTItle = '';
  if (response.flightDetails != null) {
    if (
      !isNullOrEmpty(response.flightDetails.bookingIntentDetail) &&
      !isNullOrEmpty(response.flightDetails.travelDateTime)
    ) {
      headerSubTItle = `${response.flightDetails.bookingIntentDetail} - ${fillCheckInCheckOutData(
        response.flightDetails.travelDateTime,
        'DD MMM',
      )}`;
      if (!isNullOrEmpty(response.flightDetails.returnTravelDateTime)) {
        headerSubTItle += `-${fillCheckInCheckOutData(
          response.flightDetails.returnTravelDateTime,
          'DD MMM',
        )}`;
      }
    } else {
      headerSubTItle = response.flightDetails.bookingIntentDetail;
    }
  }
  return headerSubTItle;
}

export function getPrimaryContactNo(response) {
  if (!isNullOrEmpty(response?.accountDetail?.contactDetails)) {
    for (let i = 0; i < contactDetails.length; i += 1) {
      if (contactDetails[i].contactType === 2) {
        return contactDetails[i].contactValue;
      }
    }
  }
  return undefined;
}

export function getSpecialClaimList(response, activeScCode) {
  const specialClaimList =  response.cancellationValidation.specialClaimDataList.find(
    (data) => data.scCode === activeScCode,
  );
  return specialClaimList;
}

export function makePaxFareSegmentIdList (response, selectedPax, reason) {
  const paxFareSgmntIdLst = [];
  const unselectedPaxList = [];
  response.flightDetails.segmentGroupDetailList.forEach(
    (segmentGroup) => {
      const selectedPaxMap = selectedPax[segmentGroup.intentRefID];
      if (selectedPaxMap) {
        segmentGroup.segmentGroupPassengers.forEach((passenger) => {
          if (selectedPaxMap[passenger.paxReferenceNo]) {
            passenger.paxFareSegmentIdList.forEach((paxFareSgmntId) => {
              const obj = {};
              obj.paxFareSegmentId = paxFareSgmntId;
              obj.reason = reason;
              paxFareSgmntIdLst.push(obj);
            });
          } else {
            unselectedPaxList.push(passenger.paxType);
          }
        });
      }
    },
  );
  return [paxFareSgmntIdLst, unselectedPaxList];
}

export const fetchErrorDataAdapter = async (response) => {
  try {
    if (response && response.ok) {
      const responseBody = await response.json();
      return { ViewState: ViewState.ERROR, errorPageData: responseBody.errorPageData };
    } else {
      return { ViewState: ViewState.API_ERROR };
    }
  } catch (error) {
    return { ViewState: ViewState.API_ERROR };
  }
};
