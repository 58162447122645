import React, { useState } from 'react';
import { Text, View, TouchableOpacity, Image } from 'react-native';
import createStyles from '../styles';
import { getAirlineImage } from '../../../../utils/FlightListingUtil';
import CheckBox from '../../../../../../../../../Common/Checkbox';
import { getFont } from '../../../../../../../../../PostSalesStyles';
import { useTheme } from '../../../../../../../../../theme/theme.context';

const FlightAirlines = (props) => {
  const fonts = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);
  const [filterActiveItem, setFilterActiveItem] = useState(props.selected);
  const handleFilterActiveItem = () => {
    setFilterActiveItem(!filterActiveItem);
    props.toggleOpAlFltr(!filterActiveItem, props.index);
  };
  const { data } = props;
  const currency = props.currency || '₹';
  return (
    <View style={styles.filterAirlineWrapper}>
      <TouchableOpacity
        style={styles.filterFareTypeWrapper}
        activeOpacity={0.6}
        onPress={handleFilterActiveItem}
      >
        <View style={styles.innerDtlsWrap}>
          <Image style={styles.AirlineImg} source={{ uri: getAirlineImage(data.cd) }} />
          <Text
            style={
              !filterActiveItem ? [styles.AirlineNameTxt] : [styles.AirlineNameTxt, styles.TextBlue]
            }
          >
            {data.nm}
          </Text>
        </View>
        <View style={styles.innerDtlsWrap}>
          <Text
            style={
              !filterActiveItem
                ? [styles.AirlinePriceTxt]
                : [styles.AirlinePriceTxt, styles.TextBlue]
            }
          >{`${currency} ${data.chPay}`}</Text>
          <CheckBox size={20} onChange={handleFilterActiveItem} checked={filterActiveItem} />
        </View>
      </TouchableOpacity>
    </View>
  );
};
export default FlightAirlines;
