import React from 'react';
import {View, Text, Image, StyleSheet} from 'react-native';
import AtomicCss from '../AtomicCss';
import {covidCard} from '../../utils/AcmeImageConstant';


const CovidTag = () => {
  return (
    <View style={[AtomicCss.flexRow, styles.covidTag]}>
      <Image style={styles.covidTagIcon} source={covidCard}/>
      <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
        <Text style={[styles.font12, AtomicCss.blackFont, AtomicCss.blackText]}><Text
          style={AtomicCss.azure}>My</Text>Safety -
        </Text>
        <Text
          style={[styles.font12, AtomicCss.regularFont, AtomicCss.blackText]}> Safe
          Activities</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  font12: {fontSize: 12},
  covidTagIcon: {
    width: 22,
    height: 22,
    marginRight: 4,
    marginLeft: -2
  },
  covidTag: {
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#0874ce',
    alignSelf: 'flex-start',
    borderRadius: 3,
    paddingRight: 7,
    backgroundColor: '#edf7ff'
  }
});
export default CovidTag;
