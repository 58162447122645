import React from 'react';
import {View, Text, Image} from 'react-native';
import isEmpty  from 'lodash/isEmpty';
import {fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import Button from './Button';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getPlatformElevation from './getPlatformElevation';
import BasePage from '../../Common/PostSalesBasePage';
import {buttonDisplayTypes, TripInfoIcon} from '../HolidayBookingConstants';
import {getButtonsForType} from '../HolidayBookingDetailUtils';



class BookingTripInfo extends BasePage {
  render() {
    const {knowMore} = this.props;
    if (!knowMore) {
      return null;
    }
    const roundButtons = getButtonsForType(knowMore.buttons, buttonDisplayTypes.LINK_RECTANGLE_FULL, false);

    return (
      <View style={[AtomicCss.marginBottom10, styles.cardBorder]}>
        <View style={[styles.infoCardWrapper]}>
          <View style={styles.headingIconWrapper}>
            <Image style={styles.headingIconStyle} source={TripInfoIcon}/>
          </View>
          <View style={styles.infoCardContent}>
            <Text
              style={[AtomicCss.defaultText, AtomicCss.blackFont, AtomicCss.font14, AtomicCss.marginBottom15]}>MORE
              ABOUT YOUR TRIP </Text>
            <View style={styles.buttonWrapper}>
              {!isEmpty(roundButtons)
              && roundButtons.map((item, index) => {
                return (
                  <Button
                    btnTxt={item.text}
                    btnBg={item.displayType}
                    button={item}
                    handleClick={this.props.handleButtonClick}/>
                );
              })
              }
            </View>
          </View>
        </View>
      </View>
    );
  }
}

const styles = {
  cardBorder: {
    borderTopColor: '#e4e4e4',
    borderBottomColor: '#e4e4e4',
    borderTopWidth: 1,
    borderBottomWidth: 1
  },
  infoCardWrapper: {
    backgroundColor: '#fff',
    justifyContent: 'space-between',
    flexDirection: 'row',
    ...getPlatformElevation(2),
    marginBottom: 1
  },
  infoCardContent: {
    paddingRight: 8,
    paddingVertical: 15,
    flex: 1
  },
  infoCustom: {
    fontSize: 14,
    color: '#4a4a4a',
    lineHeight: 18,
    fontFamily: fonts.regular
  },
  headingIconWrapper: {
    width: 46,
    alignItems: 'center'
  },
  headingIconStyle: {
    width: 21,
    height: 14,
    top: 15
  },
  redirectBtnWrapper: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 60,
    paddingRight: 35,
    justifyContent: 'space-between',
    flexDirection: 'row',
    backgroundColor: '#fff',
    borderBottomWidth: 1,
    borderBottomColor: '#ededed',
    alignItems: 'center'
  },
  rightArrowIcon: {
    width: 9,
    height: 13
  },
  linkBtnTxt: {
    fontSize: 14,
    fontFamily: fonts.bold,
    color: '#008cff'
  },
  buttonWrapper: {
    paddingTop: 20,
    width: '90%'
  }
};

export default BookingTripInfo;
