import React, { useCallback, useEffect, useState } from 'react';
import useApi from 'apps/post-sales/src/Common/useApi';
import { buildDelayRequest } from '../data/requestAdapter';
import { REFRESH_RAIL_DELAY } from '../constants';
import isEmpty from 'lodash/isEmpty';
import { TRAIN_DELAY_INFO } from '../../../utils/NetworkUtils';

const useRailDelay = (trainDelayDispatch: Function): Function => {
  const [latestTrainData, setLatestTrainData] = useState({});
  const [railDelayProcess, railDelayResponse, railDelayApi] = useApi(TRAIN_DELAY_INFO);

  const fetchRailDelay = (bookingId: string) => {
    const userId = REFRESH_RAIL_DELAY;
    const body = buildDelayRequest(userId, bookingId);
    railDelayApi.httpPost({ bookingId, body });
  };

  useEffect(() => {
    if (!isEmpty(railDelayResponse) && !railDelayResponse.error) {
      setLatestTrainData({ data: railDelayResponse.trainScheduleData });
      trainDelayDispatch(railDelayResponse.trainScheduleData);
    } else {
      setLatestTrainData({ error: true });
    }
  }, [railDelayResponse]);

  return fetchRailDelay;
};

export default useRailDelay;
