import React, { useEffect } from 'react';
import { View, Text, StyleSheet, Image } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { infoIcon } from 'apps/post-sales/src/cab/CabImageConstants';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import CardWrapper from '../CardWrapper';
import getStyle from './styles';
import LinearGradient from 'react-native-linear-gradient';
import CabBookingTrackingHelper from 'apps/post-sales/src/cab/CabBookingTrackingHelper';
import { CLICK_EVENT } from 'apps/post-sales/src/cab/CabBookingDetailsConstant';

interface FlightDetailsProps {
  cardData: {
    flightDetails: {
      heading: string;
      flightNumber: string;
      flightNumberText: string;
      footerInfoText: string;
      isDelayed: boolean;
      delayedText : string;
    };
    isInternationalBooking?: boolean;
  };
  pageName?: string;
}

const FlightDetails = ({ cardData, pageName }: FlightDetailsProps) => {
  const { flightDetails, isInternationalBooking } = cardData;
  const { heading,  flightNumber, flightNumberText, footerInfoText, isDelayed, delayedText } =
    flightDetails || {};

  const { fsStyle, ...fonts } = getFont();
  const { psTheme } = useTheme();
  const { color } = psTheme;
  const styles = getStyle(fonts, psTheme);

  useEffect(() => {
    if(isInternationalBooking){
      CabBookingTrackingHelper.trackClickEvent(pageName, CLICK_EVENT.FLIGHT_DETAIL_CARD_INT_BOOKING_SHOWN);
    }else{
      CabBookingTrackingHelper.trackClickEvent(pageName, CLICK_EVENT.FLIGHT_DETAIL_CARD_SHOWN);
    }
    if(isDelayed){
      CabBookingTrackingHelper.trackClickEvent(pageName, CLICK_EVENT.FLIGHT_DELAY_TAG_SHOWN);
    }
  }, []);
  return (
    <View style={[styles.flightDetailWrap]}>
      <View style={[styles.cardHeading,  AtomicCss.spaceBetween]}>
        {!!heading && <Text style={styles.heading}>{heading}</Text>}
        {!!isDelayed && (
          <LinearGradient
            colors={[color.yellow13, color.yellow14]}
            start={{ x: 0.1, y: 0.0 }}
            end={{ x: 1, y: 0.0 }}
            style={[styles.delayedTag, AtomicCss.alignItemEnd]}
          >
            {!!delayedText && <Text style={styles.delayedText}>{delayedText}</Text>}
          </LinearGradient>
        )}
      </View>

      <View style={[styles.flightNumberWrap]}>
        <View>
          {!!flightNumberText && <Text style={styles.flightNumberText}>{flightNumberText}</Text>}
          {!!flightNumber && (
            <Text style={[styles.flightNumber, AtomicCss.marginTop5]}>{flightNumber}</Text>
          )}
        </View>
      </View>
      {!!footerInfoText && (
        <View style={[styles.flightInfo]}>
          <Image style={styles.iconStyle} source={infoIcon} />
          <View style={styles.footerTextContainer}><Text style={styles.footer}>{footerInfoText}</Text></View>
        </View>
      )}
    </View>
  );
};

export default CardWrapper(FlightDetails);
