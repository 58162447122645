import {isIPhoneX} from '../../../../Styles/globalStyles';
import {colors, fonts} from './globalStyles';
import {Dimensions, Platform} from 'react-native';

const windowHeight = Platform.OS === "ios" ? Dimensions.get('screen').height : Dimensions.get('window').height;
const windowWidth = Platform.OS === "ios" ? Dimensions.get('screen').width : Dimensions.get('window').width;

const styles = {
  overlayWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    elevation: 10,
    zIndex: 100,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    flexDirection: 'row',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
    height: '100%',
    width: '100%',
    zIndex: 1
  },
  overlayContent: {
    backgroundColor: colors.white,
    padding: 20,
    position: 'relative',
    zIndex: 1,
    width: '100%',
    bottom: Platform.OS === "web" ? -26 : -10,
  }
};

export default styles;
