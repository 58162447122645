import React from 'react';
import { View, Text, Image, Platform } from 'react-native';
import AtomicCss from '../../commonStyles/AtomicCss';
import getStyle from './ChatMyraCss';
import CapsuleBtnFill from '../../../hotel/details/ActionButton/CapsuleBtnFill';
import RailBookingTrackingHelper from '../../details/RailBookingTrackEventHelper';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import { MYRA_HELP_ICON } from '../../Utils/RailImageConstant';
import { getStaticData } from '../../../staticData/staticData';
import { getFont } from '../../../PostSalesStyles';
import { getRootTag } from '@mmt/legacy-commons/AppState/RootTagHolder';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import WhiteCardWrapper from '../../../Common/WhiteCardWrapper';

const ChatMyra = () => {
  const { fsStyle, ...fonts } = getFont(true);
  const staticData = getStaticData();
  const { psTheme } = useTheme();
  const { color } = psTheme;
  const styles = getStyle(fonts, color);
  const clickHandler = () => {
    RailBookingTrackingHelper.trackClickEvents('tripDetails', 'ChatWithMyra');
    if (Platform.OS === 'ios') {
      GenericModule.startWriteToUsActivity(getRootTag());
    } else {
      GenericModule.openMyraPage();
    }
  };
  const { trainNeedSupportText, trainChatWithMyraText } = staticData;

  return (
    <View style={[{color: color.white}, AtomicCss.marginBottom10, styles.container]}>
      <View style={[styles.CardContentFull, AtomicCss.marginBottom20]}>
        <View style={[styles.CardContent]}>
          <Image style={styles.CardIcon} source={MYRA_HELP_ICON} />
          <Text style={[fonts.blackFontFamily, {color:color.black}, fsStyle.font14]}>
            {trainNeedSupportText}
          </Text>
        </View>
        <View style={[styles.marL10per, AtomicCss.paddingRight16]}>
          <CapsuleBtnFill
            action={{ actionLabeltext: trainChatWithMyraText }}
            clickHandler={clickHandler}
          />
        </View>
      </View>
    </View>
  );
};

export default WhiteCardWrapper(ChatMyra);
