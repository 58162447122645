import React from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';
import styles from './SelectPassengersCss';
import CheckboxRow from './CheckboxRow';
import { addSegmentInfo } from './SegmentDeatilsView';
import InvalidPaxCard from './InvalidPaxCard';
import SpecialClaimRaiseInfo from '../components/SpecialClaimRaiseInfo';
import {
  isSegmentNotValidForCancellation,
  isPaxNotValidForCancellation,
  getActiveSegment,
  checkIfAnypaxNotValid,
  isSegmentEligibleForClaim,
  checkIfAnypaxEligibleForClaim,
} from '../CancellationValidationUtil';
import { getHeading, getDescription } from '../components/CancellationValidationComp';

import { getStaticData } from '../../../staticData/staticData';
import { PARTIAL_CHECKEDBOX_ICON } from '../../FlightImageConstants';
import { getFont } from '../../../PostSalesStyles';

export default class SelectPassengers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPax: {},
    };
    this.segmentDisabled = this.isSegmentDisabled();
    this.setAllPaxCheckStatus(false);
    if (this.props.segmentsGroupView) {
      if (this.props.segmentGroup.segmentsGroupView === this.props.segmentsGroupView) {
        if (this.props.segmentGroup.segmentGroupPassengers != null) {
          this.props.segmentGroup.segmentGroupPassengers.forEach((pax) => {
            this.onPassengerClicked(pax, true);
          });
        }
      }
    }
  }
  getBoxDisabled() {
    const { segmentGroup } = this.props;
    return segmentGroup && segmentGroup.cancellation && segmentGroup.cancellation.disabled;
  }

  isSegmentDisabled() {
    return isSegmentNotValidForCancellation(
      this.props.segmentGroup,
      this.props.cancellationValidation,
    );
  }

  render() {
    const { fsStyle } = getFont(true);
    const allPaxSelected = !this.segmentDisabled && this.isAllPaxSelected();
    const allSelectedStyle = allPaxSelected ? 'Selected' : '';
    const isBoxDisabled = this.getBoxDisabled();
    const boxDisabledStyle = isBoxDisabled ? styles.boxDisabled : '';
    const isCheckBoxDisabled =
      isBoxDisabled ||
      (this.props.componentType === 'CTIP' &&
        (this.segmentDisabled ||
          checkIfAnypaxNotValid(this.props.segmentGroup, this.props.cancellationValidation)));
    return (
      <View style={styles.cardWrapper}>
        <View style={styles.checkAllWrapper}>
          <View style={[styles.checkAllWrapperInner, boxDisabledStyle]}>
            <TouchableOpacity
              style={[styles.checkBtn, styles.checkAllBtn, styles.PaxslctionHeader]}
              activeOpacity={0.9}
              onPress={() => this.checkAll()}
              disabled={
                isBoxDisabled ||
                (this.props.componentType === 'CTIP' &&
                  (this.segmentDisabled ||
                    checkIfAnypaxNotValid(
                      this.props.segmentGroup,
                      this.props.cancellationValidation,
                    )))
              } // test if valid for cancel
            >
              <View style={styles.SectorHeader}>
                <View style={{ flexDirection: 'row' }}>
                  <View
                    style={[
                      styles.checkbox,
                      styles.checkboxAll,
                      styles[`checkbox${allSelectedStyle}`],
                      isCheckBoxDisabled ? styles.disableChkBox : '',
                    ]}
                  >
                    {allPaxSelected && (
                      <Image style={styles.checkedIcon} source={PARTIAL_CHECKEDBOX_ICON} />
                    )}
                  </View>
                  <View>
                    {addSegmentInfo(this.props.segmentGroup, this.props.showStaticContentInEnglish)}
                    {this.getSegmentSubTitle()}
                  </View>
                </View>
              </View>
            </TouchableOpacity>
          </View>
          {isBoxDisabled && (
            <Text style={[styles.errorInfoTxt, fsStyle.font14]}>
              {this.props.segmentGroup.cancellation.infoMessage}
            </Text>
          )}
          {this.props.componentType === 'CTIP' && (
            <React.Fragment>
              {this.getValidationHeader()}
              {this.getValidationDesc()}
              {
                <View style={styles.specialclaim}>
                  {this.segmentDisabled &&
                    isSegmentEligibleForClaim(
                      this.props.segmentGroup,
                      this.props.cancellationValidation,
                    ) && (
                      <SpecialClaimRaiseInfo
                        cancellationButtonClick={() => {
                          this.props.setPopUpOpen();
                        }}
                        specialClaimData={this.props.specialClaimData}
                      />
                    )}
                </View>
              }
            </React.Fragment>
          )}
        </View>
        <View style={styles.passengersList}>
          {this.addPassengers()}
          {this.props.componentType === 'CTIP' &&
            !this.segmentDisabled &&
            checkIfAnypaxNotValid(this.props.segmentGroup, this.props.cancellationValidation) && (
              <View>
                <InvalidPaxCard
                  cancellationButtonClick={() => {
                    this.props.setPopUpOpen('pax');
                  }}
                  segmentInfo={getActiveSegment(
                    this.props.segmentGroup,
                    this.props.cancellationValidation,
                  )}
                  specialClaimData={this.props.specialClaimData}
                  type="partialPax"
                  shouldShowSpClmText={checkIfAnypaxEligibleForClaim(
                    this.props.segmentGroup,
                    this.props.cancellationValidation,
                  )}
                />
              </View>
            )}
        </View>
      </View>
    );
  }

  getSegmentSubTitle = () => {
    const { fsStyle, ...fonts } = getFont(true);
    let {
      flightAdditionalText: { cancellationNotAllowedText },
    } = getStaticData();
    if (this.props.showStaticContentInEnglish) {
      cancellationNotAllowedText =
        'Airline does not allow cancellation of one of the segment for this particular fare. However you can cancel part/all travelers from complete journey.';
    }
    if (
      this.props.retPartialNtAllowed == true &&
      this.props.segmentGroup.segmentDetails.length > 1
    ) {
      return (
        <View>
          <Text style={[styles.rtInfo, fonts.regularFontFamily, fsStyle.font12]}>
            {cancellationNotAllowedText}
          </Text>
        </View>
      );
    }
  };
  getValidationHeader = () => {
    const currentSeg = getActiveSegment(this.props.segmentGroup, this.props.cancellationValidation);
    return getHeading(currentSeg);
  };
  getValidationDesc = () => {
    const currentSeg = getActiveSegment(this.props.segmentGroup, this.props.cancellationValidation);
    return getDescription(currentSeg, true);
  };

  addPassengers() {
    const paxViews = [];
    let addPax = true;
    const isBoxDisabled = this.getBoxDisabled() || this.segmentDisabled;
    if (this.props.segmentGroup.segmentGroupPassengers) {
      this.props.segmentGroup.segmentGroupPassengers.forEach((passenger) => {
        paxViews.forEach((pax) => {
          if (pax.key === passenger.paxReferenceNo) {
            addPax = false;
          }
        });
        const shouldShowPax = !(
          this.props.componentType === 'CTIP' &&
          isPaxNotValidForCancellation(
            passenger.paxLineNo,
            this.props.segmentGroup,
            this.props.cancellationValidation,
          )
        );
        if (addPax && shouldShowPax) {
          paxViews.push(
            <CheckboxRow
              key={passenger.paxReferenceNo}
              passengerData={passenger}
              selected={this.state.selectedPax[passenger.paxReferenceNo]}
              clickHandler={this.onPassengerClicked}
              isParentDisabled={
                isBoxDisabled ||
                isPaxNotValidForCancellation(
                  passenger.paxLineNo,
                  this.props.segmentGroup,
                  this.props.cancellationValidation,
                )
              }
              showStaticContentInEnglish={false}
            />,
          );
        }
      });
    }
    return paxViews;
  }

  onPassengerClicked = (passenger, selected) => {
    this.state.selectedPax[passenger.paxReferenceNo] = selected;
    this.props.passengerClickHandler(this.props.segmentGroup, this.state.selectedPax);
    this.setState({
      selectedPax: this.state.selectedPax,
    });
  };

  isAllPaxSelected() {
    if (this.props.segmentGroup.segmentGroupPassengers) {
      const noOfPax = this.props.segmentGroup.segmentGroupPassengers.length;
      for (let count = 0; count < noOfPax; count += 1) {
        const passengerInfo = this.props.segmentGroup.segmentGroupPassengers[count];
        if (!this.state.selectedPax[passengerInfo.paxReferenceNo]) {
          return false;
        }
      }
    }
    return true;
  }

  checkAll() {
    const allSelected = !this.isAllPaxSelected();
    this.props.segmentGroup.segmentGroupPassengers.forEach((passenger) => {
      this.state.selectedPax[passenger.paxReferenceNo] = allSelected;
    });
    this.props.passengerClickHandler(this.props.segmentGroup, this.state.selectedPax);
    this.setState({
      selectedPax: this.state.selectedPax,
    });
  }

  setAllPaxCheckStatus = (select) => {
    if (this.props.segmentGroup.segmentGroupPassengers) {
      this.props.segmentGroup.segmentGroupPassengers.forEach((passenger) => {
        this.state.selectedPax[passenger.paxReferenceNo] = select;
      });
    }
  };
}

SelectPassengers.propTypes = {
  segmentGroup: PropTypes.object.isRequired,
  passengerClickHandler: PropTypes.func.isRequired,
  retPartialNtAllowed: PropTypes.bool.isRequired,
  segmentsGroupView: PropTypes.object,
};

SelectPassengers.defaultProps = {
  segmentsGroupView: {},
};
