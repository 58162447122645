/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Text } from 'react-native';
import ChildAgeSection from './ChildAgeSection';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import { getStaticData } from '../../../../staticData/staticData';
import { LANG_AWARE } from '../../../HotelConstants';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const appendWithZeros = (childAgeList, childCount) => {
  const tempList = childAgeList;
  //  append the tempList with zeros, if length is less than childCount
  const lenDiff = childCount - tempList.length;
  if (lenDiff > 0) {
    tempList.push(...Array(lenDiff).fill('0'));
  }
  return tempList;
};

const AgeSection = ({
  modifiedRoomDetail: { totalNumberOfChilds: noofchild, roomNumber: roomno, childAgeList } = {},
  onAgeSelect,
  ageModificationData,
}) => {
  const { ...fonts } = getFont(true);
  const styles = getStyles(fonts);
  const [childAgesSelected, setChildAgesSelected] = useState([...childAgeList]);
  const {
    addGuestCardText: {
      additionalText: { provideAgeText },
    },
    childAgeText
  } = getStaticData(LANG_AWARE.MODIFICATION);

  useEffect(() => {
    setChildAgesSelected(appendWithZeros(childAgeList, noofchild));
  }, [noofchild]);

  const handleAgeClick = (cardIndex, childIndex, selectedAge) => {
    setChildAgesSelected((prev) => [
      ...prev.slice(0, childIndex),
      selectedAge,
      ...prev.slice(childIndex + 1),
    ]);
    const modifiedChildAgeList = [
      ...childAgesSelected.slice(0, childIndex),
      selectedAge,
      ...childAgesSelected.slice(childIndex + 1),
    ];
    onAgeSelect(cardIndex, 'C', modifiedChildAgeList);
  };

  const ageSection = () => {
    const ageSectionDom = [];
    for (let i = 0; i < noofchild; i += 1) {
      const age = childAgesSelected.length > i ? `${childAgesSelected[i]}` : '0';
      const headingText = childAgeText.replace('{child_no}', i + 1)
      ageSectionDom.push(
        <View style={styles.marginTop24} key={i}>
          <Text style={styles.ageHeading}>{headingText}</Text>
          {age === '0' && <Text style={styles.ageErrorText}>{provideAgeText}</Text>}
          <ChildAgeSection
            cardIndex={roomno}
            childIndex={i}
            ageSelect={handleAgeClick}
            age={age}
            childAgeCandidates={ageModificationData.childAgeCandidates}
          />
        </View>,
      );
    }
    return ageSectionDom;
  };

  return ageSection();
};

const getStyles = (fonts) => {
  return StyleSheet.create({
    ageHeading: {
      flex: 1,
      color: colors.defaultTextColor,
      ...fonts.font14,
      fontFamily: fonts.bold,
      lineHeight: 20,
    },
    ageErrorText: {
      flex: 1,
      color: colors.red,
      ...fonts.font14,
      fontFamily: fonts.bold,
      lineHeight: 20,
      marginBottom: 8,
    },
    ageSectionWrapper: {
      marginBottom: 12,
      marginTop: 2,
    },
    marginTop24: { marginTop: 24 },
  });
}

export default AgeSection;
