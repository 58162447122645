import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import {View, Text, Image, TouchableOpacity} from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getPlatformElevation from './getPlatformElevation';
import BasePage from '../../Common/PostSalesBasePage';
import {buttonTypes, genericCardDefaultImage} from '../HolidayBookingConstants';


class ItineraryCardItem extends BasePage {
  static navigationOptions = {
    header: null
  };

  render() {
    const {item, firstItem} = this.props;
    return (
      <TouchableOpacity
        activeOpacity={0.6}
        onPress={() => this.props.handleButtonClick({type: buttonTypes.ITINERARY_CARD})}>
        <View style={[styles.slidercard, firstItem]}>
          <View style={styles.imgWrapper}>
            <Image
              style={styles.imgStyle}
              source={!isEmpty(item.image) ? {uri: item.image} : genericCardDefaultImage}
              onError={this._onGroupImageLoadError}/>
          </View>
          <View style={styles.descWrapper}>
            <Text
              style={[AtomicCss.blackText, AtomicCss.font14, AtomicCss.regularFont]}>{item.day}</Text>
            <Text
              style={[AtomicCss.greyText, AtomicCss.font12, AtomicCss.regularFont]}>{item.text && item.text.length > 30 ? `${item.text.substring(0,30)}...`: item.text}</Text>
          </View>
        </View>
      </TouchableOpacity>
    );
  }
}

const styles = {
  slidercard: {
    width: 200,
    height: 210,
    ...getPlatformElevation(2),
    marginBottom: 2,
    marginRight: 10,
    backgroundColor: '#fff',
    borderRadius: 4,
    borderWidth: 1,
    borderColor: '#f2f2f2'
  },
  imgWrapper: {
    width: 200,
    height: 150
  },
  imgStyle: {
    width: '100%',
    height: '100%'
  },
  descWrapper: {
    padding: 10
  },
  fisrtItemStyle: {
    marginLeft: 16
  },
  bntWrapper: {
    alignItems: 'center',
    justifyContent: 'center'
  }
};

ItineraryCardItem.propTypes = {
  itineraryCard: PropTypes.object.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
  buttons: PropTypes.array.isRequired
};

export default ItineraryCardItem;
