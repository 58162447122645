import React from 'react';
import {View, Text, Image, TouchableOpacity} from 'react-native';
import PropTypes from 'prop-types';

import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';
import {fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import {DocIcon, TickSuccessIcon , rightArrow} from '../../HolidayBookingConstants';
import {themeColors} from '../../../PostSalesStyles';

function DocumentsUploadedCard(props) {
  const {response} = props;
  if (!response) {
    return null;
  }
  if (!Object.keys(response).length) {
    return null;
  }

  const {holidayDocumentsCard} = response;
  if (!holidayDocumentsCard) {
    return null;
  }
  if (!Object.keys(holidayDocumentsCard).length) {
    return null;
  }
  if (holidayDocumentsCard.buttons === null || holidayDocumentsCard.buttons.length === 0) {
    return null;
  }

  return (
    <View style={[AtomicCss.marginBottom10, styles.cardBorder]}>
      <View style={[styles.infoCardWrapper]}>
        <View style={styles.headingIconWrapper}>
          <Image style={styles.headingIconStyle} source={DocIcon} />
        </View>
        <View style={AtomicCss.flex1}>
          <View style={styles.infoCardContent}>
            <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom15]}>
              <Text style={[AtomicCss.defaultText, AtomicCss.blackFont, AtomicCss.font14]}>
                {holidayDocumentsCard.heading}
              </Text>
              <Image style={styles.tickIcon} source={TickSuccessIcon} />
            </View>
            <Text
              style={[
                AtomicCss.regularFont,
                AtomicCss.font14,
                AtomicCss.defaultText,
                AtomicCss.lineHeight20,
                AtomicCss.marginBottom15
              ]}
            >
              {holidayDocumentsCard.subText}
            </Text>
          </View>
        </View>
      </View>
      <TouchableOpacity
        style={styles.redirectBtnWrapper}
        activeOpacity={0.8}
        onPress={() => props.handleButtonClick(holidayDocumentsCard.buttons[0])}
      >
        <Text style={styles.linkBtnTxt}>{holidayDocumentsCard.buttons[0].text}</Text>
        <Image style={styles.rightArrowIcon} source={rightArrow} />
      </TouchableOpacity>
    </View>
  );
}

DocumentsUploadedCard.propTypes = {
  response: PropTypes.shape({
    holidayDocumentsCard: PropTypes.shape({
      heading: PropTypes.string.isRequired,
      subText: PropTypes.string.isRequired,
      paxDocRelationPendingState: PropTypes.shape([
        PropTypes.shape({
          header: PropTypes.bool.isRequired,
          name: PropTypes.string.isRequired,
          documentType: PropTypes.string.isRequired
        })
      ]),
      buttons: PropTypes.shape([
        PropTypes.shape({
          text: PropTypes.string.isRequired
        })
      ])
    }).isRequired
  }).isRequired,
  handleButtonClick: PropTypes.func.isRequired
};

const styles = {
  cardBorder: {
    borderTopColor: '#e4e4e4',
    borderBottomColor: '#e4e4e4',
    borderTopWidth: 1,
    borderBottomWidth: 1
  },
  infoCardWrapper: {
    backgroundColor: '#fff',
    justifyContent: 'space-between',
    flexDirection: 'row',
    ...getPlatformElevation(2),
    marginBottom: 1
  },

  infoCardContent: {
    paddingRight: 8,
    paddingVertical: 15,
    flex: 1
  },

  infoCustom: {
    fontSize: 14,
    color: '#4a4a4a',
    lineHeight: 18,
    fontFamily: fonts.regular
  },
  headingIconWrapper: {
    width: 46,
    alignItems: 'center'
  },
  headingIconStyle: {
    width: 16,
    height: 18,
    top: 15
  },

  redirectBtnWrapper: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 50,
    paddingRight: 35,
    justifyContent: 'space-between',
    flexDirection: 'row',
    backgroundColor: '#fff',
    borderBottomWidth: 1,
    borderBottomColor: '#ededed',
    alignItems: 'center'
  },

  rightArrowIcon: {
    width: 9,
    height: 13,
    tintColor: themeColors.rightArrowTintColor
  },

  linkBtnTxt: {
    fontSize: 14,
    fontFamily: fonts.bold,
    color: themeColors.linkTextColor
  },
  buttonWrapper: {
    paddingTop: 20,
    width: '90%'
  },
  tickIcon: {
    width: 14,
    height: 14,
    marginLeft: 5
  },
  missedUploadWrapper: {
    backgroundColor: '#ffd3d4',
    paddingVertical: 6,
    paddingHorizontal: 8,
    marginBottom: 12
  },
  failedText: {
    color: '#e53442',
    fontFamily: fonts.bold,
    fontSize: 14
  },
  innerDtlsBox: {
    backgroundColor: '#f2f2f2',
    padding: 12,
    borderRadius: 4,
    marginLeft: -25
  },
  headingSection: {
    borderBottomColor: '#e5e5e5',
    borderBottomWidth: 1,
    paddingBottom: 10
  },
  bulletDesc: {
    width: 5,
    height: 5,
    borderRadius: 10,
    backgroundColor: '#000',
    marginRight: 10
  },
  btnWrapper: {
    width: '92%'
  },
  statusTextInfo: {
    color: '#cc7f01',
    fontSize: 14,
    fontFamily: fonts.bold
  },
  statusHrLine: {
    height: 3,
    width: 45,
    borderRadius: 2,
    backgroundColor: '#c6c6c6'
  },
  uploaded: {
    backgroundColor: '#cc7f01'
  },
  width110: {
    width: 110
  },
  pendingdocRow: {
    flexDirection: 'row',
    paddingBottom: 8,
    marginBottom: 8,
    borderBottomWidth: 1,
    borderBottomColor: '#d5d5d5'
  },
  pendingdocRowLast: {
    borderBottomWidth: 0,
    borderBottomColor: 'transparent'
  }
};

export default DocumentsUploadedCard;
