import React from 'react';
import {Text, TouchableOpacity} from 'react-native';
import {colors} from '@mmt/legacy-commons/Styles/globalStyles';
import { themeColors } from '../PostSalesStyles';
import LinearGradient from 'react-native-linear-gradient';
import PropTypes from 'prop-types';
import styles from './styles/CommonMamiCss';
import reviewStyles from './BlueRectAngularButtonCss';


const BlueRectangleButton = ({buttonText, clickHandler, disabled, buttonTextStyle={}, isCorporateBooking}) => {
  const colorbuttonScheme = isCorporateBooking ? themeColors.myBizGradientColor : themeColors.rectFullBlueButtonGradientColor  
  return (
    <TouchableOpacity style={reviewStyles.ghostBtn} disabled={disabled} onPress={() => clickHandler()} >
    <LinearGradient
      start={{
        x: 1.0,
        y: 0.0
      }}
      end={{
        x: 0.0,
        y: 1.0
      }}
      colors={disabled ? [colors.disabledBtnBg, colors.disabledBtnBg] : colorbuttonScheme}
      style={[styles.fullWidthBtn]}
    >
      <Text style={[styles.WhiteText,
        styles.CenterText, styles.font14, styles.blackFont, styles.transperantBgrnd,buttonTextStyle]}
      >{buttonText}
      </Text>
    </LinearGradient>
  </TouchableOpacity>
  )
};

BlueRectangleButton.propTypes = {
  buttonText: PropTypes.string.isRequired,
  clickHandler: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

BlueRectangleButton.defaultProps = {
  disabled: false
};

export default BlueRectangleButton;
