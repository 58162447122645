import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import styles from './RTPCRCardCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import HTMLView from 'react-native-htmlview';
import { TRIP_PROTECTIPN_ICON, RIGHT_ARROW, FLT_POLICY_ICON } from '../../../../FlightImageConstants';
import { fetchUrlParam } from '../../../../../Common/commonUtil';
import { removeParam } from '../../../../../utils/PostSaleUtil';
import { getFont } from '../../../../../PostSalesStyles';

const RTPCRCard = ({ heading, description, bookingIds, rtPcrCtaList, onRTPCRBtnClick }) => {
  const { fsStyle, ...fonts } = getFont(true);
  const onLinkPress = (url) => {
    const urlParams = fetchUrlParam(url);
    if (urlParams.openInWebView && urlParams.openInWebView.toLowerCase() === 'true') {
      onRTPCRBtnClick({ url: url, openInWebView: true, webViewHeaderText: '' });
    } else {
      let urlToOpen = url;
      if (urlParams.openInWebView) {
        urlToOpen = removeParam('openInWebView', url);
      }
      onRTPCRBtnClick({ url: urlToOpen, openInWebView: false, webViewHeaderText: '' });
    }
  };

  const actionItemView = () => {
    const views = [];
    if (!isEmpty(rtPcrCtaList)) {
      rtPcrCtaList.forEach((cta) => {
        views.push(
          <TouchableOpacity
            style={styles.redirectBtnWrapper}
            activeOpacity={0.8}
            onPress={() => onRTPCRBtnClick(cta)}
          >
            <Text style={[styles.linkBtnTxt, fonts.boldFontFamily, fsStyle.font14]}>
              {cta.text}
            </Text>
            <Image style={styles.rightArrowIcon} source={RIGHT_ARROW} />
          </TouchableOpacity>,
        );
      });
    }
    return views;
  };

  return (
    <View style={[AtomicCss.marginBottom10]}>
      <View style={[styles.infoCardWrapper]}>
        <View style={styles.headingIconWrapper}>
          <Image style={styles.headingIconStyle} source={TRIP_PROTECTIPN_ICON} />
        </View>
        <View style={styles.infoCardContent}>
          {!isEmpty(heading) && (
            <Text
              style={[
                AtomicCss.defaultText,
                fonts.blackFontFamily,
                fsStyle.font14,
                AtomicCss.marginBottom15,
              ]}
            >
              {heading}
            </Text>
          )}
          {!isEmpty(description) && <HTMLView value={description} onLinkPress={onLinkPress} />}
          {!isEmpty(bookingIds) && (
            <View style={styles.policySection}>
              <Image style={styles.headingIconStyle} source={FLT_POLICY_ICON} />
              <Text style={[styles.policyText, fonts.regularFontFamily, fsStyle.font12]}>
                {bookingIds}
              </Text>
            </View>
          )}
        </View>
      </View>
      {actionItemView()}
    </View>
  );
};

RTPCRCard.propTypes = {
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  bookingIds: PropTypes.string.isRequired,
  ctaList: PropTypes.array.isRequired,
};

export default React.memo(RTPCRCard);
