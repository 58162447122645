import React from 'react';

export const ToastRef = React.createRef(null);
const ToastWeb = {
  show: (msg, id = "") => {
    ToastRef.current.show(msg, { id });
  },

  hide: (id = "") => {
    ToastRef.current.hide(id);
  }
};
export default ToastWeb;

