import BusBookingModule from '@mmt/legacy-commons/Native/BusBookingModule';
import FlightBookingModule from '@mmt/legacy-commons/Native/FlightBookingModule';
import { getFont } from '../../PostSalesStyles';
import { useTheme } from '../../theme/theme.context';
import Fecha from 'fecha';

export function handleCalenderDate(date) {
  if (date) {
    return date.split('T')[0].split('-').reverse().join('-');
  }
}

export function getStyles(createStyles) {
  const fonts = getFont();
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);
  return styles;
}

export function openCalender() {
  const params = { departDate: Platform.OS == 'android'? new Date().getTime().toString() : new Date() };
  if (Platform.OS == 'android') {
    BusBookingModule.openCalendar(params);
  } else if(Platform.OS == 'ios') {
    FlightBookingModule.openCalendar(params);
  }
  else{
    return;
  }
}

export function getSelectedSeats(val) {
  const seatArr = val.split(',');
  const selectedSeats = [];
  seatArr.forEach((seat) => {
    selectedSeats.push({ seatNumber: seat });
  });
  return selectedSeats;
}