import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
import { Dimensions } from 'react-native';

const windowHeight = Dimensions.get('screen').height;
const windowWidth = Dimensions.get('screen').width;

const stylesOverlay = {
  overlayWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: windowHeight - 10,
    width: windowWidth,
    elevation: 10,
    zIndex: 100,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    flexDirection: 'row',
    marginTop: 50,
    paddingBottom: 70,
  },
  overlayContent: {
    backgroundColor: colors.white,
    paddingHorizontal: 10,
    position: 'relative',
    zIndex: 1,
    width: '100%',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    paddingTop: 10,
    top: 100,
  },
  secureTripWrapper: {
    marginBottom: 12,
    backgroundColor: '#fff',
    ...getPlatformElevation(1),
  },
  containertest: {
    marginTop: 118,
  },
  borerTxt: {
    backgroundColor: 'red',
    ...getPlatformElevation(2),
  },
  secureIconStyle: {
    width: 26,
    height: 26,
    marginRight: 8,
  },
  paddingHz20: {
    paddingHorizontal: 20,
  },
  headDetailText: {
    borderBottomWidth: 1,
    borderBottomColor: '#f2f2f2',
    paddingBottom: 20,
    marginBottom: 20,
    paddingHorizontal: 10,
  },
  benefitIconStyle: {
    width: 24,
    height: 24,
    marginRight: 16,
  },
  benefitsBtn: {
    // backgroundColor:'#f4f4f4',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 6,
    marginHorizontal: 16,
    marginBottom: 10,
    borderRadius: 2,
  },
  greenText: {
    color: colors.lightSeaGreenTextColor,
  },
  crossIconActionWrapper: {
    position: 'absolute',
    backgroundColor: '#fff',
    width: '100%',
    padding: 20,
    top: -10,
    left: 0,
    zIndex: 3,
    elevatio: 3,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  crossWrapper: {
    position: 'absolute',
    paddingHorizontal: 4,
    paddingVertical: 4,
    top: 19,
    right: 0,
  },
  backIconStyle: {
    width: 15,
    height: 15,
  },
  lastItem: {
    marginBottom: 0,
  },
  checkBoxWrapper: {
    backgroundColor: '#fff',
    padding: 20,
    marginBottom: 12,
    ...getPlatformElevation(1),
  },
  btnBox: {
    height: 220,
  },
  btnWrapper: {
    position: 'absolute',
    bottom: 120,
    left: 0,
    paddingHorizontal: 16,
    paddingVertical: 30,
    width: '100%',
  },
  flatBtnStyle: {
    borderRadius: 4,
    paddingHorizontal: 20,
    paddingVertical: 14,
    ...getPlatformElevation(2),
  },
  linkTxt: {
    color: '#008cff',
  },
  greyText: {
    color: '#f2f2f2',
  },
};

export default stylesOverlay;
