import Actions from '../../../navigation/postSalesNavigation';
import _ from 'lodash';
import fecha from 'fecha';
import {
  TRAVELLER, DEFAULT_PRIMARY_NAME, DATE_FORMAT_DISPLAY, PARENTCURLYBRACES, STATUS_CODE_SUCCESS, PASSENGER_BOOKING_STATUS
} from './DynamicFormConstants';
import styles from './DynamicFormCss';

export const validateDynamicForm = (master, visaForm) => {
  let isValid = true;
  if (!visaForm) return isValid;
  const keys = Object.keys(visaForm);
  keys.forEach((key) => {
    if (master[key]) {
      let validLen = 0;
      visaForm[key].forEach((elem) => {
        if (elem.value === '') {
          isValid = isValid && !master[key].mandatory && validateDynamicForm(master, elem.dependents);
        } else {
          isValid = isValid && isValidPattern(new RegExp(master[key].validations[elem.parent]), elem.value)
              && validateDynamicForm(master, elem.dependents);
        }
        if (isValid) validLen += 1;
      });
      if (master[key].mandatory && !validLen) {
        isValid = false;
      }
    }
  });
  return isValid;
};

export const isValidPattern = (pattern, val) => {
  if (pattern) {
    return pattern.test(val);
  }
  return true;
};

export const redirectToFormPreview = (visaFormObj, params) => {
  launchForm(visaFormObj, params);
};

export const launchForm = async (visaFormObj, params) => {
    Actions.visaFormNewPreview({
      visaForm: visaFormObj,
      visaParams: params
    });
};

export const isNextKeyGood = (key, closestElemLabel, label) => {
  if (isGreater(key, label) && isGreater(closestElemLabel, key)) {
    return true;
  }
  return false;
};

export const isGreater = (a, b) => {
  const _a = a.split('.');
  const _b = b.split('.');
  let i = 0;
  let j = 0;
  while (i < _a.length && j < _b.length) {
    if (Number(_a[i]) > Number(_b[j])) { return true; } else if (Number(_a[i]) < Number(_b[j])) { return false; }
    i += 1;
    j += 1;
  }
  if (i !== _a.length) return true;
  if (j !== _b.length) return false;
  return false;
};

export const getScreenTitleForEmptyTraveller = (paxList, paxId) => {
  const traveller = getPrimaryTraveller(paxList, paxId);
  if (_.isEmpty(traveller)) {
    return (`${capitalize(TRAVELLER)} ${paxId}/${paxList.length}`);
  }
  return traveller;
};

// If paxId is passed, its not for Primary(its for other pax)
export const getPrimaryTraveller = (paxList, paxId = 1) => {
  const arrayIndex = (paxId - 1); // paxId starts from 1, 2 and so on...
  if (!_.isEmpty(paxList) && paxList.length > arrayIndex) {
    return paxList[arrayIndex].name;
  }
  return DEFAULT_PRIMARY_NAME;
};

export const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1);

export const formatNavSubTitle = ((param1, param2, param3, param2Key = 'Entry') => {
  let subTitle = '';
  if (param1) {
    subTitle += param1;
    if (param2) {
      subTitle += ` | ${param2Key} ${param2}`;
    }
    if (param3) {
      subTitle += ` | ${param3}`;
    }
  } else if (param2) {
    subTitle += `${param2Key} ${param2}`;
    if (param3) {
      subTitle += ` | ${param3}`;
    }
  } else if (param3) {
    subTitle += param3;
  }
  return subTitle;
});

export const getDisplayDate = (date) => {
  if (date) {
    return fecha.format(getDate(date), DATE_FORMAT_DISPLAY);
  }
  return '';
};

export const getDate = (dateStr) => {
  if (dateStr.indexOf('-') !== -1) {
    const splitDate = dateStr.split('-');
    const date = new Date(parseInt(splitDate[2], 10), parseInt(splitDate[1], 10) - 1, parseInt(splitDate[0], 10));
    return date;
  }
  return dateStr;
};

export const getHypotheticalParentNode = (parent, element) => {
  if (element.url === '') {
    if (element.validations[parent] === '' || element.validations[parent]) {
      return parent;
    }
    return '';
  } else if (element.url.includes(PARENTCURLYBRACES)) {
    return parent;
  } else if (!element.url.includes('&parent=')) {
    return '';
  }
  const splittedVal = element.url.split('=');
  return splittedVal[splittedVal.length - 1];
};

export const getIndex = (jsonKey) => {
  const keys = jsonKey.split('.');
  const lastKeyWithIndex = keys[keys.length - 1];
  const index = lastKeyWithIndex.split('[')[1];
  return index.split(']')[0];
};

export const getSingleMultiInputStyle = (disabled) => {
  if (disabled) { return styles.tabActiveDisabled; }
  return styles.tabActive;
};

export const isResponseValid = response =>
  response && (response.statusCode_ === STATUS_CODE_SUCCESS || response.statusCode_ === '');

export const setErrorField = (mandatory, val, errorLabel) => {
  if (mandatory && val === '') {
    return 'Cannot leave this blank';
  }
  return errorLabel;
};

export const getNextPaxIdForDocUpload = (params) => {
  if (params.paxList.length === 1) {
    return {id: -1, status: -1};
  }

  const paxPendingList = _.filter(params.paxList, row =>
    row.status === PASSENGER_BOOKING_STATUS.PASSENGER_INITIATED
    || row.status === PASSENGER_BOOKING_STATUS.DOCUMENTS_PENDING);

  if (!_.isEmpty(paxPendingList)) {
    return paxPendingList[0];
  }

  const paxVerifiedList = _.filter(params.paxList, row =>
    row.status === PASSENGER_BOOKING_STATUS.DOCUMENTS_UPLOADED);

  if (!_.isEmpty(paxVerifiedList)) {
    return paxVerifiedList[0];
  }

  return {id: -1, status: -1};
};

export const updatePaxStatus = (pax) => {
  const temp = pax;
  switch (pax.status) {
    case PASSENGER_BOOKING_STATUS.PASSENGER_INITIATED:
    case PASSENGER_BOOKING_STATUS.DOCUMENTS_PENDING:
      temp.status = PASSENGER_BOOKING_STATUS.DOCUMENTS_UPLOADED;
      break;
    case PASSENGER_BOOKING_STATUS.DOCUMENTS_UPLOADED:
      temp.status = PASSENGER_BOOKING_STATUS.DOCUMENTS_VERIFIED;
      break;
    default:
      break;
  }
};

export const createParamsForNextTravellerNew = (visaForm, visaParams, nextPaxId, status) => {
  const params = visaParams;
  params.paxId = nextPaxId;
  // index is 0, because addmore value is false for these keys
  const firstName = extractValue(visaForm, 'firstName')[0].value;
  const lastName = extractValue(visaForm, 'lastName')[0].value;
  params.paxName = `${firstName} ${lastName}`;
  params.operation = status;
};

export const extractValue = (visaForm, key) => visaForm.fieldValues[key];

export const getVisaFormSubTitle = visaParams => formatNavSubTitle(
  visaParams.destCountry,
  getDisplayDate(visaParams.startDate),
  getPrimaryTraveller(visaParams.paxList)
);


