import { StyleSheet, Platform } from 'react-native';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from '../../css/GetPlatformElevationCss';

const styles = StyleSheet.create({
  pageWrapper: {
    flex: 1,
    backgroundColor: '#f2f2f2',
  },
  footerBtnWrapper: {
    paddingLeft: 12,
    paddingRight: 12,
    paddingBottom: 10,
    position: 'absolute',
    zIndex: 2,
    bottom: 0,
    left: 0,
    width: '100%',
    backgroundColor: '#ffffff',
  },
  whiteCard: {
    backgroundColor: '#ffffff',
    padding: 15,
    marginVertical: 8,
    marginBottom: 8,
    elevation: 2,
    shadowColor: '#000000',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.3,
    shadowRadius: 1,
  },
  cardTitle: {
    color: colors.gray,
    fontSize: 21,
    marginBottom: 10,
  },
  cardSubTitle: {
    fontSize: 14,
    color: colors.gray,
    marginBottom: 20,
  },
  pageScrollBtmSpace: {
    height: 58,
  },
  cardWrapper: {
    backgroundColor: colors.white,
    elevation: 3,
  },
  checkAllWrapper: {
    borderBottomWidth: 1,
    borderBottomColor: colors.lightGrey,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    backgroundColor: colors.white,
    marginBottom: 2,
  },
  sectorPaxList: {
    paddingVertical: 15,
    paddingHorizontal: 45,
  },
  sectorContainer: {
    padding: 10,
    marginHorizontal: 40,
  },
  sectorBorderStyle: {
    borderBottomWidth: 1,
    borderBottomColor: '#e4e4e4',
  },
  checkBtn: {
    flexDirection: 'row',
    paddingVertical: 10,
    paddingHorizontal: 15,
    alignItems: 'center',
  },
  checkAllBtn: {
    alignItems: 'flex-start',
  },
  checkbox: {
    height: 20,
    width: 18,
    backgroundColor: colors.white,
    borderColor: colors.disabledBtnBg,
    borderWidth: 1.5,
    borderRadius: 3,
    overflow: 'hidden',
    position: 'relative',
    marginRight: 12,
  },
  radio: {
    height: 24,
    width: 24,
    backgroundColor: colors.white,
    overflow: 'hidden',
    position: 'relative',
    marginRight: 12,
  },
  checkboxSelected: {
    borderColor: 'transparent',
    borderWidth: 0,
  },
  checkboxAll: {
    marginTop: 10,
  },
  checkedIcon: {
    position: 'absolute',
    height: 29,
    width: 29,
    left: -5,
    top: -4,
  },
  radioIcon: {
    position: 'absolute',
    height: 29,
    width: 29,
    left: -3,
    top: -3,
  },
  destRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  cityText: {
    color: colors.black28,
  },
  subtitle: {
    color: colors.defaultTextColor,
    marginTop: 3,
  },
  arrowIcon: {
    width: 10,
    height: 7,
    marginHorizontal: 5,
  },
  passengerName: {
    color: colors.black,
  },
  passengersList: {
    paddingHorizontal: 8,
  },
  PaxslctionHeader: {
    flex: 1,
    justifyContent: 'space-between',
  },
  SectorHeader: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
    padding: 5,
    ...Platform.select({ web: { flexDirection: 'column' } }),
  },
  checkAllWrapperInner: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  airlinesIconWrapper: {
    width: 33,
    height: 33,
  },
  airlineIcon: {
    width: 32,
    height: 32,
    borderRadius: 5,
  },
  paxDisabled: {
    backgroundColor: '#f9f9f9',
    opacity: 0.7,
  },
  boxDisabled: {
    backgroundColor: '#f9f9f9',
    opacity: 0.7,
  },
  disableChkBox: {
    backgroundColor: '#e7e7e7',
    borderColor: '#cccccc',
  },
  specialclaim: {
    paddingLeft: 40,
    paddingRight: 20,
  },
  importantInofTag: {
    borderRadius: 10,
    width: 150,
    height: 15,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 10,
    left: 30,
    elevation: 2,
    zIndex: 111,
  },
  tagStyle: {
    color: '#fff',
  },
  removetopMargin: {
    marginTop: 0,
  },
  seperator: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginHorizontal: 0,
  },
  semiCircle: {
    height: 16,
    width: 16,
    borderRadius: 8,
    backgroundColor: '#f2f2f2',
  },
  leftSemiCircle: {
    marginLeft: -8,
  },
  rightSemiCircle: {
    marginRight: -8,
  },
  dashedLineWrapper: {
    height: 2,
    flex: 1,
    overflow: 'hidden',
  },
  dashedLine: {
    flex: 1,
    marginHorizontal: 0,
    borderStyle: 'dashed',
    borderWidth: 1,
    borderColor: '#f2f2f2',
    height: 3,
  },
  collapseMainWrapper: {
    ...getPlatformElevation(2),
    backgroundColor: colors.white,
  },
  collapseHeadingSection: {
    paddingLeft: 50,
    paddingVertical: 20,
    paddingRight: 20,
    backgroundColor: colors.white,
  },
  arrowStyle: {
    width: 16,
    height: 10,
    top: 10,
    left: 7,
    transform: [{ scale: 0.7 }],
  },
  activeArrow: {
    transform: [{ rotate: '180deg' }, { scale: 0.7 }],
  },
  leftDetails: {
    width: '80%',
  },
  actionLink: {
    minHeight: 30,
  },
  arrowWrapper: {
    width: 30,
    height: 30,
    backgroundColor: '#EAF5FF',
    borderRadius: 25,
    position: 'absolute',
    right: 0,
  },
  actionBtnWrapper: {
    minHeight: 30,
    position: 'absolute',
    width: 50,
    height: 50,
    zIndex: 111,
    right: 20,
    justifyContent: 'flex-end',
  },
});

export default styles;
