import React from 'react';
import PropTypes from 'prop-types';
import {View, Text, Image, StyleSheet} from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import styles from './CabMamiCss';
import { getButtonStyle, handleClickEvent, isNotNullAndEmpty} from "../utils/CabBookingDetailUtil";
import {paymentIcon} from "../CabImageConstants";
import {isEmpty} from 'lodash';
import BaseButton, { BUTTON_VARIANT } from '../../Common/BaseButton';
import CardWrapper from '../modules/bookingDetails/components/CardWrapper';
import { ThemeContext } from '../../theme/theme.context';
import CabBookingDetailsConstant from '../CabBookingDetailsConstant';
import { getActionButton } from 'apps/post-sales/src/Common/commonUtil';
import { getFont } from '../../PostSalesStyles';
import HeadingComponent from '../modules/bookingDetails/components/HeadingComponent';

class PriceBreakUpWithPayOnlineOption extends React.Component {
  static contextType = ThemeContext;
    constructor(props) {
        super();
        this.state = {
            enteredInput :  0,
        }
    }

  render() {
    const { color } = this.context.psTheme;
    const priceBreakup = this.props.response.paymentBreakups;
    const response = this.props.response;
    const {...fonts} = getFont()
    const payModeStyles = getStyles(color, fonts);
    const outstandingAmountObj = priceBreakup.find((item) =>
      item.hasOwnProperty('ctaEnabled'),
    );
    const isCtaEnabled =
      !isEmpty(outstandingAmountObj) && outstandingAmountObj.ctaEnabled;
    const ctaText = isCtaEnabled && outstandingAmountObj.ctaLabel;
    const outStandingAmount =
      !isEmpty(outstandingAmountObj) && outstandingAmountObj.amountText;
    const { heading } = this.props.card || {};
    return (
      <React.Fragment>
         <HeadingComponent heading={heading} showIcon={true} containerStyle={styles.cardHeading}/>
          <View style={styles.cardHeading}>
            <View style={[styles.mar8Bt, styles.flexRow]}>
              <View style={[styles.flex1]}>
                {priceBreakup &&
                  priceBreakup
                    .filter((breakup) => breakup.key !== 'TOTALPAID')
                    .map((payment) => (
                      <React.Fragment>
                        <View
                          style={[
                            styles.marginBottom8,
                            styles.rowSection,
                            styles.flex1,
                          ]}
                        >
                          {!isEmpty(payment.modeLabel) && (
                            <Text
                              style={[
                                styles.blackFont,
                                styles.BlackText,
                                styles.font14,
                                styles.mar5Bt,
                              ]}
                            >
                              {payment.modeLabel}
                            </Text>
                          )}
                          {!isEmpty(payment.amountText) && (
                            <Text
                              style={[
                                styles.blackFont,
                                styles.BlackText,
                                styles.font14,
                                styles.mar5Bt,
                              ]}
                            >
                              {payment.amountText}
                            </Text>
                          )}
                        </View>
                        {!isEmpty(payment.subText) && (
                          <Text
                            style={[
                              styles.darkGrey,
                              styles.regularFont,
                              styles.font14,
                              styles.lineHt22,
                              styles.subtextSection,
                            ]}
                          >
                            {payment.subText}
                          </Text>
                        )}
                        {payment.subPaymentBreakup &&
                          payment.subPaymentBreakup.map((subPayment) => (
                            <View style={[styles.rowSection]}>
                              <Text
                                style={[
                                  styles.darkGrey,
                                  styles.regularFont,
                                  styles.font14,
                                  styles.mar5Bt,
                                  styles.lineHt22,
                                ]}
                              >
                                {subPayment.modeLabel}{' '}
                              </Text>
                              <Text
                                style={[
                                  styles.flexStart,
                                  styles.font16,
                                  styles.darkGrey,
                                  styles.regularFont,
                                ]}
                              >
                                {' '}
                                {subPayment.amountText}
                              </Text>
                            </View>
                          ))}
                      </React.Fragment>
                    ))}
              </View>
            </View>
            <View
              style={[
                payModeStyles.amountPendingSection,
                AtomicCss.marginBottom24,
              ]}
            >
              <View
                style={[
                  AtomicCss.flexRow,
                  AtomicCss.spaceBetween,
                  AtomicCss.marginBottom3,
                ]}
              >
                <Text
                  style={[
                    AtomicCss.blackFont,
                    AtomicCss.font12,
                    styles.brownText,
                  ]}
                >
                  {CabBookingDetailsConstant.amountDueText}
                </Text>
                <Text
                  style={[
                    AtomicCss.blackFont,
                    AtomicCss.font12,
                    styles.brownText,
                  ]}
                >
                  {outStandingAmount}
                </Text>
              </View>
              {!isEmpty(response.clientDetails) &&
                !isEmpty(response.clientDetails.extraChargeText) && (
                  <Text
                    style={[
                      AtomicCss.regularFont,
                      AtomicCss.font12,
                      styles.brownText,
                    ]}
                  >
                    {response.clientDetails.extraChargeText}
                  </Text>
                )}
              {!isEmpty(response.clientDetails) &&
                !isEmpty(response.clientDetails.priceBreakupText) && (
                  <Text
                    style={[
                      styles.blackFont,
                      styles.font14,
                      styles.mar5Bt,
                      styles.mar15Top,
                    ]}
                  >
                    {response.clientDetails.priceBreakupText}
                  </Text>
                )}
            </View>
            {isCtaEnabled && (
              <React.Fragment>
                <View style={[styles.marginBottom8]}>
                  <BaseButton
                    text={ctaText}
                    clickHandler={() => {
                      this.props.payOnlinePayment(
                        outstandingAmountObj.maxPayAmount,
                      );
                    }}
                    variant={BUTTON_VARIANT.CAPSULE}
                    buttonStyle={{ marginBottom: 20 }}
                    shadow
                  />
                </View>
                <Text
                  style={[
                    AtomicCss.defaultText,
                    AtomicCss.font12,
                    AtomicCss.textCenter,
                    AtomicCss.marginTop6,
                    AtomicCss.marginBottom6,
                  ]}
                >
                  {CabBookingDetailsConstant.payTotheDriverText}
                </Text>
              </React.Fragment>
            )}
          </View>
          <View
            style={
              isNotNullAndEmpty(this.props.card.actionList)
                ? [
                    getButtonStyle(this.props.card.actionList),
                    styles.borderTop,
                    styles.buttonRadiusStyle
                  ]
                : styles.hideView
            }
          >
            {getActionButton(
              this.props.card,
              this.props.response,
              this.handleClick,
              {},
              true,
              false,
              styles.buttonRadiusStyle,
              // styles.customStyle
            )}
          </View>
      </React.Fragment>
    );
  }
  handleClick = (action) => {
    if(action.actionFamily === CabBookingDetailsConstant.ACTION_DOWNLOAD_INVOICE){
      const {onDownloadInvoiceClick} = this.props;
      typeof onDownloadInvoiceClick === 'function' && onDownloadInvoiceClick();
    }else{
      handleClickEvent(
        this.props.card,
        action,
        this.props.response,
        this.props.pageName,
      );
    }
  };
}

PriceBreakUpWithPayOnlineOption.propTypes = {
  response: PropTypes.object.isRequired,
  card: PropTypes.object.isRequired,
  pageName: PropTypes.string.isRequired,
};

const getStyles = (color, fonts) => {
  return StyleSheet.create({
    headingText: {
      color: color.black,
      fontSize: fonts.fontSize.font16,
      ...fonts.boldFontFamily,
    },
    amountPendingSection: {
      borderRadius: 8,
      padding: 8,
      backgroundColor: color.creamWhite,
    },
  });
};

export default CardWrapper(PriceBreakUpWithPayOnlineOption);
