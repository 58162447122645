import { StyleSheet } from 'react-native';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
import { Fonts, Theme } from '../../types';

const getStyle = (fonts : Fonts, theme : Theme) => {
  const {color} = theme;
  return  StyleSheet.create({
    headingSection: {
      padding: 20,
      paddingBottom: 0,
    },
    headingText: {
      color: color.black,
      ...fonts.blackFontFamily,
      fontSize: fonts.fontSize.font20,
    },
    payPendingIconStyle: {
      width: 32,
      height: 32,
      marginRight: 10,
    },
    bodySection: {
      paddingVertical: 12,
      paddingHorizontal: 20,
    },

    crossIconStyle: {
      width: 24,
      height: 24,
    },
    crossIconWrapper: {
      width: 30,
      height: 30,
      top: -5,
      right: -5,
      alignItems: 'center',
      justifyContent: 'center',
    },
    btnWrapper: {
      paddingHorizontal: 20,
      paddingVertical: 12,
      paddingTop: 0,
    },
    partialPayFareBreakup: {
      borderBottomWidth: 1,
      borderBottomColor: color.lightGray,
      paddingVertical: 12,
    },
    successText: {
      color: color.lightGreen18,
    },
    buttonTxtStyle: {
      fontSize: fonts.fontSize.font16,
      ...fonts.boldFontFamily,
      textAlign: 'center',
    },
    buttonStyle: {
      borderRadius: 10,
      paddingHorizontal: 10,
      paddingVertical: 15,
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'center',
      ...getPlatformElevation(2),
    },
    priceText: {
      color: color.black,
      ...fonts.blackFontFamily,
      fontSize: fonts.fontSize.font14,
    },
    remainingText: {
      color: color.lightGreen18,
      ...fonts.blackFontFamily,
      fontSize: fonts.fontSize.font14,
    },
    priceSubText: {
      color: color.black74,
      ...fonts.regularFontFamily,
      fontSize: fonts.fontSize.font12,
    },
  });
}


export default getStyle;

export const htmlstyles = (fonts : Fonts, theme : Theme) => {
  const {color} = theme;
  return StyleSheet.create({
    p: {
      color: color.black,
    },
    b: {
      ...fonts.boldFontFamily
    }
  });
};
