import React from 'react';
import { getWhiteCardStyles } from './WhiteCardCss';
import WhiteCard from '../WhiteCard';
import { useTheme } from '../../theme/theme.context';
import { View } from 'react-native';

const WhiteCardWrapper = (Component) => {

  return function WrappedComponent(props) {
    const { psTheme } = useTheme();
    const { color } = psTheme;
    const whiteCardStyle = getWhiteCardStyles(color);
    return (
      <View style={{ zIndex: 5 }}>
        <WhiteCard styles={whiteCardStyle}>
          <Component {...props} />
        </WhiteCard>
      </View>
    );
  };
};

export default WhiteCardWrapper;
