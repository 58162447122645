import React from 'react';
import { View, Text, Image, TouchableOpacity, StyleSheet } from 'react-native';
import WhiteCardWrapper from '../../../Common/WhiteCardWrapper';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getStyle, { htmlstyles } from './style';
import HTMLView from 'react-native-htmlview';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import { PAY_PENDING_ICON } from '../../Utils/RailImageConstant';
import Button from 'apps/post-sales/src/Common/Button';
import { getStaticData } from 'apps/post-sales/src/staticData/staticData';
import isEmpty from 'lodash/isEmpty';
import BaseButton from '../../../Common/BaseButton';
import { PaymentPendingCardProps } from '../../types';
import RailBookingTrackingHelper from '../../details/RailBookingTrackEventHelper';
import { CLICK_EVENT } from '../../details/constants';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';

const PaymentPendingCard = ({
  card,
  response,
  pageName,
  handleOpenBottomOverlay,
  handlePendingPayClick,
}: PaymentPendingCardProps) => {
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme } = useTheme();
  const { color } = psTheme;
  const styles = getStyle(fonts, psTheme);

  const { heading, actionList } = card || {};
  const { viewDetailsText } = getStaticData(true);
  const { bnppDetail } = response || {};
  const { paymentPendingCardData, paymentCheckoutUrl, paymentPendingOverlayData } = bnppDetail || {};
  const { dataList, viewTnCText, viewTnCDeeplink } = paymentPendingCardData || {};

  const onPayClick = () => {
    RailBookingTrackingHelper.trackClickEvent(pageName, response, CLICK_EVENT.BNPP_CARD_PAY_CLICK);
    handlePendingPayClick(paymentCheckoutUrl);
  };

  const onViewDetailClick = () => {
    RailBookingTrackingHelper.trackClickEvent(
      pageName,
      response,
      CLICK_EVENT.BNPP_VIEW_DETAIL_CLICK,
    );
    handleOpenBottomOverlay('PendingPayment');
  };

  const onViewTncClick = () => {
    if (!isEmpty(viewTnCDeeplink)) {
      GenericModule.openDeepLink(viewTnCDeeplink);
    }
  };

  return (
    <View style={styles.payPeningCard}>
      <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom12]}>
        <View style={[AtomicCss.flex1, AtomicCss.flexRow, AtomicCss.alignCenter]}>
          <Image style={styles.payPendingIconStyle} source={PAY_PENDING_ICON} />
          {!isEmpty(heading) && <Text style={styles.heading}>{heading}</Text>}
        </View>
        {!isEmpty(viewDetailsText) && !isEmpty(paymentPendingOverlayData) && (
          <TouchableOpacity
            onPress={() => {
              onViewDetailClick();
            }}
          >
            <Text style={styles.viewDetailStyle}>{viewDetailsText}</Text>
          </TouchableOpacity>
        )}
        {!isEmpty(viewTnCText) && (
          <TouchableOpacity onPress={() => onViewTncClick()}>
            <Text style={styles.viewTncText}>{viewTnCText}</Text>
          </TouchableOpacity>
        )}
      </View>
      <View style={styles.detailsSection}>
        {!isEmpty(dataList) &&
          dataList.map((item, index) => {
            const { text, boldTextColor } = item;
            return (
              <View style={AtomicCss.marginBottom5}>
                <HTMLView value={text} stylesheet={htmlstyles(fonts, psTheme, boldTextColor)} />
              </View>
            );
          })}
      </View>

      {!isEmpty(paymentCheckoutUrl) &&
        !isEmpty(actionList) &&
        actionList.map((action, index) => {
          const { actionType: variant, actionLabeltext, actionId } = action || {};
          return (
            <BaseButton
              variant={variant}
              buttonStyle={styles.buttonStyle}
              textStyle={styles.buttonTxtStyle}
              clickHandler={() => {
                onPayClick();
              }}
              text={actionLabeltext}
            ></BaseButton>
          );
        })}
    </View>
  );
};

export default PaymentPendingCard;
