import { StyleSheet } from "react-native";
export default (theme, fonts) =>StyleSheet.create({
    iconWrapper: {
        width: 26,
        height: 26,
        marginRight: 10,
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    paxIconStyle: {
        width: 24,
        height: 24,
    },
    detailsSection: {
        paddingLeft: 18,
        paddingRight: 0,
    },
    refreshIconStyle: {
        width: 16,
        height: 17,
        marginLeft: 5
    },
})

