import { Text, View, TouchableOpacity, Image } from 'react-native';
import React, { useState } from 'react';
import { useTheme } from '../../../../theme/theme.context';
import createStyle from './myBizSwitcherCss';
import {
  TIP_FACING_TOP_ICON,
  WHITE_CROSS_ICON,
} from '../../../../flights/FlightImageConstants';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getFont } from '../../../../PostSalesStyles';

const MybizToolTip = ({ tootlTipText }) => {
  const [showToolTip, setShowTooltip] = useState(!!tootlTipText);
  const { psTheme: theme } = useTheme();
  if (!showToolTip) return null;
  const fonts = getFont(true);
  const styles = createStyle(theme, fonts);

  return (
    <View style={styles.tooltipWrapper}>
      <View style={styles.arrowWrapper}>
        <Image style={styles.arrowStyle} source={TIP_FACING_TOP_ICON} />
      </View>
      <View
        style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.spaceBetween, AtomicCss.flex1]}
      >
        <Text style={styles.notificationInfoTxt}>{tootlTipText} </Text>
        <TouchableOpacity
          style={styles.crossIconWrapper}
          onPress={() => {
            setShowTooltip(false);
          }}
        >
          <Image style={styles.crossIconWhite} source={WHITE_CROSS_ICON} />
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default MybizToolTip;
