import React from 'react';
import PropTypes from 'prop-types';
import {View, Text, Image, ScrollView, Platform} from 'react-native';
import  isEmpty  from 'lodash/isEmpty';
import Button from './Button';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import Header from './Header';
import {screenWidth, cancelledIcon} from '../HolidayBookingConstants';
import {removeTags} from '../HolidayBookingDetailUtils';

const cancellationHeading = 'Call MakeMyTrip to Cancel Holiday Package';

class CancellationModal extends React.Component {
  static navigationOptions = {
    header: null
  };

  render() {
    const {cancellationPage} = this.props;
    const cancellationInfoList = removeTags(cancellationPage.content)
      .split('~');
    return (
      <View style={styles.container}>
        <Header
          title="Cancel Holiday Package"
          headerShadow
          pageName={this.props.pageName}
          goBack={this.props.onBackPopUp}
        />
        <ScrollView>
          <View style={AtomicCss.whiteBg}>
            <View style={[styles.cardWrapper, AtomicCss.marginBottom10]}>
              <View style={[AtomicCss.marginBottom24, AtomicCss.alignCenter]}><Image
                style={styles.imageIcon}
                source={cancelledIcon}
              />
              </View>
              <View style={[AtomicCss.marginBottom16, AtomicCss.alignCenter]}>
                <Text
                  style={[AtomicCss.boldFont, AtomicCss.blackText, AtomicCss.font20,
                    AtomicCss.textCenter, {lineHeight: 26}, {marginHorizontal: 10}]}>{cancellationPage.header}
                </Text>
              </View>

              <View>
                {cancellationInfoList.map((item, index) => {
                  return (
                    <View
                      style={[styles.cancellationInfoListItem, AtomicCss.flexRow]}
                      key={index}>
                      <Text
                        style={[AtomicCss.defaultText, AtomicCss.boldFont, styles.bulletText,
                          AtomicCss.marginRight10, styles.marginTop3]}>{'\u2B24'}</Text>
                      <Text
                        style={[AtomicCss.flex1, AtomicCss.regularFont, AtomicCss.font14,
                          AtomicCss.defaultText, AtomicCss.lineHeight20]}>{item}</Text>
                    </View>
                  );
                })
                }
              </View>
            </View>
          </View>

        </ScrollView>
        {!isEmpty(cancellationPage.buttons) &&
        <View style={styles.buttonWrapper}>
          <Button
            pageName={this.props.pageName}
            button={cancellationPage.buttons[0]}
            handleClick={this.props.handleButtonClick}
            btnTxt={cancellationPage.buttons[0].text}
          />
        </View>
        }
      </View>
    );
  }
}

const styles = {
  container: {
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: '#f2f2f2',
    position: Platform.OS === 'web' ? 'fixed': 'absolute',
    elevation: 3,
    zIndex: 3,
    width: '100%',
    height: '100%'
  },
  cardWrapper: {
    width: screenWidth,
    backgroundColor: '#ffffff',
    paddingHorizontal: 16,
    paddingTop: 16
  },
  marginTop3: {
    marginTop: 3
  },
  buttonWrapper: {
    paddingHorizontal: 10,
    paddingBottom: 20,
    backgroundColor: '#ffffff'
  },
  imageIcon: {
    width: 118,
    height: 72
  },
  bulletText: {
    fontSize: 6,
    paddingHorizontal: 5,
    paddingTop: 3
  },
  cancellationInfoListItem: {
    paddingVertical: 8,
    paddingRight: 15,
    lineHeight: 20
  }
};

CancellationModal.propTypes = {
  cancellationPage: PropTypes.object.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
  onBackPopUp: PropTypes.func.isRequired,
  pageName: PropTypes.string.isRequired
};

export default CancellationModal;
