import { StyleSheet } from 'react-native';
const createStyles = (fonts, theme) =>
  StyleSheet.create({
    topStatusBar: {
      paddingTop: 5,
      paddingRight: 10,
      position: 'absolute',
      top: 0,
    },
    backArrowWrapper: {
      width: 44,
      height: 44,
      alignItems: 'center',
      justifyContent: 'center',
      left: -20,
      zIndex: 10,
    },
    topStatusCard: {
      paddingTop: 10,
      paddingBottom: 10,
      paddingHorizontal: 20,
    },
    backarrow: {
      width: 16,
      height: 16,
      tintColor: theme.color.white,
    },
    IOSbackarrow: {
      width: 10,
      height: 16,
      position: 'absolute',
    },
    statusCardWrapper: {
      paddingBottom: 10,
    },
    statusSubHeadingTxt: {
      textAlign: 'center',
      ...fonts.mediumFontFamily,
      marginBottom: 8,
    },
    statusHeadingTxt: {
      textAlign: 'center',
      fontSize: fonts.fontSize.font20,
      ...fonts.boldFontFamily,
      lineHeight: 22,
      marginBottom: 15,
      paddingLeft: 20,
      paddingRight: 20,
    },
    statusSubHeadTxt: {
      textAlign: 'center',
      fontSize: fonts.fontSize.font14,
      lineHeight: 20,
      marginBottom: 10,
      ...fonts.regularFontFamily,
    },
    topIconStyle: {
      width: 52,
      height: 52,
      alignSelf: 'center',
    },
    bookingWrapper: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    booingDtlsTxt: {
      color: theme.color.black,
      fontSize: fonts.fontSize.font16,
      ...fonts.regularFontFamily,
      marginRight: 10,

    },
    booingDtlsbold: {
      color: theme.color.black,
      fontSize: fonts.fontSize.font16,
      ...fonts.boldFontFamily,
    },
    buttonWrapper: {
      alignItems: 'center',
      flexDirection: 'row',
      flex: 1,
      justifyContent: 'center',
    },
    noteTextWrapper: {
      backgroundColor: theme.color.creamWhite,
    },
    noteText: {
      fontSize: fonts.fontSize.font14,
      textAlign: 'center',
      color:theme.color.textGrey,
      padding:5,
    },
  });
export default createStyles;
