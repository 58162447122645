import { StyleSheet } from "react-native";
import { DEFAULT_THEME } from "apps/post-sales/src/theme/default.theme";

export default <T extends typeof DEFAULT_THEME>(theme: T, fonts: any) => StyleSheet.create({
    headingSection: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: 5,
    },
    headingText: {
      color: theme.color.black,
      ...fonts.blackFontFamily,
      fontSize: fonts.fontSize.font18,
    },
    activeStyle: {
      transform: [
        {
          rotate: '-180deg',
        },
      ],
    },
    arrow: {
      transform: [
        {
          rotate: '90deg',
        },
      ],
    },
    activeArrow: {},
    showMoreText: {
      color: theme.color.azure, 
      fontSize: fonts.fontSize.font14, 
      ...fonts.boldFontFamily
    },
    showMoreLink: {
      alignSelf: 'center',
      marginTop: 5,
      paddingVertical: 6,
      paddingLeft: 10,
      paddingRight: 5,
    },
    blueArrowStyle: {
      width: 32,
      height: 32,
    },
    blueArrowSmStyle: {
      marginTop: 5,
      width: 10,
      height: 10,
    },
  });