import React from 'react';
import {StyleSheet, View} from 'react-native';
import PropTypes from 'prop-types';
import {colors} from '../../Styles/globalStyles';

const ContainerView = ({ children, center, style: customStyle, id }) => {
  const style = [styles.container];
  if (center) {
    style.push(styles.centerContent);
  }
  if (customStyle) {
    if (customStyle instanceof Object) {
      style.push(StyleSheet.create({customStyle}).customStyle);
    // eslint-disable-next-line no-restricted-globals
    } else if (!isNaN(customStyle)) {
      style.push(customStyle);
    }
  }
  return (
    <View style={style} testID={id}>
      {children && children}
    </View>
  );
};


ContainerView.propTypes = {
  children: PropTypes.node,
  center: PropTypes.bool,
  style: PropTypes.any
};

ContainerView.defaultProps = {
  children: null,
  center: false,
  style: null
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.grayBg
  },
  centerContent: {
    alignItems: 'center',
    justifyContent: 'center'
  }
});

export default ContainerView;
