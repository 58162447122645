import { StyleSheet } from 'react-native';

export default (theme) => {
  const styles = StyleSheet.create({
    outerCircle: {
      backgroundColor: theme.color.white,
      borderColor: theme.color.primary,
    },
    innerCircle: {
      width: 12,
      height: 12,
      borderRadius: 20,
      marginLeft: 2,
      backgroundColor: theme.color.primary,
      marginTop: 2,
    },
    defaultRadio: {
      width: 18,
      height: 18,
      borderRadius: 20,
      borderWidth: 1,
      marginRight: 20,
      marginTop: 5,
      borderColor: theme.color.lightTextColor,
    },
  });
  return styles;
};
