import { ACTION_TYPES } from './actionTypes';

export const onHandleTabChangeAction = (index, dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_TAB_CHANGE,
    payload: { index },
  });
};

export const handleBottomOverlayAction = (bottomOverlay, isDropOff, dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_BOTTOM_OVERLAY,
    payload: { bottomOverlay, isDropOff },
  });
};

export const updatePickupDetailsDataAction = (index, data, isDropOff, dispatch) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_PICKUP_DETAILS,
    payload: { index, data, isDropOff },
  });
};

export const updateForAllCasesAction = (isDropOff, dispatch) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_FOR_ALL_CASES,
    payload: { isDropOff },
  });
};

export const handleOnChangeAction = (key, value, isDropOff = false, dispatch) => {
  dispatch({ type: ACTION_TYPES.ON_FIELD_CHANGE, payload: { key, value, isDropOff } });
};

export const onRemoveDetailsCardAction = (data, dispatch) => {
  dispatch({
    type: ACTION_TYPES.REMOVE_DROP_OFF_DETAILS,
    payload: {
      data,
    },
  });
}

export const onFieldTouchAction = ( isDropOff, fieldName, dispatch) => {
  dispatch({
    type: ACTION_TYPES.ON_FIELD_TOUCH,
    payload: {
      isDropOff,
      fieldName,
    },
  });
};
