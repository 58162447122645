import React, { ReactNode } from 'react';
import { View, StyleSheet, ViewStyle } from 'react-native';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import CardHeader from '../CardHeader';
import Divider from '../../Atoms/Divider';

/**
 * Props for the CardContainer component.
 * @interface CardContainerProps
 * @property {ReactNode} children - The child components to be rendered inside the CardContainer.
 * @property {ViewStyle} [containerStyle] - Additional styles for the CardContainer.
 * @property {ViewStyle} [headerContainerStyle] - Additional styles for the HeaderCardContainer.
 * @property {() => ReactNode} [renderHeader] - Callback function to render the header content.
 */
interface CardContainerProps {
  children: ReactNode;
  containerStyle?: ViewStyle;
  headerContainerStyle?: ViewStyle;
  renderHeader?: () => ReactNode;
}

/**
 * CardContainer component renders a white box container with customizable styles.
 * @param {CardContainerProps} props - The component props.
 * @returns {JSX.Element} - The rendered JSX element.
 */
const CardContainer: React.FC<CardContainerProps> = ({
  children,
  containerStyle = {},
  headerContainerStyle = {},
  renderHeader,
}) => (
  <View style={[styles.whiteCard, containerStyle]}>
    {renderHeader && (
      <>
        <CardHeader
          containerStyle={[{ paddingHorizontal: 12 }, headerContainerStyle]}
        >
          {renderHeader()}
        </CardHeader>
        <Divider />
      </>
    )}

    <View style={AtomicCss.paddingHorizontal12}>{children}</View>
  </View>
);

const styles = StyleSheet.create({
  whiteCard: {
    borderRadius: 8,
    backgroundColor: colors.white,
    paddingVertical: 16,
    marginBottom: 20,
  },
});

export default CardContainer;
