import React from 'react';
import { View, Text, Image, Linking } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import DaysTimeCountDown from '../../../../../Common/DaysTimeCountDown';
import iconClock from '@mmt/legacy-assets/src/iconClock.webp';
import Button from 'apps/post-sales/src/Common/Button';
import { getFont } from '../../../../../PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import createStyles from './styles';

interface CompleteBookingProps {
    paymentDetails?: {
        paymentChargeDateTime?: Date;
    };
    pendingPaymentCard?: {
        title?: string;
        subTitle?: string;
        ctaUrl?: string;
        tagTextStart?: string;
        tagTextEnd?: string;
        ctaText?: string;
    };
}

const CompleteBooking: React.FC<CompleteBookingProps> = ({
    paymentDetails,
    pendingPaymentCard
}) => {

    const { fsStyle, ...fonts } = getFont(true);
    const { psTheme: theme } = useTheme();
    const styles = createStyles(theme);

    const {
        title,
        subTitle,
        ctaUrl,
        tagTextStart,
        tagTextEnd,
        ctaText
    } = pendingPaymentCard || {};

    const { paymentChargeDate } = paymentDetails || {};

    const clickHandler = () => {
        if (ctaUrl) {
            Linking.openURL(ctaUrl);
        }
    };

    return (
        <View style={styles.completeBookingWrapper}>
            <View style={styles.leftStrip} />
            {!!title && (
                <Text style={[styles.completeText, fonts.blackFontFamily, fsStyle.font14]}>
                    {title}
                </Text>
            )}
            <View>
                <LinearGradient
                    start={{ x: 0, y: 0 }}
                    end={{ x: 1, y: 0 }}
                    style={styles.gradient}
                    colors={[theme.color.orange, theme.color.red23]}>
                    <View style={{ marginRight: 4 }}>
                        <Image source={iconClock} style={styles.clockIcon} />
                    </View>
                    <Text style={[styles.pendingText, fonts.blackFontFamily, fsStyle.font12]}>
                        {!!tagTextStart && (
                            <Text style={styles.textWithPaddingRight}>{tagTextStart}</Text>
                        )}
                        {!!paymentChargeDate && (
                            <DaysTimeCountDown initialDate={paymentChargeDate} />
                        )}
                        {!!tagTextEnd && (
                            <Text style={styles.textWithPaddingLeft}>{tagTextEnd}</Text>
                        )}
                    </Text>
                </LinearGradient>
            </View>
            {!!subTitle && (
                <Text style={[styles.makePaymentText, fonts.blackFontFamily, fsStyle.font14]}>
                    {subTitle}
                </Text>
            )}
            {!!ctaText && (
                <View style={styles.btnWprStyle}>
                    <Button
                        btnWidth={'98%'}
                        btnTxt={ctaText}
                        btnBg="gradientBtn"
                        clickHandler={clickHandler}
                    />
                </View>
            )}
        </View>
    );
};

export default CompleteBooking;
