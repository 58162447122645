import React from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';
import PropTypes from 'prop-types';
import FareBreakUp from './FareBreakUp';
import Header from '../../Common/HeaderCross';
import PayModeBreakup from './PayModeBreakup';
import RailBookingTrackEventHelper from "../details/RailBookingTrackEventHelper";
import RailBookingTrackingHelper from '../details/RailBookingTrackEventHelper';
import { backPressHandler } from '../../utils/PostSaleUtil';
import { getStaticData } from '../../staticData/staticData';
class PaymentPriceBreakup extends React.Component {
    static navigationOptions = {
        header: null
    };

    render() {
        const staticData = getStaticData();
        const { paymentDetailsText } = staticData;
        return (
            <View style={styles.PriceBreakupWrapper}>
                <Header
                    title={paymentDetailsText}
                    headerShadow
                    headingTxt="font18"
                    handleClose={this.goBackToDetails}
                />
                <ScrollView style={{marginTop: 10}}>
                    <FareBreakUp
                        fareBreakUpDetail={this.props.bookingDetailsResponse.railFareBreakup}
                        paymentBreakup={this.props.bookingDetailsResponse.paymentBreakup}
                        fareBreakupExtraNodeList={this.props.bookingDetailsResponse.fareBreakupExtraNodeList}
                    />
                    {
                        this.props.bookingDetailsResponse.paymentBreakup &&
                        <PayModeBreakup
                            paymentBreakupList={this.props.bookingDetailsResponse.paymentBreakup.paymentBreakupMap}
                            paymentBreakup={this.props.bookingDetailsResponse.paymentBreakup}
                        />
                    }
                </ScrollView>
            </View>
        );
    }

    goBackToDetails = () => {
        RailBookingTrackingHelper.trackClickEvents('View Price Detail', 'ViewPrice_BreakUpDetail');
        backPressHandler();
    }

    componentDidMount() {
        RailBookingTrackEventHelper.trackLoadEvent('Details_paymentdetail', this.props.bookingDetailsResponse);
    }
}

const styles = StyleSheet.create({
    PriceBreakupWrapper: {
        flex: 1,
        justifyContent: 'space-between',
        backgroundColor: '#fff'
    },
    btnWrapper: {
        paddingLeft: 15,
        paddingRight: 15,
        marginBottom: 15,
        alignItems: 'center'
    }
});

export default PaymentPriceBreakup;

PaymentPriceBreakup.propTypes = {
    bookingDetailsResponse: PropTypes.object.isRequired,
    btnClickListener: PropTypes.func.isRequired
};
