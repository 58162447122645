import React from 'react';
import { View, Image } from 'react-native';
import { STAR_FILLED, STAR_EMPTY } from '../../commonImageConstants';
import createStyles from './RatingCss';
import { StarRatingProps } from './hotelType';

const maxRating = [1, 2, 3, 4, 5];

const StarRating: React.FC<StarRatingProps> = ({ defaultRating }) => {
  const styles = createStyles();
  return (
    <View style={styles.customRatingStyle}>
      {maxRating.map((item) => {
        return (
          <View activeOpacity={0.9} key={item}>
            <Image
              style={item <= defaultRating ? styles.starImageStyle : styles.defaultStarImageStyle}
              source={item <= defaultRating ? STAR_FILLED : STAR_EMPTY}
            />
          </View>
        );
      })}
    </View>
  );
};

export default StarRating;
