import AsyncStorage from '@react-native-async-storage/async-storage';
import {removeDataFromStorage} from '@mmt/legacy-commons/AppState/LocalStorage'
import { isUserLoggedIn } from '@mmt/legacy-commons/Native/UserSession/UserSessionModule';
import isUndefined from 'lodash/isUndefined';
import { getFcPrefCache } from 'apps/rails/src/RailsAbConfig'
import railsConfig, { AUTHORIZATION_KEY } from '../RailsConfig';
import { showShortToast } from '@mmt/legacy-commons/Common/Components/Toast';
import fetch2 from '../fetch2';
import { setCookie, getCookieValue } from './CommonUtils';
import { _label } from "../vernacular/AppLanguage";
import { getNewUniquePnrsHistory, getUniquePnrsFromStorage, saveUniquePnrsHistory } from './RisUtils';

export const API_TIMEOUT = 60; //60 Seconds TODO: Get value from config store

export const ORG = 'MakeMyTrip';
export const LOB = 'Rails';
export const LISTING_PAGE_CONST = 'LISTING';
export const RAILS_LISTING = 'rails_listing';
export const LANDING_PAGE_CONST = 'landing';
export const REVIEW_PAGE_CONST = 'REVIEW';
export const DATE_CHANGE_ERR_PAGE_CONST = 'DATE_CHANGE_ERR_PAGE';
export const MAX_DAYS = 122;
export const AUTO_COMPLETE_SEACH_LIMIT = 10;
export const CHILD_MIN_AGE = 5;
export const CHILD_MAX_AGE = 11;
export const SENIOR_CITIZEN_MALE_MIN_AGE = 60;
export const SENIOR_CITIZEN_TRANSGENDER_MIN_AGE = 60;
export const SENIOR_CITIZEN_FEMALE_AGE = 58;
export const SENIOR_CITIZEN_MAX_AGE = 125;
export const MIN_TRAVELLER_NAME_LENGTH = 3;
export const MAX_TRAVELLER_NAME_LENGTH = 16;
export const MAX_INFANT_ALLOWED = 2;
export const TRAVELLER_DETAILS = 'Traveller Details';
export const NO_RESERVATION_CHOICE_CODE = '99';
export const AVAILABLE_STATUS = 'AVAILABLE';
export const TRAVELLERS_PAGE_CONTEXT = 'TRAVELLERS_PAGE_CONTEXT';
export const BOTTOMSHEET_CONST = 'bottomsheet';
export const FULL_PAGE_CONST = 'full_page';
export const TRAIN_LABEL = 'VIEW TRAINS';
export const STUCK_IN_TEXT = 'Stuck in';
export const WAITING_LIST_TEXT = ' Waiting List ';
export const DEFAULT_TG_MARKETING_COUPON = 'MMTTRAIN';
export const WHY_BOOK_WITH_US = 'WHY BOOK WITH US?';
export const THREE_TIMES_REFUND = 'What is 3x refund?';

export const EXPAND_CONST = 'expand';
export const COLLAPSE_CONST = 'collapse';

export const RAILS_LANDING_PAGE_OFFERS_CARDS = 'offer';
export const RAILS_LANDING_PAGE_RECENT_BOOKING_CARDS = 'recentBooking';
export const RAILS_LANDING_PAGE_RETURN_TICKET_CARDS = 'returnTickets'
export const RAILS_LANDING_PAGE_COVID_CARDS = 'covidNotice';
export const RAILS_LANDING_PAGE_ADS_CARDS = 'ads';
export const RAILS_LANDING_PAGE_RECENT_SEARCHES_CARDS = 'recentSearch';
export const RAILS_LANDING_PAGE_MMT_HIGHLIGHTS = 'mmtHighlights';
export const RAILS_LISTING_PAGE_MMT_HIGHLIGHTS = 'rails_listing_highlight_banners';
export const RAILS_LANDING_PAGE_ANNOUNCEMENTS_CARDS = 'announcements';
export const RAILS_LANDING_PAGE_EXCLUSIVE_PARTNERS_CARDS = 'exclusivePartners';
export const RAILS_LANDING_PAGE_TRAVEL_GUIDELINE_CARD = 'travelGuideline';
export const PAGE_NOT_LOADING = 'pageNotLoading';
export const MAINTAINENCE_ERROR = 'maintenanceError';
export const EXPLORE_OPTIONS = 'Exploring other options Please wait!';

export const RIS_LIVE_STATION_URL = 'https://www.makemytrip.com/rails/live-station-details/';
export const RIS_TRAIN_ROUTE_URL = 'https://www.makemytrip.com/rails/scheduleDetail';
export const CALENDAR_HOLIDAY_LIST_URL =
  'https://connect.makemytrip.com/mobile-core-api-web/api/holidayCalendar/v1/';
export const CALENDAR_HOLIDAY_LIST_API_KEY = 'and-059e174a-7874-4b0b-825f-ac28ac66625c';

export const NON_AC = 'Non-AC';
export const AC = 'AC';

export const DATE_MONTH_YEAR_FORMAT = 'DD-MM-YYYY';
export const DOUBLE_DIGIT_DAY_WITH_MONTH_FORMAT = 'DD MMM, dddd';
export const YEAR_MONTH_DATE_FORMAT = 'YYYYMMDD';
export const YEAR_MONTH_DATE_WITH_SEPERATOR = 'YYYY-MM-DD';

export const VARIANT_LANDING_USERFLOW_OLD_LISTING = 'landingUserflowOldListing';
export const VARIANT_LANDING_USERFLOW_NEW_LISTING = 'landingUserflowNewListing';
export const VARIANT_NEW_LISTING_TRAVELLER_USERFLOW = 'newListingTravellerUserflow';

export const IRCTC_PASSWORD = "IRCTC_PASSWORD_RECEIVED_ON_SMS";

export const SORTED_SECTION_LIST_WHEN_ORDER_API_FAILS = [
  RAILS_LANDING_PAGE_RECENT_BOOKING_CARDS,
  RAILS_LANDING_PAGE_OFFERS_CARDS,
  RAILS_LANDING_PAGE_ADS_CARDS,
  RAILS_LANDING_PAGE_MMT_HIGHLIGHTS,
  RAILS_LANDING_PAGE_RETURN_TICKET_CARDS,
  RAILS_LANDING_PAGE_RECENT_SEARCHES_CARDS,
  RAILS_LANDING_PAGE_ANNOUNCEMENTS_CARDS,
  RAILS_LANDING_PAGE_EXCLUSIVE_PARTNERS_CARDS,
  RAILS_LANDING_PAGE_TRAVEL_GUIDELINE_CARD
  // RAILS_LANDING_PAGE_COVID_CARDS,      // not in use currently uncomment if covid cards come in use again
];

export const ENTITY = {
  TBS_API: 'tbsWithAvailabilityAndRecommendation',
  SEARCH_API: 'searchWithAvailAndRecommendation',
};

export const COMMON_LATENCY = {
  TOTAL: 'total',
  SEARCH_API: 'searchWithAvailAndRecommendation',
  FIRST_RENDER: 'first_render',
  OVERALL_RENDER: 'overall_render',
};

let RAILS_TRAVELLERS_PAGE_PROPS;

export const setTravellersPageProps = (props) => {
  RAILS_TRAVELLERS_PAGE_PROPS = props;
};

export const voucherState = {
  ACTIVE: 'ACTIVE',
  EXPIRED: 'EXPIRED',
  NOT_ISSUED: 'NOT_ISSUED',
};

export const dummyArpResponse = {
  avlDayList: [
    {
      availablityStatus: 'Train outside booking window'
    }
  ]
}

export const TRAIN_OUTSIDE_RESERVATION_WINDOW = "This action not allowed as the Date given is Outside Advance Reservation Period- (80012)\n";

export const TBS_STATUSES_TO_HANDLE = ['train departed', 'train cancelled', 'train outside booking window'];

export const dynamicPricingTrainTypes = ['SV', 'R', 'D', 'S'];

export const RAIL_LISTING_DATE_FORMAT = 'DD MMMM YYYY dddd';

export const INPUT_CITY_SELECTED = {
  source: 'SOURCE',
  destination: 'DESTINATION',
  defaults: 'DEFAULTS',
};

export const ASYNC_STORAGE_PREFIX = 'RAILS_';
export const RAILS_CONFIG_DATA = `${ASYNC_STORAGE_PREFIX}CONFIG_DATA`;
export const RAILS_IRCTC_SHOW_PASSWORD_REMINDER_SCREEN =
  'RAILS_IRCTC_SHOW_PASSWORD_REMINDER_SCREEN';
export const RAILS_MMT_ID = `${ASYNC_STORAGE_PREFIX}MMT_ID`;
export const RAILS_BOOKING_ID = `${ASYNC_STORAGE_PREFIX}BOOKING_ID`;
export const RAILS_BOOKING_TIMER_START_TIME = `${ASYNC_STORAGE_PREFIX}BOOKING_TIMER_START_TIME`;
export const RAILS_BUS_COMMON_LANDING = `${ASYNC_STORAGE_PREFIX}RAILS_BUS_COMMON_LANDING`;
export const MWEB_HOST = 'MWEB_HOST';
export const REVIEW_TIMER = 'REVIEW_TIMER';
export const RAILS_CURR_AVL = 'CURR_AVL';
export const ITENERARY_DATA = `${ASYNC_STORAGE_PREFIX}ITENERARY_DATA`;
export const RAILS_API_ENV = `${ASYNC_STORAGE_PREFIX}API_ENV`;
export const RAILS_API_ENV_SELECTION = `${ASYNC_STORAGE_PREFIX}API_ENV_SELECTION`;
export const ITENERARY_ID = 'itenerary_id';
export const IRCTC_ACCOUNT_REGISTER_COUNT ='irctc_account_register_count';
export const CREATE_ACCOUNT_CLICK = 'create_account_click';
export const TG_LOW_CP_BUCKET_KEY = 'lowCpBucket';

const setInCookie = [RAILS_MMT_ID, MWEB_HOST];
export const RAILS_SESSION_ID = `${ASYNC_STORAGE_PREFIX}SESSION_ID`;
export const RAILS_IS_PRE_FC_SELECTED = `${ASYNC_STORAGE_PREFIX}_IS_PRE_FC_SELECTED`;
export const RAILS_GRAFANA_DATA = `${ASYNC_STORAGE_PREFIX}GRAFANA_DATA`;
export const RAILS_SESSION_ID_EXPIRY_IN_MINUTES = 40;
export const BUILD_FLAVOR = {
  CHARLES: 'standard_charles',
};

export const setDataToAsyncStorage = async (key, value) => {
  try {
    if (value === null || value === '' || value === undefined) return;
    if (Platform.OS === "web" && setInCookie.includes(key)) {
      setCookie(key, value);
    }
    await AsyncStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.log(`Set Data to local storage error : ${error}`);
  }
};

export const getDataFromAsynStorage = async (key) => {
  try {
    const data = await AsyncStorage.getItem(key);
    if (data) {
      return JSON.parse(data);
    }
    if (Platform.OS === 'web' && setInCookie.includes(key)) {
      return getCookieValue(key);
    }
    return null;
  } catch (e) {
    console.log(`Get Data from local storage error:${e}`);
  }
};

export const removeDataFromAsyncStorage = async (key) => {
  try {
    await AsyncStorage.removeItem(key);
  } catch (error) {
    console.error("Failed to remove item", key, "error.message");
  }
}

export const _isUserProfileServieAvailable = () => {
  const now = new Date();
  const start = new Date();
  start.setHours(9, 30, 0, 0);

  const end = new Date();
  end.setHours(11, 30, 0, 0);
  if (now >= start && now <= end) {
    showShortToast(_label("service_unavailable"));
    return false;
  }
  return true;
};


export const handleError = (message, exception) => {
  const errorMessage = `${_label("sorry_something_wrong")} ${message}`;
  if (!isUndefined(exception)) {
    console.log(exception);
  }
  showShortToast(errorMessage);
};

export const appendRupeeSymbol = (amount) => {
  try {
    return `₹ ${amount}`;
  } catch (e) {
    console.log('err', e);
  }
};

export const getRequestHeader = (mmtAuth) => {
  const userIdentifierJson = `{"type": "mmt-auth" ,"deviceId" : "deviceId", "os":"os", "osVersion":"osVersion", "appVersion":"appVersion", "imie":"imie", "ipAddress":"ipAddress", "timeZone":"timeZone", "value":"${mmtAuth}"}`;
  const requestHeader = {
    'Content-Type': 'application/json',
    Authorization: AUTHORIZATION_KEY,
    'User-Identifier': userIdentifierJson,
  };
  return requestHeader;
};

export const clearLocalStorageForCurrentTransaction = () => {
  const keys = [RAILS_BOOKING_ID, RAILS_MMT_ID, RAILS_BOOKING_TIMER_START_TIME];
  AsyncStorage.multiRemove(keys, (error) => {
    console.log('Error occured while clearing local storage for current transaction', error);
  });
};

const TATKAL_START_TIME = 10;
const TATKAL_END_TIME = 12;

export const isIRCTCTatkalTime = () => {
  const now = new Date();

  const start = new Date();
  start.setHours(TATKAL_START_TIME, 0, 0, 0);

  const end = new Date();
  end.setHours(TATKAL_END_TIME, 0, 0, 0);

  if (now >= start && now <= end) {
    return true;
  }
  return false;
};


export const removeFCKey = async () => {
  const prefCacheVal = await getFcPrefCache();
  if (!prefCacheVal) {
    removeDataFromStorage(RAILS_IS_PRE_FC_SELECTED)
  }
}

export const getEmailMobileNumberFromConfirmBookingApi = async (mmtId) => {
  try {
    const res = await fetch2(railsConfig.trainBookingStatus, {
      method: 'POST',
      body: JSON.stringify({
        bookingId: mmtId,
      }),
    });
    const response = await res.json();
    return {
      ...response,
      error: false,
    };
  } catch (e) {
    return {
      error: true,
    };
  }
};

export const getStatusWithBookingId = async (bookingId) => {
  try {
    const res = await fetch2(railsConfig.trainBookingStatus, {
      method: 'POST',
      body: JSON.stringify({
        bookingId,
      }),
    });
    const response = await res.json();
    return response;
  } catch (e) {
    console.log('Some error occured while fetching booking status', e);
  }
};

export const getDefaultHeadersWithMMTAuth = (mmtAuth) => ({
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'mmt-auth': mmtAuth,
});

export const npsRating = {
  header: 'Rate your Booking Experience?',
  subheader: 'Tell us how was your booking experience!',
  subHeading: {
    oneStar: "Oh! Too bad! Why don't you give us some feedback?",
    twoStar: "Oh! Too bad! Why don't you give us some feedback?",
    threeStar: "Umm! Looks like we are doing just okay! Why don't you give us your feedback?",
    fourStar: 'Yay! We are always trying to do our best!',
    fiveStar: 'Awesome! We will keep improving!',
  },
  successMsg: 'Thanks for your rating!',
  actionOn4: 'TY',
  abVarient: 'IARP',
  canEdit: true,
  lowRatingCta: [
    {
      type: 'LEFT',
      title: 'SKIP',
    },
    {
      type: 'RIGHT',
      title: 'FEEDBACK',
    },
  ],
  fourRatingCTA: [
    {
      type: 'LEFT',
      title: 'SKIP',
    },
    {
      type: 'RIGHT',
      title: 'SUBMIT',
    },
  ],
  fiveRatingCTA: [
    {
      type: 'LEFT',
      title: 'SKIP',
    },
    {
      type: 'RIGHT',
      title: 'SUBMIT',
    },
  ],
};


export const getNpsLabels = () => {
  return {
    header: _label("rate_your_booking_experience"),
    subheader: _label("tell_us_booking_experience"),
    subHeading: {
      oneStar: _label("oneStarText"),
      twoStar: _label("oneStarText"),
      threeStar: _label("threeStarText"),
      fourStar: _label("fourStarText"),
      fiveStar: _label("fiveStarText"),
    },
    successMsg: _label("thanksForFeedback"),
    actionOn4: 'TY',
    abVarient: 'IARP',
    canEdit: true,
    lowRatingCta: [
      {
        type: 'LEFT',
        title: 'SKIP',
      },
      {
        type: 'RIGHT',
        title: 'FEEDBACK',
      },
    ],
    fourRatingCTA: [
      {
        type: 'LEFT',
        title: 'SKIP',
      },
      {
        type: 'RIGHT',
        title: 'SUBMIT',
      },
    ],
    fiveRatingCTA: [
      {
        type: 'LEFT',
        title: 'SKIP',
      },
      {
        type: 'RIGHT',
        title: 'SUBMIT',
      },
    ],
  };
};

export const UNIQUE_PNR_PAGENAMES = {
  PNR_STATUS: 'PNR_STATUS',
  TGS_TRAVELLER: 'TGS_TRAVELLER',
  TGS_THANKYOU: 'TGS_THANKYOU',
  NEW_PNR_STATUS: 'PNR_STATUS_NEW',
  NEW_TGS_TRAVELLER: 'TGS_TRAVELLER_NEW',
  NEW_TGS_THANKYOU: 'TGS_THANKYOU_NEW',
  NEW_LANDING_PAGE:'PNR_LANDING_PAGE_NEW',
  LANDING_PAGE:'PNR_LANDING_PAGE',
  TGS_LANDING_PAGE:'TGS_LANDING_PAGE',
  TGS_THANK_YOU:'TGS_THANK_YOU'
}

export const pageNameRanking = (pageName) => {
  switch (pageName) {
    case UNIQUE_PNR_PAGENAMES.PNR_STATUS: return 1;
    case UNIQUE_PNR_PAGENAMES.TGS_TRAVELLER : return 2;
    case UNIQUE_PNR_PAGENAMES.TGS_THANKYOU : return 3;
  }
}

export const isUniquePnrVisit = async (pageName, pnrNumber, travelEndDate = '') => {

  if (!pnrNumber) {
    return false;
  }

  const uniquePnrsList = await getUniquePnrsFromStorage();
  const currentPnrFromList = uniquePnrsList?.find((uniquePnr) => {
    const pnrNumberFromList = uniquePnr.pnrNumber;
    if(pnrNumber === pnrNumberFromList) {
      return uniquePnr;
    }
  })
  const visitedNewPage = pageNameRanking(pageName) > pageNameRanking(currentPnrFromList?.pageName);

  if(!currentPnrFromList || visitedNewPage) {
      const currentPnr = {
        pnrNumber: pnrNumber,
        pageName: pageName,
        travelEndDate: currentPnrFromList?.travelEndDate || travelEndDate,
      };
      const recentUniquePnrsHistory = getNewUniquePnrsHistory(currentPnr, uniquePnrsList || null);
      if (recentUniquePnrsHistory) {
        await saveUniquePnrsHistory(recentUniquePnrsHistory);
      }
    return true;
  }
  return false;
}


// HYDRA API CONSTANTS START
export const ACCEPT_ENCODING_HEADER_NAME  = 'Accept-Encoding';
export const GZIP_ENCODING = 'gzip';
export const CONTENT_TYPE_HEADER_NAME = 'Content-Type';
export const CONTENT_TYPE = 'application/json';
export const API_KEY_HEADER_NAME = 'apikey';
export const API_KEY =  'b88e435f135bd4c42584d4887189d638b1ab9eaa';
export const HOST_HEADER_NAME = 'Host' ;
export const HYDRA_HOST_URL = 'dpt-fsbrokerapi.ecs.mmt';
export const HYDRA_SERVICE_NAME = "service";
export const HYDRA_SERVICE_VALUE =  "hydra";
export const HYDRA_SEGMENTS_NAME = "segments";
export const HYDRA_SEGMENT_RAILS = "r1145"
export const USER_ID_HEADER_NAME = 'user_id';
export const PROFILE_TYPE_HEADER_NAME = 'profile_type';
export const MMT_AUTH_HEADER_NAME = 'mmt-auth';
// HYDRA API CONSTANTS END

//Speed constant
export const SPEED_STATUS = {
  ON_TIME:'ON_TIME',
  DELAY: 'DELAY',
  PLANNED_HALT: 'PLANNED_HALT',
  UNPLANNED_HALT: 'UNPLANNED_HALT',
  NA: 'NA',
};

export const pageNameArray = ['addTraveler', 'railsForgotUsername', 'createIRCTCAccount','trainsBookingPolicy'];

export const TRAVELERS_PAGE_TRACKING_KEY_NEW = 'mob_rail_travellers';
export const REVIEW_PAGE_TRACKING_KEY = 'mob_rail_review';
export const LISTING_PAGE_TRACKING_KEY = 'mob_rail_listing_v2';

export const LISTING_TRAIN_SCHEDULE = 'mob_rail_listing_train_schedule';
export const LISTING_TRAIN_SCHEDULE_DISMISS = 'mob_rail_listing_train_schedule_dismiss';
export const TRAVELER_BACK_RESISTANCE = 'rail_travelers_back_resistance';
export const REVIEW_BACK_RESISTANCE = 'rail_review_back_resistance';
export const TRAVELER_FC_PROMT = 'mob_rail_traveller_prompt_fc';
export const TRAVELER_FREE_CANCELLATION = 'mob_rail_traveller_FC';
export const TRAVELERS_FC_WIDGET_NEW = 'rails_fc_widget_new';

export const TRAVELER_DETAILS_FC_BOTTOMSHEET_CONTENT = {
  fcList: [
    'Enjoy ZERO penalty',
    'Get FULL Ticket Fare Refund',
    'Automatic waiver on your cancellation',
    'Instant refund to your original pay mode',
  ],
  fcString: 'Cancel your Train bookings for FREE with Free Cancellation',
};

export const TRAVELER_DETAILS_FC_BOTTOMSHEET_CONTENT_WHEN_BNPP = {
  fcList: [
    '<p>Enjoy ZERO penalty</p>',
    '<p>Instant refund to your original pay mode</p>',
    //eslint-disable-next-line max-len
    '<p>With Free Cancellation, you can pay the remaining amount by <s>{dueDate}</s>, <pb>{fcDueDate}</pb>, <pb>{fcDueHour} hours</pb> before journey.</p>',
  ],
  fcString: 'Cancel your Train bookings for FREE with Free Cancellation',
  headerString: 'Get Full Ticket Fare Refund',
};

export const EMAIL_CONST = 'email';
export const MOBILE_CONST = 'mobile'
export const PRICE_REPLACER = '{price}';
export const REFUND_REPLACER = '{refund}';
export const FINAL_FARE_REPLACER = '{finalFare}';
export const DUE_AMOUNT_REPLACER = '{dueAmount}';
export const DUE_DATE_REPLACER = '{dueDate}';
export const FC_DUE_DATE = '{fcDueDate}';
export const FC_DUE_HOUR = '{fcDueHour}';
export const PAY_CUT_OFF_REPLACER = '{paymentCutOffTimeInHours}';

export const IRCTC_CREATE_ACCOUNT_EVENT = 'irctc_create_account';
export const IRCTC_CAPTCHA_RETRY_EVENT = `rails_${IRCTC_CREATE_ACCOUNT_EVENT}_captcha_retry`;
export const IRCTC_CAPTCHA_FAILED_EVENT = `rails_${IRCTC_CREATE_ACCOUNT_EVENT}_captcha_failed`;
export const IRCTC_ENTER_VALID_DETAILS_RED_FLAG = `rails_${IRCTC_CREATE_ACCOUNT_EVENT}_enter_valid_details_red_flag`;
export const IRCTC_ENTER_VALID_DETAILS_EVENT = `rails_${IRCTC_CREATE_ACCOUNT_EVENT}_enter_valid_details`;
export const IRCTC_SOMETHING_WENT_WRONG_EVENT = `rails_${IRCTC_CREATE_ACCOUNT_EVENT}_something_went_wrong`;
export const RIS_ON_CLICK_GPT_BANNER = 'ris:onClick_GPT_Banner'
export const RIS_ON_CLICK_GPT_LOTTIE = 'ris:onClick_GPT_Lottie'

export const NEARBY_DATES_EVENTS = {
  availabilitySwitch: 'Rails:FAA_availability_switch',
  confirmedOptionsClick: 'rail_nearby_confirmed',
  sameDateClick: 'rail_nearby_same',
  otherDateClick: 'rail_nearby_other',
  sourceTrackingPrefix: 'nb_',
  retryClick: 'rails_error_faa_retry',
  retrySuccess: 'Rails:FAA_retry_success',
};

export const LISTING_CONNECTED_TRAVEL_EVENTS = {
  expandClick: 'rails_connected_travel_expand',
  hideClick: 'rails_connected_travel_hide',
  cardClick: 'rails_connected_travel_card_clicked',
  trainClick: 'rails_connected_travel_train_clicked',
  busClick: 'rails_connected_travel_bus_clicked',
  bottomSheetDismissClick: 'rails_connected_travel_bottomsheet_dismiss',
  connectedTrue:'connected_true',
  connectedNotShown: 'ct_notshown',
  connectedShown: 'ct_shown',
  onlyBusClick: 'rails_connected_travel_onlybus_clicked',
  waitistedTG: 'wl_green',
  waitlisted: 'wl_yellow',
}

export const availabilityBucketOmnitureMapping = {
  low: 'rails_la',
  medium: 'rails_ma',
  high: 'rails_ha',
  very_high: 'rails_va',
};

//Venrac not required, so defined here
export const ENV_SWITCH = {
  TITLE: 'Rails API Config',
  ENTER_IP: 'Enter local IP address',
  ENTER_VALID_IP: 'Enter valid IP',
  SELECT_PWA: 'Select PWA Endpoint',
  SELECT_NODE: 'Select Node Endpoint',
  APPLY: 'Apply and Exit',
  PLACEHOLDER: 'xx.xx.xx.xx:yyyy',
  WIFI_NOTE: 'NOTE : Please make sure your device is connected to office network',
};

export const RAILS_DOWNTIME_TRIGGERED =  'rails_dowtime_triggered';
export const LISTING_DOWNTIME_ERROR_CODE = 'RAIL_TBS_AVAIL_10_CODE';
export const REVIEW_DOWNTIME_ERROR_CODE = 'RAILS_BOOKING_REVIEW_ERROR_10_CODE';
export const RAILS_DOWNTIME_CACHE = 'rails_downtime_cache';
export const RESPONSE_FROM_CACHE = 'CACHE';
export const ZERO_MINS = ' 00 min 00 second';

export const irctcAccountUserNameAPI = async () => {
  try {
    const userLoggedIn = await isUserLoggedIn();
    if (!userLoggedIn) {
      return null;
    }
    const res = await fetch2(railsConfig.getIrctcUserName);
    const response = await res.json();
    return response?.primary_irctc_id;
  } catch (e) {
    console.log('error fetching irctc username', e);
  }
};

export const updateAccountUserNameAPI = async (userName) => {
  try {
    const userLoggedIn = await isUserLoggedIn();
    if (!userLoggedIn) {
      return null;
    }
    const body = {
      irctc_ids: [],
      primary_irctc_id: userName,
      sync_data: true,
    };
    fetch2(railsConfig.updateIrctcUserID, {
      method: 'POST',
      body: JSON.stringify(body),
    });
  } catch (e) {
    console.log('error updating irctc username', e);
  }
};
