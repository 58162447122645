import React from 'react';
import Actions from '../../../../../navigation/postSalesNavigation';
import PropTypes from 'prop-types';
import { ScrollView, View, Platform } from 'react-native';
import Header from "../../components/Header"
import styles from './styles';
import SpecificFlightContent from './components/TabContent/SpecificFlightContent';
import DateChangeBaseClass from '../../components/DateChangeBaseClass';
import DateChangeTrackingHelper from '../../analytics/DateChangeTrackingHelper';
import CancellationTrackingHelper from '../../../../cancellation/Helpers/CancellationTrackingHelper';
import { logMessage } from '@mmt/legacy-commons/Helpers/actionsLogger';
import { getStaticData } from '../../../../../staticData/staticData';
import BaseButton, { BUTTON_VARIANT } from '../../../../../Common/BaseButton';
import { FLIGHT_THROTTLE_OBJECT } from '../../../details/FlightBookingDetailsConstant';

export default class DateChangeRescheduleCharges extends DateChangeBaseClass {
  constructor(props) {
    super(props);
    this.getResponseFromProps();
  }

  getResponseFromProps = () => {
    let response;
    try {
      response = JSON.parse(this.props.flightDateChangeData);
    } catch (e) {
      logMessage(e);
    }
    response = response || this.props.flightDateChangeData;
    this.state = {
      response,
    };
  };

  componentDidMount() {
    if (this.props.isCancelChargesPage) {
      CancellationTrackingHelper.trackLoadEventWithFullPartialFlag(
        'cancellationpolicy',
        this.state.response,
        false,
      );
    } else {
      DateChangeTrackingHelper.trackLoadEvent('datechangepolicy', this.state.response);
    }
  }

  render() {
    const { cancellationChargesText, reschedulingChargesText } = getStaticData();
    return (
      <View style={styles.pageWrapper}>
        <Header
          title={this.props.isCancelChargesPage ? cancellationChargesText : reschedulingChargesText}
          onBackPress={   this.props.dcBtnEnabled || this.props.isCancelChargesPage
            ? this.goBack
            : () => Actions.pop()}
        />
        {this._renderView()}
      </View>
    );
  }

  _renderView = () => {
    const { proceedToRescheduleText } = getStaticData();
    return (
      <View style={styles.tabWrapper}>
        <ScrollView ref="tabContent" showsVerticalScrollIndicator={false} style={styles.tabContent}>
          <SpecificFlightContent
            response={this.state.response}
            isCancelChargesPage={this.props.isCancelChargesPage}
          />
          <View style={styles.pageScrollBtmSpace} />
        </ScrollView>
        {this.props.dcBtnEnabled && (
          <View
            style={[
              styles.footerBtnWrapper,
              this.props.dcBtnEnabled ? styles.showView : styles.hideView,
            ]}
          >
             <BaseButton
              text={proceedToRescheduleText}
              clickHandler={() => this._openDateSelectionPage(this.state.response)}
              variant={BUTTON_VARIANT.CAPSULE}
              {...FLIGHT_THROTTLE_OBJECT}
            />
          </View>
        )}
      </View>
    );
  };

  _openDateSelectionPage = (response) => {
    DateChangeTrackingHelper.trackClickableEvent(
      'datechangepolicy',
      'proceedToReschedule',
      response,
      undefined
    );
    Actions.flightRescheduleFlightsPage({
      flightDateChangeData: JSON.stringify(response),
      bookingID: response.bookingID,
    });
  };
}
DateChangeRescheduleCharges.propTypes = {
  flightDateChangeData: PropTypes.object.isRequired,
  isCancelChargesPage: PropTypes.bool.isRequired,
  dcBtnEnabled: PropTypes.bool.isRequired,
};
