import { StyleSheet } from "react-native";

const getStyle = (color, fonts) => {
    return StyleSheet.create({
        trainNameStyle:{
          fontSize: fonts.fontSize.font20,
          color: color.black,
          ...fonts.blackFontFamily,
          marginRight: 5
        },
        boardingDetailStyle: {
          fontSize: fonts.fontSize.font16,
          color: color.black,
          ...fonts.boldFontFamily
        },
        trainInfoSection: {
          borderColor: color.greyBookedSeat,
          paddingVertical: 16,
          paddingHorizontal: 14,
        },
        trainNumber: {
          fontSize: 20,
          color: color.black,
          ...fonts.regularFontFamily,
        },
        destination: {
          fontSize: 14,
          color: color.textGrey,
          ...fonts.boldFontFamily,
        },
        textAlignRight: {
          textAlign: 'right',
        },
        dividerSection: {
          width: '100%',
          height: 1,
          backgroundColor: color.disabledButton,
          position: 'absolute',
          top: 10,
        },
        layover: {
          backgroundColor: color.white,
          paddingHorizontal: 2,
          color: color.grey33,
          textAlign: 'center',
        },
        layoverWrap: {
          flex: 1,
          position: 'relative',
          flexDirection: 'row',
          justifyContent: 'center',
        },
        platformWrap: {
          ...fonts.boldFontFamily,
          backgroundColor: color.lighterBlue,
          borderRadius: 4,
          lineHeight: 17,
          paddingHorizontal: 4,
          marginLeft: 5,
          color: color.darkBlue12,
        },
        platformRight: {
          marginLeft: 0,
          marginRight: 5,
        },
      });
}

export default getStyle;