import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';

const styles = {
  whiteCard: {
    ...getPlatformElevation(2),
    backgroundColor: '#fff',
    marginBottom: 2,
  },
  topInfoSection: {
    padding: 16,
  },
  paddingtop4: {
    padding: 4,
  },
  reasonWrapper: {
    paddingVertical: 6,
    paddingLeft: 16,
  },
  listRow: {
    marginBottom: 20,
  },
  iconStyle: {
    width: 32,
    height: 32,
    marginRight: 12,
  },
  dtlsSection: {
    flex: 1,
    borderBottomWidth: 1,
    borderBottomColor: '#e7e7e7',
    paddingBottom: 20,
    paddingRight: 16,
  },
  redirectIcon: {
    width: 9,
    height: 13,
    position: 'absolute',
    right: 24,
  },
  descInfo: {
    width: '90%',
    lineHeight: 17,
  },
  listRowlastItem: {
    marginBottom: 0,
  },
  dtlsSectionlastItem: {
    borderBottomWidth: 0,
    borderBottomColor: 'transparent',
  },
};
export default styles;
