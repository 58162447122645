import React, { memo } from 'react';
import { View, Text } from 'react-native';
import styles from './BreadcrumbCss';
import { getFont } from '../../../PostSalesStyles';

const Breadcrumb = ({ activeStep, data }) => {
  const { fsStyle } = getFont(true);
  return (
    <View style={styles.steps}>
      {data.map((step, index) => {
        const activeStyle = index <= activeStep ? 'Active' : '';
        return (
          <View
            key={index}
            style={[styles.stepItem, data.length <= 3 ? { maxWidth: 150 } : { maxWidth: 70 }]}
          >
            <View style={[styles.stepNumberWrapper, styles[`stepNumberWrapper${activeStyle}`]]}>
              <Text style={[styles.stepNumber, fsStyle.font10]}>{index + 1}</Text>
            </View>
            <Text
              numberOfLines={1}
              style={[styles.stepName, styles[`stepName${activeStyle}`], fsStyle.font10]}
            >
              {step}
            </Text>
          </View>
        );
      })}
    </View>
  );
};

export default memo(Breadcrumb);
