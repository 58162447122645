import { NativeModules } from 'react-native';
import {
    CONTENT_TYPE, RAILS_DOWNLOAD_INVOICE, RAILS_DOWNLOAD_TICKET, RAILS_DOWNLOAD_TRIGGERS
} from "../const";
import {getRequestHeader} from "./NetworkModule";
import {_getDownloadFileName, convertStringToBlob} from "../webUtils";
import { getCommonHeaders } from "@rn/apps/post-sales/src/utils/NetworkUtils";
import { getStaticData } from '@rn/apps/post-sales/src/staticData/staticData';
import ToastWeb from "../components/ToastWeb";

const downloadTickets = async (bookingId, triggerName) => {
    if(triggerName && bookingId) {
        const requestBody = {
            userId: 'PWA',
            triggerName,
            bookingId
        };
        
        await downloadRequest(requestBody, bookingId, triggerName);
    }
}

const getDownloadURL = (triggerName) => {
    switch (triggerName) {
    case RAILS_DOWNLOAD_TRIGGERS.E_TICKET:
        return RAILS_DOWNLOAD_TICKET;
    case RAILS_DOWNLOAD_TRIGGERS.INVOICE:
        return RAILS_DOWNLOAD_INVOICE;
    }
}

const downloadRequest = async(requestBody, bookingId, triggerName) => {
    const staticData = getStaticData();
    const { errorText } = staticData;
    try {
        const userInfo = await getRequestHeader(bookingId, triggerName);
        const headers = await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo);
        const voucherResponse = await fetch(getDownloadURL(triggerName), {
            method: 'POST',
            headers,
            body: JSON.stringify(requestBody)
        });

        if (voucherResponse.ok) {
            const responseBody = await voucherResponse.json();
            if (responseBody && responseBody.success && responseBody.pdfResponse) {
                let blob = convertStringToBlob(responseBody.pdfResponse.body, CONTENT_TYPE.APPLICATION_PDF);
                const download = require("downloadjs");
                download(blob, _getDownloadFileName(triggerName, bookingId), CONTENT_TYPE.APPLICATION_PDF);
            } else {
                ToastWeb.show(responseBody.responseText);
            }
        } else {
            ToastWeb.show(errorText ? errorText.soomethingWentWrongOnlyError : "");
        }
    } catch (error) {
        ToastWeb.show(errorText ? errorText.soomethingWentWrongOnlyError : "");
    }
}

NativeModules.RailBookingModule = {
    downloadTickets,
};
