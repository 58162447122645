import React, {Fragment} from 'react';
import {Text, View, TouchableOpacity} from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import styles from '../BottomOverlay/ChecklistOverlay/ChecklistOverlayCss'
import Actions from '../../../../navigation/postSalesNavigation';
import { ANDROID_BACK_ARROW } from "../../../SelfDriveImageConstant";

const UnderTaking = (props) => {
    return (
            props.underTaking && (
                <View style={[styles.overlayBody,{ flexDirection : 'row', flexWrap: 'wrap' }]}>
                    {
                        props.underTaking.map(underTaking => (
                            (underTaking.key === 'NON_URL_TEXT')
                                ? (
                                    <Fragment>
                                        <Text style={[AtomicCss.font16, AtomicCss.blackText, AtomicCss.regularFont]}>
                                            {underTaking.text}
                                            {' '}
                                        </Text>
                                    </Fragment>
                                )
                                : (
                                    <View>
                                        <TouchableOpacity onPress={() => {openUndertakingPolicy(underTaking.url,underTaking.text)}}>
                                            <Text style={[AtomicCss.font16, AtomicCss.linkText, AtomicCss.regularFont]}>
                                                {underTaking.text}
                                                {' '}
                                                {' '}
                                                {' '}
                                            </Text>
                                        </TouchableOpacity>
                                    </View>
                                )
                        ))
                    }
                </View>
            )
    )
}

const openUndertakingPolicy = (url, text) => {
    Actions.openPsWebView({
        headerText: text,
        url: url,
        headerIcon : ANDROID_BACK_ARROW
    });
}

export default UnderTaking;
