import React, { useState, useEffect } from 'react';
import { CANCELLATION_REFUNDMODE_PAGE_VIEW } from '../../../constant';
import { isNetworkAvailable } from '@mmt/legacy-commons/Common/utils/AppUtils';
import { retrieveObjFromAsyncStorage, getCancelRequestKeyName } from "../../../../../../Common/commonUtil";
import { fetchFlightCancellationDetails } from '../../../data/api';
import {CancellationPreviewRequest} from "../../../../../../PostSalesConstant";
import isEmpty from "lodash/isEmpty"

const useRefundModeView = (refundModeListData, { bookingId }) => {

    const [viewState, setViewState] = useState(CANCELLATION_REFUNDMODE_PAGE_VIEW.LOADED);
    const [cancellationRefundModePageData, setCancellationRefundModePageData] = useState(refundModeListData);

    const callCancellationAPI = async () => {
        const hasNetwork = await isNetworkAvailable();
        if (!hasNetwork) {
            setViewState(CANCELLATION_REFUNDMODE_PAGE_VIEW.NO_INTERNET);
            return;
        }
        const cancelRequestKey = getCancelRequestKeyName(CancellationPreviewRequest.FLIGHT,bookingId)
        const requestBody = await retrieveObjFromAsyncStorage(cancelRequestKey);
        const response = await fetchFlightCancellationDetails(bookingId,requestBody);

        if(response.cancellationResponse && response.cancellationResponse.cancellationRefundMode && response.cancellationResponse.cancellationRefundMode.refundModeList){
            setCancellationRefundModePageData(response.cancellationResponse.cancellationRefundMode.refundModeList)
        }else{
            setCancellationRefundModePageData(response.cancellationResponse ? response.cancellationResponse : null);
        }

        setViewState(response.error ? CANCELLATION_REFUNDMODE_PAGE_VIEW.ERROR : CANCELLATION_REFUNDMODE_PAGE_VIEW.LOADED);
    }

    useEffect(() => {
        if (isEmpty(refundModeListData)) {
            setViewState(CANCELLATION_REFUNDMODE_PAGE_VIEW.LOADING);
            callCancellationAPI();
        }
    }, []);

    const onRetry = () => {
        setViewState(CANCELLATION_REFUNDMODE_PAGE_VIEW.LOADING);
        callCancellationAPI();
    }

    return [viewState, cancellationRefundModePageData, onRetry];
}

export default useRefundModeView;