import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';

const styles = {
  CardContentFull: {
    backgroundColor: colors.white,
    paddingRight: 0,
    paddingBottom: 0,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
  },
  cardHeadingWrapper: {
    marginBottom: 2,
    paddingVertical: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#e4e4e4',
    backgroundColor: '#fff',
  },
  cardHeading: {
    color: '#000',
    textAlign: 'center',
  },
  fareBreakupInnerWrpper: {},
  fareBreakupInnerSection: {
    ...getPlatformElevation(1),
    marginBottom: 1,
    marginTop: -1,
    backgroundColor: '#fff',
  },

  rowSection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 15,
    paddingHorizontal: 16,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
  },
  totalPrice: {
    padding: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 2,
  },
  totalPricetxt: {
    color: '#4a4a4a',
  },
  fareBkHeadingTxt: {
    color: '#000',
  },
  successTxt: {
    color: '#1a7971',
    textAlign: 'right',
  },
  lftTxt: {
    fontSize: 12,
    color: '#747474',
    lineHeight: 14,
  },
  payTxt: {
    fontSize: 12,
    color: '#cf8100',
    lineHeight: 14,
  },
  cardIcon: {
    width: 20,
    height: 14,
    marginRight: 10,
    marginTop: 2,
  },
  walletIcon: {
    width: 19,
    height: 16,
    marginRight: 10,
    marginTop: 2,
  },
  giftIcon: {
    width: 20,
    height: 21,
    marginRight: 10,
    marginTop: 2,
  },
  pahInfoWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderRadius: 4,
    paddingVertical: 5,
    paddingHorizontal: 8,
    marginHorizontal: 15,
    marginBottom: 10,
    alignItems: 'center',
  },
  clockIconStyle: {
    width: 20,
    height: 20,
    marginRight: 8,
  },
};

export default styles;
