import { Platform } from 'react-native';

import FlightBookingModule from '@mmt/legacy-commons/Native/FlightBookingModule';
import { getRootTag } from '@mmt/legacy-commons/AppState/RootTagHolder';

import { flightDownloadTicket } from 'apps/post-sales/src/flights/FlightBookingUtil';
import FlightBookingDetailsConstant from '../../../details/FlightBookingDetailsConstant';


export const downloadETickets = (bookingId) => {
  if (Platform.OS === 'ios') {
    flightDownloadTicket(FlightBookingDetailsConstant.FLIGHT_ETICKET, bookingId);
  } else if (Platform.OS === 'web') {
    FlightBookingModule.downloadTicket(FlightBookingDetailsConstant.FLIGHT_ETICKET, bookingId);
  } else {
    FlightBookingModule.checkStoragePermission();
  }
};

export const downloadBoardingPass = (actionData = {}, bookingId) => {
  if (Platform.OS === 'ios') {
    const rootTag = getRootTag();
    FlightBookingModule.downloadInsurancePolicy(bookingId, actionData.url, rootTag);
  } else if (Platform.OS === 'web') {
    FlightBookingModule.downloadInsurancePolicy(actionData.url, bookingId, 'BOARDING_PASS');
  } else {
    FlightBookingModule.downloadInsurancePolicy(actionData.url);
  }
};
