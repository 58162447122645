import React, { useEffect, useState } from 'react';
import {BackHandler, View, ScrollView } from 'react-native';
import useApi from '../../../../../Common/useApi';
import { resetToHolidayBookingDetail } from '../../../../HolidayBookingDetailUtils';
import ContactCard from '../../components/ContactCard';
import TopStatusCard from './components/TopStatusCard';
import VisaRequestDetailsCard from './components/VisaRequestDetailsCard';
import { HOLIDAY_THANK_YOU_VISA_URL } from '../../../../../utils/NetworkUtils';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import NoInternetView from '@mmt/legacy-commons/Common/Components/Error/NoInternetView';
import ProgressView from '@mmt/legacy-commons/Common/Components/Progress/ProgressView';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import getThankYouVisaPageData from './data/responseAdapter';
import { useTheme } from '../../../../../theme/theme.context';
import { getStaticData } from '../../../../../staticData/staticData';
import createStyles from './style';
import HolidayBookingTrackingHelper from '../../../../HolidayBookingTrackingHelper';
import { PAYMENT_STATUS } from 'apps/post-sales/src/Holiday/HolidayBookingConstants';

const ThankYouPage = (props) => {
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme);
  const [viewState, setViewState] = useState(ViewState.LOADING);
  const { bookingId, payId } = props || {};
  const [thankYouVisaInProgress, thankYouVisaResponse, thankYouVisaApi] = useApi(
    HOLIDAY_THANK_YOU_VISA_URL + `?payId=${payId}`,
  );
  useEffect(() => {
    fetchJSONAsync();
  }, [thankYouVisaApi]);
  const fetchJSONAsync = () => {
    thankYouVisaApi.httpGet();
  };

  const onGoBack = ()=>{
    resetToHolidayBookingDetail(bookingId, true);
  };

  useEffect(() => {
     BackHandler.addEventListener('hardwareBackPress', onGoBack);
     return () =>
       BackHandler.removeEventListener('hardwareBackPress', onGoBack);
   }, []);

  useEffect(() => {
    if (thankYouVisaResponse) {
      if (thankYouVisaResponse.error) {
        HolidayBookingTrackingHelper.trackErrorEvent('AddVisaThankYouPage', bookingId, 'error');
        if (thankYouVisaResponse.error === ViewState.NO_INTERNET) {
          setViewState(ViewState.NO_INTERNET);
        } else {
          setViewState(ViewState.ERROR);
        }
      } else {
        HolidayBookingTrackingHelper.trackViewEvent(
          'AddVisaThankYouPage',
          'AddVisaThankYouPage',
          '',
        );
        setViewState(ViewState.SHOW_DETAIL);
      }
    }
  }, [thankYouVisaResponse]);

  const renderPage = () => {
    const {
      topCardData,
      visaRequestDetailsCardData,
      contactUsCardData,
    } = getThankYouVisaPageData(thankYouVisaResponse);

    return (
      <View style={styles.pageWrapper}>
        <ScrollView style={styles.cardsWrapper}>
          {thankYouVisaResponse.status === PAYMENT_STATUS.SUCCESS ? (
            <>
              <TopStatusCard
                {...topCardData}
                handleButtonClick={onGoBack}
                bookingStateValue={thankYouVisaResponse.status}
                goBack={onGoBack}
              />
              <VisaRequestDetailsCard {...visaRequestDetailsCardData} />
              <ContactCard {...contactUsCardData} />
            </>
          ) : (
            <>
              <TopStatusCard
                {...topCardData}
                handleButtonClick={onGoBack}
                bookingStateValue={thankYouVisaResponse.status}
                goBack={onGoBack}
              />
              <ContactCard {...contactUsCardData} />
            </>
          )}
        </ScrollView>
      </View>
    );
  };
  const renderErrorView = () => (
    <ErrorView
      showRetry
      onRetry={() => {
        fetchJSONAsync();
      }}
    />
  );

  const renderNoNetworkView = () => (
    <NoInternetView
      onRetry={() => {
        fetchJSONAsync();
      }}
    />
  );

  const renderProgressView = () => {
    const { loadingThankYouPageText } = getStaticData();
    return <ProgressView message={loadingThankYouPageText} />;
  };

  return (
    <View style={{ flex: 1 }}>
      {viewState === ViewState.NO_INTERNET && renderNoNetworkView()}
      {(thankYouVisaInProgress || viewState === ViewState.LOADING) && renderProgressView()}
      {viewState === ViewState.ERROR && renderErrorView()}
      {viewState === ViewState.SHOW_DETAIL && renderPage()}
    </View>
  );
};

export default ThankYouPage;
