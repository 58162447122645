import React, { useState, createContext, useContext } from 'react';
import {Platform} from 'react-native'
import { PROFILE_TYPE } from '@mmt/legacy-commons/Common/constants/AppConstants';
import { setUserProfileType } from '@mmt/legacy-commons/AppState/AppUserConfig';
import { getAffiliateName, isAffiliateUser } from '../utils/NetworkUtils';
import { DEFAULT_THEME } from './default.theme';
import { MY_BIZ_THEME } from './myBiz.theme'
import {INDIGO_THEME} from './indigo.theme'
import {GI_THEME} from './gi.theme'

export const AffiliateThemes = {
  '6eholidays': INDIGO_THEME,
  'giholidays': GI_THEME,
}

export const ThemeContext = createContext({
  psTheme: DEFAULT_THEME,
  setTheme:()=>{
    // This is added for Intelligent suggestions when using themeContext
    console.log('ThemeProvider is not rendered!');
  }
});

export const getTheme = (profileType) => {

 if(Platform.OS === 'web' && isAffiliateUser()){
  return AffiliateThemes[getAffiliateName()]
 }

  switch (profileType) {
    case PROFILE_TYPE.BUSINESS:
      return MY_BIZ_THEME;

    case PROFILE_TYPE.PERSONAL:
      return DEFAULT_THEME;
    default:
      return DEFAULT_THEME;
  }
};

export const ThemeProvider = React.memo((props) => {
  const [psTheme, setTheme] = useState(props.initial);

  const _setTheme = (profileType) => {
    if (Object.keys(PROFILE_TYPE).includes(profileType)) {
      setUserProfileType(profileType);
      const newTheme = getTheme(profileType);
      setTheme(newTheme);
    }
  };

  return <ThemeContext.Provider value={{ psTheme, setTheme:_setTheme }}>{props.children}</ThemeContext.Provider>;
});

export const useTheme = () => useContext(ThemeContext);
