import React from 'react';
import { Image, Platform, Text, View, BackHandler } from 'react-native';
import createStyles from './styles';
import DateChangeBaseClass from '../../components/DateChangeBaseClass';
import DateChangeTrackingHelper from '../../analytics/DateChangeTrackingHelper';
import { resetToFlightBookingDetail } from '../../../../FlightBookingUtil';
import { CALENDAR_GREEN_TICK_ICON2, ERROR_ICON } from '../../../../FlightImageConstants';
import { BUTTON_VARIANT } from '../../../../../Common/BaseButton';
import { getStaticData } from '../../../../../staticData/staticData';
import { getDCSubmitCommitMsgDescription, getDCSubmitCommitMsgTitle } from '../../utils';
import { ThemeContext } from '../../../../../theme/theme.context';
import { getFont } from '../../../../../PostSalesStyles';
import BaseButton from '../../../../../Common/BaseButton';
import Actions from '../../../../../navigation/postSalesNavigation';
import { FLIGHT_BOOKING_ROUTE_KEYS } from '../../../../flightBookingRouteKeys';
import FlightBookingModule from '@mmt/legacy-commons/Native/FlightBookingModule';

export default class DcReqSubmit extends DateChangeBaseClass {
  static contextType = ThemeContext;
  constructor(props) {
    super(props, 'dateChangeSucess', '');
    this._openUpdatedBooking = this._openUpdatedBooking.bind(this);
    this.response = {};
    this.response.bookingID = this.props.bookingId;
    this.managerView = this.props.managerView
  }

  componentDidMount() {
    super.componentDidMount();
    BackHandler.addEventListener('hardwareBackPress', this.onHardBackPress);
    this.props.newNavigation?.addListener('beforeRemove', this.preventSwipeBack);
    if (this.props.paymentSuccess) {
      DateChangeTrackingHelper.trackLoadEvent('datechangethankyousuccess', this.response);
    } else {
      DateChangeTrackingHelper.trackLoadEvent('datechangethankyoufail', this.response);
    }
  }

  componentWillUnmount(){
    BackHandler.removeEventListener('hardwareBackPress', this.onHardBackPress);
    this.props.newNavigation?.removeListener('beforeRemove');
  }
  render() {
    const {commitMsg, paymentSuccess, commitTitle} = this.props;
    const fonts = getFont(true);
    const styles = createStyles(this.context.psTheme, fonts);
    const title = getDCSubmitCommitMsgTitle(commitTitle, paymentSuccess);
    const description = getDCSubmitCommitMsgDescription(commitMsg, paymentSuccess);
    const { tryAgainText, seeUpdatedBookingText } = getStaticData();
    return (
      <View style={styles.pageWrapper}>
        <View>
          {this.props.paymentSuccess && (
            <View>
              <View style={styles.messageContent}>
                <Image style={styles.messageIcon} source={CALENDAR_GREEN_TICK_ICON2} />
                <Text style={styles.messageTitle}>{title}</Text>
                <Text style={styles.messageDesc}>{description}</Text>
              </View>
              <View style={styles.footerBtnWrapper}>
              <BaseButton
                text={this.props.sucessText ? this.props.sucessText : seeUpdatedBookingText}
                clickHandler={this._openUpdatedBooking}
                variant={BUTTON_VARIANT.CAPSULE}
              />
              </View>
            </View>
          )}

          {!this.props.paymentSuccess && (
            <View>
              <View style={styles.messageContent}>
                <Image style={styles.ErrorRoomImage} source={ERROR_ICON} />
                <Text style={styles.messageTitle}>{title}</Text>
                <Text style={styles.messageDesc}>{description}</Text>
              </View>
              <View style={styles.footerBtnWrapper}>
              <BaseButton
                text={this.props.failureText ? this.props.failureText : tryAgainText}
                clickHandler={this._openUpdatedBooking}
                variant={BUTTON_VARIANT.CAPSULE}
              />
              </View>
            </View>
          )}
        </View>
      </View>
    );
  }

  _openUpdatedBooking = () => {
    if(Platform.OS == 'ios' && this.managerView){
      Actions.reset(FLIGHT_BOOKING_ROUTE_KEYS.FLIGHT_BOOKING_DETAIL,{BOOKING_ID:this.props.bookingId})
    }else if(Platform.OS == 'android' && this.managerView){
      FlightBookingModule.launchFlightBookingDetail(this.props.bookingId)
    }
    else{
      resetToFlightBookingDetail(this.props.bookingId);
    }
  };

  preventSwipeBack = (e) => {
    e.preventDefault();
    this.props.newNavigation?.removeListener('beforeRemove');
    return this.onHardBackPress()
  }

  onHardBackPress = () => {
    this._openUpdatedBooking();
    return true;
  };
}
