import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Keyboard, Linking, ScrollView, Text, TouchableOpacity, View, Platform } from 'react-native';
import ProgressView from '@mmt/legacy-commons/Common/Components/Progress/ProgressView';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import NoInternetView from '@mmt/legacy-commons/Common/Components/Error/NoInternetView';
import BasePage from '../../../Common/PostSalesBasePage';
import getStyles from '../../details/components/HotelMamiCss';
import ErrorPage from '../../details/pages/ErrorPage';
import {
  createModificationReviewRequest, fillCheckInCheckOutData, isIntlHotelBooking, isNullOrEmpty, resetToHotelDetail
} from '../../details/utils/HotelBookingDetailUtil';
import {
  getCommonHeaders, HOTEL_ADD_GUEST_AVAIL_URL, HOTEL_ADD_GUEST_URL,
  HOTEL_CHANGE_DATE_AVAIL_URL, HOTEL_CHANGE_DATE_URL
} from '../../../utils/NetworkUtils';
import Actions from '../../../navigation/postSalesNavigation';
import { getButtonGradientColor } from '../../../utils/PostSaleUtil';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import HotelBookingTrackingHelper from '../../HotelBookingTrackingHelper';
import { ADD_GUEST_CONFIRM_OVERLAY_CONTINUE_CONFIRM, CHANGE_DATE_CONFIRM_OVERLAY_CONTINUE_CONFIRM } from '../HotelModificationConstants';
import RescheduleOverlay from './hotelRescheduleOverlay/rescheduleOverlay';
import { LANG_AWARE } from '../../HotelConstants';
import { getStaticData } from '../../../staticData/staticData';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import BottomBar from '@Frontend_Ui_Lib_App/BottomBar/lib/BottomBar';   
import ButtonWrapper from 'apps/post-sales/src/Common/ButtonWrapper';

let modificationURL;
let modificationAvailURL;
class MAMIBlackFooter extends BasePage {
  constructor(props) {
    super(props);
    this.fonts = getFont(true);
    this.styles = getStyles(this.fonts);
    this.state = {
      reviewResponseJson: null,
      viewState: ViewState.SHOW_DETAIL,
      reasonRescheduleOverlay: false,
      bottom: 0,
    };
    this.setActionURL();
    this.trackingPageName = '';
    this.workflowStatus = null;
    this.modificationReason = null;
    this.availResponse = null;
  }

    static navigationOptions = {
      header: null
    };

    componentDidMount() {
      this.keyboardDidShowListener = Keyboard.addListener(
        'keyboardDidShow',
        this._keyboardDidShow.bind(this)
      );
      this.keyboardDidHideListener = Keyboard.addListener(
        'keyboardDidHide',
        this._keyboardDidHide.bind(this)
      );
    }

    componentWillUnmount() {
      this.keyboardDidShowListener.remove();
      this.keyboardDidHideListener.remove();
    }
    _keyboardDidShow(e) {
      if (Platform.OS === 'ios') {
        this.setState({bottom: e.endCoordinates.height - 35});
      }
    }
    _keyboardDidHide(e) {
      this.setState({bottom: 0});
    }

    setActionURL() {
      if (this.props.action === 'DATECHANGE') {
        modificationURL = HOTEL_CHANGE_DATE_URL;
        modificationAvailURL = HOTEL_CHANGE_DATE_AVAIL_URL;
      } else {
        modificationURL = HOTEL_ADD_GUEST_URL;
        modificationAvailURL = HOTEL_ADD_GUEST_AVAIL_URL;
      }
    }

    onSendForApprovalClicked = (comment, reason) => {
      const reasonText = reason.toLowerCase() === 'others' ? comment : reason;
      this.modificationReason = reasonText;
      this.workflowStatus = 'PENDING';
      this.setState({
        reasonRescheduleOverlay: false
      });
      this.paymentPage()
    }

    onSkipApprovalClicked = () => {
      this.modificationReason = undefined;
      this.workflowStatus = 'SKIP';
      this.setState({
        reasonRescheduleOverlay: false
      });
      this.props.handleViewState && this.props.handleViewState(ViewState.LOADING);
      this.paymentPage()
    }

    closeOverlay = () => {
      this.setState({
        reasonRescheduleOverlay: false
      });
    }

    render() {
      return (
        <>
          {this.state.viewState === ViewState.LOADING && this.renderProgressView()}
          {this.state.viewState === ViewState.NO_INTERNET && this.renderNoNetworkView()}
          {this.state.viewState === ViewState.ERROR && this.renderErrorView()}
          {this.state.viewState === ViewState.SHOW_DETAIL && this.renderPage()}
        </>
      );
    }
    changeffromPAHtoPASH(searchHotelPriceresponse) {
      const oldmodeofPayment = searchHotelPriceresponse.availableRoomDetails[0].originalPaymentMode;
      const newmodeofPayment = searchHotelPriceresponse.availableRoomDetails[0].paymentMode;
      if (oldmodeofPayment === 'PAH' && newmodeofPayment === 'PAS') { return true; }
      return false;
    }

    paymentPage() {
      if (this.props.action === 'DATECHANGE') {
        this.trackingPageName = isIntlHotelBooking(this.props.hotelDetailResponse.lobCode)
          ? 'Mob:customer support:Hotel International:DateChange:ResultsPage_Available'
          : 'Mob:customer support:Hotel Domestic:DateChange:ResultsPage_Available';
        HotelBookingTrackingHelper.trackClickEvent(this.trackingPageName, CHANGE_DATE_CONFIRM_OVERLAY_CONTINUE_CONFIRM, this.props.hotelDetailResponse);
      } else if (this.props.action === 'ADDGUEST') {
        this.trackingPageName = isIntlHotelBooking(this.props.hotelDetailResponse.lobCode)
          ? 'Mob:customer support:Hotel International:AddGuest:ResultsPage_Available'
          : 'Mob:customer support:Hotel Domestic:AddGuest:ResultsPage_Available';
        HotelBookingTrackingHelper.trackClickEvent(this.trackingPageName, ADD_GUEST_CONFIRM_OVERLAY_CONTINUE_CONFIRM, this.props.hotelDetailResponse);
      }
      this.fetchJSONAsync(modificationAvailURL);
    }

    countinueHandler = () => {
      if(this.availResponse.corporateBooking) {
        const corpPolicy = this.availResponse.corpPolicyDetail;
        if (!corpPolicy.approvalRequired && !corpPolicy.blockOopBooking) {
          this.workflowStatus = 'NOT_REQUIRED';
          this.setState({
            reasonRescheduleOverlay: false
          });
          this.paymentPage();
        } else if(!corpPolicy.approvalRequired && corpPolicy.blockOopBooking) {
          this.props.cnacelHandler();
        } else {
          this.setState({
            reasonRescheduleOverlay: true
          });
        }
      } else {
        this.paymentPage();
      }
    }

    isPahBooking = (paymentData) => (paymentData.paymentMode === 'PAH_WITHOUT_CC' || paymentData.applicableRefund > 0)

    getContinueMessage(searchPriceResponse) {
      const corpbooking = searchPriceResponse.corporateBooking;
      const corpPolicy = searchPriceResponse.corpPolicyDetail;
      const { continueText, cancelText, confirmText } = getStaticData(LANG_AWARE.MODIFICATION);
      if (isNullOrEmpty(searchPriceResponse.availableRoomDetails) ||
        (corpbooking && !corpPolicy.approvalRequired && !corpPolicy.blockOopBooking)) {
        return continueText;
      }
      if (corpbooking && corpPolicy.approvalRequired) {
        return continueText;
      }
      if (corpbooking && !corpPolicy.approvalRequired && corpPolicy.blockOopBooking) {
        return cancelText;
      }

      if (!corpbooking && this.isPahBooking(searchPriceResponse.availableRoomDetails[0])) {
        return confirmText;
      }
      return continueText;
    }

    renderPage() {
      const searchHotelResponse = this.props.response;
      this.availResponse = searchHotelResponse;
      const corpPolicy = searchHotelResponse.corpPolicyDetail;
      const corporateBooking = searchHotelResponse.corporateBooking;
      const { actionText, totalText, totalTextValue } = !isEmpty(this.props.paymentBreakup) ? this.props.paymentBreakup : {};

      return (
        <View>          
          <BottomBar
            description1={!isEmpty(totalTextValue) && <Text style={[this.styles.WhiteText, this.styles.font18, this.styles.boldFont]}>{`${totalTextValue} `}</Text>}
            description2={!isEmpty(totalText) &&<Text style={[this.styles.WhiteText, this.styles.font12]}>{totalText.toUpperCase()}</Text>}
            rightComponent={
              <ButtonWrapper
                clickHandler={this.props.handleClick}
                btnText={ !isEmpty(actionText) && !corporateBooking ? actionText.toUpperCase() : this.getContinueMessage(searchHotelResponse)}
                isThrottleRequired={true}
                throttleInterval={1000}
                buttonWidth={180}
              />
            }
          />
          {this.state.reasonRescheduleOverlay && (
          <View style={this.styles.overlayWrapper}>
            <TouchableOpacity
              onPress={() => this.closeOverlay()}
              activeOpacity={1}
              style={this.styles.overlay}
            />
            <View
              style={[
                this.styles.overlayContent,
                {bottom: this.state.bottom}
              ]}
            >
              <ScrollView>
                <RescheduleOverlay
                  closeOverlay={this.closeOverlay}
                  onContinueClicked={this.onSendForApprovalClicked}
                  onSkipClicked={this.onSkipApprovalClicked}
                  showSkipApproval={
                    !corpPolicy.blockSkipApproval
                  }
                  radioItem={
                    searchHotelResponse.modificationReasons
                  }
                />
              </ScrollView>
            </View>
          </View>
        )}
        </View>);
    }

    renderProgressView = () => {
      const { confirmingText } = getStaticData(LANG_AWARE.MODIFICATION);
      return (
        <ProgressView message={confirmingText} />
      )
    };

    renderNoNetworkView = () => (
      <NoInternetView
        buttonStyle={getButtonGradientColor(this.props.hotelDetailResponse.isCorporateBooking)}
        onRetry={() => this.fetchJSONAsync(modificationURL, this.props.response)}
      />);

    renderErrorView = () => (
      <ErrorPage
        showRetry
        buttonGradient={getButtonGradientColor(this.props.hotelDetailResponse.isCorporateBooking)}
        onRetry={() => this.fetchJSONAsync(modificationURL, this.props.response)}
        showHelpingHand={this.props.showHelpingHand}
        helpingHandLabel={this.props.helpingHandLabel}
        helpingHandClickHandler={this.props.helpingHandClickHandler}
        isCorporateBooking={this.props.hotelDetailResponse.isCorporateBooking}
        connectUsViewStyle={this.styles.connectUsText}
        langAware={LANG_AWARE.MODIFICATION}
      />);

    checkRefundApplicable(refundAmount) {
      if (refundAmount > 0) { return true; }
      return false;
    }

    getMessageText(isRefundApplicable, isChangeModeofPayment, oldmodeofPayment) {
      if (isRefundApplicable) {
        return 'REFUND AMOUNT (To original payment mode)';
      } else if (isChangeModeofPayment) {
        return 'DIFFERENCE PAYABLE NOW';
      } else if (!isChangeModeofPayment && oldmodeofPayment === 'PAH') {
        return 'NEW PRICE - PAY AT HOTEL';
      } else if (!isRefundApplicable) {
        return 'DIFFERENCE PAYABLE NOW';
      }
      return 'AMOUNT PAYABLE NOW';
    }

    async showPaymentDetail(reviewPriceResponse, action, hotelDetailResponse) {
      const buttonGradientColor = getButtonGradientColor(this.props.hotelDetailResponse.isCorporateBooking);
      let { newCheckInDate, newCheckOutDate, oldCheckInDate, oldCheckOutDate } = this.availResponse || {};
      if (action !== 'DATECHANGE') {
        newCheckInDate = oldCheckInDate;
        newCheckOutDate = oldCheckOutDate;
      }
      if(!reviewPriceResponse.success) {
        const { unavailabilityInstructions } = reviewPriceResponse;

        if (unavailabilityInstructions) {
          const { handleDateChangeFailure } = this.props;
          handleDateChangeFailure && handleDateChangeFailure(unavailabilityInstructions, reviewPriceResponse);
        } else {
          this.props.handleViewState(ViewState.ERROR);
          this.props.setErrorPageData(reviewPriceResponse);
        }
      } else {
        switch(reviewPriceResponse.nextStep) {
          case 'THANKYOU':
            let newBookingID = reviewPriceResponse.bookingID;
            if (reviewPriceResponse.paymentDetail) {
              newBookingID = reviewPriceResponse.paymentDetail.bookingID;
            };
            await HotelBookingModule.invalidateTripDetails();
            this.props.handleViewState(ViewState.COMMIT_SUCCESS);
            break;
          case "PAYMENT":
            if (Platform.OS === "web") {
              if (reviewPriceResponse.paymentDetail && reviewPriceResponse.paymentDetail.paymentParams && reviewPriceResponse.paymentDetail.paymentParams.checkoutUrl) {
                location.href = reviewPriceResponse.paymentDetail.paymentParams.checkoutUrl;
                this.props.closeModificationOverlay();
              } else {
                Actions.hotelModificationFailure({ bookingid, action, hotelDetailResponse });
              }
            } else {
              HotelBookingModule.openHotelDateChangePaymentPage(JSON.stringify(reviewPriceResponse), action);
            }
            break;
          case 'APPROVAL':
            if (Platform.OS === 'web') {
              const url = reviewPriceResponse.redirectionUrl;
              Linking.openURL(url);
            } else {
              HotelBookingModule.openHotelOnlineDateChangePage(reviewPriceResponse.txnData, true);
            }
            this.props.handleViewState(ViewState.DEFAULT);
            break;
          default:
            this.props.handleViewState(ViewState.DEFAULT);
            return;
        }
      }
    }

    addB2BModificationRequestParams(request) {
      return {
        ...request,
        workflowStatus: this.workflowStatus,
        modificationReason: this.modificationReason
      };
    }

    fetchJSONAsync = async (url) => {
      this.props.handleViewState(ViewState.LOADING);
      try {
        let reviewrequest = {};
        const {
          appVersion, exprimentData, deviceId, visitorId, thankYouURL
        } = Platform.OS === "web" ? HotelBookingModule.getHotelDateChangeStaticData(this.availResponse.bookingID) : await HotelBookingModule.getHotelDateChangeStaticData();
        const { hashKey, bookingID, newCheckInDate,oldCheckInDate, oldCheckOutDate, newCheckOutDate, corporateBooking} = this.availResponse || {};
        const {mmtAuth, loggingTrackingInfo} = await HotelBookingModule.getRequestHeader(bookingID, 'Hotel_Modification_ViewRoom');
        if (this.props.action === 'DATECHANGE') {
          reviewrequest = createModificationReviewRequest(appVersion,
            exprimentData,
            deviceId,
            visitorId,
            fillCheckInCheckOutData(newCheckInDate, 'YYYY-MM-DD'),
            fillCheckInCheckOutData(newCheckOutDate, 'YYYY-MM-DD'),
            this.availResponse,
            thankYouURL,
            hashKey,
            corporateBooking,
            this.state.reviewResponseJson && this.state.reviewResponseJson.availValidateReferenceKey
          );
        } else {
          reviewrequest = createModificationReviewRequest(
            appVersion, exprimentData, deviceId, visitorId,
            fillCheckInCheckOutData(oldCheckInDate, 'YYYY-MM-DD'),
            fillCheckInCheckOutData(oldCheckOutDate, 'YYYY-MM-DD'),
            this.availResponse,
            thankYouURL,
            hashKey,
            corporateBooking,
            this.state.reviewResponseJson && this.state.reviewResponseJson.availValidateReferenceKey
            );
            reviewrequest.roomDetails = this.props.roomDetails;
          }
        reviewrequest = this.addB2BModificationRequestParams({...reviewrequest});

        const reviewResponse = await fetch(url, {
          headers: await getCommonHeaders(mmtAuth, loggingTrackingInfo),
          body: JSON.stringify(reviewrequest),
          method: 'POST'
        });
        if (reviewResponse.ok) {
          const body = await
          reviewResponse.json();
          this.setState({
            reviewResponseJson: body,
            viewState: ViewState.SHOW_DETAIL
          });
          this.showPaymentDetail(body, this.props.action, this.props.hotelDetailResponse);
        } else {
          this.props.handleViewState(ViewState.ERROR);
        }
      } catch (error) {
        this.props.handleViewState(ViewState.ERROR);
      }
    }
}

MAMIBlackFooter.propTypes =
    {
      response: PropTypes.object.isRequired,
      cnacelHandler: PropTypes.func.isRequired
    };

export default MAMIBlackFooter;
