import {colors} from '@mmt/legacy-commons/Styles/globalStyles';

const styles = {
  CardContentFull: {
    backgroundColor: colors.white,
    padding: 15,
    paddingBottom: 0,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc'
  },
  CardContent: {
    backgroundColor: colors.white,
    flexDirection: 'row',
    paddingBottom: 20
  },
  CardIcon: {
    width: 24,
    height: 24,
    marginRight: 10
  },
  mapIcon: {width: 60, height: 60}
};

export default styles;
