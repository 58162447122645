import { StyleSheet } from 'react-native';

export default (theme, fonts) => StyleSheet.create({
  pageWrapper: {
    flex: 1,
    backgroundColor: theme.color.grayBg,
  },
  pageScrollBtmSpace: {
    height: 70,
  },
  tabWrapper: {
    marginTop: -25,
    position: 'relative',
    zIndex: 2,
    elevation: 2,
    shadowColor: theme.color.black,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 2,
    flex: 1,
  },
  tabsHeader: {
    paddingHorizontal: 10,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 3,
  },
  tabs: {
    backgroundColor: theme.color.white,
    borderRadius: 4,
    elevation: 2,
    shadowColor: theme.color.black,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 2,
    marginVertical: 2,
    flexDirection: 'row',
  },
  tab: {
    flex: 1,
    alignItems: 'center',
    paddingTop: 9,
    overflow: 'hidden',
    paddingBottom: 5,
    borderBottomColor: theme.color.white,
    borderBottomWidth: 3,
    borderRightColor: theme.color.grayBg,
    borderRightWidth: 2,
    position: 'relative',
    zIndex: 2,
  },
  tabActive: {
    borderBottomColor: theme.color.primary,
  },
  tabFirst: {
    borderBottomLeftRadius: 4,
  },
  tabLast: {
    borderRightWidth: 0,
    borderBottomRightRadius: 4,
  },
  tabTitleSm: {
    fontFamily: fonts.semiBold,
    color: theme.color.black28,
    fontSize: fonts.fontSize.font10,
  },
  tabTitle: {
    fontFamily: fonts.bold,
    color: theme.color.black28,
    fontSize: fonts.fontSize.font16,
  },
  tabContent: {
    flex: 1,
    marginTop: 25,
  },
  tabContentBtmSpace: {
    height: 65,
  },
  tabContentTopSpace: {
    height: 35,
  },
  whiteCard: {
    backgroundColor: theme.color.white,
    padding: 15,
    marginVertical: 8,
    elevation: 2,
    shadowColor: theme.color.black,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 2,
  },
  cardTitle: {
    color: theme.color.gray,
    fontSize: fonts.fontSize.font16,
    fontFamily: fonts.light,
    marginBottom: 15,
  },
  greenMessage: {
    marginHorizontal: 5,
    backgroundColor: theme.color.lightCyan,
    paddingVertical: 10,
    paddingLeft: 35,
    paddingRight: 15,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    position: 'relative',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  greenTick: {
    width: 20,
    height: 20,
    position: 'absolute',
    left: 10,
    top: 10,
  },
  greenText: {
    fontSize: fonts.fontSize.font14,
    color: theme.color.cyan,
    fontFamily: fonts.medium,
    lineHeight: 18,
  },
  greenTextBold: {
    fontSize: fonts.fontSize.font14,
    color: theme.color.cyan,
    fontFamily: fonts.bold,
    lineHeight: 18,
  },
  italicText: {
    color: theme.color.gray,
    fontSize: fonts.fontSize.font12,
    fontFamily: fonts.regular,
    fontStyle: 'italic',
    marginVertical: 10,
  },
  infoNote: {
    position: 'relative',
    paddingLeft: 25,
  },
  infoNoteIcon: {
    position: 'absolute',
    height: 16,
    width: 16,
    left: 0,
    top: 5,
  },
  infoNoteText: {
    color: theme.color.red,
    fontSize: fonts.fontSize.font12,
    fontFamily: fonts.regular,
    fontStyle: 'italic',
    lineHeight: 18,
  },
  footerBtnWrapper: {
    padding: 12,
    position: 'absolute',
    zIndex: 2,
    bottom: 0,
    left: 0,
    width: '100%',
  },
  showView: {
    display: 'flex',
  },
  hideView: {
    display: 'none',
  },
});
