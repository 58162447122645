import { StyleSheet } from "react-native";
import { DEFAULT_THEME } from '../../../../../../theme/default.theme';

export default <T extends typeof DEFAULT_THEME> (theme: T) => StyleSheet.create({
    overlayWrapper: {
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: theme.color.grey20,
        height: '100%',
        width: '100%',
        zIndex: 1,
        elevation: 3,
    },
    overlayHeight: {
        height: 250,
    },
});