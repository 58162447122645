import {isNetworkAvailable} from '@mmt/legacy-commons/Common/utils/AppUtils';
import ViewState from '../../flights-funnel/Listing/utils/ViewState';
import {getCommonHeaders, RAIL_PNR_STATUS} from "../../utils/NetworkUtils";
import {buildPnrRequest, preparePnrData} from "../details/RailBookingDetailsUtil";

export const FETCH_ACTIONS_RAILS_PNR_STATUS = 'FETCH_ACTIONS_RAILS_PNR_STATUS';
export const ACTIONS_RAILS_PNR_LISTING_SET_NO_NETWORK = 'ACTIONS_RAILS_PNR_LISTING_SET_NO_NETWORK';
export const ACTIONS_RAILS_PNR_LISTING_SET_BOTTOM_LOADER = 'ACTIONS_RAILS_PNR_LISTING_SET_BOTTOM_LOADER';
export const ACTIONS_RAILS_PNR_LISTING_SET_ERROR = 'ACTIONS_RAILS_PNR_LISTING_SET_ERROR';
export const ACTIONS_RAILS_PNR_LISTING_EMPTY = 'ACTIONS_RAILS_PNR_LISTING_EMPTY';
export const ACTIONS_DESTORY_SESSION = 'ACTIONS_DESTORY_SESSION';
export const ACTIONS_UPDATE_LATEST_TRAIN_DELAY_INFO = 'ACTIONS_UPDATE_LATEST_TRAIN_DELAY_INFO';


export const fetchPnrStatusInfo = (pnr, bookingId, mmtAuth, loggingTrackInfo) => async (dispatch) => {
    try {
        const hasNetwork = await isNetworkAvailable();
        if (!hasNetwork) {
            dispatch({
                type: ACTIONS_RAILS_PNR_LISTING_SET_NO_NETWORK,
                data: {showPnrStatusLodar: false, pnrNumber: pnr}
            });
            return;
        }
        dispatch({
            type: ACTIONS_RAILS_PNR_LISTING_SET_BOTTOM_LOADER,
            data: {showPnrStatusLodar: true}
        });
        const pnrDetailInfo = await fetchPnrStatus(pnr, bookingId, mmtAuth, loggingTrackInfo);
        if (pnrDetailInfo) {
            dispatch(sendSuccess(pnrDetailInfo));
        } else {
            dispatch({
                type: ACTIONS_RAILS_PNR_LISTING_EMPTY,
                data: {showPnrStatusLodar: false}
            });
        }
    } catch (error) {
        dispatch({
            type: ACTIONS_RAILS_PNR_LISTING_SET_ERROR,
            data: {showPnrStatusLodar: false}
        });
    }
};

export const updateTrainDelayInfo = (latestTrainData) => async (dispatch) => {
    dispatch({
      type: ACTIONS_UPDATE_LATEST_TRAIN_DELAY_INFO,
      data: latestTrainData,
    });
  };

async function fetchPnrStatus(pnrNo, bookingId, mmtAuth, loggingTrackInfo) {
    try {
        const commonHeaders = await getCommonHeaders(mmtAuth, loggingTrackInfo);

        const response = await fetch(RAIL_PNR_STATUS, {
            method: 'POST',
            body: JSON.stringify(buildPnrRequest(pnrNo, bookingId)),
            headers: commonHeaders
        });
        if (response.ok) {
            const responseBody = await response.json();
            return await preparePnrData(responseBody);
        } else {
            return null;
        }
    } catch (error) {
        console.log('Error is', error);
    }
}

export const destroySession = () => (dispatch) => {
    dispatch(killSession());
  };

  function killSession() {
    return {
        type : ACTIONS_DESTORY_SESSION
    }
}

export const sendSuccess = (pnrDetailInfo) => (dispatch) => {
    const {
        passengerDetails, chartPrepared, chartStatus, lastUpdateTime, allowRefresh,lastbookingId
    } = {...pnrDetailInfo};
    const data = {};
    data.passengerDetails = passengerDetails;
    data.chartPrepared = chartPrepared;
    data.chartStatus = chartStatus;
    data.showPnrStatusLodar = false;
    data.pnrViewState = ViewState.SHOW_DETAIL;
    data.lastUpdateTime = lastUpdateTime;
    data.allowRefresh = allowRefresh;
    data.lastbookingId = lastbookingId
    dispatch({
        type: FETCH_ACTIONS_RAILS_PNR_STATUS,
        data: {...data}
    });
};
