import { StyleSheet } from 'react-native';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
const getStyles =(theme)=> StyleSheet.create({
  planArriveCard: {
    backgroundColor: theme.color.white,
    borderRadius: 16,
    margin: 12,
    padding: 16,
    ...getPlatformElevation(2),
  },
  hotelImgStyle: {
    width: 56,
    height: 56,
    marginRight: 14,
  },
  defaultText: {
    color: theme.color.red10
  },
  afterComText: {
    color: theme.color.lightSeaGreenTextColor,
  },
  captalize: {
    textTransform: 'capitalize',
  },
  rightArrowIcon: {
    width: 13,
    height: 13,
    tintColor: theme.color.red10,
    marginTop: 3,
    marginLeft: 5,
  },
  tickSuccess: {
    width: 28,
    height: 28,
    position: 'absolute',
    top: 0,
    right: 12,
    zIndex: 2,
  },
});
export default getStyles;
