export const getGoogleApiRequest = (fromLocation, toLocation, bookingId) => {
  const fromCoordinates = {
    latitude: fromLocation.latitude,
    longitude: fromLocation.longitude,
  };

  const toCoordinates = {
    latitude: toLocation.latitude,
    longitude: toLocation.longitude,
  };
  return {
    bookingId: bookingId,
    destinationCoordinates: toCoordinates,
    fromCoordinates: fromCoordinates,
  };
};
