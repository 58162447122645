import { StyleSheet } from 'react-native';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';

const styles = StyleSheet.create({
  refundDetailWrapper: {
    borderTopColor: '#eee',
    borderTopWidth: 1,
    paddingTop: 16,
    marginLeft: 60,
    marginBottom: 15,
  },
  refundDetail: {
    paddingHorizontal: 6,
    paddingVertical: 4,
    flexDirection: 'row',
    alignSelf: 'flex-start',
    borderRadius: 4,
    marginBottom: 4,
  },
  refundDetailAwaited: {
    backgroundColor: colors.grayBg,
  },
  refundStatusTxt: {
    lineHeight: 16,
    textTransform: 'uppercase',
  },
  refundStatusTxtAwaited: {
    color: colors.defaultTextColor,
  },
  requestCardLink: {
    backgroundColor: colors.paleBlue,
    borderTopColor: '#e0e7ef',
    borderTopWidth: 2,
    paddingVertical: 12,
    marginHorizontal: -20,
    paddingLeft: 75,
    flexDirection: 'row',
    alignItems: 'center',
  },
  refundCardTxt: {
    color: colors.black,
    marginBottom: 4,
    paddingRight: 15,
  },
  refundDescTxt: {
    color: colors.black,
    paddingRight: 25,
  },
});

export default styles;
