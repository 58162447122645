import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Animated, DeviceEventEmitter } from 'react-native';
import NameChangeBottomSheet from '../../../../NameChange/components/NameChangePopup';
import {
  cancellationInfoJson,
  dateChangeCardClkHandler,
  getLobName,
  getSelectedSegmentGroupView,
  nameChangePopupClickHandler,
  onCancellationButtonClicked,
  specialRequestCardClickHandler,
} from '../../../../details/utils/cardUtil';
import WheelChairAddon from '../WheelChair';
import BookingInvoiceSection from '../BookingInvoices';
import { getCardListMap } from '../../utils/commonUtils';
import { FLIGHT_CANCELLATION_TYPE, ViewState } from 'apps/post-sales/src/PostSalesConstant';
import PageLoader from 'apps/post-sales/src/Common/Cancellation/PageLoader';
import ErrorPage from 'apps/post-sales/src/flights/v2/commonComponents/ErrorPage';
import { actionHandler } from '../../utils/actionHandler';
import FetchFlightData from 'apps/post-sales/src/flights/v2/utils/fetchFlightData';
import RenderPage from '@core_app_common/RenderPage';
import { flightDownloadTicket } from 'apps/post-sales/src/flights/FlightBookingUtil';
import {FLIGHT_ACTION_IDS, PAGE_REQUESTED, PAGE_NAME} from 'apps/post-sales/src/flights/v2/commonConstants';
import SpinLoader from 'core-rn-ui-factory/src/components/SpinLoader';
import { TrackingOmnitureLoadData } from 'apps/post-sales/src/flights/v2/utils/trackingUtil';
import CommonOverlayMessage from '../../../../../../Common/CommonOverlayMessage';
import CancellationInfoOverlay from '../../../../details/components/CancellationInfoComp';
import SpecialClaimBottomSheetV2 from '../../../../../cancellation/SpecialClaimNew/SpecialClaimBottomSheetV2';
import { VOID_DGCA_CONSTANTS } from '../../../../details/FlightBookingDetailsConstant';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import getStyles from './renderFlightDetailPageCss';
import isEmpty from 'lodash/isEmpty';
import FlightBookingCancellation from '../../../../Cancellation/components/FlightBookingCancellation';
import {getPageData} from 'apps/post-sales/src/flights/v2/utils/commonUtils';
interface RenderFlightDetailPageProps {
  bookingId: string;
  onLoad: (data: TrackingOmnitureLoadData) => void;
  onClick: (actionId: string, actionData: object | string) => void;
  onError: (error: string) => void;
  loadOldFlow?: boolean;
  pageRequested: string;
  extraPropsData: object;
}

const RenderFlightDetailPage: React.FC<RenderFlightDetailPageProps> = ({
  bookingId,
  onLoad,
  onClick,
  onError,
  loadOldFlow = () => {},
  pageRequested,
  extraPropsData,
}) => {
  const { BACK } = FLIGHT_ACTION_IDS;
  const { psTheme: theme } = useTheme();
  const styles = getStyles(theme);
  const [holdBookingCancellationFlow, setHoldBookingCancellationFlow] = useState(false);
  const [errorData, setErrorData] = useState(null);
  const [viewState, setViewState] = useState();
  const [wheelChairFlow, setWheelChairFlow] = useState(false);
  const [wheelChairData, setWheelChairData] = useState();
  const bottomSheetAnimated = useRef(new Animated.Value(-500)).current;
  const [showCancellationInfoOverlay, setCancellationInfoOverlay] = useState({
    value: false,
    data: null,
  });
  const [showSpecialClaimBottomSheet, setSpecialClaimBottomSheet] = useState({
    value: false,
    data: null,
  });
  const [showNameChangeOverlay, setNameChangeOverlay] = useState(false);
  const [responseJson, setResponseJson] = useState({});
  const [ancillaryResponse, setAncillaryReponse] = useState({});
  const [bookingInvoices, setBookingInvoice] = useState(false);
  const [isApiLoading, setApiLoading] = useState(false);
  const triggerName = useRef(null);

  const checkPermission = (status: { success: boolean }) => {
    if (status.success && !!triggerName.current) {
      if (triggerName.current === FLIGHT_ACTION_IDS.DOWNLOAD_INVOICE_POPUP) {
        setBookingInvoice(true);
      } else {
        flightDownloadTicket(triggerName.current, bookingId);
      }
      triggerName.current = null;
    }
  };

  useEffect(() => {
    if (DeviceEventEmitter) {
      DeviceEventEmitter.addListener('flight_booking_details_storage_permission', checkPermission);
    }

    return () => {
      if (DeviceEventEmitter) {
        DeviceEventEmitter.removeAllListeners('flight_booking_details_storage_permission');
      }
    };
  }, []);

  const fetchFlightBookingDetails = async () => {
    const flightResponse = await FetchFlightData.getData(bookingId, onError);
    return flightResponse;
  };
  const handleClick = useCallback(
    (actionId: string, actionData = {}) => {
      actionHandler({
        responseJson,
        actionId,
        actionData,
        onLoad,
        onClick,
        onError,
        bookingId,
        specialRequestCardBtnClick,
        dateChangeCardActionClick,
        cancelCardActionItemClick,
        cancelHoldBooking,
        openSpecialClaimPopUp,
        setWheelChairFlow,
        setWheelChairData,
        checkPermissionRef: triggerName,
        setBookingInvoice,
        setWalletOverlay: () => {},
        setErrorData,
        setViewState,
        loadOldFlow,
        setResponseJson,
        fetchFlightBookingDetails,
        setApiLoading,
        setAncillaryReponse,
        handleClick,
      });
    },
    [bookingId],
  );

  const shouldShowNameChangeOverlay = () => {
    setNameChangeOverlay(true);
  };

  const specialRequestCardBtnClick = (actionId: string, actionData: object) => {
    specialRequestCardClickHandler(
      actionId,
      shouldShowNameChangeOverlay,
      actionData?.responseData,
      bookingId,
      openCancellationInfo,
    );
  };

  const nameChangePopupClose = () => {
    setNameChangeOverlay(false);
  };

  const nameChangePopupProceedClick = (reasonSelected = null) => {
    nameChangePopupClickHandler(reasonSelected, nameChangePopupClose, responseJson, bookingId);
  };

  const dateChangeCardActionClick = (
    action: any,
    pageName: string,
    isSourceDgcaVoidPopup: boolean = false,
    resJson = responseJson,
  ) => {
    dateChangeCardClkHandler(
      action,
      pageName,
      resJson,
      getLobName(),
      openCancellationInfo,
      isSourceDgcaVoidPopup,
    );
  };

  const cancelCardActionItemClick = (
    actionItem: any,
    pageName: string,
    isSourceSpecialClaimSheet = false,
    isSourceCancellationInfoSheet = false,
    resJson = responseJson,
  ) => {
    const { cancellationDgca, cancellationVoid } = resJson;
    switch (actionItem.actionId) {
      case 'CANCEL_FLT_FULL':
        if (isSourceCancellationInfoSheet && (cancellationDgca || cancellationVoid)) {
          openCancellationInfo({ actionItem, pageName });
          return;
        }
        onCancellationButtonClicked(
          FLIGHT_CANCELLATION_TYPE.FULL_CANCELLATION,
          bookingId,
          resJson,
        );
        break;
      case 'CANCEL_FLT_PARTIAL':
        if (isSourceCancellationInfoSheet && (cancellationDgca || cancellationVoid)) {
          openCancellationInfo({ actionItem, pageName });
          return;
        }
        onCancellationButtonClicked(
          FLIGHT_CANCELLATION_TYPE.PARTIAL_CANCELLATION,
          bookingId,
          resJson,
        );
        break;
      case 'CANCEL_FLT_PARTIAL_SEGGRP':
        {
          if (isSourceCancellationInfoSheet && (cancellationDgca || cancellationVoid)) {
            openCancellationInfo({ actionItem, pageName });
            return;
          }
          const selectedSegmentGroupView = getSelectedSegmentGroupView(actionItem);
          onCancellationButtonClicked(
            FLIGHT_CANCELLATION_TYPE.PARTIAL_CANCELLATION,
            bookingId,
            resJson,
            selectedSegmentGroupView,
          );
        }
        break;
      default:
        break;
    }
  };
  const cancelHoldBooking = () => {
    setHoldBookingCancellationFlow(true);
  };
  const openSpecialClaimPopUp = (data) => {
    setSpecialClaimBottomSheet({ value: true, data });
    Animated.timing(bottomSheetAnimated, {
      toValue: 0,
      duration: 200,
    }).start();
  };

  const openCancellationInfo = (data) => {
    setCancellationInfoOverlay({ value: true, data });
    Animated.timing(bottomSheetAnimated, {
      toValue: 0,
      duration: 200,
    }).start();
  };

  const closeCancellationInfoOverlay = () => {
    Animated.timing(bottomSheetAnimated, {
      toValue: 0,
      duration: 200,
    }).start();
    setCancellationInfoOverlay({ value: false, data: null });
  };

  const closeSpecialClaimPopUp = () => {
    Animated.timing(bottomSheetAnimated, {
      toValue: -500,
      duration: 200,
    }).start();
    setSpecialClaimBottomSheet({ value: false, data: null });
  };

  const renderPage = () => {
    if (viewState === ViewState.ERROR) {
      return (
        <ErrorPage
          handleClick={handleClick}
          goBack={() => handleClick(BACK)}
          data={!isEmpty(errorData) ? errorData : undefined}
        />
      );
    }
    return (
      <>
        <RenderPage
          actionCallback={handleClick}
          fetchFlightBookingDetails={fetchFlightBookingDetails}
          cardListMap={getCardListMap()}
          pageData={getPageData({
            bookingId,
            pageRequested,
            pageName:
              pageRequested === PAGE_REQUESTED.REFUND_TRACKER
                ? PAGE_NAME.REFUND_TRACKER
                : PAGE_NAME.FLIGHT_DETAILS,
          })}
          cancelRequest={FetchFlightData.cancelRequest}
          extraPropsData={extraPropsData || {}}
        />
        {isApiLoading && <SpinLoader size={'large'}/>}
        {!isEmpty(responseJson) && (
          <>
            {showNameChangeOverlay &&
            (responseJson.nameCorrectionDetails?.passengerList ? (
                <NameChangeBottomSheet
                  onClose={nameChangePopupClose}
                  data={responseJson.nameCorrectionDetails}
                  onContinueClicked={nameChangePopupProceedClick}
                  responseJson={responseJson}
                  customStyle={styles.nameChangeCustomStyle}
                />
              ) : (
                showShortToast('Name change not allowed for this booking')
              ))}
            {showCancellationInfoOverlay.value && (
              // For showing DGCA and Void Popup in case of date change, cancellation and Name correction
              <CommonOverlayMessage
                handleBottomOverlay={closeCancellationInfoOverlay}
                isBorderRadius={true}
                overlayContentStyle={styles.overlayContentStyle}
                overlayWrapperStyle={styles.overlayWrapperStyle}
                content={
                  <CancellationInfoOverlay
                    bookingId={bookingId}
                    cancellationJson={cancellationInfoJson(
                      responseJson,
                      showCancellationInfoOverlay,
                    )}
                    isNameChangeCorrection={
                      showCancellationInfoOverlay?.data?.popupInfo === 'voidNameChangeInfo'
                    }
                    isSourceDateChange={
                      showCancellationInfoOverlay?.data?.popupInfo ===
                      VOID_DGCA_CONSTANTS.DGCA_VOID_DATE_CHANGE_INFO
                    }
                    closeOverlay={closeCancellationInfoOverlay}
                    sourceButtonData={showCancellationInfoOverlay.data}
                    cancelCardActionItemClick={cancelCardActionItemClick}
                    dateChangeCardActionClick={dateChangeCardActionClick}
                  />
                }
              />
            )}
            {showSpecialClaimBottomSheet.value && (
              // For special claim flow
              <CommonOverlayMessage
                handleBottomOverlay={closeSpecialClaimPopUp}
                isBorderRadius={true}
                overlayContentStyle={[styles.overlayContent]}
                content={
                  <Animated.View style={{ bottom: bottomSheetAnimated }}>
                    <SpecialClaimBottomSheetV2
                      bookingDetailsResponse={responseJson}
                      closeSpecialClaimPopUp={closeSpecialClaimPopUp}
                      cancellationButtonData={showSpecialClaimBottomSheet.data}
                      cancelCardActionItemClick={cancelCardActionItemClick}
                    />
                  </Animated.View>
                }
              />
            )}
            {wheelChairFlow &&
              !isEmpty(ancillaryResponse) &&
              !ancillaryResponse.error && (
                <WheelChairAddon
                  setWheelChairFlow={setWheelChairFlow}
                  popupContainerStyle={[styles.overlayContent]}
                  flightDetailResponse={responseJson}
                  bookingId={bookingId}
                  segmentId={ancillaryResponse?.segIdVsLineNo?.[wheelChairData.segmentId]}
                  ancillaryResponse={ancillaryResponse}
                />
              )}
          </>
        )}
        {bookingInvoices && (
          <BookingInvoiceSection setBookingInvoice={setBookingInvoice} bookingId={bookingId} />
        )}
        {holdBookingCancellationFlow && (
          <CommonOverlayMessage
            handleBottomOverlay={() => setHoldBookingCancellationFlow(false)}
            isBorderRadius={true}
            overlayContentStyle={[styles.overlayContent]}
            content={
                <FlightBookingCancellation
                  cancellationRequest={{
                    bookingId,
                    isFullCancellation: true,
                    fullCancellationReason: 'HOLD_RELEASE',
                    validateClaim: false,
                    refundThrough: '',
                    submitWithProof: '',
                    isHoldBooking: true,
                  }}
                  onSuccess={() => {
                    setHoldBookingCancellationFlow(false);
                    handleClick('REFRESH_PAGE');
                  }}
                  onError={() => {
                    setHoldBookingCancellationFlow(false);
                    handleClick('ERROR');
                  }}
                />
            }
          />
        )}
      </>
    );
  };
  if (viewState === ViewState.LOADING) { return <PageLoader />; }
  return <>{renderPage()}</>;
};

export default RenderFlightDetailPage;
