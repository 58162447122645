import { Dimensions, StyleSheet } from "react-native";
export default (theme, fonts) =>StyleSheet.create({ 
    destCard:{
        borderBottomColor:theme.color.gray1,
        borderBottomWidth:1,
        marginBottom:8,
        paddingBottom:10
    },
    rightArrow:{
        width:21,
        height:12,
        tintColor:theme.color.lightTextColor
    },
    imageContainer:{
        paddingLeft:20
    },
    busIcon:{
        height:28,
        width:28,
        marginRight:20
    }
})