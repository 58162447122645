import railsConfig from '../RailsConfig';
import { TRAIN_SCHEDULE_URL } from '../pages/RIS/LiveTrainStatus/constants';

export const apiSets = {
  LOB_GENERIC: [
    {
      api: railsConfig.configStoreUrl,
      name: 'configStore',
    },
  ],
  LANDING: [
    {
      api: railsConfig.railsLandingCardOrderUrl,
      name: 'railsLandingCardOrder',
    },
    {
      api: railsConfig.railsLandingReturnTicketUrl,
      name: 'railsLandingReturnTicketUrl',
    },
    {
      api: railsConfig.railsLandingRecentBookingUrl,
      name: 'railsLandingRecentBookingUrl',
    },
  ],
  LISTING_V2: [
    {
      api: railsConfig.confirmedOptions,
      name: 'confirmedOptions',
    },
    {
      api: railsConfig.neabyDatesProbablityUrl,
      name: 'neabyDatesProbablity',
    },
    {
      api: railsConfig.connectedTraveller,
      name: 'connectedTravel',
    },
    {
      api: railsConfig.alternateAvailabilityUrl,
      name: 'alternateAvailability',
    },
    {
      api: railsConfig.confirmationProbablity,
      name: 'confirmationProbablity',
    },
    {
      api: railsConfig.routeAvailability,
      name: 'routeAvailability',
    },
    {
      api: railsConfig.trainAvailabilityUrl,
      name: 'availability',
    },
  ],
  TRAVELLER: [
    {
      api: railsConfig.boardingStationUrl,
      name: 'boardingStation',
    },
    {
      api: railsConfig.recommendCouponURL,
      name: 'recommendCoupon',
    },
    {
      api: railsConfig.assuredConfirmationUrl,
      name: 'assuredConfirmation',
    },
    {
      api: railsConfig.validateUserId,
      name: 'validateUserId',
    },
    {
      api: railsConfig.retrieveUserId,
      name: 'retrieveUserId',
    },
    {
      api: railsConfig.retrievePassword,
      name: 'retrievePassword',
    },
  ],
  REVIEW: [
    {
      api: railsConfig.bookingReviewUrl,
      name: 'bookingReview',
    },
  ],
  RIS: [
    {
      api: TRAIN_SCHEDULE_URL,
      name: 'trainSchedule',
    },
  ],
};
