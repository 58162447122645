import { ImageStyle, TextStyle, ViewStyle, StyleSheet } from 'react-native';
import { DEFAULT_THEME } from 'apps/post-sales/src/theme/default.theme';

interface Styles {
    completeBookingWrapper: ViewStyle;
    leftStrip: ViewStyle;
    gradient: ViewStyle;
    clockIcon: ImageStyle;
    pendingText: TextStyle;
    textWithPaddingLeft: TextStyle;
    textWithPaddingRight: TextStyle;
    makePaymentText: TextStyle;
    completeText: TextStyle;
    button: ViewStyle;
    btnWprStyle: ViewStyle;
    btnStyle: ViewStyle;
    btnTextStyle: TextStyle;
    marginRight4: ViewStyle;
}

const createStyles = <Theme extends typeof DEFAULT_THEME>(theme: Theme) =>
    StyleSheet.create<Styles>({
        completeBookingWrapper: {
            borderTopLeftRadius: 25,
            borderTopRightRadius: 25,
            top: -12,
            elevation: 3,
            shadowColor: theme.color.black,
            shadowOffset: { width: 0, height: 0 },
            shadowOpacity: 0.33,
            shadowRadius: 3,
            backgroundColor: theme.color.white,
            padding: 20,
            paddingTop: 16,
            paddingLeft: 32,
        },
        leftStrip: {
            width: 5,
            height: 28,
            backgroundColor: theme.color.lightYello,
            position: 'absolute',
            left: 0,
            top: 20,
        },
        gradient: {
            borderRadius: 20,
            flex: 1,
            alignSelf: 'flex-start',
            flexDirection: 'row',
            alignItems: 'center',
            padding: 4,
            paddingRight: 5,
            marginBottom: 12,
        },
        clockIcon: {
            height: 15,
            width: 15,
        },
        pendingText: {
            lineHeight: 14,
            color: theme.color.white,
        },
        textWithPaddingLeft: {
            paddingLeft: 3,
        },
        textWithPaddingRight: {
            paddingRight: 5,
        },
        makePaymentText: {
            color: theme.color.lightYello,
            lineHeight: 16,
            marginBottom: 20,
        },
        completeText: {
            color: theme.color.textGrey,
            lineHeight: 18,
            marginBottom: 8,
            marginTop: 13,
        },
        btnWprStyle: {
            alignItems: 'center',
        },
    });

export default createStyles;