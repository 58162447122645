import React from 'react';
import { View, Text, Image, TouchableOpacity, Platform, StyleSheet } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';
import BasePage from '../../../../../Common/PostSalesBasePage';
import FlightConst, { FLIGHT_DOWNLOAD_FILES_THROTTLE_INTERVAL } from '../../FlightBookingDetailsConstant';
import * as FlightUtil from '../../../../FlightBookingUtil';
import { DOWNLOAD_ICON, ONEWAY_ARROW_ICON, RT_ICON } from '../../../../FlightImageConstants';

import { getStaticData } from '../../../../../staticData/staticData';
import { imageTransformY } from '../../../../../utils/PostSaleUtil';
import { getFont } from '../../../../../PostSalesStyles';

class StickyHeader extends BasePage {
  static navigationOptions = {
    header: null,
  };
  constructor(props) {
    super(props);
    this.lastClickTimeRef = React.createRef();
  }
  getHeaderTravelDate() {
    const dateOfTravel = [];

    this.props.response.newFlightDetails.segmentGroupDetailList.map((segmentFlightDetails) => {
      if (segmentFlightDetails.segmentDetails.length > 0) {
        dateOfTravel.push(
          FlightUtil.getTravelDate(segmentFlightDetails.segmentDetails[0].travelDateTime),
        );
      }
    });

    const passengers = this.props.response.passengerList;

    const map = new Map();
    let passengerCount = 0;
    for (const item of passengers) {
      if (!map.has(item.paxReferenceNo)) {
        map.set(item.paxReferenceNo, true);
        passengerCount++;
      }
    }

    let travelDuration = dateOfTravel.join(' - ') + ' • ' + passengerCount + ' Traveller(s)';
    return travelDuration;
  }

  getHeadingViews() {
    const views = [];
    const { fsStyle, ...fonts } = getFont(true);
    const { multicityFlightsText, flightDetailsText } = getStaticData();
    if (this.props.response.newFlightDetails.journeyType === FlightConst.MULTI_CITY) {
      views.push(
        <View style={styles.stickyInfoWrapper}>
          <View style={styles.stickyTextWrapper}>
            <Text
              numberOfLines={1}
              ellipsizeMode="tail"
              style={[styles.stickyTxt, fonts.blackFontFamily]}
            >
              {multicityFlightsText}
            </Text>
            <Text style={[styles.stickyTxt2, fonts.regularFontFamily, fsStyle.font12]}>
              {this.props.response.newFlightDetails.journeyDetails || this.getHeaderTravelDate()}
            </Text>
          </View>
        </View>,
      );
    } else if (this.props.response.newFlightDetails.bookingIntentDetail !== null) {
      let cityName = this.props.response.newFlightDetails.bookingIntentDetail.split('-');
      const cityNameLength = cityName.length;
      if (this.props.response.newFlightDetails.journeyType === FlightConst.ONE_WAY) {
        views.push(
          <View style={styles.stickyInfoWrapper}>
            <View style={styles.stickyTxtWrapper}>
              <Text
                numberOfLines={1}
                ellipsizeMode="tail"
                style={[styles.stickyTxt, fonts.blackFontFamily, fsStyle.font14]}
              >
                {cityName[0]}
              </Text>
              <Image style={[styles.oneWayIconStyle, imageTransformY]} source={ONEWAY_ARROW_ICON} />
              <Text
                numberOfLines={1}
                ellipsizeMode="tail"
                style={[styles.stickyTxt, fonts.blackFontFamily, fsStyle.font14]}
              >
                {cityName[cityNameLength - 1]}
              </Text>
            </View>
            <Text style={[styles.stickyTxt2, fonts.regularFontFamily, fsStyle.font12]}>
              {this.props.response.newFlightDetails.journeyDetails || this.getHeaderTravelDate()}
            </Text>
          </View>,
        );
      } else {
        views.push(
          <View style={styles.stickyInfoWrapper}>
            <View style={styles.stickyTxtWrapper}>
              <Text
                numberOfLines={1}
                ellipsizeMode="tail"
                style={[styles.stickyTxt, fonts.blackFontFamily]}
              >
                {cityName[0]}
              </Text>
              <Image style={styles.rtIconStyle} source={RT_ICON} />
              <Text
                numberOfLines={1}
                ellipsizeMode="tail"
                style={[styles.stickyTxt, fonts.blackFontFamily]}
              >
                {cityName[cityNameLength - 1]}
              </Text>
            </View>
            <Text style={[styles.stickyTxt2, fonts.regularFontFamily, fsStyle.font12]}>
              {this.props.response.newFlightDetails.journeyDetails || this.getHeaderTravelDate()}
            </Text>
          </View>,
        );
      }
    } else {
      views.push(
        <View style={styles.stickyInfoWrapper}>
          <View style={styles.stickyTextWrapper}>
            <Text
              numberOfLines={1}
              ellipsizeMode="tail"
              style={[styles.stickyTxt, fonts.blackFontFamily]}
            >
              {flightDetailsText}
            </Text>
          </View>
        </View>,
      );
    }

    return views;
  }  
  throttledClickHandler = () => {
    const currentTime = Date.now();
    if (currentTime - this.lastClickTimeRef.current >= FLIGHT_DOWNLOAD_FILES_THROTTLE_INTERVAL) {
      this.lastClickTimeRef.current = currentTime;
      this.props.onDownloadEticketClicked(true);
    }
  }

  render() {
    let views = [];
    views = this.getHeadingViews();
    const { styleColours } = this.props;
    const state = this.props.response?.bookingState?.state?.toUpperCase();
    const isHoliday = this.props.response?.holidayBooking;
    const showDownloadIcon = !isHoliday && !FlightUtil.isHoldBooking(this.props.topStatusCard?.topCardType) && (state === FlightConst.UPCOMING || state === FlightConst.ONTRIP) && !FlightUtil.isFareLockExpired(this.props.topStatusCard?.topCardType);
    return (
      <LinearGradient
        start={{ x: 1.0, y: 0.0 }}
        end={{ x: 0.0, y: 1.0 }}
        colors={styleColours}
        style={styles.topHeaderContainer}
      >
        <View style={styles.topStatusBar}>
          <View style={styles.stickyBarWrapper}>
            <TouchableOpacity style={styles.topBackArrowWrapper} onPress={this.props.onBackPress}>
              <Image
                style={
                  Platform.OS === 'ios'
                    ? [styles.IOSbackarrow, imageTransformY]
                    : [styles.backarrow, imageTransformY]
                }
                source={this.props.arrowStyle}
              />
            </TouchableOpacity>
            {this.props.showOtherInfo && views}
            {showDownloadIcon && <TouchableOpacity onPress={this.throttledClickHandler}>
              <Image style={styles.downloadIcon} source={DOWNLOAD_ICON} />
            </TouchableOpacity>}
          </View>
        </View>
      </LinearGradient>
    );
  }
}

const styles = StyleSheet.create({
  downloadIcon: {
    width: 24,
    height: 24,
    tintColor: '#231F20',
  },
  topHeaderContainer: {
    ...getPlatformElevation(3),
    shadowColor:'transparent'
  },
  topStatusBar: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: 20,
    paddingRight: 20,
    alignItems: 'center',
  },
  topBackArrowWrapper: {
    width: 44,
    height: 44,
    alignItems: 'center',
    justifyContent: 'center',
    left: -10,
  },

  backarrow: {
    width: 16,
    height: 16,
  },
  IOSbackarrow: {
    width: 10,
    height: 16,
  },
  stickyBarWrapper: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  stickyTxtWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  oneWayIconStyle: {
    width: 10,
    height: 8,
    marginHorizontal: 5,
  },
  stickyInfoWrapper: {
    paddingVertical: 10,
    alignItems: Platform.OS === 'ios' ? 'center' : 'flex-start',
    flex: 1,
  },
  stickyTxt: {
    color: '#4a4a4a',
    backgroundColor: 'transparent',
    marginBottom: 5,
  },
  stickyTxt2: {
    color: '#000',
    backgroundColor: 'transparent',
  },
  errorHeader: {
    ...getPlatformElevation(3),
    marginBottom: 2,
    backgroundColor: 'transparent',
  },
  ShareImgWrapper: {
    width: 24,
    height: 25,
    alignItems: 'center',
    position: 'absolute',
    right: -5,
    top: 20,
    justifyContent: 'center',
  },
  shareIcon: {
    width: 19,
    height: 12,
  },
  rtIconStyle: {
    width: 10,
    height: 8,
    marginHorizontal: 5,
  },
});

export default StickyHeader;
