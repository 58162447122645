import React, { useEffect } from 'react';
import { View, Text, Image } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getFont } from '../../PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import { createStyles } from './thankyouCss';
import BaseButton from '../../Common/BaseButton';
import isEmpty from 'lodash/isEmpty';
import { BackHandler } from 'react-native';
import { ThankYouButtonAction } from '../../types';

interface Props {
    icon: {
        uri: string;
    };
    message: string;
    description: string;
    actions: ThankYouButtonAction[];
}

const Thankyou: React.FC<Props> = ({ icon, message, description, actions = [] }) => {
    const fonts = getFont();
    const { psTheme: theme } = useTheme();
    const styles = createStyles(theme, fonts);

    return (
        <View style={styles.pageWrapper}>
            <View>
                {icon && <Image style={styles.successStyle} source={icon} />}
            </View>
            {!isEmpty(message) && <Text style={styles.heading}>{message}</Text>}
            {!isEmpty(description) && <Text style={styles.desc}>{description}</Text>}
            <View style={styles.buttonBottomWrapper}>
                <View style={[styles.buttonWrapper, AtomicCss.marginBottom15]}>
                    {!isEmpty(actions) &&
                        actions.map(action => (
                            <BaseButton
                                key={action.id}
                                text={action.text}
                                variant={action.variant}
                                clickHandler={action.clickHandler}
                                shadow={action.shadow}
                                buttonStyle={action.buttonStyle}
                            />
                        ))}
                </View>
            </View>
        </View>
    );
};

export default Thankyou;
