/* Postsales Images */
import { getImagePath } from '../utils/PostSaleUtil';

export const COVID_LOGO_ICON = getImagePath('covidLogo.webp');
export const RIGHT_ARROW_BLUE_ICON = getImagePath('blueRtArrow.webp');
export const RIGHT_ARROW_WHITE_ICON = getImagePath('White_Arrow.webp');
export const WC_SUCCESS_ICON = getImagePath('webCheckinSuccess.webp');
export const WC_OUTSIDE_ICON = getImagePath('webcheckinAirline.webp');
export const BLUE_RIGHT_LONG_ARROW_ICON = getImagePath('webcheckinArrow.webp');
export const SEAT_ICON_SM = getImagePath('seatIcon.webp');
export const SEAT_ICON = getImagePath('seatsIcon.webp');
export const SEAT_GREY_ICON = getImagePath('seatErrorAncillary.webp');
export const SEAT_ORANGE_ICON = getImagePath('seatItemIcon.webp');
export const SEAT_ALERT_ICON = getImagePath('seatOverlay.webp');
export const MEAL_ICON = getImagePath('mealIcon.webp');
export const MEAL_ICON3 = getImagePath('mealIcon3.png');
export const MEAL_VEG_ICON = getImagePath('mealsIcon.webp');
export const MEAL_NON_VEG_ICON = getImagePath('chicken.webp');
export const MEAL_ORANGE_ICON = getImagePath('mealRedirect.webp');
export const MEAL_ORANGE_ICON2 = getImagePath('mealImg.webp');
export const MEAL_GREY_ICON = getImagePath('mealsGrey.webp');
export const MEAL_ALERT_ICON = getImagePath('mealOverlay.webp');
export const BAGGAGE_ICON = getImagePath('ancillarybaggageIcon.webp');
export const BAGGAGE_ORANGE_ICON = getImagePath('baggageRedirect.webp');
export const BAGGAGE_ORANGE_ICON2 = getImagePath('baggageIcon.webp');
export const BAGGAGE_GREY_ICON = getImagePath('baggageGrey.webp');
export const BAGGAGE_WHITE_ICON = getImagePath('bagImg.webp');
export const ADD_ON_SUCCESS_ICON = getImagePath('AncillaryAddOnsSuccessfull_Icon.webp');
export const ADD_ON_FAIL_ICON = getImagePath('AncillaryBookingFailed_Icon.webp');
export const ADD_ON_PARTIAL_ICON = getImagePath('addOnpartial.webp');
export const FLIGHT_GREEN_ICON = getImagePath('webcheckinFltIcon.webp');
export const FLIGHT_DISABLE_ICON = getImagePath('inactiveSector.webp');
export const TIP_GREEN_ICON = getImagePath('tipIcon.webp');
export const WHITE_TICK_ICON = getImagePath('checkmark.webp');
export const CROSS_BOX_ICON = getImagePath('seat-not-available.webp');
export const FLIGHT_ICON = getImagePath('flight_icon2.webp');
export const RELEASE_HOLD = getImagePath('release_hold.webp');
export const FLIGHT_COCKPIT_ICON = getImagePath('cockpit.webp');
export const FLIGHT_TAIL_ICON = getImagePath('tail.webp');
export const EXIT_LEFT_ICON = getImagePath('exit-left.webp');
export const EXIT_RIGHT_ICON = getImagePath('exit-right.webp');
export const LAVATORY_ICON = getImagePath('lavatory.webp');
export const GALLEY_ICON = getImagePath('galley.webp');
export const BASSINET_ICON = getImagePath('bassinet.webp');
export const WINGS_LEFT_ICON = getImagePath('wingsIcon.webp');
export const WINGS_RIGHT_ICON = getImagePath('rectangleRight.webp');
export const ONEWAY_ARROW_ICON = getImagePath('oneWayArrow.webp');
export const ONEWAY_ARROW_ICON2 = getImagePath('oneway.webp');
export const ALERT_RED_ICON = getImagePath('ancillaryAlert.webp');
export const ALERT_RED_TRIANGLE_ICON = getImagePath('errorInfoIcon.webp');
export const SUCCESS_TICK_ICON = getImagePath('SuccessTick.webp');
export const GREEN_TICK_ICON2 = getImagePath('circular_green.webp');
export const CROSS_RED_CIRCLE_ICON = getImagePath('circular_red.webp');
export const CLOCK_GREEN_ICON = getImagePath('clock_green.webp');
export const SUCCESS_ICON = getImagePath('SuccessfullIcon.webp');
export const FAILED_ICON = getImagePath('ic_failed.webp');
export const CHECKBOX_ACTIVE_BLUE_ICON2 = getImagePath('ic_pax_consent_chkboxactive.webp');
export const CHECKBOX_ACTIVE_BLUE_ICON3 = getImagePath('checkbox_tick.webp');
export const CHECKBOX_INACTIVE_ICON2 = getImagePath('ic_pax_consent_chkboxinactive.webp');
export const CHEVRON_DOWN_BLUE_ICON2 = getImagePath('BottomArrow.webp');
export const CHEVRON_DOWN_BLUE_ICON3 = getImagePath('country_list_arrow.webp');
export const CHEVRON_UP_BLUE_ICON2 = getImagePath('TopArrow.webp');
export const PAX_ICON = getImagePath('passegngerDummyImage.webp');
export const CALENDAR_ICON = getImagePath('calendar.webp');
export const CALENDAR_GREEN_ICON = getImagePath('dcIcon.webp');
export const CALENDAR_GREEN_TICK_ICON = getImagePath('date_change_successful_icon.webp');
export const CALENDAR_GREEN_TICK_ICON2 = getImagePath('successful_icon.webp');
export const MALE_ICON = getImagePath('maleIcon.webp');
export const FEMALE_ICON = getImagePath('femaleIcon.webp');
export const CALENDAR_DATE_CHANGE_ICON = getImagePath('ic_calendar_date_change.webp');
export const AROGYA_SETU = getImagePath('setu.webp');
export const AIRLINE_PHONE_ICON = getImagePath('airline_phone.webp');
export const BAGGAGE_PROTECTION = getImagePath('baggageProtect.webp');
export const MBR_ICON = getImagePath('MBR.webp');
export const IBR_IMG = getImagePath('IBRB.webp');
export const TKR_IMG = getImagePath('TRK.webp');
export const GRNT_IMG = getImagePath('GRNT.webp');
export const AIRLINE_PHONE_BLUE_ICON = getImagePath('ic_phoneIcon_date_change.webp');
export const YELLOW_BG_INFO_ICON = getImagePath('ic_infoIcon_date_change.webp');
export const DB_ICON = getImagePath('cancellationCardDBIcon.webp');
export const ZC_ICON = getImagePath('cancellationCardZCIcon.webp');
export const GREY_RIGHT_ARROW = getImagePath('rightArrowGrey.webp');
export const SPECIAL_CLAIM_ICON = getImagePath('special_claim_icon.webp');
export const ZC_POLICY_ICON = getImagePath('ZCIcon.webp');
export const DB_POLICY_ICON = getImagePath('DBImage.webp');
export const MY_ICON = getImagePath('myIcon.webp');
export const AVAN_LOGO = getImagePath('avaan_logoIcon.webp');
export const LUGGGAGE_ICON = getImagePath('luggageIcon.webp');
export const FLT_BOOKING_ICON = getImagePath('bookingFltIcon.webp');
export const TNC_ICON = getImagePath('tncIcon.webp');
export const ONE_WAY_ICON = getImagePath('oneway_arrow.webp');
export const ADD_ON_ICON = getImagePath('cabIcon.webp');
export const WEB_CHECKIN_ICON = getImagePath('webChkInIcon.webp');
export const BAG_ICON = getImagePath('bagIcon.webp');
export const BG_MEAL_ICON = getImagePath('mealIcon2.webp');
export const CIRCLED_ARROW = getImagePath('arrowCircle.webp');
export const webChkGreyIcon = getImagePath('webChkGreyIcon.webp');
export const SM_BELT_ICON = getImagePath('beltIconSm.webp');
export const YELLOW_BELT_ICON = getImagePath('beltIconSm1.webp');
export const GATE_ICON_BLUE = getImagePath('gateIconSm.webp');
export const GATE_ICON_YELLOW = getImagePath('gateIconSm1.webp');
export const CIRCLED_SUCCESS_ICON = getImagePath('circularGreen.webp');
export const DEALYED_ICON = getImagePath('delay_icon.png');
export const DEFAULT_ICON = getImagePath('icon_default.png');
export const CANCELLED_FLT_ICON = getImagePath('icon_cancelled.png');
export const CHILD_ICON = getImagePath('childIcon.webp');
export const INFANT_ICON = getImagePath('infantIcon.webp');
export const DOUBLE_SEAT_ICON = getImagePath('doubleSeatIcon.webp');
export const CHECKIN_BAG = getImagePath('chkInBagIcon.webp');
export const GREY_INFO_ICON = getImagePath('info-icon1.webp');
export const PRIORITY_CHECKIN_ICON = getImagePath('fastForward.webp');
export const WIFI_ICON = getImagePath('wifi.webp');
export const LOUNGE_ICON = getImagePath('lounge.webp');
export const ADD_BAG = getImagePath('additionalBag.webp');
export const FARE_ICON = getImagePath('fareIcon.webp');
export const FARE_ICON_GCC = getImagePath('fareIconGcc.png');
export const FLEX_ICON = getImagePath('flexiIcon.webp');
export const ADJUSTED_ICON = getImagePath('iconAdjusted.webp');
export const USER_ICON = getImagePath('userIconForExchangeFlight.webp');
export const FLT_ICON = getImagePath('fltExIcon.webp');
export const MY_BIZ_ICON = getImagePath('myBizIcon.webp');
export const NEED_HELP_ICON = getImagePath('ic_need_help.webp');
export const ERROR_INFO_ICON = getImagePath('errorInfoIcon.webp');
export const REFUND_PRICE_ICON = getImagePath('refundPriceIcon.webp');
export const CLAIM_PROCESED_ICON = getImagePath('refundProcessIcon.webp');
export const CLAIM_REJECTED_ICON = getImagePath('special_claim_rejected.webp');
export const CALL_AGENT_ICON = getImagePath('callAgentIcon.webp');
export const TALK_TO_US_ICON = getImagePath('contactUs.webp');
export const EMPTY_LIST_ICON = getImagePath('emptyListIcon.png');
export const AGENT_CALL_ICON = getImagePath('agent_call.png');
export const SPECIAL_REQUEST = getImagePath('specialRequest.webp');
export const NAME_CHANGE_ARROW = getImagePath('name_change_arrow.webp');
export const COVID_IMAPACTED_ICON = getImagePath('ic_covid_impacted.webp');
export const FULL_AWAITED_ICON = getImagePath('ic_full_awaited.webp');
export const PARTAILLY_AWAITED_ICON = getImagePath('ic_partially_awaited.webp');
export const BLUE_ALARM_ICON = getImagePath('ctrip.webp');
export const FLT_AWAITED_ICON = getImagePath('flight_top_card_alarmOn.webp');
export const FAILED_CROSS_ICON = getImagePath('flight_top_card_errorIcon.webp');
export const SPCLIAM_RAISED_ICON = getImagePath('special_claim_raised.webp');
export const FLT_ON_HOLD_ICON = getImagePath('ic_on_hold.webp');
export const GREY_CANCELLED_ICON = getImagePath('ic_cancelled_grey.webp');
export const RT_ICON = getImagePath('roundtrip_arrow.webp');
export const GREY_CROSS_ICON = getImagePath('crossIcon2.webp');
export const TRIP_PROTECTIPN_ICON = getImagePath('tripProtection.webp');
export const FLT_POLICY_ICON = getImagePath('rectangle_20.webp');
export const DISRUPT_FLT_IMG = getImagePath('fltCoverIcon.webp');
export const MISS_FLT_IMG = getImagePath('missFltIcon.webp');
export const DIGIT_IMG = getImagePath('digit.webp');
export const ASSURANCE_ICON = getImagePath('ic_flight_assurance.webp');
export const WC_FAILED_ICON = getImagePath('webcheckinFailedIcon.webp');
export const PHONE_CALL_ICON = getImagePath('phoneIcon1.webp');
export const BLUE_RUPEES_ICON = getImagePath('PriceIcon1.webp');
export const SM_BLUE_RT_ARROW = getImagePath('rightArrow1.webp');
export const CALL_BLUE_ICON = getImagePath('ic_call_flight.webp');
export const CANCELLATION_ICON = getImagePath('ic_cancellation_reuqest.webp');
export const ERROR_ICON = getImagePath('errorImage.webp');
export const PRICE_ICON = getImagePath('PriceIcon.webp');
export const NAME_CHANGE_SUCCESS_ICON = getImagePath('name_change_request_success.webp');
export const NAME_CHANGE_FAIL_ICON = getImagePath('name_change_request_fail.webp');
export const CFAR_ICON = getImagePath('cfarIcon.png');
export const NO_TRIPS_ICON = getImagePath('no_trips.png');
export const NO_TRIPS_ICON_LARGE = getImagePath('no_trips_lrg.png');
export const VACCINATION_CARD_ICON = getImagePath('vaccination_icon.png');
export const MMTCONNECT_ICON = getImagePath('mmtConnect.png');
export const WHEELCHAIR_ICON = getImagePath('wheelchair_cta.png');
export const WHEELCHAIR_PAX_GREEN = getImagePath('wheelchair_pax_green.png');
export const WHEELCHAIR_PAX_GREY = getImagePath('wheelchair_pax_grey.png');
export const WHEELCHAIR_PAX_RED = getImagePath('wheelchair_pax_red.png');
export const rightArrowGreyIcon = getImagePath('v5rightArrowGreyIcon.webp');
export const flightRoundTripIcon = getImagePath('v5flightRoundTripIcon.webp');
export const flightIcon = getImagePath('v5flightIcon.webp');
/*
  myBiz flight images
*/
export const myBiz_WEB_CHECKIN_ICON = getImagePath('myBiz_web_checkin.png');
export const myBiz_ADD_SEAT = getImagePath('myBiz_add_seat.png');
export const myBiz_ADD_MEAL = getImagePath('myBiz_add_meal.png');
export const myBiz_ADD_BAG = getImagePath('myBiz_add_bag.png');
export const MY_BIZ_LOGO = getImagePath('v5MyBizLogo.webp');
export const TIP_FACING_TOP_ICON = getImagePath('tipFacingTop.webp');

/* from cancellation */
export const ERROR_REQUEST_ICON = getImagePath('ic_cancellation_reuqest.webp');
export const ERROR_REQUEST_ICON_GCC = getImagePath('flight_error_gcc.png');
export const FAILED_INFO_ICON = getImagePath('failedIcon.webp');
export const FLIGHT_IMG = getImagePath('flightImg.webp');
export const DOUBLE_TICK_ICON = getImagePath('double-tick.webp');
export const BG_CIRCLED_SUCCESS_ICON = getImagePath('success-icon.webp');
export const GREEN_REFUND_ICON = getImagePath('ic_refundable_green.webp');
export const RED_REFUND_ICON = getImagePath('ic_refundable_red.webp');
export const BLACK_REFUND_ICON = getImagePath('ic_refundable_black.webp');
export const ZERO_CANCEL_ICON = getImagePath('zeroCancellationIcon.webp');
export const SM_DOUBLE_SEAT_ICON = getImagePath('dbSeatIcon.webp');
export const GROPU_3_ICON = getImagePath('group_3.webp');
export const CANCELLED_PAX_ICON = getImagePath('cancelledIcon.webp');
export const PARTIAL_CHECKEDBOX_ICON = getImagePath('partiallyIcon.webp');
export const WARNING_ICON = getImagePath('warningRedBg.webp');
export const ONE_WAY_JRNY_ICON = getImagePath('oneway.webp');
export const RED_INFO_ICON = getImagePath('red_info.webp');
export const CHECKBOX_TICK_ICON = getImagePath('checkbox_tick.webp');
export const ORANGE_CHECKBOX_ICON = getImagePath('checkbox_tick_b2b.webp');
export const MAIL_ICON = getImagePath('mail.webp');
export const CALL_MMT_ICON = getImagePath('call_mmt.webp');
export const REFUNDS_ICON = getImagePath('refunds.webp');
export const SM_USER_ICON = getImagePath('user.webp');
export const CREDIT_SHELL_ICON = getImagePath('special_claim_credit_shell.webp');
export const FNO_ICON = getImagePath('special_claim_FNO.webp');
export const TDCWA_ICON = getImagePath('special_claim_TDCWA.webp');
export const FDOP_ICON = getImagePath('special_claim_FDOP.webp');
export const NOSHOW_ICON = getImagePath('special_claim_NOSHOW.webp');
export const VISAREJ_ICON = getImagePath('special_claim_VISAREJ.webp');
export const FALSE_CLAIM_ICON = getImagePath('false_special_claim.webp');
export const QUESTION_MARK_ICON = getImagePath('what_next_question_mark.webp');
export const UPLOAD_ICON = getImagePath('uploadIocn.webp');
export const SUCCESS_TICK_ICON_SM = getImagePath('tickSuccess.webp');
export const SUCCESS_DARK_GREEN_TICK_ICON_SM = getImagePath('darkGreenTickSuccess.webp');
export const DELETE_ICON = getImagePath('ic_delete.webp');
export const BG_SUCCESS_TICK_ICON = getImagePath('special_claim_success.webp');
export const BG_SUCCESS_TICK_2_ICON = getImagePath('tickSuccess2BG.webp');
export const DOWNLOAD_IMG = getImagePath('downloadImg.webp');
export const DOWNLOAD_ICON = getImagePath('download.webp');
export const GUEST_ICON = getImagePath('guest_icon.webp');
export const EMAIL_ICON = getImagePath('messageIcon.webp');
export const CONTACT_NO_ICON = getImagePath('contactIcon.webp');
export const RED_CROSS_ICON = getImagePath('errorRed.webp');
export const RED_CROSS_BG_ICON = getImagePath('errorRedBgIcon.webp');
export const ALARM_ICON = getImagePath('alarmOn.webp');
export const ON_TRIP_LOGO = getImagePath('ontrip.webp');
export const CANCELLED_LOGO = getImagePath('cancelled.webp');
export const FAILED_LOGO = getImagePath('ic_failed.webp');
export const MMT_PROMISE_LOGO = getImagePath('mmtPromise.webp');
export const CIRCULAR_GREEN = getImagePath('flight_top_card_greenTick.webp');
export const YELLOW_INFO_ICON = getImagePath('flight_booking_info-icon.webp');
export const YELLOW_TICK_ICON = getImagePath('refund_tracker_tickIcon.webp');
export const DELAY_ICON = getImagePath('delayIcon.webp');
export const WC_DELAY_ICON = getImagePath('webcheckinDelayIcon.webp');
export const WC_SUCCESS = getImagePath('webCheckinFlt.webp');
/* Image for CYT Flight Booking Details */
export const CYT_GREEN_TICK_ICON = getImagePath('tickCyt.webp');
export const CYT_CLOSE_ICON = getImagePath('closeIconCyt.webp');
/* CYT IMAGE */
export const HELP_ICON = getImagePath('help.webp');
export const REFUND_GREY = getImagePath('refundGrey.webp');
export const USER_ICON_CANCELLED = getImagePath('userIcon.webp');
export const REFUND_ICON = getImagePath('refundBigIcon.webp');
export const BLUE_LIST_ICON = getImagePath('ic_gotohelpsection.webp');
export const BLUE_LIST_ICON_SUCCESS = getImagePath('uploadProof.webp');
export const BLUE_LIST_ICON_ERROR = getImagePath('uploadError.webp');
export const REFUND_BIG_ICON = getImagePath('refundBigIcon.webp');
export const REJECT_ICON = getImagePath('rejectIcon.webp');
export const UPLOAD_PROOF = getImagePath('uploadProof.webp');
export const BLUE_LIST_2_ICON = getImagePath('blueList2Icon.webp');

export const DIGIT_PROTECTIPN_ICON = getImagePath('digitInsurance.png');
export const INS_SECURE_ICON = getImagePath('insSecureIcon.png');
export const QUOTE_ICON = getImagePath('quoteIcon.png');
export const HEALTH_WARNING_ICON = getImagePath('warningIcon.png');
export const REFUND_CALENDAR = getImagePath('refundCalendar.webp');
export const DGCA_INFO_ICON = getImagePath('dgcaInfoIcon.png');
export const VOID_INFO_ICON = getImagePath('void.webp');
/* Common Images */
export const CHECKBOX_ACTIVE_BLUE_ICON = require('@mmt/legacy-assets/src/ic-check-box-active.webp');
export const CHECKBOX_INACTIVE_ICON = require('@mmt/legacy-assets/src/ic-check-box-inactive.webp');
export const BACK_ARROW_ANDROID = require('@mmt/legacy-assets/src/backArrowAndroid.webp');
export const BACK_ARROW_WHITE_ICON = require('@mmt/legacy-assets/src/back-white.webp');
export const BACK_ARROW_BLACK_ICON = require('@mmt/legacy-assets/src/back.webp');
export const GREEN_TICK_ICON = require('@mmt/legacy-assets/src/greenTick.webp');
export const CROSS_GREY_ICON = require('@mmt/legacy-assets/src/close_icon.webp');
export const CROSS_GREY_ICON_WITHBG = require('@mmt/legacy-assets/src/close.webp');
export const CHEVRON_DOWN_BLUE_ICON = require('@mmt/legacy-assets/src/blue_down.webp');
export const CHEVRON_RIGHT_BLUE_ICON = require('@mmt/legacy-assets/src/iconArrowBlue.webp');
export const CHEVRON_RIGHT_BLUE_ICON2 = require('@mmt/legacy-assets/src/ic_arrow.webp');
export const CHEVRON_UP_BLUE_ICON = require('@mmt/legacy-assets/src/ic_arrow_blue_up_3x.webp');
export const SERVER_ERROR_ICON = require('@mmt/legacy-assets/src/ic_servererror.webp');
export const ANDROID_BACK_ARROW = require('@mmt/legacy-assets/src/backArrowBlack.webp');
export const IOS_BACK_ARROW = require('@mmt/legacy-assets/src/backArrowBlackIOS.webp');
export const BACK_WHITE_ICON = require('@mmt/legacy-assets/src/back-white.webp');
export const RIGHT_ARROW = require('@mmt/legacy-assets/src/ic_arrow.webp');
export const PAY_BY_CARD_ICON = require('@mmt/legacy-assets/src/ic_payIcon_date_change.webp');
export const RIGHT_ARROW_BLUE = require('@mmt/legacy-assets/src/right_arrow_blue.webp');
export const CANCELLED_FLIGHT_ICON = require('@mmt/legacy-assets/src/cancellation.webp');
export const CHANGE_IN_PLAN_ICON = require('@mmt/legacy-assets/src/changeplans.webp');
export const MY_SAFETY_LOGO = require('@mmt/legacy-assets/src/logoMYSafety.webp');
export const WHITE_CROSS_ICON = require('@mmt/legacy-assets/src/cross_white.webp');
export const RUPEES_ICON = require('@mmt/legacy-assets/src/refundIcon.webp');
export const CHANGE_TRV_DT_ICON = require('@mmt/legacy-assets/src/changTvlrIcon.webp');
export const NO_INTERNET_LOGO = require('@mmt/legacy-assets/src/ic_servererror.webp');
export const REFUND_PAYMENT_ICON = require('@mmt/legacy-assets/src/payment_icon.webp');
export const INFO_ICON = require('@mmt/legacy-assets/src/info_icon.webp');
export const INFO_RED_ICON = require('@mmt/legacy-assets/src/info_red.webp');
export const INFO_BLUE_ICON = require('@mmt/legacy-assets/src/info_blue.webp');
export const BLUE_DOWN_ARROW = require('@mmt/legacy-assets/src/blueArrow.webp');
export const BLACK_RIGHT_ARROW = require('@mmt/legacy-assets/src/Arrow.webp');
export const HEADER_ICON = require('@mmt/legacy-assets/src/trip_header_back_icon.webp');
export const HEADER_BACK_ICON = require('@mmt/legacy-assets/src/trip_header_back_icon.webp');
export const DB_SM_ICON = require('@mmt/legacy-assets/src/dbIconSm.webp');
export const BG_BLUE_RIGHT_ARROW = require('@mmt/legacy-assets/src/blueRightArrow.webp');
export const RADIO_INACTIVE_ICON = require('@mmt/legacy-assets/src/radio_blue_inactive.webp');
export const RADIO_BLUE_ICON = require('@mmt/legacy-assets/src/radio_blue_active.webp');
export const BLUE_TICK = require('@mmt/legacy-assets/src/tick.webp');
export const REFRESH_BLUE = require('@mmt/legacy-assets/src/refreshBlue.webp');
export const DOWNLOAD_WITH_LINE = require('@mmt/legacy-assets/src/downloadWithLine.webp');
export const CHAT_ICON = getImagePath('chat-white.webp');
export const ADD_SEAT_ICON = getImagePath('seatImg.png');
export const ADD_MEAL_ICON = getImagePath('mealImg.png');
export const RIGHT_BLUE = require('@mmt/legacy-assets/src/right_arrow_blue.webp');
export const CROSS_ICON = getImagePath('crossIcon1.webp');
export const DELETE_ICON_BLACK = require('@mmt/legacy-assets/src/deleteIcon.webp');
export const STAR_IMAGE = require('@mmt/legacy-assets/src/star.webp');
export const LOCATION_ICON = getImagePath('location_fill.webp');
export const PLUS_CIRCLE = getImagePath('plus-circle.png');
export const DEP_IMG = require('@mmt/legacy-assets/src/departure.webp');
export const DUR_IMG = require('@mmt/legacy-assets/src/duration.webp');
export const MORNING_BLUE_IMG = require('@mmt/legacy-assets/src/ic_morning_blue.webp');
export const MORNING_BLUE_ACTIVE_IMG = require('@mmt/legacy-assets/src/ic_morning_blueActive.webp');
export const NOON_GREY_IMG = require('@mmt/legacy-assets/src/ic_noon_grey.webp');
export const NOON_GREY_ACTIVE_IMG = require('@mmt/legacy-assets/src/ic_noon_greyActive.webp');
export const EVENING_GREY_IMG = require('@mmt/legacy-assets/src/ic_evening_grey.webp');
export const EVENING_GREY_ACTIVE_IMG = require('@mmt/legacy-assets/src/ic_evening_greyActive.webp');
export const NIGHT_GREY_IMG = require('@mmt/legacy-assets/src/ic_night_grey.webp');
export const NIGHT_GREY_ACTIVE_IMG = require('@mmt/legacy-assets/src/ic_night_greyActive.webp');
export const BOOKING_INVOICE = getImagePath('booking_invoice.webp');
export const REFUND_TO_MY_WALLET_ICON = { uri: 'https://imgak.mmtcdn.com/mima/images/mobile/refundMyWalletIcon.png' };
// Duplicate
//const seatsHeaderImg = require('src/Assets/seatRedirect.webp');  SEAT_ORANGE_ICON
// const chkboxicon = require('src/Assets/name_change_chkboxinactive.webp');  CHECKBOX_INACTIVE_ICON2
// const chkboxiconActive = require('src/Assets/name_change_chkboxactive.webp');  CHECKBOX_ACTIVE_BLUE_ICON2
