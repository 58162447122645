import { StyleSheet } from "react-native";
import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';
import { DEFAULT_THEME } from "../../../../../theme/default.theme";


const createStyles = <T extends typeof DEFAULT_THEME>(theme: T) => StyleSheet.create({
    wheelchairBSWrapper: {
      marginHorizontal: -20,
    },
    tabsWrapper: {
      backgroundColor: theme.color.grey22,
      flexDirection: "row",
      zIndex: 1,
      ...getPlatformElevation(1),
    },
    innerWrapper:{
      paddingTop:20,
      paddingHorizontal:20,
    },
    checkBoxWrapper: {
      paddingBottom:20,
      height: 100
    },
    checkBox: {
      marginBottom: 15
    },
    buttonWrapper:{
      flexDirection:'row',
      alignItems:'center',
      justifyContent:'flex-end',
      marginTop: 10
    },
    wheelChiarDeslWrapper: {
      color: theme.color.defaultTextColor,
      marginBottom: 20
    },
    cancelBtn:{
      paddingLeft: 25,
      paddingRight: 25
    },
    cancelBtnText: {
      color: theme.color.azure
    },
    checkBoxContainer: {
      alignItems: 'center'
    },
    holdWrapper: {
      flexDirection: 'column',
      alignItems: 'center', 
      justifyContent: 'center', 
      height: 150
    },
    loadingTxt: {
      marginTop: 12
    },
    sucessFailImg: {
      width: 65, 
      height: 38, 
      marginBottom: 20
    }
  });

  export { createStyles };