import React from 'react';
import { View, StyleSheet, ScrollView } from 'react-native';
import PropTypes from 'prop-types';
import AgeBtn from './AgeBtn';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const ChildAgeSection = (props) => {
  const { ...fonts } = getFont(true);
  const styles = getStyles(fonts);
  const ageList = props.childAgeCandidates && props.childAgeCandidates.map((option) => {
    return (
      <AgeBtn
        cardIndex={props.cardIndex}
        childIndex={props.childIndex}
        ageSelect={props.ageSelect}
        key={option}
        selected={props.age}
        label={option}
      />
    );
  });
  return (
    <ScrollView
      showsHorizontalScrollIndicator={false}
      horizontal
      contentContainerStyle={styles.scrollWrapper}
    >
      <View style={styles.childWrapper}>{ageList}</View>
    </ScrollView>
  );
};

const getStyles = (fonts) => {
  return StyleSheet.create({
    scrollWrapper: {
      flexDirection: 'row',
      marginLeft: -2,
    },
    childWrapper: {
      flexDirection: 'row',
      alignItems: 'center',
      paddingRight: 10,
    },
    container: {
      fontFamily: fonts.italic,
      ...fonts.font12,
      fontStyle: 'italic',
      letterSpacing: 0.3,
      color: colors.lightTextColor,
    },
    bar: {
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
  });
}

ChildAgeSection.propTypes = {
  cardIndex: PropTypes.number.isRequired,
  childIndex: PropTypes.number.isRequired,
  ageSelect: PropTypes.func.isRequired,
  age: PropTypes.string.isRequired,
  childAgeCandidates: PropTypes.array.isRequired,
};

export default ChildAgeSection;
