export default {
  TOGGLE_PAX: 1,
  UPLOAD_DOC: 2,
  REMOVE_DOC: 5,
  LOAD_PAX: 3,
  TOGGLE_SEGMENT: 4,
  SET_REFUND_MODE: 6,
  TOGGLE_CONFIRM: 7,
  UPLOAD_DOC_CLOUD: 8,
  UPLOAD_DOC_STATUS: 9,
  LOAD_ANCILLARY: 10,
};
