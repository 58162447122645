import React from 'react';
import {Text, View} from 'react-native';
import AtomicCss from '../commonStyles/AtomicCss';

const BannerCard = ({bannerText, yellowBg}) => {
        return (
            <View style={yellowBg ? [AtomicCss.travellerText, AtomicCss.marginLeft42] : [AtomicCss.marginLeft42, AtomicCss.travellerText, AtomicCss.travellerTextRed]}>
                <Text style={yellowBg ? [AtomicCss.font14, AtomicCss.boldFont, AtomicCss.yellowText]: [AtomicCss.font14, AtomicCss.boldFont, AtomicCss.redText]}>
                    {bannerText}
                </Text>
            </View>
        );
}

export default BannerCard;

