import { ImageStyle, StyleSheet, TextStyle, ViewStyle } from 'react-native';
import { Fonts, Theme } from 'apps/post-sales/src/types';

interface Styles {
  card: ViewStyle;
  title: TextStyle;
  description: TextStyle;
  linearGradient: ViewStyle;
  textContainer: ViewStyle;
  icon: ImageStyle;
  contentContainer: ViewStyle;
}

const createStyle = (fonts:Fonts,theme:Theme) => {
  const { color } = theme;
  return StyleSheet.create<Styles>({
    card: {
      backgroundColor: color.white,
      marginBottom: 12,
      shadowColor: color.black,
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.25,
      shadowRadius: 3.84,
      elevation: 5,
      flex: 1,
    },
    linearGradient: {
      padding: 18,
    },
    title: {
      fontSize: fonts.fontSize.font16,
      color: color.black,
      marginBottom: 10,
    },
    description: {
      fontSize: fonts.fontSize.font12,
      color: color.defaultTextColor,
      marginBottom: 5,
    },
    textContainer: {
      width: '70%',
    },
    contentContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    icon: {
      width: 80,
      height: 90,
      resizeMode: 'contain',
      margin: -18,
    },
  });
}

export default createStyle;
