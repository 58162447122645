import { ImageStyle, StyleSheet, TextStyle, ViewStyle } from 'react-native';

export enum ACTION_TYPE {
  DOWNLOAD_TICKET = 'DownloadTicket',
  DOWNLOAD_INVOICE = 'DownloadInvoice',
}

export interface Image {
  uri: string;
}

export interface StaticData {
  [key: string]: string;
}

export interface Detail {
  detail: {
    text: string;
    subText: string;
  };
  isLast: boolean;
}
export interface ContactCard {
  card: CardList;
  response: {
    contactDetailInformation: {
      contactDetails: {
        text: string;
        subText: string;
      }[];
    };
  };
}

export interface DelayTimeData {
  trainNumber: string;
  trainname: string;
  scheduledArrivalTime: string;
  actualArrivalTime: string;
  scheduledDepartureTime?: string;
  actualDepartureTime?: string;
  lastUpdatedAt: string;
  expectedSourcePlatformNumber: string;
  expectedDestinationPlatformNumber: string;
  delayInMins: string;
  trainDelayed: boolean;
  lastUpdatedMsg: string;
  sourcePlateformNo: string;
  destinationPlateformNo: string;
}

export interface Response {
  bookingState: {
    state: string;
    subState: string;
  };
  bookingID: string;
  train?: Train[];
  lastUpdatedDelayStatus: string;
  boardingPoint: string;
  fromStationCode: string;
  bnppDetail: BNPPDetails;
}

export interface StaticData {
  irctcPnrText: string;
  bookingIdCapsText: string;
}

export interface DescriptionItem {
  text: string;
  code: string;
}

export interface TopStatusData {
  heading?: string;
  colorList: string[];
  description: DescriptionItem[];
  upcomingOrCompleted: boolean;
}
export interface textObject {
  text?: string;
  subText?: string;
}
export interface FreeCancellation {
  header: string;
  additionalInfo: AdditionalInfo;
}

export interface Passenger {
  currentStatus: string;
  currentStatusMsg: string;
  fullName: string;
  passengerCurrentStatusText: string;
}

export interface StatusData {
  type: string;
  statusType: string;
  PaxName: string;
  status: string;
  bookingState: string;
}

export interface AdditionalInfo {
  headerData: textObject;
  freeCancellationMsg: string;
  refundData: textObject;
  infoList: textObject[];
  footerData: textObject;
}

export interface TrainSchedueData {
  scheduledArrivalTime: string;
  expectedArrivalTime: string;
  scheduledTimeMsg: string;
  expectedTimeMsg: string;
  isTrainDelayed: boolean;
}

export interface SegmentInfo {
  trainScheduleData?: TrainSchedueData;
  freeCancellation?: FreeCancellation;
  trainName: string;
  trainNo: string;
  boardingPointName: string;
  reservationUptoName: string;
  originCityBoardingPointStr: string;
  boardingPointPlateForm: string;
  durationStr: string;
  reservationUptoPlateForm: string;
  reservationInfo: string;
  formattedDepartureTime: string;
  formattedArrivalTime: string;
  noOfAdult: number;
  noOfChild: number;
  passenger: Passenger;
  travelClass: string;
  fromStationCode: string;
  destinationStationCode: string;
  cancellationDetailList: CancellationDetail[]
}

export interface CancellationDetail {
  requestType: string;
  requestNo: string
}

export interface CancellationReason {
  reason: string;
  selected: boolean;
  translatedReason: string;
}

export interface PnrDetails {
  segmentInfo?: SegmentInfo[];
  pnrNo: string;
  chartPreapred: number;
}

export interface Train {
  pnrDetails?: PnrDetails[];
  trainScheduleData: DelayTimeData;
}

export interface Action {
  actionRank: number;
  actionId: string;
  actionType: string;
  actionLabeltext: string;
  actionFamily: string;
  actionLabelSubText? : string;
  actionLabelDesc? : string;
}
export interface TicketCancellationPreview {
  refundBreakup:{
    totalRefund:{
      amount: number;
    }
  };
  cancellationFare: {
    totalPenalty:{
      amount: number;
    }
  };
  refundTitleMessage?: string;
}
export interface TGCancelResponse {
  response:{
    errorText: string
  };
  selectedPaxAmountPaid: {
    text: string;
    amountInDouble: number;
    labelId: string;
    amount: string;
  }[];
  ticketCancellationPreview: TicketCancellationPreview;
  deductionsBreakup: {
    text: string;
    amountInDouble: number;
    labelId: string;
    amount: string;
  }[];
  railTgCardData: RailTGCardData;
  cancellationAssured: boolean;
  refundSuccessActions: Action[];
  goToWalletDeeplink?: string;
}

export interface RailTGCardData {
  refundHeading: string;
  refundInfoText: string;
  refundText: string;
  refundAmountText: string;
  refundAmountTextList: string[]
}

export interface Segment { 
  label?: string; 
  value?: string; 
  order?: number;
  labelId?: string;
  amountSaved?: string;
}
                

export interface CardList {
  heading: string;
  subHeader?: string;
  header?: string;
  subHeading?: string;
  cardId: string;
  cardRank: number;
  showPolicy?: boolean;
  actionList: Action[];
}

export interface LayoutDetail {
  bookingSubState: string;
  cardList?: CardList[];
}

export type CancellationCardProps = {
  handleBottomSheetOverlay: (type: string) => void;
  response: object;
  card: CardList;
  disableCard: boolean;
  tdrRules: object;
  mmtAuth: string;
  loggingTrackInfo: string;
  handleViewTdrClick: () => void;
  onClickEventTracker: (arg: string) => void;
};

export type CancellationPolicyBtmSheetProps = {
  handleBottomOverlay: () => void;
  response: {
    clientDetails: {
      RailCancellationPolicyFooter: string | null;
      RailCancellationPolicyHeader: string | null;
      RailCancellationPolicyFooter2: string | null;
      cancellationPolicyMessage: {
        text: string;
        textColor: string;
        backgroundColor: string;
      }[];
      actionList: Action[];
    };
    layoutDetail: LayoutDetail;
  };
  disableCard: boolean;
  mmtAuth: string;
  loggingTrackInfo: string;
  tripStatus: string;
  tdrRules: object;
  tdrApiError: boolean;
  handleViewTdrClick: () => void;
};

export type FreeCancellationBtmSheetProps = {
  response: object;
  handleBottomOverlay: () => void;
};

export type LiveTrackingInfoProps = {
  response: {
    liveTrainUrl: string;
  };
};

export type PaymentMadeProps = {
  response: {
    couponInformation: {
      couponName: string;
      couponType: string;
      couponMessage: string;
    };
    paymentBreakup: {
      totalPaidAmountBreakUpMap: object;
      paymentBreakupAmountPaid: {
        text: string;
        value: string;
      };
    };
  };
  card: CardList;
  pageName: string;
};

export type PnrPaxStatusCardProps = {
  data: Array<{
    type: string;
    statusType: string;
    PaxName: string;
    status: string;
  }>;
};

export type TrainScheduleCardProps = {
  response: {
    lastUpdatedDelayStatus: string;
    train: Train[];
    bookingID: string;
  };
  trainScheduleData: DelayTimeData;
  latestTrainData: LatestTrainData;
};

export type ArrivalCardProps = {
  heading?: string;
  time?: string;
  timeStatus?: string;
  delay?: boolean;
};

export type LatestTrainData = {
  data?: DelayTimeData;
  error?: boolean;
};

export type TrainInfoCardProps = {
  response: {
    train: Train[];
  };
  latestTrainData: LatestTrainData;
};

export type RailBookingDetails = {
  bookingID: string;
  tgsCancellationReasonList: CancellationReason[];
  train: Train[];
  railRacTgInfo: any;
}; 

export type PNRstatusCardProps = {
  response: {
    lastUpdatedPnrStatus: string;
    bookingID: string;
    train: Train[];
  };
  fetchPnrStatusInfo: (
    pnr: string,
    bookingId: string,
    mmtAuth: string,
    loggingTrackInfo: string,
  ) => void;
  clearStore: () => void;
  onClickEventTracker: (arg: string) => void;
  mmtAuth: string;
  loggingTrackingInfo: string;
  lastUpdateTime: string;
  lastbookingId: string;
};


export type RefundTrackerCardProps = {
  response: {
    cancellationDetailList: CancellationDetail[];
    train: Train[]
  };
  card: CardList;
  bookingId: string;
}

export interface PaymentPendingCardProps {
  pageName: string;
  card: CardList;
  response: Response;
  handleOpenBottomOverlay: (action: string) => void;
  handlePendingPayClick: (paymentCheckoutUrl: string) => void;
}

export interface BNPPDetails {
  paymentCheckoutUrl: string
  paymentPendingCardData: {
    viewTnCText : string
    viewTnCDeeplink : string
    dataList: {
      text: string;
      boldTextColor: string;
    }[];
  };
  paymentPendingOverlayData: {
    heading: string;
    dataList: { text: string }[];
    priceBreakup: PriceBreakup[];
    actionList: Action[];
  };
}

export interface PriceBreakup {
  labelId: string;
  text: string;
  amount: string;
  subList: PriceBreakup[];
}

export interface PaymentPendingOverlayProps {
  pageName: string;
  response: Response;
  handleBottomOverlay: () => void;
  handlePendingPayClick: (paymentCheckoutUrl : string) => void;
}



export interface Theme {
  color: {
    defaultTextColor: string;
    black: string;
    azure: string;
    white: string;
    lightYello: string;
    red17 : string;
    lightGray : string;
    lightGreen18 : string;
    black74 : string
  };
}

export interface Fonts {
  fontSize: { [key: string]: number };
  boldFontFamily: { [key: string]: object };
  regularFontFamily: { [key: string]: object };
  blackFontFamily: { [key: string]: object };
}

export interface Styles {
  heading: ViewStyle;
  topLine: ViewStyle;
  titleText: TextStyle;
  descriptionText: TextStyle;
  headingText: TextStyle;
  overlayDescriptionText: TextStyle;
  subHeadingText: TextStyle;
  crossIconStyle: ImageStyle;
  crossIconWrapper: ViewStyle;
  bodySection: ViewStyle;
  iconStyle: ImageStyle;
  marginBt0: ViewStyle;
}
