import { StyleSheet } from 'react-native';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
import { Fonts, Theme } from '../../types';

const getStyle = (fonts : Fonts, theme : Theme) => {
  const {color} = theme;
  return StyleSheet.create({
    heading: {
      color: color.black,
      ...fonts.blackFontFamily,
      fontSize: fonts.fontSize.font16,
    },
    viewDetailStyle: {
      color: color.azure,
      ...fonts.boldFontFamily,
      fontSize: fonts.fontSize.font12,
    },
    payPeningCard: {
      ...getPlatformElevation(2),
      backgroundColor: color.white,
      borderRadius: 16,
      padding: 12,
    },
    detailsSection: {
      marginBottom: 12,
      marginLeft: 30,
    },
    payPendingIconStyle: {
      width: 28,
      height: 28,
      marginRight: 5,
    },
    remainingDaysText: {
      color: color.lightYello,
      fontSize: 14,
      ...fonts.blackFontFamily,
    },
    remainingHoursText: {
      color: color.red17,
      fontSize: 14,
      ...fonts.blackFontFamily,
    },
    buttonTxtStyle: {
      fontSize: fonts.fontSize.font12,
      ...fonts.boldFontFamily,
      textAlign: 'center',
    },
    buttonStyle: {
      borderRadius: 10,
      paddingHorizontal: 20,
      paddingVertical: 10,
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'center',
      ...getPlatformElevation(2),
    },
    viewTncText: {
      color: color.azure,
      ...fonts.boldFontFamily
    }
  });
};

export const htmlstyles = (fonts : Fonts, theme : Theme, boldTextColor : string) => {
  const {color} = theme;
  return StyleSheet.create({
    q: {
      color: boldTextColor,
      ...fonts.boldFontFamily
    },
    b: {
      ...fonts.boldFontFamily
    },
  });
};

export default getStyle;
