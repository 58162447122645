import React from 'react';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import { View, Image, Text, TouchableOpacity } from 'react-native';
import { getFont } from '../../../PostSalesStyles';
import { useTheme } from '../../../theme/theme.context';
import createStyles from './OfferCardCss';
import { OfferCardProps } from './hotelType';

const OfferCard: React.FC<OfferCardProps> = (props) => {
  const { buttonText, offerImg, deeplink, omnitureTrackHandler } = props;

  const fonts = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);

  const handleDeeplink = () => {
    omnitureTrackHandler('ExploreCTA_CrossSell');
    GenericModule.openDeepLink(deeplink);
  };

  return (
    <>
      <View>
        <View style={styles.addOfferIcon}>
          <Text style={styles.plusText}>+</Text>
        </View>
        {offerImg ? <Image source={{ uri: offerImg }} style={styles.offerImgCard} /> : null}
      </View>
      {buttonText ? (
        <TouchableOpacity
          style={styles.transparentBtn}
          activeOpacity={0.8}
          onPress={handleDeeplink}
        >
          <Text style={[AtomicCss.azure, fonts.boldFontFamily, fonts.font12]}>{buttonText}</Text>
        </TouchableOpacity>
      ) : null}
    </>
  );
};

export default OfferCard;
