const GIFTCARD_ROUTE_CONFIG = [
    {
      key: 'giftCardBookingDetail',
      component: () => require('./GiftCardBookingDetail').default
    },
    {
      key: 'viewGiftCardPaymentBreakup',
      component: () => require('./components/GiftCardPaymentCardDetails').default
    }
  ];
  
  export default GIFTCARD_ROUTE_CONFIG;