import { StyleSheet } from 'react-native';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';

export default (theme) =>
  StyleSheet.create({
    contentWrapper: {
      backgroundColor: theme.color.greyBookedSeat,
    },
    topSection: {
      flex: 0.9,
    },
    bottomSection: {
      flex: 0.1,
    },
    whiteColor: {
      backgroundColor: theme.color.grey22,
    },
    topStickySection: {
      ...getPlatformElevation(2),
      backgroundColor: theme.color.grey22,
      zIndex: 10,
    },
    cardWrapper: {
      marginBottom: 78,
    },
    pageWrapper: {
      marginTop: 16,
      marginHorizontal: 12,
      borderRadius: 8,
      marginBottom: 12,
      borderBottomWidth: 0,
      borderBottomColor: 'transparent',
    },
    buttonBottomWrapper: {
      paddingHorizontal: 11,
      width: '100%',
      flex: 1,
      textAlign: 'center',
      alignItems: 'center',
    },

    flatBtnWrapper: {
      borderRadius: 4,
    },
    flatBtn: {
      borderRadius: 8,
      paddingHorizontal: 20,
      paddingVertical: 14,
    },
    selectModeWrapper: {
      position: 'relative',
      zIndex: 2,
    },
    uploadDocWrapper: {
      position: 'relative',
      zIndex: 4,
      top: 0,
    },
    UploadDocumentsOldWrapper: {
      position: 'relative',
      zIndex: 6,
    },
    stepBarWrapper: {
      paddingHorizontal: 5,
      marginBottom: 24,
    },
    disabledLine: {
      backgroundColor: theme.color.grey24,
      width: 36,
      height: 6,
      borderRadius: 2,
      marginRight: 4,
    },
    activeLine: {
      backgroundColor: theme.color.primary,
    },
    activeDot: {
      backgroundColor: theme.color.primary,
    },
    loaddingWrapper: {
      width: '98%',
      height: 44,
      borderRadius: 4,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    activityIndicator: {
      transform: [{ scale: 1.2 }],
    },
    pageButtonStyle: {
      minWidth: '98%',
    },
  });
