import React from 'react';
import PropTypes from 'prop-types';
import {Animated, Easing, Text, TouchableOpacity, View} from 'react-native';
import getStyles from '../hotel/styles/PersistenetToasCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getFont } from '../PostSalesStyles';

class PersistentToast extends React.Component {
  constructor(props) {
    super(props);
    this.fonts = getFont(true);
    this.styles = getStyles(this.fonts);
    this.state = {
      toastPosition: new Animated.Value(0)
    };
  }

  componentDidMount() {
    const {bottom, delay, duration} = this.props;
    this.startAnimate(bottom, duration, delay);
  }

  startAnimate(bottom, duration, delay) {
    Animated.timing(this.state.toastPosition, {
      toValue: bottom,
      easing: Easing.easeInOut,
      duration,
      delay
    })
      .start();
  }

  render() {
    return (
      <View>
        <Animated.View style={[this.styles.toastWrapper, {bottom: this.state.toastPosition}]}>
          <View
            style={[this.styles.toastTextWrapper, AtomicCss.flexRow, AtomicCss.spaceBetween, AtomicCss.alignCenter]}>
            <Text style={this.styles.toastText}>{this.props.toastText}</Text>
            {
              this.props.ctaText &&
              <TouchableOpacity onPress={() => this.props.clickHandler()}
                                activeOpacity={0.6} style={this.styles.hostMessageView}><Text
                style={this.styles.toastViewText}>{this.props.ctaText}</Text></TouchableOpacity>
            }
          </View>
        </Animated.View>
      </View>
    );
  }
}

PersistentToast.propTypes = {
  bottom: PropTypes.number.isRequired,
  delay: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
  toastText: PropTypes.string.isRequired,
  ctaText: PropTypes.string,
  clickHandler: PropTypes.func
};

PersistentToast.defaultProps = {
  ctaText: null,
  clickHandler: null
}

export default PersistentToast;

