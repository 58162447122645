import React from 'react';
import { Image, Text, TouchableOpacity } from 'react-native';
import { downloadIcon } from 'apps/post-sales/src/cab/CabImageConstants';
import { useTheme } from '../../../../../theme/theme.context';
import { getFont } from '../../../../../PostSalesStyles';
import getStyle from './styles';
import {  InvoiceListRowProps } from 'apps/post-sales/src/cab/types';
import { allInvoiceClickHandler } from 'apps/post-sales/src/utils/PostSaleUtil';
import CabBookingTrackingHelper from '../../../../CabBookingTrackingHelper';
import { PAGE_NAME } from '../../../../CabBookingDetailsConstant';


const InvoiceListRow: React.FC<InvoiceListRowProps> = ({
    item,
    downloadingInvoice,
    downloadFailedText,
  }) => {
    const { fsStyle, ...fonts } = getFont(false);
    const { psTheme } = useTheme();
    const styles = getStyle(fonts, psTheme);

    const handleInvoiceClick = () => {
        const allInvoiceData = {
            invoiceData : item,
            downloadingInvoice,
            downloadFailedText,
            toastDtlsStyles : styles.toastDtls,
            toastTextStyles : styles.toastText
        }
        CabBookingTrackingHelper.trackClickEvent(PAGE_NAME.TRIP_DETAILS, `Mob_Cabs_MIMA_Documents_${item?.invoiceType}`);
        allInvoiceClickHandler(allInvoiceData);
    }
    return (
      <TouchableOpacity
        style={styles.downloadInvoiceSection}
        onPress={handleInvoiceClick}
      >
        <Text style={styles.titleText}>{item?.title}</Text>
        <Image style={styles.downloadIcon} source={downloadIcon} />
      </TouchableOpacity>
    );
  };

  export default InvoiceListRow;