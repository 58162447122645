import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {View, TouchableOpacity, StyleSheet} from 'react-native';
import ContactListItemHeader from './ContactListItemHeader';
import ContactListItemDetails from './ContactListItemDetails';

class ContactListItem extends PureComponent {
  static navigationOptions = {
    header: null
  };

  render() {
    return (
      <View
        style={this.props.isActive ? [styles.CollapsedContainer1] : [styles.CollapsedContainer1]}
      >
        <TouchableOpacity
          onPress={() => this.props.tabbingFn(this.props.index, !this.props.isActive)}
          activeOpacity={0.9}
        >
          <ContactListItemHeader
            {...this.props}
            tabbing={this.props.isActive}
          />
        </TouchableOpacity>

        {
          this.props.isActive && <ContactListItemDetails
            {...this.props}
          />
        }

      </View>
    );
  }
}

const styles = StyleSheet.create({
  CollapsedContainer1: {
    backgroundColor: '#fff',
    borderBottomWidth: 6,
    borderBottomColor: '#ededed'
  }
});

ContactListItem.propTypes = {
  isActive: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  tabbingFn: PropTypes.func.isRequired
};

export default ContactListItem;
