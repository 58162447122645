import { StyleSheet } from 'react-native';
import {colors} from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';


const styles = StyleSheet.create({
  cardBorder:{
    borderTopColor: colors.lightGrey3,
    borderBottomColor: colors.lightGrey3,
    borderTopWidth: 1,
    borderBottomWidth: 1
  },
  infoCardWrapper:{
    backgroundColor: colors.grey22,
    ...getPlatformElevation(2),  
    marginBottom: 1,
    paddingVertical: 12,
    paddingHorizontal: 16,
  },
  bmfInfoTextWrapper:{
    backgroundColor: colors.creamWhite,
    borderColor: colors.grey26,
    borderWidth: 1,
    paddingVertical: 12,
    paddingHorizontal: 14,
    borderRadius: 6,
    marginBottom: 12
  },
  bmfLogoStyle:{
    width: 15,
    height: 15,
  },
  bmfTextLogoStyle:{
    width: 90,
    height: 15,
    marginBottom: 5,
    alignSelf: 'flex-end',
  }
});

export default styles;