import React from 'react';
import {Platform, ScrollView, StyleSheet, Text, View} from 'react-native';
import PropTypes from 'prop-types';
import Actions from '../../navigation/postSalesNavigation';
import StickyHeader from '../../hotel/details/components/StickyHeader';
import AcmeCancellationReviewStrip from './AcmeCancellationReviewStrip';
import AtomicCss from '../details/AtomicCss';
import CancellationBreakupDetails from './CancellationBreakupDetails';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import {isNetworkAvailable} from '@mmt/legacy-commons/Common/utils/AppUtils';
import {getCommonHeaders, HOTEL_BOOKING_DETAILS_URL} from '../../utils/NetworkUtils';
import SimpleHeader from '@mmt/legacy-commons/Common/Components/Header/SimpleHeader';
import NoInternetView from '@mmt/legacy-commons/Common/Components/Error/NoInternetView';
import ProgressView from '@mmt/legacy-commons/Common/Components/Progress/ProgressView';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import FullWidthButton from '../../hotel/details/ActionButton/FullWidthButton';
import {colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import {openAcmeCancellationThankYou} from '../utils/AcmeBookingDetailUtil';
import BasePage from '../../Common/PostSalesBasePage';
import AcmeRefundOverlay from './AcmeRefundOverlay';
import AcmeBookingTrackingHelper from '../utils/AcmeBookingTrackingHelper';
import {headerIcon} from '../utils/AcmeImageConstant';

const successLoadTrackingPageName = 'Mob:customer support:ACME:Fullcancellation';
const errorTackingPagename = 'Mob:customer support:ACME:TripdetailspageError';

class AcmeCancellationReview extends BasePage {
  static navigationOptions = {
    header: null
  };

  constructor(props) {
    super(props, 'acmeCancellationReview');
    this.overlayshown = false;
    this.state = {
      responseJson: null,
      overlay: false,
      viewState: ViewState.LOADING
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.fetchJSONAsync();
  }

  onHardBackPress = () => {
    if (this.overlayshown) {
      this.refundpopup();
      return true;
    }
    return false;
  };

  async fetchJSONAsync() {
    try {
      const hasNetwork = await isNetworkAvailable();
      if (!hasNetwork) {
        this.setState({viewState: ViewState.NO_INTERNET});
        return;
      }
      const response = await
        fetch(this.props.url, {
          method: 'POST',
          headers: await getCommonHeaders(this.props.mmtAuth, this.props.loggingTrackInfo),
          body: JSON.stringify(this.props.cancellationPreviewRequest)
        });
      if (response && response.ok) {
        const body = await
          response.json();
        if (body && body.status) {
          this.setState({
            responseJson: body,
            viewState: ViewState.SHOW_DETAIL
          });
          this.trackLoadEvent();
        } else {
          this.setState({viewState: ViewState.ERROR});
        }
      } else {
        this.setState({viewState: ViewState.ERROR});
      }
    } catch (error) {
      this.setState({viewState: ViewState.ERROR});
      console.log(error);
    }
  }

  render() {
    return (
      <View style={{flex: 1}}>
        {this.renderStateWise()}
      </View>

    );
  }

  trackLoadEvent() {
    AcmeBookingTrackingHelper.trackLoadEvent(successLoadTrackingPageName, this.props.acmeBookingResponse);
  }

  trackError(errorReason) {
    AcmeBookingTrackingHelper.trackErrorEvent(errorTackingPagename, errorReason);
  }

  renderStateWise() {
    switch (this.state.viewState) {
      case ViewState.LOADING:
        return this.renderProgressView();
      case ViewState.NO_INTERNET:
        return this.renderProgressView();
      case ViewState.ERROR:
        return this.renderErrorView();
      default:
        return this.renderPage();
    }
  }

  renderPage() {
    return (
      <View style={{flex: 1}}>
        <View style={styles.CancellationWrapper}>
          <StickyHeader
            iconSrc={headerIcon}
            title="Cancel Booking"
            headerColor={['#ffffff', '#ffffff']}
            titleStyle={[styles.DefaultText, styles.blackFont]}
            subTitle={this.getSubTitleForStickyHeader()}
            subTitleStyle={[styles.DefaultText, styles.font12]}
            onBackPress={this.goBack}
          />
          {this.renderMainContent()}
        </View>
        {this.state.overlay ? this.renderConfirmationPopup() : null}
      </View>
    );
  }

  renderNoNetworkView = () => (
    <View style={{flex: 1}}>
      <SimpleHeader
        title="Back"
        iconPress={this.goBack}
      />
      <NoInternetView
        onRetry={() => {
          this.setState({viewState: ViewState.LOADING});
          this.fetchJSONAsync(this.props.url);
        }}
      />
    </View>);

  renderProgressView = () => (<ProgressView message="Loading..." />);

  renderErrorView = () => (
    <View style={{flex: 1}}>
      <SimpleHeader
        title="Back"
        iconPress={this.goBack}
      />
      <ErrorView
        showRetry
        onRetry={() => {
          this.setState({viewState: ViewState.LOADING});
          this.fetchJSONAsync(this.props.url);
        }}
      />
    </View>);


  renderMainContent() {
    return (
      <View style={styles.CancellationWrapper}>
        <ScrollView style={AtomicCss.greyBg}>
          <AcmeCancellationReviewStrip
            step1="REVIEW REFUND"
            step2={this.getButtonText()}
            step1Active
          />
          <View style={[styles.PartCancellationHead, AtomicCss.elevation1]}>
            <Text style={[AtomicCss.blackFont, AtomicCss.blackText, AtomicCss.font16]}>
              Refund for Cancelling Booking
            </Text>
          </View>
          <CancellationBreakupDetails cancellationPreviewResponse={this.state.responseJson} />
        </ScrollView>
        <FullWidthButton
          buttonText={this.getButtonText()}
          clickHandler={this.fullButtonConfirmClick}
        />
      </View>
    );
  }

  getButtonText() {
    if (this.state.responseJson.refundAmount > 0) {
      return 'CHOOSE REFUND MODE';
    }
    return 'CONFIRM CANCELLATION';
  }

  renderConfirmationPopup() {
    return (
      <AcmeRefundOverlay
        backPress={this.refundpopup}
        confirmClicklistener={this.overlayConfirmCancelClick}
      />
    );
  }

  getTotalRefundAmount() {
    const {customerCurrency, refundAmount} = this.state.responseJson;
    return `${customerCurrency} ${refundAmount} `;
  }

  fullButtonConfirmClick = () => {
    if (this.state.responseJson.refundAmount > 0) {
      Actions.acmeCancellationRefundMode({
        refundAmount: this.getTotalRefundAmount(),
        cancellationRefundMode: this.state.responseJson.cancellationRefundMode,
        headerTitle: 'Cancel Booking',
        headerSubTitle: this.getSubTitleForStickyHeader(),
        acmeBookingResponse: this.props.acmeBookingResponse
      });
    } else {
      this.refundpopup();
    }
    AcmeBookingTrackingHelper.trackClickEvent(successLoadTrackingPageName, 'MI_ACME_CONFIRM_CANCEL');
  };

  overlayBackPress = () => {
    this.refundpopup();
    AcmeBookingTrackingHelper.trackClickEvent(successLoadTrackingPageName, 'MI_ACME_OVERLAY_BACK');
  };

  refundpopup = () => {
    this.overlayshown = !this.state.overlay;
    this.setState({overlay: !this.state.overlay});
  };

  getSubTitleForStickyHeader() {
    return this.props.acmeBookingResponse.acmeBookingInfo.acmeBookingDetails[0].productname;
  }

  goBack = () => {
    Actions.pop();
  };

  overlayConfirmCancelClick = () => {
    openAcmeCancellationThankYou(this.props.acmeBookingResponse, 'RefundToCustomer');
    AcmeBookingTrackingHelper.trackClickEvent(successLoadTrackingPageName, 'MI_ACME_OVERLAY_CONFIRM_CANCEL');
  };

  getbuttonAction = () => {
    const action = {};
    action.actionLabeltext = 'OKAY, CANCEL';
    return action;
  };
}

const styles = StyleSheet.create({
  CancellationWrapper: {
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: '#f2f2f2'
  },
  PartCancellationHead: {
    backgroundColor: '#ffffff',
    paddingVertical: 18,
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#ddd'
  },
  refundableTxt: {
    color: '#1a7971',
    fontSize: 12,
    flex: 1
  },
  refundableGreen: {
    width: 20,
    height: 18,
    marginRight: 10,
    marginTop: 3
  },
  refundableContainer: {
    flexDirection: 'row',
    backgroundColor: '#fff',
    borderBottomColor: '#e7e7e7',
    borderBottomWidth: 1,
    paddingHorizontal: 16,
    paddingVertical: 12
  },
  refundableTxtRed: {color: '#eb2026'},
  refundPopup: {
    backgroundColor: '#fff',
    paddingVertical: 40,
    paddingHorizontal: 20,
    zIndex: 10
  },
  flexRow: {
    flexDirection: 'row'
  },
  mar15Bt: {
    marginBottom: 15
  },
  BlackText: {
    color: colors.black
  },
  regularFont: {
    fontFamily: fonts.regular
  },
  mar30Bt: {
    marginBottom: 30
  },
  DefaultText: {
    color: colors.defaultTextColor
  },
  lineHeight20: {
    lineHeight: 20
  },
  backText: {
    color: '#008cff',
    fontFamily: fonts.bold,
    fontSize: 16,
    paddingTop: 20
  },
  cancelIcon: {
    width: 65,
    height: 40,
    marginRight: 15
  },
  font24: {
    fontSize: 24
  },
  transperantBgrnd: {
    ...Platform.select({
      ios: {
        backgroundColor: 'transparent'
      }
    })
  },
  buttonContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  }

});

AcmeCancellationReview.propTypes = {
  url: PropTypes.string.isRequired,
  mmtAuth: PropTypes.string.isRequired,
  loggingTrackInfo: PropTypes.string.isRequired,
  cancellationPreviewRequest: PropTypes.object.isRequired,
  acmeBookingResponse: PropTypes.object.isRequired
};

export default AcmeCancellationReview;
