import { httpPost } from '../../../../../data/api';
import { DIGIT_INSURANCE_HOLD_API } from '../../../../../utils/NetworkUtils';

export async function fetchInsHoldDetail(bookingId, uniqueCrId) {
  const request = {
    bookingId: bookingId,
    crID: uniqueCrId,
  };
  const data = await httpPost(DIGIT_INSURANCE_HOLD_API, JSON.stringify(request), {
    uniqueIdType: 'INSURANCE_HOLD_API',
  });

  return data;
}

export default fetchInsHoldDetail;
