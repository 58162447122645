import React from 'react';
import {Text, View} from 'react-native';
import PropTypes from 'prop-types';
import styles from '../../../../flights/modules/details/components/PaymentPriceBreakup/PayModeBreakup/PayModeBreakupCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import ZoomCarConstant from "../../../utils/ZoomCarConstants";

class PayModeBreakup extends React.Component {

    render() {
        const {paymentBreakup} = this.props
        let paidAmountDetail = paymentBreakup.filter(k => k.key === ZoomCarConstant.TOTAL_PAID_KEY)[0];
        return (
            <View style={[AtomicCss.whiteBg, AtomicCss.marginBottom10]}>
                <View style={[styles.CardContentFull]}>
                    <View style={styles.cardHeadingWrapper}>
                        <Text style={styles.cardHeading}>Payment Breakup</Text>
                    </View>
                    <View style={styles.fareBreakupInnerSection}>

                        {this.getPayModeViews(paymentBreakup)}
                    </View>

                    <View style={[styles.totalPrice]}>
                        <Text style={[styles.totalPricetxt, AtomicCss.font16]}>TOTAL PAID</Text>
                        <Text style={[styles.totalPricetxt, AtomicCss.font18]}>
                            {`₹ ${paidAmountDetail.amount}` }
                        </Text>
                    </View>
                </View>
            </View>
        );
    }

    getPayModeViews(paymentBreakupList) {
        const views = [];
        paymentBreakupList && paymentBreakupList.map((payment, key) =>  {
            if (payment.key === ZoomCarConstant.TOTAL_PAID_KEY) {
                return;
            }
            views.push(
                <View key={key} style={[styles.rowSection]}>
                    <View style={AtomicCss.flexRow}>
                        <View>
                            <Text style={[styles.fareBkHeadingTxt, AtomicCss.marginBottom3]}>{payment.modeLabel}</Text>
                        </View>
                    </View>
                    <View>
                        <Text style={[styles.fareBkHeadingTxt, AtomicCss.marginBottom3]}>
                            {`₹ ${payment.amount} `}
                        </Text>
                    </View>
                </View>
            )})
        return views;
    }
}

export default PayModeBreakup;

PayModeBreakup.propTypes = {
    paymentBreakup: PropTypes.object.isRequired
};

