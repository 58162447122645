import React from 'react';
import PropTypes from 'prop-types';
import {Text, TouchableOpacity, StyleSheet} from 'react-native';

import LinearGradient from 'react-native-linear-gradient';
import AtomicCss from '../../commonStyles/AtomicCss';
import { getFont } from '../../../PostSalesStyles';

const { fsStyle, ...fonts } = getFont(true);
const RecBlueBtn = ({btntext, onPressHandler}) => (
  <TouchableOpacity onPress={() => onPressHandler()}>
    <LinearGradient start={{x: 1.0, y: 0.0}} end={{x: 0.0, y: 1.0}} colors={['#065af3', '#53b2fe']} style={[styles.RecBlueBtn, AtomicCss.marginBottom10]}>
      <Text style={[AtomicCss.whiteText, AtomicCss.CenterText,
        fsStyle.font12, fonts.boldFontFamily, AtomicCss.bgTransparent]}
      >
        {btntext}
      </Text>
    </LinearGradient>
  </TouchableOpacity>
);
const styles = StyleSheet.create({
  RecBlueBtn: {
    paddingVertical: 18,
    width: '92%',
    marginHorizontal: 15,
    borderRadius: 3,
    overflow: 'hidden'
  }
});

RecBlueBtn.propTypes = {
  onPressHandler: PropTypes.func.isRequired,
  btntext: PropTypes.string.isRequired
};

export default RecBlueBtn;
