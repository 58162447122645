import React from 'react';
import { getStaticData } from 'apps/post-sales/src/staticData/staticData';
import { BUTTON_VARIANT, RNButton, RNHeading, RNImage, RNLabel, RNView } from 'core-rn-ui-factory';
import createStyles from './styles';
import SimpleHeader from '@mmt/legacy-commons/Common/Components/Header/SimpleHeader.ios';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';

// Define interfaces for props and data structure
interface ErrorPageProps {
  goBack: () => void;
  handleClick: (actionId: string) => void;
  data?: ErrorData;
}

interface ErrorData {
  title: string;
  heading: string;
  descriptions: string[];
  imageUrl: string;
  actionList: Action[];
}

interface Action {
  actionTitle: string;
  actionId: string;
}

const ErrorPage: React.FC<ErrorPageProps> = ({ goBack, handleClick, data }) => {
  const staticData = getStaticData();
  const defaultData: ErrorData = {
    title: 'Internal Server Error',
    heading: staticData.errorText.somethingWentWrongError,
    descriptions: [staticData.flightErrorText.tryAgainText],
    imageUrl: 'https://imgak.mmtcdn.com/mima/images/mobile/errorImage.webp', //todo
    actionList: [
      {
        actionTitle: staticData.tryAgainText,
        actionId: 'REFRESH_PAGE',
      },
    ],
  };
  const { title, heading, descriptions, imageUrl, actionList } = data || defaultData;
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme.color);
  return (
    <RNView style={styles.container}>
      <SimpleHeader title={title} iconPress={goBack} />
      <RNView style={styles.noInternet}>
        <RNImage source={imageUrl} style={styles.noInternetImage} testID="errorImage" />
      </RNView>
      <RNView style={styles.serverErrorFooterContainer}>
        <RNView style={{ marginBottom: 8 }}>
          <RNHeading size="medium" style={styles.noInternetTitle} testID="">
            {heading}
          </RNHeading>
          <RNView>
            {descriptions?.map((desc, index) => (
              <RNLabel size="baseW1" style={styles.noInternetSubtitle} testID="" key={index}>
                {desc}
              </RNLabel>
            ))}
          </RNView>
        </RNView>
        {actionList?.map((action, index) => (
          <RNButton
            key={index}
            buttonType={BUTTON_VARIANT.PRIMARY}
            width={190}
            testID=""
            text={action.actionTitle}
            clickHandler={() => handleClick(action.actionId)}
          />
        ))}
      </RNView>
    </RNView>
  );
};

export default ErrorPage;