import React from 'react';
import { getActivitySummaryData } from './activityCardAdapter';
import {
    ActionsContent,
    DescriptionSection,
    MainContent,
    SummaryContainer,
    CardInfoSection,
    HeaderSection,
    JourneyStartsInfoText,
    WhereToGoTopImage,
    StatusInfoSection,
} from '../cardElements';
import MSRRefundInfoCard from '../MSRRefundInfoCard';
import { ActivityData, MsrTicket } from '../cardInterfaces';
interface ActivitySummaryCardProps {
    response: ActivityData,
    msrTicket: MsrTicket,
    isUpcomingOrOnTripFirstBooking: boolean,
    topImage: string
}

function ActivitySummaryCard({ response, msrTicket, isUpcomingOrOnTripFirstBooking, topImage }: ActivitySummaryCardProps) {
    const cardData = getActivitySummaryData(response, msrTicket);
    const {
        iconUrl,
        cityName,
        myRequestsCard,
        productName,
        startDateText,
        dateText,
        startsIn,
        actions,
        openDetails,
        dateTimeWithPaxInfo,
        lobName,
        bookingId,
        status,
        infoCardStatusText,
        infoCardStatusCTA,
        handleInfoPress } = cardData || {};

    return (
        <SummaryContainer bookingId={response.bookingID}>
            {topImage && isUpcomingOrOnTripFirstBooking &&
                <WhereToGoTopImage topImageText={startsIn} topImage={topImage} />
            }
            <MainContent onPress={openDetails}>
                <DescriptionSection>
                    <CardInfoSection
                        name={cityName}
                        passengerDetails={dateTimeWithPaxInfo}
                    />
                    <HeaderSection iconUrl={iconUrl} name={productName} />
                    <JourneyStartsInfoText startsOnText={dateText} endDate={startDateText} />
                    {infoCardStatusText && <StatusInfoSection statusText={infoCardStatusText} statusCta={infoCardStatusCTA} handleInfoPress={handleInfoPress} />}
                </DescriptionSection>
            </MainContent>
            <ActionsContent actions={actions} isInfoCardShown={!!infoCardStatusText}/>
            {myRequestsCard && <MSRRefundInfoCard data={myRequestsCard} lobName={lobName} status={status} bookingId={bookingId} />}
        </SummaryContainer>
    );
}

export default ActivitySummaryCard;