import React, { useState, useCallback } from 'react';
import { View, Text, Image, TouchableOpacity, ScrollView, Platform, Linking } from 'react-native';
import Actions from '../../../../../../navigation/postSalesNavigation';
import Button, { BUTTON_VARIANT } from '../../../../../../Common/BaseButton';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import stylesOverlay from './SecureYourTripOverlayCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';

import FlightBookingModule from '@mmt/legacy-commons/Native/FlightBookingModule';

import { INS_SECURE_ICON, BACK_WHITE_ICON, CROSS_ICON } from '../../../../../FlightImageConstants';
import { ViewState } from '../../../../../../PostSalesConstant';

import CustomerStories from './CustomerStories';
import PageLoader from '../../../../../../Common/Cancellation/PageLoader';
import FlightBookingTrackingHelper from '../../../helper/FlightBookingTrackingHelper';
import fetchInsHoldDetail from '../insuranceHoldApi';

import { getStaticData } from '../../../../../../staticData/staticData';
import { getFont } from '../../../../../../PostSalesStyles';

const SecureYourTripOverlay = React.memo((props) => {
  const { fsStyle, ...fonts } = getFont(true);
  const insuranceListingReview = get(props, 'insuranceDetailRes.insuranceListingReview', []);
  const currencySign = get(props, 'insuranceDetailRes.currencySign', '');
  const title = get(insuranceListingReview, 'title');
  const subTitle = get(insuranceListingReview, 'subtitle', '');
  const insuranceBenefits = get(props, 'insuranceDetailRes.insuranceBenefits', []);
  const insuranceViewCount = get(props, 'insuranceDetailRes.insuranceViewCount', 1);

  const actionList = get(insuranceListingReview, 'actions', []);
  const payButton = actionList.filter((item) => item.type === 'Buttontype');
  const terms = actionList.filter((item) => item.type === 'CheckBox');
  const desc = terms && terms.length > 0 ? terms[0].desc : '';
  const clickableText = desc.length >= 2 ? desc[1] : '';
  let result = [];
  if (desc && !!desc.length) {
    result.push(desc[0]);
    if (desc.length >= 3) {
      result.push(desc[2]);
    }
  }

  const shoulShowAllBenefit = get(props, 'insuranceDetailRes.showAllBenefits', false);
  const payBtnDec = get(props, 'insuranceDetailRes.payBtnDesc', '');
  const [viewState, setViewState] = useState(ViewState.LOADED);
  const [holdResponse, setHoldResponse] = useState(null);
  const [showAllBenfits, setShowAllBenfits] = useState(shoulShowAllBenefit);
  const {
    digitInsuranceText: { moreBenefitsText },
  } = getStaticData();

  const onTndcClick = useCallback(() => {
    if (terms && terms.length > 0 && terms[0].url) {
      if (terms[0].openInWebView) {
        Actions.openPsWebView({
          headerText: terms[0].webViewHeaderText ? terms[0].webViewHeaderText : '',
          headerIcon: BACK_WHITE_ICON,
          url: terms[0].url,
        });
      } else {
        Linking.openURL(terms[0].url);
      }
    }
  }, [terms]);
  const callPayment = useCallback(async () => {
    try {
      FlightBookingTrackingHelper.trackClickEventNew(
        'Insurance_Post_Sale_Details',
        'Inurance_Post_Sale_Pay_Clicked',
        props.bookingDetailRes,
      );
      setViewState(ViewState.LOADING);
      const apiResponse = await fetchInsHoldDetail(
        props.bookingId,
        props.insuranceDetailRes.uniqueCrId,
      );
      const body = apiResponse;
      setHoldResponse(body);
      if (!isEmpty(body) && body.paymentDetails) {
        /*todo future-  if insurance is sold free,
              need to write logic to not open payment module*/
        if (Platform.OS === 'ios') {
          FlightBookingModule.openAncillaryPaymentPage(
            JSON.stringify({ ...body, paymentType: 'insurance' }),
          );
        } else {
          FlightBookingModule.openAncillaryPaymentPage(JSON.stringify(body));
        }
        setViewState(ViewState.LOADED);
        props.handleBottomOverlay();
      } else {
        setViewState(ViewState.ERROR);
      }
    } catch (e) {
      setViewState(ViewState.ERROR);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.bookingId]);

  if (ViewState.LOADING === viewState) {
    return <PageLoader loaderMessage={''} />;
  } else if (ViewState.ERROR === viewState) {
    Actions.insuranceThankyou({
      isHoldFailed: true,
      bookingId: props.bookingId,
      holdDetailApi: holdResponse,
      uniqueCrId: props.insuranceDetailRes.uniqueCrId,
      responseJson: props.bookingDetailRes,
    });
    props.handleBottomOverlay();
    return null;
  }
  return (
    <View>
      <View style={stylesOverlay.crossIconActionWrapper}>
        <TouchableOpacity
          onPress={props.handleBottomOverlay}
          style={stylesOverlay.crossWrapper}
          activeOpacity={0.8}
        >
          <Image style={stylesOverlay.backIconStyle} source={CROSS_ICON} />
        </TouchableOpacity>
      </View>
      <ScrollView>
        <React.Fragment>
          <View style={[AtomicCss.marginTop10]}>
            {!!title && (
              <View
                style={[
                  AtomicCss.flexRow,
                  AtomicCss.alignCenter,
                  AtomicCss.marginBottom10,
                  AtomicCss.marginTop20,
                ]}
              >
                <Image style={stylesOverlay.secureIconStyle} source={INS_SECURE_ICON} />
                <Text style={[AtomicCss.fontWeightBold, AtomicCss.blackText, fsStyle.font24]}>
                  {title}
                </Text>
              </View>
            )}
            {!!subTitle && (
              <View style={stylesOverlay.headDetailText}>
                <Text
                  style={[
                    fsStyle.font14,
                    fonts.regularFontFamily,
                    AtomicCss.defaultText,
                    AtomicCss.lineHeight20,
                  ]}
                >
                  {subTitle}
                </Text>
              </View>
            )}
          </View>
          {!isEmpty(insuranceBenefits) &&
            insuranceBenefits &&
            insuranceBenefits.length > 0 && (
              <View style={stylesOverlay.paddingHz20}>
                {!!insuranceListingReview && !!insuranceListingReview.planBenefitTitle && (
                  <Text
                    style={[
                      fsStyle.font18,
                      AtomicCss.fontWeightBold,
                      AtomicCss.blackText,
                      AtomicCss.lineHeight20,
                      AtomicCss.marginBottom20,
                    ]}
                  >
                    {insuranceListingReview.planBenefitTitle}
                  </Text>
                )}
                {insuranceBenefits.map((item, index) => {
                  if (index > 2 && !showAllBenfits) {
                    return null;
                  }
                  return (
                    <View style={[AtomicCss.flexRow, AtomicCss.marginBottom30]}>
                      <Image
                        style={stylesOverlay.benefitIconStyle}
                        source={{ uri: item.iconUrl }}
                      />
                      <View style={[AtomicCss.flex1]}>
                        {!!item.benefitDisplayText && (
                          <Text
                            style={[
                              fsStyle.font14,
                              fonts.blackFontFamily,
                              AtomicCss.blackText,
                              AtomicCss.lineHeight20,
                            ]}
                          >
                            {item.benefitDisplayText}{' '}
                            {!!item.benefitType && (
                              <Text style={[fonts.regularFontFamily, stylesOverlay.greenText]}>
                                {item.benefitType} {currencySign ? currencySign : ''}{' '}
                                {item.sumInsured ? item.sumInsured : ''}
                              </Text>
                            )}
                          </Text>
                        )}
                        {!!item.benefitDisplayDesc && (
                          <Text
                            style={[
                              fsStyle.font14,
                              fonts.regularFontFamily,
                              AtomicCss.defaultText,
                              AtomicCss.lineHeight20,
                            ]}
                          >
                            {item.benefitDisplayDesc}
                          </Text>
                        )}
                      </View>
                    </View>
                  );
                })}
              </View>
            )}
          {!showAllBenfits && (
            <TouchableOpacity
              style={stylesOverlay.benefitsBtn}
              onPress={() => setShowAllBenfits(true)}
              activeOpacity={0.7}
            >
              <Text style={[AtomicCss.azure, fsStyle.font16, fonts.boldFontFamily]}>
                {`+ ${insuranceViewCount} ${moreBenefitsText}`}
              </Text>
            </TouchableOpacity>
          )}
        </React.Fragment>
        {!isEmpty(insuranceListingReview) &&
          !isEmpty(insuranceListingReview.insuranceTestimonial) &&
          insuranceListingReview.insuranceTestimonial.map((testimonials) => {
            return <View>{!isEmpty(testimonials) && <CustomerStories data={testimonials} />}</View>;
          })}
        <View style={stylesOverlay.borerTxt} />
        <Text style={[fsStyle.font14]}>
          {`${result[0] ? result[0] : ''}`}
          {!!clickableText && (
            <Text onPress={onTndcClick} style={stylesOverlay.linkTxt}>
              {` ${clickableText} `}
            </Text>
          )}
          {`${result[1] ? result[1] : ''}`}
        </Text>
        <View style={stylesOverlay.btnBox} />
      </ScrollView>

      {payButton.length > 0 && payButton[0].title && (
        <View style={stylesOverlay.btnWrapper}>
          <Button
            text={payButton[0].title}
            subText={payBtnDec}
            variant={BUTTON_VARIANT.PRIMARY}
            clickHandler={callPayment}
            textStyle={[{ fontSize: fonts.fontSize.font16 }]}
          />
        </View>
      )}
    </View>
  );
});

export default SecureYourTripOverlay;
