import React, { useEffect, useState } from 'react';
import { View, ScrollView } from 'react-native';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import { getStaticData } from '../../../staticData/staticData';
import Actions from '../../../navigation/postSalesNavigation';
import StickyHeaderBack from '../../components/StickyHeader/StickyHeaderBack';
import AtomicCss from '../../commonStyles/AtomicCss';

import { getFont } from '../../../PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import { createStyles } from '../previewTGChargesCss';
import RefundModeList from '../../cancellation/RefundMode/RefundModeList';
import * as PSC from '../../../PostSalesConstant';
import TGRefundInfoCard from '../TGRefundInfoCard';
import RecBlueBtn from '../../components/buttons/RecBlueBtn';
import ReviewStepHeader from '../../components/ReviewStepHeader/ReviewStepHeader';
import ProgressView from '../../../Common/ProgressView/ProgressView';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import SimpleHeader from '@mmt/legacy-commons/Common/Components/Header/SimpleHeader.android';
import { CancellationReason, TGCancelResponse, Train } from '../../types';
import { checkViewState, getPreviewRequest } from '../adapter/data';
import { RAIL_TG_PREMIUM_RETURN_URL } from '../../../utils/NetworkUtils';
import useApi from '../../../Common/useApi';
import NoInternetView from '@mmt/legacy-commons/Common/Components/Error/NoInternetView';
import { UseApiResponse } from 'apps/post-sales/src/types';
import { RAIL_TG_CANCEL_API } from '../../details/constants';
import RailBookingTrackEventHelper from '../../details/RailBookingTrackEventHelper';
import { RAIL_TRACKING_CONSTANT } from '../../details/RailBookingDetailConstant';
import { getRACTrackingEvent } from '../../details/RailBookingDetailsUtil';

interface Props {
  bookingDetails: {
    train: Train[];
    bookingID: string;
    tgsCancellationReasonList: CancellationReason[];
    railRacTgInfo: any;
  };
  isFullCancellation: boolean;
  isTGPremiumRefundCase: boolean;
  previewObj: TGCancelResponse | null; 
  reason: string;
  actionKey: string;
}

const RefundModeTGS: React.FC<Props> = ({
  bookingDetails,
  isFullCancellation,
  isTGPremiumRefundCase,
  previewObj,
  reason,
  actionKey
}) => {
  const [viewState, setViewState] = useState<string>(
    isTGPremiumRefundCase ? ViewState.LOADING : ViewState.SHOW_DETAIL
  );
  const [selectedRefundMode, setSelectedRefundMode] = useState<string>(PSC.DEAFULTREFUNDMODE);
  const [previewResponse, setPreviewResponse] = useState<TGCancelResponse | null>(
    !isTGPremiumRefundCase ? previewObj : null
  );

  const [errorText, setErrorText] = useState<string>("");

  const [previewResponseInProgress, previewAPIResponse, previewResponseAPI] : UseApiResponse<TGCancelResponse> = useApi(RAIL_TG_PREMIUM_RETURN_URL) as UseApiResponse<TGCancelResponse>;
  useEffect(() => {
    if(previewAPIResponse){
      setPreviewResponse(previewAPIResponse);
      checkViewState(previewAPIResponse, RAIL_TG_CANCEL_API.REFUND_MODE, setViewState, setErrorText);  
    }
  },[previewAPIResponse])

  const fonts = getFont();
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);

  const changeSelectedRefundMode = (updatedMode: { refundModeReason: string }) => {
    setSelectedRefundMode(updatedMode.refundModeReason);
  };


  useEffect(() => {
    if(isTGPremiumRefundCase && previewResponseAPI){
        previewResponseAPI.httpPost({
            body: getPreviewRequest(bookingDetails, actionKey, isFullCancellation)
        })
    }
  }, []);

  const handleTGPremiumCommit = () => {
    RailBookingTrackEventHelper.trackClickEvent( RAIL_TRACKING_CONSTANT.RAIL_BOOKING_DETAILS_PAGE, bookingDetails, getRACTrackingEvent(actionKey) );
    Actions.commitTGCancel({
      bookingDetails: bookingDetails,
      reason,
      actionKey: actionKey,
      isFullCancellation: isFullCancellation,
      refundOption: selectedRefundMode,
    });
  };

  const renderProgressView = () => {
    const staticData = getStaticData();
    const { loadingText } = staticData;
    return <ProgressView message={loadingText ? loadingText : ''} />;
  };

  const goBackToDetails = () => {
    Actions.popTo('railBookingDetail');
  };

  const renderErrorView = () => {
    const staticData = getStaticData();
    const { goBackToBookingText, backText } = staticData;
    return (
      <View style={{ flex: 1 }}>
        <SimpleHeader title={backText} iconPress={goBackToDetails} />
        <ErrorView
          showRetry
          buttonLabel={goBackToBookingText}
          message={errorText}
          onRetry={goBackToDetails}
        />
      </View>
    );
  };

  const renderMainView = () => {
    const staticData = getStaticData();
    const { initiateRefundText, travelWithRAC, trainCancellationText } = staticData;
    const cancelTrainTicketsText = trainCancellationText?.additionalText?.cancelTrainTicketsText;
    const { railTgCardData: cardData } = previewResponse || {};
    return (
      <View style={styles.CancellationWrapper}>
        <StickyHeaderBack trainDetails={bookingDetails ? bookingDetails.train : null} heading={ isTGPremiumRefundCase? travelWithRAC: cancelTrainTicketsText} />
        <ScrollView style={AtomicCss.greyBg}>
          {!isTGPremiumRefundCase && <ReviewStepHeader currentIndex={2} />}
          {cardData && <TGRefundInfoCard cardData={cardData} isPreview={!isTGPremiumRefundCase} />}
          <RefundModeList previewObj={previewResponse} changeSelectedRefundMode={changeSelectedRefundMode} />
        </ScrollView>
        <RecBlueBtn btntext={initiateRefundText} onPressHandler={handleTGPremiumCommit} />
      </View>
    );
  };

  const renderNoNetworkView = () => {
    const staticData = getStaticData();
    const { backText} = staticData ;
     return <View style={ AtomicCss.flex1}>
      <SimpleHeader title={backText} iconPress={goBackToDetails} />
      <NoInternetView
        buttonText={backText}
        onRetry={goBackToDetails}
      />
    </View>
  };
  return (
    <View style={{ flex: 1 }}>
      {viewState === ViewState.NO_INTERNET && renderNoNetworkView()}
      {viewState === ViewState.LOADING && renderProgressView()}
      {viewState === ViewState.SHOW_DETAIL && renderMainView()}
      {viewState === ViewState.ERROR && renderErrorView()}
    </View>
  );
};

export default RefundModeTGS;
