import { StyleSheet } from 'react-native';

export default (theme, fonts) =>
StyleSheet.create({
  cardWrapper: {
    backgroundColor: theme.color.white,
    elevation: 3,
    shadowColor: '#000000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 2,
    borderRadius: 4
  },
  checkAllWrapper: {
    borderBottomWidth: 1,
    borderBottomColor: theme.color.lightGrey,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    backgroundColor: theme.color.white,
    marginBottom: 2
  },
  sectorPaxList: {
    paddingVertical: 19,
    borderBottomColor: '#e7e7e7',
    borderBottomWidth: 1,
    paddingHorizontal: 45,
  },
  checkBtn: {
    flexDirection: 'row',
    paddingVertical: 10,
    paddingHorizontal: 15,
    alignItems: 'center'
  },
  checkAllBtn: {
    alignItems: 'flex-start'
  },
  checkbox: {
    height: 18,
    width: 18,
    backgroundColor: theme.color.white,
    borderColor: theme.color.disabledBtnBg,
    borderWidth: 1.5,
    borderRadius: 3,
    overflow: 'hidden',
    position: 'relative',
    marginRight: 12
  },
  checkboxSelected: {
    borderColor: 'transparent',
    borderWidth: 0
  },
  checkboxAll: {
    marginTop: 10
  },
  checkedIcon: {
    position: 'absolute',
    height: 29,
    width: 29,
    left: -5,
    top: -4
  },
  destRow: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  cityText: {
    fontSize: 12,
    color: theme.color.black28,
    fontFamily: fonts.bold
  },
  arrowIcon: {
    width: 10,
    height: 7,
    marginHorizontal: 5
  },
  rtInfo: {
    fontSize: 12,
    color: '#cf8100',
    fontFamily: fonts.regular,
    marginBottom: 10,
    paddingTop: 10,
    paddingRight: 10,
    width: '40%'
  },
  smallText: {
    fontSize: 10,
    color: theme.color.defaultTextColor,
    fontFamily: fonts.semibold,
    marginTop: 5,
    marginBottom: 0,
    lineHeight: 10,
    paddingBottom: 2
  },
  passengerName: {
    fontSize: 14,
    color: theme.color.black,
    fontFamily: fonts.bold
  },
  passengersList: {
    paddingHorizontal: 0
  },
  redInfoIcon: {
    width: 16,
    height: 16,
    marginRight: 10
  },
  redItalicText: {
    color: theme.color.red,
    fontFamily: fonts.regular,
    fontSize: 12,
    fontStyle: 'italic'
  },
  redInfoWrappe: {
    padding: 16,
    paddingTop: 6,
    flexDirection: 'row'
  },
  segmentRow: {
    paddingBottom: 5
  },
  PaxslctionHeader: {
    flex: 1,
    justifyContent: 'space-between'
  },
  SectorHeader: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between'
  },
  checkAllWrapperInner: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  airlinesIconWrapper: {
    width:33,
    height:33,
    marginHorizontal: 12
  },
  airlineIcon: {
    width: 32,
    height: 32
  },
  fontRegular:{
    fontFamily:fonts.regular,
    fontSize:14
  },
  fltInfo:{
    fontSize:12,
    color:'#cf8100',
    fontFamily:fonts.regular,
    marginBottom:10,
    paddingRight:20,
    paddingTop:15,
    width: '60%'
  },
  childInfoAlert: {
    fontSize: 12,
    color: '#cf8100',
    fontFamily: fonts.regular,
    paddingTop: 5
  },
  destCityWrapper:{
    alignItems: 'center',
    flex:1,
    flexWrap:'wrap',
    flexDirection:'row'
  },
  flexOne:{
    flex:1
  }  
});

