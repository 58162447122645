import React from 'react';
import {View, Text, Image, ActivityIndicator} from 'react-native';
import Actions from '../../../navigation/postSalesNavigation';
import PropTypes from 'prop-types';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getStyles from './PAHXChargeCardCss';
import ViewStates from '../utils/ViewStates';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import {getCommonHeaders, PAYNOW_SUBMIT_URL} from '../../../utils/NetworkUtils';
import {isNetworkAvailable} from '@mmt/legacy-commons/Common/utils/AppUtils';
import PostSalesHotelsBaseClass from './PostSalesHotelsBaseClass';
import {getAmountDue, getHotelName, getCityName} from '../utils/HotelBookingDetailUtil';
import HotelBookingTrackingHelper from '../../HotelBookingTrackingHelper';
import { getStaticData } from '../../../staticData/staticData';
import { YELLOWISH_GREEN_TICK_ICON } from '../../HotelImageConstants';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

class PAHXChargeCard extends PostSalesHotelsBaseClass {
  constructor(props) {
    super(props, 'hotelPayNow', 'PayNowTy');
    this.fonts = getFont(true);
    this.styles = getStyles(this.fonts);
    this.state = {
      viewState: ViewStates.LOADING
    };
  }
  componentDidMount() {
    this.chargeSameCard();
  }

  render() {
    const imagePath = YELLOWISH_GREEN_TICK_ICON;
    return (
      <View style={this.styles.flex1}>
        {this.state.viewState === ViewStates.LOADING && this.renderProgressView(imagePath)}
        {this.state.viewState === ViewStates.NO_INTERNET && this.renderNoNetworkView()}
        {this.state.viewState === ViewStates.ERROR && this.gotoChargeFailedPage()}
        {this.state.viewState === ViewStates.FAILURE && this.gotoChargeFailedPage()}
        {this.state.viewState === ViewStates.FETCH_DATA && this.gotoChargeSuccessfullPage()}
      </View>
    );
  }

  renderProgressView= (imagePath) => {
    const staticData = getStaticData();
    return(
      <View>
        <Text style={this.styles.headingTitle}>{staticData.pahxChargeCardText.additionalText.pleaseWaitWhileWeCompleteText}</Text>
        <Image style={[this.styles.imageIcon, AtomicCss.marginBottom10]} source={imagePath} />
        <Text style={this.styles.bankInfoTxt}>{staticData.pahxChargeCardText.additionalText.contactingYourBankText}</Text>

        <View style={this.styles.chargingCardWrapper}>
          <View style={AtomicCss.marginBottom30}>
            <ActivityIndicator size="large" color="#51ae77" />
          </View>
          <Text style={this.styles.bankChargeCard}>{staticData.pahxChargeCardText.additionalText.processingPaymentText}</Text>
        </View>
      </View>
    );
  }

  gotoChargeSuccessfullPage() {
    const staticData = getStaticData();
    const subMsg = this.props.paymentSummary.successfullyPaidDueAmountText || `You have successfully paid ${getAmountDue(this.props.response)} for your stay at ${getHotelName(this.props.response)}, ${getCityName(this.props.response)}.`;
    Actions.chargeSuccessfullPage({
      msg: staticData.pahxChargeCardText.additionalText.weHaveSuccessfullyReceivedPaymentText,
      subMsg,
      bookingID: this.props.response.bookingID,
      pageName: this.props.pageName,
      response: this.props.response
    });
  }
  gotoChargeFailedPage() {
    const staticData = getStaticData();
    const msg = (this.state.viewState === ViewStates.FAILURE) ? staticData.pahxChargeCardText.additionalText.yourPaymentCouldNotBeCompletedText : staticData.errorText.soomethingWentWrongOnlyError;
    const subMsg = (this.state.viewState === ViewStates.FAILURE) ? staticData.pahxChargeCardText.additionalText.pleaseTryAfterSometimeText : staticData.pahxChargeCardText.additionalText.weHaveEncounteredErrorTryAgainText;
    const pageNameSuff = (this.state.viewState === ViewStates.FAILURE) ? 'PayNow_Fail' : 'PayNow_Error';
    Actions.chargeFailedPage({
      msg,
      subMsg,
      pageNameSuff,
      bookingID: this.props.response.bookingID,
      pageName: this.props.pageName,
      response: this.props.response
    });
  }

  async chargeSameCard() {
    try {
      const hasNetwork = await isNetworkAvailable();
      if (!hasNetwork) {
        this.setState({viewState: ViewStates.NO_INTERNET});
        return;
      }
      const request = {
        bookingId: this.props.response.bookingID
      };
      const {mmtAuth, loggingTrackingInfo} = await HotelBookingModule.getRequestHeader(this.props.response.bookingID, 'HOTEL_BNPL_CHARGE_CARD');
      const response =
        await fetch(PAYNOW_SUBMIT_URL, {
          method: 'POST',
          body: JSON.stringify(request),
          headers: await getCommonHeaders(mmtAuth, loggingTrackingInfo)
        });
      if (response.ok) {
        const respBody = await response.json();
        if (respBody.mojoSuccess) {
          this.setState({viewState: ViewStates.FETCH_DATA});
          HotelBookingTrackingHelper.trackLoadEvent(this.props.pageName + ' : PayNow_Success', this.props.response);
        } else {
          this.setState({viewState: ViewStates.FAILURE});
          this.trackError(respBody.mojoResponseText);
        }
      } else {
        this.setState({viewState: ViewStates.ERROR});
        this.trackError(response.status);
      }
    } catch (error) {
      this.setState({viewState: ViewStates.ERROR});
      this.trackError(error);
    }
  }

  trackError=(ErrorMessage)=>{
    const pageNameSuff = (this.state.viewState === ViewStates.FAILURE) ? 'PayNow_Fail' : 'PayNow_Error';
    HotelBookingTrackingHelper.trackErrorWithData(`${this.props.pageName} : ${pageNameSuff}`, this.props.response.bookingID, ErrorMessage, this.props.response);
  }
}

PAHXChargeCard.propTypes = {
  response: PropTypes.object.isRequired,
  pageName: PropTypes.string.isRequired
};
export default PAHXChargeCard;
