import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Text, View, TextInput, TouchableOpacity, Image, FlatList } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';

import { getFont } from '../../../../../PostSalesStyles';
import { useTheme } from '../../../../../theme/theme.context';
import createStyles from './autoCompleteSearchPopupCss';

import { ANDROID_BACK_ARROW, RIGHT_ARROW } from '../../../../FlightImageConstants';
import { getStaticData } from '../../../../../staticData/staticData';
import { AutoCompleteSearchPopupProps, CTA_SEARCH_TYPE, FlatListDataType } from './autoCompleteSearchTypes';
import { getSearchResultActIds } from '../../utils/autoSearchBarUtil';
import FlightBookingTrackingHelper from '../../helper/FlightBookingTrackingHelper';

const MINIMUM_LETTER_INPUT = 3;
const MAX_LIST_SHOW = 10;

const AutoCompleteSearchPopup: React.FC<AutoCompleteSearchPopupProps> = ({ onBackPress, onSelect, response, flightsResponse }) => {
  const dataList = response?.dataSet;
  const popularSearch = response?.popularSearch;
  const { fsStyle, ...fonts } = getFont();
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);
  const [inputValue, setInputValue] = useState('');
  const [filteredList, setFilteredList] = useState<CTA_SEARCH_TYPE[]>([]);
  const onClick = (item: CTA_SEARCH_TYPE, isPopularSearch?: boolean) => {
    if (isPopularSearch) {
      FlightBookingTrackingHelper.trackingNewClickEvent('Search Page', `popular_search_${item.actId}`, flightsResponse, { m_c1: item.actId});
    }
    onSelect(item);
  };
  const selectOption = (optionToSelct: CTA_SEARCH_TYPE) => {
    FlightBookingTrackingHelper.trackingNewClickEvent('Search Page', `input_search_${optionToSelct.actId}`, flightsResponse, { m_c1: `${inputValue}${getSearchResultActIds(filteredList)}`});
    onSelect(optionToSelct);
  };

  /** No Result found tracking debounced for 1 sec */
  useEffect(() => {

    let timeout: null | number = null;

    if (isEmpty(filteredList) && inputValue) {
      timeout = setTimeout(() => {
        FlightBookingTrackingHelper.trackingNewClickEvent('Search Page', 'No_results_found', flightsResponse, { m_c1: inputValue });
      }, 1000);
    }

    return () => {
      typeof timeout === 'number' && clearTimeout(timeout);
    }

  }, [filteredList, inputValue])

  const clear = () => {
    setInputValue('');
  };

  const isPartialMatch = (keyword: string, userInput: string) => {
    if (keyword.includes(userInput)) {
      return true;
    }
    if (userInput.length > 1 && keyword.length > 1) {
      for (let i = 0; i < keyword.length; i++) {
        if (keyword.includes(userInput.slice(0, i) + userInput.slice(i + 1))) {
          return true;
        }
      }
    }
    return false;
  };

  const searchWithPartialInput = (userInput: string) => {
    const matchingActions: { actId: number, title: string }[] = [];
    const userInputLowerCase = userInput.toLowerCase();
    for (const action of dataList) {
      for (const keyword of action.keywords) {
        if (isPartialMatch(keyword.toLowerCase(), userInputLowerCase)) {
          matchingActions.push({ actId: action.actId, title: action.title });
          break;
        }
      }
    }
    return matchingActions;
  };

  const onInputChange = (textEntered: string) => {
    const userInput = textEntered.trim();
    setInputValue(textEntered);
    if (userInput.length >= MINIMUM_LETTER_INPUT) {
      const newFilteredList = searchWithPartialInput(userInput);
      setFilteredList(newFilteredList);
    }
  };
  const { clearText, popularSearches, searchAnything, searchBarNoResultFountText } = getStaticData();

  const renderPopularList = (data: FlatListDataType) => {
    return (
      <TouchableOpacity style={styles.psTab} activeOpacity={0.7} key={data.item.actId} onPress={() => onClick(data.item, true)}>
        <Text
          style={[fsStyle.font14, fonts.boldFontFamily, AtomicCss.defaultText]}
        >
          {data.item.title}
        </Text>
      </TouchableOpacity>
    );
  };
  const renderSearchResultList = (data: FlatListDataType) => {
    const firstItem = data.index === 0 ? {} : styles.borderTop;
    return (
      <TouchableOpacity
        key={data.item.actId}
        style={[styles.textWrapper, firstItem]}
        onPress={() => selectOption(data.item)}
      >
        <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.flex1]}>
          <Image
            style={styles.increaseArrow}
            source={require('@mmt/legacy-assets/src/Images/increaseArrow.webp')}
          />
          <Text style={styles.dropdownText}>{data.item.title}</Text>
        </View>
        <Image
          style={styles.rightArrow}
          source={RIGHT_ARROW}
        />
      </TouchableOpacity>
    );
  };

  return (
    <View style={[AtomicCss.flex1, { marginHorizontal: -20 }]}>
      <View style={styles.inputContainer}>
        <TouchableOpacity style={styles.backWrapper} onPress={() => {
          FlightBookingTrackingHelper.trackingNewClickEvent('Search Page', 'Back_button_clicked', flightsResponse);
          onBackPress();
        }}>
          <Image
            style={styles.iconBack}
            source={ANDROID_BACK_ARROW}
          />
        </TouchableOpacity>
        <TextInput
          onChangeText={onInputChange}
          style={styles.input}
          value={inputValue}
          placeholder={searchAnything}
          autoFocus={true}
        />
        <TouchableOpacity onPress={clear} style={styles.btnContainer}>
          <Text style={styles.btnText}>{clearText}</Text>
        </TouchableOpacity>
      </View>

      <View style={[AtomicCss.flex1, AtomicCss.paddingHz20]}>
        {!(inputValue.trim().length >= MINIMUM_LETTER_INPUT) ? (
          <View style={styles.dropDown}>
            {!isEmpty(popularSearch) && (
              <>
                <View style={styles.searchTypeSection}>
                  <Text style={[fonts.boldFontFamily]}>{popularSearches}</Text>
                </View>
                <View style={styles.popularSearchWrapper}>
                  <FlatList
                    data={popularSearch}
                    renderItem={renderPopularList}
                    showsVerticalScrollIndicator
                    initialNumToRender={MAX_LIST_SHOW}
                    contentContainerStyle={[AtomicCss.flexRow, AtomicCss.flexWrap]}
                  />
                </View>
              </>
            )}
          </View>
        ) : (
          <View style={styles.dropDown}>
            {isEmpty(filteredList) ? (
              <View style={[styles.textWrapper, AtomicCss.flexRow, AtomicCss.alignCenter]}>
                <Text style={styles.dropdownText}>
                  {searchBarNoResultFountText}
                </Text>
              </View>
            ) : (
              <FlatList
                data={filteredList}
                renderItem={renderSearchResultList}
                showsVerticalScrollIndicator
                initialNumToRender={MAX_LIST_SHOW}
              />
            )}
          </View>
        )}
      </View>
    </View>
  );
};

export default AutoCompleteSearchPopup;
