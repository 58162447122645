import React, { useCallback, useEffect, useState } from 'react';
import { DeviceEventEmitter, View } from 'react-native';
import { SPECIAL_CLAIM_BOTTOM_SHEET_STATES } from '../../Constant';
import FlightLoaderAnim from '../../../../Common/FlightLoaderAnimation';
import styles from './SpecialClaimBottomSheetCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import LoadingCard from '../../../../Common/LoadingCard';
import SubmitRefundRequestScreen from './SubmitRefundRequestScreen';
import useApi from '../../../../Common/useApi';
import { FLIGHT_CANCELLATION_PREVIEW_URL } from '../../../../utils/NetworkUtils';
import Actions from '../../../../navigation/postSalesNavigation';
import { isEmptyArray } from '../../../../Common/commonUtil';
import { getStaticData } from '../../../../staticData/staticData';
import FlightBookingTrackingHelper from '../../../modules/details/helper/FlightBookingTrackingHelper';
import { resetToFlightBookingDetail } from '../../../FlightBookingUtil';
import { LOBNAMES } from '../../../../PostSalesConstant';
import { EventEmitterConst } from '../../../modules/details/FlightBookingDetailsConstant';
import isEmpty from 'lodash/isEmpty';

interface Props {
  bookingDetailsResponse: any;
  closeSpecialClaimPopUp: () => void;
  cancelCardActionItemClick: () => void;
  cancellationButtonData: {
    actionId: string;
    backPress: () => void;
  };
}

const SpecialClaimBottomSheetV2: React.FC<Props> = ({
  bookingDetailsResponse,
  closeSpecialClaimPopUp,
  cancelCardActionItemClick,
  cancellationButtonData,
}) => {
  const [screen, setScreen] = useState();
  const [previewInProgress, previewResponse, previewApi] = useApi(FLIGHT_CANCELLATION_PREVIEW_URL);
  const [detailsProgress, detailsResponse, detailsApi] = useApi(FLIGHT_CANCELLATION_PREVIEW_URL);
  const { actionId = '', backPress } = cancellationButtonData || {};
  const { flightDetails, bookingID, specialClaimV2Enable } = bookingDetailsResponse;
  const goBackToBooking = useCallback(() => {
    resetToFlightBookingDetail(bookingID);
  }, [bookingID]);

  useEffect(() => {
    if (specialClaimV2Enable) {
      Actions.specialClaimV2({ bookingID, actionId, bookingDetailsResponse });
      closeSpecialClaimPopUp();
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(previewResponse)) {
      if (previewResponse.error || isEmpty(previewResponse.newSpecialClaimDataCard)) {
        closeSpecialClaimPopUp();
        Actions.specialClaimError({ goBackToBooking });
        return;
      } else {
        setScreen(SPECIAL_CLAIM_BOTTOM_SHEET_STATES.SUBMIT_REFUND_SCREEN);
      }
    }
  }, [previewResponse]);

  useEffect(() => {
    if (!isEmpty(detailsResponse) && !specialClaimV2Enable) {
      if (
        detailsResponse.error ||
        isEmpty(detailsResponse.newSpecialClaimDataCard?.cardsList) ||
        isEmpty(detailsResponse.newSpecialClaimDataCard?.actionLists)
      ) {
        closeSpecialClaimPopUp();
        Actions.specialClaimError({ goBackToBooking });
        return;
      } else {
        Actions.specialClaimNew({ detailsResponse: detailsResponse, actionId, scCode: actionId });
        return;
      }
    }
  }, [detailsResponse]);

  useEffect(() => {
    if (!specialClaimV2Enable) {
      const pageName = FlightBookingTrackingHelper.getPageName(
        'MI_FLT_CITP_SClaim',
        bookingDetailsResponse,
      );
      const paxFareSegmentIdList = getPaxFareSegmentList(actionId);
      const body = {
        bookingId: bookingID,
        isFullCancellation: false,
        validateClaim: false,
        newSpecialClaim: true,
        fullCancellationReason: actionId,
        refundOption: 'RefundToCustomer',
        lobCode: bookingID,
        paxFareSegmentIdList: paxFareSegmentIdList,
      };
      previewApi.httpPost({
        body: body,
        bookingId: bookingID,
        uniqueId: 'SC_PREVIEW',
        psLob: LOBNAMES.FLIGHT,
      });
      DeviceEventEmitter.emit(EventEmitterConst.CTA_CLICKED_EVENT);
      FlightBookingTrackingHelper.trackClickEvent(pageName, 'MI_FLT_Sclaim_New');
    }
  }, []);

  const { checkingWithAirlineText } = getStaticData();

  const getPaxFareSegmentList = (scCode) => {
    const paxFareSegmentList = [];
    if (!!flightDetails || !isEmptyArray(flightDetails.segmentGroupDetailList)) {
      flightDetails.segmentGroupDetailList.forEach((segmentGroupDetail) => {
        if (!isEmptyArray(segmentGroupDetail.segmentDetails)) {
          segmentGroupDetail.segmentDetails.forEach((segmentDetail) => {
            if (!isEmptyArray(segmentDetail.segmentPassengerDetail)) {
              segmentDetail.segmentPassengerDetail.forEach((segmentPassengerDetail) => {
                if (!segmentPassengerDetail.isPaxCancelled) {
                  const paxFareSegment = {};
                  paxFareSegment.reason = scCode;
                  paxFareSegment.paxFareSegmentId = segmentPassengerDetail.paxFareSegmentId;
                  paxFareSegmentList.push(paxFareSegment);
                }
              });
            }
          });
        }
      });
    }
    return paxFareSegmentList;
  };

  const submitRefundRequest = () => {
    DeviceEventEmitter.emit(EventEmitterConst.CTA_CLICKED_EVENT);
    detailsApi.clearResponse();
    const paxFareSegmentIdList = getPaxFareSegmentList(actionId);
    const body = {
      bookingId: bookingID,
      isFullCancellation: false,
      validateClaim: true,
      newSpecialClaim: true,
      fullCancellationReason: actionId,
      refundOption: 'RefundToCustomer',
      lobCode: bookingID,
      paxFareSegmentIdList: paxFareSegmentIdList,
    };
    detailsApi.httpPost({
      body: body,
      bookingId: bookingID,
      uniqueId: 'CANCELLATION_DETAIL',
      psLob: LOBNAMES.FLIGHT,
    });
    FlightBookingTrackingHelper.trackClickEvent(
      FlightBookingTrackingHelper.getPageName(
        'Specialclaim_initiaterequest_new',
        bookingDetailsResponse,
      ),
      'MI_FLT_Sclaim_New_Initiation',
    );
  };

  const handleBack = () => {
    setScreen(null);
    previewApi.clearResponse();
    detailsApi.clearResponse();
    closeSpecialClaimPopUp();
    typeof backPress === 'function' ? backPress() : null;
  };

  const loadSpecialClaimScreen = () => {
    if (previewInProgress || detailsProgress) {
      return null;
    }
    switch (screen) {
      case SPECIAL_CLAIM_BOTTOM_SHEET_STATES.SUBMIT_REFUND_SCREEN:
        return (
          <SubmitRefundRequestScreen
            submitRefundRequest={submitRefundRequest}
            previewResponse={previewResponse}
            handleBack={handleBack}
          />
        );
      default:
        return <LoadingCard rows={2} />;
    }
  };

  return (
    <View style={styles.OverlayContentWrapper}>
      <View style={AtomicCss.marginBottom10}>
        <View style={styles.overlayTopline} />
      </View>
      {previewInProgress && <LoadingCard rows={2} />}
      {detailsProgress && (
        <View style={styles.submitReqWrapper}>
          <FlightLoaderAnim text={checkingWithAirlineText + '...'} />
        </View>
      )}
      {loadSpecialClaimScreen()}
    </View>
  );
};

export default SpecialClaimBottomSheetV2;
