import { StyleSheet, Platform} from 'react-native';

export default (theme, fonts) =>
StyleSheet.create({
  btnTypeRound: {
    borderRadius: 4,
    overflow: 'hidden'
  },
  btnTypeRounded: {
    borderRadius: 40,
    overflow: 'hidden'
  },
  blueBtnText: {
    paddingVertical: 16,
    fontSize: 16,
    fontFamily: fonts.bold,
    color: '#ffffff',
    textAlign: 'center'
  },
  blueBtnGradient: {
    paddingHorizontal: 30
  },
  ghostBtn: {
    borderRadius: 4,
    overflow: 'hidden',
    backgroundColor: 'transparent'
  },
  ghostBtnText: {
    paddingVertical: 12,
    fontSize: 12,
    fontFamily: fonts.bold,
    color: theme.color.azure,
    textAlign: 'center'
  },
  WtRightArrow: {
    width: 16,
    height: 12,
    marginLeft: 10
  },
  BtnCenter: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  CenterText: {
    alignSelf: 'center'
  },
  WhiteText: {
    color: theme.color.white
  },
  font14: {
    fontSize: 14
  },
  fullWidthBtn: {
    width: '100%',
    paddingVertical: 18
  },
  blackFont: {
    fontFamily: fonts.black
  },
  transperantBgrnd: {
    ...Platform.select({
      ios: {
        backgroundColor: 'transparent'
      }
    })
  },
});
