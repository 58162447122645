import { StyleSheet } from 'react-native';
import { Fonts, Theme } from '../../../../types';

const getStyles = (theme: Theme, fonts: Fonts) => {
  const { color } = theme;
  return StyleSheet.create({
    container: {
      padding: 20,
      backgroundColor: color.white,
    },
    headingSection: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    headingText: {
      color: color.black22,
      ...fonts.blackFontFamily,
      fontSize: fonts.fontSize.font20,
    },
    titleText: {
      ...fonts.blackFontFamily,
      fontSize: fonts.fontSize.font14,
      color: color.lightGreen18,
      marginBottom: 5,
    },
    crossIconStyle: {
      width: 24,
      height: 24,
    },
    crossIconWrapper: {
      width: 30,
      height: 30,
      right: -10,
    },
    btnWrapper: {
      paddingHorizontal: 18,
      paddingBottom: 12,
    },
    modifyInfoBox: {
      paddingVertical: 12,
    },
  });
};

export default getStyles;
