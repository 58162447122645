import { StyleSheet } from 'react-native';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';

export default (theme, fonts) =>
  StyleSheet.create({
    cardWrapper: {
      backgroundColor: theme.color.white,
      elevation: 3,
      ...getPlatformElevation(2),
      borderRadius: 8,
      borderWidth: 1,
      borderColor: theme.color.greyBookedSeat,
    },
    cardWrapperLastItem: {
      marginBottom: 0,
    },
    destRow: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    paxText: {
      fontSize: fonts.fontSize.font16,
      color: theme.color.black,
      ...fonts.boldFontFamily,
      lineHeight: 24,
    },
    arrowIcon: {
      width: 10,
      height: 7,
      marginHorizontal: 5,
    },
    smallText: {
      fontSize: fonts.fontSize.font13,
      color: theme.color.defaultTextColor,
      ...fonts.RegularFontFamily,
      marginTop: 5,
      marginBottom: 0,
      lineHeight: 18,
    },
    checkAllWrapperInner: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingTop: 12,
      paddingBottom: 8,
      paddingHorizontal: 16,
    },
    seperatorWrapper: {
      paddingHorizontal: 20,
      overflow: 'hidden',
      marginBottom: 6,
    },
    uploadWrapper: {
      paddingHorizontal: 8,
    },
    proofWrapper: {
      borderTopWidth: 1,
      borderTopColor: theme.color.greyBookedSeat,
      flexDirection: 'row',
      padding: 16,
      alignItems: 'center',
      lineHeight: 20,
    },
    infoTag: {
      flex: 1,
      flexDirection: 'row',
      paddingHorizontal: 16,
      marginBottom: 12,
    },
    infoWrapper: {
      backgroundColor: theme.color.creamWhite,
      paddingHorizontal: 8,
      paddingVertical: 4,
      borderRadius: 4,
    },
    infoText: {
      color: theme.color.lightYello,
      fontSize: fonts.fontSize.font10,
      ...fonts.blackFontFamily,
      lineHeight: 14,
    },
  });
