import React, { useEffect, useMemo, useState } from 'react';
import { View, Text, ScrollView, Platform } from 'react-native';
import ReviewButton from '../../../SelectPassengers/ReviewButton';
import InfoListCard from '../../InfoListCard';
import LinearGradient from 'react-native-linear-gradient';
import UploadProofCard from '../../UploadProofCard';
import RaiseRequestProcessCardV2 from '../../ClaimReason/RaiseRequestProcessCardV2';
import styles from '../ClaimAncillaryRefundCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { showShortToast } from '@mmt/legacy-commons/Common/Components/Toast';
import SpecialClaimAddAttachmentModule from '@mmt/legacy-commons/Native/SpecialClaimAddAttachmentModule';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import { CreateGuid } from '../../../../../utils/PostSaleUtil';
import {
  CLAIM_ANCILLARY_REVIEW,
  getCommonHeaders,
  UPLOAD_ATTACHMENT_URL,
} from '../../../../../utils/NetworkUtils';
import { getStaticData } from '../../../../../staticData/staticData';
import AncillaryClaimProcess from '../AncillaryClaimProcess';
import useApi from '../../../../../Common/useApi';
import { isEmptyArray, isNullOrEmptyObject } from '../../../../../Common/commonUtil';
import SpecialClaimTrackingHelper from '../../../Helpers/SpecialClaimTrackingHelper';
import { getFont } from '../../../../../PostSalesStyles';

const cardIds = {
  IMPORTANT_INFO: 'IMPORTANT_INFO',
  SUBMIT_PROOF: 'SUBMIT_PROOF',
  PROCESS: 'PROCESS',
};

let optional = true;
const ReviewAncillaryRefund = ({
  loaderMsg,
  headerComp,
  onReviewDetails,
  bookingId,
  showLoader,
  hideLoader,
  selectedData,
  requestData,
  reviewData,
  setReviewData,
  flightResponse,
  scCode,
}) => {
  let attachedFile = [];
  const { uploadFailedText, pleaseAddAttachmentText, documentUploadSuccessText } = getStaticData();
  const [claimReviewInProgress, claimReviewData, claimReviewApi] = useApi(CLAIM_ANCILLARY_REVIEW);
  const [proofList] = useState([]);
  const { cards, actionLists } = reviewData || {};
  const pageName = 'AncillaryClaimReview';
  const { fsStyle, ...fonts } = getFont(true);

  const action = useMemo(() => {
    if (!isEmptyArray(actionLists)) {
      const data = actionLists.find((card) => card.actionId === 'select_refund_mode');
      return data;
    }
    return null;
  }, [actionLists]);

  useEffect(() => {
    SpecialClaimTrackingHelper.trackAncillaryClaimLoadEvent(pageName, flightResponse, { scCode });
    !reviewData && loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingId, selectedData, requestData]);

  useEffect(() => {
    setReviewData(reviewData || claimReviewData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimReviewData]);

  const loadData = () => {
    claimReviewApi.httpPost({
      bookingId,
      uniqueId: 'FLIGHT_CLAIM_ANCILLARY_REVIEW',
      body: {
        ...requestData,
        ancillarieslines: Object.values(selectedData),
      },
    });
  };

  const impInfo = useMemo(() => {
    if (!isEmptyArray(cards)) {
      const data = cards.find((card) => card.id === cardIds.IMPORTANT_INFO);
      if (data) {
        data.messages = data.entries ? data.entries.map((item) => item.title) : [];
      }
      return data;
    }
    return null;
  }, [cards]);

  const processInfo = useMemo(() => {
    if (!isEmptyArray(cards)) {
      const data = cards.find((card) => card.id === cardIds.PROCESS);
      if (data) {
        data.header = data.title;
        data.steps = data.entries
          ? data.entries.map((item) => ({
              header: item.title,
              subText: item.subtitle,
            }))
          : [];
      }
      return data;
    }
    return null;
  }, [cards]);

  const proofInfo = useMemo(() => {
    if (!isEmptyArray(cards)) {
      const data = cards.find((card) => card.id === cardIds.SUBMIT_PROOF);
      if (data) {
        data.heading = data.title;
        data.description = data.subtitle;
        data.optionalText = data.required === false ? null : 'Optional';
        optional = data.optionalText !== null;
      }
      return data;
    }
    return null;
  }, [cards]);

  const onFileChanged = (updatedFileList) => {
    attachedFile = updatedFileList;
  };

  const onContinueClicked = () => {
    SpecialClaimTrackingHelper.trackClickEvent(pageName, 'select_refund_mode');
    if (attachedFile.length > 0) {
      uploadFiles();
    } else if (optional) {
      onReviewDetails();
    } else {
      showShortToast(pleaseAddAttachmentText);
    }
  };

  const uploadFiles = async () => {
    try {
      const imageUploadReq = [];
      const data = new FormData();
      for (let i = 0; i < attachedFile.length; i += 1) {
        const imageStr =
          Platform.OS === 'web'
            ? attachedFile[i].base64String
            : await uploadFile(attachedFile[i].filePath);
        if (imageStr) {
          imageUploadReq.push(fileUploadAPI(imageStr, attachedFile[i]));
        }
        data.append('images', {
          string: JSON.stringify(imageStr),
          type: 'application/json; charset=utf-8',
        });
      }
      showLoader();
      Promise.all(imageUploadReq)
        .then((res) => {
          hideLoader();
          uploadResponseHandler(res);
        })
        .catch((err) => {
          hideLoader();
          onReviewDetails();
          showShortToast(uploadFailedText);
        });
    } catch (err) {
      hideLoader();
      onReviewDetails();
      showShortToast(uploadFailedText);
    }
  };

  const uploadFile = async (imagePath) => {
    let imageStr = '';
    if (Platform.OS === 'ios') {
      imageStr = attachedFile.find((file) => file.filePath === imagePath).data;
    } else {
      const imgInfo = await SpecialClaimAddAttachmentModule.convertToBase64(imagePath);
      imageStr = imgInfo.imageResponse;
    }
    return imageStr;
  };

  const fileUploadAPI = async (fileDataBase64Str, data) => {
    const { fileName } = data || {};
    const token = fileName ? fileName.split('.') : [];
    const userInfo = await HotelBookingModule.getRequestHeader(
      bookingId,
      'ANCILLARY_CLAIM_UPLOAD_DOC',
    );
    const requestBody = {
      authId: 'AUTOMATION',
      userId: 'AUTOMATION',
      source: 'MIMA',
      bookingID: bookingId,
      documentID: CreateGuid(),
      applicationName: 'mmtmidofficein',
      fileName,
      fileExtension: token[token.length - 1],
      fileContent: fileDataBase64Str,
      createdDate: new Date(),
    };
    return fetch(`${UPLOAD_ATTACHMENT_URL}`, {
      headers: await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo),
      method: 'POST',
      body: JSON.stringify(requestBody),
    });
  };

  const uploadResponseHandler = async (res) => {
    const fileUrls = [];
    if (!isEmptyArray(res)) {
      for (let i = 0; i < res.length; i++) {
        const result = await res[i].json();
        if (res[i].ok && result.statusCode === 200) {
          const { fileName, documentID, url } = result;
          fileUrls.push({
            fileName,
            documentId: documentID,
            url,
          });
        }
      }
    }
    if (fileUrls.length === 0) {
      showShortToast(uploadFailedText);
    } else {
      showShortToast(documentUploadSuccessText);
    }
    onReviewDetails(fileUrls);
  };

  return (
    <AncillaryClaimProcess
      {...{
        loading: loaderMsg || claimReviewInProgress,
        loaderMsg,
        error: claimReviewData
          ? claimReviewData.error || !isNullOrEmptyObject(claimReviewData.buinsessError)
          : null,
        onRetry: loadData,
        pageName,
      }}
    >
      {headerComp}
      <ScrollView contentContainerStyle={AtomicCss.paddingBottom70}>
        {proofInfo && (
          <UploadProofCard {...proofInfo} onFileChanged={onFileChanged} fileList={proofList} />
        )}
        {processInfo && <RaiseRequestProcessCardV2 {...processInfo} />}
        {impInfo && !isEmptyArray(impInfo.messages) && (
          <View style={styles.whiteCard}>
            <LinearGradient
              start={{ x: 0.1, y: 0.0 }}
              end={{ x: 0.0, y: 1.0 }}
              colors={['#ff3e5e', '#ff7f3f']}
              style={[styles.importantInofTag]}
            >
              <Text style={[styles.tagStyle, fonts.boldFontFamily, fsStyle.font10]}>
                {impInfo.title}
              </Text>
            </LinearGradient>
            <InfoListCard importantInformation={impInfo.messages} />
          </View>
        )}
      </ScrollView>
      {!isNullOrEmptyObject(action) && (
        <View style={styles.footerBtnWrapper}>
          <ReviewButton
            buttonText={action.actionText}
            clickHandler={onContinueClicked}
            disabled={false}
          />
        </View>
      )}
    </AncillaryClaimProcess>
  );
};

export default ReviewAncillaryRefund;
