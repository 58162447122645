import React from 'react';
import Actions from '../../../navigation/postSalesNavigation';
import {
  Alert,
  DeviceEventEmitter,
  Image,
  Platform,
  ScrollView,
  Text,
  ToastAndroid,
  TouchableOpacity,
  View,
  NativeModules
} from 'react-native';
import PropTypes from 'prop-types';
import Breadcrumb from './Breadcrumb';
import StickyHeader from '../../../hotel/details/components/StickyHeader';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import ClaimProcessList from './ClaimProcessList';
import styles from './BusCancelledByOperatorReviewCss';
import AddSupportingInfoStyle from './AddSupportingInfoCss';
import SpecialClaimAddAttachmentModule from '@mmt/legacy-commons/Native/SpecialClaimAddAttachmentModule';
import OverlayMessage from './OverlayMessage';
import { isNetworkAvailable } from '@mmt/legacy-commons/Common/utils/AppUtils';
import {
  BUS_CANCELLATION_URL,
  getCommonHeaders
} from '../../../utils/NetworkUtils';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import SpecialClaimBaseClass from './SpecialClaimBaseClass';
import ReviewButton from './ReviewButton';
import { headerIcon, tick3, deleteIcon, oneway } from '../../BusImageConstants';
import { label } from '@mmt/post-sales/src/staticData/staticData';
import { getFont } from '../../../PostSalesStyles';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import BusBookingTrackingHelper from './../../BusBookingTrackingHelper';
import { PageName } from './../../BusBookingDetailsConstant';

const STEPS = [
  'SELECT DETAILS',
  'CONFIRM CANCELLATION'
];

export default class BusCancelledByOperatorReview extends SpecialClaimBaseClass {
  constructor(props) {
    super(props, 'BusCancelledByOperatorReview', 'BusCancelledByOperatorReview');
    this.showAgreeOverlay = this.showAgreeOverlay.bind(this);
    this.specialClaimData = JSON.parse(this.props.specialClaimData);
    this.selectedPassenger = this.props.selectedPassenger;
    this.state = {
      activeStep: 1,
      response: this.props.response,
      viewState: ViewState.SHOW_DETAIL,
      attachmentLst: [],
      displayAgreeOverlay: false,
      showFileSelection: true
    };
    this.data = new FormData();
    this.attachedData = [];
    this.steps = [label('bus.claim.claimDetails'), label('bus.claim.confirmCancellation')];
    this.fonts = getFont(true);
  }

  onClickEventTracker = (omnitureClickEvent) => {
    BusBookingTrackingHelper.trackClickEvent(
      PageName.TRIP_DETAILS,
      undefined,
      omnitureClickEvent,
    );
  };

  showAgreeOverlay() {
    this.onClickEventTracker('Operator_Submit_Claim');
    this.setState({
      displayAgreeOverlay: true
    });
  }

  render() {
    return (
      <View style={styles.pageScroll}>
        {this.state.viewState === ViewState.LOADING && this.renderProgressView(label('bus.loading'))}
        {this.state.viewState === ViewState.NO_INTERNET && this.renderNoNetworkView()}
        {this.state.viewState === ViewState.ERROR && this.renderErrorView()}
        {this.state.viewState === ViewState.SHOW_DETAIL && this.renderPage()}
      </View>
    );
  }

  componentDidMount() {
    super.componentDidMount();
    this.trackPageLoad(this.state.response, this.specialClaimData, this.props.bookingState);
  }

  componentWillMount() {
    DeviceEventEmitter.addListener('special_claim_review_page', this.fetchAttachment.bind(this));
  }

  fetchAttachment(data) {
      const map = JSON.parse(JSON.stringify(this.state.attachmentLst));

      if(!isNullOrEmpty(this.state.attachmentLst)){
        map.push(data);
        this.setState({
            attachmentLst: map
        });
      }

      this.setState({
        showFileSelection: true
      });
  }

  updateAttachmentList = (map) => {
    this.setState({ attachmentLst: map })
  }

  getAttachmentHtmlView() {
    const attachmentHtmlView = [];
    this.state.attachmentLst.forEach(function (obj) {
      attachmentHtmlView.push(<View style={AddSupportingInfoStyle.attachedItem}>
        <View style={[AddSupportingInfoStyle.flexRow, AddSupportingInfoStyle.alignCenter]}>
          <View style={AddSupportingInfoStyle.attachedImgWrapper}>
            <Image
              style={AddSupportingInfoStyle.attachedImg}
              source={{ uri: `file://${obj.filePath}` }}
            />
          </View>
          <Text style={AddSupportingInfoStyle.attachedImgName}>{obj.fileName}</Text>
          <Image style={AddSupportingInfoStyle.greenTick} source={tick3} />
        </View>
        <TouchableOpacity
          onPress={() => this.onDelAttachment(obj.filePath)}
          style={AddSupportingInfoStyle.imgDeleteBtn}
        >
          <Image style={AddSupportingInfoStyle.deleteIcon} source={deleteIcon} />
        </TouchableOpacity>
      </View>);
    }, this);
    return attachmentHtmlView;
  }

  getSegmentView() {
    const sgmntView = [];
    const { fonts } = this;

    let obj = {};
    obj.originCityName = this.props.response.busBookingInfo.busDetail.fromCity;
    obj.originCityCode = this.props.response.busBookingInfo.busDetail.fromCity;
    obj.destinationCityName = this.props.response.busBookingInfo.busDetail.toCity;
    obj.destinationCityCode = this.props.response.busBookingInfo.busDetail.toCity;
    obj.travelDateTime = this.props.response.busAdditionalInfo.departureDetail.departureTimeInTwentyFourHoursFormat + " " + this.props.response.busAdditionalInfo.departureDetail.departureDay + " " + this.props.response.busAdditionalInfo.departureDetail.departureDayInMonth
      + " " + this.props.response.busAdditionalInfo.departureDetail.departureMonth + " " + this.props.response.busAdditionalInfo.departureDetail.departureYear
    obj.noOfTravellers = this.props.response.busBookingInfo.noOfPassengers;

    sgmntView.push(<View key={obj.originCityCode} style={[styles.segmentCard, styles.listWrapper]}>
      <View style={styles.destRow}>
        <Text style={[styles.cityText, fonts.boldFontFamily, fonts.font16, AtomicCss.lineHeight24]}>{obj.originCityCode}</Text>
        <Image style={styles.arrowIcon} source={oneway} />
        <Text style={[styles.cityText, fonts.boldFontFamily, fonts.font16, AtomicCss.lineHeight24]}>{obj.destinationCityCode}</Text>
        <Text style={[styles.smallText, fonts.semiBoldFontFamily]}>{obj.travelDateTime}</Text>
        <Text style={[styles.smallText, fonts.semiBoldFontFamily]}> | {obj.noOfTravellers} {obj.noOfTravellers == 1 ? label('bus.traveller') : label('bus.travellers')}</Text>
      </View>
    </View>);

    return sgmntView;
  }

  renderPage() {

    let specialClaimData = this.props.response.specialClaimDataList[0];
    let claimProcess = specialClaimData.claimProcess;
    const { fonts } = this;

    return (
      <View style={styles.pageWrapper}>
        <StickyHeader
          iconSrc={headerIcon}
          title={this.getTitle()}
          headerColor={['#ffffff', '#ffffff']}
          titleStyle={[styles.headerTitle, fonts.boldFontFamily, fonts.font16]}
          onBackPress={this.goBack}
        />
        <Breadcrumb activeStep={this.state.activeStep} data={this.steps} />
        <ScrollView showsVerticalScrollIndicator={false} style={styles.pageScroll}>
          <View style={styles.pageScrollTopSpace} />
          <View style={styles.whiteCard}>
            {this.getSegmentView()}
          </View>
          {this.addSupportingInformation()}
          {this.specialClaimData.refundAmtTxt ? <View style={styles.whiteCard}>
            <Text style={[styles.cardTitle, fonts.lightFontFamily, fonts.font21]}>{specialClaimData.refundAmtHdr}</Text>
            <Text style={[styles.paraText, fonts.regularFontFamily, fonts.font14]}>
              {this.specialClaimData.refundAmtTxt}
            </Text>
          </View> : null}

          <View style={styles.whiteCard}>
            <Text style={[styles.cardTitle, fonts.lightFontFamily, fonts.font21]}>{claimProcess.header}</Text>
            <Text style={[styles.paraText, styles.marginBottom20, fonts.regularFontFamily, fonts.font14]}>
              {claimProcess.description}
            </Text>
            <ClaimProcessList processList={claimProcess.processList} />
          </View>
          <View style={styles.pageScrollBtmSpace} />
        </ScrollView>
        <View style={styles.footerBtnWrapper}>
          <ReviewButton buttonText={specialClaimData.submitClaim} clickHandler={this.showAgreeOverlay} disabled={false} />
        </View>
        {this.state.displayAgreeOverlay &&
          <OverlayMessage
            closePopupHandler={this.closePopup}
            proceedClickHandler={this.onContinueClicked}
            specialClaimData={this.specialClaimData}
          />
        }
      </View>
    );
  }
  fileUpload() {
    const { FileUploaderModule: { FileUploader } } = NativeModules;
    return (
      <FileUploader
        onFileChanged={this.updateAttachmentList}
        attachmentLst={this.state.attachmentLst}
        text="ADD ATTACHMENT"
        showFileSelection={this.state.showFileSelection}
      />
    )
  }

  addSupportingInformation() {
    const { fonts } = this;
    return (
      <View style={styles.whiteCard}>
        <Text style={[styles.cardTitle, fonts.lightFontFamily, fonts.font21, AtomicCss.lineHeight34]}>{label('bus.claim.addSupportInfo')}</Text>
        <Text style={[styles.paraText, fonts.regularFontFamily, fonts.font14]}>
          {label('bus.claim.description')}
        </Text>

        <View>
          {this.getAttachmentHtmlView()}
        </View>
        {
          (Platform.OS === 'web') ?
            (<TouchableOpacity onPress={this.fetchAttachment.bind(this)}>
              {this.state.attachmentLst.length === 0 && this.fileUpload()}
            </TouchableOpacity>) :
            (<TouchableOpacity onPress={this.onAddAttachment}>
              <Text style={[styles.anchorMD, styles.marginTop20, fonts.boldFontFamily, fonts.font14]}>
                {this.state.attachmentLst.length > 0 ? label('bus.claim.anotherAttachment') : label('bus.claim.anotherAttachment')}
              </Text>
            </TouchableOpacity>)
        }
      </View>
    )
  }
  closePopup = () => {
    this.setState({
      displayAgreeOverlay: false
    });
  };


  getTitle = () => `Claim Refund: ${this.specialClaimData.scName}`;

  goBack = () => {
    super.onBackIconPress();
  };

  onContinueClicked = () => {
    this.submitSpecialClaim();
  };

  getAttachmentBase64String() {
    const result = [];
    this.state.attachmentLst.forEach((obj) => {
      result.push(obj.filePath);
    }, this);

    let imagePath = result[0];

    let imageResponse = '';

    if (Platform.OS === 'ios' || Platform.OS === 'web') {
      this.state.attachmentLst.forEach((obj) => {
        if (obj.filePath === imagePath) {
          imageResponse = obj.data;
        }
      }, this);
    } else {
      imageResponse = SpecialClaimAddAttachmentModule.convertToBase64(imagePath);
    }

    return imageResponse;
  }


  onAddAttachment = () => {
    this.onClickEventTracker('Operator_Add_attachment')
    if (this.state.attachmentLst.length >= 1) {
      ToastAndroid.show('Max 1 attachments allowed!', ToastAndroid.LONG);
    } else {
      SpecialClaimAddAttachmentModule.onButtonClicked();
    }
  };

  onDelAttachment = (key) => {
    const map = this.state.attachmentLst;
    map.splice(map.findIndex(a => a === key), 1);
    this.setState({
      attachmentLst: map,
      showFileSelection: false
    });
  };

  onHardBackPress = () => {
    if (this.state.displayAgreeOverlay) {
      this.closePopup();
      return true;
    }
    return false;
  };

  async submitSpecialClaim() {
    try {
      this.setState({
        viewState: ViewState.LOADING
      });
      const hasNetwork = await isNetworkAvailable();
      if (!hasNetwork) {
        Alert.alert(label('bus.checkInternetConnnection'));
        return;
      }
      const userInfo = await HotelBookingModule.getRequestHeader(this.props.bookingId, 'BUS_SPECIAL_CLAIM');
      let attachmentBase64Str = this.getAttachmentBase64String();

      let attachmentReqList = [];
      attachmentReqList.push(JSON.stringify(attachmentBase64Str));

      const request = {
        bookingId: this.props.bookingId,
        preview: false,
        attachments: attachmentReqList,
        refundOption: 'RefundToCustomer',
        lineNumbers: this.props.linenos,
        specialClaim: true
      };

      const response = await
        fetch(BUS_CANCELLATION_URL, {
          method: 'POST',
          body: JSON.stringify(request),
          headers: await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo)
        });

      if (response.ok) {
        const body = await
          response.json();
        if (body.status){
          Actions.busCancelledByOperatorThankYou({
            response: this.state.response,
            specialClaim: this.specialClaimData,
            bookingState: this.props.bookingState,
            bookingId: this.props.bookingId
          });
        }
        else {
          this.setState({
            viewState: ViewState.ERROR,
          });
        }
      } else {
        this.setState({
          viewState: ViewState.ERROR
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
}

BusCancelledByOperatorReview.propTypes = {
  specialClaimData: PropTypes.string.isRequired,
  bookingId: PropTypes.string.isRequired,
  lobCode: PropTypes.string.isRequired,
  selectedPassenger: PropTypes.object.isRequired
};
