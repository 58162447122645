import React, {Component} from 'react';
import {Image, Text, TouchableOpacity, View} from 'react-native';
import PropTypes from 'prop-types';

import styles from './MetroReviewRefundChargeCss';
import CommonStyle from '@mmt/legacy-commons/Styles/AtomicCss';
import {getImageIcon} from '../../MetroBookingDetailUtil';
import {addRupeeSign} from '../../../Common/commonUtil';

const REFUND_BREAKUP = {
  SHOW: 'View Breakup',
  HIDE: 'Hide Breakup'
};

export default class FareBreakup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fareBreakupDtls: true
    };
  }

  toggleBreakup = () => {
    this.setState({
      fareBreakupDtls: !this.state.fareBreakupDtls
    });
  };

  render() {
    const activeDtls = this.state.fareBreakupDtls ? REFUND_BREAKUP.HIDE : REFUND_BREAKUP.SHOW;
    return (
      <View style={styles.fareBreakUpWrapper}>
        <View style={[styles.fareBreakupHeading]}>
          <View style={CommonStyle.flexRow}>
            <Text
              style={[styles.fbHeadingTxt, {color: this.props.amountPaidBreakup.lbl.color}]}
            >{this.props.amountPaidBreakup.lbl.text}
            </Text>
            <TouchableOpacity onPress={this.toggleBreakup} activeOpacity={0.5}>
              <Text style={styles.linkTxt}>{activeDtls}</Text>
            </TouchableOpacity>
          </View>
          <Text
            style={[styles.totalPaidPriceTxt, {color: this.props.amountPaidBreakup.lbl.color}]}
          >{addRupeeSign(this.props.amountPaidBreakup.lbl.value)}
          </Text>
        </View>
        {this.state.fareBreakupDtls &&
          <View style={[styles.fareBreakupDtlsWrapper]}>
            {this.addDeductionBreakup(this.props.amountPaidBreakup.pricingComponents)}
          </View>
        }
        {this.getPaidAttributeMessage()}
      </View>
    );
  }

  addDeductionBreakup = pricingComponent => pricingComponent.map((pricingBreakup, index) => {
    const arrayLength = pricingComponent.length;
    return (
      <View
        key={index}
        style={arrayLength === index + 1 ? {marginBottom: 0} : {marginBottom: 20}}
      >
        {this.addPaidAmountBreakup(pricingBreakup.components)}
      </View>
    );
  });

  addPaidAmountBreakup = components => components.map(component => (
    <View
      key={component.desc}
      style={styles.fareBrkupRow}
    >
      <View style={[CommonStyle.flexRow, styles.descLeftWrap]}>
        <Image style={styles.dbIconSm} source={getImageIcon(component.img)} />
        <Text style={styles.fareBrkupTxt}>{component.desc}</Text>
      </View>
      <View style={[CommonStyle.flexRow, CommonStyle.alignSelfFlexEnd, styles.priceText]}>
        {
          component.showSlasher &&
          <Text
            style={[styles.fareBrkupTxtPrice, styles.lineThrough, styles.priceGap]}
          >{component.slashedValue}
          </Text>
        }
        <Text style={styles.fareBrkupTxtPrice}>{` ${addRupeeSign(component.actualValue)}`}</Text>
      </View>
    </View>
  ));

  getPaidAttributeMessage = () => {
    const views = [];
    this.props.paidmessages.forEach((attribute) => {
      if (attribute) {
        const attributeObj = attribute;
        const imageIcon = getImageIcon(attributeObj.attributImg);
        views.push(<View
          key={attributeObj.attributeSubTitle}
          style={CommonStyle.flexRow}
        >
          {imageIcon && <Image
            style={styles.redFareInfoIconSm}
            source={imageIcon}
          />}
          <Text
            style={[styles.fareBreakupAttDisc, {color: attributeObj.attributSubTitleColor}]}
          >{attributeObj.attributSubTitle}
          </Text>
        </View>);
      }
    });
    return views;
  }
}

FareBreakup.propTypes = {
  amountPaidBreakup: PropTypes.object.isRequired,
  paidmessages: PropTypes.array.isRequired,
};
