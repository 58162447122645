import React, {Component} from 'react';
import { View, Text, StyleSheet, TextInput } from 'react-native';
import AtomicCss from '../../../commonStyles/AtomicCss';

const DescribeIssueCard = (props) => {
  return (
        <View style={[AtomicCss.whiteCardSection, styles.padding16, AtomicCss.marginBottom10]}>
      <Text style={[AtomicCss.defaultText, AtomicCss.font16, AtomicCss.boldFont, AtomicCss.marginBottom10]}>{props.busSupportData.description}</Text>
          <TextInput
              style={styles.TextInputStyleClass}
              underlineColorAndroid="transparent"
              placeholderTextColor={"#000000"}
              autoCorrect={false}
              numberOfLines={8}
              multiline={true}
              onChangeText={description => props.updateDescription(description)}
            />
      <Text style={[AtomicCss.regularFont, AtomicCss.font10, AtomicCss.defaultText, AtomicCss.alignRight]}>{props.busSupportData.descriptionLength}</Text>

        </View>
    );
}

const styles = StyleSheet.create({
  padding16:{padding: 16},
  TextInputStyleClass:{
    padding: 10,
    textAlignVertical: 'top',
    marginBottom: 5,
    lineHeight: 17,
    height: 88,
    borderWidth: 1,
    borderColor: '#e7e7e7',
    borderRadius: 4 ,
    backgroundColor : "#FFFFFF",
    }
});

export default DescribeIssueCard;
