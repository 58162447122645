import { StyleSheet } from 'react-native';

export default (theme, fonts) =>
  StyleSheet.create({
    innerWrapper: {
      marginTop: 20,
      borderTopColor: theme.color.greyBookedSeat,
      paddingTop: 20,
      borderTopWidth: 1,
    },
    processCardWrapper: {
      marginBottom: 30,
    },
    headingTxt: {
      color: theme.color.black,
      fontSize: fonts.fontSize.font16,
      ...fonts.blackFontFamily,
      marginBottom: 25,
    },
    processRow: {
      paddingLeft: 16,
    },
    refundProcessRow: {
      borderLeftWidth: 2,
      borderLeftColor: theme.color.paleOrange,
      paddingLeft: 32,
      flexDirection: 'row',
      paddingBottom: 40,
    },
    borderLstItem: {
      borderLeftWidth: 0,
      borderLeftColor: 'transparent',
      paddingBottom: 0,
    },
    circle: {
      width: 32,
      height: 32,
      backgroundColor: theme.color.grey22,
      borderRadius: 50,
      position: 'absolute',
      left: 0,
      zIndex: 111,
      top: -8,
      alignItems: 'center',
      justifyContent: 'center',
    },
    circleInner: {
      width: 32,
      height: 32,
      backgroundColor: theme.color.creamWhite,
      borderWidth: 1,
      borderColor: theme.color.paleOrange,
      borderRadius: 30,
      alignItems: 'center',
      justifyContent: 'center',
    },
    titleStyle: {
      color: theme.color.black,
      ...fonts.boldFontFamily,
      fontSize: fonts.fontSize.font14,
      marginBottom: 3,
      flex: 1,
    },
    circleCounter: {
      ...fonts.blackFontFamily,
      color: theme.color.lightYello,
      fontSize: fonts.fontSize.font14,
    },
    descStyle: {
      color: theme.color.defaultTextColor,
      ...fonts.regularFontFamily,
      fontSize: fonts.fontSize.font12,
    },
    defaultText: {
      color: theme.color.defaultTextColor,
    },
    flex1: {
      flex: 1,
    },
    moreDtlsSection: {
      paddingHorizontal: 12,
      paddingVertical: 8,
      backgroundColor: theme.color.creamWhite,
      borderRadius: 4,
    },
    infoTxtColor: {
      color: theme.color.lightYello,
      ...fonts.boldFontFamily,
      fontSize: fonts.fontSize.font14,
      lineHeight: 20,
    },
    infoTxtBold: {
      ...fonts.boldFontFamily,
    },
  });
