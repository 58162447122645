import React from 'react';
import { View, StyleSheet } from 'react-native';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import HTMLView from 'react-native-htmlview';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

interface ShareOtpSectionProps {
  otpText: string;
}

const ShareOtpSection: React.FC<ShareOtpSectionProps> = ({ otpText, customStyle }) => {
  const { psTheme: theme } = useTheme();
  const { color } = theme;
  const {fsStyle, ...fonts} = getFont();
  const styles = createStyles(color);
  const htmlStyle = getHtml(fonts, color);
  if (!otpText) {
    return null;
  }

  return (
    <View style={[
      customStyle,
      styles.shareOTPSection]}>
        <HTMLView stylesheet={htmlStyle} value={`<p>${otpText}</p>`} />
    </View>
  );
};

const createStyles = (color: any) =>
  StyleSheet.create({
    shareOTPSection: {
      zIndex: 5,
      backgroundColor: color.black,
      paddingTop: 10,
      paddingBottom: 30,
      borderTopLeftRadius: 16,
      borderTopRightRadius:16,
      position: 'relative'
    },
  });

  const getHtml = (fonts, color) => {
    return StyleSheet.create({
      p : {
        fontSize: fonts.fontSize.font12,
        color: color.grayBg,
        textAlign: 'center'
      },
      b : {
        fontSize: fonts.fontSize.font18,
        color: color.white,
        fontWeight: '800'
      }
    })
  }

export default ShareOtpSection;
