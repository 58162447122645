/* Postsales Images */
import { getImagePath } from '../utils/PostSaleUtil';

export const ALERT_RED_BG_CLOUD_ICON = getImagePath('hotel_add_pax_names_failure.webp');
export const PAX_GOLD_BG_ICON = getImagePath('hotel_add_pax_names_success.webp');
export const LUNCH_ICON = getImagePath('lunchIcon.webp');
export const HELP_ICON = getImagePath('helpIcon.webp');
export const CROSS_ICON1 = getImagePath('crossIcon1.webp');
export const GUEST_ICON = getImagePath('guest_icon.webp');
export const EDIT_PENCIL_ICON = getImagePath('changeplans.webp');
export const SUCCESS_GOLD_BG_ICON = getImagePath('succesfullIcon.webp');
export const SERVICE_DESK_ICON = getImagePath('talkHostCardIcon.webp');
export const DOOR_ICON = getImagePath('rooms.webp');
export const REFUND_RUPEE_ICON = getImagePath('refundIcon.webp');
export const REFUND_RUPEE_ICON2 = getImagePath('ic_refundable_copy.webp');
export const RUPEE_GREY_BG_ICON = getImagePath('rupeeGreyBgIcon.webp');
export const CASH_IN_HAND_ICON = getImagePath('cashInHandIcon.webp');
export const GREEN_KEY_ICON = getImagePath('keyDone.webp');
export const GOLD_KEY_ICON = getImagePath('keyProgress.webp');
export const RED_KEY_ICON = getImagePath('keyRejected.webp');
export const INFO_ICON = getImagePath('extra-info-icon.webp');
export const RUPEE_ICON = getImagePath('extra-priceIcon.webp');
export const MAP_GREEN_MARKER_ICON = getImagePath('group_42.webp');
export const ENVELOPE_ICON = getImagePath('messageIcon.webp');
export const CALL_ICON = getImagePath('contactIcon.webp');
export const HOTEL_INFO_ICON = getImagePath('aminitiescard.webp');
export const HOTEL_DEFAULT_ICON = getImagePath('hotels_default_image.webp');
export const BACK_ARROW_BLACK_ICON = getImagePath('backArrowBlack.webp');
export const TICKET_SUCCESS_ICON = getImagePath('topstatusIcon1.webp');
export const BAGS_GOLD_GREEN_ICON = getImagePath('topstatusIcon3.webp');
export const JUICE_ICON = getImagePath('topstatusIcon4.webp');
export const BEACH_ICON = getImagePath('topstatusIcon5.webp');
export const THUMBS_UP_DOWN_ICON = getImagePath('topstatusIcon6.webp');
export const TICKET_FAIL_GREY_ICON = getImagePath('topstatusIcon7.webp');
export const TICKET_FAIL_RED_ICON = getImagePath('ic_failed.webp');
export const CLOCK_TICK_ICON = getImagePath('ic-on-hold.webp');
export const MEDICAL_WAIVER_ICON = getImagePath('medical-waiver.webp');
export const TICKET_DOUBLE_BLACK_SM_ICON = getImagePath('freeCancelCovered.webp');
export const NOT_DOUBLE_BLACK_ICON = getImagePath('CancelNotCovered.webp');
export const TICKET_TRIANGLE_ALERT_ICON = getImagePath('freeCancelExpired.webp');
export const GREEN_CANCEL_ICON = getImagePath('canIcon1.webp');
export const CANCEL_ICON = getImagePath('canIcon2.webp');
export const RED_CANCEL_ICON = getImagePath('canIcon3.webp');
export const CROSS_FREE_ICON = getImagePath('freeCancel.webp');
export const CIRCLE_CROSS_ICON = getImagePath('ic_cross.webp');
export const BLACK_INFO_ICON = getImagePath('info-icon2.webp');
export const PAYMENT_CARD_ICON = getImagePath('payment_icon.webp');
export const COTRAVELLER_ICON = getImagePath('ic_cotraveller.webp');
export const PAHX_SUCCESS_ICON = getImagePath('pahx_success_icon.webp');
export const GOLD_INFO_ICON = getImagePath('impinfo-icon.webp');
export const CHECKIN_DENIED_ICON = getImagePath('hotelCheckinDenied.png');
export const SERVICE_DESK_LIGHT_BLUE_BG_ICON = getImagePath('hotelServiceDesk.png');
export const CHECKIN_DENIED_ICON_MYBIZ = getImagePath('hotelCheckinDeniedMyBiz.png');
export const SERVICE_DESK_LIGHT_MYBIZ = getImagePath('hotelServiceDeskMyBiz.png');
export const CLOCK_AT_3_ICON = getImagePath('MakeRequestTime.webp');
export const BOW_ICON = getImagePath('MakeRequestSpecil.webp');
export const CROSS_FIND_ICON = getImagePath('cantFind.webp');
export const VERIFIED_STAY_100_ICON = getImagePath('100per-verified.webp');
export const CHAT_ICON = getImagePath('rate_your_stay.webp');
export const CARD_ICON = getImagePath('cardIcon.webp');
export const YELLOWISH_GREEN_TICK_ICON = getImagePath('tickIcon.webp');
export const CALL_HOTEL_ICON = getImagePath('MakeRequestCallHotel.webp');
export const ROUNDED_HOTEL_ICON = getImagePath('hotel-icon.webp');
export const BLUE_LOCATION_ICON = getImagePath('ic_viewlocation.webp');
export const CALL_BLUE_BG_ICON = getImagePath('ic_callhotel.webp');
export const ROUNDED_CHAT_BLUE_BG_ICON = getImagePath('ic_chatwithhotel.webp');
export const RESEND_BLUE_BG_ICON = getImagePath('ic_resendconfirmation.webp');
export const ID_BLUE_BG_ICON = getImagePath('ic_valid_id_proof.webp');
export const CHECKIN_BLUE_BG_ICON = getImagePath('ic_checkinathotel.webp');
export const HEADSET_BLUE_BG_ICON = getImagePath('ic_callmmtsupport.webp');
export const LETTER_INSTRUCTIONS_BLUE_BG_ICON = getImagePath('ic_gotohelpsection.webp');
export const CHAT_BLUE_BG_ICON = getImagePath('ic_chatwithus.webp');
export const WISH_WAND_ICON = getImagePath('ic_makerequest.webp');
export const HELP_BLUE_BG_ICON = getImagePath('write_to_us.webp');
export const LIVE_TRACKING_ICON = getImagePath('LiveTracking.webp');
export const TICKET_SUCCESS_BLUE_BG_ICON = getImagePath('PNRcheck.webp');
export const TRAIN_COACH_ICON = getImagePath('TrainCoach.webp');
export const MEAL_ICON = getImagePath('ic_meal.webp');
export const SHOCK_FACE_ERROR_ICON = getImagePath('errorImage.webp');
export const HOTEL_CROSS_RED_BG_ICON = getImagePath('dateChangeFailed.webp');
export const TOAST_BREADS_RED_BG_ICON = getImagePath('failedIcon2.webp');
export const HOTEL_GREEN_BG_ICON = getImagePath('successIcon2.webp');
export const INFO_CONCENTRIC_CIRCLES_RED_BG_ICON = getImagePath('info_2x.webp');
export const SUCCESSFUL_ICON = getImagePath('successfullIcon3.webp');
export const TICKET_DOUBLE_BLACK_LG_ICON = getImagePath('dBIcon.webp');
export const DOUBLE_TICK_ICON = getImagePath('double-tick.webp');
export const TICKET_RED_CROSS_ICON = getImagePath('cancelling-icon.webp');
export const PAN_CARD_ICON = getImagePath('pancard.webp');

export const CANCELLATION_ICON = getImagePath('cancellationIcon.webp');
export const NO_CANCELLATION_ICON = getImagePath('noCancellationIcon.webp');
export const BLACK_CANCELLATION_ICON = getImagePath('blackCancellationIcon.webp');
export const REWARDS_ICON = getImagePath('/V2/rewardIcon.webp');
export const SUPPORT_ICON = getImagePath('/V2/hotelIcon.webp');
export const CALL_HOST_ICON = getImagePath('/V2/callIcon.webp');
export const CHAT_HOST_ICON = getImagePath('/V2/chatIcon.webp');
export const CARE_TAKER_ICON = getImagePath('/V2/careTacker.webp');
export const VERIFIED_STAYS_ICON = getImagePath('verifiedStays.webp');
export const ROOMS_ICON = getImagePath('doorIcon.webp');
export const SINGLE_GUEST_ICON = getImagePath('guest.webp');
export const DOUBLE_GUEST_ICON = getImagePath('guestIcon.webp');
export const GREY_MAIL_ICON = getImagePath('mailGreyIcon.webp');
export const GREY_CALL_ICON = getImagePath('callGreyIcon.webp');
export const GREY_MAP_ICON = getImagePath('greyMapIcon.webp');
export const RED_MAP_ICON = getImagePath('mapRed.webp');
export const COPY_ICON = getImagePath('copyIcon.webp');
export const NEW_COPY_ICON = getImagePath('/V2/copy.webp');
export const DOWNLOAD_ICON = getImagePath('download.webp');
export const CALL_US_ICON = getImagePath('callUsIcon.webp');
export const GREEN_SHARE_ICON = getImagePath('shareGreen.webp');
export const GREEN_BACK_ARROW_ICON = getImagePath('backArrowBlack2.webp');
export const GREY_TICK_ICON = getImagePath('greyTick.webp');
export const MYSAFETY_DELLOITE_ICON = getImagePath('mySafetyDeloitte.webp');
export const SIZE_ICON = getImagePath('sizeIcon.webp');
export const BED_ICON = getImagePath('bedIcon.webp');
export const GARDEN_ICON = getImagePath('gardenIcon.webp');
export const WHITE_BG_INFO_ICON = getImagePath('infoIcon.webp');
export const PHOTOGRAPHS_ICON = getImagePath('photographs.webp');
export const FACILITIES_ICON = getImagePath('facilities.webp');
export const CONTACT_US_ICON = getImagePath('contactUs.webp');
export const MESSEGE_ICON = getImagePath('email.png');
export const RIGHT_ARROW_ICON = getImagePath('arrowRight.webp');
export const MYSAFETY_ICON = getImagePath('mySafety.webp');
export const MOON_CLOUD_ICON = getImagePath('moonIcon.webp');
export const CALENDAR_ICON = getImagePath('calendarIcon.webp');
export const TRAVELLERS_ICON = getImagePath('ic_travellers.webp');
export const MEALPLAN_ICON = getImagePath('ic_mealplan.webp');
export const PAYMENT_CARD_NEW_ICON = getImagePath('paymentCard.webp');
export const INFO_GREY_BG_ICON = getImagePath('infoIconGreyBg.webp');
export const EDIT_ICON = getImagePath('editIcon.webp');
export const CHAT_WITH_HOST_ICON = getImagePath('chatHostIcon.webp');
export const KEY_ICON = getImagePath('keyIcon.webp');
export const RTB_ACCEPTED_ICON = getImagePath('acceptedIcon.webp');
export const RTB_REJECTED_ICON = getImagePath('rejectedIcon.webp');
export const WHITE_MAP_ICON = getImagePath('/V2/locationIcon.webp');
export const BLUE_PHONE_ICON = getImagePath('/V2/phoneIcon.webp');
export const WHITE_DOWNLOAD_ICON = getImagePath('/V2/getVoucherIcon.webp');
export const HOSTEL_ICON = getImagePath('/V2/viewDtlsIcon.webp');
export const COTTAGE_ICON = getImagePath('/V2/Cottage.webp');
export const LANG_ICON = getImagePath('/V2/Language.webp')
export const GREY_STAR_ICON = getImagePath('grayStar.webp');
export const LEFT_LEAF_ICON = getImagePath('left_leaf.webp')
export const RIGHT_LEAF_ICON = getImagePath('right_leaf.webp')
export const ACCORDIAN_ICON = getImagePath('accordianIcon.webp');
export const PRICE_ICON = getImagePath('PriceIcon.webp');
export const DELETE_ICON = getImagePath('ic_delete.webp');
export const PRICE_CHANGE = getImagePath('pricechange.webp');
export const SOLD_OUT = getImagePath('soldout.webp');
export const NON_REFUNDABLE_ICON = getImagePath('noRefundIcon.webp');
export const CALENDAR_CANCELLATION_ICON = getImagePath('calendarBlackIcon.webp');
export const HOME_ICON = getImagePath('homeIcon.webp');
export const MMT_BLACK_ICON = getImagePath('mmtBlack.webp');
export const RED_CROSS_ICON = getImagePath('redCross.webp');
export const REFUND_BLUE_ICON = getImagePath('refundBlueIcon.webp');
export const DATE_CHANGE_ICON = getImagePath('dateChnageIcon.webp');
export const EDIT_BLUE_ICON = getImagePath('editBlueIcon.webp');
export const GUEST_MODIFICATION = getImagePath('guest_modification.webp');
export const GREEN_THUMBS_UP_UP_ICON = getImagePath('greenThumbsUp.webp');
export const RED_THUMBS_DOWN_ICON = getImagePath('redThumbsDown.webp');
export const PHONE_CLOCK_ICON = getImagePath('phoneClockIcon.webp');
export const MESSAGE2_ICON = getImagePath('message2.webp');
export const CARETAKER_ICON = getImagePath('caretaker.webp');
export const PROPERTY_DIMENSION_ICON = getImagePath('propertyDimensionIcon.webp');
export const FLAG_BLACK_BG = getImagePath('flagBlackBg.webp');
export const PINK_TIMER_ICON = getImagePath('pinkTimer.webp');
export const BLUE_BG_CALL_ICON = getImagePath('blueBgCallIcon.webp');
export const DOOR_WITH_BG_ICON = getImagePath('doorWithBG.webp');
export const PHONE_ICON = getImagePath('phone.webp');
export const CALENDER_ICON = getImagePath('calendar_new.webp');
export const RUPEE_SYMBOL_WHITE = getImagePath('rupeeSymbol.webp');
export const BEST_PRICE_BG = getImagePath('bestPriceGuarantee.webp');
export const CALENDAR_BLUE = getImagePath('calendar_blue.webp');
export const USER_ICON = getImagePath('user_icon.webp');
export const CROSS_ICON = getImagePath('crossIcon1.webp');
export const FLYER_EXCLUSIVE_ICON = getImagePath('flyer_exclusive.webp');
export const LATE_CHECK_IN_IMAGE = getImagePath('lateCheckinImage.png');
export const hotelIcon = getImagePath('v5hotelIcon.webp');

/* Common Images */
export const LEFT_BLACK_ARROW_ICON = require('@mmt/legacy-assets/src/trip_header_back_icon.webp');
export const CHEVRON_RIGHT_BLUE_ICON = require('@mmt/legacy-assets/src/blueRightArrow.webp');
export const WHITE_CROSS_ICON = require('@mmt/legacy-assets/src/whiteCross.webp');
export const CHEVRON_RIGHT_BLUE_ICON2 = require('@mmt/legacy-assets/src/right_arrow_blue.webp');
export const WHITE_LEFT_ARROW_ICON = require('@mmt/legacy-assets/src/back-white.webp');
export const GREEN_TICK_ICON = require('@mmt/legacy-assets/src/ic_tick.webp');
export const DOUBLE_GREEN_TICK_ICON = require('@mmt/legacy-assets/src/double_tick.webp');
export const LOCATION_MARKER_ICON = require('@mmt/legacy-assets/src/location_icon.webp');
export const INFO_RED_BG_ICON = require('@mmt/legacy-assets/src/infoIcon3.webp');
export const BLACK_LEFT_ARROW_ICON2 = require('@mmt/legacy-assets/src/back.webp');
export const FILL_STAR_ICON = require('@mmt/legacy-assets/src/fillstar.webp');
export const UNFILL_STAR_ICON = require('@mmt/legacy-assets/src/unfillstar.webp');
export const CHEVRON_RIGHT_BLUE_ICON3 = require('@mmt/legacy-assets/src/ic_arrow.webp');
export const TICK_GREEN_BG_ICON = require('@mmt/legacy-assets/src/greenTick.webp');
export const BLACK_TICK_GREEN_BG_ICON = getImagePath('success-icon.webp');
export const BLUE_RIGHT_ARROW_ICON = require('@mmt/legacy-assets/src/blueArrowRight.webp');
export const GREY_IOS_BACK_ICON = require('@mmt/legacy-assets/src/backIosGrey.webp');
export const HOLD_WARNING_ICON = getImagePath('holdWarning.webp');
export const PROMO_EXPIRY_ICON = getImagePath('promoExpiryWarningText.webp');
export const WHITE_TICK_GREEN_BG_ICON = getImagePath('tickSuccess.webp');
export const APPLE_WALLET_ICON = getImagePath('appleWalletIcon.png');
export const BOOKING_INVOICE = getImagePath('booking_invoice.webp');
export const BILL_MEMO = getImagePath('bill_memo.webp');
export const CHECKBOX_ACTIVE_BLUE_ICON2 = getImagePath('ic_pax_consent_chkboxactive.webp');
export const CHECKBOX_ACTIVE_BLUE_ICON3 = getImagePath('checkbox_tick.webp');
export const CHECKBOX_INACTIVE_ICON2 = getImagePath('ic_pax_consent_chkboxinactive.webp');


