export const ApiRequestTypes = {
  SUMMARY: 'SUMMARY',
  LOSTID_SUMMARY: 'LOSTID_SUMMARY',
  PLANNING_DETAILS: 'PLANNING_DETAILS',
  CANCEL_PLANNING_DETAILS: 'CANCEL_PLANNING_DETAILS',
  STATIC_TEXT_JSON: 'STATIC_TEXT_JSON',
};

export const BookingState = {
  UPCOMING: 'upcoming',
  ONTRIP: 'ontrip',
};

export const PAGE_BOOKINGS_STATUS = {
  ALL: 'ALL',
  UPCOMING: 'UPCOMING',
  PENDING: 'PENDING',
  CANCELLED: 'CANCELLED',
  FAILED: 'FAILED',
  COMPLETED: 'COMPLETED',
  UNSUCCESSFUL: 'UNSUCCESSFUL',
  LOSTID_SUMMARY: 'LOSTID_SUMMARY',
  PLANNING: 'PLANNING',
};

export const Lobs = {
  Flight: 'FLIGHT',
  Hotel: 'HOTEL',
  Bus: 'BUS',
  Trains: 'TRAIN',
  Cars: 'CAR',
  Rail: 'RAIL',
  Cabs: 'CAB',
  Metro: 'METRO',
  Acme: 'ACME',
  Activity: 'ACTIVITY',
  SelfDrive: 'SELFDRIVE',
  TGS : 'TGS',
  Visa: 'VISA',
  GiftCard: 'GIFTCARD',
  HOLIDAYS: 'HOLIDAYS',
  Planning: 'PLANNING',
  AdTechUpcomingCard: 'AdTechUpcomingCard',
  AdTechPendingCard: 'AdTechPendingCard',
  AdTechCancelledCard: 'AdTechCancelledCard',
  AdTechCompletedCard: 'AdTechCompletedCard',
  AdTechFailedCard: 'AdTechFailedCard',
  INSURANCE:'INSURANCE'
};

export const Constant = {
  POWERED_BY: 'Powered by', //These should move to backend in respected Summary API card data
  EXPERIENCE_IN: 'Experience in', //These should move to backend in respected Summary API card data
  IN: 'in', //These should move to backend in respected Summary API card data
  PURCHASED_ON: 'Purchased on', //These should move to backend in respected Summary API card data
  TRAVEL_DATE: 'Travel Date', //No need to move this to backend as this is not shown on UI
  BOOKING_DATE: 'Booking Date', //No need to move this to backend as this is not shown on UI,
  DUMMY_ADTECH_ID: 'DummyAdTechId',
};

export const BookingStates = {
  UPCOMING: 'UPCOMING',
  CANCELLED: 'CANCELLED',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
  LOST: 'LOST',
  UNSUCCESSFUL: 'UNSUCCESSFUL',
  LOSTID_SUMMARY: 'LOSTID_SUMMARY',
  PENDING: 'PENDING',
};

export const BookingStatusCode = {
  FAILED: '2',
  INSTANT_UNCONFIRMED: '9',
};

export const MyTripsCards = {
  TRIP_IDEAS_CARD: "Trip_ideas",
  AD_TECH_CARD: "Adtech_card",
  ADD_BOOKING:"Add_Booking"
};

export const MyTripsCardsTitle = {
  AD_TECH_CARD_TITLE: 'AdTechSection'
};

export const MyTripsCardsVisibility = {
  ALWAYS: 2,
  WITH_BOOKING: 1,
  WITHOUT_BOOKING: 0
}

export const MSR_TYPE = {
  TALK_TO_US: 'TALK_TO_US',
  ESCALATE: 'ESCALATE'
}

export const TalkToUsConstants = {
  INTERNATIONAL_HOTEL: 'International Hotel',
  DOMESTIC_HOTEL: 'Domestic Hotel',
  INTERNATIONAL_FLIGHT: 'International Air',
  DOMESTIC_FLIGHT: 'Domestic Air',
  INTERNATIONAL_GROUND: 'International Ground',
  DOMESTIC_GROUND: 'Domestic Ground'
}

export const CallNowRequestConstants = {
  SECONDARY_TYPE: 'Booking Related Issues',
  TERNARY_TYPE: 'Talk to us',
  SOURCE: 'mytrips',
}

export const MAX_SWITCHER_BOOKING_COUNT_TO_SHOW = 10;

export const MY_TRIPS_CORPORATE_DEEPLINK = 'mmyt://mytrips?isCorporate=true';

export const LOAD_MORE_THRESHOLD = 200;

export const BOOKING_SUMMARY_VIEW = {
  INITIAL: 'INITIAL',
  NEW: 'NEW',
  OLD: 'OLD'
}

export default {};
