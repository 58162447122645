import { StyleSheet } from 'react-native';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';

export default (theme) =>
  StyleSheet.create({
    cardWrapper: {
      backgroundColor: theme.color.white,
      borderRadius: 10,
      marginHorizontal: 12,
      marginBottom: 12,
      padding: 12,
      ...getPlatformElevation(2),
    },
    flyrDealOfferIcon: {
      width: 20,
      height: 27
    },
    cashbackOfferIcon: {
      width: 17,
      height: 17
    },
    flyerStyleWrapper: {
      height: 22,
      borderWidth: 1,
      justifyContent: 'center',
      borderLeftWidth: 0,
      borderColor: theme.color.blue15,
      backgroundColor: theme.color.lightBlue10,
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4
    },
    flyerTagName:{
      color: theme.color.blue15,
    },
    flyrDealDesc: {
      lineHeight: 14,
      marginTop: 5,
      marginBottom: 5
    },
    flyrDealInfoHead: {
      color: theme.color.lightGreen18,
      marginTop: 8
    },
    flyerDealInfoDescription: {
     color: theme.color.defaultTextColor
    }
  });
