import React from 'react';
import {
  Animated,
  BackHandler,
  Dimensions,
  Easing,
  Platform,
  ScrollView,
  Text,
  TouchableHighlight,
  View,
  NativeModules,
  DeviceEventEmitter,
  UIManager,
  LayoutAnimation,
  AppState,
} from 'react-native';
import BasePage from '../Common/PostSalesBasePage';
import styles from './styles/CabMamiCss';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import ProgressView from '@mmt/legacy-commons/Common/Components/Progress/ProgressView';
import {
  getBasePath,
  getInitFlowType,
  getPageName,
  getTopStatusCardId,
  isSelfServerEnabled,
  openCabBookingDetail,
  myGateView
} from './utils/CabBookingDetailUtil';
import TravellerCard from './components/TravellerCard';
import PriceBreakUpCard from './components/PriceBreakUpCard';
import RefundStatusCard from './components/RefundStatusCard';
import NeedHelpCard from './components/NeedHelpCard';
import CabBookingDetailsConstant, {
  CLICK_EVENT,
  CabBookingABKeysShortened,
  CabTrackerStatuses,
  DeepLinkType,
  EVENT_EMITTER_CONTS,
  PAGE_NAME,
  PokusesToTrackOnLoad,
} from './CabBookingDetailsConstant';
import {
  AUTH_FAILED_MESSAGE,
  CHAT_WITH_MYRA_CARD,
  COMMON_CARD,
  LOBNAMES,
} from '../PostSalesConstant';
import ChatWithMyraCard from '../Common/ChatWithMyraCard';
import { isLostBookingData, isMyraEnabled } from '../utils/PostSaleUtil';
import CabBookingTrackingHelper from './CabBookingTrackingHelper';
import InclusionExclusionCard from './components/InclusionExclusionCard';
import CabMainCard from './components/CabMainCard';
import CabOnSpotInfoOverlay from './components/CabOnSpotInfoOverlay';
import { resetToFlightBookingDetail } from '../flights/FlightBookingUtil';
import RefundTracker from '../Common/RefundTracker';
import { isNotNullAndEmpty } from '../hotel/details/utils/HotelBookingDetailUtil';
import AlertInfo from '../Common/CommonImpInfo';
import TrackYourCabCard from './components/TrackYourCabCard';
import SupportCard from './components/Support/SupportCard';
import MSRMainCard from '../Common/MSR/MainCard';
import { topStatusConfirmedIcon, topStatusCancelledIcon } from './CabImageConstants';
import TopStatusCard from './modules/bookingDetails/components/TopStatusCardV2';
import TripDetailsCard from './components/CabBookingDetail/TripDetailsCard';
import CabAndDriverDetailsCard from './components/CabBookingDetail/CabAndDriverDetailsCard';
import MMTBlackCard from '../Common/MMTBlackCard';
import PriceBreakUpWithPayOnlineOption from './components/PriceBreakUpWithPayOnlineOption';
import isEmpty from 'lodash/isEmpty';
import Actions from '../navigation/postSalesNavigation';
import MySafetyCard from './components/MySafetyCard';
import FlightBookingModule from '@mmt/legacy-commons/Native/FlightBookingModule';
import CabBookingModule from '@mmt/legacy-commons/Native/CabBookingModule';
import ViewControllerModule from '@mmt/legacy-commons/Native/ViewControllerModule';
import { getRootTag } from '@mmt/legacy-commons/AppState/RootTagHolder';
import NoInternetView from '@mmt/legacy-commons/Common/Components/Error/NoInternetView';
import AdvisoryNotice from './modules/bookingDetails/components/AdvisoryNotice';
import AdvisoryOverlay from '../Common/AdvisoryNotice/AdvisoryOverLay';
import OtherInfo from '../Common/OtherInfo/OtherInfo';
import AmenitiesCard from './components/AmenitiesCard';
import CabChatWithDriverCard from './components/CabChatWithDriverCard';
import HourlyTripDetails from './components/HourlyTripDetails';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import CabSummaryCard from './components/CabSummaryAddonCard';
import { REFUND_OOT_TRACKING } from '../Common/RefundOutOfTATForm/constants';
import LostIDBookingDetailPage from '../Common/LostIdDetailPage';
import RydeBannerCard from './components/rydeBenifits/rydeBannerCard';
import { POST_SALES_CAB_DETAILS_PAGE } from '@mmt/legacy-commons/adsConfig/adsConfigConstants';
import CommonBottomOverlayMessage from '../Common/CommonOverlayMessage/CommonBottomOverlayMessage';
import RydeBottomOverlayMessageComponent from './components/rydeBenifits/rydeBottomOverlayMessageComponent';
import { setDataInStorage, getDataFromStorage } from '@mmt/legacy-commons/AppState/LocalStorage';
import { ThemeContext } from '../theme/theme.context';
import CabVendorInfoCard from './components/CabVendorInfoCard';
import CabVoucherInfoCard from './components/CabVoucherInfoCard';
import addBookingTrackingHelper, {
  ADD_BOOKING_TRACKING_EVENTS,
} from '../summary/components/AddBooking/tracking';
import CabsSharedModuleHolder from '@mmt/cabs-shared/src';
import SpecialClaimCard from './components/specialclaimcard';
import OverlayMessage from '../Common/OverlayMessage';
import { getAdTechCard, isEasyPayNativeFlowEnabled } from '../Common/commonUtil';
import { getGdprData, shouldShowGdprBlockerScreen } from '../utils/PostSaleUtil';
import CabUpsellCard from './modules/bookingDetails/components/CabUpsellCardV2';
import {
  fetchCabBookingDetails,
  fetchOnlinePaymentDetail,
  userConfirmApi,
} from './modules/bookingDetails/data/api';
import { trackError } from './utils/CabBookingDetailUtil';
import ConfirmedCabDetailCard from './modules/bookingDetails/components/ConfirmedCabDetailCard';
import CabDriverAssigned from './modules/bookingDetails/components/CabDriverAssignedV2';
import TopStatusOverlay from './modules/bookingDetails/components/TopStatusOverlay';
import CancellationAndModificationCard from './modules/bookingDetails/components/CancellationAndModificationCard';
import PayDriverRatingCard from './modules/bookingDetails/components/PayDriverRatingCard';
import KioskBookingConfirmed from './modules/bookingDetails/components/KioskBookingConfirmed';
import TripModification from './modules/bookingDetails/components/TripModification';
import NewCommonOverlay from '../Common/NewCommonOverlay';
import CancellationPolicyOverlay from './modules/bookingDetails/components/CancellationPolicyOverlay';
import UserConfirmationCard from './modules/bookingDetails/components/UserConfirmationCard';
import TollRefundDetails from './modules/bookingDetails/components/TollRefundDetails';
import createStyles from './modules/bookingDetails/styles';
import { makeData } from './modules/bookingDetails/data/dataAdapter';
import { showShortToast } from '@mmt/legacy-commons/Common/Components/Toast';
import { getStaticData } from '../staticData/staticData';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import DownloadInvoiceOverlay from './modules/bookingDetails/components/DownloadInvoiceOverlay';
import CommonOverlayMessage from '../Common/CommonOverlayMessage';
import CallDriverOverlay from './modules/bookingDetails/components/CallDriverOverlay';
import AuthErrorView from '../Common/AuthError/AuthErrorView';
import { getCustomerSupportCardData } from '../Common/CustomerSupportCard/api';
import { RuleTraceConst } from '../Common/CustomerSupportCard/CustomerSupportCardConst';
import CustomerSupportFloatingCard from '../Common/CustomerSupportCard/CustomerSupportFloatingCard';
import { scrollHelper } from '../Common/CustomerSupportCard/CustomerSupportCardHelper';
import CustomerSupportCard from '../Common/CustomerSupportCard';
import BenefitsCard from '../Common/BenefitsClaimedCard';
import FlightDetails from './modules/bookingDetails/components/FlightDetailCard';
import AddonServices from './modules/bookingDetails/components/AddonServices';
import TravelCashCard from '../Common/TravelCard';
export const CAB_BOOKING_DETAILS_PAGE = 'cabBookingDetail';
import FIREBASE_CONFIG from '../firebase/const';
import PostsaleFirebaseApp from '../firebase/postsalesFirebase';
import NewCarTrackerCard from './modules/newCarTrackerCard/index.jsx';
import { getCoordinates } from './modules/newCarTrackerCard/data/helper';
import { getPokusConfigFetchIfNeeded } from 'packages/legacy-commons/Native/AbConfig/AbConfigModule';
import { PokusLobs } from 'packages/legacy-commons/Native/AbConfig/AbConfigKeyMappings';
import RefundIssuedCard from './modules/bookingDetails/components/RefundIssuedCard';
// import BottomSheetWpr from '../Common/BottomSheetWpr';
import RefundIssuedOverlay from './modules/bookingDetails/components/RefundIssuedOverlay';
import ConfirmLandingOverlay from './modules/bookingDetails/components/ConfirmLandingOverlay';
import ConfirmYourLanding from './modules/bookingDetails/components/ConfirmYourLanding';
import FetchingDriverDetailsAnimation from './modules/bookingDetails/components/FetchingDriverDetailsAnimation';
import TripGuaranteeCard from './modules/bookingDetails/components/TripGuaranteeCard';
import KioskDirectionsCard from './modules/bookingDetails/components/KioskDirectionsCard';
import CarPhotosCard from './modules/bookingDetails/components/CarPhotosCard';
import KioskDirectionsOverlay from './modules/bookingDetails/components/KioskDirectionsOverlay';
import CarPhotosOverlay from './modules/bookingDetails/components/CarPhotosOverlay';
import DriverProfileOverlay from './modules/bookingDetails/components/DriverProfileOverlay';

// Enable LayoutAnimation on Android
if (Platform.OS === 'android' && UIManager.setLayoutAnimationEnabledExperimental) {
  UIManager.setLayoutAnimationEnabledExperimental(true);
}
export default class CabBookingDetail extends BasePage {
  static navigationOptions = {
    header: null,
  };

  static contextType = ThemeContext;

  constructor(props) {
    super(props, CAB_BOOKING_DETAILS_PAGE);
    const cabsSharedModule = CabsSharedModuleHolder.get();
    if(Platform.OS !== 'web') {
      if (cabsSharedModule === null) {
        throw new Error('Cabs module not bootstrapped');
      }
    }
    const { IDENTIFIER } = cabsSharedModule && cabsSharedModule.getCabsSharedConstants() || {};
    if (
      (props.data && props.data.BOOKING_ID.includes(IDENTIFIER)) ||
      (props.bookingId && props.bookingId.includes(IDENTIFIER))
    ) {
      this.bookingId =
        props.data && props.data.BOOKING_ID
          ? props.data.BOOKING_ID.split('_')[0].trim()
          : props.bookingId.split('_')[0].trim();
      this.isThankyouPage = true;
    } else {
      this.bookingId =
        props.data && props.data.BOOKING_ID
          ? props.data.BOOKING_ID
          : props.bookingId
          ? props.bookingId
          : '';
      this.isThankyouPage = false;
    }
    this.pageData = props.data && props.data.pageData ? JSON.parse(props.data.pageData) : undefined;
    this.isLostBooking = isLostBookingData(this.pageData);
    this.mmtAuth = '';
    this.loggingTrackInfo = {};
    this.cabCrossSellBooking = props.data
      ? props.data.CROSS_SELL_BOOKING
      : props.CROSS_SELL_BOOKING;
    this.parentPage = props.data ? props.data.PARENT_PAGE : props.PARENT_PAGE;
    this.parentBookingId = props.data ? props.data.PARENT_BOOKING_ID : props.PARENT_BOOKING_ID;
    this.trackPageName = '';
    this.carOnSpotBoardingInfo = null;
    this.initializeStates();
    this.toggleOverlay = this.toggleOverlay.bind(this);
    this.toggleAdvisoryOverlay = this.toggleAdvisoryOverlay.bind(this);
    this.getCancellationRequestNo = this.getCancellationRequestNo.bind(this);
    this.cancelModifyHeading = '';
    this.triggerName = null;
    this.deepLinkPageData = props.data?.deepLinkData ? JSON.parse(props.data?.deepLinkData) : {};
    this.customerSupportCardWidth = new Animated.Value(146);
    this.customerSupportTextWidth = new Animated.Value(1);
    this.prevFrom = {
      latitude: 0,
      longitude: 0,
    };
    this.isTransitioning = false;
    this.isMyGate = myGateView() || props.data?.isMyGate;
  }

  async setCustomerSupportCardData(isInternationalBooking) {
    const data = await getCustomerSupportCardData(
      this.bookingId,
      this.pageData?.uuid,
      'cabBookingDetails',
    );

    if (!isEmpty(data)) {
      if (data.ruleTrace === RuleTraceConst.FLOATING) {
        isInternationalBooking
          ? CabBookingTrackingHelper.trackClickEvent(
              PAGE_NAME.TRIP_DETAILS,
              CLICK_EVENT.SELFHELP_INT_BOOKING_SHOWN,
            )
          : CabBookingTrackingHelper.trackClickEvent(
              PAGE_NAME.TRIP_DETAILS,
              CLICK_EVENT.SELFHELP_DOM_BOOKING_SHOWN,
            );
      }

      this.setState({
        customerSupportCardData: data,
      });
    }
  }

  async initComm() {
    try {
      await this.fetchJSONAsync({ bookingId: this.bookingId }, () => {
        this.initFirebaseConnectionAuth(this.state.responseJson);
      });
    } catch (error) {}
  }

  componentDidMount() {
    this.getRydeUpgradePopupRendered();
    if (!this.isLostBooking) {
      this.initComm();
    }

    if (this.pageData?.isAddBookingFlow) {
      addBookingTrackingHelper.trackLoadEvent(
        ADD_BOOKING_TRACKING_EVENTS.Addbooking_detailspage_landed,
        this.pageData?.uuid,
        this.bookingId,
      );
    }
    BackHandler.addEventListener('hardwareBackPress', this.onHardBackPress);
    this.appStateListner = AppState.addEventListener('change', this.handleAppStateChange);
  }

  componentWillMount() {
    if (DeviceEventEmitter) {
      DeviceEventEmitter.addListener(
        'cab_booking_details_storage_permission',
        this.checkPermission,
      );
    }
  }

  componentWillReceiveProps(params) {
    if (params.data.refresh && params.data.refresh.isRefresh) {
      const bookingId = params?.data?.BOOKING_ID ? params?.data?.BOOKING_ID : this.bookingId;
      this.reloadBookingDetail(bookingId);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data && this.props.data.refresh === true) {
      if (this.isLostBooking) {
        this.setState({
          refreshLostDetail: {},
        });
        return;
      }
    }
  }

  componentWillUnmount() {
    this.removeFirebaseEventListeners();
    if (this.appStateListner) {
      this.appStateListner.remove();
    }
    if (DeviceEventEmitter) {
      DeviceEventEmitter.removeAllListeners('cab_booking_details_storage_permission');
    }
    BackHandler.removeEventListener('hardwareBackPress', this.onHardBackPress);
  }

  expandCarDetail = () => {
    this.setState({
      isExpanded: !this.state.isExpanded,
    });
  };

  checkPermission = (status) => {
    if (status.success && !!this.triggerName) {
      if (this.triggerName === CabBookingDetailsConstant.DOWNLOAD_INVOICE_POPUP) {
        this.handleOpenBottomOverlay(CabBookingDetailsConstant.DownloadInvoice);
      }
      this.triggerName = null;
    }
  };

  handleAppStateChange = (nextAppState) => {
    if (this.state?.appState?.match(/inactive|background/) && nextAppState === 'active') {
      if (this.carTrackingFirebase) {
        this.attachFirebaseEventListeners();
      }
    } else if (this.state?.appState === 'active' && nextAppState?.match(/inactive|background/)) {
      if (this.carTrackingFirebase) {
        this.removeFirebaseEventListeners();
      }
    }

    this.setState({ appState: nextAppState });
  };

  async onConfirmBooking(action) {
    const { errorText } = getStaticData(false);
    const { somethingWentWrongError } = errorText;
    const userId = 'CONFIRM_CARD_CLICKED';
    const userConfirm = !isEmpty(action) && action.actionId === 'Confirm_Ride_Btn';
    const response = await userConfirmApi(userId, userConfirm, this.bookingId);
    if (response && response.error) {
      showShortToast(somethingWentWrongError);
    } else {
      this.reloadBookingDetail(this.bookingId);
    }
  }

  toggleOverlay() {
    this.setState({ overlay: !this.state.overlay });
  }
  toggleAdvisoryOverlay() {
    this.setState({ advisoryOverlay: !this.state.advisoryOverlay });
  }

  getCancellationRequestNo(cancellationDetailList) {
    const cancellationRequestNoList = [];
    if (isNotNullAndEmpty(cancellationDetailList)) {
      cancellationDetailList.forEach((cancellationDetail) => {
        cancellationRequestNoList.push(cancellationDetail.requestNo);
      });
    }
    return cancellationRequestNoList;
  }

  getRydeUpgradePopupRendered = async () => {
    const rydeUpgradeOverlayKey = `rydeUpgradeOverlay${this.bookingId}`;
    const rydeUpgradeOverlayShowed = await getDataFromStorage(rydeUpgradeOverlayKey);
    const isrydeUpgradeOverlayShowed = rydeUpgradeOverlayShowed ? rydeUpgradeOverlayShowed : false;
    this.setState({ rydeUpgradeOverlayShowed: isrydeUpgradeOverlayShowed });
  };

  setRydeUpgradePopupRendered = () => {
    const rydeUpgradeOverlayKey = `rydeUpgradeOverlay${this.bookingId}`;
    setDataInStorage(rydeUpgradeOverlayKey, true);
  };

  async fetchJSONAsync({ bookingId, isRefreshCall = false, eventName }, callback) {
    try {
      const userInfo = await HotelBookingModule.getRequestHeader(bookingId, 'CAB_BOOKING_DETAIL');
      this.mmtAuth = userInfo.mmtAuth;
      this.loggingTrackInfo = userInfo.loggingTrackingInfo;
      const cabDetailResponse = await fetchCabBookingDetails(bookingId);
      const { deepLinkExtraData } = this.deepLinkPageData || {};
      if (cabDetailResponse && cabDetailResponse.isNetworkNotAvailable) {
        this.setState({ viewState: ViewState.NO_INTERNET });
        return;
      } else if (cabDetailResponse && cabDetailResponse?.error) {
        const errorBody = cabDetailResponse.error;
        if (
          errorBody &&
          errorBody?.message &&
          errorBody.message.toUpperCase() === AUTH_FAILED_MESSAGE
        ) {
          this.setState({ viewState: ViewState.AUTH_ERROR });
        } else {
          this.loadErrorState();
        }
      } else {
        const body = cabDetailResponse;
        body.parentPage = this.parentPage;
        body.parentBookingId = this.parentBookingId;
        if (body.layoutDetail && body.layoutDetail.bookingSubState) {
          this.trackPageName = getPageName(body.layoutDetail.bookingSubState);
        }
        this.carOnSpotBoardingInfo =
          body.carAdditionalInfo && body.carAdditionalInfo.additionalLOBInfo
            ? body.carAdditionalInfo.additionalLOBInfo.onSpotBoardingInfo
            : null;

        if (body?.clientDetails?.cabAnimationData?.currStatusTripCompleted && isRefreshCall) {
          LayoutAnimation.configureNext({
            duration: 700,
            create: {
              type: LayoutAnimation.Types.easeInEaseOut,
              property: LayoutAnimation.Properties.opacity,
            },
          });
        }

        this.setState(
          {
            responseJson: body,
            isLiveTrackingEnabled: body.liveTrackingEnabled,
            viewState: ViewState.SHOW_DETAIL,
            mapLoaded: !body.liveTrackingEnabled,
            isRydeBooking: body.rydeBooking,
            isRydeUpgrade: body.upgradeRyde,
            showRydeUpgradeOverlay: body.upgradeRyde,
            cardData: makeData(body),
            showCabTrackerOverlay: false,
            isTransitioning: isRefreshCall,
            fetchDetailInProgress: false,
          },
          callback,
        );

        if (isSelfServerEnabled() || !!body.isInternationalBooking) {
          this.setCustomerSupportCardData(body.isInternationalBooking);
        }
        try {
          const pokusData = await this.getPokusEvarData();
          CabBookingTrackingHelper.trackLoadEvent(
            this.trackPageName,
            this.state.responseJson,
            deepLinkExtraData,
            pokusData,
          );
        } catch (error) {
          CabBookingTrackingHelper.trackLoadEvent(
            this.trackPageName,
            this.state.responseJson,
            deepLinkExtraData,
          );
        }
        if (body?.carBookingInfo?.carDetail?.liveTrackingAvailable) {
          this.setErrorForCarTracker();
        }

        this.openBottomSheet(body);
      }
    } catch (error) {
      this.loadErrorState();
    }
  }

  async getPokusEvarData() {
    let promises = PokusesToTrackOnLoad.map(async (key) => {
      let result = await getPokusConfigFetchIfNeeded(PokusLobs.POST_SALES, key, false);
      let shortenedKey = CabBookingABKeysShortened[key] || key; // Use the original key if a shortened version doesn't exist
      return { [shortenedKey]: result };
    });

    let results = await Promise.all(promises);

    let resultString = results
      .map((result) => {
        let key = Object.keys(result)[0];
        let value = result[key] ? 't' : 'f';
        return `${key}_${value}`;
      })
      .join(' | ');

    return resultString;
  }

  openBottomSheet = (body) => {
    const topStatusCardId = getTopStatusCardId(body);
    if (
      topStatusCardId === CabBookingDetailsConstant.TOP_STATUS_DRIVER_UNASSIGNED &&
      !body.confirmLanding
    ) {
      this.handleOpenBottomOverlay(CabBookingDetailsConstant.ConfirmYourLandingOverlay);
    }
  };

  setErrorForCarTracker = () => {
    setTimeout(() => {
      if (isEmpty(this.state.currentCarLocation)) {
        this.setState({ carTrackerError: true });
      }
    }, 15000);
  };

  toggleRydeUpgradeOverlay = () => {
    this.setState({
      showRydeUpgradeOverlay: !this.state.showRydeUpgradeOverlay,
    });
    this.setRydeUpgradePopupRendered();
  };

  toggleCabTrackerOverlay = () => {
    LayoutAnimation.configureNext({
      duration: 500,
      update: { type: LayoutAnimation.Types.easeInEaseOut },
    });

    this.setState({
      showCabTrackerOverlay: !this.state.showCabTrackerOverlay,
    });
  };

  initFirebaseConnectionAuth = async (body) => {
    try {
      const { firebaseTokenList } = body;
      const { config, tokenKey } = FIREBASE_CONFIG.CAR_STATE_UPDATE;
      this.carTrackingFirebase = new PostsaleFirebaseApp(
        config,
        tokenKey,
        firebaseTokenList,
        this.bookingId,
      );

      //Initialize firebase app
      const { isInstanceAuthenticated, errorData } = await this.carTrackingFirebase.init();

      //If isInstanceAuthenticated create event listeners to each firebase path
      if (isInstanceAuthenticated) {
        this.attachFirebaseEventListeners();
      }

      if (errorData) {
        console.log('>>Firebase Error: ', errorData);
        this.setState({ firebaseConnectionError: true, carTrackerLoading: false });
      }
    } catch (error) {
      console.log('>>Firebase Error: ', error);
      this.setState({ firebaseConnectionError: true, carTrackerLoading: false });
    }
  };

  attachFirebaseEventListeners = () => {
    //Get base paths for each firebase event source
    const { basePath } = FIREBASE_CONFIG.CAR_STATE_UPDATE;
    const { basePathFrom, basePathTo, basePathStatic, basePathEvents } = getBasePath(
      this.bookingId,
      basePath,
    );

    this.carTrackingFirebase.onValue(basePathFrom, (data) => {
      if (!isEmpty(data?.from)) {
        this.setState({
          currentCarLocation: getCoordinates(data?.from),
          carPreviousLocation: this.prevFrom,
          timestamp: data?.from?.timestamp,
          carTrackerError: false,
        });
        this.prevFrom = getCoordinates(data?.from);
      }
    });

    // to location, lat lng
    this.carTrackingFirebase.onValue(basePathTo, (data) => {
      if (!isEmpty(data?.to)) {
        this.setState({
          toCarLocation: getCoordinates(data?.to),
          carTrackerError: false,
        });
      }
    });

    // static location, lat lng
    this.carTrackingFirebase.onValue(basePathStatic, (data) => {
      if (!isEmpty(data?.pickuplocation) && !isEmpty(data?.droplocation)) {
        this.setState({
          pickupLocation: getCoordinates(data?.pickuplocation),
          destinationLocation: getCoordinates(data?.droplocation),
          carTrackerError: false,
        });
      }
    });

    // events
    this.carTrackingFirebase.onValue(basePathEvents, (data) => {
      if (!isEmpty(data)) {
        this.setState({ eventName: data.eventName });
        //If current event not same as event received from firebase, refresh page with updated cab booking deta
        if (data.eventName !== this.state?.responseJson?.currentEvent && data.refresh) {
          this.fetchJSONAsync({
            bookingId: this.bookingId,
            isRefreshCall: true,
            eventName: data.eventName,
          });
        }
      }
    });
  };

  removeFirebaseEventListeners = () => {
    const { removeAllListeners } = this.carTrackingFirebase || {};
    removeAllListeners && removeAllListeners();
  };

  reloadBookingDetail = (bookingId) => {
    this.setState({ viewState: ViewState.LOADING });
    this.fetchJSONAsync({ bookingId });
  };

  initializeStates = () => {
    this.state = {
      responseJson: null,
      viewState: this.isLostBooking ? ViewState.SHOW_DETAIL : ViewState.LOADING,
      overlay: false,
      isLiveTrackingEnabled: false,
      mapLoaded: false,
      advisoryOverlay: false,
      isRydeBooking: false,
      isRydeUpgrade: false,
      showRydeUpgradeOverlay: false,
      rydeUpgradeOverlayShowed: false,
      specialClaimSelectedItem: null,
      specialClaimClicked: false,
      showTopstatusCardPopUp: false,
      bottomOverlay: null,
      overlayPosition: new Animated.Value(0),
      appState: AppState.currentState,
    };
  };

  getModifiedBooking = (newBookingId) => {
    this.bookingId = newBookingId;
    this.isThankyouPage = false;
    this.cabCrossSellBooking = false;
    this.parentPage = '';
    this.parentBookingId = '';
    this.initializeStates();
    this.setState({ viewState: ViewState.LOADING });
    this.fetchJSONAsync({ bookingId: newBookingId });
  };

  loadErrorState = () => {
    this.setState({ viewState: ViewState.ERROR });
    trackError();
  };

  async fetchOnlinePaymentJSONAsync(amount) {
    CabBookingTrackingHelper.trackClickEvent(
      this.trackPageName,
      CabBookingDetailsConstant.CLICK_PAY_NOW,
    );
    this.setState({ viewState: ViewState.LOADING_PAY });
    try {
      const onlinePaymentDetailResponse = await fetchOnlinePaymentDetail(this.bookingId, amount);
      if (onlinePaymentDetailResponse && onlinePaymentDetailResponse.isNetworkNotAvailable) {
        this.setState({ viewState: ViewState.NO_INTERNET });
        return;
      } else if (onlinePaymentDetailResponse && onlinePaymentDetailResponse.error) {
        this.loadErrorState();
      } else {
        this.setState({ viewState: ViewState.SHOW_DETAIL });
        if (onlinePaymentDetailResponse.success) {
          isEasyPayNativeFlowEnabled()
            ? GenericModule.openDeepLink(onlinePaymentDetailResponse.paymentUrl)
            : Actions.openPsWebView({
                headerText: CabBookingDetailsConstant.PAY_ONLINE,
                url: onlinePaymentDetailResponse.paymentUrl,
                showHeader: true,
              });
        } else {
          this.loadErrorState();
        }
      }
    } catch (error) {
      this.loadErrorState();
    }
  }

  getMainPageViews() {
    const views = [];
    const { showCabTrackerOverlay } = this.state;
    if (this.state.responseJson.layoutDetail) {
      let cards = this.state?.responseJson?.layoutDetail?.cardList;
      if (showCabTrackerOverlay) {
        const cardsToShowDuringTrackingOverlay = [
          ...this.state?.responseJson?.clientDetails?.cabAnimationData?.driverCards,
          ...this.state?.responseJson?.clientDetails?.cabAnimationData?.tackingCards,
        ];
        cards = cards.filter((card) => cardsToShowDuringTrackingOverlay.includes(card?.cardId));
      }
      cards.forEach((card) => {
        card.isVisible && this.addCard(card, views);
      });
    }
    return views;
  }

  renderRefundCard = (response, cancellationRequestNoList, lostBooking = false) => {
    if (isNotNullAndEmpty(cancellationRequestNoList)) {
      return (
        <RefundTracker
          key="RefundTracker"
          cancelRequests={cancellationRequestNoList}
          bookingId={this.bookingId}
          bookingResponse={response}
          lobName={LOBNAMES.CAR}
          isLostBookingId={lostBooking}
          pageName={REFUND_OOT_TRACKING.PAGES.CAB}
          isRoundedCornerAndPadding={true}
        />
      );
    }
    return null;
  };

  onDownloadInvoiceClick = () => {
    CabBookingTrackingHelper.trackClickEvent(
      this.trackPageName,
      CabBookingDetailsConstant.CLICK_DOWNLOAD_INVOICE,
    );
    if (Platform.OS === 'android') {
      this.triggerName = CabBookingDetailsConstant.DOWNLOAD_INVOICE_POPUP;
      CabBookingModule.checkStoragePermission();
    } else {
      this.handleOpenBottomOverlay(CabBookingDetailsConstant.DownloadInvoice);
    }
  };

  handleOpenBottomOverlay = (overlay, heading = '') => {
    this.cancelModifyHeading = heading;
    this.setState({
      bottomOverlay: overlay,
    });

    Animated.timing(this.state.overlayPosition, {
      toValue: 0,
      easing: Easing.easeInOut,
      duration: 200,
      delay: 100,
    }).start();
  };

  handleCloseBottomOverlay = () => {
    this.setState({
      bottomOverlay: '',
    });
  };

  setLiveTrackingErrorState = () => {
    this.setState({ carTrackerError: true });
  };

  reloadPageWithoutLoader = () => {
    this.setState({ fetchDetailInProgress: true, bottomOverlay: '' });
    this.fetchJSONAsync({ bookingId: this.bookingId, isRefreshCall: true, confirm: true });
  };

  addCard(card, views, isSubCard) {
    const {
      bookingStatus,
      carBookingInfo,
      vendorDetailInfo,
      voucherCardInfo,
      upSellCardData,
      refreshInterval = 10 * 60 * 1000,
      tripGuaranteeDetails,
    } = this.state.responseJson;
    const { carDetail } = carBookingInfo || {};
    const { tripType, liveTrackingAvailable, connectToFirebase } = carDetail || {};
    const { color } = this.context.psTheme;
    const cabStyles = createStyles(color);
    const showNeedHelp = this.state.customerSupportCardData
      ? this.state.customerSupportCardData.ruleTrace === RuleTraceConst.BLOCK
      : true;
    const showCarTrackerDetail =
      this.state.currentCarLocation &&
      this.state.toCarLocation &&
      this.state.pickupLocation &&
      this.state.destinationLocation;
    switch (card.cardId) {
      case CabBookingDetailsConstant.TOP_STATUS_CARD_LOST_FAILED_BOOKING_CANCELLED:
      case CabBookingDetailsConstant.TOP_STATUS_CARD_DEFAULT_CONFIRMED:
      case CabBookingDetailsConstant.TOP_STATUS_CARD_PREJOURNEY_DETAILS_UNAVAIL:
      case CabBookingDetailsConstant.TOP_STATUS_CARD_POST_PICKUP_DETAILS_UNAVAIL: // couldn't fin subacrd in tscase
      case CabBookingDetailsConstant.TOP_STATUS_CARD_AFTER_REFUND_DONE: // check for blussmart
      case CabBookingDetailsConstant.TOP_STATUS_CARD_POST_PICKUP_DETAILS_AVAIL:
      case CabBookingDetailsConstant.TOP_STATUS_CARD_LOST_FAILED_BOOKING:
      case CabBookingDetailsConstant.TOP_STATUS_CARD_COMPLETTION_OF_TRIP_TO_FOREVER:
      case CabBookingDetailsConstant.TOP_STATUS_DEFAULT_COMPLETED:
      case CabBookingDetailsConstant.TOP_STATUS_CARD_PREJOURNEY_DETAILS_AVAIL:
      case CabBookingDetailsConstant.TOP_STATUS_BETWEEN_CARD_INIT_REFUND_DONE:
      case CabBookingDetailsConstant.TOP_STATUS_DEFAULT_MODIFIED:
      case CabBookingDetailsConstant.TOP_STATUS_ADDRESS_PENDING:
      case CabBookingDetailsConstant.TOP_STATUS_KIOSK_BOOKING:
      case CabBookingDetailsConstant.TOP_STATUS_LIVE_TRACKING_AFTER_PRE_JOURNEY:
      case CabBookingDetailsConstant.TOP_STAUTUS_LIVETRACKING_ENABLED_POST_PICKUP:
      case CabBookingDetailsConstant.TopStatusCard_DEFAULT_UNCONFIRMED:
      case CabBookingDetailsConstant.TOP_STATUS_MODIFIED_BOOKING:
      case CabBookingDetailsConstant.TOP_STATUS_LONG_TAIL_USER_CONFIRMATION_PENDING:
      case CabBookingDetailsConstant.TOP_STATUS_LONG_TAI_VENDOR_ACCEPTED:
      case CabBookingDetailsConstant.TOP_STATUS_LONG_TAIL_VENDOR_PENDING:
      case CabBookingDetailsConstant.TOP_STATUS_LONG_TAIL_NO_CAB_AVAILABLE:
      case CabBookingDetailsConstant.TOP_STATUS_NOT_BOARDED:
      case CabBookingDetailsConstant.TOP_STATUS_KIOSK_DRIVER_NOT_ASSIGNED:
      case CabBookingDetailsConstant.TOP_STATUS_DRIVER_UNASSIGNED:
        views.push(
          <TopStatusCard
            key={card.cardId}
            bookingId={this.bookingId}
            response={this.state.responseJson}
            iconPath={topStatusConfirmedIcon}
            color={this.getTopStatusColor(this.state.responseJson)}
            card={card}
            onBackPress={this.goBack}
            pageName={this.trackPageName}
            addCard={this.addCard.bind(this)}
            updateCarDetails={this.updateCarDetails}
            cardData={this.state.cardData}
            onConfirmBooking={this.onConfirmBooking.bind(this)}
            showCabTrackerOverlay={this.state.showCabTrackerOverlay}
            appHeight={this.state.appHeight}
          />,
        );
        break;
      case CabBookingDetailsConstant.LONG_TAIL_USER_CONFIRMATION_CARD:
      case CabBookingDetailsConstant.LONG_TAIL_PENDING:
        views.push(
          <UserConfirmationCard
            pageName={this.trackPageName}
            card={card}
            onConfirmBooking={this.onConfirmBooking.bind(this)}
            cardData={this.state.cardData}
            key={card.cardId}
          />,
        );
        break;
      case CabBookingDetailsConstant.SUBCARD_CONFIRM_YOUR_LADNING_CARD:
        views.push(
          <ConfirmYourLanding
            key={card.cardId}
            pageName={this.trackPageName}
            cardData={this.state.cardData}
            customStyle={isSubCard ? cabStyles.subCardPadding : {}}
            reloadPage={this.reloadPageWithoutLoader}
            fetchDetailInProgress={this.state.fetchDetailInProgress}
          />,
        );
        break;
      case CabBookingDetailsConstant.SUBCARD_FETCHING_DRIVER_DETAILS_ANIMATION:
        views.push(
          <FetchingDriverDetailsAnimation
            key={card.cardId}
            pageName={this.trackPageName}
            cardData={this.state.cardData}
            customStyle={isSubCard ? cabStyles.subCardPadding : {}}
            reloadPage={this.reloadPageWithoutLoader}
          />,
        );
        break;
      case CabBookingDetailsConstant.CAB_UPSELL_CARD:
        if (!isEmpty(upSellCardData)) {
          views.push(
            <>
              {!!upSellCardData?.exclusiveOfferText && (
                <View style={styles.containerOffer}>
                  <Text style={styles.offerText}>{upSellCardData?.exclusiveOfferText}</Text>
                </View>
              )}
              <CabUpsellCard upSellCardData={upSellCardData} />
            </>,
          );
        }
        break;

      case CabBookingDetailsConstant.SUBCARD_CANCELLED_CAB_DETAIL_CARD:
      case CabBookingDetailsConstant.SUBCARD_CAB_DETAIL_CARD:
      case CabBookingDetailsConstant.SUBACRD_FAILED_CAB_DETAIL_CARD:
      case CabBookingDetailsConstant.SUBACARD_MODIFIED_CAB_DETAIL_CARD:
        views.push(
          <ConfirmedCabDetailCard
            key={card.cardId}
            bookingId={this.bookingId}
            response={this.state.responseJson}
            mmtAuth={this.mmtAuth}
            loggingTrackInfo={this.loggingTrackInfo}
            updateMapLoaded={this.updateMapLoaded}
            driverStartToPickup={liveTrackingAvailable}
            iconPath={topStatusConfirmedIcon}
            color={this.getTopStatusColor(this.state.responseJson)}
            card={card}
            onBackPress={this.goBack}
            pageName={this.trackPageName}
            addCard={this.addCard.bind(this)}
            cardData={this.state.cardData}
            customStyle={isSubCard ? cabStyles.subCardPadding : {}}
            isExpanded={this.state.isExpanded}
            expandCarDetail={this.expandCarDetail}
						isSubCard={isSubCard}
          />,
        );
        break;
      case CabBookingDetailsConstant.TRIP_MODIFICATION:
        views.push(
          <TripModification
            key={card.cardId}
            card={card}
            response={this.state.responseJson}
            getModifiedBooking={this.getModifiedBooking}
            cardData={this.state.cardData}
          />,
        );
        break;

      case CabBookingDetailsConstant.FLIGHT_DETAILS:
        views.push(
          <FlightDetails
            key={card.cardId}
            cardData={this.state.cardData}
            pageName={this.trackPageName}
          />,
        );
        break;

      case CabBookingDetailsConstant.TOLL_REFUND_CARD:
        views.push(
          <TollRefundDetails
            key={card.cardId}
            card={card}
            response={this.state.responseJson}
            cardData={this.state.cardData}
          />,
        );
        break;

      case CabBookingDetailsConstant.CONFIRMED_KIOSK_BOOKING:
        views.push(
          <KioskBookingConfirmed
            key={card.cardId}
            carOnSpotBoardingInfo={this.carOnSpotBoardingInfo}
            card={card}
            response={this.state.responseJson}
            cardData={this.state.cardData}
            customStyle={cabStyles.subCardPadding}
            pageName={this.trackPageName}
          />,
        );
        break;
      case CabBookingDetailsConstant.SUBCARD_COMPLETED_PAYMENT_FOREVER:
        views.push(
          <PayDriverRatingCard
            key={card.cardId}
            bookingId={this.bookingId}
            response={this.state.responseJson}
            mmtAuth={this.mmtAuth}
            loggingTrackInfo={this.loggingTrackInfo}
            updateMapLoaded={this.updateMapLoaded}
            driverStartToPickup={liveTrackingAvailable}
            iconPath={topStatusConfirmedIcon}
            color={this.getTopStatusColor(this.state.responseJson)}
            card={card}
            onBackPress={this.goBack}
            pageName={this.trackPageName}
            addCard={this.addCard.bind(this)}
            cardData={this.state.cardData}
            customStyle={cabStyles.subCardPadding}
            onDownloadInvoiceClick={this.onDownloadInvoiceClick}
          />,
        );
        break;
      case CabBookingDetailsConstant.SUBCARD_DRIVER_ASSIGNED_CARD:
        views.push(
          <CabDriverAssigned
            key={card.cardId}
            bookingId={this.bookingId}
            response={this.state.responseJson}
            mmtAuth={this.mmtAuth}
            loggingTrackInfo={this.loggingTrackInfo}
            updateMapLoaded={this.updateMapLoaded}
            driverStartToPickup={liveTrackingAvailable}
            iconPath={topStatusConfirmedIcon}
            color={this.getTopStatusColor(this.state.responseJson)}
            card={card}
            onBackPress={this.goBack}
            pageName={this.trackPageName}
            addCard={this.addCard.bind(this)}
            cardData={this.state.cardData}
            customStyle={isSubCard ? cabStyles.subCardPadding : {}}
            isExpanded={this.state.isExpanded}
            expandCarDetail={this.expandCarDetail}
            isSubCard={isSubCard}
            handleBottomSheetOverlay={this.handleOpenBottomOverlay}
            showCabTrackerOverlay={this.state.showCabTrackerOverlay}
            currentEvent={this.state?.responseJson?.currentEvent}
            setCardHeight={(height) => {
              LayoutAnimation.configureNext({
                duration: 500,
                update: { type: LayoutAnimation.Types.easeInEaseOut },
              });
              this.setState({ driverCardHeight: height });
            }}
            isTransitioning={this.state.isTransitioning}
            isCurrentEventTripstarted={
              this.state.responseJson?.clientDetails?.cabAnimationData?.currStatusTripStarted
            }
          />,
        );
        break;
      case CabBookingDetailsConstant.TOP_STATUS_CARD_CAN_INIT_TO_REFUND_DONE:
      case CabBookingDetailsConstant.TOP_STATUS_CARD_DEFAULT_CANCELLED:
      case CabBookingDetailsConstant.TOP_STATUS_CARD_AFTER_REFUND_DONE:
      case CabBookingDetailsConstant.TOP_STATUS_CARD_LOST_FAILED_BOOKING:
      case CabBookingDetailsConstant.TOP_STATUS_CARD_LOST_FAILED_BOOKING_CANCELLED:
        views.push(
          <RefundStatusCard
            key={card.cardId}
            response={this.state.responseJson}
            iconPath={topStatusCancelledIcon}
            headText="Booking Cancelled"
            color={color.cabsCancelledGradient}
            subTextHeader={this.state.responseJson.bookingId}
            subTextDetails=""
            card={card}
            onBackPress={this.goBack}
            pageName={this.trackPageName}
            cardData={this.state.cardData}
          />,
        );
        break;
      case CabBookingDetailsConstant.RYDE_BANNER_CARD:
        if (this.state.isRydeBooking || this.state.isRydeUpgrade) {
          views.push(
            <RydeBannerCard
              key={card.cardId}
              cardData={this.rydeBannerData()}
              isRydeUpgrade={this.state.responseJson.upgradeRyde}
            />,
          );
        }
        break;
      case CabBookingDetailsConstant.CAB_ADD_TECH_CARD:
        this.state.responseJson &&
          !this.state.responseJson.isCorporateBooking &&
          views.push(this.renderAdTechCard());
        break;
      case CabBookingDetailsConstant.NEED_MORE_HELP_MIMA_CARD:
        if (this.state.customerSupportCardData?.ruleTrace === RuleTraceConst.INLINE) {
          views.push(
            <CustomerSupportCard
              needMoreHelpCard={this.state.responseJson?.needMoreHelpMIMACard}
              deepLinkHandler={this.openDeepLinkFromNeedHelp}
              pageName={this.trackPageName}
              bookingId={this.bookingId}
            />,
          );
        }
        break;

      case CabBookingDetailsConstant.ADDON_SERVICES_CARD:
        views.push(
          <AddonServices
            key={card.cardId}
            card={card}
            pageName={this.trackPageName}
            cardData={this.state.cardData}
          />,
        );
        break;

      case COMMON_CARD.BENEFITS_CLAIMED_CARD:
        if (!isEmpty(this.state.responseJson?.loyaltyDetails)) {
          views.push(
            <BenefitsCard
              {...this.state.responseJson.loyaltyDetails}
              key="BenefitsClaimedCard"
              bookingId={this.state.responseJson.bookingId}
              trackingPageName={this.trackPageName}
            />,
          );
        }
        break;

      case CabBookingDetailsConstant.CAB_ITINERARY_CARD:
        if (CabBookingDetailsConstant.UNCONFIRMED_BOOKINGSTATUS === bookingStatus) {
          views.push(
            <CabSummaryCard
              key={card.cardId}
              response={this.state.responseJson}
              updateCarDetails={this.updateCarDetails}
            />,
          );
        } else if (
          tripType &&
          tripType.toUpperCase() === CabBookingDetailsConstant.HOURLY_RENTALS
        ) {
          views.push(
            <HourlyTripDetails key={card.cardId} response={this.state.responseJson} card={card} />,
          );
        } else {
          views.push(
            <TripDetailsCard key={card.cardId} response={this.state.responseJson} card={card} />,
          );
        }
        break;
      case CabBookingDetailsConstant.CAR_TRACKER_CARD:
      case CabBookingDetailsConstant.SUBACRD_CAR_TRACKER_PRE_PICKUP:
      case CabBookingDetailsConstant.SUBACRD_CAR_TRACKER_POST_PICKUP:
        views.push(
          <NewCarTrackerCard
            key={card.cardId}
            bookingId={this.bookingId}
            cardData={this.state.cardData}
            driverStartToPickup={liveTrackingAvailable}
            customStyle={cabStyles.subCardPadding}
            toggleCabTrackerOverlay={this.toggleCabTrackerOverlay.bind(this)}
            showCabTrackerOverlay={this.state.showCabTrackerOverlay}
            carTrackingFirebase={this.carTrackingFirebase}
            pickupLocation={this.state.pickupLocation}
            destinationLocation={this.state.destinationLocation}
            currentCarLocation={this.state.currentCarLocation}
            toCarLocation={this.state.toCarLocation}
            carPreviousLocation={this.state.carPreviousLocation}
            eventName={this.state.eventName}
            timestamp={this.state.timestamp}
            carTrackerLoading={!showCarTrackerDetail}
            showCarTrackerDetail={showCarTrackerDetail}
            carTrackerError={this.state.carTrackerError}
            appHeight={this.state.appHeight}
            ignoreWrapperStyles={this.state.showCabTrackerOverlay}
            driverCardHeight={this.state.driverCardHeight}
            refreshInterval={refreshInterval}
            setLiveTrackingErrorState={this.setLiveTrackingErrorState}
          />,
        );

        break;
      case CabBookingDetailsConstant.TRACK_YOUR_CAR_CARD:
        views.push(
          <TrackYourCabCard
            key={card.cardId}
            response={this.state.responseJson}
            ignoreWrapperStyles={this.state.showCabTrackerOverlay}
          />,
        );
        break;
      case CabBookingDetailsConstant.CAB_TRAVELLER_CARD:
        views.push(
          <TravellerCard
            key={card.cardId}
            lastName={this.state.responseJson.primaryCustomerDetails.lastName}
            firstName={this.state.responseJson.primaryCustomerDetails.firstName}
            emailId={this.state.responseJson.primaryCustomerDetails.emailId}
            mobileNumber={this.state.responseJson.primaryCustomerDetails.mobileNumber}
            pageName={this.trackPageName}
            card={card}
          />,
        );
        break;
      case CabBookingDetailsConstant.CAB_PRICE_BREAK_UP_CARD:
        if (isEmpty(this.state.responseJson.paymentBreakups)) {
          views.push(
            <PriceBreakUpCard
              key={card.cardId}
              response={this.state.responseJson}
              pageName={this.trackPageName}
              card={card}
              cardData={this.state.cardData}
            />,
          );
        } else {
          views.push(
            <PriceBreakUpWithPayOnlineOption
              key={card.cardId}
              response={this.state.responseJson}
              card={card}
              pageName={this.trackPageName}
              payOnlinePayment={this.fetchOnlinePaymentJSONAsync.bind(this)}
              openPopup={this.toggleOnlinePaymentOverlay}
              cardData={this.state.cardData}
              onDownloadInvoiceClick={this.onDownloadInvoiceClick}
            />,
          );
        }
        break;
      case COMMON_CARD.TRAVEL_CARD:
        if (!isEmpty(this.state.responseJson.travelCardResponse)) {
          views.push(
            <TravelCashCard
              key="TravelCashCard"
              travelCardResponse={this.state.responseJson.travelCardResponse}
              uuid={this.state.responseJson.uuid}
              bookingID={this.state.responseJson.bookingId}
            />,
          );
        }
        break;
      case CHAT_WITH_MYRA_CARD:
        if (isMyraEnabled(this.state.responseJson.lobCode)) {
          views.push(
            <ChatWithMyraCard
              key={card.cardId}
              response={this.state.responseJson}
              card={card}
              pageName={this.trackPageName}
            />,
          );
        }
        break;
      case CabBookingDetailsConstant.SPECIAL_CLAIM_CARD:
        views.push(
          <SpecialClaimCard
            key={card.cardId}
            specialClaimDataList={this.state.responseJson.specialClaimDataList}
            specialClaimActionClick={this.raiseClaimClicked}
          />,
        );
        break;
      case CabBookingDetailsConstant.CAB_NEED_HELP_CARD:
        if (!isMyraEnabled(this.state.responseJson.lobCode) && showNeedHelp) {
          views.push(
            <NeedHelpCard
              key={card.cardId}
              response={this.state.responseJson}
              pageName={this.trackPageName}
              card={card}
            />,
          );
        }
        break;
      case CabBookingDetailsConstant.CAB_REFUND_BREAKUP_CARD:
      case CabBookingDetailsConstant.CAB_REFUND_PROGRESS_CARD:
        const cancellationRequestNoList = this.getCancellationRequestNo(
          this.state.responseJson.cancellationDetailList,
        );
        views.push(this.renderRefundCard(this.state.responseJson, cancellationRequestNoList));
        break;
      case CabBookingDetailsConstant.INCLUSION_EXCLUSION_CARD:
        views.push(
          <InclusionExclusionCard
            key={card.cardId}
            response={this.state.responseJson}
            card={card}
            pageName={this.trackPageName}
          />,
        );
        break;
      case CabBookingDetailsConstant.CAB_MY_SAFETY_CARD:
        views.push(
          <MySafetyCard
            key={card.cardId}
            response={this.state.responseJson}
            card={card}
            pageName={this.trackPageName}
          />,
        );
        break;
      case CabBookingDetailsConstant.CAB_MAIN_CARD:
        views.push(
          <CabMainCard
            key={card.cardId}
            response={this.state.responseJson}
            card={card}
            pageName={this.trackPageName}
          />,
        );
        break;
      case CabBookingDetailsConstant.ALERT_INFO_CARD:
        views.push(
          <AlertInfo
            key={card.cardId}
            infoList={this.state.responseJson.importantInformationList}
          />,
        );
        break;
      case CabBookingDetailsConstant.CANCELLATION_POLICY_CARD:
      case CabBookingDetailsConstant.MODIFICATION_POLICY_CARD:
        views.push(
          <CancellationAndModificationCard
            key={card.cardId}
            response={this.state.responseJson}
            trackPageName={this.trackPageName}
            card={card}
            handleBottomSheetOverlay={this.handleOpenBottomOverlay}
          />,
        );
        break;
      case CabBookingDetailsConstant.CAR_AND_DRIVER_CARD:
        views.push(
          <CabAndDriverDetailsCard
            pageName={this.trackPageName}
            key={card.cardId}
            response={this.state.responseJson}
            closePopup={this.toggleOverlay}
            card={card}
          />,
        );
        break;
      case COMMON_CARD.LOYALTY_CARD:
        if (this.state.responseJson.loyaltyDetails) {
          views.push(
            <MMTBlackCard
              key={card.cardId}
              {...this.state.responseJson.loyaltyDetails}
              bookingId={this.state.responseJson.bookingId}
              uuid={this.state.responseJson.uuid}
              onClick={this.onMMTBlackCardClickEvent}
              trackingPageName={this.trackPageName}
            />,
          );
        }
        break;
      case CabBookingDetailsConstant.ClaimCard:
        views.push(
          <SupportCard
            key={card.cardId}
            response={this.state.responseJson}
            card={card}
            pageName={this.trackPageName}
          />,
        );
        break;
      case CabBookingDetailsConstant.MSR_MAIN_CARD:
        views.push(
          <MSRMainCard
            key={card.cardId}
            ticketsList={(this.state.responseJson.msrTicketsForBooking || {}).ticketsList}
            card={card}
            pageName={this.trackPageName}
            customStyle={cabStyles.customStyles}
          />,
        );
        break;
      case CabBookingDetailsConstant.ADVISORY_NOTICE:
        views.push(
          <AdvisoryNotice
            key={card.cardId}
            heading={card.heading}
            description={card.subheading}
            card={card}
            response={this.state.responseJson}
            togglePopUp={this.toggleAdvisoryOverlay}
          />,
        );
        break;
      case CabBookingDetailsConstant.OTHER_INFORMATION_CARD:
        views.push(
          <OtherInfo
            key={card.cardId}
            heading={card.heading}
            message={
              this.state.responseJson.clientDetails &&
              this.state.responseJson.clientDetails.importantInformation
            }
          />,
        );
        break;
      case CabBookingDetailsConstant.CAB_AMENITIES_CARD:
        views.push(
          <AmenitiesCard
            key={card.cardId}
            response={this.state.responseJson}
            card={card}
            pageName={this.trackPageName}
          />,
        );
        break;
      case CabBookingDetailsConstant.CAB_DRIVER_CHAT_CARD:
        views.push(
          <CabChatWithDriverCard
            key={card.cardId}
            response={this.state.responseJson}
            pageName={this.trackPageName}
            card={card}
          />,
        );
        break;
      case CabBookingDetailsConstant.CAB_VENDOR_INFO_CARD:
        !isEmpty(vendorDetailInfo) &&
          views.push(
            <CabVendorInfoCard
              key={card.cardId}
              data={vendorDetailInfo}
              customStyles={cabStyles.customStyles}
              pageName={this.trackPageName}
            />,
          );
        break;
      case CabBookingDetailsConstant.CAB_VOUCHER_CARD:
        !isEmpty(voucherCardInfo) &&
          views.push(
            <CabVoucherInfoCard
              key={card.cardId}
              data={voucherCardInfo}
              customStyles={cabStyles.customStyles}
              pageName={this.trackPageName}
            />,
          );
        break;
      case CabBookingDetailsConstant.CAB_REFUND_ISSUED_CARD:
        views.push(
          <RefundIssuedCard
            key={card.cardId}
            card={card}
            pageName={this.trackPageName}
            cardData={this.state.cardData}
            response={this.state.responseJson}
            handleViewRefundBreaup={this.handleOpenBottomOverlay}
          />,
        );
        break;
      case CabBookingDetailsConstant.TRIP_GUARANTEE_CARD:
        !isEmpty(tripGuaranteeDetails) &&
          views.push(
            <TripGuaranteeCard
              key={card.cardId}
              data={tripGuaranteeDetails}
              pageName={this.trackPageName}
            />,
          );
        break;
      case CabBookingDetailsConstant.CAB_DIRECTION_CARD:
        views.push(
          <KioskDirectionsCard
            key={card.cardId}
            response={this.state.responseJson}
            pageName={this.trackPageName}
            customStyle={isSubCard ? cabStyles.subCardPadding : {}}
            handleBottomSheetOverlay={this.handleOpenBottomOverlay}
          />,
        );
        break;
      case CabBookingDetailsConstant.CAR_PHOTOS_CARD:
        views.push(
          <CarPhotosCard
            key={card.cardId}
            data={this.state.responseJson?.carPhotosData}
            pageName={this.trackPageName}
            customStyle={isSubCard ? cabStyles.subCardPadding : {}}
            handleBottomSheetOverlay={this.handleOpenBottomOverlay}
          />,
        );
        break;
      default:
        break;
    }
    return views;
  }

  raiseClaimClicked = (item) => {
    CabBookingTrackingHelper.trackClickEvent(this.trackPageName, 'Raise Claim Clicked');
    this.setState({
      specialClaimClicked: true,
      specialClaimSelectedItem: item,
    });
  };

  dismissRaiseClaimPopup = (track) => {
    if (track) {
      CabBookingTrackingHelper.trackClickEvent(this.trackPageName, 'Dismiss Raise Claim Clicked');
    }
    this.setState({
      specialClaimClicked: false,
      specialClaimSelectedItem: null,
    });
  };

  raiseClaimContinueClicked = () => {
    this.onRaiseClaimContinueClicked(this.state.specialClaimSelectedItem);
    this.dismissRaiseClaimPopup(false);
    CabBookingTrackingHelper.trackClickEvent(this.trackPageName, 'Raise Claim Continue Clicked');
  };

  onRaiseClaimContinueClicked = (specialClaimData) => {
    // Actions.cabspecialclaim({
    //   specialClaimData: JSON.stringify(specialClaimData),
    //   bookingId: this.bookingId,
    //   response: this.state.responseJson
    // });
    Actions.cabspecialclaimoperator({
      specialClaimData: specialClaimData,
      bookingId: this.bookingId,
      response: this.state.responseJson,
    });
  };

  onMMTBlackCardClickEvent = (omnitureClickEvent) => {
    CabBookingTrackingHelper.trackClickEvent(this.trackPageName, omnitureClickEvent);
  };

  rydeBannerData = () => {
    const rydeBannerIfo = this.state.responseJson && this.state.responseJson.rydeBannerInfo;
    if (!this.state.isRydeBooking && this.state.responseJson.upgradeRyde) {
      return rydeBannerIfo && rydeBannerIfo.UpgradeRyde && rydeBannerIfo.UpgradeRyde.bannerInfo;
    }
    if (this.state.isRydeBooking) {
      return rydeBannerIfo && rydeBannerIfo.NormalRyde && rydeBannerIfo.NormalRyde.bannerInfo;
    }
  };

  rydeOverlayData = () => {
    const rydeBannerInfo = this.state.responseJson && this.state.responseJson.rydeBannerInfo;
    return rydeBannerInfo && rydeBannerInfo.UpgradeRyde && rydeBannerInfo.UpgradeRyde.popupInfo;
  };

  getTopStatusCardHeaderTxt(response) {
    if (response.bookingStatus && response.bookingStatus.toUpperCase() === 'UNCONFIRMED') {
      return 'Your Cab Booking is Unconfirmed Due to Pending Details';
    }
    return 'Cab Confirmed';
  }

  goBack = () => {
    if (this.appStateListner) {
      this.appStateListner.remove();
    }
    CabBookingTrackingHelper.trackClickEvent(
      CabBookingDetailsConstant.DEFAULT_TRIP_DETAILS_PAGE,
      CabBookingDetailsConstant.BACK_ARROW_CLICK,
    );
    if(Platform.OS === 'web' && this.isMyGate){
      window.open("mygate://close","_self"); // myGate back handling
    } else if (this.cabCrossSellBooking) {
      resetToFlightBookingDetail(this.props.parentBookingId);
    } else if (this.isThankyouPage) {
      if (Platform.OS === 'ios') {
        ViewControllerModule.popViewController(getRootTag());
      } else {
        BackHandler.exitApp();
      }
      FlightBookingModule.openMyTrips();
    } else {
      super.onBackIconPress();
    }
  };

  onHardBackPress = () => {
    if (this.state.overlay) {
      this.toggleOverlay();
    } else {
      this.goBack();
    }
    return true;
  };

  updateMapLoaded = () => {
    this.setState({
      mapLoaded: true,
    });
  };

  updateCarDetails = async () => {
    const { updatePickupDetailsDeepLinkForApp, updatePickupDetailsDeepLinkForWeb } =
      this.state.responseJson || {};
    const deepLink =
      Platform.OS === 'web' ? updatePickupDetailsDeepLinkForWeb : updatePickupDetailsDeepLinkForApp;
    if (!isEmpty(deepLink)) {
      const { UserSessionModule } = NativeModules;
      const showGdprBlockerScreen = await shouldShowGdprBlockerScreen();
      if (showGdprBlockerScreen && Platform.OS !== 'web') {
        const { gdprText } = getStaticData(false);
        const { lobs: { cabs = 'Cabs' } = {} } = gdprText || {};
        UserSessionModule.openGDPRBlockerScreen(cabs, null, getGdprData(gdprText));
      } else {
        GenericModule.openDeepLink(deepLink);
        CabBookingTrackingHelper.trackClickEvent(
          CabBookingDetailsConstant.DEFAULT_TRIP_DETAILS_PAGE,
          CabBookingDetailsConstant.CLICK_CAB_CROSS_SELL_UPDATE_DEEPLINK,
        );
      }
    }
  };

  getTopStatusColor(response) {
    const { color } = this.context.psTheme;
    if (response.bookingStatus && response.bookingStatus.toUpperCase() === 'UNCONFIRMED') {
      return color.cabsUnconfirmedGradient;
    }
    return color.cabsConfirmedGradient;
  }

  openDeepLinkFromNeedHelp = (url) => {
    // for now, there is only one handling, to open cab booking detail.
    const currentScreen = Actions.currentScene();
    if (currentScreen !== 'cabBookingDetail') {
      if (Platform.OS === 'web') {
        Actions.pop();
      } else {
        openCabBookingDetail(this.bookingId);
      }
    }
  };

  renderSpecialClaimOverlay() {
    return this.state.specialClaimSelectedItem ? (
      <OverlayMessage
        popUpOutsideClick={this.dismissRaiseClaimPopup}
        leftBtnClick={() => {
          this.dismissRaiseClaimPopup(true);
        }}
        rightBtnClick={this.raiseClaimContinueClicked}
        title={this.state.specialClaimSelectedItem.popupHeader}
        leftBtnTxt={this.state.specialClaimSelectedItem.popupNo}
        rightBtnTxt={this.state.specialClaimSelectedItem.popupYes}
        content={this.state.specialClaimSelectedItem.popupTxt}
      />
    ) : null;
  }

  renderAdvisoryOverlay() {
    return (
      <View style={styles.overlayContainer}>
        <TouchableHighlight style={styles.overlayBg}>
          <Text>.</Text>
        </TouchableHighlight>
        <AdvisoryOverlay
          key="CabBottomOverlay"
          pageName="CabBottomOverlay"
          advisoryNoticeText={this.state.responseJson && this.state.responseJson.advisoryNoticeText}
          closeoverlay={this.toggleAdvisoryOverlay}
        />
      </View>
    );
  }

  renderRydeUpgradeOverlayContent = () => {
    return (
      <RydeBottomOverlayMessageComponent
        handleBottomOverlay={this.toggleRydeUpgradeOverlay}
        overlayContent={this.rydeOverlayData()}
      />
    );
  };

  renderRydeUpgradeOverlay() {
    return (
      <CommonBottomOverlayMessage
        isBorderRadius
        dismiss={this.toggleRydeUpgradeOverlay}
        content={this.renderRydeUpgradeOverlayContent()}
      />
    );
  }

  renderAdTechCard = () => {
    return getAdTechCard(POST_SALES_CAB_DETAILS_PAGE);
  };

  renderQRCode() {
    return (
      <View style={{ height: Dimensions.get('screen').height }}>
        <CabOnSpotInfoOverlay
          closePopup={this.toggleOverlay}
          carOnSpotBoardingInfo={this.carOnSpotBoardingInfo}
          bookingId={this.bookingId}
        />
      </View>
    );
  }

  renderProgressViewForOnlinePay = () => <ProgressView message="Loading..." />;

  renderPage() {
    const { color } = this.context.psTheme;
    const cabStyles = createStyles(color);
    let views = [];
    if (this.isLostBooking) {
      return (
        <LostIDBookingDetailPage
          bookingId={this.bookingId}
          pageData={this.pageData}
          lobName={LOBNAMES.CAR}
          refreshLostDetail={this.state.refreshLostDetail}
          getRefundCard={({ response, canReqNoList }) =>
            this.renderRefundCard(response, canReqNoList, true)
          }
        />
      );
    }
    views = this.getMainPageViews();
    return (
      <View style={cabStyles.cabContainer}>
        <ScrollView
          onScroll={() =>
            scrollHelper(this.customerSupportCardWidth, this.customerSupportTextWidth)
          }
          contentContainerStyle={
            !this.state.showCabTrackerOverlay
              ? cabStyles.scrollSection
              : cabStyles.scrollSectionWithOverlay
          }
        >
          {views}
        </ScrollView>
        {this.state.customerSupportCardData?.ruleTrace === RuleTraceConst.FLOATING && (
          <CustomerSupportFloatingCard
            cardWidth={this.customerSupportCardWidth}
            dynamicTextWidth={this.customerSupportTextWidth}
            deepLinkHandler={this.openDeepLinkFromNeedHelp}
            pageName={this.trackPageName}
            cardData={this.state.responseJson?.floatingNeedHelpCard}
            bookingId={this.bookingId}
            uuid={this.state.responseJson?.uuid}
            isInternational={this.state.responseJson?.isInternational}
            productCode={LOBNAMES.CAR}
          />
        )}

        {this.state.bottomOverlay === CabBookingDetailsConstant.CancellationPolicy &&
          this.getCancellationPolicyOverlay(cabStyles)}

        {this.state.bottomOverlay === CabBookingDetailsConstant.DownloadInvoice &&
          this.getDownloadInvoiceOverlay()}

        {this.state.bottomOverlay === CabBookingDetailsConstant.CallDriverOverlay &&
          this.getCallDriverOverlay()}

        {this.state.bottomOverlay === CabBookingDetailsConstant.RefundIssuedOverlay &&
          this.getRefundIssuedOverlay()}

        {this.state.bottomOverlay === CabBookingDetailsConstant.ConfirmYourLandingOverlay &&
          this.getConfirmLandingOverlay()}
        {this.state.bottomOverlay === CabBookingDetailsConstant.CarPhotosOverlay &&
          this.getCarPhotosOverlay()}
        {this.state.bottomOverlay === CabBookingDetailsConstant.DriverProfileOverlay &&
          this.getDriverProfileOverlay()}
        {this.state.bottomOverlay === CabBookingDetailsConstant.KioskDirectionsOverlay &&
          this.getKioskDirectionsOverlay()}
      </View>
    );
  }

  getDownloadInvoiceOverlay() {
    const { bookingState } = this.state.responseJson || {};
    const isCompletedBooking =
      bookingState?.subState === CabBookingDetailsConstant.DEFAULT_COMPLETED;
    return (
      <CommonOverlayMessage
        handleBottomOverlay={this.handleCloseBottomOverlay}
        isBorderRadius={true}
        overlayContentStyle={styles.overlayContentStyle}
        content={
          <DownloadInvoiceOverlay
            bookingId={this.bookingId}
            handleBottomOverlay={this.handleCloseBottomOverlay}
            isCompletedBooking={isCompletedBooking}
          />
        }
      />
    );
  }

  getCallDriverOverlay() {
    return (
      <CommonOverlayMessage
        handleBottomOverlay={this.handleCloseBottomOverlay}
        isBorderRadius={true}
        overlayContentStyle={styles.overlayContentStyle}
        content={
          <CallDriverOverlay
            handleBottomOverlay={this.handleCloseBottomOverlay}
            response={this.state.responseJson}
            pageName={this.trackPageName}
          />
        }
      />
    );
  }

  getRefundIssuedOverlay() {
    return (
      <NewCommonOverlay
        handleBottomOverlay={this.handleCloseBottomOverlay}
        roundBorder={true}
         >
          <RefundIssuedOverlay
            handleBottomOverlay={this.handleCloseBottomOverlay}
            pageName={this.trackPageName}
            cardData={this.state.cardData}
          />
      </NewCommonOverlay>
    );
  }


  getCarPhotosOverlay() {
    const data = this.state.responseJson?.carPhotosData;
    if (isEmpty(data)) return null;
    return (
      <NewCommonOverlay
          handleBottomOverlay={this.handleCloseBottomOverlay}
          roundBorder={true}
          >
        <CarPhotosOverlay
          handleBottomOverlay={this.handleCloseBottomOverlay}
          data={data}
          pageName={this.trackPageName}
        />
      </NewCommonOverlay>
    );
  }

  getDriverProfileOverlay() {
    const overlayData = this.state.responseJson?.driverProfileData?.overlayData;
    if (isEmpty(overlayData)) return null;
    return (
      <NewCommonOverlay
        handleBottomOverlay={this.handleCloseBottomOverlay}
        roundBorder={true}
        >
          <DriverProfileOverlay
            handleBottomOverlay={this.handleCloseBottomOverlay}
            overlayData={overlayData}
            pageName={this.trackPageName}
          />
      </NewCommonOverlay>
    );
  }

  getConfirmLandingOverlay() {
    return (
      <NewCommonOverlay
      handleBottomOverlay={this.handleCloseBottomOverlay}
      roundBorder={true}
       >
          <ConfirmLandingOverlay
            handleBottomOverlay={this.handleCloseBottomOverlay}
            pageName={this.trackPageName}
            cardData={this.state.cardData}
            reloadPage={this.reloadPageWithoutLoader}
            fetchDetailInProgress={this.state.fetchDetailInProgress}
          />
      </NewCommonOverlay>
    );
	}

	getKioskDirectionsOverlay() {
		return (
      <NewCommonOverlay
      handleBottomOverlay={this.handleCloseBottomOverlay}
      roundBorder={true}
       >
					<KioskDirectionsOverlay
						handleBottomOverlay={this.handleCloseBottomOverlay}
						overlayData={this.state.responseJson?.cabDirectionsData?.overlayData}
						pageName={this.trackPageName}
					/>

			</NewCommonOverlay>
		);
	}

  getCancellationPolicyOverlay(cabStyles) {
    const { responseJson = {} } = this.state;
    return (
      <NewCommonOverlay
        handleBottomOverlay={() => {
          this.handleCloseBottomOverlay();
        }}
        roundBorder={true}
      >
        <View style={cabStyles.OverlayContentWrapper}>
          <CancellationPolicyOverlay
            handleBottomOverlay={this.handleCloseBottomOverlay}
            response={this.state.responseJson}
            disableCard={this.state.responseJson.irtctcDown}
            key="CancelModifyPolicy"
            heading={this.cancelModifyHeading}
          />
        </View>
      </NewCommonOverlay>
    );
  }

  renderNoNetworkView = () => (
    <NoInternetView
      onRetry={() => {
        this.setState({ viewState: ViewState.LOADING });
        this.fetchJSONAsync({ bookingId: this.bookingId });
      }}
    />
  );

  renderProgressView = () => <ProgressView message="Loading Your Trip..." />;

  renderErrorView = () => (
    <ErrorView
      showRetry
      onRetry={() => {
        this.reloadBookingDetail(this.bookingId);
      }}
    />
  );

  renderAuthErrorView = () => (
    <AuthErrorView bookingId={this.bookingId} lobName={LOBNAMES.CAR} onGoBackPress={this.goBack} />
  );

  render() {
    return (
      <View
        style={styles.flex1}
        onLayout={(e) => this.setState({ appHeight: e.nativeEvent.layout.height })}
      >
        {this.state.viewState === ViewState.ERROR && this.renderErrorView()}
        {this.state.viewState === ViewState.AUTH_ERROR && this.renderAuthErrorView()}
        {this.state.viewState === ViewState.SHOW_DETAIL && this.renderPage()}
        {this.state.viewState === ViewState.NO_INTERNET && this.renderNoNetworkView()}
        {this.state.viewState === ViewState.LOADING && this.renderProgressView()}
        {this.state.viewState === ViewState.LOADING_PAY && this.renderProgressViewForOnlinePay()}
        {this.state.viewState === ViewState.SHOW_DETAIL &&
          this.state.specialClaimClicked &&
          this.renderSpecialClaimOverlay()}
      </View>
    );
  }
}
