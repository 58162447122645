import React, { useState } from 'react';
import { TouchableOpacity, Animated, Easing, View } from 'react-native';
import { isEmpty } from 'lodash';
import CYTToastButton from './CYTToastButton';
import CompleteTripOverlay from './Overlay';
import CompleteYourTripCard from './CYTModal';
import styles from './CompleteYourTripCss';
import FlightBookingTrackingHelper from '../../helper/FlightBookingTrackingHelper';
import { DEEP_LINK_CODE_TRACK } from '../../../../../PostSalesConstant';
import { _getFltTrackData } from '../../../../FlightOmnitureTrackerUtils';

const CompleteYourTrip = ({
  scrollViewRef,
  responseJson,
  completeYouTripJson,
  getFlightLayout,
  openDeepLink,
}) => {
  const [showCYTOverlay, updateCYTOverlay] = useState(false);
  const overlayPosition = new Animated.Value(-100);

  const FltTrackData = _getFltTrackData();

  const handleShowCompleteYourTrip = () => {
    Animated.timing(overlayPosition, {
      toValue: 40,
      easing: Easing.linear,
      duration: 800,
      delay: 200,
    }).start();
    updateCYTOverlay(true);
    FlightBookingTrackingHelper.trackClickEventForCYT(
      'Details',
      'cyt_fab_clicked',
      completeYouTripJson,
      !isEmpty(FltTrackData) ? FltTrackData.ominatureValue : '',
    );
  };
  const closeCompleteYourTrip = () => {
    Animated.timing(overlayPosition, {
      toValue: -2700,
      easing: Easing.fadeInOut,
      duration: 800,
      delay: 300,
    }).start();
    setTimeout(() => {
      updateCYTOverlay(false);
    }, 550);
  };
  const scrollToView = (no, singleItem) => {
    const scrollToTitle = no ? parseInt(no) : '';
    if (scrollToTitle) {
      scrollViewRef.scrollTo({
        y: getFlightLayout(scrollToTitle),
        animated: true,
      });
    }
    updateCYTOverlay(false);
    if (singleItem.cardValue) {
      FlightBookingTrackingHelper.trackClickEventForCYT(
        'Details',
        `${singleItem.lobName.toLowerCase()}_addon_clicked_${
          DEEP_LINK_CODE_TRACK[parseInt(singleItem.cardValue)]
        }_${singleItem.isCompleted ? 'checked' : 'unchecked'}`,
        null,
        !isEmpty(FltTrackData) ? FltTrackData.ominatureValue : '',
      );
    }
  };
  const callDeepLinkActionsCYT = (deepLinkPageName, singleItem) => {
    if (singleItem.cardValue) {
      FlightBookingTrackingHelper.trackClickEventForCYT(
        'Details',
        `${singleItem.lobName.toLowerCase()}_addon_clicked_${
          DEEP_LINK_CODE_TRACK[parseInt(singleItem.cardValue)]
        }_${singleItem.isCompleted ? 'checked' : 'unchecked'}`,
        null,
        !isEmpty(FltTrackData) ? FltTrackData.ominatureValue : '',
      );
    }
    openDeepLink(responseJson, parseInt(deepLinkPageName));
    updateCYTOverlay(false);
  };
  return (
    <>
      {!showCYTOverlay &&
        completeYouTripJson.success &&
        !isEmpty(completeYouTripJson) &&
        !isEmpty(responseJson) &&
        responseJson.bookingState.state.toLowerCase() === 'upcoming' && (
          <View style={styles.completeTripToast}>
          <TouchableOpacity
            activeOpacity={0.9}
            onPress={handleShowCompleteYourTrip}
          >
            <CYTToastButton
              completedPercentage={
                completeYouTripJson &&
                completeYouTripJson.header &&
                completeYouTripJson.header.completedPercentage
              }
              heading={
                completeYouTripJson &&
                completeYouTripJson.header &&
                completeYouTripJson.header.heading
              }
            />
          </TouchableOpacity>
          </View>
        )}
      {showCYTOverlay && (
        <CompleteTripOverlay
          handleBottomOverlay={closeCompleteYourTrip}
          overlayPosition={overlayPosition}
          content={
            <CompleteYourTripCard
              bookingID={responseJson.bookingID}
              data={completeYouTripJson}
              scrollToView={scrollToView}
              callDeepLinkCYT={callDeepLinkActionsCYT}
            />
          }
        />
      )}
    </>
  );
};

export default CompleteYourTrip;
