import getPlatformElevation from '@mmt/legacy-commons/Common/Components/Card/getPlatformElevation';


const styles ={
  cardBorder:{
    borderTopColor:'#e4e4e4',
    borderBottomColor:'#e4e4e4',
    borderTopWidth:1,
    borderBottomWidth:1,
    borderTopLeftRadius:8,
    borderTopRightRadius:8,

  },
  carDtlsCardWrapper:{
    backgroundColor:'#fff',
    justifyContent:'space-between',
    ...getPlatformElevation(2),
    marginBottom:1,
    borderTopLeftRadius:8,
    borderTopRightRadius:8,
    padding:16,
  },
  otpWrapper:{
    borderRadius:4,
    backgroundColor:'#1a7971',
    width:60,
    height:26,
    alignItems:'center',
    justifyContent:'center',
  },

  carNameTxt:{
    fontSize:12,
    color:'#4a4a4a',
    width:100,
    marginLeft:5
  },
  depositeCard:{
    borderRadius:4,
    backgroundColor:'#f5f5ff',
    padding:10,
    flexDirection:'row',
    borderWidth:1,
    borderColor:'#d9ebff',
  },
  driverImgStyle:{
    width:20,
    height:21,
    alignSelf:'center',
    top:2,
  },
  imgPlaceHolder:{
    width:24,
    height:24,
    backgroundColor:'#4a90e2',
    borderRadius:25,
    marginRight:10,
  },
  driverDtlsWrapper:{
    borderTopWidth:1,
    borderTopColor:'#f2f2f2',
  },
  driverImgStyle2:{
    width:35,
    height:35,
    borderRadius:50,
    marginRight:10,
  },
  callIconStyle:{
    width:20,
    height:20,
  },
  dlImgStyle:{
    width:16,
    height:16,
    alignSelf:'center',
    top:4,
  },
  btnWrapper:{
    paddingLeft:27,
    marginTop:20,
  },
}


export default styles;
