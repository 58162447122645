import React from 'react';
import { View, Text } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getStatusTitle } from '../../utils/cssUtil';
import { getFont } from '../../../../../PostSalesStyles';
import { useTheme } from '../../../../../theme/theme.context';
import createStyles from './MoreInfoDetailsStyle';

import { MoreInfoDetailsProps } from '../../../../types';

const MoreInfoDetails: React.FC<MoreInfoDetailsProps> = ({
  statusStyleColor,
  subTitle,
}) => {
  const fonts = getFont();
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);
  if (!subTitle) return null;
  return (
    <View style={[styles.bottomWrapper]}>
      <Text
        style={[
          AtomicCss.regularFont,
          AtomicCss.font14,
          statusStyleColor,
          AtomicCss.textCenter,
          AtomicCss.marginBottom16,
          AtomicCss.lineHeight20,
        ]}
      >
        <Text>{subTitle}</Text>
      </Text>
    </View>
  );
};
export default MoreInfoDetails;
