import { StyleSheet } from 'react-native';

export default (theme) => StyleSheet.create({
    overlayContentWrapper:{
        paddingHorizontal:10,
        paddingTop:10,
        paddingBottom: 40,
    },
    headBottomBar:{
        backgroundColor: theme.color.azure,
        height:4,
        width:30,
    },
    upperText: {
        textTransform: 'uppercase',
    },
});
