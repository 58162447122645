import React, { useCallback, useRef } from 'react';
import { Image, StyleSheet, Text, TouchableOpacity } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {getRoundIcon} from '../../hotel/details/utils/HotelBookingDetailUtil'
import { getImageTransformStyling } from '../../utils/PostSaleUtil';
import { useTheme } from '../../theme/theme.context';

export const BUTTON_VARIANT = {
  LIGHT_BLUE: 'lightBlue',
  BLUE: 'blue',
  WHITE_WITH_BORDER: 'whiteWithBorder',
  WHITE_WITH_BORDER_WITHOUT_ICON: 'WHITE_WITH_BORDER_WITHOUT_ICON'
};

const ButtonWrapper = ({
  btnWidth,
  clickHandler,
  isThrottleRequired= false,
  throttleInterval = 1000,
  action,
  btn,
  iconStyle,
  textStyle,
  btnBg,
  iconBg,
  textBg
}) => {
  const lastClickTimeRef = useRef(0);
  const throttledClickHandler = useCallback(() => {
    const currentTime = Date.now();
    if (currentTime - lastClickTimeRef.current >= throttleInterval) {
      lastClickTimeRef.current = currentTime;
      clickHandler(action);
    }
  }, [clickHandler, throttleInterval]);

  const wrapperThrottle = () => {
    if (isThrottleRequired) {
      throttledClickHandler();
    } else {
      clickHandler(action);
    }
  }
  
  return (
      <TouchableOpacity style={[btn, btnWidth, AtomicCss.marginTop12, btnBg]} onPress={wrapperThrottle}>
          {getRoundIcon(action.actionFamily) && <Image style={[iconStyle, iconBg, getImageTransformStyling()]} source={getRoundIcon(action.actionFamily)} resizeMode='contain'/>}
          <Text style={[AtomicCss.boldFont, AtomicCss.font14, textStyle, textBg]}>
            {action.actionLabeltext}
          </Text>
      </TouchableOpacity>
  );
};

const RoundButton = ({ varient, ...rest}) => {
  const {psTheme: theme}=useTheme();
  const styles = createStyle(theme);

  let newProps = { ...rest, ...styles };

  switch (varient) {
    case BUTTON_VARIANT.WHITE_WITH_BORDER:
      newProps = {
        ...newProps,
        btnBg: styles.whiteWithBorderbg,
        iconBg: styles.iconLight,
        textBg: styles.whiteWithBordertext
      }
      break;
    case BUTTON_VARIANT.WHITE_WITH_BORDER_WITHOUT_ICON:
      newProps = {
        ...newProps,
        btnBg: styles.whiteWithBorderbg,
        iconBg: styles.iconLight,
        textBg: styles.whiteWithBordertext,
        iconStyle:{}
      }
      break;   
    case BUTTON_VARIANT.LIGHT_BLUE:
      newProps = {
        ...newProps,
        btnBg: styles.btnLight,
        iconBg: styles.iconLight,
        textBg: styles.textLight
      }
      break;
    default:
      newProps = {
        ...newProps
      }
      break;
  }

  return <ButtonWrapper {...newProps} />;
};

export default RoundButton;

const createStyle = (theme) =>
  StyleSheet.create({
    btn: {
      borderRadius: 8,
      padding: 8,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor:theme.color.primary,
    },
    btnLight:{
      backgroundColor:theme.color.lightPrimaryColor
    },
    iconStyle: {
      width: 20,
      height: 20,
      marginRight: 6,
      tintColor:  theme.color.white,
    },
    iconLight:{
    tintColor:theme.color.primary
    },
    textStyle: {
      color:  theme.color.lightPrimaryColor,
      flexShrink: 1
    },
    textLight:{
      color:theme.color.primary
    },
    whiteWithBorderbg:{
      backgroundColor:theme.color.white,
      borderWidth:1,
      borderColor:theme.color.azure
    },
    whiteWithBordertext:{
      color:theme.color.azure
    }
  });