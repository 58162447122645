import { actionTypes, listingActionTypes, rtActionTypes } from '../../constants';
import { isNetworkAvailable } from '@mmt/legacy-commons/Common/utils/AppUtils';
import { showShortToast } from '@mmt/legacy-commons/Common/Components/Toast';
import DateChangeTrackingHelper from '../../analytics/DateChangeTrackingHelper';
import {dateChangeRequestAPI, dateChangePreviewAPI} from "../../data/api";

function responseFetched(response) {
  return {
    type: actionTypes.RESPONSE_FETCHED,
    dateChangeResponse: response,
  };
}

function responseFailed() {
  return {
    type: actionTypes.RESPONSE_FAILED,
  };
}

function dateChangePreviewFailed(errorPageData) {
  return {
    type: actionTypes.PREVIEW_RESPONSE_FAILED,
    errorPageData,
  };
}

function listingResponseFetched(response) {
  return {
    type: listingActionTypes.LISTING_RESPONSE_FETCHED,
    listingResponse: response,
  };
}

export const listingErrorCloseBar = () => ({
  type: listingActionTypes.LISTING_RESPONSE_ERROR_CLOSE,
});

export const listingErrorOpenBar = () => ({
  type: listingActionTypes.LISTING_RESPONSE_ERROR_OPEN,
});

export const listingErrorLoaderBar = () => ({
  type: listingActionTypes.LISTING_RESPONSE_ERROR_LOADER,
});

export const listingErrorOpen = (overlayShow) => (dispatch) => {
  if (overlayShow) {
    dispatch(listingErrorOpenBar());
  } else {
    dispatch(listingErrorCloseBar());
  }
};

export const listingErrorLoader = () => (dispatch) => {
  dispatch(listingErrorLoaderBar());
};

export const enableProceedBtnFlow = () => ({
  type: actionTypes.PROCEED_ENABLED,
});

export const enableProceedBtn = () => (dispatch) => {
  dispatch(enableProceedBtnFlow());
};

export const dateChangeLoaderCall = () => ({
  type: actionTypes.RESPONSE_LOADING,
});

export const dateChangeLoader = () => (dispatch) => {
  dispatch(dateChangeLoaderCall());
};

export const rtDtErrPopupCall = () => ({
  type: rtActionTypes.RT_DATE_ERROR,
});

export const rtDtErrPopup = (response) => (dispatch) => {
  DateChangeTrackingHelper.trackClickableEvent('datechangepickdate', 'datenotsupported', response, undefined);
  DateChangeTrackingHelper.trackErrorEvent('datechangepickdate', 'datenotsupported', undefined);
  dispatch(rtDtErrPopupCall());
};


export function dateChangeListingRequest(bookingID, segmentGroup, url) {
  return async (dispatch) => {
    dispatch(listingResponseFetched({ segmentGroup, status: true }));
  };
}

export function dateChangePreviewRequest(bookingID, segmentGroup, url, isCfarBooking) {
  return async (dispatch) => {
    dispatch(dateChangeLoaderCall());
    const datechangepickdate = 'datechangepickdate';
    try {
      const hasNetwork = await isNetworkAvailable();
      if (!hasNetwork) {
        showShortToast('Please check your internet connection');
        return;
      }
      const response = await dateChangePreviewAPI(bookingID, segmentGroup, url, isCfarBooking);
      if (response.ok) {
        const body = await response.json();
        if (body.errorPageData) {
          const errorCode = body?.errorPageData?.errorCode || 'ERROR_RESPONSE'
          dispatch(dateChangePreviewFailed(body));
          DateChangeTrackingHelper.trackErrorEvent(
            datechangepickdate,
            errorCode,
            body.errorPageData.meta.supplier,
          );
        } else if (body.error) {
          const errorCode = body?.error?.errorCode || 'ERROR_RESPONSE'
          DateChangeTrackingHelper.trackErrorEvent(datechangepickdate, errorCode, undefined);
          dispatch(responseFailed());
        } else {
          DateChangeTrackingHelper.trackLoadEvent(datechangepickdate, body);
          dispatch(responseFetched(body));
        }
      } else {
        DateChangeTrackingHelper.trackErrorEvent(datechangepickdate, 'NO_RESPONSE', undefined);
        dispatch(responseFailed());
      }
    } catch (error) {
      DateChangeTrackingHelper.trackErrorEvent(datechangepickdate, '', undefined);
      dispatch(responseFailed());
    }
  };
}
