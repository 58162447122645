import { View, StyleSheet, Dimensions } from 'react-native';
const windowW = Dimensions.get('window').width;

export default (theme, fonts) => {
  const { color } = theme;
  return StyleSheet.create({
    lightGreen: {
      backgroundColor: color.limeGreen,
      borderBottomLeftRadius: 16,
      borderBottomRightRadius: 16
    },
    notBoarded : {
      color: color.darkBrown
    },
    darkGrey: {
      backgroundColor: color.textGrey,
      borderBottomLeftRadius: 16,
      borderBottomRightRadius: 16
    },
    lightGrey: {
      backgroundColor: color.grey24,
      borderBottomLeftRadius: 16,
      borderBottomRightRadius: 16
    },
    lightYellow: {
      backgroundColor: color.creamWhite,
      borderBottomLeftRadius: 16,
      borderBottomRightRadius: 16
    },
    lightBlue: {
      backgroundColor: color.lighterBlue,
      borderBottomLeftRadius: 16,
      borderBottomRightRadius: 16
    },
    lightRed: {
      backgroundColor: color.transparentRed,
      borderBottomLeftRadius: 16,
      borderBottomRightRadius: 16
    },
    topWrapper: {
      paddingHorizontal: 16,
      paddingTop: 16,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderBottomLeftRadius: 16,
      borderBottomRightRadius: 16,
      marginBottom: 10,
      zIndex: 10,
    },
		topWrapperExpanded: {
			paddingHorizontal: 0,
			paddingTop: 0,
			borderTopLeftRadius: 0,
			borderTopRightRadius: 0,
			borderBottomLeftRadius: 0,
			borderBottomRightRadius: 0,
			marginBottom: 0,
			zIndex: 10,
		},
    skyBlue: {
      backgroundColor: color.lightBlue23,
      borderBottomLeftRadius: 16,
      borderBottomRightRadius: 16
    },
    darkSkyBlue: {
      backgroundColor: color.skyBlue5,
      borderBottomLeftRadius: 16,
      borderBottomRightRadius: 16
    },
    headerWrapper: {
      zIndex: 2,
      width: windowW,
      marginLeft: 5,
      marginTop: 5,
    },
    backgroundOverlay: {
      backgroundColor: color.limeGreen,
      paddingHorizontal: 16,
      paddingTop: 16,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderBottomLeftRadius: 16,
      borderBottomRightRadius: 16,
      marginBottom: 10,
      height: 400,
      zIndex: 5,
    },
  });
};
