import { View, StyleSheet, Dimensions } from 'react-native';
const windowW = Dimensions.get('window').width;

export default (theme, fonts) => {
  const { color } = theme;
  return StyleSheet.create({
    linkBtnWrapper: {
      alignItems: 'flex-start',
      width: '35%',
    },
    linkBtnTxt: {
      color: theme.color.azure,
      fontSize: 13,
      ...fonts.boldFontFamily,
      textAlign: 'center',
    },
    linkBtnStyle: {
      width: 44,
      height: 44,
    },
    pickupOverlayWrapper: {
      backgroundColor: color.creamWhite9,
      borderRadius: 16,
      padding: 10,
    },
    pickupTitle: {
      color: color.darkBrown,
      fontSize: fonts.fontSize.font14,
      // fontFamily: fonts.black,
    },
    pickupDesc: {
      color: color.darkBrown,
      fontSize: fonts.fontSize.font14,
      // fontFamily: fonts.regular,
    },
    container: {
      backgroundColor: color.greyBookedSeat,
      flex: 1,
    },
    topWrapper: {
      backgroundColor: color.limeGreen,
      padding: 16,
      paddingBottom: 0,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderBottomLeftRadius: 16,
      borderBottomRightRadius: 16,
      marginBottom: 10,
    },
    scrollSection: {
      paddingBottom: 16,
    },
    driverAssignedWrapper: {
      borderBottomWidth: 1,
      borderBottomColor: color.lightGray,
      paddingBottom: 16,
    },
    popupWrapper: {
      position: 'absolute',
      left: 0,
      height: Dimensions.get('window').height,
      width: Dimensions.get('window').width,
      zIndex: 100,
      flexDirection: 'row',
    },
    popupBg: {
      position: 'absolute',
      top: 0,
      left: 0,
      backgroundColor: color.grey34,
      height: '100%',
      width: '100%',
      zIndex: 1,
    },
    popupContentWrapper: {
      position: 'relative',
      zIndex: 14,
      marginTop: 14,
      width: '100%',
      paddingHorizontal: 16,
      top: 62,
    },
    blueButtonWrapper: {
      backgroundColor: color.azure,
      padding: 8,
      borderRadius: 4,
      width: 150,
      alignSelf: 'center',
      justifyContent: 'center',
      alignItems: 'center',
    },
    whiteCardNeg20: {
      marginTop: -20,
    },
    headerWrapper: {
      zIndex: 2,
      width: windowW,
    },
    innerWrpper: {
      zIndex: 2,
      paddingHorizontal: 16,
    },
  });
};
