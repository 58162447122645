import isEmpty from 'lodash/isEmpty';
import { getStaticData } from '../../../../../../staticData/staticData';
import {
  getFlightInfoData,
  getChangeDateInfo,
  getUndoWebCheckInInfo,
  getFareBreakupData,
  getImportantInformationData,
  getInfoListData,
} from 'core-ui-lib/flight/common/ODC/dataAdapter';

export const getSubmitPageData = (type, viewState, responseJson) => {
  const { bookingId, commonInfo, recommendation, mobileHeaderData, reqKey } = responseJson;
  return {
    ...responseJson.dateChangePopupData[type],
    contactNoList: this.state.responseJson.contactNoList,
    pageType: type,
    bookingId,
    commonInfo,
    recommendation,
    mobileHeaderData,
    reqKey,
    viewState,
  };
};

export const getOverlayData = (specialDateChangePaymentOverlay, responseJson) => {
  const overlayData = {};
  if (specialDateChangePaymentOverlay) {
    const {
      submitDateChangeDetailList: { title, policyList },
      actionBtn,
    } = responseJson.dateChangePopupData.DATE_CHANGE_PAYMENT;
    overlayData.title = title;
    overlayData.descriptionList = policyList;
    const btnDataList = [];
    if (!isEmpty(actionBtn)) {
      actionBtn.forEach((btn) => {
        const btnData = {};
        btnData.text = btn.title;
        btnData.clickActionData = btn.actionType;
        btnData.btnClickListener = this.handleSpecialDateChangeBtnCLick;
        btnDataList.push(btnData);
      });
    }
    overlayData.btnData = btnDataList;
  }
  return overlayData;
};

export const getFooterInfo = (responseJson) => {
  const { undoWebCheckinInfo, fareDetail, fareBreakup, dateChangeType, buttonData } = responseJson || {};
  const { bannerInfo } =  undoWebCheckinInfo || {};
  const { totalFare } = fareDetail || {};
  const {amtpayable : amountToPay} = fareBreakup || {};
  const {
    dateChangeChargesText,
    payNowText,
    continueText,
    yourRefundText
  } = getStaticData();

  let btnText = payNowText.toUpperCase();
  let priceDtlsLabel = dateChangeChargesText;

  if (amountToPay <= 0) {
    btnText = continueText;
  }
  if (amountToPay < 0) {
    priceDtlsLabel = yourRefundText;
  }

  if (dateChangeType === 'SPECIAL_DATE_CHANGE') {
    if (buttonData) {
      btnText = buttonData.title;
    } else {
      btnText = undefined;
    }
  }
  return { 
    bannerInfo,
    totalFare,
    btnText,
    priceDtlsLabel,
  };
};

export const getReviewPageData = (response) => {
  const {stopLowerCaseText} = getStaticData();
  const {undoWebCheckinInfo} =  getUndoWebCheckInInfo(response);
  return {
    extraInfo: response.extraInfo,
    flightInfoData : getFlightInfoData(response, stopLowerCaseText ),
    fareBreakupData : getFareBreakupData(response),
    importantInformationData: getImportantInformationData(response),
    infoListData : getInfoListData(response),
    changeDateInfoData : getChangeDateInfo(response),
    footerInfo : getFooterInfo(response),
    undoWebCheckinInfo,
    refundMsg: response.refundMsg,
  };
};
