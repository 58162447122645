import { StyleSheet } from "react-native"

export default (theme,fonts) => {
    return StyleSheet.create({
        cfarContainer: {
            marginTop:10,
            backgroundColor:theme.color.lightGreen19,
            borderRadius:6,
            padding:8,
            fontSize:14,
            lineHeight:20,
            color: theme.color.lightGreen18,
        },
        nonCfarContainer: {
            marginTop:10,
            backgroundColor:theme.color.creamWhite,
            borderWidth:0.5,
            borderColor: theme.color.red17,
            borderRadius:6,
            padding:8,
            fontSize: 14,
            lineHeight:20,
            color: theme.color.black,
        }
    })
}