import { getFont } from 'apps/post-sales/src/PostSalesStyles';
import React from 'react';
import {View, Easing, Animated, TouchableOpacity} from 'react-native';
import getStyles from './OverlayCss';

class DateChangeOverlay extends React.Component {
  constructor(props) {
    super(props);
    this.fonts = getFont(true);
    this.styles = getStyles(this.fonts);
    this.state = {
      toastPostion: new Animated.Value(-400)
    };
  }
  componentDidMount() {
    const bottom = 0
    this.startAnimate(bottom);
  }

  startAnimate(bottom) {
    Animated.timing(this.state.toastPostion, {
      toValue: bottom,
      easing: Easing.easeInOut
    }).start();
  }

  render() {
    return (
      <View style={this.styles.overlayWrapper}>
        <TouchableOpacity onPress={() => this.props.handleCloseOverlay()} activeOpacity={.9} style={this.styles.overlay}></TouchableOpacity>
        <Animated.View style={[this.styles.overlayContent, {bottom: this.state.toastPostion}]}>
          {this.props.children}
        </Animated.View>
      </View>

    );
  }
}

export default DateChangeOverlay;
