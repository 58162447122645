import React from "react";
import { appendUrlPrefix } from "../../webUtils";
import loadable from "@loadable/component";
import ProgressView from "../../components/ProgressView";

const VISA_PATHS = {
    VISA_BOOKING_DETAIL: {
        pathName: appendUrlPrefix('/visa'),
        pageKey: "visaBookingDetail",
        Component: loadable(() => import("@rn/apps/post-sales/src/Visa/Details/VisaBookingDetail"), { fallback: <ProgressView /> }),
        customFunction: (redirectUrl, pathName, params) => {
            if (params.data) {
                params.bookingId = params.data;
            }
            redirectUrl(pathName, params);
        }
    },
    ADD_VISA_DOCUMENT: {
        pathName: appendUrlPrefix('/visa/addDocuments'),
        pageKey: "",
        isFeatureNotSupported: true
    },
    VISA_DOCUMENT: {
        pathName: appendUrlPrefix('/visaDocument'),
        pageKey: "visaDocument",
        Component: loadable(() => import("@rn/apps/post-sales/src/Visa/Details/Component/VisaDocument"), { fallback: <ProgressView /> }),
    },
    VISA_PAYMENT_BREAKUP: {
        pathName: appendUrlPrefix('/visapaymentBreakup'),
        pageKey: "visapaymentBreakup",
        Component: loadable(() => import("@rn/apps/post-sales/src/Visa/Details/Component/VisaPaymentBreakUp"), { fallback: <ProgressView /> }),
    },
    VISA_DOCUMENT_VIEWER: {
        pathName: appendUrlPrefix('/visaDocumentViewer'),
        pageKey: "visaDocumentViewer",
        Component: loadable(() => import("@rn/apps/post-sales/src/Visa/Details/Component/VisaDocumentViewer"), { fallback: <ProgressView /> }),
    },
    VISA_FAQ:{
        pathName:appendUrlPrefix('/visaFAQ'),
        pageKey:"visaFAQ",
        Component: loadable(() => import("@rn/apps/post-sales/src/Visa/Details/Component/VisaFAQ"), { fallback: <ProgressView /> }),
        customFunction: (redirectUrl, pathName, params) => {
            if (params.data) {
                params.bookingId = params.data.BOOKING_ID;
            }
            redirectUrl(pathName, params);
        }
    }

}

export default VISA_PATHS;