import React from 'react';
import { View, Text, Image } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import createStyles from "./paymentCardCss";
import { CARD_ICON } from '../../../../Utils/tripGuaranteeImageConstants';
import Actions from '../../../../../navigation/postSalesNavigation';
import isEmpty from 'lodash/isEmpty';
import { getActionButton, getButtonStyle } from '../../../../Utils/TgsBookingDetailUtil';
import { getFont } from '../../../../../PostSalesStyles';
import { useTheme } from '../../../../../theme/theme.context';
const PaymentCard = ({ card, response }) => {
    const { paymentInfo } = response || {};
    const { heading, paymentBreakup, paymentMade } = paymentInfo || {};
    const { actionList } = card || {};
    const fonts = getFont()
    const { psTheme: theme } = useTheme();
    const styles = createStyles(theme, fonts);
    const handleClick = () => {
        Actions.tgsPriceBreakUp({
            bookingDetailsResponse: response,
        });
    }
    const btnFont = fonts.boldFontFamily;

    const getPayment = (payment) => {
        const { subHeading, Amount } = payment || {};
        return(<View style={[AtomicCss.flexRow,styles.detailsSection,AtomicCss.spaceBetween,AtomicCss.marginBottom5]}>
            {!!subHeading && <Text style={[AtomicCss.defaultText, AtomicCss.font14, fonts.regularFontFamily]}>{subHeading}</Text>}
            {!!Amount && <Text style={[AtomicCss.defaultText, AtomicCss.font14, fonts.regularFontFamily]}>{Amount}</Text>}
        </View>)
    }
    return (
        <View style={styles.topSection}>
            <View style={[AtomicCss.flexRow, AtomicCss.marginBottom10, AtomicCss.alignCenter]}>
                <View style={[AtomicCss.flex1, AtomicCss.flexRow, AtomicCss.alignCenter]}>
                    <View style={styles.iconWrapper}>
                        <Image style={styles.cardIconStyle} source={CARD_ICON} />
                    </View>
                    {!!heading && <Text style={[AtomicCss.font16, fonts.blackFontFamily, AtomicCss.blackText]}>{heading}</Text>}
                </View>
                {!!paymentMade && <Text style={[AtomicCss.font16, fonts.blackFontFamily, AtomicCss.blackText]}>{paymentMade}</Text>}
            </View>
            {!isEmpty(paymentBreakup) && paymentBreakup.map((payment) => getPayment(payment))}
            {!isEmpty(actionList) && <View
                style={!isEmpty(actionList) ? [getButtonStyle(actionList), AtomicCss.transperantBgrnd] : {}} >
                {getActionButton(card, response, handleClick, btnFont)}
            </View>}
        </View>

    )
}

export default PaymentCard