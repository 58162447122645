import React, { useCallback } from 'react';
import { View, Text, Image } from 'react-native';
import { isNotNullAndEmpty } from '../../../hotel/details/utils/HotelBookingDetailUtil';
import { ONE_WAY_JRNY_ICON } from '../../FlightImageConstants';
import styles from './SelectPassengersCss';
import { imageTransformY } from '../../../utils/PostSaleUtil';
import { getStaticData } from '../../../staticData/staticData';
import { getFont } from '../../../PostSalesStyles';

export const SegmentDetailsView = React.memo(
  ({ segment, destinationCity, stopovers, showStaticContentInEnglish }) => {
    const { fsStyle, ...fonts } = getFont(true);
    const getStopOver = useCallback((stopover) => {
      const stopoversList = [];
      if (isNotNullAndEmpty(stopover)) {
        stopover.forEach((stop) => {
          stopoversList.push(
            <Text style={[styles.cityText, fonts.regularFontFamily, fsStyle.font14]}>{stop}</Text>,
          );
        });
      }
      return stopoversList;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getFlightName = useCallback(
      (segmentName) => `${segmentName.flightDesignator.flightName}`,
      [],
    );

    let {
      flightAdditionalText: { flightWithStopText },
    } = getStaticData();
    if (showStaticContentInEnglish) {
      flightWithStopText =
        'Your journey has connecting flight with stop. The airline does not allow to cancel partial segment.';
    }
    return (
      <View key={segment.segmentLineNo}>
        <View style={[styles.destRow]}>
          <Text style={[styles.cityText, fonts.boldFontFamily, fsStyle.font16]}>
            {segment.originCity}
          </Text>
          <Image style={[styles.arrowIcon, imageTransformY]} source={ONE_WAY_JRNY_ICON} />
          {getStopOver(stopovers)}
          {isNotNullAndEmpty(stopovers) && (
            <Image style={styles.arrowIcon} source={ONE_WAY_JRNY_ICON} />
          )}
          <Text style={[styles.cityText, fonts.boldFontFamily, fsStyle.font16]}>
            {destinationCity}
          </Text>
          <View style={styles.airlinesIconWrapper}>
            <Image style={styles.airlineIcon} source={{ uri: segment.airlineLogoUrl }} />
          </View>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <Text style={[styles.smallText, fonts.semiBoldFontFamily, fsStyle.font10]}>
            {segment.flightTravelDateV2}
          </Text>
          <Text style={[styles.smallText, fonts.semiBoldFontFamily, fsStyle.font10]}>, </Text>
          <Text style={[styles.smallText, fonts.semiBoldFontFamily, fsStyle.font10]}>
            {getFlightName(segment)}
          </Text>
        </View>
        {isNotNullAndEmpty(stopovers) && (
          <Text style={[styles.fltInfo, fonts.regularFontFamily, fsStyle.font12]}>
            {flightWithStopText}
          </Text>
        )}
      </View>
    );
  },
);

export const addSegmentInfo = (segmentGroup, showStaticContentInEnglish = false) => {
  if (
    segmentGroup.segmentGroupIntent != null &&
    segmentGroup.segmentGroupIntent.split('-').length > 2
  ) {
    let stop = [];
    let stopovers = [];
    stop = segmentGroup.segmentGroupIntent.split('-');
    const stopLen = stop.length;
    stopovers = segmentGroup.segmentGroupIntent.split('-').slice(1, stopLen - 1);
    const seg = segmentGroup.segmentDetails[0];
    return (
      <SegmentDetailsView
        segment={seg}
        destinationCity={stop[stopLen - 1]}
        stopovers={stopovers}
        showStaticContentInEnglish={false}
      />
    );
  }

  return segmentGroup.segmentDetails.map((segment) => (
    <SegmentDetailsView
      segment={segment}
      key={segment.segmentLineNo}
      destinationCity={segment.destinationCity}
      stopovers={null}
      showStaticContentInEnglish={false}
    />
  ));
};

export default SegmentDetailsView;
