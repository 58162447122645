import React, { useEffect } from 'react';
import { View, Text, Image, TouchableOpacity, StyleSheet, ImageBackground } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import Actions from '../../../../navigation/postSalesNavigation';
import HotelBookingTrackingHelper from '../../../HotelBookingTrackingHelper';
import { BLUE_RIGHT_ARROW_ICON } from '../../../HotelImageConstants';
import { isNotNullAndEmpty } from '../../utils/HotelBookingDetailUtil';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const HomeStayAwardsCard = ({ homestayAwards, pageName, response }) => {
  const { fsStyle, ...fonts } = getFont(true);
  const { cta, ctaText, title, subTitle, ctaUrl, backgroundImageUrl } = homestayAwards;

  useEffect(() => {
    HotelBookingTrackingHelper.trackShowEvent(pageName, 'homestay_award_shown', response);
  }, [])

  const handleClick = () => {
    if (isNotNullAndEmpty(ctaUrl)) {
      const clickEvent = 'MI_HTL_homestay_award_click';
      HotelBookingTrackingHelper.trackClickEvent(
          pageName,
          clickEvent,
          response,
        );
      Actions.openPsWebView({
          url: ctaUrl
      });
    }
  }

  return (
    <TouchableOpacity style={[AtomicCss.marginBottom12,AtomicCss.marginHz12]} activeOpacity={0.8} onPress={handleClick}>
      <ImageBackground source={{uri: backgroundImageUrl}} style={[styles.imageWrapper]} borderRadius={10} >
        {isNotNullAndEmpty(subTitle) && 
          <Text style={[fsStyle.font11, fonts.regularFontFamily, AtomicCss.whiteText]}>
            {subTitle}
          </Text>
        }
        {isNotNullAndEmpty(title) && 
          <Text
            style={[
              fsStyle.font14,
              fonts.boldFontFamily,
              AtomicCss.whiteText,
              AtomicCss.marginBottom5,
            ]}
          >
              {title}
          </Text>
        }
        <View style={[AtomicCss.flexRow,  AtomicCss.alignCenter]}>
            {isNotNullAndEmpty(cta) && <Text style={[fsStyle.font12, fonts.blackFontFamily, AtomicCss.azure]}>
              {`${cta} `} 
              {isNotNullAndEmpty(ctaText) && <Text style={[fsStyle.font12, fonts.regularFontFamily, AtomicCss.whiteText]}>
                  {ctaText}
                </Text>
              }
            </Text>
            }
          <Image source={BLUE_RIGHT_ARROW_ICON} style={styles.arrowStyle} />
        </View>
      </ImageBackground>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  imageWrapper: {
    flex:1,
    height: 72,
    resizeMode: 'cover',
    justifyContent: 'center',
    borderRadius: 10,
    paddingLeft: 140,
    paddingRight: 12
  },
  arrowStyle: {
    width: 14,
    height: 14,
    tintColor: colors.white,
    marginLeft: 4,
  },
});

export default HomeStayAwardsCard;
