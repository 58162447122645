import React from 'react';
import {View, Text, Image} from 'react-native';
import PropTypes from 'prop-types';
import Fecha from 'fecha';
import styles from '../../styles/AcmeMamiCss';
import {
  getRefundProgressStyle,
  getRefundIcons,
  getRefundStatusLine,
  fillDepartureArrivalDate,
  isNotNullAndEmpty
} from '../../../bus/utils/BusBookingDetailUtil';
import {refundIcon} from '../../utils/AcmeImageConstant';



class RefundProgressCard extends React.Component {
  static navigationOptions = {
    header: null
  };

  render() {
    const refundCommDetails = this.props.response.refundCommDetailsList;
    const {customerCurrency} = this.props.response;
    return (
      <View>
        {this.addRefundInfo(refundCommDetails, customerCurrency)}
      </View>
    );
  }

  addRefundInfo(refundCommDetails, customerCurrency) {
    const views = [];
    if (isNotNullAndEmpty(refundCommDetails)) {
      refundCommDetails.forEach((refundCommDetail, index) => {
        this.addRefundCard(views, refundCommDetail, index, customerCurrency);
      });
    }
    return views;
  }

  addRefundCard(views, refundCommDetail, index, customerCurrency) {
    views.push(<View key={index} style={[styles.MamiCard, styles.mar10Bt]}>
      <View style={styles.CardContent}>
        <Image style={styles.CardIcon} source={refundIcon} />
        <View style={styles.ContentPortion}>
          <Text
            style={[styles.blackFont, styles.BlackText, styles.refundCardheadText, styles.mar15Bt, styles.width100per]}
          >
            REFUND
            OF {customerCurrency} {refundCommDetail.totalRefundAmount}{' '} {getRefundStatusLine(refundCommDetail.currentRefundStatus)}
          </Text>
          <Text
            style={[styles.font12, styles.defaultGrey, styles.boldFont, styles.lineHeight18, styles.flex1]}
          >{this.getHeadText()}
          </Text>
          <View style={styles.mar10Bt}>
            <View style={styles.refundbarInfo}>
              {getRefundIcons(refundCommDetail.currentRefundStatus)}
            </View>
            <View style={[styles.refundStrip, styles.mar5Bt]}>
              <View style={getRefundProgressStyle(refundCommDetail.currentRefundStatus)} />
            </View>
            <View style={styles.refundbarInfo}>
              <Text
                style={[styles.font12, styles.defaultGrey, styles.boldFont, styles.lineHeight18, styles.flex1]}
              >{this.renderBottomLeftSection(refundCommDetail)}
              </Text>
              <Text
                style={[styles.font12, styles.AlignRight, styles.defaultGrey, styles.boldFont, styles.lineHeight18, styles.flex1]}
              >{this.renderBottomRightSection(refundCommDetail)}
              </Text>
            </View>
          </View>
        </View>
      </View>

    </View>);
  }

  getHeadText() {
    if (this.props.response.bookingState.state === 'REFUNDED' || this.props.response.bookingState.state === 'FAILED_REFUND') {
      return 'Your refund will reflect in your account in 3-12 working days.';
    }
    return 'We will initiate the refund to your account with in 3 days.';
  }
  renderBottomLeftSection(refundCommDetail) {
    if (refundCommDetail.cancelledDate) {
      return `Cancellation \nInitiated on\n${fillDepartureArrivalDate(refundCommDetail.cancelledDate, 'DD MMM hh:mm A')}`;
    }
    return 'Cancellation \nInitiated';
  }

  renderBottomRightSection(refundCommDetail) {
    if (refundCommDetail.uploadRRNDate) {
      return `Refund Processed\n & Sent to bank on\n ${fillDepartureArrivalDate(refundCommDetail.uploadRRNDate, 'DD MMM hh:mm A')}`;
    }
    return `Refund Processed\n & Sent to bank by\n ${this.populateRefundExpectedDate(refundCommDetail.cancelledDate)}`;
  }

  populateRefundExpectedDate(cancelledDate) {
    if (cancelledDate) {
      const millisecondOffset = 7 * 24 * 60 * 60 * 1000;
      return Fecha.format((new Date(cancelledDate + millisecondOffset)), 'DD MMM hh:mm A');
    }
    return '';
  }
}

RefundProgressCard.propTypes = {
  response: PropTypes.object.isRequired
};
export default RefundProgressCard;
