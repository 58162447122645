import React from 'react';
import {Keyboard, ScrollView, Platform, Text, View, KeyboardAvoidingView} from 'react-native';
import getStyles from '../../styles/AddGuestNamesCss';
import BasePage from '../../../Common/PostSalesBasePage';
import WarningMessage from '../../../Common/WarningMessage';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import TouchableOpacity from '@mmt/legacy-commons/Common/Components/TouchableOpacity';
import InputElement from '../../../Common/InputElement';
import HotelBookingDetailsConstant, {
  PAX_GENDER,
  PAX_TITLE,
  PAX_TYPE
} from '../HotelBookingDetailsConstant';
import PropTypes from 'prop-types';
import {
  getHotelCheckInDate,
  getHotelCheckOutDate,
  getHotelName,
  getNoOfNonAddedPaxType,
  getPageName,
  getPaxName,
  getPaxType,
  getTotalNoOfPaxTypePresent
} from '../utils/HotelBookingDetailUtil';
import {fillDateAndTime} from '@mmt/legacy-commons/Common/utils/DateUtils';
import StickyHeader from './StickyHeader';
import {isAlphabeticalStringWithSpace, isNonEmpty} from '@mmt/legacy-commons/Common/utils/StringUtils';
import SnackBar from '../../../Common/Snackbar';
import Actions from '../../../navigation/postSalesNavigation';
import HotelBookingTrackingHelper from '../../HotelBookingTrackingHelper';
import {isEmpty} from 'lodash';
import CapsuleFullBlueButton from '../../../Common/CapsuleBlueFullButton';
import { LEFT_BLACK_ARROW_ICON } from '../../HotelImageConstants';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const keyboardAvoidingBehavior = Platform.OS === 'ios' ? 'padding' : undefined;
const headerIcon = LEFT_BLACK_ARROW_ICON;

const PAGE_NAME = 'AddGuestNames:';

const SNACKBAR_STATES = {
  SHOW_CONFIRMATION: 'SHOW_CONFIRMATION',
  FIRST_NAME_NOT_PRESENT: 'FIRST_NAME_NOT_PRESENT',
  NAME_NOT_ALPHABETICAL: 'NAME_NOT_ALPHABETICAL'
};

export default class AddGuestNames extends BasePage {
  constructor(props) {
    super(props, 'addGuestNames');
    this.fonts = getFont(true);
    this.styles = getStyles(this.fonts);
    this.hotelBookingInfo = this.props.response.hotelBookingInfo;
    this.passengers = this.props.response.passengerDetails && this.props.response.passengerDetails.passengers;
    this.snackBarState = null;
    this.state = {
      passengers: this.getNonAddedPassengers(),
      showSnackbar: false
    };
  }

  componentDidMount() {
    super.componentDidMount();
    HotelBookingTrackingHelper.trackLoadEvent(getPageName(this.props.response, PAGE_NAME), this.props.response);
  }

  getNonAddedPassengers() {
    const noOfNonAddedAdults = getNoOfNonAddedPaxType(this.props.response, PAX_TYPE.ADULT);
    const noOfNonAddedChildren = getNoOfNonAddedPaxType(this.props.response, PAX_TYPE.CHILD);
    return this.getDefaultPax(PAX_TYPE.ADULT, noOfNonAddedAdults)
      .concat(this.getDefaultPax(PAX_TYPE.CHILD, noOfNonAddedChildren));
  }

  getDefaultPax(paxType, count) {
    const passengers = [];
    const paxTypePresent = getTotalNoOfPaxTypePresent(this.props.response, paxType);
    for (let paxIndex = paxTypePresent + 1; paxIndex <= paxTypePresent + count; paxIndex++) {
      const paxId = `${paxType}_${paxIndex}`;
      passengers.push(this.getDefaultPaxState(paxType, paxId));
    }
    return passengers;
  }

  getWarningMessageText = () => {
    if(this.props.response?.warningMsgText){
      return this.props.response?.warningMsgText;
    }
    return 'Please keep in mind that names of other guests added here should match with their passports. Names once added, cannot be changed.' +
      '\nPlease note, all hotel bookings are reserved only against the name of the primary guest.';
  };

  render() {
    return (
      <View style={this.styles.container}>
        <StickyHeader
          iconSrc={headerIcon}
          title="Add Names of Other Guest(s)"
          headerColor={['#ffffff', '#ffffff']}
          titleStyle={[AtomicCss.headingTxt]}
          subTitle={this.getSubTitle()}
          subTitleStyle={[this.styles.DefaultText, this.styles.font12]}
          onBackPress={this.onBackIconPress}
        />
        <KeyboardAvoidingView style={{flex: 1}} behavior={keyboardAvoidingBehavior} keyboardVerticalOffset={25} enabled>
          <ScrollView showsVerticalScrollIndicator={false} keyboardShouldPersistTaps="handled">
            <WarningMessage
              key="warning_message"
              title={this.getWarningMessageText()}
            />
            <NameView
              key="name_view"
              passengers={this.passengers}
            />
            {this.getNamesInputs()}
          </ScrollView>
        </KeyboardAvoidingView>
        <View style={this.styles.buttonWrapper}>
          {this.showSubmitNamesCTA()}
        </View>
        {this.state.showSnackbar && this.getSnackBar()}
      </View>
    );
  }

  onHardBackPress = () => {
    if (this.state.showSnackbar) {
      this.toggleSnackbar();
      return true;
    }
    return false;
  };

  getSnackBar() {
    const snackBarObj = this.getSnackBarObject();
    return snackBarObj && <SnackBar {...snackBarObj} />;
  }

  getSnackBarObject() {
    switch (this.snackBarState) {
      case SNACKBAR_STATES.FIRST_NAME_NOT_PRESENT:
        // If only last name is added, the show error message.
        return {
          topMessage: 'Please enter the first name of the guest(s)',
          centerMessage: '',
          backCtaText: 'GO BACK',
          backCtaHandler: this.toggleSnackbar
        };
      case SNACKBAR_STATES.NAME_NOT_ALPHABETICAL:
        // If name is not alphabetical, then show error message.
        return {
          topMessage: `${this.getPluralString('Name')} added should only contain alphabets!`,
          centerMessage: '',
          backCtaText: 'GO BACK',
          backCtaHandler: this.toggleSnackbar
        };
      case SNACKBAR_STATES.SHOW_CONFIRMATION: {
        // Passengers are valid. Can be proceed further.
        const nameString = this.getPluralString('name');
        const centerMessage = `Once confirmed, you will not be able to change the ${nameString} you have added to your booking.`;
        return {
          topMessage: `Have you added the correct ${nameString}?`,
          centerMessage,
          ctaText: 'CONFIRM',
          ctaHandler: this.onSubmitNamesCTAClicked,
          backCtaText: 'GO BACK',
          backCtaHandler: this.toggleSnackbar
        };
      }
      default:
        return null;
    }
  }

  getNamesInputs() {
    const noOfNonAddedAdults = getNoOfNonAddedPaxType(this.props.response, PAX_TYPE.ADULT);
    const noOfNonAddedChildren = getNoOfNonAddedPaxType(this.props.response, PAX_TYPE.CHILD);
    const nameInputs = [];
    nameInputs.push(this.getNameInputForPaxType(PAX_TYPE.ADULT, noOfNonAddedAdults));
    nameInputs.push(this.getNameInputForPaxType(PAX_TYPE.CHILD, noOfNonAddedChildren));
    return nameInputs;
  }

  getNameInputForPaxType(paxType, count) {
    const paxInputView = [];
    const paxTypePresent = getTotalNoOfPaxTypePresent(this.props.response, paxType);
    for (let paxIndex = paxTypePresent + 1; paxIndex <= paxTypePresent + count; paxIndex++) {
      const paxId = `${paxType}_${paxIndex}`;
      const passenger = this.state.passengers.find(passenger => passenger.paxId == paxId);
      paxInputView.push(<View style={this.styles.card} key={`pax_input_${paxId}`}>
        <View style={this.styles.appendBottom20}>
          <View style={this.styles.addGuestRowHead}>
            <Text style={this.styles.addGuestRowHeadText}>{`${paxType} ${paxIndex}`}</Text>
          </View>
          <View style={this.styles.addGuestRow}>
            <TitleField passenger={passenger}/>
            <NameField
              key={`${paxId}_` + 'fn'}
              fieldType="firstName"
              passenger={passenger}
              label="First Name"
              onNameFieldChanged={this.onNameFieldChanged}
            />
            <NameField
              key={`${paxId}_` + 'ln'}
              fieldType="lastName"
              passenger={passenger}
              label="Last Name"
              onNameFieldChanged={this.onNameFieldChanged}
            />
          </View>
        </View>
      </View>);
    }
    return paxInputView;
  }

  getDefaultPaxState(paxType, paxId) {
    return {
      firstName: '',
      lastName: '',
      paxType,
      paxId,
      title: PAX_TITLE.MR,
      gender: PAX_GENDER.MALE
    };
  }

  getSubTitle() {
    const hotelName = getHotelName(this.props.response);
    const hotelCheckInDate = getHotelCheckInDate(this.props.response);
    const hotelCheckOutDate = getHotelCheckOutDate(this.props.response);
    const formattedCheckInDate = fillDateAndTime(hotelCheckInDate, 'DD MMM');
    const formattedCheckOutDate = fillDateAndTime(hotelCheckOutDate, 'DD MMM');
    return `${hotelName} • ${formattedCheckInDate} - ${formattedCheckOutDate}`;
  }

  showSubmitNamesCTA() {
    const addedPax = this.getAddedPassengers();
    // If no passenger has been added then, disable the submit button.
    const disabled = (!addedPax || addedPax.length === 0);
    const action = {
      actionLabeltext: 'SUBMIT NAMES',
      actionFamily: HotelBookingDetailsConstant.ACTION_SUBMIT_GUEST_NAMES
    };
    return (
      <CapsuleFullBlueButton
        clickHandler={this.onSubmitNamesClicked}
        action={action}
        disabled={disabled}/>
    );
  }

  isNameInvalid = (passenger) => {
    const firstName = passenger.firstName.trim();
    const lastName = passenger.lastName.trim();
    const isFirstNameValid = isNonEmpty(firstName) ? isAlphabeticalStringWithSpace(firstName) : false;
    const isLastNameValid = isNonEmpty(lastName) ? isAlphabeticalStringWithSpace(lastName) : true;
    return !(isFirstNameValid && isLastNameValid);
  };

  onSubmitNamesClicked = () => {
    for (const passenger of this.state.passengers) {
      if (isNonEmpty(passenger.firstName) || isNonEmpty(passenger.lastName)) {
        passenger.firstName = passenger.firstName.trim();
        passenger.lastName = passenger.lastName.trim();
        if (isNonEmpty(passenger.lastName) && isEmpty(passenger.firstName)) {
          this.snackBarState = SNACKBAR_STATES.FIRST_NAME_NOT_PRESENT;
          break;
        } else if (this.isNameInvalid(passenger)) {
          this.snackBarState = SNACKBAR_STATES.NAME_NOT_ALPHABETICAL;
          break;
        } else {
          this.snackBarState = SNACKBAR_STATES.SHOW_CONFIRMATION;
        }
      }
    }
    Keyboard.dismiss();
    this.toggleSnackbar();
  };

  onSubmitNamesCTAClicked = () => {
    this.toggleSnackbar();
    Actions.addGuestNamesResult({
      type: 'replace',
      bookingId: this.props.response.bookingID,
      passengers: this.getAddedPassengers(),
      response: this.props.response
    });
  };

  toggleSnackbar = () => {
    this.setState({showSnackbar: !this.state.showSnackbar});
  };

  getAddedPassengers() {
    return this.state.passengers && this.state.passengers.filter(passenger =>
      isNonEmpty(passenger.firstName.trim()));
  }

  getPluralString = (str) => {
    const paxCount = this.getAddedPassengers().length;
    return paxCount > 1 ? str.concat('s') : str;
  };

  onNameFieldChanged = (passenger, fieldType, name) => {
    passenger[fieldType] = name;
    this.setState({passengers: this.state.passengers});
  };
}

const NameView = ({passengers}) => {
  const fonts = getFont(true);
  const styles = getStyles(fonts);
  const passengerView = [];
  let adultCount = 0;
  let childCount = 0;
  passengers && passengers.forEach((passenger) => {
    adultCount += (passenger.paxType === PAX_TYPE.ADULT) ? 1 : 0;
    childCount += (passenger.paxType === PAX_TYPE.CHILD) ? 1 : 0;
    const paxName = getPaxName(passenger);
    const paxTypeString = getPaxType(passenger, adultCount, childCount);
    passengerView.push(<View style={[AtomicCss.flexRow, AtomicCss.spaceBetween]}
                             key={passenger.firstName}>
      <Text style={styles.traveller}>{paxTypeString}</Text>
      <Text style={styles.travellerName}>{paxName}</Text>
    </View>);
  });
  return (
    <View style={styles.card}>
      <View>
        <Text style={[fonts.boldFontFamily, styles.nameHeading]}>Names Already Added</Text>
      </View>
      {passengerView}
    </View>
  );
};

class TitleField extends React.Component {
  constructor(props) {
    super(props);
    this.fonts = getFont(true);
    this.styles = getStyles(this.fonts);
    this.handleChange = this.handleChange.bind(this);
    this.changeOptions = this.changeOptions.bind(this);
    this.state = {
      options: false,
      optionValue: 'Mr.'
    };
  }

  handleChange() {
    this.setState({options: true});
  }

  changeOptions(title) {
    this.setState({
      optionValue: title,
      options: false
    });
    this.props.passenger.title = title;
    this.props.passenger.gender = (title === PAX_TITLE.MR) ? PAX_GENDER.MALE : PAX_GENDER.FEMALE;
  }

  render() {
    return (
      <View style={this.styles.titleOuter}>
        <View style={this.styles.addGuestLabel}>
          <Text style={this.styles.addGuestLabelText}>Title</Text>
        </View>
        <TouchableOpacity onPress={this.handleChange} style={this.styles.selectBox}>
          <Text style={[this.styles.selectBoxText, this.fonts.boldFontFamily]}>{this.state.optionValue}</Text>
          <View style={this.styles.arrow}></View>
        </TouchableOpacity>
        {this.state.options &&
        <View style={this.styles.selectOptionsOuter}>
          <TouchableOpacity
            style={this.styles.selectOptions}
            onPress={() => this.changeOptions(PAX_TITLE.MR)}
          >
            <Text style={this.fonts.boldFontFamily}>Mr.</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={this.styles.selectOptions}
            onPress={() => this.changeOptions(PAX_TITLE.MRS)}
          >
            <Text style={this.fonts.boldFontFamily}>Mrs.</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={this.styles.selectOptions}
            onPress={() => this.changeOptions(PAX_TITLE.MS)}
          >
            <Text style={this.fonts.boldFontFamily}>Ms.</Text>
          </TouchableOpacity>
        </View>
        }
      </View>
    );
  }
}

class NameField extends React.Component {
  constructor(props) {
    super(props);
    this.fonts = getFont(true);
    this.styles = getStyles(this.fonts);
    this.state = {
      name: ''
    };
  }

  nameEnter = (name) => {
    this.setState({name});
    this.props.onNameFieldChanged(this.props.passenger, this.props.fieldType, name);
  };

  render() {
    return (
      <View style={this.styles.nameOuter}>
        <View style={this.styles.addGuestLabel}>
          <Text style={this.styles.addGuestLabelText}/>
        </View>
        <InputElement {...this.props} handleChange={this.nameEnter} value={this.state.name}/>
      </View>
    );
  }
}

AddGuestNames.propTypes = {
  response: PropTypes.object.isRequired
};

NameField.propTypes = {
  fieldType: PropTypes.string.isRequired,
  passenger: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  onNameFieldChanged: PropTypes.func.isRequired
};

NameView.propTypes = {
  passengers: PropTypes.array.isRequired
};

TitleField.propTypes = {
  passenger: PropTypes.object.isRequired
};
