import React from 'react';
import {Text, TouchableOpacity} from 'react-native';
import PropTypes from 'prop-types';
import LinearGradient from 'react-native-linear-gradient';
import {fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getPlatformElevation from './getPlatformElevation';
import {buttonDisplayTypes} from '../HolidayBookingConstants';
import {themeColors} from '../../PostSalesStyles';

let gradientColor = ['#065af3', '#53b2fe'];


class Button extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    if (this.props.handleClick) {
      this.props.handleClick(this.props.button, this.props.pageName);
    }
  }

  render() {

    const CapsuleBtnStyle = [styles.capsuleBtn];
    const buttonStyle = [styles.buttonStyle];
    const buttonTxtStyle = [styles.buttonTxtStyle, AtomicCss.fontWeightBold];
    const btnWidth = this.props.btnWidth;
    let btnText = '';
    if (this.props.btnTxt) {
      btnText = this.props.btnTxt;
    } else if (this.props.button.text) {
      btnText = this.props.button.text;
    }
    if (this.props.btnBg === buttonDisplayTypes.CAPSULE_WHITE || this.props.button.displayType === buttonDisplayTypes.CAPSULE_WHITE) {
      gradientColor = ['#fff', '#fff'];
      buttonTxtStyle.push(styles.whiteBtnTxt);
      buttonStyle.push(styles.whiteButtonStyle);
    } else if (this.props.btnBg === buttonDisplayTypes.CAPSULE_WHITE_BORDER || this.props.button.displayType === buttonDisplayTypes.CAPSULE_WHITE_BORDER) {
      gradientColor = ['#fff', '#fff'];
      buttonTxtStyle.push(styles.whiteBtnTxt);
      buttonStyle.push(styles.whiteButtonBorderStyle);
      CapsuleBtnStyle.push(styles.noShadowStyle);
    } else if (this.props.btnBg === buttonDisplayTypes.RECTANGLE_GRADIENT || this.props.button.displayType === buttonDisplayTypes.RECTANGLE_GRADIENT) {
      gradientColor = themeColors.gradientBtnColor;
      buttonTxtStyle.push(styles.gradientBtnTxt);
      CapsuleBtnStyle.push(styles.flatBtn);
    } else {
      gradientColor = themeColors.gradientBtnColor;
      buttonTxtStyle.push(styles.gradientBtnTxt);
      buttonStyle.push(styles.whiteButtonStyle);
    }

    return (

      <TouchableOpacity
        style={[buttonStyle, {width: btnWidth}]}
        activeOpacity={0.6}
        onPress={this.handleClick}>
        <LinearGradient
          start={{
            x: 1.0,
            y: 0.0
          }}
          end={{
            x: 0.0,
            y: 1.0
          }}
          colors={gradientColor}
          style={CapsuleBtnStyle}>
          <Text style={buttonTxtStyle}>{btnText}</Text>
        </LinearGradient>

      </TouchableOpacity>

    );
  }
}

const styles = {
  capsuleBtn: {
    borderRadius: 25,
    paddingHorizontal: 20,
    paddingVertical: 14,
    ...getPlatformElevation(2)

  },
  flatBtn: {
    borderRadius: 4,
    paddingHorizontal: 20,
    paddingVertical: 14,
    ...getPlatformElevation(2)

  },
  buttonStyle: {
    backgroundColor: 'transparent',
    ...getPlatformElevation(2)

  },
  buttonTxtStyle: {
    fontSize: 14,
    fontFamily: fonts.black,
    textAlign: 'center',
    color: '#fff'
  },
  whiteButtonStyle: {
    backgroundColor: 'transparent',
    borderRadius: 25,
    margin: 5
  },
  whiteButtonBorderStyle: {
    backgroundColor: '#fff',
    borderRadius: 25,
    borderWidth: 1,
    borderColor: themeColors.whiteBtnTxt,
    margin: 5,
    ...getPlatformElevation(0)
  },
  whiteBtnTxt: {
    color: themeColors.whiteBtnTxt
  },
  gradientBtnTxt: {
    color: '#fff'
  },
  noShadowStyle: {
    ...getPlatformElevation(0),
    paddingVertical: 12
  }
};

Button.propTypes = {
  handleClick: PropTypes.object.isRequired,
  button: PropTypes.object.isRequired,
  btnBg: PropTypes.string.isRequired,
  pageName: PropTypes.string.isRequired
};

export default Button;
