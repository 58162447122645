import React from 'react';
import {
  View,
  Platform,
  BackHandler,
  ScrollView,
  StyleSheet,
  Dimensions,
  Linking,
} from 'react-native';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import Actions from '../navigation/postSalesNavigation';
import BasePage from '../Common/PostSalesBasePage';
import ProgressView from '../Common/ProgressView/ProgressView';
import NoInternetView from '@mmt/legacy-commons/Common/Components/Error/NoInternetView';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import {
  getCommonHeaders,
  HOLIDAY_BOOKING_DETAIL_URL,
  FLIGHT_BOOKING_DETAILS_HOLIDAY_URL,
  HOLIDAY_BOOKING_CANCELLATION_URL,
  IOS_AAROGYA_SETU_LINK,
} from '../utils/NetworkUtils';
import { isNetworkAvailable } from '@mmt/legacy-commons/Common/utils/AppUtils';
import HolidayBookingTrackingHelper from './HolidayBookingTrackingHelper';
import MMTSupportCard from './Components/MMTSupportCard';
import TopStickyCard from './Components/TopStickyCard';
import PackageDetails from './Components/PackageDetails';
import TopCard from './Components/TopCard';
import VisaStatusCard from './Components/VisaStatusCard';
import CancellationCard from './Components/CancellationCard';
import ChangeTravelDateCard from './Components/ChangeTravelDateCard';
import BookingTripInfo from './Components/BookingTripInfo';
import PaymentCard from './Components/PaymentCard';
import TravellerCard from './Components/TravellerCard';
import AddVisaCard from './Components/AddVisaCard';
import AffiliateFooterCard from './Components/AffiliateFooterCard';
import {
  buttonTypes,
  HARDWARE_BACK_PRESS,
  HolidayBookingDetailsConstant,
  holidayDownloadAction,
  itineraryType,
  tabsType,
  CONNECT_WITH_HELPDESK,
  PENDING_TEXT,
  HOLIDAY_CAR_PICKUP_DETAILS_PAGE_IDENTIFIER,
} from './HolidayBookingConstants';
import ContactCard from './Components/ContactCard';
import ContactInfo from './Components/ContactInfo';
import TravellerInfo from './Components/TravellerInfo';
import VisaCard from './Components/VisaCard';
import CancellationModal from './Components/CancellationModal';
import ModifyModal from './Components/ModifyModal';
import InclusionExclusionModal from './Components/InclusionExclusionModal';
import ItineraryModal from './Components/ItineraryModal';
import { InclusionsOverlay } from './Components/InclusionOverlay'
import {
  getDefaultTab,
  getItineraryIndex,
  getPaxConfigLabel,
  getVouchersList,
  getVoucherIndex,
  isDocumentUploadEnabled,
  externalLinkHandler,
  isMinimalBooking,
  getCancellationRequest,
  downloadTicket,
  downloadHolidayVoucher,
  getInitialVoucherState,
} from './HolidayBookingDetailUtils';
import DownloadVoucher from './Components/DownloadVoucher';
import HolidayBookingModule from '@mmt/legacy-commons/Native/HolidayBookingModule';
import RefundTracker from '../Common/RefundTracker';
import PaymentDetails from './Components/PaymentDetails';
import SubmitRequest from './Components/SubmitRequest';
import ItineraryCard from './Components/ItineraryCard';
import DocumentsCard from './Components/DocumentsCard/DocumentsCard';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import {AUTH_FAILED_MESSAGE, COMMON_CARD, ENTITY_TYPES, LOBNAMES, PAGE_IDENTIFIER} from '../PostSalesConstant';
import CancellationPolicyModal from './Components/CancellationPolicyModal';
import MMTBlackCard from '../Common/MMTBlackCard';
import BenefitsClaimedCard from '../Common/BenefitsClaimedCard';
import CheckListSafeTravel from './Components/CheckListSafeTravel';
import CheckListOverlay from './Components/CheckListSafeTravel/CheckListOverlay';
import SelectFlights from './Components/SelectFlights';
import DocumentsPage from './DocumentsPage/DocumentsPage';
import TravelPurpose from './Components/TravelPurpose';
import AddonCard from './Components/AddonCard';
import NeedHelpCard from './Components/NeedHelpCard';
import { backPressHandler, isLostBookingData, openCustomerFormInWebView, isIosClient, isAddVisaEnabled, openWebView } from '../utils/PostSaleUtil';
import { MsrConstants } from '../Common/MSR/Constants';
import MSRMainCard from '../Common/MSR/MainCard';
import ContactUsPage from './Components/ContactUsPage';
import PackageDetailsMinimal from './Components/PackageDetailsMinimal';
import RefundStatusCard from './Components/RefundStatusCard';
import { REFUND_OOT_TRACKING } from '../Common/RefundOutOfTATForm/constants';
import LostIDBookingDetailPage from '../Common/LostIdDetailPage';
import { getCancellationRequestNo, isEasyPayNativeFlowEnabled } from '../Common/commonUtil';
import { themeColors } from '../PostSalesStyles';
import BookMyForexCard from '../Common/BookMyForexCard';
import { getRootTag } from '@mmt/legacy-commons/AppState/RootTagHolder';
import {WEB_VIEW_EVENTS} from '@mmt/legacy-commons/Common/Components/WebViewEventHandlers';
import CommonBottomOverlayMessage from '../Common/CommonOverlayMessage/CommonBottomOverlayMessage';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import AuthErrorView from '../Common/AuthError/AuthErrorView';
import TravelCashCard from '../Common/TravelCard';
import GiftClaimCard from '../Common/GiftClaimCard';

export const HOLIDAY_BOOKING_DETAIL_PAGE = 'HolidayBookingDetail';


export default class HolidayBookingDetail extends BasePage {
  static navigationOptions = {
    header: null,
  };

  constructor(props) {
    super(props, HOLIDAY_BOOKING_DETAIL_PAGE);
    this.bookingId = (props.data && props.data.BOOKING_ID) || props.BOOKING_ID || props.bookingId;
    this.pageData = props.data && props.data.pageData ? JSON.parse(props.data.pageData) : undefined;
    this.isLostBooking = isLostBookingData(this.pageData);
    this.mmtAuth = '';
    this.loggingTrackInfo = {};
    this.parentPage = (props.data && props.data.PARENT_PAGE) || props.PARENT_PAGE;
    this.trackPageName = 'TripDetails';
    this.prePageName = 'mob:cs:in domestic holidays';
    this.state = {
      body: null,
      responseJson: null,
      viewState: this.isLostBooking ? ViewState.SHOW_DETAIL : ViewState.LOADING,
      overlay: false,
      header: false,
      popUp: '',
      travelPurposeRequired: false,
    };
    this.scrollHeight = isIosClient() ? 150 : 200;
    this.toggleOverlay = this.toggleOverlay.bind(this);
    this.onTravelPurposeSuccess = this.onTravelPurposeSuccess.bind(this);
    this.bottomOverlayRef = React.createRef();
  }

  toggleOverlay() {
    this.setState({ overlay: !this.state.overlay });
  }

   closeBottomOverlay = () => {
     const { closeOverlay } = this.bottomOverlayRef.current || {};
     closeOverlay && closeOverlay();
 }

  onTravelPurposeSuccess() {
    this.setState({
      travelPurposeRequired: false,
      popUp: '',
    });
    this.openDetailTabPage(tabsType.VISA);
  }

  redirectToCarpickup(){
    if ((Platform.OS === 'ios' && this.props.pageIdentifier === HOLIDAY_CAR_PICKUP_DETAILS_PAGE_IDENTIFIER) ||
      (Platform.OS === 'android' && this.pageData && this.pageData.pageIdentifier === HOLIDAY_CAR_PICKUP_DETAILS_PAGE_IDENTIFIER)) {
      Actions.holidayAddCarPickUpDetails({
        bookingId: this.bookingId,
      });
    }
  }

  componentWillMount() {
    BackHandler.addEventListener(HARDWARE_BACK_PRESS, this.onHardBackPress);
  }

  componentWillUnmount() {
    BackHandler.removeEventListener(HARDWARE_BACK_PRESS, this.onHardBackPress);
  }

  componentDidMount() {
    this.redirectToCarpickup();
    if (!this.isLostBooking) {
      this.fetchJSONAsync(HOLIDAY_BOOKING_DETAIL_URL + this.bookingId);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.data && (this.props.data.isRefresh !==  prevProps?.data?.isRefresh  && this.props.data.isRefresh === true)){
      this.refreshTripDetailsPageWithLoader();
    }
    if (this.props.data && this.props.data !== prevProps.data && this.props.data.refresh) {
      if (this.isLostBooking) {
        this.setState({
          refreshLostDetail: {},
        });
        return;
      }
    }
  }

  handleScroll(event) {
    if (event.nativeEvent.contentOffset.y > this.scrollHeight) {
      this.setState({
        header: true,
      });
    } else {
      this.setState({
        header: false,
      });
    }
  }

  async fetchJSONAsync(url) {
    try {
      const hasNetwork = await isNetworkAvailable();
      if (!hasNetwork) {
        this.setState({ viewState: ViewState.NO_INTERNET });
        return;
      }
      const userInfo = await HotelBookingModule.getRequestHeader(
        this.bookingId,
        'HOLIDAY_BOOKING_DETAIL',
      );
      this.mmtAuth = userInfo.mmtAuth;
      this.loggingTrackInfo = userInfo.loggingTrackingInfo;
      const commonHeaders = await getCommonHeaders(this.mmtAuth, this.loggingTrackInfo);
      const response = await fetch(url, {
        headers: {
          ...commonHeaders,
          'accept-encoding': 'gzip',
        },
      });

      if (response.ok) {
        const body = await response.json();
        body.parentPage = this.parentPage;
        const {
          holidayBasePageResponse,
          bookingState,
          mobile,
          emailid,
          contactPageDetails,
          travellerPageDetails,
          cancellationPage,
          cancellationPolicyPage,
          dateChangePolicyPage,
          modificationPage,
          paymentDetails,
          daywiseItineraryDetails,
          checkList,
          downloadVoucherPage,
          refundRequestNoList,
          flightDetailsType = null,
          hotelDetailsMyTrips = null,
          activityPageDetails = null,
          transfers = null,
          documentPageDetails = null,
          visaPageDetails,
          insurancePageDetails,
          allTicketsForBooking,
          bookingType,
          wtdRefundDetails,
          currency,
          isBundlePackage,
          loyaltyDetails,
        } = body;
        if (get(holidayBasePageResponse, 'packageInclusions.flights')) {
          await this.fetchFlightBookingDetails(FLIGHT_BOOKING_DETAILS_HOLIDAY_URL + this.bookingId);
        }
        this.setState({
          body: body,
          responseJson: holidayBasePageResponse,
          bookingState,
          mobile,
          emailid,
          currency,
          contactPageDetails,
          travellerPageDetails,
          cancellationPage,
          cancellationPolicyPage,
          dateChangePolicyPage,
          modificationPage,
          paymentDetails,
          loyaltyDetails,
          daywiseItineraryDetails,
          checkList,
          downloadVoucherPage,
          hotelDetailsMyTrips,
          flightDetailsType,
          refundRequestNoList,
          detailTab: {
            visaPageDetails,
            flightDetailsType,
            hotelDetailsMyTrips,
            activityPageDetails,
            transfers,
            contactPageDetails,
            travellerPageDetails,
            insurancePageDetails,
          },
          viewState: ViewState.SHOW_DETAIL,
          documentPageDetails,
          visaPageDetails,
          travelPurposeRequired: get(
            visaPageDetails,
            'holidayVisaDetailsList[0].travellerProfileRequired',
            false,
          ),
          bookingType,
          wtdRefundDetails,
          isBundlePackage,
          allTicketsForBooking,
          downloadVoucherStatus:
            downloadVoucherPage &&
            !isEmpty(downloadVoucherPage.downloadVoucherSegmentList) &&
            getInitialVoucherState(downloadVoucherPage.downloadVoucherSegmentList),
        });
        HolidayBookingTrackingHelper.setCommonParams(
          this.trackPageName,
          body,
          commonHeaders.deviceIdentifier,
          JSON.parse(this.loggingTrackInfo).appVersion,
        );
        this.prePageName = HolidayBookingTrackingHelper.getPrePageName();
        if (isMinimalBooking(bookingType)) {
          this.trackPageName = 'TripDetails_minimalMyTrips';
        }
        HolidayBookingTrackingHelper.trackViewEvent(
          this.trackPageName,
          'Holiday Booking Details',
          'Summary',
        );
      } else {
        const errorBody = await response?.json();
        if (errorBody && errorBody?.message && errorBody.message.toUpperCase() === AUTH_FAILED_MESSAGE) {
          this.setState({ viewState: ViewState.AUTH_ERROR });
        } else {
          this.setState({viewState: ViewState.ERROR});
          this.trackError(response.status);
        }
      }
    } catch (error) {
      console.log(error);
      this.setState({ viewState: ViewState.ERROR });
      this.trackError(error.message);
    }
  }

  async fetchFlightBookingDetails(url) {
    try {
      const hasNetwork = await isNetworkAvailable();
      if (!hasNetwork) {
        this.setState({ viewState: ViewState.NO_INTERNET });
        return;
      }
      const response = await fetch(url, {
        headers: await getCommonHeaders(this.mmtAuth, this.loggingTrackInfo),
      });
      if (response && response.ok) {
        const body = await response.json();
        this.setState({
          flightDetailResponse: body,
        });
      } else {
        this.setState({ viewState: ViewState.ERROR });
        this.trackError(response.status);
      }
    } catch (error) {
      console.log(error);
      this.setState({ viewState: ViewState.ERROR });
      this.trackError(error.message);
    }
  }

  trackError = (error) => {
    HolidayBookingTrackingHelper.trackErrorEvent(
      'Mob:Customer Support:Holiday:TripDetailsError',
      this.bookingId,
      error,
      undefined,
    );
  };

  render() {
    return (
      <View style={{ flex: 1 }}>
        {this.state.viewState === ViewState.ERROR && this.renderErrorView()}
        {this.state.viewState === ViewState.AUTH_ERROR && this.renderAuthErrorView()}
        {this.state.viewState === ViewState.SHOW_DETAIL && this.renderPage()}
        {this.state.viewState === ViewState.NO_INTERNET && this.renderNoNetworkView()}
        {this.state.viewState === ViewState.LOADING && this.renderProgressView()}
      </View>
    );
  }

  renderErrorView = () => (
    <ErrorView
      showRetry
      onRetry={() => {
        this.setState({ viewState: ViewState.LOADING });
        this.fetchJSONAsync(HOLIDAY_BOOKING_DETAIL_URL + this.bookingId);
      }}
      buttonGradient={themeColors.gradientBtnColor}
    />
  );

  renderAuthErrorView = () => <AuthErrorView bookingId={this.bookingId} lobName={LOBNAMES.HOLIDAY} onGoBackPress={this.goBack} />

  renderNoNetworkView = () => (
    <NoInternetView
      onRetry={() => {
        this.setState({ viewState: ViewState.LOADING });
        this.fetchJSONAsync(HOLIDAY_BOOKING_DETAIL_URL + this.bookingId);
      }}
    />
  );

  renderProgressView = () => <ProgressView message="Loading Your Trip..." />;

  openDetailTabPage = (defaultTab) => {
    let flightBookingDetail;
    if (defaultTab === tabsType.FLIGHTS) {
      flightBookingDetail = this.state.flightDetailResponse;
    }
    Actions.holidayMimaTabDetail({
      ...this.state.detailTab,
      trackPageName: this.trackPageName,
      defaultTab,
      BOOKING_ID: this.bookingId,
      holidayData: {
        BOOKING_ID: this.bookingId,
        MMT_AUTH: this.mmtAuth,
        LOGGING_TRACKINFO: this.loggingTrackInfo,
        PARENT_PAGE: this.parentPage,
      },
      checklist: this.state.checkList,
      mmtAuth: this.mmtAuth,
      loggingTrackInfo: this.loggingTrackInfo,
      isTravelProfileRequired: this.state.travelPurposeRequired,
      isBundlePackage: this.state.isBundlePackage,
      flightBookingDetail,
      holidayResponse: this.state.responseJson,
    });
  };

  renderPage() {
    let views = [];
    if (this.isLostBooking) {
      return (
        <LostIDBookingDetailPage
          bookingId={this.bookingId}
          pageData={this.pageData}
          lobName={LOBNAMES.HOLIDAY}
          refreshLostDetail={this.state.refreshLostDetail}
          getRefundCard={({ cancellationDetailList }) => {
            this.addRefundCard(views, getCancellationRequestNo(cancellationDetailList), true);
            return views;
          }}
        />
      );
    }
    const travellerPageDetails = this.state.travellerPageDetails;
    const travellerHeaderLabel =
      travellerPageDetails &&
      getPaxConfigLabel(
        travellerPageDetails.noOfAdults,
        travellerPageDetails.noOfChildren,
        travellerPageDetails.noOfInfants,
      );
    views = this.getMainPageViews();
    return (
      <React.Fragment>
        {this.state.header && (
          <View style={styles.stickyWrapper}>
            <TopStickyCard
              bookingState={this.state.bookingState}
              topCard={this.state.responseJson.topCard}
              goBack={this.goBack}
            />
          </View>
        )}
        <ScrollView
          style={styles.cardWrapper}
          onScroll={this.handleScroll.bind(this)}
          scrollEventThrottle={1000}
        >
          {views}
        </ScrollView>
        {this.state.popUp === buttonTypes.CONTACTS && (
          <ContactInfo
            pageName="Contactsdetail"
            holidayContactList={this.state.contactPageDetails.holidayContactList}
            callMmt={this.callMmt}
            sendMail={this.sendMail}
            showLocation={this.showLocation}
            onBackPopUp={this.onBackPopUp}
          />
        )}
        {this.state.popUp === buttonTypes.TRAVELLERDETAILS && (
          <TravellerInfo
            pageName="Travelersdetail"
            travellerPageDetails={this.state.travellerPageDetails}
            travellerHeaderLabel={travellerHeaderLabel}
            onBackPopUp={this.onBackPopUp}
          />
        )}
        {this.state.popUp === buttonTypes.CANCEL && (
          <CancellationModal
            pageName="Cancellationstandalone"
            cancellationPage={this.state.cancellationPage}
            handleButtonClick={this.handleButtonClick}
            onBackPopUp={this.onBackPopUp}
          />
        )}
        {this.state.popUp === buttonTypes.CANCELPOLICY && this.state.cancellationPolicyPage && (
          <CancellationPolicyModal
            pageName="Cancellationstandalone"
            cancellationPolicyPage={this.state.cancellationPolicyPage}
            handleButtonClick={this.handleButtonClick}
            onBackPopUp={this.onBackPopUp}
          />
        )}
        {this.state.popUp === buttonTypes.CANCELLATION && this.state.cancellationPolicyPage && (
          <CancellationPolicyModal
            pageName="Cancellationstandalone"
            cancellationPolicyPage={this.state.cancellationPolicyPage}
            handleButtonClick={this.handleButtonClick}
            onBackPopUp={this.onBackPopUp}
          />
        )}
        {this.state.popUp === buttonTypes.DATECHANGE && this.state.dateChangePolicyPage && (
          <CancellationPolicyModal
            pageName="Datechangestandalone"
            cancellationPolicyPage={this.state.dateChangePolicyPage}
            handleButtonClick={this.handleButtonClick}
            onBackPopUp={this.onBackPopUp}
          />
        )}
        {this.state.popUp === buttonTypes.MODIFY && (
          <ModifyModal
            pageName="Modificationstandalone"
            modificationPage={this.state.modificationPage}
            handleButtonClick={this.handleButtonClick}
            onBackPopUp={this.onBackPopUp}
          />
        )}
        {this.state.popUp === buttonTypes.INCLUSIONEXCLUSION && (
          <InclusionExclusionModal
            pageName="InclusionExclusions"
            usefulLinks={this.state.responseJson.usefulLinks}
            handleButtonClick={this.handleButtonClick}
            onBackPopUp={this.onBackPopUp}
          />
        )}
        {this.state.popUp === buttonTypes.PAYMENTDETAILS && (
          <PaymentDetails
            pageName="PaymentDetails"
            paymentDetails={this.state.paymentDetails}
            handleButtonClick={this.handleButtonClick}
            onBackPopUp={this.onBackPopUp}
          />
        )}
        {(this.state.popUp === buttonTypes.ITINERARY ||
          this.state.popUp === buttonTypes.ITINERARY_CARD) &&
          this.state.daywiseItineraryDetails && (
            <ItineraryModal
              pageName="ViewItinerary"
              daywiseItineraryDetails={this.state.daywiseItineraryDetails}
              travellerHeaderLabel={travellerHeaderLabel}
              handleButtonClick={this.handleButtonClick}
              handleItineraryClick={this.handleItineraryClick}
              onBackPopUp={this.onBackPopUp}
              isBundlePackage={this.state.isBundlePackage}
            />
          )}
        {this.state.popUp === buttonTypes.DOWNLOADVOUCHER && (
          <DownloadVoucher
            pageName="VoucherDownload"
            status={this.state.downloadVoucherStatus}
            downloadVoucherPage={this.state.downloadVoucherPage}
            handleButtonClick={this.handleButtonClick}
            onBackPopUp={this.onBackPopUp}
            downloadVoucher={this.downloadVoucher}
          />
        )}
        {this.state.popUp === buttonTypes.SUBMIT && (
          <SubmitRequest
            pageName="SubmitRequest"
            mmtAuth={this.mmtAuth}
            bookingId={this.bookingId}
            emailId={this.state.emailid}
            mobileNo={this.state.mobile}
            requestType={this.state.submitRequestType}
            loggingTrackInfo={this.loggingTrackInfo}
            onBackPopUp={this.onBackPopUp}
            holidayResponse={this.state.body}
          />
        )}
        {this.state.popUp === buttonTypes.WEBCHECKIN && (
          <CheckListOverlay
            pageName="CheckListOverlay"
            checkList={this.state.checkList}
            handleButtonClick={this.handleButtonClick}
            checklistActionHandler={this.checklistActionHandler}
            onBackPopUp={this.onBackPopUp}
          />
        )}
        {this.state.popUp === buttonTypes.SELECT_FLIGHT && (
          <SelectFlights
            pageName="SelectFlightOverlay"
            flightDetailsType={this.state.flightDetailsType}
            handleButtonClick={this.handleButtonClick}
            externalLinkHandler={externalLinkHandler}
            onBackPopUp={this.onBackPopUp}
            holidayResponse={this.state.body}
          />
        )}
        {(this.state.popUp === buttonTypes.UPLOADDOCUMENTS ||
          this.state.popUp === buttonTypes.VIEWUPLOADEDDOC) && (
          <DocumentsPage
            pageName="UploadDocsTravellerList"
            response={this.state.responseJson}
            documentPageDetails={this.state.documentPageDetails}
            bookingId={this.bookingId}
            mmtAuth={this.mmtAuth}
            loggingTrackInfo={this.loggingTrackInfo}
            refreshTripDetailsPage={this.refreshTripDetailsPage}
            onBackPopUp={this.onBackPopUp}
          />
        )}
        {this.state.popUp === buttonTypes.TRAVELPROFILE && (
          <TravelPurpose
            mmtAuth={this.mmtAuth}
            loggingtrackInfo={this.loggingTrackInfo}
            bookingId={this.bookingId}
            visaPageDetails={this.state.visaPageDetails}
            openDetailTabPage={this.openDetailTabPage}
            onBackPopUp={this.onBackPopUp}
            onSubmitSuccess={this.onTravelPurposeSuccess}
          />
        )}
        {this.state.popUp === buttonTypes.CONTACT_RO && (
          <ContactUsPage response={this.state.responseJson} onBackPopUp={this.onBackPopUp} />
        )}
        {this.state.popUp === buttonTypes.INCLUSIONS_POPUP && (
          <CommonBottomOverlayMessage
            ref={this.bottomOverlayRef}
            isBorderRadius
            dismiss={this.onBackPopUp}
            content={
              <InclusionsOverlay
                handleClose={this.closeBottomOverlay}
                {...this.getInclusionsData(this.state.responseJson)}
              />
            }
          />
        )}
        {this.state.popUp === buttonTypes.INCLUSIONS_POPUP && (
         <CommonBottomOverlayMessage
            ref={ this.bottomOverlayRef }
            isBorderRadius
            dismiss={ this.onBackPopUp }
            content=
            {
                <InclusionsOverlay
                handleClose={ this.closeBottomOverlay }
                { ...this.getInclusionsData( this.state.responseJson )}
                />
            }
         />
        )}
      </React.Fragment>
    );
  }

  getInclusionsData( response ) {
    const  { inclusions , inclusionsHeading } = response || {};
    return {
            inclusions : inclusions,
            inclusionsHeading : inclusionsHeading
          };
  }

  getMainPageViews() {
    const views = [];
    const cards = this.state.responseJson.holidayBasePageCardList;
    cards.forEach((card) => {
      this.addCard(card, views);
    });
    return views;
  }

  addCard(card, views) {
    switch (card.cardId) {
      case HolidayBookingDetailsConstant.TOP_CARD:
        views.push(
          <View
            style={{
              elevation: 2,
              zIndex: 10,
            }}
            ref={(view) => {
              this.myComponent = view;
            }}
          >
            <TopCard
              bookingStateValue={this.state.bookingState}
              buttons={card.buttons}
              topCard={this.state.responseJson.topCard}
              handleButtonClick={this.handleButtonClick}
              goBack={this.goBack}
            />
          </View>,
        );
        break;
      case HolidayBookingDetailsConstant.VISA_STATUS_CARD:
        if (this.state.travelPurposeRequired) {
          views.push(
            <View style={{ elevation: 1 }}>
              <VisaStatusCard
                card={card}
                buttons={card.buttons}
                handleButtonClick={this.handleButtonClick}
              />
            </View>,
          );
        }
        break;
      case HolidayBookingDetailsConstant.BOOKING_SUMMARY_CARD:
        if (isMinimalBooking(this.state.bookingType)) {
          views.push(
            <View style={{ elevation: 1 }}>
              <PackageDetailsMinimal bookingSummary={this.state.responseJson.bookingSummary} />
            </View>,
          );
        } else {
          views.push(<View style={{ elevation: 1 }}>
            <PackageDetails
              handleButtonClick={ this.handleButtonClick }
              bookingSummary={this.state.responseJson.bookingSummary}
              packageInclusions={this.state.responseJson.packageInclusions}
              isVisaIncluded={get(this.state, 'visaPageDetails.holidayVisaDetailsList.length') > 0}
              showPopUp={this.showPopUp}
              response={this.state.responseJson}
              openDetailTabPage={this.openDetailTabPage}
              trackPageName={this.trackPageName}
              isTransferDetailsPending={get(this.state, 'detailTab.transfers.transferSubHeading',"") === PENDING_TEXT.toUpperCase()}
            />
          </View>);
        }
        break;
      case HolidayBookingDetailsConstant.ADD_VISA_CARD:
        if (isAddVisaEnabled()) {
          views.push(
            <AddVisaCard
              cardData={this.state.responseJson.addVisaCard}
              bookingId={this.bookingId}
              pageName={this.trackPageName}
            />,
          );
        }
        break;
      case HolidayBookingDetailsConstant.CONTACT_CARD:
        views.push(
          <View style={{ elevation: 1 }}>
            <ContactCard
              contactCard={this.state.responseJson.contactCard}
              contactPageDetails={this.state.contactPageDetails}
              buttons={card.buttons}
              handleButtonClick={this.handleButtonClick}
            />
          </View>,
        );
        break;
      case HolidayBookingDetailsConstant.TRAVELLERS_CARD:
        if (get(this.state, 'travellerPageDetails.travellerDetailList')) {
          views.push(
            <View style={{ elevation: 1 }}>
              <TravellerCard
                travellerDetails={this.state.travellerPageDetails}
                buttons={card.buttons}
                handleButtonClick={this.handleButtonClick}
              />
            </View>,
          );
        }
        break;
      case HolidayBookingDetailsConstant.PAYMENT_CARD:
        views.push(
          <View style={{ elevation: 1 }}>
            <PaymentCard
              paymentCard={this.state.responseJson.paymentCard}
              paymentDetails={this.state.paymentDetails}
              buttons={card.buttons}
              handleButtonClick={this.handleButtonClick}
            />
          </View>,
        );
        break;
        case COMMON_CARD.TRAVEL_CARD:
          if(!isEmpty(this.state.responseJson.travelCardResponse)){
            views.push(
              <TravelCashCard
              key="TravelCashCard"
              uuid={this.state.responseJson.uuid}
              travelCardResponse={this.state.responseJson.travelCardResponse}
              bookingID={this.bookingId}
              />
            )
          }
        break;
      case HolidayBookingDetailsConstant.DOCUMENTS_UPLOAD_CARD:
        if (isDocumentUploadEnabled()) {
          views.push(
            <DocumentsCard
              response={this.state.responseJson}
              submitToVendor={
                this.state.documentPageDetails && this.state.documentPageDetails.submitToVendor
                  ? this.state.documentPageDetails.submitToVendor
                  : false
              }
              trackPageName={this.trackPageName}
              handleButtonClick={this.handleButtonClick}
            />,
          );
        }
        break;
      case HolidayBookingDetailsConstant.CANT_FIND_CARD:
        views.push(
          <View style={{ elevation: 1 }}>
            <MMTSupportCard
              cantFind={this.state.responseJson.cantFind}
              buttons={card.buttons}
              handleButtonClick={this.handleButtonClick}
            />
          </View>,
        );
        break;
      case HolidayBookingDetailsConstant.VISA_CARD:
        views.push(
          <View style={{ elevation: 1 }}>
            <VisaCard visaCard={this.state.responseJson.visaCard} />
          </View>,
        );
        break;
      case HolidayBookingDetailsConstant.KNOW_MORE_CARD:
        views.push(
          <View style={{ elevation: 1 }}>
            <BookingTripInfo
              handleButtonClick={this.handleButtonClick}
              knowMore={this.state.responseJson.knowMore}
            />
          </View>,
        );
        break;
      case HolidayBookingDetailsConstant.CANCEL_BOOKING_CARD:
        views.push(
          <View style={{ elevation: 1 }}>
            <CancellationCard
              card={card}
              buttons={card.buttons}
              handleButtonClick={this.handleButtonClick}
            />
          </View>,
        );
        break;
      case HolidayBookingDetailsConstant.DATE_CHANGE_CARD:
        views.push(
          <View style={{ elevation: 1 }}>
            <ChangeTravelDateCard
              card={card}
              buttons={card.buttons}
              handleButtonClick={this.handleButtonClick}
            />
          </View>,
        );
        break;
      case HolidayBookingDetailsConstant.REFUND_CARD:
        this.addRefundCard(views, this.state.refundRequestNoList);
        break;
      case HolidayBookingDetailsConstant.ITINERARY_CARD:
        if (this.state.daywiseItineraryDetails) {
          views.push(
            <View style={{ elevation: 1 }}>
              <ItineraryCard
                itineraryCard={this.state.responseJson.itineraryCard}
                buttons={card.buttons}
                handleButtonClick={this.handleButtonClick}
              />
            </View>,
          );
        }
        break;
      case COMMON_CARD.LOYALTY_CARD:
        if (this.state.loyaltyDetails) {
          views.push(
            <MMTBlackCard
              {...this.state.loyaltyDetails}
              bookingId={this.bookingId}
              uuid={this.state.responseJson.uuid}
              onClick={this.onMMTBlackCardClickEvent}
              trackingPageName={this.trackPageName}
            />,
          );
        }
        break;
        case COMMON_CARD.BENEFIT_CLAIMED_CARD:
          if (this.state.loyaltyDetails) {
            views.push(
              <BenefitsClaimedCard
                key="BenefitsClaimedCard"
                {...this.state.loyaltyDetails}
                bookingId={this.bookingId}
                trackingPageName={this.trackPageName}
              />);
          if(this.state.otherGCClaimedDetails){
            views.push(
              <GiftClaimCard key="GiftClaimCard"  otherGCClaimedDetails={this.state.otherGCClaimedDetails} isloyaltyDetails={!isEmpty(this.state.loyaltyDetails)} />
            )
          }
        }
      break;
      case HolidayBookingDetailsConstant.WEB_CHECKIN_CARD:
        if (this.state.checkList && this.state.flightDetailResponse) {
          views.push(
            <CheckListSafeTravel
              onViewAllClick={this.toggleChecklistOverlay}
              checkList={this.state.checkList}
              checklistActionHandler={this.checklistActionHandler}
            />,
          );
        }
        break;
      case HolidayBookingDetailsConstant.PACKAGE_ADDONS:
        if (
          this.state.responseJson.packageAddOnCard &&
          !isEmpty(this.state.responseJson.packageAddOnCard.items)
        ) {
          views.push(<AddonCard addOnCard={this.state.responseJson.packageAddOnCard} />);
        }
        break;
      case HolidayBookingDetailsConstant.NEED_HELP_CARD:
        if (
          this.state.responseJson &&
          this.state.responseJson.holidayHelpCard &&
          this.state.responseJson.holidayHelpCard.isEnabled
        ) {
          views.push(
            <NeedHelpCard
              cardData={this.state.responseJson.holidayHelpCard}
              handleButtonClick={this.handleButtonClick}
            />,
          );
        }
        break;
      case MsrConstants.MSR_MAIN_CARD:
        views.push(
          <MSRMainCard
            key="MSRMainCard"
            ticketsList={(this.state.allTicketsForBooking || {}).ticketsList}
            pageName={this.prePageName + this.trackPageName}
          />,
        );
        break;
      case HolidayBookingDetailsConstant.WTD_CARD:
        if (
          isMinimalBooking(this.state.bookingType) &&
          this.state.wtdRefundDetails &&
          this.state.wtdRefundDetails.wtdDetails
        ) {
          views.push(
            <RefundStatusCard
              wtdRefundDetails={this.state.wtdRefundDetails}
              bookingId={this.bookingId}
            />,
          );
        }
        break;
      case HolidayBookingDetailsConstant.AFFILIATE_FOOTER_CARD:
        views.push(
          <AffiliateFooterCard
            affiliateFooterDetails={this.state.responseJson.holidayAffiliateCard}
          />,
        );
        break;
      case COMMON_CARD.BOOK_MY_FOREX_CARD:
        views.push(
          <BookMyForexCard
            cardData={this.state.responseJson.forexDetail}
            pageName={this.trackPageName}
          />,
        );
        break;
      default:
        break;
    }
  }

  onMMTBlackCardClickEvent = (omnitureClickEvent) => {
    HolidayBookingTrackingHelper.trackClickEvent(this.trackPageName, omnitureClickEvent);
  };

  addRefundCard(views, refundRequestNoList, lostBooking = false) {
    if (!isEmpty(refundRequestNoList)) {
      views.push(
        <View
          style={{
            elevation: 5,
            zIndex: 15,
          }}
        >
          <RefundTracker
            key="RefundTracker"
            cancelRequests={refundRequestNoList}
            bookingId={this.bookingId}
            lobName={LOBNAMES.HOLIDAY}
            pageName={REFUND_OOT_TRACKING.PAGES.HOLIDAY}
            isLostBookingId={lostBooking}
          />
        </View>,
      );
    }
  }

  sendMail = (email = '') => {
    const params = {};
    params.email = email;
    GenericModule.sendEmail(params);
  };

  showLocation = (url = '') => {};

  startChat = () => {
    const paramMap = {
      entityType: ENTITY_TYPES.POST_SALES_BOOKING_HOLIDAY,
      entityKey: this.bookingId,
      pageIdentifier: PAGE_IDENTIFIER.HOLIDAYS_DETAILS_PAGE,
    };
    GenericModule.openMyraFromParams(paramMap);
  };

  callMmt = (phone = '') => {
    Linking.openURL(`tel:${phone}`);
  };

  openLink = (link = '', header = '') => {
    Linking.canOpenURL(link).then(
      (supported) => {
        supported && Linking.openURL(link);
      },
      (err) => console.log(err),
    );
  };

  showPopUp = (popUpOverlay) => {
    this.setState({
      popUp: popUpOverlay,
    });
  };

  handleItineraryClick = (itineraryTypeValue, index, dataIndex) => {
    if (itineraryTypeValue === itineraryType.Hotel || itineraryTypeValue === itineraryType.Free) {
      const hotelIndex = getItineraryIndex(
        itineraryTypeValue,
        index,
        dataIndex,
        this.state.daywiseItineraryDetails,
      );
      if (
        this.state.isBundlePackage &&
        this.state.hotelDetailsMyTrips.hotelDetails[hotelIndex].bookingStatus.toLowerCase() ===
          'confirmed'
      ) {
        const defaultTab = getDefaultTab(itineraryTypeValue);
        this.openDetailTabPage(defaultTab);
      } else {
        this.viewHotelDetail({
          vendorNo: this.state.hotelDetailsMyTrips.hotelDetails[hotelIndex].vendorNo,
          serviceVoucherNo: this.state.hotelDetailsMyTrips.hotelDetails[hotelIndex]
            .serviceVoucherNo,
          index: hotelIndex,
        });
      }
    } else if (itineraryTypeValue === itineraryType.Flight) {
      this.viewFlightDetail(
        getItineraryIndex(itineraryTypeValue, index, dataIndex, this.state.daywiseItineraryDetails),
      );
    } else {
      const defaultTab = getDefaultTab(itineraryTypeValue);
      this.openDetailTabPage(defaultTab);
    }
  };

  viewHotelDetail = async ({ vendorNo, serviceVoucherNo, index }) => {
    const userInfo = await HotelBookingModule.getRequestHeader(
      this.bookingId,
      'HOTEL_CHILD_BOOKING_INFO',
    );
    HolidayBookingTrackingHelper.trackClickEvent('ViewItinerary', 'View_Hotel_Detail');
    Actions.hotelBookingDetail({
      BOOKING_ID: this.bookingId,
      holidayBooking: true,
      holidayPageName: this.prePageName + this.trackPageName + '_Hotels',
      vendorNo,
      serviceVoucherNo,
      stickyHeader: false,
      MMT_AUTH: userInfo.mmtAuth,
      type: 'push',
    });
  };

  viewFlightDetail = (collapseIndex = 0) => {
    HolidayBookingTrackingHelper.trackClickEvent('ViewItinerary', 'View_Flight_Detail');
    Actions.flightBookingDetail({
      BOOKING_ID: this.bookingId,
      holidayPageName: this.trackPageName + '_Flights',
      collapseIndex,
      stickyHeader: false,
      type: 'push',
      holidayBookingApi: true,
      showMyraBtn: false,
    });
  };

  viewVisaDetail = (refId, collapseIndex = 0) => {
    HolidayBookingTrackingHelper.trackClickEvent(
      'ViewItinerary',
      `Visa_View_Detail_${collapseIndex}`,
    );
    Actions.visaBookingDetail({
      BOOKING_ID: this.bookingId,
      holidayPageName: this.prePageName + this.trackPageName + '_Visa',
      refBookingId: refId,
      stickyHeader: false,
      type: 'push',
      holidayBookingApi: true,
      showMyraBtn: false,
    });
  };

  downloadVoucher = (item) => {
    this.downloadAllVoucher(item.type, item.voucherId, item.voucherUrl);
  };
  setVoucherStatus = (status) => {
    this.setState({ downloadVoucherStatus: status });
  };

  downloadAllVoucher = (type = '', voucherId = '', voucherUrl = '') => {
    const vouchers = getVouchersList(this.state.downloadVoucherPage, type, voucherId, voucherUrl);
    const voucherIndex = getVoucherIndex(
      this.state.downloadVoucherPage,
      type,
      voucherId,
      voucherUrl,
    );

    if (!isEmpty(vouchers)) {
      if (Platform.OS === 'web') {
        HolidayBookingModule.downloadTickets(
          JSON.stringify(vouchers),
          this.bookingId,
          this.state.downloadVoucherStatus,
          this.setVoucherStatus,
          this.state.downloadVoucherPage.defaultErrorMessage,
          voucherIndex,
        );
      } else {
        if (Platform.OS === 'android') {
          downloadHolidayVoucher(
            vouchers,
            this.bookingId,
            this.state.downloadVoucherStatus,
            this.setVoucherStatus,
            this.state.downloadVoucherPage.defaultErrorMessage,
            voucherIndex,
          );
        }
      }
    }
  };

  openUploadDocumentsPage = () => {
    Actions.holidayUploadDocuments({
      response: this.state.responseJson,
      documentPageDetails: this.state.documentPageDetails,
      pageName: 'UploadDocsTravellerList',
      bookingId: this.bookingId,
      refreshTripDetailsPage: () =>
        this.fetchJSONAsync(HOLIDAY_BOOKING_DETAIL_URL + this.bookingId),
    });
  };
  openCancellationReview = () => {
    const cancellationPreviewRequest = getCancellationRequest(
      this.bookingId,
      true,
      'RefundToCustomer',
    );
    Actions.holidayCancellationReview({
      holidayResponse: this.state.body,
      url: HOLIDAY_BOOKING_CANCELLATION_URL,
      pageName: 'ViewRefund',
      currency: this.state.currency,
      mmtAuth: this.mmtAuth,
      loggingTrackInfo: this.loggingTrackInfo,
      packageName: this.state.responseJson.topCard.packageName,
      packageDuration: this.state.responseJson.topCard.travelDates,
      bookingId: this.bookingId,
      cancellationPreviewRequest,
    });
  };

  refreshTripDetailsPage = () => {
    this.fetchJSONAsync(HOLIDAY_BOOKING_DETAIL_URL + this.bookingId);
  };

  refreshTripDetailsPageWithLoader = () => {
    this.setState({viewState: ViewState.LOADING});
    this.refreshTripDetailsPage();
  };

  handleButtonClick = (button, pageName = 'TripDetails') => {
    if (button.type === buttonTypes.CUSTOMER_FORM) {
      HolidayBookingTrackingHelper.trackClickEvent(this.trackPageName, 'MSR_Entry');
    } else {
      HolidayBookingTrackingHelper.trackClickEvent(this.trackPageName, button.text);
    }
    if (button.type === buttonTypes.PHONE) {
      this.callMmt(button.phone);
    } else if (button.type === buttonTypes.CHAT) {
      this.startChat();
      this.showPopUp(button.type);
    } else if (button.type === buttonTypes.LINK) {
      this.openLink(button.link, button.header);
    } else if (button.type === buttonTypes.DOWNLOADINVOICE) {
      downloadTicket(holidayDownloadAction.INVOICE, this.bookingId);
    } else if (button.type === buttonTypes.DOWNLOADALLVOUCHERS) {
      this.downloadAllVoucher();
    } else if (
      button.type === buttonTypes.PACKAGEDETAILS ||
      button.type === buttonTypes.VIEWDETAILS
    ) {
      this.openDetailTabPage('');
    } else if (
      button.type === buttonTypes.PAYMENTDETAILS ||
      button.type === buttonTypes.ITINERARY ||
      button.type === buttonTypes.INCLUSIONEXCLUSION ||
      button.type === buttonTypes.TRAVELLERDETAILS ||
      button.type === buttonTypes.CONTACTS ||
      button.type === buttonTypes.SUBMIT ||
      button.type === buttonTypes.DOWNLOADVOUCHER ||
      button.type === buttonTypes.ITINERARY_CARD ||
      button.type === buttonTypes.WEBCHECKIN ||
      button.type === buttonTypes.TRAVELPROFILE ||
      button.type === buttonTypes.CONTACT_RO ||
      button.type === buttonTypes.INCLUSIONS_POPUP
    ) {
      HolidayBookingTrackingHelper.trackViewEvent(this.trackPageName, button.type, button.type);
      this.showPopUp(button.type);
    } else if (button.type === buttonTypes.PROCEEDCANCELLATION){
      this.showPopUp('');
      this.openCancellationReview();
    } else if (
      button.type === buttonTypes.CANCEL ||
      button.type === buttonTypes.CANCELPOLICY ||
      button.type === buttonTypes.CANCELLATION
    ) {
      this.showPopUp(button.type);
      HolidayBookingTrackingHelper.trackViewEvent(
        this.trackPageName,
        buttonTypes.CANCELPOLICY,
        buttonTypes.CANCELPOLICY,
      );
      this.setState({
        submitRequestType: 'Cancellation',
      });
    } else if (button.type === buttonTypes.MODIFY || button.type === buttonTypes.DATECHANGE) {
      HolidayBookingTrackingHelper.trackViewEvent(this.trackPageName, button.type, button.type);
      this.showPopUp(button.type);
      this.setState({
        submitRequestType: 'Amendment',
      });
    } else if (
      button.type === buttonTypes.UPLOADDOCUMENTS ||
      button.type === buttonTypes.VIEWUPLOADEDDOC
    ) {
      HolidayBookingTrackingHelper.trackViewEvent(this.trackPageName, button.type, button.type);
      this.showPopUp(button.type);
    } else if (button.type === buttonTypes.CUSTOMER_FORM) {
      this.openCustomerForm();
    } else if (button.type === buttonTypes.EASYPAY_LINK) {
      isEasyPayNativeFlowEnabled()
        ? GenericModule.openDeepLink(button.link)
        : this.openLink(button.link, button.header);
    } else if (button.type === buttonTypes.RATEUS){
      openWebView(button.header, null, button.link);
    }
  };

  openCustomerForm = () => {
    const action = {
      webViewHeader: CONNECT_WITH_HELPDESK,
      url: this.state.responseJson.holidayHelpCard.customerFormUrl,
    };
    if (Platform.OS === 'web') {
      const eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
      const eventer = window[eventMethod];
      const messageEvent = eventMethod == 'attachEvent' ? 'onmessage' : 'message';
      eventer(
        messageEvent,
        function (e) {
          const key = e.message ? 'message' : 'data';
          const eventData = e[key];
          if (eventData.type === WEB_VIEW_EVENTS.OPEN_HOLIDAY_BOOKING_DETAIL) {
            window.location.href = eventData.url;
          }
        },
        false,
      );
    }
    openCustomerFormInWebView(action, this.bookingId, 'CONNECT_HELPDESK');
  };

  toggleChecklistOverlay = () => {
    HolidayBookingTrackingHelper.trackClickEvent(this.trackPageName, 'View_Flights_Checklist');
    this.showPopUp(buttonTypes.WEBCHECKIN);
  };

  toggleSelectFlightOverlay = () => {
    this.showPopUp(buttonTypes.SELECT_FLIGHT);
  };

  checklistActionHandler = (action) => {
    switch (action.type) {
      case 'READ_GUIDELINES':
        HolidayBookingTrackingHelper.trackClickEvent(
          this.trackPageName,
          'Checklist_Read_Guidelines',
        );
        externalLinkHandler(action, 'Guidelines');
        break;
      case 'AAROGYA_SETU':
        HolidayBookingTrackingHelper.trackClickEvent(
          this.trackPageName,
          'Checklist_Download_AarogyaSetu',
        );
        const iosURL = action.iosURL || IOS_AAROGYA_SETU_LINK;
        externalLinkHandler(action, 'Aarogya Setu', iosURL);
        break;
      case 'WEB_CHECKIN':
        HolidayBookingTrackingHelper.trackClickEvent(this.trackPageName, 'Checklist_Web_Checkin');
        if (this.state.flightDetailResponse) {
          Actions.openAncillary({
            pageKey: 'webcheckin',
            holidayBooking: true,
            bookingId: this.bookingId,
            holidayData: {
              BOOKING_ID: this.bookingId,
              MMT_AUTH: this.mmtAuth,
              LOGGING_TRACKINFO: this.loggingTrackingInfo,
              PARENT_PAGE: this.parentPage,
            },
            flightDetailResponse: this.state.flightDetailResponse,
          });
        }
        break;
      case 'BAGGAGE_TAG':
        HolidayBookingTrackingHelper.trackClickEvent(this.trackPageName, 'Checklist_Baggage_Tag');
        if (Platform.OS === 'ios') {
          this.state.responseJson.reactTag = getRootTag();
        } else {
          downloadTicket('Flight_BaggageTag', this.bookingId);
        }
        break;
      default:
        externalLinkHandler(action, '');
        break;
    }
  };

  onBackPopUp = (pageName = 'TripDetails') => {
    if (this.state.popUp === buttonTypes.PAYMENTDETAILS) {
      HolidayBookingTrackingHelper.trackClickEvent(pageName, 'Payment_Details_Back');
    } else if (
      this.state.popUp === buttonTypes.ITINERARY ||
      this.state.popUp === buttonTypes.ITINERARY_CARD
    ) {
      HolidayBookingTrackingHelper.trackClickEvent(pageName, 'PackageDetails_Back');
    } else if (this.state.popUp === buttonTypes.DOWNLOADVOUCHER) {
      this.setState({
        downloadVoucherStatus: getInitialVoucherState(
          !isEmpty(this.state.downloadVoucherPage) &&
            this.state.downloadVoucherPage.downloadVoucherSegmentList,
        ),
      });
    } else {
      HolidayBookingTrackingHelper.trackClickEvent(pageName, 'Back');
    }

    if (this.state.popUp !== '') {
      this.setState({
        popUp: '',
      });
    }
  };

  goBack = () => {
    backPressHandler(Actions);
  };

  onHardBackPress = () => {
    if (this.state.popUp !== '') {
      this.setState({
        popUp: '',
      });
      return true;
    }
    if (this.state.overlay) {
      this.toggleOverlay();
      return true;
    }
    return false;
  };
}

const styles = StyleSheet.create({
  cardWrapper: {
    width: Dimensions.get('screen').width,
    backgroundColor: '#f2f2f2',
  },
  stickyWrapper: {
    elevation: 0,
    zIndex: 1,
    position: 'absolute',
    width: '100%',
  },
});
