import React, { Component } from 'react';
import { View, Text} from 'react-native';
import AtomicCss from '../../../../bus/commonStyles/AtomicCss';
import CheckBox from '../CheckBox';

class IssueList extends Component {
  constructor(props) {
    super(props);
    this.pageName = 'IssueList';
  }

  getIssueList() {
    let carSupportData = this.props.carSupportData;
    let msrTicketList = this.props.msrTicketList;

    let issueList = carSupportData && carSupportData.issueList;

    issueList = issueList && issueList.map(issue => {
      if (issue && issue.subIssueList && msrTicketList) {
        let totalSubIssueLength = issue.subIssueList.length;
        let msrSubIssueLength = msrTicketList.filter((ticket) => issue.name === ticket.secondaryType).length;

        let subIssueList = issue.subIssueList;

        subIssueList &&
          subIssueList.map((subIssue, index) => {
            return subIssue;
          });

        return issue;
      }
    }
    );

    return (issueList &&
      <React.Fragment>
        {issueList.map(issue => {
            let leng = issue.subIssueList.length;

            let count = 0;
            let subIssueList = issue.subIssueList;

            return (
              <View style={[styles.sectionCard]}>
                <Text style={[AtomicCss.defaultText, AtomicCss.font16, AtomicCss.boldFont]}>
                  {issue.name}
                </Text>
                <View>
                  {subIssueList &&
                    subIssueList.map((subIssue, index) => {

                        let issueObject = {
                          id: `${issue.id}_${subIssue.id}`,
                          issue: { id: issue.id, name: issue.name },
                          subIssue: {
                            id: subIssue.id,
                            name: subIssue.name,
                            issueName: issue.name,
                            options: subIssue.options
                          }
                        };

                        let subIssueStyle = [];
                        subIssueStyle.push(styles.checkBoxWrapper);

                        count++;

                        if (index == leng - 1) { subIssueStyle.push(styles.lastItemStyle); }

                        return (
                          <View style={subIssueStyle}>
                            <CheckBox title={subIssue.name}
                              pageName={this.pageName}
                              listObj={this.props.issueListObj}
                              currentObj={issueObject}
                              updateList={this.props.updateIssueList}
                            />
                          </View>
                        );
                    }
                    )}
                </View>
              </View>
            )
        }
        )
        }
      </React.Fragment>
    );
  }

  render() {
    return (
      <View>
        <View style={[AtomicCss.whiteCardSection, AtomicCss.marginBottom16]}>
          <View style={[styles.sectionCard, { borderBottomWidth: 0 }]}>
            <Text style={[AtomicCss.defaultText, AtomicCss.font22, AtomicCss.lightFont]}>
              {this.props.carSupportData.selectIssueTitle}
            </Text>
          </View>
          {this.getIssueList()}
        </View>
      </View>
    );
  }
}

const styles = {
  sectionCard: { padding: 16, paddingBottom: 10, borderBottomWidth: 1, borderColor: '#f2f2f2' },
  checkBoxWrapper: { borderBottomWidth: 1, borderBottomColor: '#f2f2f2' },
  lastItemStyle: { borderBottomWidth: 0, borderBottomColor: 'transparent' },
  noBorderBottom: { borderBottomWidth: 0 }
};

export default IssueList;
