import { StyleSheet } from 'react-native';
import { Fonts, Theme } from '../../../types';

const getStyles = (fonts: Fonts, theme: Theme) => {
  const { color } = theme;
  return StyleSheet.create({
    dueAmountText: {
      fontSize: fonts.fontSize.font12,
      color: color.white,
      ...fonts.boldFontFamily,
    },
    payNowWrapper: {
      flexDirection: 'row',
      padding: 10,
      justifyContent: 'space-between',
      height: 60,
      backgroundColor: color.defaultTextColor,
    },
    payNowInfo: {
      color: color.lightWhite2,
      fontSize: fonts.fontSize.font12,
      ...fonts.semiBoldFontFamily,
    },
    buttonText: {
      color: color.white,
      fontSize: fonts.fontSize.font16,
      lineHeight: 18,
      ...fonts.boldFontFamily,
      minWidth: 125,
      textAlign: 'center',
    },
    buttonBlue: {
      alignItems: 'center',
      justifyContent: 'center',
      height: 42,
    },
    paymentModalIconContainer: {
      paddingHorizontal: 4,
      marginTop: 3,
    },
    container: {
      flexDirection: 'row',
      backgroundColor: color.lightGreen20,
      marginVertical: 0,
      paddingVertical: 7,
      paddingHorizontal: 10,
      height: 30,
    },
    cancelIcon: {
      width: 18,
      height: 18,
    },
    infoIcon: {
      marginHorizontal: 4,
      width: 24,
      height: 24,
      resizeMode: 'contain',
      tintColor: color.lightWhite2,
    },
    dropDownImg: {
      width: 12,
      height: 12,
      resizeMode: 'contain',
      tintColor: color.white
    }
  });
};

export default getStyles;

export const getHtmlStyle = (fonts: Fonts, theme: Theme) => {
  const { color } = theme;
  return StyleSheet.create({
    p: {
      color: color.green,
      fontSize: fonts.fontSize.font12,
      ...fonts.regularFontFamily,
    },
    b: {
      ...fonts.boldFontFamily,
    },
  });
};
