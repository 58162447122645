import React from 'react';
import Actions from '../../../navigation/postSalesNavigation';
import { View, Text, Image, TouchableOpacity, Platform, Linking } from 'react-native';
import styles from './boardingDropInfoCss';
import Notification from './notification';
import { errorIcon, locationIcon, busIcon } from '../../BusImageConstants';
import GhostButton from '../Components/ghostButton/ghostButton';
import { isEmpty } from 'lodash';
import MapView, { Marker, Polyline } from 'react-native-maps';
import {
  getCurrentLocation,
  getDestLatLon,
  getInitialRegion,
  getOperatorNumber,
  getOriginLatLon,
  getPolyLineData,
  getRotationAngle,
  handleClickEvent,
} from '../../utils/BusBookingDetailUtil';
import { INDIAN_PHONE_NUMBERS } from '../../../PostSalesConstant';
import ViewState from '../../../flights-funnel/Listing/utils/ViewState';
import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import fecha from 'fecha';
import { label } from '@mmt/post-sales/src/staticData/staticData';
import { getFont } from '../../../PostSalesStyles';

class BusTrackDetail extends React.Component {
  constructor(props) {
    super(props);
    this.busBookingInfo = props.response.busBookingInfo;
    this.busAdditionalInfo = props.response.busAdditionalInfo;
    this.state = {
      phoneNumberList: [],
      contactInfo: this.busBookingInfo.busDetail.contactNumbers || '',
      operatorNumber: [],
      polyLineData: [],
      originLatLong: {},
      initialRegion: {},
      destLatLong: {},
      lastUpdated: '',
      currentLocation: {},
      rotationAngle: 0,
      showError: true,
      viewState: ViewState.LOADING,
    };
    this.fonts = getFont(true);
  }
  componentWillReceiveProps(nextProps) {
    if (!isEmpty(nextProps.busLiveData) && this.props.busLiveData !== nextProps.busLiveData) {
      const { busLiveData } = nextProps;
      const originLatLong = getOriginLatLon(busLiveData);
      const destLatLong = getDestLatLon(busLiveData);
      const polyLineData = getPolyLineData(busLiveData);
      const lastUpdated = busLiveData.updatedOn;
      const currentLocation = getCurrentLocation(busLiveData);
      const focusArea = getInitialRegion(busLiveData);
      const rotationAngle = getRotationAngle(busLiveData);
      const operatorNumber = getOperatorNumber(busLiveData);
      this.setState({
        originLatLong,
        destLatLong,
        polyLineData,
        lastUpdated,
        currentLocation,
        rotationAngle,
        focusArea,
        showError: false,
        viewState: nextProps.viewState,
        operatorNumber,
      });
    }
  }
  componentDidMount() {
    const phoneNumberList = this.contactList();
    this.setState({
      phoneNumberList,
    });
  }

  contactList = () => {
    let phoneNumberList;
    const intRegex = INDIAN_PHONE_NUMBERS;
    const phoneNumbers = this.state.contactInfo;
    phoneNumberList = phoneNumbers.match(intRegex);
    if (!phoneNumberList) {
      phoneNumberList = [];
      phoneNumberList.push(phoneNumbers);
    }
    return phoneNumberList;
  };

  renderLocationDetails = (item, url, date, time, isLast) => {
    let activeSuffix = ' ';
    if (!isLast || this.props.response.bookingState.state.toLowerCase() === 'completed') {
      activeSuffix = 'Active';
    }
    const { fonts } = this;
    return (
      <View style={styles.locationItem}>
        <View
          style={[styles.locatioListStyleType, styles[`locatioListStyleType${activeSuffix}`]]}
        />
        <Text style={styles.timeTrackBorder}>{time}</Text>
        <Text style={styles.dayTrackBorder}>{date}</Text>

        {isLast ? null : <View style={styles.locationTrackBorder} />}
        <View style={styles.addressDetailsWrapper}>
          <View style={styles.addressDetails}>
            {!isLast && (
              <Text style={[styles.locationItemTitle, fonts.regularFontFamily]}>
                {label('bus.trackDetail.boardingPointAddress')}
              </Text>
            )}
            {isLast && (
              <Text style={[styles.locationItemTitle, fonts.regularFontFamily]}>
                {label('bus.trackDetail.droppingPointAddress')}
              </Text>
            )}
            {item.address && (
              <Text style={[styles.locationItemDesc, fonts.regularFontFamily, fonts.font14]}>
                {item.address}
              </Text>
            )}
            {!isEmpty(this.props.busLiveData) &&
              !isEmpty(this.props.busLiveData.vehicleInfo) &&
              !isLast && (
                <Text style={[styles.vehicleInfo, fonts.font12]}>
                  {label('bus.trackDetail.vehicleNo')}
                  <Text
                    style={styles.vehicleNumber}
                  >{` ${this.props.busLiveData.vehicleInfo.registrationNumber}`}</Text>
                </Text>
              )}
            {!item.address && item.dropLocation && (
              <Text style={styles.locationItemDesc}>{item.dropLocation}</Text>
            )}
          </View>
          {!isEmpty(url) && (
            <TouchableOpacity onPress={() => Linking.openURL(url)}>
              <Image source={locationIcon} style={styles.locationIcon} />
            </TouchableOpacity>
          )}
        </View>
        {item.extraDetails ? (
          <View style={styles.extraDetailsBadge}>
            <Text style={styles.extraDetailsDesc}>
              {item.extraDetails.desc}
              <Text style={styles.extraDetailsValue}> {item.extraDetails.value}</Text>
            </Text>
          </View>
        ) : null}
      </View>
    );
  };

  onTrackBus = () => {
    Actions.trackBus();
  };

  checkBoardingData(coachAdditonalInfo) {
    return (
      !isEmpty(coachAdditonalInfo) &&
      !isEmpty(coachAdditonalInfo[0].boardinglat) &&
      !isEmpty(coachAdditonalInfo[0].boardinglong)
    );
  }

  checkDropData(coachAdditonalInfo) {
    return (
      !isEmpty(coachAdditonalInfo) &&
      !isEmpty(coachAdditonalInfo[0].droplat) &&
      !isEmpty(coachAdditonalInfo[0].droplong)
    );
  }

  getDepDateandTime = (busResponse) => {
    const { busAdditionalInfo } = busResponse || {};
    const { departureDetail } = busAdditionalInfo || {};
    const { departureTimeInTwentyFourHoursFormat, departureMonth, departureDayInMonth } =
      departureDetail || {};
    const depTime =
      !isEmpty(departureTimeInTwentyFourHoursFormat) && departureTimeInTwentyFourHoursFormat;
    const depDate =
      !isEmpty(departureDayInMonth) &&
      !isEmpty(departureMonth) &&
      `${departureDayInMonth} ${departureMonth}`;
    return { depTime, depDate };
  };

  getArrDateandTime = (busResponse) => {
    const { busAdditionalInfo } = busResponse || {};
    const { arrivalDetail } = busAdditionalInfo || {};
    const { arrivalTimeInTwentyFourHoursFormat, arrivalDayInMonth, arrivalMonth } =
      arrivalDetail || {};
    const arrTime =
      !isEmpty(arrivalTimeInTwentyFourHoursFormat) && arrivalTimeInTwentyFourHoursFormat;
    const arrDate =
      !isEmpty(arrivalDayInMonth) &&
      !isEmpty(arrivalMonth) &&
      `${arrivalDayInMonth} ${arrivalMonth}`;
    return { arrTime, arrDate };
  };

  getBoardingPointUrl = (coachInfo, isBoarding) => {
    let lat;
    let lng;
    if (this.checkBoardingData(coachInfo) && isBoarding) {
      lat = coachInfo[0].boardinglat;
      lng = coachInfo[0].boardinglong;
    } else if (this.checkDropData(coachInfo)) {
      lat = coachInfo[0].droplat;
      lng = coachInfo[0].droplong;
    }
    const url = this.getUrl(lat, lng);
    return url;
  };

  getUrl = (lat, lng) => {
    if (lat && lng) {
      const scheme = Platform.select({ ios: 'maps:0,0?q=', android: 'geo:0,0?q=', web: 'https://www.google.com/maps/search/?api=1&query='
        });
      const latLng = `${lat},${lng}`;
      const label = 'Custom Label';
      const url = Platform.select({
        ios: `${scheme}${label.replace(/ /g, '%20')}@${latLng}`,
        android: `${scheme}${latLng}(${label})`,
        web: `${scheme}${latLng}`,
      });
      return url;
    }
    return null;
  };

  render() {
    const arrival = this.busAdditionalInfo.arrivalDetail;
    const departure = this.busAdditionalInfo.departureDetail;
    const coachAdditonalInfo = this.busBookingInfo.coachadditionaldetails;
    const liveTrackingEnabled = this.props.response.busLiveTrackingInfo.liveTrackingEnabled;
    const boardingPointUrl = this.getBoardingPointUrl(coachAdditonalInfo, true);
    const dropPointUrl = this.getBoardingPointUrl(coachAdditonalInfo, false);
    const { depDate, depTime } = this.getDepDateandTime(this.props.response);
    const { arrDate, arrTime } = this.getArrDateandTime(this.props.response);
    const { lastUpdated } = this.state;
    const { fonts } = this;
    return (
      <View style={styles.pageWrapper}>
        <View style={styles.header}>
          <Text style={[styles.headerTitle, fonts.blackFontFamily, fonts.font14]}>
            {label('bus.trackDetail.boardingDropInformation')}{' '}
          </Text>
          {!isEmpty(this.state.lastUpdated) && (
            <Text style={[styles.headerDesc, fonts.font12, fonts.regularFontFamily]}>
              {label('bus.trackDetail.lastupdated', null, { lastUpdated })}
            </Text>
          )}
        </View>
        {!isEmpty(this.state.originLatLong) &&
          (this.props.response.bookingState.state.toLowerCase() === 'upcoming' ||
            this.props.response.bookingState.state.toLowerCase() === 'ontrip') && (
            <MapView
              showsUserLocation
              ref={(ref) => (this.mapView = ref)}
              initialRegion={this.state.focusArea}
              region={this.state.focusArea}
              style={styles.mapContainer}
              onMapReady={() => {
                this.mapView.fitToSuppliedMarkers(['MK1', 'MK2'], {
                  edgePadding: {
                    top: 50,
                    right: 50,
                    bottom: 50,
                    left: 50,
                  },
                });
              }}
            >
              <Marker
                identifier={'MK1'}
                coordinate={this.state.originLatLong}
                title={label('bus.trackDetail.pickUpPoint')}
                description={label('bus.trackDetail.pickUp')}
              />
              <Marker
                identifier={'MK2'}
                coordinate={this.state.destLatLong}
                title={label('bus.trackDetail.dropPoint')}
              />
              <Marker
                image={busIcon}
                rotation={this.state.rotationAngle}
                coordinate={this.state.currentLocation}
              />
              <Polyline
                coordinates={this.state.polyLineData}
                strokeColor={colors.black}
                strokeWidth={1.5}
              />
            </MapView>
          )}
        {!liveTrackingEnabled &&
          this.state.viewState !== ViewState.LOADING &&
          isEmpty(this.state.originLatLong) && <Notification />}
        <View>
          {this.state.showError && this.state.viewState === ViewState.ERROR && (
            <View style={styles.mapContainer}>
              <Image source={errorIcon} style={styles.errorIcon} />
              <Text style={[styles.errorText, fonts.regularFontFamily, fonts.font14]}>{label('bus.trackDetail.mapFetchError')}</Text>
            </View>
          )}
          <View style={[styles.pageContent, { marginBottom: 10 }]}>
            {liveTrackingEnabled && !this.state.showError && (
              <GhostButton
                title={label('bus.trackDetail.trackYourBus')}
                onPresshandler={this.onTrackBus}
              />
            )}
            <View style={styles.locationList}>
              {this.renderLocationDetails(departure, boardingPointUrl, depDate, depTime)}
              {this.renderLocationDetails(arrival, dropPointUrl, arrDate, arrTime, true)}
            </View>
            {!isEmpty(this.state.phoneNumberList) &&
              this.addOperatorPhone(
                this.state.phoneNumberList,
                this.state.operatorNumber,
                liveTrackingEnabled,
              )}
          </View>
        </View>
      </View>
    );
  }

  addOperatorPhone = (phoneNumberList, operatorNumber, livetrackingEnabled) => {
    const { fonts } = this;

    if (!livetrackingEnabled && !isEmpty(phoneNumberList)) {
      return;
    } else if (!isEmpty(phoneNumberList) && !isEmpty(operatorNumber)) {
      return (
        <View>
          <TouchableOpacity
            onPress={() =>
              handleClickEvent('CallOperator', phoneNumberList[0], this.props.response)
            }
          >
            <View style={styles.footer}>
              {!isEmpty(phoneNumberList[0]) && (
                <Text style={[styles.footerBtn, fonts.font14, fonts.boldFontFamily]}>{label('bus.trackDetail.callBusOperator')}</Text>
              )}
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() =>
              handleClickEvent(
                'CallOperator',
                JSON.stringify(operatorNumber[0]),
                this.props.response,
              )
            }
          >
            <View style={[styles.footerBtn, fonts.font14, fonts.boldFontFamily]}>
              {operatorNumber.length > 0 && (
                <Text style={styles.footerBtn}>
                  {label('bus.trackDetail.callBoardingPointManager')}
                </Text>
              )}
            </View>
          </TouchableOpacity>
        </View>
      );
    } else {
      return <View />;
    }
  };
}

export default BusTrackDetail;
