import React, { useState, useMemo } from 'react';
import { View, ScrollView, Image, Text, TouchableOpacity, Linking } from 'react-native';
import styles from './InsuranceCardCss';
import { isNullOrEmpty } from '../../utils/HotelBookingDetailUtil';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { PRICE_ICON, ACCORDIAN_ICON } from '../../../HotelImageConstants';
import { getStaticData } from '../../../../staticData/staticData';
import { INSURANCE_TYPE } from '../../../HotelConstants';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const staticData = getStaticData();

const InsuranceCard = ({ response, card, pageName }) => {
    const { fsStyle, ...fonts } = getFont(true);
    const insurancedetails = response.insurancedetails;

    if (isNullOrEmpty(insurancedetails)) {
        return null;
    }


    const insList = useMemo(() => {
        return insurancedetails.map((insurance, index) => {
            return {
                heading: insurance.insuranceName,
                policyNo: insurance.policyNoText,
                extraPolicy: insurance.noOfPerson - 1,
                detail: insurance.planBenefit,
                policyDocumentUrl: insurance.policyDocumentUrl,
                active: index == 0,
                productType: insurance.productType,
            }
        });
    }, [insurancedetails]);

    const getInsuranceView = (item) => {
        return (
            <>
                <View style={styles.policyNum}>
                    <View style={[AtomicCss.flexRow]}>
                        <View style={styles.bullet}></View>
                        <Text style={[fonts.boldFontFamily, fsStyle.font12, AtomicCss.lineHeight20, AtomicCss.flex1, AtomicCss.defaultText]}>{staticData.insuranceCardText.policynoText}</Text>
                    </View>
                    <Text style={[fonts.boldFontFamily, AtomicCss.marginLeft16, fsStyle.font13, AtomicCss.lineHeight20, AtomicCss.flex1, AtomicCss.blackText]}>{item.policyNo} </Text>
                </View>
                <View style={styles.policyDetail}>
                    <Text style={[fonts.regularFontFamily, fsStyle.font13, AtomicCss.lineHeight20, AtomicCss.defaultText, AtomicCss.marginBottom12]}>{item.detail} </Text>
                   {
                    item.productType != INSURANCE_TYPE.ASSURED &&
                    <TouchableOpacity onPress={() => Linking.openURL(item.policyDocumentUrl)}>
                            <Text style={[fonts.boldFontFamily, fsStyle.font14, AtomicCss.lineHeight20, AtomicCss.azure]}>{staticData.insuranceCardText.viewBenefitText}</Text>
                        </TouchableOpacity>
                    }
                </View>
            </>
        );
    }

    const InsuranceList = ({ item }) => {
        const [toggle, setToggle] = useState(item.active);
        const handleToggle = () => {
            setToggle(!toggle)
        }
        return (
            <View style={[styles.docList]}>
                <TouchableOpacity onPress={handleToggle} style={styles.insHeading} activeOpacity={0.7}>
                    <Text style={[fsStyle.font16, AtomicCss.blackText, AtomicCss.flex1, fonts.boldFontFamily, AtomicCss.lineHeight20]}>{item.heading}</Text>
                    <Image source={ACCORDIAN_ICON} style={styles.accordianIcon} />
                </TouchableOpacity>
                {toggle &&
                    getInsuranceView(item)
                }
            </View>
        )
    };

    const InsuranceDetail = ({ name, icon, insList }) => {
        return (
            <View style={[AtomicCss.whiteCardNew, AtomicCss.marginHz12, AtomicCss.borderRadius16]}>
                <View style={[styles.headerSec, AtomicCss.flexRow, AtomicCss.alignCenter]}>
                    <Image source={icon} style={styles.headingIcon} />
                    <Text style={[fonts.blackFontFamily, fsStyle.font16, AtomicCss.blackText]}>{name}</Text>
                </View>
                {insList.map((list, index) => {
                    return (
                        <InsuranceList item={list} />
                    )
                })}
            </View>
        )
    };
    return (
        <View style={[styles.contentWrapper, AtomicCss.flex1]}>
            <ScrollView>
                {insList.length == 1 && insList[0] &&
                    <View style={[AtomicCss.whiteCardNew, AtomicCss.marginHz12, AtomicCss.borderRadius16]}>
                        <View style={[styles.headerSec, AtomicCss.flexRow, AtomicCss.alignCenter]}>
                            <Text style={[fonts.blackFontFamily, fsStyle.font16, AtomicCss.blackText]}>{insList[0].productType == INSURANCE_TYPE.ASSURED ? staticData.insuranceCardText.assuranceHeading : staticData.insuranceCardText.heading}</Text>
                        </View>
                        <View style={[styles.docList]}>
                            <View>
                                <View style={styles.insHeading}>
                                    <Text style={[fsStyle.font16, AtomicCss.blackText, AtomicCss.flex1, fonts.boldFontFamily, AtomicCss.lineHeight20]}>{insList[0].heading}</Text>
                                </View>
                                {getInsuranceView(insList[0])}
                            </View>
                        </View>
                    </View>
                }
                {insList.length > 1 && <View style={AtomicCss.cardWrapper}>
                    <InsuranceDetail
                        name={staticData.insuranceCardText.heading}
                        icon={PRICE_ICON}
                        insList={insList}
                    />
                </View>}

            </ScrollView>
        </View>
    )

};



export default InsuranceCard