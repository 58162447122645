import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#e7e7e7',
    height: '100%',
  },
  paddingAll8: {
    padding: 8,
  },
  innerWrapper: {
    paddingHorizontal: 8,
    paddingVertical: 16,
    flexDirection: 'row',
    borderRadius: 4,
  },
  innerWrapperPending: {
    backgroundColor: '#EAF5FF',
  },
  innerWrapperReview: {
    backgroundColor: '#ffedd1',
  },
  innerWrapperApproved: {
    backgroundColor: '#d1f8e6',
  },
  iconWrapperRejected: {
    backgroundColor: '#ffd3d4',
  },
  iconWrapper: {
    width: 40,
    height: 44,
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 12,
  },
  iconStyles: {
    width: 24,
    height: 24,
  },
  blueArrow: {
    width: 16,
    height: 16,
    marginRight: 8,
  },
  marginBottom4: {
    marginBottom: 4,
  },
  arrowWrapper: {
    width: 16,
    height: 16,
    marginRight: 8,
  },
  bottomLink: {
    paddingBottom: 16,
    paddingLeft: 60,
    paddingRight: 8,
  },
});

export default styles;
