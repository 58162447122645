import _isEmpty from 'lodash/isEmpty';
import _has from 'lodash/has';

import { isUserLoggedIn } from '@mmt/legacy-commons/Native/UserSession/UserSessionModule';
import { getMmtAuth } from '../Common/commonUtil';
import { isNetworkAvailable } from '@mmt/legacy-commons/Common/utils/AppUtils';
import { showShortToast } from '@mmt/legacy-commons/Common/Components/Toast';
import NetworkModule from '@mmt/legacy-commons/Native/NetworkModule';
import { prepareStaticDetailRequest } from './requestBuilder';
import { NO_INTERNET_CONNECTION, USER_PLATFORM } from './Constants/SimilarPropConstants'
import { getClient, getWebVisitorId, getBaseUrl } from './utils';

export const hotelCompareAdaptor = (response) => {
  const comparatorData = response.hotelCompareResponse;
  if (_has(comparatorData, 'hotelSearchResponse.hotelList') && !_isEmpty(comparatorData.hotelSearchResponse.hotelList)) {
    const convertedResp = [];
    const hotelCount = comparatorData.hotelSearchResponse.hotelList.length;
    for (let i = 0; i < hotelCount; i += 1) {
      const tempResp = {};
      const hotelId = comparatorData.hotelSearchResponse.hotelList[i].id;
      const flyfishReviewSummaryResponse = comparatorData.hotelSearchResponse.hotelList[i].flyfishReviewSummary;
      tempResp.id = hotelId;
      tempResp.cityName = comparatorData.hotelSearchResponse.hotelList[i].cityName;
      tempResp.hotelDisplayMap = comparatorData.hotelDisplayMap[hotelId];
      tempResp.ctaMap = comparatorData.ctaMap[hotelId];

      if (comparatorData.hotelSearchResponse.hotelList[i].displayFare) {
        tempResp.displayPriceBreakDown = comparatorData
          .hotelSearchResponse.hotelList[i].displayFare.displayPriceBreakDown;
        tempResp.isSoldOut = comparatorData
          .hotelSearchResponse.hotelList[i].isSoldOut;
        if (!comparatorData.hotelSearchResponse.hotelList[i].displayFare.displayPriceBreakDown
          || comparatorData.hotelSearchResponse.hotelList[i].displayFare.displayPriceBreakDown.displayPrice === 0) {
          tempResp.isSoldOut = true;
        }
      }
      if (flyfishReviewSummaryResponse) {
        tempResp.flyfishReviewSummaryResponse = flyfishReviewSummaryResponse;
      }
      if (!_isEmpty(comparatorData.hotelSearchResponse.hotelList[i].mainImages)) {
        tempResp.image = comparatorData
          .hotelSearchResponse.hotelList[i].mainImages[0];
      }
      if (comparatorData.hotelSearchResponse.hotelList[i].address) {
        tempResp.address = comparatorData.hotelSearchResponse.hotelList[i].address;
      }
      const locationPersuasion = comparatorData.hotelSearchResponse.hotelList[i].locationPersuasion;
      if (!_isEmpty(locationPersuasion)) {
        tempResp.locationPersuasion = locationPersuasion[0];
      }
      tempResp.starRating = comparatorData.hotelSearchResponse.hotelList[i].starRating;

      tempResp.name = comparatorData
        .hotelSearchResponse.hotelList[i].name;
      convertedResp.push(tempResp);
    }
    return { hotelList: convertedResp, deepLink: comparatorData.deeplink };
  }
  return null;
}

const addGenericHeader = (headers = {}) => {
  headers['content-type'] = 'application/json';
  headers.os = 'desktop';
  headers.vid = getWebVisitorId();
  headers.tid = getWebVisitorId();
  headers.deviceId = getWebVisitorId();
  headers.accept = 'application/json';
  headers['visitor-id'] = getWebVisitorId();

  return headers;
};

export const getStaticDetail = async (requestObj, uuids) => {
  const hasNetwork = await isNetworkAvailable();
  if (!hasNetwork) {
    showShortToast(NO_INTERNET_CONNECTION);
    return;
  }

  let headers = USER_PLATFORM.WEB ? addGenericHeader() : await NetworkModule.getHeaders();

  const userLoggedIn = await isUserLoggedIn();
  if (userLoggedIn) {
    const mmtAuth = await getMmtAuth();
    headers = {
      ...headers,
      'mmt-auth': mmtAuth
    };
  }
  const currency = requestObj.currency || 'INR';
  const region = requestObj.countryCode || 'in';
  const idContext = requestObj.idContext || 'B2C';
  const client = getClient();
  const baseUrl = getBaseUrl(requestObj.debug);
  const url = `${baseUrl}/${client}/2?language=eng&region=${region}&currency=${currency}&idContext=${idContext}`;
  const data = await prepareStaticDetailRequest(requestObj, uuids);

  return fetch(url, {
    method: 'POST',
    headers,
    body: JSON.stringify(data)
  }).then((response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  })
    .then(response => {
      return response.json()
    })
    .catch((ex) => console.log("ex...", ex));
};
