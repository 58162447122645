import React, {PureComponent} from 'react';
import {View, TouchableOpacity, StyleSheet} from 'react-native';
import IncExcHeader from './IncExcHeader';
import IncExcItem from './IncExcItem';

class IncExcCollapsed extends PureComponent {
  static navigationOptions = {
    header: null
  };

  render() {
    return (
      <View
        style={this.props.isActive ? [styles.CollapsedContainer1] : [styles.CollapsedContainer1]}>
        <TouchableOpacity
          onPress={() => this.props.tabbingFn(this.props.index, !this.props.isActive)}
          activeOpacity={0.9}
        >
          <IncExcHeader {...this.props} tabbing={this.props.isActive}/>
        </TouchableOpacity>

        {
          this.props.isActive && <IncExcItem {...this.props}/>
        }

      </View>
    );
  }
}

const styles = StyleSheet.create({
  CollapsedContainer1: {
    backgroundColor: '#fff',
    borderBottomWidth: 1,
    borderBottomColor: '#ededed'

  }
});

export default IncExcCollapsed;
