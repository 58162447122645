import React, { useState } from 'react';
import { View } from 'react-native';
import _ from 'lodash';
import FilterRow from './FilterRow';
import { getSorters } from '../../../../../utils';

const FilterSort = (props) => {
  const [currSort, setCurrSort] = useState(props.currSort);
  const [currSortOrder, setCurrSortOrder] = useState(props.currSortOrder);
  const _changeSorter = (sortType, sortOrder) => {
    setCurrSort(sortType);
    setCurrSortOrder(sortOrder);
    props.sorter(sortType, sortOrder);
  };
  return (
    <View>
      {_.map(getSorters(), (data, index) => (
        <FilterRow
          key={index}
          data={data}
          selected={currSort === data.type}
          order={currSortOrder}
          changeSorter={_changeSorter}
        />
      ))}
    </View>
  );
};

export default FilterSort;
