import React from 'react';
import {StyleSheet, TextInputProps, StyleProp, ViewStyle, TextStyle, ImageStyle } from 'react-native';
import FloatingInput from '@Frontend_Ui_Lib_App/FloatingInput';
import { getFont } from "apps/post-sales/src/PostSalesStyles"

interface FloatingInputWprProps{
  value?: string;
  labelAnimationTopValue?: number;
  onChangeText?: (text: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  labelAnimationLeftValue?: number;
  label?: string;
  startIconVerticalOffset?: number;
  isError?: boolean;
  errorMessage?: string;
  inputProps?: TextInputProps;
   customStyle?: {
        wrapperStyle?: StyleProp<ViewStyle>;
        inputFieldWrapperStyle?: StyleProp<ViewStyle>;
        labelStyle?: StyleProp<TextStyle>;
        inputFieldStyle?: Object;
        errorMessageStyle?: StyleProp<TextStyle>;
        startIconHolderStyle?: StyleProp<ViewStyle>;
        endIconHolderStyle?: StyleProp<ViewStyle>;
        startIconStyle?: StyleProp<ImageStyle>;
        endIconStyle?: StyleProp<ImageStyle>;
        actionTextHolderStyle?: StyleProp<ViewStyle>;
        actionTextStyle?: StyleProp<TextStyle>;
        endTextStyle?: StyleProp<TextStyle>;
        requiredTextStyle?: StyleProp<TextStyle>;
    };
}
  const FloatingInputWpr: React.FC<FloatingInputWprProps> = (
    {
      inputProps, 
      value, 
      labelAnimationTopValue,
       onChangeText, 
       customStyle ,
       labelAnimationLeftValue,
       label,
       startIconVerticalOffset,
       isError,
       errorMessage,
       onFocus,
       onBlur
      }) => {
    const { fsStyle, ...fonts } = getFont(true);
    const styles = getStyle(fonts);

  return (
    <FloatingInput
      customStyle={customStyle ? customStyle : styles.customStyle}
      inputProps={{
       ...inputProps,
      }}
      label={label}
      labelAnimationLeftValue={labelAnimationLeftValue ? labelAnimationLeftValue : 32}
      labelAnimationTopValue={labelAnimationTopValue ? labelAnimationTopValue : 12}
      onChangeText={onChangeText}
      startIconVerticalOffset={startIconVerticalOffset ? startIconVerticalOffset :7 }
      value={value}
      errorMessage={errorMessage}
      isError={isError}
      onBlur={onBlur}
      onFocus={onFocus}
    />
  );
};


const getStyle = (fonts) => {
    return StyleSheet.create({
  customStyle: {
    inputFieldStyle: {
        ...fonts.font12,
        fontFamily: fonts.black,
    },
    labelStyle: {
        ...fonts.font12,
        fontFamily: fonts.bold,
    },
  },
});
}

export default FloatingInputWpr;
