import { colors, fonts } from '@mmt/legacy-commons/Styles/globalStyles';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    container: {
      flex: 1,
      width: '100%',
      height: '100%',
      backgroundColor: '#ffffffd9',
      alignItems: 'center',
      justifyContent: 'center'
    },
  
    noErrorImage: {
      height: 180,
      marginVertical: 16
    },
    textDark: {
      color: colors.black,
      fontSize: 16,
      fontFamily: fonts.bold,
      textAlign: 'center'
    },
    descText: {
      color: colors.grey1,
      fontSize: 16,
      textAlign: 'center'
    },
    retryView: {
      marginTop: 16
    }
  });

export default styles;
  