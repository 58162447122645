import { StyleSheet } from 'react-native';
import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';
const styles = StyleSheet.create({
  toastWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20,
  },
  toastStyle: {
    borderRadius: 40,
    paddingTop: 7,
    paddingRight: 25,
    paddingBottom: 7,
    paddingLeft: 7,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    ...getPlatformElevation(2),
  },
  upperText: {
    textTransform: 'uppercase',
  },
});
export default styles;
