import { Platform, StyleSheet } from 'react-native';

export default (theme,fonts) => 
StyleSheet.create({
    btnTypeRound: {
      borderRadius: 4,
      overflow: 'hidden',
    },
    btnTypeRounded: {
      borderRadius: 40,
      overflow: 'hidden',
    },
    blueBtnText: {
      paddingVertical: 16,
      fontSize: fonts.fontSize.font16,
      ...fonts.boldFontFamily,
      color: theme.color.white,
      textAlign: 'center',
    },
    transperantBgrnd: {
      ...Platform.select({
        ios: {
          backgroundColor: theme.color.transparent,
        },
      }),
    },
    blueBtnGradient: {
      paddingHorizontal: 30,
    },
    ghostBtn: {
      borderRadius: 40,
      overflow: 'hidden',
      backgroundColor: theme.color.transparent,
      borderColor: theme.color.azure,
      borderWidth: 1,
    },
    ghostBtnText: {
      paddingVertical: 12,
      fontSize: fonts.fontSize.font12,
      ...fonts.boldFontFamily,
      color: theme.color.azure,
      textAlign: 'center',
    },
    WtRightArrow: {
      width: 16,
      height: 12,
      marginLeft: 10,
    },
    BtnCenter: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    btnTypeRoundedXS: {
      borderRadius: 40,
      overflow: 'hidden',
    },
    BtnTextXS: {
      paddingVertical: 12,
      fontSize: fonts.fontSize.font12,
      ...fonts.boldFontFamily,
      color: theme.color.white,
      textAlign: 'center',
    },
    BtnGradientXS: {
      paddingHorizontal: 15,
    },
    btnStyle: {
      paddingVertical: 12,
      overflow: 'hidden',
      alignItems: 'center',
    },
    bgWhite: {
      backgroundColor: theme.color.white,
    },
    corpTxtColor: {
      color: theme.color.yellow2,
      ...fonts.boldFontFamily,
      fontSize: fonts.fontSize.font16,
    },
}
);
