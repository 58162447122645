// this is a cleaned version of asyncStore. it has _reducerTransformer removed
import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import thunkMiddleware from 'redux-thunk';
import {createLogger} from 'redux-logger';

let _store;

/**
 * ! =============================== NOTE =============================== !
 * `collapsed: true` check has been added because the logs were difficult to read
 * as they were expanded and the amount of data being logged on console made it
 * tricky for eyes to keep track of action names being fired. whereas if we keep
 * collapsed, the console gives a crisp visibility of each action name. the
 * check has been added by `MMT9107`, please let me know if you believe
 * this check should be removed or have a different perspective.
 */

const loggerMiddleware = createLogger({
  collapsed: true,
  predicate: () => __DEV__,
});

let _reducerTransformer = reducer => reducer;
export const registerReducerTransformer = (reducerTransformer) => {
  _reducerTransformer = reducerTransformer;
};

export const injectAsyncReducer = (name, asyncReducer) => {
  if (_store.asyncReducers[name]) {
    return;
  }
  // eslint-disable-next-line no-param-reassign
  _store.asyncReducers[name] = asyncReducer;
  _store.replaceReducer(createReducer(_store.asyncReducers));
};

const createReducer = (asyncReducers) => combineReducers({
  ...asyncReducers
});

export const configureStore = (initialReducers, initialState, middlewares = []) => {
  const enhancer = compose(applyMiddleware(
    thunkMiddleware,
    loggerMiddleware,
  ), ...middlewares);
  _store = createStore(initialReducers, initialState, enhancer);
  _store.asyncReducers = {};
  return _store;
};

export const _getStore = () => _store;
