import { StyleSheet } from 'react-native';

export default (theme, fonts) => {
  const styles = StyleSheet.create({
    pageWrapper: {
      flex: 1,
      backgroundColor: theme.color.grayBg,
    },
    whiteBg: {
      backgroundColor: theme.color.white,
    },

    whiteCard: {
      backgroundColor: theme.color.white,
      elevation: 2,
      shadowColor: theme.color.black,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.2,
      shadowRadius: 2,
      marginVertical: 6,
      padding: 16,
    },
    infoWrapper: {
      flex: 1,
    },
    headingInfoIcon: {
      width: 32,
      height: 32,
      marginRight: 10,
    },
    cancelTopHeading: {
      ...fonts.blackFontFamily,
      color: theme.color.black,
      fontSize: fonts.fontSize.font16,
      marginBottom: 10,
      top: 4,
    },
    descriptionInfo: {
      fontSize: fonts.fontSize.font14,
      lineHeight: 20,
      ...fonts.regularFontFamily,
      color: theme.color.defaultTextColor,
    },
    paxDtlsWrapper: {
      borderColor: theme.color.lightGrey,
      borderRadius: 4,
      borderWidth: 1,
      marginLeft: 45,
      marginTop: 20,
    },
    rowSection: {
      padding: 12,
      borderBottomColor: theme.color.lightGrey,
      borderBottomWidth: 1,
    },
    DtlsHeading: {
      ...fonts.boldFontFamily,
      color: theme.color.black,
      fontSize: fonts.fontSize.font14,
      marginBottom: 10,
    },
    imgPlaceholder: {
      width: 16,
      height: 16,
      alignItem: 'center',
      marginRight: 10,
    },
    userIcon: {
      width: 16,
      height: 12,
    },
    callIcon: {
      width: 13,
      height: 14,
    },
    paxName: {
      fontSize: fonts.fontSize.font12,
      ...fonts.regularFontFamily,
      color: theme.color.black,
    },
    styleDisable: {
      opacity: 0.3,
    },
    btnWrapper: {
      padding: 10,
    },
    dividerWrapper: {
      alignItem: 'center',
      justifyContent: 'center',
      height: 50,
      position: 'relative',
      marginHorizontal: 16,
    },
    bullet: {
      width: 4,
      height: 4,
      borderRadius: 10,
      marginRight: 10,
      backgroundColor: theme.color.black,
    },
  });
  return styles;
};

export const createHtmlStyle = (theme, fonts)=> StyleSheet.create({
  q: {
    /* fix fo Html Fonts Not working without adding blank tag */
  },
  p: {
    fontSize: fonts.fontSize.font14,
    color: theme.color.defaultTextColor,
    ...fonts.regularFontFamily,
    lineHeight: 20,
  },
  b: {
    fontSize: fonts.fontSize.font14,
    color: theme.color.defaultTextColor,
    ...fonts.boldFontFamily,
    lineHeight: 20,
  },
});
