import { StyleSheet } from 'react-native';
import { colors, fonts } from '@mmt/legacy-commons/Styles/globalStyles';

const styles = StyleSheet.create({
  cityListItem: {
    paddingVertical: 10,
    paddingHorizontal: 18,
    flexDirection: 'column',
  },
  cityListItemPrimaryTextContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  cityListItemPrimaryText: {
    fontSize: 16,
    color: colors.black,
    fontFamily: fonts.light,
  },
  cityListItemMatchedPrimaryText: {
    fontSize: 16,
    color: colors.black,
    fontFamily: fonts.bold,
  },
  cityListItemSecondaryText: {
    fontSize: 12,
    color: colors.lightTextColor,
    fontFamily: fonts.regular,
  },
  recentSearchDataItemView: { marginVertical: 5, marginLeft: 35 },
  recentSearchItemTitle: {
    fontFamily: fonts.bold,
    fontSize: 10,
    lineHeight: 14,
    color: colors.black,
  },
  recentSearchItemSubtitle: {
    fontFamily: fonts.regular,
    fontSize: 10,
    lineHeight: 14,
    color: colors.greyish,
  },
  destinationInputView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 20,
    paddingBottom: 20,
    marginHorizontal: 0,
  },
  destinationInput: {
    fontFamily: fonts.black,
    color: colors.defaultTextColor,
    paddingVertical: 0,
    marginVertical: 0,
  },
  clearButtonView: { marginLeft: 'auto', marginRight: 16, marginTop: 3 },
  clearButton: {
    fontFamily: fonts.bold,
    fontSize: 10,
    letterSpacing: 0.12,
    color: colors.azure,
  },
  separator: {
    height: 1,
    width: '100%',
    backgroundColor: '#cbcbcb',
  },
  backArrow: {
    width: 15,
    height: 15,
  },
  mainView: { backgroundColor: colors.white, flex: 1 },
  headingContainerView: { marginLeft: 16, marginTop: 40, flexDirection: 'row' },
  mainTitleView: { marginLeft: 12, marginTop: -15 },
  titleText: {
    fontFamily: fonts.black,
    color: colors.defaultTextColor,
    fontSize: 16,
    paddingTop: 15,
  },
  subTitleText: {
    fontFamily: fonts.regular,
    color: colors.black,
    fontSize: 12,
    paddingTop: 5,
  },
  flexRow: {
    flexDirection: 'row',
  },
  dotImage: { height: 7, width: 7 },
  addressTitleText: {
    marginLeft: 15,
    color: colors.azure,
    fontFamily: fonts.bold,
    fontSize: 9,
  },
  verticalLine: {
    position: 'absolute',
    borderWidth: 0.5,
    borderRadius: 1,
    height: 55,
    marginLeft: 3,
    left: 20,
    marginTop: 6,
    borderStyle: 'dotted',
    borderColor: colors.lightTextColor,
  },
  inputLine: {
    borderBottomWidth: 1,
    marginTop: 5,
  },
  marginLeft22: { marginLeft: 22 },
  marginTop10: { marginTop: 10 },
  paddingLeft20: { paddingLeft: 20 },
  paddingTop2: { paddingTop: 2 },
  srcDestInputView: { flexDirection: 'row', marginTop: 5 },
  gpsView: { marginTop: 20, marginLeft: 20 },
  marginTop25: { marginTop: 25 },
  gpsAndRecentIcon: { height: 20, width: 20 },
  recentSearchTitle: {
    fontFamily: fonts.bold,
    fontSize: 9,
    lineHeight: 16,
    color: colors.lightTextColor,
    marginLeft: 15,
  },
  gpsTitle: { fontFamily: fonts.bold, fontSize: 9, lineHeight: 16, color: colors.lightTextColor },
  gpsSubTitle: { fontFamily: fonts.regular, fontSize: 10, lineHeight: 14, color: colors.azure },
  marginLeft12: { marginLeft: 12 },
  suggestionView:{
    paddingLeft:16,
    paddingRight:16, 
    height:'100%'
},
custTitleStyle:{
  fontFamily: fonts.regular,
  fontSize:14,
  fontWeight:'700',
  color:colors.black
},
custSubtitleStyle:{
  fontFamily: fonts.regular,
  fontSize:14,
  fontWeight:'400',
  color:colors.greyText1
},
leftIconStyle:{
  height:24,
  width:24,
},
custInputTitleStyle:{
  left:40,
  fontSize:12,
},
textInputStyles:{
  marginLeft: 8,
  fontWeight:'400',
  flex:1
},
leftIcContainerStyle:{
  position: 'relative', 
  top: 0, 
  left: 0, 
  alignSelf: 'center', 
  justifyContent:'center'
}
});

export default styles;
