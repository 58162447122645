import {
  compareStrings,
  getNameFieldListData,
  getFirstAndMiddleName,
  getPassengerFullName,
  validateFirstAndMiddleName,
  validateLastName,
  getPaxNameInfo,
  getPassengerKey,
  validateInput,
  getGenderList,
  initSelectedPax,
  isValidChange,
} from 'core-ui-lib/flight/common/NameChange/helper';
import { isNullOrEmptyObject } from '../../../Common/commonUtil';

export const isButton = (button) => button.type === 'button';

export const isHyperLink = (button) => button.type === 'hyperlink';

export const isAttachmentDone = (attachmentList, selectedPax) => {
  if (isNullOrEmptyObject(attachmentList)) {
    return false;
  }
  let flag = true;
  Object.keys(selectedPax).forEach((key) => {
    if (flag && isNullOrEmptyObject(attachmentList[key])) {
      flag = false;
    }
  });
  return flag;
};

export {
  getNameFieldListData,
  getFirstAndMiddleName,
  getPassengerFullName,
  validateFirstAndMiddleName,
  validateLastName,
  getPaxNameInfo,
  compareStrings,
  getPassengerKey,
  validateInput,
  initSelectedPax,
  getGenderList,
  isValidChange,
};
