import { isEmpty } from 'lodash';
import React from "react";
import { Image, Text, TouchableOpacity, View } from "react-native";
import { COTTAGE_ICON, LANG_ICON, CALL_HOST_ICON, CHAT_HOST_ICON, CARE_TAKER_ICON } from '../../../HotelImageConstants';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import createStyle from "./AssistanceCss";
import { getStaticData } from '../../../../staticData/staticData';
import HotelBookingDetailsConstant from "../../HotelBookingDetailsConstant";
import { useTheme } from '../../../../theme/theme.context';
import { getFont } from '../../../../PostSalesStyles';

const Assistance = ({ sections = {}, hostDetails = {}, caretakersDetails={}, hostCareTakerInfo, handleChatWithHost, handleCallToHost, handleCallToOthers, actionList, handleCTAAction }) => {
  const { imageURI, ctaList} = hostDetails;
  const { hosts: hostsSection} = sections;
  const {otherSections: careTackersSection} = caretakersDetails || []
  const staticData = getStaticData(true);
  const {CHAT_STAFF, CALL_STAFF} = HotelBookingDetailsConstant
  const { fsStyle, ...fonts } = getFont();
  const {psTheme: theme}=useTheme();
	const styles = createStyle(theme, fonts);
  const {hostingSince = '', hostAbout = '', location = '', language= ''} = hostCareTakerInfo
  return (
    <React.Fragment>
        <View style={[styles.hostedByDetails]}>
            <View style={[AtomicCss.flexRow, AtomicCss.marginBottom16, AtomicCss.alignStart]}>
                <View style={[AtomicCss.flex1]}>
                  {!isEmpty(hostsSection) && (
                    <Text style={[fonts.blackFontFamily, AtomicCss.blackText, fsStyle.font18, AtomicCss.marginBottom10]}>{hostsSection?.title} {hostsSection?.subTitle}</Text>
                  )}
                   {!isEmpty(hostingSince) && <Text style={[fonts.regularFontFamily,AtomicCss.defaultText,fsStyle.font14, AtomicCss.lineHeight15, AtomicCss.marginBottom10]}>{hostingSince}</Text>}
                   {!isEmpty(location) &&
                    <View style={[AtomicCss.flexRow,AtomicCss.alignCenter, AtomicCss.marginTop10, AtomicCss.marginBottom3]}>
                        <Image style={styles.cottageIcon} source={COTTAGE_ICON}/>
                        <Text style={[AtomicCss.blackText,fonts.regularFontFamily,fsStyle.font14, AtomicCss.lineHeight15]}>{location}</Text>
                    </View>
                   }
                  {!isEmpty(language) &&
                    <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.lineHeight15]}>
                      <Image style={styles.cottageIcon} source={LANG_ICON} />
                      <Text style={[AtomicCss.blackText, fonts.regularFontFamily, fsStyle.font14, AtomicCss.lineHeight15]}>{language}</Text>
                    </View>
                  } 
                </View>
                {!isEmpty(imageURI) && (
                 <View style={styles.hostImgWrapper}>
                    <Image style={styles.hostImg} source={{ uri: imageURI }}/>
                </View>
                )}
            </View>

        {!isEmpty(hostAbout) &&
          <View style={AtomicCss.marginBottom16}>
            <Text style={[fonts.regularFontFamily, fsStyle.font14, AtomicCss.lineHeight15, AtomicCss.blackText]} numberOfLines={4}>{hostAbout}</Text>
            {!isEmpty(actionList) && actionList.map((action, index) => {
              return (
                <Text key={index} style={[AtomicCss.azure, fsStyle.font14, fonts.boldFontFamily, AtomicCss.lineHeight20]} onPress={() => handleCTAAction(action)}>
                  {action.actionLabeltext}
                </Text>
              )
            })}
          </View>
        }
            
            {!isEmpty(ctaList) && (
              <View style={[AtomicCss.flexRow,AtomicCss.spaceBetween]}>
                {ctaList.map(cta => {
                  if (cta == CHAT_STAFF) {
                    return (<TouchableOpacity style={[AtomicCss.flexRow,AtomicCss.alignCenter]} onPress={handleChatWithHost}>
                       <Image style={styles.iconStyle} source={CHAT_HOST_ICON}/>
                       <Text style={[AtomicCss.azure,fsStyle.font12,fonts.blackFontFamily]}>{staticData.messageHost}</Text>
                    </TouchableOpacity>)
                  } else if (cta == CALL_STAFF) {
                    return (<TouchableOpacity style={[AtomicCss.flexRow,AtomicCss.alignCenter]} onPress={handleCallToHost}>
                      <Image style={styles.iconStyle} source={CALL_HOST_ICON}/>
                      <Text style={[AtomicCss.azure,fsStyle.font12,fonts.blackFontFamily]}>{staticData.callHost}</Text>
                    </TouchableOpacity>)
                  }
                })}
            </View>
          )}
        </View>

      {caretakersDetails && (
        <View style={styles.careTackerSection}>
          <View style={[AtomicCss.alignCenter,AtomicCss.flexRow]}>
              <Image style={styles.careTakerIcon} source={CARE_TAKER_ICON}/>
              <Text style={[AtomicCss.defaultText,fsStyle.font14,fonts.regularFontFamily, AtomicCss.lineHeight15]}>{staticData.caretakerName}: <Text style={[AtomicCss.blackText]}> {caretakersDetails.title}</Text></Text>
          </View>
          {!isEmpty(caretakersDetails) && !isEmpty(careTackersSection) && !isEmpty(careTackersSection[0].tags)  &&
          <Text style={[AtomicCss.blackText,fsStyle.font14,fonts.regularFontFamily, AtomicCss.marginTop10]}>
             <Text style={[fonts.boldFontFamily, AtomicCss.lineHeight15]}>{staticData.caretakerResponsibilitiesHead}: </Text> 
             <Text>{careTackersSection[0]?.tags.join(', ')}</Text>
          </Text>
           }
          {!isEmpty(caretakersDetails.ctaList) && ( 
          <TouchableOpacity onPress={handleCallToOthers} style={[AtomicCss.flexRow,AtomicCss.alignCenter, AtomicCss.marginTop16]}>
                <Image style={styles.iconStyle} source={CALL_HOST_ICON}/>
                <Text style={[AtomicCss.azure,fsStyle.font12,fonts.blackFontFamily]}>{staticData.callCaretaker}</Text>
            </TouchableOpacity>
          )}
        </View>
      )}  
    </React.Fragment>
  );
};
export default React.memo(Assistance);
