import React, { useState } from 'react';
import { Text, View } from 'react-native';
import createStyles from '../styles';
import FlightDeparture from './FlightDeparture';
import { getStaticData } from '../../../../../../../../../staticData/staticData';
import { getFont } from '../../../../../../../../../PostSalesStyles';
import { useTheme } from '../../../../../../../../../theme/theme.context';

const FilterDeparture = (props) => {
  const [currDepTmFltr, setCurrDepTmFltr] = useState(props.currDepFltr);
  const fonts = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);
  const { data } = props;
  const { itinFltrInfo } = props;
  const { departureTimeText } = getStaticData();
  const _departureTimeText = departureTimeText.replace('${fcn}', itinFltrInfo.fcn);
  const _renderDepView = (data, itinFltrInfo) => {
    const depVwLst = [];
    data.list.forEach((item, index) => {
      depVwLst.push(
        <FlightDeparture
          data={item}
          currency={props.currency}
          fltrMtDta={itinFltrInfo.itinFltr.depTmSlot[index]}
          selected={currDepTmFltr[index]}
          index={index}
          toggleDepFilter={_setDepFilter}
        />,
      );
    });
    return depVwLst;
  };

  const _setDepFilter = (active, index) => {
    const newCurrDepTmFltr = currDepTmFltr;
    newCurrDepTmFltr[index] = active;
    setCurrDepTmFltr(newCurrDepTmFltr);
    props.toggleDepFilter(newCurrDepTmFltr, props.index);
  };
  return (
    <View style={styles.filterItemRow}>
      <Text style={styles.FiltersHeading}>
        {_departureTimeText}
      </Text>
      <View style={styles.filterStopMain}>{_renderDepView(data, itinFltrInfo)}</View>
    </View>
  );
};
export default FilterDeparture;
