import React from 'react';
import {Image, Text, TouchableOpacity, View} from 'react-native';
import {oneWayJourney} from '../../../../../../bus/BusImageConstants'
import { getFont } from '../../../../../../PostSalesStyles';
import { useTheme } from '../../../../../../theme/theme.context';
import createStyles from "./CabOperatorCss";

const CabOperator = ({response}) => {

  const fonts = getFont()
  const {psTheme : theme} = useTheme();
  const styles = createStyles(theme,fonts);
  
  const addSegmentInfo = (response) => { 
    const {
      carBookingInfo:{carDetail:{tripType, fromCity, toCity, carBrand}}, 
      carAdditionalInfo:{departureDetail:{departureTimeInTwentyFourHoursFormat,departureDay,departureDayInMonth,departureMonth,departureYear }}
    } 
    = response;

    return (
    <View key={tripType}>
      <View style={[styles.destRow]}>
        <Text style={styles.cityText}>{fromCity}</Text>
        <Image style={styles.arrowIcon} source={oneWayJourney} />
        <Text style={styles.cityText}>{toCity}</Text>
      </View>
      <Text style={styles.smallText}>
        {departureTimeInTwentyFourHoursFormat},
        {departureDay}, 
        {departureDayInMonth}-
        {departureMonth}-
        {departureYear},     
        {carBrand}
      </Text>
    </View>
  );
  };

  return (
    <View style={styles.cardWrapper}>
      <View style={styles.checkAllWrapper}>
        <View style={[styles.checkAllWrapperInner]}>
          <TouchableOpacity
            style={[styles.checkBtn, styles.checkAllBtn, styles.PaxslctionHeader]}
            activeOpacity={0.9}
          >
            <View style={styles.SectorHeader}>
              <View style={{ flexDirection: 'row' }}>
                <View>{addSegmentInfo(response)}</View>
              </View>
            </View>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
}
export default CabOperator;

