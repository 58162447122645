import React from 'react';
import {PureComponent} from 'react';
import {View, TouchableOpacity, StyleSheet} from 'react-native';
import AccordionQuestion from './AccordionQuestion';
import AccordionDetails from './AccordionDetails';


class FaqsCollapsed extends PureComponent {

    render() {
        return (
            <View style={this.props.isActive ? [styles.CollapsedContainer1] : [styles.CollapsedContainer1]}>
                <TouchableOpacity onPress={() => this.props.tabbingFn(this.props.index, !this.props.isActive)}
                                  activeOpacity={0.9}
                >
                    <AccordionQuestion {...this.props} tabbing={this.props.isActive}/>
                </TouchableOpacity>
                {this.props.isActive &&
                <AccordionDetails response={this.props.response}/>
                }
            </View>
        );
    }
}

const styles = StyleSheet.create({
    CollapsedContainer1: {
        backgroundColor: '#fff',
        borderBottomWidth: 1,
        borderBottomColor: '#ededed'
    }
});

export default FaqsCollapsed;
