import React, { useState } from 'react';
import { View, Image, Text, TouchableOpacity } from 'react-native';
import styles from './FacilitiesCardCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { GREY_TICK_ICON } from '../../../HotelImageConstants';
import { getStaticData } from '../../../../staticData/staticData';
import { getFont, themeColors } from '../../../../PostSalesStyles';

const FACILITIES_LIMIT = 10;

const FacilitiesList = ({ data }) => {
  const { fsStyle, ...fonts } = getFont(true);
  const staticData = getStaticData();
  
  const [listshow, IsListShow] = useState(FACILITIES_LIMIT);

  const onViewMore = () => {
    if (listshow === FACILITIES_LIMIT) {
      IsListShow(data.length);
    } else {
      IsListShow(FACILITIES_LIMIT);
    }
  };

  const listItem = listshow === FACILITIES_LIMIT ? `+ ${data.length - FACILITIES_LIMIT} ` +  staticData.hotelAmenitiesCardText.additionalText.amenitiesText : staticData.viewLessText;

  return (
    <View style={styles.facilityList}>
      {data.map((item, index) => {
        if (index < listshow) {
          return (
            <View style={[styles.listWidth, AtomicCss.flexRow, AtomicCss.alignCenter, index%2 == 0 ? AtomicCss.marginRight18 : null]}>
              <Image style={styles.timerIcon} source={GREY_TICK_ICON} />
              <Text style={[fsStyle.font13, fonts.regularFontFamily, AtomicCss.darkGreyText, {flexShrink: 1}]}>
                {item}
              </Text>
            </View>
          );
        }
      })}
      {data.length > FACILITIES_LIMIT && <View style={AtomicCss.marginBottom20}>
        <TouchableOpacity onPress={onViewMore}>
          <Text style={[{color: themeColors.linkTextColor}, fonts.boldFontFamily, fsStyle.font14]}>
            {listItem}
          </Text>
        </TouchableOpacity>
      </View>}
    </View>
  );
};
export default FacilitiesList;
