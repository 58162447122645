import React from 'react';
import {View, Image, Text} from 'react-native';
import AtomicCss from '../../commonStyles/AtomicCss';
import getStyles from '../../styles/BusMamiCss';
import {getActionButton, getButtonStyle, handleBusClickEvent, isNotNullAndEmpty} from '../../utils/BusBookingDetailUtil';
import {refundIcon} from '../../BusImageConstants';
import { getFont } from '../../../PostSalesStyles';

class SupportCard extends React.Component
{
  handleClick = (action) => {
    handleBusClickEvent(this.props.card, action, this.props.response, this.props.pageName);
  };
  render(){
      const fonts = getFont(true);
      const styles = getStyles();
      return(
        <View style={[styles.MamiCard,styles.marginVertical20, styles.mar10Bt]}>
          <View style={styles.CardContent}>
            <View style={styles.flexRow}>
                <Image style={styles.CardIcon} source={refundIcon} />
                <View style={styles.description}>
                  <Text style={[fonts.blackFontFamily, styles.BlackText, AtomicCss.marginBottom15, fonts.font14]}>{this.props.response.busSupportData.scTitle}</Text>
                  <Text style={[AtomicCss.flex1, AtomicCss.defaultText, AtomicCss.lineHeight20, fonts.regularFontFamily, fonts.font14]}>
                    {this.props.response.busSupportData.scDescription}
                  </Text>
                </View>
            </View>
          </View>
          <View
            style={isNotNullAndEmpty(this.props.card.actionList) ? [getButtonStyle(this.props.card.actionList), styles.transperantBg] : styles.hideView}
          >
            {getActionButton(this.props.card, this.props.response, this.handleClick)}
          </View>
        </View>
      )
  }
}

export default SupportCard;
