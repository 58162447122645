import { PAGE_BOOKINGS_STATUS } from './summaryConstants';

const planningStateObject = {
  bookings: [],
};

const initialStateObject = {
  bookings: [],
  hasNextPage: true,
  currentPage: 0,
  currentPageResponse: null,
  statusWiseMessage: null,
};

export const summaryReducer = (state, action) => {
  switch (action.type) {
    case PAGE_BOOKINGS_STATUS.PLANNING:
      return {
        ...state,
        [PAGE_BOOKINGS_STATUS.PLANNING]: action.payload,
      };
    case PAGE_BOOKINGS_STATUS.UPCOMING:
      return {
        ...state,
        [PAGE_BOOKINGS_STATUS.UPCOMING]: action.payload,
      };
    case PAGE_BOOKINGS_STATUS.CANCELLED:
      return {
        ...state,
        [PAGE_BOOKINGS_STATUS.CANCELLED]: action.payload,
      };
    case PAGE_BOOKINGS_STATUS.FAILED:
    case PAGE_BOOKINGS_STATUS.UNSUCCESSFUL:
      return {
        ...state,
        [PAGE_BOOKINGS_STATUS.FAILED]: action.payload,
      };
    case PAGE_BOOKINGS_STATUS.COMPLETED:
      return {
        ...state,
        [PAGE_BOOKINGS_STATUS.COMPLETED]: action.payload,
      };
    case PAGE_BOOKINGS_STATUS.PENDING:
      return {
        ...state,
        [PAGE_BOOKINGS_STATUS.PENDING]: action.payload,
      };
  }
};

export const INITIAL_STATE = {
  [PAGE_BOOKINGS_STATUS.PLANNING]: planningStateObject,
  [PAGE_BOOKINGS_STATUS.UPCOMING]: initialStateObject,
  [PAGE_BOOKINGS_STATUS.CANCELLED]: initialStateObject,
  [PAGE_BOOKINGS_STATUS.COMPLETED]: initialStateObject,
  [PAGE_BOOKINGS_STATUS.FAILED]: initialStateObject,
  [PAGE_BOOKINGS_STATUS.PENDING]: initialStateObject,
};
