import React, {useEffect} from 'react';
import { View, ScrollView, Text, Image } from 'react-native';
import styles from './WhatHappensNextCardCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { RTB_ACCEPTED_ICON, RTB_REJECTED_ICON } from '../../../HotelImageConstants';
import {isNullOrEmpty} from '../../utils/HotelBookingDetailUtil';
import {RTBConstants} from '../../HotelBookingDetailsConstant';
import HotelBookingTrackingHelper from '../../../HotelBookingTrackingHelper';
import { getStaticData } from '../../../../staticData/staticData';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const WhatHappensNext = ({ response, pageName, rtbData }) => {
  const { fsStyle, ...fonts } = getFont(true);
	useEffect(() => {
		HotelBookingTrackingHelper.trackClickEvent(pageName, 'MI_HTL_WHN_Card_shown', response);
	}, [pageName, response]);
	
	
	const {whatHappensNextText} = getStaticData();
	const {subHeader, rtbCardDataList} = rtbData || {};

	const getCardIcon = (type) => {
		switch(type) {
			case RTBConstants.ACCEPTED:
				return RTB_ACCEPTED_ICON;
			case RTBConstants.REJECTED:
				return RTB_REJECTED_ICON;
			default:
				return RTB_ACCEPTED_ICON;
		}
	}

	if(isNullOrEmpty(rtbCardDataList)) return null;

	return (
		<View style={styles.container}>
			<View style={[AtomicCss.whiteCardNew, AtomicCss.marginHz12, AtomicCss.borderRadius16]}>
				<View style={styles.detailsSection}>
					<Text style={[fsStyle.font18, AtomicCss.blackText, fonts.blackFontFamily, AtomicCss.marginBottom3, AtomicCss.alignLeft]}>{whatHappensNextText}</Text>
					<Text style={[fsStyle.font14, AtomicCss.defaultText, fonts.regularFontFamily, AtomicCss.lineHeight20]}>{subHeader}</Text>
				</View>
				<ScrollView horizontal={true} showsHorizontalScrollIndicator={false} contentContainerStyle={styles.scrollWrapper}>
					<View style={[AtomicCss.flexRow]}>
						{rtbCardDataList.map(item => {
							return(
								item && <View style={[styles.cardWrapper]}>
									<View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.spaceBetween, AtomicCss.marginBottom8]}>
										<Image source={getCardIcon(item.type)} style={styles.iconStyle} />
										{item.statusTag && <View style={styles.stsTextWrapper}>
												<Text style={[fsStyle.font12, fonts.blackFontFamily, AtomicCss.lineHeight20, styles.stsTextStyle]}>{item.statusTag}</Text>
										</View>}
									</View>
									<View>
										<Text style={[fsStyle.font14, fonts.blackFontFamily, AtomicCss.blackText, AtomicCss.lineHeight20, styles.marginBottom4]}>{item.header}</Text>
										<Text style={[fsStyle.font13, fonts.regularFontFamily, AtomicCss.defaultText, AtomicCss.lineHeight20]}>{item.description}</Text>
									</View>
								</View>
							)
						})}
					</View>
				</ScrollView>
			</View>
		</View>
  	);
};

export default WhatHappensNext;