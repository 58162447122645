import { Dimensions, Platform, StyleSheet } from 'react-native';
const windowWidth =
  Platform.OS === 'ios' ? Dimensions.get('screen').width : Dimensions.get('window').width;
export default (theme, fonts) => StyleSheet.create( {
  pageWrapper: {
    flex: 1,
  },
  pageScroll: {
    flex: 1,
    backgroundColor: theme.color.grayBg,
  },
  pageScrollTopSpace: {
    height: 0,
  },
  whiteCard: {
    backgroundColor: theme.color.white,
    padding: 15,
    marginVertical: 8,
    elevation: 2,
    shadowColor: theme.color.black,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.3,
    shadowRadius: 1,
  },
  ReviewCard: {
    marginVertical: 5,
  },
  showView: {
    display: 'flex',
  },
  hideView: {
    display: 'none',
  },
  heading2Text: {
    fontSize: fonts.fontSize.font14,
    color: theme.color.black,
    ...fonts.boldFontFamily,
  },
  marBt5: {
    marginBottom: 5,
  },
  font12: {
    fontSize: fonts.fontSize.font12,
  },
  infoIcon: {
    width: 16,
    height: 16,
    marginRight: 10,
  },
  info_redTxt: {
    color: theme.color.red6,
    fontSize: fonts.fontSize.font12,
    lineHeight: 16,
    fontStyle: 'italic',
  },
  flexRow: {
    flexDirection: 'row',
  },
  marginBottom10: {
    marginBottom: 10,
  },
  padding10: {
    padding: 10,
  },
  padding20: {
    paddingRight: 30,
  },
  refundInfoWrapper: {
    borderRadius: 4,
    padding: 10,
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 10,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: '#ffedd1',
  },
  refundInfoTxt: {
    ...fonts.regularFontFamily,
    fontSize: fonts.fontSize.font14,
    color: '#1a7971',
  },
  confirmOverlayIcon: {
    width: 50,
    height: 44.3,
    marginRight: 15,
  },
  footerBtnTextStyle:{
    ...fonts.fsStyle.font16,
  },
  footerBtnStyle:{
    maxWidth:windowWidth - 200
  }

});

export const createHtmlStyle = (theme, fonts)=>StyleSheet.create({
  q: {
    /* fix fo Html Fonts Not working without adding blank tag */
  },
  p: {
    fontSize: fonts.fontSize.font14,
    color: theme.color.defaultTextColor,
    ...fonts.regularFontFamily
  },
  b: {
    fontSize: fonts.fontSize.font14,
    color: theme.color.defaultTextColor,
    ...fonts.boldFontFamily
  },
});