import React from 'react';
import { StyleSheet, Text ,View,Image} from 'react-native';
import HTMLView from 'react-native-htmlview';
import { colors, fonts } from '@mmt/legacy-commons/Styles/globalStyles';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';

const HTMLViewWrapper = ({ htmlContent,style ,contentWidth}) => {
  const inheritedStyle = style ||[];
  const htmlTitleStyle = StyleSheet.create({
    q: {
      fontFamily: fonts.regular,
      color: colors.black1,
      fontSize: 14,
    },
    b: {
      fontFamily: fonts.bold,
      color: colors.black1,
    },
    strong: {
      fontFamily: fonts.bold,
      color: colors.black1,
    },
  });

  const headTagStyles = {
    h1: {
      ...AtomicCss.font30,
      fontFamily: fonts.bold,
    },
    h2: {
      ...AtomicCss.font24,
      fontFamily: fonts.bold,
    },
    h3: {
      ...AtomicCss.font20,
      fontFamily: fonts.bold,
    },
    h4: {
      ...AtomicCss.font18,
      fontFamily: fonts.bold,
    },
    h5: {
      ...AtomicCss.font16,
      fontFamily: fonts.bold,
    },
  };

  const integerStyle = [
    'fontSize',
    'padding',
    'margin',
    'top',
    'bottom',
    'left',
    'right',
    'width',
    'height',
    'borderRadius',
    'borderWidth',
  ];

  const renderNode = (node, index, siblings, parent, defaultRenderer) => {
    const style = node && node.attribs ? node.attribs.style : null;
    let styleObj = null;
    if (style) {
      const styles = style.split(';');
      styleObj = {};

      styles.forEach((item) => {
        const prop = item && item.trim();
        if (prop) {
          let [key, value] = item.split(':');
          key = key.trim();
          value = value.trim();
          styleObj[key] = integerStyle.includes(key) ? parseInt(value) : value;
        }
      });
    }
    if (node.name === 'b' || node.name === 'strong') {
      return (
        <Text key={index} style={[{ fontFamily: fonts.bold }, styleObj,...inheritedStyle]}>
          {defaultRenderer(node.children, parent)}
        </Text>
      );
    } else if (node.name === 'span') {
      return (
        <Text key={index} style={[styleObj,...inheritedStyle]}>
          {defaultRenderer(node.children, parent)}
        </Text>
      );
    } else if (['h1', 'h2', 'h3', 'h4', 'h5'].includes(node.name)) {
      return (
        <Text key={index} style={[headTagStyles[node.name], styleObj,...inheritedStyle]}>
          {defaultRenderer(node.children, parent)}
        </Text>
      );
    } else if (node.type === 'text') {
      return <Text style={[...inheritedStyle]}>{node.data}</Text>;
    } else if (node.name === 'img'){
      try{
        return <Image key={index} style = {{width:parseInt(node.attribs.width),height:parseInt(node.attribs.height)}} source={{uri:node.attribs.src}}/>
      } catch(e){
        console.log("Invalid attributes passed to image");
        return null;
      }
    }
    return undefined;
  };

  return (
     <View style={ contentWidth ?{width : contentWidth } : null}>
    <HTMLView stylesheet={htmlTitleStyle} value={`<q>${htmlContent}</q>`} renderNode={renderNode} />
    </View>
  );
};
export default HTMLViewWrapper;
