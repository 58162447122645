import React from 'react';
import PropTypes from 'prop-types';
import { View, TouchableOpacity } from 'react-native';
import styles from '../css/OverlayMessageCss';
import AgreeOverlay from './AgreeOverlay';

const OverlayMessage = ({ closePopupHandler, proceedClickHandler, specialClaimData }) => (
  <View style={styles.overlayWrapper}>
    <TouchableOpacity
      activeOpacity={1}
      style={styles.overlay}
      onPress={() => closePopupHandler()}
    />
    <View style={styles.overlayContent}>
      <AgreeOverlay
        specialClaimData={specialClaimData}
        proceedClickedHandler={proceedClickHandler}
      />
    </View>
  </View>
);

OverlayMessage.propTypes = {
  closePopupHandler: PropTypes.func.isRequired,
  proceedClickHandler: PropTypes.func.isRequired,
  specialClaimData: PropTypes.object.isRequired,
};

export default OverlayMessage;
