import React, { useState, useRef } from 'react';
import { Image, Text, View, TouchableOpacity, LayoutAnimation } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import isEmpty from 'lodash/isEmpty';
import { ArrowDown, dropDownArrow } from '../../CabImageConstants';
import { doubleTick } from '../../CabImageConstants';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import Actions from '../../../navigation/postSalesNavigation';
import CabBookingTrackingHelper from '../../CabBookingTrackingHelper';
import CabBookingDetailsConstant, { CLICK_EVENT, PAGE_NAME } from '../../CabBookingDetailsConstant';
import { getFont } from '../../../PostSalesStyles';
import { useTheme } from '../../../theme/theme.context';
import { getStyles } from './CabUpgradeStyle';
import ExtraPayable from '../ExtraPayable';

const getPaymentTextColor = (textColor, theme) => {
  if(textColor === 'yellow')
  return theme.color.lightYello;
  else
  return theme.color.lightGreen18;
}

const CabListing = ({
  currentBookingData,
  currentBooking,
  cabDetail,
  scrollViewRef,
  rawData,
  prevRequest,
  subtitle,
  handleOverlay,
}) => {
  const ref = useRef();
  const [toggle, setToggle] = useState();
  const { fsStyle, ...fonts } = getFont();
  const { psTheme: theme } = useTheme();
  const styles = getStyles(fonts, theme);

  const handleToggle = () => {
    if (!toggle) {
      CabBookingTrackingHelper.trackClickEvent(
        PAGE_NAME.MODIFY_BOOKING,
        CLICK_EVENT.VIEW_ALL_CABS_CLICK,
      );
    } else {
      CabBookingTrackingHelper.trackClickEvent(
        PAGE_NAME.MODIFY_BOOKING,
        CLICK_EVENT.HIDE_CAB_OPTION_CLICK,
      );
    }
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    setToggle(!toggle);
    ref.current.measureLayout(scrollViewRef, (x, y) => {
      scrollViewRef.scrollTo({ x: 0, y: y, animated: true });
    });
  };

  let moreOptions;
  let moreOptionsText;
  let carDetail = cabDetail;
  if (Array.isArray(cabDetail) && !isEmpty(cabDetail)) {
    carDetail = cabDetail[0];
    if (cabDetail.length >= 2) {
      moreOptions = cabDetail.slice(1);
      moreOptionsText = moreOptions.length + ' more cab option(s) available';
    }
  }

  const { imageUrl, model, category, newPaymentText, newPriceValueText, newPaymentTextColor : textColor } = carDetail;
  const paymentTextColor = getPaymentTextColor(textColor, theme);

  const newCarDetails = (carDetail, currentBooking) => () => {
    if (!currentBooking) {
      CabBookingTrackingHelper.trackClickEvent(
        PAGE_NAME.MODIFY_BOOKING,
        CLICK_EVENT.CAB_SELECTED_CLICK,
      );
      Actions.cabDetailReview({
        carDetail: carDetail,
        rawData: rawData,
        prevRequest: prevRequest,
        subtitle: subtitle,
      });
    }
  };

  const { bookingAmountText = "", bookingAmountValue = "" } = currentBookingData || {};
  const { viewAllText, hideText } = CabBookingDetailsConstant;
  return (
    <View style={[styles.cabInfo]} ref={ref}>
      <TouchableOpacity onPress={newCarDetails(carDetail, currentBooking)}>
        <View style={styles.cabBasicInfo}>
          <View style={styles.leftSection}>
            <Image source={{ uri: imageUrl }} style={styles.cabImage} />
            <View style={{ flex: 1 }}>
              {!isEmpty(model) && <Text style={styles.cabName}>{model}</Text>}
              {!isEmpty(category) && <Text style={styles.textSm}>{category}</Text>}
            </View>
          </View>
          {!!currentBooking ? (
            <View>
              {!!bookingAmountText && (
                <Text style={[styles.bookingAmountText, AtomicCss.alignRight]}>
                  {bookingAmountText}
                </Text>
              )}
              {!!bookingAmountValue && (
                <Text style={[styles.bookingAmountValue, AtomicCss.alignRight]}>
                  {bookingAmountValue}
                </Text>
              )}
            </View>
          ) : (
            <ExtraPayable
              paymentText={newPaymentText}
              paymentTextColor={paymentTextColor}
              paymentValueText={newPriceValueText}
              handleOverlay={handleOverlay}
              carDetail={carDetail}
            />
          )}
        </View>
        <FeatureList
          carDetail={carDetail}
          currentBooking={currentBooking}
          showExtraPay={false}
          handleOverlay={handleOverlay}
        />
      </TouchableOpacity>
      {!isEmpty(moreOptions) && (
        <TouchableOpacity style={styles.toggleBtnWrapper} onPress={handleToggle}>
          <Text style={[AtomicCss.blackText, AtomicCss.boldFont, AtomicCss.font12]}>
            {moreOptionsText}
          </Text>
          <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
            {!toggle ? (
              <Text style={[AtomicCss.azure, AtomicCss.blackFont, AtomicCss.font13, AtomicCss.marginRight5]}>
                {viewAllText}
              </Text>
            ) : (
              <Text style={[AtomicCss.azure, AtomicCss.blackFont, AtomicCss.font13, AtomicCss.marginRight5]}>
                {hideText}
              </Text>
            )}
            <Image
              style={!toggle ? [styles.arrowStyle] : [styles.arrowStyle, styles.activeArrow]}
              source={dropDownArrow}
            />
          </View>
        </TouchableOpacity>
      )}
      {toggle && (
        <View style={[AtomicCss.greyBg, AtomicCss.marginTop10]}>
          {!isEmpty(moreOptions) &&
            moreOptions.map((cab, index) => {
              return (
                <TouchableOpacity onPress={newCarDetails(cab, currentBooking)}>
                  <FeatureList
                    carDetail={cab}
                    divide={index >= 1}
                    subItems={true}
                    currentBooking={currentBooking}
                    showExtraPay={true}
                    handleOverlay={handleOverlay}
                  />
                </TouchableOpacity>
              );
            })}
        </View>
      )}
    </View>
  );
};

const FeatureList = ({
  carDetail,
  divide,
  subItems,
  currentBooking,
  showExtraPay,
  handleOverlay,
}) => {
  const {
    newPaymentTextColor : textColor,
    newPaymentText,
    newPriceValueText,
    facilitiesList,
    cancellationPolicyText,
    seatText,
    seatValue,
    luggageText,
    luggageValue,
    paymentText,
    paymentValueText
  } = carDetail;

  const { fsStyle, ...fonts } = getFont();
  const { psTheme: theme } = useTheme();
  const styles = getStyles(fonts, theme);
  const paymentTextColor = getPaymentTextColor(textColor, theme);
  return (
    <View
      style={[
        styles.cabPriceWrapper,
        divide ? styles.divider : '',
        subItems ? styles.subCabPad : '',
        !currentBooking ? AtomicCss.paddingRight12 : {},
      ]}
    >
      {currentBooking ? (
        <View style={AtomicCss.flex1}>
          <View style={[AtomicCss.flexRow, AtomicCss.paddingTop10]}>
            <View style={[AtomicCss.flexRow, AtomicCss.flex1, AtomicCss.alignCenter]}>
              {!isEmpty(seatText) && !isEmpty(seatValue) && (
                <Text style={[styles.textMd, AtomicCss.marginRight5]}>
                  {seatText}
                  <Text style={styles.textBold}>&nbsp; &nbsp;{seatValue}</Text>
                </Text>
              )}
              {!isEmpty(luggageText) && !isEmpty(luggageValue) && (
                <Text style={[styles.textMd, AtomicCss.marginRight5]}>
                  {luggageText}
                  <Text style={styles.textBold}>&nbsp; &nbsp;{luggageValue}</Text>
                </Text>
              )}
            </View>
          </View>
        </View>
      ) : (
        <View style={AtomicCss.flex1}>
          <View style={[AtomicCss.flexRow]}>
            <View style={[AtomicCss.flex1, AtomicCss.justifyEnd]}>
              <View>
                {!isEmpty(facilitiesList) &&
                  facilitiesList.map((facility) =>
                    facility.text ? (
                      <View
                        style={[AtomicCss.flexRow, AtomicCss.alignCenter, styles.amenListWrapper]}
                      >
                        <View style={styles.dot} />
                        <Text style={[styles.dotList]}>{facility.text}</Text>
                      </View>
                    ) : null,
                  )}
              </View>
              {!isEmpty(cancellationPolicyText) && (
                <View style={[styles.cancellationText, AtomicCss.flexRow]} numberOfLines={2}>
                  <Image style={styles.tickImageStyle} source={doubleTick} />
                  <Text style={styles.persuationTxt}>{cancellationPolicyText}</Text>
                </View>
              )}
            </View>
            {showExtraPay && (
              <ExtraPayable
                paymentText={newPaymentText}
                paymentTextColor={paymentTextColor}
                paymentValueText={newPriceValueText}
                handleOverlay={handleOverlay}
                carDetail={carDetail}
              />
            )}
          </View>
        </View>
      )}
    </View>
  );
};

export default CabListing;
