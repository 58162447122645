import React from "react";
import { appendUrlPrefix } from "../../webUtils";
import loadable from "@loadable/component";
import ProgressView from "../../components/ProgressView";
const HOTELS_PATHS = {
    HOTEL_BOOKING_DETAIL: {
        pathName: appendUrlPrefix('/hotel'),
        pageKey: "hotelBookingDetail",
        isRTL: true,
        Component: loadable(() => import("@rn/apps/post-sales/src/hotel/details/components/HotelBookingDetails"), { fallback: <ProgressView /> }),
    },
    HOTEL_AMENITIES: {
        pathName: appendUrlPrefix('/hotel/amenities'),
        pageKey: "viewHotelAmenities",
        isRTL: true,
        Component: loadable(() => import("@rn/apps/post-sales/src/hotel/details/components/ViewHotelAmenities"), { fallback: <ProgressView /> }),
    },
    HOTEL_BOOKING_PAYMENT_BREAKUP: {
        pathName: appendUrlPrefix('/hotel/paymentBreakup'),
        pageKey: "viewPaymentBreakup",
        isRTL: true,
        Component: loadable(() => import("@rn/apps/post-sales/src/hotel/details/components/PaymentCardDetails"), { fallback: <ProgressView /> }),
    },
    HOTEL_BOOKING_CANCELLATION_PREVIEW: {
        pathName: appendUrlPrefix('/hotel/cancellation/preview'),
        pageKey: "cancellationPreview",
        isRTL: true,
        Component: loadable(() => import("@rn/apps/post-sales/src/hotel/Cancellation"), { fallback: <ProgressView /> }),
    },
    HOTEL_BOOKING_DATE_CHANGE: {
        pathName: appendUrlPrefix('/hotel/dateChange'),
        pageKey: "hotelChangeDate",
        isRTL: true,
        Component: loadable(() => import("@rn/apps/post-sales/src/hotel/modification/datechange/HotelChangeDate"), { fallback: <ProgressView /> }),
    },
    HOTEL_BOOKING_ADD_GUEST: {
        pathName: appendUrlPrefix('/hotel/addGuest'),
        pageKey: "addGuest",
        isRTL: true,
        Component: loadable(() => import("@rn/apps/post-sales/src/hotel/modification/addguest/AddGuest"), { fallback: <ProgressView /> }),
    },
    HOTEL_BOOKING_ADD_GUEST_REVIEW: {
        pathName: appendUrlPrefix('/hotel/addGuest/review'),
        pageKey: "addGuestReview",
        isRTL: true,
        Component: loadable(() => import("@rn/apps/post-sales/src/hotel/modification/addguest/AddGuestReview"), { fallback: <ProgressView /> }),
    },
    HOTEL_BOOKING_VIEW_HOUSE_RULES: {
        pathName: appendUrlPrefix('/hotel/viewHouseRule'),
        pageKey: "viewHouseRules",
        isRTL: true,
        Component: loadable(() => import("@rn/apps/post-sales/src/hotel/details/pages/HouseRules"), { fallback: <ProgressView /> }),
    },
    HOTEL_BOOKING_THANK_YOU: {
        pathName: appendUrlPrefix('/hotel/travelInsuranceThankYou'),
        pageKey: "hotelTravelInsuranceThankYou",
        isRTL: true,
        Component: loadable(() => import("@rn/apps/post-sales/src/hotel/details/pages/ThankyouPage"), { fallback: <ProgressView /> }),
    },
    HOTEL_BOOKING_CANCELLATION_POLICY: {
        pathName: appendUrlPrefix('/hotel/cancellationPolicy'),
        pageKey: "hotelCancellationPoilcyRule",
        isRTL: true,
        Component: loadable(() => import("@rn/apps/post-sales/src/hotel/details/components/HotelCancellationPolicyRules"), { fallback: <ProgressView /> }),
    },
    HOTEL_BOOKING_HOTEL_POLICY: {
        pathName: appendUrlPrefix('/hotelPolicy'),
        pageKey: "viewHotelPolicy",
        isRTL: true,
        Component: loadable(() => import("@rn/apps/post-sales/src/hotel/details/components/ViewHotelPolicy"), { fallback: <ProgressView /> }),
    },
    HOTEL_CHANGE_DATE_VIEW_PRICE: {
        pathName: appendUrlPrefix('/hotelChangeDatePrice'),
        pageKey: "hotelChangeDateViewPrice",
        isRTL: true,
        Component: loadable(() => import("@rn/apps/post-sales/src/hotel/modification/datechange/HotelChangeDateViewPrice"), { fallback: <ProgressView /> }),
    },
    HOTEL_MODIFICATION_THANK_YOU: {
        pathName: appendUrlPrefix('/hotelModificationThankyou'),
        pageKey: "hotelModificationThankYouPage",
        isRTL: true,
        Component: loadable(() => import("@rn/apps/post-sales/src/hotel/modification/common/HotelModificationThankYouPage"), { fallback: <ProgressView /> }),
    },
    HOTEL_MODIFICATION_FAILURE: {
        pathName: appendUrlPrefix('/hotelModificationFailure'),
        pageKey: "hotelModificationFailure",
        isRTL: true,
        Component: loadable(() => import("@rn/apps/post-sales/src/hotel/modification/common/HotelModificationFailure"), { fallback: <ProgressView /> }),
    },
    HOTEL_PAYMENT_INFO: {
        pathName: appendUrlPrefix('/hotelPaymentInfo'),
        pageKey: "hotelPaymentInfo",
        isRTL: false,
        Component: loadable(() => import("@rn/apps/post-sales/src/hotel/modification/common/PaymentInfo/PaymentInfo"), { fallback: <ProgressView /> }),
    },
    HOTEL_SHARE_BOOKING_DETAILS: {
        pathName: appendUrlPrefix('/hotelShareBookingDetails'),
        pageKey: "openResendVoucherPage",
        isRTL: true,
        Component: loadable(() => import("@rn/apps/post-sales/src/hotel/details/components/ResendHotelVoucher"), { fallback: <ProgressView /> }),
    },
    HOTEL_OPEN_CALL: {
        pathName: appendUrlPrefix('/hotelOpenCall'),
        pageKey: "openCallHotel",
        isRTL: true,
        Component: loadable(() => import("@rn/apps/post-sales/src/hotel/details/components/CallHotel"), { fallback: <ProgressView /> }),
    },
    HOTEL_VIEW_LOCATION: {
        pathName: appendUrlPrefix('/hotelViewLocation'),
        pageKey: "viewGetDirections",
        isRTL: true,
        Component: loadable(() => import("@rn/apps/post-sales/src/hotel/details/components/ViewGetDirections"), { fallback: <ProgressView /> }),
    },
    HOTEL_SEND_REQUEST: {
        pathName: appendUrlPrefix('/hotelSendRequest'),
        pageKey: "makeRequestPage",
        isRTL: true,
        Component: loadable(() => import("@rn/apps/post-sales/src/hotel/details/components/MakeRequest"), { fallback: <ProgressView /> }),
    },
    HOTEL_REFUND_BREAKUP: {
        pathName: appendUrlPrefix('/hotelRefundBreakup'),
        pageKey: "viewRefundCalculation",
        isRTL: true,
        Component: loadable(() => import("@rn/apps/post-sales/src/hotel/details/components/RefundBreakUpCard"), { fallback: <ProgressView /> }),
    },
    HOTEL_SUBMIT_REQUEST: {
        pathName: appendUrlPrefix('/hotelSubmitRequest'),
        pageKey: "submitRequestPage",
        isRTL: true,
        Component: loadable(() => import("@rn/apps/post-sales/src/hotel/details/components/SubmitRequest"), { fallback: <ProgressView /> }),
    },
    HOTEL_SUCCESSSFUL_SUBMISSION: {
        pathName: appendUrlPrefix('/hotelSuccessfulSubmission'),
        pageKey: "openSubmitPage",
        isRTL: true,
        Component: loadable(() => import("@rn/apps/post-sales/src/hotel/details/components/HotelSuccessfulSubmissionPage"), { fallback: <ProgressView /> }),
    },
    VIEW_HOTEL_ROOM_AMENITIES: {
        pathName: appendUrlPrefix('/viewRoomAmenities'),
        pageKey: "viewRoomAmenities",
        isRTL: true,
        Component: loadable(() => import("@rn/apps/post-sales/src/hotel/details/components/ViewRoomAmenities"), { fallback: <ProgressView /> }),
    },
    HOTEL_GUEST_NAME_CHANGE: {
        pathName: appendUrlPrefix('/hotelGuestNameChange'),
        pageKey: "openNameChangePage",
        isRTL: true,
        Component: loadable(() => import("@rn/apps/post-sales/src/hotel/details/components/HotelDetailsNameChange"), { fallback: <ProgressView /> }),
    },
    HOTEL_DETAILS_PAHX: {
        pathName: appendUrlPrefix('/detailsPAHX'),
        pageKey: "openPahxPage",
        isRTL: false,
        Component: loadable(() => import("@rn/apps/post-sales/src/hotel/details/components/DetailsPAHX"), { fallback: <ProgressView /> }),
    },
    WRITE_TO_HOTEL: {
        pathName: appendUrlPrefix('/requestWriteToHotel'),
        pageKey: "requestWriteToHotel",
        isRTL: true,
        Component: loadable(() => import("@rn/apps/post-sales/src/hotel/details/components/RequestWriteToHotel"), { fallback: <ProgressView /> }),
    },
    HOTEL_THANK_YOU_PAGE: {
        pathName: appendUrlPrefix('/hotelThankYouPage'),
        pageKey: "psThankYouPage",
        isRTL: true,
        Component: loadable(() => import("@rn/apps/post-sales/src/hotel/details/components/PSThankYouPage"), { fallback: <ProgressView /> }),
    },
    HOTEL_PROPERTY_DETAILS: {
        pathName: appendUrlPrefix('/hotel/propertyDetails'),
        pageKey: "hotelPropertyDetails",
        isRTL: true,
        Component: loadable(() => import("@rn/apps/post-sales/src/hotel/details/pages/PropertyDetails"), { fallback: <ProgressView /> }),
    },
    HOTEL_PHOTOS: {
        pathName: appendUrlPrefix('/hotelPhotos'),
        pageKey: "viewHotelPhotos",
        isRTL: true,
        Component: loadable(() => import("@rn/apps/post-sales/src/hotel/details/pages/HotelPhotos"), { fallback: <ProgressView /> }),
    },
     ROOM_PHOTOS: {
        pathName: appendUrlPrefix('/roomPhotos'),
        pageKey: "viewRoomPhotos",
        isRTL: true,
        Component: loadable(() => import("@rn/apps/post-sales/src/hotel/details/pages/RoomPhotos"), { fallback: <ProgressView /> }),
    },
     HOTEL_INDIVIDUAL_PHOTOGRAPH: {
        pathName: appendUrlPrefix('/hotel/photographs'),
        pageKey: "viewIndividualPhotograph",
        isRTL: true,
        Component: loadable(() => import("@rn/apps/post-sales/src/hotel/details/components/IndividualPhotographs"), { fallback: <ProgressView /> }),
    },
    HOTEL_PARTIAL_CANCELLATION: {
        pathName: appendUrlPrefix('/hotel/partialCancellation'),
        pageKey: "hotelPartialCancellation",
        isRTL: true,
        Component: loadable(() => import("@rn/apps/post-sales/src/hotel/Cancellation/PartialCancellation/PartialCancellation"), { fallback: <ProgressView /> }),
    },
    STAFF_DETAILS: {
        pathName: appendUrlPrefix('/hotel/staffDetails'),
        pageKey: "staffDetails",
        isRTL: true,
        Component: loadable(() => import("@rn/apps/post-sales/src/hotel/details/pages/StaffDetails"), { fallback: <ProgressView /> }),
    },
    PAHX_CARD: {
        pathName: appendUrlPrefix('/hotel/pahxChargeCard'),
        pageKey: "pahxChargeCard",
        isRTL: true,
        Component: loadable(() => import("@rn/apps/post-sales/src/hotel/details/components/PAHXChargeCard"), {fallback:<ProgressView />}),
    },
    CHARGE_SUCCESSFUL_PAGE: {
        pathName: appendUrlPrefix('/hotel/chargeSuccessfullPage'),
        pageKey: "chargeSuccessfullPage",
        isRTL: true,
        Component: loadable(() => import("@rn/apps/post-sales/src/hotel/details/components/ChargeSuccessfullPage"),{ fallback:<ProgressView/>}),
    },
    CHARGE_FAILED_PAGE: {
        pathName: appendUrlPrefix('/hotel/chargeFailedPage'),
        pageKey: "chargeFailedPage",
        isRTL: true,
        Component: loadable(() => import("@rn/apps/post-sales/src/hotel/details/components/ChargeFailedPage"), {fallback:<ProgressView/>}),
    },
    ADD_GUEST_NAMES: {
        pathName: appendUrlPrefix('/hotel/addGuestNames'),
        pageKey: "addGuestNames",
        isRTL: false,
        Component: loadable(() => import("@rn/apps/post-sales/src/hotel/details/components/AddGuestName"),{fallback:<ProgressView/>}),
    },
    ADD_GUEST_NAMES_RESULT: {
        pathName: appendUrlPrefix('/hotel/addGuestNamesResult'),
        pageKey: "addGuestNamesResult",
        isRTL: false,
        Component: loadable(() => import("@rn/apps/post-sales/src/hotel/details/components/AddGuestNamesResult"), {fallback:<ProgressView/>}),
    },

}

export default HOTELS_PATHS;
