import React from 'react';
import { View, Text, Image } from 'react-native';
import { getFont } from '../../../../../PostSalesStyles';
import { useTheme } from '../../../../../theme/theme.context';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import createStyles from './reviewTopSectionCSS';
import { BUS_YELLOW_ICON } from '../../../../BusImageConstants';
const ReviewTopSection = ({ reviewData }) => {
  const { selectedBus } = reviewData || {};
  const { operatorName, busType } = selectedBus;
  const { fsStyle, ...fonts } = getFont();
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);
  return (
    <View style={[AtomicCss.marginTop10, styles.destCard]}>
      <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween]}>
        {!!operatorName && (
          <Text
            style={[
              fsStyle.font19,
              AtomicCss.blackText,
              fonts.boldFontFamily,
              AtomicCss.marginBottom5,
            ]}
          >
            {operatorName}
          </Text>
        )}
        <Image source={BUS_YELLOW_ICON} style={styles.busIcon} />
      </View>
      {!!busType && <Text style={[fsStyle.font12, AtomicCss.marginBottom10]}>{busType}</Text>}
    </View>
  );
};

export default ReviewTopSection;
