import { colors } from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';
import {Platform} from 'react-native';

const getStyles = (fonts) => {
  return {
    container: {
      flex: 1,
      backgroundColor: '#f2f2f2'
    },
    buttonWrapper: {
      paddingHorizontal: 10,
      paddingVertical: 15
    },
    card: {
      backgroundColor: '#fff',
      marginBottom: 12,
      padding: 15,
      ...getPlatformElevation(2)
    },
    addGuestRow: {
      flexDirection: 'row'
    },
    addGuestRowHead: {
      marginBottom: 10,
      width: '100%'
    },
    addGuestRowHeadText: {
      color: '#000000',
      fontFamily: fonts.black
    },
    appendBottom20: {
      marginBottom: 20
    },
    nameHeading: {
      fontFamily: fonts.black,
      ...fonts.font14,
      marginBottom: 8
    },
    traveller: {
      ...fonts.font12,
      color: '#4a4a4a',
      width: 60,
      marginBottom: 6
    },
    travellerName: {
      flex: 1,
      ...fonts.font12,
      color: '#4a4a4a',
      marginBottom: 6
    },
    nameOuter: {
      width: '35%',
      marginRight: '5%',
      top: 0
    },
    nameInput: {
      borderBottomColor: '#c2c2c2',
      borderBottomWidth: 1,
      paddingVertical: 10
    },
    titleOuter: {
      width: 48,
      marginRight: '5%'
    },
    selectBox: {
      borderBottomColor: '#c2c2c2',
      borderBottomWidth: 1,
      paddingVertical: 10,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    selectBoxText: {
      fontFamily: fonts.bold,
      ...fonts.font14,
      color: '#4a4a4a'
    },
    selectOptionsOuter: {
      backgroundColor: '#fff',
      position: 'absolute',
      top: 18,
      width: 48,
      paddingLeft: 5,
      borderRadius: 2,
      borderColor: '#f2f2f2',
      borderWidth: 1,
      zIndex: 9999,
      ...getPlatformElevation(2)
    },
    selectOptions: {
      paddingVertical: 4
    },
    arrow: {
      width: 0,
      height: 0,
      backgroundColor: 'transparent',
      borderStyle: 'solid',
      borderTopWidth: 6,
      borderRightWidth: 5,
      borderBottomWidth: 0,
      borderLeftWidth: 5,
      borderTopColor: '#c2c2c2',
      borderRightColor: 'transparent',
      borderBottomColor: 'transparent',
      borderLeftColor: 'transparent'
    },
    addGuestLabel: {
      ...Platform.select({
        ios: {
          top: 4,
          marginBottom: 6
        },
        android: {
          top: 4,
          marginBottom: 8
        }
      })
    },
    addGuestLabelText: {
      color: '#747474',
      ...fonts.font12,
      fontFamily: fonts.regular
    },
    pageWrapper: {
      flex: 1,
      backgroundColor: colors.white,
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingBottom: '5%',
      paddingTop: '15%',
      paddingHorizontal: 15
    },
    footerBtnWrapper: {
      paddingHorizontal: 50,
      width: '100%'
    },
    messageContent: {
      justifyContent: 'center',
      alignItems: 'center'
    },
    messageImg: {
      height: 80,
      width: 80,
      marginBottom: 20
    },
    messageTitle: {
      ...fonts.font20,
      fontFamily: fonts.bold,
      color: colors.black28,
      marginBottom: 10,
      textAlign: 'center'
    },
    messageDesc: {
      ...fonts.font14,
      fontFamily: fonts.regular,
      color: colors.gray,
      textAlign: 'center',
      marginBottom: 15,
      marginTop: 5
    }
  };
}

export default getStyles;
