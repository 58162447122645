import React from 'react';
import {View, Text} from 'react-native';
import styles from './BreadcrumbCss';

export default class Breadcrumb extends React.Component {
  render() {
    	return (
      <View style={styles.steps}>
        {
                  this.props.data.map((step, index) => {
                      const activeStyle = index <= this.props.activeStep ? 'Active' : '';
                      return (
                        <View key={index} style={styles.stepItem}>
                          <View style={[styles.stepNumberWrapper, styles[`stepNumberWrapper${activeStyle}`]]}>
                            <Text style={styles.stepNumber}>{index + 1}</Text>
                          </View>
                          <Text style={[styles.stepName, styles[`stepName${activeStyle}`]]}>{step}</Text>
                        </View>
                      );
                  })
              }
      </View>
    );
  }
}

