import { NativeModules } from 'react-native';

export interface AnyObject {
  [key: string]: any;
}

export function fetchJson(input: RequestInfo, options?: RequestInit) {
  return fetch(input, options).then((value) => value.json());
}

export const botmanSensorHeader = async () => {
  try {
    const { NetworkModule } = NativeModules;
    // This will return map of sensor data and user agent.
    const sensorData = await NetworkModule.getSensorData();
    if (sensorData) {
      return sensorData;
    }
    return {};
  } catch (e) {
    return {};
  }
};

export async function fetchPostJson<T>(
  input: RequestInfo,
  body: any,
  header: AnyObject = {},
): Promise<T> {
  const data = typeof body === 'string' ? body : JSON.stringify(body);
  const sensorHeader = await botmanSensorHeader();
  let headers: AnyObject = {
    'Content-Type': 'application/json',
    ...header,
    ...sensorHeader,
  };
  return fetch(input, {
    body: data,
    headers,
    method: 'POST',
  })
    .then((value) => {
      return value.json();
    })
    .then((value) => {
      return Promise.resolve(value);
    });
}

export function fetchGetJson<T>(url: string): Promise<T> {
  return fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  })
    .then((value) => {
      return value.json();
    })
    .then((value) => {
      return Promise.resolve(value);
    });
}
