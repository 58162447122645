import React from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import Actions from '../../../navigation/postSalesNavigation';
import AtomicCss from '../../commonStyles/AtomicCss';
import { rightArrow } from '../../BusImageConstants';
import { getFont } from '../../../PostSalesStyles';
import { useTheme } from '../../../theme/theme.context';
import isEmpty from 'lodash/isEmpty';
import createStyle from './styleCss';



interface SpecialClaimDataList {
    popupHeader: string;
    popupNo: string;
    popupYes: string;
    popupTxt: string;
};
interface CardInfoProps {
    data: {
        id: string;
        iconImage?: string;
        cardTitle: string;
        cardDescription?: string;
        poweredByImg?: string;
        url?: string;
        actionTracker: string;
    };
    specialClaimDataList?: SpecialClaimDataList;
    onClickEventTracker: (arg: string) => void;
    raiseClaimClicked: (arg: SpecialClaimDataList, triggerEventTracker?: boolean) => void;
}
const CardInfo: React.FC<CardInfoProps> = ({ data, specialClaimDataList, raiseClaimClicked, onClickEventTracker }) => {
    if (isEmpty(data)) {
        return null;
    }
    const { cardTitle, cardDescription, iconImage, poweredByImg, id, url, actionTracker } = data;
    const specialClaimData = specialClaimDataList?.[0] || null;
    const { fsStyle, ...fonts } = getFont(true);
    const { psTheme: theme } = useTheme();
    const styles = createStyle(fonts, theme);

    const onClick = () => {
        onClickEventTracker(actionTracker);
        if (id === 'BusCancelledByOperator') {
            raiseClaimClicked(specialClaimData,false);
        } else {
            Actions.openPsWebView({
                url: url,
            });
        }
    }
    return (
        <TouchableOpacity style={styles.cardWrapper} onPress={onClick}>
            <View style={styles.contentWrapper}>
                {!!iconImage && <Image source={{ uri: iconImage }} style={styles.iconImageStyle} />}
                <View style={AtomicCss.flex1}>
                    {!!cardTitle && <Text style={styles.cardTitleStyle}>{cardTitle}</Text>}
                    <View style={styles.contentContainer}>
                        {!!cardDescription && <Text style={styles.cardDescriptionStyle}>{cardDescription}</Text>}
                        {!!poweredByImg && <Image source={{ uri: poweredByImg }} style={styles.poweredImgStyle} />}
                    </View>
                </View>
            </View>
            {!!rightArrow && <Image source={rightArrow} style={styles.grayArrow} />}
        </TouchableOpacity>
    );
};


export default CardInfo;