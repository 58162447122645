export const FLIGHT_BOOKING_ROUTE_KEYS = {
  FLIGHT_BOOKING_DETAIL: 'flightBookingDetail',
  FLIGHT_BOOKING_DETAILED_INFO: 'flightBookingDetailedInfo',
  FLIGHT_SELLING: 'flightSellingPage',
  FLIGHT_CANCELLATION_THANKYOU: 'flightCancellationThankyou',
  NEED_HELP_WEBVIEW: 'needHelpWebView',
  OPEN_WEB_VIEW: 'openWebView',
  ANCILLARY_FARE_BREAKUP: 'ancillaryFareBreakup',
  REVIEW_ANCILLARY: 'reviewAncillary',
  SELECT_ADD_ON: 'selectAddons',
  SELECT_SEAT: 'selectSeats',
  OPEN_ANCILLARY: 'openAncillary',
  WEB_CHECK_IN: 'webcheckin',
  ANCILLARY_SIMPLE_THANK_YOU: 'ancillarySimpleThankyou',
  TRAVEL_INSURANCE_THANK_YOU: 'travelInsuranceThankYou',
  PART_PAYMENT_THANK_YOU: 'partPaymentThankYou',
  FLIGHT_THANK_YOU_PAGE: 'thankyou',
  CANCEL_ANYWAY_SUCCESS: 'cancelAnywaySuccess',
  FLIGHT_CANCELLATION_REFUND_MODE: 'flightCancellationRefundMode',
  STATE_NAME_LIST: 'stateNameList',
  DATE_CHANGE_SUBMIT_REQUEST: 'dateChangeSubmitRequest',
  WEB_CHECKIN_COUNTRY_CODE_LIST: 'webCheckInCountryCodeList',
  PARTIAL_PAX_SELECTION: 'partialPaxSelection',
  WEB_CHECKIN_SINGLE_PAX_INFO: 'webCheckInSinglePaxInfo',
  WEB_CHECKIN_PAX_DETAILS: 'webCheckInPaxDetails',
  CANCEL_ANYWAY_REQUEST: 'cancelAnyWayRequest',
  FLIGHT_BOOKING_REFUND_BREAKUP: 'flightBookingRefundBreakup',
  CANCELLATION_ERROR_PAGE: 'cancellationErrorPage',
  SPECIAL_CLAIM_SELECT_PASSENGER: 'specialClaimSelectPassenger',
  SPECIAL_CLAIM_REVIEW_DETAILS: 'specialClaimReviewDetails',
  SELECT_REFUND_PAYMENT_MODE: 'selectRefundPaymentMode',
  SPECIAL_CLAIM_REQUEST_THANKYOU: 'specialClaimRequestThankYou',
  FALSE_CLAIM: 'falseClaim',
  AUTO_REFUND_ROUTER: 'autoRefundRouter',
  DATE_CHANGE_SUCCESS: 'dateChangeSucess',
  SUBMIT_PARTIAL_DATE_CHANGE: 'submitPartialDateChange',
  OPEN_PARTIAL_DATE_CHANGE: 'openPartialDateChange',
  FLIGHT_LISTING: 'flightListing',
  COMMON_SUBMIT_REQUEST: 'commonSubmitRequest',
  COMMON_SUBMIT_REQUEST_THANKYOU: 'commonSubmitRequestThankYou',
  COMMON_ERROR: 'commonError',
  FLIGHT_COMMON_ERROR: 'flightCommonError',
  FLIGHT_RESCHEDULE_FLIGHTS_PAGE: 'flightRescheduleFlightsPage',
  FLIGHT_RESCHEDULE_CHARGE_PAGE: 'flightRescheduleChargePage',
  FLIGHT_CANCEL_DONE: 'flightCancelDone',
  AIRLINE_CONTACT_INFO: 'airlineContactInfo',
  NAME_CHANGE_THANKYOU_NEW: 'nameChangeThankYouNew',
  FLIGHT_CANCEL_REVIEW: 'flightCancelReview',
  CORP_FLIGHT_RESCHEDULE_REVIEW_PAGE: 'corpFlightRescheduleReviewPage',
  FLIGHT_RESCHEDULE_REVIEW_PAGE: 'flightRescheduleReviewPage',
  FLIGHT_PAYMENT_PRICE_BREAKUP: 'flightPaymentPriceBreakUp',
  FLIGHT_CANCELLATION_PAGE: 'flightCancellationPage',
  SPECIAL_CLAIM_REASONS: 'specialClaimReasons',
  FLIGHT_MODIFICATION_OPTION: 'flightModificationOption',
  FLIGHT_CANCELLATION_OPTION: 'flightCancellationOption',
  FLIGHT_CANCELLATION_POLICY: 'flightCancellationPolicy',
  FLIGHT_BOOKING_POLICY_ERROR: 'flightBookingPolicyError',
  TRIP_PROTECTION_POLICY: 'tripProtectionPolicy',
  BAGGAGE_PROTECTION_POLICY: 'baggageProtectionPolicy',
  NAME_CHANGE_CLOSE_TO_DEPARTURE: 'nameChangeCloseToDeparture',
  NAME_CHANGE_SELECT_PAX_NEW: 'nameChangeSelectPaxNew',
  REFUND_DELAY_INFO: 'refundDelayInfo',
  FLIGHT_REFUND_BREAKUP: 'fltRefundBreakup',
  ANCILLARY_ERROR: 'ancillaryError',
  ANCILLARY_TY: 'ancillaryThankyou',
  FLIGHT_THANK_YOU: 'flightCommonThankYou',
  CLAIM_ANCILLARY_REFUND: 'claimAncillaryRefund',
  SPECIAL_CLAIM_NEW: 'specialClaimNew',
  SPECIAL_CLAIM_V2: 'specialClaimV2',
  PS_COMMON_ERROR: 'psCommonError',
  SPECIAL_CLAIM_ERROR: 'specialClaimError',
  INSURANCE_THANK_YOU: 'insuranceThankyou',
  FLIGHT_REFUND_BREAKUP_V2: 'fltRefundBreakupV2',
  LAST_MINUTE_CANCELLATION: 'lastMinuteCancellation',
  ERROR_IN_CANCELLATION: 'errorInLastMinuteCancellation',
  REFUND_TRACKER_FLIGHTS: 'refundTrackerFlights',
};
