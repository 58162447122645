import React from 'react';
import _ from 'lodash';
import {View, Text, Image} from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import ChangePlans from './ChangePlans';
import getStyles from '../styles/BusMamiCss';
import {getImgSource, rendorForEachElement, cancellationStyles} from '../../utils/CancellationPolicyCardUtils';
import {cancellationIcon} from '../BusImageConstants';
import { label } from '@mmt/post-sales/src/staticData/staticData';
import { getFont } from '../../PostSalesStyles';

class BusCancellationPolicyCard extends React.Component {
    
    getViewsList() {
      const list = this.props.response.busCancellationPolicyList[0].cancellationPolicy
      let viewsList = [];
      for (let index = list.length - 1; index >= 0; index--) {
        let [imgSource, currentTimeline] = getImgSource(list[index].cancellationPolicyStartTime, list[index].cancellationPolicyEndTime)
        let lastRow = index === 0;
        viewsList.push(rendorForEachElement(imgSource, list[index].cancellationTimelineTitle, list[index].penaltyAmount, lastRow, currentTimeline))
      }
      return viewsList
    }

    getFooterViewsList() {
      let viewsList = [];
      
      this.props.response.clientDetails.busCancellationPolicyFooter.map(footer => {
        viewsList.push(
          <View style={([AtomicCss.defaultTextColor, AtomicCss.lineHeight18], {flexDirection: 'row', marginTop: 10})}>
            <Text>&#9679; </Text>
            <Text>{footer}</Text>
          </View>
        )
      });

      return viewsList;
    }

    cancellationPolicyPresent() {
      return !_.isEmpty(this.props.response.busCancellationPolicyList) && 
      !_.isEmpty(this.props.response.busCancellationPolicyList[0].cancellationPolicy)
    }

    render() {
      const fonts = getFont(true);
      const styles = getStyles();

      return (
        <View style={cancellationStyles.CancellationPolicyCardWrapper}>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <Image
              style={styles.CardIcon}
              source={cancellationIcon}
            />
            <View style={styles.ContentPortion}>
              <Text style={[fonts.blackFontFamily, styles.BlackText]}>{this.props.headText}</Text>
            </View>
          </View>
          <View style={[AtomicCss.paddingLeft25, AtomicCss.paddingBottom8, AtomicCss.paddingTop10]}>
            {
              !this.cancellationPolicyPresent() &&
              !_.isEmpty(this.props.response.clientDetails) && !_.isEmpty(this.props.response.clientDetails.busCancellationPolicyHeader) && 
              this.props.response.clientDetails.busCancellationPolicyHeader.trim() != "" &&
              <Text style={[AtomicCss.defaultTextColor, AtomicCss.lineHeight18]}>
                {this.props.response.clientDetails.busCancellationPolicyHeader}
              </Text>
            }
          </View>
          {
            this.cancellationPolicyPresent() &&
            <View style = {AtomicCss.paddingTop10}>
              <View style={cancellationStyles.trackerHeadingRow}>
                <Text style={[cancellationStyles.trackerHeading, fonts.boldFontFamily, fonts.font14]}>{label('bus.cancellation.timeOfCancel')}</Text>
                <Text style={[fonts.boldFontFamily, AtomicCss.blackText, AtomicCss.paddingRight10, fonts.font14]}>{label('bus.cancellation.charges')}</Text>
              </View>
              {this.getViewsList()}
              {
                !_.isEmpty(this.props.response.clientDetails) && !_.isEmpty(this.props.response.clientDetails.busCancellationPolicyFooter) &&
                this.props.response.clientDetails.busCancellationPolicyFooter.length != 0 &&
                this.getFooterViewsList()
              }
            </View>
          }
          <View style = {AtomicCss.paddingTop20}>
          {
            <ChangePlans
            key="ChangePlansCard"
            response={this.props.response}
            pageName={this.props.trackPageName}
            card={this.props.card}
            mmtAuth={this.props.mmtAuth}
            loggingTrackInfo={this.props.loggingTrackInfo}
            fromCancellationPolicy = {true}
            confirmCancelOverlay = {this.props.confirmCancelOverlay}
          />
          }
          </View>
        </View>
      );
    }
}

export default BusCancellationPolicyCard;