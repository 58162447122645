import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
import { StyleSheet } from 'react-native';


export const getWhiteCardStyles = (color) => {
    return StyleSheet.create({
      whiteCard: {
        marginBottom: 10,
        marginHorizontal: 12,
        backgroundColor: color.white,
        ...getPlatformElevation(1),
        borderRadius: 8,
      }
    })
  }