import {
    BASIC_AUTH_CREDENTIALS,
    PROMO_OFFERS_API_AUTH,
    BASIC_AUTH_CREDENTIALS_ACME,
    BASIC_AUTH_CREDENTIALS_HLD,
    BASIC_AUTH_CREDENTIALS_FLIGHTS,
    BASIC_AUTH_CREDENTIALS_HOTELS,
    BASIC_AUTH_CREDENTIALS_TRIP_ASSISTANT
} from '@env';

const EnvConfig = {
    BASIC_AUTH_CREDENTIALS: JSON.parse(BASIC_AUTH_CREDENTIALS),
    PROMO_OFFERS_API_AUTH: JSON.parse(PROMO_OFFERS_API_AUTH),
    BASIC_AUTH_CREDENTIALS_ACME: JSON.parse(BASIC_AUTH_CREDENTIALS_ACME),
    BASIC_AUTH_CREDENTIALS_HLD: JSON.parse(BASIC_AUTH_CREDENTIALS_HLD),
    BASIC_AUTH_CREDENTIALS_FLIGHTS: JSON.parse(BASIC_AUTH_CREDENTIALS_FLIGHTS),
    BASIC_AUTH_CREDENTIALS_HOTELS: JSON.parse(BASIC_AUTH_CREDENTIALS_HOTELS),
    BASIC_AUTH_CREDENTIALS_TRIP_ASSISTANT: JSON.parse(BASIC_AUTH_CREDENTIALS_TRIP_ASSISTANT)
};

export default EnvConfig