import { StyleSheet } from "react-native";

const createStyles = (theme, fonts) => StyleSheet.create({
    pageWrapper: {
      flex: 1,
    },
    cardsWrapper: {
      backgroundColor: theme.color.lighterBlue,
      top: 0,
      marginBottom: 20,
    },
    footerBtnWrapper: {
      position: 'absolute',
      zIndex: 999,
      bottom: 0,
      left: 0,
      width: '100%',
    },
    overlayContentWrapper: {
      backgroundColor: theme.color.white,
      padding: 30,
      position: 'relative',
      zIndex: 1,
      width: '80%',
      borderRadius: 4,
      height: 100,
    },
    overlayErrorText: {
      ...fonts.boldFontFamily,
      fontSize: fonts.fontSize.font14,
    },
    overlayContentExtraStyle: {
      marginBottom: 60,
      padding: 0,
    }

  });
export default createStyles;
