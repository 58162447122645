import React from "react";
import {appendUrlPrefix} from "../../webUtils";
import loadable from "@loadable/component";
import ProgressView from "../../components/ProgressView";

const SELF_DRIVE_PATHS = {
    SELF_DRIVE_BOOKING_DETAIL: {
        pathName: appendUrlPrefix('/selfdrive'),
        pageKey: "selfDriveBookingDetail",
        Component: loadable(() => import("@rn/apps/post-sales/src/selfDrive/selfDriveBookingDetail"), { fallback: <ProgressView /> })
    },
    SELF_DRIVE_PRICE_BREAKUP : {
        pathName: appendUrlPrefix('/selfDrivePriceBreakUp'),
        pageKey: "selfDrivePriceBreakUp",
        Component: loadable(() => import("@rn/apps/post-sales/src/selfDrive/components/DetailPage/SelfDrivePriceBreakUp/PaymentPriceBreakup"), { fallback: <ProgressView /> })
    },
    SELF_DRIVE_POLICIES_WEB_VIEW : {
        pathName: appendUrlPrefix('/mpostsales/rnw/openWebVieww'),
        pageKey: "policiesWebView",
        Component: loadable(() => import("@rn/apps/post-sales/src/Common/WebViewWrapper"), { fallback: <ProgressView /> })
    },
    SELF_DRIVE_MORE_INFO : {
        pathName: appendUrlPrefix('/moreInfoWindow'),
        pageKey: "moreInfoWindow",
        Component: loadable(() => import("@rn/apps/post-sales/src/selfDrive/components/DetailPage/inclusionExclusionPage/index"), { fallback: <ProgressView /> })
    },
    SELF_DRIVE_DOWNLOAD_APP : {
        pathName: appendUrlPrefix('/getInfo'),
        pageKey: "downloadApp",
        Component: loadable(() => import("@rn/apps/post-sales/src/selfDrive/components/DetailPage/downloadApp/index"), { fallback: <ProgressView /> })
    },
    SELF_DRIVE_CANCELLATION_DETAILS : {
        pathName: appendUrlPrefix('/selfDriveCancellationDetails'),
        pageKey: "selfDriveCancellationDetails",
        Component: loadable(() => import("@rn/apps/post-sales/src/selfDrive/components/Cancellation/SelfDriveCancellationDetails"), { fallback: <ProgressView /> })
    },
    SELF_DRIVE_CANCELLATION_REFUND_MODES : {
        pathName: appendUrlPrefix('/selfDriveCancellationRefundMode'),
        pageKey: "selfDriveCancellationRefundMode",
        Component: loadable(() => import("@rn/apps/post-sales/src/selfDrive/components/Cancellation/SelfDriveCancellationRefundMode"), { fallback: <ProgressView /> })
    },
    SELF_DRIVE_CANCELLATION_REQUEST : {
        pathName: appendUrlPrefix('/SelfDriveCancelRequest'),
        pageKey: "SelfDriveCancelRequest",
        Component: loadable(() => import("@rn/apps/post-sales/src/selfDrive/components/Cancellation/SelfDriveCancelRequest"), { fallback: <ProgressView /> })
    }
}

export default SELF_DRIVE_PATHS;
