import React, { useMemo, useRef, useEffect, useReducer, useState } from 'react';
import { Platform, View } from 'react-native';
import { isEmpty } from 'lodash';
import { fetchPageData } from './data/api';
import ProgressView from '../../Common/ProgressView/ProgressView';
import plusIcon from '@mmt/legacy-assets/src/plusIcon.webp';
import { getStaticData, getCurrentLangStaticData } from '../../staticData/staticData';
import { OmnitureConstants, PAGE_BOOKINGS_STATUS, ViewState } from './summaryConstants';
import createStyles from './summaryCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { summaryReducer, INITIAL_STATE } from './data/bookingSummaryReducer';
import { MyTripsLangSwitchCard } from '../index';
import AddBookingBottomOverlay from '../components/AddBooking/AddBookingBottomOverlay';
import addBookingTrackingHelper, { ADD_BOOKING_TRACKING_EVENTS } from '../components/AddBooking/tracking';
import MyTripsHeader from './components/MyTripsHeader';
import { getBookingCount, renderCardList } from './utils';
import BookingSummarySectionList from './components/BookingSummarySectionList';
import WhereToGo from './pages/Wheretogopage';
import EmptyDataScreen from './components/EmptyDataScreen';
import flatten from 'lodash/flatten';
import { getSummaryStateArray, getShowMoreData, getMsrTicketsData, getMyTripsCardsInfo, getFilteredData } from './data/dataAdapter';
import FiltersTab from './pages/Filters';
import { useTheme } from '../../theme/theme.context';
import { AllFiltersDataType, BookingIdToTicketDetailMapType, BookingsType, SelectedFilter, MyRequestsCardDataInterface, LostIdBookingData } from './types';
import TripSummaryOmnitureTracker from './TripSummaryOmnitureTracker';
import NoUpcomingText from './pages/Wheretogopage/components/NoUpcomingText';
import { GlobalMessageBoxProps } from './components/cards/GlobalMessageBox/types';
import GlobalMessageBox from './components/cards/GlobalMessageBox';

const BookingSummary: React.FC = (props) => {
  const [viewState, setViewState] = useState<string>(ViewState.LOADING);
  const [summaryState, dispatch] = useReducer(summaryReducer, INITIAL_STATE);
  const [bookings, setBookings] = useState<BookingsType | null>(null);
  const [filtersInitialData, setFiltersInitialData] = useState<AllFiltersDataType | null>(null);
  const [bookingIdtoTicketDetailmap, setTicketsMap] = React.useState<BookingIdToTicketDetailMapType | []>([]);
  const [myRequestsCardData, setMyRequestsCardData] = React.useState<MyRequestsCardDataInterface | null>(null);
  const [showBookingOverlay, setShowBookingOverlay] = useState(false);
  const bookingSummarySectionListRef = useRef(null);
  const hideBack = props?.hideBack === 'true';
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme);
  const staticData = getCurrentLangStaticData();
  const { bookingSummaryText, refreshText } = staticData;
  const {
    otherLanguageBookingInfo,
    bookingsInOtherLanguage,
    cardDetail = {},
    addBookingCTA,
  } = bookings || {} as BookingsType;


  const selectedFilters = useRef<SelectedFilter[]>([]);

  const [stickyBottomCard] = useMemo(
    () => getMyTripsCardsInfo(cardDetail.bottomCardList || []),
    [cardDetail.bottomCardList],
  );

  const globalPersuasion = useRef<GlobalMessageBoxProps>(null);

  const handleAddBookingOverlay = (val: boolean, source: string) => {
    if (val && source === 'top') {
      addBookingTrackingHelper.trackClickEvent(
        ADD_BOOKING_TRACKING_EVENTS.Mytrips_topbar_addbooking_clicked,
        bookings && bookings.uuid,
      );
    } else if (val && source === 'bottom') {
      addBookingTrackingHelper.trackClickEvent(
        `Mytrips_bottom_addbooking_clicked`,
        bookings && bookings.uuid,
      );
    }
    setShowBookingOverlay(val);
  };

  const loadTicketsData = async () => {
    const data = await getMsrTicketsData();
    if (data && data.myRequestsCardData) {
      setMyRequestsCardData(data.myRequestsCardData);
    }
    if (data && data.bookingIdtoTicketDetailmap) {
      setTicketsMap(data.bookingIdtoTicketDetailmap);
    }
  };

  useEffect(() => {
    loadTicketsData();
  }, []);

  const setBookingsData = ({
    _bookings,
    bookingStatus = 'ALL',
    pageNumber = 0,
    filters = null,
    prevState = { ...summaryState },
    loadMore = false,
    filteredPageNumber = 0,
    lostIdBookingData = {},
    planningsData = {}
  }) => {
    if (filters) {
      selectedFilters.current = filters;
    }

    if (_bookings.responseCode === 'FAILED') {
      if (!loadMore) {
        setViewState(ViewState.ERROR);
      }
    } else {
      setBookings(_bookings);
      let currentStatusOldBookings = prevState[bookingStatus] && prevState[bookingStatus].bookings;
      const oldBookings = currentStatusOldBookings
        ? flatten(currentStatusOldBookings.map((monthBooking) => monthBooking.data))
        : currentStatusOldBookings;
      const payload = isEmpty(filters)
        ? getFilteredData(_bookings, oldBookings, pageNumber, bookingStatus, lostIdBookingData, planningsData)
        : getFilteredData(_bookings, oldBookings, pageNumber, bookingStatus, lostIdBookingData, planningsData, filteredPageNumber);
      dispatch({
        type: bookingStatus,
        payload,
      });
      if (bookingStatus === PAGE_BOOKINGS_STATUS.ALL) {
        setViewState(ViewState.LOADED);
      } else {
        setViewState(ViewState.SHOW_MORE_LOADED);
      }
    }
  };

  const loadPageData = async () => {
    setViewState(ViewState.LOADING);
    const { bookingsData, lostIdBookingData, planningsData } = await fetchPageData(summaryState);
    const { _bookings } = bookingsData || {};
    const { filters, persuasion } = _bookings || {};
    if (!!persuasion && !globalPersuasion.current) {
      globalPersuasion.current = persuasion;
    }
    if (bookingsData && bookingsData.error) {
      setViewState(ViewState.ERROR);
    }
    else if (bookingsData) {
      setBookingsData({ ...bookingsData, lostIdBookingData: lostIdBookingData, planningsData: planningsData });
      filters && setFiltersInitialData(filters);
    }
  };

  useEffect(() => {
    loadPageData();
    TripSummaryOmnitureTracker.trackLoadEvent(bookings);
  }, []);


  const handleShowMoreData = async (bookingStatus: string, page: number) => {
    setViewState(ViewState.SHOW_MORE_LOADING);
    const bookingsData = await getShowMoreData(bookingStatus, page, summaryState, selectedFilters);
    if (bookingsData && bookingsData.error && bookingsData.viewState) {
      setViewState(bookingsData.viewState);
    }
    else if (bookingsData) {
      setBookingsData(bookingsData);
    }
  };

  const loadPageDataWithMSRTickets = ()=> {
    loadPageData();
    loadTicketsData();
  }

  if (viewState === ViewState.LOADING) {
    const { loadingYourTripText } = getStaticData(true, true);
    return (
      <View style={AtomicCss.flex1}>
        <ProgressView message={loadingYourTripText} />
      </View>
    );
  }

  if (viewState === ViewState.ERROR) {
    const { additionalText } = bookingSummaryText || {};
    const { noBookingErrorText } = additionalText || {};
    TripSummaryOmnitureTracker.trackErrorEvent(
      'mob:error:customer support:APIResponse',
      OmnitureConstants.NO_BOOKINGS_ERROR_TEXT,
    );
    return (
      <View style={AtomicCss.flex1}>
        <MyTripsHeader hideBack={hideBack} />
        <EmptyDataScreen
          loadData={loadPageDataWithMSRTickets}
          mainText={noBookingErrorText}
          buttonText={refreshText}
        />
      </View>
    );
  }

  const handleScrollToTop = () => {
    // Call the scrollToTop method on the child component
    if (bookingSummarySectionListRef.current) {
      bookingSummarySectionListRef.current.scrollToTop();
    }
  };

  const handleFilterFetchedBookings = (...args) => {
    handleScrollToTop();
    setBookingsData(...args);
  }

  const renderBookingList = () => {
    const summaryStateArray = getSummaryStateArray(summaryState);
    const persuasionObj = globalPersuasion.current;
    return (
      <>
        <View style={styles.mainWrapper}>
          <MyTripsHeader
            hideBack={hideBack}
            headerData={{
              uuid: bookings && bookings.uuid,
              plusIcon: plusIcon,
              handleAddBookingOverlay,
              showBookingOverlay,
            }}
            addBookingCTA={addBookingCTA}
          />
          {Platform.OS !== 'web' && (
            <MyTripsLangSwitchCard
              bookingsInOtherLanguage={bookingsInOtherLanguage}
              otherLanguageBookingInfo={otherLanguageBookingInfo}
            />
          )}
          {getBookingCount(summaryState) === 0 && isEmpty(selectedFilters.current) ? (
            <WhereToGo />
          ) : (
            <>
              {!isEmpty(filtersInitialData) && !isEmpty(filtersInitialData.sections) && (
                <>
                  <FiltersTab
                    allFilterData={filtersInitialData}
                    data={filtersInitialData?.sections}
                    handleFetchedBookings={handleFilterFetchedBookings}
                    selectedFilters={selectedFilters.current}
                    planningsData={summaryState[PAGE_BOOKINGS_STATUS.PLANNING]}
                    lostIdBookingData={summaryState[PAGE_BOOKINGS_STATUS.AWAITING]}
                  />
                  {getBookingCount(summaryState) === 0 && !isEmpty(selectedFilters.current) && <NoUpcomingText isFilterSelected={true} />}
                </>
              )}
              <BookingSummarySectionList
                ref={bookingSummarySectionListRef}
                summaryStateArray={summaryStateArray}
                summaryState={summaryState}
                myRequestsCardData={myRequestsCardData}
                bookingIdtoTicketDetailmap={bookingIdtoTicketDetailmap}
                loadShowMoreData={handleShowMoreData}
                selectedFilters={selectedFilters.current}
                showLoadMoreLoader={viewState === ViewState.SHOW_MORE_LOADING}
              />
            </>
          )}
          {!!persuasionObj && <GlobalMessageBox {...persuasionObj} />}
          {!isEmpty(stickyBottomCard) &&
            renderCardList(
              stickyBottomCard,
              addBookingCTA,
              handleAddBookingOverlay,
              theme
            )}
          {showBookingOverlay && (
            <AddBookingBottomOverlay
              handleOverlay={handleAddBookingOverlay}
              uuid={bookings && bookings.uuid}
              response={addBookingCTA}
            />
          )}
        </View>
      </>
    );
  };

  if (viewState === ViewState.LOADED || viewState === ViewState.SHOW_MORE_LOADING || viewState === ViewState.SHOW_MORE_LOADED) {
    return renderBookingList();
  } else {
    return null;
  }
};

export default BookingSummary;