import React from 'react';
import Fecha from 'fecha';
import WhiteFullCapsuleButton from '../hotel/details/ActionButton/WhiteFullCapsuleButton';
import styles from './styles/MetroMamiCss';
import CapsuleBtn from '../hotel/details/ActionButton/CapsuleBtn';
import CapsuleBtnFill from '../hotel/details/ActionButton/CapsuleBtnFill';
import RectFullButton from '../hotel/details/ActionButton/RectFullButton';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import MetroBookingTrackingHelper from './MetroBookingTrackingHelper';
import {Image, Linking} from 'react-native';
import RoundButtonWithIcon from '../hotel/details/ActionButton/RoundButtonWithIcon';
import MetroConstant from './MetroConstant';
import Actions from '../navigation/postSalesNavigation';
import {isNullOrEmpty, openDialer} from '../Common/commonUtil';
import {HEADER_ICON,GREEN_REFUND_ICON,RED_REFUND_ICON,BLACK_REFUND_ICON, ZERO_CANCELLATION_ICON, DB_ICON_SM_ICON, RED_INFO_ICON_SM_ICON, IC_TICK, DOUBLE_TICK_1} from './MetroImageConstant';

export const REFUND_TRACKER = {
  refundInitiated: 'Refund Initiated',
  refundProcessed: 'Refund Processed',
  refundSuccess: 'Refund Success'
};

export function getTicketObject(response, ticket) {
  let ticketObject = ticket;
  ticketObject.bookingId = response.bookingId;
  ticketObject.url = response.bookingDeepLink;
  ticketObject.title= `${response.bookingInfo.metroCity} Metro Ticket`;
  ticketObject.message= `${response.bookingInfo.metroCity} Metro Ticket`;
  ticketObject.subject= `${response.bookingInfo.metroCity} Metro Ticket`;

  return ticketObject;
}

export function getRefundSubTitle(response) {
  let textTitle = '';
  if (response.refundDetail) {
    const amt = response.refundDetail.amount;
    textTitle = `₹ ${amt} has been refunded to your original paymode`;
  }
  return textTitle;
}

export function fillDepartureArrivalDate(date, outputDateFormat) {
  return Fecha.format(new Date(date), outputDateFormat);
}
export function isNotNullAndEmpty(object) {
  return (object != null && object.length > 0);
}
export function getActionButton(card, response, clickHandler) {
  const actionButtons = [];
  for (const action of card.actionList) {
    addActionButton(card, action, actionButtons, response, clickHandler);
  }
  return actionButtons;
}

export function getButtonStyle(actionList) {
  const {actionType} = actionList[0];
  if (actionType === MetroConstant.RECTANGLE_PRIMARY_HALF_WIDTH
        || actionType === MetroConstant.RECTANGLE_SECONDARY_HALF_WIDTH
        || actionType === MetroConstant.ROUND_BUTTON) {
    return [styles.ActionBtnHalf, styles.transperantBgrnd];
  } else if (actionType === MetroConstant.RECTANGLE_FULL_BUTTON) {
    return [styles.ActionBtnRec, styles.transperantBgrnd];
  }
  return [styles.ActionBtn, styles.transperantBgrnd];
}

export function getRefundProgressStyle(refundStatus) {
  const progressStyles = [];
  switch (refundStatus) {
    case REFUND_TRACKER.refundInitiated:
      progressStyles.push(styles.refundHalfbar);
      break;
    case REFUND_TRACKER.refundProcessed:
    case REFUND_TRACKER.refundSuccess:
      progressStyles.push(styles.refundFullbar);
      break;
    default:
      break;
  }
  progressStyles.push(styles.refundGreenBackground);
  return progressStyles;
}

export function getRefundIcons(refundStatus) {
  const image = [];
  image.push(<Image
    key="PromiseSinleTickImage"
    style={styles.aminitiesIcon}
    source={IC_TICK}
  />);
  switch (refundStatus) {
    case REFUND_TRACKER.refundProcessed:
    case REFUND_TRACKER.refundSuccess:
      image.push(<Image
        key="PromiseDoubleTickImage"
        style={styles.aminitiesIcon}
        source={DOUBLE_TICK_1}
      />);
      break;
    default:
      break;
  }
  return image;
}

export function getRefundStatusLine(refundStatus) {
  let refundStatusLine = MetroConstant.IN_PROGRESS;
  switch (refundStatus) {
    case REFUND_TRACKER.refundInitiated:
      refundStatusLine = MetroConstant.IN_PROGRESS;
      break;
    case REFUND_TRACKER.refundProcessed:
    case REFUND_TRACKER.refundSuccess:
      refundStatusLine = MetroConstant.COMPLETE;
      break;
    default:
      break;
  }
  return refundStatusLine;
}

export function getTitle(response) {
  return `${response.bookingInfo.source} - ${response.bookingInfo.destination}`
}

export function getSubTitle(response) {
  const oneWayTrip = response.bookingInfo.serviceType === "FORWARD";
  const tripType = oneWayTrip ? "One Way" : "Return";
  const expiryDate = Fecha.parse(response.bookingInfo.travelDateTime, 'DD-MMM-YYYY HH:mm A');
  const expiryDateString = `${Fecha.format(expiryDate, 'DD MMM')}`;
  return  expiryDateString + ' • ' + tripType;
}

export async function handleClickEvent(card, action, response, pageName) {
  switch (action.actionFamily) {
    case MetroConstant.ACTION_CAB_CALL_US:
    case MetroConstant.ACTION_CALL_HELPLINE:
      openDialer(MetroConstant.HELPLINE_NUMBER);
      MetroBookingTrackingHelper.trackClickEvent(pageName, MetroConstant.CLICK_CALL);
      break;
    case MetroConstant.JOURNEY_TYPE:
      MetroBookingTrackingHelper.trackClickEvent(pageName, MetroConstant.JOURNEY_TYPE);
      break;
    case MetroConstant.PASSENGER_TAB:
      MetroBookingTrackingHelper.trackClickEvent(pageName, MetroConstant.PASSENGER_TAB);
      break;
    case MetroConstant.SHARE_TICKET:
      MetroBookingTrackingHelper.trackClickEvent(pageName, MetroConstant.SHARE_TICKET);
      break;
    case MetroConstant.ACTION_BOOK_AGAIN:
      GenericModule.openDeepLink(MetroConstant.BOOK_AGAIN_LINK);
      MetroBookingTrackingHelper.trackClickEvent(pageName, MetroConstant.CLICK_BOOKAGAIN);
      break;
    default:
      break;
  }
}

export function addActionButton(card, action, actionButtons, response, clickHandler) {
  switch (action.actionType) {
    case MetroConstant.WHITE_BUTTON_FULL:
      actionButtons.push(<WhiteFullCapsuleButton
        key={`WhiteFullCapsuleButton${action.actionLabeltext}`}
        card={card}
        action={action}
        response={response}
        clickHandler={clickHandler}
      />);
      break;
    case MetroConstant.RECTANGLE_PRIMARY_FULL_WIDTH:
    case MetroConstant.RECTANGLE_PRIMARY_HALF_WIDTH:
      actionButtons.push(<CapsuleBtnFill
        key={`CapsuleBtnFill${action.actionLabeltext}`}
        card={card}
        action={action}
        response={response}
        clickHandler={clickHandler}
      />);
      break;
    case MetroConstant.RECTANGLE_SECONDARY_FULL_WIDTH:
    case MetroConstant.RECTANGLE_SECONDARY_HALF_WIDTH:
      actionButtons.push(<CapsuleBtn
        key={`CapsuleBtn${action.actionLabeltext}`}
        card={card}
        action={action}
        response={response}
        clickHandler={clickHandler}
      />);
      break;
    case MetroConstant.RECTANGLE_FULL_BUTTON:
      actionButtons.push(<RectFullButton
        key={`RectFullButton${action.actionLabeltext}`}
        card={card}
        action={action}
        response={response}
        clickHandler={clickHandler}
      />);
      break;
    case MetroConstant.ROUND_BUTTON:
      actionButtons.push(<RoundButtonWithIcon
        key={`RoundButtonWithIcon${action.actionLabeltext}`}
        card={card}
        action={action}
        response={response}
        clickHandler={clickHandler}
      />);
      break;
    default:
      break;
  }
}
export const goToBookingDetail = (bookingId, mmtAuth) => {
  const params = {};
  params.BOOKING_ID = bookingId;
  params.MMT_AUTH = mmtAuth;

  Actions.metroBookingDetail({data: params});
};

export function onPartialCancel(detailResponse) {
  Actions.metroCancelSelectTicket({
    detailResponse: detailResponse,
    headerTitle: "Cancel Metro Booking",
    headerSubTitle: getTitle(detailResponse),
    isFullCancel: false
  });
}

export function onFullCancel(detailResponse) {
  Actions.metroCancelReviewRefundCharge({
    detailResponse: detailResponse,
    headerTitle: "Cancel Metro Booking",
    purchaseId: detailResponse.bookingInfo.purchaseId,
    headerSubTitle: getTitle(detailResponse),
    ticketLineNoList: getTicketLineNo(detailResponse),
    isFullCancel: true
  });
}

export function getTicketLineNo(detailResponse) {
  return detailResponse.bookingInfo.ticketInfoList.map(ticket => ticket.lineNo);
}

export const getImageIcon = (imgName) => {
  if (imgName) {
    switch (imgName) {
      case 'REFUND':
        return GREEN_REFUND_ICON;
      case 'NO_REFUND':
        return RED_REFUND_ICON;
      case 'PART_REFUND':
        return BLACK_REFUND_ICON;
      case 'ZC':
      case 'ZC_BENEFIT':
      case 'ZC_EXPIRED':
        return ZERO_CANCELLATION_ICON;
      case 'DB':
      case 'DB_EXPIRED':
      case 'DB_BENEFIT':
        return DB_ICON_SM_ICON;
      case 'RED_BUBBLE':
        return RED_INFO_ICON_SM_ICON;
      default:
        return undefined;
    }
  }
  return undefined;
};

export function isPaxAlreadySelected(selectedTickets) {
  let count = 0;
  Object.keys(selectedTickets)
    .forEach((key) => {
      if (selectedTickets[key] === true) {
        ++count;
      }
    });
  return count;
}
export function getSelectedTicketKey(selectedTickets) {
  const array = [...selectedTickets.keys()];
  return array[0];
}
export function getSelectedTicketIds(selectedTickets) {
  const array = [...selectedTickets.keys()];
  return array;
}

export function getSelectedPaxSize(selectedTickets) {
  const list = [...selectedTickets.values()];
  return list.filter(v => v === true).length;
}


export function getCancellationRequestNoForMetro(cancellationDetailList) {
  const cancellationRequestNoList = [];
  if (isNotNullAndEmpty(cancellationDetailList) && cancellationDetailList instanceof Array) {
    cancellationDetailList.forEach((cancellationDetail) => {
      cancellationRequestNoList.push(cancellationDetail.requestNo);
    });
  } else if (!isNullOrEmpty(cancellationDetailList)) {
    cancellationRequestNoList.push(cancellationDetailList.requestNo);
  }
  return cancellationRequestNoList;
}

