import React from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import styles from './checkListCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import LinearGradient from 'react-native-linear-gradient';
import { MY_SAFETY_LOGO, WHITE_CROSS_ICON } from '../../../../FlightImageConstants';
import { getFont } from '../../../../../PostSalesStyles';

const CheckListOverlay = ({ checkList, handleBottomOverlay, checklistActionHandler }) => {
  const { fsStyle, ...fonts } = getFont(true);
  return (
    <View style={styles.WebCheckinOverlayWrapper}>
      <TouchableOpacity style={styles.crossIconActionWrapper} onPress={handleBottomOverlay}>
        <Image style={styles.crossIconStyle} source={WHITE_CROSS_ICON} />
      </TouchableOpacity>
      <LinearGradient
        start={{ x: 1.0, y: 0.0 }}
        end={{ x: 0.0, y: 1.0 }}
        colors={['#e8fde9', '#ffffff']}
        style={[styles.headingDtls]}
      >
        <Text
          style={[
            fsStyle.font16,
            AtomicCss.blackText,
            fonts.blackFontFamily,
            AtomicCss.marginBottom5,
            { maxWidth: 300 },
          ]}
        >
          {checkList.header}
        </Text>
        {checkList.showIcon && <Image style={styles.MY_SAFETY_LOGO} source={MY_SAFETY_LOGO} />}
      </LinearGradient>
      <View style={styles.checkListMain}>
        {checkList.itemlist &&
          checkList.itemlist.map((item, index) => (
            <View
              style={
                index === checkList.itemlist.length - 1 ? styles.lastEleBorO : styles.sectionRow
              }
            >
              <View style={styles.countWrapper}>
                <Text style={[styles.countingTxt, fonts.boldFontFamily, fsStyle.font12]}>
                  {index + 1}
                </Text>
              </View>
              <View
                style={[
                  styles.dtlsSection,
                  index === checkList.itemlist.length - 1 ? { borderBottomWidth: 0 } : {},
                ]}
              >
                <View style={AtomicCss.flex1}>
                  <Text
                    style={[
                      fonts.boldFontFamily,
                      AtomicCss.blackText,
                      fsStyle.font12,
                      AtomicCss.marginBottom5,
                      AtomicCss.alignLeft,
                    ]}
                  >
                    {item.title}
                  </Text>
                  {item.desc &&
                    item.desc.map((text) => (
                      <Text
                        style={[
                          fonts.regularFontFamily,
                          AtomicCss.defaultText,
                          fsStyle.font12,
                          AtomicCss.alignLeft,
                        ]}
                      >
                        {text}
                      </Text>
                    ))}
                </View>
                {item.action && item.action.title && (
                  <TouchableOpacity onPress={() => checklistActionHandler(item.action)}>
                    <Text style={[AtomicCss.azure, fsStyle.font14, fonts.boldFontFamily]}>
                      {item.action.title}
                    </Text>
                  </TouchableOpacity>
                )}
              </View>
            </View>
          ))}
      </View>
    </View>
  );
};

export default CheckListOverlay;
