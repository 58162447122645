import React from 'react';
import {Text} from 'react-native';
import getStyles from './HotelMamiCss';
import {appendZero} from '../utils/HotelBookingDetailUtil';
import {getTimerTextColor} from '../../../utils/MyPromiseUtil';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

class Timer extends React.Component {
  constructor(props) {
    super(props);
    this.fonts = getFont(true);
    this.styles = getStyles(this.fonts);
    this.state = {time: {}, seconds: this.props.seconds, textColor: getTimerTextColor(this.props.promiseStatus)};
    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countUp = this.countUp.bind(this);
    this.startTimer();
  }

  secondsToTime(secs) {
    const hours = Math.floor(secs / (60 * 60));
    const divisor_for_minutes = secs % (60 * 60);
    const minutes = Math.floor(divisor_for_minutes / 60);
    const divisor_for_seconds = divisor_for_minutes % 60;
    const seconds = Math.ceil(divisor_for_seconds);
    const obj = {
      h: hours,
      m: minutes,
      s: seconds
    };
    return obj;
  }

  componentDidMount() {
    const timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({time: timeLeftVar});
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  startTimer() {
    if (this.timer === 0) {
      this.timer = setInterval(this.countUp, 1000);
    }
  }

  countUp() {
    // Add one second, set state so a re-render happens.
    const seconds = this.state.seconds + 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds
    });
  }

  render() {
    return (
      <Text style={[this.styles.boldFont, this.state.textColor]}>
        {appendZero(this.state.time.h)}h : {appendZero(this.state.time.m)}m : {appendZero(this.state.time.s)}s
      </Text>
    );
  }
}
export default Timer;
