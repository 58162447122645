import {Platform, ToastAndroid} from 'react-native';
import ToastIOS from '../../Native/ToastIOS';
import Toast from './Toast2';

export const showShortToast = (message, position = 'bottom', theme = 'light') => {
  if (Platform.OS === 'web') {
    Toast.show(message);
  } else if (Platform.OS === 'ios') {
    // ToastIOS.show('<Message>','<Alignment>','<Theme>')
    // Alignment supported 'top' and 'bottom'
    // Theme support 'dark' and 'light'
    ToastIOS.show(message, position, theme);
  } else {
    ToastAndroid.show(message, ToastAndroid.SHORT);
  }
};

export const showLongToast = (message, position = 'bottom', theme = 'light') => {
  if (Platform.OS === 'web') {
    Toast.show(message);
  } else if (Platform.OS === 'ios') {
    ToastIOS.show(message, position, theme);
  } else {
    ToastAndroid.show(message, ToastAndroid.LONG);
  }
};
