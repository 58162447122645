import React from 'react';
import PropTypes from 'prop-types';
import Actions from '../../../navigation/postSalesNavigation';
import {Image, Text, View} from 'react-native';
import getStyles from './HotelMamiCss';
import {
  appendRupeeSymbol, getActionButton, getButtonStyle, handleClickEvent,
  isNotNullAndEmpty
} from '../utils/HotelBookingDetailUtil';
import HotelBookingDetailsConstant from '../HotelBookingDetailsConstant';
import HotelBookingTrackingHelper from '../../HotelBookingTrackingHelper';
import { getStaticData } from '../../../staticData/staticData';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { REFUND_RUPEE_ICON } from '../../HotelImageConstants';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const refundIcon = REFUND_RUPEE_ICON;

class RegularRefundCard extends React.Component {
  constructor(props) {
    super(props);
    this.fonts = getFont(true);
    this.styles = getStyles(this.fonts);
  }
  render() {
    const staticData = getStaticData();
    return (
      <View style={[this.styles.MamiCard, this.styles.mar10Bt]}>
        <View style={this.styles.CardContent}>
          <View style={this.styles.IconPortion}>
            <Image style={this.styles.CardIcon} source={refundIcon} />
          </View>
          <View style={this.styles.ContentPortion}>
            <Text style={[this.styles.blackFont, this.styles.BlackText, AtomicCss.alignLeft]}>{this.props.refundObj.currentStatus}</Text>
            <Text style={[this.styles.blackFont, this.styles.BlackText]}>{staticData.regularRefundCardText.additionalText.refundAmountText}: {appendRupeeSymbol(this.props.refundObj.refundAmount)}</Text>
            <Text style={this.styles.mainText}>{this.props.refundObj.refundExpectedDate}
            </Text>
          </View>
        </View>
        <View style={this.props.showButton && isNotNullAndEmpty(this.props.card.actionList) ? getButtonStyle(this.props.card.actionList) : this.styles.hideView}>
          {getActionButton(this.props.card, this.props.response, this.handleClick)}
        </View>
      </View>
    );
  }

  handleClick = (action) => {
    if (action.actionFamily === HotelBookingDetailsConstant.ACTION_VIEW_REGULAR_REFUND_DETAILS) {
      const {
        response, card, refundObj, progressStyle
      } = this.props;
      Actions.refundDetails({
        response,
        card,
        refundObj,
        progressStyle
      });
      HotelBookingTrackingHelper.trackClickEvent(this.props.pageName, `MI_HTL_${action.actionFamily}`, this.props.response);
    } else {
      handleClickEvent(this.props.card, action, this.props.response, this.props.pageName);
    }
  };
}

RegularRefundCard.propTypes = {
  card: PropTypes.object.isRequired,
  refundObj: PropTypes.object.isRequired,
  response: PropTypes.object.isRequired,
  pageName: PropTypes.string,
  progressStyle: PropTypes.array.isRequired,
  showButton: PropTypes.bool
};

RegularRefundCard.defaultProps = {
  showButton: true,
  pageName: ''
};

export default RegularRefundCard;
