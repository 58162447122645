import React, { useEffect } from 'react';
import ErrorView from '@mmt/legacy-commons/Common/Components/Error/ErrorView';
import Thankyou from '../../../Common/thankyou';
import FlightBookingTrackingHelper from '../details/helper/FlightBookingTrackingHelper';
import { resetToFlightBookingDetail } from '../../FlightBookingUtil';
import ProgressView from '../../../Common/ProgressView/ProgressView';
import useApi from '../../../Common/useApi';
import { getStaticData } from '../../../staticData/staticData';
import { LOBNAMES } from '../../../PostSalesConstant';

interface ThankYouPageProps {}

const ThankYouPage: React.FC<ThankYouPageProps> = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const bookingId = urlParams.get('bookingId') || '';
  const txnId = urlParams.get('txnId') || '';
  const key = urlParams.get('key') || '';
  const pageName = urlParams.get('pageName') || '';
  const [inProgress, response, apiRequest] = useApi(key + `${bookingId}/${txnId}`);
  const { heading, subHeading, actionList = [], theme, error, iconUrl } = response || {};
  const defaultIconUrl =
    theme !== 'error'
      ? 'https://imgak.mmtcdn.com/mima/images/mobile/AncillaryAddOnsSuccessfull_Icon.webp'
      : 'https://imgak.mmtcdn.com/mima/images/mobile/AncillaryBookingFailed_Icon.webp';

  const clickHandler = () => {
    if (bookingId) {
      resetToFlightBookingDetail(bookingId);
    }
  };

  const paymentRequest = async () => {
    try {
      if (apiRequest) {
        apiRequest.httpGet({
          bookingId: bookingId,
          psLob: LOBNAMES.FLIGHT,
          uniqueId: pageName,
        });
      }
    } catch (error) {
      console.error('Error making API call:', error);
    }
  };

  useEffect(() => {
    if (txnId) {
      paymentRequest();
    }
  }, []);

  const actions = [
    {
      text: actionList?.[0]?.cta,
      clickHandler,
      variant: 'PRIMARY',
      shadow: true,
      id: actionList?.[0]?.actionId,
    },
  ];
  const onRetry = () => {
    if (txnId) {
      paymentRequest();
    }
  };
  const renderErrorView = () => {
    const {
      errorText: { somethingWentWrongTryAgainError },
      tryAgainText,
    } = getStaticData();

    return (
      <ErrorView
        heading={somethingWentWrongTryAgainError}
        buttonLabel={tryAgainText}
        showRetry
        onRetry={onRetry}
      />
    );
  };
  if (!!error) return renderErrorView();
  useEffect(() => {
    FlightBookingTrackingHelper.trackLoadEvent(pageName, {});
  }, []);
  return (
    <React.Fragment>
      {inProgress ? (
        <ProgressView message="loading" />
      ) : (
        <Thankyou
          icon={{ uri: iconUrl || defaultIconUrl }}
          description={subHeading}
          message={heading}
          actions={actions}
        />
      )}
    </React.Fragment>
  );
};

export default ThankYouPage;
