import { StyleSheet } from "react-native";
import { Fonts, Theme } from '../../../types';

export default (fonts: Fonts, theme: Theme) => {
    const { color } = theme;
    return StyleSheet.create({
        tripAssuredWrapper: {
            backgroundColor: color.white,
            padding: 18,
            marginBottom: 10,
        },
        tripWrapper: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 12,
        },
        linkWrapper: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
        },
        arrowIconStyle: {
            width: 13,
            height: 7,
        },
        upArrowIconStyle: {
            transform: [{ rotate: '180deg' }],
            width: 13,
            height: 7,
        },
        cardWrapper: {
            backgroundColor: color.white,
            borderWidth: 1,
            borderColor: color.lightGray,
            padding: 16,
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: 12,
            borderRadius: 8,
        },
        contentWrapper: {
            flexDirection: 'row',
            alignItems: 'flex-start',
            flex: 1,
        },
        grayArrow: {
            width: 9,
            height: 13,
            tintColor: color.defaultTextColor,
        },
        iconImageStyle: {
            width: 26,
            height: 26,
            marginRight: 12,
        },
        poweredImgStyle: {
            width: 46,
            height: 14,
            marginLeft: 4,
        },
        contentContainer: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        cardTitleStyle: {
            ...fonts.boldFontFamily,
            fontSize: fonts.fontSize.font14,
            color: color.black,
            marginBottom: 5
        },
        cardDescriptionStyle: {
            ...fonts.regularFontFamily,
            fontSize: fonts.fontSize.font14,
            marginBottom: 5
        },
        insuranceAmountStyle: {
            ...fonts.boldFontFamily,
            fontSize: fonts.fontSize.font18,
            color: color.green,
        },
        headingStyle: {
            ...fonts.boldFontFamily,
            fontSize: fonts.fontSize.font16,
            color: color.black,
            marginBottom: 5,
        },
        subHeadingStyle: {
            ...fonts.regularFontFamily,
            fontSize: fonts.fontSize.font14,
            color: color.defaultTextColor,
            marginBottom: 20,
        },
        showCardTextStyle: {
            ...fonts.boldFontFamily,
            fontSize: fonts.fontSize.font14,
            color: color.azure,
            marginRight: 5,
        },
    });
}
