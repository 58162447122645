import React from "react";
import { View, Text } from "react-native";
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getFont } from '../../../PostSalesStyles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';
import createStyles from './styles';
import { getStaticData } from '../../../staticData/staticData';

interface PriceDetailsProps {
  totalAmountCalculated: number;
  baseChargeCalculated: number;
  feesSurcharges: number;
  currency: string;
}

const PriceDetails: React.FC<PriceDetailsProps> = ({
  totalAmountCalculated,
  baseChargeCalculated,
  feesSurcharges,
  currency,
}) => {
  const { fsStyle, ...fonts } = getFont(true);
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme);
  const { customerConfirmPopupText } = getStaticData(false);
  const { items } = customerConfirmPopupText || {};
  const { paymentDetails } = items || {};
  return (
    <View style={styles.paymentDetailsWrapper}>
      <Text style={[styles.paymentDetailsHeading, fsStyle.font14, fonts.blackFontFamily]}>
        {paymentDetails?.heading || ''}
      </Text>
      {paymentDetails?.items && paymentDetails.items?.[0] && (
        <View style={styles.paymentDetailsContainer}>
          <Text style={[styles.defaultText, fsStyle.font11]}>
            {paymentDetails.items[0]}
          </Text>
          <Text style={[styles.defaultText, fsStyle.font11]}>
            {currency || ''} {baseChargeCalculated}
          </Text>
        </View>
      )}
      {paymentDetails?.items && paymentDetails.items?.[1] && (
        <View style={[styles.paymentDetailsContainer, styles.borderBottom]} >
          <Text style={[styles.defaultText, fsStyle.font11]}>
            {paymentDetails.items[1]}
          </Text>
          <Text style={[styles.defaultText, fsStyle.font11]}>
            {currency || ''} {feesSurcharges}
          </Text>
        </View>
      )}
      {paymentDetails?.items && paymentDetails.items?.[2] && (
        <View style={[styles.totalAmountContent]} >
          <Text style={[AtomicCss.font11, AtomicCss.blackText, AtomicCss.blackFont]} >
            {paymentDetails.items[2]}
          </Text>
          <Text style={[fsStyle.font11, styles.defaultText, fonts.blackFontFamily]} >
            {currency || ''} {totalAmountCalculated}
          </Text>
        </View>
      )}
    </View>

  );
};

export default PriceDetails;