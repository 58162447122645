import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { Dimensions } from 'react-native';
import { getFont } from '../../../../../PostSalesStyles';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
import SectorSelection from './components/SectorSelection';
import { useTheme } from '../../../../../theme/theme.context';

const windW = Dimensions.get('window').width;
const AncillarySelection = ({ data, header, subtitle, selectedAnc, dispatchCancellationState }) => {
  const { fsStyle, ...fonts } = getFont(true);
  const {psTheme} = useTheme();
  const styles = getStyle(psTheme);
  const {color} = psTheme;
  return (
    <View style={styles.cardWrapper}>
      <View style={styles.headingText}>
        <Text
          style={[
            fsStyle.font20,
            fonts.blackFontFamily,
            color.black,
            AtomicCss.marginBottom8,
            AtomicCss.lineHeight20,
            AtomicCss.flex1,
          ]}
        >
          {header}
        </Text>
        <Text
          style={[
            fsStyle.font14,
            fonts.regularFontFamily,
            color.defaultTextColor,
            styles.lineHeight22,
            AtomicCss.flex1,
          ]}
        >
          {subtitle}
        </Text>
      </View>
      {data &&
        data.map((item, index) => {
          const lastItem = index === data.length - 1 ? styles['lastItemStyle'] : '';
          return (
            <SectorSelection
              isLastItem={lastItem}
              ancillaryData={item}
              selectedAnc={selectedAnc}
              dispatchCancellationState={dispatchCancellationState}
            />
          );
        })}
    </View>
  );
};
const getStyle = (theme) => {
  const {color} = theme;
  return StyleSheet.create({
    cardWrapper: {
      width: windW - 55,
      paddingHorizontal: 5,
    },
    headingText: {
      marginBottom: 24,
      flex: 1,
    },
    paddingAll20: {
      padding: 20,
    },
    lineHeight22: {
      lineHeight: 22,
    },
    cancelledFltCard: {
      ...getPlatformElevation(2),
      backgroundColor: color.grey22,
      padding: 20,
      borderRadius: 8,
      overflow: 'hidden',
      marginBottom: 12,
    },
    lastItemStyle: {
      marginBottom: 6,
    },
  });
} 
export default AncillarySelection;
