import React from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import Button, { BUTTON_VARIANT } from '../../../../../Common/BaseButton';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';

import styles from './SecureYourTripCss';

import { ViewState } from '../../../../../PostSalesConstant';
import { useTripInsuranceListingDetail } from './useTripInsuraceListingApi';
import { DIGIT_PROTECTIPN_ICON, INS_SECURE_ICON } from '../../../../FlightImageConstants';

import FlightBookingTrackingHelper from '../../helper/FlightBookingTrackingHelper';

import { getStaticData } from '../../../../../staticData/staticData';
import { getFont } from '../../../../../PostSalesStyles';

const SecureYourTrip = React.memo(
  ({ toggleSecureList, bookingId, bookingDetailRes, uniqueCrId, isCorporateBooking }) => {
    const { fsStyle, ...fonts } = getFont(true);
    const [viewState, response] = useTripInsuranceListingDetail(bookingId, uniqueCrId);
    const togglePopup = (showAllBenefits = false) => {
      FlightBookingTrackingHelper.trackClickEventNew(
        'Flight_Details',
        showAllBenefits ? 'Insurance_More_Benefits_Clicked' : 'Insurance_Booking_Card_Add_Clicked',
        bookingDetailRes,
      );
      toggleSecureList({ ...response, showAllBenefits });
    };

    if (viewState === ViewState.LOADING || viewState === ViewState.ERROR || !response) return null;

    const {
      digitInsuranceText: { moreBenefitsText, perTravellerText },
    } = getStaticData();

    const title = get(response, 'insuranceListingReview.title', '');
    const subtitleBenefits = get(
      response,
      'insuranceListingReview.insuranceTestimonial[0].subtitle',
      '',
    );
    const insuranceBenefits = get(response, 'insuranceBenefits', []);
    const insuranceViewCount = get(response, 'insuranceViewCount', 1);
    const pricePerPax = get(response, 'pricePerPax', '');
    const currencySign = get(response, 'currencySign', '');
    const insuranceActionList = get(response, 'insuranceActionList[0]', {});
    const insuranceCount = get(
      response,
      'insuranceListingReview.insuranceTestimonial[0].insuranceCount',
      '',
    );

    return (
      <View style={[styles.SecureCardParentContent]}>
        <View style={[styles.CardContent]}>
          <View style={styles.topSecWrapper}>
            <View style={[AtomicCss.flexRow, AtomicCss.alignCenter]}>
              <Image style={styles.CardIcon} source={INS_SECURE_ICON} />
              {!!title && (
                <Text
                  style={[
                    AtomicCss.fontWeightBold,
                    AtomicCss.blackText,
                    fsStyle.font18,
                    AtomicCss.lineHeight20,
                  ]}
                >
                  {title}
                </Text>
              )}
            </View>
          </View>
          {!!subtitleBenefits && (
            <View style={styles.insTextWrapper}>
              <Text style={[fonts.boldFontFamily, AtomicCss.blackText, fsStyle.font12]}>
                {!!insuranceCount && (
                  <Text style={[fonts.regularFontFamily, AtomicCss.greenText]}>
                    {insuranceCount}
                  </Text>
                )}{' '}
                {subtitleBenefits}
              </Text>
            </View>
          )}
          {!isEmpty(insuranceBenefits) && insuranceBenefits.length > 0 && (
            <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, styles.insBoxWrapper]}>
              {insuranceBenefits.map((benefit, index) => {
                if (index > insuranceViewCount - 1) return null;
                return (
                  <View key={benefit.id} style={styles.insBoxDetail}>
                    <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween]}>
                      <Image style={styles.fltCancel} source={{ uri: benefit.iconUrl }} />
                      {!!benefit && benefit.id === 'FLIGHT_DELAY' && (
                        <Image style={styles.digitIconStyle} source={DIGIT_PROTECTIPN_ICON} />
                      )}
                    </View>
                    {!!benefit.benefitDisplayText && (
                      <Text
                        style={[
                          fonts.blackFontFamily,
                          AtomicCss.blackText,
                          fsStyle.font12,
                          AtomicCss.marginBottom8,
                        ]}
                      >
                        {benefit.benefitDisplayText}
                      </Text>
                    )}
                    {!!benefit.benefitType && !!benefit.sumInsured && (
                      <Text
                        style={[
                          fonts.regularFontFamily,
                          AtomicCss.blackText,
                          fsStyle.font12,
                          AtomicCss.marginBottom3,
                        ]}
                      >
                        {benefit.benefitType}{' '}
                        <Text style={[styles.greenText, fonts.boldFontFamily]}>
                          {!!currencySign ? currencySign : ''}
                          {benefit.sumInsured}
                        </Text>
                      </Text>
                    )}
                  </View>
                );
              })}
            </View>
          )}
          <TouchableOpacity
            style={styles.benefitsBtn}
            onPress={() => togglePopup(true)}
            activeOpacity={0.7}
          >
            {!!insuranceViewCount && (
              <Text
                style={[AtomicCss.azure, fsStyle.font12, fonts.boldFontFamily]}
              >{`+ ${insuranceViewCount} ${moreBenefitsText}`}</Text>
            )}
          </TouchableOpacity>
          <View style={styles.insBotWrapper}>
            <Text style={[fonts.blackFontFamily, AtomicCss.blackText, fsStyle.font18]}>
              {!!currencySign ? currencySign : ''}
              {pricePerPax}
              <Text style={[fonts.regularFontFamily, AtomicCss.defaultText, fsStyle.font11]}>
                {perTravellerText}
              </Text>
            </Text>
            {!!insuranceActionList.title && (
              <View style={styles.buttonWrapper}>
                <Button
                  text={insuranceActionList.title}
                  variant={BUTTON_VARIANT.CAPSULE}
                  clickHandler={togglePopup}
                />
              </View>
            )}
          </View>
        </View>
      </View>
    );
  },
);

export default SecureYourTrip;
