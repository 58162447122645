import React, { ComponentType, useEffect, useMemo, useRef, useState } from 'react';
import { NativeModules, Platform, SafeAreaView } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator, TransitionPresets } from '@react-navigation/stack';
import { RouterProps } from './types';
import { saveNavigationState } from './navigationStateModule';
import navigationWrapperInstance from './navigation';
import withRedux from './withRedux';
import { generateUUID } from './util';

const getQueryParamsFromUrl = (url: string) => {
  try {
    const urlSplits = decodeURIComponent(url).split('?');
    if (urlSplits.length < 2) {
      return {};
    }
    const pairs = urlSplits[1].split('&');

    interface Map {
      [key: string]: string;
    }

    const result: Map = {};

    pairs.forEach((pair) => {
      const keyValues = pair.split('=');
      result[keyValues[0]] = decodeURIComponent(keyValues[1] || '');
    });

    return JSON.parse(JSON.stringify(result));
  } catch (e) {
    return {};
  }
};

// ======================================================
//enableScreens();

const Stack = createStackNavigator();

//TODO Make this consistent with navigation.getActiveRoute()
const getActiveRouteName = (state: any): string => {
  const route = state.routes[state.index];
  if (route.state) {
    return getActiveRouteName(route.state); //recursive
  }
  return route.name;
};

/*
TODO
1.Implement Animation support
2.State/Save + restoration
* */
function Router(props: RouterProps) {
  const [isReady, setIsReady] = useState(false);
  const [initialState, setInitialState] = useState<any>(null);
  let routerId = useRef(generateUUID()).current;

  // in case of iOS we need to disable the swipe back gesture when RN is foreground as React Navigation will take care of the gestures
  function broadcastRoutingEventsToNative(pageName: string, rootTag: number) {
    if (Platform.OS === 'ios') {
      // send the pages to iOS Native client
      const { GenericModule } = NativeModules;
      GenericModule && GenericModule.reactPages(pageName, rootTag);
    }
  }
  useEffect(() => {
    const restoreState = () => {
      try {
        const pageData = props['@nav/pageData'];
        if (pageData) {
          const pageDataObj = JSON.parse(pageData);
          if (typeof pageDataObj === 'object') {
            setInitialState(pageDataObj);
          }
        }
      } catch (e) {
        // Nothing
      } finally {
        setIsReady(true);
        // during first router initialise broadcast it
        broadcastRoutingEventsToNative(props.page, props.rootTag);
      }
    };

    if (!isReady) {
      restoreState();
    }
  }, [isReady, props]);

  useEffect(() => {
    return () => {
      navigationWrapperInstance.popNavigationRef(routerId);
    };
  }, [routerId]);

  const isNavRefSet = useRef(false);

  if (!isReady) {
    return null;
  }

  const screenOptions = {
    cardStyle: {
      backgroundColor: '#ffffff',
    },
    ...TransitionPresets.SlideFromRightIOS,
  };

  // not sure why react native navigation is not passing the deeplink params to the component
  // so manually extracting from props and spreading into the page below.
  const deepLinkParams = props.deep_link_intent_url
    ? getQueryParamsFromUrl(props.deep_link_intent_url)
    : {};

  return (
    <NavigationContainer
      initialState={initialState}
      onStateChange={(state) => {
        if (state) {
          // everytime page changes broadcast to iOS native
          broadcastRoutingEventsToNative(state.routes[state.index].name, props.rootTag);
        }
        saveNavigationState(props['@nav/pageId'], state);
        props.onPageChange && props.onPageChange(getActiveRouteName(state));
      }}
    >
      <Stack.Navigator
        headerMode="none"
        initialRouteName={props.initialRoute?.key}
        screenOptions={screenOptions}
      >
        {props.routes.map((value) => {
          const {
            key,
            component,
            reduxOptions,
            rnScreenOptions = {},
            initialProp = {},
            isMigratedRoute = false,
            safeAreaStyle = {},
          } = value;
          let Page: ComponentType<any> | null = null;
          return (
            <Stack.Screen
              name={key}
              key={key}
              options={rnScreenOptions}
              listeners={() => ({
                state: (e) => {
                  // Prevent default action
                  // e?.preventDefault();
                  value.onNavigationStateChange && value.onNavigationStateChange(e);
                },
              })}
            >
              {(screenProps) => {
                const {
                  navigation,
                  route: { params },
                } = screenProps;
                if (!isNavRefSet.current) {
                  isNavRefSet.current = true;
                  navigationWrapperInstance.setNavigationRef(navigation, routerId);
                }
                if (Page === null) {
                  Page = withRedux(component(), isMigratedRoute, reduxOptions);
                }
                return (
                  // eslint-disable-next-line react-native/no-inline-styles
                  <SafeAreaView style={[safeAreaStyle, { flex: 1 }]}>
                    <Page
                      {...initialProp}
                      {...props}
                      {...deepLinkParams}
                      {...params}
                      navigation={navigationWrapperInstance}
                    />
                  </SafeAreaView>
                );
              }}
            </Stack.Screen>
          );
        })}
      </Stack.Navigator>
    </NavigationContainer>
  );
}

export default React.memo(Router);

