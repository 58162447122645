import { colors } from '@mmt/legacy-commons/Styles/globalStyles';

const getStyles = (fonts) => {
  return {
    CardContentOuter: {
      flex: 1,
      height: window.height,
      width: window.width,
      marginBottom: 10,
    },
    CardContentFull: {
      backgroundColor: colors.white,
      padding: 15,
      paddingBottom: 13.5,
      borderBottomWidth: 0.5,
      borderBottomColor: '#e4e4e4',
    },
    CardContent: {
      backgroundColor: colors.white,
      flexDirection: 'row',
      alignItems: 'center',
      paddingBottom: 20,
    },
    CardIcon: {
      width: 24,
      height: 24,
      marginRight: 10,
    },
    HostIcon: {
      width: 48,
      height: 48,
      marginTop: -5,
      marginBottom: 14,
    },
    hostInfo: {
      flex: 1,
      borderTopWidth: 1,
      borderColor: '#e5e5e5',
      paddingBottom: 8,
    },
    hostReplyInfo: {
      marginTop: -13,
    },
  
    redirectBtnWrapper: {
      marginBottom: 2,
    },
  
    redirectBtn: {
      paddingTop: 15,
      paddingBottom: 15,
      paddingLeft: 50,
      paddingRight: 35,
      justifyContent: 'space-between',
      flexDirection: 'row',
      backgroundColor: '#fff',
      alignItems: 'center',
      borderTopWidth: 1,
      borderTopColor: '#ddd',
    },
  
    rightArrowIcon: {
      width: 9,
      height: 13,
    },
  
    linkBtnTxt: {
      ...fonts.font14,
      fontFamily: fonts.bold,
      color: '#008cff',
    },
    unreadCount: {
      width: 24,
      height: 24,
      borderRadius: 24,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: 5,
    },
    counterText: {
      ...fonts.font12,
      fontFamily: fonts.bold,
      color: '#fff',
    },
    specialRequestText: {
      width: "80vw"
    }
  };
}

export default getStyles;