import React from 'react';
import { Platform, View, ViewStyle } from 'react-native';
import RNAdWrapper from 'react-native-ad-wrapper';
import adTechConfigSetter from './configMobile'; // Adtech config
import AdComponent from 'ad-react-wrapper';
import adTechWebConfigSetter from './configmWeb';
import ErrorBoundary from './errorBoundary'
import * as PAGES from './adsConfigConstants';

adTechConfigSetter();
adTechWebConfigSetter();

function getUUIDMobile(pages: string) {
  switch (pages) {
    case PAGES.RAIL_FUNNEL_LANDING:
      return PAGES.MOBILE_RAILS_LANDING_CID;

    case PAGES.RAIL_LANDING_PAGE:
      return PAGES.MOBILE_RAILS_LANDING_PAGE;

    case PAGES.RAIL_THANK_YOU_PAGE_APP:
      return PAGES.RAIL_THANK_YOU_PAGE_APP_CID;

    case PAGES.RAIL_FUNNEL_LANDING_SNACKBAR:
      return PAGES.MOBILE_RAILS_LANDING_SNACKBAR;

    case PAGES.ACME_LANDING:
      return PAGES.MOBILE_ACME_LANDING_CID;

    case PAGES.RAIL_NO_TRAINS:
      return PAGES.MOBILE_RAILS_NO_TRAINS_CID;

    case PAGES.RAILS_THANK_YOU_ANDROID:
      return PAGES.MOBILE_RAILS_THANK_YOU_ANDROID_CID;

    case PAGES.RAILS_THANK_YOU_IOS:
      return PAGES.MOBILE_RAILS_THANK_YOU_IOS_CID;

    case PAGES.RIS_PNR_LANDING_SNACKBAR:
      return PAGES.MOBILE_RIS_PNR_LANDING_SNACKBAR;

    case PAGES.RIS_PNR_LANDING:
      return PAGES.MOBILE_RIS_PNR_LANDING_CID;

    case PAGES.RIS_PNR_RESULT:
      return PAGES.MOBILE_RIS_PNR_RESULT_CID;

    case PAGES.RIS_COACH_LANDING:
      return PAGES.MOBILE_RIS_COACH_POSITION_LANDING_CID;

    case PAGES.RIS_COACH_LANDING_SNACKBAR:
      return PAGES.MOBILE_RIS_COACH_POSITION_LANDING_SNACKBAR;

    case PAGES.RIS_COACH_RESULT:
      return PAGES.MOBILE_RIS_COACH_POSITION_RESULT_CID;

    case PAGES.RIS_STATION_LANDING:
      return PAGES.MOBILE_RIS_LIVE_STATION_LANDING_CID;

    case PAGES.RIS_STATION_LANDING_SNACKBAR:
      return PAGES.MOBILE_RIS_LIVE_STATION_LANDING_SNACKBAR;

    case PAGES.RIS_STATION_RESULT:
      return PAGES.MOBILE_RIS_LIVE_STATION_RESULT_CID;

    case PAGES.RIS_SCHEDULE_LANDING:
      return PAGES.MOBILE_RIS_TRAIN_SCHEDULE_LANDING_CID;

    case PAGES.RIS_SCHEDULE_LANDING_SNACKBAR:
      return PAGES.MOBILE_RIS_TRAIN_SCHEDULE_LANDING_SNACKBAR;

    case PAGES.RIS_SCHEDULE_RESULT:
      return PAGES.MOBILE_RIS_TRAIN_SCHEDULE_RESULT_CID;

    case PAGES.RIS_TLS_LANDING:
      return PAGES.MOBILE_RIS_LTS_LANDING_CID;

    case PAGES.RIS_TLS_LANDING_SNACKBAR:
      return PAGES.MOBILE_RIS_LTS_LANDING_SNACKBAR;

    case PAGES.WALLET_LANDING:
      return PAGES.MOBILE_WALLET_LANDING_CID;

    case PAGES.WALLET_ALL_TRANSACTION:
      return PAGES.MOBILE_WALLET_ALL_TRANSACTION_CID;

    case PAGES.POST_SALES_FLIGHT_DETAILS_PAGE:
      return PAGES.POST_SALES_FLIGHT_DETAIL_CID;

    case PAGES.POST_SALES_HOTEL_DETAILS_PAGE:
      return PAGES.POST_SALES_HOTEL_DETAIL_CID;

    case PAGES.TRAIN_BUS_COMMON_LANDING:
      return PAGES.MOBILE_RAILS_BUS_COMMON_LANDING_CID;

    case PAGES.TRAIN_BUS_COMMON_LANDING_SNACKBAR:
      return PAGES.MOBILE_RAILS_BUS_COMMON_LANDING_SNACKBAR;

    case PAGES.HOLIDAYS_LANDING_BANNER_1:
      return PAGES.HOLIDAYS_LANDING_CID_1;

    case PAGES.HOLIDAYS_LANDING_BANNER_2:
      return PAGES.HOLIDAYS_LANDING_CID_2;

    case PAGES.HOLIDAYS_LANDING_BANNER_3:
      return PAGES.HOLIDAYS_LANDING_CID_3;

    case PAGES.POST_SALES_BUS_DETAILS_PAGE:
      return PAGES.POST_SALES_BUS_DETAIL_CID;

    case PAGES.POST_SALES_CAB_DETAILS_PAGE:
      return PAGES.POST_SALES_CAB_DETAIL_CID;

    case PAGES.POST_SALES_RAIL_DETAILS_PAGE:
      return PAGES.POST_SALES_RAIL_DETAIL_CID;

    case PAGES.POST_SALES_BOOKING_SUMMARY_PAGE:
      return PAGES.POST_SALES_BOOKING_SUMMARY_CID;

    case PAGES.CABS_LANDING_ANDROID:
      return PAGES.CABS_FUNNEL_LANDING_CID_ANDROID;

    case PAGES.CABS_LANDING_IOS:
      return PAGES.CABS_FUNNEL_LANDING_CID_IOS;

    case PAGES.RIS_PNR_LANDING_ADFEED1:
      return PAGES.MOBILE_RIS_PNR_LANDING_ADFEED1;

    case PAGES.RIS_PNR_LANDING_ADFEED2:
      return PAGES.MOBILE_RIS_PNR_LANDING_ADFEED2;

    case PAGES.RIS_PNR_LANDING_ADFEED3:
      return PAGES.MOBILE_RIS_PNR_LANDING_ADFEED3;

    case PAGES.RIS_TLS_LANDING_ADFEED1:
      return PAGES.MOBILE_RIS_LTS_LANDING_ADFEED1;

    case PAGES.RIS_TLS_LANDING_ADFEED2:
      return PAGES.MOBILE_RIS_LTS_LANDING_ADFEED2;

    case PAGES.RIS_TLS_LANDING_ADFEED3:
      return PAGES.MOBILE_RIS_LTS_LANDING_ADFEED3;

    case PAGES.RIS_SCHEDULE_LANDING_ADFEED1:
      return PAGES.MOBILE_RIS_TRAIN_SCHEDULE_LANDING_ADFEED1;

    case PAGES.RIS_SCHEDULE_LANDING_ADFEED2:
      return PAGES.MOBILE_RIS_TRAIN_SCHEDULE_LANDING_ADFEED2;

    case PAGES.RIS_SCHEDULE_LANDING_ADFEED3:
      return PAGES.MOBILE_RIS_TRAIN_SCHEDULE_LANDING_ADFEED3;

    case PAGES.RIS_STATION_LANDING_ADFEED1:
      return PAGES.MOBILE_RIS_LIVE_STATION_LANDING_ADFEED1;

    case PAGES.RIS_STATION_LANDING_ADFEED2:
      return PAGES.MOBILE_RIS_LIVE_STATION_LANDING_ADFEED2;

    case PAGES.RIS_STATION_LANDING_ADFEED3:
      return PAGES.MOBILE_RIS_LIVE_STATION_LANDING_ADFEED3;

    case PAGES.RIS_COACH_LANDING_ADFEED1:
      return PAGES.MOBILE_RIS_COACH_POSITION_LANDING_ADFEED1;

    case PAGES.RIS_COACH_LANDING_ADFEED2:
      return PAGES.MOBILE_RIS_COACH_POSITION_LANDING_ADFEED2;

    case PAGES.RIS_COACH_LANDING_ADFEED3:
      return PAGES.MOBILE_RIS_COACH_POSITION_LANDING_ADFEED3;

    case PAGES.TRAIN_BUS_COMMON_LANDING_ADFEED1:
      return PAGES.MOBILE_RAILS_BUS_COMMON_LANDING_ADFEED1;

    case PAGES.TRAIN_BUS_COMMON_LANDING_ADFEED2:
      return PAGES.MOBILE_RAILS_BUS_COMMON_LANDING_ADFEED2;

    case PAGES.TRAIN_LISTING_ADFEED1:
      return PAGES.MOBILE_RAILS_LISTING_ADFEED1;

    case PAGES.TRAIN_LISTING_ADFEED2:
      return PAGES.MOBILE_RAILS_LISTING_ADFEED2;

    case PAGES.TRAIN_LISTING_ADFEED3:
      return PAGES.MOBILE_RAILS_LISTING_ADFEED3;
      
    case PAGES.TRAIN_BUS_COMMON_LANDING_ADFEED3:
      return PAGES.MOBILE_RAILS_BUS_COMMON_LANDING_ADFEED3;

    case PAGES.POST_SALES_BUS_CANCELLATION_SUCCESS_PAGE:
      return PAGES.POST_SALES_BUS_CANCELLATION_SUCCESS_CID;

    case PAGES.POST_SALES_CAB_CANCELLATION_SUCCESS_PAGE:
      return PAGES.POST_SALES_CAB_CANCELLATION_SUCCESS_CID;

    case PAGES.POST_SALES_TRAIN_CANCELLATION_SUCCESS_PAGE:
      return PAGES.POST_SALES_TRAIN_CANCELLATION_SUCCESS_CID;

    case PAGES.POST_SALES_TRAIN_TDR_SUCCESS_PAGE:
      return PAGES.POST_SALES_TRAIN_TDR_SUCCESS_CID;

    case PAGES.BUS_FUNNEL_LANDING_MULTIADFEED:
      return PAGES.BUS_FUNNEL_LANDING_MULTIADFEED_CID;

    case PAGES.BUS_FUNNEL_LANDING_BANNER:
      return PAGES.BUS_FUNNEL_LANDING_BANNER_CID;

    case PAGES.BUS_FUNNEL_LANDING_SNACKBAR:
      return PAGES.BUS_FUNNEL_LANDING_SNACKBAR_CID;
    case PAGES.POST_SALES_BOOKING_SUMMARY_PAGE_GCC:
      return PAGES.POST_SALES_BOOKING_SUMMARY_CID_GCC;

    default:
      return '';
  }
}

function getUUIDMWeb(pages: string) {
  switch (pages) {
    case PAGES.RAIL_FUNNEL_LANDING:
      return PAGES.MWEB_RAILS_LANDING_CID;

    case PAGES.RAIL_NO_TRAINS:
      return PAGES.MWEB_RAILS_NO_TRAINS_CID;

    case PAGES.RAILS_THANK_YOU:
      return PAGES.MWEB_RAILS_THANK_YOU_CID;

    case PAGES.RIS_PNR_LANDING:
      return PAGES.MWEB_RIS_PNR_LANDING_CID;

    case PAGES.HOLIDAYS_LANDING_BANNER_1:
      return PAGES.HOLIDAYS_LANDING_CID_1;

    case PAGES.HOLIDAYS_LANDING_BANNER_2:
      return PAGES.HOLIDAYS_LANDING_CID_2;

    case PAGES.HOLIDAYS_LANDING_BANNER_3:
      return PAGES.HOLIDAYS_LANDING_CID_3;

    case PAGES.POST_SALES_WEB_FLIGHT_DETAILS_PAGE:
      return PAGES.POST_SALES_WEB_FLIGHT_DETAIL_CID;

    case PAGES.POST_SALES_WEB_HOTEL_DETAILS_PAGE:
      return PAGES.POST_SALES_WEB_HOTEL_DETAIL_CID;

    case PAGES.POST_SALES_WEB_BOOKING_SUMMARY_PAGE:
      return PAGES.POST_SALES_WEB_BOOKING_SUMMARY_CID;

    case PAGES.BUS_FUNNEL_LANDING_MULTIADFEED:
      return PAGES.BUS_FUNNEL_LANDING_MULTIADFEED_CID;
    case PAGES.POST_SALES_WEB_BOOKING_SUMMARY_PAGE_GCC:
      return PAGES.POST_SALES_WEB_BOOKING_SUMMARY_CID_GCC;

    default:
      return '';
  }
}

export const getAdsCard = (os: string, pages: string, style: ViewStyle = {} as ViewStyle, onError?: Function) => {
  const uuid = os !== 'web' ? getUUIDMobile(pages) : getUUIDMWeb(pages);
  if (os === 'web') {
    return (
      <ErrorBoundary>
        <View>
          <AdComponent uuid={uuid} style={style} />
        </View>
      </ErrorBoundary>
    );
  }
  return (
    <ErrorBoundary>
      <View>
        <RNAdWrapper uuid={uuid} topWindowThreshold={80} style={style} onError={onError}/>
      </View>
    </ErrorBoundary>
  );
};

export const getMultiAdsCard = (pages: Array<string>, onViewError:Function ) => {
  const uuidList = pages.map((page) => {
    return Platform.OS !== 'web' ? getUUIDMobile(page) : getUUIDMWeb(page);
  });
  if (Platform.OS === 'web') {
    return (
      <View>
        <AdComponent uuid={uuidList[0]} />
      </View>
    );
  }
  return (
    <View>
      <RNAdWrapper
        uuid={uuidList}
        topWindowThreshold={80}
        style={{ alignSelf: 'center' }}
        onViewError={onViewError}
      />
    </View>
  );
};
