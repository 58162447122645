export const FETCH_ANCILLARY_REQUEST = 'FETCH_ANCILLARY_REQUEST';
export const FLIGHT_ANCILLARY = 'FLIGHT_ANCILLARY';
export const HOLD_ANCILLARY_REQUEST = 'HOLD_ANCILLARY_REQUEST';
export const WEBCHECKIN_REQUEST = 'WEBCHECKIN_REQUEST';
export const WEBCHECKIN_TRAVELLER_FORM = 'WEBCHECKIN_TRAVELLER_FORM';
export const MEALS = 'MEALS';
export const BAGGAGE = 'BAGGAGE';
export const SEATS = 'SEATS';
export const ADDONS = 'ADDONS';
export const WEBCHECKIN = 'WEBCHECKIN';
export const ADD_ONS_LIST_DEFAULT_SIZE = 4;
export const VEG_MEAL = 'Veg';
export const NON_VEG_MEAL = 'Non-Veg';
export const Vegetarian = ['Vegetarian', 'Veg', 'veg'];
export const NonVegetarian = ['Non Vegetarian', 'Non Veg', 'non_veg'];
export const SPICE_MAX = 'SpiceMax';
export const SPICEMAX_TOAST_MSG =
    'With SpiceMax you will get  Extra Legroom, Complimentary meal with Beverage, Priority Check-in, Priority baggage handling & priority Boarding.';
export const HOLD_SUCCESS = 'HOLD_SUCCESS';
export const HOLD_FAILED = 'HOLD_FAILED';
export const REVIEW_BLOCK_ALERT = 'Please select atleast 1 item to proceed';
export const THIS_SECTOR = 'this flight';
export const ERROR_SUBTEXT = 'You can still choose to purchase the item during your flight';
export const WEBCHECKIN_HOUR_TEXT_LIMIT = 72;
export const WEBCHECKIN_OPEN_CLIENT_DATE_FORMAT = 'YYYY-MM-DD hh:mm:ss';
export const WEBCHECKIN_OPEN_DATE_FORMAT = 'D MMM, h:mm A';
export const PARSE_INT_RADIX_BASE = 10;
export const TERMS_N_CONDNS_URL = 'https://promos.makemytrip.com/ancillary-terms.html';
export const TERMS_N_CONDNS_ALERT = 'Please agree to terms & conditions before proceeding';
export const PURCHASED_ANCILLARIES_REQUEST = 'PURCHASED_ANCILLARIES_REQUEST';
export const NO_INTERNET_CONNECTIVITY_TEXT = 'Please check your internet connection';
export const FLIGHT_WEBCHECKIN = 'Flight Web Check-in';
export const COMPLETING_WEBCHECKIN = 'Completing Web-Checkin';
export const SOMETHING_WENT_WRONG = 'Sorry! Something went wrong.';
export const ANCILLARY_STORAGE_KEY = 'ANCILLARY_STORAGE_KEY';
export const MANDATORY = "Field is Mandatory";
export const INVALID_AGE = "Age is invalid. Please enter the correct DOB";
export const ALL_BOUGHT = 'ALL_BOUGHT';
export const WC_REQ = 'wc_req';
export const SWIPE_LEFT = 'SWIPE_LEFT';
export const SWIPE_RIGHT = 'SWIPE_RIGHT';

export const PAX_TYPE = {
    ADULT: "ADULT",
    CHILD: "CHILD",
    INFANT: "INFANT"
}


export const ancillaryStates = {
    RESPONSE_LOADING: 'RESPONSE_LOADING',
    RESPONSE_FETCHED: 'RESPONSE_FETCHED',
    RESPONSE_FAILED: 'RESPONSE_FAILED',
    WEBCHECKIN_RESPONSE_FETCHED: 'WEBCHECKIN_RESPONSE_FETCHED',
    SEATS_RESPONSE_FETCHED: 'SEATS_RESPONSE_FETCHED',
    ADDONS_RESPONSE_FETCHED: 'ADDONS_RESPONSE_FETCHED',
    SEGMENT_CHANGE: 'SEGMENT_CHANGE',
    ADD_ONS_UPDATED: 'ADD_ONS_UPDATED',
    SEATS_UPDATED: 'SEATS_UPDATED',
    REVIEW_OPEN: 'REVIEW_OPEN',
    WEBCCHECKIN_FAILED: 'WEBCCHECKIN_FAILED',
    WEBCCHECKIN_SUCCESS: 'WEBCCHECKIN_SUCCESS',
    WEBCHECKIN_LOADER: 'WEBCHECKIN_LOADER',
    DESTROY_SESSION: 'DESTROY_SESSION',
    PURCHASED_ANCILLARIES_SUCCESS: 'PURCHASED_ANCILLARIES_SUCCESS',
    PURCHASED_ANCILLARIES_FAILURE: 'PURCHASED_ANCILLARIES_FAILURE',
};
export const ancillaryInitialState = {
    isAncillaryFetched: false,
    selectedAncillaryDetail: {},
    ancillaryResponse: null
};

export const ancillaryActionTypes = {
    UPDATE_PAGE_KEY_WITH_WEBCHECKIN_ENABLED: 'UPDATE_PAGE_KEY_WITH_WEBCHECKIN_ENABLED',
    UPDATE_PAGE_KEY_WITH_ADDON_TYPE: 'UPDATE_PAGE_KEY_WITH_ADDON_TYPE',
    OPEN_TRAVELLER_DETAIL_FROM_REVIEW: 'OPEN_TRAVELLER_DETAIL_FROM_REVIEW',
    OPEN_SEAT_FROM_WEBCHECKIN: 'OPEN_SEAT_FROM_WEBCHECKIN',
    OPEN_REVIEW_PAGE_FROM_WEBCHECKIN: 'OPEN_REVIEW_PAGE_FROM_WEBCHECKIN',
    UPDATE_ANCILLARY_WITH_PAX_DETAIL: 'UPDATE_ANCILLARY_WITH_PAX_DETAIL',
    WEBCHECKIN_GET_ANCILLARY_FETCHED: 'WEBCHECKIN_GET_ANCILLARY_FETCHED',
    UPDATE_ANCILLARY_RESPONSE: 'UPDATE_ANCILLARY_RESPONSE',
    SEATS_GET_ANCILLARY_FETCHED: 'SEATS_GET_ANCILLARY_FETCHED',
    ADDONS_GET_ANCILLARY_FETCHED: 'ADDONS_GET_ANCILLARY_FETCHED',
    ANCILLARY_FETCHED: 'ANCILLARY_FETCHED',
    ANCILLARY_FAILED: 'ANCILLARY_FAILED',
    ANCILLARY_LOADING: 'ANCILLARY_LOADING',
    UPDATE_SEGMENT: 'UPDATE_SEGMENT',
    UPDATE_ACTIVE_SEAT_SEGMENT: 'UPDATE_ACTIVE_SEAT_SEGMENT',
    UPDATE_ADDONS_SEGMENT: 'UPDATE_ADDONS_SEGMENT',
    UPDATE_SEAT_DETAIL: 'UPDATE_SEAT_DETAIL',
    UPDATE_MEAL_DETAIL: 'UPDATE_MEAL_DETAIL',
    UPDATE_BAGGAGE_DETAIL: 'UPDATE_BAGGAGE_DETAIL',
    UPDATE_PAGE_KEY: 'UPDATE_PAGE_KEY',
    UPDATE_PAGE_KEY_WITH_SEATSELECTION: 'UPDATE_PAGE_KEY_WITH_SEATSELECTION',
    UPDATE_ADDONS_DETAIL: 'UPDATE_ADDONS_DETAIL',
    UPDATE_ADDONS_ACTIVE_SEGMENT: 'UPDATE_ADDONS_ACTIVE_SEGMENT',
    UPDATE_ANCILLARY_DETAIL: 'UPDATE_ANCILLARY_DETAIL',
    UPDATE_ANCILLARY_DETAIL_SEAT: "UPDATE_ANCILLARY_DETAIL_SEAT",
    ADD_ONS_UPDATED: 'ADD_ONS_UPDATED',
    SEATS_UPDATED: 'SEATS_UPDATED',
    REVIEW_OPEN: 'REVIEW_OPEN',
    HOLD_ANCILLARY_LOADING: 'HOLD_ANCILLARY_LOADING',
    HOLD_ANCILLARY_FAILED: 'HOLD_ANCILLARY_FAILED',
    HOLD_ANCILLARY_SUCCESS: 'HOLD_ANCILLARY_SUCCESS',
    WEBCCHECKIN_FAILED: 'WEBCCHECKIN_FAILED',
    WEBCCHECKIN_SUCCESS: 'WEBCCHECKIN_SUCCESS',
    WEBCHECKIN_LOADER: 'WEBCHECKIN_LOADER',
    DESTROY_SESSION: 'DESTROY_SESSION',
    PURCHASED_ANCILLARIES_SUCCESS: 'PURCHASED_ANCILLARIES_SUCCESS',
    PURCHASED_ANCILLARIES_FAILURE: 'PURCHASED_ANCILLARIES_FAILURE',
    RESTORE_ANCILLARY_STATE: 'RESTORE_ANCILLARY_STATE',
    SAVE_WEBCHECKIN_DETAILS: 'SAVE_WEBCHECKIN_DETAILS'
};

export const SEATMAP_CELLTYPE = {
    LABEL: 'LABEL',
    SEAT: 'SEAT',
    EMPTY: 'EMPTY',
    ICON: 'ICON',
};

export const SEATMAP_ATTR = {
    EMERGENCY_EXIT_NV_EE: 'NV_EE',
    EMERGENCY_EXIT_E: 'E',
};

export const SEATMAP_ICONTYPE = {
    EXIT_LEFT: 'EL',
    EXIT_RIGHT: 'ER',
    LAVATORY: 'LA',
    GALLEY: 'GA',
    BASSINET: 'BA',
};

export const thankyouPageStatusType = {
    WEBCHECKIN_SUCCESS: 'WEBCHECKIN_SUCCESS',
    WEBCHECKIN_FAILED: 'WEBCHECKIN_FAILED',
    WEBCHECKIN_PARTIAL: 'WEBCHECKIN_PARTIAL',
    ADDONS_SUCCESS: 'ADDONS_SUCCESS',
    ADDONS_FAILED: 'ADDONS_FAILED',
    ADDONS_PARTIAL: 'ADDONS_PARTIAL',
};

export const WEB_CHECKIN_DESC = {
    OPEN: 'OPEN',
    OPEN_MMT_SUPPORTED: 'OPEN_MMT_SUPPORTED',
    NOT_OPEN: 'NOT_OPEN',
    NO_PAX_AVAILABLE: 'NO_PAX_AVAILABLE',
    ALL_CHECKED_IN: 'ALL_CHECKED_IN',
    MMT_NOT_SUPPORTED: 'MMT_NOT_SUPPORTED',
    AIRLINE_NOT_SUPPORTED: 'AIRLINE_NOT_SUPPORTED',
    WINDOW_CLOSED: 'WINDOW_CLOSED',
    BOOKING_WITH_INFANT: 'BOOKING_WITH_INFANT',
    ONLY_CHILD: 'ONLY_CHILD',
    SCHEDULED_OPEN: 'SCHEDULED_OPEN',
};

export const ANCILLARY_TYPE = {
    4: 'Seat',
    0: 'Meal',
    1: 'Baggage',
    7: 'Web Check-in',
};

export const TY_WEBCHCEKIN_STATES = [
    'WEBCHECKIN_SUCCESS',
    'WEBCHECKIN_FAILED',
    'WEBCHECKIN_PARTIAL',
];

export const PURCHASED_ANCILLARY_STATUS = {
    PAYMENT_FAILED: 'PAYMENT_FAILED',
    COMMIT_FAILED: 'COMMIT_FAILED',
    INVALID_TXID: 'INVALID_TXID',
    COMMIT_DONE: 'COMMIT_DONE',
};

export const ANCILLARY_THANK_YOU_PAGE_SPLIT_FACTOR = {
    SINGLE_ANCILLARY_SPLIT_FACTOR: 1, // either webcheckin or seat/addons
    MULTIPLE_ANCILLARY_SPLIT_FACTOR: 2, // both webcheckin and seat/addons
};

export const PaxType = {
    ADULT: 'ADULT',
    INFANT: 'INFANT',
};

export const ANCILLARY_OVERLAY = 'ancillaryOverlay';
export const HELPING_HAND_OVERLAY = 'helpingHandOverlay';
export const FARE_BREAKUP_OVERLAY = 'fareBreakupOverlay';




export const COUNTRY_CODE_LIST = [
    {
        name: 'Andorra',
        nameCode: 'ad',
        phoneCode: '376',
    },
    {
        name: 'United Arab Emirates',
        nameCode: 'ae',
        phoneCode: '971',
    },
    {
        name: 'Afghanistan',
        nameCode: 'af',
        phoneCode: '93',
    },
    {
        name: 'Antigua and Barbuda',
        nameCode: 'ag',
        phoneCode: '1',
    },
    {
        name: 'Anguilla',
        nameCode: 'ai',
        phoneCode: '1',
    },
    {
        name: 'Albania',
        nameCode: 'al',
        phoneCode: '355',
    },
    {
        name: 'Armenia',
        nameCode: 'am',
        phoneCode: '374',
    },
    {
        name: 'Angola',
        nameCode: 'ao',
        phoneCode: '244',
    },
    {
        name: 'Antarctica',
        nameCode: 'aq',
        phoneCode: '672',
    },
    {
        name: 'Argentina',
        nameCode: 'ar',
        phoneCode: '54',
    },
    {
        name: 'American Samoa',
        nameCode: 'as',
        phoneCode: '1',
    },
    {
        name: 'Austria',
        nameCode: 'at',
        phoneCode: '43',
    },
    {
        name: 'Australia',
        nameCode: 'au',
        phoneCode: '61',
    },
    {
        name: 'Aruba',
        nameCode: 'aw',
        phoneCode: '297',
    },
    {
        name: 'Aland Islands',
        nameCode: 'az',
        phoneCode: '358',
    },
    {
        name: 'Azerbaijan',
        nameCode: 'az',
        phoneCode: '994',
    },
    {
        name: 'Bosnia And Herzegovina',
        nameCode: 'ba',
        phoneCode: '387',
    },
    {
        name: 'Barbados',
        nameCode: 'bb',
        phoneCode: '1',
    },
    {
        name: 'Bangladesh',
        nameCode: 'bd',
        phoneCode: '880',
    },
    {
        name: 'Belgium',
        nameCode: 'be',
        phoneCode: '32',
    },
    {
        name: 'Burkina Faso',
        nameCode: 'bf',
        phoneCode: '226',
    },
    {
        name: 'Bulgaria',
        nameCode: 'bg',
        phoneCode: '359',
    },
    {
        name: 'Bahrain',
        nameCode: 'bh',
        phoneCode: '973',
    },
    {
        name: 'Burundi',
        nameCode: 'bi',
        phoneCode: '257',
    },
    {
        name: 'Benin',
        nameCode: 'bj',
        phoneCode: '229',
    },
    {
        name: 'Saint Barthélemy',
        nameCode: 'bl',
        phoneCode: '590',
    },
    {
        name: 'Bermuda',
        nameCode: 'bm',
        phoneCode: '1',
    },
    {
        name: 'Brunei Darussalam',
        nameCode: 'bn',
        phoneCode: '673',
    },
    {
        name: 'Bolivia, Plurinational State Of',
        nameCode: 'bo',
        phoneCode: '591',
    },
    {
        name: 'Brazil',
        nameCode: 'br',
        phoneCode: '55',
    },
    {
        name: 'Bahamas',
        nameCode: 'bs',
        phoneCode: '1',
    },
    {
        name: 'Bhutan',
        nameCode: 'bt',
        phoneCode: '975',
    },
    {
        name: 'Botswana',
        nameCode: 'bw',
        phoneCode: '267',
    },
    {
        name: 'Belarus',
        nameCode: 'by',
        phoneCode: '375',
    },
    {
        name: 'Belize',
        nameCode: 'bz',
        phoneCode: '501',
    },
    {
        name: 'Canada',
        nameCode: 'ca',
        phoneCode: '1',
    },
    {
        name: 'Cocos Islands',
        nameCode: 'cc',
        phoneCode: '61',
    },
    {
        name: 'Congo, The Democratic Republic Of The',
        nameCode: 'cd',
        phoneCode: '243',
    },
    {
        name: 'Central African Republic',
        nameCode: 'cf',
        phoneCode: '236',
    },
    {
        name: 'Congo',
        nameCode: 'cg',
        phoneCode: '242',
    },
    {
        name: 'Switzerland',
        nameCode: 'ch',
        phoneCode: '41',
    },
    {
        name: "Côte D'ivoire",
        nameCode: 'ci',
        phoneCode: '225',
    },
    {
        name: 'Cook Islands',
        nameCode: 'ck',
        phoneCode: '682',
    },
    {
        name: 'Chile',
        nameCode: 'cl',
        phoneCode: '56',
    },
    {
        name: 'Cameroon',
        nameCode: 'cm',
        phoneCode: '237',
    },
    {
        name: 'China',
        nameCode: 'cn',
        phoneCode: '86',
    },
    {
        name: 'Colombia',
        nameCode: 'co',
        phoneCode: '57',
    },
    {
        name: 'Costa Rica',
        nameCode: 'cr',
        phoneCode: '506',
    },
    {
        name: 'Cape Verde',
        nameCode: 'cv',
        phoneCode: '238',
    },
    {
        name: 'Christmas Island',
        nameCode: 'cx',
        phoneCode: '61',
    },
    {
        name: 'Cyprus',
        nameCode: 'cy',
        phoneCode: '357',
    },
    {
        name: 'Czech Republic',
        nameCode: 'cz',
        phoneCode: '420',
    },
    {
        name: 'Germany',
        nameCode: 'de',
        phoneCode: '49',
    },
    {
        name: 'Djibouti',
        nameCode: 'dj',
        phoneCode: '253',
    },
    {
        name: 'Denmark',
        nameCode: 'dk',
        phoneCode: '45',
    },
    {
        name: 'Dominica',
        nameCode: 'dm',
        phoneCode: '1',
    },
    {
        name: 'Dominican Republic',
        nameCode: 'do',
        phoneCode: '1',
    },
    {
        name: 'Algeria',
        nameCode: 'dz',
        phoneCode: '213',
    },
    {
        name: 'Ecuador',
        nameCode: 'ec',
        phoneCode: '593',
    },
    {
        name: 'Estonia',
        nameCode: 'ee',
        phoneCode: '372',
    },
    {
        name: 'Egypt',
        nameCode: 'eg',
        phoneCode: '20',
    },
    {
        name: 'Eritrea',
        nameCode: 'er',
        phoneCode: '291',
    },
    {
        name: 'Spain',
        nameCode: 'es',
        phoneCode: '34',
    },
    {
        name: 'Ethiopia',
        nameCode: 'et',
        phoneCode: '251',
    },
    {
        name: 'Finland',
        nameCode: 'fi',
        phoneCode: '358',
    },
    {
        name: 'Fiji',
        nameCode: 'fj',
        phoneCode: '679',
    },
    {
        name: 'Falkland Islands',
        nameCode: 'fk',
        phoneCode: '500',
    },
    {
        name: 'Micronesia, Federated States Of',
        nameCode: 'fm',
        phoneCode: '691',
    },
    {
        name: 'Faroe Islands',
        nameCode: 'fo',
        phoneCode: '298',
    },
    {
        name: 'France',
        nameCode: 'fr',
        phoneCode: '33',
    },
    {
        name: 'Gabon',
        nameCode: 'ga',
        phoneCode: '241',
    },
    {
        name: 'United Kingdom',
        nameCode: 'gb',
        phoneCode: '44',
    },
    {
        name: 'Grenada',
        nameCode: 'gd',
        phoneCode: '1',
    },
    {
        name: 'Georgia',
        nameCode: 'ge',
        phoneCode: '995',
    },
    {
        name: 'French Guyana',
        nameCode: 'gf',
        phoneCode: '594',
    },
    {
        name: 'Ghana',
        nameCode: 'gh',
        phoneCode: '233',
    },
    {
        name: 'Gibraltar',
        nameCode: 'gi',
        phoneCode: '350',
    },
    {
        name: 'Greenland',
        nameCode: 'gl',
        phoneCode: '299',
    },
    {
        name: 'Gambia',
        nameCode: 'gm',
        phoneCode: '220',
    },
    {
        name: 'Guinea',
        nameCode: 'gn',
        phoneCode: '224',
    },
    {
        name: 'Guadeloupe',
        nameCode: 'gp',
        phoneCode: '450',
    },
    {
        name: 'Equatorial Guinea',
        nameCode: 'gq',
        phoneCode: '240',
    },
    {
        name: 'Greece',
        nameCode: 'gr',
        phoneCode: '30',
    },
    {
        name: 'Guatemala',
        nameCode: 'gt',
        phoneCode: '502',
    },
    {
        name: 'Guam',
        nameCode: 'gu',
        phoneCode: '1',
    },
    {
        name: 'Guinea-bissau',
        nameCode: 'gw',
        phoneCode: '245',
    },
    {
        name: 'Guyana',
        nameCode: 'gy',
        phoneCode: '592',
    },
    {
        name: 'Hong Kong',
        nameCode: 'hk',
        phoneCode: '852',
    },
    {
        name: 'Honduras',
        nameCode: 'hn',
        phoneCode: '504',
    },
    {
        name: 'Croatia',
        nameCode: 'hr',
        phoneCode: '385',
    },
    {
        name: 'Haiti',
        nameCode: 'ht',
        phoneCode: '509',
    },
    {
        name: 'Hungary',
        nameCode: 'hu',
        phoneCode: '36',
    },
    {
        name: 'Indonesia',
        nameCode: 'id',
        phoneCode: '62',
    },
    {
        name: 'Ireland',
        nameCode: 'ie',
        phoneCode: '353',
    },
    {
        name: 'Israel',
        nameCode: 'il',
        phoneCode: '972',
    },
    {
        name: 'Isle Of Man',
        nameCode: 'im',
        phoneCode: '44',
    },
    {
        name: 'Iceland',
        nameCode: 'is',
        phoneCode: '354',
    },
    {
        name: 'India',
        nameCode: 'in',
        phoneCode: '91',
    },
    {
        name: 'British Indian Ocean Territory',
        nameCode: 'io',
        phoneCode: '246',
    },
    {
        name: 'Iraq',
        nameCode: 'iq',
        phoneCode: '964',
    },
    {
        name: 'Italy',
        nameCode: 'it',
        phoneCode: '39',
    },
    {
        name: 'Jersey ',
        nameCode: 'je',
        phoneCode: '44',
    },
    {
        name: 'Jamaica',
        nameCode: 'jm',
        phoneCode: '1',
    },
    {
        name: 'Jordan',
        nameCode: 'jo',
        phoneCode: '962',
    },
    {
        name: 'Japan',
        nameCode: 'jp',
        phoneCode: '81',
    },
    {
        name: 'Kenya',
        nameCode: 'ke',
        phoneCode: '254',
    },
    {
        name: 'Kyrgyzstan',
        nameCode: 'kg',
        phoneCode: '996',
    },
    {
        name: 'Cambodia',
        nameCode: 'kh',
        phoneCode: '855',
    },
    {
        name: 'Kiribati',
        nameCode: 'ki',
        phoneCode: '686',
    },
    {
        name: 'Comoros',
        nameCode: 'km',
        phoneCode: '269',
    },
    {
        name: 'Saint Kitts and Nevis',
        nameCode: 'kn',
        phoneCode: '1',
    },
    {
        name: 'South Korea',
        nameCode: 'kr',
        phoneCode: '82',
    },
    {
        name: 'Kuwait',
        nameCode: 'kw',
        phoneCode: '965',
    },
    {
        name: 'Cayman Islands',
        nameCode: 'ky',
        phoneCode: '1',
    },
    {
        name: 'Kazakhstan',
        nameCode: 'kz',
        phoneCode: '7',
    },
    {
        name: "Lao People's Democratic Republic",
        nameCode: 'la',
        phoneCode: '856',
    },
    {
        name: 'Lebanon',
        nameCode: 'lb',
        phoneCode: '961',
    },
    {
        name: 'Saint Lucia',
        nameCode: 'lc',
        phoneCode: '1',
    },
    {
        name: 'Liechtenstein',
        nameCode: 'li',
        phoneCode: '423',
    },
    {
        name: 'Sri Lanka',
        nameCode: 'lk',
        phoneCode: '94',
    },
    {
        name: 'Liberia',
        nameCode: 'lr',
        phoneCode: '231',
    },
    {
        name: 'Lesotho',
        nameCode: 'ls',
        phoneCode: '266',
    },
    {
        name: 'Lithuania',
        nameCode: 'lt',
        phoneCode: '370',
    },
    {
        name: 'Luxembourg',
        nameCode: 'lu',
        phoneCode: '352',
    },
    {
        name: 'Latvia',
        nameCode: 'lv',
        phoneCode: '371',
    },
    {
        name: 'Libya',
        nameCode: 'ly',
        phoneCode: '218',
    },
    {
        name: 'Morocco',
        nameCode: 'ma',
        phoneCode: '212',
    },
    {
        name: 'Monaco',
        nameCode: 'mc',
        phoneCode: '377',
    },
    {
        name: 'Moldova, Republic Of',
        nameCode: 'md',
        phoneCode: '373',
    },
    {
        name: 'Montenegro',
        nameCode: 'me',
        phoneCode: '382',
    },
    {
        name: 'Saint Martin',
        nameCode: 'mf',
        phoneCode: '590',
    },
    {
        name: 'Madagascar',
        nameCode: 'mg',
        phoneCode: '261',
    },
    {
        name: 'Marshall Islands',
        nameCode: 'mh',
        phoneCode: '692',
    },
    {
        name: 'Macedonia, The Former Yugoslav Republic Of',
        nameCode: 'mk',
        phoneCode: '389',
    },
    {
        name: 'Mali',
        nameCode: 'ml',
        phoneCode: '223',
    },
    {
        name: 'Myanmar',
        nameCode: 'mm',
        phoneCode: '95',
    },
    {
        name: 'Mongolia',
        nameCode: 'mn',
        phoneCode: '976',
    },
    {
        name: 'Macao',
        nameCode: 'mo',
        phoneCode: '853',
    },
    {
        name: 'Northern Mariana Islands',
        nameCode: 'mp',
        phoneCode: '1',
    },
    {
        name: 'Martinique',
        nameCode: 'mq',
        phoneCode: '596',
    },
    {
        name: 'Mauritania',
        nameCode: 'mr',
        phoneCode: '222',
    },
    {
        name: 'Montserrat',
        nameCode: 'ms',
        phoneCode: '1',
    },
    {
        name: 'Malta',
        nameCode: 'mt',
        phoneCode: '356',
    },
    {
        name: 'Mauritius',
        nameCode: 'mu',
        phoneCode: '230',
    },
    {
        name: 'Maldives',
        nameCode: 'mv',
        phoneCode: '960',
    },
    {
        name: 'Malawi',
        nameCode: 'mw',
        phoneCode: '265',
    },
    {
        name: 'Mexico',
        nameCode: 'mx',
        phoneCode: '52',
    },
    {
        name: 'Malaysia',
        nameCode: 'my',
        phoneCode: '60',
    },
    {
        name: 'Mozambique',
        nameCode: 'mz',
        phoneCode: '258',
    },
    {
        name: 'Namibia',
        nameCode: 'na',
        phoneCode: '264',
    },
    {
        name: 'New Caledonia',
        nameCode: 'nc',
        phoneCode: '687',
    },
    {
        name: 'Niger',
        nameCode: 'ne',
        phoneCode: '227',
    },
    {
        name: 'Norfolk Islands',
        nameCode: 'nf',
        phoneCode: '672',
    },
    {
        name: 'Nigeria',
        nameCode: 'ng',
        phoneCode: '234',
    },
    {
        name: 'Nicaragua',
        nameCode: 'ni',
        phoneCode: '505',
    },
    {
        name: 'Netherlands',
        nameCode: 'nl',
        phoneCode: '31',
    },
    {
        name: 'Norway',
        nameCode: 'no',
        phoneCode: '47',
    },
    {
        name: 'Nepal',
        nameCode: 'np',
        phoneCode: '977',
    },
    {
        name: 'Nauru',
        nameCode: 'nr',
        phoneCode: '674',
    },
    {
        name: 'Niue',
        nameCode: 'nu',
        phoneCode: '683',
    },
    {
        name: 'New Zealand',
        nameCode: 'nz',
        phoneCode: '64',
    },
    {
        name: 'Oman',
        nameCode: 'om',
        phoneCode: '968',
    },
    {
        name: 'Panama',
        nameCode: 'pa',
        phoneCode: '507',
    },
    {
        name: 'Peru',
        nameCode: 'pe',
        phoneCode: '51',
    },
    {
        name: 'French Polynesia',
        nameCode: 'pf',
        phoneCode: '689',
    },
    {
        name: 'Papua New Guinea',
        nameCode: 'pg',
        phoneCode: '675',
    },
    {
        name: 'Philippines',
        nameCode: 'ph',
        phoneCode: '63',
    },
    {
        name: 'Pakistan',
        nameCode: 'pk',
        phoneCode: '92',
    },
    {
        name: 'Poland',
        nameCode: 'pl',
        phoneCode: '48',
    },
    {
        name: 'Saint Pierre And Miquelon',
        nameCode: 'pm',
        phoneCode: '508',
    },
    {
        name: 'Pitcairn',
        nameCode: 'pn',
        phoneCode: '870',
    },
    {
        name: 'Puerto Rico',
        nameCode: 'pr',
        phoneCode: '1',
    },
    {
        name: 'Palestine',
        nameCode: 'ps',
        phoneCode: '970',
    },
    {
        name: 'Portugal',
        nameCode: 'pt',
        phoneCode: '351',
    },
    {
        name: 'Palau',
        nameCode: 'pw',
        phoneCode: '680',
    },
    {
        name: 'Paraguay',
        nameCode: 'py',
        phoneCode: '595',
    },
    {
        name: 'Qatar',
        nameCode: 'qa',
        phoneCode: '974',
    },
    {
        name: 'Réunion',
        nameCode: 're',
        phoneCode: '262',
    },
    {
        name: 'Romania',
        nameCode: 'ro',
        phoneCode: '40',
    },
    {
        name: 'Serbia',
        nameCode: 'rs',
        phoneCode: '381',
    },
    {
        name: 'Russian Federation',
        nameCode: 'ru',
        phoneCode: '7',
    },
    {
        name: 'Rwanda',
        nameCode: 'rw',
        phoneCode: '250',
    },
    {
        name: 'Saudi Arabia',
        nameCode: 'sa',
        phoneCode: '966',
    },
    {
        name: 'Solomon Islands',
        nameCode: 'sb',
        phoneCode: '677',
    },
    {
        name: 'Seychelles',
        nameCode: 'sc',
        phoneCode: '248',
    },
    {
        name: 'Sweden',
        nameCode: 'se',
        phoneCode: '46',
    },
    {
        name: 'Singapore',
        nameCode: 'sg',
        phoneCode: '65',
    },
    {
        name: 'Saint Helena, Ascension And Tristan Da Cunha',
        nameCode: 'sh',
        phoneCode: '290',
    },
    {
        name: 'Slovenia',
        nameCode: 'si',
        phoneCode: '386',
    },
    {
        name: 'Slovakia',
        nameCode: 'sk',
        phoneCode: '421',
    },
    {
        name: 'Sierra Leone',
        nameCode: 'sl',
        phoneCode: '232',
    },
    {
        name: 'San Marino',
        nameCode: 'sm',
        phoneCode: '378',
    },
    {
        name: 'Senegal',
        nameCode: 'sn',
        phoneCode: '221',
    },
    {
        name: 'Somalia',
        nameCode: 'so',
        phoneCode: '252',
    },
    {
        name: 'Suriname',
        nameCode: 'sr',
        phoneCode: '597',
    },
    {
        name: 'Sao Tome And Principe',
        nameCode: 'st',
        phoneCode: '239',
    },
    {
        name: 'El Salvador',
        nameCode: 'sv',
        phoneCode: '503',
    },
    {
        name: 'Sint Maarten',
        nameCode: 'sx',
        phoneCode: '1',
    },
    {
        name: 'Swaziland',
        nameCode: 'sz',
        phoneCode: '268',
    },
    {
        name: 'Turks and Caicos Islands',
        nameCode: 'tc',
        phoneCode: '1',
    },
    {
        name: 'Chad',
        nameCode: 'td',
        phoneCode: '235',
    },
    {
        name: 'Togo',
        nameCode: 'tg',
        phoneCode: '228',
    },
    {
        name: 'Thailand',
        nameCode: 'th',
        phoneCode: '66',
    },
    {
        name: 'Tajikistan',
        nameCode: 'tj',
        phoneCode: '992',
    },
    {
        name: 'Tokelau',
        nameCode: 'tk',
        phoneCode: '690',
    },
    {
        name: 'Timor-leste',
        nameCode: 'tl',
        phoneCode: '670',
    },
    {
        name: 'Turkmenistan',
        nameCode: 'tm',
        phoneCode: '993',
    },
    {
        name: 'Tunisia',
        nameCode: 'tn',
        phoneCode: '216',
    },
    {
        name: 'Tonga',
        nameCode: 'to',
        phoneCode: '676',
    },
    {
        name: 'Turkey',
        nameCode: 'tr',
        phoneCode: '90',
    },
    {
        name: 'Trinidad &amp; Tobago',
        nameCode: 'tt',
        phoneCode: '1',
    },
    {
        name: 'Tuvalu',
        nameCode: 'tv',
        phoneCode: '688',
    },
    {
        name: 'Taiwan',
        nameCode: 'tw',
        phoneCode: '886',
    },
    {
        name: 'Tanzania, United Republic Of',
        nameCode: 'tz',
        phoneCode: '255',
    },
    {
        name: 'Uganda',
        nameCode: 'ug',
        phoneCode: '256',
    },
    {
        name: 'United States',
        nameCode: 'us',
        phoneCode: '1',
    },
    {
        name: 'Uruguay',
        nameCode: 'uy',
        phoneCode: '598',
    },
    {
        name: 'Uzbekistan',
        nameCode: 'uz',
        phoneCode: '998',
    },
    {
        name: 'Holy See',
        nameCode: 'va',
        phoneCode: '379',
    },
    {
        name: 'Saint Vincent &amp; The Grenadines',
        nameCode: 'vc',
        phoneCode: '1',
    },
    {
        name: 'Venezuela, Bolivarian Republic Of',
        nameCode: 've',
        phoneCode: '58',
    },
    {
        name: 'British Virgin Islands',
        nameCode: 'vg',
        phoneCode: '1',
    },
    {
        name: 'US Virgin Islands',
        nameCode: 'vi',
        phoneCode: '1',
    },
    {
        name: 'Viet Nam',
        nameCode: 'vn',
        phoneCode: '84',
    },
    {
        name: 'Vanuatu',
        nameCode: 'vu',
        phoneCode: '678',
    },
    {
        name: 'Wallis And Futuna',
        nameCode: 'wf',
        phoneCode: '681',
    },
    {
        name: 'Samoa',
        nameCode: 'ws',
        phoneCode: '685',
    },
    {
        name: 'Kosovo',
        nameCode: 'xk',
        phoneCode: '383',
    },
    {
        name: 'Yemen',
        nameCode: 'ye',
        phoneCode: '967',
    },
    {
        name: 'Mayotte',
        nameCode: 'yt',
        phoneCode: '262',
    },
    {
        name: 'South Africa',
        nameCode: 'za',
        phoneCode: '27',
    },
    {
        name: 'Zambia',
        nameCode: 'zm',
        phoneCode: '260',
    },
    {
        name: 'Zimbabwe',
        nameCode: 'zw',
        phoneCode: '263',
    },
];
