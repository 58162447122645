import React from 'react';
import {BackHandler, DeviceEventEmitter, View} from 'react-native';
import PropTypes from 'prop-types';
import Actions from '../../../navigation/postSalesNavigation';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import BasePage from '../../../Common/PostSalesBasePage';
import * as PSC from '../../../PostSalesConstant';
import ViewState from '@mmt/legacy-commons/Common/constants/ViewState';
import PageLoader from '../../../Common/Cancellation/PageLoader';
import CabAmendFullScreenPage from './CabAmendFullScreenPage';
import CabBookingDetailsConstant from '../../CabBookingDetailsConstant';
import CabBookingTrackingHelper from '../../CabBookingTrackingHelper';
import {isNetworkAvailable} from '@mmt/legacy-commons/Common/utils/AppUtils';
import * as NetworkUtils from '../../../utils/NetworkUtils';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import CabBookingModule from '@mmt/legacy-commons/Native/CabBookingModule';
import {successIcon} from "../../CabImageConstants";

class CabCommitAmendment extends BasePage {
  constructor(props) {
    super(props, CabBookingDetailsConstant.CAB_AMEND_THANKYOU_PAGE);
    this.state = {
      viewState: ViewState.LOADING,
      apiError: false
    };
    this.amendResponse = {};
    this.errorMessage = '';
    this.mmtAuth = {};
    this.loggingTrackingInfo = {};
    this.amendentThankYouListener = null;
  }

  componentWillMount() {
    this.amendentThankYouListener = DeviceEventEmitter.addListener(CabBookingDetailsConstant.CAB_AMENDMENT_THANKYOU_EVENT, this.openModificationThankYouPage);
    BackHandler.addEventListener('hardwareBackPress', this.handleClick);
  }

  componentWillUnmount() {
    if (this.amendentThankYouListener) {
      this.amendentThankYouListener.remove();
    }
    BackHandler.removeEventListener('hardwareBackPress', this.handleClick);
  }

  componentDidMount() {
    /* this API call will commit the Amendment. Payment to be taken or refund process, will be handled at backend
        * in case of no Amount involved or Pay to driver this API will commit the Amendment */
    super.componentDidMount();
    this.commitAmendmentOnHold();
  }

    openModificationThankYouPage = (data) => {
      if (data) {
        const paymentResponse = JSON.parse(data.PAYMENT_RESPONSE_VO);
        if (paymentResponse) {
          this.amendResponse = JSON.parse(paymentResponse.response);
          if (this.amendResponse && this.amendResponse.amendDone) {
            this.setState({viewState: ViewState.SHOW_DETAIL});
          } else {
            this.errorMessage = this.amendResponse.responseText;
            this.errorSubText = this.amendResponse.clientDetails ? this.amendResponse.clientDetails.errorSubText : undefined;
            this.setState({viewState: ViewState.ERROR, apiError: false});
          }
        } else {
          this.trackError();
          this.setState({viewState: ViewState.ERROR, apiError: true});
        }
      }
    };

    render() {
      return (
        <View style={AtomicCss.flex1}>
          {this.state.viewState === ViewState.LOADING && <PageLoader />}
          {this.state.viewState === ViewState.NO_INTERNET && this.renderNoNetworkView()}
          {this.state.viewState === ViewState.ERROR && this.rendorError()}
          {this.state.viewState === ViewState.SHOW_DETAIL && this.renderPage()}
        </View>
      );
    }

    renderPage() {
      return (
        <CabAmendFullScreenPage
          headerText={CabBookingDetailsConstant.CHANGE_PICKUP_HEADER}
          errorText={this.amendResponse.clientDetails.successMsg}
          errorSubText={this.amendResponse.clientDetails.subText}
          btnText={this.amendResponse.clientDetails.confirmButtonText}
          btnClickHandler={this.handleClick}
          headerBackButton={this.handleClick}
          fromCity={this.amendResponse.clientDetails.fromCity}
          toCity={this.amendResponse.clientDetails.toCity}
          sourceIcon={successIcon}
        />
      );
    }

    handleClick = () => {
      CabBookingTrackingHelper.trackClickEvent(CabBookingDetailsConstant.CAB_AMEND_THANKYOU_PAGE, CabBookingDetailsConstant.CLICK_UPDATED_BOOKING_DETAILS);
      const params = {};
      const refresh ={};
      params.BOOKING_ID = this.props.response.bookingId;
      params.MMT_AUTH = this.mmtAuth;
      params.LOGGING_TRACKINFO = this.loggingTrackingInfo;
      refresh.isRefresh = true;
      params.refresh = refresh;
      if (this.props.response.parentPage && this.props.response.parentPage === 'FLIGHT_DETAILS_PAGE') {
        params.CROSS_SELL_BOOKING = true;
        params.PARENT_BOOKING_ID = this.props.response.parentBookingId;
      }
      Actions.cabBookingDetail({data: params});
    };

    rendorError = () => (
      <CabAmendFullScreenPage
        headerText={CabBookingDetailsConstant.CHANGE_PICKUP_HEADER}
        errorText={this.state.apiError ? CabBookingDetailsConstant.DEFAULT_ERROR_MESSAGE : this.errorMessage}
        errorSubText={this.state.apiError ? undefined : this.errorSubText}
        btnText={this.state.apiError ? CabBookingDetailsConstant.TRY_AGAIN : CabBookingDetailsConstant.BACK_TO_DETAILS}
        btnClickHandler={this.state.apiError ? () => {
                this.setState({viewState: ViewState.LOADING});
                this.commitAmendmentOnHold();
            } : this.handleClick}
        headerBackButton={this.state.apiError ? super.onBackIconPress : this.handleClick}
        fromCity={this.props.response.carBookingInfo.carDetail.fromCity}
        toCity={this.props.response.carBookingInfo.carDetail.toCity}
      />);

    async commitAmendmentOnHold() {
      try {
        const hasNetwork = await isNetworkAvailable();
        if (!hasNetwork) {
          this.setState({viewState: ViewState.NO_INTERNET});
          return;
        }
        const url = NetworkUtils.CAB_COMPLETE_AMENDMENT_URL;
        const userInfo = await HotelBookingModule.getRequestHeader(this.props.amendData.bookingId, CabBookingDetailsConstant.COMPLETE_AMENDMENT_SERVICE);
        this.mmtAuth = userInfo.mmtAuth;
        this.loggingTrackingInfo = userInfo.loggingTrackingInfo;
        const commonHeaders = await NetworkUtils.getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo);
        const response = await fetch(url, {
          method: PSC.POST,
          body: JSON.stringify(this.props.amendData),
          headers: commonHeaders
        });

        if (response && response.ok) {
          const data = await response.json();
          this.amendResponse = data;
          this.decideState();
        } else {
          this.setState({viewState: ViewState.ERROR, apiError: true});
          this.trackError();
        }
      } catch (error) {
        this.trackError();
        this.setState({viewState: ViewState.ERROR, apiError: true});
      }
    }

    trackError = () => {
      CabBookingTrackingHelper.trackErrorEvent('mob: cabs: Commit Amendment page:Error');
    }

    decideState = () => {
      if (this.amendResponse.success) {
        if (this.amendResponse.amendDone) {
          CabBookingTrackingHelper.trackLoadEvent(CabBookingDetailsConstant.CAB_AMEND_THANKYOU_PAGE, this.props.response);
          this.setState({viewState: ViewState.SHOW_DETAIL});
        } else if (this.amendResponse.amendHold) { /* this varibale willl not come true from backend since payments is disabled in v1 */
          CabBookingTrackingHelper.trackLoadEvent(CabBookingDetailsConstant.CAB_AMEND_HOLD_PAYMENT_PAGE, this.props.response);
          CabBookingModule.openPaymentsForAmendment(JSON.stringify(this.amendResponse.holdPGResponse));
        } else {
          this.trackError();
          this.setState({viewState: ViewState.ERROR});
        }
      } else {
        this.errorMessage = this.amendResponse.responseText;
        this.errorSubText = this.amendResponse.clientDetails ? this.amendResponse.clientDetails.errorSubText : undefined;
        this.trackError();
        this.setState({viewState: ViewState.ERROR});
      }
    }

  onHardBackPress = () => {
    if (this.state.apiError) {
      super.onBackIconPress();
    } else {
      this.handleClick();
    }
    return true;
  }
}

CabCommitAmendment.propTypes = {
  response: PropTypes.object.isRequired,
  amendData: PropTypes.object.isRequired
};

export default CabCommitAmendment;
