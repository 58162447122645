import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { StyleSheet, Platform } from 'react-native';

export const getStyle = (fonts, color) => {
  return StyleSheet.create({
    cardContainer: {
      backgroundColor: color.blackWithOpacity,
      borderRadius: 4,
      paddingHorizontal: 10,
      paddingTop: 10,
      marginBottom: 16,
      marginHorizontal: 4,
      borderLeftWidth: 3,
      borderLeftColor: color.yello,
    },
    cardText: {
      ...Platform.select({
        ios: AtomicCss.flexRow,
        android: AtomicCss.flexRow,
        web: {}
      }),
      ...AtomicCss.alignCenter,
       
      ...AtomicCss.marginBottom10,
    },
  });
};

export const getHtmlStyles = (fonts, color) => {
  return StyleSheet.create({
    div: {
      ...fonts.regularFontFamily,
      color: color.black,
    },
    b: {
      ...fonts.blackFontFamily,
    },
  });
};
