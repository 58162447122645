import {fonts} from '@mmt/legacy-commons/Styles/globalStyles';

const styles = {

  blackIconStyle:{
    width: 27,
    height: 25,
    marginRight: 6,
  },
  blackInfoTxt:{
    fontSize: 14,
    fontFamily: fonts.bold,
  }
};
  
export default styles;