import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import {View, Text, TouchableOpacity, TextInput} from 'react-native';
import getStyles from './OverlayCss';
import Button from '../../../../../Common/Button';
import { getStaticData } from '../../../../../staticData/staticData';
import RadioSection from '../../../../../Common/RadioSection';
import { LANG_AWARE } from '../../../../HotelConstants';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const RescheduleOverlay = (props) => {
  const { ...fonts } = getFont(true);
  const styles = getStyles(fonts);
  const staticData = getStaticData(LANG_AWARE.MODIFICATION);
  const [activeState, setActiveState] = useState(0);
  const [activeStateText, setActiveStateText] = useState(props.radioItem[0]);
  const [comment, setComment] = useState('');

  const handleRadio = useCallback((index) => {
    setActiveState(index);
    setActiveStateText(props.radioItem[index]);
  }, [props.radioItem]);

  const handleComment = useCallback((text) => {
    setComment(text);
  }, []);
  
  const getRadioListFormatData = () => {
    return props.radioItem.map((item, index) => ({
      scName: item,
      scCode: index
    }));
  }
  const shouldDisableContinue = () => {
    return activeState === 3 && !comment.trim();
  }
  return (
    <View style={styles.paddingBottom5}>
      <Text style={styles.contentTitle}>{staticData.reasonForRescheduleText}</Text>
      <View style={[styles.marginBottom20]}>
        <RadioSection
          data={getRadioListFormatData()}
          setActiveId={handleRadio}
          activeId={activeState}
          identifier="scCode"
        />
      </View>
      {activeState === 3 && <View style={styles.textAreaContainer}>
        <TextInput
          style={styles.textArea}
          underlineColorAndroid="transparent"
          placeholder={staticData.modificationHeaderText}
          placeholderTextColor="grey"
          numberOfLines={10}
          multiline
          onChangeText={text => handleComment(text)}
        />
      </View>}
      <View style={styles.contentFooter}>
        {props.showSkipApproval && (
        <TouchableOpacity
          style={styles.actionBtn}
          onPress={props.onSkipClicked}
        >
          <Text style={styles.actionBtnText}>{staticData.skipApprovalText}</Text>
        </TouchableOpacity>
          )}
        {!props.showSkipApproval && (
        <TouchableOpacity />
          )}
        <TouchableOpacity style={styles.actionBtn}>
          <Button
            btnTxt={staticData.sendForApprovalText}
            btnWidth={200}
            disable={shouldDisableContinue()}
            btnBg="b2bGradientBtn"
            btnClickAction="CONTINUE"
            clickHandler={() => {
              props.onContinueClicked(
                comment,
                activeStateText
              );
            }}
          />
        </TouchableOpacity>
      </View>
    </View>
  );
};

RescheduleOverlay.propTypes = {
  radioItem: PropTypes.array.isRequired,
  showSkipApproval: PropTypes.bool.isRequired,
  onSkipClicked: PropTypes.func.isRequired,
  onContinueClicked: PropTypes.func.isRequired
};
export default RescheduleOverlay;
