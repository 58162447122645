import { ViewStyle, TextStyle, ImageStyle, StyleSheet } from 'react-native';
import { DEFAULT_THEME } from 'apps/post-sales/src/theme/default.theme';

interface Styles {
  headerWrapper: ViewStyle;
  crossWrapper: ViewStyle;
  closeIcon: ImageStyle;
  addMarkupWrapper: ViewStyle;
  buttoncomp: ViewStyle;
  addMarkupContent: ViewStyle;
  paymentDetailsWrapper: ViewStyle;
  disabledContainer: ViewStyle;
  vCodeSection: ViewStyle;
  copyCodeSection: ViewStyle;
  borderBottom: ViewStyle;
  checkBoxWrapper: ViewStyle;
  checkBoxAddMarkup: ViewStyle;
  textarea: ViewStyle;
  radioContainer: ViewStyle;
  radioBg: ViewStyle;
  radioBgActive: ViewStyle;
  radioActive: ViewStyle;
  radio: ViewStyle;
  marginTop2: ViewStyle;
  headerText: TextStyle;
  markupHeading: ViewStyle;
}

const createStyles = <Theme extends typeof DEFAULT_THEME>(theme: Theme) =>
  StyleSheet.create<Styles>({
    headerWrapper: {
      flexDirection: "row",
      marginBottom: 16,
      alignItems: "flex-start",
      justifyContent: "space-between",
      padding: 15,
    },
    container: {
      flexDirection: "row",
      marginBottom: 12,
    },
    crossWrapper: {
      paddingHorizontal: 10,
      right: -10,
    },
    closeIcon: {
      width: 14,
      height: 14,
      alignSelf: "flex-start",
      top: 10,
    },
    markupHeading: {
      paddingLeft: 30,
      paddingTop: 3,
    },
    addMarkupWrapper: {
      borderWidth: 1,
      borderColor: theme.color.lightGrey,
      borderRadius: 8,
      marginBottom: 20,
      marginLeft: 20,
      marginRight: 20,
    },
    buttoncomp: {
      marginLeft: 20,
      marginRight: 20,
    },
    addMarkupContent: {
      paddingHorizontal: 14,
      paddingVertical: 14,
      borderBottomWidth: 1,
      borderBottomColor: theme.color.grey21,
    },
    paymentDetailsWrapper: {
      backgroundColor: theme.color.grey13,
      padding: 16,
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
    },
    disabledContainer: {
      opacity: 0.3,
    },
    vCodeSection: {
      backgroundColor: theme.color.grey22,
      borderWidth: 1,
      borderColor: theme.color.lightGray,
      borderRadius: 2,
      flexDirection: "row",
      alignItems: "center",
      marginBottom: 8,
    },
    copyCodeSection: {
      flexDirection: "row",
      width: 38,
      height: 40,
      borderRightWidth: 1,
      borderRightColor: theme.color.lightGray,
      alignItems: "center",
      justifyContent: "center",
      paddingVertical: 5,
      backgroundColor: theme.color.grey3,
    },
    borderBottom: {
      borderBottomWidth: 1,
      borderBottomColor: theme.color.grey21,
      paddingBottom: 12,
      marginBottom: 12,
    },
    checkBoxWrapper: {
      marginBottom: 20,
      marginLeft: 20,
    },
    defaultText: {
      color: theme.color.defaultTextColor,
    },
    checkBoxAddMarkup: {
      marginLeft: -14,
    },
    textGrey: {
      color: theme.color.grey,
    },
    textarea: {
      width: "100%",
      height: 40,
      paddingLeft: 14,
      color: theme.color.grey,
    },
    radioContainer: {
      flexDirection: "row",
      alignItems: "center",
      marginRight: 16,
    },
    radioBg: {
      flexDirection: "row",
      alignItems: "center",
      marginRight: 16,
    },
    radioBgActive: {
      backgroundColor: theme.color.lightBlue24,
    },
    radioActive: {
      borderColor: theme.color.submitDateChangeReqBtnText,
    },
    radio: {
      borderColor: theme.color.lightTextColor,
      borderWidth: 2,
      height: 18,
      width: 18,
      backgroundColor: theme.color.white,
      borderRadius: 10,
      alignItems: "center",
      justifyContent: "center",
      marginRight: 5,
    },
    optionValue: {
      color: theme.color.black,
      flex: 1,
      marginTop: 2,
    },
    textBlack2: {
      color: theme.color.black74,
    },
  });

export default createStyles;
