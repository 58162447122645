import React, {useState} from 'react';
import {View, ScrollView, Text, Image, TouchableOpacity} from 'react-native';
import styles from './RoomPhotosCss';
import Header from '../../cards/Header';
import SliderImages from '../../components/SliderImages';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import Actions from '../../../../navigation/postSalesNavigation';
import {isNotNullAndEmpty} from '../../utils/HotelBookingDetailUtil';
import { SIZE_ICON, BED_ICON, GARDEN_ICON, GREY_TICK_ICON } from '../../../HotelImageConstants';
import { getStaticData } from '../../../../staticData/staticData';
import { getRoomImages } from '../../utils/HotelBookingDetailUtil';
import {getFont, themeColors} from '../../../../PostSalesStyles';

const ROOM_AMENTIES_LIMIT = 10;

const RoomPhotos = ({response}) => {
	const { fsStyle, ...fonts } = getFont(true);
	const staticData = getStaticData();
	
	const [listshow, IsListShow] = useState(ROOM_AMENTIES_LIMIT);

	const listItem = listshow === ROOM_AMENTIES_LIMIT ? staticData.viewMoreText : staticData.viewLessText;

	const onViewMore = (list) => {
		if (listshow === ROOM_AMENTIES_LIMIT){
			IsListShow(list.length);
		}
		else {
			IsListShow(ROOM_AMENTIES_LIMIT);
		}
	}

	const onBackPress = () => {
    	Actions.pop();
  	};

	if (!getRoomImages(response)) {
		return null;
	}

	const roomsList = response.hotelBookingInfo.roomDetails;

	return(
		<View style={styles.container}>
			<View style={styles.topStickySection}>
				<Header 
					title={staticData.roomPhotosText}
					headerShadow
					onBackPress={onBackPress}
				/>
			</View>
			<ScrollView>
				<View style={styles.roomCardWrapper}>
					{roomsList && roomsList.map(room => {
						if(room && isNotNullAndEmpty(room.roomImageURL)) {
							return(
								<View style={[AtomicCss.whiteCardNew, AtomicCss.marginHz12, AtomicCss.borderRadius16]}>
									<View>
										<SliderImages 
											imageList={room.roomImageURL}
											arrows={true}
											pageInfo={true}
          									pageInfoBottom={true}
										/>
										<View style={styles.roomDetailList}>
											<View style={AtomicCss.marginBottom12}>
												<Text style={[fsStyle.font24, fonts.blackFontFamily, AtomicCss.blackText]}>{room.roomTypeName}</Text>
											</View>
											{isNotNullAndEmpty(room.roomSize) && <View style={[styles.listWidth, AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom12]}>
												<Image style={styles.sizeIcon} source={SIZE_ICON} />
												<Text style={[fsStyle.font13, fonts.regularFontFamily, AtomicCss.darkGreyText]}>{room.roomSize}</Text>
											</View>}
											{isNotNullAndEmpty(room.bedType) && <View style={[styles.listWidth, AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom12]}>
												<Image style={styles.bedIcon} source={BED_ICON} />
												<Text style={[fsStyle.font13, fonts.regularFontFamily, AtomicCss.darkGreyText]}>{room.bedType}</Text>
											</View>}
											{isNotNullAndEmpty(room.roomView) && <View style={[styles.listWidth, AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom12]}>
												<Image style={styles.gardenIcon} source={GARDEN_ICON} />
												<Text style={[fsStyle.font13, fonts.regularFontFamily, AtomicCss.darkGreyText]}>{room.roomView}</Text>
											</View>}
										</View>
									</View>
									
									{room.roomAmenties && room.roomAmenties.length > 0 && <View>
										<View style={[styles.dividor, AtomicCss.marginBottom15]}></View>
										<View style={styles.listHeading}>
											<Text style={[fonts.boldFontFamily, fsStyle.font14, AtomicCss.blackText, AtomicCss.alignLeft]}>{room.roomAmenitiesHeading || staticData.facilitiesText}</Text>
										</View>
										<View style={styles.facilityList}>
											{room.roomAmenties.map((item, index) => {
												while (index < listshow) {
													return(
														<View style={[styles.listWidth, AtomicCss.flexRow, AtomicCss.alignCenter, index%2 == 0 ? AtomicCss.marginRight15 : null]}>
															<Image style={styles.timerIcon} source={GREY_TICK_ICON} />
															<Text style={[fsStyle.font12, fonts.regularFontFamily, AtomicCss.darkGreyText]}>{item}</Text>
														</View>
													)
												}
											})}
											{room.roomAmenties.length > listshow && <View style={AtomicCss.marginBottom20}>
												<TouchableOpacity onPress={() => onViewMore(room.roomAmenties)}>
													<Text style={[{color:themeColors.linkTextColor}, fonts.boldFontFamily, fsStyle.font14]}>{listItem}</Text>
												</TouchableOpacity>
											</View>}
										</View>
									</View>}
								</View>
							)
						}
					})}
				</View>
			</ScrollView>
		</View>
  	)
}

export default RoomPhotos;