import React, { useEffect } from 'react';
import { Text, View, Image, ScrollView, BackHandler } from 'react-native';
import _ from 'lodash';
import PropTypes from 'prop-types';
import styles from './ErrorCss';
import SimpleHeader from '@mmt/legacy-commons/Common/Components/Header/SimpleHeader';
import LinkBtn from '../../Common/LinkBtn';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import Button from '../../Common/Button';
import DateChangeTrackingHelper from '../modules/DateChange/analytics/DateChangeTrackingHelper';

const ErrorPage = ({
  headerData,
  contentTitle,
  contentDescriptionList,
  errorImage,
  errorImageStyle,
  btnList,
  linkBtnList,
  onBackPressHandler,
  omniturePageName,
  failureReason,
}) => {
  useEffect(() => {
    BackHandler.addEventListener('hardwareBackPress', onBackPressHandler);
    return () => {
      BackHandler.removeEventListener('hardwareBackPress', onBackPressHandler);
    };
  }, [onBackPressHandler]);
  DateChangeTrackingHelper.trackErrorWithOutMessageAppend(omniturePageName, failureReason);
  return (
    <View style={styles.pageWrapper}>
      <SimpleHeader title={headerData.title} iconPress={onBackPressHandler} />
      <ScrollView showsVerticalScrollIndicator={false} style={styles.pageScroll}>
        <View style={[styles.whiteCard, { marginTop: -2 }]}>
          <View style={styles.cancelErrorWrapper}>
            <Image style={errorImageStyle} source={errorImage} />
            <Text style={styles.cancelTopHeading}>{contentTitle}</Text>
            {!_.isEmpty(contentDescriptionList) &&
              contentDescriptionList.map((description) => (
                <Text style={styles.cancelDescription}>{description}</Text>
              ))}
          </View>
          {!_.isEmpty(btnList) &&
            btnList.map((btnData) => (
              <View style={AtomicCss.marginBottom12}>
                <Button
                  btnTxt={btnData.title}
                  btnBg={btnData.buttonType.includes('WHITE') ? 'white' : 'gradientBtn'}
                  clickHandler={(actionType) => btnData.clickListener(actionType, true)}
                  btnClickAction={btnData.actionType}
                />
              </View>
            ))}
        </View>
        {!_.isEmpty(linkBtnList) && (
          <View>
            <View style={styles.dividerWrapper}>
              <View style={styles.dividerLine} />
              <View style={styles.orDivider}>
                <Text style={styles.orDividerText}>OR</Text>
              </View>
            </View>
            <View style={styles.customerSupportWrapper}>
              {linkBtnList.map((btnData) => (
                <LinkBtn
                  icon={btnData.btnIcon}
                  iconStyle={btnData.btnStyle}
                  linkTxt={btnData.text}
                  onBtnClick={(actionType) => {
                    btnData.clickListener(actionType, false);
                  }}
                  action={btnData.actionType}
                />
              ))}
            </View>
          </View>
        )}
      </ScrollView>
    </View>
  );
};

export default ErrorPage;

ErrorPage.propTypes = {
  headerData: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subText: PropTypes.string.isRequired,
  }).isRequired,
  contentTitle: PropTypes.string.isRequired,
  contentDescriptionList: PropTypes.arrayOf(PropTypes.string).isRequired,
  errorImage: PropTypes.oneOfType([
    PropTypes.shape({
      uri: PropTypes.string,
      headers: PropTypes.objectOf(PropTypes.string),
    }),
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.shape({
        uri: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
        headers: PropTypes.objectOf(PropTypes.string),
      }),
    ),
  ]).isRequired,
  errorImageStyle: PropTypes.object.isRequired,
  btnList: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      buttonType: PropTypes.string.isRequired,
      actionType: PropTypes.string.isRequired,
      clickListener: PropTypes.func.isRequired,
    }),
  ),
  linkBtnList: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      btnIcon: PropTypes.oneOfType([
        PropTypes.shape({
          uri: PropTypes.string,
          headers: PropTypes.objectOf(PropTypes.string),
        }),
        PropTypes.number,
        PropTypes.arrayOf(
          PropTypes.shape({
            uri: PropTypes.string,
            width: PropTypes.number,
            height: PropTypes.number,
            headers: PropTypes.objectOf(PropTypes.string),
          }),
        ),
      ]).isRequired,
      btnStyle: PropTypes.object.isRequired,
      actionType: PropTypes.string.isRequired,
      contactNumberList: PropTypes.arrayOf(PropTypes.string).isRequired,
      clickListener: PropTypes.func.isRequired,
    }),
  ),
  onBackPressHandler: PropTypes.func.isRequired,
  omniturePageName: PropTypes.string.isRequired,
  failureReason: PropTypes.string.isRequired,
};

ErrorPage.defaultProps = {
  linkBtnList: [],
  btnList: [],
};
