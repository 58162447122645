import React, { useEffect, useMemo, useState } from 'react';
import { View, Text, TouchableOpacity, UIManager, Platform } from 'react-native';
import HTMLView from 'react-native-htmlview';

import createStyle from './SelectPassengersCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import Seperator from '../../../../../../../../Common/CardLayoutSeperator';
import InputElement from '../InputElement';
import {
  getPaxNameInfo,
  getNameFieldListData,
  getPassengerFullName,
  getGenderList,
} from '../../../../../helper';
import { isEmptyArray } from '../../../../../../../../Common/commonUtil';
import Checkbox from '../../../../../../../../Common/Checkbox';
import { useTheme } from '../../../../../../../../theme/theme.context';
import { getStaticData } from '../../../../../../../../staticData/staticData';
import { NAME_CORRECTION_RULE_IDS } from '../../../../../constant';
import { getFont } from '../../../../../../../../PostSalesStyles';

const SelectPaxSection = ({
  passenger,
  selectedPax,
  setSelectedPax,
  isSelected,
  rule,
  editInfo,
  nameRvwCorrectionValidationText,
  ncSubmitResponse,
  globalInputError,
  isAlreadyRaised,
}) => {
  const staticData = getStaticData();
  const { nameCorrectionTexts, maleText, FemaleText } = staticData || {};
  const { psTheme: theme } = useTheme();
  const fonts = getFont(true);
  const styles = createStyle(theme, fonts);
  const { passengerName, paxReferenceNo, paxLineNo, paxGender } = passenger || {};
  const key = paxReferenceNo || paxLineNo;
  const { fnameErr, lnameErr, genderErr } = editInfo[key] || {};
  const fullName = getPassengerFullName(passenger);
  const [activeGender, setActiveGender] = useState(paxGender || null);

  const revieCorrectionErr = useMemo(() => {
    const data =
      !isEmptyArray(globalInputError) && globalInputError.find((item) => item.paxId == key);
    if (data && data.nameCorrectionApplicable === false) {
      return nameRvwCorrectionValidationText;
    }
    return null;
  }, [globalInputError, nameRvwCorrectionValidationText, key]);

  if (Platform.OS === 'android' && UIManager.setLayoutAnimationEnabledExperimental) {
    UIManager.setLayoutAnimationEnabledExperimental(true);
  }

  const handleFlightSegment = () => {
    if (!isAlreadyRaised) {
      setSelectedPax();
    }
  };

  useEffect(() => {
    if (selectedPax && isSelected) {
      selectedPax.updatedData = {
        ...getPaxNameInfo(selectedPax),
        ...(selectedPax.updatedData || {}),
        ...(editInfo[key] || {}),
      };
    }
  }, [selectedPax, isSelected, editInfo, key]);

  const onChangeHandler = (property, val) => {
    if (selectedPax) {
      selectedPax.updatedData[property] = val && val.trim();
    }
  };

  const checkError = ({ key }) => {
    let errStr = null;
    if (key === 'fName' && fnameErr) {
      errStr = fnameErr;
    } else if (key === 'lName' && lnameErr) {
      errStr = lnameErr;
    } else if (key === 'gender' && genderErr) {
      errStr = genderErr;
    }
    return errStr;
  };

  const renderUpdateFields = () => {
    const fields = getNameFieldListData(rule.ruleId, passengerName, nameCorrectionTexts);
    const genderList = getGenderList(maleText, FemaleText);
    switch (rule.ruleId) {
      case NAME_CORRECTION_RULE_IDS.Change_Gender: {
        const errText = checkError({ key: 'gender' });
        return (
          <View style={styles.genderList}>
            <Text
              style={[
                AtomicCss.font12,
                AtomicCss.darkGreyText,
                fonts.regularFontFamily,
                AtomicCss.marginBottom20,
              ]}
            >
              {nameCorrectionTexts.selectCorrectGender}
            </Text>
            <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, styles.tabWrapper]}>
              {genderList.map((item, index) => {
                const activeSuffix = item.id === activeGender ? 'Active' : '';
                const isLastTabItem = index === genderList.length - 1 ? styles.lastTabItem : '';
                return (
                  <TouchableOpacity
                    style={[styles.tabBox, styles[`tabBox${activeSuffix}`], isLastTabItem]}
                    onPress={() => {
                      onChangeHandler('gender', item.id);
                      setActiveGender(item.id);
                    }}
                    activeOpacity={0.8}
                  >
                    <Text style={[styles.tabText, styles[`tabText${activeSuffix}`]]}>
                      {item.title}
                    </Text>
                  </TouchableOpacity>
                );
              })}
            </View>
            {!!errText && (
              <View style={styles.allErrorWrapper}>
                <Text style={[styles.msgStyle, fonts.regularFontFamily]}>{errText}</Text>
              </View>
            )}
          </View>
        );
      }
      default:
        return (
          <View style={styles.inputWrapper}>
            <Text
              style={[
                AtomicCss.font11,
                fonts.boldFontFamily,
                AtomicCss.midGreyText,
                AtomicCss.marginBottom20,
                styles.upperText,
              ]}
            >
              {nameCorrectionTexts.enterCorrectName}
            </Text>
            {!isEmptyArray(fields) &&
              fields.map((field) => {
                const { key, title, validate, disable, value: val } = field;
                const errorText = checkError(field);
                const value =
                  selectedPax && selectedPax.updatedData && selectedPax.updatedData[key]
                    ? selectedPax.updatedData[key]
                    : val;
                return (
                  <InputElement
                    label={title}
                    key={key}
                    editable={!disable}
                    value={value}
                    onChange={(val) => {
                      onChangeHandler(key, val);
                    }}
                    validate={validate}
                    error={!!errorText || !!revieCorrectionErr}
                    msg={errorText}
                  />
                );
              })}
            {!!revieCorrectionErr && (
              <View style={styles.allErrorWrapper}>
                <HTMLView
                  value={`<q><p>${revieCorrectionErr}</p></q>`}
                  textComponentProps={{ style: styles.msgStyle }}
                />
              </View>
            )}
          </View>
        );
    }
  };

  return (
    <View style={[styles.cardWrapper]}>
      <View style={styles.checkAllWrapper}>
        <View style={[styles.checkAllWrapperInner]}>
          <TouchableOpacity
            style={[styles.checkBtn, styles.checkAllBtn, styles.paxslctionHeader]}
            activeOpacity={0.9}
            disabled={isAlreadyRaised}
            onPress={handleFlightSegment}
          >
            <View style={[styles.sectorHeader]}>
              <View style={AtomicCss.flexRow}>
                <Checkbox
                  checked={isSelected && !isAlreadyRaised}
                  onChange={handleFlightSegment}
                  size={19}
                  containerStyle={styles.checkboxAll}
                />
                <View>
                  <View style={styles.destRow}>
                    <Text style={[styles.cityText, isAlreadyRaised && AtomicCss.greyText]}>
                      {fullName}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </TouchableOpacity>
        </View>
      </View>
      {isAlreadyRaised && (
        <>
          <View style={styles.seperatorWrapper}>
            <Seperator />
          </View>
          <View style={styles.moreDtlsSection}>
            <Text
              style={[
                styles.infoTxtColor,
                AtomicCss.font13,
                fonts.boldFontFamily,
                styles.lineHeight22,
              ]}
            >
              {nameCorrectionTexts.alreadyRequestedText}
            </Text>
          </View>
        </>
      )}
      {isSelected && !isAlreadyRaised && (
        <>
          <View style={styles.passengersWrapper}>{renderUpdateFields()}</View>
        </>
      )}
    </View>
  );
};
export default SelectPaxSection;
