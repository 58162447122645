import React from 'react';
import { View, Text, StyleSheet, Platform } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { isEmpty } from 'lodash';
import { getFont } from 'apps/post-sales/src/PostSalesStyles';

const BookingConfirmedHeading = ({
  title,
  subTitle,
  titleStyle,
  subTitleStyle,
  subTitleSec,
  hotelUIFields,
  waiverRequestInfo,
  description
}) => {
  const { fsStyle, ...fonts } = getFont(true);
  const { waiverRequestStatusText = '', waiverRequestStatusTextSubHead = '' } = hotelUIFields || {};
  const { isWaiverRaised = false } = waiverRequestInfo || {};

  return (
    <>
      {isWaiverRaised ? (
        <View style={[styles.headingWrapper, AtomicCss.alignLeft, AtomicCss.marginBottom16]}>
          {!isEmpty(waiverRequestStatusText) && (
            <Text
              style={[
                fonts.blackFontFamily,
                fsStyle.font13,
                AtomicCss.lineHeight16,
                AtomicCss.marginBottom8,
                AtomicCss.textCenter,
                titleStyle,
              ]}
            >
              {waiverRequestStatusText}
            </Text>
          )}
          {!isEmpty(waiverRequestStatusTextSubHead) && (
            <Text
              style={[
                fonts.regularFontFamily,
                fsStyle.font14,
                AtomicCss.lineHeight20,
                AtomicCss.textCenter,
                subTitleStyle,
              ]}
            >
              {waiverRequestStatusTextSubHead}
            </Text>
          )}
        </View>
      ) : (
        <View style={[styles.headingWrapper, AtomicCss.alignLeft, AtomicCss.marginBottom16]}>
          {!isEmpty(title) && (
            <Text
              style={[
                fonts.blackFontFamily,
                fsStyle.font13,
                AtomicCss.lineHeight16,
                AtomicCss.marginBottom8,
                AtomicCss.textCenter,
                titleStyle,
              ]}
            >
              {title}
            </Text>
          )}
          {!isEmpty(subTitle) && (
            <Text
              style={[
                fonts.regularFontFamily,
                fsStyle.font14,
                AtomicCss.lineHeight20,
                AtomicCss.textCenter,
                subTitleStyle,
              ]}
            >
              {subTitle}
            </Text>
          )}
          {!isEmpty(subTitleSec) && (
            <Text
              style={[
                fonts.italicFontFamily,
                fsStyle.font14,
                AtomicCss.lineHeight20,
                AtomicCss.textCenter,
                subTitleStyle,
              ]}
            >
              {subTitleSec}
            </Text>
          )}
          {!isEmpty(description) && (
            <Text
              style={[
                Platform.select({
                  ios: fonts.italicFontFamily,
                  android: fonts.italicFontFamily,
                  web: {...fonts.regularFontFamily, ...AtomicCss.fontStyleItalic},
                }),
                fsStyle.font14,
                AtomicCss.lineHeight20,
                AtomicCss.textCenter,
                subTitleStyle,
              ]}
            >
              {description}
            </Text>
          )}
        </View>
      )}
    </>
  )
};

const styles = StyleSheet.create({
  headingWrapper: {
    paddingHorizontal: 16,
  },
});

export default BookingConfirmedHeading;
