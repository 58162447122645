import React, { useCallback, useRef } from 'react';
import { View, Image, Text, TouchableOpacity } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import rightBlueArrow from '@mmt/legacy-assets/src/blueArrowRight.webp';
import { getImageTransformStyling } from '../../utils/PostSaleUtil';
import { themeColors } from '../../PostSalesStyles';

const RedirectLink = ({ action, clickHandler, content, isThrottleRequired= false, throttleInterval = 1000, customStyle }) => {
  const lastClickTimeRef = useRef(0);

  const throttledClickHandler = useCallback(() => {
    const currentTime = Date.now();
    if (currentTime - lastClickTimeRef.current >= throttleInterval) {
      lastClickTimeRef.current = currentTime;
      clickHandler(action);
    }
  }, [clickHandler, throttleInterval]);

  const wrapperThrottle = () => {
    if (isThrottleRequired) {
      throttledClickHandler();
    } else {
      clickHandler(action);
    }
  }
  return (
    <View style={[styles.bottomLink, customStyle ? customStyle:{}]}>
      <TouchableOpacity
        style={[AtomicCss.flexRow, AtomicCss.spaceBetween, AtomicCss.alignCenter, AtomicCss.flex1]}
        onPress={wrapperThrottle}
      >
        <Text style={[AtomicCss.boldFont, AtomicCss.lineHeight15, AtomicCss.font14,{color: themeColors.redirectLinkTextColor}, styles.textStyle]}>
          {content ? content : action.actionLabeltext}
        </Text>
        <Image style={[styles.rightBlueArrow, getImageTransformStyling()]} source={rightBlueArrow} />
      </TouchableOpacity>
    </View>
  );
};

const styles = {
  rightBlueArrow: {
    width: 16,
    height: 16,
    tintColor: themeColors.rightArrowTintColor,
  },
  bottomLink: {
    paddingVertical: 20,
    paddingLeft: 45,
    paddingRight: 24,
    borderTopWidth: 1,
    borderColor: '#e7e7e7',
  },
  textStyle: {
    width: '90%',
  }
};

export default RedirectLink;
