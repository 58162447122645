import React from 'react';
import { View, Text, Image } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import VisaProcessingSteps from './VisaSteps';
import BaseButton, { BUTTON_VARIANT } from '../../../Common/BaseButton';
import { getFont } from '../../../PostSalesStyles';
import Actions from '../../../navigation/postSalesNavigation';
import isEmpty from 'lodash/isEmpty';
import { useTheme } from '../../../theme/theme.context';
import createStyles from './style';
import HolidayBookingTrackingHelper from '../../HolidayBookingTrackingHelper';

const AddVisaCard = ({ cardData, pageName, bookingId }) => {
  if (isEmpty(cardData)){
    return null;
  }
  const fonts = getFont();
  const { psTheme: theme } = useTheme();
  const styles = createStyles(fonts, theme);
  const {
    headingTag,
    heading,
    subHeading,
    steps,
    bottomTag,
    buttons,
    headingTagIcon,
    bottomTagIcon,
  } = cardData || {};
  const openApplyVisaPage = () => {
    HolidayBookingTrackingHelper.trackClickEvent(pageName, 'Add_Visa', '');
    Actions.holidaysAddVisa({
      bookingId: bookingId,
    });
  };
  return (
    <LinearGradient
      colors={theme.color.visaGradientColor}
      start={{ x: 0, y: 0 }}
      end={{ x: 0.0, y: 1.0 }}
      style={styles.visaProgressInfoCard}
    >
      {!isEmpty(headingTag) && (
        <View style={[styles.daysLeftInfo, AtomicCss.marginBottom10]}>
          {!isEmpty(headingTagIcon) && (
            <Image style={styles.clockIconStyle} source={{ uri: headingTagIcon }} />
          )}
          <Text style={styles.progressInfoText}>{headingTag}</Text>
        </View>
      )}
      {!isEmpty(heading) && <Text style={styles.cardHeading}>{heading}</Text>}
      {!isEmpty(subHeading) && <Text style={styles.subHeading}>{subHeading}</Text>}
      {!isEmpty(steps) && <VisaProcessingSteps steps={steps} />}
      {!isEmpty(bottomTag) && (
        <View style={styles.expressVisaWrapper}>
          {!isEmpty(bottomTagIcon) && (
            <Image style={styles.expressVisaIcon} source={{ uri: bottomTagIcon }} />
          )}
          <Text style={styles.bottomTag}>{bottomTag}</Text>
        </View>
      )}
      {!isEmpty(buttons) &&
        buttons.map((item) => (
          <View style={styles.btnWrapper}>
            <BaseButton
              variant={BUTTON_VARIANT.CAPSULE}
              text={item.text}
              textStyle={styles.buttonTextStyle}
              clickHandler={openApplyVisaPage}
            />
          </View>
        ))}
    </LinearGradient>
  );
};

export default AddVisaCard;
