import React, { useState, useMemo, useEffect } from 'react';
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  Platform,
  DeviceEventEmitter,
  NativeModules,
} from 'react-native';
import { isEmpty, noop } from 'lodash';
import styles from './uploadProffCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import SpecialClaimAddAttachmentModule from '@mmt/legacy-commons/Native/SpecialClaimAddAttachmentModule';
import { UPLOAD_ICON, SUCCESS_TICK_ICON_SM, DELETE_ICON } from '../../../FlightImageConstants';
import { getFont } from '../../../../PostSalesStyles';
import { getStaticData } from "../../../../staticData/staticData";

const UploadProofCard = ({ heading, description, onFileChanged, fileList = [], optionalText }) => {
  const { fsStyle, ...fonts } = getFont(true);
  const [attachmentLst, setAttachmentList] = useState([]);
  const isWebPlatform = Platform.OS === 'web';
  const FileUploaderComp = useMemo(() => {
    if (isWebPlatform) {
      const {
        FileUploaderModule: { FileUploader },
      } = NativeModules;
      return FileUploader;
    }
    return null;
  }, [isWebPlatform]);

  const staticData = getStaticData();
  useEffect(() => {
    if (fileList && fileList.length > 0) {
      setAttachmentList([...fileList, ...attachmentLst]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList]);

  const onAddAttachment = () => {
    const eventKey = 'special_claim_review_page';
    DeviceEventEmitter.addListener(eventKey, (data) => {
      DeviceEventEmitter.removeAllListeners(eventKey);
      const map = JSON.parse(JSON.stringify(attachmentLst));
      map.push(data);
      onFileChanged(map);
      setAttachmentList(map);
    });
    SpecialClaimAddAttachmentModule.onButtonClicked();
  };

  const onDelAttachment = (index) => {
    const map = JSON.parse(JSON.stringify(attachmentLst));
    map.splice(index, 1);
    onFileChanged(map);
    setAttachmentList(map);
  };

  return (
    <View style={[styles.whiteCard, AtomicCss.marginBottom10]}>
      {!isEmpty(optionalText) && (
        <View style={styles.tag}>
          <Text style={[styles.tagTxt, fonts.blackFontFamily, fsStyle.font12]}>{optionalText}</Text>
        </View>
      )}
      {!isEmpty(heading) && (
        <Text style={[styles.cardTitle, fonts.blackFontFamily, fsStyle.font14]}>{heading}</Text>
      )}
      {!isEmpty(description) && (
        <Text
          style={[
            styles.cardDesc,
            AtomicCss.marginBottom20,
            fonts.regularFontFamily,
            fsStyle.font12,
          ]}
        >
          {description}
        </Text>
      )}

      {attachmentLst.length === 0 && (
        <View
          style={[
            styles.uploadCard,
            AtomicCss.flexRow,
            AtomicCss.spaceBetween,
            AtomicCss.marginBottom10,
          ]}
        >
          <View style={[styles.lftDtls, AtomicCss.flexRow, AtomicCss.alignCenter]}>
            <Image style={styles.uploadIcon} source={UPLOAD_ICON} />
            <View>
              <Text style={[styles.uploadCardHeading, fonts.blackFontFamily, fsStyle.font14]}>
                {staticData.attachProofText}
              </Text>
              <Text style={[styles.uploadCardInfo, fonts.regularFontFamily, fsStyle.font12]}>
                Jpeg/Png
              </Text>
            </View>
          </View>
          <TouchableOpacity
            onPress={isWebPlatform ? noop() : onAddAttachment}
            style={styles.linkWrpper}
          >
            {isWebPlatform && !!FileUploaderComp && (
              <FileUploaderComp
                onFileChanged={onFileChanged}
                setAttachmentList={setAttachmentList}
                attachmentLst={attachmentLst}
                text={staticData.uploadText}
              />
            )}
            {!isWebPlatform && (
              <Text style={[styles.linkTxt, fonts.boldFontFamily, fsStyle.font14]}>{staticData.uploadText}</Text>
            )}
          </TouchableOpacity>
        </View>
      )}
      {attachmentLst.length > 0 &&
        attachmentLst.map((data, index) => (
          <View
            style={[
              styles.uploadCard,
              styles.attachedProofWrapper,
              index !== attachmentLst.length - 1 ? AtomicCss.marginBottom10 : '',
            ]}
          >
            <View>
              <Image
                style={styles.uploadproffImg}
                source={{ uri: `${!isWebPlatform ? 'file://' : ''}${data.filePath}` }}
              />
            </View>

            <View
              style={[
                AtomicCss.flexRow,
                AtomicCss.alignCenter,
                AtomicCss.spaceBetween,
                styles.attachedProof,
              ]}
            >
              <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, { width: '90%' }]}>
                <Text style={[styles.uploadCardHeading, fonts.blackFontFamily, fsStyle.font14]}>
                  {data.fileName}
                </Text>
                <Image style={styles.successIconStyle} source={SUCCESS_TICK_ICON_SM} />
              </View>
              <TouchableOpacity onPress={() => onDelAttachment(index)}>
                <Image style={styles.deleteIconStyle} source={DELETE_ICON} />
              </TouchableOpacity>
            </View>
          </View>
        ))}
      {attachmentLst.length > 0 && attachmentLst.length < 5 && (
        <TouchableOpacity onPress={isWebPlatform ? noop() : onAddAttachment}>
          {isWebPlatform && !!FileUploaderComp && (
            <FileUploaderComp
              onFileChanged={onFileChanged}
              setAttachmentList={setAttachmentList}
              attachmentLst={attachmentLst}
              text="+ ADD MORE"
            />
          )}
          {!isWebPlatform && (
            <Text
              style={[styles.linkTxt, AtomicCss.marginTop20, fonts.boldFontFamily, fsStyle.font14]}
            >
              + ADD MORE
            </Text>
          )}
        </TouchableOpacity>
      )}
    </View>
  );
};

export default UploadProofCard;
