import { Animated, Easing, Linking } from "react-native";
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import Actions from '../../navigation/postSalesNavigation';
import { setWebViewAuth } from "../../utils/PostSaleUtil";
import { getRefNamefromDeepLink, getEventNameFromDeepLink } from '../../flights/FlightBookingUtil';
import { DEEP_LINK_PAGE } from "../../PostSalesConstant";
import FlightBookingTrackingHelper from "../../flights/modules/details/helper/FlightBookingTrackingHelper";

// this function takes care of deepLink for customer support card only.
// it navigates the user to the details page and scrolls to the section the user requested help
export const customerSupportDeepLinkHandler = (bookingId, deepLinkPage) => {
    let isDeepLinkHandled = false;
    switch(deepLinkPage) {
        case DEEP_LINK_PAGE.FLIGHT_DATE_CHANGE:
        case DEEP_LINK_PAGE.FLIGHT_WEB_CHECK_IN:
        case DEEP_LINK_PAGE.FLIGHT_CANCELLATION: {
            Actions.flightBookingDetail({
                BOOKING_ID: bookingId,
                scrollToSection: getRefNamefromDeepLink(deepLinkPage),
            });
            FlightBookingTrackingHelper.trackDeepLinkClickEvent({
                eventName: getEventNameFromDeepLink(deepLinkPage),
            });
            isDeepLinkHandled = true;
            break;
        };
        default : 
            break;
    };
    return isDeepLinkHandled;
}; 


// function to animate customer support floating card on scroll
export const scrollHelper = (cardWidth, textWidth) => {
    Animated.timing(cardWidth, {
        toValue: 60,
        duration: 50,
        easing: Easing.linear,
        useNativeDriver: false,
    }).start();
    Animated.timing(textWidth, {
        toValue: 0,
        duration: 0,
        useNativeDriver: false,
    }).start();
};

export const cardClickHandler = async (omnitureTracking, deepLinkHandler, actionUrl, isWebView, isCTA) => {
    const userInfo = await HotelBookingModule.getRequestHeader('', 'NEED_HELP');
    if (!isCTA){
      omnitureTracking.trackClickEvent();
    }
    if (isWebView) {
        Actions.needHelpWebView({
            url: actionUrl,
            injectedJavaScript: setWebViewAuth(userInfo.mmtAuth),
            deepLinkHandler,
            mmtAuth: userInfo.mmtAuth,
        });
    } else {
        Linking.openURL(actionUrl);
    }
}

