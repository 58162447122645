import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';
import { StyleSheet, Platform } from 'react-native';
import { getFont } from '../../PostSalesStyles';

export default (theme) => {
  const fonts= getFont(true);
  const styles = StyleSheet.create({
    btn_default: {
      borderRadius: 4,
      paddingHorizontal: 20,
      paddingVertical: 14,
      ...getPlatformElevation(2),
    },
    btn_wrapper: {
      backgroundColor: 'transparent',
      ...getPlatformElevation(Platform.select({ios: 2, android: 2, web:0}))
    },
    txt_default: {
      fontSize: 14,
      textAlign: 'center',
      color: theme.color.white,
      ...fonts.blackFontFamily,
    },
    textContainer: { flexDirection: 'row', alignItems: 'center' },
    subTxt_deafult: {
      color: theme.color.grayBg,
    },
    capsuleBtn: {
      borderRadius: 25,
    },
    outlined: {
      borderWidth: 1,
      borderColor: theme.color.primary,
    },

    arrowRtStyle: {
      width: 18,
      height: 14,
      tintColor: theme.color.white,
      marginLeft: 10,
    },
    noShadowStyle: {
      ...getPlatformElevation(0),
      paddingVertical: 12,
    },
  });
  return styles;
};
