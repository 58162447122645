import React from 'react';
import { Dimensions, Platform, I18nManager, BackHandler, NativeModules, Alert } from 'react-native';
import isEmpty from 'lodash/isEmpty';
import { BASE_CDN_IMAGE_PATH, CORPORATE_LOB_CODES, LOBNAMES } from '../PostSalesConstant';
import { AbConfigKeyMappings, getPokusConfig, getPokusConfigFetchIfNeeded } from '@mmt/legacy-commons/Native/AbConfig/AbConfigModule';
import { PokusLobs } from '@mmt/legacy-commons/Native/AbConfig/AbConfigKeyMappings';
import Actions from '../navigation/postSalesNavigation';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import ViewControllerModule from '@mmt/legacy-commons/Native/ViewControllerModule';
import FlightBookingModule from '@mmt/legacy-commons/Native/FlightBookingModule';
import RailBookingDetailConstant from '../Rails/details/RailBookingDetailConstant';
import TGSBookingConstants from '../TripGuarantee/Utils/TGSBookingConstants'
import { isEnglishLangSelected, getUserProfileType, getUserCountryCode } from '@mmt/legacy-commons/AppState/AppUserConfig';
import {
  getCommonHeaders,
  INITALIZE_IVR_PRIVILIGE_SESSION,
  UPLOAD_ATTACHMENT_URL,
  VERIFY_BOOKING_LANG,
  FLIGHT_DOWNLOAD_TICKET,
  RAIL_DOWNLOAD_TICKET,
  DOWNLOAD_AWS_DOCUMENT,
  HOLIDAY_VOUCHER_PDF_URL,
  TGS_DOWNLOAD_INVOICE
} from './NetworkUtils';
import { showShortToast } from '@mmt/legacy-commons/Common/Components/Toast';
import SpecialClaimAddAttachmentModule from '@mmt/legacy-commons/Native/SpecialClaimAddAttachmentModule';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import { themeColors } from '../PostSalesStyles';
import { getStaticData } from '../staticData/staticData';
import { getUserSelectedLanguage } from '@mmt/legacy-commons/AppState/AppUserConfig';
import { LANGUAGE_MAP, PROFILE_TYPE } from '@mmt/legacy-commons/Common/constants/AppConstants';
import PostSalesVernac from '../PostSalesVernac';
import { FLIGHT_DOWNLOAD_TRIGGERS } from '../flights/modules/details/FlightBookingDetailsConstant';
import { HolidayBookingDetailsConstant, HOLIDAY_DOWNLOAD_TRIGGERS } from '../Holiday/HolidayBookingConstants';
import { getRootTag } from '@mmt/legacy-commons/AppState/RootTagHolder';
import { ERROR_REQUEST_ICON, ERROR_REQUEST_ICON_GCC } from '../flights/FlightImageConstants';
import { isNetworkAvailable } from '@mmt/legacy-commons/Common/utils/AppUtils';
import { PAGE_BOOKINGS_STATUS } from '../summary/BookingSummaryV5/summaryConstants';
import { isGCCBooking } from '../Common/commonUtil';
import { isCorporateUser } from 'packages/legacy-commons/Native/UserSession/UserSessionModule';
import Toast, { THEME_DARK } from 'packages/legacy-commons/Common/Components/Toast2';

export function isCorporateBooking(lobCode) {
  return CORPORATE_LOB_CODES.includes(lobCode);
}
export function isMyraEnabled(lobCode) {
  return (
    getPokusConfig(PokusLobs.POST_SALES, AbConfigKeyMappings.myraChatEnabled, false) &&
    !isCorporateBooking(lobCode)
  );
}

export function isTripIdeaCardEnabled() {
  return (
    getPokusConfig(PokusLobs.POST_SALES, AbConfigKeyMappings.showTICardonMytrips, false)
  );
}


export function isNewAncillaryEnabled() {
  return (
    getPokusConfig(PokusLobs.POST_SALES, 'NewAncillaryModule', false)
  );
}

export function openFlightAncillary(props) {
  return Actions.openAncillary(props);
}

export async function isPreSalesEnabled() {
  const preSalesEnabled = await getPokusConfigFetchIfNeeded(PokusLobs.POST_SALES, AbConfigKeyMappings.Planningpresales, false);
  return preSalesEnabled;
}

export async function isBookingSummaryPaginationEnabled() {
  const mmtpValue = await getPokusConfigFetchIfNeeded(PokusLobs.POST_SALES, AbConfigKeyMappings.mmtp, false)
  return mmtpValue;
}

export async function isRevampedBookingSummary() {
  const isGCC = await isGCCBooking();
  const isCorporate = await isCorporateUser();
  const myTripsRevamp = await getPokusConfigFetchIfNeeded(PokusLobs.POST_SALES, AbConfigKeyMappings.myTripsRevampKey, false);
  return !isGCC  && !isCorporate && myTripsRevamp;
}


export function isTGSUpgradeEnabled() {
  return getPokusConfig(PokusLobs.POST_SALES, AbConfigKeyMappings.isTGSEnabled, false);
}

export function getAcmeXSellConfig() {
  return getPokusConfig(PokusLobs.ACME, AbConfigKeyMappings.acmeXsell_config, null);
}

export function isTalkToUsEnabled() {
  return getPokusConfig(PokusLobs.POST_SALES, AbConfigKeyMappings.TalkToUsMyTrips, true);
}

export function isEscalateEnabled() {
  return getPokusConfig(PokusLobs.POST_SALES, AbConfigKeyMappings.EscalateMyTrips, true);
}
export const shouldShowCsatPopupCard = () => {
  return getPokusConfig(PokusLobs.POST_SALES, AbConfigKeyMappings.mytripsCSATApp, false);
}

export const isBusFlexiEnabled = () => {
  return getPokusConfig(PokusLobs.POST_SALES, AbConfigKeyMappings.showDateChangeBus, false);
}

export const isAddBookingFlowEnabled = () => {
  return getPokusConfig(PokusLobs.POST_SALES, AbConfigKeyMappings.addBooking, false)

}

export const isWifiCallingEnabled = () => {
  return getPokusConfig(PokusLobs.POST_SALES, AbConfigKeyMappings.voipPostSales, false);
}

export function isAddVisaEnabled(){
  return getPokusConfig(PokusLobs.POST_SALES, AbConfigKeyMappings.AddVisa_HLD_MyTrips, false);
}

export const appendCurrencyAndRound = (amount, currencySymbol) => {
  if (amount) {
    const roundedAmount = Math.round(amount);
    if (currencySymbol === undefined || currencySymbol === '') {
      return `₹ ${roundedAmount} `;
    }
    return `${currencySymbol} ${roundedAmount} `;
  }
  return '';
};
export const appendCurrency = (amount, currencySymbol) => {
  if (amount !== null && amount !== undefined) {
    if (isEmpty(currencySymbol)) {
      return `₹ ${amount}`;
    }
    return `${currencySymbol} ${amount}`;
  }
  return '';
};
export function updateRegionQueryParams(url, headers) {
  const { region, currency, language } = headers;
  url = updateQueryParams(url, 'region', region);
  url = updateQueryParams(url, 'currency', currency);
  url = updateQueryParams(url, 'language', language);
  return url;
}
export function updateQueryParams(url, key, value) {
  if (url.indexOf(`${key}=`) === -1) {
    const queryString = `${key}=${value || ''}`;
    if (url.indexOf('?') === -1) {
      url += `?${queryString}`;
    } else {
      url += `&${queryString}`;
    }
  } else {
    const urlToken = url.split(`${key}=`);
    const restParams = urlToken[1].split('&');
    restParams[0] = value;
    url = `${urlToken[0] + key}=${restParams.join('&')}`;
  }
  return url;
}
export const isValidPattern = (pattern, val) => {
  if (pattern) {
    return pattern.test(val);
  }
  return true;
};

export const isIosClient = () => Platform.OS === 'ios';

export const removeHTMLTags = (text) => text.replace(/(<([^>]+)>)/gi, '');

export const unescapeHTML = (str) => {
  const escapeChars = {
    lt: '<',
    gt: '>',
    quot: '"',
    apos: "'",
    amp: '&',
  };
  return str.replace(/\&([^;]+);/g, (entity, entityCode) => {
    let match;

    if (entityCode in escapeChars) {
      return escapeChars[entityCode];
    } else if ((match = entityCode.match(/^#x([\da-fA-F]+)$/))) {
      return String.fromCharCode(parseInt(match[1], 16));
    } else if ((match = entityCode.match(/^#(\d+)$/))) {
      return String.fromCharCode(~~match[1]);
    }
    return entity;
  });
};

export const openErrorPage = (
  errData,
  backPressHandler,
  tryAgainClick,
  bookingId,
  flowName,
  navigation,
  others
) => {
  const { psLob, langAware, renderComponent, isCorporateBooking, trackClickableEvent } = others || {};
  // As we are getting errorPageData inside errData in few scenario, so assgining it to errorData
  const backendErrorPageData = errData?.creditShellAirlinesErrorData || errData?.errorPageData || {};
  const errorData = { ...errData, ...backendErrorPageData };
  errorData.tryAgainClick = tryAgainClick;
  errorData.errorImage = getErrorPageImage(bookingId);
  errorData.errorImageStyle = {
    width: 60,
    height: 55,
    marginBottom: 25,
  };
  errorData.onBackPressHandler = backPressHandler;
  errorData.bookingId = bookingId;
  errorData.flowName = flowName;
  errorData.psLob = psLob;
  errorData.langAware = langAware;
  errorData.renderComponent = renderComponent;
  errorData.isCorporateBooking = isCorporateBooking
  errorData.trackClickableEvent = trackClickableEvent
  Actions.commonError(errorData);
}
export const getDefaultErrorPageData = () => {
  const errorData = {};
  const {
    tryAgainText,
    nameChangeThankYouText: {
      items: {
        defaultFailureText: { heading, description },
      },
    },
    errorText: { errorText },
  } = getStaticData();
  errorData.heading = heading;
  errorData.description = description;
  errorData.mobileHeaderData = {};
  errorData.mobileHeaderData.title = errorText;
  const ctaList =
    ' [{\n' +
    '            "actionId": "TRY_AGAIN",\n' +
    '            "actionText": ' +
    tryAgainText +
    ',\n' +
    '            "actionUrl": null,\n' +
    '            "type": 1\n' +
    '        }]';
  errorData.ctaList = JSON.parse(ctaList);
  return errorData;
};

export const openThankYouPage = (response, type) => {
  const { heading, description, ctaList, bookingId } = response;
  Actions.commonSubmitRequestThankYou({
    icon: getImagePath('date_change_successful_icon.webp'),
    iconStyle: {
      width: 80,
      height: 80,
      marginBottom: 8,
    },
    title: heading,
    descriptionList: [description],
    btnTitle: ctaList[0].actionText,
    bookingId,
    type: type,
    lob: LOBNAMES.FLIGHT
  });
};

export function convertFileStringToBytes(input) {
  const data = input.includes('base64') ? input.split('base64,')[1] : input;
  const byteCharacters = atob(data);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  return byteCharacters;
}
export const appendPlatformInfo = (url, userInfo) => {
  const { loggingTrackingInfo } = userInfo || {};
  const { appVersion } = loggingTrackingInfo ? JSON.parse(loggingTrackingInfo) : {};
  const platformParam = `trackPlatform=${Platform.OS}app`;
  const appVersionParam = `appVersion=${appVersion}`;
  url += `${url.indexOf('?') === -1 ? '?' : '&'}${platformParam}&${appVersionParam}`;
  return url;
};

const chars = {
  ascii() {
    return 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
  },
  indices() {
    if (!this.cache) {
      this.cache = {};
      const ascii = chars.ascii();
      for (let c = 0; c < ascii.length; c++) {
        const chr = ascii[c];
        this.cache[chr] = c;
      }
    }
    return this.cache;
  },
};
const atob = (b64) => {
  const indices = chars.indices(),
    pos = b64.indexOf('='),
    padded = pos > -1,
    len = padded ? pos : b64.length;
  let i = -1;
  let data = '';
  while (i < len) {
    const code =
      (indices[b64[++i]] << 18) |
      (indices[b64[++i]] << 12) |
      (indices[b64[++i]] << 6) |
      indices[b64[++i]];
    if (code !== 0) {
      data += String.fromCharCode((code >>> 16) & 255, (code >>> 8) & 255, code & 255);
    }
  }
  if (padded) {
    data = data.slice(0, pos - b64.length);
  }
  return data;
};
export const initialiseIVR = async (bookingId, uuid) => {
  try {
    const userInfo = await HotelBookingModule.getRequestHeader(
      bookingId,
      'INITALIZE_IVR_PRIVILIGE_SESSION',
    );
    const requestData = {
      bookingId,
      uuid,
    };
    await fetch(INITALIZE_IVR_PRIVILIGE_SESSION, {
      method: 'POST',
      headers: await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo),
      body: JSON.stringify(requestData),
    });
  } catch (error) {
    console.log('API error No need to do anything');
  }
};
function _p8(s) {
  const p = `${Math.random().toString(16)}000000000`.substr(2, 8);
  return s ? `-${p.substr(0, 4)}-${p.substr(4, 4)}` : p;
}
export function CreateGuid() {
  return _p8() + _p8(true) + _p8(true) + _p8();
}
export const setWebViewAuth = (mmtAuth, isGcc) => {
  return `
    (function(){

      function getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for(var i = 0; i <ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
      }
      var ccdeValue = getCookie('ccde');
      var auth = getCookie('mmt-auth');
      var shouldReload = false;
      if(auth != '${mmtAuth}') {
        document.cookie = 'mmt-auth=${mmtAuth}';
        if(!ccdeValue && ${isGcc}) {
          document.cookie = 'ccde=ae';
        }
        shouldReload = true;
      } else if(!ccdeValue && ${isGcc}) {
        document.cookie = 'ccde=ae';
        shouldReload = true;
      }
      if(!${isGcc} && ccdeValue === 'ae'){
        document.cookie = 'ccde=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        shouldReload = true;
      }
      if(shouldReload){
        location.reload();
      }
    })();
    true;
  `;
};

export const getScreenWidth = () =>
  Platform.OS === 'ios' ? Dimensions.get('screen').width : Dimensions.get('window').width;

export const DEVICE_WINDOW =
  Platform.OS === 'ios' ? Dimensions.get('screen') : Dimensions.get('window');

export const openCustomerFormInWebView = async (action, bookingId, type, navigation) => {
  if (action && action.url) {
    const PLATFORM_SOURCE = Platform.OS === 'android' ? 'ANDROID' : 'IOS';
    const { webViewHeader, url } = action;
    const userInfo = await HotelBookingModule.getRequestHeader(bookingId, type);
    let navigationObj = Actions;
    if (navigation) {
      navigationObj = navigation;
    }
    navigationObj.openPsWebView({
      headerText: webViewHeader,
      headerIcon: require('@mmt/legacy-assets/src/back.webp'),
      url: url + `&source=${PLATFORM_SOURCE}`,
      injectedJavaScript: setWebViewAuth(userInfo.mmtAuth),
      whiteHeader: true,
    });
  }
};

export const setRegionCookie = () => {
  return `
      (function(){
        document.cookie = "ccde=ae"
      })();
  `;
};

export const imageTransformY = { transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }] };

export const appendDotWithText = (text) => ` • ${text}`;

export const openWebView = async (
  headerText,
  headerIcon,
  url,
  backPressHandler,
  bookingId,
  openWebViewReason,
  injectedJavaScript,
  appendMMTAuth = true,
  navigation,
) => {
  let javascript = '';
  if (injectedJavaScript) {
    javascript += injectedJavaScript;
  }
  if (appendMMTAuth) {
    const userInfo = await HotelBookingModule.getRequestHeader(bookingId, openWebViewReason);
    javascript += setWebViewAuth(userInfo.mmtAuth);
  }
  let navigationObj = Actions;
  if (navigation) {
    navigationObj = navigation;
  }
  navigationObj.openPsWebView({
    headerText: headerText,
    headerIcon: headerIcon,
    url,
    backPressCb: backPressHandler,
    injectedJavaScript: javascript,
  });
};

export const getImageTransformStyling = () => {
  const imageTransformY = { transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }] };
  return imageTransformY;
};

export const backPressHandler = (navigation) => {
  // simulate hardware back press
  const somethingPoped = navigation ? navigation.pop() : Actions.pop();
  if (somethingPoped != null && !somethingPoped) {
    if (Platform.OS === 'ios') {
      ViewControllerModule.popViewController(getRootTag());
    } else {
      BackHandler.exitApp();
    }
  }
};

export function getImagePath(imageName) {
  return { uri: `https://imgak.mmtcdn.com/mima/images/mobile/${imageName}` };
}
export const removeParam = (key, sourceURL) => {
  try {
    let rtn = sourceURL.split('?')[0],
      param,
      params_arr = [],
      queryString = sourceURL.indexOf('?') !== -1 ? sourceURL.split('?')[1] : '';
    if (queryString !== '') {
      params_arr = queryString.split('&');
      for (var i = params_arr.length - 1; i >= 0; i -= 1) {
        param = params_arr[i].split('=')[0];
        if (param === key) {
          params_arr.splice(i, 1);
        }
      }
      if (params_arr.length) {
        rtn = rtn + '?' + params_arr.join('&');
      }
    }
    return rtn;
  } catch (e) {
    return sourceURL;
  }
};

export const isCorporateBookingId = (bookingId) => {
  return bookingId && (bookingId.startsWith('NN9') || bookingId.startsWith('NF9'));
};

export const isPhoneNumUri = (text) => {
  return text.startsWith('tel');
};

export const getImageUrlFromMap = (imgMap, img, device = 'Mobile') => {
  if (imgMap && img && imgMap[img]) {
    return imgMap[img][device];
  }
  return null;
};

export const checkMyPartner = () => {
  return Platform.OS === 'web' && GenericModule.checkMyPartner();
};

export const checkIndianRegion = () => {
  return Platform.OS === 'web' && GenericModule.checkIndianRegion();
}

export const isPersonalBooking = () => {
  return Platform.OS === 'web' && GenericModule.checkPersonalBooking();
}
export const getButtonGradientColor = (isCorpBooking) => {
  return isCorpBooking ? ['#f85a6e', '#f27237'] : themeColors.gradientBtnColor;
};

export const escapeHTML = (unsafe_str) => {
  return unsafe_str
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/\"/g, '&quot;')
    .replace(/\'/g, '&#39;');
};

export const isInvalidUploadDocSize = (fileSizeInBytes, maxFileSizeInMB) => {
  return fileSizeInBytes / 1024 / 1024 > maxFileSizeInMB;
};
export const getImageByteCharacters = async (imagePath) => {
  const imageResponse = await SpecialClaimAddAttachmentModule.convertToBase64(imagePath);
  return imageResponse.imageResponse;
};

export const fileUploadAPI = async (fileDataBase64Str, data, bookingID) => {
  const { fileName } = data || {};
  const token = fileName ? fileName.split('.') : [];
  const userInfo = await HotelBookingModule.getRequestHeader(bookingID, 'SPECIAL_CLAIM_UPLOAD_DOC');
  const requestBody = {
    authId: 'AUTOMATION',
    userId: 'AUTOMATION',
    source: 'MIMA',
    bookingID: bookingID,
    documentID: CreateGuid(),
    applicationName: 'mmtmidofficein',
    fileName,
    fileExtension: token[token.length - 1],
    fileContent: fileDataBase64Str,
    createdDate: new Date(),
  };
  return fetch(`${UPLOAD_ATTACHMENT_URL}`, {
    headers: await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo),
    method: 'POST',
    body: JSON.stringify(requestBody),
  });
};

export const downloadEticketVoucher = async (
  bookingId,
  triggerName,
  url,
  requestType,
  fileName,
) => {
  try {
    const hasNetwork = await isNetworkAvailable();
    if (!hasNetwork) {
      return;
    }
    const userInfo = await HotelBookingModule.getRequestHeader(bookingId, requestType);
    const commonHeaders = await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo);
    const request = {
      bookingId: bookingId,
      sendEticket: false,
      sendInvoice: false,
      triggerName: triggerName,
    };

    const apiResponse = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(request),
      headers: commonHeaders,
    });
    if (apiResponse.ok) {
      const body = await apiResponse.json();
      if (body.success) {
        await GenericModule.openPDFViewer(fileName, body.pdfString);
      } else {
        if (!isEmpty(body.responseText)) {
          Alert.alert(body.responseText);
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
};

export const externalLinkPressHandler = (url) => {
  if (url.includes('?')) {
    url += '&open=browser';
  } else {
    url += '?open=browser';
  }
  const isCorp = getUserProfileType() === PROFILE_TYPE.BUSINESS;
  if (isCorp) {
    url += '&isCorporate=true';
  }
  url += `&region=${getUserCountryCode()}`;
  GenericModule.openDeepLink(url);
};

export const isLostBookingData = (data) => {
  return data && data.lostBooking;
};

export const getNewUUID = () => {
  const s4 = () =>
    Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4() + s4() + s4()}`;
};

export const getTranslatedTextFor = (val) => {
  const {
    upcomingCapsText,
    completedCapsText,
    cancelledCapsText,
    unsuccessfulCapsText,
    failedCapsText,
    recentText,
    olderText,
    seatText,
    mealText,
    baggageText
  } = getStaticData();

  switch (val) {
    case 'UPCOMING':
      return upcomingCapsText;
    case 'COMPLETED':
      return completedCapsText;
    case 'CANCELLED':
      return cancelledCapsText;
    case 'UNSUCCESSFUL':
      return unsuccessfulCapsText;
    case 'FAILED':
      return failedCapsText;
    case 'RECENT':
      return recentText.toUpperCase();
    case 'OLDER':
      return olderText.toUpperCase();
    case "Seat":
      return seatText;
    case "Meal":
      return mealText;
    case "Baggage":
      return baggageText;
    default:
      return val;
  }
};

export const getTranslatedTextForNew = (val) => {
  const {
    upcomingText,
    completedText,
    cancelledText,
    failedText,
    onTripText,
    awaitingText,
    planningText,
  } = getStaticData();

  switch (val) {
    case PAGE_BOOKINGS_STATUS.UPCOMING:
      return upcomingText;
    case PAGE_BOOKINGS_STATUS.COMPLETED:
      return completedText;
    case PAGE_BOOKINGS_STATUS.CANCELLED:
      return cancelledText;
    case PAGE_BOOKINGS_STATUS.FAILED:
      return failedText;
    case PAGE_BOOKINGS_STATUS.ONTRIP:
      return onTripText;
    case PAGE_BOOKINGS_STATUS.AWAITING:
      return awaitingText;
    case PAGE_BOOKINGS_STATUS.PLANNING:
      return planningText;
    default:
      return val;
  }
};

export const islangValidationRequired = ({ fromMyTrips } = {}) => {
  const appLang = getUserSelectedLanguage();
  return Platform.OS !== 'web' && !fromMyTrips && appLang !== LANGUAGE_MAP.eng;
};

export const verifyBookingLang = async (bookingId, lob) => {
  try {
    const userInfo = await HotelBookingModule.getRequestHeader(bookingId, 'VERIFY_BOOKING_LANG');
    const res = await fetch(`${VERIFY_BOOKING_LANG}${bookingId}`, {
      method: 'GET',
      headers: await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo, {
        psLob: LOBNAMES.POST_SALES,
      }),
    });
    if (res && res.ok) {
      const body = await res.json();
      if (body.bookingLanguages && body.bookingLanguages.includes(getUserSelectedLanguage())) {
        lob && PostSalesVernac.updateVernacSupportLob(lob, true);
        return true;
      }
    }
    backPressHandler()
    setTimeout(() => {
      GenericModule.openDeepLink(`mmyt://tripDetails/?page=tripSummary&showLangSwitcher=true&lob=${lob}&bookingId=${bookingId}`);
    }, 0);
    return false;
  } catch (error) {
    backPressHandler()
    setTimeout(() => {
      GenericModule.openDeepLink(`mmyt://tripDetails/?page=tripSummary&showLangSwitcher=true&lob=${lob}&bookingId=${bookingId}`);
    }, 0);
    return false;
  }
};

export const staticTextVarHandler = (staticTextStr, replaceMap = {}) => {
  for (let key in replaceMap) {
    staticTextStr = staticTextStr.replace(`{${key}}`, replaceMap[key]);
  }
  return staticTextStr;
}

export const millisToSeconds = (millis) => {
  const seconds = (millis / 1000).toFixed(0);
  return seconds < 10 ? '0' + seconds : seconds
};

export const addSpaceWithAmount = (amt) => {
  return amt ? `${amt} ` : '';
}

export const isScrollViewCloseToBottom = ({ layoutMeasurement, contentOffset, contentSize }, paddingToBottom = 30) => {
  if (layoutMeasurement && contentOffset && contentSize) {
    return layoutMeasurement.height + contentOffset.y >= contentSize.height - paddingToBottom;
  }
};

export const proceedDownload = async (response, triggerName, uniqueIDType, failedText, body) => {
  const bookingId = response.bookingID;
  if (triggerName && bookingId) {
    const requestBody = body || {
      sendEticket: false,
      sendInvoice: false,
      userId: Platform.OS.toUpperCase(),
      triggerName,
      bookingId,
    };
    const userInfo = await HotelBookingModule.getRequestHeader(bookingId, uniqueIDType);
    const headers = await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo);
    !isEnglishLangSelected() ?
      await downloadRequest(
        requestBody,
        { ...headers, psLob: LOBNAMES.POST_SALES },
        { triggerName, bookingId }, failedText
      ) : await downloadRequest(requestBody, headers, { triggerName, bookingId }, failedText);
  }
}

const _getDownloadFileName = (val, bookingId) => {
  let documentType = '';
  switch (val) {
    case RailBookingDetailConstant.ACTION_TICKET_TRIGGER:
      documentType = 'ETicket';
      break;
    case RailBookingDetailConstant.ACTION_INVOICE_TRIGGER:
      documentType = 'Invoice';
      break;
    case FLIGHT_DOWNLOAD_TRIGGERS.E_TICKET:
      documentType = 'ETicket';
      break;
    case FLIGHT_DOWNLOAD_TRIGGERS.INVOICE:
      documentType = 'Invoice';
      break;
    case FLIGHT_DOWNLOAD_TRIGGERS.INSURANCE:
      documentType = 'Insurance';
      break;
    case FLIGHT_DOWNLOAD_TRIGGERS.BOARDING_PASS:
      documentType = 'Boarding_Pass';
      break;
    case HOLIDAY_DOWNLOAD_TRIGGERS.HOLIDAY_BOOKING_CARD:
      documentType = 'Booking_Card';
      break;
    case HOLIDAY_DOWNLOAD_TRIGGERS.HOLIDAY_ADD_ON_VOUCHER:
      documentType = 'AddOn_Voucher';
      break;
    case TGSBookingConstants.TGS_INVOICE_TRIGGER:
      documentType = 'Invoice';
      break;
    default:
      break;
  }
  return bookingId + '_' + documentType + '.pdf';
};

const getDownloadURL = (triggerName) => {
  switch (triggerName) {
    case RailBookingDetailConstant.ACTION_TICKET_TRIGGER:
    case RailBookingDetailConstant.ACTION_INVOICE_TRIGGER:
      return RAIL_DOWNLOAD_TICKET;
    case FLIGHT_DOWNLOAD_TRIGGERS.E_TICKET:
    case FLIGHT_DOWNLOAD_TRIGGERS.INVOICE:
    case FLIGHT_DOWNLOAD_TRIGGERS.FLIGHT_ETICKET_MYPARTNER:
      return FLIGHT_DOWNLOAD_TICKET;
    // case FLIGHT_DOWNLOAD_TRIGGERS.BAGGAGE:
    //     return FLIGHT_DOWNLOAD_BAGGAGE_TAG;
    case FLIGHT_DOWNLOAD_TRIGGERS.BOARDING_PASS:
    case FLIGHT_DOWNLOAD_TRIGGERS.INSURANCE:
      return DOWNLOAD_AWS_DOCUMENT;
    case HOLIDAY_DOWNLOAD_TRIGGERS.HOLIDAY_BOOKING_CARD:
    case HOLIDAY_DOWNLOAD_TRIGGERS.HOLIDAY_ADD_ON_VOUCHER:
      return HOLIDAY_VOUCHER_PDF_URL
    case TGSBookingConstants.TGS_INVOICE_TRIGGER:
      return TGS_DOWNLOAD_INVOICE
    default:
      return '';
  }
};
const getDownloadText = (val) => {
  const {
    downloadETicketText,
    downloadInvoiceText,
    downloadFailedText,
    downloadFileText,
    downloadSuccessText
  } = getStaticData();
  switch (val) {
    case RailBookingDetailConstant.ACTION_TICKET_TRIGGER:
      return downloadETicketText;
    case RailBookingDetailConstant.ACTION_INVOICE_TRIGGER:
      return downloadInvoiceText;
    case HolidayBookingDetailsConstant.DOWNLOAD_FAILED:
    case RailBookingDetailConstant.DOWNLOAD_FAILED:
      return downloadFailedText;
    case FLIGHT_DOWNLOAD_TRIGGERS.E_TICKET:
      return downloadETicketText;
    case FLIGHT_DOWNLOAD_TRIGGERS.INVOICE:
      return downloadInvoiceText;
    case FLIGHT_DOWNLOAD_TRIGGERS.BOARDING_PASS:
    case FLIGHT_DOWNLOAD_TRIGGERS.INSURANCE:
    case HOLIDAY_DOWNLOAD_TRIGGERS.HOLIDAY_BOOKING_CARD:
    case HOLIDAY_DOWNLOAD_TRIGGERS.HOLIDAY_ADD_ON_VOUCHER:
      return downloadFileText;
    case FLIGHT_DOWNLOAD_TRIGGERS.DOWNLOAD_FAILED:
      return downloadFailedText;
    case HOLIDAY_DOWNLOAD_TRIGGERS.DOWNLOAD_SUCCESS:
      return downloadSuccessText;
    case TGSBookingConstants.TGS_INVOICE_TRIGGER:
      return downloadInvoiceText;
    default:
      return '';
  }
};
export const downloadRequest = async (
  requestBody,
  headers,
  { triggerName, bookingId } = {},
  failedText,
) => {
  try {
    const fileName = _getDownloadFileName(triggerName, bookingId);
    showShortToast(getDownloadText(triggerName));
    const response = await fetch(getDownloadURL(triggerName), {
      method: 'POST',
      headers,
      body: JSON.stringify(requestBody),
    });
    if (response.ok) {
      const responseBody = await response.json();
      if (
        responseBody &&
        (responseBody.success || responseBody.status) &&
        (responseBody.pdfResponse || responseBody.pdfString || responseBody.pdf)
      ) {
        const fileData =
          responseBody.pdfString || responseBody.pdf || responseBody.pdfResponse.body;
        await FlightBookingModule.saveTicket(fileData, fileName);
        const { openFileText } = getStaticData();
        FlightBookingModule.showTicketDownloadedNotification({
          title: fileName,
          description: openFileText,
          fileName,
        });
        return true;
      } else {
        showShortToast(getDownloadText(failedText));
        return false;
      }
    } else {
      showShortToast(getDownloadText(failedText));
      return false;
    }
  } catch (error) {
    showShortToast(getDownloadText(failedText));
    return false;
  }
};


export const showECouponForMyPartnerBookings = (isMyPartnerBooking, boookingId) => {
  if (isMyPartnerBooking) {
    return boookingId ? boookingId.startsWith('MU') : false;
  }
  return true;
}

// Polyfill for Promise.AllSettled, if one of the promise rejects or give error, others will execute
export const promiseAllSettled = (promises) => {
    return () =>
      Promise.all(
        promises.map((p) =>
          Promise.resolve(p)
            .then((value) => ({
              status: 'fulfilled',
              value,
            }))
            .catch((reason) => ({
              status: 'rejected',
              reason,
            }))
        ),
      );
};


export const getGdprData = (gdprText) => {
  const { title, header, goBackToHomePage, contactUs } = gdprText || {};

  return {
    "title": title,
    "header": header,
    "shouldCloseOnCross": true,
    "cta": [
      {
        "text": goBackToHomePage,
        "link": "mmyt://app/home/?isCorporate=false&region=in",
        "trackingKey": "home"
      },
      {
        "text": contactUs,
        "link": "mmyt://app/callus/?01244628747",
        "trackingKey": "contactus"
      }
    ]
  }

}


export const isGroundLob = (lobName) => {
  if(lobName && typeof lobName === 'string'){
  const arr = ['train', 'trains', 'livetrainstatus', 'cab', 'cabs', 'bus', 'buses'];

  return arr.includes(lobName.toLowerCase());

  }
  return false;
}

export const nativeLog = (data)=> {
  if (NativeModules && NativeModules.GenericModule && NativeModules.GenericModule.logBreadCrumb && typeof NativeModules.GenericModule.logBreadCrumb === 'function') {
    const JS_VERSION = '24.0';
    const crumb = `RN ${data}`;
    console.log(crumb);
    NativeModules.GenericModule.logBreadCrumb(crumb, JS_VERSION);
  }
};

export const isGccFromBookingId = (bookingId) => {
  return !!bookingId && (bookingId.startsWith('AN') || bookingId.startsWith('AH'));
};

export const getErrorPageImage = (bookingId) => {
  return isGccFromBookingId(bookingId) ? ERROR_REQUEST_ICON_GCC : ERROR_REQUEST_ICON;
}
export const getRTLDirectionCss = () => {
  const lang = getUserSelectedLanguage();
  if(Platform.OS === 'web' && !!lang && LANGUAGE_MAP.ara === lang){
    return {direction:'rtl'}
  }
  return {};
}


export const padTo2Digits = (num) => {
  return num.toString().padStart(2, '0');
};


export const allInvoiceClickHandler = async (allInvoiceData) => {
  const {
    invoiceData,
    downloadingInvoiceText = 'Downloading Invoice',
    downloadFailedText = 'Download Failed',
    toastDtlsStyles = {},
    toastTextStyles = {},
  } = allInvoiceData || {};
  const { openFileText } = getStaticData();
  !!downloadingInvoiceText &&
    Toast.show(downloadingInvoiceText, 1000, 64, THEME_DARK, toastDtlsStyles, {
      ...toastTextStyles,
    });
  try {
    const fileName = invoiceData?.title + '_' + invoiceData?.invoiceno + '.pdf';
    if (Platform.OS === 'android') {
      await FlightBookingModule.saveTicket(invoiceData?.bytes, fileName);
      FlightBookingModule.showTicketDownloadedNotification({
        title: fileName,
        description: openFileText,
        fileName,
      });
    } else if (Platform.OS === 'ios') {
      await GenericModule.openPDFViewer(fileName, invoiceData?.bytes);
    } else if (Platform.OS === 'web') {
      FlightBookingModule.saveTicket(invoiceData?.bytes, fileName);
    }
  } catch (ex) {
    Toast.show(downloadFailedText, 1000, 64, THEME_DARK, toastDtlsStyles, {
      ...toastTextStyles,
    });
    console.log(ex);
  }
};

export const poll = ({ fn, validate, interval, maxAttempts = 1 }) => {
  let attempts = 0;
  const executePoll = async (resolve, reject) => {
    const result = await fn(attempts);
    attempts++;

    if (validate(result)) {
      return resolve(result);
    } else if (attempts >= maxAttempts) {
      return reject(new Error("Exceeded max attempts"));
    } else {
      setTimeout(executePoll, interval, resolve, reject);
    }
  };

  return new Promise(executePoll);
};

export const buttonThrottle = (cb, timer) => {

  let isblocked = false; // to check whether execution is blocked or not

  return () => {

    if (!isblocked) {
      cb();
      setTimeout(() => {
        isblocked = false;
      }, timer)
      isblocked = true;
    } else {
      //execution blocked
    }
  }

};


export const shouldShowGdprBlockerScreen = async () => {
  const { UserSessionModule } = NativeModules;
  const isGdprRegion = await UserSessionModule.needConsent();
  const isCorporate = await UserSessionModule.isCorporateUser();
  return isGdprRegion && !isCorporate;
}
