import { isEmpty } from 'lodash';
import React, { useCallback, useMemo, useRef, useState } from "react";
import {
  Dimensions, ScrollView, View, I18nManager
} from "react-native";
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import Tabs from '../../../../Common/Tabs';
import createStyle from "./StaffDetailsCSS";
import StaffDetailTabPage from './StaffDetailTabPage';
import Actions from '../../../../navigation/postSalesNavigation';
import { useTheme } from '../../../../theme/theme.context';

import HeaderWrapper from 'apps/post-sales/src/Common/HeaderWrapper';

const StaffDetails = ({ staffDetails }) => {
  const { psTheme: theme } = useTheme();
  const styles = createStyle(theme);
  const [activeTab, setActiveTab] = useState(0);

  const tabContent = useRef(null);
  
  const tabItems = useMemo(() => {
    const tabItems = (staffDetails.subSections || []).map(subSection => subSection && subSection.tabHeading);
    return tabItems;
  }, [staffDetails])
  const imageTransformY = { transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }] };

  const backImg = require('@mmt/legacy-assets/src/back.webp');

  const onHandleTabChange = useCallback((index) => {
    setActiveTab(index);
    const contentScrollAmount = Dimensions.get("window").width * index;
    tabContent && tabContent.current && tabContent.current.scrollTo({ x: contentScrollAmount, animated: true });
  }, [setActiveTab,tabContent]);

  const onHandleContentScroll = useCallback((event) => {
    const contentScrollWidth = Dimensions.get("window").width;
    const TabIndex = event.nativeEvent.contentOffset.x / contentScrollWidth;
    setActiveTab(TabIndex);
  },[setActiveTab]);

  return (
        <View style={[AtomicCss.flex1]}>
          <View style={styles.topStickySection}>
            <HeaderWrapper
                  titleText={staffDetails.subSectionsHeading ? staffDetails.subSectionsHeading : ''} 
                  closeIcon={backImg}
                  clickHandler={Actions.pop}
                  iconStyle={imageTransformY}
              />

            <View style={styles.TabsWrapper}>
              {!isEmpty(tabItems) && tabItems.map((item, index) => {
                return (
                  <Tabs
                    item={item}
                    index={index}
                    onHandleTabChange={onHandleTabChange}
                    activeTab={activeTab}
                  />
                );
              })}
            </View>
          </View>
          <ScrollView
            onMomentumScrollEnd={onHandleContentScroll}
            horizontal={true}
            showsHorizontalScrollIndicator={true}
            pagingEnabled={true}
            ref={tabContent}
            style={[AtomicCss.flex1]}
          >
            <View style={[AtomicCss.flexRow, AtomicCss.greyBg, AtomicCss.flex1]}>
              {
                !isEmpty(staffDetails.subSections) && staffDetails.subSections.map((subSection) => {
                  return <StaffDetailTabPage key={subSection.key} data={staffDetails[subSection.key]} tabId={subSection.key} />
                })
              }
            </View>
          </ScrollView>
        </View>
  );
}

export default React.memo(StaffDetails);
