import React from 'react';
import {Image, StyleSheet, Text, View} from 'react-native';
import {colors, fonts} from '@mmt/legacy-commons/Styles/globalStyles';
import _ from 'lodash';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {locationGhostIcon} from "../../cab/CabImageConstants";

const OtherInfo = ({message, heading}) =>{
    return(
        <View style={AtomicCss.marginBottom10}>
            <View style={[styles.card, AtomicCss.marginBottom0]}>
                <View style={styles.cardPrimaryDetails}>
                    <Image source={locationGhostIcon} style={styles.cardIcon} />
                    <View style={styles.content}>
                        <Text style={styles.cardTitle}>{heading}</Text>
                        <View style={styles.body}>
                            {
                                !_.isEmpty(message) &&
                                message.map(msg => (
                                    <View style={{flexDirection: 'row'}}>
                                        <View style={styles.bulletCircle} />
                                        <Text style={styles.tdrText}>{msg}</Text>
                                    </View>
                                ))
                            }
                        </View>
                    </View>
                </View>
            </View>
        </View>
    )
}
export default OtherInfo;
const styles = StyleSheet.create({
    card: {
        backgroundColor: colors.white,
        marginBottom: 10,
        padding: 15,
        borderBottomColor: colors.lightGrey3,
        borderBottomWidth: 1
    },
    cardPrimaryDetails: {
        flexDirection: 'row',
        paddingBottom: 5
    },
    content: {
        flex: 1
    },
    cardTitle: {
        fontSize: 14,
        fontFamily: fonts.black,
        color: colors.black2f,
        marginBottom: 10
    },
    body: {
        paddingTop: 5,
        marginBottom: 15
    },
    bulletCircle: {
        width: 6,
        height: 6,
        backgroundColor: '#5a5a5a',
        borderRadius: 20,
        top: 5,
        left: 5
    },
    tdrText: {
        fontSize: 14,
        color: colors.black,
        fontFamily: fonts.regular,
        lineHeight: 22,
        paddingLeft: 20
    },
    cardIcon: {
        height: 25,
        width: 24,
        marginRight: 8
    }
});

