import React, { useState } from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import styles from '../indexCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import RefundBreakup from './RefundBreakup';
import { USER_ICON_CANCELLED, BG_BLUE_RIGHT_ARROW } from '../../../flights/FlightImageConstants';
import { isNullOrEmpty } from '../../commonUtil';
import { getStaticData } from '../../../staticData/staticData';
import constants from './constants';
import { getFont } from '../../../PostSalesStyles';
import { addSpaceWithAmount } from '../../../utils/PostSaleUtil';

const RefundDetails  = ({data, collapsable}) => {
  const fonts = getFont(true);
  const [isCollapsed, handleAccordion] = useState(collapsable);
  const staticData = getStaticData();
  const { airlineUrl, fromCity, intentInfo, toCity, paxInfo,paxName, tagHeader, totalAmtPaidGrp, totalDeductionsGrp, refundAmount, imgType } = data;
  return (
    <View style={styles.detailCard}>
      <View style={[AtomicCss.flexRow, styles.departure]}>
      <View style={styles.airlineIconWrapper}>
        {!isNullOrEmpty(airlineUrl) && <Image source={{uri: airlineUrl}} style={styles.airlineIconSingle} />}
      </View>
      <View style={AtomicCss.flex1}>
        <Text style={[AtomicCss.font14, fonts.blackFontFamily, AtomicCss.blackText]}>{fromCity} - {toCity}</Text>
        {!!(intentInfo) && <Text style={[AtomicCss.font14, fonts.regularFontFamily, AtomicCss.defaultText, AtomicCss.marginTop5]}>{intentInfo}</Text>}
      </View>
    </View>
      {collapsable && <TouchableOpacity
        onPress={() => handleAccordion(!isCollapsed)}
        style={[
          AtomicCss.flexRow,
          AtomicCss.spaceBetween,
          AtomicCss.alignCenter,
          { height: 48, paddingHorizontal: 16 },
          isCollapsed ? {} : { backgroundColor: '#f4faff' }
        ]}
      >
        <Text style={[AtomicCss.font14, fonts.boldFontFamily, AtomicCss.azure]}>{isCollapsed ? staticData.seeDetailsText : staticData.hideDetailsText}</Text>
        <View style={styles.downArrowContainer}>
          <Image source={BG_BLUE_RIGHT_ARROW} style={[styles.downArrow, (isCollapsed ? {} : styles.upArrow)]} />
        </View>
      </TouchableOpacity>}
      {collapsable && isCollapsed ? null :
      <View style={styles.refundBreakupDetails}>
        <View style={styles.refundDesc}>
          <Image source={USER_ICON_CANCELLED} style={styles.userIcon} />
          <Text style={[AtomicCss.flex1, AtomicCss.font14, fonts.regularFontFamily, AtomicCss.gray2Text]}>
            <Text style={fonts.boldFontFamily}>{paxInfo} </Text>
              {paxName}
            </Text>
        </View>
        {tagHeader && <View style={[imgType===constants.imgType.DB ? styles.doubleBlack : styles.zeroCancellation, (tagHeader.desc===constants.BLUR ? { opacity: 0.3 } : null)]}>
          {!isNullOrEmpty(tagHeader.value) && <Image source={{uri: tagHeader.value}} style={imgType===constants.imgType.DB ? styles.doubleBlackIcon : styles.zeroIcon} />}
          <Text style={[AtomicCss.flex1, AtomicCss.font14, fonts.regularFontFamily, {color:tagHeader.color}]}>{tagHeader.text}</Text>
        </View>}
        <View style={styles.refundBreakup}>
          <View style={styles.refundBreakupSection}>
            <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween]}>
              <Text style={[AtomicCss.font14, fonts.boldFontFamily, AtomicCss.blackText, AtomicCss.flex1, AtomicCss.paddingRight16]}>{totalAmtPaidGrp.lbl.text}</Text>
              <Text style={[AtomicCss.font14, fonts.boldFontFamily, AtomicCss.blackText]}>{addSpaceWithAmount(totalAmtPaidGrp.lbl.value)}</Text>
            </View>
            <Text style={[AtomicCss.font12, fonts.boldFontFamily, AtomicCss.lightTextColor, AtomicCss.marginTop5]}>{staticData.forCancelledTicketsText}</Text>
            {totalAmtPaidGrp && totalAmtPaidGrp.pricingComponents.map((item,index) => {return item.components.map((component,index)=>(<RefundBreakup data={component} key={index} imgType={imgType} fonts={fonts} />))})}
          </View>
        </View>
        <View style={styles.refundBreakup}>
          <View style={styles.refundBreakupSection}>
            <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween]}>
              <Text style={[AtomicCss.font14, fonts.boldFontFamily, AtomicCss.blackText, AtomicCss.flex1, AtomicCss.paddingRight16]}>{totalDeductionsGrp.lbl.text}</Text>
              <Text style={[AtomicCss.font14, fonts.boldFontFamily, AtomicCss.blackText]}>{addSpaceWithAmount(totalDeductionsGrp.lbl.value)}</Text>
            </View>
            {totalDeductionsGrp && totalDeductionsGrp.pricingComponents.map((item,index) => {return item.components.map((component,index)=>(<RefundBreakup data={component} key={index} imgType={imgType} fonts={fonts} />))})}
          </View>
        </View>
        <View style={[AtomicCss.flexRow, AtomicCss.spaceBetween, AtomicCss.paddingTop10]}>
            <Text style={[AtomicCss.font16, fonts.blackFontFamily, {color:refundAmount.color ? refundAmount.color : '#000'}]}>{refundAmount.text}</Text>
            <Text style={[AtomicCss.font16, fonts.blackFontFamily, {color:refundAmount.color ? refundAmount.color : '#000'}]}>{addSpaceWithAmount(refundAmount.value)}</Text>
        </View>
      </View>
      }
    </View>
  );
}

export default React.memo(RefundDetails);
