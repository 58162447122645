import React, { Component } from 'react';
import { View, ScrollView } from 'react-native';
import createStyles from './styles';
import FooterTab from './FooterTab';
import { getQuckFltrLst, setQuickFltrs } from '../../utils/FlightListingUtil';
import { getFont } from '../../../../../../../PostSalesStyles';
import { ThemeContext } from '../../../../../../../theme/theme.context';
import { toggleFilter } from '../../../../utils';

export default class FooterLeftFilter extends Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);
    this.currFltrObj = this.props.currFltrObj;
    this.quickFltrLst = getQuckFltrLst(this.props.filterInfo);
    this.qckFltrOnLst = [];
  }

  shouldComponentUpdate(nextProps) {
    this.currFltrObj = nextProps.currFltrObj;
    this.qckFltrOnLst = setQuickFltrs(this.currFltrObj);
    return true;
  }

  render() {
    const fonts = getFont(true);
    const styles =  createStyles(this.context.psTheme, fonts);
    return (
      <View style={styles.FooterLeftFilterWrapper}>
        <ScrollView horizontal showsHorizontalScrollIndicator={false}>
          {this._renderFtrTabVw()}
        </ScrollView>
      </View>
    );
  }

  _renderFtrTabVw = () => {
    const ftbVw = [];
    for (let i = 0; i < this.quickFltrLst.length; i++) {
      ftbVw.push(
        <FooterTab
          item={this.quickFltrLst[i]}
          index={i}
          selected={this.qckFltrOnLst[i]}
          toggleQuckFltr={this._toggleQuckFltr}
        />,
      );
    }
    return ftbVw;
  };

  _toggleQuckFltr = (index) => {
    toggleFilter( this.currFltrObj, this.qckFltrOnLst, index);
    this.props.toggleFltr(this.currFltrObj);
  };
}
