import React from 'react';
import { View, Text } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getFont } from '../../../../../PostSalesStyles';
import { useTheme } from '../../../../../theme/theme.context';
import createStyles from './BookingStatusHeadingStyle';
import { BookingStatusHeadingProps } from '../../../../types';

const BookingStatusHeading: React.FC<BookingStatusHeadingProps> = ({
  title,
  subTitle,
  statusStyleColor,
}) => {
  const fonts = getFont();
  const { psTheme: theme } = useTheme();
  const styles = createStyles(theme, fonts);
  return (
    <View style={styles.headingWrapper}>
      {!!title && (
        <Text
        style={
          [styles.title,
          statusStyleColor,
          AtomicCss.textCenter,
          AtomicCss.marginBottom10
        ]}
        >
          {title}
        </Text>
      )}
      {!!subTitle && (
        <Text
        style={[
          styles.subtitle,
          statusStyleColor,
          AtomicCss.textCenter,
          AtomicCss.marginBottom10,
        ]}
        >
          {subTitle}
        </Text>
      )}
    </View>
  );
};

export default BookingStatusHeading;
