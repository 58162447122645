import { StyleSheet } from 'react-native';
import { screenDimension, ps_colors } from '../../PostSalesStyles';

const { windowHeight, windowWidth } = screenDimension;

const styles = StyleSheet.create({
  overlayWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: windowHeight,
    width: windowWidth,
    elevation: 10,
    zIndex: 100,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: ps_colors.grey20,
    opacity: 0.5,
    height: '100%',
    width: '100%',
    zIndex: 1,
  },
});

export default styles;
