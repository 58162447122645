import React from 'react';
import {Image, Text, View} from 'react-native';
import Fecha from 'fecha';
import PropTypes from 'prop-types';
import styles from './RefundProgressCardCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import {
  fillDepartureArrivalDate,
  getRefundIcons,
  getRefundProgressStyle,
  getRefundStatusLine
} from '../MetroBookingDetailUtil';
import {dateTimeFormat} from '@mmt/legacy-commons/Helpers/dateHelpers';
import {REFUND_ICON,SINGLETICK, DOUBLE_TICK} from '../MetroImageConstant';

class RefundProgressCard extends React.Component {
  render() {
    if (this.props.response.refundCommDetails) {
      const refundCommDetail = this.props.response.refundCommDetails;
      return (
        <View>
          <View style={[styles.MamiCard, styles.mar10Bt]}>
            <View style={styles.CardContent}>
              <Image style={styles.CardIcon} source={REFUND_ICON} />
              <View style={styles.ContentPortion}>
                <Text
                  style={[styles.blackFont, styles.BlackText, styles.refundCardheadText, styles.mar15Bt, styles.width100per]}
                > REFUND
                  OF &#8377; {refundCommDetail.totalRefundAmount} {getRefundStatusLine(refundCommDetail.currentRefundStatus)}
                </Text>
                <View style={styles.mar10Bt}>
                  <View style={styles.refundbarInfo}>
                    {getRefundIcons(refundCommDetail.currentRefundStatus)}
                  </View>
                  <View style={[styles.refundStrip, styles.mar5Bt]}>
                    <View style={getRefundProgressStyle(refundCommDetail.currentRefundStatus)} />
                  </View>
                  <View style={styles.refundbarInfo}>
                    <Text
                      style={[styles.font12, styles.defaultGrey, styles.boldFont, styles.lineHeight18, styles.flex1]}
                    >{this.renderBottomLeftSection(refundCommDetail)}
                    </Text>
                    <Text
                      style={[styles.font12, styles.AlignRight, styles.defaultGrey, styles.boldFont, styles.lineHeight18, styles.flex1]}
                    >{this.renderBottomRightSection(refundCommDetail)}
                    </Text>
                  </View>
                </View>
              </View>
            </View>

          </View>
        </View>
      );
    } else if (this.props.response.cancellationDetail.amount === 0) {
      return (
        <View style={[AtomicCss.whiteBg,
          AtomicCss.marginBottom10, AtomicCss.marginTop10]}
        >
          <View style={[styles.MamiCard, styles.mar10Bt]}>
            <View style={styles.CardContent}>
              <Image style={styles.CardIcon} source={REFUND_ICON} />
              <View style={AtomicCss.flex1}>
                <Text style={[AtomicCss.blackFont, AtomicCss.blackText, AtomicCss.font14,
                  AtomicCss.flex1, AtomicCss.marginBottom10, {paddingTop: 5}]}
                >
                  Your booking has been cancelled successfully, as per deductions you are not eligible for any refund amount.
                </Text>
              </View>
            </View>
          </View>
        </View>
      );
    }

    return null;
  }
  renderBottomLeftSection(refundCommDetail) {
    if (refundCommDetail && refundCommDetail.cancelledDate) {
      return `Cancellation \nInitiated on\n${fillDepartureArrivalDate(refundCommDetail.cancelledDate, dateTimeFormat)}`;
    } return 'Cancellation \nInitiated';
  }

  renderBottomRightSection(refundCommDetail) {
    if (refundCommDetail && refundCommDetail.uploadRRNDate) { return `Refund Processed\n & Sent to bank on\n ${fillDepartureArrivalDate(refundCommDetail.uploadRRNDate, dateTimeFormat)}`; }
    return `Refund Processed\n & Sent to bank by\n ${this.populateRefundExpectedDate(refundCommDetail.cancelledDate)}`;
  }

  populateRefundExpectedDate(cancelledDate) {
    if (cancelledDate) {
      const millisecondOffset = 7 * 24 * 60 * 60 * 1000;
      return Fecha.format((new Date(cancelledDate + millisecondOffset)), dateTimeFormat);
    }
    return '';
  }
}

RefundProgressCard.propTypes = {
  response: PropTypes.object.isRequired
};
export default RefundProgressCard;
