import { Platform } from 'react-native';
import isEmpty from 'lodash/isEmpty';
import HotelBookingModule from '@mmt/legacy-commons/Native/HotelBookingModule';
import { isNetworkAvailable } from '@mmt/legacy-commons/Common/utils/AppUtils';
import {
  FLIGHT_ANCILLARY_DETAILS_URL,
  FLIGHT_BOOKING_DETAILS_HOLIDAY_URL,
  FLIGHT_BOOKING_DETAILS_URL,
  FLIGHT_BOOKING_UNIVERSAL_SEARCH_DETAILS_URL,
  getCommonHeaders,
  FLIGHT_CYT_CROSSELL_URL,
  GET_ERROR_DATA_V2,
  GET_CSAT_CONTROLLER_DATA,
  CHILD_BOOKING_INFO_URL,
} from "../../../../utils/NetworkUtils";
import { getLobName } from '../utils/cardUtil';
import FlightEventsLogger, { FLIGHT_LOG_ACTIONS} from '../helper/FlightEventsLogger';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import { getUserProfileType } from '@mmt/legacy-commons/AppState/AppUserConfig';
import FlightBookingTrackingHelper from '../helper/FlightBookingTrackingHelper';
import FlightBookingModule from '@mmt/legacy-commons/Native/FlightBookingModule';
import { LOBNAMES, ENG_CONSTANT } from '../../../../PostSalesConstant';

/* we can move to postsales level will be taken care later */
export const getHeaders = async (bookingId, uniqueId, psLob) => {
  let headers = {};
  const userInfo = await HotelBookingModule.getRequestHeader(bookingId ? bookingId : '', uniqueId);
  headers = await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo, { psLob });
  return headers;
};
export const fetchFlightBookingUniversalSearchDetails = async (bookingId) => {
  try {
    const hasNetwork = await isNetworkAvailable();
    if (!hasNetwork) {
      return { isNetworkNotAvailable: true };
    }
    const flightBookingSearchDetailsUrl = FLIGHT_BOOKING_UNIVERSAL_SEARCH_DETAILS_URL;
    const url = flightBookingSearchDetailsUrl + bookingId;
    const apiTrackingConst = 'FLIGHT_DETAIL';
    const psLob = getLobName(false);
    const headers = await getHeaders(bookingId, apiTrackingConst, psLob);
    let response = await fetch(url, {
      headers,
    });

    if (response && response.ok) {
      response = await response.json();
      return response;
    } else {
      return { error: response };
    }
  } catch (err) {
    return { error: err };
  }
};

let fetchAbortController = null;

export const onCancelRequest = () => {
  if (fetchAbortController) {
    fetchAbortController.abort();
  }
};

export const fetchFlightBookingDetails = async (bookingId, holidayBookingApi, trackError) => {
  try {
    if (fetchAbortController) {
      fetchAbortController = null;
    }
    fetchAbortController = new AbortController();

    const hasNetwork = await isNetworkAvailable();
    if (!hasNetwork) {
      return { isNetworkNotAvailable: true };
    }
    const flightBookingUrl = holidayBookingApi
      ? FLIGHT_BOOKING_DETAILS_HOLIDAY_URL
      : FLIGHT_BOOKING_DETAILS_URL;
    const url = flightBookingUrl + bookingId;
    const apiTrackingConst = holidayBookingApi ? 'HOLIDAY_DETAIL' : 'FLIGHT_DETAIL';
    const psLob = getLobName(holidayBookingApi);
    const headers = await getHeaders(bookingId, apiTrackingConst, psLob);
    let response = await fetch(url, {
      headers,
      signal: fetchAbortController.signal
    });
    if (response && response.ok) {
      response = await response.json();
      return response;
    } else {
      trackError(response.status);
      response = await response?.json();
      return { error: response };
    }
  } catch (err) {
    trackError(err.message);
    return { error: err };
  }
};

export const fetchFlightAncillaryDetails = async (bookingId, holidayBookingApi) => {
  try {
    const hasNetwork = await isNetworkAvailable();
    if (!hasNetwork) {
      return { isNetworkNotAvailable: true };
    }
    const url = FLIGHT_ANCILLARY_DETAILS_URL + bookingId;
    const apiTrackingConst = holidayBookingApi ? 'HOLIDAY_DETAIL' : 'FLIGHT_DETAIL';
    const psLob = getLobName(holidayBookingApi);
    const headers = await getHeaders(bookingId, apiTrackingConst, psLob);
    let response = await fetch(url, {
      headers,
    });
    if (response && response.ok) {
      response = await response.json();
      return response;
    } else {
      return { error: response };
    }
  } catch (err) {
    return { error: err };
  }
};

export const fetchLastMinCancellationErrorData = async (
  data = [],
  successPnr,
  bookingId,
  responseJson,
  holidayBookingApi,
  trackError,
) => {
  try {
    const modifiedErrorData =
      !isEmpty(data) &&
      data.map(({ errorCode, errorMessage, errorDescription, pnrNo }) => ({
        ec: errorCode,
        em: errorMessage,
        ed: errorDescription,
        pnrNo,
      }));

    const hasNetwork = await isNetworkAvailable();
    if (!hasNetwork) {
      return { isNetworkAvailable: false };
    }
    const psLob = getLobName(holidayBookingApi);
    const headers = await getHeaders(bookingId, 'FLIGHT_LAST_MINUTE_CANCELLATION', psLob);
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({
        bookingId: bookingId,
        errorDetails: modifiedErrorData || [],
        pageId:
          !isEmpty(responseJson) && responseJson.isCorporateBooking
            ? 'TENTATIVE_CANCELLATION_CORP'
            : 'TENTATIVE_CANCELLATION',
        successPnr: successPnr.join(','),
      }),
      headers: headers,
    };
    let response = await fetch(GET_ERROR_DATA_V2, requestOptions);
    if (response && response.ok) {
      response = await response.json();
      return response;
    } else {
      trackError(response.status);
      return { error: response };
    }
  } catch (err) {
    trackError(err);
    return { error: err };
  }
};

export const fetchCompleteYourTripDetail = async (bookingId, holidayBookingApi) => {
  try {
    const hasNetwork = await isNetworkAvailable();
    if (!hasNetwork) {
      return { isNetworkNotAvailable: true };
    }
    let payload = await GenericModule.getSkyWalkerRequestBody();
    if (Platform.OS === 'android') {
      payload = JSON.parse(payload.skywalkerRequest);
    }
    const psLob = getLobName(holidayBookingApi);
    const requestOptions = {
      method: 'POST',
      headers: await getHeaders(bookingId, 'CROSS_SELL', psLob),
      body: JSON.stringify({ bookingId, payload }),
    };
    const response = await fetch(FLIGHT_CYT_CROSSELL_URL, requestOptions);
    if (response && response.ok) {
      const body = await response.json();
      return body;
    } else {
      return { error: response };
    }
  } catch (err) {
    return { error: err };
  }
};

export const getCsatControllerDataApi = async (responseJson, bookingId, holidayBookingApi) => {
  const { uuid } = responseJson || {};
  const payload = {
    surveyDTO: {
      uuid,
      bookingId: bookingId,
      profileType: getUserProfileType(),
    },
  };
  try {
    const psLob = getLobName(holidayBookingApi);
    const response = await fetch(GET_CSAT_CONTROLLER_DATA, {
      method: 'POST',
      headers: await getHeaders(bookingId, 'CROSS_SELL', psLob),
      body: JSON.stringify(payload),
    });
    if (response && response.ok) {
      const body = (await response.json()) || {};
      return body;
    } else {
      return { error: response };
    }
  } catch (e) {
    console.log('Error while fetching csat controller data', e);
    return { error: e };
  }
};

export const getCrossSellDataApi = async (bookingId, childBookingIdList) => {
  try {
    const childBookingInfoRequest = {
      childBookingIdList,
      parentBookingId: bookingId,
    };

    const childBookingInfoResponse = await fetch(CHILD_BOOKING_INFO_URL, {
      method: 'POST',
      headers: await getHeaders(bookingId, 'CAB_CROSS_SELL_FETCH_BOOKING'),
      body: JSON.stringify(childBookingInfoRequest),
    });

    if (childBookingInfoResponse && childBookingInfoResponse.ok) {
      const body = await childBookingInfoResponse.json();
      return body;
    } else {
      return { error: childBookingInfoResponse };
    }
  } catch (err) {
    console.log(err);
    return { error: err };
  }
};

export const fetchCrossSellData = async (bookingId) => {
  const requestUrl = 'https://cabs.makemytrip.com/myTrips/flight';
  const userInfo = await FlightBookingModule.getRequestHeader(
    bookingId ? bookingId.flightBookingId : '',
    'CABS_CROSS_SELL',
  );
  const headers = await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo, {
    psLob: LOBNAMES.FLIGHT,
  })
  const request = {
    type: 'myTripsRequest',
    flight_booking_id: bookingId ? bookingId.flightBookingId : '',
  };
  try {
    let resp = await fetch(
      requestUrl,
      {
        method: 'POST',
        body: JSON.stringify(request),
        headers: headers,
      },
      3000,
    );
    resp = await resp.json();
    if (resp && resp.status === 'SUCCESS') {
      try {
        if (resp.cab_list.length) {
          if (resp.is_international) {
            FlightBookingTrackingHelper.trackCabShownCard(
              'Mob:customer support:Intl Flight : Details_Personal',
            );
          } else {
            FlightBookingTrackingHelper.trackCabShownCard(
              'Mob:customer support:Dom Flight : Details_Personal',
            );
          }
        }
      } catch (error) {
        //  Ignore exception
      }
      return resp;
    }
    return null;
  } catch (e) {
    return null;
  }
};
export default {};
