import Actions from '../../../navigation/postSalesNavigation';
import Fecha from 'fecha';
import {
  getFlightItemListMap, getFareComponent, getListingHeader, getBookingType, getFilterInfo, filterSearchMap,
  getActiveFilters,
  getCalendarDataList
} from './FlightListingUtil';
import NetworkModule from '@mmt/legacy-commons/Native/NetworkModule';
import FlightListingTrackingHelper from './FlightListingTrackingHelper';
import {
  FLIGHTS_LISTING_DATE_CHANGE_REQUESTV2,
  getUserPlatform,
  getCommonHeaders
} from '../../../utils/NetworkUtils';
import FlightBookingModule from '@mmt/legacy-commons/Native/FlightBookingModule';
import { LOBNAMES } from '../../../PostSalesConstant';
import { isEmpty } from 'lodash';
export const ACTION_LOADING_FLIGHTS = 'ACTION_LOADING_FLIGHTS';
export const ACTION_SHOW_DETAIL = 'ACTION_SHOW_DETAIL';
export const ACTION_SELECT_ITEM = 'ACTION_SELECT_ITEM';
export const ACTION_ERROR = 'ACTION_ERROR';
export const ACTION_ERROR_NEW = 'ACTION_ERROR_NEW';
export const ACTION_RENDER_LIST = 'ACTION_RENDER_LIST';
export const ACTION_REFRESH = 'ACTION_REFRESH';


const getHeaders = async () => {
  const headers = await NetworkModule.getHeaders();
  return headers;
};

export const fetchSearchData = (searchKey, segmentGroup, bookingId, corporateBooking, isMyPartnerBooking, isCfarBooking) => async (dispatch) => {
  dispatch(setLoadingAction());
  try {
    let response;
    const userInfo = await
      FlightBookingModule.getRequestHeader(bookingId, 'FLIGHT_DATE_CHANGE_LISTING');
    const request = {
      authId: userInfo.mmtAuth,
      userId: getUserPlatform().toUpperCase(),
      bookingId: bookingId,
      segmentGroup,
      isCfarBooking:!!isCfarBooking,
      version: 'v2'
    };
    const headers = { ...await getHeaders(), ...await getCommonHeaders(userInfo.mmtAuth, userInfo.loggingTrackingInfo) };
    const url = `${FLIGHTS_LISTING_DATE_CHANGE_REQUESTV2}`;
    response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(request)
    });
    if (!response.ok) {
      trackError('No_Respone', bookingId, undefined);
      dispatch(setErrorAction(searchKey, ''));
      return null;
    }
    const responseBody = await response.json();
    if (!responseBody) {
      trackError('Search_Results_Failure', bookingId, undefined);
      dispatch(setErrorAction(searchKey, ''));
      return null;
    } else if (responseBody.errorPageData) {
      let errorStatus = 'NO_STATUS_IN_RESPONSE';
      if (responseBody.flightSearchInfoClientResponse && responseBody.flightSearchInfoClientResponse.flightSearchInfoResponseBody
        && responseBody.flightSearchInfoClientResponse.flightSearchInfoResponseBody.error != null
        && responseBody.flightSearchInfoClientResponse.flightSearchInfoResponseBody.error.length > 0) {
        const error = responseBody.flightSearchInfoClientResponse.flightSearchInfoResponseBody.error[0];
        errorStatus = `${error.ec}_${error.em}_${error.ed}`;
      }
      trackError(errorStatus, bookingId, !isEmpty(responseBody.errorPageData) && !isEmpty(responseBody.errorPageData.meta) && responseBody.errorPageData.meta.supplier);
      dispatch(setNewErrorAction(searchKey, responseBody.errorPageData.errorCode, responseBody.errorPageData));
      return null;
    } else if (responseBody.status === 'COMPLETED') {
      trackPageLoad(responseBody, bookingId);
      dispatch(listingSuccess(responseBody, responseBody.sessionId, responseBody.cfarInfo, responseBody.odcImportantInfo));
      return responseBody;
    } else if (responseBody.flightSearchInfoClientResponse && responseBody.flightSearchInfoClientResponse.flightSearchInfoResponseBody
      && responseBody.flightSearchInfoClientResponse.flightSearchInfoResponseBody.status === 'COMPLETED') {
      trackPageLoad(responseBody.flightSearchInfoClientResponse.flightSearchInfoResponseBody, bookingId);
      responseBody.flightSearchInfoClientResponse.flightSearchInfoResponseBody.custCurrency = responseBody.custCurrency;
      dispatch(listingSuccess(responseBody.flightSearchInfoClientResponse.flightSearchInfoResponseBody, responseBody.flightSearchInfoClientResponse.sessionId, responseBody.cfarInfo, responseBody.odcImportantInfo));
      return responseBody.flightSearchInfoClientResponse.flightSearchInfoResponseBody;
    } else if (responseBody.flightSearchInfoClientResponse && responseBody.flightSearchInfoClientResponse.flightSearchInfoResponseBody
      && responseBody.flightSearchInfoClientResponse.flightSearchInfoResponseBody.status === 'FAILED') {
      let errorStatus = 'STATUS_FAILED';
      let errorCode = '';
      if (responseBody.flightSearchInfoClientResponse && responseBody.flightSearchInfoClientResponse.flightSearchInfoResponseBody
        && responseBody.flightSearchInfoClientResponse.flightSearchInfoResponseBody.error != null
        && responseBody.flightSearchInfoClientResponse.flightSearchInfoResponseBody.error.length > 0) {
        const error = responseBody.flightSearchInfoClientResponse.flightSearchInfoResponseBody.error[0];
        errorStatus = `${error.ec}_${error.em}_${error.ed}`;
        errorCode = error.ec;
      }
      trackError(errorStatus, bookingId, undefined);
      dispatch(setErrorAction(searchKey, errorCode));
      return null;
    }
    trackError('No_Response', bookingId, undefined);
    dispatch(setErrorAction(searchKey, ''));
    return null;
  } catch (e) {
    console.error(e);
    trackError('EXCEPTION', bookingId, undefined);
    dispatch(setErrorAction(searchKey, ''));
    return null;
  }
};

export const updateSelectedItemAction = listItem => (dispatch, getState) => {
  const isFdcApplicable = !!listItem && !!listItem.fareBreakup && !!listItem.fareBreakup.header;
  const selectedFlightItem = getFareComponent(listItem, getState().flightListing.searchResponse);
  if (listItem.odc.tdc) {
    trackClickEventListingPopup('Item_Fare_Reconfirm', getState().flightListing.bookingType, listItem.airlineName, isFdcApplicable);
  } else {
    trackClickEventListingPopup('Item_Cancel', getState().flightListing.bookingType, listItem.airlineName, isFdcApplicable);
  }
  if (!!listItem.fareBreakup || !!listItem.priceBreakup) {
    const selectedFlightItemNew = {
      recomId: listItem.odc.recomId,
      sessionId: getState().flightListing.sessionId,
      crKey: getState().flightListing.searchResponse.crKey,
    };
    if (!!listItem.fareBreakup) {
      selectedFlightItemNew.fareBreakup = listItem.fareBreakup;
      selectedFlightItemNew.additionalInfoList= listItem.additionalInfoList;
    } else {
      selectedFlightItemNew.priceBreakup = listItem.priceBreakup;
    }
    dispatch({
      type: ACTION_SELECT_ITEM,
      data: { selectedFlightItem:selectedFlightItemNew },
    });
  } else
    dispatch({
      type: ACTION_SELECT_ITEM,
      data: { selectedFlightItem },
    });
};

const setLoadingAction = () => ({
  type: ACTION_LOADING_FLIGHTS
});

export const refreshListing = () => ({
  type: ACTION_REFRESH
});

export const listingLoader = () => (dispatch) => {
  dispatch(setLoadingAction());
};

const setErrorAction = (searchKey, errorCode) => ({
  type: ACTION_ERROR,
  data: {searchKey, errorCode}
});

const setNewErrorAction = (searchKey, errorCode, errorPageData) => ({
  type: ACTION_ERROR_NEW,
  data: {searchKey, errorCode, errorPageData}
});

export const listingSuccess = (response, sessionId, cfarInfo, odcImportantInfo) => (
  {
    type: ACTION_SHOW_DETAIL,
    response,
    searchResponse: response.odcSummary,
    searchMap: getFlightItemListMap(response),
    headerInfo: getListingHeader(response),
    bookingType: getBookingType(response.meta),
    filterInfo: getFilterInfo(response),
    calendarDataList: getCalendarDataList(response),
    message: response.message,
    sessionId,
    cfarInfo,
    odcImportantInfo,
  });

export const onCancellationContinue = () => (dispatch, getState) => {
  const crKey = getState().flightListing.selectedFlightItem.crKey;
  const rKey = getState().flightListing.selectedFlightItem.rKey;

  trackClickEvent('Proceed_Cancel', getState().flightListing.bookingType);
  Actions.flightCancelReview({crKey, rKey});
};

export const onReviewAndRebook = (corporateBooking, bookingId, info = {}) => (dispatch, getState) => {
  const selectedFlight = getState().flightListing.selectedFlightItem;
  const origAirline = getState().flightListing.headerInfo.origAirline;
  const journeyType = getState().flightListing.headerInfo.journeyType;
  const sessionId = getState().flightListing.sessionId;
  trackClickEvent('Review_And_Book', getState().flightListing.bookingType);
  const encryptedData = {
    crKey: selectedFlight.crKey, 
    rKey: selectedFlight.rKey, 
    sessionId: sessionId, 
    recomId: selectedFlight.recomId, 
    origAirline, 
    journeyType,
    bookingId,
    isCorporateBooking: corporateBooking,
    ...info
  }
  if (corporateBooking) {
    Actions.corpFlightRescheduleReviewPage(encryptedData);
  } else {
    Actions.flightRescheduleReviewPage(encryptedData);
  }
};

function trackPageLoad(responseBody, bookingId) {
  const trackingPageName = getPageName(getBookingType(responseBody.meta));
  FlightListingTrackingHelper
    .trackLoadEvent(trackingPageName, responseBody.meta, bookingId);
}

function getPageName(bookingType) {
  return isIntlFlightBooking(bookingType)
    ? 'Mob:Customer support:Intl flight:datechangelisting'
    : 'Mob:customer support:Dom flight:datechangelisting';
}
function getClickEvent(eventname, bookingType) {
  return isIntlFlightBooking(bookingType)
    ? `MI_Intl_datechangelisting:${eventname}`
    : `MI_dom_datechangelisting:${eventname}`;
}

function trackClickEvent(eventname, bookingType) {
  FlightListingTrackingHelper.trackClickEvent(getPageName(bookingType), getClickEvent(eventname, bookingType));
}

function trackClickEventListingPopup(eventname, bookingType, airlineName, isFdcApplicable) {
  FlightListingTrackingHelper.trackClickEventForListingPopup(getPageName(bookingType), getClickEvent(eventname, bookingType), airlineName, isFdcApplicable);
}

function trackError(message, bookingId, supplier) {
  let pageName = 'Mob:Customer support: Dom datechangelisting:Error';
  if (bookingId && bookingId.startsWith('NN')) {
    pageName = 'Mob:Customer support:Intl datechangelisting:Error';
  }
  FlightListingTrackingHelper.trackErrorEvent(pageName, message, supplier);
}

function isIntlFlightBooking(bookingType) {
  return bookingType !== 'DOMESTIC';
}

export const filterListing = (response, sortType, sortOrder, currFltrObj) => (dispatch) => {
  let searchMap = getFlightItemListMap(response);
  searchMap = filterSearchMap(searchMap, currFltrObj);
  if (sortType === 'price') { sortListingByPrice(searchMap); } else if (sortType === 'departure') { sortListingByDep(searchMap, sortOrder); } else if (sortType === 'arrival') { sortListingByArr(searchMap, sortOrder); } else if (sortType === 'duration') { sortListingByDur(searchMap); }
  dispatch({
    type: ACTION_RENDER_LIST,
    searchMap
  });
  FlightListingTrackingHelper.trackClickEvent('Mob:Customer support:datechangelisting:sortNfilter', `apply ${getActiveFilters(sortType, sortOrder, currFltrObj)}`);
};

function sortListingByPrice(searchMap) {
  searchMap.sort((a, b) => a.pay - b.pay);
}

function sortListingByDep(searchMap, sortOrder) {
  searchMap.sort((a, b) => {
    const aDate = Fecha.parse(a.legs[0].depTime, 'HH:mm');
    const bDate = Fecha.parse(b.legs[0].depTime, 'HH:mm');
    if (sortOrder === 0) { return aDate - bDate; } else if (sortOrder === 1) { return bDate - aDate; }
  });
}

function sortListingByArr(searchMap, sortOrder) {
  searchMap.sort((a, b) => {
    const aDate = Fecha.parse(a.legs[0].arrTime, 'HH:mm');
    const bDate = Fecha.parse(b.legs[0].arrTime, 'HH:mm');
    if (sortOrder === 0) { return aDate - bDate; } else if (sortOrder === 1) { return bDate - aDate; }
  });
}

function sortListingByDur(searchMap) {
  searchMap.sort((a, b) => {
    let aDur = 0;
    let bDur = 0;
    a.legs.forEach((leg) => {
      aDur += leg.dur;
    });
    b.legs.forEach((leg) => {
      bDur += leg.dur;
    });
    return aDur - bDur;
  });
}
