import React from 'react';
import { View, Text, Image } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import createStyles from "./TripGuaranteeCss";
import isEmpty from 'lodash/isEmpty';
import RectBookFullCard from '../RectBookFullCard'
import { TGS_ICON } from '../../../../Utils/tripGuaranteeImageConstants';
import { getFont } from '../../../../../PostSalesStyles';
import { useTheme } from '../../../../../theme/theme.context';

const TripVoucherUseCard = ({ card, response }) => {
    const { heading, subHeading } = card || {};
    const { tripVoucherUse } = response || {};
    const fonts = getFont();
    const {psTheme : theme} = useTheme();
    const styles = createStyles(theme,fonts);
    return (
        <>
            <View style={[AtomicCss.flexRow, AtomicCss.marginBottom10]}>
                <View style={styles.iconWrapper}>
                    <Image style={styles.voucherIconStyle} source={TGS_ICON} />
                </View>
                <View style={AtomicCss.flex1}>
                    {!!heading && <Text style={[AtomicCss.font14, fonts.blackFontFamily, AtomicCss.blackText, AtomicCss.marginBottom3]}>{heading}</Text> }
                    {!!subHeading && <Text style={[AtomicCss.font14, fonts.regularFontFamily, AtomicCss.blackText]}>{subHeading}</Text> }
                </View>
            </View>
            {!isEmpty(tripVoucherUse) && <View style={styles.detailsSection}>
                {tripVoucherUse.map((card, index) => <RectBookFullCard card={card} />)}
            </View>}

        </>
    )
}

export default TripVoucherUseCard;