import React, {PureComponent} from 'react';
import {View, ScrollView} from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import Collapsed from './Collapsed';
import ProgressView from '@mmt/legacy-commons/Common/Components/Progress/ProgressView';

export const ViewState = {
    LOADING: 'loading',
    NO_INTERNET: 'no_internet',
    SHOW_DETAIL: 'detail',
    ERROR: 'error',
    NO_RESULT: 'no_result',
};

class InfoFaqs extends React.Component {
    constructor(props) {
        super(props);
        this.tabbingFn = this.tabbingFn.bind(this);
        this.state = {
            activeCard: 0,
            collapseActiveCard: true,
            headerList: [],
            viewState: ViewState.LOADING
        };
    }

    componentDidMount() {
        this.fetchFaqHeaderInfo(this.props.response.generalFAQList);
    }

    tabbingFn(index, nextState) {
        this.setState({
            activeCard: index,
            collapseActiveCard: nextState
        });
    }

    fetchFaqHeaderInfo(response) {
        let questionHeader = [];

        response.forEach(item => {
            questionHeader.push(item.questionHeader);
        });
        this.setState({
            headerList: questionHeader,
            viewState: ViewState.SHOW_DETAIL
        })
    }

    renderProgressView = () => (<ProgressView message="Loading Your FAQ's..."/>);

    render() {
        return (
            <View style={AtomicCss.flex1}>
                {this.state.viewState === ViewState.SHOW_DETAIL && this.renderPage()}
                {this.state.viewState === ViewState.LOADING && this.renderProgressView()}
            </View>
        );
    }

    renderPage() {
        return (
            <View style={AtomicCss.elevation1}>
                <ScrollView>
                    {
                        this.state.headerList.map((item, index) => {
                            const isActive = index === this.state.activeCard ? this.state.collapseActiveCard : false
                            return (
                                <Collapsed
                                    item={item}
                                    key={index}
                                    index={index}
                                    isActive={isActive}
                                    tabbingFn={this.tabbingFn}
                                    response={this.props.response}
                                />
                            )
                        })
                    }
                </ScrollView>
            </View>
        );
    }
}

export default InfoFaqs;
