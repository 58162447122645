import React, { useEffect, useRef, useState } from 'react';
import { NativeModules, View } from 'react-native';
import BookingSummaryV4 from './indexV4';
import BookingSummary from './BookingSummaryV5';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import ProgressView from '../Common/ProgressView/ProgressView';
import { isBookingSummaryPaginationEnabled, isRevampedBookingSummary, isIosClient } from '../utils/PostSaleUtil';
import { getStaticData } from '../staticData/staticData';
import PostsalesVernac from '../PostSalesVernac';
import { BOOKING_SUMMARY_VIEW } from './summaryConstants';
import GenericModule from '@mmt/legacy-commons/Native/GenericModule';
import { AUTH_ERROR_PAGENAME, AUTH_FAILED_MESSAGE, LOBNAMES } from '../PostSalesConstant';
import { showLongToast } from 'packages/legacy-commons/Common/Components/Toast';
import PostSalesBookingTrackingHelper from '../PostSalesBookingTrackingHelper';

const BaseBookingSummaryComponent = (props) => {
  const isUserAuthenticated = useRef(true);
  const [bookingSummaryView, setBookingSummaryView] = useState(BOOKING_SUMMARY_VIEW.INITIAL);
  const { 
    langSwitcherBottomSheet,
    loadingYourTripText ,
    errorText
  } = getStaticData(true, true);

  const createDeeplink = (pageData) => {
    let detailsPageDeeplink = null;
    switch (pageData.lob) {
      case LOBNAMES.FLIGHT:
        detailsPageDeeplink = `mmyt://tripDetails/?page=flightBookingDetail&bookingId=${pageData.bookingId}`;
        break;
      case LOBNAMES.HOTEL:
        detailsPageDeeplink = `mmyt://tripDetails/?page=hotelBookingDetail&bookingId=${pageData.bookingId}`;
        break;
      case LOBNAMES.RAIL:
        detailsPageDeeplink = `mmyt://tripDetails/?page=railBookingDetail&bookingId=${pageData.bookingId}`;
        break;
      case LOBNAMES.BUS:
        detailsPageDeeplink = `mmyt://tripDetails/?page=busBookingDetail&bookingId=${pageData.bookingId}`;
        break;
      case LOBNAMES.CAR:
        detailsPageDeeplink = `mmyt://tripDetails/?page=cabBookingDetail&bookingId=${pageData.bookingId}`;
        break;
      case LOBNAMES.HOLIDAY:
        detailsPageDeeplink = `mmyt://tripDetails/?page=holidayBookingDetail&bookingId=${pageData.bookingId}`;
        break;
      case LOBNAMES.ACME:
        detailsPageDeeplink = `mmyt://tripDetails/?page=acmeBookingDetail&bookingId=${pageData.bookingId}`;
        break;
      case LOBNAMES.TGS:
        detailsPageDeeplink = `mmyt://tripDetails/?page=tripGuaranteeBookingDetail&bookingId=${pageData.bookingId}`;
        break;
      case LOBNAMES.VISA:
        detailsPageDeeplink = `mmyt://tripDetails/?page=visaBookingDetail&bookingId=${pageData.bookingId}`;
        break;
      case LOBNAMES.GIFT:
        detailsPageDeeplink = `mmyt://tripDetails/?page=giftCardBookingDetail&bookingId=${pageData.bookingId}`;
        break;
      case LOBNAMES.SELF_DRIVE:
        detailsPageDeeplink = `mmyt://tripDetails/?page=selfDriveBookingDetail&bookingId=${pageData.bookingId}`;
        break;
      case LOBNAMES.METRO:
        detailsPageDeeplink = `mmyt://tripDetails/?page=metroBookingDetail&bookingId=${pageData.bookingId}`;
        break;
    }
    return detailsPageDeeplink;
  } 

  useEffect(() => {
    PostsalesVernac.init();

    // pageData is only available for android
    // for Android we are getting json data in form of string
    const pageData = props.pageData && typeof props.pageData === 'string' && JSON.parse(props.pageData) || {};
    const { showLangSwitcher = false, lob = '', bookingId = '' } = isIosClient() ? props : pageData;
    // for iOS we don't have context of deeplink, so we are passing deeplink for opening settings to change language
    const detailsPageDeepLink = isIosClient() ? 'mmyt://app/pref' : createDeeplink(pageData);
    const { langSwitcherCTA, langSwitcherTitle, langSwitcherSubTitle } = langSwitcherBottomSheet || {};
    const langSwitcherBottomSheetData = {
      cta: langSwitcherCTA,
      title: langSwitcherTitle,
      subTitle: langSwitcherSubTitle,
      iconUrl: "https://promos.makemytrip.com/GCC/AraEng/%s/arab.png", // %s will be replaced by native side with xhdpi
      redirectionDeeplink: detailsPageDeepLink
    }
    if(showLangSwitcher && lob && bookingId && detailsPageDeepLink){
      GenericModule.openLanguageChangeBottomSheet(langSwitcherBottomSheetData);
    } else if(props?.deeplink_booking_not_found){
      PostSalesBookingTrackingHelper.trackErrorWithData(AUTH_ERROR_PAGENAME, props?.bookingId, AUTH_FAILED_MESSAGE, LOBNAMES.POST_SALES)
      try {
        errorText?.authFailedErrorMessage && showLongToast(errorText.authFailedErrorMessage);
      } catch(e) {}
    }

  }, []);

  const isNewBookingSummary = async () => {
    const useNewBookingSummaryFlag = await isRevampedBookingSummary();
    if (useNewBookingSummaryFlag) {
      setBookingSummaryView(BOOKING_SUMMARY_VIEW.NEW);
    } else {
      setBookingSummaryView(BOOKING_SUMMARY_VIEW.OLD);
    }
  };

  useEffect(() => {
    isNewBookingSummary();
  }, []);

  useEffect(() => {
    const getPaginatedValue = async () => {
      /* This code is written not to render the bookingSummary page if user is not logged in. */
      // TODO: This needs to be implemented on native IOS on ANDROID it is already handled.
      const { UserSessionModule } = NativeModules;
      if (UserSessionModule)
        isUserAuthenticated.current = await UserSessionModule.isUserLoggedIn();
    };
    getPaginatedValue();
  }, []);
  if (!isUserAuthenticated.current || bookingSummaryView === BOOKING_SUMMARY_VIEW.INITIAL) {
    return (
      <View style={AtomicCss.flex1}>
        <ProgressView message={loadingYourTripText} />
      </View>
    );
  } else {
    return bookingSummaryView === BOOKING_SUMMARY_VIEW.NEW ? <BookingSummary {...props} /> : <BookingSummaryV4 {...props} />;
  }
};

export default BaseBookingSummaryComponent;