export const formatAsRuppee = (number) => {
  let num = typeof number === 'string' ? number : String(number);
  const [intPart, decPart='']=num.split('.')
  num = intPart
  let lastThree = num.substring(num.length - 3);
  const otherNumbers = num.substring(0, num.length - 3);
  if (otherNumbers !== '' && otherNumbers !== '0') {
    lastThree = `,${lastThree}`;
  }
  if (decPart !== '') {
    lastThree = `${lastThree}.${decPart}`;
  }
  return otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree;
};

export const numAppendedWithRuppeeSymbol = number => (`₹ ${formatAsRuppee(Math.round(number))}`);

export const numAppendedWithRuppeeSymbolWithoutRound = number => (`₹ ${formatAsRuppee(number)}`);

export const numAppendedWithRuppeeSymbolWithoutRoundWithoutSpace = number => (`₹${formatAsRuppee(number)}`);

export const roundToTwoDec = (float) => Math.round(float * 100) / 100;

export const formatDecimalInt = (nr, base) => {
  const length = (String(base).length - String(nr).length) + 1;
  return length > 0 ? new Array(length).join('0') + nr : nr;
};
