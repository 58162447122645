import React from 'react';
import {View, Text, StyleSheet, TouchableOpacity, Image} from 'react-native';
import PropTypes from 'prop-types';

import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import getPlatformElevation from '@mmt/legacy-commons/Common/GetPlatformElevationCss';
import {fonts, colors} from '@mmt/legacy-commons/Styles/globalStyles';
import {DocIconPending} from '../../HolidayBookingConstants';

export default function NoDocumentsUploadedOverlayContent(props) {
  const {documentPageDetails} = props;
  if (!documentPageDetails) {
    return null;
  }
  if (!Object.keys(documentPageDetails).length) {
    return null;
  }
  const {bottomSheet} = documentPageDetails;
  if (!bottomSheet) {
    return null;
  }
  if (!Object.keys(bottomSheet).length) {
    return null;
  }
  const {NEW} = bottomSheet;
  if (!NEW) {
    return null;
  }
  if (!Object.keys(NEW).length) {
    return null;
  }
  const {heading, text, buttons} = NEW;
  if (!heading || !text || !buttons || buttons.length === 0) {
    return null;
  }

  return (
    <View style={[styles.overlayContent, styles.pendingWrapper]}>
      <View style={styles.pendingIconWrapper}>
        <Image style={styles.pendingIconstyle} source={DocIconPending} />
      </View>
      <Text style={[styles.overlayContentheading, AtomicCss.marginBottom16]}>
        {heading.split(`${documentPageDetails.dueDate}!`).map((_text, index) => {
          if (index === 0) {
            return _text;
          }
          return (
            <React.Fragment>
              <Text style={[AtomicCss.boldFont, styles.pendingTxt]}>{`${documentPageDetails.dueDate}!`}</Text>
              {_text}
            </React.Fragment>
          );
        })}
      </Text>
      <Text style={[AtomicCss.blackText, AtomicCss.font16, AtomicCss.regularFont]}>{text}</Text>
      <View style={[styles.btnWrapper, AtomicCss.flexRow, AtomicCss.spaceBetween]}>
        <TouchableOpacity
          style={[styles.btnLinkWrapper]}
          onPress={() => {
            props.handleButtonClick('NudgesOnBack_UploadNow');
            props.onClose();
          }}
        >
          <Text style={[styles.btnLinkTxt]}>{buttons[0].text}</Text>
        </TouchableOpacity>
        {buttons.length > 1 && (
          <TouchableOpacity
            style={[styles.btnLinkWrapper]}
            onPress={() => {
              props.handleButtonClick('NudgesOnBack_WillDoLater');
              props.goBack();
            }}
          >
            <Text style={[styles.btnLinkTxt]}>{buttons[1].text}</Text>
          </TouchableOpacity>
        )}
      </View>
    </View>
  );
}

NoDocumentsUploadedOverlayContent.propTypes = {
  documentPageDetails: PropTypes.shape({
    bottomSheet: PropTypes.shape({
      NEW: PropTypes.shape({
        heading: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        buttons: PropTypes.shape([
          PropTypes.shape({
            text: PropTypes.string.isRequired
          })
        ]).isRequired
      })
    })
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  handleButtonClick: PropTypes.func.isRequired
};

const styles = StyleSheet.create({
  overlayContent: {
    marginBottom: 30
  },
  overlayContentheading: {
    fontSize: 22,
    color: colors.black,
    fontFamily: fonts.bold
  },
  rowSection: {
    borderBottomColor: '#e7e7e7',
    borderBottomWidth: 1,
    paddingBottom: 18,
    marginBottom: 18
  },
  btnWrapper: {
    marginTop: 40,
    marginBottom: 20
  },
  btnLinkWrapper: {
    paddingVertical: 10
  },
  btnLinkTxt: {
    color: colors.azure,
    fontSize: 14,
    fontFamily: fonts.black
  },
  singleLink: {
    alignSelf: 'flex-end'
  },
  lastRow: {
    marginBottom: 0,
    borderBottomWidth: 0,
    borderBottomColor: 'transparent'
  },
  submittedWrapper: {
    paddingTop: 50
  },
  pendingWrapper: {
    paddingTop: 30
  },
  successfullyTxtHeading: {
    color: '#1a7971',
    fontFamily: fonts.black,
    fontSize: 26,
    marginBottom: 16
  },
  successIconWrapper: {
    width: 90,
    height: 90,
    borderRadius: 50,
    backgroundColor: '#c1f1dd',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: -70
  },

  successIconstyle: {
    width: 66,
    height: 66
  },
  pendingIconWrapper: {
    width: 60,
    height: 60,
    borderRadius: 6,
    backgroundColor: '#fdedd2',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: -60
  },
  pendingIconstyle: {
    width: 40,
    height: 40
  },
  crossIconWrapper: {
    position: 'absolute',
    right: 0,
    width: 30,
    height: 30
  },
  crossIcon: {
    width: 24,
    height: 24
  },
  uploadModeRow: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: 25
  },
  modeIcon: {
    width: 24,
    height: 24,
    marginRight: 10
  },
  cancelUploadbtnWrapper: {
    marginTop: 60
  },
  userDocCard: {
    ...getPlatformElevation(2),
    borderRadius: 6,
    paddingHorizontal: 12,
    paddingVertical: 10,
    backgroundColor: '#fff',
    borderTopWidth: 1,
    borderTopColor: '#f2f2f2'
  },
  pendingTxt: {
    color: '#cc7f01'
  }
});
