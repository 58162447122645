import React from 'react';
import { View, Text, Image, Platform, StyleSheet, TouchableOpacity } from 'react-native';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { fonts, colors } from '@mmt/legacy-commons/Styles/globalStyles';
import getPlatformElevation from '@mmt/legacy-commons/Styles/getPlatformElevation';
import { refundIcon, CONNECT_WITH_HELPDESK } from '../HolidayBookingConstants';
import { openCustomerFormInWebView } from '../../utils/PostSaleUtil';
import Button from './Button';

const handleWtdButtonClick = (wtdItem, bookingId) => {
    const action = {
        webViewHeader: CONNECT_WITH_HELPDESK,
        url: wtdItem.button.link,
    }
    openCustomerFormInWebView(action, bookingId, 'CONNECT_HELPDESK');
}

const trackerData = ({
    wtdRefundDetails,
    bookingId,
}) => {
    return (
        <View>
            {
                wtdRefundDetails.wtdDetails.map(
                    (item, index, array) => {
                        return <View
                            style={index === array.length - 1 ? [styles.refundTrackerRowSection, styles.lastEle] : styles.refundTrackerRowSection}
                            key={item.refundAmount ? `${item.refundAmount}_${index}` : index}
                        >
                            <View style={[AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom10]}>
                                {
                                    array.length > 1 ?
                                        <View style={styles.countWrapper}>
                                            <Text style={[AtomicCss.defaultText, AtomicCss.font12, AtomicCss.boldFont]}>
                                                {index + 1}
                                            </Text>
                                        </View>
                                        :
                                        <View style={styles.subTitleNoListOffset} />
                                }
                                <Text style={[AtomicCss.fontWeightBold, styles.subTitle]}>{item.message}</Text>
                            </View>
                            <View style={styles.innerContent}>
                                <Text style={styles.desc}>{item.subMessage}</Text>
                                <View style={{ width: "90%" }}>
                                    {item.showWTD && item.button &&
                                        <Button
                                            button={item.button}
                                            handleClick={() => handleWtdButtonClick(item, bookingId)}
                                        />}
                                </View>
                            </View>
                        </View>
                    }
                )
            }
        </View >
    )
}

const RefundStatusCard = ({
    wtdRefundDetails,
    bookingId
}) => {
    return (
        wtdRefundDetails ?
            <View style={styles.RefundTrackercard} >
                <View style={[AtomicCss.justifyStart, AtomicCss.flexRow, AtomicCss.alignCenter, AtomicCss.marginBottom18]}>
                    <Image source={refundIcon} style={styles.refundIcon} />
                    <View style={[AtomicCss.flex1, AtomicCss.paddingTop3]}>
                        <Text style={[styles.title, AtomicCss.fontWeightBold]}>{wtdRefundDetails.header}</Text>
                        <Text style={[styles.subHeader, AtomicCss.fontWeightBold]}>{wtdRefundDetails.subHeader}</Text>
                    </View>
                </View>
                <View style={styles.innerDtls}>
                    {trackerData({ wtdRefundDetails, bookingId })}
                </View>
            </View> : null
    );
}

const styles = StyleSheet.create({
    RefundTrackercard: {
        backgroundColor: colors.white,
        paddingVertical: 15,
        marginBottom: 8,
        ...getPlatformElevation(2),
    },
    refundIcon: {
        width: 20,
        height: 18,
        marginHorizontal: 15,
        marginBottom: 10,
    },
    innerContent: {
        flex: 1,
        paddingLeft: 35,
        paddingRight: 15,
    },
    title: {
        color: colors.black2f,
        fontFamily: fonts.black,
        fontSize: 16
    },
    subHeader: {
        color: colors.lightYello,
        fontSize: 14,
        marginTop: 3,
    },
    subTitle: {
        color: colors.grey17,
        fontSize: 14,
        marginTop: 3
    },
    subTitleNoListOffset: {
        marginLeft: 35,
    },
    desc: {
        color: colors.defaultTextColor,
        fontFamily: fonts.regular,
        fontSize: 14,
        marginBottom: 15,
        lineHeight: 20
    },
    innerDtls: {
        paddingHorizontal: 20,
    },
    refundTrackerRowSection: {
        borderBottomColor: colors.lightGrey,
        borderBottomWidth: 1,
        paddingBottom: 15,
        marginBottom: 15,
    },
    lastEle: {
        borderBottomColor: 'transparent',
        borderBottomWidth: 0,
        paddingBottom: 5,
        marginBottom: 0,
    },
    countWrapper: {
        backgroundColor: colors.lightGrey,
        borderRadius: 20,
        width: 20,
        height: 20,
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 15,
        marginTop: 3,
    },
})

export default RefundStatusCard;