import React from 'react';
import { View, Image, Text, ImageSourcePropType } from 'react-native';
import { getStaticData } from '../../../../../staticData/staticData';
import { falseClaimIcon } from 'apps/post-sales/src/cab/CabImageConstants';
import styles from './downloadInvoiceBottomSheetCss';
import AtomicCss from '@mmt/legacy-commons/Styles/AtomicCss';
import { getFont } from '../../../../../PostSalesStyles';
import BaseButton, { BUTTON_VARIANT } from '../../../../../Common/BaseButton';
import getStyle from './styles';
import { useTheme } from 'apps/post-sales/src/theme/theme.context';

interface DownloadInvoiceErrorProps {
  onRetry: () => void;
}

const CAB_DOWNLOAD_FILES_THROTTLE_INTERVAL = 3000;

const DownloadInvoiceError: React.FC<DownloadInvoiceErrorProps> = ({ onRetry }) => {
  const { fsStyle, ...fonts } = getFont(true);
  const {psTheme} = useTheme();
  const { retryText, tryAgainInSomeTime, invoicesNotDownloadedText } = getStaticData();
  const styles = getStyle(fonts, psTheme);

  return (
    <>
      <View style={styles.errorScreenWrapper}>
        <Image style={styles.errorIcon} source={falseClaimIcon as ImageSourcePropType} />
        <Text
          style={[
            AtomicCss.fontWeightBold,
            fsStyle.font22,
            fonts.regularFontFamily,
            AtomicCss.blackText,
            AtomicCss.marginBottom10,
          ]}
        >
          {invoicesNotDownloadedText}
        </Text>
        <Text style={[fsStyle.font16, fonts.regularFontFamily, AtomicCss.blackText]}>
          {tryAgainInSomeTime}
        </Text>
      </View>
      <BaseButton
        variant={BUTTON_VARIANT.PRIMARY}
        clickHandler={onRetry}
        textStyle={fsStyle.font16}
        text={retryText}
        throttleInterval={CAB_DOWNLOAD_FILES_THROTTLE_INTERVAL}
        isThrottleRequired={true}
        buttonStyle={AtomicCss.marginBottom15}
      />
    </>
  );
};

export default DownloadInvoiceError;
